import React from "react";
import { colors } from "Constants";
import {
  getAcademicYearDetailsFromCahce,
  getOrganizationDetailsFromCache,
} from "modules/CommonGraphqlHelpers";
import {
  CardsView,
  PostIcon,
  UnitIcon,
  UnknownFileIcon,
  ScopeAndSequenceSvg,
  POISvg,
  NoteIcon,
  CreateAssessmentSvg,
} from "SvgComponents";
import {
  LearningExperienceColored,
  SummativeAssessmentColored,
  FormativeAssessmentColored,
  QuickTaskOutlined,
  ListDashedOutlined,
  LinkColored,
  VideoColored,
  InsightsSquareOutlined,
  ImageColored,
  AudioColored,
  DocumentsColored,
  PdfColored,
  SlidesColored,
  SheetsColored,
} from "@toddle-design/web-icons";
import {
  formatBytes,
  getFileExtensionFromMimeType,
  getMemoizedFunction,
  getYearDurationFormat,
  localSearch,
} from "Utils";
import moment from "moment";
import ACLStore from "lib/aclStore";

// --------------CONSTANTS--------------

export const EVIDENCE_TYPE_MAPPING = {
  pt: {
    label: "common:performance_task",
    icon: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  pri: {
    label: "classRoom:pre_assessment",
    icon: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  se: {
    label: "common:supplementary_evidence",
    icon: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  fmt: {
    icon: <FormativeAssessmentColored size={"xx-small"} />,
    label: "classRoom:formative_assessment",
  },
  smt: {
    icon: <SummativeAssessmentColored size={"xx-small"} />,
    label: "classRoom:summative_assessment",
  },
  le: {
    icon: <LearningExperienceColored size={"xx-small"} />,
    label: "common:le_label",
  },
  qt: {
    icon: <QuickTaskOutlined size={"xx-small"} variant={"link"} />,
    label: "common:quick_task",
  },
  ASSESSMENT: {
    icon: <LearningExperienceColored size={"xx-small"} />,
    label: "common:le_label",
    sidebarLabel: "common:le_label_plural",
  },
  POST: {
    icon: <PostIcon fill={colors.violet63} />,
    label: "common:post",
  },
  UNIT_PLAN: {
    icon: <UnitIcon fill={colors.salmon60} />,
    label: "common:unit",
    sidebarLabel: "common:unit_plan_plural",
  },
  SUBJECT: {
    icon: <ScopeAndSequenceSvg fill={colors.salmon60} />,
    label: "common:content_standards",
  },
  POI_INSIGHT: {
    icon: <InsightsSquareOutlined size={"xx-small"} />,
    label: "planningInsights:type_insights_label_with_year",
  },
  MYP_PLANNING_INSIGHTS: {
    icon: <InsightsSquareOutlined size={"xx-small"} />,
    label: "planningInsights:type_insights_label_with_year",
  },
  ATTACHMENT: {
    sidebarLabel: "common:attachments",
  },
  POI_ACADEMIC_YEAR: {
    icon: <POISvg fill={colors.salmon60} />,
    label: "common:poi",
  },
  UNKNOWN: {
    icon: <UnknownFileIcon fill={colors.gray31} width={16} height={17} />,
  },
  NOTE: {
    icon: <NoteIcon fill={colors.yellow50} width={15} height={15} />,
    label: "common:note",
  },
  VIDEO: {
    icon: <VideoColored size={"xx-small"} />,
    label: "common:video",
  },
  IMAGE: {
    icon: <ImageColored size={"xx-small"} />,
    label: "common:image",
  },
  FILE: {
    label: "common:file",
  },
  LINK: {
    icon: <LinkColored size={"x-small"} />,
    label: "common:link",
  },
  AUDIO: {
    icon: <AudioColored size={"xx-small"} />,
    label: "common:audio",
  },
  ASSIGNMENT_RESOURCE: {
    icon: <QuickTaskOutlined size={"xx-small"} variant={"link"} />,
    label: "common:quick_task",
  },
  ASSIGNMENT: {
    sidebarLabel: "common:classroom",
  },
  STUDENT_PROGRESS_REPORT: {
    icon: <PdfColored size={"xx-small"} />,
    label: "progressReport:progress_report",
    sidebarLabel: "progressReport:progress_report_plural",
  },
};

const FILE_TYPE_MAPPING = {
  zip: {
    label: "ZIP",
    icon: null,
  },
  epub: {
    label: "EPUB",
    icon: null,
  },
  pptx: {
    label: "PPT",
    icon: <SlidesColored size={"xx-small"} />,
  },
  ppt: {
    label: "PPT",
    icon: <SlidesColored size={"xx-small"} />,
  },
  xlsx: {
    label: "XLSX",
    icon: <SheetsColored size={"xx-small"} />,
  },
  xls: {
    label: "XLS",
    icon: <SheetsColored size={"xx-small"} />,
  },
  csv: {
    label: "CSV",
    icon: <SheetsColored size={"xx-small"} />,
  },
  doc: {
    label: "Word doc",
    icon: <DocumentsColored size={"xx-small"} />,
  },
  docx: {
    label: "Word doc",
    icon: <DocumentsColored size={"xx-small"} />,
  },
  gdoc: {
    label: "Google doc",
    icon: <DocumentsColored size={"xx-small"} />,
  },
  gslides: {
    label: "Google slides",
    icon: <SlidesColored size={"xx-small"} />,
  },
  gsheet: {
    label: "Google sheet",
    icon: <SheetsColored size={"xx-small"} />,
  },
  pdf: {
    label: "PDF",
    icon: <PdfColored size={"xx-small"} />,
  },
};

export const DEFAULT_PAGE_SIZE = 9;

export const TOTAL_EVIDENCE_TO_FETCH_AFTER_DELETION = 1;

export const MAX_EVIDENCE_TITLE_LENGTH = 60;

export const EVIDENCE_VIEW_ICON_MAPPING = {
  LIST: <ListDashedOutlined size={"x-small"} variant={"default"} />,
  CARD: <CardsView width={28} height={28} fill={colors.gray13} />,
};

export const EVIDENCE_VIEW_OPTIONS = [
  {
    label: "Card View",
    svg: EVIDENCE_VIEW_ICON_MAPPING["CARD"],
    value: "CARD",
  },
  {
    label: "List View",
    svg: EVIDENCE_VIEW_ICON_MAPPING["LIST"],
    value: "LIST",
  },
];

export const ADD_EVIDENCE_OPTIONS = [
  { key: "add_evidence", label: "add_from_toddle" },
  { key: "upload_device", label: "upload_device" },
  { key: "add_google_drive", label: "add_google_drive" },
  { key: "add_one_drive", label: "add_one_drive" },
  { key: "add_link", label: "add_link" },
];

export const INITIAL_RECORD_NUMBER = 15;

export const SNP_TAGGED_PRACTICE = "SNP_TAGGED_PRACTICE";
export const SNP_STANDARD = "SNP_STANDARD";
export const SNP_DOCUMENT_CATEGORY = "SNP_DOCUMENT_CATEGORY";

export const CHAT_TABS_CONFIG = {
  admin: {
    DOCUMENTS: [
      {
        label: "snp:visiting_team",
        value: "SNP_DOCUMENT_SCHOOL_VISITING",
        chatLabel: "snp:visiting_team_chat",
      },
      {
        label: "snp:internal_school",
        value: "SNP_DOCUMENT_SCHOOL_INTERNAL",
        chatLabel: "snp:internal_school_chat",
        isDisabled: true,
      },
    ],
    DASHBOARD: [
      {
        label: "snp:visiting_team",
        value: "SNP_STANDARD_SCHOOL_VISITING",
        chatLabel: "snp:visiting_team_chat",
      },
      {
        label: "snp:internal_school",
        value: "SNP_STANDARD_SCHOOL_INTERNAL",
        chatLabel: "snp:internal_school_chat",
      },
    ],
  },
  teacher: {
    DASHBOARD: [
      {
        label: "snp:internal_school_chat",
        value: "SNP_STANDARD_SCHOOL_INTERNAL",
        chatLabel: "snp:internal_school_chat",
      },
    ],
  },
  visitor: {
    DOCUMENTS: [
      {
        label: "snp:school_team",
        value: "SNP_DOCUMENT_SCHOOL_VISITING",
        chatLabel: "snp:school_team_chat",
      },
      {
        label: "snp:visiting_team",
        value: "SNP_DOCUMENT_VISITING_INTERNAL",
        chatLabel: "snp:visiting_team_chat",
      },
    ],
    DASHBOARD: [
      {
        label: "snp:school_team",
        value: "SNP_STANDARD_SCHOOL_VISITING",
        chatLabel: "snp:school_team_chat",
      },
      {
        label: "snp:visiting_team",
        value: "SNP_STANDARD_VISITING_INTERNAL",
        chatLabel: "snp:visiting_team_chat",
      },
    ],
  },
};

export const POI_VIEW_COLOR_MAP = ["salmon60", "violet63", "blue29"];

// these fields are necessary so that old snp route does not break when new data is present
export const OLD_SNP_FIELDS = {
  state: "NA",
  month: 4,
  year: 2021,
  templateType: "SELF_STUDY_2018",
};

export const PLANNING_INSIGHT_TYPES = [
  "ACADEMIC_YEAR_POI_GRADE_CONCEPT",
  "ACADEMIC_YEAR_POI_THEME_CONCEPT",
  "ACADEMIC_YEAR_POI_GRADE_LP",
  "ACADEMIC_YEAR_POI_THEME_LP",
  "ACADEMIC_YEAR_POI_GRADE_ATL",
  "ACADEMIC_YEAR_POI_THEME_ATL",
  "ACADEMIC_YEAR_POI_GRADE_FA",
  "ACADEMIC_YEAR_POI_GRADE_SUBJECT",
  "ACADEMIC_YEAR_POI_THEME_SUBJECT",
];

export const POI_INSIGHT_TYPE_TO_DATA_MAPPING = {
  ACADEMIC_YEAR_POI_GRADE_CONCEPT: {
    locale: "POI:grades_label",
    params: "common:concept_plural",
    route: "keyConcepts",
    table: "dist_concept_grade",
    color: "violet63",
  },
  ACADEMIC_YEAR_POI_THEME_CONCEPT: {
    locale: "POI:transdisciplinary_label",
    params: "common:concept_plural",
    route: "keyConcepts",
    table: "dist_concept_theme",
    color: "blue29",
  },
  ACADEMIC_YEAR_POI_GRADE_LP: {
    locale: "POI:grades_label",
    params: "common:lp_label",
    route: "learnerProfile",
    table: "dist_lp_grade",
    color: "yellow50",
  },
  ACADEMIC_YEAR_POI_THEME_LP: {
    locale: "POI:transdisciplinary_label",
    params: "common:lp_label",
    route: "learnerProfile",
    table: "dist_lp_theme",
    color: "salmon60",
  },
  ACADEMIC_YEAR_POI_GRADE_ATL: {
    locale: "POI:grades_label",
    params: "common:atls_label",
    route: "atls",
    table: "dist_atls_grade",
    color: "teal20",
  },
  ACADEMIC_YEAR_POI_THEME_ATL: {
    locale: "POI:transdisciplinary_label",
    params: "common:atls_label",
    route: "atls",
    table: "dist_atls_theme",
    color: "pink39",
  },
  ACADEMIC_YEAR_POI_GRADE_FA: {
    locale: "POI:transdisciplinary_descriptors",
    route: "themeFocusAreas",
    table: "dist_theme_grade",
    color: "salmon60",
  },
  ACADEMIC_YEAR_POI_GRADE_SUBJECT: {
    locale: "POI:grades_label",
    params: "common:subject_plural",
    route: "subjects",
    table: "dist_subject_grade",
    color: "violet63",
  },
  ACADEMIC_YEAR_POI_THEME_SUBJECT: {
    locale: "POI:transdisciplinary_label",
    params: "common:subject_plural",
    route: "subjects",
    table: "dist_subject_theme",
    color: "blue29",
  },
};

export const MYP_PLANNING_INSIGHT_CONFIG = {
  SUBJECT_GROUP_OVERVIEW: {
    label: "planningInsights:vertical_overview_subject_group_overview_label",
    route: "sgo",
  },
  SUBJECT_GROUP_POI_GLOBAL_CONTEXTS: {
    label: "planningInsights:vertical_overview_global_contexts_insights_label",
    route: "globalContexts",
  },
  SUBJECT_GROUP_POI_KEY_AND_RELATED_CONCEPTS: {
    label:
      "planningInsights:vertical_overview_key_and_related_concepts_insights_label",
    route: "krc",
  },
  SUBJECT_GROUP_POI_ATL: {
    label: "planningInsights:vertical_overview_approaches_to_learning_label",
    route: "skills",
  },
  SUBJECT_GROUP_POI_MYP_OBJECTIVES: {
    label: "planningInsights:vertical_overview_MYP_objectives_insights_label",
    route: "objectives",
  },
};

export const MYP_PLANNING_INSIGHT_TYPES = [
  "SUBJECT_GROUP_OVERVIEW",
  "SUBJECT_GROUP_POI_GLOBAL_CONTEXTS",
  "SUBJECT_GROUP_POI_KEY_AND_RELATED_CONCEPTS",
  "SUBJECT_GROUP_POI_ATL",
  "SUBJECT_GROUP_POI_MYP_OBJECTIVES",
];

// --------------FUNCTIONS--------------

export const getAttachmentIcon = (type, mimeType) => {
  const fileMimeType = mimeType || "";
  const fileType = getFileExtensionFromMimeType({ mimeType: fileMimeType });

  if (type === "FILE") {
    const icon = _.get(FILE_TYPE_MAPPING[fileType], "icon", null);
    if (icon) return icon;
    else return _.get(EVIDENCE_TYPE_MAPPING["UNKNOWN"], "icon");
  } else {
    return _.get(EVIDENCE_TYPE_MAPPING[type], "icon", null);
  }
};

export const getEvidenceIcon = ({ type, data }) => {
  switch (type) {
    case "ATTACHMENT": {
      return getAttachmentIcon(
        _.get(data, "type", ""),
        _.get(data, "mimeType", "")
      );
    }

    case "SCHOOL_POLICIES":
    case "TEACHER_RESOURCE": {
      return getAttachmentIcon(
        _.get(data, "attachment.type", ""),
        _.get(data, "attachment.mimeType", "")
      );
    }

    case "ASSIGNMENT": {
      const contentType = _.get(data, "contentType", "");
      const type =
        contentType === "ASSIGNMENT_RESOURCE"
          ? contentType
          : _.get(data, "content.assessmentType.value", "");

      return _.get(EVIDENCE_TYPE_MAPPING[type], "icon", null);
    }

    default: {
      if (isPOIInsightEvidence({ type })) {
        return _.get(EVIDENCE_TYPE_MAPPING["POI_INSIGHT"], "icon");
      }

      if (isMypPlanningInsightEvidence({ type })) {
        return _.get(EVIDENCE_TYPE_MAPPING["MYP_PLANNING_INSIGHTS"], "icon");
      }

      const icon = _.get(EVIDENCE_TYPE_MAPPING[type], "icon", null);
      return icon ? icon : _.get(EVIDENCE_TYPE_MAPPING["UNKNOWN"], "icon");
    }
  }
};

export const trimSnpCodeLabels = ({ code }) => {
  const redundantCharacters = [" ", "(", ")"];

  return _.trim(code, redundantCharacters);
};

// To get evidence type description for attachment types
export const getAttachmentDescription = ({ type, metadata, mimeType }) => {
  const size = _.get(metadata, "size", 2048);
  const fileType = getFileExtensionFromMimeType({ mimeType });
  if (type === "LINK") return "Link";

  const fileSize = formatBytes({ bytes: size, decimals: 1 });
  if (type === "VIDEO") return "Video (" + fileSize + ")";
  if (type === "AUDIO") return "Audio (" + fileSize + ")";
  if (type === "IMAGE") return "Image (" + fileSize + ")";
  if (type === "FILE") {
    const description =
      _.get(FILE_TYPE_MAPPING[fileType], "label", "") + " (" + fileSize + ")";
    return description;
  }
  if (type === "NOTE") return "Note";

  return "Unknown";
};

export const getEvidenceTypeLabel = ({ type, data, t }) => {
  const evidenceLabel = _.get(EVIDENCE_TYPE_MAPPING[type], "label", "");

  switch (type) {
    case "ATTACHMENT":
      return getAttachmentDescription(
        _.pick(data, ["type", "mimeType", "metadata"])
      );
    case "ASSIGNMENT": {
      const contentType = _.get(data, "contentType", "");
      const type =
        contentType === "ASSIGNMENT_RESOURCE"
          ? contentType
          : _.get(data, "content.assessmentType.value", "");

      /** classroom evidences need to be given extra suffix " (classroom)" to their
       * type label to differentiate them from learning experience evidences */
      return `${t(_.get(EVIDENCE_TYPE_MAPPING[type], "label", ""))} (${t(
        "common:classroom"
      )})`;
    }
    case "SCHOOL_POLICIES":
    case "TEACHER_RESOURCE":
      return getAttachmentDescription(
        _.pick(_.get(data, "attachment"), ["type", "mimeType", "metadata"])
      );
    default: {
      if (isPOIInsightEvidence({ type })) {
        const academicYear = getAcademicYearFromId({
          academicYearId: _.get(data, "id", ""),
        });
        return t(_.get(EVIDENCE_TYPE_MAPPING["POI_INSIGHT"], "label"), {
          year: academicYear,
        });
      }

      if (isMypPlanningInsightEvidence({ type })) {
        const { parentItem } = data;

        const academicYearId = _.get(_.first(parentItem), "item.id", "");

        const academicYear = getAcademicYearFromId({
          academicYearId,
        });

        return t(
          _.get(EVIDENCE_TYPE_MAPPING["MYP_PLANNING_INSIGHTS"], "label", ""),
          {
            year: academicYear,
          }
        );
      }
      return t(evidenceLabel);
    }
  }
};

const getAttachmentTitle = attachment => {
  if (_.get(attachment, "title")) return attachment.title;
  if (_.get(attachment, "attachmentName")) return attachment.attachmentName;
  return "Untitled";
};

export const getEvidenceTitle = ({
  type,
  data,
  t,
  studentResponseAttachments,
}) => {
  switch (type) {
    case "ATTACHMENT":
      return getAttachmentTitle(data);
    case "ASSESSMENT":
      return data.assessmentTitle.value
        ? data.assessmentTitle.value
        : "Untitled";
    case "POST":
      return data.postTitle ? data.postTitle : t("common:untitled");

    case "UNIT_PLAN":
      return data.unitPlanTitle.value
        ? data.unitPlanTitle.value
        : t("common:untitled");

    case "SUBJECT":
      return _.get(data, "name", t("common:untitled"));

    case "POI_ACADEMIC_YEAR": {
      const id = _.get(data, "id", "");
      return getAcademicYearFromId({ academicYearId: id });
    }

    case "SCHOOL_POLICIES":
    case "TEACHER_RESOURCE":
      return _.get(data, "label")
        ? _.get(data, "label")
        : getAttachmentTitle(_.get(data, "attachment", {}));
    case "STUDENT_PROGRESS_REPORT": {
      const { firstName, lastName } = _.pick(_.get(data, "student", {}), [
        "firstName",
        "lastName",
      ]);
      const reportName = _.get(data, "courseProgressReportTask.title", "");

      const title = firstName + " " + lastName + " " + reportName;
      return _.replace(title, /\s/g, "_");
    }
    case "ASSIGNMENT": {
      if (_.get(data, "contentType", "") === "ASSESSMENT")
        return _.get(data, "content.title.value", "");
      else return _.get(data, "content.quickTaskTitle", "");
    }
    default:
      if (isPOIInsightEvidence({ type })) {
        return getPlanningInsightTitle({ type, t });
      }

      if (isMypPlanningInsightEvidence({ type })) {
        const label = t(MYP_PLANNING_INSIGHT_CONFIG[type].label);
        const subjectGroup = _.get(data, "name", "");

        return subjectGroup ? `${label} (${subjectGroup})` : label;
      }
      return "Untitled";
  }
};

export const getAcademicYearsList = ({ organizationId }) => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);

  return _.map(
    _.get(organizationDetails, "academicYears", []),
    academicYear => {
      const { id, startDate, endDate } = academicYear;
      return {
        label: getYearDurationFormat({ startDate, endDate }),
        value: id,
      };
    }
  );
};

export const getAcademicYearFromId = ({ academicYearId }) => {
  const { startDate, endDate } = getAcademicYearDetailsFromCahce(
    academicYearId
  );
  return getYearDurationFormat({ startDate, endDate });
};

export const getCurrentAcademicYear = organizationId => {
  const academicYears = _.get(
    getOrganizationDetailsFromCache(organizationId),
    "academicYears",
    []
  );
  return _.get(
    _.find(academicYears, academicYear => academicYear.isCurrentAcademicYear),
    "id",
    ""
  );
};

export const getGradesList = organizationId => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  return _.map(_.get(organizationDetails, "grades", []), grade => {
    const { id, name } = grade;
    return {
      label: name,
      value: id,
    };
  });
};

export const getUnitTypesList = organizationId => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const unitTypes = _.map(
    _.get(organizationDetails, "unitTypes", []),
    unitType => {
      const { id, name } = unitType;
      return {
        label: name,
        value: id,
      };
    }
  );
  return _.concat([{ label: "All", value: "ALL" }], unitTypes);
};

// utility to get the id's required from classroom evidence to redirect to it's view mode
export const getClassroomSubmissionIds = _.memoize(
  ({ data }) => {
    const attachmentId = _.get(data, "id");

    const studentAssignmentSubmission = _.get(
      data,
      "resolvedParentEntity.resolvedParentEntity",
      ""
    );

    const submissionId = _.get(studentAssignmentSubmission, "id", "");
    const assignmentId = _.get(
      studentAssignmentSubmission,
      "assignment.id",
      ""
    );

    const assignedStudents = _.get(
      data,
      "resolvedParentEntity.resolvedParentEntity.assignment.assignedStudents",
      []
    );

    const studentSubmission = _.find(assignedStudents, assignedStudent => {
      return _.get(assignedStudent, "submission.id", "") == submissionId;
    });

    const studentId = _.get(studentSubmission, "id", "");

    return { assignmentId, studentId, attachmentId };
  },
  params => JSON.stringify(params)
);

export const isPOIInsightEvidence = ({ type }) =>
  _.startsWith(type, "ACADEMIC_YEAR_POI");

export const isMypPlanningInsightEvidence = ({ type }) =>
  _.includes(MYP_PLANNING_INSIGHT_TYPES, type);

// generates params required for redirecting to evidence view for different evidence types
export const getEvidenceViewParams = ({ type, evidenceItem }) => {
  const id = _.get(evidenceItem, "id");

  switch (type) {
    case "STUDENT_ASSIGNMENT_SUBMISSION_ATTACHMENT":
      return {
        data: getClassroomSubmissionIds({ data: evidenceItem }),
      };
    case "UNIT_PLAN":
    case "ASSESSMENT":
      return { id };
    case "STUDENT_PROGRESS_REPORT":
      return {
        data: {
          taskId: _.get(evidenceItem, "courseProgressReportTask.id", ""),
          setId: _.get(
            evidenceItem,
            "courseProgressReportTask.progressReportTaskGroup.id",
            ""
          ),
          edgeId: id,
        },
        printPreview: false,
      };
  }
};

// to get the list of grades and themes/subjects of a unit
export const getUnitPlanThemeAndGrade = ({ data }) => {
  let label = "";
  const unitType = _.get(data, "unitType.value", "");

  const grades = _.join(
    _.map(_.get(data, "resolvedGrades", []), grade => grade.name),
    ", "
  );

  label = grades;

  if (unitType === "ibStandAlone") {
    const fields = _.get(data, "fields", []);

    const node = _.find(fields, ["uid", "subjects"]) ?? {};

    const subjects = _.join(
      _.map(
        _.get(node, "resolvedMinimalTree.subjects", []),
        subject => subject.name
      ),
      ", "
    );

    label = label + " • " + subjects;
  } else {
    const themes = _.join(
      _.map(
        _.get(data, "theme.resolvedMinimalTree.themes", []),
        theme => theme.label
      ),
      ", "
    );

    label = label + " • " + themes;
  }

  return label;
};

/** returns the filters that need to be applied to getSnPEvaluationCycles query
 * based on if the user type is Teacher or Visitor
 */
export const getSnPEvaluationCyclesQueryFilters = ({
  isTeacher,
  curriculumProgramId,
}) => {
  return { curriculumProgramId, ...(isTeacher && { isArchived: false }) };
};

export const getSnPSetFrameworkItemLabel = ({ data, t }) => {
  const { code, label, localizedLabel } = data;

  const sanitizedCode = trimSnpCodeLabels({ code });

  return `${sanitizedCode}: ${t(`snpTemplate:${label}`, {
    defaultValue: localizedLabel,
  })}`;
};

export const getSnpV2Cycles = ({ cycles }) => {
  return _.filter(cycles, cycle => {
    const title = _.get(cycle, "title", "");
    const cycleType = _.get(cycle, "cycleType", "");
    const set = _.get(cycle, "set", {});
    return !(_.isEmpty(title) || _.isEmpty(cycleType) || _.isEmpty(set));
  });
};
export const getPlanningInsightTitle = ({ type, t }) => {
  const locale = POI_INSIGHT_TYPE_TO_DATA_MAPPING[type]["locale"];

  const params = POI_INSIGHT_TYPE_TO_DATA_MAPPING[type]["params"];

  return t(`${locale}`, { label: t(`${params}`) });
};

const getFilteredVisitorsList = ({ queryData, disabledVisitors }) => {
  const visitorList = _.map(_.get(queryData, "staff.edges", []), "node");

  const filteredVisitorList = _.filter(
    visitorList,
    visitor => !_.includes(disabledVisitors, visitor.id)
  );

  return filteredVisitorList;
};

export const getFilteredVisitorsListMemoized = _.memoize(
  params => getFilteredVisitorsList(params),
  params => JSON.stringify(params)
);

export const invitedVisitorsListQueryVariables = {
  orderBy: "CREATED_AT",
  orderByDirection: "DESC",
};

const getMatchingUsers = ({ users, searchTerm }) => {
  return _.filter(users, ({ name }) => {
    return localSearch({ text: name, searchText: searchTerm }) > -1;
  });
};

export const getMatchingUsersMemoized = getMemoizedFunction(getMatchingUsers);

const getTransformedUsersForChat = ({ users }) => {
  return _.map(users, user => {
    const { firstName, lastName } = user;
    return {
      ..._.omit(user, ["firstName", "lastName"]),
      name: `${firstName} ${lastName}`,
    };
  });
};

export const getTransformedUsersForChatMemoized = getMemoizedFunction(
  getTransformedUsersForChat
);

export const isUserAdmin = () => ACLStore.can("AdminPortal");

export const getPracticeWrappersFromPractices = ({
  practices,
  userId,
  evidenceStatus,
}) => {
  return _.map(practices, ({ id, ...rest }) => {
    return {
      taggedBy: {
        id: userId,
        __typename: "Staff",
      },
      practice: {
        id,
        ...rest,
        __typename: "SnPPractice",
      },
      evidenceStatus,
      __typename: "SnPTaggedPracticeWrapper",
    };
  });
};

export const getNotAddedOrRemovedPracticeWrappers = ({
  practiceWrappers,
  removedPractices,
  addedPractices,
}) => {
  return _.filter(practiceWrappers, ({ practice: { id } }) => {
    return (
      !_.some(removedPractices, ({ id: practiceId }) => practiceId === id) &&
      !_.some(addedPractices, ({ id: practiceId }) => practiceId === id)
    );
  });
};

export const getUpdatedTaggedPractices = ({
  taggedPractices,
  evaluationCycleId,
  addedPractices,
  removedPractices,
  userId,
}) => {
  const taggedPracticesForCurrentCycle =
    _.find(
      taggedPractices,
      ({ evaluationCycle }) => evaluationCycle.id === evaluationCycleId
    ) ?? {};

  const practiceWrappers = _.get(
    taggedPracticesForCurrentCycle,
    "taggedPractice",
    []
  );

  const notUpdatedPracticeWrappers = getNotAddedOrRemovedPracticeWrappers({
    practiceWrappers,
    addedPractices,
    removedPractices,
  });

  const evidenceStatus = isUserAdmin() ? "APPROVED" : "SUGGESTED";

  const addedPracticeWrappers = getPracticeWrappersFromPractices({
    practices: addedPractices,
    userId,
    evidenceStatus,
  });

  const updatedTaggedPracticesForCurrentCycle = {
    ...taggedPracticesForCurrentCycle,
    evaluationCycle: {
      id: evaluationCycleId,
      __typename: "SnPEvaluationCycle",
    },
    taggedPractice: [...notUpdatedPracticeWrappers, ...addedPracticeWrappers],
    __typename: "SnPTaggedPractice",
  };

  const updatedTaggedPractices = _.concat(
    _.filter(
      taggedPractices,
      ({ evaluationCycle }) => evaluationCycleId !== evaluationCycle.id
    ),
    [updatedTaggedPracticesForCurrentCycle]
  );

  return updatedTaggedPractices;
};

export const getStandardEvidencesQueryVariables = getMemoizedFunction(
  ({ cycleId, activeTab }) => {
    const commonVariables = {
      first: 9,
      orderBy: "CREATED_AT",
      orderByDirection: "DESC",
      evidenceFilters: {
        evaluationCycleIds: [cycleId],
      },
    };

    switch (activeTab) {
      case "admin":
      case "visitor": {
        // |- Usage: Admin/Visitor Dashboard
        // |
        // |- Requirement: We need all types of approved evidences and only first 5 of each
        // |      practice in the descending order in which they are added
        return {
          ...commonVariables,
          evidenceFilters: {
            evaluationCycleIds: [cycleId],
            evidenceStatus: "APPROVED",
          },
        };
      }
      case "teacher": {
        // |- Usage: Teacher Dashboard
        // |
        // |- Requirement: We need all types of suggested and approved evidences and only first 5
        // |      per practice in the order : approved evidences > evidences suggested by me > evidences suggested by
        // |      other teachers
        return {
          ...commonVariables,
          applyTeacherDashboardOrdering: true,
        };
      }
    }
  }
);

/** Maps the no. of evidences required to render the first two rows of an evidence type
 * in suggested evidence tab */
export const EVIDENCE_GROUP_SIZE_MAPPING = {
  POST: 8,
  ASSIGNMENT: 4,
  UNIT_PLAN: 6,
  ASSESSMENT: 4,
  STUDENT_PROGRESS_REPORT: 4,
};

export const getDocumentCategoryTaggedPracticeQueryVariables = ({
  categoryId,
  cycleId,
}) => {
  /** Here we fetch tagged practices of a category and their first 9 approved evidences
   *  in descending order in which they are created similar to the way we fetch
   *  all practices of a standard in evidence dashboard */

  return {
    categoryId,
    first: 9,
    orderBy: "CREATED_AT",
    orderByDirection: "DESC",
    evidenceFilters: {
      evaluationCycleIds: [cycleId],
      evidenceStatus: "APPROVED",
    },
  };
};

export const getPracticeEvidencesQueryVariables = getMemoizedFunction(
  ({
    practiceId,
    cycleId,
    activeTab,
    isSuggestedEvidenceTab = false,
    evidenceType,
  }) => {
    const commonVariables = {
      practiceId,
      orderBy: "CREATED_AT",
      orderByDirection: "DESC",
    };

    switch (activeTab) {
      case "admin":
      case "visitor": {
        if (isSuggestedEvidenceTab) {
          // |- Usage: Admin Dashboard > Add Evidence Modal > Suggested by Teachers Tab
          // |
          // |- Requirement: We need specific evidence types and initially only
          // |  first two rows for each type in the descending order in which they are added
          return {
            ...commonVariables,
            first: EVIDENCE_GROUP_SIZE_MAPPING[evidenceType],
            evidenceFilters: {
              evaluationCycleIds: [cycleId],
              evidenceStatus: "SUGGESTED",
              evidenceType: [evidenceType],
            },
          };
        } else {
          // |- Usage: Admin/Visitor Dashboard > Selected Evidences Modal
          // |
          // |- Requirement: We need all types of approved evidences paginated (initially 9)
          // |       in the descending order in which they are added
          return {
            ...commonVariables,
            first: 9,
            evidenceFilters: {
              evaluationCycleIds: [cycleId],
              evidenceStatus: "APPROVED",
            },
          };
        }
      }
      case "teacher": {
        // |- Usage: Teacher Dashboard > Selected Evidences Modal
        // |
        // |- Requirement: We need all types of suggested and approved evidences paginated
        // |      (initially 9) in the order : approved evidences > evidences suggested by me
        // |      > evidences suggested by other teachers

        return {
          ...commonVariables,
          first: 9,
          evidenceFilters: {
            evaluationCycleIds: [cycleId],
          },
          applyTeacherDashboardOrdering: true,
        };
      }
    }
  }
);

export const canUserDeleteEvidence = ({
  activeTab,
  createdBy,
  userId,
  isCycleArchived,
}) => {
  /**
   * Admin - Can delete all the evidences
   * Teacher - Can only delete evidences added by them
   * Visitor - Cannot delete evidences
   */
  const canUserDeleteEvidence =
    activeTab === "teacher"
      ? _.get(createdBy, "id", "") == userId
      : activeTab == "visitor"
      ? false
      : true;

  return !isCycleArchived && canUserDeleteEvidence;
};

export const getLocalTime = ({ date }) => {
  const momentDate = moment(date);
  return { date: momentDate.format("ll"), time: momentDate.format("LT") };
};

export const getStandardBasicDetailsQueryVariables = ({ cycleId }) => {
  return {
    cycleId,
    filters: {
      evaluationCycleIds: [cycleId],
      evidenceStatus: "APPROVED",
    },
  };
};

export const getCompletedStatusTooltip = ({ userStatus, t }) => {
  const { updatedAt, updatedBy } = userStatus;

  const { firstName, lastName } = updatedBy;

  const localTime = getLocalTime({ date: updatedAt });

  const { time, date } = localTime;

  return t("snp:last_updated_by_text", {
    firstName,
    lastName,
    date,
    time,
  });
};

export const getTooltip = ({ isCompleted, isAdmin, t, userStatus }) => {
  if (isCompleted) {
    return getCompletedStatusTooltip({ userStatus, t });
  }

  if (isAdmin) {
    return t("common:mark_as_label", {
      label: t("snp:ready_for_review_answer"),
    });
  }

  return t("common:mark_as_label", { label: t("classRoom:reviewed") });
};

/**These are used for getting selected evidences for given evidenceTypes */
export const getPracticeEvidenceFilters = ({ cycleId, types }) => {
  return {
    evidenceStatus: "APPROVED",
    evidenceType: types,
    evaluationCycleIds: [cycleId],
  };
};

export const getDocumentCategoryEvidenceFilters = ({ types }) => {
  return {
    evidenceType: types,
  };
};

export const getDocumentCategoryEvidenceFeedQueryVariables = ({
  id,
  after,
  first,
}) => {
  return {
    id,
    first: first ?? DEFAULT_PAGE_SIZE,
    after,
    orderBy: "CREATED_AT",
    orderByDirection: "DESC",
  };
};

/**
 * Below set of util functions are used for removing evidence from cache
 */

const getEvidenceAndTotalCount = ({ data }) => {
  const taggedEvidence = _.get(data, "taggedEvidence", {});

  const evidenceEdges = _.get(taggedEvidence, "edges", []);
  const totalCount = _.get(taggedEvidence, "totalCount", 0);

  return { evidenceEdges, totalCount };
};

const updateEvidenceAndTotalCountInEvidenceData = ({
  data,
  evidenceEdges,
  totalCount,
}) => {
  const oldTaggedEvidence = _.get(data, "taggedEvidence", {});

  const updatedTaggedEvidence = {
    ...oldTaggedEvidence,
    edges: evidenceEdges,
    totalCount,
  };

  return { ...data, taggedEvidence: updatedTaggedEvidence };
};

const removeEvidence = ({ evidenceEdges, totalCount, removedEvidence }) => {
  const updatedEdges = _.filter(
    evidenceEdges,
    ({ node }) => !_.includes(removedEvidence, _.get(node, "id", ""))
  );

  const updatedTotalCount = totalCount - _.size(removedEvidence);

  return { evidenceEdges: updatedEdges, totalCount: updatedTotalCount };
};

export const removeEvidenceFromCachedData = ({ data, removedEvidence }) => {
  const { evidenceEdges, totalCount } = getEvidenceAndTotalCount({ data });

  const {
    evidenceEdges: updatedEdges,
    totalCount: updatedTotalCount,
  } = removeEvidence({ evidenceEdges, totalCount, removedEvidence });

  return updateEvidenceAndTotalCountInEvidenceData({
    data,
    evidenceEdges: updatedEdges,
    totalCount: updatedTotalCount,
  });
};
