import React from "react";
import { I18nHOC } from "UIComponents";
import classes from "./CommunityOverview.scss";
import { CLOUD_URL, colors } from "Constants";
import { compose } from "react-apollo";

const options = ({ t }) => [
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_choice.svg`,
    title: t("community:community_overview_1_title"),
    content: t("community:community_overview_1_content"),
    color: colors.salmon60,
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_voice.svg`,
    title: t("community:community_overview_2_title"),
    content: t("community:community_overview_2_content"),
    color: colors.violet63,
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_agency.svg`,
    title: t("community:community_overview_3_title"),
    content: t("community:community_overview_3_content"),
    color: colors.blue29,
  },
];

const CommunityOverview = ({ t }) => {
  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          {t("community:public_access_community_overview")}
        </div>

        <div className={classes.itemsContainer}>
          {_.map(options({ t }), ({ url, title, content, color }, index) => (
            <div key={index} className={classes.item}>
              <div className={classes.imageContainer}>
                <img src={url} />
              </div>
              <div className={classes.itemTitle} style={{ color }}>
                {title}
              </div>
              <div className={classes.itemContent}>{content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default compose(I18nHOC({ resource: ["community", "common"] }))(
  CommunityOverview
);
