import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  goToRelativeRoute,
  getPrintFile,
  setProgress,
  removeProgressElement,
} from "modules/Services";
import {
  updateStudentAssessmentEvaluationMutation,
  createAssessmentEvaluationMutation,
  deleteAssessmentEvaluationMutation,
  handleAssessmentToolResponseMutation,
  updateAssessmentEvaluationMutation,
} from "./AssessmentEvaluationMutation";
import {
  getStudentAssessmentEvaluationFromCache,
  writeStudentAssessmentEvaluationInCache,
  getCourseAssessmentEvaluationFeedFromCache,
  writeCourseAssessmentEvaluationFeed,
  getCourseAssessmentEvaluationCountFromCache,
  writeCourseAssessmentEvaluationCount,
} from "./AssessmentEvaluationGraphqlHelpers";
import { replace, push } from "react-router-redux";
import {
  createIBPYPElementRatingsMutation,
  deleteMultipleIBPYPElementRatingsMutation,
  updateStudentAssessmentMarksMutation,
} from "modules/CommonMutations";
import { createPost } from "Post/modules/PostModule";
import client from "apolloClient";
import { generateRandomId } from "Utils";
import { downloadFromUrl } from "modules/Services";
import {
  getStudentAssignmentDetailsFromCache,
  writeStudentAssignmentDetailsInCache,
} from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import {
  getAssessedDataForProjectFromCache,
  writeAssessedDataForProjectInCache,
} from "Projects/routes/ProjectDetails/routes/Assessment/modules/GraphqlHelpers";

export const NAME = "assessmentEvaluation";

export const UPDATE_ASSESSMENT_FILTERS =
  "UPDATE_ASSESSMENT_FILTERS" + " " + NAME;
export const UPDATE_STATE = "UPDATE_STATE" + " " + NAME;
export const INIT_ASSESSMENT_FILTERS = "INIT_ASSESSMENT_FILTERS" + " " + NAME;
export const UPDATE_RESOURCE_ITEMS = "UPDATE_RESOURCE_ITEMS" + " " + NAME;

export const updateAssessmentFilters = data => {
  return {
    type: UPDATE_ASSESSMENT_FILTERS,
    data,
  };
};

export const initAssessmentFilters = () => {
  return {
    type: INIT_ASSESSMENT_FILTERS,
  };
};

export const updateResourceItems = data => {
  return {
    type: UPDATE_RESOURCE_ITEMS,
    data,
  };
};

export const updateState = data => {
  return {
    type: UPDATE_STATE,
    data,
  };
};

export const updateRemark = ({ id, value }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: updateStudentAssessmentEvaluationMutation,
        variables: {
          id,
          updatedBy: getState().login.userInfo.id,
          remark: value,
        },
      });
    } catch (e) {
      //console.log(e);
    }
  };
};

export const updateSubmittedByStudent = ({ id, value = false } = {}) => {
  return async (dispatch, getState) => {
    const studentEvaluationDetails = getStudentAssessmentEvaluationFromCache({
      studentEvaluationId: id,
    });
    const updatedStudentEvaluationDetails = {
      ...studentEvaluationDetails,
      submittedByStudent: value,
    };
    writeStudentAssessmentEvaluationInCache({
      studentEvaluationId: id,
      data: updatedStudentEvaluationDetails,
    });
    try {
      await client.mutate({
        mutation: updateStudentAssessmentEvaluationMutation,
        variables: {
          id,
          updatedBy: getState().login.userInfo.id,
          submittedByStudent: value,
        },
      });
    } catch (e) {
      writeStudentAssessmentEvaluationInCache({
        studentEvaluationId: id,
        data: studentEvaluationDetails,
      });
      //console.log(e);
    }
  };
};

export const toggleStudentAssessmentEvaluation = ({
  id,
  isEnable,
  addedStudents = [],
  removedStudents = [],
}) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: updateAssessmentEvaluationMutation,
        variables: {
          id,
          updatedBy: getState().login.userInfo.id,
          isEvaluatedByStudent: isEnable,
          removedStudents,
          addedStudents,
        },
      });
    } catch (e) {
      //console.log(e);
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }

      throw e;
    }
  };
};

export const updateRemarkInLocal = ({ id, value }) => {
  return async (dispatch, getState) => {
    const studentAssessmentEvaluation = getStudentAssessmentEvaluationFromCache(
      {
        studentEvaluationId: id,
      }
    );
    writeStudentAssessmentEvaluationInCache({
      studentEvaluationId: id,
      data: { ...studentAssessmentEvaluation, remark: value },
    });
  };
};

export const changeSelectedStudent = ({ id, currentId }) => {
  return (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({
        route: id,
        type: "replace",
        replacePath: currentId,
      })
    );
  };
};

export const goToInsight = ({ id }) => {
  return (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({ route: `insight`, type: "push", replacePath: id })
    );
  };
};

export const goBackToStudentEvaluationDetails = ({ id }) => {
  return (dispatch, getState) => {
    dispatch(goToRelativeRoute({ route: `../`, type: "replace" }));
  };
};

export const backToEvaluationDetails = () => {
  return (dispatch, getState) => {
    dispatch(goToRelativeRoute({ route: `..`, type: "replace" }));
  };
};

export const goToEvaluationDetails = id => {
  return (dispatch, getState) => {
    dispatch(goToRelativeRoute({ route: `${id}` }));
  };
};

export const downloadEvaluation = ({ ids, fileName }) => {
  return async (dispatch, getState) => {
    const uploadId = generateRandomId();
    const attachment = {
      type: "FILE",
      url: ``,
      mimeType: "application/pdf",
      name: fileName,
    };
    setTimeout(() => {
      dispatch(
        setProgress({ id: uploadId, attachment: attachment, status: "SAVE" })
      );
    });
    const fileUrl = await dispatch(
      getPrintFile({
        id: _.join(ids, ","),
        type: "assessmentevaluation",
        shouldOpenInWindow: false,
      })
    );
    if (fileUrl) {
      dispatch(
        downloadFromUrl({
          fileUploadId: uploadId,
          attachment: {
            ...attachment,
            url: fileUrl,
          },
          shouldAskForSave: true,
        })
      );
    }
    dispatch(setProgress({ id: uploadId, status: "DONE" }));
    setTimeout(() => {
      dispatch(removeProgressElement({ id: uploadId }));
    }, 3000);
  };
};

export const publishEvaluation = ({ evaluationData = [] }) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    _.map(evaluationData, async item => {
      const {
        studentEvaluationId,
        studentId,
        assessmentId,
        unitPlanId,
        firstName,
        url = "",
      } = item;
      const uploadId = generateRandomId();
      const attachment = {
        type: "FILE",
        url: url,
        mimeType: "application/pdf",
        name: `${firstName}'s Assessment Evaluation`,
      };
      try {
        setTimeout(() => {
          dispatch(
            setProgress({
              id: uploadId,
              attachment: attachment,
              status: "SAVE",
            })
          );
        });

        const fileUrl = url
          ? url
          : await dispatch(
              getPrintFile({
                type: "assessmentevaluation",
                id: studentEvaluationId,
                shouldOpenInWindow: false,
                throwErrorEnabled: true,
              })
            );
        if (!fileUrl) {
          return;
        }

        const postDetails = {
          title: "",
          attachments: [
            {
              ...attachment,
              url: fileUrl,
            },
          ],
          students: { edges: [{ node: { id: studentId } }] },
          unitPlan: { id: unitPlanId },
          resourceItem: { id: assessmentId },
          resourceType: "ASSESSMENT",
          elementPYP: undefined,
          state: "PUBLISHED",
          isPrivate: false,
          courseId,
        };

        await dispatch(createPost({ postDetails, fromJournal: false }));
        dispatch(setProgress({ id: uploadId, status: "DONE" }));
        setTimeout(() => {
          dispatch(removeProgressElement({ id: uploadId }));
        }, 3000);
      } catch {
        setTimeout(() => {
          dispatch(removeProgressElement({ id: uploadId }));
        }, 3000);
      }
    });
  };
};
export const toggleStudentCompletedStatus = ({ id, isComplete }, callback) => {
  return async (dispatch, getState) => {
    const studentEvaluationDetails = getStudentAssessmentEvaluationFromCache({
      studentEvaluationId: id,
    });
    const updatedStudentEvaluationDetails = {
      ...studentEvaluationDetails,
      isComplete,
    };
    let isActive;
    if (!isComplete) {
      isActive = true;
      updatedStudentEvaluationDetails["isActive"] = true;
    }

    writeStudentAssessmentEvaluationInCache({
      studentEvaluationId: id,
      data: updatedStudentEvaluationDetails,
    });
    try {
      await client.mutate({
        mutation: updateStudentAssessmentEvaluationMutation,
        variables: {
          id,
          isComplete,
          isActive,
          updatedBy: getState().login.userInfo.id,
        },
      });
    } catch (error) {
      callback({ id });
      writeStudentAssessmentEvaluationInCache({
        studentEvaluationId: id,
        data: studentEvaluationDetails,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const toggleStudentActiveStatus = ({ id, isActive }, callback) => {
  return async (dispatch, getState) => {
    const studentEvaluationDetails = getStudentAssessmentEvaluationFromCache({
      studentEvaluationId: id,
    });
    const updatedStudentEvaluationDetails = {
      ...studentEvaluationDetails,
      isActive,
    };
    const isComplete = !isActive;
    updatedStudentEvaluationDetails["isComplete"] = isComplete;
    updatedStudentEvaluationDetails["isActive"] = isActive;

    writeStudentAssessmentEvaluationInCache({
      studentEvaluationId: id,
      data: updatedStudentEvaluationDetails,
    });

    try {
      await client.mutate({
        mutation: updateStudentAssessmentEvaluationMutation,
        variables: {
          id,
          isComplete,
          isActive,
          updatedBy: getState().login.userInfo.id,
        },
      });
    } catch (error) {
      callback({ id });
      writeStudentAssessmentEvaluationInCache({
        studentEvaluationId: id,
        data: studentEvaluationDetails,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const toggleStudentPypEvaluateStatus = ({
  id,
  evaluateIBPYPElements,
}) => {
  return async (dispatch, getState) => {
    const studentEvaluationDetails = getStudentAssessmentEvaluationFromCache({
      studentEvaluationId: id,
    });

    const updatedStudentEvaluationDetails = {
      ...studentEvaluationDetails,
      evaluateIBPYPElements,
    };
    if (!evaluateIBPYPElements) {
      updatedStudentEvaluationDetails["ibPYPElementRatings"] = [];
    }
    writeStudentAssessmentEvaluationInCache({
      studentEvaluationId: id,
      data: updatedStudentEvaluationDetails,
    });

    try {
      await client.mutate({
        mutation: updateStudentAssessmentEvaluationMutation,
        variables: {
          id,
          evaluateIBPYPElements,
          updatedBy: getState().login.userInfo.id,
        },
      });
    } catch (error) {
      writeStudentAssessmentEvaluationInCache({
        studentEvaluationId: id,
        data: studentEvaluationDetails,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const getAssessmentFilter = ({ filter, userId }) => {
  const { user, status, units, searchText } = filter;
  return {
    unitPlanIds: units != "ALL" ? units : undefined,
    state: status,
    userIds: user == "MY" ? userId : undefined,
    searchText,
  };
};

export const deleteAssessmentEvaluation = evalutationId => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const userId = getState().login.userInfo.id;
    const assessmentFilters = getState().assessmentEvaluation.assessmentFilters;
    const courseDetails = getCourseAssessmentEvaluationFeedFromCache({
      courseId,
      ...getAssessmentFilter({ filter: assessmentFilters, userId }),
    });

    const assessmentEvaluationCount = getCourseAssessmentEvaluationCountFromCache(
      {
        courseId,
      }
    );

    try {
      await client.mutate({
        mutation: deleteAssessmentEvaluationMutation,
        variables: {
          id: evalutationId,
          deletedBy: userId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteAssessmentEvaluation: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteAssessmentEvaluation },
            },
          }
        ) => {
          if (deleteAssessmentEvaluation) {
            const assessmentEvaluations = _.get(
              courseDetails,
              "assessmentEvaluations",
              {}
            );
            const evaluationEdges = _.filter(
              _.get(assessmentEvaluations, "edges", []),
              item => item.node.id != evalutationId
            );

            const updatedCourseDetails = {
              ...courseDetails,
              assessmentEvaluations: {
                ...assessmentEvaluations,
                edges: evaluationEdges,
                totalCount: evaluationEdges.length,
              },
            };

            const updatedCount = {
              ...assessmentEvaluationCount,
              assessmentEvaluations: {
                ...assessmentEvaluationCount.assessmentEvaluations,
                totalCount:
                  _.get(
                    assessmentEvaluationCount,
                    `assessmentEvaluations.totalCount`,
                    0
                  ) - 1,
              },
            };

            setTimeout(() => {
              writeCourseAssessmentEvaluationFeed({
                courseId,
                ...getAssessmentFilter({ filter: assessmentFilters, userId }),
                data: updatedCourseDetails,
              });
              writeCourseAssessmentEvaluationCount({
                courseId,
                data: updatedCount,
              });
            });
          }
        },
      });
    } catch (err) {
      setTimeout(() => {
        writeCourseAssessmentEvaluationFeed({
          courseId,
          ...getAssessmentFilter({ filter: assessmentFilters, userId }),
          data: courseDetails,
        });
        writeCourseAssessmentEvaluationCount({
          courseId,
          data: assessmentEvaluationCount,
        });
      });
      if (err.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: err.message, notString: true }));
      }
    }
  };
};

export const createAssessmentEvaluation = () => {
  return async (dispatch, getState) => {
    dispatch(initAssessmentFilters());
    const courseId = getState().teacher.selected_class.selected_course;
    const userId = getState().login.userInfo.id;
    const assessmentFilters = getState().assessmentEvaluation.assessmentFilters;

    const courseDetails = getCourseAssessmentEvaluationFeedFromCache({
      courseId,
      ...getAssessmentFilter({ filter: assessmentFilters, userId }),
    });

    const assessmentEvaluationCount = getCourseAssessmentEvaluationCountFromCache(
      {
        courseId,
      }
    );

    const assessmentId = _.isEmpty(
      getState().assessmentEvaluation.resourceItems.selectedAssessments
    )
      ? null
      : getState().assessmentEvaluation.resourceItems.selectedAssessments[0];

    const includedStudents = _.isEmpty(
      getState().assessmentEvaluation.resourceItems.includedStudents
    )
      ? null
      : getState().assessmentEvaluation.resourceItems.includedStudents;

    if (!assessmentId) {
      return;
    }

    try {
      await client.mutate({
        mutation: createAssessmentEvaluationMutation,
        variables: {
          assessmentId: assessmentId,
          includedStudents: includedStudents,
          courseId: courseId,
          createdBy: userId,
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //   planner: {
        //     __typename: "PlannerMutations",
        //     createAssessmentToolResponse: {
        //       id: generateRandomId(),
        //       rowId,
        //       columnId,
        //       __typename: "AssessmentToolResponse"
        //     }
        //   }
        // },
        update: (
          cache,
          {
            data: {
              planner: { createAssessmentEvaluation },
            },
          }
        ) => {
          const assessmentEvaluations = _.get(
            courseDetails,
            "assessmentEvaluations",
            {}
          );
          let evaluationEdges = _.get(assessmentEvaluations, "edges", []);
          evaluationEdges = [
            {
              node: createAssessmentEvaluation,
              __typename: "AssessmentEvaluationEdge",
            },

            ...evaluationEdges,
          ];
          const totalCount = _.get(assessmentEvaluations, "totalCount", 0) + 1;
          const updatedCourseDetails = {
            ...courseDetails,
            assessmentEvaluations: {
              ...assessmentEvaluations,
              edges: evaluationEdges,
              totalCount,
            },
          };

          const updatedCount = {
            ...assessmentEvaluationCount,
            assessmentEvaluations: {
              ...assessmentEvaluationCount.assessmentEvaluations,
              totalCount:
                _.get(
                  assessmentEvaluationCount,
                  `assessmentEvaluations.totalCount`,
                  0
                ) + 1,
            },
          };

          setTimeout(() => {
            if (!_.isEmpty(courseDetails)) {
              writeCourseAssessmentEvaluationFeed({
                courseId,
                ...getAssessmentFilter({ filter: assessmentFilters, userId }),
                data: updatedCourseDetails,
              });
            }

            writeCourseAssessmentEvaluationCount({
              courseId,
              data: updatedCount,
            });
          });
        },
      });
    } catch (err) {
      setTimeout(() => {
        if (!_.isEmpty(courseDetails)) {
          writeCourseAssessmentEvaluationFeed({
            courseId,
            ...getAssessmentFilter({ filter: assessmentFilters, userId }),
            data: courseDetails,
          });
        }

        writeCourseAssessmentEvaluationCount({
          courseId,
          data: assessmentEvaluationCount,
        });
      });
      if (err.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        const graphQLErrors = _.get(err, `graphQLErrors`, []);

        if (
          !_.isEmpty(graphQLErrors) &&
          graphQLErrors[0].message == "DUP_OBJ"
        ) {
          dispatch(
            setToastMsg("toastMsgs:assessment_already_added_for_evaluation")
          );
        } else {
          dispatch(setToastMsg("toastMsgs:something_went_wrong"));
        }
      }

      throw err;
    }
  };
};

export const createPypElementRating = ({
  parentId,
  parentType,
  studentId,
  studentIds = [],
  studentEvaluationId,
  criteriaValue,
  type,
  id,
  setId,
  isPublished,
  subjectId,
  score,
  academicCriteriaValue: updatedAcademicCriteriaValue,
  criteriaSet,
  criteriaLabel,
}) => {
  if (studentIds.length == 0) {
    studentIds = [studentId];
  }
  return async (dispatch, getState) => {
    let assessmentToolEvaluationDetails;

    const academicCriteriaSet = !_.isEmpty(criteriaSet)
      ? {
          ...criteriaSet,
          __typename: "AcademicCriteriaSet",
        }
      : {
          id: setId,
          __typename: "AcademicCriteriaSet",
          label: "",
          academicCriteriaValues: [],
          grades: [],
          criteriaType: "",
        };

    const academicCriteriaValue = !_.isEmpty(updatedAcademicCriteriaValue)
      ? { ...updatedAcademicCriteriaValue, __typename: "AcademicCriteriaValue" }
      : {
          id: criteriaValue,
          label: criteriaLabel,
          abbreviation: "",
          description: "",
          color: "",
          __typename: "AcademicCriteriaValue",
        };

    const newPypElementRating = {
      id: generateRandomId(),
      ibPYPElementId: id,
      ibPYPElementType: type,
      value: null,
      academicCriteriaSet: academicCriteriaSet,
      academicCriteriaValue: academicCriteriaValue,
      __typename: "IBPYPElementRating",
    };

    const elementRating = {
      parentId,
      parentType,
      academicCriteriaValueId: criteriaValue,
      academicCriteriaSetId: setId,
      ibPYPElementId: id,
      ibPYPElementType: type,
      createdBy: getState().login.userInfo.id,
      isPublished,
      subjectId,
    };

    if (score) {
      elementRating.value = score;
    }

    const elementRatings = [];
    _.map(studentIds, studentId => {
      elementRatings.push({
        studentId: studentId,
        ...elementRating,
      });
    });
    try {
      await client.mutate({
        mutation: createIBPYPElementRatingsMutation,
        variables: {
          elementRatings,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createIBPYPElementRatings: [newPypElementRating],
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createIBPYPElementRatings },
            },
          }
        ) => {
          setTimeout(() => {
            if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
              assessmentToolEvaluationDetails = getStudentAssessmentEvaluationFromCache(
                {
                  studentEvaluationId: parentId,
                }
              );
            } else if (parentType == "PROJECT") {
              assessmentToolEvaluationDetails = getAssessedDataForProjectFromCache(
                {
                  id: parentId,
                }
              );
              isPublished = true;
            } else {
              assessmentToolEvaluationDetails = getStudentAssignmentDetailsFromCache(
                {
                  id: parentId,
                  showOnlySubmitted: false,
                  userFilter:
                    getState().login.userInfo.user_type == "staff"
                      ? "ALL"
                      : "SELF",
                }
              );
            }
            const pypElementRatings = _.get(
              assessmentToolEvaluationDetails,
              "ibPYPElementRatings",
              []
            );
            let updatedElementRatings = _.filter(
              pypElementRatings,
              rating =>
                !(
                  rating.ibPYPElementId == id &&
                  rating.ibPYPElementType == type &&
                  rating.academicCriteriaSet.id == setId
                )
            );
            updatedElementRatings = [
              ...updatedElementRatings,
              ...createIBPYPElementRatings,
            ];
            if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
              writeStudentAssessmentEvaluationInCache({
                studentEvaluationId: parentId,
                data: {
                  ...assessmentToolEvaluationDetails,
                  ibPYPElementRatings: updatedElementRatings,
                },
              });
            } else if (parentType == "PROJECT") {
              writeAssessedDataForProjectInCache({
                variables: { id: parentId },
                data: {
                  node: {
                    ...assessmentToolEvaluationDetails,
                    ibPYPElementRatings: updatedElementRatings,
                  },
                },
              });
            } else {
              writeStudentAssignmentDetailsInCache({
                id: parentId,
                showOnlySubmitted: false,
                userFilter:
                  getState().login.userInfo.user_type == "staff"
                    ? "ALL"
                    : "SELF",
                data: {
                  ...assessmentToolEvaluationDetails,
                  ibPYPElementRatings: updatedElementRatings,
                },
              });
            }
          });
        },
      });
    } catch (error) {
      setTimeout(() => {
        if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
          writeStudentAssessmentEvaluationInCache({
            studentEvaluationId: parentId,
            data: assessmentToolEvaluationDetails,
          });
        } else if (parentType == "PROJECT") {
          writeAssessedDataForProjectInCache({
            variables: { id: parentId },
            data: {
              node: {
                ...assessmentToolEvaluationDetails,
              },
            },
          });
        } else {
          writeStudentAssignmentDetailsInCache({
            id: parentId,
            showOnlySubmitted: false,
            userFilter:
              getState().login.userInfo.user_type == "staff" ? "ALL" : "SELF",
            data: assessmentToolEvaluationDetails,
          });
        }
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const deletePypElementRating = ({
  studentIds = [],
  studentId,
  id,
  type,
  setId,
  parentType,
  parentId,
}) => {
  if (studentIds.length == 0) {
    studentIds = [studentId];
  }
  return async (dispatch, getState) => {
    let assessmentToolEvaluationDetails;
    if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
      assessmentToolEvaluationDetails = getStudentAssessmentEvaluationFromCache(
        {
          studentEvaluationId: parentId,
        }
      );
    } else if (parentType == "PROJECT") {
      assessmentToolEvaluationDetails = getAssessedDataForProjectFromCache({
        id: parentId,
      });
    } else {
      assessmentToolEvaluationDetails = getStudentAssignmentDetailsFromCache({
        id: parentId,
        showOnlySubmitted: false,
        userFilter:
          getState().login.userInfo.user_type == "staff" ? "ALL" : "SELF",
      });
    }

    const pypElementRatings = _.get(
      assessmentToolEvaluationDetails,
      "ibPYPElementRatings",
      []
    );

    const input = {
      studentId,
      parentId,
      parentType,
      academicCriteriaSetId: setId,
      ibPYPElementId: id,
      ibPYPElementType: type,
    };

    const inputArray = [];

    _.map(studentIds, studentId => {
      inputArray.push({
        ...input,
        studentId: studentId,
      });
    });

    try {
      await client.mutate({
        mutation: deleteMultipleIBPYPElementRatingsMutation,
        variables: {
          input: inputArray,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteIBPYPElementRatings: true,
            deleteMultipleIBPYPElementRatings: true,
          },
        },
        update: () => {
          const updatedElementRatings = _.filter(
            pypElementRatings,
            rating =>
              !(
                rating.ibPYPElementId == id &&
                rating.ibPYPElementType == type &&
                rating.academicCriteriaSet.id == setId
              )
          );
          setTimeout(() => {
            if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
              writeStudentAssessmentEvaluationInCache({
                studentEvaluationId: parentId,
                data: {
                  ...assessmentToolEvaluationDetails,
                  ibPYPElementRatings: updatedElementRatings,
                },
              });
            } else if (parentType == "PROJECT") {
              writeAssessedDataForProjectInCache({
                variables: { id: parentId },
                data: {
                  node: {
                    ...assessmentToolEvaluationDetails,
                    ibPYPElementRatings: updatedElementRatings,
                  },
                },
              });
            } else {
              writeStudentAssignmentDetailsInCache({
                id: parentId,
                showOnlySubmitted: false,
                userFilter:
                  getState().login.userInfo.user_type == "staff"
                    ? "ALL"
                    : "SELF",
                data: {
                  ...assessmentToolEvaluationDetails,
                  ibPYPElementRatings: updatedElementRatings,
                },
              });
            }
          });
        },
      });
    } catch (error) {
      setTimeout(() => {
        if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
          writeStudentAssessmentEvaluationInCache({
            studentEvaluationId: parentId,
            data: {
              ...assessmentToolEvaluationDetails,
              ibPYPElementRatings: pypElementRatings,
            },
          });
        } else if (parentType == "PROJECT") {
          writeAssessedDataForProjectInCache({
            variables: { id: parentId },
            data: {
              node: {
                ...assessmentToolEvaluationDetails,
                ibPYPElementRatings: pypElementRatings,
              },
            },
          });
        } else {
          writeStudentAssignmentDetailsInCache({
            id: parentId,
            showOnlySubmitted: false,
            userFilter:
              getState().login.userInfo.user_type == "staff" ? "ALL" : "SELF",
            data: {
              ...assessmentToolEvaluationDetails,
              ibPYPElementRatings: pypElementRatings,
            },
          });
        }
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const handleAssessmentToolResponse = ({
  parentId,
  parentType = "STUDENT_ASSESSMENT_EVALUATION",
  rowId,
  columnId,
  type,
  subjectiveResponse = "",
}) => {
  return async (dispatch, getState) => {
    try {
      const userType = getState().login.userInfo.user_type;
      const userEntityType = getState().login.userInfo.userEntityType;
      await client.mutate({
        mutation: handleAssessmentToolResponseMutation,
        variables: {
          parentId,
          parentType,
          rowId,
          columnId,
          subjectiveResponse,
          studentAssessmentEvaluationId: 123, //remove later
          type,
          createdBy: getState().login.userInfo.id,
          creatorType: userEntityType,
        },
        update: (
          cache,
          {
            data: {
              planner: { handleAssessmentToolResponse },
            },
          }
        ) => {
          if (handleAssessmentToolResponse) {
            let assessmentToolEvaluationDetails;
            if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
              assessmentToolEvaluationDetails = getStudentAssessmentEvaluationFromCache(
                {
                  studentEvaluationId: parentId,
                }
              );
            } else {
              assessmentToolEvaluationDetails = getStudentAssignmentDetailsFromCache(
                {
                  id: parentId,
                  showOnlySubmitted: false,
                  userFilter: userType == "staff" ? "ALL" : "SELF",
                }
              );
            }

            const toolResponses = _.get(
              assessmentToolEvaluationDetails,
              "toolResponses",
              []
            );

            const currentResponse = _.find(
              toolResponses,
              response =>
                response.rowId == rowId &&
                response.columnId == columnId &&
                response.creatorType == userEntityType
            );

            if (currentResponse && _.includes(currentResponse.id, "NEW")) {
              let updatedToolResponses = _.filter(
                toolResponses,
                response =>
                  !(response.rowId == rowId && response.columnId == columnId) ||
                  response.creatorType != userEntityType
              );
              updatedToolResponses = [
                ...updatedToolResponses,
                { ...currentResponse, id: handleAssessmentToolResponse.id },
              ];

              if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
                writeStudentAssessmentEvaluationInCache({
                  studentEvaluationId: parentId,
                  data: {
                    ...assessmentToolEvaluationDetails,
                    toolResponses: updatedToolResponses,
                  },
                });
              } else {
                writeStudentAssignmentDetailsInCache({
                  id: parentId,
                  showOnlySubmitted: false,
                  userFilter: userType == "staff" ? "ALL" : "SELF",
                  data: {
                    ...assessmentToolEvaluationDetails,
                    toolResponses: updatedToolResponses,
                  },
                });
              }
            }
          }
        },
      });
    } catch (error) {
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const handleAssessmentToolResponseInCache = ({
  rowId,
  columnId,
  parentId,
  parentType,
  type,
  subjectiveResponse = "",
}) => {
  return async (dispatch, getState) => {
    const userType = getState().login.userInfo.user_type;
    const userEntityType = getState().login.userInfo.userEntityType;
    let assessmentToolEvaluationDetails;
    if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
      assessmentToolEvaluationDetails = getStudentAssessmentEvaluationFromCache(
        {
          studentEvaluationId: parentId,
        }
      );
    } else if (parentType == "PROJECT") {
      assessmentToolEvaluationDetails = getAssessedDataForProjectFromCache({
        id: parentId,
      });
    } else {
      assessmentToolEvaluationDetails = getStudentAssignmentDetailsFromCache({
        id: parentId,
        showOnlySubmitted: false,
        userFilter: userType == "staff" ? "ALL" : "SELF",
      });
    }
    const toolResponses = _.get(
      assessmentToolEvaluationDetails,
      "toolResponses",
      []
    );
    let updatedToolResponses = [];
    let id = null;
    if (type == "SINGLE_POINT_RUBRIC") {
      updatedToolResponses = _.filter(
        toolResponses,
        response =>
          !(response.rowId == rowId && response.columnId == columnId) ||
          response.creatorType != userEntityType
      );
      id = _.get(
        _.find(
          toolResponses,
          response =>
            response.rowId == rowId &&
            response.columnId == columnId &&
            response.creatorType == userEntityType
        ),
        "id",
        null
      );
    } else {
      updatedToolResponses = _.filter(
        toolResponses,
        response =>
          response.rowId != rowId || response.creatorType != userEntityType
      );
    }

    if (columnId) {
      const newResponse = {
        id: id || generateRandomId(),
        rowId,
        columnId,
        subjectiveResponse,
        creatorType: userEntityType,
        __typename: "AssessmentToolResponse",
      };
      updatedToolResponses = [...updatedToolResponses, newResponse];
    }
    if (parentType == "STUDENT_ASSESSMENT_EVALUATION") {
      writeStudentAssessmentEvaluationInCache({
        studentEvaluationId: parentId,
        data: {
          ...assessmentToolEvaluationDetails,
          toolResponses: updatedToolResponses,
        },
      });
    } else if (parentType == "PROJECT") {
      writeAssessedDataForProjectInCache({
        variables: { id: parentId },
        data: {
          node: {
            ...assessmentToolEvaluationDetails,
            toolResponses: updatedToolResponses,
          },
        },
      });
    } else {
      writeStudentAssignmentDetailsInCache({
        id: parentId,
        userFilter: userType == "staff" ? "ALL" : "SELF",
        data: {
          ...assessmentToolEvaluationDetails,
          toolResponses: updatedToolResponses,
        },
      });
    }
  };
};

export const updateStudentsScore = ({
  studentIdsWithParentId,
  value,
  shouldReturnValue = true,
}) => {
  return async (dispatch, getState) => {
    const elementRating = {
      parentType: "STUDENT_ASSIGNMENT",
      academicCriteriaValueId: "-9999",
      academicCriteriaSetId: "-9999",
      ibPYPElementType: "SCORE",
      createdBy: _.get(getState(), "login.userInfo.id", ""),
      isPublished: false,
      value,
    };

    const elementRatings = _.map(studentIdsWithParentId, data => ({
      studentId: _.get(data, "studentId", ""),
      parentId: _.get(data, "parentId", ""),
      ...elementRating,
    }));

    try {
      await client.mutate({
        mutation: shouldReturnValue
          ? createIBPYPElementRatingsMutation
          : updateStudentAssessmentMarksMutation,
        variables: {
          elementRatings,
        },
      });
    } catch (error) {}
  };
};

const REDUCER_HANDLERS = {
  [INIT_ASSESSMENT_FILTERS]: (state, action) => {
    return update(state, {
      assessmentFilters: { $set: initialState.assessmentFilters },
    });
  },
  [UPDATE_RESOURCE_ITEMS]: (state, action) => {
    const { key, value } = action.data;
    return update(state, {
      resourceItems: { [key]: { $set: value } },
    });
  },
  [UPDATE_ASSESSMENT_FILTERS]: (state, action) => {
    const { key, value } = action.data;
    return update(state, {
      assessmentFilters: { [key]: { $set: value } },
    });
  },
  [UPDATE_STATE]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        [key]: { $set: params[key] },
      });
    });
    return state;
  },
};

const initialState = {
  assessmentFilters: {
    user: "ALL",
    status: "ALL",
    units: "ALL",
    searchText: "",
  },
  resourceItems: {},
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
