import gql from "graphql-tag";
import { basicUserFragment } from "./fragments";

export const editUserMutation = gql`
  mutation editUser(
    $firstName: String
    $lastName: String
    $id: ID!
    $email: String
    $profileImage: String
    $sourceId: String
    $isArchived: Boolean
    $registrationCategory: REGISTRATION_CATEGORY_TYPE_ENUM
    $yearGroupId: ID
  ) {
    platform {
      updateUserV2(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          profileImage: $profileImage
          email: $email
          sourceId: $sourceId
          isArchived: $isArchived
          registrationCategory: $registrationCategory
          yearGroupId: $yearGroupId
        }
      ) {
        warning
        response {
          ...userItem
        }
      }
    }
  }
  ${basicUserFragment.user}
`;

export const deleteUserMutation = gql`
  mutation deleteUser($id: ID!) {
    platform {
      deleteUser(input: { id: $id }) {
        warning
        isSuccess
      }
    }
  }
`;
export const addUserMutation = gql`
  mutation addUser(
    $email: String
    $userType: USER_TYPE_ENUM!
    $courses: [ID!]
    $firstName: String
    $lastName: String
    $profileImage: String
    $designation: ID
    $sourceId: String
    $curriculumProgramId: ID
    $snpEvaluationCycles: [ID!]
    $tags: [ID!]
    $yearGroupId: ID
    $registrationCategory: REGISTRATION_CATEGORY_TYPE_ENUM
    $courseTagMap: [CourseTagMapInput!]
    $staffRelationMap: [StaffRelationMapInput!]
  ) {
    platform {
      addUser(
        input: {
          email: $email
          userType: $userType
          courses: $courses
          firstName: $firstName
          lastName: $lastName
          profileImage: $profileImage
          designation: $designation
          sourceId: $sourceId
          curriculumProgramId: $curriculumProgramId
          snpEvaluationCycles: $snpEvaluationCycles
          tags: $tags
          yearGroupId: $yearGroupId
          registrationCategory: $registrationCategory
          courseTagMap: $courseTagMap
          staffRelationMap: $staffRelationMap
        }
      ) {
        ...userItem
      }
    }
  }
  ${basicUserFragment.user}
`;

export const removeCoursesFromUserMutation = gql`
  mutation removeCourseFromUser($userId: ID!, $courseIds: [ID!]) {
    platform {
      removeCourseFromUser(userId: $userId, courseIds: $courseIds) {
        id
      }
    }
  }
`;

export const addCoursesToUserMutation = gql`
  mutation addCourseToUser(
    $userId: ID!
    $courseIds: [ID!]
    $role: COURSE_USER_ROLE_ENUM!
    $courseTagMap: [CourseTagMapInput!]
  ) {
    platform {
      addCourseToUser(
        userId: $userId
        courseIds: $courseIds
        role: $role
        courseTagMap: $courseTagMap
      ) {
        id
      }
    }
  }
`;
