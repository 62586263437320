import resolvePathname from "resolve-pathname";

const getAbsolutePath = path => {
  const arrayPath = path.split("/");
  if (arrayPath[arrayPath.length - 1] == "") {
    arrayPath.pop();
  }
  return arrayPath.join("/");
};

export const getRelativePath = (path, replacePath = null) => {
  let currentLocation = window.location.pathname;
  if (replacePath) {
    let paths = currentLocation.split("/");
    const index = _.lastIndexOf(paths, replacePath);
    paths = paths.splice(0, index);
    currentLocation = paths.join("/");
  }

  return getAbsolutePath(
    resolvePathname(path, `${getAbsolutePath(currentLocation)}/`)
  );
};
