/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CaretDownFilled } from "@toddle-design/web-icons";
import { Badge } from "@toddle-design/web";

/**--internal-- */
import { CheckListContainer, AnimateHeight } from "UIComponents";

/**--relative-- */
import classes from "./FilterAccordion.scss";
import { listItemStyle } from "./FilterAccordionStyles";

const FilterAccordion = props => {
  const {
    isExpanded,
    onAccordionClick,
    label,
    values,
    options,
    updateInputField,
    allOptionLabel,
    searchText,
    labelFontClass,
  } = props;

  const labelClasses = classNames(classes.label, labelFontClass);

  const accordionElement = (
    <div className={classes.accordionCaretIconContainer}>
      <CaretDownFilled size={"xx-small"} />
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.labelContainer} onClick={onAccordionClick}>
        <div className={labelClasses}>{label}</div>
        <div className={classes.labelSiblingContainer}>
          <Badge
            value={_.size(values)}
            size={"large"}
            type={"numeric"}
            variant={"filter"}
          />
          {accordionElement}
        </div>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? "auto" : 0}>
        <CheckListContainer
          value={values}
          options={options}
          showAllOption={!searchText}
          listItemStyle={listItemStyle}
          updateInputField={updateInputField}
          allOptionLabel={allOptionLabel}
          isMatchingTextEnabled={true}
          searchText={searchText}
        />
      </AnimateHeight>
    </div>
  );
};

export default FilterAccordion;

FilterAccordion.defaultProps = {
  isExpanded: false,
  labelFontClass: "text-body-1",
};

FilterAccordion.propTypes = {
  isExpanded: PropTypes.bool,
  onAccordionClick: PropTypes.func,
  searchText: PropTypes.string,
  allOptionLabel: PropTypes.string,
  updateInputField: PropTypes.func,
  options: PropTypes.array,
  values: PropTypes.array,
  label: PropTypes.string,
  labelFontClass: PropTypes.string,
};

FilterAccordion.displayName = "FilterAccordion";
