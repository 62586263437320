import client from "apolloClient";
import { getApplicableFiltersQuery, getFilterOptionsQuery } from "./Queries";
import { filterFragments } from "./Fragments";

export const getApplicableFiltersFromCache = ({ module }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getApplicableFiltersQuery,
      variables: { module },
    });
  } catch (e) {
    return queryData;
  }
  return queryData;
};

export const getFilterOptionsFromCache = ({
  entityType,
  options,
  id,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getFilterOptionsQuery,
      variables: { entityType, options, id, filters },
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

export const getSubjectDetailFromCache = ({ subjectId }) => {
  let fragmentData = {};
  try {
    fragmentData = client.readFragment({
      id: `Subject:${subjectId}`,
      fragment: filterFragments.subjectFragment,
      fragmentName: "subjectItem",
    });
  } catch (e) {
    console.error(e);
  }
  return fragmentData;
};

export const getCourseDetailFromCache = ({ courseId }) => {
  let fragmentData = {};
  try {
    fragmentData = client.readFragment({
      id: `Course:${courseId}`,
      fragment: filterFragments.courseFragment,
      fragmentName: "courseItem",
    });
  } catch (e) {
    console.error(e);
  }

  return fragmentData;
};
