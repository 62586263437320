export const GetMeetingJoinURL = _.memoize(
  params => GetMeetingJoinURLLocal(params),
  params => JSON.stringify(params)
);

const GetMeetingJoinURLLocal = ({ meeting = {}, userId }) => {
  const meetingType = _.get(meeting, "type", "");
  const meetingCreatedById = _.get(meeting, "createdBy.id", "");
  switch (meetingType) {
    case "ZOOM":
      if (userId == meetingCreatedById) {
        return _.get(meeting, "urls.startUrl", "");
      } else {
        return _.get(meeting, "urls.joinUrl", "");
      }
    case "MICROSOFT":
      return _.get(meeting, "urls.joinUrl", "");
    case "OTHER":
    case "GOOGLE":
      return _.get(meeting, "urls.url", "");
    default:
      return false;
  }
};
