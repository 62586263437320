import React, { Fragment } from "react";
import classes from "./SideBar.scss";
import classNames from "classnames";
import { Loading, I18nHOC } from "UIComponents";
import { size as _size } from "lodash";

const loadingStyle = {
  width: 20,
  height: 20,
};
class SideBar extends React.PureComponent {
  renderLevelNodes = ({ children = [], index }) => {
    const {
      nodes,
      levelIdIndex,
      updateParentId,
      levels,
      parentNodeId,
      mode,
      t,
    } = this.props;

    const showChildren = index < levelIdIndex - 1;
    const currentLevel = levels[levelIdIndex - 1];

    return _.map(children, id => {
      const childNode = _.find(nodes, item => item.id == id) || {};

      const children = _.get(childNode, "children", []);
      const labelClass = classNames(
        { [classes.labelText]: true },
        { [classes[`labelText${index}${levelIdIndex}`]]: true },
        { [classes.selectedLabelText]: parentNodeId == id }
      );

      const isAdding = _.includes(id, "NEW");
      const isClickable = !showChildren && !_.includes(id, "NEW");

      return (
        !_.isEmpty(childNode) && (
          <Fragment key={id}>
            <div
              className={classes.labelContainer}
              onClick={isClickable ? () => updateParentId(id) : null}
              style={{ cursor: !isClickable ? "default" : "pointer" }}
            >
              <div className={labelClass}>
                {childNode.label ? childNode.label : t("common:untitled")}
              </div>
              {index == levelIdIndex - 1 && mode == "edit" && !isAdding && (
                <div className={classes.rightText}>{_size(children)}</div>
              )}
              {isAdding && (
                <div className={classes.rightText}>
                  <Loading loadingStyle={loadingStyle} />
                </div>
              )}
            </div>
            {!!showChildren && (
              <div className={classes.menuContainer}>
                {_size(children) > 0 ? (
                  this.renderLevelNodes({
                    children: children,
                    index: index + 1,
                  })
                ) : (
                  <div className={classes.noText}>
                    {t("common:no_label_added", { label: currentLevel.value })}
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )
      );
    });
  };

  render() {
    const { nodes, levels, rootNode, mode } = this.props;
    const rootChildren = _.get(
      _.find(nodes, item => item.id == _.get(rootNode, "id", "")),
      "children",
      []
    );

    const rootLevelName = _.get(levels[0], "value", "");

    return (
      <div className={classes.container}>
        {mode == "view" && (
          <div className={classes.rootLevelText}>{rootLevelName}</div>
        )}
        {this.renderLevelNodes({
          children: rootChildren,
          index: 0,
        })}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(SideBar);
