import gql from "graphql-tag";

export const removeProductItemVoteMutation = gql`
  mutation removeProductItemVote($input: UpdateLikeInput!) {
    platform {
      removeLike(input: $input)
    }
  }
`;

export const upsertProductItemVoteMutation = gql`
  mutation upsertProductItemVote($input: [UpsertVoteInput!]) {
    platform {
      upsertVote(input: $input) {
        myVote
      }
    }
  }
`;

export const resetProductUpdateNewContentMutation = gql`
  mutation resetUserProductUpdateNewCount($input: ProductUpdateInput) {
    cms {
      resetUserProductUpdateNewCount(input: $input)
    }
  }
`;

export const shareProductUpdateMutation = gql`
  mutation shareProductUpdate($id: ID!, $userIds: [ID!]) {
    cms {
      shareProductUpdate(input: { id: $id, userIds: $userIds })
    }
  }
`;
