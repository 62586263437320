import React, { PureComponent } from "react";

import classes from "./Groupedview.scss";

import {
  I18nHOC,
  EmptyView,
  LockOverlay,
  BenchmarkView,
  EmptyField,
} from "UIComponents";
import { Button } from "@toddle-design/web";

import classNames from "classnames";
import { NoUnitFoundIllustration } from "@toddle-design/theme";

const emptyFieldStyle = {
  containerStyle: {
    border: 0,
  },
};
class Groupedview extends PureComponent {
  constructor(props) {
    super(props);
  }

  getEmptyComponent = () => {
    const {
      t,
      onAddNewClicked,
      parentNode,
      emptyTextSelectSubgroupLocale,
      plannerLabel,
      isLocked,
    } = this.props;
    return isLocked ? (
      <EmptyView
        emptyImageUrl={NoUnitFoundIllustration}
        emptyHeaderText={t("common:no_label_added", {
          label: "Content skills",
        })}
        size="small"
      />
    ) : (
      <EmptyField
        title={`${t(emptyTextSelectSubgroupLocale, {
          label: parentNode.label,
          plannerLabel,
        })}`}
        onAddNewClick={() => onAddNewClicked(parentNode)}
        showAdd={true}
        containerStyle={emptyFieldStyle.containerStyle}
      />
    );
  };

  render() {
    const {
      props: {
        lockedField,
        parentNode,

        rootNodeDepth,
        onAddClicked,

        t,
        isLocked = false,
        nestedGroupedValue,
        showEditButtonAtHeader,
        innerContainerStyle,
      },
    } = this;

    const mainContainerClass = classNames({
      [classes.mainContentContainer]: true,
      [classes.lockContainer]: isLocked,
    });

    return (
      <div className={classes.mainContainer}>
        {!showEditButtonAtHeader && (
          <div className={classes.headerContainer}>
            <div className={classes.headerLabel}>{parentNode.label}</div>
            {!_.isEmpty(nestedGroupedValue) && (
              <div
                onClick={() => onAddClicked(parentNode)}
                className={classes.addButton}
              >
                <Button
                  size="medium"
                  variant="progressive"
                  disabled={!!lockedField}
                >
                  {t("edit")}
                </Button>
              </div>
            )}
          </div>
        )}
        <div className={mainContainerClass}>
          {!_.isEmpty(nestedGroupedValue) ? (
            <div className={classes.innerContainer} style={innerContainerStyle}>
              <BenchmarkView
                value={nestedGroupedValue}
                startDepth={rootNodeDepth}
                mode={"view"}
              ></BenchmarkView>
            </div>
          ) : (
            this.getEmptyComponent()
          )}
          {lockedField && (
            <LockOverlay userInfo={_.get(lockedField, "userInfo", {})} />
          )}
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(Groupedview);
