import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import ProgressReportDetails from "./routes/ProgressReportDetails";

export default store => ({
  path: "progressreport",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/ProgressReportModule").default;
            injectReducer(store, { key: "progressReport", reducer });

            const adminProgressReportReducer = require("AdminProgressReports/modules/AdminProgressReportsModule")
              .default;
            injectReducer(store, {
              key: "adminProgressReport",
              reducer: adminProgressReportReducer,
            });

            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-progressreport"
        );
      })
  ),
  childRoutes: [ProgressReportDetails(store)],
});
