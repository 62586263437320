import React from "react";
import classes from "./OrganizationSelector.scss";
import { SearchBar, UILabel, withLoader, UIButton } from "UIComponents";
import { colors } from "Constants";
import {
  getOrganizationList,
  updateOrganizationListData,
} from "Login/modules/LoginModule";
import { connect } from "react-redux";
import { checkScrollAtBottom } from "services/checkScrollAtBottom";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";
const chooseButtonStyle = {
  minWidth: 80,
  height: 32,
};

const addSchoolButtonStyle = {
  minWidth: 80,
  height: 32,
};
const SearchInputStyle = {
  customStyle: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderRadius: "8px",
    borderColor: colors.strokeOne,
    padding: "16px",
  },
  activeCustomStyle: {
    borderBottomWidth: 2,
    paddingBottom: 12,
    borderColor: colors.gray48,
  },
};

const SelectorItem = ({ item: { name, city, country } = {}, onItemClick }) => {
  const addressArr = _.filter([city, country], val => !!val);
  return (
    <div className={classes.selectorItem}>
      <div className={classes.itemLeftContainer}>
        <div className={classes.schoolName}>{name}</div>
        {addressArr.length > 0 ? (
          <div className={classes.schoolAddress}>
            {_.join(addressArr, ", ")}
          </div>
        ) : null}
      </div>
      <UIButton
        color={"pink"}
        type={"filled"}
        size={"sm"}
        onClick={onItemClick}
        containerStyle={chooseButtonStyle}
      >{`Choose`}</UIButton>
    </div>
  );
};

const AddSchoolItem = ({ onAddSchoolClick }) => {
  return (
    <div className={classes.addSchoolItem}>
      <div className={classes.itemLeftContainer}>
        <div className={classes.noSchoolText}>{`Can’t find your school?`}</div>
      </div>
      <UIButton
        color={"blue"}
        type={"filled"}
        size={"sm"}
        onClick={onAddSchoolClick}
        containerStyle={addSchoolButtonStyle}
      >{`Add school`}</UIButton>
    </div>
  );
};

class SelectorList extends React.PureComponent {
  render() {
    const { items, handleScroll, isLoading, onItemClick } = this.props;

    return _.map(items, item => {
      return (
        <SelectorItem
          item={item.node}
          key={item.node.id}
          onItemClick={() => onItemClick(item.node)}
        />
      );
    });
  }
}

const SelectorListWithLoader = withLoader(SelectorList);

class OrganizationSelector extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, isFocus: false };
    if (props.customRef) {
      props.customRef(this);
    }
  }

  isValidValue = value => {
    return !_.isEmpty(value);
  };

  UNSAFE_componentWillMount = () => {
    document.addEventListener("click", this.handleDocumentMouseClick, false);
  };

  componentDidMount = () => {
    this.resetSearchText();
    const { getOrganizationList } = this.props;
    getOrganizationList();
  };

  resetSearchText = () => {
    const { updateOrganizationListData } = this.props;
    updateOrganizationListData({ searchText: "" });
    this.updateSearchText("");
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleDocumentMouseClick, false);
  };

  onFocus = e => {
    this.setState({ isFocus: true });
    const { onSearchBoxFocus } = this.props;
    if (onSearchBoxFocus) {
      onSearchBoxFocus();
    }
  };

  onItemClick = item => {
    this.updateValue(item);

    this.updateSearchText(item.name);
    this.setState({ isFocus: false });
  };

  updateSearchText = value => {
    if (this.searchBarRef) {
      this.searchBarRef.updateSearchText(value);
    }
  };

  handleDocumentMouseClick = event => {
    if (this.containerRef && this.containerRef.contains(event.target)) {
      return;
    }

    event.stopPropagation();
    this.setState({ isFocus: false });
  };

  updateContainerRef = ref => {
    this.containerRef = ref;
  };

  updateSearchBarRef = ref => {
    this.searchBarRef = ref;
  };

  changeSearchTerm = value => {
    const { getOrganizationList, updateOrganizationListData } = this.props;
    updateOrganizationListData({ searchText: value });
    this.updateValue({});
    getOrganizationList();
  };

  handleScroll = e => {
    const {
      organizationListData: {
        pageInfo: { hasNextPage = false },
        isLoading = false,
      } = {},
      getOrganizationList,
    } = this.props;
    if (hasNextPage) {
      const isBottom = checkScrollAtBottom(e.target);
      if (isBottom && !isLoading) {
        getOrganizationList();
      }
    }
  };

  renderEdit = () => {
    const {
      inputStyle: { labelStyle } = {},
      organizationListData: {
        edges: items = [],
        isLoading,
        searchText,
        pageInfo: { hasNextPage = false } = {},
      } = {},
      onAddSchoolClick,
    } = this.props;
    const { isFocus } = this.state;
    const itemLength = _.get(items, "length", 0);
    const showAddSchool = (!_.isEmpty(items) || !isLoading) && !!searchText;
    const listInnerContainerStyle = classNames(
      { [classes.listInnerContainer]: true },
      { [classes.borderShadow]: showAddSchool }
    );

    return (
      <div className={classes.container} ref={this.updateContainerRef}>
        <SearchBar
          placeholder={"Type your school name to search"}
          searchTerm={searchText}
          changeSearchTerm={this.changeSearchTerm}
          customStyle={SearchInputStyle.customStyle}
          shouldAnimate={false}
          activeCustomStyle={SearchInputStyle.activeCustomStyle}
          onFocus={this.onFocus}
          ref={this.updateSearchBarRef}
        />
        {!!isFocus && (
          <div className={classes.listContainer}>
            <div
              className={listInnerContainerStyle}
              onScroll={this.handleScroll}
            >
              <div className={classes.listItemsContainer}>
                <SelectorListWithLoader
                  items={items}
                  isData={!_.isEmpty(items)}
                  isLoading={isLoading}
                  onItemClick={this.onItemClick}
                  showLoaderBar={false}
                  loadingStyle={{ width: 20, height: 20 }}
                  containerStyle={{ minHeight: isLoading ? 160 : 0 }}
                />
              </div>
              {itemLength == 0 && !isLoading && (
                <div className={classes.noDataContainer}>
                  <div className={classes.noDataLabelText}>
                    {`No results found for ‘${searchText}’`}
                  </div>
                  <div
                    className={classes.noDataSubText}
                  >{`Try checking your spelling`}</div>
                </div>
              )}
            </div>
            {!!showAddSchool && (
              <AddSchoolItem
                onAddSchoolClick={onAddSchoolClick}
                containerStyle={addSchoolButtonStyle}
              />
            )}
          </div>
        )}
      </div>
    );
  };
}

OrganizationSelector.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

OrganizationSelector.propTypes = {
  ...UIBaseComponent.propTypes,
};

const mapActionCreators = { getOrganizationList, updateOrganizationListData };

const mapStateToProps = state => {
  return { organizationListData: state.login.organizationListData };
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(OrganizationSelector);
