import React from "react";
import PropTypes from "prop-types";
import classes from "./ResourceBank.scss";
import ResourceList from "./ResourceList";
import ResourceFooter from "./ResourceFooter";
import { UIModal, SearchBar } from "UIComponents";
import { generateRandomId, getImageDimensions, getFileName } from "Utils";

class ResourceBank extends React.Component {
  state = { scrollTopCounter: 0 };
  componentDidMount() {
    this.props.initaliseResourceBankComponent(this.props.id);
    document.addEventListener("keydown", this.handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscKey, false);
  }

  resetResourceBank = () => {
    this.props.initaliseResourceBankComponent(this.props.id);
  };

  handleEscKey = e => {
    if (e.keyCode == 27) {
      this.onCloseClick();
    }
  };

  onCloseClick = () => {
    if (this.props.closeResource) {
      this.props.closeResource();
    }
  };

  getSendObject = async ({ file, fileUrl, isLink = false, title }) => {
    const mimeType = "image/png";
    const url = isLink ? file.url : fileUrl;
    const attachmentType = "IMAGE";
    const dimensions = await getImageDimensions({ url, type: attachmentType });

    return {
      type: attachmentType,
      url: url,
      name: title,
      mimeType: mimeType,
      metadata: { size: _.get(file, "size", 0), ...dimensions },
    };
  };

  updateFileCount(count, isError = false) {
    if (this.props.updateFilesCount) {
      this.props.updateFilesCount(count, isError);
    }
  }

  onResourceDownloaded = ({ file, uploadId, thumbURL, title, downloadUrl }) => {
    const { parentId, parentType, addUnsplashAnalytics } = this.props;
    addUnsplashAnalytics({ url: downloadUrl });
    return new Promise(async resolve => {
      let sendObj = await this.getSendObject({
        file,
        fileUrl: thumbURL,
        isLink: false,
        title,
      });

      if (this.props.onDropContent) {
        this.props.onDropContent({ ...sendObj, id: uploadId });
      }

      const { promise } = this.props.uploadFile({
        file,
        uploadId,
        startProgress: 50,
        parentType: parentType,
        parentId: parentId,
        attachment: sendObj,
      });

      promise
        .then(async item => {
          sendObj = await this.getSendObject({ file, title, fileUrl: item });

          if (this.props.onContentUploaded) {
            await this.props.onContentUploaded(sendObj);
          }
          if (this.props.onSingleContentUploaded) {
            await this.props.onSingleContentUploaded(sendObj, uploadId);
          }
          this.props.removeProgressElement({ id: uploadId });
          this.updateFileCount(-1);
          resolve(sendObj);
        })
        .catch(e => {
          this.updateFileCount(-1, true);
        });
    });
  };

  onAddClick = () => {
    const { selectedResources, downloadFromUrl } = this.props;
    selectedResources.forEach(resource => {
      const fileUploadId = generateRandomId();
      this.updateFileCount(1);
      const startProgress = 50;

      const { url, thumbURL, title, downloadUrl } = resource;

      downloadFromUrl({
        downloadUrl: url,
        fileUploadId,
        startProgress,
        onLoaded: fileBlob => {
          this.onResourceDownloaded({
            file: fileBlob,
            uploadId: fileUploadId,
            thumbURL,
            startProgress,
            title,
            downloadUrl,
          });
        },
        onContentLoadFailed: async ({ isCancelled }) => {
          this.updateFileCount(-1, true);
        },
      });
    });

    if (this.props.onAddClick) {
      this.props.onAddClick(this.props.selectedResources);
    }
    this.onCloseClick();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.filters, this.props.filters)) {
      this.props.clearResourceBankSelectedResources(this.props.id);
      this.setState({ scrollTopCounter: this.state.scrollTopCounter + 1 });
    }
  }

  updateSearchTextInRedux = searchText => {
    const { id } = this.props;
    this.props.updateResourceBankFilters({ searchText }, id);
  };

  render() {
    const {
      isData,
      isLoading,
      selectedResources,
      filters,
      getResourceList,
      toggleAllResourceBankFilterItems,
      toggleResourceBankFilterItem,
      loadMoreListData,
      toggleResourceBankSelectedResources,
      id,
      closeResource,
      multi,
      t,
    } = this.props;

    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={closeResource}
      >
        <div className={classes.innerContainer}>
          {/* <div className={classes.leftContainer}>
            <ResourceFilters
              id={id}
              isData={isData}
              isLoading={isLoading}
              getResourceList={getResourceList}
              selected={filters.selected}
              toggleAllResourceBankFilterItems={
                toggleAllResourceBankFilterItems
              }
              toggleResourceBankFilterItem={toggleResourceBankFilterItem}
              onResetClick={() => {
                this.resetResourceBank();
              }}
            />
          </div> */}
          <div className={classes.rightContainer}>
            <div className={classes.searchContainer}>
              <SearchBar
                placeholder={t("search_on_unsplash")}
                searchTerm={filters.searchText}
                changeSearchTerm={this.updateSearchTextInRedux}
                shouldAnimate={false}
                wrapperType={"box"}
                searchOnEnter={true}
              />
            </div>
            <div className={classes.resourceContainer}>
              <ResourceList
                scrollTopCounter={this.state.scrollTopCounter}
                id={id}
                isData={isData}
                isLoading={isLoading}
                getResourceList={getResourceList}
                loadMoreListData={loadMoreListData}
                selectedResources={selectedResources}
                toggleSelectedResources={toggleResourceBankSelectedResources}
                multi={multi}
              />
            </div>
            <div className={classes.rightFooterContainer}>
              <ResourceFooter
                t={t}
                onCancelClick={this.onCloseClick}
                totalSelected={selectedResources.length}
                onAddClick={this.onAddClick}
              />
            </div>
          </div>
        </div>
      </UIModal>
    );
  }
}

export default ResourceBank;

ResourceBank.defaultProps = {
  id: "default",
  multi: true,
};

ResourceBank.propTypes = {
  id: PropTypes.string,
  onAddClick: PropTypes.func,
  multi: PropTypes.bool,
};
