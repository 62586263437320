import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, SelectDropdown, UIButton, JsonEditor } from "UIComponents";
import classes from "./TemplateEditor.scss";

import { withPermission } from "lib/PermissionAware";
import { goToBack } from "modules/NavigationModule";
import { getOrganizationPlannerTemplateQuery } from "../../modules/ToolingQueries";
import { noop as _noop } from "lodash";

const updateButton = {
  borderRadius: 0,
  margin: "32px 0px 0px 16px",
};

const htmlElementProps = {
  style: {
    display: "flex",
    flexShrink: 0,
    width: "100%",
    maxHeight: "520px",
    minHeight: "520px",
  },
};
class TemplateEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: null,
      templateBody: {},
      renderEditor: true,
      editorMode: "tree",
    };
  }

  handleChildEditorMount = isRenderEditor => {
    this.setState({ renderEditor: isRenderEditor });
  };

  handleDropDownSelection = selectedTemplate => {
    const templateBody = this.props.allTemplateData[selectedTemplate.uid].body;
    this.setState({
      selectedTemplate: selectedTemplate.uid,
      templateBody,
      renderEditor: false,
    });
  };

  getTemplateName = templateId => {
    return templateId ? this.props.allTemplateData[templateId].name : null;
  };

  handleJsonFormatError = error => {
    console.error(error);
  };

  getTemplateValues = () => {
    return _.reduce(
      this.props.allTemplateData,
      (result, template) => {
        result.push({
          value: template.id,
          label: `${template.name} - ${
            (template.type == "UNIT_PLAN" && template.unitType) || ""
          }`,
        });
        return result;
      },
      []
    );
  };

  render() {
    const {
      selectedTemplate,
      renderEditor,
      editorMode,
      templateBody,
    } = this.state;

    return (
      <div className={classes.body}>
        <div className={classes.header}>
          <div className={classes.dropDownContainer}>
            <SelectDropdown
              value={selectedTemplate}
              label="Select a template"
              //ref={"uid"}
              name="uid"
              areOptionsEmptyAllowed={false}
              mode={"new"}
              placeholder="Name of Template"
              options={this.getTemplateValues()}
              updateInputField={this.handleDropDownSelection}
              error="Unit name is required"
              isDisabled={false}
            />
          </div>
          <div className={classes.buttonContainer}>
            {/* <UIButton
              color="blue"
              containerStyle={changeEditModeButton}
              onClick={this.changeEditMode}
            >
              {changeEditModeButtonText}
            </UIButton>
            <UIButton
              color="blue"
              isDisabled={disableUpdateButton}
              containerStyle={changeEditModeButton}
              onClick={this.updateTemplate}
            >
              {"Update"}
            </UIButton> */}
            <UIButton
              color="blue"
              containerStyle={updateButton}
              onClick={this.props.goToBack}
            >
              {"Exit"}
            </UIButton>
          </div>
        </div>

        <div className={classes.container}>
          <JsonEditor
            parentChildMount={this.handleChildEditorMount}
            renderEditor={renderEditor}
            value={templateBody}
            onChange={_noop}
            htmlElementProps={htmlElementProps}
            name={this.getTemplateName(selectedTemplate)}
            mode={editorMode}
            onError={this.handleJsonFormatError}
          />
        </div>
      </div>
    );
  }
}

const mapActionCreators = {
  goToBack,
};

const mapStateToProps = (state, ownProps) => {
  const id = state.login.userInfo.org_id;
  return {
    id,
    isData: true,
    isLoading: false,
    perm: "ToolPortal:ManageTemplates",
    mode: ownProps.mode,
  };
};

// export default TemplateEditor;
export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationPlannerTemplateQuery, {
    name: "getOrganizationPlannerTemplate",
    options: () => ({
      fetchPolicy: "cache-and-network",
      variables: {},
    }),
    props({ getOrganizationPlannerTemplate, ownProps: { isData, isLoading } }) {
      return {
        allTemplateData: _.reduce(
          _.get(
            getOrganizationPlannerTemplate,
            "platform.organization.plannerTemplate",
            {}
          ),
          (result, templateData) => {
            result[templateData.id] = templateData;
            return result;
          },
          {}
        ),
        isData: getOrganizationPlannerTemplate.platform && isData,
        isLoading:
          getOrganizationPlannerTemplate["networkStatus"] == 1 ||
          getOrganizationPlannerTemplate["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader,
  withPermission
)(TemplateEditor);
