import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classes from "./MiddlePane.scss";
import classNames from "classnames";
import { getElementValue, ASSESSMENT_TOOLS } from "modules/Services";
import { ViewHeader } from "UnitPlans/components/LibraryCommonComponents";
import { FieldComponent } from "UnitPlans/components/IBcomponents";
import LabelComponent from "UnitPlans/components/LabelComponent";
import { PrintIcon, LeftIcon } from "SvgComponents";
import { fontStyle, colors } from "Constants";
import { getMYPObjectiveRubricOptions } from "modules/Services";
import { getSubFields } from "UnitPlans/modules/UnitPlanModule";
import {
  LinkWithTooltip,
  MediaModal,
  I18nHOC,
  TagViewContainer,
} from "UIComponents";
import { scrollToWithAnimation } from "Utils";
import Footer from "../Footer";
import { CommentView } from "AppComponents/Community";

import { compose } from "react-apollo";
import { withRouter } from "react-router";
import { PAComponent } from "lib/PermissionAware";
import { PublicAccessWidgets } from "AppComponents";
import { Header } from "AppComponents/Community";
import { TableOutlined } from "@toddle-design/web-icons";
import { getShowAssessmentTool } from "ClassRoom/modules/utils";

const ASSESSMENT_TOOLS_TYPES = [
  "Exemplar",
  "Rubrics",
  "Anecdotal",
  "AssessmentSinglePointRubric",
  "AssessmentChecklist",
  "AssessmentTool",
];

const commentBoxStyles = {
  commentContainerStyle: { height: "max-content" },
  commentBoxContainerStyle: {
    marginRight: "70px",
    width: "auto",
  },
  replyCommentBoxContainerStyle: {
    paddingRight: "70px",
    width: "calc(100% - 70px - 62px)",
  },
  commentItemStyle: {
    moreContainer: {
      marginRight: "70px",
    },
  },
};

const fieldLabelStyle = {
  ...fontStyle.bold,
  fontSize: "1.8rem",
  marginBottom: "8px",
  color: colors.pink39,
};

const fieldLabelStyleRevamp = {
  ...fontStyle.demiBold,
  fontSize: "1.6rem",
  marginBottom: "8px",
  color: colors.textDefault,
};

const textViewcustomTextStyle = {
  fontSize: "1.6rem",
  ...fontStyle.medium,
  color: colors.textSubtle,
};

const AssessmentToolTypeLabel = ({ value, fieldKey }) =>
  fieldKey === "measureAssessing" ? (
    <div className={classes.assessmentToolTypeContainer}>
      <TableOutlined />
      <span className={classes.assessmentToolTypeValue}>{value}</span>
    </div>
  ) : null;

class MiddlePane extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = null;
    this.state = {};
    this.Refs = {};
    this.commentBoxRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    const viewSection = _.get(location, "query.section");
    if (viewSection === "comments")
      setTimeout(this.scrollToCommentSection, 500);
  }

  getAssessmentToolLabel = () => {
    const { t } = this.props;
    return t(
      _.get(
        _.find(ASSESSMENT_TOOLS, { type: this.getAssessmentToolValue() }),
        "locale"
      )
    );
  };

  getPrintButton = ({ stepKey }) => {
    if (stepKey != "assessmentTool") {
      return null;
    }
    const { onClickPrint, resourceDetails, t, isCommunity } = this.props;
    const toolValue = this.getAssessmentToolValue();

    const resourceTitle = _.get(resourceDetails, "title.value", "");
    const assessmentToolKey = _.get(
      _.find(ASSESSMENT_TOOLS, { type: toolValue }),
      "key"
    );
    const resourceId = _.get(resourceDetails, "id", "");
    const assessmentToolId = _.get(resourceDetails, "assessmentTool.id", null);
    return !isCommunity &&
      _.get(_.find(ASSESSMENT_TOOLS, { type: toolValue }), "canPrint") &&
      !!_.get(resourceDetails, "assessmentTool.id", null) ? (
      <LinkWithTooltip
        tooltip={t("common:print_with_label", {
          label: this.getAssessmentToolLabel(),
        })}
        href="#"
        placement="top"
      >
        <span
          className={classes.print}
          onClick={() =>
            onClickPrint({
              type: _.get(
                _.find(ASSESSMENT_TOOLS, { type: toolValue }),
                "printType"
              ),
              id:
                assessmentToolKey === "mypObjectiveRubric"
                  ? resourceId
                  : assessmentToolId,
              title: resourceTitle,
            })
          }
        >
          <PrintIcon />
        </span>
      </LinkWithTooltip>
    ) : null;
  };

  getAssessmentToolValue = () => {
    const { resourceFields } = this.props;
    return _.get(
      _.find(resourceFields, { uid: "measureAssessing" }),
      "value",
      null
    );
  };

  scrollToCommentSection = () => {
    const offset = _.get(this.commentBoxRef, "current.offsetTop");
    // const { parentRef } = this.props;
    // if (parentRef) scrollToWithAnimation(parentRef.current, offset, 300);
    if (this.container) scrollToWithAnimation(this.container, offset, 300);
  };

  scrollToPosition = position => {
    const { parentRef } = this.props;
    const offset = _.get(this.commentBoxRef, "current.offsetTop");
    // if (parentRef)
    //   scrollToWithAnimation(parentRef.current, offset + position, 300);
    if (this.container)
      scrollToWithAnimation(this.container, offset + position, 300);
  };

  getSteps = () => {
    const {
      view_steps,
      step_list,
      setStepsRef,
      isResourceViewRevamp,
      assessmentTool,
      resourceFields,
    } = this.props;
    return _.map(view_steps, key => {
      let { label, viewLabel, fields, subtext } = step_list[key] || {};

      if (key === "assessmentTool") {
        const toolType = _.get(
          _.find(resourceFields, { uid: "measureAssessing" }),
          "value",
          null
        );

        const score = _.find(resourceFields, ({ uid }) => uid === "score");
        const isUsingScore = _.get(score, "value.isUsingScore", false);

        const assessmentToolHasData = getShowAssessmentTool({
          assessmentTool,
          assessmentToolType: toolType,
          allFields: resourceFields,
          checkInterdisciplinaryRubric: true,
        });

        if (!assessmentToolHasData && !isUsingScore) return;

        if (viewLabel && !isResourceViewRevamp) {
          viewLabel = `${viewLabel} - ${this.getAssessmentToolLabel()}`;
        }
      }

      const stepContainerClass = classNames({
        [classes.stepContainer]: true,
        [classes.stepContainerRevamp]: isResourceViewRevamp,
      });

      const stepLeftContainerClass = classNames({
        [classes.stepLeftContainer]: true,
        [classes.stepLeftContainerRevamp]: isResourceViewRevamp,
      });

      const stepLabelClass = classNames({
        [classes.stepLabel]: true,
        [classes.stepLabelRevamp]: isResourceViewRevamp,
      });

      const fieldsContainerClass = classNames({
        [classes.fieldsContainer]: true,
        [classes.fieldsContainerRevamp]: isResourceViewRevamp,
      });

      return (
        <div
          key={key}
          className={stepContainerClass}
          ref={ref => setStepsRef({ ref, key })}
        >
          <div className={stepLeftContainerClass}>
            <div className={classes.stepLabelContainer}>
              <span className={stepLabelClass}>
                {viewLabel ? viewLabel : label}
              </span>
              {!isResourceViewRevamp && this.getPrintButton({ stepKey: key })}
            </div>
            {subtext && <div className={classes.stepSubText}>{subtext}</div>}
          </div>
          <div className={fieldsContainerClass}>
            {this.getFields({ fields, subtext })}
          </div>
        </div>
      );
    });
  };

  isCheckValue = ({ value, fieldKey }) => {
    if (fieldKey == "voiceInstruction" || fieldKey == "studentTemplate") {
      return !_.isEmpty(value);
    }

    return true;
  };

  getFieldStyle = ({ key }) => {
    switch (key) {
      case "studentTemplate":
        return {
          containerStyle: {
            gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
          },
        };

      case "resources":
        return {
          attachmentFeedContainerStyle: {
            gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
          },
        };

      default:
        return {};
    }
  };

  getOptions = ({ fieldKey, defaultOptions, fieldObj }) => {
    const {
      t,
      resourceFields: assessmentFields,
      field_list,
      unitPlanFields,
    } = this.props;
    switch (fieldKey) {
      case "mypObjectiveRubric":
        return getMYPObjectiveRubricOptions({
          unitPlanFields,
          field_list,
          assessmentFields,
        });

      default:
        return defaultOptions || [];
    }
  };

  getFields = ({ fields, subtext }) => {
    const {
      field_list,
      resourceId,
      unitPlanFields,
      resourceFields,
      benchmarkSet,
      parentType,
      t,
      assessmentToolId,
      isLocalisedTemplate,
      isCommunity = false,
      assessmentTool,
      isResourceViewRevamp,
    } = this.props;
    const showLabel = _.get(fields, "length", 0) > 1;

    return _.map(fields, fieldKey => {
      const field = field_list[fieldKey] ? field_list[fieldKey] : {};
      let {
        viewType,
        subFields,
        config: {
          viewLabel,
          label,
          styles,
          options,
          fieldTypeConfig = {},
        } = {},
        type,
      } = field;
      if (_.includes(ASSESSMENT_TOOLS_TYPES, type)) {
        label = "";
      }
      const fieldData = _.find(resourceFields, { uid: fieldKey });

      const unitPlanFieldDataObj = _.find(unitPlanFields, { uid: fieldKey });
      const toolType = _.get(
        _.find(resourceFields, { uid: "measureAssessing" }),
        "value",
        null
      );

      const unitPlanFieldValue = _.get(
        _.find(unitPlanFields, { uid: fieldKey }),
        "value",
        ""
      );

      const resolvedValue = _.get(fieldData, "resolvedMinimalTree", "") || {};
      const subjectIds = _.get(
        _.find(unitPlanFields, { uid: "subjects" }),
        "value",
        ""
      );

      const assessmentFieldValue = _.get(fieldData, "value", "");

      let value = getElementValue({
        fieldUID: fieldKey,
        valueKeys: assessmentFieldValue,
        unitPlanFieldValue,
        benchmarkSet,
        fieldObj: field,
        subjectIds: subjectIds,
        resolvedValue: resolvedValue,
        t,
        isLocalisedTemplate,
        isCommunity,
      });

      const style = {
        ..._.get(styles, "style", {}),
        ..._.get(styles, "viewStyle", {}),
      };

      const isCheckValue = this.isCheckValue({
        value,
        fieldKey,
      });
      if (!isCheckValue) {
        return null;
      }
      const assessmentToolHasData = getShowAssessmentTool({
        assessmentTool,
        assessmentToolType: toolType,
        allFields: resourceFields,
        checkInterdisciplinaryRubric: true,
      });

      if (
        fieldKey === "measureAssessing" &&
        (isResourceViewRevamp || !assessmentToolHasData)
      ) {
        return null;
      }

      const contentWrapperClass = classNames({
        [classes.contentWrapper]: true,
        [classes.contentWrapperRevamp]: isResourceViewRevamp,
      });

      return (
        <div key={fieldKey} className={classes.item} style={style}>
          <div className={contentWrapperClass}>
            {showLabel && (
              <LabelComponent
                label={viewLabel || label}
                labelStyle={
                  isResourceViewRevamp ? fieldLabelStyleRevamp : fieldLabelStyle
                }
                {...(fieldKey === "measureAssessing" &&
                  !isResourceViewRevamp && {
                    labelComponent: (
                      <AssessmentToolTypeLabel
                        fieldKey={fieldKey}
                        value={value}
                      />
                    ),
                  })}
              />
            )}
            {fieldKey !== "measureAssessing" ? (
              <FieldComponent
                fieldTypeConfig={fieldTypeConfig}
                applyDefaultClass={false}
                defaultOptions={this.getOptions({
                  fieldKey: fieldKey,
                  defaultOptions: options,
                  fieldObj: field_list[fieldKey],
                })}
                subFields={getSubFields({
                  fieldKey,
                  fieldTemplateObj: field_list[fieldKey],
                  fieldDataObj: fieldData,
                  unitPlanFieldDataObj,
                })}
                attachments={_.get(fieldData, "attachments", [])}
                customRef={(key, ref) => (this.Refs[key] = ref)}
                type={viewType}
                mode={"view"}
                assessmentToolId={assessmentToolId}
                parentType={parentType}
                parentId={resourceId}
                value={value}
                fieldId={_.get(fieldData, "id", "")}
                fieldKey={fieldKey}
                toolType={toolType}
                contentStyle={{
                  fontSize: isResourceViewRevamp ? "1.6rem" : "1.8rem",
                  ...fontStyle.medium,
                  color: isResourceViewRevamp
                    ? colors.textSubtle
                    : colors.gray13,
                }}
                textViewcustomTextStyle={
                  isResourceViewRevamp ? textViewcustomTextStyle : {}
                }
                {...this.getFieldStyle({ key: fieldKey })}
                isAssessmentToolPreview={true}
                isAssessmentCreationRevamp={isResourceViewRevamp}
                showToolPrintOption={!isCommunity}
              />
            ) : null}
          </div>
        </div>
      );
    });
  };

  render() {
    const {
      userType,
      onScroll,
      totalCommentCount,
      unreadCommentCount,
      resourceFields,
      onCommentClick,
      currentRightPane,
      onClickPrint,
      parentType,
      resourceDetails,
      onClickEdit,
      isEditPermission,
      isCommunity,
      t,
      rethumpUrl,
      toggleMediaModal,
      isCurriculumProgramFree,
      image,
      navigateToUserProfile,
      onClose,
      goToCommunityRoute,
      tags,
      updateField,
      userLoggedIn,
      curriculumType,
      isDemo,
      showFooter,
      showBackButton,
      showViewActions,
      isResourceViewRevamp,
    } = this.props;
    const {
      id: resourceId,
      paritaStarStatus,
      kristenStarStatus,
      juryStarStatus,
      paritaProcessed,
      kristenProcessed,
      juryProcessed,
      entityRatings,
      savedBy,
      submittedToCommunity,
      grades,
      likes,
      createdBy,
      originalCreatedBy,
      communityComments,
    } = resourceDetails || {};

    const isOriginalCreator =
      _.get(createdBy, "id") === _.get(originalCreatedBy, "id");
    const hideFooter =
      isOriginalCreator || !originalCreatedBy || !showFooter || isDemo;

    const innerContainerClass = classNames({
      [classes.innerContainer]: true,
      [classes.innerContainerRevamp]: isResourceViewRevamp,
    });
    return (
      <React.Fragment>
        {isCommunity && !userLoggedIn ? <Header /> : null}
        <div
          className={classes.container}
          onScroll={e => onScroll(e)}
          ref={ref => (this.container = ref)}
        >
          <div className={innerContainerClass}>
            {showBackButton && !isResourceViewRevamp && (
              <div className={classes.backButton}>
                <span className={classes.wrapper} onClick={onClose}>
                  <LeftIcon fill={colors.salmon60} width={12} height={12} />
                  <span>{t("common:back")}</span>
                </span>
              </div>
            )}

            <ViewHeader
              isDemo={isDemo}
              userType={userType}
              isCommunity={isCommunity}
              createdBy={_.get(resourceDetails, "createdBy", {})}
              unitPlanTitle={_.get(resourceDetails, "unitPlan.title.value", "")}
              fields={resourceFields}
              isCommentActive={currentRightPane == "comment"}
              onCommentClick={onCommentClick}
              totalCommentCount={totalCommentCount}
              unreadCommentCount={unreadCommentCount}
              parentType={parentType}
              parentId={resourceId}
              paritaStarStatus={paritaStarStatus}
              kristenStarStatus={kristenStarStatus}
              juryStarStatus={juryStarStatus}
              paritaProcessed={paritaProcessed}
              kristenProcessed={kristenProcessed}
              juryProcessed={juryProcessed}
              onClickPrint={onClickPrint}
              isEditPermission={isEditPermission}
              onClickEdit={onClickEdit}
              entityRatings={entityRatings}
              savedBy={savedBy}
              submittedToCommunity={submittedToCommunity}
              grades={grades}
              isCurriculumProgramFree={isCurriculumProgramFree}
              likes={likes}
              image={image}
              navigateToUserProfile={navigateToUserProfile}
              toggleMediaModal={toggleMediaModal}
              goToCommunityRoute={goToCommunityRoute}
              communityComments={communityComments}
              scrollToCommentSection={this.scrollToCommentSection}
              curriculumType={curriculumType}
              showViewActions={showViewActions}
              isResourceViewRevamp={isResourceViewRevamp}
            />

            <div className={classes.stepList}>{this.getSteps()}</div>
          </div>
          {hideFooter ? null : (
            <Footer
              originalCreatedBy={originalCreatedBy}
              createdBy={_.get(resourceDetails, "createdBy", {})}
              navigateToUserProfile={navigateToUserProfile}
              goToCommunityRoute={goToCommunityRoute}
              communityComments={communityComments}
              curriculumType={curriculumType}
            />
          )}
          {isCommunity && userLoggedIn && (
            <div className={classes.commentContainer}>
              <div
                ref={this.commentBoxRef}
                style={hideFooter ? {} : { marginTop: 0, borderTop: "none" }}
                className={classes.stepContainer}
              >
                <div className={classes.stepLabel}>
                  {t("common:comment_plural")}
                </div>
                <CommentView
                  entityId={resourceId}
                  scrollToPosition={this.scrollToPosition}
                  entityType={"ASSESSMENT"}
                  parentType={"COMMUNITY_ASSESSMENT"}
                  titleValue={""}
                  showHeaderComponent={false}
                  allowReply={true}
                  customCommentBoxStyles={commentBoxStyles}
                />
              </div>
              <PAComponent perm={"CommunityPortal:AddOrUpdateEntityTags"}>
                <div className={classes.stepContainer}>
                  <div className={classes.stepLabel}>{t("common:tags")}</div>
                  <FieldComponent
                    newTagType={"TOPIC"}
                    type="SearchBoxWithTagQuery"
                    tagType={["TOPIC", "CATEGORY"]}
                    value={tags}
                    fieldKey="tags"
                    updateInputField={updateField}
                    hasAddRemoveValues={true}
                    allowAddNewOption={true}
                    placeholder={t("common:add_with_label", {
                      label: t("common:tags"),
                    })}
                  />
                </div>
              </PAComponent>
            </div>
          )}
          {isCommunity && !userLoggedIn && (
            <PublicAccessWidgets type="learning_experience" />
          )}
        </div>
      </React.Fragment>
    );
  }
}

MiddlePane.propTypes = {
  isLocalisedTemplate: PropTypes.bool,
};

MiddlePane.defaultProps = {
  isLocalisedTemplate: true,
  showBackButton: true,
  showFooter: true,
};

export default compose(
  I18nHOC({ resource: ["unitPlanTemplate", "common"] }),
  withRouter
)(MiddlePane);
