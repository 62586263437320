import update from "immutability-helper";
import client from "apolloClient";
import {
  createPointAssessmentSetMutation,
  deletePointAssessmentSetMutation,
  updatePointAssessmentSetMutation,
} from "./ScoreCategoriesMutation";
import { getOrganizationPointSetQuery } from "./ScoreCategoriesQueries";
import { colors } from "@toddle-design/theme";
import { getSettingValue } from "modules/PermissionModule";

// Constants
export const NAME = "scoreCategories";
export const UPDATE_TEMPLATE_FIELD_VALUE =
  "UPDATE_TEMPLATE_FIELDS_VALUE" + " " + NAME;
export const UPDATE_TEMPLATE_FIELDS_SET =
  "UPDATE_TEMPLATE_FIELDS_SET" + " " + NAME;
export const INIT_TEMPLATE_FIELDS_SET = "INIT_TEMPLATE_FIELDS_SET" + " " + NAME;
export const SET_IS_EDIT_TEMPLATE_MODE =
  "SET_IS_EDIT_TEMPLATE_MODE" + " " + NAME;
export const SET_USED_COURSES = "SET_USED_COURSES" + " " + NAME;
export const SET_USED_CATEGORY_SET_NAMES =
  "SET_USED_CATEGORY_SET_NAMES" + " " + NAME;

export const colorPalletOptions = [
  colors.blue29,
  colors.red66,
  colors.violet63,
  colors.teal20,
  colors.pink39,
  colors.gray48,
  colors.blueTwo,
];

//Mutation Functions

export const updateTemplateFieldValue = data => {
  return { type: UPDATE_TEMPLATE_FIELD_VALUE, data };
};

export const initTemplateFieldsSet = () => {
  return { type: INIT_TEMPLATE_FIELDS_SET };
};

export const updateTemplateFieldsSet = data => {
  return { type: UPDATE_TEMPLATE_FIELDS_SET, data };
};

export const setIsEditMode = data => {
  return {
    type: SET_IS_EDIT_TEMPLATE_MODE,
    data,
  };
};

export const setUsedCourses = data => {
  return {
    type: SET_USED_COURSES,
    data,
  };
};

export const setUsedCategorySetNames = data => {
  return {
    type: SET_USED_CATEGORY_SET_NAMES,
    data,
  };
};

//Helpers

export const scoreSettingTypes = [
  "SCORE_CATEGORY_MYP",
  "SCORE_CATEGORY_PYP",
  "SCORE_CATEGORY_DP",
  "SCORE_CATEGORY_UBD",
];

export const getSettingType = ({ curriculumType }) => {
  switch (curriculumType) {
    case "IB_MYP":
      return "SCORE_CATEGORY_MYP";
    case "IB_PYP":
      return "SCORE_CATEGORY_PYP";
    case "UBD":
      return "SCORE_CATEGORY_UBD";
    case "IB_DP":
      return "SCORE_CATEGORY_DP";
    default:
      return "SCORE_CATEGORY";
  }
};

const getSettingTypeBasedValue = ({ curriculumType }) => {
  switch (curriculumType) {
    case "IB_MYP":
      return "EnableAssessUsingScoreByTeacherMYP";
    case "IB_PYP":
      return "EnableAssessUsingScoreByTeacherPYP";
    case "UBD":
      return "EnableAssessUsingScoreByTeacherUBD";
    case "IB_DP":
      return "EnableAssessUsingScoreByTeacherDP";
    default:
      return "EnableAssessUsingScoreByTeacher";
  }
};

export const checkCategorySetApplicableTo = ({ organizationId }) => {
  return getSettingValue({
    name: "MapAssessmentSetWith",
    organizationId,
  });
};

export const checkIsScoreEnabledFromAdmin = ({
  organizationId,
  curriculumType,
}) => {
  return getSettingValue({
    name: getSettingTypeBasedValue({ curriculumType }),
    organizationId,
  });
};

export const getCurriculumBasedScorePermissionMemoize = _.memoize(
  params => getCurriculumBasedScorePermission(params),
  params => JSON.stringify(params)
);

const getCurriculumBasedScorePermission = ({ curriculumType }) => {
  switch (curriculumType) {
    case "IB_MYP":
      return "FeatureFlag:MYPScoreEvaluation";
    case "IB_PYP":
      return "FeatureFlag:PYPScoreEvaluation";
    case "UBD":
      return "FeatureFlag:UBDScoreEvaluation";
    case "IB_DP":
      return "FeatureFlag:DPScoreEvaluation";
    default:
      return "FeatureFlag:ScoreEvaluation";
  }
};

export const getScoreDetailsMemoize = _.memoize(
  params => getScoreDetails(params),
  params => JSON.stringify(params)
);

const getScoreDetails = ({ assessmentFields, organizationId }) => {
  const scoreFieldObject = _.find(assessmentFields, field => {
    return field.uid === "score";
  });

  const maxScore = _.get(scoreFieldObject, "resolvedMinimalTree.maxScore", 0);

  const isScoreEnabled = _.get(
    scoreFieldObject,
    "resolvedMinimalTree.isUsingScore",
    false
  );

  const scoreFieldId = _.get(scoreFieldObject, "id", null);

  const scoreCategoryLabel = _.get(
    scoreFieldObject,
    "resolvedMinimalTree.category.label",
    ""
  );
  const scoreCategoryColorCode = _.get(
    scoreFieldObject,
    "resolvedMinimalTree.category.colorCode"
  );

  return {
    isScoreEnabled,
    maxScore,
    scoreFieldId,
    scoreCategoryLabel,
    scoreCategoryColorCode,
  };
};

export const getSelectedGradesMemoize = _.memoize(
  params => getSelectedGrades(params),
  params => JSON.stringify(params)
);

const getSelectedGrades = ({ grades, templateFieldsObj }) => {
  return _.reduce(
    _.get(templateFieldsObj, "courses", []),
    (selectedGrades, gradeId) => {
      const gradeDetails = _.find(grades, ({ id }) => id === gradeId);
      if (gradeDetails) {
        selectedGrades.push({
          label: _.get(gradeDetails, "name", ""),
          value: gradeId,
          key: gradeId,
        });
      }

      return selectedGrades;
    },
    []
  );
};

export const getGradesOptionsMemoize = _.memoize(
  params => getGradesOptions(params),
  params => JSON.stringify(params)
);

const getGradesOptions = ({ grades, usedCourses }) => {
  return _.reduce(
    grades,
    (gradeOptions, grade) => {
      const gradeId = _.get(grade, "id");

      if (!_.includes(usedCourses, gradeId)) {
        gradeOptions.push({
          label: _.get(grade, "name", ""),
          value: gradeId,
          key: gradeId,
        });
      }

      return gradeOptions;
    },
    []
  );
};

export const areAllCoursesUsed = ({
  organizationPointSet,
  courses,
  courseType,
  idPath,
}) => {
  const usedCourses = [];
  const courseIds = [];
  _.forEach(organizationPointSet, ({ node: organizationPoint }) => {
    _.forEach(
      _.get(organizationPoint, "associatedEntities", []),
      associatedEntity => {
        if (_.get(associatedEntity, "__typename", "") === courseType) {
          usedCourses.push(associatedEntity.id);
        }
      }
    );
  });

  _.forEach(courses, course => {
    const courseId = _.get(course, `${idPath}`, "");
    courseIds.push(courseId);
  });

  return _.isEmpty(_.difference(courseIds, usedCourses));
};

export const getCourseOptionsMemoize = _.memoize(
  params => getCourseOptions(params),
  params => JSON.stringify(params)
);

const getCourseOptions = ({
  subjectBasedCourses,
  organizationPointSet,
  templateFieldsObj,
  t,
}) => {
  let courseOptions = [];

  const templateLabel = templateFieldsObj.label;

  const usedCoursesDetail = [];

  _.forEach(organizationPointSet, ({ node: organizationPoint }) => {
    _.forEach(
      _.get(organizationPoint, "associatedEntities", []),
      associatedEntity => {
        if (_.get(associatedEntity, "__typename", "") === "Course") {
          usedCoursesDetail.push({
            id: associatedEntity.id,
            label: organizationPoint.label,
          });
        }
      }
    );
  });

  _.forEach(_.get(subjectBasedCourses, "courses.edges", []), edge => {
    const node = _.get(edge, "node", {});
    _.forEach(_.get(node, "grades", []), grade => {
      const gradeId = _.get(grade, "id");
      const idx = _.findIndex(
        courseOptions,
        courseOption => _.get(courseOption, "value") === gradeId
      );
      const courseDetail = _.find(
        usedCoursesDetail,
        usedCourse => usedCourse.id === _.get(node, "id")
      );
      const courseTemplateLabel = _.get(courseDetail, "label");

      //Create an object of classes containing class name,id,subText associated with the class
      if (idx !== -1) {
        courseOptions[idx]?.options.push({
          label: _.get(node, "title"),
          value: _.get(node, "id"),
          subText:
            courseTemplateLabel === templateLabel || !courseTemplateLabel
              ? undefined
              : t("academicSetup:score_categories_used_courses_text", {
                  label: courseTemplateLabel,
                }),
        });
      } else {
        courseOptions.push({
          label: _.get(grade, "name"),
          value: _.get(grade, "id"),
          options: [
            {
              label: _.get(node, "title"),
              value: _.get(node, "id"),
              subText:
                courseTemplateLabel === templateLabel || !courseTemplateLabel
                  ? undefined
                  : t("academicSetup:score_categories_used_courses_text", {
                      label: courseTemplateLabel,
                    }),
            },
          ],
        });
      }
    });
  });
  courseOptions = _.sortBy(courseOptions, [
    ({ label }) => _.size(label),
    ({ label }) => label.toUpperCase(),
  ]);
  return courseOptions;
};

export const getUnusedColor = ({ fieldValues }) => {
  let unusedColor = colorPalletOptions[0];
  _.forEach(colorPalletOptions, color => {
    if (
      _.isEmpty(_.find(fieldValues, fieldValue => color === fieldValue.color))
    ) {
      unusedColor = color;
      return;
    }
  });
  return unusedColor ? unusedColor : colorPalletOptions[0];
};

//Function to check if the sum of the category percentage weight is equal to 100% or not
export const checkTotalPercentageWeight = ({
  templateFieldsObj,
  weightKey,
}) => {
  const fieldValues = _.get(templateFieldsObj, "fieldValues", []);
  let totalPercentageWeight = 0;

  _.forEach(fieldValues, fieldValue => {
    totalPercentageWeight =
      totalPercentageWeight + _.toNumber(fieldValue[weightKey]);
  });
  return totalPercentageWeight;
};

export const checkTotalPercentageWeightError = ({
  totalPercentageWeight,
  t,
}) => {
  if (_.isNaN(totalPercentageWeight)) {
    return t("academicSetup:invalid_input");
  }
  if (totalPercentageWeight > 100) {
    return t("academicSetup:exceeded_label", {
      label: `${totalPercentageWeight - 100}%`,
    });
  } else {
    return t("academicSetup:remaining_label", {
      label: `${100 - totalPercentageWeight}%`,
    });
  }
};

//Function to check the invalid input fields error
export const checkTemplateObjError = ({
  templateFieldsObj,
  setAreNotValid,
  curriculumType,
}) => {
  let errorCount = 0;
  let errorObj = {};
  if (_.size(templateFieldsObj.label) === 0) {
    errorCount = errorCount + 1;
    errorObj = { ...errorObj, setLabel: true };
  }
  if (_.size(templateFieldsObj.courses) === 0) {
    errorCount = errorCount + 1;
    errorObj = { ...errorObj, courses: true };
  }
  _.forEach(templateFieldsObj.fieldValues, (fieldValue, index) => {
    if (_.size(fieldValue.label) === 0) {
      errorCount = errorCount + 1;
      errorObj = { ...errorObj, [`label_${index}`]: true };
    }
    if (curriculumType !== "IB_DP" && fieldValue.percentage_weight === "") {
      errorCount = errorCount + 1;
      errorObj = { ...errorObj, [`percentage_weight_${index}`]: true };
    }
    if (curriculumType === "IB_DP" && fieldValue.slWeight === "") {
      errorCount = errorCount + 1;
      errorObj = { ...errorObj, [`sl_weight_${index}`]: true };
    }
    if (curriculumType === "IB_DP" && fieldValue.hlWeight === "") {
      errorCount = errorCount + 1;
      errorObj = { ...errorObj, [`hl_weight_${index}`]: true };
    }
  });

  setAreNotValid(errorObj);

  return errorCount;
};

export const getCurriculumBasedAdminChildId = ({ curriculumType }) => {
  switch (curriculumType) {
    case "IB_MYP":
      return "MYP_SCORE_CATEGORIES";
    case "IB_PYP":
      return "PYP_SCORE_CATEGORIES";
    case "UBD":
      return "UBD_SCORE_CATEGORIES";
    case "IB_DP":
      return "DP_SCORE_CATEGORIES";
  }
};

export const getHierarchy = ({
  slice,
  subjectId,
  subjectName,
  t,
  curriculumProgramTitle,
}) => {
  const hierarchy = [
    {
      id: "HOME",
      label: curriculumProgramTitle,
    },
    {
      id: "ACADEMIC_CRITERIA",
      label: t("teacherHomePage:academic_setup"),
    },
    {
      id: "SCORE_CATEGORIES",
      label: t("common:point_based_assessment"),
    },
    {
      id: `${subjectId}`,
      label: subjectName,
    },
  ];
  return _.slice(hierarchy, 0, slice);
};

const getCurriculumBasedRoute = ({ curriculumType }) => {
  switch (curriculumType) {
    case "IB_MYP":
      return "mypAcademicSetup";
    case "IB_PYP":
      return "academicSetup";
    case "UBD":
      return "ubdAcademicSetup";
    case "IB_DP":
      return "dpAcademicSetup";
  }
};

export const getHierarchyRoute = ({
  id,
  curriculumType,
  curriculumProgramId,
}) => {
  switch (id) {
    case "HOME":
    case "ACADEMIC_CRITERIA":
      return `administrator`;
    case "SCORE_CATEGORIES":
      return `administrator/${curriculumProgramId}/${getCurriculumBasedRoute({
        curriculumType,
      })}/score-categories`;
    default:
      return `administrator/${curriculumProgramId}/${getCurriculumBasedRoute({
        curriculumType,
      })}/score-categories/${id}`;
  }
};

const getScoreBasedPercentageWeightObj = ({
  curriculumType,
  hlWeight,
  slWeight,
  percentage_weight,
}) => {
  return curriculumType === "IB_DP"
    ? {
        hlWeight: _.toNumber(hlWeight),
        slWeight: _.toNumber(slWeight),
      }
    : { percentageWeight: _.toNumber(percentage_weight) };
};

//Mutations
export const addScoreCategoryTemplateNode = ({
  variables,
  subjectId,
  templateFieldsObj,
  curriculumProgramId,
  curriculumType,
  isCourseApplicableToCategorySet,
}) => {
  return async (dispatch, getState) => {
    const entityType = isCourseApplicableToCategorySet ? "COURSE" : "GRADE";

    const courses = _.map(_.get(templateFieldsObj, "courses", []), course => ({
      type: entityType,
      id: course,
    }));
    let associatedEntites = [{ type: "SUBJECT", id: subjectId }, ...courses];

    const categories = _.map(
      _.get(templateFieldsObj, "fieldValues", []),
      fieldValue => {
        const {
          label,
          color,
          percentage_weight,
          hlWeight,
          slWeight,
        } = fieldValue;

        const percentageWeightObj = getScoreBasedPercentageWeightObj({
          curriculumType,
          slWeight,
          hlWeight,
          percentage_weight,
        });

        return {
          label,
          colorCode: color,
          ...percentageWeightObj,
        };
      }
    );

    const scoreCategoryTemplateVariables = {
      curriculumProgramId,
      label: _.get(templateFieldsObj, "label", ""),
      type: "SCORE",
      associatedEntites,
      categories,
    };

    return await client.mutate({
      mutation: createPointAssessmentSetMutation,
      variables: { input: [scoreCategoryTemplateVariables] },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getOrganizationPointSetQuery,
          variables,
        },
      ],
    });
  };
};

export const deleteScoreCategoryTemplateNode = ({ variables, templateId }) => {
  return async (dispatch, getState) => {
    return await client.mutate({
      mutation: deletePointAssessmentSetMutation,
      variables: {
        input: {
          ids: [templateId],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getOrganizationPointSetQuery,
          variables,
        },
      ],
    });
  };
};

export const updateScoreCategoryTemplateNode = ({
  variables,
  templateFieldsObj,
  scoreCategorySet,
  subjectId,
  curriculumType,
  isCourseApplicableToCategorySet,
}) => {
  return async (dispatch, getState) => {
    const entityType = isCourseApplicableToCategorySet ? "COURSE" : "GRADE";

    const scoreSet = _.filter(
      _.get(scoreCategorySet, "associatedEntities", []),
      associatedEntity => associatedEntity.id !== subjectId
    );

    let removedCourses = [];
    let addedCourses = [];
    _.forEach(scoreSet, associatedParent => {
      if (
        _.isEmpty(
          _.find(
            _.get(templateFieldsObj, "courses", []),
            course => course === associatedParent.id
          )
        )
      ) {
        removedCourses.push({ type: entityType, id: associatedParent.id });
      }
    });
    _.forEach(_.get(templateFieldsObj, "courses", []), course => {
      if (
        _.isEmpty(
          _.find(scoreSet, associatedParent => associatedParent.id === course)
        )
      ) {
        addedCourses.push({ type: entityType, id: course });
      }
    });

    const addedCategories = [];
    const removedCategories = [];
    const updatedCategories = [];

    _.forEach(_.get(templateFieldsObj, "fieldValues", []), fieldValue => {
      if (_.isNull(_.get(fieldValue, "id", null))) {
        const percentageWeightObj = getScoreBasedPercentageWeightObj({
          curriculumType,
          slWeight: _.get(fieldValue, "slWeight", ""),
          hlWeight: _.get(fieldValue, "hlWeight", ""),
          percentage_weight: _.get(fieldValue, "percentage_weight"),
        });

        addedCategories.push({
          label: _.get(fieldValue, "label"),
          colorCode: _.get(fieldValue, "color"),
          ...percentageWeightObj,
        });
      } else if (
        !_.isEmpty(
          _.find(
            _.get(scoreCategorySet, "categories", []),
            scoreCategory => scoreCategory.id == fieldValue.id
          )
        )
      ) {
        const percentageWeightObj = getScoreBasedPercentageWeightObj({
          curriculumType,
          slWeight: _.get(fieldValue, "slWeight", ""),
          hlWeight: _.get(fieldValue, "hlWeight", ""),
          percentage_weight: _.get(fieldValue, "percentage_weight"),
        });

        updatedCategories.push({
          id: fieldValue.id,
          label: _.get(fieldValue, "label"),
          colorCode: _.get(fieldValue, "color"),
          ...percentageWeightObj,
        });
      }
    });

    _.forEach(_.get(scoreCategorySet, "categories", []), scoreCategory => {
      if (
        _.isEmpty(
          _.find(
            _.get(templateFieldsObj, "fieldValues", []),
            fieldValue => fieldValue.id === scoreCategory.id
          )
        )
      ) {
        removedCategories.push(scoreCategory.id);
      }
    });

    const scoreCategoryTemplateVariables = {
      id: templateFieldsObj.id,
      label: _.get(templateFieldsObj, "label", ""),
      associatedEntities: {
        addedAssociatedEntities: _.isEmpty(addedCourses)
          ? undefined
          : addedCourses,
        removedAssociatedEntities: _.isEmpty(removedCourses)
          ? undefined
          : removedCourses,
      },
      categories: {
        addedCategories: _.isEmpty(addedCategories)
          ? undefined
          : addedCategories,
        removedCategories: _.isEmpty(removedCategories)
          ? undefined
          : removedCategories,
        updatedCategories: _.isEmpty(updatedCategories)
          ? undefined
          : updatedCategories,
      },
    };

    return await client.mutate({
      mutation: updatePointAssessmentSetMutation,
      variables: { input: [scoreCategoryTemplateVariables] },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getOrganizationPointSetQuery,
          variables,
        },
      ],
    });
  };
};

// Reducer Handlers
const REDUCER_HANDLERS = {
  [UPDATE_TEMPLATE_FIELD_VALUE]: (state, action) => {
    let params = action.data;
    let type = params.type;
    let data = params.data;
    let arrayindex = params.index;

    if (type == "add") {
      state = update(state, {
        templateFieldsObj: { fieldValues: { $push: [data] } },
      });
    } else if (type == "delete") {
      state = update(state, {
        templateFieldsObj: { fieldValues: { $splice: [[arrayindex, 1]] } },
      });
    } else {
      state = update(state, {
        templateFieldsObj: {
          fieldValues: { $splice: [[arrayindex, 1, data]] },
        },
      });
    }
    return state;
  },
  [UPDATE_TEMPLATE_FIELDS_SET]: (state, action) => {
    let params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        templateFieldsObj: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [INIT_TEMPLATE_FIELDS_SET]: (state, action) => {
    return update(state, {
      templateFieldsObj: { $set: _.cloneDeep(initialState.templateFieldsObj) },
    });
  },
  [SET_IS_EDIT_TEMPLATE_MODE]: (state, action) => {
    return update(state, {
      isEditMode: { $set: action.data },
    });
  },
  [SET_USED_COURSES]: (state, action) => {
    return update(state, {
      usedCourses: { $set: action.data },
    });
  },
  [SET_USED_CATEGORY_SET_NAMES]: (state, action) => {
    return update(state, {
      usedCategorySetNames: { $set: action.data },
    });
  },
};

// Initial State and export Reducer
const initialState = {
  templateFieldsObj: {
    id: null,
    label: "",
    courses: [],
    fieldValues: [
      {
        id: null,
        label: "",
        color: "#008392",
        percentage_weight: "",
        slWeight: "",
        hlWeight: "",
      },
    ],
  },
  isEditMode: false,
  usedCourses: [],
  usedCategorySetNames: [],
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
