import client from "apolloClient";
import { postFragment } from "./StudentPortfolioFragment";
import {
  getCourseAllStudentQuery,
  getStudentAssessmentFeedQuery,
  getStudentGoalsQuery,
  getStudentPypElementRatingsQuery,
  getStudentPypTaggedPostQuery,
  getStudentPypElementRemarksQuery,
} from "./StudentPortfolioQuery";

export const getStudentAssessmentFeedFromCache = ({ studentId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentAssessmentFeedQuery,
      variables: {
        studentId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentPostsFromCache = ({ postId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Post:${postId}`,
      fragment: postFragment.postAttachmentBasic,
      fragmentName: "postItem",
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getStudentGoalFeedFromCache = ({ studentId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentGoalsQuery,
      variables: {
        studentId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseAllStudentsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseAllStudentQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentPypElementRatingsFromCache = ({
  studentId,
  elementTypes,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentPypElementRatingsQuery,
      variables: {
        studentId,
        elementTypes,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentPypElementRemarksFromCache = ({
  studentId,
  elementTypes,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentPypElementRemarksQuery,
      variables: {
        studentId,
        elementTypes,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentPypTaggedPostFromCache = ({
  studentId,
  fields,
  searchText,
  pypElements,
  unitPlanIds,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentPypTaggedPostQuery,
      variables: {
        studentId,
        fields,
        searchText,
        pypElements,
        unitPlanIds,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
