import PropTypes from "prop-types";
import React from "react";

const SharingThePlanetActive = props => {
  const cls1 = { fill: "#10acbd", opacity: "0.4" };
  const cls2 = { opacity: "0.4" };
  const cls3 = { fill: "#10acbd" };
  const cls4 = { fill: "#dbdbdb" };

  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 250 250">
      <title>sharing the planet active</title>
      <circle style={cls1} cx="126.07" cy="131.76" r="41.27" />
      <circle style={cls1} cx="66.7" cy="96.94" r="2.38" />
      <path
        style={cls1}
        d="M72.79,74.34a.74.74,0,1,1-.74.74.74.74,0,0,1,.74-.74m0-1a1.72,1.72,0,1,0,1.72,1.72,1.72,1.72,0,0,0-1.72-1.72Z"
      />
      <path
        style={cls1}
        d="M209.55,124.34a2.45,2.45,0,1,1,2.45-2.45A2.46,2.46,0,0,1,209.55,124.34Zm0-3.92a1.47,1.47,0,1,0,1.47,1.47A1.47,1.47,0,0,0,209.55,120.42Z"
      />
      <circle style={cls1} cx="190.63" cy="110.77" r="1.68" />
      <path d="M136.48,219.5a10.32,10.32,0,0,1-4.88-1.23L101.32,202l-56-31.58a.74.74,0,1,1,.72-1.28l56,31.57L132.29,217a8.83,8.83,0,0,0,8.39,0c10.84-5.87,38-20.32,49.17-26.27a4.49,4.49,0,0,0,2.27-2.89,4.56,4.56,0,0,0-6.17-5.34L145.4,199.35a6.15,6.15,0,0,1-5.2-.22l-15.54-8.06,20.11-.66a5.74,5.74,0,0,0,0-11.48l-22.78-.6a57.81,57.81,0,0,1-13.79-1.69,51.1,51.1,0,0,1-16.5-7.44A53.7,53.7,0,0,1,75.31,151a.74.74,0,0,1,1.28-.73A52.24,52.24,0,0,0,92.49,168a49.64,49.64,0,0,0,16,7.23A56.33,56.33,0,0,0,122,176.86l22.79.6a7.21,7.21,0,0,1,0,14.42l-14.48.48,10.55,5.47a4.66,4.66,0,0,0,4,.17l40.55-16.89A6,6,0,0,1,190.54,192c-11.17,5.94-38.33,20.4-49.16,26.26A10.3,10.3,0,0,1,136.48,219.5Z" />
      <path d="M167.5,100.06a.74.74,0,0,1-.64-.37A52.27,52.27,0,0,0,151,82a49.59,49.59,0,0,0-16-7.23,56.29,56.29,0,0,0-13.46-1.64l-22.79-.6a7.21,7.21,0,0,1,0-14.42l14.48-.48-10.54-5.47a4.67,4.67,0,0,0-4-.17L58.07,68.9A6,6,0,0,1,52.92,58c11.2-6,38.41-20.44,49.16-26.26a10.29,10.29,0,0,1,9.79,0L142.14,48l56,31.58a.74.74,0,0,1-.72,1.28l-56-31.57L111.17,33a8.82,8.82,0,0,0-8.39,0C92,38.87,64.81,53.35,53.61,59.31a4.5,4.5,0,0,0-2.27,2.89,4.56,4.56,0,0,0,6.17,5.34L98.05,50.65a6.15,6.15,0,0,1,5.2.22l15.54,8.07-20.11.66a5.74,5.74,0,0,0,0,11.48l22.78.6a57.71,57.71,0,0,1,13.79,1.69,51.07,51.07,0,0,1,16.5,7.44A53.75,53.75,0,0,1,168.14,99a.74.74,0,0,1-.64,1.1Z" />
      <path d="M121.45,82A42.65,42.65,0,1,1,78.8,124.67,42.7,42.7,0,0,1,121.45,82m0-1.47a44.12,44.12,0,1,0,44.12,44.12,44.12,44.12,0,0,0-44.12-44.12Z" />
      <path d="M121.45,82c13.31,0,24.14,19.13,24.14,42.65s-10.83,42.65-24.14,42.65-24.14-19.13-24.14-42.65S108.14,82,121.45,82m0-1.47c-14.15,0-25.61,19.75-25.61,44.12s11.47,44.12,25.61,44.12,25.61-19.75,25.61-44.12-11.47-44.12-25.61-44.12Z" />
      <path d="M121.45,168.25a.74.74,0,0,1-.74-.74V81.82a.74.74,0,1,1,1.47,0v85.69A.74.74,0,0,1,121.45,168.25Z" />
      <path d="M164.29,125.4H78.6a.74.74,0,1,1,0-1.47h85.69a.74.74,0,1,1,0,1.47Z" />
      <g style={cls2}>
        <path
          style={cls3}
          d="M40.27,126.12a.49.49,0,0,1-.49-.49v-3.56a.49.49,0,0,1,1,0v3.56A.49.49,0,0,1,40.27,126.12Z"
        />
        <path
          style={cls3}
          d="M42,124.34H38.49a.49.49,0,1,1,0-1H42a.49.49,0,0,1,0,1Z"
        />
      </g>
      <g style={cls2}>
        <path
          style={cls3}
          d="M159.16,175.31a.49.49,0,0,1-.49-.49v-3.56a.49.49,0,1,1,1,0v3.56A.49.49,0,0,1,159.16,175.31Z"
        />
        <path
          style={cls3}
          d="M160.94,173.53h-3.56a.49.49,0,0,1,0-1h3.56a.49.49,0,1,1,0,1Z"
        />
      </g>
      <g style={cls2}>
        <line style={cls4} x1="62.35" y1="138.75" x2="59.76" y2="141.34" />
        <path
          style={cls3}
          d="M59.76,141.83a.49.49,0,0,1-.35-.84L62,138.41a.49.49,0,1,1,.69.69l-2.59,2.59A.49.49,0,0,1,59.76,141.83Z"
        />
        <line style={cls4} x1="62.35" y1="141.34" x2="59.76" y2="138.75" />
        <path
          style={cls3}
          d="M62.35,141.83a.49.49,0,0,1-.35-.14l-2.59-2.59a.49.49,0,1,1,.69-.69L62.7,141a.49.49,0,0,1-.35.84Z"
        />
      </g>
      <g style={cls2}>
        <path
          style={cls3}
          d="M177.05,132.68a.49.49,0,0,1-.35-.84l2.19-2.19a.49.49,0,0,1,.69.69l-2.19,2.19A.49.49,0,0,1,177.05,132.68Z"
        />
        <path
          style={cls3}
          d="M179.24,132.68a.49.49,0,0,1-.35-.14l-2.19-2.19a.49.49,0,1,1,.69-.69l2.19,2.19a.49.49,0,0,1-.35.84Z"
        />
      </g>
      <path
        style={cls1}
        d="M196.95,150.4l-.83,3.64-2.74-2.53,3.56-1.1m.35-1.1a.73.73,0,0,0-.22,0l-4.42,1.37a.72.72,0,0,0-.28,1.22l3.39,3.14a.71.71,0,0,0,.49.19.72.72,0,0,0,.71-.56l1-4.51a.72.72,0,0,0-.7-.88Z"
      />
      <path
        style={cls1}
        d="M44.19,93.88l3.07,3.07L44.19,100l-3.07-3.07,3.07-3.07m0-1a.88.88,0,0,0-.62.26l-3.21,3.21a.88.88,0,0,0,0,1.24l3.21,3.21a.88.88,0,0,0,1.24,0L48,97.57a.88.88,0,0,0,0-1.24l-3.21-3.21a.88.88,0,0,0-.62-.26Z"
      />
    </svg>
  );
};

export default SharingThePlanetActive;
