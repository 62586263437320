import gql from "graphql-tag";
import { progressReportCommentTemplateFragment } from "./Fragments";

export const createNewCommentTemplateMutation = gql`
  mutation createNewCommentTemplate(
    $input: ProgressReportCommentTemplateCreateInput!
  ) {
    documentation {
      createProgressReportCommentTemplate(input: $input) {
        ...progressReportCommentTemplateItem
      }
    }
  }
  ${progressReportCommentTemplateFragment.progressReportCommentTemplateEdge}
`;

export const updateCommentTemplateMutation = gql`
  mutation updateCommentTemplate(
    $input: ProgressReportCommentTemplateUpdateInput!
  ) {
    documentation {
      updateProgressReportCommentTemplate(input: $input) {
        ...progressReportCommentTemplateItem
      }
    }
  }
  ${progressReportCommentTemplateFragment.progressReportCommentTemplateEdge}
`;

export const deleteCommentTemplateMutation = gql`
  mutation deleteCommentTemplate($input: ID!) {
    documentation {
      deleteProgressReportCommentTemplate(input: $input)
    }
  }
`;

export const publishCommentBankMutation = gql`
  mutation publishCommentBank($input: PublishCommentBankInput!) {
    documentation {
      publishCommentBank(input: $input)
    }
  }
`;
