import React from "react";

const Grade1Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g id="1st" fill={props.fill && props.fill}>
        <path
          d="M32.0859,20.4614 C33.1909,19.6664 34.0549,19.4254 34.7459,19.4254 C35.9899,19.4254 38.3399,19.9424 38.3399,23.5014 L38.3399,41.3284 C38.3399,44.1254 36.3709,44.5744 35.4379,44.5744 C34.5039,44.5744 32.5349,44.1254 32.5349,41.3284 L32.5349,26.2654 L29.9099,28.3034 C29.2889,28.7864 28.6329,28.9594 28.1129,28.9594 C26.7319,28.9594 25.6599,27.8894 25.6599,26.4384 C25.6599,25.6784 26.0059,24.9174 27.0779,24.1234 L32.0859,20.4614 Z"
          id="Fill-2"
        ></path>
      </g>
    </svg>
  );
};

Grade1Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default Grade1Icon;
