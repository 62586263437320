import React from "react";
import PropTypes from "prop-types";

// Components
import {
  DialogueBoxV2,
  FullScreenLoader,
  LinkWithTooltip,
  I18nHOC,
} from "UIComponents";
import { LabelComponent } from "UnitPlans/components/LabelComponent";
import { FieldComponent } from "UnitPlans/components/IBcomponents";
import { CoachMarks } from "AppComponents";

// Constants
import { colors, LE_ASSESSMENT_TYPE_MAPPING, fontStyle } from "Constants";
import { useI18n } from "Hooks";

// Services
import {
  getMYPObjectiveRubricOptions,
  getPypElementLabel,
  ASSESSMENT_TOOLS,
  SIZE,
  EVALUATION_METHOD,
  pypElementMapping,
} from "modules/Services";

// Utils
import {
  extractLabelsForTranslation,
  getPYPElementSetFromNodes,
  generateRandomId,
} from "Utils";

// Design System
import {
  InformationOutlined,
  MicrophoneOutlined,
} from "@toddle-design/web-icons";
import { Button, Alert } from "@toddle-design/web";

// External Lib.
import { connect } from "react-redux";
import { compose } from "redux";

// Misc.
import {
  enityEnumToLocalTypeMapping,
  getPlannerFieldMutationParams,
} from "UnitPlans/modules/UnitPlanModule";
import { getFieldLockedObject } from "modules/PusherModule";
import ACLStore from "lib/aclStore";

// Scss
import classes from "./MiddlePaneV2.scss";

const styles = {
  customStyles: {
    subTextStyle: {
      fontSize: "1.6rem",
      color: colors.textSubtle,
      fontWeight: fontStyle.regular,
    },
    editContainerStyle: { flexShrink: 0, maxWidth: "696px" },
    customDropZoneStyle: {
      width: "auto",
      alignItems: "stretch",
    },
    ResourceslabelContainerStyle: {
      flexGrow: 0,
      flexBasis: "auto",
    },
    assessmentToolContainerStyle: {
      padding: 0,
      overflowY: "hidden",
    },
    timeComponentStyle: {
      height: "48px",
    },
    createDropdownWrapperStyle: {
      order: 2,
    },
  },
  button1Props: {
    variant: "outlined-subtle",
    size: "large",
  },
  button2Props: {
    size: "large",
  },
};

const MODAL_FIELD_UIDS = ["resources"];

const DIALOGS_INFO = {
  MEASURE_ASSESSING: {
    title: ({ t }) => t("classRoom:assessment_tool_remove_text"),
    message: ({ t }) => t("classRoom:assessment_tool_remove_subtext"),
    confirmButtonText: ({ t }) => t("common:remove"),
    cancelButtonText: ({ t }) => t("common:cancel"),
  },
  HL: {
    title: ({ t, tag }) =>
      t("unitPlan:remove_tag_warning_title", {
        tag: t(`common:${tag}`),
      }),
    message: ({ t, tag, assessmentTypeLabel }) =>
      t("unitPlan:remove_tag_warning_msg", {
        tag: t(`common:${tag}`),
        assessmentType: t(assessmentTypeLabel),
      }),
    confirmButtonText: ({ t, tag }) =>
      t("unitPlan:remove_tag", {
        tag: t(`common:${tag}`),
      }),
    cancelButtonText: ({ t }) => t("common:cancel"),
  },
  SL: {
    title: ({ t, tag }) =>
      t("unitPlan:remove_tag_warning_title", {
        tag: t(`common:${tag}`),
      }),
    message: ({ t, tag, assessmentTypeLabel }) =>
      t("unitPlan:remove_tag_warning_msg", {
        tag: t(`common:${tag}`),
        assessmentType: t(assessmentTypeLabel),
      }),
    confirmButtonText: ({ t, tag }) =>
      t("unitPlan:remove_tag", {
        tag: t(`common:${tag}`),
      }),
    cancelButtonText: ({ t }) => t("common:cancel"),
  },
};
class MiddlePaneV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    props.customRef(this);
    this.Refs = {};

    this.container = null;
    this.updatedField = {};
    this.sectionToDelete = "";
    this.blockText = "Add ";
    this.state = {
      scrolledToBottom: false,
      isLoading: false,
      attachmentsValue: [],
      currentDialogAction: "",
    };
  }

  blurFields = ({ field } = {}) => {
    const { uid } = field;
    const fieldRef = this.Refs[uid];

    if (fieldRef) {
      fieldRef.blur({ field });
    }
  };

  isValid = () => {
    if (this.Refs["title"]) {
      return this.Refs["title"].isValid();
    } else {
      return 0;
    }
  };

  getFlatAttachments = ({ attachmentGroups }) => {
    return _.map(attachmentGroups, attachmentGroup => {
      return {
        ..._.get(attachmentGroup, "attachments[0]", {}),
        id: _.get(attachmentGroup, "id", 0),
      };
    });
  };

  updateField = ({
    params,
    isMutation = false,
    isDebounce = true,
    hasAddRemoveValues = false,
    extraParams = {},

    isWarningReq = true,
  }) => {
    _.map(params, (obj, key) => {
      const fieldObj = _.find(this.props.resourceFields, { uid: key }) || {};
      const fieldTemplateObj = this.props.field_list[key];
      const { portalType } = this.props;
      const { id, value: oldValue } = fieldObj;
      const value = { id, value: obj };
      let shouldUpdate = true;
      if (key == "measureAssessing" && isWarningReq) {
        this.updatedField = { key, newValue: value, oldValue };
        this.setState({ currentDialogAction: "MEASURE_ASSESSING" });
        return;
      }
      if (key === "subjectLevel" && isWarningReq) {
        shouldUpdate = this.shouldShowDialogueBox({
          key,
          oldValue,
          newValue: obj,
          fieldObj,
        });
      }
      if (key == "voiceInstruction" || key == "studentTemplate") {
        isMutation = false;
      }

      if (shouldUpdate) {
        this.props.updateFieldInCache({
          fieldUID: key,
          params: { ...value, uid: key },
          fieldObj,
          fieldTemplateObj,
          extraParams,
        });
        const mutationParams = getPlannerFieldMutationParams({
          id,
          oldValue,
          hasAddRemoveValues,
          newValue: obj,
        });
        if (isMutation) {
          this.props.updateField({
            key: id,
            params: { ...(mutationParams || {}), portalType },
            type: this.props.parentType,
            isDebounce,
          });
        }
      }
    });
  };

  shouldShowDialogueBox = params => {
    const { assessmentMode } = this.props;

    const { key, oldValue, newValue, fieldObj } = params;

    const isValueRemoved = _.size(newValue) < _.size(oldValue);

    if (isValueRemoved && assessmentMode === "edit") {
      const genericTags = _.get(
        fieldObj,
        "resolvedMinimalTree.genericTags",
        []
      );

      const removedTag = _.filter(genericTags, ({ id }) => id != newValue);
      const removedTagLabel = _.get(_.first(removedTag), "label", "");

      this.updatedField = { key, newValue, oldValue };

      this.setState({
        currentDialogAction: removedTagLabel,
      });
      return false;
    } else {
      return true;
    }
  };

  onBlurInputField = ({ key: uid, id }) => {
    const { toggleFocusedField } = this.props;
    toggleFocusedField({ isFocused: false, focusedField: { uid, id } });
  };

  onFocusInputField = ({ key: uid, id }) => {
    const { toggleFocusedField } = this.props;
    toggleFocusedField({ isFocused: true, focusedField: { uid, id } });
  };

  getOptions = ({
    fieldKey,
    defaultOptions,
    fieldObj,
    value,
    curriculumType,
  }) => {
    const {
      t,
      resourceFields: assessmentFields,
      field_list,
      unitPlanFields,
      organizationSubjectLevels,
    } = this.props;
    switch (fieldKey) {
      case "measureAssessing":
        return this.getMeasureAssessingOptions({ fieldKey, defaultOptions, t });
      case "lois":
      case "goalList":
        return this.getNotPypChecklistOptions({ fieldKey });
      case "lp":
      case "concepts":
        return this.getPypElementOptions({ fieldKey });
      case "atls":
      case "benchmarks":
        return this.getBenchmarkOptions({ fieldKey });
      case "assessmentType":
        return this.getAssessmentTypeOptions({
          fieldKey,
          value,
          curriculumType,
        });
      case "mypObjectiveRubric":
        return getMYPObjectiveRubricOptions({
          unitPlanFields,
          field_list,
          assessmentFields,
        });

      case "subjects":
        return this.getResolveMinimalTreeOptions({
          fieldKey,
          fieldObj,
          nodeType: "subjects",
        });

      case "scoreSubject":
        return [
          { label: t("common:none"), value: "" },
          ...(this.getResolveMinimalTreeOptions({
            fieldKey: "subjects",
            fieldObj,
            nodeType: "subjects",
          }) || []),
        ];
      case "subjectLevel": {
        return _.map(organizationSubjectLevels, node => {
          return { label: node.label, value: node.id };
        });
      }
      default: {
        //Handled Planner Elements
        const type = _.get(fieldObj, "config.type");
        if (type == "PLANNER_ELEMENT") {
          return this.getPlannerElementOptions({ fieldKey, fieldObj });
        } else {
          return defaultOptions || [];
        }
      }
    }
  };

  getLabel = ({ fieldKey, value }) => {
    switch (fieldKey) {
      case "lois":
      case "goalList":
        return this.getNotPypChecklistLabel({ value, fieldKey });
      case "lp":
      case "concepts":
      case "atls":
      case "benchmarks":
        return getPypElementLabel({ value, type: pypElementMapping[fieldKey] });
      default:
        return "";
    }
  };

  getUnitPlanFieldValue = ({ fieldKey }) => {
    const { unitPlanFields } = this.props;
    return _.get(_.find(unitPlanFields, { uid: fieldKey }), "value", "");
  };

  getUnitPlanFieldResolvedValue = ({ fieldKey }) => {
    const { unitPlanFields } = this.props;
    return _.get(
      _.find(unitPlanFields, { uid: fieldKey }),
      "resolvedMinimalTree",
      ""
    );
  };

  getResouceFieldValue = ({ fieldKey }) => {
    const { resourceFields } = this.props;
    return _.get(_.find(resourceFields, { uid: fieldKey }), "value", "");
  };

  getResouceFieldResolveValue = ({ fieldKey }) => {
    const { resourceFields } = this.props;
    return _.get(
      _.find(resourceFields, { uid: fieldKey }),
      "resolvedMinimalTree",
      ""
    );
  };

  getMeasureAssessingOptions = ({ fieldKey, defaultOptions, t }) => {
    return this.getOptionsLocale({ options: defaultOptions, key: fieldKey, t });
  };

  getNotPypChecklistOptions = ({ fieldKey }) => {
    let unitPlanLois = this.getUnitPlanFieldValue({ fieldKey });
    unitPlanLois = _.filter(unitPlanLois, item => _.trim(item.value) != "");

    return _.map(unitPlanLois, loi => {
      return { label: loi.value, value: loi.id };
    });
  };

  getNotPypChecklistLabel = ({ value, fieldKey }) => {
    const unitPlanLois = this.getUnitPlanFieldValue({ fieldKey });
    return _.get(_.find(unitPlanLois, { id: value }), "value", "");
  };

  getPypElementOptions = ({ fieldKey }) => {
    const unitPlanPypValues = this.getUnitPlanFieldValue({ fieldKey });
    const fieldValues = this.getResouceFieldValue({ fieldKey });
    const values = _.uniq([
      ...(unitPlanPypValues || []),
      ...(fieldValues || []),
    ]);
    return _.map(values, value => {
      return {
        label: this.getLabel({ fieldKey, value }),
        value,
      };
    });
  };

  getPlannerElementOptions = ({ fieldKey, fieldObj }) => {
    const { plannerElements } = this.props;

    const plannerElementConfig = _.get(
      fieldObj,
      "config.plannerElementConfig",
      {}
    );

    const { type } = plannerElementConfig;

    const unitPlanFieldValue = this.getUnitPlanFieldValue({ fieldKey }) || [];

    const resourceFieldValue = this.getResouceFieldValue({ fieldKey }) || [];

    const resourceSubjects =
      this.getResouceFieldValue({ fieldKey: "subjects" }) || [];

    const unitPlanSubjects =
      this.getUnitPlanFieldValue({ fieldKey: "subjects" }) || [];

    const unitPlanSubjectResolvedValues = _.get(
      this.getResouceFieldResolveValue({
        fieldKey: "subjects",
      }),
      "subjects",
      []
    );

    const resourceSubjectGroups = _.reduce(
      resourceSubjects,
      (result, subjectId) => {
        const subjectGroup = _.get(
          _.find(unitPlanSubjectResolvedValues, { id: subjectId }),
          "subjectGroup",
          {}
        );
        if (!_.isEmpty(subjectGroup)) {
          result = _.unionBy(result, [subjectGroup], "id");
        }
        return result;
      },
      []
    );
    const unitPlanFieldValueNodes = _.get(
      this.getUnitPlanFieldResolvedValue({
        fieldKey,
      }),
      "nodes",
      []
    );

    const resourceFieldValueNodes = _.get(
      this.getResouceFieldResolveValue({ fieldKey }),
      "nodes",
      []
    );

    const nodes = _.uniqBy(
      [...unitPlanFieldValueNodes, ...resourceFieldValueNodes],
      "id"
    );

    const value = _.uniq([...unitPlanFieldValue, ...resourceFieldValue]);

    let parentNodes = [];
    switch (type) {
      case "MYP_ATL":
      case "UBD_ATL":
        return {
          parentNodes: _.filter(nodes, ({ parent }) => !parent),

          value,
          nodes,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: type,
          },
        };
      case "MYP_OBJECTIVES": {
        parentNodes = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = _.map(node.associatedParents, parent => {
              return { id: parent.id, label: parent.name };
            });

            result = _.unionBy(result, associatedParents, "id");
            return result;
          },
          []
        );

        if (!_.isEmpty(resourceSubjects)) {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(resourceSubjects, node.id)
          );
        }

        const groupedByType = "SUBJECT";

        return {
          parentNodes,
          value,
          nodes,
          plannerElementSetData: {
            type,
            groupedByType,
            parentType: "PLANNER_ELEMENT",
          },
        };
      }
      case "MYP_LEARNING_STANDARD":
      case "UBD_LEARNING_STANDARD":
        parentNodes = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = _.map(node.associatedParents, parent => {
              return { id: parent.id, label: parent.name };
            });

            result = _.unionBy(result, associatedParents, "id");
            return result;
          },
          []
        );
        if (!_.isEmpty(resourceSubjects)) {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(resourceSubjects, node.id)
          );
        } else {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(unitPlanSubjects, node.id)
          );
        }

        return {
          parentNodes,
          value,
          nodes,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: "SUBJECT",
          },
        };
      case "MYP_RELATED_CONCEPT":
        parentNodes = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = _.map(node.associatedParents, parent => {
              return { id: parent.id, label: parent.name };
            });

            result = _.unionBy(result, associatedParents, "id");
            return result;
          },
          []
        );

        if (!_.isEmpty(resourceSubjects)) {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(resourceSubjects, node.id)
          );
        } else {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(unitPlanSubjects, node.id)
          );
        }

        return {
          parentNodes,
          value,
          nodes,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: "SUBJECT",
          },
        };

      case "DP_PEDAGOGICAL_APPROACH": {
        const plannerElementSet =
          _.find(plannerElements, {
            type: type,
          }) || {};

        const plannerElementNodes = _.get(plannerElementSet, "nodes", []);
        return _.map(plannerElementNodes, node => {
          return { ...node, value: node.id };
        });
      }

      default:
        return _.map(nodes, node => {
          return {
            label: node.label,
            value: node.id,
          };
        });
    }
  };

  getSubjectsOptions = ({ fieldKey }) => {
    const unitPlanFieldValueNodes = _.get(
      this.getUnitPlanFieldResolvedValue({
        fieldKey,
      }),
      "subjects",
      []
    );

    return _.map(unitPlanFieldValueNodes, node => {
      return { label: node.name, value: node.id };
    });
  };

  getResolveMinimalTreeOptions = ({ fieldKey, fieldObj, nodeType }) => {
    const unitPlanFieldValueNodes = _.get(
      this.getUnitPlanFieldResolvedValue({
        fieldKey,
      }),
      nodeType,
      []
    );

    return _.map(unitPlanFieldValueNodes, node => {
      return { label: node.name || node.label, value: node.id };
    });
  };

  getBenchmarkOptions = ({ fieldKey }) => {
    const { subjects, grades } = this.props;
    const unitPlanBenchmarks = this.getUnitPlanFieldValue({ fieldKey });
    const unitPlanResolvedValue = this.getUnitPlanFieldResolvedValue({
      fieldKey,
    });
    const subjectIds = _.map(subjects, item => item.id);

    let benchmarkSet;
    switch (fieldKey) {
      case "atls": {
        benchmarkSet = getPYPElementSetFromNodes({
          nodes: _.get(unitPlanResolvedValue, "atls", []),
        });
        break;
      }
      default: {
        benchmarkSet = getPYPElementSetFromNodes({
          nodes: _.filter(
            _.get(unitPlanResolvedValue, "benchmarks", []),
            item => _.includes(subjectIds, item.subject)
          ),
        });
      }
    }

    return fieldKey == "atls"
      ? { benchmarkSet }
      : {
          unitPlanBenchmarks,
          benchmarkSet,
          subjects,
          grades,
        };
  };

  getAssessmentTypeOptions = () => {
    const { unitPlanTemplate, t } = this.props;

    const assessmentTypes = _.get(unitPlanTemplate, "assessmentTypes", []);
    const isLocalisedTemplate = _.get(
      unitPlanTemplate,
      "isLocalisedTemplate",
      true
    );

    let assessmentTypeOptionsToShow = [...assessmentTypes];

    if (isLocalisedTemplate) {
      assessmentTypeOptionsToShow = _.map(assessmentTypeOptionsToShow, item => {
        item.label = t(
          _.get(LE_ASSESSMENT_TYPE_MAPPING[item.value], "localeLabel", "")
        );
      });
    }

    return assessmentTypeOptionsToShow;
  };

  getLabelSubtext = ({ key }) => {
    const { assessmentToolId, field_list, t } = this.props;
    const { config: { subtext } = {} } = field_list[key] ? field_list[key] : {};
    // hack to show different student template subtext if school has permissions
    if (key === "studentTemplate") {
      if (ACLStore.can("TeacherPortal:UseStudentTemplate")) {
        return t("unitPlan:student_template_subtext");
      }
    } else if (key === "resources") {
      return t("unitPlanTemplate:le_resources_label");
    }
    if (_.get(_.find(ASSESSMENT_TOOLS, { key }), "hasTemplate", false)) {
      const name = _.get(_.find(ASSESSMENT_TOOLS, { key }), "locale", "");
      const isCreating = !assessmentToolId;
      return isCreating
        ? `${t("unitPlan:create_new_template_msg_with_label", {
            label: t(name),
          })}`
        : subtext;
    } else return subtext;
  };

  saveTool = ({ tool, title }) => {
    const key = _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", "");

    if (this.Refs[key]) {
      this.Refs[key].saveAssessmentTool(title);
    }
  };

  getFieldLockedObject = ({ key }) => {
    const { lockedFields } = this.props;
    let uids = [];
    switch (key) {
      case "rubric":
      case "exemplar":
      case "anecdotal":
      case "checklist":
      case "measureAssessing":
      case "singlePointRubric":
        uids = [
          "rubric",
          "exemplar",
          "anecdotal",
          "checklist",
          "measureAssessing",
          "singlePointRubric",
        ];
        break;
      default:
        uids = [key];
    }

    return getFieldLockedObject({ uids, lockedFields });
  };

  setFieldRef = (key, ref) => {
    this.Refs[key] = ref;
  };

  checkIsModalField = key => {
    return _.includes(MODAL_FIELD_UIDS, key);
  };

  renderCoachMarks = ({ id, index, resourceIndex, resourceType }) => {
    return (
      <React.Fragment>
        {index == resourceIndex && (
          <CoachMarks
            type={`RESOURCE`}
            params={{ target: `#COACHMARKS_RESOURCE_${id}`, resourceType }}
            continuous={false}
          />
        )}
      </React.Fragment>
    );
  };

  getOptionsLocale = ({ options, key, t }) => {
    const clonedOptions = _.cloneDeep(options);
    switch (key) {
      case "measureAssessing":
        _.map(clonedOptions, option => {
          if (option.value === "") {
            option.label = t(`common:none`);
          } else {
            option.label = t(
              _.get(
                _.find(ASSESSMENT_TOOLS, { type: option.value }),
                "locale",
                ""
              )
            );
          }
        });
        return clonedOptions;
      case "size":
        _.map(clonedOptions, option => {
          option.label = t(
            _.get(_.find(SIZE, { type: option.value }), "locale", "")
          );
        });
        return clonedOptions;
      case "evaluationType":
        _.map(clonedOptions, option => {
          option.label = t(
            _.get(
              _.find(EVALUATION_METHOD, { type: option.value }),
              "locale",
              ""
            )
          );
        });
        return clonedOptions;
      default:
        return clonedOptions;
    }
  };

  getValue = ({ fieldData, key, fieldObj }) => {
    switch (key) {
      case "voiceInstruction":
        return _.get(fieldData, "resolvedMinimalTree.attachment", "");
      case "studentTemplate":
        return _.map(
          _.get(fieldData, "resolvedMinimalTree.attachmentGroups", []),
          group => {
            return { ...group, isPost: true };
          }
        );
      default:
        /**
         * this "if condition" will add default options which are present in template
         * in the backend with data structure like [{ id, value,isSelected },{...}]
         * which is for UIInputList.
         *
         * when it will run ?
         * -> It will run only when value for field is null. So, this is the dependency from backend data.
         * We have another dependency from template to run this "if condition". To make run this condition,
         * in field you have to have options with [{labe,value}] form, showDefaultTemplateOptions boolean, and field type
         * should be "InputNumberList".
         */
        if (
          !_.isEmpty(fieldData) &&
          fieldObj.type === "InputNumberList" &&
          _.get(fieldObj, "config.showDefaultTemplateOptions", false) &&
          !fieldData.value
        ) {
          const options = _.map(
            _.get(fieldObj, "config.options", []),
            option => ({
              id: generateRandomId(),
              isSelected: false,
              value: option.label,
            })
          );
          this.updateField({
            extraParams: {},
            hasAddRemoveValues: false,
            isDebounce: true,
            isWarningReq: true,
            isMutation: true,
            params: {
              [key]: options,
            },
          });
          return options;
        }
        return _.get(fieldData, "value", "");
    }
  };

  getSubFields = ({ fieldKey, fieldObj }) => {
    const subFields = _.get(fieldObj, "subFields", []);

    return _.reduce(
      subFields,
      (result, subField) => {
        const { key } = subField;
        const unitPlanFieldValue = this.getUnitPlanFieldValue({ fieldKey });
        const options = [];
        _.forEach(_.get(unitPlanFieldValue, `${key}`, []), option => {
          if (!_.isEmpty(option.value)) {
            options.push({ label: option.value, value: option.id });
          }
        });

        if (!_.isEmpty(options)) {
          result.push({
            ...subField,
            config: { ...subField.config, defaultOptions: options },
          });
        }

        return result;
      },
      []
    );
  };

  getDependentFieldValue = ({ dependentFieldId }) => {
    const { field_list, resourceFields } = this.props;
    const dependentFieldData = _.find(resourceFields, {
      uid: dependentFieldId,
    });

    if (_.isEmpty(dependentFieldData)) {
      return null;
    }

    return this.getValue({
      fieldData: dependentFieldData,
      key: dependentFieldId,
      fieldObj: field_list[dependentFieldId],
    });
  };

  renderFieldComponent = fields => {
    const {
      field_list,
      resourceFields,
      createAttachment,
      deleteAttachment,
      assessmentToolId,
      resourceId,
      parentType,
      t,
      readOnly,
      unitPlanId,
      isLocalisedTemplate,
      portalType,
      localisedTemplateId,
      curriculumType,
      assessmentType,
      orgId,
    } = this.props;

    return _.map(fields, (key, index) => {
      let {
        type,

        config: {
          label,
          subLabel,
          placeholder,
          options,
          emptyText,
          styles: fieldStyles,
          hasAddRemoveValues = false,
          isRequired = false,
          prompts,
          sourceType,
          fieldTypeConfig,
          dependentFieldId,
        } = {},
      } = field_list[key] ? field_list[key] : {};

      if (label === "Name of the Assessment" && key === "title") {
        label = !isLocalisedTemplate
          ? label
          : label &&
            t(
              extractLabelsForTranslation({
                keys: ["le", localisedTemplateId, "assessment", key, "label"],
              })
            );
      }

      options = this.getOptionsLocale({ options, key, t });

      const fieldData = _.find(resourceFields, { uid: key });
      const fieldId = _.get(fieldData, "id", "");
      const subText = this.getLabelSubtext({ key });

      const resourceIndex = _.findIndex(
        fields,
        keyName => keyName == "resources"
      );
      const value = this.getValue({
        fieldData,
        key,
        fieldObj: field_list[key],
      });

      const toolType = _.get(
        _.find(resourceFields, { uid: "measureAssessing" }),
        "value",
        null
      );

      let dependentFieldValue;
      if (dependentFieldId) {
        dependentFieldValue = this.getDependentFieldValue({
          dependentFieldId,
        });
      }

      const fieldOptions = this.getOptions({
        fieldKey: key,
        defaultOptions: options,
        fieldObj: field_list[key],
        value,
        curriculumType,
      });

      return (
        <div
          className={classes.item}
          key={key}
          style={_.get(fieldStyles, "style", {})}
        >
          {this.renderCoachMarks({
            id: fieldId,
            key,
            index,
            resourceIndex,
            resourceType: parentType,
          })}

          <WrapperFieldLabelComponent
            portalType={portalType}
            readOnly={readOnly}
            assessmentType={assessmentType}
            createAttachment={createAttachment}
            deleteAttachment={deleteAttachment}
            fieldLockedObject={this.getFieldLockedObject({
              key,
            })}
            subFields={this.getSubFields({
              fieldKey: key,
              fieldObj: field_list[key],
            })}
            unitPlanId={unitPlanId}
            isModalField={this.checkIsModalField(key)}
            customRef={this.setFieldRef}
            type={type}
            label={label}
            subText={subText}
            assessmentToolId={assessmentToolId}
            hasAddRemoveValues={hasAddRemoveValues}
            extraRightButtonComponent={this.getExtraRightButtonComponent}
            parentId={resourceId}
            setScrollToBottom={this.setScrollToBottom}
            parentType={parentType}
            isRequired={isRequired}
            subLabel={subLabel}
            emptyText={emptyText}
            attachments={_.get(fieldData, "attachments", [])}
            value={JSON.stringify(value)}
            resolvedValue={JSON.stringify(
              _.get(fieldData, "resolvedMinimalTree", "")
            )}
            fieldId={fieldId}
            fieldKey={key}
            defaultOptions={JSON.stringify(fieldOptions)}
            placeholder={placeholder}
            updateInputField={this.updateField}
            onBlurInputField={this.onBlurInputField}
            onFocusInputField={this.onFocusInputField}
            styles={fieldStyles}
            prompts={prompts}
            toolType={toolType}
            sourceType={sourceType}
            fieldTypeConfig={fieldTypeConfig}
            dependentFieldValue={dependentFieldValue}
            orgId={orgId}
            curriculumType={curriculumType}
            setStepsRef={this.props.setStepsRef}
          />
        </div>
      );
    });
  };

  toggleDeleteDialogueBoxDisplay = () => {
    this.setState({ currentDialogAction: "" });
  };

  onConfirmUpdate = async () => {
    const { currentDialogAction } = this.state;

    if (_.isEqual(currentDialogAction, "MEASURE_ASSESSING")) {
      this.setState({ isLoading: true });
      await this.props.clearAssessmentTool();
      this.setState({ isLoading: false });
      return;
    }

    const { portalType, field_list } = this.props;
    const { key, newValue } = this.updatedField;
    const fieldObj = _.find(this.props.resourceFields, { uid: key }) || {};
    const id = _.get(fieldObj, "id", "");
    const value = { id, value: newValue, portalType };

    const fieldTemplateObj = field_list[key];
    this.props.updateFieldInCache({ params: value, fieldObj });

    this.props.updateField({
      key: id,
      params: value,
      fieldTemplateObj,
      type: this.props.parentType,
    });
  };

  getBlockText = () => {
    const { configurableSteps, step_list, t } = this.props;
    const stepArray = [];

    _.forEach(configurableSteps, key => {
      const { label } = step_list[key] || {};

      stepArray.push(label);
    });

    return t("common:add_array_with_and", {
      array: stepArray,
    });
  };

  shouldRenderOnLeft = fields => {
    const { field_list } = this.props;

    const fieldsToRenderOnLeft = _.filter(fields, field => {
      const { config: { shouldRenderOnLeft } = {} } = field_list[field]
        ? field_list[field]
        : {};

      return shouldRenderOnLeft ?? false;
    });

    if (!_.isEmpty(fieldsToRenderOnLeft)) {
      return fieldsToRenderOnLeft;
    } else {
      return [];
    }
  };

  render() {
    const {
      step_list,
      steps,
      onScroll,
      setStepsRef,
      t,
      warningMsg,
      assessmentType,
    } = this.props;

    const { isLoading, currentDialogAction } = this.state;

    const assessmentTypeLabel =
      LE_ASSESSMENT_TYPE_MAPPING[assessmentType]?.localeLabel;

    return (
      <React.Fragment>
        <div
          className={classes.container}
          ref={ref => (this.container = ref)}
          onScroll={e => onScroll(e)}
        >
          {warningMsg ? (
            <div className={classes.alertContainer}>
              <Alert type={"warning"} message={warningMsg} />
            </div>
          ) : null}
          <div className={classes.innerContainer}>
            {_.map(steps, key => {
              const { fields, subtext, subText } = step_list[key] || {};

              const label = _.get(step_list[key], "label", "");
              const fieldsToRenderOnLeft = this.shouldRenderOnLeft(fields);

              return (
                <div
                  className={classes.stepContainer}
                  key={key}
                  ref={ref => setStepsRef({ ref, key })}
                >
                  <div className={classes.stepLabel}>
                    <div className={classes.headerTxtContainer}>
                      <div className={classes.textContainer}>
                        {!_.isEmpty(fieldsToRenderOnLeft)
                          ? this.renderFieldComponent(fieldsToRenderOnLeft)
                          : label}
                      </div>
                    </div>

                    {subtext && (
                      <div className={classes.stepSubText}>{subtext}</div>
                    )}
                    {subText && (
                      <div className={classes.stepSubText}>{subText}</div>
                    )}
                  </div>

                  <div className={classes.formContainer}>
                    {this.renderFieldComponent(
                      _.difference(fields, fieldsToRenderOnLeft)
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {currentDialogAction && (
            <DialogueBoxV2
              modalTitle={DIALOGS_INFO[currentDialogAction]?.title({
                t,
                tag: currentDialogAction,
                assessmentTypeLabel,
              })}
              showModal={true}
              onClickButton2={this.onConfirmUpdate}
              modalBody={DIALOGS_INFO[currentDialogAction]?.message({
                t,
                tag: currentDialogAction,
                assessmentTypeLabel,
              })}
              toggleDialogueBoxDisplay={this.toggleDeleteDialogueBoxDisplay}
              button1={DIALOGS_INFO[currentDialogAction]?.cancelButtonText({
                t,
              })}
              button2={DIALOGS_INFO[currentDialogAction]?.confirmButtonText({
                t,
                tag: currentDialogAction,
              })}
              button1Props={styles.button1Props}
              button2Props={styles.button2Props}
            />
          )}
        </div>
        {isLoading && <FullScreenLoader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parentType, t } = ownProps;
  const organizationSubjectLevels = _.get(
    state,
    "platform.organizationSubjectLevels",
    []
  );
  return {
    lockedFields:
      state.pusher.lockedFields[enityEnumToLocalTypeMapping[parentType]],
    orgId: _.get(state, "login.userInfo.org_id"),
    organizationSubjectLevels,
  };
};

const mapActionCreators = {};

MiddlePaneV2.propTypes = {
  isLocalisedTemplate: PropTypes.bool,
};
MiddlePaneV2.defaultProps = {
  isLocalisedTemplate: true,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlanTemplate", "unitPlan", "common"] })
)(MiddlePaneV2);

const CustomButtonComponent = props => {
  const { isRecording } = props;
  const { t } = useI18n(["common"]);

  return (
    <Button
      icon={<MicrophoneOutlined size={"xx-small"} />}
      variant={"outlined-subtle"}
      disabled={isRecording}
    >
      {isRecording ? t("common:recording_instructions") : "Record Audio"}
    </Button>
  );
};

const WrapperFieldLabelComponent = React.memo(props => {
  const {
    label,
    subText,
    fieldKey,
    fieldId,
    prompts,
    defaultOptions,
    value,
    resolvedValue,
    portalType,
    sourceType,
  } = props;
  const labelComponent = label && (
    <LabelComponent
      label={label}
      labelStyle={styles.labelStyle}
      labelCoachmarkId={
        fieldKey == "resources" ? `COACHMARKS_RESOURCE_${fieldId}` : undefined
      }
      promptsName={"Planning Prompts"}
      prompts={prompts}
      showHelper={false}
      showComment={false}
      subText={subText}
      subTextStyle={styles.subTextStyle}
    />
  );

  return (
    <FieldComponent
      {..._.omit(props, ["cardType"])}
      {...styles.customStyles}
      editContainerStyle={styles.editContainerStyle}
      value={JSON.parse(value)}
      resolvedValue={JSON.parse(resolvedValue)}
      labelComponent={labelComponent}
      defaultOptions={JSON.parse(defaultOptions)}
      portalType={portalType}
      sourceType={sourceType}
      isShowIconsInBar={true}
      showV2Icons={true}
      buttonComponent={<CustomButtonComponent />}
      isAssessmentCreationRevamp={true}
      isLastAttachment={true} // this is to disable audio instructions player while uploading
      showResetButton={true} // this adds reset button to time selector (for proposed duration)
    />
  );
});

WrapperFieldLabelComponent.displayName = "WrapperFieldLabelComponent";
