import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
export default store => {
  return {
    path: "le/:leId/:mode",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./LeDetails").default;
              const injectedModule = require("LearningEngagement/modules/LeModule");
              injectReducer(store, {
                key: injectedModule.NAME,
                reducer: injectedModule.default,
              });
              const reducer = require("UnitPlans/modules/UnitPlanModule")
                .default;
              injectReducer(store, { key: "unitPlans", reducer });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
  };
};
