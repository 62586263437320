import { routes } from "Projects/config/routes";
import SetupRoute from "Projects/routes/Setup";
import DeadlinesRoute from "Projects/routes/Deadlines";
import GuidanceRoute from "Projects/routes/Guidance";
import ProgressRoute from "Projects/routes/Progress";
import CreateProject from "Projects/routes/CreateProject";
import StudentPortfolios from "Projects/routes/StudentPortfolios";
import ProjectGroup from "./components";
import ServiceAsActionInsights from "Projects/routes/ServiceAsActionInsights";
import CasInsights from "Projects/routes/CasInsights";
import OverviewRoute from "Projects/routes/Overview";
import StimuliSetupRoute from "Projects/routes/StimuliSetup";

const {
  projectGroup: { path },
} = routes;

export default store => {
  return {
    path,
    component: ProjectGroup,
    childRoutes: [
      SetupRoute(store),
      DeadlinesRoute(store),
      GuidanceRoute(store),
      ProgressRoute(store),
      CreateProject(store),
      ServiceAsActionInsights(store),
      StudentPortfolios(store),
      OverviewRoute(store),
      StimuliSetupRoute(store),
      CasInsights(store),
    ],
  };
};
