import React, { PureComponent, useEffect } from "react";
import { connect } from "react-redux";
import classes from "./UnitSelection.scss";

import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, UILabel, Loading } from "UIComponents";
import UnitSelectionFeed from "./UnitSelectionFeed";
import { getSubjectLabel, getCurrentAcademicYear } from "modules/Services";
import { EmptySvgIcon } from "SvgComponents";
import { getUserInfo } from "Login/modules/LoginModule";
import update from "immutability-helper";
import {
  getNodeUnitPlanFeedFromCache,
  getNodeUnitPlanEvidenceFeedFromCache,
  getFlatUnitPlanFeedFromCache,
  getUnitPlanFeedFromCache,
} from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import {
  getNodeUnitPlanFeedQuery,
  getNodeUnitPlanEvidenceFeedQuery,
  getFlatUnitPlanFeedQuery,
  getUnitPlanFeedQuery,
} from "UnitPlans/modules/UnitPlanQuery";
import {
  FilterLabelButton,
  ChecklistDropdown,
  SearchInput,
  EmptyState,
  Alert,
} from "@toddle-design/web";

import { checkScrollAtBottom, getStringFromArray } from "Utils";
import _ from "lodash";
import { defaultOrderConstants } from "UnitPlans/modules/UnitPlanModule";
import { NoDataIllustration } from "@toddle-design/theme";

const emptyStateSubtitleStyle = {
  maxWidth: "280px",
  lineHeight: "24px",
};

const EmptyView = ({ t, emptyMessage, emptySubTitle }) => {
  return (
    <div className={classes.noDataContainer}>
      <EmptyState
        illustration={NoDataIllustration}
        title={t(emptyMessage)}
        subtitle={t(emptySubTitle)}
        subtitleStyle={emptyStateSubtitleStyle}
      />
    </div>
  );
};

const DUMMY_UNIT_SET = [
  {
    units: [],
    title: "Units",
  },
];

let UnitSelectionFeedLocal = ({
  styles,
  parentType,
  isMultiSelect,
  helperLabel,
  updateSelectedItems,
  cardDimensions,
  selectedItems,
  disabledItems,
  isSearchEnabled,
  showEmptyState,
  handleUnitSearch,
  searchText,
  selectedCourseId,
  unitPlans,
  emptyMessage,
  updateState,
  isRadioBehavior,
  cardTheme,
  onClickView,
  customEmptyViewElement,
  showGrades,
  showSubTitleTooltip,
  t,
  handleScroll,
  hasNextPage,
  areMorePlansLoading,
  loadMoreFeed,
  cardWrapperElement,
  emptyView,
  isData,
  isLoading,
  customHeaderElement,
  allUnitsCount,
  title,
  updateUnitPlanFeeds,
  emptySubTitle,
}) => {
  const unitListLength = _.get(unitPlans, "length", 0);
  useEffect(() => {
    updateState({
      unitsLength: _.sum(
        _.map(unitPlans, unit => _.get(unit, "units.length", 0))
      ),
      title: t("common:unit"),
    });
    updateUnitPlanFeeds?.(_.get(unitPlans, "0.units", []));
  }, [unitPlans]);
  const updateSelectedItemsLocal = ({ id }) => {
    const selectedUnit = _.find(
      _.get(_.head(unitPlans), "units", []),
      unit => unit.id === id
    );
    if (isMultiSelect) {
      const index = _.indexOf(selectedItems, id);
      let selectedItemsLocal = [];
      if (index < 0) {
        selectedItemsLocal = update(selectedItems, { $push: [id] });
      } else {
        selectedItemsLocal = update(selectedItems, { $splice: [[index, 1]] });
      }
      updateSelectedItems(selectedItemsLocal);
    } else {
      if (isRadioBehavior) {
        updateSelectedItems(id);
      } else {
        if (selectedItems == id) {
          updateSelectedItems("", "");
        } else {
          updateSelectedItems(
            id,
            _.get(selectedUnit, "templateId", ""),
            _.get(selectedUnit, "title", ""),
            _.get(selectedUnit, "subTitle", ""),
            _.get(selectedUnit, "image", ""),
            _.get(selectedUnit, "curriculumProgramId", "")
          );
        }
      }
    }
  };

  useEffect(() => {
    if (handleScroll)
      handleScroll.current = e => {
        const containerDiv = e.target;
        if (
          containerDiv &&
          hasNextPage &&
          !areMorePlansLoading &&
          checkScrollAtBottom(containerDiv)
        ) {
          loadMoreFeed();
        }
      };
  }, [handleScroll, hasNextPage, loadMoreFeed, areMorePlansLoading]);

  return (
    <React.Fragment>
      {unitListLength > 0 ? (
        <React.Fragment>
          {customHeaderElement ? (
            React.cloneElement(customHeaderElement, {
              count: allUnitsCount,
            })
          ) : title ? (
            <div
              className={classes.headerContainer}
              style={styles.headerContainerStyle}
            >
              <div className={classes.titleText}> {title}</div>
              <div className={classes.countText}>{`(${allUnitsCount})`}</div>
            </div>
          ) : null}
          {_.map(unitPlans, item => {
            const { units, title, id } = item;

            return (
              <UnitSelectionFeed
                styles={styles}
                parentType={parentType}
                title={title}
                isMultiSelect={isMultiSelect}
                items={units}
                key={id}
                helperLabel={helperLabel}
                updateSelectedItems={updateSelectedItemsLocal}
                cardDimensions={cardDimensions}
                selectedItems={selectedItems}
                disabledItems={disabledItems}
                isSearchEnabled={isSearchEnabled}
                showEmptyState={showEmptyState}
                handleUnitSearch={handleUnitSearch}
                searchText={searchText}
                selectedCourseId={selectedCourseId}
                cardTheme={cardTheme}
                onClickView={onClickView}
                showGrades={showGrades}
                showSubTitleTooltip={showSubTitleTooltip}
                cardWrapperElement={cardWrapperElement}
                isData={isData}
                isLoading={isLoading}
              />
            );
          })}
          {areMorePlansLoading && (
            <div className={classes.loadingContainer}>
              <Loading />
            </div>
          )}
        </React.Fragment>
      ) : customEmptyViewElement ? (
        customEmptyViewElement
      ) : emptyView ? (
        emptyView
      ) : (
        <EmptyView
          emptyMessage={emptyMessage}
          t={t}
          emptySubTitle={emptySubTitle}
        />
      )}
    </React.Fragment>
  );
};

const getStaffUnitPlans = ({ data }) => {
  let unitPlans = [];
  _.forEach(_.get(data, "unitPlans.edges", []), item => {
    const units = _.get(item, "node", []);
    if (!_.isNil(units)) {
      unitPlans = [...unitPlans, ...units];
    }
  });
  return unitPlans;
};

UnitSelectionFeedLocal = compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getUnitPlanFeedQuery, {
    name: "getUnitPlanFeed",
    skip: ({ parentType }) => parentType !== "STAFF",
    options: ({
      userId,
      grades,
      portalType,
      courseId,
      parentType,
      searchText,
      unitTypes,
      collaborativeAccess,
      academicYears,
    }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: getUnitPlanFeedVariable({
          userId,
          courseId,
          parentType,
          grades,
          portalType,
          searchText,
          showCollaborators: false,
          unitTypes,
          collaborativeAccess,
          academicYears,
        }),
      };
    },
    props({
      getUnitPlanFeed,
      ownProps: {
        userId,
        courseId,
        isData,
        isLoading,
        parentType,
        grades,
        searchText,
        unitTypes,
        collaborativeAccess,
        t,
        isSearchEnabled,
        excludeUnitPlanIds,
        academicYears,
      },
    }) {
      const data = getUnitPlanFeedFromCache(
        getUnitPlanFeedVariable({
          userId,
          courseId,
          parentType,
          grades,
          searchText,
          unitTypes,
          collaborativeAccess,
          showCollaborators: false,
          academicYears,
        })
      );
      const unitPlans = getStaffUnitPlans({ data });
      const allUnits = getUnits({
        units: unitPlans,
      });

      const memoizedUnitPlans = getGroupedUnitPlansMemoize({
        t,
        allUnits,
        excludeUnitPlanIds,
      });
      return {
        emptyMessage:
          _.get(grades, "length") > 0
            ? "unitPlan:no_unit_created_in_grade"
            : "unitPlan:no_unit_created",
        getUnitPlanFeed,
        unitPlans: _.get(memoizedUnitPlans, "length")
          ? memoizedUnitPlans
          : isSearchEnabled
          ? DUMMY_UNIT_SET
          : memoizedUnitPlans,
        isData: isData && !_.isEmpty(data),
        isLoading:
          isLoading ||
          getUnitPlanFeed["networkStatus"] == 1 ||
          getUnitPlanFeed["networkStatus"] == 2,
      };
    },
  }),
  graphql(getFlatUnitPlanFeedQuery, {
    name: "getFlatUnitPlanFeed",
    skip: ({ parentType }) =>
      parentType === "ORGANIZATION" ||
      parentType === "EVIDENCE" ||
      parentType === "STAFF",
    options: ({
      userId,
      grades,
      portalType,
      courseId,
      parentType,
      searchText,
      unitTypes,
      collaborativeAccess,
      academicYears,
      first,
    }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: getUnitPlanFeedVariable({
          userId,
          courseId,
          parentType,
          grades,
          portalType,
          searchText,
          showCollaborators: false,
          unitTypes,
          collaborativeAccess,
          academicYears,
          first,
        }),
      };
    },
    props({
      getFlatUnitPlanFeed,
      ownProps: {
        userId,
        courseId,
        isData,
        isLoading,
        parentType,
        grades,
        searchText,
        unitTypes,
        collaborativeAccess,
        t,
        first,
        isSearchEnabled,
        excludeUnitPlanIds,
        academicYears,
      },
    }) {
      const variables = getUnitPlanFeedVariable({
        userId,
        courseId,
        parentType,
        grades,
        searchText,
        unitTypes,
        collaborativeAccess,
        showCollaborators: false,
        academicYears,
        first,
      });
      const data = getFlatUnitPlanFeedFromCache(variables);
      const unitPlans = getCourseUnitPlans({ data });
      const allUnits = getUnits({
        units: unitPlans,
      });

      const memoizedUnitPlans = getGroupedUnitPlansMemoize({
        t,
        allUnits,
        excludeUnitPlanIds,
      });
      const pageInfo = _.get(data, "node.flatUnitPlans.pageInfo", {});
      const totalCount = _.get(data, "node.flatUnitPlans.totalCount", 0);
      return {
        emptyMessage:
          _.get(grades, "length") > 0
            ? "unitPlan:no_unit_created_in_grade"
            : "unitPlan:no_unit_created",
        getFlatUnitPlanFeed,
        unitPlans: _.get(memoizedUnitPlans, "length")
          ? memoizedUnitPlans
          : isSearchEnabled
          ? DUMMY_UNIT_SET
          : memoizedUnitPlans,
        isData: isData && !_.isEmpty(data),
        isLoading:
          isLoading ||
          getFlatUnitPlanFeed["networkStatus"] == 1 ||
          getFlatUnitPlanFeed["networkStatus"] == 2,
        hasNextPage: pageInfo.hasNextPage,
        allUnitsCount: totalCount,
        areMorePlansLoading: getFlatUnitPlanFeed.networkStatus === 3,
        loadMoreFeed: () => {
          return getFlatUnitPlanFeed.fetchMore({
            query: getFlatUnitPlanFeedQuery,
            variables: {
              ...variables,
              after: pageInfo.endCursor,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousItems = previousResult.node.flatUnitPlans;
              const nextItems = fetchMoreResult.node.flatUnitPlans;

              const previousEdges = previousItems.edges;
              const nextEdges = nextItems.edges;

              const previousPageInfo = previousItems.pageInfo;
              const nextPageInfo = nextItems.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;

              return {
                ...previousResult,
                node: {
                  ...previousResult.node,
                  flatUnitPlans: {
                    ...previousItems,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withLoader
)(UnitSelectionFeedLocal);

class UnitSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unitsLength: [],
    };
  }

  render() {
    const {
      t,
      styles,
      helperLabel,
      isSearchEnabled,
      searchText,
      handleUnitSearch,
      disabledItems,
      totalCount,
      showFilters,
      customSearchBarStyle,
      coursesLabelValue,
      unitTypeLabelValue,
      coursesDropdownOptionsArr,
      unitTypeDropdownOptionsArr,
      courses,
      unitType,
      updateSelectedUnitTypes,
      handleScroll,
      updateSelectedCourses,
      showAlert,
    } = this.props;
    const { unitsLength, title } = this.state;
    const allUnitsCount = totalCount ?? unitsLength;

    return (
      <div className={classes.container} style={styles.containerStyle}>
        <div
          className={classes.feedContainer}
          style={styles.feedContainerStyle}
        >
          {/* do not render headerLabel until title is available */}
          {helperLabel && title ? (
            showAlert ? (
              <div className={classes.alertContainer}>
                <Alert title={helperLabel} showIcon={false} />
              </div>
            ) : (
              <UILabel
                labelStyle={styles.helperTextStyle}
                label={helperLabel}
              />
            )
          ) : null}
          <div className={classes.searchFilterContainer}>
            {isSearchEnabled && (allUnitsCount || searchText || showFilters) ? (
              <div
                className={classes.searchInputContainer}
                style={customSearchBarStyle}
              >
                <SearchInput
                  value={searchText}
                  onChange={_.debounce(handleUnitSearch, 200)}
                  placeholder={t("common:search_for_a_with_label", {
                    label: t("common:unit").toLowerCase(),
                  })}
                />
              </div>
            ) : null}
            {showFilters && (
              <div className={classes.filtersContainer}>
                <ChecklistDropdown
                  options={coursesDropdownOptionsArr}
                  value={courses}
                  onChange={value => updateSelectedCourses(value)}
                  showAllOption={true}
                >
                  <FilterLabelButton
                    variant="plain"
                    label={t("common:class")}
                    labelValue={coursesLabelValue}
                  />
                </ChecklistDropdown>

                <ChecklistDropdown
                  options={unitTypeDropdownOptionsArr}
                  value={unitType}
                  onChange={value => updateSelectedUnitTypes(value)}
                  showAllOption={true}
                >
                  <FilterLabelButton
                    variant="plain"
                    label={t("common:unit_type_label")}
                    labelValue={unitTypeLabelValue}
                  />
                </ChecklistDropdown>
              </div>
            )}
          </div>
          <UnitSelectionFeedLocal
            {...this.props}
            allUnitsCount={allUnitsCount}
            title={title}
            handleScroll={handleScroll}
            disabledItems={disabledItems}
            updateState={this.setState.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const mapActionCreators = {};

const getUnitPlanObject = ({ unit, type }) => {
  const title = _.get(unit, "title.value", "");
  const unitType = _.get(unit, "unitType.value", "");
  const subjects = _.get(unit, "subjects.value", []);
  const duration = _.get(unit, "duration.value", {});
  const unitFlowEdges = _.get(unit, "unitFlow.edges", []);
  const image = _.get(unit, "image.value", "");
  const templateId = _.get(unit, "templateId", "");
  const curriculumProgramId = _.get(unit, "curriculumProgram.id", "");

  let assessmentCount = 0;
  let leCount = 0;
  if (!_.isEmpty(_.get(unit, "resourceLibrary", {}))) {
    assessmentCount = _.get(unit, "resourceLibrary.assessmentCount", 0);
  } else {
    assessmentCount = _.get(unit, "assessmentLibrary.assessmentCount", 0);
  }

  if (!_.isEmpty(_.get(unit, "resourceLibrary", {}))) {
    leCount = _.get(unit, "resourceLibrary.leCount", 0);
  } else {
    leCount = _.get(unit, "leLibrary.leCount", 0);
  }

  let subTitle = "";
  if (unitType == "ibStandAlone") {
    subTitle = getStringFromArray({ nameArray: getSubjectLabel(subjects) });
  } else {
    subTitle = _.join(
      _.map(
        _.get(unit, "theme.resolvedMinimalTree.themes", []),
        theme => theme.label
      ),
      ", "
    );
  }

  const scheduledResourceLibrary = _.reduce(
    unitFlowEdges,
    (sum, edge) => {
      let resourceEdges = _.get(edge, `node.items.edges`, []);

      if (!resourceEdges) {
        resourceEdges = [];
      }
      return [...sum, ...resourceEdges];
    },
    []
  );

  let grade = "";

  if (type === "EVIDENCE") {
    grade = _.join(
      _.map(_.get(unit, "resolvedGrades", []), grade => grade.name),
      ", "
    );
  }

  return {
    subTitle,
    title,
    id: unit.id,
    image,
    duration,
    assessmentCount,
    resourceLibraryCount: assessmentCount + leCount,
    resourceLibrary: scheduledResourceLibrary,
    templateId,
    grade,
    curriculumProgramId,
  };
};

const getUnits = ({ units, type }) => {
  return _.map(units, unit => {
    return getUnitPlanObject({ unit, type });
  });
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });
  let first = 20;
  if (!ownProps.isQueryPaginated) {
    first = undefined;
  }

  const orgId = userInfo.org_id;
  const { startDate, endDate } =
    getCurrentAcademicYear({
      organizationId: orgId,
    }) || {};
  return {
    startDate,
    endDate,
    first,
    courseId: !_.isEmpty(ownProps.courseId)
      ? ownProps.courseId
      : state.teacher.selected_class.selected_course,
    grades:
      ownProps.grades ?? state.teacher.selected_class.selected_grades ?? [],
    userId: userInfo.id,
    isData: true,
    isLoading: false,
    orgId,
  };
};

const getCourseUnitPlans = ({ data }) => {
  return _.map(_.get(data, "node.flatUnitPlans.edges", []), unitPlan =>
    _.get(unitPlan, "node", {})
  );
};

const getGroupedUnitPlans = ({ t, allUnits, excludeUnitPlanIds }) => {
  const groupUnits = [];

  if (!_.isEmpty(excludeUnitPlanIds)) {
    allUnits = _.filter(
      allUnits,
      item => !_.includes(excludeUnitPlanIds, item.id)
    );
  }
  const allUnitsLength = _.get(allUnits, "length", 0);
  if (allUnitsLength > 0) {
    groupUnits.push({
      id: "ALL",
      title: t(`common:unit`, { count: allUnitsLength }),
      units: allUnits,
    });
  }

  return groupUnits;
};

const getGroupedUnitPlansMemoize = _.memoize(
  params => getGroupedUnitPlans(params),
  params => JSON.stringify(params)
);

const getUnitPlanFeedVariable = variables => {
  const {
    parentType: type,
    userId,
    courseId,
    grades,
    portalType,
    searchText,
    showCollaborators,
    unitTypes,
    collaborativeAccess,
    academicYears,
    first,
  } = variables;
  switch (type) {
    case "COURSE":
      return {
        id: courseId,
        ...{ ...defaultOrderConstants, first },
        filters: {
          grades,
          academicYears,
          searchText: searchText ?? "",
          unitType: unitTypes ?? [],
        },
        showCollaborators,
      };
    case "STAFF":
      return {
        id: userId,
        type,
        filters: {
          grades,
          searchText: searchText ?? "",
          unitTypes: unitTypes ?? [],
          collaborativeAccess: collaborativeAccess ?? [],
        },
        portalType,
        showCollaborators,
      };
  }
};

const ComposedUnitSelection = compose(
  I18nHOC({ resource: ["scheduler", "common", "unitPlan"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getNodeUnitPlanFeedQuery, {
    name: "getNodeUnitPlanFeed",
    skip: ({ parentType }) => parentType != "ORGANIZATION",
    options: ({
      parentId,
      parentType,
      grades,
      searchText,
      academicYears,
      unitType,
      first,
      users,
      curriculumProgramIds,
    }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: parentId,
          first,
          type: parentType,
          filters: {
            grades,
            searchText,
            academicYears,
            unitType,
            users,
            curriculumProgramIds,
          },
          showCollaborators: false,
        },
      };
    },
    props({
      getNodeUnitPlanFeed,
      ownProps: {
        parentId,
        parentType,
        searchText,
        unitType,
        isData,
        isLoading,
        shouldGroupScheduledFeed,
        first,
        users,
        t,
        curriculumProgramIds,
        grades,
        academicYears,
      },
    }) {
      const data = getNodeUnitPlanFeedFromCache({
        id: parentId,
        type: parentType,
        first,
        filters: {
          grades,
          searchText,
          academicYears,
          unitType,
          users,
          curriculumProgramIds,
        },
        showCollaborators: false,
      });
      const allUnits = getUnits({
        units: _.map(_.get(data, "unitPlansV2.edges", []), obj => obj.node),
      });

      const unitPlans = getGroupedUnitPlansMemoize({
        allUnits,
        shouldGroupScheduledFeed,
        t,
      });

      const totalCount = _.get(data, "unitPlansV2.totalCount", 0);

      return {
        emptyMessage:
          _.get(grades, "length") > 0
            ? "unitPlan:no_unit_created_in_grade"
            : "unitPlan:no_unit_created",
        unitPlans,
        totalCount,
        isData: isData && !_.isEmpty(data),
        isLoading:
          isLoading ||
          getNodeUnitPlanFeed["networkStatus"] == 1 ||
          getNodeUnitPlanFeed["networkStatus"] == 2,
        hasNextPage: _.get(data, "unitPlansV2.pageInfo.hasNextPage", false),
        areMorePlansLoading: getNodeUnitPlanFeed["networkStatus"] === 3,

        loadMoreFeed: () => {
          return getNodeUnitPlanFeed.fetchMore({
            query: getNodeUnitPlanFeedQuery,
            variables: {
              id: parentId,
              type: parentType,
              first,
              filters: {
                grades,
                searchText,
                academicYears,
                unitType,
                users,
                curriculumProgramIds,
              },
              after: _.get(
                getNodeUnitPlanFeed,
                "node.unitPlansV2.pageInfo.endCursor",
                ""
              ),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousItems = previousResult.node.unitPlansV2;
              const nextItems = fetchMoreResult.node.unitPlansV2;

              const previousEdges = previousItems.edges;
              const nextEdges = nextItems.edges;

              const nextPageInfo = nextItems.pageInfo;

              return {
                ...previousResult,
                node: {
                  ...previousResult.node,
                  unitPlansV2: {
                    ...previousItems,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: nextPageInfo,
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(getNodeUnitPlanEvidenceFeedQuery, {
    name: "getNodeUnitPlanEvidenceFeed",
    alias: "getNodeUnitPlanEvidenceFeed",
    skip: ({ parentType }) => parentType !== "EVIDENCE",
    options: ({ parentId, filters, queryType, first }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: parentId,
          type: queryType,
          filters,
          first,
        },
      };
    },
    props({
      getNodeUnitPlanEvidenceFeed,
      ownProps: {
        parentId,
        parentType,
        filters,
        isData,
        isLoading,
        queryType,
        t,
        first,
      },
    }) {
      const data = getNodeUnitPlanEvidenceFeedFromCache({
        id: parentId,
        type: queryType,
        filters,
        first,
      });

      const allUnits = getUnits({
        units: _.map(_.get(data, "unitPlansV2.edges", []), obj => obj.node),
        type: parentType,
      });

      const unitPlans = getGroupedUnitPlansMemoize({
        allUnits,
        t,
      });

      const totalCount = _.get(data, "unitPlansV2.totalCount", 0);

      return {
        unitPlans,
        totalCount,
        isData: isData && !_.isEmpty(data),
        isLoading:
          isLoading ||
          getNodeUnitPlanEvidenceFeed["networkStatus"] == 1 ||
          getNodeUnitPlanEvidenceFeed["networkStatus"] == 2,
        hasNextPage: _.get(data, "unitPlansV2.pageInfo.hasNextPage", false),
        areMorePlansLoading: getNodeUnitPlanEvidenceFeed["networkStatus"] === 3,

        loadMoreFeed: () => {
          return getNodeUnitPlanEvidenceFeed.fetchMore({
            query: getNodeUnitPlanEvidenceFeedQuery,
            variables: {
              id: parentId,
              type: queryType,
              filters,
              first,
              after: _.get(
                getNodeUnitPlanEvidenceFeed,
                "node.unitPlansV2.pageInfo.endCursor",
                ""
              ),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousItems = previousResult.node.unitPlansV2;
              const nextItems = fetchMoreResult.node.unitPlansV2;

              const previousEdges = previousItems.edges;
              const nextEdges = nextItems.edges;

              const nextPageInfo = nextItems.pageInfo;

              return {
                ...previousResult,
                node: {
                  ...previousResult.node,
                  unitPlansV2: {
                    ...previousItems,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: nextPageInfo,
                  },
                },
              };
            },
          });
        },
      };
    },
  })
)(UnitSelection);

ComposedUnitSelection.defaultProps = {
  shouldGroupScheduledFeed: true,
  isMultiSelect: false,
  styles: {},
  searchText: null,
  isRadioBehavior: false, //It works like radio button behaviour
  handleScroll: null,
  isQueryPaginated: false,
  showGrades: false,
  showSubTitleTooltip: false,
  parentType: "COURSE", ////From where you want to fetch unit plans. E.g - COURSE,STAFF
  showFilters: false,
  emptySubTitle: "unitPlan:create_unit_for_grade",
  showAlert: false,
};

export default ComposedUnitSelection;
