import React, { useState } from "react";
import classes from "./RemoveItemsModal.scss";
import { UIModal, UIButton } from "UIComponents";
import { CloseOutlined } from "@toddle-design/web-icons";
import { AssessmentIllustration } from "@toddle-design/theme";
import { fontStyles } from "@toddle-design/theme";
import PropTypes from "prop-types";
import { useI18n } from "Hooks";

const buttonContainerStyle = {
  fontStyle: fontStyles.bold,
};

const RemoveItemsModal = props => {
  const {
    items,
    onClickButton1,
    onClickButton2,
    buttonText1,
    buttonText2,
    modalTitle,
    noItemsText,
    noItemsSubText,
    setRemovedItemsList,
    modalDescription,
  } = props;

  const [itemsList, setItemslist] = useState(items);
  const onRemoveItem = item => {
    const newItemsList = _.filter(itemsList, itemObj => itemObj.id !== item.id);
    setItemslist(newItemsList);
    setRemovedItemsList(prevList => [...prevList, item]);
  };
  const isButton2Disabled = _.isEmpty(items);
  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      overlayContainer={classes.modalOverlayContainer}
      onRequestClose={onClickButton1}
    >
      <div className={classes.container}>
        <div className={classes.header}>{modalTitle}</div>
        <div className={classes.body}>
          <div className={classes.modalDescription}>{modalDescription}</div>
          {_.isEmpty(items) ? (
            <div className={classes.emptyTagsContainer}>
              <div className={classes.emptyTagsIcon}>
                <img
                  src={AssessmentIllustration}
                  width={"152px"}
                  height={"128px"}
                />
              </div>
              <div className={classes.emptyTagsTextContainer}>
                <div className={classes.emptyTagsTextHeader}>{noItemsText}</div>
                <div className={classes.emptyTagsTextDescription}>
                  {noItemsSubText}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.tagsContainer}>
              <div className={classes.selectedTagsContainer}>
                {_.map(itemsList, item => {
                  const onItemCancelClick = () => {
                    onRemoveItem(item);
                  };
                  return (
                    <div key={item.id} className={classes.selectedTag}>
                      <div className={classes.selectedTagText}>
                        {item.label}
                      </div>
                      <div
                        onClick={onItemCancelClick}
                        className={classes.selectedTagIcon}
                      >
                        <CloseOutlined variant={"on"} size={"xxx-small"} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <UIButton
            size="sm"
            onClick={onClickButton1}
            containerStyle={buttonContainerStyle}
          >
            {buttonText1}
          </UIButton>
          <UIButton
            size="sm"
            onClick={onClickButton2}
            containerStyle={{ ...buttonContainerStyle, marginLeft: "16px" }}
            color="pink"
            isDisabled={isButton2Disabled}
          >
            {buttonText2}
          </UIButton>
        </div>
      </div>
    </UIModal>
  );
};

RemoveItemsModal.defaultProps = {
  buttonText1: "Cancel",
  buttonText2: "Save",
  modalTitle: "Remove Items",
  noItemsText: "No items created yet",
  noItemsSubText: "Go ahead and create your first item",
  modalDescription:
    "Please note that this is a cumulative list of items added to the selected standards. Removing an item will remove it from all the standards they are added to.",
};
RemoveItemsModal.propTypes = {
  buttonText1: PropTypes.string,
  buttonText2: PropTypes.string,
  modalTitle: PropTypes.string,
  noItemsText: PropTypes.string,
  noItemsSubText: PropTypes.string,
  items: PropTypes.array,
  onClickButton1: PropTypes.func,
  onClickButton2: PropTypes.func,
  setRemovedItemsList: PropTypes.func,
  modalDescription: PropTypes.string,
};
export default RemoveItemsModal;
