import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

import Setup from "./routes/Setup";
import YearGroups from "./routes/YearGroups";
import TemplateConfig from "./routes/TemplateConfig";

export default store => ({
  path: "transcripts",
  component: withAsyncRouteLoading(
    () =>
      new Promise(callback => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/TranscriptModules").default;
            injectReducer(store, { key: "transcripts", reducer });
            callback(component);
          },
          "transcript-chunk"
        );
      })
  ),

  childRoutes: [Setup(store), YearGroups(store), TemplateConfig(store)],
});
