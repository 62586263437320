import gql from "graphql-tag";

export const elementSetNotCoverageCountFragment = {
  elementSetNotCoverageCount: gql`
    fragment elementSetNotCoverageItemCount on GenericYearlyInsightPlannerElementSetCoverage {
      type
      leafNodeCount
      plannerElementNode {
        id
      }
    }
  `,
};
