import React from "react";
import { subscribeToChannel, unSubscribeToChannel } from "modules/PusherModule";
import { connect } from "react-redux";
import { getHOCDisplayName } from "Utils";

const PubsherSubscribeHoc = channels => {
  return WrappedComponent => {
    class WithPusherSubscribe extends React.PureComponent {
      componentDidMount = () => {
        this.subscribeToChannel(this.props);
      };
      //TODO: Potentially computation heavy operation. may slow the component
      UNSAFE_componentWillReceiveProps = nextProps => {
        if (
          JSON.stringify(nextProps.pusherData) !=
          JSON.stringify(this.props.pusherData)
        ) {
          this.unSubscribeToChannel(this.props);
          this.subscribeToChannel(nextProps);
        }
      };

      componentWillUnmount = () => {
        this.unSubscribeToChannel(this.props);
      };

      unSubscribeToChannel = props => {
        const { unSubscribeToChannel, pusherData, hasPusherPermission } = props;

        _.map(channels, channel => {
          unSubscribeToChannel({ data: pusherData, channelType: channel });
        });
      };

      subscribeToChannel = props => {
        const { subscribeToChannel, pusherData, hasPusherPermission } = props;

        _.map(channels, channel => {
          subscribeToChannel({ data: pusherData, channelType: channel });
        });
      };

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    const mapActionCreators = {
      subscribeToChannel,
      unSubscribeToChannel,
    };

    const mapStateToProps = (state, ownProps) => {
      return {};
    };
    WithPusherSubscribe.displayName = `WithPusherSubscribe(${getHOCDisplayName(
      WrappedComponent
    )})`;
    return connect(mapStateToProps, mapActionCreators)(WithPusherSubscribe);
  };
};
export default PubsherSubscribeHoc;
