import client from "apolloClient";
import { getGlobalGradesQuery } from "./CurriculumOptionsQuery";

export const getGlobalGradesFromCache = () => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getGlobalGradesQuery,
    });
  } catch (error) {
    console.error(error);
  }
  if (queryData != null) {
    return _.get(queryData, "platform.globalGrades", []);
  } else return {};
};
