import React from "react";
import PropTypes from "prop-types";

const DiscussionAddIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 21">
      <g id="V00" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Artboard"
          transform="translate(-580.000000, -34.000000)"
          fill={props.fill}
          fillRule="nonzero"
        >
          <path
            d="M599.4907,34 C601.908006,34.0046446 603.879555,35.8775328 603.994694,38.2300537 L604,38.4388384 L604,47.091192 C603.995282,49.4707242 602.092662,51.4114643 599.702799,51.5248005 L599.4907,51.5300231 L584.841198,51.5300231 L581.396282,54.7633895 C581.24647,54.9160309 581.039639,55.0015426 580.824033,55 C580.713516,55.0004063 580.604207,54.9773491 580.503573,54.932382 C580.233882,54.8195772 580.047581,54.5762033 580.007889,54.2956082 L580,54.1888226 L580,38.4388384 C580.004718,36.0593079 581.907332,34.1185669 584.297194,34.0052232 L584.509293,34 L599.4907,34 Z M599.4907,35.1999978 L584.5116,35.1999978 C582.7431,35.2033987 581.305779,36.5583502 581.205574,38.2557138 L581.2,38.4388384 L581.199,53.302 L584.366258,50.3300231 L599.488396,50.3300253 C601.256896,50.3266302 602.694221,48.9716803 602.794426,47.2743167 L602.800002,47.091192 L602.800002,38.4412179 C602.796586,36.718384 601.420127,35.3041409 599.678639,35.2054897 L599.4907,35.1999978 Z M592,39 C592.368,39 592.66667,39.299 592.66667,39.667 L592.66667,39.667 L592.66667,42.333 L595.33333,42.333 C595.70133,42.333 596,42.632 596,43 C596,43.368 595.70133,43.667 595.33333,43.667 L595.33333,43.667 L592.66667,43.667 L592.66667,46.3333 C592.66667,46.7013 592.368,47 592,47 C591.632,47 591.33333,46.7013 591.33333,46.3333 L591.33333,46.3333 L591.33333,43.667 L588.66667,43.667 C588.29867,43.667 588,43.368 588,43 C588,42.632 588.29867,42.333 588.66667,42.333 L588.66667,42.333 L591.33333,42.333 L591.33333,39.667 C591.33333,39.299 591.632,39 592,39 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

DiscussionAddIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

DiscussionAddIcon.defaultProps = {
  width: 24,
  height: 21,
};

export default DiscussionAddIcon;
