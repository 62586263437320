import React from "react";
import classes from "./PlatformSwitcher.scss";
import { CLOUD_URL } from "Constants";
import {
  menuItemsStyle,
  academicSwitcherStyles,
  toddleCommunityLabelCustomStyle,
} from "./PlatformSwitcherStyles";
import { SwitchOrganizationIcon } from "SvgComponents";
import { SetupOutlined } from "@toddle-design/web-icons";
import { formatDate } from "Utils";
import ACLStore from "lib/aclStore";
import AcademicYearSwitcher from "./AcademicYearSwitcher";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";
import { CurriculumProgramLogo } from "UIComponents";
import { STATUS_SETUP_COMPLETE } from "SchoolOnboardSetup/modules/stringConstants";
import update from "immutability-helper";
import _ from "lodash";

const PYP_LOGO_URL = `${CLOUD_URL}/assets/webapp/PYPLogo.svg`;
const MYP_LOGO_URL = `${CLOUD_URL}/assets/webapp/MYPLogo.svg`;
const UBD_LOGO_URL = `${CLOUD_URL}/assets/webapp/UBDLogo.svg`;
const DP_LOGO_URL = `${CLOUD_URL}/assets/webapp/DPLogo.svg`;
const COMMUNITY_LOGO_URL = `${CLOUD_URL}/assets/webapp/CommunityLogo.svg`;
const ADMIN_PORTAL_LOGO_URL = `${CLOUD_URL}/assets/webapp/AdminPortalLogo.svg`;
export const ACADEMIC_SWITCHER_ICON_URL = `${CLOUD_URL}/assets/webapp/AcademicSwitcherLogo.svg`;

const CURRICULUM_PROGRAM_CONFIGURATIONS = {
  [CURRICULUM_TYPE_PYP]: {
    labelLocale: "common:label_with_home",
    value: null,
    type: "TODDLE_PYP_PLATFORM",
    curriculumProgramType: CURRICULUM_TYPE_PYP,
    isDisable: false,
    svg: <img src={PYP_LOGO_URL} alt={"PYP Platform"} />,
  },
  [CURRICULUM_TYPE_MYP]: {
    labelLocale: "common:label_with_home",
    value: null,
    type: "TODDLE_MYP_PLATFORM",
    curriculumProgramType: CURRICULUM_TYPE_MYP,
    isDisable: false,
    svg: <img src={MYP_LOGO_URL} alt={"MYP Platform"} />,
  },
  [CURRICULUM_TYPE_UBD]: {
    labelLocale: "common:label_with_home",
    value: null,
    type: "TODDLE_UBD_PLATFORM",
    curriculumProgramType: CURRICULUM_TYPE_UBD,
    isDisable: false,
    svg: <img src={UBD_LOGO_URL} alt={"UBD Platform"} />,
  },
  [CURRICULUM_TYPE_DP]: {
    labelLocale: "common:label_with_home",
    value: null,
    type: "TODDLE_DP_PLATFORM",
    curriculumProgramType: CURRICULUM_TYPE_DP,
    isDisable: false,
    svg: <img src={DP_LOGO_URL} alt={"DP"} />,
  },
};

const MENU_ITEM_TYPES = [
  {
    labelLocale: "common:home",
    value: "SNP_VISITOR_HOME",
    type: "SNP_VISITOR_HOME",
    isDisable: false,
  },
  {
    labelLocale: "common:toddle_community",
    value: "TODDLE_COMMUNITY",
    type: "TODDLE_COMMUNITY",
    isDisable: false,
    perm: "TeacherPortal:Community",
    svg: <img src={COMMUNITY_LOGO_URL} alt={"Toddle Community"} />,
    customStyles: menuItemsStyle.toddleCommunityCustomStyle,
  },
  {
    labelLocale: "common:admin_portal",
    value: "ADMIN_PORTAL",
    type: "ADMIN_PORTAL",
    isDisable: false,
    svg: <img src={ADMIN_PORTAL_LOGO_URL} />,
    customStyles: menuItemsStyle.adminPortalCustomStyle,
  },
  {
    value: "ACADEMIC_SWITCHER",
    type: "ACADEMIC_SWITCHER",
    isDisable: false,
    style: academicSwitcherStyles,
  },
  {
    labelLocale: "common:switch_organization",
    value: "SWITCH_ORGANIZATION",
    type: "SWITCH_ORGANIZATION",
    isDisable: false,
    svg: <SwitchOrganizationIcon height={32} width={32} />,
    style: menuItemsStyle.switchToAdminStyle,
  },
  {
    labelLocale: "onBoarding:school_onboarding",
    value: "CONTINUE_SCHOOL_SETUP",
    type: "CONTINUE_SCHOOL_SETUP",
    isDisable: false,
    svg: <SetupOutlined />,
  },
];

const isOnboardingComplete = ({
  curriculumProgramId,
  organizationCurriculumPrograms,
}) => {
  const programOnboardingStatus = _.get(
    _.find(
      organizationCurriculumPrograms,
      ({ id }) => id == curriculumProgramId
    ),
    "onboardingStatus",
    STATUS_SETUP_COMPLETE
  );

  return programOnboardingStatus == STATUS_SETUP_COMPLETE;
};

const showCompleteOnboardingOption = ({ organizationCurriculumPrograms }) => {
  const onboardingInCompleteCurriculumPrograms = _.filter(
    organizationCurriculumPrograms,
    ({ onboardingStatus }) => onboardingStatus != STATUS_SETUP_COMPLETE
  );

  //School Onboarding is removed from platform switcher, if only DP onboarding is pending
  //and feature flag is off for user

  if (
    _.isEqual(_.size(onboardingInCompleteCurriculumPrograms), 1) &&
    _.isEqual(
      _.get(_.first(onboardingInCompleteCurriculumPrograms), "type", null),
      CURRICULUM_TYPE_DP
    )
  ) {
    return ACLStore.can("FeatureFlag:DPOnboarding");
  }

  return !_.isEmpty(onboardingInCompleteCurriculumPrograms);
};

/**
 * Community option will be visible when atleast one PYP program is onboarded or
 * all curriculum programs are free. It won't be visible when no PYP program is onboarded
 * and some curriculum programs are paid.It will be disabled for SnP visitors
 */
const getCommunityVisibilityStatus = ({
  curriculumPrograms,
  validCurriculumPrograms,
  hasAdminPortalAccess,
  isSnpVisitor,
}) => {
  const pypCurriculumPrograms = _.filter(
    curriculumPrograms,
    ({ type }) => type == CURRICULUM_TYPE_PYP
  );

  const validCurriculumProgramIds = _.map(
    validCurriculumPrograms,
    _.iteratee("id")
  );

  const validPypCurriculumPrograms = _.filter(pypCurriculumPrograms, ({ id }) =>
    _.includes(validCurriculumProgramIds, id)
  );

  //TODO: use curriculum based permission
  const isAnyValidPypCurriculumOnboarded = _.some(
    validPypCurriculumPrograms,
    ({ onboardingStatus }) =>
      !ACLStore.can("FeatureFlag:SchoolOnBoarding") ||
      onboardingStatus == STATUS_SETUP_COMPLETE
  );

  return (
    (isAnyValidPypCurriculumOnboarded || !hasAdminPortalAccess) && !isSnpVisitor
  );
};

const isVisibleForCurriculumProgram = ({
  isOrganizationFree,
  curriculumProgramId,
  organizationCurriculumPrograms,
}) => {
  const hasAdminPortalAccess =
    ACLStore.can("AdminPortal") && !isOrganizationFree;
  const isSnpVisitor = ACLStore.can("VisitorPortal");

  const onboardingCompletion =
    !ACLStore.can("FeatureFlag:SchoolOnBoarding") ||
    isOnboardingComplete({
      curriculumProgramId,
      organizationCurriculumPrograms,
    });

  return (onboardingCompletion || !hasAdminPortalAccess) && !isSnpVisitor;
};

const getDynamicMenuItems = ({ validCurriculumPrograms }) => {
  const curriculumMenuItems = _.map(
    validCurriculumPrograms,
    ({ id, type, acronym }) => {
      const menuItem = CURRICULUM_PROGRAM_CONFIGURATIONS[type];

      return {
        ...menuItem,
        localeParams: { label: acronym },
        svg: (
          <CurriculumProgramLogo type={type} acronym={acronym} height="32px" />
        ),
        value: id,
      };
    }
  );

  return update(MENU_ITEM_TYPES, { $splice: [[1, 0, ...curriculumMenuItems]] });
};

const getMenuItems = ({
  t,
  isDevUser,
  isOrganizationFree,
  isAdminPage,
  isOnboarding,
  academicYearSelected,
  totalLinkedIdentities,
  validCurriculumPrograms,
  organizationAcademicYears,
  organizationCurriculumPrograms,
  isCommunityPage,
}) => {
  const hasAdminPortalAccess =
    ACLStore.can("AdminPortal") && !isOrganizationFree;
  const isSnpVisitor = ACLStore.can("VisitorPortal");

  const menuItems = getDynamicMenuItems({
    validCurriculumPrograms,
  });

  const filteredMenuItems = [];

  _.forEach(menuItems, itemType => {
    switch (itemType.type) {
      case "SNP_VISITOR_HOME":
        {
          filteredMenuItems.push({
            ...itemType,
            label: t(itemType.labelLocale),
            visible: isSnpVisitor,
          });
        }
        break;
      case "TODDLE_PYP_PLATFORM":
      case "TODDLE_MYP_PLATFORM":
      case "TODDLE_UBD_PLATFORM":
      case "TODDLE_DP_PLATFORM":
        {
          filteredMenuItems.push({
            ...itemType,
            label: t(itemType.labelLocale, itemType.localeParams),
            visible: isVisibleForCurriculumProgram({
              isOrganizationFree,
              organizationCurriculumPrograms,
              curriculumProgramId: itemType.value,
            }),
            value: itemType.value,
          });
        }
        break;
      case "TODDLE_COMMUNITY":
        {
          /**
           * CHECK(MCP): When multiple curriculum programs will exist how it will be handled?
           */

          filteredMenuItems.push({
            ...itemType,
            label: t(itemType.labelLocale),
            visible:
              ACLStore.can("TeacherPortal:Community") &&
              getCommunityVisibilityStatus({
                validCurriculumPrograms,
                curriculumPrograms: organizationCurriculumPrograms,
                hasAdminPortalAccess,
                isSnpVisitor,
              }),
          });
        }
        break;
      case "ADMIN_PORTAL":
        {
          filteredMenuItems.push({
            ...itemType,
            label: t(itemType.labelLocale),
            visible: hasAdminPortalAccess && (!isOnboarding || isDevUser),
          });
        }
        break;
      case "ACADEMIC_SWITCHER":
        {
          filteredMenuItems.push({
            ...itemType,
            renderComponent: (
              <AcademicYearSwitcher
                t={t}
                academicYearSelected={academicYearSelected}
              />
            ),
            children: _.map(
              organizationAcademicYears,
              ({ id, startDate, endDate }) => {
                return {
                  value: id,
                  label: getAcademicYearLabel({ startDate, endDate }),
                };
              }
            ),
            /**
             * academic year switcher won't be visible on admin and community portal.
             */
            visible:
              ACLStore.can("FeatureFlag:AcademicYearSwitcher") &&
              !isAdminPage &&
              !isCommunityPage &&
              _.size(organizationAcademicYears) > 1,
          });
        }
        break;
      case "SWITCH_ORGANIZATION":
        {
          filteredMenuItems.push({
            ...itemType,
            label: t(itemType.labelLocale),
            visible: totalLinkedIdentities > 1,
          });
        }
        break;
      case "CONTINUE_SCHOOL_SETUP":
        {
          filteredMenuItems.push({
            ...itemType,
            label: t(itemType.labelLocale),
            visible:
              showCompleteOnboardingOption({
                organizationCurriculumPrograms,
              }) &&
              ACLStore.can("FeatureFlag:SchoolOnBoarding") &&
              hasAdminPortalAccess,
          });
        }
        break;
    }
  });

  return filteredMenuItems;
};

export const memoizedGetMenuItems = _.memoize(getMenuItems, params =>
  JSON.stringify(params)
);

export const getFilteredMenuItems = ({ menuItems }) => {
  return _.filter(menuItems, "visible");
};

/**
 * e.g academic year start year 2022 end year 2022
 * if so do not show 2022-22
 */
export const isCurrentAcademicYearEndInSameYear = ({ startDate, endDate }) => {
  return _.isEqual(
    formatDate({ date: startDate, format: "YYYY" }),
    formatDate({ date: endDate, format: "YYYY" })
  );
};

export const getAcademicYearLabel = ({ startDate, endDate }) => {
  return isCurrentAcademicYearEndInSameYear({ startDate, endDate })
    ? formatDate({ date: startDate, format: "YYYY" })
    : formatDate({ date: startDate, format: "YYYY" }) +
        "-" +
        formatDate({ date: endDate, format: "YY" });
};

export const getLabelWithStyles = ({ t, userType, activeValue, menuItems }) => {
  let label = "",
    customStyles;
  if (userType === "student") {
    label = t("common:home");
  } else if (activeValue === "TODDLE_COMMUNITY") {
    label = t("common:toddle_community");
    customStyles = toddleCommunityLabelCustomStyle;
  } else {
    label = t(_.get(_.find(menuItems, { value: activeValue }), "labelLocale"));
  }

  return { label, customStyles };
};
