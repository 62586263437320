import PropTypes from "prop-types";
import React from "react";

const AddAssessment = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <g
        fill={props.fill && props.fill}
        fillRule="evenodd"
        transform="translate(4 2)"
      >
        <path d="M15 24.5996L15 22.9996C15 22.4996 15.5 21.9996 16 21.9996L17.6 21.9996 15 24.5996zM19 9.8996L19 19.9996 16 19.9996C14.3 19.9996 13 21.2996 13 22.9996L13 25.9996 3 25.9996C2.5 25.9996 2 25.4996 2 24.9996L2 5.9996C2 5.4996 2.5 4.9996 3 4.9996L14.1 4.9996C14 4.7006 14 4.2996 14 3.9996 14 3.7006 14 3.2996 14.1 2.9996L3 2.9996C1.3 2.9996 0 4.2996 0 5.9996L0 24.9996C0 26.5996 1.3 27.9996 3 27.9996L14 27.9996C14.1 27.9996 14.3 27.9996 14.4 27.9006 14.5 27.7996 14.6 27.7996 14.7 27.7006L20.7 21.7006C20.9 21.4996 21 21.2996 21 21.0996L21 20.9996 21 9.8996C20.7 9.9996 20.3 9.9996 20 9.9996 19.7 9.9996 19.3 9.9996 19 9.8996L19 9.8996zM23 3L21 3 21 1C21 .4 20.6 0 20 0 19.4 0 19 .4 19 1L19 3 18 3 17 3C16.4 3 16 3.4 16 4 16 4.6 16.4 5 17 5L18 5 19 5 19 6 19 7C19 7.6 19.4 8 20 8 20.6 8 21 7.6 21 7L21 6 21 5 23 5C23.6 5 24 4.6 24 4 24 3.4 23.6 3 23 3" />
        <path d="M14 13L7 13C6.4 13 6 13.4 6 14 6 14.6 6.4 15 7 15L14 15C14.6 15 15 14.6 15 14 15 13.4 14.6 13 14 13M7 9C6.4 9 6 9.4 6 10 6 10.6 6.4 11 7 11L14 11C14.6 11 15 10.6 15 10 15 9.4 14.6 9 14 9L7 9z" />
      </g>
    </svg>
  );
};

AddAssessment.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

AddAssessment.defaultProps = {
  height: 24,
  width: 24,
};

export default AddAssessment;
