import React, { useState } from "react";
import classes from "./AttendanceRecordingSettings.scss";
import {
  getOrganizationPeriodSetsFromCache,
  getOrganizationGlobalConstantsFromCache,
  getOrganizationDetailsFromCache,
} from "modules/CommonGraphqlHelpers";
import { setCurriculumProgramAttendanceRecordingType } from "AttendanceSetup/modules/Module";
import {
  getOrganizationPeriodSetsQuery,
  getOrganizationGlobalConstantsQuery,
  getOrganizationDetailsQuery,
} from "modules/CommonQuery";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { I18nHOC, withLoader } from "UIComponents";
import AttendanceCurriculumRow from "./AttendanceCurriculumRow";
import ACLStore from "lib/aclStore";
import {
  ATTENDANCE_GLOBAL_CONSTANTS,
  createOrganizationGlobalConstants,
} from "modules/Services";
import {
  getPaidCurriculumProgramIds,
  getCurriculumProgramLabel,
} from "Platform/modules/PlatformUtils";

const AttendanceRecordingSettings = ({
  curriculumProgramDetails,
  organizationId,
  globalConstants,
  academicYearId,
  createOrganizationGlobalConstants,
  t,
  containerRowStyle,
  useOrganizationGlobalConstants,
  setCurriculumProgramAttendanceRecordingType,
}) => {
  const [attendanceRecordMode, setRecordMode] = useState(
    useOrganizationGlobalConstants
      ? _.reduce(
          curriculumProgramDetails,
          (result, { label, id }) => {
            result[label] = _.get(
              _.find(globalConstants, constant => {
                const curriculumProgram = _.get(
                  constant,
                  "curriculumProgram",
                  {}
                );
                return curriculumProgram.id === id;
              }),
              "value",
              undefined
            );
            return result;
          },
          {}
        )
      : _.reduce(
          curriculumProgramDetails,
          (result, { label, attendanceRecordingType }) => {
            result[label] = attendanceRecordingType;
            return result;
          },
          {}
        )
  );
  const onRoutineOptionSelect = async value => {
    setRecordMode({ ...attendanceRecordMode, ...value });
    const curriculumType = _.first(_.keys(value));
    const curriculumData = _.find(
      curriculumProgramDetails,
      curriculumProgram => curriculumProgram.label === curriculumType
    );
    const globalConstantsInput = [
      {
        name: ATTENDANCE_GLOBAL_CONSTANTS.ATTENDANCE_RECORDING_TYPE,
        value: JSON.stringify(value[curriculumType]),
        curriculumId: _.get(curriculumData, "id"),
        academicYearId,
      },
    ];

    await createOrganizationGlobalConstants({
      organizationId,
      input: globalConstantsInput,
      refetchFilters: {
        academicYearIds: academicYearId,
        curriculumProgramIds: _.get(curriculumData, "id"),
        names: ATTENDANCE_GLOBAL_CONSTANTS.ATTENDANCE_RECORDING_TYPE,
      },
    });
    await setCurriculumProgramAttendanceRecordingType({
      organizationId,
      curriculumProgramId: _.get(curriculumData, "id"),
      attendanceRecordingType: _.get(value, curriculumType),
    });
  };
  return (
    <div className={classes.container}>
      {_.map(curriculumProgramDetails, currDetails => {
        const { id, label } = currDetails;
        return (
          <AttendanceCurriculumRow
            key={id}
            id={id}
            containerRowStyle={containerRowStyle}
            label={label}
            onRoutineOptionSelect={onRoutineOptionSelect}
            attendanceRecordMode={attendanceRecordMode}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  const organizationId = _.get(state, "login.userInfo.org_id", null);
  const useOrganizationGlobalConstants = ACLStore.can(
    "FeatureFlag:UseOrganizationGlobalConstants"
  );

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  const paidCurriculumProgramIds = getPaidCurriculumProgramIds({
    curriculumPrograms: organizationCurriculumPrograms,
  });

  return {
    organizationId,
    useOrganizationGlobalConstants,
    isData: true,
    isLoading: false,
    paidCurriculumProgramIds,
  };
};

const mapActionCreators = {
  createOrganizationGlobalConstants,
  setCurriculumProgramAttendanceRecordingType,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationPeriodSetsQuery, {
    skip: ({ organizationId }) => !organizationId,
    name: "getOrganizationPeriodSets",
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: organizationId,
      },
    }),
    props({
      getOrganizationPeriodSets,
      ownProps: { organizationId, isData, isLoading, paidCurriculumProgramIds },
    }) {
      const organizationDetails =
        getOrganizationPeriodSetsFromCache(organizationId) || {};

      const curriculumPrograms = _.get(
        organizationDetails,
        "curriculumPrograms",
        []
      );

      const paidCurriculumPrograms = _.filter(curriculumPrograms, ({ id }) =>
        _.includes(paidCurriculumProgramIds, id)
      );

      const curriculumProgramDetails = _.map(
        paidCurriculumPrograms,
        program => ({
          label: getCurriculumProgramLabel({
            curriculumProgram: program,
            shouldIncludeAcronym: true,
          }),
          acronym: _.get(program, "acronym", ""),
          attendanceRecordingType: _.get(
            program,
            "attendanceRecordingType",
            "ONCE_A_DAY"
          ),
          id: _.get(program, "id", null),
        })
      );
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        curriculumProgramDetails,
        isLoading:
          getOrganizationPeriodSets["networkStatus"] == 1 ||
          getOrganizationPeriodSets["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationDetailsQuery, {
    name: "getOrganizationDetails",
    options: ({ organizationId }) => {
      return {
        variables: {
          id: organizationId,
        },
        fetchPolicy: "cache-and-network",
      };
    },
    props: ({
      getOrganizationDetails,
      ownProps: { organizationId, isLoading, isData },
    }) => {
      const data = getOrganizationDetailsFromCache(organizationId);
      const academicYears = _.get(data, "academicYears", []);
      const currentAcademicYear = _.find(academicYears, {
        isCurrentAcademicYear: true,
      });
      return {
        isData: isData && !_.isEmpty(data),
        academicYearId: _.get(currentAcademicYear, "id", ""),
        isLoading:
          getOrganizationDetails["networkStatus"] == 1 ||
          getOrganizationDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationGlobalConstantsQuery, {
    skip: ({ academicYearId, useOrganizationGlobalConstants }) =>
      !academicYearId || !useOrganizationGlobalConstants,
    name: "getOrganizationGlobalConstants",
    options: ({ organizationId, academicYearId, curriculumProgramDetails }) => {
      const curriculumProgramIds = _.map(
        curriculumProgramDetails,
        curriculumProgram => curriculumProgram.id
      );
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          organizationId,
          filter: {
            academicYearIds: [academicYearId],
            curriculumProgramIds,
            names: [ATTENDANCE_GLOBAL_CONSTANTS.ATTENDANCE_RECORDING_TYPE],
          },
        },
      };
    },
    props({
      getOrganizationGlobalConstants,
      ownProps: {
        organizationId,
        academicYearId,
        curriculumProgramDetails,
        isData,
        isLoading,
      },
    }) {
      const curriculumProgramIds = _.map(
        curriculumProgramDetails,
        curriculumProgram => curriculumProgram.id
      );
      const filters = {
        academicYearIds: [academicYearId],
        curriculumProgramIds,
        names: [ATTENDANCE_GLOBAL_CONSTANTS.ATTENDANCE_RECORDING_TYPE],
      };
      const data = getOrganizationGlobalConstantsFromCache({
        organizationId,
        filters,
      });
      const organizationConstants = _.get(data, "node.globalConstants", []);
      return {
        isData: !_.isEmpty(data) && isData,
        globalConstants: _.map(organizationConstants, constant => ({
          ...constant,
          value: JSON.parse(constant.value),
        })),
        isLoading:
          getOrganizationGlobalConstants["networkStatus"] == 1 ||
          getOrganizationGlobalConstants["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(AttendanceRecordingSettings);
