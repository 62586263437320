import React from "react";
import classes from "./EmptyView.scss";
import { Button } from "@toddle-design/web";
import PropTypes from "prop-types";
import { useI18n } from "Hooks";

const getImageStyle = size => {
  switch (size) {
    case "small":
      return { height: 200, width: 280 };
    case "large":
    default:
      return { height: 200, width: 320 };
  }
};

const getEmptyTextStyle = size => {
  switch (size) {
    case "small":
      return { fontSize: "1.5rem" };
    case "large":
      return { maxWidth: 544 };
    default:
      return {};
  }
};

const getEmptyHeaderTextStyle = ({ size, emptyText }) => {
  switch (size) {
    case "small":
      return { fontSize: "1.6rem", ...(!emptyText && { marginBottom: 32 }) };
    default:
      return {};
  }
};

const EmptyView = ({
  emptyText,
  emptyHeaderText,
  buttonText,
  onButtonClick,
  containerStyle,
  showButton,
  emptyImageUrl,
  size,
  buttonVariant,
}) => {
  const { t } = useI18n(["common"]);
  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.innerContainer}>
        <img
          src={emptyImageUrl}
          style={getImageStyle(size)}
          alt={t("common:image_not_found")}
        />
        {!!emptyHeaderText && (
          <div
            className={classes.headerText}
            style={getEmptyHeaderTextStyle({ size, emptyText })}
          >
            {emptyHeaderText}
          </div>
        )}
        {!!emptyText && (
          <div className={classes.emptyText} style={getEmptyTextStyle(size)}>
            {emptyText}
          </div>
        )}
        {showButton && (
          <Button
            variant={buttonVariant}
            onClick={onButtonClick}
            size={"medium"}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

EmptyView.propTypes = {
  emptyText: PropTypes.string,
  emptyHeaderText: PropTypes.any,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  emptyImageUrl: PropTypes.any,
  containerStyle: PropTypes.object,
};

EmptyView.defaultProps = {
  containerStyle: {},
  buttonText: "",
  emptyHeaderText: "",
  emptyText: "",
  showButton: false,
  buttonVariant: "primary",
};

export default EmptyView;
