import gql from "graphql-tag";

export const projectDeadlineFragment = {
  projectDeadlineDetails: gql`
    fragment projectDeadlineItem on ProjectDeadline {
      id
      deadline {
        id
        deadlineDate
        title
      }
      project {
        id
        projectGroup {
          id
        }
        title {
          id
          value
        }
        status
        conversation {
          id
          unreadMessageCount
        }
      }
    }
  `,
};
