import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

import {
  withLoader,
  UIButton,
  DialogueBox,
  withPusherBind,
  UIModal,
  FullScreenLoader,
  I18nHOC,
} from "UIComponents";
import { graphql, compose } from "react-apollo";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import ResourceItemCard from "./components/ResourceItemCard";
import PickAttachmentModal from "./components/PickAttachmentModal";
import { getPlannerResourcesOfNodeQuery } from "UnitPlans/modules/UnitPlanQuery";
import { getPlannerResourcesOfNodeFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import ResourceCreateEditModal from "./components/ResourceCreateEditModal";
import CustomEmptyField from "./components/CustomEmptyField";
import { EmptySvgIcon } from "SvgComponents";
import { setToastMsg } from "Login/modules/LoginModule";
import { ItemViewFeed } from "UnitPlans/components/IBcomponents";
import ResourceViewItem from "./components/ResourceViewItem";
import {
  initLocalResource,
  updateLocalResource,
  createPlannerResource,
  updatePlannerResource,
  removePlannerResource,
  addPlannerResource,
} from "UnitPlans/modules/UnitPlanModule";
import classes from "./IBResources.scss";
import { connect } from "react-redux";
import { ResourceViewModal } from "AppComponents";

const EMPTY_LABEL_MAPPING = {
  ASSESSMENT: "assessment_label",
  UNIT_PLAN: "unit",
  LEARNING_ENGAGEMENT: "le_label",
};

const loadingStyle = { width: 20, height: 20 };
const ResourceFeedComponent = ({
  data,
  toggleEditableItem,
  onEditClick,
  onDeleteClick,
  canPick,
  lockedDynamicFields,
  cardmode,
  attachmentItemHeight,
  attachmentFeedContainerStyle,
}) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [resourceId, setResourceId] = useState(null);

  const setResourceModalVisibility = (value, id, e) => {
    if (e) {
      e.stopPropagation();
    }
    setModalVisibility(value);
    setResourceId(id);
  };

  return (
    <Fragment>
      <div className={classes.feedContainer}>
        {_.map(data, (item, key) => {
          const lockedField = _.find(lockedDynamicFields, { id: item.id });
          return (
            <div className={classes.item} key={item.id}>
              <ResourceItemCard
                item={item}
                key={item.id}
                onDeleteClick={onDeleteClick}
                toggleEditableItem={toggleEditableItem}
                onEditClick={onEditClick}
                canPick={canPick}
                cardmode={cardmode}
                lockedField={lockedField}
                attachmentItemHeight={attachmentItemHeight}
                attachmentFeedContainerStyle={attachmentFeedContainerStyle}
              />
              <div
                className={classes.viewResource}
                onClick={e => setResourceModalVisibility(true, item.id, e)}
              >
                View
              </div>
            </div>
          );
        })}
      </div>
      {modalVisibility && (
        <ResourceViewModal
          setModalVisibility={setModalVisibility}
          resourceId={resourceId}
        />
      )}
    </Fragment>
  );
};

class IBResources extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      isLoading: false,
      resourceModalVisible: false,
      resourceId: null,
      pickResourceModalVisible: false,
      warningDialogueBox: { showDialogueBox: false, type: null, itemCount: 0 },
    };
  }

  blur = ({ field }) => {
    const { uid, id } = field;
    const { resourceId } = this.state;
    if (resourceId == id) {
      this.onClickCreateModalClose();
    }
  };

  onEditClick = item => {
    const { updateLocalResource, onFocusInputField } = this.props;
    let params = {};
    params["id"] = item.id;
    params["title"] = item.title;
    params["type"] = item.type;
    params["description"] = item.description;
    params["attachments"] = _.map(item.attachments, attachment => {
      return _.omit(attachment, ["__typename", "streamUrl", "isRead"]);
    });
    updateLocalResource(params);
    this.setState({ resourceModalVisible: true, resourceId: item.id });
    onFocusInputField({ others: { id: item.id } });
  };

  updateResource = () => {
    const { nodeId, type, resources } = this.props;
    const { resourceId } = this.state;
    const resourceDetails = _.find(resources, { id: resourceId });
    this.props.updatePlannerResource({
      resourceId,
      nodeId,
      type,
      resourceDetails,
    });
  };

  deleteResouce = async ({ isForced = false, resourceId } = {}) => {
    const { nodeId, type } = this.props;
    this.setState({ isLoading: true });
    const warning = await this.props.removePlannerResource({
      resourceId,
      nodeId,
      type,
      isForced,
    });

    if (warning) {
      this.setState({
        isLoading: false,
        warningDialogueBox: {
          showDialogueBox: true,
          type: "delete",
          itemCount: warning.ASSESSMENT + warning.LEARNING_ENGAGEMENT,
        },
        resourceId,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  onDeleteClick = resourceId => {
    const { canPick } = this.props;
    this.deleteResouce({ resourceId, isForced: canPick });
  };

  onCreateClick = () => {
    this.setState({ resourceModalVisible: true });
  };

  onDropdownAdd = value => {
    if (value == "create") {
      this.setState({ resourceModalVisible: true });
    } else {
      this.setState({ pickResourceModalVisible: true });
    }
  };

  createResource = () => {
    const { nodeId, type, portalType } = this.props;
    this.props.createPlannerResource({ nodeId, type, portalType });
  };

  onClickCreateModalClose = () => {
    const { initLocalResource, onBlurInputField } = this.props;
    const { resourceId } = this.state;
    this.setState({ resourceModalVisible: false, resourceId: null });
    if (this.attachmentListRef) {
      this.attachmentListRef.stopAllUploads();
    }
    initLocalResource();
    if (resourceId) {
      onBlurInputField({ others: { id: resourceId } });
    }
  };

  onClickPickModalClose = () => {
    this.setState({ pickResourceModalVisible: false });
  };

  onAddNewReource = () => {
    this.setState({
      pickResourceModalVisible: false,
      resourceModalVisible: true,
      resourceId: null,
    });
  };

  pickResource = ({ resourceIds, resources }) => {
    const { nodeId, type } = this.props;

    this.props.addPlannerResource({ resourceIds, nodeId, type, resources });
  };

  initWarningDialogueBoxState = () => {
    this.setState({
      warningDialogueBox: { showDialogueBox: false, type: null, itemCount: 0 },
    });
  };

  onConfirmWarningDialogueBox = () => {
    const {
      warningDialogueBox: { type },
      resourceId,
    } = this.state;
    if (type == "delete") {
      this.deleteResouce({ resourceId, isForced: true });
      this.setState({ resourceId: null });
    }
  };

  getWarningDialogueBox = () => {
    const {
      warningDialogueBox: { showDialogueBox, type, itemCount },
    } = this.state;
    const { t } = this.props;
    return (
      showDialogueBox && (
        <DialogueBox
          isOpen={showDialogueBox}
          modalTitle={`${
            type == "delete"
              ? t("common:delete_with_label", {
                  label: t("common:resource_label"),
                })
              : t("common:update_with_label", {
                  label: t("common:resource_label"),
                })
          }`}
          showModal={true}
          onClickButton2={this.onConfirmWarningDialogueBox}
          modalBody={t("unitPlan:resource_delete_msg")}
          toggleDialogueBoxDisplay={this.initWarningDialogueBoxState}
          button1={t("common:cancel")}
          button2={t("common:confirm")}
        />
      )
    );
  };

  renderFeedContent = () => {
    const {
      props: {
        resources,
        canPick,
        mode,
        fieldLockedObject,
        attachmentItemHeight,
        attachmentFeedContainerStyle,
      },
    } = this;

    const cardmode = mode;
    const { lockedDynamicFields } = fieldLockedObject;
    return (
      <ResourceFeedComponent
        data={resources}
        lockedDynamicFields={lockedDynamicFields}
        onDeleteClick={this.onDeleteClick}
        toggleEditableItem={this.toggleEditableItem}
        onEditClick={this.onEditClick}
        canPick={canPick}
        cardmode={cardmode}
        attachmentItemHeight={attachmentItemHeight}
        attachmentFeedContainerStyle={attachmentFeedContainerStyle}
      />
    );
  };

  shouldShowEditEmpty = () => {
    const { resources } = this.props;
    return resources.length == 0;
  };

  shouldShowViewEmpty = () => {
    const { resources, parentType } = this.props;
    return resources.length == 0 && parentType != "UNIT_PLAN";
  };

  renderEditEmpty = () => {
    const { canPick, parentType, readOnly, t, unitPlanId } = this.props;
    if (readOnly) {
      return <div className={classes.emptyItemsText}>{`No Resources`}</div>;
    }
    return canPick ? (
      <CustomEmptyField
        showAdd={true}
        unitPlanId={unitPlanId}
        title={t("unitPlan:add_resources")}
        onAddNewClick={() =>
          this.setState({
            resourceModalVisible: true,
            resourceId: null,
          })
        }
        onPickExisting={() =>
          this.setState({
            pickResourceModalVisible: true,
            resourceId: null,
          })
        }
      />
    ) : (
      <div className={classes.emptyState}>
        <EmptySvgIcon height={80} width={80} />
        <div className={classes.emptyText}>
          {t("unitPlan:resource_emptyText_with_label", {
            label: t(`common:${EMPTY_LABEL_MAPPING[parentType]}`),
          })}
        </div>
        <div className={classes.addButton}>
          <UIButton
            color="blue"
            type="hollow"
            onClick={this.onCreateClick}
            size="lg"
          >
            {t("common:create_new_with_label", {
              label: t("common:resource_label"),
            })}
          </UIButton>
        </div>
      </div>
    );
  };

  renderViewEmpty = () => {
    const { parentType, t } = this.props;
    return (
      parentType != "UNIT_PLAN" && (
        <div className={classes.emptyState}>
          <EmptySvgIcon height={80} width={80} />
          <div className={classes.emptyText}>
            {t("unitPlan:resource_emptyText_with_label", {
              label: t(`common:${EMPTY_LABEL_MAPPING[parentType]}`),
            })}
          </div>
        </div>
      )
    );
  };

  renderViewItem = ({ item, index }) => {
    return <ResourceViewItem key={item.id} item={item} />;
  };

  renderUnitPlanViewMode = () => {
    const { resources, mode, maxItemCount } = this.props;
    return (
      <ItemViewFeed
        maxItemCount={maxItemCount}
        items={resources}
        renderItem={this.renderViewItem}
        itemText={"common:resource_label"}
      />
    );
  };

  renderView = () => {
    const { parentType, previewType } = this.props;

    return (
      <div className={classes.container}>
        {parentType == "UNIT_PLAN" || previewType == "UNIT_PLAN"
          ? this.renderUnitPlanViewMode()
          : this.renderFeedContent()}
      </div>
    );
  };

  renderEdit = () => {
    return <div className={classes.container}>{this.renderFeedContent()}</div>;
  };

  updateAttachmentListRef = ref => {
    this.attachmentListRef = ref;
  };

  renderChildren = () => {
    const {
      props: {
        resources,
        initLocalResource,
        updateLocalResource,
        resourceObject,
        removePlannerResource,

        canPick,
        setToastMsg,
        unitPlanId,
        portalType,
      },
    } = this;

    const {
      resourceModalVisible,
      pickResourceModalVisible,
      isLoading,
    } = this.state;
    return (
      <React.Fragment>
        <UIModal
          isOpen={resourceModalVisible}
          onRequestClose={() => this.onClickCreateModalClose()}
        >
          <ResourceCreateEditModal
            updateValueLocally={this.updateValueLocally}
            mode={!this.state.resourceId ? "new" : "edit"}
            onClickClose={() => this.onClickCreateModalClose()}
            resourceObject={resourceObject}
            updateResource={this.updateResource}
            removeResource={removePlannerResource}
            updateLocalResource={updateLocalResource}
            createResource={this.createResource}
            initializeResource={initLocalResource}
            setToastMsg={setToastMsg}
            updateAttachmentListRef={this.updateAttachmentListRef}
            portalType={portalType}
          />
        </UIModal>
        <UIModal
          isOpen={pickResourceModalVisible}
          onRequestClose={() => this.onClickPickModalClose()}
        >
          <PickAttachmentModal
            pickResource={this.pickResource}
            selectedResources={resources}
            onClickClose={() => this.onClickPickModalClose()}
            canPick={canPick}
            addNewReource={() => this.onAddNewReource()}
            unitPlanId={unitPlanId}
          />
        </UIModal>

        {this.getWarningDialogueBox()}
        {isLoading && <FullScreenLoader></FullScreenLoader>}
      </React.Fragment>
    );
  };
}

IBResources.propTypes = {
  ...UIBaseComponent.propTypes,
};

IBResources.defaultProps = {
  ...UIBaseComponent.defaultProps,
  mode: "edit",
};

const mapActionCreators = {
  initLocalResource,
  updateLocalResource,
  createPlannerResource,
  removePlannerResource,
  updatePlannerResource,

  addPlannerResource,
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  return {
    resourceObject: _.get(state, "unitPlans.resourceObject", {}),
    nodeId: ownProps.parentId,
    type: ownProps.parentType,
    canPick: ownProps.parentType != "UNIT_PLAN",
    loadingStyle,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlan", "common"] }),
  graphql(getPlannerResourcesOfNodeQuery, {
    name: "getPlannerResourcesOfNode",
    options: ({ nodeId, type, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: nodeId, type, portalType },
    }),
    props({ getPlannerResourcesOfNode, ownProps: { nodeId, type } }) {
      const nodeDetails = getPlannerResourcesOfNodeFromCache({
        nodeId,
        type,
      });
      return {
        isData: !_.isEmpty(nodeDetails),
        onPusherEventTriggered: () => getPlannerResourcesOfNode.refetch(),
        resources: _.map(
          _.get(nodeDetails, "resources.edges", []),
          resource => resource.node
        ),
        isLoading:
          getPlannerResourcesOfNode["networkStatus"] == 1 ||
          getPlannerResourcesOfNode["networkStatus"] == 2,
      };
    },
  }),
  withPusherBind(["resourcesFeedUpdate"]),
  withLoader
)(IBResources);
