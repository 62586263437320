import React, { Component } from "react";
import PropTypes from "prop-types";

const IcnNotiVideoMeet = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <path d="M29 11V21C29 21.8 28.1 22.3 27.4 21.8L23 18.8V13.1L27.4 10.1C28.1 9.7 29 10.2 29 11Z" />
      <path d="M19 26H7C4.8 26 3 24.2 3 22V10C3 7.8 4.8 6 7 6H19C21.2 6 23 7.8 23 10V22C23 24.2 21.2 26 19 26Z" />
    </svg>
  );
};

IcnNotiVideoMeet.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

IcnNotiVideoMeet.defaultProps = {
  width: 32,
  height: 32,
};

export default IcnNotiVideoMeet;
