import React, { memo } from "react";
import classes from "./EULAModal.scss";
import { UIModal } from "UIComponents";
import EULADetails from "./EULADetails";

const EULAModal = props => {
  const { setShowEULA, onClickAgree, t } = props;
  const onRequestClose = event => {
    setShowEULA(false);
    event?.stopPropagation();
  };

  return (
    <UIModal
      isOpen={true}
      onRequestClose={onRequestClose}
      modalContent={classes.modalContent}
    >
      <EULADetails
        onRequestClose={onRequestClose}
        onClickAgree={onClickAgree}
        t={t}
      />
    </UIModal>
  );
};

const EULAModalMemoised = memo(EULAModal);

export default EULAModalMemoised;
