import React from "react";
import PropTypes from "prop-types";

const PauseIconV2 = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 8 12">
      <g fill={props.fill && props.fill}>
        <path d="M0.75,0 C1.16421356,-7.6089797e-17 1.5,0.335786438 1.5,0.75 L1.5,11.25 C1.5,11.6642136 1.16421356,12 0.75,12 C0.335786438,12 5.07265313e-17,11.6642136 0,11.25 L0,0.75 C-5.07265313e-17,0.335786438 0.335786438,7.6089797e-17 0.75,0 Z M7.25,0 C7.66421356,-7.6089797e-17 8,0.335786438 8,0.75 L8,11.25 C8,11.6642136 7.66421356,12 7.25,12 C6.83578644,12 6.5,11.6642136 6.5,11.25 L6.5,0.75 C6.5,0.335786438 6.83578644,7.6089797e-17 7.25,0 Z"></path>
      </g>
    </svg>
  );
};

PauseIconV2.defaultProps = {
  height: 14,
  width: 10,
};
PauseIconV2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default PauseIconV2;
