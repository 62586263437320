import React, { Component } from "react";
import classes from "./MoreItemWithToolTip.scss";
import PropTypes from "prop-types";
import { LinkWithTooltip, I18nHOC } from "UIComponents";
import { colors } from "Constants";
class MoreItemWithToolTip extends React.Component {
  onContainerClick = e => {
    const { stopPropagationOnLabelClick } = this.props;
    if (stopPropagationOnLabelClick) e.stopPropagation();
  };
  getRenderComponent() {
    const {
      items,
      length,
      noItemText,
      suffixText,
      moreStyle,
      tooltipPlacement,
      renderComponent,
      moreJoinSymbol,
      containerStyle,
      moreAll,
      onVisibleChange,
      joinSymbol,
      t,
      isAllSelected,
      allItemsText,
      trigger,
      maxLength,
      valueStyle,
      itemsTotalCount,
      renderMoreComponent,
      showDashOnNoItemText,
    } = this.props;
    let component;
    let valueString = "";
    let moreComponent = null;
    let allItemArray = [...items];
    let itemArray = allItemArray.slice(0, length);
    const itemsLength = _.get(items, "length", 0);

    const allItemsTextLocale =
      allItemsText == "all_label"
        ? t("common:all_label", { label: t("common:items") })
        : allItemsText;

    if (!renderComponent) {
      let currentJoinSymbol = items.length == length ? joinSymbol : ", ";
      itemArray.map((item, i) => {
        valueString += item;
        valueString += i < itemArray.length - 1 ? currentJoinSymbol : "";
      });

      if (itemArray.length == 0) {
        if (noItemText) {
          valueString = noItemText;
        } else if (showDashOnNoItemText) {
          valueString = "-";
        } else {
          valueString = "";
        }
      }
    }

    if (items.length > length) {
      let moreItemArray = [];

      if (moreAll) {
        if (maxLength) {
          moreItemArray = _.slice(allItemArray, 0, maxLength);
          const remainingCount = _.get(allItemArray, "length", 0) - maxLength;
          if (remainingCount > 0) {
            moreItemArray.push(
              t("common:and_label_more", {
                label: remainingCount,
              })
            );
          }
        } else moreItemArray = allItemArray;
      } else {
        moreItemArray = allItemArray.slice(length, items.length);
      }

      moreComponent = (
        <div className={classes.tootTipConainer}>
          {_.map(moreItemArray, (item, i) => {
            return (
              <div className={classes.moreToolTipItem} key={i}>
                {item}
                <br />
              </div>
            );
          })}
          {itemsTotalCount && itemsTotalCount > maxLength ? (
            <div className={classes.moreToolTipItem}>
              {`+ ${itemsTotalCount - maxLength} ${t("common:more")}`}
              <br />
            </div>
          ) : null}
        </div>
      );
    }

    component = (
      <span
        className={classes.container}
        style={containerStyle}
        onClick={this.onContainerClick}
      >
        {!renderComponent && !isAllSelected && (
          <span style={valueStyle}>{valueString} </span>
        )}
        {items.length > length ? (
          <LinkWithTooltip
            tooltip={renderMoreComponent ? renderMoreComponent : moreComponent}
            placement={tooltipPlacement}
            href="#"
            id="tooltip-dueDate"
            dataHtml={true}
            onVisibleChange={onVisibleChange}
            trigger={trigger}
          >
            {!renderComponent && (
              <span style={moreStyle}>
                {isAllSelected
                  ? allItemsTextLocale
                  : t("common:number_more", {
                      number: `${moreJoinSymbol}${items.length - length}`,
                    })}
              </span>
            )}
            {renderComponent}
          </LinkWithTooltip>
        ) : null}
        {suffixText && (
          <span>
            &nbsp;
            {suffixText}
          </span>
        )}
      </span>
    );

    return component;
  }

  render() {
    return this.getRenderComponent();
  }
}

MoreItemWithToolTip.propTypes = {
  items: PropTypes.array,
  length: PropTypes.number,
  noItemText: PropTypes.string,
  suffixText: PropTypes.string,
  moreStyle: PropTypes.object,
  valueStyle: PropTypes.object,
  tooltipPlacement: PropTypes.string,
  renderComponent: PropTypes.element,
  moreJoinSymbol: PropTypes.string,
  moreAll: PropTypes.bool,
  isAllSelected: PropTypes.bool,
  allItemsText: PropTypes.string,
  maxLength: PropTypes.string,
  itemsTotalCount: PropTypes.number,
  stopPropagationOnLabelClick: PropTypes.bool,
  showDashOnNoItemText: PropTypes.bool,
};

MoreItemWithToolTip.defaultProps = {
  items: [],
  length: 2,
  noItemText: "",
  suffixText: "",
  moreStyle: { color: colors.blue29 },
  tooltipPlacement: "right",
  moreJoinSymbol: " +",
  moreAll: true,
  joinSymbol: " & ",
  isAllSelected: false,
  allItemsText: "all_label",
  trigger: ["click"],
  valueStyle: {},
  itemsTotalCount: 0,
  stopPropagationOnLabelClick: true,
  showDashOnNoItemText: true,
};

export default I18nHOC({ resource: "common" })(MoreItemWithToolTip);
