import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";

export const evidenceModalTabs = [
  {
    key: "UNIT_EVIDENCE",
    config: {
      evidenceTypes: ["POST"],
      label: "common:unit_evidence",
      parentType: "ORGANIZATION",
      countLocale: "common:post",
      filters: { state: "PUBLISHED" },
      shouldFilterByCurriculumProgram: true,
    },
    curriculumPrograms: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_UBD,
    ],
  },
];

export const evidenceModalTemplate = {
  key: "add_evidence_template",
  label: "add_evidence",
  evidenceOptions: evidenceModalTabs,
};
