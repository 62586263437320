import React from "react";
import BasicUserDetails from "Administrator/commonComponents/BasicUserDetails";
class EditUserModalContent extends React.Component {
  teacherDetails = null;
  isValid = () => {
    return this.teacherDetails.isValid();
  };

  render() {
    return (
      <BasicUserDetails
        ref={ref => {
          this.teacherDetails = ref;
        }}
        updateRedux={this.props.updateRedux}
        onContentUploaded={this.props.onContentUploaded}
        userDetails={this.props.teacherDetails}
        fnName="firstName"
        lnName="lastName"
        email="email"
        disabledEmail={false}
        showDesignation={true}
        showTags={this.props.showTags}
        designationList={this.props.designationList}
        tags={this.props.tags}
      />
    );
  }
}

export default EditUserModalContent;
