import { withAsyncRouteLoading } from "UIComponents";
import ViewIBPlanner from "ViewIBPlanner";
import ViewUnitFlow from "ViewUnitFlow";
import Evidence from "Evidence";
import Insights from "Insights";
import UnitComments from "UnitComments";
import UnitPlanResources from "UnitPlanResources";
import UnitFlowV2 from "UnitFlowV2";

export default store => {
  return {
    path: "view",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
    childRoutes: [
      Insights(store),
      Evidence(store),
      UnitComments(store),
      UnitFlowV2(store),
      ViewUnitFlow(store),
      UnitPlanResources(store),
      ViewIBPlanner(store),
    ],
  };
};
