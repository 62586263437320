import React, { PureComponent } from "react";
import classes from "./CustomEmptyField.scss";
import PropTypes from "prop-types";
import { AddCircleIcon } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";
import { I18nHOC } from "UIComponents";

const RenderIcon = ({ showAdd, renderIcon }) => {
  let updatedRenderIcon = null;
  if (showAdd) {
    updatedRenderIcon = <AddCircleIcon width={24} height={24} />;
  }
  if (renderIcon) {
    updatedRenderIcon = renderIcon;
  }

  return (
    updatedRenderIcon && (
      <div className={classes.renderIcon}>{updatedRenderIcon}</div>
    )
  );
};

class CustomEmptyField extends PureComponent {
  render() {
    const {
      showAdd,
      onAddNewClick,
      titleStyles,
      containerStyle,
      title,
      isDisabled,
      renderIcon,
      onPickExisting,
      t,
      unitPlanId,
    } = this.props;

    const showButton = !isDisabled && showAdd;
    const containerClass = classNames(
      { [classes.container]: !showAdd },
      { [classes.containerButton]: showAdd },
      { [classes.disableButton]: isDisabled }
    );

    const containerUpdatedStyle = {
      ...containerStyle,
      cursor: showButton ? "pointer" : "auto",
    };

    return (
      <div className={containerClass} style={containerUpdatedStyle}>
        <RenderIcon showAdd={showAdd} renderIcon={renderIcon} />
        {title && (
          <div className={classes.title} style={titleStyles}>
            {title}
          </div>
        )}
        <div className={classes.hoverButtons}>
          {unitPlanId ? (
            <div
              className={classes.leftButton}
              onClick={() => {
                showButton ? onPickExisting() : null;
              }}
            >
              {t("add_resource_unit_bank")}
            </div>
          ) : null}
          <div
            className={classes.rightButton}
            onClick={() => {
              showButton ? onAddNewClick() : null;
            }}
          >
            {t("common:create_new_with_label", {
              label: t("common:resource_label").toLowerCase(),
            })}
          </div>
        </div>
      </div>
    );
  }
}

CustomEmptyField.propTypes = {
  onAddNewClick: PropTypes.func,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
  showAdd: PropTypes.bool,
  containerStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  renderIcon: PropTypes.any,
};

CustomEmptyField.defaultProps = {
  title: "",
  titleStyles: {},
  showAdd: false,
  containerStyle: {},
  isDisabled: false,
};

export default I18nHOC({ resource: ["unitPlan", "common"] })(CustomEmptyField);
