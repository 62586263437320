import React, { useEffect, useState } from "react";
import classes from "./ChooseCourses.scss";
import { EditIcon } from "SvgComponents";
import { colors } from "Constants";
import {
  CheckboxGroup,
  ButtonDropdown,
  UIButton,
  MoreItemWithToolTip,
} from "UIComponents";
import { setSelectedCoursesForNotification } from "modules/Services";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { multiLineEllipsis } from "Utils";

const moreWithToolTipStyles = {
  moreStyle: {
    color: colors.gray31,
  },
  valueStyle: {
    ...multiLineEllipsis({ lineHeight: 1.6, lineCount: 1 }),
  },
  containerStyle: {
    display: "flex",
    alignItems: "center",
  },
};

const dropDownStyle = {
  containerStyle: {
    padding: "0",
  },
  buttonParentStyle: {},
  parentContainerStyle: {},
};

const ChooseCourses = React.memo(
  ({
    classOptions,
    t,
    selectedCourses,
    setSelectedCoursesForNotification,
    platform,
    categories,
    configurationId,
    curriculumProgramId,
  }) => {
    const [isGroupSelectOpen, setGroupSelectOpen] = useState(false);
    const [selectedCoursesLocal, setSelectedCoursesLocal] = useState([]);

    useEffect(() => {
      setSelectedCoursesLocal([...selectedCourses]);
    }, [selectedCourses]);

    const handleEdit = () => {
      setGroupSelectOpen(true);
    };

    const updateLocalInput = val => {
      const selectedCourses = getSelectedCourses(val);
      setSelectedCoursesLocal(
        _.map(selectedCourses, course => {
          return {
            id: course.value,
            title: course.label,
          };
        })
      );
    };

    const getSelectedCourses = val => {
      const selectedTags = [];
      _.forEach(classOptions, group => {
        _.forEach(group.options, item => {
          if (
            _.includes(val, item.value) &&
            !_.find(selectedTags, tagItem => tagItem.value == item.value)
          )
            selectedTags.push(item);
        });
      });
      return selectedTags;
    };

    const onCancel = () => {
      setSelectedCoursesLocal(selectedCourses);
      setGroupSelectOpen(false);
    };

    const onDone = async () => {
      const allCoursesIds = [];
      _.forEach(classOptions, group => {
        _.forEach(group.options, course => {
          if (!_.includes(allCoursesIds, course.value)) {
            allCoursesIds.push(course.value);
          }
        });
      });
      const input = _.map(allCoursesIds, courseId => {
        const selectedCourse = _.find(selectedCoursesLocal, { id: courseId });
        return {
          configurationId,
          courseId,
          isEnabled: !!selectedCourse === true,
        };
      });
      setSelectedCoursesForNotification({
        input,
        platform,
        categories,
        configurationId,
        selectedCourses: selectedCoursesLocal,
        curriculumProgramId,
      });
      setGroupSelectOpen(false);
    };

    const getDropdownComponent = () => {
      return (
        <div className={classes.checkListContainer}>
          <div className={classes.checkListBody}>
            <CheckboxGroup
              options={classOptions}
              updateInputField={updateLocalInput}
              value={_.map(selectedCoursesLocal, course => course.id)}
            />
          </div>
          <div className={classes.checkListFooter}>
            <div className={classes.buttonContainer}>
              <UIButton onClick={onCancel} size="sm">
                {t("common:cancel")}
              </UIButton>
            </div>
            <div className={classes.buttonContainer}>
              <UIButton color="blue" onClick={onDone} size="sm">
                {t("common:done")}
              </UIButton>
            </div>
          </div>
        </div>
      );
    };

    const onUpdateShowDropDown = v => {
      if (!v) {
        setSelectedCoursesLocal(selectedCourses);
      }
    };

    return (
      <div className={classes.container}>
        <div className={classes.courses}>
          <div className={classes.more}>
            {_.get(selectedCoursesLocal, "length", 0) > 0 ? (
              <MoreItemWithToolTip
                items={_.map(selectedCoursesLocal, course => course.title)}
                tooltipPlacement={"top"}
                length={0}
                trigger={["hover"]}
                {...moreWithToolTipStyles}
                renderComponent={
                  <div style={{ color: colors.gray31 }}>
                    {t("configuration:class_selected_with_count", {
                      count: selectedCoursesLocal.length,
                    })}
                  </div>
                }
              />
            ) : (
              <span>{t("configuration:no_class_selected")}</span>
            )}
          </div>
        </div>
        <ButtonDropdown
          {...dropDownStyle}
          buttonComponent={
            <div className={classes.editIcon} onClick={handleEdit}>
              <EditIcon width={16} height={16} fill={colors.gray72} />
            </div>
          }
          dropdownComponent={isGroupSelectOpen ? getDropdownComponent() : null}
          shouldCloseOnSelfClick={false}
          onUpdateShowDropDown={onUpdateShowDropDown}
        />
      </div>
    );
  }
);

ChooseCourses.displayName = "ChooseCourses";

const mapStateToProps = state => {
  const {
    platform: {
      organizationCurriculumPrograms: curriculumPrograms,
      currentCurriculumProgramType,
    },
  } = state;
  const currentCurriculumProgramId = _.find(curriculumPrograms, {
    type: currentCurriculumProgramType,
  })?.id;
  return {
    curriculumProgramId: currentCurriculumProgramId,
  };
};

const mapActionCreators = {
  setSelectedCoursesForNotification,
};

export default compose(connect(mapStateToProps, mapActionCreators))(
  ChooseCourses
);
