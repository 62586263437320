export const PROJECT_GROUP_TYPE_COMMUNITY = "COMMUNITY";
export const PROJECT_GROUP_TYPE_PERSONAL = "PERSONAL";
export const PROJECT_GROUP_TYPE_SERVICE_AS_ACTON = "SERVICE_AS_ACTION";
export const PROJECT_GROUP_TYPE_DP_CAS = "DP_CAS";
export const PROJECT_GROUP_TYPE_DP_TOK = "DP_TOK";
export const PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION = "DP_TOK_EXHIBITION";
export const PROJECT_GROUP_TYPE_DP_TOK_ESSAY = "DP_TOK_ESSAY";
export const PROJECT_GROUP_TYPE_DP_EE = "DP_EE";

//Project submission accepted file types
export const PERSONAL_PROJECT_ACCEPTED_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.google-apps.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.google-apps.presentation",
  "application/pdf",
];
export const DP_EE_ACCEPTED_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.google-apps.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.google-apps.presentation",
  "application/pdf",
];
export const DP_TOK_ESSAY_ACCEPTED_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.google-apps.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.google-apps.presentation",
  "application/pdf",
];
export const DP_TOK_EXHIBITION_ACCEPTED_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.google-apps.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.google-apps.presentation",
  "application/pdf",
];

export const COMMUNITY_PROJECT_ACCEPTED_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.google-apps.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.google-apps.presentation",
  "application/pdf",
];

//Project Roles
export const ROLE_ADMIN = "admin";
export const ROLE_SUPERVISOR = "supervisor";
export const ROLE_STUDENT = "student";

//Project Group by
export const PROJECT_PROGRESS_GROUP_BY_STATUS = "STATUS";
export const PROJECT_PROGRESS_GROUP_BY_SUPERVISOR = "SUPERVISOR";

// Project group sub-type

export const PROJECT_GROUP_SUB_TYPE_CORE = "CORE";
export const PROJECT_GROUP_SUB_TYPE_INTERNAL_ASSESSMENT = "INTERNAL_ASSESSMENT";
export const PROJECT_GROUP_SUB_TYPE_EXTERNAL_ASSESSMENT = "EXTERNAL_ASSESSMENT";

//Project Status
export const PROJECT_STATUS_PENDING_FOR_APPROVAL = "PENDING_FOR_APPROVAL";
export const PROJECT_STATUS_NOT_STARTED = "NOT_STARTED";
export const PROJECT_STATUS_DOING_GREAT = "DOING_GREAT";
export const PROJECT_STATUS_NOT_QUITE = "NOT_QUITE";
export const PROJECT_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const PROJECT_STATUS_REVIEW_REQUESTED = "REVIEW_REQUESTED";
export const PROJECT_STATUS_COMPLETED = "COMPLETED";
export const PROJECT_STATUS_REJECTED = "REJECTED";
export const PROJECT_STATUS_POOR = "POOR";
export const PROJECT_STATUS_AVERAGE = "AVERAGE";
export const PROJECT_STATUS_GOOD = "GOOD";
export const PROJECT_STATUS_VERY_GOOD = "VERY_GOOD";
export const PROJECT_STATUS_APPROVED = "APPROVED";
export const PROJECT_STATUS_DRAFT = "DRAFT";

//Project Report Status
export const PROJECT_REPORT_STATUS_PENDING = "PENDING";
export const PROJECT_REPORT_STATUS_SUBMITTED = "SUBMITTED";
export const PROJECT_REPORT_STATUS_APPROVED = "APPROVED";
export const PROJECT_REPORT_STATUS_NOT_STARTED = "NOT_STARTED";
export const PROJECT_REPORT_STATUS_NON_SUBMISSION = "NON_SUBMISSION";
export const PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL =
  "PENDING_FOR_APPROVAL";

//Grade Constants
export const GRADE_8 = "Grade 8";
export const GRADE_9 = "Grade 9";
export const GRADE_10 = "Grade 10";

//IB PYP ELEMENT TYPE
export const IB_PYP_ELEMENT_TYPE_DP = "DP_CRITERIA";

//IB PYP ELEMENT SUBTYPE
export const IB_PYP_ELEMENT_SUBTYPE_EE_FINAL_GRADE = "EE_FINAL_GRADE";

export const ATTACHMENT_TYPE_PROJECT_GROUP_SL_STIMULI =
  "PROJECT_GROUP_SL_STIMULI";
export const ATTACHMENT_TYPE_PROJECT_GROUP_HL_STIMULI =
  "PROJECT_GROUP_HL_STIMULI";

export const HL_LABEL = "HL";
export const SL_LABEL = "SL";
