import React from "react";
import PropTypes from "prop-types";
import classes from "./SubjectGroupSelect.scss";
import { SelectDropdown, FormSelectDropdown, I18nHOC } from "UIComponents";
import {
  AddIcon,
  CancelIcon,
  ArtSGIcon,
  DesignSGIcon,
  IndiAndSocSGIcon,
  LangAcquisitionSGIcon,
  LangAndLitSGIcon,
  MathematicsSGIcon,
  PAndHEduSGIcon,
  ScienceSGIcon,
  DeleteIcon,
} from "SvgComponents";
import { colors } from "Constants";
import update from "immutability-helper";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import SubjectEditModal from "./SubjectEditModal";
import classNames from "classnames";
import { MYP_INTERDISCIPLINARY } from "Constants/stringConstants";
import { Alert } from "@toddle-design/web";

const iconSize = 24;

const ICON_SUBJECT_GROUP_MAPPING = [
  {
    iconKey: "ARTS",
    icon: <ArtSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "L&L",
    icon: <LangAndLitSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "LA",
    icon: <LangAcquisitionSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "DESIGN",
    icon: <DesignSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "SCI",
    icon: <ScienceSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "MATH",
    icon: <MathematicsSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "PHE",
    icon: <PAndHEduSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "I&S",
    icon: <IndiAndSocSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "COMMON",
    icon: <IndiAndSocSGIcon width={iconSize} height={iconSize} />,
  },
];

const styles = {
  optionContainerStyle: {
    display: "flex",
    alignItems: "center",
  },
};

export class SubjectGroupSelect extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, showEditModal: false };
    this.pairRefs = {};
  }

  componentDidMount = () => {
    this.createSubjectGroupPairFromValue();
  };

  componentDidUpdate = prevProps => {
    if (
      JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) ||
      prevProps.multi !== this.props.multi
    ) {
      this.createSubjectGroupPairFromValue();
    }
  };

  isValid = () => {
    const { isSubjectEditModal } = this.props;
    let totalErrors = 0;
    _.map(this.pairRefs, pair => {
      totalErrors += pair.subjectGroup.isValid();
      totalErrors += pair.subject.isValid();
      if (pair.objectiveLevelId && !pair.objectiveLevelId.props.disabled)
        totalErrors += pair.objectiveLevelId.isValid();
    });
    if (isSubjectEditModal) {
      return totalErrors;
    } else {
      return totalErrors > 0 ? 1 : 0;
    }
  };
  //get selected objective id for the given subject
  getSelectedObjectiveId = ({ subjectId }) => {
    const { options } = this.props;
    const objectiveLevelNodes = _.get(options, "objectiveLevelNodes", []);
    let selectedObjectiveLevels = _.get(options, "selectedObjectiveLevels", []);

    //filter out the empty objectives
    selectedObjectiveLevels = _.filter(selectedObjectiveLevels, Boolean);

    //fetch the objective with subject from selected objective levels
    const objectiveLevelId = _.find(selectedObjectiveLevels, objectiveId => {
      const objective = _.find(objectiveLevelNodes, { id: objectiveId });
      return _.find(_.get(objective, "associatedParents", []), {
        id: subjectId,
      });
    });

    return objectiveLevelId;
  };

  createSubjectGroupPairFromValue = () => {
    const { options, value, multi } = this.props;
    const initSubject = _.get(options, "initSubject", {});

    const subjectGroupPair = _.map(value, val => {
      const subjectGroups = _.get(options, "subjectGroups", []);

      const subjectGroup = _.find(subjectGroups, subjectGroup => {
        const subjects = _.get(subjectGroup, "subjects", []);
        return _.findIndex(subjects, subject => subject.id == val) >= 0;
      });
      const subjectGroupId = _.get(subjectGroup, "id", null);
      const objectiveLevelId = this.getSelectedObjectiveId({ subjectId: val });
      return {
        subjectGroup: subjectGroupId,
        subject: val,
        objectiveLevelId,
      };
    });

    if (subjectGroupPair.length === 1 && multi) {
      subjectGroupPair.push({
        subjectGroup: "",
        subject: "",
        objectiveLevelId: "",
      });
    }

    const subjectGroupPairList = this.getSubjectList({
      initSubject,
      subjectGroupPair,
    });
    this.setState({ subjectGroupPair: subjectGroupPairList });
  };

  // genrates final pair list in which 1st subject will be initSubject
  getSubjectList = ({ initSubject, subjectGroupPair }) => {
    const initSubjectId = _.get(initSubject, "id", null);
    const firstSubject = _.find(
      subjectGroupPair,
      { subject: initSubjectId },
      null
    );
    const restSubject = _.filter(
      subjectGroupPair,
      pair => pair.subject !== initSubjectId
    );
    if (firstSubject) {
      return [firstSubject, ...restSubject];
    } else {
      return [];
    }
  };

  onAddClick = () => {
    const { subjectGroupPair } = this.state;
    this.setState({
      subjectGroupPair: [
        ...subjectGroupPair,
        { subjectGroup: "", subject: "", objectiveLevelId: "" },
      ],
    });
  };

  onEditClick = () => {
    this.setState({ showEditModal: true });
  };

  onCancelModalEdit = () => {
    this.setState({ showEditModal: false });
  };

  onSaveModalEdit = ({ subjectGroupPair }) => {
    this.setState({ subjectGroupPair }, this.updateValueLocally);
  };

  onDeleteClick = ({ index }) => {
    const { subjectGroupPair } = this.state;
    this.pairRefs = update(this.pairRefs, { $unset: [index] });
    this.setState({
      subjectGroupPair: update(subjectGroupPair, { $splice: [[index, 1]] }),
    });

    setTimeout(() => {
      this.updateValueLocally();
    });
  };

  updateOption = ({ index, key, value }) => {
    const { options } = this.props;
    const objectiveLevelNodes = _.get(options, "objectiveLevelNodes", []);
    const selectedObjectiveLevelsWithValue = _.get(
      options,
      "selectedObjectiveLevelsWithValue",
      []
    );
    const { subjectGroupPair } = this.state;
    const oldParams = subjectGroupPair[index];
    const newParams = { [key]: value };
    const oldValue = subjectGroupPair[index][key];
    if (key == "subjectGroup" && oldValue != value) {
      newParams["subject"] = "";
      newParams["objectiveLevelId"] = "";
    }
    if (key == "subject" && oldValue != value) {
      //condition to handle if objective for this particular subject is already selected
      //if objective is already selected for this subject in past and user mistakenly
      //removed that subject and if user add that subject again than objective for it will be pre-selected
      //pre-selecteed objectives are the one which was selected before deleting it
      const objectiveLevelOptions = _.map(
        this.getObjectiveLevelOptions({
          objectiveLevelNodes,
          subject: newParams["subject"],
        }),
        objectivelevel => objectivelevel.value
      );
      const selectedObjectiveLevelIds = _.intersection(
        selectedObjectiveLevelsWithValue,
        objectiveLevelOptions
      );
      if (_.size(selectedObjectiveLevelIds) == 1) {
        newParams["objectiveLevelId"] = selectedObjectiveLevelIds[0];
      } else {
        //this condition will be handles when subject will be changed and no objectives are selected for it
        newParams["objectiveLevelId"] = "";
      }
    }
    this.setState({
      subjectGroupPair: update(subjectGroupPair, {
        [index]: {
          $set: { ...oldParams, ...newParams },
        },
      }),
    });
    //Send only when subject or objective field toggle
    if (["subject", "objectiveLevelId"].includes(key)) {
      setTimeout(() => {
        this.updateValueLocally();
      });
    }
  };

  //Converting subjectGroupPair into subject and subjectGroups and send them to parent
  updateValueLocally = () => {
    const { options } = this.props;
    const { subjectGroupPair } = this.state;
    const subjectGroups = _.get(options, "subjectGroups", []);
    const {
      value,
      nodes,
      subjectGroupValue,
      subjectGroupNodes,
      objectiveLevelValue,
    } = _.reduce(
      subjectGroupPair,
      (result, pair) => {
        //subject group will be given empty string for added tabs which are not yet selected
        const subjectGroup =
          _.find(
            subjectGroups,
            sg => _.findIndex(sg.subjects, sub => sub.id == pair.subject) >= 0
          ) || "";
        const subject = _.find(
          subjectGroup.subjects,
          sub => sub.id == pair.subject
        );
        result.objectiveLevelValue = result.objectiveLevelValue || [];
        result.value.push(pair.subject);
        if (pair.objectiveLevelId)
          result.objectiveLevelValue.push(pair.objectiveLevelId);
        result.nodes.push({ node: { ...subject, subjectGroup } });
        if (!_.includes(result.subjectGroupValue, subjectGroup.id)) {
          result.subjectGroupValue.push(subjectGroup.id);
          result.subjectGroupNodes.push({ node: subjectGroup });
        }

        return result;
      },
      {
        value: [],
        nodes: [],
        subjectGroupValue: [],
        subjectGroupNodes: [],
        objectiveLevelValue: [],
      }
    );
    this.updateValue(value, {
      nodes,
      subjectGroupValue,
      subjectGroupNodes,
      objectiveLevelValue,
    });
  };

  //get the objectives for the selected subject
  getObjectiveLevelOptions = ({ objectiveLevelNodes, subject }) => {
    const objectiveLevels = [];
    // iterating through all the objective and find the objectives that are related to selected subject
    _.forEach(objectiveLevelNodes, objectiveLevelNode => {
      if (_.find(objectiveLevelNode.associatedParents, { id: subject })) {
        objectiveLevels.push({
          value: objectiveLevelNode.id,
          label: objectiveLevelNode.label,
        });
      }
    });
    return objectiveLevels;
  };

  getSubjectGroupOptions = () => {
    const { options } = this.props;
    const subjectGroupOptions = _.get(options, "subjectGroups", []);

    return _.map(subjectGroupOptions, opt => {
      const iconKey = _.get(
        opt,
        "globalSubjectGroup.constants.iconKey",
        "COMMON"
      );
      const icon = _.get(
        _.find(ICON_SUBJECT_GROUP_MAPPING, { iconKey }),
        "icon",
        ""
      );

      return { label: opt.name, value: opt.id, icon, iconType: "COMPONENT" };
    });
  };

  //Remove those subjects which are already selected
  getSubjectOptions = ({ subjectGroup, subject }) => {
    const { options } = this.props;
    const { subjectGroupPair } = this.state;
    const subjectGroupOptions = _.get(options, "subjectGroups", []);
    let subjectOptions = _.get(
      _.find(subjectGroupOptions, { id: subjectGroup }),
      "subjects",
      []
    );
    subjectOptions = _.filter(subjectOptions, sg => {
      return !_.find(
        subjectGroupPair,
        pair => pair.subject == sg.id && pair.subject != subject
      );
    });

    return _.map(subjectOptions, opt => {
      return {
        label: opt.name,
        value: opt.id,
        icon: opt.icon,
        iconType: "URL",
      };
    });
  };

  updateRef = ({ ref, key, index }) => {
    if (!this.pairRefs[index]) {
      this.pairRefs[index] = {};
    }
    this.pairRefs = update(this.pairRefs, {
      [index]: { [key]: { $set: ref } },
    });
  };

  renderEdit = () => {
    const { subjectGroupPair, showEditModal } = this.state;
    const {
      t,
      options: {
        initSubject = {},
        isEditable = true,
        objectiveLevelNodes,
        selectedObjectiveLevelsWithValue,
        unitType,
      } = {},
      multi,
      value,
      options,
      parentType,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.subjectGroupPairs}>
          {_.map(subjectGroupPair, (pair, index) => {
            const { subjectGroup, subject, objectiveLevelId } = pair;
            const subjectGroupOptions = this.getSubjectGroupOptions({
              subjectGroup,
            });
            const subjectOptions = this.getSubjectOptions({
              subjectGroup,
              subject,
            });

            //For interdisciplinary units user can only edit the subjects from edit modal
            const isInterDisciplinaryUnitPlan =
              unitType == MYP_INTERDISCIPLINARY && parentType == "UNIT_PLAN";

            const objectiveLevelOptions = this.getObjectiveLevelOptions({
              objectiveLevelNodes,
              subject,
            });
            const isDisabled =
              _.get(initSubject, "id", "") == pair.subject || !isEditable;

            const canBeDeleted = subjectGroupPair.length > 2;

            //get the objectives with selected objectives
            //handling old unit where multiple objective levels are selected
            const selectedObjectiveLevelIds = _.intersection(
              selectedObjectiveLevelsWithValue,
              _.map(
                this.getObjectiveLevelOptions({
                  objectiveLevelNodes,
                  subject,
                }),
                objectivelevel => objectivelevel.value
              )
            );

            /*selectedObjectiveLevelsWithValue consist of array of all elements which are selected in the objectivesMYP section
            eg for interdisciplinary there are objective selected in Disciplinary_subjectname so the value of objectives parent 
            will be stored in this
            eg for subject specific unit there is a objective tab so the value of the selected objective's parent will be stored here
            */
            //also disable the objective levels for the subject with multiple objectives selected(old units)
            const isObjectiveLevelOptionDisabled =
              _.includes(
                options.selectedObjectiveLevelsWithValue,
                objectiveLevelId
              ) ||
              isInterDisciplinaryUnitPlan ||
              selectedObjectiveLevelIds.length > 1;

            const objectiveLevelLabel = _.get(
              _.find(objectiveLevelOptions, { value: objectiveLevelId }),
              "label",
              ""
            );

            return (
              <div className={classes.pairContainer} key={index}>
                {_.size(subjectGroupPair) > 1 && (
                  <div className={classes.titleContainer}>
                    <div className={classes.titleText}>
                      {t("unitPlan:unitType_subject_count", {
                        unitType: t("common:interdisciplinary"),
                        count: index + 1,
                      })}
                    </div>
                    {!isDisabled && canBeDeleted && (
                      <div
                        className={classes.removeContainer}
                        onClick={() => this.onDeleteClick({ index })}
                      >
                        <span> {t("common:remove")} </span>
                        <DeleteIcon
                          width={12}
                          height={12}
                          fill={colors.error50}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className={classes.pair}>
                  <div className={classes.topContainer}>
                    <div className={classes.childPair}>
                      <FormSelectDropdown
                        value={subjectGroup}
                        updateInputField={value =>
                          this.updateOption({
                            key: "subjectGroup",
                            value,
                            index,
                          })
                        }
                        optionContainerStyle={styles.optionContainerStyle}
                        disabled={isDisabled}
                        label={t("unitPlan:subject_group")}
                        options={subjectGroupOptions}
                        shouldApplyDisabledClass={false}
                        placeholder={t("common:select_with_label", {
                          label: t("common:subject_group"),
                        })}
                        customRef={ref =>
                          this.updateRef({ ref, index, key: "subjectGroup" })
                        }
                      />
                    </div>
                    <div className={classes.childPair}>
                      <FormSelectDropdown
                        value={subject}
                        updateInputField={value =>
                          this.updateOption({ key: "subject", value, index })
                        }
                        optionContainerStyle={styles.optionContainerStyle}
                        label={t("common:subject")}
                        disabled={isDisabled}
                        options={subjectOptions}
                        shouldApplyDisabledClass={false}
                        placeholder={t("common:select_with_label", {
                          label: t("common:subject"),
                        })}
                        customRef={ref =>
                          this.updateRef({ ref, index, key: "subject" })
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.childPair}>
                    <FormSelectDropdown
                      value={objectiveLevelId}
                      disabled={
                        isObjectiveLevelOptionDisabled ||
                        _.isEmpty(objectiveLevelOptions)
                      }
                      updateInputField={value =>
                        this.updateOption({
                          key: "objectiveLevelId",
                          value,
                          index,
                        })
                      }
                      label={t("unitPlan:myp_objective_year_phase")}
                      optionContainerStyle={styles.optionContainerStyle}
                      options={objectiveLevelOptions}
                      shouldApplyDisabledClass={false}
                      placeholder={t("unitPlan:select_year_phase")}
                      customRef={ref =>
                        this.updateRef({
                          ref,
                          index,
                          key: "objectiveLevelId",
                        })
                      }
                    />
                  </div>
                  {objectiveLevelId && (
                    <div className={classes.alertContainer}>
                      <Alert
                        type={"warning"}
                        message={t(
                          "unitPlan:myp_objective_unit_plan_create_warning_msg",
                          { label: objectiveLevelLabel }
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {multi && isEditable && (
          <div className={classes.addButtonContainer} onClick={this.onAddClick}>
            <div className={classes.addButtonSvgContainer}>
              <AddIcon width={10} height={10} fill={colors.white} />
            </div>

            <div className={classes.addAnotherButton}>
              {t("unitPlan:add_idu_subject")}
            </div>
          </div>
        )}
        {showEditModal && (
          <SubjectEditModal
            value={value}
            options={{ ...options, isEditable: true }}
            isAbsoluteCancelIcon={true}
            onCancelClick={this.onCancelModalEdit}
            onSaveClick={this.onSaveModalEdit}
          />
        )}
      </div>
    );
  };
}

SubjectGroupSelect.propTypes = {
  ...UIBaseComponent.propTypes,
};

SubjectGroupSelect.defaultProps = {
  ...UIBaseComponent.defaultProps,
  multi: true,
  isAbsoluteCancelIcon: true,
};

export default I18nHOC({ resource: ["common", "unitPlan"] })(
  SubjectGroupSelect
);
