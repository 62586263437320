import _ from "lodash";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { parseMenuItems, AdminMenu } from "Administrator/AdminMenu";
import {
  getNextMenuRoute,
  getAdminNode,
} from "Administrator/modules/AdministratorModule";
import { goToRelativeRoute } from "modules/Services";
import { getOrganizationCurriculumProgramsFromCache } from "modules/CommonGraphqlHelpers";
import { getUserInfo } from "Login/modules/LoginModule";
import { getSettingValue } from "modules/PermissionModule";
import { getPaidCurriculumPrograms } from "Platform/modules/PlatformUtils";
import countries from "Constants/countries.json";

export const getCurriculumProgramTypeFromId = ({
  curriculumPrograms,
  curriculumProgramId,
}) => {
  const curriculumProgramType = _.get(
    _.find(curriculumPrograms, { id: curriculumProgramId }),
    "type"
  );
  return curriculumProgramType;
};

export const getCurriculumProgramIdFromType = ({
  curriculumPrograms,
  curriculumProgramType,
}) => {
  const curriculumProgramId = _.get(
    _.find(curriculumPrograms, { type: curriculumProgramType }),
    "id"
  );
  return curriculumProgramId;
};

export const ADMIN_SEARCH_TEXT_FIELDS = {
  student: ["EMAIL", "NAME", "SOURCED_ID"],
  staff: ["EMAIL", "NAME"],
  family: ["EMAIL", "NAME"],
};

export const getAdminSetupFiltersByEntityType = ({
  type,
  courses = [],
  searchText = "",
  templateType,
  curriculumProgramId,
  curriculumProgramType,
  archivalState,
  designations,
  searchTextFields,
  grades,
  subjects,
  excludedTeacherIds,
  excludedStudentIds,
  subjectGroups,
  excludedCourseIds,
}) => {
  switch (type) {
    case "GRADE":
    case "SUBJECT":
    case "SUBJECT_GROUP":
    case "YEAR_GROUPS":
      return {
        curriculumProgramIds: curriculumProgramId ? [curriculumProgramId] : [],
      };
    case "STUDENT":
      return {
        courses,
        searchText,
        curriculumProgramIds: curriculumProgramId ? [curriculumProgramId] : [],
        archivalState,
        searchTextFields,
        excludedCourseIds,
        grades,
      };
    case "STAFF":
      return {
        courses,
        searchText,
        designations,
        curriculumProgramIds: curriculumProgramId ? [curriculumProgramId] : [],
        archivalState,
        searchTextFields,
      };
    case "COURSE":
      return {
        searchText,
        archivalState,
        curriculumProgramIds: curriculumProgramId ? [curriculumProgramId] : [],
        grades,
        subjects,
        excludedTeacherIds,
        excludedStudentIds,
        subjectGroups,
      };
    case "TEMPLATE":
      return {
        templateType: _.isNull(templateType) ? [] : [templateType],
        curriculumProgramIds: curriculumProgramId ? [curriculumProgramId] : [],
      };
    case "VISITOR_SETUP":
      return {
        searchText,
        roles: ["visitor"],
      };
  }
};

export const getAdminSetupFiltersByEntityTypeMemoized = _.memoize(
  params => getAdminSetupFiltersByEntityType(params),
  params => JSON.stringify(params)
);

export const getCurriculumProgramTypeFromParentId = ({ parentId }) => {
  switch (parentId) {
    case "MYP_ACADEMIC_SETUP":
      return CURRICULUM_TYPE_MYP;

    case "UBD_ACADEMIC_SETUP":
      return CURRICULUM_TYPE_UBD;

    case "DP_ACADEMIC_SETUP":
      return CURRICULUM_TYPE_DP;

    default:
      return CURRICULUM_TYPE_PYP;
  }
};

export const getAssessmentToolFilters = ({ curriculumProgramId }) => {
  const filters = { curriculumProgramIds: [curriculumProgramId] };
  return filters;
};

export const getUpdatedAdminMenuItems = ({
  adminMenuItems,
  nodesToBeUpdated,
  organizationId,
  t,
}) => {
  const { nodes } = adminMenuItems;

  const updatedNodes = _.map(nodes, node => {
    const shouldNodeUpdate = _.includes(nodesToBeUpdated, node.id);

    if (!shouldNodeUpdate) {
      return node;
    }

    const { id } = node;
    switch (id) {
      case "SnpV2": {
        const labelSetting = getSettingValue({
          organizationId,
          name: "AppSettingSnpModuleLabel",
        });

        const label = t(`snp:${labelSetting}`, { defaultValue: labelSetting });
        return { ...node, label, skipLocalization: true };
      }
      default: {
        return node;
      }
    }
  });

  return { ...adminMenuItems, nodes: updatedNodes };
};

const getAdminMenuItems = () => {
  const userInfo = getUserInfo();
  const curriculumProgramsObj = getOrganizationCurriculumProgramsFromCache(
    _.get(userInfo, "org_id")
  );
  const curriculumPrograms = _.get(
    curriculumProgramsObj,
    "curriculumPrograms",
    []
  );

  const paidCurriculumPrograms = getPaidCurriculumPrograms({
    curriculumPrograms,
  });
  if (_.isEmpty(paidCurriculumPrograms)) return [];

  const adminMenuItems = parseMenuItems({
    curriculumPrograms: paidCurriculumPrograms,
  });

  return adminMenuItems;
};

export const getAdminMenuItemsMemoize = _.memoize(
  params => getAdminMenuItems(params),
  params => JSON.stringify(params)
);

export const getItemLabel = ({
  item,
  plannerElementSets,
  t,
  customMenuItemLabelsMap = {},
}) => {
  if (!item) return "";

  const itemId = _.get(item, "id");
  const customLabel = _.get(customMenuItemLabelsMap, `${itemId}`);

  if (customLabel) {
    return customLabel;
  }

  const defaultLabel = item.sidebarLabel || item.label;

  const localeKeys = _.reduce(
    _.get(item, "locale_keys"),
    (result, val, key) => {
      result[key] = t(val, { defaultValue: val });
      return result;
    },
    {}
  );

  const defaultLocalizedLabel = t(defaultLabel, {
    ...localeKeys,
    defaultValue: defaultLabel,
  });

  const label = item.isDynamicPlannerElementLabel
    ? _.get(
        plannerElementSets,
        `${item.plannerElementType}.label`,
        defaultLocalizedLabel
      )
    : defaultLocalizedLabel;

  return label;
};

export const getBackLabel = ({ menuItems, plannerElementSets, t }) => {
  const itemId = _.get(_.head(menuItems), "parent");
  const item = getAdminNode(itemId);
  return getItemLabel({ item, plannerElementSets, t });
};

const getMenuItems = ({ parentId }) => {
  const menuItems = [];
  const menuItemIds = _.get(getAdminNode(parentId), "children", []);
  _.forEach(menuItemIds, childId => {
    const menuItem = getAdminNode(childId);
    if (!_.isEmpty(menuItem)) menuItems.push(menuItem);
  });
  return menuItems;
};

export const getMenuItemsMemoize = _.memoize(
  params => getMenuItems(params),
  params => JSON.stringify(params)
);

const getPermissions = ({ item, nodes }) => {
  let permissions = [];
  const itemPerm = _.get(item, "perm");
  if (itemPerm) permissions.push(itemPerm);

  const children = _.get(item, "children", []);
  _.forEach(children, childId => {
    const nodeItem = _.find(nodes, { uid: childId });

    const nodeChildren = _.get(nodeItem, "children", []);
    const hasChildren = _.size(nodeChildren) !== 0;
    const nodePerm = _.get(nodeItem, "perm", "");
    const hasPerms = nodeItem && nodePerm && !hasChildren;
    if (hasPerms) {
      permissions.push(nodePerm);
    }
    if (hasChildren) {
      const childPermissions = getPermissions({
        item: nodeItem,
        nodes,
      });
      permissions = [...permissions, ...childPermissions];
    }
  });
  return permissions;
};

export const getPermissionsMemoize = _.memoize(
  params => getPermissions(params),
  params => JSON.stringify(params)
);

export const validateForm = ({ formDetails, requiredFields = [] }) => {
  let totalCount = 0;
  const newErrors = {};

  _.forOwn(formDetails, (value, key) => {
    const isError = _.isEmpty(value) && _.includes(requiredFields, key);
    newErrors[key] = isError;
    totalCount += isError;
  });
  return { totalCount, newErrors };
};

export const getSelectedOptionsLabels = ({ options, value }) => {
  const selectedOptionsLabels = _.map(
    _.filter(options, item => _.includes(value, item.value)),
    option => option.label
  );

  return selectedOptionsLabels;
};

export const getSelectedCurriculumObject = ({ selectedCurriculum }) => {
  const isSelected = curriculum => _.isEqual(curriculum, selectedCurriculum);

  const isPYP = isSelected(CURRICULUM_TYPE_PYP);
  const isMYP = isSelected(CURRICULUM_TYPE_MYP);
  const isUBD = isSelected(CURRICULUM_TYPE_UBD);
  const isDP = isSelected(CURRICULUM_TYPE_DP);

  return { isPYP, isMYP, isUBD, isDP };
};

export const parseAdminMenuId = id => {
  if (!id) return {};

  if (_.includes(id, ":")) {
    const [curriculumProgramId, nodeId] = _.split(id, ":");
    return { curriculumProgramId, nodeId };
  } else return { nodeId: id };
};

export const getAdminMenuUid = ({
  curriculumProgramId,
  nodeId,
  isDynamicNode,
}) => {
  const isDynamic = isDynamicNode || isDynamicAdminMenuNodeMemoize(nodeId);
  if (!isDynamic) return nodeId; // if its not a dynamic node, then the nodeId is the uid

  const parsed = parseAdminMenuId(nodeId);

  return curriculumProgramId
    ? `${curriculumProgramId}:${parsed.nodeId}`
    : parsed.nodeId;
};

export const removeIBPrefixFromCurriculumType = curriculumProgramType =>
  curriculumProgramType.replace("IB_", "");

const isDynamicAdminMenuNode = nodeId => {
  const { nodes } = AdminMenu;

  const parsed = parseAdminMenuId(nodeId);

  const matchedNode = _.find(nodes, {
    id: parsed.nodeId,
  });

  return _.get(matchedNode, "isDynamicNode", false);
};

export const isDynamicAdminMenuNodeMemoize = _.memoize(isDynamicAdminMenuNode);

const getCountryDropdownData = () => {
  const countryData = _.map(countries, ({ en_short_name, alpha_2_code }) => ({
    label: en_short_name,
    value: alpha_2_code,
  }));
  return countryData;
};

export const getCountryDropdownDataMemoize = _.memoize(
  params => getCountryDropdownData(params),
  params => JSON.stringify(params)
);
