import gql from "graphql-tag";
import { staffCourseFragment } from "Teacher/modules/TeacherFragments";

export const updateUserCoursesMutation = gql`
  mutation updateUserCourses(
    $removedCourses: [ID!]
    $addedCourses: [ID!]
    $id: ID!
    $filters: CourseFilter
    $isCurriculumProgramFree: Boolean! = false
  ) {
    platform {
      updateUserCourses(
        input: {
          removedCourses: $removedCourses
          addedCourses: $addedCourses
          id: $id
        }
      ) {
        ...staffItem
      }
    }
  }
  ${staffCourseFragment.staffCourse}
`;
