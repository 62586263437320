import gql from "graphql-tag";

import { staffFragment } from "modules/CommonFragments";

export const folderFragments = {
  folderItem: gql`
    fragment folderItem on Folder {
      id
      name
      color
    }
  `,
  folderDetails: gql`
    fragment folderItem on Folder {
      id
      name
      color
      createdBy {
        ...staffItem
      }
      totalPostCount
    }
    ${staffFragment.basicStaffDetails}
  `,
};
