import React, { useState, useEffect } from "react";
import { UIButton, I18nHOC, UIModal } from "UIComponents";
import classes from "./RetentionModal.scss";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import * as EventTracker from "lib/eventTracker";
import { activationEmailerStatus } from "Community/modules/CommunityModule";
import { CLOUD_URL } from "Constants";
import { goToRelativeRoute, downloadFromUrl } from "modules/Services";
import {
  setRetentionModalStatus,
  getUserInfo,
} from "Login/modules/LoginModule";
import { withRouter } from "react-router";
import CodeVerification from "./CodeVerification";
import Download from "./Download";

const dataMapping = {
  contribute_now: {
    header: "community:retention_contribute_header",
    subHeading: "community:retention_contribute_subheading",
    image: `${CLOUD_URL}/assets/webapp/Community/Type%20B_Community_Modal_4.png`,
    button: "community:retention_contribute_button",
    subText: "community:retention_subtext",
  },
  complete_profile: {
    header: "community:retention_profile_header",
    subHeading: "community:retention_profile_subheading",
    image: `${CLOUD_URL}/assets/webapp/Community/Type%20B_Community_Modal_2.png`,
    button: "community:retention_profile_button",
    subText: "community:retention_subtext",
  },
  explore_les: {
    header: "community:retention_explore_le_header",
    image: `${CLOUD_URL}/assets/webapp/Community/Type%20B_Community_Modal_1.png`,
    button: "community:retention_explore_le_button",
    subText: "community:retention_explore_le_subtext",
  },
  import_les: {
    header: "community:retention_import_le_header",
    image: `${CLOUD_URL}/assets/webapp/Community/Type%20B_Community_Modal_3.png`,
    button: "community:retention_import_le_button",
    subText: "community:retention_subtext",
  },
};

const RetentionModal = ({
  t,
  type,
  userId,
  goToRelativeRoute,
  setRetentionModalStatus,
  activationEmailerStatus,
  action,
  campaignDay,
  downloadFromUrl,
  utm_content,
  utm_source,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!type) {
      setRetentionModalStatus(false);
    } else if (action === "redirect") handleClick();
  }, []);

  const handleClick = async metadata => {
    let route;
    switch (type) {
      case "welcome": // type a, b - day 1
      case "welcome_mail":
        route = `community/home/featured/all`;
        break;
      case "explore_les": // type a, b - day 2
        route = `community/home/all/les`;
        break;
      case "complete_profile": // type a, b - day 3
        route = `community/home/profile/${userId}`;
        break;
      case "explore_units": // type a - day 4
        route = `community/home/all/units`;
        break;
      case "contribute_now": // type a - day 5
        route = `community/home/featured/all?action=contribute`;
        break;
      case "import_les": // type b - day 4, day 5
        route = `community/home/all/les`;
        break;
    }

    const inputData = {
      actionType:
        utm_source === "retention_mailer_type_b" && type === "enter_code"
          ? "enter_code"
          : type,
      campaignDay,
      metadata,
    };

    const result = await activationEmailerStatus(inputData);

    if (!_.get(result, "response")) {
      const errorMessage = _.get(result, "warnings.0.message", "");
      setErrorMessage(errorMessage);
      return;
    }
    const downloadUrl = _.get(result, "response.resourceLink");

    if (action === "download" || type === "enter_code") {
      await downloadFromUrl({
        downloadUrl,
        attachment: {
          url: downloadUrl,
          name: `${t("community:free_resource_by_day", {
            day: campaignDay - 1,
          })}`,
          mimeType: "application/pdf",
        },
        shouldAskForSave: true,
      });
    } else if (route) {
      await goToRelativeRoute({
        route,
        type: "replace",
        replacePath: "community",
      });
    }

    EventTracker.recordEvent({
      eventName: "Retention Mailer",
      eventData: {
        source: "community",
        target: type,
        campaignDay: utm_content,
        action: action === "download" ? "download" : "task",
      },
    });

    setRetentionModalStatus(false);
  };

  const data = dataMapping[type] || {};
  const { header, subHeading, image, button, subText } = data;

  if (action === "redirect") return null;

  return (
    <UIModal
      isOpen={true}
      modalContent={
        action === "download"
          ? classes.countDownModal
          : type === "enter_code"
          ? classes.enterCodeModal
          : classes.modalContent
      }
      overlayContainer={
        action === "download" ? undefined : classes.overlayContainer
      }
    >
      {type === "enter_code" ? (
        <CodeVerification
          t={t}
          handleClick={handleClick}
          errorMessage={errorMessage}
          setRetentionModalStatus={setRetentionModalStatus}
        />
      ) : action === "download" ? (
        <Download t={t} handleClick={handleClick} />
      ) : (
        <div className={classes.container}>
          <div className={classes.headerWrapper}>
            <div className={classes.header}>{t(header)}</div>
            {subHeading && (
              <div className={classes.subHeading}>{t(subHeading)}</div>
            )}
          </div>
          <div className={classes.image}>
            <img src={image} />
          </div>
          <UIButton
            onClick={() => handleClick()}
            color="pink"
            containerStyle={{
              marginBottom: "32px",
              minWidth: "100%",
              borderRadius: "4px",
            }}
          >
            {t(button)}
          </UIButton>
          <div className={classes.subText}>{t(subText)}</div>
        </div>
      )}
    </UIModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const utm_source = _.get(ownProps, "location.query.utm_source", "");
  const utm_content = _.get(ownProps, "location.query.utm_content", "");
  const campaignDay = Number(_.get(_.split(utm_content, "_"), "1", 0));
  let type = _.get(ownProps, "location.query.sub_type");
  const action =
    type === "welcome_email"
      ? "redirect"
      : _.get(ownProps, "location.query.action");

  if (action === "download") type = "get_utm_res";

  const userInfo = getUserInfo({ portalType: "COMMUNITY" });

  return {
    isData: true,
    isLoading: false,
    type,
    userId: _.get(userInfo, "id"),
    action,
    campaignDay,
    utm_content,
    utm_source,
  };
};

const mapActionCreators = {
  goToRelativeRoute,
  setRetentionModalStatus,
  activationEmailerStatus,
  downloadFromUrl,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] })
)(RetentionModal);
