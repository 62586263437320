import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import classes from "./AddAssignment.scss";
import {
  UIModal,
  FullScreenLoader,
  withLoader,
  DialogueBoxV2,
  I18nHOC,
} from "UIComponents";
import Header from "./Header";
import Footer from "./Footer";
import FooterV2 from "./FooterV2";
import { StepHeader } from "AppComponents";
import { getCurrentAcademicYear, updateField } from "modules/Services";
import moment from "moment";
import StepContainer from "./StepContainer";
import { compose, graphql } from "react-apollo";
import update from "immutability-helper";
import { connect } from "react-redux";
import { getAssignmentEditDetailsQuery } from "ClassRoom/modules/ClassRoomQuery";
import { getAssignmentEditDetailsFromCache } from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import {
  getCourseDetailsFromCache,
  getCourseStudentsFoldersFromCache,
  getMultiCourseStudentsFoldersFromCache,
} from "Course/modules/CourseGraphqlHelpers";
import { getUnitPlanDetails } from "IBPlanner/modules/IBPlannerModule";
import {
  createAssignment,
  updateAssignment,
  updateCourseId,
  setCourseId,
} from "ClassRoom/modules/ClassRoomModule";
import {
  createAssessment,
  cloneAssessment,
  deleteClonedAssessment,
  updateAssessment,
  updateAssessmentInCache,
} from "Assessments/modules/AssessmentModule";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";

import {
  getCourseStudentsFoldersQuery,
  getMultiCourseStudentsFoldersQuery,
} from "Course/modules/CourseQuery";
import { setToastMsg, getUserInfo } from "Login/modules/LoginModule";

import ScheduleAssignment from "./ScheduleAssignment";
import { getStaffCourseGenericFoldersBasicDetailsQuery } from "Teacher/modules/TeacherQuery";
import { getStaffCoursesGenericFolderBasicDetailsFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import {
  TrimTrailingSlashes,
  isURL,
  getDateTimeStringsBasedOnFormat,
} from "Utils";
import {
  getFormattedCoursesDetailsMemoize,
  getHasAssessmentDataChanged,
} from "ClassRoom/modules/utils";
import ACLStore from "lib/aclStore";
import { getScoreDetailsMemoize } from "ScoreCategories/modules/ScoreCategoriesModule";
import { getCalendarEventsViewSettingValue } from "MyCalendar/modules/Utils";
import { updateStudentsScore } from "AssessmentEvaluation/modules/AssessmentEvaluationModule";
import { setMaxScoreValue } from "Teacher/modules/TeacherModule";

let DiscussionPromptRef = null;

const generateDateTimeString = ({ dateString, timeString }) => {
  if (dateString && timeString) {
    return `${dateString}:${timeString}`;
  } else if (dateString) {
    return `${dateString}`;
  } else {
    return null;
  }
};

//function to get uniq genericfolder by name
// genericFolderOfCourse = [{label:"something",value:"folderValue"}]
const getUniqGenericFolders = ({ folderNamesLocal, genericFolderOfCourse }) => {
  const uniqGenericFolders = _.reduce(
    genericFolderOfCourse,
    (result, genericFolder) => {
      const folderIndex = _.findIndex(
        result,
        obj => obj.label == genericFolder.label
      );
      if (folderIndex < 0) {
        result.push({
          label: genericFolder.label,
          value: genericFolder.value,
          color: genericFolder.color,
        });
      }
      return result;
    },
    folderNamesLocal
  );
  return uniqGenericFolders;
};

const DIALOGUES_INFO = {
  DISCARD: {
    title: t => t("common:discard_changes"),
    message: t => t("common:dicard_the_changes_warning_msg"),
    confirmButtonText: t =>
      t("common:action_agree", { action: t("common:discard") }),
    cancelButtonText: t => t("common:agree_cancel"),
  },
  UPDATE_SCORE: {
    title: t => t("classRoom:update_max_score_title"),
    message: t => t("classRoom:confirm_update_max_score"),
    confirmButtonText: t => t("common:update"),
    cancelButtonText: t => t("common:cancel"),
  },
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AddAssignment = React.memo(props => {
  const {
    t,
    steps,
    onClose: handleClose,
    mode,
    courseId,
    course,
    contentType,
    createHeader,
    createAssignment,
    setToastMsg,
    assignmentData,
    updateAssignment,
    creationMode,
    resourceSelection,
    onDoneResourceSelection,
    genericFolders,
    cloneAssessment,
    sourceAssessmentId,
    updateCourseId,
    portalType,
    deleteClonedAssessment,
    selectedTimeSlot,
    curriculumProgram,
    createAssessment,
    getUnitPlanDetails,
    endDate,
    preSelectedCoursesDetails,
    creationSource,
    refetchUpcomingEvents,
    courseList,
    assessmentType,
    assessmentGroupType,
    pageName,
    assignmentId,
    assignmentDetails,
    showImageTitleInHeader,
    clonedAssessmentId = "",
    updateUnitPlanDataInRedux,
    courseAcademicYearsIds,
    showCalendarAssignmentByDueDate,
    setCourseId,
    maxScoreLocal,
    studentIdsWithParentId,
    setMaxScoreValue,
    updateAssessmentInCache,
    updateField,
    updateStudentsScore,
  } = props;
  const [currentStep, updateCurrentStep] = useState(steps[0].value);
  const [isLoading, toggleIsLoading] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [currentDialogueAction, changeCurrentDialogueAction] = useState("");
  const [
    showScheduleAssignmentModal,
    changeShowScheduleAssignmentModal,
  ] = useState(false);
  const [metaData, setMetaData] = useState({
    submitted: false,
  });

  const [showTemplateConfigModal, setShowTemplateConfigModal] = useState(false);
  const [currentClonedImportedLeId, setCurrentClonedImportedLeId] = useState(
    sourceAssessmentId || ""
  );
  const coursesInitialState = !_.isEmpty(courseId)
    ? getFormattedCoursesDetailsMemoize({
        courses: [course],
        selectedTimeSlot,
        itemType: "ASSIGNMENT",
        showCalendarAssignmentByDueDate,
      })
    : getFormattedCoursesDetailsMemoize({
        courses: preSelectedCoursesDetails,
        selectedTimeSlot,
        itemType: "ASSIGNMENT",
        showCalendarAssignmentByDueDate,
      });

  const [selectedState, changeSelectedState] = useState(
    resourceSelection
      ? {
          unitPlanId: "",
          resource: { label: "", attachments: [] },
        }
      : mode == "create"
      ? {
          unitPlanId: "",
          unitPlanTemplateId: "",
          leId: clonedAssessmentId ?? "",
          importedLeId: sourceAssessmentId || "",
          creationMode,
          selectedCourse: {},
          isClassDiscussionEnabled: contentType == "ASSIGNMENT_DISCUSSION",
          isStudentSubmissionEnabled: contentType == "ASSESSMENT",
          isTeacherEvaluationEnabled: false,
          isStudentEvaluationEnabled: false,
          isSimilarityCheckEnabled: false,
          isPrivate: false,
          folderNames: [],
          courses: coursesInitialState,
          meeting: {
            url: "",
            type: "ZOOM",
            authToken: "",
            microsoftAuthToken: "",
            isZoomAuthorized: false,
            scheduleDate: selectedTimeSlot
              ? _.get(selectedTimeSlot, "startDate", null)
              : moment().add(1, "days").format("YYYY-MM-DD"),
            scheduleTime: selectedTimeSlot
              ? _.get(selectedTimeSlot, "startTime", null)
              : "00:00",
          },
          contentType,
          resource: {
            label: "",
            attachments: [],
            title: "",
          },
        }
      : assignmentData
  );
  const [isNextDisabled, changeIsNextDisabled] = useState(true);
  const [isDoneButtonDisabled, setIsDoneButtonDisabled] = useState(false);
  // TODO: optimise folder structure, get folders from organization with course filter
  // filter out generic folders as per selected courses, and then returning uniq folders by name
  const genericFoldersLocal = useMemo(() => {
    if (mode == "edit") return genericFolders;
    else {
      const genericFolderOfCourse = _.filter(genericFolders, genericFolder => {
        const index = _.findIndex(
          _.get(selectedState, "courses", []),
          course => course.id == genericFolder.courseId
        );
        return index > -1;
      });
      const folderNamesLocal = _.map(
        _.get(selectedState, "folderNames", []),
        item => {
          const labelOfLocalFolder = _.get(
            _.find(
              genericFolders,
              genericFolder => genericFolder.value == item
            ),
            "label",
            item
          );
          return {
            label: labelOfLocalFolder,
            value: item,
          };
        }
      );
      return getUniqGenericFolders({ folderNamesLocal, genericFolderOfCourse });
    }
  }, [genericFolders, selectedState]);

  const prevLeId = usePrevious(selectedState.leId);
  const prevUnitPlanId = usePrevious(selectedState.unitPlanId);

  const changeCurrentStep = async value => {
    if (mode == "create") {
      switch (value) {
        case "ASSESSMENT_DETAILS":
        case "SETTINGS":
          if (
            _.includes(
              [
                "IMPORT_IN_CLASS",
                "IMPORT_LE_FROM_COMMUNITY",
                "IMPORT_LE",
                "IMPORT_LE_FROM_LE_LIBRARY",
                "ASSIGN_TO_CLASS",
              ],
              creationMode
            )
          ) {
            if (!selectedState.importedLeId) {
              setToastMsg("toastMsgs:select_le");
              return;
            }
            if (
              _.includes(
                [
                  "IMPORT_IN_CLASS",
                  "IMPORT_LE_FROM_LE_LIBRARY",
                  "ASSIGN_TO_CLASS",
                ],
                creationMode
              )
            ) {
              if (!courseId) {
                setToastMsg("toastMsgs:select_course");
                return;
              }
            }
            const response = await createAssessmentClone();
            if (!response) {
              return;
            }
          }
          break;
      }
    }

    setTimeout(() => updateCurrentStep(value), 100);
  };

  useEffect(() => {
    shouldDeleteAssessmentClone();
    if (
      _.includes(
        ["IMPORT_IN_CLASS", "ASSIGN_TO_CLASS", "CREATE_QUICK_TASK"],
        creationMode
      )
    )
      return;

    if (mode == "create") {
      changeSelectedState(prev => {
        return { ...prev, leId: "", importedLeId: "" };
      });
    }
  }, [selectedState.unitPlanId]);

  useEffect(() => {
    return () => {
      updateCourseId("");
      if (!_.get(metaData, "submitted", false)) deleteAssessmentClone();
    };
  }, []);

  useEffect(() => {
    if (!resourceSelection) {
      setTimeout(updateCourses());
    }
  }, [resourceSelection, selectedState.selectedCourse, course]);

  useEffect(() => {
    const course = _.get(selectedState, "selectedCourse.courseId", "");

    setCourseId(course);
  }, [selectedState.selectedCourse]);

  const onClose = async ({ deleteClonedAssessment = false } = {}) => {
    if (deleteClonedAssessment) await deleteAssessmentClone();
    handleClose();
  };

  const getDiscussionPromptRef = ref => {
    DiscussionPromptRef = ref;
  };

  const onResourceSelectionDone = () => {
    onDoneResourceSelection(_.get(selectedState, "resource.attachments", []));
    onClose();
  };

  const updateCourses = () => {
    if (mode === "create") {
      const selectedTimeSlotStartDate = _.get(
        selectedTimeSlot,
        "startDate",
        null
      );
      const selectedTimeSlotStartTime = _.get(
        selectedTimeSlot,
        "startTime",
        null
      );
      changeSelectedState(prev => {
        const courses = _.get(prev, "courses", []);

        const selectedCourseId = _.get(
          selectedState,
          "selectedCourse.courseId"
        );
        let courseValue;

        if (selectedCourseId) {
          courseValue = _.find(
            preSelectedCoursesDetails,
            item => item.id == selectedCourseId
          );
        } else {
          courseValue = _.cloneDeep(course);
        }

        return {
          ...prev,
          courses: !_.isEmpty(courseValue)
            ? [
                {
                  id: courseValue.id,
                  title: courseValue.title,
                  students: _.map(
                    _.get(courseValue, "students.edges", []),
                    student => student.node
                  ),
                  totalStudents: _.map(
                    _.get(courseValue, "students.edges", []),
                    student => student.node
                  ),
                  deadlineDate: showCalendarAssignmentByDueDate
                    ? selectedTimeSlotStartDate
                    : null,
                  deadlineTime:
                    showCalendarAssignmentByDueDate && selectedTimeSlotStartTime
                      ? moment(selectedTimeSlotStartTime, "HH:mm")
                          .add(30, "minutes")
                          .format("HH:mm")
                      : null,
                  state: {
                    state: "PUBLISHED",
                    scheduleDate: _.get(
                      _.first(courses),
                      "state.scheduleDate",
                      null
                    ),
                    scheduleTime: _.get(
                      _.first(courses),
                      "state.scheduleTime",
                      null
                    ),
                  },
                },
              ]
            : getFormattedCoursesDetailsMemoize({
                courses: preSelectedCoursesDetails,
                selectedTimeSlot,
                itemType: "ASSIGNMENT",
                showCalendarAssignmentByDueDate,
              }),
        };
      });
    }
  };

  const _refetchUpcomingEvents = () => {
    if (refetchUpcomingEvents) {
      refetchUpcomingEvents();
    }
  };

  const onClickDone = async () => {
    if (_.isEmpty(selectedState.courses)) {
      setToastMsg("toastMsgs:please_add_atleast_one_class_error");
      return;
    }

    // check if all selected classes have atleast one student tagged
    const courses = _.get(selectedState, "courses", []);
    const allCoursesHaveStudents = _.reduce(
      courses,
      (result, course) => {
        const students = _.get(course, "students", []);
        return (result = result && !_.isEmpty(students));
      },
      true
    );

    if (!allCoursesHaveStudents) {
      setToastMsg("toastMsgs:please_select_atleast_one_student_error");
      return;
    }
    let isScheduledAssignment = false;
    let sendObj = { ..._.omit(selectedState, ["unitPlanId"]) };
    if (mode == "create") {
      sendObj["courses"] = _.map(selectedState.courses, course => {
        const scheduleString = generateDateTimeString({
          dateString:
            contentType == "ASSIGNMENT_MEETING"
              ? _.get(selectedState, "meeting.scheduleDate")
              : course.state.scheduleDate,
          timeString:
            contentType == "ASSIGNMENT_MEETING"
              ? _.get(selectedState, "meeting.scheduleTime")
              : course.state.scheduleTime,
        });

        if (!_.isEmpty(scheduleString)) isScheduledAssignment = true;
        const scheduleStringToSend = !_.isEmpty(scheduleString)
          ? moment(scheduleString, "YYYY-MM-DD:HH:mm", true).isValid()
            ? moment(scheduleString, "YYYY-MM-DD:HH:mm").toISOString()
            : moment(scheduleString, "YYYY-MM-DD").toISOString()
          : moment().toISOString();

        const deadlineString = generateDateTimeString({
          dateString: course.deadlineDate,
          timeString: course.deadlineTime,
        });

        const deadlineStringToSend = !_.isEmpty(deadlineString)
          ? moment(deadlineString, "YYYY-MM-DD:HH:mm", true).isValid()
            ? moment(deadlineString, "YYYY-MM-DD:HH:mm").toISOString()
            : moment(
                `${deadlineString}:09:00`,
                "YYYY-MM-DD:HH:mm"
              ).toISOString()
          : null;

        return {
          courseId: course.id,
          title: course.title,
          deadline: deadlineStringToSend,
          publishedAt: scheduleStringToSend,
          students: _.map(course.students, student => student.id),
        };
      });
      sendObj;

      if (!_.isEmpty(selectedState.folderNames)) {
        const folderNamesToSend = _.map(
          selectedState.folderNames,
          folderName => {
            return _.get(
              _.find(
                genericFoldersLocal,
                genericFolder => genericFolder.value == folderName
              ),
              "label",
              folderName
            );
          }
        );
        sendObj["folderNames"] = folderNamesToSend;
      }

      sendObj = update(sendObj, {
        resource: {
          attachments: {
            $set: _.map(
              _.get(selectedState, "resource.attachments", []),
              attachment =>
                _.omit(attachment, ["id", "__typename", "streamUrl", "isRead"])
            ),
          },
          studentTemplates: {
            $set: _.map(
              _.get(selectedState, "resource.studentTemplates", []),
              template => {
                return {
                  ...(_.omit(template, [
                    "id",
                    "__typename",
                    "isPost",
                    "attachmentId",
                    "streamUrl",
                    "isRead",
                  ]) || {}),
                  attachments: _.map(
                    _.get(template, "attachments", []),
                    attachment => _.omit(attachment, ["id", "isRead"])
                  ),
                };
              }
            ),
          },
          voiceInstruction: {
            $set: _.isEmpty(selectedState.resource.voiceInstruction)
              ? undefined
              : _.omit(selectedState.resource.voiceInstruction, [
                  "id",
                  "__typename",
                  "streamUrl",
                  "isRead",
                ]),
          },
        },
      });

      if (contentType == "ASSIGNMENT_MEETING") {
        // check for title not empty for meeting
        if (_.isEmpty(_.get(selectedState, "resource.title", ""))) {
          setToastMsg("toastMsgs:please_enter_title_error");
          return;
        }
        const meeting = _.get(selectedState, "meeting", {});
        const meetingType = _.get(meeting, "type", "");
        const meetingURL = TrimTrailingSlashes(
          _.trim(_.get(meeting, "url", ""))
        );
        const isZoomAuthorized = _.get(meeting, "isZoomAuthorized");
        const microsoftAuthToken = _.get(meeting, "microsoftAuthToken", "");
        const meetingAuthToken = _.get(meeting, "authToken", "");

        sendObj = update(sendObj, {
          resource: {
            meeting: {
              $set: {},
            },
          },
        });

        sendObj = update(sendObj, {
          resource: {
            meeting: {
              type: {
                $set: meetingType,
              },
            },
          },
        });

        switch (meetingType) {
          case "OTHER":
            // check for url not empty for OTHER meeting type
            if (_.isEmpty(meetingURL)) {
              setToastMsg("toastMsgs:please_add_meeting_link");
              return;
            } else if (!isURL(meetingURL)) {
              setToastMsg("toastMsgs:please_enter_valid_link"); // if url is not valid display toast
              return;
            } else {
              sendObj = update(sendObj, {
                resource: {
                  meeting: {
                    url: {
                      $set: _.startsWith(meetingURL, "https://")
                        ? meetingURL
                        : `https://${meetingURL}`,
                    },
                  },
                },
              });
            }
            break;
          case "ZOOM":
            // check for whether user is zoom authorized or not to schedule a ZOOM call
            if (!isZoomAuthorized) {
              setToastMsg("toastMsgs:please_link_zoom_account");
              return;
            } else {
              sendObj = update(sendObj, {
                resource: {
                  meeting: {
                    authToken: {
                      $set: meetingAuthToken,
                    },
                  },
                },
              });
            }
            break;
          case "MICROSOFT":
            // check for whether microsoftAuthToken is there or not to schedule a MICROSOFT call
            if (_.isEmpty(microsoftAuthToken)) {
              setToastMsg("toastMsgs:please_link_microsoft_account");
              return;
            } else {
              sendObj = update(sendObj, {
                resource: {
                  meeting: {
                    authToken: {
                      $set: microsoftAuthToken,
                    },
                  },
                },
              });
            }
            break;
        }
        sendObj["shouldDuplicateAcrossCourses"] = false;
      }

      //omit unwanted meeting from sendObj, all necessary data has been added inside sendObj.resource.meeting
      sendObj = { ..._.omit(sendObj, ["meeting"]) };
      sendObj = update(sendObj, {
        curriculumProgramId: {
          $set: curriculumProgram.id,
        },
      });
    } else {
      const oldAttachments = _.get(assignmentData, "resource.attachments", []);
      const newAttachments = _.get(selectedState, "resource.attachments", []);
      const addedAttachments = _.differenceBy(
        newAttachments,
        oldAttachments,
        "id"
      );
      const removedAttachments = _.map(
        _.differenceBy(oldAttachments, newAttachments, "id"),
        attachment => attachment.id
      );

      const oldStudentTemplates = _.get(
        assignmentData,
        "resource.studentTemplates",
        []
      );

      const newStudentTemplates = _.get(
        selectedState,
        "resource.studentTemplates",
        []
      );

      const addedStudentTemplates = _.differenceBy(
        newStudentTemplates,
        oldStudentTemplates,
        "id"
      );

      const removedStudentTemplates = _.map(
        _.differenceBy(oldStudentTemplates, newStudentTemplates, "id"),
        template => template.id
      );

      if (!_.isEmpty(sendObj.resource)) {
        sendObj["resource"] = {
          ..._.omit(sendObj["resource"], [
            "attachments",
            "studentTemplates",
            "__typename",
          ]),
          addedStudentTemplates: _.map(addedStudentTemplates, template => {
            return {
              ...(_.omit(template, [
                "id",
                "__typename",
                "isPost",
                "attachmentId",
              ]) || {}),
              attachments: _.map(
                _.get(template, "attachments", []),
                attachment => _.omit(attachment, ["id", "streamUrl", "isRead"])
              ),
            };
          }),
          removedStudentTemplates,
          addedAttachments: _.map(addedAttachments, attachment =>
            _.omit(attachment, ["id", "__typename", "streamUrl", "isRead"])
          ),
          removedAttachments,
          voiceInstruction: {
            isAttachmentDeleted: _.isEmpty(
              _.get(sendObj, "resource.voiceInstruction", {})
            ),
            attachment: _.isEmpty(
              _.get(sendObj, "resource.voiceInstruction", {})
            )
              ? null
              : _.omit(_.get(sendObj, "resource.voiceInstruction", null), [
                  "id",
                  "__typename",
                  "streamUrl",
                  "isRead",
                ]),
          },
        };
      }

      const oldFolderNames = _.get(assignmentData, "folderNames", []);
      const newFolderNames = _.get(selectedState, "folderNames", []);

      if (!_.isEqual(newFolderNames, oldFolderNames)) {
        const addedFolders = _.filter(newFolderNames, id => {
          return (
            _.findIndex(oldFolderNames, oldFolderName => oldFolderName == id) <
            0
          );
        });

        const addedFolderNames = _.map(addedFolders, addedFolder => {
          return _.get(
            _.find(
              genericFoldersLocal,
              genericFolder => genericFolder.value == addedFolder
            ),
            "label",
            addedFolder
          );
        });

        const removedFolderIds = _.filter(oldFolderNames, id => {
          return (
            _.findIndex(newFolderNames, newFolderName => newFolderName == id) <
            0
          );
        });
        sendObj["folders"] = { addedFolderNames, removedFolderIds };
      }

      const oldStudents = _.get(assignmentData, `courses[${0}].students`, []);
      const newStudents = _.get(selectedState, `courses[${0}].students`, []);
      const deadlineDate = _.get(
        selectedState,
        `courses[${0}].deadlineDate`,
        null
      );
      const deadlineTime = _.get(
        selectedState,
        `courses[${0}].deadlineTime`,
        null
      );

      const deadlineString = generateDateTimeString({
        dateString: deadlineDate,
        timeString: deadlineTime,
      });

      const deadlineStringToSend = !_.isEmpty(deadlineString)
        ? moment(deadlineString, "YYYY-MM-DD:HH:mm", true).isValid()
          ? moment(deadlineString, "YYYY-MM-DD:HH:mm").toISOString()
          : moment(`${deadlineString}:09:00`, "YYYY-MM-DD:HH:mm").toISOString()
        : null;

      const scheduleDate = _.get(
        selectedState,
        `courses[${0}].state.scheduleDate`,
        null
      );
      const scheduleTime = _.get(
        selectedState,
        `courses[${0}].state.scheduleTime`,
        null
      );

      const addedStudents = _.map(
        _.differenceBy(newStudents, oldStudents, "id"),
        student => student.id
      );
      const removedStudents = _.map(
        _.differenceBy(oldStudents, newStudents, "id"),
        student => student.id
      );
      sendObj["students"] = {
        addedStudents,
        removedStudents,
      };
      sendObj = _.omit(sendObj, ["courses"]);
      sendObj["id"] = assignmentData.id;
      sendObj["deadline"] = deadlineStringToSend;
      const scheduleString = generateDateTimeString({
        dateString: scheduleDate,
        timeString: scheduleTime,
      });
      const scheduleStringToSend = !_.isEmpty(scheduleString)
        ? moment(scheduleString, "YYYY-MM-DD:HH:mm", true).isValid()
          ? moment(scheduleString, "YYYY-MM-DD:HH:mm").toISOString()
          : moment(scheduleString, "YYYY-MM-DD").toISOString()
        : moment().toISOString();
      sendObj["publishedAt"] = scheduleStringToSend;
    }
    sendObj["creationSource"] = creationSource;
    sendObj["pageName"] = pageName;

    try {
      toggleIsLoading(true);
      let isSuccess = false;
      if (mode == "create") {
        isSuccess = await createAssignment({
          ...(sendObj || {}),
          portalType,
          isScheduledAssignment,
        });
        setMetaData({ submitted: true });
        _refetchUpcomingEvents();
      } else {
        isSuccess = await updateAssignment({
          ...(sendObj || {}),
          isScheduledAssignment,
        });
        _refetchUpcomingEvents();
      }
      toggleIsLoading(false);
      if (isSuccess) {
        onClose();
      }
    } catch (e) {
      console.error(e);
    } finally {
      toggleIsLoading(false);
    }
  };

  const checkNextDisabledValidation = value => {
    isNextDisabled
      ? setToastMsg("toastMsgs:please_select_atleast_one_item_error")
      : changeCurrentStep(value);
  };

  const onClickStep = async value => {
    let assessmentDetails, id;
    if (
      _.get(
        steps.previous({ current: currentStep, findKey: "value" }),
        "value",
        ""
      ) === value
    ) {
      changeCurrentStep(value);
    } else {
      switch (currentStep) {
        case "DISCUSSION_CREATION":
          if (contentType == "ASSIGNMENT_RESOURCE") {
            if (_.isEmpty(_.get(selectedState, "resource.title", ""))) {
              setToastMsg("toastMsgs:please_enter_title_error");
              break;
            }
          }

          if (DiscussionPromptRef.isValid()) {
            setToastMsg("toastMsgs:please_enter_prompt_message_error");
          } else {
            changeCurrentStep(value);
          }
          break;
        case "ASSESSMENT_DETAILS": {
          assessmentDetails = getAssessmentDetailsFromCache(selectedState.leId);
          const allFields = _.get(assessmentDetails, "allFields", []);
          const { isScoreEnabled, maxScore } = getScoreDetailsMemoize({
            assessmentFields: allFields,
          });

          if (!isMaxScoreValid(isScoreEnabled, maxScore)) return;

          if (
            mode === "edit" &&
            isScoreEnabled &&
            maxScoreLocal &&
            maxScoreLocal != maxScore
          ) {
            handleMaxScoreModalState();
            return;
          }
          checkNextDisabledValidation(value);
          break;
        }
        case "UNIT_SELECTION":
        case "COMMUNITY_LE_SELECTION":
        case "LE_LIBRARY_LE_SELECTION":
        case "LE_SELECTION":
        case "RESOURCE_SELECTION":
          checkNextDisabledValidation(value);
          break;
        case "SMT_CREATION":
        case "FMT_CREATION":
        case "QT_CREATION":
        case "LE_CREATION":
        case "ASSESSMENT_CREATION":
          assessmentDetails = getAssessmentDetailsFromCache(selectedState.leId);
          if (_.isEmpty(_.get(assessmentDetails, "title.value", ""))) {
            setToastMsg("toastMsgs:please_enter_title_error");
          } else {
            const allFields = _.get(assessmentDetails, "allFields", []);
            const { isScoreEnabled, maxScore } = getScoreDetailsMemoize({
              assessmentFields: allFields,
            });

            if (isScoreEnabled && !maxScore) {
              setToastMsg("toastMsgs:please_enter_max_score_error");
              return;
            }

            changeCurrentStep(value);
          }
          break;
        case "LE_CREATE_UNIT_SELECTION":
          if (!selectedState.unitPlanId) {
            setToastMsg("toastMsgs:please_select_atleast_one_item_error");
            return;
          }

          if (!selectedState.leId) {
            toggleIsLoading(true);
            const unitPlanDetails = await getUnitPlanDetails({
              unitPlanId: selectedState.unitPlanId,
            });
            id = await createAssessment({
              unitPlanObj: {
                unitPlanId: selectedState.unitPlanId,
                unitPlanTemplateId: selectedState.unitPlanTemplateId,
              },
              academicYears: _.map(
                _.get(unitPlanDetails, "data.node.academicYears", []),
                item => item.id
              ),
            });
            toggleIsLoading(false);
            changeSelectedState(prev => {
              return { ...prev, leId: id };
            });
          }
          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
          break;
        case "SMT_CREATE_UNIT_SELECTION":
          if (!selectedState.unitPlanId) {
            setToastMsg("toastMsgs:please_select_atleast_one_item_error");
            return;
          }
          if (!selectedState.leId) {
            toggleIsLoading(true);
            const unitPlanDetails = await getUnitPlanDetails({
              unitPlanId: selectedState.unitPlanId,
            });

            id = await createAssessment({
              assessmentType: "smt",
              groupType: "ASSESSMENT",
              unitPlanObj: {
                unitPlanId: selectedState.unitPlanId,
                unitPlanTemplateId: selectedState.unitPlanTemplateId,
              },
              academicYears: _.map(
                _.get(unitPlanDetails, "data.node.academicYears", []),
                item => item.id
              ),
            });
            toggleIsLoading(false);
            changeSelectedState(prev => {
              return { ...prev, leId: id };
            });
          }
          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
          break;
        case "FMT_CREATE_UNIT_SELECTION":
          if (!selectedState.unitPlanId) {
            setToastMsg("toastMsgs:please_select_atleast_one_item_error");
            return;
          }
          if (!selectedState.leId) {
            toggleIsLoading(true);
            const unitPlanDetails = await getUnitPlanDetails({
              unitPlanId: selectedState.unitPlanId,
            });
            id = await createAssessment({
              assessmentType: "fmt",
              groupType: "ASSESSMENT",
              unitPlanObj: {
                unitPlanId: selectedState.unitPlanId,
                unitPlanTemplateId: selectedState.unitPlanTemplateId,
              },
              academicYears: _.map(
                _.get(unitPlanDetails, "data.node.academicYears", []),
                item => item.id
              ),
            });
            toggleIsLoading(false);
            changeSelectedState(prev => {
              return { ...prev, leId: id };
            });
          }
          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
          break;
        case "ASSESSMENT_CREATE_UNIT_SELECTION":
          if (!selectedState.unitPlanId) {
            setToastMsg("toastMsgs:please_select_atleast_one_item_error");
            return;
          }
          if (!selectedState.leId) {
            toggleIsLoading(true);
            const unitPlanDetails = await getUnitPlanDetails({
              unitPlanId: selectedState.unitPlanId,
            });
            id = await createAssessment({
              assessmentType: "pt",
              groupType: "ASSESSMENT",
              unitPlanObj: {
                unitPlanId: selectedState.unitPlanId,
                unitPlanTemplateId: selectedState.unitPlanTemplateId,
              },
              academicYears: _.map(
                _.get(unitPlanDetails, "data.node.academicYears", []),
                item => item.id
              ),
            });
            toggleIsLoading(false);
            changeSelectedState(prev => {
              return { ...prev, leId: id };
            });
          }
          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
          break;
        case "SELECT_POST":
          if (!selectedState.importedLeId) {
            setToastMsg("toastMsgs:please_select_atleast_one_item_error");
            return;
          }
          toggleIsLoading(true);
          id = await cloneAssessment({
            sourceAssessmentId: _.get(selectedState, "importedLeId", ""),
            portalType,
            academicYears: courseAcademicYearsIds,
          });
          toggleIsLoading(false);
          changeSelectedState(prev => {
            return { ...prev, leId: id };
          });
          changeCurrentStep(value);
          break;
        case "UNIT_FROM_GRADE_SELECTION":
          if (!selectedState.unitPlanId) {
            setToastMsg("toastMsgs:please_select_atleast_one_item_error");
            return;
          }

          if (!selectedState.leId) {
            toggleIsLoading(true);
            const unitPlanDetails = await getUnitPlanDetails({
              unitPlanId: selectedState.unitPlanId,
            });
            id = await createAssessment({
              assessmentType,
              groupType: assessmentGroupType,
              unitPlanObj: {
                unitPlanId: selectedState.unitPlanId,
                unitPlanTemplateId: selectedState.unitPlanTemplateId,
              },
              academicYears: _.map(
                _.get(unitPlanDetails, "data.node.academicYears", []),
                item => item.id
              ),
            });
            toggleIsLoading(false);
            changeSelectedState(prev => {
              return { ...prev, leId: id };
            });
          }
          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
          break;
        default:
          changeCurrentStep(value);
      }
    }
  };

  const onClickNext = async () => {
    let id, assessmentDetails;
    switch (currentStep) {
      case "DISCUSSION_CREATION":
        if (contentType == "ASSIGNMENT_RESOURCE") {
          if (_.isEmpty(_.get(selectedState, "resource.title", ""))) {
            setToastMsg("toastMsgs:please_enter_title_error");
            break;
          }
        }
        if (_.isEmpty(_.get(selectedState, "resource.label", ""))) {
          setToastMsg("toastMsgs:please_enter_prompt_message_error");
        } else {
          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
        }
        break;
      case "LE_CREATE_UNIT_SELECTION":
        if (!selectedState.unitPlanId) {
          setToastMsg("toastMsgs:please_select_atleast_one_item_error");
          return;
        }
        if (!selectedState.leId) {
          toggleIsLoading(true);
          const unitPlanDetails = await getUnitPlanDetails({
            unitPlanId: selectedState.unitPlanId,
          });
          id = await createAssessment({
            unitPlanObj: {
              unitPlanId: selectedState.unitPlanId,
              unitPlanTemplateId: selectedState.unitPlanTemplateId,
            },
            academicYears: _.map(
              _.get(unitPlanDetails, "data.node.academicYears", []),
              item => item.id
            ),
          });
          toggleIsLoading(false);
          changeSelectedState(prev => {
            return { ...prev, leId: id };
          });
        }
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      case "SMT_CREATE_UNIT_SELECTION":
        if (!selectedState.unitPlanId) {
          setToastMsg("toastMsgs:please_select_atleast_one_item_error");
          return;
        }
        if (!selectedState.leId) {
          toggleIsLoading(true);
          const unitPlanDetails = await getUnitPlanDetails({
            unitPlanId: selectedState.unitPlanId,
          });
          id = await createAssessment({
            assessmentType: "smt",
            groupType: "ASSESSMENT",
            unitPlanObj: {
              unitPlanId: selectedState.unitPlanId,
              unitPlanTemplateId: selectedState.unitPlanTemplateId,
            },
            academicYears: _.map(
              _.get(unitPlanDetails, "data.node.academicYears", []),
              item => item.id
            ),
          });
          toggleIsLoading(false);
          changeSelectedState(prev => {
            return { ...prev, leId: id };
          });
        }
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      case "FMT_CREATE_UNIT_SELECTION":
        if (!selectedState.unitPlanId) {
          setToastMsg("toastMsgs:please_select_atleast_one_item_error");
          return;
        }
        if (!selectedState.leId) {
          toggleIsLoading(true);
          const unitPlanDetails = await getUnitPlanDetails({
            unitPlanId: selectedState.unitPlanId,
          });

          id = await createAssessment({
            assessmentType: "fmt",
            groupType: "ASSESSMENT",
            unitPlanObj: {
              unitPlanId: selectedState.unitPlanId,
              unitPlanTemplateId: selectedState.unitPlanTemplateId,
            },
            academicYears: _.map(
              _.get(unitPlanDetails, "data.node.academicYears", []),
              item => item.id
            ),
          });
          toggleIsLoading(false);
          changeSelectedState(prev => {
            return { ...prev, leId: id };
          });
        }
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      case "ASSESSMENT_CREATE_UNIT_SELECTION":
        if (!selectedState.unitPlanId) {
          setToastMsg("toastMsgs:please_select_atleast_one_item_error");
          return;
        }
        if (!selectedState.leId) {
          toggleIsLoading(true);
          const unitPlanDetails = await getUnitPlanDetails({
            unitPlanId: selectedState.unitPlanId,
          });
          id = await createAssessment({
            assessmentType: "pt",
            groupType: "ASSESSMENT",
            unitPlanObj: {
              unitPlanId: selectedState.unitPlanId,
              unitPlanTemplateId: selectedState.unitPlanTemplateId,
            },
            academicYears: _.map(
              _.get(unitPlanDetails, "data.node.academicYears", []),
              item => item.id
            ),
          });
          toggleIsLoading(false);
          changeSelectedState(prev => {
            return { ...prev, leId: id };
          });
        }
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      case "SELECT_POST":
        if (!selectedState.importedLeId) {
          setToastMsg("toastMsgs:please_select_atleast_one_item_error");
          return;
        }
        toggleIsLoading(true);
        id = await cloneAssessment({
          sourceAssessmentId: _.get(selectedState, "importedLeId", ""),
          portalType,
          academicYears: courseAcademicYearsIds,
        });
        toggleIsLoading(false);
        changeSelectedState(prev => {
          return { ...prev, leId: id };
        });
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      case "ASSESSMENT_DETAILS": {
        assessmentDetails = getAssessmentDetailsFromCache(selectedState.leId);
        const allFields = _.get(assessmentDetails, "allFields", []);
        const { isScoreEnabled, maxScore } = getScoreDetailsMemoize({
          assessmentFields: allFields,
        });
        if (!isMaxScoreValid(isScoreEnabled, maxScore)) return;
        if (isScoreEnabled && maxScoreLocal && maxScoreLocal != maxScore) {
          handleMaxScoreModalState();
          return;
        }
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      }
      case "SMT_CREATION":
      case "FMT_CREATION":
      case "QT_CREATION":
      case "LE_CREATION":
      case "ASSESSMENT_CREATION":
        assessmentDetails = getAssessmentDetailsFromCache(selectedState.leId);
        if (_.isEmpty(_.get(assessmentDetails, "title.value", ""))) {
          setToastMsg("toastMsgs:please_enter_title_error");
        } else {
          const allFields = _.get(assessmentDetails, "allFields", []);
          const { isScoreEnabled, maxScore } = getScoreDetailsMemoize({
            assessmentFields: allFields,
          });

          if (!isMaxScoreValid(isScoreEnabled, maxScore)) return;

          changeCurrentStep(
            steps.next({ current: currentStep, findKey: "value" }).value
          );
        }
        break;
      case "UNIT_FROM_GRADE_SELECTION":
        if (!selectedState.unitPlanId) {
          setToastMsg("toastMsgs:please_select_atleast_one_item_error");
          return;
        }

        if (!selectedState.leId && creationMode != "IMPORT_LE") {
          toggleIsLoading(true);
          const unitPlanDetails = await getUnitPlanDetails({
            unitPlanId: selectedState.unitPlanId,
          });
          id = await createAssessment({
            assessmentType,
            groupType: assessmentGroupType,
            unitPlanObj: {
              unitPlanId: selectedState.unitPlanId,
              unitPlanTemplateId: selectedState.unitPlanTemplateId,
            },
            academicYears: _.map(
              _.get(unitPlanDetails, "data.node.academicYears", []),
              item => item.id
            ),
          });
          toggleIsLoading(false);
          changeSelectedState(prev => {
            return { ...prev, leId: id };
          });
        }
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
        break;
      default:
        changeCurrentStep(
          steps.next({ current: currentStep, findKey: "value" }).value
        );
    }
  };

  const shouldDeleteAssessmentClone = async () => {
    if (mode == "edit") {
      return;
    }

    const { importedLeId, leId, unitPlanId } = selectedState;
    if (leId) {
      if (
        importedLeId !== currentClonedImportedLeId ||
        unitPlanId !== prevUnitPlanId
      ) {
        await deleteAssessmentClone();
      } else return toggleIsLoading(false);
    }
  };

  const createAssessmentClone = async () => {
    const { importedLeId, leId } = selectedState;

    if (
      !_.isEmpty(importedLeId) &&
      currentClonedImportedLeId == importedLeId &&
      !_.isEmpty(leId)
    ) {
      return true;
    }
    toggleIsLoading(true);
    shouldDeleteAssessmentClone();

    const clonedAssesmentId = await cloneAssessment({
      sourceAssessmentId: importedLeId,
      portalType,
      academicYears: courseAcademicYearsIds,
    });

    setCurrentClonedImportedLeId(importedLeId);
    changeSelectedState(prev => ({
      ...prev,
      leId: clonedAssesmentId,
    }));
    toggleIsLoading(false);
    return clonedAssesmentId;
  };

  const deleteAssessmentClone = async () => {
    if (!prevLeId) return;
    await deleteClonedAssessment({
      assessmentId: prevLeId,
      portalType,
    });
  };

  const updateNextDisabled = () => {
    switch (currentStep) {
      case "UNIT_SELECTION":
        changeIsNextDisabled(_.isEmpty(_.get(selectedState, "unitPlanId", "")));
        break;
      case "LE_SELECTION":
        changeIsNextDisabled(
          _.isEmpty(_.get(selectedState, "importedLeId", ""))
        );
        break;
      case "LE_LIBRARY_LE_SELECTION":
      case "COMMUNITY_LE_SELECTION":
        changeIsNextDisabled(
          _.isEmpty(_.get(selectedState, "importedLeId", ""))
        );
        break;

      case "ASSESSMENT_DETAILS":
        changeIsNextDisabled(_.isEmpty(_.get(selectedState, "leId", "")));
        break;

      case "UNIT_FROM_GRADE_SELECTION":
        changeIsNextDisabled(_.isEmpty(_.get(selectedState, "unitPlanId", "")));
        break;

      case "RESOURCE_SELECTION":
        changeIsNextDisabled(
          _.isEmpty(_.get(selectedState, "resource.attachments", []))
        );
        break;
      case "DISCUSSION_CREATION":
        changeIsNextDisabled(uploadingStatus);
        break;
      case "LE_CREATE_UNIT_SELECTION":
        changeIsNextDisabled(_.isEmpty(_.get(selectedState, "unitPlanId", "")));
        break;
      default:
        changeIsNextDisabled(false);
    }
  };

  const onClickDiscard = () => {
    switch (mode) {
      case "create":
        {
          const showConfirmDialogue = getHasAssessmentDataChanged({
            assessmentId: selectedState.leId,
            unitPlanTemplateId: selectedState.unitPlanTemplateId,
          });

          showConfirmDialogue
            ? changeCurrentDialogueAction("DISCARD")
            : onClose({ deleteClonedAssessment: true });
        }
        break;
      case "edit":
        {
          const showConfirmDialogue = !_.isEqual(assignmentData, selectedState);
          showConfirmDialogue
            ? changeCurrentDialogueAction("DISCARD")
            : onClose();
          setMaxScoreBackToPreviousValue();
        }
        break;
    }
  };

  const onDialogConfirmClick = () => {
    switch (currentDialogueAction) {
      case "DISCARD":
        onClose();
        break;
      case "UPDATE_SCORE":
        handleMaxScoreUpdateClick();
        break;
    }
  };

  const toggleShowTemplateConfigModal = () =>
    setShowTemplateConfigModal(!showTemplateConfigModal);

  const setMaxScoreBackToPreviousValue = () => {
    const assessmentDetails = getAssessmentDetailsFromCache(selectedState.leId);

    const allFields = _.get(assessmentDetails, "allFields", []);
    const { maxScore, isScoreEnabled } = getScoreDetailsMemoize({
      assessmentFields: allFields,
    });
    if (
      isScoreEnabled &&
      ((maxScoreLocal && maxScoreLocal !== maxScore) || isScoreZero(maxScore))
    ) {
      const scoreField = _.find(
        allFields,
        field => field.value && field.uid === "score"
      );

      const id = _.get(scoreField, "id", "");
      const value = _.get(scoreField, "value", {});

      const previousMaxScoreValue = {
        value: {
          ...value,
          maxScore: maxScoreLocal,
        },
      };

      const previousMaxScoreDetails = {
        id,
        hasAddRemoveValues: false,
        ...previousMaxScoreValue,
      };

      updateAssessmentInCache({
        fieldUID: id,
        params: {
          ...previousMaxScoreValue,
          id,
        },
        fieldObj: {
          ...scoreField,
        },
      });

      updateField({
        key: id,
        type: "ASSESSMENT",
        params: previousMaxScoreDetails,
      });
    }
  };

  const handleMaxScoreUpdateClick = () => {
    updateStudentsScore({
      studentIdsWithParentId,
      value: "",
    });
    const assessmentDetails = getAssessmentDetailsFromCache(selectedState.leId);

    const allFields = _.get(assessmentDetails, "allFields", []);
    const { maxScore } = getScoreDetailsMemoize({
      assessmentFields: allFields,
    });

    setMaxScoreValue(maxScore);
    changeCurrentStep(
      steps.next({ current: currentStep, findKey: "value" }).value
    );
  };

  const handleMaxScoreModalState = () => {
    changeCurrentDialogueAction("UPDATE_SCORE");
  };

  const isMaxScoreValid = (isScoreEnabled, maxScore) => {
    if (isScoreEnabled) {
      if (!maxScore) {
        setToastMsg("toastMsgs:please_enter_max_score_error");
        return false;
      } else if (isScoreZero(maxScore)) {
        setToastMsg("toastMsgs:max_score_greater_than_zero");
        return false;
      }
    }
    return true;
  };

  const isScoreZero = maxScore => {
    return parseInt(maxScore) === 0;
  };

  useEffect(() => {
    updateNextDisabled();
  }, [selectedState, currentStep, uploadingStatus]);

  const isLast = steps.last().value == currentStep;
  const isFirst = steps.first().value == currentStep;

  const isLECreationRevamp = ACLStore.can("FeatureFlag:RevampLeCreation");

  return (
    <div className={classes.container}>
      {!resourceSelection && (
        <Header
          onClickBack={onClickDiscard}
          mode={mode}
          createHeader={createHeader}
          showImageTitleInHeader={showImageTitleInHeader}
          assignmentDetails={assignmentDetails}
          currentStep={currentStep}
          toggleShowTemplateConfigModal={toggleShowTemplateConfigModal}
        ></Header>
      )}

      {_.get(steps, "length", 0) > 1 && !resourceSelection && (
        <StepHeader
          steps={steps}
          currentStep={currentStep}
          onClickStep={onClickStep}
        ></StepHeader>
      )}
      <StepContainer
        resourceSelection={resourceSelection}
        mode={mode}
        courseId={_.get(selectedState, "selectedCourse.courseId") || courseId}
        courseList={courseList}
        curriculumProgram={curriculumProgram}
        changeSelectedState={changeSelectedState}
        selectedState={selectedState}
        contentType={contentType}
        currentStep={currentStep}
        currentStepObj={_.find(steps, { value: currentStep })}
        getDiscussionPromptRef={getDiscussionPromptRef}
        setUploadingStatus={setUploadingStatus}
        genericFolders={genericFoldersLocal}
        updateCourseId={updateCourseId}
        portalType={portalType}
        selectedTimeSlot={selectedTimeSlot}
        setDisableButton={setIsDoneButtonDisabled}
        assignmentId={assignmentId}
        creationMode={creationMode}
        updateUnitPlanDataInRedux={updateUnitPlanDataInRedux}
        isLECreationRevamp={isLECreationRevamp}
        showTemplateConfigModal={showTemplateConfigModal}
        toggleShowTemplateConfigModal={toggleShowTemplateConfigModal}
      ></StepContainer>
      {isLECreationRevamp ? (
        <FooterV2
          mode={mode}
          isLast={isLast}
          isFirst={isFirst}
          onClickCancel={onClickDiscard}
          onClickDone={
            resourceSelection ? onResourceSelectionDone : onClickDone
          }
          isNextDisabled={isNextDisabled}
          onClickNext={onClickNext}
          currentStep={currentStep}
          resourceSelection={resourceSelection}
          courses={_.get(selectedState, "courses", [])}
          onClickSchedule={() => changeShowScheduleAssignmentModal(true)}
          onClickPrevious={() =>
            changeCurrentStep(
              steps.previous({ current: currentStep, findKey: "value" }).value
            )
          }
          isButtonDisabled={isDoneButtonDisabled}
          isLECreationRevamp={isLECreationRevamp}
        ></FooterV2>
      ) : (
        <Footer
          mode={mode}
          isLast={isLast}
          isFirst={isFirst}
          onClickCancel={onClickDiscard}
          onClickDone={
            resourceSelection ? onResourceSelectionDone : onClickDone
          }
          isNextDisabled={isNextDisabled}
          onClickNext={onClickNext}
          currentStep={currentStep}
          resourceSelection={resourceSelection}
          courses={_.get(selectedState, "courses", [])}
          onClickSchedule={() => changeShowScheduleAssignmentModal(true)}
          onClickPrevious={() =>
            changeCurrentStep(
              steps.previous({ current: currentStep, findKey: "value" }).value
            )
          }
          isButtonDisabled={isDoneButtonDisabled}
          isLECreationRevamp={isLECreationRevamp}
        ></Footer>
      )}

      {isLoading && <FullScreenLoader></FullScreenLoader>}
      {showScheduleAssignmentModal && (
        <UIModal
          isOpen={showScheduleAssignmentModal}
          modalContent={classes.scheduleAssignmentModalCon}
          onRequestClose={() => changeShowScheduleAssignmentModal(false)}
        >
          <ScheduleAssignment
            onClickDone={param => {
              changeSelectedState(prev => {
                return {
                  ...prev,
                  ...param,
                };
              });
              changeShowScheduleAssignmentModal(false);
            }}
            onClickClose={() => changeShowScheduleAssignmentModal(false)}
            courses={_.get(selectedState, "courses", [])}
            setDisableButton={setIsDoneButtonDisabled}
          />
        </UIModal>
      )}
      {!!currentDialogueAction && (
        <DialogueBoxV2
          modalTitle={DIALOGUES_INFO[currentDialogueAction].title(t)}
          showModal={true}
          onClickButton2={onDialogConfirmClick}
          modalBody={DIALOGUES_INFO[currentDialogueAction].message(t)}
          toggleDialogueBoxDisplay={() => changeCurrentDialogueAction("")}
          button1={DIALOGUES_INFO[currentDialogueAction].cancelButtonText(t)}
          button2={DIALOGUES_INFO[currentDialogueAction].confirmButtonText(t)}
          button1Props={{ variant: "outlined" }}
        />
      )}
    </div>
  );
});

const getCourse = course => {
  return course;
};

export const getMemoizeCourse = _.memoize(getCourse, params =>
  JSON.stringify(params)
);

const mapStateToProps = (state, ownProps) => {
  const course = getMemoizeCourse(ownProps.course);
  const userInfo = getUserInfo({ portalType: ownProps.portalType }) || {};
  const curriculumProgram = _.get(course, "curriculumProgram", {});
  const orgId = state.login.userInfo.org_id;
  const { startDate, endDate } =
    getCurrentAcademicYear({
      organizationId: orgId,
    }) || {};
  let preSelectedCourseIds = _.get(ownProps, "preSelectedCourseIds", null);
  const courseList = _.get(ownProps, "courseList", null, ownProps.steps);
  const courseAcademicYearsIds = _.map(
    _.get(course, "academicYears", []),
    item => item.id
  );

  //fetch all courses details when unit from course selction
  if (
    _.includes(
      _.map(ownProps.steps, item => item.value),
      "UNIT_FROM_GRADE_SELECTION"
    )
  ) {
    preSelectedCourseIds = _.map(courseList, item => item.courseId);
  }

  const showCalendarAssignmentByDueDate =
    getCalendarEventsViewSettingValue({ organizationId: orgId }) === "DUE_DATE";

  return {
    isData: true,
    isLoading: false,
    userId: userInfo.id,
    curriculumProgram,
    courseId: ownProps.courseId || _.get(state, "classRoom.selectedCourseId"),
    startDate,
    endDate,
    course,
    preSelectedCourseIds: _.compact(preSelectedCourseIds),
    courseAcademicYearsIds,
    showCalendarAssignmentByDueDate,
    maxScoreLocal: _.get(state, "teacher.maxScore", null),
  };
};

const mapActionCreators = {
  createAssignment,
  setToastMsg,
  updateAssignment,
  cloneAssessment,
  updateCourseId,
  deleteClonedAssessment,
  createAssessment,
  getUnitPlanDetails,
  setCourseId,
  updateStudentsScore,
  setMaxScoreValue,
  updateAssessment,
  updateField,
  updateAssessmentInCache,
};

AddAssignment.propTypes = {
  resourceSelection: PropTypes.bool,
};

AddAssignment.defaultProps = {
  resourceSelection: false,
  showImageTitleInHeader: false,
};

export default compose(
  I18nHOC({ resource: ["common", "classRoom"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseStudentsFoldersQuery, {
    skip: ({ resourceSelection, courseId }) => resourceSelection || !courseId,
    name: "getCourseStudentsFolders",
    options: ({ courseId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
        folderType: "CLASSROOM",
        portalType,
      },
    }),
    props({
      getCourseStudentsFolders,
      ownProps: { courseId, isData, isLoading, steps },
    }) {
      const courseDetails = getCourseStudentsFoldersFromCache({
        id: courseId,
        folderType: "CLASSROOM",
      });
      const hasClassStep = _.find(steps, ["value", "CLASS_SELECTION"]);
      return {
        course: courseDetails,
        isData: hasClassStep ? true : !_.isEmpty(courseDetails) && isData,
        isLoading:
          getCourseStudentsFolders["networkStatus"] == 1 ||
          getCourseStudentsFolders["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getMultiCourseStudentsFoldersQuery, {
    skip: ({ resourceSelection, courseId, preSelectedCourseIds }) =>
      resourceSelection || courseId || _.isEmpty(preSelectedCourseIds),
    name: "getMultiCourseStudentsFolders",
    options: ({ preSelectedCourseIds, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: preSelectedCourseIds,
        folderType: "CLASSROOM",
        portalType,
      },
    }),
    props({
      getMultiCourseStudentsFolders,
      ownProps: { preSelectedCourseIds, isData, isLoading, steps },
    }) {
      const courseDetails = getMultiCourseStudentsFoldersFromCache({
        ids: preSelectedCourseIds,
        folderType: "CLASSROOM",
      });
      return {
        preSelectedCoursesDetails: courseDetails,
        isData: !_.isEmpty(courseDetails) && isData,
        isLoading:
          getMultiCourseStudentsFolders["networkStatus"] == 1 ||
          getMultiCourseStudentsFolders["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getAssignmentEditDetailsQuery, {
    skip: ({ mode, assignmentId }) => mode != "edit" || !assignmentId,
    name: "getAssignmentEditDetails",
    options: ({ assignmentId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: assignmentId,
        portalType,
      },
    }),
    props({
      getAssignmentEditDetails,
      ownProps: { assignmentId, isData, isLoading, courseId, course },
    }) {
      const data =
        getAssignmentEditDetailsFromCache({
          id: assignmentId,
        }) || {};

      const {
        contentType,
        content,
        assignedStudents,
        deadline,
        state: { state, publishedAt, updatedAt } = {},
        folders,
      } = data;

      const assignmentData = {
        ..._.pick(data, [
          "id",
          "contentType",
          "isClassDiscussionEnabled",
          "isStudentSubmissionEnabled",
          "isTeacherEvaluationEnabled",
          "isStudentEvaluationEnabled",
          "isSimilarityCheckEnabled",
          "isPrivate",
        ]),
      };

      const studentIdsWithParentId = _.map(assignedStudents, student => ({
        parentId: _.get(student, "id", null),
        studentId: _.get(student, "student.id", null),
      }));

      const students = _.map(assignedStudents, ({ student }) => student);
      const totalStudents = _.map(
        _.get(course, "students.edges", []),
        student => student.node
      );
      const genericFolders = _.map(_.get(course, "genericFolders", []), obj => {
        return {
          label: obj.name,
          value: obj.id,
          color: obj.color,
        };
      });

      const folderNames = _.map(folders, obj => {
        return obj.id;
      });
      assignmentData["folderNames"] = folderNames;

      const { dateString, timeString } = getDateTimeStringsBasedOnFormat({
        inputDateTimeString: deadline,
      });

      assignmentData["courses"] = [
        {
          id: courseId,
          title: course.title,
          students: students,
          totalStudents: totalStudents,
          deadlineDate: dateString,
          deadlineTime: timeString,
          state: {
            state,
            scheduleDate: publishedAt
              ? moment(publishedAt).format("YYYY-MM-DD")
              : null,
            scheduleTime: publishedAt
              ? moment(publishedAt).format("HH:mm")
              : null,
          },
        },
      ];

      if (contentType == "ASSESSMENT") {
        assignmentData["leId"] = _.get(content, "id", "");
      } else {
        assignmentData["resource"] = content;
      }

      return {
        isData: !_.isEmpty(data) && isData,
        genericFolders,
        assignmentData,
        isLoading:
          getAssignmentEditDetails["networkStatus"] == 1 ||
          getAssignmentEditDetails["networkStatus"] == 2 ||
          isLoading,
        studentIdsWithParentId,
      };
    },
  }),
  graphql(getStaffCourseGenericFoldersBasicDetailsQuery, {
    skip: ({ mode }) => mode == "edit",
    name: "getStaffCourseGenericFoldersDetails",
    options: ({ userId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        folderType: "CLASSROOM",
        portalType,
      },
    }),
    props({
      getStaffCourseGenericFoldersDetails,
      ownProps: { userId, isData, isLoading },
    }) {
      const data = getStaffCoursesGenericFolderBasicDetailsFromCache({
        id: userId,
        folderType: "CLASSROOM",
      });
      const genericFolders = _.reduce(
        _.get(data, "courses", []),
        (result, { id, genericFolders }) => {
          result = result.concat(
            _.map(genericFolders, obj => {
              return {
                label: obj.name,
                value: obj.id,
                color: obj.color,
                courseId: id,
              };
            })
          );
          return result;
        },
        []
      );
      return {
        genericFolders,
        isData: !_.isEmpty(data) && isData,
        isLoading:
          getStaffCourseGenericFoldersDetails["networkStatus"] == 1 ||
          getStaffCourseGenericFoldersDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(AddAssignment);
