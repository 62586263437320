import { getQueryUpdatedQueryString } from "Utils";
import { WORK_BOOK_URL } from "Constants";
import ACLStore from "lib/aclStore";

export const WORKBOOK_MODE_TYPES = {
  IMAGE_FEEDBACK: "image_feedback",
  ADD_OR_EDIT_STUDENT_TEMPLATE: "ADD_OR_EDIT_STUDENT_TEMPLATE",
  ADD_OR_EDIT_ATTACHMENT: "ADD_OR_EDIT_ATTACHMENT",
  WORKBOOK_MULTIPAGE_ADD_OR_EDIT: "WORKBOOK_MULTIPAGE_ADD_OR_EDIT",
  WORKBOOK_MULTIPAGE_VIEW: "WORKBOOK_VIEW",
  USE_TEMPLATE: "USE_TEMPLATE",
  WORKBOOK_FEEDBACK_VIEW: "WORKBOOK_FEEDBACK_VIEW",
};

export const WORKBOOK_IFRAME_ID_SELECTORS = {
  CLASSROOM: "workbook-iframe-classroom",
  DEFAULT: "workbook-iframe-global",
  THEATRE_MODE: "TheatreModeComponent",
};

const adjustImageOrientation = imageOrientation => {
  let adjustedImageOrientation = imageOrientation;

  if (imageOrientation >= 360) {
    adjustedImageOrientation = imageOrientation % 360;
  } else if (imageOrientation < 0) {
    const fullRounds = Math.floor((imageOrientation * -1) / 360) + 1;
    adjustedImageOrientation = (fullRounds * 360 + imageOrientation) % 360;
  }

  return adjustedImageOrientation;
};

// returns the URL used to send to the workbook
export const getWorkBookUrl = ({
  attachmentId,
  imageOrientation = 0,
  mode = WORKBOOK_MODE_TYPES["IMAGE_FEEDBACK"],
  attachmentObj = {},
  disableArr = [],
}) => {
  const { token, org_id, id, user_type, email } =
    JSON.parse(_.get(localStorage, "userInfo", {})) || {};

  const userObj = {
    id,
    org_id,
    email,
    user_type,
  };

  const imageObj = {
    imageOrientation: adjustImageOrientation(imageOrientation),
  };

  let params = {
    token: token,
    mode: mode,
    user_metadata: JSON.stringify(userObj),
    img_metadata: JSON.stringify(imageObj),
    attachment_data: JSON.stringify(attachmentObj),
    disable_arr: JSON.stringify(disableArr),
  };

  if (attachmentId) {
    params = { ...params, id: attachmentId };
  }

  const paramString = getQueryUpdatedQueryString({
    url: WORK_BOOK_URL,
    params,
  });

  return `${WORK_BOOK_URL}?${paramString}`;
};

export const getWorkBookMessagePayload = ({
  attachmentId,
  imageOrientation = 0,
  mode = WORKBOOK_MODE_TYPES["IMAGE_FEEDBACK"],
  attachmentObj = {},
  disableArr = [],
  workbookUrl,
}) => {
  const { token, org_id, id, user_type, email } =
    JSON.parse(_.get(localStorage, "userInfo", {})) || {};

  const userObj = {
    id,
    org_id,
    email,
    user_type,
  };

  const imageObj = {
    imageOrientation: adjustImageOrientation(imageOrientation),
  };

  let params = {
    token: token,
    mode: mode,
    user_metadata: JSON.stringify(userObj),
    img_metadata: JSON.stringify(imageObj),
    attachment_data: JSON.stringify(attachmentObj),
    disable_arr: JSON.stringify(disableArr),
  };

  if (attachmentId) {
    params = { ...params, id: attachmentId };
  }

  if (workbookUrl) {
    params = { ...params, workbookUrl: workbookUrl };
  }
  return params;
};

export const getWorkbookModeForMediaModal = ({
  attachmentType,
  workbookMode,
  mode,
}) => {
  if (attachmentType === "IMAGE") {
    if (workbookMode === "ADD_OR_EDIT_STUDENT_TEMPLATE") {
      return WORKBOOK_MODE_TYPES["ADD_OR_EDIT_STUDENT_TEMPLATE"];
    }
    return WORKBOOK_MODE_TYPES["USE_TEMPLATE"];
  } else if (attachmentType === "WORKBOOK") {
    if (mode === "edit") {
      return WORKBOOK_MODE_TYPES["WORKBOOK_MULTIPAGE_ADD_OR_EDIT"];
    } else {
      return WORKBOOK_MODE_TYPES["WORKBOOK_MULTIPAGE_VIEW"];
    }
  } else {
    return workbookMode;
  }
};
