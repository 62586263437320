import { withAsyncRouteLoading } from "UIComponents";
import { AssessmentDetails } from "Assessments";
import { LeDetails } from "LearningEngagement";
import { injectReducer } from "store/reducers";

export default store => {
  return {
    path: ":step_id",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("OrganizationResources/modules/OrganizationResourcesModule")
                .default;
              injectReducer(store, { key: "organizationResource", reducer });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
    childRoutes: [AssessmentDetails(store), LeDetails(store)],
  };
};
