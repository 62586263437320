import PropTypes from "prop-types";

import React from "react";
import Microsoft from "lib/microsoft";

import { CustomLoginButton, CustomLoginBoxButton } from "UIComponents";

import { MicrosoftColored, Microsoft3D } from "@toddle-design/web-icons";

class MicrosoftLoginBtn extends React.PureComponent {
  onBtnClick = () => {
    this.loginPopup();
  };

  loginPopup = () => {
    const { onSuccess, onFailure } = this.props;
    Microsoft.loginPopup({
      successCallback: accessToken => {
        if (onSuccess) {
          onSuccess(accessToken);
        }
      },
      failureCallback: () => {
        if (onFailure) {
          onFailure();
        }
      },
    });
  };

  render() {
    const { isBoxButton, innerConStyle } = this.props;

    return (
      <React.Fragment>
        {isBoxButton ? (
          <CustomLoginBoxButton
            data-test-id={"button-login-microsoft"}
            icon={<Microsoft3D height={88} width={88} />}
            buttonText={"Microsoft"}
            onClick={this.onBtnClick}
          />
        ) : (
          <CustomLoginButton
            data-test-id={"button-login-microsoft"}
            icon={<MicrosoftColored size="x-small" />}
            buttonText={"Sign in with Microsoft"}
            onClick={this.onBtnClick}
            innerConStyle={innerConStyle}
          />
        )}
      </React.Fragment>
    );
  }
}

MicrosoftLoginBtn.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  isBoxButton: PropTypes.bool,
};

MicrosoftLoginBtn.defaultProps = {
  isBoxButton: false,
};

export default MicrosoftLoginBtn;
