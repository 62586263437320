import PropTypes from "prop-types";
import React from "react";

const AddAssessment = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <g
        fill={props.fill && props.fill}
        fillRule="evenodd"
        transform="translate(4 2)"
      >
        <path d="M19 9.8999L19 24.9999C19 25.5999 18.6 25.9999 18 25.9999L3 25.9999C2.4 25.9999 2 25.5999 2 24.9999L2 5.9999C2 5.3999 2.4 4.9999 3 4.9999L14.1 4.9999C14 4.6999 14 4.2999 14 3.9999 14 3.6999 14 3.2999 14.1 2.9999L3 2.9999C1.3 2.9999 0 4.2999 0 5.9999L0 24.9999C0 26.6999 1.3 27.9999 3 27.9999L18 27.9999C19.7 27.9999 21 26.6999 21 24.9999L21 9.8999C20.7 9.9999 20.3 9.9999 20 9.9999 19.7 9.9999 19.3 9.9999 19 9.8999M23 3L21 3 21 1C21 .4 20.6 0 20 0 19.4 0 19 .4 19 1L19 3 18 3 17 3C16.4 3 16 3.4 16 4 16 4.6 16.4 5 17 5L18 5 19 5 19 6 19 7C19 7.6 19.4 8 20 8 20.6 8 21 7.6 21 7L21 6 21 5 23 5C23.6 5 24 4.6 24 4 24 3.4 23.6 3 23 3" />
        <path d="M15 14C15 13.4 14.6 13 14 13L7 13C6.4 13 6 13.4 6 14 6 14.6 6.4 15 7 15L14 15C14.6 15 15 14.6 15 14M7 9C6.4 9 6 9.4 6 10 6 10.6 6.4 11 7 11L14 11C14.6 11 15 10.6 15 10 15 9.4 14.6 9 14 9L7 9zM10.7002 20.2998C10.3002 19.9008 9.7002 19.9008 9.3002 20.2998 8.9002 20.6998 8.9002 21.2998 9.3002 21.6998L11.3002 23.6998C11.5002 23.9008 11.8002 23.9998 12.0002 23.9998 12.2002 23.9998 12.5002 23.9008 12.7002 23.6998L16.7002 19.6998C17.0992 19.2998 17.0992 18.6998 16.7002 18.2998 16.3002 17.9008 15.7002 17.9008 15.3002 18.2998L12.0002 21.5998 10.7002 20.2998z" />
      </g>
    </svg>
  );
};

AddAssessment.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

AddAssessment.defaultProps = {
  height: 24,
  width: 24,
};

export default AddAssessment;
