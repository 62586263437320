import { colors } from "Constants";

export const styles = {
  unitEvidence: {
    cardStyles: {
      margin: "unset",
      width: "100%",
      height: "216px",
      border: `1px solid ${colors.strokeTwo}`,
    },
    activeCardStyles: {
      margin: "unset",
      width: "100%",
      height: "216px",
      border: `1px solid ${colors.blue29}`,
    },
    containerStyle: {
      overflowY: "auto",
      overflowX: "hidden",
      padding: "24px 40px 0 40px",
    },
    itemFeedContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(208px, 1fr))",
      gridGap: "24px",
    },
    mediaContainerStyle: {
      width: "100%",
    },
  },
};

export const overlayComponentStyles = {
  unitEvidence: {
    overlayContainerStyle: {
      width: "100%",
      height: "104px",
    },
  },
};
