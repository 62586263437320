/**--external-- */
import React from "react";
import PropTypes from "prop-types";

/**--internal-- */
import { AnimateHeight, CheckListContainer } from "UIComponents";

/**--relative-- */
import classes from "./CheckListAccordion.scss";
import DropdownItemHeader from "../DropdownItemHeader";
import { listItemStyle } from "./CheckListAccordionStyles";

const CheckListAccordion = props => {
  const {
    options,
    label,
    values,
    searchText,
    updateInputField,
    allOptionLabel,
    isExpanded,
    updateAccordionState,
    valueGroupKey,
  } = props;

  const totalValues = _.size(values);

  return (
    <div>
      <DropdownItemHeader
        isExpanded={isExpanded}
        onHeaderClick={() => updateAccordionState({ key: valueGroupKey })}
        totalValues={totalValues}
        label={label}
      />
      <AnimateHeight duration={300} height={isExpanded ? "auto" : 0}>
        <div className={classes.checkListContainer}>
          <CheckListContainer
            value={values}
            options={options}
            showAllOption={!searchText}
            listItemStyle={listItemStyle}
            updateInputField={updateInputField}
            allOptionLabel={allOptionLabel}
            isMatchingTextEnabled={true}
            searchText={searchText}
          />
        </div>
      </AnimateHeight>
    </div>
  );
};

export default CheckListAccordion;

CheckListAccordion.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  values: PropTypes.array,
  searchText: PropTypes.string,
  updateInputField: PropTypes.func,
  allOptionLabel: PropTypes.string,
  isExpanded: PropTypes.bool,
  updateAccordionState: PropTypes.func,
  valueGroupKey: PropTypes.string,
};

CheckListAccordion.displayName = "CheckListAccordion";
