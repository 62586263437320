import PropTypes from "prop-types";
import React from "react";

const TickPlain = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      height={props.height}
      width={props.width}
      viewBox="0 0 16 16"
      // style="enable-background:new 0 0 16 16;"
    >
      <path
        fill={props.fill}
        d="M15.8,2.7c-0.3-0.3-0.7-0.3-0.9,0l-9.1,9.1L1.1,7.3C0.9,7,0.5,7,0.2,7.3c-0.3,0.3-0.3,0.7,0,0.9
	l5.1,5.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1l9.5-9.5C16.1,3.4,16.1,3,15.8,2.7z"
      />
    </svg>
  );
};

TickPlain.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

TickPlain.defaultProps = {
  height: 24,
  width: 24,
};

export default TickPlain;
