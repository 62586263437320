import React, { useState, PureComponent, Fragment } from "react";
import {
  UIButton,
  I18nHOC,
  LinkWithTooltip,
  ButtonDropdown,
  MoreItemWithToolTip,
  FullScreenLoader,
  UIModal,
  LockedScreenWrapper,
  DialogueBox,
  ProfileCascade,
  MetaTags,
} from "UIComponents";
import classes from "./ViewHeader.scss";
import client from "apolloClient";
import {
  PlanathonSubmissionStars,
  EntitySelectionModal,
  EntitySelectionModalConfig,
  CoachMarks,
} from "AppComponents";
import { ActionButtons, ShareModal } from "AppComponents/Community";
import ACLStore from "lib/aclStore";
import { PrintIcon, CommentIcon, MoreIcon } from "SvgComponents";
import classNames from "classnames";
import {
  importAssessment,
  cloneAssessment,
} from "Assessments/modules/AssessmentModule";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import {
  colors,
  fontStyle,
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
} from "Constants";
import * as EventTracker from "lib/eventTracker";
import AddAssignment from "ClassRoom/components/AddAssignment";
import { getUserRoleInSchoolQuery } from "Community/routes/OnBoarding/modules/OnBoardingQuery";
import {
  unpublishEntity,
  isUserAuthenticatedForAction,
} from "Community/modules/CommunityModule";
import { goBack } from "react-router-redux";
import { getRethumbUrl, isMobile } from "Utils";
import { updateCoachMarkStatus } from "modules/CoachMarksModule";
import { withRouter } from "react-router";
import { getIsCurrentAcademicYear } from "Platform/modules/PlatformModule";
import { getUnitPlanDetails } from "IBPlanner/modules/IBPlannerModule";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { isMapleBearLikeEducator } from "Courses/modules/utils";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { CURRICULUM_TYPE_DP } from "Constants/stringConstants";
import {
  findCountryUser,
  isGlobalUser,
} from "routes/routes/Teacher/routes/Courses/modules/utils";

const styles = {
  avatarStyles: {
    size: "medium",
    color: "neutral",
    shape: "circle",
    iconType: "none",
    shade: "light",
    randomizeColor: true,
  },
};

const actionButtonTheme = {
  actionIconStyles: {
    LIKE: { width: 22, height: 22 },
    COMMENT: { width: 26, height: 26, showCommentAlways: true },
    SHARE: { width: 22, height: 22 },
    SAVE: { width: 20, height: 20 },
  },
  textStyle: {
    ...(isMobile() ? fontStyle.demiBold : {}),
    fontSize: isMobile() ? "1.4rem" : "1.6rem",
    color: isMobile() ? colors.gray48 : colors.gray31,
  },
  countStyle: isMobile()
    ? { color: colors.gray48 }
    : { ...fontStyle.demiBold, fontSize: "1.6rem", color: colors.gray31 },
};

const ADMIN_ROLES = ["PYP Coordinator", "School leader"];

const moreItemWIthToolTipStyle = {
  moreStyle: {
    color: colors.gray31,
  },
};

const profileCascadeStyle = {
  customStyle: {
    width: "48px",
    height: "48px",
  },
};

const typeNames = {
  ASSESSMENT: "common:assessment_label",
  LEARNING_ENGAGEMENT: "common:le_label",
};

const importDropdownStyle = {
  position: "absolute",
  top: "calc(100% + 12px)",
};

const importSteps = EntitySelectionModalConfig([
  { key: "GRADE_SELECTION" },
  { key: "UNIT_SELECTION" },
]);

const getImportMenuItems = ({ t }) => {
  return [
    {
      label: t("common:add_le_to_unit"),
      value: "IMPORT_IN_UNIT",
    },
    {
      label: t("common:assign_te_to_students"),
      value: "ASSIGN_TO_STUDENTS",
    },
  ];
};

const getAssignmentModalData = ({ t, type }) => {
  switch (type) {
    case "ASSIGN_TO_STUDENTS":
      return {
        mode: "create",
        creationMode: "ASSIGN_TO_CLASS",
        steps: [
          {
            label: t("common:edit_with_label", {
              label: t("common:post"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
          },
        ],
        contentType: "ASSESSMENT",
        createHeader: t("common:assign_to_students"),
        updateUnitPlanDataInRedux: false,
      };
    case "COMMUNITY":
      return {
        mode: "create",
        creationMode: "IMPORT_IN_CLASS",
        steps: [
          {
            label: t("common:select_with_label", {
              label: t("common:class"),
            }),
            value: "CLASS_SELECTION",
          },
          {
            label: t("common:edit_with_label", {
              label: t("community:learning_experience"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
          },
        ],
        contentType: "ASSESSMENT",
        createHeader: t("community:add_le_to_class"),
        updateUnitPlanDataInRedux: false,
      };
  }
};

const authTabsCommunity = t => [
  {
    label: t("common:unpublish_this_entity", {
      label: t("community:learning_experience"),
    }),
    value: "UNPUBLISH",
  },
];

const CommunityActions = ({
  id,
  entityRatings,
  savedBy,
  likes,
  title,
  communityComments,
  scrollToCommentSection,
}) => {
  const [showShareCoachmark, setShowShareCoachmark] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const onShareCoachmarkStepChanged = event => {
    if (!event.isDone) return;
    setShowShareModal(true);
  };

  const actionCommonProps = { entity: id, entityType: "ASSESSMENT" };
  const onCommentClick = () => {
    if (scrollToCommentSection) scrollToCommentSection();
  };

  return (
    <Fragment>
      <CoachMarks
        type={"COMMUNITY_LE_BOOKMARK_BUTTON"}
        continuous={true}
        params={{ target: "#COACHMARKS_COMMUNITY_LE_BOOKMARK_BUTTON" }}
        isTransparentOverlay={true}
        disableScrolling={false}
        disableScrollParentFix={false}
        disableOverlay={true}
        startAfterTime={3000}
      />
      {showShareCoachmark && (
        <CoachMarks
          type={"COMMUNITY_SHARE_BUTTON"}
          continuous={true}
          params={{ target: "#COACHMARKS_COMMUNITY_LE_SHARE_BUTTON" }}
          isTransparentOverlay={true}
          disableScrolling={false}
          disableScrollParentFix={false}
          disableOverlay={true}
          onStepChanged={onShareCoachmarkStepChanged}
        />
      )}
      {showShareModal && (
        <ShareModal
          entityId={id}
          entityType={"ASSESSMENT"}
          closeShareModal={() => setShowShareModal(false)}
          portalType={"COMMUNITY"}
        />
      )}
      <div className={classes.actionDetailContainer}>
        <ActionButtons
          {...actionCommonProps}
          entityRatings={entityRatings}
          actionTypes={["LIKE", "COMMENT", "SAVE", "SHARE"]}
          buttonType={"NORMAL_WITH_TEXT_BUTTON"}
          eventTarget={"unit preview"}
          entityName={title}
          likes={likes}
          savedBy={savedBy}
          theme={actionButtonTheme}
          communityComments={communityComments}
          onCommentClick={onCommentClick}
          onLikeClick={isLiked => setShowShareCoachmark(isLiked)}
          customConfig={{
            SAVE: { id: "COACHMARKS_COMMUNITY_LE_BOOKMARK_BUTTON" },
            SHARE: { id: "COACHMARKS_COMMUNITY_LE_SHARE_BUTTON" },
          }}
        />
      </div>
    </Fragment>
  );
};

const MoreIconForCommunity = ({ t, onActionDropDownClick, customClass }) => {
  const dropdownContainerClass = classNames({
    [classes.dropdown]: true,
    [classes[customClass]]: !!customClass,
  });

  return (
    <div className={dropdownContainerClass}>
      <ButtonDropdown
        authTabs={authTabsCommunity(t)}
        onItemClick={onActionDropDownClick}
        buttonComponent={
          <div className={classes.moreIcon}>
            <MoreIcon width={16} height={16} />
          </div>
        }
      />
    </div>
  );
};

const ViewCommunityActions = ({
  t,
  id,
  entityRatings,
  savedBy,
  importAssessment,
  parentId,
  title,
  isCurriculumProgramFree,
  likes,
  userId,
  showDropdown,
  onActionDropDownClick,
  updateCoachMarkStatus,
  userLoggedIn,
  isUserAuthenticatedForAction,
  getUnitPlanDetails,
}) => {
  const [showImportInUnitModal, setImportInUnityModalVisibility] = useState(
    false
  );
  const [showImportInClassModal, setImportLEInClass] = useState(false);
  const [showLockScreenModal, setLockScreenModal] = useState(false);
  const [lockedScreenKey, setLockedScreenKey] = useState("");
  const [loading, setLoading] = useState(false);

  const assignmentModalData = getAssignmentModalData({ t, type: "COMMUNITY" });
  const onImportMenuItemClick = async value => {
    switch (value) {
      case "IMPORT_IN_UNIT":
        EventTracker.recordEvent({
          eventName: "Clicked import le",
          eventData: {
            target: "learning experience whole view",
            entity_id: id,
            entity_type: "ASSESSMENT",
            source: "community",
            entity_name: title,
          },
        });
        setImportInUnityModalVisibility(true);
        break;
      case "ASSIGN_TO_STUDENTS":
        if (isCurriculumProgramFree) {
          await getUserRole();
          setLockScreenModal(true);
        } else setImportLEInClass(true);
        break;
    }
  };

  const getUserRole = async () => {
    setLoading(true);
    const result = await client.query({
      query: getUserRoleInSchoolQuery,
      variables: {
        id: userId,
      },
      fetchPolicy: "network-only",
    });
    const roles = _.map(_.get(result, "data.node.interests", []), "label");
    const isAdmin = _.find(roles, role => ADMIN_ROLES.includes(role));
    setLockedScreenKey(isAdmin ? "IMPORT_LE_ADMIN" : "IMPORT_LE");
    setLoading(false);
  };

  const handleSubmit = async (
    { unitId, courseId },
    selectedUnitCurriculumProgramId
  ) => {
    const unitPlanDetails = await getUnitPlanDetails({
      unitPlanId: unitId,
    });

    const unitPlanAcademicYearsIds = _.map(
      _.get(unitPlanDetails, "data.node.academicYears", []),
      item => item.id
    );

    const result = await importAssessment({
      sourceAssessmentId: parentId,
      unitId,
      portalType: "PLANNER",
      eventTarget: "learning experience whole view",
      courseId,
      academicYears: unitPlanAcademicYearsIds,
      userInfoPortalType: "COMMUNITY",
      selectedUnitCurriculumProgramId,
    });
    if (result) {
      updateCoachMarkStatus({
        type: "COMMUNITY_IMPORT_LE",
        portalType: "COMMUNITY",
      });
      setImportInUnityModalVisibility(false);
    }
  };

  const handleUseInClass = async () => {
    const hasAccess = await isUserAuthenticatedForAction();
    if (!hasAccess) return;
  };

  return (
    <Fragment>
      <CoachMarks
        type={"COMMUNITY_IMPORT_LE"}
        continuous={true}
        params={{ target: "#COACHMARKS_COMMUNITY_IMPORT_LE" }}
        isTransparentOverlay={true}
        disableScrolling={false}
        disableScrollParentFix={false}
        disableOverlay={true}
        startAfterTime={3200}
      />

      <div className={classes.viewActionsSection}>
        {showDropdown && (
          <MoreIconForCommunity
            customClass="desktop"
            onActionDropDownClick={onActionDropDownClick}
            t={t}
          />
        )}

        <ButtonDropdown
          containerStyle={importDropdownStyle}
          authTabs={getImportMenuItems({ t })}
          buttonDropdownClass={classes.buttonDropdownClass}
          containerClass={classes.containerClass}
          disabled={!userLoggedIn}
          buttonComponent={
            <UIButton
              color="pink"
              // type="hollow"
              size={isMobile() ? "lg" : "sm"}
              id="COACHMARKS_COMMUNITY_IMPORT_LE"
              containerStyle={{ borderRadius: "4px" }}
              className={classes.useInClass}
              onClick={handleUseInClass}
            >
              {t("common:use_in_class")}
            </UIButton>
          }
          onItemClick={onImportMenuItemClick}
        />
      </div>
      {loading && <FullScreenLoader />}
      {showImportInUnitModal && (
        <EntitySelectionModal
          setEntitySelectionModal={() => setImportInUnityModalVisibility(false)}
          steps={importSteps}
          handleSubmit={handleSubmit}
          portalType={"PLANNER"}
          isFullScreen={true}
          modalTitle={t("common:add_le_to_unit_2")}
        />
      )}
      {showImportInClassModal && (
        <UIModal isOpen={true} isFullScreen={true}>
          <AddAssignment
            sourceAssessmentId={parentId}
            mode={assignmentModalData["mode"]}
            creationMode={assignmentModalData["creationMode"]}
            steps={assignmentModalData["steps"]}
            assignmentId={assignmentModalData["id"]}
            contentType={assignmentModalData["contentType"]}
            createHeader={assignmentModalData["createHeader"]}
            onClose={() => setImportLEInClass(false)}
            portalType={"PLANNER"}
            updateUnitPlanDataInRedux={
              assignmentModalData["updateUnitPlanDataInRedux"]
            }
          />
        </UIModal>
      )}
      {showLockScreenModal && (
        <LockedScreenWrapper
          featureKey={lockedScreenKey}
          screenType={"modal"}
          onClose={() => setLockScreenModal(false)}
        />
      )}
    </Fragment>
  );
};

const ViewActions = ({
  t,
  onClickPrint,
  onClickEdit,
  isEditPermission,
  centralRepoConfig,
  parentType,
  unreadCommentCount,
  parentId,
  paritaStarStatus,
  kristenStarStatus,
  juryStarStatus,
  paritaProcessed,
  kristenProcessed,
  juryProcessed,
  isCommentActive,
  onCommentClick,
  isDemo,
  courseId,
  course,
  cloneAssessment,
  title,
  imageUrl,
  disableAssignToStudents,
  getIsCurrentAcademicYear,
  courseAcademicYearsIds,
  curriculumType,
  isResourceViewRevamp,
}) => {
  const [loading, toggleLoading] = useState(false);
  const [showAssignToStudentsModal, setShowAssignToStudentsModal] = useState(
    false
  );
  const [clonedAssessmentId, setClonedAssessmentId] = useState(null);

  const onClose = () => {
    setShowAssignToStudentsModal(false);
  };

  const onClickAddAssignmentToStudents = () => {
    addAssignment();
  };

  const addAssignment = async () => {
    toggleLoading(true);
    const clonedAssessmentId = await cloneAssessment({
      sourceAssessmentId: parentId,
      portalType: "PLANNER",
      academicYears: courseAcademicYearsIds,
    });
    setClonedAssessmentId(clonedAssessmentId);
    toggleLoading(false);
    if (clonedAssessmentId) {
      setShowAssignToStudentsModal(true);
    }
  };

  const {
    shouldDisableEdit = false,
    isGlobalOrCountryUser,
  } = centralRepoConfig;

  const assignmentModalData = getAssignmentModalData({
    t,
    type: "ASSIGN_TO_STUDENTS",
  });

  const commentClass = classNames(
    { [classes.commentHelper]: true },
    { [classes.iconGrey]: !isCommentActive },
    { [classes.iconBlue]: isCommentActive },
    {
      [classes.iconPink]: unreadCommentCount > 0,
    }
  );
  const notCurrentAcademicYear = !getIsCurrentAcademicYear();
  const showAssignToStudents =
    !disableAssignToStudents &&
    !notCurrentAcademicYear &&
    !_.includes([CURRICULUM_TYPE_DP], curriculumType);

  return (
    <div className={classes.viewActionsSection}>
      {
        <div className={classes.leftIconsContainer}>
          <PlanathonSubmissionStars
            parentId={parentId}
            parentType={parentType}
            paritaStarStatus={paritaStarStatus}
            kristenStarStatus={kristenStarStatus}
            juryStarStatus={juryStarStatus}
            containerStyle={{ marginRight: 16 }}
            paritaProcessed={paritaProcessed}
            kristenProcessed={kristenProcessed}
            juryProcessed={juryProcessed}
          />
          {!isResourceViewRevamp && (
            <React.Fragment>
              <LinkWithTooltip
                tooltip={t("print_with_label", {
                  label: t(typeNames[parentType]),
                })}
                href="#"
                placement="top"
              >
                <div className={classes.print} onClick={() => onClickPrint()}>
                  <PrintIcon />
                </div>
              </LinkWithTooltip>
              {!isDemo && (
                <div className={commentClass} onClick={onCommentClick}>
                  <CommentIcon
                    width={28}
                    height={28}
                    number={unreadCommentCount}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      }
      {!isDemo && !isResourceViewRevamp && (
        <div className={classes.buttonContainer}>
          {!shouldDisableEdit ? (
            <UIButton
              color="pink"
              onClick={onClickEdit}
              size={"sm"}
              type={showAssignToStudents ? "hollow" : "filled"}
              isDisabled={
                !isEditPermission &&
                !ACLStore.can("TeacherPortal:ShowLeReadOnly") &&
                !ACLStore.can("TeacherPortal:CanEditUnitPlan")
              }
            >
              {t("common:edit")}
            </UIButton>
          ) : null}

          {!isGlobalOrCountryUser && showAssignToStudents ? (
            <UIButton
              color={"pink"}
              size={"sm"}
              containerStyle={{ borderRadius: "4px" }}
              className={classes.useInClass}
              onClick={onClickAddAssignmentToStudents}
            >
              {t("common:assign_to_students")}
            </UIButton>
          ) : null}
        </div>
      )}
      {showAssignToStudentsModal && (
        <UIModal isOpen={true} isFullScreen={true}>
          <AddAssignment
            sourceAssessmentId={parentId}
            mode={assignmentModalData["mode"]}
            creationMode={assignmentModalData["creationMode"]}
            steps={assignmentModalData["steps"]}
            contentType={assignmentModalData["contentType"]}
            createHeader={assignmentModalData["createHeader"]}
            onClose={onClose}
            portalType={"PLANNER"}
            assignmentDetails={{
              assignmentImage: imageUrl,
              assignmentTitle: title ?? t("common:untitled"),
            }}
            showImageTitleInHeader={true}
            clonedAssessmentId={clonedAssessmentId}
            courseId={courseId}
            updateUnitPlanDataInRedux={
              assignmentModalData["updateUnitPlanDataInRedux"]
            }
            course={course}
          />
        </UIModal>
      )}
      {loading && <FullScreenLoader />}
    </div>
  );
};

class ViewHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showUnpublishModal: false,
    };
  }

  unpublishLE = async () => {
    const { goBack, unpublishEntity, parentId, t, fields } = this.props;

    const title = _.get(
      _.find(fields, field => field.uid == "title"),
      "value",
      ""
    );

    await unpublishEntity({
      entityId: parentId,
      entityType: "ASSESSMENT",
      entityName: title,
      typeLabel: t("community:learning_experience"),
    });

    goBack();
  };

  onActionDropDownClick = item => {
    switch (item) {
      case "UNPUBLISH":
        this.setState({ showUnpublishModal: true });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      fields,
      unreadCommentCount,
      totalCommentCount,
      onCommentClick,
      isCommentActive,
      createdBy,
      t,
      entityRatings,
      onClickPrint,
      onClickEdit,
      isEditPermission,
      isCommunity,
      parentType,
      parentId,
      paritaStarStatus,
      kristenStarStatus,
      juryStarStatus,
      paritaProcessed,
      kristenProcessed,
      juryProcessed,
      savedBy,
      submittedToCommunity,
      importAssessment,
      grades,
      isCurriculumProgramFree,
      likes,
      userId,
      toggleMediaModal,
      image,
      userType,
      updateCoachMarkStatus,
      goToCommunityRoute,
      communityComments,
      scrollToCommentSection,
      userLoggedIn,
      curriculumType,
      isUserAuthenticatedForAction,
      isDemo,
      cloneAssessment,
      centralRepoConfig,
      courseId,
      course,
      disableAssignToStudents,
      getIsCurrentAcademicYear,
      courseAcademicYearsIds,
      isResourceViewRevamp,
      unitPlanTitle,
    } = this.props;

    const { showUnpublishModal } = this.state;
    const title = _.get(
      _.find(fields, field => field.uid == "title"),
      "value",
      ""
    );

    const tags = _.get(
      _.find(fields, field => field.uid == "tags"),
      "value",
      []
    );
    const { firstName, lastName, profileImage, id: creatorId } = createdBy;
    const schoolName = _.get(createdBy, "schoolTenures.0.school.name", "");

    const name = `${firstName} ${lastName}`;
    const gradeComp = isCommunity ? (
      <MoreItemWithToolTip
        items={_.map(grades, grade => grade.name)}
        length={2}
        noItemText={""}
        {...moreItemWIthToolTipStyle}
      />
    ) : (
      ""
    );

    const imageUrl =
      image || PLACEHOLDER_RECT_IMAGE[parentId % PLACEHOLDER_RECT_IMAGE_COUNT];

    const rethumpUrl = `url(${getRethumbUrl({
      width: 360,
      height: 240,
      imageUrl,
      fitIn: true,
    })})`;

    const showDropdown = isCommunity && _.get(createdBy, "id") === userId;

    const userInfoCard = (customClass = "") => {
      const containerClass = classNames({
        [classes.userInfoCard]: true,
        [classes[customClass]]: !!customClass,
      });

      const nameClass = classNames({
        [classes.name]: true,
        [classes.nameRevamp]: isResourceViewRevamp,
      });

      return (
        <div className={containerClass}>
          <div className={classes.listProfile}>
            <ProfileCascade
              customStyle={profileCascadeStyle.customStyle}
              items={[{ imageUrl: profileImage, value: name }]}
              showAvatar={isResourceViewRevamp}
              avatarStyles={styles.avatarStyles}
            />
          </div>

          <div className={classes.content}>
            <div
              onClick={() =>
                isCommunity
                  ? goToCommunityRoute({
                      route: `profile/${creatorId}`,
                      isOpenInNewTab: false,
                    })
                  : null
              }
              className={nameClass}
            >
              {name}
            </div>
            <div className={classes.schoolName}>{schoolName}</div>
          </div>
        </div>
      );
    };

    const titleComp = customClass => {
      const containerClass = classNames({
        [classes.headerText]: true,
        [classes.headerTextRevamp]: isResourceViewRevamp,
        [classes[customClass]]: true,
      });

      const titleWrapperClasses = classNames({
        [classes.titleWrapper]: true,
        [classes[customClass]]: true,
      });

      return customClass === "mobile" ? (
        <div className={titleWrapperClasses}>
          <div className={classes.wrapper}>
            <div className={classes.leLabel}>{t("common:le_label")}</div>
            <div className={containerClass}>{title || t("untitled")}</div>
          </div>
          {userLoggedIn && (
            <MoreIconForCommunity
              t={t}
              customClass="mobile"
              onActionDropDownClick={this.onActionDropDownClick}
            />
          )}
        </div>
      ) : (
        <div className={containerClass}>{title || t("untitled")}</div>
      );
    };

    const showViewActions =
      _.isEqual(userType, "staff") && this.props.showViewActions;

    const imageClass = classNames({
      [classes.imageContainer]: true,
      [classes.imageContainerRevamp]: isResourceViewRevamp,
    });

    const cardContainerClass = classNames({
      [classes.cardContainer]: true,
      [classes.cardContainerRevamp]: isResourceViewRevamp,
    });

    const topContainerClass = classNames({
      [classes.topSection]: true,
      [classes.topSectionRevamp]: isResourceViewRevamp,
    });

    const containerClass = classNames({
      [classes.container]: true,
      [classes.containerRevamp]: isResourceViewRevamp,
    });

    const rightContainerClass = classNames({
      [classes.rightContainer]: true,
      [classes.rightContainerRevamp]: !isCommunity && isResourceViewRevamp,
    });

    return (
      <div className={containerClass}>
        <MetaTags image={rethumpUrl} title={title} />
        <div className={classes.leftContainer}>
          <div
            className={imageClass}
            onClick={toggleMediaModal}
            style={{
              backgroundImage: rethumpUrl,
            }}
          ></div>
          {userInfoCard("mobile")}
        </div>
        <div className={rightContainerClass}>
          {isResourceViewRevamp && (
            <div className={classes.unitTitle}>{`Unit: ${unitPlanTitle}`}</div>
          )}
          {titleComp("mobile")}
          <div className={topContainerClass}>
            <div className={cardContainerClass}>
              {userInfoCard("desktop")}

              {isCommunity ? (
                <ViewCommunityActions
                  id={parentId}
                  t={t}
                  entityRatings={entityRatings}
                  savedBy={savedBy}
                  importAssessment={importAssessment}
                  parentId={parentId}
                  title={title}
                  isCurriculumProgramFree={isCurriculumProgramFree}
                  likes={likes}
                  userId={userId}
                  showDropdown={showDropdown}
                  onActionDropDownClick={this.onActionDropDownClick}
                  updateCoachMarkStatus={updateCoachMarkStatus}
                  userLoggedIn={userLoggedIn}
                  getUnitPlanDetails={getUnitPlanDetails}
                  isUserAuthenticatedForAction={isUserAuthenticatedForAction}
                />
              ) : (
                showViewActions && (
                  <ViewActions
                    t={t}
                    onClickPrint={onClickPrint}
                    isEditPermission={isEditPermission}
                    centralRepoConfig={centralRepoConfig}
                    onClickEdit={onClickEdit}
                    parentId={parentId}
                    parentType={parentType}
                    onCommentClick={onCommentClick}
                    totalCommentCount={totalCommentCount}
                    unreadCommentCount={unreadCommentCount}
                    isCommentActive={isCommentActive}
                    paritaStarStatus={paritaStarStatus}
                    kristenStarStatus={kristenStarStatus}
                    juryStarStatus={juryStarStatus}
                    paritaProcessed={paritaProcessed}
                    kristenProcessed={kristenProcessed}
                    juryProcessed={juryProcessed}
                    submittedToCommunity={submittedToCommunity}
                    curriculumType={curriculumType}
                    isDemo={isDemo}
                    cloneAssessment={cloneAssessment}
                    courseId={courseId}
                    course={course}
                    title={title}
                    imageUrl={imageUrl}
                    disableAssignToStudents={disableAssignToStudents}
                    getIsCurrentAcademicYear={getIsCurrentAcademicYear}
                    courseAcademicYearsIds={courseAcademicYearsIds}
                    isResourceViewRevamp={isResourceViewRevamp}
                  />
                )
              )}
            </div>

            {titleComp("desktop")}
          </div>

          {isCommunity ? (
            <CommunityActions
              id={parentId}
              entityRatings={entityRatings}
              savedBy={savedBy}
              likes={likes}
              title={title}
              communityComments={communityComments}
              scrollToCommentSection={scrollToCommentSection}
            />
          ) : null}

          {/* <div className={classes.bottomContainer}>
            <div className={classes.subTitleText}>
              {isCommunity
                ? gradeComp
                : `${t("common:by_with_label", {
                    label: `${firstName} ${lastName}`
                  })}`}
            </div>
          </div> */}
        </div>
        {showUnpublishModal && (
          <DialogueBox
            size="auto"
            showModal={true}
            modalTitle={t("common:unpublish_entity", {
              label: t("community:learning_experience"),
            })}
            modalBody={t("community:unpublish_le_from_community", {
              label: title || t("untitled"),
            })}
            button1={t("common:agree_cancel")}
            toggleDialogueBoxDisplay={() =>
              this.setState({ showUnpublishModal: false })
            }
            button2={t("common:action_agree", {
              action: t("common:unpublish"),
            })}
            onClickButton2={this.unpublishLE}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const courseId = state.teacher.selected_class.selected_course;
  const disableAssignToStudents = !_.get(ownProps, "params.course_id", null);
  const courseDetails = getCourseDetailsFromCache({
    id: courseId,
  });

  const { params: { assessmentId = null } = {} } = ownProps;
  const assessmentDetails = getAssessmentDetailsFromCache(assessmentId);
  const { isImported = false } = assessmentDetails;

  const organizationId = state.login.userInfo.org_id;
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const organizationTags = _.get(organizationDetails, "tags", []);
  const isEducator = isMapleBearLikeEducator({ organizationTags });
  const shouldDisableActionsForEducator = isImported && isEducator;
  const userTags = _.get(state, "login.userInfo.tags", []);
  const isGlobalOrCountryUser = Boolean(
    isGlobalUser({ userTags }) || findCountryUser({ userTags })
  );
  const centralRepoConfig = {
    shouldDisableEdit: shouldDisableActionsForEducator,
    shouldDisableDelete: shouldDisableActionsForEducator,
    isGlobalOrCountryUser: isGlobalOrCountryUser,
  };

  const courseAcademicYearsIds = _.map(
    _.get(courseDetails, "academicYears", []),
    item => item.id
  );

  return {
    centralRepoConfig,
    course: courseDetails,
    courseId,
    courseAcademicYearsIds,
    disableAssignToStudents,
    userId: _.get(state, "login.userInfo.id"),
    userLoggedIn: _.get(state, "login.userLoggedIn"),
  };
};

const mapActionCreators = {
  importAssessment,
  unpublishEntity,
  goBack,
  updateCoachMarkStatus,
  isUserAuthenticatedForAction,
  cloneAssessment,
  getIsCurrentAcademicYear,
  getUnitPlanDetails,
};

ViewHeader.defaultProps = {
  showViewActions: true,
};

export default compose(
  withRouter,
  I18nHOC({ resource: ["common", "community", "classRoom"] }),
  connect(mapStateToProps, mapActionCreators)
)(ViewHeader);
