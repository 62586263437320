import PropTypes from "prop-types";
import React from "react";

const DeleteIcon = props => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={props.width}
      height={props.height}
      {...props}
    >
      <g fill={props.fill && props.fill}>
        <path d="M12.86587,4.29744 C12.5518782,4.29959302 12.297868,4.55360322 12.295715,4.867595 L12.295715,14.025725 C12.2906348,14.4762565 11.9266465,14.8402448 11.476115,14.845325 L4.5095,14.845325 C4.0589685,14.8402448 3.69498024,14.4762565 3.6899,14.025725 L3.6899,4.84978 C3.6899,4.53489209 3.43463291,4.279625 3.119745,4.279625 C2.80485709,4.279625 2.54959,4.53489209 2.54959,4.84978 L2.54959,14.007905 C2.55209446,15.0794632 3.42014682,15.9475066 4.491705,15.95 L11.458295,15.95 C12.5298442,15.9474928 13.3978828,15.0794542 13.40039,14.007905 L13.40039,4.84978 C13.4067722,4.70452975 13.353108,4.56305251 13.2520007,4.45857442 C13.1508933,4.35409632 13.0112514,4.29582299 12.86587,4.29744 Z" />
        <path d="M6.867855,13.081405 L6.867855,5.77628 C6.87596053,5.47189667 6.63762747,5.21766508 6.333355,5.206125 C6.18615209,5.20872836 6.04627131,5.27080541 5.94557948,5.37821411 C5.84488764,5.48562281 5.79196028,5.6292156 5.798855,5.77628 L5.798855,13.081405 C5.79074947,13.3857883 6.02908253,13.6400199 6.333355,13.65156 C6.48055791,13.6489566 6.62043869,13.5868796 6.72113052,13.4794709 C6.82182236,13.3720622 6.87474972,13.2284694 6.867855,13.081405 Z" />
        <path d="M10.20119,13.081405 L10.20119,5.77628 C10.2092955,5.47189667 9.97096247,5.21766508 9.66669,5.206125 C9.51948709,5.20872836 9.37960631,5.27080541 9.27891448,5.37821411 C9.17822264,5.48562281 9.12529528,5.6292156 9.13219,5.77628 L9.13219,13.081405 C9.12408447,13.3857883 9.36241753,13.6400199 9.66669,13.65156 C9.81389291,13.6489566 9.95377369,13.5868796 10.0544655,13.4794709 C10.1551574,13.3720622 10.2080847,13.2284694 10.20119,13.081405 Z" />
        <path d="M14.158275,3.19712 L15.194245,3.19712 C15.5117217,3.19619185 15.7688569,2.93905672 15.769785,2.62158 C15.7416375,2.29374048 15.4657207,2.04290742 15.13669,2.04604 L11.366905,2.04604 L11.366905,1.096405 C11.3670337,0.806344692 11.2518647,0.528127527 11.0467611,0.323023896 C10.8416575,0.117920266 10.5634403,0.00275126684 10.27338,0.00288 L5.669065,0.00288 C5.37900469,0.00275126684 5.10078753,0.117920266 4.8956839,0.323023896 C4.69058027,0.528127527 4.57541127,0.806344692 4.57554,1.096405 L4.57554,2.046045 L0.805755,2.046045 C0.600134358,2.04604499 0.410133047,2.1557423 0.307322725,2.333815 C0.204512402,2.51188769 0.204512402,2.73128231 0.307322725,2.909355 C0.410133047,3.0874277 0.600134358,3.19712501 0.805755,3.197125 L1.841725,3.197125 L14.158275,3.19712 Z M5.726615,1.15396 L10.215825,1.15396 L10.215825,2.046045 L5.72662,2.046045 L5.726615,1.15396 Z" />
      </g>
    </svg>
  );
};

DeleteIcon.defaultProps = {
  height: 16,
  width: 16,
};

DeleteIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default DeleteIcon;
