import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import GuidanceRoute from "Projects/routes/Guidance";
import { routes } from "Projects/config/routes";
import EstablishDeadlines from "Projects/routes/Setup/routes/EstablishDeadlines";
import Invite from "Projects/routes/Setup/routes/Invite";
import GuidingQuestions from "Projects/routes/Setup/routes/GuidingQuestions";
import InitiateProject from "Projects/routes/Setup/routes/InitiateProject";

import ReportTemplate from "Projects/routes/Setup/routes/ReportTemplate";
import Introduction from "Projects/routes/Setup/routes/Introduction";
const {
  setup: { path },
} = routes;

const SetupRoute = store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./Setup").default;
              // const reducer = require("Teacher/routes/Profile/modules/ProfileModule")
              //   .default;
              // injectReducer(store, { key: "platformProfile", reducer });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [
      EstablishDeadlines(store),
      ReportTemplate(store),
      Invite(store),
      GuidanceRoute(store),
      Introduction(store),
      GuidingQuestions(store),
      InitiateProject(store),
    ],
  };
};

export default SetupRoute;
