import React from "react";
import classes from "./ShareModal.scss";
import { UIModal, UIButton } from "UIComponents";
import { CancelIcon } from "SvgComponents";
import { connect } from "react-redux";
import { FacebookButton, TwitterButton } from "AppComponents";
class ShareModal extends React.PureComponent {
  render() {
    const { onClose } = this.props;
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={onClose}
      >
        <div className={classes.headerContainer}>
          <div className={classes.headerText}>{`Awesome!!`}</div>
          <div className={classes.closeIcon} onClick={onClose}>
            <CancelIcon width={16} height={16}></CancelIcon>
          </div>
        </div>
        <div
          className={classes.detailText}
        >{`We are excited to have you as a part of the Planathon. We’ll keep you posted about the Planathon updates.`}</div>
        <div className={classes.border} />
        <div
          className={classes.detailText}
        >{`Help us spread the word so that we can together build the ultimate library of PYP inspirations.`}</div>
        <div className={classes.buttonContainer}>
          <FacebookButton />
          <TwitterButton />
        </div>
      </UIModal>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapActionCreators)(ShareModal);
