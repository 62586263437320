import gql from "graphql-tag";
import { courseAttendanceFragment } from "./AttendanceFragments";

export const getCourseAttendanceQuery = gql`
  query getCourseAttendance(
    $id: ID!
    $startDate: String!
    $endDate: String!
    $searchText: String
    $courseStartDate: String!
    $courseEndDate: String!
    $periodIds: [ID!]
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseAttendanceItem
      }
    }
  }
  ${courseAttendanceFragment.courseAttendance}
`;

export const getCourseSingleDateAttendanceQuery = gql`
  query getCourseSingleDateAttendance(
    $id: ID!
    $date: String!
    $showDeletedRemark: Boolean
    $periodIds: [ID!]
    $courseIds: [ID!]
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseAttendanceItem
      }
    }
  }
  ${courseAttendanceFragment.courseSingleDateAttendance}
`;

export const getCoursePeriodListQuery = gql`
  query getCoursePeriodList($id: ID!, $filters: PeriodFilterOptions) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...coursePeriodListItem
      }
    }
  }
  ${courseAttendanceFragment.coursePeriodList}
`;

export const getCoursePeriodListQueryV2 = gql`
  query getCoursePeriodListV2($id: ID!, $filters: PeriodFilterOptions) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...coursePeriodListItemV2
      }
    }
  }
  ${courseAttendanceFragment.coursePeriodListV2}
`;
