import React from "react";
import classes from "./Levels.scss";
import TopHeader from "./TopHeader";
import { connect } from "react-redux";
import { getStandardBenchmarkSetQuery } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorQueries";
import {
  getPlannerElementNodesOfSetQuery,
  getBenchmarkSetQuery,
  getOrganizationGradeBasicDetailsQuery,
  getSubjectBenchmarksQuery,
  getATLsQuery,
} from "modules/CommonQuery";
import {
  getPlannerElementNodesOfSetFromCache,
  getOrganizationPypElementSetFromCache,
  getOrganizationBasicGradeDetailsFromCache,
  getSubjectBenchmarksFromCache,
  getATLsFromCache,
} from "modules/CommonGraphqlHelpers";
import { getBenchmarkSetDetailsFromCache } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorGraphqlHelpers";

import { goToBack } from "modules/NavigationModule";
import {
  withLoader,
  Loading,
  I18nHOC,
  LockedScreenWrapper,
} from "UIComponents";
import { graphql, compose } from "react-apollo";
import LevelEditor from "./LevelEditor";
import NodeTreeEditor from "./NodeTreeEditor";
import ACLStore from "lib/aclStore";
import { isFeatureLocked } from "modules/PermissionModule";
import { getPYPElementSetFromNodes } from "Utils";
import {
  getLevelsProps,
  getCombinedNodes,
  addGradesToNodes,
  getTabsMemoize,
  getUpdatedRootNodeMemoize,
  mergeSelectedNodesMemoize,
  orderBySequenceNodesMemoize,
  updateSelectedNodesTypeMemoize,
  getStartDepth,
  getShowOutlineStatus,
} from "../Utils";
// TODO: Named imports( putting in {} ) is causing withQueryParamsBasedInterface undefined
import withQueryParamsBasedInterface from "UIComponents/QueryParamsBasedInterfaceHOC";
import { goToRelativeRoute } from "modules/Services";
import NodeEditor from "NodeEditor";
import {
  updateSubjectItem,
  updateSnsSettingsAction,
  resetNodeEditorState,
} from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule";
import {
  ELEMENT_TYPE_MYP_LEARNING_STANDARD,
  ELEMENT_TYPE_UBD_LEARNING_STANDARD,
  ELEMENT_TYPE_DP_SUBJECT_STANDARD,
  ELEMENT_TYPE_DP_SYLLABUS,
  ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK,
} from "Constants/stringConstants";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import { withRouter } from "react-router";
import classNames from "classnames";
import _ from "lodash";

class Levels extends React.PureComponent {
  constructor(props) {
    super(props);
    const { nodeType, rootNode } = props;
    const rootGrades = _.get(rootNode, "grades", []);
    this.state = {
      levelId: "",
      mode: "view",
      gradeFilters: rootGrades,
      showDetails: false,
      showLockScreen: false,
    };
  }

  componentDidMount = () => {
    // const { loginActiveTab } = this.props;
    const {
      isSetPreview,
      nodes,
      rootNode,
      showViewModeOnly = false,
      loginActiveTab,
    } = this.props;

    let mode = "view";
    if (loginActiveTab == "school-onboarding") {
      mode = showViewModeOnly ? "view" : mode;
    }
    this.onChangeMode(mode);
    if (!isSetPreview) {
      this.props.updateSubjectItem({ ...rootNode, id: rootNode.subject });
      this.props.updateSnsSettingsAction("SNS_DETAIL");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { nodes, loginActiveTab, showViewModeOnly } = this.props;
    let mode = prevState.mode;
    if (["school-onboarding", "teacher"].includes(loginActiveTab)) {
      mode = showViewModeOnly ? "view" : mode;
    }
    if (prevState.mode != mode) {
      this.onChangeMode(mode);
    }
  };

  onTabChange = item => {
    this.setState({ levelId: item.value });
  };

  updateGradeFilters = filters => {
    const { organizationGrades } = this.props;
    const gradeFilters = [];
    const filterValues = filters["gradeFilters"];

    _.forEach(organizationGrades, item => {
      if (_.includes(filterValues, item.id)) {
        gradeFilters.push(item);
      }
    });
    this.setState({ gradeFilters: gradeFilters });
  };

  onBackConfirmClick = () => {
    const { onBackClick, goToBack } = this.props;

    if (onBackClick) {
      onBackClick();
    } else {
      goToBack();
      //goToRelativeRoute({ route: "../", type: "replace" });
    }
  };

  onBackClick = () => {
    const { mode } = this.state;
    const {
      loginActiveTab,
      goToRelativeRoute,
      isSetPreview,
      onBackClick,
    } = this.props;
    if (loginActiveTab == "school-onboarding") {
      if (isSetPreview) {
        onBackClick();
      } else {
        goToRelativeRoute({ route: "../" });
      }
    } else if (this.isShowNodeTree() && mode == "edit") {
      if (this.nodeTreeEditor) {
        this.nodeTreeEditor.onActionClick({ action: "DISCARD" });
      }
    } else {
      this.onBackConfirmClick();
    }
  };

  onConceptualUnderStandingCheckBoxClick = () => {
    if (this.nodeTreeEditor) {
      this.nodeTreeEditor.onConceptualUnderStandingCheckBoxClick();
    }
  };

  onChangeMode = mode => {
    const { tabs = [] } = this.props;
    const isLocked = isFeatureLocked("SNS_EDITOR");
    if (mode == "edit" && isLocked) {
      this.setState({ showLockScreen: true });
    } else {
      this.setState({ mode: mode });
      let firstTab = "";
      this.setState({ showDetails: false });
      if (mode == "edit") {
        firstTab = tabs[0];
      } else if (mode == "view") {
        if (tabs.length == 1) {
          firstTab = tabs[0];
        } else {
          firstTab = tabs[1];
        }
      }

      this.setState({ showDetails: true });
      if (firstTab) this.onTabChange(firstTab);
    }
  };

  isShowNodeTree = () => {
    const { isCurriculumProgramFree } = this.props;
    const { mode } = this.state;
    return isCurriculumProgramFree || mode == "view";
  };

  onSubmitClick = async ({ valuesToAdd, valuesToRemove, shouldCloseModal }) => {
    const { onSubmitClick, isTagging, nodes, rootNodes } = this.props;
    if (isTagging) {
      const taggedIds = this.getTaggedIds();
      onSubmitClick &&
        onSubmitClick({
          selections: taggedIds,
          nodes,
          rootNodes,
          valuesToAdd,
          valuesToRemove,
          shouldCloseModal,
        });
    } else {
      if (this.nodeTreeEditor) {
        this.nodeTreeEditor.onActionClick({ action: "SAVE" });
      }
    }
  };

  onSubmitClickV2 = async ({
    valuesToAdd,
    valuesToRemove,
    shouldCloseModal,
    taggedIds = [],
    nodes,
  }) => {
    const {
      onSubmitClick,
      isTagging,
      rootNodes,
      nodes: levelsNodes,
      nodeType,
    } = this.props;
    if (isTagging) {
      onSubmitClick &&
        onSubmitClick({
          selections: taggedIds,
          nodes: nodeType == "BENCHMARK" ? levelsNodes : nodes, // TEMPORARY: SOON TO CHANGE FOR BENCHMARK
          rootNodes,
          valuesToAdd,
          valuesToRemove,
          shouldCloseModal,
        });
    }
  };

  canRenderNewNodeEditor = () => {
    const {
      nodeType,
      openedFrom,
      isTagging,
      isNewNodeEditor,
      isNewPlannerElementEditor,
    } = this.props;
    const isNewBenchmark = isNewNodeEditor && nodeType === "BENCHMARK";
    const renderNewEditor = _.includes(
      ["UNIT_PLAN", "PROGRESS_REPORT"],
      openedFrom
    );

    return (
      (isNewPlannerElementEditor || isNewBenchmark) &&
      (!isTagging || renderNewEditor)
    );
  };

  getTaggedIds = () => {
    if (this.nodeTreeEditor) {
      return this.nodeTreeEditor.getTaggedIds();
    }
  };

  updateNodeTreeRef = ref => {
    this.nodeTreeEditor = ref;
    if (this.props.customRef) {
      this.props.customRef(ref);
    }
  };

  getDescriptionText = () => {
    const { rootNode, nodeType, t } = this.props;

    const title = _.get(rootNode, "standardSet.title", "");
    const description = _.get(rootNode, "standardSet.description", "");
    return nodeType == "BENCHMARK"
      ? _.get(rootNode, "standardSet", null)
        ? `${title ? `${title} ` : ``}${description ? `(${description})` : ``}`
        : t("common:custom")
      : "";
  };

  onCloseLockScreenModal = () => {
    this.setState({ showLockScreen: false });
  };
  render() {
    const {
      tabs,
      nodes,
      levels,
      rootNode,
      organizationGrades,
      nodeSet,
      loginActiveTab,
      nodeType,
      label,
      isTagging,
      value,
      updateValueLocally,
      editRootNode,
      isAdmin,
      onClickChoose,
      isSetPreview,
      hideHeader,
      theme,
      uptoDepth,
      showSelectedCount,
      startDepth,
      showOutline,
      showSearchHeader,
      multiSelectConfig,
      nodeRenderType,
      showInsights,
      insightsData,
      nodeParentType,
      showEditButton = true,
      plannerElementSetConfig,
      shouldSaveOnUpdate, //if this flag is true then changes will save on real time (i.e. mutation will call on onCheck)
      showGrade, // if this flag is false than grade filter and grade column will not be displayed
      showBackButton,
      enableBreadCrumbs,
      isNewNodeEditor,
      organizationId,
      grades,
      onBackClick,
      setPreviewId,
      selectedTaggedIds = [],
      openedFrom,
      customRef,
      showViewModeOnly,
      selectedNodes,
      customOnSettingsItemClick,
      addRootNodeTagging,
      isEditButtonDisabled,
      disableToolTipMsg,
      disableEditButton,
      tags,
      dummyRootNode,
      nodeEditorConfigProp,
      resolvedMinimalTreeNodes,
    } = this.props;
    const {
      levelId,
      mode,
      gradeFilters,
      showDetails,
      showLockScreen,
    } = this.state;

    const rootGrades = _.get(rootNode, "grades", []);
    const showNodeTree = this.isShowNodeTree();
    const shouldRenderNewNodeEditor = this.canRenderNewNodeEditor();

    const innerContainerStyle = classNames({
      [classes.innerContainer]: true,
      [classes.innerContainerNodeEditor]: shouldRenderNewNodeEditor,
    });

    return (
      <div className={innerContainerStyle}>
        {!hideHeader && !shouldRenderNewNodeEditor && (
          <TopHeader
            title={label}
            showEditButton={showEditButton}
            disableEditButton={disableEditButton}
            onClickChoose={onClickChoose}
            isSetPreview={isSetPreview}
            subTitle={_.get(rootNode, "label", "")}
            onBackClick={this.onBackClick}
            showBackButton={showBackButton}
            changeTab={this.onTabChange}
            currentTab={levelId}
            tabs={tabs}
            onChangeMode={this.onChangeMode}
            mode={mode}
            grades={rootGrades}
            gradeFilters={gradeFilters}
            updateGradeFilters={this.updateGradeFilters}
            loginActiveTab={loginActiveTab}
            onSaveClick={this.onSubmitClick}
            // onSaveClick={this.onBackClick}
            isTagging={isTagging}
            showNodeTree={showNodeTree}
            enableBreadCrumbs={enableBreadCrumbs}
            description={this.getDescriptionText()}
            editRootNode={editRootNode}
            onConceptualUnderStandingCheckBoxClick={
              this.onConceptualUnderStandingCheckBoxClick
            }
            isAdmin={isAdmin}
            showDetails={showDetails}
          />
        )}
        {showLockScreen && (
          <LockedScreenWrapper
            featureKey={`SNS_EDITOR`}
            screenType={"modal"}
            onClose={this.onCloseLockScreenModal}
          />
        )}
        {showDetails ? (
          showNodeTree ? (
            shouldRenderNewNodeEditor ? (
              <NodeEditor
                description={this.getDescriptionText()}
                organizationId={organizationId}
                subject={_.get(rootNode, "subject")} // subject id required
                nodeType={nodeType}
                isSetPreview={isSetPreview}
                onBackClick={onBackClick}
                setPreviewId={setPreviewId}
                onClickChoose={onClickChoose}
                showEditButton={showEditButton}
                loginActiveTab={loginActiveTab}
                openedFrom={openedFrom}
                grades={grades} // grades coming from the parent component (like BenchmarksModal in UnitPlan gives the grade the unit is in)
                selectedTaggedIds={selectedTaggedIds}
                updateValueLocally={updateValueLocally}
                onSubmitClick={this.onSubmitClickV2}
                value={value}
                customRef={customRef}
                showViewModeOnly={showViewModeOnly}
                uptoDepth={uptoDepth}
                selectedNodes={selectedNodes}
                customOnSettingsItemClick={customOnSettingsItemClick} // on settings item click for planner elements
                addRootNodeTagging={addRootNodeTagging} // true for planner elements to add subject node to nodes array for editor
                isEditButtonDisabled={disableEditButton}
                disableToolTipMsg={disableToolTipMsg}
                tags={tags}
                dummyRootNode={dummyRootNode}
                nodeEditorConfigProp={nodeEditorConfigProp}
                plannerElementSetConfig={plannerElementSetConfig}
                resolvedMinimalTreeNodes={resolvedMinimalTreeNodes}
              />
            ) : (
              <NodeTreeEditor
                updateRef={this.updateNodeTreeRef}
                isTagging={isTagging}
                showInsights={showInsights}
                insightsData={insightsData}
                nodes={nodes}
                showOutline={showOutline}
                startDepth={startDepth}
                nodeType={nodeType}
                mode={mode}
                gradeFilters={gradeFilters}
                updateGradeFilters={this.updateGradeFilters}
                rootOptionsGrades={rootGrades}
                value={value}
                updateValueLocally={updateValueLocally}
                rootNode={rootNode}
                onChangeMode={this.onChangeMode}
                onBackConfirmClick={this.onBackConfirmClick}
                theme={theme || {}}
                uptoDepth={uptoDepth}
                nodeParentType={nodeParentType} //TODO: remove,we may not need this as we're already transforming associatedParent as node.grades for each node
                showSearchHeader={showSearchHeader}
                showSelectedCount={showSelectedCount}
                multiSelectConfig={multiSelectConfig}
                nodeRenderType={nodeRenderType}
                onSubmitClick={this.onSubmitClick}
                shouldSaveOnUpdate={shouldSaveOnUpdate}
                showGrade={showGrade}
                plannerElementSetConfig={plannerElementSetConfig}
              />
            )
          ) : (
            <LevelEditor
              nodes={
                nodeParentType === "PLANNER_ELEMENT"
                  ? getCombinedNodes({ nodes, subject: rootNode })
                  : nodes
              }
              levelId={levelId}
              levels={levels}
              rootNode={rootNode}
              grades={organizationGrades}
              gradeFilters={gradeFilters}
              nodeSet={nodeSet}
              mode={mode}
              nodeParentType={nodeParentType}
              nodeType={nodeType}
              routeLabel={label}
              plannerElementSetConfig={plannerElementSetConfig}
              showGrade={showGrade}
            />
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  goToBack,
  updateSubjectItem,
  updateSnsSettingsAction,
  goToRelativeRoute,
  resetNodeEditorState,
};

const mapStateToProps = (state, ownProps) => {
  const {
    nodeSet,
    params,
    organizationGrades,
    nodeType,
    rootNodes,
    rootNode: currentRootNode,
    subjectId,
    useBenchmarkSetQuery,
    rootNodeId,
    setPreviewId,
    depthLabels,
    cardType,
    resolvedMinimalTreeNodes,
  } = ownProps;
  const { nodeId: paramNodeId } = params || {};
  const nodeId = paramNodeId || _.get(currentRootNode, "id", "") || rootNodeId;

  const rootNode = currentRootNode || {
    ..._.find(rootNodes, item => item.rootNodeId == nodeId),
    id: nodeId,
  };

  const rootLevels =
    cardType == "ATLS" ? depthLabels : _.get(rootNode, "levels", []);
  const levels = _.slice(rootLevels, 1, rootLevels.length);
  let nodes = [];

  /**
   * initial value for startDepth must be null, as this is required in NodeTreeEditor
   */
  let startDepth = null,
    showOutline;

  const shouldUseResolvedMinimalTreeNodes = !_.isEmpty(
    resolvedMinimalTreeNodes
  );

  if (nodeType == "ATL" && useBenchmarkSetQuery) {
    nodes = _.filter(
      nodeSet.nodes,
      ({ atlCategory }) => atlCategory.id == nodeId
    );
  }

  if (shouldUseResolvedMinimalTreeNodes) {
    nodes = addGradesToNodes({ nodes: resolvedMinimalTreeNodes });
    startDepth = getStartDepth({ nodes, rootNode });
    showOutline = getShowOutlineStatus({ nodes, startDepth });
  }

  //added myp learning standard and ubd learning stadnard here
  const isNewPlannerElementEditor =
    (ACLStore.can("FeatureFlag:NewNodeEditorPlannerElement") &&
      _.includes(
        [
          ELEMENT_TYPE_MYP_LEARNING_STANDARD,
          ELEMENT_TYPE_UBD_LEARNING_STANDARD,
        ],
        nodeType
      )) ||
    _.includes(
      [
        ELEMENT_TYPE_DP_SYLLABUS,
        ELEMENT_TYPE_DP_SUBJECT_STANDARD,
        ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK,
      ],
      nodeType
    ); // we need to use new node editor in DP without any permission

  const curriculumProgramId = _.get(ownProps, "params.curriculumProgramId", "");
  const tabConfigurations = { admin: { curriculumProgramId } };

  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });

  return {
    isData: true,
    isLoading: false,
    organizationId: state.login.userInfo.org_id,
    nodeId: nodeId,
    editRootNode: _.get(state, "multiLevelNodeEditor.rootNode", {}),
    // Re-evaluate rootNode only when organizationGrades are provided in props
    rootNode: !_.isUndefined(organizationGrades)
      ? getUpdatedRootNodeMemoize({
          rootNode,
          organizationGrades,
          subjectId,
        })
      : rootNode,
    isAdmin: ACLStore.can("AdminPortal"),
    tabs: getTabsMemoize(levels),
    nodes: orderBySequenceNodesMemoize(nodes),
    levels: levels,
    nodeSet: nodeSet,
    isCurriculumProgramFree,
    loginActiveTab: state.login.activeTab,
    isNewNodeEditor: ACLStore.can("FeatureFlag:NewNodeEditorBenchmark"),
    isNewPlannerElementEditor,
    setPreviewId,
    startDepth,
    showOutline,
    shouldUseResolvedMinimalTreeNodes,
  };
};

const LevelsWrapper = compose(
  I18nHOC({ resource: ["common"] }),
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationGradeBasicDetailsQuery, {
    name: "getOrganizationGrades",
    skip: ({ organizationGrades, nodeType }) =>
      _.get(organizationGrades, "length", 0) > 0 ||
      _.includes(
        [ELEMENT_TYPE_DP_SYLLABUS, ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK],
        nodeType
      ),
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { organizationId },
    }),
    props({
      getOrganizationGrades,
      ownProps: { rootNode, isData, isLoading, organizationId, subjectId },
    }) {
      const queryData = getOrganizationBasicGradeDetailsFromCache({
        organizationId,
      });
      const organizationGrades = _.get(queryData, "grades", []);
      return {
        rootNode: getUpdatedRootNodeMemoize({
          rootNode,
          organizationGrades,
          subjectId,
        }),
        organizationGrades,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getOrganizationGrades["networkStatus"] == 1 ||
          getOrganizationGrades["networkStatus"] == 2 ||
          getOrganizationGrades["networkStatus"] == 4 ||
          isLoading,
      };
    },
  }),
  graphql(getATLsQuery, {
    name: "getALTs",
    skip: ({ nodeId, useBenchmarkSetQuery, nodeType }) =>
      !nodeId || nodeType !== "ATL" || useBenchmarkSetQuery,
    options: ({ nodeId, grades }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: nodeId,
        grades,
      },
    }),
    props({
      getALTs,
      ownProps: { isData, isLoading, grades, nodeId, selectedNodes },
    }) {
      const queryData = getATLsFromCache({
        id: nodeId,
        grades,
      });

      const mergedNodes = mergeSelectedNodesMemoize({
        nodes: _.get(queryData, "atls", []),
        selectedNodes: selectedNodes,
      });

      return {
        nodes: mergedNodes,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getALTs["networkStatus"] == 1 ||
          getALTs["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getSubjectBenchmarksQuery, {
    name: "getSubjectBenchmarks",
    skip: ({
      nodeType,
      isSetPreview,
      rootNode: { subject },
      useBenchmarkSetQuery,
    }) =>
      nodeType != "BENCHMARK" ||
      isSetPreview ||
      !subject ||
      useBenchmarkSetQuery,
    options: ({ rootNode: { subject } = {}, grades, isNewNodeEditor }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        subject,
        ...(!isNewNodeEditor && { grades }),
      },
    }),
    props({
      getSubjectBenchmarks,
      ownProps: {
        isData,
        isLoading,
        rootNode,
        grades,
        nodeId,
        uptoDepth,
        selectedNodes,
        isNewNodeEditor,
      },
    }) {
      const queryData = getSubjectBenchmarksFromCache({
        subject: _.get(rootNode, "subject", ""),
        ...(!isNewNodeEditor && { grades }),
      });
      const benchmarks = mergeSelectedNodesMemoize({
        nodes: _.get(queryData, "benchmarks", []),
        selectedNodes: updateSelectedNodesTypeMemoize({
          rootNode,
          selectedNodes,
        }),
      });
      const nodeSet = getPYPElementSetFromNodes({
        nodes: benchmarks,
      });
      const rootNodes = _.get(nodeSet, "rootNodes", []);
      const nodes = _.get(nodeSet, "nodes", []);

      return {
        nodeSet,
        rootNodes,
        nodes: !uptoDepth
          ? nodes
          : _.filter(nodes, item => item.depth <= uptoDepth),
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getSubjectBenchmarks["networkStatus"] == 1 ||
          getSubjectBenchmarks["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),

  graphql(getBenchmarkSetQuery, {
    name: "getBenchmarkSet",
    skip: ({
      nodeType,
      isSetPreview,
      rootNode: { subject },
      useBenchmarkSetQuery,
      isNewNodeEditor,
    }) =>
      nodeType !== "BENCHMARK" ||
      isSetPreview ||
      !subject ||
      !useBenchmarkSetQuery ||
      isNewNodeEditor,
    options: ({ organizationId, rootNode: { subject } = {}, grades }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        subjects: subject,
        grades,
      },
    }),
    props({
      getBenchmarkSet,
      ownProps: {
        isData,
        isLoading,
        organizationId,
        nodeType,
        valueType,
        rootNode: { subject } = {},
        grades,
        nodeId,
        uptoDepth,
        isNewNodeEditor,
      },
    }) {
      const queryData = getOrganizationPypElementSetFromCache({
        organizationId,
        type: nodeType,
        subjects: subject,
        grades,
        isNewNodeEditor,
      });

      const nodeSet = _.get(queryData, `pypElement.${valueType}`, {});
      const rootNodes = _.get(nodeSet, "rootNodes", []);
      const nodes = _.get(nodeSet, "nodes", []);
      return {
        nodeSet,
        rootNodes,

        nodes: !uptoDepth
          ? nodes
          : _.filter(nodes, item => item.depth <= uptoDepth),
        isData:
          !_.isEmpty(queryData) &&
          isData &&
          (!nodeId || _.includes(rootNodes, nodeId)),
        isLoading:
          getBenchmarkSet["networkStatus"] == 1 ||
          getBenchmarkSet["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),

  graphql(getStandardBenchmarkSetQuery, {
    name: "getStandardBenchmarkSet",
    skip: ({ nodeType, isSetPreview, isNewNodeEditor }) =>
      nodeType != "BENCHMARK" || !isSetPreview || isNewNodeEditor,
    options: ({ organizationId, setPreviewId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        id: setPreviewId,
      },
    }),
    props({
      getStandardBenchmarkSet,
      ownProps: { isData, isLoading, organizationId, setPreviewId, rootNode },
    }) {
      const queryData = getBenchmarkSetDetailsFromCache({
        organizationId,
        id: setPreviewId,
      });

      const standardSet = _.omit(queryData, ["benchmarks"]);
      const nodeSet = _.get(queryData, `benchmarks`, {});

      return {
        rootNode: {
          ...rootNode,
          standardSet,
          snsChildren: _.get(
            standardSet,
            "snsChildren",
            _.get(rootNode, "snsChildren", [])
          ),
        },
        nodeSet,
        rootNodes: _.get(nodeSet, "rootNodes", []),
        nodes: _.get(nodeSet, "nodes", []),
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getStandardBenchmarkSet["networkStatus"] == 1 ||
          getStandardBenchmarkSet["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerElementNodesOfSetQuery, {
    name: "getPlannerElementNodesOfSet",
    skip: ({
      nodeParentType,
      isNewPlannerElementEditor,
      nodeType,
      shouldUseResolvedMinimalTreeNodes,
    }) =>
      nodeParentType != "PLANNER_ELEMENT" || //TODO: USE STRING consts preferably. CTRL + F to search we've already used it twice in this component and inside other components down the tree
      isNewPlannerElementEditor ||
      shouldUseResolvedMinimalTreeNodes,
    options: ({ plannerElementSetConfig: { id, filters } = {} }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id,
        filters,
      },
    }),
    props({
      getPlannerElementNodesOfSet,
      ownProps: {
        rootNode,
        isData,
        isLoading,
        plannerElementSetConfig: { id, filters } = {},
      },
    }) {
      const plannerElementSetData = getPlannerElementNodesOfSetFromCache({
        id,
        filters,
      });

      const plannerElementNodes = _.get(plannerElementSetData, "nodes", []);

      const nodes = addGradesToNodes({
        nodes: plannerElementNodes,
      });

      const startDepth = getStartDepth({
        nodes,
        rootNode,
      });

      const showOutline = getShowOutlineStatus({ nodes, startDepth });

      return {
        startDepth,
        showOutline,
        nodes,
        isData: !_.isEmpty(plannerElementSetData) && isData,
        isLoading:
          getPlannerElementNodesOfSet["networkStatus"] == 1 ||
          getPlannerElementNodesOfSet["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader,
  withQueryParamsBasedInterface({ cb: getLevelsProps })
)(Levels);

export default LevelsWrapper;

LevelsWrapper.defaultProps = {
  startDepth: null,
  showSearchHeader: true,
  shouldSaveOnUpdate: true,
  showGrade: true,
  nodeEditorConfigProp: {},
  resolvedMinimalTreeNodes: [],
};
