import { injectReducer } from "store/reducers";
import { getNextMenuRoute } from "Administrator/modules/AdministratorModule";
import { getRelativePath } from "Utils";
import { withAsyncRouteLoading } from "UIComponents";
import TurnItInSettings from "../Settings/routes/TurnItInSettings";

export default store => ({
  path: "integration-settings",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components/IntegrationSettings")
              .default;
            const reducer = require("../Settings/modules/SettingsModule")
              .default;
            injectReducer(store, { key: "settings", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-settings"
        );
      })
  ),
  childRoutes: [TurnItInSettings(store)],
});
