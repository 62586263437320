import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import classes from "./EventModal.scss";
import { graphql, compose } from "react-apollo";
import EventBody from "./components/EventBody";
import EventHeader from "./components/EventHeader";
import EventFooter from "./components/EventFooter";
import { setToastMsg } from "Login/modules/LoginModule";
import { deleteAssignment } from "ClassRoom/modules/ClassRoomModule";
import EditOrCreateEvent from "MyCalendar/components/EditOrCreateEvent";
import ActionForSelectModal from "MyCalendar/components/ActionForSelectModal";
import { getCalendarEventDetailsQuery } from "MyCalendar/modules/MyCalendarQuery";
import {
  deletePlatformCalendarEvent,
  updatePlatformCalendarEventBasicDetails,
} from "MyCalendar/modules/MyCalendarModule";
import { getCalendarEventDetailsFromCache } from "MyCalendar/modules/MyCalendarGraphqlHelpers";
import {
  withLoader,
  DialogueBox,
  FullScreenLoader,
  I18nHOC,
} from "UIComponents";
import {
  getStartEndTimeText,
  getEventMetadata,
  getCalendarEventsViewSettingValue,
} from "MyCalendar/modules/Utils";
import { GetMeetingJoinURL, formatDate } from "Utils";
import { goToRelativeRoute } from "modules/Services";
import { goToPlatformHomeChildRoute } from "modules/NavigationModule";
import ACLStore from "lib/aclStore";
import { getUserCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getUserCoursesVars } from "Course/modules/CourseModule";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";

const DIALOGS_INFO = {
  DELETE: {
    title: "calendar:delete_event",
    message: t => t("calendar:delete_event_msg"),
    confirmButtonText: t =>
      t("common:action_agree", { action: t("common:delete") }),
    cancelButtonText: "common:agree_cancel",
  },
};

const getAssignedUsers = ({ itemType, users }) => {
  switch (itemType) {
    case "ASSIGNMENT": {
      return _.reduce(
        users,
        (result, studentObj) => {
          const id = _.get(studentObj, "student.id", null);
          const profileImage = _.get(studentObj, "student.profileImage", null);
          const name = `${_.get(studentObj, "student.firstName", null)} ${_.get(
            studentObj,
            "student.lastName",
            null
          )}`;

          result.push({ id, profileImage, name });
          return result;
        },
        []
      );
    }
    case "CALENDAR_EVENT_ITEM": {
      return _.reduce(
        users,
        (result, studentObj) => {
          const id = _.get(studentObj, "id", null);
          const profileImage = _.get(studentObj, "profileImage", null);
          const name = `${_.get(studentObj, "firstName", null)} ${_.get(
            studentObj,
            "lastName",
            null
          )}`;

          result.push({ id, profileImage, name });
          return result;
        },
        []
      );
    }
  }
};

const getNormalizedEventDetails = ({
  eventData,
  userId,
  showCalendarAssignmentByDueDate,
}) => {
  let meetingLink = "";
  let assignedUsers = [];
  let courseId = null;

  const {
    id,
    startTime,
    endTime,
    calendars,
    itemType,
    item,
  } = _.pick(eventData, [
    "id",
    "startTime",
    "endTime",
    "calendars",
    "itemType",
    "item",
  ]);

  const {
    content,
    contentType,
    assignedStudents,
    createdBy,
    users,
    recurringSetting,
  } = _.pick(item, [
    "content",
    "contentType",
    "assignedStudents",
    "createdBy",
    "users",
    "recurringSetting",
  ]);

  switch (itemType) {
    case "ASSIGNMENT": {
      meetingLink = GetMeetingJoinURL({
        meeting: _.get(content, "meeting", null),
        userId,
      });
      assignedUsers = getAssignedUsers({
        itemType,
        users: assignedStudents,
      });
      courseId = _.get(
        _.find(calendars, { parentType: "COURSE" }),
        "parent.id",
        null
      );

      break;
    }
    case "CALENDAR_EVENT_ITEM": {
      meetingLink = GetMeetingJoinURL({
        meeting: _.get(item, "meeting", null),
        userId,
      });
      assignedUsers = getAssignedUsers({
        itemType,
        users,
      });
      break;
    }
  }
  const eventCreatorName = `${_.get(createdBy, "firstName", null)} ${_.get(
    createdBy,
    "lastName",
    null
  )}`;

  const publishedTime = getEventTimeText({
    startTime,
    endTime,
    showOnlyEndTime:
      itemType === "ASSIGNMENT" && showCalendarAssignmentByDueDate,
  });

  return {
    id,
    item,
    itemType,
    courseId,
    publishedTime,
    scheduleDate: moment(startTime).format("YYYY-MM-DD"),
    scheduleStartTime: moment(startTime).format("hh:mm a"),
    scheduleEndTime: moment(endTime).format("hh:mm a"),
    meetingLink,
    createdBy: {
      id: _.get(createdBy, "id", null),
      name: _.get(createdBy, "id", null) === userId ? "You" : eventCreatorName,
      profileImage: _.get(createdBy, "profileImage", null),
    },
    calendarWiseAssignedUsers: getCalendarWiseAssignedUsers({
      calendars,
      assignedUsers,
    }),
    contentType,
    assignmentId: _.get(item, "id"),
    recurringSetting,
    calendars,
  };
};

const getEventTimeText = ({ startTime, endTime, showOnlyEndTime }) => {
  const today = moment(startTime).format("dddd, MMM DD");

  const timeDuration = showOnlyEndTime
    ? formatDate({ date: endTime, format: "hh:mm a" })
    : getStartEndTimeText({
        startDate: startTime,
        endDate: endTime,
      });

  return `${today}, ${timeDuration}`;
};

const getCalendarWiseAssignedUsers = ({ calendars, assignedUsers }) => {
  return _.reduce(
    calendars,
    (result, calendarItem) => {
      const parent = _.get(calendarItem, "parent");
      const parentType = _.get(calendarItem, "parentType");

      if (parentType === "COURSE") {
        const totalUserCount = _.get(parent, "allStudents.totalCount");
        const allStudents = _.get(parent, "allStudents.edges");
        const assignedUsersFromThisCalendar = _.intersectionWith(
          assignedUsers,
          allStudents,
          (val1, val2) => _.get(val1, "id") === _.get(val2, "node.id")
        );
        const calendarTitle = _.get(parent, "title");

        const item = {
          id: _.get(calendarItem, "parent.id"),
          title: calendarTitle,
          totalUserCount: totalUserCount,
          assignedUsers: assignedUsersFromThisCalendar,
        };

        result.push(item);
      }
      return result;
    },
    []
  );
};

/////
///

////

///

////

///

///

const EventModal = React.memo(props => {
  const {
    eventId,
    eventData,
    onCloseEventModal,
    refetchEvents,
    deletePlatformCalendarEvent,
    userType,
    deleteAssignment,
    setToastMsg,
    userId,
    goToRelativeRoute,
    goToPlatformHomeChildRoute,
    accessedViaDailyView,
    t,
    calendarParentColorMap,
    updatePlatformCalendarEventBasicDetails,
    userCourses,
    showCalendarAssignmentByDueDate,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentDialogAction, setCurrentDialogAction] = useState("");
  const [currentDialogItem, setCurrentDialogItem] = useState({});
  const [isEditingEvent, setIsEditingEvent] = useState(false);
  const [editModeDetails, setEditModeDetails] = useState({});
  const eventDetails = getNormalizedEventDetails({
    eventData,
    userId,
    showCalendarAssignmentByDueDate,
  });

  const [showRecurringDeleteOptions, setShowRecurringDeleteOptions] = useState(
    false
  );
  const [eventIdForDeletion, setEventIdForDeletion] = useState("");
  const {
    id,
    item,
    itemType,
    contentType,
    courseId,
    publishedTime,
    scheduleDate,
    scheduleStartTime,
    scheduleEndTime,
    meetingLink,
    createdBy,
    assignmentId,
    recurringSetting,
    calendarWiseAssignedUsers,
    calendars,
  } = eventDetails;

  const [isQuickReschedule, setIsQuickReschedule] = useState(false);
  const [rescheduleData, updateRescheduleData] = useState({
    scheduleDate,
    scheduleStartTime,
    scheduleEndTime,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    updateRescheduleData({ scheduleDate, scheduleStartTime, scheduleEndTime });
  }, [scheduleDate, scheduleStartTime, scheduleEndTime]);

  const {
    title,
    description,
    backgroundColor,
    color,
    eventIcon,
  } = getEventMetadata({
    item,
    itemType,
    userType,
    calendars,
    calendarParentColorMap,
  });

  useEffect(() => {
    document.addEventListener("keydown", escapePress);
    return () => {
      document.removeEventListener("keydown", escapePress);
    };
  }, []);

  const escapePress = e => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code == 27) {
      e.stopPropagation();
      onCloseEventModal();
      onToggleDialogueClick();
    }
  };

  ///
  ///
  ///
  //

  ///

  const _refetchEvents = () => {
    if (refetchEvents) {
      refetchEvents();
    }
  };

  const onRecurringOptionsDelete = async option => {
    deletePlatformCalendarEvent({
      id: eventId,
      actionFor: option,
      refetchEvents: _refetchEvents,
      setShowRecurringDeleteOptions,
      onCloseEventModal,
    });
  };

  const onClickDelete = async () => {
    setCurrentDialogItem({ id: assignmentId });
    if (itemType === "CALENDAR_EVENT_ITEM") {
      if (recurringSetting) {
        setShowRecurringDeleteOptions(true);
      } else {
        setEventIdForDeletion(eventId);
        setCurrentDialogAction("DELETE");
      }
    } else {
      setCurrentDialogAction("DELETE");
    }
  };

  const onConfirmBtnClick = async () => {
    //reschedule event from quick view
    if (isQuickReschedule) {
      setIsLoading(true);
      const isSuccess = await updatePlatformCalendarEventBasicDetails({
        id,
        itemType,
        rescheduleData,
        setIsQuickReschedule,
      });
      if (isSuccess) {
        _refetchEvents();
        setToastMsg({
          msg: "toastMsgs:event_update_success",
          type: "success",
        });
      } else {
        setToastMsg("toastMsgs:something_went_wrong");
      }
      setIsLoading(false);
      return;
    }

    if (meetingLink) {
      window.open(meetingLink, "_blank");
    } else {
      if (accessedViaDailyView) {
        goToPlatformHomeChildRoute({
          route: `courses/classroom-details/${assignmentId}/`,
        });
      } else {
        goToRelativeRoute({
          route: `./classroom-details/${assignmentId}/`,
        });
      }
    }
  };

  const onToggleDialogueClick = () => {
    setCurrentDialogAction("");
    setCurrentDialogItem({});
  };

  const onDeleteDialogueClose = () => {
    setEventIdForDeletion("");
    setCurrentDialogAction("");
  };

  const onConfirmDeleteEventClick = () => {
    deletePlatformCalendarEvent({
      id: eventId,
      actionFor: "ALL",
      refetchEvents: _refetchEvents,
      setShowRecurringDeleteOptions,
      onCloseEventModal,
    });
  };

  const onDialogConfirmClick = async () => {
    const { id } = currentDialogItem;

    switch (currentDialogAction) {
      case "DELETE":
        setIsLoading(true);
        try {
          await deleteAssignment(id);
          setToastMsg({
            msg: "toastMsgs:action_successfully",
            locale_params: [
              {
                key: "label",
                value: "common:post",
                isPlainText: false,
              },
              { key: "action", value: "toastMsgs:delete", isPlainText: false },
            ],
            type: "success",
          });
        } catch (e) {
          if (e.networkError) {
            setToastMsg("toastMsgs:no_internet_connection");
          } else {
            setToastMsg("toastMsgs:something_went_wrong");
          }
        } finally {
          setIsLoading(false);
          onCloseEventModal();
          _refetchEvents();
        }
        break;
    }
  };

  const onClickEdit = () => {
    setIsEditingEvent(true);
    setEditModeDetails({
      id: assignmentId,
      eventId: id,
      contentType,
      itemType,
    });
  };

  const onRescheduleEventClick = param => {
    setIsQuickReschedule(param);
  };

  const rescheduleDataChangesLocal = params => {
    updateRescheduleData({ ...rescheduleData, ...params });
  };

  const courseIds = _.map(_.filter(calendars, { parentType: "COURSE" }), item =>
    _.get(item, "parent.id")
  );

  const isNotUserCourse = _.isEmpty(_.intersection(userCourses, courseIds));

  return (
    <>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <EventHeader
            userType={userType}
            hideEditButton={
              itemType === "ASSIGNMENT" && contentType === "ASSIGNMENT_MEETING"
            }
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            onCloseEventModal={onCloseEventModal}
            isNotUserCourse={isNotUserCourse}
          />
          <EventBody
            color={color}
            title={title}
            eventIcon={eventIcon}
            description={description}
            meetingLink={meetingLink}
            publishedTime={publishedTime}
            scheduleDate={scheduleDate}
            scheduleStartTime={scheduleStartTime}
            scheduleEndTime={scheduleEndTime}
            backgroundColor={backgroundColor}
            calendarWiseAssignedUsers={calendarWiseAssignedUsers}
            onRescheduleEventClick={onRescheduleEventClick}
            rescheduleDataChangesLocal={rescheduleDataChangesLocal}
            rescheduleData={rescheduleData}
            setFooterDisabled={setIsButtonDisabled}
            userType={userType}
            itemType={itemType}
            t={t}
            isNotUserCourse={isNotUserCourse}
            showCalendarAssignmentByDueDate={showCalendarAssignmentByDueDate}
          />
          <EventFooter
            createdBy={createdBy}
            onConfirmBtnClick={onConfirmBtnClick}
            isQuickReschedule={isQuickReschedule}
            itemType={itemType}
            isButtonDisabled={isButtonDisabled}
            t={t}
          />
        </div>
      </div>
      {isEditingEvent && (
        <EditOrCreateEvent
          mode={"edit"}
          courseId={courseId} //  for video meetings courseId will be sent null. becoz there can be multiple courses in on event
          calendarEventModalClose={onCloseEventModal}
          editModeDetails={editModeDetails}
          refetchEvents={_refetchEvents}
        />
      )}
      {!!currentDialogAction && !eventIdForDeletion && (
        <DialogueBox
          modalTitle={t(DIALOGS_INFO[currentDialogAction].title)}
          showModal={true}
          onClickButton2={onDialogConfirmClick}
          modalBody={DIALOGS_INFO[currentDialogAction].message(t)}
          toggleDialogueBoxDisplay={onToggleDialogueClick}
          button1={
            t(DIALOGS_INFO[currentDialogAction].cancelButtonText) ||
            t("common:cancel")
          }
          button2={DIALOGS_INFO[currentDialogAction].confirmButtonText(t)}
        />
      )}
      {eventIdForDeletion ? (
        <DialogueBox
          modalTitle={t(DIALOGS_INFO[currentDialogAction].title)}
          showModal={true}
          onClickButton2={onConfirmDeleteEventClick}
          modalBody={DIALOGS_INFO[currentDialogAction].message(t)}
          toggleDialogueBoxDisplay={onDeleteDialogueClose}
          button1={
            t(DIALOGS_INFO[currentDialogAction].cancelButtonText) ||
            t("common:cancel")
          }
          button2={DIALOGS_INFO[currentDialogAction].confirmButtonText(t)}
        />
      ) : null}
      {showRecurringDeleteOptions ? (
        <ActionForSelectModal
          t={t}
          onClose={setShowRecurringDeleteOptions}
          onAction={onRecurringOptionsDelete}
        />
      ) : null}
      {isLoading && <FullScreenLoader />}
    </>
  );
});

const EventModalWithLoading = withLoader(EventModal);

const EventModalComp = props => {
  return (
    <div className={classes.modalContent}>
      <EventModalWithLoading {...props} />
    </div>
  );
};

const mapActionCreators = {
  deleteAssignment,
  setToastMsg,
  deletePlatformCalendarEvent,
  goToRelativeRoute,
  goToPlatformHomeChildRoute,
  updatePlatformCalendarEventBasicDetails,
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = _.get(state, "login.userInfo", {});
  const userType = _.get(userInfo, "user_type");
  const userId = _.get(userInfo, "id");
  const calendarParentColorMap = state.myCalendar.calendarParentColorMap;

  const platform = _.get(state, "platform", {});
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });

  const userCourses = _.map(
    _.get(
      getUserCoursesFromCache(
        getUserCoursesVars({ userInfo, platform, isCurriculumProgramFree })
      ),
      "courses",
      []
    ),
    ({ id }) => id
  );

  const showCalendarAssignmentByDueDate =
    getCalendarEventsViewSettingValue({ organizationId: userInfo.org_id }) ===
    "DUE_DATE";

  return {
    userId,
    entityType: "PLATFORM_CALENDAR_EVENT",
    isData: true,
    isLoading: false,
    userType,
    calendarParentColorMap,
    userCourses,
    showCalendarAssignmentByDueDate,
  };
};

export default compose(
  I18nHOC({
    resource: ["common", "toastMsgs", "calendar", "classRoom"],
  }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCalendarEventDetailsQuery, {
    name: "getCalendarEventDetails",
    options: ({ eventId, entityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: eventId,
        type: entityType,
      },
    }),
    props({
      getCalendarEventDetails,
      ownProps: { eventId, isData, isLoading, entityType },
    }) {
      const eventData = getCalendarEventDetailsFromCache({
        id: eventId,
        type: entityType,
      });

      return {
        isData: !_.isEmpty(eventData) && isData,
        eventData,
        isLoading:
          getCalendarEventDetails["networkStatus"] == 1 ||
          getCalendarEventDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(EventModalComp);
