import React, { PureComponent } from "react";
import classes from "./Accordian.scss";
import classNames from "classnames";
import { colors } from "Constants/colorConstants";
import { AnimateHeight } from "UIComponents";
import { ArrowIcon } from "SvgComponents";
import { ChevronRightOutlined } from "@toddle-design/web-icons";

class Accordian extends PureComponent {
  state = {
    isExpanded: this.props.isExpanded,
  };

  componentDidUpdate(prevProps) {
    const { isExpanded } = this.props;
    if (prevProps.isExpanded !== isExpanded)
      this.setState({ isExpanded: isExpanded });
  }

  onAccordianClick = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });

    const { getIsExpanded } = this.props;
    if (getIsExpanded) {
      getIsExpanded(isExpanded);
    }
  };

  render() {
    const {
      props: {
        title,
        readOnly,
        expandedContent,
        getExpandedContent,
        showLabelBorder,
        hideContainerBorder,
        count,
        customTitleStyle,
        mainContentContainerStyle,
        mainContainerClass,
        customTitleComponent,
        headerStyle,
        showLeftArrow,
      },
      state: { isExpanded },
    } = this;

    const arrowStyle = classNames(
      { [classes.arrowContainer]: true },
      { [classes.openArrow]: isExpanded },
      { [classes.closeArrow]: !isExpanded }
    );

    const leftArrowStyle = classNames(
      { [classes.leftArrowContainer]: true },
      { [classes.leftOpenArrow]: isExpanded },
      { [classes.leftCloseArrow]: !isExpanded }
    );

    const titleStyle = classNames(
      { [classes.headerLabel]: true },
      { [classes.headerLabelOpen]: isExpanded }
    );

    const mainContainer = classNames(
      { [mainContainerClass]: !!mainContainerClass },
      { [classes.mainContainer]: true },
      { [classes.showLabelBorder]: showLabelBorder }
    );

    const mainContentContainer = classNames(
      { [classes.mainContentContainer]: true },
      { [classes.hideContainerBorder]: hideContainerBorder }
    );

    const titleStyleCustom = {
      ...customTitleStyle,
      color: isExpanded ? colors.blue29 : customTitleStyle.color,
    };

    return (
      <div className={mainContainer}>
        <div
          className={classes.headerContainer}
          style={{
            ...headerStyle,
            justifyContent: showLeftArrow ? "flex-start" : "space-between",
          }}
          onClick={this.onAccordianClick}
        >
          {showLeftArrow && (
            <div className={leftArrowStyle}>
              <ChevronRightOutlined size={"xxx-small"} />
            </div>
          )}
          {customTitleComponent ? (
            customTitleComponent
          ) : (
            <div className={classes.titleContainer}>
              <div style={titleStyleCustom} className={titleStyle}>
                {title}
              </div>
              {count > 0 ? (
                <div className={classes.countStyle}>{count}</div>
              ) : null}
            </div>
          )}
          {!showLeftArrow && (
            <div className={arrowStyle}>
              <ArrowIcon width={12} height={6} fill={colors.gray48} />
            </div>
          )}
        </div>

        <AnimateHeight
          duration={300}
          height={isExpanded || readOnly ? "auto" : 0}
          style={{ width: "100%" }}
        >
          <div
            style={mainContentContainerStyle}
            className={mainContentContainer}
          >
            {getExpandedContent
              ? isExpanded
                ? getExpandedContent()
                : null
              : expandedContent}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

Accordian.defaultProps = {
  showLabelBorder: true,
  hideContainerBorder: false,
  count: 0,
  customTitleStyle: {},
  isExpanded: false,
  mainContentContainerStyle: {},
  headerStyle: {},
  showLeftArrow: false,
};

export default Accordian;
