import React from "react";
import classes from "./SlickSlider.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronIcon } from "SvgComponents";
import PropTypes from "prop-types";

const arrowStyle = {
  display: "flex",
  background: "white",
  zIndex: 1,
  borderRadius: 16,
  width: 32,
  height: 32,
  opacity: 1,
  margin: "0 16px",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.3)",
  alignItems: "center",
  justifyContent: "center",
};

const NextArrow = props => {
  const {
    className,
    style,
    onClick,
    onArrowClick,
    slideCount,
    currentSlide,
    customArrowStyle,
    customArrowIconContainerStyle,
    infinite,
  } = props;

  if (currentSlide == slideCount - 1 && !infinite) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        ...arrowStyle,
        right: 16,
        ...customArrowStyle,
      }}
      onClick={e => onArrowClick(e, onClick)}
    >
      <div
        className={classes.arrowIconContainer}
        style={{ transform: "rotate(180deg)" }}
      >
        <ChevronIcon {...customArrowIconContainerStyle} />
      </div>
    </div>
  );
};

const PrevArrow = props => {
  const {
    className,
    style,
    onClick,
    onArrowClick,
    currentSlide,
    customArrowStyle,
    customArrowIconContainerStyle,
    infinite,
  } = props;
  if (currentSlide == 0 && !infinite) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        ...arrowStyle,
        left: 16,
        ...customArrowStyle,
      }}
      onClick={e => onArrowClick(e, onClick)}
    >
      <div className={classes.arrowIconContainer}>
        <ChevronIcon {...customArrowIconContainerStyle} />
      </div>
    </div>
  );
};

class SlickSlider extends React.PureComponent {
  onArrowClick = (e, onClick) => {
    e.stopPropagation();
    onClick();
  };

  render() {
    const {
      customPrevArrowStyle,
      customNextArrowStyle,
      swipeToSlide,
      variableWidth,
      infinite,
      showDots,
      className,
      slidesToScroll,
      customArrowIconContainerStyle,
      renderItem,
      items,
      slidesToShow,
      autoplay,
      autoplaySpeed,
      swipe,
      containerStyle,
    } = this.props;
    var settings = {
      className: className,
      dots: showDots,
      infinite: infinite,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToScroll,
      variableWidth: variableWidth,
      swipeToSlide: swipeToSlide,
      autoplay: autoplay,
      autoplaySpeed: autoplaySpeed,
      swipe: swipe,
      // lazyLoad: true,
      nextArrow: (
        <NextArrow
          onArrowClick={this.onArrowClick}
          customArrowStyle={customNextArrowStyle}
          customArrowIconContainerStyle={customArrowIconContainerStyle}
          infinite={infinite}
        />
      ),
      prevArrow: (
        <PrevArrow
          onArrowClick={this.onArrowClick}
          customArrowStyle={customPrevArrowStyle}
          customArrowIconContainerStyle={customArrowIconContainerStyle}
          infinite={infinite}
        />
      ),
    };

    return (
      <div className={classes.container} style={{ ...(containerStyle || {}) }}>
        <Slider {...settings}>
          {_.map(items, (item, index) => {
            return renderItem({ item, index });
          })}
        </Slider>
      </div>
    );
  }
}

SlickSlider.propTypes = {
  items: PropTypes.array,
  renderItem: PropTypes.func,
  customPrevArrowStyle: PropTypes.object,
  customNextArrowStyle: PropTypes.object,
  swipeToSlide: PropTypes.bool,
  className: PropTypes.string,
  showDots: PropTypes.bool,
  infinite: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  customArrowIconContainerStyle: PropTypes.object,
};
SlickSlider.defaultProps = {
  items: [],
  customPrevArrowStyle: {},
  customNextArrowStyle: {},
  customArrowIconContainerStyle: {},
  swipeToSlide: true,
  variableWidth: true,
  infinite: false,
  showDots: true,
  className: "slider variable-width",
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  swipe: true,
};

export default SlickSlider;
