import gql from "graphql-tag";
import { mypCriteriaWithDescriptorFragmnet } from "./AssessmentToolFragment";
export const getCriteriaDescriptorsQuery = gql`
  query getCriteriaDescriptors($ids: [ID!]) {
    multiNode(ids: $ids, type: PLANNER_ELEMENT_NODE) {
      id
      ... on PlannerElementNode {
        ...mypCriteriaWithDescriptorItem
      }
    }
  }
  ${mypCriteriaWithDescriptorFragmnet.mypCriteriaWithDescriptor}
`;
