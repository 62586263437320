import React from "react";
import classes from "./StudentItem.scss";
import { ProfileCascade, MoreItemWithToolTip, UIButton } from "UIComponents";
import { TickPlain } from "SvgComponents";
import { colors } from "Constants";
import { useTranslation } from "react-i18next";
import { I18nHOC } from "UIComponents";

const profileCascadeStyle = {
  customStyle: {
    borderWidth: 0,
    width: 32,
    height: 32,
  },
};

const StaffItem = ({ item, isSelected, onAddClick, t }) => {
  const { id, firstName, lastName, profileImage, email } = item;
  const name = !!firstName ? `${firstName} ${lastName}` : email;

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <ProfileCascade
          items={[{ id, value: firstName, imageUrl: profileImage }]}
          customStyle={profileCascadeStyle.customStyle}
        />
        <div className={classes.nameClasscontainer}>
          <div className={classes.nameText}>{name}</div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        {isSelected ? (
          <div className={classes.addedContainer}>
            <TickPlain fill={colors.blue29} width={14} height={14} />
            <div className={classes.addedText}>{t("common:added")}</div>
          </div>
        ) : (
          <UIButton
            color="blue"
            onClick={e => onAddClick(e, [item])}
            size="sm"
            type="hollow"
          >
            {t("add")}
          </UIButton>
        )}
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["common"] })(StaffItem);
