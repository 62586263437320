import React from "react";
import { UIButton, I18nHOC, UIModal } from "UIComponents";
import classes from "./MagicLinkExpired.scss";
import { compose } from "react-apollo";
import * as EventTracker from "lib/eventTracker";
import { ToddleIcon } from "SvgComponents";
import { Trans } from "react-i18next";
import { colors, CLOUD_URL } from "Constants";
import { isMobile } from "Utils";

const url = `${CLOUD_URL}/assets/webapp/Magic_link_expired.png`;

const MagicLinkExpired = ({ t, closeModal }) => {
  const handleSignInClick = () => {
    closeModal();
  };

  return (
    <UIModal
      isOpen={true}
      isFullScreen={isMobile()}
      overlayContainer={classes.overlayContainer}
      modalContent={isMobile() ? classes.styling : classes.container}
    >
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <ToddleIcon />
        </div>
        <div className={classes.toddleText}>{t("common:toddle")}</div>
      </div>

      <div className={classes.imageContainer}>
        <img src={url} height="164px" />
      </div>

      <div className={classes.title}>{t("common:sign_in_link_expired")}</div>
      <div className={classes.subTitle}>{t("common:magic_link_expiry")}</div>
      {isMobile() ? (
        <div className={classes.subTitle}>
          <Trans i18nKey="common:visit_website_and_signin">
            Please go to
            <a
              style={{ color: colors.salmon60 }}
              href="https://web.toddleapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              web.toddleapp.com
            </a>
            on your <strong>desktop or laptop</strong> and sign in.
          </Trans>
        </div>
      ) : (
        <UIButton color="pink" onClick={handleSignInClick}>
          {t("common:sign_in_to_toddle")}
        </UIButton>
      )}
    </UIModal>
  );
};

export default compose(I18nHOC({ resource: ["common"] }))(MagicLinkExpired);
