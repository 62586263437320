import React from "react";
import classes from "./SmallLibraryItem.scss";
import DropDown from "./DropDown";
import { CommentIcon } from "SvgComponents";

import { ResourceImagePlaceHolder, I18nHOC } from "UIComponents";
import { colors, LE_ASSESSMENT_TYPE_MAPPING } from "Constants";

class LibraryItem extends React.PureComponent {
  render() {
    const {
      onCardClicked,
      item,
      onDeleteClick,
      onDuplicateClick,
      onPrintClick,
      readOnly,
      onAddToCommunityClick,
      onAssignToStudentClick,
      t,
      curriculumType,
      provided,
      snapshot,
      innerRef,
      updateTagAssessmentId,
      totalNewSnpCycles,
    } = this.props;

    const assessmentType = _.get(item, "assessmentType.value", "le") || "le";

    const updatedProps = {
      ...provided.draggableProps,
      ...provided.dragHandleProps,
    };

    const draggingStyle = snapshot.isDragging
      ? {
          boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0,.1)",
          border: `solid 1px ${colors.blue29}`,
        }
      : {};

    const style = {
      ...provided.draggableProps.style,
      ...draggingStyle,
    };

    return (
      <div
        ref={ref => innerRef(ref)}
        className={classes.item}
        {...updatedProps}
        style={style}
        onClick={e => {
          if (!e.defaultPrevented) onCardClicked(item);
        }}
        key={item.id}
      >
        <div className={classes.itemImageContainer}>
          <ResourceImagePlaceHolder
            id={item.id}
            type={item.assessmentType}
            imageUrl={item.image.value}
          />
        </div>

        <div className={classes.itemContentContainer}>
          <div className={classes.itemDetailContainer}>
            <div className={classes.itemDetailLeftContainer}>
              <div className={classes.titleContainer}>
                <div className={classes.title}>
                  {!item.title.value ? t("common:untitled") : item.title.value}
                </div>
                <div className={classes.commentIcon}>
                  <CommentIcon width={24} height={24} fill={colors.strokeOne} />
                </div>
              </div>

              <div className={classes.createdBy}>
                {t(LE_ASSESSMENT_TYPE_MAPPING[assessmentType].localeLabel)}
              </div>
            </div>
          </div>
          {!readOnly && (
            <div className={classes.menuContainer}>
              <DropDown
                item={item}
                curriculumType={curriculumType}
                onDeleteClick={onDeleteClick}
                onEditClick={onCardClicked}
                onDuplicateClick={onDuplicateClick}
                onPrintClick={onPrintClick}
                onAddToCommunityClick={onAddToCommunityClick}
                onAssignToStudentClick={onAssignToStudentClick}
                updateTagAssessmentId={updateTagAssessmentId}
                totalNewSnpCycles={totalNewSnpCycles}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(LibraryItem);
