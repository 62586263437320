import React, { Fragment } from "react";
import classes from "./GradeOrCourseSelection.scss";
import { CoachmarkChevronIcon } from "SvgComponents";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { colors } from "Constants";
import { withLoader } from "UIComponents";
import { CURRICULUM_TYPE_PYP } from "Constants/stringConstants";
import { getStaffCoursesQuery } from "Teacher/modules/TeacherQuery";
import { getStaffCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getUserInfo } from "Login/modules/LoginModule";
import GrouppedGradeList from "Teacher/routes/Courses/components/components/GrouppedGradeList";
import { withRouter } from "react-router";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";

const GradeSelection = props => {
  const { grades, handleSelect, styles = {} } = props;
  return (
    <div className={classes.container} style={{ ...(styles.container || {}) }}>
      <div className={classes.list} style={{ ...(styles.list || {}) }}>
        {_.map(grades, grade => {
          return (
            <ClassCard
              key={grade.id}
              grade={grade}
              handleSelect={handleSelect}
            ></ClassCard>
          );
        })}
      </div>
    </div>
  );
};

const ClassCard = React.memo(({ grade, handleSelect }) => {
  const { name, id: gradeId, courseId } = grade;

  const color = colors.blue29;
  return (
    <div
      className={classes.item}
      style={{ borderLeftColor: color }}
      onClick={() => handleSelect({ gradeId, courseId })}
    >
      <div className={classes.itemTitle}>{`${name}`}</div>
      <div className={classes.icon}>
        <CoachmarkChevronIcon />
      </div>
    </div>
  );
});

const GradeOrCourseSelection = props => {
  const { groupClasses, handleSelect } = props;
  return (
    <Fragment>
      {groupClasses ? (
        <GrouppedGradeList
          {...props}
          handleSelect={gradeId => handleSelect({ gradeId })}
        />
      ) : (
        <GradeSelection {...props} />
      )}
    </Fragment>
  );
};

GradeOrCourseSelection.defaultProps = {
  groupClasses: false,
  portalType: "PLANNER",
};

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });
  const isCommunity = state.login.activeTab == "community";

  const curriculumProgramId = _.get(ownProps, "params.curriculumProgramId", "");
  const tabConfigurations = { admin: { curriculumProgramId } };
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });

  return {
    isCommunity,
    isData: true,
    isLoading: false,
    userId: userInfo.id,
    organizationId: userInfo.org_id,
    isCurriculumProgramFree,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  graphql(getStaffCoursesQuery, {
    name: "getStaffCourses",
    options: ({ userId, portalType, isCurriculumProgramFree }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        portalType,
        isCurriculumProgramFree,
      },
    }),
    props({
      getStaffCourses,
      ownProps: {
        userId,
        isData,
        isCommunity,
        isLoading,
        isCurriculumProgramFree,
      },
    }) {
      const queryData = getStaffCoursesFromCache(
        userId,
        isCurriculumProgramFree
      );
      const allCourses = _.get(queryData, "courses", []);

      const courses = _.filter(allCourses, ({ isDemo, curriculumProgram }) => {
        if (isCommunity) {
          return !isDemo && curriculumProgram.type == CURRICULUM_TYPE_PYP;
        }

        return !isDemo;
      });

      const mixedGrades = [];
      _.forEach(courses, ({ grades, id }) =>
        mixedGrades.push(
          ..._.map(grades, grade => {
            return { ...(grade || {}), courseId: id };
          })
        )
      );
      const grades = _.uniqBy(mixedGrades, "id");

      return {
        courses,
        grades,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getStaffCourses["networkStatus"] == 1 ||
          getStaffCourses["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(GradeOrCourseSelection);
