import React from "react";
import classes from "./LinkModal.scss";
import { Loading, UIModal, I18nHOC } from "UIComponents";
import { getWebLinkDetails } from "modules/Services";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import shortid from "shortid";
import { isURL, addHttpInUrl, addHttpsInUrl } from "Utils";
import { setToastMsg } from "Login/modules/LoginModule";
import { Button, IconButton, TextAreaInput } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";

const styles = {
  textAreaStyles: {
    overflowY: "hidden",
  },
};

class LinkModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      link: _.get(props, "value", ""),
      isLoading: false,
      error: "",
    };
  }

  onLinkAdd = async () => {
    const {
      getWebLinkDetails,
      onLinkAdd,
      t,
      onClose,
      setToastMsg,
    } = this.props;

    const { link } = this.state;

    this.setState({ isLoading: true });
    const linkValue = _.trim(link);
    if (isURL(linkValue)) {
      const linkUrl = addHttpsInUrl(linkValue);
      const urlDetailObject = await getWebLinkDetails(linkUrl);
      if (urlDetailObject) {
        const id = shortid.generate();

        this.setState({ link: "", isLoading: false });

        onLinkAdd({ attachments: urlDetailObject.attachments, id });
        onClose();
      } else {
        this.setState({
          error: t("common:upload_err"),
          isLoading: false,
        });
      }
    } else {
      setToastMsg("toastMsgs:web_link_not_valid");
      this.setState({
        error: t("common:upload_err"),
        isLoading: false,
      });
    }
  };

  updateLink = e => {
    const link = e.target.value;
    this.setState({ link });
  };

  onKeyUp = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.onLinkAdd();
    }
  };

  render() {
    const { t, mode, onClose } = this.props;

    const { link, isLoading, error } = this.state;

    return (
      <UIModal
        isOpen={true}
        onRequestClose={onClose}
        modalContent={classes.modalContent}
      >
        <div className={classes.modalHeader}>
          <div className={classes.header}>{t("common:add_from_link")}</div>
          <IconButton
            icon={<CloseOutlined />}
            variant="plain"
            size="medium"
            onClick={onClose}
          />
        </div>

        <div className={classes.modalBody}>
          {isLoading ? (
            <Loading />
          ) : (
            <TextAreaInput
              minRows={1}
              placeholder={t("common:enter_link_url_placeholder")}
              onChange={this.updateLink}
              name="link"
              value={link}
              error={error}
              onKeyUp={this.onKeyUp}
              // onEnterPress={this.onLinkAdd}
              style={styles.textAreaStyles}
            />
          )}
        </div>
        <div className={classes.modalFooter}>
          <Button size="medium" onClick={this.onLinkAdd} disabled={isLoading}>
            {mode === "edit" ? t("common:update") : t("common:add")}
          </Button>
        </div>
      </UIModal>
    );
  }
}

LinkModal.defaultProps = {
  value: "",
  mode: "create",
};

const mapActionCreators = {
  getWebLinkDetails,
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "common" })
)(LinkModal);
