import React from "react";
import classes from "./TopHeader.scss";
import { SegmentControl, Button } from "@toddle-design/web";
import { ToddleIcon, LeftIcon } from "SvgComponents";

export const getTabs = feedAnalytics => {
  const onBoardedSchoolsCount = _.get(feedAnalytics, "ONBOARDED", 0);
  const onBoardingSchoolsCount = _.get(feedAnalytics, "ONBOARDING", 0);
  const transitionSchoolsCount = _.get(feedAnalytics, "TRANSITION", 0);
  return [
    {
      key: "ONBOARDED",
      label: `All schools ${onBoardedSchoolsCount}`,
      value: "ONBOARDED",
    },
    {
      key: "ONBOARDING",
      label: `Onboarding ${onBoardingSchoolsCount}`,
      value: "ONBOARDING",
    },
    {
      key: "TRANSITION",
      label: `Transition ${transitionSchoolsCount}`,
      value: "TRANSITION",
    },
  ];
};

const TopHeader = props => {
  const {
    goToBack,
    onClickAdd,
    activeTab,
    updateOrganizationFilters,
    feedAnalytics,
  } = props;

  const handleTabChange = value => {
    updateOrganizationFilters({ tab: value });
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.leftIcon} onClick={goToBack}>
          <LeftIcon />
          <ToddleIcon />
        </div>
        <div className={classes.title}>{`Schools`}</div>
      </div>
      <div>
        <SegmentControl
          value={activeTab}
          options={getTabs(feedAnalytics)}
          onChange={handleTabChange}
          size="x-large"
        />
      </div>
      <div className={classes.rightContainer}>
        <Button onClick={onClickAdd} size="large">{`Create new school`}</Button>
      </div>
    </div>
  );
};

export default TopHeader;
