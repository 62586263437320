import React from "react";
import classes from "./CaptureVideo.scss";
import ScreenPanel from "../../ScreenPanel";
import {
  I18nHOC,
  DialogueBox,
  CountdownScreen,
  FullScreenLoader,
} from "UIComponents";
import { compose } from "react-apollo";
import Webcam from "react-webcam";
import {
  generateRandomId,
  formatTime,
  getCompressedBlob,
  isSafari,
} from "Utils";
import { PauseIcon, ResumeIcon, RotateIcon } from "SvgComponents";
import ScreenHeader from "./ScreenHeader";
import {
  checkIfMultipleCamerasIsPresent,
  checkIfVideoMimeTypeSupported,
} from "Post/utils";
import { colors } from "Constants";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
// time limit in milliseconds

const RECORDING_TIME_LIMIT = 600000;
const COUNTDOWN_TIME_LIMIT = 3000;

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

// Buttons

const PauseButton = ({ onClick }) => {
  return (
    <div className={classes.pauseButton} onClick={onClick}>
      <PauseIcon width={16} height={20} fill={colors.white} />
    </div>
  );
};

const RecordButton = ({ onClick }) => {
  return (
    <div className={classes.recordButton} onClick={onClick}>
      <div className={classes.oval}></div>
    </div>
  );
};

const ResumeButton = ({ onClick }) => {
  return (
    <div className={classes.resumeButton} onClick={onClick}>
      <ResumeIcon width={24} height={22} />
    </div>
  );
};

const FlipCameraButton = ({ onClick }) => {
  return (
    <div className={classes.flipCameraButton} onClick={onClick}>
      <RotateIcon />
    </div>
  );
};

// Screens

const PauseScreen = ({ onClick }) => {
  return (
    <div className={classes.screenContainer}>
      <div className={classes.mediaControls}>
        <PauseButton onClick={onClick} />
        <PauseButton onClick={onClick} />
      </div>
    </div>
  );
};

const ResumeScreen = ({ onClick }) => {
  return (
    <div className={classes.screenContainer}>
      <div className={classes.mediaControls}>
        <ResumeButton onClick={onClick} />
        <ResumeButton onClick={onClick} />
      </div>
    </div>
  );
};

const RecordScreen = ({ onClick, showFlipCamera = false, onFlipCamera }) => {
  return (
    <div className={classes.screenContainer}>
      <div className={classes.mediaControls}>
        <RecordButton onClick={onClick} />
        <RecordButton onClick={onClick} />
      </div>
      {showFlipCamera ? (
        <div className={classes.rightSideCameraOptions}>
          <FlipCameraButton onClick={onFlipCamera} />
        </div>
      ) : null}
    </div>
  );
};

const CAPTURE_MIME_TYPE_WEBM = "video/webm;codecs=vp8,opus";
const CAPTURE_MIME_TYPE_MP4 = "video/mp4";

const isWebmSupported = checkIfVideoMimeTypeSupported("video/webm");
class CaptureVideo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.mediaRecorder = null;
    this.timerInstance = null;
    this.chunks = [];
    this.mediaOptions = {
      mimeType: isWebmSupported
        ? CAPTURE_MIME_TYPE_WEBM
        : CAPTURE_MIME_TYPE_MP4,
    };
    this.state = {
      recordingState: "record",
      timer: 0,
      showExitDialog: false,
      facingMode: FACING_MODE_USER,
      hasMultipleCameras: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      screenPanelProps: { updatePost },
      setToastMsg,
    } = this.props;

    checkIfMultipleCamerasIsPresent()
      .then(isPresent => {
        this.setState({
          hasMultipleCameras: isPresent,
          isLoading: false,
          facingMode: isPresent ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER,
        });
      })
      .catch(e => {
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
        });
        this.setState({ isLoading: false });
      });

    updatePost({ attachments: [] });
  }

  flipCamera = () => {
    if (this.state.hasMultipleCameras) {
      this.setState(({ facingMode }) => ({
        facingMode:
          facingMode === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER,
      }));
    }
  };

  startTimer = () => {
    this.timerInstance = setInterval(() => {
      // close recording after recording limit reached
      if (this.state.timer == RECORDING_TIME_LIMIT / 1000)
        this.handleStopCaptureClick();
      else this.setState(state => ({ timer: state.timer + 1 }));
    }, 1000);
  };

  stopTimer = () => {
    clearInterval(this.timerInstance);
  };

  componentWillUnmount() {
    this.setState({ recordingState: "stopped" });
    clearInterval(this.timerInstance);
  }

  handleStartCaptureClick = () => {
    this.setState({ recordingState: "recording" });

    // init Mediarecorder
    try {
      this.mediaRecorder = new MediaRecorder(
        this.webcamRef.current.stream,
        this.mediaOptions
      );

      // Add event listener to listen when player stops and dataavailable event triggered
      this.mediaRecorder.ondataavailable = this.onDataAvailable;

      // Add event listener to listen when stop event triggered
      this.mediaRecorder.onstop = this.onStop;

      this.mediaRecorder.start();

      // start timer
      this.startTimer();
    } catch {}
  };

  onStop = async () => {
    const {
      screenPanelProps: {
        updatePost,
        goToStep,
        postDetails: { attachments = [] },
      },
    } = this.props;

    const data = new Blob(this.chunks, { type: this.mediaOptions.mimeType });

    if (data.size > 0 && this.webcamRef.current) {
      // generate upload id
      const uploadId = generateRandomId();

      // get video resolution
      let vidHeight = 720;
      let vidWidth = 1280;

      if (_.get(this.webcamRef, "current.stream", null)) {
        const webcamSettings = this.webcamRef.current.stream
          ?.getVideoTracks?.()?.[0]
          ?.getSettings?.();
        vidHeight = _.get(webcamSettings, "height", vidHeight);
        vidWidth = _.get(webcamSettings, "width", vidWidth);
      }

      const { blob: compressedBlob, mimeType } = await getCompressedBlob({
        chunks: _.cloneDeep(this.chunks),
        mimeType: isWebmSupported
          ? CAPTURE_MIME_TYPE_WEBM
          : CAPTURE_MIME_TYPE_MP4,
      });

      // create blob url from blob
      const blobUrl = URL.createObjectURL(compressedBlob, {
        type: mimeType,
      });

      // file name
      const timestamp = moment().format("DD-MM-YY_h_mm");
      const filename = "toddle_video_".concat(timestamp);
      // create file object
      const file = new File([compressedBlob], filename, {
        type: mimeType,
        lastModified: Date.now(),
      });

      const attachment = {
        file: file,
        id: uploadId,
        metadata: {
          height: vidHeight,
          size: compressedBlob.size,
          width: vidWidth,
        },
        mimeType,
        name: filename,
        type: "VIDEO",
        url: blobUrl,
      };

      // update redux store and move to next step
      updatePost({ attachments: [...attachments, attachment], uploadId });
      if (goToStep) goToStep("POST_DETAIL");
    }
  };

  onDataAvailable = ({ data }) => {
    if (data) this.chunks.push(data);
  };

  handlePauseCaptureClick = () => {
    // pause timer
    this.stopTimer();
    if (this.mediaRecorder?.state !== "inactive") {
      this.mediaRecorder?.pause?.();
    }
    this.setState({ recordingState: "paused" });
  };

  handleResumeCaptureClick = () => {
    if (this.mediaRecorder?.state !== "inactive") {
      this.mediaRecorder?.resume?.();
    }
    this.setState({ recordingState: "recording" });
    // resume timer
    this.startTimer();
  };

  handleStopCaptureClick = () => {
    // stop timer
    this.stopTimer();

    if (this.mediaRecorder?.state !== "inactive") {
      this.mediaRecorder?.stop?.();
    }
  };

  handleCountdown = () => {
    this.setState({ recordingState: "countdown" });
  };

  getRecordingStateScreen = () => {
    const { recordingState, hasMultipleCameras } = this.state;
    const isSafariOrNot = isSafari();

    switch (recordingState) {
      case "record":
        return (
          <RecordScreen
            onClick={this.handleCountdown}
            onFlipCamera={this.flipCamera}
            showFlipCamera={hasMultipleCameras}
          />
        );
      case "countdown":
      case "recording":
        if (isSafariOrNot) {
          return;
        }
        return <PauseScreen onClick={this.handlePauseCaptureClick} />;
      case "paused":
        if (isSafariOrNot) {
          return;
        }
        return <ResumeScreen onClick={this.handleResumeCaptureClick} />;
    }
  };

  // Screen Info: It comes with overlay and text over the media controls
  getScreenInfo = () => {
    const { t } = this.props;
    const { recordingState } = this.state;

    switch (recordingState) {
      case "countdown":
        return (
          <CountdownScreen
            timeLimit={COUNTDOWN_TIME_LIMIT}
            callback={this.handleStartCaptureClick}
          />
        );
      case "paused":
        return (
          <div className={classes.screenInfoContainer}>
            <div className={classes.overlay}></div>
            <div className={classes.recordingPausedText}>
              {t("common:state_paused", {
                label: t("common:recording"),
              })}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  onCloseClick = () => {
    const {
      screenPanelProps: { onCloseClick },
    } = this.props;
    const { recordingState } = this.state;

    // enable exit dialog box when its recording else just go back
    if (recordingState != "record") {
      this.setState({ showExitDialog: true });
      // pause recording when user tries to exit
      this.handlePauseCaptureClick();
    } else {
      onCloseClick();
    }
  };

  handleCancelExitDialog = () => {
    this.setState({ showExitDialog: false });
    // resume recording when user drops the idea to exit
    this.handleResumeCaptureClick();
  };

  handleToggleExitDialogBoxDisplay = ({ isCancel } = { isCancel: false }) => {
    if (isCancel) {
      this.setState({ showExitDialog: false });
      // resume recording when user drops the idea to exit by clicking cross icon
      this.handleResumeCaptureClick();
    }
  };

  handleError = e => {
    const { setToastMsg } = this.props;
    const errorMessage = _.get(e, "message", "");
    if (!_.isEmpty(errorMessage)) {
      setToastMsg({
        msg: "toastMsgs:enable_dynamic_permission",
        locale_params: [
          { key: "permission", value: "camera", isPlainText: true },
        ],
      });
    }
    /*if (
      !_.isEmpty(errorMessage) &&
      _.isEqual(errorMessage, "Permission denied")
    ) {
      setToastMsg({
        msg: "toastMsgs:enable_dynamic_permissions",
        locale_params: [
          {
            key: "permissions",
            value: "camera and microphone",
            isPlainText: true,
          },
        ],
      });
    } else {
      setToastMsg({
        msg: "toastMsgs:something_went_wrong",
      });
    }*/
  };

  render() {
    const { screenPanelProps, t } = this.props;
    const { onCloseClick } = screenPanelProps;
    const {
      recordingState,
      timer,
      showExitDialog,
      facingMode,
      isLoading,
    } = this.state;
    // progressTimer format is 00:00/10:00
    const isSafariOrNot = isSafari();
    const progressTimer = formatTime(timer).concat(
      "/",
      formatTime(RECORDING_TIME_LIMIT / 1000)
    );
    const mirroredOutput = facingMode === FACING_MODE_USER;

    return (
      <ScreenPanel {...screenPanelProps} containerStyle={{ padding: 0 }}>
        {isLoading && <FullScreenLoader />}
        <ScreenHeader
          isRecordingPaused={recordingState == "paused"}
          onBackClick={this.onCloseClick}
          onDoneClick={this.handleStopCaptureClick}
          isDoneDisabled={recordingState == "record"}
          showTimer={recordingState != "record"}
          timer={progressTimer}
        />
        <div className={classes.captureVideoContainer}>
          <Webcam
            className={classes.webcamScreen}
            ref={this.webcamRef}
            audio={true}
            audioConstraints={{
              echoCancellation: true,
              noiseSuppression: true,
            }}
            imageSmoothing={true}
            mirrored={mirroredOutput}
            onUserMedia={() => {}}
            onUserMediaError={this.handleError}
            videoConstraints={{
              facingMode: facingMode,
            }}
          />
          {this.getRecordingStateScreen()}
          {this.getScreenInfo()}

          {/* If user clicked back without saving */}
          {showExitDialog && (
            <DialogueBox
              modalTitle={t("common:capture_video")}
              showModal={true}
              onClickButton1={this.handleCancelExitDialog}
              onClickButton2={onCloseClick}
              modalBody={t("common:exit_without_save_prompt")}
              toggleDialogueBoxDisplay={this.handleToggleExitDialogBoxDisplay}
              button1={t("common:cancel")}
              button2={t("common:yes")}
            ></DialogueBox>
          )}

          {/* If Media recorder is not supported by the browser show this */}
          {!window.hasOwnProperty("MediaRecorder") && (
            <DialogueBox
              modalTitle={t("common:capture_video")}
              showModal={true}
              onClickButton2={onCloseClick}
              toggleDialogueBoxDisplay={onCloseClick}
              modalBody={t("common:functionality_unsupported_browser", {
                label: "Chrome or Firefox",
              })}
              button2={t("common:okay")}
            ></DialogueBox>
          )}
        </div>
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "common" })
)(CaptureVideo);
