import client from "apolloClient";
import {
  resourceNodeFragment,
  resourcePlannerFragment,
  staffDetailUnitPlanFeedFragment,
  unitPlanResourceLibraryFragment,
  courseDetailUnitPlanFeedFragment,
} from "./UnitPlanFragments";
import {
  getUnitPlansQuery,
  getAssessmentsQuery,
  getPlannerElementNodeQuery,
  getPlannerFieldResponsesQuery,
  getUnitPlanResourceLibraryQuery,
  getPlannerResourcesOfNodeQuery,
  getNodeUnitPlanFeedQuery,
  getPlannerOrganizationResourcesOfNodeQuery,
  getNodeUnitPlanEvidenceFeedQuery,
  getPlannerFieldsOfNodeQuery,
  getFlatUnitPlansQuery,
  getMypRepoOrganizationDetailsQuery,
  getCentralRepoOrgDetailsQuery,
  getFlatUnitPlanFeedQuery,
  getFlatUnitPlanTitleQuery,
  getStaffCoursesBasicDetailsQuery,
} from "./UnitPlanQuery";
import { unitPlanFeedFragment } from "modules/CommonFragments";

const fragmentNameMapping = {
  UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: "unitPlan",
    fragmentName: "unitPlanNodeItem",
  },
  ASSESSMENT: {
    typeName: "Assessment",
    fragment: "assessment",
    fragmentName: "assessmentNodeItem",
  },
  LEARNING_ENGAGEMENT: {
    typeName: "LearningEngagement",
    fragment: "le",
    fragmentName: "leNodeItem",
  },
};

const unitPlanFeedParentFragmentMapping = {
  STAFF: {
    typeName: "Staff",
    fragment: staffDetailUnitPlanFeedFragment.staffDetailUnitPlanFeed,
    fragmentName: "staffDetailUnitPlanFeedItem",
  },
  COURSE: {
    typeName: "Course",
    fragment: courseDetailUnitPlanFeedFragment.courseUnitPlanFeed,
    fragmentName: "courseFlatUnitPlanFeedItem",
  },
};

const unitPlanFeedCountParentFragmentMapping = {
  STAFF: {
    typeName: "Staff",
    fragment: staffDetailUnitPlanFeedFragment.staffUnitPlanFeedCount,
    fragmentName: "staffUnitPlanFeedCountItem",
  },
  COURSE: {
    typeName: "Course",
    fragment: courseDetailUnitPlanFeedFragment.courseUnitPlanFeedCount,
    fragmentName: "courseUnitPlanFeedCountItem",
  },
};

export const getPlannerResourcesOfNodeFromCache = ({ nodeId, type }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerResourcesOfNodeQuery,
      variables: {
        id: nodeId,
        type,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPlannerOrganizationResourcesOfNodeFromCache = ({
  nodeId,
  type,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerOrganizationResourcesOfNodeQuery,
      variables: {
        id: nodeId,
        type,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writePlannerResourcesOfNodeInCache = ({ nodeId, type, data }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;
    queryData = client.writeFragment({
      id: `${typeName}:${nodeId}`,
      fragment: resourceNodeFragment[fragment],
      fragmentName: fragmentName,
      data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getResourceFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PlannerResource:${id}`,
      fragment: resourcePlannerFragment.resource,
      fragmentName: "resourcePlannerItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeResourceInCache = ({ id, data }) => {
  let queryData = {};

  try {
    queryData = client.writeFragment({
      id: `PlannerResource:${id}`,
      fragment: resourcePlannerFragment.resource,
      fragmentName: "resourcePlannerItem",
      data: data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getUnitPlanFeedFromCache = ({
  id,
  filters,
  groupBy,
  type,
  showCollaborators = false,
  centralRepoInput,
}) => {
  let queryData = {};
  const {
    typeName,
    fragmentName,
    fragment,
  } = unitPlanFeedParentFragmentMapping[type];
  try {
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      variables: {
        filters,
        groupBy,
        showCollaborators,
        centralRepoInput,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getFlatUnitPlanFeedFromCache = ({
  id,
  first,
  orderBy,
  orderByDirection,
  filters,
  showCollaborators = false,
  centralRepoInput,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getFlatUnitPlanFeedQuery,
      variables: {
        id,
        filters,
        showCollaborators,
        first,
        orderBy,
        orderByDirection,
        centralRepoInput,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getFlatUnitPlanTitleFromCache = ({
  id,
  first,
  orderBy,
  orderByDirection,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getFlatUnitPlanTitleQuery,
      variables: {
        id,
        filters,
        first,
        orderBy,
        orderByDirection,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeFlatUnitPlanFeedInCache = ({
  id,
  first,
  orderBy,
  orderByDirection,
  filters,
  showCollaborators = false,
  centralRepoInput,
  data,
}) => {
  try {
    client.writeQuery({
      query: getFlatUnitPlanFeedQuery,
      variables: {
        id,
        filters,
        showCollaborators,
        first,
        orderBy,
        orderByDirection,
        centralRepoInput,
      },
      data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const writeUnitPlanFeedInCache = ({
  id,
  filters,
  data,
  groupBy,
  type,
  showCollaborators = true,
  centralRepoInput,
}) => {
  const {
    typeName,
    fragmentName,
    fragment,
  } = unitPlanFeedParentFragmentMapping[type];
  client.writeFragment({
    id: `${typeName}:${id}`,
    fragment,
    fragmentName,
    variables: {
      filters,
      groupBy,
      showCollaborators,
      centralRepoInput,
    },
    data,
  });
};

export const getUnitPlanCountFromCache = ({ id, filters, type }) => {
  let queryData = {};
  const {
    typeName,
    fragmentName,
    fragment,
  } = unitPlanFeedCountParentFragmentMapping[type];
  try {
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeUnitPlanCountInCache = ({ id, data, filters, type }) => {
  const {
    typeName,
    fragmentName,
    fragment,
  } = unitPlanFeedCountParentFragmentMapping[type];
  client.writeFragment({
    id: `${typeName}:${id}`,
    fragment,
    fragmentName,
    variables: {
      filters,
    },
    data,
  });
};

export const getUnitPlanResourceLibraryFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUnitPlanResourceLibraryQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeUnitPlanResourceLibraryInCache = ({ id, data, filters }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `UnitPlan:${id}`,
      fragment: unitPlanResourceLibraryFragment.unitPlanResourceLibrary,
      fragmentName: "unitPlanResourceLibraryItem",
      variables: { filters },
      data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

// export const getUnitPlanFeedFromCache = staffId => {
//   client.readFragment({
//     id: `Staff:${staffId}`,
//     fragment: staffDetailUnitPlanFeedFragment.staffDetailUnitPlanFeed,
//     fragmentName: "staffDetailUnitPlanFeedItem",
//   });
// };

// export const writeStaffDetailUnitPlanFeedFragment = ({ staffId, data }) => {
//   client.writeFragment({
//     id: `Staff:${staffId}`,
//     fragment: staffDetailUnitPlanFeedFragment.staffDetailUnitPlanFeed,
//     fragmentName: "staffDetailUnitPlanFeedItem",
//     data: data
//   });
// };

export const getFeedUnitPlanItemFromCache = ({
  id,
  showCollaborators = true,
  centralRepoInput,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `UnitPlan:${id}`,
      fragment: unitPlanFeedFragment.unitPlanFeed,
      fragmentName: "unitPlanItem",
      variables: {
        id,
        showCollaborators,
        centralRepoInput,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeFeedUnitPlanItemToCache = ({
  id,
  data,
  showCollaborators = true,
  centralRepoInput,
}) => {
  try {
    client.writeFragment({
      id: `UnitPlan:${id}`,
      fragment: unitPlanFeedFragment.unitPlanFeed,
      fragmentName: "unitPlanItem",
      variables: {
        id,
        showCollaborators,
        centralRepoInput,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getNodeUnitPlanFeedFromCache = ({
  id,
  type,
  filters,
  first,
  showCollaborators,
  centralRepoInput,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getNodeUnitPlanFeedQuery,
      variables: {
        id,
        type,
        filters,
        first,
        showCollaborators,
        centralRepoInput,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getUnitPlansFromCache = ({ ids, uids, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUnitPlansQuery,
      variables: {
        ids,
        uids,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "multiNode", []);
  else return {};
};

export const getPlannerElementNodeFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerElementNodeQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", []);
  else return {};
};

export const getAssessmentsFromCache = ({ ids }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssessmentsQuery,
      variables: {
        ids,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "multiNode", []);
  else return {};
};

export const getPlannerFieldResponsesFromCache = ({
  id,
  type,
  uids,
  responseFilter,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerFieldResponsesQuery,
      variables: {
        id,
        type,
        uids,
        responseFilter,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getNodeUnitPlanEvidenceFeedFromCache = ({
  id,
  filters,
  type,
  first,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getNodeUnitPlanEvidenceFeedQuery,
      variables: {
        id,
        type,
        filters,
        first,
      },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getPlannerFieldsOfNodeFromCache = params => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getPlannerFieldsOfNodeQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }

  return queryData;
};

export const getFlatUnitPlansFromCache = ({
  id,
  uids,
  orderByDirection,
  orderBy,
  first,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getFlatUnitPlansQuery,
      variables: {
        id,
        uids,
        orderByDirection,
        orderBy,
        filters,
        first,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getMypRepoOrganizationDetailsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getMypRepoOrganizationDetailsQuery,
      variables: params,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCentralRepoOrgDetailsFromCache = params => {
  try {
    const queryData = client.readQuery({
      query: getCentralRepoOrgDetailsQuery,
      variables: params,
    });
    return _.get(queryData, "node", {});
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const getStaffCoursesBasicDetailsQueryFromCache = ({
  id,
  type,
  filters,
  first,
  after,
}) => {
  try {
    const queryData = client.readQuery({
      query: getStaffCoursesBasicDetailsQuery,
      variables: {
        id,
        type,
        filters,
        first,
        after,
      },
    });
    return _.get(queryData, "node", {});
  } catch (e) {
    console.error(e);
    return {};
  }
};
