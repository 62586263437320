import React, { useState } from "react";
import classes from "./StandardList.scss";
import classNames from "classnames";

import { AnimateHeight } from "UIComponents";

import { CaretDownFilled, CaretUpFilled } from "@toddle-design/web-icons";
import { getPracticesIdsMemoized } from "./utils";
import { animateHeightStyle } from "./StandardListStyles";
import PracticeList from "./PracticeList";
const StandardList = props => {
  const [expandedStandards, setExpandedStandards] = useState([]);

  const {
    standards,
    updateSelectedPractices,
    areAllStandardsOpen,
    practiceListsCollection,
    itemDetails,
    cycleDetails,
  } = props;

  const { disabledPractices, selectedPractices } = practiceListsCollection;

  const updateExpandedStandards = ({ isOpen, id }) => {
    if (!isOpen) {
      setExpandedStandards(expandedStandards => [...expandedStandards, id]);
    } else {
      setExpandedStandards(expandedStandards =>
        _.filter(expandedStandards, standardId => standardId !== id)
      );
    }
  };

  return _.map(standards, ({ id, label, practices }) => {
    const isOpen = _.includes(expandedStandards, id) || areAllStandardsOpen;

    const practicesIds = getPracticesIdsMemoized({ practices });

    const selectedPracticesOfStandardCount = _.size(
      _.intersection(selectedPractices, practicesIds)
    );

    const disabledPracticesOfStandardCount = _.size(
      _.intersection(disabledPractices, practicesIds)
    );

    const totalCount =
      selectedPracticesOfStandardCount + disabledPracticesOfStandardCount;

    const ArrowComponent = isOpen ? CaretUpFilled : CaretDownFilled;

    return (
      <div className={classes.container} key={id}>
        <div onClick={() => updateExpandedStandards({ isOpen, id })}>
          <div className={classes.standardLabelContainer}>
            <div className={classes.standardLabel}>{`${label}`}</div>
            <div className={classes.rightContainer}>
              {totalCount > 0 && (
                <div className={classes.selectedCount}>{totalCount}</div>
              )}

              <ArrowComponent size={"xx-small"} variant={"subtle"} />
            </div>
          </div>
        </div>
        <AnimateHeight
          duration={300}
          height={isOpen ? "auto" : 0}
          style={animateHeightStyle}
        >
          <div className={classes.practiceList}>
            <PracticeList
              practices={practices}
              updateSelectedPractices={updateSelectedPractices}
              practiceListsCollection={practiceListsCollection}
              itemDetails={itemDetails}
              cycleDetails={cycleDetails}
            />
          </div>
        </AnimateHeight>
      </div>
    );
  });
};

export default StandardList;
