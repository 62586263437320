import { getMemoizedFunction } from "Utils";
import update from "immutability-helper";

export const getAddEvidenceModalTemplateMemoized = getMemoizedFunction(
  ({ template, curriculumProgramId }) => {
    const { evidenceOptions } = template;

    const updatedEvidenceOptions = _.map(evidenceOptions, ({ key, config }) => {
      const { shouldFilterByCurriculumProgram } = config;

      const updatedConfig = update(config, {
        filters: {
          curriculumProgramIds: shouldFilterByCurriculumProgram
            ? { $set: [curriculumProgramId] }
            : {},
        },
      });

      return {
        key,
        config: updatedConfig,
      };
    });

    return update(template, {
      evidenceOptions: { $set: updatedEvidenceOptions },
    });
  }
);
