import update from "immutability-helper";
import client from "apolloClient";
import { generateRandomId } from "Utils";

import {
  getChecklistElementFromCache,
  writeChecklistElementInCache,
  getOrganizationChecklistsFromCache,
  writeOrganizationChecklistInCache,
} from "./ChecklistGraphqlHelpers";

import {
  getAssessmentToolNodeDetailsFromCache,
  writeAssessmentToolNodeDetailsInCache,
} from "modules/CommonGraphqlHelpers";
import {
  createChecklistOptionItemMutation,
  createChecklistItemMutation,
  deleteChecklistOptionMutation,
  deleteChecklistItemMutation,
  updateChecklistItemMutation,
  updateChecklistOptionMutation,
  createChecklistMutation,
  deleteChecklistMutation,
} from "./ChecklistMutation";
import { setToastMsg } from "Login/modules/LoginModule";
import { getStaffBasicDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import {
  writeAssessmentToolOfParentInCache,
  getAssessmentToolOfParentFromCache,
} from "modules/AssessmentTool/AssessmentToolGraphqlHelpers";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import ACLStore from "lib/aclStore";

export const NAME = "rubric";

export const saveChecklist = ({
  copyToolId,
  mode = "CREATE",
  isGlobal = true,
  title = "",
  parentId,
  parentType,
  filters,
  curriculumProgramId,
} = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const checklistId = copyToolId;
    const copyChecklist = mode == "COPY";
    const organizationId = state.login.userInfo.org_id;

    const organizationDeatils = getOrganizationChecklistsFromCache({
      id: organizationId,
      filters,
    });

    const checklists = _.get(organizationDeatils, "checklists", []) || [];
    let checklist = getAssessmentToolNodeDetailsFromCache({
      id: checklistId,
      type: "CHECKLIST",
    });
    const checklistItems = [{ label: "" }];
    const checklistOptionItems = [{ label: "Yes" }, { label: "No" }];

    if (!copyChecklist) {
      checklist = {
        id: generateRandomId(),
        __typename: "Checklist",
        title: "",
        checklistItems: _.map(checklistItems, checklist => {
          return {
            ...checklist,
            id: generateRandomId(),
            __typename: "ChecklistItem",
          };
        }),
        checklistOptionItems: _.map(checklistOptionItems, checklist => {
          return {
            ...checklist,
            id: generateRandomId(),
            __typename: "ChecklistOptionItem",
          };
        }),
        createdBy: getStaffBasicDetailsFromCache(state.login.userInfo.id),
      };
    } else {
      checklist = {
        ...checklist,
        id: generateRandomId(),
        title,
        createdBy: getStaffBasicDetailsFromCache(state.login.userInfo.id),
      };
    }

    try {
      const result = await client.mutate({
        mutation: createChecklistMutation,
        variables: {
          title,
          copyChecklist,
          checklistId,
          isGlobal,
          createdBy: state.login.userInfo.id,
          checklistItems: checklistItems,
          checklistOptionItems: checklistOptionItems,
          parentId,
          parentType,
          ...(isGlobal && { curriculumProgramId }),
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //   planner: {
        //     __typename: "PlannerMutations",
        //     createChecklist: checklist
        //   }
        // },
        update: (
          cache,
          {
            data: {
              planner: { createChecklist },
            },
          }
        ) => {
          if (!_.isEmpty(createChecklist)) {
            if (isGlobal) {
              const data = {
                ...organizationDeatils,
                checklists: [...checklists, createChecklist],
              };

              setTimeout(() =>
                writeOrganizationChecklistInCache({
                  id: organizationId,
                  data,
                  filters,
                })
              );
            }
            let updatedAssessmentDetails = {};
            let parentAssessmentToolDetails;
            if (parentId) {
              parentAssessmentToolDetails = getAssessmentToolOfParentFromCache({
                id: parentId,
                type: parentType,
              });
              updatedAssessmentDetails = {
                ...parentAssessmentToolDetails,
                assessmentTool: createChecklist,
              };

              setTimeout(() =>
                writeAssessmentToolOfParentInCache({
                  id: parentId,
                  data: updatedAssessmentDetails,
                  type: parentType,
                })
              );
            }
          }
        },
      });

      if (!ACLStore.can("FeatureFlag:AssessmentCreationRevamp")) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:successfully_added_checklist_template",
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      }
      return _.get(result, "data.planner.createChecklist.id", null);
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      console.error(e);
      return null;
    }
  };
};

export const createChecklistItem = ({ label = "", checklistId }) => {
  return async (dispatch, getState) => {
    const checkList = getAssessmentToolNodeDetailsFromCache({
      id: checklistId,
      type: "CHECKLIST",
    });

    try {
      await client.mutate({
        mutation: createChecklistItemMutation,
        variables: {
          checklistId,
          label,
          createdBy: getState().login.userInfo.id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createChecklistItem: {
              id: generateRandomId(),
              label,
              __typename: "ChecklistItem",
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createChecklistItem },
            },
          }
        ) => {
          let checklistItems = _.get(checkList, "checklistItems", []);

          if (!checklistItems) {
            checklistItems = [];
          }
          checklistItems = [...checklistItems, createChecklistItem];
          const data = {
            ...checkList,
            checklistItems,
          };

          setTimeout(() =>
            writeAssessmentToolNodeDetailsInCache({
              id: checklistId,
              data,
              type: "CHECKLIST",
            })
          );
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const createChecklistOptionItem = ({ label = "", checklistId }) => {
  return async (dispatch, getState) => {
    const checkList = getAssessmentToolNodeDetailsFromCache({
      id: checklistId,
      type: "CHECKLIST",
    });
    try {
      await client.mutate({
        mutation: createChecklistOptionItemMutation,
        variables: {
          checklistId,
          label,
          createdBy: getState().login.userInfo.id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createChecklistOptionItem: {
              id: generateRandomId(),
              label,
              __typename: "ChecklistOptionItem",
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createChecklistOptionItem },
            },
          }
        ) => {
          let checklistOptionItems = _.get(
            checkList,
            "checklistOptionItems",
            []
          );
          if (!checklistOptionItems) {
            checklistOptionItems = [];
          }
          checklistOptionItems = [
            ...checklistOptionItems,
            createChecklistOptionItem,
          ];
          const data = {
            ...checkList,
            checklistOptionItems,
          };
          setTimeout(() =>
            writeAssessmentToolNodeDetailsInCache({
              id: checklistId,
              data,
              type: "CHECKLIST",
            })
          );
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteChecklist = ({ id, filters }) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;

    const organizationDeatils = getOrganizationChecklistsFromCache({
      id: organizationId,
      filters,
    });
    const checklists = _.get(organizationDeatils, "checklists", []);
    try {
      await client.mutate({
        mutation: deleteChecklistMutation,
        variables: {
          id: id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteChecklist: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteChecklist },
            },
          }
        ) => {
          if (deleteChecklist) {
            const updatedChecklists = _.filter(
              checklists,
              checklist => checklist.id != id
            );
            const data = {
              ...organizationDeatils,
              checklists: updatedChecklists,
            };

            setTimeout(() =>
              writeOrganizationChecklistInCache({
                id: organizationId,
                data,
                filters,
              })
            );
          }
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:successfully_deleted_checklist_template",
          type: "tick",
          position: "toast-bottom-right",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      setTimeout(() =>
        writeOrganizationChecklistInCache({
          id: organizationId,
          data: organizationDeatils,
          filters,
        })
      );
      console.error(e);
    }
  };
};

export const deleteChecklistOption = ({ checklistId, optionId }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: deleteChecklistOptionMutation,
        variables: {
          id: optionId,
          deletedBy: getState().login.userInfo.id,
          checklistId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteChecklistOption: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteChecklistOption },
            },
          }
        ) => {
          if (deleteChecklistOption) {
            let checkList = getAssessmentToolNodeDetailsFromCache({
              id: checklistId,
              type: "CHECKLIST",
            });

            let checklistOptionItems = _.get(
              checkList,
              "checklistOptionItems",
              []
            );
            checklistOptionItems = _.filter(
              checklistOptionItems,
              item => item.id != optionId
            );
            checkList = {
              ...checkList,
              checklistOptionItems,
            };

            setTimeout(() =>
              writeAssessmentToolNodeDetailsInCache({
                id: checklistId,
                data: checkList,
                type: "CHECKLIST",
              })
            );
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteChecklistItem = ({ checklistId, itemId }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: deleteChecklistItemMutation,
        variables: {
          id: itemId,
          deletedBy: getState().login.userInfo.id,
          checklistId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteChecklistItem: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteChecklistItem },
            },
          }
        ) => {
          if (deleteChecklistItem) {
            let checkList = getAssessmentToolNodeDetailsFromCache({
              id: checklistId,
              type: "CHECKLIST",
            });
            let checklistItems = _.get(checkList, "checklistItems", []);
            checklistItems = _.filter(
              checklistItems,
              item => item.id != itemId
            );
            checkList = {
              ...checkList,
              checklistItems,
            };

            setTimeout(() =>
              writeAssessmentToolNodeDetailsInCache({
                id: checklistId,
                data: checkList,
                type: "CHECKLIST",
              })
            );
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateChecklistElement = ({ id, label, type }) => {
  return async (dispatch, getState) => {
    try {
      let mutation = "";
      switch (type) {
        case "CHECKLIST_ITEM":
          mutation = updateChecklistItemMutation;
          break;
        case "CHECKLIST_OPTION":
          mutation = updateChecklistOptionMutation;
      }
      await client.mutate({
        mutation,
        variables: {
          label,
          updatedBy: getState().login.userInfo.id,
          id,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const writeChecklistElementLocal = ({
  id,
  type,
  label,
  elementDetails,
}) => {
  return async (dispatch, getState) => {
    const updatedElementDetails = { ...elementDetails, label };

    writeChecklistElementInCache({ id, type, data: updatedElementDetails });
  };
};

const REDUCER_HANDLERS = {};

const initialState = {
  rubricData: {
    id: null,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
