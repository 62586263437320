import React from "react";
import { I18nHOC } from "UIComponents";
import classes from "./Explore.scss";
import { CLOUD_URL } from "Constants";
import { compose } from "react-apollo";
import SignupButton from "../SignupButton";

const leOptions = ({ t }) => [
  {
    // urlSm: `${CLOUD_URL}/assets/webapp/community_paccess/LE_1_sm_v1.png`,
    url: `${CLOUD_URL}/assets/webapp/community_paccess/LE_1.png`,
    title: t("community:explore_le_1"),
  },
  {
    // urlSm: `${CLOUD_URL}/assets/webapp/community_paccess/LE_2_sm_v1.png`,
    url: `${CLOUD_URL}/assets/webapp/community_paccess/LE_2.png`,
    title: t("community:explore_le_2"),
  },
  {
    // urlSm: `${CLOUD_URL}/assets/webapp/community_paccess/LE_3_sm_v1.png`,
    url: `${CLOUD_URL}/assets/webapp/community_paccess/LE_3.png`,
    title: t("community:explore_le_3"),
  },
];

const profileOptions = ({ t }) => [
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/Profile_1.png`,
    title: t("community:explore_profile_1"),
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/Profile_2.png`,
    title: t("community:explore_profile_2"),
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/Profile_3.png`,
    title: t("community:explore_profile_3"),
  },
];

const Explore = ({ t, type }) => {
  const title =
    type === "learning_experience"
      ? t("community:public_access_explore_le")
      : t("community:public_access_explore_profile");

  const options = type === "profile" ? profileOptions : leOptions;

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.header}>{title}</div>

        <div className={classes.itemsContainer}>
          {_.map(options({ t }), ({ url, urlSm, title }, index) => (
            <div key={index} className={classes.item}>
              <div className={classes.imageContainer}>
                {/* <picture> */}
                {/* <source srcSet={urlSm} media="(max-width: 700px)" /> */}
                <img src={url} />
                {/* </picture> */}
              </div>
              <div className={classes.itemTitle}>{title}</div>
            </div>
          ))}
        </div>

        <SignupButton type={type} />
        {/* <div className={classes.divider} /> */}
      </div>
    </div>
  );
};

export default compose(I18nHOC({ resource: ["community", "common"] }))(Explore);
