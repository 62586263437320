import React, { Component } from "react";
import PropTypes from "prop-types";
import classes from "./SearchBar.scss";
import { CancelIcon, SearchIcon } from "SvgComponents";
import classNames from "classnames";
import { colors } from "Constants";
export default class SearchBarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputTextState: false,
      searchText: "",
    };
    const { debounceDuration } = props;
    this.searchInput = "";
    this.onUpdateSearchText = _.debounce(
      this.onUpdateSearchTextOnDebounce,
      debounceDuration
    );
  }

  componentDidMount() {
    if (this.props.searchTerm) {
      this.setState({
        inputTextState: true,
        searchText: this.props.searchTerm,
      });
    }

    if (this.props.initialFocusOn && this.searchInput) {
      this.searchInput.focus();
    }
  }

  updateSearchText = value => {
    this.setState({
      searchText: value,
    });
  };

  handleKeyPress = e => {
    if (e.key === "Enter" && this.props.searchOnEnter) {
      this.onUpdateSearchText();
    }
    if (e.key === "Enter" && this.props.onPressEnter) this.props.onPressEnter();
  };

  handleKeyDown = e => {
    const { handleKeyDown, clearSearchTermOnEnter } = this.props;
    if (handleKeyDown) {
      handleKeyDown(e);
    }
    if (clearSearchTermOnEnter && e.key == "Enter") this.updateSearchText("");
  };

  searchUpdate = e => {
    this.setState({ searchText: e.target.value });
    if (!this.props.searchOnEnter) this.onUpdateSearchText();
  };

  onUpdateSearchTextOnDebounce = () => {
    this.props.changeSearchTerm(this.state.searchText);
  };

  updateInputState = value => {
    this.setState({ inputTextState: value });
  };

  onFocus = e => {
    e.stopPropagation();
    this.updateInputState(true);
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  onBlur = e => {
    const { showCancelOnEmptyAlso } = this.props;
    e.stopPropagation();
    if (!showCancelOnEmptyAlso && !this.props.searchTerm) {
      this.updateInputState(false);
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  focusInput = val => {
    this.updateInputState(val);
    if (val && this.searchInput) {
      this.searchInput.focus();
    }
  };
  toggleInputTextState = e => {
    if (this.props.searchTerm) {
      this.focusInput(true);
      e.stopPropagation();
    } else {
      this.focusInput(!this.state.inputTextState);
      e.stopPropagation();
    }

    if (this.props.onSearchBarClick) {
      this.props.onSearchBarClick();
    }
  };

  onClickInput = e => {
    if (this.props.onSearchBarClick) {
      e.stopPropagation();
      this.props.onSearchBarClick();
    }
  };

  cancelText = e => {
    const { onCloseClick } = this.props;
    if (onCloseClick) {
      e.stopPropagation;
      onCloseClick();
    }
    this.setState({ searchText: "" });
    this.props.changeSearchTerm("");
    this.updateInputState(false);
  };

  closeSearchInput = () => {
    if (!this.searchInput.value && this.searchInput) {
      this.updateInputState(false);
    }
  };

  render() {
    const { searchText: searchTextState, inputTextState } = this.state;
    const { searchTerm: searchTextProps, isSearchTextInState } = this.props;
    const searchText = isSearchTextInState ? searchTextState : searchTextProps;

    const {
      shouldAnimate,
      placeholder,
      customStyle,
      activeCustomStyle,
      searchSvgStyle,
      autoComplete,
      wrapperType,
      showSearchIcon,
      inputTextStyle,
      renderLeftComponent,
      renderRightComponent,
      error,
      searchIconStyle,
      showCancelIcon,
      showCancelOnEmptyAlso,
    } = this.props;
    let showCancelIconLocalState = searchText;
    if (showCancelOnEmptyAlso) showCancelIconLocalState = inputTextState;

    const deleteSvgClass = classNames(
      { [classes.crossSvg]: showCancelIconLocalState },
      { [classes.crossSvgHidden]: !showCancelIconLocalState }
    );
    const inputEnable = !!inputTextState && shouldAnimate;
    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.searchInputActive]: inputEnable },
      { [classes.searchInput]: !inputEnable },
      { [classes.notAnimateSearchInput]: !shouldAnimate },
      { [classes.containerBox]: wrapperType == "box" },
      { [classes.error]: !!error }
    );
    const activeStyle = inputEnable ? activeCustomStyle : {};
    const containerStyle = {
      ...customStyle,
      ...activeStyle,
    };

    return (
      <div className={containerClass} style={containerStyle}>
        {!!showSearchIcon && (
          <div
            className={classes.searchSvg}
            onClick={event => this.toggleInputTextState(event)}
            style={searchSvgStyle}
          >
            <SearchIcon {...searchIconStyle} />
          </div>
        )}
        {renderLeftComponent ? renderLeftComponent() : null}
        <input
          data-test-id={this.props["data-test-id"]}
          ref={ref => (this.searchInput = ref)}
          className={classes.inputText}
          style={inputTextStyle}
          type="text"
          placeholder={placeholder}
          value={searchText}
          onChange={e => this.searchUpdate(e)}
          name="text"
          onClick={e => this.onClickInput(e)}
          onFocus={e => this.onFocus(e)}
          onBlur={e => this.onBlur(e)}
          autoComplete={autoComplete}
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.handleKeyDown}
        />
        {showCancelIcon && (
          <div className={deleteSvgClass} onClick={this.cancelText}>
            <CancelIcon
              width={12}
              height={12}
              fill={inputTextState ? colors.gray31 : colors.gray48}
            />
          </div>
        )}
        {renderRightComponent ? renderRightComponent() : null}
      </div>
    );
  }
}

SearchBarContainer.propTypes = {
  searchTerm: PropTypes.string,
  changeSearchTerm: PropTypes.func,
  placeholder: PropTypes.string,
  onSearchBarClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  customStyle: PropTypes.object,
  shouldAnimate: PropTypes.bool,
  activeCustomStyle: PropTypes.object,
  autoComplete: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  searchOnEnter: PropTypes.bool,
  renderLeftComponent: PropTypes.func,
  renderRightComponent: PropTypes.func,
  showCancelOnEmptyAlso: PropTypes.bool,
  debounceDuration: PropTypes.number,
  clearSearchTermOnEnter: PropTypes.bool,
};

SearchBarContainer.defaultProps = {
  shouldAnimate: false,
  autoComplete: "off",
  showSearchIcon: true,
  showCancelIcon: true,
  searchOnEnter: false,
  inputTextStyle: {},
  isSearchTextInState: true,
  searchIconStyle: { width: 16, height: 16, fill: colors.gray48 },
  showCancelOnEmptyAlso: false,
  debounceDuration: 200,
  clearSearchTermOnEnter: false,
  ["data-test-id"]: "search-bar",
};
