import React from "react";
import classes from "./Guidelines.scss";
import { I18nHOC } from "UIComponents";

const unitGuidelines = [
  "cover_image_guideline",
  "complete_guideline",
  "minimun_le_guideline",
  "le_image_guideline",
  "authenticity_guideline",
];

const leGuidelines = [
  "name_guideline",
  "le_complete_guideline",
  "clear_instructions_guideline",
  "audio_guideline",
];

const agreements = [
  "ownership_agreement",
  "modify_agreement",
  "unpublish_agreement",
];

const Guidelines = ({ t, entityType }) => {
  const guidelines = entityType === "UNIT_PLAN" ? unitGuidelines : leGuidelines;
  const agreementSubtext =
    entityType === "UNIT_PLAN"
      ? "community:unit_agreeing_subtext"
      : "community:le_agreeing_subtext";

  return (
    <div className={classes.guidelinesContainer}>
      <div className={classes.header}>{t("sharing_guidelines")}</div>
      <div className={classes.guidelinesSubText}>
        {t("guideline_subtext", {
          label: t(
            entityType === "UNIT_PLAN"
              ? "community:unit"
              : "community:learning_experience"
          ),
        })}
      </div>
      <div className={classes.checkListContainer}>
        {_.map(guidelines, guideline => {
          return (
            <div className={classes.checkListItem} key={guideline}>
              <div className={classes.checkIconContainer}></div>
              <div className={classes.checkListItemText}>{t(guideline)}</div>
            </div>
          );
        })}
      </div>

      <div className={classes.subText}>
        {t("resource_subtext_label", {
          label: t(
            entityType === "UNIT_PLAN"
              ? "community:unit"
              : "community:learning_experience"
          ),
        })}
      </div>

      <div className={classes.subText}>{t(agreementSubtext)}</div>
      <div className={classes.checkListContainer}>
        {_.map(agreements, agreement => {
          return (
            <div className={classes.checkListItem} key={agreement}>
              <div className={classes.checkIconContainer}></div>
              <div className={classes.checkListItemText}>
                {t(agreement, {
                  label: t(
                    entityType === "UNIT_PLAN"
                      ? "community:unit"
                      : "community:learning_experience"
                  ),
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.guidelineConditions}>
        {t("guidelines_conditions", {
          label: t(
            entityType === "UNIT_PLAN" ? "community:unit" : "community:resource"
          ),
        })}
      </div>
    </div>
  );
};

export default I18nHOC({ resource: "community" })(Guidelines);
