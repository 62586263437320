import React from "react";
import classes from "./NotificationCenter.scss";
import { I18nHOC } from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { notificationCenterReadForUserMutation } from "modules/Services";
import ListContainer from "AppComponents/NotificationCenter/ListContainer";

const NotificationCenter = React.memo(props => {
  const { userId, t, notificationCenterReadForUserMutation } = props;

  return (
    <div className={classes.container}>
      <div className={classes.notificationHeader}>
        <div className={classes.headerText}>
          {t("announcement:notifications")}
        </div>
        <div
          className={classes.markAllReadText}
          onClick={() => notificationCenterReadForUserMutation(userId)}
        >
          {t("common:mark_all_read")}
        </div>
      </div>

      {/* <div className={classes.notificationList}></div> */}
      <ListContainer activeTab={"COMMUNITY"} showNotificationCenter={true} />
    </div>
  );
});

const mapActionCreators = {
  notificationCenterReadForUserMutation,
};

const mapStateToProps = (state, ownProps) => {
  const userId = state.login.userInfo.id;

  return {
    userId: userId,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "announcement"] }),
  connect(mapStateToProps, mapActionCreators)
)(NotificationCenter);
