import PropTypes from "prop-types";
import React from "react";

const FileIcon = props => {
  return (
    <svg viewBox="0 0 16 16" width={props.width} height={props.height}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-128.000000, -8482.000000)">
          <g transform="translate(128.000000, 8482.000000)">
            <polygon
              fill={props.fill}
              points="4 11.73578 12 11.73578 12 10.4 4 10.4"
            />
            <polygon
              fill={props.fill}
              points="9.42350973 8.73578 12.0035097 8.73578 12.0035097 7.4 9.42350973 7.4"
            />
            <polygon
              fill={props.fill}
              points="9.42350973 5.73578 12.0035097 5.73578 12.0035097 4.4 9.42350973 4.4"
            />
            <rect
              id="Rectangle"
              stroke={props.fill}
              strokeWidth="1.34"
              x="4.67"
              y="5.07"
              width="3"
              height="3"
            />
            <path
              d="M10.58808,0 L1.63567,0 C0.75138,0 0.03273,0.7187 0.03273,1.6029 L0.03273,14.39706 C0.03273,15.28135 0.75138,16 1.63567,16 L14.36433,16 C15.24862,16 15.96727,15.28135 15.96727,14.39706 L15.96727,5.1788 C15.96727,4.6672 15.72683,4.1102 15.35549,3.7589 L11.96794,0.549 C11.61529,0.2151 11.07297,0 10.58808,0 M10.58808,1.3358 C10.73501,1.3358 10.94206,1.4186 11.04892,1.5201 L14.4378,4.7287 C14.54467,4.8289 14.63149,5.0319 14.63149,5.1788 L14.63149,14.39706 C14.63149,14.544 14.51127,14.66422 14.36433,14.66422 L1.63567,14.66422 C1.48873,14.66422 1.36851,14.544 1.36851,14.39706 L1.36851,1.6029 C1.36851,1.456 1.48873,1.3358 1.63567,1.3358 L10.58808,1.3358"
              id="Fill-228"
              fill={props.fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

FileIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

FileIcon.defaultProps = {
  height: 16,
  width: 16,
  fill: "#000000",
};

export default FileIcon;
