import { withAsyncRouteLoading } from "UIComponents";
export default store => ({
  path: ":template_id",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "progress-report"
        );
      })
  ),
});
