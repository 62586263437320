import { withAsyncRouteLoading } from "UIComponents";
import LearningStandards from "./routes/DPLearningStandards";
import ScoreCategories from "ScoreCategories";
import DPSubjects from "./routes/DPSubjects";
import TemplateGradeMapping from "Administrator/commonComponents/TemplateGradeMapping";
import { updateChildRoutes } from "Administrator/modules/AdministratorModule";

export default store => ({
  path: "dpAcademicSetup",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
  onEnter(nextState) {
    const { routes } = nextState;
    store.dispatch(
      updateChildRoutes({
        routes,
        path: "dpAcademicSetup",
        pathId: "DP_ACADEMIC_SETUP",
      })
    );
  },
  childRoutes: [
    ScoreCategories(store),
    DPSubjects(store),
    LearningStandards(store),
    TemplateGradeMapping(store),
  ],
});
