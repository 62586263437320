import React from "react";
import classes from "./CheckListView.scss";
import { connect } from "react-redux";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";

import {
  createChecklistItem,
  createChecklistOptionItem,
  deleteChecklistOption,
  deleteChecklistItem,
  writeChecklistElementLocal,
} from "Checklist/modules/ChecklistModule";

import { updateField } from "modules/Services";
import CheckListViewRow from "./CheckListViewRow";

const MIN_OPTION_COUNT = 2;
const MAX_OPTION_COUNT = 4;

const CheckListComp = ({
  checklistItems,
  checklistOptionItems,
  toolEvaluationResponses,
  updateEvaluationCell,
  isEvaluation,
  showInsight,
  isEvaluatedByStudent,
  insights,
  insightColor,
  t,
}) => {
  return (
    <div className={classes.checkListContainer}>
      <CheckListViewRow
        toolEvaluationResponses={toolEvaluationResponses}
        key={"temp"}
        header={true}
        label={t("assessmentEvaluation:indicator")}
        showInsight={showInsight}
        options={checklistOptionItems}
        index={0}
        minOptionCount={MIN_OPTION_COUNT}
        id={"temp"}
        isEvaluatedByStudent={isEvaluatedByStudent}
      />
      {_.map(checklistItems, (item, index) => {
        return (
          <CheckListViewRow
            updateEvaluationCell={updateEvaluationCell}
            toolEvaluationResponses={toolEvaluationResponses}
            key={item.id}
            label={item.label}
            insights={insights}
            showInsight={showInsight}
            insightColor={insightColor}
            rowId={item.id}
            options={checklistOptionItems}
            index={index}
            minOptionCount={MIN_OPTION_COUNT}
            id={item.id}
            isEvaluation={isEvaluation}
            isEvaluatedByStudent={isEvaluatedByStudent}
          />
        );
      })}
    </div>
  );
};
class CheckListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showToolList: false };
  }

  render() {
    const {
      showInsight,
      checklist,
      toolEvaluationResponses,
      updateEvaluationCell,
      isEvaluation,
      isEvaluatedByStudent,
      insights,
      insightColor,
      containerStyle,
      t,
    } = this.props;

    if (!checklist) {
      return (
        <div className={classes.emptyText}>
          {t("assessmentEvaluation:no_checklist_defined")}
        </div>
      );
    }

    const { checklistItems, checklistOptionItems } = checklist;

    return (
      <div className={classes.container} style={containerStyle}>
        <CheckListComp
          t={t}
          showInsight={showInsight}
          updateEvaluationCell={updateEvaluationCell}
          insights={insights}
          insightColor={insightColor}
          isEvaluation={isEvaluation}
          checklistItems={checklistItems}
          toolEvaluationResponses={toolEvaluationResponses}
          checklistOptionItems={checklistOptionItems}
          isEvaluatedByStudent={isEvaluatedByStudent}
        />
      </div>
    );
  }
}

const mapActionCreators = {
  createChecklistItem,
  createChecklistOptionItem,
  deleteChecklistOption,
  deleteChecklistItem,
  writeChecklistElementLocal,
  updateField,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  I18nHOC({ resource: "assessmentEvaluation" }),
  connect(mapStateToProps, mapActionCreators)
)(CheckListView);
