import { EvidenceCardV2 } from "AppComponents";
import React, { Fragment } from "react";
import classes from "./OrganizationResourcesFeed.scss";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const OrganizationResourcesFeed = props => {
  const {
    items,
    selectedItems,
    updateSelectedItems,

    styles,
    disabledItems,
    customHeaderElement,

    cardWrapperElement,

    cardType,

    types,
  } = props;

  const checkIsSelected = ({ id }) => {
    return _.includes(selectedItems, id);
  };

  const checkIsDisabled = ({ id }) => {
    return _.includes(disabledItems, id);
  };

  const handleCardClick = ({ id }) => {
    const isSelected = checkIsSelected({ id });

    // de-select if selected
    const currentSelection = isSelected
      ? _.difference(selectedItems, [id])
      : [...selectedItems, id];

    updateSelectedItems(currentSelection);
  };

  const renderResourceFeed = () => {
    return (
      <Fragment>
        {customHeaderElement
          ? React.cloneElement(customHeaderElement, {
              count: _.size(items),
            })
          : null}

        <div className={classes.feedContainer}>
          {_.map(items, item => {
            const id = _.get(item, "id", "");
            const isSelected = checkIsSelected({ id });
            const isDisabled = checkIsDisabled({ id });

            const additionalProps = cardWrapperElement
              ? { data: item, isSelected, onCardClick: handleCardClick }
              : {};

            return (
              <ConditionalWrapper
                key={id}
                type="wrapperAsElement"
                wrapper={cardWrapperElement}
                {...additionalProps}
                condition={!!cardWrapperElement}
              >
                <EvidenceCardV2
                  isHeadingClickable={false}
                  key={id}
                  id={id}
                  isDisabled={isDisabled}
                  type={_.first(types)}
                  data={item}
                  showFooterActions={false}
                  styles={_.get(styles, "cardStyles", {})}
                  isSelected={isSelected}
                  cardType={cardType}
                  hideDefaultActions={!!cardWrapperElement}
                  onClickCard={({ data }) => handleCardClick({ id: data.id })}
                />
              </ConditionalWrapper>
            );
          })}
        </div>
      </Fragment>
    );
  };

  return <div className={classes.container}>{renderResourceFeed()}</div>;
};

export default OrganizationResourcesFeed;
