import React from "react";
import classes from "./HeroComponent.scss";
import { UIButton, I18nHOC } from "UIComponents";

import { CancelIcon, ScrollDownSvg, PremiumIcon } from "SvgComponents";
import ImageCarousel from "../ImageCarousel";

const HeroComponent = React.memo(props => {
  const {
    heading,
    subHeading,
    images,
    isAdmin,
    goToBack,
    isFullScreen,
    createPlanUpgradeRequest,
    showCancelIcon = false,
    showScrollDownIcon = false,
    onClickScrollDown,
    onSeePlansClicked,
    t,
  } = props;
  return (
    <div className={classes.container}>
      {showCancelIcon && (
        <div className={classes.cancelIcon} onClick={goToBack}>
          <CancelIcon width={16} heigth={16} />
        </div>
      )}
      <div
        className={classes.wrapperContainer}
        style={isFullScreen ? { height: 680 } : {}}
      >
        <div className={classes.premiumContainer}>
          <PremiumIcon></PremiumIcon>
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.textContainer}>
            <div className={classes.topTextContainer}>
              <div className={classes.heading}>{t(heading)}</div>

              <div className={classes.subHeading}>{t(subHeading)}</div>
            </div>
            <div className={classes.bottomTextContainer}>
              {isAdmin ? (
                <React.Fragment>
                  <div className={classes.upgradeContainer}>
                    <div className={classes.upgradeText}>
                      {t("blockScreen:upgrade_msg")}
                    </div>
                    <div
                      onClick={onSeePlansClicked}
                      className={classes.plansText}
                    >
                      {t("blockScreen:see_our_plans")}
                    </div>
                  </div>
                  <div className={classes.upgradeButton}>
                    <UIButton
                      size={"lg"}
                      color={"pink"}
                      onClick={createPlanUpgradeRequest}
                    >
                      {t("blockScreen:schedule_a_meeting")}
                    </UIButton>
                  </div>
                </React.Fragment>
              ) : (
                <div className={classes.teacherText}>
                  {t("blockScreen:premium_feature_msg")}
                </div>
              )}
            </div>
          </div>
          <div className={classes.imageContainer}>
            <ImageCarousel
              images={images}
              imageClass={classes.image}
            ></ImageCarousel>
          </div>
        </div>
      </div>
      {showScrollDownIcon && (
        <div className={classes.scrollDownIcon} onClick={onClickScrollDown}>
          <ScrollDownSvg></ScrollDownSvg>
        </div>
      )}
    </div>
  );
});

export default I18nHOC({ resource: "blockScreen" })(HeroComponent);
