import React from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";

const CardsView = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 100 100"
      fill={props.fill && props.fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.375 18.75C57.6491 18.75 56.25 20.1491 56.25 21.875V40.625C56.25 42.3509 57.6491 43.75 59.375 43.75H78.125C79.8509 43.75 81.25 42.3509 81.25 40.625V21.875C81.25 20.1491 79.8509 18.75 78.125 18.75H59.375ZM59.375 56.25C57.6491 56.25 56.25 57.6491 56.25 59.375V78.125C56.25 79.8509 57.6491 81.25 59.375 81.25H78.125C79.8509 81.25 81.25 79.8509 81.25 78.125V59.375C81.25 57.6491 79.8509 56.25 78.125 56.25H59.375ZM18.75 59.375C18.75 57.6491 20.1491 56.25 21.875 56.25H40.625C42.3509 56.25 43.75 57.6491 43.75 59.375V78.125C43.75 79.8509 42.3509 81.25 40.625 81.25H21.875C20.1491 81.25 18.75 79.8509 18.75 78.125V59.375ZM21.875 18.75C20.1491 18.75 18.75 20.1491 18.75 21.875V40.625C18.75 42.3509 20.1491 43.75 21.875 43.75H40.625C42.3509 43.75 43.75 42.3509 43.75 40.625V21.875C43.75 20.1491 42.3509 18.75 40.625 18.75H21.875Z"
      />
    </svg>
  );
};

CardsView.defaultProps = {
  height: 32,
  width: 32,
};
CardsView.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
};
export default CardsView;
