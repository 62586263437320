import { connect } from "react-redux";
import component from "../components";
import { ApolloProvider, graphql, compose, withApollo } from "react-apollo";
import { getResourceListQuery } from "./Queries";
import {
  initaliseResourceBankComponent,
  updateResourceBankFilters,
  toggleResourceBankFilterItem,
  toggleAllResourceBankFilterItems,
  toggleResourceBankSelectedResources,
  clearResourceBankSelectedResources,
  addUnsplashAnalytics,
} from "modules/ResourceBankModule";

import {
  downloadFromUrl,
  uploadFile,
  removeProgressElement,
} from "modules/Services";

import { I18nHOC } from "UIComponents";

const mapActionCreators = {
  initaliseResourceBankComponent,
  updateResourceBankFilters,
  toggleResourceBankFilterItem,
  toggleAllResourceBankFilterItems,
  toggleResourceBankSelectedResources,
  clearResourceBankSelectedResources,
  downloadFromUrl,
  uploadFile,
  removeProgressElement,
  addUnsplashAnalytics,
};

const mapStateToProps = (state, ownProps) => {
  let id = _.get(ownProps, "id", "default");
  return {
    filters: _.get(
      state.resourceBank.resourceBank[id],
      "filters",
      state.resourceBank.resourceBank.default.filters
    ),
    selectedResources: _.get(
      state.resourceBank.resourceBank[id],
      "selectedResources",
      []
    ),
    id: id,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "common" }),
  withApollo,
  graphql(getResourceListQuery, {
    name: "getResourceList",
    options: ({ filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: { filters },
    }),
    props: ({ getResourceList, ownProps }) => {
      return {
        getResourceList,
        ownProps,
        isData: !_.isEmpty(getResourceList, "platform.resourceList", {}),
        isLoading:
          getResourceList.networkStatus == 1 ||
          getResourceList.networkStatus == 2 ||
          getResourceList.networkStatus == 4,
        loadMoreListData: () => {
          return getResourceList.fetchMore({
            query: getResourceListQuery,
            variables: {
              filters: ownProps.filters,
              stateInformation: _.get(
                getResourceList,
                "platform.resourceList.stateInformation",
                ""
              ),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousResources = _.get(
                previousResult,
                "platform.resourceList.resources",
                []
              );
              const newResources = _.get(
                fetchMoreResult,
                "platform.resourceList.resources",
                []
              );
              const newStateInformation = _.get(
                fetchMoreResult,
                "platform.resourceList.stateInformation",
                ""
              );
              const isMore = _.get(
                fetchMoreResult,
                "platform.resourceList.isMore",
                false
              );

              return {
                ...previousResult,
                platform: {
                  // By returning `cursor` here, we update the `loadMore` function
                  // to the new cursor.
                  ...previousResult.platform,
                  resourceList: {
                    ...previousResult.platform.resourceList,
                    isMore: isMore,
                    stateInformation: newStateInformation,
                    // Put the new resources in the front of the list
                    resources: [...previousResources, ...newResources],
                  },
                },
              };
            },
          });
        },
      };
    },
  })
)(component);
