import React from "react";
import classes from "./OrganizationCreateModal.scss";

import OrganizationModal from "../OrganizationModal";

const steps = [
  {
    id: "name",
    label: "Name",
    ref: "name",
    name: "name",
    placeholder: "Enter organization name",
    validationCheck: true,
    type: "text",
  },
  {
    id: "domains",
    label: "Domains",
    ref: "domains",
    name: "domains",
    placeholder: "Enter Domains",
    multi: true,
    allowAddOption: true,
    type: "selectDropDown",
  },

  {
    id: "city",
    label: "City",
    ref: "city",
    name: "city",
    placeholder: "Enter city",
    type: "text",
  },
  {
    id: "country",
    label: "Country",
    ref: "country",
    name: "country",
    placeholder: "Enter country",
    type: "text",
  },
  {
    id: "ibCode",
    label: "IB Code",
    ref: "ibCode",
    name: "ibCode",
    placeholder: "Enter IB code",
    type: "text",
  },

  // {
  //   id: "adminFirstName",
  //   label: "Admin First Name",
  //   ref: "adminFirstName",
  //   name: "adminFirstName",
  //   placeholder: "Enter First Name",
  //   type: "text"
  // },
  // {
  //   id: "adminFirstName",
  //   label: "Admin Last Name",
  //   ref: "adminFirstName",
  //   name: "adminLastName",
  //   placeholder: "Enter Last Name",
  //   type: "text"
  // },
  // {
  //   id: "email",
  //   label: "Admin Email",
  //   ref: "email",
  //   name: "email",
  //   placeholder: "Enter Email",
  //   type: "text"
  // }
];

class OrganizationEditModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  onSaveClick = () => {
    const { onSaveClick } = this.props;
    if (onSaveClick) {
      onSaveClick(this.state.data);
    }
  };

  getOptions = key => {
    const { data } = this.state;

    switch (key) {
      default:
        return undefined;
    }
  };

  render() {
    const { data, onClose, onSaveClick } = this.props;
    return (
      <OrganizationModal
        title={"Add organization"}
        data={{}}
        getOptions={this.getOptions}
        steps={steps}
        onClose={onClose}
        onSaveClick={onSaveClick}
      />
    );
  }
}

OrganizationEditModal.defaultProps = {
  modalTitle: "Duplicate the Unit",
  confirmButtonName: "Duplicate",
};

export default OrganizationEditModal;
