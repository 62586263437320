import JSZip from "jszip";
import { saveAs } from "file-saver";
// import ExcelJS from "exceljs";
import { getFileExtensionFromMimeType } from "Utils";

/**
 * This function takes the JSON data and export it in a specified format.
 * Note that the input data has an specified format.
 * data = { data, fields, filename }
 *
 * @param {Object} param0 - data: Input data in JSON
 * - outputformat: This is the format in which data must be downloaded
 * - fileName: Output filename
 */

const XLSX_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const exportData = (
  { data, outputFormat, fileName, fileType, sheetName, columnWidthConfig } = {
    fileName: "download",
    fileType: "",
    sheetName: "",
    columnWidthConfig: [],
  }
) => {
  switch (outputFormat) {
    case "CSV": {
      const csvData = jsonToCsv(data);
      const csvDataBlob = new Blob([csvData], { type: "text/csv" });
      saveAs(csvDataBlob, `${fileName}.csv`);
      break;
    }
    case "ZIP": {
      const zip = new JSZip();
      _.forEach(data, item => {
        const csvData = jsonToCsv(item);
        zip.file(`${item.filename}.csv`, csvData);
      });
      zip
        .generateAsync({
          type: "blob",
        })
        .then(content => {
          saveAs(content, `${fileName}.zip`);
        });
      break;
    }
    case "XLSX": {
      const xlsxData = jsonToXLSX({
        ...data,
        fileType,
        sheetName,
        columnWidthConfig,
      });
      xlsxData.then(content => {
        const xlsxDataBlob = new Blob([content], { type: XLSX_FILE_TYPE });
        saveAs(xlsxDataBlob, `${fileName}.xlsx`);
      });
      break;
    }
  }
};

/**
 * This function converts the specified JSON to CSV
 *
 * @param {Object} param0 - data : JSON data to be converted
 * - fields : List of field names
 *
 * @return {String} - final CSV data
 */
export const jsonToCsv = ({ data, fields }) => {
  const csvRows = [];
  const dataRows = [fields];

  // Extract row values from JSON and put in an array. Index wise
  // iteration to maintain order.
  if (!_.isEmpty(data)) {
    for (var rowIndex = 0; rowIndex < data.length; rowIndex++) {
      const tempRow = [];
      _.forEach(fields, field => {
        tempRow.push(`"${data[rowIndex][field]}"`);
      });
      dataRows.push(tempRow);
    }
  }

  // Convert array of arrays to array of CSVs
  _.forEach(dataRows, tempRow => {
    if (tempRow) {
      csvRows.push(tempRow.join(","));
    }
  });

  // Final CSV data
  return csvRows.join("\n");
};

export const jsonToXLSX = async ({
  data,
  fields,
  fileType,
  sheetName,
  columnWidthConfig,
}) => {
  const dataRows = [fields];

  _.forEach(data, dataRow => {
    const tempRow = [];
    _.forEach(fields, field => {
      const cellData = _.get(dataRow, `${field}`, "") || "";
      tempRow.push(cellData);
    });
    dataRows.push(tempRow);
  });

  let ExcelJS;
  try {
    ExcelJS = await import(/* webpackChunkName: "ExcelJS" */ "exceljs");
  } catch (err) {
    window.location.reload();
  }
  const workbook = new ExcelJS.Workbook();

  switch (fileType) {
    case "subjectGroupOverview":
      {
        const workSheetName = "Subject Group Overview";
        workbook.addWorksheet(workSheetName);
        const workSheet = workbook.getWorksheet(workSheetName);
        workSheet.insertRows(1, dataRows);

        //set the default row height
        workSheet.views = [{}];
        workSheet.properties.defaultRowHeight = 22;

        //freeze the first row
        workSheet.views = [{ state: "frozen", ySplit: 1 }];

        _.forEach(workSheet.columns, (column, columnNumber) => {
          column.width = columnNumber < 8 ? 30 : 90;
          column.alignment = { vertical: "middle", wrapText: false };
        });
        workSheet.getRow(1).font = {
          color: { argb: "FFFFFF" },
        };
        workSheet.getRow(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "93355A" },
        };
      }
      break;
    case "projectAssessment":
      {
        const workSheetName = "Criterion totals and comments";
        workbook.addWorksheet(workSheetName);
        const workSheet = workbook.getWorksheet(workSheetName);

        //set the default row height
        workSheet.views = [{}];
        workSheet.properties.defaultRowHeight = 22;

        //Add data to the sheet
        workSheet.insertRows(1, dataRows);

        //format first row
        workSheet.getRow(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "f2f2f2" },
        };

        _.forEach(workSheet.columns, column => {
          //right align numeric columns
          column.alignment = {
            vertical: "middle",
            wrapText: true,
          };

          //auto size column width according to cell content
          let maxCellLength = 0;
          let tempCellLength = 0;
          column["eachCell"]({ includeEmpty: true }, function (cell) {
            if (cell._row._number == 1) return;
            tempCellLength = cell.value ? cell.value.toString().length : 11;
            maxCellLength = _.max([tempCellLength, maxCellLength]);
          });

          // Supervisor's comment may contain a lot of data which can break the excel view, so we putting a max limit on column width
          column.width = _.min([_.max([maxCellLength, 11]), 75]);
        });

        workSheet.getColumn(1).font = {
          bold: true,
        };
        workSheet.getRow(2).font = {
          bold: true,
        };

        //freeze the first two rows
        workSheet.views = [{ state: "frozen", ySplit: 2 }];
      }
      break;
    case "classRosterDataBulkDownload":
      {
        const workSheetName = sheetName;
        workbook.addWorksheet(workSheetName, {
          views: [{ state: "frozen", ySplit: 1 }],
        });
        const workSheet = workbook.getWorksheet(workSheetName);

        workSheet.insertRows(1, dataRows);

        const firstRow = workSheet.getRow(1);

        firstRow.font = {
          bold: true,
        };
        firstRow.height = 40;

        _.forEach(workSheet.columns, (column, columnNumber) => {
          column.alignment = {
            vertical: "top",
            wrapText: true,
          };
          column.width = columnWidthConfig[columnNumber];
        });
      }
      break;
    case "studentMembershipTable":
      {
        const workSheetName = "Membership";
        workbook.addWorksheet(workSheetName);
        const workSheet = workbook.getWorksheet(workSheetName);

        //set the default row height
        workSheet.views = [{}];
        workSheet.properties.defaultRowHeight = 22;

        //Add data to the sheet
        workSheet.insertRows(1, dataRows);

        //format first row
        workSheet.getRow(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "f2f2f2" },
        };

        _.forEach(workSheet.columns, column => {
          //auto size column width according to cell content
          let maxCellLength = 0;
          let tempCellLength = 0;
          column["eachCell"]({ includeEmpty: true }, function (cell) {
            tempCellLength = cell.value ? cell.value.toString().length : 11;
            maxCellLength = _.max([tempCellLength, maxCellLength]);
          });

          column.width = _.max([maxCellLength + 2, 11]);
        });
        workSheet.getRow(1).font = {
          bold: true,
        };
      }
      break;
    default:
      {
        const workSheetName = "Sheet";
        workbook.addWorksheet(workSheetName);
        let workSheet = workbook.getWorksheet(workSheetName);
        workSheet.insertRows(1, dataRows);
      }
      break;
  }
  const excelBuffer = await workbook.xlsx.writeBuffer();

  return excelBuffer;
};

// This function can be used to download multiple files into a zip file
// items - is an array of attachments
// downloadFromUrl - is the Service function
// zipFileName - is the file name of the zip file which will be downloaded
export const downloadFilesInBulkAsZip = ({
  items,
  downloadFromUrl,
  zipFileName,
}) => {
  const itemsSize = _.size(items);

  if (itemsSize == 0) return;
  if (itemsSize == 1) {
    downloadFromUrl({
      attachment: _.head(items),
      shouldAskForSave: true,
    });
    return;
  }

  const zipFile = new JSZip();
  const itemsCount = _.size(items);
  let count = 0;
  _.forEach(items, attachment => {
    const { name } = attachment;

    downloadFromUrl({
      attachment,
      showProgress: false,
      onLoaded: fileBlob => {
        const fileExtension = getFileExtensionFromMimeType({
          mimeType: fileBlob.type,
        });
        const fileName = `${name}.${fileExtension}`;
        zipFile.file(fileName, fileBlob);

        count += 1;
        if (count === itemsCount) {
          zipFile.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, `${zipFileName}.zip`);
          });
        }
      },
    });
  });
};
