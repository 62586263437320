import React from "react";
import classes from "./LoginForm.scss";

import { FormTextInput } from "UIComponents";
import { Button } from "@toddle-design/web";

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }
  UNSAFE_componentWillMount = () => {
    const { resetScreen } = this.props;
    resetScreen("loginForm");
  };

  onForgotPasswordClick = () => {
    const { updateCurrentScreen, userType, role } = this.props;
    if (userType === "staff" && role === "visitor") {
      updateCurrentScreen({
        type: "forgotPassword",
        paramsToBeRemoved: ["role"],
      });
    } else {
      updateCurrentScreen({ type: "forgotPassword" });
    }
  };

  onBackClick = () => {
    const { updateCurrentScreen, userType, role } = this.props;

    switch (userType) {
      case "staff": {
        if (role === "visitor") {
          updateCurrentScreen({ type: "visitorLogin" });
        } else {
          updateCurrentScreen({ type: "teacherLogin" });
        }
        break;
      }
      case "student":
        updateCurrentScreen({ type: "studentLogin" });
        break;
      case "parent":
        updateCurrentScreen({ type: "parentLogin" });
        break;
    }
  };

  onSignInClick = () => {
    const { onSignInClick, showErrorToast } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    onSignInClick();
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  onPasswordEnter = () => {
    this.onSignInClick();
  };

  getBackButtonText = () => {
    const { userType, role } = this.props;
    switch (userType) {
      case "student":
        return "Student account";
      case "staff":
        if (role === "visitor")
          return "Programme evaluation team member account";
        return "Teacher account";
    }
  };

  render() {
    const {
      updateInputField,
      checkEmailVerification,
      data: { email, password } = {},
      renderBackButtonComponent,
      customStyle: { containerStyle, headerStyle } = {},
      userType,
      role,
      renderTradeMarkText,
    } = this.props;

    const isVisitor = userType === "staff" && role === "visitor";

    return (
      <div className={classes.container} style={containerStyle}>
        {renderBackButtonComponent({
          onClick: this.onBackClick,
          buttonText: this.getBackButtonText(),
        })}
        <div style={headerStyle}> {"Sign in to Toddle"} </div>
        {isVisitor ? (
          <div
            className={classes.subHeader}
          >{`Programme evaluation team member sign in`}</div>
        ) : null}

        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <div className={classes.inputContainer}>
              <FormTextInput
                data-test-id="input-login-email"
                ref={ref => (this.inputRefs["email"] = ref)}
                textInputLabel={"Email*"}
                value={email}
                name={"email"}
                placeholder={"Enter your email"}
                updateInputField={updateInputField}
                error={"Enter email address"}
                customValidation={checkEmailVerification}
                size={"large"}
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabelContainer}>
                <span className={classes.label}>Password*</span>
                <Button
                  data-test-id="button-forgot-password"
                  variant={"inline-progressive"}
                  size={"small"}
                  tabindex="-1"
                  onClick={this.onForgotPasswordClick}
                >{`Forgot password?`}</Button>
              </div>
              <FormTextInput
                data-test-id="input-login-email"
                ref={ref => (this.inputRefs["password"] = ref)}
                value={password}
                name={"password"}
                type={"password"}
                placeholder={"Enter Password"}
                updateInputField={updateInputField}
                error={"Enter password"}
                size={"large"}
                onEnter={this.onPasswordEnter}
              />
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <Button
              data-test-id="button-sign-in"
              variant={"destructive"}
              size={"large"}
              onClick={this.onSignInClick}
              isFullWidth={true}
            >
              {"Sign in"}
            </Button>
          </div>
        </div>
        {isVisitor &&
          renderTradeMarkText({
            align: "left",
          })}
      </div>
    );
  }
}

export default LoginForm;
