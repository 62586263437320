import React from "react";
import { compose } from "redux";
import classes from "./OutLine.scss";
import { connect } from "react-redux";
import classNames from "classnames";
import { LinkWithTooltip, I18nHOC } from "UIComponents";

class OutLineComp extends React.PureComponent {
  updateOutlineRef = ref => {
    const { updateOutlineRef, id } = this.props;
    updateOutlineRef({ ref, id });
  };

  onChangeOutline = () => {
    const { onChangeOutline, id } = this.props;
    onChangeOutline({ selectedNodeId: id, forcePosition: true });
  };

  render() {
    const {
      id,
      label,
      isSelected,
      outlineLeftDivStyle,
      strandWiseCount,
      showSelectedCount,
      t,
    } = this.props;

    const containerStyle = classNames(
      { [classes.outLineItem]: true },
      { [classes.outLineSelected]: isSelected }
    );
    const labelTextStyle = classNames(
      { [classes.labelText]: true },
      { [classes.depthText]: true },
      { [classes.selectLabel]: isSelected }
    );

    const leftDivStyle = classNames(
      { [classes.leftDiv]: true },
      { [classes.leftDivSelected]: isSelected }
    );
    let plainLabel = label;
    if (!plainLabel) {
      plainLabel = t("common:untitled");
    } else {
      plainLabel = plainLabel.replace(/<b>/g, "");
      plainLabel = plainLabel.replace(/<\/b>/g, "");
    }
    return (
      <div
        className={containerStyle}
        ref={this.updateOutlineRef}
        onClick={this.onChangeOutline}
      >
        <div className={classes.leftContainer}>
          <div className={leftDivStyle} style={outlineLeftDivStyle} />
          <LinkWithTooltip tooltip={plainLabel} href="#">
            <div className={labelTextStyle}>{plainLabel}</div>
          </LinkWithTooltip>
        </div>
        {!!showSelectedCount && (
          <div className={classes.countContainer}>
            {_.get(strandWiseCount, id, 0)}
          </div>
        )}
      </div>
    );
  }
}

class OutLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setFirstNode({ filteredNodes: props.filteredNodes });
  }

  updateOutlineScrollRef = ref => {
    const { updateOutlineScrollRef } = this.props;
    updateOutlineScrollRef({ ref });
  };

  setFirstNode = ({ filteredNodes }) => {
    const { onChangeOutline } = this.props;
    onChangeOutline({
      selectedNodeId: _.get(filteredNodes[0], "id", ""),
    });
  };
  componentDidUpdate = prevProps => {
    const { selectedNodeId, filteredNodes } = this.props;
    if (!_.isEqual(filteredNodes, prevProps.filteredNodes)) {
      const selectedNode = _.find(
        filteredNodes,
        node => node.id === selectedNodeId
      );
      if (!selectedNode) this.setFirstNode({ filteredNodes: filteredNodes });
    }
  };

  render() {
    const {
      filteredNodes,
      selectedNodeId,
      updateOutlineRef,
      onChangeOutline,
      mode,
      theme: {
        outLineContainerStyle,
        outLineScrollContainer,
        outlineLeftDivStyle,
      },
      strandWiseCount,
      showSelectedCount,
      t,
    } = this.props;
    const containerStyle = classNames(
      { [classes.container]: true },
      {
        [classes.viewContainer]: mode == "view",
      }
    );
    const scrollContainerStyle = classNames(
      { [classes.scrollContainer]: true },
      { [classes.scrollContainerView]: mode == "view" }
    );

    return (
      <div className={containerStyle} style={outLineContainerStyle}>
        <div
          className={scrollContainerStyle}
          id={"COACHMARKS_SCOPE_SEQUENCE_EDITOR_OUTLINE"}
          ref={this.updateOutlineScrollRef}
          style={outLineScrollContainer}
        >
          <div className={classes.headerText}>{t("common:outline")}</div>
          <div className={classes.outLineContainer}>
            {_.map(filteredNodes, item => {
              const { id, label } = item;
              const isSelected = item.id == selectedNodeId;
              return (
                <OutLineComp
                  label={label}
                  isSelected={isSelected}
                  id={id}
                  key={id}
                  t={t}
                  updateOutlineRef={updateOutlineRef}
                  onChangeOutline={onChangeOutline}
                  outlineLeftDivStyle={outlineLeftDivStyle}
                  strandWiseCount={strandWiseCount}
                  showSelectedCount={showSelectedCount}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapActionCreators = {};

const getStrandId = ({ nodeId, strandIds, nodes }) => {
  if (_.includes(strandIds, nodeId)) {
    return nodeId;
  }

  const parent = _.get(
    _.find(nodes, item => item.id == nodeId),
    "parent",
    undefined
  );

  if (parent) {
    return getStrandId({ nodeId: parent, strandIds, nodes });
  }
  return null;
};

const getStrandWiseCount = ({ taggedNodeIds, nodes, filteredNodes }) => {
  const strandWiseCount = {};
  const strandIds = _.map(filteredNodes, item => item.id);

  _.forEach(strandIds, id => {
    strandWiseCount[id] = 0;
  });

  _.forEach(_.uniq(taggedNodeIds), nodeId => {
    const id = getStrandId({ nodeId, strandIds, nodes });

    if (_.has(strandWiseCount, id)) {
      strandWiseCount[id] = strandWiseCount[id] + 1;
    }
  });
  return strandWiseCount;
};

const getStrandWiseCountMemoize = _.memoize(
  params => getStrandWiseCount(params),
  params => JSON.stringify(params)
);

const mapStateToProps = (state, ownProps) => {
  const { taggedNodeIds, nodes, showSelectedCount, startDepth } = ownProps;
  const filteredNodes = _.filter(
    nodes,
    item => item.depth == (!_.isNull(startDepth) ? startDepth : 1)
  );
  return {
    filteredNodes,
    strandWiseCount: showSelectedCount
      ? getStrandWiseCountMemoize({ taggedNodeIds, nodes, filteredNodes })
      : {},
  };
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators)
)(OutLine);
