import update from "immutability-helper";
import React from "react";
import { colors, fontStyle } from "Constants";
import { push, replace } from "react-router-redux";
import { STATUS, EVENTS, ACTIONS, LIFECYCLE } from "react-joyride";
import {
  getStaffBasicDetailsFromCache,
  getStaffCoachMarksFromCache,
  getCoachMarkStatusFromCache,
  writeCoachMarkStatusInCache,
  getStudentCoachMarksFromCache,
} from "modules/CommonGraphqlHelpers";

import { AddOutlined } from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";

import { updateUserCoachMarkStatusMutation } from "modules/CommonMutations";
import client from "apolloClient";
import ACLStore from "lib/aclStore";
import { ArrowIcon, CoachmarkArrowSvg } from "SvgComponents";
import { TickCircleFilled, StarFilled } from "@toddle-design/web-icons";
import { Beacon } from "AppComponents/CoachMarks/CoachMarkStyles";
import { Trans } from "react-i18next";
import { getUserInfo } from "Login/modules/LoginModule";
import { isCommunityActiveCheckByOnBoardingStatus } from "Community/modules/CommunityModule";
import { getUserCommunityStatusFromCache } from "Community/modules/CommunityGraphqlHelpers";
import { getSettingValue } from "modules/PermissionModule";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";

const snpCoachMarksButtonLabelStyles = {
  ...fontStyle.medium,
  fontSize: "1.2rem",
};
const snpCoachMarksTheme = {
  containerStyle: {
    borderRadius: "16px",
  },
  titleStyle: {
    fontSize: "1.8rem",
  },
  buttonContainerStyle: {
    gap: "32px",
  },
  skipButtonStyle: snpCoachMarksButtonLabelStyles,
  nextButtonStyle: {
    ...snpCoachMarksButtonLabelStyles,
    borderRadius: "4px",
    height: "32px",
    padding: "8px 16px",
  },
};

const roundedContainerStyle = {
  borderRadius: "24px",
  boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.2)",
  maxWidth: "400px",
};

const colorTitleStyle = {
  color: colors.pink39,
};

const buttonContainerStyle = {
  justifyContent: "flex-start",
  width: "100%",
};

const nextButtonStyle = {
  backgroundColor: colors.pink39,
};

const PREMIUM_ALLOWED_COACHMARKS = [
  "HELP_CENTER",
  "IMPORT_LE_FROM_COMMUNITY_CLASSROOM",
  "IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN",
  "GO_TO_COMMUNITY_BUTTON",
  "TODDLE_ACADEMY_BUTTON",
  "LANGUAGE_SWITCHER_BUTTON",
  "PLATFORM_SWITCHER_V2_BUTTON",
  "PLATFORM_SWITCHER_SCHOOL_ONBOARDING",
  "DASHBOARD_NOTIFICATION_CENTER",
  "SNP_MODULE",
  "SNP_DASHBOARD_NAVIGATION",
  "SNP_STANDARD_STATUS_TICKS",
  "SNP_DASHBOARD_EVIDENCES",
  "SNP_INVITE_VISITING_TEAM",
  "SNP_CHAT_MODULE",
  "SNP_DOCUMENT_CATEGORY_NAVIGATION",
  "SCOPE_SEQUENCE_VIEWER",
  "SCOPE_SEQUENCE_EDITOR",
];

export const NAME = "coachMarks";
export const UPDATE_DATA = "UPDATE_DATA" + " " + NAME;
export const UPDATE_COACHMARK_STATUS = "UPDATE_COACHMARK_STATUS" + " " + NAME;

export const updateCoachMarksData = value => {
  return { type: UPDATE_DATA, payload: value };
};

export const updateCoachMarkViewStatus = value => {
  return { type: UPDATE_COACHMARK_STATUS, payload: value };
};

export const onStepChanged = ({
  type,
  stepIndex,
  isBeaconClicked = false,
  isSkipped = false,
  isDone = false,
}) => {
  return (dispatch, getState) => {
    const coachMarksObj = _.get(getState().coachMarks.coachMarksObj, type, {});

    dispatch(updateCoachMarksData({ type, stepIndex }));

    if (coachMarksObj.onStepChanged) {
      coachMarksObj.onStepChanged({
        stepIndex,
        nextStep: _.get(coachMarksObj.steps, stepIndex, {}),
        isBeaconClicked,
        isSkipped,
        isDone,
      });
    }
  };
};

export const updateCoachMarkStatus = ({
  type,
  isViewed = true,
  coachMarkId,
  portalType,
}) => {
  return async (dispatch, getState) => {
    // console.log("Update coachmark status");
    const userLoggedIn = _.get(getState(), "login.userLoggedIn");

    if (!userLoggedIn) return;

    const coachMarkStatusDetails = getCoachMarkStatusFromCache(coachMarkId);
    const currentIsViewed = _.get(coachMarkStatusDetails, "isViewed", false);
    if (currentIsViewed) {
      return;
    }
    const userInfo = getUserInfo({ portalType }) || {};
    const userId = userInfo.id;

    // console.log("CoachMarks Id", coachMarkId);
    //console.log("coachMarkStatusDetails", coachMarkStatusDetails);
    const count = _.get(coachMarkStatusDetails, "count", 0) + 1;

    try {
      writeCoachMarkStatusInCache({
        coachMarkId: coachMarkId,
        data: {
          ...coachMarkStatusDetails,
          count,
          isViewed,
        },
      });
      await client.mutate({
        mutation: updateUserCoachMarkStatusMutation,
        variables: {
          userId,
          coachMark: type,
          isViewed,
          portalType,
          count,
        },
      });
      //console.log("status>>>>>>", getCoachMarkStatusFromCache(coachMarkId));
    } catch (e) {
      writeCoachMarkStatusInCache({
        coachMarkId: coachMarkId,
        data: coachMarkStatusDetails,
      });
      console.error(e);
    }
  };
};

export const getIsViewed = ({ type }) => {
  return (dispatch, getState) => {
    switch (type) {
      case "IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN":
      case "COMMUNITY_PROFILE_UPDATE":
      case "COMMUNITY_CONTRIBUTE_BUTTON":
      case "COMMUNITY_PROFILE_VIEW":
      case "COMMUNITY_IMPORT_LE":
      case "COMMUNITY_VIEW_BOOKMARKS":
      case "GO_TO_COMMUNITY_BUTTON":
      case "COMMUNITY_SHARE_BUTTON":
        return false;
    }
    return true;
  };
};

//get all portaltypes for which perticular coachmark should reset
// (Like Unit plan should reset once shown in any portal)
export const getCoachmarkPortalTypes = ({ type, portalType }) => {
  switch (type) {
    case "UNIT_PLAN":
      return ["PLANNER", "COMMUNITY"];
  }
  return [portalType];
};

//get all porttypes for which coachmarks should be update for perticular type and update it
export const checkAndUpdateCoachMarkStatus = ({
  type,
  isViewed,
  portalType,
}) => {
  return (dispatch, getState) => {
    const coachmarkPortalTypes = getCoachmarkPortalTypes({ type, portalType });

    _.forEach(coachmarkPortalTypes, item => {
      const coachmark = dispatch(
        getCoachMarkStatusDetails({ type, portalType: item })
      );
      // console.log("CoachMark", coachmark);
      const coachMarkId = _.get(coachmark, "id", "");

      dispatch(
        updateCoachMarkStatus({
          type,
          isViewed,
          coachMarkId,
          portalType: item,
        })
      );
    });
  };
};

//get coachmark steps and start coachmark and update coachmark status in backend
export const startCoachMarks = ({
  type,
  stepIndex = 0,
  continuous,
  onCoachmarksInit,
  portalType,
} = {}) => {
  return (dispatch, getState) => {
    const isAlreadyRunning = dispatch(isAlreadyRunningCoachmarkSequences());

    if (isAlreadyRunning) {
      return;
    }
    dispatch(updateCoachMarksData({ type, run: true, stepIndex }));

    const isViewed = dispatch(getIsViewed({ type }));

    if (continuous) {
      dispatch(
        checkAndUpdateCoachMarkStatus({
          type,
          isViewed,
          portalType,
        })
      );
    }

    if (onCoachmarksInit) {
      onCoachmarksInit();
    }
  };
};

//increase coachmark step on next click or handle close coachmark status
export const handleCoachMarkCallback = ({
  type,
  data,
  coachMarkId,
  portalType,
  continuous,
}) => {
  return (dispatch, getState) => {
    const { action, index, type: cType, status, size, lifecycle } = data;
    //  console.log(action, lifecycle, status, ACTIONS, size);
    if (size == 1 && !continuous) {
      if (
        action == ACTIONS.UPDATE &&
        size == 1 &&
        lifecycle == LIFECYCLE.TOOLTIP
      ) {
        dispatch(
          onStepChanged({
            type,
            isBeaconClicked: true,
          })
        );
        dispatch(
          checkAndUpdateCoachMarkStatus({
            type,
            isViewed: true,
            coachMarkId,
            portalType,
          })
        );
        if (lifecycle == LIFECYCLE.COMPLETE) {
          setTimeout(() => {
            dispatch(updateCoachMarksData({ type, run: false }));
          });
        }
        // console.log("Log1>>>", action, lifecycle, status, ACTIONS, size);
      } else if (action == ACTIONS.CLOSE || action == ACTIONS.SKIP) {
        dispatch(
          onStepChanged({
            type,
            isDone: action == ACTIONS.CLOSE,
            isSkipped: action == ACTIONS.SKIP,
          })
        );
        setTimeout(() => {
          dispatch(updateCoachMarksData({ type, run: false }));
        });
      }
    } else {
      // console.log("Here>>>>>", size, status, lifecycle);
      if (
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
        (size == 1 && lifecycle == LIFECYCLE.COMPLETE)
      ) {
        dispatch(
          onStepChanged({
            type,
            stepIndex: 0,
            isSkipped: status == STATUS.SKIPPED,
            isDone:
              status == STATUS.FINISHED ||
              (size == 1 && action == ACTIONS.NEXT),
          })
        );
        setTimeout(() => {
          dispatch(updateCoachMarksData({ type, run: false }));
        });
      } else if (
        [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(cType) &&
        size > 1
      ) {
        const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        dispatch(onStepChanged({ type, stepIndex }));
      }
    }
  };
};
export const getCoachMarkStatusDetails = ({ type, portalType }) => {
  return (dispatch, getState) => {
    const userInfo = getUserInfo({ portalType }) || {};

    const userId = userInfo.id;
    const userData = getStaffCoachMarksFromCache(userId);
    // console.log("coachmark data:", userData, userId, portalType, type);
    const coachMarksStatus = _.find(
      _.get(userData, "coachMarkStatus", []),
      item => item.coachMark == type
    );

    //console.log(coachMarksStatus);
    return coachMarksStatus;
  };
};

export const getCoachMarkStatusDetailsForStudents = ({ type, portalType }) => {
  return (dispatch, getState) => {
    const userInfo = getUserInfo({ portalType }) || {};

    const userId = userInfo.id;
    const userData = getStudentCoachMarksFromCache(userId);
    const coachMarksStatus = _.find(_.get(userData, "coachMarkStatus", []), {
      coachMark: type,
    });

    return coachMarksStatus || {};
  };
};

//check any continues coachmark running in the screen
export const isAlreadyRunningCoachmarkSequences = () => {
  return (dispatch, getState) => {
    const coachmarkValues = Object.values(
      getState().coachMarks.coachMarksObj || {}
    );
    //console.log("coachmarkValues", coachmarkValues);

    return !!_.find(coachmarkValues, item => !!item.run && !!item.continuous);
  };
};

// a - initial offset, d - interval, x - visits
export const isMemberOfSeries = ({ a, d, x }) => {
  return (x - a) % d == 0 && (x - a) / d >= 0;
};

//check when coachmark should be visible depend on isViewed or count (Frequency)
export const isCoachmarkVisible = ({ type, portalType }) => {
  return (dispatch, getState) => {
    const bookmarkStatus = _.get(
      getState().coachMarks.coachMarksViewStatus,
      "bookmarkStatus",
      false
    );
    const coachMarksStatusDetails = dispatch(
      getCoachMarkStatusDetails({ type, portalType })
    );
    if (_.isEmpty(coachMarksStatusDetails)) {
      return false;
    }
    const { isViewed, count } = coachMarksStatusDetails || {};
    let shouldUpdateCountOnly = false;
    let isVisible = !isViewed;
    switch (type) {
      case "COMMUNITY_PROFILE_UPDATE":
        isVisible = !isViewed && isMemberOfSeries({ a: 0, d: 5, x: count });
        shouldUpdateCountOnly = !isVisible;
        break;

      //show it in first visit, then show it when user bookmarks for the first time
      case "COMMUNITY_VIEW_BOOKMARKS":
        isVisible = !isViewed && (count == 1 || bookmarkStatus);
        shouldUpdateCountOnly = !isVisible;
        break;

      case "GO_TO_COMMUNITY_BUTTON":
        isVisible = !isViewed && isMemberOfSeries({ a: 1, d: 4, x: count });
        shouldUpdateCountOnly = !isVisible;
        break;

      case "COMMUNITY_CONTRIBUTE_BUTTON":
        //when contribute coachmark is visible the count is always 3 for the first time
        isVisible = !isViewed && count == 3;
        shouldUpdateCountOnly = !isVisible;
        break;

      case "COMMUNITY_PROFILE_VIEW":
        isVisible = !isViewed && isMemberOfSeries({ a: 1, d: 6, x: count });
        shouldUpdateCountOnly = !isVisible;
        break;

      case "COMMUNITY_IMPORT_LE":
        //isVisible = !isViewed && isMemberOfSeries({ a: 1, d: 2, x: count });
        isVisible = !isViewed && count == 2;
        shouldUpdateCountOnly = !isVisible;
        break;

      case "COMMUNITY_LE_BOOKMARK_BUTTON":
        isVisible = !isViewed && count == 1;
        shouldUpdateCountOnly = !isVisible;
        break;

      case "COMMUNITY_UNIT_BOOKMARK_BUTTON":
        isVisible = !isViewed && count == 2;
        shouldUpdateCountOnly = !isVisible;
        break;

      case "COMMUNITY_SHARE_BUTTON":
        isVisible = !isViewed && count < 2;
        break;
    }
    if (shouldUpdateCountOnly) {
      dispatch(
        checkAndUpdateCoachMarkStatus({
          type,
          isViewed: isVisible,
          portalType,
        })
      );
    }

    return isVisible;
  };
};

export const initCoachMarks = ({
  type,
  onStepChanged,
  startStepIndex = 0,
  params = {},
  continuous,
  startAfterTime,
  onCoachmarksInit,
  portalType,
}) => {
  return async (dispatch, getState) => {
    //Check if user logged in or not?
    const state = getState();
    const userLoggedIn = state.login.userLoggedIn;
    if (!userLoggedIn) {
      return;
    }
    const startAfter = startAfterTime ? startAfterTime : continuous ? 300 : 100;
    const coachMarksStatusDetails = dispatch(
      getCoachMarkStatusDetails({ type, portalType })
    );

    const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });

    const isVisible = dispatch(isCoachmarkVisible({ type, portalType }));
    if (!isVisible) {
      return;
    }

    let coachMarksObj = {};
    switch (type) {
      case "UNIT_PLAN": {
        const { isCommunity } = params || {};
        if (isCommunity) {
          coachMarksObj = dispatch(
            getUnitPlanCommunityCoachMarksObj({ params, type, portalType })
          );
        } else {
          coachMarksObj = dispatch(
            getUnitPlanCoachMarksObj({ params, type, portalType })
          );
        }
        break;
      }

      case "UNIT_FLOW":
        coachMarksObj = dispatch(getUnitFlowCoachMarksObj({ params, type }));
        break;

      case "LEARNING_ENGAGEMENT":
        coachMarksObj = dispatch(getResourceCoachMarkObj({ params, type }));
        break;

      case "ASSESSMENT":
        coachMarksObj = dispatch(getResourceCoachMarkObj({ params, type }));
        break;

      case "DASHBOARD":
        coachMarksObj = dispatch(getDashboardCoachmarksObj({ params, type }));
        break;

      case "SCOPE_SEQUENCE_EDITOR":
        coachMarksObj = dispatch(
          getScopeAndSequenceEditorCoachMarksObj({ params, type })
        );
        break;
      case "SCOPE_SEQUENCE_VIEWER":
        coachMarksObj = dispatch(
          getScopeAndSequenceViewerCoachMarksObj({ params, type })
        );
        break;
      case "COMMENT":
        coachMarksObj = dispatch(getCommentCoachMarksObj({ params }));
        break;

      case "HELP":
        coachMarksObj = dispatch(getHelpCoachMarksObject({ params }));
        break;

      case "PROMPTS":
        coachMarksObj = dispatch(getPromptsCoachMarksObject({ params }));
        break;

      case "SCOPE_SEQUENCE":
        coachMarksObj = dispatch(
          getScopeAndSequenceCoachMarksObject({ params })
        );
        break;

      case "REFLECTIONS":
        coachMarksObj = dispatch(getReflectionsCoachMarksObject({ params }));
        break;

      case "HELP_CENTER":
        coachMarksObj = dispatch(getHelpCenterCoachMarksObject({ params }));
        break;

      case "TODDLE_ACADEMY_BUTTON":
        coachMarksObj = dispatch(getToddleAcademyCoachMarksObject({ params }));
        break;

      case "DASHBOARD_NOTIFICATION_CENTER":
        coachMarksObj = dispatch(
          getDashBoardNotificationCenterCoachMarksObject({ params })
        );
        break;

      case "RESOURCE":
        coachMarksObj = dispatch(
          getPlannerResourceCoachMarksObject({ params })
        );
        break;
      case "SUBJECTS_AND_SCOPE_SEQUENCE_CONFIG":
        coachMarksObj = dispatch(
          getSubjectsAndScopeAndSequenceConfigCoachMarksObj({ params, type })
        );
        break;
      case "HOME_BUTTON":
        coachMarksObj = dispatch(getHomeButtonCoachMarksObject({ params }));
        break;

      case "UNIT_PLAN_INSIGHTS_PRINT":
        coachMarksObj = dispatch(
          getUnitPlanInsightsAndPrintCoachMarksObject({ params })
        );
        break;

      case "IMPORT_LE_FROM_COMMUNITY_CLASSROOM":
        coachMarksObj = dispatch(
          getImportLeInClassroomFromCommunityObject({ params })
        );
        break;

      case "IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN":
        coachMarksObj = dispatch(
          getImportLeInUnitPlanFromCommunityObject({ params })
        );
        break;

      case "COMMUNITY_PROFILE_UPDATE":
        coachMarksObj = dispatch(getCommunityProfileObject({ params }));
        break;

      case "COMMUNITY_VIEW_BOOKMARKS":
        coachMarksObj = dispatch(getCommunityBookmarkObject({ params }));
        break;

      case "GO_TO_COMMUNITY_BUTTON":
        coachMarksObj = dispatch(getPlatformSwitchObject({ params }));
        break;

      case "COMMUNITY_CONTRIBUTE_BUTTON":
        coachMarksObj = dispatch(
          getCommunityContributeButtonObject({ params })
        );
        break;

      case "COMMUNITY_PROFILE_VIEW":
        coachMarksObj = dispatch(getCommunityProfileViewObject({ params }));
        break;

      case "COMMUNITY_IMPORT_LE":
        coachMarksObj = dispatch(getCommunityImportLEObject({ params }));
        break;

      case "COMMUNITY_LE_BOOKMARK_BUTTON":
        coachMarksObj = dispatch(getCommunityLEBookmarkObject({ params }));
        break;

      case "COMMUNITY_UNIT_BOOKMARK_BUTTON":
        coachMarksObj = dispatch(getCommunityUnitBookmarkObject({ params }));
        break;

      case "COMMUNITY_SHARE_BUTTON":
        coachMarksObj = dispatch(getCommunityShareObject({ params }));
        break;

      case "LANGUAGE_SWITCHER_BUTTON":
        coachMarksObj = dispatch(getLangaugeCoachMarksObject({ params }));
        break;

      case "PLATFORM_SWITCHER_V2_BUTTON":
        coachMarksObj = dispatch(
          getPlatformSwitcherV2CoachMarksObject({ params })
        );
        break;

      case "PLATFORM_SWITCHER_SCHOOL_ONBOARDING":
        coachMarksObj = dispatch(
          getPlatformSwitcherSchoolOnboardingObject({ params })
        );
        break;

      case "SNP_MODULE":
        coachMarksObj = dispatch(getSnpModuleCoachMarksObject({ params }));
        break;

      case "SNP_DASHBOARD_NAVIGATION":
        coachMarksObj = dispatch(
          getSnpDashboardNavigationCoachMarksObject({ params })
        );
        break;

      case "SNP_DOCUMENT_CATEGORY_NAVIGATION":
        coachMarksObj = dispatch(
          getSnpDocumentsNavigationCoachMarksObject({ params })
        );
        break;

      case "SNP_STANDARD_STATUS_TICKS":
        coachMarksObj = dispatch(
          getSnpStandardStatusCoachMarksObject({ params })
        );
        break;

      case "SNP_DASHBOARD_EVIDENCES":
        coachMarksObj = dispatch(
          getSnpDashboardEvidencesCoachMarksObject({ params })
        );
        break;

      case "SNP_INVITE_VISITING_TEAM":
        coachMarksObj = dispatch(
          getSnpInviteVisitingTeamCoachMarksObject({ params })
        );
        break;

      case "SNP_CHAT_MODULE":
        coachMarksObj = dispatch(getSnpChatModuleCoachMarksObject({ params }));
        break;
    }

    //console.log(coachMarksObj);
    if (
      !_.isEmpty(coachMarksObj) &&
      (portalType == "COMMUNITY" ||
        isCurriculumProgramFree ||
        _.includes(PREMIUM_ALLOWED_COACHMARKS, type))
    ) {
      dispatch(
        updateCoachMarksData({
          type,
          onStepChanged,
          ...coachMarksObj,
          continuous,
          run: false,
          coachMarkId: _.get(coachMarksStatusDetails, "id", ""),
        })
      );

      setTimeout(() => {
        dispatch(
          startCoachMarks({
            type,
            stepIndex: startStepIndex,
            continuous,
            onCoachmarksInit,
            portalType,
          })
        );
      }, startAfter);
    }
  };
};

const getDefaultCoachmarkProperties = ({
  placement = "bottom-end",
  disableBeacon = true,
  placementBeacon = "top",
  spotlightClicks = false,
  isFixed = true,
  showNextButton = false,
} = {}) => {
  const theme = {
    containerStyle: roundedContainerStyle,
    titleStyle: colorTitleStyle,
    buttonContainerStyle: buttonContainerStyle,
    nextButtonStyle: nextButtonStyle,
  };

  return {
    placement,
    disableBeacon,
    placementBeacon,
    spotlightClicks,
    isFixed,
    theme,
    showNextButton,
  };
};

export const getHelpCenterCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:helpCenter_title"),
          content: ({ t }) => t("blockScreen:helpCenter_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -4,
        marginTop: -8,
      },
    };
  };
};
export const getToddleAcademyCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:toddle_academy_title"),
          content: ({ t }) => t("blockScreen:toddle_academy_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -4,
        marginTop: -8,
      },
    };
  };
};

export const getDashBoardNotificationCenterCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:dashboard_notification_title"),
          content: ({ t }) => t("blockScreen:dashboard_notification_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -20,
        marginTop: -12,
      },
      wrapperZIndex: 9,
    };
  };
};

export const getLangaugeCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:langauge_switcher_title"),
          content: ({ t }) => t("blockScreen:langauge_switcher_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -4,
        marginTop: -8,
      },
      wrapperZIndex: 9,
    };
  };
};

export const getPlatformSwitcherV2CoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const isCommunity = _.get(getState(), "login.activeTab") == "community";
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:platform_switcher_v2_title"),
          content: ({ t }) =>
            isCommunity
              ? t("blockScreen:platform_switcher_v2_content_community")
              : t("blockScreen:platform_switcher_v2_content_platform"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -4,
        marginTop: -8,
      },
      wrapperZIndex: 9,
    };
  };
};

export const getPlatformSwitcherSchoolOnboardingObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target, curriculum } = params;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:platform_switcher_v2_title"),
          content: ({ t }) =>
            t("blockScreen:platform_switcher_school_onboarding_subtitle", {
              curriculum,
            }),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -4,
        marginTop: -8,
      },
    };
  };
};

export const getDashboardCoachmarksObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const state = getState();
    const userInfo = getUserInfo({ portalType: "PLANNER" }) || {};
    const communityUserInfo = getUserInfo({ portalType: "COMMUNITY" });

    const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });
    if (!isCurriculumProgramFree) {
      return {};
    }
    const { demoCourse } = params;
    const userId = userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const isAdmin = ACLStore.can("AdminPortal");
    const isCommunityOpen = ACLStore.can("TeacherPortal:Community");

    const userCommunityStatusData = getUserCommunityStatusFromCache({
      id: _.get(communityUserInfo, "id", ""),
    });
    const onBoardingStatus = _.get(
      userCommunityStatusData,
      "node.communityDetails.onboardingStatus",
      0
    );
    const isCommunityActive = isCommunityActiveCheckByOnBoardingStatus({
      onBoardingStatus,
    });

    let steps = [];
    steps.push({
      title: ({ t }) => t("blockScreen:dashboard_coachmark_step_1_title_new"),
      content: ({ t }) =>
        t("blockScreen:dashboard_coachmark_step_1_content_new"),
      placement: "center",
      target: `#COACHMARKS_${type}`,
      disableBeacon: true,
      showSkipButton: false,
      nextButtonText: ({ t }) => t("blockScreen:explore_toddle_platform"),
      nextButtonSize: "lg",
      stepIndex: 0,
    });
    if (!ACLStore.can("TeacherPortal:PlanathonComplete") && demoCourse) {
      steps.push({
        title: ({ t }) => t("blockScreen:dashboard_coachmark_step_2_title"),
        content: ({ t }) => t("blockScreen:dashboard_coachmark_step_2_content"),
        placement: "right",
        target: `#COACHMARKS_${type}_DEMO_GRADE`,
        disableBeacon: true,
        stepIndex: 1,
      });
    }

    if (isCommunityOpen && !isCommunityActive) {
      steps.push({
        title: ({ t }) => t("blockScreen:dashboard_coachmark_step_2_title_2"),
        content: ({ t }) =>
          t("blockScreen:dashboard_coachmark_step_2_content_2"),
        placement: "right",
        target: `#COACHMARKS_${type}_COMMUNITY`,
        disableBeacon: true,
        stepIndex: 1,
      });
    }

    steps = [
      ...steps,
      {
        title: ({ t }) => t("blockScreen:dashboard_coachmark_step_3_title"),
        content: ({ t }) => t("blockScreen:dashboard_coachmark_step_3_content"),
        placement: "right-start",
        target: `#COACHMARKS_${type}_GRADES`,
        disableBeacon: true,
        stepIndex: 2,
        tootTipWidth: "32vw",
      },
      {
        title: ({ t }) =>
          demoCourse
            ? t("blockScreen:dashboard_coachmark_step_4_title")
            : t("common:school_setup"),
        content: ({ t }) =>
          demoCourse
            ? t("blockScreen:dashboard_coachmark_step_4_content_1", {
                action: t(`common:lowercase`, {
                  text: isAdmin ? t("common:configure") : t("common:view"),
                }),
              })
            : t("blockScreen:dashboard_coachmark_step_4_content_3", {
                action: t(`common:lowercase`, {
                  text: isAdmin ? t("common:configure") : t("common:view"),
                }),
              }),
        placement: "left-start",
        target: `#COACHMARKS_${type}_SETUP_TRACKING`,
        disableBeacon: true,
        stepIndex: 3,
      },
      {
        title: ({ t }) => t("blockScreen:dashboard_coachmark_step_5_title"),
        placement: "center",
        target: `#COACHMARKS_${type}`,
        disableBeacon: true,
        nextButtonText: ({ t }) => t("blockScreen:lets_go"),
        nextButtonSize: "lg",
        content: ({ t }) => {
          return (
            <span style={{ lineHeight: "2.4rem" }}>
              <span>
                {t("blockScreen:dashboard_coachmark_step_5_content_text1")}
              </span>
              <span style={{ display: "flex", marginTop: 16 }}>
                <span>
                  {t("blockScreen:dashboard_coachmark_step_5_content_text2")}
                </span>
                {/* <CoachmarkArrowSvg /> */}
                <Beacon />
              </span>
            </span>
          );
        },
        stepIndex: 4,
      },
    ];

    return { steps };
  };
};

export const getUnitPlanCoachMarksObj = ({ params, type, portalType }) => {
  return (dispatch, getState) => {
    const userInfo = getUserInfo({ portalType });
    const userId = userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const { tabs, plannerFormat } = params;
    // if (plannerFormat != "E_PYP") {
    // return {};
    // }

    const values = {
      planner: {
        title: ({ t }) =>
          plannerFormat == "E_PYP"
            ? t("blockScreen:reflecting_n_planning")
            : t("blockScreen:planning"),
        content: ({ t }) =>
          plannerFormat == "E_PYP"
            ? t("blockScreen:unitPlan_planner_content_1")
            : t("blockScreen:unitPlan_planner_content_2"),
      },
      implementation: {
        title: ({ t }) => t("blockScreen:designing_n_implementing"),
        content: ({ t }) => t("blockScreen:unitPlan_implementation_content"),
      },
      unitFlow: {
        title: ({ t }) => t("blockScreen:unpack_unit"),
        content: ({ t }) => t("blockScreen:unitPlan_unitFlow_content"),
      },
      evidence: {
        title: ({ t }) => t("blockScreen:unitPlan_evidence_title"),
        content: ({ t }) => t("blockScreen:unitPlan_evidence_content"),
      },
      reflection: {
        title: ({ t }) =>
          plannerFormat == "E_PYP"
            ? t("blockScreen:reflecting")
            : t("blockScreen:reflection"),
        content: ({ t }) =>
          plannerFormat == "E_PYP"
            ? t("blockScreen:unitPlan_reflection_content_1")
            : t("blockScreen:unitPlan_reflection_content_2"),
      },
    };

    const steps = [
      {
        title: ({ t }) => t("blockScreen:unitPlan_title"),
        content: ({ t }) =>
          t("blockScreen:unitPlan_content_with_name", { name: firstName }),
        placement: "center",
        target: `#COACHMARKS_${type}`,
        disableBeacon: true,
        showSkipButton: false,
        nextButtonText: ({ t }) => t("blockScreen:lets_go"),
        nextButtonSize: "lg",
        stepIndex: 0,
      },
    ];

    _.forEach(tabs, (tab, index) => {
      const tabValue = values[tab.value];
      if (tabValue) {
        steps.push({
          placement: "top",
          target: `#COACHMARKS_${type}_${tab.value}`,
          disableBeacon: true,
          ...tabValue,
          stepIndex: index,
        });
      }
    });

    steps.push({
      title: ({ t }) => t("blockScreen:unit_planning_title"),
      placement: "center",
      target: `#COACHMARKS_${type}`,
      disableBeacon: true,
      nextButtonText: ({ t }) => t("blockScreen:lets_go"),
      nextButtonSize: "lg",
      content: ({ t }) => {
        return (
          <span style={{ lineHeight: "2.4rem" }}>
            <span style={{ display: "flex", marginTop: 16 }}>
              <span>{t("blockScreen:lookout_red_dots")}</span>
              <CoachmarkArrowSvg />
              <Beacon />
            </span>
          </span>
        );
      },
      stepIndex: _.get(tabs, "length", 0),
    });

    return {
      steps: steps,
    };
  };
};

export const getUnitPlanCommunityCoachMarksObj = ({
  params,
  type,
  portalType,
}) => {
  return (dispatch, getState) => {
    const userInfo = getUserInfo({ portalType });
    const userId = userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const { tabs, plannerFormat } = params;
    // if (plannerFormat != "E_PYP") {
    // return {};
    // }

    const theme = {
      containerStyle: roundedContainerStyle,
      titleStyle: colorTitleStyle,
    };

    const values = {
      planner: {
        title: ({ t }) =>
          plannerFormat == t("blockScreen:community_unitPlan_planner_title"),
        content: ({ t }) => t("blockScreen:community_unitPlan_planner_content"),
      },
      implementation: {
        title: ({ t }) =>
          t("blockScreen:community_unitPlan_implementing_title"),
        content: ({ t }) =>
          t("blockScreen:community_unitPlan_implementing_content"),
      },
      unitFlow: {
        title: ({ t }) => t("blockScreen:community_unitPlan_unitflow_title"),
        content: ({ t }) =>
          t("blockScreen:community_unitPlan_unitflow_content"),
      },
      comments: {
        title: ({ t }) => t("blockScreen:community_unitPlan_comment_title"),
        content: ({ t }) => t("blockScreen:community_unitPlan_comment_content"),
      },
    };

    const steps = [
      {
        title: ({ t }) => t("blockScreen:community_unitPlan_title"),
        content: ({ t }) => t("blockScreen:community_unitPlan_content"),
        placement: "center",
        target: `#COACHMARKS_${type}`,
        disableBeacon: true,
        showSkipButton: false,
        nextButtonText: ({ t }) => t("blockScreen:lets_go"),
        nextButtonSize: "lg",
        stepIndex: 0,
        theme,
      },
    ];

    _.forEach(tabs, (tab, index) => {
      const tabValue = values[tab.value];
      if (tabValue) {
        steps.push({
          placement: "top",
          target: `#COACHMARKS_${type}_${tab.value}`,
          disableBeacon: true,
          ...tabValue,
          stepIndex: index,
          theme,
        });
      }
    });

    return {
      steps: steps,
    };
  };
};

export const getResourceCoachMarkObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const label = "common:le_label";

    return {
      steps: [
        {
          title: ({ t }) =>
            t("blockScreen:resource_title_with_label", {
              label: t("common:lowercase", { text: t(label) }),
            }),
          content: ({ t }) =>
            t("blockScreen:resource_content_with_name_label", {
              label: t("common:lowercase", { text: t(label) }),
              name: firstName,
            }),
          placement: "center",
          target: `#COACHMARKS_${type}`,
          disableBeacon: true,
          showSkipButton: false,
          nextButtonText: ({ t }) => t("blockScreen:lets_go"),
          nextButtonSize: "lg",
          stepIndex: 0,
        },
      ],
    };
  };
};

export const getLeCoachMarksObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const { tabs } = params;
    const userId = getState().login.userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const values = {
      s1: {
        title: ({ t }) => t("blockScreen:add_basic_details"),
        content: ({ t }) => t("blockScreen:le_s1_content"),
      },
      pypElements: {
        title: ({ t }) => t("blockScreen:le_pyp_elements_title"),
        content: ({ t }) => t("blockScreen:le_pyp_elements_content"),
      },
      s3: {
        title: ({ t }) => t("blockScreen:le_s3_title"),
        content: ({ t }) => t("blockScreen:le_s3_content"),
      },
      implementation: {
        title: ({ t }) => t("blockScreen:le_implementation_title"),
        content: ({ t }) => t("blockScreen:le_implementation_content"),
      },
    };
    const steps = [];
    steps.push({
      title: ({ t }) => t("blockScreen:le_first_title"),
      content: ({ t }) =>
        t("blockScreen:le_first_content_with_name", { name: firstName }),
      placement: "center",
      target: `#COACHMARKS_${type}`,
      disableBeacon: true,
      showSkipButton: false,
      nextButtonText: ({ t }) => t("blockScreen:lets_go"),
      nextButtonSize: "lg",
      stepIndex: 0,
    });

    _.forEach(tabs, (tab, index) => {
      const tabValue = values[tab];
      if (tabValue) {
        steps.push({
          placement: "right",
          target: `#COACHMARKS_${type}_${tab}`,
          disableBeacon: true,
          ...tabValue,
          stepIndex: index,
        });
      }
    });

    return {
      steps: steps,
    };
  };
};

export const getAssessmentCoachMarksObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const { tabs } = params;

    const userId = getState().login.userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const values = {
      s1: {
        title: ({ t }) => t("blockScreen:add_basic_details"),
        content: ({ t }) => t("blockScreen:assessment_s1_content"),
      },
      pypElements: {
        title: ({ t }) => t("blockScreen:assessment_pyp_elements_title"),
        content: ({ t }) => t("blockScreen:assessment_pyp_elements_content"),
      },
      s3: {
        title: ({ t }) => t("blockScreen:assessment_s3_title"),
        content: ({ t }) => t("blockScreen:assessment_s3_content"),
      },
    };
    const steps = [];
    steps.push({
      title: ({ t }) => t("blockScreen:assessment_first_title"),
      content: ({ t }) => t("blockScreen:assessment_first_content_with_name"),
      placement: "right",
      target: `#COACHMARKS_${type}`,
      disableBeacon: true,
      showSkipButton: false,
      nextButtonText: ({ t }) => t("blockScreen:lets_go"),
      stepIndex: 0,
    });

    _.forEach(tabs, (tab, index) => {
      const tabValue = values[tab];
      if (tabValue) {
        steps.push({
          placement: "bottom",
          target: `#COACHMARKS_${type}_${tab}`,
          disableBeacon: true,
          ...tabValue,
          stepIndex: index,
        });
      }
    });

    return {
      steps: steps,
    };
  };
};

export const getUnitFlowCoachMarksObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const { isData } = params;

    const steps = [
      {
        title: ({ t }) => t("blockScreen:unitFlow_title_1"),
        content: ({ t }) => t("blockScreen:unitFlow_content_1"),
        placement: "right",
        target: `#COACHMARKS_${type}_LEFT_PANE`,
        disableBeacon: true,
        stepIndex: 0,
      },
      {
        title: ({ t }) => t("blockScreen:unitFlow_title_2"),
        content: ({ t }) => t("blockScreen:unitFlow_content_2"),
        placement: "left",
        target: `#COACHMARKS_${type}_RIGHT_PANE`,
        disableBeacon: true,
        stepIndex: 1,
      },
      {
        title: ({ t }) => t("blockScreen:unitFlow_title_3"),
        content: ({ t }) => t("blockScreen:unitFlow_content_3"),
        placement: "bottom",
        target: `#COACHMARKS_${type}_CREATE_BUTTON`,
        disableBeacon: true,
        stepIndex: 2,
      },
    ];

    return {
      steps: steps,
      startCountIndex: 0,
    };
  };
};

export const getCommentCoachMarksObj = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:comment_title"),
          content: ({ t }) => t("blockScreen:comment_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        paddingTop: 20,
        paddingLeft: 16,
      },
    };
  };
};

export const getHelpCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:help_text_title"),
          content: ({ t }) => t("blockScreen:help_text_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        paddingTop: 20,
        paddingLeft: 16,
      },
    };
  };
};

export const getPromptsCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:prompts_title"),
          content: ({ t }) => {
            return (
              <span>
                <Trans i18nKey="prompts_content">
                  These are the prompts as provided by the IB in their planning
                  templates. You can choose to hide these IB prompts by clicking
                  on the
                  <div
                    style={{
                      margin: "0 4px",
                      transform: "rotate( -180deg )",
                      display: "inline-block",
                    }}
                  >
                    <ArrowIcon fill={colors.blue29} />
                  </div>
                  button.
                </Trans>
              </span>
            );
          },
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "top",
          target: target,
        },
      ],
      beaconStyles: {
        paddingTop: 24,
        marginLeft: -200,
      },
    };
  };
};

export const getReflectionsCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    // console.log(target);
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:reflection_coachmark_title"),
          content: ({ t }) => t("blockScreen:reflection_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
        },
      ],
      beaconStyles: {},
    };
  };
};

export const getPlannerResourceCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target, resourceType } = params;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:planner_resource_title"),
          content: ({ t }) =>
            t("blockScreen:planner_resource_content_with_label", {
              label:
                resourceType == "ASSESSMENT"
                  ? t("common:assessment_label_plural")
                  : t("common:le_label_plural"),
            }),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
        },
      ],
      beaconStyles: {
        marginLeft: 16,
      },
    };
  };
};

export const getScopeAndSequenceCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:sns_title"),
          content: ({ t }) => t("blockScreen:sns_content_1"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          nextButtonText: ({ t }) => t("blockScreen:sns_nextButtonText"),
          showSkipButton: false,
        },
      ],
      beaconStyles: {
        marginLeft: -16,
      },
    };
  };
};

export const getScopeAndSequenceEditorCoachMarksObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_1"),
          content: ({ t }) =>
            t("blockScreen:sns_editor_content_1", { name: firstName }),
          placement: "center",
          target: `#COACHMARKS_${type}`,
          disableBeacon: true,
          showSkipButton: false,
          nextButtonText: ({ t }) => t("blockScreen:lets_go"),
          nextButtonSize: "lg",
          tootTipWidth: "30vw",
          stepIndex: 0,
        },
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_6"),
          content: ({ t }) => t("blockScreen:sns_editor_content_6"),
          placement: "bottom",
          target: `.COACHMARKS_${type}_ROW`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 1,
        },
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_2"),
          content: ({ t }) => t("blockScreen:sns_editor_content_2"),
          placement: "bottom",
          target: `#COACHMARKS_${type}_HIERARCHY_ARROWS`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 2,
        },
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_3"),
          content: ({ t }) => t("blockScreen:sns_editor_content_3"),
          placement: "bottom",
          target: `#COACHMARKS_${type}_BULK_ACTIONS`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 3,
        },
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_4"),
          content: ({ t }) => t("blockScreen:sns_editor_content_4"),
          placement: "right",
          target: `#COACHMARKS_${type}_ADD_STANDARDS`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 4,
        },
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_5"),
          content: ({ t }) => t("blockScreen:sns_editor_content_5"),
          placement: "right",
          target: `#COACHMARKS_${type}_REARRANGE_STANDARDS`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 5,
        },
        {
          title: ({ t }) => t("blockScreen:sns_editor_title_7"),
          content: ({ t }) => t("blockScreen:sns_editor_content_7"),
          placement: "right",
          target: `#COACHMARKS_${type}_PUBLISH_BUTTON`,
          nextButtonText: ({ t }) => t("common:done"),
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 6,
        },
      ],
    };
  };
};
export const getScopeAndSequenceViewerCoachMarksObj = ({ params, type }) => {
  return (dispatch, getState) => {
    const subjectLabel = params.subjectLabel;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:sns_viewer_title_1"),
          content: ({ t }) => t("blockScreen:sns_viewer_content_1"),
          placement: "center",
          target: `#COACHMARKS_${type}`,
          disableBeacon: true,
          showSkipButton: false,
          nextButtonText: ({ t }) => t("blockScreen:lets_go"),
          nextButtonSize: "lg",
          tootTipWidth: "30vw",
          stepIndex: 0,
        },
        {
          title: ({ t }) => t("blockScreen:sns_viewer_title_2"),
          content: ({ t }) => t("blockScreen:sns_viewer_content_2"),
          placement: "auto",
          target: `#COACHMARKS_${type}_OUTLINE`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 1,
        },
        {
          title: ({ t }) => t("blockScreen:sns_viewer_title_3"),
          content: ({ t }) => t("blockScreen:sns_viewer_content_3"),
          placement: "bottom",
          target: `#COACHMARKS_${type}_EDIT_BUTTON`,
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 2,
        },
        {
          title: ({ t }) => t("blockScreen:sns_viewer_title_4"),
          content: ({ t }) => t("blockScreen:sns_viewer_content_4"),
          placement: "bottom",
          target: `#COACHMARKS_${type}_PREFERENCES_BUTTON`,
          nextButtonText: ({ t }) => t("common:done"),
          disableBeacon: true,
          tootTipWidth: "28vw",
          stepIndex: 3,
        },
      ],
    };
  };
};
export const getSubjectsAndScopeAndSequenceConfigCoachMarksObj = ({
  params,
  type,
}) => {
  return (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const { firstName } = getStaffBasicDetailsFromCache(userId) || {};
    const isAdmin = ACLStore.can("AdminPortal");
    if (!isAdmin) return {}; // Skip scope and sequence coachmarks for Teachers

    return {
      steps: [
        {
          title: ({ t }) =>
            t("blockScreen:sns_config_title_1_with_name", { name: firstName }),
          content: ({ t }) => t("blockScreen:sns_config_content_1"),
          placement: "center",
          target: `#COACHMARKS_${type}`,
          disableBeacon: true,
          showSkipButton: false,
          nextButtonText: ({ t }) => t("blockScreen:lets_go"),
          nextButtonSize: "lg",
          stepIndex: 0,
        },
        {
          title: ({ t }) => t("blockScreen:sns_config_title_2"),
          content: ({ t }) => t("blockScreen:sns_config_content_2"),
          placement: "left",
          target: `#COACHMARKS_${type}_ENABLED_SUBJECTS`,
          disableBeacon: true,
          stepIndex: 1,
        },
        {
          title: ({ t }) => t("blockScreen:sns_config_title_3"),
          content: ({ t }) => t("blockScreen:sns_config_content_3"),
          placement: "top",
          target: `#COACHMARKS_${type}_DISABLED_SUBJECTS`,
          disableBeacon: true,
          stepIndex: 2,
        },
        {
          title: ({ t }) => t("blockScreen:sns_config_title_4"),
          content: ({ t }) => t("blockScreen:sns_config_content_4"),
          placement: "bottom",
          target: `#COACHMARKS_${type}_CREATE_SUBJECTS`,
          disableBeacon: true,
          stepIndex: 3,
        },
        {
          title: ({ t }) => t("blockScreen:sns_config_title_5"),
          content: ({ t }) => t("blockScreen:sns_config_content_5"),
          placement: "right",
          target: `#COACHMARKS_${type}_VIEWEDIT_SUBJECT`,
          disableBeacon: true,
          stepIndex: 4,
        },
      ],
    };
  };
};

export const getHomeButtonCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:home_button_title"),
          content: ({ t }) => t("blockScreen:home_button_content"),
          placement: "right",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: -28,
        marginTop: -8,
      },
      wrapperZIndex: 400,
    };
  };
};

export const getUnitPlanInsightsAndPrintCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:unit_insight_title"),
          content: ({ t }) => t("blockScreen:unit_insight_content"),
          placement: "bottom",
          disableBeacon: false,
          placementBeacon: "right",
          target: target,
          spotlightClicks: false,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginLeft: "-12px",
        marginTop: "-8px",
      },
      disableOnScroll: true,
    };
  };
};

export const getImportLeInUnitPlanFromCommunityObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const theme = {
      titleStyle: colorTitleStyle,
      buttonContainerStyle: buttonContainerStyle,
      nextButtonStyle: nextButtonStyle,
    };
    return {
      steps: [
        {
          title: ({ t }) =>
            t("blockScreen:search_le_from_community_coachmark_title"),
          content: ({ t }) =>
            t("blockScreen:search_le_from_community_coachmark_content"),
          placement: "right",
          disableBeacon: true,
          target: target,
          spotlightClicks: false,
          isFixed: true,
          tootTipWidth: "28vw",
          theme,
          nextButtonText: ({ t }) =>
            t("blockScreen:search_le_from_community_coachmark_button"),
        },
      ],
    };
  };
};

export const getImportLeInClassroomFromCommunityObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const theme = {
      titleStyle: colorTitleStyle,
      buttonContainerStyle: buttonContainerStyle,
      nextButtonStyle: nextButtonStyle,
    };

    return {
      steps: [
        {
          title: ({ t }) =>
            t("blockScreen:search_le_from_community_coachmark_title"),
          content: ({ t }) =>
            t(
              "blockScreen:classroom_search_le_from_community_coachmark_content"
            ),
          placement: "bottom",
          disableBeacon: true,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
          theme,
          nextButtonText: ({ t }) =>
            t("blockScreen:search_le_from_community_coachmark_button"),
        },
      ],
    };
  };
};

export const getCommunityProfileObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const theme = {
      containerStyle: roundedContainerStyle,
      titleStyle: colorTitleStyle,
      buttonContainerStyle: buttonContainerStyle,
      nextButtonStyle: nextButtonStyle,
    };

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:community_profile_update_title"),
          content: ({ t }) => t("blockScreen:community_profile_update_content"),
          placement: "bottom-end",
          disableBeacon: true,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
          theme,
          showNextButton: false,
        },
      ],
      zIndex: 999,
    };
  };
};

export const getCommunityBookmarkObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          ...getDefaultCoachmarkProperties(),
          title: ({ t }) => t("blockScreen:community_view_bookmarks_title"),
          content: ({ t }) => t("blockScreen:community_view_bookmarks_content"),
          target,
        },
      ],
      zIndex: 999,
    };
  };
};

export const getPlatformSwitchObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;

    return {
      steps: [
        {
          ...getDefaultCoachmarkProperties(),
          title: ({ t }) => t("blockScreen:platform_switch_title"),
          content: ({ t }) => t("blockScreen:platform_switch_content"),
          target,
        },
      ],
      zIndex: 999,
    };
  };
};

export const getCommunityContributeButtonObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const theme = {
      containerStyle: roundedContainerStyle,
      titleStyle: colorTitleStyle,
      buttonContainerStyle: buttonContainerStyle,
      nextButtonStyle: nextButtonStyle,
    };

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:community_contribute_title"),
          content: ({ t }) => t("blockScreen:community_contribute_content"),
          placement: "bottom-end",
          disableBeacon: true,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
          theme,
          showNextButton: false,
        },
      ],
      zIndex: 999,
    };
  };
};

export const getCommunityProfileViewObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const theme = {
      containerStyle: roundedContainerStyle,
      titleStyle: colorTitleStyle,
      buttonContainerStyle: buttonContainerStyle,
      nextButtonStyle: nextButtonStyle,
    };

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:community_profile_view_title"),
          content: ({ t }) => t("blockScreen:community_profile_view_content"),
          placement: "bottom-start",
          disableBeacon: true,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
          theme,
          showNextButton: false,
        },
      ],
      zIndex: 999,
    };
  };
};

export const getCommunityImportLEObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    const theme = {
      containerStyle: roundedContainerStyle,
      titleStyle: colorTitleStyle,
      buttonContainerStyle: buttonContainerStyle,
      nextButtonStyle: nextButtonStyle,
    };

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:community_import_title"),
          content: ({ t }) => t("blockScreen:community_import_content"),
          placement: "bottom-end",
          disableBeacon: true,
          placementBeacon: "top",
          target: target,
          spotlightClicks: false,
          isFixed: true,
          theme,
          showNextButton: false,
        },
      ],
    };
  };
};

export const getCommunityLEBookmarkObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          ...getDefaultCoachmarkProperties(),
          title: ({ t }) => t("blockScreen:community_le_bookmark_title"),
          content: ({ t }) => t("blockScreen:community_le_bookmark_content"),
          target,
        },
      ],
    };
  };
};

export const getCommunityUnitBookmarkObject = ({ params }) => {
  return (dispatch, getState) => {
    const { target } = params;
    return {
      steps: [
        {
          ...getDefaultCoachmarkProperties(),
          title: ({ t }) => t("blockScreen:community_unit_bookmark_title"),
          content: ({ t }) => t("blockScreen:community_unit_bookmark_content"),
          target: target,
        },
      ],
    };
  };
};

export const getCommunityShareObject = ({ params }) => {
  return () => {
    const { target } = params;
    return {
      steps: [
        {
          ...getDefaultCoachmarkProperties({ showNextButton: false }),
          title: ({ t }) => t("blockScreen:community_share_title"),
          content: ({ t }) => t("blockScreen:community_share_content"),
          target: target,
        },
      ],
    };
  };
};

export const getSnpModuleCoachMarksObject = ({ params }) => {
  return (dispatch, getState) => {
    const state = getState();

    const activeTab = state.login.activeTab;
    const userFirstName = state.login.userInfo.first_name;

    const organizationId = _.get(state, "login.userInfo.org_id", "");

    const requirementLabelSetting = getSettingValue({
      organizationId,
      name: "AppSettingSnpRequirementPluralLabel",
    });

    const { availableTabs } = params;

    const isAdminModule = activeTab === "admin";
    const isTeacherModule = activeTab === "teacher";

    const snpModuleLabelSetting = getSettingValue({
      name: "AppSettingSnpModuleLabel",
      organizationId,
    });

    let steps = [];

    if (isTeacherModule) {
      const StarIconElement = () => (
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            fill: colors.yellow50,
          }}
        >
          <StarFilled size={"xxx-small"} />
        </div>
      );

      const teacherStep3Content = () => (
        <p style={{ display: "inline-block" }}>
          <Trans i18nKey="blockScreen:snp_teacher_step_3_content">
            Click on the
            <IconButton
              size={"small"}
              variant={"destructive"}
              icon={<AddOutlined />}
            />
            button to suggest evidences for each practice. Your school admin(s)
            will be able to see the evidences suggested by the teachers and pick
            the final ones. The final evidences will be highlighted with a
            <StarIconElement /> icon.
          </Trans>
        </p>
      );

      steps = [
        {
          title: ({ t }) =>
            t("blockScreen:snp_common_step_1_title", {
              name: userFirstName,
            }),
          content: ({ t }) => {
            const moduleName = t(`snp:${snpModuleLabelSetting}`, {
              defaultValue: snpModuleLabelSetting,
            });

            return t("blockScreen:snp_common_step_1_content", { moduleName });
          },
          placement: "center",
          target: "#COACHMARKS_SNP_HOME",
          disableBeacon: true,
          stepIndex: 0,
          showProgress: true,
          theme: {
            ...snpCoachMarksTheme,
            containerStyle: { marginTop: "-300px", borderRadius: "16px" },
          },
        },
        {
          title: ({ t }) => t("blockScreen:snp_teacher_step_2_title"),
          content: ({ t }) => {
            const requirementLabel = t(`snp:${requirementLabelSetting}`, {
              defaultValue: requirementLabelSetting,
            });

            return t("blockScreen:snp_teacher_step_2_content", {
              requirementLabel,
            });
          },
          placement: "right-start",
          target: "#COACHMARKS_SNP_DASHBOARD_SIDEBAR",
          disableBeacon: true,
          stepIndex: 1,
          theme: snpCoachMarksTheme,
        },
        {
          title: ({ t }) => t("blockScreen:snp_teacher_step_3_title"),
          content: teacherStep3Content,
          placement: "bottom-end",
          target: "#COACHMARKS_SNP_PRACTICE",
          disableBeacon: true,
          stepIndex: 2,
          theme: snpCoachMarksTheme,
        },
        {
          title: ({ t }) => t("blockScreen:snp_common_chat_title"),
          content: ({ t }) => t("blockScreen:snp_teacher_chat_content"),
          placement: "left",
          target: "#COACHMARKS_SNP_CHAT",
          disableBeacon: true,
          stepIndex: 3,
          theme: snpCoachMarksTheme,
        },
      ];
    } else {
      const step1Title = ({ t }) => {
        return isAdminModule
          ? t("blockScreen:snp_admin_step_1_title")
          : t("blockScreen:snp_common_step_1_title", {
              name: userFirstName,
            });
      };

      const conclusionStepContent = ({ t }) => (
        <div style={{ display: "grid", gridTemplateColumns: "88% 12%" }}>
          <div>{t("blockScreen:snp_conclusion_step_content")}</div>
          <Beacon />
        </div>
      );

      const allSteps = [
        {
          title: step1Title,
          content: ({ t }) => {
            const moduleName = t(`snp:${snpModuleLabelSetting}`, {
              defaultValue: snpModuleLabelSetting,
            });

            return t("blockScreen:snp_common_step_1_content", { moduleName });
          },
          placement: "center",
          target: "#COACHMARKS_SNP_HOME",
          disableBeacon: true,
          showProgress: true,
          theme: {
            ...snpCoachMarksTheme,
            containerStyle: { marginTop: "-310px", borderRadius: "16px" },
          },
        },
        {
          title: ({ t }) => t("blockScreen:snp_common_guidance_tab_title"),
          content: ({ t }) =>
            t(`blockScreen:snp_${activeTab}_guidance_tab_content`),
          target: "#COACHMARKS_SNP_HEADER_GUIDANCE",
          tab: "GUIDANCE",
          disableBeacon: true,
          theme: snpCoachMarksTheme,
        },
        {
          title: ({ t }) => t("blockScreen:snp_common_dashboard_tab_title"),
          content: ({ t }) => {
            const requirementLabel = t(`snp:${requirementLabelSetting}`, {
              defaultValue: requirementLabelSetting,
            });

            return t(`blockScreen:snp_${activeTab}_dashboard_tab_content`, {
              requirementLabel,
            });
          },
          target: "#COACHMARKS_SNP_HEADER_DASHBOARD",
          tab: "DASHBOARD",
          disableBeacon: true,
          theme: snpCoachMarksTheme,
        },
        {
          title: ({ t }) => t("blockScreen:snp_common_documents_tab_title"),
          content: ({ t }) =>
            t(`blockScreen:snp_${activeTab}_documents_tab_content`),
          target: "#COACHMARKS_SNP_HEADER_DOCUMENTS",
          tab: "DOCUMENTS",
          disableBeacon: true,
          theme: snpCoachMarksTheme,
        },
        {
          title: ({ t }) => t("blockScreen:snp_conclusion_step_title"),
          content: conclusionStepContent,
          placement: "center",
          target: "#COACHMARKS_SNP_HOME",
          disableBeacon: true,
          theme: {
            ...snpCoachMarksTheme,
            containerStyle: {
              marginTop: "-310px",
              borderRadius: "16px",
            },
          },
          nextButtonText: ({ t }) => t("common:done"),
        },
      ];

      const getFilteredSteps = () => {
        const filteredSteps = _.filter(
          allSteps,
          ({ tab }) => _.isEmpty(tab) || _.includes(availableTabs, tab)
        );

        if (_.size(availableTabs) === 1) {
          return _.map(filteredSteps, step => {
            const { tab = "" } = step;
            if (!_.includes(availableTabs, tab)) return step;
            return {
              ...step,
              target: "#COACHMARKS_SNP_HOME",
              placement: "center",
              theme: { ...step.theme, containerStyle: { marginTop: "-310px" } },
            };
          });
        }

        return filteredSteps;
      };

      const filteredSteps = getFilteredSteps();
      steps = _.map(filteredSteps, (step, index) => ({
        ...step,
        stepIndex: index,
      }));
    }

    return {
      steps,
      startCountIndex: 0,
    };
  };
};

export const getSnpDashboardNavigationCoachMarksObject = () => {
  return (dispatch, getState) => {
    const state = getState();

    const organizationId = _.get(state, "login.userInfo.org_id", "");

    const requirementLabelSetting = getSettingValue({
      organizationId,
      name: "AppSettingSnpRequirementPluralLabel",
    });
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:snp_dashboard_navigation_title"),
          content: ({ t }) => {
            const requirementLabel = t(`snp:${requirementLabelSetting}`, {
              defaultValue: requirementLabelSetting,
            });

            return t("blockScreen:snp_dashboard_navigation_content", {
              requirementLabel,
            });
          },
          placement: "right-start",
          target: "#COACHMARKS_SNP_DASHBOARD_SIDEBAR",
          disableBeacon: false,
          theme: snpCoachMarksTheme,
        },
      ],
      beaconStyles: {
        marginLeft: "-8px",
        marginTop: "28px",
      },
    };
  };
};

export const getSnpDocumentsNavigationCoachMarksObject = () => {
  return (dispatch, getState) => {
    const activeTab = getState().login.activeTab;

    let steps = [
      {
        title: ({ t }) =>
          t("blockScreen:snp_common_documents_navigation_title_1"),
        content: ({ t }) =>
          t(`blockScreen:snp_common_documents_navigation_content_1`),
        target: "#COACHMARKS_SNP_DOCUMENTS_SIDEBAR",
        placement: "right-start",
        placementBeacon: "top",
        showProgress: true,
        stepIndex: 0,
        theme: snpCoachMarksTheme,
      },
      {
        title: ({ t }) =>
          t(`blockScreen:snp_${activeTab}_documents_navigation_title_2`),
        content: ({ t }) =>
          t(`blockScreen:snp_${activeTab}_documents_navigation_content_2`),
        target: "#COACHMARKS_SNP_DOCUMENTS_SIDEBAR",
        placement: "right",
        disableBeacon: true,
        stepIndex: 1,
        theme: snpCoachMarksTheme,
      },
    ];

    if (activeTab === "admin") {
      steps = update(steps, {
        $push: [
          {
            title: ({ t }) =>
              t(`blockScreen:snp_admin_documents_navigation_title_3`),
            content: ({ t }) =>
              t(`blockScreen:snp_admin_documents_navigation_content_3`),
            target: "#COACHMARKS_SNP_DOCUMENTS_SIDEBAR",
            placement: "right-end",
            disableBeacon: true,
            stepIndex: 2,
            theme: snpCoachMarksTheme,
          },
        ],
      });
    }

    return {
      steps,
      startCountIndex: 0,
      beaconStyles: {
        position: "absolute",
        top: 46,
        left: 92,
      },
    };
  };
};

export const getSnpStandardStatusCoachMarksObject = () => {
  return (dispatch, getState) => {
    const activeTab = getState().login.activeTab;

    const TickCircleEmpty = () => (
      <div
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          display: "inline-block",
          border: `1px solid ${colors.borderDark}`,
        }}
      />
    );

    const content = () => {
      return (
        <p>
          {activeTab === "admin" ? (
            <Trans i18nKey={`blockScreen:snp_admin_standard_status_content`}>
              Once you’re done adding all the required evidences for a standard,
              you can mark it done by clicking on the
              <TickCircleEmpty /> icon.
            </Trans>
          ) : (
            <Trans i18nKey={`blockScreen:snp_visitor_standard_status_content`}>
              Once you’re done reviewing the required evidences for a standard,
              you can mark it done by clicking on the
              <TickCircleFilled size={"xx-small"} variant={"subtle"} /> icon.
              This is visible only to the members of the visiting team.
            </Trans>
          )}
        </p>
      );
    };

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:snp_common_standard_status_title"),
          content,
          placement: "right-start",
          placementBeacon: "right",
          target: "#COACHMARKS_SNP_SIDEBAR_TICK",
          theme: snpCoachMarksTheme,
          spotlightClicks: true,
        },
      ],
      beaconStyles: {
        marginTop: "-8px",
      },
    };
  };
};

export const getSnpDashboardEvidencesCoachMarksObject = () => {
  return (dispatch, getState) => {
    const state = getState();

    const organizationId = _.get(state, "login.userInfo.org_id", "");

    const requirementLabelSetting = getSettingValue({
      organizationId,
      name: "AppSettingSnpRequirementPluralLabel",
    });

    const TransComponent = ({ t }) => {
      const requirementLabel = t(`snp:${requirementLabelSetting}`, {
        defaultValue: requirementLabelSetting,
      });

      return (
        <div style={{ display: "inline-block" }}>
          <Trans i18nKey={"blockScreen:snp_admin_dashboard_evidence_content"}>
            Click on the
            <IconButton
              size={"small"}
              variant={"destructive"}
              icon={<AddOutlined />}
            />
            button to add evidence to a practice or {{ requirementLabel }}. You
            can either choose the evidences suggested by teachers or pick from
            anywhere across Toddle platform including unit evidences, classroom,
            insights and much more.
          </Trans>
        </div>
      );
    };

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:snp_teacher_step_3_title"),
          content: ({ t }) => <TransComponent t={t} />,
          placement: "bottom-end",
          placementBeacon: "top-end",
          target: "#COACHMARKS_SNP_PRACTICE",
          theme: snpCoachMarksTheme,
        },
      ],
      beaconStyles: {
        marginLeft: "-240px",
        marginTop: "-68px",
      },
    };
  };
};

export const getSnpInviteVisitingTeamCoachMarksObject = () => {
  return () => {
    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:snp_invite_visiting_team_title"),
          content: ({ t }) => t("blockScreen:snp_invite_visiting_team_content"),
          placement: "bottom-end",
          placementBeacon: "right",
          target: "#COACHMARKS_SNP_INVITE_VISITING_TEAM",
          theme: snpCoachMarksTheme,
          isFixed: true,
        },
      ],
      beaconStyles: {
        marginTop: "-42px",
      },
      zIndex: 1003,
    };
  };
};

export const getSnpChatModuleCoachMarksObject = () => {
  return (dispatch, getState) => {
    const activeTab = getState().login.activeTab;

    return {
      steps: [
        {
          title: ({ t }) => t("blockScreen:snp_common_chat_title"),
          content: ({ t }) => t(`blockScreen:snp_${activeTab}_chat_content`),
          target: "#COACHMARKS_SNP_CHAT",
          placement: "left-start",
          placementBeacon: "right",
          stepIndex: 0,
          showProgress: true,
          theme: snpCoachMarksTheme,
        },
        {
          title: ({ t }) => t("blockScreen:snp_common_chat_title_2"),
          content: ({ t }) => t(`blockScreen:snp_${activeTab}_chat_content_2`),
          target: "#COACHMARKS_SNP_CHAT_HEADER",
          placement: "left-start",
          disableBeacon: true,
          stepIndex: 1,
          theme: snpCoachMarksTheme,
          spotlightClicks: true,
        },
      ],
      startCountIndex: 0,
      beaconStyles: {
        marginTop: "-20px",
      },
    };
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_DATA]: (state, action) => {
    const params = action.payload;
    const type = _.get(params, "type", "");
    if (type) {
      if (!state.coachMarksObj[type]) {
        state = update(state, {
          coachMarksObj: { [type]: { $set: {} } },
        });
      }
      Object.keys(params).map((key, index) => {
        state = update(state, {
          coachMarksObj: { [type]: { [key]: { $set: params[key] } } },
        });
      });
    }
    return state;
  },
  [UPDATE_COACHMARK_STATUS]: (state, action) => {
    const params = action.payload;
    state = update(state, {
      coachMarksViewStatus: { bookmarkStatus: { $set: true } },
    });
    return state;
  },
};

const initialState = {
  coachMarksObj: {},
  coachMarksViewStatus: { bookmarkStatus: false },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
