import { injectReducer } from "store/reducers";
import EvidenceElement from "./routes/EvidenceElement";
import { withAsyncRouteLoading } from "UIComponents";
export default store => ({
  path: "evidence",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/EvidenceModule").default;
            injectReducer(store, { key: "evidence", reducer });
            const post = require("Post/modules/PostModule").default;
            injectReducer(store, {
              key: "post",
              reducer: post,
            });
            const journalReducer = require("Journal/modules/JournalModule")
              .default;
            injectReducer(store, {
              key: "journal",
              reducer: journalReducer,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "unit-planner"
        );
      })
  ),
  childRoutes: [EvidenceElement(store)],
});
