// import PropTypes from 'prop-types';
import React from "react";
import classes from "./GradeComponent.scss";

import ClassCard from "../../ClassCard";
class GradeComponent extends React.PureComponent {
  render() {
    const {
      grade: { id, courses, name },
      onClassSelect,
      showRightPane,
      styles = {},
      showSelectedIcon,
      selectedIds = [],
      showCount = false,
      isMapleBearLikeOrg,
    } = this.props;

    return (
      <div
        className={classes.container}
        style={{ ...(styles.container || {}) }}
      >
        <div className={classes.gradeDiv}>{name}</div>
        <div
          className={classes.gradeCardsDiv}
          style={{ ...(styles.list || {}) }}
        >
          {_.map(courses, (course, index) => {
            return (
              <ClassCard
                course={course}
                key={course.id}
                count={index}
                onClassSelect={onClassSelect}
                showCount={showCount}
                containerStyle={{
                  borderLeftWidth: 4,
                  borderRadius: 4,
                  height: 80,
                }}
                showSelectedIcon={showSelectedIcon}
                selected={_.includes(selectedIds, course.id)}
                isMapleBearLikeOrg={isMapleBearLikeOrg}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default GradeComponent;
