import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, Loading, I18nHOC, withPusherBind } from "UIComponents";

import { getNotificationsQuery } from "modules/CommonQuery";
import { getNotificationListFromCache } from "modules/CommonGraphqlHelpers";
import { CLOUD_URL, colors } from "Constants";
import { getStaffCoursesQuery } from "Teacher/modules/TeacherQuery";
import { getStaffCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";

import { setNotificationCount } from "Teacher/modules/TeacherModule";
import { setCurrentCurriculumProgram } from "Platform/modules/PlatformModule";

import classNames from "classnames";
import classes from "./ListContainer.scss";
import { navigate } from "modules/NavigationModule";
import { checkScrollAtBottom } from "services/checkScrollAtBottom";
import {
  IcnNotiAddedCollaborator,
  IcnNotiAddedGoal,
  IcnNotiAddedPolicy,
  IcnNotiAddedResource,
  IcnNotiAnnouncement,
  IcnNotiParentConnected,
  IcnNotiPostComment,
  IcnNotiPostLike,
  IcnNotiReportEvaluation,
  IcnNotiReportRivision,
  IcnNotiAddedPost,
  IcnNotiAddStudent,
  AddCircleIcon,
  MessageIcon,
  SubmissionIcon,
  FeedbackNotificationIcon,
  IcnNotiSubmissionReturned,
  IcnNotiEvaluationCompleted,
  IcnNotiVideoMeet,
  CameraIcon,
  NoteIconV2,
  FileIconSvg,
  NewLinkIcon,
  VoiceIcon,
  VideoIconSvg,
  LEFilledIcon,
  QuickTaskFilledIcon,
  Formative,
  Summative,
  PersonalProjectIcon,
  CommunityProjectIcon,
} from "SvgComponents";
import {
  EmailPlainOutlined,
  ClockTimerOutlined,
  WorkbookOutlined,
  StudentShareOutlined,
  PlaceholderFileOutlined,
  FolderOutlined,
  EeOutlined,
  CasOutlined,
  TheoryofKnowledgeOutlined,
  PpOutlined,
  SaaOutlined,
  CpOutlined,
  AttendanceOutlined,
  StatusOutlined,
} from "@toddle-design/web-icons";
import {
  notificationCenterOpenMutation,
  notificationCenterReadMutation,
} from "modules/Services";
import List from "./List";

const projectNotificationSubCategries = {
  PERSONAL: {
    svgIcon: <PpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwoD,
  },
  COMMUNITY: {
    svgIcon: <CpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  SERVICE_AS_ACTION: {
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  DP_TOK_ESSAY: {
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  DP_TOK_EXHIBITION: {
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  DP_EE: {
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
};

export const notificationTypes = {
  COMMENT: {
    label: "common:comment",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
  },
  ASSIGNMENT_MESSAGE_SENT: {
    label: "notifications:student_message_sent",
    studentLabel: "notifications:teacher_message_sent",
    svgIcon: <MessageIcon fill={colors.white} width={22} height={22} />,
    bgColor: colors.pink39,
  },
  ASSIGNMENT_CREATE: {
    label: "notifications:new_task",
    svgIcon: <IcnNotiAddedPost fill={colors.white} width={32} height={32} />,
    bgColor: colors.blue29,
    subCategories: {
      ASSIGNMENT_RESOURCE: {
        svgIcon: (
          <QuickTaskFilledIcon fill={colors.white} width={24} height={24} />
        ),
        bgColor: colors.grass,
      },
      ASSESSMENT: {
        svgIcon: <LEFilledIcon fill={colors.white} height={24} width={24} />,
        bgColor: colors.violet63,
      },
      le: {
        svgIcon: <LEFilledIcon fill={colors.white} height={24} width={24} />,
        bgColor: colors.violet63,
      },
      fmt: {
        svgIcon: <Formative fill={colors.white} height={24} width={24} />,
        bgColor: colors.yellow50,
      },
      smt: {
        svgIcon: <Summative fill={colors.white} height={24} width={24} />,
        bgColor: colors.teal42,
      },
      qt: {
        svgIcon: (
          <QuickTaskFilledIcon fill={colors.white} width={24} height={24} />
        ),
        bgColor: colors.grass,
      },
    },
  },
  ZOOM_MEETING_CREATE: {
    label: "notifications:meeting_invitation",
    svgIcon: <IcnNotiAnnouncement fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink48,
  },
  CALENDAR_EVENT_ITEM: {
    label: "notifications:meeting_invitation",
    svgIcon: <IcnNotiVideoMeet fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink48,
  },
  ASSIGNMENT_GROUP_MESSAGE_SENT: {
    label: "notifications:student_group_message_sent",
    svgIcon: <MessageIcon fill={colors.white} width={22} height={22} />,
    bgColor: colors.pink39,
  },
  REPLIED_MESSAGE: {
    label: "notifications:parent_message_sent",
    svgIcon: <MessageIcon fill={colors.white} width={22} height={22} />,
    bgColor: colors.pink39,
  },
  CHAT_MESSAGE_SENT: {
    label: "notifications:parent_message_sent",
    svgIcon: <MessageIcon fill={colors.white} width={22} height={22} />,
    bgColor: colors.pink39,
  },
  COLLABORATOR: {
    label: "notifications:added_as_collaborator",
    svgIcon: (
      <IcnNotiAddedCollaborator fill={colors.white} width={32} height={32} />
    ),
    bgColor: "#5f2945",
  },
  POST: {
    label: "common:tagged_post",
    bgColor: colors.violet63,
    svgIcon: <IcnNotiAddedPost fill={colors.white} width={32} height={32} />,
    subCategories: {
      NOTE: {
        svgIcon: <NoteIconV2 fill={colors.white} width={24} height={24} />,
        bgColor: colors.violet63,
      },
      WORKBOOK: {
        svgIcon: <WorkbookOutlined size="x-small" variant="on" />,
        bgColor: colors.violet63,
      },
      FILE: {
        svgIcon: <FileIconSvg fill={colors.white} width={20} height={20} />,
        bgColor: colors.violet63,
      },
      LINK: {
        svgIcon: <NewLinkIcon fill={colors.white} width={24} height={24} />,
        bgColor: colors.violet63,
      },
      AUDIO: {
        svgIcon: <VoiceIcon fill={colors.white} width={24} height={22} />,
        bgColor: colors.violet63,
      },
      VIDEO: {
        svgIcon: <VideoIconSvg fill={colors.white} width={24} height={24} />,
        bgColor: colors.violet63,
      },
      IMAGE: {
        svgIcon: <CameraIcon fill={colors.white} width={22} height={22} />,
        bgColor: colors.violet63,
      },
      POST: {
        svgIcon: (
          <IcnNotiAddedPost fill={colors.white} width={32} height={32} />
        ),
        bgColor: colors.violet63,
      },
    },
  },
  POST_APPROVAL: {
    label: "journal:approve_post",
    bgColor: colors.violet63,
    svgIcon: <IcnNotiAddedPost fill={colors.white} width={32} height={32} />,
  },
  POST_APPROVAL_WITH_COMMENT: {
    label: "notifications:notif_title_approval_with_comment",
    bgColor: colors.violet63,
    svgIcon: <IcnNotiAddedPost fill={colors.white} width={32} height={32} />,
  },
  POST_REJECT_WITH_COMMENT: {
    label: "notifications:notif_title_reject_with_comment",
    bgColor: colors.violet63,
    svgIcon: <IcnNotiAddedPost fill={colors.white} width={32} height={32} />,
  },
  GOAL_CREATED: {
    label: "notifications:added_a_goal",
    svgIcon: <IcnNotiAddedGoal fill={colors.white} width={32} height={32} />,
    bgColor: "#5f2945",
  },
  TEACHER_RESOURCE: {
    label: "notifications:added_a_resource",
    svgIcon: (
      <IcnNotiAddedResource fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.teal20,
  },
  TEACHER_SCHOOL_POLICY: {
    label: "notifications:added_a_policy",
    svgIcon: <IcnNotiAddedPolicy fill={colors.white} width={32} height={32} />,
    bgColor: "#ffab00",
  },
  STUDENT_ADDED: {
    label: "notifications:new_student",
    bgColor: colors.bar,
    svgIcon: <IcnNotiAddStudent fill={colors.white} width={32} height={32} />,
  },
  LEARNING_ENGAGEMENT: {
    label: "notifications:new_le",
    useDefaultImage: true,
  },
  ASSESSMENT: {
    label: "notifications:new_le",
    useDefaultImage: false,
    svgIcon: <LEFilledIcon fill={colors.white} height={24} width={24} />,
    bgColor: colors.violet63,
  },
  GOAL_COMPLETED: {
    label: "notifications:goal_completed",
    svgIcon: <IcnNotiAddedGoal fill={colors.white} width={32} height={32} />,
    bgColor: "#5f2945",
  },
  GOAL_MILESTONE_COMPLETED: {
    label: "notifications:milestone_completed",
    svgIcon: <IcnNotiAddedGoal fill={colors.white} width={32} height={32} />,
    bgColor: "#5f2945",
  },
  STUDENT_ADDED_TO_PARENT: {
    label: "notifications:new_family_member",
    svgIcon: (
      <IcnNotiParentConnected fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.seaweedTwo,
  },
  ENTITY_RATING: {
    label: "common:likes",
    svgIcon: <IcnNotiPostLike fill={colors.white} width={32} height={32} />,
    bgColor: colors.salmon60,
  },
  ENTITY_LIKE: {
    label: "common:likes",
    svgIcon: <IcnNotiPostLike fill={colors.white} width={32} height={32} />,
    bgColor: colors.salmon60,
  },
  POST_LIKE: {
    label: "common:likes",
    svgIcon: <IcnNotiPostLike fill={colors.white} width={32} height={32} />,
    bgColor: colors.salmon60,
  },
  NEW_DISCUSSION: {
    label: "common:comment",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
  },
  POST_COMMENT: {
    label: "common:comment",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
  },
  PROGRESS_REPORT_EVALUTATION: {
    label: "assessmentEvaluation:evaluation",
    svgIcon: (
      <IcnNotiReportEvaluation fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.blueTwo,
  },
  PROGRESS_REPORT: {
    label: "notifications:progress_report_title",
    svgIcon: (
      <IcnNotiReportEvaluation fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.blueTwo,
  },
  PROGRESS_REPORT_RE_EVALUTATION: {
    label: "common:revision",
    svgIcon: (
      <IcnNotiReportRivision fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.blueTwo,
  },
  CIRCULAR_ADDED: {
    svgIcon: <IcnNotiAnnouncement fill={colors.white} width={32} height={32} />,
    bgColor: colors.teal20,
  },
  STUDENT_ASSIGNMENT_SUBMISSION: {
    label: "notifications:handed_in_submission",
    svgIcon: <SubmissionIcon fill={colors.white} width={20} height={20} />,
    bgColor: colors.teal20,
  },
  STUDENT_ASSIGNMENT_SUBMISSION_FEEDBACK: {
    label: "common:feedback",
    svgIcon: (
      <FeedbackNotificationIcon width={32} height={32} fill={colors.white} />
    ),
    bgColor: colors.teal20,
  },
  ALL_STUDENT_ASSIGNMENT_SUBMISSION: {
    label: "notifications:submission_complete",
    svgIcon: <SubmissionIcon fill={colors.white} width={20} height={20} />,
    bgColor: colors.teal20,
  },
  COMMUNITY_COMMENT: {
    label: "common:comment",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
  },
  COMMUNITY_COMMENT_REPLY: {
    label: "common:comment",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
  },
  STUDENT_ASSIGNMENT_SUBMISSION_UNHAND: {
    label: "classRoom:submission_returned_text",
    svgIcon: (
      <IcnNotiSubmissionReturned fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.yellow50,
  },
  STUDENT_ASSIGNMENT_SHARED: {
    label: "assessmentEvaluation:evaluation_complete",
    svgIcon: (
      <IcnNotiEvaluationCompleted fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.yellow50,
  },
  STUDENT_ASSESSMENT_EVALUATION: {
    label: "assessmentEvaluation:evaluation_complete",
    svgIcon: (
      <IcnNotiEvaluationCompleted fill={colors.white} width={32} height={32} />
    ),
    bgColor: colors.yellow50,
  },
  PERSONAL_PROJECT_INVITATION: {
    label: "notifications:notif_title_invited_project",
    svgIcon: <PpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwoD,
  },
  COMMUNITY_PROJECT_INVITATION: {
    label: "notifications:notif_title_invited_project",
    svgIcon: <CpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  SERVICE_AS_ACTION_INVITATION: {
    label: "notifications:notif_title_invited_project",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  SERVICE_AS_ACTION_ACTIVITY_APPROVAL: {
    label: "notifications:notif_title_approve_activity",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  PERSONAL_PROJECT_STATUS_UPDATE: {
    label: "notifications:notif_title_personal_project_status_update",
    svgIcon: <PpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwoD,
  },
  COMMUNITY_PROJECT_STATUS_UPDATE: {
    label: "notifications:notif_title_community_project_status_update",
    svgIcon: <CpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  COMMUNITY_PROJECT_REPORT_STATUS_UPDATE: {
    label: "notifications:notif_title_community_project_report_status_update",
    svgIcon: <CpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  PERSONAL_PROJECT_REPORT_STATUS_UPDATE: {
    label: "notifications:notif_title_personal_project_report_status_update",
    svgIcon: <PpOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwoD,
  },
  PROJECT_COMMENT_MENTION: {
    label: "notifications:notif_title_project_comment_mention_v2",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
    subCategories: projectNotificationSubCategries,
  },
  SERVICE_AS_ACTION_IN_PROGRESS: {
    label: "notifications:notif_title_service_as_action_in_progress",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  SERVICE_AS_ACTION_IN_PROGRESS_AFTER_APPROVAL: {
    label:
      "notifications:notif_title_service_as_action_in_progress_after_approval",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  SERVICE_AS_ACTION_REVIEW_REQUESTED: {
    label: "notifications:notif_title_service_as_action_review_requested",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  SERVICE_AS_ACTION_COMPLETED: {
    label: "notifications:notif_title_service_as_action_completed",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  COMMUNITY_PROJECT_DEADLINE: {
    label: "notifications:notif_title_project_deadline_reminder",
    svgIcon: <ClockTimerOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  PERSONAL_PROJECT_DEADLINE: {
    label: "notifications:notif_title_project_deadline_reminder",
    svgIcon: <ClockTimerOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  DP_PROJECT_DEADLINE: {
    label: "notifications:notif_title_project_deadline_reminder",
    svgIcon: <ClockTimerOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
  },
  PROJECT_DEADLINE_REMINDER: {
    label: "notifications:notif_title_project_deadline_reminder",
    svgIcon: <ClockTimerOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceOneD,
    subCategories: projectNotificationSubCategries,
  },
  SERVICE_AS_ACTION_PENDING_FOR_APPROVAL: {
    label: "notifications:notif_title_service_as_action_pending_for_approval",
    svgIcon: <SaaOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  UNIT_PLAN_SHARED: {
    label: "notifications:notif_title_unit_plan_shared",
    svgIcon: <StudentShareOutlined variant={"on"} size={"x-small"} />,
    bgColor: "#5f2945",
  },
  SNP_CHAT: {
    label: "common:comment",
    svgIcon: <IcnNotiPostComment fill={colors.white} width={32} height={32} />,
    bgColor: colors.pink39,
  },
  FILE_RESOURCE_SHARED_WITH_STUDENT: {
    label: "notifications:notif_single_file_shared_title",
    svgIcon: <PlaceholderFileOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwo,
  },
  FOLDER_RESOURCE_SHARED_WITH_STUDENT: {
    label: "notifications:notif_single_folder_shared_title",
    svgIcon: <FolderOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwo,
  },
  MULTIPLE_RESOURCE_SHARED_WITH_STUDENT: {
    label: "notifications:notif_multiple_file_shared_title",
    svgIcon: <FolderOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceTwo,
    subCategories: {
      FILE: {
        svgIcon: <PlaceholderFileOutlined variant={"on"} size={"x-small"} />,
        bgColor: colors.decorativeSurfaceTwo,
      },
      FOLDER: {
        svgIcon: <FolderOutlined variant={"on"} size={"x-small"} />,
        bgColor: colors.decorativeSurfaceTwo,
      },
    },
  },
  CAS_ADVISOR_ASSIGNED: {
    label: "notifications:notif_title_cas_advisor_assigned",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_ADVISEE_ASSIGNED: {
    label: "notifications:notif_title_cas_advisor_assigned",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_ADVISOR_FEEDBACK_ADDED: {
    label: "notifications:notif_title_cas_advisor_feedback_addded",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_PROJECT_APPROVAL_REQUESTED: {
    label: "notifications:notif_title_cas_project_approval_requested",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_PROJECT_APPROVED: {
    label: "notifications:notif_title_cas_project_approved",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_PROJECT_REJECTED: {
    label: "notifications:notif_title_cas_project_rejected",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_LO_ACHIEVED: {
    label: "notifications:notif_title_cas_lo_achieved",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  CAS_MARKED_AS_COMPLETE: {
    label: "notifications:notif_title_cas_completed",
    svgIcon: <CasOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  ASSIGNMENT_SHARED_WITH_STUDENT: {
    label: "notifications:new_task",
    svgIcon: <IcnNotiAddedPost fill={colors.white} width={32} height={32} />,
    bgColor: colors.blue29,
    subCategories: {
      ASSIGNMENT_RESOURCE: {
        svgIcon: (
          <QuickTaskFilledIcon fill={colors.white} width={24} height={24} />
        ),
        bgColor: colors.grass,
      },
      ASSESSMENT: {
        svgIcon: <LEFilledIcon fill={colors.white} height={24} width={24} />,
        bgColor: colors.violet63,
      },
      le: {
        svgIcon: <LEFilledIcon fill={colors.white} height={24} width={24} />,
        bgColor: colors.violet63,
      },
      fmt: {
        svgIcon: <Formative fill={colors.white} height={24} width={24} />,
        bgColor: colors.yellow50,
      },
      smt: {
        svgIcon: <Summative fill={colors.white} height={24} width={24} />,
        bgColor: colors.teal42,
      },
      qt: {
        svgIcon: (
          <QuickTaskFilledIcon fill={colors.white} width={24} height={24} />
        ),
        bgColor: colors.grass,
      },
    },
  },
  EE_SUPERVISOR_ASSIGNED: {
    label: "notifications:notif_title_ee_supervisor_assigned",
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  EE_PROPOSAL_APPROVAL_REQUESTED: {
    label: "notifications:notif_title_ee_proposal_approval_requested",
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  EE_PROPOSAL_APPROVED: {
    label: "notifications:notif_title_ee_proposal_approved",
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  EE_FINAL_ESSAY_SUBMITTED: {
    label: "notifications:notif_title_ee_final_essay_submitted",
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  EE_REFLECTION_MARKED_AS_COMPLETE: {
    label: "notifications:notif_title_ee_reflection_marked_as_complete",
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  EE_MARKED_AS_COMPLETE: {
    label: "notifications:notif_title_ee_marked_as_complete",
    svgIcon: <EeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_EXHIBITION_SUPERVISOR_ASSIGNED: {
    label: "notifications:notif_title_tok_exhibition_supervisor_assigned",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_EXHIBITION_PROPOSAL_APPROVED: {
    label: "notifications:notif_title_tok_exhibition_proposal_approved",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_EXHIBITION_FINAL_DRAFT_SUBMITTED: {
    label: "notifications:notif_title_tok_exhibition_final_essay_submitted",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_EXHIBITION_MARKED_AS_COMPLETE: {
    label: "notifications:notif_title_tok_exhibition_marked_as_complete",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_ESSAY_SUPERVISOR_ASSIGNED: {
    label: "notifications:notif_title_tok_essay_supervisor_assigned",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_ESSAY_PROPOSAL_APPROVED: {
    label: "notifications:notif_title_tok_essay_proposal_approved",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_ESSAY_FINAL_DRAFT_SUBMITTED: {
    label: "notifications:notif_title_tok_essay_final_essay_submitted",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_ESSAY_MARKED_AS_COMPLETE: {
    label: "notifications:notif_title_tok_essay_marked_as_complete",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  TOK_ESSAY_INTERACTION_MARKED_AS_COMPLETE: {
    label: "notifications:notif_title_tok_essay_interaction_marked_as_complete",
    svgIcon: <TheoryofKnowledgeOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
  },
  ATTENDANCE_REMINDER: {
    label: "attendance:attendance_reminder",
    svgIcon: <AttendanceOutlined variant="on" size={"x-small"} />,
    bgColor: colors.interactiveThreeDefault,
  },
  PROJECT_APPROVED: {
    label: "notifications:notif_title_project_approved",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
  PROJECT_MARKED_AS_COMPLETED: {
    label: "notifications:notif_title_project_marked_as_complete",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
  PROJECT_OVERALL_STATUS_CHANGED: {
    label: "notifications:notif_title_project_status_changed",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
  PROJECT_FINAL_REPORT_SUBMITTED: {
    label: "notifications:notif_title_final_report_submitted",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
  PROJECT_SUPERVISEE_ASSIGNED: {
    label: "notifications:notif_title_project_supervisee_assigned",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
  PROJECT_SUPERVISOR_ASSIGNED: {
    label: "notifications:notif_title_project_supervisor_assigned",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
  PROJECT_REMOVED_AS_COLLABORATOR: {
    label: "notifications:notif_title_project_removed_as_collaborator",
    svgIcon: <StatusOutlined variant={"on"} size={"x-small"} />,
    bgColor: colors.decorativeSurfaceFiveD,
    subCategories: projectNotificationSubCategries,
  },
};

const styles = {
  loadingStyle: { display: "flex", width: "28px", height: "28px" },
};

class ListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.customRef) {
      props.customRef(this);
    }
  }

  fetchMoreNotifications = e => {
    const container = this.container;
    if (
      this.props.hasNextPage &&
      this.props.getNotifications.networkStatus != 3 &&
      checkScrollAtBottom(container)
    ) {
      this.props.loadMoreNotifications();
    }
    if (this.props.onScroll) {
      this.props.onScroll(e);
    }
  };

  scrollToTop = () => {
    if (this.container) {
      this.container.scrollTop = 0;
    }
  };

  onNotificationClicked = notification => {
    const {
      onNotificationClicked,
      navigate,
      notificationCenterReadMutation,
      userId,
      courses,
      selectedCourseId,
      sourceFrom,
    } = this.props;

    //Over riding user courseId in case of notification having multiple course.
    const cloneNotification = _.cloneDeep(notification);
    const userCourseIds = _.map(courses, course => Number(_.get(course, "id")));
    const notificationCourseIds = _.get(notification, "metadata.courses");
    const notificationCourseId = _.get(notification, "metadata.courseId");
    const notificationCourse_id = _.get(notification, "metadata.course_id");

    const userSelectedCourseId =
      sourceFrom == "sidebar" ? selectedCourseId : "";
    const updatedNotificationCourseId =
      _.first(_.intersection(userCourseIds, notificationCourseIds)) ||
      userSelectedCourseId ||
      notificationCourseId ||
      notificationCourse_id ||
      _.get(userCourseIds, ["0"]);
    cloneNotification.metadata = {
      ...(cloneNotification.metadata || {}),
      courseId: updatedNotificationCourseId,
    };

    notificationCenterReadMutation({
      userId,
      notificationId: cloneNotification.id,
    });
    const course = _.find(
      _.get(notification, "courses", []),
      course => course.id == Number(updatedNotificationCourseId)
    );
    if (!_.isEmpty(course)) {
      this.syncPlatformCurriculum({ course });
    }

    navigate({
      params: cloneNotification,
      target: "notification",
    });

    if (onNotificationClicked) {
      onNotificationClicked(cloneNotification);
    }
  };

  syncPlatformCurriculum = ({ course }) => {
    const {
      currentCurriculumProgramType,
      setCurrentCurriculumProgram,
    } = this.props;
    const notificationCurriculumProgram = _.get(
      course,
      "curriculumProgram",
      {}
    );

    if (
      currentCurriculumProgramType !== notificationCurriculumProgram.type &&
      !_.isEmpty(notificationCurriculumProgram)
    ) {
      setCurrentCurriculumProgram(notificationCurriculumProgram);
    }
  };

  renderNotifications = () => {
    const {
      props: { notifications, user_type },
    } = this;

    return (
      <div
        ref={ref => (this.containerDiv = ref)}
        className={classes.notificationsContainer}
      >
        {_.get(notifications, "length", 0) > 0 ? (
          <List
            data={notifications}
            user_type={user_type}
            onNotificationClicked={this.onNotificationClicked}
            notificationTypes={notificationTypes}
          />
        ) : null}
        {this.props.getNotifications.networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading loadingStyle={styles.loadingStyle} />
          </div>
        )}
      </div>
    );
  };

  renderEmptyState = () => {
    return (
      <div className={classes.emptyState}>
        {this.props.t("notifications:no_notifications")}
      </div>
    );
  };

  render() {
    const {
      props: { notifications },
    } = this;

    return (
      <div
        onScroll={this.fetchMoreNotifications}
        className={classes.container}
        ref={ref => (this.container = ref)}
      >
        {_.get(notifications, "length", 0) > 0
          ? this.renderNotifications()
          : this.renderEmptyState()}
      </div>
    );
  }

  componentDidMount = () => {
    setTimeout(() => {
      if (!this.props.isLoading && this.props.showNotificationCenter) {
        this.props.notificationCenterOpenMutation({
          userId: this.props.userId,
          activeTabFilterValue: this.props.activeTabFilterValue,
        });
      }
    });
  };

  componentWillUnmount = () => {
    this.props.getNotifications.refetch();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      ((!nextProps.isLoading && this.props.isLoading) ||
        !_.isEqual(
          nextProps.activeTabFilterValue,
          this.props.activeTabFilterValue
        )) &&
      this.props.showNotificationCenter
    ) {
      setTimeout(() => {
        this.props.notificationCenterOpenMutation({
          userId: this.props.userId,
          activeTabFilterValue: nextProps.activeTabFilterValue,
        });
      });
    }
    if (
      nextProps.showNotificationCenter !== this.props.showNotificationCenter
    ) {
      if (this.container) {
        this.container.scrollTop = 0;
      }
      // this.props.getNotifications.refetch();
    }
  }
}

const mapActionCreators = {
  notificationCenterOpenMutation,
  notificationCenterReadMutation,
  setNotificationCount,
  navigate,
  setCurrentCurriculumProgram,
};

const mapStateToProps = (state, ownProps) => {
  const selectedCourseId = _.get(
    state,
    "teacher.selected_class.selected_course"
  );
  const currentCurriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType"
  );

  return {
    userId: state.login.userInfo.id,
    userEntityType: state.login.userInfo.userEntityType,
    user_type: state.login.userInfo.user_type,
    selectedCourseId,
    currentCurriculumProgramType,
  };
};

export default compose(
  I18nHOC({ resource: ["notifications"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getNotificationsQuery, {
    name: "getNotifications",
    options: ({ userId, activeTabFilterValue, userEntityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: userEntityType,
        notificationCategoryFilter: activeTabFilterValue,
      },
    }),
    props({
      getNotifications,
      ownProps: { userId, activeTabFilterValue, userEntityType },
    }) {
      const notificationData = getNotificationListFromCache({
        id: userId,
        type: userEntityType,
        notificationCategoryFilter: activeTabFilterValue,
      });

      const notification = _.get(notificationData, "notification", []);

      const isLoading =
        getNotifications["networkStatus"] == 1 ||
        getNotifications["networkStatus"] == 2;
      return {
        getNotifications,
        isData: !_.isEmpty(notification),
        notifications: _.map(
          _.get(notification, "edge", []),
          item => item.node
        ),
        isLoading:
          getNotifications["networkStatus"] == 1 ||
          getNotifications["networkStatus"] == 2 ||
          getNotifications["networkStatus"] == 4,
        hasNextPage: _.get(
          getNotifications.node,
          "notification.pageInfo.hasNextPage",
          false
        ),
        onPusherEventTriggered: () => getNotifications.refetch(),
        loadMoreNotifications: () => {
          const endCursor = _.get(
            getNotifications.node,
            "notification.pageInfo.endCursor",
            null
          );

          return getNotifications.fetchMore({
            query: getNotificationsQuery,
            variables: {
              after: endCursor,
              id: userId,
              type: userEntityType,
              notificationCategoryFilter: activeTabFilterValue,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousNotifications =
                previousResult.node.notification.edge;
              const newNotifications = fetchMoreResult.node.notification.edge;

              return {
                ...previousResult,
                node: {
                  ...previousResult.node,
                  notification: {
                    ...previousResult.node.notification,
                    pageInfo: {
                      ...previousResult.node.notification.pageInfo,
                      ...fetchMoreResult.node.notification.pageInfo,
                    },
                    edge: [...previousNotifications, ...newNotifications],
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withPusherBind(["notificationAdded"]),
  withLoader
)(ListContainer);
