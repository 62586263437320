import gql from "graphql-tag";
import { plannerElementNodeFragment } from "modules/CommonFragments";

export const getMultiLevelNodeDraftQuery = gql`
  query multiLevelNodeDraft($draftId: ID!) {
    node(id: $draftId, type: MULTI_LEVEL_NODE_DRAFT) {
      ... on MultiLevelNodeDraft {
        id
        nodes
        rootNodes
        subjectId
        type
        levels {
          id
          value
        }
        maxDepth
      }
    }
  }
`;

export const getSubjectDetailsQuery = gql`
  query getSubjectDetails($subject: ID!) {
    node(id: $subject, type: SUBJECT) {
      id
      ... on Subject {
        id
        name
        grades {
          id
          name
        }
        benchmarkRootNode {
          id
          label
        }
        variants {
          level {
            id
            label
            type
          }
        }
      }
    }
  }
`;

export const getPlannerElementSetQuery = gql`
  query getPlannerElementSet($id: ID!) {
    node(id: $id, type: PLANNER_ELEMENT_SET) {
      id
      ... on PlannerElementSet {
        id
        label
        type
        nodes {
          ...plannerElementNodeItem
        }
      }
    }
  }
  ${plannerElementNodeFragment.plannerElementNode}
`;
