import client from "apolloClient";
import {
  getAssessedDataForProjectQuery,
  getAssessmentToolForProjectGroupQuery,
  getOrganizationAcademicCriteriaValuesQuery,
  getPlannerElementSetNodeQuery,
  getProjectAcademicCriteriaValueQuery,
  getProjectAssessmentRemarkQuery,
} from "./Queries";

export const getAssessmentToolForProjectGroupFromCache = params => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getAssessmentToolForProjectGroupQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAssessedDataForProjectFromCache = params => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getAssessedDataForProjectQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeAssessedDataForProjectInCache = ({ variables, data }) => {
  let queryData = {};

  try {
    queryData = client.writeQuery({
      query: getAssessedDataForProjectQuery,
      variables,
      data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getProjectAssessmentRemarkFromCache = ({ projectId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProjectAssessmentRemarkQuery,
      variables: {
        id: projectId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPlannerElementSetNodeFromCache = ({
  plannerElementSetId,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getPlannerElementSetNodeQuery,
      variables: {
        id: plannerElementSetId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getOrganizationAcademicCriteriaValuesFromCache = ({
  organisationId,
  filters,
  finalGradeFilters,
  fetchFinalGrade,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationAcademicCriteriaValuesQuery,
      variables: {
        id: organisationId,
        filters,
        finalGradeFilters,
        fetchFinalGrade,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getProjectAcademicCriteriaValueFromCache = ({ projectId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProjectAcademicCriteriaValueQuery,
      variables: {
        id: projectId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeProjectAcademicCriteriaValueInCache = ({
  projectId,
  data,
}) => {
  try {
    client.writeQuery({
      query: getProjectAcademicCriteriaValueQuery,
      variables: {
        id: projectId,
      },
      data: { node: data },
    });
  } catch (e) {
    console.warn("Unable to write : ", e);
  }
};
