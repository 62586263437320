import gql from "graphql-tag";

import {
  commentNodeFragment,
  totalCommentNodeFragment,
  notificationFragment,
  staffFragment,
  organizationFragment,
  organizationPypElementSetFragment,
  plannerTemplateFragment,
  organizationGradesFragment,
  organizationUnitPlanFeedFragment,
  studentAttendanceSummaryFragment,
  plannerTemplateHelpTextFragment,
  organizationCoursesFragment,
  designationFragment,
  pypElementFragment,
  zoomMeetingNodeFragment,
  attachmentFragment,
  userIdentityDetailFragment,
  studentFragment,
  notificationListNodeFragment,
  organizationAttendanceOptionFragment,
  plannerElementSetFragment,
  subjectFragment,
  thirdPartyAccountFragment,
  curriculumProgramFragment,
  parentFragment,
  childFragment,
  criteriaSetFragment,
  pypElementRatingFragment,
  plannerElementSetWithDepthLabelFragment,
  staffUnitPlanFragment,
  gradeFragment,
  academicYearFragment,
  nodeEditorFragment,
  subjectGroupFragment,
  messageFragment,
  plannerElementNodeFragment,
  userFragment,
  yearGroupFragments,
  projectGroupFragments,
  pageInfoFragment,
  identityUserFragment,
} from "./CommonFragments";

import { rubricFragment } from "Rubric/modules/RubricFragments";
import { checklistFragment } from "Checklist/modules/ChecklistFragments";
import { singlePointRubricFragment } from "SinglePointRubric/modules/SinglePointRubricFragments";
import { assessmentToolFragment } from "modules/AssessmentTool/AssessmentToolFragment";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";

export const getAttachmentQuery = gql`
  query getAttachment($id: ID!) {
    node(id: $id, type: ATTACHMENT) {
      id
      ...attachmentItem
    }
  }
  ${attachmentFragment.attachment}
`;

export const getOrganizationStudentListQuery = gql`
  query getStudentList(
    $organizationId: ID!
    $searchText: String
    $grades: [ID!]
    $courses: [ID!]
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ... on Organization {
        id
        students(
          filters: {
            searchText: $searchText
            grades: $grades
            courses: $courses
          }
        ) {
          edges {
            node {
              grades
              ...studentItem
            }
          }
        }
      }
    }
  }
  ${studentFragment.basicStudentDetails}
`;

export const getGlobalConstantsQuery = gql`
  query getGlobalConstantsQuery {
    globalConstants {
      designations {
        ...designationItem
      }
    }
  }
  ${designationFragment.designation}
`;
export const getOrganizationCoursesQuery = gql`
  query getOrganizationCoursesQuery(
    $organizationId: ID!
    $filters: CourseFilterInput
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationCourseItem
      }
    }
  }
  ${organizationCoursesFragment.organizationCourses}
`;

export const getOrganizationBasicCoursesQuery = gql`
  query getOrganizationCoursesQuery(
    $organizationId: ID!
    $filters: CourseFilterInput
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationCourseItem
      }
    }
  }
  ${organizationCoursesFragment.organizationBasicCourses}
`;

export const getOrganizationGradeBasicDetailsQuery = gql`
  query getOrganizationGrades(
    $organizationId: ID!
    $filters: GradeFilterInput
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationGradesItem
      }
    }
  }
  ${organizationGradesFragment.organizationGrades}
`;

export const getPlatformUserDetailsQuery = gql`
  query getPlatformUserDetails($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        ...staffItem
        ...staffCoachMarksItem
        firstLoggedInAt
        type
        settings {
          name
          value
        }
        designation {
          id
          title
        }
        allCourses {
          id
          academicYears {
            id
          }
          curriculumProgram {
            ...curriculumProgramBasicDetailsItem
          }
        }
        jwt
        userIdentityDetails {
          ...userIdentityDetailItem
        }
        linkedIdentityUsersV2 {
          totalCount
        }
        linkedIdentities {
          id
        }
        organization {
          id
          name
          tier {
            plan
            isFree
          }
          snpSet {
            id
          }
          academicYears {
            id
            isCurrentAcademicYear
          }
          curriculumPrograms {
            ...subscriptionPlanDetailsItem
          }
        }
      }
      ... on Student {
        ...studentItem
        ...studentCoachMarksItem
        type
        settings {
          name
          value
        }
        allCourses {
          id
          academicYears {
            id
          }
          curriculumProgram {
            ...curriculumProgramBasicDetailsItem
          }
        }
        jwt
        linkedIdentityUsersV2 {
          totalCount
        }
        organization {
          id
          name
          tier {
            plan
            isFree
          }
          academicYears {
            id
            isCurrentAcademicYear
          }
          curriculumPrograms {
            ...subscriptionPlanDetailsItem
          }
        }
      }
      ... on FamilyMember {
        ...parentItem
        communicationState {
          id
          messageType
          isSeen
          appType
        }
        settings {
          name
          value
        }
        children {
          ...childItem
          curriculumPrograms {
            id
            type
          }
          organization {
            id
            name
            tier {
              plan
              isFree
            }
            snpSet {
              id
            }
            academicYears {
              id
              isCurrentAcademicYear
            }
            curriculumPrograms {
              ...subscriptionPlanDetailsItem
            }
          }
          allCourses {
            id
            academicYears {
              id
            }
            curriculumProgram {
              ...curriculumProgramBasicDetailsItem
            }
          }
          curriculumProgram {
            ...curriculumProgramBasicDetailsItem
          }
        }
      }
    }
  }
  ${curriculumProgramFragment.subscriptionPlanDetails}
  ${childFragment.basicChildDetails}
  ${parentFragment.basicParentDetails}
  ${studentFragment.basicStudentDetails}
  ${studentFragment.studentCoachMarks}
  ${staffFragment.basicStaffDetails}
  ${staffFragment.staffCoachMarks}
  ${userIdentityDetailFragment.userIdentityDetail}
  ${curriculumProgramFragment.curriculumProgramBasicDetails}
`;

export const getUserDetailsWithLinkedIdentitiesQuery = gql`
  query getUserDetailsWithLinkedIdentities(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $first: Int
    $after: String
    $searchText: String
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        linkedIdentityUsersV2(
          first: $first
          after: $after
          searchText: $searchText
        ) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edgesV2 {
            node {
              ...identityUserBasicDetailsItem
            }
          }
        }
      }
      ... on Student {
        id
        linkedIdentityUsersV2(
          first: $first
          after: $after
          searchText: $searchText
        ) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edgesV2 {
            node {
              ...identityUserBasicDetailsItem
            }
          }
        }
      }
    }
  }

  ${identityUserFragment.basicDetails}
`;

export const getUserDetailsWithLinkedIdentitiesV1Query = gql`
  query getUserDetailsWithLinkedIdentitiesV1(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        linkedIdentities {
          ...linkedIdentityDetailItem
        }
      }
    }
  }
  ${staffFragment.userLinkedIdentityDetail}
`;

export const getOrganizationDetailsQuery = gql`
  query getOrganizationDetails($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationDetails}
`;

export const getOrganizationPeriodSetsQuery = gql`
  query getOrganizationPeriodSets(
    $id: ID!
    $periodSetFilter: PeriodSetFilterOptions
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        curriculumPrograms {
          ...curriculumProgramItem
        }
      }
    }
  }
  ${curriculumProgramFragment.periodSet}
`;

export const getOrganizationPypConstantsQuery = gql`
  query getOrganizationPypConstant($organizationId: ID!) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationPypConstants}
`;

export const getOrganizationCurriculumProgramsQuery = gql`
  query getOrganizationCurriculumPrograms($organizationId: ID!) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...curriculumProgramsItem
      }
    }
  }
  ${organizationFragment.curriculumPrograms}
`;

export const getPlannerElementSetsOfCurriculumQuery = gql`
  query getPlannerElementSetsOfCurriculum(
    $id: ID!
    $elementTypes: [PLANNER_ELEMENT_TYPE_ENUM]
    $filters: PlannerElementFilters
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      id
      ... on IbMypCurriculumProgram {
        id
        plannerElementSets(elementTypes: $elementTypes) {
          ...plannerElementSetWithNodesItem
        }
      }
      ... on UBDCurriculumProgram {
        id
        plannerElementSets(elementTypes: $elementTypes) {
          ...plannerElementSetWithNodesItem
        }
      }
      ... on IbDpCurriculumProgram {
        id
        plannerElementSets(elementTypes: $elementTypes) {
          ...plannerElementSetWithNodesItem
        }
      }
    }
  }

  ${plannerElementSetFragment.plannerElementSetWithNodes}
`;

export const getYearGroupAssociatedChildPlannerElementNodesQuery = gql`
  query getYearGroupAssociatedChildPlannerElementNodesQuery(
    $id: ID!
    $filters: AssociatedChildPlannerElementNodesFilters
  ) {
    node(id: $id, type: YEAR_GROUP) {
      ... on YearGroup {
        id
        associatedChildPlannerElementNodes(filters: $filters) {
          ...plannerElementNodeItem
        }
      }
    }
  }
  ${plannerElementNodeFragment.plannerElementNode}
`;

export const getPlannerElementNodesOfSetQuery = gql`
  query getPlannerElementNodesOfSet($id: ID!, $filters: PlannerElementFilters) {
    node(id: $id, type: PLANNER_ELEMENT_SET) {
      id
      ... on PlannerElementSet {
        ...plannerElementSetWithNodesItem
      }
    }
  }
  ${plannerElementSetFragment.plannerElementSetWithNodes}
`;

export const getPlannerElementNodesOfSetWithChildQuery = gql`
  query getPlannerElementNodesOfSetWithChild(
    $id: ID!
    $filters: PlannerElementFilters
  ) {
    node(id: $id, type: PLANNER_ELEMENT_SET) {
      id
      ... on PlannerElementSet {
        ...plannerElementSetWithNodesAndChildItem
      }
    }
  }
  ${plannerElementSetFragment.plannerElementSetWithNodesAndChild}
`;

export const getPlannerElementNodesOfSetWithDepthLabelQuery = gql`
  query getPlannerElementNodesOfSet(
    $id: ID!
    $filters: PlannerElementFilters
    $plannerElementDepthLabelSetFilters: PlannerElementDepthLabelSetFilters
  ) {
    node(id: $id, type: PLANNER_ELEMENT_SET) {
      id
      ... on PlannerElementSet {
        ...plannerElementSetWithNodesItem
      }
    }
  }
  ${plannerElementSetWithDepthLabelFragment.plannerElementSetWithNodes}
`;

export const getOrganizationConstantsQuery = gql`
  query getOrganizationConstant($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationConstants}
`;

export const getCommentOfNodeQuery = gql`
  query getCommentOfNode(
    $id: ID!
    $after: String
    $type: ENTITY_TYPE_ENUM!
    $isCommunity: Boolean!
  ) {
    node(id: $id, type: $type) {
      id
      ... on PlannerField {
        ...plannerFieldNodeItem
      }
      ... on Assessment {
        ...assessmentNodeItem @skip(if: $isCommunity)
        ...communityAssessmentItem @include(if: $isCommunity)
      }
      ... on LearningEngagement {
        ...leNodeItem
      }
      ... on PlannerFlowSection {
        ...flowSectionItem
      }
      ... on Post {
        ...postItem
      }
      ... on GoalTask {
        ...studentGoalTaskItem
      }
      ... on UnitPlan {
        id
        ...communityUnitPlanItem @include(if: $isCommunity)
      }
    }
  }
  ${commentNodeFragment.flowSection}
  ${commentNodeFragment.le}
  ${commentNodeFragment.assessment}
  ${commentNodeFragment.plannerField}
  ${commentNodeFragment.post}
  ${commentNodeFragment.studentGoalTask}
  ${commentNodeFragment.studentGoalTask}
  ${commentNodeFragment.communityUnitPlan}
  ${commentNodeFragment.communityAssessment}
`;

export const getTotalCommentOfNodeQuery = gql`
  query getTotalCommentOfNode(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $isCommunity: Boolean!
  ) {
    node(id: $id, type: $type) {
      id
      ... on PlannerField {
        ...plannerFieldNodeItem
      }
      ... on Assessment {
        ...assessmentNodeItem @skip(if: $isCommunity)
        ...communityAssessmentItem @include(if: $isCommunity)
      }
      ... on LearningEngagement {
        ...leNodeItem
      }
      ... on PlannerFlowSection {
        ...flowSectionItem
      }
      ... on Post {
        ...postItem
      }
      ... on GoalTask {
        ...studentGoalTaskItem
      }
      ... on UnitPlan {
        id
        ...communityUnitPlanItem @include(if: $isCommunity)
      }
    }
  }
  ${totalCommentNodeFragment.flowSection}
  ${totalCommentNodeFragment.le}
  ${totalCommentNodeFragment.assessment}
  ${totalCommentNodeFragment.plannerField}
  ${totalCommentNodeFragment.post}
  ${totalCommentNodeFragment.studentGoalTask}
  ${totalCommentNodeFragment.communityUnitPlan}
  ${totalCommentNodeFragment.communityAssessment}
`;

export const getNotificationsQuery = gql`
  query getNotifications(
    $id: ID!
    $after: String
    $type: ENTITY_TYPE_ENUM!
    $notificationCategoryFilter: [NOTIFICATION_CATEGORY_ENUM!]
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        ...staffItem
      }
      ... on Student {
        ...studentItem
      }
      ... on FamilyMember {
        ...parentItem
      }
    }
  }
  ${notificationListNodeFragment.staff}
  ${notificationListNodeFragment.student}
  ${notificationListNodeFragment.parent}
`;

export const getNotificationsCountQuery = gql`
  query getNotificationsCount(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $notificationCategoryFilter: [NOTIFICATION_CATEGORY_ENUM!]
  ) {
    node(id: $id, type: $type) {
      ... on Staff {
        ...staffCountItem
      }
      ... on Student {
        ...studentCountItem
      }
      ... on FamilyMember {
        ...parentCountItem
      }
    }
  }
  ${notificationFragment.studentCountItem}
  ${notificationFragment.staffCountItem}
  ${notificationFragment.parentCountItem}
`;

export const getRelatedConceptSetQuery = gql`
  query getRelatedConceptSet(
    $subjects: [ID!]
    $organizationId: ID!
    $concepts: [ID!]
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationPypElementSetItem
      }
    }
  }

  ${organizationPypElementSetFragment.relatedConceptSet}
`;

export const getAtlSetQuery = gql`
  query getAtlSet($grades: [ID!], $organizationId: ID!) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationPypElementSetItem
      }
    }
  }

  ${organizationPypElementSetFragment.atlSet}
`;

export const getBenchmarkSetQuery = gql`
  query getBenchmarkSet(
    $subjects: [ID!]
    $grades: [ID!]
    $organizationId: ID!
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationPypElementSetItem
      }
    }
  }
  ${organizationPypElementSetFragment.benchmarkSet}
`;

export const getMultiLevelNodesQuery = gql`
  query getMultiLevelNodes(
    $subjectId: ID
    $organizationId: ID!
    $type: MULTI_LEVEL_NODE_TYPE!
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        getMultiLevelNodes(input: { type: $type, subjectId: $subjectId }) {
          rootNodes
          levels {
            id
            value
          }
          nodes {
            ...MultiLevelNodeBenchmarkItem
            ...MultiLevelNodePlannerElementItem
            ...plannerElementNodeItem
            ...benchmarkItem
          }
          levels {
            id
            value
          }
          maxDepth
          draftId
        }
      }
    }
  }
  ${nodeEditorFragment.MultiLevelNodeBenchmarkDetails}
  ${nodeEditorFragment.MultiLevelNodePlannerElementDetails}
  ${plannerElementNodeFragment.plannerElementNode}
  ${pypElementFragment.benchmarkV2}
`;

export const getPlannerTemplateQuery = gql`
  query getPlannerTemplate($ids: [ID!]) {
    multiNode(ids: $ids, type: PLANNER_TEMPLATE) {
      id
      ... on PlannerTemplate {
        ...templateItem
      }
    }
  }
  ${plannerTemplateFragment.plannerTemplate}
`;

export const getOrganizationUnitPlanFeedQuery = gql`
  query getOrganizationUnitPlanFeed(
    $id: ID!
    $grades: [ID!]
    $scheduledStatus: UNIT_PLAN_SCHEDULED_STATUS_ENUM
    $type: UNIT_PLAN_TYPE_ENUM
    $searchText: String
    $academicYears: [ID!]
    $getPlanathonUnits: Boolean
    $users: [ID!]
    $removePlanathonUnits: Boolean
    $showCollaborators: Boolean! = true
    $createdBy: [ID!]
    $curriculumProgramIds: [ID!]
    $subjects: [ID!]
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationUnitPlanFeedItem
      }
    }
  }
  ${organizationUnitPlanFeedFragment.organizationUnitPlanFeed}
`;

export const getOrganizationUnitPlanFeedV2Query = gql`
  query getOrganizationUnitPlanFeed(
    $id: ID!
    $filters: UnitPlansV2FeedFilters!
    $showCollaborators: Boolean! = true
    $after: String
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationUnitPlanFeedItem
      }
    }
  }
  ${organizationUnitPlanFeedFragment.organizationUnitPlanFeedV2}
`;

export const getOrganizationUnitPlanFeedCountQuery = gql`
  query getOrganizationUnitPlanFeed(
    $id: ID!
    $grades: [ID!]
    $scheduledStatus: UNIT_PLAN_SCHEDULED_STATUS_ENUM
    $type: UNIT_PLAN_TYPE_ENUM
    $searchText: String
    $academicYears: [ID!]
    $getPlanathonUnits: Boolean
    $users: [ID!]
    $removePlanathonUnits: Boolean
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationUnitPlanFeedCountItem
      }
    }
  }
  ${organizationUnitPlanFeedFragment.organizationUnitPlanFeedCount}
`;

export const getOrganizationAttendanceOptionsQuery = gql`
  query getOrganizationAttendanceOptions($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        constants {
          attendanceOptions {
            ...organizationAttendanceOptionItem
          }
        }
      }
    }
  }
  ${organizationAttendanceOptionFragment.organizationAttendanceOption}
`;

export const getCurriculumAttendanceOptionsQuery = gql`
  query getCurriculumAttendanceOptionsQuery(
    $id: ID!
    $curriculumId: [ID]
    $filter: AttendanceOptionsSetFilterOptions
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        curriculumPrograms(curriculumIds: $curriculumId) {
          attendanceOptionSet(filter: $filter) {
            id
            attendanceOptions {
              ...organizationAttendanceOptionItem
            }
          }
        }
      }
    }
  }
  ${organizationAttendanceOptionFragment.organizationAttendanceOption}
`;

export const getStudentAttendanceSummaryQuery = gql`
  query getStudentAttendanceSummary(
    $studentId: ID!
    $courseId: ID!
    $startDate: String!
    $endDate: String!
    $groupingType: ATTENDANCE_SUMMARY_GROUPING_TYPE_ENUM
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentAttendanceSummaryItem
      }
    }
  }
  ${studentAttendanceSummaryFragment.studentAttendanceSummary}
`;

export const getHelpTextQuery = gql`
  query getHelpText($id: ID!, $locale: String) {
    planner {
      i18nHelpText(id: $id, locale: $locale) {
        ...helpTextItem
      }
    }
  }
  ${plannerTemplateHelpTextFragment.plannerTemplateHelpText}
`;

export const getAssessmentToolNodeDetailsQuery = gql`
  query getAssessmentToolNodeDetails($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on Rubric {
        ...rubricItem
      }
      ... on Checklist {
        ...checklist
      }
      ... on SinglePointRubric {
        ...singlePointRubricItem
      }
      ... on Exemplar {
        ...exemplarItem
      }
      ... on Anecdote {
        ...anecdoteItem
      }
      ... on MypObjectiveRubric {
        ...mypObjectiveRubricItem
      }
    }
  }
  ${rubricFragment.rubric}
  ${checklistFragment.checklist}
  ${singlePointRubricFragment.singlePointRubric}
  ${assessmentToolFragment.mypObjectiveRubric}
  ${assessmentToolFragment.exemplar}
  ${assessmentToolFragment.anecdote}
`;

export const getSubjectBenchmarksQuery = gql`
  query getSubjectBenchmarks($subject: ID!, $grades: [ID!]) {
    node(id: $subject, type: SUBJECT) {
      id
      ... on Subject {
        id
        isDemo
        benchmarks(grades: $grades) {
          ...benchmarkItem
        }
      }
    }
  }
  ${pypElementFragment.benchmark}
`;

export const getATLsQuery = gql`
  query getALTs($id: ID!, $grades: [ID!]) {
    node(id: $id, type: ATL_CATEGORY) {
      id
      ... on ATLCategory {
        id
        atls(grades: $grades) {
          ...atlItem
        }
      }
    }
  }
  ${pypElementFragment.atl}
`;

export const getUserZoomAuthorizedStatusQuery = gql`
  query getUserZoomAuthorizedStatus($id: ID!) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        isZoomAuthorized
      }
    }
  }
`;

export const getZoomMeetingNodeQuery = gql`
  query getZoomMeetingNode($parentId: ID!, $parentType: ENTITY_TYPE_ENUM!) {
    node(id: $parentId, type: $parentType) {
      id
      ... on Course {
        ...courseZoomMeetingItem
      }
    }
  }
  ${zoomMeetingNodeFragment.courseZoomMeeting}
`;

export const getResourceQuery = gql`
  query getResource($id: ID!) {
    node(id: $id, type: PLANNER_RESOURCE) {
      id
      ... on PlannerResource {
        id
        title
        description
        attachments {
          ...attachmentItem
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const getUserThirdPartyAuthorizedStatusQuery = gql`
  query getUserThirdPartyAuthorizedStatus(
    $id: ID!
    $serviceType: THIRD_PARTY_SERVICE_TYPE
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        linkedThirdPartyAccounts(serviceType: $serviceType) {
          ...thirdPartyAccountItem
        }
      }
      ... on Student {
        id
        linkedThirdPartyAccounts(serviceType: $serviceType) {
          ...thirdPartyAccountItem
        }
      }
      ... on FamilyMember {
        id
        linkedThirdPartyAccounts(serviceType: $serviceType) {
          ...thirdPartyAccountItem
        }
      }
    }
  }
  ${thirdPartyAccountFragment.thirdPartyAccount}
`;

export const getSubjectsByGradeQuery = gql`
  query getSubjectsByGrade($id: ID!) {
    node(id: $id, type: GRADE) {
      id
      ... on Grade {
        id
        name
        subjects {
          id
          name
          icon
        }
      }
    }
  }
`;

export const getSubjectGroupDetailsQuery = gql`
  query getSubjectGroupDetails($id: ID!) {
    node(id: $id, type: SUBJECT_GROUP) {
      id
      ... on SubjectGroup {
        id
        name
        subjects {
          ...subjectItem
        }
      }
    }
  }
  ${subjectFragment.basicSubjectDetails}
`;

export const getDpSubjectVariantsQuery = gql`
  query getOrganizationSubjectVariants($id: ID!) {
    node(id: $id, type: IB_DP_CURRICULUM_PROGRAM) {
      ... on IbDpCurriculumProgram {
        id
        subjectVariants {
          subject {
            name
            id
            icon
            ibOfferedSubject {
              id
            }
          }
          subjectGroup {
            id
            name
            type
            globalSubjectGroup {
              id
              name
              type
            }
          }
          level {
            id
            label
            globalTag {
              id
            }
          }
          responseLanguages {
            id
            label
            globalTag {
              id
            }
          }
        }
      }
    }
  }
`;

export const getIbOfferedSubjectVariants = gql`
  query getOrganizationGlobalSubjectVariants(
    $subjectVariantFilters: IbOfferedSubjectVariantFilters
    $subjectGroupFilters: GlobalSubjectGroupFilters
    $globalTagFilters: GlobalGenericTagFilters
  ) {
    globalConstants {
      ibOfferedSubjectVariants(filters: $subjectVariantFilters) {
        subject {
          id
          name
          contentSubject {
            id
            assessmentYearTitle
          }
        }
        subjectGroup {
          id
          name
          type
        }
        level {
          id
          label
        }
        responseLanguages {
          id
          label
        }
      }
      globalSubjectGroups(filters: $subjectGroupFilters) {
        id
        name
        type
      }
      globalGenericTags(filters: $globalTagFilters) {
        id
        label
        type
      }
    }
  }
`;

export const getOrganizationSubjectGroupsQuery = gql`
  query getOrganizationSubjectGroups(
    $id: ID!
    $filters: SubjectGroupFilterInput
    $subjectFilters: SubjectFilterInput
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationSubjectGroups}
`;

export const getOrganizationSubjectsQuery = gql`
  query getOrganizationSubjects($id: ID!, $filters: SubjectFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationSubjects}
`;

export const getConfigurationsQuery = gql`
  query getConfigurations(
    $platform: CONFIGURATION_PLATFORM_ENUM
    $categories: [String!]
    $getRoot: Boolean
    $courseId: ID
    $curriculumProgramId: ID
  ) {
    platform {
      organization {
        id
        configurations(
          platform: $platform
          categories: $categories
          getRoot: $getRoot
          courseId: $courseId
        ) {
          id
          permission
          booleanValue
          stringValue
          jsonValue
          intValue
          metadata
          label
          permissionCategory
          description
          valueType
          valueSet
          parentID
          platforms
          toolTip
          subText
          displayOrder
          status
          selectedCourses(curriculumProgramId: $curriculumProgramId) {
            id
            title
          }
          uiType
        }
      }
    }
  }
`;

export const getEnabledClassNotificationsQuery = gql`
  query getEnabledClassNotifications($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        enabledClassNotifications {
          course {
            id
            title
          }
          isEnabled
        }
      }
    }
  }
`;

export const getAcademicCriteriaSetsQuery = gql`
  query getAcademicCriteriaSets(
    $organizationId: ID!
    $filters: academicCriteriaSetsFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        constants {
          academicCriteriaSets(filters: $filters) {
            ...criteriaSetItem
          }
        }
      }
    }
  }
  ${criteriaSetFragment.criteriaSet}
`;

export const getOrganizationElementRatingQuery = gql`
  query getOrganizationElementRating(
    $organizationId: ID!
    $filters: RatingLevelsFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        constants {
          ratingLevels(filters: $filters) {
            ...ratingItem
          }
        }
      }
    }
  }
  ${pypElementRatingFragment.organizationElementRating}
`;

export const getChildrenQuery = gql`
  query getChildren($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on FamilyMember {
        children {
          ...childItem
          ...childDetailsItem
        }
      }
    }
  }
  ${childFragment.basicChildDetails}
  ${childFragment.childDetails}
`;

export const getOrganizationPypAtlConstantsQuery = gql`
  query getOrganizationPypAtlConstants($organizationId: ID!) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        pypElement {
          atls {
            rootNodes
            nodes {
              ...atlItem
            }
          }
        }
      }
    }
  }
  ${pypElementFragment.atl}
`;

export const getUnitPlansOfGradeQuery = gql`
  query getUnitPlansOfGrade($id: ID!, $filters: UnitPlanFilter) {
    node(id: $id, type: GRADE) {
      id
      ... on Grade {
        name
        subjects {
          id
          name
        }
        unitPlans(filters: $filters) {
          id
          unitType {
            ...unitplanFieldBasicDetailsItem
          }
          title {
            ...unitplanFieldBasicDetailsItem
          }
          duration {
            ...unitplanFieldBasicDetailsItem
          }
          theme {
            ...unitplanFieldBasicDetailsItem
          }
          image {
            ...unitplanFieldBasicDetailsItem
          }
          subjects {
            ...unitplanFieldBasicDetailsItem
          }
          startDate
          endDate
        }
      }
    }
  }
  ${plannerFieldFragment.unitplanFieldBasicDetails}
`;

export const getOrganizationGradesQuery = gql`
  query getOrganizationGrades($id: ID!, $filters: GradeFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      ... on Organization {
        id
        grades(filters: $filters) {
          id
          name
          displaySequence
          curriculumProgram {
            id
            type
          }
          globalGrade {
            id
            uid
            name
          }
        }
      }
    }
  }
`;

export const getStaffListQuery = gql`
  query getStaffList(
    $organizationId: ID!
    $searchText: String
    $grades: [ID!]
    $courses: [ID!]
    $archivalState: ARCHIVAL_STATE_ENUM
    $curriculumProgramIds: [ID!]
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ... on Organization {
        id
        staff(
          filters: {
            searchText: $searchText
            grades: $grades
            courses: $courses
            archivalState: $archivalState
            curriculumProgramIds: $curriculumProgramIds
          }
        ) {
          totalCount
          edges {
            node {
              ...staffCoursesItem
            }
          }
        }
      }
    }
  }

  ${staffUnitPlanFragment.staffCourses}
`;

export const getOrganizationStaffQuery = gql`
  query getOrganizationStaff(
    $organizationId: ID!
    $filters: OrgConnectionFilterInput
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ... on Organization {
        id
        staff(filters: $filters) {
          totalCount
          edges {
            node {
              ...userItem
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getUserGradesQuery = gql`
  query getGradeWiseProjectCount(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $allGradesFilters: AllGradesFilters
  ) {
    node(id: $id, type: $type) {
      id
      ... on Student {
        id
        allGrades(filters: $allGradesFilters) {
          ...gradeItem
        }
      }
      ... on Staff {
        id
        allGrades(filters: $allGradesFilters) {
          ...gradeItem
        }
      }
    }
  }
  ${gradeFragment.basicGradeDetails}
`;

export const getFilteredPostCountWithTypeQuery = gql`
  query getFilteredPostCount(
    $id: ID!
    $filters: PostFilterInput
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      ... on Student {
        id
        posts(filters: $filters) {
          totalCount
        }
      }
    }
  }
`;

export const getOrganizationGlobalConstantsQuery = gql`
  query getOrganizationGlobalConstants(
    $organizationId: ID!
    $filter: GlobalConstantsFilterOptions
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ... on Organization {
        id
        globalConstants(filter: $filter) {
          ...organizationGlobalConstantItem
        }
      }
    }
  }
  ${organizationFragment.organizationGlobalConstants}
`;

export const getTurnitinReportViewerLaunchUrlQuery = gql`
  query getTurnitinReportViewerLaunchUrl(
    $input: TurnitinReportViewerLauncherUrlInput!
  ) {
    integration {
      getTurnitinReportViewerLaunchUrl(input: $input)
    }
  }
`;

export const getIsTurnitinEulaRequiredQuery = gql`
  query getIsTurnitinEulaRequired {
    integration {
      isTurnitinEulaRequired
    }
  }
`;

export const getLatestTurnitinEulaQuery = gql`
  query getLatestTurnitinEula($input: GetLatestTurnitinEulaInput) {
    integration {
      getLatestTurnitinEula(input: $input) {
        version
        content
        locale
      }
    }
  }
`;

export const getOrganizationSubjectsWithPlannerElementDepthLabelSetsQuery = gql`
  query getOrganizationSubjectsWithPlannerElementDepthLabelSets(
    $id: ID!
    $subjectFilters: SubjectFilterInput
    $plannerDepthFilters: PlannerElementDepthLabelSetFilters
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        subjects(filters: $subjectFilters) {
          id
          name
          plannerElementDepthLabelSets(filters: $plannerDepthFilters) {
            plannerElementSet {
              id
            }
            plannerElementDepthLabels {
              id
              depth
              label
            }
          }
        }
      }
    }
  }
`;

export const getPlannerElementSetNodesAndDepthLabelsQuery = gql`
  query getPlannerElementSetNodesWithDepthLabelSets(
    $id: ID!
    $filters: PlannerElementFilters
  ) {
    node(id: $id, type: PLANNER_ELEMENT_SET) {
      id
      ... on PlannerElementSet {
        nodes(filters: $filters) {
          id
          label
          depth
        }
        depthLabels {
          id
          depth
          label
        }
      }
    }
  }
`;

export const checkIfFeatureEnabledQuery = gql`
  query checkIfFeatureEnabled($input: FeatureActiveStatusInput!) {
    platform {
      isFeatureActive(input: $input)
    }
  }
`;

export const getCourseUnitPlanCountOfSubjectQuery = gql`
  query getCourseUnitPlanCountOfSubject($id: ID!) {
    node(id: $id, type: SUBJECT) {
      ... on Subject {
        id
        courseCount
        unitPlanCount
      }
    }
  }
`;

export const getBasicCommentOfNodeQuery = gql`
  query getBasicCommentOfNode(
    $id: ID!
    $after: String
    $first: Int
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      ... on Post {
        ...basicCommentPostItem
      }
    }
  }
  ${commentNodeFragment.basicCommentPost}
`;

export const getFilteredTags = gql`
  query getFilteredTags($keys: [String!]!) {
    platform {
      allTags(input: { keys: $keys }) {
        id
        key
        value
      }
    }
  }
`;

export const getStudentPeriodListQuery = gql`
  query getStudentPeriodList($id: ID!, $periodFilters: PeriodFilterOptions) {
    node(id: $id, type: STUDENT) {
      ... on Student {
        ...studentPeriodListItem
      }
    }
  }
  ${studentFragment.studentPeriodList}
`;

export const getStudentPeriodListInfoQuery = gql`
  query getStudentPeriodListInfo(
    $id: ID!
    $periodFilters: PeriodFilterOptions
  ) {
    node(id: $id, type: STUDENT) {
      ... on Student {
        ...studentPeriodListInfoItem
      }
    }
  }
  ${studentFragment.studentPeriodListInfo}
`;

export const getStudentPeriodSetsQuery = gql`
  query getStudentPeriodSets(
    $id: ID!
    $periodSetFilter: PeriodSetFilterOptions
  ) {
    node(id: $id, type: STUDENT) {
      ... on Student {
        id
        curriculumPrograms {
          ...curriculumProgramItem
        }
      }
    }
  }
  ${curriculumProgramFragment.periodSet}
`;

export const getOrganizationYearGroupIdsQuery = gql`
  query getOrganizationYearGroups($id: ID!, $filters: YearGroupFilter) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        yearGroups(filters: $filters) {
          id
        }
      }
    }
  }
`;

export const getOrganizationYearGroupsQuery = gql`
  query getOrganizationYearGroups($id: ID!, $filters: YearGroupFilter) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        yearGroups(filters: $filters) {
          ...basicYearGroupDetails
        }
      }
    }
  }
  ${yearGroupFragments.basicYearGroupDetails}
`;

export const getOrganizationExamSessionsQuery = gql`
  query getOrganizationExamSession($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        examSessions {
          id
          session
          month
        }
      }
    }
  }
`;

export const getOrganizationMultiLevelNodeTagsQuery = gql`
  query getOrganizationMultiLevelNodeTags(
    $organizationId: ID!
    $filters: GenericTagsFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        name
        genericTagsV2(filters: $filters) {
          id
          label
          icon {
            id
            url
          }
        }
      }
    }
  }
`;

export const getOrganizationTemplatesQuery = gql`
  query getOrganizationTemplates(
    $id: ID!
    $filters: OrganizationTemplateFilter
  ) {
    node(id: $id, type: ORGANIZATION) {
      ... on Organization {
        id
        templates(filters: $filters) {
          id
          body
        }
      }
    }
  }
`;

export const getStudentStaffRelationQuery = gql`
  query student(
    $id: ID!
    $first: Int
    $after: String
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $filters: StaffStudentRelationFilter
  ) {
    node(id: $id, type: STUDENT) {
      ... on Student {
        ...studentItem
        staffRelation(
          first: $first
          after: $after
          orderBy: $orderBy
          orderByDirection: $orderByDirection
          filters: $filters
        ) {
          totalCount
          edges {
            node {
              ... on Staff {
                ...staffItem
              }
              ... on Student {
                ...studentItem
              }
            }
            cursor
          }
          pageInfo {
            ...pageInfoItem
          }
        }
      }
    }
  }
  ${pageInfoFragment.pageInfo}
  ${studentFragment.basicStudentDetails}
  ${staffFragment.basicStaffDetails}
`;

export const getOrganizationTemplatesWithHelpEnabledFieldsQuery = gql`
  query getOrganizationTemplates(
    $id: ID!
    $filters: OrganizationTemplateFilter
  ) {
    node(id: $id, type: ORGANIZATION) {
      ... on Organization {
        id
        templates(filters: $filters) {
          id
          body
          helpEnabledFields {
            id
            uid
            helpText {
              id
            }
            type
          }
        }
      }
    }
  }
`;
