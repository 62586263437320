import gql from "graphql-tag";
import { subjectFlatVariantsCountItems } from "./DPSubjectsFragments";

export const getDPSubjectGroupsQuery = gql`
  query getDPSubjectGroups($id: ID!, $filters: SubjectGroupFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        subjectGroups(filters: $filters) {
          id
          name
          type
          globalSubjectGroup {
            id
          }
          subjects {
            id
            name
            type
            icon
            ibOfferedSubject {
              id
            }
            flatVariants {
              level {
                id
                label
                globalTag {
                  id
                }
              }
              responseLanguage {
                id
                label
                globalTag {
                  id
                }
              }
              courses {
                ...courseCountItem
              }
              unitPlans {
                ...unitPlanCountItem
              }
            }
            courses {
              ...courseCountItem
            }
            unitPlans {
              ...unitPlanCountItem
            }
          }
        }
      }
    }
  }
  ${subjectFlatVariantsCountItems.courseCountItem}
  ${subjectFlatVariantsCountItems.unitPlanCountItem}
`;
