// import Activity from "./routes/Activity";
// import ReportCard from "./routes/ReportCard";
// import ResourceBankRoute from "./routes/ResourceBank";
// import ArchiveUnitPlans from "ArchiveUnitPlans";
// import SchedulerPlan from "./routes/SchedulerPlan";
import UnitPlans from "./routes/UnitPlans";
import Scheduler from "./routes/Scheduler";
import { injectReducer } from "store/reducers";
import POI from "POI";
// import SchedularTeacherPlan from "./routes/SchedularTeacherPlan";
import MYPLearningStandards from "MYPLearningStandards";
import DPLearningStandards from "DPLearningStandards";
import Journal from "./routes/Journal";
import AssessmentEvaluation from "./routes/AssessmentEvaluation";
import ClassProgressSummary from "./routes/ClassProgressSummary";
import ProgressSummary from "ProgressSummary";
import StudentPortfolio from "./routes/StudentPortfolio";
import ProgressReport from "./routes/ProgressReport";
import StudentPortfolioReport from "./routes/StudentPortfolioReport";
import FamilyConversations from "./routes/FamilyConversations";
import UnitLibrary from "./routes/UnitLibrary";
import ClassRoom from "./routes/ClassRoom";
import ClassSettings from "./routes/ClassSettings";
import ClassNotificationSettings from "./routes/ClassNotificationSettings";
import { withAsyncRouteLoading } from "UIComponents";
import {
  TeacherResources,
  TeacherPolicy,
  ParentPolicy,
} from "OrganizationResources";
import ScopeAndSequence from "./routes/ScopeAndSequence";
import Attendance from "./routes/Attendance";
import Circulars from "./routes/Circulars";
import MyCalender from "./routes/MyCalendar";
import PlanningInsights from "./routes/PlanningInsights";
import LELibrary from "./routes/LELibrary";
import ManageFamily from "./routes/ManageFamily";
import Projects from "Projects";
import StudentDrive from "./routes/StudentDrive";
import TimetableConfiguration from "./routes/TimetableConfiguration";
import Continuum from "Continuum";

import { getRelativePath } from "Utils";

export default store => ({
  path: ":course_id",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            const module2 = require("Circulars/modules/CircularsModule");
            const reducer = module2.default;
            const key = module2.NAME;
            injectReducer(store, { key, reducer });

            const module = require("Course/modules/CourseModule");
            injectReducer(store, { key: module.NAME, reducer: module.default });

            cb(component);
          },
          err => {
            reject(err);
          },
          "teacher-course"
        );
      })
  ),

  childRoutes: [
    // Activity(store),
    // SchedulerPlan(store),
    // SchedularTeacherPlan(store),
    // ReportCard(store),
    // ResourceBankRoute(store),
    // ArchiveUnitPlans(store),
    //EducatorCenter(store),
    ClassRoom(store),
    StudentPortfolio(store),
    POI(store),
    UnitPlans(store),
    Scheduler(store),
    Journal(store),
    FamilyConversations(store),
    ClassProgressSummary(store),
    ProgressSummary(store),
    AssessmentEvaluation(store),
    ProgressReport(store),
    UnitLibrary(store),
    LELibrary(store),
    ScopeAndSequence(store),
    Attendance(store),
    StudentPortfolioReport(store),
    TeacherResources(store),
    TeacherPolicy(store),
    ParentPolicy(store),
    Circulars(store),
    ClassSettings(store),
    ClassNotificationSettings(store),
    MyCalender(store),
    PlanningInsights(store),
    DPLearningStandards(store),
    MYPLearningStandards(store),
    ManageFamily(store),
    Projects(store),
    StudentDrive(store),
    TimetableConfiguration(store),
    Continuum(store),
  ],
});
