import React from "react";
import { colors } from "Constants";
import { LinkWithTooltip } from "UIComponents";
import classes from "./QuestionsCount.scss";

const benchmarkCountConfig = {
  linkProps: {
    target: "__blank",
    rel: "noopener noreferrer",
  },
  linkStyles: {
    padding: "4px 4px 4px 4px",
    borderRadius: "24px",
    minWidth: 28,
    display: "inline-block",
    color: colors.white,
  },
};

const QuestionCount = ({ isLeaf, benchmarkQuestionCount }) => {
  let count = 0,
    color = colors.gray13,
    url = "#",
    yearSplit = [];

  if (benchmarkQuestionCount) {
    count = _.get(benchmarkQuestionCount, "count", "0");
    color = _.get(benchmarkQuestionCount, "color") || colors.gray13;
    url = _.get(benchmarkQuestionCount, "url", `#`);
    yearSplit = _.get(benchmarkQuestionCount, "yearSplit", []);
  }
  const benchmarkDetail = (
    <>
      {_.map(yearSplit, obj => {
        const { year, count } = obj;
        return (
          <div className={classes.yearCountBenchmarkContainer}>
            <span className={classes.yearBenchmark}>{year}</span> {count}
          </div>
        );
      })}
    </>
  );
  if (isLeaf) {
    if (count === 0) {
      return (
        <div style={{ color: color }} className={classes.emptyBenchmarkCount}>
          {count}
        </div>
      );
    }
    return (
      <div className={classes.benckmarkCount}>
        <LinkWithTooltip
          tooltip={benchmarkDetail}
          placement="bottom"
          linkStyles={{
            ...benchmarkCountConfig.linkStyles,
            background: color,
          }}
          linkProps={{ ...benchmarkCountConfig.linkProps, href: url }}
        >
          {`${count}`}
        </LinkWithTooltip>
      </div>
    );
  }
  return null;
};

export default QuestionCount;
