import React from "react";
import classes from "./TagLearningGoals.scss";
import ScreenPanel from "../../ScreenPanel";
import TagLearningGoalsList from "./TagLearningGoalsList";

import { UIButton, I18nHOC } from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";

const styles = {
  titleTextStyle: {
    fontSize: "2rem",
  },
  leftContainerStyles: {
    width: "100%",
  },
  rightContainerStyles: {
    alignSelf: "flex-start",
    marginTop: "4px",
  },
};

const FooterComponent = React.memo(props => {
  const { onNextClick, t, selectedElementsCount } = props;
  return (
    <div className={classes.footerButtonContainer}>
      <div className={classes.footerText}>
        {t("journal:count_pyp_element_selected", {
          count: selectedElementsCount,
        })}
      </div>
      <UIButton
        color={"pink"}
        type={"filled"}
        size={"sm"}
        onClick={onNextClick}
      >
        {t("done")}
      </UIButton>
    </div>
  );
});
FooterComponent.displayName = "FooterComponent";

class TagLearningGoals extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedElementsCount: 0,
    };
  }

  updateListRef = ref => {
    this.listRef = ref;
  };

  onNextClick = () => {
    if (this.listRef) {
      this.listRef.onNextClick();
    }
  };

  updateSelectedElementsCount = selectedElementsCount => {
    this.setState({ selectedElementsCount });
  };

  render() {
    const { screenPanelProps, t, modalHeaderTitle } = this.props;
    const { selectedElementsCount } = this.state;

    return (
      <ScreenPanel
        {...screenPanelProps}
        modalHeaderTitle={t(modalHeaderTitle)}
        titleTextStyle={styles.titleTextStyle}
        leftContainerStyles={styles.leftContainerStyles}
        rightContainerStyles={styles.rightContainerStyles}
        footerComponent={
          <FooterComponent
            t={t}
            onNextClick={this.onNextClick}
            selectedElementsCount={selectedElementsCount}
          />
        }
      >
        <TagLearningGoalsList
          updateRef={this.updateListRef}
          {...this.props}
          updateSelectedElementsCount={this.updateSelectedElementsCount}
        />
      </ScreenPanel>
    );
  }
}

const getModalHeaderTitle = ({ resourceItem, unitPlan, selectFromAllPYP }) => {
  let modalHeaderTitle = "journal:tag_PYP_elements_stage";
  if (selectFromAllPYP) {
    modalHeaderTitle = "journal:select_from_all_learning_goals";
  } else if (!_.isEmpty(resourceItem)) {
    _.get(resourceItem, ["assessmentType", "value"]) === "le"
      ? (modalHeaderTitle = "journal:select_elements_tag_le")
      : (modalHeaderTitle = "journal:select_elements_tag_only_assessment");
  } else if (!_.isEmpty(unitPlan)) {
    modalHeaderTitle = "journal:select_elements_tag_units";
  }
  return modalHeaderTitle;
};

const mapStateToProps = state => {
  const { resourceItem, unitPlan, selectFromAllPYP } = state.post.postDetails;

  const modalHeaderTitle = getModalHeaderTitle({
    resourceItem,
    unitPlan,
    selectFromAllPYP,
  });
  return {
    curriculumProgramType: state.platform.currentCurriculumProgramType,
    modalHeaderTitle,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "journal"] }),
  connect(mapStateToProps, null)
)(TagLearningGoals);
