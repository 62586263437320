import React from "react";

import HOWTHE from "./HowTheWorldWorks";
import HOWTHEactive from "./HowTheWorldWorksActive";
import HOWWEE from "./HowWeExpressOurselves";
import HOWWEEactive from "./HowWeExpressOurselvesActive";
import HOWWEO from "./HowWeOrganizeOurselves";
import HOWWEOactive from "./HowWeOrganizeOurselvesActive";
import SHARIN from "./SharingThePlanet";
import SHARINactive from "./SharingThePlanetActive";
import WHEREW from "./WhereWeAreInPlaceAndTime";
import WHEREWactive from "./WhereWeAreInPlaceAndTimeActive";
import WHOWEA from "./WhoWeAre";
import WHOWEAactive from "./WhoWeAreActive";

export const themeIcon = ({ theme, metadata, selectedTheme }) => {
  let themeSvg;
  const icon = _.get(metadata, "icon", null);

  switch (true) {
    case icon == "HOWTHE" || theme == "HOWTHE":
      if (selectedTheme == "HOWTHE") {
        themeSvg = <HOWTHEactive />;
      } else {
        themeSvg = <HOWTHE />;
      }
      break;
    case icon == "HOWWEO" || theme == "HOWWEO":
      if (selectedTheme == "HOWWEO") {
        themeSvg = <HOWWEOactive />;
      } else {
        themeSvg = <HOWWEO />;
      }
      break;
    case icon == "HOWWEE" || theme == "HOWWEE":
      if (selectedTheme == "HOWWEE") {
        themeSvg = <HOWWEEactive />;
      } else {
        themeSvg = <HOWWEE />;
      }
      break;
    case icon == "SHARIN" || theme == "SHARIN":
      if (selectedTheme == "SHARIN") {
        themeSvg = <SHARINactive />;
      } else {
        themeSvg = <SHARIN />;
      }
      break;
    case icon == "WHEREW" || theme == "WHEREW":
      if (selectedTheme == "WHEREW") {
        themeSvg = <WHEREWactive />;
      } else {
        themeSvg = <WHEREW />;
      }
      break;
    case icon == "WHOWEA" || theme == "WHOWEA":
      if (selectedTheme == "WHOWEA") {
        themeSvg = <WHOWEAactive />;
      } else {
        themeSvg = <WHOWEA />;
      }
      break;
    default:
      themeSvg = <WHOWEA />;
      break;
  }

  return themeSvg;
};
