import gql from "graphql-tag";
import {
  attachmentFragment,
  similarityFragment,
  userFragment,
  pypElementRatingFragment,
} from "modules/CommonFragments";

export const supervisorFragments = {
  supervisorInfoFragment: gql`
    fragment supervisorInfo on Staff {
      id
      firstName
      lastName
      profileImage
    }
  `,
};

export const studentFragments = {
  studentInfoFragment: gql`
    fragment studentInfo on Student {
      id
      firstName
      lastName
      profileImage
    }
  `,
  studentInfoFragmentV2: gql`
    fragment studentInfo on Student {
      id
      firstName
      lastName
      profileImage
      courseMap(filters: $studentCourseFilters) {
        id
        tags {
          id
          label
          type
        }
      }
    }
  `,
};

export const deadlineFragments = {
  deadlineFragment: gql`
    fragment deadline on ProjectGroupDeadline {
      id
      deadlineDate
      title
    }
  `,
  deadlineFragmentWithStatusCount: gql`
    fragment projectDeadline on ProjectGroupDeadline {
      id
      deadlineDate
      title
      statusWiseProjects @include(if: $fetchStatusWiseProjects) {
        completedProjects {
          id
          students {
            ...studentInfo
          }
        }
        pendingProjects {
          id
          students {
            ...studentInfo
          }
        }
      }
    }
    ${studentFragments.studentInfoFragment}
  `,
};

export const projectFieldsFragment = {
  projectFieldsDetails: gql`
    fragment projectFieldsDetails on Project {
      id
      fields(filters: $filters) {
        id
        uid
        value
        resolvedMinimalTree {
          id
          type
          ... on ResolvedFieldPlannerElementSet {
            id
            type
            ... on ResolvedFieldPlannerElementSet {
              id
              type
              nodes {
                id
                label
                type
              }
            }
          }
        }
      }
    }
  `,
};

export const projectFragments = {
  projectInfoProgressFragment: gql`
    fragment projectInfoProgress on Project {
      id
      status
      type
      reportStatus
      isCompleted
      allPostsCount @skip(if: $isServiceAsAction)
      posts @skip(if: $isServiceAsAction) {
        totalCount
      }
      academicHonesty(filters: { orderBy: DISPLAY_SEQUENCE })
        @skip(if: $isServiceAsAction) {
        id
        isLocked
      }
      finalProjectReports @skip(if: $isServiceAsAction) {
        id
        name
        type
        mimeType
        url
        thumbUrl
        title
        metadata
        streamUrl
        parentType
        similarityReport {
          ...similarityItem
        }
        createdBy {
          id
          type
        }
      }
      projectReports @skip(if: $isServiceAsAction) {
        isRead
      }
      students {
        ...studentInfo
      }
      supervisors {
        ...supervisorInfo
      }
      academicHonestyRemarks @skip(if: $isServiceAsAction) {
        id
        isLocked
      }
      completedDeadlines @skip(if: $isServiceAsAction) {
        deadline {
          id
        }
      }
      isAcademicHonestyLocked @skip(if: $isServiceAsAction)
      ibPYPElementRatings @skip(if: $isServiceAsAction) {
        id
        ibPYPElementId
        value
        academicCriteriaSet {
          label
          criteriaType
          id
        }
        academicCriteriaValue {
          id
          label
          abbreviation
        }
      }
    }
    ${supervisorFragments.supervisorInfoFragment}
    ${studentFragments.studentInfoFragmentV2}
    ${similarityFragment.similarityItem}
  `,
  projectInfoFragment: gql`
    fragment projectInfoItem on Project {
      id
      students {
        ...studentInfo
      }
      supervisors {
        ...supervisorInfo
      }
      status
      #we only need collaborator and we can get the owner from the students so no need for extra filter
      studentCollaborators(filters: { roles: [COLLABORATOR] }) {
        student {
          ...studentInfo
        }
      }
      reportStatus
      finalProjectReports {
        id
      }
      isAcademicHonestyLocked
      ibPYPElementRatings {
        ...basicIBPYPElementRatingItem
      }
      completedDeadlines {
        deadline {
          id
        }
      }
    }
    ${pypElementRatingFragment.basicIBPYPElementRating}
    ${supervisorFragments.supervisorInfoFragment}
    ${studentFragments.studentInfoFragment}
  `,
  studentPortfolioProject: gql`
    fragment projectInfo on Project {
      ...projectFieldsDetails
      type
      status
      reportStatus
      posts(filters: $postFilters) {
        totalCount
      }
    }
    ${projectFieldsFragment.projectFieldsDetails}
  `,
  projectStudentInfoFragment: gql`
    fragment projectStudentInfo on Project {
      id
      students {
        ...studentInfo
      }
    }
    ${studentFragments.studentInfoFragmentV2}
  `,
};

export const projectAcademicHonestyFragments = {
  interviewInfoFragment: gql`
    fragment interviewInfoItem on ProjectAcademicHonesty {
      id
      description
      meetingDate
      createdBy {
        ...userItem
      }
      isPrivate
      isFormalInterview
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUser}
  `,
};

export const studentPortfolioProgressFragment = {
  studentPortfolioProgress: gql`
    fragment portfolioProgress on StudentProjectPortfolio {
      id
      isComplete
      status
      interviews(filters: { isFormalInterview: true }) {
        totalCount
      }
      achievedPlannerElements {
        totalCount
        edges {
          node {
            label
            id
          }
        }
      }
      strandCounts {
        creativityCount
        activityCount
        serviceCount
      }
    }
  `,
};

export const studentProjectPortfolioFragments = {
  studentProjectPortfolioInterviewsFragment: gql`
    fragment studentProjectPortfolioInterviewsItem on StudentProjectPortfolio {
      id
      interviews {
        edges {
          node {
            ...interviewInfoItem
          }
        }
      }
    }
    ${projectAcademicHonestyFragments.interviewInfoFragment}
  `,
  studentProjectPortfolioDetailsFragment: gql`
    fragment studentProjectPortfolioDetailsItem on StudentProjectPortfolio {
      ...portfolioProgress
      id
      student {
        ...userItem
      }
      projectCounts {
        projectsCount
        experiencesCount
      }
      isComplete
      projects(filters: $projectFilters) {
        edges {
          node {
            ...projectInfo
          }
        }
      }
      experiences: projects(filters: $experienceFilters) {
        edges {
          node {
            ...projectInfo
          }
        }
      }
    }
    ${projectFragments.studentPortfolioProject}
    ${userFragment.basicUser}
    ${studentPortfolioProgressFragment.studentPortfolioProgress}
  `,
};

export const guidingQuestionsFragment = {
  guidingQuestions: gql`
    fragment guidingQuestions on ProjectGroupGuidingQuestion {
      id
      question
      grades {
        id
        name
      }
    }
  `,
};

export const insightFragments = {
  plannerElementInsightSpecific: gql`
    fragment plannerElementInsightSpecificItem on ProjectPlannerElementInsightSpecific {
      student {
        ...studentInfo
      }
      studentInsights {
        node {
          id
        }
        addressedInProjects {
          id
        }
        evidenceInProjects {
          id
        }
        evidenceInPosts {
          id
        }
      }
    }
    ${studentFragments.studentInfoFragment}
  `,
};

export const serviceAsActionFragments = {
  gradeWiseProjectCount: gql`
    fragment gradeWiseProjectCount on Grade {
      id
      name
      allProjects(filters: $filters) {
        totalCount
      }
    }
  `,
};

export const studentProjectPortfolioFragment = {
  studentProjectPortfolioEvidences: gql`
    fragment studentProjectPortfolioEvidences on PlannerElementEvidences {
      plannerElementNode {
        id
        label
        metadata
      }
      evidence {
        totalCount
      }
      isAchieved
    }
  `,
};

export const projectGroupFragment = {
  projectGroupWithAssessmentTools: gql`
    fragment projectGroupWithAssessmentToolsItem on ProjectGroup {
      assessmentTools(filters: $assessmentToolFilters) {
        id
        ... on MypObjectiveRubric {
          id
          label
          genericTags {
            id
            label
          }
          criteria {
            id
            label
            type
            highestMark
            parent
            children
            subText
            objectiveRubricDescriptors {
              id
              achievementLevel {
                id
                label
                subText
              }
              label
            }
          }
        }
      }
    }
  `,
};
