import { routes } from "Projects/config/routes";
import { withAsyncRouteLoading } from "UIComponents";
import PortfolioDetails from "./routes/PortfolioDetails";
import ProjectApproval from "./routes/ProjectApproval";
import { injectReducer } from "store/reducers";

const {
  portfolios: { path },
} = routes;

export default store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((cb, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("./modules/StudentPortfoliosModules");
              injectReducer(store, {
                key: reducer.NAME,
                reducer: reducer.default,
              });
              cb(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [ProjectApproval(store), PortfolioDetails(store)],
  };
};
