import gql from "graphql-tag";
import { pypElementFragment, subjectFragment } from "modules/CommonFragments";

export const createStandardSetRequestMutation = gql`
  mutation createStandardSetRequest(
    $standardSetName: String
    $subjectName: String
    $standardSetLink: String
    $subjectId: String
    $description: String
  ) {
    platform {
      createStandardSetRequest(
        input: {
          standardSetName: $standardSetName
          subjectName: $subjectName
          subjectId: $subjectId
          description: $description
          standardSetLink: $standardSetLink
        }
      )
    }
  }
`;

export const addBenchmarkMutation = gql`
  mutation addBenchmark(
    $label: String!
    $parentId: ID
    $levelId: ID!
    $grades: [ID!]
    $phase: String
    $levels: JSON
    $subject: ID!
    $type: SUBJECT_SNS_CHILDREN_ENUM
    $depth: Int
    $code: String
    $plannerElementSetId: ID
  ) {
    planner {
      addNode: addBenchmark(
        input: {
          label: $label
          parentId: $parentId
          levelId: $levelId
          grades: $grades
          phase: $phase
          levels: $levels
          subject: $subject
          depth: $depth
          type: $type
          code: $code
          plannerElementSetId: $plannerElementSetId
        }
      ) {
        ...benchmarkItem
      }
    }
  }
  ${pypElementFragment.benchmark}
`;

export const addATLMutation = gql`
  mutation addATL(
    $label: String!
    $parentId: ID
    $levelId: ID!
    $grades: [ID!]
    $levels: JSON
    $subText: String
    $depth: Int
    $plannerElementSetId: ID
  ) {
    planner {
      addNode: addATL(
        input: {
          label: $label
          parentId: $parentId
          levelId: $levelId
          grades: $grades
          levels: $levels
          subText: $subText
          depth: $depth
          plannerElementSetId: $plannerElementSetId
        }
      ) {
        ...atlItem
      }
    }
  }
  ${pypElementFragment.atl}
`;

export const editBenchmarkMutation = gql`
  mutation editBenchmark(
    $id: ID!
    $label: String
    $grades: [ID!]
    $levels: JSON
    $code: String
  ) {
    planner {
      editNode: editBenchmark(
        input: {
          id: $id
          label: $label
          grades: $grades
          levels: $levels
          code: $code
        }
      ) {
        id
      }
    }
  }
`;

export const editATLMutation = gql`
  mutation updateATL(
    $id: ID!
    $label: String
    $grades: [ID!]
    $levels: JSON
    $subText: String
  ) {
    planner {
      editNode: updateATL(
        input: {
          id: $id
          label: $label
          grades: $grades
          levels: $levels
          subText: $subText
        }
      ) {
        id
      }
    }
  }
`;

export const createSubjectSnSMutation = gql`
  mutation addBenchmark(
    $label: String!
    $levelId: ID!
    $subject: ID!
    $levels: JSON
    $grades: [ID!]
    $clearAllBenchmarkForL0: Boolean
    $plannerElementSetId: ID
  ) {
    planner {
      addBenchmark(
        input: {
          label: $label
          levelId: $levelId
          subject: $subject
          levels: $levels
          grades: $grades
          clearAllBenchmarkForL0: $clearAllBenchmarkForL0
          plannerElementSetId: $plannerElementSetId
        }
      ) {
        id
      }
    }
  }
`;

export const removeBenchmarkMutation = gql`
  mutation removeBenchmark($id: ID!) {
    planner {
      removeNode: removeBenchmark(input: { id: $id })
    }
  }
`;

export const removeATLMutation = gql`
  mutation deleteATL($id: ID!) {
    planner {
      removeNode: deleteATL(input: { id: $id })
    }
  }
`;

export const setStandardSetOfSubjectMutation = gql`
  mutation setStandardSetOfSubject(
    $id: ID!
    $standardSet: ID
    $changeStandardSet: Boolean
    $framework: SUBJECT_FRAMEWORK_ENUM
    $snsChildren: [SUBJECT_SNS_CHILDREN_ENUM]
  ) {
    platform {
      updateSubject(
        input: {
          id: $id
          standardSet: $standardSet
          changeStandardSet: $changeStandardSet
          framework: $framework
          snsChildren: $snsChildren
        }
      ) {
        ...subjectItem
      }
    }
  }
  ${subjectFragment.subjectBenchmarkDetails}
`;

export const handleBenchmarkInBulkMutation = gql`
  mutation handleBenchmarkInBulk(
    $subject: ID!
    $plannerElementSetId: ID
    $benchmarks: [BenchmarkBulkInput!]
    $snsChildren: [SUBJECT_SNS_CHILDREN_ENUM]
  ) {
    platform {
      updateSubject(input: { id: $subject, snsChildren: $snsChildren }) {
        ...subjectItem
      }
    }
    planner {
      handleBenchmarkInBulk(
        input: {
          subject: $subject
          benchmarks: $benchmarks
          plannerElementSetId: $plannerElementSetId
        }
      ) {
        rootNodes
        nodes {
          ...benchmarkItem
        }
      }
    }
  }
  ${subjectFragment.subjectBenchmarkDetails}
  ${pypElementFragment.benchmark}
`;
