import { checkEmptyElementValue } from "modules/Services";
import update from "immutability-helper";
import {
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
} from "Constants";
import moment from "moment";
import { colors } from "Constants";
import _ from "lodash";

const getUnitPlanStepsLocally = params => {
  const {
    unitPlanDetails,
    stageKey,
    stage_list,
    step_list,
    field_list,
    unitPlanFields,
  } = params;
  const stageStepIdList = _.get(stage_list, `${stageKey}.steps`, []);
  const stageSteps = _.pick(step_list, stageStepIdList);

  const stepList = _.reduce(
    stageSteps,
    (result, step, key) => {
      const showInUnitSummary = _.get(step, "showInUnitSummary", true);
      const fields = _.filter(_.get(step, "fields", []), field => {
        const fieldObj = _.get(field_list, field, {});

        // don't show IBReflection type fields in unit summary
        if (_.get(fieldObj, "type", "") === "IBReflection") {
          return false;
        }

        return checkEmptyElementValue({
          fieldKey: field,
          data: unitPlanDetails,
          unitPlanFields,
          fieldObj,
        });
      });

      if (fields.length > 0 && showInUnitSummary) {
        result.push(key);
      }
      return result;
    },
    []
  );
  // returns array of step id which have atleast one field filled.
  return stepList;
};

export const getDefaultUnitPlanImage = ({ id, imageUrl, getRethumb }) => {
  return !_.isEmpty(imageUrl)
    ? getRethumb(imageUrl)
    : `url(${PLACEHOLDER_RECT_IMAGE[id % PLACEHOLDER_RECT_IMAGE_COUNT]})`;
};

export const getUnitPlanStepsMemoized = _.memoize(
  params => getUnitPlanStepsLocally(params),
  params => JSON.stringify(params)
);

const getUnitPlanStagesLocally = params => {
  const {
    unitPlanDetails,
    unitSummaryModules,
    module_list,
    stage_list,
    step_list,
    field_list,
    unitPlanFields,
    curriculumType,
    parent_stage_list,
  } = params;

  //making a copy to return only nonEmpty stages nd steps
  const updatedStageListCopy = _.cloneDeep(stage_list);
  let updatedParentStageList = _.cloneDeep(parent_stage_list);

  return _.reduce(
    unitSummaryModules,
    (result, module) => {
      const stages = _.get(module_list, `${module}.stages`, []);

      _.forEach(stages, stageKey => {
        const steps = getUnitPlanStepsMemoized({
          stageKey,
          stage_list,
          step_list,
          field_list,
          unitPlanFields,
          unitPlanDetails,
        });

        //if steps are not empty we are updating it in updatedStageListCopy
        if (!_.isEmpty(steps)) {
          result.push(stageKey);
          updatedStageListCopy[stageKey].steps = steps;
        }
      });

      if (curriculumType == "UBD") {
        const parentStages = _.get(
          module_list,
          `${module}.parentStageList`,
          []
        );

        _.forEach(parentStages, key => {
          const stageList = _.get(updatedParentStageList[key], "stages", []);
          updatedParentStageList = update(updatedParentStageList, {
            [key]: {
              stages: {
                $set: _.filter(stageList, key => _.includes(result, key)),
              },
            },
          });
        });
      }

      const updatedStageList = _.reduce(
        result,
        (obj, key) => {
          obj[key] = updatedStageListCopy[key];
          return obj;
        },
        {}
      );

      return { updatedStageList, updatedParentStageList };
    },
    []
  );
};

export const getUnitPlanStagesMemoized = _.memoize(
  params => getUnitPlanStagesLocally(params),
  params => JSON.stringify(params)
);

const getSharedCourses = ({ unitPlanDetails, shareWithModalType }) => {
  const sharedCoursesWithUser = _.find(
    _.get(unitPlanDetails, "sharedCoursesWithUserGroup", []),
    { userType: shareWithModalType }
  );

  return _.map(_.get(sharedCoursesWithUser, "courses", []), _.iteratee("id"));
};

export const getSharedCoursesMemoized = _.memoize(
  params => getSharedCourses(params),
  params => JSON.stringify(params)
);

const getSharedSections = ({ unitPlanDetails, shareWithModalType }) => {
  const enabledSections = _.find(
    _.get(unitPlanDetails, "enabledSections", []),
    { userType: shareWithModalType }
  );

  return _.get(enabledSections, "sections", []);
};

export const getSharedSectionsMemoized = _.memoize(
  params => getSharedSections(params),
  params => JSON.stringify(params)
);

export const getYearlyUnitPlans = ({ edges }) => {
  return _.map(edges, edge => {
    return {
      id: edge.node.id,
      startDate: edge.node.startDate,
      endDate: edge.node.endDate,
      unitPlan: edge.node,
    };
  });
};

export const getYearlyUnitPlansMemoize = _.memoize(
  params => getYearlyUnitPlans(params),
  params => JSON.stringify(params)
);

export const getFlatUnitPlans = ({ edges }) => {
  return _.map(edges, edge => edge.node);
};

export const getFlatUnitPlansMemoize = _.memoize(
  params => getFlatUnitPlans(params),
  params => JSON.stringify(params)
);

export const getTeachingHoursMemoize = _.memoize(
  params => getTeachingHours(params),
  params => JSON.stringify(params)
);

export const getLELibraryLabels = ({
  assessmentType,
  assessmentTypeDetail,
  labelType,
  t,
}) => {
  switch (assessmentType) {
    case "ASSESSMENT": {
      return labelType === "searchText"
        ? t("common:search_for_assessment_plural")
        : t("unitPlan:add_assessment_item_plural");
    }
    case "LEARNING_ENGAGEMENT": {
      return labelType === "searchText"
        ? t("common:search_for_le_label_plural")
        : t("unitPlan:add_le_label_item_plural");
    }
    case "SECOND_STAGE_ASSESSMENT": {
      return labelType === "searchText"
        ? t("common:search_for_stage_2_assessment_plural")
        : t("unitPlan:add_stage_2_assessment_item_plural");
    }
    case "THIRD_STAGE_ASSESSMENT": {
      return labelType === "searchText"
        ? t("common:search_for_stage_3_assessment_plural")
        : t("unitPlan:add_stage_3_assessment_item_plural");
    }
    default: {
      return labelType === "searchText"
        ? t("common:search_for_with_label", {
            label: t(assessmentTypeDetail.label),
          })
        : t("unitPlan:add_library_item", {
            label: t(assessmentTypeDetail.label),
          });
    }
  }
};

export const getLELibraryLabelsMemoize = _.memoize(
  params => getLELibraryLabels(params),
  params => JSON.stringify(params)
);

/** This function is to get bar color based on current date and unit start and end date.
 *
 * @param startDate = unit starting date
 * @param endDate = unit ending date
 *
 */

export const getBarColor = ({ startDate, endDate }) => {
  const today = moment();
  if (moment(startDate).isBefore(today) && moment(endDate).isBefore(today)) {
    return colors.teal54;
  } else if (
    moment(startDate).isBefore(today) &&
    moment(endDate).isAfter(today)
  ) {
    return colors.violet74;
  } else if (
    moment(startDate).isAfter(today) &&
    moment(endDate).isAfter(today)
  ) {
    return colors.pink78;
  } else {
    return colors.gray13;
  }
};

export const getTeachingHours = ({ unitPlans, label }) => {
  return _.reduce(
    unitPlans,
    (sumTeachingHours, unit) => {
      const fields = _.get(unit, "fields", []);
      const teachingHours = _.get(_.find(fields, { uid: "hours" }), "value", 0);

      if (label == "totalHours") {
        return sumTeachingHours + teachingHours;
      }
      const subjectLevelTags = _.filter(
        _.get(
          _.find(fields, { uid: "subjectLevel" }),
          "resolvedMinimalTree.genericTags"
        ),
        item => item.label === label
      );
      if (!_.isEmpty(subjectLevelTags)) {
        return sumTeachingHours + teachingHours;
      }
      return sumTeachingHours;
    },
    0
  );
};

export const getIsFromClassroom = ({ sourceFrom, template }) => {
  const classroomEditSteps = _.get(template, "classroomEditSteps", []);
  return _.isEqual(sourceFrom, "CLASSROOM") && !_.isEmpty(classroomEditSteps);
};

export const getGroupedCoursesByRegion = courses => {
  if (!_.get(courses, "length", 0)) {
    return [];
  }

  const NORMALIZED_KEY = "REGION";
  const EXCLUDED_VALUES = ["GLOBAL"];

  const normalizedCourses = {};

  _.forEach(courses, course => {
    const tags = _.get(course, "tags", []);

    _.forEach(tags, tag => {
      if (tag.key === NORMALIZED_KEY) {
        if (!_.includes(EXCLUDED_VALUES, tag.value)) {
          const tagValue = normalizedCourses[tag.value];
          if (tagValue) {
            tagValue.courses.push(course);
          } else {
            normalizedCourses[tag.value] = {
              region: tag,
              courses: [course],
            };
          }
        }
      }
    });
  });

  return _.map(Object.keys(normalizedCourses).sort(), regionName => {
    return normalizedCourses[regionName];
  });
};
