import client from "apolloClient";
import { createContext } from "react";
import update from "immutability-helper";
import asyncWrap from "Utils/asyncWrap";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  deleteProjectDeadlineMutation,
  updateProjectDeadlineMutation,
  createProjectDeadlineMutation,
  reorderProjectDeadlineMutation,
  createProjectTemplateCopyMutation,
  updateProjectFieldMutation,
  markProjectDeadlinesCompleteMutation,
  updateProjectMutation,
  editProjectMutation,
  createProjectMutation,
  removeProjectsMutation,
  createProjectGroupGuidingQuestionMutation,
  updateProjectGroupGuidingQuestionMutaion,
  deleteProjectGroupGuidingQuestionMutation,
  reorderProjectGroupGuidingQuestionMutation,
  updateStudentProjectPortfolioMutation,
  updateAchievedPlannerElementMutation,
  updateStudentPortfolioStatusMutation,
  updateProjectStatusInBulkMutation,
  updateProjectWithoutResponseFieldsMutation,
} from "./ProjectMutations";
import {
  getProjectGroupDeadlinesFromCache,
  writeProjectGroupDeadlinesInCache,
  writeProjectGroupDeadlineInCache,
  getProjectInfoFromCache,
  writeProjectInfoInCache,
  writeGuidingQuestionFragmentInCache,
  getGuidingQuestionFragmentFromCache,
  getGuidingQuestionsFromCache,
  writeGuidingQuestionsInCache,
  getProjectDetailsFromCache,
  writeProjectDetailsInCache,
  getLearningOutcomesWithEvidencesFromCache,
  writeLearningOutcomesWithEvidencesInCache,
  getStudentProjectPortfolioProgressFragmentInCache,
  writeStudentProjectPortfolioProgressFragmentInCache,
  getProjectGroupProjectCountFromCache,
  writeProjectGroupProjectCountInCache,
  getUserProjectGroupsFromCache,
  writeProjectRatedCriteriaInCache,
  getProjectCriteriaDetailsFromCache,
  getProjectStudentInfoFragmentFromCache,
  getProjectGroupYearGroupFromCache,
} from "./ProjectGraphqlHelpers";
import _, {
  filter as _filter,
  concat as _concat,
  get as _get,
  unionBy,
} from "lodash";
import {
  formatDate,
  moveElementInArray,
  isValidValue,
  extractLabelsForTranslation,
  exportData,
  generateRandomId,
} from "Utils";
import {
  getProjectReportsQuery,
  getGuidingQuestionsQuery,
  getGradeWiseProjectCountQuery,
  getProjectGroupSupervisorsQuery,
  getProjectGroupStudentsQuery,
  getStudentProjectPortfolioInterviewsQuery,
  getProjectInfoQuery,
  getProjectGroupProjectsWithFieldsQuery,
  getProjectGroupProjectCountQuery,
  getStudentProjectPortfolioQuery,
  getProjectAssessmentBulkDownloadQuery,
} from "./ProjectQueries";
import { getResolvedOptimisticValue, getPrintFile } from "modules/Services";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
import { createAttachmentMutation } from "modules/CommonMutations";
import { getProjectReportTemplateDetailsQuery } from "Projects/routes/Setup/routes/ReportTemplate/modules/ReportTemplateQuery";
import {
  getProjectGroupProjectsQuery,
  getProjectGroupProjectsCountQuery,
} from "Projects/routes/Setup/routes/Invite/modules/InviteQueries";
import {
  PROJECT_GROUP_TYPE_COMMUNITY,
  PROJECT_GROUP_TYPE_PERSONAL,
  PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
  PROJECT_STATUS_PENDING_FOR_APPROVAL,
  PROJECT_STATUS_IN_PROGRESS,
  PROJECT_GROUP_TYPE_DP_CAS,
  ROLE_ADMIN,
  ROLE_SUPERVISOR,
  PROJECT_GROUP_TYPE_DP_EE,
  ROLE_STUDENT,
  PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
  PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
  PROJECT_REPORT_STATUS_APPROVED,
  PROJECT_REPORT_STATUS_SUBMITTED,
  PROJECT_REPORT_STATUS_NOT_STARTED,
  PROJECT_REPORT_STATUS_NON_SUBMISSION,
  PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL,
  PROJECT_REPORT_STATUS_PENDING,
  PROJECT_GROUP_SUB_TYPE_INTERNAL_ASSESSMENT,
  PROJECT_GROUP_SUB_TYPE_EXTERNAL_ASSESSMENT,
  ATTACHMENT_TYPE_PROJECT_GROUP_SL_STIMULI,
  ATTACHMENT_TYPE_PROJECT_GROUP_HL_STIMULI,
  SL_LABEL,
  PROJECT_GROUP_SUB_TYPE_CORE,
} from "Projects/Constants/stringConstants";
import {
  getSupervisorProjectQuery,
  getSupervisorStudentProjectsQuery,
  getGroupedProjectsQuery,
  getCoordinatorProjectsQuery,
} from "Projects/routes/Progress/modules/ProgressQueries";
import { getOrganizationGradesFromCache } from "GradesSetup/modules/GraphqlHelpers";
import {
  deleteAcademicHonestyMutation,
  createAcademicHonestyMutation,
  updateAcademicHonestyMutation,
} from "Projects/routes/ProjectDetails/routes/AcademicHonesty/modules/Mutations";
import { projectGroupPortfolioStudentViewFilters } from "Projects/routes/StudentPortfolios/routes/PortfolioDetails/routes/StudentPortfolio/modules/StudentPortfolioModules";
import ACLStore from "lib/aclStore";
import {
  PROJECT_REPORT_STATUS,
  PROJECT_STATUS,
  OVERALL_PROGRESS,
} from "Projects/config/constants";
import {
  PlannerCreateIBPYPElementRatingsMutation,
  PlannerDeleteIBPYPElementRatingMutation,
} from "Projects/routes/ProjectDetails/routes/Assessment/modules/Mutations";
import {
  getRatedScoreMemoized,
  getTotalScoreMemoized,
} from "AppComponents/CriteriaRubricAssessment/Utils.js";
import moment from "moment-timezone";
import { getSettingValue } from "modules/PermissionModule";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";

export const NAME = "project";

export const UPDATE_CREATE_PROJECT_DATA_IN_REDUX =
  "UPDATE_CREATE_PROJECT_DATA_IN_REDUX" + " " + NAME;

export const UPDATE_PORTFOLIO_STUDENT_IN_REDUX =
  "UPDATE_PORTFOLIO_STUDENT_IN_REDUX" + " " + NAME;

export const RESET_CREATE_PROJECT_DATA_IN_REDUX =
  "RESET_CREATE_PROJECT_DATA_IN_REDUX" + " " + NAME;

export const UPDATE_CURRENT_GRADE = "UPDATE_CURRENT_GRADE" + " " + NAME;

export const UPDATE_RIGHT_SIDEBAR_TAB = "UPDATE_RIGHT_SIDEBAR_TAB" + " " + NAME;
export const UPDATE_YEAR_GROUP_ID = "UPDATE_YEAR_GROUP_ID" + " " + NAME;

export const UPDATE_PROJECT_MODAL_AND_DIALOGUE_VALUE =
  "UPDATE_PROJECT_MODAL_AND_DIALOGUE_VALUE" + " " + NAME;

export const isToddleTemplate = parentType => {
  return _.includes(
    [
      "TODDLE_PERSONAL_PROJECT_REPORT_TEMPLATE",
      "TODDLE_COMMUNITY_PROJECT_REPORT_TEMPLATE",
      "TODDLE_TOK_EXHIBITION_REPORT_TEMPLATE",
      "TODDLE_TOK_ESSAY_REPORT_TEMPLATE",
      "TODDLE_EXTENDED_ESSAY_REPORT_TEMPLATE",
    ],
    parentType
  );
};
export const PROJECT_TYPE_LABEL_MAP = {
  [PROJECT_GROUP_TYPE_PERSONAL]: "project:personal_project",
  [PROJECT_GROUP_TYPE_COMMUNITY]: "project:community_project",
  [PROJECT_GROUP_TYPE_DP_CAS]: "project:cas",
  [PROJECT_GROUP_TYPE_DP_TOK_ESSAY]: "project:tok_essay",
  [PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION]: "project:tok_exhibition",
  [PROJECT_GROUP_TYPE_DP_EE]: "project:ee",
};

export const PROJECT_TYPE_LABEL_MAP_V2 = {
  [PROJECT_GROUP_TYPE_PERSONAL]: "project:personal_project",
  [PROJECT_GROUP_TYPE_COMMUNITY]: "project:community_project",
  [PROJECT_GROUP_TYPE_DP_CAS]: "project:creativity_action_service",
  [PROJECT_GROUP_TYPE_DP_TOK_ESSAY]: "project:theory_of_knowledge_essay",
  [PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION]:
    "project:theory_of_knowledge_exhibition",
  [PROJECT_GROUP_TYPE_DP_EE]: "project:extended_essay",
};

export const avatarBackgroundColours = ["violet", "blue", "yellow", "pink"];

export const STRAND_COLOR_MAPPING = {
  Creativity: {
    label: "project:creativity",
    color: "interactiveThreeDefault",
  },
  Activity: {
    label: "project:activity",
    color: "interactiveOneDefault",
  },
  Service: {
    label: "project:service",
    color: "interactiveTwoDefault",
  },
};

export const getFieldValue = ({ type, nodeField, projectFields, fielduid }) => {
  switch (type) {
    case "SubmissionItem": {
      return _.get(nodeField, "attachments", null);
    }
    case "SelectAttachmentComponent": {
      return _.get(nodeField, "resolvedMinimalTree.attachments", null);
    }
    default:
      return (
        _.get(nodeField, "value", null) ??
        _.get(projectFields[fielduid], "value", null)
      );
  }
};

export const getProjectGroupTypeToCompareForSimilarity = ({
  projectGroupSubType,
  projectGroupType,
}) => {
  const projectComparisonMap = {
    [PROJECT_GROUP_TYPE_PERSONAL]: "MYP_PERSONAL_PROJECT",
    [PROJECT_GROUP_TYPE_COMMUNITY]: "MYP_COMMUNITY_PROJECT",
  };

  switch (projectGroupSubType) {
    case PROJECT_GROUP_SUB_TYPE_INTERNAL_ASSESSMENT:
    case PROJECT_GROUP_SUB_TYPE_EXTERNAL_ASSESSMENT:
      return projectGroupSubType;
    default:
      return projectComparisonMap[projectGroupType] ?? projectGroupType;
  }
};

export const getProjectGroupSubtype = ({ projectGroupId }) => {
  const yearGroupData = getProjectGroupYearGroupFromCache({
    id: projectGroupId,
  });
  return _.get(yearGroupData, "node.subType", null);
};

export const getShowSimilarityOnProject = ({
  projectGroupType,
  projectGroupId,
  organizationId,
}) => {
  const projectGroupSubType = getProjectGroupSubtype({ projectGroupId });
  let showSimilarityOnProject = false;

  if (!_.isNil(projectGroupType)) {
    const projectTypeToCompare = getProjectGroupTypeToCompareForSimilarity({
      projectGroupSubType,
      projectGroupType,
    });
    const turnitinEnabledProjects = getSettingValue({
      name: "TurnitinEnabledForOtherModules",
      organizationId,
    });

    showSimilarityOnProject = _.includes(
      turnitinEnabledProjects,
      projectTypeToCompare
    );
  }
  return showSimilarityOnProject;
};

export const getFieldIdsNotificationCount = ({ fieldIds, fields }) => {
  return _.reduce(
    fieldIds,
    (notificationCount, fieldId) => {
      notificationCount += _.get(
        _.find(fields, { uid: fieldId }),
        "comments.unreadCountV2",
        0
      );
      return notificationCount;
    },
    0
  );
};

export const feedDataColumnList = [
  {
    label: "Status",
    locale: "common:status",
    id: "project_report_status",
    style: {
      paddingLeft: "24px",
      justifyContent: "center",
    },
    uiConfig: { uiType: "STATUS_THUMBS_OR_TICK" },
    dataConfig: {
      dataType: "PROJECT_REPORT_STATUS",
    },
    sortConfig: {
      entity: "PROJECT_REPORT_STATUS",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_STUDENT],
    projectGroupType: [
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Student",
    locale: "common:student",
    id: "student_name",
    style: {
      justifyContent: "start",
      position: "sticky",
      left: "0",
      backgroundColor: "inherit",
      zIndex: "9",
      width: "100%",
      padding: "0 20px",
      height: "100%",
      alignItems: "center",
    },
    uiConfig: {
      uiType: "NAME_WITH_AVATAR",
      profileCascadeStyle: { height: "32px", width: "32px" },
    },
    dataConfig: { dataType: "PORTFOLIO_STUDENT_NAME_WITH_AVATAR" },
    sortConfig: {
      entity: "STUDENT",
      hasSortingEnabled: true,
      sortOptionsType: "name",
      sortType: "dropdown",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Student",
    locale: "common:student",
    id: "student_name",
    style: {
      justifyContent: "start",
      position: "sticky",
      left: "0",
      backgroundColor: "inherit",
      zIndex: "9",
      width: "100%",
      padding: "0 20px",
      height: "100%",
      alignItems: "center",
    },
    uiConfig: {
      uiType: "NAME_WITH_AVATAR",
      profileCascadeStyle: { height: "32px", width: "32px" },
    },
    dataConfig: { dataType: "PROJECT_STUDENT_NAME_WITH_AVATAR" },
    sortConfig: {
      entity: "STUDENT",
      hasSortingEnabled: true,
      sortOptionsType: "name",
      sortType: "dropdown",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Student",
    locale: "common:student",
    id: "student_cascade",
    uiConfig: {
      uiType: "NAMES_CASCADE_WITH_AVATAR",
      profileCascadeStyle: { height: "32px", width: "32px" },
    },
    dataConfig: {
      dataType: "PROJECT_STUDENT_NAME_WITH_AVATAR",
    },
    sortConfig: {
      entity: "STUDENT",
      sortType: "dropdown",
      hasSortingEnabled: true,
      sortOptionsType: "name",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_STUDENT],
    projectGroupType: [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON],
  },
  {
    label: "Adviser",
    locale: "project:advisor",
    id: "advisor_name",
    style: { justifyContent: "start" },
    uiConfig: {
      uiType: "NAME_WITH_AVATAR",
      profileCascadeStyle: { height: "24px", width: "24px" },
    },
    sortConfig: {
      entity: "STAFF",
      hasSortingEnabled: true,
      sortType: "dropdown",
      sortOptionsType: "name",
    },
    dataConfig: { dataType: "PORTFOLIO_STAFF_NAME_WITH_AVATAR" },
    users: [ROLE_ADMIN],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Supervisors",
    locale: "project:supervisor",
    id: "supervisor_name",
    style: { justifyContent: "start" },
    sortConfig: {
      entity: "STAFF",
      hasSortingEnabled: true,
      sortOptionsType: "name",
      sortType: "dropdown",
    },
    uiConfig: {
      uiType: "NAME_WITH_AVATAR",
      profileCascadeStyle: { height: "24px", width: "24px" },
    },
    dataConfig: { dataType: "PROJECT_SUPERVISOR_NAME_WITH_AVATAR" },
    users: [ROLE_ADMIN, ROLE_STUDENT],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Teacher",
    locale: "common:teacher",
    id: "supervisor_name",
    style: { justifyContent: "start" },
    uiConfig: {
      uiType: "NAME_WITH_AVATAR",
      profileCascadeStyle: { height: "24px", width: "24px" },
    },
    dataConfig: { dataType: "PROJECT_SUPERVISOR_NAME_WITH_AVATAR" },
    sortConfig: {
      entity: "STAFF",
      hasSortingEnabled: true,
      sortType: "dropdown",
      sortOptionsType: "name",
    },
    users: [ROLE_ADMIN],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
  {
    label: "Experiences",
    locale: "project:experience_plural",
    id: "experience_count",
    style: { justifyContent: "start" },
    type: "INTCOLUMN",
    dataConfig: { dataType: "EXPERIENCE_COUNT" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Projects",
    locale: "project:project_plural",
    id: "project_count",
    style: { justifyContent: "start" },
    type: "INTCOLUMN",
    dataConfig: { dataType: "PROJECT_COUNT" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Learning Outcomes achieved",
    locale: "project:lo_achieved",
    id: "lo_count",
    sortConfig: {
      entity: "ACHIEVED_PLANNER_ELEMENTS_COUNT",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    style: { justifyContent: "start" },
    type: "INTCOLUMN",
    dataConfig: { dataType: "LO_COUNT" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Interviews",
    locale: "project:interview_plural",
    id: "interviews",
    uiConfig: { uiType: "STATUS_TICK_MARK" },
    dataConfig: { dataType: "PORTFOLIO_INTERVIEWS" },
    sortConfig: {
      entity: "FORMAL_INTERVIEWS_COUNT",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Title",
    locale: "common:title",
    id: "titlePlannerField",
    type: "TEXTCOLUMN",
    dataConfig: {
      fieldType: "essayTitle",
      dataType: "PLANNER_FIELD",
    },
    style: { paddingRight: "24px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_TOK_ESSAY],
  },
  {
    label: "Project Title",
    locale: "progressReport:project_title",
    id: "titleField",
    dataConfig: {
      fieldType: "title",
      dataType: "FIELD",
    },
    uiConfig: { uiType: "TEXT_OR_DASH" },
    style: { paddingRight: "20px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Activity Title",
    locale: "project:activity_title",
    id: "titleField",
    uiConfig: { uiType: "TEXT_OR_DASH" },
    dataConfig: {
      fieldType: "title",
      dataType: "FIELD",
    },
    style: { paddingRight: "20px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_STUDENT],
    projectGroupType: [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON],
  },
  {
    label: "Meeting notes",
    locale: "project:meeting_note",
    id: "meeting_notes",
    uiConfig: { uiType: "MEETING_NOTES" },
    dataConfig: { dataType: "PROJECT_ACADEMIC_HONESTY" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Subject",
    locale: "project:subject",
    id: "subject",
    type: "TEXTCOLUMN",
    dataConfig: { dataType: "SUBJECT" },
    sortConfig: {
      entity: "SUBJECT",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_EE],
  },
  {
    label: "Title",
    locale: "project:title",
    id: "titleField",
    type: "TEXTCOLUMN",
    dataConfig: {
      fieldType: "title",
      dataType: "FIELD",
    },
    style: { paddingRight: "20px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_EE],
  },
  {
    label: "Theme",
    locale: "project:theme",
    id: "theme",
    type: "TEXTCOLUMN",
    dataConfig: {
      fieldType: "TOKTheme",
      dataType: "PLANNER_FIELD",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION],
  },
  {
    label: "IA Prompt",
    locale: "project:ia_prompts",
    id: "ia_prompt",
    type: "TEXTCOLUMN",
    dataConfig: {
      fieldType: "IAPrompt",
      dataType: "PLANNER_FIELD",
    },
    style: { paddingRight: "20px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION],
  },
  {
    label: "Proposal",
    locale: "project:proposal_status",
    id: "proposal",
    uiConfig: { uiType: "ICON" },
    dataConfig: { dataType: "PROJECT_REPORT_STATUS" },
    style: { width: "100%" },
    sortConfig: {
      entity: "REPORT_STATUS",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
  {
    label: "Reflection",
    locale: "project:reflection_session_plural",
    id: "reflection",
    uiConfig: { uiType: "STATUS_TICK_MARK" },
    dataConfig: { dataType: "PROJECT_ACADEMIC_HONESTY" },
    sortConfig: {
      entity: "REFLECTION_COUNT",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_EE],
  },
  {
    label: "Interaction",
    locale: "project:interaction_status",
    id: "interaction",
    uiConfig: { uiType: "STATUS_TICK_MARK" },
    dataConfig: { dataType: "PROJECT_ACADEMIC_HONESTY" },
    sortConfig: {
      entity: "REFLECTION_COUNT",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_TOK_ESSAY],
  },
  {
    label: "RPPF",
    locale: "project:rppf_status",
    id: "rppf",
    style: { width: "100%" },
    uiConfig: { uiType: "STATUS_TICK_MARK" },
    dataConfig: { dataType: "PROJECT_ACADEMIC_HONESTY_REMARKS" },
    sortConfig: {
      entity: "RPPF",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_EE],
  },
  {
    label: "PPF",
    locale: "project:ppf_status",
    id: "ppf",
    uiConfig: { uiType: "STATUS_TICK_MARK" },
    dataConfig: { dataType: "PROJECT_ACADEMIC_HONESTY_REMARKS" },
    sortConfig: {
      entity: "RPPF",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_TOK_ESSAY],
  },
  {
    label: "Overall progress",
    locale: "project:overall_progress",
    id: "cas_overall_progress",
    uiConfig: { uiType: "OVERALL_PROGRESS" },
    dataConfig: { dataType: "PROJECT_STATUS" },
    sortConfig: {
      entity: "OVERALL_PROGRESS",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    disableCellClick: true,
    style: { paddingRight: "28px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
  },
  {
    label: "Overall progress",
    locale: "project:overall_progress",
    id: "ee_overall_progress",
    uiConfig: { uiType: "OVERALL_PROGRESS" },
    dataConfig: { dataType: "PROJECT_STATUS" },
    sortConfig: {
      entity: "STATUS",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    disableCellClick: true,
    style: { paddingRight: "28px" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
  {
    label: "Status",
    locale: "common:status",
    id: "mark_complete",
    disableCellClick: true,
    uiConfig: { uiType: "STATUS" },
    dataConfig: { dataType: "PORTFOLIO_STATUS" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_CAS],
    style: { paddingRight: "20px" },
  },
  {
    label: "Status",
    locale: "common:status",
    id: "status",
    uiConfig: { uiType: "STATUS" },
    dataConfig: { dataType: "PROJECT_STATUS" },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    style: { paddingRight: "32px" },
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
  {
    label: "Grade",
    locale: "project:grade",
    id: "grade",
    type: "INTCOLUMN",
    style: { justifyContent: "start" },
    dataConfig: {
      dataType: "ASSESSMENT_RATING",
      totalAssessmentRating: 10,
      ratingType: "FINAL_GRADE",
    },
    sortConfig: {
      entity: "ASSESSMENT_GRADE",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [PROJECT_GROUP_TYPE_DP_EE],
  },
  {
    label: "Assessment",
    locale: "project:assessment",
    id: "TOKgrade",
    type: "INTCOLUMN",
    style: { justifyContent: "start" },
    sortConfig: {
      entity: "ASSESSMENT_GRADE",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    dataConfig: {
      ratingType: "IB_DEFINED",
      totalAssessmentRating: 10,
      dataType: "ASSESSMENT_RATING",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
  {
    label: "AHF",
    locale: "project:AHF",
    id: "academic_honesty",
    style: {
      justifyContent: "center",
    },
    uiConfig: { uiType: "LOCK_OPEN_CLOSE" },
    dataConfig: {
      dataType: "PROJECT_ACADEMIC_HONESTY_LOCKED",
    },
    sortConfig: {
      entity: "ACADEMIC_HONESTY",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Assessment",
    locale: "project:assessment",
    id: "pp_grade",
    dataConfig: {
      dataType: "ASSESSMENT_RATING",
      totalAssessmentRating: 24,
    },
    uiConfig: { uiType: "TEXT_OR_DASH" },
    sortConfig: {
      entity: "ASSESSMENT_GRADE",
      hasSortingEnabled: true,
      sortType: "toggle",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Overall Student Progress",
    locale: "project:overall_student_progress",
    id: "project_status",
    uiConfig: { uiType: "COLOR_TEXT" },
    dataConfig: {
      dataType: "PROJECT_STATUS",
    },
    users: [ROLE_ADMIN, ROLE_SUPERVISOR],
    projectGroupType: [
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
  {
    label: "Action",
    locale: "common:action_plural",
    id: "project_actions",
    type: "ACTIONS",
    dataConfig: {
      dataType: "PROJECT_ACTIONS",
    },
    style: { justifyContent: "center" },
    disableCellClick: true,
    users: [ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_STUDENT],
    projectGroupType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
      PROJECT_GROUP_TYPE_COMMUNITY,
    ],
  },
];

export const getFeedDataColumnList = ({ userType, projectGroupType }) => {
  return _.filter(
    feedDataColumnList,
    columnDetails =>
      _.includes(columnDetails.users, userType) &&
      _.includes(columnDetails.projectGroupType, projectGroupType)
  );
};

export const getUpdatedAction = ({
  entityType,
  action,
  isFinalReportSubmitted,
  nodeFields,
  t,
  courseId,
}) => {
  let fieldValue;
  switch (entityType) {
    case "FINAL_REPORT":
      return {
        ...action,
        disabled: !isFinalReportSubmitted,
        disabledTooltip: t("common:file_not_uploaded_yet"),
      };

    case "TEMPLATE_FIELD":
      fieldValue = _.get(
        _.find(nodeFields, { uid: action.config?.metadata.fieldUid }),
        "value",
        null
      );
      return {
        ...action,
        disabled: _.isEmpty(fieldValue),
        disabledTooltip: t("common:file_not_uploaded_yet"),
      };

    default:
      return {
        ...action,
        config: {
          ...action.config,
          metadata: {
            ...action.config?.metadata,
            ...(courseId && { courseId }),
          },
        },
      };
  }
};

const getProjectActionDetails = ({
  id,
  t,
  isFinalReportSubmitted,
  getPrintFile,
  updateProjectModalAndDialogueValue,
  goToRelativeRoute,
  key,
  action,
  nodeFields,
  courseId,
}) => {
  const { entityType } = _.get(action, "config", {});

  const updatedAction = getUpdatedAction({
    entityType,
    action,
    isFinalReportSubmitted,
    nodeFields,
    t,
    courseId,
  });

  const { labelLocale, actionType } = updatedAction;

  switch (actionType) {
    case "PRINT": {
      const { nameLocale, type, metadata } = _.get(updatedAction, "config", {});
      const { disabled = false, disabledTooltip } = updatedAction;
      return {
        key,
        label: t("common:download_with_label", {
          label: t(labelLocale),
        }),
        clickAction: () =>
          getPrintFile({
            id,
            name: t(nameLocale),
            mode: "pdfurl",
            type,
            metadata,
          }),
        disabled,
        disabledTooltip,
      };
    }

    case "DELETE": {
      return {
        key,
        label: t(labelLocale),
        isDestructive: true,
        clickAction: () =>
          updateProjectModalAndDialogueValue({
            projectId: id,
            displayDialogueBox: true,
            dialogueType: "DELETE",
          }),
      };
    }

    case "SHARE": {
      const { shareUserType } = _.get(updatedAction, "config", {});
      return {
        key,
        label: t(labelLocale),
        clickAction: () =>
          updateProjectModalAndDialogueValue({
            projectId: id,
            displayShareModel: true,
            shareModalDetails: {
              shareUserType,
              shareModalTitle: labelLocale,
            },
          }),
      };
    }

    case "EDIT": {
      const { extraParams } = _.get(updatedAction, "config", {});
      return {
        key,
        label: t(labelLocale),
        clickAction: () =>
          updateProjectModalAndDialogueValue({
            projectId: id,
            displayCreateEditProjectModel: true,
            extraParams,
            modalMode: "EDIT",
          }),
      };
    }

    case "GO_TO_PROJECT": {
      return {
        key,
        label: t(labelLocale),
        clickAction: () =>
          goToRelativeRoute({
            route: `./${id}`,
          }),
      };
    }

    default:
      return {};
  }
};

const getProjectActionMenu = props => {
  const { actionIds, actionsList, ...rest } = props;

  return _.map(actionIds, key =>
    getProjectActionDetails({
      key,
      action: _.get(actionsList, `${key}`, {}),
      ...rest,
    })
  );
};

const getColumnData = props => {
  const {
    t,
    subjects,
    getPrintFile,
    project,
    dataConfig = {},
    updateProjectDetails,
    updateProjectOverallProgress,
    updateStudentProjectPortfolio,
    id,
    updateProjectModalAndDialogueValue,
    uiType,
    lockLabel,
    assessmentTools,
    deadlines,
    goToRelativeRoute,
    actionIds = [],
    actionsList,
    courseId,
  } = props;

  const HELP_OBJ = {
    ee_overall_progress: {
      onProgressStatusChange: updateProjectOverallProgress,
    },
    cas_overall_progress: {
      onProgressStatusChange: updateStudentProjectPortfolio,
    },
  };

  const getFieldValue = ({ fieldType, fields }) => {
    const value = _.get(
      _.find(fields, field => field.uid == fieldType),
      "value",
      ""
    );
    return value;
  };

  const getFieldAttachments = ({ fieldType, fields }) => {
    const value = _.get(
      _.find(fields, field => field.uid == fieldType),
      "attachments",
      ""
    );
    return value;
  };

  const getResolvedMinimalTreeValue = ({ fieldType, fields }) => {
    const value = _.get(
      _.find(fields, field => field.uid == fieldType),
      "resolvedMinimalTree",
      ""
    );
    return value;
  };

  const getSubjectValue = ({ fields }) => {
    const subjectId = getFieldValue({ fieldType: "subject", fields });
    return _.get(
      _.find(subjects, subject => subject.id == subjectId),
      "name",
      "-"
    );
  };

  const getPlannerElementValue = ({ fieldType, fields }) => {
    const plannerNodes = _.get(
      _.find(fields, field => field.uid == fieldType),
      "resolvedMinimalTree.nodes",
      []
    );
    return _.get(_.first(plannerNodes), "label", "-");
  };

  const getDeadlinesValue = ({ completedDeadlines }) => {
    const deadlinesList = _.map(deadlines, deadline => ({
      ...deadline,
      isChecked: Boolean(
        _.find(
          completedDeadlines,
          completedDeadline => completedDeadline.deadline.id === deadline.id
        )
      ),
    }));
    const deadlineLabel =
      _.size(deadlines) > 0
        ? `${_.size(_.filter(deadlinesList, { isChecked: true }))}/${_.size(
            deadlines
          )}`
        : "-";
    const deadlinesAchieved = _.filter(deadlinesList, { isChecked: true });
    const deadlinesPending = _.filter(deadlinesList, { isChecked: false });
    const isTooltipEnabled = !_.isEmpty(deadlines);
    const deadlineData = [
      { data: deadlinesAchieved, subLabel: "common:completed" },
      { data: deadlinesPending, subLabel: "common:pending" },
    ];

    return { deadlineData, deadlineLabel, isTooltipEnabled };
  };

  const getAssessmentRatings = ({
    project,
    totalAssessmentRating,
    ratingType,
  }) => {
    const ibPYPElementRatings = _.get(project, "node.ibPYPElementRatings", []);

    if (ratingType) {
      const assessmentRating = _.get(
        _.find(
          ibPYPElementRatings,
          value =>
            _.get(value, "academicCriteriaSet.criteriaType", "") == ratingType
        ),
        "academicCriteriaValue.abbreviation",
        "-"
      );

      if (ratingType == "FINAL_GRADE") return assessmentRating;

      return assessmentRating > 0
        ? `${assessmentRating}/${totalAssessmentRating}`
        : "-";
    } else {
      if (_.isEmpty(ibPYPElementRatings)) {
        return null;
      }

      //uniqBy is used on ibPYPElementId to remove duplicate data in case of multiple students in a project
      const rating = _.reduce(
        _.uniqBy(ibPYPElementRatings, "ibPYPElementId"),
        (count, item) => {
          return (
            count + +_.get(item, "academicCriteriaValue.abbreviation", "0")
          );
        },
        0
      );

      return `${rating}/${totalAssessmentRating}`;
    }
  };

  const getCriterionAssessmentRating = ({
    subjectLevelId,
    assessmentTools,
  }) => {
    const assessmentTool = _.find(assessmentTools, item =>
      _.some(item?.genericTags, item => _.isEqual(item?.id, subjectLevelId))
    );
    const criteria = _.get(assessmentTool, "criteria", []);
    const totalScore = getTotalScoreMemoized({ criteria });

    const ibPYPElementRatings = _.get(project, "node.ibPYPElementRatings", []);

    const ratedScore = getRatedScoreMemoized({
      ratedCriteria: ibPYPElementRatings,
    });

    return ratedScore > 0 && totalScore > 0
      ? `${ratedScore}/${totalScore}`
      : "-";
  };

  const getInterviewArray = count => {
    const tickArray = [];
    _.times(count, () => {
      tickArray.push(true);
    });
    return tickArray;
  };

  const { dataType = "" } = dataConfig;

  switch (dataType) {
    case "ID":
      return _.get(project, "node.id", "");

    case "PORTFOLIO_STUDENT_NAME_WITH_AVATAR":
      return {
        id: _.get(project, `node.student.id`, null),
        name: `${_.get(project, `node.student.firstName`, "")} ${_.get(
          project,
          `node.student.lastName`,
          ""
        )}`,
        profileImage: _.get(project, `node.student.profileImage`, null),
      };

    case "PROJECT_STUDENT_NAME_WITH_AVATAR": {
      switch (uiType) {
        default:
        case "NAME_WITH_AVATAR":
          return {
            id: _.get(project, `node.students[0].id`, null),
            name: `${_.get(project, `node.students[0].firstName`, "")} ${_.get(
              project,
              `node.students[0].lastName`,
              ""
            )}`,
            profileImage: _.get(project, `node.students[0].profileImage`, null),
          };

        case "NAMES_CASCADE_WITH_AVATAR":
          return {
            isStudentsListEmpty: _.isEmpty(_.get(project, "node.students")),
            firstProfileName: `${_.get(
              project,
              "node.students[0].firstName",
              ""
            )} ${_.get(project, "node.students[0].lastName", "")}`,
            firstProfileImage: _.get(
              project,
              "node.students[0].profileImage",
              null
            ),
            firstProfileId: _.get(project, "node.students[0].id", null),
            tailingStudents: _.map(
              _.tail(_.get(project, "node.students", [])),
              student =>
                `${_.get(student, "firstName", "")} ${_.get(
                  student,
                  "lastName",
                  ""
                )}`
            ),
          };
      }
    }

    case "PORTFOLIO_STAFF_NAME_WITH_AVATAR":
      return {
        id: _.get(project, `node.staff.id`, null),
        name: `${_.get(project, `node.staff.firstName`, "")} ${_.get(
          project,
          `node.staff.lastName`,
          ""
        )}`,
        profileImage: _.get(project, `node.staff.profileImage`, null),
      };

    case "PORTFOLIO_COMPLETION_STATUS":
      return _.get(project, "node.isComplete", false)
        ? { icon: "tickIcon", customTooltip: t("common:completed") }
        : { icon: "thumbsIcon", customTooltip: t("project:pending") };

    case "PROJECT_SUPERVISOR_NAME_WITH_AVATAR":
      return {
        id: _.get(project, `node.supervisors[0].id`, null),
        name: `${_.get(project, `node.supervisors[0].firstName`, "")} ${_.get(
          project,
          `node.supervisors[0].lastName`,
          ""
        )}`,
        profileImage: _.get(project, `node.supervisors[0].profileImage`, null),
      };

    case "UNREAD_COMMENTS_COUNT":
      return (
        _.reduce(
          _.get(project, "node.fields", []),
          (unreadCommentsCount, field) => {
            unreadCommentsCount += _.get(field, "comments.unreadCountV2", 0);
            return unreadCommentsCount;
          },
          0
        ) +
        _.reduce(
          _.get(project, "node.projectReports", []),
          (unseenAnnotationCount, report) => {
            if (!report?.isRead) {
              ++unseenAnnotationCount;
            }
            return unseenAnnotationCount;
          },
          0
        )
      );

    case "FIELD": {
      const { fieldType } = dataConfig;
      const fields = _.get(project, "node.fields", []);
      const value = getFieldValue({
        fieldType,
        fields,
      });
      const resolvedMinimalTree = getResolvedMinimalTreeValue({
        fieldType,
        fields,
      });
      switch (uiType) {
        case "FINAL_REPORT_WITH_SIMILARITY":
          return {
            finalReport: getFieldAttachments({ fieldType, fields }),
            projectId: _.get(project, "node.id", null),
          };
        case "TICK_AND_CLOCK":
        case "ICON": {
          switch (id) {
            case "bibliography": {
              const bibliographyField = _.filter(fields, field =>
                _.includes(field?.uid, "bibliography")
              );
              const isBibliographyValueEmpty = _.isEmpty(
                _.get(bibliographyField, "[0].value", [])
              );

              return {
                iconType: !isBibliographyValueEmpty ? "tickIcon" : "closeIcon",
                iconVariant: !isBibliographyValueEmpty ? "success" : "subtle",
              };
            }

            case "extract": {
              const filterFields = _.filter(
                fields,
                field =>
                  _.includes(field.uid, "extract_title") ||
                  _.includes(field.uid, "extract_description")
              );

              return {
                iconType: !_.some(filterFields, item => {
                  if (_.includes(item.uid, "extract_title")) {
                    return !item.value;
                  } else if (_.includes(item.uid, "extract_description")) {
                    const fieldValue = _.get(
                      item.value,
                      `fieldValues.${item.value?.selectedField}`,
                      null
                    );
                    return !fieldValue;
                  }
                })
                  ? "tickIcon"
                  : "closeIcon",
              };
            }
            default: {
              return { iconType: !_.isEmpty(value) ? "tickIcon" : "closeIcon" };
            }
          }
        }
        case "TEXT_WITH_IMAGE": {
          let subTitleText = "";
          switch (id) {
            case "experience_title":
              subTitleText =
                _.isEqual(_.get(project, "node.type", ""), "PROJECT") &&
                t("project:cas_project");
              break;
          }
          return {
            value,
            image: getFieldValue({ fieldType: "image", fields }),
            subTitleText,
          };
        }

        case "CHARACTER_DENOTATION": {
          let characterDenotationData = {};
          let tooltip = "";
          switch (id) {
            case "cas_strands": {
              const casStrands = [
                {
                  value: "Creativity",
                  abbreviation: "C",
                  subLabel: "project:creativity",
                },
                {
                  value: "Activity",
                  abbreviation: "A",
                  subLabel: "project:activity",
                },
                {
                  value: "Service",
                  abbreviation: "S",
                  subLabel: "project:service",
                },
              ];
              const availableCasStrands = _.map(
                resolvedMinimalTree?.nodes,
                item => item?.label
              );

              characterDenotationData = _.map(casStrands, item => {
                let shouldShowEnabled = false;
                if (_.includes(availableCasStrands, item?.value)) {
                  shouldShowEnabled = true;
                  tooltip = t("project:label_strand_selected", {
                    label: t(item?.subLabel),
                  });
                } else {
                  tooltip = t("project:label_strand_not_selected", {
                    label: t(item?.subLabel),
                  });
                }
                return {
                  abbreviation: item?.abbreviation,
                  shouldShowEnabled,
                  tooltip,
                };
              });
            }
          }

          return {
            data: characterDenotationData,
          };
        }

        case "TEXT_WITH_TOOLTIP": {
          let data = [];
          switch (id) {
            case "los_tagged": {
              data = {
                text: _.size(resolvedMinimalTree?.nodes),
                tooltipListArray: [
                  {
                    title: "",
                    list: _.map(
                      resolvedMinimalTree?.nodes,
                      item => item?.label
                    ),
                  },
                ],
              };
              break;
            }
            case "duration": {
              data = {
                text: t("common:count_day", {
                  count: moment
                    .duration(
                      moment(value?.endDate).diff(moment(value?.startDate))
                    )
                    .format("D"),
                }),
                tooltip: `${formatDate({
                  date: value?.startDate,
                  format: "D MMM",
                })} - ${formatDate({
                  date: value?.endDate,
                  format: "D MMM",
                })}`,
              };
            }
          }
          return data;
        }

        case "TEXT_OR_DASH": {
          switch (id) {
            case "duration": {
              return t("common:count_day", {
                count: moment
                  .duration(
                    moment(value?.endDate).diff(moment(value?.startDate))
                  )
                  .format("DD"),
              });
            }
          }
          return value;
        }

        default:
          return value;
      }
    }

    case "SUBJECT":
      return getSubjectValue({ fields: _.get(project, "node.fields", []) });

    case "PLANNER_FIELD": {
      const { fieldType } = dataConfig;

      return getPlannerElementValue({
        fieldType,
        fields: _.get(project, "node.fields", []),
      });
    }

    case "PROJECT_REPORT_STATUS": {
      switch (uiType) {
        case "TICK_AND_CLOCK":
        case "ICON":
          return {
            iconType:
              _.get(project, "node.reportStatus", "") ==
              PROJECT_REPORT_STATUS_APPROVED
                ? "tickIcon"
                : "clockIcon",
            tickTooltip: t("common:approved"),
            clockTooltip: t("project:pending_for_approval"),
          };

        default:
        case "STATUS_THUMBS_OR_TICK": {
          switch (_.get(project, "node.reportStatus", "none")) {
            case PROJECT_REPORT_STATUS_APPROVED:
              return "thumbsIcon";
            case PROJECT_REPORT_STATUS_SUBMITTED:
              return "tickIcon";
            case PROJECT_REPORT_STATUS_NOT_STARTED:
              return "dashedMinusIcon";
            case PROJECT_REPORT_STATUS_PENDING:
            case PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL:
              return "alertIcon";
            case PROJECT_REPORT_STATUS_NON_SUBMISSION:
              return "closeIcon";
            default:
              return null;
          }
        }
      }
    }

    case "PROJECT_ACADEMIC_HONESTY":
      switch (uiType) {
        default:
        case "STATUS_TICK_MARK":
          return {
            tickArray: _.map(
              _.get(project, "node.academicHonesty", []),
              academicHonesty => academicHonesty.isLocked
            ),
            tickCount: 3,
            untickedIconType: "iconWithNumber",
          };

        case "MEETING_NOTES":
          return {
            academicHonesty: _.get(project, "node.academicHonesty", []),
            academicHonestyRemark: _.get(
              project,
              "node.academicHonestyRemarks",
              {}
            ),
          };
      }

    case "PROJECT_ACADEMIC_HONESTY_LOCKED":
      return _.get(project, "node.isAcademicHonestyLocked", false);

    case "PROJECT_ACADEMIC_HONESTY_REMARKS":
      return {
        tickArray: [
          _.get(project, "node.academicHonestyRemarks.isLocked", false),
        ],
        tickCount: 1,
        untickedIconType: "dash",
      };

    case "PORTFOLIO_INTERVIEWS":
      return {
        tickArray: getInterviewArray(
          _.get(project, "node.interviews.totalCount", 0)
        ),
        tickCount: 3,
        untickedIconType: "iconWithNumber",
      };

    case "ASSESSMENT_RATING": {
      const { ratingType = null, totalAssessmentRating = 10 } = dataConfig;
      return getAssessmentRatings({
        project,
        totalAssessmentRating,
        ratingType,
      });
    }
    case "FINAL_REPORT_WITH_SIMILARITY": {
      return {
        finalReport: _.get(project, "node.finalProjectReports", []),
        projectId: _.get(project, "node.id", null),
      };
    }

    case "PROJECT_STATUS":
      switch (uiType) {
        case "STATUS":
          return {
            id: _.get(project, "node.id", false),
            isCompleted: _.get(project, "node.isCompleted", false),
            isFinalReportSubmitted:
              _.size(_.get(project, "node.finalProjectReports", [])) > 0,
            label: _.get(project, "node.isCompleted", false)
              ? t("common:locked")
              : t("common:editable"),
            updateStatus: updateProjectDetails,
            menuOptions: [
              {
                label: t("project:unlock_with_label", {
                  label: t(lockLabel),
                }),
                key: "INCOMPLETE",
              },
              {
                label: t("project:lock_with_label", {
                  label: t(lockLabel),
                }),
                key: "COMPLETE",
                disabled: !(
                  _.size(_.get(project, "node.finalProjectReports", [])) > 0
                ),
              },
            ],
          };

        case "OVERALL_PROGRESS": {
          const { onProgressStatusChange } = HELP_OBJ[id];

          return {
            status: _.get(project, "node.status", ""),
            onProgressStatusChange,
          };
        }

        case "TEXT_OR_DASH": {
          switch (id) {
            case "cas_overall_progress":
              return t(
                _.find(OVERALL_PROGRESS, {
                  value: _.get(project, `node.status`, ""),
                })?.label
              );
            case "project_status":
            default:
              return t(
                PROJECT_STATUS[_.get(project, `node.status`, "")]?.label
              );
          }
        }

        default:
        case "COLOR_TEXT":
          return {
            color: PROJECT_STATUS[_.get(project, `node.status`, "")]?.color,
            label: PROJECT_STATUS[_.get(project, `node.status`, "")]?.label,
          };
      }

    case "PORTFOLIO_STATUS":
      return {
        id: _.get(project, "node.id", false),
        status: _.get(project, "node.status", ""),
        isCompleted: _.get(project, "node.isComplete", false),
        updateStatus: updateStudentProjectPortfolio,
        menuOptions: [
          {
            label: t("project:complete"),
            key: "COMPLETE",
            disabled:
              _.get(project, "node.achievedPlannerElements.totalCount", 0) !==
                7 && _.get(project, "node.interviews.totalCount", 0) !== 3,
            disabledTooltip: t("project:cas_requirement_not_met"),
          },
          { label: t("project:incomplete"), key: "INCOMPLETE" },
        ],
        label: _.get(project, "node.isComplete", false)
          ? t("project:complete")
          : t("project:incomplete"),
      };

    case "EXPERIENCE_COUNT":
      return _.get(project, `node.projectCounts.experiencesCount`, 0);

    case "PROJECT_COUNT":
      return _.get(project, `node.projectCounts.projectsCount`, 0);

    case "STRAND_COUNT": {
      const strandLocaleKeyMap = {
        serviceCount: "project:service",
        creativityCount: "project:creativity",
        activityCount: "project:activity",
      };
      const strandCountsKey = dataConfig?.strandCountsKey;
      const strandCount = _.get(
        project,
        `node.strandCounts.${strandCountsKey}`,
        0
      );
      const projectCounts = _.get(project, "node.projectCounts", {});
      return {
        text: strandCount,
        tooltip: t(
          "project:label_strand_selected_strandCount_times_across_projectCount_experiences_projects",
          {
            label: t(strandLocaleKeyMap[strandCountsKey]),
            strandCount,
            projectCount:
              _.get(projectCounts, "projectsCount", 0) +
              _.get(projectCounts, "experiencesCount", 0),
          }
        ),
      };
    }

    case "LO_COUNT":
      return {
        text: _.get(project, `node.achievedPlannerElements.totalCount`, 0),
        tooltipListArray: [
          {
            title: "",
            list: _.map(
              _.get(project, "node.achievedPlannerElements.edges", []),
              item => _.get(item, "node.label", "")
            ),
          },
        ],
      };

    case "POST_COUNT":
      switch (dataConfig?.postType) {
        case "allPostsCount":
          return _.get(project, "node.allPostsCount", 0);
        default:
          return _.get(project, `node.posts.totalCount`, 0);
      }

    case "DEADLINE":
      return getDeadlinesValue({
        completedDeadlines: _.get(project, "node.completedDeadlines", []),
      });

    case "PROJECT_ACTIONS":
      return getProjectActionMenu({
        actionIds,
        actionsList,
        id: _.get(project, "node.id", ""),
        t,
        isCompleted: _.get(project, "node.isCompleted", false),
        isFinalReportSubmitted:
          _.size(_.get(project, "node.finalProjectReports", [])) > 0,
        updateProjectDetails,
        getPrintFile,
        updateProjectModalAndDialogueValue,
        nodeFields: _.get(project, "node.fields", []),
        goToRelativeRoute,
        courseId,
      });

    case "SUBJECT_LEVEL": {
      const tags = _.get(project, "node.students.0.courseMap.0.tags");
      const tag = _.find(tags, { type: "SUBJECT_LEVEL" });

      return _.get(tag, "label", "");
    }

    case "CRITERION_ASSESSMENT_RATING": {
      const tags = _.get(project, "node.students.0.courseMap.0.tags");
      const tag = _.find(tags, { type: "SUBJECT_LEVEL" });
      const subjectLevelId = tag?.id;

      return getCriterionAssessmentRating({ subjectLevelId, assessmentTools });
    }

    default:
      return {};
  }
};

export const getStudentPortfolioFeedData = props => {
  const { studentPortfolioDetails, columnsConfig, ...rest } = props;

  return _.map(studentPortfolioDetails, portfolio => {
    return _.reduce(
      columnsConfig,
      (result, columnConfig) => {
        result[columnConfig.id] = getColumnData({
          project: portfolio,
          ...rest,
          ...columnConfig,
        });
        return result;
      },
      {}
    );
  });
};

export const getProjectGroupFeedData = props => {
  const { columnsConfig, projects, ...rest } = props;

  return _.map(projects, project => {
    return _.reduce(
      columnsConfig,
      (result, columnConfig) => {
        result[columnConfig.id] = getColumnData({
          project,
          ...rest,
          ...columnConfig,
        });
        return result;
      },
      {}
    );
  });
};

export const getSortMenuOptions = ({ type, entity }) => {
  switch (type) {
    case "name":
      return [
        {
          key: "FIRST_NAME_AZ",
          sortEntity: `${entity}_FIRST_NAME`,
          direction: "ASC",
          label: "first_name_az",
        },
        {
          key: "FIRST_NAME_ZA",
          sortEntity: `${entity}_FIRST_NAME`,
          direction: "DESC",
          label: "first_name_za",
        },
        {
          key: "LAST_NAME_AZ",
          sortEntity: `${entity}_LAST_NAME`,
          direction: "ASC",
          label: "last_name_az",
        },
        {
          key: "LAST_NAME_ZA",
          sortEntity: `${entity}_LAST_NAME`,
          direction: "DESC",
          label: "last_name_za",
        },
      ];
    default:
      return [];
  }
};

export const updateProjectOverallProgress = ({ id, status }) => {
  const projectInfo = getProjectInfoFromCache({ projectId: id });
  writeProjectInfoInCache({
    projectId: id,
    data: {
      ...projectInfo.node,
      status,
    },
  });
  return async (dispatch, getState) => {
    try {
      return await client.mutate({
        mutation: updateProjectMutation,
        variables: {
          status,
          projectId: id,
        },
      });
    } catch (e) {
      writeProjectInfoInCache({
        projectId: id,
        data: projectInfo.node,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const updateGuidingQuestionsInTemplate = ({
  template,
  guidingQuestions,
}) => {
  const { body } = template;
  const nodes = { ...body.nodes };
  const field_list = { ...body.field_list };
  let guidingQuestionFields = [];

  guidingQuestionFields = _.map(guidingQuestions, question => {
    const fieldId = "guidingQuestion_" + question.id;
    return {
      ...field_list.guidingQuestions,
      id: fieldId,
      config: {
        isDynamic: true,
        label: question.question,
        viewLabel: question.question,
        printLabel: question.question,
      },
      skipLocalisation: true,
    };
  });

  const guidingFieldIds = _.map(guidingQuestionFields, g => g.id);
  const guidingQuestionNode = {
    ...nodes.guidingQuestions,
    fieldIds: guidingFieldIds,
  };

  const guidingQuestionFieldsById = _.reduce(
    guidingQuestionFields,
    (result, questionField) => {
      result[questionField.id] = questionField;
      return result;
    },
    {}
  );

  const updatedTemplate = {
    ...template,
    body: {
      ...body,
      nodes: {
        ...nodes,
        guidingQuestions: guidingQuestionNode,
      },
      field_list: {
        ...field_list,
        ...guidingQuestionFieldsById,
      },
    },
  };
  return updatedTemplate;
};

export const getCanItemBeStarred = ({ projectGroupType }) => {
  switch (projectGroupType) {
    case PROJECT_GROUP_TYPE_PERSONAL:
    case PROJECT_GROUP_TYPE_COMMUNITY:
      return true;
    default:
      return false;
  }
};

export const getUpdatedFields = ({ fields, fieldsToRemove }) => {
  return _.filter(fields, field => _.indexOf(fieldsToRemove, field) < 0);
};

export const getCASProjectDetailsFilter = {
  uids: ["title", "dateDuration", "CASStrands", "image"],
};

export const getTemplateWithRemovedFields = ({
  template,
  nodesWithFieldsToRemove,
  summaryFieldsToRemove = [],
}) => {
  const { body } = template;
  const nodes = { ...body.nodes };
  const field_list = { ...body.field_list };
  const summary = { ...body.summary };
  let updatedSummary = summary;
  const updatedNodes = {};

  //removing fields from the specific node
  _.forEach(nodesWithFieldsToRemove, (nodeField, nodeId) => {
    updatedNodes[nodeId] = {
      ...nodes[nodeId],
      fieldIds: getUpdatedFields({
        fields: nodes[nodeId].fieldIds,
        fieldsToRemove: nodeField,
      }),
      mandatory: getUpdatedFields({
        fields: nodes[nodeId].mandatory || [],
        fieldsToRemove: nodeField,
      }),
    };
  });
  //removing fields from summary fields
  updatedSummary = getUpdatedFields({
    fields: updatedSummary,
    fieldsToRemove: summaryFieldsToRemove,
  });

  const updatedTemplate = {
    ...template,
    body: {
      ...body,
      nodes: {
        ...nodes,
        ...updatedNodes,
      },
      field_list: {
        ...field_list,
      },
      summary: updatedSummary,
    },
  };
  return updatedTemplate;
};

const getShouldRemoveDependentField = ({ dependencyField }) => {
  switch (dependencyField.uid) {
    case "CASStrands": {
      const selectedStrands = _.get(
        dependencyField,
        "resolvedMinimalTree.nodes",
        []
      );
      return _.find(
        selectedStrands,
        strand => _.get(strand, "label", "") == "Service"
      );
    }
    default:
      return isValidValue({
        value: dependencyField.value,
      });
  }
};

export const updateTemplateFieldsWithDependency = ({
  template,
  projectFieldData, //projectFieldData
}) => {
  const { body } = template;
  const field_list = { ...body.field_list };

  const summaryFieldsToRemove = [];
  //contains the node object with array of fields
  const parentNodeFieldsToRemove = {};

  //filtered out the dependent fields from the template field_List
  const dependentFields = _.filter(
    field_list,
    field => !_.isEmpty(_.get(field, "config.elementDependencyConfig", {}))
  );

  //created 2 array summaryfields to remove and nodes field to remove
  _.forEach(dependentFields, dependentField => {
    //fetch the dependency field and dependent parent node
    const {
      config: { elementDependencyConfig },
    } = dependentField;
    const dependentParentNodes = elementDependencyConfig.parentNodes;

    //fetch the value from the projectData fields of the dependency field
    const dependencyField = _.find(projectFieldData, {
      uid: elementDependencyConfig.field,
    });

    //added a key of dependent pareant node in the object
    _.forEach(dependentParentNodes, dependentParentNode => {
      if (!parentNodeFieldsToRemove[dependentParentNode]) {
        parentNodeFieldsToRemove[dependentParentNode] = [];
      }
    });

    const shouldRemoveDependentField = getShouldRemoveDependentField({
      dependencyField,
    });

    //if falsy value than add the dependent field to both the array
    _.forEach(dependentParentNodes, dependentParentNode => {
      if (!shouldRemoveDependentField) {
        summaryFieldsToRemove.push(dependentField.id);
        parentNodeFieldsToRemove[dependentParentNode].push(dependentField.id);
      }
    });
  });

  return getTemplateWithRemovedFields({
    template,
    nodesWithFieldsToRemove: parentNodeFieldsToRemove,
    summaryFieldsToRemove,
  });
};

export const getRefinedProjectsAndExperiences = ({ data }) => {
  const refinedData = _.map(data, item => {
    const { fields } = item.node;

    const getField = params => {
      return _.find(fields, { ...params });
    };

    const casStrands = _.map(
      _.get(getField({ uid: "CASStrands" }), "resolvedMinimalTree.nodes", []),
      item => item.label
    ).join(", ");

    const title = _.get(getField({ uid: "title" }), "value", "");

    const dateDuration = getField({ uid: "dateDuration" });
    const date = `${formatDate({
      date: _.get(dateDuration, "value.startDate", ""),
      format: "DD MMM",
    })} - ${formatDate({
      date: _.get(dateDuration, "value.endDate", ""),
      format: "DD MMM",
    })}`;

    const image = _.get(getField({ uid: "image" }), "value");

    return { ..._.omit(item.node, "fields"), casStrands, title, date, image };
  });

  const projects = _.filter(refinedData, { type: "PROJECT" });
  const experiences = _.filter(refinedData, { type: "EXPERIENCE" });
  return [projects, experiences];
};

export const getNodeWithFieldsToRemove = ({ fieldsToRemove, nodes }) => {
  const nodesWithFieldsToRemove = {};
  _.forEach(nodes, (node, id) => {
    const fieldsToRemoveFromNode = _.intersection(
      node.fieldIds,
      fieldsToRemove
    );
    if (!_.isEmpty(fieldsToRemoveFromNode)) {
      nodesWithFieldsToRemove[id] = fieldsToRemoveFromNode;
    }
  });
  return nodesWithFieldsToRemove;
};

export const getFieldsToRemove = ({ removeFieldsWithValue }) => {
  const fieldsToRemove = [];
  _.forEach(removeFieldsWithValue, (value, field) => {
    if (_.size(value) <= 1) fieldsToRemove.push(field);
  });
  return fieldsToRemove;
};

export const getTemplateWithRemovedChildIds = ({
  template,
  childIdsToRemove,
}) => {
  const {
    body: { nodes },
  } = template;
  // checking all the nodes from body and filtering the childIds which is in the childIdsToRemove.
  const updatedNodes = _.reduce(
    nodes,
    (result, val, key) =>
      update(result, {
        [key]: {
          $set: update(val, {
            //filtering childIds
            childIds: childIds =>
              update(childIds || [], {
                $set: _.filter(
                  childIds,
                  childId => !_.includes(childIdsToRemove, childId)
                ),
              }),
          }),
        },
      }),
    {}
  );

  return update(template, {
    body: {
      nodes: { $set: updatedNodes },
    },
  });
};

export const projectAssessmentBulkDownload = ({
  projectGroup,
  projectIds,
  fileName,
}) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.query({
        query: getProjectAssessmentBulkDownloadQuery,
        variables: {
          id: projectGroup,
          projectIds,
        },
        fetchPolicy: "network-only",
      });

      const projectAssessmentData = _.get(
        result,
        "data.node.assessmentCsvExport",
        []
      );
      return exportData({
        fileName,
        outputFormat: "XLSX",
        data: projectAssessmentData,
        fileType: "projectAssessment",
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      console.error(e);
      return null;
    }
  };
};

export const getProjectDetailsValueFromFieldKey = ({
  projectDetails = {},
  key,
}) => {
  switch (key) {
    case "casProjectType": {
      const { projectType } = projectDetails;
      if (projectType === "PROJECT") return ["PROJECT"];
      return [];
    }

    default:
      return null;
  }
};

export const updateProjectDetailsField = ({
  fieldUID,
  fieldValue,
  projectId,
}) => {
  return async (dispatch, getState) => {
    switch (fieldUID) {
      case "casProjectType":
        dispatch(
          updateProjectDetails({
            projectId,
            projectType: _.includes(fieldValue, "PROJECT")
              ? "PROJECT"
              : "EXPERIENCE",
          })
        );
        break;
    }
  };
};

export const updateAchievedPlannerElements = ({ input, portfolioId }) => {
  return async (dispatch, getState) => {
    const cachedData = getLearningOutcomesWithEvidencesFromCache({
      id: portfolioId,
    });
    const portfolioCachedData = getStudentProjectPortfolioProgressFragmentInCache(
      { id: portfolioId }
    );
    writeStudentProjectPortfolioProgressFragmentInCache({
      id: portfolioId,
      data: update(portfolioCachedData, {
        achievedPlannerElements: {
          $set: {
            totalCount:
              _.get(
                portfolioCachedData,
                "achievedPlannerElements.totalCount",
                0
              ) +
              _.size(_.get(input, "addedAchievedPlannerElementIds", [])) -
              _.size(_.get(input, "removedAchievedPlannerElementIds", [])),
            __typename: "PlannerElementConnection",
          },
        },
      }),
    });
    let plannerElementEvidences = [];
    try {
      if (!_.isEmpty(input.addedAchievedPlannerElementIds)) {
        plannerElementEvidences = _.map(
          _.get(cachedData, "node.plannerElementEvidences", []),
          evidence => {
            if (
              _.includes(
                input.addedAchievedPlannerElementIds,
                _.get(evidence, "plannerElementNode.id", null)
              )
            )
              return { ...evidence, isAchieved: true };
            return evidence;
          }
        );
      }
      if (!_.isEmpty(input.removedAchievedPlannerElementIds)) {
        plannerElementEvidences = _.map(
          _.get(cachedData, "node.plannerElementEvidences", []),
          evidence => {
            if (
              _.includes(
                input.removedAchievedPlannerElementIds,
                _.get(evidence, "plannerElementNode.id", null)
              )
            )
              return { ...evidence, isAchieved: false };
            return evidence;
          }
        );
      }
      writeLearningOutcomesWithEvidencesInCache({
        id: portfolioId,
        data: {
          node: {
            id: portfolioId,
            __typename: "StudentProjectPortfolio",
            plannerElementEvidences,
          },
        },
      });
      await client.mutate({
        mutation: updateAchievedPlannerElementMutation,
        variables: {
          input,
        },
      });
    } catch (e) {
      writeLearningOutcomesWithEvidencesInCache({
        id: portfolioId,
        data: cachedData,
      });
      writeStudentProjectPortfolioProgressFragmentInCache({
        id: portfolioId,
        data: portfolioCachedData,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const getHelpTextLabel = ({ uid, template }) => {
  const {
    body: { field_list = {}, nodes = {} } = {},
    helpEnabledFields,
  } = template;

  const type = _.get(_.find(helpEnabledFields, { uid }), "type", "FIELD");

  switch (type) {
    case "FIELD": {
      const { config: { label = "", viewLabel = "" } = {} } =
        field_list[uid] ?? {};

      return viewLabel ? viewLabel : label;
    }
    case "STEP": {
      const { label = "" } = nodes[uid] ?? {};
      return label;
    }
    default:
      return "";
  }
};

//This is used to update node / step in the Template with
//the respective role config based on role.
const updateItemWithConfig = ({ role, item }) => {
  const studentConfig = _.get(item, "studentConfig", {});
  const teacherConfig = _.get(item, "teacherConfig", {});

  if (role == ROLE_STUDENT) {
    return _.merge(item, studentConfig);
  } else {
    return _.merge(item, teacherConfig);
  }
};

const updateNodeItemWithConfig = ({ role, item }) => {
  const studentConfig = _.get(item, "studentConfig", {});
  const teacherConfig = _.get(item, "teacherConfig", {});

  if (role == ROLE_STUDENT) {
    return {
      ...item,
      ...studentConfig,
    };
  } else {
    return {
      ...item,
      ...teacherConfig,
    };
  }
};

//In this function we are updating all the fields and nodes
//in the template with role based config.
const updateTemplateWithRoleConfig = ({ template, role }) => {
  const {
    body: { nodes, field_list },
    body,
  } = template;

  const updatedNodes = {};
  const updatedFieldsList = {};

  _.forEach(nodes, (node, nodeId) => {
    updatedNodes[nodeId] = updateNodeItemWithConfig({ role, item: node });
  });

  _.forEach(field_list, (field, id) => {
    updatedFieldsList[id] = updateItemWithConfig({ role, item: field });
  });

  const updatedTemplate = {
    ...template,
    body: {
      ...body,
      nodes: {
        ...updatedNodes,
      },
      field_list: {
        ...updatedFieldsList,
      },
    },
  };

  return updatedTemplate;
};

export const updateTemplate = ({
  t,
  template,
  guidingQuestions,
  projectFieldData,
  fieldsToRemove = [],
  childIdsToRemove, //if u want to remove child from node, u can pass just childIds which u want to remove from node and it will be removed from all the node which contain the given childIds.
  removeFieldsWithValue, //should contain the field name with its value used when we want to remove fields on array based condition,
  role,
}) => {
  //Updating template nodes and fields based on role
  if (!_.isEmpty(role)) {
    template = updateTemplateWithRoleConfig({ template, role });
  }

  //condition to add and remove the dependent fields
  if (!_.isEmpty(projectFieldData)) {
    template = updateTemplateFieldsWithDependency({
      template,
      projectFieldData,
    });
  }

  //handled for service as action guiding question where template will be updated according to the guiding questions available
  if (_.size(guidingQuestions) > 0) {
    template = updateGuidingQuestionsInTemplate({
      template,
      guidingQuestions,
    });
  }

  //remove the fields with array size 1
  if (removeFieldsWithValue) {
    fieldsToRemove = [
      ...fieldsToRemove,
      ...getFieldsToRemove({ removeFieldsWithValue }),
    ];
  }

  //remove the given fields from the template
  if (fieldsToRemove) {
    const {
      body: { nodes },
    } = template;
    const nodesWithFieldsToRemove = getNodeWithFieldsToRemove({
      fieldsToRemove,
      nodes,
    });
    template = getTemplateWithRemovedFields({
      template,
      nodesWithFieldsToRemove,
    });
  }

  // removing childIds from node
  if (!_.isEmpty(childIdsToRemove)) {
    template = getTemplateWithRemovedChildIds({
      template,
      childIdsToRemove,
    });
  }

  return getLocalisedTemplate({ template, t });
};

export const getLocalisedTemplate = ({ template, t }) => {
  const isLocalisedTemplate = _.get(
    template,
    "body.isLocalisedTemplate",
    false
  );
  const body = _.get(template, "body", {});
  const localisedTemplateId = _.get(
    template,
    "body.localisedTemplateId",
    false
  );
  const localisationKeys = [
    "label",
    "viewLabel",
    "subLabel",
    "labelForStudent",
    "labelForParent",
    "subtext",
    "prompts",
    "responseHeaderText",
    "placeholder",
    "emptyText",
    "blockSubText",
    "subText",
    "toggleSubText",
    "toggleLabel",
    "modalSearchPlaceholder",
    "modalTitleLabel",
    "modalTitleEditLabel",
    "editLabel",
    "buttonLabel",
    "templateSubText",
  ];
  const localisationLists = ["field_list", "nodes"];
  if (isLocalisedTemplate) {
    _.forEach(body, (list, key) => {
      const keyType = key == "field_list" ? "field" : "node";
      if (_.includes(localisationLists, key)) {
        _.forEach(list, (item, itemKey) => {
          if (key != "field_list") {
            body[key][itemKey] = getLocalisedItem({
              item,
              t,
              keyType,
              localisedTemplateId,
              itemKey,
              localisationKeys,
            });
          }
          if (!item.skipLocalisation) {
            body[key][itemKey] = {
              ...body[key][itemKey],
              config: getLocalisedConfig({
                config: _.get(item, "config", {}),
                t,
                keyType,
                localisedTemplateId,
                itemKey,
                localisationKeys,
              }),
            };
            if (_.has(item, "subFields")) {
              body[key][itemKey] = {
                ...body[key][itemKey],
                subFields: getLocalisedSubFields({
                  subFields: _.get(item, "subFields", {}),
                  t,
                  keyType,
                  localisedTemplateId,
                  itemKey,
                  localisationKeys,
                }),
              };
            }
          }
        });
      }
    });
  }
  return template;
};

export const getLocalisedSubFields = ({
  subFields,
  t,
  keyType,
  localisedTemplateId,
  localisationKeys,
}) => {
  return _.map(subFields, subField => {
    return {
      ...subField,
      config: getLocalisedConfig({
        config: _.get(subField, "config", {}),
        t,
        keyType,
        localisedTemplateId,
        itemKey: _.get(subField, "key", {}),
        localisationKeys,
      }),
    };
  });
};

export const getLocalisedConfig = ({
  config,
  t,
  keyType,
  localisedTemplateId,
  itemKey,
  localisationKeys,
}) => {
  let fieldConfig = config;
  _.forEach(config, (value, configKey) => {
    if (_.includes(localisationKeys, configKey)) {
      //Un comment if you want locale key and value in consol
      // console.log(
      //   `"${extractLabelsForTranslation({
      //     keys: [localisedTemplateId, keyType, itemKey, configKey],
      //   })}": "${value}",`
      // );
      fieldConfig = {
        ...fieldConfig,
        [configKey]:
          value &&
          t(
            `projectTemplate:${extractLabelsForTranslation({
              keys: [localisedTemplateId, keyType, itemKey, configKey],
            })}`
          ),
      };
    }
    if (configKey == "columns") {
      _.forEach(value, (columnValue, columnKey) => {
        fieldConfig.columns[columnKey] = getLocalisedItem({
          item: columnValue,
          t,
          keyType,
          localisedTemplateId,
          itemKey,
          extraKey: columnKey,
          localisationKeys,
        });
      });
    }
    if (configKey == "fieldTypeConfig") {
      fieldConfig = {
        ...fieldConfig,
        fieldTypeConfig: getLocalisedConfig({
          config: _.get(config, "fieldTypeConfig", {}),
          t,
          keyType,
          localisedTemplateId,
          itemKey,
          localisationKeys,
        }),
      };
    }
  });
  return fieldConfig;
};

export const getLocalisedItem = ({
  item,
  t,
  keyType,
  localisedTemplateId,
  itemKey,
  extraKey = "",
  localisationKeys,
}) => {
  let step = item;
  _.forEach(item, (field, fieldKey) => {
    if (!item.skipLocalisation && _.includes(localisationKeys, fieldKey)) {
      //Un comment if you want locale key and value in consol
      // console.log(
      //   `"${extractLabelsForTranslation({
      //     keys: [localisedTemplateId, keyType, itemKey, fieldKey],
      //   })}
      //   ": "${field}",`
      // );
      step = {
        ...step,
        [fieldKey]:
          field &&
          t(
            `projectTemplate:${extractLabelsForTranslation({
              keys: [localisedTemplateId, keyType, itemKey, extraKey, fieldKey],
            })}`
          ),
      };
    }
  });
  return step;
};

export const deleteDeadlineItem = ({
  projectGroupId,
  deadlineItem,
  fetchStatusWiseProjects = false,
}) => async dispatch => {
  const projectGroupDeadlineData = getProjectGroupDeadlinesFromCache({
    projectGroupId,
    fetchStatusWiseProjects,
  });
  writeProjectGroupDeadlinesInCache({
    projectGroupId,
    fetchStatusWiseProjects,
    data: {
      node: {
        ...projectGroupDeadlineData.node,
        deadlines: _filter(
          _get(projectGroupDeadlineData, "node.deadlines", []),
          deadline => deadline.id !== deadlineItem.id
        ),
      },
    },
  });
  const [error] = await asyncWrap(
    client.mutate({
      mutation: deleteProjectDeadlineMutation,
      variables: {
        input: {
          id: deadlineItem.id,
        },
      },
    })
  );
  if (error) {
    writeProjectGroupDeadlinesInCache({
      projectGroupId,
      fetchStatusWiseProjects,
      data: {
        node: {
          ...projectGroupDeadlineData.node,
          deadlines: _get(projectGroupDeadlineData, "node.deadlines", []),
        },
      },
    });
    dispatch(setToastMsg("toastMsgs:something_went_wrong"));
  }
  return { error };
};

export const createDeadlineItem = ({
  deadlineItem,
  projectGroupId,
  showSuccessToast = true,
  fetchStatusWiseProjects = false,
}) => async dispatch => {
  const projectGroupDeadlineData = getProjectGroupDeadlinesFromCache({
    projectGroupId,
    fetchStatusWiseProjects,
  });
  const { title, deadlineDate } = deadlineItem;
  const [error] = await asyncWrap(
    client.mutate({
      mutation: createProjectDeadlineMutation,
      variables: {
        input: {
          title,
          deadlineDate,
          projectGroupId,
        },
        fetchStatusWiseProjects,
      },
      update: (cache, { data }) => {
        writeProjectGroupDeadlinesInCache({
          projectGroupId,
          fetchStatusWiseProjects,
          data: {
            node: {
              ...projectGroupDeadlineData.node,
              deadlines: _concat(
                _get(projectGroupDeadlineData, "node.deadlines", []),
                _get(data, "documentation.createProjectGroupDeadline")
              ),
            },
          },
        });
      },
    })
  );
  if (error) {
    dispatch(setToastMsg("toastMsgs:something_went_wrong"));
  } else if (showSuccessToast) {
    dispatch(
      setToastMsg({
        type: "success",
        msg: "toastMsgs:successfully_with_label",
        locale_params: [
          { key: "label", value: "toastMsgs:added", isPlainText: false },
        ],
      })
    );
  }
  return { error };
};

export const updateDeadlineItem = ({
  deadlineItem,
  fetchStatusWiseProjects = false,
}) => async dispatch => {
  const { id, title, deadlineDate } = deadlineItem;
  const [error] = await asyncWrap(
    client.mutate({
      mutation: updateProjectDeadlineMutation,
      variables: {
        input: {
          id,
          title,
          deadlineDate,
        },
        fetchStatusWiseProjects,
      },
      update: (cache, result) => {
        writeProjectGroupDeadlineInCache({
          projectDeadlineId: id,
          data: deadlineItem,
        });
      },
    })
  );

  if (error) {
    dispatch(setToastMsg("toastMsgs:something_went_wrong"));
  }
  return { error };
};

export const reorderProjectDeadlineItem = ({
  fromIndex,
  toIndex,
  id,
  putAfterItemId,
  projectGroupId,
  fetchStatusWiseProjects = false,
}) => async dispatch => {
  const deadlineData = getProjectGroupDeadlinesFromCache({
    projectGroupId,
    fetchStatusWiseProjects,
  });
  const deadlineList = _.get(deadlineData, "node.deadlines", []);

  const updatedDeadlineList = moveElementInArray({
    array: deadlineList,
    fromIndex,
    toIndex,
  });

  writeProjectGroupDeadlinesInCache({
    projectGroupId,
    fetchStatusWiseProjects,
    data: {
      node: {
        ...deadlineData.node,
        deadlines: updatedDeadlineList,
      },
    },
  });
  const [error] = await asyncWrap(
    client.mutate({
      mutation: reorderProjectDeadlineMutation,
      variables: {
        input: {
          id,
          putAfterItemId,
        },
      },
    })
  );
  if (error) {
    writeProjectGroupDeadlinesInCache({
      projectGroupId,
      fetchStatusWiseProjects,
      data: {
        node: {
          ...deadlineData.node,
          deadlines: deadlineList,
        },
      },
    });
    dispatch(setToastMsg("toastMsgs:something_went_wrong"));
  }
  return { error };
};

export const createProjectTemplateCopy = async ({
  projectId,
  projectGroupReportTemplateId,
  projectGroupId,
}) => {
  const [error, result] = await asyncWrap(
    client.mutate({
      mutation: createProjectTemplateCopyMutation,
      variables: {
        input: {
          projectId,
          projectGroupReportTemplateId,
          projectGroupId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getProjectReportsQuery,
          variables: {
            id: projectId,
          },
        },
      ],
    })
  );
  return {
    error,
    attachment: result?.data?.documentation?.createProjectTemplateCopy,
  };
};
//For re-ordering lists
export const getDragEndValues = ({
  droppableId,
  draggableId,
  items,
  params,
}) => {
  const _droppableId = _.get(params, "destination.droppableId", "");
  const destinationIndex = _.get(params, "destination.index", null);
  const _draggableId = _.split(_.get(params, "draggableId", ""), ":");
  const toIndex = _.get(params, "destination.index", null);
  const fromIndex = _.get(params, "source.index", null);

  if (_droppableId == droppableId && _draggableId[0] == draggableId) {
    const putAfterItemIndex =
      toIndex <= fromIndex ? destinationIndex - 1 : destinationIndex;
    const putAfterItemId = _.get(items, `${[putAfterItemIndex]}.id`, null);

    const id = _draggableId[1];
    return {
      fromIndex,
      toIndex,
      id,
      putAfterItemId,
    };
  }
};

export const updateStudentProjectPortfolio = ({ status, id, isCompleted }) => {
  return async (dispatch, getState) => {
    const cachedData = getStudentProjectPortfolioProgressFragmentInCache({
      id,
    });
    writeStudentProjectPortfolioProgressFragmentInCache({
      id,
      data: {
        ...cachedData,
        ...(!!status && { status }),
        ...(!_.isUndefined(isCompleted) && { isComplete: isCompleted }),
      },
    });
    try {
      return await client.mutate({
        mutation: updateStudentPortfolioStatusMutation,
        variables: {
          id,
          ...(!!status && { status }),
          ...(!_.isUndefined(isCompleted) && { isComplete: isCompleted }),
        },
      });
    } catch (e) {
      writeStudentProjectPortfolioProgressFragmentInCache({
        id,
        data: cachedData,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

const getStrandCount = ({
  addedStrandId,
  removedStrandId,
  nodes,
  creativityCount,
  activityCount,
  serviceCount,
}) => {
  if (!_.isEmpty(removedStrandId)) {
    const removedNode = _.find(nodes, { id: removedStrandId[0] });
    switch (_.get(removedNode, "metadata.subType", "")) {
      case "ACTIVITY":
        return {
          creativityCount: creativityCount,
          activityCount: activityCount - 1,
          serviceCount: serviceCount,
        };
      case "CREATIVITY":
        return {
          creativityCount: creativityCount - 1,
          activityCount: activityCount,
          serviceCount: serviceCount,
        };
      case "SERVICE":
        return {
          creativityCount: creativityCount,
          activityCount: activityCount,
          serviceCount: serviceCount - 1,
        };
    }
  }
  if (!_.isEmpty(addedStrandId)) {
    const addedNode = _.find(nodes, { id: addedStrandId[0] });
    switch (_.get(addedNode, "metadata.subType", "")) {
      case "ACTIVITY":
        return {
          creativityCount: creativityCount,
          activityCount: activityCount + 1,
          serviceCount: serviceCount,
        };
      case "CREATIVITY":
        return {
          creativityCount: creativityCount + 1,
          activityCount: activityCount,
          serviceCount: serviceCount,
        };
      case "SERVICE":
        return {
          creativityCount: creativityCount,
          activityCount: activityCount,
          serviceCount: serviceCount + 1,
        };
    }
  }
};

export const updateProjectFieldInCache = ({
  fieldUID,
  params,
  fieldObj,
  extraParams,
  fieldTemplateObj,
  plannerElementSets,
  portfolioId,
}) => {
  return (dispatch, getState) => {
    const data = {
      ...fieldObj,
      value: params.value,
      attachments: _.includes(
        ["SubmissionItem", "ReportItem"],
        fieldTemplateObj?.type
      )
        ? params?.value
        : [],
      resolvedMinimalTree: getResolvedOptimisticValue({
        resolvedValue: _.get(fieldObj, "resolvedMinimalTree", {}),
        fieldUID,
        fieldTemplateObj,
        params: extraParams,
        value: _.get(params, "value", ""),
      }),
    };
    if (fieldUID == "CASStrands") {
      const addedStrandId = _.difference(params.value, fieldObj.value);
      const removedStrandId = _.difference(fieldObj.value, params.value);

      const portfolioCachedData = getStudentProjectPortfolioProgressFragmentInCache(
        { id: portfolioId }
      );
      const strandCount = getStrandCount({
        addedStrandId,
        removedStrandId,
        nodes: _.get(
          _.find(plannerElementSets, { type: "DP_CAS_STRANDS" }),
          "nodes",
          []
        ),
        creativityCount: _.get(
          portfolioCachedData,
          "strandCounts.creativityCount"
        ),
        activityCount: _.get(portfolioCachedData, "strandCounts.activityCount"),
        serviceCount: _.get(portfolioCachedData, "strandCounts.serviceCount"),
      });
      writeStudentProjectPortfolioProgressFragmentInCache({
        id: portfolioId,
        data: update(portfolioCachedData, {
          strandCounts: {
            $set: {
              ...strandCount,
              __typename: "ProjectPortfolioStrandsCount",
            },
          },
        }),
      });
    }
    client.writeFragment({
      id: `PlannerField:${params.id}`,
      fragment: plannerFieldFragment.projectFieldItem,
      fragmentName: "projectFieldItem",
      data: data,
    });
  };
};

export const updateProjectField = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateProjectFieldMutation,
        variables: params,
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const markProjectDeadlinesComplete = ({
  projectId,
  addedArray,
  removedArray,
}) => {
  return async (dispatch, getState) => {
    const projectInfo = getProjectInfoFromCache({ projectId });
    //removing from Completed List
    const updatedCompletedDeadlines = _.filter(
      projectInfo.node.completedDeadlines,
      completedDeadline =>
        !_.find(
          removedArray,
          itemId => itemId === completedDeadline.deadline.id
        )
    );
    //adding in completed list
    if (addedArray.length > 0) {
      _.forEach(addedArray, itemId => {
        updatedCompletedDeadlines.push({
          deadline: {
            id: itemId,
            __typename: "ProjectGroupDeadline",
          },
          __typename: "CompletedDeadlines",
        });
      });
    }

    writeProjectInfoInCache({
      projectId,
      data: {
        ...projectInfo.node,
        completedDeadlines: updatedCompletedDeadlines,
      },
    });

    try {
      await client.mutate({
        mutation: markProjectDeadlinesCompleteMutation,
        variables: {
          projectId,
          addedArray,
          removedArray,
        },
      });
    } catch (e) {
      writeProjectInfoInCache({
        projectId,
        data: projectInfo.node,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const updateProject = ({
  projectId,
  statusToUpdate,
  projectGroupType,
  projectGroupId,
  currentStatus,
  statusMessage,
  useMutationWithoutFields = false,
}) => {
  return async (dispatch, getState) => {
    const projectInfo = getProjectInfoFromCache({ projectId });
    writeProjectInfoInCache({
      projectId,
      data: {
        ...projectInfo.node,
        ...statusToUpdate,
      },
    });

    try {
      await client.mutate({
        mutation: useMutationWithoutFields
          ? updateProjectWithoutResponseFieldsMutation
          : updateProjectMutation,
        variables: {
          projectId,
          statusMessage,
          ...statusToUpdate,
        },
        update: () => {
          switch (projectGroupType) {
            case PROJECT_GROUP_TYPE_DP_CAS: {
              const { status } = statusToUpdate;
              if (
                !(
                  currentStatus === status &&
                  status === PROJECT_STATUS_PENDING_FOR_APPROVAL
                )
              ) {
                const data = getProjectGroupProjectCountFromCache({
                  id: projectGroupId,
                  filters: { status: PROJECT_STATUS_PENDING_FOR_APPROVAL },
                });
                let newCount = null;
                if (status === PROJECT_STATUS_PENDING_FOR_APPROVAL)
                  newCount = _.get(data, "node.projects.totalCount", null) + 1;
                else
                  newCount = _.get(data, "node.projects.totalCount", null) - 1;
                if (newCount >= 0)
                  writeProjectGroupProjectCountInCache({
                    id: projectGroupId,
                    filters: { status: PROJECT_STATUS_PENDING_FOR_APPROVAL },
                    data: update(data, {
                      node: {
                        projects: {
                          totalCount: { $set: newCount },
                        },
                      },
                    }),
                  });
              }
              break;
            }
            default:
              return null;
          }
        },
      });
    } catch (error) {
      writeProjectInfoInCache({
        projectId,
        data: projectInfo.node,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const updateProjectDetails = ({
  projectId,
  projectType,
  isCompleted,
}) => {
  return async (dispatch, getState) => {
    const projectDetails = getProjectDetailsFromCache({ projectId });
    writeProjectDetailsInCache({
      projectId,
      data: {
        node: {
          ...projectDetails.node,
          ...(!!projectType && { type: projectType }),
          ...(!_.isUndefined(isCompleted) && { isCompleted }),
        },
      },
    });

    try {
      await client.mutate({
        mutation: updateProjectMutation,
        variables: {
          projectId,
          ...(!!projectType && { type: projectType }),
          ...(!_.isUndefined(isCompleted) && { isCompleted }),
        },
      });
    } catch (error) {
      writeProjectDetailsInCache({
        projectId,
        data: projectDetails.node,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const createAttachment = ({
  name,
  url,
  thumbUrl,
  mimeType,
  type,
  parentId,
  parentType,
  metadata,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    try {
      return await client.mutate({
        mutation: createAttachmentMutation,
        variables: {
          name,
          url,
          thumbUrl,
          mimeType,
          type,
          parentId,
          createdBy: userId,
          parentType,
          metadata,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getProjectReportTemplateDetailsQuery,
            variables: {
              id: parentId,
            },
          },
        ],
      });
    } catch (error) {
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const createRubricAssessmentCriteriaRating = ({
  parentType,
  parentId,
  ibPYPElementType,
  ibPYPElementId,
  value,
  studentCourseFilters,
}) => {
  return async (dispatch, getState) => {
    const createdBy = getState().login.userInfo.id;
    const { node: { students = [] } = {} } = getProjectDetailsFromCache({
      projectId: parentId,
    });
    const data = getProjectCriteriaDetailsFromCache({
      id: parentId,
      studentCourseFilters,
    });

    const student = _.first(students);
    const index = _.findIndex(data?.ibPYPElementRatings, { ibPYPElementId });
    let updatedData = {};

    if (!_.isEqual(index, -1)) {
      updatedData = update(data, {
        ibPYPElementRatings: {
          [index]: {
            value: { $set: value },
          },
        },
      });
    } else {
      const newIbPYPElementType = {
        value,
        id: generateRandomId(),
        ibPYPElementId,
        // parentType,
        // parentId,
        // ibPYPElementType,
        academicCriteriaSet: null,
        academicCriteriaValue: null,
        __typename: "IBPYPElementRating",
      };

      updatedData = update(data, {
        ibPYPElementRatings: {
          $push: [newIbPYPElementType],
        },
      });
    }

    writeProjectRatedCriteriaInCache({
      id: parentId,
      data: updatedData,
    });

    try {
      await client.mutate({
        mutation: PlannerCreateIBPYPElementRatingsMutation,
        variables: {
          input: {
            studentId: student?.id,
            parentType,
            parentId,
            ibPYPElementType,
            ibPYPElementId,
            createdBy,
            value,
          },
        },
      });
    } catch (e) {
      writeProjectRatedCriteriaInCache({
        id: parentId,
        data,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const deleteRubricAssessmentCriteriaRating = ({
  parentType,
  parentId,
  ibPYPElementType,
  ibPYPElementId,
  studentCourseFilters,
}) => {
  return async dispatch => {
    const { node: { students = [] } = {} } = getProjectDetailsFromCache({
      projectId: parentId,
    });
    const student = _.first(students);

    const data = getProjectCriteriaDetailsFromCache({
      id: parentId,
      studentCourseFilters,
    });
    const index = _.findIndex(data?.ibPYPElementRatings, { ibPYPElementId });

    writeProjectRatedCriteriaInCache({
      id: parentId,
      data: update(data, {
        ibPYPElementRatings: { $splice: [[index, 1]] },
      }),
    });

    try {
      await client.mutate({
        mutation: PlannerDeleteIBPYPElementRatingMutation,
        variables: {
          input: {
            studentId: student?.id,
            parentType,
            parentId,
            ibPYPElementType,
            ibPYPElementId,
          },
        },
      });
    } catch (e) {
      writeProjectRatedCriteriaInCache({
        id: parentId,
        data,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const getProjectGroupTypeFromId = ({ state, projectGroupId }) => {
  const {
    login: {
      userInfo: {
        communityProjectId,
        personalProjectId,
        serviceAsActionId,
        projectGroups,
      },
    },
  } = state;
  const projectGroupIdsMap = new Map([
    [communityProjectId, PROJECT_GROUP_TYPE_COMMUNITY],
    [personalProjectId, PROJECT_GROUP_TYPE_PERSONAL],
    [serviceAsActionId, PROJECT_GROUP_TYPE_SERVICE_AS_ACTON],
  ]);

  if (!_.isEmpty(projectGroups)) {
    _.forEach(projectGroups, projectGroup => {
      projectGroupIdsMap.set(projectGroup.id, projectGroup.type);
    });
  }

  if (projectGroupIdsMap.has(String(projectGroupId))) {
    return projectGroupIdsMap.get(String(projectGroupId));
  }

  throw new Error("project group id not found");
};

export const createProject = ({
  projectGroupId,
  supervisorId,
  studentIds,
  filters,
  extraParams: { type, queryVariables },
  fields,
  grades,
  userType,
  studentCollaborators,
  type: projectType,
  portfolioId,
  curriculumProgramIds,
  roleType,
}) => {
  return async (dispatch, getState) => {
    const refetchQueries = [];
    switch (type) {
      case "invite_project_admin":
        refetchQueries.push({
          query: getProjectGroupProjectsQuery,
          variables: {
            projectGroupId,
            filters,
          },
        });
        refetchQueries.push({
          query: getProjectGroupProjectsCountQuery,
          variables: {
            projectGroupId,
          },
        });

        break;
      case "student_progress_admin":
        refetchQueries.push({
          query: getGroupedProjectsQuery,
          variables: {
            projectGroupId,
            filters,
          },
        });
        break;
      case "student_progress_supervisor":
        refetchQueries.push({
          query: getSupervisorProjectQuery,
          variables: {
            projectGroupIds: [projectGroupId],
            staffId: supervisorId,
            searchText: "",
          },
        });
        break;
      case "student_portfolio_project":
        refetchQueries.push({
          query: getStudentProjectPortfolioQuery,
          variables: {
            id: portfolioId,
            ...projectGroupPortfolioStudentViewFilters,
          },
        });
        break;
      case "community_dashboard":
        refetchQueries.push({
          query: getProjectGroupProjectsWithFieldsQuery,
          variables: queryVariables.getProjectGroupProjectsWithFieldsQueryVars,
        });
        break;
      case "saa_project":
        refetchQueries.push({
          query: getGradeWiseProjectCountQuery,
          variables: {
            id: getState().login.userInfo.id,
            type: roleType,
            filters,
            allGradesFilters: { curriculumProgramIds },
          },
        });
        break;
    }

    let statusObj = {};
    const projectGroupType = getProjectGroupTypeFromId({
      projectGroupId,
      state: getState(),
    });
    if (userType === ROLE_STUDENT) {
      statusObj = {
        status: PROJECT_STATUS_PENDING_FOR_APPROVAL,
      };
    } else {
      switch (projectGroupType) {
        case PROJECT_GROUP_TYPE_DP_CAS:
          // statusObj = {
          //   status: PROJECT_STATUS_APPROVED,
          // };
          break;
        default:
          statusObj = {
            status: PROJECT_STATUS_IN_PROGRESS,
          };
          break;
      }
    }

    let variables = {};
    switch (projectGroupType) {
      case PROJECT_GROUP_TYPE_DP_CAS:
        variables = {
          projectGroupId,
          fields,
          studentCollaborators,
          type: projectType,
          ...statusObj,
        };
        break;
      default:
        variables = {
          projectGroupId,
          supervisorId,
          studentIds,
          fields,
          grades,
          ...statusObj,
        };
        break;
    }

    try {
      return await client.mutate({
        mutation: createProjectMutation,
        variables,
        awaitRefetchQueries: true,
        refetchQueries,
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const getProjectFields = ({ projectData }) => {
  return _.map(_.keys(projectData), key => {
    return {
      uid: key,
      value: projectData[key].value,
    };
  });
};

//need to change the key names i am not getting the idea what should be the name
export const getObjectIdsByDifference = ({
  minuendObjectList,
  subtrahendObjectList,
}) => {
  return _.map(
    _.differenceBy(minuendObjectList, subtrahendObjectList, "id"),
    object => object.id
  );
};

export const editProject = ({
  projectId,
  projectGroupId,
  filters,
  extraParams: { type, supervisorId, queryVariables },
  previousStudents,
  updatedStudents,
  previousSupervisor,
  updatedSupervisor,
  previousStudentCollaborators,
  updatedStudentCollaborators,
}) => {
  let addedStudents = [],
    removedStudents = [],
    addedSupervisors = [],
    removedSupervisors = [],
    addedStudentCollaborators = [],
    removedStudentCollaborators = [];

  if (!_.isEmpty(updatedStudents)) {
    addedStudents = getObjectIdsByDifference({
      minuendObjectList: updatedStudents,
      subtrahendObjectList: previousStudents,
    });
    removedStudents = getObjectIdsByDifference({
      minuendObjectList: previousStudents,
      subtrahendObjectList: updatedStudents,
    });
  }
  if (!_.isEmpty(updatedSupervisor)) {
    if (updatedSupervisor != previousSupervisor) {
      addedSupervisors = [updatedSupervisor];
      if (previousSupervisor) removedSupervisors = [previousSupervisor];
    }
  }

  if (
    !_.isEmpty(previousStudentCollaborators) ||
    !_.isEmpty(updatedStudentCollaborators)
  ) {
    addedStudentCollaborators = getObjectIdsByDifference({
      minuendObjectList: updatedStudentCollaborators,
      subtrahendObjectList: previousStudentCollaborators,
    });
    removedStudentCollaborators = getObjectIdsByDifference({
      minuendObjectList: previousStudentCollaborators,
      subtrahendObjectList: updatedStudentCollaborators,
    });
  }
  const refetchQueries = [];
  switch (type) {
    case "invite_project_admin":
      refetchQueries.push({
        query: getProjectGroupProjectsQuery,
        variables: {
          projectGroupId,
          filters,
        },
      });
      break;
    case "student_progress_admin":
      refetchQueries.push({
        query: getGroupedProjectsQuery,
        variables: {
          projectGroupId,
          filters,
        },
      });
      break;
    case "student_progress_supervisor":
      refetchQueries.push({
        query: getSupervisorProjectQuery,
        variables: {
          projectGroupIds: [projectGroupId],
          staffId: supervisorId,
          searchText: "",
        },
      });
      break;
    case "SaA_student_progress_admin":
      refetchQueries.push(
        {
          query: getCoordinatorProjectsQuery,
          variables: {
            projectGroupId,
            filters,
          },
        },
        {
          query: getProjectGroupSupervisorsQuery,
          variables: queryVariables.projectGroupSupervisorsQueryVars,
        },
        {
          query: getProjectGroupStudentsQuery,
          variables: queryVariables.projectGroupStudentsQueryVars,
        }
      );
      break;
    case "SaA_student_progress_supervisor_student":
      refetchQueries.push({
        query: getSupervisorStudentProjectsQuery,
        variables: queryVariables.supervisorStudentProjectsQueryVars,
      });
      break;
    case "project_dashboard_edit_collaborators":
      refetchQueries.push({
        query: getProjectInfoQuery,
        variables: queryVariables.projectInfoQueryVar,
      });
      break;
    case "saa_dashboard":
      refetchQueries.push(
        {
          query: getProjectGroupProjectsWithFieldsQuery,
          variables: queryVariables.getProjectGroupProjectsWithFieldsQueryVars,
        },
        {
          query: getGradeWiseProjectCountQuery,
          variables: queryVariables.gradeWiseProjectCountQueryVars,
        }
      );
      break;
    case "community_dashboard":
      refetchQueries.push({
        query: getProjectGroupProjectsWithFieldsQuery,
        variables: queryVariables.getProjectGroupProjectsWithFieldsQueryVars,
      });
      break;
  }
  return async dispatch => {
    try {
      await client.mutate({
        mutation: editProjectMutation,
        variables: {
          projectId,
          addedSupervisors,
          removedSupervisors,
          addedStudents,
          removedStudents,
          addedStudentCollaborators,
          removedStudentCollaborators,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const removeProjects = ({
  projectIds,
  projectGroupId,
  filters = {},
  staffId = "",
  searchText = "",
  extraParams: { type, queryVariables },
}) => {
  const refetchQueries = [];
  switch (type) {
    case "invite_project_admin":
      refetchQueries.push({
        query: getProjectGroupProjectsQuery,
        variables: {
          projectGroupId,
          filters,
        },
      });
      refetchQueries.push({
        query: getProjectGroupProjectsCountQuery,
        variables: {
          projectGroupId,
        },
      });
      break;
    case "student_progress_admin":
      refetchQueries.push({
        query: getGroupedProjectsQuery,
        variables: {
          projectGroupId,
          filters,
        },
      });
      break;
    case "student_progress_supervisor":
      refetchQueries.push({
        query: getSupervisorProjectQuery,
        variables: {
          projectGroupIds: [projectGroupId],
          staffId,
          searchText,
        },
      });
      break;
    case "SaA_student_progress_admin":
      refetchQueries.push(
        {
          query: getCoordinatorProjectsQuery,
          variables: {
            projectGroupId,
            filters,
          },
        },
        {
          query: getGradeWiseProjectCountQuery,
          variables: queryVariables.gradeWiseProjectCountQueryVars,
        },
        {
          query: getProjectGroupSupervisorsQuery,
          variables: queryVariables.projectGroupSupervisorsQueryVars,
        },
        {
          query: getProjectGroupStudentsQuery,
          variables: queryVariables.projectGroupStudentsQueryVars,
        }
      );
      break;
    case "SaA_student_progress_supervisor_student":
      refetchQueries.push(
        {
          query: getGradeWiseProjectCountQuery,
          variables: queryVariables.gradeWiseProjectCountQueryVars,
        },
        {
          query: getSupervisorStudentProjectsQuery,
          variables: queryVariables.supervisorStudentProjectsQueryVars,
        }
      );
      break;
    case "cas_experience_and_projects":
    case "cas_experience_and_projects_students_screen":
      refetchQueries.push({
        query: getStudentProjectPortfolioQuery,
        variables:
          queryVariables.getProjectGroupStudentProjectPortfolioQueryVars,
      });
      break;
    case "saa_dashboard":
      refetchQueries.push(
        {
          query: getProjectGroupProjectsWithFieldsQuery,
          variables: queryVariables.getProjectGroupProjectsWithFieldsQueryVars,
        },
        {
          query: getGradeWiseProjectCountQuery,
          variables: queryVariables.gradeWiseProjectCountQueryVars,
        }
      );
      break;
    case "community_dashboard":
      refetchQueries.push({
        query: getProjectGroupProjectsWithFieldsQuery,
        variables: queryVariables.getProjectGroupProjectsWithFieldsQueryVars,
      });
      break;
    case "cas_project_dashboard":
      refetchQueries.push(
        {
          query: getProjectGroupProjectsWithFieldsQuery,
          variables: queryVariables.getProjectGroupProjectsWithFieldsQueryVars,
        },
        {
          query: getStudentProjectPortfolioQuery,
          variables:
            queryVariables.getProjectGroupStudentProjectPortfolioQueryVars,
        }
      );
      break;
  }
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: removeProjectsMutation,
        variables: {
          projectIds,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw e;
    }
  };
};

export const ProjectPlannerContext = createContext({});

export const createProjectGroupGuidingQuestion = ({
  question,
  projectGroupId,
  grades,
}) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;
    const curriculumPrograms = getState().platform
      .organizationCurriculumPrograms;
    const curriculumProgramIds = _.get(
      _.find(curriculumPrograms, { type: "IB_MYP" }),
      "id",
      null
    );
    try {
      await client.mutate({
        mutation: createProjectGroupGuidingQuestionMutation,
        variables: {
          question,
          projectGroupId,
          grades: _.isEmpty(grades) ? null : grades,
        },
        update: (cache, { data }) => {
          const cachedData = getGuidingQuestionsFromCache({ projectGroupId });
          const gradesArray = _.get(
            getOrganizationGradesFromCache({
              id: organizationId,
              filters: {
                curriculumProgramIds,
              },
            }),
            "grades",
            []
          );
          const guidingQuestionId = _.get(
            data,
            "documentation.createProjectGroupGuidingQuestion.id",
            ""
          );
          const selectedGrades = _.filter(gradesArray, grade =>
            _.includes(grades, grade.id)
          );
          writeGuidingQuestionsInCache({
            variables: { projectGroupId },
            data: update(cachedData, {
              node: {
                guidingQuestions: {
                  $push: [
                    {
                      question,
                      grades: selectedGrades,
                      id: guidingQuestionId,
                      __typename: "ProjectGroupGuidingQuestion",
                    },
                  ],
                },
              },
            }),
          });
        },
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const deleteProjectGroupGuidingQuestion = ({ id, projectGroupId }) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: deleteProjectGroupGuidingQuestionMutation,
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getGuidingQuestionsQuery,
            variables: { projectGroupId },
          },
        ],
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const updateProjectGroupGuidingQuestion = ({ id, question, grades }) => {
  return async dispatch => {
    const cachedData = getGuidingQuestionFragmentFromCache({ id });
    try {
      writeGuidingQuestionFragmentInCache({
        id,
        data: update(cachedData, {
          question: { $set: question },
          grades: {
            $set: grades,
          },
        }),
      });
      const gradeAdded = _.map(
        _.differenceBy(grades, cachedData.grades, "id"),
        item => item.id
      );

      const gradeRemoved = _.map(
        _.differenceBy(cachedData.grades, grades, "id"),
        item => item.id
      );

      await client.mutate({
        mutation: updateProjectGroupGuidingQuestionMutaion,
        variables: {
          id,
          question,
          gradeAdded,
          gradeRemoved,
        },
      });
    } catch (e) {
      writeGuidingQuestionFragmentInCache({ id, data: cachedData });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const reorderProjectGroupGuidingQuestion = ({
  fromIndex,
  toIndex,
  id,
  putAfterItemId,
  projectGroupId,
}) => async dispatch => {
  const cachedData = getGuidingQuestionsFromCache({ projectGroupId });
  const guidingQuestions = _.get(cachedData, "node.guidingQuestions", []);

  const updatedGuidingQuestions = moveElementInArray({
    array: guidingQuestions,
    fromIndex,
    toIndex,
  });

  writeGuidingQuestionsInCache({
    variables: { projectGroupId },
    data: update(cachedData, {
      node: {
        guidingQuestions: { $set: updatedGuidingQuestions },
      },
    }),
  });
  try {
    await client.mutate({
      mutation: reorderProjectGroupGuidingQuestionMutation,
      variables: {
        id,
        putAfterItemId,
      },
    });
  } catch (e) {
    writeGuidingQuestionsInCache({
      variables: { projectGroupId },
      data: cachedData,
    });
    dispatch(setToastMsg("toastMsgs:something_went_wrong"));
  }
};

export const updateGuidingQuestionInCache = ({
  id,
  changedData,
  changedKey,
}) => {
  const cachedData = getGuidingQuestionFragmentFromCache({ id });
  writeGuidingQuestionFragmentInCache({
    id,
    data: update(cachedData, {
      [changedKey]: { $set: changedData },
    }),
  });
};

export const updateCreateProjectDataInRedux = data => {
  return {
    type: UPDATE_CREATE_PROJECT_DATA_IN_REDUX,
    data,
  };
};

export const updatePortfolioStudentDetailsInRedux = data => {
  return {
    type: UPDATE_PORTFOLIO_STUDENT_IN_REDUX,
    data,
  };
};

export const resetCreateProjectDataInRedux = data => {
  return {
    type: RESET_CREATE_PROJECT_DATA_IN_REDUX,
    data,
  };
};

export const updateCurrentGrade = data => {
  return {
    type: UPDATE_CURRENT_GRADE,
    data,
  };
};

export const updateRightSidebarTab = data => {
  return {
    type: UPDATE_RIGHT_SIDEBAR_TAB,
    data,
  };
};

export const updateYearGroupId = data => {
  return {
    type: UPDATE_YEAR_GROUP_ID,
    data,
  };
};

export const updateProjectModalAndDialogueValue = data => {
  return {
    type: UPDATE_PROJECT_MODAL_AND_DIALOGUE_VALUE,
    data,
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_PORTFOLIO_STUDENT_IN_REDUX]: (state, action) => {
    return update(state, {
      portfolioStudentDetails: { $set: action.data },
    });
  },
  [RESET_CREATE_PROJECT_DATA_IN_REDUX]: state => {
    return update(state, {
      createProjectData: { $set: _.cloneDeep(initialState.createProjectData) },
    });
  },
  [UPDATE_CREATE_PROJECT_DATA_IN_REDUX]: (state, action) => {
    const params = action.data;
    _.map(_.keys(params), key => {
      state = update(state, {
        createProjectData: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [UPDATE_CURRENT_GRADE]: (state, action) => {
    const params = action.data;
    return update(state, {
      currentGrade: { $set: params },
    });
  },
  [UPDATE_RIGHT_SIDEBAR_TAB]: (state, action) => {
    return update(state, {
      sidebarTab: { $set: action.data },
    });
  },
  [UPDATE_YEAR_GROUP_ID]: (state, action) => {
    return update(state, { yearGroupId: { $set: action.data } });
  },
  [UPDATE_PROJECT_MODAL_AND_DIALOGUE_VALUE]: (state, action) => {
    return update(state, {
      projectModelAndDialogueValue: { $set: action.data },
    });
  },
};

const initialState = {
  createProjectData: {},
  portfolioStudentDetails: {},
  currentGrade: null,
  sidebarTab: null,
  yearGroupId: null,
  projectModelAndDialogueValue: null,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const updateStudentProjectPortfolioField = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateStudentProjectPortfolioMutation,
        variables: params,
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const deleteStudentProjectPortfolioInterview = ({
  id,
  portfolioId,
  isFormalInterview,
}) => {
  return async dispatch => {
    const portfolioCachedData = getStudentProjectPortfolioProgressFragmentInCache(
      { id: portfolioId }
    );
    try {
      if (isFormalInterview) {
        writeStudentProjectPortfolioProgressFragmentInCache({
          id: portfolioId,
          data: update(portfolioCachedData, {
            interviews: {
              $set: {
                //total counts refer to the number of formal interview completed for particular portfolio id
                totalCount:
                  _.get(portfolioCachedData, "interviews.totalCount", 0) - 1,
                __typename: "ProjectAcademicHonestyConnection",
              },
            },
          }),
        });
      }
      await client.mutate({
        mutation: deleteAcademicHonestyMutation,
        variables: { input: { id } },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getStudentProjectPortfolioInterviewsQuery,
            variables: {
              id: portfolioId,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:deleted", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const createStudentProjectPortfolioInterview = ({ input }) => {
  return async dispatch => {
    const portfolioCachedData = getStudentProjectPortfolioProgressFragmentInCache(
      { id: input.parentId }
    );
    const { wasFormalInterview } = input;
    try {
      if (wasFormalInterview != _.get(input, "isFormalInterview", false)) {
        writeStudentProjectPortfolioProgressFragmentInCache({
          id: input.parentId,
          data: update(portfolioCachedData, {
            interviews: {
              $set: {
                //total counts refer to the number of formal interview completed for particular portfolio id
                totalCount:
                  _.get(portfolioCachedData, "interviews.totalCount", 0) +
                  _.get(input, "isFormalInterview", false) -
                  !_.get(input, "isFormalInterview", false),
                __typename: "ProjectAcademicHonestyConnection",
              },
            },
          }),
        });
      }
      await client.mutate({
        mutation: createAcademicHonestyMutation,
        variables: { input: _.omit(input, "wasFormalInterview") },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getStudentProjectPortfolioInterviewsQuery,
            variables: {
              id: input.parentId,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:added", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      if (wasFormalInterview != _.get(input, "isFormalInterview", false)) {
        writeStudentProjectPortfolioProgressFragmentInCache({
          id: input.parentId,
          data: portfolioCachedData,
        });
      }
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const updateStudentProjectPortfolioInterview = ({
  input,
  portfolioId,
}) => {
  const portfolioCachedData = getStudentProjectPortfolioProgressFragmentInCache(
    { id: portfolioId }
  );
  const { wasFormalInterview } = input;
  if (wasFormalInterview != _.get(input, "isFormalInterview", false)) {
    writeStudentProjectPortfolioProgressFragmentInCache({
      id: portfolioId,
      data: update(portfolioCachedData, {
        interviews: {
          $set: {
            // total count refers to the formal interview completed for particular porfolio
            totalCount:
              _.get(portfolioCachedData, "interviews.totalCount", 0) +
              _.get(input, "isFormalInterview", false) -
              !_.get(input, "isFormalInterview", false),
            __typename: "ProjectAcademicHonestyConnection",
          },
        },
      }),
    });
  }
  return async dispatch => {
    try {
      await client.mutate({
        mutation: updateAcademicHonestyMutation,
        variables: { input: _.omit(input, "wasFormalInterview") },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getStudentProjectPortfolioInterviewsQuery,
            variables: {
              id: portfolioId,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:updated", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      if (wasFormalInterview != _.get(input, "isFormalInterview", false)) {
        writeStudentProjectPortfolioProgressFragmentInCache({
          id: portfolioId,
          data: portfolioCachedData,
        });
      }
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const updateProjectGroupProjectStatus = ({
  projectId,
  status,
  projectGroupId,
  searchText,
  statusMessage,
  courses,
}) => {
  return async (dispatch, getState) => {
    const projectGroupType = getProjectGroupTypeFromId({
      projectGroupId,
      state: getState(),
    });

    try {
      await client.mutate({
        mutation: updateProjectMutation,
        variables: { projectId, status, statusMessage },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getProjectGroupProjectsWithFieldsQuery,
            variables: {
              id: projectGroupId,
              projectFieldFilters: getCASProjectDetailsFilter,
              projectFilters: {
                status: PROJECT_STATUS_PENDING_FOR_APPROVAL,
                searchText,
                courses,
              },
              isServiceAsAction:
                projectGroupType === PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
            },
          },
          {
            query: getProjectGroupProjectCountQuery,
            variables: {
              id: projectGroupId,
              filters: { status: PROJECT_STATUS_PENDING_FOR_APPROVAL, courses },
            },
          },
        ],
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const updateProjectGroupProjectStatusInBulk = ({
  projectGroupId,
  status,
  fromStatus,
  courses,
}) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: updateProjectStatusInBulkMutation,
        variables: { projectGroupId, status, fromStatus },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getProjectGroupProjectCountQuery,
            variables: {
              id: projectGroupId,
              filters: { status: PROJECT_STATUS_PENDING_FOR_APPROVAL, courses },
            },
          },
        ],
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const FieldComponentContext = createContext();

const constructNestedFieldsRecursive = ({
  fieldIds,
  projectFieldData,
  field_list,
  dynamicTemplateFieldList,
  role,
}) => {
  _.forEach(fieldIds, rootFieldId => {
    const currentField = _.find(projectFieldData, { id: rootFieldId });
    const currentFieldUid = _.get(currentField, "uid");

    const templateUid =
      _.get(currentField, "pseudoFieldUid", "") || currentFieldUid;

    let newTemplateField = {
      ...updateItemWithConfig({ role, item: field_list[templateUid] }),
      id: currentFieldUid,
    };

    if (!_.isEmpty(currentField.children)) {
      constructNestedFieldsRecursive({
        fieldIds: currentField.children,
        projectFieldData,
        field_list,
        dynamicTemplateFieldList,
        role,
      });

      const childUids = _.map(currentField.children, child => {
        return _.find(projectFieldData, item => item.id === child).uid;
      });

      newTemplateField = {
        ...newTemplateField,
        childrenList: _.map(childUids, item => dynamicTemplateFieldList[item]),
      };
    }

    dynamicTemplateFieldList[`${currentFieldUid}`] = newTemplateField;
  });
};

export const constructNestedTemplate = ({
  projectFieldData,
  template,
  role,
}) => {
  const {
    body: { field_list },
  } = template;

  const dynamicTemplateFieldList = field_list;

  //rootFields will have parent null
  const rootFields = _.filter(projectFieldData, item => !item.parent);

  constructNestedFieldsRecursive({
    fieldIds: _.map(rootFields, item => item.id),
    projectFieldData,
    field_list,
    dynamicTemplateFieldList,
    role,
  });

  const dynamicTemplate = update(template, {
    body: {
      field_list: {
        $set: dynamicTemplateFieldList,
      },
    },
  });

  return dynamicTemplate;
};

export const constructNestedTemplateMemoize = _.memoize(
  params => constructNestedTemplate(params),
  ({ projectFieldData }) =>
    JSON.stringify(_.map(projectFieldData, item => item.id))
);

export const isIEAssessment = ({ projectGroupSubType }) => {
  return _.includes(
    [
      PROJECT_GROUP_SUB_TYPE_INTERNAL_ASSESSMENT,
      PROJECT_GROUP_SUB_TYPE_EXTERNAL_ASSESSMENT,
    ],
    projectGroupSubType
  );
};

export const isStudentIEAssessment = ({ role, projectGroupSubType }) => {
  return (
    _.isEqual(role, ROLE_STUDENT) && isIEAssessment({ projectGroupSubType })
  );
};

export const getProjectStatusLabel = ({
  projectGroupType,
  projectGroupTypeName,
  projectGroupSubType,
  t,
}) => {
  switch (projectGroupType) {
    case PROJECT_GROUP_TYPE_SERVICE_AS_ACTON:
      return t("project:activity_status");
    default:
      return isIEAssessment({ projectGroupSubType })
        ? t("project:label_with_status", {
            label: t("classRoom:student_work"),
          })
        : t("project:project_status");
  }
};

export const getPlannerElementFilters = ({
  plannerElementFilters,
  courseId,
}) => {
  if (plannerElementFilters) {
    const type = _.get(plannerElementFilters, "associatedParents.type", "");

    switch (type) {
      case "SUBJECT": {
        const courseDetails = getCourseDetailsFromCache({
          id: courseId,
        });

        const subjectId = _.get(
          _.first(_.get(courseDetails, "subjects", [])),
          "id",
          ""
        );

        return _.merge(plannerElementFilters, {
          associatedParents: { ids: [subjectId] },
        });
      }

      default:
        return {};
    }
  } else {
    return {};
  }
};

export const getStimuliParentType = ({ courseId, projectId }) => {
  const studentCourseFilters = { courseIds: courseId ? [courseId] : [] };

  const data = getProjectStudentInfoFragmentFromCache({
    projectId,
    studentCourseFilters,
  });

  const tags = _.get(data, "students.0.courseMap.0.tags");
  const tag = _.find(tags, { type: "SUBJECT_LEVEL" });

  const subjectLevelTag = _.get(tag, "label", "");

  const parentType = _.isEqual(subjectLevelTag, SL_LABEL)
    ? ATTACHMENT_TYPE_PROJECT_GROUP_SL_STIMULI
    : ATTACHMENT_TYPE_PROJECT_GROUP_HL_STIMULI;

  return parentType;
};
