import React, { Fragment, useState, createRef } from "react";
import PropTypes from "prop-types";
import classes from "./AudioRecorderSelector.scss";
import {
  UIBaseComponent,
  UIModal,
  AudioRecorder,
  AudioRecorderV2,
  I18nHOC,
  MediaPlaceholder,
  DialogueBox,
  Portal,
} from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { colors, CLOUD_URL } from "Constants";
import { CancelIcon, DownloadIcon } from "SvgComponents";
import { getAttachmentSendObj } from "Utils";
import {
  uploadFile,
  exceededSizeError,
  removeProgressElement,
  createAttachment,
  deleteAttachment,
  downloadFromUrl,
} from "modules/Services";
import classNames from "classnames";
import { MicrophoneOutlined, DeleteOutlined } from "@toddle-design/web-icons";

const styles = {
  control: {
    borderTop: `1px solid ${colors.strokeOne}`,
  },
  buttonStyles: {
    flex: "1",
  },
};

const DIALOGS_INFO = {
  DELETE: {
    title: "delete_with_label",
    message: "delete_msg_with_label",
    confirmButtonText: "delete_agree",
    cancelButtonText: "agree_cancel",
  },
};

const CustomMediaPlaceholder = ({
  attachments,
  deleteAudio,
  mode,
  isUploading,
  t,
  onDeleteClick,
  downloadFromUrl,
  isLastAttachment,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onDialogConfirmClick = () => {
    deleteAudio();
    setShowModal(false);
  };

  const onToggleDialogueClick = () => {
    setShowModal(false);
  };

  const setDialogueBoxStatus = e => {
    onDeleteClick ? onDeleteClick(e) : setShowModal(true);
  };

  const onDownloadClick = e => {
    e.stopPropagation();
    downloadFromUrl({
      attachment: _.first(attachments),
      shouldAskForSave: true,
    });
  };

  return (
    <Fragment>
      <div className={classes.customMediaWrapper}>
        <MediaPlaceholder
          attachments={attachments}
          showFileName={false}
          mode={mode}
          isUploading={isUploading}
          isLastAttachment={isLastAttachment}
        />
        {mode === "edit" && !isUploading && (
          <div className={classes.deleteIcon} onClick={setDialogueBoxStatus}>
            <DeleteOutlined size={"xxx-small"} />
          </div>
        )}
        {/* {mode == "view" && (
          <div className={classes.deleteIcon} onClick={onDownloadClick}>
            <DownloadIcon />
          </div>
        )} */}
      </div>
      {showModal && (
        <DialogueBox
          showModal={true}
          modalTitle={t(DIALOGS_INFO["DELETE"].title, {
            label: t(`voice_instruction`),
          })}
          onClickButton2={onDialogConfirmClick}
          modalBody={t(DIALOGS_INFO["DELETE"].message, {
            label: t(`voice_instruction`),
          })}
          toggleDialogueBoxDisplay={onToggleDialogueClick}
          button1={t(DIALOGS_INFO["DELETE"].cancelButtonText) || t("cancel")}
          button2={t(DIALOGS_INFO["DELETE"].confirmButtonText)}
        />
      )}
    </Fragment>
  );
};

class AudioRecorderSelector extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalVisibility: false,
      isContainerProvided: false,
    };
    this.audioRecorderRef = null;
  }

  setModalVisibility = state => () => this.setState({ modalVisibility: state });

  onModalClose = async () => {
    // stop recorder using ref
    if (this.audioRecorderRef.refs.wrappedInstance)
      await this.audioRecorderRef.refs.wrappedInstance.cancelRecording();
    this.setModalVisibility(false)();
    this.updateRecordingStatus(false);
  };

  handleAudioFile = props => {
    this.uploadFile(props);
    this.setModalVisibility(false)();
    this.updateRecordingStatus(false);
  };

  getCreateAttachmentFieldTypeAndId = () => {
    const { fieldId, parentType, name } = this.props;
    let fieldType = "";
    switch (name) {
      case "voiceInstruction":
        if (parentType == "ASSESSMENT")
          fieldType = "ASSESSMENT_VOICE_INSTRUCTION";
        break;
    }
    return { fieldId, fieldType };
  };

  createOrDeleteAttachment = async ({ attachment, isDelete = false }) => {
    const { createAttachment, deleteAttachment } = this.props;
    const { fieldId, fieldType } = this.getCreateAttachmentFieldTypeAndId();

    if (!fieldType) {
      return attachment;
    }
    if (!isDelete) {
      const attachments = await createAttachment({
        attachment,
        nodeId: fieldId,
        type: fieldType,
        parentType: fieldType,
      });
      return _.get(attachments, "length", 0) > 0 ? attachments[0] : {};
    } else {
      deleteAttachment({
        attachmentId: _.get(attachment, "id", ""),
        nodeId: fieldId,
        type: fieldType,
      });
    }
    return attachment;
  };

  uploadFile = attachment => {
    this.updateUploadingState(true);
    const { file, title } = attachment;
    const { parentId, parentType } = this.props;

    const { promise, uploadId } = this.props.uploadFile({
      file,
      startProgress: 0,
      parentType,
      parentId,
      attachment,
    });

    promise
      .then(async item => {
        let sendObj = await getAttachmentSendObj(file, item);
        sendObj = await this.createOrDeleteAttachment({ attachment: sendObj });

        this.props.removeProgressElement({ id: uploadId });
        this.updateUploadingState(false);
        this.updateValue({
          id: uploadId,
          thumbUrl: "",
          title: "",
          __typename: "Attachment",
          ...(sendObj || {}),
        });
      })
      .catch(e => {
        //console.log(e);
        this.updateUploadingState(false);
      });
  };

  updateUploadingState(value) {
    if (this.props.uploadStatus) {
      this.props.uploadStatus(value);
    }
  }

  updateRecordingStatus = value => {
    if (this.props.updateRecordingStatus) {
      this.props.updateRecordingStatus(value);
    }
  };

  deleteAudio = async () => {
    const { value } = this.props;
    await this.createOrDeleteAttachment({ attachment: value, isDelete: true });
    this.updateValue(null);
  };

  handleAudioRecording = () => {
    this.setModalVisibility(true)();
    this.updateRecordingStatus(true);
  };

  handleCancelRecording = () => {
    this.updateRecordingStatus(false);
  };

  renderButton = () => {
    const {
      buttonComponent,
      header,
      t,
      version,
      buttonContainerStyle,
      onDeleteClick,
      disabled = false,
    } = this.props;
    const { modalVisibility } = this.state;

    const customButtonClass = classNames(
      { [classes.customButton]: true },
      { [classes.customButtonDisabled]: modalVisibility }
    );

    const buttonWrapperClasses = classNames(
      { [classes.buttonWrapper]: true },
      { [classes.disabled]: disabled }
    );

    const buttonTextWrapperClasses = classNames(
      { [classes.buttonTextWrapper]: true },
      { [classes.disabled]: disabled }
    );

    const customButtonText = modalVisibility
      ? t("common:recording_instructions")
      : t("common:add_audio_instructions");

    return (
      <div className={classes.container} style={buttonContainerStyle}>
        {buttonComponent ? (
          <div
            className={buttonWrapperClasses}
            onClick={!disabled ? this.handleAudioRecording : null}
          >
            {React.cloneElement(buttonComponent, {
              isRecording: modalVisibility,
            })}
          </div>
        ) : (
          <div
            onClick={
              !modalVisibility && !disabled
                ? this.handleAudioRecording
                : () => {}
            }
            className={customButtonClass}
          >
            <span className={buttonTextWrapperClasses}>
              <MicrophoneOutlined
                size={"xxx-small"}
                variant={"link"}
                disabled={disabled}
              />
              <span className={classes.buttonText}>
                {header ? header : customButtonText}
              </span>
            </span>
          </div>
        )}
        {this.renderRecorder()}
      </div>
    );
  };

  handleAudioRecorderRef = ref => (this.audioRecorderRef = ref);

  renderRecorder = () => {
    const { version, disabled = false } = this.props;
    const { modalVisibility } = this.state;
    if (disabled) {
      return null;
    }
    switch (version) {
      case "v1":
        return modalVisibility ? this.renderAudioRecorderV1() : null;
      case "v2":
        return modalVisibility ? this.renderAudioRecorderV2() : null;
      default:
        return null;
    }
  };

  renderAudioRecorderV1 = () => {
    const { header, t } = this.props;
    return (
      <UIModal modalContent={classes.modalContent} isOpen={true}>
        <div className={classes.modalContainer}>
          <div className={classes.headerContainer}>
            <div className={classes.header}>
              {header ? header : t("common:add_audio_instructions")}
            </div>
            <div className={classes.cancelIcon} onClick={this.onModalClose}>
              <CancelIcon width={12} height={12} />
            </div>
          </div>
          <AudioRecorder
            handleAudioFile={this.handleAudioFile}
            styles={styles}
            recordButtonColor="pink"
            ref={this.handleAudioRecorderRef}
            onDraftModalOutsideClick={this.onModalClose}
          />
        </div>
      </UIModal>
    );
  };

  renderAudioRecorderV2 = () => {
    const {
      container,
      innerContainerStyle,
      audioStripStyle,
      draftRecordingModalTitle,
      stripContainerStyles,
    } = this.props;
    const { isContainerProvided } = this.state;
    if (container && container.current) {
      this.setState({ isContainerProvided: true });
    }

    if (isContainerProvided) {
      container.current.style.position = "relative";
    }

    return (
      <Portal container={isContainerProvided ? container.current : null}>
        <AudioRecorderV2
          handleAudioFile={this.handleAudioFile}
          handleAudioStripVisibility={this.setModalVisibility(false)}
          innerContainerStyle={innerContainerStyle}
          audioStripStyle={isContainerProvided ? {} : audioStripStyle}
          styles={styles}
          recordButtonColor="pink"
          ref={this.handleAudioRecorderRef}
          draftRecordingModalTitle={draftRecordingModalTitle}
          stripContainerStyles={stripContainerStyles}
          onCancel={this.handleCancelRecording}
        />
      </Portal>
    );
  };

  renderView = () => {
    return this.renderValue();
  };

  renderValue = () => {
    const {
      mode,
      t,
      onDeleteClick,
      downloadFromUrl,
      isLastAttachment,
    } = this.props;
    const value = this.getValue();

    const isUploading = this.isUploading();
    return this.isValidValue(value) ? (
      <CustomMediaPlaceholder
        onDeleteClick={onDeleteClick}
        attachments={[value]}
        deleteAudio={this.deleteAudio}
        mode={mode}
        isUploading={isUploading}
        t={t}
        downloadFromUrl={downloadFromUrl}
        isLastAttachment={isLastAttachment}
      />
    ) : null;
  };

  isValidValue = value => {
    return !!value && !_.isEmpty(value);
  };

  isUploading = () => {
    const { uploadingObjRedux } = this.props;
    return this.isValidValue(_.get(uploadingObjRedux, "attachment", {}));
  };

  getValue = () => {
    const { uploadingObjRedux, value } = this.props;
    return this.isValidValue(value)
      ? value
      : _.get(uploadingObjRedux, "attachment", {});
  };

  renderEdit = () => {
    const { showValue } = this.props;
    const value = this.getValue();

    if (showValue && this.isValidValue(value)) {
      return this.renderValue();
    } else {
      return this.renderButton();
    }
  };
}

AudioRecorderSelector.defaultProps = {
  ...UIBaseComponent.defaultProps,
  showValue: true,
  parentId: null,
  parentType: "",
  version: "v2",
  isLastAttachment: false,
};

AudioRecorderSelector.propTypes = {
  ...UIBaseComponent.propTypes,
  version: PropTypes.oneOf(["v1", "v2"]),
};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;

  return {
    showValue: ownProps.showValue,
    progressOfUploads,
    uploadingObjRedux:
      _.find(
        Object.values(progressOfUploads),
        item =>
          item.parentType == ownProps.parentType &&
          item.parentId == ownProps.parentId
      ) || {},
  };
};

const mapActionCreators = {
  uploadFile,
  exceededSizeError,
  removeProgressElement,
  createAttachment,
  deleteAttachment,
  downloadFromUrl,
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators)
)(AudioRecorderSelector);
