import React from "react";

import { ChevronRightOutlined } from "@toddle-design/web-icons";

import classes from "./ArrowWithCount.scss";

const ArrowWithCount = props => {
  const { count } = props;

  return (
    <div className={classes.container}>
      {count != 0 && <div className={classes.count}>{count}</div>}
      <ChevronRightOutlined size={"xx-small"} variant={"subtle"} />
    </div>
  );
};

export default ArrowWithCount;
