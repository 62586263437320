/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CaretDownFilled } from "@toddle-design/web-icons";

/**--internal -- */
import { ButtonDropdown, FilterSelectionText } from "UIComponents";

/**--relative-- */
import classes from "./Level3Filter.scss";
import { containerStyle } from "./Level3FilterStyles";
import DropdownContent from "./DropdownContent";
import {
  getFlattenOptionsMemoized,
  getFlattenValuesMemoized,
} from "./Level3FilterUtils";

const ButtonComponent = ({ label, showDropdown, options, values }) => {
  const iconContainerClassList = classNames(
    {
      [classes.iconContainer]: true,
    },
    {
      [classes.activatedIconContainer]: showDropdown,
    }
  );

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.buttonLabel}>{label}:</div>
      <FilterSelectionText options={options} values={values} />

      <div className={iconContainerClassList}>
        <CaretDownFilled size={"xxx-small"} />
      </div>
    </div>
  );
};

const Level3Filter = props => {
  const {
    parentContainerStyle,
    buttonDropDownContainerStyle,
    label,
    getAllOptionLabel,
    onUpdateShowDropDown,
    isDisabled,
    optionList,
    valueList,
    getSearchPlaceholder,
    updateInputField,
  } = props;

  const options = getFlattenOptionsMemoized({ optionList });

  const values = getFlattenValuesMemoized({ valueList });

  const buttonElement = props.buttonElement ? (
    React.cloneElement(props.buttonElement, { label, options, values })
  ) : (
    <ButtonComponent label={label} options={options} values={values} />
  );

  const placeholder = getSearchPlaceholder({ label });

  return (
    <ButtonDropdown
      shouldCloseOnSelfClick={false}
      parentContainerStyle={parentContainerStyle}
      containerStyle={{ ...containerStyle, ...buttonDropDownContainerStyle }}
      buttonComponent={buttonElement}
      dropdownComponent={
        <DropdownContent
          optionList={optionList}
          placeholder={placeholder}
          updateInputField={updateInputField}
          valueList={valueList}
          getAllOptionLabel={getAllOptionLabel}
        />
      }
      onUpdateShowDropDown={onUpdateShowDropDown}
      disabled={isDisabled}
    />
  );
};

Level3Filter.displayName = "Level3Filter";

Level3Filter.defaultProps = {
  parentContainerStyle: {},
  buttonDropDownContainerStyle: {},
  isDisabled: false,
};

Level3Filter.propTypes = {
  parentContainerStyle: PropTypes.object,
  buttonDropDownContainerStyle: PropTypes.object,
  label: PropTypes.string,
  getAllOptionLabel: PropTypes.func,
  onUpdateShowDropDown: PropTypes.func,
  isDisabled: PropTypes.bool,
  optionList: PropTypes.array,
  valueList: PropTypes.array,
  getSearchPlaceholder: PropTypes.func,
  updateInputField: PropTypes.func,
};

export default Level3Filter;
