import PropTypes from "prop-types";
import React from "react";

const DiscussionFilledIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      fill={props.fill && props.fill}
      viewBox="0 0 32 32"
    >
      <path d="M25 25H19.5L14.3 29.3C14 29.6 13.5 29.3 13.5 28.9V25H11C8.8 25 7 23.2 7 21V10C7 7.8 8.8 6 11 6H25C27.2 6 29 7.8 29 10V21C29 23.2 27.2 25 25 25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 13C12.5 12.4477 12.9477 12 13.5 12H23.5C24.0523 12 24.5 12.4477 24.5 13C24.5 13.5523 24.0523 14 23.5 14H13.5C12.9477 14 12.5 13.5523 12.5 13Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 18C12.5 17.4477 12.9477 17 13.5 17H23.5C24.0523 17 24.5 17.4477 24.5 18C24.5 18.5523 24.0523 19 23.5 19H13.5C12.9477 19 12.5 18.5523 12.5 18Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4C7.25229 4 5 6.25229 5 9V20C5 20.5523 4.55228 21 4 21C3.44772 21 3 20.5523 3 20V9C3 5.14772 6.14772 2 10 2H22C22.5523 2 23 2.44772 23 3C23 3.55228 22.5523 4 22 4H10Z"
      />
    </svg>
  );
};

DiscussionFilledIcon.defaultProps = {
  height: 20,
  width: 20,
};

DiscussionFilledIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DiscussionFilledIcon;
