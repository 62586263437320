import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import FamilyCommunicationDetails from "./routes/FamilyCommunicationDetails";
export default store => ({
  path: "familyconversations",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/FamilyConversationsModule")
              .default;

            injectReducer(store, { key: "familyConversations", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "family-conversations"
        );
      })
  ),
  childRoutes: [FamilyCommunicationDetails(store)],
});
