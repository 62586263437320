import React from "react";
import {
  generateRandomId,
  extractContentFromHTML,
  getWeekStartDate,
  getDateTimeStringsBasedOnFormat,
  getTimelineLabels,
} from "Utils";
import update from "immutability-helper";
import moment from "moment";
import {
  ASSIGNMENT_TODO_STATE_TABS,
  MYP_ASSIGNMENT_TYPE_MAPPING,
  UBD_ASSIGNMENT_TYPE_MAPPING,
  ASSIGNMENT_TYPE_MAPPING,
  PYP_ASSIGNMENT_TYPE_MAPPING,
} from "ClassRoom/modules/ClassRoomModule";
import { colors } from "Constants";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";

import {
  getOrganizationConstantsFromCache,
  getPlannerTemplateFromCache,
} from "modules/CommonGraphqlHelpers";
import { getTaggableElements } from "Post/modules/PostModule";
import { getUserInfo } from "Login/modules/LoginModule";
import { getUserCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getUserCoursesFilters } from "Course/modules/CourseModule";
import {
  ASSESSMENT_TOOLS,
  getPlannerElementsToEvaluate,
  pypElementMapping,
  getCriteriaSetBasedOnPlannerElementType,
} from "modules/Services";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import {
  LeftArrowOutlined,
  StudentOutlined,
  CommentMultipleOutlined,
  FilterOutlined,
  StudentShareOutlined,
  ReSubmissionRequestOutlined,
  AssessmentandEvaluationOutlined,
  AssessmentApplicableOutlined,
  PencilLineOutlined,
  TickLargeOutlined,
  WarningCircleOutlined,
  ClockTimerOutlined,
} from "@toddle-design/web-icons";

export const getAssessmentObj = ({
  curriculumType,
  assessmentValue,
  contentType,
}) => {
  if (assessmentValue) {
    switch (curriculumType) {
      case CURRICULUM_TYPE_MYP:
      case CURRICULUM_TYPE_DP:
        return _.get(MYP_ASSIGNMENT_TYPE_MAPPING, `${assessmentValue}`, {});
      case CURRICULUM_TYPE_UBD:
        return _.get(UBD_ASSIGNMENT_TYPE_MAPPING, `${assessmentValue}`, {});
      default:
        return _.get(PYP_ASSIGNMENT_TYPE_MAPPING, `${assessmentValue}`, {});
    }
  } else {
    return _.get(ASSIGNMENT_TYPE_MAPPING, `${contentType}`, {});
  }
};

export const getEvaluationTexts = ({
  isTeacherEvaluationEnabled,
  isStudentEvaluationEnabled,
  t,
  isEvaluationApplicable = true,
  teacherEvaluationOnly = false,
}) => {
  const teacherEvaluation = t("common:teacher_evaluation");
  const studentEvaluation = t("common:student_evaluation");
  const teacherEvalNotApplicable = t("common:teacher_eval_not_applicable");
  const studentEvalNotApplicable = t("common:student_eval_not_applicable");
  const evaluationNotApplicable = t("classRoom:evaluation_not_applicable");

  if (teacherEvaluationOnly) {
    if (isTeacherEvaluationEnabled) {
      return isEvaluationApplicable
        ? [teacherEvaluation]
        : [teacherEvalNotApplicable];
    } else {
      return null;
    }
  }

  if (isTeacherEvaluationEnabled) {
    if (isStudentEvaluationEnabled) {
      // both teacher and student evaluation
      return isEvaluationApplicable
        ? [teacherEvaluation, studentEvaluation]
        : [evaluationNotApplicable];
    }
    // only teacher evaluation
    return isEvaluationApplicable
      ? [teacherEvaluation]
      : [teacherEvalNotApplicable];
  }
  if (isStudentEvaluationEnabled) {
    // only student evaluation
    return isEvaluationApplicable
      ? [studentEvaluation]
      : [studentEvalNotApplicable];
  }
};

const getSubmissionAttachmentsToDisplayLocal = ({
  attachmentGroups,
  attachments = [],
}) => {
  const attachmentGroupsToDisplay = _.reduce(
    attachmentGroups,
    (result, attachmentGroup) => {
      const attachmentGroupAttachments = _.get(
        attachmentGroup,
        "attachments",
        []
      );

      if (!_.isEmpty(attachmentGroupAttachments)) {
        result = _.concat(result, attachmentGroupAttachments);
      }
      return result;
    },
    []
  );
  if (_.isEmpty(attachments)) return attachmentGroupsToDisplay;
  else return _.concat(attachmentGroupsToDisplay, attachments);
};

export const getSubmissionAttachmentsToDisplay = _.memoize(
  params => getSubmissionAttachmentsToDisplayLocal(params),
  params => JSON.stringify(params)
);

const getAttachmentsToDisplayWithPostConfigLocal = ({
  attachmentGroups,
  attachments = [],
}) => {
  const newAttachmentGroups = _.reduce(
    attachmentGroups,
    (result, attachmentGroup) => {
      const attachmentGroupAttachments = _.map(
        _.get(attachmentGroup, "attachments", []),
        attachment => {
          return {
            ...attachmentGroup,
            attachmentId: attachment?.id,
            attachments: [attachment],
            isPost: true,
          };
        }
      );

      if (!_.isEmpty(attachmentGroupAttachments)) {
        result = _.concat(result, attachmentGroupAttachments);
      }
      return result;
    },
    []
  );
  return _.concat(newAttachmentGroups, attachments);
};

export const getAttachmentsToDisplayWithPostConfig = _.memoize(
  params => getAttachmentsToDisplayWithPostConfigLocal(params),
  params => {
    const attachmentIds = _.reduce(
      params?.attachmentGroups,
      (result, attachmentGroup) => {
        const attachmentGroupAttachments = _.map(
          _.get(attachmentGroup, "attachments", []),
          attachment => attachment?.id
        );

        if (!_.isEmpty(attachmentGroupAttachments)) {
          result = _.concat(result, attachmentGroupAttachments);
        }
        return result;
      },
      []
    );

    return JSON.stringify({ ...params, attachmentIds });
  }
);

const getSubmissionAttachmentsToDownload = ({
  attachmentGroups,
  attachments,
}) => {
  const attachmentsToDownload = _.reduce(
    _.concat(attachmentGroups, attachments),
    (result, obj) => {
      if (!_.isEmpty(_.get(obj, "attachments", []))) {
        result = result.concat(_.get(obj, "attachments", []));
      } else {
        result = result.concat(obj);
      }
      return result;
    },
    []
  );
  return attachmentsToDownload;
};

export const getStudentSubmissionObj = ({
  studentId,
  firstName,
  lastName,
  attachmentGroups,
  attachments,
  response,
}) => {
  let studentSubmissionObj = {};
  let attachmentsToDownload = getSubmissionAttachmentsToDownload({
    attachmentGroups,
    attachments,
  });
  attachmentsToDownload = _.filter(
    attachmentsToDownload,
    item =>
      !(
        _.isEqual(_.get(item, "type", ""), "WORKBOOK") &&
        _.isEmpty(_.get(item, "metadata.publicUrl", ""))
      )
  );
  studentSubmissionObj = update(studentSubmissionObj, {
    [studentId]: { $set: {} },
  });
  studentSubmissionObj = update(studentSubmissionObj, {
    [studentId]: { attachments: { $set: attachmentsToDownload } },
  });
  if (!_.isEmpty(response)) {
    studentSubmissionObj = update(studentSubmissionObj, {
      [studentId]: {
        attachments: {
          $push: [
            { id: generateRandomId(), type: "RESPONSE", title: response },
          ],
        },
      },
    });
  }

  studentSubmissionObj = update(studentSubmissionObj, {
    [studentId]: { folderName: { $set: `${firstName}_${lastName}` } },
  });
  return studentSubmissionObj;
};

export const getAllStudentsSubmissionObj = ({ studentsSubmissions }) => {
  let allStudentsSubmissionObj = {};
  _.map(
    studentsSubmissions,
    ({ student: { id: studentId, firstName, lastName }, submission = {} }) => {
      const response = _.get(submission, "response", "") || "";
      const attachmentGroups = _.get(submission, "attachmentGroups", []) || [];
      const attachments = _.get(submission, "attachments", []) || [];

      const studentSubmissionObj = getStudentSubmissionObj({
        studentId,
        firstName,
        lastName,
        attachmentGroups,
        attachments,
        response,
      });

      allStudentsSubmissionObj = update(allStudentsSubmissionObj, {
        [studentId]: { $set: studentSubmissionObj[studentId] },
      });
    }
  );
  return allStudentsSubmissionObj;
};

const getDefaultTodoFilters = ({
  userId,
  courseIds,
  filterOptions,
  todoActiveTab,
  orgId,
  createdBy,
  userType,
  searchText,

  contentTypes,
  academicYears,
}) => {
  let filters = {
    courseIds:
      _.get(courseIds, "length", 0) == 0 || _.get(courseIds, "0") === "ALL"
        ? userType === "staff"
          ? _.map(_.get(filterOptions, "courses", []), "value")
          : undefined
        : courseIds,
    contentTypes,
  };

  if (userType === "staff") {
    filters = {
      ...filters,
      searchText,
      createdBy:
        createdBy === "ALL" || _.get(createdBy, 0) === "ALL"
          ? undefined
          : _.get(createdBy, 0),
      organizationId: orgId,
    };
  } else {
    filters = {
      ...filters,
      studentId: userId,
      academicYears,
    };
  }

  if (todoActiveTab === "ASSIGNED") {
    filters = {
      ...filters,
      states: "PUBLISHED",
      status: [
        "EVALUATION_PENDING",
        "SUBMISSION_PENDING",
        "EVALUATED",
        "REQUEST_RESUBMISSION",
        "SUBMISSION_NOT_REQUIRED",
        "TURNED_IN",
        "EVALUATED_SHARED",
      ],
    };
  } else if (todoActiveTab === "OVERDUE") {
    filters = {
      ...filters,
      status: "OVERDUE",
      states: "PUBLISHED",
    };
  } else if (todoActiveTab === "COMPLETED") {
    filters = {
      ...filters,
      states: "ARCHIVED",
    };
  } else if (todoActiveTab === "TO_REVIEW") {
    filters = {
      ...filters,
      states: "PUBLISHED",
    };
  } else if (todoActiveTab === "REVIEWED") {
    filters = {
      ...filters,
      states: "ARCHIVED",
    };
  }

  return filters;
};

export const getClassroomTodoCountFilters = params => {
  const { orgId, filterDate, todoActiveTab } = params;
  const {
    lastWeekStartDate,
    lastWeekEndDate,
    earlierEndDate,
    thisWeekStartDate,
    thisWeekEndDate,
    laterStartDate,
  } = getDateList({
    orgId,
    todoActiveTab,
  });
  let dateFilters = {};
  if (todoActiveTab == "REVIEWED" || todoActiveTab == "COMPLETED") {
    dateFilters = {
      archiveLastWeekStartDate: lastWeekStartDate,
      archiveLastWeekEndDate: lastWeekEndDate,
      archiveEarlierEndDate: earlierEndDate,
      archiveThisWeekStartDate: thisWeekStartDate,
      archiveThisWeekEndDate: thisWeekEndDate,
      archiveLaterStartDate: laterStartDate,
    };
  } else {
    dateFilters = {
      lastWeekStartDate,
      lastWeekEndDate,
      earlierEndDate,
      thisWeekStartDate,
      thisWeekEndDate,
      laterStartDate,
    };
  }
  const filters = {
    ...getDefaultTodoFilters(params),
    ...(dateFilters || {}),
  };

  return filters;
};

export const getDateList = ({ orgId, todoActiveTab }) => {
  const organizationDetails = getOrganizationConstantsFromCache(orgId);
  const { scheduler } = organizationDetails || {};
  const week_start_day = _.get(scheduler, "week_start_day");
  const currentWeekStartDate = getWeekStartDate({
    startDate: moment(),
    weekStartDay: week_start_day,
    weekLength: 7,
  });
  const lastWeekStartDate = moment(currentWeekStartDate)
    .subtract(7, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const lastWeekEndDate = moment(currentWeekStartDate)
    .startOf("day")
    .toDate()
    .toISOString();
  const earlierEndDate = moment(currentWeekStartDate)
    .subtract(7, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const thisWeekStartDate = moment(currentWeekStartDate)
    .startOf("day")
    .toDate()
    .toISOString();
  const thisWeekEndDate = moment(currentWeekStartDate)
    .add(7, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const nextWeekStartDate = moment(currentWeekStartDate)
    .add(7, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const nextWeekEndDate = moment(currentWeekStartDate)
    .add(14, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const laterStartDate = moment(currentWeekStartDate)
    .add(7, "days")
    .startOf("day")
    .toDate()
    .toISOString();

  return {
    lastWeekStartDate,
    lastWeekEndDate,
    earlierEndDate,
    thisWeekStartDate,
    thisWeekEndDate,
    laterStartDate,
    nextWeekEndDate,
    nextWeekStartDate,
  };
};

export const getDatesFilters = ({ orgId, filterDate, todoActiveTab }) => {
  const filters = {};
  const {
    lastWeekStartDate,
    lastWeekEndDate,
    earlierEndDate,
    thisWeekStartDate,
    thisWeekEndDate,
    laterStartDate,
  } = getDateList({
    orgId,
    todoActiveTab,
  });

  let filterKey = "deadlineDates";
  if (todoActiveTab == "REVIEWED" || todoActiveTab == "COMPLETED") {
    filterKey = "archiveDates";
  }
  switch (filterDate) {
    case "LAST_WEEK":
      filters[filterKey] = {
        startDate: lastWeekStartDate,
        endDate: lastWeekEndDate,
      };

      break;
    case "EARLIER":
      filters[filterKey] = {
        endDate: earlierEndDate,
      };
      break;
    case "THIS_WEEK":
      filters[filterKey] = {
        startDate: thisWeekStartDate,
        endDate: thisWeekEndDate,
      };
      break;
    case "LATER":
      filters[filterKey] = {
        startDate: laterStartDate,
      };
      break;
    case "NO_DUE_DATE":
      filters["isNoDeadlineDate"] = true;
      break;
  }
  return filters;
};

export const getClassroomTodoFilters = ({
  orgId,
  filterDate,
  todoActiveTab,
  ...rest
}) => {
  const filters = {
    ...getDefaultTodoFilters({
      ...(rest || {}),
      orgId,
      todoActiveTab,
    }),
    ...getDatesFilters({ orgId, filterDate, todoActiveTab }),
  };
  let { orderBy, orderByDirection } =
    _.find(ASSIGNMENT_TODO_STATE_TABS, item => item.value == todoActiveTab) ||
    {};

  if (
    todoActiveTab == "ASSIGNED" ||
    todoActiveTab === "OVERDUE" ||
    todoActiveTab === "TO_REVIEW" ||
    todoActiveTab === "OVERDUE"
  ) {
    if (filterDate != "NO_DUE_DATE") {
      orderBy = "DUE_AT";
    }
  }

  if (filterDate == "LATER") {
    orderByDirection = "ASC";
  }

  return { ...(filters || {}), orderBy, orderByDirection };
};

export const getAssignmentStats = ({ assignedStudents }) => {
  const completeOrEvaluatedAssignments = [];
  const sharedAssignments = [];
  const submittedAssignments = [];
  const students = [];

  _.forEach(assignedStudents, item => {
    const { isEvaluated, isSharedWithStudent, isSubmitted } = item;
    const firstName = _.get(item, "student.firstName", "");
    const lastName = _.get(item, "student.lastName", "");
    const isArchived = _.get(item, "student.isArchived", false);
    const name = `${firstName} ${lastName}`;

    if (isEvaluated) {
      completeOrEvaluatedAssignments.push({ name, isArchived });
    }
    if (isSharedWithStudent) {
      sharedAssignments.push({ name, isArchived });
    }
    if (isSubmitted) {
      submittedAssignments.push({ name, isArchived });
    }

    students.push({ name, isArchived });
  });

  return {
    students,
    submittedAssignments,
    completeOrEvaluatedAssignments,
    sharedAssignments,
  };
};

export const formatDueDate = ({ deadline, t }) => {
  return deadline
    ? `${t("classRoom:due_date")}: ${
        getDateTimeStringsBasedOnFormat({
          inputDateTimeString: deadline,
        }).dateTimeString
      }`
    : t("classRoom:no_due_date");
};

export const extractEntityName = ({ content }) => {
  return (
    _.get(content, "assignmentTitle", "") ||
    _.get(content, "title.value", "") ||
    extractContentFromHTML({
      htmlString: _.get(content, "label", ""),
    })
  );
};

export const getStatusDetails = ({ data, t, userType }) => {
  const {
    assignment,
    status,
    evaluatedAt,
    submission,
    state: assignmentStateTabV2,
    publishedAt: publishedAtV2,
  } = data || {};
  const { deadline, state, contentType } = assignment || {};
  const { publishedAt, state: assignmentStateTab } = state || {};
  const { submittedAt, returnedAt } = submission || {};

  let statusColor = colors.gray48;
  let dateColor = colors.black;
  let label = "";
  let date = "";

  const publishedAtDate = publishedAt ? publishedAt : publishedAtV2;
  const assignmentStateTabValue =
    userType == "parent" ? assignmentStateTabV2 : assignmentStateTab;

  if (
    contentType == "ASSIGNMENT_MEETING" ||
    assignmentStateTabValue == "SCHEDULED"
  ) {
    date = publishedAtDate
      ? getDateTimeStringsBasedOnFormat({
          inputDateTimeString: publishedAtDate,
        }).dateTimeString
      : "-";
    label = t("common:scheduled_for_with_label");
  } else {
    switch (status) {
      case "REQUEST_RESUBMISSION":
        date = getDateTimeStringsBasedOnFormat({
          inputDateTimeString: returnedAt,
        }).dateLabelString;
        break;

      case "SUBMISSION_NOT_REQUIRED":
      case "SUBMISSION_PENDING":
        date = deadline
          ? getDateTimeStringsBasedOnFormat({ inputDateTimeString: deadline })
              .dateTimeString
          : t("classRoom:no_due_date");
        break;

      case "COMPLETED":
      case "EVALUATED_SHARED":
        date = getDateTimeStringsBasedOnFormat({
          inputDateTimeString: evaluatedAt,
        }).dateLabelString;
        break;
      case "EVALUATED":
        date = getDateTimeStringsBasedOnFormat({
          inputDateTimeString: evaluatedAt,
        }).dateLabelString;
        statusColor = colors.blue29;
        dateColor = colors.blue29;
        break;
      case "OVERDUE":
        date = getDateTimeStringsBasedOnFormat({
          inputDateTimeString: deadline,
        }).dateTimeString;
        statusColor = colors.textCritical;
        break;
      case "TURNED_IN": {
        const currentDateTime = new Date();
        date = getDateTimeStringsBasedOnFormat({
          inputDateTimeString: submittedAt ?? currentDateTime.toISOString(),
        }).dateTimeString;
        break;
      }
    }
    label = getAssignmentStatusLabel({ status, t });
  }

  return {
    date,
    statusColor,
    dateColor,
    label,
  };
};

export const getAssignmentStatusLabel = ({ status, t }) => {
  return t(`classRoom:${_.toLower(status)}`);
};

export const getClassroomPostDetails = ({
  attachments,
  student,
  content,
  title,
  curriculumProgramType,
  courseId,
}) => {
  const taggedAssessment = _.get(content, "presetAssessment");
  const taggedUnitPlan = _.get(content, "presetAssessment.unitPlan");
  const userInfo = getUserInfo({ portalType: "PLANNER" });
  let pypTaggedDetails = {};
  if (
    taggedUnitPlan &&
    _.get(taggedUnitPlan, "organization.id") == _.get(userInfo, "org_id")
  ) {
    const taggablElements = getTaggableElements({
      curriculumProgramType,
      unitPlan: taggedUnitPlan,
    });
    const uids = _.map(
      taggablElements,
      taggableElement => taggableElement.value
    );
    const allFields = _.get(content, "allFields", []);
    const fields = !_.isEmpty(allFields)
      ? allFields
      : _.get(taggedAssessment, "fields", []);

    const elementPYP = [];
    _.forEach(uids, uid => {
      const field = _.find(fields, item => item?.uid == uid);
      if (!_.isEmpty(field)) {
        elementPYP.push({
          fieldUID: uid,
          parentType: "RESOURCE",
          value: _.get(field, "value", []) || [],
          resolvedMinimalTree: _.get(field, "resolvedMinimalTree", []) || [],
        });
      }
    });
    pypTaggedDetails = {
      resourceItem: _.cloneDeep(taggedAssessment),
      unitPlan: _.cloneDeep(taggedUnitPlan),
      resourceType: "ASSESSMENT",
      elementPYP,
    };
  }
  const postCreateDetail = {
    attachments: _.map(attachments, attachment =>
      _.omit(attachment, ["__typename", "streamUrl", "isRead"])
    ),
    students: {
      totalCount: 1,
      edges: [
        {
          node: student,
          __typename: "StudentsEdge",
        },
      ],
    },
    isHiddenFromStudents: false,
    isPrivate: false,
    title,
    courseId,
    ...pypTaggedDetails,
  };
  return postCreateDetail;
};

const getFormattedCoursesDetails = ({
  courses,
  selectedTimeSlot,
  itemType,
  showCalendarAssignmentByDueDate,
}) => {
  const selectedTimeSlotStartDate = _.get(selectedTimeSlot, "startDate", null);
  const selectedTimeSlotStartTime = _.get(selectedTimeSlot, "startTime", null);
  return _.reduce(
    courses,
    (result, course) => {
      const details = {
        id: _.get(course, "id"),
        title: _.get(course, "title"),
        students: _.map(
          _.get(course, "students.edges", []),
          student => student.node
        ),
        totalStudents: _.map(
          _.get(course, "students.edges", []),
          student => student.node
        ),
        deadlineDate:
          showCalendarAssignmentByDueDate && itemType == "ASSIGNMENT"
            ? selectedTimeSlotStartDate
            : null,
        deadlineTime:
          showCalendarAssignmentByDueDate &&
          itemType == "ASSIGNMENT" &&
          selectedTimeSlotStartTime
            ? moment(selectedTimeSlotStartTime, "HH:mm")
                .add(30, "minutes")
                .format("HH:mm")
            : null,
        state: {
          state: "PUBLISHED",
          scheduleDate:
            itemType !== "ASSIGNMENT" ||
            (itemType == "ASSIGNMENT" && !showCalendarAssignmentByDueDate)
              ? selectedTimeSlotStartDate
              : null,
          scheduleTime:
            itemType !== "ASSIGNMENT" ||
            (itemType == "ASSIGNMENT" && !showCalendarAssignmentByDueDate)
              ? selectedTimeSlotStartTime
              : null,
        },
      };

      result.push(details);
      return result;
    },
    []
  );
};

export const getFormattedCoursesDetailsMemoize = _.memoize(
  params => getFormattedCoursesDetails(params),
  params => JSON.stringify(params)
);

export const getAttachmentGroupFromCurrentMedia = (
  attachmentGroups,
  currentMediaId
) => {
  const foundAttachmentGroup = _.first(
    _.filter(
      attachmentGroups,
      attachmentGroup =>
        _.findIndex(
          _.get(attachmentGroup, "attachments", []),
          attachment => attachment?.id === currentMediaId
        ) != -1
    )
  );
  return foundAttachmentGroup?.id;
};

const getAttachmentGroupResponseObject = ({
  attachmentGroups,
  conversations,
}) => {
  // Returns an object that gives messageIdArr corresponding to the attachmentGroup id
  const conversationItems = [];
  _.forEach(conversations, conversation => {
    const item = _.get(conversation, "node.item", {});
    if (
      !_.isEmpty(item) &&
      _.get(conversation, "node.itemType") ===
        "STUDENT_ASSIGNMENT_SUBMISSION_FEEDBACK"
    ) {
      conversationItems.push({
        ...item,
        messageId: _.get(conversation, "node.id"),
      });
    }
  });

  const attachmentGroupFeedbackMapper = {};

  _.forEach(attachmentGroups, attachmentGroup => {
    const messageIdArr = _.map(
      _.filter(
        conversationItems,
        conversationItem => _.get(conversationItem, "id") == attachmentGroup.id
      ),
      item => item.messageId
    );

    const messageLength = _.get(messageIdArr, "length");
    if (messageLength > 0) {
      attachmentGroupFeedbackMapper[attachmentGroup.id] = messageIdArr;
    }
  });
  return attachmentGroupFeedbackMapper;
};

export const getAttachmentGroupResponseObjectMemoize = _.memoize(
  params => getAttachmentGroupResponseObject(params),
  params => JSON.stringify(params)
);

export const isStudentPartOfCourse = ({
  userInfo,
  courseId,
  platformInfo,
  isCurriculumProgramFree,
}) => {
  const { id, userEntityType } = userInfo;
  const {
    organizationCurriculumPrograms,
    currentCurriculumProgramType,
    currentCurriculumProgramId,
  } = platformInfo;
  const courseFilters = {
    ...getUserCoursesFilters({
      currentCurriculumProgramType,
      currentCurriculumProgramId,
      organizationCurriculumPrograms,
    }),
    includeRemovedCoursesForStudent: true,
  };
  const { courses } = getUserCoursesFromCache({
    id,
    type: userEntityType,
    filters: courseFilters,
    isCurriculumProgramFree,
  });
  const studentArchivedCourseIds = _.map(
    _.filter(
      courses,
      ({ isRemovedCourseForLoggedInUser }) => isRemovedCourseForLoggedInUser
    ),
    ({ id }) => id
  );
  return !studentArchivedCourseIds.includes(courseId);
};

const getClassroomProps = ({ sourceType }) => {
  switch (sourceType) {
    case "SNP":
      return {
        showHeaderBackButton: false,
        showEvaluationDropdown: false,
        showDeleteMessageOption: false,
        showSendMessageOption: false,
        showCreatePostButton: false,
        showGiveFeedbackButton: false,
        isPypEvaluationEditable: false,
        isToolResponseEnabled: false,
        showAddResponseButton: false,
      };
    default:
      return {
        isPypEvaluationEditable: true,
        isToolResponseEnabled: true,
        showCreatePostButton: true,
        showAddResponseButton: true,
      };
  }
};

export const getClassroomPropsMemoize = _.memoize(
  params => getClassroomProps(params),
  ({ sourceType }) => JSON.stringify({ sourceType })
);

const getAssignmentTypeFilterOptions = ({
  t,
  currentCurriculumProgramType,
}) => {
  switch (currentCurriculumProgramType) {
    case "IB_MYP": {
      return [
        { label: t("common:le_label"), value: "le", key: "le" },
        {
          label: t("classRoom:formative_assessment"),
          value: "fmt",
          key: "fmt",
        },
        {
          label: t("classRoom:summative_assessment"),
          value: "smt",
          key: "smt",
        },
      ];
    }
    // ! Temporarily Disabled
    // case "UBD": {
    //   return [
    //     { label: t("common:le_label"), value: "le" },
    //     { label: t("common:assessment"), value: "assessment" },
    //   ];
    // }
    case "IB_PYP": {
      return [];
    }
    default:
      return [];
  }
};

export const getAssignmentTypeFilterOptionsMemoize = _.memoize(
  params => getAssignmentTypeFilterOptions(params),
  ({ currentCurriculumProgramType }) =>
    JSON.stringify({ currentCurriculumProgramType })
);

/** util to get the content type value based on the curriculum program for the insights query */
export const getContentTypesInsightsFilter = ({
  contentTypes,
  currentCurriculumProgramType,
}) => {
  if (_.first(contentTypes) === "ALL") {
    switch (currentCurriculumProgramType) {
      case CURRICULUM_TYPE_PYP:
        return ["ASSESSMENT", "ASSIGNMENT_RESOURCE", "ASSIGNMENT_DISCUSSION"];
      case CURRICULUM_TYPE_MYP:
      case CURRICULUM_TYPE_UBD:
        return ["ASSESSMENT"];
      default:
        return [];
    }
  } else {
    return contentTypes;
  }
};

export const getPostIconState = params => {
  const {
    postsAddedToJournalCount,
    assignedStudentsCount,
    responsesEvaluatedButNotPublishedToJournalCount,
    isAllSubmissionsRevamp = false,
  } = params;

  let tooltip = "";
  let tooltipContainerStyle = {};
  let align = {};
  let isDisabled = false;
  let isPostAddIcon = true;
  let iconVariant = "subtle";
  let hasOnClickAction = false;

  if (postsAddedToJournalCount === assignedStudentsCount) {
    tooltip = "classRoom:all_responses_added_to_journal";
    align = { offset: isAllSubmissionsRevamp ? [6, 0] : [12, 0] };
    isPostAddIcon = false;
    iconVariant = "success";
    tooltipContainerStyle = {
      padding: "0px",
    };
  } else if (
    responsesEvaluatedButNotPublishedToJournalCount === 0 &&
    postsAddedToJournalCount > 0
  ) {
    tooltip = "classRoom:all_marked_evaluated_responses_have_been_added";
    tooltipContainerStyle = {
      maxWidth: "180px",
      padding: "0px",
    };
    align = { offset: isAllSubmissionsRevamp ? [14, 0] : [20, 0] };
    isPostAddIcon = false;
    iconVariant = "success";
  } else if (responsesEvaluatedButNotPublishedToJournalCount > 0) {
    tooltip = "classRoom:add_all_responses_to_journal";
    tooltipContainerStyle = {
      maxWidth: "180px",
      padding: "0px",
    };
    align = { offset: isAllSubmissionsRevamp ? [6, 0] : [12, 0] };
    hasOnClickAction = true;
  } else {
    tooltip = "classRoom:only_completed_evaluated_responses_can_be_added";
    tooltipContainerStyle = {
      maxWidth: "180px",
      padding: "0px",
    };
    align = { offset: isAllSubmissionsRevamp ? [14, 0] : [20, 0] };
    isDisabled = true;
  }
  return {
    tooltip,
    tooltipContainerStyle,
    align,
    isDisabled,
    isPostAddIcon,
    iconVariant,
    hasOnClickAction,
  };
};
const STATUS_COLOR_MAPPING = {
  critical: {
    textColor: colors.red52,
    surfaceColor: colors.toddleBG2,
    iconVariant: "critical",
  },
  warning: {
    textColor: colors.yellow38,
    surfaceColor: colors.surfaceWarning,
    iconVariant: "warning",
  },
  success: {
    textColor: colors.teal33,
    surfaceColor: colors.surfaceSuccess,
    iconVariant: "success",
  },
  disabled: {
    textColor: colors.textSubtle,
    surfaceColor: colors.surfaceSubtle,
  },
};

/* util to get submission, teacher evaluation, student evaluation status along with colors, based on student assignment data */

export const getStudentAssignmentStatus = _.memoize(
  params => _getStudentAssignmentStatus(params),
  params => JSON.stringify(params)
);

const _getStudentAssignmentStatus = studentAssignmentData => {
  const {
    submission,
    isEvaluated,
    isEvaluationApplicable,
    isStudentEvaluationEnabled,
    isStudentSubmissionEnabled,
    isTeacherEvaluationEnabled,
    toolResponses,
    isScheduled,
    publishedAt,
    t,
  } = studentAssignmentData;

  const isStudentEvaluationDone = getIsStudentEvaluationDone(toolResponses);
  const submissionStatus = _.get(submission, "status");

  let submissionStatusData = {
    ...STATUS_COLOR_MAPPING["disabled"],
    label: "Submission not required",
    isDisabled: true,
  };
  let teacherEvaluationStatus = {};
  let studentEvaluationStatus = {};

  if (isStudentSubmissionEnabled) {
    if (isScheduled) {
      submissionStatusData = {
        ...STATUS_COLOR_MAPPING["disabled"],
        label: t("common:scheduled_for_with_label", {
          label: publishedAt
            ? moment(publishedAt).format("Do, MMM YYYY hh:mm a")
            : "-",
        }),
        isDisabled: true,
      };
    } else {
      switch (submissionStatus) {
        case "DRAFT":
          submissionStatusData = {
            ...STATUS_COLOR_MAPPING["warning"],
            label: t("common:pending"),
            isDisabled: false,
          };
          break;
        case "SUBMITTED":
          submissionStatusData = {
            ...STATUS_COLOR_MAPPING["success"],
            label: t("classRoom:turned_in"),
            isDisabled: false,
          };
          break;
        case "RETURNED":
          submissionStatusData = {
            ...STATUS_COLOR_MAPPING["critical"],
            label: t("classRoom:request_resubmission"),
            isDisabled: false,
          };
          break;
      }
    }
  }

  if (isTeacherEvaluationEnabled && !isScheduled) {
    if (isEvaluationApplicable) {
      if (isEvaluated) {
        teacherEvaluationStatus = {
          ...STATUS_COLOR_MAPPING["success"],
          label: t("common:completed"),
          isDisabled: false,
        };
      } else {
        teacherEvaluationStatus = {
          ...STATUS_COLOR_MAPPING["warning"],
          label: t("common:pending"),
          isDisabled: false,
        };
      }
    } else {
      teacherEvaluationStatus = {
        ...STATUS_COLOR_MAPPING["critical"],
        label: "Evalutaion N/A",
        isDisabled: false,
      };
    }
  }

  if (isStudentEvaluationEnabled && !isScheduled) {
    if (isEvaluationApplicable) {
      if (submissionStatus == "SUBMITTED" && isStudentEvaluationDone) {
        studentEvaluationStatus = {
          ...STATUS_COLOR_MAPPING["success"],
          label: t("common:completed"),
          isDisabled: false,
        };
      } else {
        studentEvaluationStatus = {
          ...STATUS_COLOR_MAPPING["warning"],
          label: t("common:pending"),
          isDisabled: false,
        };
      }
    } else {
      studentEvaluationStatus = {
        ...STATUS_COLOR_MAPPING["critical"],
        label: "Evalutaion N/A",
        isDisabled: false,
      };
    }
  }

  return {
    submissionStatusData,
    teacherEvaluationStatus,
    studentEvaluationStatus,
  };
};

export const getStudentAssignmentSubmissionStatus = _.memoize(
  params => _getStudentAssignmentSubmissionStatus(params),
  params => JSON.stringify(params)
);

const _getStudentAssignmentSubmissionStatus = ({ submissionStatus, t }) => {
  let submissionStatusLabel = {
    label: t("classRoom:submission_not_required"),
  };
  switch (submissionStatus) {
    case "DRAFT":
      submissionStatusLabel = t("common:submission_pending");
      break;
    case "SUBMITTED":
      submissionStatusLabel = t("classRoom:turned_in");
      break;
    case "RETURNED":
      submissionStatusLabel = t("classRoom:request_resubmission");
      break;
  }
  return submissionStatusLabel;
};

/* util to check if student evaluation is done, based on toolResponses */

export const getIsStudentEvaluationDone = _.memoize(
  params => _getIsStudentEvaluationDone(params),
  params => JSON.stringify(params)
);

const _getIsStudentEvaluationDone = toolResponses => {
  const isStudentEvaluationDone = _.some(toolResponses, toolResponse => {
    return _.get(toolResponse, "creatorType") === "STUDENT";
  });
  return isStudentEvaluationDone;
};

export const STUDENT_ASSIGNMENT_MEDIA_CREATION_OPTIONS = {
  enableTagging: false,
  enableCaption: false,
  isPost: false,
  addButtonLabel: "common:add_to_work",
  creationTitle: "common:add_work",
  addMediaOptions: ["PHOTO", "VOICE", "VIDEO", "FILE", "WORKBOOK", "LINK"],
  showInstructionsWithVoice: true,
  allowCarouselCreation: false,
};

export const getShowAssessmentTool = _.memoize(
  params => _getShowAssessmentTool(params),
  params => JSON.stringify(params)
);

const _getShowAssessmentTool = assessment => {
  const { assessmentToolType, allFields, assessmentTool } = assessment;

  if (_.isEmpty(assessmentTool)) return false;

  const assessmentToolTypeHasEval = _.get(
    _.find(ASSESSMENT_TOOLS, item => item.type == assessmentToolType),
    "hasEvaluation",
    false
  );

  const interdisciplinaryField = _.find(allFields, field => {
    return _.get(field, "uid") === "interdisciplinaryCriteriaMYP";
  });

  const isMYPRubric = assessmentToolType === "MYP_OBJECTIVE_RUBRIC";
  let objectivesMYP = {};

  if (isMYPRubric) {
    objectivesMYP = _.find(allFields, field => {
      return _.get(field, "uid") === "objectivesMYP";
    });
    if (_.isEmpty(_.get(objectivesMYP, "value"))) {
      objectivesMYP = interdisciplinaryField;
    }
  }
  const shouldCheckInterdisciplinary = _.get(
    assessment,
    "checkInterdisciplinaryRubric",
    false
  );

  const hasValue = isMYPRubric
    ? !_.isEmpty(_.get(objectivesMYP, "value"))
    : interdisciplinaryField && shouldCheckInterdisciplinary
    ? !_.isEmpty(_.get(interdisciplinaryField, "value"))
    : !_.isEmpty(assessmentTool);

  return (
    !_.isEmpty(assessmentToolType) && assessmentToolTypeHasEval && hasValue
  );
};

const _checkAssessmentHasPypEvaluation = ({
  assessmentDetails,
  curriculumProgramType,
}) => {
  const plannerElementsToEvaluate = getPlannerElementsToEvaluate({
    assessmentDetails,
    curriculumProgramType,
  });

  return !_.isEmpty(plannerElementsToEvaluate);
};

export const checkAssessmentHasPypEvaluation = _.memoize(
  params => _checkAssessmentHasPypEvaluation(params),
  params => JSON.stringify(params)
);

const _getScoreData = ({ allFields }) => {
  const score = _.find(allFields, field => {
    return field.uid === "score";
  });
  const category = _.get(score, "resolvedMinimalTree.category");
  return {
    isUsingScoreAssessment: _.get(
      score,
      "resolvedMinimalTree.isUsingScore",
      false
    ),
    scoreAssociatedEntities: _.get(category, "set.associatedEntities", []),
    scoreCategoryId: _.get(category, "id"),
    scoreCategoryLabel: _.get(category, "label"),
  };
};

export const getScoreData = _.memoize(
  params => _getScoreData(params),
  params => JSON.stringify(params)
);

export const getScoreValueElementRatingsMemoize = _.memoize(
  params => getScoreValueElementRatings(params),
  params => JSON.stringify(params)
);

const getScoreValueElementRatings = ({ ibPYPElementRatings }) => {
  const scoreElement = _.find(ibPYPElementRatings, {
    ibPYPElementType: "SCORE",
  });
  return _.get(scoreElement, "value");
};

/* util to get student response data */

export const getStudentResponseData = _.memoize(
  params => _getStudentResponseData(params),
  params => JSON.stringify(params)
);

const _getStudentResponseData = items => {
  const studentResponseData = _.find(items, {
    itemType: "STUDENT_ASSIGNMENT",
  });

  const item = _.get(studentResponseData, "item", {});
  const itemId = _.get(item, "id");
  const itemType = _.get(studentResponseData, "itemType", "");
  const content = _.get(item, "assignment.content", {});
  const assessmentType = _.get(content, "assessmentType.value");
  const assessmentId = _.get(content, "id");
  const studentResponseAttachments = getSubmissionAttachmentsToDisplay({
    attachmentGroups: _.get(
      studentResponseData,
      "item.submission.attachmentGroups",
      {}
    ),
  });
  const postId = _.get(item, "post.id", "");
  return {
    itemId,
    postId,
    itemType,
    assessmentType,
    assessmentId,
    studentResponseAttachments,
  };
};
export const checkAchievementCriteriaExists = _.memoize(
  params => _checkAchievementCriteriaExists(params),
  params => JSON.stringify(params)
);

const _checkAchievementCriteriaExists = ({
  assessmentFields,
  curriculumProgramType,
  getCriteriaSetOfPypType,
}) => {
  const plannerElementsToEvaluate = getPlannerElementsToEvaluate({
    assessmentDetails: {
      allFields: assessmentFields,
    },
    curriculumProgramType,
  });
  let isCriteriaSetsExists = false;

  _.map(plannerElementsToEvaluate, pypElement => {
    const elementKey = pypElement.value;
    const type = pypElementMapping[elementKey];

    const criteriaSet = getCriteriaSetBasedOnPlannerElementType({
      type,
      getCriteriaSetOfPypType,
    });
    if (!_.isEmpty(criteriaSet)) {
      isCriteriaSetsExists = true;
    }
  });
  return !isCriteriaSetsExists;
};

export const getUpdatedCoursesStudentsForHLSL = ({
  courses,
  coursesUserMap,
}) => {
  return _.reduce(
    courses,
    (result, course) => {
      const courseId = _.get(course, "id");
      const courseStudents = _.get(course, "students", []);

      const courseUserMap = _.find(coursesUserMap, item => {
        return _.get(item, "id") == courseId;
      });

      const courseUserMapIds = _.map(courseUserMap?.userMap?.edges, item => {
        return item?.node?.user?.id;
      });

      const filteredCourseStudents = _.filter(courseStudents, courseStudent => {
        return _.includes(courseUserMapIds, courseStudent?.id);
      });

      return update(result, {
        $push: [
          {
            ...course,
            students: filteredCourseStudents,
            totalStudents: filteredCourseStudents,
          },
        ],
      });
    },
    []
  );
};

export const getCourseStudents = ({ coursesUserMap, courseId }) => {
  const courseUserMap = _.find(coursesUserMap, item => {
    return _.get(item, "id") == courseId;
  });
  return _.map(courseUserMap?.userMap?.edges, item => {
    return {
      ..._.get(item, "node.user", {}),
      tags: _.get(item, "node.tags", []),
    };
  });
};

export const filterFieldsBasedOnConfig = _.memoize(
  params => _filterFieldsBasedOnConfig(params),
  params => JSON.stringify(params)
);

const _filterFieldsBasedOnConfig = ({ fields, field_list, userType }) => {
  const filteredFields = _.filter(fields, field => {
    const hideForUserTypes = _.get(
      field_list,
      `${field}.config.hideForUserTypes`,
      []
    );
    return !_.includes(hideForUserTypes, userType);
  });

  return filteredFields;
};

export const getLeftPaneOptions = _.memoize(
  params => _getLeftPaneOptions(params),
  params => JSON.stringify(params)
);

const _getLeftPaneOptions = params => {
  const {
    t,
    classDiscussionUnreadCount,
    allSubmissionsCount,
    isStudentSubmissionEnabled,
    isInsights,
  } = params;

  const options = [
    {
      Icon: LeftArrowOutlined,
      title: t("common:back_to_label", {
        label: isInsights
          ? t("classRoom:class_stream_insights")
          : t("teacherHomePage:class_stream"),
      }),
      action: "goback",
      showDivider: true,
      customDividerStyles: {
        margin: " 0 0 12px 0",
      },
    },
    {
      Icon: StudentOutlined,
      title: t("classRoom:all_submissions"),
      action: "allsubmissions",
      rightText: t("common:student_count_plural", {
        count: allSubmissionsCount,
      }),
      hideItem: !isStudentSubmissionEnabled,
    },
    {
      Icon: CommentMultipleOutlined,
      title: t("classRoom:class_discussion"),
      action: "classdiscussion",
      showDivider: true,
      count: classDiscussionUnreadCount,
      customStyles: {
        marginTop: isStudentSubmissionEnabled ? "4px" : "0px",
      },
    },
    {
      Icon: FilterOutlined,
      showDropdown: true,
    },
  ];

  return options;
};

export const getStudentTypeOptions = _.memoize(
  params => _getStudentTypeOptions(params),
  params => JSON.stringify(params)
);

const _getStudentTypeOptions = params => {
  const {
    t,
    assignedStudents,
    isStudentSubmissionEnabled,
    isTeacherEvaluationEnabled,
  } = params;

  const totalStudents = _.size(assignedStudents);

  let studentAssignmentOptions = [];

  if (isStudentSubmissionEnabled) {
    studentAssignmentOptions = [
      ...studentAssignmentOptions,
      "SUBMISSION_PENDING",
      "OVERDUE",
      "TURNED_IN",
      "REQUEST_RESUBMISSION",
    ];
  }

  if (isTeacherEvaluationEnabled) {
    const turnedInIndex = _.findIndex(
      studentAssignmentOptions,
      item => item == "TURNED_IN"
    );
    if (turnedInIndex > -1) {
      studentAssignmentOptions = update(studentAssignmentOptions, {
        $splice: [[turnedInIndex, 1]],
      });
    }
    studentAssignmentOptions = [
      ...studentAssignmentOptions,
      "EVALUATION_PENDING",
      "EVALUATED",
      "EVALUATED_SHARED",
      "EVALUATION_NOT_APPLICABLE",
    ];
  } else {
    studentAssignmentOptions = [...studentAssignmentOptions, "COMPLETED"];
  }

  const studentTypeOptions = [
    {
      label: `${t("common:allStudents")} (${totalStudents})`,
      key: "ALL_STUDENTS",
      disabled: !totalStudents,
    },
    ..._.map(studentAssignmentOptions, status => {
      const studentAssignmentStatus = getAssignmentStatusLabel({ status, t });
      const statusCount = _.get(
        _.filter(assignedStudents, item => item.status == status),
        "length",
        0
      );
      return {
        label: `${studentAssignmentStatus} (${statusCount})`,
        key: status,
        disabled: !statusCount,
      };
    }),
  ];

  return studentTypeOptions;
};

export const getActiveAndArchiveStudents = _.memoize(
  params => _getActiveAndArchiveStudents(params),
  params => JSON.stringify(params)
);

const _getActiveAndArchiveStudents = ({ assignedStudents }) => {
  const activeStudents = _.filter(
    assignedStudents,
    ({ student }) => !_.get(student, "isArchived", false)
  );

  const archivedStudents = _.filter(assignedStudents, ({ student }) =>
    _.get(student, "isArchived", false)
  );

  return {
    activeStudents,
    archivedStudents,
  };
};

export const getMediaManagerDropdownOptions = params => {
  const {
    currentStudentData,
    t,
    isTeacherEvaluationEnabled,
    isCurrentAcademicYearSelected,
    isArchivedClass,
    contentType,
    assessmentToolTypeHasEval,
    isPrivate,
    isStudentSubmissionEnabled,
    isStudentEvaluationEnabled,
  } = params;
  const {
    isEvaluationApplicable,
    isEvaluated,
    isSharedWithStudent,
    student = {},
    status,
    isSubmitted,
  } = currentStudentData;
  const { isArchived } = student;

  const showMarkEvaluatedShareButton =
    contentType === "ASSESSMENT" &&
    isTeacherEvaluationEnabled &&
    assessmentToolTypeHasEval &&
    !isEvaluated;

  const hideEditButton =
    isSharedWithStudent ||
    (isEvaluationApplicable && isEvaluated && !isTeacherEvaluationEnabled);

  let shareLabel = "";
  if (!isEvaluated && !isSharedWithStudent) {
    shareLabel = t("assessmentEvaluation:mark_as_evaluated_share_with_student");
  } else {
    shareLabel = t("assessmentEvaluation:label_evaluation_with_student", {
      label: t("common:share"),
    });
  }

  const options = [];

  if (!isEvaluated && !isEvaluationApplicable) {
    const disabled =
      !isCurrentAcademicYearSelected ||
      !isEvaluationApplicable ||
      isArchived ||
      isArchivedClass;
    options.push({
      label: isTeacherEvaluationEnabled
        ? t("assessmentEvaluation:mark_as_evaluated")
        : t("assessmentEvaluation:mark_as_completed"),
      icon: <StudentShareOutlined disabled={disabled} />,
      key: "evaluate",
      disabled,
    });
  }

  if (isEvaluated && !hideEditButton) {
    const disabled = !isCurrentAcademicYearSelected;
    options.push({
      label: isTeacherEvaluationEnabled
        ? t("assessmentEvaluation:edit_evaluation")
        : t("common:edit"),
      icon: <PencilLineOutlined disabled={disabled} />,
      key: "edit_evaluation",
      disabled,
    });
  }

  if (showMarkEvaluatedShareButton) {
    const disabled =
      !isEvaluationApplicable ||
      (isEvaluated && isSharedWithStudent) ||
      !isCurrentAcademicYearSelected ||
      isPrivate;
    options.push({
      label: shareLabel,
      icon: <StudentShareOutlined disabled={disabled} />,
      key: !isEvaluated && !isSharedWithStudent ? "evaluate_share" : "share",
      disabled,
    });
  }

  if (isTeacherEvaluationEnabled || isStudentEvaluationEnabled) {
    const disabled = isEvaluated || !isCurrentAcademicYearSelected;
    if (isEvaluationApplicable) {
      options.push({
        label: t("assessmentEvaluation:evaluation_not_applicable"),
        icon: <AssessmentandEvaluationOutlined disabled={disabled} />,
        key: "evaluation_not_applicable",
        disabled,
      });
    } else {
      options.push({
        label: t("assessmentEvaluation:enable_evaluation"),
        icon: <AssessmentApplicableOutlined disabled={disabled} />,
        key: "enable_evaluation",
        disabled,
      });
    }
  }
  if (isStudentSubmissionEnabled) {
    const disabled =
      status === "RETURNED" ||
      isEvaluated ||
      !isSubmitted ||
      !isCurrentAcademicYearSelected ||
      isArchived ||
      isArchivedClass;
    options.push({
      label: t("assessmentEvaluation:request_resubmission"),
      icon: <ReSubmissionRequestOutlined disabled={disabled} />,
      key: "request_resubmission",
      disabled,
    });
  }

  return options;
};

export const getMediaManagerDialogInfo = _.memoize(
  params => _getMediaManagerDialogInfo(params),
  params => JSON.stringify(params)
);

const _getMediaManagerDialogInfo = ({ dialogContentClass }) => {
  const DIALOGS_INFO = {
    RETURN: {
      title: ({ t, firstName }) => {
        return t("common:return_submission_label", { name: firstName });
      },
      warningText: ({ t, firstName }) => {
        return t("common:sure_return_submission_name", {
          name: firstName,
        });
      },
      modalBodyStyle: {
        padding: "8px 64px 16px 48px",
      },
      confirmButtonText: "common:return",
      cancelButtonText: "common:cancel",
      isPromptBox: true,
      promptBoxType: "TextAreaInput",
      promptDetail: ({ t }) => {
        return {
          placeholder: t("common:enter_remark_here"),
          label: t("common:remark"),
          checkValidation: false,
          promptText: "",
        };
      },
      modalBody: () => {},
      dialogContentClass,
      button1Props: {
        variant: "outlined",
      },
    },
    EVALUATE: {
      title: ({ t }) => {
        return t("common:are_you_sure");
      },
      warningText: () => {},
      promptDetail: () => {},
      modalBody: ({ t, isSubmitted, firstName }) => {
        return !isSubmitted
          ? t("common:evaluate_not_submitted_with_name", {
              name: firstName,
            })
          : null;
      },
      modalBodyStyle: {
        padding: "8px 64px 16px 48px",
      },
      confirmButtonText: "common:yes",
      cancelButtonText: "common:no",
    },
    EVALUATE_SHARE: {
      title: ({ t }) => {
        return t("common:are_you_sure");
      },
      warningText: () => {},
      promptDetail: () => {},
      modalBody: ({ t, isSubmitted, firstName }) => {
        return !isSubmitted
          ? t("common:evaluate_share_not_submitted_with_name", {
              name: firstName,
            })
          : null;
      },
      modalBodyStyle: {
        padding: "8px 64px 16px 48px",
      },
      confirmButtonText: "common:yes",
      cancelButtonText: "common:no",
    },
    SHARE: {
      title: ({ t }) => {
        return t("common:are_you_sure");
      },
      warningText: () => {},
      promptDetail: () => {},
      modalBody: ({ t, isSubmitted, firstName }) => {
        return !isSubmitted
          ? t("common:share_not_submitted_with_name", {
              name: firstName,
            })
          : null;
      },
      modalBodyStyle: {
        padding: "8px 64px 16px 48px",
      },
      confirmButtonText: "common:yes",
      cancelButtonText: "common:no",
    },
  };

  return DIALOGS_INFO;
};

export const getDistinctTurnInCount = _.memoize(
  params => _getDistinctTurnInCount(params),
  params => JSON.stringify(params)
);

const _getDistinctTurnInCount = ({ assignedStudents }) => {
  return _.reduce(
    assignedStudents,
    (result, student) => {
      const isEvaluated = _.get(student, "isEvaluated", false);
      const status = _.get(student, "submission.status", "");
      const conversation = _.get(student, "conversation", {});
      const unreadMessageCounts = _.get(
        conversation,
        "unreadMessageCounts",
        []
      );
      const count = _.get(_.first(unreadMessageCounts), "count", 0);

      const distinctTurnInCount =
        _.isEqual(status, "SUBMITTED") && count > 0 && !isEvaluated ? 1 : 0;

      return (result += distinctTurnInCount);
    },
    0
  );
};

export const getAllowAddLEToJournal = _.memoize(
  params => _getAllowAddLEToJournal(params),
  params => JSON.stringify(params)
);

const _getAllowAddLEToJournal = params => {
  const { response, toolResponses, attachmentGroups, toolHasEval } = params;

  const isResponseAdded = !_.isEmpty(response) || !_.isEmpty(attachmentGroups);
  const isToolResponsesAdded = toolHasEval ? !_.isEmpty(toolResponses) : false;

  return isResponseAdded || isToolResponsesAdded;
};

export const getSubjectNameMemoize = _.memoize(
  params => getSubjectName(params),
  params => JSON.stringify(params)
);

const getSubjectName = ({ subjects }) => {
  const subjectName = _.get(
    subjects,
    "resolvedMinimalTree.subjects[0].name",
    ""
  );

  return subjectName;
};

export const getIsCurrentDateSelected = _.memoize(
  params => _getIsCurrentDateSelected(params),
  params => JSON.stringify(params)
);

const _getIsCurrentDateSelected = ({ date }) => {
  if (_.isEmpty(date)) return false;
  return moment().isSame(moment(date, "YYYY-MM-DD"), "day");
};

export const getTemplateArrayMemoize = _.memoize(
  params => getTemplateArray(params),
  params => JSON.stringify(params)
);

const getTemplateArray = params => _.filter(params, param => !_.isEmpty(param));

export const getAllFieldsFromTemplate = _.memoize(
  params => _getAllFieldsFromTemplate(params),
  params => JSON.stringify(params)
);

const _getAllFieldsFromTemplate = ({ template }) => {
  const classroomEditSteps = _.get(template, "classroomEditSteps", []);
  const stepList = _.get(template, "step_list", {});

  const allTemplateFields = [];

  Object.entries(stepList).forEach(step => {
    // basicV2: { config: {...}, label: "Basic V2"}
    // stepKey -> basicV2 and stepValue : {config: {...}, label: "Basic V2"}
    const [stepKey, stepValue] = step;
    if (_.includes(classroomEditSteps, stepKey)) {
      const { fields = [] } = stepValue;
      if (!_.isEmpty(fields)) {
        allTemplateFields.push(...fields);
      }
    }
  });

  return allTemplateFields;
};

export const getHasAssessmentDataChanged = params => {
  const { assessmentId, unitPlanTemplateId } = params;

  const assessmentDetails = getAssessmentDetailsFromCache(assessmentId);
  const { templateId, allFields } = assessmentDetails;

  const templates = getPlannerTemplateFromCache(
    getTemplateArrayMemoize([templateId, unitPlanTemplateId]),
    true
  );

  const template = _.get(_.find(templates, { id: templateId }), "body", {});
  const allTemplateFields = getAllFieldsFromTemplate({ template });

  const getHasAssessmentDataChanged = _.reduce(
    allFields,
    (result, field) => {
      const { uid: fieldUID = "", value: fieldValue = [] } = field;

      if (_.includes(allTemplateFields, fieldUID)) {
        const isFieldEmpty = _.isNumber(fieldValue)
          ? fieldValue > 0
          : !_.isEmpty(fieldValue);
        return (result = result || isFieldEmpty);
      } else {
        return result;
      }
    },
    false
  );

  return getHasAssessmentDataChanged;
};

export const getScheduleOrDeadlineTime = _.memoize(
  params => _getscheduleOrDeadlineTime(params),
  params => JSON.stringify(params)
);

const _getscheduleOrDeadlineTime = params => {
  const {
    date,
    minuteStep = 15,
    is24HourFormat = true,
    defaultStartTime = "00:00",
  } = params;

  const isCurrentDateSelected = getIsCurrentDateSelected({
    date,
  });

  const startTime = isCurrentDateSelected
    ? moment().format("HH:mm")
    : defaultStartTime;

  const timelineLabels = getTimelineLabels({
    interval: minuteStep,
    period: "m",
    startTime,
    minuteDifference: 0,
  });

  const minTime = _.first(timelineLabels)?.value;

  if (is24HourFormat) {
    const minTimeIn24HourFormat = moment(minTime, "hh:mm a").format("HH:mm");

    return {
      time: minTimeIn24HourFormat,
      isCurrentDateSelected,
      startTime,
    };
  } else {
    return { time: minTime, isCurrentDateSelected, startTime };
  }
};

const evaluationColumnsHeaderStyle = {
  textAlign: "center",
  marginLeft: 0,
};

const ALL_SUBMISSIONS_COLUMNS_ARRAY = [
  {
    id: "student",
    localeKey: "classRoom:student_name",
  },
  {
    id: "attachments",
    localeKey: "classRoom:student_work",
  },
  {
    id: "assessmentTool",
    localeKey: "common:le_assessmentTool_label",
  },
  {
    id: "submission",
    localeKey: "common:submission",
    style: evaluationColumnsHeaderStyle,
  },
  {
    id: "evaluation",
    localeKey: "common:evaluation",
    style: evaluationColumnsHeaderStyle,
  },
  {
    id: "complete",
    localeKey: "common:complete",
    style: evaluationColumnsHeaderStyle,
  },
  {
    id: "shared",
    localeKey: "common:shared",
    style: evaluationColumnsHeaderStyle,
  },
  {
    id: "actions",
    localeKey: "",
  },
];

const getAllSubmissionsColumns = ({ canEvaluate }) => {
  return _.filter(ALL_SUBMISSIONS_COLUMNS_ARRAY, column => {
    switch (column?.id) {
      case "assessmentTool":
      case "evaluation":
      case "shared":
        return canEvaluate;
      case "complete":
        return !canEvaluate;
      default:
        return true;
    }
  });
};

export const getAllSubmissionsColumnsMemoized = _.memoize(
  getAllSubmissionsColumns,
  params => JSON.stringify(params)
);

const getAllSubmissionsStatus = ({
  columnId,
  t,
  isSubmittedVal = false,
  isTeacherEvaluationEnabled = false,
  isStudentEvaluationEnabled = false,
  isEvaluated = false,
  isSharedWithStudent = false,
  toolResponses = [],
  isEvaluationApplicable = false,
  isOverdue = false,
  isResubmissionRequested = false,
  teacherEvaluationTickClass,
}) => {
  let statusTooltip = "";
  let statusIcon = null;
  let showStatusIcon = isEvaluationApplicable;
  const statusTagState = {
    showTag: false,
    tagIcon: null,
    tagColor: "neutral",
    tagLabel: null,
  };
  const isStudentEvaluationDone = getIsStudentEvaluationDone(toolResponses);

  switch (columnId) {
    case "submission":
      statusTooltip = isSubmittedVal
        ? t("assessmentEvaluation:submission_turned_in")
        : t("classRoom:submission_pending");
      statusIcon = isSubmittedVal ? (
        <TickLargeOutlined variant="success" size="xx-small" />
      ) : (
        <WarningCircleOutlined disabled size="xx-small" />
      );
      statusTagState.showTag =
        !isSubmittedVal && (isOverdue || isResubmissionRequested);
      statusTagState.tagColor = isOverdue ? "yellow" : "neutral";
      statusTagState.tagLabel = isOverdue
        ? t("classRoom:overdue")
        : t("classRoom:resubmission");
      statusTagState.tagIcon = isOverdue ? (
        <ClockTimerOutlined size="xxxx-small" variant="warning" />
      ) : (
        <ReSubmissionRequestOutlined size="xxxx-small" />
      );
      break;
    case "evaluation":
      statusTooltip =
        (isStudentEvaluationEnabled && isStudentEvaluationDone) ||
        (isTeacherEvaluationEnabled && isEvaluated) ? (
          <>
            {isStudentEvaluationEnabled && (
              <>
                {t("assessmentEvaluation:name_evaluation_with_label", {
                  name: t("common:student"),
                  label: isStudentEvaluationDone
                    ? t("common:complete")
                    : t("common:pending"),
                })}
                <br />
              </>
            )}
            {isTeacherEvaluationEnabled &&
              t("assessmentEvaluation:name_evaluation_with_label", {
                name: t("common:teacher"),
                label: isEvaluated ? t("common:complete") : t("common:pending"),
              })}
          </>
        ) : (
          t("classRoom:evaluation_pending")
        );
      statusIcon =
        (isStudentEvaluationEnabled && isStudentEvaluationDone) ||
        (isTeacherEvaluationEnabled && isEvaluated) ? (
          <>
            {isStudentEvaluationEnabled && (
              <TickLargeOutlined
                variant="success"
                disabled={!isStudentEvaluationDone}
                size="xx-small"
              />
            )}

            {isTeacherEvaluationEnabled && (
              <TickLargeOutlined
                variant="success"
                disabled={!isEvaluated}
                size="xx-small"
                className={teacherEvaluationTickClass}
              />
            )}
          </>
        ) : (
          <WarningCircleOutlined disabled size="xx-small" />
        );
      break;
    case "complete":
      statusTooltip = isEvaluated
        ? t("assessmentEvaluation:marked_as_complete")
        : t("common:pending");
      statusIcon = isEvaluated ? (
        <TickLargeOutlined variant="success" size="xx-small" />
      ) : (
        <WarningCircleOutlined disabled size="xx-small" />
      );
      showStatusIcon = true;
      break;
    case "shared":
      statusTooltip = isSharedWithStudent
        ? t("assessmentEvaluation:evaluation_shared_with_students_and_parents")
        : t(
            "assessmentEvaluation:evaluation_not_shared_with_students_and_parents"
          );
      statusIcon = isSharedWithStudent ? (
        <TickLargeOutlined variant="success" size="xx-small" />
      ) : (
        <WarningCircleOutlined disabled size="xx-small" />
      );
      break;
    default:
      statusTooltip = "";
      break;
  }
  return {
    statusTooltip,
    statusIcon,
    statusTagState,
    showStatusIcon,
  };
};

export const getAllSubmissionsStatusMemoized = _.memoize(
  getAllSubmissionsStatus,
  params => JSON.stringify(params)
);

const getAllSubmissionsFilterIds = ({
  selectedSubmissionsCount,
  selectedStudentsSubmissions,
  assignedStudents,
}) => {
  const studentsSubmissions =
    selectedSubmissionsCount > 0
      ? selectedStudentsSubmissions
      : assignedStudents;

  const submissionsCanBeReturned = _.map(
    _.filter(
      studentsSubmissions,
      item =>
        _.isEqual(_.get(item, "submission.status", ""), "SUBMITTED") &&
        !_.get(item, "isEvaluated", false) &&
        _.get(item, "isSubmitted", false)
    ),
    submission => submission?.id
  );

  const submissionsCanBeShared = _.map(
    _.filter(
      studentsSubmissions,
      item =>
        _.get(item, "isEvaluationApplicable", false) &&
        _.get(item, "isEvaluated", false) &&
        !_.get(item, "isSharedWithStudent", false)
    ),
    submission => submission?.id
  );

  const submissionsCanBeUnshared = _.map(
    _.filter(
      studentsSubmissions,
      item =>
        _.get(item, "isEvaluationApplicable", false) &&
        _.get(item, "isEvaluated", false) &&
        _.get(item, "isSharedWithStudent", false)
    ),
    submission => submission?.id
  );

  const notSharedWithStudents = _.map(
    _.filter(
      studentsSubmissions,
      item => !_.get(item, "isSharedWithStudent", false)
    ),
    submission => submission?.id
  );

  const notSubmittedCount = _.get(
    _.filter(studentsSubmissions, item => !_.get(item, "isSubmitted", false)),
    "length",
    0
  );

  const notEvaluatedStudents = _.map(
    _.filter(studentsSubmissions, item => !_.get(item, "isEvaluated", false)),
    submission => submission?.id
  );

  const evaluationNotApplicableStudents = _.map(
    _.filter(
      studentsSubmissions,
      item => !_.get(item, "isEvaluationApplicable", false)
    ),
    submission => submission?.id
  );

  return {
    submissionsCanBeReturned,
    submissionsCanBeShared,
    submissionsCanBeUnshared,
    notSharedWithStudents,
    notSubmittedCount,
    notEvaluatedStudents,
    evaluationNotApplicableStudents,
  };
};

export const getAllSubmissionsFilterIdsMemoized = _.memoize(
  getAllSubmissionsFilterIds,
  params => JSON.stringify(params)
);
