import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { connect } from "react-redux";
import classes from "./DropzoneComponent.scss";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import {
  uploadFile,
  uploadFileV2,
  exceededSizeError,
  removeProgressElement,
} from "modules/Services";
import { getAttachmentSendObj, generateRandomId } from "Utils";
import { LockedScreenWrapper, DialogueBox } from "UIComponents";
import { setToastMsg } from "Login/modules/LoginModule";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import ACLStore from "lib/aclStore";

const DIALOGS_INFO = {
  /**
   *  This component cannot be localized as it is
   *  external and it's default prop is dialogueBoxInfo
   */

  CONFIRM: {
    title: "Bulk Upload File",
    message: () => (
      <div style={{ marginBottom: "16px", color: "red" }}>
        {`Are you sure you want to upload? This action can't be undone.`}
      </div>
    ),
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
  },
};
DIALOGS_INFO.CONFIRM.message.displayName = "CONFIRM_MESSAGE";
class DropzoneComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showLockScreen: false, showConfirmationDialogue: false };
    this.dropZoneRef = {};
  }

  customizedFileObject = async (file, item) => {
    let sendObj;
    if (this.props.customizedFileObject) {
      sendObj = await this.props.customizedFileObject(file, item);
    } else {
      sendObj = await getAttachmentSendObj(file, item);
    }
    return sendObj;
  };

  onDropRejected = e => {
    console.error("REJECTED", e);
    const { isCurriculumProgramFree, accept, setToastMsg } = this.props;
    if (isCurriculumProgramFree) {
      this.setState({ showLockScreen: true });
    } else {
      const unsupportedFiles = _.reduce(
        e,
        (files, item) => {
          if (!_.includes(accept, item?.type)) {
            return [...files, item?.name];
          }
          return files;
        },
        []
      );
      if (!_.isEmpty(unsupportedFiles)) {
        setToastMsg({
          msg: "toastMsgs:file_type_not_supported_with_file_names",
          locale_params: [
            {
              key: "files",
              value: _.join(unsupportedFiles, ", "),
              isPlainText: true,
            },
          ],
          type: "alert",
          position: "toast-top-center",
        });
      }
      if (_.size(e) > _.size(unsupportedFiles)) {
        this.props.exceededSizeError({ maxSize: this.props.maxSize });
      }
    }
  };

  onDrop = async (files, index) => {
    const that = this;
    const {
      parentType,
      parentId,
      shouldUpload,
      isAuthenticated,
      portalType,
      uploadType,
    } = that.props;

    const allPromises = _.map(files, async file => {
      const uploadId = generateRandomId();
      if (this.props.updateFilesCount) {
        this.props.updateFilesCount(1);
      }

      let sendObj = await this.customizedFileObject(file, `${file.preview}`);

      if (this.props.onDropContent) {
        this.props.onDropContent({ ...sendObj, file: file, id: uploadId });
      }
      try {
        if (shouldUpload) {
          let uploadStartTime = Date.now();
          const item = await this.props.uploadFileV2({
            file,
            parentType,
            parentId,
            attachment: sendObj,
            isAuthenticated,
            uploadId,
            portalType,
          });

          let uploadTime = Date.now() - uploadStartTime;
          const metadata = _.get(sendObj, "metadata", {});
          const updatedMetadata = { ...metadata, uploadTime };
          sendObj = { ...sendObj, metadata: updatedMetadata, url: item };
          if (this.props.onSingleContentUploaded) {
            this.props.onSingleContentUploaded(sendObj, uploadId, {
              parentId,
              parentType,
              uploadType,
            });
          }
          if (this.props.updateFilesCount) {
            this.props.updateFilesCount(-1);
          }
        } else {
          if (this.props.updateFilesCount) {
            this.props.updateFilesCount(-1);
          }
        }
        return sendObj;
      } catch (e) {
        if (this.props.updateFilesCount) {
          this.props.updateFilesCount(-1);
        }
        return null;
      }
    });
    const values = _.filter(await Promise.all(allPromises), urlObj => urlObj);
    if (this.props.onContentUploaded) {
      this.props.onContentUploaded(values); // Sends all files' sendObj together as an array
    }

    /**
 * @description : Old Uploading Code (Commented by Chaitu)
 
 */

    // let allPromises = files.map((file, key) => {
    //   if (that.props.updateFilesCount) {
    //     that.props.updateFilesCount(1);
    //   }
    //   return new Promise(async resolve => {
    //     let sendObj = { file };
    //     if (that.customizedFileObject) {
    //       sendObj = await that.customizedFileObject(file, `${file.preview}`);
    //     }
    //     let uploadObj = {};

    //     if (shouldUpload) {
    //       uploadObj = that.props.uploadFile({
    //         file,
    //         parentType,
    //         parentId,
    //         attachment: sendObj,
    //         isAuthenticated
    //       });
    //     }
    //     const { promise, uploadId } = uploadObj;
    //     if (that.props.onDropContent) {
    //       that.props.onDropContent({ ...sendObj, file: file, id: uploadId });
    //     }

    //     console.log("uploadObj", uploadObj, shouldUpload);

    //     if (shouldUpload) {
    //       promise
    //         .then(async item => {
    //           let sendObj = await that.customizedFileObject(file, item);

    //           if (that.props.onSingleContentUploaded) {
    //             await that.props.onSingleContentUploaded(sendObj, uploadId);
    //           }
    //           if (that.props.updateFilesCount) {
    //             that.props.updateFilesCount(-1);
    //           }
    //           console.log(sendObj, "sendObj");
    //           that.props.removeProgressElement({ id: uploadId });
    //           resolve(sendObj);
    //         })
    //         .catch(e => {
    //           console.log(e, "dropzone error");
    //           if (that.props.updateFilesCount) {
    //             that.props.updateFilesCount(-1, true);
    //           }
    //         });
    //     } else {
    //       if (that.props.updateFilesCount) {
    //         that.props.updateFilesCount(-1);
    //       }
    //       console.log(sendObj, "sendObj");
    //       resolve(sendObj);
    //     }
    //   });
    // });
    // console.log(allPromises, "allPromises");
    // Promise.all(allPromises)
    //   .then(values => {
    //     console.log(values, "sendObj");
    //     if (this.props.onContentUploaded) {
    //       this.props.onContentUploaded(values);
    //     }
    //   })
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  onCloseLockScreenModal = () => {
    this.setState({ showLockScreen: false });
  };
  toggleDialogueBoxDisplay = () =>
    this.setState({
      showConfirmationDialogue: !this.state.showConfirmationDialogue,
    });
  dialogueBoxConfirm = () => {
    this.dropZoneRef.open();
  };

  render() {
    const { showLockScreen, showConfirmationDialogue } = this.state;
    const {
      isDialogueBoxEnabled,
      dialogueBoxInfo,
      disableClick,
      dropzoneClass,
    } = this.props;

    const dropzoneClasses = classNames(classes.dropZone, dropzoneClass);
    return (
      <React.Fragment>
        <Dropzone
          accept={this.props.accept}
          onDrop={this.onDrop}
          className={dropzoneClasses}
          multiple={this.props.multiple}
          style={this.props.dropZoneStyle}
          maxSize={this.props.maxSize}
          onDropRejected={this.onDropRejected}
          disabled={this.props.mode == "view"}
          disableClick={
            this.props.mode == "view" || isDialogueBoxEnabled || disableClick
          }
          onFileDialogCancel={this.onClose}
          ref={ref => (this.dropZoneRef = ref)}
        >
          {this.props.renderComponent ? (
            <div
              className={classes.renderCompChild}
              onClick={() =>
                isDialogueBoxEnabled ? this.toggleDialogueBoxDisplay() : null
              }
            >
              {this.props.renderComponent}
            </div>
          ) : null}
        </Dropzone>
        {showLockScreen && (
          <LockedScreenWrapper
            featureKey={`FILE_SIZE_LIMIT`}
            screenType={"modal"}
            onClose={this.onCloseLockScreenModal}
          ></LockedScreenWrapper>
        )}
        {showConfirmationDialogue && isDialogueBoxEnabled && (
          <DialogueBox
            modalTitle={dialogueBoxInfo.title}
            onClickButton2={this.dialogueBoxConfirm}
            modalBody={dialogueBoxInfo.message()}
            toggleDialogueBoxDisplay={this.toggleDialogueBoxDisplay}
            button1={_.get(dialogueBoxInfo, "cancelButtonText", "")}
            button2={_.get(dialogueBoxInfo, "confirmButtonText", "")}
          />
        )}
      </React.Fragment>
    );
  }
}

DropzoneComponent.propTypes = {
  onContentUploaded: PropTypes.func,
  renderComponent: PropTypes.any,
  multiple: PropTypes.bool,
  dropZoneStyle: PropTypes.object,
  uploadFile: PropTypes.func,
  updateFilesCount: PropTypes.func,
  onSingleContentUploaded: PropTypes.func,
  onDropContent: PropTypes.func,
  mode: PropTypes.string,
  customizedFileObject: PropTypes.func,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  shouldUpload: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  dialogueBox: PropTypes.bool,
  dialogueBoxInfo: PropTypes.object,
  dropzoneClass: PropTypes.string,
};

DropzoneComponent.defaultProps = {
  accept: [
    "",
    "image/jpeg",
    "image/png",
    "image/jpg",
    "video/mp4",
    "video/quicktime",
    "audio/mp3",
    "audio/mpeg",
    "audio/m4a",
    "audio/x-m4a",
    "audio/vnd.wav",
    "audio/wav",
    "audio/aac",
    "audio/x-wav",
    "audio/vnd.dlna.adts",
    "audio/wave",
    "text/csv",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/x-iwork-numbers-sffnumbers",
    "application/x-iwork-keynote-sffkey",
    "application/x-iwork-pages-sffpages",
    "application/epub+zip",
    "application/zip",
  ],
  multiple: false,
  dropZoneStyle: {},
  mode: "edit",
  parentType: "",
  parentId: null,
  shouldUpload: true,
  isAuthenticated: true,
  isDialogueBoxEnabled: false,
  dialogueBoxInfo: DIALOGS_INFO.CONFIRM,
};

const mapActionCreators = {
  uploadFile,
  uploadFileV2,
  exceededSizeError,
  removeProgressElement,
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  let { maxSize } = ownProps;
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });
  maxSize = maxSize ? maxSize : isCurriculumProgramFree ? 10485760 : 209715200;
  return {
    isCurriculumProgramFree: state.login.userInfo.isCurriculumProgramFree,
    maxSize: ACLStore.can("FeatureFlag:DropzoneInfiniteFileSize")
      ? Infinity
      : maxSize,
  };
};
export default connect(mapStateToProps, mapActionCreators, null, {
  withRef: true,
})(DropzoneComponent);
