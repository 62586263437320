import React from "react";
import classes from "./AttachmentList.scss";
import AttachmentCard from "../AttachmentCard";
import { PdfLineIcon } from "SvgComponents";
import {
  AddCircleOutlined,
  ImageSquareOutlined,
  VideoOutlined,
  LinkOutlined,
} from "@toddle-design/web-icons";

import { colors } from "Constants";
import { DropzoneComponent, LinkModal, I18nHOC } from "UIComponents";

class AttachmentList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLinkModal: false,
    };
  }

  toggleLinkModal = () => {
    this.setState({ showLinkModal: !this.state.showLinkModal });
  };

  onLinkAdd = async ({ attachments, id }) => {
    const { addAttachment, addLocalAttachment } = this.props;

    addLocalAttachment({ ...attachments[0], id });
    addAttachment(attachments, id);
  };

  onContentUploaded = (params, uploadId) => {
    this.props.addAttachment([params], uploadId);
  };

  renderAttachmentFeed = () => {
    const { attachments, deleteAttachment, mode } = this.props;

    return _.map(attachments, (attachment, index) => {
      return (
        <AttachmentCard
          key={attachment.id}
          attachment={attachment}
          allAttachments={attachments}
          deleteAttachment={deleteAttachment}
          mode={mode}
        />
      );
    });
  };

  render() {
    const { mode, t } = this.props;

    const { showLinkModal } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.centeredContainer}>
          {this.renderAttachmentFeed()}
          <React.Fragment>
            {mode == "edit" ? (
              <div className={classes.addNewContainer}>
                <div className={classes.innerContainer}>
                  <AddCircleOutlined variant="subtle" size="x-small" />
                  <div className={classes.addMediaText}>
                    {t("common:add_with_label", {
                      label: t("common:lowercase", {
                        text: t("common:resource_label"),
                      }),
                    })}
                  </div>
                </div>
                <div className={classes.hoverContainer} />
                <div className={classes.hoverItemContainer}>
                  <DropzoneComponent
                    renderComponent={
                      <div>
                        <ImageSquareOutlined variant="subtle" size="xx-small" />
                        <div className={classes.hoverItemText}>
                          {t("common:add_with_label", {
                            label: t("common:lowercase", {
                              text: t("common:photo"),
                            }),
                          })}
                        </div>
                      </div>
                    }
                    onSingleContentUploaded={this.onContentUploaded}
                    onDropContent={this.props.addLocalAttachment}
                    accept={["image/jpeg", "image/png", "image/jpg"]}
                    multiple={true}
                  />
                </div>
                <div className={classes.hoverItemContainer}>
                  <DropzoneComponent
                    renderComponent={
                      <div>
                        <VideoOutlined variant="subtle" size="xx-small" />
                        <div className={classes.hoverItemText}>
                          {t("common:add_with_label", {
                            label: t("common:lowercase", {
                              text: t("common:video"),
                            }),
                          })}
                        </div>
                      </div>
                    }
                    onSingleContentUploaded={this.onContentUploaded}
                    onDropContent={this.props.addLocalAttachment}
                    accept={["video/mp4"]}
                    multiple={true}
                  />
                </div>
                <div className={classes.hoverItemContainer}>
                  <DropzoneComponent
                    renderComponent={
                      <div>
                        <PdfLineIcon
                          fill={colors.iconSubtle}
                          height={16}
                          width={16}
                        />
                        <div className={classes.hoverItemText}>
                          {t("common:add_with_label", {
                            label: t("common:lowercase", {
                              text: t("common:file"),
                            }),
                          })}
                        </div>
                      </div>
                    }
                    onSingleContentUploaded={this.onContentUploaded}
                    onDropContent={this.props.addLocalAttachment}
                    accept={[
                      "application/pdf",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "application/msword",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      "application/vnd.ms-powerpoint",
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                      "application/x-iwork-numbers-sffnumbers",
                      "application/x-iwork-keynote-sffkey",
                      "application/x-iwork-pages-sffpages",
                    ]}
                    multiple={true}
                  />
                </div>
                <div
                  className={classes.hoverItemContainer}
                  onClick={() => this.setState({ showLinkModal: true })}
                >
                  <LinkOutlined variant="subtle" size="xx-small" />
                  <div className={classes.hoverItemText}>{`${t(
                    "common:add"
                  )} link`}</div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        </div>
        {showLinkModal ? (
          <LinkModal
            onLinkAdd={this.onLinkAdd}
            onClose={this.toggleLinkModal}
          />
        ) : null}
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(AttachmentList);
