export default store => {
  return {
    path: "organizationPreset",
    getComponent(nextState, cb) {
      // require.ensure([],require => {
      const comp = require("./OrganizationManagement").default;
      cb(null, comp);
      // },"administrator-tooling");
    },
  };
};
