import React, { Fragment } from "react";
import classes from "./ClassSelectionModal.scss";
import { CancelIcon, CoachmarkChevronIcon } from "SvgComponents";
import { connect } from "react-redux";
import { getStaffCoursesQuery } from "Teacher/modules/TeacherQuery";
import { graphql, compose } from "react-apollo";
import { withLoader, UIModal, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import { getStaffCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import { withRouter } from "react-router";

const ClassSelectionModal = React.memo(props => {
  const { onClose, showInModal } = props;
  return (
    <Fragment>
      {showInModal ? (
        <UIModal isOpen={true} onRequestClose={onClose}>
          <ClassSelection {...props} />
        </UIModal>
      ) : (
        <ClassSelection {...props} />
      )}
    </Fragment>
  );
});

const ClassSelection = props => {
  const { onClose, courses, onClassSelect, t, showHeader, styles = {} } = props;
  return (
    <div className={classes.container} style={{ ...(styles.container || {}) }}>
      <div
        className={classes.scrollContainer}
        style={{ ...(styles.scrollContainer || {}) }}
      >
        {showHeader && (
          <div className={classes.header}>
            <div className={classes.heading}>
              {t("journal:select_grade_heading")}
            </div>
            <div className={classes.cancelIcon} onClick={onClose}>
              <CancelIcon width={16} height={16} />
            </div>
          </div>
        )}
        <div className={classes.list} style={{ ...(styles.list || {}) }}>
          {_.map(courses, course => {
            return (
              <ClassCard
                key={course.id}
                course={course}
                onClassSelect={onClassSelect}
              ></ClassCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ClassCard = React.memo(props => {
  const { course, onClassSelect } = props;
  const { title, id, grades } = course;

  const { globalGrade, id: gradeId } = grades[0] || {};
  const { constants } = globalGrade || {};
  const color = _.get(constants, "color", "") || colors.blue29;
  return (
    <div
      className={classes.item}
      style={{ borderLeftColor: color }}
      onClick={() => onClassSelect({ id, gradeId })}
    >
      <div className={classes.itemTitle}>{`${title}`}</div>
      <div className={classes.icon}>
        <CoachmarkChevronIcon></CoachmarkChevronIcon>
      </div>
    </div>
  );
});

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const curriculumProgramId = _.get(ownProps, "params.curriculumProgramId", "");
  const tabConfigurations = { admin: { curriculumProgramId } };
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });

  const userId = state.login.userInfo.id;

  return {
    isData: true,
    isLoading: false,
    userId: userId,
    isCurriculumProgramFree,
  };
};

ClassSelectionModal.defaultProps = {
  showInModal: true,
  showHeader: true,
};

export default compose(
  I18nHOC({ resource: ["journal"] }),
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  graphql(getStaffCoursesQuery, {
    name: "getStaffCourses",
    options: ({ userId, isCurriculumProgramFree }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        isCurriculumProgramFree,
      },
    }),
    props({
      getStaffCourses,
      ownProps: { userId, isData, isLoading, isCurriculumProgramFree },
    }) {
      const queryData = getStaffCoursesFromCache(
        userId,
        isCurriculumProgramFree
      );
      const allCourses = _.get(queryData, "courses", []);
      const courses = _.filter(allCourses, { isDemo: false });
      return {
        isData: !_.isEmpty(queryData) && isData,
        courses,
        isLoading:
          getStaffCourses["networkStatus"] == 1 ||
          getStaffCourses["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),

  withLoader
)(ClassSelectionModal);
