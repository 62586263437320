import React, { useRef } from "react";
import classes from "./VerifyParentInviteCode.scss";
import { FormTextInput } from "UIComponents";
import { Button } from "@toddle-design/web";

// This screen will never be used for family class invite and for invited family member via email
const VerifyParentInviteCode = props => {
  const {
    renderBackButtonComponent,
    updateInputField,
    data: { inviteCode } = {},
    customStyle: { containerStyle, headerStyle, subTextStyle } = {},
    updateCurrentScreen,
  } = props;

  const inputRef = useRef();

  // onClick functions
  const onBackClick = () => {
    updateCurrentScreen({ type: "parentLogin" });
    updateInputField({});
  };

  const onSubmitClick = () => {
    const { showErrorToast, handleVerifyParentInviteCode } = props;
    const errorCount = isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    handleVerifyParentInviteCode();
  };

  const isValid = () => {
    return inputRef.current.isValid();
  };

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.headerButtonContainer}>
        {renderBackButtonComponent({
          onClick: onBackClick,
          buttonText: "Family account",
          currentStep: 1,
          maxStep: 2,
        })}
      </div>
      <div style={headerStyle}> {"Create your Toddle account"} </div>
      <div style={subTextStyle}>
        {
          "View and celebrate your child's learning moments, communicate with teachers and receive school updates, all from your Toddle account"
        }
      </div>
      <React.Fragment>
        <div className={classes.inviteCodeContainer}>
          <FormTextInput
            size={"large"}
            textInputLabel={"Family invite code*"}
            value={inviteCode}
            name={"inviteCode"}
            updateInputField={updateInputField}
            error={"Enter Family invite code"}
            ref={inputRef}
          />
        </div>
        <div className={classes.bottomContainer}>
          <Button
            size={"large"}
            variant={"destructive"}
            isFullWidth={true}
            onClick={onSubmitClick}
          >
            {"Continue"}
          </Button>
        </div>
      </React.Fragment>
    </div>
  );
};

export default VerifyParentInviteCode;
