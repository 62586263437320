import React, { PureComponent } from "react";
import classes from "./UserNameAvatar.scss";
import PropTypes from "prop-types";
import {
  reduce as _reduce,
  indexOf as _indexOf,
  last as _last,
  first as _first,
  uniqBy as _uniqBy,
} from "lodash";
import { ProfileCascade, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import { ShareIcon } from "SvgComponents";
import { LinkWithTooltip } from "UIComponents";
import { compose } from "react-apollo";
import { UserDetails } from "AppComponents/ArchivedComponents";
import { Avatar } from "@toddle-design/web";
class UserNameAvatar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      id,
      name,
      profileImage,
      email,
      profileCellStyle,
      profileCascadeStyle,
      showTooltip,
      shouldDynamicColor,
      isArchived,
      t,
      profileTextStyle,
    } = this.props;

    return (
      <LinkWithTooltip tooltip={name} placement="top" isVisible={showTooltip}>
        <div className={classes.profileCell} style={profileCellStyle}>
          {id == "ALL" ? (
            <div className={classes.allIconContainer}>
              <ShareIcon fill={colors.gray48} />
            </div>
          ) : (
            <ProfileCascade
              items={[{ id, value: name, imageUrl: profileImage }]}
              customStyle={profileCascadeStyle}
              shouldDynamicColor={shouldDynamicColor}
              isArchived={isArchived}
            />
          )}
          <UserDetails
            email={email}
            name={name}
            isArchived={isArchived}
            viewMode={"NAME_AND_EMAIL"}
            profileTextStyle={profileTextStyle}
          />
        </div>
      </LinkWithTooltip>
    );
  }
}
UserNameAvatar.defaultProps = {
  profileCascadeStyle: { width: 32, height: 32 },
  showTooltip: true,
};
UserNameAvatar.propTypes = {
  profileCascadeStyle: PropTypes.object,
  showTooltip: PropTypes.bool,
};

export default I18nHOC({ resource: ["common"] })(UserNameAvatar);
