import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
export default store => ({
  path: "insights",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ProgressReport/modules/ProgressReportModule")
              .default;
            injectReducer(store, { key: "progressReport", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-progressreport"
        );
      })
  ),
  childRoutes: [],
});
