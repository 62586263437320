import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import classes from "./ShareSettingsStepV2.scss";
import { SwitchButton, I18nHOC, withLoader, UIModal } from "UIComponents";
import classNames from "classnames";
import update from "immutability-helper";
import ClassCardV2 from "../ClassCardV2";
import ACLStore from "lib/aclStore";
import { getSettingValue } from "modules/PermissionModule";
import {
  WarningTriangleOutlined,
  InformationOutlined,
  AddOutlined,
  FolderFilled,
} from "@toddle-design/web-icons";
import { Button, SelectDropdown, Tooltip } from "@toddle-design/web";
import {
  getShowAssessmentTool,
  getUpdatedCoursesStudentsForHLSL,
  getCourseStudents,
  checkAssessmentHasPypEvaluation,
  getScoreData,
  getSubjectNameMemoize,
  getScheduleOrDeadlineTime,
} from "ClassRoom/modules/utils";
import { getUpdatedArchivedFilters } from "Utils";
import {
  ASSESSMENT_TYPE_QUICK_TASK,
  CURRICULUM_TYPE_PYP,
} from "Constants/stringConstants";
import { colors } from "Constants";
import { getUserInfo } from "Login/modules/LoginModule";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getUserCoursesFilters } from "Course/modules/CourseModule";
import {
  getAssessmentDetailsQuery,
  getMultiCourseUserMapQuery,
} from "ClassRoom/modules/ClassRoomQuery";
import {
  getAssessmentDetailsFromCache,
  getMultiCourseUserMapFromCache,
} from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import { getCourseIdsMemoize } from "UnitPlans/components/IBcomponents/MaxScoreTool/Utils";
import { checkCategorySetApplicableTo } from "ScoreCategories/modules/ScoreCategoriesModule";
import AddClassModal from "../AddClassModal";

const updatedClassItem = ({ id, params, courses }) => {
  const index = _.findIndex(courses, { id });
  return update(courses, {
    [index]: { $set: { ...courses[index], ...params } },
  });
};

const getCourses = ({
  isUsingScoreAssessment,
  scoreCategoryId,
  courseIdsOfScoreCategorySet,
  stateCourses,
  isCourseApplicableToCategorySet,
}) => {
  // if score is enable and category is selected, then keep only relevant courses
  if (
    isCourseApplicableToCategorySet &&
    isUsingScoreAssessment &&
    scoreCategoryId
  ) {
    return _.filter(stateCourses, course => {
      return _.includes(courseIdsOfScoreCategorySet, _.get(course, "id"));
    });
  } else {
    return stateCourses;
  }
};

const getCoursesMemoize = _.memoize(
  params => getCourses(params),
  params => JSON.stringify(params)
);

const ShareSettingsStepV2 = React.memo(props => {
  const {
    curriculumProgram,
    isClassDiscussionEnabled,
    updateSelectedItems,
    courses: stateCourses,
    courseId,
    isStudentSubmissionEnabled,
    isStudentEvaluationEnabled,
    isTeacherEvaluationEnabled,
    contentType,
    t,
    genericFolders,
    portalType,
    assessmentToolAvailable,
    studentTemplates,
    selectedTimeSlot,
    leHasPypEvaluation,
    setDisableButton,
    mode,
    assignmentId,
    isSimilarityCheckEnabled,
    isTurnitinEnabledForGrade,
    isQt,
    isPrivate,
    folderNames,
    isUsingScoreAssessment,
    scoreCategoryId,
    scoreCategoryLabel,
    subjectName,
    courseIdsOfScoreCategorySet,
    assignmentTags,
    coursesUserMap,
    isCourseApplicableToCategorySet,
  } = props;
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [coursesSearchText, setCoursesSearchText] = useState("");
  const [selectedFolders, setSelectedFolders] = useState([]);

  const courses = getCoursesMemoize({
    isUsingScoreAssessment,
    scoreCategoryId,
    courseIdsOfScoreCategorySet,
    stateCourses,
    isCourseApplicableToCategorySet,
  });
  if (_.size(courses) !== _.size(stateCourses)) {
    updateSelectedItems({
      courses: [...courses],
    });
  }

  useEffect(() => {
    if (
      mode == "create" &&
      (assessmentToolAvailable || isUsingScoreAssessment)
    ) {
      updateSelectedItems({ isTeacherEvaluationEnabled: true });
    }

    // set previously selected folders to select dropdown
    if (!_.isEmpty(folderNames)) {
      const foldersWithValue = _.map(folderNames, folderName => {
        return { value: folderName };
      });

      setSelectedFolders(
        _.intersectionBy(genericFolders, foldersWithValue, "value")
      );
    }
  }, [assessmentToolAvailable, isUsingScoreAssessment]);

  //To filter out students based on HL/SL
  useEffect(() => {
    if (!_.isEmpty(coursesUserMap)) {
      const updatedCourses = getUpdatedCoursesStudentsForHLSL({
        courses,
        coursesUserMap,
      });
      updateSelectedItems({
        courses: updatedCourses,
      });
    }
  }, [coursesUserMap]);

  const enableClassroomPrivateSetting = ACLStore.can(
    "FeatureFlag:EnableClassroomPrivateSetting"
  );

  const allowTeacherEvaluationSettings =
    assessmentToolAvailable || leHasPypEvaluation || isUsingScoreAssessment;

  const curriculumType = _.get(curriculumProgram, "type", "IB_PYP");

  const getTeacherEvalSubTitle = () => {
    if (isQt) {
      return allowTeacherEvaluationSettings
        ? t("classRoom:enable_teacher_eval_qt")
        : t("classRoom:le_not_have_assessment_tool");
    } else
      return allowTeacherEvaluationSettings
        ? t("classRoom:enable_teacher_eval")
        : t("classRoom:le_not_have_assessment_tool_tagged_pyp_elements");
  };

  const onClickRemoveClass = id => {
    const removeIndex = _.findIndex(courses, course => course.id === id);
    updateSelectedItems({
      courses: update(courses, { $splice: [[removeIndex, 1]] }),
    });
  };

  const handleFolderChange = folders => {
    const selectedFoldersValue = _.map(folders, folder => {
      return _.get(folder, "value");
    });
    setSelectedFolders(folders);
    updateSelectedItems({
      folderNames: selectedFoldersValue ?? [],
    });
  };

  const handleOnAddClass = () => setShowAddClassModal(true);

  const closeModal = () => {
    setCoursesSearchText("");
    setShowAddClassModal(false);
  };

  const onClickAddClasses = params => {
    updateSelectedItems({
      courses: [...courses, ...params],
    });
    closeModal();
  };

  const getGenericFoldersWithIcons = () => {
    const genericFoldersWithIcons = _.map(genericFolders, folder => {
      const { color, ...rest } = folder;
      return {
        ...rest,
        icon: <FolderFilled size={"x-small"} style={{ color: color }} />,
      };
    });

    return genericFoldersWithIcons;
  };

  const updateClassCardItem = ({ params, courseId }) => {
    const deadlineDate = _.get(params, "deadlineDate", null);

    // if deadline date is current date then time returned is min time that can be set as due time
    // for example - if current time is 11:35 AM then min time that can be set as due time is 11:45
    // if deadline date is not current date then time returned is 9:00 am
    if (deadlineDate) {
      const { time } = getScheduleOrDeadlineTime({
        date: deadlineDate,
        defaultStartTime: "09:00 am",
      });

      params = {
        ...params,
        deadlineTime: time,
      };

      updateSelectedItems({
        courses: updatedClassItem({
          id: courseId,
          params,
          courses,
        }),
      });
    } else {
      updateSelectedItems({
        courses: updatedClassItem({
          id: courseId,
          params,
          courses,
        }),
      });
    }
  };

  const classRoomDiscussionCard = classNames({ [classes.settingCard]: true });

  const studentEvalSettingCard = classNames(
    { [classes.settingCard]: enableClassroomPrivateSetting },
    { [classes.settingCardLast]: !enableClassroomPrivateSetting }
  );

  const teacherEvalSettingTitle = classNames({
    [classes.settingTitle]: true,
    [classes.settingTitleDisabled]: !allowTeacherEvaluationSettings,
  });

  const studentEvalSettingTitle = classNames({
    [classes.settingTitle]: true,
    [classes.settingTitleDisabled]: !assessmentToolAvailable,
  });

  const showEvaluation =
    curriculumType !== "IB_MYP" || ACLStore.can("Common:MYPClassroomRating");

  const showSelectClass = mode === "create";

  return (
    <div className={classes.container}>
      <div className={classes.coursesContainer}>
        {_.isEmpty(courses) ? (
          <div>{t("common:no_class_added")}</div>
        ) : (
          <div className={classes.innerContainer}>
            <div className={classes.leftContainer}>
              <div className={classes.title}>
                {t("classRoom:classes_label")}
              </div>
              <div className={classes.desc}>
                {t("classRoom:settings_add_class_subtext")}
              </div>
            </div>

            <div className={classes.rightContainer}>
              <div className={classes.cardContainer}>
                <div className={classes.cardHeader}>
                  <div className={classes.headingTitleLabel}>
                    {t("common:class")}
                  </div>
                  <div className={classes.headingTitleLabel}>
                    {t("common:student_plural")}
                  </div>
                  <div className={classes.headingTitleLabel}>
                    {t("common:due_date_and_time")}
                  </div>
                </div>
                <div className={classes.cardInnerContainer}>
                  {_.map(courses, course => {
                    const courseStudents = getCourseStudents({
                      coursesUserMap,
                      courseId: course.id,
                    });
                    return (
                      <ClassCardV2
                        course={course}
                        key={course.id}
                        showDeadline={true}
                        courseId={courseId}
                        onClickRemoveClass={onClickRemoveClass}
                        updateItem={params =>
                          updateClassCardItem({ params, courseId: course?.id })
                        }
                        t={t}
                        portalType={portalType}
                        setDisableButton={setDisableButton}
                        mode={mode}
                        assignmentId={assignmentId}
                        assignmentTags={assignmentTags}
                        courseStudents={courseStudents}
                      />
                    );
                  })}
                </div>
                {showSelectClass && (
                  <div className={classes.addClassBtn}>
                    <Button
                      icon={<AddOutlined size={"xx-small"} />}
                      size={"medium"}
                      variant={"outlined-subtle"}
                      onClick={handleOnAddClass}
                    >
                      {t("common:add_with_label", {
                        label: t("common:class"),
                      })}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={classes.folderContainer}>
        <div className={classes.innerContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.title}>{t("common:folder")}</div>
          </div>
          <div className={classes.rightContainer}>
            <SelectDropdown
              options={getGenericFoldersWithIcons()}
              isMulti={true}
              size={"large"}
              placeholder={t("classRoom:enter_folder_name_to_select")}
              onChange={folders => handleFolderChange(folders)}
              isCreatable={true}
              value={selectedFolders}
              noOptionsText={t("common:add_with_label", {
                label: t("common:folder"),
              })}
            />
          </div>
        </div>
      </div>

      <div className={classes.innerContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.title}>{t("common:settings")}</div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.settings}>
            <div className={classes.settingCard}>
              <div className={classes.settingLeftContainer}>
                <div className={classes.settingTitle}>
                  {t("classRoom:online_submission")}
                </div>
                <Tooltip
                  tooltip={t("classRoom:enable_student_to_submit")}
                  placement={"bottom"}
                  id="tooltip-InformationOutlined"
                >
                  <InformationOutlined size={"xx-small"} variant={"subtle"} />
                </Tooltip>
              </div>
              <div className={classes.switch}>
                <SwitchButton
                  data-test-id={"switch-enabled-student-to-submit"}
                  isDisabled={_.get(studentTemplates, "length", 0) > 0}
                  updateInputField={value =>
                    updateSelectedItems({
                      isStudentSubmissionEnabled: value,
                      ...(!value && { isSimilarityCheckEnabled: value }),
                    })
                  }
                  value={isStudentSubmissionEnabled}
                />
              </div>
            </div>
          </div>

          <div className={classRoomDiscussionCard}>
            <div className={classes.settingLeftContainer}>
              <div className={classes.settingTitle}>
                {t("classRoom:class_discussion")}
              </div>
              <Tooltip
                tooltip={t("classRoom:enable_student_discussion")}
                placement={"bottom"}
                id="tooltip-InformationOutlined"
              >
                <InformationOutlined size={"xx-small"} variant={"subtle"} />
              </Tooltip>
            </div>
            <div className={classes.switch}>
              <SwitchButton
                data-test-id={"switch-enabled-student-discussion"}
                isDisabled={contentType == "ASSIGNMENT_DISCUSSION"}
                updateInputField={value =>
                  updateSelectedItems({ isClassDiscussionEnabled: value })
                }
                value={isClassDiscussionEnabled}
                onColor={colors.blue29}
              />
            </div>
          </div>
          {isTurnitinEnabledForGrade && isStudentSubmissionEnabled && (
            <div className={classes.settingCard}>
              <div className={classes.settingLeftContainer}>
                <div className={classes.settingTitle}>
                  {t("classRoom:check_for_similarity")}
                </div>
                <Tooltip
                  tooltip={t("classRoom:enable_similarity_check_subtext")}
                  placement={"bottom"}
                  id="tooltip-InformationOutlined"
                >
                  <InformationOutlined size={"xx-small"} variant={"subtle"} />
                </Tooltip>
              </div>
              <div className={classes.switch}>
                <SwitchButton
                  data-test-id={"switch-enabled-similarity-check"}
                  updateInputField={value =>
                    updateSelectedItems({
                      isSimilarityCheckEnabled: value,
                    })
                  }
                  value={isSimilarityCheckEnabled}
                />
              </div>
            </div>
          )}
          {contentType == "ASSESSMENT" && showEvaluation && (
            <div className={classes.settingCard}>
              <div className={classes.settingLeftContainer}>
                <div className={teacherEvalSettingTitle}>
                  {t("common:teacher_evaluation")}
                </div>
                <Tooltip
                  tooltip={getTeacherEvalSubTitle()}
                  placement={"bottom"}
                  id="tooltip-InformationOutlined"
                >
                  <InformationOutlined
                    size={"xx-small"}
                    variant={"subtle"}
                    disabled={!allowTeacherEvaluationSettings}
                  />
                </Tooltip>
              </div>
              <div className={classes.switch}>
                {allowTeacherEvaluationSettings ? (
                  <SwitchButton
                    data-test-id={"switch-enabled-teacher-evaluation"}
                    updateInputField={value =>
                      updateSelectedItems({ isTeacherEvaluationEnabled: value })
                    }
                    value={isTeacherEvaluationEnabled}
                  />
                ) : (
                  <div className={classes.warningTriangleStyle}>
                    <Tooltip
                      tooltip={t(
                        "common:no_label_selected_with_label_lowercase",
                        {
                          label: t("common:le_assessmentTool_label"),
                        }
                      )}
                      placement={"bottom"}
                      id="tooltip-InformationOutlined"
                    >
                      <WarningTriangleOutlined
                        size={"x-small"}
                        variant={"warning"}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          )}
          {contentType == "ASSESSMENT" && showEvaluation && (
            <div className={studentEvalSettingCard}>
              <div className={classes.settingLeftContainer}>
                <div className={studentEvalSettingTitle}>
                  {t("common:student_evaluation")}
                </div>
                <Tooltip
                  tooltip={
                    assessmentToolAvailable
                      ? t("classRoom:enable_student_eval")
                      : t("classRoom:le_not_have_assessment_tool")
                  }
                  placement={"bottom"}
                  id="tooltip-InformationOutlined"
                >
                  <InformationOutlined
                    size={"xx-small"}
                    variant={"subtle"}
                    disabled={!assessmentToolAvailable}
                  />
                </Tooltip>
              </div>
              <div className={classes.switch}>
                {assessmentToolAvailable ? (
                  <SwitchButton
                    data-test-id={"switch-enabled-student-evaluation"}
                    updateInputField={value =>
                      updateSelectedItems({ isStudentEvaluationEnabled: value })
                    }
                    value={isStudentEvaluationEnabled}
                  />
                ) : (
                  <div className={classes.warningTriangleStyle}>
                    <Tooltip
                      tooltip={t(
                        "common:no_label_selected_with_label_lowercase",
                        {
                          label: t("common:le_assessmentTool_label"),
                        }
                      )}
                      placement={"bottom"}
                      id="tooltip-InformationOutlined"
                    >
                      <WarningTriangleOutlined
                        size={"x-small"}
                        variant={"warning"}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          )}

          {enableClassroomPrivateSetting ? (
            <div className={classes.settingCardLast}>
              <div className={classes.settingLeftContainer}>
                <div className={classes.settingTitle}>
                  {t("classRoom:private_post")}
                </div>
                <Tooltip
                  tooltip={t("classRoom:post_private_teacher")}
                  placement={"bottom"}
                  id="tooltip-InformationOutlined"
                >
                  <InformationOutlined size={"xx-small"} variant={"subtle"} />
                </Tooltip>
              </div>
              <div className={classes.switch}>
                <SwitchButton
                  data-test-id={"switch-enabled-private-post"}
                  updateInputField={value =>
                    updateSelectedItems({ isPrivate: value })
                  }
                  value={isPrivate}
                  onColor={colors.blue29}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {showAddClassModal && (
        <UIModal
          isOpen={showAddClassModal}
          modalContent={classes.addClassModalContainer}
          onRequestClose={closeModal}
        >
          <AddClassModal
            curriculumProgram={curriculumProgram}
            onClickClose={closeModal}
            onClickAddClasses={onClickAddClasses}
            coursesSearchText={coursesSearchText}
            changeCoursesSeachText={setCoursesSearchText}
            courses={courses}
            portalType={portalType}
            selectedTimeSlot={selectedTimeSlot}
            courseIdsOfScoreCategorySet={courseIdsOfScoreCategorySet}
            scoreCategoryLabel={scoreCategoryLabel}
            subjectName={subjectName}
          />
        </UIModal>
      )}
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const curriculumProgram = _.get(ownProps, "curriculumProgram", {});
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  const turnitinEnabledGrades = getSettingValue({
    name: "TurnitinEnabledGrades",
    organizationId,
  });
  const selectedGrades = _.get(
    state,
    "teacher.selected_class.selected_grades",
    []
  );
  const isTurnitinEnabledForGrade = !_.isEmpty(
    _.intersection(turnitinEnabledGrades, selectedGrades)
  );

  const userInfo = getUserInfo({ portalType: ownProps.portalType }) || {};
  const courseId = _.get(ownProps.params, "course_id", undefined);

  const currentCurriculumProgramType =
    state.platform.currentCurriculumProgramType;
  const currentCurriculumProgramId = state.platform.currentCurriculumProgram.id;
  const organizationCurriculumPrograms =
    state.platform.organizationCurriculumPrograms;
  let staffCourseFilter = {};

  if (courseId) {
    const courseDetails = getCourseDetailsFromCache({
      id: courseId,
    });
    const curriculumProgramId = _.get(courseDetails, "curriculumProgram.id");
    staffCourseFilter = { curriculumProgramIds: [curriculumProgramId] };
  } else {
    staffCourseFilter = getUserCoursesFilters({
      currentCurriculumProgramType,
      organizationCurriculumPrograms,
      currentCurriculumProgramId,
    });
  }

  staffCourseFilter = getUpdatedArchivedFilters(
    {
      ...staffCourseFilter,
      searchText: ownProps.coursesSearchText,
    },
    {}
  );

  const courseIds = _.map(ownProps?.courses, item => item.id);
  const isCourseApplicableToCategorySet =
    checkCategorySetApplicableTo({
      organizationId,
    }) === "COURSE";

  return {
    isData: true,
    isLoading: false,
    curriculumProgram,
    isTurnitinEnabledForGrade,
    staffCourseFilter,
    userId: userInfo.id,
    courseIds,
    isCourseApplicableToCategorySet,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "classRoom"] }),
  connect(mapStateToProps),
  graphql(getAssessmentDetailsQuery, {
    skip: ({ contentType, leId }) => contentType != "ASSESSMENT" || !leId,
    name: "getAssessmentDetails",
    options: ({ leId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: leId,
      },
    }),
    props({
      getAssessmentDetails,
      ownProps: {
        leId,
        isData,
        isLoading,
        curriculumProgram,
        curriculumType,
        isCourseApplicableToCategorySet,
      },
    }) {
      const assessmentDetails = getAssessmentDetailsFromCache({
        id: leId,
      });

      const assessmentToolAvailable = getShowAssessmentTool(assessmentDetails);

      const leHasPypEvaluation = checkAssessmentHasPypEvaluation({
        assessmentDetails,
        curriculumProgramType: _.get(
          curriculumProgram,
          "type",
          curriculumType || CURRICULUM_TYPE_PYP
        ),
      });

      const isQt = _.isEqual(
        _.get(assessmentDetails, "type"),
        ASSESSMENT_TYPE_QUICK_TASK
      );

      const allFields = _.get(assessmentDetails, "allFields");
      const {
        scoreAssociatedEntities,
        isUsingScoreAssessment,
        scoreCategoryId,
        scoreCategoryLabel,
      } = getScoreData({
        allFields,
      });
      const courseIdsOfScoreCategorySet = getCourseIdsMemoize({
        associatedEntities: scoreAssociatedEntities,
        entityType: isCourseApplicableToCategorySet ? "Course" : "Grade",
      });

      const assignmentTags = _.get(
        _.find(allFields, field => {
          return _.get(field, "uid") === "subjectLevel";
        }),
        "value"
      );
      const subjects = _.find(allFields, field => field.uid === "subjects");
      const subjectName = getSubjectNameMemoize({ subjects });

      return {
        assessmentToolAvailable,
        isUsingScoreAssessment,
        scoreCategoryId,
        scoreCategoryLabel,
        subjectName,
        isQt,
        leHasPypEvaluation,
        courseIdsOfScoreCategorySet,
        assignmentTags,
        isData: !_.isEmpty(assessmentDetails) && isData,
        isLoading:
          getAssessmentDetails["networkStatus"] == 1 ||
          getAssessmentDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getMultiCourseUserMapQuery, {
    skip: ({ courseIds, assignmentTags }) =>
      _.isEmpty(courseIds) || _.isEmpty(assignmentTags),
    name: "getMultiCourseUserMap",
    options: ({ courseIds, portalType, assignmentTags }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: courseIds,
        portalType,
        filters: {
          tags: assignmentTags,
        },
      },
    }),
    props({
      getMultiCourseUserMap,
      ownProps: { courseIds, isData, isLoading, assignmentTags },
    }) {
      const coursesUserMap = getMultiCourseUserMapFromCache({
        ids: courseIds,
        filters: {
          tags: assignmentTags,
        },
      });
      return {
        isData: !_.isEmpty(coursesUserMap) && isData,
        coursesUserMap,
        isLoading:
          getMultiCourseUserMap["networkStatus"] == 1 ||
          getMultiCourseUserMap["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(ShareSettingsStepV2);
