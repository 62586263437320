export const FILTER_NAME_ACADEMIC_YEAR = "academicYears";
export const FILTER_NAME_GRADES = "grades";
export const FILTER_NAME_CREATORS = "createdBy";
export const FILTER_NAME_POST_TYPE = "types";
export const FILTER_NAME_POST_ASSESSMENT_TYPE = "assessmentTypes";
export const FILTER_NAME_SUBJECTS = "subjects";

export const POST_TYPE_FILTER_MAPPING = {
  ["LEARNING_ENGAGEMENT_MYP"]: {
    enumType: "LEARNING_ENGAGEMENT",
    assessmentType: "le",
  },
  ["FORMATIVE_ASSESSMENT_MYP"]: {
    enumType: "ASSESSMENT",
    assessmentType: "fmt",
  },
  ["SUMMATIVE_ASSESSMENT_MYP"]: {
    enumType: "ASSESSMENT",
    assessmentType: "smt",
  },
  ["LEARNING_ENGAGEMENT_UBD"]: {
    enumType: "LEARNING_ENGAGEMENT",
  },
  ["ASSESSMENT_UBD"]: {
    enumType: "ASSESSMENT",
  },
  ["LEARNING_ENGAGEMENT"]: {
    enumType: "LEARNING_ENGAGEMENT",
    assessmentType: "le",
  },
  ["QUICK_TASK"]: {
    enumType: "QUICK_TASK",
    assessmentType: "qt",
  },
  ["QUICK_TASK_UBD"]: {
    enumType: "QUICK_TASK",
  },
};
export const FILTER_NAME_UNIT_TYPE = "unitTypes";
