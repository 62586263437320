import React from "react";
import classes from "./ClassCard.scss";
import { colors } from "Constants";
import { getCountString } from "Utils";
import { I18nHOC } from "UIComponents";
import { TickPlain } from "SvgComponents";
import classNames from "classnames";

const ClassCard = React.memo(props => {
  const {
    course,
    onClassSelect,
    showCount,
    containerStyle = {},
    t,
    showSelectedIcon,
    selected,
    isMapleBearLikeOrg = false,
    showSubText = false,
    subText,
  } = props;
  const {
    title,
    grades = [],
    id,
    isArchived,
    unitPlans: { totalCount = 0 },
  } = course;

  const { globalGrade } = grades[0] || {};
  let { unitPlanCount = 0 } = grades[0] || {};

  if (isMapleBearLikeOrg) {
    unitPlanCount = totalCount;
  }

  const color = _.get(globalGrade, "constants.color", "") || colors.blue29;

  const unitPlanCountString = t("teacherHomePage:unit_plan_with_count", {
    count: unitPlanCount,
  });

  const classCardContainerClasses = classNames(classes.container, {
    archivedBg: isArchived,
  });
  return (
    <div
      data-test-id={`button-course-${course?.id}`}
      className={classCardContainerClasses}
      onClick={() => onClassSelect(id, false, "classList")}
      style={{ borderLeftColor: color, ...containerStyle }}
    >
      <div className={classes.classLabel}>{`${title}`}</div>
      {!!showCount &&
        (unitPlanCount > 0 ? (
          <div className={classes.unitPlanCount}>{unitPlanCountString}</div>
        ) : (
          <div className={classes.startPlanningText}>
            {t("teacherHomePage:start_planning")}
          </div>
        ))}
      {showSelectedIcon && selected && (
        <div className={classes.icon} style={{ background: color }}>
          <TickPlain fill={colors.white} height={8} width={8} />
        </div>
      )}
      {!!showSubText && <span> {subText}</span>}
    </div>
  );
});

export default I18nHOC({ resource: ["teacherHomePage"] })(ClassCard);
