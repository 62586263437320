import PropTypes from "prop-types";
import React from "react";
const ArrowIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 12 7">
      <g
        id="Page-1"
        stroke={props.stroke}
        strokeWidth="1"
        fillRule="evenodd"
        fill={props.fill && props.fill}
      >
        <g id="Collaborate-1" transform="translate(-992.000000, -191.000000)">
          <g id="Group" transform="translate(420.000000, 162.000000)">
            <polygon
              id="Triangle-2"
              transform="translate(578.000000, 32.966064) scale(1, -1) translate(-578.000000, -32.966064) "
              points="578 29.9660645 584 35.9660645 572 35.9660645"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
ArrowIcon.defaultProps = {
  width: 12,
  height: 7,
  stroke: "none",
};
ArrowIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default ArrowIcon;
