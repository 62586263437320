import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import moment from "moment";

import classes from "./ProductUpdatesItem.scss";
import {
  getTagStyles,
  getModalStyles,
  styles,
} from "./ProductUpdatesItemStyles";

import { I18nHOC, Tag, MediaModal } from "UIComponents";

import { copyToClipboard, getStringAutoLinks } from "Utils";

import { colors } from "Constants";
import { setToastMsg, openNewConversation } from "Login/modules/LoginModule";
import {
  filterAndSortCategories,
  getVisibleCategory,
} from "ProductUpdates/modules/ProductUpdatesModules";

import {
  LinkOutlined,
  EmailPlainOutlined,
  CaretDownFilled,
  CommentRemarkOutlined,
  ShareOutlined,
  ThumbsUpOutlined,
  ThumbsDownOutlined,
  ThumbsUpFilled,
  ThumbsDownFilled,
} from "@toddle-design/web-icons";

import {
  IconButton,
  Tooltip,
  Dropdown,
  DropdownMenu,
} from "@toddle-design/web";

const getOptions = t => [
  {
    label: t("common:copy_link"),
    icon: <LinkOutlined variant={"subtle"} />,
    key: "COPY_LINK",
  },
  {
    label: t("community:share_via_email"),
    icon: <EmailPlainOutlined variant={"subtle"} />,
    key: "EMAIL",
  },
];

const FeedbackButtonComp = props => {
  const { onFeedBackClick, t } = props;
  return (
    <Tooltip tooltip={t("common:share_feedback")} placement={"bottom"}>
      <IconButton
        onClick={onFeedBackClick}
        icon={<CommentRemarkOutlined size={"xxx-small"} />}
        variant={"outlined-subtle"}
      />
    </Tooltip>
  );
};

const CopyLinkButtonComp = props => {
  const { copyLink, t } = props;
  return (
    <Tooltip tooltip={t("common:copy_shareable_link")} placement={"bottom"}>
      <IconButton
        onClick={copyLink}
        icon={<LinkOutlined size={"xxx-small"} />}
        variant={"outlined-subtle"}
      />
    </Tooltip>
  );
};

const ProductUpdatesItem = props => {
  /**
   * source: from where ProductUpdatesItem is called
   * source can be
   * 1. ProductUpdates.js (value of source will be undefined)
   * 2. ProductUpdatesModal.js (value of source will be "MODAL")
   */
  const { item = {}, t, source, orgCurriculums } = props;
  const [showMediaModal, setShowMediaModal] = useState(false);

  const handleImageClick = () => {
    setShowMediaModal(!showMediaModal);
  };

  const modalStyles = getModalStyles(source);

  const onFeedBackClick = () => {
    const { title } = item;
    const prefilledMessage = t("common:sharing_feedback_for_label", {
      label: `- ${title}\n\n`,
    });
    openNewConversation(prefilledMessage);
  };

  const copyLink = () => {
    const { setToastMsg } = props;

    const locationHref = window.location?.href;
    const search = window.location?.search;

    let URL;
    if (!search) {
      URL = `${locationHref}?type=product_updates&productId=${item.id}`;
    } else {
      URL = locationHref;
    }

    const success = copyToClipboard(URL);
    if (!success) return;
    setToastMsg({
      msg: "toastMsgs:link_copy_success",
      type: "success",
      position: "toast-bottom-left",
    });
  };

  const onDropDownItemClick = e => {
    const { key } = e;
    const { openShareModal } = props;

    if (key === "EMAIL") {
      openShareModal(item);
    } else if (key === "COPY_LINK") {
      copyLink();
    }
  };

  // render functions
  const renderHeaderRowOne = () => {
    const { title, createdAt } = item;
    return (
      <div>
        <div className={classes.created}>
          {moment(createdAt).format("DD MMMM, YYYY")}
        </div>
        <div className={classes.headerRowOne}>
          <div className={classes.title} style={modalStyles.title}>
            {title}
          </div>
        </div>
      </div>
    );
  };

  const renderHeaderRowTwo = () => {
    const { categories } = item;

    const updatedCategories = filterAndSortCategories([...categories]);
    const visibleCategories = getVisibleCategory({
      categories: updatedCategories,
      orgCurriculums,
    });

    return (
      <div className={classes.headerRowTwo} style={modalStyles.headerRowTwo}>
        <div className={classes.leftContainer}>
          <div className={classes.tagList}>
            {_.map(visibleCategories, (category, i) => {
              const { title, color } = category || {};

              const tagStyles = getTagStyles(color);

              const renderDot = () => {
                // show dot only if..
                // 1. element is not last in array
                // 2. current element's group is not matching with next element's group
                if (
                  i !== visibleCategories?.length - 1 &&
                  category?.group !== visibleCategories[i + 1]?.group
                ) {
                  return <span className={classes.dot} />;
                }
              };

              return (
                <>
                  <Tag
                    item={{ label: title }}
                    showCloseButton={false}
                    styles={tagStyles}
                  />
                  {renderDot()}
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    const { description, imageUrl, id } = item;

    const attachments = [{ id: id, url: imageUrl, type: "IMAGE" }];

    const updatedDescription = getStringAutoLinks(description);

    const renderContentDiv = (
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{ __html: updatedDescription || "" }}
      />
    );

    return (
      <React.Fragment>
        {imageUrl && (
          <div
            onClick={handleImageClick}
            className={classes.poster}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        )}
        {showMediaModal ? (
          <MediaModal
            toggleMediaModal={handleImageClick}
            attachments={attachments}
          />
        ) : null}

        <div className={classes.seeMoreLess}>
          {renderContentDiv}
          {/* Comment out below code as of now (Might be helpful in future) */}
          {/* {source === "MODAL" ? (
            <>{renderContentDiv()}</>
          ) : (
            <SeeMoreLessHtml
              fullSource={updatedDescription}
              shouldLimitEmbeddedMedia={true}
            />
          )} */}
        </div>
      </React.Fragment>
    );
  };

  const renderFooter = () => {
    const { handleVote, userType } = props;
    const { votes } = item;
    const vote = _.get(votes, "myVote");

    const renderDropDown = () => {
      return (
        <Dropdown
          overlay={
            <DropdownMenu
              options={getOptions(t)}
              onClick={onDropDownItemClick}
            />
          }
          getPopupContainer={() => {
            return document.getElementById("product-update-feed-container");
          }}
          placement={"topRight"}
        >
          <Tooltip tooltip={t("common:share")} placement={"bottom"}>
            <IconButton icon={<ShareOutlined />} variant={"outlined-subtle"} />
          </Tooltip>
        </Dropdown>
      );
    };

    return (
      <div className={classes.footer}>
        <div className={classes.voteContainer}>
          <span className={classes.voteInfoText}>
            {t("common:like_this_feature_update")}
          </span>
          <IconButton
            onClick={() => handleVote({ ...item, vote }, "LIKE")}
            icon={
              vote === "LIKE" ? (
                <ThumbsUpFilled
                  variant="link"
                  style={{ color: colors.iconLink }}
                />
              ) : (
                <ThumbsUpOutlined variant="subtle" />
              )
            }
            variant={"plain"}
            style={{ marginRight: "8px" }}
          />
          <IconButton
            onClick={() => handleVote({ ...item, vote }, "DISLIKE")}
            icon={
              vote === "DISLIKE" ? (
                <ThumbsDownFilled
                  variant="critical"
                  style={{ color: colors.iconCritical }}
                />
              ) : (
                <ThumbsDownOutlined variant="subtle" />
              )
            }
            variant={"plain"}
          />
        </div>
        <div className={classes.rightFooter}>
          {userType === "staff" && (
            <div className={classes.rightFooterButton}>
              <FeedbackButtonComp t={t} onFeedBackClick={onFeedBackClick} />
            </div>
          )}

          <div className={classes.rightFooterButton}>
            {source !== "MODAL" && userType === "staff" ? (
              renderDropDown()
            ) : (
              <CopyLinkButtonComp t={t} copyLink={copyLink} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container} style={modalStyles.container}>
      {renderHeaderRowOne()}
      {renderHeaderRowTwo()}
      {renderBody()}
      {renderFooter()}
    </div>
  );
};

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = state => {
  const orgCurriculums = _.map(
    _.get(state, "platform.organizationCurriculumPrograms"),
    "type"
  );
  return {
    orgCurriculums,
    userType: state.login.userInfo.user_type,
  };
};

export default compose(
  I18nHOC({
    resource: ["common, community"],
  }),
  connect(mapStateToProps, mapActionCreators)
)(ProductUpdatesItem);
