import React from "react";
import classes from "./SearchDropDown.scss";
import StaffItem from "./StaffItem";
import CourseItem from "./CourseItem";
import StudentItem from "./StudentItem";
import { withLoader, I18nHOC } from "UIComponents";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getStaffListQuery,
  getCourseStaffGroupQuery,
} from "IBPlanner/modules/IBQuery";
import {
  getStaffListFromCache,
  getCourseStaffGroupFromCache,
} from "IBPlanner/modules/IBGraphqlHelpers";
import { getOrganizationCoursesStudents } from "Course/modules/CourseQuery";
import { getCourseAllStudentQuery } from "StudentPortfolio/modules/StudentPortfolioQuery";
import { getCourseAllStudentsFromCache } from "StudentPortfolio/modules/StudentPortfolioGraphqlHelpers";
import { getProjectGroupPortfolioStudentsFromCache } from "Projects/modules/ProjectGraphqlHelpers";
import { getOrganizationStudentListQuery } from "modules/CommonQuery";
import { getOrganizationStudentListFromCache } from "modules/CommonGraphqlHelpers";
import { getOrganizationCoursesStudentsFromCache } from "Course/modules/CourseGraphqlHelpers";
import _ from "lodash";
import { getProjectGroupStudentsQuery } from "Projects/modules/ProjectQueries";

const StaffListComp = ({
  selectedItems,
  staffList,
  onAddClick,
  t,
  courses,
  ownerId,
  searchMenuAddedText,
}) => {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitleText}>
        {t("common:teacher_plural")}
      </div>
      {_.map(staffList, (item, index) => {
        const isSelected =
          _.filter(selectedItems, function (o) {
            return o.id == item.id;
          }).length > 0;
        return (
          <StaffItem
            ownerId={ownerId}
            coursesSelectedIds={courses}
            item={item}
            isSelected={isSelected}
            key={item.id}
            onAddClick={onAddClick}
            searchMenuAddedText={searchMenuAddedText}
          />
        );
      })}
    </div>
  );
};

const StudentListComp = ({ selectedItems, studentList, onAddClick, t }) => {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitleText}>
        {t("common:student_plural")}
      </div>
      {_.map(studentList, (item, index) => {
        const isSelected =
          _.filter(selectedItems, function (o) {
            return o.id == item.id;
          }).length > 0;
        return (
          <StudentItem
            item={item}
            isSelected={isSelected}
            key={item.id}
            onAddClick={onAddClick}
          />
        );
      })}
    </div>
  );
};

const getCollaboratorList = course => {
  const collaborators = _.get(course, "collaborators.edges", []);

  return _.map(collaborators, collaborator => collaborator.node);
};

const CourseListComp = ({
  selectedItems,
  courseList,
  onAddClick,
  t,
  searchType,
}) => {
  return (
    <div className={classes.sectionContainer}>
      <div className={classes.sectionTitleText}>{t("common:group_plural")}</div>
      {_.map(
        _.filter(
          courseList,
          course => _.get(course, "collaborators.totalCount", 0) > 0
        ),
        (item, index) => {
          const collaborators = getCollaboratorList(item);
          const isSelected =
            _.differenceBy(collaborators, selectedItems, "id").length === 0;

          return (
            <CourseItem
              item={item}
              isSelected={isSelected}
              key={item.id}
              onAddClick={onAddClick}
              collaborators={collaborators}
              searchType={searchType}
            />
          );
        }
      )}
    </div>
  );
};

const NoDataComp = ({ t }) => {
  return (
    <div className={classes.noDataContainer}>
      <div className={classes.nodatatext}>{t("common:no_result_found")}</div>
    </div>
  );
};

class SearchDropDown extends React.PureComponent {
  render() {
    const {
      selectedItems,
      staffList,
      onAddClick,
      t,
      studentList,
      searchDropdownListContainerStyle,
      courses,
      ownerId,
      searchMenuAddedText,
      courseList,
      searchType,
    } = this.props;
    const courseListLength = _.get(courseList, "length", 0);
    const staffListLength = _.get(staffList, "length", 0);
    return (
      <div
        className={classes.listContainer}
        style={searchDropdownListContainerStyle}
      >
        {courseListLength > 0 && (
          <CourseListComp
            t={t}
            selectedItems={selectedItems}
            courseList={courseList}
            onAddClick={onAddClick}
            searchType={searchType}
          />
        )}
        {staffListLength > 0 && (
          <StaffListComp
            ownerId={ownerId}
            t={t}
            courses={courses}
            selectedItems={selectedItems}
            staffList={staffList}
            onAddClick={onAddClick}
            searchMenuAddedText={searchMenuAddedText}
          />
        )}
        {!_.isEmpty(studentList) && (
          <StudentListComp
            t={t}
            selectedItems={selectedItems}
            studentList={studentList}
            onAddClick={onAddClick}
          />
        )}
        {staffListLength == 0 &&
          courseListLength == 0 &&
          _.isEmpty(studentList) && <NoDataComp t={t} />}
      </div>
    );
  }
}

const filterHiddenCollaborators = ({ hideCollaborators, list }) => {
  return _.filter(list, item => !_.includes(hideCollaborators, item.id));
};

const getStaffsList = ({ queryData, hideCollaborators }) => {
  const staffs = _.map(
    _.get(queryData, "platform.organization.staff.edges", []),
    staff => staff.node
  );
  return filterHiddenCollaborators({ list: staffs, hideCollaborators });
};

const getCourseList = ({ queryData }) => {
  const courses = _.get(queryData, "courses.edges", []);
  return _.map(courses, course => course.node);
};

const getPortfolioStudents = ({ items, hideCollaborators }) => {
  const students = _.map(items, item => item.node);
  return filterHiddenCollaborators({ list: students, hideCollaborators });
};

const extractNodeFromItemList = ({ items, hideCollaborators }) => {
  const students = _.map(items, item => item.node);
  return filterHiddenCollaborators({ list: students, hideCollaborators });
};
const mapActionCreators = {};

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;
  return {
    isData: true,
    isLoading: false,
    organizationId,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlan", "common"] }),
  graphql(getStaffListQuery, {
    name: "getStaffList",
    skip: ({ searchListTypes }) => !_.includes(searchListTypes, "INDIVIDUAL"),
    options: ({ searchText, grades, portalType, courses, archivalFilters }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          searchText,
          grades,
          portalType,
          courses,
          ...archivalFilters,
        },
      };
    },
    props({
      getStaffList,
      ownProps: {
        isData,
        isLoading,
        searchText,
        grades,
        portalType,
        courses,
        archivalFilters,
        hideCollaborators,
      },
    }) {
      const data = getStaffListFromCache({
        searchText,
        grades,
        portalType,
        courses,
        ...archivalFilters,
      });
      return {
        staffList: getStaffsList({ queryData: data, hideCollaborators }),
        isData: !_.isEmpty(data) && isData,
        isLoading:
          getStaffList["networkStatus"] == 1 ||
          getStaffList["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  // graphql(getStaffListQuery, {
  //   name: "getStaffList",
  //   skip: ({ searchListTypes }) => !_.includes(searchListTypes, "INDIVIDUAL"),
  //   options: ({ searchText, grades, portalType }) => {
  //     return {
  //       fetchPolicy: "cache-and-network",
  //       variables: { searchText, grades, portalType }
  //     };
  //   },
  //   props({ getStaffList, ownProps: { isData, isLoading } }) {
  //     return {
  //       staffList: getStaffsList({ queryData: getStaffList }),
  //       isData: getStaffList.platform && isData,
  //       isLoading:
  //         getStaffList["networkStatus"] == 1 ||
  //         getStaffList["networkStatus"] == 2 ||
  //         isLoading
  //     };
  //   }
  // }),
  graphql(getCourseStaffGroupQuery, {
    name: "getCourseStaffGroup",
    skip: ({ searchListTypes }) => !_.includes(searchListTypes, "GROUP"),
    options: ({ searchText, grades, portalType }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: { searchText, grades, portalType },
      };
    },
    props({
      getCourseStaffGroup,
      ownProps: { isData, isLoading, searchText, grades, portalType },
    }) {
      const data = getCourseStaffGroupFromCache({
        searchText,
        grades,
        portalType,
      });
      return {
        courseList: getCourseList({
          queryData: _.get(data, "platform.organization", {}),
        }),
        isData: !_.isEmpty(data) && isData,
        isLoading:
          getCourseStaffGroup["networkStatus"] == 1 ||
          getCourseStaffGroup["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationCoursesStudents, {
    name: "getCourseStudentGroup",
    skip: ({ searchListTypes }) =>
      !_.includes(searchListTypes, "STUDENT_GROUP"),
    options: ({ searchText, grades, organizationId }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: { searchText, grades, organizationId },
      };
    },
    props({
      getCourseStudentGroup,
      ownProps: { isData, isLoading, searchText, grades, organizationId },
    }) {
      const data = getOrganizationCoursesStudentsFromCache({
        searchText,
        grades,
        organizationId,
      });

      return {
        courseList: getCourseList({
          queryData: data,
        }),
        isData: !_.isEmpty(data) && isData,
        isLoading:
          getCourseStudentGroup["networkStatus"] == 1 ||
          getCourseStudentGroup["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  //TODO: changes this should fetch course from organization and add courseIds in filters
  graphql(getCourseAllStudentQuery, {
    name: "getCourseOfStudents",
    skip: ({ searchListTypes, courses }) =>
      !_.includes(searchListTypes, "COURSE_STUDENTS") ||
      _.isEmpty(_.first(courses)),
    options: ({ courses, searchText, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: _.first(courses),
        filters: { searchText, portalType },
      },
    }),
    props({
      getCourseOfStudents,
      ownProps: { isData, isLoading, courses, searchText, hideCollaborators },
    }) {
      const courseData = getCourseAllStudentsFromCache({
        id: _.first(courses),
        filters: { searchText },
      });
      return {
        getCourseOfStudents,
        studentList: extractNodeFromItemList({
          items: _.get(courseData, "students.edges", []),
          hideCollaborators,
        }),
        isData: !_.isEmpty(courseData) && isData,

        isLoading:
          getCourseOfStudents["networkStatus"] == 1 ||
          getCourseOfStudents["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationStudentListQuery, {
    name: "getStudentList",
    skip: ({ searchListTypes }) =>
      !_.includes(searchListTypes, "ORGANISATION_STUDENTS"),
    options: ({ searchText, grades, courses, organizationId }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: { searchText, grades, courses, organizationId },
      };
    },
    props({
      getStudentList,
      ownProps: {
        isData,
        isLoading,
        searchText,
        grades,
        courses,
        organizationId,
        hideCollaborators,
      },
    }) {
      const queryData = getOrganizationStudentListFromCache({
        searchText,
        grades,
        courses,
        organizationId,
      });
      return {
        studentList: extractNodeFromItemList({
          items: _.get(queryData, "students.edges", []),
          hideCollaborators,
        }),
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getStudentList["networkStatus"] == 1 ||
          getStudentList["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getProjectGroupStudentsQuery, {
    name: "getProjectGroupStudents",
    skip: ({ searchListTypes }) =>
      !_.includes(searchListTypes, "PROJECT_GROUP_PORTFOLIO_STUDENTS"),
    options: ({ projectGroupId, searchText }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        projectGroupId,
        filters: { searchText },
      },
    }),
    props({
      getProjectGroupStudents,
      ownProps: {
        isData,
        isLoading,
        projectGroupId,
        hideCollaborators,
        searchText,
      },
    }) {
      const data = getProjectGroupPortfolioStudentsFromCache({
        projectGroupId,
        filters: { searchText },
      });
      return {
        studentList: getPortfolioStudents({
          items: _.get(data, "node.allStudents.edges", []),
          hideCollaborators,
        }),
        isData: !_.isEmpty(data) && isData,
        isLoading:
          _.includes([1, 2], getProjectGroupStudents["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  withLoader
)(SearchDropDown);

SearchDropDown.defaultProps = {
  searchType: "teacher",
};

SearchDropDown.propTypes = {
  searchType: PropTypes.string,
};
