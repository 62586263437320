/**--external--*/
import React, { useState, useRef, cloneElement } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import moment from "moment";
import classNames from "classnames";
/**--internal--*/
import { WarningTriangleOutlined } from "@toddle-design/web-icons";
import { InputTextField, DialogueBox, I18nHOC } from "UIComponents";
import { editCycle, deleteCycle } from "SnP_v2/modules/SnPModule";
/**--relative--*/
import {
  SNP_CARD_ICON_MAPPING,
  CYCLE_TYPE_LOCALE_MAPPING,
} from "../SnPCyclesFeedUtils";
import styles from "./CycleCardStyles";
import classes from "./CycleCard.scss";

const CycleCard = props => {
  const {
    cycle,
    feedType,
    onClickCard,
    editCycle,
    deleteCycle,
    isOldSnpCycle,
    isChecked,
    cycleActionsElement,
    cardStyles,
    t,
  } = props;

  const {
    id,
    title: initialTitle,
    isArchived,
    cycleType,
    curriculumProgram,
  } = cycle;

  const [showEditableTitle, toggleEditableTitle] = useState(false);
  const [title, changeTitle] = useState(initialTitle);
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const titleRef = useRef();

  const onClickCardLocal = () => {
    if (!isOldSnpCycle && !showEditableTitle) onClickCard({ id });
  };

  const toggleDialogueBox = () => toggleDeleteConfirmation(prev => !prev);

  const curriculumProgramId = curriculumProgram.id;

  const onClickDeleteCycle = async () => {
    await deleteCycle({
      id,
      cycleQueryFilters: { curriculumProgramId },
    });
  };

  const editCycleDetails = async ({ key, value }) => {
    try {
      await editCycle({
        cycleId: id,
        key,
        value,
        cycleQueryFilters: { curriculumProgramId },
      });
    } catch {
      changeTitle(initialTitle);
    }
  };

  const editCycleTitle = () => {
    toggleEditableTitle(false);

    /** reset if there are any errors or if title is the same */
    if (titleRef.current.isValid() || title === initialTitle) {
      changeTitle(initialTitle);
      return;
    }

    editCycleDetails({
      key: "title",
      value: title,
    });
  };

  const onDropDownItemClick = actionType => {
    switch (actionType) {
      case "ARCHIVE":
        editCycleDetails({
          key: "isArchived",
          value: true,
        });
        break;
      case "UNARCHIVE":
        editCycleDetails({
          key: "isArchived",
          value: false,
        });
        break;
      case "DELETE":
        toggleDeleteConfirmation(true);
        break;
      default:
        toggleEditableTitle(true);
        break;
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") editCycleTitle();
  };

  const getCycleActionsConfig = () => {
    switch (feedType) {
      case "EDIT":
        return {
          cycle,
          onDropDownItemClick,
        };
      case "SELECT":
        return {
          isChecked,
        };
      default:
        return {};
    }
  };

  const curriculumProgramType = curriculumProgram.type;

  const iconElement =
    SNP_CARD_ICON_MAPPING[
      isArchived ? `${curriculumProgramType}_ARCHIVED` : curriculumProgramType
    ];

  const typeLabel = t(CYCLE_TYPE_LOCALE_MAPPING[cycleType ?? "EVALUATION"]);

  const containerClasses = classNames(
    { [classes.container]: true },
    { [classes.disabledCard]: isOldSnpCycle }
  );

  const titleClasses = classNames("text-label-1", classes.title);
  const subTextClasses = classNames("text-subtitle-2", classes.standards);

  return (
    <>
      <div
        className={containerClasses}
        onClick={e => {
          if (!e.defaultPrevented) {
            onClickCardLocal();
          }
        }}
        style={cardStyles.container}
      >
        <div className={classes.cycleDetails}>
          <div className={classes.cardIcon} style={cardStyles.cardIcon}>
            {React.cloneElement(iconElement, { ...cardStyles.iconStyle })}
          </div>
          <div className={classes.labels} style={cardStyles.cardLabel}>
            {!isOldSnpCycle ? (
              showEditableTitle ? (
                <InputTextField
                  type={"text"}
                  autoFocus={true}
                  value={title}
                  inputStyle={styles.titleInputStyle}
                  updateInputField={changeTitle}
                  handleKeyPress={handleKeyPress}
                  onBlurInputField={editCycleTitle}
                  isRequired={true}
                  ref={titleRef}
                />
              ) : (
                <div className={titleClasses} style={cardStyles.title}>
                  {title}
                </div>
              )
            ) : (
              <>
                <span className={classes.warningText}>
                  <WarningTriangleOutlined
                    variant={"critical"}
                    size={"xx-small"}
                  />
                  {t("snp:old_snp_cycle_warning")}
                </span>
                <div className={titleClasses}>
                  {`${moment(_.get(cycle, "month"), "MM").format("MMMM")}
                  - 
                  ${_.get(cycle, "year")}`}
                </div>
              </>
            )}

            <div className={subTextClasses} style={cardStyles.standards}>
              {`${typeLabel}  |  `}
              {!isOldSnpCycle ? _.get(cycle, "set.title", "") : `IB SNP 2014`}
            </div>
          </div>
        </div>
        <div className={classes.cycleActions} style={cardStyles.cardActions}>
          {cycleActionsElement &&
            cloneElement(cycleActionsElement, getCycleActionsConfig())}
        </div>
      </div>
      {showDeleteConfirmation && (
        <DialogueBox
          showModal={showDeleteConfirmation}
          modalTitle={t("common:delete_with_label", { label: t("snp:cycle") })}
          modalBody={t("common:delete_msg_with_label", {
            label: t("snp:cycle"),
          })}
          toggleDialogueBoxDisplay={toggleDialogueBox}
          button1={t("common:cancel")}
          button2={t("common:delete_agree")}
          onClickButton2={onClickDeleteCycle}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { cycle } = ownProps;
  const { title, cycleType, set } = cycle;

  return {
    // If any of the fields which are set as required in new snp is empty
    // then treat it as an old cycle
    isOldSnpCycle: _.isEmpty(title) || _.isEmpty(cycleType) || _.isEmpty(set),
  };
};

const mapActionCreators = {
  editCycle,
  deleteCycle,
};

export default compose(
  I18nHOC({ resource: ["common", "snp"] }),
  connect(mapStateToProps, mapActionCreators)
)(CycleCard);

CycleCard.defaultProps = {
  cardStyles: {},
};
