import gql from "graphql-tag";

import { organizationSubjectsFragment } from "./MultiLevelNodeEditorFragments";
import {
  standardSetFragment,
  pypPlannerElementSetFragment,
} from "modules/CommonFragments";

export const getStandardBenchmarkSetQuery = gql`
  query getStandardBenchmarkSet($id: ID!, $organizationId: ID!) {
    planner {
      standardBenchmarkSet(id: $id, organizationId: $organizationId) {
        ...standardSetItem
      }
    }
  }
  ${standardSetFragment.standardSetDetails}
`;
export const getOrganizationSubjectsQuery = gql`
  query getOrganizationSubjects($id: ID!, $filters: SubjectFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationSubjectsFragment.organizationSubjects}
`;

export const getBenchmarkAnalyticsQuery = gql`
  query getBenchmarkAnalytics(
    $id: ID!
    $type: PYP_ANALYTICS_TYPE_ENUM!
    $grades: [ID!]
  ) {
    node(id: $id, type: PLANNER_BENCHMARK) {
      id
      ... on PlannerBenchmark {
        id
        analytics(type: $type, grades: $grades)
      }
    }
  }
`;

export const getBenchmarkStandardSetsQuery = gql`
  query getBenchmarkStandardSets($globalSubjects: [ID!], $searchText: String) {
    globalConstants {
      benchmarkStandardSets(
        globalSubjects: $globalSubjects
        searchText: $searchText
      ) {
        ...standardSetItem
      }
    }
  }
  ${standardSetFragment.standardSetFeed}
`;

export const getAllGlobalSubjectsQuery = gql`
  query getGlobalSubjects($filters: GlobalSubjectFilters) {
    platform {
      allGlobalSubjects(filters: $filters) {
        id
        title
      }
    }
  }
`;

export const getPypAtlSetQuery = gql`
  query getPypAtlSet($id: ID!, $grades: [ID!]) {
    node(id: $id, type: PYP_ATL) {
      ... on ATLSet {
        ...atlSetItem
      }
    }
  }
  ${pypPlannerElementSetFragment.atlSet}
`;
