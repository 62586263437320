// import { ApolloClient, createNetworkInterface, IntrospectionFragmentMatcher } from 'react-apollo';
import { ApolloClient } from "apollo-client-preset";
import { onError } from "apollo-link-error";
import { BatchHttpLink } from "apollo-link-batch-http";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link";
import { logoutUser, getUserInfo } from "Login/modules/LoginModule";
import { getCustomFetchFn } from "Utils/UrlHelpers";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from "apollo-cache-inmemory";
import { current_connection } from "modules/PusherModule";
import schema from "./schema.json";
import { store } from "./store/createStore";
import { split } from "apollo-link";
import { getExtensions } from "services/tracing";
import { Sentry } from "services/sentry";
import { onErrorLinkSentry } from "services/sentry/errors";
import { globalErrorHandler } from "services/sentry/globalErrorHandler";
import { isPublicPlatformRoute } from "Login/modules/LoginModule";
import { PUBLIC_USER_INFO } from "Constants";

const batchHttpLink = new BatchHttpLink({
  fetch: getCustomFetchFn(),
  includeExtensions: true,
});
const httpLink = createHttpLink({
  fetch: getCustomFetchFn(),
  includeExtensions: true,
});

// Checks if the user in localStorage and redux is same
const checkIsValidUser = ({ userInfo }) => {
  const { user_type } = userInfo;
  const isUserComingFromPublicAccess = _.get(
    store.getState(),
    "login.isUserComingFromPublicAccess",
    false
  );

  if (isUserComingFromPublicAccess) return true;

  const reduxUserId = _.get(store.getState(), "login.userInfo.id", {});
  const validIds = _.map(
    _.get(userInfo, "userIdentityDetails", []),
    item => item.id
  );
  switch (user_type) {
    case "staff":
      if (reduxUserId && !_.isEmpty(validIds)) {
        return _.includes(validIds, reduxUserId);
      }
      break;

    case "student":
      if (reduxUserId) {
        return reduxUserId == _.get(userInfo, "id", "");
      }
      break;
  }
  return true;
};

/** __FamilyPortal__
 * 1. When last remaining child is removed from family portal, we will need "parentToken" to add new child
 * 2. In FamilyPortal "parentToken" refers jwt of parent and "token" refers to jwt of child
 * Note: For student and teacher if condition will not be executed
 *
 * when user hit public route that time we send public jwt.
 */
const getToken = userInfo => {
  if (isPublicPlatformRoute()) {
    return `Bearer ${PUBLIC_USER_INFO.token}`;
  }
  if (
    _.get(userInfo, "user_type", "") === "parent" &&
    _.isEmpty(_.get(userInfo, "token", ""))
  ) {
    return `Bearer ${userInfo.parentToken}`;
  }
  return `Bearer ${userInfo.token}`;
};

const middlewareLink = setContext((request, previousContext) => {
  const trace = Sentry.getCurrentHub().traceHeaders();
  const extensions = getExtensions({ request });
  if (request?.extensions) {
    request.extensions.route = extensions.route;
    request.extensions.href = extensions.href;
  }
  return new Promise((success, fail) => {
    const state = store.getState();

    const loginPortalType = _.get(state, "login.portalType", null);

    const sampleOrgPortalType = _.get(
      state,
      "platform.sampleOrgPortalType",
      ""
    );
    const connectionId = _.get(current_connection, "connection.id", "");
    const portalType =
      _.get(request, "variables.portalType", "") ||
      sampleOrgPortalType ||
      loginPortalType;
    const userInfo = getUserInfo({ portalType });
    if (checkIsValidUser({ userInfo })) {
      success({
        headers: {
          authorization: userInfo ? getToken(userInfo) : null,
          "X-Tod-Connection-Id": connectionId,
          "X-Tod-Source": "WEB",
          ...trace,
        },
      });
    } else {
      window.location.href = "/";
      fail();
    }
  });
});

const errorLink = onError(errorHandler => {
  const { graphQLErrors, networkError, operation, response } = errorHandler;

  if (networkError && networkError.statusCode === 401) {
    logoutUser();
    return;
  }

  onErrorLinkSentry(errorHandler);
  globalErrorHandler(errorHandler);
});
const networkLink = split(
  operation => {
    const context = operation.getContext();
    return context.useHttpLink === true;
  },
  httpLink,
  batchHttpLink
);

const cache = new InMemoryCache({
  //resultCaching: false,
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema.data,
  }),
  dataIdFromObject: object => {
    switch (object.__typename) {
      case "BenchmarkLevel":
        return null; // use `key` as the primary key
      case "ATLLevel":
        return null; // use `key` as the primary key
      case "ConfigurablePermission":
        return null; // use `key` as the primary key
      case "StudentTranscript": {
        return null;
      }
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([middlewareLink, errorLink, networkLink]),
  cache,
});

export default client;
