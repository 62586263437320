import { routes } from "Projects/config/routes";
import { withAsyncRouteLoading } from "UIComponents";
import PortfolioDetailsStep from "./routes/PortfolioDetailsStep";
import Evidences from "./routes/Evidences";
import Interviews from "./routes/Interviews";
import StudentPortfolio from "./routes/StudentPortfolio";

const {
  portfolioDetails: { path },
} = routes;

export default store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((cb, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              cb(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [
      Evidences(store),
      Interviews(store),
      StudentPortfolio(store),
      PortfolioDetailsStep(store),
    ],
  };
};
