import PropTypes from "prop-types";
import React from "react";
import { Translation } from "react-i18next";
import "react-select/dist/react-select.css";
import Select from "react-select";
import { Creatable, Value } from "react-select";
import classes from "./SelectDropdown.scss";
import classNames from "classnames";
import { getRethumbUrl, getStringFromArray } from "Utils";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import ValueComponent from "./ValueComponent";
import ValueComponentWithIcon from "./ValueComponentWithIcon";

const getRethumb = ({ imageUrl, size = 24 }) => {
  const thumbnailUrl = imageUrl
    ? getRethumbUrl({
        width: size,
        height: size,
        imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${thumbnailUrl})`;
};

const OptionComponent = ({
  data,
  optionContainerStyle,
  optionLabelStyle,
  optionSubTextStyle,
  isMultiLineOption,
}) => {
  const { option, onFocus, onSelect, className } = data;
  const {
    icon,
    label,
    subText,
    iconType,
    optionIconSize,
    optionIconStyle = {},
  } = option;
  const optionLabelClass = classNames({
    [classes.optionLabel]: !isMultiLineOption,
    [classes.multiLineOptionLabel]: isMultiLineOption,
  });

  return (
    <div
      className={className}
      style={optionContainerStyle}
      onMouseEnter={event => onFocus(option, event)}
      onMouseMove={event => onFocus(option, event)}
      onMouseUp={event => {
        event.preventDefault();
        event.stopPropagation();
        onSelect(option, event);
      }}
    >
      {!_.isEmpty(icon) && (
        <div className={classes.optionIcon}>
          {_.isEqual(iconType, "COMPONENT") ? (
            <div className={classes.icon} style={optionIconStyle}>
              {React.cloneElement(icon, { size: optionIconSize })}
            </div>
          ) : (
            <div
              style={{
                background: getRethumb({
                  imageUrl: icon,
                  size: optionIconSize,
                }),
                ...optionIconStyle,
              }}
              className={classes.icon}
            />
          )}
        </div>
      )}
      <div className={optionLabelClass} style={optionLabelStyle}>
        {label}
      </div>
      {subText && (
        <div className={classes.optionSubtext} style={optionSubTextStyle}>
          {subText}
        </div>
      )}
    </div>
  );
};
class SelectDropdown extends UIBaseComponent {
  handleKeyPress = e => {
    if (e.key === "Enter") {
      const param = {};
      param[e.target.name] = e.target.value;
      if (this.props.onEnter) {
        this.props.onEnter(param);
      }
    }
  };
  getValue = (options, label) => {
    let value = `No ${label} Selected`;
    let valueList = [];
    if (!this.props.multi) {
      if (this.props.value) {
        value = _.find(options, { value: this.props.value }).label;
      }
    } else {
      valueList = _.map(this.props.value, (value, index) => {
        return _.find(options, { value }).label;
      });
      if (valueList.length > 0) {
        value = getStringFromArray({ nameArray: valueList });
      }
    }

    return value;
  };

  getViewValue = () => {
    const value = _.get(
      _.find(this.props.options, { value: this.props.value }),
      "label",
      ""
    );
    return value;
  };

  updateMultiValue = params => {
    const { isUpdateValueWithLabel } = this.props;
    if (isUpdateValueWithLabel) {
      this.updateValue(params, this.props.options);
    } else {
      if (_.isArray(params)) {
        const values = _.map(params, val => val.value);
        this.updateValue(values, this.props.options);
      } else {
        this.updateValue(_.get(params, "value", ""), this.props.options);
      }
    }
  };

  updateValueLocally = params => {
    const { multi } = this.props;
    if (multi) {
      this.updateMultiValue(params);
    } else {
      this.updateValue(_.get(params, "value", ""), this.props.options);
    }
  };

  renderEdit = () => {
    const { renderOptionsComponent, renderValueComponent } = this.props;
    const selectOption = classNames(
      { [classes.selectOption]: true },
      { [classes.errorBorder]: !!this.state.error }
    );
    let isDisabled = this.props.disabled;

    if (!this.props.areOptionsEmptyAllowed) {
      isDisabled = this.props.options.length == 0 || isDisabled;
    }

    const selectClassName = classNames(
      { [classes.container]: true },
      { [classes.disabledContainer]: isDisabled },
      { [classes.errorContainer]: !!this.state.error },
      { [this.props.containerClassName]: this.props.containerClassName }
    );

    const { error } = this.state;
    const {
      multi,
      placeholder,
      dropdownStyle,
      options,
      value,
      addButtonText,
      allowAddOption,
      clearable,
      menuContainerStyle,
      optionContainerStyle,
      optionLabelStyle,
      optionSubTextStyle,
      isMultiLineOption,
      errorStyle,
    } = this.props;
    const props = {
      ref: this.updateInputRef,
      multi,
      disabled: isDisabled,
      closeOnSelect: !multi,
      autoBlur: !multi,
      clearable,
      placeholder,
      style: {
        ...(dropdownStyle || {}),
        ...(error ? errorStyle || {} : {}),
      },
      backspaceRemoves: multi,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      options: options,
      menuContainerStyle: menuContainerStyle,
      onChange: this.updateValueLocally,
      value: value,
      className: selectOption,
      showNewOptionAtTop: false,
      promptTextCreator: inputValue => `${addButtonText} '${inputValue}'`,
      cacheOptions: true,
      defaultOptions: true,
      valueComponent: renderValueComponent
        ? renderValueComponent
        : data =>
            multi ? (
              <ValueComponent data={data} />
            ) : _.isEmpty(data.value.icon) ? (
              <Value {...data} />
            ) : (
              <ValueComponentWithIcon {...data} />
            ),
      optionComponent: data =>
        renderOptionsComponent ? (
          renderOptionsComponent(data)
        ) : (
          <OptionComponent
            data={data}
            optionContainerStyle={optionContainerStyle}
            optionLabelStyle={optionLabelStyle}
            optionSubTextStyle={optionSubTextStyle}
            isMultiLineOption={isMultiLineOption}
          />
        ),
      noResultsText: (
        <Translation>
          {(t, { i18n }) => t("common:noResultHeading")}
        </Translation>
      ),
    };

    return (
      <div className={selectClassName}>
        {allowAddOption ? <Creatable {...props} /> : <Select {...props} />}
      </div>
    );
  };
}

SelectDropdown.defaultProps = {
  ...UIBaseComponent.defaultProps,
  dropdownStyle: {},
  areOptionsEmptyAllowed: true,
  showHelper: false,
  options: [],
  multi: false,
  allowAddOption: false,
  addButtonText: "Add",
  optionContainerStyle: {},
  optionLabelStyle: {},
  optionSubTextStyle: {},
  isMultiLineOption: false,
  renderValueComponent: undefined,
  isAsync: false,
  clearable: false,
  isUpdateValueWithLabel: false,
};

SelectDropdown.propTypes = {
  ...UIBaseComponent.propTypes,
  multi: PropTypes.bool,
  options: PropTypes.array,
  areOptionsEmptyAllowed: PropTypes.bool,
  updateInputField: PropTypes.func,
  dropdownStyle: PropTypes.object,
  allowAddOption: PropTypes.bool,
  addButtonText: PropTypes.string,
  optionContainerStyle: PropTypes.object,
  optionLabelStyle: PropTypes.object,
  optionSubTextStyle: PropTypes.object,
  isMultiLineOption: PropTypes.bool,
  isUpdateValueWithLabel: PropTypes.bool,
};
export default SelectDropdown;
