import React, { PureComponent } from "react";
import classes from "./CountdownScreen.scss";
import { I18nHOC, UIModal } from "UIComponents";
import { compose } from "react-apollo";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

class CountdownScreen extends PureComponent {
  constructor(props) {
    super(props);
    const timeLimitInSec = props.timeLimit / 1000;
    this.countdownInstance = null;
    this.state = {
      countdown: timeLimitInSec,
    };
  }

  componentDidMount() {
    this.countdownInstance = setInterval(() => {
      this.setState(state => ({
        countdown: state.countdown - 1,
      }));
    }, 1000);
  }

  componentDidUpdate() {
    const { callback } = this.props;

    if (this.state.countdown == 0 && callback) {
      callback();
    }
  }

  componentWillUnmount() {
    clearInterval(this.countdownInstance);
  }

  render() {
    const { countdown } = this.state;
    const { t } = this.props;

    return (
      <UIModal isOpen={true} modalContent={classes.modalContent}>
        <div className={classes.countDownBox}>
          <Trans i18nKey="common:countdown_with_timeunit">
            <div className={classes.recordingInText}>
              {{ action: t("common:recording") }} in
            </div>
            <div className={classes.countText}>{{ countdown }}</div>
            <div className={classes.secondsText}>
              {{ timeunit: t("common:second_plural") }}
            </div>
          </Trans>
        </div>
      </UIModal>
    );
  }
}

export default compose(I18nHOC({ resource: "common" }))(CountdownScreen);

CountdownScreen.propTypes = {
  timeLimit: PropTypes.number,
  callback: PropTypes.func,
};

CountdownScreen.defaultProps = {
  timeLimit: 3000,
};
