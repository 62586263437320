import PropTypes from "prop-types";
import React from "react";

const DpCycleArchived = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H104V104H0V0Z" fill="#FFEDC1" />
      <g filter="url(#filter0_d_7740_8036)">
        <path
          d="M34 38.6667C34 37.1939 35.1939 36 36.6667 36H67.3333C68.8061 36 70 37.1939 70 38.6667V69.3333C70 70.8061 68.8061 72 67.3333 72H36.6667C35.1939 72 34 70.8061 34 69.3333V38.6667Z"
          fill="white"
        />
        <path
          d="M45 55L48.2929 58.2929C48.6834 58.6834 49.3166 58.6834 49.7071 58.2929L59 49"
          stroke="#FFAB00"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#filter1_d_7740_8036)">
          <path
            d="M45.334 36C45.334 35.2636 45.9309 34.6667 46.6673 34.6667H57.334C58.0704 34.6667 58.6673 35.2636 58.6673 36V38.6667H45.334V36Z"
            fill="#FFAB00"
          />
        </g>
        <path
          d="M43 32.3333C43 31.597 43.597 31 44.3333 31H59.6667C60.403 31 61 31.597 61 32.3333V39H43V32.3333Z"
          fill="#FFAB00"
        />
        <path
          d="M40 39L64 39"
          stroke="#FFAB00"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51 35C51 34.4477 51.4477 34 52 34C52.5523 34 53 34.4477 53 35C53 35.5523 52.5523 36 52 36C51.4477 36 51 35.5523 51 35Z"
          fill="#C28200"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7740_8036"
          x="18"
          y="31"
          width="52"
          height="57"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-8" dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.521569 0 0 0 0 0.34902 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7740_8036"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7740_8036"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7740_8036"
          x="42.6673"
          y="32"
          width="18.6666"
          height="9.33333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.33333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7740_8036"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7740_8036"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

DpCycleArchived.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

DpCycleArchived.defaultProps = {
  height: 104,
  width: 104,
};

export default DpCycleArchived;
