import gql from "graphql-tag";

export const progressReportCommentTemplateFragment = {
  progressReportCommentTemplateEdge: gql`
    fragment progressReportCommentTemplateItem on ProgressReportCommentTemplate {
      id
      title
      message
      isGlobal
      createdBy {
        id
      }
      grades {
        id
        name
      }
    }
  `,
  progressReportPageInfo: gql`
    fragment progressReportCommentTemplatePageInfo on PageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  `,
};
