import gql from "graphql-tag";
import { studentFragment, staffFragment } from "modules/CommonFragments";

import { unitPlanFragment, staffUnitPlanFragment } from "./IBFragments";

export const getStaffListQuery = gql`
  query getStaffList(
    $searchText: String
    $grades: [ID!]
    $courses: [ID!]
    $archivalState: ARCHIVAL_STATE_ENUM
    $includeArchived: IncludeArchivedFromModuleInput
  ) {
    platform {
      organization {
        id
        staff(
          filters: {
            searchText: $searchText
            grades: $grades
            courses: $courses
            archivalState: $archivalState
            includeArchived: $includeArchived
          }
        ) {
          totalCount
          edges {
            node {
              ...staffCoursesItem
            }
          }
        }
      }
    }
  }

  ${staffUnitPlanFragment.staffCourses}
`;

export const getCourseStaffGroupQuery = gql`
  query getCourseStaffGroup($searchText: String, $grades: [ID!]) {
    platform {
      organization {
        id
        courses(filters: { searchText: $searchText, grades: $grades }) {
          totalCount
          edges {
            node {
              id
              title
              collaborators: allCollaborators {
                totalCount
                edges {
                  node {
                    ...staffItem
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${staffFragment.basicStaffDetails}
`;

export const getUnitPlanQuery = gql`
  query getUnitPlan($id: ID!) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFragment.unitPlanDetails}
`;

export const getUnitSummaryPlanQuery = gql`
  query getUnitSummaryPlan($id: ID!) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        ...unitSummaryPlanItem
      }
    }
  }
  ${unitPlanFragment.unitSummaryPlanDetails}
`;

export const getUnitPlanCollaboratorsDetailsQuery = gql`
  query getUnitPlan($id: ID!) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFragment.unitPlanCollaboratorDetails}
`;
