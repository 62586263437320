import React from "react";
import PropTypes from "prop-types";

const ProgressReportCardIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 76 86"
      fill="none"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M21.7988 20.688C21.7988 18.0791 23.9137 15.9643 26.5225 15.9643H65.2894C67.8982 15.9643 70.0131 18.0791 70.0131 20.688V59.4548C70.0131 62.0637 67.8982 64.1786 65.2894 64.1786H26.5225C23.9137 64.1786 21.7988 62.0637 21.7988 59.4548V20.688Z"
          fill="white"
        />
        <path
          d="M35.1919 56.1429L35.1919 48.1071"
          stroke="#B0B0B0"
          strokeWidth="2.65709"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.8882 56.1429L41.8882 48.1071"
          stroke="#B0B0B0"
          strokeWidth="2.65709"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.5845 56.1429L48.5845 45.4286"
          stroke="#B0B0B0"
          strokeWidth="2.65709"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.2808 56.1429L55.2808 42.75"
          stroke="#B0B0B0"
          strokeWidth="2.65709"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.6204 30.6964L41.8883 42.75L37.8704 38.7321L33.8525 42.75"
          stroke={props.arrowStrokeColor}
          strokeWidth="2.06662"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.9238 30.6964H56.6203V37.3928"
          stroke={props.arrowStrokeColor}
          strokeWidth="2.06662"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.8525 10.2904C33.8525 8.986 34.91 7.92856 36.2144 7.92856H55.5978C56.9022 7.92856 57.9597 8.986 57.9597 10.2904V18.6428H33.8525V10.2904Z"
          fill="#FFAB00"
        />
        <path
          d="M29.2397 18.7916L61.3826 18.7916"
          stroke="#FFAB00"
          strokeWidth="2.36186"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.5669 14.625C44.5669 13.8853 45.1665 13.2857 45.9062 13.2857C46.6458 13.2857 47.2455 13.8853 47.2455 14.625C47.2455 15.3647 46.6458 15.9643 45.9062 15.9643C45.1665 15.9643 44.5669 15.3647 44.5669 14.625Z"
          fill="#C28200"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.542134"
          y="0.842994"
          width="76.5565"
          height="84.5923"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-7.08556" dy="7.08556" />
          <feGaussianBlur stdDeviation="7.08556" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.709804 0 0 0 0 0.388235 0 0 0 0 0.878431 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

ProgressReportCardIcon.defaultProps = {
  fill: "none",
  height: 183,
  width: 165,
  arrowStrokeColor: "#B563E0",
};

ProgressReportCardIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  arrowStrokeColor: PropTypes.string,
};

export default ProgressReportCardIcon;
