import React from "react";
import classes from "./ScreenBaseComponent.scss";
import classNames from "classnames";

const RIGHT_PANE_ANIMATION_TIME = 300;

class ScreenBaseComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentRightPane: "",
      rightPaneId: "",
      showRightPane: undefined,
    };
  }

  isFullScreenRightPane = () => {
    return false;
  };

  handleRightPane = ({ rightPane, id }) => {
    const { currentRightPane, rightPaneId } = this.state;
    if (rightPaneId == id && rightPane == currentRightPane) {
      this.updateShowRightPane(false);
      setTimeout(() => {
        this.updateRightPaneState({
          currentRightPane: "",
          rightPaneId: "",
        });
      }, RIGHT_PANE_ANIMATION_TIME);
      return;
    }
    if (currentRightPane) {
      this.updateShowRightPane(false);
      setTimeout(() => {
        if (!!rightPane) {
          this.updateShowRightPane(true);
        }
        this.updateRightPaneState({
          currentRightPane: rightPane,
          rightPaneId: id,
        });
      }, RIGHT_PANE_ANIMATION_TIME + 30);
    } else {
      this.updateShowRightPane(!!rightPane);
      if (!!!rightPane) {
        setTimeout(() => {
          this.updateRightPaneState({
            currentRightPane: "",
            rightPaneId: "",
          });
        }, RIGHT_PANE_ANIMATION_TIME);
      } else {
        this.updateRightPaneState({
          currentRightPane: rightPane,
          rightPaneId: id,
        });
      }
    }
  };

  customRightPaneScrollRef = ref => {
    this.rightPaneScroll = ref;
  };
  updateShowRightPane = val => {
    this.setState({ showRightPane: val });
    if (val && this.rightPaneScroll) {
      this.rightPaneScroll.goToScrollTop();
    }
  };
  updateRightPaneState = obj => {
    this.setState({ ...obj });
  };

  closeRightPane = () => {
    this.handleRightPane({ rightPane: "" });
  };

  renderMiddlePane = () => {
    return null;
  };

  getRightPaneComponent = () => {
    return null;
  };

  showRightPaneLine = () => {
    return true;
  };

  renderRightPane = () => {
    const isFullScreenRightPane = this.isFullScreenRightPane();
    const { currentRightPane, showRightPane } = this.state;
    const {
      rightPaneWidth,
      alwaysShowRightPane,
      rightPaneLineStyle,
      customRightPaneStyle = {},
    } = this.props;

    const rightPaneStyle = classNames(
      { [classes.rightPane]: true },
      { [classes.rightPaneFullScreen]: isFullScreenRightPane },
      {
        [classes.visible]:
          (showRightPane && showRightPane != undefined) || alwaysShowRightPane,
      },
      {
        [classes.hidden]:
          (!showRightPane && showRightPane != undefined) || alwaysShowRightPane,
      }
    );

    return (
      <React.Fragment>
        {!!isFullScreenRightPane && (
          <div className={classes.fullScreenOverlay} />
        )}
        <div
          className={rightPaneStyle}
          style={{
            ...customRightPaneStyle,
            width: rightPaneWidth,
            right: -rightPaneWidth,
          }}
        >
          {!!currentRightPane && this.showRightPaneLine() && (
            <div className={classes.rightPaneLine} style={rightPaneLineStyle} />
          )}
          {this.getRightPaneComponent()}
        </div>
      </React.Fragment>
    );
  };
}

export default ScreenBaseComponent;

ScreenBaseComponent.defaultProps = {
  rightPaneWidth: 440,
  alwaysShowRightPane: false,
};
