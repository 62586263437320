import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./Standard.scss";
import { exportData } from "Utils";
import axios from "axios";
import { colors } from "Constants";
import {
  getStandardFeedQuery,
  getAllGlobalSubjectsQuery,
} from "Tooling/modules/ToolingQueries";
import { getStandardFeedFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
import {
  withLoader,
  SearchBar,
  Loading,
  FullScreenLoader,
  UIButton,
  FilterDropdown,
  I18nHOC,
  DialogueBox,
} from "UIComponents";
import Header from "./components/Header";
import FeedList from "AppComponents/FeedList";
import { goToBack } from "modules/NavigationModule";
import {
  changeStandardSearchString,
  deleteStandard,
  updateSubjectFilters,
} from "Tooling/modules/ToolingModule";
import ManageStandard from "./components/ManageStandard";
import { checkScrollAtBottom, getBackendServerUrl } from "Utils";

const StandardList = compose(withLoader)(FeedList);
const parentStyle = {
  gridTemplateColumns: "40% 30% 17% 5%",
  gridColumnGap: "20px",
};
const columnList = () => [
  {
    label: "Title",
    id: "title",
    type: "TEXTCOLUMN",
  },
  {
    label: "Curriculum Name",
    id: "curriculumName",
    type: "TEXTCOLUMN",
  },
  {
    label: "Subjects",
    id: "globalSubjects",
    type: "ARRAYCOLUMNWITHOVER",
  },
  {
    label: "Actions",
    locale: "common:action_plural",
    id: "action",
    type: "ACTIONS",
  },
];

const styles = {
  buttonParentStyle: {
    border: `1px solid ${colors.strokeTwo}`,
    borderRadius: "4px",
  },
  subjectFilterBoxStyle: {
    height: 48,
    marginLeft: 16,
    borderRadius: 4,
  },
  subjectFilterTextStyle: {
    color: colors.gray48,
    fontSize: "1.6rem",
    lineHeight: "2.6rem",
  },
  subjectButtonDropDownContainerStyle: {
    top: 60,
    right: 0,
    minWidth: 200,
    maxHeight: 300,
    overflowY: "auto",
  },
};
const DIALOGS_INFO = {
  DELETE_STANDARD: {
    title: "Delete Standard",
    message: ({ title }) => {
      return (
        <div>
          <div style={{ marginBottom: "16px", color: `${colors.salmon60}` }}>
            {`Are you sure you want to delete the Standard? This action can't be undone.`}
          </div>
          <div>
            Standard title :{" "}
            <span className={classes.deleteStandardLabel}>{title}</span>
          </div>
        </div>
      );
    },
    confirmButtonText: "Delete",
    cancelButtonText: "Cancel",
  },
};

class Standard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isLoading: false,
      showDialogueBox: false,
      standardObj: {},
      dialogueBoxScreen: null,
      teacherModal: false,
      showCreateDialog: false,
      subjectFilter: "ALL",
      isOpenDeleteDialogBox: false,
    };
  }

  openModalCreateModeal = () => {
    this.setState({ mode: "create" });
  };

  closeModal = () => {
    this.setState({
      showDialogueBox: false,
      studentId: "",
      dialogueBoxScreen: null,
    });
  };

  changeSearchTerm = value => {
    this.props.changeStandardSearchString({ standards: value });
  };

  getActionMenu = staff => {
    const actions = [
      {
        label: "Edit Standard",
        clickAction: () => this.onClickEditStandard(staff),
        svg: null,
        isDisable: false,
      },
      {
        label: "Manage Subjects",
        svg: null,
        isDisable: false,
        clickAction: () => this.onClickManageStandardSubjects(staff),
      },
      {
        label: "Delete",
        svg: null,
        isDisable: false,
        clickAction: () => {
          this.setState({
            standardObj: staff,
            isOpenDeleteDialogBox: true,
          });
        },
      },
      {
        label: "Download",
        svg: null,
        isDisable: false,
        clickAction: () =>
          this.downloadCSV({ standardSetId: _.get(staff, "id") }),
      },
    ];
    return actions;
  };

  downloadCSV = async ({ standardSetId }) => {
    const accessToken = _.get(JSON.parse(localStorage.userInfo), "token", "");
    axios
      .post(
        getBackendServerUrl({
          token: accessToken,
          path: `/auth/tools/getStandardSetSnSCsvData`,
        }),
        {
          Authorization: `Bearer ${accessToken}`,
          standard_set_id: standardSetId,
        }
      )
      .then(response => {
        const data = _.get(response, "data.gradesData", {});
        exportData({ data, outputFormat: "CSV", fileName: data.filename });
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  onActionClick = ({ route, obj }) => {
    this.setState({
      showDialogueBox: true,
      dialogueBoxScreen: route,
      standardObj: obj,
    });
  };

  onClickEditStandard = standard => {
    this.onActionClick({
      route: "editStandardInfo",
      obj: standard,
    });
  };

  onClickManageStandardSubjects = standard => {
    this.onActionClick({
      route: "manageStandardSubjects",
      obj: standard,
    });
  };

  onClickCreateStandard = () => {
    this.onActionClick({
      route: "createStandard",
    });
  };

  getDialogueBox = () => {
    let mode, step, standardObject, editType, title;
    const { showDialogueBox, dialogueBoxScreen, standardObj } = this.state;
    if (showDialogueBox) {
      switch (dialogueBoxScreen) {
        case "editStandardInfo":
          standardObject = { standardObj };
          mode = "edit";
          editType = "detailsEdit";
          step = ["editStandard"];
          title = "Edit Standard";
          break;
        case "manageStandardSubjects":
          standardObject = { standardObj };
          mode = "edit";
          editType = "subjectsEdit";
          step = ["manageSubjects"];
          title = "Edit Standard";
          break;
        case "createStandard":
          standardObject = {};
          mode = "create";
          step = ["editStandard", "manageSubjects"];
          title = "Create Standard";
          break;
        default:
          return null;
      }

      return (
        <ManageStandard
          closeModal={this.closeModal}
          standardObject={standardObj}
          mode={mode}
          step={step}
          editType={editType}
          headerTitle={title}
          subjects={this.props.subjectsData}
          getStandardFeed={this.props.getStandardFeed}
        />
      );
    } else {
      return null;
    }
  };

  toggleDialogueBoxDisplay = () => {
    this.setState({
      isOpenDeleteDialogBox: false,
    });
  };
  onDialogConfirmClick = async () => {
    this.setState({
      isLoading: true,
    });
    const { id } = this.state.standardObj;
    await this.props.deleteStandard({
      id,
    });

    this.setState({
      isLoading: false,
    });
    this.toggleDialogueBoxDisplay();
  };
  openDeleteDialogBox = ({ id, title }) => {};
  getFeed = () => {
    const { standards, subjectsData } = this.props;
    return _.map(standards, standard => {
      var subjectNames = [];
      _.map(subjectsData, sub => {
        if (_.includes(standard.node.globalSubjects, sub.value)) {
          subjectNames.push({ id: sub.value, title: sub.label });
        }
      });
      const { id, title, description, globalSubjects, curriculumName } =
        _.get(standard, "node", {}) || {};

      return {
        id,
        title,
        description,
        curriculumName: curriculumName ? curriculumName : "-",
        globalSubjects: subjectNames,
        action: this.getActionMenu(standard.node),
      };
    });
  };

  onScroll = e => {
    const { hasNextPage, networkStatus, loadMoreResult } = this.props;
    const containerDiv = e.target;

    if (
      containerDiv &&
      hasNextPage &&
      networkStatus != 3 &&
      checkScrollAtBottom(containerDiv)
    ) {
      loadMoreResult();
    }
  };

  onItemClick = id => {
    const { onSwitchToStandard } = this.props;
    // onSwitchToStandard({ standardId: id });
  };

  getFeedCustomComponent = ({ column, columnData, id }) => {
    switch (column.id) {
      case "actionButton":
        return (
          <UIButton
            size="sm"
            color={"blue"}
            onClick={() => this.onItemClick(id)}
          >{`View`}</UIButton>
        );
    }

    return null;
  };
  onSubjectFilterDropDownItemClick = ({ selectedSubjectIds }) => {
    this.props.updateSubjectFilters({ selectedSubjectIds });
  };
  render() {
    const {
      isData,
      isLoading,
      totalCount,
      networkStatus,
      subjectsData,
      showCreateDialog,
      updateSubjectFilters,
      filters,
    } = this.props;

    const {
      isLoading: isStateLoading,
      isOpenDeleteDialogBox,
      standardObj,
    } = this.state;

    return (
      <div className={classes.container} onScroll={e => this.onScroll(e)}>
        <div className={classes.innerContainer}>
          <div className={classes.headerContainer}>
            <Header goToBack={this.props.goToBack}></Header>

            <UIButton
              color="pink"
              type={"hollow"}
              onClick={this.onClickCreateStandard}
            >
              {`Create Standard`}
            </UIButton>
          </div>
          <div className={classes.header}>
            <div className={classes.leftHeader}>
              <SearchBar
                shouldAnimate={false}
                wrapperType={"box"}
                name="searchText"
                placeholder={`Search by Title`}
                ref={ref => (this.searchBar = ref)}
                changeSearchTerm={this.changeSearchTerm}
                searchTerm={filters.searchText}
              />
            </div>
            <div className={classes.rightHeader}>
              <FilterDropdown
                updateInputField={this.onSubjectFilterDropDownItemClick}
                options={subjectsData}
                name={"selectedSubjectIds"}
                value={filters.subjects}
                label={"subject_one_or_more"}
                filterBoxStyle={styles.subjectFilterBoxStyle}
                filterTextStyle={styles.subjectFilterTextStyle}
                buttonDropDownContainerStyle={
                  styles.subjectButtonDropDownContainerStyle
                }
              />
            </div>
          </div>

          <div
            className={classes.standardCountContainer}
          >{`${totalCount} Standards`}</div>
          <StandardList
            parentStyle={parentStyle}
            columnList={columnList()}
            feedData={this.getFeed()}
            fetchMoreFeed={this.fetchMoreFeed}
            getCustomComponent={this.getFeedCustomComponent}
            isData={isData}
            isLoading={isLoading}
          />
        </div>
        {isOpenDeleteDialogBox && (
          <DialogueBox
            modalTitle={DIALOGS_INFO.DELETE_STANDARD.title}
            onClickButton2={this.onDialogConfirmClick}
            modalBody={DIALOGS_INFO.DELETE_STANDARD.message({
              title: standardObj.title,
            })}
            toggleDialogueBoxDisplay={this.toggleDialogueBoxDisplay}
            button1={DIALOGS_INFO.DELETE_STANDARD.cancelButtonText}
            button2={DIALOGS_INFO.DELETE_STANDARD.confirmButtonText}
          />
        )}
        {networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        {isStateLoading && <FullScreenLoader></FullScreenLoader>}
        {this.getDialogueBox()}
      </div>
    );
  }
}

const mapActionCreators = {
  goToBack,
  changeStandardSearchString,
  deleteStandard,
  updateSubjectFilters,
  // onSwitchToStandard,
};

const mapStateToProps = (state, ownProps) => {
  const { subjectFilter } = state.tooling;
  const filters = {
    searchText: state.tooling.searchString.standards,
    subjects: subjectFilter,
  };
  return {
    isData: true,
    isLoading: false,
    mode: ownProps.mode,
    Authorization: state.login.userInfo.jwt,
    filters,
  };
};

export default compose(
  I18nHOC({ resource: ["toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getStandardFeedQuery, {
    name: "getStandardFeed",
    options: ({ filters }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: { filters },
      };
    },
    props({ getStandardFeed, ownProps: { isData, isLoading, filters } }) {
      const standardDetails = getStandardFeedFromCache({ filters });

      const standards = _.get(standardDetails, "edges", []);
      const totalCount = _.get(standardDetails, "totalCount", 0);

      return {
        totalCount,
        standards,
        getStandardFeed,
        hasNextPage: _.get(standardDetails, "pageInfo.hasNextPage", false),
        networkStatus: getStandardFeed["networkStatus"],
        isData: !_.isEmpty(standardDetails) && isData,
        isLoading:
          getStandardFeed["networkStatus"] == 1 ||
          getStandardFeed["networkStatus"] == 2 ||
          isLoading,
        loadMoreResult: () => {
          return getStandardFeed.fetchMore({
            query: getStandardFeedQuery,
            variables: {
              after: standardDetails.pageInfo.endCursor,
              filters,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousFeed =
                previousResult.planner.standardBenchmarkSetFeed;
              const nextFeed = fetchMoreResult.planner.standardBenchmarkSetFeed;

              const previousEdges = previousFeed.edges;
              const nextEdges = nextFeed.edges;

              const previousPageInfo = previousFeed.pageInfo;
              const nextPageInfo = nextFeed.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;
              return {
                ...previousResult,
                planner: {
                  ...previousResult.planner,
                  standardBenchmarkSetFeed: {
                    ...previousResult.planner.standardBenchmarkSetFeed,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(getAllGlobalSubjectsQuery, {
    name: "getAllGlobalSubjectsQuery",
    props: ({ getAllGlobalSubjectsQuery, ownProps: { isData, isLoading } }) => {
      const queryData = _.get(getAllGlobalSubjectsQuery, "platform", {});

      const subjectsData = _.map(
        _.get(queryData, "allGlobalSubjects"),
        ({ id, title }) => {
          return { label: title, value: id };
        }
      );
      return {
        subjectsData,
        isData: !_.isEmpty(subjectsData) && isData,
        isLoading:
          getAllGlobalSubjectsQuery["networkStatus"] == 1 ||
          getAllGlobalSubjectsQuery["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(Standard);
