import gql from "graphql-tag";
import {
  unitPlanFragment,
  assessmentFragment,
  leCollectionFragment,
  peopleFragment,
} from "./CommunityParentEntityFragments";
import { tagsOnBoardingFragment } from "OnBoarding/modules/OnBoardingFragments";
export const getCommunityCommonFeedQuery = gql`
  query getCommunityCommonFeed(
    $id: ID!
    $entityTypes: [COMMUNITY_ENTITY_TYPES_ENUM!]
    $searchText: String
    $first: Int
    $after: String
    $feedType: [COMMUNITY_FEED_ENUM]
    $filters: UserCommunityEntityFilter
    $seed: Float
  ) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        communityEntities(
          entityTypes: $entityTypes
          first: $first
          after: $after
          searchText: $searchText
          feedType: $feedType
          filters: $filters
          seed: $seed
        ) {
          totalCount
          edges {
            isPremium
            isNew
            node {
              id
              ... on UnitPlan {
                id
                ...unitPlanItem
              }
              ... on Assessment {
                id
                ...assessmentItem
              }
              ... on LECollection {
                id
                ...leCollectionItem
              }
              ... on Staff {
                id
                ...peopleItem
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${unitPlanFragment.unitPlanFeed}
  ${assessmentFragment.assessmentFeed}
  ${leCollectionFragment.leCollectionFeed}
  ${peopleFragment.peopleFeed}
`;

export const getCommunityBannerFeedQuery = gql`
  query getCommunityBannerFeed(
    $id: ID!
    $entityTypes: [COMMUNITY_ENTITY_TYPES_ENUM!]
    $searchText: String
    $first: Int
    $after: String
    $feedType: [COMMUNITY_FEED_ENUM]
    $filters: UserCommunityEntityFilter
    $seed: Float
  ) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        communityEntities(
          entityTypes: $entityTypes
          first: $first
          after: $after
          searchText: $searchText
          feedType: $feedType
          filters: $filters
          seed: $seed
        ) {
          edges {
            node {
              id
              ... on UnitPlan {
                id
                ...unitPlanItemBannerFeed
              }
            }
          }
        }
      }
    }
  }
  ${unitPlanFragment.unitPlanItemBannerFeed}
`;

export const getCommunityGroupFeedQuery = gql`
  query getCommunityGroupFeed($id: ID!, $seed: Float) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        communityLanes(seed: $seed) {
          label
          laneFilter {
            type
            entity {
              id
              ... on Tag {
                ...tagInfo
              }
            }
          }
          communityEntities {
            totalCount
            edges {
              isPremium
              isNew
              node {
                id
                ... on UnitPlan {
                  id
                  ...unitPlanItem
                }
                ... on Assessment {
                  id
                  ...assessmentItem
                }
                ... on LECollection {
                  id
                  ...leCollectionItem
                }
              }
            }
          }
        }
      }
    }
  }
  ${tagsOnBoardingFragment.tag}
  ${unitPlanFragment.unitPlanFeed}
  ${assessmentFragment.assessmentFeed}
  ${leCollectionFragment.leCollectionFeed}
`;

export const getCommunityCombineFeedQuery = gql`
  query getCommunityCombineFeed(
    $id: ID!
    $searchText: String
    $unitsFirst: Int
    $lesFirst: Int
    $educatorsFirst: Int
    $leCollectionsFirst: Int
    $seed: Float
    $feedType: [COMMUNITY_FEED_ENUM]
    $showEducators: Boolean!
    $showLeCollections: Boolean!
  ) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        units: communityEntities(
          entityTypes: UNIT_PLAN
          first: $unitsFirst
          searchText: $searchText
          seed: $seed
          feedType: $feedType
        ) {
          totalCount
          edges {
            isPremium
            isNew
            node {
              id
              ... on UnitPlan {
                ...unitPlanItem
              }
            }
          }
        }
        les: communityEntities(
          entityTypes: ASSESSMENT
          first: $lesFirst
          searchText: $searchText
          seed: $seed
          feedType: $feedType
        ) {
          totalCount
          edges {
            isPremium
            isNew
            node {
              id
              ... on Assessment {
                ...assessmentItem
              }
            }
          }
        }
        educators: communityEntities(
          entityTypes: STAFF
          first: $educatorsFirst
          searchText: $searchText
          seed: $seed
          feedType: $feedType
        ) @include(if: $showEducators) {
          totalCount
          edges {
            isPremium
            isNew
            node {
              id
              ... on Staff {
                ...peopleItem
              }
            }
          }
        }
        lecollections: communityEntities(
          entityTypes: LE_COLLECTION
          first: $leCollectionsFirst
          searchText: $searchText
          seed: $seed
          feedType: $feedType
        ) @include(if: $showLeCollections) {
          totalCount
          edges {
            isPremium
            isNew
            node {
              id
              ... on LECollection {
                id
                ...leCollectionItem
              }
            }
          }
        }
      }
    }
  }
  ${unitPlanFragment.combineUnitPlanFeed}
  ${assessmentFragment.assessmentFeed}
  ${peopleFragment.peopleFeed}
  ${leCollectionFragment.leCollectionFeed}
`;

export const getCollectionQuery = gql`
  query getCollection($id: ID!) {
    node(id: $id, type: LE_COLLECTION) {
      id
      ... on LECollection {
        id
        name
        assessments {
          totalCount
          edges {
            isPremium
            isNew
            node {
              id
              ... on Assessment {
                id
                ...assessmentItem
              }
            }
          }
        }
      }
    }
  }
  ${assessmentFragment.assessmentFeed}
`;

export const getUnitPlanQuickViewQuery = gql`
  query getUnitPlanQuickView($id: ID!, $uids: [String!]) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        ...unitPlanQuickViewItem
      }
    }
  }
  ${unitPlanFragment.unitPlanQuickViewDetails}
`;
