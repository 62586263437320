import update from "immutability-helper";
import client from "apolloClient";
import {
  createPostMutation,
  updatePostMutation,
  updateAssignmentToJournalMutation,
} from "Post/modules/PostMutations";
import {
  getCoursePostFeedQuery,
  getCourseAllStudentQuery,
} from "Post/modules/PostQuery";
import { generateRandomId } from "Utils";

import { setToastMsg } from "Login/modules/LoginModule";
import { getPostDetailsFromCache } from "Post/modules/PostGraphqlHelpers";
import { uploadFileV2, createAttachmentGroups } from "modules/Services";
import { updateFilterObject } from "Journal/modules/JournalModule";
import _ from "lodash";
import { CLOUD_URL } from "Constants";
import {
  getPracticesV2InputForPost,
  getAddedPracticesV2,
  getRemovedPracticesV2,
  isJournalCardRevamp,
  hasTranslationPermission,
  getPostTranslationFilter,
} from "Post/utils";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import {
  getStudentAssignmentDetailsQuery,
  getAssignmentAllSubmissionQuery,
} from "ClassRoom/modules/ClassRoomQuery";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getCourseDetailsQuery } from "Course/modules/CourseQuery";
import { ATTACHMENT_OMIT_ATTRIBUTES } from "store/static";

export const NAME = "post";

export const UPDATE_POST = "UPDATE_POST" + " " + NAME;
export const UPDATE_STUDENT_LIST_FILTERS =
  "UPDATE_STUDENT_LIST_FILTERS" + " " + NAME;
export const RESET_POST = "RESET_POST" + " " + NAME;

export const POST_TYPE_DETAILS = [
  {
    value: "PUBLISHED",
    label: "Published",
    key: "published",
    orderBy: "PUBLISHED_AT",
    user: "student",
  },
  {
    value: "DRAFT",
    label: "Draft",
    key: "draft",
    orderBy: "PUBLISHED_AT",
    user: "staff",
  },
  {
    value: "IN_APPROVAL",
    label: "In Approval",
    key: "inApproval",
    orderBy: "PUBLISHED_AT",
    user: "student",
  },
];

export const TAGGABLE_PYP_ELEMENTS = [
  {
    label: "common:atls_label",
    value: "atls",
    isNestedView: true,
    startDepth: 1,
  },
  {
    label: "common:lp_label",
    value: "lp",
    isNestedView: false,
  },
  {
    label: "common:concept_plural",
    value: "concepts",
    isNestedView: false,
  },
  {
    label: "common:content_standards",
    value: "benchmarks",
    isNestedView: true,
    startDepth: 0,
  },
  {
    label: "common:action_label",
    value: "action",
    isNestedView: false,
  },
];

export const TAGGABLE_DP_ELEMENTS = [
  {
    label: "common:aim_label",
    value: "aimDP",
    isNestedView: true,
    startDepth: 1,
  },
  {
    label: "common:objectives_label",
    value: "objectivesDP",
    isNestedView: true,
    startDepth: 1,
  },
];

export const TAGGABLE_MYP_ELEMENTS = [
  {
    label: "common:myp_objectives",
    value: "objectivesMYP",
    isNestedView: true,
    startDepth: 1,
    elementType: "MYP_OBJECTIVES",
    isGlobal: false,
  },
  {
    label: "common:atls_label",
    value: "atlsMYP",
    isNestedView: true,
    startDepth: 1,
    elementType: "MYP_ATL",
    //isGlobal: true,
    isGradeSpecific: true,
  },
  {
    label: "common:concept_plural",
    value: "conceptsMYP",
    isNestedView: false,
    elementType: "MYP_KEY_CONCEPT",
    isGlobal: true,
  },
  {
    label: "common:related_concepts",
    value: "relatedConceptsMYP",
    isNestedView: false,
    elementType: "MYP_RELATED_CONCEPT",
    isGlobal: false,
  },
  {
    label: "common:lp_label",
    value: "lpMYP",
    isNestedView: false,
    elementType: "MYP_LEARNER_PROFILE",
    isGlobal: true,
  },
  {
    label: "common:content_standards",
    value: "learningStandardMYP",
    isNestedView: true,
    startDepth: 0,
    elementType: "MYP_LEARNING_STANDARD",
    isGlobal: false,
    isGradeSpecific: true,
    isSubjectSpecific: true,
  },
];

export const TAGGABLE_UBD_ELEMENTS = [
  {
    label: "common:skill_plural",
    isDynamicPlannerElementLabel: true,
    value: "atlsUBD",
    isNestedView: true,
    startDepth: 1,
    elementType: "UBD_ATL",
    isGradeSpecific: true,
  },
  {
    label: "common:content_standards",
    isDynamicPlannerElementLabel: true,
    value: "learningStandardUBD",
    isNestedView: true,
    startDepth: 0,
    elementType: "UBD_LEARNING_STANDARD",
    isGradeSpecific: true,
    isSubjectSpecific: true,
  },
];

export const TAGGABLE_ELEMENTS_FIELD_LIST = {
  IB_MYP: {
    atlsMYP: {
      config: {
        plannerElementConfig: {
          type: "MYP_ATL",
        },
        fieldTypeConfig: {
          rootNodeDepth: 1,
        },
        type: "PLANNER_ELEMENT",
      },
      viewType: "Nestedview",
    },
    objectivesMYP: {
      config: {
        plannerElementConfig: {
          type: "MYP_OBJECTIVES",
          subType: "PERSONAL_OBJECTIVES",
        },
        fieldTypeConfig: {
          rootNodeDepth: 2,
        },
        type: "PLANNER_ELEMENT",
      },
      viewType: "Nestedview",
    },
    disciplinaryObjectivesMYP: {
      config: {
        plannerElementConfig: {
          type: "MYP_OBJECTIVES",
          subType: "PERSONAL_OBJECTIVES",
        },
        fieldTypeConfig: {
          rootNodeDepth: 2,
        },
        type: "PLANNER_ELEMENT",
      },
      viewType: "Nestedview",
    },
    conceptsMYP: {
      config: {
        type: "PLANNER_ELEMENT",
        fieldTypeConfig: {
          multi: true,
        },
        plannerElementConfig: {
          type: "MYP_KEY_CONCEPT",
          filteredBy: ["subjectGroup"],
          shouldPrefetch: true,
        },
      },
    },
    learningStandardMYP: {
      type: "NestedSelectComponent",
      config: {
        icon: "PicnLearningStandards",
        type: "PLANNER_ELEMENT",

        fieldTypeConfig: {
          rootNodeDepth: 0,
        },

        plannerElementConfig: {
          type: "MYP_LEARNING_STANDARD",
        },
      },
      viewType: "Nestedview",
    },
    disciplinaryLearningStandardMYP: {
      type: "NestedSelectComponent",
      config: {
        icon: "PicnLearningStandards",
        type: "PLANNER_ELEMENT",

        fieldTypeConfig: {
          rootNodeDepth: 0,
        },

        plannerElementConfig: {
          type: "MYP_LEARNING_STANDARD",
        },
      },
      viewType: "Nestedview",
    },
    lpMYP: {
      type: "MultiSelect",
      config: {
        type: "PLANNER_ELEMENT",

        fieldTypeConfig: {
          multi: true,
        },
        hasAddRemoveValues: true,
        plannerElementConfig: {
          type: "MYP_LEARNER_PROFILE",
        },
      },
    },
    relatedConceptsMYP: {
      type: "NestedSelectComponent",
      config: {
        type: "PLANNER_ELEMENT",
        fieldTypeConfig: {
          rootNodeDepth: 0,
        },
        multiSelectConfig: {
          multi: true,
        },
      },
      hasAddRemoveValues: true,
      plannerElementConfig: {
        type: "MYP_RELATED_CONCEPT",
      },
    },
  },
  UBD: {
    atlsUBD: {
      config: {
        plannerElementConfig: {
          type: "UBD_ATL",
        },
        type: "PLANNER_ELEMENT",
      },
      viewType: "Nestedview",
    },
    learningStandardUBD: {
      config: {
        plannerElementConfig: {
          type: "UBD_LEARNING_STANDARD",
        },
        fieldTypeConfig: {
          rootNodeDepth: 0,
        },
        type: "PLANNER_ELEMENT",
      },
      viewType: "Nestedview",
    },
  },
};

export const getFieldList = ({ curriculumProgramType }) => {
  return _.get(TAGGABLE_ELEMENTS_FIELD_LIST, curriculumProgramType, {}) || {};
};

export const getTaggableElements = ({
  curriculumProgramType,
  unitPlan,
  plannerElementSets,
} = {}) => {
  if (
    _.includes(
      [CURRICULUM_TYPE_UBD, CURRICULUM_TYPE_MYP],
      curriculumProgramType
    ) &&
    !_.isEmpty(plannerElementSets)
  ) {
    const taggableElements = getTaggableElementsWithDynamicLabels({
      elements:
        curriculumProgramType == CURRICULUM_TYPE_UBD
          ? TAGGABLE_UBD_ELEMENTS
          : TAGGABLE_MYP_ELEMENTS,
      plannerElementSets,
    });
    return taggableElements;
  } else if (curriculumProgramType == CURRICULUM_TYPE_UBD) {
    return TAGGABLE_UBD_ELEMENTS;
  } else if (curriculumProgramType == CURRICULUM_TYPE_DP) {
    return TAGGABLE_DP_ELEMENTS;
  } else if (curriculumProgramType == CURRICULUM_TYPE_MYP) {
    if (_.get(unitPlan, "unitType.value") == "ibMYPInterdisciplinary") {
      const dynamicUIDItems = [
        {
          uid: "disciplinaryObjectivesMYP",
          refUid: "objectivesMYP",
        },
        {
          uid: "disciplinaryLearningStandardMYP",
          refUid: "learningStandardMYP",
        },
      ];
      const elements = _.cloneDeep(TAGGABLE_MYP_ELEMENTS);
      // const subjectValues = _.get(unitPlan, "subjects.value", []);

      // _.forEach(dynamicUIDItems, uidItem => {
      //   const uidElement = _.find(
      //     elements,
      //     element => element.value == uidItem.refUid
      //   );

      //   _.forEach(subjectValues, subjectValue => {
      //     const uidIndex = _.findIndex(
      //       TAGGABLE_MYP_ELEMENTS,
      //       element => element.value == uidItem.refUid
      //     );

      //     elements = update(elements, {
      //       $splice: [
      //         [
      //           uidIndex,
      //           0,
      //           {
      //             ...(uidElement || {}),
      //             value: `${uidItem.uid}_${subjectValue}`
      //           }
      //         ]
      //       ]
      //     });
      //   });
      // });

      return _.filter(
        elements,
        item =>
          !_.includes(
            ["learningStandardMYP", "objectivesMYP", "relatedConceptsMYP"],
            item.value
          )
      );
    }

    return TAGGABLE_MYP_ELEMENTS;
  }

  return TAGGABLE_PYP_ELEMENTS;
};

export const getFieldUids = ({ curriculumProgramType }) => {
  const taggablElements = getTaggableElements({ curriculumProgramType });
  const fieldUids = _.map(
    taggablElements,
    taggableElement => taggableElement.value
  );
  return [...fieldUids, "measureAssessing"];
};

export const getTaggableElementsWithDynamicLabels = ({
  elements,
  plannerElementSets,
}) => {
  return _.map(elements, element => {
    if (
      plannerElementSets[element.elementType] &&
      element.isDynamicPlannerElementLabel
    ) {
      return {
        ...element,
        label: plannerElementSets[element.elementType]?.label,
        localisedLabel: plannerElementSets[element.elementType]?.label,
      };
    }
    return element;
  });
};

export const updatePost = data => {
  return { type: UPDATE_POST, data: data };
};
export const resetPost = () => {
  return { type: RESET_POST };
};

export const updateStudentListFilters = data => {
  return { type: UPDATE_STUDENT_LIST_FILTERS, data: data };
};

const REDUCER_HANDLERS = {
  [UPDATE_POST]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        postDetails: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [RESET_POST]: (state, action) => {
    return update(state, {
      postDetails: { $set: initialState.postDetails },
    });
  },
  [UPDATE_STUDENT_LIST_FILTERS]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        studentListFilters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
};

export const updateFolderArray = data => {
  return async (dispatch, getState) => {
    const postDetails = getState().post.postDetails;
    const postId = getState().post.postDetails.id;
    const folders = postDetails.folders;

    //new
    //  set it into folders
    if (!postId) {
      dispatch(updatePost({ folders: data }));
    } else {
      const addedFolders = _.differenceWith(data, folders, _.isEqual);
      const removedFolders = _.differenceWith(folders, data, _.isEqual);

      dispatch(updatePost({ addedFolders, removedFolders }));
    }
    //edit
    //  set it into addedFolders, deletedFolders
  };
};

export const uploadAttachments = ({ attachments, parentId, parentType }) => {
  return async (dispatch, getState) => {
    const attachmentLength = _.get(attachments, "length", 0);
    const attachmentType = _.get(attachments[0], "type", "");
    const attachmentUrl = _.get(attachments[0], "url", "");
    if (
      attachmentLength > 0 &&
      attachmentType != "NOTE" &&
      attachmentType != "LINK" &&
      attachmentUrl.search("blob:") > -1
    ) {
      const allPromises = _.map(attachments, async attachment => {
        const uploadStartTime = Date.now();
        const currentUrl = _.get(attachment, "url", "");
        if (_.includes(currentUrl, CLOUD_URL)) {
          // attachment already uploaded on the cloud
          return attachment;
        }
        const url = await dispatch(
          uploadFileV2({
            file: _.get(attachment, "file", {}),
            parentType,
            parentId,
            attachment: attachment,

            uploadId: generateRandomId(),
          })
        );
        const uploadTime = Date.now() - uploadStartTime;
        const metadata = _.get(attachment, "metadata", {});
        const updatedMetadata = { ...metadata, uploadTime };
        attachment = { ...attachment, metadata: updatedMetadata };
        return {
          ..._.omit(attachment, ["file", "id", "streamUrl", "isRead"]),
          url,
        };
      });
      attachments = await Promise.all(allPromises);
    }
    return attachments;
  };
};

export const createMedia = ({ parentId, parentType }) => {
  return async (dispatch, getState) => {
    const postDetails = getState().post.postDetails;
    let { attachments } = postDetails;
    attachments = await dispatch(
      uploadAttachments({ attachments, parentType, parentId })
    );

    // removing id from the attachments if present
    attachments = _.map(attachments, attachment =>
      _.omit(attachment, ["id", "isRead"])
    );
    const attachmentGroups = _.map(attachments, attachment => {
      return {
        parentId,
        parentType,
        attachments: [attachment],
      };
    });
    switch (parentType) {
      case "STUDENT_ASSIGNMENT_SUBMISSION":
        await dispatch(
          createAttachmentGroups({
            groupParentType: parentType,
            groupParentId: parentId,
            input: attachmentGroups,
          })
        );

        break;
    }
  };
};
export const updateOrEditPost = ({
  isSnpV2Practices = false,
  courseIdFromParent,
} = {}) => {
  return async (dispatch, getState) => {
    const postDetails = getState().post.postDetails;

    const { id: userId, user_type: userType } = getState().login.userInfo;
    const courseId = getState().teacher.selected_class.selected_course;
    if (_.get(postDetails, "id", "")) {
      await dispatch(editPost({ postDetails, isSnpV2Practices }));
    } else {
      let { attachments, state } = postDetails;
      if (isJournalCardRevamp() && userType == "student") {
        state = undefined;
      }
      // Keep student highlighted in filter after update/edit post
      dispatch(
        updateFilterObject({
          state: state,
          studentIds: userType == "student" ? userId : undefined,
          orderBy: getOrderBy(state),
          unTagged: false,
        })
      );

      attachments = await dispatch(
        uploadAttachments({ attachments, parentId: null, parentType: "POST" })
      );

      await dispatch(
        createPost({
          postDetails: {
            ...postDetails,
            courseId: courseIdFromParent ? courseIdFromParent : courseId,
            attachments,
          },
        })
      );
    }
  };
};
export const getOrderBy = state => {
  return _.get(
    _.find(POST_TYPE_DETAILS, item => item.value == state),
    "orderBy",
    "CREATED_AT"
  );
};
export const editPost = ({ postDetails, isSnpV2Practices }) => {
  return async (dispatch, getState) => {
    const {
      id,
      title,
      attachments,
      students: { edges: students = [] } = {},
      unitPlan,
      resourceItem,
      resourceType,
      elementPYP,
      state = "DRAFT",
      isPrivate,
      isHiddenFromStudents,
      addedFolders,
      removedFolders,
      practices,
      taggedPractices,
    } = postDetails;
    const courseId = getState().teacher.selected_class.selected_course;

    const translationFilter = getPostTranslationFilter({
      userInfo: _.get(getState(), "login.userInfo", {}),
      shouldTranslate: false,
      handleTranslation: hasTranslationPermission(),
    });

    const oldPostDetails = getPostDetailsFromCache({
      postId: id,
      translationFilter: translationFilter,
    });

    const evaluationCycleId = _.get(
      _.first(taggedPractices),
      "evaluationCycle.id",
      ""
    );

    const oldPractices = oldPostDetails.practices || [];
    const updatedPractices = practices;

    const oldTaggedPractices = oldPostDetails.taggedPractices || [];
    const newTaggedPractices = taggedPractices;

    const addedPractices = isSnpV2Practices
      ? getAddedPracticesV2({
          oldTaggedPractices,
          newTaggedPractices,
        })
      : _.differenceBy(updatedPractices, oldPractices, "id");

    const removedPractices = isSnpV2Practices
      ? getRemovedPracticesV2({
          oldTaggedPractices,
          newTaggedPractices,
        })
      : _.differenceBy(oldPractices, updatedPractices, "id");

    const userId = getState().login.userInfo.id;
    const userType = getState().login.userInfo.user_type;
    const studentIds = _.map(students, item => item.node.id);
    try {
      await client.mutate({
        mutation: updatePostMutation,
        variables: {
          postId: id,
          courseId: courseId,
          title: title,
          attachments: _.map(attachments, item =>
            _.omit(item, ["id", ...ATTACHMENT_OMIT_ATTRIBUTES])
          ),
          studentIds: studentIds,
          unitPlanId: _.get(unitPlan, "id", null),
          resourceId: _.get(resourceItem, "id", null),
          createdBy: userId,
          resourceType: resourceItem ? resourceType : null,
          elementsPYP: _.map(elementPYP, item =>
            _.omit(item, ["id", "__typename", "resolvedMinimalTree"])
          ),
          addedPractices: _.map(addedPractices, ({ id }) => id),
          removedPractices: _.map(removedPractices, ({ id }) => id),
          state: state,
          evaluationCycleId,
          isPrivate: isPrivate,
          isHiddenFromStudents,
          updatedBy: userId,
          addedFolders: _.map(addedFolders, folder => folder.id),
          removedFolders: _.map(removedFolders, folder => folder.id),
        },
        refetchQueries: [
          {
            query: getCourseAllStudentQuery,
            variables: {
              id: courseId,
              folderIds: getState().journal.filters.folderIds,
            },
          },
        ],
      });

      dispatch(
        setToastMsg({
          msg: "toastMsgs:post_updated_successfully",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
      const filters = getState().journal.filters;
      // Keep student highlighted in filter after update/edit post
      if (!_.includes(studentIds, filters.studentIds)) {
        dispatch(
          updateFilterObject({
            studentIds: userType == "student" ? userId : undefined,
            unTagged: false,
          })
        );
      }
      if (!_.isEqual(state, filters.state)) {
        if (isJournalCardRevamp() && userType == "student") {
          return;
        }
        dispatch(
          updateFilterObject({
            state: state,
            orderBy: getOrderBy(state),
          })
        );
      }
    } catch (e) {
      console.warn(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
      throw e;
    }
  };
};

export const addPracticeToPost = ({ id, practice, state }) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    try {
      await client.mutate({
        mutation: updatePostMutation,
        variables: {
          postId: id,
          addedPractices: practice,
          state,
          updatedBy: userId,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:success_tagged_post",

          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("Something went wrong"));
    }
  };
};

export const createPost = ({ postDetails, fromJournal = true }) => {
  return async (dispatch, getState) => {
    const {
      title,
      attachments,
      students: { edges: students = [] } = {},
      unitPlan,
      resourceItem,
      resourceType,
      elementPYP,
      state = "DRAFT",
      isPrivate,
      isHiddenFromStudents,
      folders,
      courseId,
      practices,
      taggedPractices,
      curriculumProgramId,
      items,
    } = postDetails;
    //const courseId = getState().teacher.selected_class.selected_course;
    const userId = getState().login.userInfo.id;
    const userType = getState().login.userInfo.user_type;

    const responseData = _.find(
      items,
      item => _.get(item, "itemType") === "STUDENT_ASSIGNMENT"
    );
    const studentAssignmentId = _.get(responseData, "item.id", null);
    const submissionType = _.get(responseData, "itemType", null);

    const refetchStudentAssignmentDetails = [];

    if (studentAssignmentId) {
      refetchStudentAssignmentDetails.push({
        query: getStudentAssignmentDetailsQuery,
        variables: {
          id: studentAssignmentId,
        },
      });
    }

    let courseDetails = getCourseDetailsFromCache({ id: courseId });
    if (_.isEmpty(courseDetails) && !!courseId) {
      courseDetails = await dispatch(getCourseDetails({ id: courseId }));
    }

    const academicYears = _.map(
      _.get(courseDetails, "academicYears", []),
      item => item.id
    );

    const curriculumProgramType = getState().platform.currentCurriculumProgram
      .type;
    const fieldUids = getFieldUids({ curriculumProgramType });

    const refetchQueryFilters = _.get(getState(), "journal.filters", {});

    const translationFilter = getPostTranslationFilter({
      userInfo: _.get(getState(), "login.userInfo", {}),
      shouldTranslate: false,
      handleTranslation: hasTranslationPermission(),
    });
    try {
      await client.mutate({
        mutation: createPostMutation,
        variables: {
          courseId: courseId,
          title: title,
          attachments: _.map(attachments, item =>
            _.omit(item, [
              "file",
              "id",
              "similarityReport",
              "isPost",
              "createdBy",
            ])
          ),
          studentIds: _.map(students, item => item.node.id),
          unitPlanId: _.get(unitPlan, "id", null),
          resourceId: _.get(resourceItem, "id", null),
          createdBy: userId,
          practices: _.map(practices, practice => practice.id),
          practicesV2: getPracticesV2InputForPost({
            taggedPractices,
          }),
          resourceType: resourceItem ? resourceType : null,
          elementsPYP: _.map(elementPYP, element =>
            _.omit(element, ["resolvedMinimalTree"])
          ),
          state: state,
          isPrivate: isPrivate,
          isHiddenFromStudents,
          publishedBy: userId,
          folders: _.map(folders, folder => folder.id),
          curriculumProgramId,
          parentId: studentAssignmentId,
          parentType: submissionType,
          academicYears,
        },
        awaitRefetchQueries: true,
        refetchQueries: fromJournal
          ? [
              {
                query: getCoursePostFeedQuery,
                variables: {
                  id: courseId,
                  filters: refetchQueryFilters,
                  uids: fieldUids,
                  translationFilter: translationFilter,
                },
              },
              {
                query: getCourseAllStudentQuery,
                variables: {
                  id: courseId,
                  folderIds: _.get(getState(), "journal.filters.folderIds", []),
                },
              },
              ...refetchStudentAssignmentDetails,
            ]
          : [],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:post_created_successfully",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const getCourseDetails = ({ id }) => {
  return async (dispatch, getState) => {
    const result = await client.query({
      query: getCourseDetailsQuery,
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });
    return _.get(result, "data.node", []);
  };
};

export const updateAssignmentToJournal = ({ studentAssignmentIds }) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const postDetails = getState().post.postDetails;

    const {
      state,
      assignmentId,
      title,
      unitPlan,
      resourceItem,
      resourceType,
      elementPYP,
      isPrivate,
      isHiddenFromStudents,
      folders,
      courseId,
      practices,
      taggedPractices,
      curriculumProgramId,
    } = postDetails;

    try {
      await client.mutate({
        mutation: updateAssignmentToJournalMutation,
        variables: {
          assignmentId: assignmentId,
          studentAssignmentIds,
          postData: {
            courseId: courseId,
            title: title,
            unitPlanId: _.get(unitPlan, "id", null),
            resourceId: _.get(resourceItem, "id", null),
            createdBy: userId,
            practices: _.map(practices, practice => practice.id),
            practicesV2: getPracticesV2InputForPost({
              taggedPractices,
            }),
            resourceType: resourceItem ? resourceType : null,
            elementsPYP: _.map(elementPYP, element =>
              _.omit(element, ["resolvedMinimalTree"])
            ),
            state: state,
            isPrivate: isPrivate,
            isHiddenFromStudents,
            publishedBy: userId,
            folders: _.map(folders, folder => folder.id),
            curriculumProgramId,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAssignmentAllSubmissionQuery,
            variables: {
              id: assignmentId,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: `toastMsgs:posts_action_successfully`,
          locale_params: [
            {
              key: "action",
              value: `toastMsgs:created`,
              isPlainText: false,
            },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

const initialState = {
  postDetails: {
    postIds: [],
    studentIds: [],
    taggedPYPElements: {},
    taggedPYPChildren: [],
    resourceId: null,
    resourceType: null,
    attachments: [],
    activityIds: [],
    courseId: null,
    title: "",
    isResourceUpdate: true,
    state: "DRAFT",
    comments: {},
    likes: {},
    isPrivate: true,
    isHiddenFromStudents: true,
    isResourceSkipped: false,
    folders: [],
    selectedFolders: [],
    practices: [],
    taggedPractices: [],
    addedFolders: [],
    removedFolders: [],
  },
  postCreationMode: "new",
  studentListFilters: {
    searchText: "",
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
