import React, { useRef } from "react";

import classes from "./ParentLogin.scss";
import {
  GoogleLoginBtn,
  MicrosoftLoginBtn,
  CustomLoginButton,
} from "UIComponents";

import { GOOGLE_CLIENT_ID } from "Constants";

import {
  LexColored,
  DeviceMobileOutlined,
  ProfileColored,
} from "@toddle-design/web-icons";
import { ThirdPartyAuth } from "AppComponents";
import { isNewFamilySignUpFlowEnabled } from "config";

const ParentLogin = props => {
  const {
    onGoogleSignInCallBack,
    onGoogelFailureCallBack,
    onMicroSoftSignInCallBack,
    onMicroSoftFailureCallBack,
    renderTradeMarkText,
    updateCurrentScreen,
    onLexSignInCallBack,
    customStyle: { containerStyle, headerStyle, subTextStyle } = {},
    renderBackButtonComponent,
    isFamilyClassInviteRoute,
  } = props;

  const lexAuthRef = useRef();

  const onLexLoginClick = () => {
    if (lexAuthRef && lexAuthRef.current) {
      const onAuthClick = _.get(
        lexAuthRef,
        "current.refs.wrappedInstance.onAuthClick"
      );
      onAuthClick();
    }
  };

  const onLoginClick = () => {
    const { updateCurrentScreen } = props;
    updateCurrentScreen({ type: "parentLoginForm" });
  };

  const getScreenTypeOnCreateAccountClick = () => {
    if (isNewFamilySignUpFlowEnabled) {
      // for class invite, we don't need to go to verifyParentInviteCodeScreen because we have region in the url of class invite
      return isFamilyClassInviteRoute
        ? "createParentAccount"
        : "verifyParentInviteCode";
    } else {
      return "createParentAccount";
    }
  };

  const onCreateAccountClick = () => {
    updateCurrentScreen({ type: getScreenTypeOnCreateAccountClick() });
  };

  const onBackClick = () => {
    const { updateCurrentScreen } = props;
    updateCurrentScreen({ type: "loginHome" });
  };

  return (
    <div className={classes.container} style={containerStyle}>
      {renderBackButtonComponent({
        onClick: onBackClick,
        buttonText: "Toddle home",
      })}
      <div style={{ ...headerStyle, fontSize: "3.2rem" }}>Family account</div>
      <div className={classes.buttonsContainer}>
        <GoogleLoginBtn
          clientId={GOOGLE_CLIENT_ID}
          onSuccess={onGoogleSignInCallBack}
          onFailure={onGoogelFailureCallBack}
          prompt="select_account"
        />

        <MicrosoftLoginBtn
          onSuccess={onMicroSoftSignInCallBack}
          onFailure={onMicroSoftFailureCallBack}
        />

        <CustomLoginButton
          icon={<LexColored size="x-small" />}
          onClick={onLexLoginClick}
          buttonText={"Sign in with Lex"}
        />

        <CustomLoginButton
          icon={<DeviceMobileOutlined size="x-small" />}
          buttonText={"Sign in with email/phone"}
          onClick={onLoginClick}
        />
      </div>
      <div className={classes.orContainer}>
        <div className={classes.orLineContainer} />
        <div className={classes.orText}>or</div>
        <div className={classes.orLineContainer} />
      </div>
      <CustomLoginButton
        icon={<ProfileColored size="x-small" />}
        buttonText={"Create an account"}
        onClick={onCreateAccountClick}
      />
      <ThirdPartyAuth
        serviceType="LEX"
        ref={lexAuthRef}
        onSuccessCallBack={onLexSignInCallBack}
      />
      {renderTradeMarkText()}
    </div>
  );
};

export default ParentLogin;
