import React from "react";

const FileIconSvg = props => {
  return (
    <svg height={props.height} width={props.width} viewBox="0 0 19 23">
      <g stroke="none" fill={props.fill} fillRule="evenodd">
        <path d="M12.6740139,0.97962963 C12.0403132,0.340740741 11.195379,0 10.2659513,0 L3.37973705,0 C1.52088167,0 0,1.53333333 0,3.40740741 L0,19.5925926 C0,21.4666667 1.52088167,23 3.37973705,23 L14.8285963,23 C16.6874517,23 18.2083333,21.4666667 18.2083333,19.5925926 L18.2083333,8.00740741 C18.2083333,7.11296296 17.8703596,6.26111111 17.2366589,5.57962963 L12.6740139,0.97962963 Z M12.2093001,2.8962963 L15.3778036,6.09074074 L13.8991686,6.09074074 C12.9697409,6.09074074 12.2093001,5.32407407 12.2093001,4.38703704 L12.2093001,2.8962963 Z M16.5607115,19.5925926 C16.5607115,20.5296296 15.8002707,21.2962963 14.870843,21.2962963 L3.37973705,21.2962963 C2.45030936,21.2962963 1.68986852,20.5296296 1.68986852,19.5925926 L1.68986852,3.40740741 C1.68986852,2.47037037 2.45030936,1.7037037 3.37973705,1.7037037 L10.308198,1.7037037 C10.3926914,1.7037037 10.4349381,1.7037037 10.5194316,1.7037037 L10.5194316,4.38703704 C10.5194316,6.26111111 12.0403132,7.79444444 13.8991686,7.79444444 L16.5607115,7.79444444 C16.5607115,7.87962963 16.5607115,7.92222222 16.5607115,8.00740741 L16.5607115,19.5925926 Z" />
        <path d="M4.70454545,13.4166667 L8.71212121,13.4166667 C9.19128788,13.4166667 9.58333333,12.9854167 9.58333333,12.4583333 C9.58333333,11.93125 9.19128788,11.5 8.71212121,11.5 L4.70454545,11.5 C4.22537879,11.5 3.83333333,11.93125 3.83333333,12.4583333 C3.83333333,12.9854167 4.22537879,13.4166667 4.70454545,13.4166667 Z" />
        <path d="M13.5214238,15.3333333 L4.68690958,15.3333333 C4.21744265,15.3333333 3.83333333,15.7645833 3.83333333,16.2916667 C3.83333333,16.81875 4.21744265,17.25 4.68690958,17.25 L13.5214238,17.25 C13.9908907,17.25 14.375,16.81875 14.375,16.2916667 C14.375,15.7645833 13.9908907,15.3333333 13.5214238,15.3333333 Z" />
      </g>
    </svg>
  );
};

FileIconSvg.defaultProps = {
  fill: "white",
  height: 19,
  width: 23,
};

export default FileIconSvg;
