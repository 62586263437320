import React, { useState } from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";

import classes from "./ParentSettings.scss";
import {
  UIButton,
  I18nHOC,
  withLoader,
  FullScreenLoader,
  Configurations,
} from "UIComponents";

import { goToRelativeRoute } from "modules/Services";
import {
  switchChild,
  setChildStatus,
  handleAcademicYearForParent,
  setIsLastChildRemoved,
} from "Login/modules/LoginModule";
import { removeChildForFamilyMember } from "../modules/ParentSettingsModule";
import { getChildrenQuery } from "modules/CommonQuery";
import { getChildrenFromCache } from "modules/CommonGraphqlHelpers";

import AddChildModal from "Courses/components/ParentHomePage/AddChildModal";
import ChildModal from "Login/components/AddChildPage/ChildModal";
import TopHeader from "../../ParentProgressReport/components/TopHeader";
import ChildrenListItem from "./ChildrenListItem";

import { getUserAcadYearConstantsMemoize } from "Login/components/ChildrenPage/Utils";

const styles = {
  buttonStyle: {
    minWidth: "84px",
    height: "32px",
  },
  labelStyle: {
    fontSize: "1.2rem",
  },
};

const getChildName = childItem =>
  `${_.get(childItem, "firstName", "")} ${_.get(childItem, "lastName", "")}`;

const ParentSettings = props => {
  const { t, goToRelativeRoute, handleAcademicYearForParent } = props;

  //local states
  const [addChildModal, setAddChildModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [removeChildModal, setRemoveChildModal] = useState(false);
  const [childDetails, setChildDetails] = useState({});
  const [fullScreenLoader, setFullScreenLoader] = useState(false);

  // routing
  const doRoutingToSettings = ({ childData, source }) => {
    const allCourses = _.get(childData, "allCourses");
    const orgAcademicYears = _.get(childData, "organization.academicYears", {});
    const organizationCurriculumPrograms = _.get(
      childData,
      "organization.curriculumPrograms",
      []
    );

    // get mapping of academic year and curriculum
    const { currentAcadYearObj } = getUserAcadYearConstantsMemoize({
      allCourses,
      orgAcademicYears,
      organizationCurriculumPrograms,
    });

    let route;
    if (source === "addChild") {
      route = "/";
    } else {
      const academicYearCurriculumProgram = !_.isEmpty(
        currentAcadYearObj.curriculumPrograms
      )
        ? currentAcadYearObj.curriculumPrograms
        : childData.curriculumPrograms;

      const academicYearCurriculumProgramId = _.get(
        academicYearCurriculumProgram,
        "[0].id"
      );

      route = `/platform/${academicYearCurriculumProgramId}/settings`;
    }

    goToRelativeRoute({
      route,
      type: "replace",
    });

    // update redux state(academicYearSelected) after routing
    handleAcademicYearForParent();
  };

  // this function will be called while adding child
  const handleChildData = ({ childData, source }) => {
    const { switchChild } = props;

    // switch child
    switchChild({ childData });

    // do routing
    doRoutingToSettings({ childData, source });
  };

  //  onClick functions
  const onAddChildClick = () => {
    setAddChildModal(true);
  };

  const onModalDoneClick = () => {
    handleChildData({ childData: childDetails, source: "addChild" });
    setSuccessModal(false);
  };

  const onRemoveClick = (childId, childName, profileImage) => {
    setChildDetails({
      childId,
      childName,
      profileImage,
    });
    setRemoveChildModal(true);
  };

  const onModalRemoveClick = async childId => {
    const {
      removeChildForFamilyMember,
      children,
      switchChild,
      userId,
      setChildStatus,
      setIsLastChildRemoved,
      activeChild,
    } = props;

    /** Three conditions while removing child
     *  Case 1: There is only one child and the parent want's to remove
     *  Case 2: There are more than 1 child and the parent want's to remove current active child
     *  Case 3: There are more than 1 child and parent want's to remove non active child (No extra steps needed except doing mutation in this case)
     *  Note: By current active child I mean currently selected child
     */

    setFullScreenLoader(true);

    // do mutation irrespective of above case
    await removeChildForFamilyMember({ childId, userId });

    // *Case 1*
    if (_.size(children) === 1) {
      // reset local storage and redux store
      switchChild({ childData: {} });

      // setChildStatus action will impact in LoginView.js
      setChildStatus(false);

      // setIsLastChildRemoved action will impact in ChildrenPage.js
      setIsLastChildRemoved(true);

      // do routing
      goToRelativeRoute({
        route: "/platform",
      });

      // *Case 2*
    } else if (activeChild === childId) {
      // get first element of array in which childId is not present
      const childData = _.first(
        _.filter(children, childItem => childItem.id !== childId)
      );

      // switch child
      switchChild({ childData });

      // do routing
      doRoutingToSettings({ childData });
    }

    setFullScreenLoader(false);
    setRemoveChildModal(false);
  };

  // render function's
  const renderTopHeader = () => (
    <TopHeader
      title={t("common:settings")}
      showToddleIcon={true}
      onGoBackClick={() => goToRelativeRoute({ route: "../" })}
    />
  );

  const renderManageChildren = () => {
    const { children } = props;
    return (
      <div className={classes.childrenContainer}>
        <div className={classes.childrenHeader}>
          <div className={classes.headerText}>
            {t("configuration:manage_children")}
          </div>
          <div className={classes.button}>
            <UIButton
              containerStyle={styles.buttonStyle}
              labelStyle={styles.labelStyle}
              color="blue"
              size="sm"
              type="hollow"
              onClick={onAddChildClick}
            >
              {t("common:add_child")}
            </UIButton>
          </div>
        </div>
        <div className={classes.childrenList}>
          {_.map(children, childItem => (
            <ChildrenListItem
              childName={getChildName(childItem)}
              schoolName={_.get(childItem, "organization.name", "")}
              removeText={t("common:remove_child")}
              profileImage={_.get(childItem, "profileImage", "")}
              childId={_.get(childItem, "id", "")}
              onRemoveClick={onRemoveClick}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderManageNotification = () => {
    return (
      <div className={classes.notificationContainer}>
        <div className={classes.headerText}>
          {t("common:notification_settings")}
        </div>
        <div className={classes.settingsContainer}>
          <Configurations
            platform="FAMILY_WEB"
            categories={["NOTIFICATION"]}
            showChooseCourses={false}
            isNotificationConfiguration={true}
          />
        </div>
      </div>
    );
  };

  const renderAddChildModal = () => (
    <AddChildModal
      setAddChildModal={setAddChildModal}
      setSuccessModal={setSuccessModal}
      setChildDetails={setChildDetails}
    />
  );

  const renderAddChildSuccessModal = () => (
    <ChildModal
      childId={childDetails.id}
      firstName={childDetails.firstName}
      profileImage={childDetails.profileImage}
      onDoneClick={onModalDoneClick}
    />
  );

  const renderRemoveChildModal = () => (
    <ChildModal
      childId={childDetails.childId}
      fullName={childDetails.childName}
      profileImage={childDetails.profileImage}
      onRemoveClick={onModalRemoveClick}
      onCloseClick={() => setRemoveChildModal(false)}
      isRemoveModal={true}
    />
  );

  return (
    <React.Fragment>
      <div className={classes.container}>
        {renderTopHeader()}
        <div className={classes.bottomContainer}>
          <div className={classes.detailsContainer}>
            {renderManageNotification()}
            {renderManageChildren()}
          </div>
        </div>
      </div>
      {addChildModal && renderAddChildModal()}
      {successModal && renderAddChildSuccessModal()}
      {removeChildModal && renderRemoveChildModal()}
      {fullScreenLoader && <FullScreenLoader />}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: _.get(state, "login.userInfo.id", ""),
    activeChild: _.get(state, "login.userInfo.childID", ""),
  };
};

const mapActionCreators = {
  switchChild,
  goToRelativeRoute,
  removeChildForFamilyMember,
  setChildStatus,
  setIsLastChildRemoved,
  handleAcademicYearForParent,
};

export default compose(
  I18nHOC({
    resource: ["common", "configuration"],
  }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getChildrenQuery, {
    name: "getChildren",
    options: ({ userId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: "FAMILY_MEMBER",
      },
    }),
    props({ getChildren, ownProps: { userId } }) {
      const childrenData = getChildrenFromCache({
        id: userId,
        type: "FAMILY_MEMBER",
      });
      const children = _.get(childrenData, "children", []);

      return {
        isData: !_.isEmpty(childrenData),
        isLoading:
          getChildren["networkStatus"] == 1 ||
          getChildren["networkStatus"] == 2,
        children,
      };
    },
  }),
  withLoader
)(ParentSettings);
