import React, { PureComponent } from "react";
import classes from "./EditOrgInfo.scss";
import { withLoader, ProgressSteps, I18nHOC } from "UIComponents";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import PropTypes from "prop-types";
import { colors } from "Constants";
import { getOrganizationGradesQuery } from "GradesSetup/modules/Queries";
import { getOrganizationGradesFromCache } from "GradesSetup/modules/GraphqlHelpers";
import classNames from "classnames";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  checkAcademicYears,
  checkDPAcademicYears,
  transformAcademicYears,
} from "Tooling/modules/ToolingModule";
import { Button } from "@toddle-design/web";
import { generateRandomId } from "Utils";

import update from "immutability-helper";
import { CURRICULUM_TYPE_DP } from "Constants/stringConstants";
import { getGlobalGradesGroupedByCurriculumProgram } from "./modules/UpsertOrganizationUtils";

const steps = [1, 2, 3, 4];
class EditOrgInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.step1Ref = React.createRef();
    this.step2Ref = React.createRef();

    this.scrollContainerRef = React.createRef();

    this.state = {
      currentStep: 1,
      organizationDetails: {},
    };
  }

  initializeStateForCreateMode = () => {
    const { organizationDetails } = this.props;

    this.setState({
      organizationDetails: {
        ...organizationDetails,
        curriculumPrograms: [{ id: generateRandomId(), isTypeEditable: true }],
        admins: [{ id: generateRandomId() }],
      },
    });
  };

  initializeStateForEditMode = () => {
    const {
      globalGradesGroupedByCurriculumProgram,
      organizationDetails,
    } = this.props;

    const examSessionMonth = _.get(
      organizationDetails,
      "examSessionMonth",
      null
    );

    const academicYears = transformAcademicYears({
      academicYears: _.get(organizationDetails, "academicYears", []),
    });

    const curriculumPrograms = _.map(
      _.get(organizationDetails, "curriculumOptions", []),
      ({
        curriculumType,
        planId,
        rosteringMethod,
        toddleBuddyId,
        id,
        label,
        acronym,
      }) => {
        const selectedGlobalGrades = globalGradesGroupedByCurriculumProgram[id];

        const gradesToBeIncluded = _.map(selectedGlobalGrades, ({ id }) => id);

        const isDpCurriculumProgram = curriculumType === CURRICULUM_TYPE_DP;

        return {
          ...(isDpCurriculumProgram
            ? { examSessionMonth, orgDpExamSession: examSessionMonth }
            : {}),
          grades: gradesToBeIncluded,
          name: label,
          acronym,
          rosteringMethod,
          toddleBuddy: toddleBuddyId,
          type: curriculumType,
          plan: planId,
          id,
          isTypeEditable: false,
        };
      }
    );

    const updatedOrganizationDetails = {
      id: organizationDetails.id,
      academicYears,
      curriculumPrograms: _.reverse(curriculumPrograms),
    };

    this.setState({
      organizationDetails: updatedOrganizationDetails,
      currentStep: 2,
    });
  };

  componentDidMount = () => {
    const { mode } = this.props;
    switch (mode) {
      case "create": {
        this.initializeStateForCreateMode();
        break;
      }

      case "edit": {
        this.initializeStateForEditMode();
        break;
      }
    }
  };

  componentDidUpdate = prevProps => {
    const { isLoading: oldLoadingStatus } = prevProps;
    const { isData, isLoading, mode } = this.props;

    if (oldLoadingStatus !== isLoading && isData && mode == "edit") {
      this.initializeStateForEditMode();
    }
  };

  scrollToTop = () => {
    this.scrollContainerRef.current.scrollTop = 0;
  };

  checkDpAcademicYears = () => {
    const { organizationDetails } = this.state;

    const { academicYears } = organizationDetails;

    const transformedAcademicYears = _.map(
      academicYears,
      ({ dateDurationValue }) => dateDurationValue
    );

    //Checking if DP school created has 1prev, current and 1 next academic year
    return checkDPAcademicYears({
      academicYears: transformedAcademicYears,
    });
  };

  validateCurrentStep = () => {
    const { currentStep, organizationDetails } = this.state;
    const { setToastMsg, mode } = this.props;
    switch (currentStep) {
      case 1: {
        return this.step1Ref?.current?.isValid();
      }

      case 3: {
        const { academicYears, curriculumPrograms } = organizationDetails;

        const dpCurriculumPrograms = _.filter(
          curriculumPrograms,
          ({ type }) => type == CURRICULUM_TYPE_DP
        );

        if (!_.isEmpty(dpCurriculumPrograms)) {
          //Checking if DP school created has 1prev, current and 1 next academic year
          const academicYearsValid = this.checkDpAcademicYears();
          if (!academicYearsValid) {
            setToastMsg("toastMsgs:please_select_one_prev_current_next_year");
            return false;
          }
        }

        const areAcademicYearsValid = checkAcademicYears({ academicYears });
        if (!areAcademicYearsValid) {
          setToastMsg(
            "toastMsgs:multiple_academic_years_cannot_have_same_dates"
          );
          return false;
        }

        return true;
      }

      case 2: {
        const totalErrors = this.step2Ref?.current?.isValid();

        const { curriculumPrograms } = organizationDetails;
        const dpCurriculumPrograms = _.filter(
          curriculumPrograms,
          ({ type }) => type == CURRICULUM_TYPE_DP
        );

        const areDpCurriculumProgramsEmpty = _.isEmpty(dpCurriculumPrograms);

        const areCurriculumProgramsEmpty = _.isEmpty(curriculumPrograms);

        let areAcademicYearsValid = true;

        const areDpCurriculumProgramsValid = _.reduce(
          dpCurriculumPrograms,
          (result, { examSessionMonth }) => {
            return result && !!examSessionMonth;
          },
          true
        );

        if (areCurriculumProgramsEmpty) {
          setToastMsg("toastMsgs:school_must_have_one_curriculum");
        } else if (!areDpCurriculumProgramsValid) {
          setToastMsg("toastMsgs:please_select_exam_session_month");
        } else if (!areDpCurriculumProgramsEmpty && mode === "edit") {
          areAcademicYearsValid = this.checkDpAcademicYears();

          if (!areAcademicYearsValid) {
            setToastMsg("toastMsgs:please_create_one_prev_current_next_year");
          }
        }

        return (
          totalErrors === 0 &&
          areDpCurriculumProgramsValid &&
          areAcademicYearsValid &&
          !areCurriculumProgramsEmpty
        );
      }

      case 4: {
        return true;
      }
    }
  };
  onClickNext = () => {
    const { currentStep, organizationDetails } = this.state;
    const { onClickOperation } = this.props;
    const isValid = this.validateCurrentStep();

    if (!isValid) {
      return;
    }

    if (currentStep === 4) {
      onClickOperation({ ...organizationDetails });
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      currentStep: prevState.currentStep + 1,
    }));

    this.scrollToTop();
  };

  onClickCancel = () => {
    this.setState(prevState => ({
      ...prevState,
      currentStep: prevState.currentStep - 1,
    }));
  };

  updateOrganizationInformation = ({ key, value }) => {
    this.setState(prevState => ({
      ...prevState,
      organizationDetails: { ...prevState.organizationDetails, [key]: value },
    }));
  };

  addEntity = ({ entity }) => {
    let field;

    const entityInfo = { id: generateRandomId() };

    switch (entity) {
      case "ADMIN": {
        field = "admins";
        break;
      }
      case "CURRICULUM": {
        field = "curriculumPrograms";
        entityInfo.isTypeEditable = true;
      }
    }

    this.setState(prevState => {
      return update(prevState, {
        organizationDetails: {
          [field]: {
            $set: [entityInfo, ...(prevState.organizationDetails[field] ?? [])],
          },
        },
      });
    });
  };

  updateEntity = ({ entity, entityDetails }) => {
    let field;
    switch (entity) {
      case "ADMIN": {
        field = "admins";
        break;
      }
      case "CURRICULUM": {
        field = "curriculumPrograms";
      }
    }

    this.setState(prevState => {
      return update(prevState, {
        organizationDetails: {
          [field]: {
            $set: _.map(
              prevState.organizationDetails[field],
              oldEntityDetails => {
                if (oldEntityDetails.id == entityDetails.id) {
                  return { ...oldEntityDetails, ...entityDetails };
                }
                return oldEntityDetails;
              }
            ),
          },
        },
      });
    });
  };

  deleteEntity = ({ entity, entityId }) => {
    let field;
    switch (entity) {
      case "ADMIN": {
        field = "admins";
        break;
      }
      case "CURRICULUM": {
        field = "curriculumPrograms";
      }
    }

    this.setState(prevState => {
      const entityDetails = prevState.organizationDetails[field];

      const updatedEntityDetails = _.filter(
        entityDetails,
        ({ id }) => id !== entityId
      );

      return update(prevState, {
        organizationDetails: {
          [field]: {
            $set: updatedEntityDetails,
          },
        },
      });
    });
  };

  getCurrentStepClasses = () => {
    const { currentStep } = this.state;
    switch (currentStep) {
      case 1: {
        return classNames(classes.stepContainer, classes.step1Container);
      }
      case 3: {
        return classNames(classes.stepContainer, classes.step3Container);
      }
      case 2: {
        return classNames(classes.stepContainer, classes.step2Container);
      }
      case 4: {
        return classNames(classes.stepContainer, classes.step4Container);
      }
    }
  };

  getCurrentStepElement = () => {
    const { organizationDetails, currentStep } = this.state;

    const {
      name,
      code,
      locale,
      rosteringMethod,
      logoAttachment,
      academicYears,
      operationalDays,
      curriculumPrograms,
      admins,
      timezone,
    } = organizationDetails;

    const { toddleBuddies, mode } = this.props;

    switch (currentStep) {
      case 1: {
        return (
          <Step1
            customRef={this.step1Ref}
            name={name}
            code={code}
            locale={locale}
            rosteringMethod={rosteringMethod}
            logoAttachment={logoAttachment}
            updateOrganizationInformation={this.updateOrganizationInformation}
          />
        );
      }
      case 3: {
        return (
          <Step2
            academicYears={academicYears}
            timezone={timezone}
            operationalDays={operationalDays}
            updateOrganizationInformation={this.updateOrganizationInformation}
          />
        );
      }
      case 2: {
        const addCurriculumProgram = () =>
          this.addEntity({ entity: "CURRICULUM" });

        const updateCurriculumProgram = curriculumProgramDetails =>
          this.updateEntity({
            entity: "CURRICULUM",
            entityDetails: curriculumProgramDetails,
          });

        const deleteCurriculumProgram = ({ id }) =>
          this.deleteEntity({ entity: "CURRICULUM", entityId: id });

        return (
          <Step3
            customRef={this.step2Ref}
            addCurriculumProgram={addCurriculumProgram}
            curriculumPrograms={curriculumPrograms}
            updateCurriculumProgram={updateCurriculumProgram}
            toddleBuddies={toddleBuddies}
            mode={mode}
            deleteCurriculumProgram={deleteCurriculumProgram}
          />
        );
      }
      case 4: {
        const addAdmin = () => this.addEntity({ entity: "ADMIN" });

        const updateAdmin = curriculumProgramDetails =>
          this.updateEntity({
            entity: "ADMIN",
            entityDetails: curriculumProgramDetails,
          });

        const deleteAdmin = ({ id }) =>
          this.deleteEntity({ entity: "ADMIN", entityId: id });

        return (
          <Step4
            addAdmin={addAdmin}
            admins={admins}
            updateAdmin={updateAdmin}
            deleteAdmin={deleteAdmin}
          />
        );
      }
    }
  };

  renderCreateOrganizationFlow = () => {
    const { currentStep } = this.state;
    const { t, closeModal } = this.props;

    const currentStepClasses = this.getCurrentStepClasses();

    return (
      <div className={classes.container}>
        <ProgressSteps
          steps={steps}
          currentStep={currentStep}
          minHeight={8}
          currentStepBackgroundColor={colors.violet63}
        />

        <div className={classes.scrollContainer} ref={this.scrollContainerRef}>
          <div className={currentStepClasses}>
            {this.getCurrentStepElement()}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            <Button
              variant={"plain"}
              size={"large"}
              onClick={currentStep === 1 ? closeModal : this.onClickCancel}
            >
              {currentStep == 1 ? t("common:cancel") : t("common:back")}
            </Button>
            <Button
              variant={"primary"}
              size={"large"}
              className={classes.nextButton}
              onClick={this.onClickNext}
            >
              {currentStep == 4 ? t("common:create") : t("common:next")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  updateCurriculumPrograms = () => {
    const { organizationDetails } = this.state;
    const { onClickOperation } = this.props;
    const isValid = this.validateCurrentStep();
    if (!isValid) {
      return;
    }

    onClickOperation({ ...organizationDetails });
  };

  renderEditCurriculumProgramsFlow = () => {
    const { t, closeModal } = this.props;

    const currentStepClasses = this.getCurrentStepClasses();

    return (
      <div className={classes.container}>
        <div className={classes.scrollContainer} ref={this.scrollContainerRef}>
          <div className={currentStepClasses}>
            {this.getCurrentStepElement()}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            <Button variant={"plain"} size={"large"} onClick={closeModal}>
              {t("common:cancel")}
            </Button>
            <Button
              variant={"primary"}
              size={"large"}
              className={classes.nextButton}
              onClick={this.updateCurriculumPrograms}
            >
              {t("common:update")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { mode } = this.props;
    return mode === "create"
      ? this.renderCreateOrganizationFlow()
      : this.renderEditCurriculumProgramsFlow();
  }
}

const mapActionCreators = { setToastMsg };
const mapStateToProps = (state, ownProps) => {
  const { mode, organizationDetails } = ownProps;
  return {
    mode,
    organizationId: _.get(organizationDetails, "id", ""),
    isLoading: false,
    isData: true,
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationGradesQuery, {
    name: "getOrganizationGrades",
    skip: ({ mode }) => mode === "create",
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId },
    }),
    props: ({
      getOrganizationGrades,
      ownProps: { organizationId, isData, isLoading },
    }) => {
      const organizationGrades = getOrganizationGradesFromCache({
        id: organizationId,
      });
      const grades = _.get(organizationGrades, "grades", []);

      const globalGradesGroupedByCurriculumProgram = getGlobalGradesGroupedByCurriculumProgram(
        { grades }
      );

      return {
        globalGradesGroupedByCurriculumProgram,
        isData: !_.isEmpty(organizationGrades) && isData,
        isLoading:
          _.includes([1, 2], getOrganizationGrades["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  withLoader
)(EditOrgInfo);

EditOrgInfo.defaultProps = {
  mode: "create",
};

EditOrgInfo.propTypes = {
  mode: PropTypes.string,
  organizationDetails: PropTypes.object,
};
