import React from "react";
import classes from "./Header.scss";
import { ToddleIcon } from "SvgComponents";
import { PrintOutlined, ChevronLeftOutlined } from "@toddle-design/web-icons";
import { Button, Tooltip, IconButton } from "@toddle-design/web";
import { colors, fontStyle } from "Constants";
import { UILabel, I18nHOC } from "UIComponents";
import LabelComponent from "UnitPlans/components/LabelComponent";
import { ResourceDetailsContext } from "UnitPlans/modules/UnitPlanModule";
import { PlanathonSubmissionStars } from "AppComponents";
import { PAComponent } from "lib/PermissionAware";
const labelStyle = {
  fontSize: 14,
  color: colors.gray31,
  ...fontStyle.bold,
  overflow: "hidden",
  display: "-webkit-box",
  lineHeight: "1.8rem",
  height: 18,
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "1",
  maxWidth: "25ch",
};

const typeNames = {
  ASSESSMENT: "Assessment",
  LEARNING_ENGAGEMENT: "Learning Experience",
};

class Header extends React.PureComponent {
  componentDidUpdate = prevProps => {
    _.map(prevProps, (prop, key) => {
      if (this.props[key] != prop) {
        //console.log(key, prop);
      }
    });
  };

  getExtraRightButtonComponent = () => {
    const { t, onClickPrint, curriculumType } = this.props;

    if (curriculumType !== "UBD") {
      return (
        <Tooltip
          tooltip={`${t("print_with_label", { label: t("common:le_label") })}`}
          placement="bottom"
        >
          <IconButton
            icon={<PrintOutlined />}
            size="large"
            variant="outlined-subtle"
            onClick={() => onClickPrint()}
          />
        </Tooltip>
      );
    } else return null;
  };

  render() {
    const {
      onClose,
      totalCommentCount,
      unreadCommentCount,
      isCommentActive,
      onCommentClick,
      isUnitSummaryActive,
      onUnitSummaryClick,
      resourceTitle,
      onClickSave,
      t,
      showPlannerOverview = true,
    } = this.props;

    return (
      <ResourceDetailsContext.Consumer>
        {resourceDetails => {
          const {
            id: resourceId,
            paritaStarStatus,
            kristenStarStatus,
            juryStarStatus,
            paritaProcessed,
            kristenProcessed,
            juryProcessed,
          } = resourceDetails;
          return (
            <div className={classes.container}>
              <div className={classes.leftContainer} onClick={onClose}>
                <ChevronLeftOutlined size="x-small" />
                <ToddleIcon />
              </div>
              <div className={classes.middleContainer}>
                <UILabel
                  labelContainerStyle={{ marginBottom: 0 }}
                  labelComponent={
                    <LabelComponent
                      labelStyle={labelStyle}
                      label={resourceTitle || t("common:untitled")}
                      showComment={true}
                      extraRightButtonComponent={this.getExtraRightButtonComponent()}
                      onCommentClick={onCommentClick}
                      totalCommentCount={totalCommentCount}
                      unreadCommentCount={unreadCommentCount}
                      commentIconColor={colors.blue29}
                      isCommentActive={isCommentActive}
                      commentIconSize={"large"}
                      customCommentIconVariant={"outlined-subtle"}
                      showCommentIconAsActivated={isCommentActive}
                    />
                  }
                />
              </div>
              <div className={classes.rightContainer}>
                <PlanathonSubmissionStars
                  parentId={resourceId}
                  parentType={"ASSESSMENT"}
                  paritaStarStatus={paritaStarStatus}
                  kristenStarStatus={kristenStarStatus}
                  juryStarStatus={juryStarStatus}
                  paritaProcessed={paritaProcessed}
                  kristenProcessed={kristenProcessed}
                  juryProcessed={juryProcessed}
                />
                {showPlannerOverview && (
                  <PAComponent perm={"TeacherPortal:ShowUnitSummaryInLe"}>
                    <Button
                      size={"large"}
                      onClick={onUnitSummaryClick}
                      variant="outlined-subtle"
                    >
                      {isUnitSummaryActive
                        ? `${t("common:hide_with_label", {
                            label: t("common:planner_overview"),
                          })}`
                        : `${t("common:show_with_label", {
                            label: t("common:planner_overview"),
                          })}`}
                    </Button>
                  </PAComponent>
                )}
                <Button size={"large"} onClick={onClickSave}>
                  {t("common:save_exit")}
                </Button>
              </div>
            </div>
          );
        }}
      </ResourceDetailsContext.Consumer>
    );
  }
}

export default I18nHOC({ resource: "common" })(Header);
