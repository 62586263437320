import gql from "graphql-tag";
import {
  organizationPresetFragment,
  organizationFragment,
  teacherFragments,
  academicYearFragment,
  progressReportBaseTemplateFragment,
} from "./ToolingFragments";

export const updateBaseProgressReportTemplateMutation = gql`
  mutation updateBaseProgressReportTemplate($id: ID!, $body: JSON) {
    documentation {
      updateProgressReportBaseTemplate(input: { id: $id, body: $body }) {
        ...baseTemplateItemDetails
      }
    }
  }
  ${progressReportBaseTemplateFragment.baseTemplateItemDetails}
`;

export const internalToolsGenericMutation = gql`
  mutation internalToolsGenericMutation(
    $apiType: INTERNAL_TOOL_API_TYPE_ENUM!
    $params: JSON!
  ) {
    platform {
      internalToolsGenericMutation(
        input: { apiType: $apiType, params: $params }
      ) {
        isSuccess
        warning
        data
      }
    }
  }
`;

export const createOrganizationMutation = gql`
  mutation createOrganization(
    $name: String!
    $code: String
    $planId: ID
    $logo: String
    $academicYears: [DateRange!]
    $schedulerWeekLength: Int
    $schedulerStartDay: Int
    $schedulerEndTime: String
    $schedulerStartTime: String
    $locale: String
    $additionalData: JSON
    $toddleBuddy: String
    $curriculumType: [CURRICULUM_PROGRAM_TYPE_ENUM!]
    $curriculumOptions: [CurriculumOptionsInput]
    $admins: [OrganizationCreateAdminInput]
    $rosteringMethod: ROSTERING_METHOD_ENUM
    $organizationConstants: [OrganizationConstantsInput]
    $examSessionMonth: EXAM_SESSION_MONTH_ENUM
  ) {
    platform {
      createOrganization(
        input: {
          name: $name
          code: $code
          planId: $planId
          logo: $logo
          locale: $locale
          toddleBuddy: $toddleBuddy
          curriculumType: $curriculumType
          scheduler: {
            startDay: $schedulerStartDay
            weekLength: $schedulerWeekLength
            startTime: $schedulerStartTime
            endTime: $schedulerEndTime
          }
          academicYears: $academicYears
          additionalData: $additionalData
          curriculumOptions: $curriculumOptions
          admins: $admins
          rosteringMethod: $rosteringMethod
          organizationConstants: $organizationConstants
          examSessionMonth: $examSessionMonth
        }
      ) {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationList}
`;

export const createOrganizationV2Mutation = gql`
  mutation createOrganizationV2(
    $name: String!
    $code: String
    $logo: String
    $academicYears: [DateRange!]
    $schedulerWeekLength: Int
    $schedulerStartDay: Int
    $schedulerEndTime: String
    $schedulerStartTime: String
    $locale: String
    $attachments: [AttachmentInput]
    $curriculumOptions: [CurriculumOptionsInput]
    $admins: [OrganizationCreateAdminInput]
    $rosteringMethod: ROSTERING_METHOD_ENUM
    $organizationConstants: [OrganizationConstantsInput]
    $examSessionMonth: EXAM_SESSION_MONTH_ENUM
  ) {
    platform {
      createOrganizationV2(
        input: {
          name: $name
          code: $code
          logo: $logo
          locale: $locale
          scheduler: {
            startDay: $schedulerStartDay
            weekLength: $schedulerWeekLength
            startTime: $schedulerStartTime
            endTime: $schedulerEndTime
          }
          academicYears: $academicYears
          curriculumOptions: $curriculumOptions
          admins: $admins
          rosteringMethod: $rosteringMethod
          organizationConstants: $organizationConstants
          examSessionMonth: $examSessionMonth
          attachments: $attachments
        }
      ) {
        organization {
          ...organizationItem
        }
      }
    }
  }
  ${organizationFragment.organizationList}
`;

export const updateOrganizationMutation = gql`
  mutation updateOrganization(
    $id: ID!
    $name: String
    $code: String
    $planId: ID
    $logo: String
    $schedulerWeekLength: Int
    $schedulerStartDay: Int
    $schedulerEndTime: String
    $schedulerStartTime: String
    $locale: String
    $additionalData: JSON
    $toddleBuddy: String
    $curriculumType: [CURRICULUM_PROGRAM_TYPE_ENUM!]
    $curriculumOptions: [CurriculumOptionsInput]
    $admins: [OrganizationCreateAdminInput]
  ) {
    platform {
      updateOrganization(
        input: {
          name: $name
          code: $code
          planId: $planId
          logo: $logo
          id: $id
          locale: $locale
          toddleBuddy: $toddleBuddy
          scheduler: {
            startDay: $schedulerStartDay
            weekLength: $schedulerWeekLength
            startTime: $schedulerStartTime
            endTime: $schedulerEndTime
          }
          additionalData: $additionalData
          curriculumType: $curriculumType
          admins: $admins
          curriculumOptions: $curriculumOptions
        }
      ) {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.organizationList}
`;

export const updateCurriculumProgramsMutation = gql`
  mutation updateOrganizationV2(
    $id: ID!
    $curriculumOptions: [CurriculumOptionsUpdateInput]
    $examSessionMonth: EXAM_SESSION_MONTH_ENUM
  ) {
    platform {
      updateOrganizationV2(
        input: {
          id: $id
          curriculumOptions: $curriculumOptions
          examSessionMonth: $examSessionMonth
        }
      ) {
        organization {
          ...organizationItem
        }
      }
    }
  }
  ${organizationFragment.organizationList}
`;

export const createOrganizationPresetMutation = gql`
  mutation createOrganizationPreset(
    $name: String!
    $domains: [String!]
    $city: String
    $country: String
    $ibCode: String
  ) {
    organizationManagement {
      createOrganizationPreset(
        input: {
          name: $name
          domains: $domains
          city: $city
          country: $country
          ibCode: $ibCode
        }
      ) {
        id
      }
    }
  }
`;

export const updateOrganizationPresetMutation = gql`
  mutation updateOrganizationPreset(
    $id: ID!
    $name: String!
    $domains: [String!]
    $city: String
    $country: String
    $ibCode: String
  ) {
    organizationManagement {
      updateOrganizationPreset(
        input: {
          id: $id
          name: $name
          domains: $domains
          city: $city
          country: $country
          ibCode: $ibCode
        }
      ) {
        ...organizationItem
      }
    }
  }
  ${organizationPresetFragment.orgItem}
`;

export const deleteOrganizationPresetMutation = gql`
  mutation deleteOrganizationPreset($id: ID!) {
    organizationManagement {
      deleteOrganizationPreset(input: { id: $id })
    }
  }
`;

export const approveOrganizationMutation = gql`
  mutation approveSchool(
    $id: ID!
    $name: String!
    $domains: [String!]
    $city: String
    $country: String
    $ibCode: String
  ) {
    organizationManagement {
      approveSchool(
        input: {
          id: $id
          name: $name
          domains: $domains
          city: $city
          country: $country
          ibCode: $ibCode
        }
      ) {
        id
      }
    }
  }
`;

export const deleteApproveSchoolRequestMutation = gql`
  mutation deleteApproveSchoolRequest($id: ID!) {
    organizationManagement {
      deleteApproveSchoolRequest(input: { id: $id }) {
        warning
        isSuccess
      }
    }
  }
`;

export const approveUserMutation = gql`
  mutation approveUser($presetOrgId: ID!, $unauthenticatedUserId: ID!) {
    school {
      approveFirstUserWorkEmailRequest(
        presetOrgId: $presetOrgId
        unauthenticatedUserId: $unauthenticatedUserId
      )
    }
  }
`;

export const deleteApproveUserRequestMutation = gql`
  mutation deleteApproveUserRequest($id: ID!) {
    organizationManagement {
      deleteApproveUserRequest(input: { id: $id }) {
        warning
        isSuccess
      }
    }
  }
`;

// User Feed

export const editTeacherMutation = gql`
  mutation editTeacher(
    $firstName: String
    $lastName: String
    $id: ID!
    $email: String
    $profileImage: String
    $removedCourses: [ID!]
    $addedCourses: [ID!]
    $role: ORG_USER_ROLES_ENUM
    $designation: ID
    $status: ORG_USER_STATUS_ENUM
  ) {
    platform {
      updateUser(
        input: {
          id: $id
          designation: $designation
          firstName: $firstName
          lastName: $lastName
          profileImage: $profileImage
          email: $email
          addedCourses: $addedCourses
          removedCourses: $removedCourses
          status: $status
          role: $role
        }
      ) {
        ...teacherItem
      }
    }
  }
  ${teacherFragments.teacherFeed}
`;

export const createOrganizationInvitedUserMutation = gql`
  mutation createOrganizationInvitedUser($users: [UserInput!]) {
    platform {
      addUsers(input: $users) {
        warning
        response {
          ...teacherItem
        }
      }
    }
  }
  ${teacherFragments.teacherFeed}
`;

//Edit Standard
export const editStandardInfoMutation = gql`
  mutation editStandardInfo($input: StandardInfo!) {
    planner {
      updateStandardInfo(input: $input) {
        id
        title
        description
        globalSubjects
      }
    }
  }
`;

// Create or Edit Academic Year
// While Creating it will pass OrgId, While Editing it will pass Academic Year's id
export const updateOrganizationAcademicYearMutation = gql`
  mutation updateOrganization(
    $orgId: ID!
    $id: ID!
    $startDate: String!
    $endDate: String!
  ) {
    organizationManagement {
      manageOrganizationAcademicYear(
        input: {
          id: $orgId
          academicYear: {
            id: $id
            dates: { startDate: $startDate, endDate: $endDate }
          }
        }
      ) {
        warning
        response {
          ...academicYear
        }
      }
    }
  }
  ${academicYearFragment.academicYear}
`;

export const createOrganizationAcademicYearMutation = gql`
  mutation updateOrganization(
    $id: ID!
    $startDate: String!
    $endDate: String!
  ) {
    organizationManagement {
      manageOrganizationAcademicYear(
        input: {
          id: $id
          newAcademicYear: { startDate: $startDate, endDate: $endDate }
        }
      ) {
        warning
        response {
          ...academicYear
        }
      }
    }
  }
  ${academicYearFragment.academicYear}
`;

export const deleteOrganizationMutation = gql`
  mutation deleteOrganization($id: ID!) {
    platform {
      deleteOrganization(input: { id: $id }) {
        isSuccess
      }
    }
  }
`;

export const updateConfigurablePermissionMutation = gql`
  mutation updateConfigurablePermission(
    $id: ID!
    $permissions: [ConfigurablePermissionToggleInput!]!
  ) {
    platform {
      toggleOrganizationConfigurablePermission(
        input: { organizationId: $id, permissions: $permissions }
      )
    }
  }
`;

export const deleteStandardMutation = gql`
  mutation deleteStandard($id: ID!) {
    planner {
      deleteStandardBenchmarkSet(input: { id: $id }) {
        isSuccess
      }
    }
  }
`;

export const updateOrganizationPaidStatusMutation = gql`
  mutation updateOrganizationPaidStatus(
    $organizationId: ID!
    $isPaid: Boolean!
  ) {
    organizationManagement {
      updateOrganizationPaidStatus(
        input: { organizationId: $organizationId, isPaid: $isPaid }
      )
    }
  }
`;

export const validateInternalToolsModulePasswordMutation = gql`
  mutation validateInternalToolsModulePasswordMutation(
    $input: InternalToolsModulePasswordInput!
  ) {
    platform {
      validateInternalToolsModulePassword(input: $input)
    }
  }
`;
