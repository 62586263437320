import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import UnitPlanCreate from "UnitPlans/routes/UnitPlanCreate";
import UnitPlanDetails from "UnitPlanDetails";

export default store => ({
  path: ":insightChildRoute",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            const poiReducer = require("POI/modules/POIModule").default;
            const teacherReducer = require("Teacher/modules/TeacherModule")
              .default;
            const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
              .default;
            injectReducer(store, { key: "poiAdmin", reducer: poiReducer });
            injectReducer(store, { key: "teacher", reducer: teacherReducer });
            injectReducer(store, { key: "unitPlans", reducer: unitPlanModule });

            resolve(component);
          },
          err => {
            reject(err);
          },
          "organization-educatorCenter"
        );
      })
  ),
  onEnter(nextState, replace) {
    // Redirect old routes
    const { location: { pathname } = {} } = nextState;
    if (_.includes(pathname, "/planningInsights/tabs")) {
      const newPath = _.replace(pathname, "/tabs", "");
      replace(newPath);
    }
  },
  childRoutes: [UnitPlanCreate(store), UnitPlanDetails(store)],
});
