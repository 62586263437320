import client from "apolloClient";
import {
  studentDetailsFragment,
  studentNoteFragment,
  organizationFragment,
  studentProfileResponseFragment,
  studentProfileSectionFragment,
  studentProfileQuestionFragment,
  studentOtherDetailsFragment,
  personalContactsFragment,
} from "./StudentProfileFragments";

export const getStudentNotesFromCache = ({ studentId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${studentId}`,
      fragment: studentDetailsFragment.studentNotes,
      fragmentName: "studentNotesItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentNotesToCache = ({ studentId, data }) => {
  try {
    client.writeFragment({
      id: `Student:${studentId}`,
      fragment: studentDetailsFragment.studentNotes,
      fragmentName: "studentNotesItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStudentNoteFromCache = ({ noteId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `StudentNote:${noteId}`,
      fragment: studentNoteFragment.studentNote,
      fragmentName: "studentNoteItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentNoteToCache = ({ noteId, data }) => {
  try {
    client.writeFragment({
      id: `StudentNote:${noteId}`,
      fragment: studentNoteFragment.studentNote,
      fragmentName: "studentNoteItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStudentDetailsFromCache = ({ studentId, courseFilters }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${studentId}`,
      variables: {
        courseFilters,
      },
      fragment: studentDetailsFragment.studentDetails,
      fragmentName: "studentDetailsItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentDetailsToCache = ({ studentId, data }) => {
  try {
    client.writeFragment({
      id: `Student:${studentId}`,
      fragment: studentDetailsFragment.studentDetails,
      fragmentName: "studentDetailsItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStudentProfileResponsesFromCache = ({ studentId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${studentId}`,
      fragment: studentDetailsFragment.studentProfileResponses,
      fragmentName: "studentProfileResponsesItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentProfileResponsesToCache = ({ studentId, data }) => {
  try {
    client.writeFragment({
      id: `Student:${studentId}`,
      fragment: studentDetailsFragment.studentProfileResponses,
      fragmentName: "studentProfileResponsesItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStudentProfileResponseFromCache = ({ responseId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `StudentProfileResponse:${responseId}`,
      fragment: studentProfileResponseFragment.studentProfileResponse,
      fragmentName: "studentProfileResponseItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentProfileResponseToCache = ({ responseId, data }) => {
  try {
    client.writeFragment({
      id: `StudentProfileResponse:${responseId}`,
      fragment: studentProfileResponseFragment.studentProfileResponse,
      fragmentName: "studentProfileResponseItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getOrganizationStudentNoteCategoryFromCache = ({
  organizationId,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationFragment.studentNoteCategory,
      fragmentName: "organizationItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationStudentProfileSectionFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationFragment.studentProfileSections,
      fragmentName: "organizationItem",
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeOrganizationStudentProfileSectionInCache = ({
  organizationId,
  data,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationFragment.studentProfileSections,
      fragmentName: "organizationItem",
      data,
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getStudentProfileSectionFromCache = ({ sectionId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `StudentProfileSection:${sectionId}`,
      fragment: studentProfileSectionFragment.studentProfileSection,
      fragmentName: "studentProfileSectionItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentProfileSectionInCache = ({ sectionId, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `StudentProfileSection:${sectionId}`,
      fragment: studentProfileSectionFragment.studentProfileSection,
      fragmentName: "studentProfileSectionItem",
      data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getStudentProfileQuestionFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `StudentProfileQuestion:${id}`,
      fragment: studentProfileQuestionFragment.studentProfileQuestion,
      fragmentName: "studentProfileQuestionItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentProfileQuestionInCache = ({ id, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `StudentProfileQuestion:${id}`,
      fragment: studentProfileQuestionFragment.studentProfileQuestion,
      fragmentName: "studentProfileQuestionItem",
      data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getStudentOtherDetailsFromCache = ({ studentId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${studentId}`,
      fragment: studentOtherDetailsFragment.studentOtherDetails,
      fragmentName: "studentOtherDetailsItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentOtherDetailsToCache = ({ studentId, data }) => {
  try {
    client.writeFragment({
      id: `Student:${studentId}`,
      fragment: studentOtherDetailsFragment.studentOtherDetails,
      fragmentName: "studentOtherDetailsItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getPersonalContactCache = ({ contactId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PersonalContact:${contactId}`,
      fragment: personalContactsFragment.personalContacts,
      fragmentName: "personalContactsItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writePersonalContactToCache = ({ contactId, data }) => {
  try {
    client.writeFragment({
      id: `PersonalContact:${contactId}`,
      fragment: personalContactsFragment.personalContacts,
      fragmentName: "personalContactsItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const writeStudentFamilyInfoToCache = ({ familyId, data }) => {
  try {
    client.writeFragment({
      id: `FamilyMember:${familyId}`,
      fragment: studentDetailsFragment.familyInfo,
      fragmentName: "familyInfo",
      data,
    });
  } catch (e) {}
};

export const getStudentFamilyInfoFromCache = ({ familyId }) => {
  try {
    const queryData = client.readFragment({
      id: `FamilyMember:${familyId}`,
      fragment: studentDetailsFragment.familyInfo,
      fragmentName: "familyInfo",
    });

    return queryData ? queryData : {};
  } catch (e) {
    return {};
  }
};
