import moment from "moment";

export const getYearDurationFormat = ({ startDate, endDate }) => {
  const startYear = `${moment(startDate).format("YYYY")}`;

  if (moment(startDate).year() != moment(endDate).year()) {
    return `${startYear} - ${moment(endDate).format("YY")}`;
  }

  return startYear;
};

export const getMonthDurationFormat = ({ startDate, endDate }) => {
  const startMonth = moment(startDate).format("MMM");
  const endMonth = moment(endDate).format("MMM");

  return startMonth !== endMonth
    ? `${moment(startDate).format("MMM D")} - ${moment(endDate).format(
        "MMM D"
      )}`
    : `${moment(startDate).format("MMM D")} - ${moment(endDate).format("D")}`;
};

export const getMonthListOfGivenRange = ({ startDate, endDate }) => {
  let months = [];
  let currentDate = moment(startDate).startOf("month");
  while (moment(currentDate).isBefore(moment(endDate))) {
    let monthStartDate = moment(currentDate).startOf("month");
    let monthEndDate = moment(currentDate).endOf("month");
    months.push({
      startDate: monthStartDate.isBefore(moment(startDate))
        ? moment(startDate).format("YYYY-MM-DD")
        : moment(monthStartDate).format("YYYY-MM-DD"),
      endDate: monthEndDate.isBefore(moment(endDate))
        ? moment(monthEndDate).format("YYYY-MM-DD")
        : moment(endDate).format("YYYY-MM-DD"),
    });
    currentDate = moment(currentDate).add(1, "months");
  }

  return months;
};
