import client from "apolloClient";
import { getGlobalSearchResult } from "./GlobalSearchBarQueries";

export const getGlobalSearchResultFromCache = ({ filters }) => {
  try {
    const queryData = client.readQuery({
      query: getGlobalSearchResult,
      variables: filters,
    });
    return queryData ? queryData : {};
  } catch (e) {
    return {};
  }
};
