import React from "react";
import classes from "./EventFooter.scss";
import { ProfileCascade } from "UIComponents";
import { Button } from "@toddle-design/web";

const styles = {
  avatarStyles: {
    size: "medium",
  },
};

const EventFooter = React.memo(props => {
  const {
    createdBy,
    onConfirmBtnClick,
    itemType,
    isQuickReschedule,
    isButtonDisabled,
    t,
  } = props;
  return (
    <div className={classes.footer}>
      <div className={classes.leftFooter}>
        <div className={classes.profile}>
          <ProfileCascade
            items={[
              {
                id: _.get(createdBy, "id"),
                value: _.get(createdBy, "name"),
                imageUrl: _.get(createdBy, "profileImage"),
              },
            ]}
            showAvatar={true}
            avatarStyles={styles.avatarStyles}
          />
          <div className={classes.nameContainer}>
            <div className={classes.assignedBy}>{`Assigned by`}</div>
            <div className={classes.name}>{_.get(createdBy, "name")}</div>
          </div>
        </div>
      </div>
      <div className={classes.rightFooter}>
        <Button
          variant={isQuickReschedule ? "progressive" : "primary"}
          onClick={onConfirmBtnClick}
          disabled={isButtonDisabled}
          style={{ zIndex: 1 }}
        >
          {isQuickReschedule
            ? t("common:update")
            : itemType == "CALENDAR_EVENT_ITEM"
            ? t("calendar:join_now")
            : t("calendar:view_task")}
        </Button>
      </div>
    </div>
  );
});

EventFooter.displayName = "EventFooter";

EventFooter.defaultProps = {
  isButtonDisabled: false,
};

export default EventFooter;
