import gql from "graphql-tag";
import {
  snpEvaluationCycleFragment,
  snpActionFragment,
  snpPracticeEvidenceFragment,
} from "./SnPFragments";
export const updateSnPEvaluationCycleMutation = gql`
  mutation updateSnPEvaluationCycle(
    $year: Int
    $month: Int
    $isCreatedByDocument: Boolean
    $state: SNP_EVALUATION_CYCLE_STATUS_ENUM
    $id: ID!
  ) {
    platform {
      updateSnpEvaluationCycle(
        input: {
          year: $year
          month: $month
          id: $id
          state: $state
          isCreatedByDocument: $isCreatedByDocument
        }
      ) {
        ...snpEvaluationCycleItem
      }
    }
  }
  ${snpEvaluationCycleFragment.evaluationCycleDetails}
`;

export const createSnPEvaluationCycleMutation = gql`
  mutation createSnPEvaluationCycle($input: [SnpEvaluationCycleCreateInput]) {
    platform {
      createSnpEvaluationCycle(input: $input) {
        ...snpEvaluationCycleItem
      }
    }
  }
  ${snpEvaluationCycleFragment.evaluationCycleDetails}
`;

export const upsertSnpSelfStudyResponseMutation = gql`
  mutation upsertSnpSelfStudyResponse(
    $questionId: ID!
    $selfStudyId: ID!
    $response: JSON!
  ) {
    platform {
      upsertSnpSelfStudyResponse(
        input: {
          questionId: $questionId
          selfStudyId: $selfStudyId
          response: $response
        }
      ) {
        id
      }
    }
  }
`;

export const upsertSnpSelfStudySectionMutation = gql`
  mutation upsertSnpSelfStudySection(
    $sectionId: ID!
    $selfStudyId: ID!
    $isCompleted: Boolean
    $type: SNP_SECTION_TYPE_ENUM!
  ) {
    platform {
      upsertSnpSelfStudySection(
        input: {
          isCompleted: $isCompleted
          sectionId: $sectionId
          selfStudyId: $selfStudyId
          type: $type
        }
      ) {
        id
      }
    }
  }
`;

export const updateSelfStudyMutation = gql`
  mutation updateSelfStudy($id: ID!, $isCompleted: Boolean) {
    platform {
      updateSnpSelfStudy(input: { isCompleted: $isCompleted, id: $id }) {
        id
      }
    }
  }
`;

export const createSnPActionMutation = gql`
  mutation createSnPAction(
    $evaluationCycleId: ID!
    $practiceIds: [ID!]
    $fields: [SnPActionFieldInput!]
    $milestones: [SnPMilestoneCreateInput!]
    $evidence: [AttachmentInput!]
  ) {
    platform {
      createSnPAction(
        input: {
          evaluationCycleId: $evaluationCycleId
          practiceIds: $practiceIds
          fields: $fields
          milestones: $milestones
          evidence: $evidence
        }
      ) {
        ...snpActionItem
      }
    }
  }
  ${snpActionFragment.actionTableDetails}
`;

export const updateSnPActionMutation = gql`
  mutation updateSnPAction(
    $id: ID!
    $fields: [SnPActionFieldInput!]
    $addedPracticeIds: [ID!]
    $removedPracticeIds: [ID!]
    $addedMilestones: [SnPMilestoneCreateInput!]
    $removedMilestones: [ID!]
    $updatedMilestones: [SnPMilestoneUpdateInput!]
    $addedEvidence: [AttachmentInput!]
    $removedEvidence: [ID!]
  ) {
    platform {
      updateSnPAction(
        input: {
          id: $id
          fields: $fields
          addedPracticeIds: $addedPracticeIds
          removedPracticeIds: $removedPracticeIds
          addedMilestones: $addedMilestones
          removedMilestones: $removedMilestones
          updatedMilestones: $updatedMilestones
          addedEvidence: $addedEvidence
          removedEvidence: $removedEvidence
        }
      ) {
        ...snpActionItem
      }
    }
  }
  ${snpActionFragment.actionTableDetails}
`;

export const deleteSnPActionMutation = gql`
  mutation deleteSnPAction($id: ID!) {
    platform {
      deleteSnPAction(input: { id: $id })
    }
  }
`;

export const handleSnPEvidenceInPracticeMutation = gql`
  mutation handleSnPEvidenceInPractice(
    $id: ID!
    $removedEvidence: [ID!]
    $addedEvidence: [SnPEvidenceAddInput!]
  ) {
    platform {
      handleSnPEvidenceInPractice(
        input: {
          id: $id
          removedEvidence: $removedEvidence
          addedEvidence: $addedEvidence
        }
      ) {
        id
        evidence {
          totalCount
          edges {
            node {
              ...practiceEvidenceItem
            }
          }
        }
      }
    }
  }
  ${snpPracticeEvidenceFragment.practiceEvidence}
`;
