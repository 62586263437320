import update from "immutability-helper";
import client from "apolloClient";
import {
  createNewCommentTemplateMutation,
  updateCommentTemplateMutation,
  deleteCommentTemplateMutation,
  publishCommentBankMutation,
} from "./Mutation";
import { getAllCommentTemplatesQuery } from "./Queries";
import {
  getAllCommentTemplatesFromCache,
  writeProgressReportCommentTemplateInCache,
} from "./Helpers";
import { setToastMsg } from "Login/modules/LoginModule";
import ACLStore from "lib/aclStore";

export const NAME = "commentTemplates";

const SET_TEMPLATE_DATA = "SET_TEMPLATE_DATA" + " " + NAME;

export const setTemplateData = data => {
  return {
    type: SET_TEMPLATE_DATA,
    payload: data,
  };
};

export const createProgressReportCommentTemplate = ({ input, queryInput }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: createNewCommentTemplateMutation,
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAllCommentTemplatesQuery,
            variables: { filters: queryInput },
          },
        ],
      });
      if (_.isEmpty(response)) return false;
      else return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateProgressReportCommentTemplate = ({ input, queryInput }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: updateCommentTemplateMutation,
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAllCommentTemplatesQuery,
            variables: { filters: queryInput },
          },
        ],
      });
      if (_.isEmpty(response)) return false;
      else return response;
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteProgressReportCommentTemplate = ({ input, queryInput }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: deleteCommentTemplateMutation,
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAllCommentTemplatesQuery,
            variables: { filters: queryInput },
          },
        ],
      });
      if (_.isEmpty(response)) return false;
      else return response;
    } catch (e) {
      console.error(e);
    }
  };
};

export const publishCommentBank = () => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;
    const shouldUpdateComments = ACLStore.can(
      "FeatureFlag:PublishCommentBankUpdate"
    );
    try {
      const response = await client.mutate({
        mutation: publishCommentBankMutation,
        variables: {
          input: { organizationId: organizationId, shouldUpdateComments },
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:published_successfully",
          type: "tick",
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

const REDUCER_HANDLERS = {
  [SET_TEMPLATE_DATA]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

const initialState = {
  selectedTemplateId: null,
  mode: "VIEW",
  searchText: "",
  selectedFilter: "all",
  activeTab: "",
  first: 20,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
