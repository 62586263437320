import React, { useState, useRef, useEffect } from "react";
import classes from "./FileViewer.scss";
import IframeWrapper from "./IframeWrapper";
import { MS_FILE_VIEWER_URL } from "Constants";
import { FullScreenLoader, I18nHOC, EmbedVideoPlayer } from "UIComponents";
import { GiveFeedbackIcon } from "SvgComponents";
import ReactPlayer from "react-player";

const FileViewer = React.memo(
  ({
    file,
    mode,
    showFileHeader,
    shouldShowGiveFeedbackButton,
    onClickGiveFeedback,
    t,
  }) => {
    const mediaType = _.get(file, "type", "");
    const mimeType = _.get(file, "mimeType", "");
    let fileUrl = _.get(file, "url", "");
    const id = _.get(file, "id", "");
    const name = _.get(file, "name", "");
    const isVideoLink = mediaType == "LINK" && ReactPlayer.canPlay(fileUrl);

    let openInViewer = false;

    if (mediaType == "FILE" && mimeType != "application/pdf") {
      openInViewer = true;
    }
    //when the value of mode is "preview" the google doc url is getting converted to preview mode instead of edit using the following condition
    if (mode == "preview" && mediaType == "LINK") {
      let splittedLink = fileUrl.split("/");
      splittedLink[splittedLink.length - 1] = "preview";
      fileUrl = splittedLink.join("/");
    }

    const iframeSrc = openInViewer
      ? `${MS_FILE_VIEWER_URL}${fileUrl}`
      : fileUrl;

    const [isLoading, setIsLoading] = useState(true);
    const iframeRef = useRef(null);

    const iframeLoaded = e => {
      if (isLoading) {
        setIsLoading(false);
      }
    };

    if (isVideoLink) {
      if (isLoading) setIsLoading(false);
    }
    return (
      <div className={classes.container}>
        {showFileHeader && (
          <div className={classes.previewHeader}>{`Preview - ${name}`}</div>
        )}
        {isVideoLink ? (
          <EmbedVideoPlayer
            className={classes.embedPlayerContainer}
            url={fileUrl}
            thumbnail={file.thumbUrl}
          />
        ) : (
          <IframeWrapper
            iframeSrc={iframeSrc}
            iframeRef={iframeRef}
            iframeLoaded={iframeLoaded}
            isLoading={isLoading}
            id={id}
          />
        )}

        {shouldShowGiveFeedbackButton ? (
          <div className={classes.feedbackButton} onClick={onClickGiveFeedback}>
            <GiveFeedbackIcon width={16} height={16} />
            <span className={classes.feedbackButtonText}>
              {t("common:give_feedback_text")}
            </span>
          </div>
        ) : null}
        {isLoading && <FullScreenLoader />}
      </div>
    );
  }
);

FileViewer.displayName = "FileViewer";

FileViewer.defaultProps = {
  mode: "edit",
  showFileHeader: false,
};

export default I18nHOC({ resource: "common" })(FileViewer);
