import React from "react";
import { I18nHOC, SlickSlider } from "UIComponents";
import classes from "./UnitPreview.scss";
import { CLOUD_URL, colors } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import SignupButton from "../SignupButton";
import { isDesktop } from "Utils";

const url = `${CLOUD_URL}/assets/webapp/community_paccess/unitplan_public_access.png`;
const url2 = `${CLOUD_URL}/assets/webapp/community_paccess/unitplan_public_access_2_v3.png`;

const options = ({ t }) => [
  {
    title: t("community:unit_preview_1_title"),
    content: t("community:unit_preview_1_content"),
  },
  {
    title: t("community:unit_preview_2_title"),
    content: t("community:unit_preview_2_content"),
  },
  {
    title: t("community:unit_preview_3_title"),
    content: t("community:unit_preview_3_content"),
  },
  {
    title: t("community:unit_preview_4_title"),
    content: t("community:unit_preview_4_content"),
  },
];

const arrowStyle = {
  backgroundColor: "unset",
  boxShadow: "unset",
  borderWidth: 0,
};

const slickSliderStyle = {
  customPrevArrowStyle: {
    ...arrowStyle,
    top: "calc(100% + 19px)",
    left: "30%",
  },
  customNextArrowStyle: {
    ...arrowStyle,
    top: "calc(100% + 19px)",
    right: "30%",
  },
  customArrowIconContainerStyle: {
    fill: colors.gray72,
    width: 9,
    height: 16,
  },
};

const UnitPreview = ({ t, type }) => {
  const renderCarouselItem = ({ item }) => {
    const { id, title, content } = item;
    return (
      <div key={id} className={classes.carouselItem}>
        <div className={classes.carouselData}>
          <div className={classes.title}>{title}</div>
          <div className={classes.content}>{content}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          {t("community:public_access_unit_preview")}
        </div>
        <div className={classes.divider}></div>
        <div className={classes.subHeader}>
          {t("community:public_access_unit_preview_subheader")}
        </div>
        <div className={classes.imageContainer}>
          <img src={isDesktop() ? url2 : url} />
        </div>
        <div className={classes.carouselContainer}>
          <SlickSlider
            items={options({ t })}
            renderItem={renderCarouselItem}
            variableWidth={false}
            showDots={true}
            infinite={true}
            autoplay={true}
            containerStyle={{ width: "100%" }}
            {...slickSliderStyle}
          />
        </div>
        <SignupButton type={type} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: _.get(state, "login.userInfo.id"),
  };
};

export default compose(
  connect(mapStateToProps),
  I18nHOC({ resource: ["community", "common"] })
)(UnitPreview);
