import { timezoneList } from "Constants/timezone";

export const getTimezoneList = () => {
  return _.map(timezoneList, ({ label, timezone, zone }) => {
    return { value: zone, label: `(${timezone}) ${label}` };
  });
};

export const getTimezoneDetailsByZone = ({ zone }) => {
  const { label, timezone } = _.find(
    timezoneList,
    ({ zone: currentZone }) => zone === currentZone
  );

  return { zone, label: `(${timezone}) ${label}` };
};
