import { htmlToText as _htmlToText } from "html-to-text";

/**
 * Inserts `text` at the cursor position in an input/textarea element
 * and dispatch element's onchange handler with the updated value
 *
 * @param {HTMLInputElement} inputElement
 * @param {String} text
 */
export const insertAtCursorPosition = (inputElement, text) => {
  const { selectionStart, selectionEnd } = inputElement;
  const ev = new Event("input", {
    bubbles: true,
  });
  inputElement.focus();
  inputElement.setRangeText(text, selectionStart, selectionEnd, "end");
  inputElement.dispatchEvent(ev);
};

/**
 * Inserts stringified html and replaces spaces, &nbsp;, <div> and </div> with
 * empty string and checks whether its empty.
 * Userful for richtext editor.
 *
 * @param {String} htmlString
 */
export const isEmptyHtml = htmlString => {
  return _.isEmpty(
    _.trim(
      htmlString
        .replace(/\s+(?=[^<>]*<)/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/<div>/gi, "")
        .replace(/<\/div>/gi, "")
    )
  );
};

/**
 * @param {String} string
 * @param {Object} options
 * @returns {String}
 *
 * @description see https://github.com/html-to-text/node-html-to-text for more info
 */
export const htmlToText = (string, options) => {
  if (_.isNil(options)) {
    options = {
      wordwrap: 130,
      uppercaseHeadings: false,
      tags: {
        a: { options: { ignoreHref: true } },
        ul: { options: { itemPrefix: " " } },
      },
    };
  }
  return _htmlToText(string, options);
};
