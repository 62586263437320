import { injectReducer } from "store/reducers";
import {
  CircularRoute,
  Calendar,
  SchoolSetup,
  Trash,
  SnP,
  SnP_v2,
  Settings,
  SchoolCalendar,
  IntegrationSettings,
  Transcripts,
  YearGroupManager,
} from "./routes";
import CurriculumProgram from "./routes/CurriculumProgram";
import { withAsyncRouteLoading } from "UIComponents";
import POI from "POI";
import { AssessmentDetails } from "Assessments";

export default store => ({
  path: "administrator",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./containers/AdministratorContainer")
              .default;
            const reducer = require("./modules/AdministratorModule").default;
            injectReducer(store, { key: "administrator", reducer });

            const commonReducerForAdmin = require("./modules/CommonModule");
            const commonAdminReducer = commonReducerForAdmin.default;
            const commonAdminKey = commonReducerForAdmin.NAME;
            injectReducer(store, {
              key: commonAdminKey,
              reducer: commonAdminReducer,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "administrator"
        );
      })
  ),
  childRoutes: [
    YearGroupManager(store),
    Transcripts(store),
    POI(store),
    CircularRoute(store),
    Calendar(store),
    SchoolCalendar(store),
    SchoolSetup(store),
    Trash(store),
    SnP(store),
    Settings(store),
    IntegrationSettings(store),
    AssessmentDetails(store),
    YearGroupManager(store),
    SnP_v2(store),
    CurriculumProgram(store), // this needs to be ad bottom otherwise routes after this will not be matched
  ],
});
