import { getRethumbUrl } from "Utils";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
let isActive = false;
const loadScript = async (scriptsrc, uniquescriptid) => {
  const existingScript = document.getElementById(uniquescriptid);
  if (!existingScript) {
    return new Promise(function (resolve, reject) {
      const script = document.createElement("script");
      script.src = scriptsrc;
      script.id = uniquescriptid;
      document.body.appendChild(script);
      script.onload = resolve;
    });
  } else return;
};
const loadUpvoty = async () => {
  await loadScript(
    "https://toddle.upvoty.com/javascript/upvoty.embed.js",
    "upvotyscript"
  );
  isActive = true;
};
const identifyUserUpvoty = async (userid, fullname, email, profileimage) => {
  if (isActive) {
    const imageUrl = getRethumbUrl({
      width: 200,
      height: 200,
      imageUrl: profileimage || RESOURCE_PLACEHOLDER_URL,
      fitIn: false,
    });
    window.upvoty.init("identify", {
      user: {
        id: userid,
        name: fullname,
        email: email,
        avatar: imageUrl,
      },
      baseUrl: "feedback.toddleapp.com",
      publicKey: "972aca83dcce8feb73630758121abb9a",
    });
  }
};
export { loadUpvoty, identifyUserUpvoty };
