import React from "react";
import update from "immutability-helper";
import {
  setToastMsg,
  getUserInfo,
  hasCommunityDataLoaded,
} from "Login/modules/LoginModule";
import client from "apolloClient";
import { goToRelativeRoute } from "modules/Services";
import {
  updateEntityRatingMutation,
  addToCommunityMutation,
  createFollowMutation,
  removeFollowMutation,
  addLikeMutation,
  removeLikeMutation,
  unpublishEntityMutation,
  shareEntityMutation,
  handleEntityTagMutation,
  inviteUserToCommunityMutation,
  activationEmailerMutation,
  upvoteResourceRequestMutation,
  createResourceRequestMutation,
} from "Community/modules/CommunityMutations";
import {
  getEntityRatingFromCache,
  writeEntityRatingToCache,
  writeSaveByNodeToCache,
  getSaveByNodeFromCache,
  getUserCommunityStatusFromCache,
  writeUserCommunityStatusToCache,
  getLikesNodeFromCache,
  writeLikesNodeToCache,
  getCommunityUserDetailsFromCache,
  writeCommunityUserDetailsToCache,
  writeResourceRequestInCache,
} from "Community/modules/CommunityGraphqlHelpers";
import { getUserCommunityStatusQuery } from "Community/modules/CommunityQuery";
import {
  getCommunityCommonFeedFromCache,
  writeCommunityCommonFeedToCache,
} from "CommunityParentEntity/modules/CommunityParentEntityGraphqlHelpers";
import { updateUserBasicInfoMutation } from "OnBoarding/modules/OnBoardingMutations";
import config from "OnBoarding/components/config";
import {
  updateCoachMarkStatus,
  updateCoachMarkViewStatus,
  getCoachMarkStatusDetails,
} from "modules/CoachMarksModule";
import { goToCommunityOnboarding } from "modules/NavigationModule";
import { isCommunitySharingURL } from "Utils/CommunityHelpers.js";

export const CommunityContext = React.createContext();
import * as EventTracker from "lib/eventTracker";
const stage = _.get(config, "stage", []);

export const THEMES = [
  { label: "How the world works", value: "HOWTHE" },
  { label: "How we express ourselves", value: "HOWWEE" },
  { label: "How we organize ourselves", value: "HOWWEO" },
  { label: "Where we are in place and time", value: "WHEREW" },
  { label: "Who we are", value: "WHOWEA" },
  { label: "Sharing the planet", value: "SHARIN" },
];

import { generateRandomId } from "Utils";
import { getOrganizationCurriculumProgramsFromCache } from "modules/CommonGraphqlHelpers";

export const NAME = "community";
export const SET_ACTIVE_PARENT = "SET_ACTIVE_PARENT" + " " + NAME;
export const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION" + " " + NAME;
export const SET_COMMUNITY_ACTIVE_TAB = "SET_COMMUNITY_ACTIVE_TAB" + " " + NAME;
export const SET_PD_RESOURCES_NEW_KEY = "SET_PD_RESOURCES_NEW_KEY" + " " + NAME;
export const SET_PROFILE_DETAIL_MODAL = "SET_PROFILE_DETAIL_MODAL" + " " + NAME;
export const SET_JOIN_COMMUNITY_MODAL = "SET_JOIN_COMMUNITY_MODAL" + " " + NAME;
export const SET_COACHMARK_RENDER_STATUS =
  "SET_COACHMARK_RENDER_STATUS" + " " + NAME;
export const SET_RESOURCE_REQUEST_PAGE =
  "SET_RESOURCE_REQUEST_PAGE" + " " + NAME;
export const SET_LOCATION_BEFORE_ON_BOARDING =
  "SET_LOCATION_BEFORE_ON_BOARDING" + " " + NAME;
export const SET_IS_RESOURCE_REQUEST_MODAL_OPEN =
  "SET_IS_RESOURCE_REQUEST_MODAL_OPEN" + " " + NAME;

export const COMMUNITY_TABS = [
  {
    label: "common:unit_plural",
    value: "units",
    parentValue: "all",
    entityType: "UNIT_PLAN",
    profileFirst: 2,
    isChildEntity: true,
    eventPageName: "all units",
  },
  {
    label: "common:le_label_plural",
    value: "les",
    parentValue: "all",
    entityType: "ASSESSMENT",
    profileFirst: 3,
    isChildEntity: true,
    eventPageName: "all learning experiences",
  },

  // {
  //   label: "community:pd_resources",
  //   parentValue: "pdresources",
  //   value: "pdresources",
  //   isChildEntity: false
  // }
];

export const setActiveParentValue = data => {
  return { type: SET_ACTIVE_PARENT, payload: data };
};

export const setActiveSectionValue = data => {
  return { type: SET_ACTIVE_SECTION, payload: data };
};

export const setNewPdResourcesKey = () => {
  return { type: SET_PD_RESOURCES_NEW_KEY };
};

export const setCommunityActiveTab = data => {
  return { type: SET_COMMUNITY_ACTIVE_TAB, payload: data };
};

export const setActiveProfileDetailModal = data => {
  return { type: SET_PROFILE_DETAIL_MODAL, payload: data };
};

export const setJoinCommunityModal = data => {
  return { type: SET_JOIN_COMMUNITY_MODAL, payload: data };
};

export const setCoachmarkRenderStatus = data => {
  return { type: SET_COACHMARK_RENDER_STATUS, payload: data };
};

export const setResourceRequestPage = data => {
  return async dispatch => {
    if (data) {
      const hasAccess = await dispatch(isUserAuthenticatedForAction());
      if (!hasAccess) return;
    }

    dispatch({ type: SET_RESOURCE_REQUEST_PAGE, payload: data });
  };
};

export const showResourceRequestModal = () => {
  return async dispatch => {
    const hasAccess = await dispatch(isUserAuthenticatedForAction());
    if (!hasAccess) return;

    dispatch({ type: SET_IS_RESOURCE_REQUEST_MODAL_OPEN, payload: true });
  };
};

export const hideResourceRequestModal = () => {
  return { type: SET_IS_RESOURCE_REQUEST_MODAL_OPEN, payload: false };
};

export const isCommunityActiveCheckByOnBoardingStatus = ({
  onBoardingStatus,
}) => {
  return onBoardingStatus > stage.length;
};
export const checkCommunityActiveStatusAndGoToOnboarding = ({
  isForced = false,
  saveLocation = true,
  openOnBoarding = true,
  isNetworkOnly = false,
  search,
} = {}) => {
  return async (dispatch, getState) => {
    if (!isForced) {
      return true;
    }
    const userLoggedIn = _.get(getState(), "login.userLoggedIn");
    if (!hasCommunityDataLoaded({ userLoggedIn })) return;

    const userInfo = getUserInfo({ portalType: "COMMUNITY" });
    const { id } = userInfo || {};

    let statusData = isNetworkOnly
      ? {}
      : getUserCommunityStatusFromCache({ id });
    if (_.isEmpty(statusData)) {
      const response = await client.query({
        query: getUserCommunityStatusQuery,
        variables: {
          id,
        },
        fetchPolicy: "network-only",
      });
      statusData = _.get(response, "data", {});
    }

    const onBoardingStatus = _.get(
      statusData,
      "node.communityDetails.onboardingStatus",
      0
    );

    const isActive = isCommunityActiveCheckByOnBoardingStatus({
      onBoardingStatus,
    });

    if (!isActive) {
      if (openOnBoarding) {
        dispatch(goToCommunityOnboarding({ saveLocation, search }));
      }
      return false;
    }

    return true;
  };
};

export const updateCommunityStatus = ({ onboardingStatus }) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const statusData = getUserCommunityStatusFromCache({
      id: userId,
    });

    const updatedData = update(statusData, {
      node: {
        communityDetails: { onboardingStatus: { $set: onboardingStatus } },
      },
    });

    // console.log(statusData, onboardingStatus, updatedData);

    writeUserCommunityStatusToCache({
      id: userId,
      data: updatedData,
    });
    try {
      await client.mutate({
        mutation: updateUserBasicInfoMutation,
        variables: { id: userId, onboardingStatus },
      });
      return true;
    } catch (e) {
      console.error(e);
      writeUserCommunityStatusToCache({ id: userId, data: statusData });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const addToCommunity = data => {
  return async (dispatch, getState) => {
    try {
      const {
        entityType,
        grades,
        about,
        tags,
        leId,
        unitId,
        collaborators,
        portalType = "PLANNER",
        entityName,
        eventTarget,
      } = data;

      const platformUserInfo = getUserInfo({ portalType: "PLANNER" });
      const communityUserInfo = getUserInfo({ portalType: "COMMUNITY" });
      const platformOrgId = _.get(platformUserInfo, "org_id");
      const communityOrgId = _.get(communityUserInfo, "org_id");
      const organizationCurriculumData = getOrganizationCurriculumProgramsFromCache(
        communityOrgId
      );
      const targetCurriculumProgramId = _.get(
        organizationCurriculumData,
        "curriculumPrograms[0].id",
        ""
      );
      const input = {
        id: entityType === "ASSESSMENT" ? leId : unitId,
        type: entityType,
        grades: grades || [],
        about: about || "",
        tags: tags || [],
        collaborators: _.map(collaborators, "id") || [],
        portalType,
        sourceOrganizationId: platformOrgId,
        targetCurriculumProgramId,
      };

      const response = await client.mutate({
        mutation: addToCommunityMutation,
        variables: {
          ...input,
        },
      });

      EventTracker.recordEvent({
        eventName: "Contributed",
        eventData: {
          entity_type: entityType,
          entity_id: _.get(response, "data.community.submitToCommunity", ""),
          source: "community",
          entity_name: entityName,
          target: eventTarget,
        },
      });
      dispatch(
        updateCoachMarkStatus({
          type: "COMMUNITY_CONTRIBUTE_BUTTON",
          portalType: "COMMUNITY",
        })
      );
      return true;
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const inviteUserToCommunity = data => {
  return async (dispatch, getState) => {
    try {
      const { emails = [] } = data;

      const response = await client.mutate({
        mutation: inviteUserToCommunityMutation,
        variables: {
          emails,
          portalType: "PLANNER",
        },
      });

      return true;
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const activationEmailerStatus = data => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: activationEmailerMutation,
        variables: data,
      });
      return _.get(result, "data.community.trackActivationEmail", {});
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const setActiveParent = ({ value, route, type = "push" }) => {
  return async (dispatch, getState) => {
    if (!value) {
      return;
    }
    if (value != "featured" || value != "pdresources") {
      dispatch(setCommunityActiveTab(""));
    }
    if (value == "pdresources") {
      dispatch(setNewPdResourcesKey());
    }
    dispatch(setActiveParentValue(value));
    if (isCommunitySharingURL()) {
      dispatch(
        goToRelativeRoute({
          route: `community/home/${route ? route : value}`,
          type,
          replacePath: "home",
        })
      );
    } else {
      dispatch(
        goToRelativeRoute({
          route: `home/${route ? route : value}`,
          type,
          replacePath: "home",
        })
      );
    }
  };
};

export const navigateToUserProfile = ({ id }) => {
  return async (dispatch, getState) => {
    dispatch(setActiveParent({ value: "profile", route: `profile/${id}` }));
  };
};

export const goToUserProfile = profileId => {
  return async (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({
        route: `/community/home/profile/${profileId}`,
      })
    );
  };
};

export const updateRating = ({ rating, entity, entityType }) => {
  return async (dispatch, getState) => {
    const isActiveCommunity = await dispatch(
      checkCommunityActiveStatusAndGoToOnboarding()
    );
    if (!isActiveCommunity) {
      return;
    }
    const entityRatingData = getEntityRatingFromCache({
      id: entity,
      entityType,
    });
    let entityRatings = _.get(entityRatingData, "node.entityRatings", []);
    const userId = getState().login.userInfo.id;
    const likedByMe = _.find(
      entityRatings,
      item =>
        _.get(item, "rating.id") == rating && _.get(item, "user.id") == userId
    );

    if (likedByMe) {
      entityRatings = _.filter(entityRatings, item => {
        return item.id != _.get(likedByMe, "id", "");
      });
    } else {
      entityRatings = [
        ...(entityRatings || []),
        {
          id: generateRandomId(),
          rating: { id: rating, __typename: "Rating" },
          user: { id: userId, __typename: "User" },
          __typename: "EntityRating",
        },
      ];
    }
    const updateData = update(entityRatingData, {
      node: { entityRatings: { $set: entityRatings } },
    });

    writeEntityRatingToCache({ id: entity, entityType, data: updateData });
    try {
      const response = await client.mutate({
        mutation: updateEntityRatingMutation,
        variables: {
          rating,
          entity,
          entityType,
        },
      });

      return { isRated: !likedByMe };
    } catch (err) {
      console.error(err);
      writeEntityRatingToCache({
        id: entity,
        entityType,
        data: entityRatingData,
      });
      return {};
    }
  };
};

export const updateLike = ({ entityId, entityType }) => {
  return async (dispatch, getState) => {
    const isActiveCommunity = await dispatch(
      checkCommunityActiveStatusAndGoToOnboarding()
    );
    if (!isActiveCommunity) {
      return;
    }
    const likeData = getLikesNodeFromCache({
      entityId,
      entityType,
    });

    let likes = _.get(likeData, "likes", {}) || {};

    const userId = getState().login.userInfo.id;
    const likedByMe = _.get(likes, "isByMe", false);
    likes = {
      ...(likes || {}),
      isByMe: !likedByMe,
      totalCount: _.get(likes, "totalCount", 0) + (likedByMe ? -1 : 1),
    };
    const updateData = update(likeData, {
      likes: { $set: likes },
    });

    writeLikesNodeToCache({ entityId, entityType, data: updateData });
    try {
      const response = await client.mutate({
        mutation: likedByMe ? removeLikeMutation : addLikeMutation,
        variables: {
          parentId: entityId,
          parentType: entityType,
        },
      });

      if (!likedByMe) {
        likes = {
          ...(likes || {}),
          edges: _.get(
            response,
            "data.platform.addLike.likeConnection.edges",
            []
          ),
        };
      } else {
        likes = {
          ...(likes || {}),
          edges: _.filter(
            _.get(likes, "edges", []),
            item => _.get(item, "node.user.id") != userId
          ),
        };
      }
      const updateData = update(likeData, {
        likes: { $set: likes },
      });
      writeLikesNodeToCache({ entityId, entityType, data: updateData });
      // console.log("Response", response);
      return { isLiked: !likedByMe };
    } catch (err) {
      console.error(err);
      writeLikesNodeToCache({
        entityId,
        entityType,
        data: likeData,
      });
      return {};
    }
  };
};

export const updateFollow = ({
  entityId,
  entityType,
  followType = "SAVE",
  queryVariables,
}) => {
  return async (dispatch, getState) => {
    let queryData = {};

    const savedByData = getSaveByNodeFromCache({ entityId, entityType });

    let savedBy = _.get(savedByData, "savedBy", []);
    const userId = getState().login.userInfo.id;
    const savedByMe = _.find(savedBy, item => _.get(item, "id") == userId);

    if (savedByMe) {
      savedBy = _.filter(savedBy, item => {
        return item.id != userId;
      });
    } else {
      savedBy = [
        ...(savedBy || []),
        {
          id: userId,
          __typename: "User",
        },
      ];
      const coachMarksStatusDetails = dispatch(
        getCoachMarkStatusDetails({
          type: "COMMUNITY_VIEW_BOOKMARKS",
          portalType: "COMMUNITY",
        })
      );
      const { isViewed } = coachMarksStatusDetails || {};
      if (!isViewed) {
        dispatch(updateCoachMarkViewStatus({ bookmarkStatus: true }));
        dispatch(
          updateCoachMarkStatus({
            type: "COMMUNITY_VIEW_BOOKMARKS",
            portalType: "COMMUNITY",
            isViewed: true,
          })
        );
      }
    }

    if (
      savedByMe &&
      _.get(queryVariables, "feedType", "") == "SAVED" &&
      _.get(queryVariables, "entityTypes", "") == entityType
    ) {
      queryData = getCommunityCommonFeedFromCache(queryVariables);
    }

    const updateData = update(savedByData, {
      savedBy: { $set: savedBy },
    });

    if (!_.isEmpty(queryData)) {
      let updatedQueryData = update(queryData, {
        node: {
          communityEntities: {
            edges: {
              $set: _.filter(
                _.get(queryData, "node.communityEntities.edges", []),
                item => _.get(item, "node.id", "") != entityId
              ),
            },
          },
        },
      });
      updatedQueryData = update(updatedQueryData, {
        node: {
          communityEntities: {
            totalCount: {
              $set:
                _.get(queryData, "node.communityEntities.totalCount", 0) - 1,
            },
          },
        },
      });

      writeCommunityCommonFeedToCache({
        ...(queryVariables || {}),
        data: updatedQueryData,
      });
    }
    writeSaveByNodeToCache({ entityId, entityType, data: updateData });
    try {
      const response = await client.mutate({
        mutation: savedByMe ? removeFollowMutation : createFollowMutation,
        variables: {
          user: userId,
          entityId,
          entityType,
          followType,
        },
      });

      return { isBookmarked: !savedByMe };
    } catch (err) {
      console.error(err);
      if (!_.isEmpty(queryData)) {
        writeCommunityCommonFeedToCache({
          ...(queryVariables || {}),
          data: queryData,
        });
      }
      writeSaveByNodeToCache({
        entityId,
        entityType,
        data: savedByData,
      });
      return {};
    }
  };
};

export const unpublishEntity = data => {
  return async (dispatch, getState) => {
    try {
      const { entityType, entityId, entityName, typeLabel } = data;
      await client.mutate({
        mutation: unpublishEntityMutation,
        variables: data,
      });
      EventTracker.recordEvent({
        eventName: "Removed Contribution",
        eventData: {
          entity_type: entityType,
          entity_id: entityId,
          source: "community",
          entity_name: entityName,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:unpublished_entity_with_label",
          locale_params: [
            {
              key: "entity",
              value: typeLabel,
              isPlainText: true,
            },
            { key: "entityName", value: entityName, isPlainText: true },
          ],
          type: "success",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const shareEntity = data => {
  return async (dispatch, getState) => {
    try {
      const { entityType, entityId, typeLabel, entityName, emails } = data;
      await client.mutate({
        mutation: shareEntityMutation,
        variables: data,
      });

      EventTracker.recordEvent({
        eventName: "Shared",
        eventData: {
          entity_type: entityType,
          entity_id: entityId,
          source: "community",
          entity_name: entityName,
          emails: _.get(emails, "length", 0) > 0 ? emails : undefined,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:entity_shared",
          locale_params: [
            {
              key: "entity",
              value: typeLabel,
              isPlainText: true,
            },
          ],
          type: "success",
          position: "toast-bottom-left",
        })
      );
      return true;
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const upvoteResourceRequest = data => {
  return async (dispatch, getState) => {
    try {
      const contentId = _.get(data, "item.id");
      writeResourceRequestInCache({
        id: contentId,
        data: {
          ..._.get(data, "item", {}),
          isUpvoted: true,
          upvoteCount: _.get(data, "item.isUpvoted")
            ? _.get(data, "item.upvoteCount") - 1
            : _.get(data, "item.upvoteCount") + 1,
        },
      });
      await client.mutate({
        mutation: upvoteResourceRequestMutation,
        variables: { contentId },
      });

      EventTracker.recordEvent({
        eventName: "Upvote Resource Request",
        eventData: {
          entity_type: _.get(data, "types"),
          entity_id: _.get(data, "item.id"),
          source: "community",
          entity_name: _.get(data, "item.contentKeyword"),
        },
      });
      return true;
    } catch (err) {
      writeResourceRequestInCache({
        id: _.get(data, "item.id"),
        data: _.get(data, "item", {}),
      });
      console.error(err);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const updateCommunityUserDetails = ({ userId, data }) => {
  const getData = key =>
    _.map(_.get(data, ["node", key], []), info =>
      _.pick(info, ["id", "__typename"])
    );

  try {
    const prevData = getCommunityUserDetailsFromCache({ id: userId });

    const newData = {
      ..._.cloneDeep(prevData),
      communityDetails: {
        ...prevData.communityDetails,
        aboutMe: _.get(data, "node.communityDetails.aboutMe"),
      },
      experience: [...getData("currentSchools"), ...getData("pastSchools")],
      certificates: [...getData("trainings"), ...getData("workshops")],
    };

    writeCommunityUserDetailsToCache({
      id: userId,
      data: {
        node: newData,
      },
    });
  } catch (err) {
    // console.log("errr::", err);
  }
};

export const handleEntityTag = ({
  entityId,
  entityType,
  valuesToRemove,
  valuesToAdd,
  portalType,
}) => {
  return async (dispatch, getState) => {
    client.mutate({
      mutation: handleEntityTagMutation,
      variables: {
        entityId,
        entityType,
        valuesToRemove,
        valuesToAdd,
        portalType,
      },
    });
  };
};

export const createResourceRequest = data => async dispatch => {
  try {
    const result = await client.mutate({
      mutation: createResourceRequestMutation,
      variables: data,
    });

    EventTracker.recordEvent({
      eventName: "Create Resource Request",
      eventData: {
        entity_id: _.get(
          result,
          "data.community.createResourceRequest.id",
          null
        ),
        entity_type: _.get(data, "resourceType", null),
        source: "community",
        target: _.get(EventTracker.eventHistory, "Request Resource"),
        entity_name: _.get(data, "resourceTopic", null),
      },
    });

    return true;
  } catch (err) {
    dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    return false;
  }
};

export const isUserAuthenticatedForAction = data => {
  return async (dispatch, getState) => {
    const userLoggedIn = _.get(getState(), "login.userLoggedIn");
    if (userLoggedIn) return true;

    dispatch(setJoinCommunityModal(true));
    return false;
  };
};

const REDUCER_HANDLERS = {
  [SET_ACTIVE_PARENT]: (state, action) => {
    return update(state, { activeParent: { $set: action.payload } });
  },
  [SET_ACTIVE_SECTION]: (state, action) => {
    return update(state, { activeSection: { $set: action.payload } });
  },
  [SET_COMMUNITY_ACTIVE_TAB]: (state, action) => {
    return update(state, { activeTab: { $set: action.payload } });
  },
  [SET_PD_RESOURCES_NEW_KEY]: (state, action) => {
    return update(state, { pdResourcesKey: { $set: generateRandomId() } });
  },

  [SET_PROFILE_DETAIL_MODAL]: (state, action) => {
    return update(state, {
      activeProfileModal: { $set: action.payload },
    });
  },
  [SET_COACHMARK_RENDER_STATUS]: (state, action) => {
    return update(state, {
      coachmarkRenderStatus: { $set: action.payload },
    });
  },
  [SET_JOIN_COMMUNITY_MODAL]: (state, action) => {
    return update(state, {
      showJoinCommunityModal: { $set: action.payload },
    });
  },
  [SET_RESOURCE_REQUEST_PAGE]: (state, action) => {
    return update(state, { showResourceRequestPage: { $set: action.payload } });
  },
  [SET_IS_RESOURCE_REQUEST_MODAL_OPEN]: (state, action) => {
    return update(state, {
      isResourceRequestModalOpen: { $set: action.payload },
    });
  },
};

const initialState = {
  activeParent: "featured",
  activeTab: "",
  pdResourcesKey: generateRandomId(),
  coachmarkRenderStatus: true, // Refactor: Todo: used when the user is redirected to Unit/LE after signup and coachmark does not show up on back
  locationBeforeOnboarding: "",
  showResourceRequestPage: false,
  isResourceRequestModalOpen: false,
  showJoinCommunityModal: false,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
