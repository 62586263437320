import React from "react";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import {
  typesConfig,
  getFilterDataMemoized,
  getParseResultMemoized,
  getModuleTypeBySearchTerm,
} from "../Utils";
import { getGlobalSearchResult } from "../../modules/GlobalSearchBarQueries";
import { getGlobalSearchResultFromCache } from "../../modules/GlobalSearchBarHelper";
import { I18nHOC, withLoader } from "UIComponents";
import { SearchList } from "../SearchList/SearchList";

const style = {
  searchListStyle: {
    height: "100%",
  },
};

const Suggestions = props => {
  const {
    searchState,
    module,
    suggestions = [],
    suggestionModules,
    t,
    onSuggestionClick,
  } = props;

  const data =
    module === typesConfig.MODULES.id ? suggestionModules : suggestions;

  return (
    <div className="mt-6 flex-1" style={{ minHeight: 0, height: "100%" }}>
      <div className="text-label-3 mb-2 mx-10">
        {t("common:suggestions_for_you")}
      </div>
      <div className="overflow-y-auto flex-1" style={style.searchListStyle}>
        <SearchList
          data={_.slice(data, 0, 4)}
          onSelectItem={onSuggestionClick}
          searchState={searchState}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { module, t } = ownProps;

  const curriculumPrograms = state.platform.organizationCurriculumPrograms;

  const curriculumProgramType = _.map(curriculumPrograms, data => data.type);

  const userInfo = state.login.userInfo;

  const suggestionModules =
    module === typesConfig.MODULES.id
      ? getModuleTypeBySearchTerm({
          term: "",
          orgId: userInfo.org_id,
          module,
          curriculumProgramType,
          t,
        })
      : [];

  return {
    isData: true,
    isLoading: false,
    filters: getFilterDataMemoized({
      state: state.platform,
      searchText: "",
      module,
    }),
    suggestionModules: getParseResultMemoized({ result: suggestionModules, t }),
  };
};

export default compose(
  I18nHOC({
    resource: [
      "common",
      "teacherHomePage",
      "classRoom",
      "configuration",
      "attendance",
      "integration",
    ],
  }),
  connect(mapStateToProps, null),
  graphql(getGlobalSearchResult, {
    name: "getGlobalSearchResultQuery",
    options: ({ filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: filters,
    }),
    skip: props => {
      const { module } = props;
      return module == typesConfig.MODULES.id;
    },
    props: ({
      getGlobalSearchResultQuery,
      ownProps: { isData, isLoading, filters, t },
    }) => {
      const result = getGlobalSearchResultFromCache({
        filters,
      });

      const queryResult = _.get(result, "platform.platformSearch", []);

      const suggestions = getParseResultMemoized({
        result: queryResult,
        t,
      });

      return {
        suggestions,
        isData: !_.isEmpty(result) && isData,
        isLoading:
          _.includes([1, 2], getGlobalSearchResultQuery["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  withLoader
)(Suggestions);
