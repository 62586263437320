import gql from "graphql-tag";
import { filterFragments, applicableFiltersFragment } from "./Fragments";

export const getApplicableFiltersQuery = gql`
  query getApplicableFilters($module: GENERIC_MODULE_NAMES_ENUM) {
    globalConstants {
      ...applicableFilters
    }
  }
  ${applicableFiltersFragment}
`;

export const getFilterOptionsQuery = gql`
  query getFilterOptions(
    $entityType: FACET_ENTITY_TYPE_ENUM!
    $options: JSON
    $id: ID!
    $filters: PlannerElementFilters
  ) {
    node(id: $id, type: ORGANIZATION) {
      ... on Organization {
        id
        genericFacets(entityType: $entityType, options: $options) {
          mainFacets {
            ... on UnitPlan {
              ...unitItem
            }
            ... on PlannerATL {
              ...atlItem
            }
            ... on PlannerConcept {
              ...conceptItem
            }
            ... on PlannerLearnerProfile {
              ...lpaItem
            }
            ... on Subject {
              ...subjectItem
            }
            ... on AcademicYear {
              ...academicYearItem
            }
            ... on Folder {
              ...folderItem
            }
            ... on ActivityTypeFacet {
              ...activityItem
            }
            ... on Grade {
              ...gradeItem
            }
            ... on UnitPlanTypeFacet {
              ...unitPlanTypeItem
            }
            ... on Theme {
              ...themeItem
            }
            ... on ProgressReportTaskGroup {
              ...reportSetItem
            }
            ... on SubjectGroup {
              ...subjectGroupItem
            }
            ... on PlannerElementSet {
              ...plannerElementItem
            }
          }
          nestedFacets {
            id
            label
            mainFacets {
              ... on Course {
                ...courseItem
              }
            }
          }
        }
      }
    }
  }
  ${filterFragments.unitFragment}
  ${filterFragments.atlFragment}
  ${filterFragments.conceptFragment}
  ${filterFragments.lpaFragment}
  ${filterFragments.academicYearFragment}
  ${filterFragments.subjectFragment}
  ${filterFragments.courseFragment}
  ${filterFragments.folderFragment}
  ${filterFragments.activityFragment}
  ${filterFragments.gradeFragment}
  ${filterFragments.unitPlanTypeFragment}
  ${filterFragments.themeFragment}
  ${filterFragments.reportSetFragment}
  ${filterFragments.subjectGroupFragment}
  ${filterFragments.plannerElementFragment}
`;
