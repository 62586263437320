import client from "apolloClient";
import moment from "moment";
import update from "immutability-helper";
import {
  updateFirstExperienceMutation,
  updateSnPEvaluationCycleMutation,
  createSnPEvaluationCycleMutation,
  handleSnPEvidenceInPracticeMutation,
  updateDocumentCategoryMutation,
  deleteSnPEvaluationCycleMutation,
  updateStandardStatusMutation,
  createDocumentCategoryMutation,
  deleteDocumentCategoryMutation,
  updateVisitorsInCycleMutation,
  handleSnPEvidenceInDocumentCategoryMutation,
  tagSnpPracticesMutation,
  updateSuggestedEvidenceViewStatusMutation,
  updateCategoryStatusMutation,
  updateSnpPracticeEvidenceStatusMutation,
} from "./SnPMutations";
import {
  getSnPEvaluationCyclesQuery,
  getPracticeEvidencesQuery,
  getDocumentCategoryTaggedPracticesQuery,
  getStandardBasicDetailsQuery,
  getDocumentCategoryEvidenceFeedDetailsQuery,
  getSnPDocumentReviewStagesQuery,
} from "./SnPQueries";
import {
  OLD_SNP_FIELDS,
  SNP_DOCUMENT_CATEGORY,
  invitedVisitorsListQueryVariables,
  getPracticeEvidencesQueryVariables,
  getDocumentCategoryTaggedPracticeQueryVariables,
  getStandardBasicDetailsQueryVariables,
  getDocumentCategoryEvidenceFeedQueryVariables,
} from "./SnPUtils";
import {
  writeFirstExperienceToCache,
  writeStandardStatusToCache,
  writeInvitedVisitorListInCache,
  updateFeedInCache,
  getSnPEvaluationCyclesFromCache,
  updateSnPCyclesInCache,
  writeSuggestedEvidenceViewStatusInCache,
  getDocumentCategoryTaggedPracticesFromCache,
  writeDocumentCategoryDataToCache,
  getSnPDocumentCategoryGroupFromCache,
  writeSnPDocumentCategoryGroupInCache,
  writeDocumentCategoryTaggedPracticesInCache,
  writeCategoryStatusToCache,
  deletePracticeEvidenceFromCache,
  deleteDocumentCategoryEvidenceFromCache,
} from "./SnPGraphqlHelpers";

import { setToastMsg } from "Login/modules/LoginModule";
import { openLinkInNewTab } from "modules/Services";
import { dispatchError } from "Utils";
import { CURRICULUM_TYPE_MYP } from "Constants/stringConstants";
import { getUnitPlanCentralRepoInputAndFilters } from "routes/routes/Teacher/routes/Courses/modules/utils";

export const SNP_TABS = [
  {
    label: "snp:guidance_tab_label",
    labelId: "COACHMARKS_SNP_HEADER_GUIDANCE",
    value: "guidance-documents",
    tabType: "GUIDANCE",
  },
  {
    label: "snp:dashboard_tab_label",
    labelId: "COACHMARKS_SNP_HEADER_DASHBOARD",
    value: "evidence-dashboard",
    tabType: "DASHBOARD",
  },
  {
    label: "snp:documents_tab_label",
    labelId: "COACHMARKS_SNP_HEADER_DOCUMENTS",
    value: "school-documents",
    tabType: "DOCUMENTS",
  },
];

// --------THUNKS--------

export const createSnPEvaluationCycle = ({ cycle, curriculumProgramId }) => {
  return async (dispatch, getState) => {
    let res = "";
    const { title, type, standard } = cycle;

    try {
      res = await client.mutate({
        mutation: createSnPEvaluationCycleMutation,
        variables: {
          input: {
            ...OLD_SNP_FIELDS,
            title,
            cycleType: type,
            setId: standard,
            curriculumProgramId,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getSnPEvaluationCyclesQuery,
            variables: {
              id: getState().login.userInfo.org_id,
            },
          },
        ],
      });
    } catch (e) {
      dispatchError({ dispatch, e });
      return false;
    }

    dispatch(
      setToastMsg({
        msg: "toastMsgs:cycle_action_success",
        locale_params: [
          {
            key: "action",
            value: `toastMsgs:created`,
          },
        ],
        type: "success",
      })
    );
    return _.get(res, "data.platform.createSnpEvaluationCycleV2[0].id", "");
  };
};

export const editCycle = ({ cycleId, key, value, cycleQueryFilters }) => {
  return async (dispatch, getState) => {
    const orgId = getState().login.userInfo.org_id;

    const queryData = getSnPEvaluationCyclesFromCache({
      id: orgId,
      filters: cycleQueryFilters,
    });
    const cycles = _.get(queryData, "snpEvaluationCycles", []);

    try {
      await client.mutate({
        mutation: updateSnPEvaluationCycleMutation,
        variables: {
          updatedCycle: {
            id: cycleId,
            [key]: value,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            updateSnpEvaluationCycleV2: {
              __typename: "SnPEvaluationCycle",
              id: cycleId,
            },
          },
        },
        update: (
          cache,
          {
            data: {
              platform: {
                updateSnpEvaluationCycleV2: { id },
              },
            },
          }
        ) => {
          const currentCycleIndex = _.findIndex(cycles, { id });

          const updatedCycles = update(cycles, {
            [currentCycleIndex]: {
              [key]: { $set: value },
            },
          });

          setTimeout(() => {
            updateSnPCyclesInCache({
              data: {
                node: {
                  __typename: "Organization",
                  id: orgId,
                  snpEvaluationCycles: updatedCycles,
                },
              },
              id: orgId,
              filters: cycleQueryFilters,
            });
          });
        },
      });
    } catch (e) {
      dispatchError({ dispatch, e });

      setTimeout(() => {
        updateSnPCyclesInCache({
          data: { node: queryData },
          id: orgId,
        });
      });

      throw e;
    }

    dispatch(
      setToastMsg({
        msg: "toastMsgs:cycle_action_success",
        locale_params: [
          {
            key: "action",
            value: `toastMsgs:updated`,
          },
        ],
        type: "success",
      })
    );
  };
};

export const updateFirstExperience = param => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateFirstExperienceMutation,
        variables: {
          value: param,
        },
      });
      writeFirstExperienceToCache(getState().login.userInfo.id, param);
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const deleteCycle = ({ id, cycleQueryFilters }) => {
  return async (dispatch, getState) => {
    const orgId = getState().login.userInfo.org_id;

    const queryData = getSnPEvaluationCyclesFromCache({
      id: orgId,
      filters: cycleQueryFilters,
    });

    const cycles = _.get(queryData, "snpEvaluationCycles", []);

    try {
      await client.mutate({
        mutation: deleteSnPEvaluationCycleMutation,
        variables: {
          id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            deleteSnpEvaluationCycle: true,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { deleteSnpEvaluationCycle },
            },
          }
        ) => {
          if (deleteSnpEvaluationCycle) {
            const updatedCycles = _.reject(cycles, ["id", id]);
            setTimeout(() => {
              updateSnPCyclesInCache({
                data: {
                  node: {
                    __typename: "Organization",
                    id: orgId,
                    snpEvaluationCycles: updatedCycles,
                  },
                },
                id: orgId,
                filters: cycleQueryFilters,
              });
            });
          }
        },
      });
    } catch (e) {
      setTimeout(() => {
        updateSnPCyclesInCache({
          data: { node: queryData },
          id: orgId,
        });
      });
      dispatchError({ dispatch, e });
      return;
    }
    dispatch(
      setToastMsg({
        msg: "toastMsgs:cycle_action_success",
        locale_params: [
          {
            key: "action",
            value: `toastMsgs:deleted`,
          },
        ],
        type: "success",
      })
    );
    return;
  };
};

export const updateDocumentCategory = ({ input }) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: updateDocumentCategoryMutation,
        variables: { input },
        update: (
          cache,
          {
            data: {
              platform: { updateSnpDocumentCategory },
            },
          }
        ) => {
          writeDocumentCategoryDataToCache({
            id: input.id,
            data: updateSnpDocumentCategory,
          });
        },
      });
    } catch (e) {
      dispatchError({ dispatch, e });
      throw e;
    }
  };
};

export const updateDocumentCategoryStatus = ({ id, status }) => {
  return async (dispatch, getState) => {
    const userStatus = getUserStatus({ state: getState(), status });
    const mutation = updateCategoryStatusMutation;

    try {
      await client.mutate({
        mutation,
        variables: {
          input: { id, status },
        },
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            updateSnpDocumentCategoryV2: {
              id,
              userStatus,
              __typename: "SnPDocumentCategory",
            },
            __typename: "PlatformMutations",
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { updateSnpDocumentCategoryV2 },
            },
          }
        ) => {
          writeCategoryStatusToCache({
            data: updateSnpDocumentCategoryV2,
          });
        },
      });
    } catch (e) {
      dispatchError({ dispatch, e });
    }
  };
};

export const handleSnPEvidenceInPractice = ({
  id,
  removedEvidence,
  addedEvidence,
  evidenceStatus,
  isSuggestedEvidenceTab = false,
  evidenceType,
}) => {
  return async (dispatch, getState) => {
    const cycleId = getState().snpV2.currentCycle.id;
    const activeTab = getState().login.activeTab;

    try {
      dispatch(updateLoadingState(true));

      await client.mutate({
        mutation: handleSnPEvidenceInPracticeMutation,
        variables: {
          cycleId: _.get(getState(), "snpV2.currentCycle.id", ""),
          practiceId: id,
          removedEvidence,
          addedEvidence,
          evidenceStatus,
        },
        refetchQueries: [
          {
            query: getPracticeEvidencesQuery,
            variables: getPracticeEvidencesQueryVariables({
              practiceId: id,
              cycleId,
              activeTab,
              isSuggestedEvidenceTab,
              evidenceType,
            }),
          },
          {
            query: getStandardBasicDetailsQuery,
            variables: getStandardBasicDetailsQueryVariables({
              cycleId,
            }),
          },
        ],
        awaitRefetchQueries: true,
      });

      if (_.size(addedEvidence)) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:evidence_add",
            type: "success",
          })
        );
      } else {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:evidence_removed",
            type: "success",
          })
        );
      }
      dispatch(updateLoadingState(false));
    } catch (e) {
      dispatchError({ dispatch, e });
      dispatch(updateLoadingState(false));
      throw e;
    }
  };
};

export const addEvidencesToPracticesFromModal = ({
  id,
  addedEvidence,
  suggestedEvidence,
  evidenceStatus,
  evidenceType,
}) => {
  return async (dispatch, getState) => {
    const cycleId = getState().snpV2.currentCycle.id;
    const activeTab = getState().login.activeTab;

    const areSuggestedEvidenceEmpty = _.isEmpty(suggestedEvidence);

    const areEvidenceAdded = !_.isEmpty(addedEvidence);

    try {
      dispatch(updateLoadingState(true));

      if (areEvidenceAdded) {
        await client.mutate({
          mutation: handleSnPEvidenceInPracticeMutation,
          variables: {
            cycleId: _.get(getState(), "snpV2.currentCycle.id", ""),
            practiceId: id,
            addedEvidence,
            evidenceStatus,
          },
          refetchQueries: areSuggestedEvidenceEmpty
            ? [
                {
                  query: getPracticeEvidencesQuery,
                  variables: getPracticeEvidencesQueryVariables({
                    practiceId: id,
                    cycleId,
                    activeTab,
                    isSuggestedEvidenceTab: false,
                    evidenceType,
                  }),
                },
                {
                  query: getStandardBasicDetailsQuery,
                  variables: getStandardBasicDetailsQueryVariables({
                    cycleId,
                  }),
                },
              ]
            : [],
          awaitRefetchQueries: true,
        });
      }

      if (!areSuggestedEvidenceEmpty) {
        await client.mutate({
          mutation: updateSnpPracticeEvidenceStatusMutation,
          variables: {
            input: {
              evidenceIds: suggestedEvidence,
              evidenceStatus: "APPROVED",
            },
          },
          refetchQueries: [
            {
              query: getPracticeEvidencesQuery,
              variables: getPracticeEvidencesQueryVariables({
                practiceId: id,
                cycleId,
                activeTab,
              }),
            },
            {
              query: getStandardBasicDetailsQuery,
              variables: getStandardBasicDetailsQueryVariables({
                cycleId,
              }),
            },
          ],
          awaitRefetchQueries: true,
        });
      }

      dispatch(
        setToastMsg({
          msg: "toastMsgs:evidence_add",
          type: "success",
        })
      );
    } catch (e) {
      dispatchError({ dispatch, e });
    } finally {
      dispatch(updateLoadingState(false));
    }
  };
};

export const createDocumentCategory = ({
  cycleId,
  section,
  categoryGroupId,
}) => async dispatch => {
  const { id, title, description, status } = section;

  const userStatus = {
    status,
    __typename: "SnPUserStatus",
  };

  section = {
    ..._.omit(section, ["status"]),
    userStatus,
  };

  const newSectionFragment = {
    ...section,
    __typename: "SnPDocumentCategory",
  };

  const cachedData = getSnPDocumentCategoryGroupFromCache({
    id: categoryGroupId,
  });
  const dataWithOptimisticValue = update(cachedData, {
    documentCategories: { $push: [newSectionFragment] },
  });

  try {
    const result = await client.mutate({
      mutation: createDocumentCategoryMutation,
      variables: {
        input: {
          evaluationCycleId: cycleId,
          title,
          description,
          status,
          documentCategoryGroupId: categoryGroupId,
        },
      },
      optimisticResponse: {
        platform: {
          __typename: "PlatformMutations",
          createSnpDocumentCategoryV2: {
            ...newSectionFragment,
            __typename: "SnPDocumentCategory",
          },
        },
      },
      update: (
        cache,
        {
          data: {
            platform: { createSnpDocumentCategoryV2: realDocumentCategory },
          },
        }
      ) => {
        const cachedDocumentCategoryIndex = _.findIndex(
          dataWithOptimisticValue.documentCategories,
          { id }
        );

        const dataWithRealValue = update(dataWithOptimisticValue, {
          documentCategories: {
            $splice: [[cachedDocumentCategoryIndex, 1, realDocumentCategory]],
          },
        });

        writeSnPDocumentCategoryGroupInCache({
          id: categoryGroupId,
          data: dataWithRealValue,
        });
      },
    });

    dispatch(
      setToastMsg({
        msg: "toastMsgs:successfully_with_label",
        locale_params: [
          { key: "label", value: "toastMsgs:added", isPlainText: false },
        ],
        type: "tick",
      })
    );

    return _.get(result, "data.platform.createSnpDocumentCategoryV2", {});
  } catch (e) {
    dispatchError({ dispatch, e });
    throw e;
  }
};

export const deleteDocumentCategory = ({
  id,
  categoryGroupId,
}) => async dispatch => {
  try {
    await client.mutate({
      mutation: deleteDocumentCategoryMutation,
      variables: {
        input: { id },
      },
      optimisticResponse: {
        __typename: "Mutation",
        platform: {
          __typename: "PlatformMutations",
          deleteSnpDocumentCategory: true,
        },
      },
      update: () => {
        const currentCategoryGroup = getSnPDocumentCategoryGroupFromCache({
          id: categoryGroupId,
        });

        const updatedData = update(currentCategoryGroup, {
          documentCategories: {
            $set: _.filter(
              currentCategoryGroup.documentCategories,
              category => id !== category.id
            ),
          },
        });

        writeSnPDocumentCategoryGroupInCache({
          id: categoryGroupId,
          data: updatedData,
        });
      },
    });

    dispatch(
      setToastMsg({
        msg: "toastMsgs:successfully_with_label",
        locale_params: [
          { key: "label", value: "toastMsgs:deleted", isPlainText: false },
        ],
        type: "tick",
      })
    );
  } catch (e) {
    dispatchError({ dispatch, e });
    throw e;
  }
};

export const updateStandardStatus = ({ standardId, status, isAdmin }) => {
  return async (dispatch, getState) => {
    const userStatus = getUserStatus({ status, state: getState() });

    try {
      await client.mutate({
        mutation: updateStandardStatusMutation,
        variables: {
          standardId,
          status,
        },
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            updateSnpEvaluationCycleStandard: {
              id: standardId,
              userStatus,
              __typename: "SnPEvaluationCycleStandard",
            },
            __typename: "PlatformMutations",
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { updateSnpEvaluationCycleStandard },
            },
          }
        ) => {
          writeStandardStatusToCache({
            standardId,
            data: updateSnpEvaluationCycleStandard,
          });
        },
      });
    } catch (e) {
      dispatchError({ dispatch, e });
    }
  };
};

export const goToSnpEvidenceView = ({ evidenceType, id, data }) => {
  let route = "";
  let config = {};

  return dispatch => {
    switch (evidenceType) {
      case "POST": {
        route = `/platform/links/post/${id}`;
        break;
      }
      case "UNIT_PLAN": {
        route = `/platform/links/unit/${id}/view`;
        break;
      }
      case "ASSESSMENT": {
        route = `/platform/links/assessment/${id}/view`;
        break;
      }
      case "ASSIGNMENT": {
        route = `/platform/links/classroom-details/${id}`;
        break;
      }
      case "SUBJECT": {
        route = `/platform/links/scopeandsequence/${id}`;
        break;
      }
      case "STUDENT_PROGRESS_REPORT": {
        const edgeId = _.get(data, "id", "");
        route = `/platform/links/progress-report/${edgeId}`;
        break;
      }

      case "SCHOOL_POLICIES":
      case "TEACHER_RESOURCE":
        route = _.get(data, "attachment.url", "");
        break;
      case "POI_ACADEMIC_YEAR": {
        config = {
          academicYear: id,
        };
        route = `/platform/links/insight/poi`;
        break;
      }
      case "ACADEMIC_YEAR_POI_GRADE_FA":
      case "ACADEMIC_YEAR_POI_GRADE_ATL":
      case "ACADEMIC_YEAR_POI_THEME_ATL":
      case "ACADEMIC_YEAR_POI_GRADE_LP":
      case "ACADEMIC_YEAR_POI_THEME_LP":
      case "ACADEMIC_YEAR_POI_GRADE_SUBJECT":
      case "ACADEMIC_YEAR_POI_THEME_SUBJECT":
      case "ACADEMIC_YEAR_POI_GRADE_CONCEPT":
      case "ACADEMIC_YEAR_POI_THEME_CONCEPT": {
        const { route: insightRoute, academicYearId, table } = data;
        config = {
          insightProps: {
            table,
          },
          academicYear: academicYearId,
        };

        route = `/platform/links/insight/${insightRoute}`;
        break;
      }

      case "SUBJECT_GROUP_OVERVIEW":
      case "SUBJECT_GROUP_POI_GLOBAL_CONTEXTS":
      case "SUBJECT_GROUP_POI_KEY_AND_RELATED_CONCEPTS":
      case "SUBJECT_GROUP_POI_ATL":
      case "SUBJECT_GROUP_POI_MYP_OBJECTIVES": {
        const { id, route: insightRoute, academicYearId, subjects } = data;

        config = {
          curriculumType: CURRICULUM_TYPE_MYP,
          academicYearId,
          subjectId: _.first(subjects).id,
        };
        route = `/platform/links/planningInsights/verticalOverview/${id}/${insightRoute}`;
        break;
      }

      default:
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
        return;
    }

    if (_.isEmpty(config)) {
      dispatch(
        openLinkInNewTab({
          url: `${route}?preserve_query_params=true&source_type=SNP`,
        })
      );
    } else {
      dispatch(
        openLinkInNewTab({
          url: `${route}?preserve_query_params=true&source_type=SNP&config=${JSON.stringify(
            config
          )}`,
        })
      );
    }
  };
};
export const updateVisitorList = ({
  addedVisitors,
  removedVisitors,
  updatedData,
}) => {
  return async (dispatch, getState) => {
    const cycleId = _.get(getState(), "snpV2.currentCycle.id", "");
    try {
      await client.mutate({
        mutation: updateVisitorsInCycleMutation,
        variables: {
          cycleId,
          addedVisitors,
          removedVisitors,
        },
        update: () => {
          const updatedVisitors = _.map(updatedData, visitor => {
            return {
              ...visitor,
              __typename: "Staff",
            };
          });

          const data = {
            node: {
              id: cycleId,
              visitors: updatedVisitors,
              __typename: "SnPEvaluationCycle",
            },
          };
          writeInvitedVisitorListInCache({
            variables: { cycleId, ...invitedVisitorsListQueryVariables },
            data,
          });
        },
      });
    } catch (e) {
      console.error(e);
      dispatchError({ dispatch, e });
      return false;
    }

    dispatch(
      setToastMsg({
        msg: "toastMsgs:action_successfully",
        locale_params: [
          {
            key: "label",
            value: "snp:visitor_plural",
            isPlainText: false,
          },
          {
            key: "action",
            value: "toastMsgs:updated",
          },
        ],
        type: "success",
      })
    );
    return true;
  };
};

export const updateSuggestedEvidenceViewStatus = ({
  practiceId,
  isSuggestedEvidenceViewed,
}) => {
  return async (dispatch, getState) => {
    const cycleId = getState().snpV2.currentCycle.id;
    const staffId = getState().login.userInfo.id;

    try {
      await client.mutate({
        mutation: updateSuggestedEvidenceViewStatusMutation,
        variables: {
          practiceId,
          cycleId,
          staffId,
        },
        update: () => {
          writeSuggestedEvidenceViewStatusInCache({
            staffId,
            practiceId,
            cycleId,
            isSuggestedEvidenceViewed,
          });
        },
      });
    } catch {}
  };
};

export const addEvidenceInDocumentCategory = ({ id, addedEvidence }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const cycleId = _.get(state, "snpV2.currentCycle.id", "");
  try {
    dispatch(updateLoadingState(true));
    await client.mutate({
      mutation: handleSnPEvidenceInDocumentCategoryMutation,
      variables: {
        input: {
          id,
          addedEvidence,
        },
      },
      refetchQueries: [
        {
          query: getDocumentCategoryEvidenceFeedDetailsQuery,
          variables: getDocumentCategoryEvidenceFeedQueryVariables({
            id,
          }),
        },
        { query: getSnPDocumentReviewStagesQuery, variables: { cycleId } },
      ],
      awaitRefetchQueries: true,
    });

    dispatch(
      setToastMsg({
        msg: "toastMsgs:evidence_add",
        type: "success",
      })
    );

    dispatch(updateLoadingState(false));
  } catch (e) {
    dispatchError({ dispatch, e });
    dispatch(updateLoadingState(false));
  }
};

export const deleteEvidenceFromPractice = ({
  id,
  removedEvidence,
  shouldRefetchEvidenceFeedQuery = false,
}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(updateLoadingState(true));
      const state = getState();
      const cycleId = _.get(state, "snpV2.currentCycle.id", "");
      const activeTab = _.get(state, "login.activeTab", "");

      await client.mutate({
        mutation: handleSnPEvidenceInPracticeMutation,
        variables: { practiceId: id, removedEvidence, cycleId },
        refetchQueries: shouldRefetchEvidenceFeedQuery
          ? [
              {
                query: getPracticeEvidencesQuery,
                variables: getPracticeEvidencesQueryVariables({
                  practiceId: id,
                  cycleId,
                  activeTab,
                  isSuggestedEvidenceTab: false,
                }),
              },
              {
                query: getStandardBasicDetailsQuery,
                variables: getStandardBasicDetailsQueryVariables({
                  cycleId,
                }),
              },
            ]
          : [
              {
                query: getStandardBasicDetailsQuery,
                variables: getStandardBasicDetailsQueryVariables({
                  cycleId,
                }),
              },
            ],
        awaitRefetchQueries: true,
        update: () => {
          deletePracticeEvidenceFromCache({
            id,
            removedEvidence,
            cycleId,
            activeTab,
          });
        },
      });

      dispatch(
        setToastMsg({
          msg: "toastMsgs:evidence_removed",
          type: "success",
        })
      );
    } catch (e) {
      dispatchError({ dispatch, e });
    } finally {
      dispatch(updateLoadingState(false));
    }
  };
};

export const deleteEvidenceFromDocumentCategory = ({
  id,
  removedEvidence,
  shouldRefetchEvidenceFeedQuery = false,
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const cycleId = _.get(state, "snpV2.currentCycle.id", "");
    try {
      dispatch(updateLoadingState(true));
      await client.mutate({
        mutation: handleSnPEvidenceInDocumentCategoryMutation,
        variables: { input: { id, removedEvidence } },
        update: () => {
          deleteDocumentCategoryEvidenceFromCache({ id, removedEvidence });
        },
        refetchQueries: shouldRefetchEvidenceFeedQuery
          ? [
              {
                query: getDocumentCategoryEvidenceFeedDetailsQuery,
                variables: getDocumentCategoryEvidenceFeedQueryVariables({
                  id,
                }),
              },
              {
                query: getSnPDocumentReviewStagesQuery,
                variables: { cycleId },
              },
            ]
          : [
              {
                query: getSnPDocumentReviewStagesQuery,
                variables: { cycleId },
              },
            ],

        awaitRefetchQueries: true,
      });

      dispatch(
        setToastMsg({
          msg: "toastMsgs:evidence_removed",
          type: "success",
        })
      );
    } catch (e) {
      console.error(e);
      dispatchError({ dispatch, e });
    } finally {
      dispatch(updateLoadingState(false));
    }
  };
};

export const tagPractices = ({
  evaluationCycleId,
  itemId,
  itemType,
  addedPractices,
  removedPractices = [],
  assignmentFeedFilters,
}) => async (dispatch, getState) => {
  try {
    await client.mutate({
      mutation: tagSnpPracticesMutation,
      variables: {
        input: {
          evaluationCycleId,
          itemId,
          itemType,
          addedPractices,
          removedPractices,
        },
      },
      update: (
        cache,
        {
          data: {
            platform: { handleSnPPracticeTagging },
          },
        }
      ) => {
        const { centralRepoInput } = getUnitPlanCentralRepoInputAndFilters({
          courseId: _.get(
            getState(),
            "teacher.selected_class.selected_course",
            ""
          ),
          organizationId: _.get(getState(), "login.userInfo.org_id", ""),
        });
        const currentViewType = _.get(
          getState(),
          "unitPlans.currentViewType",
          ""
        );
        const currentCurriculumType = _.get(
          getState(),
          "platform.currentCurriculumProgram.type",
          ""
        );
        updateFeedInCache({
          itemId,
          itemType,
          evaluationCycleId,
          addedPractices: _.map(addedPractices, id => ({ id })),
          removedPractices: _.map(removedPractices, id => ({ id })),

          unitPlanId: _.get(getState(), "planner.unitPlanData.unitPlanId", ""),
          userId: _.get(getState(), "login.userInfo.id", ""),
          handleSnPPracticeTagging,
          centralRepoInput,
          currentViewType,
          currentCurriculumType,
          assignmentFeedFilters,
        });
      },
      refetchQueries:
        itemType == SNP_DOCUMENT_CATEGORY
          ? [
              /** When a practices is tagged in a document category, we need to fetch its evidences as well */
              {
                query: getDocumentCategoryTaggedPracticesQuery,
                variables: getDocumentCategoryTaggedPracticeQueryVariables({
                  categoryId: itemId,
                  cycleId: evaluationCycleId,
                }),
              },
            ]
          : [],
      awaitRefetchQueries: true,
    });

    dispatch(
      setToastMsg({
        msg: "toastMsgs:success_tagged_post",
        type: "success",
        position: "toast-bottom-left",
      })
    );
  } catch (e) {
    dispatchError({ dispatch, e });
    throw e;
  }
};

export const removeTaggedPractice = ({
  evaluationCycleId,
  itemId,
  itemType,
  practiceId,
}) => {
  return async dispatch => {
    const variables = {
      categoryId: itemId,
      first: 9,
      orderBy: "CREATED_AT",
      orderByDirection: "DESC",
      evidenceFilters: {
        evaluationCycleIds: [evaluationCycleId],
        evidenceStatus: "APPROVED",
      },
    };

    const queryData = getDocumentCategoryTaggedPracticesFromCache({
      variables,
    });

    const oldPractices = _.get(
      queryData,
      "taggedPractices[0].taggedPractice",
      []
    );

    try {
      await client.mutate({
        mutation: tagSnpPracticesMutation,
        variables: {
          input: {
            evaluationCycleId,
            itemId,
            itemType,
            removedPractices: [practiceId],
          },
        },
        update: () => {
          writeDocumentCategoryTaggedPracticesInCache({
            variables,
            data: {
              node: {
                id: itemId,
                taggedPractices: [
                  {
                    evaluationCycle: {
                      id: evaluationCycleId,
                      __typename: "SnPEvaluationCycle",
                    },
                    taggedPractice: _.reject(oldPractices, [
                      "practice.id",
                      practiceId,
                    ]),
                    __typename: "SnPTaggedPractice",
                  },
                ],
                __typename: "SnPDocumentCategory",
              },
            },
          });
        },
      });

      dispatch(
        setToastMsg({
          msg: "toastMsgs:action_successfully",
          locale_params: [
            {
              key: "label",
              value: "common:practice",
              isPlainText: false,
            },
            {
              key: "action",
              value: "common:untagged",
              isPlainText: false,
            },
          ],
          type: "success",
        })
      );
    } catch (e) {
      dispatchError({ dispatch, e });
      throw e;
    }
  };
};

export const getUserStatus = ({ status, state }) => {
  const { first_name: firstName, last_name: lastName, id } = _.get(
    state,
    "login.userInfo",
    {}
  );

  return {
    status,
    updatedBy: {
      id,
      firstName,
      lastName,
      __typename: "Staff",
    },
    updatedAt: moment().toISOString(),
    __typename: "SnPUserStatus",
  };
};

// --------REDUX--------

const NAME = "snpV2";
const UPDATE_DASHBOARD_VIEW_PREFERENCE =
  "UPDATE_DASHBOARD_VIEW_PREFERENCE" + " " + NAME;
const UPDATE_CYCLE_DETAILS = "UPDATE_CYCLE_DETAILS" + " " + NAME;
const UPDATE_LOADING_STATE = "UPDATE_LOADING_STATE" + " " + NAME;
const UPDATE_EMPTY_STATE = "UPDATE_EMPTY_STATE" + " " + NAME;
export const updateDashboardViewPreference = data => {
  return { type: UPDATE_DASHBOARD_VIEW_PREFERENCE, payload: data };
};

export const updateCycleDetails = data => {
  return { type: UPDATE_CYCLE_DETAILS, payload: data };
};

export const updateLoadingState = data => {
  return { type: UPDATE_LOADING_STATE, payload: data };
};

export const updateEmptyState = data => {
  return { type: UPDATE_EMPTY_STATE, payload: data };
};

const REDUCER_HANDLERS = {
  [UPDATE_DASHBOARD_VIEW_PREFERENCE]: (state, action) => {
    return update(state, { currentDashboardView: { $set: action.payload } });
  },
  [UPDATE_CYCLE_DETAILS]: (state, action) => {
    return update(state, { currentCycle: { $set: action.payload } });
  },
  [UPDATE_LOADING_STATE]: (state, action) => {
    return update(state, { isLoading: { $set: action.payload } });
  },
  [UPDATE_EMPTY_STATE]: (state, action) => {
    return update(state, { isCyclesEmpty: { $set: action.payload } });
  },
};

const initialCycleDetails = {
  id: "",
  isArchived: false,
  curriculumProgramId: "",
  setId: "",
};

const initialState = {
  currentDashboardView: "LIST",
  currentCycle: initialCycleDetails,
  isLoading: false,
  isCyclesEmpty: true,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
