import React from "react";
import {
  I18nHOC,
  TextAreaInput,
  withLoader,
  BenchmarkView,
  EmptyField,
} from "UIComponents";
import _ from "lodash";
import { graphql, compose } from "react-apollo";
import classes from "./MYPObjectiveRubric.scss";
import { getPlannerElementSetsOfCurriculumFromCache } from "modules/CommonGraphqlHelpers";
import { getPlannerElementSetsOfCurriculumQuery } from "modules/CommonQuery";
import { connect } from "react-redux";
import { DEMO_MYP_ORG } from "Constants";
import { orderNodes, checkIsNodeTopParent } from "Utils";
import RubricTable from "./RubricTable";
import { plannerElementValue } from "modules/Services";
import { handledMYPRubricDescriptors } from "modules/AssessmentTool/AssessmentToolModule";
import { getCriteriaDescriptorsQuery } from "modules/AssessmentTool/AssessmentToolQuery";
import { createAssessmentTool } from "AssessmentToolSetup/modules/AssessmentToolSetupModule";
import { getCriteriaDescriptorsFromCache } from "modules/AssessmentTool/AssessmentToolGraphqlHelpers";

const updateObjectiveNodes = ({ nodes }) => {
  return _.map(nodes, node => {
    const { depth, label } = node;

    switch (depth) {
      //Hack to convert Objective into Criteria
      case 1: {
        return {
          ...node,
          label: _.replace(label, "MYP Objectives", "MYP Criteria"),
        };
      }
      //Hack to convert Objective into Criteria
      case 2: {
        return {
          ...node,
          label: _.replace(
            label,
            /\b(?:Objective|Criteria|Objectives)\b/gi,
            "Criterion"
          ),
        };
      }
      default:
        return node;
    }
  });
};

const updateObjectiveNodesMemoize = _.memoize(
  params => updateObjectiveNodes(params),
  params => JSON.stringify(params)
);
class MYPObjectiveRubric extends React.PureComponent {
  constructor(props) {
    super(props);
    this.rubricTableRef = null;
    if (props.customRef) {
      props.customRef(this);
    }
  }

  blur = () => {
    if (this.rubricTableRef) {
      this.rubricTableRef.blur();
    }
  };

  updateObjectives = newValues => {
    const {
      objectiveField = {},
      updateInputField,
      handledMYPRubricDescriptors,
      assessmentTool,
    } = this.props;
    const { value, fieldKey, unitPlanFieldResolvedValue } = objectiveField;

    updateInputField(
      { [fieldKey]: newValues },
      { nodes: _.get(unitPlanFieldResolvedValue, "nodes", []) }
    );
    handledMYPRubricDescriptors({
      newObjectives: newValues,
      oldObjectives: value,
      rubricId: assessmentTool.id,
    });
  };

  getObjectiveSelection = () => {
    const { mode, objectiveField = {}, t } = this.props;
    const { value, unitPlanFieldResolvedValue } = objectiveField;

    const objectivesNodes = _.get(objectiveField, "resolvedValue.nodes", []);

    let nodes = _.uniqBy(
      [...unitPlanFieldResolvedValue.nodes, ...objectivesNodes],
      "id"
    );

    const hasDisplaySequence = !_.some(nodes, node => !node.displaySequence);

    if (hasDisplaySequence) {
      nodes = _.orderBy(nodes, ["displaySequence"], ["asc"]);
    }

    const updatedUnitPlanFieldResolvedValue = {
      ...unitPlanFieldResolvedValue,
      nodes: updateObjectiveNodesMemoize({
        nodes,
      }),
    };

    const nestedValue = plannerElementValue({
      fieldObj: {
        viewType: "Nestedview",
      },
      resolvedValue: updatedUnitPlanFieldResolvedValue,
      value: _.map(nodes, item => item.id),
    });

    return (
      <React.Fragment>
        <div className={classes.subText}>
          {t("common:a_rubric_will_be_generated")}
        </div>
        <BenchmarkView
          mode={"view"}
          value={nestedValue || {}}
          selectedValues={value || []}
          isTagging={mode == "edit"}
          updateInputField={this.updateObjectives}
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      objectives,
      unitPlanObjectives,
      subType,
      emptyTextObj,
      mode,
      t,
    } = this.props;

    return (
      <div className={classes.container}>
        {_.isEmpty(unitPlanObjectives) && mode == "edit" ? (
          <EmptyField
            title={t(_.get(emptyTextObj, subType, "common:not_defined"))}
          />
        ) : (
          <>
            {mode == "edit" && this.getObjectiveSelection()}
            {!_.isEmpty(objectives) && (
              <RubricTable
                {...this.props}
                objectives={updateObjectiveNodesMemoize({ nodes: objectives })}
                customRef={ref => (this.rubricTableRef = ref)}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  handledMYPRubricDescriptors,
  createAssessmentTool,
};

const mapStateToProps = (state, ownProps) => {
  const curriculumType = "IB_MYP";

  const isDemo = _.get(state, "teacher.selected_class.isDemo", false);
  //Can move it into template
  const plannerElementTypes = ["MYP_ACHIEVEMENT_LEVEL"];
  const curriculum = _.find(state.platform.organizationCurriculumPrograms, {
    type: curriculumType,
  });

  const unitPlanObjectives = _.get(
    ownProps,
    "options.objectiveField.unitPlanFieldValue",
    []
  );

  return {
    curriculumType,
    curriculumNodeInterfaceType: _.get(curriculum, "nodeInterfaceType", ""),
    curriculumId: isDemo
      ? _.get(DEMO_MYP_ORG, "curriculumId", "")
      : _.get(curriculum, "id", ""),
    plannerElementTypes,
    isData: true,
    isLoading: false,
    unitPlanObjectives,
  };
};

/**
 * This function extracts the objectives from nodes
 * It also handles the special case for objective node.
 * It also handles the special case if objectives are selected from multiple years
 */

const getObjectivesFromNodes = ({ nodes }) => {
  let objectives = orderNodes({
    nodes,
    startDepth: 2,
    gradeSequences: [],
  });

  const parents = _.filter(nodes, node => checkIsNodeTopParent({ node }));

  objectives = _.map(objectives, node => {
    const { isLeaf, parent, label } = node;

    if (isLeaf) {
      return node;
    } else {
      //Handle specially to convert objective to criteria
      const parentLabel = _.get(_.find(parents, { id: parent }), "label", "");

      const updatedLabel = _.replace(label, "MYP Objective", "MYP Criteria");
      const updatedParentLabel = _.replace(parentLabel, "MYP Objectives", "");
      //Handle specially if there are objectives selected from multiple years
      if (_.get(parents, "length", 0) > 1) {
        return { ...node, label: `${updatedParentLabel} - ${updatedLabel}` };
      } else {
        return { ...node, label: updatedLabel };
      }
    }
  });
  return objectives;
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getPlannerElementSetsOfCurriculumQuery, {
    name: "getPlannerElementSetsOfCurriculum",
    skip: ({ curriculumType }) => curriculumType == "IB_PYP",
    options: ({
      curriculumId,
      plannerElementTypes,
      curriculumNodeInterfaceType,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: plannerElementTypes,
      },
    }),
    props({
      getPlannerElementSetsOfCurriculum,
      ownProps: {
        isData,
        isLoading,
        curriculumId,
        plannerElementTypes,
        curriculumNodeInterfaceType,
        options,
      },
    }) {
      const curriculumData = getPlannerElementSetsOfCurriculumFromCache({
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: plannerElementTypes,
      });

      const achievementLevels = _.get(
        _.find(_.get(curriculumData, "plannerElementSets", []), {
          type: "MYP_ACHIEVEMENT_LEVEL",
        }),
        "nodes",
        []
      );

      const { objectiveField } = options;

      const { resolvedValue: { nodes = [] } = {} } = objectiveField || {};

      const objectives = getObjectivesFromNodes({
        nodes,
      });

      return {
        objectives,
        achievementLevels,
        objectiveField,
        isData: !_.isEmpty(curriculumData) && isData,
        isLoading:
          getPlannerElementSetsOfCurriculum["networkStatus"] == 1 ||
          getPlannerElementSetsOfCurriculum["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),

  withLoader
)(MYPObjectiveRubric);

MYPObjectiveRubric.defaultProps = {
  isEvaluation: true,
};

//Need Review @kunal
