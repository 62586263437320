import gql from "graphql-tag";
import { projectInfoFragment } from "./ProgressFragments";
import { userFragment } from "modules/CommonFragments";

export const getStudentProjectQuery = gql`
  query getStudentProject($studentId: ID!, $projectGroupId: ID!) {
    node(id: $studentId, type: STUDENT) {
      ... on Student {
        id
        projects(filters: { projectGroupId: $projectGroupId }) {
          ...projectInfo
        }
      }
    }
  }
  ${projectInfoFragment}
`;

export const getStudentProjectIdQuery = gql`
  query getStudentProjectId($studentId: ID!, $projectGroupId: ID!) {
    node(id: $studentId, type: STUDENT) {
      ... on Student {
        id
        projects(filters: { projectGroupId: $projectGroupId }) {
          id
        }
      }
    }
  }
`;

export const getSupervisorProjectQuery = gql`
  query getSupervisorProject(
    $staffId: ID!
    $projectGroupIds: [ID!]
    $searchText: String
  ) {
    node(id: $staffId, type: STAFF) {
      ... on Staff {
        ...userItem
        projects(
          filters: {
            projectGroupIds: $projectGroupIds
            searchText: $searchText
          }
        ) {
          ...projectInfo
        }
      }
    }
  }
  ${projectInfoFragment}
  ${userFragment.basicUser}
`;

//here filter variable will take "STATUS" or "SUPERVISOR"
export const getGroupedProjectsQuery = gql`
  query getGroupedProjects(
    $projectGroupId: ID!
    $filters: GroupedProjectsFilterInput
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        groupedProjects(filters: $filters) {
          groupedItem {
            id
            ... on Staff {
              id
              firstName
              lastName
              profileImage
            }
            ... on ProjectStatus {
              id
              status
            }
          }
          projects {
            ...projectInfo
          }
        }
      }
    }
  }
  ${projectInfoFragment}
`;

export const getCoordinatorProjectsQuery = gql`
  query getCoordinatorProjects(
    $projectGroupId: ID!
    $filters: ProjectGroupProjectsFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        projects(filters: $filters) {
          totalCount
          edges {
            node {
              ...projectInfo
            }
          }
        }
      }
    }
  }
  ${projectInfoFragment}
`;

export const getSupervisorStudentProjectsQuery = gql`
  query getSupervisorStudentProjects(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: ProjectFilter
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        ...userItem
        projects(filters: $filters) {
          ...projectInfo
        }
      }
      ... on Student {
        ...userItem
        projects(filters: $filters) {
          ...projectInfo
        }
      }
    }
  }
  # TODO: make object of fragment "projectInfoFragment"
  ${userFragment.basicUser}
  ${projectInfoFragment}
`;
