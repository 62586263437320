import PropTypes from "prop-types";
import React from "react";

const GoogleDriveIconV2 = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 14"
      fill={props.fill && props.fill}
    >
      <path d="M16 9.49709C16.0001 9.38279 15.961 9.27191 15.8893 9.18293L10.4237 0.239177C10.379 0.166101 10.3164 0.105727 10.2417 0.0638452C10.1669 0.0219634 10.0827 -2.12312e-05 9.99709 6.83787e-08H5.49847C5.45432 -2.31381e-05 5.41036 0.00586063 5.36776 0.0174947C5.35527 0.0209937 5.34427 0.0279915 5.33177 0.0322402C5.30301 0.0414076 5.27515 0.0532037 5.24855 0.0674794C5.23562 0.0761881 5.22318 0.0856167 5.21131 0.0957207C5.18813 0.110527 5.16622 0.127247 5.14583 0.145705C5.13456 0.158822 5.12397 0.172503 5.11409 0.186693C5.09711 0.20507 5.08165 0.224795 5.06785 0.245675C5.06703 0.247011 5.06636 0.248436 5.06585 0.249923L5.06311 0.253672L0.0646394 9.12595C0.0209474 9.2033 -0.00134308 9.29089 6.25998e-05 9.37971C0.00146828 9.46853 0.0265193 9.55537 0.072637 9.6313L2.56537 13.744L2.57187 13.755C2.58377 13.7725 2.5968 13.7892 2.61086 13.805C2.6228 13.8219 2.63573 13.8381 2.6496 13.8535C2.66757 13.8695 2.6867 13.8843 2.70683 13.8975C2.72113 13.9094 2.73607 13.9205 2.75156 13.9307C2.77561 13.9433 2.8007 13.9539 2.82654 13.9622C2.84104 13.9677 2.85453 13.9757 2.86928 13.9797C2.91171 13.9907 2.9554 13.9961 2.99924 13.9957H12.9962C13.0811 13.9959 13.1647 13.9744 13.239 13.9333C13.3134 13.8922 13.376 13.8328 13.421 13.7608L15.8953 9.79924C15.9627 9.71292 15.9996 9.60663 16 9.49709ZM9.71668 0.999693L14.6042 8.99724H10.7911L6.34821 0.999693H9.71668ZM9.6472 8.99724H6.22525L7.98521 6.00516L9.6472 8.99724ZM5.49522 1.52453L7.42288 4.99147L3.48384 11.6862L2.99299 12.5212L1.07883 9.36288L5.49522 1.52453ZM12.7193 12.996H3.87397L5.63818 9.99693H14.5937L12.7193 12.996Z" />
    </svg>
  );
};

GoogleDriveIconV2.defaultProps = {
  width: 16,
  height: 14,
};
GoogleDriveIconV2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
export default GoogleDriveIconV2;
