import React from "react";
import classes from "./StepContainer.scss";
import UnitSelectionWrapper from "../UnitSelectionWrapper";
import LESelectWrapper from "../LESelectWrapper";

const StepItem = props => {
  const { currentStep, selectedItems, setSelectedItems } = props;

  switch (currentStep) {
    case "UNIT_SELECTION": {
      return (
        <UnitSelectionWrapper
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      );
    }
    case "LE_SELECTION": {
      return (
        <LESelectWrapper
          currentStep={currentStep}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      );
    }
  }
};
const StepContainer = props => {
  const { currentStep, selectedItems, setSelectedItems } = props;
  return (
    <div className={classes.container}>
      <StepItem
        currentStep={currentStep}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </div>
  );
};

export default StepContainer;
