import { withAsyncRouteLoading } from "UIComponents";

import { AssessmentDetails } from "Assessments";
export default store => {
  return {
    path: "collection/:collectionId",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community-collection"
          );
        })
    ),
    childRoutes: [AssessmentDetails(store)],
  };
};
