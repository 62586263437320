import React from "react";
import classes from "./InviteTeacherModal.scss";
import { compose } from "redux";
import {
  UIModal,
  UIButton,
  InputTextField,
  FullScreenLoader,
  SelectDropdown,
  I18nHOC,
} from "UIComponents";
import * as EventTracker from "lib/eventTracker";
import ACLStore from "lib/aclStore";
import { getGlobalConstantsFromCache } from "modules/CommonGraphqlHelpers";
import { CancelIcon, DeleteIcon, AddCircleIcon } from "SvgComponents";
import { generateRandomId } from "Utils";
import update from "immutability-helper";
import { colors } from "Constants";
import { emailValidation, getStringFromArray } from "Utils";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";
import { getDesignationsLocales } from "modules/Services";

const inputFieldStyle = {
  inputStyle: {
    padding: "12px 16px",
  },
};

class InviteTeacherModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.emailRefs = {};
    this.warnings = [];
    this.state = {
      isLoading: false,
      invitedTeachers: [this.getNewTeacherObject()],
    };
  }

  getNewTeacherObject = () => {
    const { designationList } = this.props;
    return {
      id: generateRandomId(),
      email: "",
      designation: designationList.first().value,
      status: "INVITED",
      userType: "staff",
    };
  };

  updateInviteTeacherField = ({ params, index }) => {
    const { invitedTeachers } = this.state;
    const teacher = { ...invitedTeachers[index], ...params };
    const updatedInvitedTeachers = update(invitedTeachers, {
      $splice: [[index, 1, teacher]],
    });

    this.setState({ invitedTeachers: updatedInvitedTeachers });
  };

  deleteTeacher = ({ index }) => {
    const { invitedTeachers } = this.state;
    const updatedInvitedTeachers = update(invitedTeachers, {
      $splice: [[index, 1]],
    });

    this.setState({ invitedTeachers: updatedInvitedTeachers }, () => {
      if (invitedTeachers.length == 1) {
        this.addTeacher();
      }
    });
  };

  addTeacher = () => {
    const { invitedTeachers } = this.state;
    const updatedInvitedTeachers = update(invitedTeachers, {
      $push: [this.getNewTeacherObject()],
    });

    this.setState({ invitedTeachers: updatedInvitedTeachers });
  };

  checkEmailVerification = value => {
    const { t } = this.props;
    const isValidEmail = !!value && emailValidation(value);

    if (!isValidEmail) {
      return t("common:valid_email_msg");
    } else {
      return _.get(_.find(this.warnings, { email: value }), "message", "");
    }
  };

  onClickInvite = async () => {
    const {
      onCancelClick,
      createOrganizationInvitedUser,
      setToastMsg,
      designationList,
      parentType,
    } = this.props;
    const { invitedTeachers } = this.state;
    let totalErrors = 0;
    _.map(this.emailRefs, ref => {
      if (ref) {
        totalErrors += ref.isValid();
      }
    });
    if (totalErrors == 0) {
      this.setState({ isLoading: true });
      const { warning = [] } = await createOrganizationInvitedUser({
        invitedUsers: _.map(invitedTeachers, teacher =>
          _.omit(teacher, ["id"])
        ),
      });

      this.setState({ isLoading: false });
      if (_.isEmpty(warning)) {
        const invitedEmails = _.map(invitedTeachers, ({ email }) => email);
        const invitedMoreString = getStringFromArray({
          nameArray: invitedEmails,
          andProps: 1,
        });

        const invitedTrackedTeachers = _.map(
          invitedTeachers,
          ({ email, designation }) => {
            return {
              invited_from: parentType,
              invited_email: email,
              invited_role: _.get(
                _.find(designationList, { value: designation }),
                "label"
              ),
            };
          }
        );

        _.map(invitedTrackedTeachers, teacher => {
          EventTracker.recordEvent({
            eventName: "Invited Collaborator",
            eventData: teacher,
          });
        });

        setToastMsg({
          msg: "toastMsgs:invite_email_sent_msg",
          locale_params: [
            {
              key: "invitedMoreString",
              value: invitedMoreString,
              isPlainText: true,
            },
          ],
          type: "tick",
          timeOut: 3500,
          position: "toast-bottom-left",
        });
        onCancelClick();
      } else {
        this.warnings = [...this.warnings, ...warning];

        _.map(this.emailRefs, ref => {
          if (ref) {
            ref.isValid();
          }
        });
      }
    } else {
      setToastMsg({
        msg: "toastMsgs:rectify_errors_with_count",
        locale_params: [
          { key: "count", value: totalErrors, isPlainText: true },
        ],
        position: "toast-top-center",
        type: "alert",
        fill: colors.yellow50,
      });
    }
  };

  render() {
    const { onCancelClick, designationList, t } = this.props;
    const { invitedTeachers, isLoading } = this.state;
    return (
      <UIModal isOpen={true}>
        <div className={classes.container}>
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <div className={classes.headerTitle}>
                {t("common:invite_your_team")}
              </div>
              <div className={classes.cancelIcon} onClick={onCancelClick}>
                <CancelIcon width={16} height={16} />
              </div>
            </div>
            <div className={classes.scrollContainer}>
              <div className={classes.scrollInnerContainer}>
                <div className={classes.row}>
                  <div className={classes.fieldLabel}>
                    {t("common:email_address")}
                  </div>
                  <div className={classes.fieldLabel}>
                    {t("common:select_with_label", {
                      label: t("common:role"),
                    })}
                  </div>
                </div>
                <div className={classes.rowList}>
                  {_.map(invitedTeachers, (teacher, index) => {
                    const { id, email, designation } = teacher;
                    return (
                      <div className={classes.row} key={id}>
                        <InputTextField
                          value={email}
                          name={"email"}
                          customValidation={this.checkEmailVerification}
                          inputStyle={inputFieldStyle.inputStyle}
                          updateInputField={params =>
                            this.updateInviteTeacherField({ params, index })
                          }
                          error={t("common:enter_email_address")}
                          showErrorText={true}
                          ref={ref => (this.emailRefs[id] = ref)}
                          placeholder={t("common:enter_an_email")}
                        />
                        <SelectDropdown
                          options={designationList}
                          value={designation}
                          name={"designation"}
                          updateInputField={params =>
                            this.updateInviteTeacherField({ params, index })
                          }
                        />
                        <div
                          className={classes.deleteBox}
                          onClick={() => this.deleteTeacher({ index })}
                        >
                          <DeleteIcon width={16} height={16} />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.addButton} onClick={this.addTeacher}>
                  <AddCircleIcon width={16} height={16} fill={colors.blue29} />
                  <div className={classes.addButtonText}>
                    {t("common:add_with_label", {
                      label: t("common:lowercase", {
                        text: t("common:another"),
                      }),
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <UIButton size={"sm"} onClick={onCancelClick}>
              {t("common:cancel")}
            </UIButton>
            <UIButton color={"pink"} size={"sm"} onClick={this.onClickInvite}>
              {t("common:invite")}
            </UIButton>
          </div>
        </div>
        {isLoading && <FullScreenLoader />}
      </UIModal>
    );
  }
}

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  const isAdmin = ACLStore.can("AdminPortal");
  return {
    isAdmin,
    designationList: getDesignationsLocales({
      t: ownProps.t,
    }),
  };
};

export default compose(
  I18nHOC({ resource: ["common", "schoolSetup"] }),
  connect(mapStateToProps, mapActionCreators)
)(InviteTeacherModal);
