import { withAsyncRouteLoading } from "UIComponents";
export default store => ({
  path: "manage-folders",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("../../../Journal/routes/ManageFolders/components")
              .default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-settings"
        );
      })
  ),
});
