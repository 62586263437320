import React from "react";
import classes from "./ModalContainer.scss";
import { colors } from "Constants";

import ResourceItemCard from "../../ResourceItemCard";

class ModalContainer extends React.PureComponent {
  render() {
    let {
      resources,
      selectedResources,
      updateSelectedResources,
      canPick,
    } = this.props;

    return (
      <div className={classes.container}>
        {_.map(resources, item => {
          return (
            <div className={classes.item} key={item.id}>
              <ResourceItemCard
                isSelected={
                  _.findIndex(
                    selectedResources,
                    resource => resource == item.id
                  ) != -1
                }
                updateSelectedResources={updateSelectedResources}
                item={item}
                canPick={canPick}
                cardmode="select"
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default ModalContainer;
