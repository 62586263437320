import { withAsyncRouteLoading } from "UIComponents";
import SubjectToGrades from "./routes/SubjectsToGrades";
import ScopeAndSequence from "./routes/ScopeAndSequence";
import DPSubjectsSetup from "./routes/DPSubjectsSetup";
import Sync from "./routes/Sync";

export default store => ({
  path: ":curriculumProgramId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "school-onboard-setup"
        );
      })
  ),
  childRoutes: [
    SubjectToGrades(store),
    ScopeAndSequence(store),
    Sync(store),
    DPSubjectsSetup(store),
  ],
});
