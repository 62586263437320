import React from "react";
import classes from "./Duration.scss";
import { UIInputNumberField, I18nHOC } from "UIComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";

class Duration extends UIBaseComponent {
  isValidValue = value => {
    return !!value && value.week > 0 && value.hour > 0;
  };
  isValid = () => {
    let totalCount = 0;
    if (this.week) {
      totalCount += this.week.isValid();
    }
    if (this.hour) {
      totalCount += this.hour.isValid();
    }
    return totalCount;
  };
  updateInputField = params => {
    const { value } = this.props;
    let updateValue = value ? { ...value, ...params } : { ...params };
    this.updateValue(updateValue);
  };
  renderEdit = () => {
    const { value, t } = this.props;
    const weekValue = _.get(value, "week", 0);
    const hourValue = _.get(value, "hour", 0);

    return (
      <div className={classes.container}>
        <div className={classes.item}>
          <UIInputNumberField
            name="week"
            ref={ref => {
              this.week = ref;
            }}
            numberLabel={t("noOfWeeks")}
            value={weekValue}
            updateInputField={this.updateInputField}
          />
        </div>
        <div className={classes.item}>
          <UIInputNumberField
            name="hour"
            ref={ref => {
              this.hour = ref;
            }}
            numberLabel={t("hrsPerWeek")}
            value={hourValue}
            updateInputField={this.updateInputField}
          />
        </div>
      </div>
    );
  };
}
export default I18nHOC({ resource: "common" })(Duration);
