import React, { useState } from "react";
import classes from "./InputCheckList.scss";
import { AddCircleOutlined } from "@toddle-design/web-icons";
import { useI18n } from "Hooks";
import shortId from "shortid";
import ListItem from "./ListItem/ListItem";
import CheckListItem from "./CheckListItem/CheckListItem";
import { Button } from "@toddle-design/web";
import update from "immutability-helper";

const InputCheckList = props => {
  const { value, onValueChange, listType } = props;
  const { t } = useI18n(["common"]);
  const [focusedItem, setFocusedItem] = useState("");

  const onActionClick = action => {
    switch (action.value) {
      case "DELETE":
        onValueChange(
          _.filter(value, val => {
            return val.id != action.id;
          })
        );
        break;
      case "EDIT":
        setFocusedItem(action.id);
        break;
      default:
        return {};
    }
  };

  const onAddClick = () => {
    if (!_.isEmpty(_.last(value).value)) {
      const newItemId = shortId.generate();
      onValueChange([...value, { id: newItemId, value: "", isSelected: true }]);
      setFocusedItem(newItemId);
    }
  };

  const onUpdateItem = ({ id, type, label }) => {
    let indexToUpdate = "";
    switch (type) {
      case "SELECTION":
        indexToUpdate = _.findIndex(value, { id: id });
        onValueChange(
          update(value, {
            [indexToUpdate]: {
              isSelected: { $set: !value[indexToUpdate].isSelected },
            },
          })
        );
        break;
      case "LABEL":
        indexToUpdate = _.findIndex(value, { id: id });
        onValueChange(
          update(value, { [indexToUpdate]: { value: { $set: label } } })
        );
        break;
      default:
        return {};
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.listContainer}>
        {_.map(value, val => {
          return _.isEqual(listType, "CheckList") ? (
            <CheckListItem
              item={val}
              onActionClick={onActionClick}
              updateItem={onUpdateItem}
              removeFocuedItem={() => setFocusedItem("")}
              isFocused={_.isEqual(focusedItem, val.id)}
            />
          ) : (
            <ListItem
              item={val}
              onActionClick={onActionClick}
              updateItem={onUpdateItem}
              removeFocuedItem={() => setFocusedItem("")}
              isFocused={_.isEqual(focusedItem, val.id)}
            />
          );
        })}
      </div>
      <div className={classes.addButtonContainer}>
        <Button
          variant={
            _.isEmpty(_.get(_.last(value), "value", ""))
              ? "inline"
              : "inline-primary"
          }
          icon={<AddCircleOutlined />}
          onClick={onAddClick}
          disabled={_.isEmpty(_.get(_.last(value), "value", ""))}
        >
          {t("common:add_with_label", {
            label: t("common:another"),
          })}
        </Button>
      </div>
    </div>
  );
};

export default InputCheckList;
