import React, { useState, useMemo, memo } from "react";
import classes from "./UnitSummary.scss";
import { CloseOutlined } from "@toddle-design/web-icons";
import { compose, graphql } from "react-apollo";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  // MoreItemWithToolTip,
  I18nHOC,
  Unveil,
  withLoader,
  AnimateHeight,
} from "UIComponents";
import { colors } from "Constants";
// import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import {
  // getSubjectLabel,
  // getPypElementLabel,
  getElementValue,
} from "modules/Services";
import { updateTemplate } from "IBPlanner/modules/IBPlannerModule";
import { getPlannerFieldOptions } from "UnitPlans/modules/UnitPlanModule";
import FieldComponent from "UnitPlans/components/IBcomponents/FieldComponent";
import LabelComponent from "UnitPlans/components/LabelComponent";
import { fontStyle } from "Constants";
import { getUnitSummaryPlanQuery } from "IBPlanner/modules/IBQuery";
import { getUnitSummaryPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { BottomArrow } from "SvgComponents";
import { getUnitPlanStagesMemoized } from "UnitPlans/modules/Utils";
import { IconButton } from "@toddle-design/web";
import { size } from "lodash-es";
// import { plannerIcons } from "UnitPlans/components/plannerIcons";

const UNIT_SUMMARY_FIELDS = [
  "centralIdea",
  "lois",
  "concepts",
  "lp",
  "atls",
  "benchmarks",
];

const inputStyle = {
  labelStyle: {
    ...fontStyle.demiBold,
    fontSize: "1.4rem",
    lineHeight: 1.71,
    color: colors.violet63,
  },
  fieldStyle: {
    ...fontStyle.medium,
    color: colors.black,
    fontSize: "1.2rem",
    lineHeight: 1.71,
  },
  textStyle: {
    ...fontStyle.medium,
    color: colors.black,
    fontSize: "1.2rem",
    lineHeight: 1.71,
  },
};
const nodeLabelStyle = [
  {
    depth: 0,
    style: {
      color: colors.black,
    },
  },
  {
    depth: 1,
    style: {
      color: colors.black,
    },
  },
  {
    depth: 2,
    style: {
      fontSize: "1.2rem",
    },
  },
  {
    depth: 3,
    style: {
      color: colors.gray48,
      fontSize: "1.2rem",
    },
  },
];

// const getRethumb = imageUrl => {
//   const rethumpUrl = imageUrl
//     ? getRethumbUrl({
//         width: 160,
//         height: 176,
//         imageUrl: imageUrl,
//         fitIn: true
//       })
//     : "";
//   return `url(${rethumpUrl})`;
// };

const backwardCompatibilityStyles = {
  container: {},
  fieldContainer: { padding: "12px 32px" },
  header: { marginBottom: "20px" },
};

const getFieldTypeConfig = ({ viewType }) => {
  switch (viewType) {
    case "Nestedview":
    case "ScopeAndSequence":
      return {
        nodeLabelStyle,
      };
    case "RichText":
      return {
        textStyle: inputStyle.textStyle,
      };
    case "TextView":
      return {
        applyDefaultClass: false,
        customTextStyle: inputStyle.textStyle,
      };
    case "MultiField":
      return {
        containerStyle: {
          gridGap: 12,
        },
      };
    case "IBRelatedConcepts":
      return {
        listStyle: { marginBottom: "16px" },
        mainContainerStyle: { marginBottom: "8px" },
        headerContainerStyle: { marginBottom: "4px" },
        listHeaderStyle: { fontSize: "1.4rem" },
        listItemsStyle: { fontSize: "1.2rem" },
      };
    default:
      return {};
  }
};

const getSubFieldsWithStyles = subFields => {
  return _.map(subFields, sF => ({
    ...sF,
    config: {
      ...sF.config,
      fieldTypeConfig: {
        contentStyle: inputStyle.fieldStyle,
        ...getFieldTypeConfig(sF.viewType),
      },
    },
  }));
};

const UnitSummary = memo(function UnitSummary(props) {
  const {
    unitPlanFields, //gives all data regarding Unit plan
    onClose, //close button function
    unitPlanTemplate, //Unit Template
    t, //Labels
    hideCloseButton, //function to hide close button
    renderHeaderComponent, //for Discussion
    itemContainerClass, //for Discussion
    headerTitle,
    isLocalisedTemplate,
    theme,
    isLoading,
    unitPlanDetails,
    curriculumType,
    localisedTemplateId,
    localisedTemplateType,
    organizationId,
    showEmptyScreen = false,
    emptyScreen,
  } = props;

  const { containerStyle } = theme || {};
  const unitSummaryModules = _.get(
    unitPlanTemplate,
    "unitSummaryModules",
    null
  );

  return (
    <div
      className={classes.container}
      style={{
        ...containerStyle,
        ...(unitSummaryModules ? {} : backwardCompatibilityStyles.container),
      }}
    >
      {renderHeaderComponent ? (
        renderHeaderComponent()
      ) : (
        <HeaderV2
          t={t}
          unitPlanFields={unitPlanFields}
          onClose={onClose}
          hideCloseButton={hideCloseButton}
          unitSummaryModules={unitSummaryModules}
          headerTitle={headerTitle}
          localisedTemplateId={localisedTemplateId}
          localisedTemplateType={localisedTemplateType}
        />
      )}
      {/* {renderHeaderComponent ? (
        renderHeaderComponent()
      ) : unitSummaryModules ? (
        <HeaderV2
          t={t}
          unitPlanFields={unitPlanFields}
          onClose={onClose}
          hideCloseButton={hideCloseButton}
        />
      ) : (
        <Header
          t={t}
          unitPlanFields={unitPlanFields}
          onClose={onClose}
          hideCloseButton={hideCloseButton}
        />
      )} */}

      {unitSummaryModules ? (
        <Stages
          t={t}
          unitSummaryModules={unitSummaryModules}
          isLocalisedTemplate={isLocalisedTemplate}
          unitPlanFields={unitPlanFields}
          module_list={_.get(unitPlanTemplate, "module_list", {})}
          stage_list={_.get(unitPlanTemplate, "stage_list", {})}
          step_list={_.get(unitPlanTemplate, "step_list", {})}
          field_list={_.get(unitPlanTemplate, "field_list", {})}
          parent_stage_list={_.get(unitPlanTemplate, "parent_stage_list", {})}
          unitPlanDetails={unitPlanDetails}
          curriculumType={curriculumType}
          localisedTemplateId={localisedTemplateId}
          localisedTemplateType={localisedTemplateType}
        />
      ) : (
        <Fields
          t={t} //its good to use HOC directly in the given component than passing props around
          fields={_.get(unitPlanTemplate, "unitSummary", [])}
          field_list={_.get(unitPlanTemplate, "field_list", [])}
          isLocalisedTemplate={isLocalisedTemplate}
          unitPlanFields={unitPlanFields}
          unitSummaryModules={unitSummaryModules}
          localisedTemplateId={localisedTemplateId}
          localisedTemplateType={localisedTemplateType}
          organizationId={organizationId}
          showEmptyScreen={showEmptyScreen}
          emptyScreen={emptyScreen}
        />
      )}
    </div>
  );
});

const Stages = memo(function Stages(props) {
  const {
    unitPlanDetails,
    unitSummaryModules,
    unitPlanFields,
    module_list,
    stage_list,
    step_list,
    field_list,
    curriculumType,
    parent_stage_list,
  } = props;

  //you get updatedStageList and updatedModuleList from this func
  const updatedStageModuleLists = getUnitPlanStagesMemoized({
    unitPlanDetails,
    unitSummaryModules,
    module_list,
    unitPlanFields,
    stage_list,
    step_list,
    field_list,
    curriculumType,
    parent_stage_list,
  });

  const updatedStageList = updatedStageModuleLists.updatedStageList;
  const updatedParentStageList = updatedStageModuleLists.updatedParentStageList;

  const isUBD = curriculumType == "UBD";

  let parentStagesLabels = [];
  if (isUBD) {
    const updatedParentStages = _.get(
      module_list,
      "planner.parentStageList",
      {}
    );
    parentStagesLabels = updatedParentStages.reduce((result, key) => {
      if (!_.isEmpty(updatedParentStageList[key].stages)) {
        return result.concat(updatedParentStageList[key].label);
      }
      return result;
    }, []);
  }

  //in case on ubd unitStages is the set of non empty parent stages n in myp it is set of non empty stages
  const unitStages = isUBD ? parentStagesLabels : _.keys(updatedStageList);
  const [collapsedStages, setCollapsedStages] = useState(unitStages);

  const toggleCollapsedStages = params => {
    const { stageKey } = params;
    if (collapsedStages.includes(stageKey)) {
      setCollapsedStages(_.filter(collapsedStages, item => item !== stageKey));
    } else {
      setCollapsedStages([...collapsedStages, stageKey]);
    }
  };

  return (
    <>
      {_.map(unitStages, (stageKey, index) => {
        const isExpanded = !collapsedStages.includes(stageKey);
        const stageLabel = _.get(stage_list, `${stageKey}.label`, null);
        const labelClasses = classNames(classes.stageLabel, {
          [classes.stageLabelExpanded]: isExpanded,
        });

        /* in ubd a parentstage may have more than one stages this func is to return one array
        with all the stages corresponding steps to pass into Steps*/
        let ubdSteps = [];

        if (isUBD) {
          //finding the index of the parentstage corresponding to the label in parenstage array
          const key = _.find(
            _.keys(updatedParentStageList),
            keys => updatedParentStageList[keys].label === unitStages[index]
          );
          const stageList = updatedParentStageList[key].stages;

          _.forEach(stageList, stage => {
            ubdSteps = _.union(ubdSteps, updatedStageList[stage].steps);
          });
        }

        return (
          <div className={classes.stageContainer} key={stageKey}>
            <div
              className={classes.stageHeader}
              onClick={() => toggleCollapsedStages({ stageKey })}
            >
              {isUBD ? (
                <div className={labelClasses}>{unitStages[index]}</div>
              ) : (
                <div className={labelClasses}>{stageLabel}</div>
              )}
              <div
                className={classes.arrowIcon}
                style={
                  isExpanded
                    ? {
                        transform: "rotate(180deg)",
                      }
                    : {
                        transform: "rotate(0deg)",
                      }
                }
              >
                <BottomArrow />
              </div>
            </div>

            {/* stageSteps is the list of the non-empty stack names corresponding to the 
            particular stageKey stageSteps={unitStages[index][stageKey]}*/}
            <Steps
              stageKey={stageKey}
              isExpanded={isExpanded}
              {...props}
              stageSteps={isUBD ? ubdSteps : updatedStageList[stageKey].steps}
            />
          </div>
        );
      })}
    </>
  );
});

const Steps = memo(function Steps(props) {
  const {
    t,
    unitSummaryModules,
    isLocalisedTemplate,
    unitPlanFields,
    step_list,
    field_list,
    isExpanded,
    stageSteps,
    localisedTemplateId,
    localisedTemplateType,
  } = props;

  return (
    <AnimateHeight
      height={isExpanded ? "auto" : 0}
      duration={300}
      onAnimationStart={null}
      onAnimationEnd={null}
      animateOpacity={true}
    >
      <div className={classes.stepsContainer}>
        {_.map(stageSteps, stepKey => {
          const subLabel = _.get(step_list, `${stepKey}.subLabel`, null);
          return (
            <div key={stepKey} className={classes.stepGroup}>
              {subLabel && (
                <div className={classes.stepSubLabel}>{subLabel}</div>
              )}
              <Fields
                t={t}
                fields={_.get(step_list, `${stepKey}.fields`, [])}
                field_list={field_list}
                isLocalisedTemplate={isLocalisedTemplate}
                unitPlanFields={unitPlanFields}
                unitSummaryModules={unitSummaryModules}
                localisedTemplateId={localisedTemplateId}
                localisedTemplateType={localisedTemplateType}
              />
            </div>
          );
        })}
      </div>
    </AnimateHeight>
  );
});

const Fields = memo(function Fields(props) {
  const {
    t,
    fields,
    field_list,
    unitPlanFields,
    unitSummaryModules,
    organizationId,
    showEmptyScreen = false,
    emptyScreen,
  } = props;

  return showEmptyScreen
    ? emptyScreen
    : _.map(fields, fieldKey => {
        const field = field_list[fieldKey] ? field_list[fieldKey] : {};

        let {
          type,
          viewType,
          subFields,
          config: { viewLabel, label, styles, options } = {},
        } = field;

        viewType = viewType ?? type;

        // Providing Styling
        inputStyle.templateStyle = {
          ..._.get(styles, "style", {}),
          ..._.get(styles, "viewStyle", {}),
        };

        //extraction IDs from the Unit Data
        const unitPlanFieldValue = _.get(
          _.find(unitPlanFields, { uid: fieldKey }),
          "value",
          ""
        );

        //field data
        const resolvedValue =
          _.get(
            _.find(unitPlanFields, { uid: fieldKey }),
            "resolvedMinimalTree",
            ""
          ) || {};
        const subjectIds = _.get(
          _.find(unitPlanFields, { uid: "subjects" }),
          "value",
          ""
        );

        const subjectGroup = _.first(
          _.get(
            _.find(unitPlanFields, { uid: "subjectGroup" }),
            "resolvedMinimalTree.subjectGroups",
            []
          )
        );

        const value = getElementValue({
          fieldUID: fieldKey, // field key
          valueKeys: unitPlanFieldValue, // value key
          resolvedValue: resolvedValue, // value's resolved value
          isUnitPlan: true,
          subjectIds: subjectIds, // subjects IDs
          fieldObj: field,
          shouldStripRichTextStyles: true,
          t,
        });

        const fieldTypeConfig = getFieldTypeConfig({ viewType });
        //Displaying Label & value pairs using FieldComponent
        if (!_.isEmpty(value)) {
          return (
            <div
              key={fieldKey}
              className={classes.fieldContainer}
              style={{
                ...(unitSummaryModules
                  ? {}
                  : backwardCompatibilityStyles.fieldContainer),
              }}
            >
              <Unveil maxHeight={240} showCollapse={true}>
                <div className={classes.item} style={inputStyle.templateStyle}>
                  <FieldComponent
                    defaultOptions={getPlannerFieldOptions({
                      fieldKey: fieldKey,
                      defaultOptions: options,
                      field_list,
                      unitPlanFields,
                      organizationId,
                      t,
                    })}
                    subFields={getSubFieldsWithStyles(subFields)}
                    contentStyle={inputStyle.fieldStyle}
                    type={viewType}
                    mode={"view"}
                    {...fieldTypeConfig}
                    labelComponent={
                      <LabelComponent
                        label={viewLabel || label}
                        labelStyle={inputStyle.labelStyle}
                      />
                    }
                    value={value}
                    fieldKey={fieldKey}
                    subjectGroup={subjectGroup}
                    nodes={resolvedValue.nodes}
                    resolvedValue={resolvedValue}
                  />
                </div>
              </Unveil>
            </div>
          );
        } else {
          return null;
        }
      });
});

// const Header = React.memo(props => {
//   const { unitPlanFields, onClose, t, hideCloseButton } = props;
//   const image =
//     _.get(_.find(unitPlanFields, { uid: "image" }), "value", "") ||
//     RESOURCE_PLACEHOLDER_URL;
//   const title =
//     _.get(_.find(unitPlanFields, { uid: "title" }), "value", "") || `Untitled`;
//   const themeKey = _.get(_.find(unitPlanFields, { uid: "theme" }), "value", "");
//   const subjectKey = _.get(
//     _.find(unitPlanFields, { uid: "subjects" }),
//     "value",
//     ""
//   );

//   const themeOrSubject = !_.isEmpty(themeKey)
//     ? getPypElementLabel({
//         value: themeKey,
//         type: "THEME"
//       })
//     : getSubjectLabel(subjectKey);

//   return (
//     <div className={classes.header}>
//       <div
//         className={classes.image}
//         style={{
//           backgroundImage: getRethumb(image),
//           backgroundColor: !image ? colors.strokeOne : "unset"
//         }}
//       ></div>
//       <div className={classes.headerDetails}>
//         <div className={classes.heading}>{t("unitPlan:unit_summary")}</div>
//         <div className={classes.title}>{`${title}`}</div>
//         <div className={classes.subTitle}>
//           {_.isArray(themeOrSubject) ? (
//             <MoreItemWithToolTip
//               items={themeOrSubject}
//               length={1}
//               noItemText={t("common:no_with_label", {
//                 label: t("common:subject_plural")
//               })}
//             />
//           ) : (
//             themeOrSubject
//           )}
//         </div>
//       </div>
//       {!hideCloseButton && (
//         <div className={classes.closeIcon} onClick={onClose}>
//           <CancelIcon width={12} height={12} />
//         </div>
//       )}
//     </div>
//   );
// });

const HeaderV2 = React.memo(function HeaderV2(props) {
  const {
    onClose,
    t,
    hideCloseButton,
    unitSummaryModules,
    headerTitle,
  } = props;
  return (
    <div
      className={classes.headerV2}
      style={{
        ...(unitSummaryModules ? {} : backwardCompatibilityStyles.header),
      }}
    >
      <div className={classes.headerDetailsV2}>
        <div className={classes.headingV2}>
          {headerTitle || t("common:planner_overview")}
        </div>
      </div>
      {!hideCloseButton && (
        <div className={classes.closeIcon} onClick={onClose}>
          <IconButton
            icon={<CloseOutlined size={"xx-small"} variant={"subtle"} />}
            size={"medium"}
            variant={"plain"}
          />
        </div>
      )}
    </div>
  );
});

const updateTemplateMemoize = _.memoize(
  ({
    template,
    unitType,
    curriculumType,
    resolvedSubjectNodes,
    t,
    localisedTemplateId,
    localisedTemplateType,
    plannerElementSets,
  }) =>
    updateTemplate({
      t,
      template,
      unitType,
      curriculumType,
      resolvedSubjectNodes,
      localisedTemplateId,
      localisedTemplateType,
      plannerElementSets,
    }),
  params => JSON.stringify(params)
);

UnitSummary.defaultProps = {
  unitSummaryFields: UNIT_SUMMARY_FIELDS,
};

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );
  return {
    isData: true,
    isLoading: false,
    organizationId: state.login.userInfo.org_id,
    plannerElementSets,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({
    resource: ["unitPlanTemplate", "unitPlan", "common", "projectTemplate"],
  }),
  graphql(getUnitSummaryPlanQuery, {
    name: "getUnitSummaryPlan",
    options: ({ unitPlanId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: unitPlanId },
    }),
    props({
      getUnitSummaryPlan,
      ownProps: { isData, isLoading, unitPlanId, t, plannerElementSets },
    }) {
      const unitPlanDetails = getUnitSummaryPlanDetailsFromCache(unitPlanId);

      // const unitPlanDetails = _.get(getUnitSummaryPlan, "node", {});
      const unitPlanFields = _.get(unitPlanDetails, "allFields", "");

      const unitType = _.get(unitPlanDetails, "unitType.value", "");

      const template = _.get(unitPlanDetails, "template", {});
      const isLocalisedTemplate = _.get(
        template,
        "body.isLocalisedTemplate",
        true
      );
      const localisedTemplateId = _.get(
        template,
        "body.localisedTemplateId",
        ""
      );
      const localisedTemplateType = _.get(
        template,
        "body.localisedTemplateType",
        "unitPlan"
      );
      const curriculumType = _.get(unitPlanDetails, "curriculumProgram.type");
      const resolvedSubjectNodes = _.get(
        _.find(unitPlanFields, { uid: "subjects" }),
        "resolvedMinimalTree.subjects",
        []
      );

      const unitPlanTemplate = _.get(
        updateTemplateMemoize({
          t,
          template,
          unitType,
          curriculumType,
          resolvedSubjectNodes,
          plannerElementSets,
        }),
        "body",
        {}
      );

      return {
        template_id: _.get(unitPlanDetails, "templateId", null),
        localisedTemplateId,
        localisedTemplateType,
        unitPlanDetails,
        curriculumType,
        unitPlanFields,
        unitPlanTemplate,
        isLocalisedTemplate,
        isData: !_.isEmpty(unitPlanDetails) && isData,
        isLoading:
          getUnitSummaryPlan["networkStatus"] == 1 ||
          getUnitSummaryPlan["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(UnitSummary);

export { UnitSummary };

//5633
