import React, { Fragment } from "react";
import { SearchBarWithTag, TagInput, ButtonDropdown } from "UIComponents";
import classes from "./SearchBox.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
// import { colors, fontStyle } from "Constants";

class SearchBox extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchText: "", // does not include comma & whitespace
      searchTextOriginal: "",
      cachedOptions: [],
      selectedTags: [],
      filteredOptions: [],
      showAddNewItem: false,
    };

    this.searchBoxRef = React.createRef();
    this.searchBarWithTagRef = React.createRef();
    this.buttonDropdownRef = React.createRef();
  }

  componentDidMount() {
    this.filterOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { options, value, showInDropdown } = this.props;
    if (
      prevProps.value !== value ||
      prevProps.options !== options ||
      _.get(prevProps, "options.length") !== _.get(options, "length")
    )
      this.filterOptions();

    if (
      !showInDropdown &&
      !prevState.showAddNewItem &&
      this.state.showAddNewItem
    ) {
      document.addEventListener("click", this.handleDocumentMouseClick, false);
    }
  }

  componentWillUnmount = () => {
    this.removeDocumentClickListener();
  };

  handleDocumentMouseClick = event => {
    if (!this.searchBoxRef) return;
    const { showAddNewItem } = this.state;
    if (showAddNewItem) this.setState({ showAddNewItem: false });
    event.stopPropagation();
  };

  removeDocumentClickListener = () => {
    document.removeEventListener("click", this.handleDocumentMouseClick, false);
  };

  isValidValue = value => !_.isEmpty(value);

  removeOption = item => {
    const { onRemoveOption, options } = this.props;
    const updatedValues = _.filter(this.props.value, id => id != item.value);
    this.updateValue(updatedValues, options);

    if (onRemoveOption) onRemoveOption(item);
  };

  filterOptions = () => {
    const { options, value } = this.props;
    const { searchText = "", cachedOptions } = this.state;
    const mergedOptions = _.uniqBy([...cachedOptions, ...options], "value");

    const selectedTags = [];
    let filteredOptions = [...mergedOptions];

    _.forEach(value, id => {
      filteredOptions = _.filter(filteredOptions, option => {
        if (option.value === id) {
          selectedTags.push(option);
          return false;
        }
        return true;
      });
    });

    if (searchText)
      filteredOptions = _.filter(filteredOptions, item =>
        _.includes(_.toLower(item.label), _.toLower(searchText))
      );

    this.setState(
      {
        selectedTags,
        filteredOptions,
      },
      () => this.shouldShowAddNewOption()
    );
  };

  clearSearchText = () => {
    if (this.searchBarWithTagRef.current)
      this.searchBarWithTagRef.current.clearSearchBar();
  };

  updateCache = newValues => {
    const { options } = this.props;
    const cachedOptions = _.filter(options, option =>
      _.includes(newValues, option.value)
    );
    this.setState({ cachedOptions });
    this.clearSearchText();
  };

  handleTagSelect = params => {
    const { name, value, options } = this.props;
    const newValues = _.uniq([...(value || []), ...(params[name] || [])]);
    this.updateValue(newValues, options);

    this.updateCache(newValues);
  };

  handleListSelect = newValue => {
    const { value, options } = this.props;
    const newValues = _.uniq([...(value || []), newValue]);
    this.updateValue(newValues, options);

    this.updateCache(newValues);
  };

  onFocusInputField = () => {
    const { onFocusInputField } = this.props;
    if (onFocusInputField) onFocusInputField();
  };

  onAddLabel = async () => {
    const { searchText, cachedOptions } = this.state;
    const { onAddLabel } = this.props;

    if (!searchText) return;
    const newTag = await onAddLabel(searchText);
    this.clearSearchText();
    this.setState({
      searchText: "",
      searchTextOriginal: "",
      showAddNewItem: false,
    });

    if (newTag) {
      this.setState(
        {
          cachedOptions: [...cachedOptions, newTag],
        },
        () => this.filterOptions()
      );
    }
  };

  onSearchChange = async value => {
    const { onSearchChange } = this.props;
    if (onSearchChange) await onSearchChange(value.trim() || undefined);
    const searchTextCleaned = value.replace(/[,]/g, "").trim();
    this.setState({ searchText: searchTextCleaned, searchTextOriginal: value });
    this.filterOptions();
  };

  shouldShowAddNewOption = () => {
    const { allowAddNewOption } = this.props;
    const {
      searchText = "",
      filteredOptions = [],
      selectedTags = [],
    } = this.state;

    const selectedTagsContainSearch = _.find(selectedTags, tag =>
      _.includes(_.toLower(tag.label), _.toLower(searchText))
    );

    const showAddNewItem =
      allowAddNewOption &&
      searchText.length &&
      !filteredOptions.length &&
      !selectedTagsContainSearch;

    this.setState({ showAddNewItem });
  };

  searchComponent = () => {
    const {
      placeholder,
      removeOnBackspace,
      styles = {},
      showSearchIcon,
      inlineView,
      showInDropdown,
      addOnComma,
      showSearchIconInStart,
    } = this.props;

    const {
      selectedTags,
      showAddNewItem,
      error,
      searchTextOriginal,
    } = this.state;

    const searchBarWithTagStyles = {
      tag: styles.tag || {},
      ...(styles || {}),
    };

    return (
      <div className={classes.searchContainer}>
        <SearchBarWithTag
          removeOnBackspace={removeOnBackspace}
          addOnComma={addOnComma}
          removeOption={this.removeOption}
          ref={this.searchBarWithTagRef}
          styles={searchBarWithTagStyles}
          placeholder={placeholder}
          value={selectedTags}
          searchText={searchTextOriginal}
          onSearchChange={this.onSearchChange}
          showSearchIcon={showSearchIcon || !inlineView}
          inlineView={inlineView}
          showAddNewItem={!showInDropdown && showAddNewItem}
          onAddLabel={this.onAddLabel}
          error={error}
          showSearchIconInStart={showSearchIconInStart}
          onFocusInputField={this.onFocusInputField}
        />
      </div>
    );
  };

  tagListResult = () => {
    const {
      value,
      name,
      tagListLabel,
      styles = {},
      onAddOption,
      showInDropdown,
    } = this.props;
    const { filteredOptions, searchText, showAddNewItem } = this.state;
    return (
      <Fragment>
        {filteredOptions.length ? (
          <div
            className={classes.tagList}
            style={{ padding: showInDropdown ? "16px" : "16px 0" }}
          >
            <TagInput
              label={tagListLabel}
              containerStyle={styles.tagList}
              styles={styles.tag}
              multiSelect={true}
              options={filteredOptions}
              value={value}
              name={name}
              updateInputField={this.handleTagSelect}
              handleClick={onAddOption}
            />
          </div>
        ) : showInDropdown && showAddNewItem ? (
          <div
            className={classes.addNewLabel}
            onClick={this.onAddLabel}
          >{`Add "${searchText}"`}</div>
        ) : searchText ? (
          <div
            style={{ padding: showInDropdown ? "16px" : "16px 0" }}
            className={classes.noResultContainer}
          >
            No results found.
          </div>
        ) : null}
      </Fragment>
    );
  };

  lineListResult = () => {
    const { filteredOptions, searchText, showAddNewItem } = this.state;
    return (
      <div className={classes.dropdownList}>
        {filteredOptions.length ? (
          _.map(filteredOptions, option => {
            const { label, value } = option;
            return (
              <div
                className={classes.dropdownListItem}
                onClick={e => {
                  e.preventDefault();
                  this.handleListSelect(value);
                }}
                key={value}
              >
                {label}
              </div>
            );
          })
        ) : showAddNewItem ? (
          <div
            className={classes.addNewLabel}
            onClick={this.onAddLabel}
          >{`Add "${searchText}"`}</div>
        ) : (
          <div
            style={{ padding: "16px" }}
            className={classes.noResultContainer}
          >
            No more results.
          </div>
        )}
      </div>
    );
  };

  resultComponent = () => {
    const { resultType, hideDropdownWhenSearchIsEmpty } = this.props;
    const { searchText } = this.state;
    if (hideDropdownWhenSearchIsEmpty && !searchText) return null;

    return (
      <div className={classes.resultContainer}>
        {resultType === "TAGS" ? this.tagListResult() : this.lineListResult()}
      </div>
    );
  };

  renderEdit = () => {
    const { showInDropdown, hideDropdownWhenSearchIsEmpty } = this.props;
    const { searchText } = this.state;

    const buttonDropdown = {
      button: { width: "100%" },
      dropdown: {
        width: "100%",
        padding: "0",
        position: "absolute",
        top: "calc(100% + 4px)",
        zIndex: 13,
        visibility:
          hideDropdownWhenSearchIsEmpty && !searchText ? "hidden" : "visible",
      },
    };

    return (
      <div className={classes.container} ref={this.searchBoxRef}>
        {showInDropdown ? (
          <ButtonDropdown
            shouldCloseOnButtonClick={false}
            shouldCloseOnSelfClick={false}
            ref={this.buttonDropdownRef}
            buttonParentStyle={buttonDropdown.button}
            containerStyle={{
              ...buttonDropdown.dropdown,
            }}
            buttonComponent={this.searchComponent()}
            dropdownComponent={this.resultComponent()}
            keepDropdownOpenOnMultiSelect={true}
          />
        ) : (
          <Fragment>
            {this.searchComponent()}
            {this.resultComponent()}
          </Fragment>
        )}
      </div>
    );
  };
}

SearchBox.propTypes = {
  ...UIBaseComponent.propTypes,
};

SearchBox.defaultProps = {
  ...UIBaseComponent.defaultProps,
  resultSize: 10,
  noResultText: "",
  allowAddNewOption: false,
  showInDropdown: false,
  showSearchIcon: false,
  inlineView: false,
  resultType: "TAGS",
  addOnComma: false,
  showSearchIconInStart: false,
  hideDropdownWhenSearchIsEmpty: false,
};

export default SearchBox;
