import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classes from "./BenchmarksModal.scss";

import { CommentBox } from "AppComponents";

import classNames from "classnames";

import HelperPane from "IBPlanner/routes/PlannerStep/components/HelperPane";

import MultiLevelSelector from "./MultiLevelSelector";
import Levels from "MultiLevelNodeEditor/routes/Levels/components";
import { I18nHOC } from "UIComponents";

class BenchmarksModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showHelper: false,
      showSidebar: false,
      expandedObjective: {},
      currentRightContent: null,
    };
  }

  onHelpClicked = () => {
    this.setState({ showHelper: !this.state.showHelper });
  };

  onCloseHelper = () => {
    this.setState({ showHelper: !this.state.showHelper });
  };

  onExpandClicked = ({ expanded, data }) => {
    const state = { ...this.state };
    const expandedObjective = {};
    if (!expanded) {
      expandedObjective[data.id] = true;
      state.expandedObjective = expandedObjective;
    } else {
      state.expandedObjective = expandedObjective;
    }
    this.setState(state);
  };

  onSubmitClicked = ({
    selections,
    nodes,
    rootNodes,
    valuesToAdd,
    valuesToRemove,
    shouldCloseModal,
  }) => {
    this.props.onSubmitClicked({
      id: this.props.id,
      selections,
      nodes,
      rootNodes,
      valuesToAdd,
      valuesToRemove,
      shouldCloseModal,
    });
    this.onCloseComment();
  };

  onCancelClicked = () => {
    this.props.onCancelClicked();
    this.onCloseComment();
  };

  renderHelperPane = () => {
    if (this.state.showHelper) {
      return (
        <HelperPane
          helperText={this.props.helperText}
          onClose={this.onCloseHelper}
          onHelpClicked={this.onHelpClicked}
        />
      );
    }
    return null;
  };

  onCloseComment = () => {
    this.setState({ showSidebar: false });
  };

  onCommentClick = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
      currentRightContent: "comment",
    });
  };

  renderSidebar = () => {
    const {
      state: { showSidebar, currentRightContent },
    } = this;
    const { fieldId } = this.props;
    let rightPaneStyle = classNames(
      { [classes.modalSidebar]: true },
      { [classes.smallSideBar]: !showSidebar },
      { [classes.fullSideBar]: showSidebar }
    );
    return (
      <div className={rightPaneStyle}>
        {currentRightContent === "comment" ? (
          <CommentBox
            parentType={"FIELD"}
            nodeId={fieldId}
            type={"PLANNER_FIELD"}
            onClose={this.onCloseComment}
          />
        ) : null}
      </div>
    );
  };

  render() {
    const {
      props: {
        subjectId,
        levels,
        selectedNodes,
        subText,
        helperText,
        value,
        subject: { label },
        modalState,
        totalCommentCount,
        unreadCommentCount,
        rootNode,
        grades,
        updateValueLocally,
        t,
      },
      state: { showSidebar },
    } = this;

    let modalContainerStyle = classNames({
      [classes.modalContentSmall]: showSidebar,
      [classes.modleContentFull]: !showSidebar,
    });

    return (
      <div className={classes.modalContainer}>
        <div className={modalContainerStyle}>
          <Levels
            subjectId={subjectId}
            label={t("unitPlanTemplate:unitPlan_benchmarks_label")}
            levels={levels}
            updateValueLocally={updateValueLocally}
            value={value}
            totalCommentCount={totalCommentCount}
            unreadCommentCount={unreadCommentCount}
            onExpandClicked={this.onExpandClicked}
            subText={subText}
            helperText={helperText}
            modalState={modalState}
            emptyText={t("unitPlan:add_benchmarks_with_label", {
              label: label,
            })}
            showOutline={true}
            showHelper={this.props.showHelper}
            showComment={this.props.showSidebar}
            onHelpClick={this.onHelpClicked}
            onCommentClick={this.onCommentClick}
            isCommentActive={this.state.showSidebar}
            isTagging={true}
            rootNode={rootNode}
            mode={"view"}
            nodeType="BENCHMARK"
            valueType={"benchmarks"}
            onBackClick={this.onCancelClicked}
            onSubmitClick={this.onSubmitClicked}
            grades={grades}
            selectedNodes={selectedNodes}
            selectedTaggedIds={_.map(selectedNodes, ({ id }) => id)}
            openedFrom={"UNIT_PLAN"}
          />
        </div>
        {this.renderSidebar()}
      </div>
    );
  }
}

BenchmarksModal.propTypes = {
  data: PropTypes.object,
  subject: PropTypes.object,
};

BenchmarksModal.defaultProps = {
  template_id: [1],
  value: [],
};

export default I18nHOC({ resource: ["unitPlanTemplate", "unitPlan"] })(
  BenchmarksModal
);
