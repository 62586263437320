import React from "react";
// import substyle from "substyle";

function LoadingIndicator() {
  return (
    <div>
      <div>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default LoadingIndicator;
