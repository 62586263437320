import React, { createRef } from "react";
import classes from "./CreateDropDown.scss";
import {
  AddCircleIcon,
  GoogleDriveIcon,
  ToddleIcon,
  ResourcesLineIcon,
  UploadIconV2,
  ImageIcon,
  LinkIconV2,
  GoogleDriveIconV2,
  OneDriveIcon,
  NoteIcon,
  CancelIcon,
} from "SvgComponents";
import {
  WorkbookOutlined,
  LinkOutlined,
  DeviceDesktopOutlined,
  WorkbookColored,
  GoogleDriveColored,
  OneDriveColored,
  NoteOutlined,
  PortfolioOutlined,
} from "@toddle-design/web-icons";
import { CreatePost } from "Post";
import { colors } from "Constants";
import {
  DropzoneComponent,
  LinkModal,
  GooglePicker,
  OneDrivePicker,
  ButtonDropdown,
  I18nHOC,
  AddNoteDialogue,
  UIModal,
} from "UIComponents";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "react-apollo";
import { setToastMsg } from "Login/modules/LoginModule";
import ACLStore from "lib/aclStore";
import classNames from "classnames";
import { Workbook } from "AppComponents";
import {
  WORKBOOK_MODE_TYPES,
  canEditPdfInPdftron,
  shouldConverPdfToWorkbook,
  getAttachmentSendObj,
} from "Utils";
import {
  DropdownMenuItem,
  DropdownMenuItemGroup,
  Dropdown,
  DropdownMenu,
  DropdownMenuDivider,
  DropdownSubMenu,
  Tooltip,
  IconButton,
  CameraOutlined,
  VideoOutlined,
} from "@toddle-design/web";
import CaptureImage from "Post/routes/CreatePost/components/Screens/CaptureImage";
import CaptureVideo from "Post/routes/CreatePost/components/Screens/CaptureVideo";
import { Button } from "@toddle-design/web";
import { ICONS } from "Constants";

const DROP_DOWN_OPTIONS = [
  { key: "upload_device", label: "upload_device" },
  { key: "add_link", label: "add_link" },
  { key: "add_google_drive", label: "add_google_drive" },
  { key: "add_one_drive", label: "add_one_drive" },
];

const LINK = "add_link";
const UPLOAD_FROM_DEVICE = "upload_device";
const NOTE = "add_note";
const RESOURCE = "select_resource";
const WORKBOOK = "create_template_multipage";
const ONE_DRIVE = "add_one_drive";
const GOOGLE_DRIVE = "add_google_drive";

const OPTIONS = [
  {
    key: UPLOAD_FROM_DEVICE,
    label: "common:upload_device",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<DeviceDesktopOutlined size={"x-small"} variant={"subtle"} />}
      />
    ),
  },
  {
    key: LINK,
    label: "common:add_link",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<LinkOutlined size={"x-small"} variant={"subtle"} />}
      />
    ),
  },
  {
    key: NOTE,
    label: "common:add_note",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<NoteOutlined size={"x-small"} variant={"subtle"} />}
      />
    ),
  },
  {
    key: RESOURCE,
    label: "common:select_unit_resources",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<PortfolioOutlined size={"x-small"} variant={"subtle"} />}
      />
    ),
  },
  {
    key: WORKBOOK,
    label: "create_template_multipage",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<WorkbookColored size={"x-small"} />}
      />
    ),
  },
  {
    key: GOOGLE_DRIVE,
    label: "common:add_google_drive",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<GoogleDriveColored size={"x-small"} />}
      />
    ),
  },
  {
    key: ONE_DRIVE,
    label: "common:add_one_drive",
    icon: (
      <IconButton
        variant={"outlined-subtle"}
        icon={<OneDriveColored size={"x-small"} />}
      />
    ),
  },
  { key: "upload_from_unit_plan", label: "upload_from_unit_plan" },
];

const buttonDropDownStyle = {
  containerStyle: {
    top: 0,
    width: "max-content",
    borderWidth: 0,
    left: 0,
    padding: 0,
    zIndex: 12,
  },
  buttonParentStyle: {
    width: "100%",
  },
};

const dropzoneStyle = {
  inset: "0",
  overflowX: "hidden",
  alignItems: "flex-start",
};

const initialShowCaptureScreen = {
  capture_image: false,
  capture_video: false,
};

const captureOptions = ["capture_image", "capture_video"];

class CreateDropDown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLinkModal: false,
      uploadCount: 0,
      showResourceBank: false,
      showMediaCreation: false,
      showNoteModal: false,
      showWorkbookModal: false,
      showCaptureScreen: initialShowCaptureScreen,
      showDescriptionModal: false,
    };

    if (props.customRef) {
      props.customRef(this);
    }
    if (props.createDropDownRef) {
      props.createDropDownRef(this);
    }
    this.customRef = null;
    this.dropzoneRef = createRef();
    this.googlePickerRef = createRef();
    this.realClickRef = createRef();
    this.dropdownComponentRef = createRef();

    this.realClickRef.current = true;
    this.innerDropzoneRef = createRef();
  }

  isActive = () => {
    const {
      showNoteModal,
      showLinkModal,
      showResourceBank,
      showMediaCreation,
    } = this.state;

    return (
      showNoteModal || showLinkModal || showMediaCreation || showResourceBank
    );
  };

  toggleMediaCreation = value => {
    this.setState({ showMediaCreation: value });
  };

  toggleLinkModal = () => {
    this.setState({ showLinkModal: !this.state.showLinkModal });
  };

  toddleAddNoteDialogue = () => {
    this.setState({ showNoteModal: !this.state.showNoteModal });
  };

  onLinkAdd = async ({ attachments, id }) => {
    const {
      addAttachment,
      parentId,
      parentType,
      onMultipleFilesUploaded,
    } = this.props;
    const parentDetails = {
      parentId,
      parentType,
    };

    if (addAttachment) addAttachment(attachments, id, parentDetails);
    if (onMultipleFilesUploaded) onMultipleFilesUploaded(attachments);

    if (this.state.uploadCount > 0) {
      this.updateFilesCount(-1);
    }
  };

  saveNote = params => {
    const { addAttachment, parentId, parentType } = this.props;
    const parentDetails = {
      parentId,
      parentType,
    };

    addAttachment(
      [{ type: "NOTE", title: _.get(params, "note", "") }],
      null,
      parentDetails
    );
  };

  onContentUploaded = (params, uploadId, parentDetails) => {
    const {
      addAttachment,
      inputTextFocusOnDrop,
      sourceType = "",
      curriculumProgramType = "",
    } = this.props;
    inputTextFocusOnDrop ? inputTextFocusOnDrop() : null;

    //override attachment type of pdf to workbook for Uploading UI
    if (
      sourceType === "STUDENT_TEMPLATE" &&
      shouldConverPdfToWorkbook({ curriculumProgramType }) &&
      _.get(params, "type", "") === "FILE" &&
      _.get(params, "mimeType", "") === "application/pdf"
    ) {
      params.type = "WORKBOOK";
    }
    if (addAttachment) addAttachment([params], uploadId, parentDetails);
  };

  updateFilesCount = count => {
    let newCount = this.state.uploadCount;
    newCount = newCount + count;

    if (this.props.updateLoadingState) {
      if (newCount > 0) {
        this.props.updateLoadingState(true);
      } else {
        this.props.updateLoadingState(false);
      }
    }
    this.setState({ uploadCount: newCount });
  };

  onFailedAllowedMimeType = () => {
    this.props.setToastMsg("toastMsgs:unsupported_extensions");
  };

  onOneDriveClick = () => {
    if (this.oneDrivePickerRef) {
      this.oneDrivePickerRef.onLoginClick();
    }
  };

  // This function will show popup if showDescription passed as true, on option click of create dropdownMenu.
  // Added only for upload_device, add_google_drive and add_one_drive
  // Eg : If we click on upload_device option, a popup will come before filePicker and onClick "Okay" of popup modal filePicker will be open.

  onFilePickerOptionClick = (e, callBack) => {
    const { showDescription } = this.props;
    if (showDescription) {
      if (this.realClickRef.current) {
        this.dropdownComponentRef.current = e.target;
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          showDescriptionModal: true,
        });

        this.realClickRef.current = false;
      } else {
        this.realClickRef.current = true;
        if (callBack) {
          callBack();
        }
      }
    } else {
      if (callBack) {
        callBack();
      }
    }
  };

  onZoomMeetingClick = () => {
    if (this.zoomMeetingRef) {
      this.zoomMeetingRef.onLoginClick();
    }
  };

  onMicroSoftLoginCallBack = data => {};

  updateOneDriveRef = ref => {
    this.oneDrivePickerRef = ref;
  };

  updateZoomMeetingRef = ref => {
    this.zoomMeetingRef = ref;
  };

  closeResourceBank = () => {
    this.setState({ showResourceBank: false });
  };

  toggleOpenWorkbook = showWorkbookModal => {
    this.setState({
      showWorkbookModal,
    });
  };

  onWorkbookSuccessfullyUpdate = attachment => {
    const { shouldCallMutation } = this.props;
    const attachmentId = _.get(attachment, "id", null);
    if (!attachment || !attachmentId) return;
    let omitKeys = ["parentType", "title", "streamUrl", "isRead"];
    if (shouldCallMutation) {
      omitKeys = [...omitKeys, ...["id"]];
    }
    const formattedAttachment = {
      ..._.omit(attachment, omitKeys),
    };

    this.onContentUploaded(formattedAttachment, attachmentId);
  };

  closeWorkBook = () => {
    this.toggleOpenWorkbook(false);
  };

  toggleCaptureScreen = params => {
    const { showCaptureScreen } = this.state;
    if (params) {
      const { key } = params;
      const showCaptureScreenNew = { ...showCaptureScreen, [key]: true };
      this.setState({ showCaptureScreen: showCaptureScreenNew });
    } else {
      this.setState({ showCaptureScreen: initialShowCaptureScreen });
    }
  };

  updatePost = ({ attachments, uploadId }) => {
    const { addAttachment } = this.props;
    if (!_.isEmpty(attachments)) {
      // removing file key in the object as its breaking the mutation
      const attachmentArray = _.map(attachments, attachment => {
        return _.omit(attachment, ["file", "isRead"]);
      });
      if (addAttachment) addAttachment(attachmentArray, uploadId);
      this.toggleCaptureScreen();
    }
  };

  onClickDropdownOption = ({ key }) => {
    const { onDropDownOptionClick } = this.props;
    const isCaptureOption = _.includes(captureOptions, key);

    if (key === "add_link") {
      this.setState({ showLinkModal: true });
    } else if (key === "add_one_drive") {
      this.onOneDriveClick();
    } else if (key === "create_template_multipage") {
      this.setState({ showWorkbookModal: true });
    } else if (key === "add_note") {
      this.toddleAddNoteDialogue();
    } else if (key === "upload_device") {
      // clicking the dropzone component programatically
      if (this.innerDropzoneRef?.current?.click)
        this.innerDropzoneRef.current.click();
    } else if (key === "add_google_drive") {
      if (this.googlePickerRef && this.googlePickerRef.onAuthClick) {
        this.googlePickerRef.onAuthClick();
      }
    } else if (isCaptureOption) {
      this.toggleCaptureScreen({ key });
    } else {
      onDropDownOptionClick({ key });
    }
  };

  setInnerGooglePickerRef = ref => {
    this.googlePickerRef = ref;
  };

  customizedFileObject = async (file, item) => {
    let sendObj = await getAttachmentSendObj(file, item);

    //override attachment type of pdf to workbook for Uploading UI
    const { sourceType = "", curriculumProgramType = "" } = this.props;
    if (
      sourceType === "STUDENT_TEMPLATE" &&
      shouldConverPdfToWorkbook({ curriculumProgramType })
    ) {
      if (
        _.get(sendObj, "type", "") === "FILE" &&
        _.get(sendObj, "mimeType", "") === "application/pdf"
      ) {
        return {
          ...sendObj,
          type: "WORKBOOK",
          name: "Workbook",
        };
      }
    }

    return sendObj;
  };

  getDropdownItemByKey = (
    {
      key,
      accept: acceptTypes,
      label,
      icon = null,
      type = null,
      isLabelLocalized = false,
      isDialogueBoxEnabled = false,
      dialogueBoxInfo = null,
    },
    index
  ) => {
    const {
      isShowIconsInBar,
      portalType,
      multiselect,
      t,
      parentId,
      parentType,
      accept,
      exportAsPdf,
      sourceType,
      enableShareModal,
      attachments,
      isNewDropdown,
      defaultExportType,
      dropzoneClass,
      onMultipleFilesUploaded,
      attachmentExportOptions,
      curriculumProgramType,
      showV2Icons,
      customDropZoneStyle,
    } = this.props;

    /**
     * Temporary condition to allow pdf files upload as student template files
     * Once we are in condition to open pdftron for all school, we will move this config in template only
     */
    if (
      sourceType === "STUDENT_TEMPLATE" &&
      (canEditPdfInPdftron({ curriculumProgramType }) ||
        shouldConverPdfToWorkbook({ curriculumProgramType }))
    ) {
      acceptTypes?.push("application/pdf");

      // overriding label as now it will accept pdf also and not just images.
      if (key === "upload_device") {
        label = "upload_device";
      }
    }

    const isCaptureOption = _.includes(captureOptions, key);

    const captureOptionIcon =
      key == "capture_image" ? (
        <CameraOutlined variant={"subtle"} />
      ) : (
        <VideoOutlined variant={"subtle"} />
      );

    const iconClasses = classNames({
      [classes.icon]: !isNewDropdown,
      [classes.iconButtonContainer]: showV2Icons,
    });

    const hoverItemContainerClasses = classNames({
      [classes.hoverItemContainer]: !isNewDropdown,
      [classes.iconButtonContainer]: showV2Icons,
    });

    const hoverItemTextClasses = classNames(
      isNewDropdown ? "flex" : classes.hoverItemText
    );

    const buttonIconAndTextClasses = classNames({
      [classes.buttonIconAndText]: !isNewDropdown,
    });

    const dividerClasses = classNames({ [classes.divider]: !isNewDropdown });

    const googleDriveInnerDivClasses = classNames({
      [classes.googleDriveInnerDiv]: !isNewDropdown,
    });

    const innerIconClasses = classNames({
      [classes.innerIcon]: !isNewDropdown,
    });

    const iconSvgContainerClasses = classNames(
      isNewDropdown ? "flex" : classes.iconSvgContainer
    );

    const getIconButton = key => {
      return _.find(OPTIONS, { key: key })?.icon;
    };

    return key === "add_evidence" ? (
      isShowIconsInBar ? (
        <div
          className={iconClasses}
          key={`${key}-${index}`}
          onClick={() => {
            if (!isNewDropdown) this.onClickDropdownOption({ key });
          }}
        >
          <Tooltip
            tooltip={!isLabelLocalized ? t(label) : label}
            placement="top"
          >
            {!isNewDropdown &&
              (showV2Icons ? (
                getIconButton(key)
              ) : (
                <div className={classes.innerIcon}>
                  <ResourcesLineIcon width={16} height={14} />
                </div>
              ))}
          </Tooltip>
        </div>
      ) : (
        <div
          className={hoverItemContainerClasses}
          key={`${key}-${index}`}
          onClick={() => {
            if (!isNewDropdown) this.onClickDropdownOption({ key });
          }}
        >
          {!isNewDropdown && (
            <div className={classes.innerIcon}>
              {icon ? (
                icon
              ) : (
                <ToddleIcon width={16} height={16} fill={colors.gray48} />
              )}
            </div>
          )}
          <div className={hoverItemTextClasses}>
            {!isLabelLocalized ? t(`${label}`) : label}
          </div>
        </div>
      )
    ) : key === "upload_device" ? (
      <React.Fragment key={`${key}-${index}`}>
        <DropzoneComponent
          // do not remove this ref. This ref is used for the ability to paste a copied image in the chat
          ref={this.dropzoneRef}
          portalType={portalType}
          multiple={multiselect}
          dropzoneClass={dropzoneClass}
          dropZoneStyle={isNewDropdown ? dropzoneStyle : customDropZoneStyle}
          renderComponent={
            isShowIconsInBar ? (
              <div className={iconClasses}>
                <Tooltip
                  tooltip={!isLabelLocalized ? t(label) : label}
                  placement="top"
                >
                  {!isNewDropdown &&
                    (showV2Icons ? (
                      getIconButton(key)
                    ) : (
                      <div className={classes.innerIcon}>
                        <UploadIconV2 height={18} width={18} />
                      </div>
                    ))}
                </Tooltip>
              </div>
            ) : (
              <div
                ref={this.innerDropzoneRef}
                id={`upload_device`}
                className={hoverItemContainerClasses}
                onClick={this.onFilePickerOptionClick}
              >
                <div className={buttonIconAndTextClasses}>
                  {!isNewDropdown && (
                    <div className={classes.innerIcon}>
                      {icon ? icon : <ImageIcon height={16} width={16} />}
                    </div>
                  )}
                  <div className={hoverItemTextClasses}>
                    {!isLabelLocalized
                      ? t(label || "common:upload_device")
                      : label}
                  </div>
                </div>
              </div>
            )
          }
          onSingleContentUploaded={this.onContentUploaded}
          onContentUploaded={onMultipleFilesUploaded}
          updateFilesCount={this.updateFilesCount}
          parentId={parentId}
          parentType={parentType}
          accept={acceptTypes || accept}
          uploadType={type}
          isDialogueBoxEnabled={isDialogueBoxEnabled}
          dialogueBoxInfo={dialogueBoxInfo}
          onDropContent={this.props.onDropContent}
          customizedFileObject={this.customizedFileObject}
        />
      </React.Fragment>
    ) : isCaptureOption ? (
      <div
        className={hoverItemContainerClasses}
        onClick={() => {
          if (!isNewDropdown) this.toggleCaptureScreen({ key });
        }}
        key={`${key}-${index}`}
      >
        {!isNewDropdown && (
          <div className={classes.innerIcon}>
            {icon ? icon : captureOptionIcon}
          </div>
        )}
        <div className={hoverItemTextClasses}>{t(label)}</div>
      </div>
    ) : key === "add_link" ? (
      isShowIconsInBar ? (
        <div
          className={iconClasses}
          onClick={() => {
            if (!isNewDropdown) {
              this.setState({ showLinkModal: true });
            }
          }}
          key={`${key}-${index}`}
        >
          <Tooltip
            tooltip={!isLabelLocalized ? t(label) : label}
            placement="top"
          >
            {!isNewDropdown &&
              (showV2Icons ? (
                getIconButton(key)
              ) : (
                <div className={classes.innerIcon}>
                  <LinkIconV2 height={20} width={20} />
                </div>
              ))}
          </Tooltip>
        </div>
      ) : (
        <div
          className={hoverItemContainerClasses}
          onClick={() => {
            if (!isNewDropdown) {
              this.setState({ showLinkModal: true });
            }
          }}
          key={`${key}-${index}`}
        >
          {!isNewDropdown && (
            <div className={classes.innerIcon}>
              {icon ? (
                icon
              ) : (
                <LinkOutlined size={"xxx-small"} variant={"subtle"} />
              )}
            </div>
          )}
          <div className={hoverItemTextClasses}>{t(label)}</div>
        </div>
      )
    ) : key === "add_google_drive" ? (
      <div
        key={`${key}-${index}`}
        className={!isShowIconsInBar && hoverItemContainerClasses}
      >
        <GooglePicker
          accept={acceptTypes || accept}
          portalType={portalType}
          renderComponent={
            isShowIconsInBar ? (
              <div className={classes.icon}>
                <Tooltip
                  tooltip={!isLabelLocalized ? t(label) : label}
                  placement="top"
                >
                  {!isNewDropdown &&
                    (showV2Icons ? (
                      getIconButton(key)
                    ) : (
                      <div className={innerIconClasses}>
                        <GoogleDriveIconV2 height={18} width={16} />
                      </div>
                    ))}
                </Tooltip>
              </div>
            ) : (
              // <div className={classes.hoverItemContainer}>
              <div
                className={googleDriveInnerDivClasses}
                id={`add_google_drive`}
                onClick={this.onFilePickerOptionClick}
              >
                {!isNewDropdown && (
                  <div className={innerIconClasses}>
                    {icon ? icon : <GoogleDriveIcon />}
                  </div>
                )}
                <div className={hoverItemTextClasses}>{t(label)}</div>
              </div>
              // </div>
            )
          }
          pickerType="GoogleDrive"
          classStyle={iconSvgContainerClasses}
          updateFilesCount={this.updateFilesCount}
          multiselect={multiselect}
          exportAsPdf={exportAsPdf}
          onSingleContentUploaded={this.onContentUploaded}
          onMultipleFilesUploaded={onMultipleFilesUploaded}
          onFailedAllowedMimeType={this.onFailedAllowedMimeType}
          thumbnailSize={"w248-h200"}
          parentId={parentId}
          parentType={parentType}
          sourceType={sourceType}
          enableShareModal={enableShareModal}
          defaultExportType={defaultExportType}
          onDropContent={this.props.onDropContent}
          setInnerGooglePickerRef={this.setInnerGooglePickerRef}
          attachmentExportOptions={attachmentExportOptions}
        />
      </div>
    ) : key === "add_one_drive" ? (
      isShowIconsInBar ? (
        <div
          className={iconClasses}
          id={`add_one_drive`}
          onClick={!isNewDropdown && this.onOneDriveClick}
          key={`${key}-${index}`}
        >
          <Tooltip
            tooltip={!isLabelLocalized ? t(label) : label}
            placement="top"
          >
            {!isNewDropdown &&
              (showV2Icons ? (
                getIconButton(key)
              ) : (
                <div className={classes.innerIcon}>
                  {icon ? icon : <OneDriveIcon height={12} width={18} />}
                </div>
              ))}
          </Tooltip>
        </div>
      ) : (
        <div
          className={hoverItemContainerClasses}
          onClick={e =>
            !isNewDropdown &&
            this.onFilePickerOptionClick(e, this.onOneDriveClick)
          }
          id={`add_one_drive`}
          key={`${key}-${index}`}
        >
          {!isNewDropdown && (
            <div className={classes.innerIcon}>
              {icon ? icon : <OneDriveIcon height={12} width={18} />}
            </div>
          )}
          <div className={hoverItemTextClasses}>{t(label)}</div>
        </div>
      )
    ) : key === "create_template_multipage" ? (
      <div
        className={hoverItemContainerClasses}
        onClick={() => {
          if (!isNewDropdown) {
            this.setState({ showWorkbookModal: true });
          }
        }}
        key={`${key}-${index}`}
      >
        {showV2Icons ? (
          <Tooltip tooltip={t("common:create_workbook")} placement="top">
            {getIconButton(key)}
          </Tooltip>
        ) : (
          <>
            <WorkbookOutlined size="xx-small" variant="subtle" />
            <div className={hoverItemTextClasses}>
              {t("common:create_workbook")}
            </div>
          </>
        )}
      </div>
    ) : key === "add_note" ? (
      isShowIconsInBar ? (
        <div
          className={iconClasses}
          onClick={!isNewDropdown && this.toddleAddNoteDialogue}
          key={`${key}-${index}`}
        >
          <Tooltip
            tooltip={!isLabelLocalized ? t(label) : label}
            placement="top"
          >
            {!isNewDropdown &&
              (showV2Icons ? (
                getIconButton(key)
              ) : (
                <div className={classes.innerIcon}>
                  <NoteIcon height={16} width={16} />
                </div>
              ))}
          </Tooltip>
        </div>
      ) : (
        <div
          className={hoverItemContainerClasses}
          onClick={!isNewDropdown && this.toddleAddNoteDialogue}
          key={`${key}-${index}`}
        >
          {!isNewDropdown && (
            <div className={classes.innerIcon}>
              <NoteIcon fill={colors.gray48} width={16} height={16} />
            </div>
          )}
          <div className={hoverItemTextClasses}>{t(label)}</div>
        </div>
      )
    ) : type === "divider" ? (
      <div className={dividerClasses} key={`${key}-${index}`} />
    ) : isShowIconsInBar ? (
      <div
        className={iconClasses}
        key={`${key}-${index}`}
        onClick={() => {
          if (!isNewDropdown) {
            this.onClickDropdownOption({ key });
          }
        }}
      >
        <Tooltip tooltip={!isLabelLocalized ? t(label) : label} placement="top">
          {!isNewDropdown &&
            (showV2Icons ? (
              getIconButton("select_resource")
            ) : (
              <div className={classes.innerIcon}>
                {icon ? icon : <ToddleIcon width={16} height={16} />}
              </div>
            ))}
        </Tooltip>
      </div>
    ) : (
      <div
        className={hoverItemContainerClasses}
        key={`${key}-${index}`}
        onClick={() => {
          if (!isNewDropdown) {
            this.onClickDropdownOption({ key });
          }
        }}
      >
        {!isNewDropdown &&
          (icon ? (
            icon
          ) : (
            <ToddleIcon width={16} height={16} fill={colors.gray48} />
          ))}
        <div className={hoverItemTextClasses}>
          {!isLabelLocalized ? t(`${label}`) : label}
        </div>
      </div>
    );
  };

  getDropdownStructure = dropdownOptions => {
    const { t } = this.props;
    return (
      <DropdownMenu
        onClick={e => this.onClickDropdownOption(e)}
        className={classes.newDropdownMenu}
      >
        {_.map(dropdownOptions, (dropdownItem, index) => {
          if (dropdownItem.isDivider) {
            return <DropdownMenuDivider />;
          } else if (dropdownItem.isItemGroup) {
            // dropdownItemGroup
            const isLastItemGroup = dropdownOptions.length - 1 === index;
            const options = _.get(dropdownItem, "options", []);
            return (
              <>
                <DropdownMenuItemGroup
                  key={dropdownItem.key}
                  title={t(dropdownItem.label)}
                >
                  {_.map(options, (option, index) => {
                    return (
                      <DropdownMenuItem
                        icon={option.icon}
                        key={option.key}
                        title={
                          !!option.isLabelLocalized
                            ? option.label
                            : t(option.label)
                        }
                      >
                        {this.getDropdownItemByKey(option, index)}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuItemGroup>
                {!isLastItemGroup && <DropdownMenuDivider />}
              </>
            );
          } else if (dropdownItem.isSubMenu) {
            //dropdownSubMenu
            const isLastSubMenu = dropdownOptions.length - 1 === index;
            const options = _.get(dropdownItem, "options", []);
            return (
              <>
                <DropdownSubMenu
                  key={dropdownItem.key}
                  title={t(dropdownItem.label)}
                  icon={dropdownItem.icon}
                >
                  {_.map(options, (option, index) => {
                    return (
                      <DropdownMenuItem
                        icon={option.icon}
                        key={option.key}
                        title={
                          !!option.isLabelLocalized
                            ? option.label
                            : t(option.label)
                        }
                      >
                        {this.getDropdownItemByKey(option, index)}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownSubMenu>
                {!isLastSubMenu && <DropdownMenuDivider />}
              </>
            );
          } else {
            // simple dropdown item
            return (
              <DropdownMenuItem
                icon={dropdownItem.icon}
                key={dropdownItem.key}
                title={
                  !!dropdownItem.isLabelLocalized
                    ? dropdownItem.label
                    : t(dropdownItem.label)
                }
              >
                {this.getDropdownItemByKey(dropdownItem, index)}
              </DropdownMenuItem>
            );
          }
        })}
      </DropdownMenu>
    );
  };

  createDropdownComponent = () => {
    const { createButtonComponentConfig } = this.props;
    const { label, icon = "AddOutlined" } = createButtonComponentConfig;
    const { t } = this.props;
    const Icon = ICONS[icon];

    const buttonLabel = label
      ? t(label)
      : t("common:add_a_with_label", { label: t("common:file") });

    return (
      <Button icon={<Icon />} variant="outlined" size="medium">
        {buttonLabel}
      </Button>
    );
  };

  getButtonComponent = ({ type }) => {
    const {
      containerStyle = {},
      addButtonStyles = {},
      addMediaText,
      t,
    } = this.props;

    switch (type) {
      case "ICON_BUTTON":
        return this.createDropdownComponent();

      default:
        return (
          <div className={classes.container} style={containerStyle}>
            <div className={classes.innerContainer}>
              <AddCircleIcon
                fill={
                  addButtonStyles.addIconColor
                    ? addButtonStyles.addIconColor
                    : colors.gray48
                }
                width={20}
                height={20}
              />
              <div
                className={classes.addMediaText}
                style={addButtonStyles.addTextStyle}
              >
                {addMediaText ? addMediaText : t("common:add_attachments")}
              </div>
            </div>
          </div>
        );
    }
  };

  getDescriptionModal = () => {
    const { descriptionModalConfig = {}, parentType, t } = this.props;

    const {
      descriptionModalHeader = "",
      descriptionModalBody = "",
      DescriptionModalLeftFooter = null,
      onDescriptionModalClickOkay,
    } = descriptionModalConfig;

    const modalBodyStyle = classNames({
      [classes.modalBody]: true,
      ["text-body-1"]: true,
    });

    const onCloseModal = () => {
      this.realClickRef.current = true;

      this.setState({
        showDescriptionModal: false,
      });
    };

    const onClickOkay = () => {
      this.setState({ showDescriptionModal: false });
      this.dropdownComponentRef.current.click();
      if (onDescriptionModalClickOkay) {
        onDescriptionModalClickOkay({ parentType });
      }
    };

    return (
      <UIModal isOpen={true} modalContent={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={"heading-4"}>{descriptionModalHeader}</div>
          <div className={classes.closeIcon}>
            <IconButton
              onClick={onCloseModal}
              icon={<CancelIcon />}
              variant={"plain"}
              size={"xxx-small"}
            />
          </div>
        </div>
        <div className={modalBodyStyle}>{t(descriptionModalBody)}</div>
        <div className={classes.modalFooter}>
          <div className={classes.leftModalFooter}>
            {DescriptionModalLeftFooter}
          </div>

          <div className={classes.rightModalFooter}>
            <Button
              variant={"progressive"}
              size={"large"}
              onClick={onClickOkay}
            >
              {t("common:okay")}
            </Button>
          </div>
        </div>
      </UIModal>
    );
  };

  render() {
    const {
      showMediaCreation,
      showNoteModal,
      showLinkModal,
      showWorkbookModal,
      showCaptureScreen,
      showDescriptionModal,
    } = this.state;
    const {
      exportAsPdf,
      parentId,
      parentType,
      accept,
      buttonComponent,
      buttonDropDownContainerStyle = {},
      containerStyle = {},
      buttonParentStyle = {},
      t,
      dropDownOptions,
      onDropDownOptionClick,
      addMediaText,
      zoomPromptText,
      portalType,
      shouldCreateAsMedia,
      mediaCreationOptions,
      addButtonStyles = {},
      addAttachmentOptions,
      sourceType,
      isShowIconsInBar,
      shouldCallMutation,
      updateAttachments,
      attachments,
      buttonDropdownParentContainerStyle,
      multiselect,
      hoverContainerStyles,
      enableShareModal,
      isNewDropdown,
      disabled,
      defaultExportType,
      dropdownPlacement,
      attachmentExportOptions,
      onMultipleFilesUploaded,
      createButtonComponentConfig,
    } = this.props;

    const filteredDropdownOptions = _.filter(
      dropDownOptions,
      item => !item.perm || ACLStore.can(item.perm)
    );

    const type = _.get(createButtonComponentConfig, "type", "");

    const screenPanelProps = {
      onCloseClick: () => this.toggleCaptureScreen(),
      updatePost: this.updatePost,
      postDetails: { attachments: [] },
    };

    const mapAttachmentOptions = _.map(
      filteredDropdownOptions,
      (dropdownOption, index) => {
        return this.getDropdownItemByKey(dropdownOption, index);
      }
    );

    return (
      <React.Fragment>
        {showWorkbookModal && (
          <Workbook
            workbookMode={WORKBOOK_MODE_TYPES["WORKBOOK_MULTIPAGE_ADD_OR_EDIT"]}
            onUpdateSuccessfully={this.onWorkbookSuccessfullyUpdate}
            onDiscardDirectly={this.closeWorkBook}
            updateAttachments={updateAttachments}
          />
        )}
        {showDescriptionModal && this.getDescriptionModal()}
        {!shouldCreateAsMedia ? (
          isShowIconsInBar ? (
            <div className={classes.iconBarContainer}>
              {mapAttachmentOptions}
            </div>
          ) : isNewDropdown ? (
            <Dropdown
              overlay={this.getDropdownStructure(dropDownOptions)}
              destroyPopupOnHide={false}
              placement={dropdownPlacement}
              disabled={disabled}
            >
              {buttonComponent
                ? buttonComponent
                : this.getButtonComponent({
                    type,
                  })}
            </Dropdown>
          ) : (
            <ButtonDropdown
              containerStyle={{
                ...buttonDropDownStyle.containerStyle,
                ...buttonDropDownContainerStyle,
              }}
              buttonComponent={
                buttonComponent
                  ? buttonComponent
                  : this.getButtonComponent({
                      type,
                    })
              }
              buttonParentStyle={{
                ...buttonDropDownStyle.buttonParentStyle,
                ...buttonParentStyle,
              }}
              dropdownComponent={
                <div
                  className={classes.hoverContainer}
                  style={hoverContainerStyles}
                >
                  {mapAttachmentOptions}
                </div>
              }
              parentContainerStyle={buttonDropdownParentContainerStyle}
              buttonComponentStyle={
                this.props.buttonDropdownButtonComponentStyle
              }
              disabled={disabled}
            />
          )
        ) : buttonComponent ? (
          buttonComponent
        ) : (
          <div
            className={classes.container}
            style={containerStyle}
            onClick={() => this.toggleMediaCreation(true)}
          >
            <div
              className={classes.innerContainer}
              style={_.get(addButtonStyles, "addButtonContainerStyle", {})}
            >
              <AddCircleIcon
                fill={colors.gray48}
                width={20}
                height={20}
                {..._.get(addButtonStyles, "addIconStyles", {})}
              />
              <div
                className={classes.addMediaText}
                style={addButtonStyles.addTextStyle}
              >
                {addMediaText ? addMediaText : t("common:add_attachments")}
              </div>
            </div>
          </div>
        )}
        <OneDrivePicker
          updateFilesCount={this.updateFilesCount}
          multiselect={multiselect}
          onSingleContentUploaded={this.onContentUploaded}
          onFailedAllowedMimeType={this.onFailedAllowedMimeType}
          thumbnailSize={"w248-h200"}
          updateRef={this.updateOneDriveRef}
          parentId={parentId}
          parentType={parentType}
          exportAsPdf={exportAsPdf}
          portalType={portalType}
          onMultipleFilesUploaded={onMultipleFilesUploaded}
        />
        {showCaptureScreen.capture_image && (
          <UIModal isOpen={true} isFullScreen={true}>
            <CaptureImage
              setToastMsg={setToastMsg}
              screenPanelProps={screenPanelProps}
            />
          </UIModal>
        )}
        {showCaptureScreen.capture_video && (
          <UIModal isOpen={true} isFullScreen={true}>
            <CaptureVideo
              setToastMsg={setToastMsg}
              screenPanelProps={screenPanelProps}
            />
          </UIModal>
        )}
        {showLinkModal ? (
          <LinkModal
            onLinkAdd={this.onLinkAdd}
            onClose={this.toggleLinkModal}
          />
        ) : null}

        {showNoteModal && (
          <AddNoteDialogue
            onClose={this.toddleAddNoteDialogue}
            saveNote={this.saveNote}
          />
        )}

        {showMediaCreation && (
          <CreatePost
            onClose={() => this.toggleMediaCreation(false)}
            parentType={parentType}
            parentId={parentId}
            mediaCreationOptions={mediaCreationOptions}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = state => {
  const curriculumProgramType =
    state.platform.currentCurriculumProgramType || "IB_PYP";
  return { curriculumProgramType };
};

CreateDropDown.propTypes = {
  cardType: PropTypes.string,
  exportAsPdf: PropTypes.bool,
  buttonDropDownContainerStyle: PropTypes.object,
  accept: PropTypes.array,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  containerStyle: PropTypes.object,
  dropDownOptions: PropTypes.array,
  multiselect: PropTypes.bool,
  isNewDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  dropzoneClass: PropTypes.string,
  attachmentExportOptions: PropTypes.array,
  showV2Icons: PropTypes.bool,
  customDropZoneStyle: PropTypes.object,
  buttonComponentConfig: PropTypes.object,
  showDescription: PropTypes.bool,
};

CreateDropDown.defaultProps = {
  exportAsPdf: false,
  parentId: "",
  parentType: "",
  buttonDropDownContainerStyle: {},
  buttonDropDownStyle: {},
  cardType: "horizontal",
  containerStyle: {},
  shouldCreateAsMedia: false,
  dropDownOptions: DROP_DOWN_OPTIONS,
  isShowIconsInBar: false,
  multiselect: true,
  hoverContainerStyles: {},
  isNewDropdown: false,
  disabled: false,
  attachmentExportOptions: ["LINK", "FILE"],
  showV2Icons: false,
  customDropZoneStyle: {},
  buttonComponentConfig: {},
  showDescription: false,
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators, null, { withRef: true })
)(CreateDropDown);
