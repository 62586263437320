// import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default () => ({
  path: "key-concepts",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            console.error(err);
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
});
