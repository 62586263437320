import React, { useState, useMemo } from "react";
import classes from "./ListModal.scss";
import { useI18n } from "Hooks";
import { UIBaseComponent, UIModal, EmptyView, I18nHOC } from "UIComponents";
import {
  TagOutlined,
  PencilLineOutlined,
  HelpBulbOutlined,
} from "@toddle-design/web-icons";
import {
  SearchInput,
  RadioButton,
  Button,
  IconButton,
  Tooltip,
} from "@toddle-design/web";
import { NoSearchResultsIllustration } from "@toddle-design/theme";
import classNames from "classnames";
import { compose } from "react-apollo";
import { connect } from "react-redux";

const ModalComponent = props => {
  const {
    options,
    value,
    modalTitleLabel,
    modalSearchPlaceholder,
    onClose,
    updateValue,
    showIndexInListModal,
  } = props;
  const [selectedOption, setSelectedOption] = useState(value);
  const [searchText, setSearchText] = useState("");
  const { t } = useI18n(["common"]);

  const newOptions = useMemo(() => {
    return _.filter(options, option =>
      _.includes(_.toLower(option.label), _.toLower(searchText))
    );
  }, [options, searchText]);

  const onClickOption = value => {
    setSelectedOption(value);
  };

  const onDoneClick = () => {
    updateValue(selectedOption, { nodes: options });
    onClose();
  };

  const headingContainer = classNames({
    ["heading-4"]: true,
    [classes.headingContainer]: true,
  });

  return (
    <UIModal isOpen={true} isFullScreen={false}>
      <div className={classes.modalContainer}>
        <div className={headingContainer}>{modalTitleLabel}</div>
        <div className={classes.searchBox}>
          <SearchInput
            placeholder={modalSearchPlaceholder}
            onChange={setSearchText}
            value={searchText}
            size={"large"}
          />
        </div>
        <div className={classes.listContainer}>
          <div className={classes.innerContainer}>
            {_.size(newOptions) > 0 ? (
              <>
                {_.map(newOptions, (option, index) => {
                  const serialString = showIndexInListModal
                    ? `${index + 1}.`
                    : "";
                  return (
                    <div
                      className={classes.listItemContainer}
                      key={option.value}
                      onClick={() => onClickOption(option.value)}
                    >
                      <span className="text-body-2">{`${serialString} ${option.label}`}</span>
                      <RadioButton checked={selectedOption === option.value} />
                    </div>
                  );
                })}
              </>
            ) : (
              <EmptyView
                emptyImageUrl={NoSearchResultsIllustration}
                size={"small"}
                emptyText={t("project:no_results_found_empty_text")}
                emptyHeaderText={t("common:noResultHeading")}
              />
            )}
          </div>
        </div>
      </div>
      <footer className={classes.footerContainer}>
        <Button onClick={onClose} variant={"outlined"}>
          {t("common:cancel")}
        </Button>
        <Button onClick={onDoneClick}>{t("common:done")}</Button>
      </footer>
    </UIModal>
  );
};

class ListModal extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showModal: false,
    };
  }

  toggleModal = () => {
    if (this.props?.isBoxDisabled) {
      return;
    }
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  renderLabel = () => {
    const {
      value,
      options,
      label,
      editLabel,
      isHelpActive,
      showHelper,
      onHelpClick,
      helpEnabledFields,
      fieldKey,
      fieldId,
      getIsHelpActive,
    } = this.props;

    const { helpText } = _.find(helpEnabledFields, { uid: fieldKey }) || {};

    const valueLabel = _.get(
      _.find(options, option => option.value === value),
      "label",
      null
    );
    const editLabelClass = classNames({
      [classes.editLabel]: true,
      ["text-body-2"]: true,
    });
    return (
      <div className={classes.labelContainer}>
        <span className="text-label-2">{label}</span>
        <div className={classes.labelRightContainer}>
          {Boolean(valueLabel) && (
            <span className={editLabelClass} onClick={this.toggleModal}>
              <PencilLineOutlined size={"xxx-small"} variant={"link"} />
              {editLabel}
            </span>
          )}
          {showHelper && (
            <IconButton
              icon={<HelpBulbOutlined />}
              variant={
                getIsHelpActive({ fielduid: fieldKey }) ? "primary" : "plain"
              }
              size={"large"}
              onClick={() =>
                onHelpClick({
                  fieldKey,
                  helperTextId: helpText.id,
                  id: fieldId,
                })
              }
            />
          )}
        </div>
      </div>
    );
  };

  renderEdit = () => {
    const {
      options,
      value,
      modalTitleLabel,
      modalSearchPlaceholder,
      fieldTypeConfig,
      disabledTooltip,
      t,
      isBoxDisabled,
    } = this.props;
    const { showModal } = this.state;

    const valueLabel = _.get(
      _.find(options, option => option.value === value),
      "label",
      null
    );
    const boxInnerContainerClasses = classNames({
      [classes.boxInnerContainer]: true,
      ["text-body-2"]: true,
      [classes.boxDisabled]: isBoxDisabled,
    });

    const getBoxInnerComponent = ({ iconVariant = "link" }) => (
      <div className={boxInnerContainerClasses} onClick={this.toggleModal}>
        <TagOutlined variant={iconVariant} />
        {modalTitleLabel}
      </div>
    );

    return (
      <div className={classes.container}>
        {valueLabel ? (
          <div className={classes.valueLabelText}>
            <span className="text-body-2"> {valueLabel}</span>
          </div>
        ) : (
          <div className={classes.boxOuterContainer}>
            {isBoxDisabled ? (
              <Tooltip tooltip={t(disabledTooltip)} isVisible={!isBoxDisabled}>
                {getBoxInnerComponent({ iconVariant: "subtle" })}
              </Tooltip>
            ) : (
              getBoxInnerComponent({ iconVariant: "link" })
            )}
          </div>
        )}
        {showModal && (
          <ModalComponent
            options={options}
            value={value}
            updateValue={this.updateValue}
            modalTitleLabel={modalTitleLabel}
            modalSearchPlaceholder={modalSearchPlaceholder}
            onClose={this.toggleModal}
            showIndexInListModal={_.get(
              fieldTypeConfig,
              "showIndexInListModal",
              true
            )}
          />
        )}
      </div>
    );
  };
}

ListModal.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isBoxDisabled:
      ownProps?.shouldBeDisabledOnEmpty && _.isEmpty(ownProps?.options),
  };
};

export default compose(
  I18nHOC({ resource: ["project"] }),
  connect(mapStateToProps)
)(ListModal);
