import PropTypes from "prop-types";
import React from "react";

const CircularAlertIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <g fill={props.fill && props.fill}>
        <path
          d="M16,1.71974194 C23.8741008,1.71974194 30.2796387,8.12567782 30.2796387,16 C30.2796387,23.8742115 23.8742115,30.2796387 16,30.2796387 C8.12578852,30.2796387 1.72036129,23.8742115 1.72036129,16 C1.72036129,8.12567782 8.12589921,1.71974194 16,1.71974194 Z M16,32 C24.823595,32 32,24.823595 32,16 C32,7.17640503 24.823595,0 16,0 C7.17640503,0 0,7.17640503 0,16 C0,24.823595 7.17640503,32 16,32 Z M15.9995797,17.7322498 C16.4875479,17.7322498 16.8841697,17.3352993 16.8841697,16.8468193 L16.8841697,9.14139375 C16.8841697,8.65355388 16.4881881,8.25806452 15.9995797,8.25806452 C15.5109074,8.25806452 15.1147796,8.65361786 15.1147796,9.14139375 L15.1147796,16.8468193 C15.1147796,17.3352353 15.5115476,17.7322498 15.9995797,17.7322498 Z M17.1354839,22.899634 C17.1354839,22.2723516 16.6280077,21.7654109 16.0014709,21.7654109 C15.3723045,21.7654109 14.8645161,22.2720398 14.8645161,22.899634 C14.8645161,23.5270402 15.3723269,24.0336469 16.0014709,24.0336469 C16.6279853,24.0336469 17.1354839,23.5267284 17.1354839,22.899634 Z"
          id="Combined-Shape"
        />
      </g>
    </svg>
  );
};

CircularAlertIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

CircularAlertIcon.defaultProps = {
  height: 16,
  width: 16,
};

export default CircularAlertIcon;
