import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
// import XHR from "i18next-xhr-backend";
// import HttpApi from "i18next-http-backend";
// import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";
import moment from "moment";
import allLanguages from "moment/min/locales.min";
// import resources from "../static/locales/index";
import { loadLocalesBundle } from "Utils";
import { i18nextLanguage } from "config";
allLanguages;

//here if you add some word in below list then it will be preserved from converting into lower case
const PRESERVE_WORDS = {
  MYP: "MYP",
  PYP: "PYP",
  UBD: "UbD",
  CAS: "CAS",
  EE: "EE",
  TOK: "TOK",
  PAI: "PAI",
  PEP: "PEP",
};

const getRegex = () => {
  return new RegExp(
    "\\b(" + Object.keys(PRESERVE_WORDS).join("|") + ")\\b",
    "gi"
  );
};

const i18next = () => {
  i18n
    .use(intervalPlural)
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
      // resources,
      react: {
        useSuspense: false,
      },
      fallbackLng: "en",
      ns: [],
      lng: i18nextLanguage,
      defaultNS: false,
      debug: false,
      saveMissing: false,
      transSupportBasicHtmlNodes: true,
      backend: {
        // backends: [HttpApi],
        // backends: [LocalStorageBackend,HttpApi],
        // backendOptions: [
        //   {
        //     loadPath: `/locales/{{lng}}/{{ns}}.json`,
        //     crossDomain: true,
        //     version: "v1"
        //   }
        // ]
      },
      returnEmptyString: false,
      overloadTranslationOptionHandler: args => {
        return {
          defaultValue: `# ${args[0]} ${i18n.language}`,
        };
      },
      interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
          let andTxt = " and ";
          let orTxt = " or ";

          if (lng === "es") {
            andTxt = " y ";
          }

          if (lng === "es") {
            orTxt = " o ";
          }

          if (lng === "tr") {
            andTxt = " ve ";
            orTxt = " veya ";
          }

          let formats = _.split(format, ",");

          if (moment.isMoment(value)) {
            return moment(value).format(format);
          }
          if (value instanceof Date) return moment(value).format(format);
          if (_.includes(formats, "joinArrayWithAnd")) {
            if (value.length > 1) {
              value = value.slice(0, -1).join(", ") + andTxt + value.slice(-1);
            }
          }
          if (_.includes(formats, "joinArrayWithOr")) {
            if (value.length > 1) {
              value = value.slice(0, -1).join(", ") + orTxt + value.slice(-1);
            }
          }
          if (_.includes(formats, "lowercase")) value = _.toLower(value);
          if (_.includes(formats, "uppercase")) value = _.toUpper(value);
          if (_.includes(formats, "capitalize")) value = _.capitalize(value);

          return _.replace(
            value,
            getRegex(),
            match => PRESERVE_WORDS[_.toUpper(match)]
          );
        },
      },
    });

  i18n.on("languageChanged", lng => {
    loadLocalesBundle(lng);
    loadLocalesBundle("en");
    if (lng == "en") {
      moment.locale(lng, {
        calendar: {
          lastDay: "[Yesterday]",
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          lastWeek: "D MMMM",
          nextWeek: "D MMMM",
          sameElse: "D MMMM",
        },
      });
    } else if (lng == "es") {
      moment.locale(lng, {
        calendar: {
          lastDay: "[ayer]",
          sameDay: "[hoy]",
          nextDay: "[mañana]",
          lastWeek: "D MMMM",
          nextWeek: "D MMMM",
          sameElse: "D MMMM",
        },
        week: { dow: 0 },
      });
    } else {
      moment.locale(lng, { week: { dow: 0 } });
    }
  });
};

export default i18next;
export { i18n };
