import gql from "graphql-tag";

import { productUpdateFragment } from "./ProductUpdatesFragments";

export const getProductUpdatesQuery = gql`
  query getProductUpdatesFeed(
    $filters: ProductUpdatesFilter
    $after: String
    $first: Int
    $orderBy: ORDERBY_FIELD_ENUM_CMS
    $onlyNewContent: Boolean
  ) {
    cms {
      productUpdatesFeed(
        filters: $filters
        after: $after
        first: $first
        orderBy: $orderBy
        onlyNewContent: $onlyNewContent
      ) {
        count
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...productUpdateItem
          }
          cursor
        }
      }
    }
  }
  ${productUpdateFragment.productUpdate}
`;

export const getProductUpdateQuery = gql`
  query getProductUpdate($id: ID!) {
    node(id: $id, type: PRODUCT_UPDATE) {
      ... on ProductUpdates {
        ...productUpdateItem
      }
    }
  }
  ${productUpdateFragment.productUpdate}
`;
