import React from "react";
import classes from "./DropDown.scss";
import { ButtonDropdown, I18nHOC } from "UIComponents";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
const buttonDropDownContainerStyle = {
  top: 40,
  width: 120,
  right: 0,
};

const buttonDropDownItemStyle = {
  height: 32,
};

const DropDown = ({
  item,
  onDeleteClick,
  onEditClick,
  canPick,
  onUpdateShowDropDown,
  fieldValue,
  t,
}) => {
  const menuItems = [
    {
      label: t("edit"),
      clickAction: () => {
        onEditClick(item);
      },
      svg: null,
      isDisable: false,
    },
    {
      label: canPick ? t("remove") : t("delete"),
      clickAction: () => {
        onDeleteClick({ item, fieldValue });
      },
      svg: null,
      isDisable: false,
    },
  ];

  return (
    <div className={classes.container}>
      <ButtonDropdown
        onUpdateShowDropDown={onUpdateShowDropDown}
        authTabs={menuItems}
        containerStyle={buttonDropDownContainerStyle}
        itemStyle={buttonDropDownItemStyle}
        buttonComponent={
          <div className={classes.moreButton}>
            <MoreIcon width={16} height={16} fill={colors.warmGrey} />
          </div>
        }
      />
    </div>
  );
};

export default I18nHOC({ resource: "common" })(DropDown);
