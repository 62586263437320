/**--external-- */
import React from "react";
import classNames from "classnames";
import propTypes from "prop-types";

/**--internal-- */
import { I18nHOC, Timezone, OperationalDays } from "UIComponents";
import AcademicYearsSetup from "../../../AcademicYearsSetup";

/**--relative-- */
import classes from "./Step2.scss";

const Step2 = props => {
  const {
    t,
    academicYears,
    operationalDays,
    updateOrganizationInformation,
    timezone,
  } = props;
  const headerClasses = classNames("heading-3", classes.header);
  const subHeaderClasses = classNames("heading-5", classes.subHeader);

  const updateOperationalDays = value => {
    updateOrganizationInformation({
      key: "operationalDays",
      value,
    });
  };

  const updateTimezone = timezone => {
    updateOrganizationInformation({ key: "timezone", value: timezone });
  };

  return (
    <div className={classes.container}>
      <h3 className={headerClasses}>
        {t("onBoarding:configure_academic_year")}
      </h3>
      <div>
        <AcademicYearsSetup
          t={t}
          academicYears={academicYears}
          updateOrgObject={({ academicYears }) =>
            updateOrganizationInformation({
              key: "academicYears",
              value: academicYears,
            })
          }
        />
      </div>
      <div className={classes.footer}>
        <h5 className={subHeaderClasses}>
          {t("onBoarding:define_time_zone_operational_days")}
        </h5>
        <div className={classes.timezoneContainer}>
          <Timezone
            value={timezone}
            updateInputField={updateTimezone}
            menuPlacement="top"
          />
        </div>
        <OperationalDays
          operationalDays={operationalDays}
          updateOperationalDays={updateOperationalDays}
        />
      </div>
    </div>
  );
};

Step2.propTypes = {
  t: propTypes.func,
  academicYears: propTypes.arrayOf(propTypes.object),
  operationalDays: propTypes.array,
  updateOrganizationInformation: propTypes.func,
  timezone: propTypes.string,
};

export default I18nHOC({ resource: ["onBoarding", "common"] })(Step2);
