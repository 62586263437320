import React, { Component } from "react";
import PropTypes from "prop-types";

const IcnNotiPostLike = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <g
        id="Parents/-Students-liked-the-post"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M15.9244334,10.3857986 L15.748319,10.2323592 C13.9537859,8.70458477 12.2811763,8.10795387 10.7332153,8.52415066 C7.97851971,9.26479949 6.17316389,12.4674397 7.10447599,15.5485358 C8.35940959,18.9177517 11.20611,21.6984258 15.6193263,23.9432215 C15.8090638,24.039732 16.032276,24.0452311 16.2265356,23.9581807 C20.2563929,22.1523481 23.1339329,19.3724795 24.8305885,15.6359277 C25.8463777,12.3441599 24.3058184,9.26749693 21.3433923,8.52132776 C19.5929278,8.08042472 17.8240264,8.6833432 16.0658385,10.2566512 L15.9244334,10.3857986 Z M20.9994068,9.88701216 C23.1482417,10.4282558 24.272343,12.6732072 23.51621,15.1382943 L23.3550838,15.4801686 C21.9245431,18.4183498 19.6070841,20.7129474 16.4052121,22.3154735 L15.9563557,22.5313177 L16.2578294,22.6879391 C12.1351008,20.5909009 9.54176688,18.0577167 8.43831129,15.0987467 C7.75235731,12.8244873 9.10809881,10.4194477 11.0988858,9.88418925 C12.2665009,9.57025521 13.7154278,10.1909871 15.449547,11.862689 C15.7286848,12.1317796 16.1728341,12.1245943 16.4431216,11.8466153 C18.0905926,10.1522626 19.5957029,9.53345039 20.9994068,9.88701216 Z"
          id="Path-5"
          fill={props.fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

IcnNotiPostLike.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

IcnNotiPostLike.defaultProps = {
  width: 32,
  height: 32,
};

export default IcnNotiPostLike;
