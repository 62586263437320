import React from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";

const FeedbackResponseIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 5.72386 5.22386 5.5 5.5 5.5H10.5C10.7761 5.5 11 5.72386 11 6C11 6.27614 10.7761 6.5 10.5 6.5H5.5C5.22386 6.5 5 6.27614 5 6Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.6C5 8.32386 5.22386 8.1 5.5 8.1H10.5C10.7761 8.1 11 8.32386 11 8.6C11 8.87614 10.7761 9.1 10.5 9.1H5.5C5.22386 9.1 5 8.87614 5 8.6Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.35C2 3.09181 3.00624 2 4.3 2H11.7C12.9974 2 13.9877 3.09103 14.0494 4.32503C14.0498 4.33335 14.05 4.34167 14.05 4.35V9.95C14.05 10.1862 13.9965 10.3881 13.9556 10.5423C13.9482 10.5702 13.9412 10.5966 13.9351 10.6213C13.9265 10.6556 13.9143 10.689 13.8986 10.7208C13.8986 10.7208 13.8961 10.7264 13.8911 10.7387C13.8858 10.7516 13.8829 10.7587 13.8796 10.767C13.876 10.776 13.8718 10.7863 13.8636 10.8065C13.8403 10.8633 13.8084 10.9388 13.766 11.0236C13.6813 11.193 13.5525 11.4046 13.3536 11.6036C12.7173 12.2399 11.907 12.3 11.7 12.3H9.75337L8.80883 13.588C8.41053 14.1707 7.58947 14.1707 7.19117 13.588L6.24663 12.3H4.75C4.08535 12.3 3.62637 12.2523 3.16141 11.9895C2.96166 11.884 2.6627 11.618 2.39313 11.2406C2.38217 11.2253 2.37208 11.2093 2.36292 11.1928C2.06625 10.6588 2 10.1731 2 9.9V4.35ZM4.3 3C3.59376 3 3 3.60819 3 4.35V9.9C3 10.024 3.03225 10.3271 3.22362 10.6826C3.32347 10.8192 3.42039 10.9245 3.49893 10.9984C3.56675 11.0622 3.6098 11.0924 3.62212 11.1011C3.62639 11.1041 3.62697 11.1045 3.62361 11.1028C3.63188 11.1069 3.64004 11.1113 3.64807 11.1159C3.87986 11.2483 4.11917 11.3 4.75 11.3H6.5C6.65927 11.3 6.80901 11.3759 6.9032 11.5043L8 13L9.0968 11.5043C9.19099 11.3759 9.34073 11.3 9.5 11.3H11.7C11.793 11.3 12.2827 11.2601 12.6464 10.8964C12.8104 10.7325 12.8726 10.5814 12.9329 10.4348C12.9473 10.4 12.9615 10.3654 12.977 10.331C12.9839 10.3037 12.9903 10.2789 12.9962 10.2561C13.0336 10.1109 13.05 10.0473 13.05 9.95V4.36338C13.0062 3.60283 12.399 3 11.7 3H4.3Z"
        fill={props.fill}
      />
    </svg>
  );
};

FeedbackResponseIcon.defaultProps = {
  height: 16,
  width: 16,
  fill: colors.white,
};

FeedbackResponseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default FeedbackResponseIcon;
