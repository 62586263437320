import React from "react";
import classes from "./ManageOrgFeatures.scss";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { UIButton, UIModal, SwitchButton, withLoader } from "UIComponents";
import _ from "lodash";
import { getConfigurableSettingsQuery } from "Tooling/modules/ToolingQueries";
import { getConfigurableSettingsFromCache } from "Tooling/modules/ToolingGraphqlHelpers";

class ManageOrgFeatures extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
    };
  }

  onClickSave = () => {
    const { permissions } = this.state;
    const param = _.map(permissions, setting => ({
      permissionId: setting.id,
      value: setting.isEnabled,
    }));
    this.props.onClickOperation(param);
  };

  componentDidMount = () => {
    this.setState({
      permissions: this.props.configurablePermission,
    });
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.configurablePermission !== this.props.configurablePermission
    ) {
      this.setState({
        permissions: this.props.configurablePermission,
      });
    }
  };

  onClickCancel = () => {
    this.props.closeModal();
  };

  updatePermission = param => {
    const { permissions } = this.state;
    const updatedPermissions = _.map(permissions, setting =>
      setting.id === param.id ? param : setting
    );
    this.setState({ permissions: updatedPermissions });
  };

  convertPermissions = permissions => {
    return _.map(permissions, setting => ({
      permissionId: setting.id,
      value: setting.isEnabled,
    }));
  };

  render() {
    const { permissions } = this.state;
    return (
      <>
        <div className={classes.container}>
          <div className={classes.modalBody}>
            <div
              className={classes.modalHeader}
            >{`Manage school features`}</div>

            {_.map(permissions, setting => (
              <div key={setting.id} className={classes.settingCard}>
                <div className={classes.settingLeftContainer}>
                  <div className={classes.settingTitle}>{setting.label}</div>
                </div>
                <div className={classes.switch}>
                  <SwitchButton
                    updateInputField={value =>
                      this.updatePermission({ ...setting, isEnabled: value })
                    }
                    value={setting.isEnabled}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={classes.modalFooter}>
          <div className={classes.rightPaneFooter}>
            <UIButton
              className={classes.cancelButton}
              size="sm"
              color="grey"
              onClick={this.onClickCancel}
            >
              {`Cancel`}
            </UIButton>
            <div className={classes.lastButton}>
              <UIButton
                className={classes.saveButton}
                isDisabled={false}
                size="sm"
                color="blue"
                onClick={this.onClickSave}
              >
                {`Save changes`}
              </UIButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const ManageOrgFeaturesLoading = withLoader(ManageOrgFeatures);

const ManageOrgFeaturesComp = React.memo(props => {
  const { closeModal } = props;
  return (
    <UIModal
      isOpen={true}
      onRequestClose={closeModal}
      modalContent={classes.modalContent}
    >
      <ManageOrgFeaturesLoading {...props} />
    </UIModal>
  );
});

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    id: ownProps.orgDetail.id,
  };
};

const mapActionCreators = {};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getConfigurableSettingsQuery, {
    name: "getConfigurableSettings",
    options: ({ orgDetail }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: orgDetail.id },
    }),
    props: ({
      getConfigurableSettings,
      ownProps: { id, isLoading, isData },
    }) => {
      const configurableSettingsData = getConfigurableSettingsFromCache(id);
      const configurablePermission = _.get(
        configurableSettingsData,
        "configurablePermission",
        []
      );
      return {
        configurablePermission,
        isData: !_.isEmpty(configurableSettingsData) && isData,
        isLoading:
          getConfigurableSettings["networkStatus"] == 1 ||
          getConfigurableSettings["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(ManageOrgFeaturesComp);
