import gql from "graphql-tag";

import {
  staffCourseFragment,
  studentCourseFragment,
} from "Teacher/modules/TeacherFragments";

//Don't use it. Will deprecate. Use getUserCoursesQuery instead.
export const getStaffCoursesQuery = gql`
  query getStaffCoursesQuery(
    $id: ID!
    $filters: CourseFilter
    $isCurriculumProgramFree: Boolean! = false
  ) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        ...staffItem
      }
    }
  }
  ${staffCourseFragment.staffCourse}
`;

export const getUserCoursesQuery = gql`
  query getUserCourses(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: CourseFilter
    $isCurriculumProgramFree: Boolean! = false
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        ...staffItem
      }
      ... on Student {
        ...studentCourseItem
      }
    }
  }
  ${studentCourseFragment.studentCourse}
  ${staffCourseFragment.staffCourse}
`;

export const getStudentCourseTagsQuery = gql`
  query getStudentCourseTags(
    $id: ID!
    $courseIdsFilter: UserCourseMapFilters!
  ) {
    node(id: $id, type: STUDENT) {
      id
      ... on Student {
        ...studentCourseTags
      }
    }
  }
  ${studentCourseFragment.studentCourseTags}
`;

export const getStaffCoursesStudentsQuery = gql`
  query getStaffCoursesStudents(
    $id: ID!
    $searchText: String
    $filters: CourseFilter
  ) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        ...staffItem
      }
    }
  }
  ${staffCourseFragment.StaffCourseStudents}
`;

export const getStaffCoursesBasicDetailsQuery = gql`
  query getStaffCoursesBasicDetailsQuery($id: ID!, $filters: CourseFilter) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        ...staffItem
      }
    }
  }
  ${staffCourseFragment.staffCourseBasicDetails}
`;

export const getStaffCourseGenericFoldersBasicDetailsQuery = gql`
  query getStaffCourseGenericFoldersDetails(
    $id: ID!
    $folderType: FOLDER_TYPE_ENUM!
  ) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        ...staffItem
      }
    }
  }
  ${staffCourseFragment.staffCourseGenericFoldersBasicDetails}
`;
