import { Sentry } from "services/sentry";
import _get from "lodash/get";
import _trim from "lodash/trim";

const codes = {
  UNAVAILABLE_RESOURCE: {
    id: "UNAVAILABLE_RESOURCE",
  },
  BAD_USER_INPUT: {
    id: "BAD_USER_INPUT",
  },
  GRAPHQL_VALIDATION_FAILED: {
    id: "GRAPHQL_VALIDATION_FAILED",
  },
};

const isGraphQLBadInputError = e => {
  if (e?.extensions?.code == codes.BAD_USER_INPUT.id) {
    return true;
  }
  return false;
};

const isGraphQLValidationError = e => {
  if (e?.extensions?.code == codes.GRAPHQL_VALIDATION_FAILED.id) {
    return true;
  }
  return false;
};

export const isUnavailableResourceError = e => {
  if (_get(e, "extensions.code") == codes.UNAVAILABLE_RESOURCE.id) {
    return true;
  }
  return false;
};

const shouldReportGraphQLError = e => {
  if (isGraphQLBadInputError(e)) {
    return true;
  } else if (isGraphQLValidationError(e)) {
    return true;
  }
  return false;
};

/**
 * @description process&capture&send errors to sentry
 */
export const onErrorLinkSentry = errorHandler => {
  const { graphQLErrors, networkError, operation } = errorHandler;
  const {
    extensions: { route = "NO_ROUTE" } = {},
    operationName = "NOP",
    variables = {},
  } = operation;

  const query = _trim(_get(operation, "query.loc.source.body", ""));

  graphQLErrors?.forEach(e => {
    if (shouldReportGraphQLError(e)) {
      const { extensions: { code = "NO_CODE" } = {}, message = null } = e;
      /**
       * @description console.logs with huge data will increase breadcrumbs size-
       * which will exceed  the sentry event payload size leading to
       * sentry discarding the event from sending it to server so
       * it is required to clearBreadcrumbs.
       */
      Sentry.getCurrentHub().getScope().clearBreadcrumbs();
      Sentry.captureMessage(message, {
        tags: {
          route,
          operationName,
          code,
        },
        extra: {
          query,
          variables: JSON.stringify(variables),
        },
      });
    }
  });
};
