import React from "react";
import { Alert } from "SvgComponents";
import { colors } from "Constants";
import classes from "./DisabledStateTooltip.scss";
import { LinkWithTooltip, I18nHOC } from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";

const tooltipContainerStyle = {
  width: "312px",
  height: "56px",
  padding: "12px 16px",
  borderRadius: "4px",
};

const getMessage = ({ isCurrentAcademicYear, isArchivedClass, t }) => {
  if (!isCurrentAcademicYear)
    return t("common:feature_not_available_previous_year");
  else if (isArchivedClass)
    return t("common:feature_not_available_archived_class");
};

const isFalsy = value => typeof value === "boolean" && !value;

const DisabledStateTooltip = props => {
  const {
    children,
    placement,
    isCurrentAcademicYear,
    containerStyle,
    t,
    isArchivedClass,
  } = props;

  const isVisible = isFalsy(isCurrentAcademicYear) || Boolean(isArchivedClass);
  const message = getMessage({ isCurrentAcademicYear, isArchivedClass, t });

  const getTootipContainer = () => {
    return (
      <div className={classes.tooltipContainer}>
        <Alert fill={colors.white} />
        <div className={classes.message}>{message}</div>
      </div>
    );
  };

  return (
    <LinkWithTooltip
      tooltip={getTootipContainer()}
      placement={placement}
      id="tooltip-previous-academic-year"
      isVisible={isVisible}
      tooltipContainerStyle={
        containerStyle ? containerStyle : tooltipContainerStyle
      }
    >
      {children}
    </LinkWithTooltip>
  );
};

DisabledStateTooltip.defaultProps = {
  placement: "bottom",
};

const mapStateToProps = (state, ownProps) => {
  const isCurrentAcademicYearSelected = _.get(
    state,
    "platform.academicYearSelected.isCurrentAcademicYear",
    true
  );
  return {
    // isCurrentAcademicYearSelected,
    isArchivedClass: _.get(state, "teacher.selected_class.isArchivedClass"),
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps)
)(DisabledStateTooltip);
