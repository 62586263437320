import React, { useState } from "react";
import classes from "./Table.scss";
import TableBody from "./Components/TableBody";
import { useI18n } from "Hooks";
import { Loading, LinkWithTooltip } from "UIComponents";
import { colors } from "Constants";
import { onAddFirstRow, getFilteredColumns } from "NodeEditor/Utils";
import { Checkbox } from "@toddle-design/web";
import {
  AddCircleOutlined,
  WarningCircleOutlined,
} from "@toddle-design/web-icons";

const disabledStyle = { fill: colors.strokeTwo, cursor: "not-allowed" };
const overlayStyle = { maxWidth: 180 };

const TableHeader = props => {
  const { t } = useI18n(["scopeAndSequence", "common", "academicSetup"]);
  const {
    mode,
    nodes,
    setNodes,
    rootNodeId,
    updateNodesInDraft,
    userId,
    currentEditorConfig,
    columnIds,
    isActionDisabled,
    plannerElementSets,
    nodeType,
  } = props;

  const [isChecked, setIsChecked] = useState(false);
  const rootNodeChildren = _.get(nodes[rootNodeId], "children", []);
  const allowedColumns = currentEditorConfig.columns;
  if (_.isEmpty(rootNodeChildren) && isChecked) {
    setIsChecked(false);
  }
  const checkAllNodes = () => {
    if (!_.isEmpty(rootNodeChildren)) {
      if (!isChecked) {
        const newNodes = { ...nodes };
        _.forOwn(newNodes, (value, key) => {
          if (value.depth != 0) {
            newNodes[key] = { ...value, isChecked: true };
          }
        });
        setNodes(newNodes);
        setIsChecked(true);
      } else {
        const newNodes = { ...nodes };
        _.forOwn(newNodes, (value, key) => {
          if (value.depth != 0) {
            newNodes[key] = { ...value, isChecked: false };
          }
        });
        setNodes(newNodes);
        setIsChecked(false);
      }
    }
  };

  const filteredColumns = getFilteredColumns({ allowedColumns, mode });

  const tooltip = (
    <ul className={classes.benchmarkQuestionTooltipContainer}>
      <li>{t("academicSetup:benchmark_question_tooltip_label1")}</li>
      <li>{t("academicSetup:benchmark_question_tooltip_label2")}</li>
    </ul>
  );

  const handleAddFirstRow = () => {
    const updatedNodes = onAddFirstRow({ rootNodeId, nodes, setNodes, userId });
    updateNodesInDraft({ updatedNodes });
  };

  const addIconTooltipText = t("common:add_with_label", {
    label: t("scopeAndSequence:strand"),
  });

  return (
    <thead className={classes.tableHeader}>
      <tr>
        {_.map(filteredColumns, column => {
          const columnLabel = _.get(column, "label", "");
          const columnStyle = _.get(column, "style", {});
          const columHeader = _.get(
            plannerElementSets[nodeType],
            "label",
            t(columnLabel)
          );
          switch (column.id) {
            case columnIds.label: {
              return (
                <th
                  className={classes.tableCell}
                  key={column.id}
                  style={columnStyle}
                >
                  <div className={classes.learningStandardsHeader}>
                    {mode === "edit" && !isActionDisabled && (
                      <div className={classes.actionButtonContainer}>
                        <div className={classes.addIconContainer}>
                          <div
                            className={classes.addIcon}
                            onClick={handleAddFirstRow}
                            style={isActionDisabled ? disabledStyle : {}}
                          >
                            <LinkWithTooltip
                              tooltip={addIconTooltipText}
                              placement={"top"}
                            >
                              <AddCircleOutlined size={"xx-small"} />
                            </LinkWithTooltip>
                          </div>
                        </div>
                        <div className={classes.checkBoxContainer}>
                          <Checkbox
                            isChecked={isChecked}
                            onChange={checkAllNodes}
                            size="xx-small"
                          />
                        </div>
                      </div>
                    )}
                    <div>{_.capitalize(columHeader)}</div>
                  </div>
                </th>
              );
            }
            case columnIds.code: {
              return (
                <th
                  className={classes.tableCell}
                  key={column.id}
                  style={columnStyle}
                >
                  {t(columnLabel)}
                </th>
              );
            }
            case columnIds.grade: {
              return (
                <th
                  className={classes.tableCell}
                  key={column.id}
                  style={columnStyle}
                >
                  {t(columnLabel)}
                </th>
              );
            }
            case columnIds.tag: {
              return (
                <th
                  className={classes.tableCell}
                  key={column.id}
                  style={columnStyle}
                >
                  {t(columnLabel)}
                </th>
              );
            }
            case columnIds.starQues: {
              return (
                <th
                  className={classes.tableCell}
                  key={column.id}
                  style={columnStyle}
                >
                  <div className={classes.staarQuesContainer}>
                    <div className={classes.questionHeader}>
                      {t(columnLabel)}
                    </div>
                    <div className={classes.quesWrapper}>
                      <LinkWithTooltip
                        tooltip={tooltip}
                        placement={"top"}
                        id="tooltip-CreatePostSvg"
                        overlayStyle={overlayStyle}
                        href="javascript:void(0)"
                      >
                        <WarningCircleOutlined
                          variant={"link"}
                          size={"x-small"}
                        />
                      </LinkWithTooltip>
                    </div>
                  </div>
                </th>
              );
            }
          }
        })}
      </tr>
    </thead>
  );
};

const Table = props => {
  const {
    mode,
    nodes,
    setNodes,
    rootNodeId,
    updateNodesInDraft,
    userId,
    currentEditorConfig,
    columnIds,
    isActionDisabled,
    maxSidebarDepth,
    plannerElementSets,
    nodeType,
  } = props;
  const tableStyle = currentEditorConfig.tableStyle || {};
  return (
    <div className={classes.tableContainer}>
      {_.size(nodes) > 0 ? (
        <table className={classes.table} style={tableStyle} id={"TABLE"}>
          <TableHeader
            mode={mode}
            nodes={nodes}
            setNodes={setNodes}
            rootNodeId={rootNodeId}
            updateNodesInDraft={updateNodesInDraft}
            userId={userId}
            currentEditorConfig={currentEditorConfig}
            columnIds={columnIds}
            isActionDisabled={isActionDisabled}
            plannerElementSets={plannerElementSets}
            nodeType={nodeType}
          />
          <TableBody {...props} />
        </table>
      ) : (
        <div className={classes.loadingContainer}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Table;
