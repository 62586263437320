/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import { ButtonDisclosure } from "@toddle-design/web";
import { FilterOutlined } from "@toddle-design/web-icons";

/**--internal-- */
import { useI18n } from "Hooks";

const MoreFiltersButton = props => {
  const { t } = useI18n(["common"]);

  const { onButtonClick, isActivated } = props;

  return (
    <ButtonDisclosure
      icon={<FilterOutlined />}
      size={"medium"}
      variant={"progressive"}
      onClick={onButtonClick}
      isActivated={isActivated}
    >
      {t("common:more")}
    </ButtonDisclosure>
  );
};

MoreFiltersButton.defaultProps = {
  onButtonClick: _.noop,
  isActivated: false,
};

MoreFiltersButton.propTypes = {
  onButtonClick: PropTypes.func,
  isActivated: PropTypes.bool,
};

MoreFiltersButton.displayName = "MoreFiltersButton";

export default MoreFiltersButton;
