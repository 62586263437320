import client from "apolloClient";
import { organizationGradeItem } from "GradesSetup/modules/Fragment";

export const getOrganizationGradesFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Organization:${id}`,
      variables: { filters },
      fragment: organizationGradeItem,
      fragmentName: "organizationItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeOrganizationGradesInCache = ({ id, filters, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${id}`,
      variables: { filters },
      fragment: organizationGradeItem,
      fragmentName: "organizationItem",
      data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
