import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import { getAssessmentQuery } from "Assessments/modules/AssessmentQuery";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { getUnitPlanDetailsQuery } from "PlanathonUnitPlans/modules/PlanathonUnitPlansQueries";
import {
  updateAssessmentInRedux,
  deleteAssessment,
  onClose,
  onClickEdit,
  showNotEditableLePlanToast,
} from "Assessments/modules/AssessmentModule";
import {
  updateUnitPlanInRedux,
  getLocalisedTemplate,
} from "IBPlanner/modules/IBPlannerModule";
import { graphql, compose } from "react-apollo";
import { withLoader, withPusherSubscribe, I18nHOC } from "UIComponents";
import {
  getPlannerFieldsOfNode,
  ResourceDetailsContext,
} from "UnitPlans/modules/UnitPlanModule";

import { DialogueBox, withPresenceChannel } from "UIComponents";
import classNames from "classnames";
import classes from "./AssessmentDetails.scss";
import { ShareWidget } from "AppComponents/Community";
import {
  updateAssessmentInCache,
  clearAssessmentTool,
} from "Assessments/modules/AssessmentModule";
import { setToastMsg, getUserInfo } from "Login/modules/LoginModule";
import { getPlannerTemplateFromCache } from "modules/CommonGraphqlHelpers";
import { getPlannerTemplateQuery } from "modules/CommonQuery";

import { getPrintFile, ASSESSMENT_TOOLS } from "modules/Services";
import { updateNavigationObject } from "modules/NavigationModule";
import {
  ResourceView,
  PypElementDetails,
  ResourceEdit,
} from "UnitPlans/components/LibraryCommonComponents";
import { isUnitPlanEditPermission } from "UnitPlans/modules/UnitPlanModule";
import ACLStore from "lib/aclStore";
import {
  goToRelativeRoute,
  trackEntityPreview,
  addApiEvent,
} from "modules/Services";
import { withPermission } from "lib/PermissionAware";
import { getPlannerElementSetsOfCurriculumEnhancer } from "modules/CommonQueryEnhancers";
import {
  CURRICULUM_TYPE_DP,
  UNIT_TYPE_DP,
  SUBJECT_TYPE_GENERIC,
} from "Constants/stringConstants";
import {
  getCurriculumBasedScorePermissionMemoize,
  checkIsScoreEnabledFromAdmin,
} from "ScoreCategories/modules/ScoreCategoriesModule";
import { withRouter } from "react-router";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import { SNPv2_VISITOR_PORTAL_PERM_GROUP } from "Constants/permissionConstants";

const RIGHT_PANE_ANIMATION_TIME = 300;
const getAssessmentType = assessmentType => {
  switch (assessmentType) {
    case "le":
      return "common:le_label";
    case "fmt":
      return "classRoom:formative_assessment";
    case "smt":
      return "classRoom:summative_assessment";
  }
};
const getWarningMsgParams = (assessmentType, t) => ({
  assessmentType: t(getAssessmentType(assessmentType)),
});

const getAssessmentObj = (sourceFrom, presetAssessment, assessmentDetails) =>
  sourceFrom === "CLASSROOM" && presetAssessment
    ? presetAssessment
    : assessmentDetails;

class AssessmentDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showRightPane: false,
      showDeleteDialogueBox: false,
      currentRightPane: null,
    };
    this.step = null;
  }

  UNSAFE_componentWillMount = () => {
    const { assessmentDetails, updateUnitPlanDataInRedux = true } = this.props;
    const { isDeleted } = assessmentDetails || {};

    if (isDeleted) {
      this.props.onClose();
      this.props.setToastMsg("toastMsgs:trying_to_access_deleted_le");
    } else {
      this.props.updateAssessmentInRedux({
        templateId: _.get(this.props.assessmentDetails, "templateId", ""),
      });
      if (updateUnitPlanDataInRedux) {
        this.props.updateUnitPlanInRedux({
          unitPlanId: _.get(this.props.assessmentDetails, "unitPlan.id", ""),
          templateId: _.get(
            this.props.assessmentDetails,
            "unitPlan.templateId",
            ""
          ),
        });
      }
      if (!_.isNil(this.props.showAssessmentCommentBox)) {
        setTimeout(() => this.doNotificationTask(this.props));
      }
    }
  };

  componentDidMount = () => {
    const {
      mode,
      isUnitPlanEditPermission,
      assessmentDetails,
      goToRelativeRoute,
      isCommunity,
      showNotEditableLePlanToast,
      renderAsComponent,
      trackEntityPreview,
      addApiEvent,
      userType,
    } = this.props;
    if (userType === "staff" && !isCommunity) {
      /**
       * This API event is for analytics on teachers or staff
       */
      addApiEvent({ eventType: "VIEW_ASSESSMENT", onlyOnceInADay: true });
    }

    if (renderAsComponent) return;
    if (mode != "view") {
      const isEditPermission = isCommunity
        ? false
        : isUnitPlanEditPermission({
            unitPlanId: _.get(assessmentDetails, "unitPlan.id", ""),
          }) || ACLStore.can("TeacherPortal:CanEditUnitPlan");
      if (!isEditPermission || !_.includes(["edit", "create"], mode)) {
        goToRelativeRoute({
          route: "./view",
          type: "replace",
          replacePath: mode,
        });
        if (!isEditPermission) {
          showNotEditableLePlanToast();
        }
      }
    }
    if (isCommunity) trackEntityPreview();
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      !_.isNil(nextProps.showAssessmentCommentBox) &&
      this.props.showAssessmentCommentBox != nextProps.showAssessmentCommentBox
    ) {
      setTimeout(() => this.doNotificationTask(nextProps));
    }
  };

  doNotificationTask = props => {
    if (props.showAssessmentCommentBox) {
      this.onCommentClick();
    }

    this.props.updateNavigationObject({ showAssessmentCommentBox: null });
  };

  onCommentClick = () => {
    this.handleRightPane({ rightPane: "comment" });
  };

  onDetailsClick = () => {
    this.handleRightPane({ rightPane: "details" });
  };

  handleRightPane = ({ rightPane }) => {
    const { currentRightPane } = this.state;
    if (rightPane == currentRightPane) {
      this.upsateShowRightPane(false);
      setTimeout(() => {
        this.updateRightPaneState({
          currentRightPane: "",
        });
      }, RIGHT_PANE_ANIMATION_TIME);
    } else if (currentRightPane) {
      this.upsateShowRightPane(false);
      setTimeout(() => {
        this.upsateShowRightPane(true);

        this.updateRightPaneState({
          currentRightPane: rightPane,
        });
      }, RIGHT_PANE_ANIMATION_TIME + 30);
    } else {
      this.upsateShowRightPane(true);

      this.updateRightPaneState({
        currentRightPane: rightPane,
      });
    }
  };

  upsateShowRightPane = val => {
    this.setState({ showRightPane: val });
  };

  updateRightPaneState = ({ currentRightPane }) => {
    this.setState({ currentRightPane });
  };

  onClose = ({ onlyExit = false }) => {
    const { mode, onCloseCallback, onClose } = this.props;

    if (mode == "create" && onlyExit) {
      this.setState({ showDeleteDialogueBox: true });
    } else {
      onClose({ isRemoved: false });
      if (onCloseCallback) {
        onCloseCallback({ isRemoved: false });
      }
    }
  };

  toggleDeleteDialogueBoxDisplay = () => {
    this.setState({ showDeleteDialogueBox: false });
  };

  onConfirmClose = () => {
    const {
      deleteAssessment,
      onClose,
      onCloseCallback,
      assessmentId,
    } = this.props;
    deleteAssessment({ assessmentId: assessmentId });
    onClose({ isRemoved: true });
    if (onCloseCallback) {
      onCloseCallback({ isRemoved: true });
    }
  };

  onConfirmSave = () => {
    this.step.onClickSave();
  };

  onContainerClick = e => {
    e.stopPropagation();
  };

  onClickPrint = ({ id, type = "assessment", title } = {}) => {
    const { getPrintFile, assessmentId } = this.props;
    getPrintFile({ id: id || assessmentId, type, extraParams: { title } });
  };

  setStepRef = ref => {
    this.step = ref;
  };

  render() {
    const leftPaneStyle = classNames(
      { [classes.smallLeftPane]: this.state.showRightPane },
      { [classes.fullLeftPane]: !this.state.showRightPane }
    );

    const rightPaneStyle = classNames(
      { [classes.rightPane]: true },
      { [classes.visibleRightPane]: this.state.showRightPane },
      { [classes.hiddenRightPane]: !this.state.showRightPane }
    );

    const {
      assessmentId,
      unitPlanId,
      pypElementDetails,
      template,
      unitPlanTemplate,
      benchmarkSet,
      unitPlanFields,
      assessmentFields,
      assessmentDetails,
      title,
      updateAssessmentInCache,
      grades,
      subjects,
      pypTemplateElements,
      isData,
      isLoading,
      onClickEdit,
      mode,
      t,
      enabledSections,
      renderAsComponent,
      sourceFrom,
      portalType,
      isCurriculumProgramFree,
      location,
      curriculumType,
      unitPlanName,
      assessmentType,
      plannerElements,
      assessmentMode,
      showTemplateConfigModal,
      toggleShowTemplateConfigModal,
    } = this.props;
    const {
      showRightPane,
      currentRightPane,
      showDeleteDialogueBox,
    } = this.state;

    const warningMsg =
      unitPlanName && assessmentType
        ? t(
            "classRoom:changes_made_here",
            getWarningMsgParams(assessmentType, t)
          )
        : "";

    return (
      !assessmentDetails.isDeleted && (
        <div className={classes.container} onClick={this.onContainerClick}>
          {/* <ShareWidget /> */}
          {mode != "view" ? (
            <ResourceDetailsContext.Provider value={assessmentDetails}>
              <ResourceEdit
                unitPlanId={unitPlanId}
                template={template}
                templateId={_.get(assessmentDetails, "templateId")}
                enabledSections={enabledSections}
                updateFieldInCache={updateAssessmentInCache}
                unitPlanTemplate={unitPlanTemplate}
                benchmarkSet={benchmarkSet}
                unitPlanFields={unitPlanFields}
                handleRightPane={this.handleRightPane}
                customRef={this.setStepRef}
                mode={mode}
                assessmentMode={assessmentMode}
                isData={isData}
                isLoading={isLoading}
                title={title}
                parentType={"ASSESSMENT"}
                resourceId={assessmentId}
                onClose={this.onClose}
                subjects={subjects}
                grades={grades}
                assessmentToolId={_.get(
                  assessmentDetails,
                  "assessmentTool.id",
                  ""
                )}
                totalCommentCount={_.get(
                  assessmentDetails,
                  "comments.totalCount",
                  0
                )}
                unreadCommentCount={_.get(
                  assessmentDetails,
                  "comments.unreadCount",
                  0
                )}
                showRightPane={showRightPane}
                currentRightPane={currentRightPane}
                resourceFields={assessmentFields}
                onClickPrint={this.onClickPrint}
                renderAsComponent={renderAsComponent}
                sourceFrom={sourceFrom}
                portalType={portalType}
                curriculumType={curriculumType}
                warningMsg={warningMsg}
                assessmentType={assessmentType}
                plannerElements={plannerElements}
                showTemplateConfigModal={showTemplateConfigModal}
                toggleShowTemplateConfigModal={toggleShowTemplateConfigModal}
                assessmentId={assessmentId}
              />
            </ResourceDetailsContext.Provider>
          ) : (
            <ResourceView
              t={t}
              onClickPrint={this.onClickPrint}
              parentType={"ASSESSMENT"}
              isData={isData}
              isLoading={isLoading}
              assessmentToolId={_.get(
                assessmentDetails,
                "assessmentTool.id",
                ""
              )}
              updateFieldInCache={updateAssessmentInCache}
              showRightPane={showRightPane}
              onClickEdit={onClickEdit}
              resourceId={assessmentId}
              currentRightPane={currentRightPane}
              unitPlanFields={unitPlanFields}
              benchmarkSet={benchmarkSet}
              template={template}
              resourceDetails={assessmentDetails}
              resourceFields={assessmentFields}
              onClose={this.onClose}
              handleRightPane={this.handleRightPane}
              unitPlanId={unitPlanId}
              isCurriculumProgramFree={isCurriculumProgramFree}
              location={location}
              curriculumType={curriculumType}
              assessmentTool={_.get(assessmentDetails, "assessmentTool")}
              assessmentType={assessmentType}
              assessmentId={assessmentId}
            />
          )}

          {/* <div className={rightPaneStyle}>
            {this.state.currentRightPane == "comment" ? (
              <CommentBox
                parentType={"ASSESSMENT"}
                nodeId={assessmentId}
                type={"ASSESSMENT"}
                headerStyle={{ border: "none" }}
                feedStyle={{ paddingTop: 0 }}
                commentBoxContainerStyle={{ minHeight: 70 }}
                onClose={this.onCommentClick}
              />
            ) : null}
            {this.state.currentRightPane == "details" ? (
              <PypElementDetails
                grades={grades}
                unitPlanFields={unitPlanFields}
                subjects={subjects}
                pypTemplateElements={pypTemplateElements}
                elements={pypElementDetails}
                onClose={this.onDetailsClick}
              />
            ) : null}
          </div> */}
          {showDeleteDialogueBox && (
            <DialogueBox
              modalTitle={t("discard_changes")}
              showModal={true}
              onClickButton2={this.onConfirmClose}
              onClickButton1={this.onConfirmSave}
              modalBody={t("exit_without_save_prompt")}
              toggleDialogueBoxDisplay={this.toggleDeleteDialogueBoxDisplay}
              button1={t("save_exit")}
              button2={t("discard_exit")}
            />
          )}
        </div>
      )
    );
  }
}

const AssessmentDetailsLoader = withLoader(AssessmentDetails);

const AssessmentDetailsContainer = props => {
  const { params: { assessmentId } = {} } = props;
  const { renderAsComponent } = props;
  return (
    <div
      className={
        renderAsComponent ? classes.container : classes.overlayContainer
      }
    >
      <AssessmentDetailsLoader {...props} key={assessmentId} />
    </div>
  );
};

const setPusherData = ({ assessmentId, organizationId, userId }) => {
  return {
    assessmentId,
    organizationId,
    userId,
  };
};

const setPusherDataMemoize = _.memoize(
  params => setPusherData(params),
  params => JSON.stringify(params)
);

const getPlannerElementTypes = ({ curriculumType }) => {
  switch (curriculumType) {
    case CURRICULUM_TYPE_DP:
      return ["DP_PEDAGOGICAL_APPROACH"];
    default:
      return [];
  }
};

const mapActionCreators = {
  updateAssessmentInRedux,
  updateUnitPlanInRedux,
  deleteAssessment,
  updateNavigationObject,
  getPrintFile,
  updateAssessmentInCache,
  clearAssessmentTool,
  setToastMsg,
  onClose,
  onClickEdit,
  getPlannerFieldsOfNode,
  isUnitPlanEditPermission,
  goToRelativeRoute,
  showNotEditableLePlanToast,
  trackEntityPreview,
  addApiEvent,
};

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;

  const userInfo = getUserInfo({ portalType: ownProps.portalType }) || {};
  const curriculumProgramId = _.get(ownProps, "params.curriculumProgramId", "");

  const curriculumType = state.platform.currentCurriculumProgramType;
  const organizationId = userInfo.org_id;
  const assessmentId =
    ownProps.assessmentId || _.get(ownProps, "params.assessmentId");
  const userId = userInfo.id;
  const isCommunity = state.login.activeTab == "community";
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );
  const userType = state.login.userInfo.user_type;

  const tabConfigurations = { admin: { curriculumProgramId } };
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });
  const curriculum = _.find(state.platform.organizationCurriculumPrograms, {
    type: curriculumType,
  });
  const assessmentType = _.get(state, "assessment.filters.groupType", "");
  const isDPLE =
    _.isEqual(curriculumType, CURRICULUM_TYPE_DP) &&
    _.isEqual(assessmentType, "LEARNING_ENGAGEMENT");
  const plannerElementTypes = getPlannerElementTypes({ curriculumType });
  return {
    plannerElementSets,
    curriculumType,
    isCommunity,
    isData: true,
    isLoading: false,
    organizationId: userInfo.org_id,
    mode: ownProps.mode || _.get(ownProps, "params.mode"),
    assessmentId,
    pusherData: setPusherDataMemoize({
      assessmentId,
      organizationId,
      userId,
    }),
    isCurriculumProgramFree,
    showAssessmentCommentBox:
      state.navigation.navigationObject.showAssessmentCommentBox,
    userType,
    shouldCheckPermission: !isCommunity,
    perm: ["TeacherPortal", SNPv2_VISITOR_PORTAL_PERM_GROUP],
    showErrorComp: true,
    errorText: t("common:sorry_you_dont_have_access_to_this_page"),
    isDPLE,
    plannerElementTypes,
    curriculumNodeInterfaceType: _.get(curriculum, "nodeInterfaceType", ""),
    curriculumId: _.get(curriculum, "id", ""),
  };
};

const getTemplateArrayMemoize = _.memoize(
  params => getTemplateArray(params),
  params => JSON.stringify(params)
);

const getTemplateArray = params => {
  return _.filter(params, param => !_.isEmpty(param));
};

const updateStepList = ({ step_list, fieldsToRemove }) => {
  return _.forEach(step_list, (step, key) => {
    step_list[key] = {
      ...step,
      fields: _.filter(
        _.get(step, "fields", []),
        field => !_.includes(fieldsToRemove, field)
      ),
    };
  });
};

const getDynamicTemplate = ({
  template,
  resourceFields,
  unitType,
  t,
  curriculumType,
  plannerElementSets,
  unitPlanTemplate,
  organizationId,
  subjects,
}) => {
  const dynamicTemplate = _.cloneDeep(template);
  const step_list = dynamicTemplate["step_list"];
  const field_list = dynamicTemplate["field_list"];
  if (_.includes(["UBD"], curriculumType) && !_.isEmpty(plannerElementSets)) {
    _.forEach(field_list, (field, key) => {
      if (_.get(field, "config.type", "") == "PLANNER_ELEMENT") {
        field_list[key] = {
          ...field,
          skipLocalisation: true,
          config: {
            ...field.config,
            label: field.config?.labelLocale
              ? t(field.config.labelLocale, {
                  label:
                    plannerElementSets[
                      _.get(field, "config.plannerElementConfig.type", "")
                    ]?.label,
                })
              : "",
            subtext: field.config?.subTextLocale
              ? t(field.config.subTextLocale, {
                  label:
                    plannerElementSets[
                      _.get(field, "config.plannerElementConfig.type", "")
                    ]?.label,
                })
              : "",
            viewLabel: field.config?.viewLabelLocale
              ? t(field.config.viewLabelLocale, {
                  label:
                    plannerElementSets[
                      _.get(field, "config.plannerElementConfig.type", "")
                    ]?.label,
                })
              : "",
            emptyText: field.config?.emptyTextLocale
              ? t(field.config.emptyTextLocale, {
                  label:
                    plannerElementSets[
                      _.get(field, "config.plannerElementConfig.type", "")
                    ]?.label,
                })
              : "",
            emptyViewText: field.config?.emptyViewTextLocale
              ? t(field.config.emptyViewTextLocale, {
                  label:
                    plannerElementSets[
                      _.get(field, "config.plannerElementConfig.type", "")
                    ]?.label,
                })
              : "",
            fieldTypeConfig: {
              ...field.config.fieldTypeConfig,
              plannerLabel:
                plannerElementSets[
                  _.get(field, "config.plannerElementConfig.type", "")
                ]?.label,
              emptyText: field.config?.fieldTypeConfig?.emptyTextLocale
                ? t(field.config.fieldTypeConfig.emptyTextLocale, {
                    label:
                      plannerElementSets[
                        _.get(field, "config.plannerElementConfig.type", "")
                      ]?.label,
                  })
                : "",
              emptyParentNodeText: field.config?.fieldTypeConfig
                ?.emptyParentNodeTextLocale
                ? t(field.config.fieldTypeConfig.emptyParentNodeTextLocale, {
                    label:
                      plannerElementSets[
                        _.get(field, "config.plannerElementConfig.type", "")
                      ]?.label,
                  })
                : "",
            },
          },
        };
      }
    });
  }

  if (!_.isEmpty(template)) {
    const tool = _.get(
      _.find(resourceFields, { uid: "measureAssessing" }),
      "value",
      null
    );
    const dynamicStep = step_list["assessmentTool"];
    if (dynamicStep && !!tool) {
      dynamicStep["fields"] = [
        ...dynamicStep["fields"],
        _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", ""),
      ];
      dynamicStep["isMaxium"] = true;
    }

    const fieldsArr = _.get(step_list, "assessmentTool.fields");

    if (_.includes(fieldsArr, "score")) {
      dynamicStep["isMaxium"] = true;
    }
    /**
     * This is condition for edit assessment task in classroom of UBD curriculum.
     * The assessment type of UBD comes from unit plan.
     * If unit plan reference is not there we filter assessmentType field from step list
     * */

    if (_.isEmpty(unitPlanTemplate)) {
      _.forEach(step_list, step => {
        if (_.includes(step.fields, "assessmentType")) {
          step.fields = _.filter(
            step.fields,
            field => field != "assessmentType"
          );
        }
      });
    }

    /* 
       If featureFlag is off for score based assessment or score is disabled from the admin end, then only we will check the conditions
       if score field is there, then remove subjects from s1 and basicV2
    */
    const scorePermission = getCurriculumBasedScorePermissionMemoize({
      curriculumType,
    });

    const isScoreEnabledFromAdmin = checkIsScoreEnabledFromAdmin({
      organizationId,
      curriculumType,
    });

    if (
      unitType == UNIT_TYPE_DP &&
      _.get(_.first(subjects), "type", SUBJECT_TYPE_GENERIC) !==
        SUBJECT_TYPE_GENERIC
    ) {
      updateStepList({
        step_list,
        fieldsToRemove: ["subjectLevel"],
      });
    }

    if (!ACLStore.can(scorePermission) || !isScoreEnabledFromAdmin) {
      const hasScoreInFieldList = _.has(field_list, "score");

      //Subjects should not be removed for ibMYPInterdisciplinary, because it was there in the past
      if (unitType !== "ibMYPInterdisciplinary" && hasScoreInFieldList) {
        updateStepList({
          step_list,
          fieldsToRemove: ["subjects"],
        });
      }
    }

    // Keep subjects for interdisciplinary or transdisciplinary unit plans
    if (
      !_.includes(
        ["ibInquiry", "ibMYPInterdisciplinary", "ubdInquiry"],
        unitType
      )
    ) {
      _.forEach(step_list, step => {
        if (_.includes(step.fields, "subjects")) {
          step.fields = _.filter(step.fields, field => field != "subjects");
        }
      });

      //Remove MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC Option for non MYP Interdisciplinary Unit plan
      if (field_list["measureAssessing"]) {
        const index = _.findIndex(
          _.get(field_list["measureAssessing"], "config.options", []),
          { value: "MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC" }
        );
        if (index >= 0) {
          field_list["measureAssessing"] = update(
            field_list["measureAssessing"],
            { config: { options: { $splice: [[index, 1]] } } }
          );
        }
      }
    }
  }

  const localisedTemplate = getLocalisedTemplate({
    template: { body: dynamicTemplate },
    t,
    templateType: "le",
  });
  return _.get(localisedTemplate, "body", {});
};

export default compose(
  I18nHOC({ resource: ["common", "unitPlanTemplate"] }),
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  graphql(getUnitPlanDetailsQuery, {
    name: "getUnitPlanDetails",
    skip: ({ unitPlanId }) => !unitPlanId,
    options: ({ unitPlanId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: unitPlanId,
      },
    }),
    props({ getUnitPlanDetails, ownProps: { unitPlanId } }) {
      const unitDetails = _.get(getUnitPlanDetails, "node", {});
      const unitPlanName = _.get(unitDetails, "title.value", "");
      return {
        unitPlanName,
        isData: !_.isEmpty(unitDetails),
        networkStatus: getUnitPlanDetails.networkStatus,
        isLoading:
          getUnitPlanDetails["networkStatus"] == 1 ||
          getUnitPlanDetails["networkStatus"] == 2,
      };
    },
  }),
  graphql(getAssessmentQuery, {
    name: "getAssessment",
    options: ({ assessmentId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: assessmentId, portalType },
    }),
    props({
      getAssessment,
      ownProps: {
        assessmentId,
        isData,
        isLoading,
        getPlannerFieldsOfNode,
        unitPlanId: ownPropsUnitPlanId,
        sourceFrom,
      },
    }) {
      const assessmentDetails = _.get(getAssessment, "node", {});
      const templateId = _.get(assessmentDetails, "templateId", null);
      const presetAssessment = _.get(assessmentDetails, "presetAssessment", {});
      const assessmentObj = getAssessmentObj(
        sourceFrom,
        presetAssessment,
        assessmentDetails
      );
      const unitPlanId = _.get(
        assessmentObj,
        "unitPlan.id",
        ownPropsUnitPlanId
      );
      const grades = _.get(assessmentDetails, "unitPlan.grades", "");
      const unitPlanFields = _.get(assessmentObj, "unitPlan.allFields", []);
      const enabledSections = _.get(assessmentDetails, "enabledSections", []);
      const assessmentType = _.get(
        assessmentDetails,
        "assessmentType.value",
        ""
      );
      const unitPlanTemplateId = _.get(
        assessmentDetails,
        "unitPlan.templateId",
        null
      );
      const subjects = _.get(
        _.find(unitPlanFields, { uid: "subjects" }),
        "resolvedMinimalTree.subjects",
        []
      );

      const unitType = _.get(
        _.find(unitPlanFields, { uid: "unitType" }),
        "value",
        ""
      );

      const curriculumType = _.get(
        assessmentDetails,
        "curriculumProgram.type",
        "IB_PYP"
      );

      return {
        curriculumType,
        unitPlanFields,
        assessmentDetails,
        subjects,
        unitPlanTemplateId,
        unitPlanId,
        grades,
        templateId,
        enabledSections,
        assessmentType,
        assessmentFields: _.get(assessmentDetails, "allFields", []),
        isData: !_.isEmpty(assessmentDetails) && isData,
        isLoading:
          getAssessment["networkStatus"] == 1 ||
          getAssessment["networkStatus"] == 2 ||
          isLoading,
        unitType,
      };
    },
  }),

  graphql(getPlannerTemplateQuery, {
    name: "getPlannerTemplate",
    skip: ({ templateId, unitPlanTemplateId, mode }) => !templateId,
    options: ({ templateId, unitPlanTemplateId, mode, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        portalType,
        ids:
          mode == "view"
            ? getTemplateArrayMemoize([templateId])
            : getTemplateArrayMemoize([templateId, unitPlanTemplateId]),
      },
    }),
    props({
      getPlannerTemplate,
      ownProps: {
        templateId,
        isLoading,
        isData,
        unitPlanTemplateId,
        assessmentFields,
        mode,
        unitType,
        subjects,
        plannerElementSets,
        curriculumType,
        t,
        organizationId,
      },
    }) {
      const templates = getPlannerTemplateFromCache(
        mode == "view"
          ? getTemplateArrayMemoize([templateId])
          : getTemplateArrayMemoize([templateId, unitPlanTemplateId]),
        true
      );
      const template = _.get(_.find(templates, { id: templateId }), "body", {});

      const unitPlanTemplate = _.get(
        _.find(templates, { id: unitPlanTemplateId }),
        "body",
        {}
      );

      const pypFields = _.get(template, `step_list.pypElements.fields`, []);
      return {
        template: getDynamicTemplate({
          template,
          resourceFields: assessmentFields,
          unitType,
          t,
          plannerElementSets,
          curriculumType,
          unitPlanTemplate,
          organizationId,
          subjects,
        }),
        unitPlanTemplate,

        isData: !_.isEmpty(templates) && isData,

        isLoading:
          getPlannerTemplate["networkStatus"] == 1 ||
          getPlannerTemplate["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  getPlannerElementSetsOfCurriculumEnhancer,
  withPermission,
  withPusherSubscribe(["assessment"]),
  withPresenceChannel("assessment")
)(AssessmentDetailsContainer);
