import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { colors } from "Constants";
import classes from "./ResponsesEdit.scss";
import { generateRandomId } from "Utils";
import {
  editResponse,
  updateLocalResponses,
} from "IBPlanner/modules/IBPlannerModule";
import update from "immutability-helper";
import { graphql, compose } from "react-apollo";
import ResponseItem from "AppComponents/RemarkBox/ResponseItem";
import CreateResponse from "AppComponents/RemarkBox/CreateResponse";
import { AddCircleIcon, ResourcesLineIcon } from "SvgComponents";
import {
  I18nHOC,
  withLoader,
  LockOverlay,
  FullScreenLoader,
  withPusherBind,
} from "UIComponents";
import { getPlannerFieldResponsesQuery } from "UnitPlans/modules/UnitPlanQuery";
import { getPlannerFieldResponsesFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import { NoSearchResultsIllustration } from "@toddle-design/theme";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";
import AddEvidenceModalWrapper from "./AddEvidenceModalWrapper";
import { Button } from "@toddle-design/web";
import { AddCircleOutlined } from "@toddle-design/web-icons";
import _ from "lodash";

const getResponseInputMemoized = _.memoize(
  params => ({
    id: params.id,
    type: params.type,
    uids: [params.fieldKey],
    responseFilter: {
      courseIds: params.selectedCourseIds,
      studentIds: params.studentIds,
    },
  }),
  params => JSON.stringify(params)
);

const getFilteredDropDownOptionsMemoized = _.memoize(
  params =>
    _.filter(DROP_DOWN_OPTIONS, item => {
      if (params.userType != "staff" && item.key == "add_post") {
        return false;
      }
      return (
        !_.isEmpty(params.curriculumType) &&
        !_.includes(item.hideCurriculumTypes, params.curriculumType)
      );
    }),
  params => JSON.stringify(params)
);

const styles = {
  attachmentListContainer: {
    gridTemplateColumns: "repeat(auto-fill, 1fr)",
  },
};

const DROP_DOWN_OPTIONS = [
  {
    key: "upload_device",
    label: "common:upload_device",
    hideCurriculumTypes: [],
  },
  {
    key: "add_link",
    label: "common:add_link",
    hideCurriculumTypes: [],
  },
  {
    key: "add_google_drive",
    label: "common:add_google_drive",
    hideCurriculumTypes: [],
  },
  {
    key: "add_one_drive",
    label: "common:add_one_drive",
    hideCurriculumTypes: [],
  },
  {
    key: "add_note",
    label: "common:add_note",
    hideCurriculumTypes: [],
  },
  {
    key: "add_post",
    label: "common:add_post",
    hideCurriculumTypes: [CURRICULUM_TYPE_DP],
    icon: <ResourcesLineIcon width={16} height={16} fill={colors.gray48} />,
  },
];

class ResponsesEdit extends PureComponent {
  constructor(props) {
    super(props);

    if (props.customRef) {
      props.customRef(this);
    }

    this.state = {
      isFocused: false,
      isEditingResponse: false,
      responseFieldActive: false,
      isFullScreenLoading: false,
      showEvidenceSelectionModal: false,
    };

    this.createDropDownRef = null;
    this.createResponseRef = null;
    this.createContainerRef = null;
    this.responseContainerRef = null;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleBodyClick, false);
  }

  componentWillUnmount() {
    const { localResponsesObject, fieldId } = this.props;
    const { isEditingResponse } = this.state;
    const responseValue = _.trim(
      _.get(localResponsesObject, `${fieldId}.message`, "")
    );
    const selectedEvidence = _.get(
      localResponsesObject,
      `${fieldId}.evidence`,
      []
    );

    if (responseValue || selectedEvidence.length > 0) {
      if (isEditingResponse) {
        this.onSaveEditClicked();
      } else {
        this.onSubmitClick();
      }
    }
    document.removeEventListener("click", this.handleBodyClick);
    this.emptyLocalObjectInRedux();
  }

  // TODO Need to comment what is handled in this function
  componentDidUpdate = (prevProps, prevState) => {
    const { isEditingResponse, showEvidenceSelectionModal } = this.state;

    if (prevState.showEvidenceSelectionModal != showEvidenceSelectionModal) {
      if (showEvidenceSelectionModal) {
        document.removeEventListener("click", this.handleBodyClick, false);
      } else {
        document.addEventListener("click", this.handleBodyClick);
      }
    }

    if (isEditingResponse) {
      const {
        fieldId: prevFieldId,
        localResponsesObject: prevLocalResponsesObject,
      } = prevProps;

      const {
        fieldId,
        localResponsesObject,
        updateInputFieldLocally,
      } = this.props;
      const responseObj = localResponsesObject[fieldId] || {};
      const prevResponseObj = prevLocalResponsesObject[fieldId] || {};

      if (
        isEditingResponse &&
        prevFieldId == fieldId &&
        prevResponseObj != responseObj
      ) {
        if (updateInputFieldLocally) {
          updateInputFieldLocally(responseObj);
        }
      }
    }
  };

  /**
   * This function handles the response box activity when user clicks anywhere.
   * It keeps the box in focus and open when there is present some content.
   * Also it handles the cases when the create dropdown is being used actively
   */
  handleBodyClick = event => {
    const { localResponsesObject, fieldId } = this.props;
    const { isFocused, isEditingResponse, responseFieldActive } = this.state;

    if (
      !_.isNull(this.createDropDownRef) &&
      this.createDropDownRef.isActive()
    ) {
      return false;
    }

    if (
      !isEditingResponse &&
      this.createContainerRef &&
      !this.createContainerRef.contains(event.target)
    ) {
      const responseValue = _.trim(
        _.get(localResponsesObject, `${fieldId}.message`, "")
      );

      const selectedEvidence = _.get(
        localResponsesObject,
        `${fieldId}.evidence`,
        []
      );

      /*  to prevent updating isFocused to false when DriveShare modal is open:
      get the classnames of elements from where the event has passed and 
      check if any of that element is DriveShareModal*/

      const targetClassnames = _.map(event.path, path => path.className);
      const isDriveShareModalOpen = _.reduce(
        targetClassnames,
        (res, curr) => {
          res = res || _.includes(curr, "DriveShareModal");
          return res;
        },
        false
      );

      if (isFocused && !responseFieldActive) {
        this.setState({
          isFocused:
            !!responseValue ||
            selectedEvidence.length > 0 ||
            isDriveShareModalOpen,
        });
      }
    }
  };

  blur = ({ field }) => {
    const { localResponsesObject, fieldId } = this.props;
    const responseId = localResponsesObject[fieldId].id;

    if (responseId == _.trim(field.id, "Reflection:")) {
      this.onCancelEdit();
    }
  };

  updateCreateDropDownRef = ref => {
    this.createDropDownRef = ref;
  };

  getResponses = () => {
    const {
      mode,
      fieldId,
      userInfo,
      responses,
      showEvidence,
      onMediaClick,
      fieldLockedObject,
      localResponsesObject,
      filteredDropDownOptions,
    } = this.props;

    const { lockedDynamicFields } = fieldLockedObject;
    const localResponseObj = localResponsesObject[fieldId];

    const responseFeed = _.map(responses, response => {
      const isEditingResponse =
        localResponseObj && _.isEqual(localResponseObj.id, response.id);
      const lockedField = _.find(lockedDynamicFields, {
        id: `Reflection:${response.id}`,
      });

      return (
        <div key={response.id} className={classes.responseItemContainer}>
          <ResponseItem
            mode={mode}
            response={response}
            lockByAuthor={true}
            userInfo={userInfo}
            showEvidence={showEvidence}
            onMediaClick={onMediaClick}
            isActive={isEditingResponse}
            onClickEdit={this.onClickEdit}
            onCancelEdit={this.onCancelEdit}
            onClickDelete={this.onClickDelete}
            localResponseObj={localResponseObj}
            onResponseChange={this.onResponseChange}
            onSaveEditClicked={this.onSaveEditClicked}
            dropDownOptions={filteredDropDownOptions}
            onContentUploaded={this.onContentUploaded}
            onDeleteEvidenceClick={this.onDeleteEvidenceClick}
            onDropDownOptionClick={this.onDropDownOptionClick}
            createDropDownRef={this.updateCreateDropDownRef}
            attachmentListContainerStyle={styles.attachmentListContainer}
          />
          {lockedField && (
            <LockOverlay userInfo={_.get(lockedField, "userInfo", {})} />
          )}
        </div>
      );
    });

    return responseFeed;
  };

  /**
   * This function extracts ids from the evidence. It provides the values for
   * both initial and passed uploaded attachments or post. Uploaded
   * attachments don't have attachment property whereas post has.
   */
  getFilteredIdsFromEvidence = ({ evidence, responseId, isPost }) => {
    const { responses } = this.props;
    const response = _.find(responses, { id: responseId });
    const currentEvidence = evidence || response.evidence;

    let filteredEvidence = [];

    if (isPost) {
      filteredEvidence = _.filter(currentEvidence, item =>
        _.has(item, "attachments")
      );
    } else {
      filteredEvidence = _.filter(
        currentEvidence,
        item => !_.has(item, "attachments")
      );
    }

    return _.map(filteredEvidence, item => item.id);
  };

  onSaveEditClicked = async () => {
    const { fieldId, editResponse, localResponsesObject } = this.props;
    const responseObject = localResponsesObject[fieldId];

    try {
      this.setState({ isFullScreenLoading: true });

      if (
        _.trim(responseObject.message) ||
        !_.isEmpty(responseObject.evidence)
      ) {
        const responseId = responseObject.id;

        const initialPostIds = this.getFilteredIdsFromEvidence({
          responseId,
          isPost: true,
        });
        const initialAttachmentIds = this.getFilteredIdsFromEvidence({
          responseId,
          isPost: false,
        });

        //if there is content we update
        await editResponse({
          fieldId,
          id: responseId,
          initialPostIds,
          initialAttachmentIds,
        });
      } else {
        //no content we delete the response
        this.onClickDelete({ id: responseObject.id });
      }

      this.onCancelEdit();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  onResponseChange = value => {
    const { updateLocalResponses, fieldId, localResponsesObject } = this.props;
    const responseObject = localResponsesObject[fieldId] || {};

    updateLocalResponses({
      [fieldId]: update(responseObject, {
        message: { $set: value },
      }),
    });
  };

  onSubmitClick = async () => {
    const {
      fieldId,
      createResponse,
      updateLocalResponses,
      getPlannerFieldResponses,
    } = this.props;

    try {
      this.setState({ isFullScreenLoading: true });

      await createResponse({ fieldId });
      updateLocalResponses({ [fieldId]: null });
      if (getPlannerFieldResponses) {
        await getPlannerFieldResponses.refetch();
      }

      this.setState({ isFocused: false });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  onClickEdit = response => {
    const { fieldId, updateLocalResponses, onFocusInputField } = this.props;

    this.setState({
      isEditingResponse: true,
    });

    updateLocalResponses({
      [fieldId]: _.cloneDeep(response),
    });

    onFocusInputField({ others: { id: `Reflection:${response.id}` } });
  };

  onClickDelete = async ({ id }) => {
    const { fieldId, deleteResponse, getPlannerFieldResponses } = this.props;
    const { isEditingResponse } = this.state;

    if (isEditingResponse) {
      this.onCancelEdit();
    }

    try {
      this.setState({ isFullScreenLoading: true });

      await deleteResponse({ id, fieldId });
      if (getPlannerFieldResponses) {
        await getPlannerFieldResponses.refetch();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  emptyLocalObjectInRedux = () => {
    const { fieldId, updateLocalResponses } = this.props;

    updateLocalResponses({
      [fieldId]: {},
    });
  };

  onCancelEdit = () => {
    const { isEditingResponse, isFocused } = this.state;
    const { onBlurInputField, localResponsesObject, fieldId } = this.props;

    if (isEditingResponse) {
      this.setState({
        isEditingResponse: false,
      });
      onBlurInputField({ others: { id: localResponsesObject[fieldId].id } });
    }
    if (isFocused) {
      this.setState({ isFocused: false });
    }

    this.emptyLocalObjectInRedux();
  };

  onFocusCreate = () => {
    const { isEditingResponse } = this.state;

    if (isEditingResponse) {
      this.onCancelEdit();
      this.setState({
        isFocused: true,
        responseFieldActive: true,
        isEditingResponse: false,
      });
    } else {
      this.setState({ isFocused: true, responseFieldActive: true });
    }
  };

  onBlurCreate = () => {
    this.setState({ responseFieldActive: false });
  };

  onModalClose = () => {
    this.setState({ showEvidenceSelectionModal: false });
  };

  onAddEvidenceClick = e => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ showEvidenceSelectionModal: true });
  };

  onDropDownOptionClick = ({ key }) => {
    switch (key) {
      case "add_post":
        this.setState({ showEvidenceSelectionModal: true });
        break;
    }
  };

  onContentUploaded = params => {
    const { updateLocalResponses, fieldId, localResponsesObject } = this.props;
    const responseObject = localResponsesObject[fieldId] || {};

    const updatedParams = _.map(params, item => {
      return {
        ...item,
        id: generateRandomId(),
        isPost: false,
      };
    });

    updateLocalResponses({
      [fieldId]: update(responseObject, {
        evidence: evidence => update(evidence || [], { $push: updatedParams }),
      }),
    });
  };

  onDeleteEvidenceClick = ({ id }) => {
    const { updateLocalResponses, fieldId, localResponsesObject } = this.props;
    const responseObject = localResponsesObject[fieldId] || {};

    updateLocalResponses({
      [fieldId]: update(responseObject, {
        evidence: {
          $set: _.filter(
            responseObject["evidence"],
            item => !_.isEqual(item.id, id)
          ),
        },
      }),
    });
  };

  updateCreateContainerRef = ref => {
    this.createContainerRef = ref;
  };

  updateCreateResponseRef = ref => {
    this.createResponseRef = ref;
  };

  getCreateContainer = isCreatingResponse => {
    const {
      fieldId,
      userInfo,
      onMediaClick,
      showEvidence,
      localResponsesObject,
      filteredDropDownOptions,
    } = this.props;

    const localResponseObj = localResponsesObject[fieldId] || {};

    return (
      <div
        className={classes.responseItemContainer}
        ref={this.updateCreateContainerRef}
      >
        <CreateResponse
          userInfo={userInfo}
          showEvidence={showEvidence}
          onMediaClick={onMediaClick}
          onClickCancel={this.onCancelEdit}
          isActive={isCreatingResponse}
          onBlurCreate={this.onBlurCreate}
          onFocusCreate={this.onFocusCreate}
          onSubmitClick={this.onSubmitClick}
          localResponseObj={localResponseObj}
          onResponseChange={this.onResponseChange}
          dropDownOptions={filteredDropDownOptions}
          onContentUploaded={this.onContentUploaded}
          customRef={this.updateCreateResponseRef}
          createDropDownRef={this.updateCreateDropDownRef}
          onDeleteEvidenceClick={this.onDeleteEvidenceClick}
          onDropDownOptionClick={this.onDropDownOptionClick}
          attachmentListContainerStyle={styles.attachmentListContainer}
        />
      </div>
    );
  };

  getEmptyContainer = () => {
    const { coachMarkId, t } = this.props;
    return (
      <div className={classes.emptyContainer} id={coachMarkId}>
        <Button
          variant={"inline"}
          icon={<AddCircleOutlined />}
          onClick={this.onFocusCreate}
          size={"small"}
        >
          {t("addOngoingReflection")}
        </Button>
      </div>
    );
  };

  noDataComponent = () => {
    const {
      userInfo: { childName },
      t,
    } = this.props;
    return (
      <div className={classes.noDataContainer}>
        <img src={NoSearchResultsIllustration}></img>
        {t("unitPlan:no_reflection_added_with_username", {
          username: childName,
        })}
      </div>
    );
  };

  render() {
    const {
      options,
      fieldId,
      responses,
      isReflectionField,
      userType,
      curriculumType,
    } = this.props;
    const {
      isFocused,
      isEditingResponse,
      isFullScreenLoading,
      showEvidenceSelectionModal,
    } = this.state;
    const isCreatingResponse = isFocused && !isEditingResponse;
    const areResponsesLoaded = _.size(responses) > 0;
    const showCreateResponseBox =
      (isReflectionField || isCreatingResponse) &&
      !_.isEqual(userType, "parent");
    const showEmptyScreen = userType == "parent" && _.isEmpty(responses);

    return (
      <div
        className={classes.container}
        ref={ref => (this.responseContainerRef = ref)}
      >
        {isFullScreenLoading && <FullScreenLoader />}

        {showEmptyScreen && this.noDataComponent()}

        {(showCreateResponseBox || areResponsesLoaded) && (
          <div className={classes.responseContainer}>
            {areResponsesLoaded && this.getResponses()}
            {showCreateResponseBox &&
              this.getCreateContainer(isCreatingResponse)}
          </div>
        )}

        {!isReflectionField && !isCreatingResponse && this.getEmptyContainer()}

        {showEvidenceSelectionModal && (
          <AddEvidenceModalWrapper
            fieldId={fieldId}
            onRequestClose={this.onModalClose}
            curriculumType={curriculumType}
          />
        )}
      </div>
    );
  }
}

const mapActionCreatorsToProps = {
  editResponse,
  updateLocalResponses,
};

const mapStateToProps = (state, ownProps) => {
  const { fieldKey, parentId, curriculumType, selectedCourseIds } = ownProps;
  const { userInfo } = state.login;
  const userType = userInfo.user_type;
  const studentIds = [];
  if (userType != "staff") {
    userType == "student"
      ? studentIds.push(userInfo.id)
      : studentIds.push(userInfo.childID);
  }
  const responseInput = getResponseInputMemoized({
    fieldKey,
    id: parentId,
    type: "UNIT_PLAN",
    selectedCourseIds,
    studentIds,
  });

  const filteredDropDownOptions = getFilteredDropDownOptionsMemoized({
    curriculumType,
    userType,
  });

  return {
    isData: true,
    isLoading: false,
    responseInput,
    filteredDropDownOptions,
    userType,
    userInfo,
  };
};

const ResponsesEditWrapper = compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, mapActionCreatorsToProps),
  graphql(getPlannerFieldResponsesQuery, {
    skip: ({ showCourseFilter, userType }) =>
      userType == "staff" && !showCourseFilter,
    name: "getPlannerFieldResponses",
    options: ({ responseInput }) => ({
      fetchPolicy: "cache-and-network",
      variables: responseInput,
    }),
    props({
      getPlannerFieldResponses,
      ownProps: { fieldId, fieldKey, responseInput, isData, isLoading },
    }) {
      const data = getPlannerFieldResponsesFromCache(responseInput);

      const fieldData = _.find(data.fields, { id: fieldId });
      const responses = _.get(fieldData, "responses", []);
      const responseNodes = _.map(
        _.get(responses, "edges", []),
        _.iteratee("node")
      );

      return {
        responses: responseNodes,
        isData: !_.isEmpty(data) && isData,
        getPlannerFieldResponses,
        onPusherEventTriggered: params => {
          if (_.includes(params.fields, fieldKey)) {
            getPlannerFieldResponses.refetch();
          }
        },
        isLoading:
          getPlannerFieldResponses["networkStatus"] == 1 ||
          getPlannerFieldResponses["networkStatus"] == 2 ||
          getPlannerFieldResponses["networkStatus"] == 4 ||
          isLoading,
      };
    },
  }),
  withPusherBind(["unitPlanFieldUpdate"]),
  withLoader
)(ResponsesEdit);

ResponsesEditWrapper.defaultProps = {
  showEvidence: true,
};

export default ResponsesEditWrapper;
