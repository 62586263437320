import React from "react";
import classes from "./SwitcherComponent.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import FieldComponent from "../FieldComponent";
import {
  EmptyField,
  I18nHOC,
  RadioButtonList,
  NotAddedEmptyView,
} from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import classNames from "classnames";

const styles = {
  editContainerStyle: {
    marginBottom: "16px",
  },
  listContainerStyle: {
    columnGap: "24px",
  },
  listItemStyle: {
    flexBasis: "unset",
  },
};

class SwitcherComponent extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const { updateInputField, fieldKey, value, switcherFields } = this.props;
    const currentOption = _.first(switcherFields);
    if (_.isNull(value)) {
      const params = {
        [fieldKey]: {
          selectedField: currentOption.key,
          fieldValues: _.reduce(
            switcherFields,
            (result, value) => {
              result[value.key] = null;
              return result;
            },
            {}
          ),
        },
      };
      updateInputField(params);
    }
  };

  updateFieldValues = ({ params }) => {
    const { updateInputField, fieldKey, value } = this.props;

    const data = params[fieldKey];

    const { selectedField, fieldValues } = value;

    const newData = { [selectedField]: data };

    params[fieldKey] = {
      ...value,
      fieldValues: {
        ...fieldValues,
        ...newData,
      },
    };

    updateInputField(params);
  };

  updateRadioValue = optionValue => {
    const { updateInputField, fieldKey, value } = this.props;

    const params = {
      [fieldKey]: {
        ...value,
        selectedField: optionValue[fieldKey],
      },
    };

    updateInputField(params);
  };

  renderEditEmpty = () => {
    const { emptyText, emptyContainerStyle } = this.props;
    return (
      !!emptyText && (
        <EmptyField containerStyle={emptyContainerStyle} title={emptyText} />
      )
    );
  };

  renderEdit = () => {
    const {
      childMode,
      onFocusInputField,
      onBlurInputField,
      value,
      fieldKey,
      switcherFields,
      disabled,
      t,
    } = this.props;

    const currentOption = _.find(switcherFields, {
      key: _.get(value, "selectedField", ""),
    });
    const type = _.get(currentOption, "type", "RichText");

    const fieldConfig = _.get(currentOption, "fieldConfig", {});
    const fieldValue = _.get(
      value,
      `fieldValues.${value?.selectedField}`,
      null
    );

    const options = _.map(switcherFields, option => {
      const { localeLabelKey = "", label = "" } = option;

      return {
        ...option,
        value: option.key,
        label: localeLabelKey ? t(localeLabelKey) : label,
      };
    });

    return (
      <div className={classes.container}>
        <RadioButtonList
          options={options}
          value={_.get(value, "selectedField", "")}
          name={fieldKey}
          updateInputField={this.updateRadioValue}
          editContainerStyle={styles.editContainerStyle}
          listContainerStyle={styles.listContainerStyle}
          listItemStyle={styles.listItemStyle}
        />
        <div>
          <FieldComponent
            {...fieldConfig}
            value={fieldValue}
            key={fieldKey}
            customRef={this.setFieldRef}
            onFocusInputField={onFocusInputField}
            onBlurInputField={onBlurInputField}
            type={type}
            fieldKey={fieldKey}
            updateInputField={this.updateFieldValues}
            mode={childMode}
            styles={styles}
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  renderView = () => {
    const {
      childMode,
      onFocusInputField,
      onBlurInputField,
      value,
      fieldKey,
      switcherFields,
      disabled,
    } = this.props;

    const fieldValue = _.get(
      value,
      `fieldValues.${value?.selectedField}`,
      null
    );

    const currentOption = _.find(switcherFields, {
      key: _.get(value, "selectedField", ""),
    });
    const type = _.get(currentOption, "type", "RichText");
    const fieldConfig = _.get(currentOption, "fieldConfig", {});

    return (
      <div className={classes.container}>
        <FieldComponent
          {...fieldConfig}
          value={fieldValue}
          key={fieldKey}
          customRef={this.setFieldRef}
          onFocusInputField={onFocusInputField}
          onBlurInputField={onBlurInputField}
          type={type}
          fieldKey={fieldKey}
          updateInputField={this.updateFieldValues}
          mode={childMode}
          styles={styles}
          disabled={disabled}
        />
      </div>
    );
  };

  renderViewEmpty = () => {
    const { inputStyle = {} } = this.props;

    return <NotAddedEmptyView inputStyle={inputStyle} />;
  };

  shouldShowViewEmpty = () => {
    const { value } = this.props;
    const fieldValue = _.get(
      value,
      `fieldValues.${value?.selectedField}`,
      null
    );

    return _.isEmpty(fieldValue);
  };
}

const mapStateToProps = (state, ownProps) => {
  //this is the mode of SwitcherComponent
  //This is used to render view and edit modes of switcher component as a whole
  //This wont effect the mode of child components

  const mode = _.get(ownProps, "disabled", false) ? "view" : ownProps?.mode;

  //Mode coming from ownProps will be passed as the same to the child components.
  const childMode = _.get(ownProps, "mode", "edit");

  return {
    mode,
    childMode,
  };
};

SwitcherComponent.defaultProps = {
  ...UIBaseComponent.defaultProps,
  containerStyle: {},
};

SwitcherComponent.propTypes = {
  ...UIBaseComponent.propTypes,
};

export default compose(
  I18nHOC({ resource: ["projectTemplate", "project", "common"] }),
  connect(mapStateToProps, null)
)(SwitcherComponent);
