import React from "react";
import classes from "./DocumentSelector.scss";
import { DropzoneComponent } from "UIComponents";
import { AttachmentItem } from "Attachment";

class DocumentSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showAttachmentError: false, uploadCount: 0 };
  }

  onAttachmentDrop = params => {
    const { updateInputField } = this.props;
    updateInputField({ attachment: params });
    this.setState({ showAttachmentError: false });
  };

  updateFilesCount = (count, isError) => {
    let newCount = this.state.uploadCount;
    newCount = newCount + count;
    const { updateInputField } = this.props;
    if (this.props.updateLoadingState) {
      if (newCount > 0) {
        this.props.updateLoadingState(true);
      } else {
        this.props.updateLoadingState(false);
      }
    }
    this.setState({ uploadCount: newCount });
    if (!!isError) {
      updateInputField({ attachment: {} });
    }
  };

  onContentUploaded = (params = {}) => {
    const { updateInputField } = this.props;
    updateInputField({ attachment: params, url: _.get(params, "url", "") });
  };

  isValid = () => {
    const { attachment = {} } = this.props;

    if (!attachment || _.isEmpty(attachment)) {
      this.setState({ showAttachmentError: true });
      return 1;
    }

    return 0;
  };

  deleteAttachment = () => {
    const { updateInputField } = this.props;
    updateInputField({ attachment: {} });
  };

  render() {
    const {
      instruction,
      attachment,
      progress,
      renderErrorComponent,
      renderContactUsButton,
    } = this.props;
    const { showAttachmentError } = this.state;

    return (
      <div className={classes.dropzoneInputContainer}>
        <div className={classes.dropzoneInstructionContainer}>
          <div className={classes.dropzoneHeaderText}>{`Upload Document*`}</div>
          <div className={classes.instructionHeaderText}>
            {instruction.headerText}
          </div>
          <div className={classes.instructionsContainer}>
            {_.map(instruction.texts, (text, index) => {
              return (
                <div className={classes.instructionContainer} key={index}>
                  <div className={classes.dot}>{`${index + 1}.`}</div>
                  <div className={classes.instructionText}>{text}</div>
                </div>
              );
            })}
          </div>
        </div>

        {_.isEmpty(attachment) ? (
          <React.Fragment>
            <DropzoneComponent
              multiple={false}
              accept={[
                "image/jpeg",
                "image/png",
                "image/jpg",
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "application/x-iwork-numbers-sffnumbers",
                "application/x-iwork-keynote-sffkey",
                "application/x-iwork-pages-sffpages",
              ]}
              parentId={""}
              parentType={"IDENTIFICATION"}
              onDropContent={this.onAttachmentDrop}
              isAuthenticated={false}
              onSingleContentUploaded={this.onContentUploaded}
              maxSize={10485760}
              updateFilesCount={this.updateFilesCount}
              renderComponent={
                <div
                  className={classes.uploadDocumentButton}
                >{`Upload Document`}</div>
              }
            />
            {renderErrorComponent(
              showAttachmentError ? "Please select document" : ""
            )}
          </React.Fragment>
        ) : (
          <div className={classes.attachmentContainer}>
            <AttachmentItem
              attachment={{ ...attachment, progress }}
              mode={"edit"}
              deleteAttachment={this.deleteAttachment}
            />
          </div>
        )}
        <div className={classes.contactUs}>
          {renderContactUsButton({ text: "Chat with us" })}
          {` if you’re having trouble`}
        </div>
      </div>
    );
  }
}

export default DocumentSelector;
