/**--external-- */
import React, { useEffect } from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";

/**--internal-- */
import { getFilterOptionsQuery } from "../../../modules/Queries";
import { getFilterOptionsFromCache } from "../../../modules/GraphqlHelpers";
import {
  getFilterOptionParam,
  getFilterOptions,
  getFilterDisabilityStatusConfig,
  getFilterParam,
  getValidFilterValuesStatus,
} from "../../../modules/Utils";

import { FilterDropdown, FilterList, Level3Filter } from "UIComponents";
import {
  filterSearchStyle,
  filterStyles,
  parentContainerStyle,
} from "./DynamicFilterStyles";
import { useI18n } from "Hooks";

import ButtonComponent from "./ButtonComponent";
import { ELEMENT_TYPE_MYP_OBJECTIVES } from "Constants/stringConstants";

const DynamicFilter = props => {
  const {
    options,
    isLoading,
    isData,
    isFilterVisible,
    containerParentRef,
    values,
    isCheckList,
    label,
    levels,
    entityType,
    parentFiltersData,
    isParent,
    dependentFilterParents,
    buttonDropDownContainerStyle,
    updateLoadingFilters,
    updateInputField,
    updateFilterOptions,
    markDependentFilterAsUpdated,
    parentFilterObj,
  } = props;

  const totalOptions = _.size(options);

  const isDependent = !_.isEmpty(dependentFilterParents);

  useEffect(() => {
    return () => {
      /**Clear pending filters when component is unmounted */
      updateLoadingFilters({ isReady: true, filter: entityType });
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(parentFilterObj) || !isDependent) {
      return;
    }

    const dependentFilters = _.get(parentFilterObj, "dependentFilters", []);

    if (!_.includes(dependentFilters, entityType)) {
      return;
    }

    updateInputField({ values: [], key: entityType });

    markDependentFilterAsUpdated({ filter: entityType });
  }, [parentFilterObj]);

  /**update filter options when old data from cache is available,
   * latest data is available.
   * By updating options this way, user can see options early,
   * if they are available in cache.  */
  useEffect(() => {
    if (!isLoading) {
      const { areValuesUpdated, updatedValues } = getValidFilterValuesStatus({
        options,
        values,
        entityType,
      });

      if (areValuesUpdated) {
        updateInputField({ values: updatedValues, key: entityType, isParent });
      }
    }

    updateFilterOptions({
      filterOptions: options,
      filter: entityType,
    });

    updateLoadingFilters({ filter: entityType, isReady: isData });
  }, [isLoading]);

  const { t } = useI18n(["snp", "common"]);

  /**If this filter is part of more filters, then it will be queried, but it won't
   * be shown unless it is selected. For other filters, they are always visible.
   */
  if (!isFilterVisible) return null;

  const filterDisabilityStatusConfig = getFilterDisabilityStatusConfig({
    parentFiltersData,
    config: dependentFilterParents,
    totalOptions,
    isLoading,
    levels,
  });

  const getAllOptionLabelForFilterList = ({ label1, label2 }) => {
    switch (entityType) {
      case "COURSE": {
        return t("snp:all_label1_label2", {
          label1,
          label2,
        });
      }

      case ELEMENT_TYPE_MYP_OBJECTIVES: {
        return t("common:all_label", { label: label1 });
      }
    }
  };

  if (levels === 1) {
    return (
      <FilterDropdown
        label={label}
        searchBarProps={{
          ...filterSearchStyle,
          placeholder: t("common:search_label", {
            label,
          }),
        }}
        buttonComponent={
          <ButtonComponent
            t={t}
            label={label}
            entityType={entityType}
            values={values}
            options={options}
            filterDisabilityStatusConfig={filterDisabilityStatusConfig}
            containerParentRef={containerParentRef}
          />
        }
        showAllOption={isCheckList}
        isCheckList={isCheckList}
        options={options}
        updateInputField={values =>
          updateInputField({ values, key: entityType, isParent })
        }
        value={values}
        isDisabled={filterDisabilityStatusConfig.isDisabled}
        buttonDropDownContainerStyle={{
          ...filterStyles.buttonDropDownContainerStyle,
          ...buttonDropDownContainerStyle,
        }}
        showSearchBar={true}
        parentContainerStyle={parentContainerStyle}
        checkListItemStyle={filterStyles.checkListItemStyle}
        checkboxItemStyle={filterStyles.checkboxItemStyle}
        checkBoxParentContainer={filterStyles.checkBoxParentContainer}
        isMatchingTextEnabled={true}
        isSearchTextInState={true}
      />
    );
  }

  if (entityType == ELEMENT_TYPE_MYP_OBJECTIVES) {
    return (
      <Level3Filter
        optionList={options}
        updateInputField={({ updatedValueList }) => {
          updateInputField({
            key: entityType,
            values: updatedValueList,
            isParent,
          });
        }}
        buttonDropDownContainerStyle={buttonDropDownContainerStyle}
        getSearchPlaceholder={({ label }) =>
          t("common:search_label", { label })
        }
        getAllOptionLabel={({ label }) => t("common:all_label", { label })}
        buttonElement={
          <ButtonComponent
            t={t}
            entityType={entityType}
            filterDisabilityStatusConfig={filterDisabilityStatusConfig}
            containerParentRef={containerParentRef}
          />
        }
        valueList={values}
        label={label}
        isDisabled={filterDisabilityStatusConfig.isDisabled}
      />
    );
  }

  return (
    <FilterList
      updateFilterList={({ updatedValueList }) => {
        updateInputField({
          key: entityType,
          values: updatedValueList,
          isParent,
        });
      }}
      getAllOptionLabelForFilter={getAllOptionLabelForFilterList}
      getSearchPlaceholder={({ label }) => t("common:search_label", { label })}
      valueList={values || []}
      optionList={options || []}
      label={label}
      onUpdateShowDropDown={() => {}}
      buttonElement={
        <ButtonComponent
          t={t}
          entityType={entityType}
          filterDisabilityStatusConfig={filterDisabilityStatusConfig}
          containerParentRef={containerParentRef}
        />
      }
      buttonDropDownContainerStyle={buttonDropDownContainerStyle}
      isDisabled={filterDisabilityStatusConfig.isDisabled}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const organizationId = _.get(state, "login.userInfo.org_id", "");

  const {
    entityType,
    parentFiltersData,
    curriculumProgramId,
    parentFilterObj,
    dependentFilterParents,
    curriculumType,
  } = ownProps;

  const dependentFilters = _.get(parentFilterObj, "dependentFilters", []);

  const currentParentFilters = _.map(
    dependentFilterParents,
    ({ parent }) => parent
  );

  const commonFilters = _.intersection(dependentFilters, currentParentFilters);

  const optionParam = getFilterOptionParam({
    entityType,
    parentFiltersData,
    curriculumProgramId,
    curriculumType,
  });

  const filterParam = getFilterParam({ entityType, parentFiltersData });

  let filterParamForInterDisciplinaryObjectives = {};

  if (entityType == ELEMENT_TYPE_MYP_OBJECTIVES) {
    filterParamForInterDisciplinaryObjectives = getFilterParam({
      entityType,
      parentFiltersData,
      entitySubType: "INTERDISCIPLINARY_CRITERIA",
    });
  }

  return {
    id: organizationId,
    optionParam,
    shouldSkipQuery: !_.isEmpty(commonFilters),
    filterOptions: [],
    filterParam,
    isLoading: true,
    isData: false,
    filterParamForInterDisciplinaryObjectives,
  };
};

export default compose(
  connect(mapStateToProps),
  graphql(getFilterOptionsQuery, {
    name: "getFilterOptionsQuery",
    alias: "getFilterOptionsQuery",
    skip: ({ shouldSkipQuery }) => shouldSkipQuery,
    options: ({ baseSetType, entityType, id, optionParam, filterParam }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          entityType: baseSetType ? baseSetType : entityType,
          id,
          options: optionParam,
          filters: filterParam,
        },
      };
    },
    props: ({
      getFilterOptionsQuery: { networkStatus },
      ownProps: {
        baseSetType,
        entityType,
        id,
        optionParam,
        levels,
        attributes,
        filterParam,
      },
    }) => {
      const data = getFilterOptionsFromCache({
        entityType: baseSetType ? baseSetType : entityType,
        options: optionParam,
        id,
        filters: filterParam,
      });

      let updatedOptions = [];

      const isCachedDataAvailable = !_.isEmpty(data);

      if (isCachedDataAvailable) {
        updatedOptions = getFilterOptions({
          data,
          levels,
          attributes,
          entityType,
        });
      }

      return {
        isData: !_.isEmpty(data),
        isLoading: _.includes([1, 2], networkStatus),
        options: updatedOptions,
      };
    },
  }),
  graphql(getFilterOptionsQuery, {
    name: "getFilterOptionsQuery",
    alias: "getFilterOptionsQuery(Interdisciplinary Objectives)",
    skip: ({ entityType }) => {
      return entityType != ELEMENT_TYPE_MYP_OBJECTIVES;
    },
    options: ({
      baseSetType,
      entityType,
      id,
      optionParam,
      filterParamForInterDisciplinaryObjectives,
    }) => {
      return {
        /**
         * Assumption: Myp interdisciplinary objectives do not change over time
         */
        fetchPolicy: "cache-first",
        variables: {
          entityType: baseSetType ? baseSetType : entityType,
          id,
          options: optionParam,
          filters: filterParamForInterDisciplinaryObjectives,
        },
      };
    },
    props: ({ getFilterOptionsQuery, ownProps }) => {
      const { networkStatus } = getFilterOptionsQuery;

      const {
        baseSetType,
        entityType,
        id,
        optionParam,
        levels,
        attributes,
        filterParamForInterDisciplinaryObjectives,
        isLoading,
        isData,
        options,
      } = ownProps;

      const data = getFilterOptionsFromCache({
        entityType: baseSetType ? baseSetType : entityType,
        options: optionParam,
        id,
        filters: filterParamForInterDisciplinaryObjectives,
      });

      let updatedOptions = [];

      const isCachedDataAvailable = !_.isEmpty(data);

      if (isCachedDataAvailable) {
        updatedOptions = getFilterOptions({
          data,
          levels,
          attributes,
          entityType,
        });
      }

      return {
        isData: !_.isEmpty(data) && isData,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
        options: _.isEmpty(options)
          ? updatedOptions
          : [...options, ...updatedOptions],
      };
    },
  })
)(DynamicFilter);

DynamicFilter.defaultProps = {
  isFilterVisible: true,
};
