import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";
import { injectReducer } from "store/reducers";

const {
  initiateProject: { path },
} = routes;

const InitiateProjectRoute = store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components/InitiateProject").default;
              //   const reducer = require("./modules/InviteModules");
              //   injectReducer(store, {
              //     key: reducer.NAME,
              //     reducer: reducer.default
              //   });
              resolve(component);
            },
            err => {
              reject(err);
            }
          );
        })
    ),
    childRoutes: [],
  };
};
export default InitiateProjectRoute;
