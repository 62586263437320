import React, { useState } from "react";
import classes from "./Configurations.scss";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { withLoader, DialogueBox, I18nHOC } from "UIComponents";
import { setConfigurationValue } from "modules/Services";
import { getConfigurationsQuery } from "modules/CommonQuery";
import { getConfigurationsFromCache } from "modules/CommonGraphqlHelpers";
import SubModule from "./components/SubModule";
import { setSelectedCoursesForNotification } from "modules/Services";
import ScoreCategoriesConformationModal from "ScoreCategories/components/ScoreCategoriesConformationModal";
import { scoreSettingTypes } from "ScoreCategories/modules/ScoreCategoriesModule";

const COLUMNID_USER_PLATFROM_MAP = {
  educator_web: t => {
    return {
      user: t("configuration:teachers"),
      platform: t("configuration:educator_web"),
    };
  },
  educator_app: t => {
    return {
      user: t("configuration:teachers"),
      platform: t("configuration:educator_app"),
    };
  },
  student_app: t => {
    return {
      user: t("configuration:students"),
      platform: t("configuration:student_app"),
    };
  },
  family_app: t => {
    return {
      user: t("configuration:parents"),
      platform: t("configuration:family_app"),
    };
  },
};

const PERMISSION_CATEGORY_LOCALE_MAP = {
  MODULE: ({ t, value, metaData, label }) => {
    const settingLocaleParams = _.get(metaData, "localeParams", {});
    const columnId = _.get(metaData, "columnId");
    const columnLabelFn = _.get(COLUMNID_USER_PLATFROM_MAP, columnId, () => {
      return {};
    });
    const localeParams = {
      ...columnLabelFn(t),
      moduleName: t(`configuration:${label}`),
    };
    return t(
      `configuration:${
        value ? "alert_turn_on_module_setting" : "alert_turn_off_module_setting"
      }`,
      { ...localeParams, ...settingLocaleParams }
    );
  },
  ATTENDANCE: ({ t, value, metaData }) => {
    const settingLocaleParams = _.get(metaData, "localeParams", {});
    const columnId = _.get(metaData, "columnId");
    const columnLabelFn = _.get(COLUMNID_USER_PLATFROM_MAP, columnId, () => {
      return {};
    });
    const localeParams = {
      ...columnLabelFn(t),
      moduleName: t("configuration:attendance"),
    };
    return t(
      `configuration:${
        value ? "alert_turn_on_module_setting" : "alert_turn_off_module_setting"
      }`,
      { ...localeParams, ...settingLocaleParams }
    );
  },
};

const getModalBodyText = ({ value, t, setting }) => {
  const permissionCategory = _.get(setting, "permissionCategory", null);
  const metaData = _.get(setting, "metadata", {});
  const getBodyTextFn = _.get(
    PERMISSION_CATEGORY_LOCALE_MAP,
    permissionCategory,
    () => ""
  );
  return getBodyTextFn({
    t,
    value,
    metaData,
    label: _.get(setting, "label", ""),
  });
};

const Configurations = React.memo(
  ({
    configurations,
    setConfigurationValue,
    platform,
    categories,
    courseId,
    t,
    showChooseCourses,
    classOptions,
    isNotificationConfiguration,
    setSelectedCoursesForNotification,
    curriculumProgramId,
    courseName,
    organizationId,
    setSelectedValue,
  }) => {
    const [currentDialogAction, setCurrentDialogAction] = useState("");
    const [settingBeingUpdated, setSettingBeingUpdated] = useState(null);
    const module = _.get(_.filter(configurations, { parentID: null }), "0", {});
    const moduleId = _.get(module, "id", null);
    const subModules = _.filter(configurations, { parentID: moduleId });
    subModules.sort((a, b) => a.displayOrder - b.displayOrder);
    const isScoreCategory = _.includes(
      scoreSettingTypes,
      _.get(categories, "0", "")
    );

    const DIALOGS_INFO = {
      SAVE: {
        title: t("common:are_you_sure"),
        modalBody: getModalBodyText({
          value: _.get(settingBeingUpdated, "value", null),
          setting: _.get(settingBeingUpdated, "setting", {}),
          t,
        }),
        button1: t("common:no"),
        button2: t("common:yes"),
      },
    };

    const saveSetting = params => {
      const callMapCoursesMutation = _.get(
        params,
        "callMapCoursesMutation",
        false
      );
      if (callMapCoursesMutation) {
        // call courses map mutation on switch with course id and isEnabled value
        setSelectedCourse(params);
        return;
      }
      const confirmBeforeUpdate = _.get(
        params,
        "setting.metadata.confirmBeforeUpdate",
        false
      );
      if (!confirmBeforeUpdate) {
        return updateConfiguration(params);
      }
      setSettingBeingUpdated(params);
      setCurrentDialogAction("SAVE");
    };

    const onCloseDialogBox = () => {
      setCurrentDialogAction("");
      setSettingBeingUpdated(null);
    };

    const onClickButton2 = () => {
      return updateConfiguration(settingBeingUpdated);
    };

    const updateConfiguration = async params => {
      const setting = _.get(params, "setting", {});
      const valueType = _.get(setting, "valueType", "");
      const value = _.get(params, "value");
      const optimisticUpdate = _.get(params, "optimisticUpdate", true);
      const refetchConfiguration = _.get(params, "refetchConfiguration", false);
      const input = {
        id: setting.id,
        platform,
        valueType,
        courseId,
      };
      switch (valueType) {
        case "BOOLEAN": {
          input["booleanValue"] = value;
          break;
        }
        case "DROPDOWN": {
          const dropDownValueType = _.get(
            setting,
            "valueSet.dropdownValueType",
            ""
          );
          if (dropDownValueType === "INT") {
            input["intValue"] = value;
            break;
          } else if (dropDownValueType == "STRING") {
            input["stringValue"] = value;
            break;
          }
          return;
        }
        case "JSON": {
          input["jsonValue"] = value;
          break;
        }
        default: {
          return;
        }
      }
      try {
        return await setConfigurationValue(
          input,
          platform,
          categories,
          courseId,
          curriculumProgramId,
          optimisticUpdate,
          refetchConfiguration,
          setSelectedValue,
          value
        );
      } catch (error) {
        console.error(error);
      }
    };

    const setSelectedCourse = params => {
      const setting = _.get(params, "setting", {});
      const configurationId = _.get(setting, "id");
      const selectedCourses = _.get(params, "selectedCourses", []);
      const value = _.get(params, "value");
      const input = {
        configurationId,
        courseId,
        isEnabled: value,
      };
      setSelectedCoursesForNotification({
        input,
        platform,
        categories,
        configurationId,
        selectedCourses: value
          ? [...selectedCourses, { id: courseId, title: courseName }]
          : _.filter(selectedCourses, course => course.id !== courseId),
        courseId,
        curriculumProgramId,
      });
    };

    return (
      <div className={classes.containerBody}>
        {!!currentDialogAction && !!settingBeingUpdated && !isScoreCategory && (
          <DialogueBox
            modalTitle={DIALOGS_INFO[currentDialogAction].title}
            showModal={true}
            onClickButton2={onClickButton2}
            modalBody={DIALOGS_INFO[currentDialogAction].modalBody}
            toggleDialogueBoxDisplay={onCloseDialogBox}
            button1={DIALOGS_INFO[currentDialogAction].button1}
            button2={DIALOGS_INFO[currentDialogAction].button2}
          />
        )}
        {!!currentDialogAction && !!settingBeingUpdated && isScoreCategory ? (
          <ScoreCategoriesConformationModal
            setIsScoreCategoriesEnabled={onClickButton2}
            setShowModal={onCloseDialogBox}
            isScoreCategoriesEnabled={
              !_.get(settingBeingUpdated, "value", null)
            }
          />
        ) : null}
        {_.map(subModules, subModule => {
          const isParentDisabled = _.get(module, "status") === "DISABLED";
          const isDisabled = _.get(subModule, "status") === "DISABLED";
          return (
            <SubModule
              subModule={subModule}
              allSettings={configurations}
              onUpdateSetting={saveSetting}
              platform={platform}
              isDisabled={isDisabled || isParentDisabled}
              key={_.get(subModule, "id")}
              showChooseCourses={showChooseCourses}
              classOptions={classOptions}
              t={t}
              categories={categories}
              isNotificationConfiguration={isNotificationConfiguration}
              courseId={courseId}
              organizationId={organizationId}
            />
          );
        })}
      </div>
    );
  }
);

Configurations.defaultProps = {
  showChooseCourses: false,
};

const mapStateToProps = state => {
  const {
    platform: {
      organizationCurriculumPrograms: curriculumPrograms,
      currentCurriculumProgramType,
    },
  } = state;
  const currentCurriculumProgramId = _.find(curriculumPrograms, {
    type: currentCurriculumProgramType,
  })?.id;

  const organizationId = _.get(state, "login.userInfo.org_id", "");

  return {
    isLoading: false,
    isData: true,
    containerStyle: {
      height: "auto",
    },
    curriculumProgramId: currentCurriculumProgramId,
    organizationId,
  };
};

const mapActionCreators = {
  setConfigurationValue,
  setSelectedCoursesForNotification,
};

export default compose(
  I18nHOC({ resource: ["common", "configuration", "journal", "snp"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getConfigurationsQuery, {
    name: "getConfigurations",
    options: ({ platform, categories, courseId, curriculumProgramId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        platform,
        categories,
        courseId,
        curriculumProgramId,
      },
    }),
    props: ({
      getConfigurations,
      ownProps: {
        platform,
        categories,
        isData,
        isLoading,
        courseId,
        curriculumProgramId,
      },
    }) => {
      const allData = getConfigurationsFromCache({
        platform,
        categories,
        courseId,
        curriculumProgramId,
      });
      const configurations = _.get(
        allData,
        "platform.organization.configurations",
        []
      );
      return {
        configurations,
        isData: !_.isEmpty(configurations) && isData,
        isLoading:
          getConfigurations["networkStatus"] == 1 ||
          getConfigurations["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(Configurations);
