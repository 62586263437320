import { withAsyncRouteLoading } from "UIComponents";
import AdminEducatorCenter from "../AdminEducatorCenter";
import AdminUnitPlans from "../AdminUnitPlans";
import ProgressReportSetup from "../ProgressReportSetup";
import AcademicSetup from "../AcademicSetup";
import MYPAcademicSetup from "../MYPAcademicSetup";
import UBDAcademicSetup from "../UBDAcademicSetup";
import ProgressSummary from "ProgressSummary";
import PlanningInsights from "PlanningInsights";
import SnP_v2 from "../SnP_v2";
import DPAcademicSetup from "../DPAcademicSetup";
import ProjectGroupSetup from "../ProjectGroupSetup";
import AttendanceDashboard from "../AttendanceDashboard";
import { ExamRegistration } from "../DPExamRegistration";
import { ECoursework } from "../IB_ECoursework";

export default store => ({
  path: ":curriculumProgramId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "administrator-curriculum-type"
        );
      })
  ),
  childRoutes: [
    AdminEducatorCenter(store),
    AdminUnitPlans(store),
    ProgressReportSetup(store),
    PlanningInsights(store),
    ProgressSummary(store),
    AcademicSetup(store),
    MYPAcademicSetup(store),
    UBDAcademicSetup(store),
    ProjectGroupSetup(store),
    DPAcademicSetup(store),
    AttendanceDashboard(store),
    SnP_v2(store),
    ..._.map(ExamRegistration, comp => comp(store)),
    ..._.map(ECoursework, comp => comp(store)),
  ],
});
