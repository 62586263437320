import gql from "graphql-tag";
import { progressReportCommentTemplateFragment } from "./Fragments";

export const getAllCommentTemplatesQuery = gql`
  query getAllCommentTemplates(
    $filters: ProgressReportCommentTemplateFilterOptions!
  ) {
    documentation {
      progressReportCommentTemplates(filters: $filters) {
        pageInfo {
          ...progressReportCommentTemplatePageInfo
        }
        totalCount
        edges {
          cursor
          node {
            ...progressReportCommentTemplateItem
          }
        }
      }
    }
  }
  ${progressReportCommentTemplateFragment.progressReportPageInfo}
  ${progressReportCommentTemplateFragment.progressReportCommentTemplateEdge}
`;
