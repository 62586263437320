import React from "react";
import { compose } from "react-apollo";
import { I18nHOC } from "UIComponents";
import { ToddleIcon, Tick } from "SvgComponents";
import { CLOUD_URL } from "Constants";
import { colors } from "Constants/colorConstants";
import classes from "./LinkSentSuccessScreen.scss";

const imageURL = `${CLOUD_URL}/assets/webapp/Magic_link_generate.png`;

const LinkSentSuccessScreen = ({ email, onResendClick, t }) => {
  return (
    <>
      <div className={classes.logoContainer}>
        <div className={classes.logo}>
          <ToddleIcon />
        </div>
        <div className={classes.logoText}>{t("common:toddle")}</div>
      </div>
      <div className={classes.imageContainer}>
        <img src={imageURL} height="164px" />
      </div>
      <div className={classes.title}>
        <Tick fillBackground={colors.grass} />
        <span>{t("community:link_sent")}</span>
      </div>
      <div className={classes.description}>
        {t("community:link_sent_desc", { email })}
      </div>
      <div className={classes.revert}>{t("community:link_sent_revert")}</div>
      <div onClick={onResendClick} className={classes.revertLink}>
        {t("community:link_sent_revert_link")}
      </div>
    </>
  );
};

export default compose(I18nHOC({ resource: ["common", "community"] }))(
  LinkSentSuccessScreen
);
