import gql from "graphql-tag";

export const trackEntitySearchMutation = gql`
  mutation trackEntitySearch(
    $searchTerm: String
    $tabType: String
    $results: [KeywordTypeCountInput!]
  ) {
    community {
      addSearchCountReport(
        input: { searchTerm: $searchTerm, tabType: $tabType, results: $results }
      ) {
        id
      }
    }
  }
`;
