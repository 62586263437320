/**
 * If all curriculum programs are free then organization is considered as free
 */
import ACLStore from "lib/aclStore";
import { CURRICULUM_SUBSCRIPTION_PLAN_PERM } from "Constants/permissionConstants";
import _ from "lodash";

export const getOrganizationFreeStatus = ({ curriculumPrograms }) => {
  return _.every(curriculumPrograms, curriculumProgram => {
    return _.get(curriculumProgram, "subscriptionPlan.isFree", false);
  });
};

export const getPaidCurriculumPrograms = ({ curriculumPrograms }) => {
  const areCurriculumProgramPlansEnabled = ACLStore.can(
    CURRICULUM_SUBSCRIPTION_PLAN_PERM
  );

  if (!areCurriculumProgramPlansEnabled) {
    return curriculumPrograms;
  }

  return _.filter(
    curriculumPrograms,
    ({ subscriptionPlan = {} }) => !_.get(subscriptionPlan, "isFree", false)
  );
};

export const getPaidCurriculumProgramIds = ({ curriculumPrograms }) => {
  const paidCurriculumPrograms = getPaidCurriculumPrograms({
    curriculumPrograms,
  });

  return _.map(paidCurriculumPrograms, ({ id }) => id);
};

export const getCurriculumProgramAcronymById = ({ curriculumPrograms }) => {
  return _.reduce(
    curriculumPrograms,
    (result, { id, acronym }) => {
      return { ...result, [id]: acronym };
    },
    {}
  );
};

export const getCurriculumProgramLabel = ({
  curriculumProgramId,
  shouldIncludeAcronym = false,
  curriculumPrograms,
  curriculumProgram,
}) => {
  if (!_.isEmpty(curriculumProgram)) {
    const { label, acronym } = curriculumProgram;

    return shouldIncludeAcronym ? `${label} (${acronym})` : label;
  }

  const currentCurriculumProgram = _.find(
    curriculumPrograms,
    ({ id }) => id == curriculumProgramId
  );

  const { label, acronym } = currentCurriculumProgram;

  return shouldIncludeAcronym ? `${label} (${acronym})` : label;
};
