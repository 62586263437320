import gql from "graphql-tag";
import { likefragment, journalPostFragment } from "./PostFragments";

export const createLikeMutation = gql`
  mutation createLike(
    $userId: ID!
    $parentId: ID!
    $parentType: PARENT_TYPE_ENUM!
  ) {
    documentation {
      createLike(
        input: { userId: $userId, parentId: $parentId, parentType: $parentType }
      ) {
        totalCount
        edges {
          node {
            ...likeItem
          }
        }
      }
    }
  }
  ${likefragment.like}
`;

export const deleteLikeMutation = gql`
  mutation deleteLike(
    $userId: ID!
    $parentId: ID!
    $parentType: PARENT_TYPE_ENUM!
  ) {
    documentation {
      deleteLike(
        input: { userId: $userId, parentId: $parentId, parentType: $parentType }
      ) {
        totalCount
        edges {
          node {
            ...likeItem
          }
        }
      }
    }
  }
  ${likefragment.like}
`;

export const deletePostMutation = gql`
  mutation deletePost($id: ID!) {
    documentation {
      deletePost(id: $id) {
        isSuccess
      }
    }
  }
`;

export const updatePostMutation = gql`
  mutation updatePost(
    $postId: ID!
    $courseId: ID
    $title: String
    $description: String
    $updatedBy: ID!
    $unitPlanId: ID
    $resourceId: ID
    $resourceType: FLOW_SECTION_ITEM_ENUM
    $elementsPYP: [PYPElement!]
    $studentIds: [ID!]
    $state: PostStateEnum!
    $isPrivate: Boolean
    $isHiddenFromStudents: Boolean
    $attachments: [AttachmentInput!]
    $evaluationCycleId: ID
    $addedFolders: [ID!]
    $removedFolders: [ID!]
    $addedPractices: [ID!]
    $removedPractices: [ID!]
    $approvComment: String
    $rejectComment: String
  ) {
    documentation {
      updatePost(
        input: {
          postId: $postId
          courseId: $courseId
          title: $title
          description: $description
          updatedBy: $updatedBy
          unitPlanId: $unitPlanId
          resourceId: $resourceId
          resourceType: $resourceType
          elementsPYP: $elementsPYP
          studentIds: $studentIds
          state: $state
          isPrivate: $isPrivate
          isHiddenFromStudents: $isHiddenFromStudents
          attachments: $attachments
          folders: {
            addedFolders: $addedFolders
            removedFolders: $removedFolders
          }
          practices: {
            evaluationCycleId: $evaluationCycleId
            addedPractices: $addedPractices
            removedPractices: $removedPractices
          }
          approvComment: $approvComment
          rejectComment: $rejectComment
        }
      ) {
        ...postItem
      }
    }
  }
  ${journalPostFragment.journalPost}
`;

export const createPostMutation = gql`
  mutation createPost(
    $courseId: ID!
    $title: String
    $description: String
    $createdBy: ID!
    $unitPlanId: ID
    $resourceId: ID
    $resourceType: FLOW_SECTION_ITEM_ENUM
    $elementsPYP: [PYPElement!]
    $attachments: [AttachmentInput!]
    $studentIds: [ID!]
    $state: PostStateEnum!
    $publishedBy: ID!
    $isPrivate: Boolean
    $isHiddenFromStudents: Boolean
    $folders: [ID!]
    $practices: [ID!]
    $practicesV2: PostPracticeCreateInput
    $curriculumProgramId: ID
    $parentId: ID
    $parentType: POST_ITEM_TYPE_ENUM
    $academicYears: [ID!]
  ) {
    documentation {
      createPost(
        input: {
          courseId: $courseId
          title: $title
          description: $description
          createdBy: $createdBy
          unitPlanId: $unitPlanId
          resourceId: $resourceId
          resourceType: $resourceType
          elementsPYP: $elementsPYP
          attachments: $attachments
          studentIds: $studentIds
          state: $state
          publishedBy: $publishedBy
          isPrivate: $isPrivate
          isHiddenFromStudents: $isHiddenFromStudents
          folders: $folders
          practices: $practices
          practicesV2: $practicesV2
          curriculumProgramId: $curriculumProgramId
          parentId: $parentId
          parentType: $parentType
          academicYears: $academicYears
        }
      ) {
        id
      }
    }
  }
`;

export const bulkPostsActionMutation = gql`
  mutation bulkPostsAction($input: PostBulkActionInput!) {
    documentation {
      bulkPostsAction(input: $input)
    }
  }
`;

export const updateAssignmentToJournalMutation = gql`
  mutation updateAssignmentToJournal(
    $assignmentId: ID!
    $postData: PostCompleteInput
  ) {
    platform {
      updateAssignmentToJournal(
        input: { assignmentId: $assignmentId, postData: $postData }
      ) {
        response
        warning
      }
    }
  }
`;

export const addSeenMutation = gql`
  mutation addSeen($input: SeenInput!) {
    platform {
      addSeen(input: $input) {
        totalCount
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;
