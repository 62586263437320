import React from "react";
import classes from "./config.scss";
import { I18nHOC } from "UIComponents";
import { Trans } from "react-i18next";

const Footer = I18nHOC({ resource: ["community", "common"] })(({ t }) => (
  <div className={classes.termsAndConditions}>
    <Trans i18nKey="community:terms_and_conditions_statement">
      {"By pressing the 'Share' button you are agreeing to Toddle Community's"}
      <a
        href="https://www.toddleapp.com/pp/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </a>
      and
      <a
        href="https://www.toddleapp.com/communityterms/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms & Conditions
      </a>
    </Trans>
  </div>
));

const config = (configuration = []) => {
  const result = [];
  _.forEach(configuration, configObj => {
    const { key, ...rest } = configObj;
    switch (key) {
      case "UNIT_SELECTION":
        result.push({
          label: "community:select_unit",
          value: "UNIT_SELECTION",
          key: "unitId",
          props: { shouldGroupScheduledFeed: false },
          ...rest,
        });
        break;

      case "LE_SELECTION":
        result.push({
          label: "community:le_selection_label",
          value: "LE_SELECTION",
          key: "leId",
          ...rest,
        });
        break;

      case "CONTRIBUTE_TO_COMMUNITY":
        result.push({
          value: "CONTRIBUTE_TO_COMMUNITY",
          styles: {
            header: { paddingRight: 0, paddingLeft: 0 },
            body: { padding: 0 },
          },
          FooterContent: Footer,
          buttonLabel: "common:share",
          validateFromComponent: true,
          ...rest,
        });
        break;

      case "GRADE_SELECTION":
        result.push({
          label: "community:select_grade",
          key: "gradeId",
          value: "GRADE_SELECTION",
          ...rest,
        });
        break;
    }
  });
  return result;
};
export default config;
