import React from "react";
import classes from "./LevelViewTable.scss";

import { connect } from "react-redux";
import { generateGradeWiseTreesOfNode } from "Utils";
import { colors } from "Constants";
import { BenchmarkView, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import { StickyTable, Row, Cell } from "react-sticky-table";
import "react-sticky-table/dist/react-sticky-table.css";
import { EmptySvgIcon } from "SvgComponents";
import { Trans } from "react-i18next";

//import BenchmarkView from "./BenchmarkView";

const NoDataComponent = ({ emptyText }) => {
  return (
    <div className={classes.emptyState}>
      <EmptySvgIcon />
      <div className={classes.emptyText}>{emptyText}</div>
    </div>
  );
};

class LevelViewTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showTable: true };
  }
  updateStickyTableRef = ref => {
    this.StickyTable = ref;
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { parentNodeId } = this.props;
    if (!_.isEqual(parentNodeId, nextProps.parentNodeId)) {
      this.resetTable();
    }
  };

  getEmptyText = () => {
    const {
      rootNode: { grades = [] } = {},
      nodeType,
      routeLabel,
      gradeFilterLength,
      levelIdIndex,
      grades: filterGrades,
      parentNode: { label: parentLabel, id: parentNodeId } = {},
      t,
    } = this.props;
    let currentLabel = "";
    if (levelIdIndex > 0 && !!parentNodeId && gradeFilterLength > 0) {
      currentLabel = parentLabel;
    }
    if (filterGrades.length > 0) {
      return currentLabel
        ? t("academicSetup:sns_view_empty_grade_filter_currentLabel", {
            routeLabel,
            currentLabel,
          })
        : t("academicSetup:sns_view_empty_grade_filter", {
            routeLabel,
          });
    }

    if (grades.length == 0) {
      return nodeType == "BENCHMARK" ? (
        <Trans i18nKey="sns_view_empty_no_grade">
          {"You haven't added this subject to any grade yet.\nPlease go to"}
          <span
            className={classes.emtyTextBoldText}
          >{` Academic setup > Subjects `}</span>
          to add it in grades.
        </Trans>
      ) : (
        t("academicSetup:no_skill_added_grade")
      );
    }
    if (filterGrades.length == 0) {
      return t("academicSetup:sns_view_empty_no_filter", { routeLabel });
    }

    return null;
  };

  resetTable = () => {
    if (this.StickyTable) {
      this.setState({ showTable: false });
      setTimeout(() => {
        this.setState({ showTable: true });
      }, 100);
    }
  };

  render() {
    const {
      grades = [],
      gradeWiseTree,
      rowsLength,
      rootNode,
      parentNode: { label: parentLabel } = {},
      levelIdIndex,
      gradeFilterLength,
      routeLabel,
      nodeType,
    } = this.props;
    const rowArray = _.range(0, rowsLength);
    const { showTable } = this.state;

    if (
      _.get(grades, "length", 0) == 0 ||
      rowsLength == 0 ||
      gradeFilterLength == 0
    ) {
      return <NoDataComponent emptyText={this.getEmptyText()} />;
    }

    const levelLength = Math.max(1, _.get(rootNode, "levels.length", 0) - 2);
    const headerText =
      levelIdIndex > 0
        ? parentLabel
        : _.get(rootNode, `levels[${levelIdIndex + 1}].value`, "");

    return showTable ? (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          {!!headerText && (
            <div className={classes.headerText}>{headerText}</div>
          )}
          <StickyTable stickyColumnCount={0} ref={this.updateStickyTableRef}>
            <Row>
              {_.map(grades, grade => {
                return (
                  <Cell className={classes.fixedCell} key={grade.id}>
                    <div className={classes.gradeText}>{grade.name}</div>
                  </Cell>
                );
              })}
            </Row>
            {_.map(rowArray, (row, rowIndex) => {
              return (
                <Row key={"row_" + rowIndex}>
                  {_.map(grades, (grade, cellIndex) => {
                    const gradeValues = _.get(gradeWiseTree, grade.id, null);

                    const value = gradeValues ? gradeValues[row] : null;

                    return (
                      <Cell
                        className={classes.valueCell}
                        key={"cell_" + rowIndex + "_" + cellIndex}
                      >
                        {value ? (
                          <BenchmarkView
                            value={[value]}
                            mode={"view"}
                            startDepth={
                              nodeType == "BENCHMARK" ? value.depth : 2
                            }
                          />
                        ) : (
                          "-"
                        )}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </StickyTable>
        </div>
      </div>
    ) : null;
  }
}

const generateGradeWiseTreesOfNodeMemoize = _.memoize(
  params => generateGradeWiseTreesOfNode(params),
  params => JSON.stringify(params)
);

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const { parentNodeId, grades, nodes } = ownProps;

  const gradeWiseTree = generateGradeWiseTreesOfNodeMemoize({
    nodes,
    nodeId: parentNodeId,
    grades: grades,
  });

  const rowsLength = _.max(
    _.map(grades, item => {
      return _.get(gradeWiseTree[item.id], "length", 0);
    })
  );

  return {
    parentNode: _.find(nodes, item => item.id == parentNodeId),
    gradeWiseTree: gradeWiseTree,
    rowsLength: rowsLength,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "academicSetup"] }),
  connect(mapStateToProps, mapActionCreators)
)(LevelViewTable);
