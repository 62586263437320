import React from "react";
import PropTypes from "prop-types";
import classes from "./DateSelector.scss";
import moment from "moment";
import classNames from "classnames";
import { CalendarDateOutlined } from "@toddle-design/web-icons";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { isDayOutSideRange, scrollIntoView } from "Utils";

import UIBaseComponent from "UIComponents/UIBaseComponent";
import { I18nHOC } from "UIComponents";
import {
  ICON_AFTER_POSITION,
  ICON_BEFORE_POSITION,
} from "react-dates/constants";

class DateSelector extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = { error: "", focused: false };
  }

  isValidValue = value => {
    return _.get(Object.keys(value), "length", 0) != 0;
  };

  updateInputField = (value, name) => {
    const { isValueMomentObj, value: currentDate } = this.props;
    if (value) {
      const param = {};
      param[name] = isValueMomentObj
        ? value
        : moment(value).format("YYYY-MM-DD");

      const isSameDate = moment(currentDate).isSame(param[name]);

      if (isSameDate) {
        param[name] = null;
        this.props.updateInputField(param);
      } else {
        this.props.updateInputField(param);
      }

      if (this.state.error != "") {
        this.setState({ error: "" });
      }
    }

    this.onClickDatePicker();
  };

  onClickDatePicker = e => {
    e && e.preventDefault();
  };

  isOutsideRange = day => {
    const { maxDate, minDate } = this.props;
    return isDayOutSideRange({ day, maxDate, minDate });
  };

  setFocused = bool => {
    this.setState({ focused: bool });
  };

  onFocusChangeLocal = ({ focused }) => {
    const { onFocusChange, isScrollIntoViewRequired = false } = this.props;

    onFocusChange ? onFocusChange(focused) : null;
    this.setState({ focused });

    if (focused) {
      this.onFocus();

      isScrollIntoViewRequired &&
        setTimeout(() => {
          const singleDatePickerDomNode = document.getElementsByClassName(
            "DayPicker_transitionContainer"
          )[0];

          scrollIntoView({
            element: singleDatePickerDomNode,
            block: "center",
          });
        }, 300);
    } else {
      this.onBlur();
    }
  };

  returnYears = () => {
    const years = [];
    for (let i = moment().year() - 50; i <= moment().year() + 50; i++) {
      years.push(<option value={i}>{i}</option>);
    }
    return years;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div className={classes.monthYearSelectorcontainer}>
      <div className={classes.monthDropDownContainer}>
        <select
          value={month.month()}
          onChange={e => onMonthSelect(month, e.target.value)}
        >
          {_.map(moment.months(), (label, value) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>

      <div className={classes.yearDropDownContainer}>
        <select
          value={month.year()}
          onChange={e => onYearSelect(month, e.target.value)}
        >
          {this.returnYears()}
        </select>
      </div>
    </div>
  );

  renderEdit = () => {
    const {
      value,
      name,
      isDisabled,
      openDirection,
      numberOfMonths,
      placeholder,
      placeholder_locale,
      dateClassname,
      t,
      inputIconPosition,
      displayFormat,
      dateSelectorContainerStyle,
      isBottomPaddingRequired,
      inputIconSize,
      inputIconVariant,
      showMonthAndYearSelector,
      anchorDirection,
    } = this.props;

    const selectClassName = classNames(
      { [classes.container]: true },
      { [classes.disabledContainer]: isDisabled },
      { [classes.errorContainer]: Boolean(this.state.error) },
      {
        [classes.downContainer]:
          openDirection === "down" && isBottomPaddingRequired,
      },
      dateClassname
    );

    const { focused } = this.state;

    return (
      <div className={selectClassName} style={dateSelectorContainerStyle}>
        <SingleDatePicker
          id="date_input"
          isOutsideRange={this.isOutsideRange}
          anchorDirection={anchorDirection}
          date={value ? moment(value) : null}
          focused={focused}
          onDateChange={props => this.updateInputField(props, name)}
          openDirection={openDirection}
          disabled={isDisabled}
          showDefaultInputIcon={true}
          customInputIcon={
            <CalendarDateOutlined
              size={inputIconSize}
              variant={inputIconVariant}
            />
          }
          hideKeyboardShortcutsPanel={true}
          onFocusChange={this.onFocusChangeLocal}
          numberOfMonths={numberOfMonths}
          placeholder={
            placeholder === "common:select_with_label"
              ? t(placeholder, { label: t(placeholder_locale) })
              : placeholder
          }
          inputIconPosition={
            inputIconPosition === "before"
              ? ICON_BEFORE_POSITION
              : ICON_AFTER_POSITION
          }
          displayFormat={displayFormat}
          renderMonthElement={
            showMonthAndYearSelector && this.renderMonthElement
          }
        />
      </div>
    );
  };
}

DateSelector.defaultProps = {
  ...UIBaseComponent.defaultProps,
  minDate: null,
  maxDate: null,
  isDisabled: false,
  autoFocus: false,
  openDirection: "down",
  anchorDirection: "left",
  isValueMomentObj: true,
  numberOfMonths: 1,
  placeholder: "common:select_with_label",
  placeholder_locale: "common:date",
  inputIconPosition: "before",
  inputIconSize: "xx-small",
  inputIconVariant: "default",
  displayFormat: "DD-MM-YYYY",
  isBottomPaddingRequired: true,
  showMonthAndYearSelector: false,
};

DateSelector.propTypes = {
  ...UIBaseComponent.propTypes,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateClassname: PropTypes.string,
  datePickerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updateInputField: PropTypes.func,
  name: PropTypes.string,
  openDirection: PropTypes.oneOf(["up", "down"]),
  anchorDirection: PropTypes.oneOf(["left", "right"]),
  numberOfMonths: PropTypes.number,
  placeholder_locale: PropTypes.string,
  inputIconPosition: PropTypes.oneOf(["before", "after"]),
  isBottomPaddingRequired: PropTypes.bool,
  showMonthAndYearSelector: PropTypes.bool,
};

export default I18nHOC({ resource: ["common"] })(DateSelector);
