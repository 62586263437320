import React from "react";
import { RecentSearchList } from "../SearchList/RecentSearchList";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";

const style = {
  searchListStyle: {
    height: "100%",
  },
};

const RecentSearch = props => {
  const { data = [], cursor, t, onCursorFocus, onSuggestionClick } = props;

  return _.size(data) ? (
    <div className="mt-6 flex-1" style={{ minHeight: 0, height: "100%" }}>
      <div className="text-label-3 mb-2 mx-10">
        {t("integration:recent_searches")}
      </div>
      <div className="overflow-y-auto flex-1" style={style.searchListStyle}>
        <RecentSearchList
          data={data}
          cursor={cursor}
          onSelectItem={onSuggestionClick}
          onCursorFocus={onCursorFocus}
        />
      </div>
    </div>
  ) : null;
};

export default compose(I18nHOC({ resource: ["integration"] }))(RecentSearch);
