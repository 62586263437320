import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";
import classes from "./MultiSelectQuestions.scss";
import { TextAreaInput, UIButton, I18nHOC, Textview } from "UIComponents";
import { CancelIcon } from "SvgComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";

class MultiSelectQuestions extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.focusedCount = 0;
    this.inputRefs = {};
  }

  updateInputRef = ({ ref, id }) => {
    this.inputRefs[id] = ref;
  };

  blur = () => {
    _.map(this.inputRefs, itemRef => {
      if (itemRef) {
        itemRef.blur();
      }
    });
  };

  updateFocusedCount = value => {
    const updatedFocusedCount = this.focusedCount + value;

    if (this.focusedCount == 0 && updatedFocusedCount != 0) {
      this.onFocus();
    } else if (updatedFocusedCount == 0 && this.focusedCount != 0) {
      this.onBlur();
    }
    this.focusedCount = updatedFocusedCount;
  };

  isValidValue = value => {
    return _.get(Object.keys(value || {}), "length", 0) != 0;
  };

  shouldShowEmpty = () => {
    return false;
  };

  updateInputField = ({ selectedId, val, value, questionCode }) => {
    let updatedValue = value ? value : {};
    updatedValue = {
      ...updatedValue,
      [selectedId]: {
        ...updatedValue[selectedId],
        [questionCode]: val,
      },
    };
    this.updateValue(updatedValue);
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      _.xorBy(this.props.options.nodes, nextProps.options.nodes, "value")
        .length > 0
    ) {
      const value = nextProps.value || {};
      const updatedValue = _.reduce(
        nextProps.options.nodes,
        (result, node) => {
          const key = node.value;
          if (value[key]) {
            result[key] = value[key];
          } else {
            const questions = nextProps.options.questions;
            const newObj = _.reduce(
              questions,
              (result, ques) => {
                result[ques.value] = "";
                return result;
              },
              {}
            );
            result[key] = newObj;
          }
          return result;
        },
        {}
      );

      this.updateValue(updatedValue);
    }
  };

  getUILabel = ({ questionCode, label }) => {
    const { nodes } = this.props.options;
    const { value, mode } = this.props;

    return _.map(nodes, node => {
      const questionValue = _.get(value, `${node.value}.${questionCode}`, "");

      return (
        <div className={classes.kcContainer} key={node.value}>
          <div className={classes.kcLabelAndSubText}>
            <div className={classes.kcLabelContainer}>
              <div className={"text-label-2"}>{node.label}</div>
            </div>
            {this.props.showSubText && (
              <div className={classes.subText}>{node.subText}</div>
            )}
          </div>
          <div className={classes.TextAreaInputDiv}>
            <TextAreaInput
              value={questionValue}
              updateInputField={val =>
                this.updateInputField({
                  selectedId: node.value,
                  val,
                  value,
                  questionCode,
                })
              }
              updateInputRef={ref =>
                this.updateInputRef({ ref, id: node.value })
              }
              onBlurInputField={() =>
                setTimeout(() => this.updateFocusedCount(-1))
              }
              onFocusInputField={() => this.updateFocusedCount(1)}
              placeholder={label}
            />
          </div>
        </div>
      );
    });
  };

  getViewQuestion = questionCode => {
    const { nodes } = this.props.options;
    const { value, mode } = this.props;

    return _.map(nodes, node => {
      const questionValue = _.get(value, `${node.value}.${questionCode}`, "");

      return (
        <div className={classes.viewKcContainer} key={node.value}>
          <div className={"text-label-2"}>{node.label}</div>
          <div className={classes.viewTextAreaInputDiv}>
            {questionValue ? (
              <Textview
                mode={"view"}
                showViewEmpty={false}
                value={questionValue}
              ></Textview>
            ) : (
              "N/A"
            )}
          </div>
        </div>
      );
    });
  };

  getQuestions = () => {
    const { mode, options, labelStyle, t, name } = this.props;
    const { questions } = options;
    //handles for key concept and action questions label
    if (questions[0].value === "q1") {
      if (_.includes(["conceptsQuestion", "actionQuestion"], name))
        questions[0].label = t(`unitPlan:${questions[0].value}_${name}_label`);
      else questions[0].label = t(`unitPlan:${questions[0].value}_label`);
    }

    return _.map(questions, question => {
      const questionContainerClass = classNames(
        { [classes.questionContainer]: mode != "view" },
        { [classes.questionViewContainer]: mode == "view" }
      );

      const feedContainerClass = classNames(
        { [classes.feedContainer]: mode != "view" },
        { [classes.viewFeedContainer]: mode == "view" }
      );
      return (
        <div className={questionContainerClass} key={question.value}>
          {/* <div className={classes.questionHeader}>
            <div className={classes.label} style={labelStyle}>
              {question.label}
            </div>
          </div> */}

          <div className={feedContainerClass}>
            {mode == "view"
              ? this.getViewQuestion(question.value)
              : this.getUILabel({
                  questionCode: question.value,
                  label: question.label,
                })}
          </div>
        </div>
      );
    });
  };

  renderView = () => {
    const { nodes } = this.props.options;
    return _.get(nodes, "length", 0) > 0 && this.getQuestions();
  };

  renderEdit = () => {
    const { nodes } = this.props.options;
    return _.get(nodes, "length", 0) > 0 && this.getQuestions();
  };
}

export default I18nHOC({ resource: ["unitPlan"] })(MultiSelectQuestions);
