import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";
import ACLStore from "lib/aclStore";
import { getStudentTodosFiltersFromCache } from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import { getClassroomTodoCountFilters } from "ClassRoom/modules/utils";
import { COUNT_KEYS } from "Constants";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getSettingValue } from "modules/PermissionModule";

export const getClassroomTodoPerm = ({ currentCurriculumProgramType }) => {
  switch (currentCurriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return ACLStore.can("Common:Classroom");
    case CURRICULUM_TYPE_MYP:
      return ACLStore.can("Common:MYPClassroom");
    case CURRICULUM_TYPE_UBD:
      return ACLStore.can("Common:UBDClassroom");
    case CURRICULUM_TYPE_DP:
      return ACLStore.can("Common:DPClassroom");
  }
};

export const getCalendarPerm = ({ currentCurriculumProgramType }) => {
  switch (currentCurriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return ACLStore.can("Common:PlatformCalendar");
    case CURRICULUM_TYPE_MYP:
      return false;
    case CURRICULUM_TYPE_DP:
      return false;
    case CURRICULUM_TYPE_UBD:
      return ACLStore.can("Common:UBDFullCalendar");
  }
};

export const getClassRoomTodoText = ({
  currentCurriculumProgramType,
  userType,
  t,
}) => {
  switch (currentCurriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return t(
        userType == "student"
          ? "common:classroom_todo"
          : "classRoom:classroom_review"
      );
    case CURRICULUM_TYPE_MYP:
    case CURRICULUM_TYPE_UBD:
      return t(
        userType == "student"
          ? "common:class_stream_todo"
          : "classRoom:class_stream_review"
      );
    default:
      return t(
        userType == "student"
          ? "common:classroom_todo"
          : "classRoom:classroom_review"
      );
  }
};

export const getTodosCountForSubLabel = props => {
  const todoCount = getStudentTodosFiltersFromCache({
    ...getClassroomTodoCountFilters({
      userId: props.userId,
      academicYears: props.currentAcademicYearId,
      ...(props.todoFilters || {}),
      todoActiveTab: props.todoActiveTab,
      orgId: props.orgId,
    }),
  });

  return _.reduce(
    COUNT_KEYS[props.todoActiveTab] || [],
    (output, itemKey) => {
      output = output + _.get(todoCount, `${itemKey}.totalCount`, 0);
      return output;
    },
    0
  );
};

export const regionsLevelConst = {
  COUNTRY: "COUNTRY",
};

export const mapleBearLikeConstants = {
  MAPLEBEAR: "MAPLEBEAR",
  COMMON_COURSE: "COMMONCOURSE",
};

export const findCountryUser = ({ userTags }) => {
  return _.find(
    userTags,
    tag => tag.key === "REGION" && tag.value !== "GLOBAL"
  );
};

export const mapleBearLikeCountryUser = ({ userTags }) => {
  return mapleBearLikeOrg() && findCountryUser({ userTags });
};

// country user but not global user
export const mapleBearLikeNonGlobalCountryUser = ({ userTags }) => {
  return Boolean(
    mapleBearLikeOrg() &&
      !isGlobalUser({ userTags }) &&
      findCountryUser({ userTags })
  );
};

export const mapleBearLikeOrg = () => {
  return ACLStore.can("FeatureFlag:IsMapleBearLikeOrg");
};

export const mapleBearLikeCentralOrg = ({ organizationTags = [] }) => {
  return Boolean(
    mapleBearLikeOrg() &&
      _.find(
        organizationTags,
        tag => tag.key === "MAPLEBEAR_ORG" && tag.value === "PARENT"
      )
  );
};

export const isMapleBearLikeEducator = ({ organizationTags = [] }) => {
  return Boolean(
    mapleBearLikeOrg() &&
      _.find(
        organizationTags,
        tag => tag.key === "MAPLEBEAR_ORG" && tag.value === "CHILD"
      )
  );
};

export const mapleBearLikeUser = ({ userTags }) => {
  return Boolean(
    mapleBearLikeOrg() && _.find(userTags, tag => tag.key === "REGION")
  );
};

export const mapleBearLikeCentralOrgGlobalUser = ({
  userTags = [],
  organizationTags = [],
}) => {
  return Boolean(
    mapleBearLikeCentralOrg({ organizationTags }) && isGlobalUser({ userTags })
  );
};

export const mapleBearLikeGlobalOrCountryUser = ({
  userTags,
  organizationTags,
}) => {
  return Boolean(
    mapleBearLikeCentralOrgGlobalUser({ userTags, organizationTags }) ||
      mapleBearLikeNonGlobalCountryUser({ userTags })
  );
};

export const isGlobalUser = ({ userTags }) =>
  _.find(userTags, tag => tag.key === "REGION" && tag.value === "GLOBAL");

export const getUserRegions = ({ userTags }) =>
  _.filter(userTags, tag => tag.key === "REGION" && tag.value !== "GLOBAL");

export const shouldShowRegionTags = ({ organizationTags, userTags }) => {
  return (
    mapleBearLikeCentralOrg({ organizationTags }) &&
    (mapleBearLikeCentralOrgGlobalUser({ organizationTags, userTags }) ||
      _.get(getUserRegions({ userTags }), "length", 0) >= 1)
  );
};

// list of options in the dropdown menu
// Global user gets all region tags
// Country user gets only the user associated tags
export const getRegionOptions = ({ userTags, organizationTags, tags }) => {
  return mapleBearLikeCentralOrgGlobalUser({
    userTags,
    organizationTags,
  })
    ? tags
    : getUserRegions({ userTags });
};

export const doesTwoEntityBelongToSameRegion = (entityTags1, entityTags2) => {
  return Boolean(
    _.find(entityTags1, tag1 => {
      return (
        tag1.key === "REGION" &&
        _.find(
          entityTags2,
          tag2 => tag2.key === "REGION" && tag2.id === tag1.id
        )
      );
    })
  );
};

export const getUserCourseTagsFilters = ({ userTags }) => {
  //fetch tags for regions in which current user is.
  const regionTags = _.map(getUserRegions({ userTags }), region => region.id);
  const globalUser = isGlobalUser({ userTags });

  const filterTags = globalUser ? [] : regionTags;

  return _.size(filterTags) ? { tags: filterTags } : {};
};

export const isMapleBearLikeCommonCourse = ({ courseTags = [] }) => {
  return _.find(
    courseTags,
    tag =>
      tag.key === mapleBearLikeConstants.MAPLEBEAR &&
      tag.value === mapleBearLikeConstants.COMMON_COURSE
  );
};

export const disableEditForMapleBearChildOrg = () =>
  mapleBearLikeOrg() && ACLStore.can("FeatureFlag:MapleBearEditDisable");

export const getEntityTagIds = ({ key, tags }) =>
  _.map(
    _.filter(tags, tag => tag.key === key),
    tag => tag.id
  );

export const getUnitPlanCentralRepoInputAndFilters = _.memoize(
  ({ courseId, organizationId }) => {
    const organizationDetails = getOrganizationDetailsFromCache(organizationId);
    const organizationTags = _.get(organizationDetails, "tags", []);
    const regionId = _.get(
      _.first(_.filter(organizationTags, tag => tag.key === "REGION")),
      "id",
      ""
    );
    const course = getCourseDetailsFromCache({ id: courseId });
    const courseTags = _.get(course, "tags", []);
    const courseRegionIds = _.map(
      _.filter(courseTags, { key: "REGION" }),
      "id"
    );
    const isEducator = isMapleBearLikeEducator({ organizationTags });
    const centralRepoCourseId = _.get(
      organizationDetails,
      "repoOrganization.commonCourseId",
      ""
    );
    const centralRepoFilters =
      isEducator && courseId === centralRepoCourseId
        ? { tags: [regionId] }
        : {};

    const isSchoolGroup = mapleBearLikeOrg();
    const centralRepoRegionInput = !_.isEmpty(courseRegionIds)
      ? courseRegionIds
      : regionId
      ? [regionId]
      : [];
    const centralRepoInput = {
      region: centralRepoRegionInput,
      isSchoolGroup,
    };
    return { centralRepoInput, centralRepoFilters };
  },
  JSON.stringify
);

// Check for homepage revamp feature flags
export const isHomepageRevampUI = ({
  currentCurriculumProgramType,
  needRevampWithoutType = false,
}) => {
  // following condition returns true irrespective of curriculum type. (case: when we need new UI anyhow)
  if (needRevampWithoutType === true)
    return (
      ACLStore.can("FeatureFlag:DPHomepageRevamp") ||
      ACLStore.can("FeatureFlag:HomepageRevamp")
    );

  if (currentCurriculumProgramType === CURRICULUM_TYPE_DP) {
    return (
      ACLStore.can("FeatureFlag:DPHomepageRevamp") ||
      ACLStore.can("FeatureFlag:HomepageRevamp")
    );
  }
  return ACLStore.can("FeatureFlag:HomepageRevamp");
};

export const getLastSelectedTab = ({ activeTab, userId, userEntityType }) => {
  const persistLastSelectedTab = ACLStore.can(
    "FeatureFlag:PersistLastSelectedMenu"
  );

  if (persistLastSelectedTab) {
    const userLastSelectedTab = getSettingValue({
      userId,
      name: `${userEntityType}_WEB:lastSelectedTab`,
      userEntityType,
    });

    return !_.isEmpty(userLastSelectedTab) ? userLastSelectedTab : activeTab;
  } else {
    return activeTab;
  }
};
