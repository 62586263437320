import PropTypes from "prop-types";
import React from "react";

const EmptyLE = props => {
  return (
    <svg
      width="200"
      height="198"
      viewBox="0 0 200 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.7 197.594C83.3502 197.594 49.5975 192.993 27.1375 175.929C-7.20077 149.788 -8.66465 111.1 21.1147 81.3205C50.8941 51.5411 60.2629 -12.8693 111.708 2.27137C163.152 17.412 200 60.2826 200 106.374C200 142.301 172.814 197.594 98.7 197.594Z"
        fill="#F7F7FC"
      />
      <path
        d="M136.136 173.921C136.052 171.16 133.793 169.027 131.075 169.069L128.021 169.027L128.272 178.94L131.326 178.981C134.044 178.94 136.219 176.681 136.136 173.921Z"
        fill="#E7E7F9"
      />
      <path
        d="M78.2908 170.198L70.4277 175.301L78.5418 179.985L83.6026 179.818V170.073L78.2908 170.198Z"
        fill="#E7E7F9"
      />
      <path
        d="M128.186 169.153L78.2891 170.198C79.1674 170.156 79.9202 170.867 79.9621 171.788C80.0039 172.666 79.2929 173.419 78.3727 173.461V173.502C79.251 173.461 80.0039 174.172 80.0457 175.092C80.0875 175.97 79.3765 176.723 78.4564 176.765V176.807C79.3347 176.765 80.0875 177.476 80.1294 178.396C80.1712 179.274 79.4602 180.027 78.54 180.069L128.437 179.023L128.186 169.153Z"
        fill="#E7E7F9"
      />
      <path
        d="M75.0703 172.29L73.8574 177.267L70.4277 175.301L75.0703 172.29Z"
        fill="#E7E7F9"
      />
      <path
        d="M138.727 171.161C138.644 168.4 136.385 166.267 133.667 166.309L130.613 166.267L130.864 176.18L133.917 176.221C136.636 176.18 138.811 173.921 138.727 171.161Z"
        fill="#F75961"
      />
      <path
        d="M80.8826 167.438L73.0195 172.499L81.1336 177.183L86.1944 177.058V167.313L80.8826 167.438Z"
        fill="#FFFAE3"
      />
      <path
        d="M130.737 166.393L80.8398 167.438C81.7182 167.396 82.471 168.107 82.5128 169.028C82.5547 169.906 81.8436 170.659 80.9235 170.701V170.742C81.8018 170.701 82.5547 171.412 82.5965 172.332C82.6383 173.21 81.9273 173.963 81.0071 174.005V174.047C81.8855 174.005 82.6383 174.716 82.6801 175.636C82.722 176.556 82.0109 177.267 81.0908 177.309L130.988 176.263L130.737 166.393Z"
        fill="#FFBF39"
      />
      <path
        d="M77.6621 169.487L76.4492 174.506L73.0195 172.499L77.6621 169.487Z"
        fill="#686868"
      />
      <path
        d="M130.74 166.393L130.991 176.138"
        stroke="white"
        strokeWidth="1.39664"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.121 129.628H76.7406C73.5619 129.628 70.9688 127.035 70.9688 123.857L71.0106 67.142C71.0106 63.9633 73.6037 61.3701 76.7824 61.3701H111.163C114.341 61.3701 116.934 63.9633 116.934 67.142L116.893 123.857C116.893 127.077 114.299 129.628 111.121 129.628Z"
        fill="#E7E7F9"
      />
      <path
        d="M108.736 100.895H78.9151C76.782 100.895 75.0254 99.1384 75.0254 97.0053V69.0662C75.0254 66.9332 76.782 65.1765 78.9151 65.1765H108.736C110.869 65.1765 112.626 66.9332 112.626 69.0662V97.0053C112.626 99.1802 110.911 100.895 108.736 100.895Z"
        fill="#E7E7F9"
      />
      <path
        d="M108.484 117.499C110.771 117.499 112.625 115.645 112.625 113.358C112.625 111.072 110.771 109.218 108.484 109.218C106.198 109.218 104.344 111.072 104.344 113.358C104.344 115.645 106.198 117.499 108.484 117.499Z"
        fill="#E7E7F9"
      />
      <path
        d="M94.8926 71.0737L98.4895 78.6859L106.813 80.0243L100.706 85.7961L101.961 94.1193L94.5998 90.0623L87.0713 93.8683L88.6188 85.587L82.6797 79.606L91.0447 78.5604L94.8926 71.0737Z"
        fill="#E7E7F9"
      />
      <path
        d="M73.3539 144.811L41.065 156.689C38.0955 157.776 34.7495 156.271 33.662 153.259L14.0461 100.058C12.9587 97.0883 14.4644 93.7423 17.4758 92.6549L49.7646 80.7766C52.7342 79.6892 56.0802 81.1948 57.1676 84.2062L76.7835 137.408C77.871 140.377 76.3235 143.681 73.3539 144.811Z"
        fill="#E7E7F9"
      />
      <path
        d="M61.1826 118.67L33.2017 128.959C31.1941 129.712 28.9356 128.666 28.2246 126.659L18.563 100.435C17.8102 98.427 18.8558 96.1684 20.8634 95.4574L48.8443 85.1685C50.8519 84.4156 53.1104 85.4613 53.8214 87.4689L63.483 113.693C64.2359 115.659 63.1902 117.917 61.1826 118.67Z"
        fill="#E7E7F9"
      />
      <path
        opacity="0.8"
        d="M65.9922 136.278C68.2791 136.278 70.1329 134.425 70.1329 132.138C70.1329 129.851 68.2791 127.997 65.9922 127.997C63.7054 127.997 61.8516 129.851 61.8516 132.138C61.8516 134.425 63.7054 136.278 65.9922 136.278Z"
        fill="#E7E7F9"
      />
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M25.5879 119.548C26.3826 115.407 28.5993 110.43 30.3141 106.582C30.3978 106.331 30.5232 106.122 30.6905 105.955C31.1088 105.537 31.778 105.495 32.4054 105.537C39.5992 105.871 55.4091 109.092 62.2684 111.309"
          fill="#E7E7F9"
        />
        <path
          opacity="0.8"
          d="M47.9219 107.335C48.9257 104.282 50.0549 101.27 51.3097 98.3427C51.3933 98.1754 51.477 97.9663 51.6443 97.8408C51.8534 97.7154 52.1044 97.7572 52.3135 97.7572C54.0283 97.9663 55.7013 98.3427 57.3325 98.8446"
          fill="#E7E7F9"
        />
        <path
          opacity="0.8"
          d="M41.5654 101.647C43.9909 101.647 45.9571 99.6808 45.9571 97.2554C45.9571 94.83 43.9909 92.8638 41.5654 92.8638C39.14 92.8638 37.1738 94.83 37.1738 97.2554C37.1738 99.6808 39.14 101.647 41.5654 101.647Z"
          fill="#E7E7F9"
        />
      </g>
      <path
        d="M144.708 156.982L113.297 143.012C110.37 141.716 109.073 138.328 110.37 135.4L133.373 83.5791C134.67 80.6513 138.058 79.3548 140.985 80.6513L172.438 94.6209C175.366 95.9174 176.662 99.3053 175.366 102.233L152.362 154.054C151.023 156.94 147.636 158.237 144.708 156.982Z"
        fill="#E7E7F9"
      />
      <path
        d="M154.202 129.754L126.974 117.667C125.009 116.788 124.13 114.488 125.009 112.522L136.343 86.9671C137.222 85.0013 139.522 84.123 141.488 85.0013L168.716 97.0887C170.681 97.967 171.56 100.267 170.681 102.233L159.347 127.746C158.469 129.712 156.168 130.59 154.202 129.754Z"
        fill="#E7E7F9"
      />
      <path
        d="M148.307 146.902C150.594 146.902 152.447 145.048 152.447 142.761C152.447 140.474 150.594 138.621 148.307 138.621C146.02 138.621 144.166 140.474 144.166 142.761C144.166 145.048 146.02 146.902 148.307 146.902Z"
        fill="#E7E7F9"
      />
      <path
        d="M132.83 112.564C132.83 112.564 142.91 106.834 154.035 106.834Z"
        fill="#E7E7F9"
      />
      <path
        d="M141.029 109.093C141.029 109.093 144.124 92.5721 163.615 106.249C163.615 106.207 148.641 122.728 141.029 109.093Z"
        fill="#E7E7F9"
      />
      <path
        d="M115.47 129.377H81.0902C77.9115 129.377 75.3184 126.784 75.3184 123.606L75.3602 66.891C75.3602 63.7123 77.9533 61.1191 81.132 61.1191H115.512C118.691 61.1191 121.284 63.7123 121.284 66.891L121.242 123.606C121.2 126.784 118.649 129.377 115.47 129.377Z"
        fill="#FFBF39"
      />
      <path
        d="M113.086 100.644H83.2647C81.1316 100.644 79.375 98.8871 79.375 96.7541V68.815C79.375 66.6819 81.1316 64.9253 83.2647 64.9253H113.086C115.219 64.9253 116.976 66.6819 116.976 68.815V96.7541C116.976 98.8871 115.261 100.644 113.086 100.644Z"
        fill="#FFF2D7"
      />
      <path
        d="M112.834 117.206C115.121 117.206 116.975 115.353 116.975 113.066C116.975 110.779 115.121 108.925 112.834 108.925C110.547 108.925 108.693 110.779 108.693 113.066C108.693 115.353 110.547 117.206 112.834 117.206Z"
        stroke="#C28200"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.5469 111.016L102.547 111.225"
        stroke="#C28200"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0078 116.704L94.7682 116.83"
        stroke="#C28200"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.2422 70.7805L102.839 78.3927L111.162 79.7729L105.056 85.5447L106.311 93.8679L98.9494 89.8109L91.4209 93.6169L92.9684 85.3356L87.0293 79.3546L95.3943 78.2672L99.2422 70.7805Z"
        stroke="#FFBF39"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.7055 144.518L45.4166 156.396C42.447 157.484 39.101 155.978 38.0136 152.967L18.3977 99.8072C17.3102 96.8376 18.8159 93.4916 21.8273 92.4041L54.1162 80.5259C57.0858 79.4384 60.4318 80.9441 61.5192 83.9555L81.1351 137.157C82.2226 140.126 80.675 143.431 77.7055 144.518Z"
        fill="#FB768E"
      />
      <path
        d="M65.5342 118.378L37.5533 128.667C35.5457 129.42 33.2872 128.374 32.5761 126.366L22.9146 100.142C22.1617 98.1345 23.2073 95.876 25.2149 95.1649L53.1958 84.876C55.2034 84.1231 57.462 85.1688 58.173 87.1764L67.8346 113.401C68.5874 115.408 67.5418 117.625 65.5342 118.378Z"
        fill="#FFFEFC"
      />
      <path
        opacity="0.8"
        d="M70.3438 135.986C72.6306 135.986 74.4845 134.132 74.4845 131.845C74.4845 129.558 72.6306 127.705 70.3438 127.705C68.057 127.705 66.2031 129.558 66.2031 131.845C66.2031 134.132 68.057 135.986 70.3438 135.986Z"
        stroke="#93435D"
        strokeWidth="1.2257"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.8"
        d="M39.3926 141.13L60.0959 133.686"
        stroke="#93435D"
        strokeWidth="1.2257"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.8"
        d="M41.7773 146.275L54.7431 141.632"
        stroke="#93435D"
        strokeWidth="1.2257"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M29.9395 119.256C30.7341 115.115 32.9509 110.138 34.6657 106.29C34.7493 106.039 34.8748 105.83 35.0421 105.663C35.4603 105.244 36.1295 105.203 36.7569 105.244C43.9508 105.579 59.7606 108.8 66.6199 111.016"
          stroke="#FB93A6"
          strokeWidth="1.2257"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.8"
          d="M52.2734 107.085C53.2772 104.031 54.4065 101.02 55.6613 98.0922C55.7449 97.9249 55.8286 97.7158 55.9959 97.5903C56.205 97.4649 56.4559 97.5067 56.6651 97.5067C58.3799 97.7158 60.0529 98.0922 61.684 98.5941"
          stroke="#FB93A6"
          strokeWidth="1.2257"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.8"
          d="M45.917 101.355C48.3424 101.355 50.3086 99.3886 50.3086 96.9632C50.3086 94.5377 48.3424 92.5715 45.917 92.5715C43.4916 92.5715 41.5254 94.5377 41.5254 96.9632C41.5254 99.3886 43.4916 101.355 45.917 101.355Z"
          stroke="#FB93A6"
          strokeWidth="1.2257"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M149.058 156.689L117.606 142.72C114.678 141.423 113.382 138.035 114.678 135.107L137.682 83.2863C138.978 80.3586 142.366 79.062 145.294 80.3586L176.746 94.3281C179.674 95.6247 180.971 99.0125 179.674 101.94L156.67 153.761C155.374 156.689 151.986 157.986 149.058 156.689Z"
        fill="#4FBDC6"
      />
      <path
        d="M158.552 129.461L131.324 117.374C129.358 116.496 128.48 114.195 129.358 112.229L140.693 86.6743C141.571 84.7086 143.871 83.8303 145.837 84.7086L173.065 96.796C175.031 97.6743 175.909 99.9747 175.031 101.94L163.697 127.495C162.818 129.461 160.518 130.34 158.552 129.461Z"
        fill="#C4F5F0"
      />
      <path
        d="M152.654 146.651C154.941 146.651 156.795 144.797 156.795 142.511C156.795 140.224 154.941 138.37 152.654 138.37C150.368 138.37 148.514 140.224 148.514 142.511C148.514 144.797 150.368 146.651 152.654 146.651Z"
        stroke="#008799"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.961 127.579L143.995 136.655"
        stroke="#008799"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.078 132.932L134.626 138.621"
        stroke="#008799"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.18 112.313C137.18 112.313 147.26 106.583 158.385 106.583"
        stroke="#35C4CF"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.379 108.8C145.379 108.8 148.474 92.2788 167.964 105.956C167.964 105.956 152.991 122.435 145.379 108.8Z"
        stroke="#35C4CF"
        strokeWidth="1.22566"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.179 46.8986C139.397 46.8986 141.194 45.1009 141.194 42.8834C141.194 40.6658 139.397 38.8682 137.179 38.8682C134.962 38.8682 133.164 40.6658 133.164 42.8834C133.164 45.1009 134.962 46.8986 137.179 46.8986Z"
        stroke="#FB93A6"
        strokeWidth="0.910894"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.7663 149.412C96.2909 149.412 97.5268 148.176 97.5268 146.651C97.5268 145.127 96.2909 143.891 94.7663 143.891C93.2418 143.891 92.0059 145.127 92.0059 146.651C92.0059 148.176 93.2418 149.412 94.7663 149.412Z"
        stroke="#FFBF39"
        strokeWidth="0.623011"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9238 71.8259L31.1938 77.0959"
        stroke="#FFBF39"
        strokeWidth="1.17085"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1944 71.8259L25.5898 77.0959"
        stroke="#FFBF39"
        strokeWidth="1.17085"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.293 146.735L173.563 152.046"
        stroke="#FB93A6"
        strokeWidth="1.17085"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.565 146.735L167.961 152.047"
        stroke="#FB93A6"
        strokeWidth="1.17085"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3965 99.8071L36.9249 150.039C36.9249 150.039 38.347 158.195 45.4154 156.438L58.0047 151.796L62.229 150.373L68.7537 147.864L77.6624 144.56"
        stroke="#C75C76"
        strokeWidth="0.704823"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3208 66.8909L75.1953 121.012"
        stroke="#C28200"
        strokeWidth="0.704823"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.722 83.2861L114.718 135.107C114.718 135.107 112.794 139.959 117.646 142.719L126.638 146.735L128.855 147.738L141.486 153.343L149.056 156.689"
        stroke="#2DADBA"
        strokeWidth="0.704823"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyLE;
