import {
  createAnecdotalMutation,
  createExemplarMutation,
  createMypObjectiveRubricMutation,
  editMypObjectiveRubricDescriptorMutation,
  createMypObjectiveRubricDescriptorsForObjectiveMutation,
  deleteMypObjectiveRubricDescriptorsForObjectiveMutation,
} from "./AssessmentToolMutations";
import client from "apolloClient";
import {
  writeAssessmentToolOfParentInCache,
  getAssessmentToolOfParentFromCache,
  writeAssessmentToolElementInCache,
  getAssessmentToolElementFromCache,
  getCriteriaWithDescriptorFromCache,
} from "./AssessmentToolGraphqlHelpers";

import {
  getAssessmentToolNodeDetailsFromCache,
  writeAssessmentToolNodeDetailsInCache,
} from "modules/CommonGraphqlHelpers";

import { generateRandomId } from "Utils";
export const handledMYPRubricDescriptors = ({
  rubricId,
  newObjectives,
  oldObjectives,
}) => {
  return async (dispatch, getState) => {
    const addedObjectives = _.difference(newObjectives, oldObjectives);
    const removedObjectives = _.difference(oldObjectives, newObjectives);
    if (!_.isEmpty(addedObjectives)) {
      dispatch(
        addMYPRubricDescriptorsForObjective({
          rubricId,
          objectiveIds: addedObjectives,
        })
      );
    }

    if (!_.isEmpty(removedObjectives)) {
      dispatch(
        deleteMYPRubricDescriptorsForObjective({
          rubricId,
          objectiveIds: removedObjectives,
        })
      );
    }
  };
};

export const addMYPRubricDescriptorsForObjective = ({
  rubricId,
  objectiveIds,
}) => {
  return async (dispatch, getState) => {
    const newDescriptors = _.reduce(
      objectiveIds,
      (result, objectiveId) => {
        const criteria = getCriteriaWithDescriptorFromCache({
          id: objectiveId,
        });

        const descriptors = _.map(
          _.get(criteria, "objectiveRubricDescriptors", []),
          descriptor => {
            return { ...descriptor, id: generateRandomId() };
          }
        );
        result = _.concat(result, descriptors);
        return result;
      },
      []
    );

    let rubricData = getAssessmentToolNodeDetailsFromCache({
      id: rubricId,
      type: "MYP_OBJECTIVE_RUBRIC",
    });
    let descriptors = _.get(rubricData, "descriptors", []);
    if (!descriptors) {
      descriptors = [];
    }

    descriptors = [...descriptors, ...newDescriptors];

    let data = {
      ...rubricData,

      descriptors,
    };

    writeAssessmentToolNodeDetailsInCache({
      id: rubricId,
      data,
      type: "MYP_OBJECTIVE_RUBRIC",
    });

    try {
      await client.mutate({
        mutation: createMypObjectiveRubricDescriptorsForObjectiveMutation,
        variables: {
          objectiveIds,
          rubricId,
        },
        update: (
          cache,
          {
            data: {
              planner: { createMypObjectiveRubricDescriptorsForObjective },
            },
          }
        ) => {
          rubricData = getAssessmentToolNodeDetailsFromCache({
            id: rubricId,
            type: "MYP_OBJECTIVE_RUBRIC",
          });
          let descriptors = _.get(rubricData, "descriptors", []);
          if (!descriptors) {
            descriptors = [];
          }

          descriptors = _.filter(
            descriptors,
            des =>
              _.findIndex(
                createMypObjectiveRubricDescriptorsForObjective,
                createDes => {
                  return (
                    des.objective.id == createDes.objective.id &&
                    des.achievementLevel.id == createDes.achievementLevel.id
                  );
                }
              ) < 0
          );

          descriptors = [
            ...descriptors,
            ...createMypObjectiveRubricDescriptorsForObjective,
          ];

          data = {
            ...rubricData,

            descriptors,
          };

          writeAssessmentToolNodeDetailsInCache({
            id: rubricId,
            data,
            type: "MYP_OBJECTIVE_RUBRIC",
          });
        },
      });
    } catch (e) {
      setTimeout(() =>
        writeAssessmentToolNodeDetailsInCache({
          id: rubricId,
          data: rubricData,
          type: "MYP_OBJECTIVE_RUBRIC",
        })
      );
    }
  };
};

export const deleteMYPRubricDescriptorsForObjective = ({
  rubricId,
  objectiveIds,
}) => {
  return async (dispatch, getState) => {
    const rubricData = getAssessmentToolNodeDetailsFromCache({
      id: rubricId,
      type: "MYP_OBJECTIVE_RUBRIC",
    });

    try {
      await client.mutate({
        mutation: deleteMypObjectiveRubricDescriptorsForObjectiveMutation,
        variables: {
          objectiveIds,
          rubricId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteMypObjectiveRubricDescriptorsForObjective: {
              __typename: "DeleteOutput",
              isSuccess: true,
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteMypObjectiveRubricDescriptorsForObjective },
            },
          }
        ) => {
          if (deleteMypObjectiveRubricDescriptorsForObjective.isSuccess) {
            let descriptors = _.get(rubricData, "descriptors", []);
            if (!descriptors) {
              descriptors = [];
            }
            descriptors = _.filter(descriptors, descriptor => {
              return _.indexOf(objectiveIds, descriptor.objective.id) == -1;
            });
            const data = {
              ...rubricData,

              descriptors,
            };

            writeAssessmentToolNodeDetailsInCache({
              id: rubricId,
              data,
              type: "MYP_OBJECTIVE_RUBRIC",
            });
          }
        },
      });
    } catch (e) {
      setTimeout(() =>
        writeAssessmentToolNodeDetailsInCache({
          id: rubricId,
          data: rubricData,
          type: "MYP_OBJECTIVE_RUBRIC",
        })
      );
    }
  };
};

export const createMYPObjectiveRubricInAssessment = ({
  parentId,
  parentType,
  objectiveIds,
}) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createMypObjectiveRubricMutation,
        variables: {
          parentId,
          parentType,
          objectiveIds,
        },

        update: (
          cache,
          {
            data: {
              planner: { createMypObjectiveRubric },
            },
          }
        ) => {
          if (!_.isEmpty(createMypObjectiveRubric)) {
            let updatedAssessmentDetails = {};
            let parentAssessmentToolDetails;
            if (parentId) {
              parentAssessmentToolDetails = getAssessmentToolOfParentFromCache({
                id: parentId,
                type: parentType,
              });
              updatedAssessmentDetails = {
                ...parentAssessmentToolDetails,
                assessmentTool: createMypObjectiveRubric,
              };

              setTimeout(() =>
                writeAssessmentToolOfParentInCache({
                  id: parentId,
                  data: updatedAssessmentDetails,
                  type: parentType,
                })
              );
            }
          }
        },
      });
    } catch (e) {
      console.error(e);
      setTimeout(() =>
        writeAssessmentToolOfParentInCache({
          parentId,
          data: getAssessmentToolOfParentFromCache({
            id: parentId,
            type: parentType,
          }),
          type: parentType,
        })
      );
    }
  };
};

export const createExemplarInAssessment = ({ parentId, parentType }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createExemplarMutation,
        variables: {
          parentId,
          parentType,
          message: "",
          createdBy: getState().login.userInfo.id,
        },

        update: (
          cache,
          {
            data: {
              planner: { createExemplar },
            },
          }
        ) => {
          if (!_.isEmpty(createExemplar)) {
            let updatedAssessmentDetails = {};
            let parentAssessmentToolDetails;
            if (parentId) {
              parentAssessmentToolDetails = getAssessmentToolOfParentFromCache({
                id: parentId,
                type: parentType,
              });
              updatedAssessmentDetails = {
                ...parentAssessmentToolDetails,
                assessmentTool: createExemplar,
              };

              setTimeout(() =>
                writeAssessmentToolOfParentInCache({
                  id: parentId,
                  data: updatedAssessmentDetails,
                  type: parentType,
                })
              );
            }
          }
        },
      });
    } catch (e) {
      console.error(e);
      setTimeout(() =>
        writeAssessmentToolOfParentInCache({
          parentId,
          data: getAssessmentToolOfParentFromCache({
            id: parentId,
            type: parentType,
          }),
          type: parentType,
        })
      );
    }
  };
};

export const createAnecdoteInAssessment = ({ parentId, parentType }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createAnecdotalMutation,
        variables: {
          parentId,
          parentType,
          createdBy: getState().login.userInfo.id,
        },

        update: (
          cache,
          {
            data: {
              planner: { createAnecdote },
            },
          }
        ) => {
          if (!_.isEmpty(createAnecdote)) {
            let updatedAssessmentDetails = {};
            let parentAssessmentToolDetails;
            if (parentId) {
              parentAssessmentToolDetails = getAssessmentToolOfParentFromCache({
                id: parentId,
                type: parentType,
              });
              updatedAssessmentDetails = {
                ...parentAssessmentToolDetails,
                assessmentTool: createAnecdote,
              };

              setTimeout(() =>
                writeAssessmentToolOfParentInCache({
                  id: parentId,
                  data: updatedAssessmentDetails,
                  type: parentType,
                })
              );
            }
          }
        },
      });
    } catch (e) {
      console.error(e);
      setTimeout(() =>
        writeAssessmentToolOfParentInCache({
          parentId,
          data: getAssessmentToolOfParentFromCache({
            id: parentId,
            type: parentType,
          }),
          type: parentType,
        })
      );
    }
  };
};

/*
  Handle all types of Assessment Tool Elements local updates
  E.g - MYP_OBJECTIVE_DESCRIPTOR label update
*/

export const writeAssessmentToolElementLocal = ({ id, type, data }) => {
  return (dispatch, getState) => {
    const elementDetails = getAssessmentToolElementFromCache({ id, type });
    const updatedElementDetails = { ...elementDetails, ...data };
    writeAssessmentToolElementInCache({
      id,
      type,
      data: updatedElementDetails,
    });
  };
};

/*
  Handle all types of Assessment Tool Elements
  E.g - MYP_OBJECTIVE_DESCRIPTOR
*/
export const updateAssessmentToolElement = ({ id, data, type }) => {
  return async (dispatch, getState) => {
    try {
      let mutation = "";
      switch (type) {
        case "MYP_OBJECTIVE_DESCRIPTOR":
          mutation = editMypObjectiveRubricDescriptorMutation;
          break;
      }
      await client.mutate({
        mutation,
        variables: {
          id,
          ...data,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};
