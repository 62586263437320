import React from "react";
import classes from "./TagSnpModalV2.scss";
import { UIModal } from "UIComponents";
import TagSnp from "./TagSnp";

const TagSnpModalV2 = props => {
  const { closeModal } = props;

  return (
    <UIModal
      onRequestClose={closeModal}
      isOpen={true}
      modalContent={classes.container}
    >
      <TagSnp {...props} />
    </UIModal>
  );
};

export default TagSnpModalV2;
