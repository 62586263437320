import React from "react";
import classes from "./OrganizationCard.scss";
import { UIButton } from "UIComponents";

const OrganizationCard = props => {
  const { name, logo, linkedIdentityTitle, onSelectOrganization, t } = props;

  return (
    <div className={classes.container}>
      <figure className={classes.figureContainer}>
        <img src={logo} className={classes.figure} alt={name} />
      </figure>
      <div className={classes.infoContainer}>
        <div className={classes.orgName}>{name}</div>
        <div className={classes.identityTitle}>
          {linkedIdentityTitle === "Visitor"
            ? `${linkedIdentityTitle} - Authorization and Evaluation`
            : linkedIdentityTitle}
        </div>
      </div>

      <UIButton
        type={"hollow"}
        color={"pink"}
        className={classes.selectButtonContainer}
        onClick={onSelectOrganization}
      >
        {t("common:select_with_label")}
      </UIButton>
    </div>
  );
};

export default OrganizationCard;
