import React from "react";
import classes from "./FullScreenLoader.scss";
import Modal from "react-modal";
import Loading from "../Loading";
import classNames from "classnames";
class FullScreenLoader extends React.PureComponent {
  render() {
    const { overlayContainer } = this.props;
    const overlayContainerClass = classNames(
      { [classes.overlayContainer]: true },

      { [overlayContainer]: !!overlayContainer }
    );
    return (
      <Modal
        isOpen={true}
        overlayClassName={overlayContainerClass}
        className={classes.fullScreenModalContent}
        ariaHideApp={false}
      >
        <Loading containerStyle={{ height: "100%" }} />
      </Modal>
    );
  }
}

export default FullScreenLoader;
