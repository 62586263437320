import gql from "graphql-tag";
import {
  projectFragments,
  projectGroupFragments,
  studentProjectPortfolioFragments,
} from "./InviteFragments";
import { userFragment } from "modules/CommonFragments";

export const getProjectGroupSupervisorsQuery = gql`
  query getProjectGroupSupervisors(
    $projectGroupId: ID!
    $filters: ProjectGroupSupervisorFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        supervisors(filters: $filters) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              staff {
                ...userItem
                isArchived
              }
              projects {
                id
                title {
                  id
                  value
                }
                students {
                  ...userItem
                }
              }
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getProjectGroupProjectsQuery = gql`
  query getProjectGroupProjects(
    $projectGroupId: ID!
    $filters: ProjectGroupProjectsFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        projects(filters: $filters) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              ...projectInfo
            }
          }
        }
      }
    }
  }
  ${projectFragments.projectInfoFragment}
`;

export const getProjectGroupStudentPortfoliosQuery = gql`
  query getProjectGroupStudentPortfolios(
    $projectGroupId: ID!
    $filters: StudentProjectPortfolioFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        studentProjectPortfolio(filters: $filters) {
          totalCount
          edges {
            node {
              ...portfolioStudentAdvisor
            }
          }
        }
      }
    }
  }
  ${studentProjectPortfolioFragments.studentProjectPortfolioDetailsFragment}
`;

export const getProjectAllocationStatusQuery = gql`
  query getProjectAllocationStatus(
    $projectGroupId: ID!
    $filters: ProjectGroupProjectAllocationFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        projectAllocationStatus(staffId: 0, filters: $filters) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              allocated
              project {
                id
                students {
                  ...userItem
                }
              }
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getStudentsQuery = gql`
  query getStudents(
    $gradesArray: [ID!]
    $organizationId: ID!
    $archivalState: ARCHIVAL_STATE_ENUM
    $includeArchived: IncludeArchivedFromModuleInput
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ... on Organization {
        students(
          filters: {
            grades: $gradesArray
            archivalState: $archivalState
            includeArchived: $includeArchived
          }
        ) {
          totalCount
          edges {
            node {
              ...userItem
              isArchived
              grades
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getProjectGroupProjectsCountQuery = gql`
  query getProjectGroupProjectsCount($projectGroupId: ID!) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        projects {
          totalCount
        }
      }
    }
  }
`;

export const getProjectGroupSupervisorsCountQuery = gql`
  query getProjectGroupSupervisorsCount($projectGroupId: ID!) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        supervisors {
          totalCount
        }
      }
    }
  }
`;

export const getCourseSupervisorsCountQuery = gql`
  query getCourseSupervisorsCount($projectGroupId: ID!) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        courseSupervisors {
          totalCount
        }
      }
    }
  }
`;

export const getProjectGroupStudentsQuery = gql`
  query getProjectGroupStudents(
    $id: ID!
    $filters: ProjectGroupStudentFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        students(filters: $filters) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              student {
                ...projectGroupStudent
                projects(filters: { projectGroupId: $id }) {
                  id
                  title {
                    id
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${projectGroupFragments.projectGroupStudentFragment}
`;

export const getProjectGroupStudentCountQuery = gql`
  query getProjectGroupStudentCount($projectGroupId: ID!) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        students {
          totalCount
        }
      }
    }
  }
`;

export const getProjectStudentAllocationStatusQuery = gql`
  query getProjectStudentAllocationStatus(
    $projectGroupId: ID!
    $filters: ProjectGroupStudentAllocationFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        projectStudentAllocationStatus(filters: $filters) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              student {
                ...userItem
              }
              allocated
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getProjectSupervisorAndStudentsQuery = gql`
  query getProjectSupervisorAndStudents($projectId: ID!) {
    node(id: $projectId, type: PROJECT) {
      ... on Project {
        id
        supervisors {
          ...userItem
        }
        students {
          ...userItem
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getProjectParticipantsQuery = gql`
  query getInviteProjectInfo($projectId: ID!) {
    node(id: $projectId, type: PROJECT) {
      ... on Project {
        id
        students {
          ...userItem
        }
        supervisors {
          ...userItem
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getSupervisorProjectsQuery = gql`
  query getSupervisorProjects($staffId: ID!, $filters: ProjectFilter) {
    node(id: $staffId, type: STAFF) {
      ... on Staff {
        id
        projects(filters: $filters) {
          id
          students {
            ...userItem
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;
