import update from "immutability-helper";
import {
  createLikeMutation,
  deleteLikeMutation,
  deletePostMutation,
  updatePostMutation,
  bulkPostsActionMutation,
  addSeenMutation,
} from "Post/modules/PostMutations";
import {
  createFolderMutation,
  deleteFolderMutation,
  updateFolderMutation,
} from "Journal/modules/JournalMutations";
import { getCourseFoldersDetailsQuery } from "Journal/modules/JournalQuery";
import {
  getCourseJournalFeedFromCache,
  writeCourseJournalFeedToCache,
  writePostDetailFragment,
} from "Post/modules/PostGraphqlHelpers";

import {
  getCourseAllStudentQuery,
  getCoursePostFeedQuery,
} from "Post/modules/PostQuery";
import { generateRandomId } from "Utils";
import { setToastMsg } from "Login/modules/LoginModule";
import { getFieldUids } from "Post/modules/PostModule";
import {
  isJournalCardRevamp,
  hasTranslationPermission,
  getPostTranslationFilter,
} from "Post/utils";

import client from "apolloClient";

export const NAME = "journal";

export const TOGGLE_LOADING = "TOGGLE_LOADING" + " " + NAME;
export const UPDATE_FILTER_OBJECT = "UPDATE_FILTER_OBJECT" + " " + NAME;

export const toggleLoading = data => {
  return {
    type: TOGGLE_LOADING,
    data,
  };
};

export const updateFilterObject = data => {
  return { type: UPDATE_FILTER_OBJECT, data: data };
};

export const createFolder = ({ name, color }) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    try {
      await client.mutate({
        mutation: createFolderMutation,
        variables: {
          courseId,
          name: name,
          color,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getCourseFoldersDetailsQuery,
            variables: { courseId: courseId },
          },
        ],
      });
      setToastMsg({
        msg: "toastMsgs:folder_successfully_action",
        locale_params: [
          { key: "action", value: "toastMsgs:created", isPlainText: false },
        ],
        type: "tick",
        position: "toast-bottom-left",
      });
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw err;
    }
  };
};

export const updateFolder = ({ id, name, color }) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    try {
      await client.mutate({
        mutation: updateFolderMutation,
        variables: {
          id,
          courseId,
          name: name,
          color,
        },
      });
      setToastMsg({
        msg: "toastMsgs:folder_successfully_action",
        locale_params: [
          { key: "action", value: "toastMsgs:updated", isPlainText: false },
        ],
        type: "tick",
        position: "toast-bottom-left",
      });
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw err;
    }
  };
};

export const deleteFolder = id => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    try {
      await client.mutate({
        mutation: deleteFolderMutation,
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getCourseFoldersDetailsQuery,
            variables: { courseId: courseId },
          },
        ],
      });
      setToastMsg({
        msg: "toastMsgs:folder_successfully_action",
        locale_params: [
          { key: "action", value: "toastMsgs:deleted", isPlainText: false },
        ],
        type: "tick",
        position: "toast-bottom-left",
      });
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const bulkPostsAction = ({ action }) => {
  return async (dispatch, getState) => {
    try {
      const filters = getState().journal.filters;
      const courseId = getState().teacher.selected_class.selected_course;
      const curriculumProgramType = getState().platform.currentCurriculumProgram
        .type;
      const fieldUids = getFieldUids({ curriculumProgramType });
      dispatch(toggleLoading(true));

      const translationFilter = getPostTranslationFilter({
        userInfo: _.get(getState(), "login.userInfo", {}),
        shouldTranslate: false,
        handleTranslation: hasTranslationPermission(),
      });

      await client.mutate({
        mutation: bulkPostsActionMutation,
        variables: {
          input: { ...filters, action, courseId },
        },
        refetchQueries: [
          {
            query: getCourseAllStudentQuery,
            variables: { id: courseId },
          },
          {
            query: getCoursePostFeedQuery,
            variables: {
              id: courseId,
              filters,
              uids: fieldUids,
              translationFilter: translationFilter,
            },
          },
        ],
      });

      let actionString = "";

      switch (action) {
        case "APPROVE":
          actionString = "approved";
          dispatch(updateFilterObject({ state: "PUBLISHED" }));
          break;

        case "REJECT":
          actionString = "rejected";
          break;

        default:
          actionString = "updated";
      }
      dispatch(toggleLoading(false));
      dispatch(
        setToastMsg({
          msg: `toastMsgs:posts_action_successfully`,
          locale_params: [
            {
              key: "action",
              value: `toastMsgs:${actionString}`,
              isPlainText: false,
            },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      dispatch(toggleLoading(false));
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const updatePostStatus = (
  postId,
  status = "DRAFT",
  comment = {},
  showToast = true
) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = getState().journal.filters;
    const currentState = _.get(filters, "state", "DRAFT");
    const userId = getState().login.userInfo.id;
    const curriculumProgramType = getState().platform.currentCurriculumProgram
      .type;
    const fieldUids = getFieldUids({ curriculumProgramType });
    const translationFilter = getPostTranslationFilter({
      userInfo: _.get(getState(), "login.userInfo", {}),
      shouldTranslate: false,
      handleTranslation: hasTranslationPermission(),
    });

    let courseDetails = getCourseJournalFeedFromCache({
      courseId: courseId,
      filters: filters,
      uids: fieldUids,
      translationFilter: translationFilter,
    });

    let posts = _.get(courseDetails, "posts", {});
    let edges = _.get(posts, "edges", []);
    const postDetail = _.find(edges, obj => obj.node.id == postId);
    if (_.isEmpty(postDetail)) {
      return;
    }
    const { approveComment, rejectComment } = comment;

    try {
      await client.mutate({
        mutation: updatePostMutation,
        variables: {
          postId: postId,
          courseId,
          updatedBy: userId,
          state: status,
          approvComment: approveComment,
          rejectComment: rejectComment,
        },
        optimisticResponse: {
          __typename: "Mutation",
          documentation: {
            __typename: "DocumentationMutations",
            updatePost: {
              ...postDetail.node,
              state: status,
            },
          },
        },
        update: (
          cache,
          {
            data: {
              documentation: { updatePost },
            },
          }
        ) => {
          courseDetails = getCourseJournalFeedFromCache({
            courseId: courseId,
            filters: filters,
            uids: fieldUids,
            translationFilter: translationFilter,
          });
          posts = _.get(courseDetails, "posts", {});
          edges = _.filter(
            _.get(posts, "edges", []),
            obj => obj.node.id != postId
          );

          writeCourseJournalFeedToCache({
            courseId: courseId,
            data: {
              ...courseDetails,
              posts: {
                ...posts,
                edges,
                totalCount: posts.totalCount - 1,
              },
            },
            filters: {
              ...filters,
              state: currentState,
            },
            uids: fieldUids,
            translationFilter: translationFilter,
          });

          courseDetails = getCourseJournalFeedFromCache({
            courseId: courseId,
            filters: {
              ...filters,
              state: status,
            },
            uids: fieldUids,
            translationFilter: translationFilter,
          });
          if (!_.isEmpty(courseDetails)) {
            posts = _.get(courseDetails, "posts", {});
            edges = _.get(posts, "edges", []);
            edges = [{ node: updatePost, __typename: "PostEdge" }, ...edges];

            writeCourseJournalFeedToCache({
              courseId: courseId,
              data: {
                ...courseDetails,
                posts: {
                  ...posts,
                  edges,
                  totalCount: posts.totalCount + 1,
                },
              },
              filters: {
                ...filters,
                state: status,
              },
              uids: fieldUids,
              translationFilter: translationFilter,
            });
          }
        },
        refetchQueries: courseId
          ? [
              {
                query: getCourseAllStudentQuery,
                variables: { id: courseId },
              },
            ]
          : [],
      });
      if (!!showToast) {
        dispatch(
          setToastMsg({
            msg: _.isEqual(status, "REJECTED")
              ? "toastMsgs:post_returned_for_review"
              : "toastMsgs:post_action_successfully",
            locale_params: [
              { key: "action", value: "toastMsgs:updated", isPlainText: false },
            ],
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      }
      return true;
    } catch (err) {
      if (!!showToast) {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      return false;
    }
  };
};

export const deletePost = postId => {
  return async (dispatch, getState) => {
    const filters = { ...getState().journal.filters };
    if (
      _.get(getState(), ".login.userInfo.user_type", "") == "student" &&
      isJournalCardRevamp()
    ) {
      if (_.has(filters, "state")) filters.state = undefined;
    }
    const courseId = getState().teacher.selected_class.selected_course;
    const curriculumProgramType = getState().platform.currentCurriculumProgram
      .type;
    const fieldUids = getFieldUids({ curriculumProgramType });
    const translationFilter = getPostTranslationFilter({
      userInfo: _.get(getState(), "login.userInfo", {}),
      shouldTranslate: false,
      handleTranslation: hasTranslationPermission(),
    });
    const courseJournalFeed = getCourseJournalFeedFromCache({
      courseId,
      filters,
      uids: fieldUids,
      translationFilter: translationFilter,
    });

    const updatePostEdges = _.filter(
      _.get(courseJournalFeed, "posts.edges", []),
      item => item.node.id != postId
    );
    const updatedPosts = {
      ...courseJournalFeed.posts,
      edges: updatePostEdges,
      totalCount: updatePostEdges.length,
    };
    const updatedCourseFeed = update(courseJournalFeed, {
      posts: { $set: updatedPosts },
    });

    try {
      await client.mutate({
        mutation: deletePostMutation,
        variables: {
          id: postId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          documentation: {
            __typename: "DocumentationMutations",
            deletePost: { isSuccess: true, __typename: "DeleteOutput" },
          },
        },
        update: (
          cache,
          {
            data: {
              documentation: { deletePost },
            },
          }
        ) => {
          if (deletePost && deletePost.isSuccess) {
            writeCourseJournalFeedToCache({
              courseId,
              filters,
              data: updatedCourseFeed,
              uids: fieldUids,
              translationFilter: translationFilter,
            });
          } else {
            writeCourseJournalFeedToCache({
              courseId,
              filters,
              data: courseJournalFeed,
              uids: fieldUids,
              translationFilter: translationFilter,
            });
          }
        },
        refetchQueries: [
          {
            query: getCourseAllStudentQuery,
            variables: { id: courseId },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:post_action_successfully",
          locale_params: [
            { key: "action", value: "toastMsgs:deleted", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};
export const createPostLike = ({
  postId,
  postDetails,
  fragmentName = "journalPost",
}) => {
  return async (dispatch, getState) => {
    const likes = postDetails.likes.edges;
    const userInfo = getState().login.userInfo;
    let newLike = {
      id: generateRandomId(),
      user: { __typename: "User" },
      __typename: "Like",
    };
    const { id, first_name: firstName, last_name: lastName } = userInfo;
    newLike["user"] = {
      id,
      firstName,
      lastName,
      profileImage: _.get(userInfo, "profile_image", ""),
      __typename: "User",
    };

    const updatedLikes = [...likes, { node: newLike, __typename: "LikeEdge" }];
    const likedUsersNode = {
      ...postDetails.likes,
      edges: updatedLikes,
      totalCount: postDetails.likes.totalCount + 1,
    };

    writePostDetailFragment({
      postId,
      data: { ...postDetails, likes: likedUsersNode },
      fragmentName,
    });

    try {
      await client.mutate({
        mutation: createLikeMutation,
        variables: {
          userId: userInfo.id,
          parentId: postId,
          parentType: "POST",
        },
      });
    } catch (e) {
      writePostDetailFragment({
        postId,
        data: postDetails,
        fragmentName,
      });
    }
  };
};

export const deletePostLike = ({
  postId,
  postDetails,
  fragmentName = "journalPost",
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const likes = _.filter(
      postDetails.likes.edges,
      item => _.get(item, "node.user.id", "") != userId
    );

    let likedUsersNode = {
      ...postDetails.likes,
      edges: likes,
      totalCount: likes.length,
    };

    writePostDetailFragment({
      postId,
      data: { ...postDetails, likes: likedUsersNode },
      fragmentName,
    });
    try {
      await client.mutate({
        mutation: deleteLikeMutation,
        variables: {
          userId: userId,
          parentId: postId,
          parentType: "POST",
        },
      });
    } catch (e) {
      writePostDetailFragment({
        postId,
        data: postDetails,
        fragmentName,
      });
    }
  };
};

export const markPostAsSeenByUser = ({
  parentIds,
  parentType = "POST",
  userId,
  postDetails,
}) => {
  // ParentIds = array of post-id
  return async (dispatch, getState) => {
    try {
      const seenEdges = postDetails.seen.edges;
      const userInfo = getState().login.userInfo;
      let newSeen = {
        id: generateRandomId(),
        user: { __typename: "User" },
        __typename: "Seen",
      };
      const { id, first_name: firstName, last_name: lastName } = userInfo;
      newSeen["user"] = {
        id,
        firstName,
        lastName,
        profileImage: _.get(userInfo, "profile_image", ""),
        __typename: "User",
      };

      const updatedSeenData = [
        ...seenEdges,
        { node: newSeen, __typename: "SeenEdge" },
      ];
      const seenUsersNode = {
        ...postDetails.seen,
        edges: updatedSeenData,
        totalCount: postDetails.seen.totalCount + 1,
      };

      writePostDetailFragment({
        postId: parentIds[0],
        data: { ...postDetails, seen: seenUsersNode },
      });

      const result = await client.mutate({
        mutation: addSeenMutation,
        variables: {
          input: { userId, parentIds, parentType },
        },
      });
    } catch (e) {
      console.error(e);
      writePostDetailFragment({
        postId: parentIds[0],
        data: postDetails,
      });
      //dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_FILTER_OBJECT]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        filters: { [key]: { $set: params[key] } },
      });
    });

    return state;
  },
  [TOGGLE_LOADING]: (state, action) => {
    return update(state, {
      isLoading: { $set: action.data },
    });
  },
};

const initialState = {
  isLoading: false,
  filters: {
    state: "PUBLISHED",
    orderBy: "PUBLISHED_AT",
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
