import React from "react";
import classes from "./Footer.scss";
import { Button } from "@toddle-design/web";

const Footer = props => {
  const {
    t,
    onClickCancel,
    onClickAdd,
    isAddButtonDisabled,
    selectedEvidenceCount,
    addButtonLabel,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.evidenceCountCon}>
        {t("snp:selected_evidence_count", {
          count: selectedEvidenceCount,
        })}
      </div>
      <div className={classes.btnCon}>
        <div className={classes.cancelButton}>
          <Button
            variant={"outlined-subtle"}
            onClick={onClickCancel}
            size={"large"}
          >
            {t(`common:cancel`)}
          </Button>
        </div>
        <Button
          variant={"progressive"}
          disabled={isAddButtonDisabled}
          onClick={onClickAdd}
          size={"large"}
        >
          {addButtonLabel ? addButtonLabel : t(`common:add`)}
        </Button>
      </div>
    </div>
  );
};

export default Footer;
