import client from "apolloClient";
import {
  getUserCoursesQuery,
  getStaffCoursesQuery,
  getStaffCoursesStudentsQuery,
  getStaffCoursesBasicDetailsQuery,
  getStaffCourseGenericFoldersBasicDetailsQuery,
  getStudentCourseTagsQuery,
} from "./TeacherQuery";

//Don't use it. Will deplicate soon. Use getUserCoursesFromCache instead
export const getStaffCoursesFromCache = (user_id, isCurriculumProgramFree) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffCoursesQuery,
      variables: {
        id: user_id,
        isCurriculumProgramFree,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getUserCoursesFromCache = ({
  id,
  type,
  filters,
  isCurriculumProgramFree = false,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUserCoursesQuery,
      variables: {
        id,
        type,
        filters,
        isCurriculumProgramFree,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStaffCoursesStudentsFromCache = ({
  id,
  searchText,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffCoursesStudentsQuery,
      variables: {
        id,
        searchText,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStaffCoursesBasicDetailsFromCache = ({ userId, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffCoursesBasicDetailsQuery,
      variables: {
        id: userId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStaffCoursesGenericFolderBasicDetailsFromCache = ({
  id,
  folderType,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffCourseGenericFoldersBasicDetailsQuery,
      variables: {
        id,
        folderType,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentCourseTagsFromCache = ({ id, courseIds }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentCourseTagsQuery,
      variables: {
        id: id,
        courseIdsFilter: { courseIds },
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
