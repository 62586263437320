import React from "react";
import classes from "./CriteriaRatingV2.scss";
import classNames from "classnames";

//Use this version when you only have highest Mark and don't have the options for Criteria Rating.
const CriteriaRatingV2 = ({
  highestMark,
  id,
  isEditable,
  ratedCriteria,
  width,
  onSelectMark,
  type,
}) => {
  return (
    <div className={classes.ratingElementsContainer} style={{ width }}>
      {_.times(highestMark + 1, mark => {
        const isSelected =
          _.find(ratedCriteria, {
            ibPYPElementId: id,
          })?.value == mark;

        const ratingElementClass = classNames(
          { "text-body-3": true },
          { [classes.ratingElement]: true },
          { [classes.ratingElementDisabled]: !isEditable },
          { [classes.selectedRatingElement]: isSelected }
        );

        return (
          <div
            className={ratingElementClass}
            key={mark}
            onClick={e => {
              //To avoid expanding accordian
              e.stopPropagation();
              onSelectMark({ mark, isSelected, id, type });
            }}
          >
            {mark}
          </div>
        );
      })}
    </div>
  );
};

export default CriteriaRatingV2;
