import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import moment from "moment";
import {
  isDayOutSideRange,
  getOperationalDates,
  getOperationalDaysMemoize,
  getAlpha,
} from "Utils";
import {
  upsertCourseAttendanceMutationV2,
  updatePeriodSelectionMutation,
} from "Attendance/modules/AttendanceMutations";
import {
  getCourseAttendanceQuery,
  getCoursePeriodListQuery,
  getCourseSingleDateAttendanceQuery,
} from "Attendance/modules/AttendanceQuery";
import {
  getCurrentAcademicYear,
  ATTENDANCE_GLOBAL_CONSTANTS,
  getCourseAcademicYearDates,
} from "modules/Services";

import {
  getOrganizationPeriodSetsFromCache,
  getOrganizationConstantsFromCache,
} from "modules/CommonGraphqlHelpers";
import ACLStore from "lib/aclStore";

import client from "apolloClient";

const WEEK_DAYS_LOCALES = [
  "common:sunday",
  "common:monday",
  "common:tuesday",
  "common:wednesday",
  "common:thursday",
  "common:friday",
  "common:saturday",
];

export const NAME = "attendance";
export const UPDATE_STATE = "UPDATE_STATE" + " " + NAME;

export const updateState = data => {
  return {
    type: UPDATE_STATE,
    data,
  };
};

export const getGlobalConstantByName = ({
  globalConstants,
  name,
  defaultValue,
}) => {
  return _.get(
    _.find(globalConstants, {
      name,
    }),
    "value",
    defaultValue
  );
};

export const getRotationDayLabel = ({ rotationDay }) => {
  return `${rotationDay}/${getAlpha(rotationDay)}`;
};

export const getOrganizationConstants = ({
  organizationId,
  curriculumProgramType,
  periodSetFilter,
  globalConstants,
}) => {
  const useOrganizationGlobalConstants = ACLStore.can(
    "FeatureFlag:UseOrganizationGlobalConstants"
  );

  const organizationDetails = getOrganizationPeriodSetsFromCache(
    organizationId,
    periodSetFilter
  );

  const organizationConstants = getOrganizationConstantsFromCache(
    organizationId
  );
  const schedulerConstants =
    _.get(organizationConstants, "scheduler", {}) || {};

  const {
    count_holiday_as_rotation_day,
    no_of_days_in_rotation_cycle,
    operational_days: operationalDaysFromConstants,
    routine_mode,
    week_length,
    week_start_day,
  } = schedulerConstants;

  const opertionalDaysArr = getOperationalDaysMemoize({
    schedulerConstants,
    operationalDaysFromConstants,
  });

  const operationalDaysFromGlobalConstants = getGlobalConstantByName({
    globalConstants,
    name: ATTENDANCE_GLOBAL_CONSTANTS.OPERATIONAL_DAYS,
    defaultValue: undefined,
  });

  const noOfDaysFromGlobalConstants = getGlobalConstantByName({
    globalConstants,
    name: ATTENDANCE_GLOBAL_CONSTANTS.NO_OF_DAYS_IN_ROTATION_CYCLE,
    defaultValue: 0,
  });

  const countHolidayAsRotationFromGlobalConstants = getGlobalConstantByName({
    globalConstants,
    name: ATTENDANCE_GLOBAL_CONSTANTS.COUNT_HOLIDAYS_AS_ROTATION_DAY,
    defaultValue: false,
  });

  const attendanceRecordingTypeFromGlobalConstants = getGlobalConstantByName({
    globalConstants,
    name: ATTENDANCE_GLOBAL_CONSTANTS.ATTENDANCE_RECORDING_TYPE,
    defaultValue: "ONCE_A_DAY",
  });

  const rotineModeFromGlobalConstants = getGlobalConstantByName({
    globalConstants,
    name: ATTENDANCE_GLOBAL_CONSTANTS.ROUTINE_MODE,
    defaultValue: "OPERATIONAL_DAYS",
  });

  const routineMode = useOrganizationGlobalConstants
    ? rotineModeFromGlobalConstants
    : routine_mode;
  const countHolidayAsRotationDay = useOrganizationGlobalConstants
    ? countHolidayAsRotationFromGlobalConstants
    : count_holiday_as_rotation_day;
  const noOfDaysInRotationCycle = useOrganizationGlobalConstants
    ? noOfDaysFromGlobalConstants
    : no_of_days_in_rotation_cycle;
  const operationalDays = useOrganizationGlobalConstants
    ? operationalDaysFromGlobalConstants
    : opertionalDaysArr;

  const isRotationCycle = routineMode === "ROTATION_CYCLE";

  const currentCurriculumPeriodObj =
    _.find(_.get(organizationDetails, "curriculumPrograms"), [
      "curriculum.type",
      curriculumProgramType,
    ]) || {};

  const periodSet = _.get(currentCurriculumPeriodObj, "periodSet") || {};
  const periods = _.get(periodSet, "periods");
  const attendanceRecordingTypeFromCurriculum = _.get(
    currentCurriculumPeriodObj,
    "attendanceRecordingType",
    ""
  );
  const attendanceRecordType = useOrganizationGlobalConstants
    ? attendanceRecordingTypeFromGlobalConstants
    : attendanceRecordingTypeFromCurriculum;
  const isPeriodByAttendance = attendanceRecordType === "EACH_PERIOD";
  const periodIds = isPeriodByAttendance ? _.map(periods, "id") : undefined;

  return {
    periodSet,
    isPeriodByAttendance,
    periodIds,
    countHolidayAsRotationDay,
    noOfDaysInRotationCycle,
    operationalDays,
    routineMode,
    attendanceRecordType,
    weekLength: week_length,
    weekStartDay: week_start_day,
    isRotationCycle,
  };
};

const getPeriodsAvailableBasedOnDate = ({
  selectedPeriods,
  date,
  isRotationCycle,
  rotationDay,
}) => {
  const weekdayNumber = moment(date).days();
  return _.sortBy(
    _.map(
      _.filter(
        selectedPeriods,
        !isRotationCycle ? ["day", weekdayNumber] : ["rotationDay", rotationDay]
      ),
      ({ period }) => ({ label: period.label, value: period.id })
    ),
    "label"
  );
};

export const getPeriodsAvailableBasedOnDateMemoize = _.memoize(
  params => getPeriodsAvailableBasedOnDate(params),
  params => JSON.stringify(params)
);

export const getDateWiseAttendance = ({
  students,
  attendanceSummary = [],
  startDate,
  endDate,
  schedulerConstants,
  attendanceOverAllSummary = [],
  operationalDaysFromConstants,
}) => {
  const dateWiseAttendance = [];
  const { operationalDates: weekDates } = getOperationalDates({
    weekStartDate: startDate,
    weekEndDate: endDate,
    schedulerConstants,
    operationalDaysFromConstants,
  });
  _.forEach(students, student => {
    const studentObj = {};
    const dateWiseValue = [];

    studentObj["student"] = _.get(student, "node", {});

    _.forEach(weekDates, date => {
      const formattedDate = moment(date).format("YYYY-MM-DD").toString();
      const studentDateSummary = _.find(
        attendanceSummary,
        summary =>
          _.get(summary, "student.id", "") == _.get(student, "node.id", "") &&
          moment(summary.date).format("YYYY-MM-DD").toString() == formattedDate
      );

      dateWiseValue.push({
        date,
        value: _.get(studentDateSummary, "value", {}),
        remark: _.get(studentDateSummary, "remark", ""),
      });
    });

    studentObj["overAllSummary"] = _.get(
      _.find(
        attendanceOverAllSummary,
        item => item.student.id == _.get(student, "node.id", "")
      ),
      "attendanceCount",
      []
    );

    studentObj["dateWiseValue"] = dateWiseValue;
    dateWiseAttendance.push(studentObj);
  });

  return dateWiseAttendance;
};

const getRotationDayText = ({
  rotationDays,
  isPeriodByAttendance,
  isSchoolWorking,
  weekStartDate,
  weekEndDate,
  t,
}) => {
  if (isPeriodByAttendance && isSchoolWorking) {
    const rotationDay = _.get(
      _.find(rotationDays, rotationDayObj =>
        moment(weekStartDate, "YYYY-MM-DD").isSame(
          _.get(rotationDayObj, "date", ""),
          "day"
        )
      ),
      "rotationDay",
      ""
    );
    return rotationDay
      ? t("common:day_single", { day: getRotationDayLabel({ rotationDay }) })
      : null;
  } else if (!isPeriodByAttendance && _.get(rotationDays, "length")) {
    const filteredRotationDays = _.filter(
      rotationDays,
      item =>
        moment(item.date).isSameOrAfter(weekStartDate, "day") &&
        moment(item.date).isSameOrBefore(weekEndDate, "day")
    );
    return t("common:day_range", {
      day1: getRotationDayLabel({
        rotationDay: _.get(_.first(filteredRotationDays), "rotationDay", ""),
      }),
      day2: getRotationDayLabel({
        rotationDay: _.get(_.last(filteredRotationDays), "rotationDay", ""),
      }),
    });
  }
  return null;
};

export const getRotationDayTextMemoize = _.memoize(
  params => getRotationDayText(params),
  params => JSON.stringify(params)
);

export const getPeriodWiseAttendance = ({
  students,
  attendanceSummary = [],
  startDate,
  periodSet,
}) => {
  const periodWiseAttendance = [];

  _.forEach(students, student => {
    const studentObj = {};
    const periodWiseValue = [];

    studentObj["student"] = _.get(student, "node", {});

    _.forEach(periodSet, period => {
      const studentDateSummary = _.find(
        attendanceSummary,
        summary =>
          _.get(summary, "student.id", "") == _.get(student, "node.id", "") &&
          _.get(summary, "period.id") == _.get(period, "id")
      );

      periodWiseValue.push({
        period,
        value: _.get(studentDateSummary, "value", {}),
        remark: _.get(studentDateSummary, "remark", ""),
      });
    });

    // studentObj["overAllSummary"] = _.get(
    //   _.find(
    //     attendanceOverAllSummary,
    //     item => item.student.id == _.get(student, "node.id", "")
    //   ),
    //   "attendanceCount",
    //   []
    // );

    studentObj["periodWiseValue"] = periodWiseValue;
    periodWiseAttendance.push(studentObj);
  });

  return periodWiseAttendance;
};

export const updatePeriodSelection = data => {
  return async (dispatch, getState) => {
    try {
      const {
        courseId,
        addedSelections,
        removedSelections,
        routineMode,
        currentDate,
      } = data;
      await client.mutate({
        mutation: updatePeriodSelectionMutation,
        variables: {
          input: {
            courseId,
            addedSelections,
            removedSelections,
          },
        },
        refetchQueries: [
          {
            query: getCoursePeriodListQuery,
            variables: {
              id: courseId,
              filters: {
                routineMode,
                startDate: currentDate,
                endDate: currentDate,
              },
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:period_configuration_saved",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      console.error(e);
      throw e;
    }
  };
};

export const upsertCourseAttendance = ({
  date,
  studentAttendance,
  shouldSendSMS,
  activePeriod,
  isPeriodByAttendance,
  periodIds,
  operationalDays,
  academicYearId,
  curriculumProgramId,
}) => {
  return async (dispatch, getState) => {
    const activeDay = _.get(getState(), "attendance.activeDay");

    const courseId = getState().teacher.selected_class.selected_course;
    const { startDate, endDate } = getCourseAcademicYearDates(courseId);
    const { weekStartDate, searchText } = getState().attendance;

    try {
      await client.mutate({
        mutation: upsertCourseAttendanceMutationV2,
        variables: {
          courseId: courseId,
          date: moment(date).format("YYYY-MM-DD").toString(),
          studentAttendance,
          shouldSendSMS,
          showDeletedRemark: true,
          periodId: isPeriodByAttendance ? activePeriod : undefined,
          academicYearId,
          curriculumProgramId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getCourseAttendanceQuery,
            variables: {
              id: courseId,
              startDate: isPeriodByAttendance ? activeDay : weekStartDate,
              endDate: isPeriodByAttendance
                ? activeDay
                : moment(weekStartDate)
                    .day(_.last(operationalDays) || 0)
                    .format("YYYY-MM-DD"),
              searchText: searchText,
              courseStartDate: startDate,
              courseEndDate: endDate,
              periodIds,
            },
          },
          {
            query: getCourseSingleDateAttendanceQuery,
            variables: {
              id: courseId,
              date,
              showDeletedRemark: true,
              periodIds: isPeriodByAttendance ? [activePeriod] : undefined,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:attendance_successfully_submitted",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      console.error(e);
      throw e;
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_STATE]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        [key]: { $set: params[key] },
      });
    });
    return state;
  },
};

const initialState = {
  weekStartDate: moment().isoWeekday(0).format("YYYY-MM-DD"),
  weekLength: 5,
  weekStartDay: 0,
  searchText: "",
  activeDay: moment().format("YYYY-MM-DD"),
  activePeriod: null,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const isOutsideRange = ({
  day,
  startDate,
  isEditingPastDaysAttendanceAllowed,
}) => {
  const maxDate = moment().format("YYYY-MM-DD");
  const minDate = isEditingPastDaysAttendanceAllowed
    ? moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    : moment().subtract("1 day").format("YYYY-MM-DD");

  return isDayOutSideRange({ day, maxDate, minDate });
};

export const getTimeTableRowHeaderLabel = ({ routineMode, item, t }) => {
  if (routineMode === "ROTATION_CYCLE") {
    return t("common:rotation_day_text", {
      day: item,
    });
  }
  return t(WEEK_DAYS_LOCALES[item]);
};

export const filterPeriodsOfSameDay = ({
  periodsAvailableBasedOnDayValues,
  filteredTableData,
}) => {
  return _.map(filteredTableData, item => {
    return {
      ...item,
      periodWiseValue: _.filter(
        _.get(item, "periodWiseValue", []),
        periodValue => {
          return _.includes(
            periodsAvailableBasedOnDayValues,
            _.get(periodValue, "period.id", "")
          );
        }
      ),
    };
  });
};

export const getConstantByName = ({ constants, constantName }) =>
  _.get(
    _.find(constants, ({ name }) => name === constantName),
    "value"
  );
