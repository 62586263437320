import React from "react";
import PropTypes from "prop-types";
import classes from "./TagInput.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { Tag } from "../Tags";
import classNames from "classnames";

class TagInput extends UIBaseComponent {
  isValidValue = value => !_.isEmpty(value);

  handleTagClick = item => {
    const { value, multiSelect, options, handleClick } = this.props;
    let updatedResult = [];

    if (_.includes(value, item.value))
      updatedResult = _.filter(value, tagId => tagId !== item.value);
    else updatedResult = multiSelect ? [...value, item.value] : [item.value];

    this.updateValue(updatedResult, options);
    if (handleClick) handleClick(item);
  };

  renderEdit = () => {
    const { options, value, styles, selectedTheme } = this.props;
    const { error } = this.state;
    const containerClasses = classNames(
      {
        [classes.container]: true,
      },
      { [classes.errorContainer]: !!error }
    );

    return (
      <div className={containerClasses}>
        {_.map(options, item => (
          <Tag
            theme={selectedTheme}
            styles={styles}
            selected={_.includes(value, item.value)}
            key={item.value}
            item={item}
            onClick={this.handleTagClick}
          />
        ))}
      </div>
    );
  };
}

TagInput.defaultProps = {
  ...UIBaseComponent.defaultProps,
  multiSelect: false,
  options: [],
  value: [],
};

TagInput.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
};

export default TagInput;
