import React, { useState, useEffect, useRef } from "react";
import classes from "./EditOrCreateEvent.scss";
import PropTypes from "prop-types";
import { UIModal, I18nHOC, FullScreenLoader } from "UIComponents";
import AddAssignment from "ClassRoom/components/AddAssignment";
import { compose } from "redux";
import ScheduleEvent from "ClassRoom/components/AddAssignment/ScheduleEvent";
import {
  MYP_CALENDAR_OPTIONS_NEW,
  ASSIGNMENT_CREATE_OPTIONS_NEW_CALENDAR,
  UBD_CALENDAR_OPTIONS,
  getDropdownOptions as getOptionsForDropdown,
} from "ClassRoom/modules/ClassRoomModule";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";
import { createAssessment } from "Assessments/modules/AssessmentModule";
import { connect } from "react-redux";
import { Dropdown, DropdownMenu } from "@toddle-design/web";

const EditOrCreateEvent = React.memo(props => {
  const {
    mode,
    courseId,
    course,
    courseList,
    authTabs,
    showOverlay,
    showDropdown,
    refetchEvents,
    buttonComponent,
    selectedTimeSlot,
    onOverlayClickClose,
    calendarEventModalClose,
    btnDropdownStyles,
    editModeDetails,
    popoverProps,
    preSelectedCourseIds,
    curriculumProgramType,
    t,
    pageName,
    createAssessment,
  } = props;

  const [assignmentModalData, setAssignmentModalData] = useState({});
  const [showAddAssignmentModal, setShowAddAssignmentModal] = useState(false);
  const [showScheduleEventModal, setScheduleEventModal] = useState(false);
  const preselectedCoursesLength = _.size(preSelectedCourseIds);
  const [clonedAssessmentId, setClonedAssessmentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedSlotClicked, setSelectedSlotClicked] = useState(false);

  const dropdownButtonRef = useRef(null);

  const optionsToExcludeInCalendar =
    preselectedCoursesLength == 1
      ? ["IMPORT_LE_FROM_COMMUNITY"]
      : ["IMPORT_LE_FROM_COMMUNITY", "IMPORT_LE_FROM_LE_LIBRARY"];

  const getDropdownOptions = () => {
    const options =
      curriculumProgramType === CURRICULUM_TYPE_UBD
        ? UBD_CALENDAR_OPTIONS
        : curriculumProgramType === CURRICULUM_TYPE_MYP ||
          curriculumProgramType === CURRICULUM_TYPE_DP
        ? MYP_CALENDAR_OPTIONS_NEW
        : ASSIGNMENT_CREATE_OPTIONS_NEW_CALENDAR;

    return getOptionsForDropdown({
      t,
      options,
      optionsToExcludeInCalendar,
    });
  };

  const clonedOptions = getDropdownOptions();

  /**
   * This function is used to create Steps for Assignment/ Event
   * Only used when mode==='create
   */
  const onDropdownItemClick = async ({ key }) => {
    const newAssignmentModalData = {
      mode: "create",
      creationMode: key,
      creationSource: "CALENDAR",
      courseId,
      pageName,
    };
    let clonedAssessmentId, reload;

    switch (key) {
      //TODO : Need to fix this academicYears for school calendar on Home page
      case "CREATE_QUICK_TASK":
        setLoading(true);
        clonedAssessmentId = await createAssessment({
          unitPlanObj: {
            unitPlanId: -1,
          },
          academicYears: _.map(
            _.get(course, "academicYears", []),
            item => item.id
          ),
          assessmentType: "qt",
          groupType: "QUICK_TASK",
        });
        setClonedAssessmentId(clonedAssessmentId);
        setLoading(false);

        newAssignmentModalData["steps"] = [
          {
            label: t("common:description"),
            value: "QT_CREATION",
            heading: t("classRoom:share_resources"),
            subHeading: t("classRoom:placeholder_for_subtext"),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["createHeader"] = t(
          "classRoom:create_a_quick_task"
        );
        break;
      case "IMPORT_LE":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:unit"),
            }),
            value:
              preSelectedCourseIds.length > 1
                ? "UNIT_FROM_GRADE_SELECTION"
                : "UNIT_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:unit"),
            }),
          },
          {
            label: t("common:select_with_label", {
              label: t("common:lowercase", {
                text: t("common:le_label"),
              }),
            }),
            value: "LE_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:lowercase", {
                text: t("common:le_label"),
              }),
            }),
          },
          {
            label: t("common:edit_with_label", {
              label: t("community:learning_experience"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["createHeader"] = t(
          "classRoom:assign_from_unit"
        );
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : preselectedCoursesLength === 1
          ? _.head(preSelectedCourseIds)
          : null;
        break;
      case "IMPORT_LE_FROM_LE_LIBRARY": {
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:lowercase", {
                text: t("common:post"),
              }),
            }),
            value: "LE_LIBRARY_LE_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:lowercase", {
                text: t("common:post"),
              }),
            }),
          },
          {
            label: t("common:edit_with_label", {
              label: t("common:post"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : _.get(preSelectedCourseIds, "0", null);
        newAssignmentModalData["createHeader"] = _.isEqual(
          curriculumProgramType,
          CURRICULUM_TYPE_PYP
        )
          ? t("common:assign_from_learning_experience_library")
          : t("common:assign_from_learning_experience_&_assessment_library");
        break;
      }
      case "CREATE_DISCUSSION":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:details"),
            value: "DISCUSSION_CREATION",
            heading: t("classRoom:initiate_a_classroom_discussion"),
            subHeading: t("classRoom:student_response_to_discussion_prompts"),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["contentType"] = "ASSIGNMENT_DISCUSSION";
        newAssignmentModalData["createHeader"] = t(
          "classRoom:intiate_a_new_discussion"
        );
        break;
      case "SCHEDULE_VIDEO_CALL":
        newAssignmentModalData["steps"] = [
          {
            value: "SCHEDULE_CALL_SETTINGS",
            label: t("common:schedule_video_call"),
          },
        ];
        newAssignmentModalData["contentType"] = "ASSIGNMENT_MEETING";
        newAssignmentModalData["createHeader"] = t(
          "common:schedule_video_call"
        );
        break;
      case "CREATE_LE":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:unit"),
            }),
            value:
              preSelectedCourseIds.length > 1
                ? "UNIT_FROM_GRADE_SELECTION"
                : "LE_CREATE_UNIT_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:unit"),
            }),
          },
          {
            label: t("common:create_with_label", {
              label: t("common:lowercase", { text: t("common:le_label") }),
            }),
            value: "LE_CREATION",
            heading:
              ("common:create_with_label",
              { label: t("common:lowercase", { text: t("common:le_label") }) }),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["assessmentGroupType"] = "LEARNING_ENGAGEMENT";
        newAssignmentModalData["createHeader"] = t("classRoom:create_le");
        newAssignmentModalData["assessmentType"] = "le";
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : preselectedCoursesLength === 1
          ? _.head(preSelectedCourseIds)
          : null;
        break;
      case "CREATE_SA":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:unit"),
            }),
            value:
              preselectedCoursesLength > 1
                ? "UNIT_FROM_GRADE_SELECTION"
                : "SMT_CREATE_UNIT_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:unit"),
            }),
          },
          {
            label: t("common:create_with_label", {
              label: t("common:lowercase", {
                text: t("common:summative_assessment"),
              }),
            }),
            value: "SMT_CREATION",
            heading:
              ("common:create_with_label",
              {
                label: t("common:lowercase", {
                  text: t("common:summative_assessment"),
                }),
              }),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["assessmentGroupType"] = "ASSESSMENT";
        newAssignmentModalData["createHeader"] = t("classRoom:create_sa");
        newAssignmentModalData["assessmentType"] = "smt";
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : preselectedCoursesLength === 1
          ? _.head(preSelectedCourseIds)
          : null;
        break;
      case "CREATE_FA":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:unit"),
            }),
            value:
              preselectedCoursesLength > 1
                ? "UNIT_FROM_GRADE_SELECTION"
                : "FMT_CREATE_UNIT_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:unit"),
            }),
          },
          {
            label: t("common:create_with_label", {
              label: t("common:lowercase", {
                text: t("common:formative_assessment"),
              }),
            }),
            value: "FMT_CREATION",
            heading:
              ("common:create_with_label",
              {
                label: t("common:lowercase", {
                  text: t("common:formative_assessment"),
                }),
              }),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["assessmentGroupType"] = "ASSESSMENT";
        newAssignmentModalData["createHeader"] = t("classRoom:create_fa");
        newAssignmentModalData["assessmentType"] = "fmt";
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : preselectedCoursesLength === 1
          ? _.head(preSelectedCourseIds)
          : null;
        break;
      case "CREATE_ASSESSMENT":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:unit"),
            }),
            value:
              preselectedCoursesLength > 1
                ? "UNIT_FROM_GRADE_SELECTION"
                : "ASSESSMENT_CREATE_UNIT_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:unit"),
            }),
          },
          {
            label: t("common:create_with_label", {
              label: t("common:lowercase", {
                text: t("common:assessment"),
              }),
            }),
            value: "ASSESSMENT_CREATION",
            heading:
              ("common:create_with_label",
              {
                label: t("common:lowercase", {
                  text: t("common:assessment"),
                }),
              }),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["assessmentGroupType"] = "ASSESSMENT";
        newAssignmentModalData["createHeader"] = t(
          "classRoom:create_assessment_classroom"
        );
        // newAssignmentModalData["assessmentType"] = "fmt";
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : preselectedCoursesLength === 1
          ? _.head(preSelectedCourseIds)
          : null;
        break;
      case "UNIT":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:select_with_label", {
              label: t("common:unit"),
            }),
            value:
              preselectedCoursesLength > 1
                ? "UNIT_FROM_GRADE_SELECTION"
                : "UNIT_SELECTION",
            heading: t("common:select_with_label", {
              label: t("common:unit"),
            }),
          },
          {
            label: t("common:select_post"),
            value: "SELECT_POST",
            heading: "common:select_post",
          },
          {
            label: t("common:edit_post"),
            value: "ASSESSMENT_DETAILS",
            heading: "common:edit_post",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
            subHeading: t("classRoom:classes_discussion_shared_with"),
          },
        ];
        newAssignmentModalData["courseList"] = courseList;
        newAssignmentModalData["contentType"] = "ASSESSMENT";
        newAssignmentModalData["createHeader"] = t(
          "classRoom:assign_from_unit"
        );
        newAssignmentModalData["courseId"] = courseId
          ? courseId
          : preselectedCoursesLength === 1
          ? _.head(preSelectedCourseIds)
          : null;
        break;
      case "IMPORT_LE_FROM_COMMUNITY":
        // TODO: add the functionality of adding LE from community in calendar
        onOverlayClickClose();
        return;
      default:
        newAssignmentModalData["steps"] = [];
    }

    setAssignmentModalData({
      ...assignmentModalData,
      ...newAssignmentModalData,
    });
    if (key === "SCHEDULE_VIDEO_CALL") {
      setScheduleEventModal(true);
    } else {
      setShowAddAssignmentModal(true);
    }
  };

  const onCloseAddAssignmentModal = async () => {
    setShowAddAssignmentModal(false);
    setScheduleEventModal(false);
    onOverlayClickClose ? onOverlayClickClose() : null;
    refetchEvents ? await refetchEvents() : null;
    calendarEventModalClose ? calendarEventModalClose() : null;
  };

  /**
   *  Logic for event edit mode starts here
   */
  const onCalendarItemEdit = ({ eventId }) => {
    const newAssignmentModalData = { eventId, mode: "edit" };
    newAssignmentModalData["steps"] = [
      {
        value: "SCHEDULE_CALL_SETTINGS",
        label: t("common:schedule_video_call"),
      },
    ];
    newAssignmentModalData["contentType"] = "ASSIGNMENT_MEETING";
    newAssignmentModalData["createHeader"] = t("common:schedule_video_call");
    setAssignmentModalData(assignmentModalData => ({
      ...assignmentModalData,
      ...newAssignmentModalData,
    }));
    setScheduleEventModal(true);
  };

  const onAssesmentEdit = ({ id, contentType }) => {
    const newAssignmentModalData = { id, mode: "edit", contentType };

    switch (contentType) {
      case "ASSESSMENT":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:edit_with_label", {
              label: t("community:learning_experience"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:post_settings"),
          },
        ];
        newAssignmentModalData["courseId"] = courseId;
        break;
      case "ASSIGNMENT_RESOURCE":
      case "ASSIGNMENT_DISCUSSION":
        newAssignmentModalData["steps"] = [
          {
            label: t("common:description"),
            value: "DISCUSSION_CREATION",
            heading: t("classRoom:initiate_a_classroom_discussion"),
            subHeading: t("classRoom:student_response_to_discussion_prompts"),
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
            heading: t("classRoom:select_classes_for_discussion"),
            subHeading: t(
              "classRoom:discussion_will_happen_within_class_group"
            ),
          },
        ];
        newAssignmentModalData["courseId"] = courseId;
        break;
      default:
        return; // video calls can't be edited
    }
    setAssignmentModalData(assignmentModalData => ({
      ...assignmentModalData,
      ...newAssignmentModalData,
    }));
    setShowAddAssignmentModal(true);
  };

  useEffect(() => {
    if (mode === "edit") {
      const { id, eventId, contentType, itemType } = editModeDetails;
      if (itemType === "CALENDAR_EVENT_ITEM") {
        onCalendarItemEdit({ eventId });
      } else {
        onAssesmentEdit({ id, contentType });
      }
    }
    if (dropdownButtonRef && dropdownButtonRef.current) {
      // this is to open the dropdown when the user clicks on any tile in the calendar
      dropdownButtonRef.current.click();
      setSelectedSlotClicked(true);
    }
  }, []);

  return (
    <>
      {mode === "create" && (
        <>
          {showOverlay && (
            <div
              className={classes.overlay}
              onClick={onOverlayClickClose}
            ></div>
          )}
          <Dropdown
            overlay={
              <DropdownMenu
                options={authTabs ? authTabs : clonedOptions}
                onClick={onDropdownItemClick}
              />
            }
          >
            {buttonComponent ? (
              buttonComponent
            ) : (
              <div
                ref={dropdownButtonRef}
                style={btnDropdownStyles.parentContainerStyle}
                className={classes.selectedSlot}
                onClick={selectedSlotClicked ? onOverlayClickClose : null}
              />
            )}
          </Dropdown>
        </>
      )}
      {showAddAssignmentModal && (
        <UIModal isOpen={true} isFullScreen={true}>
          <AddAssignment
            courseId={assignmentModalData["courseId"]}
            courseList={assignmentModalData["courseList"]}
            mode={assignmentModalData["mode"]}
            assessmentType={assignmentModalData["assessmentType"]}
            assessmentGroupType={assignmentModalData["assessmentGroupType"]}
            assignmentId={assignmentModalData["id"]}
            creationMode={assignmentModalData["creationMode"]}
            creationSource={assignmentModalData["creationSource"]}
            steps={assignmentModalData["steps"]}
            contentType={assignmentModalData["contentType"]}
            createHeader={assignmentModalData["createHeader"]}
            onClose={onCloseAddAssignmentModal}
            selectedTimeSlot={selectedTimeSlot ? selectedTimeSlot : {}}
            course={course}
            pageName={assignmentModalData["pageName"]}
            preSelectedCourseIds={preSelectedCourseIds}
            clonedAssessmentId={clonedAssessmentId}
          ></AddAssignment>
        </UIModal>
      )}
      {showScheduleEventModal ? (
        <UIModal isOpen={true} isFullScreen={true}>
          <ScheduleEvent
            courseId={assignmentModalData["courseId"]}
            mode={assignmentModalData["mode"]}
            assignmentId={assignmentModalData["id"]}
            createHeader={assignmentModalData["createHeader"]}
            eventId={assignmentModalData["eventId"]}
            onClickDiscard={onCloseAddAssignmentModal}
            selectedTimeSlot={selectedTimeSlot ? selectedTimeSlot : {}}
            showAddClassButton={true}
            preSelectedCourseIds={_.compact(preSelectedCourseIds)}
          />
        </UIModal>
      ) : null}
      {loading && <FullScreenLoader />}
    </>
  );
});

EditOrCreateEvent.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]),
  showOverlay: PropTypes.bool,
  onOverlayClickClose: PropTypes.func,
  showDropdown: PropTypes.bool,
  buttonComponent: PropTypes.element,
  btnDropdownStyles: PropTypes.object,
  selectedTimeSlot: PropTypes.object,
  refetchEvents: PropTypes.func,
  calendarEventModalClose: PropTypes.func,
  editModeDetails: PropTypes.object,
};

EditOrCreateEvent.defaultProps = {
  mode: "create",
  showOverlay: false,
  showDropdown: false,
  onOverlayClickClose: null,
  refetchEvents: null,
  calendarEventModalClose: null,
};

const mapActionCreators = {
  createAssessment,
};

export default compose(
  I18nHOC({ resources: ["common", "classRoom"] }),
  connect(null, mapActionCreators)
)(EditOrCreateEvent);
