import React from "react";
import PropTypes from "prop-types";
import classes from "./UIModal.scss";
import Modal from "react-modal";
import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { I18nHOC } from "UIComponents";
import { PrivateComponent } from "../PrivacyAware";
import { formatDate } from "Utils";
class UIModal extends React.PureComponent {
  UNSAFE_componentWillMount = () => {
    document.addEventListener("keydown", this.escapePress);
    document.addEventListener("mousedown", this.onOutSideClick);
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapePress);
    document.removeEventListener("mousedown", this.onOutSideClick);
  }
  escapePress = e => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code == 27) {
      e.stopPropagation();
      this.onRequestClose(e);
    }
  };

  onRequestClose = e => {
    const { onRequestClose } = this.props;
    if (onRequestClose) {
      onRequestClose(e);
    }
  };

  onOutSideClick = e => {
    const { closeOnOutSideClick } = this.props;

    if (
      closeOnOutSideClick &&
      this.contentRef &&
      !this.contentRef.contains(e.target)
    ) {
      e.stopPropagation();
      this.onRequestClose(e);
    }
  };

  afterOpenModal = () => {
    // if (this.overlayRef) {
    //   this.overlayRef.addEventListener(
    //     "touchmove",
    //     e => {
    //       console.log(e.target, this.overlayRef);
    //       if (!this.overlayRef.contains(e.target)) {
    //         e.preventDefault();
    //       }
    //     },
    //     false
    //   );
    // }
  };

  render() {
    const {
      isOpen,
      overlayContainer,
      modalContent,
      isFullScreen,
      customStyle,
      academicYearSelected,
      isTeacherPage,
      t,
      isArchived,
      shouldCloseOnOverlayClick,
      hideArchiveBanner,
    } = this.props;
    // for previous academic year warning
    const isCurrentAcademicYear = _.get(
      academicYearSelected,
      "isCurrentAcademicYear",
      true
    );
    const startDate = _.get(academicYearSelected, "startDate", "");
    const endDate = _.get(academicYearSelected, "endDate", "");
    // const childrenStyle = classNames({
    //   [classes.childrenNormal]: isCurrentAcademicYear,
    //   [classes.childrenSmall]: !isCurrentAcademicYear
    // });
    const overlayContainerClass = classNames(
      { [classes.overlayContainer]: true },
      { [classes.noFullscreenOverlaycontainer]: !isFullScreen },
      { [overlayContainer]: !!overlayContainer }
    );

    const modalContentClass = classNames(
      { [classes.modalContent]: true },
      { [classes.fullScreenModalContent]: isFullScreen },
      { [modalContent]: !!modalContent }
    );

    const showArchivedAlert = isArchived && isFullScreen && !hideArchiveBanner;

    const showPreviousYearAlert = !isCurrentAcademicYear && isFullScreen;
    const previousYearAlert = t("common:previous_academic_year_view_alert", {
      year: `${formatDate({
        date: startDate,
        format: "YYYY",
      })}-${formatDate({ date: endDate, format: "YY" })}`,
    });

    const showAlertBanner = showArchivedAlert || showPreviousYearAlert;
    const alertBannerContent = showArchivedAlert
      ? t("common:inside_archived_class")
      : showPreviousYearAlert
      ? previousYearAlert
      : null;

    const childrenClasses = classNames(classes.modalChildren, {
      [classes.modalChildrenResize]: showAlertBanner,
    });

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        overlayClassName={overlayContainerClass}
        className={modalContentClass}
        ariaHideApp={false}
        // shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldFocusAfterRender={false}
        contentRef={ref => (this.contentRef = ref)}
        overlayRef={ref => (this.overlayRef = ref)}
        onAfterOpen={this.afterOpenModal}
        style={customStyle}
      >
        {this.props.children && (
          <PrivateComponent isScreen={true}>
            <div className={classes.childrenContainer}>
              {showAlertBanner && (
                <div className={classes.alertBanner}>{alertBannerContent}</div>
              )}
              <div className={childrenClasses}>{this.props.children}</div>
            </div>
          </PrivateComponent>
        )}
      </Modal>
    );
  }
}

UIModal.defaultProps = {
  isOpen: false,
  onRequestClose: null,
  modalContent: "",
  overlayContainer: "",
  isFullScreen: false,
  customStyle: { content: {}, overlay: {} },
  academicYearSelected: {},
  isTeacherPage: true,
  shouldCloseOnOverlayClick: true,
  hideArchiveBanner: false,
};

UIModal.propTypes = {
  isOpen: PropTypes.bool,
  modalContent: PropTypes.string,
  onRequestClose: PropTypes.func,
  overlayContainer: PropTypes.string,
  customStyle: PropTypes.object,
  academicYearSelected: PropTypes.object,
  isTeacherPage: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  hideArchiveBanner: PropTypes.bool,
};
// for previous academic year warning
const mapStateToProps = state => {
  const academicYearSelected = _.get(
    state,
    "platform.academicYearSelected",
    {}
  );
  const isTeacherPage = _.get(state, "login.activeTab", "");

  const isArchivedClass = _.get(
    state,
    "teacher.selected_class.isArchivedClass"
  );
  const isRemovedCourseForLoggedInUser = _.get(
    state,
    "teacher.selected_class.isRemovedCourseForLoggedInUser"
  );
  const isArchived = isArchivedClass || isRemovedCourseForLoggedInUser;
  return {
    academicYearSelected,
    isTeacherPage: isTeacherPage == "teacher",
    isArchived,
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, null)
)(UIModal);
