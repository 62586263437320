import gql from "graphql-tag";
import {
  deadlineFragments,
  projectAcademicHonestyFragments,
} from "./ProjectFragments";
import { attachmentFragment, userFragment } from "modules/CommonFragments";

export const deleteProjectDeadlineMutation = gql`
  mutation deleteProjectDeadline($input: DeleteProjectGroupDeadlineInput!) {
    documentation {
      deleteProjectGroupDeadline(input: $input)
    }
  }
`;

export const updateProjectDeadlineMutation = gql`
  mutation updateProjectDeadline(
    $input: UpdateProjectGroupDeadlineInput!
    $fetchStatusWiseProjects: Boolean!
  ) {
    documentation {
      updateProjectGroupDeadline(input: $input) {
        ...projectDeadline
      }
    }
  }
  ${deadlineFragments.deadlineFragmentWithStatusCount}
`;

export const createProjectDeadlineMutation = gql`
  mutation createProjectDeadline(
    $input: CreateProjectGroupDeadlineInput!
    $fetchStatusWiseProjects: Boolean!
  ) {
    documentation {
      createProjectGroupDeadline(input: $input) {
        ...projectDeadline
      }
    }
  }
  ${deadlineFragments.deadlineFragmentWithStatusCount}
`;

export const reorderProjectDeadlineMutation = gql`
  mutation reorderProjectDeadline($input: ReorderInput!) {
    documentation {
      reorderProjectGroupDeadline(input: $input)
    }
  }
`;

export const createProjectTemplateCopyMutation = gql`
  mutation createProjectTemplateCopy($input: CreateProjectTemplateCopyInput!) {
    documentation {
      createProjectTemplateCopy(input: $input) {
        ...attachmentItem
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const updateProjectFieldMutation = gql`
  mutation updateProjectField($input: UpdateProjectInput!) {
    documentation {
      updateProject(input: $input) {
        id
      }
    }
  }
`;

export const markProjectDeadlinesCompleteMutation = gql`
  mutation markProjectDeadlinesComplete(
    $projectId: ID!
    $addedArray: [ID!]
    $removedArray: [ID!]
  ) {
    documentation {
      markProjectDeadlinesComplete(
        input: {
          projectId: $projectId
          deadlines: { added: $addedArray, removed: $removedArray }
        }
      )
    }
  }
`;

export const updateProjectMutation = gql`
  mutation updateProject(
    $projectId: ID!
    $status: String
    $reportStatus: String
    $type: PROJECT_TYPE_ENUM
    $statusMessage: String
    $isCompleted: Boolean
  ) {
    documentation {
      updateProject(
        input: {
          status: $status
          id: $projectId
          reportStatus: $reportStatus
          type: $type
          statusMessage: $statusMessage
          isCompleted: $isCompleted
        }
      ) {
        id
        status
        reportStatus
        type
        isCompleted
      }
    }
  }
`;

export const updateProjectWithoutResponseFieldsMutation = gql`
  mutation updateProject(
    $projectId: ID!
    $status: String
    $reportStatus: String
    $type: PROJECT_TYPE_ENUM
    $statusMessage: String
    $isCompleted: Boolean
  ) {
    documentation {
      updateProject(
        input: {
          status: $status
          id: $projectId
          reportStatus: $reportStatus
          type: $type
          statusMessage: $statusMessage
          isCompleted: $isCompleted
        }
      ) {
        id
      }
    }
  }
`;

export const createProjectMutation = gql`
  mutation createProject(
    $projectGroupId: ID!
    $supervisorId: ID
    $studentIds: [ID!]
    $fields: [PlannerFieldInput]
    $grades: [ID!]
    $status: PROJECT_STATUS_ENUM
    $studentCollaborators: [StudentCollaborators!]
    $type: PROJECT_TYPE_ENUM
  ) {
    documentation {
      createProject(
        input: {
          supervisorId: $supervisorId
          studentIds: $studentIds
          projectGroupId: $projectGroupId
          fields: $fields
          grades: $grades
          status: $status
          studentCollaborators: $studentCollaborators
          type: $type
        }
      ) {
        id
      }
    }
  }
`;

export const editProjectMutation = gql`
  mutation editProject(
    $projectId: ID!
    $addedSupervisors: [ID!]
    $removedSupervisors: [ID!]
    $removedStudents: [ID!]
    $addedStudents: [ID!]
    $removedStudentCollaborators: [ID!]
    $addedStudentCollaborators: [ID!]
  ) {
    documentation {
      assignProjectParticipants(
        input: {
          projectId: $projectId
          supervisor: { added: $addedSupervisors, removed: $removedSupervisors }
          student: { added: $addedStudents, removed: $removedStudents }
          studentCollaborators: {
            added: $addedStudentCollaborators
            removed: $removedStudentCollaborators
          }
        }
      )
    }
  }
`;

export const removeProjectsMutation = gql`
  mutation removeProjects($projectIds: [ID!]!) {
    documentation {
      removeProjects(input: { projectIds: $projectIds })
    }
  }
`;

export const createProjectGroupGuidingQuestionMutation = gql`
  mutation createProjectGroupGuidingQuestion(
    $question: String!
    $projectGroupId: ID!
    $grades: [ID!]
  ) {
    documentation {
      createProjectGroupGuidingQuestion(
        input: {
          question: $question
          projectGroupId: $projectGroupId
          grades: $grades
        }
      ) {
        id
      }
    }
  }
`;

export const updateProjectGroupGuidingQuestionMutaion = gql`
  mutation updateProjectGroupGuidingQuestion(
    $question: String!
    $id: ID!
    $gradeAdded: [ID!]
    $gradeRemoved: [ID!]
  ) {
    documentation {
      updateProjectGroupGuidingQuestion(
        input: {
          question: $question
          id: $id
          grades: { added: $gradeAdded, removed: $gradeRemoved }
        }
      )
    }
  }
`;

export const deleteProjectGroupGuidingQuestionMutation = gql`
  mutation deleteProjectGroupGuidingQuestion($id: ID!) {
    documentation {
      deleteProjectGroupGuidingQuestion(input: { id: $id })
    }
  }
`;

export const reorderProjectGroupGuidingQuestionMutation = gql`
  mutation reorderProjectGroupGuidingQuestion($id: ID!, $putAfterItemId: ID) {
    documentation {
      reorderProjectGroupGuidingQuestion(
        input: { id: $id, putAfterItemId: $putAfterItemId }
      )
    }
  }
`;

export const updateStudentProjectPortfolioMutation = gql`
  mutation updateStudentProjectPortfolio(
    $input: UpdateStudentProjectPortfolioInput!
  ) {
    documentation {
      updateStudentProjectPortfolio(input: $input) {
        id
      }
    }
  }
`;

export const updateProjectStatusInBulkMutation = gql`
  mutation updateProjectStatusInBulk(
    $projectGroupId: ID!
    $status: PROJECT_STATUS_ENUM!
    $fromStatus: PROJECT_STATUS_ENUM!
  ) {
    documentation {
      updateProjectStatusInBulk(
        input: {
          projectGroupId: $projectGroupId
          status: $status
          fromStatus: $fromStatus
        }
      )
    }
  }
`;

export const updateStudentPortfolioStatusMutation = gql`
  mutation updateStudentPortfolioStatus(
    $id: ID!
    $status: STUDENT_PROJECT_PORTFOLIO_STATUS_ENUM
    $isComplete: Boolean
  ) {
    documentation {
      updateStudentProjectPortfolio(
        input: { id: $id, status: $status, isComplete: $isComplete }
      ) {
        id
      }
    }
  }
`;

export const updateAchievedPlannerElementMutation = gql`
  mutation updateAchievedPlannerElement(
    $input: UpdateAchievedPlannerElementsInput!
  ) {
    planner {
      updateAchievedPlannerElements(input: $input)
    }
  }
`;

export const updateAcademicHonestyLockStatusMutation = gql`
  mutation updateAcademicHonestyLockStatus(
    $projectId: ID!
    $isAcademicHonestyLocked: Boolean
  ) {
    documentation {
      updateProject(
        input: {
          id: $projectId
          isAcademicHonestyLocked: $isAcademicHonestyLocked
        }
      ) {
        isAcademicHonestyLocked
      }
    }
  }
`;
