import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { getRethumbUrl, getRelativePath } from "Utils";
import { connect } from "react-redux";
import classes from "./InsightCountInfo.scss";
import { graphql, compose } from "react-apollo";
import { I18nHOC, UIModal, Loading } from "UIComponents";
import { CloseOutlined, ArrowOutOutlined } from "@toddle-design/web-icons";
import { openLinkInNewTab } from "modules/Services";
import {
  getUnitPlansQuery,
  getPlannerElementNodeQuery,
} from "UnitPlans/modules/UnitPlanQuery";
import {
  getUnitPlansFromCache,
  getPlannerElementNodeFromCache,
} from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import { colors, PLANNER_ELEMENT_NODE_LABEL_MAPPING } from "Constants";
import InsightCountPopover from "./Components/InsightCountPopover";
import classNames from "classnames";
import { IconButton } from "@toddle-design/web";

const getRethumb = imageUrl => {
  const rethumbUrl = imageUrl
    ? getRethumbUrl({
        width: 144,
        height: 144,
        imageUrl: imageUrl,
        fitIn: true,
      })
    : "";
  return `url("${rethumbUrl}")`;
};

class InsightCountInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showLEPopover: false,
      selectedUnitId: null,
      leAssessmentIds: null,
    };
    this.insightCountRef = {};
  }

  goToUnitPlanDetail = ({ id }) => {
    const { openLinkInNewTab, insightType } = this.props;
    const mode = "view";
    let route = `planningInsights/units/${id}/${mode}`;
    let replacePath = `planningInsights`;

    if (_.isEqual(insightType, `yearlyInsights`)) {
      route = `${id}/${mode}`;
      replacePath = `insight`;
    }

    const routePath = getRelativePath(route, replacePath);
    openLinkInNewTab({ url: routePath });
  };

  updateInsightCountRef = ({ id, ref }) => {
    this.insightCountRef[id] = ref;
  };

  getStyle = ({ index }) => {
    let style = {};
    if (!_.isEqual(index, 0)) {
      style = { borderTop: `1px solid ${colors.strokeOne}` };
    }
    return style;
  };

  getInsightCountStyle = insightCount => {
    let style = {};
    if (insightCount > 0) {
      style = { textDecoration: "underline" };
    }
    return style;
  };

  toggleShowLEPopover = value => {
    this.setState({ showLEPopover: value });
  };
  updateSelectedUnitId = value => {
    this.setState({ selectedUnitId: value });
  };
  updateLeAssessmentIds = value => {
    this.setState({ leAssessmentIds: value });
  };

  onClickInsightCount = (insightCount, id, assessmentIds) => {
    if (insightCount > 0) {
      this.toggleShowLEPopover(true);
      this.updateSelectedUnitId(id);
      this.updateLeAssessmentIds(assessmentIds);
    }
  };

  onClosePopover = () => {
    this.toggleShowLEPopover(false);
    this.updateSelectedUnitId(null);
    this.updateLeAssessmentIds([]);
  };

  generateCard = ({
    imageUrl,
    title,
    subTitle,
    onClickOpenLinkSvg,
    id,
    index,
    assessments,
    assessmentCard = false,
  }) => {
    const { showModalWithInsightsCount, isViewOnly } = this.props;
    const insightCount = _.get(assessments, "totalCount", 0);
    const edges = _.get(assessments, "edges", []);
    const assessmentIds = _.map(edges, edge => {
      return _.get(edge, "node.id", null);
    });

    const unitPlanClasses = classNames(classes.unitPlanCard, {
      [classes.clickableUnitPlanCard]: !isViewOnly,
    });

    return (
      <div
        key={id}
        className={unitPlanClasses}
        style={this.getStyle({ index })}
        ref={ref => this.updateInsightCountRef({ ref, id })}
      >
        <div
          className={classes.image}
          style={{ backgroundImage: getRethumb(imageUrl) }}
        />
        <div className={classes.details}>
          <div className={classes.title} onClick={onClickOpenLinkSvg}>
            {title}
          </div>
          <div className={classes.subTitle}>{subTitle}</div>
        </div>

        {(!showModalWithInsightsCount || assessmentCard) && (
          <div
            className={classes.openInNewTabClass}
            onClick={onClickOpenLinkSvg}
          >
            <ArrowOutOutlined size={"xxx-small"} />
          </div>
        )}
        {showModalWithInsightsCount && !assessmentCard && (
          <div
            className={classNames({
              [classes.insightCount]: true,
              [classes.underlinedInsightCount]: insightCount > 0,
            })}
            onClick={() => {
              this.onClickInsightCount(insightCount, id, assessmentIds);
            }}
          >
            {insightCount}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      t,
      onClose,
      showModalWithInsightsCount,
      isLoading,
      coveredUnitPlans,
      plannerElementNode,
      curriculumType,
      nodeLabel,
      isViewOnly,
    } = this.props;

    const { showLEPopover, selectedUnitId, leAssessmentIds } = this.state;

    const plannerElementNodeType = _.get(plannerElementNode, "type", "MYP_ATL");
    const plannerElementNodeLabel = _.get(plannerElementNode, "label", "");
    const labelForUnitsModal = _.isEqual(curriculumType, "IB_PYP")
      ? t("common:associated_units_modal_with_insights_count_heading", {
          label: nodeLabel,
        })
      : t("common:associated_units");

    const title = showModalWithInsightsCount
      ? t("common:associated_units_modal_with_insights_count_heading", {
          label: plannerElementNodeLabel,
        })
      : labelForUnitsModal;

    return (
      <div className={classes.container}>
        <UIModal isOpen={true} modalContent={classes.modalContainer}>
          {isLoading ? (
            <Loading />
          ) : (
            <React.Fragment>
              <div className={classes.modalHeader}>
                <div className={classes.heading}>
                  <div className={classes.title}>{title}</div>

                  <IconButton
                    icon={<CloseOutlined />}
                    onClick={onClose}
                    variant="plain"
                  />
                </div>
                {showModalWithInsightsCount && (
                  <div className={classes.modalSubHeader}>
                    {t("common:associated_units_modal_subheader", {
                      label:
                        PLANNER_ELEMENT_NODE_LABEL_MAPPING[
                          plannerElementNodeType
                        ],
                    })}
                  </div>
                )}
              </div>
              <div className={classes.modalBody}>
                {_.map(coveredUnitPlans, (unit, index) => {
                  const { id, fields } = unit;
                  const imageUrl = _.get(unit, "image.value", "");
                  const title = _.get(unit, "title.value", "");
                  const subjects = _.get(
                    _.find(fields, { uid: "subjects" }),
                    "resolvedMinimalTree.subjects",
                    []
                  );

                  const assessments = _.get(
                    unit,
                    "unitInsights.0.assessments",
                    {}
                  );

                  const subTitle = _.join(
                    _.map(subjects, subject => {
                      return subject.name;
                    }),
                    ", "
                  );

                  return this.generateCard({
                    id,
                    index,
                    imageUrl,
                    title,
                    subTitle,
                    assessments,
                    onClickOpenLinkSvg: isViewOnly
                      ? _.noop
                      : () => this.goToUnitPlanDetail({ id }),
                  });
                })}
              </div>
              {showLEPopover && (
                <InsightCountPopover
                  showLEPopover={showLEPopover}
                  leAssessmentIds={leAssessmentIds}
                  selectedUnitId={selectedUnitId}
                  getStyle={this.getStyle}
                  getRethumb={getRethumb}
                  onClosePopover={this.onClosePopover}
                  insightCountRef={this.insightCountRef}
                  generateCard={this.generateCard}
                  isViewOnly={isViewOnly}
                />
              )}
            </React.Fragment>
          )}
        </UIModal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const curriculumType = state.platform.currentCurriculumProgramType;

  return {
    curriculumType,
    fieldUIDs: ["subjects"],
    isData: true,
    isLoading: false,
  };
};

const mapActionCreatorsToProps = {
  openLinkInNewTab,
};

const getFilters = ({ showModalWithInsightsCount, plannerElementNodeId }) => {
  if (showModalWithInsightsCount) {
    return { nodeIds: [plannerElementNodeId] };
  }
  return {};
};

const InsightCountInfoWrapper = compose(
  connect(mapStateToProps, mapActionCreatorsToProps),
  I18nHOC({ resource: ["common", "planningInsights"] }),
  graphql(getUnitPlansQuery, {
    name: "getUnitPlans",
    options: ({
      coveredUnitPlanIds,
      showModalWithInsightsCount,
      plannerElementNodeId,
      fieldUIDs,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: coveredUnitPlanIds,
        uids: fieldUIDs,
        filters: getFilters({
          showModalWithInsightsCount,
          plannerElementNodeId,
        }),
      },
    }),
    props({
      getUnitPlans,
      ownProps: {
        fieldUIDs,
        coveredUnitPlanIds,
        showModalWithInsightsCount,
        plannerElementNodeId,
        isData,
        isLoading,
      },
    }) {
      const data = getUnitPlansFromCache({
        ids: coveredUnitPlanIds,
        uids: fieldUIDs,
        filters: getFilters({
          showModalWithInsightsCount,
          plannerElementNodeId,
        }),
      });

      return {
        coveredUnitPlans: data,

        isData: !_.isEmpty(data) && isData,
        isLoading:
          getUnitPlans["networkStatus"] == 1 ||
          getUnitPlans["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerElementNodeQuery, {
    name: "getPlannerElementNode",
    skip: ({ plannerElementNodeId }) => !plannerElementNodeId,
    options: ({ plannerElementNodeId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: plannerElementNodeId,
      },
    }),
    props({
      getPlannerElementNode,
      ownProps: { plannerElementNodeId, isData, isLoading },
    }) {
      const data = getPlannerElementNodeFromCache({
        id: plannerElementNodeId,
      });

      return {
        plannerElementNode: data,
        isData: !_.isEmpty(data) && isData,
        isLoading:
          getPlannerElementNode["networkStatus"] == 1 ||
          getPlannerElementNode["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(InsightCountInfo);

InsightCountInfoWrapper.defaultProps = {
  coveredUnitPlanIds: [],
  showModalWithInsightsCount: false,
  plannerElementNodeId: null,
  nodeLabel: null,
  insightType: `planningInsights`,
  isViewOnly: false,
};

InsightCountInfoWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  coveredUnitPlanIds: PropTypes.array,
  showModalWithInsightsCount: PropTypes.bool,
  plannerElementNodeId: PropTypes.any,
  nodeLabel: PropTypes.any,
  insightType: PropTypes.string,
  isViewOnly: PropTypes.bool,
};

export default InsightCountInfoWrapper;
