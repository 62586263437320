import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "timetable",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/TimetableModule").default;
            const timeTableReducer = require("TimetableConfiguration/modules/TimetableConfigurationModule")
              .default;
            injectReducer(store, { key: "timetable", reducer });
            injectReducer(store, {
              key: "timetableConfiguration",
              reducer: timeTableReducer,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "timetable"
        );
      })
  ),
  childRoutes: [],
});
