import React from "react";
import classes from "./LevelEditor.scss";
import { connect } from "react-redux";
import LevelEditorTable from "./LevelEditorTable";
import LevelViewTable from "./LevelViewTable";
import SideBar from "./SideBar";
import { nodeFilterByGrades } from "Utils";

const getLevelParentNode = ({
  depth,
  outPutNodes = [],
  currentNodeId,
  currentDepth = 0,
  nodes,
}) => {
  const currentNode = _.find(nodes, item => item.id == currentNodeId);

  const children = _.get(currentNode, "children", []);
  if (depth == currentDepth) {
    outPutNodes.push(currentNodeId);
    return;
  } else {
    _.forEach(children, childId => {
      return getLevelParentNode({
        depth,
        outPutNodes,
        currentNodeId: childId,
        currentDepth: currentDepth + 1,
        nodes,
      });
    });
  }
};

class LevelEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      levelIdIndex: 0,
      levelSelectedParents: {},
    };
    this.levelEditorTable = null;
    //wrong
    setTimeout(() => {
      this.updateSelectedLevelParentId(
        _.get(_.first(props.levels), "id", "L1")
      );
    });
  }

  updatelevelSelectedParents = (levelId, parentNodeId) => {
    const { levelSelectedParents } = this.state;
    this.setState({
      levelSelectedParents: {
        ...levelSelectedParents,
        [levelId]: parentNodeId,
      },
    });
  };

  updateParentId = parentNodeId => {
    const { levelId } = this.props;
    this.updatelevelSelectedParents(levelId, parentNodeId);
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { levelId, gradeFilterNodes, mode } = this.props;
    const {
      levelId: nextLevelId,
      nextGradeFilterNodes: nextGradeFilterNodes,
      mode: nextMode,
    } = nextProps;

    if (levelId != nextLevelId) {
      this.updateLevelIdIndex(nextLevelId);
      this.updateSelectedLevelParentId(nextLevelId);
    }

    if (
      (mode == "view" && !_.isEqual(gradeFilterNodes, nextGradeFilterNodes)) ||
      !_.isEqual(mode, nextMode)
    ) {
      setTimeout(() => {
        this.updateSelectedLevelParentId(nextLevelId);
      }, 100);
    }
  };

  updateSelectedLevelParentId = levelId => {
    const { levelSelectedParents } = this.state;
    const { levels, gradeFilterNodes, rootNode } = this.props;

    const currenSelectedParentId = levelSelectedParents[levelId];
    if (
      !currenSelectedParentId ||
      !_.find(gradeFilterNodes, item => item.id == currenSelectedParentId)
    ) {
      const levelIdIndex = _.findIndex(levels, item => item.id == levelId);

      let outPutNodes = [];
      getLevelParentNode({
        depth: levelIdIndex,
        currentNodeId: rootNode.id,
        nodes: gradeFilterNodes,
        outPutNodes,
      });

      this.updatelevelSelectedParents(levelId, _.first(outPutNodes));
    }
  };

  updateLevelIdIndex = levelId => {
    const { levels } = this.props;
    const levelIdIndex = _.findIndex(levels, item => item.id == levelId);
    this.setState({
      levelIdIndex: levelIdIndex,
    });
  };

  render() {
    const {
      nodes,
      levelId,
      levels,
      rootNode,
      grades,
      nodeSet,
      mode,
      gradeFilters,
      nodeType,
      gradeFilterNodes,
      routeLabel,
      nodeParentType,
      plannerElementSetConfig,
      showGrade,
    } = this.props;
    const { levelIdIndex, levelSelectedParents } = this.state;

    const parentNodeId = levelSelectedParents[levelId];

    //find current node and its children
    const currentNode = _.find(nodes, { id: parentNodeId });
    const children = _.get(currentNode, "children", []);
    const gradeFilterLength = _.get(gradeFilterNodes, "length", 0);

    return (
      <div className={classes.container}>
        {levelIdIndex > 0 && !!parentNodeId && gradeFilterLength > 1 && (
          <SideBar
            levelIdIndex={levelIdIndex}
            nodes={gradeFilterNodes}
            levelId={levelId}
            parentNodeId={parentNodeId}
            levels={levels}
            updateParentId={this.updateParentId}
            rootNode={rootNode}
            mode={mode}
          />
        )}

        {mode == "edit" ? (
          <LevelEditorTable
            nodes={nodes}
            grades={grades}
            levels={levels}
            levelIdIndex={levelIdIndex}
            parentNodeId={parentNodeId}
            levelId={levelId}
            updateSelectedLevelParentId={this.updateSelectedLevelParentId}
            rootNode={rootNode}
            nodeType={nodeType}
            nodeParentType={nodeParentType}
            plannerElementSetConfig={plannerElementSetConfig}
            showGrade={showGrade}
          />
        ) : (
          <LevelViewTable
            nodes={nodes}
            levelId={levelId}
            grades={gradeFilters}
            parentNodeId={parentNodeId}
            nodeSet={nodeSet}
            rootNode={rootNode}
            levelIdIndex={levelIdIndex}
            nodeType={nodeType}
            gradeFilterLength={gradeFilterLength}
            routeLabel={routeLabel}
            showGrade={showGrade}
          >
            {children}
          </LevelViewTable>
        )}
      </div>
    );
  }
}

const nodeFilterByGradesMemoize = _.memoize(
  params => nodeFilterByGrades(params),
  params => JSON.stringify(params)
);

const mapActionCreators = {};

//Wrong.. write this in render for better readability and code co-location
const mapStateToProps = (state, ownProps) => {
  const { nodes, gradeFilters = [], mode } = ownProps;

  let gradeFilterNodes = [];
  if (mode == "edit" || gradeFilters.length > 0) {
    gradeFilterNodes = nodeFilterByGradesMemoize({
      nodes,
      grades: mode == "view" ? gradeFilters : [],
    });
  }

  return {
    gradeFilterNodes: gradeFilterNodes,
  };
};

export default connect(mapStateToProps, mapActionCreators)(LevelEditor);
