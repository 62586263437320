import axios from "axios";
import update from "immutability-helper";
import { push, goBack } from "react-router-redux";
import { addUnsplashAnalyticsMutation } from "ResourceBank/containers/Mutations";
import client from "apolloClient";

// ------------------------------------
// Constants
// ------------------------------------
export const NAME = "resources";

export const INITIALISE_RESOURCEBANK_COMPONENT =
  "INITIALISE_RESOURCEBANK_COMPONENT" + " " + NAME;
export const UPDATE_RESOURCEBANK_FILTERS =
  "UPDATE_RESOURCEBANK_FILTERS" + " " + NAME;
export const TOGGLE_RESOURCEBANK_FILTER_ITEM =
  "TOGGLE_RESOURCEBANK_FILTER_ITEM" + " " + NAME;
export const TOGGLE_ALL_RESOURCEBANK_FILTER_ITEMS =
  "TOGGLE_ALL_RESOURCE_FILTER_ITEMS" + " " + NAME;
export const TOGGLE_RESOURCEBANK_SELECTED_RESOURCES =
  "TOGGLE_RESOURCEBANK_SELECTED_RESOURCES" + " " + NAME;
export const CLEAR_RESOURCEBANK_SELECTED_RESOURCES =
  "CLEAR_RESOURCEBANK_SELECTED_RESOURCES" + " " + NAME;

export const SET_PROGRESS = "SET_PROGRESS" + " " + NAME;
export const REMOVE_PROGRESS_ELEMENT = "REMOVE_PROGRESS_ELEMENT" + " " + NAME;

// PURE ACTIONS
export const setProgress = value => {
  return { type: SET_PROGRESS, payload: value };
};

export const removeProgressElement = value => {
  return { type: REMOVE_PROGRESS_ELEMENT, payload: value };
};

export const initaliseResourceBankComponent = id => {
  return {
    type: INITIALISE_RESOURCEBANK_COMPONENT,
    data: id,
  };
};

export const updateResourceBankFilters = (params, id) => {
  return {
    type: UPDATE_RESOURCEBANK_FILTERS,
    data: { params, id },
  };
};

export const toggleResourceBankFilterItem = (params, id) => {
  return {
    type: TOGGLE_RESOURCEBANK_FILTER_ITEM,
    data: { params, id },
  };
};

export const toggleAllResourceBankFilterItems = (params, id) => {
  return {
    type: TOGGLE_ALL_RESOURCEBANK_FILTER_ITEMS,
    data: { params, id },
  };
};

export const toggleResourceBankSelectedResources = (params, id) => {
  return {
    type: TOGGLE_RESOURCEBANK_SELECTED_RESOURCES,
    data: { params, id },
  };
};

export const clearResourceBankSelectedResources = id => {
  return {
    type: CLEAR_RESOURCEBANK_SELECTED_RESOURCES,
    data: { id },
  };
};

export const addUnsplashAnalytics = ({ url }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: addUnsplashAnalyticsMutation,
        variables: {
          url,
        },
      });
    } catch (e) {
    } finally {
    }
  };
};

// ------------------------------------
// Reducer Handlers
// ------------------------------------
const REDUCER_HANDLERS = {
  [INITIALISE_RESOURCEBANK_COMPONENT]: (state, action) => {
    return update(state, {
      resourceBank: { [action.data]: { $set: resourceBank } },
    });
  },
  [UPDATE_RESOURCEBANK_FILTERS]: (state, action) => {
    const params = action.data.params;
    const id = action.data.id;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        resourceBank: { [id]: { filters: { [key]: { $set: params[key] } } } },
      });
    });
    return state;
  },
  [TOGGLE_ALL_RESOURCEBANK_FILTER_ITEMS]: (state, action) => {
    const stateItemToUpdate = action.data.params.type;
    const id = action.data.id;
    return update(state, {
      resourceBank: {
        [id]: {
          filters: {
            selected: {
              [stateItemToUpdate]: { $set: action.data.params.allList },
            },
          },
        },
      },
    });
  },
  [TOGGLE_RESOURCEBANK_FILTER_ITEM]: (state, action) => {
    const params = action.data.params;
    const value = params.value;
    const opt = params.opt;
    const index = params.index;
    const stateItemToUpdate = params.type;
    const item = _.cloneDeep(params.item);
    const id = action.data.id;
    if (value) {
      return update(state, {
        resourceBank: {
          [id]: {
            filters: { selected: { [stateItemToUpdate]: { $push: [opt] } } },
          },
        },
      });
    } else {
      return update(state, {
        resourceBank: {
          [id]: {
            filters: {
              selected: { [stateItemToUpdate]: { $splice: [[index, 1]] } },
            },
          },
        },
      });
    }
  },
  [TOGGLE_RESOURCEBANK_SELECTED_RESOURCES]: (state, action) => {
    const {
      params: { type, data, index: arrayindex, multi },
      id,
    } = action.data;

    if (multi) {
      if (type == "add") {
        if (arrayindex == "") {
          state = update(state, {
            resourceBank: { [id]: { selectedResources: { $push: [data] } } },
          });
        } else {
          state = update(state, {
            resourceBank: {
              [id]: { selectedResources: { $splice: [[arrayindex, 0, data]] } },
            },
          });
        }
      } else if (type == "delete") {
        state = update(state, {
          resourceBank: {
            [id]: { selectedResources: { $splice: [[arrayindex, 1]] } },
          },
        });
      } else {
        state = update(state, {
          resourceBank: {
            [id]: { selectedResources: { $splice: [[arrayindex, 1, data]] } },
          },
        });
      }
    } else {
      state = update(state, {
        resourceBank: {
          [id]: { selectedResources: { $set: type === "add" ? [data] : [] } },
        },
      });
    }
    return state;
  },
  [CLEAR_RESOURCEBANK_SELECTED_RESOURCES]: (state, action) => {
    const id = action.data.id;
    return update(state, {
      resourceBank: { [id]: { selectedResources: { $set: [] } } },
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  progressOfUploads: {
    //id: progress
  },
  resourceBank: {
    default: {
      filters: {
        searchText: "",
        count: 20,
        selected: {
          sources: ["Unsplash"],
          mediaTypes: ["image"],
        },
      },
      selectedResources: [],
    },
  },
};

const resourceBank = {
  filters: {
    searchText: "",
    count: 20,
    selected: {
      sources: ["Unsplash"],
      mediaTypes: ["image"],
    },
  },
  selectedResources: [],
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
