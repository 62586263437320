import React from "react";
import classes from "./SubmissionItem.scss";
import {
  UIBaseComponent,
  I18nHOC,
  NotAddedEmptyView,
  FullScreenLoader,
} from "UIComponents";
import { compose } from "react-apollo";
import { AttachmentList, CreateDropDown } from "Attachment";
import { Button } from "@toddle-design/web";
import { ICONS } from "Constants";
import { colors } from "@toddle-design/theme";
import { SimilarityIndexComponent } from "AppComponents";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getProjectGroupTypeFromId } from "Projects/modules/ProjectModules";
import classNames from "classnames";
import { createAttachment } from "modules/Services";
const DROP_DOWN_OPTIONS = [
  { key: "upload_device", label: "upload_device" },
  { key: "add_google_drive", label: "add_google_drive" },
  { key: "add_one_drive", label: "add_one_drive" },
];

class SubmissionItem extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMoreAttachments: false,
      showFullScreenLoader: false,
    };
  }

  componentDidMount = () => {
    const { showSingleAttachment } = this.props;
    this.setState({ showMoreAttachments: showSingleAttachment });
  };

  updateInputFieldLocally = (params, extraParams) => {
    const { name, value, updateInputField } = this.props;

    if (_.isEmpty(params[name])) {
      params[name] = _.difference(value, [_.head(value)]);
    }

    updateInputField(params, extraParams);
  };

  addAttachment = async newAttachments => {
    const {
      value,
      fieldId: parentId,
      createAttachment,
      parentType = "FIELD",
    } = this.props;

    this.setState({ showFullScreenLoader: true });

    newAttachments = await Promise.all(
      _.map(newAttachments, attachment => {
        return createAttachment({
          attachment,
          nodeId: parentId,
          parentType,
          type: parentType,
        });
      })
    );

    newAttachments = _.map(newAttachments, attachments => _.last(attachments));

    this.updateValue([...(value ? value : []), ...newAttachments], parentId);
    this.setState({ showFullScreenLoader: false });
  };

  createDropdownComponent = () => {
    const {
      buttonLabel,
      buttonIconType,
      t,
      accept,
      defaultExportType,
      attachmentExportOptions,
      multiselect,
      fieldId,
      buttonDropDownContainerStyle,
      buttonParentStyle,
      dropDownOptions,
      canAddMaximumAttachments,
    } = this.props;
    const Icon = ICONS[buttonIconType];

    const label =
      buttonLabel || t("common:add_a_with_label", { label: t("common:file") });

    return (
      <CreateDropDown
        buttonComponent={
          <Button icon={<Icon />} variant="outlined" size="medium">
            {label}
          </Button>
        }
        multiselect={multiselect}
        buttonDropDownContainerStyle={buttonDropDownContainerStyle}
        attachmentExportOptions={attachmentExportOptions}
        accept={accept}
        defaultExportType={defaultExportType}
        parentId={fieldId}
        parentType={"FIELD"}
        shouldCallMutation={true}
        onMultipleFilesUploaded={this.addAttachment}
        dropDownOptions={dropDownOptions}
        canAddMaximumAttachments={canAddMaximumAttachments}
        showLocalAttachmentsInViewMode={true}
        buttonParentStyle={buttonParentStyle}
      />
    );
  };

  renderEdit = () => {
    const {
      value,
      fieldId,
      mode,
      dropDownOptions,
      attachmentContainerStyle,
      cardBackgroundColor,
      cardType,
      horizontalCardStyle,
      buttonParentStyle,
      disabled,
      showSeparator,
      canAddMaximumAttachments,
      projectGroupType,
      projectId,
      showSimilarity,
      projectGroupId,
      t,
      deleteEntityLabel,
      isAnnotationEnabled,
      showSingleAttachment,
      name,
      updateSelectedTemplate,
      fieldKey,
    } = this.props;

    const { showMoreAttachments, showFullScreenLoader } = this.state;

    const cardStyles = cardBackgroundColor
      ? { ...horizontalCardStyle, backgroundColor: colors[cardBackgroundColor] }
      : horizontalCardStyle;

    const attachmentBottomComponent = params => {
      const {
        attachment,
        recheckButtonHoverClass,
        showSimilarityOnHeader = false,
      } = params;
      return (
        <SimilarityIndexComponent
          metadata={{
            entityId: projectId,
            entityType: "PROJECT",
          }}
          attachment={attachment}
          recheckButtonHoverClass={recheckButtonHoverClass}
          showSimilarityOnHeader={showSimilarityOnHeader}
          projectGroupType={projectGroupType}
          projectGroupId={projectGroupId}
        />
      );
    };

    const setShowSingleAttachment = () => {
      this.setState({
        showMoreAttachments: !showMoreAttachments,
      });
    };

    const seeMoreTextStyles = classNames({
      "text-label-2": true,
      [classes.seeMoreText]: true,
    });

    return (
      <>
        <div className={classes.container}>
          {!_.isEmpty(value) || disabled ? (
            <AttachmentList
              value={
                !_.isEmpty(value)
                  ? showMoreAttachments
                    ? [_.head(value)]
                    : value
                  : []
              }
              name={name}
              deleteEntityLabel={deleteEntityLabel || t("common:file")}
              mode={mode}
              parentId={fieldId}
              parentType={"FIELD"}
              updateInputField={this.updateInputFieldLocally}
              showLocalAttachmentsInViewMode={true}
              showCreateDropdown={false}
              shouldCallMutation={true}
              onClickAttachmentCard={attachment =>
                updateSelectedTemplate({
                  attachment,
                  annotationConfig: {
                    isAnnotationEnabled,
                    showSimilarity,
                    fieldKey,
                  },
                })
              }
              showNotificationsDot={isAnnotationEnabled}
              dropDownOptions={dropDownOptions}
              attachmentContainerStyle={attachmentContainerStyle}
              horizontalCardStyle={cardStyles}
              cardType={cardType}
              buttonParentStyle={buttonParentStyle}
              disabled={disabled}
              emptyComponent={<NotAddedEmptyView />}
              attachmentBottomComponent={
                showSimilarity ? attachmentBottomComponent : null
              }
            />
          ) : null}
          {showSingleAttachment && _.size(value) > 1 && (
            <div
              className={seeMoreTextStyles}
              onClick={setShowSingleAttachment}
            >
              {showMoreAttachments
                ? t("project:number_more_files", {
                    count: _.size(value) - 1,
                  })
                : t("common:see_less_noDots")}
            </div>
          )}

          {!disabled &&
            (!canAddMaximumAttachments ||
              canAddMaximumAttachments > _.size(value)) &&
            this.createDropdownComponent()}

          {showSeparator && <div className={classes.separator} />}
        </div>
        {showFullScreenLoader && <FullScreenLoader />}
      </>
    );
  };
}

SubmissionItem.defaultProps = {
  ...UIBaseComponent.defaultProps,
  dropDownOptions: DROP_DOWN_OPTIONS,
  buttonIconType: "AddOutlined",
  accept: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.google-apps.document",
    "application/pdf",
    "application/rtf",
    "application/vnd.oasis.opendocument.text",
  ],
  defaultExportType: "LINK",
  attachmentExportOptions: ["LINK", "FILE"],
  multiselect: false,
  cardType: "design-media-card",
  horizontalCardStyle: {},
  attachmentContainerStyle: {},
  buttonDropDownContainerStyle: {
    top: "44px",
  },
  buttonParentStyle: {},
  showSeparator: false,
  canAddMaximumAttachments: null,
  showSimilarity: false,
  isAnnotationEnabled: false,
  showSingleAttachment: false,
};

const mapActionCreators = {
  createAttachment,
};

const mapStateToProps = (state, ownProps) => {
  const projectGroupId = _.get(ownProps, "params.projectGroup", null);
  const projectGroupType = getProjectGroupTypeFromId({
    state,
    projectGroupId,
  });
  return {
    projectId: _.get(ownProps, "params.projectId", null),
    projectGroupType,
    projectGroupId,
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  withRouter,
  connect(mapStateToProps, mapActionCreators)
)(SubmissionItem);
