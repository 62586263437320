import React from "react";
import classes from "./PlanathonSubmissionStars.scss";
import { colors } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import ACLStore from "lib/aclStore";
import { Checkbox } from "UIComponents";
import { SmallStarIcon, BigStarIcon, TickPlain } from "SvgComponents";
import { updateUnitPlanForPlanathon } from "UnitPlans/modules/UnitPlanModule";
import { updateAssessmentForPlanathon } from "Assessments/modules/AssessmentModule";
const STAR_ACTIONS = [
  {
    label: "P",
    value: "paritaProcessed",
    color: colors.blue29,
    clickPerm: "TeacherPortal:ParitaPlanathonStarClick",
    showPerm: "TeacherPortal:ParitaPlanathonStarShow",
    type: "TICK",
  },
  {
    label: "K",
    value: "kristenProcessed",
    color: colors.blue29,
    clickPerm: "TeacherPortal:kristenPlanathonStarClick",
    showPerm: "TeacherPortal:kristenPlanathonStarShow",
    type: "TICK",
  },
  {
    label: "",
    value: "juryProcessed",
    color: colors.blue29,
    clickPerm: "TeacherPortal:JuryPlanathonStarClick",
    showPerm: "TeacherPortal:JuryPlanathonStarShow",
    type: "TICK",
  },
  {
    label: "P",
    value: "paritaStarStatus",
    color: colors.violet63,
    clickPerm: "TeacherPortal:ParitaPlanathonStarClick",
    showPerm: "TeacherPortal:ParitaPlanathonStarShow",
  },
  {
    label: "K",
    value: "kristenStarStatus",
    color: colors.salmon60,
    clickPerm: "TeacherPortal:kristenPlanathonStarClick",
    showPerm: "TeacherPortal:kristenPlanathonStarShow",
  },
  {
    label: "",
    value: "juryStarStatus",
    color: colors.yellow50,
    clickPerm: "TeacherPortal:JuryPlanathonStarClick",
    showPerm: "TeacherPortal:JuryPlanathonStarShow",
  },
];

const StarItem = React.memo(props => {
  const { value, item, onItemClick } = props;
  const { label, color, clickPerm, type } = item || {};
  const canClick = ACLStore.can(clickPerm);
  return (
    <div
      className={classes.startItem}
      onClick={event => onItemClick({ event, item, value: !value })}
      style={{
        backgroundColor: value ? color : colors.gray72,
        cursor: canClick ? "pointer" : "default",
      }}
    >
      {type == "TICK" ? (
        <div className={classes.circle}>
          {!label && (
            <TickPlain
              width={16}
              height={16}
              fill={value ? color : colors.gray72}
            />
          )}
        </div>
      ) : !!label ? (
        <BigStarIcon width={24} height={24} />
      ) : (
        <SmallStarIcon width={24} height={24} />
      )}
      {!!label && (
        <div
          className={classes.labelContainer}
          style={{ color: value ? color : colors.gray72 }}
        >
          {label}
        </div>
      )}
    </div>
  );
});

class PlanathonSubmissionStars extends React.PureComponent {
  onItemClick = ({ event, item, value }) => {
    event.stopPropagation();

    const canClick = ACLStore.can(item.clickPerm);
    if (!canClick) {
      return;
    }
    const {
      parentType,
      parentId,
      updateUnitPlanForPlanathon,
      updateAssessmentForPlanathon,
    } = this.props;
    console.error(parentType, parentId);
    switch (parentType) {
      case "UNIT_PLAN":
        updateUnitPlanForPlanathon({
          unitPlanId: parentId,
          [item.value]: value,
        });
        break;

      case "ASSESSMENT":
        updateAssessmentForPlanathon({
          assessmentId: parentId,
          [item.value]: value,
        });
        break;
    }
  };

  render() {
    const { containerStyle } = this.props;
    return (
      <div className={classes.container} style={containerStyle}>
        {_.map(STAR_ACTIONS, item => {
          const showPerm = ACLStore.can(item.showPerm);

          if (!showPerm) {
            return null;
          }
          const value = _.get(this.props, item.value, false);
          return (
            <StarItem
              item={item}
              key={item.value}
              value={value}
              onItemClick={this.onItemClick}
            />
          );
        })}
      </div>
    );
  }
}
PlanathonSubmissionStars.defaultProps = {
  containerStyle: {},
};

const mapActionCreators = {
  updateUnitPlanForPlanathon,
  updateAssessmentForPlanathon,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(PlanathonSubmissionStars);
