import React, { useRef } from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { prepareLoadMoreFeed } from "Utils";
import { useHandleScroll } from "Utils/CustomHooks";
import {
  withLoader,
  PaginationLoader,
  I18nHOC,
  NoDataComponent,
} from "UIComponents";
import { getOrganizationAssignmentFeedQuery } from "ClassRoom/modules/ClassRoomQuery";
import { getOrganizationAssignmentFeedFromCache } from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import AssignmentFeed from "./AssignmentFeed";
import { getAssignmentsFromEdgesMemoized } from "./AssignmentSelectionUtils";
import classes from "./AssignmentSelection.scss";

const AssignmentSelection = props => {
  const {
    assignments,
    selectedItems,
    updateSelectedItems,
    disabledItems,
    customOverlayElement,
    customEmptyViewElement,
    customHeaderElement,
    networkStatus,
    loadMoreFeed,
    hasNextPage,
    isFetchingMoreData,
    styles,
    t,
  } = props;

  const scrollContainerRef = useRef({});
  const getScrollRef = () => {
    return scrollContainerRef.current;
  };

  useHandleScroll({ networkStatus, loadMoreFeed, hasNextPage, getScrollRef });

  const assignmentsCount = _.size(assignments);
  const areAssignmentsEmpty = assignmentsCount == 0;

  return (
    <div
      className={classes.container}
      ref={scrollContainerRef}
      style={_.get(styles, "container", {})}
    >
      <div className={classes.innerContainer}>
        {customHeaderElement &&
          React.cloneElement(customHeaderElement, {
            count: assignmentsCount,
          })}
        {!areAssignmentsEmpty ? (
          <>
            <div
              className={classes.feedContainer}
              styles={_.get(styles, "feedContainer", {})}
            >
              <AssignmentFeed
                assignments={assignments}
                selectedItems={selectedItems}
                updateSelectedItems={updateSelectedItems}
                disabledItems={disabledItems}
                customOverlayElement={customOverlayElement}
                styles={styles}
              />
            </div>
            {isFetchingMoreData && <PaginationLoader />}
          </>
        ) : customEmptyViewElement ? (
          customEmptyViewElement
        ) : (
          <div className={classes.emptyStateContainer}>
            <NoDataComponent
              isSearch={false}
              emptyHeaderText={t("common:no_data_found")}
              emptyText={""}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;

  return {
    organizationId,
  };
};

const composedAssignmentSelection = compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, null),
  graphql(getOrganizationAssignmentFeedQuery, {
    name: "getOrganizationAssignmentFeed",
    alias: "getOrganizationAssignmentFeed",
    options: ({
      organizationId,
      first,
      filters,
      orderBy,
      orderByDirection,
    }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          organizationId,
          first,
          orderBy,
          orderByDirection,
          filters,
        },
      };
    },
    props({
      getOrganizationAssignmentFeed,
      ownProps: {
        organizationId,
        first,
        filters,
        orderBy,
        orderByDirection,
        t,
      },
    }) {
      const queryData = getOrganizationAssignmentFeedFromCache({
        organizationId,
        first,
        orderBy,
        orderByDirection,
        filters,
      });

      const hasNextPage = _.get(
        queryData,
        "assignments.pageInfo.hasNextPage",
        false
      );
      const networkStatus = _.get(
        getOrganizationAssignmentFeed,
        "networkStatus"
      );

      const endCursor = _.get(
        getOrganizationAssignmentFeed,
        "node.assignments.pageInfo.endCursor",
        ""
      );

      const edges = _.get(queryData, "assignments.edges", []);
      const assignments = getAssignmentsFromEdgesMemoized({ data: edges, t });

      return {
        assignments,

        hasNextPage,
        isData: !_.isEmpty(queryData),
        networkStatus,
        isLoading: _.includes([1, 2], networkStatus),
        isFetchingMoreData: networkStatus == 3,

        loadMoreFeed: prepareLoadMoreFeed({
          query: getOrganizationAssignmentFeedQuery,
          variables: {
            organizationId,
            first,
            orderBy,
            orderByDirection,
            filters,
            after: endCursor,
          },
          queryData: getOrganizationAssignmentFeed,
          key: "assignments",
        }),
      };
    },
  }),
  withLoader
)(AssignmentSelection);

export default composedAssignmentSelection;

composedAssignmentSelection.defaultProps = {
  first: 20,
  orderBy: "PUBLISHED_AT",
  orderByDirection: "DESC",
  filters: {
    contentTypes: ["ASSESSMENT", "ASSIGNMENT_RESOURCE"],
    states: ["PUBLISHED"],
  },
  customOverlayElement: null,
};
