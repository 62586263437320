import React from "react";
import classes from "./ContentEditable.scss";
import PropTypes from "prop-types";
import { TextAreaInput } from "UIComponents";
import ContentEditable from "react-contenteditable";
const textAreaStyles = {
  padding: 0,
  border: 0,
};
class ContentEditableComp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      editValue: props.value,
    };
  }

  focus = () => {
    const { value } = this.props;
    this.setState(
      {
        editValue: value,
      },
      () => {
        this.domElm.focus();
      }
    );
  };

  save = (e, { key, shiftKey } = {}) => {
    const { name, updateInputField, onEnterPress } = this.props;

    if (updateInputField && this.isValueChanged()) {
      e.preventDefault();
      updateInputField({ [name]: this.domElm.textContent });
    }

    if (key == "Enter" && onEnterPress && !shiftKey) {
      e.preventDefault();
      onEnterPress();
    }
  };

  cancel = () => {
    this.setState({
      editing: false,
    });
  };

  isValueChanged = () => {
    return this.props.value !== this.domElm.textContent;
  };

  onTabPressed = (e, shiftKey) => {
    const { onTabPressed } = this.props;
    if (onTabPressed) {
      e.preventDefault();
      onTabPressed({ isShiftTab: shiftKey });
    }
  };

  handleKeyDown = e => {
    const { key, shiftKey } = e;

    switch (key) {
      case "Enter":
      case "Escape":
        this.save(e, { key, shiftKey });
        return false;
      case "Tab":
        this.onTabPressed(e, shiftKey);
        break;
    }
  };

  updateDomRef = ref => {
    this.domElm = ref;
  };

  updateInputField = params => {
    this.setState(params);
  };

  handleChange = evt => {
    this.setState({ editValue: evt.target.value });
  };

  pasteAsPlainText = event => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  render() {
    const { editValue } = this.state;
    const { placeholder, style } = this.props;

    return (
      <ContentEditable
        style={style}
        innerRef={this.updateDomRef}
        className={classes.container}
        placeholder={placeholder}
        html={editValue}
        disabled={false}
        onChange={this.handleChange}
        onBlur={this.save}
        onPaste={this.pasteAsPlainText}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}

ContentEditable.defaultProps = {};

ContentEditable.propTypes = {
  editing: PropTypes.bool,
};

export default ContentEditableComp;
