import React from "react";
import classes from "./HeroComponent.scss";
import classNames from "classnames";
import { UIButton, I18nHOC } from "UIComponents";
import { BAR_URL } from "Constants";
import { HeroData } from "Planathon/data";
import { CLOUD_URL } from "Constants";
import { CancelIcon, CheckboxOn } from "SvgComponents";

const HeroComponent = props => {
  const { onSubmissionClick, onClose, totalPlanathonUnits, t } = props;
  const { title, description, duration, subTitle } = HeroData;
  return (
    <div className={classes.container}>
      <div className={classes.detailsContainer}>
        <div className={classes.detailsLeftContainer}>
          <div className={classes.title}>{t(title)}</div>
          <div className={classes.subtitleContainer}>
            <div className={classes.subtitle}>{`${t(subTitle)}`}</div>
          </div>
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: t(description) }}
          ></div>
          <div className={classes.strips}>
            <img src={BAR_URL}></img>
          </div>
          <div className={classes.duration}>{t(duration)}</div>
          {/* <div className={classes.button}>
            <UIButton
              color={"pink"}
              onClick={onSubmissionClick}
              containerStyle={{ height: 56 }}
            >
              {totalPlanathonUnits > 0
                ? t("common:view_submissions")
                : t("common:start_submission")}
            </UIButton>
          </div> */}
        </div>
        <div className={classes.detailsRightContainer}>
          <iframe
            src="https://player.vimeo.com/video/375901355"
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className={classes.heroImageWrapper}>
        <img
          src={`${CLOUD_URL}/assets/website/planathon/hero_web.png`}
          className={classes.heroDesktopImage}
        ></img>

        <img
          src={`${CLOUD_URL}/assets/website/planathon/hero_mobile.png`}
          className={classes.heroMobileImage}
        ></img>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["planathon", "common"] })(HeroComponent);
