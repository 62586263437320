import React from "react";
import classes from "./Footer.scss";

class Footer extends React.PureComponent {
  render() {
    const { footerComponent, containerStyle } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={classes.footerInnerContainer}
          style={{ ...containerStyle }}
        >
          {footerComponent}
        </div>
      </div>
    );
  }
}

export default Footer;
