import _ from "lodash";

export const extractContentFromHTML = ({ htmlString, space = true }) => {
  if (!_.isEmpty(htmlString)) {
    let span = document.createElement("span");
    span.innerHTML = htmlString;
    if (space) {
      let children = span.querySelectorAll("*");
      let lengthOfChildren = _.get(children, "length", 0);
      for (let i = 0; i < lengthOfChildren; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  } else {
    return "";
  }
};
