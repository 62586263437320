import React from "react";

const Grade5Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M24.4531,22.5508 C24.5211,20.4438 25.4881,19.4438 27.5961,19.4438 L37.1311,19.4438 C39.5141,19.4438 39.9301,21.1018 39.9301,21.9298 C39.9301,22.7598 39.5141,24.4178 37.1311,24.4178 L29.7381,24.4178 L29.5311,28.2868 C30.2561,28.1128 31.2581,28.0448 31.9821,28.0448 C36.8891,28.0448 40.8261,30.6698 40.8261,35.8168 C40.8261,41.7598 36.1971,44.5568 31.3961,44.5568 C28.4261,44.5568 25.7301,43.4508 24.1411,41.7928 C23.5881,41.2068 23.1741,40.3768 23.1741,39.5818 C23.1741,38.0978 24.3491,36.9918 25.8691,36.9918 C26.5601,36.9918 27.1461,37.1638 28.2191,38.3048 C28.9781,39.0998 30.0491,39.6528 31.3261,39.6528 C33.2971,39.6528 34.9881,38.4438 34.9881,36.0938 C34.9881,33.1918 32.3631,32.3638 29.9801,32.3638 C28.9431,32.3638 28.1831,32.4668 27.5961,32.5708 C27.0781,32.6738 26.6971,32.7418 26.2831,32.7418 C25.6621,32.7418 24.0371,32.4998 24.1411,30.2208 L24.4531,22.5508 Z"></path>
      </g>
    </svg>
  );
};

Grade5Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default Grade5Icon;
