import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classes from "./IBSkillsModal.scss";
import { CommentBox } from "AppComponents";
import { withLoader } from "UIComponents";
import classNames from "classnames";
import MultiLevelSelector from "../../BenchmarksComponent/BenchmarksModal/MultiLevelSelector";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { mergeSelectedNodes, getPYPElementSetFromNodes } from "Utils";
import { getATLsFromCache } from "modules/CommonGraphqlHelpers";
import { getATLsQuery } from "modules/CommonQuery";
import Levels from "MultiLevelNodeEditor/routes/Levels/components";
import { I18nHOC } from "UIComponents";

class IBSkillsModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { showSidebar: false };
  }

  onSubmitClicked = ({
    selections,
    nodes,
    valuesToAdd,
    valuesToRemove,
    shouldCloseModal,
  }) => {
    this.props.onSubmitClicked({
      selections,
      nodes,
      valuesToAdd,
      valuesToRemove,
      shouldCloseModal,
    });
  };

  getNodesObject = nodes => {
    return _.reduce(
      nodes,
      (result, current) => {
        result[current.id] = current;
        return result;
      },
      {}
    );
  };

  onCloseComment = () => {
    this.setState({ showSidebar: false });
  };

  onCommentClick = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  renderSidebar = () => {
    const {
      state: { showSidebar },
      props: { fieldId },
    } = this;
    let rightPaneStyle = classNames(
      { [classes.modalSidebar]: true },
      { [classes.smallSideBar]: !showSidebar },
      { [classes.fullSideBar]: showSidebar }
    );
    return (
      <div className={rightPaneStyle}>
        <CommentBox
          parentType={"FIELD"}
          nodeId={fieldId}
          type={"PLANNER_FIELD"}
          onClose={this.onCloseComment}
        />
      </div>
    );
  };

  render() {
    const {
      props: {
        rootNode,

        value,

        selectedNodes,
        onCancelClicked,

        grades,

        t,
      },
      state: { showSidebar },
    } = this;

    let modalContainerStyle = classNames({
      [classes.modalContentSmall]: showSidebar,
      [classes.modleContentFull]: !showSidebar,
    });

    return (
      <div className={classes.modalMainContainer}>
        <div className={modalContainerStyle}>
          <Levels
            label={t("unitPlanTemplate:unitPlan_atls_label")}
            value={value}
            rootNode={rootNode}
            isTagging={true}
            mode={"view"}
            showOutline={false}
            nodeType="ATL"
            valueType={"atls"}
            onBackClick={onCancelClicked}
            onSubmitClick={this.onSubmitClicked}
            grades={grades}
            selectedNodes={selectedNodes}
          />
        </div>
        {/* {this.renderSidebar()} */}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const atlSet = getPYPElementSetFromNodes({
    nodes: [
      ..._.get(ownProps.resolvedValue, "atls", []),
      ..._.get(ownProps, "options.atlCategories", []),
    ],
  });
  const nodeId = _.get(ownProps.rootNode, "id", "");

  return {
    organizationId: state.login.userInfo.org_id,
    nodes: _.get(atlSet, "nodes", []),
    rootNodes: _.get(atlSet, "rootNodes", []),
    isLoading: false,
    isData: true,
    nodeId,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlanTemplate", "unitPlan"] }),
  connect(mapStateToProps, mapActionCreators),
  // graphql(getATLsQuery, {
  //   name: "getALTs",
  //   skip: ({ nodeId }) => !nodeId,
  //   options: ({ nodeId, grades }) => ({
  //     fetchPolicy: "cache-and-network",
  //     variables: {
  //       id: nodeId,
  //       grades
  //     }
  //   }),
  //   props({
  //     getALTs,
  //     ownProps: { isData, isLoading, grades, nodeId, selectedNodes }
  //   }) {
  //     const queryData = getATLsFromCache({
  //       id: nodeId,
  //       grades
  //     });

  //     const elements = mergeSelectedNodes({
  //       nodes: _.get(queryData, "atls", []),
  //       selectedNodes
  //     });
  //     const rootNode = _.find(elements, item => item.id == nodeId);

  //     return {
  //       rootNodes: _.map(
  //         _.filter(elements, item => item.levelId == "L2"),
  //         node => node.id
  //       ),
  //       levels: _.reduce(
  //         _.get(rootNode, "levels", []),
  //         (result, level) => {
  //           if (level.id !== _.get(rootNode, "levelId", "")) {
  //             result.push(level);
  //           }
  //           return result;
  //         },
  //         []
  //       ),

  //       nodes: _.keyBy(elements, "id", ""),
  //       nodeArray: elements,
  //       isData:
  //         !_.isEmpty(queryData) &&
  //         isData &&
  //         (!nodeId ||
  //           _.includes(
  //             _.map(elements, item => item.id),
  //             nodeId
  //           )),
  //       isLoading:
  //         getALTs["networkStatus"] == 1 ||
  //         getALTs["networkStatus"] == 2 ||
  //         isLoading
  //     };
  //   }
  // }),
  withLoader
)(IBSkillsModal);

IBSkillsModal.propTypes = {
  types: PropTypes.array,
};

IBSkillsModal.defaultProps = {
  levels: [
    {
      id: "L1",
      value: "Objectives",
    },
    {
      id: "L2",
      value: "Sub-objectives",
    },
  ],
  rootNodes: [],
  nodes: [],
};
