import React from "react";
import classes from "./RightComponent.scss";
import { SettingIcon } from "SvgComponents";
import { colors } from "Constants";

const RightComponent = React.memo(({ containerStyles, label, onClick }) => {
  return (
    <div
      className={classes.container}
      style={containerStyles}
      onClick={onClick}
    >
      <div className={classes.settingIcon}>
        <SettingIcon width={16} height={16} fill={colors.blue29} />
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
});

export default RightComponent;
