import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { I18nHOC, LinkWithTooltip } from "UIComponents";
import classes from "./AttachmentItemV3.scss";
import { downloadFromUrl } from "modules/Services";
import { getThumbnailIcon, htmlToText, getLabelValue } from "Utils";
import {
  DownloadOutlined,
  JournalAddOutlined,
  DeleteOutlined,
} from "@toddle-design/web-icons";
import { ModelContent } from "../AttachmentCard/AttachmentCard";
import _ from "lodash";

const AttachmentItemV3 = props => {
  const {
    attachment,
    t,
    isDisabledMode,
    horizontalCardStyle,
    mode,
    showCreatePostButton,
    showDeleteForSelf,
    deleteAttachment,
    attachmentGroup,
    userId,
    attachmentBottomComponent,
  } = props;

  const progressValue = parseInt(_.get(attachment, `progress`, "101"));

  //The progress Value is between 0-100 when its being uploaded, and once uploaded
  //the attachment doesn't have progress in it, so by default its 101, which means its created
  const isAttachmentCreated = progressValue > 100;
  const { id, type, mimeType, createdBy } = attachment;
  const iconSvg = getThumbnailIcon({ type, mimeType });

  const [showMediaModal, setShowMediaModal] = useState(false);

  const toggleMediaModal = value => {
    setShowMediaModal(value);
  };

  const onDownloadClick = e => {
    e.stopPropagation();
    const { downloadFromUrl, attachment } = props;
    downloadFromUrl({
      attachment: attachment,
      shouldAskForSave: true,
    });
  };

  const onDeleteClick = e => {
    e.stopPropagation();

    const attachmentGroupId = _.get(attachmentGroup, "id", "");

    if (deleteAttachment) {
      deleteAttachment(attachmentGroupId);
    }
  };

  const onAttachmentClick = e => {
    e.stopPropagation();
    const { attachment, onClickAttachmentCard, attachmentGroup } = props;

    if (onClickAttachmentCard) {
      onClickAttachmentCard(attachment.id, attachmentGroup);
    } else {
      if (!showMediaModal) {
        setShowMediaModal(true);
      }
    }
  };

  const onClickCreatePostLocal = e => {
    e.stopPropagation();
    const { attachment, onClickCreatePost } = props;
    if (onClickCreatePost) {
      onClickCreatePost(attachment);
    }
  };

  const stopPropagationHandler = e => {
    e.stopPropagation();
  };

  const showAttachmentItemBottomComponent =
    _.isFunction(attachmentBottomComponent) && attachmentBottomComponent();

  return (
    <div className={classes.attachmentItem}>
      <div className={classes.container} style={horizontalCardStyle}>
        <div
          className={classes.innerContainer}
          onClick={
            isDisabledMode || !isAttachmentCreated ? null : onAttachmentClick
          }
        >
          <div className={classes.iconContainer}>
            <span className={classes.icon}>{iconSvg}</span>
          </div>
          <div className={classes.rightContainer}>
            <div
              className={classes.titleContainer}
              dangerouslySetInnerHTML={{
                __html: htmlToText(getLabelValue({ item: attachment, t }), {
                  wordwrap: 130,
                  tags: {
                    a: { options: { ignoreHref: true } },
                    ul: { options: { itemPrefix: " " } },
                  },
                }),
              }}
            />

            <div className={classes.iconsContainer}>
              {mode == "view" && showCreatePostButton && (
                <LinkWithTooltip
                  tooltip={t("journal:add_to_journal")}
                  placement={"bottom"}
                  href="#"
                  id="tooltip-CreatePostSvg"
                >
                  <div
                    className={classes.actionIcon}
                    onClick={onClickCreatePostLocal}
                  >
                    <JournalAddOutlined size={"xxx-small"} variant={"subtle"} />
                  </div>
                </LinkWithTooltip>
              )}
              {isAttachmentCreated && type !== "LINK" && type !== "WORKBOOK" && (
                <LinkWithTooltip
                  tooltip={t("common:download")}
                  placement={"bottom"}
                  href="#"
                  id="tooltip-DownloadIcon"
                >
                  <div className={classes.actionIcon} onClick={onDownloadClick}>
                    <DownloadOutlined size={"xxx-small"} variant={"subtle"} />
                  </div>
                </LinkWithTooltip>
              )}
              {showDeleteForSelf && userId == _.get(createdBy, "id") && (
                <LinkWithTooltip
                  tooltip={t("common:delete")}
                  placement={"bottom"}
                  href="#"
                  id="tooltip-DeleteIcon"
                >
                  <div className={classes.actionIcon} onClick={onDeleteClick}>
                    <DeleteOutlined size={"xxx-small"} variant={"subtle"} />
                  </div>
                </LinkWithTooltip>
              )}
            </div>
          </div>
          <ModelContent
            attachments={[attachment]}
            toggleMediaModal={toggleMediaModal}
            showMediaModal={showMediaModal}
            mode={mode}
          />
        </div>
        {showAttachmentItemBottomComponent && (
          <div className={classes.attachmentDivider}></div>
        )}
        {showAttachmentItemBottomComponent && (
          <div onClick={stopPropagationHandler}>
            {attachmentBottomComponent()}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { attachment } = ownProps;
  const userId = state.login.userInfo.id;

  if (_.get(attachment, "isPost", false)) {
    const attachmentObj = _.last(_.get(attachment, "attachments", []));
    const isWorkbook = _.isEqual(_.get(attachmentObj, "type", ""), "WORKBOOK");
    if (isWorkbook) {
      return {
        attachment: { ...attachmentObj, isPost: true },
        attachmentGroup: attachment,
        userId,
      };
    } else {
      return {
        attachment: { ...attachmentObj, id: attachment.id, isPost: true },
        attachmentGroup: attachment,
        userId,
      };
    }
  }

  return {
    attachment,
    userId,
  };
};

const mapActionCreators = {
  downloadFromUrl,
};

AttachmentItemV3.defaultProps = {
  horizontalCardStyle: {},
  showDeleteForSelf: false,
};

export default compose(
  I18nHOC({ resource: ["common", "journal"] }),
  connect(mapStateToProps, mapActionCreators)
)(AttachmentItemV3);
