import React from "react";
import Loading from "../Loading";
import { getHOCDisplayName } from "Utils";

const AsyncRouteLoading = getComponent => {
  class AsyncComponent extends React.Component {
    static component = null;
    state = { component: AsyncComponent.component };

    componentDidMount() {
      if (!this.state.component) {
        getComponent()
          .then(component => {
            AsyncComponent.component = component;
            AsyncComponent.displayName = `WithAsyncComponent${getHOCDisplayName(
              component
            )}`;
            this.setState({ component });
          })
          .catch(e => {
            console.error(e);
            window.location.reload();
          });
      }
    }
    render() {
      const { component: Component } = this.state;
      return Component ? <Component {...this.props} /> : <Loading />;
    }
  }

  return AsyncComponent;
};

export default AsyncRouteLoading;
