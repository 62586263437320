import { colors, CLOUD_URL } from "Constants";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";

const STATUS_NOT_STARTED = "NOT_STARTED";
const STATUS_PLAN_CHANGE_NOT_STARTED = "PLAN_CHANGE_NOT_STARTED";
const STATUS_IN_PROGRESS = "IN_PROGRESS";
const STATUS_QC_INITIATED = "QC_INITIATED";
const STATUS_SETUP_COMPLETE = "COMPLETE";
const GRADES = "GRADES";
const YEAR_GROUPS = "YEAR_GROUPS";

const curriculumCardDetails = t => {
  return {
    IB_PYP: {
      color: colors.salmon60,
      title: t("common:pyp"),
      imageURL: `${CLOUD_URL}/assets/webapp/SchoolOnboardingPYPSetupV2.svg`,
      label: t("common:PYP"),
    },
    IB_MYP: {
      color: colors.violet63,
      title: t("common:myp"),
      imageURL: `${CLOUD_URL}/assets/webapp/SchoolOnboardingMYPSetupV2.svg`,
      label: t("common:MYP"),
    },
    UBD: {
      color: colors.yellow50,
      title: t("common:ubd"),
      imageURL: `${CLOUD_URL}/assets/webapp/SchoolOnboardingUBDSetup.svg`,
      label: t("common:UBD"),
    },
    IB_DP: {
      color: colors.salmon60,
      title: t("common:dp"),
      imageURL: `${CLOUD_URL}/assets/webapp/SchoolOnboardingPYPSetupV2.svg`,
      label: t("common:DP"),
    },
  };
};

const getCurriculumName = (curriculumType, t) => {
  switch (curriculumType) {
    case CURRICULUM_TYPE_MYP:
      return t("common:MYP");
    case CURRICULUM_TYPE_PYP:
      return t("common:PYP");
    case CURRICULUM_TYPE_UBD:
      return t("common:UBD");
    case CURRICULUM_TYPE_DP:
      return t("common:DP");
    default:
      return "";
  }
};

const getStepLabel = ({ selectedStep, t }) => {
  const count = _.last(_.split(selectedStep, "_"));
  return t("onBoarding:step_count", { count });
};

export {
  STATUS_NOT_STARTED,
  STATUS_PLAN_CHANGE_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_QC_INITIATED,
  STATUS_SETUP_COMPLETE,
  GRADES,
  YEAR_GROUPS,
  getCurriculumName,
  curriculumCardDetails,
  getStepLabel,
};
