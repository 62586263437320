import React from "react";
import classes from "./AddNoteDialogue.scss";
import { UIModal, I18nHOC, RichTextEditor } from "UIComponents";
import { colors } from "Constants";
import { isEmptyHtml } from "Utils";
import { Button, IconButton } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";

class AddNoteDialogue extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      noteValue: _.get(props, "note.title", ""),
      mode: _.get(props, "mode", "edit"),
    };
  }

  saveNote = () => {
    const { saveNote, onClose } = this.props;

    const { noteValue } = this.state;

    saveNote({
      note: _.get(noteValue, "description", ""),
    });

    onClose();
  };

  updateNote = val => {
    this.setState({ noteValue: val });
  };

  updateMode = val => {
    this.setState({ mode: val });
  };

  render() {
    const { t, onClose, note } = this.props;
    const { noteValue, mode } = this.state;

    const isImportedFromOtherOrg = _.get(note, "isImported", false);

    const styles = {
      content: {
        color: colors.gray48,
        fontSize: "1.2rem",
      },
    };

    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={onClose}
      >
        <div className={classes.headerContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.headerMainTxt}>{t("common:add_note")} </div>
          </div>
          <IconButton
            icon={<CloseOutlined />}
            variant="plain"
            size="medium"
            onClick={onClose}
          />
        </div>

        {mode == "VIEW" ? (
          <div
            className={classes.centerContainer}
            dangerouslySetInnerHTML={{
              __html: noteValue,
            }}
          ></div>
        ) : (
          <div className={classes.centerContainer}>
            <RichTextEditor
              name="description"
              width={"100%"}
              height={400}
              autoresizeMaxHeight={400}
              placeholder={t("common:text_placeholder")}
              updateInputField={this.updateNote}
              textAreaStyles={styles.content}
              value={noteValue}
            />
          </div>
        )}

        <div className={classes.footer}>
          {mode == "VIEW" ? (
            !isImportedFromOtherOrg ? (
              <Button
                size="medium"
                variant="progressive"
                onClick={() => this.updateMode("EDIT")}
              >
                {t("common:edit")}
              </Button>
            ) : null
          ) : (
            <Button
              size="medium"
              disabled={isEmptyHtml(_.get(noteValue, "description", ""))}
              onClick={this.saveNote}
            >
              {t("common:save")}
            </Button>
          )}
        </div>
      </UIModal>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(AddNoteDialogue);
