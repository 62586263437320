import React, { useState, useEffect } from "react";
import classes from "./Download.scss";

const Download = ({ t, handleClick }) => {
  const [counter, setCounter] = useState(3);

  useEffect(() => {
    startCounter();
  }, []);

  const startCounter = () => {
    const ref = setInterval(() => {
      setCounter(prev => {
        const newValue = prev - 1;
        if (newValue === 1) {
          clearInterval(ref);
          handleClick();
        }
        return newValue;
      });
    }, 1000);
  };

  return (
    <div className={classes.countDownContainer}>
      <div className={classes.message}>
        {t("community:download_starting_in")}
      </div>
      <div className={classes.counter}>{counter}</div>
      <div className={classes.message}>{t("community:seconds")}</div>
    </div>
  );
};

export default Download;
