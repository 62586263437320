import React, { useState, Fragment } from "react";
import classes from "./TagPractices.scss";
import { SNP_DOCUMENT_CATEGORY } from "SnP_v2/modules/SnPUtils";
import Header from "./Header";
import DocumentCategoryHeader from "./DocumentCategoryHeader";
import StandardListWithSearch from "./StandardListWithSearch";
import { Button } from "@toddle-design/web";

import {
  EVIDENCE_TYPE_UNIT,
  EVIDENCE_TYPE_POST,
  EVIDENCE_TYPE_ASSESSMENT,
  EVIDENCE_TYPE_PROGRESS_REPORT,
  ASSIGNMENT_TYPE_QUICK_TASK,
  ASSIGNMENT_TYPE_LE,
  ASSIGNMENT_TYPE_FORMATIVE,
  ASSIGNMENT_TYPE_SUMMATIVE,
  ASSIGNMENT_TYPE_PERFORMANCE_TASK,
  ASSIGNMENT_TYPE_PRE_ASSESSMENT,
  ASSIGNMENT_TYPE_SUPPLEMENTARY,
  ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE,
} from "Constants/stringConstants";

const Footer = props => {
  const {
    showBackButton,
    count,
    onBackButtonClick,
    t,
    onAddButtonClick,
  } = props;

  return (
    <div className={classes.footer}>
      <div className={classes.count}>
        {t("common:label_tagged", {
          label: t("common:label_count", {
            label: t("common:practice", { count }),
            count,
          }),
        })}
      </div>
      <div className={classes.buttonContainer}>
        {showBackButton && (
          <Button variant={"inline-progressive"} onClick={onBackButtonClick}>
            {t(`common:back`)}
          </Button>
        )}
        <Button variant={"primary"} onClick={onAddButtonClick}>
          {t(`common:add`)}
        </Button>
      </div>
    </div>
  );
};

const TagPractices = props => {
  const {
    itemType,
    itemTitle,
    t,
    thumbnailData,
    goToPreviousPage,
    handleTaggedPractices,
    closeButtonElement,
    setId,
    practiceListsCollection,
    showBackButton,

    cycleDetails,
  } = props;

  const { taggedPractices, disabledPractices } = practiceListsCollection;

  const [selectedPractices, setSelectedPractices] = useState(taggedPractices);

  const toggleSelectedPractices = ({ id, isSelected }) => {
    setSelectedPractices(selectedPractices => {
      if (isSelected) return [...selectedPractices, id];
      return _.filter(selectedPractices, practiceId => practiceId !== id);
    });
  };

  const onAddButtonClick = () => handleTaggedPractices({ selectedPractices });

  const HeaderComponent =
    itemType === SNP_DOCUMENT_CATEGORY ? DocumentCategoryHeader : Header;

  const evidenceTypeToEvidenceNameMapping = {
    [EVIDENCE_TYPE_UNIT]: "common:unit",
    [EVIDENCE_TYPE_ASSESSMENT]: "common:assessment",
    [EVIDENCE_TYPE_POST]: "common:post",
    [EVIDENCE_TYPE_PROGRESS_REPORT]: "common:report_card",
    [ASSIGNMENT_TYPE_QUICK_TASK]: "common:quick_task",
    [ASSIGNMENT_TYPE_LE]: "common:le_label",
    [ASSIGNMENT_TYPE_FORMATIVE]: "classRoom:formative_assessment",
    [ASSIGNMENT_TYPE_SUMMATIVE]: "classRoom:summative_assessment",
    [ASSIGNMENT_TYPE_PRE_ASSESSMENT]: "classRoom:pre_assessment",
    [ASSIGNMENT_TYPE_PERFORMANCE_TASK]: "common:performance_task",
    [ASSIGNMENT_TYPE_SUPPLEMENTARY]: "common:supplementary_evidence",
    [ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE]: "common:quick_task",
  };

  const itemTypeLabel = t("common:lowercase", {
    text: t(evidenceTypeToEvidenceNameMapping[itemType]),
  });

  const itemDetails = {
    itemTypeLabel,
    itemTitle,
    messageLocale:
      itemType === EVIDENCE_TYPE_PROGRESS_REPORT
        ? "snp:progress_report_approved_for_practice_label"
        : "snp:evidence_approved_for_practice_label",
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <HeaderComponent
          t={t}
          itemType={itemType}
          itemLabel={itemTypeLabel}
          thumbnailData={thumbnailData}
          itemTitle={itemTitle}
          closeButtonElement={closeButtonElement}
        />

        <StandardListWithSearch
          practiceListsCollection={{
            ..._.omit(practiceListsCollection, "taggedPractices"),
            selectedPractices,
          }}
          updateSelectedPractices={toggleSelectedPractices}
          setId={setId}
          itemDetails={itemDetails}
          cycleDetails={cycleDetails}
          t={t}
        />
      </div>

      <Footer
        showBackButton={showBackButton}
        count={_.size(selectedPractices) + _.size(disabledPractices)}
        onAddButtonClick={onAddButtonClick}
        onBackButtonClick={goToPreviousPage}
        t={t}
      />
    </Fragment>
  );
};

export default TagPractices;

TagPractices.defaultProps = {
  showBackButton: true,
};
