import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";

import ACLStore from "lib/aclStore";

import { ENABLE_MULTIPLE_CURRICULUM_PROGRAMS } from "Constants/permissionConstants";

export const CURRICULUM_PROGRAMS = [
  {
    label: "Primary Years Programme(PYP)",
    value: CURRICULUM_TYPE_PYP,
  },
  {
    label: "Middle Years Programme(MYP)",
    value: CURRICULUM_TYPE_MYP,
  },
  {
    label: "Understanding by Design(UbD)",
    value: CURRICULUM_TYPE_UBD,
  },
  {
    label: "Diploma Programme(DP)",
    value: CURRICULUM_TYPE_DP,
  },
];

export const getAvailableCurriculumProgramTypes = ({ curriculumPrograms }) => {
  const areMultipleCurriculumProgramsEnabled = ACLStore.can(
    ENABLE_MULTIPLE_CURRICULUM_PROGRAMS
  );

  if (areMultipleCurriculumProgramsEnabled) {
    return CURRICULUM_PROGRAMS;
  }

  const curriculumProgramTypes = _.map(curriculumPrograms, ({ type }) => type);

  return _.filter(
    CURRICULUM_PROGRAMS,
    ({ value }) => !_.includes(curriculumProgramTypes, value)
  );
};
