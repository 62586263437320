import React from "react";
import classes from "./LeLibrary.scss";
import PropTypes from "prop-types";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { connect } from "react-redux";
import { getUnitPlanResourceLibraryQuery } from "UnitPlans/modules/UnitPlanQuery";
import { withLoader, withPusherBind } from "UIComponents";
import { getUnitPlanResourceLibraryFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import { graphql, compose } from "react-apollo";

import {
  updateLeInRedux,
  deleteLe,
  createLe,
  duplicateLe,
  gotoLeDetails,
} from "LearningEngagement/modules/LeModule";

import { getPrintFile } from "modules/Services";
import LeLibraryEdit from "./LeLibraryEdit";
import { ItemViewFeed } from "UnitPlans/components/IBcomponents";
import { ResourceCardV1 } from "AppComponents";
import { colors, fontStyle } from "Constants";

const ResourceViewTheme = {
  imageContainer: {
    height: 44,
    width: 60,
  },
  sectionLabel: {
    fontSize: "1.4rem",
    color: colors.gray31,
    ...fontStyle.bold,
  },
  sectionAuthorAndDuration: {
    fontSize: "1.4rem",
    color: colors.gray48,
  },
  sectionItem: {
    alignItems: "center",
    cursor: "pointer",
  },
  sectionItemDetails: {
    padding: "0 12px",
  },
};

class LeLibrary extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  showModal = ({ id, mode }) => {
    this.props.gotoLeDetails({ id, mode });
  };

  onViewCardClicked = ({ id, mode = "view" }) => {
    this.showModal({ id, mode });
  };

  renderViewItem = ({ item, index }) => {
    return (
      <ResourceCardV1
        item={item}
        showTypeLabel={false}
        imageThumbSize={120}
        theme={ResourceViewTheme}
        key={item.id}
        onContainerClick={() => this.onViewCardClicked({ id: item.id })}
      />
    );
  };

  renderView = () => {
    const { les } = this.props;

    return (
      <ItemViewFeed
        items={les}
        {...this.props}
        renderItem={this.renderViewItem}
        itemText={"common:le_label"}
      />
    );
  };

  render() {
    const { mode } = this.props;

    return (
      <React.Fragment>
        {mode == "edit" ? (
          <LeLibraryEdit {...this.props} showModal={this.showModal} />
        ) : (
          this.renderView()
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  updateLeInRedux,
  deleteLe,
  createLe,
  duplicateLe,
  getPrintFile,
  gotoLeDetails,
};

const mapStateToProps = (state, ownProps) => {
  const unitPlanId = state.planner.unitPlanData.unitPlanId;
  const unitPlanDetails = getUnitPlanDetailsFromCache(
    state.planner.unitPlanData.unitPlanId
  );
  const unitPlanFields = _.get(unitPlanDetails, "allFields", []);
  const unitPlantitle = _.get(
    _.find(unitPlanFields, { uid: "title" }),
    "value",
    ""
  );

  return {
    unitPlanId,
    unitPlantitle,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getUnitPlanResourceLibraryQuery, {
    name: "getUnitPlanResourceLibrary",
    options: ({ unitPlanId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: unitPlanId, groupType: "LEARNING_ENGAGEMENT" },
    }),
    props({ getUnitPlanResourceLibrary, ownProps: { unitPlanId } }) {
      const unitPlanDetails = getUnitPlanResourceLibraryFromCache({
        id: unitPlanId,
        groupType: "LEARNING_ENGAGEMENT",
      });
      return {
        onPusherEventTriggered: () => getUnitPlanResourceLibrary.refetch(),
        isData: !_.isEmpty(unitPlanDetails),
        isLoading:
          getUnitPlanResourceLibrary["networkStatus"] == 1 ||
          getUnitPlanResourceLibrary["networkStatus"] == 2,
        getUnitPlanResourceLibrary,
        les: _.map(
          _.get(unitPlanDetails, "resourceLibrary.edges", []),
          resource => resource.node
        ),
      };
    },
  }),
  withPusherBind(["unitPlanResourceLibraryFeedUpdate"]),
  withLoader
)(LeLibrary);

LeLibrary.propTypes = {
  label: PropTypes.string,
  subText: PropTypes.string,
  labelStyle: PropTypes.object,
  labelComponent: PropTypes.element,
  mode: PropTypes.string,
};

LeLibrary.defaultProps = {
  label: "",
  subText: "",
  labelStyle: {},
  mode: "edit",
};
