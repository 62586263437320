import React from "react";
import classes from "./CreateAccount.scss";
import { FormTextInput, FormSelectDropdown, Loading } from "UIComponents";
import { Button } from "@toddle-design/web";
import { ROLES, colors } from "Constants";
import { STATUS_CODES } from "Login/modules/LoginModule";
import { emailValidation } from "Utils";

const LoadingStyle = {
  width: 20,
  height: 20,
};

const PASSWORD_VALIDATION_TEXT =
  "Password should be atleast 8 characters in length";

const styles = {
  dropDownStyle: {
    borderRadius: "8px",
  },
  privacyTextStyle: {
    textAlign: "center",
    marginBottom: "16px",
  },
};

class CreateAccount extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { verifyingEmail: false };
    this.inputRefs = {};
    this.updateEmailField = _.debounce(this.updateEmailFieldOnDebounce, 500);
  }
  componentDidMount = () => {
    const { resetScreen, getDesignationList } = this.props;
    resetScreen("addIdentification");
    resetScreen("addPYPDetails");
    getDesignationList();
  };

  onNoWorkEmailClick = () => {
    const { updateCurrentScreen } = this.props;
  };

  renderWarningsComponent = warningsText => {
    return <div className={classes.emailWarnings}>{warningsText}</div>;
  };

  getWarningsText = () => {
    const {
      data: { stateCode },
      organizationSelectionData: {
        selectedSchool: { name: schoolName = "" } = {},
      } = {},
      renderContactUsButton,
    } = this.props;

    if (stateCode == "SUP_DOM_MIS_MTCH_NO_ORG") {
      return (
        <span>
          The email address domain you entered doesn’t match our records. You
          will need to provide additional identification to prove that you
          belong to this school. Please{" "}
          {renderContactUsButton({
            text: "chat with us",
            style: { color: colors.black },
          })}{" "}
          in case you have any questions.
        </span>
      );
    }

    if (stateCode == "SUP_DOM_MIS_MTCH_HS_ORG") {
      return `It is preffered that you enter a work email address. Non work email address will require approval from school admin before you can access the Toddle Platform`;
    }
    return null;
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "organizationSelection" });
  };

  onSubmitClick = () => {
    const {
      createUnauthenticatedUser,
      createInvitedUser,
      onNextClick,
      data: { token },
      showErrorToast,
    } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    if (!!token) {
      createInvitedUser();
    } else {
      createUnauthenticatedUser();
    }
    onNextClick();
  };

  passwordValidation = value => {
    if (value.length < 8) {
      return PASSWORD_VALIDATION_TEXT;
    }
    return "";
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  updateEmailFieldOnDebounce = async () => {
    const {
      verifyDomain,
      data: { email },
    } = this.props;

    if (emailValidation(email)) {
      this.setState({ verifyingEmail: true });
      await verifyDomain({
        email,
      });

      this.setState({ verifyingEmail: false });
    }
    if (this.inputRefs["email"]) {
      this.inputRefs["email"].isValid();
    }
  };

  updateInputEmailField = params => {
    const { updateInputField } = this.props;
    updateInputField(params);
    this.updateEmailField();
  };

  updateInputDesignationField = params => {
    const { updateInputField, designationList } = this.props;
    const role = _.get(
      _.find(designationList, { id: params.designation }),
      "signup_role_level"
    );
    updateInputField({ ...params, role });
  };
  onSingInClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "teacherLogin" });
  };

  checkEmailVerification = value => {
    const {
      checkEmailVerification,
      data: { stateCode },
    } = this.props;

    const verificationError = checkEmailVerification(value);
    return verificationError ? verificationError : this.getErrorMessage();
  };

  getErrorMessage = () => {
    const {
      data: { stateCode },
    } = this.props;
    switch (stateCode) {
      case "SUP_USR_EXSTS":
        return (
          <span>
            {STATUS_CODES[stateCode]}
            Please
            <span
              className={classes.signInButton}
              onClick={this.onSingInClick}
            >{`Sign In`}</span>
            {` instead.`}
          </span>
        );
    }
    return "";
  };

  isAllowed = () => {
    const {
      data: { stateCode },
    } = this.props;
    return stateCode == "SUP_INV_USR" || stateCode == "SUP_DOM_MTCH";
  };

  isRequestAllowed = () => {
    const {
      data: { stateCode },
    } = this.props;
    return (
      stateCode == "SUP_DOM_MIS_MTCH_HS_ORG" ||
      stateCode == "SUP_DOM_MIS_MTCH_NO_ORG"
    );
  };

  isAlreadyExists = () => {
    const {
      data: { stateCode },
    } = this.props;
    return stateCode == "SUP_USR_EXSTS";
  };

  onEnterPress = ({ next }) => {
    if (this.inputRefs[next] && this.inputRefs[next].focus) {
      this.inputRefs[next].focus();
    }
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      data: {
        email,
        role,
        designation,
        firstName,
        lastName,
        password,
        token,
        stateCode,
      } = {},
      organizationSelectionData: {
        selectedSchool: { name: schoolName = "" } = {},
      } = {},
      renderErrorComponent,
      renderBackButtonComponent,
      customStyle: {
        containerStyle,
        headerStyle,
        subTextStyle,
        buttonStyle,
      } = {},
      renderCreateAccountPrivacyText,
      designationList,
    } = this.props;
    const { verifyingEmail } = this.state;

    const isAlreadyExists = this.isAlreadyExists();
    const buttonText = !!token ? "Sign Up" : "Continue";
    const showDetails = (stateCode && !isAlreadyExists) || !!token;
    // const isDisabledRoleDropwDown = !!token && !!role;
    let isDisabledRoleDropwDown = !!token && !!role;
    if (stateCode == "SUP_INV_USR" && designation != undefined) {
      isDisabledRoleDropwDown = true;
    }
    const designationRoles = designationList.map(role => {
      return { value: role.id, label: role.title, subtitle: role.subText };
    });
    return (
      <div
        className={classes.container}
        style={{ ...containerStyle, paddingTop: 64 }}
      >
        {!token && renderBackButtonComponent({ onClick: this.onBackClick })}
        <div style={headerStyle}> {`Let’s get to know each other`} </div>
        <div style={subTextStyle}>
          {`Tell us about yourself and your role at ${schoolName}`}
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <div className={classes.inputContainer}>
              <FormTextInput
                size={"large"}
                textInputLabel={"Work email address*"}
                value={email}
                placeholder={"Enter work email address"}
                name={"email"}
                updateInputField={this.updateInputEmailField}
                ref={ref => (this.inputRefs["email"] = ref)}
                customValidation={this.checkEmailVerification}
                renderWarningsComponent={this.renderWarningsComponent}
                warningsText={this.getWarningsText()}
                error={"Enter email address"}
                disabled={!!token}
                onEnter={() => this.onEnterPress({ next: "designation" })}
              />
              {!!verifyingEmail && (
                <div className={classes.loadingContainer}>
                  <Loading loadingStyle={LoadingStyle} />
                </div>
              )}
            </div>
            {showDetails && (
              <React.Fragment>
                <div className={classes.drodpownContainer}>
                  <FormSelectDropdown
                    size={"large"}
                    options={designationRoles}
                    label={"Role*"}
                    value={designation}
                    placeholder={"Select your role"}
                    name={"designation"}
                    updateInputField={this.updateInputDesignationField}
                    ref={ref => (this.inputRefs["designation"] = ref)}
                    error={"Select role"}
                    disabled={isDisabledRoleDropwDown}
                    onEnter={() => this.onEnterPress({ next: "firstName" })}
                  />
                </div>
                <div className={classes.inputRowContainer}>
                  <FormTextInput
                    size={"large"}
                    textInputLabel={"First name*"}
                    value={firstName}
                    placeholder={""}
                    name={"firstName"}
                    updateInputField={updateInputField}
                    ref={ref => (this.inputRefs["firstName"] = ref)}
                    error={"Enter first name"}
                    onEnter={() => this.onEnterPress({ next: "lastName" })}
                  />
                  <FormTextInput
                    size={"large"}
                    textInputLabel={"Last name*"}
                    value={lastName}
                    placeholder={""}
                    name={"lastName"}
                    updateInputField={updateInputField}
                    ref={ref => (this.inputRefs["lastName"] = ref)}
                    error={"Enter last name"}
                    onEnter={() => this.onEnterPress({ next: "password" })}
                  />
                </div>

                <div className={classes.inputContainer}>
                  <FormTextInput
                    size={"large"}
                    textInputLabel={"Create a password*"}
                    value={password}
                    placeholder={""}
                    name={"password"}
                    updateInputField={updateInputField}
                    type={"password"}
                    ref={ref => (this.inputRefs["password"] = ref)}
                    customValidation={this.passwordValidation}
                    error={"Enter password"}
                    onEnter={this.onSubmitClick}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          {showDetails && (
            <div className={classes.bottomContainer}>
              {!!token ? (
                renderCreateAccountPrivacyText({
                  buttonText,
                  privacyTextStyle: styles.privacyTextStyle,
                })
              ) : (
                <div />
              )}
              <Button
                size={"large"}
                variant={"destructive"}
                isFullWidth={true}
                onClick={this.onSubmitClick}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CreateAccount;
