import React, { Component } from "react";
import PropTypes from "prop-types";
import classes from "./SearchBarContainer.scss";
import { SearchIcon } from "SvgComponents";

import { CancelIcon } from "SvgComponents";
import classNames from "classnames";

export default class SearchBarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputTextState: false,
      searchText: "",
    };
    this.onUpdateSearchText = _.debounce(
      this.onUpdateSearchTextOnDebounce,
      200
    );

    this.reference = {};
  }

  UNSAFE_componentWillMount() {
    if (this.props.searchTerm) {
      this.setState({ inputTextState: true });
    }
  }

  searchUpdate = e => {
    this.setState({ searchText: e.target.value });
    this.onUpdateSearchText();
  };

  onUpdateSearchTextOnDebounce = () => {
    this.props.changeSearchTerm(this.state.searchText);
  };

  toggleInputTextState = e => {
    if (this.props.searchTerm) {
      this.setState({ inputTextState: true });
      this.reference.searchInput.focus();
      e.stopPropagation();
    } else {
      this.setState({ inputTextState: !this.state.inputTextState });
      this.reference.searchInput.focus();
      e.stopPropagation();
    }

    if (this.props.onSearchBarClick) {
      this.props.onSearchBarClick();
    }
  };

  onClickInput = e => {
    e.stopPropagation();
    if (this.props.onSearchBarClick) {
      this.props.onSearchBarClick();
    }
  };

  cancelText = () => {
    this.setState({ searchText: "" });
    this.props.changeSearchTerm("");
  };

  closeSearchInput = () => {
    if (!this.reference.searchInput.value && this.reference.searchInput) {
      this.setState({ inputTextState: false });
    }
  };
  render() {
    const {
      showSearchIcon,
      showCancelIcon,
      searchIconStyle,
      cancelIconStyle,
    } = this.props;
    const deleteSvgClass = this.props.searchTerm
      ? classes.crossSvg
      : classes.crossSvgHidden;
    const searchInputClass = this.state.inputTextState
      ? classes.searchInputActive
      : classes.searchInput;
    const searchSvg = classNames(
      { [classes.searchSvg]: true },
      { [classes.searchSvgActive]: this.state.inputTextState }
    );

    return (
      <div className={classes.container} style={this.props.customStyle}>
        <div className={searchInputClass}>
          {showSearchIcon && (
            <div
              className={searchSvg}
              onClick={event => this.toggleInputTextState(event)}
              style={this.props.searchSvgStyle}
            >
              <SearchIcon {...searchIconStyle} />
            </div>
          )}
          <input
            ref={ref => {
              this.reference.searchInput = ref;
            }}
            className={classes.inputText}
            type="text"
            autoFocus={this.props.autoFocus}
            style={this.props.inputStyle}
            placeholder={this.props.placeholder}
            value={this.state.searchText}
            onChange={e => this.searchUpdate(e)}
            name="text"
            onClick={e => this.onClickInput(e)}
          />
          {showCancelIcon && (
            <div
              className={deleteSvgClass}
              onClick={() => {
                this.cancelText();
              }}
            >
              <CancelIcon fill={"#82828C"} {...cancelIconStyle} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SearchBarContainer.propTypes = {
  searchTerm: PropTypes.string,
  changeSearchTerm: PropTypes.func,
  placeholder: PropTypes.string,
  onSearchBarClick: PropTypes.func,
  customStyle: PropTypes.object,
};

SearchBarContainer.defaultProps = {
  showSearchIcon: true,
  autoFocus: false,
  showCancelIcon: true,
  searchIconStyle: {},
  cancelIconStyle: {},
};
