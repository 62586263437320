export default function routeChange({ getState }) {
  return next => action => {
    if (action.type == "@@router/LOCATION_CHANGE") {
      // console.log(action.payload);
      //  console.log(`Route Changed: ${action.payload.location.pathname}`);
      //  if(window.ga) ga('send', 'pageview',action.payload.pathname);
      //  console.log('Heelo');
      //  if(window.Intercom) {window.Intercom('update')}
    }

    return next(action);
  };
}
