const tagRegExGroup = "([@]+\\[([\\w\\W )][^\\)\\]]+)\\]\\(([0-9\\w]+)\\))";
const tagRegExWithoutGroup = "([@]+\\[[\\w\\W )][^\\)\\]]+\\]\\([0-9\\w]+\\))";
// const urlRegEx = /(https?|ftp|file|www)?:\/\/[^\s]+/g;
// const urlRegEx = /(((https|file|ftp):\/\/)|www\.)?[^\s]+\.[a-z]+/g;
export const urlRegEx = /((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*/g;
import Autolinker from "autolinker";
import _ from "lodash";

const isAllTagged = comment => {
  let result = false;
  const matches = comment.match(new RegExp(tagRegExGroup, "g"));
  _.forEach(matches, item => {
    const match = item.match(new RegExp(tagRegExGroup));
    if (match && match[3] === "all") {
      result = true;
    }
  });
  return result;
};

export const getTaggedUserIds = ({ comment, collaborators }) => {
  const matches = comment.match(new RegExp(tagRegExGroup, "g"));
  let taggedUsers = [];
  if (isAllTagged(comment)) {
    taggedUsers = _.reduce(
      collaborators,
      (result, collaborator) => {
        if (collaborator.id != "all") {
          result.push(collaborator.id);
        }
        return result;
      },
      []
    );
  } else {
    taggedUsers = _.reduce(
      matches,
      (result, item) => {
        const match = item.match(new RegExp(tagRegExGroup));
        if (match && match[3] !== "all") {
          result.push(match[3]);
        }
        return result;
      },
      []
    );
  }

  return taggedUsers;
};

export const getCommentDisplayContent = comment => {
  let result = comment.replace(
    new RegExp(tagRegExWithoutGroup, "g"),
    tagText => {
      const match = tagText.match(new RegExp(tagRegExGroup));
      if (match) {
        return `<b>@${match[2]}</b>`;
      }
      return tagText;
    }
  );
  try {
    result = getStringAutoLinks(result);
  } catch (error) {
    console.error(error);
  }
  result = result.replace(new RegExp(/\n/, "g"), "</br>");
  return result;
};

export const getStringAutoLinks = value => {
  if (typeof value !== "string") {
    return value;
  }
  value = Autolinker.link(value, {
    replaceFn: function (match) {
      switch (match.getType()) {
        case "url":
          return !_.find(
            _.split(match.getAnchorText(), "."),
            item => _.get(item, "length") <= 1
          );
        // let Autolinker perform its normal anchor tag replacement
      }
    },
  });
  // value = _.replace(value, /&nbsp;/g, " ");
  // value = value.autoLink({ target: "_blank", rel: "noopener noreferrer" });
  return value;
};

// https://github.com/bryanwoods/autolink-js
// Generated by CoffeeScript 1.10.0
(function () {
  var autoLink,
    slice = [].slice;
  autoLink = function () {
    var callback, k, linkAttributes, option, options, pattern, v;
    options = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    pattern = /(^|[\s\n]|<[A-Za-z]*\/?>)(((?:https?|ftp|file):\/\/)?(www.)?[\-A-Z0-9+\u0026\u2019@#\/%?=()~_|!:,.;]*\.[\-A-Z0-9+\u0026@#\/%=~()_|]+)/gi;
    if (!(options.length > 0)) {
      return this.replace(pattern, "$1<a href='$2'>$2</a>");
    }
    option = options[0];
    callback = option["callback"];
    linkAttributes = (function () {
      var results;
      results = [];
      for (k in option) {
        v = option[k];
        if (k !== "callback") {
          results.push(" " + k + "='" + v + "'");
        }
      }
      return results;
    })().join("");
    return this.replace(pattern, function (match, space, url) {
      var link;
      let actualUrl = url;
      if (
        !(
          typeof actualUrl === "string" &&
          (actualUrl.startsWith("https") || actualUrl.startsWith("http"))
        )
      ) {
        actualUrl = `//${url}`;
      }
      link =
        (typeof callback === "function" ? callback(url) : void 0) ||
        "<a href='" + actualUrl + "'" + linkAttributes + ">" + url + "</a>";
      return "" + space + link;
    });
  };

  String.prototype["autoLink"] = autoLink;
}.call(this));

export const isNumber = value => {
  return /^-?\d+$/.test(value);
};

export const replaceSlashNWithBRTag = string => {
  const regEx = new RegExp("((\\n)+)", "g");

  const replacer = (match, p1) => {
    const length = p1?.length;
    if (length === 1) {
      return "<br>";
    }
    return "<br><br>";
  };

  return string.replace(regEx, replacer);
};

/**
 * this fn will replace keys like {{key}} with values in label
 * ex. label = "PROJECT_GROUP_{{type}}" , key="type" value="DP_CAS"
 * so replacedLabel="PROJECT_GROUP_DP_CAS"
 */
export const replaceLabelKeysWithValue = ({ label, key, value }) => {
  return _.replace(label, new RegExp(`{{${key}}}`, "g"), value);
};
