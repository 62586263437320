import gql from "graphql-tag";
import { rubricFragment, rubricElementsFragment } from "./RubricFragments";
export const createRubricMutation = gql`
  mutation createRubric(
    $title: String
    $criterias: [CriteriaRubricInput!]
    $indicators: [IndicatorRubricInput!]
    $createdBy: ID!
    $isGlobal: Boolean
    $rubricId: ID
    $copyRubric: Boolean!
    $parentId: ID
    $parentType: ASSESSMENT_TOOL_PARENT_TYPE_ENUM
    $curriculumProgramId: ID
  ) {
    planner {
      createRubric(
        input: {
          title: $title
          criterias: $criterias
          indicators: $indicators
          createdBy: $createdBy
          isGlobal: $isGlobal
          rubricId: $rubricId
          copyRubric: $copyRubric
          parentId: $parentId
          parentType: $parentType
          curriculumProgramId: $curriculumProgramId
        }
      ) {
        ...rubricItem
      }
    }
  }
  ${rubricFragment.rubric}
`;

export const createRubricIndicatorMutation = gql`
  mutation createRubricIndicator(
    $rubricId: ID!
    $createdBy: ID!
    $label: String!
  ) {
    planner {
      createRubricIndicator(
        input: { createdBy: $createdBy, rubricId: $rubricId, label: $label }
      ) {
        indicator {
          ...indicatorItem
        }
        descriptors {
          ...descriptorItem
        }
      }
    }
  }
  ${rubricElementsFragment.indicator}
  ${rubricElementsFragment.descriptor}
`;

export const createRubricCriteriaMutation = gql`
  mutation createRubricCriteria(
    $rubricId: ID!
    $createdBy: ID!
    $label: String!
  ) {
    planner {
      createRubricCriteria(
        input: { createdBy: $createdBy, rubricId: $rubricId, label: $label }
      ) {
        criteria {
          ...criteriaItem
        }
        descriptors {
          ...descriptorItem
        }
      }
    }
  }
  ${rubricElementsFragment.criteria}
  ${rubricElementsFragment.descriptor}
`;

export const deleteRubricMutation = gql`
  mutation deleteRubric($id: ID!) {
    planner {
      deleteRubric(input: { id: $id })
    }
  }
`;

export const deleteRubricIndicatorMutation = gql`
  mutation deleteRubricIndicator($rubricId: ID!, $deletedBy: ID!, $id: ID!) {
    planner {
      deleteRubricIndicator(
        input: { deletedBy: $deletedBy, rubricId: $rubricId, id: $id }
      )
    }
  }
`;

export const deleteRubricCriteriaMutation = gql`
  mutation deleteRubricCriteria($rubricId: ID!, $deletedBy: ID!, $id: ID!) {
    planner {
      deleteRubricCriteria(
        input: { deletedBy: $deletedBy, rubricId: $rubricId, id: $id }
      )
    }
  }
`;

export const updateRubricMutation = gql`
  mutation updateRubric($id: ID!, $title: String!) {
    planner {
      updateRubric(input: { id: $id, title: $title }) {
        id
      }
    }
  }
`;

export const updateRubricIndicatorMutation = gql`
  mutation updateRubricIndicator($id: ID!, $updatedBy: ID!, $label: String) {
    planner {
      updateRubricIndicator(
        input: { updatedBy: $updatedBy, id: $id, label: $label }
      ) {
        id
      }
    }
  }
`;

export const updateRubricCriteriaMutation = gql`
  mutation updateRubricCriteria($id: ID!, $updatedBy: ID!, $label: String) {
    planner {
      updateRubricCriteria(
        input: { updatedBy: $updatedBy, id: $id, label: $label }
      ) {
        id
      }
    }
  }
`;

export const updateRubricDescriptorMutation = gql`
  mutation updateRubricDescriptor($id: ID!, $updatedBy: ID!, $label: String) {
    planner {
      updateRubricDescriptor(
        input: { updatedBy: $updatedBy, id: $id, label: $label }
      ) {
        id
      }
    }
  }
`;
