import { fontStyle, colors } from "Constants";

export const styles = {
  cardStyles: {
    container: {
      height: "80px",
      margin: "24px 36px 0",
    },
    title: {
      fontSize: "18px",
      lineHeight: "25px",
      ...fontStyle.bold,
      marginBottom: "4px",
      color: colors.gray13,
    },
    cardLabel: {
      padding: 0,
      marginLeft: "16px",
      border: "unset",
      borderRadius: "unset",
    },
    cardActions: {
      width: "max-content",
      marginRight: "16px",
    },
    cardIcon: {
      height: "72px",
      borderRadius: "8px 0 0 8px",
    },
    standards: {
      fontSize: "14px",
      lineHeight: "19px",
      ...fontStyle.medium,
      color: colors.gray48,
    },
    iconStyle: {
      width: 80,
      height: 80,
    },
  },
};
