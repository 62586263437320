import React, { useEffect } from "react";
import { UIButton, I18nHOC, UIModal } from "UIComponents";
import classes from "./Bifurcation.scss";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import * as EventTracker from "lib/eventTracker";
import { CLOUD_URL } from "Constants";
import { updateBasicUserDetails } from "modules/Services";
import {
  goToToddlePlateform,
  setBifurcationModalStatus,
} from "Login/modules/LoginModule";
import { goToCommunityOnboarding } from "modules/NavigationModule";
import { setDashboardCoachmarkVisibility } from "Teacher/modules/TeacherModule";
import { getUserInfo } from "Login/modules/LoginModule";

const toddleUrl = `${CLOUD_URL}/assets/webapp/Bifurcation_platform.png`;
const communityUrl = `${CLOUD_URL}/assets/webapp/Bifurcation_community.png`;

const Bifurcation = ({
  t,
  firstName,
  goToToddlePlateform,
  goToCommunityOnboarding,
  updateBasicUserDetails,
  bifurcationAction,
  setDashboardCoachmarkVisibility,
  setBifurcationModalStatus,
  name,
  userId,
}) => {
  useEffect(() => {
    if (bifurcationAction) handleClick(bifurcationAction);
    else {
      EventTracker.recordEvent({
        eventName: "Viewed Page",
        eventData: {
          page: "Bifurcation",
          entity_id: userId,
          entity_name: name,
          entity_type: "STAFF",
        },
      });
    }
  }, []);

  const handleClick = async path => {
    switch (path) {
      case "platform":
        await goToToddlePlateform();
        setDashboardCoachmarkVisibility(true);
        break;
      case "community": {
        const search = _.get(window, "location.search", "");
        await goToCommunityOnboarding({ search, saveLocation: false });
        break;
      }
    }

    if (!bifurcationAction) {
      EventTracker.recordEvent({
        eventName: "Clicked Bifurcation",
        eventData: {
          target: path,
          entity_id: userId,
          entity_name: name,
          entity_type: "STAFF",
        },
      });
    }

    await updateBasicUserDetails(
      { showBifurcationScreen: false },
      { portalType: "PLANNER" }
    );

    setBifurcationModalStatus(false);
  };

  if (bifurcationAction) return null;

  return (
    <UIModal
      overlayContainer={classes.overlayContainer}
      isOpen={true}
      isFullScreen={true}
    >
      <div className={classes.containerWrapper}>
        <div className={classes.container}>
          <div className={classes.title}>
            {t("community:bifurcation_title", { name: firstName })}
          </div>
          <div className={classes.subTitle}>
            {t("community:bifurcation_subtitle")}
          </div>

          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <div className={classes.imgContainer}>
                <img src={communityUrl} height={164} />
              </div>
              <div className={classes.cardTitle}>
                {t("community:explore_community")}
              </div>
              <div className={classes.cardDescription}>
                {t("community:explore_community_message")}
              </div>
              <UIButton onClick={() => handleClick("community")} color="pink">
                {t("common:go_to_community")}
              </UIButton>
            </div>

            <div className={classes.card}>
              <div className={classes.imgContainer}>
                <img src={toddleUrl} height={164} />
              </div>
              <div className={classes.cardTitle}>
                {t("community:explore_platform")}
              </div>
              <div className={classes.cardDescription}>
                {t("community:explore_platform_message")}
              </div>
              <UIButton onClick={() => handleClick("platform")} color="orchid">
                {t("community:explore_toddle")}
              </UIButton>
            </div>
          </div>
        </div>
      </div>
    </UIModal>
  );
};

const mapStateToProps = state => {
  const userInfo = getUserInfo({ portalType: "PLANNER" });
  const name = `${_.get(userInfo, "first_name", "")} ${_.get(
    userInfo,
    "last_name",
    ""
  )}`;
  return {
    name,
    userId: _.get(userInfo, "id"),
    bifurcationAction: _.get(state, "login.bifurcationAction"),
  };
};

const mapActionCreators = {
  goToToddlePlateform,
  goToCommunityOnboarding,
  updateBasicUserDetails,
  setDashboardCoachmarkVisibility,
  setBifurcationModalStatus,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] })
)(Bifurcation);
