import React from "react";
import classes from "./UserDetails.scss";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const UserDetails = ({
  t,
  name,
  isArchived,
  email,
  viewMode,
  nameClasses,
  profileTextStyle,
  statusWrapperStyle = {},
  type,
  wrapper,
  condition,
}) => {
  if (viewMode === "NAME_AND_EMAIL")
    return (
      <div className={classes.nameContainer}>
        {!!name && (
          <div className={classes.name} style={profileTextStyle}>
            {name}
          </div>
        )}
        {!isArchived ? (
          <div className={classes.email}>{email}</div>
        ) : (
          <div className={classes.rowWrapper}>
            {!!email && <div className={classes.email}>{email}</div>}
            {email && isArchived && (
              <span className={classes.seperator}>•</span>
            )}
            <div className={classes.archived}>{t("common:archived")}</div>
          </div>
        )}
      </div>
    );
  else if (viewMode === "NAME_ONLY")
    return (
      <div className={classes.statusWrapper} style={statusWrapperStyle}>
        <ConditionalWrapper condition={condition} wrapper={wrapper} type={type}>
          <div className={nameClasses}>{name}</div>
        </ConditionalWrapper>
        {isArchived && (
          <div className={classes.archived}>{t("common:archived")}</div>
        )}
      </div>
    );
  else return null;
};

export default compose(
  I18nHOC({
    resource: ["common"],
  })
)(UserDetails);
