import React from "react";

const VideoIconSvg = props => {
  return (
    <svg height={props.height} width={props.width} viewBox="0 0 24 16">
      <g stroke="none" strokeWidth="1" fill={props.fill} fillRule="evenodd">
        <path d="M22.290689,12.45304 C22.290689,12.649444 22.093851,12.728076 22.015046,12.767391 C21.936357,12.806591 21.857553,12.845907 21.700117,12.767391 L17.416439,9.247432 L17.416439,7.456953 L21.778806,3.232667 C21.896955,3.154151 22.015046,3.193409 22.093851,3.232667 C22.133138,3.271982 22.290689,3.350556 22.290689,3.54696 L22.290689,12.45304 Z M15.667726,12.68373 C15.667726,13.547979 14.959062,14.255139 14.092847,14.255139 L3.323534,14.255139 C2.457377,14.255139 1.748655,13.547979 1.748655,12.68373 L1.748655,3.316328 C1.748655,2.452021 2.457377,1.744918 3.323534,1.744918 L14.092847,1.744918 C14.959062,1.744918 15.667726,2.452021 15.667726,3.316328 L15.667726,12.68373 Z M22.976349,1.644953 C22.385777,1.330717 21.637652,1.330717 20.970593,1.723584 L17.30055,5.34004 L17.416439,3.258511 C17.416439,1.529897 15.999054,0 14.266681,0 L3.1497,0 C1.417327,0 0,1.529897 0,3.258511 L0,12.741547 C0,14.470103 1.417327,16 3.1497,16 L14.266681,16 C15.999054,16 17.416439,14.470103 17.416439,12.741547 L17.416439,11.364345 L21.086482,14.276474 C21.401412,14.472936 21.75586,14.590825 22.110192,14.590825 C22.425121,14.590825 22.700706,14.512194 23.015751,14.355047 C23.645668,14.001496 24,13.372909 24,12.665807 L24,3.373509 C23.960598,2.627149 23.606266,1.998562 22.976349,1.644953 Z" />
      </g>
    </svg>
  );
};

VideoIconSvg.defaultProps = {
  height: 24,
  width: 16,
};

export default VideoIconSvg;
