import React from "react";
import PropTypes from "prop-types";

const ScienceSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23.4998 16C23.4998 19.7951 22.6197 23.2063 21.2225 25.6514C19.8172 28.1107 17.952 29.5 15.9998 29.5C14.0475 29.5 12.1823 28.1107 10.777 25.6514C9.37983 23.2063 8.49976 19.7951 8.49976 16C8.49976 12.2049 9.37983 8.79366 10.777 6.34857C12.1823 3.88934 14.0475 2.5 15.9998 2.5C17.952 2.5 19.8172 3.88934 21.2225 6.34857C22.6197 8.79366 23.4998 12.2049 23.4998 16Z"
          stroke="#2DADBA"
        />
        <path
          d="M19.7494 22.4952C16.4628 24.3927 13.0685 25.3362 10.2524 25.3487C7.42001 25.3613 5.28422 24.4407 4.30808 22.75C3.33194 21.0593 3.60256 18.7493 5.02968 16.3027C6.44859 13.8701 8.96277 11.4023 12.2494 9.50479C15.5361 7.60724 18.9303 6.66379 21.7464 6.65125C24.5788 6.63864 26.7146 7.55926 27.6908 9.24998C28.6669 10.9407 28.3963 13.2507 26.9692 15.6973C25.5503 18.1298 23.0361 20.5976 19.7494 22.4952Z"
          stroke="#2DADBA"
        />
        <path
          d="M19.7501 9.50483C23.0367 11.4024 25.5509 13.8702 26.9698 16.3027C28.397 18.7493 28.6676 21.0593 27.6914 22.75C26.7153 24.4407 24.5795 25.3614 21.7471 25.3488C18.931 25.3362 15.5367 24.3928 12.2501 22.4952C8.96343 20.5977 6.44925 18.1299 5.03034 15.6973C3.60322 13.2507 3.33261 10.9407 4.30875 9.25002C5.28488 7.5593 7.42068 6.63868 10.2531 6.65129C13.0692 6.66383 16.4634 7.60728 19.7501 9.50483Z"
          stroke="#2DADBA"
        />
        <circle cx="15.9998" cy="16" r="2.5" fill="#96E6E0" stroke="#2DADBA" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ScienceSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

ScienceSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default ScienceSGIcon;
