import React from "react";
import classes from "./DropDown.scss";
import { ButtonDropdown, I18nHOC } from "UIComponents";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
const buttonDropDownContainerStyle = {
  top: 24,
  width: 120,
  zIndex: 11,
};

const buttonDropDownItemStyle = {
  height: 32,
};

const DropDown = ({ item, onUpdateShowDropDown, menuItems }) => {
  return (
    <div className={classes.container}>
      <ButtonDropdown
        onUpdateShowDropDown={onUpdateShowDropDown}
        authTabs={menuItems}
        containerStyle={buttonDropDownContainerStyle}
        itemStyle={buttonDropDownItemStyle}
        buttonComponent={
          <MoreIcon width={16} height={16} fill={colors.gray48} />
        }
      />
    </div>
  );
};

export default I18nHOC({ resource: "common" })(DropDown);
