/**when to use this component?
 * When you have an array of filters with common searchBar and
 * all filters are inside single dropdown, use this component
 */

/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**--internal -- */
import { ButtonDropdown, FilterSelectionText } from "UIComponents";
import { ArrowIcon } from "SvgComponents";
/**--relative-- */
import { containerStyle } from "./FilterListStyles";
import classes from "./FilterList.scss";
import { getFlattenOptionsMemoized, getFlattenValuesMemoized } from "./utils";
import FilterListDropdown from "./FilterListDropdown";

const ButtonComponent = ({ label, showDropdown, options, values }) => {
  const iconContainerClassList = classNames(
    {
      [classes.iconContainer]: true,
    },
    {
      [classes.activatedIconContainer]: showDropdown,
    }
  );

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.buttonLabel}>{label}:</div>
      <FilterSelectionText options={options} values={values} />

      <div className={iconContainerClassList}>
        <ArrowIcon width={10} height={5} />
      </div>
    </div>
  );
};

const FilterList = props => {
  const {
    optionList,
    getAllOptionLabelForFilter,
    getSearchPlaceholder,
    updateFilterList,
    valueList,
    parentContainerStyle,
    label,
    onUpdateShowDropDown,
    isDisabled,
    buttonDropDownContainerStyle,
  } = props;

  const options = getFlattenOptionsMemoized({ optionList });

  const values = getFlattenValuesMemoized({ valueList });

  const buttonElement = props.buttonElement ? (
    React.cloneElement(props.buttonElement, { label, options, values })
  ) : (
    <ButtonComponent label={label} options={options} values={values} />
  );

  const dropdownElement = (
    <FilterListDropdown
      updateFilterList={updateFilterList}
      valueList={valueList}
      optionList={optionList}
      getAllOptionLabelForFilter={getAllOptionLabelForFilter}
      getSearchPlaceholder={getSearchPlaceholder}
      label={label}
    />
  );

  return (
    <ButtonDropdown
      shouldCloseOnSelfClick={false}
      parentContainerStyle={parentContainerStyle}
      containerStyle={{ ...containerStyle, ...buttonDropDownContainerStyle }}
      buttonComponent={buttonElement}
      dropdownComponent={dropdownElement}
      onUpdateShowDropDown={onUpdateShowDropDown}
      disabled={isDisabled}
    />
  );
};

FilterList.defaultProps = {
  parentContainerStyle: {},
  optionList: [],
  getAllOptionLabelForFilter: () => {},
  getSearchPlaceholder: () => {},
  updateFilterList: () => {},
  valueList: [],
  label: "",
  onUpdateShowDropDown: () => {},
  buttonElement: null,
  isDisabled: false,
  buttonDropDownContainerStyle: {},
};

FilterList.propTypes = {
  parentContainerStyle: PropTypes.object,
  optionList: PropTypes.array,
  getAllOptionLabelForFilter: PropTypes.func,
  getSearchPlaceholder: PropTypes.func,
  updateFilterList: PropTypes.func,
  valueList: PropTypes.array,
  label: PropTypes.string,
  onUpdateShowDropDown: PropTypes.func,
  buttonElement: PropTypes.object,
  isDisabled: PropTypes.bool,
  buttonDropDownContainerStyle: PropTypes.object,
};

export default FilterList;
