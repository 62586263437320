import React from "react";

const AicnMobileSettings = props => {
  return (
    <svg width="45px" height="52px" viewBox="0 0 45 52">
      <g
        id="Subjects"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-30" transform="translate(-27.000000, -24.000000)">
          <rect
            id="Rectangle-Copy-6"
            fillRule="nonzero"
            x="0"
            y="0"
            width="100"
            height="100"
          />
          <g
            id="Group-13-Copy"
            transform="translate(28.000000, 25.000000)"
            stroke="#F75961"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g id="Group">
              <path
                d="M0.2,45.4 L0.2,5.4 C0.2,2.7 2.4,0.4 5.2,0.4 L24.2,0.4 C26.9,0.4 29.2,2.6 29.2,5.4 L29.2,45.4 C29.2,48.1 27,50.4 24.2,50.4 L5.2,50.4 C2.4,50.4 0.2,48.1 0.2,45.4 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <circle
                id="Oval"
                fillOpacity="0.1"
                fill="#F75961"
                fillRule="nonzero"
                cx="14.7"
                cy="45.7"
                r="1.9"
              />
              <path d="M9.8,5 L19.6,5" id="Path" fill="#FFFFFF" />
            </g>
            <g
              id="Group"
              transform="translate(14.000000, 11.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M28.2,11.8 L24.9,11 C24.8,10.6 24.6,10.2 24.4,9.7 L26.1,6.8 C26.4,6.2 26.3,5.5 25.9,5.1 L24.3,3.5 C23.8,3 23.1,3 22.6,3.3 L19.7,5 C19.3,4.8 18.9,4.6 18.5,4.5 L17.7,1.2 C17.5,0.6 17,0.1 16.3,0.1 L14,0.1 C13.3,0.1 12.8,0.5 12.6,1.2 L11.8,4.5 C11.4,4.6 11,4.8 10.5,5 L7.6,3.3 C7,3 6.3,3.1 5.9,3.5 L4.3,5.1 C3.8,5.6 3.8,6.3 4.1,6.8 L5.8,9.7 C5.6,10.1 5.4,10.5 5.3,11 L2,11.8 C1.4,12 0.9,12.5 0.9,13.2 L0.9,15.5 C0.9,16.2 1.3,16.7 2,16.9 L5.3,17.7 C5.4,18.1 5.6,18.5 5.8,18.9 L4.1,21.8 C3.8,22.4 3.9,23.1 4.3,23.5 L5.9,25.1 C6.4,25.6 7.1,25.7 7.6,25.3 L10.5,23.6 C10.9,23.8 11.3,24 11.7,24.1 L12.5,27.4 C12.7,28 13.2,28.5 13.9,28.5 L16.2,28.5 C16.9,28.5 17.4,28 17.6,27.4 L18.4,24.1 C18.8,23.9 19.2,23.8 19.6,23.6 L22.5,25.3 C23.1,25.6 23.8,25.5 24.2,25.1 L25.8,23.5 C26.3,23 26.4,22.3 26,21.8 L24.3,18.9 C24.5,18.5 24.7,18.1 24.8,17.6 L28.1,16.8 C28.7,16.6 29.2,16.1 29.2,15.4 L29.2,13.1 C29.3,12.6 28.8,12 28.2,11.8 Z M15,20 C11.9,20 9.4,17.5 9.4,14.4 C9.4,11.3 11.9,8.8 15,8.8 C18.1,8.8 20.6,11.3 20.6,14.4 C20.6,17.5 18.1,20 15,20 Z"
                id="Shape"
                fillRule="nonzero"
              />
              <circle
                id="Oval"
                strokeWidth="1.00001522"
                transform="translate(15.052540, 14.390680) rotate(-80.781636) translate(-15.052540, -14.390680) "
                cx="15.05254"
                cy="14.39068"
                r="5.60008526"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AicnMobileSettings;
