import gql from "graphql-tag";
import { teacherFragments } from "TeacherSetup/modules/Fragments";
export const editTeacherMutation = gql`
  mutation editTeacher(
    $firstName: String
    $lastName: String
    $id: ID!
    $email: String
    $profileImage: String
    $removedCourses: [ID!]
    $addedCourses: [ID!]
    $role: ORG_USER_ROLES_ENUM
    $designation: ID
    $status: ORG_USER_STATUS_ENUM
    $isArchived: Boolean
    $addedTags: [ID!]
    $removedTags: [ID!]
  ) {
    platform {
      updateUser(
        input: {
          id: $id
          designation: $designation
          firstName: $firstName
          lastName: $lastName
          profileImage: $profileImage
          email: $email
          addedCourses: $addedCourses
          removedCourses: $removedCourses
          status: $status
          role: $role
          isArchived: $isArchived
          addedTags: $addedTags
          removedTags: $removedTags
        }
      ) {
        ...teacherItem
      }
    }
  }
  ${teacherFragments.teacherFeed}
`;

export const createOrganizationInvitedUserMutation = gql`
  mutation createOrganizationInvitedUser($users: [UserInput!]) {
    platform {
      addUsers(input: $users) {
        warning
        response {
          ...teacherItem
        }
      }
    }
  }
  ${teacherFragments.teacherFeed}
`;
