import React from "react";
import classes from "./ModalContainer.scss";
import { InputTextField, TextAreaInput, I18nHOC } from "UIComponents";
import { AttachmentList } from "Attachment";

const textAreaStyles = {
  overflowY: "hidden",
};
class ModalContainer extends React.PureComponent {
  isValid = () => {
    const totalErrors = this.title.isValid();

    return totalErrors;
  };

  render() {
    let {
      resourceObject: {
        id,
        description,
        title,
        type,

        attachments,
      } = {},
      updateLocalResource,
      updateLoadingState,
      setToastMsg,
      updateAttachmentListRef,
      portalType,
      t,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.inputField}>
          <InputTextField
            name="title"
            ref={ref => {
              this.title = ref;
            }}
            value={title}
            label={t("common:name_of_the_with_label", {
              label: t("common:resource_label"),
            })}
            updateInputField={updateLocalResource}
            placeholder={t("common:title")}
          />
        </div>
        <div className={classes.inputField}>
          <AttachmentList
            value={attachments}
            customRef={updateAttachmentListRef}
            label={t("common:attachments")}
            name={"attachments"}
            updateInputField={updateLocalResource}
            updateLoadingState={updateLoadingState}
            setToastMsg={setToastMsg}
            parentType={"RESOURCE"}
            parentId={`${id}`}
            portalType={portalType}
          />
        </div>

        <div className={classes.inputField}>
          <TextAreaInput
            name="description"
            minRows={3}
            textAreaStyles={textAreaStyles}
            placeholder={t("resource_description")}
            label={t("resource_description_placeholder")}
            updateInputField={updateLocalResource}
            value={description}
          />
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(ModalContainer);
