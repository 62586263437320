import React, { PureComponent } from "react";

import classes from "./SharedWith.scss";

import ScreenPanel from "../../ScreenPanel";

import { UIButton, I18nHOC } from "UIComponents";
import SharedWithList from "./SharedWithList";
import { Button } from "@toddle-design/web";

const FooterComponent = React.memo(props => {
  const { onDoneClick, t } = props;
  return (
    <div className={classes.footerButtonContainer}>
      <Button onClick={onDoneClick}>{t("done")}</Button>
    </div>
  );
});

class SharedWith extends PureComponent {
  onDoneClick = () => {
    const {
      screenPanelProps: { updatePost, onCloseClick } = {},
      students,
    } = this.props;

    onCloseClick();
  };

  render() {
    const { screenPanelProps, t } = this.props;

    return (
      <ScreenPanel
        {...screenPanelProps}
        footerComponent={
          <FooterComponent t={t} onDoneClick={this.onDoneClick} />
        }
      >
        <SharedWithList />
      </ScreenPanel>
    );
  }
}

export default I18nHOC({ resource: "common" })(SharedWith);
