import React, { useRef, useState } from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import {
  I18nHOC,
  UIModal,
  UIButton,
  InputTextField,
  Loading,
} from "UIComponents";
import { LeftIcon } from "SvgComponents";
import LinkSentSuccessScreen from "../LinkSentSuccessScreen";
import { emailValidation } from "Utils";
import { sendResourceLink } from "modules/Services";
import classes from "./SendResourceLink.scss";

const SendResourceLink = ({ sendResourceLink, onBackClick, t }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  const emailRef = useRef();

  const updateInputField = data => {
    setEmail(data.email);
  };

  const onSendClick = async () => {
    const isResourceTypeInputValid = !emailRef.current.isValid();

    if (!isResourceTypeInputValid) return;

    setIsSubmitting(true);

    const url = _.get(window, "location.pathname", "");
    const success = await sendResourceLink(email, url);

    if (!success) {
      setIsSubmitting(false);
      return;
    }

    setIsSubmitted(true);
    setIsSubmitting(false);
  };

  const onResendClick = () => {
    setIsSubmitted(false);
    setEmail("");
  };

  const checkEmailVerification = value => {
    const isValidEmail = emailValidation(value);
    if (!isValidEmail) return t("common:invalid_email_error_msg");
    return "";
  };

  return (
    <UIModal
      isOpen={true}
      isFullScreen={true}
      modalContent={classes.modalContent}
    >
      {isSubmitting ? (
        <Loading containerStyle={{ height: "100%" }} />
      ) : (
        <>
          {isSubmitted ? (
            <LinkSentSuccessScreen
              email={email}
              onResendClick={onResendClick}
            />
          ) : (
            <>
              <div onClick={onBackClick} className={classes.backButton}>
                <LeftIcon height={10} width={6} />
                <span>{t("common:back")}</span>
              </div>

              <div className={classes.title}>
                {t("community:send_resource_link")}
              </div>
              <div className={classes.description}>
                {t("community:send_resource_link_desc")}
              </div>

              <div className={classes.formItem}>
                <InputTextField
                  ref={emailRef}
                  name="email"
                  label={t("common:email")}
                  updateInputField={updateInputField}
                  value={email}
                  customValidation={checkEmailVerification}
                  isRequired={true}
                />
              </div>

              <UIButton color="pink" onClick={onSendClick}>
                {t("community:send_resource_link_btn")}
              </UIButton>
            </>
          )}
        </>
      )}
    </UIModal>
  );
};

const mapActionCreators = {
  sendResourceLink,
};

export default compose(
  I18nHOC({ resource: ["common", "community"] }),
  connect(null, mapActionCreators)
)(SendResourceLink);
