import React from "react";

import classes from "./DocumentCategoryHeader.scss";

const DocumentCategoryHeader = props => {
  const { closeButtonElement, t } = props;

  return (
    <div className={classes.header}>
      <div className={classes.info}>
        <div className={classes.title}>
          {t("snp:document_category_tagging_label")}
        </div>
      </div>
      {closeButtonElement}
    </div>
  );
};

export default DocumentCategoryHeader;
