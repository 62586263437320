import PropTypes from "prop-types";
import React from "react";
import classes from "./ChooseComponent.scss";
import { ToddleIcon } from "SvgComponents";
class ChooseComponent extends React.Component {
  setScreen = role => {
    this.props.setScreenWithRole(role);
  };

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.toddleIcon}>
            <ToddleIcon />
          </div>
        </div>

        <div className={classes.title}>Choose Role:</div>

        <div className={classes.tabsContainer}>
          <div
            className={classes.tabs}
            onClick={() => this.setScreen("todden")}
          >
            Teacher
          </div>
          <div
            className={classes.tabs}
            onClick={() => this.setScreen("administrator")}
          >
            Admin
          </div>
        </div>
      </div>
    );
  }
}

export default ChooseComponent;
