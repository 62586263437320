import gql from "graphql-tag";
import {
  staffFragment,
  attachmentFragment,
  userFragment,
  taggedPracticesFragment,
  studentResponseFragment,
} from "modules/CommonFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
export const postEvidenceFragment = {
  postEvidence: gql`
    fragment postItem on Post {
      id
      title
      attachments {
        ...attachmentItem
      }
      state
      elementPYP {
        id
        fieldUID
        value
      }
      resourceItem {
        id
        ... on Assessment {
          id
          assessmentType {
            id
            value
          }
        }
      }
      createdBy {
        ...userItem
      }
      taggedPractices {
        ...taggedPracticesOnEvidence
      }
      items {
        item {
          id
          ...studentResponseItem
        }
        itemType
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${taggedPracticesFragment.taggedPractices}
    ${studentResponseFragment.studentResponse}
  `,
  postEvidenceFilter: gql`
    fragment postItem on Post {
      id
      title
      attachments {
        ...attachmentItem
      }

      createdBy {
        ...userItem
      }
      items {
        item {
          id
          ...studentResponseItem
        }
        itemType
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${studentResponseFragment.studentResponse}
  `,
};

export const unitPlanEvidenceFragment = {
  unitPlanEvidence: gql`
    fragment unitPlanItem on UnitPlan {
      id
      fields(uids: $uids) {
        ...resourceLibraryFieldItem
      }
      posts(fieldUID: $fieldId) {
        ...postItem
      }
    }
    ${postEvidenceFragment.postEvidence}
    ${plannerFieldFragment.resourceLibraryField}
  `,
  unitPlanEvidenceWithFilter: gql`
    fragment unitPlanItem on UnitPlan {
      id
      posts(filters: $filters) {
        ...postItem
      }
    }
    ${postEvidenceFragment.postEvidenceFilter}
  `,
};
