import { injectReducer } from "store/reducers";
import component from "./containers/ChooseContainer";
import Module from "./containers/ChooseModule";
export default store => {
  injectReducer(store, { key: "choose", reducer: Module });
  return {
    path: "choose",
    component,
  };
};
