import React from "react";
import propTypes from "prop-types";
import classes from "./FilterContainer.scss";
import {
  SearchInput,
  Dropdown,
  DropdownMenu,
  ButtonDisclosure,
} from "@toddle-design/web";

import { CURRICULUM_PLANS_OPTIONS } from "Tooling/modules/ToolingUtils";

const FilterContainer = props => {
  const {
    searchText,
    changeSearchTerm,
    toddleBuddies,
    buddyFilterValue,
    planFilterValue,
    updateOrganizationFilters,
    showPlanFilter,
  } = props;

  const selectedBuddy = _.find(
    toddleBuddies,
    ({ id }) => id == buddyFilterValue
  );

  const getToddleBuddyList = () => {
    return [
      { label: "All", key: "" },
      ..._.map(toddleBuddies, ({ id, firstName, lastName }) => ({
        label: `${firstName || ""} ${lastName || ""}`,
        key: id,
      })),
    ];
  };

  const onPlanItemClick = ({ key }) => {
    updateOrganizationFilters({ plan: key });
  };

  const onBuddyItemClick = ({ key }) => {
    updateOrganizationFilters({ toddleBuddy: key });
  };

  const selectedBuddyLabel = _.get(selectedBuddy, "firstName", "") || "All";

  const buddyFilterLabel = `Toddle buddy: ${selectedBuddyLabel}`;

  const selectedPlan = _.find(
    CURRICULUM_PLANS_OPTIONS,
    ({ key }) => key == planFilterValue
  );

  const selectedPlanLabel = _.get(selectedPlan, "label", "") || "All";

  const planFilterLabel = `Plan : ${selectedPlanLabel}`;

  return (
    <div className={classes.header}>
      <div className={classes.leftHeader}>
        <SearchInput
          onChange={changeSearchTerm}
          value={searchText}
          placeholder={`Search by organization name`}
        />
      </div>
      <div className={classes.rightHeader}>
        <Dropdown
          overlay={
            <DropdownMenu
              options={getToddleBuddyList()}
              onClick={onBuddyItemClick}
            />
          }
        >
          <ButtonDisclosure variant="plain">
            {buddyFilterLabel}
          </ButtonDisclosure>
        </Dropdown>

        {showPlanFilter && (
          <Dropdown
            overlay={
              <DropdownMenu
                options={CURRICULUM_PLANS_OPTIONS}
                onClick={onPlanItemClick}
              />
            }
          >
            <ButtonDisclosure variant="plain">
              {planFilterLabel}
            </ButtonDisclosure>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

FilterContainer.defaultProps = {
  showPlanFilter: true,
};

FilterContainer.propTypes = {
  showPlanFilter: propTypes.bool,
};

export default FilterContainer;

FilterContainer.displayName = "FilterContainer";
