import React from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { withRouter } from "react-router";
import { getPermissionStateBasedOnSubscriptionPlan } from "modules/PermissionModule";
import LockedScreenWrapper from "../LockedScreenComponents";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import { getHOCDisplayName } from "Utils";

const FeaturePermissionHandledHOC = ({ key, route, screenType = "screen" }) => {
  return WrappedComponent => {
    class WithFeaturePermissions extends React.PureComponent {
      componentDidMount = () => {
        const { setActiveTabFromChild } = this.props;
        if (setActiveTabFromChild && screenType == "screen") {
          setActiveTabFromChild(route);
        }
      };
      getWrapperComponent = state => {
        const { onClose, overrideLockState } = this.props;

        switch (state) {
          case "ENABLED":
            return <WrappedComponent {...this.props} />;
          case "LOCKED":
            return overrideLockState ? (
              <WrappedComponent {...this.props} />
            ) : (
              <LockedScreenWrapper
                featureKey={key}
                screenType={screenType}
                onClose={onClose}
              ></LockedScreenWrapper>
            );

          default:
            return null;
        }
      };
      render() {
        const { isCurriculumProgramFree } = this.props;
        const state = getPermissionStateBasedOnSubscriptionPlan({
          key,
          isCurriculumProgramFree,
        });
        return this.getWrapperComponent(state);
      }
    }

    const mapStateToProps = (state, ownProps) => {
      const curriculumProgramId = _.get(
        ownProps,
        "params.curriculumProgramId",
        ""
      );

      const tabConfigurations = { admin: { curriculumProgramId } };

      const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
        state,
        tabConfigurations,
      });

      return { isCurriculumProgramFree };
    };

    const WrapperComponent = compose(
      withRouter,
      connect(mapStateToProps)
    )(WithFeaturePermissions);

    WrapperComponent.displayName = `WithFeaturePermissions(${getHOCDisplayName(
      WrapperComponent
    )})`;
    return WrapperComponent;
  };
};

export default FeaturePermissionHandledHOC;
