import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./OrganizationV2.scss";
import moment from "moment";
import {
  I18nHOC,
  DialogueBox,
  withLoader,
  Loading,
  FilterCountBlock,
  BulkUploadErrorModal,
  FullScreenLoader,
  UIModal,
} from "UIComponents";
import { getOrganizationFeedQuery } from "Tooling/modules/ToolingQueries";
import { getOrganizationFeedFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
import EditOrgInfo from "Tooling/routes/Organization/components/EditOrgInfo";
import TopHeader from "./components/TopHeader";
import FilterContainer from "./components/FilterContainer";
import FeedList from "AppComponents/FeedList";
import { goToBack } from "modules/NavigationModule";
import {
  createOrganization,
  updateOrganization,
  bulkUpload,
  changeOrganizationSearchString,
  getOrganizationData,
  deleteOrganization,
  updateConfigurablePermissions,
  updateOrganizationFilters,
  updateOrganizationPaidStatus,
  getDefaultAcademicYears,
  updateCurriculumPrograms,
  getValidAdminsPayload,
} from "Tooling/modules/ToolingModule";
import { styles } from "./OrganizationV2Styles";
import {
  ONBOARDED_SCHOOL_PAGE_COLUMNS,
  ONBOARDING_SCHOOL_PAGE_COLUMNS,
  getCurriculumProgramsGroupedByPlan,
  getCurriculumProgramsGroupedByOnboardingStatus,
  TRANSITION_SCHOOL_PAGE_COLUMNS,
} from "Tooling/modules/ToolingUtils";
import { onSwitchToOrganization, setToastMsg } from "Login/modules/LoginModule";
import { checkScrollAtBottom, exportData } from "Utils";
import {
  OrganizationBasicDetails,
  ToddleBuddiesBasicDetails,
  CurriculumPlanDetails,
  Actions,
  OrganizationStatus,
} from "./components";
import EditOrgSetting from "Tooling/routes/Organization/components/EditOrgSetting/EditOrgSetting";
import ManageOrgFeatures from "Tooling/routes/Organization/components/ManageOrgFeatures";
import UploadDPRoster from "Tooling/routes/Organization/components/UploadDPRoster";
import { withPermission } from "lib/PermissionAware";
import {
  CURRICULUM_TYPE_DP,
  PLAN_TYPE_DEMO,
  PLAN_TYPE_FREE,
  PLAN_TYPE_360,
  PLAN_TYPE_PRO,
  PLAN_TYPE_PRO_PILOT,
  PLAN_TYPE_360_PILOT,
} from "Constants/stringConstants";
import ACLStore from "lib/aclStore";
import { ORGANIZATION_LOGO_PLACEHOLDER_URL } from "Constants";
import { getCurriculumProgramAcronymById } from "Platform/modules/PlatformUtils";
import {
  getOnboardingFilters,
  getTransitionFilters,
  getCurriculumPlanFilters,
  getFiltersMemoized,
} from "./OrganizationV2Utils";
import { ENABLE_DP_ROASTER_SHEET } from "Constants/permissionConstants";
import UpdateOrgAcademicYears from "Tooling/routes/Organization/components/UpdateOrgAcademicYears";

const OrganizationList = compose(withLoader)(FeedList);

const DIALOGS_INFO = {
  DELETE_ORGANIZATION: {
    title: "Delete School",
    message: ({ orgDetail }) => {
      return (
        <div>
          <div style={{ marginBottom: "16px", color: "red" }}>
            {`Are you sure you want to delete the School? This action can't be undone.`}
          </div>
          <div>
            <b>School name: </b>
            {orgDetail.name}
          </div>
          <div>
            <b>School id: </b>
            {orgDetail.id}
          </div>
        </div>
      );
    },
    confirmButtonText: "Delete",
    cancelButtonText: "Cancel",
  },
};

class OrganizationV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: "create",
      isCreate: true,
      modalOpen: false,
      settingModalOpen: false,
      manageOrgFeatureModal: false,
      academicYearModalOpen: false,
      bulkUploadErrorModal: false,
      selectedOrgId: null,
      errors: [],
      isLoading: false,
      currentDialogAction: false,
    };
  }

  openModalCreateModeal = () => {
    this.setState({ modalOpen: true, mode: "create" });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      settingModalOpen: false,
      academicYearModalOpen: false,
      manageOrgFeatureModal: false,
      uploadDPRosterModalOpen: false,
      dpCurriculumProgramId: null,
    });
  };

  onClickEditOrgAcademicYear = id => {
    this.setState({
      academicYearModalOpen: true,
      mode: "editAcademicYear",
      selectedOrgId: id,
    });
  };

  toggleDialogueBoxDisplay = () => {
    this.setState({
      currentDialogAction: false,
    });
  };

  onManageCurriculumPrograms = id => {
    this.setState({
      modalOpen: true,
      mode: "edit",
      selectedOrgId: id,
    });
  };

  onClickEditOrgSetting = id => {
    this.setState({
      settingModalOpen: true,
      mode: "editSetting",
      selectedOrgId: id,
    });
  };

  onClickUploadDPRosterSheet = ({ organizationId, curriculumProgramId }) => {
    this.setState({
      uploadDPRosterModalOpen: true,
      mode: "uploadDPRoster",
      selectedOrgId: organizationId,
      dpCurriculumProgramId: curriculumProgramId,
    });
  };

  onClickDownloadData = async (name, presorg_id) => {
    try {
      const data = await this.props.getOrganizationData(presorg_id);
      exportData({ data, outputFormat: "ZIP", fileName: name });
    } catch (error) {
      console.error(error);
    }
  };

  changeSearchTerm = value => {
    this.props.changeOrganizationSearchString({ organizations: value });
  };

  toggleBulkUploadErrorModal = () => {
    this.setState({
      bulkUploadErrorModal: !this.state.bulkUploadErrorModal,
    });
  };

  onContentUploaded = async ({ data, type, organizationId }) => {
    const { getOrganizationFeed, bulkUpload } = this.props;
    this.setState({
      isLoading: true,
    });
    const { successful, errors } = await bulkUpload({
      url: data.url,
      type,
      organizationId,
    });
    if (successful) {
      await getOrganizationFeed.refetch();
      this.setState({
        isLoading: false,
      });
      // await refetch here;
    } else {
      this.setState({
        errors: errors,
        isLoading: false,
      });
      this.toggleBulkUploadErrorModal();
    }
  };

  onClickDeleteOrg = id => {
    this.setState({
      currentDialogAction: "DELETE_ORGANIZATION",
      selectedOrgId: id,
    });
  };

  onClickManageOrgFeatures = id => {
    this.setState({
      manageOrgFeatureModal: true,
      mode: "manageFeatures",
      selectedOrgId: id,
    });
  };
  getActionMenu = item => {
    const { t } = this.props;

    const isPaid = _.get(item, "isPaid", false);

    const curriculumPrograms = _.get(item, "curriculumPrograms", []);

    const dpCurriculumPrograms = _.filter(
      curriculumPrograms,
      ({ type }) => type === CURRICULUM_TYPE_DP
    );

    const curriculumProgramAcronymById = getCurriculumProgramAcronymById({
      curriculumPrograms,
    });

    const dpRosterSheetOptions = _.map(dpCurriculumPrograms, ({ id }) => {
      return {
        key: `DP_ROASTER-${id}`,
        label: t("common:upload_with_label", {
          label: t("onBoarding:roster_sheet_with_label", {
            label: curriculumProgramAcronymById[id],
          }),
        }),
      };
    });

    const commonOptions = [
      {
        key: "MANAGE_CURRICULUM_PROGRAMS",
        label: t("onBoarding:manage_curriculum_programs"),
      },
      {
        key: "MANAGE_ACADEMIC_YEARS",
        label: "Manage academic years",
      },
      {
        key: "DOWNLOAD_ORGANIZATION_DATA",
        label: t("common:download_with_lowercase_label", {
          label: t("onBoarding:school_data"),
        }),
      },
      {
        key: "DELETE_ORGANIZATION",
        label: t("common:delete_with_label", { label: t("common:school") }),
      },
      {
        key: "UPDATE_PAID_STATUS",
        label: t("common:mark_as_label", {
          label: isPaid ? t("onBoarding:unpaid") : t("onBoarding:paid"),
        }),
      },
    ];

    const isDPUploadRosterAllowed = ACLStore.can(ENABLE_DP_ROASTER_SHEET);

    return isDPUploadRosterAllowed
      ? [...commonOptions, ...dpRosterSheetOptions]
      : commonOptions;
  };

  onActionMenuItemClick = ({ key, organization }) => {
    const { updateOrganizationPaidStatus } = this.props;
    switch (key) {
      case "MANAGE_CURRICULUM_PROGRAMS": {
        this.onManageCurriculumPrograms(organization.id);
        break;
      }
      case "MANAGE_ACADEMIC_YEARS": {
        this.onClickEditOrgAcademicYear(organization.id);
        break;
      }
      case "DOWNLOAD_ORGANIZATION_DATA": {
        this.onClickDownloadData(organization.name, organization.id);
        break;
      }
      case "DELETE_ORGANIZATION": {
        this.onClickDeleteOrg(organization.id);
        break;
      }

      case "UPDATE_PAID_STATUS": {
        updateOrganizationPaidStatus({
          organizationId: organization.id,
          isPaid: !organization.isPaid,
        });
        break;
      }

      default: {
        if (_.startsWith(key, "DP_ROASTER")) {
          const dpCurriculumProgramId = key.split("-")[1];
          this.onClickUploadDPRosterSheet({
            organizationId: organization.id,
            curriculumProgramId: dpCurriculumProgramId,
          });
        }
      }
    }
  };

  onUploadDPRosterSaveClick = async param => {
    const curriculumProgramId = _.get(
      this.state,
      "dpCurriculumProgramId",
      null
    );

    const sheets = _.get(param, "sheets", []);

    for (const sheet of sheets) {
      sheet.url &&
        (await this.props.bulkUpload({
          url: sheet.url,
          type: sheet.type,
          organizationId: param.orgDetail.id,
          inputs: {
            curriculumProgramId,
          },
        }));
    }
    this.closeModal();
  };

  getFeedDataV2 = () => {
    const { organizations, activeTab } = this.props;

    return _.map(organizations, ({ node: organization }) => {
      const { id, name, logo, curriculumPrograms } = organization;

      const commonFeedData = {
        SCHOOL_DETAILS: {
          id,
          name,
          profileImage: _.isEmpty(logo)
            ? ORGANIZATION_LOGO_PLACEHOLDER_URL
            : logo,
        },
        TODDLE_BUDDIES: { curriculumPrograms },
        ACTIONS: {
          tabs: this.getActionMenu(organization),
          onActionMenuItemClick: ({ key }) =>
            this.onActionMenuItemClick({ key, organization }),
          onViewClick: () => this.onItemClick(organization.id),
        },
      };

      switch (activeTab) {
        case "ONBOARDED": {
          const groupedCurriculumPrograms = getCurriculumProgramsGroupedByPlan({
            curriculumPrograms,
          });

          return {
            ...commonFeedData,
            ...groupedCurriculumPrograms,
          };
        }
        case "ONBOARDING": {
          const groupedCurriculumPrograms = getCurriculumProgramsGroupedByOnboardingStatus(
            { curriculumPrograms }
          );

          return {
            ...commonFeedData,
            SETUP_COMPLETE: groupedCurriculumPrograms["COMPLETE"],
            NOT_STARTED: groupedCurriculumPrograms["NOT_STARTED"],
            IN_PROGRESS: groupedCurriculumPrograms["IN_PROGRESS"],
            QC_INITIATED: groupedCurriculumPrograms["QC_INITIATED"],
          };
        }
        case "TRANSITION": {
          return {
            ...commonFeedData,
            PROGRAMS: curriculumPrograms,
            STATUS: _.get(organization, "academicYears", []),
          };
        }
      }
    });
  };

  getCustomColumnElement = ({ column, columnData }) => {
    switch (column.id) {
      case "SCHOOL_DETAILS": {
        return <OrganizationBasicDetails {...columnData} />;
      }
      case "TODDLE_BUDDIES": {
        return (
          <ToddleBuddiesBasicDetails
            curriculumPrograms={columnData.curriculumPrograms}
          />
        );
      }
      case PLAN_TYPE_DEMO:
      case "PROGRAMS":
      case PLAN_TYPE_FREE:
      case PLAN_TYPE_PRO:
      case PLAN_TYPE_PRO_PILOT:
      case PLAN_TYPE_360_PILOT:
      case PLAN_TYPE_360: {
        return <CurriculumPlanDetails curriculumPrograms={columnData} />;
      }
      case "ACTIONS": {
        return (
          <Actions
            tabs={columnData.tabs}
            onTabClick={columnData.onActionMenuItemClick}
            onViewClick={columnData.onViewClick}
          />
        );
      }
      case "SETUP_COMPLETE":
      case "NOT_STARTED":
      case "IN_PROGRESS":
      case "QC_INITIATED":
      case "IN_30_7":
      case "IN_7_0":
      case "OVERDUE": {
        return (
          <CurriculumPlanDetails
            curriculumPrograms={columnData}
            showPlanNameInTooltip
          />
        );
      }
      case "STATUS": {
        return <OrganizationStatus academicYears={columnData} />;
      }

      default: {
        return <div>{column.id}</div>;
      }
    }
  };

  getFeedListParentStyle = () => {
    const { activeTab } = this.props;
    switch (activeTab) {
      case "ONBOARDED": {
        return styles.onBoardedFeedListStyles.parentStyle;
      }
      case "ONBOARDING": {
        return styles.onBoardingFeedListStyles.parentStyle;
      }
      case "TRANSITION": {
        return styles.transitionFeedListStyles.parentStyle;
      }
    }
  };

  getDpExamSessionMonth = ({ curriculumPrograms }) => {
    const dpCurriculumPrograms = _.find(
      curriculumPrograms,
      ({ type }) => type === CURRICULUM_TYPE_DP
    );

    return _.get(dpCurriculumPrograms, "examSessionMonth", null);
  };

  getAcademicYearsPayload = ({ academicYears }) => {
    return _.map(academicYears, academicYear => {
      return {
        startDate: moment(academicYear?.dateDurationValue?.startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(academicYear?.dateDurationValue?.endDate).format(
          "YYYY-MM-DD"
        ),
      };
    });
  };

  getCurriculumOptionsPayload = ({
    curriculumPrograms,
    shouldIncludeId = false,
  }) => {
    return _.map(curriculumPrograms, curriculumProgram => {
      const {
        type,
        acronym,
        rosteringMethod,
        toddleBuddy,
        plan,
        grades,
        name,
        id,
      } = curriculumProgram;

      return {
        ...(shouldIncludeId ? { id } : {}),
        curriculumType: type,
        rosteringMethod,
        toddleBuddyId: toddleBuddy,
        planId: plan,
        gradesToBeIncluded: grades,
        label: name,
        acronym,
      };
    });
  };

  getSchedularPayload = ({
    schedulerStartTime,
    schedulerEndTime,
    schedulerWeekLength,
  }) => {
    return {
      schedulerStartTime: moment(schedulerStartTime).format("HH:mm"),
      schedulerEndTime: moment(schedulerEndTime).format("HH:mm"),
      schedulerWeekLength: parseInt(schedulerWeekLength),
    };
  };

  createOrganization = async orgDetails => {
    const { getOrganizationFeed } = this.props;

    const {
      schedulerStartTime,
      schedulerEndTime,
      schedulerWeekLength,
      curriculumPrograms,
      attachment,
      admins,
    } = orgDetails;

    const examSessionMonth = this.getDpExamSessionMonth({
      curriculumPrograms,
    });

    const validAdmins = getValidAdminsPayload({ admins });

    const academicYears = this.getAcademicYearsPayload({
      academicYears: orgDetails["academicYears"],
    });

    const { timezone } = orgDetails;

    const timezoneWrapper = timezone
      ? [
          {
            name: "TIMEZONE",
            value: timezone,
          },
        ]
      : [];

    let updatedOrgDetails = {
      ...orgDetails,
      academicYears,
      admins: validAdmins,
      attachments: attachment ? [attachment] : [],
      examSessionMonth,
      curriculumOptions: this.getCurriculumOptionsPayload({
        curriculumPrograms,
      }),
      ...this.getSchedularPayload({
        schedulerStartTime,
        schedulerEndTime,
        schedulerWeekLength,
      }),
      organizationConstants: [
        {
          name: "OPERATIONAL_DAYS",
          value: JSON.stringify(orgDetails["operationalDays"]),
        },
        ...timezoneWrapper,
      ],
    };

    updatedOrgDetails = _.omit(updatedOrgDetails, [
      "operationalDays",
      "logoAttachment",
      "curriculumPrograms",
      "toddleBuddy",
    ]);

    try {
      this.setState({ isLoading: true });
      await this.props.createOrganization(updatedOrgDetails);

      await getOrganizationFeed.refetch();
      this.closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  updateCurriculumPrograms = async orgDetails => {
    const { updateCurriculumPrograms, getOrganizationFeed } = this.props;

    const { id, curriculumPrograms } = orgDetails;

    const curriculumOptions = this.getCurriculumOptionsPayload({
      curriculumPrograms,
      shouldIncludeId: true,
    });

    const examSessionMonth = this.getDpExamSessionMonth({
      curriculumPrograms,
    });

    this.setState({ isLoading: true });

    try {
      await updateCurriculumPrograms({
        id,
        curriculumOptions,
        examSessionMonth,
      });
      await getOrganizationFeed.refetch();
      this.closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onClickOperation = async orgDetails => {
    const { mode } = this.state;

    if (mode == "create") {
      this.createOrganization(orgDetails);
    } else {
      this.updateCurriculumPrograms(orgDetails);
    }
  };

  isSchoolAlreadyHaveMypUbd = id => {
    const { organizations } = this.props;
    const orgDetails = _.get(
      _.find(organizations, ({ node }) => {
        return node.id === id;
      }),
      "node",
      {}
    );
    const curriculumTypes = ["IB_MYP", "UBD"];
    const curriculumPrograms = _.get(orgDetails, "curriculumPrograms", []);

    const filterCurriculumPrograms = _.filter(
      curriculumPrograms,
      curriculumProgram =>
        _.some(
          curriculumTypes,
          curriculumType => curriculumProgram.type === curriculumType
        )
    );
    return _.size(filterCurriculumPrograms) === 0 ? false : true;
  };

  getOrgDetail = () => {
    const { organizations } = this.props;
    const { selectedOrgId } = this.state;
    const orgDetails = _.get(
      _.find(organizations, ({ node }) => {
        return node.id === selectedOrgId;
      }),
      "node",
      {}
    );

    const curriculumPrograms = _.get(orgDetails, "curriculumPrograms", []);
    const curriculumOptions = _.map(
      curriculumPrograms,
      ({ buddy, subscriptionPlan, type, label, acronym, id }) => ({
        curriculumType: type,
        planId: _.get(subscriptionPlan, "id", ""),
        toddleBuddyId: _.get(buddy, "id", ""),
        gradesToBeIncluded: [],
        id,
        label,
        acronym,
      })
    );

    const rosteringMethod = _.get(
      _.head(curriculumPrograms),
      "rosteringMethod",
      ""
    );

    return {
      ..._.pick(orgDetails, ["id", "name", "logo", "code", "toddleBuddy"]),
      planId: _.get(orgDetails, "tier.id"),
      additionalData: _.get(orgDetails, "constants.additionalData.note"),
      academicYears: _.get(orgDetails, "academicYears", []),
      schedulerStartTime: moment(
        _.get(orgDetails, "constants.scheduler.start_time_for_slot"),
        "HH:mm"
      ),
      schedulerEndTime: moment(
        _.get(orgDetails, "constants.scheduler.end_time_for_slot"),
        "HH:mm"
      ),
      schedulerStartDay: _.get(
        orgDetails,
        "constants.scheduler.week_start_day"
      ),
      locale: _.get(
        _.find(_.get(orgDetails, "settings", []), { name: "locale" }),
        "value",
        ""
      ),
      schedulerWeekLength: _.get(orgDetails, "constants.scheduler.week_length"),
      curriculumOptions,

      rosteringMethod,

      id: _.get(orgDetails, "id", ""),
      examSessionMonth: _.get(orgDetails, "constants.examSessionMonth", null),
    };
  };

  getBuddies = () => {
    const { organizations, getOrganizationFeed } = this.props;
    return _.get(getOrganizationFeed, "platform.toddleBuddies", []);
  };

  onScroll = e => {
    const { modalOpen } = this.state;

    const { hasNextPage, networkStatus, loadMoreResult } = this.props;
    const containerDiv = e.target;

    if (
      !modalOpen &&
      containerDiv &&
      hasNextPage &&
      networkStatus != 3 &&
      checkScrollAtBottom(containerDiv)
    ) {
      loadMoreResult();
    }
  };

  onItemClick = id => {
    const { onSwitchToOrganization } = this.props;
    onSwitchToOrganization({ organizationId: id });
  };

  getCreateOrgDetails = () => {
    const orgDetails = {
      academicYears: getDefaultAcademicYears(),
      schedulerStartTime: moment("08:00", "HH:mm"),
      schedulerEndTime: moment("15:00", "H:mm"),
      schedulerStartDay: 1,
      locale: "en",
      schedulerWeekLength: 5,
      rosteringMethod: "",
      operationalDays: [1, 2, 3, 4, 5],
      examSessionMonth: null,
    };

    return orgDetails;
  };

  onDialogConfirmClick = async () => {
    const { currentDialogAction } = this.state;
    this.setState({ isLoading: true });
    if (currentDialogAction === "DELETE_ORGANIZATION") {
      await this.props.deleteOrganization({ id: this.state.selectedOrgId });
    }
    this.setState({ isLoading: false });
  };

  getToddleBuddies = () => {
    const { getOrganizationFeed } = this.props;
    return _.get(getOrganizationFeed, "platform.buddies", []);
  };

  onManageFeaturesSaveClick = async param => {
    const { getOrganizationFeed } = this.props;

    this.setState({ isLoading: true });
    await this.props.updateConfigurablePermissions({
      id: this.state.selectedOrgId,
      permissions: param,
    });
    await getOrganizationFeed.refetch();
    this.setState({ isLoading: false });
    this.closeModal();
  };

  onFilterClick = value => {
    const { updateOrganizationFilters, activeTab } = this.props;
    switch (activeTab) {
      case "ONBOARDING": {
        updateOrganizationFilters({ onboardingFilter: value });
        break;
      }
      case "TRANSITION": {
        updateOrganizationFilters({ transitionFilter: value });
        break;
      }
      case "ONBOARDED": {
        updateOrganizationFilters({ plan: value });
      }
    }
  };

  getFiltersBlockProps = () => {
    const {
      activeTab,
      feedAnalytics,
      onboardingAnalytics,
      transitionAnalytics,
      onboardingFilter,
      transitionFilter,
      planFilterValue,
      subscriptionPlanAnalytics,
    } = this.props;
    switch (activeTab) {
      case "ONBOARDING":
        return {
          filters: getOnboardingFilters(onboardingAnalytics, feedAnalytics),
          activeFilter: onboardingFilter,
        };
      case "TRANSITION":
        return {
          filters: getTransitionFilters(transitionAnalytics, feedAnalytics),
          activeFilter: transitionFilter,
        };
      case "ONBOARDED": {
        return {
          filters: getCurriculumPlanFilters(
            subscriptionPlanAnalytics,
            feedAnalytics
          ),
          activeFilter: planFilterValue,
        };
      }
      default:
        return {};
    }
  };

  getColumnList = () => {
    const { activeTab } = this.props;
    switch (activeTab) {
      case "ONBOARDED": {
        return ONBOARDED_SCHOOL_PAGE_COLUMNS;
      }
      case "ONBOARDING": {
        return ONBOARDING_SCHOOL_PAGE_COLUMNS;
      }
      case "TRANSITION": {
        return TRANSITION_SCHOOL_PAGE_COLUMNS;
      }
    }
  };

  render() {
    const {
      isData,
      isLoading,
      networkStatus,
      activeTab,
      updateOrganizationFilters,
      buddyFilterValue,
      planFilterValue,
      feedAnalytics,
    } = this.props;
    const {
      mode,
      modalOpen,
      settingModalOpen,
      academicYearModalOpen,
      currentDialogAction,
      bulkUploadErrorModal,
      manageOrgFeatureModal,
      errors,
      isLoading: isStateLoading,
      uploadDPRosterModalOpen,
    } = this.state;

    return (
      <div className={classes.container} onScroll={e => this.onScroll(e)}>
        <TopHeader
          goToBack={this.props.goToBack}
          onClickAdd={this.openModalCreateModeal}
          activeTab={activeTab}
          updateOrganizationFilters={updateOrganizationFilters}
          feedAnalytics={feedAnalytics}
        />

        <FilterCountBlock
          onFilterClick={this.onFilterClick}
          {...this.getFiltersBlockProps()}
          activeCountStyle={styles.countStyle}
          activeLabelStyle={styles.labelStyle}
        />

        <div className={classes.innerContainer}>
          <FilterContainer
            searchText={this.props.searchText}
            changeSearchTerm={this.changeSearchTerm}
            toddleBuddies={this.getToddleBuddies()}
            planFilterValue={planFilterValue}
            buddyFilterValue={buddyFilterValue}
            updateOrganizationFilters={updateOrganizationFilters}
            showPlanFilter={activeTab !== "ONBOARDED"}
          />

          <OrganizationList
            columnList={this.getColumnList()}
            parentStyle={this.getFeedListParentStyle()}
            feedData={this.getFeedDataV2()}
            fetchMoreFeed={this.fetchMoreFeed}
            isData={isData}
            getCustomComponent={this.getCustomColumnElement}
            isLoading={isLoading}
          />
        </div>
        {modalOpen ? (
          <UIModal isOpen isFullScreen>
            <EditOrgInfo
              bulkUpload={this.props.bulkUpload}
              closeModal={this.closeModal}
              mode={mode}
              onClickOperation={this.onClickOperation}
              organizationDetails={
                mode == "create"
                  ? this.getCreateOrgDetails()
                  : this.getOrgDetail()
              }
              toddleBuddies={this.getToddleBuddies()}
            />
          </UIModal>
        ) : null}

        {settingModalOpen ? (
          <EditOrgSetting
            bulkUpload={this.props.bulkUpload}
            closeModal={this.closeModal}
            mode={mode}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {manageOrgFeatureModal ? (
          <ManageOrgFeatures
            closeModal={this.closeModal}
            mode={mode}
            onClickOperation={this.onManageFeaturesSaveClick}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {academicYearModalOpen ? (
          <UpdateOrgAcademicYears
            closeModal={this.closeModal}
            mode={mode}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {currentDialogAction ? (
          <DialogueBox
            modalTitle={DIALOGS_INFO[currentDialogAction].title}
            onClickButton2={this.onDialogConfirmClick}
            modalBody={DIALOGS_INFO[currentDialogAction].message({
              orgDetail: this.getOrgDetail(),
            })}
            toggleDialogueBoxDisplay={this.toggleDialogueBoxDisplay}
            button1={DIALOGS_INFO[currentDialogAction].cancelButtonText}
            button2={DIALOGS_INFO[currentDialogAction].confirmButtonText}
          />
        ) : null}

        {bulkUploadErrorModal ? (
          <BulkUploadErrorModal
            toggleBulkUploadErrorModal={this.toggleBulkUploadErrorModal}
            errors={errors}
          />
        ) : null}

        {uploadDPRosterModalOpen && (
          <UploadDPRoster
            closeModal={this.closeModal}
            mode={mode}
            onClickOperation={this.onUploadDPRosterSaveClick}
            orgDetail={this.getOrgDetail()}
          />
        )}

        {networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        {isStateLoading && <FullScreenLoader></FullScreenLoader>}
      </div>
    );
  }
}

const mapActionCreators = {
  createOrganization,
  updateOrganization,
  goToBack,
  bulkUpload,
  changeOrganizationSearchString,
  onSwitchToOrganization,
  getOrganizationData,
  deleteOrganization,
  updateConfigurablePermissions,
  updateOrganizationFilters,
  updateOrganizationPaidStatus,
  setToastMsg,
  updateCurriculumPrograms,
};

const mapStateToProps = (state, ownProps) => {
  const {
    tab,
    toddleBuddy,
    plan,
    onboardingFilter,
    transitionFilter,
  } = state.tooling.organizationFilters;

  const searchText = state.tooling.searchString.organizations;
  const filters = getFiltersMemoized({
    tab,
    toddleBuddy,
    plan,
    searchText,
    onboardingFilter,
    transitionFilter,
  });
  return {
    isData: true,
    isLoading: false,
    mode: ownProps.mode,
    searchText,
    activeTab: tab,
    buddyFilterValue: toddleBuddy,
    planFilterValue: plan,
    filters,
    onboardingFilter,
    transitionFilter,
    perm: "ToolPortal:ManageOrganizationRostering",
  };
};

// export default Organization;
export default compose(
  I18nHOC({ resource: ["toastMsgs", "onBoarding", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationFeedQuery, {
    name: "getOrganizationFeed",
    options: ({ filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: { filters },
    }),
    props({ getOrganizationFeed, ownProps: { isData, isLoading, filters } }) {
      const organizationDetails = getOrganizationFeedFromCache({ filters });
      const organizations = _.get(organizationDetails, "edges", []);
      const totalCount = _.get(organizationDetails, "totalCount", 0);
      const feedAnalytics = _.get(organizationDetails, "feedAnalytics", {});
      const onboardingAnalytics = _.get(
        organizationDetails,
        "onboardingAnalytics",
        {}
      );
      const transitionAnalytics = _.get(
        organizationDetails,
        "transitionAnalytics",
        {}
      );
      const subscriptionPlanAnalytics = _.get(
        organizationDetails,
        "subscriptionPlanAnalytics",
        {}
      );
      return {
        totalCount,
        feedAnalytics,
        onboardingAnalytics,
        transitionAnalytics,
        subscriptionPlanAnalytics,
        organizations,
        getOrganizationFeed,
        hasNextPage: _.get(organizationDetails, "pageInfo.hasNextPage", false),
        networkStatus: getOrganizationFeed["networkStatus"],
        isData: !_.isEmpty(organizationDetails) && isData,
        isLoading:
          getOrganizationFeed["networkStatus"] == 1 ||
          getOrganizationFeed["networkStatus"] == 2 ||
          isLoading,
        loadMoreResult: () => {
          return getOrganizationFeed.fetchMore({
            query: getOrganizationFeedQuery,
            variables: {
              after: organizationDetails.pageInfo.endCursor,
              filters,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousFeed = previousResult.platform.organizationFeed;
              const nextFeed = fetchMoreResult.platform.organizationFeed;

              const previousEdges = previousFeed.edges;
              const nextEdges = nextFeed.edges;

              const previousPageInfo = previousFeed.pageInfo;
              const nextPageInfo = nextFeed.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;
              return {
                ...previousResult,
                platform: {
                  ...previousResult.platform,
                  organizationFeed: {
                    ...previousResult.platform.organizationFeed,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withPermission
)(OrganizationV2);
