import PropTypes from "prop-types";
import React from "react";

const Formative = ({ fill, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5626 7.08333H33.4376C34.7147 7.08333 35.7501 8.11867 35.7501 9.39583C35.7501 10.673 34.7147 11.7083 33.4376 11.7083H19.5626C18.2854 11.7083 17.2501 10.673 17.2501 9.39583C17.2501 8.11867 18.2854 7.08333 19.5626 7.08333ZM14.6859 7.08347C15.5519 5.26042 17.41 4 19.5626 4H33.4376C35.5901 4 37.4482 5.26042 38.3142 7.08347H38.8333C42.2391 7.08347 45 9.84438 45 13.2501V37.9168C45 43.0255 40.8586 47.1668 35.75 47.1668H17.25C12.1414 47.1668 8 43.0254 8 37.9168V13.2501C8 9.84438 10.7609 7.08347 14.1667 7.08347H14.6859ZM14.1759 9.71223C14.3396 12.545 16.6887 14.7918 19.5626 14.7918H33.4376C35.9957 14.7918 38.138 13.0117 38.6933 10.6226C38.1379 13.0116 35.9956 14.7917 33.4376 14.7917H19.5626C16.6887 14.7917 14.3397 12.545 14.1759 9.71223ZM38.8334 9.39583C38.8334 9.30271 38.831 9.21013 38.8264 9.11816L38.8334 9.39583ZM18.8761 29.338H23.8351C24.8421 29.338 24.9751 28.635 24.9751 28.331C24.9751 28.027 24.8421 27.324 23.8351 27.324H18.8761V23.638H24.1581C25.1841 23.638 25.3361 22.916 25.3361 22.593C25.3361 22.27 25.1841 21.548 24.1581 21.548H17.9071C16.9381 21.548 16.4821 22.004 16.4821 22.973V33.822C16.4821 34.962 17.2801 35.171 17.6791 35.171C18.0781 35.171 18.8761 34.962 18.8761 33.822V29.338ZM37.8221 35.171C37.4231 35.171 36.9292 35.019 36.6061 34.24L35.6371 31.922H29.4621L28.4931 34.259C28.1891 35.019 27.6951 35.171 27.2961 35.171C26.6501 35.171 26.0991 34.734 26.0991 33.993C26.0991 33.803 26.1371 33.594 26.2701 33.29L31.1151 22.517C31.5711 21.51 32.1411 21.377 32.5971 21.377C33.0531 21.377 33.6421 21.51 34.0982 22.517L38.9051 33.252C39.0381 33.556 39.0571 33.784 39.0571 33.936C39.0571 34.715 38.4682 35.171 37.8221 35.171ZM34.8011 29.87L32.5781 24.474L30.3171 29.87H34.8011Z"
        fill={fill}
      />
    </svg>
  );
};

Formative.defaultProps = {
  fill: "#18605A",
  width: "24",
  height: "24",
};

Formative.propTypes = {
  fill: PropTypes.string,
};

export default Formative;
