const CAN_SHOW_STATES = ["ENABLED", "LOCKED"];

const LOCK_STATES = ["LOCKED"];
class ACLStore {
  constructor() {
    if (!ACLStore.instance) {
      //  console.log('only once');
      this.userPermissions = {};
      ACLStore.instance = this;
    }
    return ACLStore.instance;
  }

  can = (perms, status = "one") => {
    if (_.isArray(perms)) {
      let isCan = false;
      _.forEach(perms, perm => {
        if (_.includes(CAN_SHOW_STATES, this.userPermissions[perm])) {
          isCan = true;
        }
        if (status == "one" && isCan) {
          return true;
        }
      });
      return isCan;
    } else {
      return _.includes(CAN_SHOW_STATES, this.userPermissions[perms]);
    }
  };

  canAll = perms => {
    if (_.isArray(perms)) {
      return _.every(perms, perm =>
        _.includes(CAN_SHOW_STATES, this.userPermissions[perm])
      );
    } else {
      return _.includes(CAN_SHOW_STATES, this.userPermissions[perms]);
    }
  };

  isLocked = perms => {
    if (_.isArray(perms)) {
      let isLocked = true;
      _.forEach(perms, perm => {
        isLocked =
          isLocked && _.includes(LOCK_STATES, this.userPermissions[perm]);
      });
      return isLocked;
    } else {
      return _.includes(LOCK_STATES, this.userPermissions[perms]);
    }
  };

  getPermissionState = perm => {
    const isCan = this.can(perm);
    const isLocked = this.isLocked(perm);
    if (isCan) {
      if (isLocked) {
        return "LOCKED";
      } else {
        return "ENABLED";
      }
    } else {
      return "DISABLED";
    }
  };

  get = () => {
    return this.userPermissions;
  };

  getPermissionsArray = () => {
    return _.reduce(
      this.userPermissions,
      (results, value, key) => {
        if (_.includes(CAN_SHOW_STATES, value)) {
          results.push(key);
        }
        return results;
      },
      []
    );
  };

  clearAclStore = compId => {
    Object.assign(this.userPermissions, {});
  };

  update = userPermissions => {
    Object.assign(this.userPermissions, userPermissions);
  };
}

const instance = new ACLStore();
Object.freeze(instance);

export default instance;
