import React, { useState, useEffect, memo } from "react";
import classes from "./APIInputDetails.scss";
import { FormTextInput } from "UIComponents";
import classNames from "classnames";
import { Button } from "@toddle-design/web";
import { WarningTriangleFilled } from "@toddle-design/web-icons";

const APIInputDetails = props => {
  const {
    setShowEditDialog,
    subModule,
    t,
    apiKey,
    apiUrl,
    onUpdateSetting,
    turnItInSetting,
    isSubmoduleTextLocalized,
    setIsLoading,
    setToastMsg,
    action,
    containerStyle,
    modalBodyStyle,
  } = props;

  const getErrorComponent = () => {
    return (
      <div className={classes.errorContainer}>
        <WarningTriangleFilled size={"xx-small"} variant={"critical"} />
        <div className={classes.errorText}>
          {t("configuration:wrong_turnitin_api_url_or_api_key_text")}
        </div>
      </div>
    );
  };

  const [turnitinDetails, setTurnitinDetails] = useState({});
  const [showError, setShowError] = useState(false);
  const customRef = {};

  useEffect(() => {
    setTurnitinDetails({
      localApiKey: apiKey,
      localApiUrl: apiUrl,
    });
  }, []);

  const onClickSave = async () => {
    if (isValid() > 0) return;
    setIsLoading(true);
    setShowError(false);
    const response = await onUpdateSetting({
      setting: turnItInSetting,
      value: {
        api_key: _.get(turnitinDetails, "localApiKey", null),
        api_url: _.get(turnitinDetails, "localApiUrl", null),
      },
      optimisticUpdate: false,
      refetchConfiguration: true,
    });
    setIsLoading(false);
    if (response) {
      setToastMsg({
        msg: "configuration:successful_turnitin_integration_text",
        type: "success",
      });
      if (action == "edit") onRequestClose();
    } else setShowError(true);
  };

  const onRequestClose = () => {
    setShowEditDialog(false);
  };

  const updateTurnitinDetails = ({ key, value }) => {
    setTurnitinDetails({ ...turnitinDetails, [key]: value });
  };

  const isValid = () => {
    const refKeys = Object.keys(customRef);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (customRef[key]) {
        errorCount += customRef[key].isValid();
      }
    });

    return errorCount;
  };

  const customValidation = value => {
    const turnitinURLRegex = /^(https:\/\/)/;

    if (!turnitinURLRegex.test(value)) {
      return t("configuration:please_enter_valid_api_url");
    } else {
      return "";
    }
  };

  const label = _.get(subModule, "label", "");
  const subText = _.get(subModule, "subText", null);

  const modalFooterClass = classNames({
    [classes.modalFooter]: true,
    [classes.errorModalFooter]: showError,
    [classes.integrationModalFooter]: action == "create",
    [classes.noPadding]: action == "create" && apiUrl && apiKey,
  });

  const { localApiUrl, localApiKey } = turnitinDetails;

  return (
    <>
      <div className={classes.container} style={containerStyle}>
        {action == "edit" && (
          <div className={classes.modalHeaderContainer}>
            <div className={classes.modalHeader}>
              {isSubmoduleTextLocalized ? t(`configuration:${label}`) : label}
            </div>
            {subText && (
              <div className={classes.modalSubtext}>
                {isSubmoduleTextLocalized
                  ? t(`configuration:${subText}`)
                  : subText}
              </div>
            )}
          </div>
        )}
        <div className={classes.modalBody} style={modalBodyStyle}>
          <FormTextInput
            ref={ref => (customRef["apiUrl"] = ref)}
            label={t("configuration:turnitin_api_url")}
            value={localApiUrl}
            updateInputField={value =>
              updateTurnitinDetails({ key: "localApiUrl", value })
            }
            disabled={action == "create" && apiUrl ? true : false}
            placeholder={t("configuration:enter_with_label", {
              label: t("configuration:turnitin_api_url"),
            })}
            error={t("configuration:please_enter_valid_api_url")}
            customValidation={customValidation}
          />
          <FormTextInput
            ref={ref => (customRef["apiKey"] = ref)}
            label={t("configuration:api_key")}
            value={localApiKey}
            updateInputField={value =>
              updateTurnitinDetails({ key: "localApiKey", value })
            }
            disabled={action == "create" && apiKey ? true : false}
            placeholder={t("configuration:enter_with_label", {
              label: t("configuration:api_key"),
            })}
            error={t("configuration:please_enter_valid_api_key")}
          />
          {showError && getErrorComponent()}
        </div>
        <div className={modalFooterClass}>
          {action == "create" && !(apiUrl || apiKey) && (
            <div className={classes.integrateButton}>
              <Button size="medium" variant="primary" onClick={onClickSave}>
                {t("common:integrate_with_label", {
                  label: t("common:now"),
                })}
              </Button>
            </div>
          )}
          {action == "edit" && (
            <>
              <Button
                size={"medium"}
                variant={"dismissive"}
                onClick={onRequestClose}
              >
                {t("common:cancel")}
              </Button>
              <Button size={"medium"} variant={"primary"} onClick={onClickSave}>
                {t("common:save")}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const APIInputDetailsMemoised = memo(APIInputDetails);

export default APIInputDetailsMemoised;
