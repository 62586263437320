import React, { forwardRef, useImperativeHandle, useState } from "react";
import { RadialProgressBar, UIButton, I18nHOC } from "UIComponents";
import withRecorder from "../WithRecorder";
import { VoiceIcon, TickPlain, CancelIcon, PauseIconV2 } from "SvgComponents";
import { colors } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import PropTypes from "prop-types";
/**
 * updateAudioRecorderBlob will be used to save recording blob in redux as draft
 */
import { updateAudioRecorderBlob } from "modules/Services";
import { setToastMsg } from "Login/modules/LoginModule";

import classes from "./AudioRecorderV2.scss";
import classNames from "classnames";
import { Alert } from "@toddle-design/web";

const AudioRecorderV2 = forwardRef(
  (
    {
      t,
      recordButtonColor,
      styles: {
        control: controlStyles = {},
        buttonStyles = {},
        buttonsContainerStyles = {},
      } = {},
      innerContainerStyle,
      audioStripStyle,
      audioRecorderType,
      draftRecording,
      cancelRecording,
      isPaused,
      togglePause,
      timer,
      isPermissionGranted,
      playerState,
      startRecording,
      stopRecording,
      calculatedPercent,
      timeStamp,
      formattedLimit,
      stripContainerStyles,
    },
    forwardedRef
  ) => {
    // cancel recording from parent
    const [alertVisible, setAlertVisible] = useState(isPermissionGranted);

    useImperativeHandle(forwardedRef, () => ({
      cancelRecording: () => {
        cancelRecording();
      },
    }));

    const onAlertClose = () => {
      if (audioRecorderType == "strip") cancelRecording();
      setAlertVisible(false);
    };
    const renderRecorder = () => {
      const canRenderRecorder = playerState !== "cancelled" && !draftRecording;
      switch (audioRecorderType) {
        case "radial":
          return canRenderRecorder ? (
            <AudioRecorderRadial
              controlStyles={controlStyles}
              playerState={playerState}
              isPermissionGranted={isPermissionGranted}
              timer={timer}
              cancelRecording={cancelRecording}
              stopRecording={stopRecording}
              startRecording={startRecording}
              calculatedPercent={calculatedPercent}
              buttonStyles={buttonStyles}
              recordButtonColor={recordButtonColor}
              t={t}
              isPaused={isPaused}
              onTogglePause={togglePause}
              buttonsContainerStyles={buttonsContainerStyles}
              timeStamp={timeStamp}
              formattedLimit={formattedLimit}
              setAlertVisible={setAlertVisible}
            />
          ) : null;
        case "strip":
          return canRenderRecorder ? (
            <AudioRecorderStrip
              audioStripStyle={audioStripStyle}
              innerContainerStyle={innerContainerStyle}
              timer={timer}
              formattedLimit={formattedLimit}
              cancelRecording={cancelRecording}
              stopRecording={stopRecording}
              playerState={playerState}
              isPermissionGranted={isPermissionGranted}
              recordButtonColor={recordButtonColor}
              startRecording={startRecording}
              buttonStyles={buttonStyles}
              t={t}
              isPaused={isPaused}
              onTogglePause={togglePause}
              timeStamp={timeStamp}
              stripContainerStyles={stripContainerStyles}
            />
          ) : null;
        default:
          return null;
      }
    };

    return (
      <>
        {!isPermissionGranted && (
          <div
            className={
              audioRecorderType == "strip"
                ? classes.stripAlertContainer
                : classes.alertContainer
            }
          >
            {alertVisible && (
              <Alert
                title={t("common:microphone_permission_message")}
                type={"warning"}
                isClosable={true}
                onClose={onAlertClose}
              />
            )}
          </div>
        )}
        {renderRecorder()}
      </>
    );
  }
);

/**
 * UI component to show AudioRecorder Radial view
 */
const AudioRecorderRadial = props => {
  const {
    controlStyles,
    isPermissionGranted,
    playerState,
    stopRecording,
    startRecording,
    calculatedPercent,
    buttonStyles,
    recordButtonColor,
    timer,
    t,
    isPaused,
    onTogglePause,
    buttonsContainerStyles,
    timeStamp,
    setAlertVisible,
  } = props;

  const onRecordClick = () => {
    if (!isPermissionGranted) setAlertVisible(true);
    startRecording(); // starts recording, if permission is not given then asks for it.
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.progressContainer}>
          <RadialProgressBar
            size={280}
            value={calculatedPercent}
            circleStroke={"#F0F0F3"}
            progressStroke={colors.violet63}
            isArc={true}
            circleStrokeWidth={4}
          />
          <div className={classes.timer}>{timer}</div>
          {isPaused && (
            <div className={classes.pausedTextRadial}>{t("common:paused")}</div>
          )}
        </div>
      </div>
      <div className={classes.controls} style={{ ...(controlStyles || {}) }}>
        <div
          className={classes.buttonsContainer}
          style={{ ...buttonsContainerStyles }}
        >
          {isPermissionGranted &&
          playerState === "recording" &&
          timeStamp > 0 ? (
            <>
              <UIButton
                color={"blue"}
                type={"filled"}
                size={"lg"}
                onClick={() => onTogglePause(!isPaused)}
                containerStyle={{ ...buttonStyles }}
              >
                <span className={classes.buttonTextWrapper}>
                  {isPaused ? (
                    <RecorderButton isPaused={isPaused} />
                  ) : (
                    <PauseIconV2 fill={"white"} width={12} height={12} />
                  )}
                  <span className={classes.buttonText}>
                    {isPaused ? t("common:resume") : t("common:pause")}
                  </span>
                </span>
              </UIButton>
              <UIButton
                color={"pink"}
                type={"filled"}
                size={"lg"}
                onClick={stopRecording}
                containerStyle={{ ...buttonStyles }}
              >
                <span className={classes.buttonTextWrapper}>
                  <TickPlain
                    fillBackground={"transparent"}
                    width={12}
                    height={12}
                  />
                  <span className={classes.buttonText}>{t("common:done")}</span>
                </span>
              </UIButton>
            </>
          ) : (
            <UIButton
              color={recordButtonColor}
              type={"filled"}
              size={"lg"}
              onClick={onRecordClick}
              containerStyle={{ ...buttonStyles }}
            >
              <span className={classes.buttonTextWrapper}>
                <VoiceIcon width={16} height={16} />
                <span className={classes.buttonText}>{t("common:record")}</span>
              </span>
            </UIButton>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * UI component to show AudioRecorder Strip controls
 */
const AudioRecorderStrip = props => {
  const {
    playerState,
    isPermissionGranted,
    audioStripStyle,
    innerContainerStyle,
    timer,
    formattedLimit,
    cancelRecording,
    stopRecording,
    recordButtonColor,
    startRecording,
    buttonStyles,
    t,
    isPaused,
    onTogglePause,
    stripContainerStyles,
  } = props;

  const buttonContainerStyle = {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    minWidth: "unset",
    marginLeft: "16px",
    padding: "4px",
  };

  const recordingIconClass = classNames(
    { [classes.recordingIcon]: true },
    { [classes.recordingAnimation]: playerState === "recording" && !isPaused },
    { [classes.recordingIconPause]: isPaused }
  );

  const recordTimeStyles = classNames({
    [classes.recordTime]: true,
    [classes.recordingAnimation]: isPaused,
  });

  return (
    <>
      {isPermissionGranted && playerState === "record" && (
        <div
          className={classes.audioRecorderStrip}
          style={{
            ...audioStripStyle,
            backgroundColor: colors.white,
          }}
        >
          <UIButton
            color={recordButtonColor}
            type={"filled"}
            size={"lg"}
            onClick={startRecording}
            containerStyle={{ width: "100%", ...buttonStyles }}
          >
            <span className={classes.buttonTextWrapper}>
              <VoiceIcon width={16} height={16} />
              <span className={classes.buttonText}>{t("common:record")}</span>
            </span>
          </UIButton>
        </div>
      )}

      {isPermissionGranted && playerState === "recording" && (
        <div
          className={classes.audioRecorderStrip}
          style={{ ...(audioStripStyle || {}), ...stripContainerStyles }}
        >
          {/* <div
        className={classes.progressBar}
        style={{ width: `${calculatedPercent}%` }}
      ></div> */}
          <div
            className={classes.innerContainer}
            style={{ ...(innerContainerStyle || {}) }}
          >
            <div className={classes.leftContent}>
              <div className={recordingIconClass} />
              <div className={recordTimeStyles}>
                {isPaused && <span className={classes.pausedText}>Paused</span>}
                <span>{timer}</span>
                <span>/{formattedLimit}</span>
              </div>
            </div>
            <div className={classes.rightContent}>
              <UIButton
                color="grey"
                containerStyle={buttonContainerStyle}
                onClick={cancelRecording}
              >
                <CancelIcon width={12} height={12} fill={colors.white} />
              </UIButton>
              <UIButton
                color="blue"
                containerStyle={buttonContainerStyle}
                onClick={() => onTogglePause(!isPaused)}
              >
                {isPaused ? (
                  <RecorderButton isPaused={isPaused} />
                ) : (
                  <PauseIconV2 width={16} height={16} fill={colors.white} />
                )}
              </UIButton>
              <UIButton
                color="pink"
                containerStyle={buttonContainerStyle}
                onClick={stopRecording}
              >
                <TickPlain width={16} height={16} fill={colors.white} />
              </UIButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RecorderButton = ({ isPaused }) => {
  const recordingIconWhiteStyles = classNames({
    [classes.recordingIcon]: true,
    [classes.recordingIconWhite]: true,
    [classes.recordingAnimation]: isPaused,
  });
  return <div className={recordingIconWhiteStyles} />;
};

const mapActionCreators = { setToastMsg, updateAudioRecorderBlob };
const mapStateToProps = state => {
  return {
    draftRecording: _.get(state, "app_services.draftRecording", null),
  };
};

/**
 * ===== prop types & default props =====
 * assign them to both composed component and
 * original component, so that both can access them.
 */
const propTypes = {
  limit: PropTypes.number,
  recordButtonColor: PropTypes.string,
  autoStartRecording: PropTypes.bool,
  audioRecorderType: PropTypes.oneOf(["strip", "radial"]),
  handleAudioFile: PropTypes.func,
  innerContainerStyle: PropTypes.object,
  audioStripStyle: PropTypes.object,
  handleAudioStripVisibility: PropTypes.func,
  onCancel: PropTypes.func,
};
const defaultProps = {
  limit: 300000, // in milliseconds
  recordButtonColor: "blue",
  autoStartRecording: true,
  audioRecorderType: "strip",
  audioStripStyle: {
    top: "unset",
    height: "72px",
    zIndex: "1000",
  },
  innerContainerStyle: {},
  handleAudioStripVisibility: () => {},
  onCancel: () => {},
  saveDraftRecording: true,
  stripContainerStyles: {},
};

AudioRecorderV2.propTypes = propTypes;
AudioRecorderV2.defaultProps = defaultProps;

const ComposedAudioRecorderV2 = compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators, null, { withRef: true }),
  withRecorder
)(AudioRecorderV2);

ComposedAudioRecorderV2.propTypes = propTypes;
ComposedAudioRecorderV2.defaultProps = defaultProps;

export default ComposedAudioRecorderV2;
