import update from "immutability-helper";
import React from "react";
import {
  createAssignmentMutation,
  deleteAssignmentMutation,
  updateAssignmentMutation,
  updateStateForAssignmentMutation,
  editStudentAssignmentSubmissionMutation,
  updateElementRemarkMutation,
  editStudentAssignmentMutation,
  assignmentBulkActionMutation,
  sendStudentAssignementSubmissionFeedbackMutation,
  updateUserMessageStateMutation,
} from "./ClassRoomMutation";
import {
  getCourseAssignmentFeedQuery,
  getCourseAssignmentStateWiseCountQuery,
  getStudentTodosFilterCountQuery,
  getStudentTodosQuery,
  getStudentAssignmentDetailsQuery,
} from "./ClassRoomQuery";
import {
  getCourseAssignmentFeedFromCache,
  getCourseAssignmentStateWiseCountFromCache,
  writeCourseAssignmentFeedFromCache,
  writeCourseAssignmentStateWiseCountInCache,
  writeStudentAssigmentSubmissionInCache,
  getStudentAssignmentDetailsFromCache,
  writeStudentAssignmentDetailsInCache,
} from "./ClassRoomGraphqlHelpers";
import { getCourseGenericFoldersQuery } from "Course/modules/CourseQuery";
import {
  getCourseGenericFoldersFromCache,
  writeCourseGenericFoldersInCache,
} from "Course/modules/CourseGraphqlHelpers";
import client from "apolloClient";
import * as EventTracker from "lib/eventTracker";
import { updateField, goToRelativeRoute } from "modules/Services";
import { setToastMsg } from "Login/modules/LoginModule";
import { goToPlatformHomeFeatureRoute } from "modules/NavigationModule";
import { updateCoachMarkStatus } from "modules/CoachMarksModule";
import {
  getStringFromArray,
  ATTACHMENT_WARNINGS,
  generateRandomId,
  getFileExtensionFromMimeType,
} from "Utils";
import {
  getConversationsQuery,
  getConversationsUnreadCountQuery,
} from "AppComponents/ChatContainer/modules/ChatContainerQuery";
import {
  // getStudentTasksQuery,
  getStudentTasksFilterCountQuery,
} from "Todos/modules/TodosQueries";
import {
  getStudentTasksFromCache,
  writeStudentTasksToCache,
} from "Todos/modules/TodosGraphqlHelpers";
import { getClassroomTodoFilters, getClassroomTodoCountFilters } from "./utils";
import ACLStore from "lib/aclStore";

import { DiscussionFilledIcon, CreateAssessmentSvg } from "SvgComponents";
import {
  QuickTaskOutlined,
  LeOutlined,
  VideoOutlined,
  UnitPlanOutlined,
  FaOutlined,
  SaOutlined,
  LeLibraryOutlined,
  AssessmentAddOutlined,
  CommunityOutlined,
  ImportOutlined,
} from "@toddle-design/web-icons";
import { colors } from "Constants";

const getMutationParams = ({ params, creationMode }) => {
  let newParams;
  // for creating le, smt and fmt from classroom, we have to send importedLeId instead of
  // leId, so that backend creates distinct copies for all the assigned classes. If leId is
  // sent instead of importedLeId, then backend does not creates a copy for the first assigned class
  switch (creationMode) {
    case "CREATE_FA":
    case "CREATE_SA":
    case "CREATE_LE":
    case "CREATE_ASSESSMENT":
      newParams = update(params, {
        importedLeId: {
          $set: params.leId,
        },
        leId: {
          $set: "",
        },
      });
      return _.omit(newParams, ["creationMode", "creationSource", "pageName"]);
    default:
      return _.omit(params, [
        "importedLeId",
        "creationMode",
        "creationSource",
        "pageName",
      ]);
  }
};

export const ASSIGNMENT_STATE_TABS = {
  PUBLISHED: {
    label: "common:ong_label",
    studentLabel: "classRoom:todo",
    value: "PUBLISHED",
    orderBy: "PUBLISHED_AT",
    orderByDirection: "DESC",
    orderByDateKey: "publishedAt",
  },
  SCHEDULED: {
    label: "common:scheduled",
    studentLabel: "common:scheduled",
    value: "SCHEDULED",
    orderBy: "PUBLISHED_AT",
    orderByDirection: "ASC",
    orderByDateKey: "publishedAt",
  },
  ARCHIVED: {
    label: "common:completed",
    studentLabel: "common:done",
    value: "ARCHIVED",
    orderBy: "ARCHIVED_AT",
    orderByDirection: "DESC",
    orderByDateKey: "updatedAt",
  },
};

export const ASSIGNMENT_TODO_STATE_TABS = {
  ASSIGNED: {
    label: "classRoom:assigned",
    value: "ASSIGNED",
    orderBy: "PUBLISHED_AT",
    orderByDirection: "DESC",
    userType: ["student", "parent"],
  },
  OVERDUE: {
    label: "classRoom:overdue",
    value: "OVERDUE",
    orderBy: "DUE_AT",
    orderByDirection: "DESC",
    userType: ["student", "parent"],
  },
  COMPLETED: {
    label: "common:completed",
    value: "COMPLETED",
    orderBy: "ARCHIVED_AT",
    orderByDirection: "DESC",
    userType: ["student", "parent"],
  },
  TO_REVIEW: {
    label: "classRoom:to_review",
    value: "TO_REVIEW",
    orderBy: "PUBLISHED_AT",
    orderByDirection: "DESC",
    userType: ["staff"],
  },
  REVIEWED: {
    label: "common:completed",
    value: "REVIEWED",
    orderBy: "ARCHIVED_AT",
    orderByDirection: "DESC",
    userType: ["staff"],
  },
};

const PYP_CREATE_OPTIONS = [
  {
    label: "common:create",
    key: "create",
    isItemGroup: true,
    options: [
      {
        label: "common:quick_task",
        key: "CREATE_QUICK_TASK",
        icon: <QuickTaskOutlined variant={"subtle"} />,
      },
      {
        label: "common:le_label",
        key: "CREATE_LE",
        icon: <LeOutlined variant={"subtle"} />,
        perm: "TeacherPortal:CreateClassroomLE",
      },
      {
        label: "common:video_meeting_lower",
        key: "SCHEDULE_VIDEO_CALL",
        icon: <VideoOutlined variant={"subtle"} />,
      },
    ],
  },
];

const PYP_IMPORT_OPTIONS_GROUP = [
  {
    label: "common:import_from",
    key: "Assign",
    isItemGroup: true,
    options: [
      {
        label: "common:unit",
        key: "IMPORT_LE",
        icon: <UnitPlanOutlined variant={"subtle"} />,
      },
      {
        label: "common:library",
        key: "IMPORT_LE_FROM_LE_LIBRARY",
        icon: <LeLibraryOutlined variant={"subtle"} />,
        perm: "TeacherPortal:LELibrary",
      },
      {
        label: "common:community",
        key: "IMPORT_LE_FROM_COMMUNITY",
        icon: <CommunityOutlined variant={"subtle"} />,
        perm: "TeacherPortal:Community",
      },
    ],
  },
];
export const ASSIGNMENT_CREATE_OPTIONS_NEW_CALENDAR = [
  ...PYP_CREATE_OPTIONS,
  ...PYP_IMPORT_OPTIONS_GROUP,
];

export const ASSIGNMENT_CREATE_OPTIONS_NEW = [
  ...PYP_CREATE_OPTIONS,
  ...PYP_IMPORT_OPTIONS_GROUP,
];

const MYP_CREATE_OPTIONS = [
  {
    label: "common:create",
    key: "create",
    isItemGroup: true,
    options: [
      {
        label: "common:quick_task",
        key: "CREATE_QUICK_TASK",
        icon: <QuickTaskOutlined variant={"subtle"} />,
      },
      {
        label: "classRoom:formative_assessment",
        key: "CREATE_FA",
        icon: <FaOutlined variant="subtle" />,
      },
      {
        label: "classRoom:summative_assessment",
        key: "CREATE_SA",
        icon: <SaOutlined variant="subtle" />,
      },
      {
        label: "common:le_label",
        key: "CREATE_LE",
        icon: <LeOutlined variant="subtle" />,
      },
    ],
  },
];

const MYP_IMPORT_OPTIONS_GROUP = {
  label: "common:import_from",
  key: "Assign",
  isItemGroup: true,
  options: [
    {
      label: "common:library",
      key: "IMPORT_LE_FROM_LE_LIBRARY",
      icon: <LeOutlined variant={"subtle"} />,
      perm: "TeacherPortal:LELibraryMyp",
    },
    {
      label: "common:unit",
      key: "UNIT",
      icon: <UnitPlanOutlined variant={"subtle"} />,
    },
  ],
};

export const MYP_ASSIGNMENT_OPTIONS_NEW = [
  ...MYP_CREATE_OPTIONS,
  MYP_IMPORT_OPTIONS_GROUP,
];

const UBD_CREATE_OPTIONS = [
  {
    label: "common:create",
    key: "create",
    isItemGroup: true,
    options: [
      {
        label: "common:quick_task",
        key: "CREATE_QUICK_TASK",
        icon: <QuickTaskOutlined variant={"subtle"} />,
      },
      {
        label: "common:assessment",
        key: "CREATE_ASSESSMENT",
        icon: <AssessmentAddOutlined variant={"subtle"} />,
      },
      {
        label: "common:le_label",
        key: "CREATE_LE",
        icon: <LeOutlined variant={"subtle"} />,
      },
    ],
  },
];

const UBD_IMPORT_OPTIONS_GROUP = {
  label: "common:import_from",
  key: "Assign",
  isItemGroup: true,
  options: [
    {
      label: "common:library",
      key: "IMPORT_LE_FROM_LE_LIBRARY",
      icon: <LeLibraryOutlined variant={"subtle"} />,
      perm: "TeacherPortal:LELibraryUbd",
    },
    {
      label: "common:unit",
      key: "UNIT",
      icon: <UnitPlanOutlined variant={"subtle"} />,
    },
  ],
};

export const UBD_ASSIGNMENT_OPTIONS_NEW = [
  ...UBD_CREATE_OPTIONS,
  UBD_IMPORT_OPTIONS_GROUP,
];

const SCHEDULE_VIDEO_CALL_OPTION = {
  label: "common:schedule_video_call",
  key: "SCHEDULE_VIDEO_CALL",
  icon: <VideoOutlined variant={"subtle"} />,
};

export const MYP_CALENDAR_OPTIONS_NEW = [
  ...MYP_CREATE_OPTIONS,
  MYP_IMPORT_OPTIONS_GROUP,
  SCHEDULE_VIDEO_CALL_OPTION,
];

export const SCORE_DETAIL_CATEGORIES_OPTION = [
  {
    id: "AVERAGE",
    label: "classRoom:class_average",
  },
  {
    id: "HIGHEST",
    label: "classRoom:highest",
  },
  {
    id: "LOWEST",
    label: "classRoom:lowest",
  },
];

export const UBD_CALENDAR_OPTIONS = [
  ...UBD_CREATE_OPTIONS,
  UBD_IMPORT_OPTIONS_GROUP,
  SCHEDULE_VIDEO_CALL_OPTION,
];

export const ASSIGNMENT_TYPE_MAPPING = {
  ASSESSMENT: {
    typeLabel: "common:le_label",
    color: colors.violet63,
    svg: <LeOutlined variant="on" />,
  },
  ASSIGNMENT_RESOURCE: {
    typeLabel: "common:quick_task",
    color: colors.blue40,
    svg: <QuickTaskOutlined variant="on" />,
  },
  ASSIGNMENT_DISCUSSION: {
    typeLabel: "common:discussion",
    color: colors.green42,
    svg: <DiscussionFilledIcon fill={colors.white} />,
  },
  ASSIGNMENT_MEETING: {
    typeLabel: "common:video_call",
    typeLabel2: "common:video_meeting",
    color: colors.pink48,
    svg: <VideoOutlined variant={"on"} />,
  },
};

export const PYP_ASSIGNMENT_TYPE_MAPPING = {
  le: {
    typeLabel: "common:le_label",
    color: colors.violet63,
    svg: <LeOutlined variant="on" />,
  },
  qt: {
    typeLabel: "common:quick_task",
    color: colors.blue40,
    svg: <QuickTaskOutlined variant="on" />,
  },
};

export const MYP_ASSIGNMENT_TYPE_MAPPING = {
  smt: {
    typeLabel: "classRoom:summative_assessment",
    color: colors.teal42,
    svg: <SaOutlined variant={"on"} />,
  },
  fmt: {
    typeLabel: "classRoom:formative_assessment",
    color: colors.yellow50,
    svg: <FaOutlined variant={"on"} />,
  },
  le: {
    typeLabel: "common:le_label",
    color: colors.violet63,
    svg: <LeOutlined variant="on" />,
  },
  qt: {
    typeLabel: "common:quick_task",
    color: colors.blue40,
    svg: <QuickTaskOutlined variant="on" />,
  },
};

export const UBD_ASSIGNMENT_TYPE_MAPPING = {
  fmt: {
    typeLabel: "classRoom:formative_assessment",
    color: colors.yellow50,
    svg: <FaOutlined variant={"on"} />,
  },
  pt: {
    typeLabel: "common:performance_task",
    color: colors.blue29,
    svg: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  pri: {
    typeLabel: "classRoom:pre_assessment",
    color: colors.blue29,
    svg: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  se: {
    typeLabel: "common:supplementary_evidence",
    color: colors.blue29,
    svg: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  le: {
    typeLabel: "common:le_label",
    color: colors.violet63,
    svg: <LeOutlined variant={"on"} />,
  },
  qt: {
    typeLabel: "common:quick_task",
    color: colors.blue40,
    svg: <QuickTaskOutlined variant={"on"} />,
  },
  ba: {
    typeLabel: "common:benchmark_assessment",
    color: colors.blue29,
    svg: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  afl: {
    typeLabel: "common:assessment_for_learning",
    color: colors.blue29,
    svg: <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />,
  },
  aol: {
    typeLabel: "common:assessment_of_learning",
    color: colors.yellow50,
    svg: <CreateAssessmentSvg fill={colors.white} fill1={colors.yellow50} />,
  },
};

export const CURRICULUM_WISE_ASSIGNMENT_TYPE_MAPPING = {
  IB_PYP: {
    key: "contentTypes",
    value: ASSIGNMENT_TYPE_MAPPING,
  },
  IB_MYP: {
    key: "assessmentTypes",
    value: MYP_ASSIGNMENT_TYPE_MAPPING,
  },
  UBD: {
    key: "assessmentTypes",
    value: UBD_ASSIGNMENT_TYPE_MAPPING,
  },
  IB_DP: {
    key: "assessmentTypes",
    value: MYP_ASSIGNMENT_TYPE_MAPPING,
  },
};

export const STUDENT_ASSIGNMENT_STATUS = [
  { label: "classRoom:submission_pending", value: "SUBMISSION_PENDING" },
  { label: "classRoom:overdue", value: "OVERDUE" },
  { label: "classRoom:evaluation_pending", value: "EVALUATION_PENDING" },
  { label: "classRoom:turned_in", value: "TURNED_IN" },
  {
    label: "classRoom:request_resubmission",
    value: "REQUEST_RESUBMISSION",
  },
  { label: "classRoom:evaluated", value: "EVALUATED" },
  { label: "classRoom:evaluated_and_shared", value: "EVALUATED_SHARED" },
  { label: "classRoom:completed", value: "COMPLETED" },
];

export const STUDENT_FEED_STATUS = [
  { label: "classRoom:submission_pending", value: "SUBMISSION_PENDING" },
  {
    label: "classRoom:submission_not_required",
    value: "SUBMISSION_NOT_REQUIRED",
  },
  { label: "classRoom:overdue", value: "OVERDUE" },
  { label: "classRoom:turned_in", value: "TURNED_IN" },
  {
    label: "classRoom:request_resubmission",
    value: "REQUEST_RESUBMISSION",
  },
  { label: "classRoom:evaluated", value: "EVALUATED" },
  { label: "classRoom:completed", value: "COMPLETED" },
];

export const NAME = "classRoom";

export const INIT_FILTERS = "INIT_FILTERS" + " " + NAME;
export const UPDATE_FILTERS = "UPDATE_FILTERS" + " " + NAME;
export const UPDATE_COURSE_ID = "UPDATE_COURSE_ID" + " " + NAME;
export const UPDATE_TODO_FILTERS = "UPDATE_TODO_FILTERS" + " " + NAME;
export const CHANGE_INSIGHTS_SEARCH_TERM = "INSIGHTS_SEARCH_TERM" + " " + NAME;
export const SET_COURSE_ID = "SET_COURSE_ID" + " " + NAME;
export const SET_STUDENT_DETAIL_FILTERS =
  "SET_STUDENT_DETAIL_FILTERS" + " " + NAME;
export const INIT_STUDENT_DETAIL_FILTERS =
  "INIT_STUDENT_DETAIL_FILTERS" + " " + NAME;
export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};

export const updateTodoFilters = data => {
  return {
    type: UPDATE_TODO_FILTERS,
    data,
  };
};

export const initFilters = () => {
  return {
    type: INIT_FILTERS,
  };
};

export const initStudentDetailFilters = () => {
  return {
    type: INIT_STUDENT_DETAIL_FILTERS,
  };
};

export const updateCourseId = courseId => {
  return {
    type: UPDATE_COURSE_ID,
    payload: courseId,
  };
};

export const assignmentBulkAction = params => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = getState().classRoom.filters;
    try {
      const response = await client.mutate({
        mutation: assignmentBulkActionMutation,
        variables: params,
        refetchQueries: _.isEmpty(courseId)
          ? []
          : [
              {
                query: getCourseAssignmentFeedQuery,
                variables: { courseId, ...getQueryFilters({ filters }) },
              },
              {
                query: getCourseAssignmentStateWiseCountQuery,
                variables: { courseId },
              },
              {
                query: getCourseGenericFoldersQuery,
                variables: { courseId, folderType: "CLASSROOM" },
              },
            ],
      });
      const actionResponse = _.get(
        response,
        "data.platform.assignmentBulkAction",
        []
      );
      if (!_.isEmpty(_.get(actionResponse, "warning"))) {
        const errorCode = _.get(actionResponse, "warning.errorCode");
        dispatch(
          setToastMsg({
            msg: errorCode,
          })
        );
        return false;
      }
      return true;
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const changeStudentInsightsSearchTerm = value => {
  return { type: CHANGE_INSIGHTS_SEARCH_TERM, payload: value };
};

export const setCourseId = value => {
  return { type: SET_COURSE_ID, payload: value };
};

export const setStudentDetailFilters = value => {
  return { type: SET_STUDENT_DETAIL_FILTERS, payload: value };
};

export const changeCurrentStudent = ({ id, currentSection }) => {
  return (dispatch, getState) => {
    dispatch(goToRelativeRoute({ route: `../../${id}/${currentSection}` }));
  };
};

const getAssignmentResponseWarnings = ({ assignmentsResponse }) => {
  const errorCode = _.get(assignmentsResponse, ["0", "warning", "errorCode"]);
  const errorText = ATTACHMENT_WARNINGS[errorCode];
  const locale_params = [];
  if (errorCode === "ATTACHMENT_WITHOUT_PERMISSION") {
    const attachmentList = _.get(
      assignmentsResponse,
      ["0", "warning", "metadata", "attachmentList"],
      []
    );
    const attachmentNamesArray = _.map(attachmentList, attachment => {
      const attachmentName = _.get(attachment, "name", "");
      if (_.get(attachment, "type", "") === "IMAGE") {
        return attachmentName?.concat(" (Image)");
      }
      const mimeType = _.get(attachment, "mime_type", null);
      if (mimeType) {
        const attachmentType = _.upperCase(
          getFileExtensionFromMimeType({
            mimeType,
          })
        );
        return attachmentName?.concat(` (${attachmentType})`);
      }
      return attachmentName;
    });
    const attachmentNames = _.join(attachmentNamesArray, ", ");
    locale_params.push({
      key: "attachments",
      value: attachmentNames,
      isPlainText: true,
    });
  }
  return { errorText, locale_params };
};

const getAssignmentResponseWarningsMemoized = _.memoize(
  params => getAssignmentResponseWarnings(params),
  params => JSON.stringify(params)
);

export const createAssignment = params => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = getState().classRoom.filters;
    const {
      importedLeId,
      portalType,
      creationMode,
      courses,
      leId,
      isScheduledAssignment,
      creationSource,
      pageName,
    } = params || {};
    const userType = getState().login.userInfo.user_type;
    const userId = getState().login.userInfo.id;

    const isLeFromCommunity =
      creationMode == "IMPORT_IN_CLASS" ||
      creationMode == "IMPORT_LE_FROM_COMMUNITY";

    const isLeImport =
      isLeFromCommunity ||
      _.includes(
        ["IMPORT_LE", "IMPORT_LE_FROM_LE_LIBRARY", "ASSIGN_TO_CLASS"],
        creationMode
      );

    const refetchQueries =
      _.includes(
        ["IMPORT_IN_CLASS", "IMPORT_LE_FROM_LE_LIBRARY", "ASSIGN_TO_CLASS"],
        creationMode
      ) ||
      creationSource != "CLASSROOM_FEED" ||
      _.isEmpty(courseId)
        ? []
        : [
            {
              query: getCourseAssignmentFeedQuery,
              variables: {
                courseId,
                ...getQueryFilters({ filters }),
                portalType,
              },
            },
            {
              query: getCourseAssignmentStateWiseCountQuery,
              variables: { courseId, portalType },
            },
            {
              query: getCourseGenericFoldersQuery,
              variables: { courseId, folderType: "CLASSROOM", portalType },
            },
          ];
    try {
      const response = await client.mutate({
        mutation: createAssignmentMutation,
        variables: {
          ...getMutationParams({ params, creationMode }),
          courses: _.map(courses, item => _.omit(item, ["title"])),
          studentIds: userType == "student" ? userId : null,
          source: _.join(
            _.filter([creationSource, pageName], item => item),
            "_"
          ),
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
      const assignmentsResponse = _.get(
        response,
        "data.platform.createAssignmentV2",
        []
      );
      if (!_.isEmpty(_.get(assignmentsResponse, ["0", "warning"]))) {
        const {
          errorText,
          locale_params,
        } = getAssignmentResponseWarningsMemoized({ assignmentsResponse });
        dispatch(
          setToastMsg({
            msg: errorText,
            timeOut: 5000,
            locale_params,
          })
        );
        return false;
      }
      const assignments = _.map(assignmentsResponse, item => item.response);
      const assignmentLength = _.get(assignments, "length", 0);
      const assessmentName = _.get(assignments, "0.content.title.value", "");

      const courseNames = getStringFromArray({
        nameArray: _.map(courses, item => item.title),
      });
      if (isLeImport) {
        const onToastClick = _.includes(
          ["IMPORT_IN_CLASS", "IMPORT_LE_FROM_LE_LIBRARY", "ASSIGN_TO_CLASS"],
          creationMode
        )
          ? () => {
              dispatch(
                goToPlatformHomeFeatureRoute({
                  courseId: _.get(courses[0], "courseId", ""),
                  mode: "view",
                  isOpenInNewTab: true,
                  route:
                    assignmentLength == 1
                      ? `classroom/classroom-details/${_.get(
                          assignments[0],
                          "id",
                          ""
                        )}`
                      : `classroom`,
                })
              );
            }
          : null;
        dispatch(
          setToastMsg({
            msg: _.includes(
              [
                "IMPORT_IN_CLASS",
                "IMPORT_LE_FROM_LE_LIBRARY",
                "ASSIGN_TO_CLASS",
              ],
              creationMode
            )
              ? "toastMsgs:copy_le_successfully_imported_classroom_with_link"
              : "toastMsgs:copy_le_successfully_imported_classroom",
            type: "success",
            locale_params: [
              {
                key: "entityName",
                value: assessmentName,
                isPlainText: true,
              },
              {
                key: "courseLabel",
                value:
                  _.get(courses, "length", 0) == 1 ? `class` : "class_plural",
                isPlainText: false,
              },
              {
                key: "courseNames",
                value: courseNames,
                isPlainText: true,
              },
            ],
            onClick: onToastClick,
            closeButton: _.includes(
              [
                "IMPORT_IN_CLASS",
                "IMPORT_LE_FROM_LE_LIBRARY",
                "ASSIGN_TO_CLASS",
              ],
              creationMode
            ),
          })
        );
      } else {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:action_successfully",
            locale_params: [
              {
                key: "label",
                value: "common:post",
                isPlainText: false,
              },
              {
                key: "action",
                value: `toastMsgs:${
                  isScheduledAssignment ? "scheduled" : "created"
                }`,
                isPlainText: false,
              },
            ],
            type: "success",
          })
        );
      }

      if (isLeFromCommunity) {
        dispatch(
          updateCoachMarkStatus({
            type: "COMMUNITY_IMPORT_LE",
            portalType: "COMMUNITY",
          })
        );
        EventTracker.recordEvent({
          eventName: "Imported le in classroom",
          eventData: {
            entity_type: "ASSESSMENT",
            entity_id: importedLeId,
            source: "community",
            entity_name: assessmentName,
            target:
              creationMode == "IMPORT_IN_CLASS"
                ? "learning experience whole view"
                : "search in community",
          },
        });
      }
      return true;
    } catch (e) {
      console.error(e);
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const updateAssignment = params => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = getState().classRoom.filters;
    const { creationSource } = params || {};
    try {
      const response = await client.mutate({
        mutation: updateAssignmentMutation,
        variables: { ..._.omit(params, ["creationSource", "pageName"]) },
        refetchQueries:
          creationSource != "CLASSROOM_FEED" || _.isEmpty(courseId)
            ? []
            : [
                {
                  query: getCourseAssignmentFeedQuery,
                  variables: { courseId, ...getQueryFilters({ filters }) },
                },
                {
                  query: getCourseAssignmentStateWiseCountQuery,
                  variables: { courseId },
                },
                {
                  query: getCourseGenericFoldersQuery,
                  variables: { courseId, folderType: "CLASSROOM" },
                },
              ],
      });
      const assignmentsResponse = _.get(
        response,
        "data.platform.updateAssignmentV2",
        []
      );
      if (!_.isEmpty(_.get(assignmentsResponse, ["0", "warning"]))) {
        const {
          errorText,
          locale_params,
        } = getAssignmentResponseWarningsMemoized({ assignmentsResponse });
        dispatch(
          setToastMsg({
            msg: errorText,
            timeOut: 5000,
            locale_params,
          })
        );
        return false;
      }
      dispatch(
        setToastMsg({
          msg: "toastMsgs:action_successfully",
          locale_params: [
            {
              key: "label",
              value: "common:post",
              isPlainText: false,
            },
            {
              key: "action",
              value: `toastMsgs:updated`,
              isPlainText: false,
            },
          ],
          type: "success",
        })
      );
      return true;
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const deleteAssignment = id => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = getState().classRoom.filters;
    const itemDetails = getCourseAssignmentFeedFromCache({
      courseId,
      ...getQueryFilters({ filters }),
    });
    const stateWiseCountDetails = getCourseAssignmentStateWiseCountFromCache({
      courseId,
    });

    await client.mutate({
      mutation: deleteAssignmentMutation,
      variables: {
        id,
      },

      update: (
        cache,
        {
          data: {
            platform: { deleteAssignment },
          },
        }
      ) => {
        if (
          deleteAssignment &&
          !_.isEmpty(itemDetails) &&
          !_.isEmpty(stateWiseCountDetails)
        ) {
          const { assignmentStateTab, folderTopic } = filters;

          //calculate and update updated assignments
          const items = _.get(itemDetails, "assignments", {});
          const itemEdges = _.filter(
            _.get(items, "edges", []),
            item => item.node.id != id
          );
          const updatedItemDetails = {
            ...itemDetails,
            assignments: {
              ...items,
              edges: itemEdges,
              totalCount: _.get(items, "totalCount", 1) - 1,
            },
          };
          writeCourseAssignmentFeedFromCache({
            courseId,
            ...getQueryFilters({ filters }),
            data: updatedItemDetails,
          });

          //calculate and update updated stateWiseCount
          const stateWiseCount = _.map(
            _.get(stateWiseCountDetails, "assignments.stateWiseCount", []),
            countDetails => {
              if (countDetails.state == assignmentStateTab)
                return {
                  ...countDetails,
                  count: _.get(countDetails, "count", 1) - 1,
                };
              else return countDetails;
            }
          );
          const updatedStateWiseCountDetails = {
            ...stateWiseCountDetails,
            assignments: {
              stateWiseCount: stateWiseCount,
            },
          };
          writeCourseAssignmentStateWiseCountInCache({
            courseId,
            data: updatedStateWiseCountDetails,
          });

          //if folder is not ALL, update generic folder count as well
          if (folderTopic !== "ALL") {
            const courseGenericFolderDetails = getCourseGenericFoldersFromCache(
              { courseId, folderType: "CLASSROOM" }
            );

            const updatedGenericFolders = _.map(
              _.get(courseGenericFolderDetails, "genericFolders", []),
              genericFolder => {
                if (genericFolder.id == folderTopic) {
                  const updatedStateWiseCountOfFolder = _.map(
                    _.get(genericFolder, "typeDetails.stateWiseCount", []),
                    countDetails => {
                      if (countDetails.state == assignmentStateTab)
                        return update(countDetails, {
                          count: { $set: _.get(countDetails, "count", 1) - 1 },
                        });
                      else return countDetails;
                    }
                  );
                  return update(genericFolder, {
                    typeDetails: {
                      stateWiseCount: { $set: updatedStateWiseCountOfFolder },
                    },
                  });
                } else return genericFolder;
              }
            );

            const updatedCourseGenericFolderDetails = update(
              courseGenericFolderDetails,
              { genericFolders: { $set: updatedGenericFolders } }
            );
            writeCourseGenericFoldersInCache({
              courseId,
              folderType: "CLASSROOM",
              data: updatedCourseGenericFolderDetails,
            });
          }
        }
      },
    });
  };
};

export const updateStateForAssignment = params => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = getState().classRoom.filters;
    const userType = getState().login.userInfo.user_type;
    const userId = getState().login.userInfo.id;

    const teacherFeedVars = {
      courseId,
      ...getQueryFilters({ filters }),
    };

    const studentFeedVars = {
      courseIds: courseId,
      ...getFamilyStudentFeedQueryFilters({ filters, userId, userType }),
    };

    await client.mutate({
      mutation: updateStateForAssignmentMutation,
      variables: {
        ...params,
        studentIds: userType == "student" ? userId : null,
      },
      awaitRefetchQueries: true,
      refetchQueries: _.isEmpty(courseId)
        ? []
        : [
            {
              query:
                userType == "staff"
                  ? getCourseAssignmentFeedQuery
                  : getStudentTodosQuery,
              variables:
                userType == "staff" ? teacherFeedVars : studentFeedVars,
            },
            {
              query: getCourseAssignmentStateWiseCountQuery,
              variables: { courseId },
            },
            {
              query: getCourseGenericFoldersQuery,
              variables: { courseId, folderType: "CLASSROOM" },
            },
          ],
    });
  };
};

export const updateStateForTodoAssignment = ({ params, filterOptions }) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const todoFilters = getState().classRoom.todoFilters;
    const userType = getState().login.userInfo.user_type;
    const userId = getState().login.userInfo.id;

    await client.mutate({
      mutation: updateStateForAssignmentMutation,
      variables: {
        ...params,
        studentIds: userType == "student" ? userId : null,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getStudentTodosFilterCountQuery,
          variables: getClassroomTodoCountFilters({
            courseIds: courseId,
            userId,
            userType,
            ...todoFilters,
          }),
        },
        {
          query: getStudentTodosQuery,
          variables: getClassroomTodoFilters({
            userId,
            filterOptions,
            userType,
            ...todoFilters,
          }),
        },
      ],
    });
  };
};

export const editStudentAssignmentSubmissionWithDebounce = params => {
  return async (dispatch, getState) => {
    dispatch(
      updateField({
        key: params.id,
        params,
        type: "STUDENT_ASSIGNMENT_SUBMISSION",
        isDebounce: true,
      })
    );
  };
};

export const editStudentAssignmentSubmission = ({
  mutationParams,
  refetchQueryParams,
  filterCountVariables,
}) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: editStudentAssignmentSubmissionMutation,
        variables: mutationParams,
        awaitRefetchQueries: true,
        refetchQueries: refetchQueryParams
          ? [
              {
                query: getStudentAssignmentDetailsQuery,
                variables: refetchQueryParams,
              },
              {
                query: getStudentTasksFilterCountQuery,
                variables: filterCountVariables,
              },
            ]
          : [],
      });

      return { ...response, successful: true };
    } catch (e) {
      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const updateTeacherRemarkInCache = ({
  assignmentDetailsVariables,
  message,
}) => {
  const cacheData = getStudentAssignmentDetailsFromCache(
    assignmentDetailsVariables
  );

  const elementRemarkFallback = {
    ibPYPElementId: null,
    ibPYPElementType: "STUDENT_ASSIGNMENT",
    id: _.get(cacheData, ["elementRemarks", 0, "id"], generateRandomId()),
  };

  const data = {
    ...(cacheData || {}),
    elementRemarks: [
      {
        ..._.get(cacheData, ["elementRemarks", 0], elementRemarkFallback),
        message,
        __typename: "IbPypElementRemark",
      },
    ],
  };

  writeStudentAssignmentDetailsInCache({
    ...assignmentDetailsVariables,
    data,
  });
};

export const updateScoreElementRatingInCache = ({
  assignmentDetailsVariables,
  scoreValue,
}) => {
  const cacheData = getStudentAssignmentDetailsFromCache(
    assignmentDetailsVariables
  );

  const ibPYPElementRatings = _.get(cacheData, "ibPYPElementRatings", []);

  let hasScoreField;
  let updatedIbPYPElementRatings = _.map(ibPYPElementRatings, item => {
    const { ibPYPElementType } = item;
    if (ibPYPElementType === "SCORE") {
      hasScoreField = true;
      return {
        ...item,
        value: scoreValue,
      };
    }
    return item;
  });

  // if "SCORE" element doesn't exist, then add it with random id
  if (!hasScoreField) {
    updatedIbPYPElementRatings = [
      ...(ibPYPElementRatings || []),
      {
        academicCriteriaSet: null,
        academicCriteriaValue: null,
        ibPYPElementId: null,
        ibPYPElementType: "SCORE",
        id: generateRandomId(),
        value: scoreValue,
        __typename: "IBPYPElementRating",
      },
    ];
  }

  const data = {
    ...(cacheData || {}),
    ibPYPElementRatings: updatedIbPYPElementRatings,
  };

  writeStudentAssignmentDetailsInCache({
    ...assignmentDetailsVariables,
    data,
  });
};

export const updateElementRemark = mutationVariables => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateElementRemarkMutation,
        variables: {
          input: { ...mutationVariables },
        },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const editStudentAssignment = params => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: editStudentAssignmentMutation,
        variables: params,
      });
      return { ...response, successful: true };
    } catch (e) {
      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const unHandStudentAssignmentSubmission = params => {
  return async (dispatch, getState) => {
    try {
      const paramsToSend = { id: params.id, status: params.status };
      if (!_.isEmpty(_.get(params, "statusMessage.label", null))) {
        paramsToSend["statusMessage"] = params.statusMessage;
      }
      const response = await client.mutate({
        mutation: editStudentAssignmentSubmissionMutation,
        variables: paramsToSend,
        refetchQueries: [
          {
            query: getConversationsQuery,
            variables: { id: _.get(params, "statusMessage.parentId", "") },
          },
        ],
      });
      return { ...response, successful: true };
    } catch (e) {
      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const updateAssignmentSubmissionState = ({
  changeIsLoading,
  submission,
  status,
  statusOnResponseUnsuccessful,
}) => {
  return async (dispatch, getState) => {
    try {
      const submissionId = _.get(submission, "id", "");
      changeIsLoading(true);
      const res = await dispatch(
        editStudentAssignmentSubmission({
          mutationParams: {
            id: submissionId,
            status,
          },
        })
      );
      changeIsLoading(false);

      if (!res.successful) {
        writeStudentAssigmentSubmissionInCache({
          id: submissionId,
          data: { ...submission, status: statusOnResponseUnsuccessful },
        });
        dispatch(setToastMsg(res.errors[0]));
      }
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const sendStudentAssignementSubmissionFeedback = input => {
  return async () => {
    try {
      const response = await client.mutate({
        mutation: sendStudentAssignementSubmissionFeedbackMutation,
        variables: { input },
      });
      return { ...response, successful: true };
    } catch (e) {
      return {
        successful: false,
        errors: ["Something went wrong"],
      };
    }
  };
};

export const updateUserMessageState = ({ input, conversationId }) => {
  return async () => {
    try {
      const response = await client.mutate({
        mutation: updateUserMessageStateMutation,
        variables: { input },
      });
      client.query({
        query: getConversationsUnreadCountQuery,
        variables: {
          id: conversationId,
        },
        fetchPolicy: "network-only",
      });
      return { ...response, successful: true };
    } catch (e) {
      return {
        successful: false,
        errors: ["Something went wrong"],
      };
    }
  };
};

export const getQueryFilters = ({ filters }) => {
  const {
    contentTypes,
    searchText,
    createdBy,
    assignmentStateTab,
    folderTopic,
    assessmentTypes,
  } = filters;
  const sendFilters = {};
  if (searchText != "") {
    sendFilters["searchText"] = searchText;
  }
  if (contentTypes != "ALL") {
    sendFilters["contentTypes"] = [contentTypes];
  }
  if (assessmentTypes != "ALL") {
    sendFilters["assessmentTypes"] = [assessmentTypes];
  }
  if (createdBy != "ALL") {
    sendFilters["createdBy"] = createdBy;
  }
  if (assignmentStateTab) {
    sendFilters["states"] = assignmentStateTab;
    sendFilters["orderBy"] = _.get(
      ASSIGNMENT_STATE_TABS[assignmentStateTab],
      "orderBy",
      ""
    );
    sendFilters["orderByDirection"] = _.get(
      ASSIGNMENT_STATE_TABS[assignmentStateTab],
      "orderByDirection",
      ""
    );

    sendFilters["unreadMessageCountFilters"] = {
      includeItemTypes: ["STUDENT_ASSIGNMENT_SUBMISSION"],
    };
  }
  if (folderTopic !== "ALL") {
    sendFilters["folderIds"] = folderTopic;
  }
  return sendFilters;
};

// parent and student filters are managed with this function
export const getFamilyStudentFeedQueryFilters = ({
  filters = {},
  userId,
  userType = "",
  courseId,
  childID,
}) => {
  const {
    assignmentStateTab,
    folderTopic,
    status,
    searchText,
    contentTypes,
    assessmentTypes,
  } = filters;
  const sendFilters = {};

  if (courseId) {
    sendFilters["courseIds"] = courseId;
  }

  if (userType === "parent") {
    sendFilters["studentIds"] = childID;
    sendFilters["userId"] = userId;
    sendFilters["first"] = 10;
  } else if (userType === "student") {
    sendFilters["studentId"] = userId;
  }

  if (searchText != "") {
    sendFilters["searchText"] = searchText;
  }

  //for student web classroom feed if current tab is scheduled fetch tasks based on permission
  if (
    assignmentStateTab == "SCHEDULED" &&
    !ACLStore.can("Common:ShowScheduleTaskInClassroom")
  ) {
    sendFilters["contentTypes"] = ["ASSIGNMENT_MEETING"];
    sendFilters["assessmentTypes"] = [];
  } else {
    if (contentTypes != "ALL") {
      sendFilters["contentTypes"] = [contentTypes];
    }
    if (assessmentTypes != "ALL") {
      sendFilters["assessmentTypes"] = [assessmentTypes];
    }
  }

  if (assignmentStateTab) {
    sendFilters["states"] = [assignmentStateTab];
    sendFilters["orderBy"] = _.get(
      ASSIGNMENT_STATE_TABS[assignmentStateTab],
      "orderBy",
      ""
    );
    sendFilters["orderByDirection"] = _.get(
      ASSIGNMENT_STATE_TABS[assignmentStateTab],
      "orderByDirection",
      ""
    );
  }
  if (folderTopic != "ALL") {
    sendFilters["folderIds"] = folderTopic;
  }
  if (status != "ALL") {
    sendFilters["status"] = status;
  }
  return sendFilters;
};

export const upcomingFeedFilters = {
  type: ["COURSE", "PRIMARY"],
  calendarIds: [],
  folderIds: [],
  showUpcoming: true,
  contentTypes: ["ASSIGNMENT_MEETING", "RECURRING_MEETING"],
};

const importFromUnitOption = {
  key: "IMPORT_LE",
  label: "classRoom:import_unit",
  icon: <ImportOutlined variant={"subtle"} />,
};

//This function returns options array for the dropdown integrated from design library
export const getDropdownOptions = ({
  options,
  t,
  optionsToExcludeInCalendar = [],
}) => {
  const clonedOptions = [];
  _.forEach(options, option => {
    const { label, key, perm, isItemGroup } = option;
    if (isItemGroup) {
      const childOptions = _.get(option, "options", []);
      const clonedChildOptions = _.reduce(
        childOptions,
        (result, childOption) => {
          const { perm } = childOption;
          if (
            (!perm || ACLStore.can(perm)) &&
            !_.includes(
              optionsToExcludeInCalendar,
              _.get(childOption, "key", "")
            )
          ) {
            result.push({ ...childOption, label: t(childOption.label) });
          }
          return result;
        },
        []
      );
      const clonedChildOptionsSize = _.size(clonedChildOptions);
      // if(clonedChildOptionsSize === 1) {
      //   clonedOptions.push(clonedChildOptions[0])
      // }
      // else
      if (clonedChildOptionsSize === 1 && label === "common:import_from") {
        // Edge case: When the subGroup is "Import from" and if it only has one option, then we consider it as an individual item
        clonedOptions.push({
          ...importFromUnitOption,
          label: t(importFromUnitOption.label),
        });
      } else if (clonedChildOptionsSize > 0) {
        clonedOptions.push({
          ...option,
          label: t(label),
          options: clonedChildOptions,
        });
      }
    } else {
      // an item itself
      if (
        (!perm || ACLStore.can(perm)) &&
        (!_.includes(optionsToExcludeInCalendar), key)
      ) {
        clonedOptions.push({
          ...option,
          label: t(label),
          isDisable: false,
        });
      }
    }
  });
  return clonedOptions;
};

const REDUCER_HANDLERS = {
  [INIT_FILTERS]: (state, action) => {
    return update(state, {
      filters: { $set: initialState.filters },
    });
  },

  [UPDATE_FILTERS]: (state, action) => {
    const { key, value } = action.data;
    return update(state, {
      filters: { [key]: { $set: value } },
    });
  },

  [UPDATE_TODO_FILTERS]: (state, action) => {
    const { key, value } = action.data;
    return update(state, {
      todoFilters: { [key]: { $set: value } },
    });
  },

  [UPDATE_COURSE_ID]: (state, action) => {
    return {
      ...state,
      selectedCourseId: action.payload,
    };
  },
  [CHANGE_INSIGHTS_SEARCH_TERM]: (state, action) => {
    return update(state, {
      classRoomInsightsSearchTerm: { $set: action.payload },
    });
  },
  [SET_STUDENT_DETAIL_FILTERS]: (state, action) => {
    return update(state, {
      studentDetailFilters: {
        $set: { ...state.studentDetailFilters, ...action.payload },
      },
    });
  },
  [INIT_STUDENT_DETAIL_FILTERS]: (state, action) => {
    return update(state, {
      studentDetailFilters: { $set: initialState.studentDetailFilters },
    });
  },
  [SET_COURSE_ID]: (state, action) => {
    return update(state, {
      courseId: { $set: action.payload },
    });
  },
};

export const initialState = {
  filters: {
    searchText: "",
    contentTypes: "ALL",
    assessmentTypes: "ALL",
    createdBy: "ALL",
    assignmentStateTab: _.get(ASSIGNMENT_STATE_TABS, "PUBLISHED.value", ""),
    folderTopic: "ALL",
    status: "ALL",
  },
  todoFilters: {
    searchText: "",
    contentTypes: [
      "ASSESSMENT",
      "ASSIGNMENT_RESOURCE",
      "ASSIGNMENT_DISCUSSION",
    ],
    createdBy: "ALL",
    todoActiveTab: "",
    filterDate: "",
    courseIds: [],
    orderBy: "PUBLISHED_AT",
    orderByDirection: "DESC",
  },
  classRoomInsightsSearchTerm: "",
  studentDetailFilters: {
    folderIds: [],
    contentTypes: ["ALL"],
    status: ["ALL"],
    searchText: "",
    assessmentTypes: ["ALL"],
  },
  courseId: null,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
