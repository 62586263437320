import UAParser from "ua-parser-js";

export const getBasicDeviceInfo = () => {
  const parser = new UAParser();
  const { browser, os, device } = parser.getResult();
  const screen = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  return {
    browser,
    os,
    device,
    screen,
  };
};
