import React from "react";

import classes from "./Benchmark.scss";

import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";

import { getOrganizationPypElementSetFromCache } from "modules/CommonGraphqlHelpers";
import { connect } from "react-redux";
import { EmptyField } from "UIComponents";
import SubjectBenchmarks from "./SubjectBenchmarks";
import { mergeSelectedNodes } from "Utils";

import {
  filterSubjectBenchmarkValues,
  getUpdatedBenchmarkSelections,
  filterLevelsArray,
} from "Utils";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import _ from "lodash";

class Benchmark extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  onItemSelected = ({ selections, subject, nodes }) => {
    let {
      props: { value, benchmarkSet },
    } = this;

    const updatedValue = _.uniq([
      ..._.filter(
        value,
        id =>
          !_.includes(
            _.map(nodes, item => item.id),
            id
          )
      ),
      ...(selections || []),
    ]);

    this.updateValue(updatedValue, {
      nodes: nodes,
      subject: subject.subjectId,
    });
  };

  getBenchmarkData = subject => {
    const { benchmarkSet } = this.props;

    const template = _.find(benchmarkSet, item => {
      return item.subject === subject;
    });

    if (template) {
      const rootNodes = _.get(template, "benchmarks.rootNodes", []);
      const levels = _.get(template, "levels", []);
      const nodes = _.keyBy(_.get(template, "benchmarks.nodes"), "id");
      const root = _.get(template, "root", {});
      return { rootNodes, levels, nodes, root };
    }
    return {};
  };

  renderAtls = () => {
    const {
      props: { value, nodes, readOnly },
    } = this;
    //filtered the ATLS with depth 1
    const rootSkills = _.filter(nodes, node => node.depth == 1);

    return (
      <div className={classes.subjectBenchmarkContainer}>
        {_.map(rootSkills, rootSkill => {
          //fetch the nodes which are child or subchild of parent skill
          const skillNodes = _.filter(
            nodes,
            nodeItem => nodeItem.atlCategory.id == rootSkill.atlCategory.id
          );

          const children = rootSkill.children;

          const skillValue = _.map(skillNodes, skillNode => skillNode.id);

          const selectedValue = filterSubjectBenchmarkValues({
            value: value,
            nodes,
            rootNodes: children,
          });

          return _.size(skillValue) > 0 ? (
            <SubjectBenchmarks
              key={rootSkill.id}
              values={skillValue}
              selectedValues={selectedValue}
              subject={{
                value: rootSkill,
                label: rootSkill.label,
                id: rootSkill.id,
                subjectId: rootSkill.parent,
              }}
              nodes={skillNodes}
              rootNodes={children}
              onItemSelected={this.onItemSelected}
              readOnly={readOnly}
            />
          ) : null;
        })}
      </div>
    );
  };

  renderSubjects = subjects => {
    const {
      props: { value, unitPlanBenchmarks, rootNodes, nodes, readOnly },
    } = this;

    return (
      <div className={classes.subjectBenchmarkContainer}>
        {_.map(subjects, (item, key) => {
          const subject = item.benchmarkRootNode || {};

          const subjectLevelId = _.get(subject, "levelId", "");
          const levels = _.reduce(
            _.get(subject, "levels", []),
            (result, level) => {
              if (level.id !== subjectLevelId) {
                result.push(level);
              }
              return result;
            },
            []
          );

          const subjectNodes = _.filter(
            nodes,
            nodeItem => nodeItem.subject == item.id
          );

          const children = _.map(
            _.filter(subjectNodes, item => item.depth == 1),
            node => node.id
          );
          const subjectValue = _.map(
            subjectNodes,
            subjectItem => subjectItem.id
          );

          const subjectSelectedValue = filterSubjectBenchmarkValues({
            value: value,
            nodes,
            rootNodes: children,
          });

          return _.get(subjectValue, `length`, 0) > 0 ? (
            <SubjectBenchmarks
              key={item.id}
              values={subjectValue}
              selectedValues={subjectSelectedValue}
              subject={{
                value: subject,
                label: subject.label,
                id: subject.id,
                subjectId: subject.subject,
              }}
              levels={levels}
              nodes={subjectNodes}
              rootNodes={children}
              onItemSelected={this.onItemSelected}
              readOnly={readOnly}
            />
          ) : null;
        })}
      </div>
    );
  };

  shouldShowEditEmpty = () => {
    const {
      props: { nodes, subjects, fieldKey },
    } = this;

    switch (fieldKey) {
      case "atls":
        return _.size(nodes) == 0;
      default:
        return _.size(subjects) == 0 || _.size(nodes) == 0;
    }
  };

  renderEditEmpty = () => {
    const { emptyText } = this.props;
    return <EmptyField title={emptyText} />;
  };

  renderComponent = ({ fieldKey, subjects }) => {
    switch (fieldKey) {
      case "atls":
        return this.renderAtls();
      default:
        return this.renderSubjects(subjects);
    }
  };

  renderEdit = () => {
    const {
      props: { subjects, fieldKey },
    } = this;
    return (
      <div className={classes.mainContainer}>
        {this.renderComponent({ subjects, fieldKey })}
      </div>
    );
  };
}

const mergeSelectedNodesMemoize = _.memoize(
  params => mergeSelectedNodes(params),
  params => JSON.stringify(params)
);
const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const options = ownProps.options;
  const subjects = _.get(options, "subjects", []);
  const grades = _.get(options, "grades", "");
  const unitPlanBenchmarks = _.get(options, "unitPlanBenchmarks", []);
  const organizationId = state.login.userInfo.org_id;

  const nodes = mergeSelectedNodesMemoize({
    nodes: _.get(options.benchmarkSet, "nodes", []),
    selectedNodes: _.get(ownProps, "resolvedValue.benchmarks", []),
  });

  const rootNodes = _.get(options.benchmarkSet, "rootNodes", []);

  return {
    benchmarkSet: { rootNodes, nodes },
    subjects,
    unitPlanBenchmarks,
    nodes,
    rootNodes,
  };
};

export default connect(mapStateToProps, mapActionCreators)(Benchmark);
