import React from "react";
import { UIModal, I18nHOC } from "UIComponents";
import classes from "./ApprovalRequestSentModal.scss";
import { Button } from "@toddle-design/web";
import { TickCircleOutlined } from "@toddle-design/web-icons";
import { compose } from "react-apollo";
import classNames from "classnames";

const ApprovalRequestSentModal = ({ t, student, onClose }) => {
  const subHeaderStyles = classNames({
    "text-subtitle-2": true,
    [classes.subHeaderWrapper]: true,
  });

  const headerStyles = classNames({
    "heading-3": true,
    [classes.headerWrapper]: true,
  });

  return (
    <UIModal isOpen={true} modalContent={classes.modalContent}>
      <div className={classes.container}>
        <div className={classes.iconWrapper}>
          <TickCircleOutlined
            style={{ width: "56px", height: "56px" }}
            size="small"
            variant="warning"
          />
        </div>
        <div className={headerStyles}>{t("common:login_successful")}</div>
        <div className={subHeaderStyles}>
          {t("common:family_approval_request", {
            label: student?.name,
          })}
        </div>
        <div className={classes.btnWrapper}>
          <Button
            onClick={onClose}
            isFullWidth={true}
            className={classes.doneBtn}
          >
            {t("common:done")}
          </Button>
        </div>
      </div>
    </UIModal>
  );
};

export default compose(I18nHOC({ resource: ["integration", "common"] }))(
  ApprovalRequestSentModal
);
