import React, { useReducer, useState } from "react";
import classes from "./Header.scss";
import { ToddleIcon } from "SvgComponents";
import {
  SettingsOutlined,
  ChevronLeftOutlined,
} from "@toddle-design/web-icons";
import { colors } from "Constants";
import { useI18n } from "Hooks";
import { onAddFirstRow } from "NodeEditor/Utils";
import {
  DialogueBox,
  FullScreenLoader,
  UIButton,
  ButtonDropdown,
  LinkWithTooltip,
} from "UIComponents";
import ACLStore from "lib/aclStore";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";
import { Tooltip } from "@toddle-design/web";
import { disableEditForMapleBearChildOrg } from "Courses/modules/utils";

const styles = {
  buttonContainerStyle: {
    margin: "0px 0px 0px 16px",
  },
  containerStyle: {
    zIndex: 14,
  },
};

const getDialogueBoxData = ({ t, type, headerLabel }) => {
  switch (type) {
    case "DRAFT_CONFIRMATION":
      return {
        modalTitle: t("scopeAndSequence:unpublished_changes"),
        modalBody: t("scopeAndSequence:unpublished_changes_msg", {
          subject: headerLabel,
        }),
        confirmText: t("common:retain_changes"),
        cancelText: t("common:discard_changes"),
        button2Props: { color: "blue" },
        modalBodyStyle: {
          paddingTop: "24px",
          paddingRight: "48px",
        },
        footerContainerStyle: {
          paddingRight: "48px",
        },
        name: type,
        showCloseButton: true,
      };
    case "RECONFIGURE":
      return {
        modalTitle: t("scopeAndSequence:reconfigure_subject_standards"),
        modalBody: (
          <div>
            <div
              style={{
                color: `${colors.jaguar}`,
                margin: "0px 0px 24px 0px",
              }}
            >
              {t("scopeAndSequence:reconfigure_warning_msg", {
                subject: headerLabel,
              })}
            </div>
            <div
              style={{
                color: `${colors.yellow50}`,
                margin: "0px 0px 24px 0px",
              }}
            >
              {t("scopeAndSequence:reconfigure_warning_msg_desc")}
            </div>
          </div>
        ),
        button2Props: { color: "pink" },
        confirmText: t("scopeAndSequence:reconfigure"),
        cancelText: t("common:cancel"),
        dialogueContentClass: classes.dialogueBoxContent,
        name: type,
        showCloseButton: true,
      };
    case "EDIT_TO_VIEW_CONFIRM":
      return {
        modalTitle: t("common:save_exit_small"),
        modalBody: (
          <div className={classes.saveDraftContainer}>
            <div>{t("scopeAndSequence:exit_from_draft_text_one")}</div>
            {t("scopeAndSequence:exit_from_draft_text_two")}
          </div>
        ),
        button2Props: { color: "blue" },
        confirmText: t("common:okay"),
        cancelText: t("common:cancel"),
        dialogueContentClass: classes.dialogueBoxContentEditToConfirm,
        name: type,
        showCloseButton: false,
      };
    default:
      console.error("Other Case");
  }
};
const renderMenuItems = t => {
  const MENU_ITEMS = [
    {
      label: t("scopeAndSequence:reconfigure_standards"),
      value: "RECONFIGURE",
    },
    {
      label: t("scopeAndSequence:rename_hierarchy_levels"),
      value: "EDIT_HIERARCHY",
    },
  ];
  if (ACLStore.can("AdminPortal:SnSAppendBulkUpload")) {
    MENU_ITEMS.push({
      label: t("scopeAndSequence:append_standards"),
      value: "APPEND",
    });
  }
  return MENU_ITEMS;
};
const Header = props => {
  const { t } = useI18n(["common", "scopeAndSequence"]);
  const {
    mode,
    onChangeMode,
    goToBack,
    headerLabel,
    description,
    draftId,
    createMultiLevelNodesDraft,
    deleteMultiLevelNodesDraft,
    subjectId,
    nodeType,
    nodes,
    rootNodes,
    inputForMultiLevelNodeQuery,
    nodesObjMap,
    setNodes,
    currentEditorConfig,
    goToRelativeRoute,
    updateSnsSettingsAction,
    isSetPreview,
    onBackClick,
    onClickChoose,
    publishMultiLevelNodeDraft,
    userId,
    rootNodeId,
    showEditButton,
    setToastMsg,
    customOnSettingsItemClick,
    isEditButtonDisabled,
    disableToolTipMsg,
    plannerElementSetConfig,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const reducer = (prevState, action) => {
    if (action != "CLOSE_DIALOGUE") {
      return getDialogueBoxData({ t, type: action, headerLabel });
    } else {
      return {};
    }
  };
  //we are using useReducer to manage dialogueBoxes state and data
  const [currentDialogueBoxData, dispatchDialogue] = useReducer(reducer, {});

  //This function checks if there are any errors related to the editor.
  const checkIsValid = () => {
    let isValid = true;
    const errorCheckFields = _.get(currentEditorConfig, "errorCheckFields", []);
    let nodeToScrollTo;
    const newNodes = { ...nodesObjMap };
    _.forOwn(newNodes, (value, key) => {
      if (value.parentId) {
        for (const field of errorCheckFields) {
          const currentFieldValue = value[field];
          if (_.isEmpty(currentFieldValue) && value.depth != 0) {
            newNodes[key] = { ...value, isError: true };
            isValid = false;
            if (!nodeToScrollTo) nodeToScrollTo = key;
            break;
          }
          newNodes[key] = { ...value, isError: false };
        }
      }
    });
    if (isValid) {
      return true;
    } else {
      setNodes(newNodes);
      const node = document.getElementById(nodeToScrollTo);
      if (node) node.scrollIntoView({ block: "center" });
      return false;
    }
  };

  const toggleMode = async () => {
    if (mode == "view") {
      if (!_.isEmpty(draftId)) {
        //if there is any draftId present, we show the confirmation dialogueBox
        dispatchDialogue("DRAFT_CONFIRMATION");
      } else {
        //if the subject dont have draft, we create a new draft for this subject
        const input = {
          subjectId,
          nodeType: nodeType,
          nodes,
          rootNodes,
        };
        setIsLoading(true);

        const result = await createMultiLevelNodesDraft({
          input,
          inputForMultiLevelNodeQuery,
        });
        setIsLoading(false);
        if (result) {
          //if the draft creation is successful we switch into editing mode
          onChangeMode();
        }
      }
    } else if (mode == "edit") {
      dispatchDialogue("EDIT_TO_VIEW_CONFIRM");
    }
  };

  const showSettingsButton =
    !isSetPreview && showEditButton && !disableEditForMapleBearChildOrg();

  const btnText =
    mode === "view" ? t("common:edit") : t("common:save_as_draft");

  const goBack = () => {
    if (isSetPreview) {
      onBackClick();
    } else {
      if (onBackClick) onBackClick();
      else goToBack();
    }
  };

  const closeDialogueBox = () => {
    dispatchDialogue("CLOSE_DIALOGUE");
  };

  const onClickButton1 = async () => {
    const { name } = currentDialogueBoxData;
    switch (name) {
      case "DRAFT_CONFIRMATION":
        {
          //delete the existing draft for this subject and create a new draft for this subject
          const input = { draftId };
          setIsLoading(true);
          const isDeleteDraftSuccess = await deleteMultiLevelNodesDraft(input);
          if (isDeleteDraftSuccess) {
            const inputForCreateDraft = {
              subjectId,
              nodeType: nodeType,
              nodes,
              rootNodes,
            };
            const isNodeDataEmpty = _.size(nodes) < 2; // Size 0 or 1 means there are no nodes
            if (isNodeDataEmpty) {
              // create one dummy node as a draft
              const newNodes = onAddFirstRow({
                rootNodeId,
                nodes: _.keyBy(nodes, "id"),
                setNodes,
                userId,
              });
              inputForCreateDraft["nodes"] = newNodes;
            }

            const result = await createMultiLevelNodesDraft({
              input: inputForCreateDraft,
              inputForMultiLevelNodeQuery,
            });
            setIsLoading(false);
            if (result) {
              onChangeMode();
            }
          }
        }
        break;
      default:
        console.error("other case");
    }
  };

  const onSettingsItemClick = value => {
    if (value === "RECONFIGURE") {
      dispatchDialogue(value);
      return;
    }

    updateSnsSettingsAction(value); // updates redux with snsSettingsAction for showUpload and showEditLevels pages
    if (customOnSettingsItemClick) {
      // in case of planner elements this function is given to handle menu item navigation
      customOnSettingsItemClick({ action: value });
    } else {
      goToRelativeRoute({ route: "../" });
    }
  };

  const onClickButton2 = async () => {
    const { name } = currentDialogueBoxData;
    switch (name) {
      case "DRAFT_CONFIRMATION":
        {
          onChangeMode();
        }
        break;
      case "RECONFIGURE":
        //open the reconfigure frame that will render in MultiNodeLevelEditor.js
        {
          updateSnsSettingsAction(name);
          if (customOnSettingsItemClick) {
            customOnSettingsItemClick({ action: name });
          } else {
            goToRelativeRoute({ route: "../" });
          }
        }
        break;
      case "EDIT_TO_VIEW_CONFIRM":
        {
          //from edit to view mode
          onChangeMode();
        }
        break;
      default:
        console.error("other case");
    }
  };

  const onClickPublish = async () => {
    const isValid = checkIsValid();
    if (isValid) {
      setIsLoading(true);
      const isDraftPublished = await publishMultiLevelNodeDraft({
        draftId,
        inputForMultiLevelNodeQuery,
        plannerElementSetId: plannerElementSetConfig.id,
      });

      if (isDraftPublished) {
        onChangeMode("view");
      }
      setIsLoading(false);
    } else {
      setToastMsg({ msg: "toastMsgs:sns_errors_on_publish" });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <div className={classes.leftContent}>
          <div className={classes.goBackDiv} onClick={goBack}>
            <div className={classes.chevronIcon}>
              <ChevronLeftOutlined variant={"subtle"} size={"xx-small"} />
            </div>
            <div className={classes.toddleIcon}>
              <ToddleIcon />
            </div>
          </div>
          <div className={classes.headings}>
            <div className={classes.heading}>{headerLabel}</div>
            <div className={classes.subHeading}>{description}</div>
          </div>
        </div>
        <div className={classes.rightContent}>
          {showSettingsButton && (
            <>
              <div
                id={"COACHMARKS_SCOPE_SEQUENCE_VIEWER_PREFERENCES_BUTTON"}
                className={classes.preferencesContainer}
              >
                <ButtonDropdown
                  authTabs={renderMenuItems(t)}
                  buttonComponent={
                    <SettingsOutlined
                      size={"small"}
                      disabled={isEditButtonDisabled}
                    />
                  }
                  onItemClick={onSettingsItemClick}
                  containerStyle={styles.containerStyle}
                  disabled={isEditButtonDisabled}
                />
              </div>
              <div
                id={
                  mode === "view"
                    ? "COACHMARKS_SCOPE_SEQUENCE_VIEWER_EDIT_BUTTON"
                    : ""
                }
              >
                {isEditButtonDisabled ? (
                  <LinkWithTooltip
                    tooltip={disableToolTipMsg}
                    placement={"bottom"}
                    isVisible={!_.isEmpty(disableToolTipMsg)}
                  >
                    <UIButton
                      color="pink"
                      onClick={toggleMode}
                      size={"sm"}
                      isDisabled={isEditButtonDisabled}
                    >
                      {btnText}
                    </UIButton>
                  </LinkWithTooltip>
                ) : (
                  <UIButton color="pink" onClick={toggleMode} size={"sm"}>
                    {btnText}
                  </UIButton>
                )}
              </div>
            </>
          )}
          {isSetPreview && (
            <UIButton size={"sm"} color={"pink"} onClick={onClickChoose}>
              {t("common:choose")}
            </UIButton>
          )}
          {!isSetPreview && mode === "edit" && showEditButton && (
            <div id={"COACHMARKS_SCOPE_SEQUENCE_EDITOR_PUBLISH_BUTTON"}>
              <UIButton
                size={"sm"}
                color={"blue"}
                containerStyle={styles.buttonContainerStyle}
                onClick={onClickPublish}
              >
                {t("common:save_with_label", {
                  label: t("common:lowercase", {
                    text: t("common:and_label") + t("common:publish"),
                  }),
                })}
              </UIButton>
            </div>
          )}
        </div>
      </div>
      {!_.isEmpty(currentDialogueBoxData) && (
        <DialogueBox
          customModalContentClass={currentDialogueBoxData.dialogueContentClass}
          modalTitle={currentDialogueBoxData.modalTitle}
          showModal={true}
          onClickButton1={onClickButton1}
          onClickButton2={onClickButton2}
          modalBody={currentDialogueBoxData.modalBody}
          button1Props={currentDialogueBoxData.button1Props}
          button2Props={currentDialogueBoxData.button2Props}
          toggleDialogueBoxDisplay={closeDialogueBox}
          button1={currentDialogueBoxData.cancelText}
          button2={currentDialogueBoxData.confirmText}
          modalBodyStyle={currentDialogueBoxData.modalBodyStyle}
          footerContainerStyle={currentDialogueBoxData.footerContainerStyle}
          showCloseButton={currentDialogueBoxData.showCloseButton}
        />
      )}
      {isLoading && <FullScreenLoader />}
    </div>
  );
};

const mapActionCreators = { setToastMsg };

export default connect(null, mapActionCreators)(Header);
