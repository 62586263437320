import _ from "lodash";
import update from "immutability-helper";
import client from "apolloClient";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  updateOrganizationAttendanceOptionMutation,
  deleteOrganizationAttendanceOptionMutation,
  createOrganizationAttendanceOptionMutation,
  setCurriculumProgramAttendanceRecordingTypeMutation,
} from "AttendanceSetup/modules/Mutations";
import { writeOrganizationAttendanceOptionsToCache } from "AttendanceSetup/modules/GraphqlHelpers";
import { getCurriculumAttendanceOptionsQueryFromCache } from "modules/CommonGraphqlHelpers";
import {
  getOrganizationCurriculumProgramsQuery,
  getOrganizationDetailsQuery,
} from "modules/CommonQuery";
import { getCurrentAcademicYear } from "modules/Services";
import { colors } from "Constants";

// Constants
export const NAME = "attendanceSetup";
export const UPDATE_ATTENDANCE_OPTION = "UPDATE_ATTENDANCE_OPTION" + " " + NAME;

export const RECORDING_MODE_OPTIONS = [
  {
    label: "attendance:once_a_day",
    value: "ONCE_A_DAY",
  },
  {
    label: "attendance:for_each_period",
    value: "EACH_PERIOD",
  },
];

// Actions creators

export const updateLocalAttendanceOption = value => {
  return { type: UPDATE_ATTENDANCE_OPTION, payload: value };
};

export const createAttendanceOption = ({
  currentCurriculumProgramId: curriculumId,
  attendanceOptionsSetId,
  academicYearId,
}) => {
  return async (dispatch, getState) => {
    const {
      color,
      label,
      abbreviation,
      sendSMS,
      sendEmail,
    } = getState().attendanceSetup.attendanceOption;
    const organizationId = getState().login.userInfo.org_id;

    try {
      await client.mutate({
        mutation: createOrganizationAttendanceOptionMutation,
        variables: {
          input: {
            color,
            label,
            abbreviation,
            sendSMS,
            sendEmail,
            attendanceOptionsSetId,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { createAttendanceOption },
            },
          }
        ) => {
          if (!_.isEmpty(createAttendanceOption)) {
            const attendanceOptionsData = getCurriculumAttendanceOptionsQueryFromCache(
              {
                id: organizationId,
                curriculumId,
                filter: {
                  academicYearId,
                },
              }
            );
            const curriculumPrograms = _.get(
              attendanceOptionsData,
              "node.curriculumPrograms",
              []
            );

            const updatedCurriculumAttendanceOptions = _.map(
              curriculumPrograms,
              value => {
                if (
                  attendanceOptionsSetId ===
                  _.get(value, "attendanceOptionSet.id", null)
                ) {
                  return update(value, {
                    attendanceOptionSet: {
                      attendanceOptions: {
                        $push: [createAttendanceOption],
                      },
                    },
                  });
                }
              }
            );

            writeOrganizationAttendanceOptionsToCache({
              id: organizationId,
              data: update(attendanceOptionsData, {
                node: {
                  curriculumPrograms: {
                    $set: updatedCurriculumAttendanceOptions,
                  },
                },
              }),
            });

            dispatch(
              setToastMsg({
                msg: "toastMsgs:successfully_with_label",
                locale_params: [
                  {
                    key: "label",
                    value: "toastMsgs:added",
                    isPlainText: false,
                  },
                ],
                type: "tick",
              })
            );
          } else {
            dispatch(setToastMsg("toastMsgs:something_went_wrong"));
          }
        },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const updateAttendanceOption = () => {
  return async (dispatch, getState) => {
    const {
      id,
      color,
      label,
      abbreviation,
      sendSMS,
      sendEmail,
    } = getState().attendanceSetup.attendanceOption;

    try {
      await client.mutate({
        mutation: updateOrganizationAttendanceOptionMutation,
        variables: {
          input: { id, color, label, abbreviation, sendSMS, sendEmail },
        },
      });

      dispatch(
        setToastMsg({
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:updated", isPlainText: false },
          ],
          type: "tick",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const deleteAttendanceOption = ({
  id,
  currentCurriculumProgramId: curriculumId,
  attendanceOptionsSetId,
  academicYearId,
}) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;

    try {
      await client.mutate({
        mutation: deleteOrganizationAttendanceOptionMutation,
        variables: {
          input: { id },
        },
        update: (
          cache,
          {
            data: {
              platform: { deleteAttendanceOption },
            },
          }
        ) => {
          if (deleteAttendanceOption) {
            const attendanceOptionsData = getCurriculumAttendanceOptionsQueryFromCache(
              {
                id: organizationId,
                curriculumId,
                filter: {
                  academicYearId,
                },
              }
            );

            const curriculumPrograms = _.get(
              attendanceOptionsData,
              "node.curriculumPrograms",
              []
            );
            const curriculumProgram = _.head(curriculumPrograms);

            const optionIndex = _.findIndex(
              _.get(
                curriculumProgram,
                "attendanceOptionSet.attendanceOptions",
                []
              ),
              option => {
                return option.id == id;
              }
            );
            const updatedCurriculumAttendanceOptions = _.map(
              curriculumPrograms,
              value => {
                if (
                  attendanceOptionsSetId ===
                  _.get(value, "attendanceOptionSet.id", null)
                ) {
                  return update(value, {
                    attendanceOptionSet: {
                      attendanceOptions: {
                        $splice: [[optionIndex, 1]],
                      },
                    },
                  });
                }
              }
            );

            writeOrganizationAttendanceOptionsToCache({
              id: organizationId,
              data: update(attendanceOptionsData, {
                node: {
                  curriculumPrograms: {
                    $set: updatedCurriculumAttendanceOptions,
                  },
                },
              }),
            });

            dispatch(
              setToastMsg({
                msg: "toastMsgs:successfully_with_label",
                locale_params: [
                  {
                    key: "label",
                    value: "toastMsgs:deleted",
                    isPlainText: false,
                  },
                ],
                type: "tick",
              })
            );
          } else {
            dispatch(setToastMsg("toastMsgs:something_went_wrong"));
          }
        },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const setCurriculumProgramAttendanceRecordingType = ({
  organizationId,
  curriculumProgramId,
  attendanceRecordingType,
}) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: setCurriculumProgramAttendanceRecordingTypeMutation,
        variables: {
          curriculumProgramId,
          attendanceRecordingType,
        },
        refetchQueries: [
          {
            query: getOrganizationDetailsQuery,
            variables: { id: organizationId },
          },
        ],
      });
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

// Reducer Handlers
const REDUCER_HANDLERS = {
  [UPDATE_ATTENDANCE_OPTION]: (state, action) => {
    return update(state, { attendanceOption: { $set: action.payload } });
  },
};

// Initial State and export Reducer
const initialState = {
  attendanceOption: {
    id: "",
    color: null,
    abbreviation: "",
    isDefault: false,
    label: "",
    sendSMS: false,
    sendEmail: false,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
