import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import client from "apolloClient";
import { updateField } from "modules/Services";
import {
  writeUserInfoToCache,
  getUserInfoFromCache,
  updateCommunityActivityInCache,
  updateSchoolTenureInCache,
  writeGlobalSchoolsInCache,
  getGlobalSchoolsFromCache,
  getCommunityTagsFromCache,
  writeCommunityTagsInCache,
} from "./OnBoardingGraphqlHelpers";
import {
  updateUserBasicInfoMutation,
  handleMultipleUserTagMutation,
  addProfileEntityMutation,
  updateWorkshopOrTrainingMutation,
  removeCommunityActivityFromUserMutation,
  registerUserForCommunityMutation,
  removeCommunitySchoolUserTenureMutation,
  addCommunitySchoolUserTenureMutation,
  changeCommunitySchoolUserTenureMutation,
  addSchoolInCommunityMutation,
  handleCommunityUserGlobalGradeMappingMutation,
  createNewTagMutation,
} from "./OnBoardingMutations";
import * as EventTracker from "lib/eventTracker";
import { updateCoachMarkStatus } from "modules/CoachMarksModule";
import moment from "moment";
import { updateCommunityUserDetails } from "Community/modules/CommunityModule";

export const NAME = "communityOnboarding";

export const TAG_MAPPING = {
  interests: { type: "INTEREST" },
  topics: { type: "TOPIC" },
  skills: { type: "SKILL" },
};

export const ENTITY_TYPE_MAPPING = {
  TRAINING: {
    key: "trainings",
    removeMutation: removeCommunityActivityFromUserMutation,
    addMutation: addProfileEntityMutation,
    userTraitsKey: "community_certificates_count",
  },
  WORKSHOP: {
    key: "workshops",
    removeMutation: removeCommunityActivityFromUserMutation,
    addMutation: addProfileEntityMutation,
    userTraitsKey: "community_workshops_counts",
  },
  CURRENT_PROFESSION: {
    key: "currentSchools",
    removeMutation: removeCommunitySchoolUserTenureMutation,
    addMutation: addCommunitySchoolUserTenureMutation,
    userTraitsKey: "community_current_schools_count",
  },
  PAST_PROFESSION: {
    key: "pastSchools",
    removeMutation: removeCommunitySchoolUserTenureMutation,
    addMutation: addCommunitySchoolUserTenureMutation,
    userTraitsKey: "community_past_schools_count",
  },
};
// CONSTANTS
export const UPDATE_ONBOARDING_OBJECT = `UPDATE_ONBOARDING_OBJECT ${NAME}`;
export const UPDATE_FILTERS = "UPDATE_COMMUNITY_FILTERS" + " " + NAME;
// ACTIONS CREATORS

export const updateFilters = data => {
  return { type: UPDATE_FILTERS, data };
};

export const createGlobalSchoolOption = ({ searchText, option }) => {
  return async (dispatch, getState) => {
    const data = getGlobalSchoolsFromCache({ searchText });
    try {
      const { name, id } = option || {};
      const userId = getState().login.userInfo.id;
      writeGlobalSchoolsInCache({
        searchText,
        data: {
          ...(data || {}),
          community: {
            ...(_.get(data, "community", {}) || {}),
            constants: {
              ...(_.get(data, "community.constants", {}) || {}),
              schools: {
                ...(_.get(data, "community.constants.schools", {}) || {}),
                totalCount:
                  _.get(data, "community.constants.schools.totalCount", 0) + 1,
                edges: [
                  ...(_.get(data, "community.constants.schools.edges", {}) ||
                    []),
                  {
                    node: { ...(option || {}), __typename: "CommunitySchool" },
                    __typename: "CommunitySchoolEdge",
                  },
                ],
              },
            },
          },
          __typename: "CommunityQuery",
        },
      });
      const response = await client.mutate({
        mutation: addSchoolInCommunityMutation,
        variables: {
          name: name,
          user: userId,
        },
      });
      const newOption = _.get(
        response,
        "data.community.addSchoolInCommunity",
        {}
      );

      writeGlobalSchoolsInCache({
        searchText,
        data: {
          ...(data || {}),
          community: {
            ...(_.get(data, "community", {}) || {}),
            constants: {
              ...(_.get(data, "community.constants", {}) || {}),
              schools: {
                ...(_.get(data, "community.constants.schools", {}) || {}),
                edges: [
                  ..._.filter(
                    _.get(data, "community.constants.schools.edges", []) || [],
                    item => item.id != id
                  ),
                  {
                    node: { newOption },
                    __typename: "CommunitySchoolEdge",
                  },
                ],
              },
            },
          },
          __typename: "CommunityQuery",
        },
      });

      return newOption;
    } catch (e) {
      writeGlobalSchoolsInCache({
        searchText,
        data: data,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      console.error(e);
    }
  };
};

export const updateOnBoardingObject = ({
  updatedData = {},
  extraParams,
  userData,
  updateMutation = true,
}) => {
  return async (dispatch, getState) => {
    let [key, value] = Object.entries(updatedData)[0];
    const userId = getState().login.userInfo.id;

    let isDebounce = !_.includes(
      ["communityDetails.onboardingStatus", "grades"],
      key
    );
    if (extraParams) {
      const updatedValue = [];
      _.forEach(value, val => {
        const match = _.find(extraParams, item => item.value === val);
        if (match) updatedValue.push(match);
      });
      updatedData = {
        [key]: updatedValue,
      };
    }

    let data = _.cloneDeep(userData);
    Object.keys(updatedData).forEach(key =>
      _.setWith(data, key, updatedData[key])
    );

    updateUser({
      userId,
      data: {
        node: update(data, {
          communityDetails: { $set: data.communityDetails },
        }),
      },
    });

    const tagKeys = ["interests", "topics", "skills"];
    if (tagKeys.includes(key)) {
      const oldValues = _.map(_.get(userData, key), "value");
      isDebounce = false;
      updatedData = {
        extraParams,
        value,
        fieldKey: key,
        addTagMapping: _.difference(value, oldValues),
        removeTagMapping: _.difference(oldValues, value),
        returnTagTypes: _.get(TAG_MAPPING, [key, "type"], undefined),
      };
    }
    if (key == "grades") {
      const oldValues = _.map(_.get(userData, key), "value");
      updatedData = {
        fieldKey: key,
        valuesToAdd: _.difference(value, oldValues),
        valuesToRemove: _.difference(oldValues, value),
      };
    }

    isDebounce = false;
    if (updateMutation) {
      await dispatch(
        updateField({
          key: userId,
          params: { ...updatedData, id: userId },
          type: "COMMUNITY_ONBOARDING",
          isDebounce,
        })
      );
    }
  };
};

export const updateUserInfoInCache = ({ userId, data }) => {
  return (dispatch, getState) => {
    updateUser({ userId, data });
  };
};

export const updateBasicInfo = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateUserBasicInfoMutation,
        variables: {
          ...params,
        },
      });
      if (_.has(params, "aboutMe")) {
        EventTracker.recordTrait({
          data: {
            community_bio: !!params["aboutMe"],
          },
        });
        if (!!params["aboutMe"]) {
          dispatch(
            updateCoachMarkStatus({
              type: "COMMUNITY_PROFILE_UPDATE",
              portalType: "COMMUNITY",
            })
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateUserGlobalGrades = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: handleCommunityUserGlobalGradeMappingMutation,
        variables: {
          ...params,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateTagsAnalytics = params => {
  return async (dispatch, getState) => {
    const { returnTagTypes, value, extraParams } = params || {};

    let userTraitsKey = null;
    switch (returnTagTypes) {
      case "INTEREST":
        userTraitsKey = "community_passionate";
        break;
      case "TOPIC":
        userTraitsKey = "community_topic_interested";
        break;
      case "SKILL":
        userTraitsKey = "community_topic_skills";
        break;
    }

    if (!!userTraitsKey) {
      EventTracker.recordTrait({
        data: {
          [userTraitsKey]: _.map(
            _.filter(extraParams, item => _.includes(value, item.id)),
            option => option.label
          ),
        },
      });
    }
  };
};

export const handleMultipleUserTag = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: handleMultipleUserTagMutation,
        variables: {
          ...params,
        },
      });
      dispatch(updateTagsAnalytics(params));
    } catch (e) {
      console.error(e);
    }
  };
};

export const registerUserForCommunity = params => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().login.userInfo.id;
      await client.mutate({
        mutation: registerUserForCommunityMutation,
        variables: {
          user: userId,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const createNewTag = params => {
  return async (dispatch, getState) => {
    try {
      const types = _.get(params, "type", "SKILL");
      const key = _.get(params, "key", "skills");
      const result = await client.mutate({
        mutation: createNewTagMutation,
        variables: {
          ...params,
        },
        update: (
          cache,
          {
            data: {
              community: { createTag: newTag },
            },
          }
        ) => {
          newTag["value"] = newTag["id"];
          let queryData = getCommunityTagsFromCache({ types });
          const oldTags =
            _.get(queryData, `community.constants.${key}`, []) || [];
          queryData = update(queryData, {
            community: {
              constants: { [key]: { $set: [...oldTags, newTag] } },
            },
          });
          writeCommunityTagsInCache({ data: queryData, types });
        },
      });
      return _.get(result, "data.community.createTag", {});
    } catch (err) {
      console.error(err);
    }
  };
};

export const updateTagForUser = (newTag, { userId }) => {
  return async (dispatch, getState) => {
    try {
      const userData = getUserInfoFromCache({
        id: userId,
      });
      updateUser({
        userId,
        data: {
          node: update(userData, {
            skills: {
              $set: [..._.get(userData, "skills", []), newTag],
            },
          }),
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export const addProfileEntityCountAnalytics = ({ count, userTraitsKey }) => {
  return async (dispatch, getState) => {
    if (!!userTraitsKey) {
      EventTracker.recordTrait({
        data: {
          [userTraitsKey]: count,
        },
      });
    }
  };
};

export const addProfileEntity = params => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().login.userInfo.id;
      const userData = getUserInfoFromCache({
        id: userId,
      });
      const { key, addMutation, userTraitsKey } =
        _.get(ENTITY_TYPE_MAPPING, params.field, {}) || {};

      const response = await client.mutate({
        mutation: addMutation,
        variables: {
          ...params,
          user: userId,
        },
        update: (
          cache,
          {
            data: {
              community: { addProfileEnity },
            },
          }
        ) => {
          if (!!addProfileEnity && !_.isEmpty(addProfileEnity)) {
            updateUser({
              userId,
              data: {
                node: update(userData, {
                  [key]: {
                    $set: [
                      ...(_.get(userData, key, []) || []),
                      addProfileEnity,
                    ],
                  },
                }),
              },
            });
          }
        },
      });
      dispatch(
        addProfileEntityCountAnalytics({
          userTraitsKey,
          count: _.get(userData, `${key}.length`, 0) + 1,
        })
      );
      return response;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      console.error(e);
    }
  };
};

export const removeProfileEntity = (params, type) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const userData = getUserInfoFromCache({
      id: userId,
    });

    const entityId = _.get(params, "id", "");
    try {
      const { key, removeMutation, userTraitsKey } =
        _.get(ENTITY_TYPE_MAPPING, params.field, {}) || {};

      updateUser({
        userId,
        data: {
          node: update(userData, {
            [key]: {
              $set: _.filter(
                _.get(userData, key, []),
                item => item.id != entityId
              ),
            },
          }),
        },
      });
      await client.mutate({
        mutation: removeMutation,
        variables: {
          user: userId,
          activity: entityId,
          id: entityId,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:action_successfully",
          locale_params: [
            {
              key: "label",
              value: `${
                type === "EXPERIENCE"
                  ? "community:teaching_experience"
                  : "community:workshop_certification"
              }`,
              isPlainText: false,
            },
            { key: "action", value: "toastMsgs:deleted", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
      dispatch(
        addProfileEntityCountAnalytics({
          userTraitsKey,
          count: _.get(userData, `${key}.length`, 0) - 1,
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      updateUser({
        userId,
        data: {
          node: userData,
        },
      });
      console.error(e);
    }
  };
};

export const updateWorkshopOrTraining = params => {
  return async (dispatch, getState) => {
    try {
      return await client.mutate({
        mutation: updateWorkshopOrTrainingMutation,
        variables: {
          ...params,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const updateProfileActivityEntity = ({
  field,
  oldValue,
  updatedValue,
  extraParams,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;

    const { key: fieldKey } = _.get(ENTITY_TYPE_MAPPING, field, {}) || {};
    let [key, value] = Object.entries(updatedValue)[0];

    if (key == "workshopCategory") {
      const workshopCategory = updatedValue["workshopCategory"] || {};
      updatedValue["workshopCategory"] = !_.isEmpty(workshopCategory)
        ? {
            id: workshopCategory.value,
            name: workshopCategory.label,
            __typename: "WorkshopCategory",
          }
        : null;

      updatedValue["category"] = _.get(
        updatedValue,
        "workshopCategory.id",
        undefined
      );
    }
    let newValue = {
      ...(oldValue || {}),
      ...(updatedValue || {}),
    };

    updateCommunityActivityInCache({
      id: _.get(oldValue, "id", ""),
      data: newValue,
    });
    newValue =
      field == "TRAINING"
        ? _.omit(newValue, ["category", "workshopCategory"])
        : newValue;

    dispatch(
      updateField({
        key: userId,
        params: {
          ...(updatedValue || {}),
          id: _.get(oldValue, "id", ""),
          fieldKey,
          user: userId,

          isInvalid:
            _.filter(
              Object.values(newValue),
              val => val == undefined || !val || val == ""
            ).length > 0,
        },
        type: "COMMUNITY_ONBOARDING",
        isDebounce: true,
      })
    );
  };
};
export const changeCommunitySchoolUserTenure = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: changeCommunitySchoolUserTenureMutation,
        variables: {
          ...params,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateSchoolTenure = ({
  field,
  oldValue,
  updatedValue,
  extraParams,
  userData,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    let updatedData = _.cloneDeep(updatedValue);
    if (_.has(updatedData, "grades")) {
      dispatch(
        updateOnBoardingObject({
          updatedData: updatedData,
          userData,
        })
      );
      if (Object.keys(_.omit(updatedData, "grades")).length <= 0) {
        return;
      }
    }

    let { startDate, endDate } = oldValue || {};

    if (!startDate) {
      // Onboarding - Avoid year to get a default value if a month is selected.
      updatedData["startDate"] = "9999-01-01";
    } else {
      updatedData["startDate"] = startDate;
    }
    if (endDate) {
      updatedData["endDate"] = endDate;
    }

    if (_.has(updatedData, "startMonth")) {
      updatedData["startDate"] = moment(updatedData["startDate"], "YYYY-MM-DD")
        .set("month", parseInt(updatedData["startMonth"]))
        .format("YYYY-MM-DD")
        .toString();
    }
    if (_.has(updatedData, "startYear")) {
      updatedData["startDate"] = moment(updatedData["startDate"], "YYYY-MM-DD")
        .set("year", updatedData["startYear"])
        .format("YYYY-MM-DD")
        .toString();
    }

    if (_.has(updatedData, "endMonth")) {
      updatedData["endDate"] = moment(updatedData["endDate"], "YYYY-MM-DD")
        .set("month", parseInt(updatedData["endMonth"]))
        .format("YYYY-MM-DD")
        .toString();
    }
    if (_.has(updatedData, "endYear")) {
      const endYear = updatedData["endYear"];
      updatedData["endDate"] = moment(updatedData["endDate"], "YYYY-MM-DD")
        .set("year", endYear)
        .format("YYYY-MM-DD")
        .toString();
    }

    if (_.has(updatedData, "school")) {
      const school = updatedData["school"] || {};

      updatedData["school"] = !_.isEmpty(school)
        ? {
            id: school.id,
            name: school.name,
            country: school.country,
            __typename: "CommunitySchool",
          }
        : null;
      // updatedData["country"] = school.country;
    }

    let newValue = {
      ...(oldValue || {}),
      ...(updatedData || {}),
    };

    updateSchoolTenureInCache({
      id: _.get(oldValue, "id", ""),
      data: newValue,
    });

    if (_.has(updatedData, "school")) {
      updatedData["school"] = _.get(updatedData, "school.id", null);
    }
    const { key: fieldKey } = _.get(ENTITY_TYPE_MAPPING, field, {}) || {};

    if (!_.includes(updatedData["school.id"], "NEW")) {
      dispatch(
        updateField({
          key: userId,
          params: {
            ...(updatedData || {}),
            designation: _.get(updatedData, "designation.id"),
            id: _.get(oldValue, "id", ""),
            fieldKey,
            isInvalid:
              _.filter(
                Object.values(newValue),
                val => val == undefined || !val || val == ""
              ).length > 0,
          },
          type: "COMMUNITY_ONBOARDING",
          isDebounce: true,
        })
      );
    }
  };
};

export const updateProfileEntity = params => {
  return async (dispatch, getState) => {
    const { field } = params;
    const userId = getState().login.userInfo.id;
    if (!params.userData) {
      params.userData = getUserInfoFromCache({
        id: userId,
      });
    }
    switch (field) {
      case "WORKSHOP":
      case "TRAINING":
        dispatch(updateProfileActivityEntity(params));
        break;

      case "CURRENT_PROFESSION":
      case "PAST_PROFESSION":
        dispatch(updateSchoolTenure(params));
        break;
    }
  };
};

export const updateUser = params => {
  writeUserInfoToCache(params);
  updateCommunityUserDetails(params);
};

export const updateUserHandler = params => {
  let fieldKey = _.get(params, "fieldKey", "");
  const updatedParams = {};
  params = _.omit(params, ["fieldKey"]);
  _.forEach(params, (value, key) => {
    if (key !== "id") {
      key = key.split(".").pop();
      if (!fieldKey) {
        fieldKey = key;
      }
    }
    updatedParams[key] = value;
  });

  return dispatch => {
    switch (fieldKey) {
      case "firstName":
      case "lastName":
      case "personalEmail":
      case "aboutMe":
      case "experience":
      case "currentSchoolDuration":
      case "workshopCount":
      case "city":
      case "country":
      case "numberOfworkshops":
      case "onboardingStatus":
      case "profileImage":
      case "banner":
      case "facebook":
      case "twitter":
      case "linkedin":
        dispatch(updateBasicInfo(updatedParams));
        break;

      case "interests":
      case "topics":
      case "skills":
        dispatch(handleMultipleUserTag(updatedParams));
        break;

      case "workshops":
      case "trainings":
        dispatch(updateWorkshopOrTraining(updatedParams));
        break;

      case "currentSchools":
      case "pastSchools":
        dispatch(changeCommunitySchoolUserTenure(updatedParams));
        break;

      case "grades":
        dispatch(updateUserGlobalGrades(updatedParams));
        break;
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_ONBOARDING_OBJECT]: (state, action) => {
    const { payload } = action;

    Object.keys(payload).forEach(field => {
      if (!state.onBoardingObj[field]) {
        state = update(state, {
          onBoardingObj: { [field]: { $set: {} } },
        });
      }
      state = update(state, {
        onBoardingObj: { [field]: { $set: payload[field] || {} } },
      });
    });
    return state;
  },
  [UPDATE_FILTERS]: (state, action) => {
    let params = action.data.params;

    let id = action.data.id;
    if (!state.filters[id]) {
      state = update(state, {
        filters: { [id]: { $set: {} } },
      });
    }
    Object.keys(params).map((key, index) => {
      if (!_.get(state.filters[id], key, "")) {
        state = update(state, {
          filters: { [id]: { [key]: { $set: {} } } },
        });
      }
      state = update(state, {
        filters: {
          [id]: { [key]: { $set: params[key] } },
        },
      });
    });

    return state;
  },
};

const initialState = {
  onBoardingObj: {},
  filters: {},
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
