import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

const DPSubjectsSetup = store => ({
  path: "dpSubjects",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const dpSubjectSetupReducer = require("DPSubjectsSetup/modules/DPSetupSubjectsModule")
              .default;
            injectReducer(store, {
              key: "dpSubjectsSetup",
              reducer: dpSubjectSetupReducer,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "school-onboard-setup"
        );
      })
  ),
  childRoutes: [],
});

export default DPSubjectsSetup;
