import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import PortfolioDetailV2 from "./routes/PortfolioDetailsV2";

// import PortfolioDetails from "./routes/PortfolioDetail";
// import ACLStore from "lib/aclStore";

// const ChildRoutes = ACLStore.can("TeacherPortal:StudentProfileV2")
//   ? PortfolioDetailV2
//   : PortfolioDetails;

export default store => ({
  path: "studentPortfolio",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/StudentPortfolioModule").default;
            injectReducer(store, { key: "studentPortfolio", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-studentPortfolio"
        );
      })
  ),
  childRoutes: [PortfolioDetailV2(store)],
});
