import gql from "graphql-tag";
import { conversationsFragment } from "./ChatContainerFragments";

export const getConversationsQuery = gql`
  query getConversations(
    $id: ID!
    $after: String
    $first: Int
    $filters: MessagesFilters
  ) {
    node(id: $id, type: CONVERSATION) {
      id
      ... on Conversation {
        ...messages
      }
    }
  }
  ${conversationsFragment.messages}
`;

export const getConversationsUnreadCountQuery = gql`
  query getConversationsUnreadCount($id: ID!) {
    node(id: $id, type: CONVERSATION) {
      id
      ... on Conversation {
        id
        unreadMessageCount
      }
    }
  }
`;
