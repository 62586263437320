/**--external-- */
import React, { useRef, useImperativeHandle } from "react";
import propTypes from "prop-types";
import classNames from "classnames";

/**--internal-- */
import { useI18n } from "Hooks";
import {
  FormTextInput,
  FormSelectDropdown,
  DropzoneComponent,
} from "UIComponents";
import { LANGUAGE_OPTIONS, ROASTER_OPTIONS } from "Constants";

/**--relative-- */
import classes from "./Step1.scss";
import DropZoneButtonComponent from "./DropZoneButtonComponent";

const Step1 = props => {
  const {
    name,
    code,
    locale,
    rosteringMethod,
    logoAttachment,
    updateOrganizationInformation,
    customRef,
  } = props;

  const { t } = useI18n(["onBoarding"]);

  const nameRef = useRef();
  const languageRef = useRef();
  const rosteringMethodRef = useRef();

  useImperativeHandle(customRef, () => {
    return {
      isValid: () => {
        const errorCount = _.reduce(
          [nameRef, languageRef, rosteringMethodRef],
          (result, ref) => {
            return result + ref?.current?.isValid() ?? 0;
          },
          0
        );

        return errorCount == 0;
      },
    };
  });

  const headerClasses = classNames("heading-3", classes.header);

  const schoolDetailsHeaderClasses = classNames(
    "heading-5",
    classes.schoolDetailsHeader
  );

  const schoolLogoLabelClasses = classNames(
    "text-label-2",
    classes.schoolLogoLabel
  );

  return (
    <div className={classes.container}>
      <h3 className={headerClasses}>{t("onBoarding:onboard_new_school")}</h3>
      <h5 className={schoolDetailsHeaderClasses}>
        {t("onBoarding:school_details")}
      </h5>
      <div className={classes.schoolLogoContainer}>
        <p className={schoolLogoLabelClasses}>{t("onBoarding:school_logo")}</p>
        <DropzoneComponent
          accept="image/jpeg,image/png,image/jpg"
          onContentUploaded={attachments => {
            updateOrganizationInformation({
              key: "logoAttachment",
              value: _.first(attachments),
            });
          }}
          multiple={false}
          renderComponent={
            <DropZoneButtonComponent logoAttachment={logoAttachment} />
          }
        />
      </div>
      <div className={classes.schoolDetailsContainer}>
        <FormTextInput
          value={name}
          updateInputField={name =>
            updateOrganizationInformation({ key: "name", value: name })
          }
          placeholder={t("onBoarding:enter_school_name")}
          size={"x-large"}
          isRequired
          ref={nameRef}
          label={t("onBoarding:school_name")}
        />
        <FormTextInput
          value={code}
          updateInputField={code =>
            updateOrganizationInformation({ key: "code", value: code })
          }
          placeholder={t("onBoarding:enter_school_code")}
          size={"x-large"}
          label={t("onBoarding:school_code_optional")}
        />
        <div className={classes.selectDropdownContainer}>
          <FormSelectDropdown
            placeholder={t("onBoarding:choose_language")}
            options={LANGUAGE_OPTIONS}
            size={"x-large"}
            value={locale}
            isRequired
            label={t("onBoarding:platform_language")}
            updateInputField={locale =>
              updateOrganizationInformation({
                key: "locale",
                value: locale,
              })
            }
            ref={languageRef}
            menuPlacement="top"
          />
        </div>
        <div className={classes.selectDropdownContainer}>
          <FormSelectDropdown
            placeholder={t("onBoarding:choose_rostering_method")}
            options={ROASTER_OPTIONS}
            size={"x-large"}
            value={rosteringMethod}
            isRequired
            label={t("onBoarding:rostering_method")}
            updateInputField={rosteringMethod =>
              updateOrganizationInformation({
                key: "rosteringMethod",
                value: rosteringMethod,
              })
            }
            ref={rosteringMethodRef}
            menuPlacement="top"
          />
        </div>
      </div>
    </div>
  );
};

Step1.propTypes = {
  name: propTypes.string,
  code: propTypes.string,
  locale: propTypes.string,
  rosteringMethod: propTypes.string,
  logoAttachment: propTypes.object,
  updateOrganizationInformation: propTypes.func,
  customRef: propTypes.object,
};

export default Step1;
