const styles = {
  linkStyles: { display: "flex", alignItems: "center" },
  tooltipContainerStyle: { width: "240px", fontSize: "1.3rem" },
};

export const approvedPracticesStyles = {
  tooltipContainerStyle: { width: "248px", fontSize: "1.3rem" },
};

export default styles;
