import React from "react";
import classes from "./Footer.scss";
import { I18nHOC } from "UIComponents";
import {
  SplitButtonDropdown,
  Button,
  DropdownMenu,
  Tooltip,
} from "@toddle-design/web";
import moment from "moment";

const Footer = React.memo(props => {
  const {
    isLast,
    isFirst,
    onClickNext,
    onClickPrevious,
    onClickCancel,
    onClickDone,
    isNextDisabled,
    mode,
    currentStep,
    courses,
    resourceSelection,
    t,
    onClickSchedule,
    steps,
    isDoneButtonDisabled,
  } = props;

  const getSplitButtonOptions = t => {
    return [
      {
        label: t("common:assign"),
        key: "assign",
      },
      { key: "schedule", label: t("common:schedule") },
      { isDivider: true, key: "1-2" },
      {
        key: "discard",
        label: t("common:discard"),
        isDestructive: true,
      },
    ];
  };
  const onChangeButtonOption = ({ key }) => {
    switch (key) {
      case "assign":
        onClickDone();
        break;
      case "schedule":
        onClickSchedule();
        break;
      case "discard":
        onClickCancel();
        break;
      default:
        null;
    }
  };

  const getScheduleText = () => {
    const scheduledCourses = _.filter(courses, course => {
      return !_.isEmpty(course.state.scheduleDate);
    });

    if (mode == "create") {
      let scheduleText = "";
      _.forEach(scheduledCourses, ({ title }, index) => {
        if (index == 0) scheduleText += title;
        else scheduleText += ` & ${title}`;
      });
      return t("common:scheduled_for_with_label", {
        label: scheduleText,
      });
    } else {
      return t("common:scheduled_at_with_label", {
        label: moment(_.first(scheduledCourses).state.scheduleDate).format(
          "Do, MMM YYYY"
        ),
      });
    }
  };

  const tooltipText = (
    <>
      {_.map(
        _.filter(
          courses,
          course => !_.isEmpty(_.get(course, "state.scheduleDate"))
        ),
        course => {
          return <div>{_.get(course, "title")}</div>;
        }
      )}
    </>
  );
  const isScheduled = _.find(
    courses,
    course => !_.isEmpty(course.state.scheduleDate)
  );
  const showScheduleButton =
    currentStep == "SETTINGS" &&
    (mode == "create" ||
      _.get(_.first(courses), "state.state", "") == "SCHEDULED");

  const currentStepData = _.find(steps, { value: currentStep });
  const showSplitButton = _.get(
    currentStepData,
    "config.showSplitButton",
    false
  );
  const actionButtons = _.get(currentStepData, "config.actionButtons", []);
  const label1 = _.get(_.first(actionButtons), "label", "");
  const label2 = _.get(_.last(actionButtons), "label", "");
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {!isLast && (
          <Button variant={"outlined"} size={"medium"} onClick={onClickCancel}>
            {t(label1)}
          </Button>
        )}
        <div className={classes.rightButtonContainer}>
          {resourceSelection && !isFirst && (
            <div className={classes.cancelButtonText} onClick={onClickPrevious}>
              {t(label2)}
            </div>
          )}
          {!isLast && (
            <Button
              variant={"progressive"}
              onClick={onClickNext}
              disabled={isNextDisabled}
              size={"medium"}
            >
              {t(label2)}
            </Button>
          )}
        </div>
        {isLast && showSplitButton && (
          <div className={classes.buttonsContainer}>
            {showScheduleButton && (
              <Tooltip placement={"top"} tooltip={tooltipText}>
                <div
                  className={classes.scheduleTextContainer}
                  style={{ textDecoration: isScheduled && "underline" }}
                >
                  {isScheduled && getScheduleText()}
                </div>
              </Tooltip>
            )}
            <SplitButtonDropdown
              overlay={
                <DropdownMenu
                  options={getSplitButtonOptions(t)}
                  showSelection
                  onClick={e => onChangeButtonOption(e)}
                />
              }
            >
              <Button size={"medium"} onClick={onClickDone}>
                {t("common:assign")}
              </Button>
            </SplitButtonDropdown>
          </div>
        )}

        {isLast && !showSplitButton && (
          <div className={classes.buttonsContainer}>
            <Button
              size={"medium"}
              variant={"outlined"}
              onClick={onClickCancel}
            >
              {t(label1)}
            </Button>
            <Button
              size={"medium"}
              variant={"progressive"}
              onClick={onClickDone}
              disabled={isDoneButtonDisabled}
            >
              {t(label2)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});

Footer.displayName = "Footer";

Footer.defaultProps = {
  isButtonDisabled: false,
};

export default I18nHOC({ resource: ["common", "teacherHomePage"] })(Footer);
