import React from "react";

// UI components
import {
  UIModal,
  Loading,
  I18nHOC,
  DialogueBox,
  UIBaseComponent,
} from "UIComponents";

// Redux Thunks
import {
  writeAssessmentToolInLocal,
  updateAssessmentTool,
  createAssessmentTool,
  deleteAssessmentTool,
} from "AssessmentToolSetup/modules/AssessmentToolSetupModule";

// Queries
import { getAssessmentToolNodeDetailsQuery } from "modules/CommonQuery";
import { getAssessmentToolNodeDetailsFromCache } from "modules/CommonGraphqlHelpers";

// Smart Components
import { AssessmentToolChooseListV2 } from "UnitPlans/components/LibraryCommonComponents";
import RubricTable from "Rubric/components/RubricTable";
import SinglePointRubricTable from "SinglePointRubric/components/SinglePointRubricTable";
import ChecklistTable from "Checklist/components/ChecklistTable";
import Anecdotal from "../AssessmentTool/Anecdotal";
import Exemplar from "../AssessmentTool/Exemplar";
import MYPObjectiveRubric from "../AssessmentTool/MYPObjectiveRubric";

// Services
import {
  ASSESSMENT_TOOLS,
  createAttachment,
  deleteAttachment,
  updateField,
} from "modules/Services";

// External lib.
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";

// Design System
import { IconButton, Button } from "@toddle-design/web";
import { CloseOutlined, ChevronLeftOutlined } from "@toddle-design/web-icons";
import { colors } from "@toddle-design/theme";

// Module
import { updateAssessmentInRedux } from "Assessments/modules/AssessmentModule";

// Scss
import classes from "./AssessmentToolV2.scss";

const getPromptDetail = t => {
  return {
    placeholder: t("unitPlan:enter_template_title"),
    label: t("common:title"),
    checkValidation: true,
  };
};

const styles = {
  button1Style: {
    type: "hollow",
    containerStyle: {
      borderRadius: "8px",
      borderColor: colors.borderSubtle,
    },
  },
  button2Style: {
    color: "blue",
    containerStyle: {
      borderRadius: "8px",
    },
  },
  anecdotalStyles: {
    listItemStyle: {
      padding: "8px 0",
    },
  },
};
class AssessmentToolV2 extends UIBaseComponent {
  constructor(props) {
    super(props);

    const {
      t,
      isCreateMode,
      assessmentToolHasTemplate,
      assessmentToolTypeLocale,
      assessmentToolModalMode,
    } = this.props;

    this.state = {
      isLoading: false,
      showDialogueBox: false,
      headerDetails: {
        title: assessmentToolTypeLocale,
        showBackButton: false,
        showCloseButton: true,
        backButtonClickAction: null,
      },
      footerDetails: {
        showFooter: !_.isEqual(assessmentToolModalMode, "use_from_template"),
        button1Label: t("classRoom:save_as_template"),
        button2Label: t("common:cancel"),
        button3Label: t("common:create"),
        showButton1: assessmentToolHasTemplate,
        showButton2: true,
        showButton3: isCreateMode,
        button1ClickAction: null,
        button2ClickAction: null,
        button3ClickAction: null,
        button1Variant: "inline-progressive",
        button3Variant: "primary",
      },
      templateSearchText: "",
      templateCreatedBy: [],
    };
  }

  componentDidMount = () => {
    const {
      isCreateMode,
      assessmentToolModalMode,
      clearAssessmentTool,
    } = this.props;

    // in case of create new, call mutation;

    if (isCreateMode) {
      this.createAssessmentTool();
      this.updateFooterDetails({
        button1ClickAction: this.onClickToolSave,
        button2ClickAction: async () => {
          await clearAssessmentTool();
          this.toggleAssessmentToolModal();
        },
        button3ClickAction: this.toggleAssessmentToolModal,
      });
    } else {
      switch (assessmentToolModalMode) {
        case "view_tool":
          this.viewTool();
          break;
        case "edit_tool":
          this.editTool();
          break;
      }
    }
  };

  createAssessmentTool = async () => {
    const {
      createAssessmentTool,
      type,
      parentId,
      parentType,
      filters,
    } = this.props;
    await createAssessmentTool({
      type,
      parentId,
      parentType,
      isGlobal: false,
      filters,
    });
  };

  updateAssessmentTool = ({ params, isDebounce }) => {
    const { updateAssessmentTool, type, toolId, updateField } = this.props;
    updateField({
      key: toolId,
      params: {
        id: toolId,
        params,
        type,
      },
      type,
      isDebounce,
      func: updateAssessmentTool,
    });
  };

  saveAssessmentTool = async title => {
    const {
      toolId,
      type,
      createAssessmentTool,
      filters,
      curriculumProgramId,
    } = this.props;
    await createAssessmentTool({
      copyToolId: toolId,
      title,
      type,
      mode: "COPY",
      filters,
      curriculumProgramId,
    });
  };

  onCopyTool = async id => {
    const {
      type,
      createAssessmentTool,
      parentId,
      parentType,
      filters,
    } = this.props;

    await createAssessmentTool({
      copyToolId: id,
      type,
      isGlobal: false,
      mode: "COPY",
      parentId,
      parentType,
      filters,
    });
  };

  createAttachment = attachment => {
    const { createAttachment, toolId, type } = this.props;
    createAttachment({
      attachment,
      nodeId: toolId,
      type,
      parentType: type,
    });
  };

  deleteAttachment = attachmentId => {
    const { deleteAttachment, toolId, type } = this.props;
    deleteAttachment({
      attachmentId,
      nodeId: toolId,
      type,
    });
  };

  writeAssessmentToolInLocal = ({ params }) => {
    const { writeAssessmentToolInLocal, type, toolId } = this.props;
    writeAssessmentToolInLocal({ type, id: toolId, params });
  };

  onClickToolSave = () => this.setState({ showDialogueBox: true });

  updateHeaderDetails = params =>
    this.setState(prevState => ({
      headerDetails: { ...prevState.headerDetails, ...params },
    }));

  updateFooterDetails = params =>
    this.setState(prevState => ({
      footerDetails: { ...prevState.footerDetails, ...params },
    }));

  updateAssessmentToolMode = assessmentToolMode => {
    const { updateAssessmentInRedux } = this.props;
    updateAssessmentInRedux({
      assessmentToolMode,
    });
  };

  viewTool = () => {
    const {
      t,
      assessmentToolHasTemplate,
      assessmentToolTypeLocale,
      mode,
    } = this.props;
    this.updateAssessmentToolMode("view");

    this.updateFooterDetails({
      showFooter: mode !== "view",
      showButton1: assessmentToolHasTemplate,
      showButton2: false,
      showButton3: !assessmentToolHasTemplate,
      button1Label:
        assessmentToolHasTemplate &&
        t("common:edit_with_label", {
          label: assessmentToolTypeLocale,
        }),
      button3Label: !assessmentToolHasTemplate && t("common:done"),
      button3Variant: "progressive",
      button1Variant: "outlined-subtle",
      button1ClickAction: assessmentToolHasTemplate && this.editTool,
      button3ClickAction:
        !assessmentToolHasTemplate && this.toggleAssessmentToolModal,
    });

    this.updateHeaderDetails({
      showCloseButton: assessmentToolHasTemplate || mode === "view",
    });
  };

  editTool = () => {
    const { t, mode, assessmentToolHasTemplate } = this.props;

    this.updateAssessmentToolMode("edit");

    this.updateFooterDetails({
      showFooter: mode !== "view",
      showButton1: assessmentToolHasTemplate,
      button1Label: t("classRoom:save_as_template"),
      showButton2: false,
      showButton3: true,
      button3Label: t("common:done"),
      button3Variant: "progressive",
      button3ClickAction: this.toggleAssessmentToolModal,
      button1Variant: "inline-progressive",
      button1ClickAction: assessmentToolHasTemplate && this.onClickToolSave,
    });

    this.updateHeaderDetails({
      showCloseButton: false,
    });
  };

  renderTool = ({ mode = "edit", assessmentTool }) => {
    const { type, setScrollToBottom, updateInputField } = this.props;
    if (_.isEmpty(assessmentTool)) return;

    switch (type) {
      case "RUBRIC":
        return (
          <RubricTable
            customRef={this.updateInputRef}
            mode={mode}
            rubric={assessmentTool}
            onClickToolSave={this.onClickToolSave}
            setScrollToBottom={setScrollToBottom}
            showSaveTemplateButton={false}
          />
        );
      case "CHECKLIST":
        return (
          <ChecklistTable
            mode={mode}
            customRef={this.updateInputRef}
            checklist={assessmentTool}
            onClickToolSave={this.onClickToolSave}
            setScrollToBottom={setScrollToBottom}
            showSaveTemplateButton={false}
          />
        );
      case "SINGLE_POINT_RUBRIC":
        return (
          <SinglePointRubricTable
            mode={mode}
            customRef={this.updateInputRef}
            rubric={assessmentTool}
            onClickToolSave={this.onClickToolSave}
            setScrollToBottom={setScrollToBottom}
            showSaveTemplateButton={false}
          />
        );
      case "ANECDOTE":
        return (
          <Anecdotal
            {...this.props}
            ref={this.updateInputRef}
            anecdotal={assessmentTool?.message}
            onClickToolSave={this.onClickToolSave}
            writeAssessmentToolInLocal={this.writeAssessmentToolInLocal}
            updateAssessmentTool={this.updateAssessmentTool}
            styles={styles.anecdotalStyles}
          />
        );
      case "MYP_OBJECTIVE_RUBRIC":
        return (
          <MYPObjectiveRubric
            {...this.props}
            ref={this.updateInputRef}
            customRef={this.updateInputRef}
            updateInputField={updateInputField}
          />
        );
      case "EXEMPLAR":
        return (
          <Exemplar
            {...this.props}
            ref={this.updateInputRef}
            onFocus={this.onFocus}
            onClickToolSave={this.onClickToolSave}
            onBlur={this.onBlur}
            value={assessmentTool?.message}
            attachments={assessmentTool?.attachments}
            createAttachment={this.createAttachment}
            deleteAttachment={this.deleteAttachment}
            writeAssessmentToolInLocal={this.writeAssessmentToolInLocal}
            updateAssessmentTool={this.updateAssessmentTool}
          />
        );
      default:
        return null;
    }
  };

  updateTemplateSearchText = text => {
    this.setState({ templateSearchText: text });
  };

  updateTemplateCreatedBy = createdBy => {
    this.setState({ templateCreatedBy: createdBy });
  };

  toggleShowDialogueBox = () =>
    this.setState({ showDialogueBox: !this.state.showDialogueBox });

  toggleAssessmentToolModal = () => {
    const { showAssessmentToolModal, updateAssessmentInRedux } = this.props;
    updateAssessmentInRedux({
      showAssessmentToolModal: !showAssessmentToolModal,
    });
  };

  render() {
    const {
      t,
      type,
      assessmentTool,
      clearAssessmentTool,
      assessmentToolModalMode,
      assessmentToolMode,
    } = this.props;

    const {
      headerDetails,
      footerDetails,
      showDialogueBox,
      templateSearchText,
      templateCreatedBy,
    } = this.state;

    const { showFooter } = footerDetails;

    const assessmentToolName = t(
      _.get(_.find(ASSESSMENT_TOOLS, { type }), "locale", "")
    );

    const renderTool = () => {
      switch (assessmentToolModalMode) {
        case "create_new":
        case "view_tool": {
          return _.isEmpty(assessmentTool) ? (
            <Loading />
          ) : (
            this.renderTool({ assessmentTool, mode: assessmentToolMode })
          );
        }
        case "use_from_template": {
          return (
            <AssessmentToolChooseListV2
              type={type}
              name={assessmentToolName}
              searchText={templateSearchText}
              createdBy={templateCreatedBy}
              renderTool={this.renderTool}
              onCopyTool={this.onCopyTool}
              updateHeaderDetails={this.updateHeaderDetails}
              updateFooterDetails={this.updateFooterDetails}
              handleTemplateSearch={this.updateTemplateSearchText}
              toggleAssessmentToolModal={this.toggleAssessmentToolModal}
              updateTemplateCreatedBy={this.updateTemplateCreatedBy}
            />
          );
        }
        default: {
          return (
            assessmentTool &&
            this.renderTool({ assessmentTool, mode: assessmentToolMode })
          );
        }
      }
    };

    return (
      <>
        <UIModal modalContent={classes.modalContent} isOpen={true}>
          <Header
            t={t}
            toggleAssessmentToolModal={this.toggleAssessmentToolModal}
            headerDetails={headerDetails}
            shouldClearAssessmentTool={_.isEqual(
              assessmentToolModalMode,
              "create_new"
            )}
            clearAssessmentTool={clearAssessmentTool}
          />
          <div className={classes.container}>
            <div className={classes.innerContainer}>{renderTool()}</div>
          </div>

          {showFooter && (
            <Footer
              t={t}
              footerDetails={footerDetails}
              onClickToolSave={this.onClickToolSave}
              toggleAssessmentToolModal={this.toggleAssessmentToolModal}
            />
          )}
        </UIModal>

        {showDialogueBox && (
          <DialogueBox
            modalTitle={t("common:save_this_template_with_label")}
            showModal={true}
            onClickButton2={this.saveAssessmentTool}
            toggleDialogueBoxDisplay={this.toggleShowDialogueBox}
            button1={t("common:cancel")}
            button2={t("common:add")}
            isPromptBox={true}
            promptDetail={getPromptDetail(t)}
            button1Props={styles.button1Style}
            button2Props={styles.button2Style}
          />
        )}
      </>
    );
  }
}

const Header = props => {
  const {
    t,
    headerDetails,
    shouldClearAssessmentTool,
    clearAssessmentTool,
    toggleAssessmentToolModal,
  } = props;

  const {
    title,
    showBackButton,
    showCloseButton,
    backButtonClickAction,
  } = headerDetails;

  const onCancelIconClick = async () => {
    if (shouldClearAssessmentTool) {
      await clearAssessmentTool();
      toggleAssessmentToolModal();
    } else {
      toggleAssessmentToolModal();
    }
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftContainer}>
        {showBackButton && (
          <IconButton
            variant="plain"
            size="medium"
            icon={<ChevronLeftOutlined />}
            onClick={backButtonClickAction}
          />
        )}
        <div className={classes.headerTitle}>
          {t("common:capitalize", {
            text: title,
          })}
        </div>
      </div>
      {showCloseButton && (
        <div className={classes.iconContainer}>
          <IconButton
            icon={<CloseOutlined size={"xx-small"} />}
            variant={"plain"}
            onClick={onCancelIconClick}
          />
        </div>
      )}
    </div>
  );
};

const Footer = props => {
  const { t, footerDetails } = props;

  const {
    showButton1,
    showButton2,
    showButton3,
    button1Label,
    button2Label,
    button3Label,
    button1ClickAction,
    button2ClickAction,
    button3ClickAction,
    button1Variant,
    button3Variant,
  } = footerDetails;

  return (
    <div className={classes.footerContainer}>
      <div className={classes.buttonsContainer}>
        <div className={classes.leftButtonContainer}>
          {showButton1 && (
            <Button
              size={"large"}
              variant={button1Variant}
              onClick={button1ClickAction}
            >
              {button1Label}
            </Button>
          )}
        </div>

        <div className={classes.rightButtonContainer}>
          {showButton2 && (
            <Button
              size={"large"}
              variant={"outlined-subtle"}
              onClick={button2ClickAction}
            >
              {button2Label}
            </Button>
          )}
          {showButton3 && (
            <Button
              size={"large"}
              variant={button3Variant}
              onClick={button3ClickAction}
            >
              {button3Label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { t, assessmentToolId } = ownProps;
  const toolId = assessmentToolId;
  const mode = ownProps.mode == "view" ? "view" : !toolId ? "create" : "edit";
  const type = _.get(
    _.find(ASSESSMENT_TOOLS, { type: ownProps.toolType }),
    "entityType",
    ""
  );

  const {
    assessmentId,
    assessmentToolMode,
    assessmentToolModalMode,
    showAssessmentToolModal,
  } = state.assessment.assessmentData;
  const organizationId = state.login.userInfo.org_id;
  const userId = state.login.userInfo.id;
  const { id: curriculumProgramId } = state.platform.currentCurriculumProgram;

  const createNew = assessmentToolModalMode === "create_new";
  const isCreateMode = _.isEqual(mode, "create") && createNew;

  const assessmentToolType = ownProps.toolType;
  const assessmentToolTypeLocale = t(
    _.find(ASSESSMENT_TOOLS, { type: assessmentToolType })?.locale
  );

  const assessmentToolHasTemplate = _.find(ASSESSMENT_TOOLS, {
    type: assessmentToolType,
  })?.hasTemplate;

  return {
    assessmentId: ownProps.assessmentId ? ownProps.assessmentId : assessmentId,
    toolId,
    mode,
    type,
    assessmentToolType,
    assessmentToolTypeLocale,
    assessmentToolHasTemplate,
    isCreateMode,
    organizationId,
    userId,
    createNew,
    isData: true,
    isLoading: false,
    curriculumProgramId,
    assessmentToolMode,
    showAssessmentToolModal,
    assessmentToolModalMode,
  };
};

const mapActionCreators = {
  writeAssessmentToolInLocal,
  updateAssessmentTool,
  createAssessmentTool,
  deleteAssessmentTool,
  createAttachment,
  deleteAttachment,
  updateField,
  updateAssessmentInRedux,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getAssessmentToolNodeDetailsQuery, {
    name: "getAssessmentToolNodeDetails",
    skip: ({ toolId }) => !toolId || _.includes(toolId, "NEW"),
    options: ({ toolId, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: toolId, type },
    }),
    props({
      getAssessmentToolNodeDetails,
      ownProps: { isData, isLoading, toolId, type },
    }) {
      const queryData = getAssessmentToolNodeDetailsFromCache({
        id: toolId,
        type,
      });
      const networkStatus = _.get(
        getAssessmentToolNodeDetails,
        "networkStatus"
      );
      return {
        assessmentTool: queryData,
        isData: !_.isEmpty(queryData) && isData,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  })
)(AssessmentToolV2);
