import { getRegionOptions } from "Courses/modules/utils";
import {
  ADMIN_SEARCH_TEXT_FIELDS,
  getAdminSetupFiltersByEntityTypeMemoized,
} from "routes/routes/Administrator/modules/Utils";
import { parseAdminMenuId } from "Administrator/modules/Utils";

/**
 *
 * @param orgTags - Organization Tags
 * @param userTags - Logged in user tags
 * @param regionTags - All the available tags coming from backend
 * @param entityTags - entity = Course/Teacher
 * @returns { key, value, disabled } object array which gives the list of all possible dropdowns
 *                                   options
 */

export const getLocalizedLabel = ({
  organizationTags,
  userTags,
  regionTags,
  entityTags,
  includeGlobalRegion = true,
}) => {
  const options = getRegionOptions({
    organizationTags,
    userTags,
    tags: regionTags,
  });
  const courseTagsButNotUserTags = getEntityTagsButNotUserTags({
    organizationTags,
    userTags,
    regionTags,
    entityTags,
  });

  const validOptions = getValidRegionOptions(
    options,
    entityTags,
    includeGlobalRegion
  );
  return _.map(validOptions, option => {
    return {
      ..._.find(regionTags, tag => tag.id === option),
      disabled: _.includes(courseTagsButNotUserTags, option),
    };
  });
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const getEntityTagsButNotUserTags = ({
  organizationTags,
  userTags,
  regionTags,
  entityTags,
}) => {
  const options = getRegionOptions({
    organizationTags,
    userTags,
    tags: regionTags,
  });

  const userAccessTags = _.map(options, tag => tag.id);

  // When the logged in user does not have access to a region
  // which has already been set to the subject, then that tag field
  // is disabled (no edit permission)
  return _.difference(entityTags, userAccessTags);
};

const getValidRegionOptions = (options, courseTags, addGlobalTag = true) => {
  // No Global tag for subject

  const optionsWithGlobalCondition = addGlobalTag
    ? options
    : _.filter(options, option => option.value !== "GLOBAL");

  const finalOptions = _.map(optionsWithGlobalCondition, option => option.id);
  return _.uniq([...finalOptions, ...courseTags]);
};

export const getTeacherFilters = state => {
  const searchText = _.get(state, "teacherSetup.searchText");
  const filters = _.get(state, "teacherSetup.filters");

  const { subChildId } = _.get(state, "administrator.routes", {});
  const { curriculumProgramId } = parseAdminMenuId(subChildId);

  const curriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms"
  );

  const currentCurriculumProgram = _.find(curriculumPrograms, {
    id: curriculumProgramId,
  });

  const curriculumProgramAcronym = _.get(
    currentCurriculumProgram,
    "acronym",
    "All"
  );

  const teacherFilters = getAdminSetupFiltersByEntityTypeMemoized({
    curriculumProgramId,
    type: "STAFF",
    searchText,
    searchTextFields: ADMIN_SEARCH_TEXT_FIELDS["staff"],
    ...filters,
  });

  return {
    teacherFilters,
    subChildId,
    selectedCurriculumProgramId: curriculumProgramId,
    selectedCurriculumProgramAcronym: curriculumProgramAcronym,
  };
};
