import { colors } from "@toddle-design/theme";
import React, { useState } from "react";
import { MediaModal, MediaPlaceholder } from "UIComponents";

const MediaModalWrapper = ({
  attachments,
  toggleMediaModal,
  showMediaModal,
}) => {
  return showMediaModal ? (
    <div>
      <MediaModal
        toggleMediaModal={toggleMediaModal}
        attachments={attachments}
        activeIndex={0}
        onWorkbookUpdateSuccessfully={() => toggleMediaModal(false)}
      />
    </div>
  ) : null;
};

export const JournalPreviewCard = props => {
  const {
    attachment,
    cardStyle,
    containerStyle,
    itemStyles,
    mode = "view",
    isStudentResponse,
  } = props;

  const [showMediaModal, setShowMediaModal] = useState(false);
  const onClickMedia = ({ event } = {}) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setShowMediaModal(true);
  };
  const { isPost, attachments = [], id } = attachment;
  return (
    <React.Fragment>
      <MediaPlaceholder
        onClickMedia={onClickMedia}
        mode={mode}
        isDisabledMode={false}
        attachments={isPost ? attachments : [attachment]}
        imageMaxHeight={_.get(cardStyle, "height", 284)}
        imageMaxWidth={284}
        resolutionMutliplier={2}
        showOverlayIcons={true}
        scalableRatio={1}
        itemStyles={{
          borderRadius: "4px",
          backgroundColor: colors.white,
          ...(itemStyles || {}),
        }}
        containerStyle={{
          height: "284px",
          flex: 1,
          width: "284px",
          ...(containerStyle || {}),
        }}
        noteContainerStyle={{ padding: 0 }}
        showDownloadIcon={false}
        isAttachmentCreated={true}
        isStudentResponse={isStudentResponse}
        showIconCardsWithExtension={true}
      />
      <MediaModalWrapper
        attachments={isPost ? attachments : [attachment]}
        toggleMediaModal={() => {
          setShowMediaModal(!showMediaModal);
        }}
        showMediaModal={showMediaModal}
      />
    </React.Fragment>
  );
};
