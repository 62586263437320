import React from "react";
import PropTypes from "prop-types";

const LangAndLitSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <path
        d="M2.5 6.5C2.5 4.29086 4.29086 2.5 6.5 2.5H25.5C27.7091 2.5 29.5 4.29086 29.5 6.5V21.5C29.5 23.7091 27.7091 25.5 25.5 25.5H18.5L16.424 28.8216C16.2282 29.1349 15.7718 29.1349 15.576 28.8216L13.7938 25.97C13.611 25.6776 13.2905 25.5 12.9458 25.5H6.5C4.29086 25.5 2.5 23.7091 2.5 21.5V6.5Z"
        fill="white"
        stroke="#FFAB00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9.5V22L17.3211 21.0563C18.1691 20.4506 19.1852 20.125 20.2273 20.125H23.5C24.1904 20.125 24.75 19.5654 24.75 18.875V8.25C24.75 7.55964 24.1904 7 23.5 7H18.5C17.1193 7 16 8.11929 16 9.5Z"
        fill="#FFE6AA"
        stroke="#FFAB00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 8.25V18.875C7.25 19.5654 7.80964 20.125 8.5 20.125H11.7727C12.8148 20.125 13.8309 20.4506 14.6789 21.0563L16 22V9.5C16 8.11929 14.8807 7 13.5 7H8.5C7.80964 7 7.25 7.55964 7.25 8.25Z"
        fill="#FFE6AA"
        stroke="#FFAB00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LangAndLitSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

LangAndLitSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default LangAndLitSGIcon;
