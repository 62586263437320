import gql from "graphql-tag";

export const productUpdateFragment = {
  productUpdate: gql`
    fragment productUpdateItem on ProductUpdates {
      id
      title
      description
      imageUrl
      updatedAt
      createdAt
      categories {
        id
        type
        title
        group
        color
        displaySequence
      }
      votes {
        myVote
      }
    }
  `,
};
