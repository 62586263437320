import { CURRICULUM_PLANS, PAID_PLAN_IDS } from "Constants";

/**
 * If curriculum program is paid, then show only paid plans in options
 * otherwise show all plans.
 */
export const getCurriculumPlans = ({ currentPlan, mode }) => {
  const showOnlyPaidPlans =
    _.includes(PAID_PLAN_IDS, currentPlan) && mode == "edit";

  if (showOnlyPaidPlans) {
    return _.filter(CURRICULUM_PLANS, ({ value }) =>
      _.includes(PAID_PLAN_IDS, value)
    );
  }

  return CURRICULUM_PLANS;
};
