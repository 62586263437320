import React from "react";
import classes from "./AttachmentItem.scss";
import {
  getThumbUrl,
  getLabelValue,
  WORKBOOK_MODE_TYPES,
  htmlToText,
} from "Utils";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  DeleteIcon,
  PdfLineIcon,
  LinkIcon,
  PlayIcon,
  EyeIcon,
  DownloadIcon,
  NoteIconSvg,
  CreateAssessmentSvg,
} from "SvgComponents";
import { Tick } from "SvgComponents";
import { colors, LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { MediaModal, I18nHOC, AudioRecorderSelector } from "UIComponents";
import { downloadFromUrl } from "modules/Services";
import ACLStore from "lib/aclStore";
import { IconButton, ProgressIndicator } from "@toddle-design/web";
import { DeleteOutlined } from "@toddle-design/web-icons";
import { getStudentResponseData } from "ClassRoom/modules/utils";
// const ICON_MAPPER = {
//   IMAGE: <EyeIcon width={16} height={12} fill={colors.white} />,
//   FILE: <PdfLineIcon width={16} height={16} fill={colors.white} />,
//   VIDEO: <PlayIcon />,
//   LINK: <LinkIcon width={16} height={16} />,
//   NOTE: <NoteIconSvg width={16} height={16} />
// };

export const ModelContent = ({
  attachments,
  toggleMediaModal,
  mode,
  sourceType,
  updateAttachments,
}) => {
  const showPdfTronEditButton =
    mode === "edit" && sourceType === "STUDENT_TEMPLATE";
  return (
    <div className={classes.modalContent}>
      <MediaModal
        toggleMediaModal={toggleMediaModal}
        attachments={attachments}
        showWorkBook={
          mode == "edit" &&
          sourceType == "STUDENT_TEMPLATE" &&
          ACLStore.can("FeatureFlag:Workbook")
        }
        workbookMode={
          sourceType == "STUDENT_TEMPLATE"
            ? WORKBOOK_MODE_TYPES["ADD_OR_EDIT_STUDENT_TEMPLATE"]
            : WORKBOOK_MODE_TYPES["USE_TEMPLATE"]
        }
        mode={mode}
        showPdfTronEditButton={showPdfTronEditButton}
        showPdfTronfeedback={true}
        updateAttachments={updateAttachments}
      />
    </div>
  );
};

const PERMISSION_TYPE = {
  copy: "common:copy_for_student",
  edit: "common:drive_student_can_edit",
};

class AttachmentItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMediaModal: false,
    };
  }
  toggleMediaModal = value => {
    this.setState({ showMediaModal: false });
  };

  onClickAttachment = e => {
    const { showMediaModal } = this.state;
    e.stopPropagation();
    const {
      mode,
      attachment,
      onClickMedia,
      updateSelectedItems,
      isSelected,
      onClickAttachmentCard,
    } = this.props;

    if (onClickAttachmentCard) {
      onClickAttachmentCard(attachment.id);
    } else if (onClickMedia) {
      onClickMedia(attachment);
    } else {
      if (mode == "select") {
        updateSelectedItems({ attachment, isSelected });
      } else {
        if (!showMediaModal) {
          this.setState({ showMediaModal: true });
        }
      }
    }
  };

  onDeleteClick = e => {
    const {
      attachment: { id } = {},
      deleteAttachment,
      onDeleteAudioAttachment,
    } = this.props;
    if (onDeleteAudioAttachment) {
      onDeleteAudioAttachment();
      return;
    }
    e.stopPropagation();
    if (deleteAttachment) {
      deleteAttachment(id);
    }
  };

  onDownloadClick = e => {
    const { downloadFromUrl, attachment } = this.props;
    e.stopPropagation();
    downloadFromUrl({
      attachment: attachment,
      shouldAskForSave: true,
    });
  };

  getLabelValueForPost = ({ item, t }) => {
    const { title, name } = item;

    return title || name || t("common:untitled");
  };

  render() {
    const {
      customContainerStyle = {},
      attachment,
      mode,
      t,
      isSelected,
      showSingleCardTheme = true,
      updateAttachments,
      imageContainerStyle = {},
      detailContainerStyle = {},
      subText,
      disabled = false,
      deleteIconStyle = {},
    } = this.props;

    const { type, isPost, postEntityItems } = attachment;
    const { thumbUrl, isIcon, color, borderColor } = getThumbUrl({
      attachment: attachment,
      width: 256,
      height: 256,
    });

    const { showMediaModal } = this.state;
    const progressValue = parseInt(_.get(attachment, `progress`, "101"));

    //The progress Value is between 0-100 when its being uploaded, and once uploaded
    //the attachment doesn't have progress in it, so by default its 101, which means its created
    const isAttachmentCreated = progressValue > 100;

    if (attachment.type === "AUDIO") {
      return (
        <div
          className={classes.audioContainer}
          onClick={isAttachmentCreated ? this.onClickAttachment : null}
        >
          <AudioRecorderSelector
            mode={mode}
            value={attachment}
            onDeleteClick={this.onDeleteClick}
          />
          {mode == "select" && (
            <div className={classes.audioControlIconsCon}>
              {isSelected && (
                <Tick fill={colors.blue29} width={20} height={20} />
              )}
            </div>
          )}
        </div>
      );
    }

    let Icon = null;
    let iconColor = "";

    const { assessmentType } = getStudentResponseData(postEntityItems);
    if (assessmentType) {
      const iconType = assessmentType === "qt" ? "outlinedIcon" : "icon";
      Icon =
        _.get(LE_ASSESSMENT_TYPE_MAPPING[assessmentType], iconType) ||
        CreateAssessmentSvg;
      iconColor = _.get(
        LE_ASSESSMENT_TYPE_MAPPING[assessmentType],
        "decorativeBGColor",
        colors.decorativeSurfaceOneL
      );
    }

    const nameTextClass = classNames(
      { [classes.nameText]: true },
      { [classes.singleCardThemeNameText]: showSingleCardTheme }
    );

    const typeTextClass = classNames(
      { [classes.typeText]: true },
      { [classes.singleCardThemeTypeText]: showSingleCardTheme }
    );

    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.disabledContainer]: disabled }
    );

    const permission = _.get(attachment, "metadata.permission", false);
    const permissionText = _.get(PERMISSION_TYPE, permission, null);
    const { sourceType } = this.props;

    let attachmentImageContainerStyle = showSingleCardTheme
      ? {
          width: "46px",
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
        }
      : {};

    attachmentImageContainerStyle = {
      ...attachmentImageContainerStyle,
      border: "none",
      borderRight: "none",
    };

    return (
      <div
        className={containerClass}
        style={customContainerStyle}
        onClick={isAttachmentCreated ? this.onClickAttachment : null}
      >
        {assessmentType ? (
          <div
            className={classes.assessmentTypeIcon}
            style={{
              backgroundColor: iconColor,
            }}
          >
            <Icon
              size={"x-small"}
              variant={assessmentType === "qt" ? "link" : "default"}
            />
          </div>
        ) : (
          <div
            className={classes.attachmentImageContainer}
            style={{
              backgroundImage: `url(${thumbUrl})`,
              backgroundColor: color,
              backgroundSize: isIcon ? "35%" : "cover",
              ...attachmentImageContainerStyle,
              ...imageContainerStyle,
            }}
          >
            {progressValue < 100 && (
              <div className={classes.overlay}>
                <div className={classes.iconContainer}>
                  <ProgressIndicator
                    variant="preview-loader"
                    progress={progressValue}
                  ></ProgressIndicator>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={classes.rightContainer}
          style={{
            padding: !showSingleCardTheme
              ? "8px 8px 8px 16px"
              : "0px 8px 0px 8px",
          }}
        >
          <div className={classes.detailContainer} style={detailContainerStyle}>
            <div
              className={nameTextClass}
              dangerouslySetInnerHTML={{
                __html: htmlToText(
                  isPost
                    ? this.getLabelValueForPost({ item: attachment, t })
                    : getLabelValue({ item: attachment, t }),
                  {
                    wordwrap: 130,
                    tags: {
                      a: { options: { ignoreHref: true } },
                      ul: { options: { itemPrefix: " " } },
                    },
                  }
                ),
              }}
            ></div>
            <div className={typeTextClass}>
              {/* HACK: show type of permission based on sourceType === STUDENT_TEMPLATE, can be changed later */}
              {subText
                ? subText
                : permissionText
                ? t(permissionText)
                : sourceType === "STUDENT_TEMPLATE" && type === "IMAGE"
                ? t("common:copy_for_student")
                : type === "WORKBOOK" &&
                  _.get(attachment, "metadata.numOfPages", 0)
                ? t("common:workbook_page", {
                    count: _.get(attachment, "metadata.numOfPages", 0),
                  })
                : isPost
                ? "POST"
                : type}
            </div>
          </div>
          {mode == "select" && (
            <div className={classes.tickIconContainer}>
              {isSelected && (
                <Tick fill={colors.blue29} width={20} height={20} />
              )}
            </div>
          )}
          {mode == "edit" && isAttachmentCreated && (
            <div className={classes.deleteSvgIcon} style={deleteIconStyle}>
              <IconButton
                onClick={this.onDeleteClick}
                icon={<DeleteOutlined />}
                variant={"plain"}
                size={"medium"}
              />
            </div>
          )}
          {mode == "view" && isAttachmentCreated && type !== "WORKBOOK" && (
            <div
              className={classes.downloadIconContainer}
              onClick={this.onDownloadClick}
            >
              <DownloadIcon width={20} height={20} />
            </div>
          )}
        </div>

        {showMediaModal && (
          <ModelContent
            attachments={[attachment]}
            toggleMediaModal={this.toggleMediaModal}
            mode={mode}
            sourceType={sourceType}
            updateAttachments={updateAttachments}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { attachment } = ownProps;
  const attachmentObj = {
    attachment: attachment.isPost
      ? {
          ..._.last(attachment.attachments),
          id: attachment.id,
          attachmentId: _.get(_.last(attachment.attachments), "id"),
          isPost: true,
          postEntityItems: _.get(attachment, "postEntityItems", []),
        }
      : attachment, //Handled attachment group
  };

  if (attachment.title) {
    return {
      attachment: { ...attachmentObj.attachment, title: attachment.title },
    };
  }
  return attachmentObj;
};

const mapActionCreators = { downloadFromUrl };

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators)
)(AttachmentItem);
