import sanitizeHtml from "sanitize-html";

export const getStyleStrippedText = ({ text, type }) => {
  let config = {};

  switch (type) {
    case "RICH_TEXT_EDITOR":
      config = {
        allowedTags: [
          "a",
          "ol",
          "ul",
          "li",
          "em",
          "div",
          "span",
          "strong",
          "p",
        ],
        selfClosing: ["br"],
        allowedAttributes: {
          a: ["rel", "href", "target"],
          div: ["style"],
          span: ["style"],
        },
        allowedStyles: {
          div: {
            "text-align": [/^justify$/],
          },
          span: {
            "font-size": [/^\d+(?:px|rem|em|%)$/],
            "text-decoration": [/^underline$/],
          },
        },
      };
      break;
    default:
      config = {
        allowedTags: ["em", "b", "div", "span", "p", "strong"],
        allowedAttributes: {
          "*": ["style"],
        },
        allowedStyles: {
          "*": {
            "font-weight": [
              /[1-9]00/,
              /^normal$/,
              /^lighter$/,
              /^bold$/,
              /^bolder$/,
            ],
          },
        },
      };
  }

  return sanitizeHtml(text, config);
};
