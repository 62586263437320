import React from "react";
import classes from "./StudentList.scss";
import { I18nHOC } from "UIComponents";
import { Avatar, Checkbox, EmptyState } from "@toddle-design/web";
import { AVATAR_COLORS } from "Constants/colorConstants";
import { AddStudentIllustration } from "@toddle-design/theme";

const emptyStateSubtitleStyle = {
  lineHeight: "24px",
};

const StudentItem = React.memo(props => {
  const {
    item: { id, firstName, lastName, profileImage, isArchived } = {},
    isChecked,
    isDisabled,
    onItemCheckBoxClick,
    t,
  } = props;

  return (
    <div
      className={classes.studentItem}
      onClick={() => onItemCheckBoxClick(props.item)}
    >
      <div className={classes.itemLeftContainer}>
        <div className={classes.avatarContainer}>
          <Avatar
            name={`${firstName} ${lastName}`}
            shade={isArchived ? "light" : "dark"}
            src={profileImage}
            iconType={id === "ALL" ? "group" : ""}
            color={AVATAR_COLORS[id % _.size(AVATAR_COLORS)]}
          />
        </div>
        <div className={classes.statusWrapper}>
          <div className={classes.nameText}>{`${firstName} ${lastName}`}</div>
          {isArchived && (
            <div className={"archivedLabel"}>{t("common:archived")}</div>
          )}
        </div>
      </div>
      <div className={classes.itemRightContainer}>
        <Checkbox
          size={"xx-small"}
          isChecked={isChecked}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
});

StudentItem.displayName = "StudentItem";

class StudentList extends React.PureComponent {
  render() {
    const {
      students,
      selectedStudentIds,
      onItemCheckBoxClick,
      searchText,
      isAllSelected,
      userInfo: { id: userId, user_type: userType },
      t,
    } = this.props;
    const studentLength = _.get(students, "length", 0);
    return (
      <div className={classes.container}>
        {studentLength > 0 ? (
          <div className={classes.listContainer}>
            {!searchText && (
              <StudentItem
                key={"ALL"}
                item={{ id: "ALL", firstName: t("all"), lastName: "" }}
                isChecked={isAllSelected}
                onItemCheckBoxClick={onItemCheckBoxClick}
              />
            )}
            {/* Make sure student cant uncheck itself by disabling the checkbox*/}
            {_.map(students, item => {
              const isChecked = _.includes(selectedStudentIds, item.node.id);
              return (
                <StudentItem
                  key={item.node.id}
                  item={item.node}
                  isChecked={isChecked}
                  isDisabled={userType == "student" && userId == item.node.id}
                  onItemCheckBoxClick={onItemCheckBoxClick}
                  t={t}
                />
              );
            })}
          </div>
        ) : searchText ? (
          <div className={classes.noDataText}>
            {t("common:no_with_label", {
              label: t("common:student_plural"),
            })}
          </div>
        ) : (
          <div className={classes.emptyStateContainer}>
            <EmptyState
              illustration={AddStudentIllustration}
              title={t("common:no_students_to_choose")}
              subtitle={t("common:request_admin_to_add_students")}
              subtitleStyle={emptyStateSubtitleStyle}
            />
          </div>
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(StudentList);
