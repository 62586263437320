import PropTypes from "prop-types";
import React from "react";

const FamilyCommunicationIcon = props => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 100 100">
      <g transform="translate(1.000000, 0.000000)" fill={props.fill}>
        <path
          d="M97.02,46.95 C97.02,46.85 97.01,46.74 97,46.64 C95.92,36.37 90.97,27.4 83.68,21.1 L83.68,15.21 C83.68,6.83 76.85,0 68.46,0 L15.99,0 C7.6,0 0.78,6.83 0.78,15.21 L0.78,75.38 C0.78,76.51 1.39,77.54 2.37,78.09 C2.83,78.35 3.36,78.48 3.87,78.48 C4.44,78.48 5.01,78.32 5.51,78.01 L22.5,67.38 C28.15,79.48 39.79,88.26 53.9,89.74 C54,89.75 54.09,89.76 54.19,89.76 L73.33,89.96 L92.74,99.67 C93.17,99.89 93.66,99.99 94.13,99.99 C94.69,99.99 95.27,99.83 95.76,99.53 C96.68,98.97 97.23,97.96 97.2201341,96.89 L97.02,46.95 Z M27.8,64.09 C27.36,63.08 26.97,62.05 26.63,61 C25.58,57.78 25.02,54.33 25.02,50.77 C25.02,32.57 39.83,17.76 58.03,17.76 C65.29,17.76 72.03,20.1 77.49,24.08 C78.58,24.87 79.6,25.72 80.58,26.64 C81.69,27.68 82.72,28.78 83.67,29.96 C87.53,34.72 90.1,40.61 90.81,47.13 L91,91.88 L75.44,84.11 C75.02,83.9 74.55,83.79 74.09,83.79 L54.38,83.59 C43.55,82.39 34.44,76.08 29.36,67.2 C28.8,66.19 28.27,65.16 27.8,64.09 Z M20.28,61.47 L6.97,69.79 L6.97,15.21 C6.97,10.24 11.02,6.19 15.99,6.19 L68.46,6.19 C73.43,6.19 77.48,10.24 77.48,15.21 L77.48,16.72 C71.73,13.44 65.08,11.57 58.02,11.57 C36.41,11.57 18.82,29.15 18.82,50.77 C18.82,54.48 19.33,58.06 20.29,61.46 C20.29,61.46 20.29,61.46 20.28,61.47 Z"
          id="Shape"
        ></path>
        <path
          d="M44.49,48.1 L73.82,48.1 C75.53,48.1 76.91,46.71 76.91,45.01 C76.91,43.31 75.52,41.92 73.82,41.92 L44.49,41.92 C42.78,41.92 41.4,43.31 41.4,45.01 C41.4,46.71 42.78,48.1 44.49,48.1 Z"
          id="Path"
        ></path>
        <path
          d="M44.49,62.76 L73.82,62.76 C75.05,62.76 76.11,62.03 76.61,61 C76.8,60.6 76.92,60.14 76.92,59.67 C76.92,57.96 75.53,56.58 73.83,56.58 L44.49,56.58 C42.78,56.58 41.4,57.97 41.4,59.67 C41.4,60.15 41.52,60.6 41.71,61 C42.2,62.04 43.26,62.76 44.49,62.76 Z"
          id="Path"
        ></path>
      </g>
    </svg>
  );
};

FamilyCommunicationIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

FamilyCommunicationIcon.defaultProps = {
  height: 20,
  width: 20,
  fill: "white",
};

export default FamilyCommunicationIcon;
