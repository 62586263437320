import PropTypes from "prop-types";
import React from "react";
// import { colors } from "Constants";

const EyeIcon = props => {
  return (
    <svg viewBox="0 0 16 12" width={props.width} height={props.height}>
      <g stroke="none" strokeWidth="1" fill={props.fill} fillRule="evenodd">
        <path
          d="M8,1.95 C4.8587,1.95 2.1013,5.2073 1.3307,6.206 C2.1013,7.1927 4.8587,10.45 8,10.45 C11.1413,10.45 13.8987,7.1927 14.6693,6.194 C13.8987,5.2073 11.1413,1.95 8,1.95 M8,11.7833 C4.3533,11.7833 1.3827,8.4433 0.276,7.0087 C-0.092,6.5327 -0.092,5.8673 0.276,5.3913 C1.3827,3.9567 4.3533,0.6167 8,0.6167 C11.6467,0.6167 14.6173,3.9567 15.724,5.3913 C16.092,5.8673 16.092,6.5327 15.724,7.0087 C14.6173,8.4433 11.6467,11.7833 8,11.7833"
          id="Fill-111"
        />
        <path
          d="M7.9009,5.0751 C7.2956,5.0751 6.8023,5.5684 6.8023,6.1737 C6.8023,6.7804 7.2956,7.2737 7.9009,7.2737 C8.5063,7.2737 8.9996,6.7804 8.9996,6.1737 C8.9996,5.5684 8.5063,5.0751 7.9009,5.0751 M7.9009,8.6071 C6.5596,8.6071 5.4689,7.5164 5.4689,6.1737 C5.4689,4.8324 6.5596,3.7417 7.9009,3.7417 C9.2423,3.7417 10.3329,4.8324 10.3329,6.1737 C10.3329,7.5164 9.2423,8.6071 7.9009,8.6071"
          id="Fill-112"
        />
      </g>
    </svg>
  );
};

EyeIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

EyeIcon.defaultProps = {
  width: 16,
  height: 12,
  // fill: colors.white
};

export default EyeIcon;
