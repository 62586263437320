import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import ManageFolders from "./routes/ManageFolders";
import PostDetailRoute from "./routes/PostDetailRoute";

export default store => ({
  path: "journal",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/JournalModule").default;
            const post = require("Post/modules/PostModule").default;

            injectReducer(store, {
              key: "post",
              reducer: post,
            });

            injectReducer(store, { key: "journal", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-journal"
        );
      })
  ),
  childRoutes: [ManageFolders(store), PostDetailRoute(store)],
});
