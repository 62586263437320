import React from "react";

import {
  PypCycle,
  PypCycleArchived,
  MypCycle,
  MypCycleArchived,
  IgcseCycle,
  IgcseCycleArchived,
  DpCycle,
  DpCycleArchived,
} from "SvgComponents";

export const SNP_CARD_ICON_MAPPING = {
  IB_PYP: <PypCycle />,
  IB_PYP_ARCHIVED: <PypCycleArchived />,
  IB_MYP: <MypCycle />,
  IB_MYP_ARCHIVED: <MypCycleArchived />,
  UBD: <IgcseCycle />,
  UBD_ARCHIVED: <IgcseCycleArchived />,
  IGCSE: <IgcseCycle />,
  IGCSE_ARCHIVED: <IgcseCycleArchived />,
  IB_DP: <DpCycle />,
  IB_DP_ARCHIVED: <DpCycleArchived />,
};

const getUserTypeFeedLabels = ({ userType, index, t }) => {
  const ongoingCyclesLabel = t("common:ong_with_label", {
    label: t("common:lowercase", {
      text: t("snp:cycle_plural"),
    }),
  });
  const archivedCyclesLabel = t("common:archive_with_label", {
    label: t("common:lowercase", {
      text: t("snp:cycle_plural"),
    }),
  });
  const authAndEvalLabel = t("snp:authorization_evaluation");

  switch (userType) {
    case "admin":
      return [ongoingCyclesLabel, archivedCyclesLabel][index];
    case "teacher":
      return ongoingCyclesLabel;
    case "visitor":
      return authAndEvalLabel;
  }
};

export const getGroupedCycles = ({ cycles, userType, feedType, t }) => {
  if (feedType === "EDIT" || feedType === "VIEW") {
    /** In Admin View, Cycles feed is split into Ongoing cycles and Archived cycles */
    const groupedCycles =
      userType === "admin"
        ? _.partition(cycles, ["isArchived", false])
        : [cycles];

    return _.map(groupedCycles, (cycleGroup, index) => {
      return {
        headerLabel: getUserTypeFeedLabels({ userType, index, t }),
        data: cycleGroup,
      };
    });
  } else {
    return [{ data: cycles }];
  }
};

const getCycleCardDropdownOptions = ({ isTitleEmpty, isArchived, t }) => {
  const allOptions = [
    {
      label: t("snp:unarchive_cycle_label"),
      key: "UNARCHIVE",
      isVisible: isArchived /** show unarchive cycle option for archived cycles and vice versa */,
    },
    {
      label: t("snp:archive_cycle_label"),
      key: "ARCHIVE",
      isVisible: !isArchived,
    },
    {
      label: t("common:delete_with_label", { label: t("snp:cycle") }),
      key: "DELETE",
      isVisible: true,
    },
    {
      label: t("common:edit_with_label", { label: t("common:title") }),
      key: "EDIT",
      isVisible: !isTitleEmpty /** do not show edit title option for old snp cycle with no title */,
    },
  ];

  const filteredOptions = _.filter(allOptions, { isVisible: true });

  return filteredOptions;
};

export const getCycleCardDropdownOptionsMemoized = _.memoize(
  params => getCycleCardDropdownOptions(params),
  params => JSON.stringify(params)
);

export const CYCLE_TYPE_LOCALE_MAPPING = {
  AUTHORIZATION: "snp:authorization",
  EVALUATION: "common:evaluation",
};
