import PropTypes from "prop-types";
import React from "react";
import { colors } from "Constants";
const CoachmarkArrowSvg = props => {
  return (
    <svg width="47px" height="15px" viewBox="0 0 47 15">
      <g
        id="Onboarding-Coachmarks"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M0.922018713,1.58815784 C17.6565457,-1.05413589 28.8331488,1.00647837 40.4435887,9.11501489 L40.4435887,9.11501489 L45.196,12.821 L42.3916455,6.11431398 C42.2969422,5.88785901 42.3813582,5.63127497 42.5802139,5.50222477 L42.6600228,5.46011703 C42.9147846,5.35357586 43.2076785,5.47373245 43.3142197,5.72849428 L43.3142197,5.72849428 L46.8555738,14.1965998 C47.0060042,14.5563099 46.7051313,14.9421364 46.3196066,14.8839011 L46.3196066,14.8839011 L37.2437865,13.512956 C36.9707417,13.4717113 36.7828305,13.2169293 36.8240752,12.9438844 C36.8653199,12.6708396 37.1201019,12.4829285 37.3931468,12.5241732 L37.3931468,12.5241732 L44.581,13.61 L39.8498523,9.91924271 C28.6425471,2.09306659 17.869971,0.0280394444 1.75264505,2.47150406 L1.07798129,2.57592081 C0.805218076,2.61898868 0.549186393,2.43278382 0.506118517,2.16002061 C0.463050642,1.8872574 0.649255502,1.63122572 0.922018713,1.58815784 Z"
          id="Path"
          fill={props.fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

CoachmarkArrowSvg.propTypes = {
  fill: PropTypes.string,
};

CoachmarkArrowSvg.defaultProps = {
  fill: colors.salmon60,
};

export default CoachmarkArrowSvg;
