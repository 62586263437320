import React from "react";
import { I18nHOC, SlickSlider } from "UIComponents";
import classes from "./Testimonials.scss";
import { colors, CLOUD_URL } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import {
  setActiveParent,
  setActiveProfileDetailModal,
} from "Community/modules/CommunityModule";
import { isDesktop, isTablet } from "Utils";
import { QuoteIcon } from "SvgComponents";

const arrowStyle = {
  backgroundColor: "unset",
  boxShadow: "unset",
  borderWidth: 0,
};

const slickSliderStyle = {
  customPrevArrowStyle: {
    ...arrowStyle,
    top: isDesktop() ? "calc(50% - 24px)" : "100px",
    left: isDesktop() ? "-32px" : "16px",
  },
  customNextArrowStyle: {
    ...arrowStyle,
    top: isDesktop() ? "calc(50% - 24px)" : "100px",
    right: isDesktop() ? "-32px" : "16px",
  },
  customArrowIconContainerStyle: {
    fill: colors.gray72,
    width: isDesktop() ? 24 : isTablet() ? 20 : 9,
    height: isDesktop() ? 48 : isTablet() ? 40 : 16,
  },
};

const options = [
  {
    id: 2,
    url: `${CLOUD_URL}/assets/webapp/Community/community_testimonial_1.svg`,
    comment:
      "Toddle Community has transformed how I plan for learning! With Community, there’s now a never a dearth of inspiration for us as we plan units. I love that I can also share my creations with PYP educators across the world - a platform I never had before",
    name: "Alex Whitaker",
    role: "PYP Coordinator",
    school: "International School of Stuttgart",
  },
  {
    id: 2,
    url: `${CLOUD_URL}/assets/webapp/Community/community_testimonial_2.svg`,
    comment:
      "A great platform to connect, create and collaborate with the PYPers around the world!",
    name: "Nishat Wahid Makda",
    role: "Nursery Homeroom Teacher",
    school: "D.Y. Patil International School",
  },
];

const Testimonials = ({ t }) => {
  const quoteScale = isDesktop() ? "1.4" : isTablet() ? "1" : ".85";

  const renderCarouselItem = ({ item, index }) => {
    const { url, comment, name, role, school, id } = item;

    // const rethumpUrl = getRethumbUrl({
    //   width: 512,
    //   height: 512,
    //   imageUrl: this.getValue({ item: node, key: "image.value" }),
    //   fitIn: true
    // });

    return (
      <div key={id} className={classes.carouselItem}>
        <div className={classes.imageContainer}>
          <img src={url} />
        </div>

        <div className={classes.content}>
          <div className={classes.detailContainer}>
            <span className={classes.leftQuote}>
              <QuoteIcon scale={quoteScale} fill={colors.yellow50} />
            </span>
            <div className={classes.comment}>{comment}</div>
            <span className={classes.rightQuote}>
              <span className={classes.rightQuoteWrapper}>
                <QuoteIcon scale={quoteScale} fill={colors.yellow50} />
              </span>
            </span>
          </div>

          <div className={classes.userInfo}>
            <div className={classes.user}>
              <span className={classes.name}>{name}</span>&nbsp;
              <span className={classes.role}>{role}</span>
            </div>
            <div className={classes.school}>{school}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <SlickSlider
          items={options}
          renderItem={renderCarouselItem}
          variableWidth={false}
          showDots={true}
          infinite={true}
          containerStyle={{ width: "100%" }}
          autoplay={true}
          autoplaySpeed={6000}
          {...slickSliderStyle}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: _.get(state, "login.userInfo.id"),
  };
};

const mapActionCreators = {
  setActiveParent,
  setActiveProfileDetailModal,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] })
)(Testimonials);
