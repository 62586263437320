import _ from "lodash";
import update from "immutability-helper";
import client from "apolloClient";
import {
  createSubjectMutation,
  updateSubjectMutation,
  deleteSubjectMutation,
} from "SubjectsSetup/modules/Mutations";
import { getOrganizationSubjectGroupsQuery } from "modules/CommonQuery";
import { setToastMsg } from "Login/modules/LoginModule";
import { getOrganizationSubjectsQuery } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorQueries";
import { getUserCourseTagsFilters } from "Courses/modules/utils";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";
import { getDPSubjectGroupsQuery } from "Administrator/routes/DPAcademicSetup/routes/DPSubjects/modules/DPSubjectsQueries";
// Consts
export const NAME = "subjectsSetup";
export const CHANGE_SEARCH_TERM = "CHANGE_SEARCH_TERM" + " " + NAME;
export const INIT_SEARCH_TERM = "INIT_SEARCH_TERM" + " " + NAME;
export const TOGGLE_IS_LOADING = "TOGGLE_IS_LOADING" + " " + NAME;

export const changeSearchTerm = value => {
  return { type: CHANGE_SEARCH_TERM, payload: value };
};

export const initSearchTerm = () => {
  return {
    type: INIT_SEARCH_TERM,
  };
};

export const toggleIsLoading = data => {
  return {
    type: TOGGLE_IS_LOADING,
    data,
  };
};

export const createSubjectMutationFn = ({ input, filters, isMyp = false }) => {
  return async (dispatch, getState) => {
    dispatch(toggleIsLoading(true));
    const organizationId = getState().login.userInfo.org_id;
    const userTags = _.get(getState(), "login.userInfo.tags", []);
    const filteredTags = getUserCourseTagsFilters({ userTags });

    const inputWithTagFilters = { ...input, ...filteredTags };

    try {
      const res = await client.mutate({
        mutation: createSubjectMutation,
        variables: inputWithTagFilters,
        awaitRefetchQueries: true,
        refetchQueries: isMyp
          ? [
              {
                query: getOrganizationSubjectGroupsQuery,
                variables: {
                  id: organizationId,
                  filters,
                },
              },
            ]
          : [
              {
                query: getOrganizationSubjectsQuery,
                variables: {
                  id: organizationId,
                  filters,
                },
              },
            ],
      });
      dispatch(toggleIsLoading(false));
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_action_subject",
          locale_params: [
            { key: "action", value: "toastMsgs:added", isPlainText: false },
          ],
        })
      );
      return res;
    } catch (err) {
      dispatch(toggleIsLoading(false));
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return err;
    }
  };
};

export const updateSubjectMutationFn = input => {
  return (dispatch, getState) => {
    client
      .mutate({
        mutation: updateSubjectMutation,
        variables: {
          ...input,
        },
      })
      .catch(err => {
        console.error(err);
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

const getRefetchQuery = ({ curriculumType }) => {
  switch (curriculumType) {
    case CURRICULUM_TYPE_MYP:
      return getOrganizationSubjectGroupsQuery;
    case CURRICULUM_TYPE_DP:
      return getDPSubjectGroupsQuery;
    default:
      return getOrganizationSubjectsQuery;
  }
};

export const deleteSubjectMutationFn = ({ id, filters, curriculumType }) => {
  return (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;
    const query = getRefetchQuery({ curriculumType });
    client
      .mutate({
        mutation: deleteSubjectMutation,
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query,
            variables: {
              id: organizationId,
              filters,
            },
          },
        ],
      })
      .then(res => {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:successfully_action_subject",
            locale_params: [
              { key: "action", value: "toastMsgs:deleted", isPlainText: false },
            ],
          })
        );
      })
      .catch(err => {
        console.error(err);
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

// Reducer Handlers
const REDUCER_HANDLERS = {
  [CHANGE_SEARCH_TERM]: (state, action) => {
    return update(state, { searchText: { $set: action.payload } });
  },
  [INIT_SEARCH_TERM]: (state, action) => {
    return update(state, { searchText: { $set: initialState.searchText } });
  },
  [TOGGLE_IS_LOADING]: (state, action) => {
    return update(state, { isLoading: { $set: action.data } });
  },
};

// Initial State and export Reducer

const initialState = {
  isLoading: false,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
