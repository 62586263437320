export const isURL = str => {
  // var a = document.createElement("a");
  // a.href = str;
  // return a.host && a.host != window.location.host;
  // console.log(str);
  // var regexQuery ="^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
  // var regexQuery = "^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)";
  // var url = new RegExp(regexQuery, "i");
  var url = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
  // console.log(url.test(str));
  return url.test(str);
};

export const addHttpInUrl = url => {
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url;
};

export const addHttpsInUrl = url => {
  if (url === "") {
    return url;
  }
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    if (_.includes(url, "www")) {
      url = `https://${url}`;
    } else {
      url = `https://www.${url}`;
    }
  }
  return url;
};
