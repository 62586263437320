import React, { Fragment } from "react";
import classes from "./PostFeed.scss";
import { PostCard } from "AppComponents";
import update from "immutability-helper";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const PostFeed = React.memo(
  ({
    items,
    isMultiSelect,
    selectedItems,
    updateSelectedItems,
    styles,
    disabledItems,
    cardWrapperElement,

    customHeaderElement,
  }) => {
    const checkIsSelected = ({ id }) => {
      let isSelected = false;
      if (isMultiSelect) {
        isSelected = _.includes(selectedItems, id);
      } else {
        isSelected = selectedItems == id;
      }
      return isSelected;
    };

    const selectPost = ({ id }) => {
      if (isMultiSelect) {
        const index = _.indexOf(selectedItems, id);
        let selectedItemsLocal = [];
        if (index < 0) {
          selectedItemsLocal = update(selectedItems, { $push: [id] });
        } else {
          selectedItemsLocal = update(selectedItems, { $splice: [[index, 1]] });
        }
        updateSelectedItems(selectedItemsLocal);
      } else {
        updateSelectedItems(selectedItems == id ? "" : id);
      }
    };

    const checkIsDisabled = ({ id }) => {
      return _.findIndex(disabledItems, item => item == id) > -1;
    };

    const renderPostFeed = () => {
      return (
        <Fragment>
          {customHeaderElement &&
            React.cloneElement(customHeaderElement, {
              count: _.size(items),
            })}
          <div className={classes.postFeedCon} style={styles.itemFeedContainer}>
            {_.map(items, item => {
              const isSelected = checkIsSelected({ id: item.id });
              const isDisabled = checkIsDisabled({ id: item.id });
              const additionalProps = cardWrapperElement
                ? { data: item, isSelected, onCardClick: selectPost }
                : {};
              return (
                <div className={classes.postFeedItem}>
                  <ConditionalWrapper
                    key={item.id}
                    type="wrapperAsElement"
                    wrapper={cardWrapperElement}
                    {...additionalProps}
                    condition={!!cardWrapperElement}
                  >
                    <PostCard
                      id={item.id}
                      key={item.id}
                      title={item.title}
                      createdBy={item.createdBy}
                      attachments={item.attachments}
                      onPostSelectionCardClick={selectPost}
                      isSelected={isSelected}
                      isDisabled={isDisabled}
                      styles={styles}
                      hideDefaultActions={!!cardWrapperElement}
                      items={_.get(item, "items", [])}
                    />
                  </ConditionalWrapper>
                </div>
              );
            })}
          </div>
        </Fragment>
      );
    };

    return <div className={classes.container}>{renderPostFeed()}</div>;
  }
);

export default PostFeed;
