import React, { Fragment, useRef } from "react";
import classes from "./ActionTable.scss";
import { DeleteIcon, CheckboxOn } from "SvgComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { UIBaseContentEditable } from "UIComponents";
import { generateRandomId } from "Utils";
import update from "immutability-helper";
import { useI18n } from "Hooks";
import { getStyleStrippedText } from "Utils";
import { Button } from "@toddle-design/web";

const styles = {
  deleteIconStyle: {
    cursor: "pointer",
  },
  buttonContainerStyle: {
    borderRadius: "8px",
  },
  contentEditableStyle: {
    maxHeight: "200px",
    overflowY: "auto",
  },
};

const DEFAULT_ROWS_TO_INIT = 1;

export const ActionTable = props => {
  const {
    columns = [],
    rows = [],
    onRowAdd = () => {},
    onCellValueChange = () => {},
    onRowSelect = () => {},
    onRowDelete = () => {},
    updateFocusCount,
    isTableEmpty,
  } = props;

  const { t } = useI18n(["common"]);
  const cellsRef = useRef({});
  return (
    <Fragment>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.deleteIconCell} />
            <th className={classes.checkboxCell}></th>
            {_.map(columns, column => {
              const { id, label } = column;
              return <th key={id}>{label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(rows, row => {
            const { id: rowId, data = [], isChecked = false } = row;
            return (
              <tr key={rowId} className={classes.row}>
                <td className={classes.deleteIconCell}>
                  <div className={classes.deleteIcon}>
                    {!isTableEmpty && (
                      <DeleteIcon
                        onClick={() => {
                          onRowDelete({ rowId });
                        }}
                        style={styles.deleteIconStyle}
                      />
                    )}
                  </div>
                </td>
                <td className={classes.checkboxCell}>
                  <div className={classes.checkboxCellContent}>
                    <div
                      className={
                        isChecked
                          ? classes.selectedradioButton
                          : classes.radiobutton
                      }
                      onClick={() => {
                        onRowSelect({
                          rowId,
                          isChecked: !isChecked,
                        });
                      }}
                    >
                      {isChecked ? <CheckboxOn /> : null}
                    </div>
                  </div>
                </td>
                {_.map(data, cell => {
                  let { id: cellId, value: cellValue } = cell;
                  cellValue = getStyleStrippedText({ text: cellValue });

                  return (
                    <td
                      key={cellId}
                      cellPadding={0}
                      cellSpacing={0}
                      onClick={() => cellsRef.current[cellId].focus()}
                    >
                      <UIBaseContentEditable
                        ref={ref => (cellsRef.current[cellId] = ref)}
                        name={"label"}
                        mode={"edit"}
                        allowEnter={true}
                        style={styles.contentEditableStyle}
                        shouldPasteAsPlainText={false}
                        shouldParseInPlainText={false}
                        updateInputField={params => {
                          onCellValueChange({
                            rowId,
                            cellId,
                            value: params.label,
                          });
                        }}
                        value={cellValue}
                        onFocusInputField={() => updateFocusCount({ value: 1 })}
                        onBlurInputField={() => updateFocusCount({ value: -1 })}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={classes.actionButton}>
        <Button onClick={onRowAdd} variant={"outlined-subtle"}>
          {t("common:add_a_with_label", {
            label: t("common:row"),
          })}
        </Button>
      </div>
    </Fragment>
  );
};

const ActionTableFieldComponent = props => {
  const {
    fieldTypeConfig: {
      columns: templateColumns = {},
      columnsList: templateColoumnList = [],
    } = {},
    fieldKey,
    updateInputField,
    onBlurInputField,
    onFocusInputField,
    value: { rows: valueRows = [], columns: valueColumns = [] } = {},
  } = props;
  const focusedCount = useRef(0);
  const templateColArr = _.map(templateColoumnList, columnId => {
    return templateColumns[columnId];
  });

  const columns = valueColumns.length ? valueColumns : templateColArr;

  const updateField = params => {
    updateInputField({
      [fieldKey]: {
        rows,
        columns,
        ...params,
      },
    });
  };

  const getNewRow = () => {
    return {
      id: generateRandomId(),
      isChecked: false,
      data: _.map(columns, col => {
        return {
          id: generateRandomId(),
          uid: col.id,
          value: "",
        };
      }),
    };
  };

  const rows = (() => {
    if (valueRows.length) {
      return valueRows;
    }

    /**
     * @description : by default create new rows
     */
    return _.map([...Array(DEFAULT_ROWS_TO_INIT)], () => {
      return getNewRow();
    });
  })();

  const onRowAdd = () => {
    const row = getNewRow();
    updateField({
      rows: [...rows, row],
    });
  };

  const onRowDelete = ({ rowId }) => {
    const updatedRows = _.filter(rows, row => {
      return row.id != rowId;
    });
    updateField({
      rows: updatedRows,
    });
  };

  const onCellValueChange = ({ rowId, cellId, value }) => {
    const targetRowIndex = _.findIndex(rows, { id: rowId });
    const targetCellIndex = _.findIndex(rows[targetRowIndex].data, {
      id: cellId,
    });
    const updatedRows = update(rows, {
      [targetRowIndex]: {
        data: {
          [targetCellIndex]: {
            value: {
              $set: value,
            },
          },
        },
      },
    });

    updateField({
      rows: updatedRows,
    });
  };

  const onRowSelect = value => {
    const { rowId } = value;
    const targetRowIndex = _.findIndex(rows, { id: rowId });
    const updatedRows = update(rows, {
      [targetRowIndex]: {
        isChecked: {
          $set: value.isChecked,
        },
      },
    });
    updateField({
      rows: updatedRows,
    });
  };
  const updateFocusCount = ({ value }) => {
    const updatedFocusedCount = focusedCount.current + value;

    if (focusedCount.current == 0 && updatedFocusedCount != 0) {
      onFocusInputField({ key: "actionPlan", id: null });
    } else if (updatedFocusedCount == 0 && focusedCount.current != 0) {
      onBlurInputField({ key: "actionPlan", id: null });
    }
    focusedCount.current = updatedFocusedCount;
  };

  //If all the rows are empty or if row is created but no data has been added to any column.
  const isTableEmpty =
    _.isEmpty(valueRows) ||
    (_.isEqual(_.size(rows), 1) &&
      _.isEmpty(_.filter(_.first(rows)?.data, item => item?.value)));

  return (
    <ActionTable
      columns={columns}
      rows={rows}
      onRowAdd={onRowAdd}
      onRowDelete={onRowDelete}
      onCellValueChange={onCellValueChange}
      onRowSelect={onRowSelect}
      updateFocusCount={updateFocusCount}
      isTableEmpty={isTableEmpty}
    />
  );
};

class ActionTableUIComponent extends UIBaseComponent {
  renderEdit = () => {
    const { props } = this;
    return <ActionTableFieldComponent {...props} />;
  };
}

ActionTableUIComponent.defaultProps = {
  ...UIBaseComponent.defaultProps,
  editContainerStyle: { flexDirection: "column" },
};

export default ActionTableUIComponent;
