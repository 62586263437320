import React from "react";
import classes from "./Planathon.scss";
import { goToBack, goToPlatformHomeChildRoute } from "modules/NavigationModule";
import { connect } from "react-redux";
import {
  FullScreenLoader,
  withLoader,
  withPusherBind,
  I18nHOC,
} from "UIComponents";
import ShareModal from "./ShareModal";
import {
  getStaffBasicDetailsFromCache,
  getOrganizationUnitPlanFeedCountFromCache,
} from "modules/CommonGraphqlHelpers";
import { downloadFromUrl } from "modules/Services";
import {
  navigateToClassSelector,
  createUserPlanathonRequest,
  onClassSelect,
} from "Teacher/modules/TeacherModule";
import {
  HeroComponent,
  PlanathonDetails,
  ParticipantDetails,
  ReviewPanel,
  ParticipantSteps,
  ImportantDates,
  PlanathonCategory,
  ParticipantNextStep,
} from "./components";
import { CloseIcon } from "SvgComponents";
import { createOrganizationInvitedUser } from "TeacherSetup/modules/Module";
import * as EventTracker from "lib/eventTracker";
import { getStaffCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { compose, graphql } from "react-apollo";
import { getStaffCoursesQuery } from "Teacher/modules/TeacherQuery";
import { ClassSelectionModal, InviteTeacherModal } from "AppComponents";
import { goToCreateUnitPlan } from "UnitPlans/modules/UnitPlanModule";
import { getOrganizationUnitPlanFeedCountQuery } from "modules/CommonQuery";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import ACLStore from "lib/aclStore";

class Planathon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRequestedForPlanathon: props.isRequestedForPlanathon,
      showShareModal: false,
      isActivityLoading: false,
      showClassSelectionModal: false,
      showInviteModal: false,
    };
  }

  componentDidMount = () => {
    EventTracker.recordEvent({
      eventName: "Viewed Page",
      eventData: { page: "planathon" },
    });
  };

  onInClick = async () => {
    const { createUserPlanathonRequest } = this.props;
    try {
      this.setState({ isActivityLoading: true });
      await createUserPlanathonRequest();
      this.setState({ showShareModal: true, isRequestedForPlanathon: true });
      EventTracker.recordEvent({
        eventName: "Registered for Planathon",
        eventData: {},
      });
    } finally {
      this.setState({ isActivityLoading: false });
    }
  };

  onSubmissionClick = () => {
    this.onNextStepButtonClick({ type: "SUBMIT_UNIT" });
  };

  onClose = () => {
    this.setState({ showShareModal: false });
  };

  onCloseClassSelectionModal = () => {
    this.setState({ showClassSelectionModal: false });
  };

  onClassSelect = ({ id }) => {
    const { goToCreateUnitPlan } = this.props;
    this.setState({ showClassSelectionModal: false });

    goToCreateUnitPlan({ courseId: id, replacePath: "planathon" });
  };

  onClickSampleUrl = courseId => {
    const { goToPlatformHomeChildRoute } = this.props;

    goToPlatformHomeChildRoute({
      route: `courses/${courseId}`,
      isOpenInNewTab: true,
    });
  };

  onNextStepButtonClick = ({ type }) => {
    const {
      onClassSelect,
      demoCourse,
      goToPlatformHomeChildRoute,
    } = this.props;
    switch (type) {
      case "SAMPLE_UNIT":
        this.onClickSampleUrl(_.get(demoCourse, "id", ""));
        //onClassSelect(_.get(demoCourse, "id", ""), true);
        break;

      case "INVITE":
        this.setState({ showInviteModal: true });
        break;

      case "CREATE_UNIT":
        this.setState({ showClassSelectionModal: true });
        break;

      case "SUBMIT_UNIT":
        goToPlatformHomeChildRoute({ route: "planathonunitplans" });
        break;
    }
  };

  onClickCancelInvite = () => {
    this.setState({ showInviteModal: false });
  };

  createOrganizationInvitedUser = async ({ invitedUsers }) => {
    const { createOrganizationInvitedUser } = this.props;
    const result = await createOrganizationInvitedUser({
      invitedUsers,
    });

    return result;
  };

  render() {
    const {
      goToBack,
      isCurriculumProgramFree,
      demoCourse,
      downloadFromUrl,
      navigateToClassSelector,
      totalPlanathonUnits,
    } = this.props;
    const {
      showShareModal,
      isActivityLoading,
      isRequestedForPlanathon,
      showClassSelectionModal,
      showInviteModal,
    } = this.state;

    return (
      <React.Fragment>
        {showShareModal && <ShareModal onClose={this.onClose} />}
        {isActivityLoading && <FullScreenLoader></FullScreenLoader>}
        <div className={classes.container}>
          <div
            className={classes.cancelIcon}
            onClick={() => navigateToClassSelector({ type: "push" })}
          >
            <CloseIcon />
          </div>

          <HeroComponent
            onSubmissionClick={this.onSubmissionClick}
            onClose={goToBack}
            isRequestedForPlanathon={isRequestedForPlanathon}
            totalPlanathonUnits={totalPlanathonUnits}
          ></HeroComponent>
          <PlanathonDetails></PlanathonDetails>

          <ReviewPanel></ReviewPanel>
          <PlanathonCategory></PlanathonCategory>

          <ParticipantDetails></ParticipantDetails>

          {!isCurriculumProgramFree ? (
            <ParticipantSteps></ParticipantSteps>
          ) : (
            <ParticipantNextStep
              isCurriculumProgramFree={isCurriculumProgramFree}
              downloadFromUrl={downloadFromUrl}
              onNextStepButtonClick={this.onNextStepButtonClick}
              demoCourse={demoCourse}
              totalPlanathonUnits={totalPlanathonUnits}
            ></ParticipantNextStep>
          )}
          <ImportantDates
            onInClick={this.onInClick}
            isRequestedForPlanathon={isRequestedForPlanathon}
          ></ImportantDates>
        </div>
        {showClassSelectionModal && (
          <ClassSelectionModal
            onClose={this.onCloseClassSelectionModal}
            onClassSelect={this.onClassSelect}
          ></ClassSelectionModal>
        )}
        {showInviteModal && (
          <InviteTeacherModal
            parentType={"planathon page"}
            createOrganizationInvitedUser={this.createOrganizationInvitedUser}
            onCancelClick={this.onClickCancelInvite}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  createUserPlanathonRequest,
  goToBack,
  downloadFromUrl,
  navigateToClassSelector,
  onClassSelect,
  goToCreateUnitPlan,
  createOrganizationInvitedUser,
  goToPlatformHomeChildRoute,
};

const mapStateToProps = state => {
  const userId = state.login.userInfo.id;
  const userDetails = getStaffBasicDetailsFromCache(userId);
  const isRequestedForPlanathon = _.get(
    userDetails,
    "isRequestedForPlanathon",
    false
  );

  return {
    isRequestedForPlanathon,
    isCurriculumProgramFree: getCurriculumProgramFreeStatus({ state }),
    isData: true,
    isLoading: false,
    userId,
    organizationId: state.login.userInfo.org_id,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["planathon"] }),
  graphql(getStaffCoursesQuery, {
    name: "getStaffCourses",
    options: ({ userId, isCurriculumProgramFree }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        isCurriculumProgramFree,
      },
    }),
    props({
      getStaffCourses,
      ownProps: { userId, isData, isLoading, isCurriculumProgramFree },
    }) {
      const queryData = getStaffCoursesFromCache(
        userId,
        isCurriculumProgramFree
      );
      const allCourses = _.get(queryData, "courses", []);
      const courses = _.filter(allCourses, { isDemo: false });
      const demoCourse = _.find(allCourses, { isDemo: true });
      return {
        isData: !_.isEmpty(queryData) && isData,
        courses,
        demoCourse,
        isLoading:
          getStaffCourses["networkStatus"] == 1 ||
          getStaffCourses["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationUnitPlanFeedCountQuery, {
    name: "getOrganizationUnitPlanFeedCount",
    skip: () => !ACLStore.can("TeacherPortal:PlanathonSubmission"),
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: organizationId,
        getPlanathonUnits: true,
      },
    }),
    props({
      getOrganizationUnitPlanFeedCount,
      ownProps: { organizationId, isData, isLoading },
    }) {
      const organizationData = getOrganizationUnitPlanFeedCountFromCache({
        organizationId,
        getPlanathonUnits: true,
      });

      return {
        getOrganizationUnitPlanFeedCount,
        onPusherEventTriggered: () => {
          getOrganizationUnitPlanFeedCount.refetch();
        },
        totalPlanathonUnits: _.get(
          organizationData,
          "unitPlans.totalCount",
          []
        ),
        isData: !_.isEmpty(organizationData) && isData,
        networkStatus: getOrganizationUnitPlanFeedCount.networkStatus,
        isLoading:
          getOrganizationUnitPlanFeedCount["networkStatus"] == 1 ||
          getOrganizationUnitPlanFeedCount["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withPusherBind(["unitPlanFeedUpdate"]),
  withLoader
)(Planathon);
