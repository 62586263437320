import { colors } from "Constants";
import {
  PLAN_TYPE_360,
  PLAN_TYPE_360_PILOT,
  PLAN_TYPE_DEMO,
  PLAN_TYPE_FREE,
  PLAN_TYPE_PRO,
  PLAN_TYPE_PRO_PILOT,
} from "Constants/stringConstants";

export const getOnboardingFilters = (onboardingAnalytics, feedAnalytics) => {
  return [
    {
      key: "ALL",
      label: "Schools onboarding",
      value: "",
      count: _.get(feedAnalytics, "ONBOARDING", 0),
    },
    {
      key: "SETUP_COMPLETED",
      label: "Setup complete",
      value: "COMPLETE",
      color: colors.textSuccess,
      count: _.get(onboardingAnalytics, "COMPLETE", 0),
    },
    {
      key: "NOT_STARTED",
      label: "Not started",
      value: "NOT_STARTED",
      color: colors.textSubtle,
      count: _.get(onboardingAnalytics, "NOT_STARTED", 0),
    },
    {
      key: "IN_PROGRESS",
      label: "In progress",
      value: "IN_PROGRESS",
      color: colors.textWarning,
      count: _.get(onboardingAnalytics, "IN_PROGRESS", 0),
    },
    {
      key: "QC_INITIATED",
      label: "Review requested",
      value: "QC_INITIATED",
      color: colors.textCritical,
      count: _.get(onboardingAnalytics, "QC_INITIATED", 0),
    },
  ];
};

export const getTransitionFilters = (transitionAnalytics, feedAnalytics) => {
  return [
    {
      key: "ALL",
      label: "Academic transition",
      value: "",
      count: _.get(feedAnalytics, "TRANSITION", 0),
    },
    {
      key: "IN_30_7",
      label: "30 days left",
      value: "IN_30_7",
      color: colors.textSubtle,
      count: _.get(transitionAnalytics, "30_7", 0),
    },
    {
      key: "IN_7_0",
      label: "7 days left",
      value: "IN_7_0",
      color: colors.textWarning,
      count: _.get(transitionAnalytics, "7_0", 0),
    },
    {
      key: "OVERDUE",
      label: "Overdue",
      value: "OVERDUE",
      color: colors.textCritical,
      count: _.get(transitionAnalytics, "OVERDUE", 0),
    },
  ];
};

export const getCurriculumPlanFilters = (
  subscriptionPlanAnalytics,
  feedAnalytics
) => {
  return [
    {
      key: "ALL",
      label: "School organizations",
      value: "",
      count: _.get(feedAnalytics, "ONBOARDED", 0),
    },
    {
      key: PLAN_TYPE_DEMO,
      label: "Demo Plan",
      value: "6",
      color: colors.textSubtle,
      count: _.get(subscriptionPlanAnalytics, PLAN_TYPE_DEMO, 0),
    },
    {
      key: PLAN_TYPE_FREE,
      label: "Free Plan",
      value: "1",
      color: colors.textWarning,
      count: _.get(subscriptionPlanAnalytics, PLAN_TYPE_FREE, 0),
    },
    {
      key: PLAN_TYPE_PRO,
      label: "Pro Plan",
      value: "2",
      color: colors.textCritical,
      count: _.get(subscriptionPlanAnalytics, PLAN_TYPE_PRO, 0),
    },
    {
      key: PLAN_TYPE_PRO_PILOT,
      label: "Pro Pilot Plan",
      value: "3",
      color: colors.textSubtle,
      count: _.get(subscriptionPlanAnalytics, PLAN_TYPE_PRO_PILOT, 0),
    },
    {
      key: PLAN_TYPE_360,
      label: "360 Plan",
      value: "4",
      color: colors.violet63,
      count: _.get(subscriptionPlanAnalytics, PLAN_TYPE_360, 0),
    },
    {
      key: PLAN_TYPE_360_PILOT,
      label: "360 Pilot Plan",
      value: "5",
      color: colors.red66,
      count: _.get(subscriptionPlanAnalytics, PLAN_TYPE_360_PILOT, 0),
    },
  ];
};

const getFilters = ({
  tab,
  toddleBuddy,
  plan,
  searchText,
  onboardingFilter,
  transitionFilter,
}) => {
  const filters = {
    searchText,
    feedType: tab,
    onboardingStatus:
      onboardingFilter && tab == "ONBOARDING" ? [onboardingFilter] : undefined,
    toddleBuddy: toddleBuddy ? toddleBuddy : undefined,
    planType: plan ? plan : undefined,
    transitionStatus:
      transitionFilter && tab == "TRANSITION" ? transitionFilter : undefined,
  };
  return filters;
};

export const getFiltersMemoized = _.memoize(
  params => getFilters(params),
  params => JSON.stringify(params)
);
