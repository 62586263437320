import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import classes from "./LinkWithTooltip.scss";
import "rc-tooltip/assets/bootstrap.css";

class LinkWithTooltip extends Component {
  state = {
    showTooltip: this.props.alwaysShowTooltip,
  };
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll, true);
  };

  handleScroll = () => {
    const { shouldDestroyOnScroll, alwaysShowTooltip } = this.props;
    const { showTooltip } = this.state;
    if (shouldDestroyOnScroll && showTooltip) {
      if (!alwaysShowTooltip) {
        this.onVisibleChange(false);
      }
    }
  };

  onMouseLeave = () => {
    const { alwaysShowTooltip } = this.props;
    if (!alwaysShowTooltip) {
      this.setState({
        showTooltip: false,
      });
    }
  };
  onMouseEnter = () => {
    this.setState({
      showTooltip: true,
    });
  };

  onVisibleChange = visible => {
    const { alwaysShowTooltip } = this.props;
    if (!alwaysShowTooltip) {
      this.setState({
        showTooltip: visible,
      });
      if (this.props.onVisibleChange) this.props.onVisibleChange(visible);
    }
  };

  render() {
    const {
      trigger,
      placement,
      children,
      tooltip,
      id,
      linkStyles,
      overlayStyle,
      prefixCls,
      getTooltipContainer,
      alignConfig,
      isVisible,
      tooltipContainerStyle,
      mouseEnterDelay,
      linkProps,
    } = this.props;
    const { showTooltip } = this.state;
    const tooltipComp = showTooltip ? (
      React.isValidElement(tooltip) ? (
        <div
          id={id}
          className={classes.tooltipContainer}
          style={tooltipContainerStyle}
        >
          {tooltip}
        </div>
      ) : (
        <div
          id={id}
          className={classes.tooltipContainer}
          style={tooltipContainerStyle}
          dangerouslySetInnerHTML={{ __html: tooltip }}
        />
      )
    ) : (
      <div />
    );
    return (
      <Tooltip
        onVisibleChange={this.onVisibleChange}
        placement={placement}
        trigger={trigger}
        overlay={tooltipComp}
        visible={isVisible && showTooltip}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={0.2}
        overlayStyle={overlayStyle}
        prefixCls={prefixCls}
        destroyTooltipOnHide={true}
        getTooltipContainer={getTooltipContainer}
        align={alignConfig}
      >
        <a style={linkStyles} {...linkProps}>
          {children}
        </a>
      </Tooltip>
    );
  }
}

LinkWithTooltip.propTypes = {
  // tooltip:PropTypes.func,
  // href:PropTypes.String,
  // children:PropTypes.Element,
  // id:PropTypes.String,
  // placement:PropTypes.String
};

LinkWithTooltip.defaultProps = {
  tooltip: "Tooltip",
  isVisible: true,
  placement: "top",
  linkStyles: {},
  trigger: ["hover"],
  overlayStyle: { zIndex: 1180 }, // Added this default props to show tooltip in modals
  prefixCls: "rc-tooltip",
  getTooltipContainer: undefined,
  shouldDestroyOnScroll: true,
  alignConfig: {},
  alwaysShowTooltip: false,
  mouseEnterDelay: 0.2,
  linkProps: {
    href: null,
  },
};

export default LinkWithTooltip;
