import React, { useState, useEffect, useCallback } from "react";
import classes from "./TableRow.scss";
import { observeElementInViewport } from "observe-element-in-viewport";
import LearningStandards from "./Components/LearningStandards";
import { Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import {
  onAddNewNode,
  onLeftIndentNode,
  onRightIndentNode,
  updateNode,
  columnIds,
  pasteBulkBenchmarkItems,
  onBulkAddTags,
  onUpdateNodeGrades,
  onLeftIndentGrades,
  onRightIndentGrades,
  getUniqueUpdatedNodes,
  getUpdatedNodeForDraft,
  getFilteredColumns,
} from "NodeEditor/Utils";
import CodeCell from "./Components/CodeCell";
import TagCell from "./Components/TagCell";
import GradesCell from "./Components/GradesCell";
import QuestionsCount from "./Components/QuestionsCount";
import { scrollIntoView } from "Utils";

const TableRow = props => {
  const {
    nodes,
    setNodes,
    nodeId,
    mode,
    maxDepth,
    indexCountArray,
    droppableSnapshot,
    setNodesRef,
    placeholdersText,
    changeSelectedSidebarItem,
    gradeOptions,
    filters,
    updateNodesInDraft,
    userId,
    currentEditorConfig,
    organizationTags,
    inputForMultiLevelTagsQuery,
    organizationTagsObjectMap,
    isActionDisabled,
    isCoachmarksRunning,
    showSelectionBox,
    onCheckBoxClick,
    taggedNodeIds,
    hasChildren,
    maxSidebarDepth,
    isScrollManual,
  } = props;
  const [isRowVisible, setIsRowVisible] = useState(false);

  useEffect(() => {
    const target = document.getElementById(nodeId);

    // handler for when target is in viewport
    const inHandler = (entry, unobserve, targetEl) => {
      if (node.depth <= maxSidebarDepth) {
        if (isScrollManual.current) {
          //if max depth is 2, then set the parent also so that the sidebar opens when scrolling upwards
          const params =
            maxSidebarDepth == 2 && node.depth == 2
              ? { 1: node.parent, 2: nodeId }
              : { [node.depth]: nodeId };
          changeSelectedSidebarItem({ params });
        }
      }
      if (!isRowVisible) {
        setIsRowVisible(true);
      } else {
        unobserve();
      }
    };
    // handler for when target is NOT in viewport
    const outHandler = () => {};

    // the returned function, when called, stops tracking the target element in the given viewport
    const unobserveFn = observeElementInViewport(
      target,
      inHandler,
      outHandler,
      {
        // set viewport, if null then it's set to window
        viewport: null,
      }
    );

    return () => {
      unobserveFn();
    };
  }, []);

  const node = nodes[nodeId];
  const {
    code,
    depth,
    grades,
    parentId,
    isError = false,
    tags,
    benchmarkQuestionCount,
  } = node;

  //calculate index of the node
  let isPresent = _.findIndex(indexCountArray, id => id == nodeId);
  if (isPresent == -1) {
    indexCountArray.push(nodeId);
  }
  const indexOfNode = _.findIndex(indexCountArray, id => id == nodeId) + 1;
  //checking if the node is disabled for drag drop. if yes, make a light gray background
  const draggingOverWithId =
    droppableSnapshot.isDraggingOver && droppableSnapshot.draggingOverWith;

  const isDisabled = draggingOverWithId
    ? node.depth > nodes[draggingOverWithId].depth
    : false;
  //give styling
  const rowContainerStyle = classNames({
    [classes.rowContainer]: true,
    [classes.rowContainerDisabled]: isDisabled,
    [classes.isRowDragging]: draggingOverWithId
      ? draggingOverWithId == nodeId
      : false,
    [classes.isRowError]: isError,
    COACHMARKS_SCOPE_SEQUENCE_EDITOR_ROW: indexOfNode == 1,
  });

  //functions related to actions
  //1) Content edit
  const onContentEdit = useCallback(
    ({ params }) => {
      updateNode({ nodeId, setNodes, params });
      //remove any error state for this node.
      removeError();
    },
    [nodeId, setNodes, removeError]
  );

  //2) Left Indent
  const onLeftIndent = useCallback(() => {
    const updatedNodesOnLeftIndent = onLeftIndentNode({
      nodes,
      setNodes,
      nodeId,
      maxDepth,
    });

    if (_.size(updatedNodesOnLeftIndent) > 0) {
      const updatedNodeGrades = onLeftIndentGrades({
        nodes: updatedNodesOnLeftIndent,
        setNodes,
        nodeId,
      });

      const updatedNodes = getUniqueUpdatedNodes({
        previouslyUpdateNodes: updatedNodesOnLeftIndent,
        latestUpdateNodes: updatedNodeGrades,
      });

      updateNodesInDraft({ updatedNodes });
    }
  }, [nodes, setNodes, nodeId, maxDepth, updateNodesInDraft]);

  //3) Right Indent

  const onRightIndent = useCallback(() => {
    const updatedNodesOnRightIndent = onRightIndentNode({
      nodes,
      setNodes,
      nodeId,
      maxDepth,
    });

    if (_.size(updatedNodesOnRightIndent) > 0) {
      const updatedNodeGrades = onRightIndentGrades({
        nodes: updatedNodesOnRightIndent,
        setNodes,
        nodeId,
      });

      const updatedNodes = getUniqueUpdatedNodes({
        previouslyUpdateNodes: updatedNodesOnRightIndent,
        latestUpdateNodes: updatedNodeGrades,
      });

      updateNodesInDraft({ updatedNodes });
    }
  }, [nodes, setNodes, nodeId, maxDepth, updateNodesInDraft]);

  //4) Add new row
  const onAddRow = useCallback(() => {
    const [updatedNodes, newNodeId] = onAddNewNode({
      nodes,
      setNodes,
      nodeId,
      maxDepth,
      userId,
      isActionDisabled,
    });
    setTimeout(() => {
      scrollIntoView({
        element: document.getElementById(newNodeId),
        checkForElementInViewPort: true,
        block: "center",
      });
    }, 300);

    updateNodesInDraft({ updatedNodes });
  }, [
    nodes,
    setNodes,
    maxDepth,
    nodeId,
    updateNodesInDraft,
    userId,
    isActionDisabled,
  ]);

  //5) on checkbox click
  const onCheckboxClick = useCallback(
    ({ prevIsChecked }) => {
      setNodes(prevNodes => {
        return {
          ...prevNodes,
          [nodeId]: { ...prevNodes[nodeId], isChecked: !prevIsChecked },
        };
      });
    },
    [setNodes, nodeId]
  );
  //6) on Add Tags
  const onAddTags = useCallback(
    ({ itemsList }) => {
      const updatedNodes = onBulkAddTags({
        nodes,
        setNodes,
        selectedNodeIds: [nodeId],
        itemsList,
      });
      updateNodesInDraft({ updatedNodes });
    },
    [nodes, setNodes, nodeId, updateNodesInDraft]
  );

  //7) update current node grades and it's child grades
  const onUpdateGrades = useCallback(
    ({ grades, nodeGrades }) => {
      const updatedNodes = onUpdateNodeGrades({
        setNodes,
        nodeId,
        grades,
        nodes,
        nodeGrades,
      });

      //remove the error state for this node.
      removeError();
      updateNodesInDraft({ updatedNodes });
    },
    [setNodes, nodeId, nodes, updateNodesInDraft, removeError]
  );

  const removeError = useCallback(() => {
    if (isError) {
      setNodes(prevNodes => {
        return {
          ...prevNodes,
          [nodeId]: {
            ...prevNodes[nodeId],
            isError: false,
          },
        };
      });
    }
  }, [isError, nodeId, setNodes]);

  //8) Bulk rows that are copied from google docs excel sheet are being paste into NodeEditor.js.

  const onBulkPasteRows = useCallback(
    ({ pastedData, cellName, isNewRow, itemsList }) => {
      const updatedNodes = pasteBulkBenchmarkItems({
        nodes,
        setNodes,
        nodeId,
        pastedData,
        cellName,
        isNewRow,
        itemsList,
        userId,
        isActionDisabled,
      });
      updateNodesInDraft({ updatedNodes });
    },
    [nodes, setNodes, nodeId, updateNodesInDraft, userId, isActionDisabled]
  );
  //we can ignore Eslint warning
  const updateDraftNode = useCallback(
    _.debounce(({ cellName, labelValue }) => {
      const updateNode = getUpdatedNodeForDraft({
        cellName,
        nodeId,
        nodes,
        labelValue,
      });
      updateNodesInDraft({ updatedNodes: updateNode });
    }, 1000),
    [nodes, userId]
  );

  //allowed columns
  const allowedColumns = currentEditorConfig.columns;

  const filteredColumns = getFilteredColumns({ allowedColumns, mode });

  return (
    <Draggable
      draggableId={nodeId}
      index={indexOfNode}
      isDragDisabled={mode === "view" || isActionDisabled}
    >
      {provided => (
        <tr
          className={rowContainerStyle}
          ref={ref => {
            provided.innerRef(ref);
            depth <= maxSidebarDepth ? setNodesRef({ id: nodeId, ref }) : null;
          }}
          {...provided.draggableProps}
          id={nodeId}
        >
          {_.map(filteredColumns, column => {
            const columnStyle = _.get(column, "style", {});
            switch (column.id) {
              case columnIds.label: {
                return (
                  <td
                    className={classes.tableCell}
                    key={column.id}
                    style={columnStyle}
                  >
                    <LearningStandards
                      node={node}
                      mode={mode}
                      dragHandleProps={provided.dragHandleProps}
                      onContentEdit={onContentEdit}
                      onLeftIndent={onLeftIndent}
                      onRightIndent={onRightIndent}
                      onAddRow={onAddRow}
                      onCheckboxClick={onCheckboxClick}
                      placeholdersText={placeholdersText}
                      filters={filters}
                      isActionDisabled={isActionDisabled}
                      isError={isError}
                      currentEditorConfig={currentEditorConfig}
                      removeError={removeError}
                      onBulkPasteRows={onBulkPasteRows}
                      isCoachmarksRunning={isCoachmarksRunning}
                      indexOfNode={indexOfNode}
                      updateDraftNode={updateDraftNode}
                      showSelectionBox={showSelectionBox}
                      onCheckBoxClick={onCheckBoxClick}
                      taggedNodeIds={taggedNodeIds}
                    />
                  </td>
                );
              }
              case columnIds.code: {
                return (
                  <td
                    className={classes.tableCell}
                    key={column.id}
                    style={columnStyle}
                  >
                    {isRowVisible && (
                      <CodeCell
                        mode={mode}
                        code={code}
                        depth={depth}
                        onContentEdit={onContentEdit}
                        filters={filters}
                        onBulkPasteRows={onBulkPasteRows}
                        updateDraftNode={updateDraftNode}
                      />
                    )}
                  </td>
                );
              }
              case columnIds.grade: {
                return (
                  <td
                    className={classes.tableCell}
                    key={column.id}
                    style={columnStyle}
                  >
                    {isRowVisible && (
                      <GradesCell
                        grades={grades}
                        gradeOptions={gradeOptions}
                        mode={mode}
                        onUpdateGrades={onUpdateGrades}
                      />
                    )}
                  </td>
                );
              }
              case columnIds.tag: {
                return (
                  <td
                    className={classes.tableCell}
                    key={column.id}
                    style={columnStyle}
                  >
                    {isRowVisible && (
                      <TagCell
                        organizationTags={organizationTags}
                        mode={mode}
                        tags={tags}
                        inputForMultiLevelTagsQuery={
                          inputForMultiLevelTagsQuery
                        }
                        organizationTagsObjectMap={organizationTagsObjectMap}
                        onAddTags={onAddTags}
                      />
                    )}
                  </td>
                );
              }
              case columnIds.starQues: {
                return (
                  <td
                    className={classes.tableCell}
                    key={column.id}
                    style={columnStyle}
                  >
                    <QuestionsCount
                      isLeaf={!hasChildren}
                      benchmarkQuestionCount={benchmarkQuestionCount}
                    />
                  </td>
                );
              }
            }
          })}
        </tr>
      )}
    </Draggable>
  );
};

export default TableRow;
