import { env as environment, backendUrl } from "config";
import { getBackendUrl, getCustomFetch } from "@toddle/frontend-utils";

const getServerURL = () => {
  return backendUrl;
};

// Uncomment "overrideBaseurl" to hit localhost backend endpoint, and do not add path i.e, /graphql
export const getBackendServerUrl = ({
  region,
  env = environment,
  token,
  path,
  domain,
}) => {
  return getBackendUrl({
    // overrideBaseurl: "http://localhost:3001",
    region,
    token,
    env,
    path,
    domain,
    getStaticBaseurl: getServerURL,
  });
};

export const getCustomFetchFn = () => {
  return getCustomFetch({
    env: environment,
    getCustomBackendUrl: getBackendServerUrl,
  });
};
