import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import classes from "./CheckListViewRow.scss";
import { Tick } from "SvgComponents";
import { colors, fontStyle } from "Constants";
import classNames from "classnames";
import { getCountString } from "Utils";
import { I18nHOC, LinkWithTooltip } from "UIComponents";

const inputStyle = {
  cell: {
    display: "flex",
    border: "none",
    marginTop: 0,
    flexShrink: 0,
    width: "100%",
    color: colors.gray13,
    lineHeight: 1.57,
    alignItems: "center",
    ...fontStyle.medium,
  },
  disbledCell: {
    color: colors.surfaceDisabled,
  },
};

const styles = {
  headerStyle: {
    backgroundColor: colors.surfaceDark,
  },
};

const CheckListOption = ({
  label,
  id,
  rowId,
  toolEvaluationResponses,
  isEvaluation = false,
  updateEvaluationCell,
  isEvaluatedByStudent,
  showInsight,
  insights,
  insightColor,
  userType,
  t,
}) => {
  const groupedToolEvaluationResponse = _.groupBy(
    toolEvaluationResponses,
    toolEvaluationResponse => toolEvaluationResponse.creatorType
  );

  const staffToolEvaluationResponses = _.get(
    groupedToolEvaluationResponse,
    "STAFF",
    []
  );

  const studentToolEvaluationResponses = _.get(
    groupedToolEvaluationResponse,
    "STUDENT",
    []
  );
  const responseCount = _.get(
    _.find(
      insights,
      insight => insight.rowId == rowId && insight.columnId == id
    ),
    "responseCount",
    0
  );

  const isSelected =
    _.findIndex(
      staffToolEvaluationResponses,
      response => response.rowId == rowId && response.columnId == id
    ) >= 0;
  const isStudentSelected =
    _.findIndex(
      studentToolEvaluationResponses,
      response => response.rowId == rowId && response.columnId == id
    ) >= 0 && isEvaluatedByStudent;
  const cellClass = classNames(
    { [classes.option]: true },
    { [classes.clickableCell]: isEvaluation && userType == "staff" },
    { [classes.clickableStudentCell]: isEvaluation && userType == "student" },
    {
      [classes.selectableCell]: isSelected,
    },
    {
      [classes.selectableStudentCell]: !isSelected && isStudentSelected,
    },
    {
      [classes.insightCell]: showInsight,
    }
  );

  const studentArray = _.get(
    _.find(
      insights,
      insight =>
        _.isEqual(_.get(insight, "rowId", ""), rowId) &&
        _.isEqual(_.get(insight, "columnId", ""), id)
    ),
    "studentsList",
    []
  );

  const moreComponent = (
    <div className={classes.toolTipConainer}>
      {_.map(studentArray, (student, i) => {
        const { firstName, lastName } = student;
        return (
          <div className={classes.moreToolTipItem} key={i}>
            {firstName} {lastName}
          </div>
        );
      })}
    </div>
  );

  const isToolTipVisible = !_.isEmpty(studentArray);

  return (
    // <div className={cellClass}>
    <div
      onClick={
        isEvaluation
          ? () =>
              updateEvaluationCell({
                rowId: rowId,
                columnId:
                  (userType == "staff" && isSelected) ||
                  (userType == "student" && isStudentSelected)
                    ? null
                    : id,
              })
          : null
      }
      className={cellClass}
    >
      {!showInsight ? (
        <React.Fragment>
          <div style={inputStyle.cell}>{label}</div>
          {isSelected ? <div className={classes.selectableCell2} /> : null}
          {isStudentSelected || isSelected ? (
            <div className={classes.tickSvgContainer}>
              {isStudentSelected && (
                <div
                  className={classes.tickSvgComponent}
                  style={{ marginRight: isSelected ? "8px" : "0px" }}
                >
                  <Tick
                    width={16}
                    height={16}
                    fillBackground={colors.yellow50}
                  />
                </div>
              )}
              {isSelected && (
                <div className={classes.tickSvgComponent}>
                  <Tick width={16} height={16} />
                </div>
              )}
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <LinkWithTooltip
          tooltip={moreComponent}
          placement={"top"}
          isVisible={isToolTipVisible}
        >
          <div className={classes.insight}>
            <div
              className={classes.insightCount}
              style={{ color: insightColor }}
            >
              {responseCount}
            </div>
            <div className={classes.insightStudent}>
              {getCountString({
                count: responseCount,
                singularText: t("common:student").toLowerCase(),
                pluralText: t("common:student_plural").toLowerCase(),
                shouldShowZero: true,
                showCount: false,
              })}
            </div>
          </div>
        </LinkWithTooltip>
      )}
    </div>
  );
};

class CheckListViewRow extends React.PureComponent {
  render() {
    const {
      id,
      label,
      options,
      showInsight,
      minOptionCount,
      isEvaluation,
      toolEvaluationResponses,
      header,
      updateEvaluationCell,
      isEvaluatedByStudent,
      insights,
      insightColor,
      userType,
      t,
    } = this.props;

    const tableTextClass = classNames({
      [classes.tableHeader]: header,
      [classes.tableText]: !header,
    });

    const optionHeaderClass = classNames({
      [classes.optionHeader]: true,
      [classes.showInsight]: showInsight,
    });

    const headerContainerClass = classNames({
      [classes.headerOptionContainer]: true,
      [classes.insightsHeaderOptionContainer]: showInsight,
    });

    return (
      <div className={classes.container}>
        <div className={classes.row}>
          <div
            className={classes.labelContainer}
            style={header ? styles.headerStyle : {}}
          >
            <div
              value={label}
              disabled={true}
              placeholder={t("unitPlan:type_criteria_here")}
              className={tableTextClass}
            >
              {label}
            </div>
          </div>

          <div className={classes.optionContainer}>
            {header ? (
              <div className={headerContainerClass}>
                {_.map(options, (option, index) => {
                  return (
                    <div key={option.id} className={optionHeaderClass}>
                      {(showInsight || index == 0) && (
                        <div className={classes.tableheader}>
                          {showInsight
                            ? option.label
                            : t("assessmentEvaluation:options")}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              _.map(options, (item, optionIndex) => {
                return (
                  <CheckListOption
                    t={t}
                    insights={insights}
                    showInsight={showInsight}
                    isEvaluatedByStudent={isEvaluatedByStudent}
                    isEvaluation={isEvaluation}
                    updateEvaluationCell={updateEvaluationCell}
                    key={item.id}
                    label={item.label}
                    id={item.id}
                    rowId={id}
                    insightColor={insightColor}
                    optionIndex={optionIndex}
                    minOptionCount={minOptionCount}
                    index={optionIndex}
                    toolEvaluationResponses={toolEvaluationResponses}
                    userType={userType}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userType: state.login.userInfo.user_type,
  };
};

export default compose(
  I18nHOC({
    resource: ["common", "assessmentEvaluation", "unitPlan"],
  }),
  connect(mapStateToProps)
)(CheckListViewRow);
