import React from "react";
import classes from "./classes.scss";

import { ProgressSteps, UIButton, UIModal } from "UIComponents";
import { CancelIcon, ChevronIcon } from "SvgComponents";
import { colors } from "Constants";
import Step from "./Step";

import OrganizationModal from "../OrganizationModal";

const Footer = React.memo(props => {
  const { onClose, onSaveClick, collaboratorToggle, nextButtonName } = props;

  return (
    <div className={classes.footerButtonContainer}>
      <div className={classes.footerLeftContainer} />
      <div className={classes.footerRightContainer}>
        <div className={classes.leftFooterButton}>
          <UIButton
            color="grey"
            size="sm"
            onClick={onClose}
          >{`Cancel`}</UIButton>
        </div>
        <UIButton
          isDisabled={collaboratorToggle}
          color="pink"
          size="sm"
          onClick={onSaveClick}
        >
          {"Save"}
        </UIButton>
      </div>
    </div>
  );
});

Footer.displayName = "Footer";

const Header = React.memo(props => {
  const { showBack, title, onClose, onBackClicked } = props;
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerLeftContainer}>
        {/* <div className={classes.backButtonContainer}>
          {showBack && (
            <div className={classes.backButton} onClick={onBackClicked}>
              <span className={classes.backIcon}>
                <ChevronIcon fill={colors.salmon60} />
              </span>

              {`Back`}
            </div>
          )}
        </div> */}
        {title && <div className={classes.titleText}>{title}</div>}
      </div>
      <div className={classes.cancelIcon} onClick={onClose}>
        <CancelIcon width={16} heigth={16} />
      </div>
    </div>
  );
});

Header.displayName = "Header";
const steps = [
  {
    id: "name",
    label: "Name",
    ref: "name",
    name: "name",
    placeholder: "Enter organization name",
    validationCheck: true,
    type: "text",
  },
  {
    id: "domains",
    label: "Domains",
    ref: "domains",
    name: "domains",
    placeholder: "Enter Domains",

    multi: true,
    allowAddOption: true,
    type: "selectDropDown",
  },
  {
    id: "city",
    label: "City",
    ref: "city",
    name: "city",
    placeholder: "Enter city",
    type: "text",
  },

  {
    id: "country",
    label: "Country",
    ref: "country",
    name: "country",
    placeholder: "Enter Country",
    type: "text",
  },
  {
    id: "ibCode",
    label: "IB Code",
    ref: "ibCode",
    name: "ibCode",
    placeholder: "Enter IB code",
    type: "text",
  },
];

const getDomainList = _.memoize(
  domains =>
    _.map(domains, (value, index) => ({
      label: value,
      value: value,
    })),
  domains => JSON.stringify(domains)
);

class OrganizationEditModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };

    // this.refs = {};
  }

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  onSaveClick = () => {
    const { onSaveClick } = this.props;
    if (onSaveClick) {
      onSaveClick(this.state.data);
    }
  };

  getOptions = key => {
    const { data } = this.state;

    switch (key) {
      case "domains":
        return getDomainList(data.domains);

      default:
        return [];
    }
  };

  customRef = ({ ref, id }) => {
    // this.refs[id] = ref;
  };

  render() {
    const { data, onClose, onSaveClick } = this.props;
    return (
      <OrganizationModal
        title={"Edit organization"}
        data={data}
        getOptions={this.getOptions}
        steps={steps}
        onClose={onClose}
        onSaveClick={onSaveClick}
      />
    );
  }
}

OrganizationEditModal.defaultProps = {
  modalTitle: "Duplicate the Unit",
  confirmButtonName: "Duplicate",
};

export default OrganizationEditModal;
