import gql from "graphql-tag";
import { assessmentFragment } from "./AssessmentFragments";
export const getAssessmentQuery = gql`
  query getAssessment($id: ID!) {
    node(id: $id, type: ASSESSMENT) {
      id
      ... on Assessment {
        ...assessmentItem
      }
    }
  }
  ${assessmentFragment.assessmentDetails}
`;

export const getAssessmentOfUnitQuery = gql`
  query getUnitPlan($id: ID!, $filters: resourceLibraryFilter) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        resourceLibrary(filters: $filters) {
          edges {
            type
            node {
              ...assessmentItem
            }
          }
        }
      }
    }
  }
  ${assessmentFragment.assessmentSelectModalFeed}
`;

export const getParentAssesmentQuery = gql`
  query getParentAssesment(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $searchText: String
    $grades: [ID!]
    $units: [ID!]
    $academicYears: [ID!]
    $curriculumProgramIds: [ID!]
    $after: String
    $excludeSnPEvidenceFilters: ExcludeSnPEvidenceFilters
  ) {
    node(id: $id, type: $type) {
      id
      ... on Organization {
        assessments(
          first: 20
          after: $after
          filters: {
            searchText: $searchText
            grades: $grades
            academicYears: $academicYears
            units: $units
            curriculumProgramIds: $curriculumProgramIds
            excludeSnPEvidenceFilters: $excludeSnPEvidenceFilters
          }
        ) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...assessmentItem
            }
          }
        }
      }
    }
  }
  ${assessmentFragment.assessmentSelectModalFeed}
`;
