import _ from "lodash";
import update from "immutability-helper";
import client from "apolloClient";
import {
  deleteUserMutation,
  addUserMutation,
  removeCoursesFromUserMutation,
  addCoursesToUserMutation,
} from "QueryMutationFragment/CommonQueryMutationFragment/mutations";
import {
  getTeacherFeedQuery,
  checkDuplicateEmailQuery,
} from "TeacherSetup/modules/Queries";
import { setToastMsg } from "Login/modules/LoginModule";
import { updateLoading } from "Administrator/modules/CommonModule";
import { colors } from "Constants";
import { resetPasswordMutation } from "modules/CommonMutations";
import {
  editTeacherMutation,
  createOrganizationInvitedUserMutation,
} from "TeacherSetup/modules/Mutations";
import { getTeacherFilters } from "./utils";
// Consts
export const NAME = "teacherSetup";
export const TOGGLE_ACTION_LOADING = "TOGGLE_ACTION_LOADING" + " " + NAME;
export const UPDATE_FILTERS = "UPDATE_FILTERS" + " " + NAME;
export const CHANGE_SEARCH_TERM = "CHANGE_SEARCH_TERM" + " " + NAME;
export const INIT_SEARCH_TERM = "INIT_SEARCH_TERM" + " " + NAME;
export const UPDATE_TEACHER_OBJECT = "UPDATE_TEACHER_OBJECT" + " " + NAME;
export const UPDATE_TEACHER_STEP = "UPDATE_TEACHER_STEP" + " " + NAME;
export const INIT_TEACHER_OBJECT = "INIT_TEACHER_OBJECT" + " " + NAME;
export const TOGGLE_TEACHER_TABS = "TOGGLE_TEACHER_TABS" + " " + NAME;
export const INIT_REMOVED_COURSES = "INIT_REMOVED_COURSES" + " " + NAME;
export const TOGGLE_REMOVED_COURSES = "TOGGLE_REMOVED_COURSES" + " " + NAME;
export const TOGGLE_MANAGE_COURSE_ROUTE =
  "TOGGLE_MANAGE_COURSE_ROUTE" + " " + NAME;

const staffLabelMapping = [
  { key: "admin", locale: "common:admin_count" },
  { key: "teacher", locale: "common:teacher_count" },
];

const userStatus = [
  { key: "ROSTERED", label: "joined_count", color: colors.blue29 },
  { key: "INVITED", label: "invited_count", color: colors.yellow50 },
  { key: "REQUESTED", label: "requested_count", color: colors.salmon60 },
];

export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};

export const getTeacherRolesCount = ({ userCount, t }) => {
  const outPutString = _.reduce(
    staffLabelMapping,
    (result, { key, locale }) => {
      const count = _.get(_.find(userCount, { role: key }), "totalCount", 0);
      result.push(t ? t(locale, { count }) : locale);
      return result;
    },
    []
  );

  return _.join(outPutString, ", ");
};

export const getTeacherStatusCount = ({ userStatusCount, t }) => {
  const outPutString = _.reduce(
    userStatus,
    (result, { key, label, color }) => {
      const count = _.get(
        _.find(userStatusCount, { status: key }),
        "totalCount",
        0
      );

      if (count > 0) {
        const comp = `<span style="color:${color}">${t(`common:${label}`, {
          count,
        })}</span>`;
        result.push(comp);
      }
      return result;
    },
    []
  );

  return _.join(outPutString, ", ");
};

export const toggleManageCourseRoute = data => {
  return {
    type: TOGGLE_MANAGE_COURSE_ROUTE,
    data: data,
  };
};

export const toggleActionLoading = data => {
  return {
    type: TOGGLE_ACTION_LOADING,
    data,
  };
};

export const toggleTeacherTabs = data => {
  return {
    type: TOGGLE_TEACHER_TABS,
    payload: data,
  };
};

export const toggleRemovedCourses = data => {
  return {
    type: TOGGLE_REMOVED_COURSES,
    payload: data,
  };
};

export const initRemovedCourses = data => {
  return {
    type: INIT_REMOVED_COURSES,
    payload: data,
  };
};

export const changeSearchTerm = value => {
  return { type: CHANGE_SEARCH_TERM, payload: value };
};
export const initSearchTerm = () => {
  return {
    type: INIT_SEARCH_TERM,
  };
};

export const updateTeacherObject = data => {
  return {
    type: UPDATE_TEACHER_OBJECT,
    payload: data,
  };
};

export const initTeacherObject = () => {
  return {
    type: INIT_TEACHER_OBJECT,
  };
};

export const updateTeacherStep = data => {
  return {
    type: UPDATE_TEACHER_STEP,
    payload: data,
  };
};

export const onContentUploaded = values => {
  return (dispatch, getState) => {
    dispatch(updateTeacherObject({ profileImage: values }));
  };
};

export const removeCourseFromTeacher = teacherId => {
  return (dispatch, getState) => {
    const courseIds = getState().adminCommon.selectedList.courses.map(
      course => course.id
    );
    const organizationId = getState().login.userInfo.org_id;
    const searchText = getState().teacherSetup.searchText;
    const filters = getState().teacherSetup.filters;
    const { orderByDirection, orderBy } = _.get(
      getState().app_services,
      "orderByDirectionObj.adminTeacherFeed",
      {}
    );
    client
      .mutate({
        mutation: removeCoursesFromUserMutation,
        variables: {
          userId: teacherId,
          courseIds: courseIds,
        },
        refetchQueries: [
          {
            query: getTeacherFeedQuery,
            variables: {
              organizationId,
              filters: { searchText, ...filters },
              orderByDirection,
              orderBy,
            },
          },
        ],
      })
      .then(res => {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:successfully_removed_courses_from_teacher",
          })
        );
        dispatch(initRemovedCourses());
      })
      .catch(err => {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

export const addCoursesToTeacher = teacherId => {
  return (dispatch, getState) => {
    const courseIds = getState().adminCommon.selectedList.courses.map(
      course => course.id
    );
    const organizationId = getState().login.userInfo.org_id;
    const searchText = getState().teacherSetup.searchText;
    const filters = getState().teacherSetup.filters;
    const { orderByDirection, orderBy } = _.get(
      getState().app_services,
      "orderByDirectionObj.adminTeacherFeed",
      {}
    );
    client
      .mutate({
        mutation: addCoursesToUserMutation,
        variables: {
          userId: teacherId,
          courseIds: courseIds,
          role: "collaborator",
        },
        refetchQueries: [
          {
            query: getTeacherFeedQuery,
            variables: {
              organizationId,
              filters: { ...filters, searchText },
              orderByDirection,
              orderBy,
            },
          },
        ],
      })
      .then(res => {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:successfully_added_courses_to_teacher",
          })
        );
      })
      .catch(err => {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

export const onClickCreateTeacher = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const params = {};
      params["createTeacher"] = true;
      const organizationId = getState().login.userInfo.org_id;
      const teacherDetails = getState().teacherSetup.teacherDetails;
      const courses = getState().adminCommon.selectedList.courses.map(
        student => student.id
      );

      const { teacherFilters } = getTeacherFilters(getState());

      const { orderByDirection, orderBy } = _.get(
        getState().app_services,
        "orderByDirectionObj.adminTeacherFeed",
        {}
      );
      dispatch(toggleActionLoading(true));
      client
        .mutate({
          mutation: addUserMutation,
          variables: {
            email: teacherDetails.email,
            userType: "staff",
            courses: courses,
            firstName: teacherDetails.firstName,
            lastName: teacherDetails.lastName,
            profileImage: teacherDetails.profileImage,
            designation: teacherDetails.designation,
            tags: teacherDetails.tags,
            staffRelationMap: [],
          },
          refetchQueries: [
            {
              query: getTeacherFeedQuery,
              variables: {
                organizationId,
                filters: teacherFilters,
                orderByDirection,
                orderBy,
              },
            },
          ],
        })
        .then(res => {
          params["createTeacher"] = false;
          dispatch(updateLoading(params));
          dispatch(toggleActionLoading(false));
          // dispatch(initTeacherObject());
          dispatch(
            setToastMsg({
              type: "success",
              msg: "toastMsgs:saved_successfully",
            })
          );
          resolve();
        })
        .catch(err => {
          console.error(err);
          dispatch(toggleActionLoading(false));
          dispatch(setToastMsg("toastMsgs:something_went_wrong"));
          reject();
        });
    });
  };
};

export const updateTeacherDetails = params => {
  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      await client.mutate({
        mutation: editTeacherMutation,
        variables: params,
      });
    } catch (e) {
      if (
        _.includes(
          _.get(e, "message"),
          "USER_EMAIL_ALREADY_EXISTS_IN_ORGANIZATION"
        )
      ) {
        dispatch(
          setToastMsg("toastMsgs:teacher_with_given_mail_exists_in_org")
        );
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const resetPasswordDetails = params => {
  params.is_password_updated = !params.is_password_updated;

  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      await client.mutate({
        mutation: resetPasswordMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const deleteTeacher = ({ teacherId, callback }) => {
  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      await client.mutate({
        mutation: deleteUserMutation,
        variables: {
          id: teacherId,
        },
      });
      await callback();
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:removed_staff_from_team",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const archiveTeacher = ({ teacherId, isArchived, callback }) => {
  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      const newArchivedState = !isArchived;
      await client.mutate({
        mutation: editTeacherMutation,
        variables: {
          id: teacherId,
          isArchived: newArchivedState,
        },
      });
      await callback();
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_archived_staff",
          locale_params: [
            {
              key: "action",
              value: newArchivedState ? `common:archived` : `common:restored`,
            },
          ],
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const createOrganizationInvitedUser = ({ invitedUsers }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createOrganizationInvitedUserMutation,
        variables: {
          users: invitedUsers,
        },
      });

      return _.get(result, "data.platform.addUsers", {});
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
    }
  };
};

export const checkDuplicateEmail = ({ email }) => {
  return async (dispatch, getState) => {
    const params = {
      id: getState().login.userInfo.org_id,
      email,
    };
    try {
      const result = await client.query({
        query: checkDuplicateEmailQuery,
        variables: params,
      });

      return _.get(result, "data.node.checkUserEmailExists", false);
    } catch (e) {
      console.error(e);
    }
  };
};

// Reducer Handlers
const REDUCER_HANDLERS = {
  [TOGGLE_TEACHER_TABS]: (state, action) => {
    return update(state, { teacherTab: { $set: action.payload } });
  },
  [INIT_REMOVED_COURSES]: (state, action) => {
    return update(state, {
      removedCourses: { $set: initialState.removedCourses },
    });
  },

  [TOGGLE_REMOVED_COURSES]: (state, action) => {
    const data = action.payload;
    const operation = data.operation;
    const value = data.value;
    const itemIndex = data.index;
    if (operation == "add") {
      state = update(state, { removedCourses: { $push: [value] } });
    } else {
      state = update(state, { removedCourses: { $splice: [[itemIndex, 1]] } });
    }
    return state;
  },
  [CHANGE_SEARCH_TERM]: (state, action) => {
    return update(state, { searchText: { $set: action.payload } });
  },
  [INIT_SEARCH_TERM]: (state, action) => {
    return update(state, { searchText: { $set: initialState.searchText } });
  },
  [UPDATE_FILTERS]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        filters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [UPDATE_TEACHER_OBJECT]: (state, action) => {
    const params = action.payload;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        teacherDetails: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [INIT_TEACHER_OBJECT]: (state, action) => {
    return update(state, {
      teacherDetails: { $set: initialState.teacherDetails },
    });
  },
  [UPDATE_TEACHER_STEP]: (state, action) => {
    return update(state, { teacherStep: { $set: action.payload } });
  },
  [TOGGLE_MANAGE_COURSE_ROUTE]: (state, action) => {
    return update(state, { manageCourseRoute: { $set: action.data } });
  },
  [TOGGLE_ACTION_LOADING]: (state, action) => {
    return update(state, {
      isActionLoading: { $set: action.data },
    });
  },
};

// Initial State and export Reducer

const initialState = {
  searchText: "",
  curriculumProgramId: "",
  filters: {
    courses: [],
    archivalState: "ACTIVE",
    designations: [],
  },
  teacherDetails: {
    id: null,
    firstName: "",
    profileImage: null,
    lastName: "",
    email: "",
    tags: [],
    initialTags: [],
    designation: "",
  },
  teacherTab: "classes",
  removedCourses: [],
  manageCourseRoute: "courseList",
  teacherStep: 2,
  isActionLoading: false,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
