import PropTypes from "prop-types";
import React, { Fragment } from "react";
import classes from "./AssessmentFeed.scss";
import { ResourceCardV1 } from "AppComponents";
import { colors } from "Constants";
import { Tick, ChevronIcon } from "SvgComponents";
import { Checkbox } from "UIComponents";
import update from "immutability-helper";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const getTheme = ({ cardType }) => {
  return {
    sectionItem: {
      padding: cardType == "boxCards" ? "16px" : "32px 0",
      border: cardType == "boxCards" && "1px solid",
      borderBottom: "1px solid",
      borderColor: `${colors.strokeTwo}`,
      cursor: "pointer",
    },
    sectionItemDetails: {
      padding: cardType == "boxCards" && 0,
    },
    sectionLabel: {
      textDecoration: "none",
    },
    rightContainer: {
      flex: cardType == "listCards" && 1,
    },
    imageContainer: {
      height: cardType == "boxCards" && 44,
      width: cardType == "boxCards" && 60,
      marginRight: cardType == "boxCards" && 12,
    },
  };
};

{
  /* <CheckTickOff height={24} width={24} fill={colors.strokeOne} /> */
}

export const ItemRightContainer = ({
  type,
  isSelected,
  cardType,
  hideDefaultActions,
}) => {
  return type == "ASSESSMENT_EVALUATION" ? (
    <div className={classes.chevron}>
      <ChevronIcon width={16} height={16} fill={colors.gray31} />
    </div>
  ) : cardType == "boxCards" && isSelected && !hideDefaultActions ? (
    <Tick
      width={24}
      height={24}
      fillBackground={colors.blue29}
      fillTick={colors.white}
    />
  ) : (
    cardType !== "boxCards" && (
      <Checkbox
        fill={colors.blue29}
        offFill={colors.strokeTwo}
        isChecked={isSelected}
        style={{ width: 24, height: 24 }}
        checkBoxParentContainer={{ display: "block", width: "fit-content" }}
      />
    )
  );
};

class AssessmentFeed extends React.PureComponent {
  isSelected = ({ id }) => {
    const { isMultiSelect, selectedItems } = this.props;
    let isSelected = false;
    if (isMultiSelect) {
      isSelected = _.includes(selectedItems, id);
    } else {
      isSelected = selectedItems == id;
    }
    return isSelected;
  };

  selectAssessment = ({ id }) => {
    const { selectedItems, isMultiSelect, updateSelectedItems } = this.props;

    if (isMultiSelect) {
      const index = _.indexOf(selectedItems, id);
      let selectedItemsLocal = [];
      if (index < 0) {
        selectedItemsLocal = update(selectedItems, { $push: [id] });
      } else {
        selectedItemsLocal = update(selectedItems, { $splice: [[index, 1]] });
      }
      updateSelectedItems(selectedItemsLocal);
    } else {
      updateSelectedItems(selectedItems == id ? "" : id);
    }
  };

  getItemList = (filteredItems, type) => {
    const {
      cardType,
      disabledItems,
      customCardStyles,

      cardWrapperElement,
      t,
    } = this.props;
    return _.map(filteredItems, (opt, index) => {
      const isSelected = this.isSelected({ id: opt.id });

      //Handled Assessment Evaluation Disabled State - Hack
      const isDisabled =
        (type == "ASSESSMENT_EVALUATION" && opt.evaluations.totalCount > 0) ||
        _.findIndex(disabledItems, item => item == opt.id) > -1
          ? true
          : false;

      const showTypeLabel = cardType !== "boxCards";

      const theme = _.merge(getTheme({ cardType }), customCardStyles);

      const additionalProps = cardWrapperElement
        ? { data: opt, isSelected, onCardClick: this.selectAssessment }
        : {};

      return (
        <div className={classes.resourceFeedItemDiv} key={opt.id}>
          <ConditionalWrapper
            type="wrapperAsElement"
            wrapper={cardWrapperElement}
            {...additionalProps}
            condition={!!cardWrapperElement && type === "SNP_EVIDENCE"}
          >
            <ResourceCardV1
              item={opt}
              index={index}
              isDisabled={isDisabled}
              onContainerClick={() => this.selectAssessment({ id: opt.id })}
              rightComponent={
                <ItemRightContainer
                  isSelected={isSelected}
                  type={type}
                  cardType={cardType}
                  hideDefaultActions={!!cardWrapperElement}
                />
              }
              theme={theme}
              showTypeLabel={showTypeLabel}
            />
          </ConditionalWrapper>
        </div>
      );
    });
  };

  renderAssessmentFeed = () => {
    const { items, type, itemFeedContainer, customHeaderElement } = this.props;

    return (
      <Fragment>
        {customHeaderElement &&
          React.cloneElement(customHeaderElement, {
            count: _.size(items),
          })}

        <div
          className={classes.resourceFeedContainerClass}
          style={itemFeedContainer}
        >
          {this.getItemList(items, type)}
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <div className={classes.container}>{this.renderAssessmentFeed()}</div>
    );
  }
}

export default AssessmentFeed;
