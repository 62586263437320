import PropTypes from "prop-types";
import React from "react";
import classes from "./CheckListContainer.scss";
import classNames from "classnames";
import _ from "lodash";
import {
  ViewInputField,
  UILabel,
  Listview,
  EmptyField,
  I18nHOC,
  MatchingText,
  LinkWithTooltip,
} from "UIComponents";
import { getStringFromArray } from "Utils";
import update from "immutability-helper";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { colors } from "Constants";
import { compose } from "react-apollo";
import { TickPlain } from "SvgComponents";
import { Checkbox } from "@toddle-design/web";
import { InformationOutlined } from "@toddle-design/web-icons";

// TODO: Remove this map
const checkboxSizeMap = {
  "24px": "x-small",
  "18px": "xx-small",
  "14px": "xxx-small",
  "11px": "xxx-small",
};

class CheckList extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isCollapsed: true,
    };
  }

  isAllSelected = () => {
    if (this.props.options && this.props.value) {
      return this.props.options.length == this.props.value.length;
    } else {
      return false;
    }
  };

  isSelected = opt => {
    if (this.props.value) {
      return !(
        _.findIndex(this.props.value, function (o) {
          return o == opt;
        }) == -1
      );
    } else {
      return false;
    }
  };

  toggleAllCheckbox = isChecked => {
    const value = !isChecked;
    if (value) {
      //add all
      const newValue = _.map(this.props.options, option => option.value);
      this.updateValue(newValue);
    } else {
      //remove all
      this.updateValue([]);
    }
    if (this.state.error != "") {
      //this.props.errorResolve();
      this.setState({ error: "" });
    }
  };

  isDisabled = val => {
    return (
      _.findIndex(this.props.disabledList, function (o) {
        return o == val;
      }) != -1
    );
  };

  toggleCheckbox = (checkboxValue, isChecked, option) => {
    const { customToggleFn } = this.props;
    if (customToggleFn && typeof customToggleFn === "function") {
      customToggleFn(option);
      return;
    }
    const value = !isChecked;
    let newValue = this.props.value;
    let index = _.findIndex(this.props.value, function (o) {
      return o == checkboxValue;
    });

    if (value) {
      //add to the list
      if (index == -1) {
        index = newValue.length;
      }
      newValue = update(newValue, { $splice: [[index, 0, checkboxValue]] });
    } else {
      //remove from the list
      newValue = update(newValue, { $splice: [[index, 1]] });
    }

    this.updateValue(newValue);

    if (this.state.error != "") {
      //this.props.errorResolve();
      this.setState({ error: "" });
    }
  };

  getSelectedLabel = () => {
    const selectedLabel = [];
    const { options, value } = this.props;
    _.map(value, val => {
      selectedLabel.push(_.find(options, { value: val }).label);
    });
    return selectedLabel;
  };

  getLabelStyle = ({ isChecked }) => {
    const { listItemStyle, activeBorder } = this.props;
    const labelStyles = {
      ...listItemStyle,
    };
    if (listItemStyle.border || activeBorder) {
      labelStyles["border"] =
        isChecked && activeBorder ? activeBorder : listItemStyle.border;
    }
    return labelStyles;
  };

  getCheckboxSize = () => {
    const { checkBoxStyles } = this.props;
    return checkBoxStyles?.width in checkboxSizeMap
      ? checkboxSizeMap[checkBoxStyles?.width]
      : "xx-small";
  };

  getCheckListForEdit = () => {
    let {
      fillColor,
      theme,
      isMatchingTextEnabled,
      searchText,
      tooltipPosition,
    } = this.props;

    const searchOptions = this.props.options;

    return _.map(searchOptions, (opt, index) => {
      const isChecked = this.isSelected(opt.value);
      const disabled = this.isDisabled(opt.value) || this.props.disabled;
      if (!fillColor) {
        fillColor = _.get(this.props, `fillColorList`, false)
          ? this.props.fillColorList[opt.value]
          : `${colors.teal20}`;
      }
      const labelStyles = this.getLabelStyle({ isChecked });

      return (
        <div key={index} className={classes.label} style={labelStyles}>
          {theme === "CUSTOM" ? (
            <span
              onClick={() => {
                !disabled && this.toggleCheckbox(opt.value, isChecked, opt);
              }}
            >
              {this.customStyle({ label: opt.label, checked: isChecked })}
            </span>
          ) : (
            <Checkbox
              disabled={disabled}
              isChecked={isChecked}
              size={this.getCheckboxSize()}
              onChange={e => {
                if (!disabled) this.toggleCheckbox(opt.value, isChecked, opt);
              }}
            >
              <span
                style={{
                  ...this.props.CheckBoxLabelStyles,
                  ...(disabled ? {} : this.props.checkboxItemLabelStyle || {}),
                }}
              >
                {isMatchingTextEnabled ? (
                  <MatchingText searchText={searchText} text={opt.label} />
                ) : (
                  opt.label
                )}
                {opt.subText && (
                  <span className={classes.checkboxSubtextContainer}>
                    <LinkWithTooltip
                      tooltip={opt.subText}
                      placement={tooltipPosition ? tooltipPosition : "bottom"}
                      href="#"
                      trigger={["hover"]}
                      tooltipContainerStyle={{ width: "284px" }}
                    >
                      <InformationOutlined
                        variant={"subtle"}
                        size={"xxx-small"}
                        style={{ paddingTop: "2px" }}
                      />
                    </LinkWithTooltip>
                  </span>
                )}
              </span>
            </Checkbox>
          )}
        </div>
      );
    });
  };

  customStyle = ({ label, checked }) => {
    return (
      <div className={classes.row}>
        <div className={classes.customLabel}>{label}</div>
        <div
          className={classes.checkIconContainer}
          style={{
            background: checked ? colors.blue29 : "",
            borderColor: checked ? "transparent" : colors.strokeOne,
          }}
        >
          {checked && <TickPlain fill={colors.white} width={8} height={8} />}
        </div>
      </div>
    );
  };

  shouldShowEditEmpty = () => {
    return _.get(this.props.options, "length", 0) == 0;
  };

  renderEditEmpty = () => {
    const { emptyText, emptyContainerStyle } = this.props;
    return (
      !!emptyText && (
        <EmptyField
          containerStyle={emptyContainerStyle}
          title={this.props.emptyText}
        />
      )
    );
  };

  renderView = () => {
    const selectedLabel = this.getSelectedLabel();
    return <Listview value={selectedLabel} mode="view" />;
  };

  renderEdit = () => {
    const { t, theme } = this.props;
    const checkList = this.getCheckListForEdit();

    const isAllChecked = this.isAllSelected();
    const isAllDisable =
      this.props.disabled || this.props.disabledList.length > 0;

    const checkListContainerClass = classNames(
      { [classes.checkListContainer]: true },
      {
        [classes.checkListContainerCollapsed]:
          this.props.isCollapsible && this.state.isCollapsed,
      },
      {
        [classes.checkListContainerExpanded]:
          this.props.isCollapsible && !this.state.isCollapsed,
      }
    );
    const fillColor = _.get(this.props, `fillColorList`, false)
      ? this.props.fillColorList["all"]
      : `${colors.teal20}`;

    let allOptionLabelLocale = this.props.allOptionLabel;

    if (allOptionLabelLocale === "common:all") {
      allOptionLabelLocale = this.props.t(allOptionLabelLocale);
    }
    return (
      <div className={classes.container}>
        <div
          className={checkListContainerClass}
          style={this.props.checkListContainerStyle}
        >
          {this.props.showAllOption ? (
            <div className={classes.label} style={this.props.listItemStyle}>
              {theme === "CUSTOM" ? (
                <span
                  onClick={() => {
                    if (isAllDisable) {
                      return;
                    }
                    this.toggleAllCheckbox(isAllChecked);
                  }}
                >
                  {this.customStyle({
                    label: allOptionLabelLocale,
                    checked: isAllChecked,
                  })}
                </span>
              ) : (
                <Checkbox
                  isChecked={isAllChecked}
                  disabled={isAllDisable}
                  size={this.getCheckboxSize()}
                  onChange={e => {
                    // e.preventDefault();
                    if (isAllDisable) {
                      return;
                    }
                    this.toggleAllCheckbox(isAllChecked);
                  }}
                >
                  <span style={this.props.CheckBoxLabelStyles}>
                    {allOptionLabelLocale}
                  </span>
                </Checkbox>
              )}
            </div>
          ) : null}

          {checkList}
        </div>

        {this.props.isCollapsible && this.props.options.length > 2 ? (
          <div>
            {this.state.isCollapsed ? (
              <div
                className={classes.expandText}
                onClick={() => this.setState({ isCollapsed: false })}
              >
                {`+ ${t("common:number_more", {
                  number: this.props.options.length - 2,
                })}`}
              </div>
            ) : (
              <div
                className={classes.expandText}
                onClick={() => this.setState({ isCollapsed: true })}
              >
                {t("common:see_less_noDots")}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };
}

export default I18nHOC({ resource: ["common"] })(CheckList);

CheckList.defaultProps = {
  ...UIBaseComponent.defaultProps,
  value: [],
  disabled: false,
  listItemStyle: {},
  CheckBoxLabelStyles: {},
  checkListContainerStyle: {},
  disabledList: [],
  showAllOption: false,
  isCollapsible: false,
  offFill: `${colors.gray72}`,
  emptyContainerStyle: {},
  allOptionLabel: "common:all",
  searchEnable: false,
  isMatchingTextEnabled: false,
};

CheckList.propTypes = {
  ...UIBaseComponent.propTypes,
  disabled: PropTypes.bool,
  listItemStyle: PropTypes.object,
  CheckBoxLabelStyles: PropTypes.object,
  disabledList: PropTypes.array,
  showAllOption: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  checkBoxStyles: PropTypes.object,
  offFill: PropTypes.string,
  emptyContainerStyle: PropTypes.object,
  allOptionLabel: PropTypes.string,
};
