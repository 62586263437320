import { withAsyncRouteLoading } from "UIComponents";
import CommunityParentEntity from "./routes/CommunityParentEntity";
import PDResources from "./routes/PDResources";
import Profile from "./routes/Profile";
import SchoolProfile from "./routes/SchoolProfile";
import { AssessmentDetails } from "Assessments";
import UnitQuickView from "CommunityEntity/routes/UnitQuickView";

export default store => {
  return {
    path: "home",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community"
          );
        })
    ),
    childRoutes: [
      Profile(store),
      SchoolProfile(store),
      PDResources(store),
      CommunityParentEntity(store),
      AssessmentDetails(store),
      UnitQuickView(store),
    ],
  };
};
