import {
  assessmentToolParentNodeFragment,
  mypObjectiveRubricDescriptorFragmnet,
  mypCriteriaWithDescriptorFragmnet,
} from "./AssessmentToolFragment";
import { getAssessmentQuery } from "Assessments/modules/AssessmentQuery";
import { getCriteriaDescriptorsQuery } from "./AssessmentToolQuery";
import { getUnitPlanQuery } from "IBPlanner/modules/IBQuery";
import client from "apolloClient";
const fragmentNameMapping = {
  UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: assessmentToolParentNodeFragment.unitPlan,
    fragmentName: "unitPlanNodeItem",
    query: getUnitPlanQuery,
  },
  ASSESSMENT: {
    typeName: "Assessment",
    fragment: assessmentToolParentNodeFragment.assessment,
    fragmentName: "assessmentNodeItem",
    query: getAssessmentQuery,
  },
};

const assessmentToolElementFragmentNameMapping = {
  MYP_OBJECTIVE_DESCRIPTOR: {
    typeName: "MypObjectiveRubricDescriptor",
    fragment: mypObjectiveRubricDescriptorFragmnet.mypObjectiveRubricDescriptor,
    fragmentName: "mypObjectiveRubricDescriptorItem",
  },
};

export const writeAssessmentToolOfParentInCache = ({ id, data, type }) => {
  let queryData = {};
  const typeName = fragmentNameMapping[type].typeName;
  const fragment = fragmentNameMapping[type].fragment;
  const fragmentName = fragmentNameMapping[type].fragmentName;
  try {
    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment: fragment,
      fragmentName: fragmentName,
      data: data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getAssessmentToolOfParentFromCache = ({ id, type }) => {
  let queryData = {};
  const query = fragmentNameMapping[type].query;
  try {
    queryData = client.readQuery({
      query: query,
      variables: {
        id: id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCriteriaDescriptorsFromCache = ({ ids }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getCriteriaDescriptorsQuery,
      variables: {
        ids,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "multiNode", []);
  else return {};
};

export const getCriteriaWithDescriptorFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `PlannerElementNode:${id}`,
      fragment: mypCriteriaWithDescriptorFragmnet.mypCriteriaWithDescriptor,
      fragmentName: "mypCriteriaWithDescriptorItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

/*
  Handle all types of Assessment Tool Elements read cache
  E.g - MYP_OBJECTIVE_DESCRIPTOR
*/
export const getAssessmentToolElementFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    const {
      typeName,
      fragment,
      fragmentName,
    } = assessmentToolElementFragmentNameMapping[type];
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

/*
  Handle all types of Assessment Tool Elements write cache
  E.g - MYP_OBJECTIVE_DESCRIPTOR
*/
export const writeAssessmentToolElementInCache = ({ id, data, type }) => {
  let queryData = {};
  try {
    const {
      typeName,
      fragment,
      fragmentName,
    } = assessmentToolElementFragmentNameMapping[type];

    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
