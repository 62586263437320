import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { colors, fontStyle } from "Constants";
import classes from "./ScheduleEvent.scss";
import {
  InputTextField,
  I18nHOC,
  DateSelector,
  TimeSelectorV2,
  UILabel,
  UIModal,
  UIButton,
  RadioButtonList,
  FullScreenLoader,
  OauthPopup,
  Checkbox,
  FilterDropdown,
  TextAreaInput,
  withLoader,
} from "UIComponents";
import ClassCard from "../ClassCard";
import AddClassModal from "../AddClassModal";
import Stepper from "../Stepper";
import Header from "ClassRoom/components/AddAssignment/Header";
import Footer from "ClassRoom/components/AddAssignment/Footer";
import ActionForSelectModal from "MyCalendar/components/ActionForSelectModal";
import {
  getCourseStudentsFoldersFromCache,
  getMultiCourseStudentsFoldersFromCache,
} from "Course/modules/CourseGraphqlHelpers";
import { setToastMsg } from "Login/modules/LoginModule";
import { getCurrentAcademicYear, updateSettings } from "modules/Services";
import {
  createPlatformCalendarEvent,
  editPlatformCalendarEvent,
} from "MyCalendar/modules/MyCalendarModule";
import moment from "moment";
import {
  getUserZoomAuthorizedStatus,
  generateZoomRefreshToken,
  getUserThirdPartyAuthorizedStatus,
} from "modules/Services";
import { ZOOM_AUTH_URL, redirectUri } from "Constants";
import update from "immutability-helper";
import Microsoft from "lib/microsoft";
import {
  getCourseStudentsFoldersQuery,
  getMultiCourseStudentsFoldersQuery,
} from "Course/modules/CourseQuery";
import { getCalendarEventDetailsQuery } from "MyCalendar/modules/MyCalendarQuery";
import { getCalendarEventDetailsFromCache } from "MyCalendar/modules/MyCalendarGraphqlHelpers";
import {
  getCourseWiseUsersDetails,
  checkUserAuthScopes,
  getCalendarEventsViewSettingValue,
} from "MyCalendar/modules/Utils";
import classNames from "classnames";
import { getFormattedCoursesDetailsMemoize } from "ClassRoom/modules/utils";
import {
  START_PICKER_START_TIME,
  START_PICKER_END_TIME,
  END_PICKER_END_TIME,
} from "MyCalendar/modules/Utils";

import { ThirdPartyAuth } from "AppComponents";
import ACLStore from "lib/aclStore";
import { getSettingValue } from "modules/PermissionModule";

const isNewZoomAccountLinkingEnable = () => {
  return ACLStore.can("FeatureFlag:NewZoomAccountLinking");
};

const getWeekOrdinalText = weekNumber => {
  switch (weekNumber) {
    case 1:
      return "first";
    case 2:
      return "second";
    case 3:
      return "third";
    case 4:
      return "fourth";
    case 5:
      return "last";
  }
};

const createMonthlyRecurringOptions = ({ date, weekDay, weekNumber }) => {
  const options = [
    {
      label: `Monthly on day ${date}`,
      value: "by_date",
    },
    {
      label: `Monthly on the ${getWeekOrdinalText(weekNumber)} ${weekDay}`,
      value: "by_week",
    },
  ];
  return options;
};

const weekOfMonth = ({ date }) => {
  let weekInYearIndex = date.week();
  /**
   * Special case for the last week of a year.
   * In US the first week of the year is the week that contains 1st jan. So it is very
   * much possible that the first week overlaps with the last week of the previous year.
   * Eg: if 1st jan 2021 is friday, then 27-31 dec 2020 are considered in the 1st week of 2021.
   * In such cases, we manually calulate the week number wrt its actual year
   */
  if (date.year() !== date.weekYear()) {
    weekInYearIndex = date.clone().subtract(1, "week").week() + 1;
  }
  const startDateOfMonth = moment(date).startOf("month");
  const weekIndex = weekInYearIndex - startDateOfMonth.week() + 1;
  /**
   * Week number of a day doesn't always gurantee that it is its nth occurance in that month.
   * For eg: If a month starts from tuesday, then the first monday that occurs is in week #2 of that month.
   * But it is actually the 1st monday of the month. In such cases, we subtract an offset from the week number to
   * get its occurance number
   */
  const weekOffset = date.day() >= startDateOfMonth.day() ? 0 : -1;
  return weekIndex + weekOffset;
};

const styles = {
  radioButtonOptionStyle: {
    flexBasis: "unset",
    marginRight: "24px",
  },
  radioButtonConStyle: {
    marginBottom: "24px",
  },
  videoOptionLabelStyle: {
    marginBottom: "28px",
  },
  meetingRangeTextContainerStyle: {
    justifyContent: "center",
  },
  repeatTextContainerStyle: {
    justifyContent: "center",
    display: "inline-flex",
    width: "auto",
    marginRight: "18px",
  },
  endsOnTextContainerStyle: {
    justifyContent: "center",
    display: "inline-flex",
    width: "auto",
    marginRight: "18px",
  },
  stepperStyle: {
    marginRight: "24px",
  },
  checkboxContainerStyle: {
    height: "15px",
    width: "15px",
  },
  checkboxStyle: {
    borderRadius: "50%",
  },
  meetingTimeLabelStyle: {
    fontWeight: "600",
  },
  addClassLabelStyle: {
    marginTop: 16,
    width: "max-content",
  },
  checkBoxItemStyle: {
    borderRadius: "50%",
    marginTop: "2px",
  },
  checkBoxLabelStyle: {
    fontWeight: "600",
    fontSize: "1.6rem",
  },
  recurringOptionDropdownStyle: {
    display: "inline-flex",
    marginTop: "-6px",
  },
  timeSelectorDropdownContainerStyle: {
    width: "100%",
  },
  timeSelectorDropdownItemStyle: {
    display: "flex",
    width: "100%",
  },
};

const RECURRENCE_FREQUENCY_TYPE = [
  {
    label: "common:day",
    value: "DAILY",
  },
  {
    label: "common:week",
    value: "WEEKLY",
  },
  {
    label: "common:month",
    value: "MONTHLY",
  },
];

const VIDEO_CALL_OPTIONS = [
  {
    label: "common:zoom",
    value: "ZOOM",
  },
  {
    label: "common:microsoft_teams",
    value: "MICROSOFT",
  },
  {
    label: "common:google_meet",
    value: "GOOGLE",
    perm: "FeatureFlag:CalendarGoogleMeet",
  },
  {
    label: "common:add_meeting_link",
    value: "OTHER",
  },
];

const DAYS = [
  {
    label: "S",
    value: 0,
  },
  {
    label: "M",
    value: 1,
  },
  {
    label: "T",
    value: 2,
  },
  {
    label: "W",
    value: 3,
  },
  {
    label: "T",
    value: 4,
  },
  {
    label: "F",
    value: 5,
  },
  {
    label: "S",
    value: 6,
  },
];

const WEEK_VALUES = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const DayIcon = React.memo(({ label, value, isSelected, updateDayChange }) => {
  const dayButtonClass = classNames(
    { [classes.dayButton]: true },
    { [classes.selectedDayButton]: isSelected }
  );
  return (
    <div className={dayButtonClass} onClick={() => updateDayChange(value)}>
      <p className={classes.dayLabel}>{label}</p>
    </div>
  );
});

const MeetingTypeComponent = ({
  meetingType,
  meetingURL,
  meetingChangesLocal,
  isZoomAuthorized,
  isMicrosoftAuthorized,
  onClickLinkZoom,
  onClickLinkMicrosoft,
  t,
  isGoogleAuthorized,
  updateThirPartyAuthRef,
  onClickLinkGoogle,
  onGoogleSuccessCallBack,
  onZoomSuccessCallBack,
}) => {
  switch (meetingType) {
    case "OTHER":
      return (
        <InputTextField
          name="url"
          placeholder={t("common:enter_with_label", {
            label: t("common:link"),
          })}
          updateInputField={meetingChangesLocal}
          value={meetingURL}
          isRequired={true}
        />
      );
    case "ZOOM":
      if (isNewZoomAccountLinkingEnable()) {
        return (
          <React.Fragment>
            {isZoomAuthorized ? (
              <div className={classes.linkedTextCon}>
                {t("common:zoom_link_generate_auto")}
              </div>
            ) : (
              <React.Fragment>
                <ThirdPartyAuth
                  serviceType="ZOOM"
                  ref={updateThirPartyAuthRef}
                  onSuccessCallBack={onZoomSuccessCallBack}
                ></ThirdPartyAuth>
                <UIButton
                  color={"blue"}
                  type={"hollow"}
                  containerStyle={{ marginTop: 16 }}
                  onClick={onClickLinkZoom}
                >
                  {t("common:link_zoom_account")}
                </UIButton>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            {isZoomAuthorized ? (
              <div className={classes.linkedTextCon}>
                {t("common:zoom_link_generate_auto")}
              </div>
            ) : (
              <UIButton
                color={"blue"}
                type={"hollow"}
                containerStyle={{ marginTop: 16 }}
                onClick={onClickLinkZoom}
              >
                {t("common:link_zoom_account")}
              </UIButton>
            )}
          </React.Fragment>
        );
      }
    case "MICROSOFT":
      return (
        <React.Fragment>
          {!_.isEmpty(isMicrosoftAuthorized) ? (
            <div className={classes.linkedTextCon}>
              {t("common:zoom_link_generate_auto")}
            </div>
          ) : (
            <UIButton
              color={"blue"}
              type={"hollow"}
              containerStyle={{ marginTop: 16 }}
              onClick={onClickLinkMicrosoft}
            >
              {t("common:link_microsoft_account")}
            </UIButton>
          )}
        </React.Fragment>
      );

    case "GOOGLE":
      return (
        <React.Fragment>
          {isGoogleAuthorized ? (
            <div className={classes.linkedTextCon}>
              {t("common:zoom_link_generate_auto")}
            </div>
          ) : (
            <React.Fragment>
              <ThirdPartyAuth
                serviceType="GOOGLE"
                ref={updateThirPartyAuthRef}
                onSuccessCallBack={onGoogleSuccessCallBack}
              ></ThirdPartyAuth>
              <UIButton
                color={"blue"}
                type={"hollow"}
                containerStyle={{ marginTop: 16 }}
                onClick={onClickLinkGoogle}
              >
                {t("common:link_google_account")}
              </UIButton>
            </React.Fragment>
          )}
        </React.Fragment>
      );
  }
};

const MeetingTypeComponentMemo = memo(MeetingTypeComponent);

const ScheduleEvent = React.memo(props => {
  const {
    courseId,
    courseDetails,
    portalType,
    showAddClassButton,
    getUserZoomAuthorizedStatus,
    generateZoomRefreshToken,
    t,
    curriculumProgram,
    onClickDiscard,
    mode,
    createHeader,
    meetingData,
    coursesData,
    selectedTimeSlot,
    createPlatformCalendarEvent,
    editPlatformCalendarEvent,
    endDate,
    oldCourseIds,
    oldUserIds,
    preSelectedCoursesDetails,
    refetchUpcomingEvents,
    getUserThirdPartyAuthorizedStatus,
    userMeetingTypePreference,
    updateSettings,
    userId,
    showCalendarAssignmentByDueDate,
  } = props;

  const [meetingObj, setMeetingObj] = useState(
    mode === "create"
      ? {
          id: "",
          label: "",
          description: "",
          url: "",
          type: _.isEmpty(userMeetingTypePreference)
            ? "ZOOM"
            : userMeetingTypePreference,
          authToken: "",
          microsoftAuthToken: "",
          isZoomAuthorized: false,
          scheduleDate: _.get(
            selectedTimeSlot,
            "startDate",
            moment().add(1, "days").format("YYYY-MM-DD")
          ),
          scheduleTime: _.get(selectedTimeSlot, "startTime", "00:00"),
          scheduleEndTime: moment(
            _.get(selectedTimeSlot, "startTime", "00:00"),
            "H:mm"
          )
            .add(30, "minutes")
            .format("HH:mm"),
          isRecurring: false,
          frequency: "WEEKLY",
          recurringInterval: 1,
          recurringWeekDays: [],
          monthRecurringUnit: "by_date",
          monthDays: [],
          setPositions: [],
          scheduledEndDate: moment(endDate).format("YYYY-MM-DD"),
        }
      : meetingData
  );

  const [courses, setCourses] = useState(
    mode === "create"
      ? !_.isEmpty(courseId)
        ? getFormattedCoursesDetailsMemoize({
            courses: [courseDetails],
            selectedTimeSlot,
            showCalendarAssignmentByDueDate,
          })
        : getFormattedCoursesDetailsMemoize({
            courses: preSelectedCoursesDetails,
            selectedTimeSlot,
            showCalendarAssignmentByDueDate,
          })
      : coursesData
  );

  const meetingLabel = _.get(meetingObj, "label", "");
  const description = _.get(meetingObj, "description", "");
  const meetingURL = _.get(meetingObj, "url", "");
  const meetingType = _.get(meetingObj, "type", "");
  const isZoomAuthorized = _.get(meetingObj, "isZoomAuthorized", false);
  const isGoogleAuthorized = _.get(meetingObj, "isGoogleAuthorized", false);
  const isMicrosoftAuthorized = _.get(meetingObj, "microsoftAuthToken", "");
  const scheduleDate = _.get(meetingObj, "scheduleDate", "");
  const scheduleStartTime = _.get(meetingObj, "scheduleTime", "");
  const scheduleEndTime = _.get(meetingObj, "scheduleEndTime", "");

  const recurringMeetSelected = _.get(meetingObj, "isRecurring");
  const recurringInterval = _.get(meetingObj, "recurringInterval");
  const recurringFrequencyType = _.get(meetingObj, "frequency");
  const monthRecurringUnit = _.get(meetingObj, "monthRecurringUnit");
  const scheduledEndDate = _.get(meetingObj, "scheduledEndDate");

  const localLocale = moment(scheduleDate, "YYYY-MM-DD");
  localLocale.locale("en");
  const dateForMonthlyRecurringOption = moment(
    scheduleDate,
    "YYYY-MM-DD"
  ).format("DD");
  const weekDayForMonthlyRecurringOption = localLocale.format("dddd");
  const weekNumberForMonthlyRecurringOption = weekOfMonth({
    date: moment(scheduleDate),
  });

  const recurringWeekDays = !_.isEmpty(meetingObj.recurringWeekDays)
    ? meetingObj.recurringWeekDays
    : recurringFrequencyType === "WEEKLY"
    ? [WEEK_VALUES[weekDayForMonthlyRecurringOption]]
    : [];

  const monthlyRecurringOptions = useMemo(
    () =>
      createMonthlyRecurringOptions({
        date: dateForMonthlyRecurringOption,
        weekDay: weekDayForMonthlyRecurringOption,
        weekNumber: weekNumberForMonthlyRecurringOption,
      }),
    [
      dateForMonthlyRecurringOption,
      weekDayForMonthlyRecurringOption,
      weekNumberForMonthlyRecurringOption,
    ]
  );

  const zoomLinkingEnabled = isNewZoomAccountLinkingEnable();

  const [showAddClassModal, changeShowAddClassModal] = useState(false);
  const [coursesSearchText, changeCoursesSeachText] = useState("");
  const [showFullScreenLoader, toggleFullScreenLoader] = useState(false);
  const [showActionForModal, setShowActionForModal] = useState(false);
  const [isScheduleButtonDisabled, setScheduleButtonDisable] = useState(false);
  const [isFromTimeInvalid, setIsFromTimeInvalid] = useState(false);
  const [isToTimeInvalid, setIsToTimeInvalid] = useState(false);

  const authPopUpRef = useRef(null);
  const updateThirPartyAuthRef = useRef(null);

  const getThirdPartyServiceStatus = async () => {
    toggleFullScreenLoader(true);
    const promises = [getMicrosoftAuthorizeStatus()];
    if (zoomLinkingEnabled) {
      promises.push(getUserThirdPartyAuthorizedStatus({ serviceType: "ZOOM" }));
    } else {
      promises.push(getZoomAuthStatusLocal());
    }
    if (ACLStore.can("FeatureFlag:CalendarGoogleMeet")) {
      promises.push(
        getUserThirdPartyAuthorizedStatus({ serviceType: "GOOGLE" })
      );
    }
    let isGoogleAuthorized = false;
    const values = await Promise.all(promises);
    const accessToken = _.get(values, ["2", "accessToken"], null);
    if (!_.isEmpty(accessToken)) {
      isGoogleAuthorized = await checkUserAuthScopes({
        accessToken,
        scopes: ["https://www.googleapis.com/auth/calendar"],
      });
    }
    meetingChangesLocal({
      microsoftAuthToken: values[0],
      isZoomAuthorized: values[1],
      isGoogleAuthorized: isGoogleAuthorized,
    });
    toggleFullScreenLoader(false);
  };

  useEffect(() => {
    getThirdPartyServiceStatus();
  }, []);

  useEffect(() => {
    if (isToTimeInvalid || isFromTimeInvalid) {
      setScheduleButtonDisable(true);
    } else {
      setScheduleButtonDisable(false);
    }
  }, [isFromTimeInvalid, isToTimeInvalid]);

  const meetingChangesLocal = params => {
    setMeetingObj({ ...meetingObj, ...params });
  };

  const getZoomAuthStatusLocal = async () => {
    const zoomAuthorizedStatus = await getUserZoomAuthorizedStatus();
    return zoomAuthorizedStatus;
  };

  const handleDaySelection = value => {
    if (_.includes(recurringWeekDays, value)) {
      meetingChangesLocal({
        recurringWeekDays: _.filter(recurringWeekDays, day => day != value),
      });
    } else {
      meetingChangesLocal({
        recurringWeekDays: [...recurringWeekDays, value],
      });
    }
  };

  const getMicrosoftAuthorizeStatus = async () => {
    return new Promise(resolve => {
      Microsoft.getUserProfile({
        successCallback: accessToken => {
          // console.log("microsoft accessToken", accessToken);
          resolve(accessToken);
        },
        failureCallback: () => {
          // console.log("microsoft account not linked");
          resolve("");
        },
      });
    });
  };

  const updatedClassItem = ({ id, params }) => {
    const courseIndex = _.findIndex(courses, { id });
    setCourses(
      update(courses, {
        [courseIndex]: { $set: { ...courses[courseIndex], ...params } },
      })
    );
  };

  const onClickAddClasses = params => {
    setCourses([...courses, ...params]);
    changeShowAddClassModal(false);
  };

  const onClickRemoveClass = id => {
    const removeIndex = _.findIndex(courses, course => course.id === id);
    setCourses(update(courses, { $splice: [[removeIndex, 1]] }));
  };

  const onClickLinkZoom = () => {
    if (zoomLinkingEnabled) {
      if (updateThirPartyAuthRef && updateThirPartyAuthRef.current) {
        const onAuthClick = _.get(
          updateThirPartyAuthRef,
          "current.refs.wrappedInstance.onAuthClick"
        );
        if (typeof onAuthClick === "function") {
          onAuthClick({ forceAuthorized: true });
        }
      }
    } else {
      if (authPopUpRef.current) {
        authPopUpRef.current.createPopup();
      }
    }
  };

  const onClickLinkGoogle = () => {
    if (updateThirPartyAuthRef && updateThirPartyAuthRef.current) {
      const onAuthClick = _.get(
        updateThirPartyAuthRef,
        "current.refs.wrappedInstance.onAuthClick"
      );
      if (typeof onAuthClick === "function") {
        onAuthClick({ forceAuthorized: true });
      }
    }
  };

  const onGoogleSuccessCallBack = () => {
    meetingChangesLocal({ isGoogleAuthorized: true });
  };

  const onZoomSuccessCallBack = () => {
    meetingChangesLocal({ isZoomAuthorized: true });
  };

  const onClickLinkMicrosoft = () => {
    Microsoft.loginPopup({
      successCallback: accessToken => {
        // console.log("onClickLinkMicrosoft accessToken", accessToken);
        meetingChangesLocal({ microsoftAuthToken: accessToken });
      },
      failureCallback: () => {
        // console.log("error while linking microsoft account");
        onClickLinkMicrosoft();
      },
    });
  };

  const onAuthCallBack = async accessCode => {
    toggleFullScreenLoader(true);
    const isSuccess = await generateZoomRefreshToken({
      accessCode,
      redirectUri,
    });
    toggleFullScreenLoader(false);
    if (isSuccess) {
      meetingChangesLocal({ isZoomAuthorized: true });
    }
  };

  const clonedVideoCallOptions = [];
  _.forEach(VIDEO_CALL_OPTIONS, item => {
    const isEnable = !item.perm || ACLStore.can(item.perm);
    if (isEnable) {
      clonedVideoCallOptions.push({
        ...item,
        label: t(`${item.label}`),
      });
    }
  });

  const recurringFrequencyTypeOptions = _.map(
    RECURRENCE_FREQUENCY_TYPE,
    item => {
      return {
        ...item,
        label: t(`${item.label}`).toLowerCase(),
      };
    }
  );

  const onStepChange = recurringInterval => {
    meetingChangesLocal({ recurringInterval });
  };

  const onRecurringUnitSelect = ({ frequency }) => {
    meetingChangesLocal({
      frequency: _.first(frequency),
      recurringWeekDays: [],
    });
  };

  const onMonthlyRecurringUnitSelect = ({ monthRecurringUnit }) => {
    if (_.first(monthRecurringUnit) === "by_date") {
      meetingChangesLocal({
        monthDays: [+dateForMonthlyRecurringOption],
        setPositions: [],
        monthRecurringUnit,
      });
    } else {
      meetingChangesLocal({
        setPositions: [weekNumberForMonthlyRecurringOption % 5 || -1],
        monthDays: [],
        recurringWeekDays: [WEEK_VALUES[weekDayForMonthlyRecurringOption]],
        monthRecurringUnit,
      });
    }
  };

  const onMeetingEdit = async option => {
    const isSuccess = await editPlatformCalendarEvent({
      meetingObj,
      courses,
      dateForMonthlyRecurringOption,
      oldCourseIds,
      oldUserIds,
      toggleFullScreenLoader,
      onClickDiscard,
      actionFor: option,
    });
    if (!isSuccess) {
      getThirdPartyServiceStatus();
      onEditModalClose();
    }
  };

  const onStartTimeChange = ({ scheduleTime }) => {
    meetingChangesLocal({
      scheduleTime,
      scheduleEndTime: moment(scheduleTime, "HH:mm")
        .add(30, "minutes")
        .format("HH:mm"),
    });
  };

  const onEditModalClose = () => {
    setShowActionForModal(false);
  };

  const _refetchUpcomingEvents = () => {
    if (refetchUpcomingEvents) {
      refetchUpcomingEvents();
    }
  };

  const onClickDone = async () => {
    const isRecurring = _.get(meetingObj, "isRecurring", false);
    if (userMeetingTypePreference != _.get(meetingObj, "type", "")) {
      updateSettings({
        id: userId,
        type: "user",
        settings: [
          {
            name: "TeacherPortal:schedualMeetingTypePreference",
            value: _.get(meetingObj, "type", ""),
          },
        ],
        showToastMsg: false,
      });
    }
    if (mode === "create") {
      const isSuccess = await createPlatformCalendarEvent({
        meetingObj,
        courses,
        dateForMonthlyRecurringOption,
        toggleFullScreenLoader,
        onClickDiscard,
      });
      if (isSuccess) {
        _refetchUpcomingEvents();
      } else {
        getThirdPartyServiceStatus();
      }
    } else {
      if (isRecurring) {
        setShowActionForModal(true);
      } else {
        const isSuccess = await editPlatformCalendarEvent({
          meetingObj,
          courses,
          dateForMonthlyRecurringOption,
          oldCourseIds,
          oldUserIds,
          toggleFullScreenLoader,
          onClickDiscard,
          actionFor: "ALL",
        });
        if (isSuccess) {
          _refetchUpcomingEvents();
        } else {
          getThirdPartyServiceStatus();
        }
      }
    }
  };

  const getTimeSelectorByPerm = ({
    value,
    dateVal,
    startTime,
    disableConfirmButton,
    updateInputField,
    name,
  }) => {
    return (
      <TimeSelectorV2
        value={value}
        SelectedTimeformat="HH:mm"
        name={name}
        isValueMomentObj={false}
        updateInputField={updateInputField}
        isDisabled={_.isEmpty(dateVal)}
        startTime={moment(startTime, "HH:mm").add(5, "minutes").format("HH:mm")}
        endTime={START_PICKER_END_TIME}
        dropdownItemStyle={styles.timeSelectorDropdownItemStyle}
        dropdownContainerStyle={styles.timeSelectorDropdownContainerStyle}
        timeComponentStyle={{
          padding: "0px",
        }}
        disableConfirmButton={disableConfirmButton}
      ></TimeSelectorV2>
    );
  };

  return (
    <div className={classes.container}>
      <Header
        onClickBack={onClickDiscard}
        mode={mode}
        createHeader={createHeader}
      ></Header>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <OauthPopup
            url={ZOOM_AUTH_URL}
            onCode={onAuthCallBack}
            ref={authPopUpRef}
          ></OauthPopup>
          <div className={classes.inputField}>
            <InputTextField
              label={t("common:video_meeting_title")}
              name="label"
              placeholder={t("common:enter_with_label", {
                label: t("common:video_meeting_title"),
              })}
              updateInputField={meetingChangesLocal}
              value={meetingLabel}
              isRequired={true}
            />
          </div>
          <div className={classes.inputField}>
            <TextAreaInput
              value={description}
              minRows={2}
              maxRows={null}
              label={t("calendar:video_meeting_desc")}
              updateInputField={meetingChangesLocal}
              name="description"
              placeholder={t("common:enter_with_label", {
                label: t("calendar:video_meeting_desc"),
              })}
            />
          </div>
          <RadioButtonList
            containerStyle={styles.radioButtonConStyle}
            labelContainerStyle={styles.videoOptionLabelStyle}
            options={clonedVideoCallOptions}
            name={"type"}
            value={meetingType}
            updateInputField={meetingChangesLocal}
            isRequired={true}
            showErrorText={true}
            listItemStyle={styles.radioButtonOptionStyle}
            label={t("calendar:meeting_link")}
          />
          <MeetingTypeComponentMemo
            meetingType={meetingType}
            meetingURL={meetingURL}
            meetingChangesLocal={meetingChangesLocal}
            isZoomAuthorized={isZoomAuthorized}
            isMicrosoftAuthorized={isMicrosoftAuthorized}
            onClickLinkZoom={onClickLinkZoom}
            onClickLinkMicrosoft={onClickLinkMicrosoft}
            isGoogleAuthorized={isGoogleAuthorized}
            updateThirPartyAuthRef={updateThirPartyAuthRef}
            onClickLinkGoogle={onClickLinkGoogle}
            t={t}
            onGoogleSuccessCallBack={onGoogleSuccessCallBack}
            onZoomSuccessCallBack={onZoomSuccessCallBack}
          />
          <hr className={classes.separator} />
          <div className={classes.meetingContainer}>
            <UILabel
              labelStyle={styles.meetingTimeLabelStyle}
              label={t("calendar:meeting_time")}
            ></UILabel>
            <div className={classes.horizontalRowContainer}>
              <DateSelector
                updateInputField={meetingChangesLocal}
                value={scheduleDate}
                name={"scheduleDate"}
                isValueMomentObj={false}
                minDate={moment().subtract(1, "day")}
              ></DateSelector>
              <UILabel
                labelContainerStyle={styles.meetingRangeTextContainerStyle}
                label={t("calendar:from_date")}
                labelStyle={fontStyle.regular}
              />
              {getTimeSelectorByPerm({
                dateVal: scheduleDate,
                value: scheduleStartTime,
                name: "scheduleTime",
                startTime: START_PICKER_START_TIME,
                disableConfirmButton: setIsFromTimeInvalid,
                updateInputField: onStartTimeChange,
              })}
              <UILabel
                labelContainerStyle={styles.meetingRangeTextContainerStyle}
                label={t("calendar:to_date")}
                labelStyle={fontStyle.regular}
              />
              {getTimeSelectorByPerm({
                dateVal: scheduleDate,
                value: scheduleEndTime,
                name: "scheduleEndTime",
                startTime: moment(scheduleStartTime, "HH:mm")
                  .add(5, "minutes")
                  .format("HH:mm"),
                disableConfirmButton: setIsToTimeInvalid,
                updateInputField: meetingChangesLocal,
              })}
            </div>
          </div>
          <div className={classes.recurringMeeting}>
            <Checkbox
              style={styles.checkboxContainerStyle}
              checkboxStyle={styles.checkboxStyle}
              fill={colors.blue29}
              label={t("calendar:recurring_meeting")}
              onCheckboxClick={() =>
                meetingChangesLocal({ isRecurring: !recurringMeetSelected })
              }
              isChecked={recurringMeetSelected}
              labelStyles={styles.checkBoxLabelStyle}
              checkboxItemStyle={styles.checkBoxItemStyle}
            />
            {recurringMeetSelected ? (
              <div className={classes.recurringItemsContainer}>
                <div className={classes.offestSelection}>
                  <UILabel
                    labelContainerStyle={styles.repeatTextContainerStyle}
                    label={t("calendar:repeat_every")}
                    labelStyle={fontStyle.regular}
                  />
                  <Stepper
                    value={recurringInterval}
                    containerStyle={styles.stepperStyle}
                    onValueChange={onStepChange}
                  />
                  <FilterDropdown
                    name={"frequency"}
                    updateInputField={onRecurringUnitSelect}
                    options={recurringFrequencyTypeOptions}
                    value={recurringFrequencyType}
                    isCheckList={false}
                    showAllOption={false}
                    parentContainerStyle={styles.recurringOptionDropdownStyle}
                  />
                </div>
                {recurringFrequencyType === "WEEKLY" && (
                  <div className={classes.weekdayRepeat}>
                    <UILabel
                      labelContainerStyle={styles.repeatTextContainerStyle}
                      label={t("calendar:repeat_on")}
                      labelStyle={fontStyle.regular}
                    />
                    <div className={classes.daysContainer}>
                      {_.map(DAYS, day => (
                        <DayIcon
                          label={day.label}
                          value={day.value}
                          updateDayChange={handleDaySelection}
                          key={day.value}
                          isSelected={_.includes(recurringWeekDays, day.value)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {recurringFrequencyType === "MONTHLY" && (
                  <FilterDropdown
                    updateInputField={onMonthlyRecurringUnitSelect}
                    options={monthlyRecurringOptions}
                    value={monthRecurringUnit}
                    isCheckList={false}
                    showAllOption={false}
                    name="monthRecurringUnit"
                    parentContainerStyle={{ marginBottom: "32px" }}
                  />
                )}
                <div>
                  <UILabel
                    labelContainerStyle={styles.endsOnTextContainerStyle}
                    label={t("calendar:ends_on")}
                    labelStyle={fontStyle.regular}
                  />
                  <div className={classes.endDateContainer}>
                    <DateSelector
                      updateInputField={meetingChangesLocal}
                      value={scheduledEndDate}
                      name={"scheduledEndDate"}
                      isValueMomentObj={false}
                      minDate={moment().subtract(1, "day")}
                    ></DateSelector>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <hr className={classes.separator} />
          <div className={classes.coursesContainer}>
            {_.map(courses, course => {
              return (
                <ClassCard
                  course={course}
                  key={course.id}
                  courseId={courseId}
                  onClickRemoveClass={onClickRemoveClass}
                  updateItem={params =>
                    updatedClassItem({ id: course.id, params })
                  }
                  t={t}
                  portalType={portalType}
                  setDisableButton={setScheduleButtonDisable}
                ></ClassCard>
              );
            })}
          </div>
          {showAddClassButton && (
            <UIButton
              size="sm"
              color={"blue"}
              type={"hollow"}
              containerStyle={styles.addClassLabelStyle}
              onClick={() => changeShowAddClassModal(true)}
            >
              {t("common:add_with_label", { label: t("common:class") })}
            </UIButton>
          )}
          {showAddClassModal && (
            <UIModal
              isOpen={showAddClassModal}
              modalContent={classes.addClassModalContainer}
              onRequestClose={() => changeShowAddClassModal(false)}
            >
              <AddClassModal
                curriculumProgram={curriculumProgram}
                onClickClose={() => changeShowAddClassModal(false)}
                onClickAddClasses={onClickAddClasses}
                coursesSearchText={coursesSearchText}
                changeCoursesSeachText={changeCoursesSeachText}
                courses={courses}
                portalType={portalType}
                subText={t("classRoom:add_class_subText1")}
              />
            </UIModal>
          )}
          {showActionForModal ? (
            <ActionForSelectModal
              t={t}
              onAction={onMeetingEdit}
              onClose={onEditModalClose}
              mode="edit"
            />
          ) : null}
          {!!showFullScreenLoader && <FullScreenLoader />}
        </div>
      </div>
      <Footer
        mode={mode}
        onClickCancel={onClickDiscard}
        onClickDone={onClickDone}
        isNextDisabled={true}
        isLast={true}
        currentStep="SCHEDULE_CALL_SETTINGS"
        isButtonDisabled={isScheduleButtonDisabled}
      ></Footer>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const curriculumProgram = _.get(ownProps.course, "curriculumProgram", {});
  const orgId = state.login.userInfo.org_id;
  const { startDate, endDate } = getCurrentAcademicYear({
    organizationId: orgId,
  });
  const userMeetingTypePreference = getSettingValue({
    name: "TeacherPortal:schedualMeetingTypePreference",
    userId: state.login.userInfo.id,
  });

  const showCalendarAssignmentByDueDate =
    getCalendarEventsViewSettingValue({ organizationId: orgId }) === "DUE_DATE";

  return {
    curriculumProgram,
    startDate,
    endDate,
    isData: true,
    isLoading: false,
    userMeetingTypePreference,
    userId: state.login.userInfo.id,
    showCalendarAssignmentByDueDate,
  };
};
const mapActionCreators = {
  getUserZoomAuthorizedStatus,
  generateZoomRefreshToken,
  createPlatformCalendarEvent,
  editPlatformCalendarEvent,
  setToastMsg,
  getUserThirdPartyAuthorizedStatus,
  updateSettings,
};

export default compose(
  I18nHOC({ resource: ["common", "classRoom", "calendar"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseStudentsFoldersQuery, {
    skip: ({ courseId }) => !courseId,
    name: "getCourseStudentsFolders",
    options: ({ courseId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
        folderType: "CLASSROOM",
        portalType,
      },
    }),
    props({
      getCourseStudentsFolders,
      ownProps: { courseId, isData, isLoading, steps },
    }) {
      const courseDetails = getCourseStudentsFoldersFromCache({
        id: courseId,
        folderType: "CLASSROOM",
      });
      const hasClassStep = _.find(steps, ["value", "CLASS_SELECTION"]);
      return {
        courseDetails,
        isData: hasClassStep ? true : !_.isEmpty(courseDetails) && isData,
        isLoading:
          getCourseStudentsFolders["networkStatus"] == 1 ||
          getCourseStudentsFolders["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getMultiCourseStudentsFoldersQuery, {
    skip: ({ courseId, preSelectedCourseIds }) =>
      courseId || _.isEmpty(preSelectedCourseIds),
    name: "getMultiCourseStudentsFolders",
    options: ({ preSelectedCourseIds, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: preSelectedCourseIds,
        folderType: "CLASSROOM",
        portalType,
      },
    }),
    props({
      getMultiCourseStudentsFolders,
      ownProps: { preSelectedCourseIds, isData, isLoading, steps },
    }) {
      const courseDetails = getMultiCourseStudentsFoldersFromCache({
        ids: preSelectedCourseIds,
        folderType: "CLASSROOM",
      });
      return {
        preSelectedCoursesDetails: courseDetails,
        isData: !_.isEmpty(courseDetails) && isData,
        isLoading:
          getMultiCourseStudentsFolders["networkStatus"] == 1 ||
          getMultiCourseStudentsFolders["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getCalendarEventDetailsQuery, {
    skip: ({ mode, eventId }) => mode != "edit" || !eventId,
    name: "getCalendarEventDetails",
    options: ({ eventId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: eventId,
        type: "PLATFORM_CALENDAR_EVENT",
      },
    }),
    props({
      getCalendarEventDetails,
      ownProps: { eventId, isData, isLoading, endDate: academicYearEnd },
    }) {
      const data =
        getCalendarEventDetailsFromCache({
          id: eventId,
          type: "PLATFORM_CALENDAR_EVENT",
        }) || {};
      const calendarData = {
        ..._.pick(data, ["id", "startTime", "calendars", "endTime", "item"]),
      };

      const {
        label,
        description,
        meeting,
        users,
        recurringSetting,
      } = calendarData.item;

      const type = _.get(meeting, "urls.type", "");
      const url = _.get(meeting, "urls.url", "");

      const courseWiseData = getCourseWiseUsersDetails(
        _.map(calendarData.calendars, course => course.parent),
        [...users]
      );

      const oldCourseIds = _.map(
        calendarData.calendars,
        course => course.parent.id
      );
      const oldUserIds = _.map(users, user => user.id);

      const authToken = "";
      const microsoftAuthToken = "";
      const isZoomAuthorized = false;

      const scheduleDate = moment(calendarData.startTime).format("YYYY-MM-DD");
      const scheduleTime = moment(calendarData.startTime).format("HH:mm");
      const scheduleEndTime = moment(calendarData.endTime).format("HH:mm");
      const isRecurring = recurringSetting ? true : false;
      const {
        frequency,
        endDate,
        interval: recurringInterval,
        monthDays,
        setPositions,
        weekDays: recurringWeekDays,
      } = _.pick(recurringSetting || {}, [
        "frequency",
        "endDate",
        "interval",
        "monthDays",
        "setPositions",
        "weekDays",
      ]);
      const monthRecurringUnit = (setPositions || []).length
        ? "by_week"
        : "by_date";
      const scheduledEndDate = moment(+endDate || academicYearEnd).format(
        "YYYY-MM-DD"
      );

      const localLocale = moment(scheduleDate, "YYYY-MM-DD");
      localLocale.locale("en");
      const weekDayForMonthlyRecurringOption = localLocale.format("dddd");

      const meetingData = {
        id: calendarData.id,
        label,
        description,
        url,
        type,
        authToken,
        microsoftAuthToken,
        isZoomAuthorized,
        scheduleDate,
        scheduleTime,
        scheduleEndTime,
        isRecurring,
        frequency: frequency || "WEEKLY",
        recurringInterval,
        recurringWeekDays: recurringWeekDays || [
          WEEK_VALUES[weekDayForMonthlyRecurringOption],
        ],
        monthRecurringUnit,
        monthDays: monthDays || [],
        setPositions: setPositions || [],
        scheduledEndDate,
      };
      const coursesData = courseWiseData.map(courseData => ({
        ...courseData,
        state: {
          state: "PUBLISHED",
          scheduleDate: calendarData.startTime,
          scheduleTime: calendarData.startTime,
        },
      }));
      return {
        isData: !_.isEmpty(data) && isData,
        meetingData,
        coursesData,
        oldCourseIds,
        oldUserIds,
        isLoading:
          getCalendarEventDetails["networkStatus"] == 1 ||
          getCalendarEventDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(ScheduleEvent);
