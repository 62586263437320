import { injectReducer } from "store/reducers";
import Insight from "PlanningInsights/routes/Insight";
import { withAsyncRouteLoading } from "UIComponents";
import UnitPlanCreate from "UnitPlans/routes/UnitPlanCreate";
import UnitPlanDetails from "UnitPlanDetails";

export default store => ({
  path: "poi",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("POI/modules/POIModule").default;
            const teacherReducer = require("Teacher/modules/TeacherModule")
              .default;
            const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
              .default;
            injectReducer(store, { key: "poiAdmin", reducer });
            injectReducer(store, { key: "teacher", reducer: teacherReducer });
            injectReducer(store, { key: "unitPlans", reducer: unitPlanModule });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "organization-educatorCenter"
        );
      })
  ),
  childRoutes: [Insight(store), UnitPlanCreate(store), UnitPlanDetails(store)],
});
