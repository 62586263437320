import React from "react";
import classes from "./ToggleSwitchInput.scss";
import { UIBaseComponent } from "UIComponents";
import { ToggleSwitch } from "@toddle-design/web";

class SwitchButton extends UIBaseComponent {
  renderEdit = () => {
    const {
      value,
      isDisabled,
      toggleLabel,
      toggleSubText,
      toggleLabelStyle,
      toggleSubTextStyle,
    } = this.props;

    return (
      <div className={classes.container}>
        {(toggleLabel || toggleSubText) && (
          <div className={classes.textContainer}>
            {toggleLabel && (
              <div className={classes.labelText} style={toggleLabelStyle}>
                {toggleLabel}
              </div>
            )}
            {toggleSubText && (
              <div className={classes.subText} style={toggleSubTextStyle}>
                {toggleSubText}
              </div>
            )}
          </div>
        )}
        <div className={classes.toggleSwitchContainer}>
          <ToggleSwitch
            onChange={e => this.updateValue(e.target.checked)}
            disabled={isDisabled}
            checked={value}
          />
        </div>
      </div>
    );
  };
}

SwitchButton.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

export default SwitchButton;
