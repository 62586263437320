import React from "react";
import classes from "./ColorSelector.scss";
import { ArrowIcon } from "SvgComponents";
import { colors } from "Constants";
import { ColorDrop, ColorTray, ButtonDropdown } from "UIComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";

const styles = {
  dropdownContainer: {
    border: "none",
    padding: 0,
    right: "auto",
    marginBottom: 40,
    top: 56,
  },
};

class ColorSelector extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, showTray: false };
  }

  setShowTray = value => {
    this.setState({ showTray: value });
  };

  renderView = () => {
    const { value, colorDropSize, viewContainerStyle } = this.props;
    return (
      <div
        className={classes.colorDropContainer}
        style={{ ...viewContainerStyle }}
      >
        <ColorDrop size={colorDropSize} color={value} />
      </div>
    );
  };

  renderEdit = () => {
    const {
      value,
      options,
      colorDropSize,
      colorPalletButtonStyle,
    } = this.props;
    const { showTray } = this.state;
    return (
      <div className={classes.colorPalletButtonContainer}>
        <ButtonDropdown
          containerStyle={styles.dropdownContainer}
          buttonComponent={
            <div
              className={classes.colorPalletButton}
              style={colorPalletButtonStyle}
            >
              <ColorDrop size={colorDropSize} color={value} />

              <div
                style={{
                  marginLeft: 8,
                  transform: showTray ? "rotate(-180deg)" : "rotate(0deg)",
                  marginRight: "-20px",
                }}
              >
                <ArrowIcon fill={colors.gray48} />
              </div>
            </div>
          }
          dropdownComponent={
            <ColorTray
              selectedColor={value}
              onColorSelected={color => this.updateValue(color)}
              options={options}
            />
          }
          onUpdateShowDropDown={this.setShowTray}
        />
      </div>
    );
  };
}

ColorSelector.defaultProps = {
  ...UIBaseComponent.defaultProps,
  colorPalletButtonStyle: {},
};

export default ColorSelector;
