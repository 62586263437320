import React from "react";
import PropTypes from "prop-types";
import classes from "./ThemeSelectComponent.scss";
import { themeIcon } from "./assets/SVGs";
import { EmptyField } from "UIComponents";
import { Tick } from "SvgComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";

class ThemeSelectComponent extends UIBaseComponent {
  onClickTheme = value => {
    const { options } = this.props;

    if (this.props.value != value) {
      this.updateValue(value, { nodes: options });
    } else {
      this.updateValue("", { nodes: options });
    }
  };

  getThemeList = () => {
    const { options, value, emptyText, isDisabled } = this.props;
    const { error } = this.state;

    if (_.isEmpty(options)) {
      return <EmptyField title={emptyText} />;
    }

    return _.map(options, theme => {
      const isSelected = value == theme.value;

      const themeSvg = themeIcon({
        theme: theme.value,
        selectedTheme: value,
        metadata: theme.metadata,
      });

      const containerStyle = classNames(
        { [classes.themeComponent]: !isSelected },
        { [classes.themeComponentActive]: isSelected },
        { [classes.themeComponentDisabled]: isDisabled }
      );
      const themeContainerStyle = classNames(
        { [classes.themeIcon]: true },
        { [classes.error]: !!error }
      );

      return (
        <div
          key={theme.value}
          className={containerStyle}
          onClick={() => {
            !isDisabled ? this.onClickTheme(theme.value) : null;
          }}
        >
          <div className={themeContainerStyle}>
            {isSelected ? (
              <div className={classes.selectedTickComp}>
                <Tick width={24} height={24} />
              </div>
            ) : null}
            <div className={classes.themeSvg}>{themeSvg}</div>
          </div>
          <div className={classes.themeLabel}>{theme.label}</div>
        </div>
      );
    });
  };

  renderEdit = () => {
    const themeList = this.getThemeList();

    return (
      <div className={classes.componentContainer}>
        <div className={classes.themeListComp}>{themeList}</div>
      </div>
    );
  };
}

ThemeSelectComponent.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
};

ThemeSelectComponent.defaultProps = {
  ...UIBaseComponent.defaultProps,
  options: [],
  isDisabled: false,
};

export default ThemeSelectComponent;
