import gql from "graphql-tag";
import { toolResponseFragment } from "modules/CommonFragments";
import { assessmentEvaluationFragment } from "./AssessmentEvaluationFragments";

export const handleAssessmentToolResponseMutation = gql`
  mutation handleAssessmentToolResponse(
    $parentId: ID
    $parentType: ASSESSMENT_TOOL_RESPONSE_PARENT_TYPE_ENUM
    $studentAssessmentEvaluationId: ID!
    $rowId: ID!
    $columnId: ID
    $subjectiveResponse: String
    $type: ASSESSMENT_TOOL_TYPE_ENUM!
    $createdBy: ID!
    $creatorType: ASSESSMENT_TOOL_RESPONSE_CREATOR_TYPE_ENUM!
  ) {
    planner {
      handleAssessmentToolResponse(
        input: {
          parentId: $parentId
          parentType: $parentType
          studentAssessmentEvaluationId: $studentAssessmentEvaluationId
          rowId: $rowId
          columnId: $columnId
          type: $type
          subjectiveResponse: $subjectiveResponse
          createdBy: $createdBy
          creatorType: $creatorType
        }
      ) {
        id
      }
    }
  }
`;

export const createAssessmentEvaluationMutation = gql`
  mutation createAssessmentEvaluation(
    $assessmentId: ID!
    $courseId: ID!
    $createdBy: ID!
    $includedStudents: [ID!]
  ) {
    planner {
      createAssessmentEvaluation(
        input: {
          assessmentId: $assessmentId
          courseId: $courseId
          createdBy: $createdBy
          includedStudents: $includedStudents
          isEvaluatedByStudent: false
        }
      ) {
        ...evaluationItem
      }
    }
  }
  ${assessmentEvaluationFragment.evaluationFeed}
`;

export const updateStudentAssessmentEvaluationMutation = gql`
  mutation updateStudentAssessmentEvaluation(
    $id: ID!
    $isComplete: Boolean
    $isActive: Boolean
    $updatedBy: ID!
    $evaluateIBPYPElements: Boolean
    $remark: String
    $submittedByStudent: Boolean
  ) {
    planner {
      updateStudentAssessmentEvaluation(
        input: {
          id: $id
          evaluateIBPYPElements: $evaluateIBPYPElements
          isComplete: $isComplete
          updatedBy: $updatedBy
          remark: $remark
          isActive: $isActive
          submittedByStudent: $submittedByStudent
        }
      ) {
        id
      }
    }
  }
`;

export const updateAssessmentEvaluationMutation = gql`
  mutation updateAssessmentEvaluation(
    $id: ID!
    $isEvaluatedByStudent: Boolean
    $updatedBy: ID!
    $removedStudents: [ID!]
    $addedStudents: [ID!]
  ) {
    planner {
      updateAssessmentEvaluation(
        input: {
          id: $id
          isEvaluatedByStudent: $isEvaluatedByStudent
          isComplete: false
          updatedBy: $updatedBy
          addedStudents: $addedStudents
          removedStudents: $removedStudents
        }
      ) {
        ...evaluationItem
      }
    }
  }
  ${assessmentEvaluationFragment.evaluationFeed}
`;

export const deleteAssessmentEvaluationMutation = gql`
  mutation deleteAssessmentEvaluation($id: ID!, $deletedBy: ID!) {
    planner {
      deleteAssessmentEvaluation(input: { id: $id, deletedBy: $deletedBy })
    }
  }
`;
