import React from "react";

// Design System
import { Checkbox, Tooltip } from "@toddle-design/web";

// Utils
import ConditionalWrapper from "Utils/ConditionalWrapper";

// Scss
import classes from "./GroupedListItem.scss";

const GroupedListItem = React.memo(props => {
  const { option, isChecked, selectedItems, setSelectedItems } = props;

  const { id, icon, title } = option;

  const onClickItem = itemId => {
    let selectedItemsLocal = [];
    if (isChecked) {
      selectedItemsLocal = _.filter(selectedItems, ({ id }) => id != itemId);
    } else {
      selectedItemsLocal = [...selectedItems, option];
    }
    setSelectedItems(selectedItemsLocal);
  };

  return (
    <div className={classes.container} onClick={() => onClickItem(id)}>
      <div className={classes.innerContainer}>
        <div className={classes.optionContainer}>
          <div className={classes.leftContainer}>
            {icon && <div className={classes.iconContainer}>{icon}</div>}
            <ConditionalWrapper
              condition={_.size(title) > 40}
              wrapper={<Tooltip tooltip={title} placement="bottom" />}
              type="wrapperAsElement"
            >
              {title && <div className={classes.titleContainer}>{title}</div>}
            </ConditionalWrapper>
          </div>
          <div className={classes.rightContainer}>
            <Checkbox isChecked={isChecked} />
          </div>
        </div>
      </div>
    </div>
  );
});

GroupedListItem.displayName = "GroupedListItem";

export default GroupedListItem;
