import React from "react";
import classes from "./UseTemplateHeader.scss";
import { ChevronIcon, ToddleIcon, NewTabIcon } from "SvgComponents";

const UseTemplateHeader = ({
  title,
  onClose,
  rightActionButton,
  shouldShowTabIcon,
  url,
  attachmentBottomComponent,
  showSimilarityIndex,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.logoCon} onClick={onClose}>
        <div className={classes.chevronIconContainer}>
          <ChevronIcon fill="#fff" />
        </div>

        <ToddleIcon width={40} height={40} />
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.rightPanel}>
        {showSimilarityIndex && (
          <div className={classes.similarityReportBorderStyle}>
            {attachmentBottomComponent()}
          </div>
        )}
        {shouldShowTabIcon && (
          <a
            className={classes.newTabIcon}
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <NewTabIcon fill="#fff" width={20} height={20} />
          </a>
        )}
        {rightActionButton && (
          <div className={classes.turnInButtonContainer}>
            {rightActionButton}
          </div>
        )}
      </div>
    </div>
  );
};
UseTemplateHeader.defaultProps = {
  shouldShowTabIcon: true,
};

export default UseTemplateHeader;
