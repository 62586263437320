import gql from "graphql-tag";
import { unitPlanFragment } from "./PlanathonUnitPlansFragments";

export const getUnitPlanDetailsQuery = gql`
  query getUnitPlanDetails($id: ID!) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFragment.unitPlanDetails}
`;
