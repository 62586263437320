import ReactDOMServer from "react-dom/server";
import React from "react";
import { createRegex } from "Utils";

export const makeBold = (item, keyword) => {
  var re = createRegex({ text: keyword || "", flags: "gi" });
  return item.replace(re, val =>
    ReactDOMServer.renderToStaticMarkup(<b>{val}</b>)
  );
};
