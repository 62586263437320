import update from "immutability-helper";
import client from "apolloClient";

import {
  getAssessmentToolNodeDetailsFromCache,
  writeAssessmentToolNodeDetailsInCache,
} from "modules/CommonGraphqlHelpers";
import {
  createExemplarInAssessment,
  createAnecdoteInAssessment,
  createMYPObjectiveRubricInAssessment,
} from "modules/AssessmentTool/AssessmentToolModule";
import { saveRubric, deleteRubric } from "Rubric/modules/RubricModule";
import {
  saveChecklist,
  deleteChecklist,
} from "Checklist/modules/ChecklistModule";
import {
  deleteSinglePointRubric,
  saveSinglePointRubric,
} from "SinglePointRubric/modules/SinglePointRubricModule";
import { updateRubricMutation } from "Rubric/modules/RubricMutation";
import { updateChecklistMutation } from "Checklist/modules/ChecklistMutation";
import { updateSinglePointRubricMutation } from "SinglePointRubric/modules/SinglePointRubricMutation";
import {
  updateAnecdotalMutation,
  updateExemplarMutation,
} from "modules/AssessmentTool/AssessmentToolMutations";
export const writeAssessmentToolInLocal = ({ id, params, type }) => {
  return (dispatch, getState) => {
    let data = getAssessmentToolNodeDetailsFromCache({ id, type });
    data = { ...data, ...params };
    writeAssessmentToolNodeDetailsInCache({ id, data, type });
  };
};

export const updateAssessmentTool = ({ id, params, type }) => {
  return async (dispatch, getState) => {
    try {
      let mutation = "";
      switch (type) {
        case "RUBRIC":
          mutation = updateRubricMutation;
          break;
        case "CHECKLIST":
          mutation = updateChecklistMutation;
          break;
        case "SINGLE_POINT_RUBRIC":
          mutation = updateSinglePointRubricMutation;
          break;
        case "ANECDOTE":
          mutation = updateAnecdotalMutation;
          break;
        case "EXEMPLAR":
          mutation = updateExemplarMutation;
          break;
      }
      await client.mutate({
        mutation,
        variables: {
          ...params,
          id,
          updatedBy: getState().login.userInfo.id,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteAssessmentTool = ({ id, type, filters }) => {
  return (dispatch, getState) => {
    switch (type) {
      case "RUBRIC":
        dispatch(
          deleteRubric({
            id,
            filters,
          })
        );
        break;
      case "CHECKLIST":
        dispatch(
          deleteChecklist({
            id,
            filters,
          })
        );
        break;
      case "SINGLE_POINT_RUBRIC":
        dispatch(
          deleteSinglePointRubric({
            id,
            filters,
          })
        );
        break;
    }
  };
};

export const createAssessmentTool = ({
  type,
  // assessmentId = null,
  isGlobal = true,
  title = "",
  copyToolId = null,
  mode = "CREATE",
  parentId,
  parentType,
  assessmentToolData,
  filters,
  curriculumProgramId,
}) => {
  return async (dispatch, getState) => {
    let id = null;
    switch (type) {
      case "RUBRIC":
        id = await dispatch(
          saveRubric({
            mode,
            title,
            copyToolId,
            isGlobal,
            parentId,
            parentType,
            filters,
            curriculumProgramId,
          })
        );
        break;
      case "CHECKLIST":
        id = await dispatch(
          saveChecklist({
            mode,
            title,
            copyToolId,
            isGlobal,
            parentId,
            parentType,
            filters,
            curriculumProgramId,
          })
        );
        break;
      case "SINGLE_POINT_RUBRIC":
        id = await dispatch(
          saveSinglePointRubric({
            mode,
            title,
            copyToolId,
            isGlobal,
            parentId,
            parentType,
            filters,
            curriculumProgramId,
          })
        );
        break;
      case "EXEMPLAR":
        id = await dispatch(
          createExemplarInAssessment({ parentId, parentType })
        );
        break;
      case "ANECDOTE":
        id = await dispatch(
          createAnecdoteInAssessment({ parentId, parentType })
        );
        break;
      case "MYP_OBJECTIVE_RUBRIC":
        id = await dispatch(
          createMYPObjectiveRubricInAssessment({
            parentId,
            parentType,
            ...assessmentToolData,
          })
        );
        break;
    }
    return id;
  };
};
