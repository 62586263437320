import ACLStore from "lib/aclStore";
import { CLOUD_URL } from "Constants";
import { getSettingsOfNodeFromCache } from "./CommonGraphqlHelpers";
import {
  SNPv2_VISITOR_MODULE_PERM,
  CURRICULUM_SUBSCRIPTION_PLAN_PERM,
} from "Constants/permissionConstants";
import update from "immutability-helper";
export const SCHEDULE_MEETING_URL = "https://meetings.hubspot.com/deepanshu3";

export const FEATURE_PERMISSION_MAPPING = {
  SCHEDULER: "TeacherPortal:Scheduler",
  YEARLY_INSIGHT: "TeacherPortal:YEARLY_INSIGHT",
  POI: ["TeacherPortal:POI", SNPv2_VISITOR_MODULE_PERM],
  ATTENDANCE: "TeacherPortal:Attendance",
  EVIDENCE: ["TeacherPortal:Evidence", SNPv2_VISITOR_MODULE_PERM],
  SNS_EDITOR: "AdminPortal:SnsEditor",
  PORTFOLIO_REPORTS: "TeacherPortal:PortfolioReports",
  FAMILY_COMMUNICATION: "TeacherPortal:FamilyCommunications",
};

export const LOCKED_SCREEN_CONFIG = {
  YEARLY_INSIGHT: {
    heading: "yearly_insight_heading",
    subHeading: "yearly_insight_subHeading",
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/planning/yearlyInsight.png`,
      },
    ],
    showCancelIcon: true,
  },

  POI: {
    heading: "poi_heading",
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/planning/feature_poi.png`,
        label: "poi_view",
      },
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/planning/poiInsight.png`,
        label: "poi_insights",
      },
    ],
    subHeading: "poi_subHeading",
  },
  EVIDENCE: {
    heading: "evidence_heading",
    showFeatures: true,
    showCTA: true,
    productType: "evidence",
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/hero/evidenceHero.png`,
      },
    ],
    subHeading: "evidence_subHeading",
  },
  SNS_EDITOR: {
    heading: "sns_editor_heading",
    showFeatures: true,
    showCTA: true,
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/hero/evidenceHero.png`,
      },
    ],
    subHeading: "sns_editor_subHeading",
  },
  FILE_SIZE_LIMIT: {
    heading: "file_size_limit_heading",
    showFeatures: true,
    showCTA: true,
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/hero/evidenceHero.png`,
      },
    ],
    subHeading: "file_size_limit_subHeading",
  },
  SCHEDULER: {
    heading: "scheduler_heading",
    subHeading: "scheduler_subHeading",
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/hero/schedulerHero.png`,
      },
    ],
    hasScrollable: true,
    showFeatures: true,
    showCTA: true,
    productType: "scheduler",
  },
  PORTFOLIO_REPORTS: {
    heading: "portfolio_reports_heading",
    showFeatures: true,
    showCTA: true,
    productType: "report",
    hasScrollable: true,
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/hero/reportHero.png`,
      },
    ],
    subHeading: "portfolio_reports_subHeading",
  },
  FAMILY_COMMUNICATION: {
    heading: "family_communication_heading",
    showFeatures: true,
    showCTA: true,
    productType: "communication",
    hasScrollable: true,
    images: [
      {
        image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/hero/communicationHero.png`,
      },
    ],
    subHeading: "family_communication_subHeading",
  },
  IMPORT_LE: {
    subText: "toddle_360_users",
    heading: "assign_assessment",
    subHeading: "toddle_360_le_assignment",
    upgradeText: "contact_upgrade_subscription",
    customFooter: true,
    smallContainer: true,
  },
  IMPORT_LE_ADMIN: {
    subText: "toddle_360_users",
    heading: "assign_assessment",
    subHeading: "toddle_360_le_assignment",
    upgradeText: "about_toddle_360",
    customFooter: true,
    smallContainer: true,
    showContactButton: true,
  },
};

export const ProductFeatures = {
  evidence: {
    photoOnLeftType: "even",
    features: [
      {
        key: "Evidence",
        title: "evidence_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/evidence/evidence.png`,
        description: "evidence_des",
      },
      {
        key: "Tag PYP Post",
        title: "tag_pyp_post_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/evidence/tagPYPPost.png`,
        description: "tag_pyp_post_des",
      },
      {
        key: "Reflection",
        title: "reflection_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/evidence/reflection.png`,
        description: "reflection_des",
      },
    ],
  },
  report: {
    photoOnLeftType: "odd",
    features: [
      {
        key: "Post Card",
        title: "post_card_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/report/postCard.png`,
        description: "post_card_des",
      },
      {
        key: "Student Assessment",
        title: "student_assessment_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/report/studentAssessment.png`,
        description: "student_assessment_des",
      },
      {
        key: "PYP Evaluation",
        title: "pyp_evaluation_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/report/pypEvaluation.png`,
        description: "pyp_evaluation_des",
      },
      {
        key: "Progress Report",
        title: "progress_report_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/report/porgressReport.png`,
        description: "progress_report_des",
      },
      {
        key: "Student Goal",
        title: "student_goal_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/report/studentGoals.png`,
        description: "student_goal_des",
      },
    ],
  },
  communication: {
    photoOnLeftType: "even",
    features: [
      {
        key: "Parent Journal",
        title: "parent_journal_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/communication/parentJournal.png`,
        description: "parent_journal_des",
      },
      {
        key: "ParentProgressReport",
        title: "parent_progress_report_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/communication/parentProgressReport.png`,
        description: "parent_progress_report_des",
      },
      {
        key: "ParentPolicy",
        title: "parent_policy_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/communication/parentPolicy.png`,
        description: "parent_policy_des",
      },
    ],
  },
  scheduler: {
    photoOnLeftType: "odd",
    features: [
      {
        key: "YearlyScheduler",
        title: "yearly_scheduler_title",
        images: [
          {
            image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/planning/yearlyScheduler.png`,
            label: "yearly_view",
          },
          {
            image: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/planning/yearlyInsight.png`,
            label: "yearly_insights",
          },
        ],
        description: "yearly_scheduler_des",
      },
      {
        key: "WeeklyScheduler",
        title: "weekly_scheduler_title",
        images: `${CLOUD_URL}/assets/toddleapp.com/illustrations/feature/planning/weeklyScheduler.png`,
        description: "weekly_scheduler_des",
      },
    ],
  },
};

export const getPermissionState = key => {
  const perm = FEATURE_PERMISSION_MAPPING[key];

  return ACLStore.getPermissionState(perm);
};

/**
 * Note this util is for temporary fix. Remove it when curriculum based perm is integrated
 */
export const getPermissionStateBasedOnSubscriptionPlan = ({
  key,
  isCurriculumProgramFree,
}) => {
  const isCurriculumSubscriptionPlanEnabled = ACLStore.can(
    CURRICULUM_SUBSCRIPTION_PLAN_PERM
  );

  if (!isCurriculumSubscriptionPlanEnabled) {
    return getPermissionState(key);
  }

  /**
   * For paid curriculum programs, use default behaviour
   */
  if (!isCurriculumProgramFree) {
    return getPermissionState(key);
  }

  /**
   * For free curriculum programs, if feature is enabled, treat it as locked, otherwise treat as disabled
   * Note: when feature is locked, lock screen will be shown
   */

  const perm = FEATURE_PERMISSION_MAPPING[key];

  const isFeatureEnabled = ACLStore.can(perm);

  if (isFeatureEnabled) {
    return "LOCKED";
  }
  return "DISABLED";
};

export const isFeatureLocked = key => {
  const perm = FEATURE_PERMISSION_MAPPING[key];

  return ACLStore.isLocked(perm);
};

export const getSettingValue = ({
  name,
  courseId,
  organizationId,
  userId,
  userEntityType = "STAFF",
}) => {
  let organizationSettings = [];
  let courseSettings = [];
  let userSettings = [];
  if (organizationId) {
    organizationSettings = getSettingsOfNodeFromCache({
      id: organizationId,
      type: "ORGANIZATION",
    });
  }

  if (courseId) {
    courseSettings = getSettingsOfNodeFromCache({
      id: courseId,
      type: "COURSE",
    });
  }

  if (userId) {
    userSettings = getSettingsOfNodeFromCache({
      id: userId,
      type: userEntityType,
    });
  }
  let allSettings =
    _.get(_.find(userSettings, { name }), "value") ||
    _.get(_.find(courseSettings, { name }), "value") ||
    _.get(_.find(organizationSettings, { name }), "value");
  try {
    allSettings = allSettings ? JSON.parse(allSettings) : [];
  } catch (error) {
    return allSettings;
  }
  return allSettings;
};
