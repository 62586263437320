import PropTypes from "prop-types";
import React from "react";

import { CustomLoginButton, CustomLoginBoxButton } from "UIComponents";

const style = {
  display: "inline-block",
  background: "#2387FB",
  color: "#fff",
  width: 190,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: 2,
  border: "1px solid transparent",
  fontSize: 16,
  fontWeight: "bold",
  fontFamily: "AvenirNextLTW01-Regular",
};

import { Gmail3D, GoogleColored } from "@toddle-design/web-icons";

class GoogleLogin extends React.PureComponent {
  componentDidMount() {
    const {
      clientId,
      scope,
      cookiePolicy,
      loginHint,
      hostedDomain,
    } = this.props;
    ((d, s, id, cb) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      js = d.createElement(s);
      js.id = id;
      js.src = "//apis.google.com/js/platform.js";
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = cb;
    })(document, "script", "google-login", () => {
      const params = {
        client_id: clientId,
        cookiepolicy: cookiePolicy,
        login_hint: loginHint,
        hosted_domain: hostedDomain,
        scope,
      };
      window.gapi?.load?.("auth2", () => {
        if (!window.gapi.auth2.getAuthInstance()) {
          window.gapi.auth2.init(params);
        }
      });
    });
  }

  onBtnClick = () => {
    if (!_.get(window, "gapi.auth2", "")) {
      return;
    }

    const auth2 = window.gapi.auth2.getAuthInstance();
    const { offline, redirectUri, onSuccess, onFailure } = this.props;
    if (offline) {
      const options = {
        redirect_uri: redirectUri,
      };
      auth2.grantOfflineAccess(options).then(
        res => {
          onSuccess(res);
        },
        err => {
          onFailure(err);
        }
      );
    } else {
      auth2.signIn({ prompt: "select_account" }).then(
        res => {
          /*
            offer renamed response keys to names that match use
          */
          const basicProfile = res.getBasicProfile();
          const authResponse = res.getAuthResponse();
          res.googleId = basicProfile.getId();
          res.tokenObj = authResponse;
          res.tokenId = authResponse.id_token;
          res.accessToken = authResponse.access_token;
          res.profileObj = {
            googleId: basicProfile.getId(),
            imageUrl: basicProfile.getImageUrl(),
            email: basicProfile.getEmail(),
            name: basicProfile.getName(),
            givenName: basicProfile.getGivenName(),
            familyName: basicProfile.getFamilyName(),
          };
          onSuccess(res);
        },
        err => {
          onFailure(err);
        }
      );
    }
  };

  render() {
    const { isBoxButton, innerConStyle } = this.props;
    return (
      <React.Fragment>
        {isBoxButton ? (
          <CustomLoginBoxButton
            data-test-id={"button-login-google"}
            icon={<Gmail3D height={88} width={88} />}
            buttonText={"Google"}
            onClick={this.onBtnClick}
          />
        ) : (
          <CustomLoginButton
            data-test-id={"button-login-google"}
            icon={<GoogleColored size="x-small" />}
            buttonText={"Sign in with Google"}
            onClick={this.onBtnClick}
            innerConStyle={innerConStyle}
          />
        )}
      </React.Fragment>
    );
  }
}

GoogleLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  offline: PropTypes.bool,
  scope: PropTypes.string,
  className: PropTypes.string,
  redirectUri: PropTypes.string,
  cookiePolicy: PropTypes.string,
  loginHint: PropTypes.string,
  hostedDomain: PropTypes.string,
  isBoxButton: PropTypes.bool,
  children: PropTypes.node,
};

GoogleLogin.defaultProps = {
  buttonText: "Login with Google",
  scope: "profile email",
  redirectUri: "postmessage",
  isBoxButton: false,
  cookiePolicy: "single_host_origin",
};

export default GoogleLogin;
