import React from "react";
import classes from "./Header.scss";
import { TickPlain, ChevronIcon } from "SvgComponents";
import { colors } from "Constants";
import { I18nHOC, UIButton } from "UIComponents";
import classNames from "classnames";

class Header extends React.PureComponent {
  render() {
    const {
      onBackClick,
      isDoneDisabled = false,
      showTimer,
      timer,
      isRecordingPaused,
      onDoneClick,
      t,
    } = this.props;

    const doneButtonStyle = !isDoneDisabled
      ? {
          borderRadius: 20,
          backgroundColor: colors.blue29,
          borderWidth: 0,
        }
      : { borderRadius: 20, borderWidth: 0 };

    const timerContainerClass = classNames({
      [classes.timerContainer]: true,
      [classes.darkTimerContainer]: isRecordingPaused,
    });

    const container = classNames({
      [classes.container]: true,
      [classes.darkContainer]: isRecordingPaused,
    });

    return (
      <div className={container}>
        <div className={classes.leftContainer}>
          <div className={classes.backContainer} onClick={onBackClick}>
            <div className={classes.chevronIconContainer}>
              <ChevronIcon />
            </div>
            <div className={classes.backButtonText}>{t("common:back")}</div>
          </div>
        </div>
        {showTimer && (
          <div className={classes.centerContainer}>
            <div className={timerContainerClass}>
              {!isRecordingPaused && <div className={classes.redDot}></div>}
              <div className={classes.timerText}>{timer}</div>
            </div>
          </div>
        )}
        <div className={classes.rightContainer}>
          <UIButton
            isDisabled={isDoneDisabled}
            containerStyle={{ ...doneButtonStyle }}
            onClick={onDoneClick}
            size="sm"
          >
            <div className={classes.tickIconContainer}>
              <TickPlain width={16} height={11} />
            </div>
            <div className={classes.buttonText}>{t("common:done")}</div>
          </UIButton>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(Header);
