import gql from "graphql-tag";

export const academicHonestyFragment = {
  basicDetails: gql`
    fragment academicHonestyItem on ProjectAcademicHonesty {
      id
      description
      meetingDate
      isLocked
      isStarred
    }
  `,
  remarksDetails: gql`
    fragment remarksDetailsItem on Remark {
      id
      message
      isLocked
    }
  `,
};
