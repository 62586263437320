import React, { createRef } from "react";
import classes from "./StudentLogin.scss";
import { GOOGLE_CLIENT_ID } from "Constants";
import {
  GoogleLoginBtn,
  MicrosoftLoginBtn,
  CustomLoginBoxButton,
  FormTextInput,
} from "UIComponents";
import { Button } from "@toddle-design/web";

import { ThirdPartyAuth } from "AppComponents";
import {
  Clever3D,
  Lex3D,
  Mail3D,
  ProfileOutlined,
} from "@toddle-design/web-icons";

class StudentLogin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
    };

    this.inputRef = createRef(null);
  }

  isValid = value => {
    return _.trim(value).length > 0;
  };

  onLoginClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "loginForm" });
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "loginHome" });
  };

  updateInputField = value => {
    const { updateInputField } = this.props;
    const { showError } = this.state;

    if (showError && this.isValid(value)) this.setState({ showError: false });
    updateInputField({ code: value });
  };

  onSubmitClick = () => {
    const {
      signInUsingCode,
      data: { code },
    } = this.props;

    this.inputRef?.isValid();
    if (this.isValid(code)) {
      signInUsingCode({ code });
    } else {
      this.setState({ showError: true });
    }
  };

  updateCleverAuthRef = ref => {
    this.authCleverRef = _.get(ref, "refs.wrappedInstance", {});
  };

  updateLexAuthRef = ref => {
    this.authLexRef = _.get(ref, "refs.wrappedInstance", {});
  };

  onCleverLogInClick = () => {
    if (this.authCleverRef) {
      this.authCleverRef.onAuthClick();
    }
  };

  onLexLoginClick = () => {
    if (this.authLexRef) {
      this.authLexRef.onAuthClick();
    }
  };

  render() {
    const {
      onGoogleSignInCallBack,
      onGoogelFailureCallBack,
      onMicroSoftSignInCallBack,
      onMicroSoftFailureCallBack,
      onCleverSignInCallBack,
      onLexSignInCallBack,
      renderTradeMarkText,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
      inputStyle,
      renderBackButtonComponent,
      data: { code } = {},
      currentScreen,
    } = this.props;

    const { showError } = this.state;

    return (
      <div className={classes.container} style={containerStyle}>
        {renderBackButtonComponent({
          onClick: this.onBackClick,
          buttonText: "Toddle home",
        })}
        <div style={{ ...headerStyle, fontSize: "3.2rem" }}>
          Student account
        </div>
        <div className={classes.signInCodeContainer}>
          <div className={classes.signInText}>
            {"Enter student sign-in code"}
          </div>
          <div className={classes.inputRowContainer}>
            <div className={classes.inputField}>
              <FormTextInput
                ref={ref => (this.inputRef = ref)}
                value={code}
                size={"large"}
                updateInputField={this.updateInputField}
                onEnter={this.onSubmitClick}
                leadingIcon={
                  <ProfileOutlined size="xx-small" variant="subtle" />
                }
                error={"Enter sign-in code"}
              />
            </div>
            <Button
              variant={"destructive"}
              disabled={code.length == 0}
              onClick={this.onSubmitClick}
              size={"large"}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </div>
        </div>
        <div className={classes.orContainer}>
          <div className={classes.orLineContainer} />
          <div className={classes.orText}>or sign in with</div>
          <div className={classes.orLineContainer} />
        </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.buttonContainer}>
            <GoogleLoginBtn
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={onGoogleSignInCallBack}
              onFailure={onGoogelFailureCallBack}
              prompt="select_account"
              isBoxButton={true}
            />
          </div>
          <div className={classes.buttonContainer}>
            <MicrosoftLoginBtn
              onSuccess={onMicroSoftSignInCallBack}
              onFailure={onMicroSoftFailureCallBack}
              isBoxButton={true}
            />
          </div>

          <div className={classes.buttonContainer}>
            <CustomLoginBoxButton
              icon={<Mail3D height={88} width={88} />}
              buttonText={"Email"}
              onClick={this.onLoginClick}
            />
          </div>

          <div className={classes.buttonContainer}>
            <CustomLoginBoxButton
              icon={<Clever3D height={88} width={88} />}
              buttonText={"Clever"}
              onClick={this.onCleverLogInClick}
            />
          </div>

          <div className={classes.buttonContainer}>
            <CustomLoginBoxButton
              icon={<Lex3D height={88} width={88} />}
              buttonText={"Lex"}
              onClick={this.onLexLoginClick}
            />
          </div>
        </div>
        {renderTradeMarkText()}

        <ThirdPartyAuth
          serviceType="CLEVER"
          ref={this.updateCleverAuthRef}
          onSuccessCallBack={onCleverSignInCallBack}
        />
        <ThirdPartyAuth
          serviceType="LEX"
          ref={this.updateLexAuthRef}
          onSuccessCallBack={onLexSignInCallBack}
        />
      </div>
    );
  }
}

export default StudentLogin;
