import React from "react";
import classes from "./TopHeader.scss";
import { LeftIcon, ToddleIcon } from "SvgComponents";
import { colors } from "Constants";
import { DownloadCloudOutlined } from "@toddle-design/web-icons";
import { LinkWithTooltip } from "UIComponents";

const TopHeader = React.memo(
  ({
    title,
    subTitle,
    onGoBackClick,
    showToddleIcon,
    containerStyle,
    showDownloadIcon,
    handleDownloadClick,
    t,
  }) => {
    return (
      <div className={classes.container} style={containerStyle}>
        <div className={classes.leftContainer}>
          <div className={classes.backButtonContainer} onClick={onGoBackClick}>
            <div className={classes.backIconContainer}>
              <LeftIcon fill={colors.gray48} />
            </div>
            {showToddleIcon && (
              <div className={classes.toddleIconContainer}>
                <ToddleIcon />
              </div>
            )}
          </div>
          <div className={classes.infoContainer}>
            <div className={classes.title}>{title}</div>
            {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
          </div>
        </div>
        {showDownloadIcon ? (
          <div onClick={handleDownloadClick} className={classes.downloadIcon}>
            <LinkWithTooltip
              tooltip={t("common:download")}
              placement={"bottom"}
            >
              <DownloadCloudOutlined size="x-small" variant="subtle" />
            </LinkWithTooltip>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
);

TopHeader.displayName = "TopHeader";

export default TopHeader;
