import TimeMe from "timeme.js";
import * as EventTracker from "lib/eventTracker";

const startTimer = ({ page_name }) => {
  TimeMe.initialize({
    currentPageName: page_name,
    idleTimeoutInSeconds: 30,
  });
};

const stopTimer = ({
  page_name,
  reset = true,
  entity_type,
  entity_id,
  entity_name,
  target,
  source = "community",
} = {}) => {
  TimeMe.stopTimer(page_name);
  const time_spent = TimeMe.getTimeOnPageInSeconds(page_name);

  if (!time_spent || time_spent <= 2) return;

  if (reset) {
    TimeMe.resetRecordedPageTime(page_name);
    EventTracker.recordEvent({
      eventName: "Time Tracker",
      eventData: {
        source,
        time_spent,
        target,
        page_name,
        entity_type,
        entity_id,
        entity_name,
      },
    });
  }
};

const showAllTimers = () => {
  return TimeMe.getTimeOnAllPagesInSeconds();
};

const resetAllTimers = () => {
  TimeMe.resetAllRecordedPageTimes();
};

const stopAllTimers = () => {
  resetAllTimers();
};

export { startTimer, stopTimer, showAllTimers, stopAllTimers };
