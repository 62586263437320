import React from "react";
import _ from "lodash";
import commonClasses from "../common.scss";
import classes from "./ReviewPanel.scss";
import { I18nHOC } from "UIComponents";
import classNames from "classnames";

import ProfileModal from "./ProfileModal";
import { ReviewPanelDetails } from "Planathon/data";
class ReviewPanelComp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showProfileModal: false, profileDetails: {} };
  }

  onClickImage = data => {
    this.setState({ showProfileModal: true, profileDetails: data });
    document.body.style.overflow = "hidden";
  };

  onCloseModal = () => {
    this.setState({ showProfileModal: false, profileDetails: {} });
    document.body.style.overflow = "auto";
  };

  render() {
    const { t } = this.props;
    const { showProfileModal, profileDetails } = this.state;
    const { title, juries, othersText, backgroundImages } = ReviewPanelDetails;
    const containerClass = classNames(
      { [commonClasses.container]: true },
      { [classes.container]: true }
    );

    return (
      <div className={classes.mainContainer}>
        {_.map(backgroundImages, backgroundImage => {
          const { image, style, name } = backgroundImage;
          return <img src={image} style={style} key={name}></img>;
        })}
        <div className={containerClass}>
          <div className={commonClasses.title}>{t(title)}</div>
          <div className={classes.stepsContainer}>
            {_.map(juries, (jurie, index) => {
              const { name, image, designation } = jurie;
              return (
                <div className={classes.step} key={index}>
                  <div
                    className={classes.stepImage}
                    onClick={() => this.onClickImage(jurie)}
                  >
                    <img src={image} height={"100%"}></img>
                  </div>
                  <div className={classes.stepTitle}>{name}</div>
                  <div className={classes.designation}>{t(designation)}</div>
                </div>
              );
            })}
          </div>
          {/* <div className={classes.othersText}>{t(othersText)}</div> */}
        </div>
        {showProfileModal && (
          <ProfileModal
            closeEnquiryModal={this.onCloseModal}
            profileDetails={profileDetails}
          ></ProfileModal>
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["planathon"] })(ReviewPanelComp);
