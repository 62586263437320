import {
  getRefinedProjectsAndExperiences,
  getCASProjectDetailsFilter,
} from "Projects/modules/ProjectModules";
import { OVERALL_PROGRESS } from "Projects/config/constants.js";

export const INTERVIEW_TOTAL_COUNTS = 3;
export const LO_TOTAL_COUNT = 7;

export const projectGroupPortfolioStudentViewFilters = {
  filters: getCASProjectDetailsFilter,
  projectFilters: { types: "PROJECT" },
  experienceFilters: { types: "EXPERIENCE" },
  interviewFilters: { isFormalInterview: true },
  postFilters: { state: "PUBLISHED" },
};

export const getOrganisedStudentDetailsFromPortfolioEdge = ({
  portfolioEdgeNode,
}) => {
  const {
    achievedPlannerElements,
    projects,
    id,
    interviews,
    projectCounts,
    experiences,
    status,
    strandCounts,
    isComplete,
  } = portfolioEdgeNode;

  const refinedProjects = _.first(
    getRefinedProjectsAndExperiences({
      data: _.get(projects, "edges", []),
    })
  );

  const refinedExperiences = _.last(
    getRefinedProjectsAndExperiences({
      data: _.get(experiences, "edges", []),
    })
  );

  const statusDetails = _.find(OVERALL_PROGRESS, {
    value: status,
  });

  const strandList = [
    {
      id: "Creativity",
      label: "project:creativity",
      count: _.get(strandCounts, "creativityCount", "0"),
    },
    {
      id: "Activity",
      label: "project:activity",
      count: _.get(strandCounts, "activityCount", "0"),
    },
    {
      id: "Service",
      label: "project:service",
      count: _.get(strandCounts, "serviceCount", "0"),
    },
  ];

  const statsList = [
    {
      value: _.get(projectCounts, "projectsCount", null),
      subtext: "project:project",
    },
    {
      value: _.get(projectCounts, "experiencesCount", null),
      subtext: "project:experience_plural",
    },
    {
      id: "interviewsAndFeedback",
      value: "project:selected_count_of_total_count",
      localisationVars: {
        count: _.get(interviews, "totalCount", 0),
        totalCount: INTERVIEW_TOTAL_COUNTS,
      },
      subtext: "project:interviews_conducted",
    },
    {
      id: "evidencesAndReflections",
      value: "project:selected_count_of_total_count",
      localisationVars: {
        count: _.get(achievedPlannerElements, "totalCount", 0),
        totalCount: LO_TOTAL_COUNT,
      },
      subtext: "project:learning_outcomes_achieved",
    },
    {
      id: "interestsAndGoals",
      value: "common:add_with_label",
      localisationVars: {
        label: "project:interests_and_goals",
      },
    },
    {
      value: statusDetails?.value,
      subtext: "project:overall_progress",
      color: _.get(statusDetails, "style.color", null),
    },
  ];

  return {
    portfolioId: id,
    projects: refinedProjects,
    experiences: refinedExperiences,
    statsList,
    strandList,
    isComplete,
  };
};
