import React from "react";
import classes from "./RequestSuccess.scss";
import { ToddleIcon } from "SvgComponents";
import * as EventTracker from "lib/eventTracker";
import { openLinkInNewTab } from "modules/Services";
import { SCHEDULE_MEETING_URL_FREE_DEMO } from "Constants";
import { connect } from "react-redux";
class RequestSuccess extends React.PureComponent {
  getDetailText = () => {
    const {
      data: { status, isDomainValid },
    } = this.props;
    switch (status) {
      case "REQ_ACC_NO_ORG":
        return "We will notify you via email once we finish reviewing your documents. It usually takes less than a day.";

      case "REQ_ACC_HS_ORG":
        return "An approval request for your account has been sent to the PYP coordinator of your school. You will be notified via email once they take an action.";

      case "SCHOOL_ADDED":
        return "We will notify you via email once we finish reviewing your documents. It usually takes less than a day. ";
    }
    return "";
  };

  getHeaderText = () => {
    const {
      data: { status, isDomainValid },
    } = this.props;
    switch (status) {
      case "REQ_ACC_NO_ORG":
        return "Thank you for providing us with the information!";

      case "REQ_ACC_HS_ORG":
        return "Approval request sent!";

      case "SCHOOL_ADDED":
        return "Thank you for providing us with the information!";
    }
    return "";
  };

  onScheduleClick = () => {
    const { openLinkInNewTab } = this.props;
    EventTracker.recordEvent({
      eventName: "Clicked Schedule Demo",
      eventData: { from: "schedule demo modal" },
    });
    openLinkInNewTab({ url: SCHEDULE_MEETING_URL_FREE_DEMO });
  };

  render() {
    const {
      customStyle: { containerStyle } = {},
      data: { status, firstName },
    } = this.props;
    const headerText = this.getHeaderText();

    return (
      <div
        className={classes.container}
        style={{ ...containerStyle, paddingTop: 232 }}
      >
        <div className={classes.logoContainer}>
          <ToddleIcon width={80} height={80} />
        </div>
        {!!headerText && (
          <div className={classes.headerText}>{this.getHeaderText()}</div>
        )}

        <div className={classes.detailText}>{this.getDetailText()}</div>
      </div>
    );
  }
}

const mapActionCreators = {
  openLinkInNewTab,
};

export default connect(null, mapActionCreators)(RequestSuccess);
