import React from "react";
import { CriteriaRubricAssessment, SupervisorComment } from "AppComponents";
import classes from "./CriteriaRubricAssessmentWithComments.scss";
import { useI18n } from "Hooks";

const CriteriaRubricAssessmentWithComments = props => {
  const {
    label,
    subText,
    assessmentRemarks,
    ibPYPElementRatings,
    isRatingEditable,
    showSupervisorComment,
    typeOfScale,
    criteria,
    commentLabelLocale,
    onSelectMark,
    updateRemarks,
    assessmentContainerStyle,
    assessmentHeading,
  } = props;

  const { t } = useI18n(["common"]);

  const ratedCriteria = _.map(ibPYPElementRatings, item => {
    return {
      ibPYPElementId: item?.ibPYPElementId,
      value: item?.value,
    };
  });

  return (
    <div className={classes.container} style={assessmentContainerStyle}>
      <div className={classes.assessmentContainer}>
        <CriteriaRubricAssessment
          typeOfScale={typeOfScale}
          criteria={criteria}
          headerLabel={label}
          headerSubText={subText}
          ratedCriteria={ratedCriteria}
          onSelectMark={onSelectMark}
          isEditable={isRatingEditable}
          assessmentHeading={assessmentHeading}
          helpUID={"criteriaRubricAssessment"}
        />
      </div>
      {showSupervisorComment && (
        <SupervisorComment
          assessmentRemarks={assessmentRemarks}
          headingLabel={t(commentLabelLocale)}
          updateRemarks={updateRemarks}
          disabled={!isRatingEditable}
        />
      )}
    </div>
  );
};

CriteriaRubricAssessmentWithComments.defaultProps = {
  criteria: [],
  label: "",
  subText: "",
  ibPYPElementRatings: [],
  isRatingEditable: true,
  showSupervisorComment: true,
  typeOfScale: "evaluationScale",
  commentLabelLocale: "project:supervisor_s_comment",
  assessmentHeading: "",
};

export default CriteriaRubricAssessmentWithComments;
