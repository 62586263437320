import PropTypes from "prop-types";
import React from "react";
import { RadioButton as RADIO_BUTTON } from "@toddle-design/web";

class RadioButton extends React.PureComponent {
  render = () => {
    const {
      props: { isChecked, isDisabled, label, name },
    } = this;

    return (
      <RADIO_BUTTON disabled={isDisabled} checked={isChecked} name={name}>
        {label}
      </RADIO_BUTTON>
    );
  };
}

RadioButton.defaultProps = {
  isChecked: false,
  isDisabled: false,
  label: "",
};

RadioButton.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
};

export default RadioButton;
