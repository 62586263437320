import React from "react";
import classes from "./ResetPassword.scss";

import { InputTextField, UIButton } from "UIComponents";

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  onSaveClick = () => {
    const errorCount = this.isValid();
    if (errorCount > 0) {
      return;
    }
    const { sendResetPassword } = this.props;
    sendResetPassword();
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "loginForm" });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  confirmPasswordValidation = value => {
    const { data: { password } = {} } = this.props;

    if (!_.isEqual(password, value)) {
      return "Password and confirm password do not match";
    }
    return "";
  };

  passwordValidation = value => {
    if (value.length < 8) {
      return "Password should be atleast 8 characters in length";
    }
    return "";
  };

  onConfirmPasswordEnter = () => {
    this.onSaveClick();
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      data: { password, confirmPassword } = {},
      renderErrorComponent,
      customStyle: {
        containerStyle,
        headerStyle,
        subTextStyle,
        buttonStyle,
      } = {},
    } = this.props;
    return (
      <div className={classes.container} style={containerStyle}>
        <div style={headerStyle}> {`Reset Password`} </div>
        <div
          style={subTextStyle}
        >{`Enter a new password. Password should be atleast 8 characters in length.`}</div>
        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <div className={classes.inputContainer}>
              <InputTextField
                label={"New Password"}
                value={password}
                placeholder={"Enter new password"}
                name={"password"}
                updateInputField={updateInputField}
                type={"password"}
                {...inputStyle}
                ref={ref => (this.inputRefs["password"] = ref)}
                showErrorText={true}
                customValidation={this.passwordValidation}
                renderErrorComponent={renderErrorComponent}
                error={"Enter password"}
              />
            </div>
            <div className={classes.inputContainer}>
              <InputTextField
                label={"Confirm New Password"}
                value={confirmPassword}
                placeholder={"Re-enter new password"}
                name={"confirmPassword"}
                updateInputField={updateInputField}
                type={"password"}
                {...inputStyle}
                ref={ref => (this.inputRefs["confirmPassword"] = ref)}
                showErrorText={true}
                customValidation={this.confirmPasswordValidation}
                renderErrorComponent={renderErrorComponent}
                error={"Enter confirm password"}
                onEnter={this.onConfirmPasswordEnter}
              />
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <div />
            <UIButton
              type="filled"
              color="pink"
              onClick={this.onSaveClick}
              containerStyle={buttonStyle}
            >
              {"Save"}
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
