import React, { forwardRef } from "react";
import { getImage } from "../Utils";
import classNames from "classnames";
import classes from "./SearchItem.scss";
import { Avatar } from "@toddle-design/web";
import { makeBold } from "Utils";

export const SearchItem = forwardRef((props, ref) => {
  const {
    id,
    item,
    onSelectItem,
    searchText,
    uiState: { isSelected = false, index } = {},
    onFocusIn = () => {},
    onFocusOut = () => {},
  } = props;
  const { title, subTitle, type, imageUrl, metadata } = item;

  const imageByType = getImage({ type, metadata });
  return (
    <div
      data-elm="search-item"
      ref={el => (ref.current[index] = el)}
      className={classNames({
        [classes.container]: true,
        [classes.selected]: isSelected,
        "py-3 flex": true,
      })}
      role="button"
      onClick={() => {
        onSelectItem({ item });
      }}
      onMouseEnter={onFocusIn}
      onMouseLeave={onFocusOut}
    >
      <div className="mr-3 ml-10  w-8 flex justify-center items-center">
        {imageByType ? (
          <div className={classes.imageContainer}>{imageByType}</div>
        ) : (
          <Avatar
            size="small"
            shade="light"
            shape="square"
            name={title}
            src={imageUrl}
            className={classes.profileImageContainer}
          />
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div
          className={classNames({
            [classes.title]: true,
            "text-body-2": true,
          })}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: makeBold(title, searchText),
            }}
          ></div>
        </div>
        {subTitle ? (
          <div
            className={classNames({
              [classes.subTitle]: true,
              "text-body-3": true,
            })}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: makeBold(subTitle, searchText),
              }}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
});
SearchItem.displayName = "SearchItem";
