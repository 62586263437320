import React, { useState } from "react";
import classes from "./UnitSelectionFeed.scss";
import { UnitCard } from "AppComponents";
import { getCountString } from "Utils";
import { I18nHOC, UILabel, SearchBar } from "UIComponents";
import { goToRelativeRoute } from "modules/Services";
import { NoUnits, NoResultsFound } from "SvgComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import classNames from "classnames";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { EmptyState } from "@toddle-design/web";
import {
  NoDataIllustration,
  NoSearchInFilterIllustration,
} from "@toddle-design/theme";

const styleObj = {
  noUnitsTextStyle: {
    fontSize: "1.4rem",
    margin: "auto",
  },
  linkStyle: {
    color: "red",
    cursor: "pointer",
    display: "inline-block",
  },
};

const UnitSelectionFeed = ({
  title,
  items,
  updateSelectedItems,
  t,
  parentType,
  selectedItems,
  isMultiSelect,
  cardDimensions,
  styles,
  helperLabel,
  disabledItems,
  showEmptyState,
  selectedGrade,
  selectedCourseId,
  goToRelativeRoute,
  isSearchEnabled,
  handleUnitSearch,
  searchText,
  cardTheme,
  showGrades,
  showSubTitleTooltip,
  cardWrapperElement,
}) => {
  const checkIsDisabled = ({ id }) => {
    return _.findIndex(disabledItems, item => item == id) > -1;
  };

  let itemsLength = items.length;

  return (
    <div
      className={classes.container}
      style={_.get(styles, "feedParentContainer", {})}
    >
      <div
        className={classes.itemFeedContainer}
        style={styles.itemFeedContainer}
      >
        {_.map(items, item => {
          let detailsString = "";
          if (parentType == "SCHEDULER") {
            detailsString = t("common:weekCount_interval", {
              postProcess: "interval",
              count: _.get(item.duration, `week`, 0),
            });
          } else if (parentType == "ASSESSMENT_EVALUTATION") {
            detailsString = t("common:le_with_count", {
              count: _.get(item, `assessmentCount`, 0),
            });
          }
          let isSelected = false;
          if (isMultiSelect) {
            isSelected = _.includes(selectedItems, item.id);
          } else {
            isSelected = selectedItems == item.id;
          }
          const isDisabled = checkIsDisabled({ id: item.id });

          const additionalProps = cardWrapperElement
            ? { data: item, isSelected, onCardClick: updateSelectedItems }
            : {};

          return (
            <div className={classes.cardContainer} key={item.id}>
              <ConditionalWrapper
                type="wrapperAsElement"
                wrapper={cardWrapperElement}
                {...additionalProps}
                condition={!!cardWrapperElement}
              >
                <UnitCard
                  cardDimensions={cardDimensions}
                  item={item}
                  onItemClick={updateSelectedItems}
                  detailsString={detailsString}
                  isSelected={isSelected}
                  isDisabled={isDisabled}
                  theme={cardTheme}
                  showGrades={showGrades}
                  showSubTitleTooltip={showSubTitleTooltip}
                  hideDefaultActions={!!cardWrapperElement}
                  t={t}
                />
              </ConditionalWrapper>
            </div>
          );
        })}
        {!itemsLength && showEmptyState ? (
          <div className={classes.emptyStateContainer}>
            {isSearchEnabled && searchText ? (
              <EmptyState
                illustration={NoSearchInFilterIllustration}
                title={t("common:no_label_found", {
                  label:
                    isSearchEnabled && searchText
                      ? t("common:result_plural")
                      : t("common:unit_plural").toLowerCase(),
                })}
                subtitle={
                  isSearchEnabled && searchText
                    ? t("calendar:no_unit_found_in_search", {
                        searchText,
                      })
                    : t("calendar:no_unit_found_in_grade", {
                        grade: selectedGrade,
                      })
                }
              />
            ) : (
              <EmptyState
                illustration={NoDataIllustration}
                title={t("unitPlan:no_unit_created_in_grade")}
              />
            )}
            <div className={classes.emptyStateSubTextContainer}>
              <div className={classes.emptyStateSubTextDesc}>
                {isSearchEnabled && searchText ? null : (
                  <div
                    className={classes.emptyStateSubTextLink}
                    onClick={() => {
                      goToRelativeRoute({
                        route: `../${selectedCourseId}/unitPlans`,
                      });
                    }}
                  >
                    {t("unitPlan:units_of_inquiry")}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapActionCreators = {
  goToRelativeRoute,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common", "unitPlan"] })
)(UnitSelectionFeed);

UnitSelectionFeed.defaultProps = {
  isSearchEnabled: false,
  showEmptyState: false,
  cardTheme: {},
};
