import client from "apolloClient";

import { courseFragment } from "./CourseFragments";
import {
  getGradeDetailsQuery,
  getCourseCollaboratorQuery,
  getCourseStudentsFoldersQuery,
  getCourseGenericFoldersQuery,
  getCourseDetailsQuery,
  getMultiCourseStudentsFoldersQuery,
  getCourseArchivedStudentsQuery,
  getOrganizationCoursesStudents,
  getCourseSidebarTabsContentCountQuery,
} from "./CourseQuery";

export const getGradesDetailsFromCache = grades => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getGradeDetailsQuery,
      variables: {
        ids: grades,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "multiNode", []);
  else return {};
};

export const getCourseStudentsFoldersFromCache = ({ id, folderType }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseStudentsFoldersQuery,
      variables: {
        id,
        folderType,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", []);
  else return {};
};

export const getMultiCourseStudentsFoldersFromCache = ({ ids, folderType }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getMultiCourseStudentsFoldersQuery,
      variables: {
        ids,
        folderType,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "multiNode", []);
  else return {};
};

export const getCourseDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", []);
  else return {};
};

export const getUniqlyCombinedGradesDetails = grades => {
  const gradeList = getGradesDetailsFromCache(grades);

  return _.reduce(
    gradeList,
    (result, grade) => {
      result["id"] = [...(result["id"] || []), grade.id];
      result["name"] = [...(result["name"] || []), grade.name];
      result["subjects"] = _.unionBy(
        result["subjects"],
        grade["subjects"],
        "id"
      );
      result["atlCategories"] = _.unionBy(
        result["atlCategories"],
        grade["atlCategories"],
        "id"
      );
      result["plannerTemplates"] = _.unionBy(
        result["plannerTemplates"],
        grade["plannerTemplates"],
        "id"
      );

      return result;
    },
    {}
  );
};

export const getCourseFeedDetailsFromCache = course_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Course:${course_id}`,
      fragment: courseFragment.courseFeed,
      fragmentName: "courseFeedItem",
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationCoursesStudentsFromCache = ({
  searchText,
  grades,
  organizationId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationCoursesStudents,
      variables: { searchText, grades, organizationId },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseCollaboratorsFromCache = course_id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseCollaboratorQuery,
      variables: {
        id: course_id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseGenericFoldersFromCache = ({
  courseId,
  folderType,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseGenericFoldersQuery,
      variables: {
        courseId,
        folderType,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeCourseGenericFoldersInCache = ({
  courseId,
  data,
  folderType,
}) => {
  try {
    client.writeFragment({
      id: `Course:${courseId}`,
      fragment: courseFragment.courseGenericFoldersDetailsFragment,
      fragmentName: "courseItem",
      variables: {
        folderType,
      },
      data,
    });
  } catch (e) {}
};

export const getCourseArchivedStudentsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseArchivedStudentsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseSidebarTabsContentCountFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseSidebarTabsContentCountQuery,
      variables: {
        id,
      },
    });
  } catch (error) {
    return {};
  }

  return queryData != null ? _.get(queryData, "node", {}) : {};
};
