import React from "react";
import classes from "./Header.scss";
import { I18nHOC } from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { ToddleIcon, ChevronIcon } from "SvgComponents";
import { SettingsOutlined } from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";
import ACLStore from "lib/aclStore";
import classNames from "classnames";

const Header = React.memo(props => {
  const {
    onClickBack,
    t,
    mode,
    createHeader,
    showImageTitleInHeader,
    assignmentDetails,
    currentStep,
    toggleShowTemplateConfigModal,
  } = props;

  const steps = [
    "QT_CREATION",
    "LE_CREATION",
    "SMT_CREATION",
    "FMT_CREATION",
    "ASSESSMENT_CREATION",
  ];

  const isAssessmentCreationRevamp = ACLStore.can(
    "FeatureFlag:AssessmentCreationRevamp"
  );

  // const showSettingsIcon =
  //   _.includes(steps, currentStep) && isAssessmentCreationRevamp ;

  const showSettingsIcon = false;

  const headerTextClass = classNames({
    [classes.title]: !showImageTitleInHeader,
    [classes.subTitle]: showImageTitleInHeader,
  });

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.logo} onClick={onClickBack}>
          <div className={classes.chevronIconContainer}>
            <ChevronIcon />
          </div>

          {showImageTitleInHeader ? (
            <div
              className={classes.assignmentImage}
              style={{
                backgroundImage: `url(${assignmentDetails.assignmentImage})`,
              }}
            ></div>
          ) : (
            <ToddleIcon width={40} height={40} />
          )}
        </div>

        <div className={classes.details}>
          {showImageTitleInHeader && (
            <div className={classes.title}>
              {assignmentDetails.assignmentTitle}
            </div>
          )}
          <div className={headerTextClass}>
            {mode == "create"
              ? createHeader
              : t(`common:edit_with_label`, {
                  label: t(`common:lowercase`, {
                    text: t(`common:classwork`),
                  }),
                })}
          </div>
        </div>
      </div>

      <div className={classes.rightContainer}>
        {showSettingsIcon && (
          <IconButton
            icon={<SettingsOutlined />}
            variant="outlined-subtle"
            size="large"
            onClick={toggleShowTemplateConfigModal}
          />
        )}
      </div>
    </div>
  );
});

Header.displayName = "Header";

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapActionCreators = {};

export default compose(
  I18nHOC({ resource: ["common", "teacherHomePage", "classRoom"] }),
  connect(mapStateToProps, mapActionCreators)
)(Header);
