import React, { forwardRef, useImperativeHandle } from "react";
import { RadialProgressBar, UIButton, I18nHOC } from "UIComponents";
import withRecorder from "../WithRecorder";
import { VoiceIcon, TickPlain, PauseIconV2 } from "SvgComponents";
import { compose } from "react-apollo";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";

/**
 * updateAudioRecorderBlob will be used to save recording blob in redux as draft
 */
import { updateAudioRecorderBlob } from "modules/Services";

import classes from "./AudioRecorder.scss";
import classNames from "classnames";

const AudioRecorder = forwardRef(
  (
    {
      t,
      recordButtonColor,
      styles: {
        control: controlStyles = {},
        buttonStyles = {},
        buttonsContainerStyles = {},
      } = {},
      timer,
      calculatedPercent,
      cancelRecording,
      startRecording,
      stopRecording,
      togglePause,
      playerState,
      isPaused,
      timeStamp,
    },
    forwardedRef
  ) => {
    // cancel recording
    useImperativeHandle(forwardedRef, () => ({
      cancelRecording: () => {
        cancelRecording();
      },
    }));

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.progressContainer}>
            <RadialProgressBar
              size={280}
              value={calculatedPercent}
              circleStroke={"#F0F0F3"}
              progressStroke={"#6464dc"}
              isArc={true}
              circleStrokeWidth={4}
            />
            <div className={classes.timer}>{timer}</div>
            {isPaused && (
              <div className={classes.pausedText}>{t("common:paused")}</div>
            )}
          </div>
        </div>
        <div className={classes.controls} style={{ ...(controlStyles || {}) }}>
          <div
            className={classes.buttonsContainer}
            style={{ ...buttonsContainerStyles }}
          >
            {playerState === "recording" && timeStamp > 0 ? (
              <>
                <UIButton
                  color={"blue"}
                  type={"filled"}
                  size={"lg"}
                  onClick={() => togglePause(!isPaused)}
                  containerStyle={{ ...buttonStyles }}
                >
                  <span className={classes.buttonTextWrapper}>
                    {isPaused ? (
                      <RecorderButton isPaused={isPaused} />
                    ) : (
                      <PauseIconV2 fill={"white"} width={12} height={12} />
                    )}
                    <span className={classes.buttonText}>
                      {isPaused ? t("common:resume") : t("common:pause")}
                    </span>
                  </span>
                </UIButton>
                <UIButton
                  color={"pink"}
                  type={"filled"}
                  size={"lg"}
                  onClick={stopRecording}
                  containerStyle={{ ...buttonStyles }}
                >
                  <span className={classes.buttonTextWrapper}>
                    <TickPlain
                      fillBackground={"transparent"}
                      width={12}
                      height={12}
                    />
                    <span className={classes.buttonText}>
                      {t("common:done")}
                    </span>
                  </span>
                </UIButton>
              </>
            ) : (
              <UIButton
                color={recordButtonColor}
                type={"filled"}
                size={"lg"}
                onClick={startRecording}
                containerStyle={{ width: "100%", ...buttonStyles }}
              >
                <span className={classes.buttonTextWrapper}>
                  <VoiceIcon />
                  <span className={classes.buttonText}>
                    {t("common:record")}
                  </span>
                </span>
              </UIButton>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const RecorderButton = ({ isPaused }) => {
  const recordingIconWhiteStyles = classNames({
    [classes.recordingIcon]: true,
    [classes.recordingIconWhite]: true,
    [classes.recordingAnimation]: isPaused,
  });
  return <div className={recordingIconWhiteStyles} />;
};

const mapActionCreators = { setToastMsg, updateAudioRecorderBlob };
const mapStateToProps = (state, ownProps) => {
  return {
    draftRecording: _.get(state, "app_services.draftRecording", null),
  };
};

const propTypes = {};
const defaultProps = {
  limit: 300000, // in milliseconds,
  recordButtonColor: "blue",
  saveDraftRecording: true,
};

AudioRecorder.propTypes = propTypes;
AudioRecorder.defaultProps = defaultProps;

const ComposedAudioRecorder = compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators, null, { withRef: true }),
  withRecorder
)(AudioRecorder);

ComposedAudioRecorder.propTypes = propTypes;
ComposedAudioRecorder.defaultProps = defaultProps;

export default ComposedAudioRecorder;
