import React from "react";
import classes from "./AttachmentLoaderMenu.scss";
import { AttachmentLoader } from "Attachment";
import { AnimateHeight } from "UIComponents";
import { ArrowIcon } from "SvgComponents";
import classNames from "classnames";
import { colors } from "Constants";
import { getCountString } from "Utils";
import { ChevronDownOutlined } from "@toddle-design/web-icons";

class AttachmentLoaderMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isEnable: true };
  }

  toggleIsEnable = () => {
    this.setState({ isEnable: !this.state.isEnable });
  };
  render() {
    const { progressOfUploads, attachmentLoaderMenuStyles } = this.props;
    const uploadingIds = Object.keys(progressOfUploads);
    const uploadingIdsLength = _.get(uploadingIds, "length", 0);
    const { isEnable } = this.state;
    const arrowStyle = classNames(
      { [classes.arrowContainer]: true },
      { [classes.openArrow]: isEnable },
      { [classes.closeArrow]: !isEnable }
    );
    const fileString = getCountString({
      count: uploadingIdsLength,
      singularText: "File",
      pluralText: "Files",
      shouldShowZero: true,
    });

    return uploadingIdsLength > 0 ? (
      <div className={classes.container} style={attachmentLoaderMenuStyles}>
        <div className={classes.headerContainer} onClick={this.toggleIsEnable}>
          <div className={classes.headerText}>{`Processing ${fileString}`}</div>
          <ChevronDownOutlined
            variant="subtle"
            size="xxx-small"
            className={arrowStyle}
          ></ChevronDownOutlined>
        </div>
        <AnimateHeight duration={300} height={!isEnable ? 0 : "auto"}>
          <div className={classes.scrollContainer}>
            {_.map(uploadingIds, key => {
              return (
                <AttachmentLoader item={progressOfUploads[key]} key={key} />
              );
            })}
          </div>
        </AnimateHeight>
      </div>
    ) : null;
  }
}

export default AttachmentLoaderMenu;
