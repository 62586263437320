import PropTypes from "prop-types";
import React from "react";

const DpCycle = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H104V104H0V0Z" fill="#FFEDC1" />
      <g filter="url(#filter0_d_7740_8059)">
        <path
          d="M34 39.6667C34 38.1939 35.1939 37 36.6667 37H67.3333C68.8061 37 70 38.1939 70 39.6667V70.3333C70 71.8061 68.8061 73 67.3333 73H36.6667C35.1939 73 34 71.8061 34 70.3333V39.6667Z"
          fill="white"
        />
        <circle
          cx="52"
          cy="56"
          r="8"
          transform="rotate(-45 52 56)"
          stroke="#FFAB00"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 6"
        />
        <path
          d="M59.0005 47L54.0005 52H59.0005V47Z"
          fill="#FFAB00"
          stroke="#FFAB00"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="46.3431"
          cy="61.6569"
          r="2"
          transform="rotate(-45 46.3431 61.6569)"
          fill="white"
          stroke="#FFAB00"
          strokeWidth="1.5"
        />
        <g filter="url(#filter1_d_7740_8059)">
          <path
            d="M45.334 37C45.334 36.2636 45.9309 35.6666 46.6673 35.6666H57.334C58.0704 35.6666 58.6673 36.2636 58.6673 37V39.6666H45.334V37Z"
            fill="#FFAB00"
          />
        </g>
        <path
          d="M43 33.3333C43 32.597 43.597 32 44.3333 32H59.6667C60.403 32 61 32.597 61 33.3333V40H43V33.3333Z"
          fill="#FFAB00"
        />
        <path
          d="M40 40L64 40"
          stroke="#FFAB00"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51 36C51 35.4477 51.4477 35 52 35C52.5523 35 53 35.4477 53 36C53 36.5523 52.5523 37 52 37C51.4477 37 51 36.5523 51 36Z"
          fill="#C28200"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7740_8059"
          x="18"
          y="32"
          width="52"
          height="57"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-8" dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.521569 0 0 0 0 0.34902 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7740_8059"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7740_8059"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7740_8059"
          x="42.6673"
          y="33"
          width="18.6666"
          height="9.33333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.33333" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7740_8059"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7740_8059"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

DpCycle.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

DpCycle.defaultProps = {
  height: 104,
  width: 104,
};

export default DpCycle;
