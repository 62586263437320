import React from "react";
import PropTypes from "prop-types";

const NotificationBell = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 100 100"
      fill={props.fill}
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M60.4899,83.3012 C62.1799,83.3012 63.5509,84.6712 63.5509,86.3612 C63.5509,93.8692 57.4419,99.9772 49.9339,99.9772 C42.4259,99.9772 36.3179,93.8692 36.3179,86.3612 C36.3179,84.6712 37.6879,83.3012 39.3789,83.3012 C41.0689,83.3012 42.4389,84.6712 42.4389,86.3612 C42.4389,90.4942 45.8019,93.8572 49.9339,93.8572 C54.0669,93.8572 57.4289,90.4942 57.4289,86.3612 C57.4289,84.6712 58.7999,83.3012 60.4899,83.3012 Z M72.0895,73.2758 C75.6885,73.2758 79.0225,71.7108 81.0065,69.0888 C82.6215,66.9428 83.1425,64.2778 82.4325,61.7848 C82.3215,61.3958 72.0515,25.3688 70.5635,20.1578 C70.4805,20.0158 70.4085,19.8658 70.3495,19.7098 L70.0535,18.9318 C67.1435,11.2688 59.0545,6.1208 49.9245,6.1208 C40.8465,6.1208 32.8695,11.0448 30.0315,18.3858 L17.4345,61.7948 C16.7255,64.2778 17.2435,66.9398 18.8605,69.0868 C20.8355,71.7098 24.1655,73.2758 27.7695,73.2758 L72.0895,73.2758 Z M76.1885,17.6028 C76.2485,17.7628 76.2485,17.7628 88.3185,60.1068 C89.5595,64.4608 88.6755,69.0788 85.8925,72.7758 C82.7525,76.9238 77.5935,79.3968 72.0895,79.3968 L27.7695,79.3968 C22.2535,79.3968 17.0955,76.9188 13.9715,72.7688 C11.1895,69.0758 10.3075,64.4578 11.5525,60.1008 L24.1885,16.5578 C24.2115,16.4808 24.2365,16.4028 24.2655,16.3278 C27.9615,6.5618 38.2735,-0.0002 49.9245,-0.0002 C61.5815,-0.0002 71.9695,6.7338 75.7755,16.7578 L75.8475,16.9478 C75.9865,17.1508 76.1015,17.3698 76.1885,17.6028 Z"
          id="Combined-Shape"
        />
      </g>
    </svg>
  );
};

NotificationBell.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

NotificationBell.defaultProps = {
  height: 20,
  width: 20,
};

export default NotificationBell;
