import gql from "graphql-tag";
import { attachmentFragment } from "modules/CommonFragments";

export const getProjectReportTemplateDetailsQuery = gql`
  query getProjectReportTemplateDetails($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        selectedProjectReportTemplate {
          ...attachmentItem
          presetAttachment {
            ...attachmentItem
          }
        }
        allProjectReportTemplates {
          ...attachmentItem
          presetAttachment {
            ...attachmentItem
          }
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;
