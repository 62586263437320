import React from "react";

const CameraIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 21">
      <path
        d="M6.89345 1.7615C7.31769 0.660963 8.41515 0 9.5077 0H14.4C15.6111 0 16.5997 0.684671 17.0143 1.76161L17.934 4.026L17.9412 4.04442C18.0737 4.39234 18.4498 4.63637 18.8308 4.63637H21.2308C22.756 4.63637 24 5.86156 24 7.36364V18.2727C24 19.7748 22.756 21 21.2308 21H2.76923C1.24404 21 0 19.7748 0 18.2727V7.36364C0 5.86156 1.24404 4.63637 2.76923 4.63637H5.07692C5.5236 4.63637 5.82489 4.41624 5.96647 4.04442L5.97372 4.026L6.89345 1.7615ZM9.5077 1.81818C9.12669 1.81818 8.75062 2.0622 8.61815 2.4101L8.6109 2.42853L7.69121 4.69291C7.27664 5.76983 6.28801 6.45455 5.07692 6.45455H2.76923C2.26365 6.45455 1.84615 6.86572 1.84615 7.36364V18.2727C1.84615 18.7707 2.26365 19.1818 2.76923 19.1818H21.2308C21.7364 19.1818 22.1538 18.7707 22.1538 18.2727V7.36364C22.1538 6.86572 21.7364 6.45455 21.2308 6.45455H18.8308C17.7382 6.45455 16.6408 5.79354 16.2165 4.69303L15.2968 2.42853L15.2895 2.4101C15.148 2.03831 14.8467 1.81818 14.4 1.81818H9.5077Z"
        fill={props.fill}
      />
      <path
        d="M11.9999 8.27277C9.96068 8.27277 8.30758 9.90083 8.30758 11.9091C8.30758 13.9174 9.96068 15.5455 11.9999 15.5455C14.0391 15.5455 15.6922 13.9174 15.6922 11.9091C15.6922 9.90083 14.0391 8.27277 11.9999 8.27277ZM6.46143 11.9091C6.46143 8.89667 8.94108 6.45459 11.9999 6.45459C15.0587 6.45459 17.5383 8.89667 17.5383 11.9091C17.5383 14.9216 15.0587 17.3637 11.9999 17.3637C8.94108 17.3637 6.46143 14.9216 6.46143 11.9091Z"
        fill={props.fill}
      />
    </svg>
  );
};

CameraIcon.defaultProps = {
  height: 24,
  width: 21,
};

export default CameraIcon;
