import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./AddStudentsModal.scss";
import { I18nHOC } from "UIComponents";
import StudentSelection from "../StudentSelection";
import { Button, IconButton, SearchInput } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";

const AddStudentsModal = React.memo(
  ({
    students,
    onClickCancel,
    onClickAdd,
    courseId,
    disableUpdateOnEmpty,
    t,
    portalType,
    mode,
    assignmentId,
    assignmentTags,
  }) => {
    const [studentsLocally, changeStudentsLocally] = useState([]);
    const [searchTerm, changeSearchTerm] = useState("");
    useEffect(() => {
      changeStudentsLocally(students);
    }, [students]);
    const onClickAddStudents = () => {
      onClickAdd(studentsLocally);
      onClickCancel();
    };
    const disableUpdate =
      (disableUpdateOnEmpty && _.isEmpty(studentsLocally)) ||
      _.isEqual(studentsLocally, students);

    return (
      <div className={classes.container}>
        <div className={classes.headerCon}>
          <div className={classes.addStudentsLabel}>
            {t("common:select_students")}
          </div>
          <IconButton
            variant={"on"}
            size={"medium"}
            icon={<CloseOutlined variant={"subtle"} />}
            onClick={onClickCancel}
          />
        </div>
        <div className={classes.modalBody}>
          <div className={classes.searchContainer}>
            <SearchInput
              placeholder={`${t("common:search")}..`}
              value={searchTerm}
              onChange={changeSearchTerm}
            />
          </div>
          <StudentSelection
            selectedItemType={`OBJECT`}
            includedStudents={studentsLocally}
            updateStudentArray={changeStudentsLocally}
            parentType={`Course`}
            parentId={courseId}
            searchTerm={searchTerm}
            portalType={portalType}
            mode={mode}
            assignmentId={assignmentId}
            assignmentTags={assignmentTags}
          />
        </div>
        <div className={classes.footerCon}>
          <div className={classes.studentSelectedLabel}>
            {t("common:count_selected", {
              count: _.get(studentsLocally, "length", 0),
            })}
          </div>
          <div className={classes.footerBtnCon}>
            <React.Fragment>
              <Button
                size={"medium"}
                variant={"outlined"}
                style={{ marginRight: "16px" }}
                onClick={onClickCancel}
              >
                {t("common:cancel")}
              </Button>
              <Button
                size={"medium"}
                variant={"primary"}
                onClick={onClickAddStudents}
                disabled={disableUpdate}
              >
                {t("common:select")}
              </Button>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
);

AddStudentsModal.displayName = "AddStudentsModal";

AddStudentsModal.propTypes = {
  disableUpdateOnEmpty: PropTypes.bool,
};

AddStudentsModal.defaultProps = {
  disableUpdateOnEmpty: false,
};

export default I18nHOC({ resource: ["common"] })(AddStudentsModal);
