import {
  CURRICULUM_TYPE_PYP,
  ELEMENT_TYPE_MYP_ATL,
  ELEMENT_TYPE_MYP_KEY_CONCEPT,
  ELEMENT_TYPE_MYP_LEARNER_PROFILE,
  ELEMENT_TYPE_MYP_OBJECTIVES,
  ELEMENT_TYPE_UBD_ATL,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
  ELEMENT_TYPE_MYP_LEARNING_STANDARD,
  ELEMENT_TYPE_UBD_LEARNING_STANDARD,
  ELEMENT_TYPE_MYP_SUBJECT,
  ELEMENT_TYPE_UBD_SUBJECT,
} from "Constants/stringConstants";
import { getSubjectDetailFromCache } from "./GraphqlHelpers";
import { getMemoizedFunction, getYearDurationFormat } from "Utils";
import { tabFiltersConfig, filterLabelConfig } from "./Config";

const CURRICULUM_TYPE_TO_SUBJECT_TYPE_MAPPING = {
  [CURRICULUM_TYPE_PYP]: "SUBJECT",
  [CURRICULUM_TYPE_MYP]: ELEMENT_TYPE_MYP_SUBJECT,
  [CURRICULUM_TYPE_UBD]: ELEMENT_TYPE_UBD_SUBJECT,
};

const CURRICULUM_TYPE_TO_LEARNING_STANDARD_MAPPING = {
  [CURRICULUM_TYPE_MYP]: ELEMENT_TYPE_MYP_LEARNING_STANDARD,
  [CURRICULUM_TYPE_UBD]: ELEMENT_TYPE_UBD_LEARNING_STANDARD,
};

export const getMoreFiltersSelectionStatus = ({ appliedFilters }) => {
  const tabs = _.keys(appliedFilters);

  const initialMoreSelectedFilters = _.reduce(
    tabs,
    (acc, tab) => {
      const selectedFilters = _.keys(_.get(appliedFilters, tab, {}));
      const entityTypeInfo = tabFiltersConfig[tab].entityTypeInfo;

      const shouldShowMoreFilters = !!_.size(
        _.filter(
          selectedFilters,
          filter => entityTypeInfo[filter].tabDisplayOrder > 1
        )
      );

      return {
        ...acc,
        [tab]: shouldShowMoreFilters,
      };
    },
    {}
  );
  return initialMoreSelectedFilters;
};

export const getUpdatedModuleFacets = getMemoizedFunction(
  ({ data, curriculumType, activeTab }) => {
    const moduleFacets = _.get(data, "globalConstants.moduleFacets", []);

    const updatedModuleFacets = _.map(
      moduleFacets,
      ({ entityType, ...rest }) => {
        const attributes = _.get(
          filterLabelConfig,
          `${entityType}.attributes`,
          []
        );

        if (_.isEmpty(attributes)) {
          return { ...rest, entityType };
        }
        return { ...rest, entityType, attributes };
      }
    );

    const availableFilters = _.get(
      tabFiltersConfig,
      `${activeTab}.curriculumWiseAvailableFilters.${curriculumType}`,
      []
    );

    return _.filter(updatedModuleFacets, ({ entityType }) => {
      return _.includes(availableFilters, entityType);
    });
  }
);

const getSelectedObjectiveIds = ({ selectedObjectives }) => {
  const objectiveIds = [];

  _.forEach(selectedObjectives, ({ key, values = [] }) => {
    if (key == "INTERDISCIPLINARY_CRITERIA") {
      objectiveIds.push(...values);
    } else {
      /**
       * For objectives of subject, we need to go one
       * level deep(subject->yearly objectives)
       */
      const subGroups = values;
      _.forEach(subGroups, ({ values }) => {
        objectiveIds.push(...values);
      });
    }
  });

  return objectiveIds;
};

const getFilterPropFromState = ({
  activeTab,
  curriculumType,
  appliedFilters,
}) => {
  const filterProp = {};

  switch (activeTab) {
    case "UNIT_EVIDENCE":
    case "PROGRESS_REPORT_POST": {
      const courseOptions = appliedFilters.COURSE || {};

      const selectedCourses = _.flatMap(courseOptions, ({ values }) => values);
      if (!_.isEmpty(selectedCourses)) {
        filterProp.courseIds = _.uniq(selectedCourses);
      }

      const selectedUnits = appliedFilters.UNIT_PLAN || [];

      if (!_.isEmpty(selectedUnits)) {
        filterProp.unitPlanIds = selectedUnits;
      }

      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];

      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYears = selectedAcademicYears;
      }

      const plannerElements = [
        { entityType: "ATL", plannerElementType: "ATL" },
        { entityType: "CONCEPT", plannerElementType: "CONCEPT" },
        {
          entityType: ELEMENT_TYPE_MYP_KEY_CONCEPT,
          plannerElementType: ELEMENT_TYPE_MYP_KEY_CONCEPT,
        },
        {
          entityType: "LEARNER_PROFILE",
          plannerElementType: "LEARNER_PROFILE",
        },
        {
          entityType: ELEMENT_TYPE_MYP_LEARNER_PROFILE,
          plannerElementType: ELEMENT_TYPE_MYP_LEARNER_PROFILE,
        },
        {
          entityType: ELEMENT_TYPE_MYP_ATL,
          plannerElementType: ELEMENT_TYPE_MYP_ATL,
        },
        {
          entityType: ELEMENT_TYPE_UBD_ATL,
          plannerElementType: ELEMENT_TYPE_UBD_ATL,
        },
        {
          entityType: "SUBJECT",
          plannerElementType:
            CURRICULUM_TYPE_TO_SUBJECT_TYPE_MAPPING[curriculumType],
        },
      ];

      let pypElements = _.reduce(
        plannerElements,
        (result, { entityType, plannerElementType }) => {
          const selectedItems = _.map(appliedFilters[entityType] || [], id => ({
            type: plannerElementType,
            id,
          }));

          return [...result, ...selectedItems];
        },
        []
      );

      let selectedMypObjectives = getSelectedObjectiveIds({
        selectedObjectives: appliedFilters[ELEMENT_TYPE_MYP_OBJECTIVES],
      });

      selectedMypObjectives = _.map(selectedMypObjectives, id => ({
        id,
        type: ELEMENT_TYPE_MYP_OBJECTIVES,
      }));

      pypElements = [...pypElements, ...selectedMypObjectives];

      if (!_.isEmpty(pypElements)) {
        filterProp.pypElements = pypElements;
      }

      return filterProp;
    }
    case "UNIT_PLANS": {
      const selectedGrades = appliedFilters.GRADE || [];
      if (!_.isEmpty(selectedGrades)) {
        filterProp.grades = selectedGrades;
      }

      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];
      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYears = selectedAcademicYears;
      }

      const unitPlanTypes = appliedFilters.UNIT_PLAN_TYPE || [];
      if (_.size(unitPlanTypes) === 1) {
        filterProp.type = unitPlanTypes[0];
      }

      const selectedThemes = appliedFilters.UNIT_PLAN_THEME || [];
      if (!_.isEmpty(selectedThemes)) {
        filterProp.themes = selectedThemes;
      }

      const selectedSubjects = appliedFilters.SUBJECT || [];
      if (!_.isEmpty(selectedSubjects)) {
        filterProp.subjects = selectedSubjects;
      }

      return filterProp;
    }
    case "LEARNING_EXPERIENCES": {
      const selectedGrades = appliedFilters.GRADE || [];
      if (!_.isEmpty(selectedGrades)) {
        filterProp.grades = selectedGrades;
      }

      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];

      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYears = selectedAcademicYears;
      }

      const selectedUnits = appliedFilters.UNIT_PLAN || [];
      if (!_.isEmpty(selectedUnits)) {
        filterProp.units = selectedUnits;
      }

      return filterProp;
    }

    case "PROGRESS_REPORTS": {
      const selectedGrades = appliedFilters.GRADE || [];
      if (!_.isEmpty(selectedGrades)) {
        filterProp.gradeIds = selectedGrades;
      }

      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];
      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYearIds = selectedAcademicYears;
      }

      const selectedProgressReportTaskGroups =
        appliedFilters.PROGRESS_REPORT_TASK_GROUP || [];
      if (!_.isEmpty(selectedProgressReportTaskGroups)) {
        filterProp.progressReportTaskGroupIds = selectedProgressReportTaskGroups;
      }
      return filterProp;
    }

    case "CLASSROOM": {
      const courseOptions = appliedFilters.COURSE || {};

      const selectedCourses = _.flatMap(courseOptions, ({ values }) => values);
      if (!_.isEmpty(selectedCourses)) {
        filterProp.courseIds = selectedCourses;
      }

      const selectedFolderOptions = appliedFilters.FOLDER || [];
      if (!_.isEmpty(selectedFolderOptions)) {
        filterProp.folderIds = selectedFolderOptions;
      }

      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];
      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYearIds = selectedAcademicYears;
      }

      const selectedActivityTypes = appliedFilters.ACTIVITY_TYPE || [];
      if (!_.isEmpty(selectedActivityTypes)) {
        filterProp.assessmentTypes = selectedActivityTypes;
      } else {
        filterProp.contentTypes = ["ASSESSMENT", "ASSIGNMENT_RESOURCE"];
      }

      return filterProp;
    }

    case "MYP_PLANNING_INSIGHTS": {
      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];
      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYearId = selectedAcademicYears[0];
      }

      const selectedSubjectGroups = appliedFilters.SUBJECT_GROUP || [];
      if (!_.isEmpty(selectedSubjectGroups)) {
        filterProp.subjectGroupId = selectedSubjectGroups[0];
      }

      return filterProp;
    }

    case "POI_INSIGHTS": {
      const selectedAcademicYears = appliedFilters.ACADEMIC_YEAR || [];
      if (!_.isEmpty(selectedAcademicYears)) {
        filterProp.academicYearId = selectedAcademicYears[0];
      }

      return filterProp;
    }
    default: {
      return {};
    }
  }
};

export const getFilterPropFromStateMemoized = getMemoizedFunction(
  getFilterPropFromState
);

export const getFilterOptionParam = ({
  entityType,
  curriculumProgramId,
  parentFiltersData,
  curriculumType,
}) => {
  switch (entityType) {
    case "ATL": {
      return {
        filters: { depth: 1, curriculumProgramIds: [curriculumProgramId] },
      };
    }

    case "SUBJECT": {
      if (curriculumType == CURRICULUM_TYPE_MYP) {
        const { values: courses = [] } = parentFiltersData?.COURSE ?? {};
        return {
          filters: {
            curriculumProgramIds: [curriculumProgramId],
            courseIds: courses,
          },
        };
      }
      return {
        filters: {
          curriculumProgramIds: [curriculumProgramId],
        },
      };
    }

    case "GRADE":
    case "ACTIVITY_TYPE":
    case "UNIT_PLAN_TYPE":
    case "UNIT_PLAN_THEME":
    case "LEARNER_PROFILE":
    case "CONCEPT": {
      return {
        filters: { curriculumProgramIds: [curriculumProgramId] },
      };
    }

    case "COURSE": {
      const options = {
        filters: { curriculumProgramIds: [curriculumProgramId] },
      };

      const { values: academicYears = [] } =
        parentFiltersData.ACADEMIC_YEAR ?? {};
      options.filters.academicYear = academicYears[0];

      return options;
    }

    case "UNIT_PLAN": {
      const options = {
        filters: { curriculumProgramIds: [curriculumProgramId] },
      };

      const { values: grades = [] } = parentFiltersData.GRADE ?? {};
      options.filters.grades = grades;

      const { values: courses = [] } = parentFiltersData?.COURSE ?? {};
      options.filters.courses = _.uniq(courses);

      const { values: academicYears = [] } =
        parentFiltersData?.ACADEMIC_YEAR ?? [];
      options.filters.academicYears = academicYears;

      return options;
    }

    case "FOLDER": {
      const options = {
        filters: { curriculumProgramIds: [curriculumProgramId] },
      };

      const { values: courses } = parentFiltersData?.COURSE ?? [];
      options.courseIds = courses;

      return options;
    }

    case "PROGRESS_REPORT_TASK_GROUP": {
      const options = {
        filters: {
          curriculumProgramIds: [curriculumProgramId],
        },
      };

      const { values: academicYears = [] } =
        parentFiltersData.ACADEMIC_YEAR ?? {};
      options.filters.academicYearId = academicYears[0];

      return options;
    }

    case ELEMENT_TYPE_MYP_ATL:
    case ELEMENT_TYPE_MYP_KEY_CONCEPT:
    case ELEMENT_TYPE_MYP_LEARNER_PROFILE:
    case ELEMENT_TYPE_MYP_OBJECTIVES:
    case ELEMENT_TYPE_UBD_ATL: {
      return {
        curriculumId: curriculumProgramId,
        filters: {
          elementTypes: [entityType],
        },
      };
    }
  }
};

export const getFilterParam = ({
  entityType,
  parentFiltersData,
  entitySubType,
}) => {
  switch (entityType) {
    case ELEMENT_TYPE_MYP_ATL:
    case ELEMENT_TYPE_UBD_ATL: {
      return { startDepth: 1, upToDepth: 1 };
    }
    case ELEMENT_TYPE_MYP_KEY_CONCEPT:
    case ELEMENT_TYPE_MYP_LEARNER_PROFILE: {
      return { startDepth: 0, upToDepth: 0 };
    }
    case ELEMENT_TYPE_MYP_OBJECTIVES: {
      if (entitySubType == "INTERDISCIPLINARY_CRITERIA") {
        return {
          subTypes: ["INTERDISCIPLINARY_CRITERIA"],
          startDepth: 1,
          upToDepth: 2,
        };
      }

      const { subjects = [] } = parentFiltersData.COURSE ?? {};

      return {
        associatedParents: [{ ids: subjects, type: "SUBJECT" }],
        startDepth: 1,
        upToDepth: 2,
      };
    }
  }
};

const getLabel = ({ key, values }) => {
  switch (key) {
    case "ACADEMIC_YEAR": {
      const [startDate, endDate] = values;
      return getYearDurationFormat({ startDate, endDate });
    }
    default: {
      return _.join(values, " ");
    }
  }
};

/**
 *
 * @param {*} param0
 * @param {Array} param0.attributes - Array of values. Each value specifies
 *                                          property of mainFacets/nestedFacets to extract label
 *
 */
export const getOptions = ({ mainFacets, attributes, key }) => {
  return _.map(mainFacets, option => {
    const id = _.get(option, "id", "");
    const labels = _.map(attributes, attribute => _.get(option, attribute, ""));
    const label = getLabel({ key, values: labels });

    switch (key) {
      case "UNIT_PLAN_THEME": {
        return { label, value: option.uid };
      }

      default: {
        return { label, value: id };
      }
    }
  });
};

export const getFilterListOptionsV2 = ({ nestedFacets, attributes, key }) => {
  return _.map(nestedFacets, nestedFacet => {
    const nestedFacetId = _.get(nestedFacet, "id", "");
    const nestedFacetLabel = _.get(nestedFacet, "label", "");
    const mainFacets = _.get(nestedFacet, "mainFacets", []);
    return {
      key: nestedFacetId,
      label: nestedFacetLabel,
      options: getOptions({ mainFacets, attributes, key }),
    };
  });
};

/**It is expected that all nodes belongs to one subject */
const getMypObjectives = ({ nodes }) => {
  const parentIdToChildNodesMapping = {};

  let options = [];

  /**This loop will do two things
   * a. separate root nodes and store it in options array
   * b. create mapping between parent and all its children
   */
  _.forEach(nodes, ({ id, parent, label }) => {
    if (parent) {
      if (parentIdToChildNodesMapping[parent]) {
        parentIdToChildNodesMapping[parent].push({ label, value: id });
      } else {
        parentIdToChildNodesMapping[parent] = [{ label, value: id }];
      }
    } else {
      options.push({ key: id, label });
    }
  });

  /**Here we are attaching children  with respective parent*/
  options = _.map(options, ({ key, label }) => {
    return {
      key,
      label,
      options: parentIdToChildNodesMapping[key],
      isLeafNode: true,
    };
  });

  return options;
};

export const getMypObjectivesGroupedBySubject = ({ nodes }) => {
  const groupedNodes = _.groupBy(nodes, ({ associatedParents }) => {
    return _.get(_.first(associatedParents), "id", "");
  });

  const subjects = _.filter(_.keys(groupedNodes), key => key != "");

  const options = [];

  _.forEach(subjects, subject => {
    const objectives = getMypObjectives({ nodes: groupedNodes[subject] });

    const subjectDetail = getSubjectDetailFromCache({ subjectId: subject });

    /**
     * isLeafNode is set to true if
     * either objectives are part of interdisciplinary objectives
     * or they belongs year wise objectives
     */
    options.push({
      key: subject,
      label: _.get(subjectDetail, "subjectName", ""),
      options: objectives,
      isLeafNode: false,
    });
  });

  const interDisciplinaryNodes = _.filter(
    nodes,
    ({ associatedParents }) => !associatedParents
  );

  const interDisciplinaryObjectives = _.map(
    interDisciplinaryNodes,
    ({ id, label }) => ({ value: id, label })
  );

  if (!_.isEmpty(interDisciplinaryObjectives)) {
    options.push({
      key: "INTERDISCIPLINARY_CRITERIA",
      label: "Interdisciplinary objectives",
      options: interDisciplinaryObjectives,
      isLeafNode: true,
    });
  }

  return options;
};

export const getFilterOptions = ({ levels, attributes, data, entityType }) => {
  let mainFacets = [];
  if (levels === 1) {
    switch (entityType) {
      case ELEMENT_TYPE_MYP_ATL:
      case ELEMENT_TYPE_MYP_KEY_CONCEPT:
      case ELEMENT_TYPE_MYP_LEARNER_PROFILE:
      case ELEMENT_TYPE_UBD_ATL: {
        mainFacets = _.get(data, "genericFacets.mainFacets[0].nodes", []);
        break;
      }
      default: {
        mainFacets = _.get(data, "genericFacets.mainFacets", []);
      }
    }

    return getOptions({ mainFacets, attributes, key: entityType });
  } else if (entityType === ELEMENT_TYPE_MYP_OBJECTIVES) {
    const nodes = _.get(data, "genericFacets.mainFacets[0].nodes", []);

    return getMypObjectivesGroupedBySubject({ nodes });
  } else {
    const nestedFacets = _.get(data, "genericFacets.nestedFacets", []);

    return getFilterListOptionsV2({
      nestedFacets,
      attributes,
      key: entityType,
    });
  }
};

export const getFilterDisabilityStatusConfig = ({
  parentFiltersData,
  config,
  totalOptions,
  isLoading,
}) => {
  if (isLoading) {
    return { isDisabled: true, stateCode: "LOADING" };
  }
  const totalParentFilters = _.size(config);

  for (let i = 0; i < totalParentFilters; i++) {
    const { parent: parentFilter, selectOnlyOneOptionInParentFilter } = config[
      i
    ];
    const { values, label } = parentFiltersData[parentFilter];

    if (selectOnlyOneOptionInParentFilter && _.size(values) !== 1) {
      return {
        isDisabled: true,
        stateCode: "PARENT_FILTER_MULTI_VALUE",
        parentFilterLabel: label,
      };
    }
  }

  const isDisabled = totalOptions === 0;

  if (isDisabled) {
    return { isDisabled, stateCode: "NO_OPTIONS" };
  }

  return { isDisabled: false };
};

export const getDependentFilters = ({ parent, activeTab, curriculumType }) => {
  const dependentFilterConfig = _.get(
    tabFiltersConfig,
    `${activeTab}.dependentFilterConfig`,
    []
  );

  const filters = _.get(
    tabFiltersConfig,
    `${activeTab}.curriculumWiseAvailableFilters.${curriculumType}`,
    []
  );

  /**Only consider those dependent Filters that are available for current curriculum */
  const filteredDependentFilterConfig = _.filter(
    dependentFilterConfig,
    ({ filter }) => _.includes(filters, filter)
  );

  const dependentFilters = [];

  _.forEach(filteredDependentFilterConfig, ({ filter, parents }) => {
    const isParentPresent =
      _.findIndex(
        parents,
        ({ parent: parentFilter, curriculumType: validCurriculum }) => {
          const isAllowedByCurriculum =
            !validCurriculum || curriculumType === validCurriculum;
          return parent === parentFilter && isAllowedByCurriculum;
        }
      ) > -1;

    if (isParentPresent) {
      dependentFilters.push(filter);
    }
  });

  return dependentFilters;
};

export const getValidMypObjectivesStatus = ({ optionList, valueList }) => {
  const updatedValues = [];

  let areValuesUpdated = false;

  _.forEach(valueList, ({ values, key, isLeafNode }) => {
    const { options } = _.find(
      optionList,
      ({ key: optionKey }) => key == optionKey
    );

    if (isLeafNode) {
      const filteredValues = _.filter(values, value =>
        _.findIndex(
          options,
          ({ value: optionValue }) => optionValue == value > -1
        )
      );

      if (_.size(values) != _.size(filteredValues)) {
        areValuesUpdated = true;
      }

      updatedValues.push({ key, values: filteredValues });
    } else {
      const {
        areValuesUpdated: areSubGroupValuesUpdated,
        updatedValues: updatedSubGroupValues,
      } = getValidMypObjectivesStatus({
        optionList: options,
        valueList: values,
      });

      areValuesUpdated = areValuesUpdated || areSubGroupValuesUpdated;

      if (!_.isEmpty(updatedSubGroupValues)) {
        updatedValues.push({ key, values: updatedSubGroupValues });
      }
    }
  });

  return { areValuesUpdated, updatedValues };
};

export const getValidFilterValuesStatus = ({ options, values, entityType }) => {
  switch (entityType) {
    case "COURSE": {
      const valueList = values;
      const optionList = options;
      const updatedValues = [];

      let areValuesUpdated = false;
      _.forEach(valueList, ({ key, values }) => {
        const options = _.find(optionList, ["key", key])?.options ?? [];

        const filteredValues = _.filter(values, value => {
          return _.findIndex(options, ["value", value]) > -1;
        });

        if (_.size(values) !== _.size(filteredValues)) {
          areValuesUpdated = true;
        }

        updatedValues.push({ key, values: filteredValues });
      });

      return { areValuesUpdated, updatedValues };
    }

    case ELEMENT_TYPE_MYP_OBJECTIVES: {
      return getValidMypObjectivesStatus({
        optionList: options,
        valueList: values,
      });
    }

    default: {
      let updatedValues = _.filter(options, ({ value }) => {
        return _.includes(values, value);
      });
      updatedValues = _.map(updatedValues, ({ value }) => value);
      const areValuesUpdated = _.size(updatedValues) !== _.size(values);

      return { areValuesUpdated, updatedValues };
    }
  }
};

/**If filter is part of set(ex. planner element set), then extract label from planner element set
 * else use filterLocale
 */
export const getFilterLabel = ({
  plannerElementSets,
  isLabelPresentInSetLabels,
  filterLocale,
  entityType,
  curriculumType,
  localeParams,
  t,
}) => {
  switch (entityType) {
    case "SUBJECT": {
      /**
       * Use dynamic subject label only for UBD
       */
      if (isLabelPresentInSetLabels && curriculumType == CURRICULUM_TYPE_UBD) {
        return plannerElementSets[
          CURRICULUM_TYPE_TO_LEARNING_STANDARD_MAPPING[curriculumType]
        ]?.label;
      }

      if (curriculumType == CURRICULUM_TYPE_MYP) {
        return t("common:subject_plural");
      }

      return t(filterLocale);
    }
    default: {
      if (isLabelPresentInSetLabels) {
        return plannerElementSets[entityType]?.label;
      }
      return t(filterLocale, localeParams);
    }
  }
};
