import client from "apolloClient";
import { getStudentProgressReportFeedQuery } from "./ParentProgressReportQueries";

export const getStudentProgressReportFeedFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportFeedQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};
