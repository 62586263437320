import React, { useState } from "react";
import classes from "./AttachmentsContainer.scss";
import JournalPreviewCard from "AppComponents/Attachment/JournalPreviewCard";
import { MediaCard } from "@toddle-design/web";
import _ from "lodash";
import { MediaModal, TextMoreLess } from "UIComponents";

export const AttachmentsContainer = props => {
  const MediaModalWrapper = ({
    attachments,
    toggleMediaModal,
    showMediaModal,
  }) => {
    return showMediaModal ? (
      <div>
        <MediaModal
          toggleMediaModal={toggleMediaModal}
          attachments={attachments}
          activeIndex={0}
          onWorkbookUpdateSuccessfully={() => toggleMediaModal(false)}
        />
      </div>
    ) : null;
  };

  const [showMediaModal, setShowMediaModal] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState({});

  const onClickMedia = ({ event, attachmentData } = {}) => {
    if (event && event.stopPropagation) event.stopPropagation();
    setShowMediaModal(true);
    setCurrentAttachment(attachmentData);
  };

  const responseCaption = _.get(props, "responseCaption", "");
  return (
    <React.Fragment>
      <MediaModalWrapper
        attachments={[currentAttachment]}
        toggleMediaModal={() => {
          setShowMediaModal(!showMediaModal);
        }}
        showMediaModal={showMediaModal}
      />
      {_.get(props, "isSidePaneContent", false) ? (
        <div className={classes.smallAttachmentsContainer}>
          {_.get(responseCaption, "length", 0) > 0 && (
            <div className={classes.response}>
              <TextMoreLess value={responseCaption} minHeight={48} />
            </div>
          )}
          {_.map(_.get(props, "attachments", []), attachment => {
            const attachmentData = _.get(attachment, "attachments[0]", {});
            const attachmentType = _.get(attachmentData, "type", "FILE");
            const name = _.get(attachmentData, "name", "untitled");
            const mimeType = _.get(attachmentData, "mimeType", "");
            const thumbUrl =
              attachmentType == "IMAGE"
                ? _.get(attachmentData, "url", "")
                : _.get(attachmentData, "thumbUrl", "");
            const url = _.get(attachmentData, "url", "");
            return (
              <MediaCard
                key={_.get(attachment, "id", "")}
                cardType="medium"
                name={name}
                type={attachmentType}
                mimeType={mimeType}
                thumbUrl={thumbUrl}
                url={url}
                showSubtext={true}
                onClick={event => {
                  onClickMedia({ event, attachmentData });
                }}
              />
            );
          })}
          {(_.get(props, "hasAssesmentTool", false) ||
            _.get(props, "hasLearningGoals", false)) && (
            <div className={classes.studentEvaluationLabel}>
              Student evaluation
            </div>
          )}
          {_.get(props, "hasAssesmentTool", false) && (
            <div>
              <MediaCard
                cardType="medium"
                name={_.get(props, "evaluationContent.name", "")}
                type={_.replace(
                  _.get(props, "evaluationContent.assessmentToolType", ""),
                  /_/g,
                  " "
                )}
                showSubtext={true}
                onClick={e => {
                  props.setShowAssessmentTool(true);
                }}
              />
            </div>
          )}
          {_.get(props, "hasLearningGoals", false) && (
            <div>
              <MediaCard
                cardType="medium"
                type={"LEARNING GOALS"}
                name={"Rate on learning goals"}
                showSubtext={true}
                onClick={e => {
                  if (_.has(props, "setShowRateLearningGoalsModal"))
                    props.setShowRateLearningGoalsModal(true);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className={classes.attachmentsContainer}
          style={_.get(props, "attachmentsContainerStyle", {})}
        >
          {_.map(_.get(props, "attachments", []), attachment => {
            return (
              <JournalPreviewCard
                key={_.get(attachment, "id", "")}
                attachment={attachment}
                itemStyles={_.get(props, "itemStyles", {})}
                cardStyle={_.get(props, "cardStyle", {})}
                containerStyle={_.get(props, "containerStyle", {})}
                isStudentResponse={_.get(props, "isStudentResponse", false)}
              />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};
