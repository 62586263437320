import PropTypes from "prop-types";
import React from "react";

const HowTheWorldWorks = props => {
  const cls1 = { fill: "#dbdbdb" };
  const cls2 = { fill: "#fff" };
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 250 250">
      <title> where we are place and time active copy</title>
      <circle style={cls1} cx="119.22" cy="124.71" r="74.24" />
      <circle style={cls2} cx="179.68" cy="164.87" r="24.67" />
      <path
        style={cls2}
        d="M179.68,140.19A24.67,24.67,0,1,1,155,164.87a24.67,24.67,0,0,1,24.67-24.67m0-3a27.68,27.68,0,1,0,27.68,27.68,27.71,27.71,0,0,0-27.68-27.68Z"
      />
      <path
        style={cls2}
        d="M93.32,107.11l-50.8,8.76a3.83,3.83,0,0,0-2.1,7l25.14,17.34Z"
      />
      <path
        style={cls2}
        d="M93.32,107.11,65.56,140.19,40.41,122.85a3.83,3.83,0,0,1,2.1-7l50.8-8.76m7.58-4.36-8.09,1.39-50.6,8.73a6.84,6.84,0,0,0-3.5,12.45l25.14,17.34,2.25,1.55,1.76-2.09L95.62,109l5.28-6.29Z"
      />
      <path
        style={cls2}
        d="M133,140.39l.2,51.55a3.83,3.83,0,0,1-6.51,3.28l-21.45-21.75Z"
      />
      <path
        style={cls2}
        d="M133,140.39l.2,51.55a3.83,3.83,0,0,1-3.82,4.44,3.73,3.73,0,0,1-2.69-1.15l-21.45-21.75L133,140.39m3-8.22-5.28,6.29-27.76,33.08-1.76,2.1,1.92,1.95,21.45,21.75a6.75,6.75,0,0,0,4.83,2,6.92,6.92,0,0,0,5.27-2.43,6.68,6.68,0,0,0,1.56-5.22L136,140.38l0-8.21Z"
      />
      <path
        style={cls2}
        d="M159,73.48a3.77,3.77,0,0,0-4.36-3.66l-24.53,3.91a35,35,0,0,0-21.28,12.06L58.07,146.26a4,4,0,0,0,.49,5.63l36.27,30.43a4,4,0,0,0,5.63-.49l50.74-60.47a35,35,0,0,0,8.18-23.06Z"
      />
      <path
        style={cls2}
        d="M155.22,69.77A3.77,3.77,0,0,1,159,73.48l.41,24.83a35,35,0,0,1-8.18,23.06l-50.74,60.47a4,4,0,0,1-5.63.49L58.56,151.89a4,4,0,0,1-.49-5.63l50.74-60.47a35,35,0,0,1,21.28-12.06l24.53-3.91a3.81,3.81,0,0,1,.6,0m0-3a6.83,6.83,0,0,0-1.07.09l-24.53,3.91A38,38,0,0,0,106.5,83.86L55.76,144.33a7,7,0,0,0,.86,9.87L92.9,184.63a7,7,0,0,0,9.87-.86l50.74-60.47a38,38,0,0,0,8.88-25L162,73.43a6.8,6.8,0,0,0-6.76-6.66Z"
      />
      <path
        style={cls2}
        d="M81.83,192.8,50.5,166.51a2.3,2.3,0,0,1,.43-3.8l14.65-7.46L90.3,176l-4.8,15.72A2.3,2.3,0,0,1,81.83,192.8Z"
      />
      <path
        style={cls2}
        d="M65.59,155.25,90.3,176l-4.8,15.72a2.29,2.29,0,0,1-3.67,1.09L50.5,166.51a2.3,2.3,0,0,1,.43-3.8l14.65-7.46m.41-3.58-1.77.9L49.57,160a5.3,5.3,0,0,0-1,8.79L79.9,195.1a5.3,5.3,0,0,0,8.48-2.51l4.8-15.72.58-1.9-1.52-1.28L67.52,152.95,66,151.67Z"
      />
      <circle
        style={cls2}
        cx="114.6"
        cy="121.92"
        r="16.86"
        transform="translate(-52.46 131.34) rotate(-50)"
      />
      <path
        style={cls2}
        d="M114.61,105.05a16.86,16.86,0,1,1-12.93,6,16.79,16.79,0,0,1,12.93-6m0-3h0a19.87,19.87,0,1,0,12.76,4.65A19.83,19.83,0,0,0,114.61,102Z"
      />
      <line style={cls2} x1="66.16" y1="186.64" x2="53.03" y2="202.29" />
      <path
        style={cls2}
        d="M53,203.79a1.5,1.5,0,0,1-1.15-2.47L65,185.67a1.5,1.5,0,0,1,2.3,1.93L54.18,203.26A1.5,1.5,0,0,1,53,203.79Z"
      />
      <line style={cls2} x1="51.05" y1="191.53" x2="39.59" y2="205.19" />
      <path
        style={cls2}
        d="M39.59,206.69a1.5,1.5,0,0,1-1.15-2.47L49.9,190.56a1.5,1.5,0,1,1,2.3,1.93L40.74,206.16A1.5,1.5,0,0,1,39.59,206.69Z"
      />
      <line style={cls2} x1="52.02" y1="176.67" x2="47.22" y2="182.39" />
      <path
        style={cls2}
        d="M47.22,183.89a1.5,1.5,0,0,1-1.15-2.47l4.79-5.71a1.5,1.5,0,1,1,2.3,1.93l-4.79,5.71A1.5,1.5,0,0,1,47.22,183.89Z"
      />
      <line style={cls2} x1="74.63" y1="193.95" x2="68.17" y2="201.66" />
      <path
        style={cls2}
        d="M68.17,203.16A1.5,1.5,0,0,1,67,200.69l6.46-7.7a1.5,1.5,0,1,1,2.3,1.93l-6.46,7.7A1.5,1.5,0,0,1,68.17,203.16Z"
      />
      <path
        style={cls1}
        d="M81.6,38.79,83.86,41,81.6,43.3,79.35,41l2.26-2.26m0-1A.89.89,0,0,0,81,38l-2.4,2.4a.9.9,0,0,0,0,1.27l2.4,2.4a.9.9,0,0,0,1.27,0l2.4-2.4a.9.9,0,0,0,0-1.27L82.24,38a.89.89,0,0,0-.63-.26Z"
      />
      <path
        style={cls1}
        d="M36.8,145.78a1.4,1.4,0,0,1-1-.41l-2.4-2.4a1.4,1.4,0,0,1,0-2l2.4-2.4a1.4,1.4,0,0,1,2,0l2.4,2.4a1.4,1.4,0,0,1,0,2l-2.4,2.4A1.39,1.39,0,0,1,36.8,145.78Zm0-6.6a.39.39,0,0,0-.28.12l-2.4,2.4a.4.4,0,0,0,0,.56l2.4,2.4a.39.39,0,0,0,.56,0l2.4-2.4a.4.4,0,0,0,0-.56l-2.4-2.4A.4.4,0,0,0,36.8,139.17Z"
      />
      <circle style={cls1} cx="63.32" cy="207.14" r="1.3" />
      <circle style={cls1} cx="81.6" cy="207.14" r="1.95" />
      <path
        style={cls1}
        d="M210.73,130.74a.67.67,0,0,1,.29.07l4.35,2.05a.67.67,0,0,1,.1,1.16l-4,2.74a.65.65,0,0,1-.38.12.67.67,0,0,1-.67-.62l-.4-4.79a.65.65,0,0,1,.17-.51.68.68,0,0,1,.5-.22m0-1a1.68,1.68,0,0,0-1.67,1.81l.4,4.79a1.67,1.67,0,0,0,1.67,1.54,1.66,1.66,0,0,0,.95-.3l4-2.74a1.67,1.67,0,0,0-.24-2.89l-4.35-2.05a1.67,1.67,0,0,0-.72-.16Z"
      />
      <rect
        style={cls1}
        x="36"
        y="167.88"
        width="5.51"
        height="1"
        transform="translate(-107.72 76.72) rotate(-45)"
      />
      <rect
        style={cls1}
        x="38.25"
        y="165.63"
        width="1"
        height="5.51"
        transform="translate(-107.72 76.72) rotate(-45)"
      />
      <rect
        style={cls1}
        x="45.39"
        y="210.28"
        width="3.67"
        height="1"
        transform="translate(-135.21 95.13) rotate(-45)"
      />
      <rect
        style={cls1}
        x="46.72"
        y="208.94"
        width="1"
        height="3.67"
        transform="translate(-135.21 95.13) rotate(-45)"
      />
      <rect
        style={cls1}
        x="136.83"
        y="208.03"
        width="3.67"
        height="1"
        transform="translate(-106.84 159.13) rotate(-45)"
      />
      <rect
        style={cls1}
        x="138.16"
        y="206.7"
        width="1"
        height="3.67"
        transform="translate(-106.82 159.03) rotate(-44.98)"
      />
      <rect
        style={cls1}
        x="114.06"
        y="39.25"
        width="3.67"
        height="1"
        transform="translate(5.84 93.59) rotate(-45)"
      />
      <rect
        style={cls1}
        x="115.39"
        y="37.91"
        width="1"
        height="3.67"
        transform="translate(5.86 93.63) rotate(-45.02)"
      />
      <path
        style={cls2}
        d="M93.32,107.1l-50.8,8.76a3.83,3.83,0,0,0-2.1,7l25.14,17.34Z"
      />
      <path d="M89.53,109.28,65.28,138.17l-24-16.57a2.33,2.33,0,0,1,1.28-4.24h.11l.11,0,46.76-8.06m3.79-2.18-50.8,8.76a3.83,3.83,0,0,0-2.1,7l25.14,17.34L93.32,107.1Z" />
      <path
        style={cls2}
        d="M133,140.39l.2,51.55a3.83,3.83,0,0,1-6.51,3.28l-21.45-21.75Z"
      />
      <path d="M131.49,144.5l.18,47.44v.11l0,.11a2.22,2.22,0,0,1-.5,1.85,2.45,2.45,0,0,1-1.84.85,2.24,2.24,0,0,1-1.62-.71l-20.49-20.78,24.24-28.89m1.49-4.11-27.76,33.08,21.45,21.75a3.73,3.73,0,0,0,2.69,1.15,3.83,3.83,0,0,0,3.82-4.44l-.2-51.55Z" />
      <path
        style={cls2}
        d="M159,73.47a3.77,3.77,0,0,0-4.36-3.66l-24.53,3.91a35,35,0,0,0-21.28,12.06L58.07,146.25a4,4,0,0,0,.49,5.63l36.27,30.43a4,4,0,0,0,5.63-.49l50.74-60.47a35,35,0,0,0,8.18-23.06Z"
      />
      <path d="M155.22,71.27a2.27,2.27,0,0,1,2.26,2.23l.41,24.83a33.48,33.48,0,0,1-7.83,22.06L99.31,180.86a2.49,2.49,0,0,1-1.91.89,2.46,2.46,0,0,1-1.6-.58L59.52,150.74a2.5,2.5,0,0,1-.31-3.52L110,86.75a33.48,33.48,0,0,1,20.37-11.54l24.53-3.91.36,0m0-1.5a3.81,3.81,0,0,0-.6,0l-24.53,3.91a35,35,0,0,0-21.28,12.06L58.07,146.25a4,4,0,0,0,.49,5.63l36.27,30.43a4,4,0,0,0,5.63-.49l50.74-60.47a35,35,0,0,0,8.18-23.06L159,73.47a3.77,3.77,0,0,0-3.76-3.71Z" />
      <path
        style={cls2}
        d="M81.43,193.07,50.11,166.78a2.3,2.3,0,0,1,.43-3.8l14.65-7.46,24.72,20.74L85.11,192A2.3,2.3,0,0,1,81.43,193.07Z"
      />
      <path d="M65,157.31l23.2,19.46-4.51,14.77a.8.8,0,0,1-.77.56.76.76,0,0,1-.5-.19L51.07,165.63a.79.79,0,0,1,.15-1.31l13.76-7m.2-1.79L50.54,163a2.3,2.3,0,0,0-.43,3.8l31.33,26.29a2.27,2.27,0,0,0,1.47.54,2.29,2.29,0,0,0,2.2-1.63l4.8-15.72L65.19,155.52Z" />
      <circle
        style={cls2}
        cx="114.6"
        cy="121.91"
        r="16.86"
        transform="translate(-52.46 131.34) rotate(-50)"
      />
      <path d="M114.61,106.55h0A15.36,15.36,0,1,1,102.83,112a15.37,15.37,0,0,1,11.78-5.49m0-1.5A16.86,16.86,0,1,0,125.44,109a16.83,16.83,0,0,0-10.83-3.95Z" />
      <line style={cls2} x1="66.16" y1="186.63" x2="53.03" y2="202.29" />
      <path d="M53,203a.74.74,0,0,1-.48-.18.75.75,0,0,1-.09-1.06l13.13-15.65a.75.75,0,0,1,1.15,1L53.61,202.77A.75.75,0,0,1,53,203Z" />
      <line style={cls2} x1="51.05" y1="191.52" x2="39.59" y2="205.19" />
      <path d="M39.59,205.94a.74.74,0,0,1-.48-.18A.75.75,0,0,1,39,204.7L50.48,191a.75.75,0,1,1,1.15,1L40.16,205.67A.75.75,0,0,1,39.59,205.94Z" />
      <line style={cls2} x1="52.02" y1="176.67" x2="47.22" y2="182.38" />
      <path d="M47.22,183.14a.74.74,0,0,1-.48-.18.75.75,0,0,1-.09-1.06l4.79-5.71a.75.75,0,0,1,1.15,1l-4.79,5.71A.75.75,0,0,1,47.22,183.14Z" />
      <line style={cls2} x1="74.63" y1="193.95" x2="68.17" y2="201.65" />
      <path d="M68.17,202.4a.74.74,0,0,1-.48-.18.75.75,0,0,1-.09-1.06l6.46-7.7a.75.75,0,1,1,1.15,1l-6.46,7.7A.75.75,0,0,1,68.17,202.4Z" />
      <circle style={cls2} cx="179.68" cy="164.87" r="24.67" />
      <path d="M179.68,141.7a23.17,23.17,0,1,1-23.17,23.17,23.2,23.2,0,0,1,23.17-23.17m0-1.5a24.67,24.67,0,1,0,24.67,24.67,24.67,24.67,0,0,0-24.67-24.67Z" />
      <circle style={cls2} cx="174.5" cy="179.48" r="1.3" />
      <path d="M174.5,178.69a.8.8,0,1,1-.8.8.8.8,0,0,1,.8-.8m0-.5a1.3,1.3,0,1,0,1.3,1.3,1.3,1.3,0,0,0-1.3-1.3Z" />
      <circle style={cls2} cx="189.41" cy="174.29" r="2.6" />
      <path d="M189.41,172.19a2.1,2.1,0,1,1-2.1,2.1,2.1,2.1,0,0,1,2.1-2.1m0-.5a2.6,2.6,0,1,0,2.6,2.6,2.6,2.6,0,0,0-2.6-2.6Z" />
      <circle style={cls2} cx="164.79" cy="165.78" r="1.95" />
      <path d="M164.79,164.34a1.45,1.45,0,1,1-1.45,1.45,1.45,1.45,0,0,1,1.45-1.45m0-.5a1.95,1.95,0,1,0,1.95,1.95,1.95,1.95,0,0,0-1.95-1.95Z" />
      <path
        style={cls2}
        d="M92,77.52h0A10.38,10.38,0,0,0,81.6,67.15h0A10.38,10.38,0,0,0,92,56.77h0a10.38,10.38,0,0,0,10.38,10.38h0A10.38,10.38,0,0,0,92,77.52Z"
      />
      <path d="M92,61.44a11.45,11.45,0,0,0,5.71,5.71A11.45,11.45,0,0,0,92,72.86a11.45,11.45,0,0,0-5.71-5.71A11.45,11.45,0,0,0,92,61.44m0-4.67A10.38,10.38,0,0,1,81.6,67.15,10.38,10.38,0,0,1,92,77.52a10.38,10.38,0,0,1,10.38-10.38A10.38,10.38,0,0,1,92,56.77Z" />
      <path style={cls2} d="M62,103.61h0l-4.9-4.9h0l4.9-4.9h0l4.9,4.9h0Z" />
      <path d="M62,94.52l2.24,2.24,2,2-2.1,2.1-2.1,2.1-2.25-2.25-1.94-1.94,1.81-1.81L62,94.52m0-.7h0l-4.89,4.89h0L62,103.61h0l4.89-4.89h0L62,93.82Z" />
      <path
        style={cls2}
        d="M66.87,67.14,58.7,75.31a5.75,5.75,0,0,0-8.14,0L42.4,67.14a5.75,5.75,0,0,0,0-8.14l8.17-8.17a5.75,5.75,0,0,0,8.14,0L66.87,59A5.75,5.75,0,0,0,66.87,67.14Z"
      />
      <path d="M59.31,58.4a12.61,12.61,0,0,0,0,9.34,12.63,12.63,0,0,0-9.36,0,12.61,12.61,0,0,0,0-9.34,12.63,12.63,0,0,0,9.36,0m3.48-3.48a11.53,11.53,0,0,1-16.31,0h0a11.53,11.53,0,0,1,0,16.31h0a11.53,11.53,0,0,1,16.31,0,11.53,11.53,0,0,1,0-16.31Z" />
    </svg>
  );
};

export default HowTheWorldWorks;
