/**--external-- */
import React from "react";
import propTypes from "prop-types";

/**--internal-- */
import { useI18n } from "Hooks";
import { getTimezoneList } from "Utils";
import { FormSelectDropdown } from "UIComponents";
import { VIEW_MODE } from "Constants/stringConstants";

const Timezone = props => {
  const { value, updateInputField, menuPlacement, mode } = props;

  const { t } = useI18n(["onBoarding"]);

  const timezones = getTimezoneList();

  return (
    <FormSelectDropdown
      placeholder={t("onBoarding:choose_time_zone")}
      value={value}
      size="x-large"
      noOptionsText={t("onBoarding:no_time_zone_found")}
      options={timezones}
      label={t("onBoarding:time_zone")}
      updateInputField={updateInputField}
      menuPlacement={menuPlacement}
      disabled={mode === VIEW_MODE}
    />
  );
};

Timezone.propTypes = {
  value: propTypes.string,
  updateInputField: propTypes.func,
  menuPlacement: propTypes.string,
  mode: propTypes.bool,
};

export default Timezone;

Timezone.displayName = "Timezone";
