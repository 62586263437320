import React from "react";
import { connect } from "react-redux";
import classes from "./AttachmentCard.scss";
import { getThumbUrl } from "Utils";
import {
  PDFViewer,
  ImageViewer,
  VideoViewer,
  RadialProgressBar,
  MediaModal,
} from "UIComponents";

import {
  DeleteIcon,
  VideoIcon,
  PdfLineIcon,
  ExpandIcon,
  DownloadIcon,
  LinkIcon,
  PlayIcon,
} from "SvgComponents";
import { colors } from "Constants";

export const ModelContent = ({
  attachments,
  toggleMediaModal,
  showMediaModal,
}) => {
  return showMediaModal ? (
    <div className={classes.modalContent}>
      <MediaModal
        toggleMediaModal={toggleMediaModal}
        attachments={attachments}
      />
    </div>
  ) : null;
};

class AttachmentCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMediaModal: false,
    };
  }

  showAttachment = () => {
    this.setState({ showMediaModal: true });
  };

  attachmentActionsSvgs = type => {
    return (
      <div className={classes.svgContainer}>
        {type != "LINK" && type !== "WORKBOOK" ? (
          <a
            target={`_blank`}
            href={this.props.attachment.url}
            download={true}
            onClick={e => {
              e.stopPropagation();
            }}
            className={classes.actionButton}
          >
            <div className={classes.actionSvgIcon}>
              <div className={classes.downloadIcon}>
                <DownloadIcon />
              </div>
            </div>
          </a>
        ) : null}

        <div
          className={classes.actionButton}
          onClick={() => this.showAttachment()}
        >
          <div className={classes.actionSvgIcon}>
            {type == "VIDEO" ? (
              <PlayIcon fill={colors.strokeTwo} />
            ) : (
              <ExpandIcon width={40} height={40} />
            )}
          </div>
        </div>

        {this.props.mode == "edit" ? (
          <div
            className={classes.actionButton}
            onClick={() =>
              this.props.deleteAttachment(this.props.attachment.id)
            }
          >
            <div className={classes.actionSvgIcon}>
              <DeleteIcon width={16} height={16} />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  getCenterSvgDetails = type => {
    let svgIcon = null;
    switch (type) {
      case "IMAGE":
        svgIcon = null;
        break;
      case "FILE":
        svgIcon = (
          <div className={classes.centralButton}>
            <div className={classes.pdfSvg}>
              <PdfLineIcon width={13} height={16} fill={colors.white} />
            </div>
          </div>
        );
        break;
      case "VIDEO":
        svgIcon = (
          <div className={classes.centralButton}>
            {/* <div className={classes.play} /> */}
            <PlayIcon fill={colors.strokeTwo} />
          </div>
        );
        break;
      case "LINK":
        svgIcon = (
          <div className={classes.centralButton}>
            <LinkIcon width={20} height={18} />
          </div>
        );
        break;
    }
    return svgIcon;
  };

  toggleMediaModal = value => {
    this.setState({ showMediaModal: value });
  };

  render() {
    const { attachment, progressOfUploads } = this.props;
    const { showMediaModal } = this.state;
    const progressValue = parseInt(
      _.get(progressOfUploads, `${attachment.id}.progress`, "100")
    );

    const title = attachment.name;
    const { thumbUrl, isIcon, color } = getThumbUrl({
      attachment,
      width: 256,
      height: 256,
    });
    const centerIconDiv = this.getCenterSvgDetails(attachment.type);
    const attachmentActionsSvgs = this.attachmentActionsSvgs(attachment.type);

    return (
      <div className={classes.container}>
        <div
          className={classes.overlayBackground}
          style={{
            backgroundImage: `url(${thumbUrl})`,
            backgroundColor: color,
            backgroundSize: isIcon ? "20%" : "cover",
            backgroundPosition: isIcon ? "center" : "auto",
          }}
        />
        <div className={classes.bottomDiv}>
          <div className={classes.title}>{title}</div>
        </div>

        <div className={classes.centerIcon}>{centerIconDiv}</div>

        {progressValue >= 100 ? <div className={classes.overlay} /> : null}

        {progressValue >= 100 ? attachmentActionsSvgs : null}
        {progressValue < 100 ? (
          <div className={classes.uploadingContainer}>
            <div className={classes.progressBar}>
              <RadialProgressBar
                value={progressValue}
                circleStroke={"#F0F0F3"}
                progressStroke={"#F0F0F3"}
              />
            </div>
          </div>
        ) : null}

        <ModelContent
          attachments={[attachment]}
          toggleMediaModal={this.toggleMediaModal}
          showMediaModal={showMediaModal}
        />
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    progressOfUploads: state.app_services.progressOfUploads,
  };
};

export default connect(mapStateToProps, mapActionCreators)(AttachmentCard);
