import gql from "graphql-tag";
import { attachmentFragment, userFragment } from "modules/CommonFragments";

export const snpPracticeEvidenceFragment = {
  practiceEvidence: gql`
    fragment practiceEvidenceItem on SnPPracticeEvidence {
      id
      evidenceType
      evidenceItem {
        id
        ... on Attachment {
          id
          ...attachmentItem
        }
        ... on Assessment {
          id
          assessmentTitle: title {
            id
            value
          }
          image {
            id
            value
          }
          #aliasing is needed as sometimes it returns type User or User!
          assessmentCreatedBy: createdBy {
            ...userItem
          }
          assessmentType {
            id
            value
          }
        }
        ... on UnitPlan {
          id
          unitPlanTitle: title {
            id
            value
          }
          unitPlanCreatedBy: createdBy {
            ...userItem
          }
          image {
            id
            value
          }
        }
        ... on Post {
          id
          postTitle: title
          attachments {
            ...attachmentItem
          }
          postCreatedBy: createdBy {
            ...userItem
          }
        }
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
  `,
};

// ... on Assessment {
//   id
//   ...assessmentItem
// }
// ... on UnitPlan {
//   id
//   ...unitPlanItem
// }
// ... on Post {
//   id
//   ...postSelectionItem
// }
// ${assessmentFragment.assessmentSelectModalFeed}
//     ${unitPlanFeedFragment.unitPlanFeed}
//     ${journalPostFragment.postSelection}

export const selfStudyResponseFragment = {
  selfStudyResponse: gql`
    fragment selfStudyResponseItem on SnPSelfStudyResponse {
      id
      response
      questionId
    }
  `,
  selfStudyResponseDetails: gql`
    fragment selfStudyResponseItem on SnPSelfStudyResponse {
      id
      questionId
      label
      response
      config
    }
  `,
};

export const selfStudySectionFragment = {
  selfStudySection: gql`
    fragment selfStudySectionItem on SnPSection {
      id
      isCompleted
      sectionId
      type
    }
  `,
};

export const snpSelfStudyFragment = {
  selfStudyDetails: gql`
    fragment snpSelfStudyItem on SnPSelfStudy {
      id
      isCompleted
      responses {
        ...selfStudyResponseItem
      }
      sections {
        ...selfStudySectionItem
      }
    }
    ${selfStudySectionFragment.selfStudySection}
    ${selfStudyResponseFragment.selfStudyResponse}
  `,
};

export const snpEvaluationCycleFragment = {
  evaluationCycle: gql`
    fragment snpEvaluationCycleItem on SnPEvaluationCycle {
      id
      year
      month
      isCreatedByDocument
      state
    }
  `,
  evaluationCycleDetails: gql`
    fragment snpEvaluationCycleItem on SnPEvaluationCycle {
      id
      year
      month
      isCreatedByDocument
      state
      selfStudy {
        id
        isCompleted
      }
    }
  `,
};
export const snpMileStoneFragment = {
  mileStoneDetails: gql`
    fragment snpMileStoneItem on SnPMilestone {
      id
      message
      isAchieved
    }
  `,
};

export const snpActionFragment = {
  actionDetails: gql`
    fragment snpActionItem on SnPAction {
      id
    }
  `,
  actionTableDetails: gql`
    fragment snpActionItem on SnPAction {
      id
      fields {
        id
        uid
        value
      }
      milestones {
        ...snpMileStoneItem
      }
      evidence {
        ...attachmentItem
      }
    }
    ${snpMileStoneFragment.mileStoneDetails}
    ${attachmentFragment.attachment}
  `,
  actionFullDetails: gql`
    fragment snpActionItem on SnPAction {
      id
      fields {
        id
        uid
        value
      }
      milestones {
        ...snpMileStoneItem
      }
      practices {
        id
        code
        label: localeLabelKey
      }
      evidence {
        ...attachmentItem
      }
    }
    ${snpMileStoneFragment.mileStoneDetails}
    ${attachmentFragment.attachment}
  `,
};

export const snpPracticeFragment = {
  practiceBasicDetail: gql`
    fragment snpPracticeItem on SnPPractice {
      id
      code
      type
      label: localeLabelKey
      actions {
        totalCount
      }
      evidence {
        totalCount
      }
      ibReportStatus
      selfStudyStatus
    }
  `,
  practiceList: gql`
    fragment snpPracticeItem on SnPPractice {
      id
      code
      label: localeLabelKey
    }
  `,
  practiceDetail: gql`
    fragment snpPracticeItem on SnPPractice {
      id
      code
      label: localeLabelKey
      actions(evaluationCycleIds: $evaluationCycleIds) {
        totalCount
      }
      evidence {
        totalCount
        edges {
          node {
            ...practiceEvidenceItem
          }
        }
      }
      ibReportStatus(evaluationCycleId: $evaluationCycleId)
      selfStudyStatus(evaluationCycleId: $evaluationCycleId)
    }
    ${snpPracticeEvidenceFragment.practiceEvidence}
  `,
  practiceActionDetails: gql`
    fragment snpPracticeItem on SnPPractice {
      id
      code
      label: localeLabelKey
      actions(evaluationCycleIds: $evaluationCycleIds) {
        totalCount
        edges {
          node {
            ...snpActionItem
          }
        }
      }
    }
    ${snpActionFragment.actionTableDetails}
  `,
  practiceSelfStudyDetails: gql`
    fragment snpPracticeItem on SnPPractice {
      id
      code
      label: localeLabelKey
      selfStudyresponseGroups(evaluationCycleIds: $evaluationCycleIds) {
        id
        responses {
          ...selfStudyResponseItem
        }
      }
    }
    ${selfStudyResponseFragment.selfStudyResponseDetails}
  `,
};
export const snpStandardFragment = {
  standardBasicDetails: gql`
    fragment snpStandardItem on SnPStandard {
      id
      code
      label: localeLabelKey
      subText: localeSubTextKey
    }
  `,
  standardDetails: gql`
    fragment snpStandardItem on SnPStandard {
      id
      selfStudyConclusion(evaluationCycleId: $evaluationCycleId)
      selfStudyStatus(evaluationCycleId: $evaluationCycleId)
      ibReportStatus(evaluationCycleId: $evaluationCycleId)
      ibReportConclusion(evaluationCycleId: $evaluationCycleId)
      practices(
        filters: {
          searchText: $searchText
          showOnlyPracticeWithAction: $showOnlyPracticeWithAction
        }
      ) {
        ...snpPracticeItem
      }
    }
    ${snpPracticeFragment.practiceBasicDetail}
  `,
  standardPracticesList: gql`
    fragment snpStandardItem on SnPStandard {
      id
      label: localeLabelKey
      code
      practices(filters: { searchText: $searchText }) {
        ...snpPracticeItem
      }
    }
    ${snpPracticeFragment.practiceList}
  `,
  standardActionDetails: gql`
    fragment snpStandardItem on SnPStandard {
      id
      practices {
        ...snpPracticeItem
      }
    }
    ${snpPracticeFragment.practiceActionDetails}
  `,
};

export const snpSetFragment = {
  snpSetFrameworks: gql`
    fragment snpSetItem on SnPSet {
      id
      frameworks {
        id
        code
        label: localeLabelKey
        standards {
          ...snpStandardItem
        }
      }
    }
    ${snpStandardFragment.standardBasicDetails}
  `,
  snpSetPractices: gql`
    fragment snpSetItem on SnPSet {
      id
      frameworks {
        id
        standards {
          ...snpStandardItem
        }
      }
    }
    ${snpStandardFragment.standardPracticesList}
  `,
  snpSetActions: gql`
    fragment snpSetItem on SnPSet {
      id
      frameworks {
        id
        standards {
          ...snpStandardItem
        }
      }
    }
    ${snpStandardFragment.standardActionDetails}
  `,
};
