import React from "react";
import PropTypes from "prop-types";

const DesignSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <path
        d="M18 24.5C18 24.7761 18.2239 25 18.5 25C18.7761 25 19 24.7761 19 24.5L18 24.5ZM18 13L18 24.5L19 24.5L19 13L18 13Z"
        fill="#F2768E"
      />
      <path
        d="M12.5 24.5L12.5 11.5L19.5 11.5L19.5 24.5L12.5 24.5Z"
        fill="#FCCED6"
        stroke="#F2768E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 23C15.5 23.2761 15.7239 23.5 16 23.5C16.2761 23.5 16.5 23.2761 16.5 23L15.5 23ZM15.5 11L15.5 23L16.5 23L16.5 11L15.5 11Z"
        fill="#F2768E"
      />
      <path
        d="M19.5 24.5L16.4096 28.9148C16.2106 29.1992 15.7894 29.1992 15.5904 28.9148L12.5 24.5L19.5 24.5Z"
        fill="white"
      />
      <path
        d="M16.4096 28.9148L16 28.6281L16.4096 28.9148ZM19.5 24.5L19.9096 24.7867C20.0165 24.634 20.0296 24.4345 19.9435 24.2691C19.8574 24.1037 19.6864 24 19.5 24L19.5 24.5ZM12.5 24.5L12.5 24C12.3136 24 12.1426 24.1037 12.0565 24.2691C11.9704 24.4345 11.9835 24.634 12.0904 24.7867L12.5 24.5ZM15.5904 28.9148L16 28.6281L16 28.6281L15.5904 28.9148ZM16.8192 29.2016L19.9096 24.7867L19.0904 24.2133L16 28.6281L16.8192 29.2016ZM19.5 24L12.5 24L12.5 25L19.5 25L19.5 24ZM12.0904 24.7867L15.1808 29.2016L16 28.6281L12.9096 24.2133L12.0904 24.7867ZM16 28.6281L16 28.6281L15.1808 29.2016C15.5789 29.7703 16.4211 29.7703 16.8192 29.2016L16 28.6281Z"
        fill="#F2768E"
      />
      <path
        d="M15 28L17 28L16.8944 28.2111C16.5259 28.9482 15.4741 28.9482 15.1056 28.2111L15 28Z"
        stroke="#F2768E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5001 11.5L19.5001 11.5C19.6667 10.3333 19.2001 8 16.0001 8C12.8001 8 12.3334 10.3333 12.5001 11.5Z"
        fill="white"
        stroke="#F2768E"
      />
      <path
        d="M28 16.5C28 9.87258 22.6274 4.5 16 4.5C9.37258 4.5 4 9.87258 4 16.5"
        stroke="#F2768E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 4.5H28.5"
        stroke="#F2768E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="14.5"
        y="2.5"
        width="3"
        height="3"
        rx="0.5"
        fill="#FCCED6"
        stroke="#F2768E"
      />
      <rect
        x="3"
        y="3"
        width="3"
        height="3"
        fill="#FCCED6"
        stroke="#F2768E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="3"
        y="15"
        width="3"
        height="3"
        fill="#FCCED6"
        stroke="#F2768E"
        strokeLinejoin="round"
      />
      <rect
        x="26"
        y="3"
        width="3"
        height="3"
        fill="#FCCED6"
        stroke="#F2768E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="26"
        y="15"
        width="3"
        height="3"
        fill="#FCCED6"
        stroke="#F2768E"
        strokeLinejoin="round"
      />
    </svg>
  );
};

DesignSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

DesignSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default DesignSGIcon;
