import React from "react";
import { UIModal, I18nHOC, Loading } from "UIComponents";
import classes from "./ContributeSuccessModal.scss";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { FBIcon, TwitterIcon, LinkedinIcon, CancelIcon } from "SvgComponents";
import { getUserInfo } from "Login/modules/LoginModule";
import { getEntityBasicInfoQuery } from "Community/modules/CommunityQuery";
import { goToUserProfile } from "Community/modules/CommunityModule";
import { checkAndUpdateCoachMarkStatus } from "modules/CoachMarksModule";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import { getRethumbUrl, getResourceURL, getSharerURL } from "Utils";
import { SOCIAL_TYPES, colors } from "Constants";
import { recordEvent } from "lib/eventTracker";
import { Trans } from "react-i18next";

const SOCIAL_DATA = [
  {
    type: SOCIAL_TYPES.FACEBOOK,
    Icon: FBIcon,
    backgroundColor: "#3b5a9a",
    textId: "common:facebook",
  },
  {
    type: SOCIAL_TYPES.TWITTER,
    Icon: TwitterIcon,
    backgroundColor: "#28aae1",
    textId: "common:twitter",
  },
  {
    type: SOCIAL_TYPES.LINKEDIN,
    Icon: LinkedinIcon,
    backgroundColor: "#0097d3",
    textId: "common:linkedin",
  },
];

const ContributeSuccessModal = ({
  entity,
  entityType,
  userId,
  setSuccessModal,
  isLoading,
  goToUserProfile,
  checkAndUpdateCoachMarkStatus,
  t,
}) => {
  const image = _.get(entity, "image.value", "") || RESOURCE_PLACEHOLDER_URL;
  const imageURL = image
    ? getRethumbUrl({
        width: 140,
        height: 140,
        imageUrl: image,
      })
    : "";
  const facebookShareUrl = _.get(entity, "facebookShareUrl", "");
  const twitterShareUrl = _.get(entity, "twitterShareUrl", "");
  const linkedinShareUrl = _.get(entity, "linkedinShareUrl", "");

  const contentType =
    entityType === "UNIT_PLAN" ? "unit plan" : "learning experience";
  const authorFirstName = _.capitalize(
    _.get(entity, "createdBy.firstName", "")
  );
  const authorLastName = _.capitalize(_.get(entity, "createdBy.lastName", ""));
  const author = `${authorFirstName} ${authorLastName}`;
  const shareText = `Check out this engaging ${contentType} by ${author} on Toddle!`;

  const onProfileClick = () => {
    goToUserProfile(userId);
    setSuccessModal(false);
  };

  const onClose = () => setSuccessModal(false);

  const socialShareClickListener = type => {
    recordEvent({
      eventName: "Share",
      eventData: {
        target: type,
        entityId: entity.id,
        entityType: entity.type,
        entityName: entity.name,
        source: "contribute-success-modal",
      },
    });
    let url = "";
    switch (type) {
      case SOCIAL_TYPES.FACEBOOK:
        url = facebookShareUrl;
        break;
      case SOCIAL_TYPES.TWITTER:
        url = twitterShareUrl;
        break;
      case SOCIAL_TYPES.LINKEDIN:
        url = linkedinShareUrl;
        break;
      default:
        break;
    }
    window.open(getSharerURL(type, url, shareText), "_blank");
    checkAndUpdateCoachMarkStatus({
      type: "COMMUNITY_SHARE_BUTTON",
      isViewed: true,
      portalType: "COMMUNITY",
    });
  };

  return (
    <UIModal isOpen={true} modalContent={classes.modalContent}>
      {isLoading ? (
        <Loading containerStyle={{ minHeight: "550px" }} />
      ) : (
        <div onClick={e => e.stopPropagation()} className={classes.container}>
          <div className={classes.content}>
            <div className={classes.cancelIcon} onClick={onClose}>
              <CancelIcon width={18} height={18} />
            </div>

            <div className={classes.iconContainer}>
              <img src={imageURL} width="135" height="135" />
            </div>
            <div className={classes.submittedText}>
              {t("community:submitted_to_community", {
                label: t(
                  `community:${
                    ["UNIT_PLAN", "units"].includes(entityType)
                      ? "unit"
                      : "learning_experience"
                  }`
                ),
              })}
            </div>
            <div className={classes.thankYouText}>
              <Trans i18nKey="community:contributed_thank_you_message_new">
                Thank you for your contibution to Toddle.you can view your
                contributions in
                <span onClick={onProfileClick}>Your Profile</span>
              </Trans>
            </div>
          </div>

          <div className={classes.shareWrapper}>
            <div className={classes.shareTitle}>
              {t("community:inspire_by_sharing")}
            </div>
            <div className={classes.shareActionWrapper}>
              {SOCIAL_DATA.map(({ type, Icon, backgroundColor, textId }) => (
                <div
                  key={type}
                  className={classes.shareAction}
                  onClick={() => socialShareClickListener(type)}
                >
                  <div
                    className={classes.shareIcon}
                    style={{ backgroundColor }}
                  >
                    <Icon width={24} height={24} fill={colors.white} />
                  </div>
                  <div className={classes.shareIconTitle}>{t(textId)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </UIModal>
  );
};

const mapStateToProps = () => {
  const userInfo = getUserInfo({ portalType: "COMMUNITY" }) || {};
  return {
    userId: userInfo.id,
  };
};

const mapActionCreators = {
  goToUserProfile,
  checkAndUpdateCoachMarkStatus,
};

export default compose(
  I18nHOC({ resource: ["community", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getEntityBasicInfoQuery, {
    name: "entityInfo",
    skip: ({ entityId }) => !entityId,
    options: ({ entityId, entityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: entityId, entityType },
    }),
    props: ({ entityInfo, ownProps }) => {
      const entity = _.get(entityInfo, "node");
      return {
        entity,
        ownProps,
        isData: ownProps.isData && !_.isEmpty(entity),
        isLoading:
          entityInfo["networkStatus"] == 1 ||
          entityInfo["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  })
)(ContributeSuccessModal);
