var utils_1 = require("@sentry/utils");
var global = utils_1.getGlobalObject();
/**
 * Creates routing instrumentation for React Router v3
 * Works for React Router >= 3.2.0 and < 4.0.0
 *
 * @param history object from the `history` library
 * @param routes a list of all routes, should be
 * @param match `Router.match` utility
 */
export function reactRouterV3Instrumentation(history, routes, match) {
  return function (
    startTransaction,
    startTransactionOnPageLoad,
    startTransactionOnLocationChange
  ) {
    if (startTransactionOnPageLoad === void 0) {
      startTransactionOnPageLoad = true;
    }
    if (startTransactionOnLocationChange === void 0) {
      startTransactionOnLocationChange = true;
    }
    var activeTransaction;
    var prevName;
    // Have to use global.location because history.location might not be defined.
    if (startTransactionOnPageLoad && global && global.location) {
      normalizeTransactionName(routes, global.location, match, function (
        localName
      ) {
        prevName = localName;
        activeTransaction = startTransaction({
          name: prevName,
          op: "pageload",
          tags: {
            "routing.instrumentation": "react-router-v3",
          },
        });
      });
    }
    if (startTransactionOnLocationChange && history.listen) {
      history.listen(function (location) {
        if (location.action === "PUSH" || location.action === "POP") {
          const isFinished = activeTransaction?._finished;
          /**
           * @description don't send transacation to sentry
           * server that are not finshed by idleTimeout.
           * This case can be reproduced by doing fast
           * route navigations and has incomplete and missing
           * spans that leads to incorrect performance
           * measurement metrics.
           */
          if (!isFinished) {
            if (activeTransaction) {
              activeTransaction.sampled = false;
            }
          }
          if (activeTransaction) {
            activeTransaction.finish();
          }
          var tags_1 = {
            "routing.instrumentation": "react-router-v3",
          };
          if (prevName) {
            tags_1.from = prevName;
          }
          normalizeTransactionName(routes, location, match, function (
            localName
          ) {
            prevName = localName;
            activeTransaction = startTransaction({
              name: prevName,
              op: "navigation",
              tags: tags_1,
            });
          });
        }
      });
    }
  };
}

/**
 * Normalize transaction names using `Router.match`
 */
export function normalizeTransactionName(appRoutes, location, match, callback) {
  var name = location.pathname;
  match(
    {
      location: location,
      routes: appRoutes,
    },
    function (error, _redirectLocation, renderProps) {
      if (error || !renderProps) {
        return callback(name);
      }
      var routePath = getRouteStringFromRoutes(renderProps.routes || []);
      if (routePath.length === 0 || routePath === "/*") {
        return callback(name);
      }
      name = routePath;
      return callback(name);
    }
  );
}
/**
 * Generate route name from array of routes
 */
function getRouteStringFromRoutes(routes) {
  if (!Array.isArray(routes) || routes.length === 0) {
    return "";
  }
  var routesWithPaths = routes.filter(function (route) {
    return !!route.path;
  });
  var index = -1;
  for (var x = routesWithPaths.length - 1; x >= 0; x--) {
    var route = routesWithPaths[x];
    if (route.path && route.path.startsWith("/")) {
      index = x;
      break;
    }
  }
  const string = routesWithPaths
    .slice(index)
    .filter(function (_a) {
      var path = _a.path;
      return !!path;
    })
    .map(function (_a) {
      var path = _a.path;
      return path;
    })
    .join("/");

  if (string?.startsWith("//")) {
    return string.substring(1);
  }

  return string;
}
