import gql from "graphql-tag";
import { studentAssignmentFragment } from "ClassRoom/modules/ClassRoomFragments";
import { projectDeadlineFragment } from "./TodosFragments";

export const getStudentTasksQuery = gql`
  query getStudentTasks(
    $userId: ID!
    $studentIds: [ID!]
    $filters: TaskFilter
    $first: Int
    $after: String
    $orderByDirection: ORDER_BY_DIRECTION
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $userId, type: $type) {
      id
      ... on Student {
        id
        tasks(
          filters: $filters
          first: $first
          after: $after
          orderByDirection: $orderByDirection
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
          edges {
            id
            orderDate
            itemType
            item {
              id
              ... on StudentAssignment {
                ...studentAssignmentItem
              }
              ... on ProjectDeadline {
                ...projectDeadlineItem
              }
            }
          }
        }
      }
      ... on FamilyMember {
        id
        tasks(
          filters: $filters
          first: $first
          after: $after
          studentIds: $studentIds
          orderByDirection: $orderByDirection
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
          edges {
            id
            orderDate
            itemType
            item {
              id
              ... on StudentAssignment {
                ...studentAssignmentItem
              }
              ... on ProjectDeadline {
                ...projectDeadlineItem
              }
            }
          }
        }
      }
    }
  }
  ${studentAssignmentFragment.studentAssignmentBasicDetails}
  ${projectDeadlineFragment.projectDeadlineDetails}
`;

export const getStudentTasksFilterCountQuery = gql`
  query getStudentTasksFilterCount(
    $courseIds: [ID!]
    $userId: ID!
    $lastWeekStartDate: String
    $lastWeekEndDate: String
    $earlierEndDate: String
    $thisWeekStartDate: String
    $thisWeekEndDate: String
    $entityTypes: [TASK_EVENT_ITEM_TYPE_ENUM!]
    $academicYearId: ID
    $studentIds: [ID!]
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $userId, type: $type) {
      id
      ... on Student {
        id
        LAST_WEEK: tasks(
          filters: {
            academicYearId: $academicYearId
            courseIds: $courseIds
            completionStatus: "COMPLETED"
            entityTypes: $entityTypes
            startDate: $lastWeekStartDate
            endDate: $lastWeekEndDate
          }
        ) {
          totalCount
        }
        EARLIER: tasks(
          filters: {
            academicYearId: $academicYearId
            courseIds: $courseIds
            completionStatus: "COMPLETED"
            entityTypes: $entityTypes
            endDate: $earlierEndDate
          }
        ) {
          totalCount
        }
        THIS_WEEK: tasks(
          filters: {
            academicYearId: $academicYearId
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "COMPLETED"
            startDate: $thisWeekStartDate
            endDate: $thisWeekEndDate
          }
        ) {
          totalCount
        }
        UPCOMING: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "UPCOMING"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
        ) {
          totalCount
        }
        NODUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "NODUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
        ) {
          totalCount
        }
        OVERDUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "OVERDUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
        ) {
          totalCount
        }
      }
      ... on FamilyMember {
        id
        LAST_WEEK: tasks(
          filters: {
            academicYearId: $academicYearId
            courseIds: $courseIds
            completionStatus: "COMPLETED"
            entityTypes: $entityTypes
            startDate: $lastWeekStartDate
            endDate: $lastWeekEndDate
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        EARLIER: tasks(
          filters: {
            academicYearId: $academicYearId
            courseIds: $courseIds
            completionStatus: "COMPLETED"
            entityTypes: $entityTypes
            endDate: $earlierEndDate
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        THIS_WEEK: tasks(
          filters: {
            academicYearId: $academicYearId
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "COMPLETED"
            startDate: $thisWeekStartDate
            endDate: $thisWeekEndDate
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        UPCOMING: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "UPCOMING"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        NODUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "NODUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        OVERDUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "OVERDUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
      }
    }
  }
`;

export const getStudentTasksHomepageCountQuery = gql`
  query getStudentTasksHomepageCountQuery(
    $courseIds: [ID!]
    $userId: ID!
    $entityTypes: [TASK_EVENT_ITEM_TYPE_ENUM!]
    $academicYearId: ID
    $studentIds: [ID!]
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $userId, type: $type) {
      id
      ... on Student {
        id
        UPCOMING: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "UPCOMING"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
        ) {
          totalCount
        }
        OVERDUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "OVERDUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
        ) {
          totalCount
        }
        NODUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "NODUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
        ) {
          totalCount
        }
      }
      ... on FamilyMember {
        id
        UPCOMING: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "UPCOMING"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        OVERDUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "OVERDUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
        NODUE: tasks(
          filters: {
            academicYearId: $academicYearId
            status: "NODUE"
            courseIds: $courseIds
            entityTypes: $entityTypes
            completionStatus: "TODO"
          }
          studentIds: $studentIds
        ) {
          totalCount
        }
      }
    }
  }
`;
