import React from "react";
import moment from "moment";
import {
  LearningExperienceColored,
  SummativeAssessmentColored,
  FormativeAssessmentColored,
  QuickTaskOutlined,
} from "@toddle-design/web-icons";
import { colors } from "Constants";
import { CreateAssessmentSvg } from "SvgComponents";

export const ASSIGNMENT_TYPE_MAPPING = {
  le: {
    typeLabel: "common:le_label",
    iconElement: <LearningExperienceColored size={"xx-small"} />,
  },
  ASSESSMENT: {
    typeLabel: "common:le_label",
    iconElement: <LearningExperienceColored size={"xx-small"} />,
  },
  ASSIGNMENT_RESOURCE: {
    typeLabel: "common:quick_task",
    iconElement: <QuickTaskOutlined size={"xx-small"} variant={"link"} />,
  },
  qt: {
    typeLabel: "common:quick_task",
    iconElement: <QuickTaskOutlined size={"xx-small"} variant={"link"} />,
  },
  smt: {
    typeLabel: "classRoom:summative_assessment",
    iconElement: <SummativeAssessmentColored size={"xx-small"} />,
  },
  fmt: {
    typeLabel: "classRoom:formative_assessment",
    iconElement: <FormativeAssessmentColored size={"xx-small"} />,
  },
  pt: {
    typeLabel: "common:performance_task",
    iconElement: (
      <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />
    ),
  },
  pri: {
    typeLabel: "classRoom:pre_assessment",
    iconElement: (
      <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />
    ),
  },
  se: {
    typeLabel: "common:supplementary_evidence",
    iconElement: (
      <CreateAssessmentSvg fill={colors.white} fill1={colors.blue29} />
    ),
  },
};

const getAssignmentsFromEdges = ({ data, t }) => {
  return _.map(data, assignment => {
    const { id, content, contentType, createdAt } = _.get(
      assignment,
      "node",
      {}
    );

    const isQuickTask = contentType === "ASSIGNMENT_RESOURCE";

    let type = _.get(content, "assessmentType.value", "");

    type = type ? type : contentType;

    const title = isQuickTask
      ? { value: _.get(content, "quickTaskTitle") }
      : _.get(content, "title", "Untitled");

    const date = moment(createdAt).format("DD MMMM YYYY");
    const subTitle = `${t(ASSIGNMENT_TYPE_MAPPING[type].typeLabel)} | ${date}`;

    const image = _.get(content, "image", {});

    return {
      id,
      title,
      subTitle,
      image,
      type,
    };
  });
};

export const getAssignmentsFromEdgesMemoized = _.memoize(
  getAssignmentsFromEdges,
  params => JSON.stringify(params)
);
