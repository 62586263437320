import PropTypes from "prop-types";
import React from "react";

const HowWeOrganizeOurselvesActive = props => {
  const cls1 = { fill: "#10acbd", opacity: "0.4" };
  const cls2 = { fill: "#fff" };

  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 250 250">
      <title>How we organise ourselves active</title>
      <path
        style={cls1}
        d="M212.63,158.24,48.84,176.87a15.08,15.08,0,0,1-16.71-13.5l-5.06-51.16a15.08,15.08,0,0,1,13.3-16.46L204.2,77.12A13.94,13.94,0,0,1,219.65,89.6L224.93,143A13.94,13.94,0,0,1,212.63,158.24Z"
      />
      <rect
        style={cls2}
        x="55.14"
        y="72.67"
        width="142.12"
        height="104.65"
        rx="13.93"
        ry="13.93"
        transform="translate(6.05 255.83) rotate(-92.16)"
      />
      <path d="M155.15,54h0a20.27,20.27,0,0,1,20.31,19.58l3.74,99a20.37,20.37,0,0,1-19.56,21.1L98,196h-.78a20.27,20.27,0,0,1-20.31-19.58l-3.74-99a20.34,20.34,0,0,1,19.56-21.1L154.36,54h.78m0-1.16-.83,0L92.71,55.16A21.5,21.5,0,0,0,72,77.45l3.74,99a21.5,21.5,0,0,0,21.47,20.69l.83,0,61.61-2.33a21.5,21.5,0,0,0,20.67-22.3l-3.74-99a21.5,21.5,0,0,0-21.47-20.69Z" />
      <path
        style={cls1}
        d="M92.26,90.38h8.88a4.56,4.56,0,0,1,4.56,4.56v10.52a2.93,2.93,0,0,1-2.93,2.93H92.26a4.56,4.56,0,0,1-4.56-4.56V94.94A4.56,4.56,0,0,1,92.26,90.38Z"
        transform="translate(-3.68 3.72) rotate(-2.16)"
      />
      <path d="M99.41,88a2.44,2.44,0,0,1,2.44,2.35l.41,10.8a2.44,2.44,0,0,1-2.35,2.53l-10.8.41H89a2.44,2.44,0,0,1-2.44-2.35L86.17,91a2.44,2.44,0,0,1,2.35-2.53L99.31,88h.1m0-1.16h-.14l-10.8.41A3.6,3.6,0,0,0,85,91l.41,10.8a3.6,3.6,0,0,0,3.6,3.47h.14l10.8-.41a3.6,3.6,0,0,0,3.46-3.74L103,90.35a3.6,3.6,0,0,0-3.6-3.47Z" />
      <path d="M92.29,99.49H92.2a1,1,0,0,1-.74-.42l-1.81-2a.58.58,0,1,1,.86-.78l1.79,2,5.55-5.49a.58.58,0,0,1,.81.82L93,99.19A1,1,0,0,1,92.29,99.49Z" />
      <line style={cls2} x1="108.45" y1="87.13" x2="135.96" y2="86.09" />
      <path d="M108.45,87.7a.58.58,0,0,1,0-1.16l27.51-1h0a.58.58,0,0,1,0,1.16l-27.51,1Z" />
      <line style={cls2} x1="108.76" y1="95.51" x2="126.57" y2="94.84" />
      <path d="M108.76,96.09a.58.58,0,0,1,0-1.16l17.8-.67h0a.58.58,0,0,1,0,1.16l-17.8.67Z" />
      <line style={cls2} x1="109.08" y1="103.89" x2="158.18" y2="102.04" />
      <path d="M109.08,104.47a.58.58,0,0,1,0-1.16l49.1-1.85h0a.58.58,0,0,1,0,1.16l-49.1,1.85Z" />
      <rect
        style={cls1}
        x="88.67"
        y="130.12"
        width="18.01"
        height="18.01"
        rx="4.06"
        ry="4.06"
        transform="matrix(1, -0.04, 0.04, 1, -5.18, 3.79)"
      />
      <path d="M100.16,128.43a2.44,2.44,0,0,1,2.44,2.35l.41,10.8a2.44,2.44,0,0,1-2.35,2.53l-10.8.41h-.09a2.44,2.44,0,0,1-2.44-2.35l-.41-10.8a2.44,2.44,0,0,1,2.35-2.53l10.8-.41h.1m0-1.16H100l-10.8.41a3.6,3.6,0,0,0-3.46,3.74l.41,10.8a3.6,3.6,0,0,0,3.6,3.47h.14l10.8-.41a3.6,3.6,0,0,0,3.46-3.74l-.41-10.8a3.6,3.6,0,0,0-3.6-3.47Z" />
      <path d="M93,139.88H93a1,1,0,0,1-.74-.42l-1.81-2a.58.58,0,1,1,.86-.78l1.79,2,5.55-5.49a.58.58,0,1,1,.81.82l-5.65,5.59A1,1,0,0,1,93,139.88Z" />
      <line style={cls2} x1="109.2" y1="127.52" x2="136.71" y2="126.48" />
      <path d="M109.2,128.1a.58.58,0,0,1,0-1.16l27.51-1a.6.6,0,0,1,.6.56.58.58,0,0,1-.56.6l-27.51,1Z" />
      <line style={cls2} x1="109.52" y1="135.9" x2="159.39" y2="134.02" />
      <path d="M109.52,136.48a.58.58,0,0,1,0-1.16l49.88-1.88h0a.58.58,0,0,1,0,1.16l-49.88,1.88Z" />
      <line style={cls2} x1="109.83" y1="144.28" x2="126.54" y2="143.65" />
      <path d="M109.83,144.86a.58.58,0,0,1,0-1.16l16.71-.63a.57.57,0,0,1,.6.56.58.58,0,0,1-.56.6l-16.71.63Z" />
      <rect
        style={cls2}
        x="43.38"
        y="138.09"
        width="13.58"
        height="13.58"
        rx="4.23"
        ry="4.23"
        transform="translate(-18.84 7.97) rotate(-7.65)"
      />
      <path d="M52.91,138.85A2.45,2.45,0,0,1,55.33,141l.85,6.32a2.44,2.44,0,0,1-2.1,2.75l-6.32.85-.33,0A2.45,2.45,0,0,1,45,148.78l-.85-6.32a2.44,2.44,0,0,1,2.1-2.75l6.32-.85.33,0m0-1.16a3.63,3.63,0,0,0-.48,0l-6.32.85a3.6,3.6,0,0,0-3.09,4l.85,6.32a3.6,3.6,0,0,0,4,3.09l6.32-.85a3.6,3.6,0,0,0,3.09-4l-.85-6.32a3.6,3.6,0,0,0-3.57-3.12Z" />
      <path d="M48.9,147.71a.91.91,0,0,1-.69-.31L46.72,146a.58.58,0,1,1,.78-.86l1.37,1.25L52.55,142a.58.58,0,1,1,.89.74l-3.84,4.61a.91.91,0,0,1-.69.33Zm.13-1.12h0Z" />
      <rect
        style={cls2}
        x="171.41"
        y="108.5"
        width="13.58"
        height="13.58"
        rx="4.16"
        ry="4.16"
        transform="translate(-13.76 24.75) rotate(-7.65)"
      />
      <path d="M180.93,109.26h0a2.45,2.45,0,0,1,2.42,2.12l.85,6.32a2.45,2.45,0,0,1-2.1,2.75l-6.32.85-.33,0A2.45,2.45,0,0,1,173,119.2l-.85-6.32a2.45,2.45,0,0,1,2.1-2.75l6.32-.85.33,0m0-1.16a3.65,3.65,0,0,0-.48,0l-6.32.85a3.6,3.6,0,0,0-3.09,4l.85,6.32a3.6,3.6,0,0,0,4,3.09l6.32-.85a3.6,3.6,0,0,0,3.09-4l-.85-6.32a3.6,3.6,0,0,0-3.57-3.12Z" />
      <path d="M176.93,118.13a.91.91,0,0,1-.69-.31l-1.49-1.35a.58.58,0,0,1,.78-.86l1.37,1.25,3.67-4.4a.58.58,0,0,1,.89.74l-3.84,4.61A.75.75,0,0,1,176.93,118.13Zm.13-1.12h0Z" />
      <rect
        style={cls1}
        x="90.06"
        y="166.95"
        width="18.01"
        height="18.01"
        rx="5"
        ry="5"
        transform="matrix(1, -0.04, 0.04, 1, -6.57, 3.86)"
      />
      <path d="M94.42,176.26h-.08a1,1,0,0,1-.75-.42l-1.81-2a.58.58,0,1,1,.86-.78l1.79,2,5.55-5.49a.58.58,0,0,1,.81.82L95.14,176A1,1,0,0,1,94.42,176.26Zm.07-1.15h0Z" />
      <path d="M101.54,164.81a2.44,2.44,0,0,1,2.44,2.35l.41,10.8a2.44,2.44,0,0,1-2.35,2.53l-10.8.41h-.09a2.44,2.44,0,0,1-2.44-2.35l-.41-10.8a2.44,2.44,0,0,1,2.35-2.53l10.8-.41h.1m0-1.16h-.14l-10.8.41a3.6,3.6,0,0,0-3.46,3.74l.41,10.8a3.6,3.6,0,0,0,3.6,3.47h.14l10.8-.41a3.6,3.6,0,0,0,3.46-3.74l-.41-10.8a3.6,3.6,0,0,0-3.6-3.47Z" />
      <line style={cls2} x1="110.57" y1="163.9" x2="150.81" y2="162.38" />
      <path d="M110.57,164.47a.58.58,0,0,1,0-1.16l40.24-1.52h0a.58.58,0,0,1,0,1.16l-40.24,1.52Z" />
      <line style={cls2} x1="110.89" y1="172.28" x2="145.47" y2="170.97" />
      <path d="M110.89,172.86a.58.58,0,0,1,0-1.16l34.58-1.31a.62.62,0,0,1,.6.56.58.58,0,0,1-.56.6l-34.58,1.31Z" />
      <line style={cls2} x1="111.21" y1="180.66" x2="127.92" y2="180.03" />
      <path d="M111.21,181.24a.58.58,0,0,1,0-1.16l16.71-.63a.56.56,0,0,1,.6.56.58.58,0,0,1-.56.6l-16.71.63Z" />
      <path
        style={cls2}
        d="M105.79,54h35.84a0,0,0,0,1,0,0V65.64a2.42,2.42,0,0,1-2.42,2.42H108.44a2.65,2.65,0,0,1-2.65-2.65V54A0,0,0,0,1,105.79,54Z"
        transform="translate(-2.21 4.71) rotate(-2.16)"
      />
      <path d="M140.24,54.5l.35,9.2a2.57,2.57,0,0,1-2.47,2.67l-28,1.06H110a2.92,2.92,0,0,1-2.92-2.82l-.33-8.84,33.5-1.27m1.11-1.2-35.81,1.35.38,10A4.08,4.08,0,0,0,110,68.58h.16l28-1.06a3.73,3.73,0,0,0,3.59-3.87l-.39-10.35Z" />
      <circle
        style={cls2}
        cx="195.35"
        cy="91.39"
        r="4.74"
        transform="translate(-3.31 7.44) rotate(-2.16)"
      />
      <path d="M195.35,87.81a3.58,3.58,0,0,1,.13,7.16h-.14a3.58,3.58,0,0,1-.13-7.16h.14m0-1.16h-.18a4.74,4.74,0,0,0,.18,9.47h.18a4.74,4.74,0,0,0-.18-9.47Z" />
      <circle
        style={cls2}
        cx="65.86"
        cy="126.48"
        r="3.35"
        transform="translate(-4.73 2.58) rotate(-2.16)"
      />
      <path d="M65.87,124.29a2.19,2.19,0,0,1,.08,4.38h-.09a2.19,2.19,0,0,1-.08-4.38h.09m0-1.16h-.13a3.35,3.35,0,0,0,.12,6.7H66a3.35,3.35,0,0,0-.12-6.7Z" />
      <circle
        style={cls2}
        cx="57.95"
        cy="158.81"
        r="2.37"
        transform="translate(-5.95 2.3) rotate(-2.16)"
      />
      <path d="M58,157.6a1.21,1.21,0,0,1,1.21,1.17A1.22,1.22,0,0,1,58,160a1.21,1.21,0,0,1,0-2.42h0m0-1.16h-.09a2.37,2.37,0,0,0,.09,4.74H58a2.37,2.37,0,0,0-.09-4.74Z" />
      <circle
        style={cls2}
        cx="189.51"
        cy="135.66"
        r="2.77"
        transform="matrix(1, -0.04, 0.04, 1, -4.99, 7.25)"
      />
      <path d="M189.51,134a1.61,1.61,0,0,1,1.61,1.56,1.63,1.63,0,0,1-1.62,1.68,1.62,1.62,0,0,1-.06-3.23h.06m0-1.16h-.11a2.77,2.77,0,0,0,.1,5.55h.11a2.77,2.77,0,0,0-.1-5.55Z" />
    </svg>
  );
};

export default HowWeOrganizeOurselvesActive;
