import gql from "graphql-tag";

export const updateAttachmentMutation = gql`
  mutation updateAttachment($newAttachment: AttachmentUpdateInput!) {
    platform {
      updateAttachment(input: $newAttachment) {
        id
        ... on Attachment {
          type
          title
          url
          name
          mimeType
          thumbUrl
          description
          streamUrl
          metadata
        }
      }
    }
  }
`;
