import { graphql } from "react-apollo";
import { getStaffListQuery } from "modules/CommonQuery";
import { getStaffListFromCache } from "modules/CommonGraphqlHelpers";

const options = {
  name: "getStaffList",
  options: ({
    organizationId,
    searchText = "",
    archivalState,
    curriculumProgramIds,
  }) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      organizationId,
      searchText,
      archivalState,
      curriculumProgramIds,
    },
  }),
  skip: ({ importFromLeLibrary = true }) => !importFromLeLibrary,
  props({
    getStaffList,
    ownProps: {
      organizationId,
      searchText = "",
      isData,
      isLoading,
      archivalState,
      curriculumProgramIds,
    },
  }) {
    const data = getStaffListFromCache({
      organizationId,
      searchText,
      archivalState,
      curriculumProgramIds,
    });
    const creatorList = _.map(_.get(data, "node.staff.edges"), item => ({
      value: item.node.id,
      label: `${item.node.firstName} ${item.node.lastName}`,
      isArchived: _.get(item, "node.isArchived"),
    }));
    return {
      isData: !_.isEmpty(data) && isData,
      isLoading: isLoading || _.includes([1, 2], getStaffList["networkStatus"]),
      creatorList,
      options: creatorList,
    };
  },
};

export default graphql(getStaffListQuery, options);
