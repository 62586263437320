import React from "react";
import { Helmet } from "react-helmet";

const MetaTagsComp = React.memo(props => {
  const { title, image, description, url } = props;

  return (
    <Helmet>
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />
    </Helmet>
  );
});

export default MetaTagsComp;
