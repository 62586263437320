import React from "react";
import classes from "./HelperPane.scss";
import { CancelIcon, HelperBulb } from "SvgComponents";
import { colors } from "Constants";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, MediaModal } from "UIComponents";
import { getHelpTextQuery } from "modules/CommonQuery";
import { getHelpTextFromCache } from "modules/CommonGraphqlHelpers";
import ReactHtmlParser from "react-html-parser";
import { generateRandomId } from "Utils";
import { Button } from "@toddle-design/web";

class HelperPane extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMediaModal: false,
      mediaModalData: {},
    };
  }

  toggleMediaModal = val => {
    this.setState({
      showMediaModal: val,
    });
  };

  onClickImage = e => {
    this.toggleMediaModal(true);
    this.setState({
      mediaModalData: {
        attachments: [
          {
            type: "IMAGE",
            id: generateRandomId(),
            url: e.currentTarget.getAttribute("src"),
            name: e.currentTarget.getAttribute("alt") ?? "Image",
          },
        ],
      },
    });
  };

  //it will be executed for every node i.e. div, span, img etc.
  /*
node: will have following details.
  type (string): The type of node (tag, text, style etc)
  name (string): The name of the node
  children (array): Array of children nodes
  next (node): The node's next sibling
  prev (node): The node's previous sibling
  parent (node): The node's parent
  data (string): The text content, if the type is text

ref:https://www.npmjs.com/package/react-html-parser
 */
  htmlNodeTransform = node => {
    const { t } = this.props;
    if (node.type === "tag" && node.name === "img") {
      return (
        <div
          className={classes.imageContainer}
          src={node.attribs.src}
          alr={node.attribs.alt}
          onClick={this.onClickImage}
        >
          <img
            {...node.attribs}
            src={node.attribs.src}
            alr={node.attribs.alt}
          ></img>
          <div className={classes.hoverContainer}>
            <Button>
              {t("common:view_with_label_lowercase", {
                label: t("common:image"),
              })}
            </Button>
          </div>
        </div>
      );
    }
  };

  render() {
    let {
      onClose,
      fieldKey,
      helperText: { title = "", subtitle = "", content = "" } = {},
      label,
    } = this.props;

    if (!title) title = label;

    const { showMediaModal, mediaModalData } = this.state;

    return (
      <div className={classes.container}>
        <div onClick={onClose} className={classes.cancelIcon}>
          <CancelIcon height={16} width={16} />
        </div>
        <div className={classes.scrollContainer}>
          <div className={classes.header}>
            <div className={classes.helperBulb}>
              <HelperBulb fill={colors.blue29} height={32} width={32} />
            </div>
          </div>
          <div className={classes.helperBody}>
            <div className={classes.helperTitle}>{title}</div>
            <div className={classes.helperSubTitle}>{subtitle}</div>
            <div className={classes.helperContent}>
              {ReactHtmlParser(content, { transform: this.htmlNodeTransform })}
            </div>
          </div>
        </div>
        {showMediaModal && (
          <MediaModal
            attachments={mediaModalData.attachments}
            toggleMediaModal={this.toggleMediaModal}
          />
        )}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const { t, i18n } = ownProps;

  return {
    locale: i18n.language,
    showLoaderBar: false,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlanTemplate", "projectTemplate"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getHelpTextQuery, {
    name: "getHelpText",
    options: ({ helperTextId, locale }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: helperTextId,
        locale,
      },
    }),
    props({ getHelpText, ownProps: { helperTextId, locale } }) {
      const heplerText = getHelpTextFromCache({ id: helperTextId, locale });
      return {
        isData: !_.isEmpty(heplerText),
        helperText: heplerText,
        networkStatus: getHelpText.networkStatus,
        isLoading:
          getHelpText["networkStatus"] == 1 ||
          getHelpText["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(HelperPane);
