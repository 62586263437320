import update from "immutability-helper";
import client from "apolloClient";

import {
  upsertProductItemVoteMutation,
  removeProductItemVoteMutation,
  shareProductUpdateMutation,
  resetProductUpdateNewContentMutation,
} from "./ProductUpdatesMutations";

import { writeProductUpdatesFragmentToCache } from "./ProductUpdatesGraphqlHelpers";

import { colors, CURRICULUM_PLANS_CONSTANTS_MAPPING } from "Constants";

import { setToastMsg } from "Login/modules/LoginModule";
import { CURRICULUM_SUBSCRIPTION_PLAN_PERM } from "Constants/permissionConstants";
import ACLStore from "lib/aclStore";

import _ from "lodash";

// -------------------------------- constants --------------------------------

export const STYLES_MAPPING = {
  pink: {
    color: colors.pink59,
    background: colors.pink98,
    borderColor: colors.pink95,
  },
  violet: {
    color: colors.violet54,
    background: colors.violet98,
    borderColor: colors.violet94,
  },
  blue: {
    borderColor: colors.blue90,
    color: colors.blue23,
    background: colors.blue96,
  },
  gray: {
    borderColor: colors.strokeOne,
    background: colors.gray98,
    color: colors.gray31,
  },
  yellow: {
    borderColor: colors.yellow80,
    background: colors.yellow96,
    color: colors.yellow38,
  },
};

/**
 * @deprecated
 */
export const PLAN_MAPPING = {
  Free: "FREE",
  Pro: "PRO",
  "Pro Pilot": "PRO_PILOT",
  360: "360",
  "360 Pilot": "360_PILOT",
  Demo: "DEMO",
};

const PLATFORM = ["WEB", "MOBILE"];
// -------------------------------- util --------------------------------

export const filterAndSortCategories = categories => {
  const filteredCategories = categories.filter(
    category => category?.group !== "Plan" && category?.displaySequence
  );

  const sortedCategories = filteredCategories.sort(
    (a, b) => a.displaySequence - b.displaySequence
  );

  return sortedCategories;
};

export const getVisibleCategory = ({ categories, orgCurriculums }) => {
  const IBCurriculums = ["IB_PYP", "IB_MYP", "IB_DP", "UBD"];
  const isOrgIB = _.every(orgCurriculums, curriculum =>
    _.includes(IBCurriculums, curriculum)
  );

  if (!isOrgIB) {
    // don't show curriculum categories if org is not pure IB
    return _.filter(categories, category => category?.group !== "Curriculum");
  }
  const excludedCategoryTypes = ["UBD"];

  const visibleCurriculum = _.filter(
    categories,
    category =>
      !_.includes(excludedCategoryTypes, category?.type) &&
      category?.group === "Curriculum"
  );
  const visibleDevices = _.filter(
    categories,
    category => category?.group === "Devices"
  );
  return _.concat(visibleCurriculum, visibleDevices);
};

export const getProductUpdatesFilters = ({
  curriculumPrograms,
  planCategory,
}) => {
  const areCurriculumPlansEnabled = ACLStore.can(
    CURRICULUM_SUBSCRIPTION_PLAN_PERM
  );

  if (!areCurriculumPlansEnabled) {
    const curriculumProgramTypes = _.map(
      curriculumPrograms,
      ({ type }) => type
    );

    return {
      platform: PLATFORM,
      curriculum: curriculumProgramTypes,
      plan: PLAN_MAPPING[planCategory],
    };
  }

  const curriculumPlan = _.map(
    curriculumPrograms,
    ({ type, subscriptionPlan }) => {
      return {
        curriculum: type,
        plan: CURRICULUM_PLANS_CONSTANTS_MAPPING[subscriptionPlan.type],
      };
    }
  );

  return {
    platform: PLATFORM,
    curriculumPlan,
  };
};

// -------------------------------- mutations --------------------------------

export const shareProductUpdate = ({ shareProductUpdateInput }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: shareProductUpdateMutation,
        variables: {
          ...shareProductUpdateInput,
        },
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "classRoom:shared", isPlainText: false },
          ],
        })
      );
      return true;
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const resetProductUpdateNewContent = ({ filters }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: resetProductUpdateNewContentMutation,
        variables: {
          input: {
            ...filters,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeProductItemVote = params => {
  return async (dispatch, getState) => {
    try {
      writeProductUpdatesFragmentToCache({
        id: params.parentId,
        myVote: null,
      });
      await client.mutate({
        mutation: removeProductItemVoteMutation,
        variables: {
          input: params,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const upsertProductItemVote = params => {
  return async (dispatch, getState) => {
    try {
      writeProductUpdatesFragmentToCache({
        id: params.parentId,
        myVote: params.type,
      });

      await client.mutate({
        mutation: upsertProductItemVoteMutation,
        variables: {
          input: params,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

// helper for upsert and remove like
export const handleVote = (item, action) => {
  const { vote, id } = item;
  return async (dispatch, getState) => {
    if (vote === action) {
      dispatch(
        removeProductItemVote({
          parentId: id,
          parentType: "PRODUCT_UPDATE",
        })
      );
    } else {
      dispatch(
        upsertProductItemVote({
          parentId: id,
          parentType: "PRODUCT_UPDATE",
          type: action,
        })
      );
    }
  };
};

// -------------------------------- redux --------------------------------

// action types
export const NAME = "productUpdates";
export const UPDATE_FILTER = "UPDATE_FILTER" + " " + NAME;

// actions
export const updateFeedFilters = payload => {
  return { type: UPDATE_FILTER, payload };
};

// reducers
const REDUCER_HANDLERS = {
  [UPDATE_FILTER]: (state, action) => {
    return update(state, {
      filters: { $set: action.payload },
    });
  },
};

// initial state
const initialState = {
  filters: {
    categories: undefined,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
