import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./UserApproval.scss";
import { withPermission } from "lib/PermissionAware";
import { FeedList } from "AppComponents";
import { ChevronIcon } from "SvgComponents";
import { getUserApprovalRequestFeed } from "../../modules/ToolingQueries";
import moment from "moment";
import {
  onClickNavigate,
  approveUser,
  changeOrganizationSearchString,
  deleteApproveUser,
} from "Tooling/modules/ToolingModule";
import { withLoader, Loading, SearchBar, DialogueBox } from "UIComponents";

import OrganizationModal from "../OrganizationManagement/OrganizationModal";
import { getGlobalConstantsFromCache } from "modules/CommonGraphqlHelpers";
const parentStyle = {
  gridTemplateColumns: "15% 15% 20% 10% 8% 16% 5%",
  gridColumnGap: "16px",
};

const columnList = [
  {
    label: "Name",
    id: "name",
    type: "TEXTCOLUMN",
    style: { justifySelf: "start" },
  },
  {
    label: "Email",
    id: "email",
    type: "TEXTCOLUMN",
    style: { justifySelf: "start" },
  },

  {
    label: "Organization name",
    id: "organizationName",
    type: "TEXTCOLUMN",
    style: {
      justifyContent: "start",
    },
  },

  {
    label: "Role",
    id: "designation",
    type: "TEXTCOLUMN",
    style: {
      justifyContent: "start",
    },
  },
  {
    label: "Requested On",
    id: "createdAt",
    type: "TEXTCOLUMN",
    style: { justifySelf: "start" },
  },

  {
    label: "URL",
    id: "url",
    type: "CUSTOM",
    style: {
      justifySelf: "start",
    },
  },

  {
    label: "Actions",
    id: "action",
    type: "ACTIONS",
  },
];

const steps = [
  {
    id: "firstName",
    label: "User First Name",
    ref: "firstName",
    name: "firstName",
    placeholder: "Enter user first name",
    validationCheck: true,
    type: "text",
    disabled: true,
  },
  {
    id: "lastName",
    label: "User Last Name",
    ref: "lastName",
    name: "lastName",
    placeholder: "Enter user last name",
    validationCheck: true,
    type: "text",
    disabled: true,
  },
  {
    id: "email",
    label: "User Email",
    ref: "email",
    name: "email",
    placeholder: "Enter email",
    type: "text",
    disabled: true,
  },
  {
    id: "designation",
    label: "Designation",
    ref: "designation",
    name: "designation",
    placeholder: "Enter role",
    type: "selectDropDown",
    disabled: true,
  },
  {
    id: "orgName",
    label: "School name",
    ref: "orgName",
    name: "orgName",
    placeholder: "School name",
    type: "text",
    disabled: true,
  },

  {
    id: "city",
    label: "School City",
    ref: "city",
    name: "city",
    placeholder: "Enter city",
    type: "text",
    disabled: true,
  },
  {
    id: "country",
    label: "School Country",
    ref: "country",
    name: "country",
    placeholder: "Enter country",
    type: "text",
    disabled: true,
  },
  {
    id: "domains",
    label: "School Domains",
    ref: "domains",
    name: "domains",
    placeholder: "Enter Domains",
    type: "selectDropDown",
    error: "Provide the domain",
    disabled: true,
    multi: true,
  },
];

class OrganizationManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,

      orgData: null,
      dialogueBoxScreen: null,
    };
  }

  getFeedData = () => {
    const { organizations } = this.props;
    const filteredList = organizations;

    const feedData = _.map(filteredList, (organization, index) => {
      const id = organization.id;

      return {
        ...organization,
        id,
        designation: _.get(organization, "designation.title", ""),
        name: `${organization.firstName || ""} ${organization.lastName || ""}`,
        createdAt: moment(organization.createdAt).format("Do MMM YY"),
        domains: (organization.domains || []).join(", "),
        organizationName: _.get(organization, "organizationPreset.name", ""),
        action: [
          {
            label: "Approve",
            clickAction: () =>
              this.setState({
                dialogueBoxScreen: "editRequest",
                orgData: {
                  ...organization,
                  designation: _.get(organization, "designation.id", ""),
                },
              }),
          },
          {
            label: "Reject",
            clickAction: () =>
              this.setState({
                dialogueBoxScreen: "rejectRequest",
                orgData: {
                  ...organization,
                  designation: _.get(organization, "designation.id", ""),
                },
              }),
          },
        ],
      };
    });
    return feedData;
  };

  onClose = () => {
    this.setState({ dialogueBoxScreen: false });
  };

  approveUser = async data => {
    this.onClose();

    try {
      await this.props.approveUser(data);
    } catch (err) {
      console.error(err);
    }
  };

  getModalData = () => {
    const { orgData } = this.state;

    const result = _.cloneDeep(orgData);
    const organization = _.get(result, "organizationPreset", {});

    result.orgName = organization.name;
    result.domains = organization.domains;
    result.city = organization.city;
    result.country = organization.country;
    result.ibCode = organization.ibCode;
    result.plannerFormat = organization.plannerFormat;

    return result;
  };

  onItemClick = id => {
    const school = _.find(this.props.organizations, { id });
    if (school) {
      this.setState({
        orgData: { ...school, designation: school.designation.id },
        dialogueBoxScreen: "editRequest",
      });
    }
  };

  getCustomComponent = ({ column, columnData, id }) => {
    const doesUrlHaveScheme =
      columnData &&
      (columnData.includes("https://") ||
        columnData.includes("http://") ||
        columnData.includes("://"));
    let url = columnData;
    if (columnData && !doesUrlHaveScheme) {
      url = `//${columnData}`;
    }
    switch (column.id) {
      case "url":
        return (
          <div>
            <a
              className={classes.linkColumn}
              href={url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {columnData}
            </a>
          </div>
        );
      case "webUrl":
        return (
          <div className={classes.linkColumn}>
            <a
              className={classes.linkColumn}
              href={url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {columnData}
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  onBackClicked = () => {
    this.props.onClickNavigate({
      type: "replace",
      link: "organizationManagement",
    });
  };

  getOptions = key => {
    const { designations } = this.props;
    const { orgData } = this.state;

    switch (key) {
      case "designation":
        return _.map(designations, ({ id, title, description }) => {
          return { label: title, value: id, subText: description };
        });

      case "domains":
        return _.map(
          _.get(orgData, "organizationPreset.domains", []),
          domain => {
            return { label: domain, value: domain };
          }
        );
      default:
        return [];
    }
  };

  changeSearchTerm = value => {
    this.props.changeOrganizationSearchString({ userApproval: value });
  };

  deleteApproveUser = data => {
    this.props.deleteApproveUser(data);
  };

  getDialogueBox = () => {
    const { dialogueBoxScreen, orgData } = this.state;

    if (dialogueBoxScreen) {
      switch (dialogueBoxScreen) {
        case "rejectRequest":
          return (
            <DialogueBox
              modalTitle="Reject the request"
              onClickButton2={() => this.deleteApproveUser(orgData)}
              modalBody="Are you sure you want to reject this request?"
              toggleDialogueBoxDisplay={this.onClose}
              button1="Cancel"
              button2="Reject"
            />
          );
        case "editRequest":
          return (
            <OrganizationModal
              title={"User and School Details"}
              data={this.getModalData()}
              getOptions={this.getOptions}
              steps={steps}
              onClose={this.onClose}
              onSaveClick={this.approveUser}
              doneButtonText={"Approve"}
            />
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  render() {
    const { loading, searchText } = this.props;
    return (
      <div
        ref={ref => (this.scrollContainer = ref)}
        className={classes.container}
      >
        <div className={classes.headerContainer}>
          <div
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            onClick={this.onBackClicked}
          >
            <div className={classes.backContainer}>
              <ChevronIcon />
            </div>
            <div className={classes.header}>{"User Approval"}</div>
          </div>
        </div>
        <div className={classes.searchContainer}>
          <SearchBar
            placeholder={"Search by user name"}
            searchTerm={searchText}
            changeSearchTerm={this.changeSearchTerm}
            shouldAnimate={false}
            wrapperType={"box"}
          />
        </div>
        <FeedList
          parentStyle={parentStyle}
          columnList={columnList}
          getCustomComponent={this.getCustomComponent}
          feedData={this.getFeedData()}
          fetchMoreFeed={this.fetchMoreFeed}
        />
        {this.props.getAllOrganization.networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        {this.getDialogueBox()}

        {loading ? (
          <div className={classes.loadingContainerFull}>
            <Loading />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapActionCreators = {
  approveUser,
  onClickNavigate,
  deleteApproveUser,
  changeOrganizationSearchString,
};

const mapStateToProps = (state, ownProps) => {
  const organizationId = state.login.userInfo.org_id;
  const loading = state.tooling.isLoading.orgnizationManagement;
  const globalConstants = getGlobalConstantsFromCache();
  const designations = _.get(globalConstants, "designations", []);
  return {
    // isLoading: true,
    // isData: false,
    searchText: state.tooling.searchString.userApproval,
    designations,
    perm: "ToolPortal:ManageOrganization",
    organizationSearchString: state.tooling.organizationSearchString,
    organizationId,
    loading,
  };
};

// export default Organization;
export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getUserApprovalRequestFeed, {
    name: "getAllOrganization",
    options: ({ searchText }) => ({
      fetchPolicy: "cache-and-network",
      variables: { searchText },
    }),
    props({ getAllOrganization }) {
      const organizations = _.get(
        getAllOrganization,
        "organizationManagement.userApprovalRequestFeed",
        []
      );
      return {
        organizations,
        hasNextPage: _.get(
          getAllOrganization,
          "organizationManagement.organizationPresetFeed.pageInfo.hasNextPage",
          false
        ),
        getAllOrganization,
        isData: getAllOrganization.organizationManagement,
        isLoading:
          getAllOrganization["networkStatus"] == 1 ||
          getAllOrganization["networkStatus"] == 2,
      };
    },
  }),
  withLoader,
  withPermission
)(OrganizationManagement);
