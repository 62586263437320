export const timezoneList = [
  {
    id: "1",
    zone: "Pacific/Niue",
    label: "Niue",
    timezone: "GMT-11:00",
  },
  {
    id: "2",
    zone: "Pacific/Pago_Pago",
    label: "Pago Pago",
    timezone: "GMT-11:00",
  },
  {
    id: "3",
    zone: "Pacific/Honolulu",
    label: "Hawaii Time",
    timezone: "GMT-10:00",
  },
  {
    id: "4",
    zone: "Pacific/Rarotonga",
    label: "Rarotonga",
    timezone: "GMT-10:00",
  },
  {
    id: "5",
    zone: "Pacific/Tahiti",
    label: "Tahiti",
    timezone: "GMT-10:00",
  },
  {
    id: "6",
    zone: "Pacific/Marquesas",
    label: "Marquesas",
    timezone: "GMT-09:30",
  },
  {
    id: "7",
    zone: "America/Anchorage",
    label: "Alaska Time",
    timezone: "GMT-09:00",
  },
  {
    id: "8",
    zone: "Pacific/Gambier",
    label: "Gambier",
    timezone: "GMT-09:00",
  },
  {
    id: "9",
    zone: "America/Los_Angeles",
    label: "Pacific Time",
    timezone: "GMT-08:00",
  },
  {
    id: "10",
    zone: "America/Tijuana",
    label: "Pacific Time - Tijuana",
    timezone: "GMT-08:00",
  },
  {
    id: "11",
    zone: "America/Vancouver",
    label: "Pacific Time - Vancouver",
    timezone: "GMT-08:00",
  },
  {
    id: "12",
    zone: "America/Whitehorse",
    label: "Pacific Time - Whitehorse",
    timezone: "GMT-08:00",
  },
  {
    id: "13",
    zone: "Pacific/Pitcairn",
    label: "Pitcairn",
    timezone: "GMT-08:00",
  },
  {
    id: "14",
    zone: "America/Dawson_Creek",
    label: "Mountain Time - Dawson Creek",
    timezone: "GMT-07:00",
  },
  {
    id: "15",
    zone: "America/Denver",
    label: "Mountain Time",
    timezone: "GMT-07:00",
  },
  {
    id: "16",
    zone: "America/Edmonton",
    label: "Mountain Time - Edmonton",
    timezone: "GMT-07:00",
  },
  {
    id: "17",
    zone: "America/Hermosillo",
    label: "Mountain Time - Hermosillo",
    timezone: "GMT-07:00",
  },
  {
    id: "18",
    zone: "America/Mazatlan",
    label: "Mountain Time - Chihuahua, Mazatlan",
    timezone: "GMT-07:00",
  },
  {
    id: "19",
    zone: "America/Phoenix",
    label: "Mountain Time - Arizona",
    timezone: "GMT-07:00",
  },
  {
    id: "20",
    zone: "America/Yellowknife",
    label: "Mountain Time - Yellowknife",
    timezone: "GMT-07:00",
  },
  {
    id: "21",
    zone: "America/Belize",
    label: "Belize",
    timezone: "GMT-06:00",
  },
  {
    id: "22",
    zone: "America/Chicago",
    label: "Central Time",
    timezone: "GMT-06:00",
  },
  {
    id: "23",
    zone: "America/Costa_Rica",
    label: "Costa Rica",
    timezone: "GMT-06:00",
  },
  {
    id: "24",
    zone: "America/El_Salvador",
    label: "El Salvador",
    timezone: "GMT-06:00",
  },
  {
    id: "25",
    zone: "America/Guatemala",
    label: "Guatemala",
    timezone: "GMT-06:00",
  },
  {
    id: "26",
    zone: "America/Managua",
    label: "Managua",
    timezone: "GMT-06:00",
  },
  {
    id: "27",
    zone: "America/Mexico_City",
    label: "Central Time - Mexico City",
    timezone: "GMT-06:00",
  },
  {
    id: "28",
    zone: "America/Regina",
    label: "Central Time - Regina",
    timezone: "GMT-06:00",
  },
  {
    id: "29",
    zone: "America/Tegucigalpa",
    label: "Central Time - Tegucigalpa",
    timezone: "GMT-06:00",
  },
  {
    id: "30",
    zone: "America/Winnipeg",
    label: "Central Time - Winnipeg",
    timezone: "GMT-06:00",
  },
  {
    id: "31",
    zone: "Pacific/Galapagos",
    label: "Galapagos",
    timezone: "GMT-06:00",
  },
  {
    id: "32",
    zone: "America/Bogota",
    label: "Bogota",
    timezone: "GMT-05:00",
  },
  {
    id: "33",
    zone: "America/Cancun",
    label: "America Cancun",
    timezone: "GMT-05:00",
  },
  {
    id: "34",
    zone: "America/Cayman",
    label: "Cayman",
    timezone: "GMT-05:00",
  },
  {
    id: "35",
    zone: "America/Guayaquil",
    label: "Guayaquil",
    timezone: "GMT-05:00",
  },
  {
    id: "36",
    zone: "America/Havana",
    label: "Havana",
    timezone: "GMT-05:00",
  },
  {
    id: "37",
    zone: "America/Iqaluit",
    label: "Eastern Time - Iqaluit",
    timezone: "GMT-05:00",
  },
  {
    id: "38",
    zone: "America/Jamaica",
    label: "Jamaica",
    timezone: "GMT-05:00",
  },
  {
    id: "39",
    zone: "America/Lima",
    label: "Lima",
    timezone: "GMT-05:00",
  },
  {
    id: "40",
    zone: "America/Nassau",
    label: "Nassau",
    timezone: "GMT-05:00",
  },
  {
    id: "41",
    zone: "America/New_York",
    label: "Eastern Time",
    timezone: "GMT-05:00",
  },
  {
    id: "42",
    zone: "America/Panama",
    label: "Panama",
    timezone: "GMT-05:00",
  },
  {
    id: "43",
    zone: "America/Port-au-Prince",
    label: "Port-au-Prince",
    timezone: "GMT-05:00",
  },
  {
    id: "44",
    zone: "America/Rio_Branco",
    label: "Rio Branco",
    timezone: "GMT-05:00",
  },
  {
    id: "45",
    zone: "America/Toronto",
    label: "Eastern Time - Toronto",
    timezone: "GMT-05:00",
  },
  {
    id: "46",
    zone: "Pacific/Easter",
    label: "Easter Island",
    timezone: "GMT-05:00",
  },
  {
    id: "47",
    zone: "America/Caracas",
    label: "Caracas",
    timezone: "GMT-04:30",
  },
  {
    id: "48",
    zone: "America/Asuncion",
    label: "Asuncion",
    timezone: "GMT-03:00",
  },
  {
    id: "49",
    zone: "America/Barbados",
    label: "Barbados",
    timezone: "GMT-04:00",
  },
  {
    id: "50",
    zone: "America/Boa_Vista",
    label: "Boa Vista",
    timezone: "GMT-04:00",
  },
  {
    id: "51",
    zone: "America/Campo_Grande",
    label: "Campo Grande",
    timezone: "GMT-03:00",
  },
  {
    id: "52",
    zone: "America/Cuiaba",
    label: "Cuiaba",
    timezone: "GMT-03:00",
  },
  {
    id: "53",
    zone: "America/Curacao",
    label: "Curacao",
    timezone: "GMT-04:00",
  },
  {
    id: "54",
    zone: "America/Grand_Turk",
    label: "Grand Turk",
    timezone: "GMT-04:00",
  },
  {
    id: "55",
    zone: "America/Guyana",
    label: "Guyana",
    timezone: "GMT-04:00",
  },
  {
    id: "56",
    zone: "America/Halifax",
    label: "Atlantic Time - Halifax",
    timezone: "GMT-04:00",
  },
  {
    id: "57",
    zone: "America/La_Paz",
    label: "La Paz",
    timezone: "GMT-04:00",
  },
  {
    id: "58",
    zone: "America/Manaus",
    label: "Manaus",
    timezone: "GMT-04:00",
  },
  {
    id: "59",
    zone: "America/Martinique",
    label: "Martinique",
    timezone: "GMT-04:00",
  },
  {
    id: "60",
    zone: "America/Port_of_Spain",
    label: "Port of Spain",
    timezone: "GMT-04:00",
  },
  {
    id: "61",
    zone: "America/Porto_Velho",
    label: "Porto Velho",
    timezone: "GMT-04:00",
  },
  {
    id: "62",
    zone: "America/Puerto_Rico",
    label: "Puerto Rico",
    timezone: "GMT-04:00",
  },
  {
    id: "63",
    zone: "America/Santo_Domingo",
    label: "Santo Domingo",
    timezone: "GMT-04:00",
  },
  {
    id: "64",
    zone: "America/Thule",
    label: "Thule",
    timezone: "GMT-04:00",
  },
  {
    id: "65",
    zone: "Atlantic/Bermuda",
    label: "Bermuda",
    timezone: "GMT-04:00",
  },
  {
    id: "66",
    zone: "America/St_Johns",
    label: "Newfoundland Time - St. Johns",
    timezone: "GMT-03:30",
  },
  {
    id: "67",
    zone: "America/Araguaina",
    label: "Araguaina",
    timezone: "GMT-03:00",
  },
  {
    id: "68",
    zone: "America/Argentina/Buenos_Aires",
    label: "Buenos Aires",
    timezone: "GMT-03:00",
  },
  {
    id: "69",
    zone: "America/Bahia",
    label: "Salvador",
    timezone: "GMT-03:00",
  },
  {
    id: "70",
    zone: "America/Belem",
    label: "Belem",
    timezone: "GMT-03:00",
  },
  {
    id: "71",
    zone: "America/Cayenne",
    label: "Cayenne",
    timezone: "GMT-03:00",
  },
  {
    id: "72",
    zone: "America/Fortaleza",
    label: "Fortaleza",
    timezone: "GMT-03:00",
  },
  {
    id: "73",
    zone: "America/Godthab",
    label: "Godthab",
    timezone: "GMT-03:00",
  },
  {
    id: "74",
    zone: "America/Maceio",
    label: "Maceio",
    timezone: "GMT-03:00",
  },
  {
    id: "75",
    zone: "America/Miquelon",
    label: "Miquelon",
    timezone: "GMT-03:00",
  },
  {
    id: "76",
    zone: "America/Montevideo",
    label: "Montevideo",
    timezone: "GMT-03:00",
  },
  {
    id: "77",
    zone: "America/Paramaribo",
    label: "Paramaribo",
    timezone: "GMT-03:00",
  },
  {
    id: "78",
    zone: "America/Recife",
    label: "Recife",
    timezone: "GMT-03:00",
  },
  {
    id: "79",
    zone: "America/Santiago",
    label: "Santiago",
    timezone: "GMT-03:00",
  },
  {
    id: "80",
    zone: "America/Sao_Paulo",
    label: "Sao Paulo",
    timezone: "GMT-02:00",
  },
  {
    id: "81",
    zone: "Antarctica/Palmer",
    label: "Palmer",
    timezone: "GMT-03:00",
  },
  {
    id: "82",
    zone: "Antarctica/Rothera",
    label: "Rothera",
    timezone: "GMT-03:00",
  },
  {
    id: "83",
    zone: "Atlantic/Stanley",
    label: "Stanley",
    timezone: "GMT-03:00",
  },
  {
    id: "84",
    zone: "America/Noronha",
    label: "Noronha",
    timezone: "GMT-02:00",
  },
  {
    id: "85",
    zone: "Atlantic/South_Georgia",
    label: "South Georgia",
    timezone: "GMT-02:00",
  },
  {
    id: "86",
    zone: "America/Scoresbysund",
    label: "Scoresbysund",
    timezone: "GMT-01:00",
  },
  {
    id: "87",
    zone: "Atlantic/Azores",
    label: "Azores",
    timezone: "GMT-01:00",
  },
  {
    id: "88",
    zone: "Atlantic/Cape_Verde",
    label: "Cape Verde",
    timezone: "GMT-01:00",
  },
  {
    id: "89",
    zone: "Africa/Abidjan",
    label: "Abidjan",
    timezone: "GMT+00:00",
  },
  {
    id: "90",
    zone: "Africa/Accra",
    label: "Accra",
    timezone: "GMT+00:00",
  },
  {
    id: "91",
    zone: "Africa/Bissau",
    label: "Bissau",
    timezone: "GMT+00:00",
  },
  {
    id: "92",
    zone: "Africa/Casablanca",
    label: "Casablanca",
    timezone: "GMT+00:00",
  },
  {
    id: "93",
    zone: "Africa/El_Aaiun",
    label: "El Aaiun",
    timezone: "GMT+00:00",
  },
  {
    id: "94",
    zone: "Africa/Monrovia",
    label: "Monrovia",
    timezone: "GMT+00:00",
  },
  {
    id: "95",
    zone: "America/Danmarkshavn",
    label: "Danmarkshavn",
    timezone: "GMT+00:00",
  },
  {
    id: "96",
    zone: "Atlantic/Canary",
    label: "Canary Islands",
    timezone: "GMT+00:00",
  },
  {
    id: "97",
    zone: "Atlantic/Faroe",
    label: "Faeroe",
    timezone: "GMT+00:00",
  },
  {
    id: "98",
    zone: "Atlantic/Reykjavik",
    label: "Reykjavik",
    timezone: "GMT+00:00",
  },
  {
    id: "99",
    zone: "Etc/GMT",
    label: "GMT (no daylight saving)",
    timezone: "GMT+00:00",
  },
  {
    id: "100",
    zone: "Europe/Dublin",
    label: "Dublin",
    timezone: "GMT+00:00",
  },
  {
    id: "101",
    zone: "Europe/Lisbon",
    label: "Lisbon",
    timezone: "GMT+00:00",
  },
  {
    id: "102",
    zone: "Europe/London",
    label: "London",
    timezone: "GMT+00:00",
  },
  {
    id: "103",
    zone: "Africa/Algiers",
    label: "Algiers",
    timezone: "GMT+01:00",
  },
  {
    id: "104",
    zone: "Africa/Ceuta",
    label: "Ceuta",
    timezone: "GMT+01:00",
  },
  {
    id: "105",
    zone: "Africa/Lagos",
    label: "Lagos",
    timezone: "GMT+01:00",
  },
  {
    id: "106",
    zone: "Africa/Ndjamena",
    label: "Ndjamena",
    timezone: "GMT+01:00",
  },
  {
    id: "107",
    zone: "Africa/Tunis",
    label: "Tunis",
    timezone: "GMT+01:00",
  },
  {
    id: "108",
    zone: "Africa/Windhoek",
    label: "Windhoek",
    timezone: "GMT+02:00",
  },
  {
    id: "109",
    zone: "Europe/Amsterdam",
    label: "Amsterdam",
    timezone: "GMT+01:00",
  },
  {
    id: "110",
    zone: "Europe/Andorra",
    label: "Andorra",
    timezone: "GMT+01:00",
  },
  {
    id: "111",
    zone: "Europe/Belgrade",
    label: "Central European Time - Belgrade",
    timezone: "GMT+01:00",
  },
  {
    id: "112",
    zone: "Europe/Berlin",
    label: "Berlin",
    timezone: "GMT+01:00",
  },
  {
    id: "113",
    zone: "Europe/Brussels",
    label: "Brussels",
    timezone: "GMT+01:00",
  },
  {
    id: "114",
    zone: "Europe/Budapest",
    label: "Budapest",
    timezone: "GMT+01:00",
  },
  {
    id: "115",
    zone: "Europe/Copenhagen",
    label: "Copenhagen",
    timezone: "GMT+01:00",
  },
  {
    id: "116",
    zone: "Europe/Gibraltar",
    label: "Gibraltar",
    timezone: "GMT+01:00",
  },
  {
    id: "117",
    zone: "Europe/Luxembourg",
    label: "Luxembourg",
    timezone: "GMT+01:00",
  },
  {
    id: "118",
    zone: "Europe/Madrid",
    label: "Madrid",
    timezone: "GMT+01:00",
  },
  {
    id: "119",
    zone: "Europe/Malta",
    label: "Malta",
    timezone: "GMT+01:00",
  },
  {
    id: "120",
    zone: "Europe/Monaco",
    label: "Monaco",
    timezone: "GMT+01:00",
  },
  {
    id: "121",
    zone: "Europe/Oslo",
    label: "Oslo",
    timezone: "GMT+01:00",
  },
  {
    id: "122",
    zone: "Europe/Paris",
    label: "Paris",
    timezone: "GMT+01:00",
  },
  {
    id: "123",
    zone: "Europe/Prague",
    label: "Central European Time - Prague",
    timezone: "GMT+01:00",
  },
  {
    id: "124",
    zone: "Europe/Rome",
    label: "Rome",
    timezone: "GMT+01:00",
  },
  {
    id: "125",
    zone: "Europe/Stockholm",
    label: "Stockholm",
    timezone: "GMT+01:00",
  },
  {
    id: "126",
    zone: "Europe/Tirane",
    label: "Tirane",
    timezone: "GMT+01:00",
  },
  {
    id: "127",
    zone: "Europe/Vienna",
    label: "Vienna",
    timezone: "GMT+01:00",
  },
  {
    id: "128",
    zone: "Europe/Warsaw",
    label: "Warsaw",
    timezone: "GMT+01:00",
  },
  {
    id: "129",
    zone: "Europe/Zurich",
    label: "Zurich",
    timezone: "GMT+01:00",
  },
  {
    id: "130",
    zone: "Africa/Cairo",
    label: "Cairo",
    timezone: "GMT+02:00",
  },
  {
    id: "131",
    zone: "Africa/Johannesburg",
    label: "Johannesburg",
    timezone: "GMT+02:00",
  },
  {
    id: "132",
    zone: "Africa/Maputo",
    label: "Maputo",
    timezone: "GMT+02:00",
  },
  {
    id: "133",
    zone: "Africa/Tripoli",
    label: "Tripoli",
    timezone: "GMT+02:00",
  },
  {
    id: "134",
    zone: "Asia/Amman",
    label: "Amman",
    timezone: "GMT+02:00",
  },
  {
    id: "135",
    zone: "Asia/Beirut",
    label: "Beirut",
    timezone: "GMT+02:00",
  },
  {
    id: "136",
    zone: "Asia/Damascus",
    label: "Damascus",
    timezone: "GMT+02:00",
  },
  {
    id: "137",
    zone: "Asia/Gaza",
    label: "Gaza",
    timezone: "GMT+02:00",
  },
  {
    id: "138",
    zone: "Asia/Jerusalem",
    label: "Jerusalem",
    timezone: "GMT+02:00",
  },
  {
    id: "139",
    zone: "Asia/Nicosia",
    label: "Nicosia",
    timezone: "GMT+02:00",
  },
  {
    id: "140",
    zone: "Europe/Athens",
    label: "Athens",
    timezone: "GMT+02:00",
  },
  {
    id: "141",
    zone: "Europe/Bucharest",
    label: "Bucharest",
    timezone: "GMT+02:00",
  },
  {
    id: "142",
    zone: "Europe/Chisinau",
    label: "Chisinau",
    timezone: "GMT+02:00",
  },
  {
    id: "143",
    zone: "Europe/Helsinki",
    label: "Helsinki",
    timezone: "GMT+02:00",
  },
  {
    id: "144",
    zone: "Europe/Istanbul",
    label: "Istanbul",
    timezone: "GMT+02:00",
  },
  {
    id: "145",
    zone: "Europe/Kaliningrad",
    label: "Moscow-01 - Kaliningrad",
    timezone: "GMT+02:00",
  },
  {
    id: "146",
    zone: "Europe/Kiev",
    label: "Kiev",
    timezone: "GMT+02:00",
  },
  {
    id: "147",
    zone: "Europe/Riga",
    label: "Riga",
    timezone: "GMT+02:00",
  },
  {
    id: "148",
    zone: "Europe/Sofia",
    label: "Sofia",
    timezone: "GMT+02:00",
  },
  {
    id: "149",
    zone: "Europe/Tallinn",
    label: "Tallinn",
    timezone: "GMT+02:00",
  },
  {
    id: "150",
    zone: "Europe/Vilnius",
    label: "Vilnius",
    timezone: "GMT+02:00",
  },
  {
    id: "151",
    zone: "Africa/Khartoum",
    label: "Khartoum",
    timezone: "GMT+03:00",
  },
  {
    id: "152",
    zone: "Africa/Nairobi",
    label: "Nairobi",
    timezone: "GMT+03:00",
  },
  {
    id: "153",
    zone: "Antarctica/Syowa",
    label: "Syowa",
    timezone: "GMT+03:00",
  },
  {
    id: "154",
    zone: "Asia/Baghdad",
    label: "Baghdad",
    timezone: "GMT+03:00",
  },
  {
    id: "155",
    zone: "Asia/Qatar",
    label: "Qatar",
    timezone: "GMT+03:00",
  },
  {
    id: "156",
    zone: "Asia/Riyadh",
    label: "Riyadh",
    timezone: "GMT+03:00",
  },
  {
    id: "157",
    zone: "Europe/Minsk",
    label: "Minsk",
    timezone: "GMT+03:00",
  },
  {
    id: "158",
    zone: "Europe/Moscow",
    label: "Moscow+00 - Moscow",
    timezone: "GMT+03:00",
  },
  {
    id: "159",
    zone: "Asia/Tehran",
    label: "Tehran",
    timezone: "GMT+03:30",
  },
  {
    id: "160",
    zone: "Asia/Baku",
    label: "Baku",
    timezone: "GMT+04:00",
  },
  {
    id: "161",
    zone: "Asia/Dubai",
    label: "Dubai",
    timezone: "GMT+04:00",
  },
  {
    id: "162",
    zone: "Asia/Tbilisi",
    label: "Tbilisi",
    timezone: "GMT+04:00",
  },
  {
    id: "163",
    zone: "Asia/Yerevan",
    label: "Yerevan",
    timezone: "GMT+04:00",
  },
  {
    id: "164",
    zone: "Europe/Samara",
    label: "Moscow+01 - Samara",
    timezone: "GMT+04:00",
  },
  {
    id: "165",
    zone: "Indian/Mahe",
    label: "Mahe",
    timezone: "GMT+04:00",
  },
  {
    id: "166",
    zone: "Indian/Mauritius",
    label: "Mauritius",
    timezone: "GMT+04:00",
  },
  {
    id: "167",
    zone: "Indian/Reunion",
    label: "Reunion",
    timezone: "GMT+04:00",
  },
  {
    id: "168",
    zone: "Asia/Kabul",
    label: "Kabul",
    timezone: "GMT+04:30",
  },
  {
    id: "169",
    zone: "Antarctica/Mawson",
    label: "Mawson",
    timezone: "GMT+05:00",
  },
  {
    id: "170",
    zone: "Asia/Aqtau",
    label: "Aqtau",
    timezone: "GMT+05:00",
  },
  {
    id: "171",
    zone: "Asia/Aqtobe",
    label: "Aqtobe",
    timezone: "GMT+05:00",
  },
  {
    id: "172",
    zone: "Asia/Ashgabat",
    label: "Ashgabat",
    timezone: "GMT+05:00",
  },
  {
    id: "173",
    zone: "Asia/Dushanbe",
    label: "Dushanbe",
    timezone: "GMT+05:00",
  },
  {
    id: "174",
    zone: "Asia/Karachi",
    label: "Karachi",
    timezone: "GMT+05:00",
  },
  {
    id: "175",
    zone: "Asia/Tashkent",
    label: "Tashkent",
    timezone: "GMT+05:00",
  },
  {
    id: "176",
    zone: "Asia/Yekaterinburg",
    label: "Moscow+02 - Yekaterinburg",
    timezone: "GMT+05:00",
  },
  {
    id: "177",
    zone: "Indian/Kerguelen",
    label: "Kerguelen",
    timezone: "GMT+05:00",
  },
  {
    id: "178",
    zone: "Indian/Maldives",
    label: "Maldives",
    timezone: "GMT+05:00",
  },
  {
    id: "179",
    zone: "Asia/Calcutta",
    label: "India Standard Time",
    timezone: "GMT+05:30",
  },
  {
    id: "180",
    zone: "Asia/Colombo",
    label: "Colombo",
    timezone: "GMT+05:30",
  },
  {
    id: "181",
    zone: "Asia/Katmandu",
    label: "Katmandu",
    timezone: "GMT+05:45",
  },
  {
    id: "182",
    zone: "Antarctica/Vostok",
    label: "Vostok",
    timezone: "GMT+06:00",
  },
  {
    id: "183",
    zone: "Asia/Almaty",
    label: "Almaty",
    timezone: "GMT+06:00",
  },
  {
    id: "184",
    zone: "Asia/Bishkek",
    label: "Bishkek",
    timezone: "GMT+06:00",
  },
  {
    id: "185",
    zone: "Asia/Dhaka",
    label: "Dhaka",
    timezone: "GMT+06:00",
  },
  {
    id: "186",
    zone: "Asia/Omsk",
    label: "Moscow+03 - Omsk, Novosibirsk",
    timezone: "GMT+06:00",
  },
  {
    id: "187",
    zone: "Asia/Thimphu",
    label: "Thimphu",
    timezone: "GMT+06:00",
  },
  {
    id: "188",
    zone: "Indian/Chagos",
    label: "Chagos",
    timezone: "GMT+06:00",
  },
  {
    id: "189",
    zone: "Asia/Rangoon",
    label: "Rangoon",
    timezone: "GMT+06:30",
  },
  {
    id: "190",
    zone: "Indian/Cocos",
    label: "Cocos",
    timezone: "GMT+06:30",
  },
  {
    id: "191",
    zone: "Antarctica/Davis",
    label: "Davis",
    timezone: "GMT+07:00",
  },
  {
    id: "192",
    zone: "Asia/Bangkok",
    label: "Bangkok",
    timezone: "GMT+07:00",
  },
  {
    id: "193",
    zone: "Asia/Hovd",
    label: "Hovd",
    timezone: "GMT+07:00",
  },
  {
    id: "194",
    zone: "Asia/Jakarta",
    label: "Jakarta",
    timezone: "GMT+07:00",
  },
  {
    id: "195",
    zone: "Asia/Krasnoyarsk",
    label: "Moscow+04 - Krasnoyarsk",
    timezone: "GMT+07:00",
  },
  {
    id: "196",
    zone: "Asia/Saigon",
    label: "Hanoi",
    timezone: "GMT+07:00",
  },
  {
    id: "197",
    zone: "Asia/Ho_Chi_Minh",
    label: "Ho Chi Minh",
    timezone: "GMT+07:00",
  },
  {
    id: "198",
    zone: "Indian/Christmas",
    label: "Christmas",
    timezone: "GMT+07:00",
  },
  {
    id: "199",
    zone: "Antarctica/Casey",
    label: "Casey",
    timezone: "GMT+08:00",
  },
  {
    id: "200",
    zone: "Asia/Brunei",
    label: "Brunei",
    timezone: "GMT+08:00",
  },
  {
    id: "201",
    zone: "Asia/Choibalsan",
    label: "Choibalsan",
    timezone: "GMT+08:00",
  },
  {
    id: "202",
    zone: "Asia/Hong_Kong",
    label: "Hong Kong",
    timezone: "GMT+08:00",
  },
  {
    id: "203",
    zone: "Asia/Irkutsk",
    label: "Moscow+05 - Irkutsk",
    timezone: "GMT+08:00",
  },
  {
    id: "204",
    zone: "Asia/Kuala_Lumpur",
    label: "Kuala Lumpur",
    timezone: "GMT+08:00",
  },
  {
    id: "205",
    zone: "Asia/Macau",
    label: "Macau",
    timezone: "GMT+08:00",
  },
  {
    id: "206",
    zone: "Asia/Makassar",
    label: "Makassar",
    timezone: "GMT+08:00",
  },
  {
    id: "207",
    zone: "Asia/Manila",
    label: "Manila",
    timezone: "GMT+08:00",
  },
  {
    id: "208",
    zone: "Asia/Shanghai",
    label: "China Time - Beijing",
    timezone: "GMT+08:00",
  },
  {
    id: "209",
    zone: "Asia/Singapore",
    label: "Singapore",
    timezone: "GMT+08:00",
  },
  {
    id: "210",
    zone: "Asia/Taipei",
    label: "Taipei",
    timezone: "GMT+08:00",
  },
  {
    id: "211",
    zone: "Asia/Ulaanbaatar",
    label: "Ulaanbaatar",
    timezone: "GMT+08:00",
  },
  {
    id: "212",
    zone: "Australia/Perth",
    label: "Western Time - Perth",
    timezone: "GMT+08:00",
  },
  {
    id: "213",
    zone: "Asia/Pyongyang",
    label: "Pyongyang",
    timezone: "GMT+08:30",
  },
  {
    id: "214",
    zone: "Asia/Dili",
    label: "Dili",
    timezone: "GMT+09:00",
  },
  {
    id: "215",
    zone: "Asia/Jayapura",
    label: "Jayapura",
    timezone: "GMT+09:00",
  },
  {
    id: "216",
    zone: "Asia/Seoul",
    label: "Seoul",
    timezone: "GMT+09:00",
  },
  {
    id: "217",
    zone: "Asia/Tokyo",
    label: "Tokyo",
    timezone: "GMT+09:00",
  },
  {
    id: "218",
    zone: "Asia/Yakutsk",
    label: "Moscow+06 - Yakutsk",
    timezone: "GMT+09:00",
  },
  {
    id: "219",
    zone: "Pacific/Palau",
    label: "Palau",
    timezone: "GMT+09:00",
  },
  {
    id: "220",
    zone: "Australia/Adelaide",
    label: "Central Time - Adelaide",
    timezone: "GMT+10:30",
  },
  {
    id: "221",
    zone: "Australia/Darwin",
    label: "Central Time - Darwin",
    timezone: "GMT+09:30",
  },
  {
    id: "222",
    zone: "Antarctica/DumontDUrville",
    label: "Dumont D'Urville",
    timezone: "GMT+10:00",
  },
  {
    id: "223",
    zone: "Asia/Magadan",
    label: "Moscow+07 - Magadan",
    timezone: "GMT+10:00",
  },
  {
    id: "224",
    zone: "Asia/Vladivostok",
    label: "Moscow+07 - Yuzhno-Sakhalinsk",
    timezone: "GMT+10:00",
  },
  {
    id: "225",
    zone: "Australia/Brisbane",
    label: "Eastern Time - Brisbane",
    timezone: "GMT+10:00",
  },
  {
    id: "226",
    zone: "Australia/Hobart",
    label: "Eastern Time - Hobart",
    timezone: "GMT+11:00",
  },
  {
    id: "227",
    zone: "Australia/Sydney",
    label: "Eastern Time - Melbourne, Sydney",
    timezone: "GMT+11:00",
  },
  {
    id: "228",
    zone: "Pacific/Chuuk",
    label: "Truk",
    timezone: "GMT+10:00",
  },
  {
    id: "229",
    zone: "Pacific/Guam",
    label: "Guam",
    timezone: "GMT+10:00",
  },
  {
    id: "230",
    zone: "Pacific/Port_Moresby",
    label: "Port Moresby",
    timezone: "GMT+10:00",
  },
  {
    id: "231",
    zone: "Pacific/Efate",
    label: "Efate",
    timezone: "GMT+11:00",
  },
  {
    id: "232",
    zone: "Pacific/Guadalcanal",
    label: "Guadalcanal",
    timezone: "GMT+11:00",
  },
  {
    id: "233",
    zone: "Pacific/Kosrae",
    label: "Kosrae",
    timezone: "GMT+11:00",
  },
  {
    id: "234",
    zone: "Pacific/Norfolk",
    label: "Norfolk",
    timezone: "GMT+11:00",
  },
  {
    id: "235",
    zone: "Pacific/Noumea",
    label: "Noumea",
    timezone: "GMT+11:00",
  },
  {
    id: "236",
    zone: "Pacific/Pohnpei",
    label: "Ponape",
    timezone: "GMT+11:00",
  },
  {
    id: "237",
    zone: "Asia/Kamchatka",
    label: "Moscow+09 - Petropavlovsk-Kamchatskiy",
    timezone: "GMT+12:00",
  },
  {
    id: "238",
    zone: "Pacific/Auckland",
    label: "Auckland",
    timezone: "GMT+13:00",
  },
  {
    id: "239",
    zone: "Pacific/Fiji",
    label: "Fiji",
    timezone: "GMT+13:00",
  },
  {
    id: "240",
    zone: "Pacific/Funafuti",
    label: "Funafuti",
    timezone: "GMT+12:00",
  },
  {
    id: "241",
    zone: "Pacific/Kwajalein",
    label: "Kwajalein",
    timezone: "GMT+12:00",
  },
  {
    id: "242",
    zone: "Pacific/Majuro",
    label: "Majuro",
    timezone: "GMT+12:00",
  },
  {
    id: "243",
    zone: "Pacific/Nauru",
    label: "Nauru",
    timezone: "GMT+12:00",
  },
  {
    id: "244",
    zone: "Pacific/Tarawa",
    label: "Tarawa",
    timezone: "GMT+12:00",
  },
  {
    id: "245",
    zone: "Pacific/Wake",
    label: "Wake",
    timezone: "GMT+12:00",
  },
  {
    id: "246",
    zone: "Pacific/Wallis",
    label: "Wallis",
    timezone: "GMT+12:00",
  },
  {
    id: "247",
    zone: "Pacific/Apia",
    label: "Apia",
    timezone: "GMT+14:00",
  },
  {
    id: "248",
    zone: "Pacific/Enderbury",
    label: "Enderbury",
    timezone: "GMT+13:00",
  },
  {
    id: "249",
    zone: "Pacific/Fakaofo",
    label: "Fakaofo",
    timezone: "GMT+13:00",
  },
  {
    id: "250",
    zone: "Pacific/Tongatapu",
    label: "Tongatapu",
    timezone: "GMT+13:00",
  },
  {
    id: "251",
    zone: "Pacific/Kiritimati",
    label: "Kiritimati",
    timezone: "GMT+14:00",
  },
];
