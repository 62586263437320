import React from "react";
import update from "immutability-helper";
import { AddOutlined, AssessmentAddOutlined } from "@toddle-design/web-icons";
import { LE_ASSESSMENT_TYPE_MAPPING } from "Constants";

export const getLECreationRevampTemplate = _.memoize(
  params => _getLECreationRevampTemplate(params),
  params => JSON.stringify(params)
);

const EDIT_STEPS_TO_ADD = ["resourcesStep", "studentTemplate"];
const IMPLEMENTATION_FIELDS_TO_REMOVE = ["plannerResources", "studentTemplate"];
const FIELDS_TO_UPDATE = [
  "title",
  "image",
  "duration",
  "measureAssessing",
  "plannerResources",
  "studentTemplate",
  "score",
];
const STEPS_TO_UPDATE = [
  "basicV2",
  "assessmentTool",
  "resourcesStep",
  "implementation",
  "studentTemplate",
  "submissionTemplate",
  "pypElements",
  "teacherNotes",
];

const getStepsToAddToQt = ({ t }) => {
  return {
    studentTemplate: {
      fields: ["studentTemplate"],
      label: t("unitPlanTemplate:le_studentTemplate_classroom_label"),
      subText: t("classRoom:student_template_text"),
    },
  };
};

// steps for which text needs to be updated
const UPDATE_TEXT_STEPS = ["resourcesStep", "studentTemplate"];

const IMAGE_CONFIG = {
  fieldTypeConfig: {
    buttonSize: "small",
    noDataCenterContainerStyle: {
      padding: "8px 0",
    },
    uploadBoxStyles: {
      height: "204px",
    },
    buttonWrapperStyle: {
      gridTemplateColumns: "1fr",
      gridGap: "8px",
    },
    customDropZoneText: "common:drag_and_drop_image",
    dropZoneTextStyle: {
      fontSize: "1.6rem",
    },
  },
  styles: {
    style: {
      width: "272px",
    },
  },
  shouldRenderOnLeft: true,
};

const fieldTypeConfig = {
  resources: {
    cardType: "design-media-card",
    showImageDifferent: false,
    attachmentContainerStyle: {
      gridTemplateColumns: "1fr",
      marginBottom: "24px",
    },
  },
  studentTemplate: {
    cardType: "design-media-card",
    attachmentContainerStyle: {
      gridTemplateColumns: "1fr",
    },
  },
};

const styles = {
  measureAssessing: {
    width: "100%",
    flexDirection: "row",
    gap: "16px",
  },
  duration: {
    width: "100%",
  },
};

const getExtraMeasureAssessingFields = ({ t, tool }) => {
  return {
    isSubMenu: true,
    key: tool,
    options: [
      {
        key: "create_new",
        label: t("common:create_with_label", {
          label: t("common:new"),
        }),
        icon: "AddOutlined",
      },
      {
        key: "use_from_template",
        label: t("common:select_a_with_label", {
          label: t("common:template"),
        }),
        icon: "AssessmentAddOutlined",
      },
    ],
  };
};

const getNewMeasureAssessingOptions = ({ t, options }) => {
  // remove NONE option
  const updatedOptions = _.filter(options, ({ value }) => value !== "");

  return _.map(updatedOptions, option => {
    const { value } = option;
    switch (value) {
      case "RUBRIC":
      case "CHECKLIST":
      case "SINGLE_POINT_RUBRIC":
        return {
          ...option,
          ...getExtraMeasureAssessingFields({ t, tool: value }),
        };
      default:
        return { ...option, key: option?.value };
    }
  });
};

const updateSteps = params => {
  const { step, template, curriculumType, assessmentType } = params;

  const steps = _.get(template, step, []);
  const implementationIndex = _.indexOf(steps, "implementation");
  const resourcesIndex = _.indexOf(steps, "resourcesStep");
  const newClassroomSteps = _.difference(EDIT_STEPS_TO_ADD, steps);

  // in dp (fmt, le, smt) templates studentTemplate is called submissionTemplate
  if (curriculumType === "IB_DP" && assessmentType !== "qt") {
    // remove submissionTemplate and assessmentTool from steps
    let filteredSteps = _.filter(
      steps,
      step => !_.includes(["submissionTemplate", "assessmentTool"], step)
    );

    // get index of resources
    const resourcesIndex = _.indexOf(filteredSteps, "resourcesStep");

    // add submissionTemplate and assessmentTool after resources step
    filteredSteps = update(filteredSteps, {
      $splice: [
        [resourcesIndex + 1, 0, "submissionTemplate", "assessmentTool"],
      ],
    });

    // set updated steps in template and return
    return update(template, {
      [`${step}`]: {
        $set: filteredSteps,
      },
    });
  }

  const stepIndex = resourcesIndex > 0 ? resourcesIndex : implementationIndex;

  // add studentTemplate, resourcesStep to steps
  const updatedTemplate = update(template, {
    [`${step}`]: {
      $splice: [[stepIndex + 1, 0, ...newClassroomSteps]],
    },
  });

  return updatedTemplate;
};

const _getLECreationRevampTemplate = params => {
  const {
    t,
    template,
    userPreference = [],
    assessmentType,
    isFromClassroom,
    curriculumType,
  } = params;

  if (_.isEmpty(template)) return;

  let newTemplate = _.cloneDeep(template);
  let shouldAddLearningIntentions = false;
  let classroomSteps = [];

  if (isFromClassroom) {
    classroomSteps = _.get(newTemplate, "classroomEditSteps", []);

    newTemplate = updateSteps({
      template: newTemplate,
      step: "classroomEditSteps",
      curriculumType,
      assessmentType,
    });
  } else {
    classroomSteps = _.get(newTemplate, "steps", []);

    newTemplate = updateSteps({
      template: newTemplate,
      step: "steps",
      curriculumType,
      assessmentType,
    });
  }

  // update field_list
  _.forEach(FIELDS_TO_UPDATE, field => {
    const currentField = newTemplate.field_list[field];
    if (currentField) {
      switch (field) {
        case "title":
          currentField.config.label = t("common:title");
          break;
        case "image":
          currentField.config = IMAGE_CONFIG;
          break;
        case "duration":
          currentField.config.styles.style = styles.duration;
          currentField.config.label = t("common:duration");
          break;
        case "measureAssessing":
          {
            const config = currentField.config;
            config.label = "Select assessment tool";
            config.styles.style = styles.measureAssessing;
            config.options = getNewMeasureAssessingOptions({
              t,
              options: config.options,
            });
          }
          break;
        case "plannerResources":
          {
            currentField.config = _.omit(currentField.config, "label");
            currentField.config.fieldTypeConfig = fieldTypeConfig.resources;
          }
          break;
        case "studentTemplate":
          {
            currentField.config = _.omit(currentField.config, [
              "label",
              "subtext",
            ]);
            currentField.config.fieldTypeConfig =
              fieldTypeConfig.studentTemplate;
          }
          break;
        case "score": {
          currentField.config.label = "Score";
        }
      }
    }
  });

  // update step_list
  _.forEach(STEPS_TO_UPDATE, step => {
    const currentStep = newTemplate.step_list[step];
    if (currentStep) {
      switch (step) {
        case "basicV2":
          {
            const fields = _.get(currentStep, "fields");
            let filteredFields = [];
            if (_.includes(fields, "goals")) {
              // if template has learning intentions
              shouldAddLearningIntentions = true;
              filteredFields = _.filter(
                fields,
                field => !_.includes(["image", "goals"], field)
              );
            } else {
              filteredFields = _.filter(fields, field => field !== "image");
            }
            filteredFields?.unshift("image");
            currentStep.fields = filteredFields;
          }
          break;
        case "implementation":
          {
            const fields = currentStep.fields;
            const filteredFields = _.filter(
              fields,
              field => !_.includes(IMPLEMENTATION_FIELDS_TO_REMOVE, field)
            );
            currentStep.fields = filteredFields;
            currentStep.label = t("unitPlanTemplate:le_implementation_label");
          }
          break;
        case "assessmentTool":
          currentStep.label = t("common:le_assessmentTool_label");
          currentStep.viewLabel = t("common:le_assessmentTool_label");
          break;
        case "studentTemplate":
        case "submissionTemplate":
          currentStep.subText = t("classRoom:student_template_text");
          break;
        case "pypElements":
          currentStep.subText = t("unitPlanTemplate:le_pypElements_iconText");
          break;
        case "teacherNotes":
          currentStep.subtext = t("classRoom:teacher_notes_subtext");
      }
    }
  });

  const assessmentTypeLabel =
    LE_ASSESSMENT_TYPE_MAPPING[assessmentType]?.localeLabel;

  // update text for different assessment types

  if (assessmentType) {
    const fieldToUpdate = "description";
    const currentField = newTemplate.field_list[fieldToUpdate];

    currentField.config.label = t("common:capitalize", {
      text: t("classRoom:instructions_with_label", {
        label: t(assessmentTypeLabel),
      }),
    });

    currentField.config.subtext = t(
      "classRoom:assessment_description_subtext",
      {
        label: t(assessmentTypeLabel),
      }
    );

    if (_.isEqual(assessmentType, "qt")) {
      newTemplate.step_list = {
        ...newTemplate.step_list,
        ...getStepsToAddToQt({ t }),
      };
    }
  }

  if (shouldAddLearningIntentions) {
    const basicV2Index = _.indexOf(classroomSteps, "basicV2");
    newTemplate = update(newTemplate, {
      classroomEditSteps: {
        $splice: [[basicV2Index + 1, 0, "goals"]],
      },
    });

    const goalsField = newTemplate.field_list["goals"];
    goalsField.config = _.omit(goalsField.config, "label");
  }

  // if (!_.isEmpty(userPreference)) {
  //   const userPreferenceArr = Object.entries(userPreference).filter(
  //     ([key]) => key !== "basicV2"
  //   );
  //   _.forEach(userPreferenceArr, pref => {
  //     const [key, value] = pref;
  //     const currentStep = newTemplate.step_list[key];
  //     if (!_.isEmpty(value)) {
  //       currentStep.fields = value;
  //     } else {
  //       const classroomEditSteps = newTemplate.classroomEditSteps;
  //       newTemplate.classroomEditSteps = _.filter(
  //         classroomEditSteps,

  //         step => step !== key
  //       );
  //     }
  //   });
  // }

  return newTemplate;
};

export const getIconFromTemplate = _.memoize(
  params => _getIconFromTemplate(params),
  params => JSON.stringify(params)
);

const _getIconFromTemplate = icon => {
  switch (icon) {
    case "AddOutlined":
      return <AddOutlined />;
    case "AssessmentAddOutlined":
      return <AssessmentAddOutlined />;
    default:
      return;
  }
};
