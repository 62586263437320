import React from "react";
import classes from "./SearchSelectedItem.scss";
import { ProfileCascade } from "UIComponents";
import { CancelIcon } from "SvgComponents";

const SearchSelectedItem = ({ removeOption, item, disabled, styles }) => {
  const { id, firstName, lastName, profileImage, email } = item;
  const name = !!firstName ? `${firstName} ${lastName}` : email;

  const { containerStyle, labelStyle, profileCascadeStyle, iconSize } = styles;

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.imageNameContainer}>
        <ProfileCascade
          items={[{ id, value: firstName, imageUrl: profileImage }]}
          customStyle={profileCascadeStyle}
        />
        <div className={classes.nameText} style={labelStyle}>
          {name}
        </div>
      </div>
      {!disabled && (
        <div
          className={classes.removeButton}
          onClick={event => removeOption(item, event)}
        >
          <CancelIcon width={iconSize} height={iconSize} />
        </div>
      )}
    </div>
  );
};

export default SearchSelectedItem;

SearchSelectedItem.defaultProps = {
  styles: {
    profileCascadeStyle: {
      borderWidth: 0,
      width: 28,
      height: 28,
    },
    iconSize: 8,
  },
};
