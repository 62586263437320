import React, { memo } from "react";
import classes from "./CodeCell.scss";
import { useI18n } from "Hooks";

const CELL_NAME = "code";

const CodeCell = ({
  onContentEdit,
  code,
  mode,
  onBulkPasteRows,
  updateDraftNode,
}) => {
  const { t } = useI18n(["common", "scopeAndSequence"]);

  const handleContentEdit = event => {
    event.stopPropagation();
    const updatedCode = event.currentTarget.textContent;
    const prevCode = code;

    if (updatedCode !== prevCode) {
      const params = {
        code: updatedCode,
      };
      onContentEdit({ params });
    }
  };

  const isCodeEmpty = _.isEmpty(code);

  const placeholderText = t("common:enter_with_label", {
    label: t("scopeAndSequence:code"),
  });

  const handleOnPaste = event => {
    const pastedData = event.clipboardData.getData("Text");
    const itemsList = pastedData.split(/\r?\n/); // regex spitting the excel sheet data into a list;
    const itemsListLength = _.size(itemsList);
    if (itemsListLength > 1) {
      // the list will be pasted in row format
      event.preventDefault();
      onBulkPasteRows({
        pastedData,
        cellName: CELL_NAME,
        isNewRow: false,
        itemsList,
      });
    }
  };

  const handleOnInput = event => {
    const updatedLabel = event.currentTarget.textContent;
    updateDraftNode({ cellName: CELL_NAME, labelValue: updatedLabel });
  };

  return (
    <div
      className={classes.codeContainer}
      contentEditable={mode === "edit"}
      onBlur={handleContentEdit}
      suppressContentEditableWarning={true}
      placeholdertext={isCodeEmpty ? placeholderText : ""}
      dangerouslySetInnerHTML={{
        __html: isCodeEmpty ? (mode === "view" ? "--" : "") : code,
      }}
      onPasteCapture={handleOnPaste}
      onInput={handleOnInput}
    />
  );
};

export default memo(CodeCell);
