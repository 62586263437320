export const filterList = (filterInputList, searchTerm, keysToFilter) => {
  const filteredList = _.filter(filterInputList, function (listItem) {
    let combinedItem = "";
    keysToFilter.map(keyItem => {
      combinedItem = combinedItem.concat(_.get(listItem, keyItem, ""));
    });

    if (
      combinedItem
        .replace(/\s/g, "")
        .toLowerCase()
        .includes(searchTerm.replace(/\s/g, "").toLowerCase())
    )
      return listItem;
    else return null;
  });
  return _.compact(filteredList);
};

export const getFilterObject = ({ searchText, grades = {} }) => {
  const gradeIds = _.map(grades, grade => grade.id);

  return {
    searchText,
    grades: gradeIds,
    archivalState: "ALL",
  };
};
