import React, { useState, useEffect, Fragment } from "react";
import { UIButton, I18nHOC, withLoader, Loading } from "UIComponents";
import classes from "./AddLE.scss";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { getCommunityCommonFeedQuery } from "CommunityParentEntity/modules/CommunityParentEntityQuery";
import { getCommunityCommonFeedFromCache } from "CommunityParentEntity/modules/CommunityParentEntityGraphqlHelpers";
import * as EventTracker from "lib/eventTracker";
import ACLStore from "lib/aclStore";
import { getUserInfo } from "Login/modules/LoginModule";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import { getRethumbUrl } from "Utils";
import DropDown from "../DropDown";
import { AssessmentSelectModal, CoachMarks } from "AppComponents";
import update from "immutability-helper";
import {
  importAssessment,
  createAssessment,
} from "Assessments/modules/AssessmentModule";
import { checkCommunityActiveStatusAndGoToOnboarding } from "Community/modules/CommunityModule";
import { JoinCommunityModal } from "AppComponents/Community";
import { LeSearchModal } from "Community/components/commonComponents";
import { AddCircleIcon } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";
import { ButtonDisclosure } from "@toddle-design/web";
import { AddOutlined } from "@toddle-design/web-icons";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { hasIncludeGivenPathInCurrentRoute } from "IBPlanner/modules/IBPlannerModule";
import ImportResourceModal from "AppComponents/ImportResourceModal";

const typeNames = {
  ASSESSMENT: {
    label: "common:assessment_label_plural",
    iconColor: colors.salmon60,
  },
  LEARNING_ENGAGEMENT: {
    label: "common:le_label_plural",
    iconColor: colors.violet63,
  },
  SECOND_STAGE_ASSESSMENT: {
    label: "common:stage_2_assessment_plural",
    iconColor: colors.salmon60,
  },
  THIRD_STAGE_ASSESSMENT: {
    label: "common:stage_3_assessment_plural",
    iconColor: colors.orange54,
  },
};

const buttonStyle = {
  container: {
    minWidth: "252px",
  },
  label: {
    fontSize: "1.4rem",
  },
};

let eventData = {};

const getImportFromUnitData = ({ t }) => {
  const steps = [
    {
      value: "UNIT_SELECTION",
      label: t("classRoom:import_unit"),
      config: {
        heading: t("classRoom:import_unit"),
        actionButtons: [
          {
            label: "common:cancel",
            value: "cancel",
          },
          {
            label: "common:next",
            value: "next",
          },
        ],
      },
    },
    {
      value: "LE_SELECTION",
      label: t("common:select_learning"),
      config: {
        heading: t("classRoom:import_unit"),
        isMultiSelect: false,
        showLEPreview: true,
        actionButtons: [
          {
            label: "common:cancel",
            value: "cancel",
          },
          {
            label: "common:add",
            value: "add",
          },
        ],
      },
    },
  ];
  const createHeader = t("classRoom:import_unit");

  return { steps, createHeader };
};

const LEDropdownButton = ({
  size,
  assessmentTypeGroup,
  groupType,
  showLoadingInButton,
  styles,
  isLoading,
  showAddIcon,
  t,
  createText,
  isActivated,
  //className,
  onClick, // used internally for triggerring dropdown component
}) => {
  if (size == "small") {
    const typeLabel =
      typeNames[assessmentTypeGroup ? assessmentTypeGroup : groupType].label;

    const titleTextClasses = classNames(classes.titleText, "heading-5");
    return (
      <div className={classes.headerContainer}>
        <div className={titleTextClasses}>{t(typeLabel)}</div>
        <ButtonDisclosure
          onClick={onClick}
          className={classes.addButton}
          size={"large"}
          variant="outlined-subtle"
          icon={<AddOutlined />}
          isActivated={isActivated}
        >
          {t("common:new")}
        </ButtonDisclosure>
      </div>
    );
  } else {
    return (
      <div
        className={classes.createTextContainerSmall}
        style={{ ...(styles.createTextContainerSmall || {}) }}
        id="COACHMARKS_IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN"
      >
        <div className={classes.addSvg}>
          {showLoadingInButton && isLoading ? (
            <Loading />
          ) : showAddIcon ? (
            <AddCircleIcon height={16} width={16} fill={colors.blue29} />
          ) : null}
        </div>
        {!isLoading ? (
          <div className={classes.createTextSmall}>{createText}</div>
        ) : null}
      </div>
    );
  }
};

const AddLE = ({
  t,
  images = [],
  readOnly,
  list,
  label,
  unitPlanId,
  importAssessment,
  createAssessment,
  setLoadingState,
  isLoading,
  showDropMessage,
  isDraggingOver,
  onSearchOnCommunitySuccess,
  onCreateNewLESuccess,
  onImportFromUnitSuccess,
  styles = {},
  showLoadingInButton,
  showAddIcon,
  eventTarget,
  showCoachmark,
  size,
  groupType,
  curriculumType,
  assessmentTypeGroup,
  assessmentTypes,
  unitPlanSelectionProps,
}) => {
  const [showAssessmentSelectModal, setShowAssessmentSelectModal] = useState(
    false
  );
  const [resourceItems, setResourceItems] = useState([]);
  const [showJoinCommunityModal, setShowJoinCommunityModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [importFromLeLibrary, setImportFromLeLibrary] = useState(false);

  useEffect(() => {
    if (isImportFromUnitRevamp && !_.isEmpty(resourceItems)) {
      onImportFromUnitClick();
    }
  }, [resourceItems]);

  const listLength = _.get(list, "length", 0);

  const assessmentType = {
    CREATE: "le",
    CREATE_FMT_ASSESSMENT: "fmt",
    CREATE_PT_ASSESSMENT: "pt",
    CREATE_PRI_ASSESSMENT: "pri",
    CREATE_SMT_ASSESSMENT: "smt",
  };

  const onItemClick = async value => {
    switch (value) {
      case "SEARCH_COMMUNITY":
        onSearchOnCommunityClick();
        break;
      case "SEARCH_LE_LIBRARY":
        setImportFromLeLibrary(true);
        setShowSearchModal(true);
        break;
      case "IMPORT_FROM_UNITS":
        setShowAssessmentSelectModal(true);
        setResourceItems([]);
        break;
      default:
        onCreateNewClicked({ assessmentType: assessmentType[value] });
    }
  };

  const onLeSearchModalClose = () => {
    setShowSearchModal(false);
    setImportFromLeLibrary(false);
  };

  const onCreateNewClicked = async ({ assessmentType = "le" } = {}) => {
    if (isLoading) return;
    setLoadingState(true, loadingProperty);
    const loadingProperty = "FULLSCREEN_LOADING";
    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const unitPlanAcademicYearsIds = _.map(
      _.get(unitPlanDetails, "academicYears", []),
      item => item.id
    );
    const id = await createAssessment({
      assessmentType,
      groupType,
      academicYears: unitPlanAcademicYearsIds,
    });
    setLoadingState(false, loadingProperty);
    if (onCreateNewLESuccess) onCreateNewLESuccess({ id });
  };

  // Search Community
  const onSearchOnCommunityClick = async ({ target } = {}) => {
    eventData = {
      target: target ? target : eventTarget,
      entity_id: unitPlanId,
      entity_type: "UNIT_PLAN",
      source: "community",
    };
    EventTracker.recordEvent({
      eventName: "Clicked search on community",
      eventData,
    });
    setLoadingState(true, "FULLSCREEN_LOADING");
    const isActiveCommunity = await checkCommunityActiveStatusAndGoToOnboarding(
      {
        isForced: true,
        openOnBoarding: false,
        isNetworkOnly: true,
      }
    );
    setLoadingState(false, "FULLSCREEN_LOADING");
    if (!isActiveCommunity) {
      setShowJoinCommunityModal(true);
    } else {
      setShowSearchModal(true);
      eventData = {};
    }
  };

  const onCoachmarkImportLeCommunityClick = params => {
    const { isDone } = params;
    if (isDone) {
      onSearchOnCommunityClick({ target: "coachmark" });
    }
  };

  const onCloseJoinCommunityModal = () => {
    setShowJoinCommunityModal(false);
    eventData = {};
  };

  // Import from units
  const onImportFromUnitClick = async () => {
    setLoadingState(true, "FULLSCREEN_LOADING");
    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const unitPlanAcademicYearsIds = _.map(
      _.get(unitPlanDetails, "academicYears", []),
      item => item.id
    );

    const importId = await importAssessment({
      sourceAssessmentId: _.get(resourceItems, ["selectedAssessments", 0], ""),
      assessmentTypes,
      academicYears: unitPlanAcademicYearsIds,
    });

    setLoadingState(false, "FULLSCREEN_LOADING");
    if (importId) setShowAssessmentSelectModal(false);

    if (onImportFromUnitSuccess) onImportFromUnitSuccess(importId);
  };

  const updateResourceItems = ({ key, value } = {}) => {
    setResourceItems(
      update(resourceItems, {
        [key]: { $set: value },
      })
    );
  };

  const renderButtonComponent = ({ singleButton = false } = {}) => {
    const createText = singleButton
      ? t("common:create_new_with_label", {
          label,
        })
      : t("common:add_a_with_label", {
          label,
        });

    return (
      <LEDropdownButton
        size={size}
        assessmentTypeGroup={assessmentTypeGroup}
        groupType={groupType}
        showLoadingInButton={showLoadingInButton}
        styles={styles}
        isLoading={isLoading}
        showAddIcon={showAddIcon}
        t={t}
        createText={createText}
      />
    );
  };

  const showCommunitySearch = ACLStore.can("TeacherPortal:Community");
  const isImportFromUnitRevamp = ACLStore.can(
    "FeatureFlag:ImportFromUnitRevamp"
  );

  const containerClasses = classNames(
    { [classes.container]: true },
    { [classes.dropContainer]: isDraggingOver }
  );

  if (readOnly) return;

  const { steps, createHeader } = getImportFromUnitData({ t });

  const createItemMutation = selectedAssessmentId => {
    setResourceItems({
      selectedAssessments: [selectedAssessmentId],
    });
  };

  return (
    <Fragment>
      {showCoachmark && size !== "small" && (
        <CoachMarks
          type={"IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN"}
          continuous={true}
          params={{ target: "#COACHMARKS_IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN" }}
          isTransparentOverlay={true}
          onStepChanged={onCoachmarkImportLeCommunityClick}
          disableScrolling={false}
          disableScrollParentFix={false}
          disableOverlay={true}
        />
      )}
      {listLength || size == "small" ? (
        <DropDown
          size={size}
          curriculumType={curriculumType}
          renderButtonComponent={renderButtonComponent}
          onItemClick={onItemClick}
          listLength={listLength}
          label={label}
          styles={styles}
          groupType={groupType}
          assessmentTypeGroup={assessmentTypeGroup}
        />
      ) : (
        <div
          className={containerClasses}
          style={{ ...(styles.container || {}) }}
          id="COACHMARKS_IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN"
        >
          {showDropMessage && isDraggingOver && (
            <div className={classes.dropText}>
              {t("unitPlan:drop_respource_msg")}
            </div>
          )}
          <div
            className={classes.wrapper}
            style={{
              opacity: showDropMessage && isDraggingOver ? 0 : 1,
            }}
          >
            <div className={classes.header} style={{ ...styles.header }}>
              {t("common:add_with_label", {
                label: t("community:learning_experience"),
              })}
            </div>

            {showDropMessage && (
              <Fragment>
                <div
                  className={classes.subText}
                  style={{ marginBottom: 0, ...styles.subText }}
                >
                  {t("unitPlan:add_le_dragging")}
                </div>
                <div className={classes.divider}>
                  <span>{t("common:or")}</span>
                </div>
              </Fragment>
            )}

            {showCommunitySearch && (
              <Fragment>
                <div
                  className={classes.subText}
                  style={{ ...(styles.subText || {}) }}
                >
                  {t("common:discover_le_on_community")}
                </div>
                <div className={classes.previewContainer}>
                  {_.map(images, ({ id, value }) => {
                    const imageUrl = getRethumbUrl({
                      width: 100,
                      height: 100,
                      imageUrl: value || RESOURCE_PLACEHOLDER_URL,
                      fitIn: true,
                    });
                    return (
                      <div
                        key={id}
                        className={classes.previewItem}
                        style={{ background: `url(${imageUrl})` }}
                      ></div>
                    );
                  })}
                </div>

                <UIButton
                  containerStyle={buttonStyle.container}
                  color="cannonPink"
                  onClick={() => onItemClick("SEARCH_COMMUNITY")}
                >
                  {t("community:search_on_toddle_community")}
                </UIButton>
                <div className={classes.divider}>
                  <span>{t("common:or")}</span>
                </div>
              </Fragment>
            )}

            <div
              className={classes.buttonGroup}
              id={"COACHMARKS_UNIT_FLOW_CREATE_BUTTON"}
            >
              <UIButton
                type="hollow"
                containerStyle={buttonStyle.container}
                color="blue"
                onClick={() => onItemClick("CREATE")}
              >
                {t("common:create_new_with_label", {
                  label: t("community:learning_experience"),
                })}
              </UIButton>
              <UIButton
                type="hollow"
                containerStyle={buttonStyle.container}
                color="blue"
                onClick={() => onItemClick("IMPORT_FROM_UNITS")}
              >
                {t("common:import_from_unit")}
              </UIButton>
            </div>
          </div>
        </div>
      )}
      {showAssessmentSelectModal ? (
        isImportFromUnitRevamp ? (
          <ImportResourceModal
            isFullScreen={true}
            steps={steps}
            mode={"create"}
            createHeader={createHeader}
            onClose={() => setShowAssessmentSelectModal(false)}
            createItemMutation={createItemMutation}
          />
        ) : (
          <AssessmentSelectModal
            onClose={() => setShowAssessmentSelectModal(false)}
            mode={"ADD"}
            updateResourceItems={updateResourceItems}
            onClickAdd={onImportFromUnitClick}
            steps={[1, 2]}
            type={"IMPORT"}
            resourceItems={resourceItems}
            excludeUnitPlanIds={[unitPlanId]}
            groupType={groupType}
            unitPlanSelectionProps={unitPlanSelectionProps}
            emptySubTitle={"unitPlan:create_unit_for_course"}
          />
        )
      ) : null}

      {showJoinCommunityModal && (
        <JoinCommunityModal
          onClose={onCloseJoinCommunityModal}
          eventData={eventData}
        />
      )}

      {showSearchModal && (
        <LeSearchModal
          onLeSearchModalClose={onLeSearchModalClose}
          portalType={importFromLeLibrary ? "PLANNER" : "COMMUNITY"}
          importFromLeLibrary={importFromLeLibrary}
          unitPlanId={unitPlanId}
          onSuccess={onSearchOnCommunitySuccess}
          eventTarget={eventTarget}
          assessmentTypes={assessmentTypes}
          groupType={groupType}
        />
      )}
    </Fragment>
  );
};

AddLE.defaultProps = {
  setLoadingState: () => {},
  showDropMessage: false,
  isDraggingOver: false,
  isLoading: false,
  showLoadingInButton: false,
  showAddIcon: true,
  showCoachmark: false,
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });
  const userId = userInfo.id;
  const unitPlanSelectionProps = {
    parentType: "COURSE",
  };
  if (hasIncludeGivenPathInCurrentRoute({ path: "/adminunitplans" })) {
    const adminUnitPlanFilters = _.get(state, "adminUnitPlans.filters", {});
    unitPlanSelectionProps.parentType = "ORGANIZATION";
    unitPlanSelectionProps.grades = adminUnitPlanFilters.grades;
    unitPlanSelectionProps.academicYears = adminUnitPlanFilters.academicYears;
    unitPlanSelectionProps.parentId = userInfo.org_id;
  }
  return {
    userId,
    isData: true,
    isLoading: false,
    queryVariables: {
      portalType: ownProps.portalType,
      id: userId,
      entityTypes: "ASSESSMENT",
      first: 8,
      seed: 0.52,
      filters: {},
    },
    unitPlanSelectionProps,
  };
};

const mapActionCreators = {
  importAssessment,
  createAssessment,
  checkCommunityActiveStatusAndGoToOnboarding,
};

export default compose(
  I18nHOC({ resource: ["community", "common", "unitPlan"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCommunityCommonFeedQuery, {
    name: "getCommunityCommonFeed",
    skip: ({ size }) => size == "small", // TODO: Need to improve this condition
    options: ({ queryVariables }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: queryVariables,
      };
    },
    props({ getCommunityCommonFeed, ownProps: { queryVariables } }) {
      const entityObject = _.get(
        getCommunityCommonFeed,
        "node.communityEntities",
        {}
      );
      const data = getCommunityCommonFeedFromCache(queryVariables);
      const images = _.map(
        _.get(data, "node.communityEntities.edges"),
        "node.image"
      );
      return {
        images,
        getCommunityCommonFeed,
        feedList: _.get(entityObject, "edges", []),
        isData: !_.isEmpty(data),
        networkStatus: getCommunityCommonFeed.networkStatus,
        isLoading:
          getCommunityCommonFeed["networkStatus"] == 1 ||
          getCommunityCommonFeed["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(AddLE);
