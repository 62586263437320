import React, { useState, useEffect, memo } from "react";
import classes from "./EventBody.scss";
import {
  ProfileCascade,
  AnimateHeight,
  DateSelector,
  TimeSelectorV2,
} from "UIComponents";
import { LinkIconSvg, BottomArrow } from "SvgComponents";
import classNames from "classnames";
import { getStringAutoLinks } from "Utils";
import { colors } from "Constants";
import { useMount } from "Utils/CustomHooks";
import moment from "moment";
import {
  START_PICKER_START_TIME,
  START_PICKER_END_TIME,
  END_PICKER_END_TIME,
} from "MyCalendar/modules/Utils";
import { fontStyle } from "Constants";
import ACLStore from "lib/aclStore";

const styles = {
  extendedModalStyle: {
    flex: "unset",
    minHeight: "400px",
  },
  timeSelectorDropdownContainerStyle: {
    top: "-11px",
    left: "-1px",
    width: "128px",
    position: "absolute",
  },
  timeSelectorDropdownItemStyle: {
    display: "flex",
    width: "100%",
  },
  timeSelectorInputStyle: {
    border: "none",
    padding: "4px 8px",
    fontSize: "1.2rem",
    ...fontStyle.medium,
    color: "#717171",
  },
  timeSelectorTimeComponentStyle: {
    padding: "0",
    height: "unset",
    width: "72px",
    backgroundColor: colors.toddleBG1,
    zIndex: "0",
  },
  timeSelectorDropdownParentContainerStyle: {
    width: "100%",
  },
  avatarStyles: {
    size: "small",
    color: "neutral",
    shape: "circle",
    iconType: "none",
    shade: "light",
    randomizeColor: true,
  },
};

const CalendarLabel = ({ title, userCount, hasMoreLabel = false }) => {
  return (
    <div className={classes.calendarLabel}>
      <div className={classes.title}>{title}</div>
      <div
        className={classes.userCount}
        dangerouslySetInnerHTML={{
          __html: `&nbsp; - ${userCount} students${
            hasMoreLabel ? "," : ""
          } &nbsp;`,
        }}
      ></div>
    </div>
  );
};

const CalendarWiseAssignedUsers = React.memo(props => {
  const { calendarWiseAssignedUsers } = props;
  const [isCourseStudentsVisible, setIsCourseStudentsVisible] = useState(false);

  const arrowIconStyle = classNames(
    { [classes.arrowIcon]: true },
    { [classes.arrowIconReversed]: isCourseStudentsVisible }
  );

  return (
    <div className={classes.courseDetails}>
      <div
        className={classes.courseDetailsHeader}
        onClick={() => setIsCourseStudentsVisible(state => !state)}
      >
        <div className={classes.left}>
          <div className={classes.assignedToLabel}>{`Assigned to`}</div>
          {!isCourseStudentsVisible ? (
            <div className={classes.courseSummary}>
              {_.map(calendarWiseAssignedUsers, (calendar, index) => {
                const { id, title, assignedUsers } = calendar;
                const hasMoreLabel =
                  calendarWiseAssignedUsers.length - 1 > index ? true : false;
                return (
                  <CalendarLabel
                    key={id}
                    title={title}
                    userCount={assignedUsers.length}
                    hasMoreLabel={hasMoreLabel}
                  />
                );
              })}
            </div>
          ) : (
            <AnimateHeight
              height={isCourseStudentsVisible ? "auto" : 0}
              duration={300}
              onAnimationStart={null}
              onAnimationEnd={null}
              animateOpacity={true}
            >
              <div className={classes.courseDetailsBody}>
                {_.map(calendarWiseAssignedUsers, calendar => {
                  const { id, title, assignedUsers } = calendar;
                  return (
                    <div key={id} className={classes.assignedCalUsersContainer}>
                      <div className={classes.assignedCalendarLabel}>
                        <CalendarLabel
                          title={title}
                          userCount={assignedUsers.length}
                        />
                      </div>
                      <div className={classes.studentListContainer}>
                        {_.map(assignedUsers, user => (
                          <div
                            key={_.get(user, "id")}
                            className={classes.studentProfile}
                          >
                            <ProfileCascade
                              items={[
                                {
                                  id: _.get(user, "id"),
                                  value: _.get(user, "name"),
                                  imageUrl: _.get(user, "profileImage"),
                                },
                              ]}
                              showAvatar={true}
                              avatarStyles={styles.avatarStyles}
                            />
                            <div className={classes.name}>
                              {_.get(user, "name")}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </AnimateHeight>
          )}
        </div>
        <div className={classes.right}>
          <div className={arrowIconStyle}>
            <BottomArrow fill={colors.gray31} width={20} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
});

const EventBody = props => {
  const {
    color,
    title,
    eventIcon,
    description,
    meetingLink,
    publishedTime,
    backgroundColor,
    calendarWiseAssignedUsers,
    onRescheduleEventClick,
    rescheduleDataChangesLocal,
    rescheduleData,
    setFooterDisabled,
    userType,
    itemType,
    t,
    isNotUserCourse,
    showCalendarAssignmentByDueDate,
  } = props;

  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const { scheduleDate, scheduleStartTime, scheduleEndTime } = rescheduleData;
  const onRescheduleEventClickLocal = () => {
    onRescheduleEventClick(true);
  };

  const [isFromTimeInvalid, setIsFromTimeInvalid] = useState(false);
  const [isToTimeInvalid, setIsToTimeInvalid] = useState(false);
  const [shouldModalExtend, toggleShouldModalExtend] = useState(false);

  useEffect(() => {
    if (isFromTimeInvalid || isToTimeInvalid) {
      setFooterDisabled(true);
    } else {
      setFooterDisabled(false);
    }
  }, [isFromTimeInvalid, isToTimeInvalid]);

  useMount(() => {
    setTimeout(() => setShowTimeSelector(true), 100);
  });

  const onStartTimeChange = param => {
    onRescheduleEventClickLocal();
    rescheduleDataChangesLocal({
      scheduleStartTime: param,
      scheduleEndTime: moment(param, "HH:mm")
        .add(30, "minutes")
        .format("HH:mm"),
    });
  };

  const onEndTimeChange = param => {
    onRescheduleEventClickLocal();
    rescheduleDataChangesLocal({
      scheduleEndTime: param,
    });
  };

  const onUpdateDateInput = param => {
    onRescheduleEventClickLocal();
    rescheduleDataChangesLocal(param);
  };

  const getTimeSelectorByPerm = ({
    value,
    updateInputField,
    startTime,
    endTime,
    disableConfirmButton,
  }) => {
    return (
      <TimeSelectorV2
        showInputIcon={false}
        value={value}
        SelectedTimeformat="HH:mm"
        isValueMomentObj={false}
        updateInputField={updateInputField}
        isDisabled={_.isEmpty(scheduleDate)}
        startTime={startTime}
        endTime={endTime}
        dropdownItemStyle={styles.timeSelectorDropdownItemStyle}
        dropdownContainerStyle={styles.timeSelectorDropdownContainerStyle}
        containerStyle={{
          width: "unset",
        }}
        inputStyle={styles.timeSelectorInputStyle}
        timeComponentStyle={styles.timeSelectorTimeComponentStyle}
        showBorder={false}
        disableConfirmButton={disableConfirmButton}
        alwaysShowTooltip={true}
        showInputBorderOnFocus={true}
        positionDropdownDivWithPopover={true}
        dropdownParentContainerStyle={
          styles.timeSelectorDropdownParentContainerStyle
        }
        dropdownParentStyle={styles.timeSelectorDropdownParentContainerStyle}
      ></TimeSelectorV2>
    );
  };

  const getDateAndtimeContentByUserType = () => {
    if (
      userType === "staff" &&
      !isNotUserCourse &&
      (itemType !== "ASSIGNMENT" || !showCalendarAssignmentByDueDate)
    ) {
      return (
        <>
          <DateSelector
            containerStyle={{
              width: "unset",
              marginRight: 12,
            }}
            dateSelectorContainerStyle={{ padding: 0, border: "none" }}
            updateInputField={onUpdateDateInput}
            value={scheduleDate}
            name={"scheduleDate"}
            isValueMomentObj={false}
            displayFormat={"dddd, MMM DD"}
            onFocusChange={val => toggleShouldModalExtend(val)}
            minDate={moment()}
          ></DateSelector>

          {showTimeSelector &&
            getTimeSelectorByPerm({
              value: scheduleStartTime,
              updateInputField: onStartTimeChange,
              startTime: START_PICKER_START_TIME,
              endTime: START_PICKER_END_TIME,
              disableConfirmButton: setIsFromTimeInvalid,
            })}

          <div className={classes.timeBetweenText}>{"to"}</div>

          {showTimeSelector &&
            getTimeSelectorByPerm({
              value: scheduleEndTime,
              updateInputField: onEndTimeChange,
              startTime: scheduleStartTime,
              endTime: START_PICKER_END_TIME,
              disableConfirmButton: setIsToTimeInvalid,
            })}
        </>
      );
    } else {
      if (showCalendarAssignmentByDueDate && itemType === "ASSIGNMENT") {
        return <>{`${t("classRoom:due_date")}: ${publishedTime}`}</>;
      } else {
        return <>{publishedTime}</>;
      }
    }
  };

  return (
    <div
      className={classes.body}
      style={shouldModalExtend ? styles.extendedModalStyle : {}}
    >
      <div className={classes.headerTitle}>
        <div className={classes.eventImageContainer}>
          <div className={classes.eventImage} style={{ backgroundColor }}>
            <div className={classes.eventIcon} style={{ fill: color }}>
              {eventIcon}
            </div>
          </div>
        </div>
        <div className={classes.eventTitleContainer}>
          <div className={classes.title}>{title}</div>
          <div className={classes.publishedTime}>
            {getDateAndtimeContentByUserType()}
          </div>
        </div>
      </div>
      {description && (
        <div
          className={classes.descriptionText}
          dangerouslySetInnerHTML={{
            __html: getStringAutoLinks(description),
          }}
        ></div>
      )}
      {meetingLink && (
        <div className={classes.meeting}>
          <div className={classes.meetingLinkIcon}>
            <LinkIconSvg fill={colors.blue29} width={18} height={18} />
          </div>
          <div
            className={classes.meetingLink}
            dangerouslySetInnerHTML={{
              __html: getStringAutoLinks(meetingLink),
            }}
          ></div>
        </div>
      )}
      <CalendarWiseAssignedUsers
        calendarWiseAssignedUsers={calendarWiseAssignedUsers}
      />
    </div>
  );
};

export default memo(EventBody);
