import React from "react";
import classes from "./ShareButtons.scss";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import { FBIcon, TwitterIcon } from "SvgComponents";
import {
  colors,
  SHARE_URL,
  TWITTER_TITLE,
  FB_TITLE,
  FB_HASH_TAG,
} from "Constants";
import { useTranslation } from "react-i18next";

const FacebookButton = ({ shareUrl, title, fbHashTag }) => {
  const { t } = useTranslation(["common"]);
  return (
    <FacebookShareButton
      className={classes.fbButton}
      url={shareUrl}
      quote={title}
      hashtag={fbHashTag}
    >
      <div className={classes.iconContainer}>
        <FBIcon fill={colors.white} />
      </div>
      {t("common:share_on_fb")}
    </FacebookShareButton>
  );
};

FacebookButton.defaultProps = {
  shareUrl: SHARE_URL,
  title: FB_TITLE,
  fbHashTag: FB_HASH_TAG,
};

const TwitterButton = ({ shareUrl, title }) => {
  const { t } = useTranslation(["common"]);
  return (
    <TwitterShareButton
      url={shareUrl}
      title={title}
      className={classes.twitterButton}
    >
      <div className={classes.iconContainer}>
        <TwitterIcon fill={colors.white} />
      </div>
      {t("common:share_on_twitter")}
    </TwitterShareButton>
  );
};

TwitterButton.defaultProps = {
  shareUrl: SHARE_URL,
  title: TWITTER_TITLE,
};

export { FacebookButton, TwitterButton };
