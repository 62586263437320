import React from "react";
import classes from "./ColorDrop.scss";
import { TickPlain, ColorNoneIcon } from "SvgComponents";
import { colors } from "Constants";

const ColorDrop = props => {
  const { color, isSelected, size } = props;
  return _.isEmpty(color) ? (
    <div className={classes.noColor} style={{ width: size, height: size }}>
      <ColorNoneIcon width={size} height={size} />
    </div>
  ) : (
    <div
      key={color}
      style={{ backgroundColor: color, width: size, height: size }}
      className={classes.colorDrop}
    >
      {isSelected ? <TickPlain fill={"white"} width={12} height={12} /> : null}
    </div>
  );
};

ColorDrop.defaultProps = {
  size: 24,
  isSelected: false,
};

export default ColorDrop;
