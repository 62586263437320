import React from "react";

// UI Components
import {
  UIModal,
  I18nHOC,
  Accordian,
  Loading,
  FullScreenLoader,
} from "UIComponents";

// External libs.
import { connect } from "react-redux";
import { compose } from "react-apollo";
import PropTypes from "prop-types";

// Services and modules
import { getSettingValue } from "modules/PermissionModule";
import { getPrintFile, updateSettings } from "modules/Services";
import { getPlannerTemplateFromCache } from "modules/CommonGraphqlHelpers";

// Design system
import { Button, Checkbox, IconButton } from "@toddle-design/web";
import { DragDotsOutlined, CloseOutlined } from "@toddle-design/web-icons";

// Scss
import classes from "./UserTemplateSettingsModal.scss";

const SOURCES = {
  CLASSROOM_LE: {
    moduleKeyForSettingData: "classroomEditSteps",
    removeModuleKeys: ["basicV2"],
    removeModuleStepKeys: {},
    sourceModuleDataKey: "step_list",
    moduleStepsDataKey: "fields",
    defaultSelectedSettingData: {},
  },
};

class UserTemplateSettingsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const { selectedSettingState } = this.props;
    this.state = {
      selectedSettingState,
      showFullscreenLoader: false,
    };
  }

  componentDidMount() {
    const { selectedSettingState } = this.state;
    const { userId, templateId } = this.props;
    const localSelectedState = _.cloneDeep(selectedSettingState);

    const userPreference = getSettingValue({
      userId: userId,
      name: templateId,
    });

    if (_.isEmpty(userPreference)) {
      const moduleKeysData = this.getModuleKeysData();

      _.forEach(moduleKeysData, moduleKey => {
        const moduleData = this.getModuleDataByModuleKey(moduleKey);
        const { moduleStepList } = moduleData;

        localSelectedState[moduleKey] = moduleStepList;
      });

      this.setState({ selectedSettingState: localSelectedState });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedSettingState } = this.props;
    if (!_.isEqual(selectedSettingState, prevProps.selectedSettingState)) {
      this.setState({ selectedSettingState });
    }
  }

  getModuleKeysData = () => {
    const { sourceData, template } = this.props;
    const moduleKey = _.get(sourceData, "moduleKeyForSettingData");
    const moduleKeysData = _.get(template, moduleKey, []);
    const removeModuleKeys = _.get(sourceData, "removeModuleKeys", []);
    return _.filter(moduleKeysData, key => !_.includes(removeModuleKeys, key));
  };

  getModuleDataByModuleKey = moduleKey => {
    const { sourceData, template } = this.props;
    const sourceModuleData = _.get(template, sourceData.sourceModuleDataKey);
    const moduleData = _.get(sourceModuleData, moduleKey, {});
    const moduleStepsData = _.get(
      moduleData,
      sourceData.moduleStepsDataKey,
      []
    );
    const removeModuleStepKeys = _.get(sourceData, "removeModuleStepKeys", {});
    const filteredStepList = _.filter(moduleStepsData, stepKey => {
      const removeSteps = _.get(removeModuleStepKeys, moduleKey, []);
      if (_.includes(removeSteps, stepKey)) return false;
      return true;
    });
    return {
      moduleLabel: _.get(moduleData, "label"),
      moduleStepList: filteredStepList,
    };
  };

  onSaveClick = () => {
    const {
      userId,
      updateSettings,
      onClose,
      templateId,
      sourceData,
    } = this.props;
    const { selectedSettingState } = this.state;
    const clonedSelectedState = _.cloneDeep(selectedSettingState);

    this.setState({ showFullscreenLoader: true });

    // add defaultSelectedSetting in userSetting.
    const defaultSelectedSettingData = _.get(
      sourceData,
      "defaultSelectedSettingData",
      {}
    );
    const newSettingValue = _.forEach(clonedSelectedState, (value, key) => {
      const defultSettingData = _.get(defaultSelectedSettingData, key, []);
      _.forEach(defultSettingData, key => {
        if (!_.includes(value, key)) {
          value.push(key);
        }
      });
    });

    updateSettings({
      id: userId,
      type: "user",
      settings: [
        {
          name: templateId,
          value: JSON.stringify(newSettingValue),
        },
      ],
      onSettingsUpdate: () => {
        this.setState({ showFullscreenLoader: false });
        onClose?.();
      },
      showToastMsg: false,
    });
  };

  onCheckboxClick = ({ moduleKey, stepKey }) => {
    const moduleData = this.getModuleDataByModuleKey(moduleKey);
    const { moduleStepList } = moduleData;

    const { selectedSettingState } = this.state;
    const selectedValues = [...(selectedSettingState[moduleKey] ?? [])];

    let newSelectedValues = [];
    if (stepKey) {
      if (_.includes(selectedValues, stepKey)) {
        newSelectedValues = _.filter(selectedValues, item => item !== stepKey);
      } else {
        newSelectedValues = [...selectedValues, stepKey];
      }
    } else {
      const isAllSelected = moduleStepList?.length === selectedValues.length;

      newSelectedValues = isAllSelected ? [] : moduleStepList;
    }

    this.setState({
      selectedSettingState: {
        ...selectedSettingState,
        [moduleKey]: newSelectedValues,
      },
    });
  };

  getCheckboxState = ({ moduleStepList, selectedValues }) => {
    let checkboxState = {
      isIndeterminate: true,
      isChecked: false,
    };
    if (moduleStepList?.length === selectedValues.length) {
      checkboxState = {
        isIndeterminate: false,
        isChecked: true,
      };
    } else if (selectedValues.length === 0) {
      checkboxState = {
        isIndeterminate: false,
        isChecked: false,
      };
    }
    return checkboxState;
  };

  getStepLabel = ({ step: field }) => {
    const { template } = this.props;
    const fieldList = _.get(template, "field_list", {});
    const currentField = fieldList[field];

    const fieldLabel = _.get(currentField, "config.label", "");
    const viewLabel = _.get(currentField, "config.viewLabel", "");

    return !_.isEmpty(viewLabel) ? viewLabel : fieldLabel;
  };

  renderSettingData = () => {
    const { selectedSettingState } = this.state;
    const moduleKeysData = this.getModuleKeysData();
    const renderModuleSteps = _.map(moduleKeysData, moduleKey => {
      const moduleData = this.getModuleDataByModuleKey(moduleKey);

      const { moduleLabel, moduleStepList } = moduleData;
      const selectedValues = selectedSettingState[moduleKey] || [];
      const checkboxState = this.getCheckboxState({
        moduleStepList,
        selectedValues,
      });

      const renderSteps = _.map(moduleStepList, step => {
        const stepLabel = this.getStepLabel({ step });
        const isChecked = selectedValues.includes(step);

        return (
          <div
            onClick={() =>
              this.onCheckboxClick({
                moduleKey: moduleKey,
                stepKey: step,
              })
            }
            className={classes.labelContainer}
          >
            <div className={classes.checkboxContainer}>
              <Checkbox size={"xx-small"} isChecked={isChecked} />
            </div>
            <div className={classes.stepLabel}>{stepLabel}</div>
          </div>
        );
      });

      if (_.size(renderSteps) > 1) {
        return (
          <Accordian
            title={moduleLabel}
            expandedContent={
              <div className={classes.renderSteps}>{renderSteps}</div>
            }
            hideContainerBorder={true}
            mainContainerClass={classes.accordianBorder}
            customTitleComponent={
              <div className={classes.accordianTitleContainer}>
                <div className={classes.dragDotsOutlinedIcon}>
                  <DragDotsOutlined />
                </div>
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    size={"xx-small"}
                    isChecked={checkboxState.isChecked}
                    isIndeterminate={checkboxState.isIndeterminate}
                    onChange={e => {
                      e.stopPropagation();
                      this.onCheckboxClick({
                        moduleKey: moduleKey,
                      });
                    }}
                  />
                </div>
                <div className={classes.moduleLabel}>{moduleLabel}</div>
              </div>
            }
          />
        );
      } else {
        return (
          <div className={classes.accordianBorder}>
            <div className={classes.singalCheckboxContainer}>
              <div className={classes.dragDotsOutlinedIcon}>
                <DragDotsOutlined />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  size={"xx-small"}
                  isChecked={checkboxState.isChecked}
                  isIndeterminate={checkboxState.isIndeterminate}
                  onChange={e => {
                    e.stopPropagation();
                    this.onCheckboxClick({
                      moduleKey: moduleKey,
                    });
                  }}
                />
              </div>
              <div className={classes.moduleLabel}>{moduleLabel}</div>
            </div>
          </div>
        );
      }
    });

    return (
      <div className={classes.accordianListContainer}>{renderModuleSteps}</div>
    );
  };

  render() {
    const { t, onClose, template, modalTitle, modalTitleSubText } = this.props;
    const { showFullscreenLoader } = this.state;

    return (
      <React.Fragment>
        <UIModal
          isOpen={true}
          modalContent={classes.modalContent}
          onRequestClose={onClose}
        >
          <div className={classes.container}>
            <div className={classes.headerConatiner}>
              <div className={classes.headerText}>{t(`${modalTitle}`)}</div>
              {modalTitleSubText && (
                <div className={classes.headerSubText}>
                  {t(`${modalTitleSubText}`)}
                </div>
              )}
              <IconButton
                icon={<CloseOutlined size={"small"} variant={"plain"} />}
                size={"medium"}
                variant={"plain"}
                onClick={onClose}
              />
            </div>
            {_.isEmpty(template) ? (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            ) : (
              <div className={classes.accordianContainer}>
                {this.renderSettingData()}
              </div>
            )}
            <div className={classes.footerContainer}>
              <div>
                <Button onClick={this.onSaveClick} size={"medium"}>
                  {t("common:save")}
                </Button>
              </div>
            </div>
          </div>
        </UIModal>
        {showFullscreenLoader && <FullScreenLoader />}
      </React.Fragment>
    );
  }
}

UserTemplateSettingsModal.propTypes = {
  template: PropTypes.object,
  modalTitle: PropTypes.string,
  modalTitleSubText: PropTypes.string,
  sourceRequest: PropTypes.string,
  templateId: PropTypes.number,
  onClose: PropTypes.func,
  isDemo: PropTypes.bool,
};

UserTemplateSettingsModal.defaultProps = {
  isDemo: false,
};

const mapActionCreators = {
  updateSettings,
  getPrintFile,
};

const mapStateToProps = (state, ownProps) => {
  const { templateId, sourceRequest = "CLASSROOM_LE" } = ownProps;
  let { template } = ownProps;
  const userId = state.login.userInfo.id;
  if (_.isEmpty(template)) {
    template = _.get(getPlannerTemplateFromCache(templateId), "body", {});
  }

  const sourceData = _.get(SOURCES, sourceRequest, {});
  const moduleKey = _.get(sourceData, "moduleKeyForSettingData");
  const moduleKeysData = _.get(template, moduleKey, []);
  let selectedSettingState = _.cloneDeep(
    getSettingValue({
      userId: userId,
      name: templateId,
    })
  );

  if (_.isEmpty(selectedSettingState)) {
    selectedSettingState = {};
    _.forEach(moduleKeysData, step => {
      selectedSettingState[step] = [];
    });
  } else {
    // Remove defaultSelectedSetting in userSetting because handle the checkbox Indeterminate state.
    const defaultSelectedSettingData = _.get(
      sourceData,
      "defaultSelectedSettingData",
      {}
    );
    _.forEach(selectedSettingState, (value, key) => {
      const defaultSelectedSetting = _.get(defaultSelectedSettingData, key, []);
      selectedSettingState[key] = _.filter(
        value,
        t => !_.includes(defaultSelectedSetting, t)
      );
    });
  }
  return {
    template,
    sourceData,
    selectedSettingState,
    userId,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "unitPlan"] }),
  connect(mapStateToProps, mapActionCreators)
)(UserTemplateSettingsModal);
