import client from "apolloClient";
import {
  subjectFragment,
  unitTypeFragment,
  staffFragment,
  commentNodeFragment,
  notificationFragment,
  resourceLibraryFragment,
  attachmentNodeFragment,
  organizationFragment,
  pypElementFragment,
  focusAreaFragment,
  organizationPypElementSetFragment,
  plannerTemplateFragment,
  criteriaSetFragment,
  criteriaValueFragment,
  studentFragment,
  parentFragment,
  gradeFragment,
  organizationUnitPlanFeedFragment,
  academicYearFragment,
  courseFragment,
  studentAttendanceSummaryFragment,
  organizationCoursesFragment,
  coachMarkFragment,
  settingsNodeFragment,
  zoomMeetingNodeFragment,
  notificationListNodeFragment,
  attachmentGroupsNodeFragment,
  messageFragment,
  plannerElementNodeFragment,
  signatureFragment,
  attachmentFragment,
  yearGroupFragments,
} from "./CommonFragments";
import {
  getPlannerElementSetsOfCurriculumQuery,
  getPlannerElementNodesOfSetQuery,
  getPlannerElementNodesOfSetWithChildQuery,
  getOrganizationPypConstantsQuery,
  getOrganizationCurriculumProgramsQuery,
  getAtlSetQuery,
  getBenchmarkSetQuery,
  getRelatedConceptSetQuery,
  getPlannerTemplateQuery,
  getOrganizationConstantsQuery,
  getOrganizationDetailsQuery,
  getAssessmentToolNodeDetailsQuery,
  getCommentOfNodeQuery,
  getOrganizationGradeBasicDetailsQuery,
  getGlobalConstantsQuery,
  getHelpTextQuery,
  getPlatformUserDetailsQuery,
  getChildrenQuery,
  getSubjectBenchmarksQuery,
  getATLsQuery,
  getNotificationsQuery,
  getZoomMeetingNodeQuery,
  getOrganizationAttendanceOptionsQuery,
  getCurriculumAttendanceOptionsQuery,
  getConfigurationsQuery,
  getSubjectsByGradeQuery,
  getSubjectGroupDetailsQuery,
  getOrganizationSubjectGroupsQuery,
  getOrganizationBasicCoursesQuery,
  getEnabledClassNotificationsQuery,
  getOrganizationSubjectsQuery,
  getAcademicCriteriaSetsQuery,
  getOrganizationElementRatingQuery,
  getOrganizationPeriodSetsQuery,
  getOrganizationPypAtlConstantsQuery,
  getPlannerElementNodesOfSetWithDepthLabelQuery,
  getUnitPlansOfGradeQuery,
  getAttachmentQuery,
  getOrganizationStudentListQuery,
  getOrganizationGradesQuery,
  getStaffListQuery,
  getOrganizationStaffQuery,
  getFilteredPostCountWithTypeQuery,
  getUserGradesQuery,
  getMultiLevelNodesQuery,
  getOrganizationGlobalConstantsQuery,
  getUserDetailsWithLinkedIdentitiesQuery,
  getOrganizationSubjectsWithPlannerElementDepthLabelSetsQuery,
  getPlannerElementSetNodesAndDepthLabelsQuery,
  getBasicCommentOfNodeQuery,
  getFilteredTags,
  getStudentPeriodListQuery,
  getStudentPeriodListInfoQuery,
  getStudentPeriodSetsQuery,
  getOrganizationYearGroupsQuery,
  getOrganizationExamSessionsQuery,
  getDpSubjectVariantsQuery,
  getOrganizationYearGroupIdsQuery,
  getOrganizationMultiLevelNodeTagsQuery,
  getOrganizationTemplatesQuery,
  getIbOfferedSubjectVariants,
  getOrganizationTemplatesWithHelpEnabledFieldsQuery,
  getStudentStaffRelationQuery,
  getYearGroupAssociatedChildPlannerElementNodesQuery,
  getUserDetailsWithLinkedIdentitiesV1Query,
} from "./CommonQuery";
import { getPermissionsQuery } from "QueryMutationFragment/CommonQueryMutationFragment/queries";
import { rubricFragment } from "Rubric/modules/RubricFragments";
import { checklistFragment } from "Checklist/modules/ChecklistFragments";
import { singlePointRubricFragment } from "SinglePointRubric/modules/SinglePointRubricFragments";
import { assessmentToolFragment } from "modules/AssessmentTool/AssessmentToolFragment";

const fragmentNameMapping = {
  PLANNER_FIELD: {
    typeName: "PlannerField",
    fragment: "plannerField",
    fragmentName: "plannerFieldNodeItem",
  },
  EXEMPLAR: {
    typeName: "Exemplar",
    fragment: "exemplar",
    fragmentName: "exemplarNodeItem",
  },
  UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: "unitPlan",
    fragmentName: "unitPlanNodeItem",
  },
  ASSESSMENT: {
    typeName: "Assessment",
    fragment: "assessment",
    fragmentName: "assessmentNodeItem",
  },
  LEARNING_ENGAGEMENT: {
    typeName: "LearningEngagement",
    fragment: "le",
    fragmentName: "leNodeItem",
  },
  PLANNER_FLOW_SECTION: {
    typeName: "PlannerFlowSection",
    fragment: "flowSection",
    fragmentName: "flowSectionItem",
  },
  POST: {
    typeName: "Post",
    fragment: "post",
    fragmentName: "postItem",
  },
  ATL: {
    typeName: "PlannerATL",
    fragment: "atl",
    fragmentName: "atlItem",
  },
  MYP_ATL: {
    typeName: "PlannerATL",
    fragment: "atl",
    fragmentName: "atlItem",
  },
  LEARNER_PROFILE: {
    typeName: "PlannerLearnerProfile",
    fragment: "lp",
    fragmentName: "lpItem",
  },
  CONCEPT: {
    typeName: "PlannerConcept",
    fragment: "concept",
    fragmentName: "conceptItem",
  },
  ACTION: {
    typeName: "Action",
    fragment: "action",
    fragmentName: "actionItem",
  },
  THEME: {
    typeName: "PlannerTheme",
    fragment: "theme",
    fragmentName: "themeItem",
  },
  BENCHMARK: {
    typeName: "PlannerBenchmark",
    fragment: "benchmark",
    fragmentName: "benchmarkItem",
  },
  RELATED_CONCEPT: {
    typeName: "PlannerRelatedConcept",
    fragment: "relatedConcept",
    fragmentName: "relatedConceptItem",
  },
  STUDENT_GOAL_TASK: {
    typeName: "GoalTask",
    fragment: "studentGoalTask",
    fragmentName: "studentGoalTaskItem",
  },
  COMMUNITY_UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: "communityUnitPlan",
    fragmentName: "communityUnitPlanItem",
  },
  COMMUNITY_ASSESSMENT: {
    typeName: "Assessment",
    fragment: "communityAssessment",
    fragmentName: "communityAssessmentItem",
  },
  PROJECT_SIGNATURE_STUDENT: {
    typeName: "Student",
    fragment: "studentSignature",
    fragmentName: "studentSignature",
  },
  PROJECT_SIGNATURE_SUPERVISOR: {
    typeName: "Staff",
    fragment: "supervisorSignature",
    fragmentName: "supervisorSignature",
  },
  PLANNER_ELEMENT_NODE: {
    typeName: "PlannerElementNode",
    fragment: "plannerElementNode",
    fragmentName: "plannerElementNode",
  },
};

const zoomMeetingFragmentMapping = {
  COURSE: {
    typeName: "Course",
    fragment: "courseZoomMeeting",
    fragmentName: "courseZoomMeetingItem",
  },
};

const pypElementSetFragmentNameMapping = {
  BENCHMARK: {
    fragment: "benchmarkSet",
  },
  RELATED_CONCEPT: {
    fragment: "relatedConceptSet",
  },
  CONCEPT: {
    fragment: "conceptSet",
  },
  LEARNER_PROFILE: {
    fragment: "lpSet",
  },
  ATL: {
    fragment: "atlSet",
  },
  THEME: {
    fragment: "themeSet",
  },
  ACTION: {
    fragment: "actionSet",
  },
};

const assessmentToolFragmentNameMapping = {
  RUBRIC: {
    typeName: "Rubric",
    fragment: rubricFragment.rubric,
    fragmentName: "rubricItem",
  },
  CHECKLIST: {
    typeName: "Checklist",
    fragment: checklistFragment.checklist,
    fragmentName: "checklist",
  },
  SINGLE_POINT_RUBRIC: {
    typeName: "SinglePointRubric",
    fragment: singlePointRubricFragment.singlePointRubric,
    fragmentName: "singlePointRubricItem",
  },
  ANECDOTE: {
    typeName: "Anecdote",
    fragment: assessmentToolFragment.anecdote,
    fragmentName: "anecdoteItem",
  },
  EXEMPLAR: {
    typeName: "Exemplar",
    fragment: assessmentToolFragment.exemplar,
    fragmentName: "exemplarItem",
  },
  MYP_OBJECTIVE_RUBRIC: {
    typeName: "MypObjectiveRubric",
    fragment: assessmentToolFragment.mypObjectiveRubric,
    fragmentName: "mypObjectiveRubricItem",
  },
};

const userBasicDetailsFragmentMap = {
  STAFF: {
    typeName: "Staff",
    fragment: staffFragment["basicStaffDetails"],
    fragmentName: "staffItem",
  },
  STUDENT: {
    typeName: "Student",
    fragment: studentFragment["basicStudentDetails"],
    fragmentName: "studentItem",
  },
  FAMILY_MEMBER: {
    typeName: "FamilyMember",
    fragment: parentFragment["basicParentDetails"],
    fragmentName: "parentItem",
  },
};

const userNotificationCountFragmentMap = {
  STAFF: {
    typeName: "Staff",
    fragment: notificationFragment["staffCountItem"],
    fragmentName: "staffCountItem",
  },
  STUDENT: {
    typeName: "Student",
    fragment: notificationFragment["studentCountItem"],
    fragmentName: "studentCountItem",
  },
  FAMILY_MEMBER: {
    typeName: "FamilyMember",
    fragment: notificationFragment["parentCountItem"],
    fragmentName: "parentCountItem",
  },
};

const userNotificationListFragmentMap = {
  STAFF: {
    typeName: "Staff",
    fragment: notificationListNodeFragment["staff"],
    fragmentName: "staffItem",
  },
  STUDENT: {
    typeName: "Student",
    fragment: notificationListNodeFragment["student"],
    fragmentName: "studentItem",
  },
  FAMILY_MEMBER: {
    typeName: "FamilyMember",
    fragment: notificationListNodeFragment["parent"],
    fragmentName: "parentItem",
  },
};

export const settingsNodeFragmentMap = {
  ORGANIZATION: {
    typeName: "Organization",
    fragment: settingsNodeFragment["organization"],
    fragmentName: "organizationItem",
  },
  COURSE: {
    typeName: "Course",
    fragment: settingsNodeFragment["course"],
    fragmentName: "courseItem",
  },
  STAFF: {
    typeName: "Staff",
    fragment: settingsNodeFragment["staff"],
    fragmentName: "staffItem",
  },
  STUDENT: {
    typeName: "Student",
    fragment: settingsNodeFragment["student"],
    fragmentName: "studentItem",
  },
  FAMILY_MEMBER: {
    typeName: "FamilyMember",
    fragment: settingsNodeFragment["parent"],
    fragmentName: "parentItem",
  },
};

export const attachmentGroupsNodeFragmentMap = {
  STUDENT_ASSIGNMENT_SUBMISSION: {
    typeName: "StudentAssignmentSubmission",
    fragment:
      attachmentGroupsNodeFragment[
        "studentAssignmentSubmissionAttachmentGroups"
      ],
    fragmentName: "studentAssignmentSubmissionAttachmentGroupsItem",
  },
  PLANNER_FIELD_STUDENT_TEMPLATE: {
    typeName: "ResolvedFieldStudentTemplate",
    fragment: attachmentGroupsNodeFragment["studentTemplateAttachmentGroups"],
    fragmentName: "studentTemplateAttachmentGroupsItem",
  },
};

export const getDpSubjectVariantsFromCache = variables => {
  try {
    const result = client.readQuery({
      query: getDpSubjectVariantsQuery,
      variables,
    });
    return result || {};
  } catch (e) {
    return {};
  }
};

export const getIbOfferedSubjectVariantsFromCache = ({
  subjectVariantFilters,
  subjectGroupFilters,
  globalTagFilters,
}) => {
  try {
    const queryData = client.readQuery({
      query: getIbOfferedSubjectVariants,
      variables: {
        subjectVariantFilters,
        subjectGroupFilters,
        globalTagFilters,
      },
    });
    return queryData || {};
  } catch (e) {
    return {};
  }
};

export const getPlatformUserDetailsFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlatformUserDetailsQuery,
      variables: {
        id,
        type,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, `node`, {});
  else return {};
};

export const writePlatformUserDetailsToCache = ({ id, type, data }) => {
  try {
    client.writeQuery({
      query: getPlatformUserDetailsQuery,
      variables: { id, type },
      data,
    });
  } catch (e) {
    return {};
  }
};

export const getUserDetailsWithLinkedIdentitiesFromCache = ({
  id,
  type,
  first,
  after,
  searchText,
}) => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getUserDetailsWithLinkedIdentitiesQuery,
      variables: { id, type, first, after, searchText },
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

export const getUserDetailsWithLinkedIdentitiesV1FromCache = ({ id, type }) => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getUserDetailsWithLinkedIdentitiesV1Query,
      variables: { id, type },
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

export const getOrganizationStudentListFromCache = ({
  searchText,
  grades,
  courses,
  organizationId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationStudentListQuery,
      variables: { searchText, grades, courses, organizationId },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, `node`, {});
  else return {};
};

export const getPermissionsFromCache = ({ userId, groups }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPermissionsQuery,
      variables: {
        userId,
        groups,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
};

export const getChildrenFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getChildrenQuery,
      variables: {
        id,
        type,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, `node`, {});
  else return {};
};

export const getAcademicYearDetailsFromCahce = id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `AcademicYear:${id}`,
      fragment: academicYearFragment.academicYear,
      fragmentName: "academicYearItem",
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getGlobalConstantsFromCache = () => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getGlobalConstantsQuery,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) {
    return _.get(queryData, "globalConstants", {});
  } else return {};
};

export const getPlannerTemplateFromCache = (
  templateIds,
  returnAsArray = false
) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerTemplateQuery,
      variables: {
        ids: templateIds,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) {
    const templates = _.get(queryData, "multiNode", []);
    if (templates.length == 1 && !returnAsArray) {
      return templates[0];
    } else {
      return templates;
    }
  } else return {};
};

export const getPlannerTemplateFromFragment = templateId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PlannerTemplate:${templateId}`,
      fragment: plannerTemplateFragment.plannerTemplate,
      fragmentName: "templateItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getPypElementFromCache = ({ type, id }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;

    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment: pypElementFragment[fragment],
      fragmentName: fragmentName,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getPlannerElementNodeFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PlannerElementNode:${id}`,
      fragment: plannerElementNodeFragment.plannerElementNode,
      fragmentName: "plannerElementNodeItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writePlannerElementInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `PlannerElementNode:${id}`,
      fragmentName: "plannerElementNodeItem",
      fragment: plannerElementNodeFragment.plannerElementNode,
      data,
    });
  } catch (e) {
    console.error(e);
  }
};
export const writePypElementFromCache = ({ type, id, data }) => {
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;

    client.writeFragment({
      id: `${typeName}:${id}`,
      fragment: pypElementFragment[fragment],
      fragmentName: fragmentName,
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const writeProjectSignatureInCache = ({ type, id, data }) => {
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;

    client.writeFragment({
      id: `${typeName}:${id}`,
      fragment: signatureFragment[fragment],
      fragmentName: fragmentName,
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getFocusAreaFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `FocusArea:${id}`,
      fragment: focusAreaFragment.focusArea,
      fragmentName: "focusAreaItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeOrganizationPypElementSetInCache = ({
  subjects,
  grades,
  organizationId,
  type,
  data,
}) => {
  const fragment = pypElementSetFragmentNameMapping[type].fragment;

  try {
    client.writeFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationPypElementSetFragment[fragment],
      fragmentName: "organizationPypElementSetItem",
      data: data,
      variables: {
        subjects,
        grades,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getOrganizationPypElementSetFromCache = ({
  subjects,
  grades,
  organizationId,
  type,
  concepts,
}) => {
  let queryData = {};
  let query = getOrganizationPypConstantsQuery;

  try {
    switch (type) {
      case "BENCHMARK":
        query = getBenchmarkSetQuery;
        break;
      case "ATL":
        query = getAtlSetQuery;
        break;
      case "RELATED_CONCEPT":
        query = getRelatedConceptSetQuery;
        break;
    }
    queryData = client.readQuery({
      query,
      variables: {
        organizationId,
        subjects,
        grades,
        concepts,
        type,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", "");
  else return {};
};

export const getOrganizationPypElementSetFromCacheV2 = ({
  subjectId,
  organizationId,
  type,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getMultiLevelNodesQuery,
      variables: {
        organizationId,
        subjectId,
        type,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", "");
  else return {};
};

export const getOrganizationPypConstantsFromCache = organizationId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationPypConstantsQuery,
      variables: {
        organizationId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node.pypElement", {});
  else return {};
};

export const getOrganizationCurriculumProgramsFromCache = organizationId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationCurriculumProgramsQuery,
      variables: {
        organizationId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPlannerElementNodesOfSetFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerElementNodesOfSetQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPlannerElementNodesOfSetWithChildFromCache = ({
  id,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerElementNodesOfSetWithChildQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getPlannerElementNodesOfSetWithDepthLabelFromCache = ({
  id,
  filters,
  plannerElementDepthLabelSetFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerElementNodesOfSetWithDepthLabelQuery,
      variables: {
        id,
        filters,
        plannerElementDepthLabelSetFilters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  return queryData !== null ? _.get(queryData, "node", {}) : {};
};

export const writePlannerElementNodesOfSetInCache = ({ id, filters, data }) => {
  let queryData = {};
  try {
    queryData = client.writeQuery({
      query: getPlannerElementNodesOfSetQuery,
      variables: {
        id,
        filters,
      },
      data,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getPlannerElementSetsOfCurriculumFromCache = ({
  id,
  elementTypes,
  type,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerElementSetsOfCurriculumQuery,
      variables: {
        id,
        elementTypes,
        type,
        filters,
      },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getYearGroupAssociatedChildPlannerElementNodesFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getYearGroupAssociatedChildPlannerElementNodesQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getOrganizationConstantsFromCache = organizationId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationConstantsQuery,
      variables: {
        id: organizationId,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node.constants", {});
};

export const writeOrganizationConstantsFromCache = ({
  organizationId,
  constants,
}) => {
  let data = client.readFragment({
    id: `Organization:${organizationId}`,
    fragment: organizationFragment.organizationConstants,
    fragmentName: "organizationItem",
  });

  data = { ...data, constants };

  try {
    client.writeFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationFragment.organizationConstants,
      fragmentName: "organizationItem",
      data,
    });
  } catch (e) {}
};

export const getCriteriaSetDetailsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `AcademicCriteriaSet:${id}`,
      fragment: criteriaSetFragment.criteriaSet,
      fragmentName: "criteriaSetItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeCriteriaSetInCache = ({ id, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `AcademicCriteriaSet:${id}`,
      fragment: criteriaSetFragment.criteriaSet,
      fragmentName: "criteriaSetItem",
      data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getCriteriaValueFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `AcademicCriteriaValue:${id}`,
      fragment: criteriaValueFragment.criteriaValue,
      fragmentName: "criteriaValueItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getOrganizationDetailsFromCache = organizationId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationDetailsQuery,
      variables: {
        id: organizationId,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getOrganizationPeriodSetsFromCache = (
  organizationId,
  periodSetFilter
) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationPeriodSetsQuery,
      variables: {
        id: organizationId,
        periodSetFilter,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getGradeBasicDetailsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Grade:${id}`,
      fragment: gradeFragment.basicGradeDetails,
      fragmentName: "gradeItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getOrganizationBasicGradeDetailsFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationGradeBasicDetailsQuery,
      variables: {
        organizationId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getOrganizationAllCoursesFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationCoursesFragment.organizationCourses,
      fragmentName: "organizationCourseItem",
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getUserBasicDetailsFromCache = ({ id, type }) => {
  let queryData = {};

  try {
    const { typeName, fragment, fragmentName } = userBasicDetailsFragmentMap[
      type
    ];
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
    });
  } catch (e) {
    return {};
  }

  return queryData ? queryData : {};
};

export const writeUserBasicDetailsFromCache = ({ id, data, type }) => {
  let queryData = {};
  const { typeName, fragment, fragmentName } = userBasicDetailsFragmentMap[
    type
  ];
  try {
    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      data,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getStaffBasicDetailsFromCache = user_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Staff:${user_id}`,
      fragment: staffFragment.basicStaffDetails,
      fragmentName: "staffItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeStaffBasicDetailsInCache = ({ userId, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Staff:${userId}`,
      fragment: staffFragment.basicStaffDetails,
      fragmentName: "staffItem",
      data,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getStudentBasicDetailsFromCache = user_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${user_id}`,
      fragment: studentFragment.basicStudentDetails,
      fragmentName: "studentItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getSubjectBasicDetailsFromCache = subject_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Subject:${subject_id}`,
      fragment: subjectFragment.basicSubjectDetails,
      fragmentName: "subjectItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getSubjectDetailsFromCache = ({ subjectId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Subject:${subjectId}`,
      fragment: subjectFragment.subjectDetails,
      fragmentName: "subjectItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeSubjectDetailsInCache = ({ subjectId, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Subject:${subjectId}`,
      fragment: subjectFragment.subjectDetails,
      fragmentName: "subjectItem",
      data,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getUnitTypeFromCache = type_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `UnitType:${type_id}`,
      fragment: unitTypeFragment.unitType,
      fragmentName: "unitTypeItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getResourceFeedItemFromCache = ({ resource_id, type }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `${fragmentNameMapping[type].typeName}:${resource_id}`,
      fragment: resourceLibraryFragment.resourceFeed,
      fragmentName: "resourceLibraryItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getCommentsOfNodeFromCache = ({ nodeId, type, isCommunity }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCommentOfNodeQuery,
      variables: {
        id: nodeId,
        type,
        isCommunity,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  return _.get(queryData, "node", {});
};

export const writeCommentNodeFragment = ({
  nodeId,
  data,
  type,
  parentType,
}) => {
  const fragmentObj =
    fragmentNameMapping[parentType] || fragmentNameMapping[type] || {};
  const typeName = fragmentObj.typeName;
  const fragment = fragmentObj.fragment;
  const fragmentName = fragmentObj.fragmentName;
  client.writeFragment({
    id: `${typeName}:${nodeId}`,
    fragment: commentNodeFragment[fragment],
    fragmentName: fragmentName,
    data: data,
  });
};

export const writeMessageFragment = ({ nodeId, data }) => {
  try {
    client.writeFragment({
      id: `Message:${nodeId}`,
      fragment: messageFragment.message,
      fragmentName: "messageItem",
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const readMessageFragment = ({ id }) => {
  let queryData = {};

  queryData = client.readFragment({
    id: `Message:${id}`,
    fragment: messageFragment.message,
    fragmentName: "messageItem",
  });

  return queryData ? queryData : {};
};

export const getAttachmentOfNodeFromCache = ({ nodeId, type }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;
    queryData = client.readFragment({
      id: `${typeName}:${nodeId}`,
      fragment: attachmentNodeFragment[fragment],
      fragmentName: fragmentName,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getAttachmentGroupsOfNodeFromCache = ({ id, type }) => {
  let queryData = {};
  const { typeName, fragment, fragmentName } =
    attachmentGroupsNodeFragmentMap[type] || {};

  try {
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
    });
  } catch (e) {
    console.error(e, "error");
    return {};
  }
  return queryData ? queryData : {};
};

export const writeAttachmentNodeFragment = ({ nodeId, data, type }) => {
  const typeName = fragmentNameMapping[type]?.typeName;
  const fragment = fragmentNameMapping[type]?.fragment;
  const fragmentName = fragmentNameMapping[type]?.fragmentName;
  if (!typeName || !fragment || !fragmentName) {
    return;
  }
  try {
    client.writeFragment({
      id: `${typeName}:${nodeId}`,
      fragment: attachmentNodeFragment[fragment],
      fragmentName: fragmentName,
      data: data,
    });
  } catch (e) {}
};

export const writeAttachmentGroupsOfNodeInCache = ({ id, type, data }) => {
  let queryData = {};
  const { typeName, fragment, fragmentName } =
    attachmentGroupsNodeFragmentMap[type] || {};

  try {
    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      data,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeNotificationFragment = ({ notificationId, data }) => {
  client.writeFragment({
    id: `Notification:${notificationId}`,
    fragment: notificationFragment.notificationItem,
    fragmentName: "notificationItem",
    data,
  });
};

export const getNotificationFromCache = notificationId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Notification:${notificationId}`,
      fragment: notificationFragment.notificationItem,
      fragmentName: "notificationItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getNotificationListFromCache = ({
  id,
  type,
  notificationCategoryFilter,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getNotificationsQuery,
      variables: {
        id,
        type,
        notificationCategoryFilter,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

// export const getStaffNotificationItemFromCache = ({
//   staffId,
//   notificationCategoryFilter
// }) => {
//   let queryData = {};
//   try {
//     queryData = client.readFragment({
//       id: `Staff:${staffId}`,
//       fragment: staffFragment.staffNotificationItem,
//       fragmentName: "staffItem",
//       variables: { notificationCategoryFilter }
//     });
//   } catch (e) {
//     // console.log(e);
//     return {};
//   }
//   if (queryData != null) return queryData;
//   else return {};
// };

export const writeNotificationListInCache = ({
  id,
  type,
  data,
  notificationCategoryFilter,
}) => {
  const { typeName, fragment, fragmentName } = userNotificationListFragmentMap[
    type
  ];

  try {
    client.writeFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      variables: {
        notificationCategoryFilter,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

// export const getStaffNotificationCountFromCache = staffId => {
//   let queryData = {};
//   try {
//     queryData = client.readFragment({
//       id: `Staff:${staffId}`,
//       fragment: notificationFragment.staffCountItem,
//       fragmentName: "staffCountItem"
//     });
//   } catch (e) {
//     return {};
//   }
//   if (queryData != null) return queryData;
//   else return {};
// };

export const getUserNotificationCountFromCache = ({
  id,
  type,
  notificationCategoryFilter,
}) => {
  let queryData = {};
  const { typeName, fragment, fragmentName } = userNotificationCountFragmentMap[
    type
  ];
  try {
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      variables: {
        notificationCategoryFilter,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getBasicCourseDetailFromCache = courseId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Course:${courseId}`,
      fragment: courseFragment.basicCourserDetails,
      fragmentName: "courseItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const geCourseGradesDetailFromCache = courseId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Course:${courseId}`,
      fragment: courseFragment.courserGradesDetails,
      fragmentName: "courseGradeItem",
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

// export const writeStaffNotificationCountFromCache = ({ staffId, data }) => {
//   try {
//     client.writeFragment({
//       id: `Staff:${staffId}`,
//       fragment: notificationFragment.staffCountItem,
//       fragmentName: "staffCountItem",
//       data
//     });
//   } catch (e) {
//     console.log(e);
//   }
// };

export const writeUserNotificationInFromCache = ({
  id,
  data,
  type,
  notificationCategoryFilter,
}) => {
  const { typeName, fragment, fragmentName } = userNotificationCountFragmentMap[
    type
  ];
  try {
    client.writeFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      data,
      variables: {
        notificationCategoryFilter,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getOrganizationUnitPlanFeedFromCache = ({
  organizationId,
  grades,
  scheduledStatus,
  type,
  searchText,
  academicYears,
  getPlanathonUnits,
  users,
  createdBy,
  removePlanathonUnits,
  curriculumProgramIds,
  showCollaborators = true,
  subjects,
  centralRepoInput,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationUnitPlanFeedFragment.organizationUnitPlanFeed,
      fragmentName: "organizationUnitPlanFeedItem",
      variables: {
        grades,
        scheduledStatus,
        type,
        searchText,
        academicYears,
        getPlanathonUnits,
        users,
        removePlanathonUnits,
        showCollaborators,
        createdBy,
        curriculumProgramIds,
        subjects,
        centralRepoInput,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getOrganizationUnitPlanFeedV2FromCache = ({
  id,
  filters,
  showCollaborators = true,
  centralRepoInput,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Organization:${id}`,
      fragment: organizationUnitPlanFeedFragment.organizationUnitPlanFeedV2,
      fragmentName: "organizationUnitPlanFeedItem",
      variables: {
        filters,
        showCollaborators,
        centralRepoInput,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getOrganizationUnitPlanFeedCountFromCache = ({
  organizationId,
  grades,
  scheduledStatus,
  type,
  searchText,
  academicYears,
  getPlanathonUnits,
  users,
  removePlanathonUnits,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationUnitPlanFeedFragment.organizationUnitPlanFeedCount,
      fragmentName: "organizationUnitPlanFeedCountItem",
      variables: {
        grades,
        scheduledStatus,
        type,
        searchText,
        academicYears,
        getPlanathonUnits,
        users,
        removePlanathonUnits,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeOrganizationUnitPlanFeedInCache = ({
  organizationId,
  grades,
  scheduledStatus,
  type,
  searchText,
  data,
  academicYears,
  getPlanathonUnits,
  users,
  removePlanathonUnits,
  showCollaborators = true,
  centralRepoInput,
}) => {
  try {
    client.writeFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationUnitPlanFeedFragment.organizationUnitPlanFeed,
      fragmentName: "organizationUnitPlanFeedItem",
      variables: {
        grades,
        scheduledStatus,
        type,
        searchText,
        academicYears,
        getPlanathonUnits,
        users,
        removePlanathonUnits,
        showCollaborators,
        centralRepoInput,
      },
      data,
    });
  } catch (e) {}
};

export const writeOrganizationUnitPlanFeedV2InCache = ({
  id,
  data,
  filters,
  showCollaborators = true,
  centralRepoInput,
}) => {
  try {
    client.writeFragment({
      id: `Organization:${id}`,
      fragment: organizationUnitPlanFeedFragment.organizationUnitPlanFeedV2,
      fragmentName: "organizationUnitPlanFeedItem",
      variables: {
        filters,
        showCollaborators,
        centralRepoInput,
      },
      data,
    });
  } catch (e) {}
};

export const writeOrganizationUnitPlanFeedCountInCache = ({
  organizationId,
  grades,
  scheduledStatus,
  type,
  searchText,
  data,
  academicYears,
  getPlanathonUnits,
  users,
  removePlanathonUnits,
}) => {
  try {
    client.writeFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationUnitPlanFeedFragment.organizationUnitPlanFeedCount,
      fragmentName: "organizationUnitPlanFeedCountItem",
      variables: {
        grades,
        scheduledStatus,
        type,
        searchText,
        academicYears,
        getPlanathonUnits,
        users,
        removePlanathonUnits,
      },
      data,
    });
  } catch (e) {}
};

export const getOrganizationAttendanceOptionsFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationAttendanceOptionsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  return queryData ? queryData : {};
};

export const getCurriculumAttendanceOptionsQueryFromCache = ({
  id,
  curriculumId,
  filter,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCurriculumAttendanceOptionsQuery,
      variables: {
        id,
        curriculumId,
        filter,
      },
    });
  } catch (e) {
    return {};
  }

  return queryData ? queryData : {};
};

export const getStudentAttendanceSummaryFromCache = ({
  studentId,
  courseId,
  startDate,
  endDate,
  groupingType,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${studentId}`,
      fragment: studentAttendanceSummaryFragment.studentAttendanceSummary,
      fragmentName: "studentAttendanceSummaryItem",
      variables: {
        studentId,
        courseId,
        startDate,
        endDate,
        groupingType,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getHelpTextFromCache = ({ id, locale }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getHelpTextQuery,
      variables: {
        id,
        locale,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "planner.i18nHelpText", {});
  else return {};
};

export const getAssessmentToolNodeDetailsFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssessmentToolNodeDetailsQuery,
      variables: {
        id,
        type,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeAssessmentToolNodeDetailsInCache = ({ id, type, data }) => {
  const typeName = assessmentToolFragmentNameMapping[type].typeName;
  const fragment = assessmentToolFragmentNameMapping[type].fragment;
  const fragmentName = assessmentToolFragmentNameMapping[type].fragmentName;
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
      data,
    });
  } catch (e) {
    //console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getStaffCoachMarksFromCache = userId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Staff:${userId}`,
      fragment: staffFragment.staffCoachMarks,
      fragmentName: "staffCoachMarksItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getStudentCoachMarksFromCache = userId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Student:${userId}`,
      fragment: studentFragment.studentCoachMarks,
      fragmentName: "studentCoachMarksItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCoachMarkStatusFromCache = coachMarkId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `CoachMarkUserStatus:${coachMarkId}`,
      fragment: coachMarkFragment.coachMark,
      fragmentName: "coachMarkItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeCoachMarkStatusInCache = ({ coachMarkId, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `CoachMarkUserStatus:${coachMarkId}`,
      fragment: coachMarkFragment.coachMark,
      fragmentName: "coachMarkItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getGradeUnitDetailsFromCache = ({
  gradeId,
  userId,
  isCurriculumProgramFree,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Grade:${gradeId}`,
      fragment: gradeFragment.gradeUnitDetails,
      fragmentName: "gradeItem",
      variables: {
        id: userId,
        isCurriculumProgramFree,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeGradeUnitDetailsInCache = ({
  id,
  data,
  userId,
  isCurriculumProgramFree,
}) => {
  try {
    client.writeFragment({
      id: `Grade:${id}`,
      fragment: gradeFragment.gradeUnitDetails,
      fragmentName: "gradeItem",
      variables: {
        id: userId,
        isCurriculumProgramFree,
      },
      data,
    });
  } catch (e) {}
};

export const getSettingsOfNodeFromCache = ({ id, type }) => {
  let queryData = {};
  const { typeName, fragment, fragmentName } = settingsNodeFragmentMap[type];
  try {
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment,
      fragmentName,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "settings", []);
  else return {};
};

export const getSubjectBenchmarksFromCache = ({ subject, grades }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSubjectBenchmarksQuery,
      variables: {
        subject,
        grades,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getATLsFromCache = ({ id, grades }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getATLsQuery,
      variables: {
        id,
        grades,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeResourceFeedItemFromCache = ({ resource_id, type, data }) => {
  const queryData = {};
  try {
    client.writeFragment({
      id: `${fragmentNameMapping[type].typeName}:${resource_id}`,
      fragment: resourceLibraryFragment.resourceFeed,
      fragmentName: "resourceLibraryItem",
      data,
    });
  } catch (e) {
    return {};
  }
};

export const getZoomMeetingNodeFromCache = ({ parentType, parentId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getZoomMeetingNodeQuery,
      variables: {
        parentType,
        parentId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeZoomMeetingNodeToCache = ({ parentType, parentId, data }) => {
  try {
    const { typeName, fragment, fragmentName } = zoomMeetingFragmentMapping[
      parentType
    ];
    client.writeFragment({
      id: `${typeName}:${parentId}`,
      fragment: zoomMeetingNodeFragment[fragment],
      fragmentName: fragmentName,
      data,
    });
  } catch (e) {}
};

export const getSubjectsByGradeFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getSubjectsByGradeQuery,
      variables: { id },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSubjectGroupDetailsFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getSubjectGroupDetailsQuery,
      variables: { id },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getOrganizationSubjectGroupsFromCache = ({
  id,
  filters,
  subjectFilters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationSubjectGroupsQuery,
      variables: { id, filters, subjectFilters },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeSubjectInCache = ({ subjectId, data }) => {
  try {
    client.writeFragment({
      id: `Subject:${subjectId}`,
      fragment: subjectFragment.subjectDetails,
      fragmentName: "subjectItem",
      data: data,
    });
  } catch (e) {}
};

export const getSubjectFromCache = ({ subjectId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Subject:${subjectId}`,
      fragment: subjectFragment.subjectDetails,
      fragmentName: "subjectItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationBasicCoursesFromCache = ({
  filters,
  organizationId,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationBasicCoursesQuery,
      variables: { filters, organizationId },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getConfigurationsFromCache = ({
  platform,
  categories,
  getRoot,
  courseId,
  curriculumProgramId,
}) => {
  let queryData = [];
  try {
    queryData = client.readQuery({
      query: getConfigurationsQuery,
      variables: {
        platform,
        categories,
        getRoot,
        courseId,
        curriculumProgramId,
      },
    });
  } catch (e) {
    console.error(e);
    return [];
  }
  if (queryData != null) return queryData;
  else return [];
};

export const writeConfigurationsToCache = ({
  platform,
  categories,
  getRoot,
  courseId,
  curriculumProgramId,
  data,
}) => {
  try {
    client.writeQuery({
      query: getConfigurationsQuery,
      variables: {
        platform,
        categories,
        getRoot,
        courseId,
        curriculumProgramId,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getEnabledClassNotificationsFromCache = ({ id, type }) => {
  let queryData = [];
  try {
    queryData = client.readQuery({
      query: getEnabledClassNotificationsQuery,
      variables: {
        id,
        type,
      },
    });
  } catch (e) {
    console.error(e);
    return [];
  }
  if (queryData != null)
    return _.get(queryData, "node.enabledClassNotifications", []);
  else return [];
};

export const getOrganizationSubjectsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationSubjectsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAcademicCriteriaSetsFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAcademicCriteriaSetsQuery,
      variables: {
        organizationId,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeAcademicCriteriaSetsToCache = ({
  organizationId,
  filters,
  data,
}) => {
  let queryData = {};
  try {
    queryData = client.writeQuery({
      query: getAcademicCriteriaSetsQuery,
      variables: {
        organizationId,
        filters,
      },
      data,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getOrganizationElementRatingFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationElementRatingQuery,
      variables: {
        organizationId,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (!queryData) {
    return {};
  }
  return queryData;
};

export const writeOrganizationElementRatingToCache = ({
  organizationId,
  filters,
  data,
}) => {
  let queryData = {};
  try {
    queryData = client.writeQuery({
      query: getOrganizationElementRatingQuery,
      variables: {
        organizationId,
        filters,
      },
      data,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getOrganizationPypAtlConstantsFromCache = organizationId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationPypAtlConstantsQuery,
      variables: {
        organizationId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node.pypElement", {});
  else return {};
};

export const getUnitPlansOfGradeFromCache = ({ id, filters }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getUnitPlansOfGradeQuery,
      variables: { id, filters },
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAttachmentFromCache = input => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getAttachmentQuery,
      variables: { ...input },
    });
  } catch (e) {
    return {};
  }
  return queryData ? _.get(queryData, "node", {}) : {};
};

export const getOrganizationGradesFromCache = ({ id, filters }) => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getOrganizationGradesQuery,
      variables: { id, filters },
    });
  } catch (e) {
    return {};
  }
  return queryData ? _.get(queryData, "node", {}) : {};
};

export const getStaffListFromCache = ({
  organizationId,
  searchText,
  grades,
  archivalState,
  courses,
  curriculumProgramIds,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffListQuery,
      variables: {
        organizationId,
        searchText,
        grades,
        archivalState,
        courses,
        curriculumProgramIds,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationStaffFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationStaffQuery,
      variables: params,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getUserGradesFromCache = ({
  id: userId,
  type: userType,
  allGradesFilters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getUserGradesQuery,
      variables: {
        id: userId,
        type: userType,
        allGradesFilters,
      },
    });
  } catch (e) {
    return {};
  }

  return queryData != null ? queryData : {};
};

export const getOrganizationGlobalConstantsFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationGlobalConstantsQuery,
      variables: {
        organizationId,
        filter: filters,
      },
    });
  } catch (e) {
    return {};
  }

  return queryData != null ? queryData : {};
};

export const getFilteredPostCountWithTypeFromCache = ({
  id,
  filters,
  type,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getFilteredPostCountWithTypeQuery,
      variables: {
        id,
        filters,
        type,
      },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getAttachmentFragmentFromCache = ({ attachmentId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Attachment:${attachmentId}`,
      fragment: attachmentFragment.attachment,
      fragmentName: "attachmentItem",
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeAttachmentSimilarityReportInCache = ({
  attachmentId,
  data,
}) => {
  try {
    client.writeFragment({
      id: `Attachment:${attachmentId}`,
      fragment: attachmentFragment.attachment,
      fragmentName: "attachmentItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getOrganizationSubjectsWithPlannerElementDepthLabelSetsFromCache = ({
  id,
  subjectFilters,
  plannerDepthFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationSubjectsWithPlannerElementDepthLabelSetsQuery,
      variables: {
        id,
        subjectFilters,
        plannerDepthFilters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData !== null) return _.get(queryData, "node", {});
  return {};
};

export const getPlannerElementSetNodesAndDepthLabelsFromCache = ({
  id,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPlannerElementSetNodesAndDepthLabelsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData !== null) return _.get(queryData, "node", {});
  return {};
};

export const getBasicCommentOfNodeFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getBasicCommentOfNodeQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  if (queryData !== null) return queryData;
  return {};
};
export const writeProcessJournalCommentsInCache = ({ variables, data }) => {
  try {
    client.writeQuery({
      query: getBasicCommentOfNodeQuery,
      variables,
      data,
    });
  } catch (e) {
    return {};
  }
};

export const getFilteredTagsFromCache = ({ keys }) => {
  let queryData = null;
  try {
    queryData = client.readQuery({
      query: getFilteredTags,
      variables: {
        keys,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData !== null) return queryData;
  return {};
};

export const getStudentPeriodListFromCache = variables => {
  try {
    const queryData = client.readQuery({
      query: getStudentPeriodListQuery,
      variables,
    });
    return _.get(queryData, "node", {});
  } catch (e) {
    return {};
  }
};

export const getStudentPeriodListInfoFromCache = variables => {
  try {
    const queryData = client.readQuery({
      query: getStudentPeriodListInfoQuery,
      variables,
    });
    return _.get(queryData, "node", {});
  } catch (e) {
    return {};
  }
};

export const getStudentPeriodSetsFromCache = variables => {
  try {
    const queryData = client.readQuery({
      query: getStudentPeriodSetsQuery,
      variables,
    });
    return _.get(queryData, "node", {});
  } catch (e) {
    return {};
  }
};

export const getOrganizationYearGroupsFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationYearGroupsQuery,
      variables,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData !== null) return queryData;
  return {};
};

export const getBasicYearGroupDetailsFragmentFromCache = ({ yearGroupId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `YearGroup:${yearGroupId}`,
      fragment: yearGroupFragments.basicYearGroupDetails,
      fragmentName: "basicYearGroupDetails",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationYearGroupIdsFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationYearGroupIdsQuery,
      variables,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData !== null) return queryData;
  return {};
};

export const getOrganizationExamSessionsFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationExamSessionsQuery,
      variables,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData !== null) return queryData;
  return {};
};

export const getOrganizationMultiLevelNodeTagsFromCache = ({
  organizationId,
  nodeType,
  filters = {},
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationMultiLevelNodeTagsQuery,
      variables: {
        organizationId,
        nodeType,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getOrganizationTemplatesFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationTemplatesQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationTemplatesWithHelpEnabledFieldsFromCache = ({
  id,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationTemplatesWithHelpEnabledFieldsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getStudentStaffRelationFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentStaffRelationQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
