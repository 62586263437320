import { withAsyncRouteLoading } from "UIComponents";
import StudentEvaluation from "./routes/StudentEvaluation";
import AssessmentInsight from "./routes/AssessmentInsight";

export default store => ({
  path: ":evaluationId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "assessment-evaluation"
        );
      })
  ),
  childRoutes: [AssessmentInsight(store), StudentEvaluation(store)],
});
