import gql from "graphql-tag";
import { messageFragment } from "modules/CommonFragments";

export const conversationsFragment = {
  messages: gql`
    fragment messages on Conversation {
      id
      messages(after: $after, first: $first, filters: $filters) {
        totalCount
        unreadCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            ...messageItem
          }
        }
      }
      pinMessages(after: $after, first: $first, filters: $filters) {
        pageInfo {
          endCursor
          startCursor
          hasNextPage
        }
        edges {
          node {
            ...messageItem
          }
        }
      }
    }
    ${messageFragment.message}
  `,
  basicDetails: gql`
    fragment conversationBasicDetails on Conversation {
      id
      parentType
      unreadMessageCount
      users {
        ... on Staff {
          id
          firstName
          lastName
          email
          profileImage
          designation {
            id
            roles
          }
        }
      }
    }
  `,
};
