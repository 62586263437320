import React, { Fragment, useState } from "react";
import { UIButton, I18nHOC } from "UIComponents";
import classes from "./PublicAccessWidgets.scss";
import { colors } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import * as EventTracker from "lib/eventTracker";
import { Trans } from "react-i18next";
import { goToLoginHomeScreen } from "modules/NavigationModule";
import SendResourceLink from "../Community/SendResourceLink";
import querystringify from "querystringify";

const linkStyles = {
  color: colors.blue29,
  textDecoration: "underline",
  paddingLeft: "4px",
  cursor: "pointer",
};

const SignupButton = ({ t, goToLoginHomeScreen, type }) => {
  const [showSendResourceLink, setShowSendResourceLink] = useState(false);

  const target = `public access widget - ${type}`;
  const redirect_url = encodeURIComponent(_.get(location, "pathname", "/"));

  const onSignInClick = e => {
    e.stopPropagation();
    EventTracker.recordEvent({
      eventName: "Clicked sign in",
      eventData: {
        target,
        source: "community",
      },
    });
    const currentQuery = querystringify.parse(
      _.get(window, "location.search", "")
    );
    goToLoginHomeScreen({
      isOpenInNewTab: false,
      params: {
        target,
        type: "teacherLogin",
        usertype: "staff",
        redirect_url,
        is_public_access: true,
        action: "community",
        utm_source: currentQuery.utm_source,
        utm_token: currentQuery.utm_token,
      },
    });
  };

  const onSigUpClick = e => {
    e.stopPropagation();
    EventTracker.recordEvent({
      eventName: "Clicked join for free",
      eventData: {
        target,
        source: "community",
      },
    });
    const currentQuery = querystringify.parse(
      _.get(window, "location.search", "")
    );
    goToLoginHomeScreen({
      isOpenInNewTab: false,
      params: {
        target,
        type: "organizationSelection",
        usertype: "staff",
        redirect_url,
        action: "community",
        is_public_access: true,
        utm_source: currentQuery.utm_source,
        utm_token: currentQuery.utm_token,
      },
    });
  };

  return (
    <div className={classes.signUpContainer}>
      <UIButton
        containerStyle={{ marginBottom: "40px" }}
        color="pink"
        onClick={onSigUpClick}
        className={classes.signupButton}
      >
        {t("community:join_now_free")}
      </UIButton>

      <div className={classes.signInLink}>
        <Trans i18nKey="community:already_have_an_account">
          Already have an account?
          <div style={linkStyles} onClick={onSignInClick}>
            Sign in
          </div>
        </Trans>
      </div>

      <div className={classes.getLink}>
        <Trans i18nKey="community:already_have_an_account_get_link">
          Already have an account? Enter your
          <span onClick={() => setShowSendResourceLink(true)}>email id</span>
          to get the link
        </Trans>
      </div>

      {showSendResourceLink && (
        <SendResourceLink onBackClick={() => setShowSendResourceLink(false)} />
      )}
    </div>
  );
};

const mapActionCreators = {
  goToLoginHomeScreen,
};

export default compose(
  connect(null, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] })
)(SignupButton);
