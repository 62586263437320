import React from "react";
import PropTypes from "prop-types";
import classes from "./AudioViewer.scss";
import { CancelIcon } from "SvgComponents";
import { UIModal } from "UIComponents";
import ReactAudioPlayer from "react-audio-player";
import { colors, AudioPlayerEvent } from "Constants";
class AudioViewer extends React.PureComponent {
  componentDidMount = () => {
    AudioPlayerEvent.emit("stop");
  };
  onCloseClick = e => {
    e.stopPropagation();
    this.props.closeViewer();
  };

  render() {
    const { audioUrl, closeViewer } = this.props;
    return (
      <UIModal
        isOpen={true}
        isFullScreen={true}
        onRequestClose={closeViewer}
        overlayContainer={classes.overlayContainer}
        modalContent={classes.modalContent}
      >
        <div className={classes.crossSvg} onClick={e => this.onCloseClick(e)}>
          <CancelIcon width={14} height={14}></CancelIcon>
        </div>

        <div className={classes.audioPlayerWrapper} onClick={this.onCloseClick}>
          <ReactAudioPlayer src={audioUrl} autoPlay controls />
        </div>
      </UIModal>
    );
  }
}

export default AudioViewer;
