import React, { useState, Fragment, memo } from "react";
import { I18nHOC, withLoader } from "UIComponents";
import { TagSnpModalV2 } from "AppComponents";
import classes from "./FeedsContainer.scss";
import { compose } from "react-apollo";
import { connect } from "react-redux";

import classNames from "classnames";
import LibraryItem from "../LibraryItem";
import SmallLibraryItem from "../SmallLibraryItem";
import { EVIDENCE_TYPE_ASSESSMENT } from "Constants/stringConstants";
import getEvaluationCyclesGraphql from "SnP_v2/modules/EnhanceQueries/getEvaluationCyclesGraphql";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getLELibraryLabelsMemoize } from "UnitPlans/modules/Utils";
import { EmptyState } from "@toddle-design/web";
import { LEIllustraton, NoAssignmentIllustration } from "@toddle-design/theme";

const emptyStateSubtitleStyle = {
  maxWidth: "237px",
  lineHeight: "24px",
};

const FeedsContainer = memo(props => {
  const [tagAssessmentId, updateTagAssessmentId] = useState(false);

  const onCardClicked = ({ id, mode = "view" }) => {
    props.onCardClicked({ id: id, mode });
  };

  const onPrintClick = item => {
    props.onPrintClick(item.id);
  };

  const renderItems = () => {
    const {
      list,
      readOnly,
      size,
      curriculumType,
      onDeleteClick,
      onDuplicateClick,
      onAddToCommunityClick,
      onAssignToStudentClick,
      totalNewSnpCycles,
    } = props;
    return _.map(list, (item, index) => {
      return size == "small" ? (
        <Draggable
          key={item.id}
          draggableId={`RESOURCE_ITEM:ASSESSMENT:${item.id}`}
          index={index}
        >
          {(provided, snapshot) => (
            <Fragment>
              <SmallLibraryItem
                innerRef={provided.innerRef}
                snapshot={snapshot}
                provided={provided}
                key={item.id}
                item={item}
                curriculumType={curriculumType}
                onCardClicked={onCardClicked}
                onDeleteClick={onDeleteClick}
                onDuplicateClick={onDuplicateClick}
                onPrintClick={onPrintClick}
                onAddToCommunityClick={onAddToCommunityClick}
                onAssignToStudentClick={onAssignToStudentClick}
                readOnly={readOnly}
                updateTagAssessmentId={updateTagAssessmentId}
                totalNewSnpCycles={totalNewSnpCycles}
              />
              {snapshot.isDragging && (
                <SmallLibraryItem
                  innerRef={provided.innerRef}
                  snapshot={snapshot}
                  provided={provided}
                  key={item.id}
                  item={item}
                  curriculumType={curriculumType}
                  onCardClicked={onCardClicked}
                  onDeleteClick={onDeleteClick}
                  onDuplicateClick={onDuplicateClick}
                  onPrintClick={onPrintClick}
                  onAddToCommunityClick={onAddToCommunityClick}
                  onAssignToStudentClick={onAssignToStudentClick}
                  readOnly={readOnly}
                  updateTagAssessmentId={updateTagAssessmentId}
                  totalNewSnpCycles={totalNewSnpCycles}
                />
              )}
            </Fragment>
          )}
        </Draggable>
      ) : (
        <LibraryItem
          key={item.id}
          item={item}
          onCardClicked={onCardClicked}
          onDeleteClick={onDeleteClick}
          onDuplicateClick={onDuplicateClick}
          onPrintClick={onPrintClick}
          onAddToCommunityClick={onAddToCommunityClick}
          readOnly={readOnly}
        />
      );
    });
  };

  const renderNoDataComp = () => {
    const {
      size,
      assessmentTypeDetail,
      t,
      filters: { searchText = "" },
      assessmentType,
      i18n,
    } = props;

    if (size == "small") {
      return (
        <div className={classes.noSearchContainer}>
          <div className={classes.noSearchTopContainer}>
            <EmptyState
              illustration={
                assessmentType === "LEARNING_ENGAGEMENT"
                  ? LEIllustraton
                  : NoAssignmentIllustration
              }
              subtitle={
                _.size(searchText) > 0
                  ? t("common:no_search_msg_label", { label: searchText })
                  : getLELibraryLabelsMemoize({
                      assessmentType,
                      assessmentTypeDetail,
                      labelType: "emptyText",
                      t,
                      lng: _.get(i18n, "language", "en"),
                    })
              }
              subtitleStyle={emptyStateSubtitleStyle}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const getTagSnpModalV2Element = () => {
    const { list, curriculumProgramId } = props;
    const tagAssessmentItem = _.find(list, item => item.id === tagAssessmentId);

    const itemTitle = _.get(tagAssessmentItem, "title.value", "");

    const thumbnailData = _.get(tagAssessmentItem, "image", {});

    const taggedPractices = _.get(tagAssessmentItem, "taggedPractices", []);

    const closeModal = () => updateTagAssessmentId("");

    return tagAssessmentId ? (
      <TagSnpModalV2
        curriculumProgramId={curriculumProgramId}
        itemId={tagAssessmentId}
        itemTitle={itemTitle}
        thumbnailData={thumbnailData}
        taggedPractices={taggedPractices}
        closeModal={closeModal}
        itemType={EVIDENCE_TYPE_ASSESSMENT}
      />
    ) : null;
  };

  const { list, size } = props;

  const itemsContainer = classNames(
    { [classes.itemsContainer]: true },
    { [classes.itemsContainerSmall]: size == "small" }
  );

  return (
    <Fragment>
      <Droppable droppableId="RESOURCE_LIST" isDropDisabled={true}>
        {(provided, snapshot) => (
          <div
            className={itemsContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {_.size(list) > 0 ? renderItems() : renderNoDataComp()}
          </div>
        )}
      </Droppable>
      {getTagSnpModalV2Element()}
    </Fragment>
  );
});

FeedsContainer.displayName = FeedsContainer;

FeedsContainer.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  const filters = _.get(state, "assessment.filters", {});
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  const curriculumType = ownProps.curriculumType;

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  const curriculumProgramId = _.get(
    _.find(
      organizationCurriculumPrograms,
      ({ type }) => type === curriculumType
    ),
    "id",
    ""
  );

  return {
    filters,
    organizationId,
    curriculumProgramId,
    loginActiveTab: _.get(state, "login.activeTab", ""),
  };
};

const mapActionCreators = {};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators),
  getEvaluationCyclesGraphql,
  withLoader
)(FeedsContainer);

FeedsContainer.defaultProps = {
  totalNewSnpCycles: 0,
};
