import React, { useMemo } from "react";
import { compose } from "react-apollo";
import { withLoader } from "UIComponents";
import ModalComponent from "../ModalComponent";

const ModalComponentWrapper = props => {
  const {
    graphqlEnhancerQueryInfo: { enhancerQuery, enhancerVariables = {} },
  } = props;

  const Component = useMemo(
    () => compose(enhancerQuery, withLoader)(ModalComponent),
    []
  );
  const newProps = {
    ...props,
    isData: true,
    isLoading: false,
    ...enhancerVariables,
  };
  return <Component {...newProps} />;
};

export default ModalComponentWrapper;
