import React, { useEffect } from "react";
import classes from "./VerifyPhoneOTP.scss";
import { UIButton, OtpInput } from "UIComponents";
import { colors, fontStyle } from "Constants";

const INPUT_LENGTH = 6;

const styles = {
  inputStyle: {
    width: "100%",
    height: "112px",
    margin: "4px",
    fontSize: "3.2rem",
    borderRadius: 4,
    border: "1px solid",
    borderColor: colors.strokeOne,
    outline: "none",
    ...fontStyle.medium,
  },
  focusStyle: {
    borderColor: colors.gray48,
    outline: "none",
  },
};

const VerifyPhoneOTP = props => {
  const {
    generateOTPForParent,
    customStyle: {
      containerStyle,
      headerStyle,
      subTextStyle,
      buttonStyle,
    } = {},
    renderBackButtonComponent,
    data: { phoneNo, otp } = {},
    updateCurrentScreen,
    updateInputField,
    isLoading,
    renderCreateAccountPrivacyText,
    verifyParentOTP,
  } = props;

  useEffect(() => {
    if (!phoneNo) {
      generateOTPForParent({ isPhone: true });
    }
  }, []);

  const onBackClick = () => {
    updateCurrentScreen({ type: "forgotPhonePassword" });
    updateInputField({});
  };

  const onResendClick = () => {
    updateInputField({});
    generateOTPForParent({ showMessage: true, isPhone: true });
  };

  const onConfirmClick = () => {
    verifyParentOTP({ isPhone: true });
  };

  const onEnter = () => {
    const { data: { otp } = {} } = props;
    const isPending = _.get(otp, "length", 0) != INPUT_LENGTH;
    if (isPending) {
      return;
    }
    onConfirmClick();
  };

  const onChangeOTP = otp => {
    const { updateInputField } = props;
    updateInputField({ otp });
  };

  return (
    <div className={classes.container} style={containerStyle}>
      {renderBackButtonComponent({ onClick: onBackClick })}
      <div style={headerStyle}> {`Verify your phone number`} </div>
      <div style={subTextStyle}>
        {
          <span>
            We just texted a six-digit code to{" "}
            <span className={classes.phoneText}>
              {phoneNo ? `+${phoneNo}` : ""}
            </span>
            {`. Enter the code below to confirm your phone number. `}
            <span className={classes.resendButton} onClick={onResendClick}>
              Resend Code
            </span>
          </span>
        }
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.inputsContainer}>
          <OtpInput
            inputStyle={styles.inputStyle}
            focusStyle={styles.focusStyle}
            numInputs={INPUT_LENGTH}
            hasErrored={false}
            onChange={onChangeOTP}
            separator={<span />}
            isInputNum={false}
            shouldAutoFocus
            value={otp}
            isDisabled={!!isLoading}
            onEnter={onEnter}
          />
        </div>
        <div className={classes.bottomContainer}>
          {renderCreateAccountPrivacyText({
            buttonText: "Verify",
          })}
          <div className={classes.confirmButton}>
            <UIButton
              size="lg"
              type="filled"
              color="pink"
              onClick={onConfirmClick}
              isDisabled={_.get(otp, "length", 0) != INPUT_LENGTH}
              containerStyle={buttonStyle}
            >
              {"Verify"}
            </UIButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPhoneOTP;
