import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./User.scss";
import { I18nHOC } from "UIComponents";
import {
  getUserFeedQuery,
  getAllOrganizationForFilter,
} from "Tooling/modules/ToolingQueries";
import { getUserFeedFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
import { withLoader, SearchBar, Loading } from "UIComponents";
import Header from "./components/Header";
import FilterHeader from "./components/FilterHeader";
import { FullScreenLoader } from "UIComponents";
import FeedList from "AppComponents/FeedList";
import { goToBack } from "modules/NavigationModule";
import {
  changeUserSearchString,
  updateFilters,
  changeSearchTerm,
} from "Tooling/modules/ToolingModule";
import { getGlobalConstantsQuery } from "modules/CommonQuery";
import { EditUserInfo } from "./components/EditUser";

import { ChangePassword } from "AppComponents";
import { checkScrollAtBottom } from "Utils";

const UserList = compose(withLoader)(FeedList);
const parentStyle = {
  gridTemplateColumns: "20% 20% 30% 15% 10%",
  gridColumnGap: "20px",
};
const columnList = () => [
  {
    label: "User Name",
    id: "name",
    type: "NAMEWITHIMAGE",
  },
  {
    label: "Designation",
    id: "designation",
    type: "TEXTCOLUMN",
  },
  {
    label: "Email ID",
    id: "email",
    type: "TEXTCOLUMN",
  },
  {
    label: "Organization",
    id: "organization",
    type: "TEXTCOLUMN",
  },
  {
    label: "Actions",
    locale: "common:action_plural",
    id: "action",
    type: "ACTIONS",
  },
];

class User extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isLoading: false,
      showDialogueBox: false,
      teacherId: "",
      dialogueBoxScreen: null,
      teacherModal: false,
    };
  }

  openModalCreateModeal = () => {
    this.setState({ mode: "create" });
  };

  closeModal = () => {
    this.setState({
      showDialogueBox: false,
      studentId: "",
      dialogueBoxScreen: null,
    });
  };

  changeSearchTerm = value => {
    this.props.changeUserSearchString({ users: value });
  };

  getActionMenu = staff => {
    const actions = [
      {
        label: "Edit User",
        clickAction: () => this.onClickEditTeacher(staff.id),
        svg: null,
        isDisable: false,
      },
      {
        key: "changePassword",
        label: "Reset Password",
        svg: null,
        isDisable: false,
        clickAction: () => this.onClickChangePassword(staff.id),
      },
    ];
    return actions;
  };

  onActionClick = ({ route, id }) => {
    this.setState({
      showDialogueBox: true,
      dialogueBoxScreen: route,
      teacherId: id,
    });
  };

  onClickEditTeacher = itemId => {
    this.onActionClick({
      route: "editTeacherDetails",
      id: itemId,
    });
  };

  onClickChangePassword = itemId => {
    this.onActionClick({
      route: "changePassword",
      id: itemId,
    });
  };

  getDialogueBox = () => {
    const { designationList } = this.props;
    const { showDialogueBox, dialogueBoxScreen, teacherId } = this.state;
    if (showDialogueBox) {
      switch (dialogueBoxScreen) {
        case "editTeacherDetails":
          return (
            <EditUserInfo
              closeModal={this.closeModal}
              teacherId={teacherId}
              designationList={designationList}
            />
          );
        case "changePassword":
          return (
            <ChangePassword
              closeModal={this.closeModal}
              teacherId={teacherId}
            />
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  getFeed = () => {
    const { users } = this.props;
    return _.map(users, user => {
      const {
        id,
        firstName,
        lastName,
        profileImage,
        designation,
        email,
        role,
        organization,
      } = _.get(user, "node", {}) || {};
      return {
        id: id,
        name: {
          name: firstName + " " + lastName,
          profileImage: profileImage,
        },
        designation: _.get(designation, "title") || role,
        email,
        organization: _.get(organization, "name", ""),
        action: this.getActionMenu(user.node),
      };
    });
  };

  onScroll = e => {
    const { hasNextPage, networkStatus, loadMoreResult } = this.props;
    const containerDiv = e.target;

    if (
      containerDiv &&
      hasNextPage &&
      networkStatus != 3 &&
      checkScrollAtBottom(containerDiv)
    ) {
      loadMoreResult();
    }
  };

  render() {
    const {
      isData,
      isLoading,
      totalCount,
      networkStatus,
      organizationList,
      changeSearchTerm,
      updateFilters,
      filters,
      designationList,
    } = this.props;
    const { isLoading: isStateLoading } = this.state;
    return (
      <div className={classes.container} onScroll={e => this.onScroll(e)}>
        <div className={classes.innerContainer}>
          <Header goToBack={this.props.goToBack}></Header>
          <div className={classes.header}>
            <div className={classes.innerHeader}>
              <div className={classes.leftHeader}>
                <SearchBar
                  shouldAnimate={false}
                  wrapperType={"box"}
                  name="searchText"
                  placeholder={`Search by User name or Email ID`}
                  ref={ref => (this.searchBar = ref)}
                  changeSearchTerm={this.changeSearchTerm}
                  searchTerm={this.props.searchText}
                />
              </div>
              <div className={classes.rightHeader}>{`${totalCount} Users`}</div>
            </div>

            <FilterHeader
              organizationList={organizationList}
              changeSearchTerm={changeSearchTerm}
              filters={filters}
              updateFilters={updateFilters}
            />
          </div>

          <UserList
            parentStyle={parentStyle}
            columnList={columnList()}
            feedData={this.getFeed()}
            fetchMoreFeed={this.fetchMoreFeed}
            isData={isData}
            isLoading={isLoading}
          />
        </div>
        {networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        {isStateLoading && <FullScreenLoader></FullScreenLoader>}
        {this.getDialogueBox()}
      </div>
    );
  }
}

const mapActionCreators = {
  goToBack,
  changeUserSearchString,
  changeSearchTerm,
  updateFilters,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    mode: ownProps.mode,
    searchText: state.tooling.searchString.users,
    filters: state.tooling.filters,
  };
};

export default compose(
  I18nHOC({ resource: ["toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getUserFeedQuery, {
    name: "getUserFeed",
    options: ({ searchText, filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        searchText: searchText,
        organizations: filters.organizations,
      },
    }),
    props({
      getUserFeed,
      ownProps: { isData, isLoading, searchText, filters },
    }) {
      const userDetails = getUserFeedFromCache({
        searchText,
        organizations: filters.organizations,
      });

      const users = _.get(userDetails, "edges", []);
      const totalCount = _.get(userDetails, "totalCount", 0);
      return {
        totalCount,
        users,
        getUserFeed,
        hasNextPage: _.get(userDetails, "pageInfo.hasNextPage", false),
        networkStatus: getUserFeed["networkStatus"],
        isData: !_.isEmpty(userDetails) && isData,
        isLoading:
          getUserFeed["networkStatus"] == 1 ||
          getUserFeed["networkStatus"] == 2 ||
          isLoading,
        loadMoreResult: () => {
          return getUserFeed.fetchMore({
            query: getUserFeedQuery,
            variables: {
              after: userDetails.pageInfo.endCursor,
              searchText,
              organizations: filters.organizations,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousFeed = previousResult.platform.userFeed;
              const nextFeed = fetchMoreResult.platform.userFeed;

              const previousEdges = previousFeed.edges;
              const nextEdges = nextFeed.edges;

              const previousPageInfo = previousFeed.pageInfo;
              const nextPageInfo = nextFeed.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;
              return {
                ...previousResult,
                platform: {
                  ...previousResult.platform,
                  userFeed: {
                    ...previousResult.platform.userFeed,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(getAllOrganizationForFilter, {
    name: "getAllOrganizationDetailsForFilter",
    options: () => ({
      fetchPolicy: "cache-and-network",
    }),
    props({
      getAllOrganizationDetailsForFilter,
      ownProps: { organizationId, isData, isLoading },
    }) {
      const queryData = _.get(
        getAllOrganizationDetailsForFilter,
        "platform",
        {}
      );
      const organizationList = _.map(
        _.get(queryData, "allOrganization"),
        ({ id, name }) => {
          return { label: name, value: id };
        }
      );
      return {
        isData: !_.isEmpty(queryData) && isData,
        organizationList,
        isLoading:
          getAllOrganizationDetailsForFilter["networkStatus"] == 1 ||
          getAllOrganizationDetailsForFilter["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getGlobalConstantsQuery, {
    name: "getGlobalConstants",
    options: () => ({
      fetchPolicy: "cache-and-network",
    }),
    props({ getGlobalConstants, ownProps: { isData, isLoading } }) {
      const queryData = _.get(getGlobalConstants, "globalConstants", {});

      const designationList = _.map(
        _.get(queryData, "designations"),
        ({ id, title, description }) => {
          return { label: title, value: id, subText: description };
        }
      );

      return {
        designationList,
        isData: _.get(getGlobalConstants, "globalConstants", {}) && isData,
        isLoading:
          getGlobalConstants["networkStatus"] == 1 ||
          getGlobalConstants["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(User);
