import React from "react";
import classes from "./AcademicYearsSetup.scss";
import moment from "moment";
import { DateDurationSelector } from "UIComponents";
import {
  AddCircleOutlined,
  CalendarScheduleYearOutlined,
  DeleteOutlined,
} from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";
import update from "immutability-helper";
import { generateRandomId } from "Utils";
import classNames from "classnames";

const stylesObj = {
  containerStyle: { width: "max-content" },
  parentContainerStyle: {
    height: "70px",
    flexDirection: "row",
  },
};

const getPreviousAcademicYears = ({ academicYears, currentAcademicYear }) => {
  return _.filter(academicYears, ({ dateDurationValue }) => {
    const { endDate } = dateDurationValue;

    const currentAcademicYearStartDate =
      currentAcademicYear.dateDurationValue.startDate;

    return moment(endDate).isBefore(moment(currentAcademicYearStartDate));
  });
};

const getNextAcademicYears = ({ academicYears, currentAcademicYear }) => {
  return _.filter(academicYears, ({ dateDurationValue }) => {
    const { startDate } = dateDurationValue;

    const currentAcademicYearEndDate =
      currentAcademicYear.dateDurationValue.endDate;

    return moment(startDate).isAfter(moment(currentAcademicYearEndDate));
  });
};
const AcademicYearComp = props => {
  const {
    t,
    academicYears,
    updateAcademicYears,
    onClickAcademicYearDelete,
    showDeleteIcon = true,
    noAcademicYearsText,
  } = props;

  const academicYearsHeaderClasses = classNames(
    "text-label-3",
    classes.academicYearsHeader
  );

  return (
    <React.Fragment>
      <div className={academicYearsHeaderClasses}>
        <span className={classes.headerLabel}>
          {t("common:academic_year_start_date")}
        </span>
        <span className={classes.headerLabel}>
          {t("common:academic_year_end_date")}
        </span>
        <span className={classes.headerLabel}>{t("common:year")}</span>
      </div>

      {_.isEmpty(academicYears) ? (
        <div className={classes.emptyContainer}>{noAcademicYearsText}</div>
      ) : (
        _.map(academicYears, year => {
          const academicYearLabel = `${moment(
            year?.dateDurationValue?.startDate
          ).format("YYYY")}-${moment(year?.dateDurationValue?.endDate).format(
            "YY"
          )}`;
          const isCurrentAcademicYear = _.get(
            year,
            "isCurrentAcademicYear",
            false
          );

          return (
            <div key={year.id} className={classes.academicYear}>
              <DateDurationSelector
                value={year?.dateDurationValue}
                startDateLabel={" "}
                endDateLabel={" "}
                containerStyle={stylesObj.containerStyle}
                updateInputField={params =>
                  updateAcademicYears({ id: year.id, params })
                }
                shouldChangeEndDateWithStartDate={true}
                minNumOfDays={365}
              />

              <div className={classes.buttonContainer}>
                <CalendarScheduleYearOutlined
                  size="xx-small"
                  variant="default"
                />
                <span className={classes.yearLabel}>{academicYearLabel} </span>
              </div>
              {showDeleteIcon && (
                <div className={classes.deleteIcon}>
                  <IconButton
                    onClick={() => onClickAcademicYearDelete(year.id)}
                    icon={<DeleteOutlined />}
                    variant={"plain"}
                    size={"medium"}
                    disabled={isCurrentAcademicYear}
                  />
                </div>
              )}
            </div>
          );
        })
      )}
    </React.Fragment>
  );
};

const AcademicYearsSetup = props => {
  const { t, academicYears, updateOrgObject } = props;

  const currentAcademicYear = _.find(
    academicYears,
    ay => ay.isCurrentAcademicYear
  );

  const previousAcademicYears = getPreviousAcademicYears({
    academicYears,
    currentAcademicYear,
  });

  const nextAcademicYears = getNextAcademicYears({
    academicYears,
    currentAcademicYear,
  });

  const updateAcademicYears = ({ id, params }) => {
    const dateDurationValue = _.get(
      _.find(academicYears, { id }),
      "dateDurationValue",
      {}
    );
    const indexOfYearToUpdate = _.findIndex(academicYears, { id });

    const updatedAcademicYears = update(academicYears, {
      [indexOfYearToUpdate]: {
        dateDurationValue: {
          $set: { ...dateDurationValue, ...params },
        },
      },
    });

    updateOrgObject({ academicYears: updatedAcademicYears });
  };

  const onClickAcademicYearDelete = id => {
    const updatedAcademicYears = _.filter(
      academicYears,
      year => !_.isEqual(year.id, id)
    );

    updateOrgObject({
      academicYears: updatedAcademicYears,
    });
  };

  const addPreviousAcademicYear = () => {
    const lastPreviousAcademicYear =
      _.last(previousAcademicYears) || currentAcademicYear;

    const { startDate } = lastPreviousAcademicYear.dateDurationValue;

    const newAcademicYearEndDate = moment(startDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    const newAcademicYearStartDate = moment(newAcademicYearEndDate)
      .subtract(1, "year")
      .add(1, "days")
      .format("YYYY-MM-DD");

    const newAcademicYear = {
      id: generateRandomId(),
      dateDurationValue: {
        startDate: newAcademicYearStartDate,
        endDate: newAcademicYearEndDate,
      },
    };

    const updatedPreviousAcademicYears = [
      ...previousAcademicYears,
      newAcademicYear,
    ];

    updateOrgObject({
      academicYears: [
        ...updatedPreviousAcademicYears,
        currentAcademicYear,
        ...nextAcademicYears,
      ],
    });
  };

  const addNextAcademicYear = () => {
    const lastAcademicYear = _.last(academicYears) || currentAcademicYear;

    const endDateOfLastAY = _.get(
      lastAcademicYear,
      "dateDurationValue.endDate",
      null
    );

    const startDate = moment(endDateOfLastAY)
      .add(1, "days")
      .format("YYYY-MM-DD");

    const endDate = moment(startDate)
      .add(1, "years")
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    const newAcademicYear = [
      {
        id: generateRandomId(),
        dateDurationValue: {
          startDate,
          endDate,
        },
      },
    ];
    const updatedAcademicYears = _.concat(academicYears, newAcademicYear);

    updateOrgObject({
      academicYears: updatedAcademicYears,
    });
  };

  const onClickAddAcademicYear = ({
    shouldCreatePreviousAcademicYear = false,
  } = {}) => {
    if (shouldCreatePreviousAcademicYear) {
      addPreviousAcademicYear();
    } else {
      addNextAcademicYear();
    }
  };

  const academicYearContainerHeadingClasses = classNames(
    "heading-5",
    classes.academicYearContainerHeading
  );

  return (
    <div className={classes.academicYearsContainer}>
      <div className={classes.innerContainer}>
        <div className={academicYearContainerHeadingClasses}>
          {t("onBoarding:define_current_academic_year")}
        </div>
        <AcademicYearComp
          t={t}
          academicYears={[currentAcademicYear]}
          updateAcademicYears={updateAcademicYears}
          onClickAcademicYearDelete={onClickAcademicYearDelete}
          showDeleteIcon={false}
        />
      </div>
      <div className={classes.innerContainer}>
        <div className={academicYearContainerHeadingClasses}>
          {t("onBoarding:previous_academic_years")}
        </div>
        <AcademicYearComp
          t={t}
          academicYears={previousAcademicYears}
          updateAcademicYears={updateAcademicYears}
          onClickAcademicYearDelete={onClickAcademicYearDelete}
          showDeleteIcon={true}
          noAcademicYearsText={t("common:no_with_label", {
            label: t("onBoarding:previous_academic_years"),
          })}
        />
        <div
          className={classes.addAcademicYearText}
          onClick={() =>
            onClickAddAcademicYear({ shouldCreatePreviousAcademicYear: true })
          }
        >
          <AddCircleOutlined size="xx-small" variant="link" />
          {t("common:add_with_label", {
            label: t("common:lowercase", {
              text: t("common:academic_year"),
            }),
          })}
        </div>
      </div>
      <div className={classes.innerContainer}>
        <div className={academicYearContainerHeadingClasses}>
          {t("onBoarding:upcoming_academic_years")}
        </div>
        <AcademicYearComp
          t={t}
          academicYears={nextAcademicYears}
          updateAcademicYears={updateAcademicYears}
          onClickAcademicYearDelete={onClickAcademicYearDelete}
          noAcademicYearsText={t("common:no_with_label", {
            label: t("onBoarding:upcoming_academic_years"),
          })}
          showDeleteIcon={true}
        />
        <div
          className={classes.addAcademicYearText}
          onClick={() => onClickAddAcademicYear()}
        >
          <AddCircleOutlined size="xx-small" variant="link" />
          {t("common:add_with_label", {
            label: t("common:lowercase", {
              text: t("common:academic_year"),
            }),
          })}
        </div>
      </div>
    </div>
  );
};

export default AcademicYearsSetup;
