import React from "react";
import classes from "./UISegmentedControl.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import UIBaseComponent from "UIComponents/UIBaseComponent";

const Options = ({
  options,
  value,
  onOptionClick,
  textStyle,
  optionStyle,
  tabContainerStyle,
  selectedTextStyle,
  selectedOptionStyle,
  isDisabled,
  rectangularOvalTheme,
  numberTextWrapperStyle,
  numberWrapperStyle,
}) => {
  const tabContainerClass = classNames(
    { [classes.tabcontainer]: true },
    { [classes.rectangularOvalTabCon]: rectangularOvalTheme }
  );
  const lengthOfOptions = _.get(options, "length", 0);
  const selectedOptionIndex = _.findIndex(
    options,
    option => option.value == value
  );
  return (
    <div
      className={tabContainerClass}
      style={{
        ...tabContainerStyle,
        //gridTemplateColumns: `repeat(${_.get(options, "length", 0)}, 1fr)`
      }}
    >
      {_.map(options, (item, index) => {
        const isSelected = index == selectedOptionIndex;
        const tabStyle = classNames(
          { [classes.tab]: true },
          { [classes.rectangularOvalTab]: rectangularOvalTheme },
          { [classes.tabSelected]: isSelected },
          {
            [classes.rectangularOvalTabSelected]:
              rectangularOvalTheme && isSelected,
          }
        );
        const tabTextStyle = classNames(
          { [classes.tabText]: true },
          { [classes.rectangularOvalTabText]: rectangularOvalTheme },
          { [classes.tabSelectedText]: isSelected },
          {
            [classes.rectangularOvalTabSelectedText]:
              rectangularOvalTheme && isSelected,
          }
        );
        const numberTextStyle = classNames(
          { [classes.numberText]: true },
          { [classes.tabSelectedText]: isSelected }
        );

        const numberTextWrapperClass = classNames(
          { [classes.numberTextWrapper]: true },
          {
            [classes.recOvalNumberTextWrapper]:
              isSelected ||
              lengthOfOptions - 1 == index ||
              index == selectedOptionIndex - 1,
          }
        );
        const disabledStyle = { cursor: "not-allowed", opacity: 0.5 };
        let style = isSelected
          ? { ...optionStyle, ...selectedOptionStyle }
          : optionStyle;

        style = isDisabled ? { ...style, ...disabledStyle } : { ...style };

        const textCombineStyle = isSelected
          ? { ...textStyle, ...selectedTextStyle }
          : textStyle;

        const numberCombineStyle = {
          ...textCombineStyle,
          ...numberWrapperStyle,
        };

        return (
          <div
            className={tabStyle}
            style={style}
            key={item.value}
            onClick={!isDisabled ? () => onOptionClick(item.value) : null}
          >
            <div
              className={numberTextWrapperClass}
              style={numberTextWrapperStyle}
            >
              <div
                className={tabTextStyle}
                style={textCombineStyle}
                {...(item.labelId && { id: item.labelId })}
              >
                {item.label}
              </div>
              <div className={numberTextStyle} style={numberCombineStyle}>
                {item.number}
              </div>
              {item.svgIcon && item.svgIcon}
            </div>
          </div>
        );
      })}
    </div>
  );
};

class UISegmentedControl extends UIBaseComponent {
  componentDidMount = () => {
    setTimeout(() => {
      this.initOption();
    });
  };

  initOption = () => {
    const { value, options } = this.props;
    if (!value && options.length > 0) {
      this.updateValue(options[0].value);
    }
  };

  shouldShowEditEmpty = () => {
    return _.get(this.props.options, "length", 0) == 1;
  };

  renderEdit = () => {
    const {
      options,
      value,
      optionStyle,
      selectedOptionStyle,
      tabContainerStyle,
      textStyle,
      selectedTextStyle,
      isDisabled,
      rectangularOvalTheme,
      numberTextWrapperStyle,
      numberWrapperStyle,
    } = this.props;

    return (
      <Options
        options={options}
        value={value}
        isDisabled={isDisabled}
        onOptionClick={this.updateValue}
        optionStyle={optionStyle}
        tabContainerStyle={tabContainerStyle}
        textStyle={textStyle}
        selectedTextStyle={selectedTextStyle}
        selectedOptionStyle={selectedOptionStyle}
        rectangularOvalTheme={rectangularOvalTheme}
        numberTextWrapperStyle={numberTextWrapperStyle}
        numberWrapperStyle={numberWrapperStyle}
      />
    );
  };
}

UISegmentedControl.defaultProps = {
  ...UIBaseComponent.defaultProps,
  optionStyle: {},
  options: [],
  textStyle: {},
  selectedTextStyle: {},
  selectedOptionStyle: {},
  tabContainerStyle: {},
  numberWrapperStyle: {},
  rectangularOvalTheme: false,
};

UISegmentedControl.propTypes = {
  ...UIBaseComponent.propTypes,
  optionStyle: PropTypes.object,
  options: PropTypes.array,
  textStyle: PropTypes.object,
  selectedTextStyle: PropTypes.object,
  selectedOptionStyle: PropTypes.object,
  tabContainerStyle: PropTypes.object,
  rectangularOvalTheme: PropTypes.bool,
};

export default UISegmentedControl;
