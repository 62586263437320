import gql from "graphql-tag";

export const subjectFlatVariantsCountItems = {
  courseCountItem: gql`
    fragment courseCountItem on SubjectCourseConnection {
      totalCount
      edges {
        node {
          id
          title
        }
      }
    }
  `,
  unitPlanCountItem: gql`
    fragment unitPlanCountItem on SubjectUnitPlanConnection {
      totalCount
      edges {
        node {
          id
        }
      }
    }
  `,
};
