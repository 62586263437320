import {
  getTotalCommentOfNode,
  commentParentTypeToEntityMapping,
} from "./Services";
import { getPlannerFieldsOfNode } from "UnitPlans/modules/UnitPlanModule";
import { getUnitPlanCollaboratorsDetails } from "IBPlanner/modules/IBPlannerModule";
import { PusherEvent, PresenceEvent, BlurFieldEvent } from "Constants";
import * as Ably from "ably/browser/static/ably-commonjs.js";
import { PUSHER_APP_KEY } from "store/static";
import update from "immutability-helper";
import { getConversations } from "ChatContainer/modules/ChatContainerModule";

export const NAME = "pusher";
export const HANDLE_FOCUSED_FIELD = "HANDLE_FOCUSED_FIELD" + " " + NAME;
export const INIT_LOCKED_FIELD = "INIT_LOCKED_FIELD" + " " + NAME;

export let current_connection = null;

const PUSHER_EVENTS_MAPPING = {
  "notification-added": ["notificationAdded"],
  project_field_update: ["projectFieldUpdate"],
  unit_plan_created: ["unitPlanFeedUpdate"],
  assessment_changed: ["unitPlanResourceLibraryFeedUpdate"],
  le_changed: ["unitPlanResourceLibraryFeedUpdate"],
  unit_flow_changed: ["unitFlowFeedUpdate"],
  unit_flow_section_updated: ["unitFlowSectionUpdate"],
  unit_flow_child_resources_updated: ["unitFlowChildResourcesUpdated"],
  unit_flow_resources_updated: ["unitFlowResourcesUpdated"],
  unit_plan_resources_updated: ["unitPlanResourcesUpdated"],
  unit_plan_child_resources_updated: ["unitPlanChildResourcesUpdated"],
  assessment_resources_updated: ["assessmentResourcesUpdated"],
  resource_changed: ["resourcesFeedUpdate"],
  unit_plan_updated: ["unitPlanFieldUpdate"],
  unit_collaborator_added: ["unitPlanCollaboratorUpdate"],
  assessment_updated: ["assessmentFieldUpdate"],
  le_updated: ["leFieldUpdate"],
  "comment-added": ["nodeTotalCommentUpdate", "nodeCommentFeedUpdate"],
  USER_MESSAGE_RECEIVED: ["chatMessageReceived"],
  USER_MESSAGE_UPDATED: ["chatMessageReceived"],
  USER_MESSAGE_DELETED: ["chatMessageReceived"],
  USER_MESSAGE_READ: ["chatMessageReadReceipt"],
  STUDENT_ASSIGNMENT_SUBMISSION_REAL_TIME: ["studentAssignmentSubmitted"],
  ZOOM_MEETING_REAL_TIME: ["zoomMeetingUpdate"],
  ASSIGNMENT_CREATE: ["assignmentFeedUpdate"],
  ASSIGNMENT_DELETE: ["assignmentFeedUpdate"],
  ASSIGNMENT_UPDATE: ["assignmentFeedUpdate"],
  ASSIGNMENT_ARCHIVE: ["assignmentFeedUpdate"],
  ASSIGNMENT_UNARCHIVE: ["assignmentFeedUpdate"],
  ASSIGNMENT_MEETING_SCHEDULED: ["startingSoonAssignmentFeedUpdate"],
  STUDENT_ASSIGNMENT_SUBMISSION_FEEDBACK: ["assignmentFeedUpdate"],
  CALENDAR_EVENT_ITEM: [
    "startingSoonAssignmentFeedUpdate",
    "calendarEventsUpdate",
  ],
  CALENDAR_EVENT_ITEM_REMOVE_UPCOMING: ["startingSoonAssignmentFeedUpdate"],
  PLATFORM_CALENDAR_EVENT_CREATE: ["calendarEventsUpdate"],
  PLATFORM_CALENDAR_EVENT_UPDATE: ["calendarEventsUpdate"],
  PLATFORM_CALENDAR_EVENT_DELETE: ["calendarEventsUpdate"],
  ASSIGNMENT_MARK_COMPLETED: ["assignmentDetailsUpdate"],
  SUB_TASK_CHANGED: ["subTaskChanged"],
  ATTACHMENT_SIMILARITY_REPORT_UPDATE: ["attachmentSimilarityReportUpdate"],
  STUDENT_PROGRESS_REPORT_STATUS_UPDATE: ["studentProgressReportStatusUpdate"],
  JOB_FEED_UPDATE: ["JobFeedUpdate"],
  STUDENT_PROGRESS_REPORT_SHARED: ["studentProgressReportShared"],
};

export const TYPE_TO_PRESENCE_EVENTS_MAPPING = {
  unitPlan: "updateUnitPlanLock",
  le: "updateLeLock",
  assessment: "updateAssessmentLock",
  project: "updateProjectLock",
};

export const TYPE_TO_BLUR_FIELD_EVENTS_MAPPING = {
  unitPlan: "blurUnitPlanField",
  le: "blurLeField",
  assessment: "blurAssessmentField",
  project: "blurProjectField",
};

export const getFieldLockedObject = _.memoize(
  ({ uids, lockedFields }) => {
    const lockedField = _.find(
      lockedFields,
      field => _.includes(uids, field.uid) && !field.id
    );
    const lockedDynamicFields = _.filter(lockedFields, field =>
      _.includes(uids, field.uid)
    );
    return { lockedField, lockedDynamicFields };
  },
  params => JSON.stringify(params)
);

export const initLockedFields = data => {
  return { type: INIT_LOCKED_FIELD, data };
};

export const handleFocuedField = data => {
  return { type: HANDLE_FOCUSED_FIELD, data };
};

export const toggleFocusedField = data => {
  return (dispatch, getState) => {
    const { type, focusedField = {}, isFocused } = data;
    const lockedFields = getState().pusher.lockedFields[type];
    const lockedField = _.find(
      lockedFields,
      field =>
        field.id == _.get(focusedField, "id", "") &&
        field.uid == _.get(focusedField, "uid", "")
    );
    if (!lockedField) {
      const event = TYPE_TO_PRESENCE_EVENTS_MAPPING[type];

      if (event) {
        PresenceEvent.emit(event, { isFocused, focusedField });
      }
      dispatch(handleFocuedField(data));
    }
  };
};

export const setPusherConnection = userId => {
  return (dispatch, getState) => {
    current_connection = new Ably.Realtime({
      key: PUSHER_APP_KEY,
      clientId: userId,
    });
  };
};

const getChannelName = ({ data = {}, channelType }) => {
  const {
    organizationId,
    userId,
    unitPlanId,
    unitFlowSectionId,
    leId,
    assessmentId,
    projectId,
  } = data;
  let channelName = null;
  switch (channelType) {
    case "user":
      channelName = `user_${organizationId}_${userId}`;
      break;

    case "unitPlan":
      channelName = `org_${organizationId}_unitplan_${unitPlanId}`;
      break;

    case "uniflowSection":
      channelName = `org_${organizationId}_unitflowsection_${unitFlowSectionId}`;
      break;

    case "le":
      channelName = `org_${organizationId}_le_${leId}`;
      break;

    case "assessment":
      channelName = `org_${organizationId}_assessment_${assessmentId}`;
      break;
    case "project":
      channelName = `project_${organizationId}_${projectId}`;
      break;
  }
  return channelName;
};

export const getChannelInstance = ({ data = {}, channelType }) => {
  const conn = current_connection;
  if (!conn) {
    return null;
  }

  const channelName = getChannelName({ data, channelType });
  const channel = conn.channels.get(channelName);
  return channel;
};

///Normal channel

const bindPusherEvent = ({
  eventData: { metadata = "{}" },
  events,
  data,
  eventName,
}) => {
  return (dispatch, getState) => {
    const { authorId, fields, parentId, parentType, conversation_id } =
      JSON.parse(metadata) || {};

    const { unitPlanId, leId, assessmentId } = data;

    if (!(!!authorId && parseInt(authorId) == data.userId)) {
      _.map(events, event => {
        switch (event) {
          case "nodeTotalCommentUpdate":
            dispatch(
              getTotalCommentOfNode({
                id: parentId,
                type: commentParentTypeToEntityMapping(parentType),
              })
            );
            break;
          case "chatMessageReceived":
            dispatch(
              getConversations({
                parentId,
                parentType,
                conversationId: conversation_id,
              })
            );
            PusherEvent.emit(event, { event, ...(JSON.parse(metadata) || {}) });
            break;
          case "unitPlanFieldUpdate":
            dispatch(
              getPlannerFieldsOfNode({
                type: "UNIT_PLAN",
                uids: fields,
                id: unitPlanId,
              })
            );
            PusherEvent.emit(event, { event, ...(JSON.parse(metadata) || {}) });
            break;
          case "unitPlanCollaboratorUpdate":
            dispatch(getUnitPlanCollaboratorsDetails({ id: unitPlanId }));
            break;
          case "leFieldUpdate":
            dispatch(
              getPlannerFieldsOfNode({
                type: "LEARNING_ENGAGEMENT",
                uids: fields,
                id: leId,
              })
            );
            break;
          case "assessmentFieldUpdate":
            dispatch(
              getPlannerFieldsOfNode({
                type: "ASSESSMENT",
                uids: fields,
                id: assessmentId,
              })
            );
            break;
          default:
            PusherEvent.emit(event, { event, ...(JSON.parse(metadata) || {}) });
        }
      });
    }
  };
};

export const subscribeToChannel = ({ data = {}, channelType }) => {
  return async (dispatch, getState) => {
    const conn = current_connection;
    if (!conn) {
      return;
    }

    const channelName = getChannelName({ data, channelType });
    const channel = conn.channels.get(channelName);

    channel.subscribe(message => {
      const eventData = message.data;
      const eventName = message.name;
      const events = PUSHER_EVENTS_MAPPING[eventName];

      dispatch(bindPusherEvent({ eventData, events, eventName, data }));
    });
  };
};

export const unSubscribeToChannel = ({ data = {}, channelType }) => {
  return async (dispatch, getState) => {
    const conn = current_connection;
    if (!conn) {
      return;
    }
    const channelName = getChannelName({ data, channelType });
    console.error("Unsubscribe", channelName);
    const channel = conn.channels.get(channelName);
    channel.unsubscribe();
  };
};

/////Presence Channel

export const bindPresenceEvents = ({ channelType, message, channel }) => {
  return (dispatch, getState) => {
    let members = [];
    if (!channel) {
      return;
    }

    let lockedFields = [];

    // const focusedField = getState().pusher.focusedField[channelType];
    // console.log("Previously Focused Field", focusedField);
    // if (focusedField) {
    //   BlurFieldEvent.emit(TYPE_TO_BLUR_FIELD_EVENTS_MAPPING[channelType], {
    //     field: focusedField
    //   });
    // }

    switch (message.action) {
      case "enter":
        if (
          message.connectionId == _.get(current_connection, "connection.id", "")
        ) {
          members = getMembersOfPresenceChannel({ channel });

          lockedFields = getLockedFieldsFromMembers(members);
          dispatch(initLockedFields({ type: channelType, lockedFields }));
          //console.log(lockedFields, "locked Field", message.action);
          const focusedField = getState().pusher.focusedField[channelType];

          // console.log("Previously Focused Field", focusedField);

          if (focusedField) {
            BlurFieldEvent.emit(
              TYPE_TO_BLUR_FIELD_EVENTS_MAPPING[channelType],
              {
                field: focusedField,
              }
            );
          }
          //dispatch(toggleFocusedField({ isFocused: false, type: channelType }));
        }

        break;
      case "leave":
      case "update":
        if (
          message.connectionId != _.get(current_connection, "connection.id", "")
        ) {
          members = getMembersOfPresenceChannel({ channel });

          lockedFields = getLockedFieldsFromMembers(members);
          dispatch(initLockedFields({ type: channelType, lockedFields }));
          //console.log(lockedFields, "locked Field", message.action);
        }
    }
  };
};

export const getLockedFieldsFromMembers = members => {
  return _.reduce(
    members,
    (result, member) => {
      const { data: { userInfo = {}, lockedField } = {} } = member;
      if (lockedField) {
        result.push({ ...lockedField, userInfo });
      }
      return result;
    },
    []
  );
};

export const getMembersOfPresenceChannel = ({ channel }) => {
  if (!channel) {
    return [];
  }
  let channelMembers = [];
  channel.presence.get(function (err, members) {
    channelMembers = members;
  });
  return _.filter(
    channelMembers,
    member =>
      member.connectionId != _.get(current_connection, "connection.id", "")
  );
};

export const updateStateToPresenceChannel = ({ channel, lockedField }) => {
  return (dispatch, getState) => {
    if (!channel) {
      return;
    }
    const {
      id,
      first_name: firstName,
      last_name: lastName,
      profile_image: profileImage,
    } = getState().login.userInfo;
    channel.presence.update({
      userInfo: { id, firstName, lastName, profileImage },
      lockedField,
    });
  };
};

export const enterPresenceChannel = ({ channel }) => {
  return (dispatch, getState) => {
    if (!channel) {
      return;
    }
    const {
      id,
      first_name: firstName,
      last_name: lastName,
      profile_image: profileImage,
    } = getState().login.userInfo;
    channel.presence.enter({
      userInfo: { id, firstName, lastName, profileImage },
      // lockedField: { uid: "title" }
    });
  };
};

export const subscribeToPresenceChannel = ({ channel, channelType }) => {
  return (dispatch, getState) => {
    if (!channel) {
      return;
    }
    dispatch(enterPresenceChannel({ channelType, channel }));

    channel.presence.subscribe(["enter", "leave", "update"], message => {
      dispatch(bindPresenceEvents({ message, channelType, channel }));
    });
  };
};

export const unSubscribeToPresenceChannel = ({ channel }) => {
  return (dispatch, getState) => {
    if (!channel) {
      return;
    }
    channel.presence.leave();
    channel.unsubscribe();
  };
};

const REDUCER_HANDLERS = {
  [HANDLE_FOCUSED_FIELD]: (state, action) => {
    const { type, focusedField, isFocused } = action.data;

    if (!isFocused) {
      state = update(state, {
        focusedField: { [type]: { $set: null } },
      });
    } else {
      state = update(state, {
        focusedField: { [type]: { $set: focusedField } },
      });
    }

    return state;
  },
  [INIT_LOCKED_FIELD]: (state, action) => {
    const { type, lockedFields } = action.data;

    state = update(state, {
      lockedFields: { [type]: { $set: lockedFields } },
    });

    return state;
  },
};

const initialState = {
  focusedField: {
    unitPlan: null,
    le: null,
    assessment: null,
    project: null,
  },
  lockedFields: {
    unitPlan: [],
  },
  hasPusherPermission: true,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
