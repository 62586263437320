import { withAsyncRouteLoading } from "UIComponents";
import Membership from "./routes/Membership";
import Overview from "./routes/Overview";

export default store => ({
  path: ":yearGroupId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-yearGroupManager"
        );
      })
  ),
  childRoutes: [Overview(store), Membership(store)],
});
