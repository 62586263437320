import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classes from "./AssessmentToolPreviewModalV2.scss";
import { withLoader, UIButton, UIModal, I18nHOC } from "UIComponents";
import { graphql, compose } from "react-apollo";
import { getAssessmentToolNodeDetailsQuery } from "modules/CommonQuery";
import { getAssessmentToolNodeDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { setToastMsg } from "Login/modules/LoginModule";
import RubricTable from "Rubric/components/RubricTable";
import ChecklistTable from "Checklist/components/ChecklistTable";
import SinglePointRubricTable from "SinglePointRubric/components/SinglePointRubricTable";
import MYPObjectiveRubric from "UnitPlans/components/IBcomponents/AssessmentTool/MYPObjectiveRubric";
import { getMYPObjectiveRubricOptions } from "modules/Services";
import { Button } from "@toddle-design/web";

const getModalContent = props => {
  const { title, onClickClose, t, commentMessage } = props;

  const renderAssessmentTool = () => {
    const {
      assessmentTool,
      type,
      mode,
      toolResponses,
      isEvaluatedByStudent,
      isTeacherEvaluationEnabled,
      unitPlanFields,
      assessmentFields,
      field_list,
      parentId,
      parentType,
      studentId,
      isEvaluated,
      ibPYPElementRatings,
      showRatingContainerInViewMode,
    } = props;

    switch (type) {
      case "RUBRIC":
        return (
          <RubricTable
            mode={mode}
            rubric={assessmentTool}
            toolEvaluationResponses={toolResponses}
            isEvaluatedByStudent={isEvaluatedByStudent}
          />
        );
      case "CHECKLIST":
        return (
          <ChecklistTable
            mode={mode}
            checklist={assessmentTool}
            toolEvaluationResponses={toolResponses}
            isEvaluatedByStudent={isEvaluatedByStudent}
          />
        );
      case "SINGLE_POINT_RUBRIC":
        return (
          <SinglePointRubricTable
            mode={mode}
            rubric={assessmentTool}
            isEvaluation={isTeacherEvaluationEnabled}
            toolEvaluationResponses={toolResponses}
            isEvaluatedByStudent={isEvaluatedByStudent}
          />
        );
      case "MYP_OBJECTIVE_RUBRIC":
        return (
          <MYPObjectiveRubric
            assessmentTool={assessmentTool}
            mode={mode}
            parentId={parentId}
            parentType={parentType}
            studentId={studentId}
            isEvaluated={isEvaluated}
            options={getMYPObjectiveRubricOptions({
              unitPlanFields,
              assessmentFields,
              field_list,
            })}
            toolEvaluationResponses={toolResponses}
            isEvaluatedByStudent={isEvaluatedByStudent}
            ibPYPElementRatings={ibPYPElementRatings}
            showRatingContainerInViewMode={showRatingContainerInViewMode}
          />
        );
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <div className={classes.modalHeader}>
        <span>{title ? title : t("common:no_title")}</span>
        <span className={classes.actionButtons} style={{ marginRight: "16px" }}>
          <Button variant="outlined" size={"medium"} onClick={onClickClose}>
            {t("common:cancel")}
          </Button>
        </span>
      </div>
      <div className={classes.modalBody}>
        <div className={classes.innerWrapper}>{renderAssessmentTool()}</div>
        {commentMessage ? (
          <div className={classes.commentContainer}>
            <div className={classes.commentHeader}>
              {t("common:teachers_remark")}
            </div>
            <div className={classes.commentText}>{commentMessage}</div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const AssessmentToolModalWithLoader = withLoader(getModalContent);
class AssessmentToolPreviewModalV2 extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClickClose } = this.props;

    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={onClickClose}
      >
        <AssessmentToolModalWithLoader {...this.props} />
      </UIModal>
    );
  }
}

AssessmentToolPreviewModalV2.defaultProps = {
  isEvaluated: true,
};

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getAssessmentToolNodeDetailsQuery, {
    name: "getAssessmentToolNodeDetails",
    options: ({ id, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id, type },
    }),
    props({
      getAssessmentToolNodeDetails,
      ownProps: { isData, isLoading, id, type },
    }) {
      const queryData = getAssessmentToolNodeDetailsFromCache({ id, type });
      const networkStatus = _.get(
        getAssessmentToolNodeDetails,
        "networkStatus"
      );
      return {
        assessmentTool: queryData,
        isData: !_.isEmpty(queryData) && isData,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  })
)(AssessmentToolPreviewModalV2);
