import client from "apolloClient";

import {
  organizationPresetFragment,
  organizationStaffFeedFragment,
} from "./ToolingFragments.js";
import {
  getOrganizationFeedQuery,
  getTeacherFeedQuery,
  getUserFeedQuery,
  getStandardFeedQuery,
  getAcademicYears,
  getConfigurableSettingsQuery,
  getProgressReportBaseTemplateListQuery,
  getProgressReportBaseTemplateQuery,
  getOrganizationFeedTotalCountQuery,
  getInternalToolsGenericQuery,
} from "./ToolingQueries";

export const getProgressReportBaseTemplateListFromCache = () => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProgressReportBaseTemplateListQuery,
      variables: {},
    });
  } catch (error) {
    console.error(error);
  }
  if (queryData != null)
    return _.get(queryData, "documentation.progressReportBaseTemplateList", {});
  else return {};
};

export const getProgressReportBaseTemplateFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProgressReportBaseTemplateQuery,
      variables: {
        id,
      },
    });
  } catch (error) {
    console.error(error);
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeOrganizationPresetItem = ({ organizationId, data }) => {
  try {
    client.writeFragment({
      id: `OrganizationPreset:${organizationId}`,
      fragment: organizationPresetFragment.orgItem,
      fragmentName: "organizationItem",
      data: data,
    });
  } catch (e) {}
};

export const getOrganizationFeedFromCache = ({ filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationFeedQuery,
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null)
    return _.get(queryData, "platform.organizationFeed", {});
  else return {};
};

//User Feed

export const getUserFeedFromCache = ({ searchText, organizations }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUserFeedQuery,
      variables: {
        searchText,
        organizations,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "platform.userFeed", {});
  else return {};
};

export const getStandardFeedFromCache = ({ filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStandardFeedQuery,
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null)
    return _.get(queryData, "planner.standardBenchmarkSetFeed", {});
  else return {};
};

export const getStaffFeedForOrganizationFromCache = ({
  organizationId,
  filters,
  after,
  orderBy,
  orderByDirection,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getTeacherFeedQuery,
      variables: {
        orderBy,
        orderByDirection,
        filters,
        after,
        organizationId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeStaffFeedForOrganizationInCache = ({
  organizationId,
  filters,
  after,
  data,
}) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${organizationId}`,
      fragment: organizationStaffFeedFragment.organizationStaffFeedEdge,
      fragmentName: "organizationStaffFeedEdgeItem",
      variables: {
        after,
        filters,
      },
      data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAcademicYearsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAcademicYears,
      variables: {
        id: id,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) {
    return _.get(queryData, "node", {});
  } else return {};
};

export const getConfigurableSettingsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getConfigurableSettingsQuery,
      variables: {
        id: id,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) {
    return _.get(queryData, "node", {});
  } else return {};
};

export const getOrganizationFeedTotalCountFromCache = ({ filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationFeedTotalCountQuery,
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null)
    return _.get(queryData, "platform.organizationFeed", {});
  else return {};
};

export const getInternalToolsGenericQueryFromCache = ({ apiType, params }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getInternalToolsGenericQuery,
      variables: {
        apiType,
        params,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) {
    const data = _.get(queryData, "platform.internalToolsGenericQuery", {});
    if (data.isSuccess) return data.data;
  } else return {};
};
