import React from "react";
import classes from "./DropDown.scss";
import { withRouter } from "react-router";
import { ButtonDropdown } from "UIComponents";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
import { useTranslation } from "react-i18next";
import ACLStore from "lib/aclStore";
import {
  SNPv2_TEACHER_MODULE_PERM,
  SNPv2_PERM,
} from "Constants/permissionConstants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { isMapleBearLikeEducator } from "Courses/modules/utils";
import { getIsCurrentAcademicYear } from "Platform/modules/PlatformModule";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import {
  findCountryUser,
  isGlobalUser,
} from "routes/routes/Teacher/routes/Courses/modules/utils";

const buttonDropDownContainerStyle = {
  marginTop: -12,
  minWidth: 120,
  width: "max-content",
  right: 0,
};

const buttonDropDownItemStyle = {
  height: 32,
};

const checkCustomPermission = ({ key, customPermissionParams }) => {
  switch (key) {
    case "TAG_SNP": {
      const { totalNewSnpCycles, isAdmin } = customPermissionParams;
      const isFeatureAvailableInModule = isAdmin
        ? ACLStore.can(SNPv2_PERM)
        : ACLStore.can(SNPv2_TEACHER_MODULE_PERM) && ACLStore.can(SNPv2_PERM);

      return totalNewSnpCycles > 0 && isFeatureAvailableInModule;
    }
    default:
      return true;
  }
};

const changeLocale = (menuItems, t) => {
  const menus = [];
  _.forEach(menuItems, element => {
    const { label, perm, customPermissionParams, key } = element;
    const isItemAllowedByPerm = !perm || ACLStore.can(perm);
    const isItemAllowedByCustomPermission = checkCustomPermission({
      key,
      customPermissionParams,
    });

    if (isItemAllowedByPerm && isItemAllowedByCustomPermission) {
      menus.push({ ...element, label: t(label) });
    }
  });
  return menus;
};

const DropDown = ({
  item,
  centralRepoConfig = {},
  onDeleteClick,
  onEditClick,
  onDuplicateClick,
  onPrintClick,
  onAddToCommunityClick,
  onAssignToStudentClick,
  curriculumType,
  updateTagAssessmentId,
  totalNewSnpCycles,
  disableAssignToStudents,
  getIsCurrentAcademicYear,
}) => {
  const isAdmin = ACLStore.can("AdminPortal");
  const notCurrentAcademicYear = !getIsCurrentAcademicYear();
  const {
    shouldDisableEdit = false,
    shouldDisableDelete = false,
    isGlobalOrCountryUser,
  } = centralRepoConfig;
  let menuItems = [
    {
      label: "edit",
      key: "edit",
      clickAction: () => {
        onEditClick({ id: item.id, mode: "edit" });
      },
      svg: null,
      isDisable: shouldDisableEdit,
      curriculumsToExclude: [],
    },
    {
      label: "duplicate",
      key: "duplicate",
      clickAction: () => {
        onDuplicateClick(item);
      },
      svg: null,
      isDisable: false,
      curriculumsToExclude: [],
    },
    {
      label: "print",
      key: "print",
      clickAction: () => {
        onPrintClick(item);
      },
      svg: null,
      isDisable: false,
      curriculumsToExclude: [],
    },
    {
      label: "delete",
      key: "delete",
      clickAction: () => {
        onDeleteClick(item);
      },
      svg: null,
      isDisable: shouldDisableDelete,
      curriculumsToExclude: [],
    },
    {
      label: "add_to_community",
      key: "add_to_community",
      clickAction: () => {
        onAddToCommunityClick(item);
      },
      svg: null,
      isDisable: false,
      perm: "TeacherPortal:Community",
      curriculumsToExclude: [
        CURRICULUM_TYPE_DP,
        CURRICULUM_TYPE_MYP,
        CURRICULUM_TYPE_UBD,
      ],
    },

    {
      label: "assign_to_students",
      key: "assign_to_students",
      clickAction: () => {
        onAssignToStudentClick(item);
      },
      svg: null,
      isDisable: disableAssignToStudents || notCurrentAcademicYear,
      shouldHide: isGlobalOrCountryUser,
      curriculumsToExclude: [CURRICULUM_TYPE_DP],
    },
    {
      label: "tag_snp",
      key: "TAG_SNP",
      clickAction: () => {
        updateTagAssessmentId(itemId);
      },
      svg: null,
      isDisable: false,
      customPermissionParams: { totalNewSnpCycles, isAdmin },
      curriculumsToExclude: [],
    },
  ];

  menuItems = _.filter(menuItems, item => {
    if (item.shouldHide) {
      return false;
    }
    return !_.includes(item.curriculumsToExclude, curriculumType);
  });

  const { t } = useTranslation("common");

  const itemId = _.get(item, "id", "");

  return (
    <div className={classes.container}>
      <ButtonDropdown
        authTabs={changeLocale(menuItems, t)}
        containerStyle={buttonDropDownContainerStyle}
        buttonParentStyle={{ padding: "0px 16px", marginRight: "-16px" }}
        itemStyle={buttonDropDownItemStyle}
        buttonComponent={
          <MoreIcon width={16} height={16} fill={colors.warmGrey} />
        }
      />
    </div>
  );
};
const mapActionCreators = {
  getIsCurrentAcademicYear,
};

const mapStateToProps = (state, ownProps) => {
  const disableAssignToStudents = !_.get(ownProps, "params.course_id", null);
  const { item = {} } = ownProps;
  const { isImported = false } = item;
  const organizationId = state.login.userInfo.org_id;
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const organizationTags = _.get(organizationDetails, "tags", []);
  const isEducator = isMapleBearLikeEducator({ organizationTags });
  const shouldDisableActionsForEducator = isImported && isEducator;
  const userTags = _.get(state, "login.userInfo.tags", []);
  const isGlobalOrCountryUser = Boolean(
    isGlobalUser({ userTags }) || findCountryUser({ userTags })
  );

  const centralRepoConfig = {
    shouldDisableEdit: shouldDisableActionsForEducator,
    shouldDisableDelete: shouldDisableActionsForEducator,
    isGlobalOrCountryUser: isGlobalOrCountryUser,
  };

  return {
    centralRepoConfig,
    disableAssignToStudents,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators)
)(DropDown);
