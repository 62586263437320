import React, { useState, memo } from "react";
import { SearchBox, withLoader, I18nHOC } from "UIComponents";

import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";

import { getCommunityTagsQuery } from "OnBoarding/modules/OnBoardingQuery";
import { setToastMsg } from "Login/modules/LoginModule";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { createNewTag } from "OnBoarding/modules/OnBoardingModule";

const searchBoxStyles = {
  searchBarInputText: {
    fontSize: "1.6rem",
  },
  searchBarWithTagContainer: {
    paddingLeft: "16px",
  },
  searchBarContainer: {
    paddingLeft: 0,
  },
  tag: {
    container: {
      height: "32px",
      margin: "5px",
    },
  },
};

class SearchBoxWithTagQuery extends UIBaseComponent {
  updateField = (value, options) => {
    this.updateValue(value, options);
  };
  onSearchChange = async searchText => {
    const { getCommunityTopics, tagType } = this.props;
    if (getCommunityTopics) {
      await getCommunityTopics.refetch({ searchText, types: tagType });
    }
  };

  handleAddLabel = async value => {
    const {
      userId,
      newTagType,
      createNewTag,
      tags,
      value: selectedValue,
    } = this.props;
    const newTag = await createNewTag({
      label: value,
      user: userId,
      type: newTagType,
      key: "tags",
    });

    this.updateValue(
      _.uniq([..._.map(selectedValue, item => item.value), newTag.id]),
      _.uniqBy([...(tags || []), ...(selectedValue || []), newTag], "value")
    );
    return newTag;
  };

  render() {
    const { t, tags, value, placeholder, name, allowAddNewOption } = this.props;

    return (
      <SearchBox
        onSearchChange={this.onSearchChange}
        allowAddNewOption={allowAddNewOption}
        removeOnBackspace={true}
        updateInputField={this.updateField}
        styles={searchBoxStyles}
        placeholder={placeholder}
        isRequired={true}
        options={_.uniqBy([...(tags || []), ...(value || [])], "value")}
        value={_.map(value, item => item.value)}
        showInDropdown={true}
        inlineView={true}
        resultType={"LINE"}
        onAddLabel={this.handleAddLabel}
      />
    );
  }
}

SearchBoxWithTagQuery.defaultProps = {
  showFooter: true,
};

const mapStateToProps = (state, ownProps) => {
  return { userId: _.get(state, "login.userInfo.id") };
};

const mapActionCreators = {
  createNewTag,
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: ["community", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCommunityTagsQuery, {
    name: "getCommunityTopics",
    options: ({ searchText, tagType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        searchText,
        types: tagType,
      },
    }),
    props: ({ getCommunityTopics, ownProps }) => {
      const tags = _.get(getCommunityTopics, "community.constants.tags", []);
      return {
        tags,
        getCommunityTopics,
        ownProps,
      };
    },
  })
)(SearchBoxWithTagQuery);
