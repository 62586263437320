import PropTypes from "prop-types";
import React from "react";

const ResourcesLineIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 14"
      fill={props.fill && props.fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 4.13525C0.5 3.18863 1.27753 2.4165 2.23077 2.4165H13.7692C14.7225 2.4165 15.5 3.18863 15.5 4.13525V11.0103C15.5 12.5871 14.2032 13.8748 12.6154 13.8748H3.38462C1.79676 13.8748 0.5 12.5871 0.5 11.0103V4.13525ZM2.23077 3.56234C1.91478 3.56234 1.65385 3.82146 1.65385 4.13525V11.0103C1.65385 11.9543 2.43401 12.729 3.38462 12.729H12.6154C13.566 12.729 14.3462 11.9543 14.3462 11.0103V4.13525C14.3462 3.82146 14.0852 3.56234 13.7692 3.56234H2.23077Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15192 3.27194L2.15579 2.70703L4.10593 6.12456C4.25579 6.33968 4.4348 6.42699 4.59616 6.42699H11.3462C11.555 6.42699 11.7457 6.30037 11.8109 6.17077L11.8176 6.15747L13.7865 2.70703L14.7904 3.27194L12.8353 6.69811C12.551 7.24701 11.9396 7.57282 11.3462 7.57282H4.59616C3.95361 7.57282 3.44495 7.20594 3.13536 6.74478L3.12376 6.72751L1.15192 3.27194Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81448 1.68805L6.53143 2.58755C6.43639 2.88956 6.1128 3.05788 5.80868 2.9635C5.50456 2.86913 5.33506 2.54779 5.4301 2.24578L5.71856 1.32911C5.72177 1.31892 5.72526 1.30883 5.72904 1.29883C5.96965 0.661654 6.56085 0.125 7.36538 0.125H8.57692C9.38145 0.125 9.97265 0.661654 10.2133 1.29883C10.217 1.30883 10.2205 1.31892 10.2237 1.32911L10.5122 2.24578C10.6072 2.54779 10.4377 2.86913 10.1336 2.9635C9.82949 3.05788 9.50591 2.88956 9.41087 2.58755L9.12781 1.68805C9.02052 1.41696 8.80717 1.27083 8.57692 1.27083H7.36538C7.13513 1.27083 6.92178 1.41696 6.81448 1.68805Z"
      />
    </svg>
  );
};

ResourcesLineIcon.defaultProps = {
  height: 20,
  width: 20,
};

ResourcesLineIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default ResourcesLineIcon;
