import { injectReducer } from "store/reducers";

import ChooseRoute from "./routes/Choose";

import NoMatch from "NoMatch";
import component from "./components";
import Module from "./modules/LoginModule";
import PlanathonTerms from "./routes/PlanathonTerms";
import Community from "./routes/Community";
import CommunityTerms from "./routes/CommunityTerms";
import Platform from "./routes/Platform";

export const createRoutes = store => {
  injectReducer(store, { key: "login", reducer: Module });
  const teacherReducer = require("Teacher/modules/TeacherModule").default;
  injectReducer(store, { key: "teacher", reducer: teacherReducer });

  const communityReducer = require("Community/modules/CommunityModule").default;
  injectReducer(store, { key: "community", reducer: communityReducer });

  return {
    path: "/",
    component,
    childRoutes: [
      ChooseRoute(store),
      Platform(store),
      Community(store),
      CommunityTerms(store),
      PlanathonTerms(store),
      NoMatch(store),
    ],
  };
};

export default createRoutes;
