import React from "react";
import { colors } from "Constants";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  ELEMENT_TYPE_UBD_ATL,
  ELEMENT_TYPE_UBD_LEARNING_STANDARD,
  ELEMENT_TYPE_UBD_SUBJECT,
  ELEMENT_TYPE_MYP_ATL,
  ELEMENT_TYPE_MYP_OBJECTIVES,
  ELEMENT_TYPE_MYP_SUBJECT,
  ELEMENT_TYPE_MYP_LEARNING_STANDARD,
  SEPARATE,
  SEPARATE_PAGE,
  PROGRESS_SUMMARY_PAGE,
  SUBJECT_REPORT_PAGE,
  CUMULATIVE,
  PERCENTAGE,
  NUMBER,
  MANUAL,
} from "Constants/stringConstants";
import {
  SNPv2_PERM,
  SNPv2_TEACHER_MODULE_PERM,
} from "Constants/permissionConstants";
import ACLStore from "lib/aclStore";
import update from "immutability-helper";
import {
  DeleteOutlined,
  PencilLineOutlined,
  ReloadArrowOutlined,
  PrintOutlined,
  DisableOutlined,
  DownloadCloudOutlined,
  TeacherShareOutlined,
  LockOutlined,
  LockOpenOutlined,
  TagAddOutlined,
  StudentAddOutlined,
} from "@toddle-design/web-icons";
import { AnimateHeight } from "UIComponents";
import { generateRandomId } from "Utils/GenerateRandomId";
import { Alert } from "@toddle-design/web";

export const pypElementMapping = {
  lp: "LEARNER_PROFILE",
  concept: "CONCEPT",
  atl: "ATL",
  benchmark: "BENCHMARK",
  mypObjectives: ELEMENT_TYPE_MYP_OBJECTIVES,
  mypAtl: ELEMENT_TYPE_MYP_ATL,
  mypSubject: ELEMENT_TYPE_MYP_SUBJECT,
  ubdAtl: ELEMENT_TYPE_UBD_ATL,
  ubdLearningStandard: ELEMENT_TYPE_UBD_LEARNING_STANDARD,
  ubdSubject: ELEMENT_TYPE_UBD_SUBJECT,
  mypLearningStandard: ELEMENT_TYPE_MYP_LEARNING_STANDARD,
};

export const achievementCriterionLabels = [
  { label: "A", objectiveLabel: "Criterion A" },
  { label: "B", objectiveLabel: "Criterion B" },
  { label: "C", objectiveLabel: "Criterion C" },
  { label: "D", objectiveLabel: "Criterion D" },
];

export const getCriteriaSetOfPypType = ({
  type,
  subType = null,
  parentId = null,
  levelId = null,
  academicCriteriaSets = [],
  ratingLevels = [],
}) => {
  const filteredRatings = _.filter(ratingLevels, level => {
    let isMatched = level.ibPYPElementType == type;
    if (subType) {
      isMatched = level.subType == subType && isMatched;
    }
    if (parentId) {
      isMatched = level.ibPYPParentElementId == parentId && isMatched;
    }
    if (levelId) {
      isMatched = level.levelId == levelId && isMatched;
    }
    return isMatched;
  });
  const criteriaSetIds = _.reduce(
    filteredRatings,
    (result, rating) => {
      result = _.union(result, rating.academicCriteriaSets);
      return result;
    },
    []
  );

  return _.filter(academicCriteriaSets, criteriaSet => {
    const setId = _.find(criteriaSetIds, id => {
      return (
        id == criteriaSet.id &&
        (_.isUndefined(criteriaSet.isRatingEnabled) ||
          criteriaSet.isRatingEnabled)
      );
    });
    return setId ? true : false;
  });
};

export const checkCriteriaSetOfPypTypeInLevels = ({
  type,
  subType = null,
  parentId = null,
  levelId = "L1",
  setId,
  ratingLevels,
}) => {
  const filteredRatings = _.filter(ratingLevels, level => {
    let isMatched = level.ibPYPElementType == type;
    if (subType) {
      isMatched = level.subType == subType && isMatched;
    }
    if (parentId) {
      isMatched = level.ibPYPParentElementId == parentId && isMatched;
    }
    if (levelId) {
      isMatched = level.levelId == levelId && isMatched;
    }
    return isMatched;
  });

  const criteriaSets = _.reduce(
    filteredRatings,
    (result, rating) => {
      result = _.union(result, rating.academicCriteriaSets);
      return result;
    },
    []
  );

  return _.indexOf(criteriaSets, setId) >= 0;
};

export const getStudentElementRatingValueOfCriteriaSet = ({
  ratings,
  type,
  itemId,
  setId,
  nodeId,
}) => {
  let elementRating = _.find(ratings, ratingObj => {
    let isMatched = ratingObj.ibPYPElementType == type;
    if (itemId) {
      isMatched = ratingObj.ibPYPElementId == itemId && isMatched;
    }
    return (
      isMatched &&
      ratingObj.academicCriteriaSet == setId &&
      ratingObj.nodeId == nodeId
    );
  });
  return _.get(elementRating, "academicCriteriaValue", null);
};

/**
 * this function returns all ratings on MYP Criteria
 */
export const getMypCriteriaRatings = ({
  ratings,
  setId,
  subjectObjectivesMap,
  itemId,
}) => {
  if (!itemId) {
    itemId = "il";
  }
  const subjectObjectives = _.get(subjectObjectivesMap, [itemId], {});
  return _.filter(ratings, ratingObj => {
    return (
      ratingObj.ibPYPElementType == "MYP_OBJECTIVES" &&
      ratingObj.academicCriteriaSet == setId &&
      _.includes(subjectObjectives, ratingObj.nodeId)
    );
  });
};

export const calculateMypCriteriaRatingsTotal = ({
  mypCriteriaRatings,
  academicCriteriaSetValues,
}) => {
  return _.reduce(
    mypCriteriaRatings,
    (result, rating) => {
      const ratingValueId = _.get(rating, "academicCriteriaValue");
      const ratingValue = _.find(academicCriteriaSetValues, {
        id: ratingValueId,
      });
      return result + parseInt(_.get(ratingValue, "abbreviation", 0));
    },
    0
  );
};

/**
 * function used to calculate total of each subject achievement ratings in MYP Progress reports
 */
export const getStudentElementRatingTotal = ({
  itemId = "il",
  ratings,
  subjectObjectivesMap,
  academicCriteriaSets,
  isForceCalculateTotal,
}) => {
  const ibDefinedSet = _.find(academicCriteriaSets, {
    criteriaType: "IB_DEFINED",
  });
  const filteredRatings = getMypCriteriaRatings({
    ratings,
    itemId,
    subjectObjectivesMap,
    setId: _.get(ibDefinedSet, "id"),
  });
  if (isForceCalculateTotal || _.size(filteredRatings) === 4) {
    const total = calculateMypCriteriaRatingsTotal({
      mypCriteriaRatings: filteredRatings,
      academicCriteriaSetValues: _.get(
        ibDefinedSet,
        "academicCriteriaValues",
        []
      ),
    });
    if (total == 0) {
      return "-";
    }
    return total;
  }
  return "-";
};

/**
 * this function gives array consisting of dynamically calculated criteria set
 * i.e. Final grade and Local grade
 * used in MYP progress report
 */
export const getDynamicGradeSets = ({
  total = 0,
  ratings,
  gradeNode = {},
  dynamicCriteriaTypes = ["FINAL_GRADE", "LOCAL_GRADE"],
  isRatingEnabled,
  parentEntity = {},
  academicCriteriaSets,
  shouldNotCalculateFinalGradeFromTotal,
}) => {
  const result = [];
  const filteredCriteriaSets = _.filter(academicCriteriaSets, set => {
    return _.includes(dynamicCriteriaTypes, set.criteriaType);
  });
  _.map(filteredCriteriaSets, criteriaSet => {
    const criteriaType = _.get(criteriaSet, "criteriaType");
    const label = _.get(criteriaSet, "label");
    const academicCriteriaValues = _.get(criteriaSet, "academicCriteriaValues");

    if (criteriaType == "FINAL_GRADE") {
      let value;
      let id;
      if (!shouldNotCalculateFinalGradeFromTotal) {
        // calculate based on total
        // if total falls in between of boundary guidelines
        // get that criteria value
        const matchedFinalGradeCriteriaValue = _.find(
          academicCriteriaValues,
          value => {
            return _.includes(_.get(value, "boundary_guidelines"), total);
          }
        );
        const { id: valueId, abbreviation } =
          matchedFinalGradeCriteriaValue || {};
        // if value exists, get abbreviation
        value = !_.isEmpty(valueId) ? abbreviation : "-";
        id = !_.isEmpty(valueId) ? valueId : "final_grade";
      } else {
        // get from ratings
        const { id: gradeNodeId, itemId, pypType } = gradeNode;
        const valueId = getStudentElementRatingValueOfCriteriaSet({
          setId: criteriaSet.id,
          type: pypElementMapping[pypType],
          nodeId: gradeNodeId,
          itemId,
          ratings,
        });
        const { abbreviation } =
          _.find(academicCriteriaValues, value => {
            return _.includes(_.get(value, "id"), valueId);
          }) || {};
        value = !_.isEmpty(valueId) ? abbreviation : "-";
        id = !_.isEmpty(valueId) ? valueId : "final_grade";
      }
      result.push({
        ...criteriaSet,
        id: criteriaSet.id,
        label: criteriaSet.label,
        value,
        valueId: id,
      });
    }

    if (criteriaType == "LOCAL_GRADE") {
      const finalGradeValueId = _.get(
        _.find(result, item => _.get(item, "criteriaType") === "FINAL_GRADE"),
        "valueId"
      );
      const matchedLocalGradeCriteriaValue = _.find(
        academicCriteriaValues,
        value => {
          return (
            value.grade_id == _.get(parentEntity, "id") &&
            value.final_grade_value_id == finalGradeValueId
          );
        }
      );
      const { label: abbreviation } = matchedLocalGradeCriteriaValue || {};
      result.push({
        ...criteriaSet,
        id: criteriaSet.id,
        label: criteriaSet.label,
        value: !_.isEmpty(matchedLocalGradeCriteriaValue) ? abbreviation : "-",
        valueId: "local_grade",
      });
    }

    if (criteriaType == "CUSTOM") {
      const { id: gradeId, itemId, pypType } = gradeNode;

      const academicCriteriaValue = _.find(criteriaSet.academicCriteriaValues, {
        id: getStudentElementRatingValueOfCriteriaSet({
          setId: criteriaSet.id,
          type: pypElementMapping[pypType],
          nodeId: gradeId,
          itemId,
          ratings,
        }),
      });

      result.push({
        ...criteriaSet,
        id: criteriaSet.id,
        label: criteriaSet.label,
        value: _.get(academicCriteriaValue, "abbreviation", "-"),
        valueId: _.get(academicCriteriaValue, "id"),
      });
    }
  });

  return !_.isUndefined(isRatingEnabled)
    ? _.filter(
        result,
        item => _.get(item, "isRatingEnabled") == isRatingEnabled
      )
    : result;
};

const styles = {
  alertBox: {
    marginTop: "16px",
  },
};

export const ACTIONS = {
  START: {
    label: t => t("common:start"),
    variant: "progressive",
  },
  EDIT_TITLE: {
    label: t => t("common:edit_with_label", { label: t("common:title") }),
    svg: <PencilLineOutlined size={"xxx-small"} variant={"subtle"} />,
    isPromptBox: true,
    classReportMessage: {
      title: t =>
        t("common:update_with_label", {
          label: t("progressReport:progress_report"),
        }),
      message: params => ``,
      confirmButtonText: t => t("common:update"),
      cancelButtonText: t => t("common:cancel"),
      isPromptBox: true,
      promptDetail: (params, t) => ({
        placeholder: t("progressReport:progress_report_title_label"),
        label: t("progressReport:progress_report_title_placeholder"),
        checkValidation: true,
        promptText: params.title ? _.trim(params.title) : ``,
      }),
    },
    curriculumPrograms: [CURRICULUM_TYPE_PYP],
  },
  EDIT: {
    classReportLabel: t =>
      t("common:edit_with_label", {
        label: t("progressReport:class_report_plural"),
      }),

    studentReportLabel: t =>
      t("common:unlock_with_label", {
        label: t("progressReport:progress_report"),
      }),
    variant: "outlined-subtle",
    svg: <LockOpenOutlined size={"xx-small"} variant={"subtle"} />,
  },
  COMPLETE: {
    studentReportLabel: t => t("common:mark_as_complete"),
    variant: "progressive",
    studentReportMessage: {
      title: t =>
        t("common:mark_as_complete_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) => {
        const countString = t("common:field_count", {
          count: params.totalCount,
        });
        return t("progressReport:progress_report_in_complete_fields_warning", {
          label: countString,
        });
      },

      confirmButtonText: t => t("common:mark_as_complete_agree"),
      cancelButtonText: t => t("common:continue_editing_agree"),
    },
    curriculumPrograms: [CURRICULUM_TYPE_PYP],
  },
  PRINT: {
    label: t =>
      t("common:print_with_label", {
        label: t("common:preview"),
      }),
    svg: <PrintOutlined size={"xx-small"} variant={"subtle"} />,
  },
  SHARE_WITH_PARENT: {
    label: t => {
      const canShareWithStudent = ACLStore.can("Common:StudentProgressReport");
      let label = t("common:family_&_students");
      if (!canShareWithStudent) {
        label = t("common:family");
      }
      return t("common:share_with_with_label", {
        label,
      });
    },
    variant: "progressive",
    svg: <TeacherShareOutlined size={"xx-small"} variant={"subtle"} />,
    classReportMessage: {
      title: t =>
        t("common:share_label", { label: t("progressReport:progress_report") }),
      message: (params, t) => {
        const count = getStatusWiseStudents({
          studentProgressReport: _.get(params, "studentProgressReport", []),
          status: "LOCKED",
        }).length;
        const countString = t("progressReport:progress_report_count", {
          count,
        });
        let label = t("progressReport:share_class_report_message", {
          label: countString,
        });
        const canShareWithStudent = ACLStore.can(
          "Common:StudentProgressReport"
        );
        if (!canShareWithStudent) {
          label = t("progressReport:share_class_report_message_parents_only", {
            label: countString,
          });
        }
        return label;
      },
      confirmButtonText: t => t("progressReport:share_class_report_agree"),
      cancelButtonText: t => t("progressReport:share_class_report_disagree"),
    },
    studentReportMessage: {
      title: t =>
        t("common:share_label", { label: t("progressReport:progress_report") }),
      message: (params, t) => {
        const canShareWithStudent = ACLStore.can(
          "Common:StudentProgressReport"
        );
        let label = t("progressReport:share_student_report_message");
        if (!canShareWithStudent) {
          label = t("progressReport:share_student_report_message_parents_only");
        }
        return label;
      },
      confirmButtonText: t => t("progressReport:share_student_report_agree"),
      cancelButtonText: t => t("progressReport:share_student_report_disagree"),
    },
  },
  UNSHARE_WITH_PARENT: {
    label: t => t("common:unshare"),
    svg: <TeacherShareOutlined size={"xx-small"} variant={"subtle"} />,
    classReportMessage: {
      title: t =>
        t("common:unshare_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) => {
        const count = getStatusWiseStudents({
          studentProgressReport: _.get(params, "studentProgressReport", []),
          status: "PUBLISHED",
        }).length;
        const countString = t("progressReport:progress_report_count", {
          count,
        });
        const canShareWithStudent = ACLStore.can(
          "Common:StudentProgressReport"
        );
        let label = t("progressReport:unshare_class_report_message", {
          label: countString,
        });
        if (!canShareWithStudent) {
          label = t(
            "progressReport:unshare_class_report_message_parents_only",
            {
              label: countString,
            }
          );
        }
        return label;
      },
      confirmButtonText: t => t("progressReport:unshare_class_report_agree"),
      cancelButtonText: t => t("progressReport:unshare_class_report_disagree"),
    },
    studentReportMessage: {
      title: t =>
        t("common:unshare_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) => {
        const canShareWithStudent = ACLStore.can(
          "Common:StudentProgressReport"
        );
        let label = t("progressReport:unshare_student_report_message");
        if (!canShareWithStudent) {
          label = t(
            "progressReport:unshare_student_report_message_parents_only"
          );
        }
        return label;
      },
      confirmButtonText: t => t("progressReport:unshare_student_report_agree"),
      cancelButtonText: t =>
        t("progressReport:unshare_student_report_disagree"),
    },
  },
  DELETE: {
    classReportLabel: t =>
      t("common:delete_with_label", {
        label: t("progressReport:progress_report_plural"),
      }),
    svg: <DeleteOutlined size={"xxx-small"} variant={"subtle"} />,
    classReportMessage: {
      title: t =>
        t("common:delete_with_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) =>
        t("common:delete_msg_with_label", {
          label: t("progressReport:progress_report"),
        }),
      confirmButtonText: t => t("common:delete_agree"),
      cancelButtonText: t => t("common:agree_cancel"),
    },
  },
  EDIT_STUDENTS: {
    label: t =>
      t("common:edit_with_label", {
        label: t("common:student_list"),
      }),
    svg: <PencilLineOutlined size={"xxx-small"} variant={"subtle"} />,
  },
  EDIT_UNIT_PLANS: {
    label: t =>
      t("common:edit_with_label", {
        label: t("common:unit_plural"),
      }),
    svg: <PencilLineOutlined size={"xxx-small"} variant={"subtle"} />,
    curriculumPrograms: [CURRICULUM_TYPE_PYP],
  },
  EDIT_ATTENDANCE: {
    label: t => t("progressReport:edit_attendance_range"),
    svg: <PencilLineOutlined size={"xxx-small"} variant={"subtle"} />,
  },
  SYNC_ATTENDANCE: {
    label: t =>
      t("common:sync_with_label", {
        label: t("common:attendance"),
      }),
    svg: <ReloadArrowOutlined size={"xx-small"} variant={"subtle"} />,
    classReportMessage: {
      title: t =>
        t("common:sync_with_label", {
          label: t("common:attendance"),
        }),
      message: (params, t) =>
        t("progressReport:sync_msg_with_label", {
          label: t("progressReport:progress_report"),
        }),
      confirmButtonText: t => t("common:yes"),
      cancelButtonText: t => t("common:cancel"),
    },
  },
  LOCK: {
    studentReportLabel: t =>
      t("common:lock_with_label", {
        label: t("common:lowercase", {
          text: t("progressReport:progress_report"),
        }),
      }),
    classReportLabel: t =>
      t("common:lock_with_label", {
        label: t("progressReport:progress_report_plural"),
      }),
    variant: "primary",
    svg: <LockOutlined size={"xx-small"} variant={"subtle"} />,

    studentReportMessage: {
      title: t =>
        t("common:lock_with_label", {
          label: t("common:lowercase", {
            text: t("progressReport:progress_report"),
          }),
        }),
      message: (params, t) => {
        const countString = t("common:field_count", {
          count: params.totalCount,
        });
        return (
          <>
            {t(
              "progressReport:new_progress_report_incomplete_fields_warning_with_label",
              { label: countString }
            )}
            <div style={styles.alertBox}>
              <Alert
                type="warning"
                message={t("progressReport:lock_progress_report_warning")}
              />
            </div>
          </>
        );
      },
      confirmButtonText: t => t("progressReport:lock_progress_report_agree"),
      cancelButtonText: t => t("common:cancel"),
    },

    classReportMessage: {
      title: t =>
        t("common:lock_with_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) => {
        return params.isAdmin
          ? t("progressReport:lock_report_admin_warning_message")
          : t("progressReport:lock_report_teacher_warning_message");
      },
      confirmButtonText: t => t("progressReport:lock_report_agree"),
      cancelButtonText: t => t("common:continue_editing_agree"),
    },
  },
  LOCKING: {
    // Dummy action for an intermediate state
    studentReportLabel: t => t("common:locking"),
    isDisabled: true,
  },
  COMPLETE_LOCK: {
    studentReportLabel: t => t("progressReport:mark_complete_lock_report"),
    variant: "progressive",
    studentReportMessage: {
      title: t =>
        t("common:mark_as_complete_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) => {
        const countString = t("common:field_count", {
          count: params.totalCount,
        });
        return t("progressReport:progress_report_in_complete_fields_warning", {
          label: countString,
        });
      },
      confirmButtonText: t =>
        t("common:yes_with_action", {
          action: t("common:lowercase", {
            text: t("progressReport:mark_complete_lock_report"),
          }),
        }),
      cancelButtonText: t => t("common:continue_editing_agree"),
    },
  },
  SEND_FOR_RE_EVALUATION: {
    label: t => t("progressReport:send_teacher_for_revision"),
    variant: "progressive",
    svg: <ReloadArrowOutlined size={"xx-small"} variant={"subtle"} />,
    curriculumPrograms: [CURRICULUM_TYPE_PYP],
    classReportMessage: {
      title: t =>
        t("common:unlock_with_label", {
          label: t("progressReport:progress_report"),
        }),
      message: (params, t) => {
        return params.isAdmin
          ? t("progressReport:unlock_report_admin_warning_message")
          : t("progressReport:unlock_report_teacher_warning_message");
      },
      confirmButtonText: t => t("progressReport:unlock_report_agree"),
      cancelButtonText: t => t("progressReport:unlock_report_disagree"),
    },
    classReportLabel: t =>
      t("common:unlock_with_label", {
        label: t("progressReport:progress_report_plural"),
      }),
  },
  DISABLE_EVALUATION: {
    studentReportLabel: t =>
      t("progressReport:exclude_student_from_this_report_set"),
    svg: <DisableOutlined size={"xx-small"} variant={"subtle"} />,
    curriculumPrograms: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_UBD,
    ],
  },
  ENABLE_EVALUTAION: {
    studentReportLabel: t =>
      t("progressReport:include_student_in_this_report_set"),
    svg: <StudentAddOutlined size={"xxx-small"} variant={"subtle"} />,
    curriculumPrograms: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_UBD,
    ],
  },
  DOWNLOAD: {
    studentReportLabel: t =>
      t("common:download_with_label", {
        label: t("progressReport:progress_report"),
      }),
    classReportLabel: t =>
      t("common:download_with_label", {
        label: t("progressReport:progress_report_plural"),
      }),
    svg: <DownloadCloudOutlined size={"xx-small"} variant={"subtle"} />,
  },
  EDIT_SIGNATORIES: {
    label: t =>
      t("common:edit_with_label", {
        label: t("common:signatories"),
      }),
    svg: <PencilLineOutlined size={"xxx-small"} variant={"subtle"} />,
    curriculumPrograms: [CURRICULUM_TYPE_PYP],
  },
  TAG_SNP: {
    label: t => t("common:tag_snp"),
    svg: <TagAddOutlined size={"xx-small"} variant={"subtle"} />,
    curriculumPrograms: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_UBD,
    ],
  },
  SET_GRADE_TASK_SUBJECT_OBJECTIVES: {
    label: t => t("common:start"),
    variant: "progressive",
    curriculumPrograms: [CURRICULUM_TYPE_MYP],
  },
  EDIT_GRADE_TASK_SUBJECT_OBJECTIVES: {
    label: t =>
      t("common:edit_with_label", {
        label: t("common:criteria"),
      }),
    curriculumPrograms: [CURRICULUM_TYPE_MYP],
    svg: <PencilLineOutlined size={"xxx-small"} variant={"subtle"} />,
  },
};

const CLASS_ACTIONS_MENU_SEQUENCES = [
  "EDIT_TITLE",
  "LOCK",
  "EDIT_GRADE_TASK_SUBJECT_OBJECTIVES",
  "EDIT_STUDENTS",
  "EDIT_UNIT_PLANS",
  "EDIT_ATTENDANCE",
  "SYNC_ATTENDANCE",
  "EDIT_SIGNATORIES",
  "SEND_FOR_RE_EVALUATION",
  "SHARE_WITH_PARENT",
  "UNSHARE_WITH_PARENT",
  // "PRINT",
  "DOWNLOAD",
  "DELETE",
];

const STUDENT_ACTIONS_MENU_SEQUENCES = [
  "SEND_FOR_RE_EVALUATION",
  "SHARE_WITH_PARENT",
  "UNSHARE_WITH_PARENT",
  "ENABLE_EVALUTAION",
  "DISABLE_EVALUATION",
  "PRINT",
  "DOWNLOAD",
  "TAG_SNP",
];

const ACTIONS_BUTTON_SEQUENCES = [
  "EDIT",
  "COMPLETE",
  "LOCKING",
  "LOCK",
  "COMPLETE_LOCK",
  "SEND_FOR_RE_EVALUATION",
];

export const STATUS_MAPPING = {
  NOT_STARTED: {
    getLabel: () => {
      return "common:not_started";
    },
    getColor: () => {
      return colors.gray72;
    },
    adminClassReportActions: {
      [CURRICULUM_TYPE_PYP]: ["START", "EDIT_TITLE", "DELETE"],
      [CURRICULUM_TYPE_MYP]: ["START", "EDIT_TITLE", "DELETE"],
      defaultValue: ["EDIT_TITLE", "DELETE"],
    },
    teacherClassReportActions: {
      [CURRICULUM_TYPE_PYP]: ["START"],
      [CURRICULUM_TYPE_MYP]: ["START"],
    },
    adminStudentReportActions: {},
    teacherStudentReportActions: {},
    nextAction: "START",
  },
  NA: {
    getLabel: ({ curriculumProgramType } = {}) => {
      switch (curriculumProgramType) {
        case CURRICULUM_TYPE_PYP: {
          return "common:not_assessed";
        }
        default: {
          return "common:excluded";
        }
      }
    },
    getColor: ({ curriculumProgramType } = {}) => {
      switch (curriculumProgramType) {
        case CURRICULUM_TYPE_PYP: {
          return colors.yellow50;
        }
        default: {
          return colors.textSubtle;
        }
      }
    },
    adminClassReportActions: {
      defaultValue: [
        "EDIT_TITLE",
        "EDIT_STUDENTS",
        "EDIT_UNIT_PLANS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "DELETE",
      ],
    },
    teacherClassReportActions: {
      [CURRICULUM_TYPE_PYP]: ["EDIT_STUDENTS", "EDIT_UNIT_PLANS"],
      defaultValue: [],
    },
    adminStudentReportActions: {
      defaultValue: ["ENABLE_EVALUTAION"],
    },
    teacherStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["ENABLE_EVALUTAION"],
      defaultValue: [],
    },
  },
  PENDING: {
    getLabel: ({ curriculumProgramType } = {}) => {
      switch (curriculumProgramType) {
        case CURRICULUM_TYPE_PYP: {
          return "common:pending";
        }
        default: {
          return "common:unlocked";
        }
      }
    },
    getColor: ({ curriculumProgramType } = {}) => {
      switch (curriculumProgramType) {
        case CURRICULUM_TYPE_PYP: {
          return colors.salmon60;
        }
        default: {
          return colors.textDefault;
        }
      }
    },
    mode: "edit",
    isFreezed: false,
    adminClassReportActions: {
      [CURRICULUM_TYPE_PYP]: [
        "EDIT_TITLE",
        "EDIT_STUDENTS",
        "EDIT_UNIT_PLANS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "EDIT_SIGNATORIES",
        "PRINT",
        "DELETE",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "EDIT_TITLE",
        "EDIT_STUDENTS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "PRINT",
        "DELETE",
        "SET_GRADE_TASK_SUBJECT_OBJECTIVES",
        "EDIT_GRADE_TASK_SUBJECT_OBJECTIVES",
      ],
      defaultValue: [
        "EDIT_TITLE",
        "EDIT_STUDENTS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "PRINT",
        "DELETE",
      ],
    },
    teacherClassReportActions: {
      [CURRICULUM_TYPE_PYP]: [
        "EDIT_STUDENTS",
        "EDIT_UNIT_PLANS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "EDIT_SIGNATORIES",
        "PRINT",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "SET_GRADE_TASK_SUBJECT_OBJECTIVES",
        "EDIT_GRADE_TASK_SUBJECT_OBJECTIVES",
      ],
      defaultValue: [],
    },
    adminStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["PRINT", "DISABLE_EVALUATION", "COMPLETE_LOCK"],
      defaultValue: ["PRINT", "DISABLE_EVALUATION", "LOCK"],
    },
    teacherStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["PRINT", "DISABLE_EVALUATION", "COMPLETE_LOCK"],
      defaultValue: ["PRINT"],
    },
    getCurriculumWiseNextAction: data =>
      getCurriculumWiseNextActionForStatus({ ...data, status: "PENDING" }),
  },
  DONE: {
    getLabel: () => {
      return "common:completed";
    },
    getColor: () => {
      return colors.blue29;
    },
    mode: "view",
    isFreezed: false,
    adminClassReportActions: {
      [CURRICULUM_TYPE_PYP]: [
        "EDIT_TITLE",
        "EDIT_STUDENTS",
        "EDIT_UNIT_PLANS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "LOCK",
        "PRINT",
        "DELETE",
      ],
      defaultValue: [],
    },
    teacherClassReportActions: {
      [CURRICULUM_TYPE_PYP]: [
        "EDIT_STUDENTS",
        "EDIT_UNIT_PLANS",
        "EDIT_ATTENDANCE",
        "SYNC_ATTENDANCE",
        "PRINT",
        "LOCK",
      ],
      defaultValue: [],
    },
    adminStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["EDIT", "PRINT"],
      defaultValue: [],
    },
    teacherStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["EDIT", "PRINT"],
      defaultValue: [],
    },
    nextAction: "LOCK",
  },
  LOCKING: {
    getLabel: () => {
      return "common:locking";
    },
    getColor: () => {
      return colors.gray13;
    },
    mode: "view",
    isFreezed: false,
    adminClassReportActions: {},
    teacherClassReportActions: {},
    adminStudentReportActions: {
      defaultValue: ["LOCKING"],
    },
    teacherStudentReportActions: {},
  },
  LOCKED: {
    getLabel: () => {
      return "common:locked";
    },
    getColor: ({ curriculumProgramType } = {}) => {
      switch (curriculumProgramType) {
        case CURRICULUM_TYPE_PYP: {
          return colors.violet63;
        }
        default: {
          return colors.textWarning;
        }
      }
    },
    mode: "view",
    isFreezed: true,
    adminClassReportActions: {
      [CURRICULUM_TYPE_PYP]: [
        "PRINT",
        "DELETE",
        "SHARE_WITH_PARENT",
        "SEND_FOR_RE_EVALUATION",
        "DOWNLOAD",
      ],
      defaultValue: ["PRINT", "DELETE", "DOWNLOAD"],
    },
    teacherClassReportActions: {
      defaultValue: ["PRINT", "DOWNLOAD"],
    },
    adminStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["PRINT", "EDIT", "SHARE_WITH_PARENT", "DOWNLOAD"],
      defaultValue: ["EDIT", "PRINT"],
    },
    teacherStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: ["EDIT", "PRINT", "DOWNLOAD"],
      defaultValue: ["PRINT", "DOWNLOAD"],
    },
    nextAction: "SHARE_WITH_PARENT",
  },
  PUBLISHED: {
    getLabel: () => {
      let label = "common:shared_with_family_&_students";
      const canShareWithStudent = ACLStore.can("Common:StudentProgressReport");
      if (!canShareWithStudent) {
        label = "common:shared_with_family";
      }
      return label;
    },
    getColor: ({ curriculumProgramType } = {}) => {
      switch (curriculumProgramType) {
        case CURRICULUM_TYPE_PYP: {
          return colors.gray31;
        }
        default: {
          return colors.textSuccess;
        }
      }
    },
    mode: "view",
    isFreezed: true,
    adminClassReportActions: {
      defaultValue: ["PRINT", "DELETE", "UNSHARE_WITH_PARENT", "DOWNLOAD"],
    },
    teacherClassReportActions: {
      defaultValue: ["PRINT", "DOWNLOAD"],
    },
    adminStudentReportActions: {
      [CURRICULUM_TYPE_PYP]: [
        "PRINT",
        "UNSHARE_WITH_PARENT",
        "DOWNLOAD",
        "TAG_SNP",
      ],
      defaultValue: ["PRINT", "TAG_SNP"],
    },
    teacherStudentReportActions: {
      defaultValue: ["PRINT", "DOWNLOAD", "TAG_SNP"],
    },
  },
};

export const STATUS_SEQUENCES = [
  "NOT_STARTED",
  "NA",
  "PENDING",
  "DONE",
  "LOCKING",
  "LOCKED",
  "PUBLISHED",
];

export const STATUS_COUNTS_SEQUENCES = [
  "PUBLISHED",
  "LOCKED",
  "DONE",
  "PENDING",
  "NA",
];

const getCurriculumWiseNextActionForStatus = data => {
  const curriculumProgramType = _.get(data, "curriculumProgramType");
  const status = _.get(data, "status");
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_MYP: {
      switch (status) {
        case "PENDING": {
          const gradeSubjectObjectivesIsComplete = _.get(
            data,
            "item.gradeSubjectObjectivesIsComplete"
          );
          return gradeSubjectObjectivesIsComplete
            ? null
            : "SET_GRADE_TASK_SUBJECT_OBJECTIVES";
        }
        default: {
          return null;
        }
      }
    }
    default: {
      return null;
    }
  }
};

export const checkIsFreezed = status => {
  return _.get(STATUS_MAPPING[status], "isFreezed", false);
};

export const getNestedTreeOfNode = ({ nodes, id }) => {
  let output = JSON.stringify(getFullTree({ nodes, id }));
  return getFullTreeMemoize(output);
};
const getFullTreeMemoize = _.memoize(params => JSON.parse(params));

const getFullTree = ({ nodes, id }) => {
  let output = {};
  getRecursiveTree({ nodes, id, output });
  return output;
};

const getRecursiveTree = ({ output, nodes, id }) => {
  const node = nodes[id];
  if (node) {
    output[id] = node;
    _.map(node.children, childId => {
      getRecursiveTree({ output, nodes, id: childId });
    });
  }
};

export const getMode = ({
  status,
  proofReadingMode = "edit",
  archived = false,
}) => {
  if (archived) {
    return "view";
  }
  if (status === "PENDING") {
    return proofReadingMode;
  }
  return _.get(STATUS_MAPPING[status], "mode", "edit");
};
export const getCompletedStudents = studentProgressReport => {
  return _.map(
    _.filter(studentProgressReport, item => {
      const status = _.get(item, "status", "NA");
      if (status == "DONE" || status == "PUBLISHED" || status == "LOCKED") {
        return true;
      }
    }),
    item2 => {
      return _.get(item2, "student.id", "");
    }
  );
};

export const getTotalSelectedStudents = studentProgressReport => {
  return _.filter(studentProgressReport, item => {
    const status = _.get(item, "status", "NA");
    if (status != "NA") {
      return true;
    }
  });
};

export const getStatusWiseStudents = ({ studentProgressReport, status }) => {
  return _.filter(studentProgressReport, item => {
    const studentStatus = _.get(item, "status", "NA");
    if (studentStatus == status) {
      return true;
    }
  });
};

export const getTotalSelectedStudentIds = studentProgressReport => {
  return _.map(getTotalSelectedStudents(studentProgressReport), item => {
    return _.get(item, "student.id", "");
  });
};

export const isProgressReportEvaluationNotStarted = ({ unitPlans }) => {
  return _.get(unitPlans, "length", 0) == 0;
};

export const getProgressReportCountByStatus = ({ studentProgressReport }) => {
  const studentStatusArray = _.map(
    studentProgressReport,
    student => student.status
  );
  return _.countBy(studentStatusArray, status => status);
};

export const getCourseReportStatus = ({ studentProgressReport, unitPlans }) => {
  const studentStatusArray = _.map(
    studentProgressReport,
    student => student.status
  );
  let courseStatus = "NOT_STARTED";
  let statusObject = getProgressReportCountByStatus({ studentProgressReport });

  const isEvaluationStarted = isProgressReportEvaluationNotStarted({
    unitPlans,
  });

  if (isEvaluationStarted) {
    courseStatus = "NOT_STARTED";
  } else {
    _.forEach(STATUS_SEQUENCES, status => {
      const count = _.get(statusObject, status, 0);
      if (
        (count > 0 && status != "NA") ||
        (count == _.get(studentStatusArray, "length", 0) && status == "NA")
      ) {
        courseStatus = status;
        return false;
      }
    });
  }

  return courseStatus;
};

export const getProgressReportStatusDetails = ({ status }) => {
  return _.get(STATUS_MAPPING, status, {});
};

export const getUnitPlanReorderStepData = ({ unitPlans }) => {
  let nodes = {};
  let rootNodes = [];

  _.forEach(unitPlans, item => {
    const { id, title, theme } = item;
    rootNodes.push(item.id);
    nodes[id] = {
      id,
      label: _.isString(title) ? title : _.get(title, "value", ""),
      parent: null,
      children: [],
      subLabel: _.get(theme, "resolvedMinimalTree.themes.0.label", ""),
    };
  });

  return { nodes, rootNodes };
};

export const getProgressReportTaskSteps = ({
  studentProgressReport,
  unitPlans,
  modalMode,
  attendanceStatus,
  attendanceDates,
  signatories,
  teacherSignatoriesStatus,
}) => {
  const steps = [];
  if (modalMode == "ALL" || modalMode == "EDIT_UNIT_PLANS") {
    steps.push({
      id: "up",
      data: _.map(unitPlans, item => {
        const { id, title } = item;
        return {
          id,
          title: _.isString(title) ? title : _.get(title, "value", ""),
        };
      }),
    });
    const unitPlanReorderData = getUnitPlanReorderStepData({ unitPlans });
    steps.push({ id: "unitReOrder", data: unitPlanReorderData });
  }
  if (modalMode == "ALL" || modalMode == "EDIT_STUDENTS") {
    steps.push({
      id: "stu",
      data: getTotalSelectedStudentIds(studentProgressReport),
    });
  }
  if (modalMode == "ALL" || modalMode == "EDIT_ATTENDANCE") {
    steps.push({
      id: "attendance",
      data: attendanceDates,
    });
  }
  if (
    (modalMode == "ALL" && teacherSignatoriesStatus) ||
    modalMode == "EDIT_SIGNATORIES"
  ) {
    steps.push({
      id: "signatories",
      data: signatories,
    });
  }
  return steps;
};

const getSignatoriesGroup = ({ newSignatories, oldSignatories }) => {
  const oldSignatoriesIds = _.map(oldSignatories, signatory =>
    _.get(signatory, "id")
  );

  const addedSignatories = _.differenceBy(newSignatories, oldSignatories, "id");
  const added = _.reduce(
    addedSignatories,
    (result, signatory) => {
      const { name, designation, signatureImage } = signatory;
      // only consider in added only if any of these field is filled
      if (name !== "" || designation !== "" || signatureImage !== "") {
        result.push({ name, designation, signatureImage });
      }
      return result;
    },
    []
  );

  // these signatory will be considered as removed
  const updatedWithEmptyFields = _.filter(newSignatories, signatory => {
    const { id, name, designation, signatureImage } = signatory;
    // also checking for id to be present in oldSignatories as newly added signatory are all empty field
    // ( but those are not to be considerd as removed )
    return (
      _.includes(oldSignatoriesIds, id) &&
      name === "" &&
      designation === "" &&
      signatureImage === ""
    );
  });

  const removedSignatories = [
    ..._.differenceBy(oldSignatories, newSignatories, "id"),
    ...updatedWithEmptyFields,
  ];

  const removed = _.map(removedSignatories, signatory =>
    _.get(signatory, "id")
  );

  const updatedSignatories = _.intersectionWith(
    newSignatories,
    oldSignatories,
    (val1, val2) => {
      if (_.includes(removed, _.get(val2, "id"))) {
        return false;
      }
      return _.get(val1, "id") === _.get(val2, "id") && !_.isEqual(val1, val2);
    }
  );

  const updated = _.map(updatedSignatories, signatory =>
    _.pick(signatory, ["id", "name", "designation", "signatureImage"])
  );

  return { added, removed, updated };
};

export const getProgressReportTaskModalUpdatedValues = ({
  unitPlans,
  studentProgressReport,
  params,
  modalMode,
  attendanceStatus,
  signatories,
  teacherSignatoriesStatus,
}) => {
  const selectedUnitplans = params["up"];
  const selectedStudents = params["stu"];
  const selectedAttendanceDates = params["attendance"];
  const unitPlanReorderData = _.get(
    params["unitReOrder"],
    "rootNodes",
    selectedUnitplans
  );

  // const oldSelectedUnitPlans = _.map(unitPlans, item => item.id);
  const oldStudentProgressReport = getTotalSelectedStudentIds(
    studentProgressReport
  );
  const newSignatories = params["signatories"];
  const oldSignatories = signatories;
  let unitPlanIds = undefined;
  let addedStudents = undefined;
  let removedStudents = undefined;
  let addedUnitPlanIds = undefined;
  let removedUnitPlanIds = undefined;
  let attendanceDates = undefined;
  let signatoriesGroup = undefined;
  if (modalMode == "ALL" || modalMode == "EDIT_UNIT_PLANS") {
    // addedUnitPlanIds = _.difference(selectedUnitplans, oldSelectedUnitPlans);
    // removedUnitPlanIds = _.difference(oldSelectedUnitPlans, selectedUnitplans);
    unitPlanIds = unitPlanReorderData;
  }
  if (modalMode == "ALL" || modalMode == "EDIT_STUDENTS") {
    addedStudents = _.difference(selectedStudents, oldStudentProgressReport);
    removedStudents = _.difference(oldStudentProgressReport, selectedStudents);
  }

  if (modalMode == "ALL" || modalMode == "EDIT_ATTENDANCE") {
    attendanceDates = selectedAttendanceDates;
  }
  if (
    (modalMode == "ALL" && teacherSignatoriesStatus) ||
    modalMode == "EDIT_SIGNATORIES"
  ) {
    signatoriesGroup = getSignatoriesGroup({ newSignatories, oldSignatories });
  }

  return {
    addedStudents,
    removedStudents,
    // addedUnitPlanIds,
    // removedUnitPlanIds,
    attendanceDates,
    unitPlanIds,
    signatoriesGroup,
  };
};

export const getActionDetails = ({
  item,
  action,
  isStudentReport = true,
  onActionClick,
}) => {
  const actionDetails = ACTIONS[action] || {};

  const label = isStudentReport
    ? actionDetails.studentReportLabel
    : actionDetails.classReportLabel;

  const message = isStudentReport
    ? actionDetails.studentReportMessage
    : actionDetails.classReportMessage;

  return {
    item,
    action,
    isForced: !message,
    message,
    isStudentReport,
    isDisabled: _.get(actionDetails, "isDisabled", false),
    label: label ? label : actionDetails.label,
    value: action,
    variant: actionDetails.variant,
    svg: actionDetails.svg,
    clickAction: () =>
      onActionClick({
        action,
        item,
        isForced: !message,
        message,
        isStudentReport,
      }),
    curriculumPrograms: _.get(actionDetails, "curriculumPrograms", null),
  };
};

export const getStatusAllActions = ({
  isStudentReport,
  isAdmin,
  status,
  curriculumProgramType,
}) => {
  const statusDetails = getProgressReportStatusDetails({ status });
  let key;
  let actions = [];
  if (isAdmin) {
    if (isStudentReport) {
      key = "adminStudentReportActions";
    } else {
      key = "adminClassReportActions";
    }
  } else {
    if (isStudentReport) {
      key = "teacherStudentReportActions";
    } else {
      key = "teacherClassReportActions";
    }
  }
  actions = _.get(
    statusDetails,
    [key, curriculumProgramType],
    _.get(statusDetails, [key, "defaultValue"], [])
  );
  return actions;
};

const checkCustomPermission = ({ key, customPermissionParams, currentTab }) => {
  switch (key) {
    case "TAG_SNP": {
      const isFeatureAvailableInModule =
        currentTab === "ADMIN"
          ? ACLStore.can(SNPv2_PERM)
          : ACLStore.can(SNPv2_TEACHER_MODULE_PERM) && ACLStore.can(SNPv2_PERM);

      const { totalNewSnpCycles } = customPermissionParams;
      return totalNewSnpCycles > 0 && isFeatureAvailableInModule;
    }
    default:
      return true;
  }
};

const getSequenceKeyAllowanceStatus = ({
  key,
  isAdmin,
  sequences,
  customPermissionParams,
}) => {
  const currentTab = isAdmin ? "ADMIN" : "TEACHER";

  const isKeyFromSequencesAllowed = _.includes(sequences, key);
  const isKeyFromCustomPermissionAllowed = checkCustomPermission({
    key,
    customPermissionParams,
    currentTab,
  });

  return isKeyFromCustomPermissionAllowed && isKeyFromSequencesAllowed;
};

export const getProgressReportActionBySequence = ({
  item,
  isStudentReport = true,
  onActionClick,
  isAdmin,
  sequences = [],
  customPermissionParams = {},
  curriculumProgramType = CURRICULUM_TYPE_PYP,
}) => {
  const status = isStudentReport
    ? item.status
    : getStatusByCurriculumType({ curriculumProgramType, item });
  const array = getStatusAllActions({
    isStudentReport,
    isAdmin,
    status,
    curriculumProgramType,
  });
  const actionArray = [];
  _.forEach(array, key => {
    if (
      getSequenceKeyAllowanceStatus({
        key,
        isAdmin,
        sequences,
        customPermissionParams,
      })
    ) {
      actionArray.push(
        getActionDetails({
          item,
          action: key,
          isStudentReport,
          onActionClick,
          isAdmin,
        })
      );
    }
  });

  return _.sortBy(actionArray, actionValue => {
    return sequences.indexOf(actionValue.value);
  });
};

export const getProgressReportActionsByStatus = ({
  item,
  isStudentReport,
  onActionClick,
  isAdmin,
  curriculumProgramType = CURRICULUM_TYPE_PYP,
  customPermissionParams = {},
  attendanceMode,
}) => {
  const actionSequences = isStudentReport
    ? STUDENT_ACTIONS_MENU_SEQUENCES
    : CLASS_ACTIONS_MENU_SEQUENCES;
  const nextAction = getNextPossibleAction({
    item,
    isStudentReport,
    isAdmin,
    curriculumProgramType,
  });
  let filteredSequences = _.filter(
    actionSequences,
    actionName => actionName != nextAction
  );
  if (!isStudentReport) {
    const teacherSignatoriesStatus = _.get(
      item,
      "filteredNodes.teacherSignatoriesStatus",
      false
    );
    const attendanceStatus =
      curriculumProgramType === CURRICULUM_TYPE_PYP
        ? _.get(item, "filteredNodes.attendanceStatus", false)
        : true;
    if (!attendanceStatus) {
      filteredSequences = _.filter(
        filteredSequences,
        actionName => actionName != "EDIT_ATTENDANCE"
      );
    }
    if (!teacherSignatoriesStatus) {
      filteredSequences = _.filter(
        filteredSequences,
        actionName => actionName != "EDIT_SIGNATORIES"
      );
    }

    if (attendanceMode != MANUAL) {
      filteredSequences = _.filter(
        filteredSequences,
        actionName => actionName != "SYNC_ATTENDANCE"
      );
    }
  }
  const actions = getProgressReportActionBySequence({
    sequences: filteredSequences,
    item,
    isStudentReport,
    onActionClick,
    isAdmin,
    customPermissionParams,
    curriculumProgramType,
  });
  const finalActions = filterByCurriculumProgramType({
    curriculumProgramType,
    actions,
  });
  return finalActions;
};

const filterByCurriculumProgramType = ({ curriculumProgramType, actions }) => {
  return _.filter(actions, action => {
    const curriculumPrograms = _.get(action, "curriculumPrograms", null);
    if (!curriculumPrograms) {
      return true;
    }
    return _.includes(curriculumPrograms, curriculumProgramType);
  });
};

export const getProgressReportButtonsByStatus = ({
  item,
  isStudentReport = true,
  onActionClick,
  isAdmin,
  curriculumType = CURRICULUM_TYPE_PYP,
}) => {
  const actions = getProgressReportActionBySequence({
    sequences: ACTIONS_BUTTON_SEQUENCES,
    item,
    isStudentReport,
    onActionClick,
    isAdmin,
    curriculumProgramType: curriculumType,
  });
  return filterByCurriculumProgramType({
    curriculumProgramType: curriculumType,
    actions,
  });
};

export const checkIsStudentProgressReportEditable = ({ status, isAdmin }) => {
  let actionKey = "teacherStudentReportActions";

  if (isAdmin) {
    actionKey = "adminStudentReportActions";
  }
  return _.includes(_.get(STATUS_MAPPING[status], `${actionKey}`, []), "EDIT");
};

export const getGradeReportStatus = ({ courseReport }) => {
  const statusCountMap = {
    PENDING: 0,
    LOCKING: 0,
    LOCKED: 0,
    PUBLISHED: 0,
  };
  const studentProgressReports = _.get(
    courseReport,
    "studentProgressReport",
    []
  );
  for (const report of studentProgressReports) {
    const status = _.get(report, "status", "PENDING");
    if (status === "PENDING") {
      return "PENDING";
    }
    statusCountMap[status] += 1;
  }

  if (statusCountMap["LOCKING"] > 0) {
    return "LOCKING";
  }

  if (statusCountMap["LOCKED"] > 0) {
    return "LOCKED";
  }

  if (statusCountMap["PUBLISHED"] > 0) {
    return "PUBLISHED";
  }
  return "PENDING";
};

const getStatusByCurriculumType = ({ curriculumProgramType, item }) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return getCourseReportStatus({
        studentProgressReport: _.get(item, "studentProgressReport", []),
        unitPlans: _.get(item, "unitPlans", []),
      });
    case CURRICULUM_TYPE_MYP:
    case CURRICULUM_TYPE_UBD:
      return getGradeReportStatus({
        courseReport: item,
      });
  }
};

export const getNextPossibleAction = ({
  item,
  isStudentReport = true,
  isAdmin,
  curriculumProgramType = CURRICULUM_TYPE_PYP,
  isGetCurriculumWiseNextAction = false,
}) => {
  const status = isStudentReport
    ? item.status
    : getStatusByCurriculumType({ curriculumProgramType, item });
  const statusDetails = getProgressReportStatusDetails({ status });
  const nextAction =
    isGetCurriculumWiseNextAction && statusDetails.getCurriculumWiseNextAction
      ? statusDetails.getCurriculumWiseNextAction({
          curriculumProgramType,
          item,
        })
      : statusDetails.nextAction;
  if (!nextAction) {
    return null;
  }

  const actions = getStatusAllActions({
    isStudentReport,
    isAdmin,
    status,
    curriculumProgramType,
  });

  if (!_.includes(actions, nextAction)) {
    return null;
  }
  return nextAction;
};

export const getNextPossibleActionDetails = ({
  item,
  isStudentReport = true,
  isAdmin,
  onActionClick,
  curriculumProgramType = CURRICULUM_TYPE_PYP,
  isGetCurriculumWiseNextAction,
}) => {
  const nextAction = getNextPossibleAction({
    item,
    isStudentReport,
    isAdmin,
    curriculumProgramType,
    isGetCurriculumWiseNextAction,
  });
  if (!nextAction) {
    return {};
  }
  const actionDetails = getActionDetails({
    item: item,
    action: nextAction,
    isStudentReport: isStudentReport,
    onActionClick: onActionClick,
  });

  return actionDetails;
};

/**
 * This function checks for psNode (which stores progress summary configs)
 * and overrides the node properties.
 */
export const getUpdatedNode = ({ node, sectionUID }) => {
  if (_.isEqual(sectionUID, "PROGRESS_SUMMARY")) {
    if (_.has(node, "psNode")) {
      const psNode = node.psNode;
      const updatedNode = _.cloneDeep(_.omit(node, ["psNode"]));
      const hasConfig = _.get(updatedNode, "hasConfig", false);

      //merge psNode and node if isEnabled is true or (if the section is configurable, and it is enabled in progress summary page)
      if (
        updatedNode.isEnabled ||
        (hasConfig && !updatedNode.isEnabled && psNode.isEnabled)
      ) {
        return _.merge(updatedNode, psNode);
      }
    }
  }

  return node;
};

/**
 * this is temporary function
 * Ideally this map should be provided from backend
 */
export const getSubjectObjectivesMap = ({ template }) => {
  const { nodes } = template;
  const result = {};

  _.map(nodes, node => {
    const { entityType, itemId, children } = node;
    const childrenObjectivesId = _.find(children, childId => {
      return _.get(nodes, `${childId}.pypSubType`) === "SUBJECT_CRITERIA";
    });
    if (entityType == "SUBJECT") {
      result[itemId] = _.get(nodes, `${childrenObjectivesId}.children`, []);
    }
  });

  result["il"] = _.get(nodes, "il=>objectives.children", []);
  return result;
};

/*
 * this function is used for Progress Report cards at various places like
 * Progress report at teacher web,
 * student Portfolio, Progress Report card at student end and at parent end
 */
export const getProgressReportCardImageStyle = {
  0: {
    backgroundColor: colors.purple93,
    arrowStrokeColor: colors.purple63,
  },
  1: {
    backgroundColor: colors.violet94,
    arrowStrokeColor: colors.violet63,
  },
  2: {
    backgroundColor: colors.teal90,
    arrowStrokeColor: colors.teal42,
  },
  3: {
    backgroundColor: colors.yellow88,
    arrowStrokeColor: colors.yellow50,
  },
  4: {
    backgroundColor: colors.pink95,
    arrowStrokeColor: colors.pink70,
  },
};

//to calculate final grade
export const getFinalGrade = ({
  itemId,
  subjectObjectivesMap = {},
  ratings,
  academicCriteriaSets,
}) => {
  const total = getStudentElementRatingTotal({
    itemId,
    subjectObjectivesMap,
    ratings,
    academicCriteriaSets,
  });

  const dynamicCriteriaSets = getDynamicGradeSets({
    total,
    academicCriteriaSets,
    isRatingEnabled: true,
  });

  const finalGrade = _.get(
    _.find(dynamicCriteriaSets, set => {
      return _.get(set, "criteriaType", "") == "FINAL_GRADE";
    }),
    "value",
    ""
  );

  return finalGrade;
};

//this function checkes if we can merge attendanceSubjectSpecific node and merges it with attendance node

export const mergeAttendanceSubjectSpecificNode = ({ node, nodes }) => {
  const configValue = _.get(node, "configValue", {});
  //merge attendanceSubjectSpecific for first time. (This happens only for MYP)
  if (
    configValue.showAttendance == SEPARATE &&
    configValue.displayOn == SEPARATE_PAGE &&
    node.attendanceType != "SUBJECT_SPECIFIC"
  ) {
    //merge attendanceSubjectSpecific node
    const subjectSpecificNode = _.omit(nodes["attendanceSubjectSpecific"], [
      "id",
    ]);
    let updatedNode = _.cloneDeep(_.omit(node, ["webPreview"]));
    updatedNode = _.merge(updatedNode, subjectSpecificNode);
    return { shouldMerge: true, updatedNode };
  } else return { shouldMerge: false };
};

//this function sets isEnabled and psNode.isEnabled to false for nodes
const disableNodes = ({ nodes }) => {
  for (const key in nodes) {
    const node = nodes[key];

    if (node.isEnabled) {
      node.isEnabled = false;
    }
    //if psNode exists set value of isEnabled to false
    if (node.psNode) {
      node.psNode.isEnabled = false;
    }
  }
};
const updateSubjectAttendanceNodes = ({
  nodes,
  isEnabled,
  psNodeIsEnabled,
}) => {
  for (const key in nodes) {
    const node = nodes[key];
    //enable subject attendance nodes if needed.
    if (node.attendanceType == "SUBJECT_ATTENDANCE") {
      if (isEnabled) {
        node.isEnabled = true;
      }
      if (psNodeIsEnabled && !_.isEmpty(node.psNode)) {
        node.psNode.isEnabled = true;
      }
    }
  }
};

export const mergeNodes = ({
  nodes,
  nodeId,
  nodeIdToMerge,
  omitFields = ["id"],
}) => {
  const nodeToMerge = _.omit(nodes[nodeIdToMerge], omitFields);
  nodes[nodeId] = _.merge(nodes[nodeId], nodeToMerge);
};

export const getNodesByUid = ({ nodes, uid, shouldCloneDeep = false }) => {
  const filteredNodes = _.pickBy(nodes, (value, key) => {
    return value.uid == uid;
  });

  return shouldCloneDeep ? _.cloneDeep(filteredNodes) : filteredNodes;
};

export const onAttendanceConfigOptionChange = ({ value, key, nodes }) => {
  const parentNode = _.get(nodes, "attendance", {});
  const configValue = _.get(parentNode, "configValue", {});
  const parentNodeId = _.get(parentNode, "id", "");
  if (_.isEmpty(configValue)) {
    return {};
  }
  switch (value) {
    case CUMULATIVE:
    case SEPARATE: {
      const updatedNodes = getNodesByUid({
        nodes,
        uid: "ATTENDANCE",
        shouldCloneDeep: true,
      });
      //1) Disable ATTENDANCE nodes
      disableNodes({ nodes: updatedNodes });
      //2) Merge attendanceCumulative node with parentNode
      const nodeIdToMerge =
        value == CUMULATIVE
          ? "attendanceCumulative"
          : "attendanceSubjectSpecific";

      mergeNodes({
        nodes: updatedNodes,
        nodeId: parentNodeId,
        nodeIdToMerge,
      });
      //3) make isEnabled of parent node true
      updatedNodes[parentNodeId].isEnabled = true;
      //4) update the config value
      updatedNodes[parentNodeId].configValue[key] = value;
      updatedNodes[parentNodeId].configValue.displayOn =
        updatedNodes[parentNodeId].defaultConfigValue.displayOn;

      return { updatedNodes, scrollToNodeId: parentNodeId };
    }
    case PERCENTAGE:
    case NUMBER:
      return {
        updatedNodes: {
          [parentNodeId]: {
            ...parentNode,
            configValue: { ...parentNode.configValue, displayIn: value },
          },
        },
      };
    case SEPARATE_PAGE: {
      const updatedNodes = getNodesByUid({
        nodes,
        uid: "ATTENDANCE",
        shouldCloneDeep: true,
      });
      //1) Disable ATTENDANCE nodes
      disableNodes({ nodes: updatedNodes });
      //2) make isEnabled of parent node true
      updatedNodes[parentNodeId].isEnabled = true;
      //3)update the config value
      updatedNodes[parentNodeId].configValue[key] = value;

      return { updatedNodes, scrollToNodeId: parentNodeId };
    }
    case PROGRESS_SUMMARY_PAGE: {
      const updatedNodes = getNodesByUid({
        nodes,
        uid: "ATTENDANCE",
        shouldCloneDeep: true,
      });
      const showAttendance = _.get(configValue, "showAttendance", "");
      switch (showAttendance) {
        case SEPARATE:
        case CUMULATIVE:
          {
            disableNodes({ nodes: updatedNodes });
            //make psNode.isEnabled true for parent node
            const parentNode = updatedNodes[parentNodeId];
            if (!_.isEmpty(parentNode.psNode)) {
              parentNode.psNode.isEnabled = true;
            }
          }
          break;
        default: {
          updatedNodes[parentNodeId].isEnabled = false;
          updatedNodes[parentNodeId].psNode.isEnabled = true;
        }
      }
      //update the config value
      updatedNodes[parentNodeId].configValue[key] = value;

      return { updatedNodes, scrollToNodeId: "ps" };
    }
    case SUBJECT_REPORT_PAGE: {
      const updatedNodes = getNodesByUid({
        nodes,
        uid: "ATTENDANCE",
        shouldCloneDeep: true,
      });
      //disable attendance nodes
      disableNodes({ nodes: updatedNodes });
      updateSubjectAttendanceNodes({
        nodes: updatedNodes,
        isEnabled: true,
        psNodeIsEnabled: false,
      });
      //update the config value
      updatedNodes[parentNodeId].configValue[key] = value;
      return { updatedNodes, scrollToNodeId: "sr" };
    }
  }
};

export const onAdvisorCommentConfigOptionChange = ({ value, nodes }) => {
  const parentNode = nodes["advisorComment"];
  const parentNodeId = _.get(parentNode, "id", "");

  let isEnabled;
  let psNodeIsEnabled;
  let scrollToNodeId;

  switch (value) {
    case SEPARATE_PAGE:
      isEnabled = true;
      psNodeIsEnabled = false;
      scrollToNodeId = parentNodeId;
      break;
    case PROGRESS_SUMMARY_PAGE:
      isEnabled = false;
      psNodeIsEnabled = true;
      scrollToNodeId = "ps";
      break;
  }
  //update node and scroll to node
  const updatedNodes = {
    [parentNodeId]: {
      isEnabled,
      psNode: { ...parentNode.psNode, isEnabled: psNodeIsEnabled },
      configValue: { displayOn: value },
    },
  };

  return { updatedNodes, scrollToNodeId };
};
// this is to update the option icon's state between active and disabled based on the calculated value isDisabled
export const getUpdatedSvg = ({ svg, isDisable }) => {
  let svgProps = _.get(svg, "props", {});
  if (!_.isEmpty(svgProps)) {
    svgProps = { ...svgProps, disabled: isDisable };
  }
  return { ...svg, props: svgProps };
};

export const getStatusLabelByStatus = ({ status, label, t }) => {
  switch (status) {
    case "PUBLISHED": {
      return t("common:shared_with_with_label", {
        label,
      });
    }
    case "LOCKING": {
      return t("common:locking");
    }
    default: {
      return null;
    }
  }
};

export const getSignatureStyle = ({ index, length, type }) => {
  const modulo = length > 2 ? 3 : 2;
  const position = (index + 1) % modulo;

  if (type == "image") {
    switch (position) {
      case 0:
        return { backgroundPosition: "right" };
      case 1:
        return { backgroundPosition: "left" };
      case 2:
        return { backgroundPosition: "center" };
    }
  } else {
    switch (position) {
      case 0:
        return { alignItems: "flex-end", textAlign: "end" };
      case 1:
        return { alignItems: "flex-start", textAlign: "start" };
      case 2:
        return { alignItems: "center", textAlign: "center" };
    }
  }
};

//this function is used to render components of static pages outside of static pages
//currently this function is used to render signatory related components in Transcripts module.
export const renderStaticFieldComp = ({
  key,
  nodes,
  replicaId,
  data: propData,
  updateRefs,
  scale,
}) => {
  const data = _.get(propData, replicaId ? replicaId : key, {});
  const node = _.get(nodes, key, {});

  const customValueFunction = _.get(data, "customValueFunction", null);

  const Component = _.get(data, "component", null);
  const value = customValueFunction
    ? customValueFunction(nodes)
    : _.isEmpty(node.value)
    ? data.defaultValue
    : node.value;

  if (Component) {
    return (
      <AnimateHeight
        duration={300}
        key={key}
        height={node.isEnabled ? "auto" : 0}
        style={{ width: "auto" }}
        customRef={ref => (updateRefs ? updateRefs({ ref, id: key }) : null)}
      >
        <Component
          {..._.omit(data, ["component"])}
          containerStyle={
            typeof data.containerStyle == "function"
              ? data.containerStyle(scale)
              : data.containerStyle
          }
          emptyStyle={
            _.isEmpty(node.value) && data.emptyStyle ? data.emptyStyle : {}
          }
          id={node.id}
          scale={scale}
          value={value}
          imageStyle={data.imageStyle ? data.imageStyle : {}}
        />
      </AnimateHeight>
    );
  }
  return null;
};

//This function adds dummy node (if required) to solve border issue in student details section in student transcript
export const addDummyNodeInTranscriptStudentDetails = ({
  nodes,
  replicationFormats,
}) => {
  const newNodes = _.cloneDeep(nodes);
  const studentDetailsEnabledChildren = _.filter(
    _.get(nodes, "studentDetails.children", []),
    nodeId => {
      const node = nodes[nodeId];
      const nodeUid = _.get(node, "uid", "");
      return _.get(node, "isEnabled", false) && nodeUid != "STUDENT_NAME";
    }
  );
  //we need to create dummy children nodes in order to have border that has full width
  const newNodesCount = _.size(studentDetailsEnabledChildren) % 2;
  if (newNodesCount == 1) {
    const referenceNode = _.get(replicationFormats, "studentDetail", {});
    //clone the reference  node
    const newNode = _.cloneDeep(referenceNode);
    newNode.id = generateRandomId();
    //finally add the new node in nodes
    newNodes[newNode.id] = newNode;
    newNodes.studentDetails.children = [
      ...newNodes.studentDetails.children,
      newNode.id,
    ];
  }
  return newNodes;
};

export const constructNodesForTranscript = ({ nodes, replicationFormats }) => {
  return addDummyNodeInTranscriptStudentDetails({ nodes, replicationFormats });
};

export const getNodesByUids = ({ nodes, uids }) => {
  const outputNodes = {};
  _.forEach(nodes, node => {
    const nodeUid = _.get(node, "uid", "");
    if (_.includes(uids, nodeUid)) {
      outputNodes[nodeUid] = node;
    }
  });
  return outputNodes;
};
