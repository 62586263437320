import PropTypes from "prop-types";
import React from "react";
import classes from "./TaggableInputField.scss";
import classNames from "classnames";
import { ProfileCascade, I18nHOC } from "UIComponents";
import { MentionsInput, Mention } from "./MentionLibrary";
import { _getTriggerRegex } from "./MentionLibrary/MentionsInput";
import { removeAsterisk } from "Utils";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { colors } from "Constants";

import Textarea from "react-textarea-autosize";

class TaggableInputField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuggestions: false,
      atIndex: -1,
      setCursorAtEnd: false,
      enabled: false,
    };
  }

  onEnterPress = e => {
    if (this.props.onEnterPress) this.props.onEnterPress(e);
  };

  addOthers = () => {
    let value = this.props.value;
    const regEx = _getTriggerRegex("@", { allowSpaceInQuery: true });

    let shouldAddAt = false;

    if (value && value.length > 0 && value[value.length - 1] !== "@") {
      shouldAddAt = true;
    } else if (_.isEmpty(value)) {
      shouldAddAt = true;
    }

    if (shouldAddAt) {
      value = _.isEmpty(value) ? "@" : `${value} @`;
      this.setState({ value });
      this.updateLocalComment(value);
    }

    // if (!value.match(regEx)) {

    // }

    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  removeFocus = () => {
    if (this.inputRef && this.inputRef.blur) {
      this.inputRef.blur();
    }
  };

  matchRegex = value => {
    //match regex for @
    const escapeRegex = str => str.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const escapedTriggerChar = escapeRegex("@");
    // const reg = new RegExp(
    //   `(?:^|\\s)(${escapedTriggerChar}([^${
    //     true ? "" : "\\s"
    //   }${escapedTriggerChar}]*))$`
    // );
    const reg = new RegExp(`(@([a-zA-z]+[ ]*[a-zA-Z]*)*)+`);
    return value ? value.replace("&nbsp;", "").match(reg) : null;
  };

  updateLocalComment = param => {
    const { name, updateInputField } = this.props;
    if (updateInputField) {
      if (name) {
        updateInputField({ [name]: param });
      } else {
        updateInputField(param);
      }
    }
  };

  renderSuggestions = (item, search, highlightedDisplay, index, focused) => {
    // console.log(item, search, highlightedDisplay, index, focused);

    return (
      <div
        // onClick={() => this.onItemClicked(item)}
        className={classes.listItemContainer}
        style={{ backgroundColor: focused ? colors.strokeOne : "white" }}
      >
        <div className={classes.profileImage}>
          <ProfileCascade
            items={[
              {
                id: item.id,
                value: item.firstName,
                imageUrl: item.profileImage,
              },
            ]}
          />
        </div>
        <div>{highlightedDisplay}</div>
      </div>
    );
  };

  handleChange = e => {
    this.updateLocalComment(e.target.value);
  };

  handleKeyDown = e => {
    const { allowSubmitOnEnter, allowSubmitOnCmdEnter } = this.props;

    const shouldHandleEnterPress =
      (e.keyCode === 13 && allowSubmitOnEnter) ||
      (e.keyCode === 13 && (e.metaKey || e.ctrlKey) && allowSubmitOnCmdEnter);

    if (shouldHandleEnterPress) {
      e.preventDefault();
      this.updateLocalComment(e.target.value);
      this.onEnterPress(e);
    }
  };

  render() {
    const {
      props: {
        value,
        collaborators,
        parentNode,
        disableTagging,
        userInfo,
        showProfileAvatar,
        mentionInputConStyle,
        t,
        onBlur,
        commentContainerRef,
        placeholder,
        textAreaStyle,
        highlighterContainerStyle,

        controlContainerStyle,

        onPaste,

        textAreaInputRef,
        minRows,
        maxRows,
        textAreaStyles,
        suggestionsOverlayStyle,
        notifyAllMessage,
      },
      state: { showSuggestions, enabled },
    } = this;

    return (
      <div className={classes.container}>
        <MentionsInput
          inputRef={ref => (this.inputRef = ref)}
          allowSpaceInQuery={true}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          parentNode={parentNode}
          commentContainerRef={commentContainerRef}
          disableTagging={disableTagging}
          userInfo={userInfo}
          showProfileAvatar={showProfileAvatar}
          mentionInputConStyle={mentionInputConStyle}
          onBlur={onBlur}
          placeholder={placeholder}
          textAreaStyle={textAreaStyle}
          highlighterContainerStyle={highlighterContainerStyle}
          controlContainerStyle={controlContainerStyle}
          textAreaInputRef={textAreaInputRef}
          minRows={minRows}
          onPaste={onPaste}
          maxRows={maxRows}
          suggestionsOverlayStyle={suggestionsOverlayStyle}
          textAreaStyles={textAreaStyles}
          notifyAllMessage={notifyAllMessage}
        >
          <Mention
            trigger="@"
            data={_.map(collaborators, item => {
              if (item.firstName === "common:all") {
                item.display = t(item.firstName);
              } else {
                item.display = `${item.firstName} ${item.lastName}`;
              }
              item.display = item.display.trim();
              return item;
            })}
            renderSuggestion={this.renderSuggestions}
            style={{ backgroundColor: "#E6E6E8" }}
            appendSpaceOnAdd={true}
          />
        </MentionsInput>
      </div>
    );
  }
}

TaggableInputField.defaultProps = {
  disableTagging: false,
  allowSubmitOnEnter: false,
  allowSubmitOnCmdEnter: false,
};

TaggableInputField.propTypes = {
  disableTagging: PropTypes.bool,
  allowSubmitOnCmdEnter: PropTypes.bool,
  allowSubmitOnEnter: PropTypes.bool,
};

export default I18nHOC({ resource: ["common"] })(TaggableInputField);
