import gql from "graphql-tag";
import {
  tagsOnBoardingFragment,
  communityActivityFragment,
  staffOnBoardingFragment,
  schoolFragment,
} from "./OnBoardingFragments";
export const updateUserBasicInfoMutation = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $personalEmail: String
    $aboutMe: String
    $experience: String
    $currentSchoolDuration: String
    $workshopCount: Int
    $city: String
    $country: String
    $onboardingStatus: Int
    $profileImage: String
    $banner: String
    $facebook: String
    $twitter: String
    $linkedin: String
  ) {
    community {
      addCommunityDetailsForUser(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          personalEmail: $personalEmail
          aboutMe: $aboutMe
          experience: $experience
          currentSchoolDuration: $currentSchoolDuration
          workshopCount: $workshopCount
          city: $city
          country: $country
          onboardingStatus: $onboardingStatus
          profileImage: $profileImage
          banner: $banner
          facebook: $facebook
          twitter: $twitter
          linkedin: $linkedin
        }
      ) {
        id
      }
    }
  }
`;

export const handleMultipleUserTagMutation = gql`
  mutation handleMultipleUserTag(
    $addTagMapping: [ID!]
    $removeTagMapping: [ID!]
    $createNewTags: [TagInput!]
    $returnTagTypes: [TAG_TYPE_ENUM!]
  ) {
    community {
      handleMultipleUserTag(
        input: {
          addTagMapping: $addTagMapping
          removeTagMapping: $removeTagMapping
          createNewTags: $createNewTags
          returnTagTypes: $returnTagTypes
        }
      ) {
        ...tagInfo
      }
    }
  }
  ${tagsOnBoardingFragment.tag}
`;

export const addProfileEntityMutation = gql`
  mutation addProfileEntity(
    $user: ID
    $label: String
    $type: COMMUNITY_ACTIVITY_TYPE_ENUM!
    $category: ID
  ) {
    community {
      addProfileEnity: addCommunityActivity(
        input: { user: $user, label: $label, type: $type, category: $category }
      ) {
        ...communityActivityItem
      }
    }
  }
  ${communityActivityFragment.communityActivity}
`;

export const updateWorkshopOrTrainingMutation = gql`
  mutation updateWorkshopOrTraining(
    $id: ID!
    $label: String
    $subtext: String
    $category: ID
  ) {
    community {
      updateCommunityActivity(
        input: {
          id: $id
          subtext: $subtext
          label: $label
          category: $category
        }
      ) {
        ...communityActivityItem
      }
    }
  }
  ${communityActivityFragment.communityActivity}
`;

export const removeCommunityActivityFromUserMutation = gql`
  mutation removeCommunityActivityFromUser($user: ID!, $activity: ID!) {
    community {
      removeCommunityActivityFromUser(
        input: { user: $user, activity: $activity }
      )
    }
  }
`;

export const registerUserForCommunityMutation = gql`
  mutation registerUserForCommunity($user: ID!) {
    community {
      registerUserForCommunity(input: { user: $user }) {
        ...staffItem
      }
    }
  }
  ${staffOnBoardingFragment.staff}
`;

export const removeCommunitySchoolUserTenureMutation = gql`
  mutation removeCommunitySchoolUserTenure($id: ID!) {
    community {
      removeCommunitySchoolUserTenure(input: { id: $id })
    }
  }
`;

export const addCommunitySchoolUserTenureMutation = gql`
  mutation addCommunitySchoolUserTenure(
    $user: ID!
    $school: ID
    $startDate: String
    $endDate: String
    $currentSchool: Boolean!
    $city: String
    $country: String
  ) {
    community {
      addProfileEnity: addCommunitySchoolUserTenure(
        input: {
          user: $user
          school: $school
          startDate: $startDate
          endDate: $endDate
          currentSchool: $currentSchool
          city: $city
          country: $country
        }
      ) {
        ...schoolTenureItem
      }
    }
  }
  ${schoolFragment.schoolTenure}
`;

export const changeCommunitySchoolUserTenureMutation = gql`
  mutation changeCommunitySchoolUserTenure(
    $id: ID!
    $startDate: String
    $endDate: String
    $currentSchool: Boolean
    $school: ID
    $city: String
    $country: String
    $designation: ID
  ) {
    community {
      changeCommunitySchoolUserTenure(
        input: {
          id: $id
          startDate: $startDate
          endDate: $endDate
          school: $school
          currentSchool: $currentSchool
          city: $city
          country: $country
          designation: $designation
        }
      ) {
        ...schoolTenureItem
      }
    }
  }
  ${schoolFragment.schoolTenure}
`;

export const addSchoolInCommunityMutation = gql`
  mutation addSchoolInCommunity($user: ID, $country: String, $name: String) {
    community {
      addSchoolInCommunity(
        input: { user: $user, country: $country, name: $name }
      ) {
        id
        name
        country
      }
    }
  }
`;

export const handleCommunityUserGlobalGradeMappingMutation = gql`
  mutation handleCommunityUserGlobalGradeMapping(
    $valuesToAdd: [ID!]
    $valuesToRemove: [ID!]
  ) {
    community {
      handleCommunityUserGlobalGradeMapping(
        input: { valuesToAdd: $valuesToAdd, valuesToRemove: $valuesToRemove }
      ) {
        id
      }
    }
  }
`;

export const createNewTagMutation = gql`
  mutation createNewTag($label: String, $type: TAG_TYPE_ENUM!, $user: ID) {
    community {
      createTag(input: { label: $label, type: $type, user: $user }) {
        id
        label
        type
      }
    }
  }
`;
