import { AttachmentList } from "Attachment";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { I18nHOC, ProfileCascade, TextMoreLess, UIModal } from "UIComponents";
import classes from "./StudentResponseV2.scss";
import {
  TableColored,
  GoalsOutlined,
  TableOutlined,
  ChevronRightOutlined,
  ChevronLeftOutlined,
  SinglePointRubricOutlined,
  RubricOutlined,
  ChecklistOutlined,
} from "@toddle-design/web-icons";
import { Button, IconButton } from "@toddle-design/web";
import { ASSESSMENT_TOOLS, getCriteriaSetOfPypType } from "modules/Services";
import { AssessmentToolPreviewModalV2 } from "UnitPlans/components/LibraryCommonComponents";
import _ from "lodash";
import {
  getAttachmentsToDisplayWithPostConfig,
  checkAchievementCriteriaExists,
  checkAssessmentHasPypEvaluation,
} from "ClassRoom/modules/utils";
import classNames from "classnames";
import PypEvaluation from "AssessmentEvaluation/components/PypEvaluation";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import RubricTable from "Rubric/components/RubricTable";
import { AttachmentsContainer } from "./AttachmentsContainer/AttachmentsContainer";
import { getSettingValue } from "modules/PermissionModule";

const StudentResponseEvaluation = props => {
  const {
    assessmentToolType,
    evaluationTextStyle,
    isPreviewCard,
    setShowAssessmentTool,
    t,
    isAttachmentGroupEmpty,
    setShowRateLearningGoalsModal,
    showRateLearningGoals,
    isFullWidth,
  } = props;
  const evaluationContentContainerClass = classNames({
    [classes.evaluationContentContainer]: !isPreviewCard,
    [classes.evaluationContentContainerPreview]: isPreviewCard,
  });

  const evaluationContentClass = classNames({
    [classes.evaluationContent]: true,
    [classes.evaluationContentPreview]: isPreviewCard,
  });
  const showAssessmentToolCard =
    !_.isEqual(assessmentToolType, "NONE") &&
    !_.isEqual(assessmentToolType, null);

  const itemCardStyle = {
    minWidth: isFullWidth ? "100%" : "300px",
    paddingRight: "16px",
  };

  const getAssessmentToolIcon = type => {
    switch (type) {
      case "RUBRIC":
        return <RubricOutlined size="medium" />;
      case "SINGLE_POINT_RUBRIC":
        return <SinglePointRubricOutlined size="medium" />;
      case "CHECKLIST":
        return <ChecklistOutlined size="medium" />;
      default:
        return <TableOutlined size="medium" />;
    }
  };

  return (
    (showAssessmentToolCard || showRateLearningGoals) && (
      <div className={classes.evaluationContainer}>
        {!isPreviewCard && (
          <div className={classes.evaluationText} style={evaluationTextStyle}>
            {t("common:evaluation")}
          </div>
        )}
        <div className={evaluationContentContainerClass}>
          {showAssessmentToolCard && (
            <div
              className={classes.evaluationItemContainer}
              style={{ ...itemCardStyle }}
            >
              <div
                className={evaluationContentClass}
                onClick={() => setShowAssessmentTool(true)}
              >
                <div className={classes.evaluationIcon}>
                  {getAssessmentToolIcon(assessmentToolType)}
                </div>
                <div className={classes.evaluationTitle}>
                  {t(
                    _.get(
                      _.find(
                        ASSESSMENT_TOOLS,
                        item => _.get(item, "type") == assessmentToolType
                      ),
                      "locale",
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          {showRateLearningGoals && (
            <div
              className={classes.evaluationItemContainer}
              style={{ ...itemCardStyle }}
            >
              <div
                className={evaluationContentClass}
                onClick={() => setShowRateLearningGoalsModal(true)}
              >
                <div className={classes.evaluationIcon}>
                  <GoalsOutlined size={"medium"} variant={"link"} />
                </div>
                <div className={classes.evaluationTitle}>
                  {t("common:learning_goals")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const RateOnLearningGoalsModalContent = props => {
  const {
    isComplete,
    parentId,
    parentType,
    showToggleStudentPypEvaluateStatus,
    evaluateIBPYPElements,
    studentDetails,
    assessmentFields,
    ibPYPElementRatings,
    isEvaluated,
    curriculumProgramType,
    course,
    t,
    setShowRateLearningGoalsModal,
  } = props;
  return (
    <React.Fragment>
      <div className={classes.modalHeader}>
        <span>{t("common:learning_goals")}</span>
        <span className={classes.actionButtons}>
          <Button
            variant={"outlined"}
            size={"medium"}
            onClick={() => setShowRateLearningGoalsModal(false)}
          >
            {t("common:cancel")}
          </Button>
        </span>
      </div>
      <div className={classes.modalBody}>
        <div className={classes.innerWrapper}>
          <PypEvaluation
            isComplete={isComplete}
            parentId={parentId}
            parentType={parentType}
            showToggleStudentPypEvaluateStatus={
              showToggleStudentPypEvaluateStatus
            }
            evaluateIBPYPElements={evaluateIBPYPElements}
            studentDetails={studentDetails}
            assessmentFields={assessmentFields}
            ibPYPElementRatings={ibPYPElementRatings}
            isEvaluated={isEvaluated}
            curriculumProgramType={curriculumProgramType}
            course={course}
            isPreviewMode={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const RateOnLearningGoalsPreviewModal = props => {
  const { setShowRateLearningGoalsModal } = props;
  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      onRequestClose={() => setShowRateLearningGoalsModal(false)}
    >
      <RateOnLearningGoalsModalContent {...props} />
    </UIModal>
  );
};

const StudentResponseV2 = props => {
  const {
    t,
    item,
    showSeeMore,
    containerStyle = {},
    responseStyle,
    horizontalCardStyle,
    showEvaluation,
    isPreviewCard,
    evaluationTextStyle,
    attachmentsContainerStyle,
    course,
    curriculumProgramType,
    getCriteriaSetOfPypType,
    isSidePaneContent,
    isFinalCriteriaSettingEnabled,
  } = props;

  const student = _.get(item, "student", {});
  const studentAssignmentId = _.get(item, "id", "");
  const content = _.get(item, "assignment.content", {});
  const assessmentToolTitle = _.get(content, "title.value", "");
  const assessmentTool = _.get(content, "assessmentTool", {});
  const assessmentToolId = _.get(content, "assessmentTool.id", "");
  const assessmentToolType = _.get(content, "assessmentToolType", "");
  const submission = _.get(item, "submission", {});
  const response = _.get(submission, "response", "");
  const attachmentGroups = _.get(submission, "attachmentGroups", {});
  const attachmentsToDisplay = getAttachmentsToDisplayWithPostConfig({
    attachmentGroups,
  });
  const isEvaluated = _.get(item, "isEvaluated", false);
  const isSharedWithStudent = _.get(item, "isSharedWithStudent", false);

  const toolResponses = _.get(item, "toolResponses", {});
  const ibPYPElementRatings = _.get(item, "ibPYPElementRatings", []);
  const isEvaluatedByStudent = _.get(
    item,
    "assignment.isStudentEvaluationEnabled",
    true
  );
  const isTeacherEvaluationEnabled = _.get(
    item,
    "assignment.isTeacherEvaluationEnabled",
    true
  );

  const commentMessage = _.get(
    _.find(
      _.get(item, "elementRemarks", []),
      ({ ibPYPElementType }) => ibPYPElementType === "STUDENT_ASSIGNMENT"
    ),
    "message",
    ""
  );
  const assessmentFields = _.get(
    content,
    "allFields",
    _.get(content, "fields", [])
  );
  const field_list = _.get(content, "template.body.field_list", []);
  const unitPlanFields = _.get(item, "unitPlanFields", []);

  const [showAssessmentTool, setShowAssessmentTool] = useState(false);
  const [showRateLearningGoalsModal, setShowRateLearningGoalsModal] = useState(
    false
  );

  const isEmptyAchievementCriteria = checkAchievementCriteriaExists({
    assessmentFields,
    curriculumProgramType,
    getCriteriaSetOfPypType,
  });

  const showRateLearningGoals =
    isTeacherEvaluationEnabled &&
    checkAssessmentHasPypEvaluation({
      assessmentDetails: content,
      curriculumProgramType,
    }) &&
    !isEmptyAchievementCriteria;

  const isAttachmentGroupEmpty = _.isEmpty(attachmentGroups);
  const isResponseEmpty = _.isEmpty(response);

  const showAssessmentToolCard =
    !_.isEqual(assessmentToolType, "NONE") &&
    !_.isEqual(assessmentToolType, null);
  const totalItems =
    _.size(attachmentsToDisplay) +
    (showRateLearningGoals ? 1 : 0) +
    (showAssessmentToolCard ? 1 : 0);

  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [isLeftButtonVisible, setIsLeftButtonVisible] = useState(
    currentScrollPosition > 0
  );
  const [isRightButtonVisible, setIsRightButtonVisible] = useState(
    totalItems > 1
  );

  const innerContainerClass = classNames({
    [classes.innerContainer]: true,
    [classes.innerContainerPreview]: isPreviewCard,
  });

  const wrapperClass = classNames({
    [classes.wrapper]: isSidePaneContent,
    [classes.wrapperPreview]: !isSidePaneContent,
  });

  const scrollResponseToLeft = e => {
    e.stopPropagation();
    if (!isRightButtonVisible) setIsRightButtonVisible(true);

    // When user reached at first item on left side
    if (currentScrollPosition + 284 >= 0) {
      setCurrentScrollPosition(0);
      setIsLeftButtonVisible(false);
    } else setCurrentScrollPosition(currentScrollPosition + 284);
  };
  const scrollResponseToRight = e => {
    e.stopPropagation();
    if (!isLeftButtonVisible) setIsLeftButtonVisible(true);

    // When user reached at last item on right side
    if (-(284 * Math.max(totalItems - 1, 0)) == currentScrollPosition - 284) {
      setCurrentScrollPosition(currentScrollPosition - 284);
      setIsRightButtonVisible(false);
    } else {
      setCurrentScrollPosition(currentScrollPosition - 284);
    }
  };

  return (
    <React.Fragment>
      {showAssessmentTool && (
        <AssessmentToolPreviewModalV2
          id={assessmentToolId}
          type={assessmentToolType}
          mode={"view"}
          toolResponses={toolResponses}
          title={t(
            _.get(
              _.find(ASSESSMENT_TOOLS, item => item.type == assessmentToolType),
              "locale",
              ""
            )
          )}
          showChooseButton={false}
          onClickClose={() => setShowAssessmentTool(false)}
          isEvaluatedByStudent={isEvaluatedByStudent}
          unitPlanFields={unitPlanFields}
          assessmentFields={assessmentFields}
          field_list={field_list}
          parentId={studentAssignmentId}
          parentType={"STUDENT_ASSIGNMENT"}
          studentid={_.get(student, "id", "")}
          ibPYPElementRatings={ibPYPElementRatings}
          isTeacherEvaluationEnabled={isTeacherEvaluationEnabled}
          commentMessage={commentMessage}
          showRatingContainerInViewMode={
            isFinalCriteriaSettingEnabled &&
            assessmentToolType === "MYP_OBJECTIVE_RUBRIC" &&
            isEvaluated &&
            isSharedWithStudent
          }
        />
      )}
      {showRateLearningGoalsModal && (
        <RateOnLearningGoalsPreviewModal
          isComplete={true}
          parentId={studentAssignmentId}
          parentType={"STUDENT_ASSIGNMENT"}
          showToggleStudentPypEvaluateStatus={false}
          evaluateIBPYPElements={true}
          studentDetails={student}
          assessmentFields={assessmentFields}
          ibPYPElementRatings={ibPYPElementRatings}
          isEvaluated={true}
          curriculumProgramType={curriculumProgramType}
          course={course}
          setShowRateLearningGoalsModal={setShowRateLearningGoalsModal}
          t={t}
        />
      )}

      {totalItems > 0 && (
        <React.Fragment>
          {!isSidePaneContent ? (
            <div className={classes.container} style={containerStyle}>
              <div
                className={innerContainerClass}
                style={{ position: "relative" }}
              >
                {!_.isEmpty(response) && (
                  <div className={classes.response} style={responseStyle}>
                    {showSeeMore ? (
                      <TextMoreLess value={response} minHeight={48} />
                    ) : (
                      response
                    )}
                  </div>
                )}
                <div className={classes.scrollButtonsContainer}>
                  {isLeftButtonVisible && (
                    <div className={classes.leftScrollButton}>
                      <IconButton
                        icon={<ChevronLeftOutlined />}
                        onClick={scrollResponseToLeft}
                        variant="plain"
                        className={classes.responseScrollButton}
                      />
                    </div>
                  )}
                  {isRightButtonVisible && (
                    <div className={classes.rightScrollButton}>
                      <IconButton
                        icon={<ChevronRightOutlined />}
                        onClick={scrollResponseToRight}
                        variant="plain"
                        className={classes.responseScrollButton}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={wrapperClass}
                  style={{
                    transform: `translateX(${currentScrollPosition}px)`,
                  }}
                >
                  {!isAttachmentGroupEmpty && (
                    <div
                      className={classes.attachmentListContainer}
                      style={{ width: totalItems == 1 ? "100%" : "initial" }}
                    >
                      {
                        <AttachmentsContainer
                          attachments={attachmentsToDisplay}
                          itemStyles={{ padding: "16px", borderRadius: "8px" }}
                          isStudentResponse={true}
                          containerStyle={{
                            height: "284px",
                            width: totalItems == 1 ? "initial" : "284px",
                          }}
                          attachmentsContainerStyle={{
                            display: totalItems == 1 ? "flex" : "grid",
                          }}
                          isSidePaneContent={props.isSidePaneContent ?? false}
                        />
                      }
                    </div>
                  )}
                  <StudentResponseEvaluation
                    assessmentTool={assessmentTool}
                    assessmentToolType={assessmentToolType}
                    evaluationTextStyle={evaluationTextStyle}
                    showEvaluation={showEvaluation}
                    isPreviewCard={isPreviewCard}
                    setShowAssessmentTool={setShowAssessmentTool}
                    t={t}
                    isAttachmentGroupEmpty={isAttachmentGroupEmpty}
                    setShowRateLearningGoalsModal={
                      setShowRateLearningGoalsModal
                    }
                    showRateLearningGoals={showRateLearningGoals}
                    isFullWidth={totalItems == 1}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.sidePaneContainer}>
              <AttachmentsContainer
                attachments={attachmentsToDisplay}
                itemStyles={{ padding: "16px", borderRadius: "8px" }}
                isStudentResponse={true}
                containerStyle={{ height: "284px", width: "284px" }}
                isSidePaneContent={props.isSidePaneContent ?? false}
                hasAssesmentTool={showAssessmentToolCard}
                hasLearningGoals={showRateLearningGoals}
                evaluationContent={{
                  name: assessmentToolTitle,
                  assessmentTool: _.get(content, "assessmentTool", {}),
                  assessmentToolType: _.get(content, "assessmentToolType", ""),
                }}
                setShowAssessmentTool={setShowAssessmentTool}
                setShowRateLearningGoalsModal={setShowRateLearningGoalsModal}
                responseCaption={response}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

StudentResponseV2.defaultProps = {
  showEvaluation: true,
};

const mapStateToProps = (state, ownProps) => {
  const courseId = _.get(state, "teacher.selected_class.selected_course");
  const course = getCourseDetailsFromCache({ id: courseId });
  const curriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType",
    "IB_PYP"
  );
  const userType = _.get(state, "login.userInfo.user_type", "");
  const userEntityType = _.get(state, "login.userInfo.userEntityType", "");
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  const isFinalCriteriaSettingEnabled = getSettingValue({
    name:
      userType === "student"
        ? "ShowFinalGradeRatingToStudent"
        : "ShowFinalGradeRatingToParent",
    organizationId,
    userEntityType,
  });

  return {
    course,
    curriculumProgramType,
    isFinalCriteriaSettingEnabled,
  };
};

const mapActionCreators = {
  getCriteriaSetOfPypType,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators)
)(StudentResponseV2);
