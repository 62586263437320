import gql from "graphql-tag";
import {
  attachmentFragment,
  studentResponseFragment,
  userFragment,
} from "modules/CommonFragments";
import { organizationResourceFragment } from "AppComponents/OrganizationResources/modules/OrganizationResourcesFragments";
import { assignmentFragment } from "ClassRoom/modules/ClassRoomFragments";
import { conversationsFragment } from "AppComponents/ChatContainer/modules/ChatContainerFragments";

export const customAttachmentFragment = {
  attachment: gql`
    fragment attachmentItem on Attachment {
      id
      type
      title
      streamUrl
      url
      name
      mimeType
      thumbUrl
      description
      metadata
    }
  `,
  attachmentEvidence: gql`
    fragment attachmentEvidenceItem on Attachment {
      id
      type
      title
      streamUrl
      url
      # here aliasing is needed as field name has different types across implementations
      attachmentName: name
      mimeType
      thumbUrl
      description
      metadata
      resolvedParentEntity {
        ... on AttachmentGroup {
          resolvedParentEntity {
            ... on StudentAssignmentSubmission {
              id
              assignment {
                id
                assignedStudents {
                  id
                  submission {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const userInfoFragment = gql`
  fragment userInfo on User {
    id
    firstName
    lastName
    profileImage
    email
  }
`;

export const guidanceDocumentsFragment = gql`
  fragment guidanceDocuments on SnPEvaluationCycle {
    id
    set {
      id
      guidanceDocuments {
        id
        displaySequence
        localeLabelKey
        localeSubTextKey
        icon
        localizedAttachment(filters: $filters) {
          id
          type
          title
          name
          mimeType
          metadata
          url
        }
      }
    }
  }
`;

export const assessmentFragment = {
  assessmentEvidence: gql`
    fragment assessmentItem on Assessment {
      id
      assessmentTitle: title {
        id
        value
      }
      image {
        id
        value
      }
      createdBy {
        ...userItem
      }
      assessmentType {
        id
        value
      }
      duration {
        id
        value
      }
    }
    ${userFragment.basicUserDetails}
  `,
};

export const unitPlanFragment = {
  unitPlanEvidence: gql`
    fragment unitPlanItem on UnitPlan {
      id
      unitPlanTitle: title {
        id
        value
      }
      image {
        id
        value
      }
      unitType {
        id
        value
      }
      resolvedGrades {
        id
        name
      }
      theme {
        value
        id
        resolvedMinimalTree {
          ... on ResolvedFieldThemeSet {
            id
            themes {
              label
              id
            }
          }
        }
      }
      fields(uids: ["subjects"]) {
        id
        uid
        resolvedMinimalTree {
          ... on ResolvedFieldSubjectSet {
            id
            subjects {
              id
              name
              __typename
            }
            __typename
          }
          __typename
        }
      }
    }
  `,
};

export const postFragment = {
  postEvidence: gql`
    fragment postItem on Post {
      id
      postTitle: title
      attachments {
        ...attachmentItem
      }
      createdBy {
        ...userItem
      }
      items {
        item {
          id
          ...studentResponseItem
        }
        itemType
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${studentResponseFragment.studentResponse}
  `,
};

export const subjectFragment = gql`
  fragment subject on Subject {
    id
    name
    icon
    standardSet {
      id
      title
    }
    benchmarkRootNode {
      id
    }
  }
`;

export const progressReportFragment = gql`
  fragment progressReport on StudentProgressReport {
    id
    student {
      id
      isDevUser
      firstName
      lastName
      profileImage
      email
    }
    sectionCompleteStatus {
      section
      isCompleted
    }
    createdAt
    courseProgressReportTask {
      id
      title
      progressReportTaskGroup {
        id
        academicYearId
      }
    }
  }
`;

export const progressReportsConnectionFragment = gql`
  fragment progressReportsConnection on StudentProgressReportConnection {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...progressReport
      }
    }
  }
  ${progressReportFragment}
`;

export const subjectGroupFragment = gql`
  fragment subjectGroupItem on SubjectGroup {
    id
    name
    subjects {
      id
      name
    }
  }
`;

export const evidenceItemFragment = {
  evidenceItem: gql`
    fragment evidenceItemInterface on SnPEvidenceItemInterface {
      id
      ... on Attachment {
        ...attachmentEvidenceItem
      }
      ... on Assessment {
        ...assessmentItem
      }
      ... on UnitPlan {
        ...unitPlanItem
      }
      ... on Post {
        ...postItem
      }
      ... on Subject {
        ...subject
      }
      ... on OrganizationResource {
        ...organizationResourceBasicDetailsItem
      }
      ... on StudentProgressReport {
        ...progressReport
      }
      ... on SubjectGroup {
        ...subjectGroupItem
      }
      ...assignmentItem
    }
    ${customAttachmentFragment.attachmentEvidence}
    ${assessmentFragment.assessmentEvidence}
    ${unitPlanFragment.unitPlanEvidence}
    ${postFragment.postEvidence}
    ${subjectFragment}
    ${organizationResourceFragment.basicDetails}
    ${progressReportFragment}
    ${subjectGroupFragment}
    ${assignmentFragment.assignmentSelectionFeed}
  `,
};

export const snpPracticeEvidenceFragment = {
  evidenceDetails: gql`
    fragment evidenceItem on SnPPracticeEvidence {
      id
      evidenceType
      evidenceStatus
      evidenceItem {
        ...evidenceItemInterface
      }
      createdBy {
        id
      }
      createdAt
      parentItem {
        item {
          id
        }
      }
    }
    ${evidenceItemFragment.evidenceItem}
  `,
};

export const snpPracticesFragment = {
  practiceDetails: gql`
    fragment practiceItem on SnPPractice {
      id
      code
      type
      label: localeLabelKey
      localizedLabel: label
    }
  `,
  evidenceBasicDetails: gql`
    fragment evidenceBasicDetailsItem on SnPPractice {
      id
      taggedEvidence(filters: $filters) {
        edges {
          node {
            id
            evidenceType
            evidenceItem {
              id
            }
            parentItem {
              item {
                id
              }
            }
          }
        }
      }
    }
  `,
  practiceEvidenceDetailsV2: gql`
    fragment practiceItem on SnPPractice {
      id
      code
      type
      label: localeLabelKey
      taggedEvidence(
        first: $first
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        applyTeacherDashboardOrdering: $applyTeacherDashboardOrdering
        filters: $evidenceFilters
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...evidenceItem
          }
        }
      }
    }
    ${snpPracticeEvidenceFragment.evidenceDetails}
  `,
};

export const snpStandardsFragment = {
  standardDetails: gql`
    fragment snpStandardItem on SnPStandard {
      id
      code
      label: localeLabelKey
      localizedLabel: label
      subLabel: localeSubTextKey
      localizedSubLabel: subText
      practicesV2(orderBy: DISPLAY_SEQUENCE, orderByDirection: ASC) {
        edges {
          node {
            ...practiceItem
          }
        }
      }
    }
    ${snpPracticesFragment.practiceDetails}
  `,
  standardEvidenceDetails: gql`
    fragment snpStandardItem on SnPStandard {
      id
      practicesV2(orderBy: DISPLAY_SEQUENCE, orderByDirection: ASC) {
        edges {
          node {
            ...practiceItem
          }
        }
      }
    }
    ${snpPracticesFragment.practiceEvidenceDetailsV2}
  `,
  standardEvidenceBasicDetails: gql`
    fragment standardEvidenceBasicDetailsItem on SnPStandard {
      id
      practicesV2(orderBy: DISPLAY_SEQUENCE, orderByDirection: ASC) {
        edges {
          node {
            id
            taggedEvidence(filters: $filters) {
              totalCount
            }
          }
        }
      }
    }
  `,
};

export const snpSetFrameworkFragment = {
  frameworkDetails: gql`
    fragment snpSetItem on SnPSet {
      id
      frameworks {
        id
        code
        label: localeLabelKey
        localizedLabel: label
        standards {
          ...snpStandardItem
        }
      }
    }
    ${snpStandardsFragment.standardDetails}
  `,
};

export const visitorFragment = {
  visitorFeedDetails: gql`
    fragment visitorItem on Staff {
      id
      profileImage
      firstName
      lastName
    }
  `,
};

export const snpDocumentCategoryEvidenceFragment = gql`
  fragment snpDocumentCategoryEvidence on SnPDocumentCategoryEvidence {
    id
    evidenceType
    evidenceItem {
      ...evidenceItemInterface
    }
    parentItem {
      item {
        id
      }
    }
  }
  ${evidenceItemFragment.evidenceItem}
`;

export const helpTextFragments = {
  basicDetails: gql`
    fragment helpTextBasicDetails on HelpText {
      id
    }
  `,
  details: gql`
    fragment helpTextDetails on HelpText {
      id
      title
      content
      localizedAttachment(filters: $attachmentFilters) {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const statusFragment = {
  basicDetails: gql`
    fragment statusItem on SnPUserStatus {
      status
      updatedBy {
        id
        firstName
        lastName
      }
      updatedAt
    }
  `,
};

export const snpDocumentCategoryFragment = {
  basicDetails: gql`
    fragment snpDocumentCategoryItem on SnPDocumentCategory {
      id
      title
      localeLabelKey
      userStatus: status {
        ...statusItem
      }
      createdAt
      description
      isDefaultDocumentCategory
      taggedEvidence {
        totalCount
      }
    }
    ${statusFragment.basicDetails}
  `,
  allDetails: gql`
    fragment snpDocumentCategoryItem on SnPDocumentCategory {
      id
      title
      localeLabelKey
      userStatus: status {
        ...statusItem
      }
      createdAt
      description
      localeSubTextKey
      userDescription {
        id
        description
      }
      isDefaultDocumentCategory
      taggedEvidence(
        first: $first
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...snpDocumentCategoryEvidence
          }
        }
      }
      i18nHelpText {
        ...helpTextBasicDetails
      }
    }
    ${helpTextFragments.basicDetails}
    ${snpDocumentCategoryEvidenceFragment}
    ${statusFragment.basicDetails}
  `,
  documentConversations: gql`
    fragment documentConversations on SnPDocumentCategory {
      id
      conversation {
        ...conversationBasicDetails
      }
    }
    ${conversationsFragment.basicDetails}
  `,
  documentCategoryHelpText: gql`
    fragment documentCategoryHelpText on SnPDocumentCategory {
      id
      i18nHelpText(filters: { locale: $locale }) {
        ... on HelpText {
          ...helpTextDetails
        }
      }
    }
    ${helpTextFragments.details}
  `,
  evidenceBasicDetails: gql`
    fragment evidenceBasicDetailsItem on SnPDocumentCategory {
      id
      taggedEvidence(filters: $filters) {
        edges {
          node {
            id
            evidenceType
            evidenceItem {
              id
            }
            parentItem {
              item {
                id
              }
            }
          }
        }
      }
    }
  `,
  responseDetails: gql`
    fragment responseItem on SnPDocumentCategory {
      id
      title
      userDescription {
        id
        description
      }
    }
  `,
  taggedPractices: gql`
    fragment snpDocumentCategoryItem on SnPDocumentCategory {
      id
      taggedPractices {
        evaluationCycle {
          id
        }
        taggedPractice {
          practice {
            ...practiceItem
          }
        }
      }
    }
    ${snpPracticesFragment.practiceEvidenceDetailsV2}
  `,
  statusDetails: gql`
    fragment statusDetailsItem on SnPDocumentCategory {
      id
      userStatus: status {
        ...statusItem
      }
    }
    ${statusFragment.basicDetails}
  `,
  evidenceFeedDetails: gql`
    fragment evidenceFeedDetailsItem on SnPDocumentCategory {
      id
      taggedEvidence(
        first: $first
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...snpDocumentCategoryEvidence
          }
        }
      }
    }
    ${snpDocumentCategoryEvidenceFragment}
  `,
};

export const snpDocumentCategoryGroupFragment = gql`
  fragment snpDocumentCategoryGroupItem on SnPDocumentCategoryGroup {
    id
    title
    localeLabelKey
    description
    localeSubTextKey
    displaySequence
    isCustomCategoryAllowed
    documentCategories {
      ...snpDocumentCategoryItem
    }
  }
  ${snpDocumentCategoryFragment.basicDetails}
`;

export const evaluationCycleStandardFragments = {
  standardConversations: gql`
    fragment standardConversations on SnPEvaluationCycleStandard {
      id
      conversation {
        ...conversationBasicDetails
      }
    }
    ${conversationsFragment.basicDetails}
  `,
  standardStatusDetails: gql`
    fragment standardItem on SnPEvaluationCycleStandard {
      id
      userStatus: status {
        ...statusItem
      }
    }
    ${statusFragment.basicDetails}
  `,
  standardDetails: gql`
    fragment standardDetailsItem on SnPEvaluationCycleStandard {
      id
      userStatus: status {
        ...statusItem
      }
      standard {
        id
        practicesV2(orderBy: DISPLAY_SEQUENCE, orderByDirection: ASC) {
          edges {
            node {
              ...practiceItem
            }
          }
        }
      }
    }
    ${statusFragment.basicDetails}
    ${snpPracticesFragment.practiceEvidenceDetailsV2}
  `,
  standardBasicDetails: gql`
    fragment standardBasicDetailsItem on SnPEvaluationCycleStandard {
      id
      userStatus: status {
        ...statusItem
      }

      standard {
        id
        ...standardEvidenceBasicDetailsItem
      }
    }
    ${statusFragment.basicDetails}
    ${snpStandardsFragment.standardEvidenceBasicDetails}
  `,
};

export const snpEvaluationCycleFragment = {
  cycleDetails: gql`
    fragment snpEvaluationCycleItem on SnPEvaluationCycle {
      id
      isArchived
      title
      createdAt
      year
      month
      availableTabs
      curriculumProgram {
        id
        type
      }
      set {
        id
        title
      }
      cycleType
    }
  `,
  cycleStandardBasicDetails: gql`
    fragment cycleItem on SnPEvaluationCycle {
      id
      evaluationCycleStandard {
        ...standardBasicDetailsItem
      }
    }
    ${evaluationCycleStandardFragments.standardBasicDetails}
  `,
};
