import _ from "lodash";
import ACLStore from "lib/aclStore";
import { getPostDetailFromFeedCache } from "Post/modules/PostGraphqlHelpers";
import { getSettingValue } from "modules/PermissionModule";
/**
 * This function returns a sharing status string based on the sharing
 * permission given for the post.
 *
 * @param {*} param0
 * @param {Boolean} param0.isPrivate - Can any user see the post
 * @param {Boolean} param0.isHiddenFromStudents - Can any student see the post
 * @param {Boolean} param0.withPrefix - Show string with prefix "Shared with"
 *
 * @returns {String} - Returns the sharing status of a post
 */
export const getSharedWithString = ({
  isPrivate,
  isHiddenFromStudents,
  withPrefix = true,
  t,
}) => {
  if (!isPrivate && !isHiddenFromStudents) {
    return withPrefix
      ? t("journal:shared_with_students_families")
      : t("journal:students_n_families");
  } else if (isPrivate && !isHiddenFromStudents) {
    return withPrefix
      ? t("journal:shared_students")
      : t("journal:shared_with_sidebar_only_students");
  } else if (!isPrivate && isHiddenFromStudents) {
    return withPrefix
      ? t("journal:shared_families")
      : t("journal:only_families");
  } else if (isPrivate && isHiddenFromStudents) {
    return t("journal:post_visible_to_teachers_only");
  }
};

export const getSharedWithStringV2 = ({ isPrivate, isHiddenFromStudents }) => {
  if (!isPrivate && !isHiddenFromStudents) {
    return "everyone";
  } else if (isPrivate && !isHiddenFromStudents) {
    return "student";
  } else if (!isPrivate && isHiddenFromStudents) {
    return "family";
  } else if (isPrivate && isHiddenFromStudents) {
    return "private";
  }
};

export const isUserLiked = ({ likes, userId }) => {
  return _.find(
    _.get(likes, "edges", []),
    item => _.get(item, "node.user.id", "") == userId
  );
};

/**
 * This function checks whether a user has permission to see a journal post.
 *
 * @param {*} param0
 * @param {Object} param0.userInfo - Object has details of the logged in user
 * @param {Object} param0.postDetails - Object has journal post details
 *
 * @returns {Boolean} - Returns true if a user has correct permission to see the post
 */
export const checkPostDetailsVisibilityPermission = ({
  userInfo,
  postDetails,
}) => {
  const { isHiddenFromStudents, students } = postDetails;
  const { user_type: userType, id: userId } = userInfo;

  // Sharing permission
  if (userType == "student") {
    if (isHiddenFromStudents) {
      return false;
    }

    // else if (!isHiddenFromStudents) {
    //   // Check whether student is tagged in the post or not
    //   const taggedStudentList = _.get(students, "edges", []);
    //   const taggedStudent = _.find(
    //     taggedStudentList,
    //     student => student.node.id === userId
    //   );

    //   if (!taggedStudent) {
    //     return false;
    //   }
    // }
  }

  return true;
};

export const checkIfMultipleCamerasIsPresent = async () => {
  if ("mediaDevices" in navigator) {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const videoDevices = _.filter(
      devices,
      device => device.kind === "videoinput"
    );
    const numOfCameras = _.get(videoDevices, "length", 1);
    if (numOfCameras > 1) {
      return true;
    }
  }
  return false;
};

export const checkIfVideoMimeTypeSupported = mimeType => {
  if (!window.MediaRecorder) return false;
  if (!MediaRecorder.isTypeSupported)
    return mimeType.startsWith("audio/mp4") || mimeType.startsWith("video/mp4");
  return MediaRecorder.isTypeSupported(mimeType);
};

/**It removes practice details except practice-id from taggedPractices */
export const getTransformedTaggedPractices = ({ taggedPractices }) => {
  return _.map(taggedPractices, ({ evaluationCycle, taggedPractice }) => ({
    evaluationCycle,
    taggedPractice: _.map(taggedPractice, ({ practice, ...rest }) => ({
      ...rest,
      practice: { id: practice.id },
    })),
  }));
};

export const getPracticesV2InputForPost = ({ taggedPractices }) => {
  return _.first(
    _.map(
      taggedPractices,
      ({ evaluationCycle: { id = "" } = {}, taggedPractice }) => {
        return {
          evaluationCycleId: id,
          practices: _.map(taggedPractice, ({ practice: { id } }) => id),
        };
      }
    )
  );
};

const getPracticeWrappers = ({ taggedPractices }) => {
  const taggedPracticesForFirstEvaluationCycle = _.first(taggedPractices) ?? {};

  return taggedPracticesForFirstEvaluationCycle?.taggedPractice ?? [];
};

export const getAddedPracticesV2 = ({
  oldTaggedPractices,
  newTaggedPractices,
}) => {
  const oldPracticeWrappers = getPracticeWrappers({
    taggedPractices: oldTaggedPractices,
  });

  const newPracticeWrappers = getPracticeWrappers({
    taggedPractices: newTaggedPractices,
  });

  const addedPracticeWrappers = _.differenceWith(
    newPracticeWrappers,
    oldPracticeWrappers,
    (newPracticeWrapper, oldPracticeWrapper) => {
      const {
        practice: { id },
        evidenceStatus,
      } = newPracticeWrapper;
      const {
        practice: { id: oldId },
        evidenceStatus: oldEvidenceStatus,
      } = oldPracticeWrapper;

      return id === oldId && evidenceStatus === oldEvidenceStatus;
    }
  );

  return _.map(addedPracticeWrappers, ({ practice }) => practice);
};

export const getRemovedPracticesV2 = ({
  oldTaggedPractices,
  newTaggedPractices,
}) => {
  const oldPracticeWrappers = getPracticeWrappers({
    taggedPractices: oldTaggedPractices,
  });

  const newPracticeWrappers = getPracticeWrappers({
    taggedPractices: newTaggedPractices,
  });

  const removedPracticeWrappers = _.differenceWith(
    oldPracticeWrappers,
    newPracticeWrappers,
    (oldPracticeWrapper, newPracticeWrapper) => {
      const {
        practice: { id },
      } = oldPracticeWrapper;
      const {
        practice: { id: newId },
      } = newPracticeWrapper;
      return id === newId;
    }
  );

  return _.map(removedPracticeWrappers, ({ practice }) => practice);
};

export const isJournalCardRevamp = () => {
  return ACLStore.can("FeatureFlag:JournalRevamp");
};

export const isPostSeenByUser = ({ postId, userId }) => {
  const postDetails = getPostDetailFromFeedCache({ postId });
  return _.find(_.get(postDetails, "seen.edges", []), {
    node: { user: { id: userId } },
  });
};

export const hasTranslationPermission = () => {
  return ACLStore.can("Common:TranslateMessage");
};

export const isPostViewCountEnabled = () => {
  return ACLStore.can("FeatureFlag:EnablePostViewCount");
};

// Function to get translation filter for journal post
export const getPostTranslationFilter = ({
  userInfo,
  shouldTranslate,
  handleTranslation,
}) => {
  let localeLanguage = getSettingValue({
    name: "locale",
    organizationId: _.get(userInfo, "org_id", null),
    userId: _.get(userInfo, "id", null),
    userEntityType: _.get(userInfo, "userEntityType", null),
  });
  if (_.isEmpty(localeLanguage)) localeLanguage = "en";
  const translationFilter = {
    targetedLanguage: localeLanguage,
    shouldTranslate: shouldTranslate,
    handleTranslation: handleTranslation,
  };
  return translationFilter;
};