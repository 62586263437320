import React from "react";
import classes from "./CommunitySignUpModal.scss";
import { UIButton, UIModal, I18nHOC } from "UIComponents";
import { CancelIcon, ToddleIcon } from "SvgComponents";
import { goToLoginHomeScreen } from "modules/NavigationModule";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import * as EventTracker from "lib/eventTracker";
import { Trans } from "react-i18next";
import { colors, COMMUNITY_SIGN_UP_IMAGE } from "Constants";
import { setJoinCommunityModal } from "Community/modules/CommunityModule";

const joinButtonStyle = { minWidth: "240px", height: "56px" };

const CommunitySignUpModal = React.memo(props => {
  const redirect_url = encodeURIComponent(_.get(location, "pathname", "/"));
  const { goToLoginHomeScreen, t, target, setJoinCommunityModal } = props;

  const onClose = () => {
    setJoinCommunityModal(false);
  };

  const onJoinClick = e => {
    e.stopPropagation();
    onClose();

    EventTracker.recordEvent({
      eventName: "Clicked join for free",
      eventData: {
        target,
        source: "community",
      },
    });
    goToLoginHomeScreen({
      isOpenInNewTab: false,
      params: {
        target,
        type: "organizationSelection",
        usertype: "staff",
        redirect_url,
        action: "community",
        is_public_access: true,
      },
    });
  };
  const onClick = e => {
    e.stopPropagation();
  };

  const onSignInClick = e => {
    e.stopPropagation();
    onClose();

    EventTracker.recordEvent({
      eventName: "Clicked sign in",
      eventData: {
        target,
        source: "community",
      },
    });
    goToLoginHomeScreen({
      isOpenInNewTab: false,
      params: {
        target,
        type: "teacherLogin",
        usertype: "staff",
        redirect_url,
        is_public_access: true,
        action: "community",
      },
    });
  };

  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      onRequestClose={onClose}
    >
      <div className={classes.container} onClick={onClick}>
        <div className={classes.cancelIcon} onClick={onClose}>
          <CancelIcon width={16} heigth={16} />
        </div>
        <div className={classes.middleBody}>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <ToddleIcon />
            </div>
            <div className={classes.communityText}>
              {t("community:community")}
            </div>
          </div>
          <div className={classes.imageContainer}>
            <img src={COMMUNITY_SIGN_UP_IMAGE}></img>
          </div>
          <div className={classes.detailText}>
            {t("community:community_join_text")}
          </div>
          <div className={classes.footer}>
            <UIButton
              size={"lg"}
              color={"pink"}
              containerStyle={joinButtonStyle}
              onClick={onJoinClick}
            >
              {t("teacherHomePage:join_now_free")}
            </UIButton>
          </div>
          <div className={classes.signInLink}>
            <Trans i18nKey="community:already_have_an_account">
              Already have an account?
              <div onClick={onSignInClick} className={classes.signInLinkButton}>
                Sign in
              </div>
            </Trans>
          </div>
        </div>
      </div>
    </UIModal>
  );
});

const mapActionCreators = { goToLoginHomeScreen, setJoinCommunityModal };

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  I18nHOC({ resource: ["teacherHomePage", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(CommunitySignUpModal);
