import gql from "graphql-tag";

export const getGlobalSearchResult = gql`
  query getGlobalSearchResultQuery(
    $searchText: String
    $platformSearchEntities: [PLATFORM_SEARCH_ENTITY_ENUM!]
    $maxCountPerType: Int!
    $scope: SEARCH_SCOPE_ENUM!
    $academicYear: ID!
    $curriculum: ID!
    $metadata: JSON!
  ) {
    platform {
      platformSearch(
        input: {
          searchText: $searchText
          platformSearchEntities: $platformSearchEntities
          maxCountPerType: $maxCountPerType
          scope: $scope
          academicYear: $academicYear
          curriculum: $curriculum
          metadata: $metadata
        }
      ) {
        id
        type
        metadata
        title
        subTitle
        order
      }
    }
  }
`;
