import React from "react";
import { TickPlain } from "SvgComponents";
import classes from "./GridCard.scss";
import { colors } from "Constants";
import { UIButton } from "UIComponents";
import classNames from "classnames";

const button = {
  container: {
    maxWidth: "72px",
    minWidth: "72px",
    height: "38px",
  },
};

const GridCard = ({ item, checked, onClick, gridActionType, t }) => {
  const { label, value } = item;

  const containerClasses = classNames({
    [classes.cardContainer]: true,
    [classes.buttonType]: gridActionType === "BUTTON",
  });

  return (
    <div onClick={() => onClick(item, checked)} className={containerClasses}>
      <div className={classes.labelContainer}>
        <div className={classes.label}>{label}</div>
        <div className={classes.value}>{value}</div>
      </div>
      {gridActionType === "BUTTON" ? (
        <UIButton
          size="sm"
          color="blue"
          type="hollow"
          containerStyle={button.container}
        >
          {t("common:share")}
        </UIButton>
      ) : (
        <div
          className={classes.checkIconContainer}
          style={{
            background: checked ? colors.blue29 : "",
            borderColor: checked ? "transparent" : colors.strokeOne,
          }}
        >
          {checked && <TickPlain fill={colors.white} width={8} height={8} />}
        </div>
      )}
    </div>
  );
};

export default GridCard;
