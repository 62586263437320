import React from "react";
import classes from "./Header.scss";
import { ToddleIcon, ChevronIcon, CreateAssessmentSvg } from "SvgComponents";
import { colors, LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { I18nHOC } from "UIComponents";
import ScreenBaseComponent from "AppComponents/ScreenBaseComponent";
import LEPreview from "AppComponents/LEPreview";
class Header extends ScreenBaseComponent {
  constructor(props) {
    super(props);
  }

  myRef = React.createRef();

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (!this.myRef?.current?.contains(e.target)) {
      this.closeRightPane();
    }
  };

  onLEClick = () => {
    this.handleRightPane({ rightPane: "LE_PREVIEW" });
  };

  getRightPaneComponent = () => {
    const { assessmentId } = this.props;
    const { currentRightPane } = this.state;
    switch (currentRightPane) {
      case "LE_PREVIEW":
        return (
          <LEPreview
            assessmentId={assessmentId}
            portalType={"PLANNER"}
            parentType={"JOURNAL"}
            showAttachmentItemV3={true}
            showBackButton={true}
            onClickBackButton={this.closeRightPane}
          />
        );
    }
  };

  render() {
    const {
      title,
      onBackClick,
      showTitle,
      rightContainerContent,
      LETitle,
      assessmentId,
      assessmentType,
    } = this.props;

    const LEAssessmentType = LE_ASSESSMENT_TYPE_MAPPING[assessmentType];
    const Icon = _.get(LEAssessmentType, "filledIcon") || CreateAssessmentSvg;
    const iconColor = _.get(LEAssessmentType, "filledIconColor", colors.blue29);

    return (
      <div
        className={classes.container}
        onClick={this.handleClickOutside}
        ref={this.myRef}
      >
        <div className={classes.leftContainer}>
          <div className={classes.logoContainer} onClick={onBackClick}>
            <div className={classes.chevronIconContainer}>
              <ChevronIcon fill={colors.gray48} />
            </div>
            <ToddleIcon />
            {showTitle && <div className={classes.titleText}>{title}</div>}
          </div>
          {assessmentId && (
            <div className={classes.leContainer} onClick={this.onLEClick}>
              <div className={classes.leIconContainer}>
                <Icon
                  width={20}
                  height={20}
                  fill={iconColor}
                  fill1={colors.white}
                />
              </div>
              <div className={classes.assessmentTitle}>{LETitle}</div>
            </div>
          )}
        </div>
        {rightContainerContent && (
          <div className={classes.rightContainer}>{rightContainerContent}</div>
        )}
        {this.renderRightPane()}
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(Header);
