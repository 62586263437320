import gql from "graphql-tag";

export const getResourceListQuery = gql`
  query getResourceList(
    $filters: ResourceBankFiltersInput!
    $stateInformation: String
  ) {
    platform {
      resourceList: resourceBankSearchResult(
        filters: $filters
        stateInformation: $stateInformation
      ) {
        resources: resourceBankResultData {
          mediaType
          thumbURL
          title
          url
          source
          downloadUrl
          author {
            userName
            firstName
            lastName
            imageUrl
          }
          extra {
            misc
          }
        }
        stateInformation
        filters {
          searchText
          count
          facets
          selected
        }
        isMore
      }
    }
  }
`;
