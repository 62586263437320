import React from "react";
import classes from "./ActionsContainer.scss";
import { EyeOutlined } from "@toddle-design/web-icons";
import { Tick, CheckTickOff } from "SvgComponents";
import { DeleteOutlined } from "@toddle-design/web-icons";
import { LinkWithTooltip, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import classNames from "classnames";

const ActionIconsContainer = props => {
  const {
    styles,
    isSelected,
    showBlackOverlay,
    t,
    onCardClick,
    data,
    onClickView,
    children,
    showDeleteOption,
    onClickDelete,
  } = props;

  const handleSelect = () => {
    if (onCardClick) onCardClick({ id: data.id, type: data.itemType });
  };

  const handleView = e => {
    e.stopPropagation();
    if (onClickView) onClickView({ data, evidenceType: data.itemType });
  };

  const handleDelete = e => {
    e.stopPropagation();
    if (onClickDelete) onClickDelete({ data, isSelected });
  };

  const eyeIconClassList = classNames(classes.icon, {
    [classes.iconWithOverlay]: showBlackOverlay,
  });

  const unSelectIconClassList = classNames(classes.icon, {
    [classes.iconWithOverlay]: showBlackOverlay,
  });

  return (
    <div className={classes.container}>
      {children}
      {showBlackOverlay && (
        <div
          className={classes.overlayContainer}
          style={styles.overlayContainerStyle}
          onClick={handleSelect}
        />
      )}

      <div
        className={classes.iconsContainer}
        onClick={handleSelect}
        style={styles.iconsContainerStyle || {}}
      >
        <div className={classes.hoverOnlyIconsContainer}>
          <div className={eyeIconClassList} onClick={handleView}>
            <LinkWithTooltip
              tooltip={t("common:view")}
              linkStyles={{ display: "flex" }}
            >
              <EyeOutlined
                size={"xxx-small"}
                variant={showBlackOverlay ? "on" : "subtle"}
              />
            </LinkWithTooltip>
          </div>
          {showDeleteOption && (
            <div className={classes.icon} onClick={handleDelete}>
              <LinkWithTooltip tooltip={t("common:remove")}>
                <DeleteOutlined
                  variant={showBlackOverlay ? "on" : "subtle"}
                  size={"xxx-small"}
                />
              </LinkWithTooltip>
            </div>
          )}
        </div>

        <div className={isSelected ? classes.icon : unSelectIconClassList}>
          {isSelected ? (
            <Tick width={16} height={16} fill={colors.blue29} />
          ) : (
            <CheckTickOff width={16} height={16} />
          )}
        </div>
      </div>
    </div>
  );
};

ActionIconsContainer.defaultProps = {
  showBlackOverlay: false,
  showDeleteOption: false,
};

export default I18nHOC({ resource: ["common"] })(ActionIconsContainer);
