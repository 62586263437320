import { withAsyncRouteLoading } from "UIComponents";
import CurriculumType from "./routes/CurriculumType";

export default store => {
  return {
    path: "product-roadmap",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./ProductRoadmap").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-course"
          );
        })
    ),
    childRoutes: [CurriculumType(store)],
  };
};
