/**--external-- */
import React from "react";
import classNames from "classnames";
import { Button, IconButton, Dropdown, DropdownMenu } from "@toddle-design/web";
import update from "immutability-helper";
import {
  AddOutlined,
  DotsHorizontalOutlined,
  DeleteOutlined,
} from "@toddle-design/web-icons";

/**--internal-- */
import {
  UIBaseComponent,
  I18nHOC,
  UIModal,
  DragAndDropList,
} from "UIComponents";
import Levels from "MultiLevelNodeEditor/routes/Levels/components";
import { generateRandomId, mergeDeep, getTopParent } from "Utils";
import { plannerElementsUnitPlanPropsMemoize } from "NodeEditor/Utils";
import { getElementValue } from "modules/Services";
import FieldComponent from "../FieldComponent";

/**--relative-- */
import classes from "./MultiWindowTable.scss";
import {
  nodeEditorConfigProp,
  getDropdownOptions,
} from "./MultiWindowTableUtils";
import { overlayStyle, containerStyle } from "./MultiWindowTableStyles";

class MultiWindowTable extends UIBaseComponent {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      fieldKey: null,
      tableId: null,
      tableRowId: null,
    };

    if (_.isEmpty(value)) {
      const table = this.generateEmptyTable();

      this.updateInputFieldLocally({ value: table });
    }
  }

  updateInputFieldLocally = ({ value }) => {
    this.updateValue(value);
  };

  getSubFieldsGroupedByWindowGroupId = () => {
    const { subFields } = this.props;
    return _.groupBy(subFields, ({ windowGroupId }) => windowGroupId);
  };

  generateEmptyTable = () => {
    const tableId = generateRandomId();

    const tableGroupValues = {
      [tableId]: {
        tableRowsValues: [],
      },
    };

    return tableGroupValues;
  };

  checkIfTableRowIsEmpty = ({ tableRow }) => {
    const { subFieldValues } = tableRow;

    return _.every(subFieldValues, ({ values, id }) => {
      const availableValues = this.getAvailableValues({ fieldKey: id, values });

      return _.isEmpty(availableValues);
    });
  };
  addTableRow = ({ windowGroupId, tableId }) => {
    const tableRowId = generateRandomId();

    const { value: tableGroupValues } = this.props;

    const subFields = this.getSubFieldsGroupedByWindowGroupId()[windowGroupId];

    const subFieldInitialValues = _.map(subFields, ({ key }) => ({
      id: key,
      values: [],
    }));

    const updatedTableGroupValues = update(tableGroupValues, {
      [tableId]: {
        tableRowsValues: {
          $push: [
            {
              id: tableRowId,
              subFieldValues: subFieldInitialValues,
              windowGroupId,
            },
          ],
        },
      },
    });

    this.updateInputFieldLocally({ value: updatedTableGroupValues });

    return tableRowId;
  };

  getAvailableValues = ({ fieldKey, values }) => {
    const subFieldConfig = this.getSubFieldConfig({ fieldKey });

    const { getOptions, subjectIds } = this.props;

    const configOptions = _.get(subFieldConfig, "config.options", []);

    const updatedOptions = getOptions({
      fieldKey,
      options: configOptions,
    });

    const resolvedMinimalTree = updatedOptions.resolvedMinimalTree ?? {};

    const updatedValues = getElementValue({
      valueKeys: values,
      fieldObj: subFieldConfig,
      resolvedValue: resolvedMinimalTree,
      subjectIds,
    });

    return updatedValues;
  };

  getSubFieldConfig = ({ fieldKey }) => {
    const { subFields } = this.props;

    return _.find(subFields, ({ key }) => key === fieldKey);
  };

  getTableRowIndex = ({ tableGroupValues, tableId, tableRowId }) => {
    const tableRows = _.get(tableGroupValues, `${tableId}.tableRowsValues`, []);

    return _.findIndex(tableRows, ({ id }) => id === tableRowId);
  };

  getFieldValue = ({ tableGroupValues, tableId, tableRowId, fieldKey }) => {
    const tableRowsValues = _.get(
      tableGroupValues,
      `${tableId}.tableRowsValues`,
      []
    );

    const currentTableRow = _.find(tableRowsValues, ({ id }) => {
      return tableRowId === id;
    });

    const subField = _.find(
      _.get(currentTableRow, "subFieldValues", []),
      ({ id }) => id === fieldKey
    );

    return _.get(subField, "values", []);
  };

  updateFieldModalVisibility = ({ key, tableRowId, tableId }) => {
    this.setState({ fieldKey: key, tableId, tableRowId });
  };

  getTableRowsValuesByWindowGroup = ({ tableRowsValues }) => {
    return _.groupBy(tableRowsValues, ({ windowGroupId }) => windowGroupId);
  };

  addTable = () => {
    const table = this.generateEmptyTable();

    const { value: tableGroupValues } = this.props;

    const updatedTableGroupValues = { ...tableGroupValues, ...table };

    this.updateInputFieldLocally({ value: updatedTableGroupValues });
  };

  deleteTable = ({ tableId }) => {
    const { value: tableGroupValues } = this.props;

    const updatedTableGroupValues = { ..._.omit(tableGroupValues, [tableId]) };

    this.updateInputFieldLocally({ value: updatedTableGroupValues });
  };

  addAndOpenFieldInstance = ({ windowGroupId, tableId, key }) => {
    const tableRowId = this.addTableRow({ windowGroupId, tableId });

    this.updateFieldModalVisibility({
      key,
      tableId,
      tableRowId,
    });
  };

  updateFieldInstance = ({ fieldKey, tableRowId, tableId, fieldValues }) => {
    const { value: tableGroupValues } = this.props;

    const tableRowIndex = this.getTableRowIndex({
      tableGroupValues,
      tableId,
      tableRowId,
    });

    const updatedValues = update(tableGroupValues, {
      [tableId]: {
        tableRowsValues: {
          [tableRowIndex]: {
            subFieldValues: {
              $set: _.map(
                _.get(
                  tableGroupValues,
                  `${tableId}.tableRowsValues[${tableRowIndex}].subFieldValues`,
                  []
                ),
                ({ id, values }) => {
                  if (id === fieldKey) {
                    return { id, values: fieldValues };
                  }
                  return { id, values };
                }
              ),
            },
          },
        },
      },
    });

    this.updateInputFieldLocally({ value: updatedValues });
  };

  deleteTableRow = ({ tableRowId, tableId }) => {
    const { value: tableGroupValues } = this.props;

    const updatedValues = update(tableGroupValues, {
      [tableId]: {
        tableRowsValues: {
          $set: _.filter(
            _.get(tableGroupValues, `${tableId}.tableRowsValues`, []),
            ({ id }) => id !== tableRowId
          ),
        },
      },
    });

    this.updateInputFieldLocally({ value: updatedValues });
  };

  deleteFieldInstance = ({ tableRowId, tableId, fieldKey }) => {
    const { value: tableGroupValues } = this.props;

    const tableRowIndex = this.getTableRowIndex({
      tableGroupValues,
      tableId,
      tableRowId,
    });

    const { subFieldValues } = _.get(
      tableGroupValues,
      `${tableId}.tableRowsValues.[${tableRowIndex}]`,
      {}
    );

    const otherSubFieldValues = _.filter(
      subFieldValues,
      ({ id }) => id !== fieldKey
    );

    const areOtherSubFieldValuesEmpty = _.every(
      otherSubFieldValues,
      ({ values, id }) => {
        const availableValues = this.getAvailableValues({
          values,
          fieldKey: id,
        });

        return _.isEmpty(availableValues);
      }
    );

    if (areOtherSubFieldValuesEmpty) {
      this.deleteTableRow({ tableRowId, tableId });
    } else {
      const updatedValues = update(tableGroupValues, {
        [tableId]: {
          tableRowsValues: {
            [tableRowIndex]: {
              subFieldValues: {
                $set: _.map(subFieldValues, ({ id, values }) => {
                  if (id !== fieldKey) {
                    return { id, values };
                  }
                  return { id, values: [] };
                }),
              },
            },
          },
        },
      });

      this.updateInputFieldLocally({ value: updatedValues });
    }
  };

  closeModal = () => {
    const { tableRowId, tableId } = this.state;

    const { value: tableGroupValues } = this.props;

    const tableRow = _.find(
      _.get(tableGroupValues, `${tableId}.tableRowsValues`, []),
      ({ id }) => id == tableRowId
    );

    const isTableRowEmpty = this.checkIfTableRowIsEmpty({ tableRow });

    if (isTableRowEmpty) {
      this.deleteTableRow({ tableRowId, tableId });
    }

    this.updateFieldModalVisibility({
      tableRowId: null,
      fieldKey: null,
      tableId: null,
    });
  };

  getNodeEditorConfigurationProps = ({
    values,
    plannerElementSetData,
    parentNode,
    resolvedMinimalTree,
  }) => {
    const { curriculumType } = this.props;

    const { nodes } = resolvedMinimalTree;
    let filteredValue = values;

    let filters = {};

    let filteredNodes = [];

    if (!_.isNull(plannerElementSetData.groupedByType)) {
      //Handled for Objectives in which they are groupedBy subject groups
      if (plannerElementSetData.type != plannerElementSetData.groupedByType) {
        filteredNodes = _.filter(
          nodes,
          nodeItem =>
            !!_.find(nodeItem.associatedParents, { id: parentNode.id })
        );

        filteredValue = _.filter(
          values,
          val => !!_.find(filteredNodes, { id: val })
        );
        filters = {
          associatedParents: [
            {
              ids: [parentNode.id],
              type: plannerElementSetData.groupedByType,
            },
          ],
        };
        if (parentNode.selectedObjectiveIds) {
          filters = {
            ...filters,
            parentNodes: parentNode.selectedObjectiveIds,
          };
        }
      }

      //Handled for ATLS in which they are groupedBy ATL root nodes
      else if (!parentNode.isDummy) {
        filteredValue = _.filter(values, val => {
          const topParent = getTopParent({ nodeId: val, nodes });
          return topParent == parentNode.id;
        });

        filters = {
          parentNodes: [parentNode.id],
        };
      }
    }

    //Handled for Interdisciplinary Criteria in which they are groupedBy ATL root nodes
    if (plannerElementSetData.subType) {
      filters = { ...filters, subTypes: [plannerElementSetData.subType] };
    }

    //Merge filter with prop filters
    if (!_.isNil(plannerElementSetData.filters)) {
      filters = mergeDeep(plannerElementSetData.filters, filters);
    }

    const rootNode = {
      ...parentNode,
      subject: parentNode.isDummy ? null : parentNode.id,
    };

    const newNodeEditorProps = plannerElementsUnitPlanPropsMemoize({
      filters,
      nodes: _.filter(nodes, node => _.includes(filteredValue, node.id)),
      parentNode,
      curriculumType,
      plannerElementType: plannerElementSetData.type,
    });

    return {
      rootNode,
      newNodeEditorProps,
      filteredValue,
      filters,
    };
  };

  onDropdownClick = ({ action, key, tableId, tableRowId }) => {
    switch (action) {
      case "EDIT": {
        this.updateFieldModalVisibility({
          key,
          tableId,
          tableRowId,
        });
        break;
      }
      case "DELETE": {
        this.deleteFieldInstance({ tableId, tableRowId, fieldKey: key });
        break;
      }
    }
  };

  renderHeaderElement = ({ fieldKey, label }) => {
    const { t } = this.props;

    const fieldConfig = this.getSubFieldConfig({ fieldKey });

    const headerClassName = _.get(fieldConfig, "config.headerClassName", "");

    const headerLabelClasses = classNames(classes.headerLabel, "heading-4");

    const headerClasses = classNames(
      classes.headerBaseContainer,
      classes[headerClassName]
    );

    return (
      <div className={headerClasses}>
        <h4 className={headerLabelClasses}>{label}</h4>
        <Button variant="progressive" onClick={this.closeModal}>
          {t("common:done")}
        </Button>
      </div>
    );
  };

  renderInquiryQuestionsEditMode = ({
    label,
    fieldKey,
    values,
    tableId,
    tableRowId,
  }) => {
    const updateInquiryQuestions = ({ inquiryQuestions }) => {
      this.updateFieldInstance({
        fieldKey,
        tableRowId,
        tableId,
        fieldValues: inquiryQuestions,
      });
    };

    return (
      <div className={classes.inquiryQuestionsContainer}>
        {this.renderHeaderElement({ label, fieldKey })}
        <div className={classes.dragAndDropListContainer}>
          <DragAndDropList
            mode="edit"
            value={values}
            name={fieldKey}
            disabled={false}
            updateInputField={updateInquiryQuestions}
          />
        </div>
      </div>
    );
  };

  renderLevelsEditMode = ({
    label,
    fieldKey,
    values,
    tableId,
    tableRowId,
    fieldTypeConfig,
    emptyMessage,
    defaultOptions,
    resolvedMinimalTree,
  }) => {
    const { t } = this.props;

    const updateLearningStandards = values => {
      this.updateFieldInstance({
        fieldValues: values,
        fieldKey,
        tableRowId,
        tableId,
      });
    };

    const emptyTextAddSubgroupLocale = _.get(
      fieldTypeConfig,
      "emptyTextAddSubgroupLocale",
      ""
    );

    const { parentNodes, plannerElementSetData } = defaultOptions;

    const parentNode = _.first(parentNodes);

    const resolvedMinimalTreeNodes = resolvedMinimalTree?.nodes ?? [];

    const {
      rootNode,
      newNodeEditorProps,
      filteredValue,
      filters,
    } = this.getNodeEditorConfigurationProps({
      values,
      parentNode,
      plannerElementSetData,
      resolvedMinimalTree,
    });

    const fieldEmptyTextContainerClasses = classNames(
      "text-subtitle-1",
      classes.fieldEmptyTextContainer
    );

    return (
      <div className={classes.levelsWrapper}>
        {this.renderHeaderElement({ label, fieldKey })}
        <div className={classes.levelsContainer}>
          {_.isEmpty(resolvedMinimalTreeNodes) ? (
            <div className={fieldEmptyTextContainerClasses}>{emptyMessage}</div>
          ) : (
            <Levels
              emptyText={t(emptyTextAddSubgroupLocale, {
                label: parentNode.label,
              })}
              rootNode={rootNode}
              {...newNodeEditorProps}
              value={filteredValue}
              nodeType={plannerElementSetData.type}
              nodeParentType={plannerElementSetData.parentType}
              plannerElementSetConfig={{ ...plannerElementSetData, filters }}
              updateValueLocally={updateLearningStandards}
              isTagging
              hideHeader
              mode="edit"
              onBackClick={this.closeModal}
              showSearchHeader={false}
              nodeEditorConfigProp={nodeEditorConfigProp}
              useResolvedMinimalTreeNodes
              resolvedMinimalTreeNodes={resolvedMinimalTreeNodes}
            />
          )}
        </div>
      </div>
    );
  };

  renderFieldEditMode = ({ tableRowId, fieldKey, tableId, subField }) => {
    const { label, fieldTypeConfig, options: configOptions } = _.get(
      subField,
      "config",
      {}
    );

    const { type } = subField;

    const { value: tableGroupValues, t, getOptions } = this.props;

    const values = this.getFieldValue({
      tableGroupValues,
      tableId,
      tableRowId,
      fieldKey,
    });

    switch (type) {
      case "InputNumberList": {
        return this.renderInquiryQuestionsEditMode({
          label,
          fieldKey,
          values,
          tableId,
          tableRowId,
        });
      }

      case "NestedSelectComponent": {
        const updatedOptions = getOptions({
          options: configOptions,
          fieldKey,
        });

        return this.renderLevelsEditMode({
          label,
          fieldKey,
          values,
          tableId,
          tableRowId,
          fieldTypeConfig,
          defaultOptions: updatedOptions,
          resolvedMinimalTree: updatedOptions.resolvedMinimalTree,
          emptyMessage: t(`unitPlan:no_unit_plan_element_selected_with_label`, {
            label,
          }),
        });
      }
    }
  };

  renderModal = () => {
    const { fieldKey, tableRowId, tableId } = this.state;

    const subField = this.getSubFieldConfig({ fieldKey });

    const modalStyles = _.get(subField, "config.styles.modalStyles", {});

    return (
      <UIModal
        isOpen
        customStyle={{ content: modalStyles, overlay: overlayStyle }}
      >
        {this.renderFieldEditMode({
          tableRowId,
          fieldKey,
          tableId,
          subField,
        })}
      </UIModal>
    );
  };

  renderFieldViewMode = ({
    fieldConfig,
    values,
    resolvedMinimalTree,
    defaultOptions,
    tableId,
    tableRowId,
  }) => {
    const { t } = this.props;

    const config = _.get(fieldConfig, "config", {});

    const editStyle = _.get(config, "styles.editStyle", {});

    const fieldKey = _.get(fieldConfig, "key", "");

    const fieldTypeConfig = _.get(config, "fieldTypeConfig", {});

    const nodes = _.get(resolvedMinimalTree, "nodes", []);

    const availableValues = this.getAvailableValues({ fieldKey, values });

    const onAddButtonClick = () => {
      this.onDropdownClick({
        action: "EDIT",
        key: fieldKey,
        tableId,
        tableRowId,
      });
    };

    const onDropdownClick = e => {
      e.domEvent.stopPropagation();
      const action = e.key;
      this.onDropdownClick({
        action,
        key: fieldKey,
        tableId,
        tableRowId,
      });
    };

    return (
      <div className={classes.fieldContainer} style={editStyle}>
        {_.isEmpty(availableValues) ? (
          <Button
            variant="outlined-subtle"
            icon={<AddOutlined />}
            onClick={onAddButtonClick}
          >
            {t("common:add")}
          </Button>
        ) : (
          <React.Fragment>
            <FieldComponent
              {..._.omit(config, ["label"])}
              containerStyle={containerStyle}
              fieldTypeConfig={fieldTypeConfig}
              value={availableValues}
              type={fieldConfig.viewType}
              defaultOptions={defaultOptions}
              mode="view"
              nodes={nodes}
            />
            <Dropdown
              getPopupContainer={el => {
                return el;
              }}
              overlay={
                <DropdownMenu
                  onClick={onDropdownClick}
                  options={getDropdownOptions({ t })}
                />
              }
              placement={"bottomRight"}
            >
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                }}
                icon={
                  <DotsHorizontalOutlined
                    variant={"subtle"}
                    size={"xx-small"}
                  />
                }
                variant="plain"
              />
            </Dropdown>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderTable = ({ tableId, tableRowsValues, isDeleteTableDisabled }) => {
    const { t, getOptions } = this.props;

    const cellHeaderClasses = classNames(classes.cellHeader, "text-label-3");

    const tableRowsValuesByWindowGroup = this.getTableRowsValuesByWindowGroup({
      tableRowsValues,
    });

    const subFieldsGroupedByWindowGroup = this.getSubFieldsGroupedByWindowGroupId();

    const windowGroupIds = _.keys(subFieldsGroupedByWindowGroup);

    return (
      <div className={classes.tableWrapper}>
        <div className={classes.container}>
          {_.map(windowGroupIds, windowGroupId => {
            const tableRowsValues = tableRowsValuesByWindowGroup[windowGroupId];

            const subFields = subFieldsGroupedByWindowGroup[windowGroupId];

            return (
              <div
                className={classes.tableRow}
                key={`${windowGroupId}-${tableId}`}
              >
                <div className={classes.tableRowHeader}>
                  {_.map(subFields, subField => {
                    const {
                      label,
                      styles: { editStyle },
                    } = _.get(subField, "config", {});
                    return (
                      <div
                        className={cellHeaderClasses}
                        style={editStyle}
                        key={subField.key}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div>
                {_.map(
                  tableRowsValues,
                  ({ id: tableRowId, subFieldValues }) => {
                    return (
                      <div key={tableRowId} className={classes.tableRowContent}>
                        {_.map(subFieldValues, ({ id, values }) => {
                          const fieldConfig = this.getSubFieldConfig({
                            fieldKey: id,
                          });

                          const configOptions = _.get(
                            fieldConfig,
                            "config.options",
                            []
                          );

                          const updatedOptions = getOptions({
                            fieldKey: id,
                            options: configOptions,
                          });

                          return (
                            <React.Fragment key={id}>
                              {this.renderFieldViewMode({
                                fieldConfig,
                                values,
                                resolvedMinimalTree:
                                  updatedOptions.resolvedMinimalTree,
                                defaultOptions: updatedOptions,
                                tableRowId,
                                tableId,
                              })}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  }
                )}
                <div className={classes.addFieldSection}>
                  {_.map(subFields, ({ key, config }) => {
                    const {
                      styles: { editStyle },
                    } = config;

                    return (
                      <div
                        className={classes.addButtonContainer}
                        key={`${key}-footer`}
                        style={editStyle}
                      >
                        <Button
                          variant="outlined-subtle"
                          icon={<AddOutlined />}
                          onClick={() =>
                            this.addAndOpenFieldInstance({
                              windowGroupId,
                              tableId,
                              key,
                            })
                          }
                        >
                          {t("common:add")}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <IconButton
          icon={<DeleteOutlined />}
          variant="outlined-subtle"
          size="medium"
          disabled={isDeleteTableDisabled}
          onClick={() => this.deleteTable({ tableId })}
        />
      </div>
    );
  };

  renderEdit = () => {
    const { tableId } = this.state;

    const { value: tableGroupValues, t } = this.props;

    const tableIds = _.keys(tableGroupValues);

    const totalTables = _.size(tableIds);

    const isDeleteTableDisabled = totalTables === 1;

    return (
      <div className={classes.tableGroupsContainer}>
        {_.map(tableIds, tableId => {
          const { tableRowsValues } = tableGroupValues[tableId];

          return (
            <React.Fragment key={tableId}>
              {this.renderTable({
                tableId,
                tableRowsValues,
                isDeleteTableDisabled,
              })}
            </React.Fragment>
          );
        })}
        <div>
          <Button
            size="large"
            variant="outlined-subtle"
            onClick={this.addTable}
            icon={<AddOutlined />}
          >
            {t("common:add_another_table")}
          </Button>
        </div>
        {tableId && this.renderModal()}
      </div>
    );
  };
}

export default I18nHOC({ resource: ["common", "unitPlan"] })(MultiWindowTable);
