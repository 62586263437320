import { fontStyle, colors } from "Constants";

export const toddleMenuDropdownStyles = ({ isAcademicYearEndInSameYear }) => {
  return {
    containerStyle: {
      position: "absolute",
      top: "calc(100% + 8px)",
      left: "0px",
      minWidth: 184,
      zIndex: 13,
    },
    buttonParentStyle: {
      position: "relative",
      height: "100%",
    },
    buttonComponentStyle: { height: "100%" },
    subMenuContainerStyle: {
      width: "max-content",
    },
  };
};

export const menuItemsStyle = {
  toddleCommunityCustomStyle: {
    title: {
      // color: colors.steel,
      fontSize: "1.6rem",
      ...fontStyle.demiBold,
    },
  },
  adminPortalCustomStyle: {
    orgName: {
      ...fontStyle.demiBold,
      color: colors.gray48,
    },
  },

  switchToAdminStyle: {
    borderTop: `1px solid ${colors.gray92}`,
    paddingTop: "4px",
  },
};

export const optionSvgStyle = {
  width: "32px",
  display: "flex",
  justifyContent: "center",
};

export const toddleCommunityLabelCustomStyle = {
  title: {
    // color: colors.gray48,
    fontSize: "1.6rem",
    ...fontStyle.demiBold,
  },
};

export const academicSwitcherStyles = {
  height: "68px",
  borderBottom: `1px solid ${colors.strokeOne}`,
  borderTop: `1px solid ${colors.strokeOne}`,
  padding: "0px",
  backgroundColor: colors.white,
};
