import React from "react";
import PropTypes from "prop-types";

const PAndHEduSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <path
        d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
        fill="white"
        stroke="#2DADBA"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5874 3.02173L16.4107 28.9784"
        stroke="#2DADBA"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.02124 16.412L28.9779 15.5886"
        stroke="#2DADBA"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4931 25.8365C23.2365 22.6082 20.0948 20.3115 16.4115 20.3115C12.5332 20.3115 9.26149 22.8465 8.15649 26.3565"
        stroke="#2DADBA"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50586 6.16331C8.76253 9.39164 11.9042 11.6883 15.5875 11.6883C19.4658 11.6883 22.7375 9.15331 23.8425 5.64331"
        stroke="#2DADBA"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

PAndHEduSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

PAndHEduSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default PAndHEduSGIcon;
