import React, { useState } from "react";

// Design System
import { SideNavigation, EmptyState, Tooltip } from "@toddle-design/web";
import { colors, LEIllustraton } from "@toddle-design/theme";

// External
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import classNames from "classnames";

// GraphQL
import { getOrganizationResourcesFeedQuery } from "OrganizationResources/modules/OrganizationResourcesQuery";
import { getOrganizationResourcesFromCache } from "OrganizationResources/modules/OrganizationResourcesGraphqlHelpers";

// Internal
import { LEPreviewV2, LeFeed } from "AppComponents";
import { I18nHOC, withLoader } from "UIComponents";
import { generateRandomId, getRethumbUrl } from "Utils";
import { getDefaultUnitPlanImage } from "UnitPlans/modules/Utils";

// Scss
import classes from "./LESelectWrapper.scss";

const styles = {
  leFeedStyles: {
    imageContainerStyle: {
      width: "80px",
      height: "80px",
      flexShrink: 0,
      borderRadius: "8px",
    },
    customSelectedContainerStyle: {
      background: "none !important",
      border: `1px solid ${colors.violet63}`,
      borderRadius: "8px",
    },
    customNonSelectedContainerStyle: {
      borderRadius: "8px",
    },
    feedContainerStyle: {
      padding: "0 40px 24px",
      overflowY: "auto",
    },
    currentlySelectedstyle: {
      border: `1px solid ${colors.violet63}`,
      borderRadius: "8px",
    },
    feedItemTitleStyle: {
      fontSize: "1.6rem",
    },
    staffProfileImageStyle: {
      width: "20px",
      height: "20px",
    },
    staffNameStyle: {
      fontSize: "1.2rem",
    },
    feedInnerContainerStyle: {
      maxWidth: "680px",
    },
    headerTabContainerStyle: {
      maxWidth: "680px",
    },
    customSearchBarStyle: {
      minWidth: "284px",
      maxWidth: "480px",
    },
  },
  sideNavigation: {
    overflowY: "hidden",
  },
};

const getStepPortalType = currentStep => {
  switch (currentStep) {
    case "COMMUNITY_LE_SELECTION":
      return "COMMUNITY";
    case "LE_LIBRARY_LE_SELECTION":
      return "PLANNER";
    default:
      return "";
  }
};

const OMIT_STYLES = ["feedInnerContainerStyle", "headerTabContainerStyle"];

const SideNavigationWithLoader = withLoader(SideNavigation);

const getRethumb = imageUrl => {
  const rethumbUrl = imageUrl
    ? getRethumbUrl({
        width: 40,
        height: 40,
        imageUrl: imageUrl,
        fitIn: false,
      })
    : "";
  return `url(${rethumbUrl})`;
};

const LeftPane = props => {
  const {
    t,
    isData,
    isLoading,
    unitFlowSections,
    selectedItems,
    selectedOption,
    setSelectedOption,
    setSelectedFolder,
  } = props;
  const {
    unitPlanId,
    unitPlanTitle,
    unitPlanSubTitle,
    unitPlanImage,
  } = selectedItems;

  const divider = !_.isEmpty(unitFlowSections)
    ? [
        {
          key: "divider",
          isDivider: true,
        },
      ]
    : [];

  const allOption = [
    {
      key: "",
      title: t("common:all"),
    },
    ...divider,
  ];

  const options = _.map(unitFlowSections, section => {
    return {
      key: _.get(section, "id", ""),
      title: _.get(section, "label", ""),
    };
  });

  const sideNavigationOptions = [
    {
      key: generateRandomId(),
      options: [
        {
          key: generateRandomId(),
          options: [...allOption, ...options],
        },
      ],
    },
  ];

  const onClickSideNavigation = option => {
    setSelectedOption(option);
    setSelectedFolder("");
  };

  return (
    <div className={classes.sideNavigationContainer}>
      <div className={classes.unitPlanInfoContainer}>
        <div
          className={classes.unitPlanImageContainer}
          style={{
            backgroundImage: getDefaultUnitPlanImage({
              id: unitPlanId,
              imageUrl: unitPlanImage,
              getRethumb,
            }),
          }}
        />
        <div className={classes.unitPlanInfo}>
          <Tooltip placement={"top"} tooltip={unitPlanTitle}>
            <div className={classes.unitPlanTitle}>{unitPlanTitle}</div>
          </Tooltip>
          <Tooltip placement={"bottom"} tooltip={unitPlanSubTitle}>
            <div className={classes.unitPlanSubTitle}>{unitPlanSubTitle}</div>
          </Tooltip>
        </div>
      </div>
      <SideNavigationWithLoader
        onClick={onClickSideNavigation}
        options={sideNavigationOptions}
        selectedOptionKey={selectedOption}
        theme={"light"}
        isData={isData}
        isLoading={isLoading}
        containerStyle={styles.sideNavigation}
      />
    </div>
  );
};

const LESelectWrapper = props => {
  const {
    t,
    isData,
    isLoading,
    currentStep,
    selectedItems,
    setSelectedItems,
    courseId,
    curriculumType,
    unitFlowSections,
    isLibraryOrCommunity,
  } = props;

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");

  const stepPortalType = getStepPortalType(currentStep);
  const showLEPreview = _.get(selectedItems, "showLEPreview", true);
  const currentUnitFlowSection = !_.isEmpty(selectedFolder)
    ? selectedFolder
    : selectedOption;

  const updateSelectedLeIds = ids => {
    setSelectedItems(prev => {
      return {
        ...prev,
        selectedAssessmentIds: ids,
      };
    });
  };

  const updateAssessmentId = id => {
    setSelectedItems(prev => {
      return {
        ...prev,
        selectedAssessmentId: id,
      };
    });
  };

  const updateCurrentUnitFlowSection = id => {
    setSelectedFolder(id);
  };

  const containerClass = classNames({
    [classes.container]: true,
    [classes.LEContainer]: isLibraryOrCommunity,
    [classes.noPreviewContainer]: !showLEPreview,
  });

  const middleContainerClass = classNames({
    [classes.middleContainer]: true,
    [classes.LEMiddleContainer]: isLibraryOrCommunity,
  });

  return (
    <div className={containerClass}>
      {!isLibraryOrCommunity && (
        <div className={classes.leftContainer}>
          <LeftPane
            t={t}
            unitFlowSections={unitFlowSections}
            selectedItems={selectedItems}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setSelectedFolder={setSelectedFolder}
            isData={isData}
            isLoading={isLoading}
          />
        </div>
      )}
      <div className={middleContainerClass}>
        <LeFeed
          courseId={courseId}
          parentResourceId={selectedOption}
          unitPlanId={selectedItems.unitPlanId}
          portalType={stepPortalType}
          assessmentId={selectedItems.selectedAssessmentId}
          feedAsPosts={!isLibraryOrCommunity}
          showHeaderText={false}
          curriculumType={curriculumType}
          importFromLeLibrary={currentStep == "LE_LIBRARY_LE_SELECTION"}
          setAssessmentId={assessmentId => updateAssessmentId(assessmentId)}
          updateSelectedItems={ids => updateSelectedLeIds(ids)}
          isMultiSelect={selectedItems.isMultiSelect}
          isUnitFlow={true}
          currentUnitFlowSection={currentUnitFlowSection}
          updateCurrentUnitFlowSection={updateCurrentUnitFlowSection}
          selectedItems={selectedItems.selectedAssessmentIds}
          showCardWithStaffProfile={true}
          showCheckBox={selectedItems.isMultiSelect}
          {..._.omit(styles.leFeedStyles, showLEPreview ? OMIT_STYLES : null)}
        />
      </div>
      {showLEPreview && (
        <div className={classes.rightContainer}>
          {!_.isEmpty(selectedItems.selectedAssessmentId) ? (
            <LEPreviewV2
              assessmentId={selectedItems.selectedAssessmentId}
              portalType={stepPortalType}
              parentType={"CLASSROOM"}
            />
          ) : (
            <div className={classes.emptyStateContainer}>
              <EmptyState
                illustration={LEIllustraton}
                size="small"
                subtitle={t("common:select_to_preview")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { currentStep } = ownProps;
  const isLibraryOrCommunity = _.includes(
    ["COMMUNITY_LE_SELECTION", "LE_LIBRARY_LE_SELECTION"],
    currentStep
  );
  return {
    courseId: state.teacher.selected_class.selected_course,
    curriculumType: state.platform.currentCurriculumProgramType,
    isLibraryOrCommunity,
    isData: true,
    isLoading: false,
  };
};

export default compose(
  connect(mapStateToProps),
  I18nHOC({ resource: ["common"] }),
  graphql(getOrganizationResourcesFeedQuery, {
    name: "getOrganizationResourcesFeedQuery",
    skip: ({ isLibraryOrCommunity }) => isLibraryOrCommunity,
    options: ({ selectedItems: { unitPlanId } }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        parentId: unitPlanId,
        parentType: "UNIT_PLAN",
      },
    }),
    props({
      getOrganizationResourcesFeedQuery,
      ownProps: {
        isData,
        isLoading,
        selectedItems: { unitPlanId },
      },
    }) {
      const unitPlanFlow = getOrganizationResourcesFromCache({
        parentId: unitPlanId,
        parentType: "UNIT_PLAN",
      });
      const networkStatus = _.get(
        getOrganizationResourcesFeedQuery,
        "networkStatus"
      );

      return {
        getOrganizationResourcesFeedQuery,
        isData: isData && !_.isEmpty(unitPlanFlow),
        unitFlowSections: _.get(unitPlanFlow, "unitFlowResources", []),
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  })
)(LESelectWrapper);
