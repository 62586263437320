import React, { useState } from "react";
import { RECORDING_MODE_OPTIONS } from "../../modules/Module";
import classes from "./AttendanceCurriculumRow.scss";
import { I18nHOC, UIButton } from "UIComponents";
import { colors, fontStyle } from "Constants";
import classNames from "classnames";
import { compose } from "react-apollo";
import { PencilLineOutlined } from "@toddle-design/web-icons";
import { IconButton, SelectDropdown } from "@toddle-design/web";

const styles = {
  optionLabelStyle: {
    color: colors.gray13,
    fontFamily: fontStyle.medium.fontFamily,
  },
  selectDropdownStyle: {
    height: "40px",
    padding: "0px",
  },
};

const AttendanceCurriculumRow = ({
  id,
  containerRowStyle,
  label,
  onRoutineOptionSelect,
  attendanceRecordMode,
  t,
}) => {
  const [recordingMode, setRecodingMode] = useState(
    attendanceRecordMode[label]
  );
  const [mode, setMode] = useState("view");

  const selectButtonContainerClass = classNames(
    { [classes.selectButtonContainer]: true },
    { [classes.selectButtonContainerWithPadding]: mode === "view" }
  );

  const recordingModeSelect = option => {
    const recordingMode = option.value;
    setRecodingMode(recordingMode);
  };
  const onEditIconClick = () => {
    setMode("edit");
  };
  const onSaveClick = () => {
    setMode("view");
    onRoutineOptionSelect({
      [label]: recordingMode,
    });
  };

  const attendanceRecordingModeOptions = _.map(
    RECORDING_MODE_OPTIONS,
    option => {
      return {
        ...option,
        label: t(option.label),
      };
    }
  );

  const selectedOption = _.find(
    attendanceRecordingModeOptions,
    option => option.value === recordingMode
  );

  return (
    <div
      key={id}
      className={classes.curriculumProgramRow}
      style={containerRowStyle}
    >
      <div className={classes.labelContainer}>{label}</div>
      <div className={classes.buttonsContainer}>
        <div className={selectButtonContainerClass}>
          {mode === "view" ? (
            <div className={classes.attendanceRecordModeViewText}>
              {selectedOption.label}
            </div>
          ) : (
            <SelectDropdown
              name={"recordingMode"}
              isSearchable={false}
              value={selectedOption}
              dropdownStyle={styles.selectDropdownStyle}
              onChange={recordingModeSelect}
              options={attendanceRecordingModeOptions}
              isCheckList={false}
              showAllOption={false}
              optionLabelStyle={styles.optionLabelStyle}
              isClearable={false}
            />
          )}
        </div>
        <div className={classes.actionButtonsContainer}>
          {mode == "edit" ? (
            <UIButton
              type="filled"
              size="sm"
              color="blue"
              onClick={onSaveClick}
            >
              {t("common:save")}
            </UIButton>
          ) : (
            <IconButton
              icon={<PencilLineOutlined />}
              variant={"plain"}
              size={"small"}
              onClick={onEditIconClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  I18nHOC({ resource: ["assessmentEvaluation", "common"] })
)(AttendanceCurriculumRow);
