import { isUnavailableResourceError } from "services/sentry/errors";
import { store } from "store/createStore";
import { setIsPageNotFound } from "modules/Services";

export const globalErrorHandler = errorHandler => {
  const { graphQLErrors, networkError, operation } = errorHandler;

  graphQLErrors?.forEach(e => {
    const { app_services: { isPageNotFound } = {} } = store.getState();
    if (isUnavailableResourceError(e)) {
      if (!isPageNotFound) {
        store.dispatch(setIsPageNotFound(true));
      }
    }
  });
};
