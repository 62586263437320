import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import AdministratorRoute from "../Administrator";
import TeacherRoute from "../Teacher";
import ProductRoadmap from "./routes/ProductRoadmap";
import VisitorHome from "./routes/VisitorHome";
import SchoolOnboardSetup from "SchoolOnboardSetup";
import SymLinks from "./routes/SymLinks";
import PublicLinks from "./routes/PublicLinks";
import Profile from "./routes/Profile";
import ProfileV2 from "./routes/ProfileV2";
import OrganizationSwitcher from "./routes/OrganizationSwitcher";
import RegionsRoute from "./routes/Regions";
import { getRelativePath } from "Utils";

export default store => ({
  path: "platform",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("Platform/modules/PlatformModule").default;
            injectReducer(store, { key: "platform", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "teacher-course"
        );
      })
  ),
  onEnter: (nextState, replace) => {
    const pathname = _.get(nextState, "location.pathname", "");
    const currentRoute = _.last(pathname.split("/"));

    if (_.includes(["tools"], currentRoute)) {
      replace(getRelativePath("../regions"));
    }
  },
  childRoutes: [
    SymLinks(store),
    PublicLinks(store),
    OrganizationSwitcher(store),
    VisitorHome(store),
    ProductRoadmap(store),
    AdministratorRoute(store),
    SchoolOnboardSetup(store),
    RegionsRoute(store),
    Profile(store),
    ProfileV2(store),
    TeacherRoute(store),
  ],
});
