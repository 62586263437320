import React, { Fragment, useState } from "react";
import { UIButton, I18nHOC, UIModal } from "UIComponents";
import classes from "./SigninBlockerScreen.scss";
import { compose } from "react-apollo";
import { ToddleIcon } from "SvgComponents";
import * as EventTracker from "lib/eventTracker";
import { Trans } from "react-i18next";
import { CLOUD_URL } from "Constants";
import { isMobile } from "Utils";
import { sendSigninMagicLink } from "modules/Services";
import { connect } from "react-redux";
import LinkSentSuccessScreen from "../Community/LinkSentSuccessScreen";
import { setToastMsg } from "Login/modules/LoginModule";

const url = `${CLOUD_URL}/assets/webapp/Magic_link_generate.png`;

const SigninBlockerScreen = ({
  t,
  regenerateLink = true,
  sendSigninMagicLink,
  setToastMsg,
  firstName,
  lastName,
  email,
  userId,
}) => {
  const [linkSent, setLinkSent] = useState(false);

  const sendMagicLink = async () => {
    const pageUrl = _.get(window, "location.pathname", "");
    const isSuccess = await sendSigninMagicLink(pageUrl);
    if (isSuccess) {
      setLinkSent(true);
      EventTracker.recordEvent({
        eventName: "Send Magic Link",
        eventData: {
          entity_id: userId,
          entity_name: `${firstName} ${lastName}`,
          entity_type: "STAFF",
          source: "community",
        },
      });
    } else {
      setLinkSent(false);
    }
  };

  const onResendClick = async () => {
    const pageUrl = _.get(window, "location.pathname", "");
    const isSuccess = await sendSigninMagicLink(pageUrl);
    if (!isSuccess) return;
    setToastMsg({
      msg: "toastMsgs:link_resent",
      type: "success",
      position: "toast-bottom-center",
    });
  };

  return (
    <UIModal
      isOpen={true}
      isFullScreen={isMobile()}
      modalContent={isMobile() ? classes.styling : classes.container}
    >
      {linkSent ? (
        <LinkSentSuccessScreen email={email} onResendClick={onResendClick} />
      ) : (
        <>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <ToddleIcon />
            </div>
            <div className={classes.toddleText}>{t("common:toddle")}</div>
          </div>

          <div className={classes.imageContainer}>
            <img src={url} height="164px" />
          </div>

          <div className={classes.title}>
            {t("common:hi_with_name", { name: firstName })}
          </div>
          <div className={classes.welcomeMessage}>
            {t("common:signup_blocker_welcome_message")}
          </div>

          {regenerateLink ? (
            <Fragment>
              <div className={classes.subTitle}>
                <Trans i18nKey="common:signup_blocker_regenerate" />
              </div>
              <UIButton
                onClick={sendMagicLink}
                color="pink"
                isDisabled={linkSent}
              >
                {t("common:signup_blocker_send_link")}
              </UIButton>
            </Fragment>
          ) : (
            <div className={classes.subTitle}>
              <Trans i18nKey="common:signup_blocker_info" />
            </div>
          )}
        </>
      )}
    </UIModal>
  );
};

const mapStateToProps = state => {
  const userInfo = _.get(state, "login.userInfo");
  const firstName = _.get(userInfo, "first_name", "");
  const lastName = _.get(userInfo, "last_name", "");
  const email = _.get(userInfo, "email", "");
  const userId = _.get(userInfo, "id", "");

  return { firstName, lastName, email, userId };
};

const mapActionCreators = {
  sendSigninMagicLink,
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: ["community", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(SigninBlockerScreen);
