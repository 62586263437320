import { getStudentResponseData } from "ClassRoom/modules/utils";
/**
 * This util file can be used to keep all those helper functions which
 * restructure the data original or combine different sources.
 */

/**
 * This function takes list of evidence, categorizes it and returns in a
 * format which is suitable for AttachmentList.
 *
 * @param {*} param0
 * @param {Array} param0.evidence: List of evidence which can be of any
 *                                 type e.g. Attachment, Post
 *
 */
export const getFormattedEvidence = ({ evidence }) => {
  const formattedEvidence = [];

  _.forEach(evidence, item => {
    if (!_.has(item, "attachments")) {
      formattedEvidence.push({
        ...item,
        isPost: false,
      });
    } else if (!_.isEmpty(_.get(item, "items"))) {
      const {
        assessmentType,
        studentResponseAttachments,
      } = getStudentResponseData(_.get(item, "items", []));

      formattedEvidence.push({
        id: item.id,
        isPost: true,
        attachments: studentResponseAttachments,
        title: item.title,
        assessmentType: assessmentType,
        postEntityItems: _.get(item, "items", []),
      });
    } else {
      const itemAttachments = _.get(item, "attachments", []);
      formattedEvidence.push({
        id: item.id,
        isPost: true,
        attachments: itemAttachments,
        title: item.title,
      });
    }
  });

  return formattedEvidence;
};
