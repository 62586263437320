import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "process-journal",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            const module = require("./modules/Module");
            const reducer = module.default;
            injectReducer(store, { key: module.NAME, reducer });

            const multiLevelNodeEditorReducer = require("MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule")
              .default;
            injectReducer(store, {
              key: "multiLevelNodeEditor",
              reducer: multiLevelNodeEditorReducer,
            });

            cb(component);
          },
          err => {
            reject(err);
          },
          "project-group"
        );
      })
  ),
});
