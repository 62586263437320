import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import SubMenuCurriculumWrapper from "Administrator/commonComponents/SubMenuCurriculumWrapper";
import { getNextMenuRoute } from "Administrator/modules/AdministratorModule";
import { getRelativePath } from "Utils";

export default store => ({
  path: "classes",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const module2 = require("CourseSetup/modules/Module");
            const reducer = module2.default;
            const key = module2.NAME;
            injectReducer(store, { key, reducer });

            const CreateCourseModule = require("CourseSetup/components/CreateCourse/containers/CreateCourseModule");
            injectReducer(store, {
              key: CreateCourseModule.NAME,
              reducer: CreateCourseModule.default,
            });

            const courseDetailsModule = require("CourseSetup/components/EditCourse/containers/CourseDetailsModule");
            injectReducer(store, {
              key: courseDetailsModule.NAME,
              reducer: courseDetailsModule.default,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
  onEnter(nextState, replace) {
    if (_.last(nextState.routes).path == "classes") {
      const route = getNextMenuRoute("COURSE_SETUP");
      if (route) {
        replace(getRelativePath(route));
      } else {
        replace(getRelativePath("../"));
      }
    }
  },
  childRoutes: [SubMenuCurriculumWrapper(store)],
});
