import React, { Component } from "react";
import PropTypes from "prop-types";

const IcnNotiSubmissionReturned = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C9.05688 7 9.11265 6.99525 9.16694 6.98613C9.57638 8.15656 10.6929 9 12 9H20C21.3071 9 22.4236 8.15656 22.8331 6.98613C22.8873 6.99525 22.9431 7 23 7C23.2644 7 24.298 7.01084 25.0945 7.80373C25.6719 8.37852 26 9.03425 26 9.68326V24.3167C26 25.6852 24.7206 27 23 27H9C7.26107 27 6 25.768 6 24.3167V9.68326C6 8.3148 7.27936 7 9 7ZM23 5C22.9431 5 22.8874 5.00476 22.8331 5.01388C22.4236 3.84344 21.3071 3 20 3H12C10.6929 3 9.57638 3.84344 9.16694 5.01387C9.11265 5.00475 9.05688 5 9 5C6.32064 5 4 7.06982 4 9.68326V24.3167C4 27.0465 6.33893 29 9 29H23C25.6794 29 28 26.9302 28 24.3167V9.68326C28 8.34132 27.3281 7.20519 26.5055 6.38632C25.1124 4.99951 23.3617 4.99992 23.0077 5L23 5ZM12 5C11.4523 5 11 5.45228 11 6C11 6.54772 11.4523 7 12 7H20C20.5477 7 21 6.54772 21 6C21 5.45228 20.5477 5 20 5H12ZM15.7071 14.7071C16.0976 14.3166 16.0976 13.6834 15.7071 13.2929C15.3166 12.9024 14.6834 12.9024 14.2929 13.2929L12.2936 15.2922L12.2864 15.2995C12.1937 15.3938 12.1236 15.502 12.0759 15.6172C12.027 15.7351 12 15.8644 12 16C12 16.1356 12.027 16.2649 12.0759 16.3828C12.1236 16.498 12.1937 16.6062 12.2864 16.7005L12.2936 16.7078L14.2929 18.7071C14.6834 19.0976 15.3166 19.0976 15.7071 18.7071C16.0976 18.3166 16.0976 17.6834 15.7071 17.2929L15.4142 17H18C19.1046 17 20 17.8954 20 19C20 20.1046 19.1046 21 18 21H13C12.4477 21 12 21.4477 12 22C12 22.5523 12.4477 23 13 23H18C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15H15.4142L15.7071 14.7071Z"
        fill={props.fill}
      />
    </svg>
  );
};

IcnNotiSubmissionReturned.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

IcnNotiSubmissionReturned.defaultProps = {
  width: 32,
  height: 32,
};

export default IcnNotiSubmissionReturned;
