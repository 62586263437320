import gql from "graphql-tag";
import {
  likeConnectionfragment,
  resourceRequestFragment,
} from "./CommunityFragments";

export const updateEntityRatingMutation = gql`
  mutation createEntityRating(
    $rating: ID!
    $entity: ID!
    $entityType: RATING_ENTITY_ENUM!
  ) {
    community {
      createEntityRating(
        input: { rating: $rating, entity: $entity, entityType: $entityType }
      )
    }
  }
`;

export const addToCommunityMutation = gql`
  mutation contributeToCommunity(
    $id: ID!
    $type: SUBMIT_TO_COMMUNITY_ITEM_TYPE_ENUM!
    $grades: [ID!]
    $about: String
    $tags: [ID!]
    $collaborators: [ID!]
    $sourceOrganizationId: ID
    $targetCurriculumProgramId: ID
  ) {
    community {
      submitToCommunity(
        input: {
          id: $id
          type: $type
          grades: $grades
          tags: $tags
          about: $about
          collaborators: $collaborators
          sourceOrganizationId: $sourceOrganizationId
          targetCurriculumProgramId: $targetCurriculumProgramId
        }
      )
    }
  }
`;

export const createFollowMutation = gql`
  mutation createFollow(
    $user: ID!
    $entityId: ID!
    $entityType: FOLLOW_ENTITY_TYPE_ENUM!
    $followType: FOLLOW_TYPE_ENUM!
  ) {
    community {
      createFollow(
        input: {
          user: $user
          entityId: $entityId
          entityType: $entityType
          followType: $followType
        }
      ) {
        id
        user {
          id
        }
      }
    }
  }
`;

export const removeFollowMutation = gql`
  mutation removeFollow(
    $user: ID!
    $entityId: ID!
    $entityType: FOLLOW_ENTITY_TYPE_ENUM!
    $followType: FOLLOW_TYPE_ENUM!
  ) {
    community {
      removeFollow(
        input: {
          user: $user
          entityId: $entityId
          entityType: $entityType
          followType: $followType
        }
      )
    }
  }
`;
export const addLikeMutation = gql`
  mutation addLike($parentId: ID!, $parentType: PARENT_TYPE_ENUM!) {
    platform {
      addLike(input: { parentId: $parentId, parentType: $parentType }) {
        likeConnection {
          ...likeFeedConnectionItem
        }
      }
    }
  }
  ${likeConnectionfragment.likeFeedConnection}
`;

export const removeLikeMutation = gql`
  mutation removeLike($parentId: ID!, $parentType: PARENT_TYPE_ENUM!) {
    platform {
      removeLike(input: { parentId: $parentId, parentType: $parentType })
    }
  }
`;

export const unpublishEntityMutation = gql`
  mutation unpublishEntity(
    $entityId: ID!
    $entityType: FOLLOW_ENTITY_TYPE_ENUM!
  ) {
    community {
      unPublishEntity(input: { entityId: $entityId, entityType: $entityType })
    }
  }
`;

export const shareEntityMutation = gql`
  mutation shareEntity(
    $entityId: ID!
    $entityType: FOLLOW_ENTITY_TYPE_ENUM!
    $emails: [String!]
  ) {
    community {
      shareEntity(
        input: { entityId: $entityId, entityType: $entityType, emails: $emails }
      )
    }
  }
`;

export const handleEntityTagMutation = gql`
  mutation handleEntityTag(
    $entityId: ID!
    $entityType: FOLLOW_ENTITY_TYPE_ENUM!
    $valuesToAdd: [ID!]
    $valuesToRemove: [ID!]
  ) {
    community {
      handleEntityTag(
        input: {
          entityId: $entityId
          entityType: $entityType
          valuesToAdd: $valuesToAdd
          valuesToRemove: $valuesToRemove
        }
      ) {
        id
      }
    }
  }
`;

export const inviteUserToCommunityMutation = gql`
  mutation inviteUserToCommunity($emails: [String!]) {
    community {
      inviteToJoinCommunity(input: { emails: $emails })
    }
  }
`;

export const activationEmailerMutation = gql`
  mutation activationEmailer(
    $actionType: String!
    $campaignDay: ID!
    $metadata: ActivationEmailMeta
  ) {
    community {
      trackActivationEmail(
        actionType: $actionType
        metadata: $metadata
        campaignDay: $campaignDay
      ) {
        response {
          id
          resourceLink
        }
        warnings
      }
    }
  }
`;

export const upvoteResourceRequestMutation = gql`
  mutation upvoteResourceRequest($contentId: ID!) {
    community {
      toggleSurveyContentUpvote(input: { contentId: $contentId }) {
        ...resourceRequestItem
      }
    }
  }
  ${resourceRequestFragment.resourceRequest}
`;

export const createResourceRequestMutation = gql`
  mutation createResourceRequest(
    $resourceType: COMMUNITY_ENTITY_TYPES_ENUM!
    $unitType: UNIT_PLAN_TYPE_ENUM
    $unitTheme: String
    $resourceTopic: String!
    $gradeIds: [ID!]
    $description: String
  ) {
    community {
      createResourceRequest(
        input: {
          resourceType: $resourceType
          unitType: $unitType
          unitTheme: $unitTheme
          resourceTopic: $resourceTopic
          grades: $gradeIds
          description: $description
        }
      ) {
        id
      }
    }
  }
`;
