/**--external-- */
import React from "react";
import { AvatarGroup, Avatar } from "@toddle-design/web";

/**--relative-- */
import {
  getBuddyName,
  getCurriculumProgramsHavingBuddies,
} from "./ToddleBuddiesBasicDetailsUtils";

const ToddleBuddiesBasicDetails = props => {
  const { curriculumPrograms } = props;

  const curriculumProgramHavingBuddies = getCurriculumProgramsHavingBuddies({
    curriculumPrograms,
  });

  if (_.isEmpty(curriculumProgramHavingBuddies)) {
    return "N/A";
  }

  return (
    <AvatarGroup max={2} shape="circle" size="small">
      {_.map(curriculumProgramHavingBuddies, ({ id, type, buddy }) => {
        const buddyName = getBuddyName({ buddy });
        return <Avatar name={`${buddyName} (${type})`} key={id} />;
      })}
    </AvatarGroup>
  );
};

export default ToddleBuddiesBasicDetails;

ToddleBuddiesBasicDetails.displayName = "ToddleBuddiesBasicDetails";
