import React from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";
const SpeakerIcon = props => {
  return (
    <svg viewBox="0 0 16 16" width={props.width} height={props.height}>
      <g
        id="Group-41"
        transform="translate(0.000000, -0.000341)"
        fill={props.fill && props.fill}
      >
        <path
          d="M9.59759386,0.143691815 L9.55900236,0.166702931 L4.23739465,3.52875447 L2.74763074,3.52875447 C1.24896984,3.52875447 0.0283489393,4.77777473 0.000486688545,6.33239509 L0,6.38674851 L0,9.60942515 C0,11.1710249 1.20339491,12.4379946 2.69546609,12.466914 L2.74763074,12.4674192 L4.23746814,12.4674192 L4.27745781,12.4927301 L9.54930814,15.8294763 C9.89736124,16.049398 10.3317388,16.0571673 10.6867884,15.8498799 C11.0311302,15.648844 11.2496354,15.2753636 11.2644968,14.8651434 L11.2653062,14.819419 L11.2653062,1.17685323 C11.2650565,0.76361536 11.0577237,0.382438352 10.7217457,0.169521742 L10.6837551,0.146504717 L10.6444668,0.124933958 C10.313457,-0.0481053042 9.92194431,-0.0409680005 9.59759386,0.143691815 Z M9.65123757,2.07525343 L9.65123757,13.9351924 L5.33677937,11.1895353 L5.33677937,4.80638323 L9.65123757,2.07525343 Z M3.72271261,5.21639676 L3.72271261,10.7797769 L2.74763074,10.7797769 C2.12019327,10.7797769 1.61406675,10.2544612 1.61406675,9.60942515 L1.61406675,6.38674851 C1.61406675,5.74171248 2.12019327,5.21639676 2.74763074,5.21639676 L3.72271261,5.21639676 Z"
          id="Shape"
        ></path>
        <path
          d="M13.465497,4.6545469 C13.6195236,4.49687101 13.829395,4.408219 14.0482788,4.408219 C14.2671625,4.408219 14.477034,4.49687101 14.6310605,4.6545469 C16.4561218,6.50418721 16.4561218,9.49712323 14.6316347,11.3461788 C14.4243639,11.557114 14.1222425,11.6400744 13.8385845,11.56435 C13.554564,11.4885288 13.3318224,11.2650227 13.2545677,10.9777228 C13.1772148,10.6900575 13.2575995,10.3825662 13.4651607,10.1713349 C14.6500797,8.97182897 14.6500797,7.02948148 13.4648575,5.82966787 C13.1443771,5.50540878 13.1443771,4.97945699 13.464828,4.65522778 L13.465497,4.6545469 Z"
          id="Path"
        ></path>
      </g>
    </svg>
  );
};

SpeakerIcon.defaultProps = {
  height: 16,
  width: 16,
};
SpeakerIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
export default SpeakerIcon;
