import { colors, fontStyle } from "Constants";

const styles = {
  titleInputStyle: {
    ...fontStyle.demiBold,
    color: colors.gray13,
    fontSize: "1.8rem",
    height: "28px",
    padding: "12px 0",
    marginBottom: "4px",
    borderRadius: "0",
    borderWidth: "0 0 1px 0",
  },
};

export default styles;
