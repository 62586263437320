import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

const SubjectToGrades = store => ({
  path: "subjects",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const subjectSetupReducer = require("SubjectsSetup/modules/SubjectsModule")
              .default;
            injectReducer(store, {
              key: "subjectsSetup",
              reducer: subjectSetupReducer,
            });
            const reducer = require("SubjectGroupsAndSubjects/modules/Module")
              .default;
            injectReducer(store, {
              key: "subjectGroupsAndSubjectsSetup",
              reducer,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "school-onboard-setup"
        );
      })
  ),
  childRoutes: [],
});

export default SubjectToGrades;
