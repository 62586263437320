import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "globalContexts",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "planning-insights"
        );
      })
  ),
});
