import gql from "graphql-tag";
import { organizationGradeItem } from "GradesSetup/modules/Fragment";

export const getOrganizationGlobalGradesQuery = gql`
  query getOrganizationGlobalGrades($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        globalGrades {
          id
          uid
          name
          constants
          displaySequence
        }
      }
    }
  }
`;

export const getOrganizationGradesQuery = gql`
  query getOrganizationGrades($id: ID!, $filters: GradeFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationGradeItem}
`;
