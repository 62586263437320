import React from "react";
import classes from "./HeaderRow.scss";
import { I18nHOC } from "UIComponents";
import classNames from "classnames";

const HeaderRow = React.memo(props => {
  const { containerClass, typeDetail, mode, isStrand, isTagging, t } = props;
  const containerStyle = classNames(
    { [containerClass]: true },
    { [classes.container]: true },
    { [classes.editContainer]: mode == "edit" }
  );

  const headerLabelStyle = classNames(
    { [classes.headerLabel]: true },
    { [classes.headerLabelView]: mode == "view" }
  );

  return (
    <div
      className={containerStyle}
      style={mode == "edit" ? { borderLeftColor: typeDetail.color } : {}}
    >
      {mode == "edit" && !isStrand ? (
        <div className={classes.headerLabel}>{t("common:action_plural")}</div>
      ) : (
        <div />
      )}
      <div className={headerLabelStyle} style={{ color: typeDetail.color }}>
        {_.get(typeDetail, "label", "")}
      </div>
      <div className={classes.headerLabel}>{``}</div>
      {!isTagging && <div className={classes.headerLabel}>{``}</div>}
    </div>
  );
});

export default I18nHOC({ resource: ["common"] })(HeaderRow);
