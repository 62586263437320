export const getLabelValue = ({ item, t }) => {
  const { name, title } = item;

  return name || title || t("common:untitled");
};

export const getTagValues = (tags, t, fn) => {
  return _.map(tags, tag => {
    let tagKey = tag.key;
    let tagValue = tag.value;

    if (tagKey === "REGION") {
      tagKey = t("common:region");
      tagValue = t(`common:r_${tag.value}`);
    } else if (tag.key === "MAPLEBEAR_USER") {
      tagKey = t("common:usertype");
      tagValue = _.capitalize(tagValue);
    }

    const label = fn ? fn(tag) : `${tagKey} - ${tagValue}`;
    return {
      label,
      value: tag.id,
      disabled: _.get(tag, "disabled", false),
    };
  });
};

export const getLabelValueForMultiFilterLabelButton = ({
  options = [],
  selectedOptions = [],
  t,
  allText,
  noneText,
}) => {
  // used for FilterLabelButton component from Design Library
  if (options.length === selectedOptions.length) {
    return allText ?? t("all");
  } else if (selectedOptions.length === 0) {
    return noneText ?? t("none");
  } else {
    return selectedOptions;
  }
};
