import React from "react";
import classes from "./Tags.scss";
import { AddIcon, CancelIcon } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";
import PropTypes from "prop-types";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";

const getThemeClass = (theme = "blue") => {
  switch (theme) {
    case "blue":
      return classes.blue29;
    case "salmon":
      return classes.salmon;
    case "black":
      return classes.black;
    default:
      return classes.blue29;
  }
};

const Tag = ({
  item,
  onClick,
  isAddButton = false,
  onClickAddButton,
  showCloseButton,
  removeOption,
  selected,
  theme,
  t,
  styles: { label: labelStyles = {}, container: containerStyles = {} } = {},
}) => {
  const containerClasses = classNames(
    {
      [classes.tagContainer]: true,
    },
    {
      [classes.addButton]: !!isAddButton,
    },
    {
      [getThemeClass(theme)]: !!selected,
    }
  );

  if (isAddButton) {
    return (
      <div
        onClick={onClickAddButton}
        style={containerStyles}
        className={containerClasses}
      >
        <div className={classes.addIcon}>
          <AddIcon width={8} height={8} />
        </div>
        <div
          style={{ ...labelStyles, color: colors.blue29 }}
          className={classes.label}
        >
          {t("add")}
        </div>
      </div>
    );
  }

  return (
    <div
      style={containerStyles}
      className={containerClasses}
      onClick={() => (onClick ? onClick(item) : null)}
    >
      <div style={labelStyles} className={classes.label}>
        {item.label}
      </div>
      {showCloseButton && (
        <div
          className={classes.removeButton}
          onClick={e => removeOption(item, e)}
        >
          <CancelIcon width={8} height={8} />
        </div>
      )}
    </div>
  );
};

class Tags extends UIBaseComponent {
  removeOption = item => {
    const { options } = this.props;
    const valueList = _.map(options, "value");
    const updatedResult = _.filter(valueList, value => value !== item.value);
    this.updateValue(updatedResult, options);
  };

  renderEdit = () => {
    const {
      options,
      styles,
      showAddButton,
      onClickAddButton,
      showCloseButton,
      t,
    } = this.props;
    return (
      <div className={classes.container}>
        {options.map(item => (
          <Tag
            key={item.value}
            styles={styles}
            item={item}
            removeOption={this.removeOption}
            showCloseButton={showCloseButton}
          />
        ))}
        {showAddButton && (
          <Tag
            styles={styles}
            onClickAddButton={onClickAddButton}
            isAddButton={true}
            t={t}
          />
        )}
      </div>
    );
  };
}

Tags.defaultProps = {
  ...UIBaseComponent.defaultProps,
  options: [],
  showAddButton: false,
  showCloseButton: true,
  value: null, // dont use empty array. updateValue wont work.
};

Tags.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
};

export default compose(I18nHOC({ resource: "common" }))(Tags);

export { Tag };
