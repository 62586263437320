import React from "react";
import classes from "./ForgotPassword.scss";
import { FormTextInput } from "UIComponents";
import { Button } from "@toddle-design/web";

const ForgotPasswordScreen = React.memo(props => {
  const {
    updateInputField,
    data: { email } = {},
    onBackClick,
    onSendClick,
    checkEmailVerification,
    updateInpurRef,
    onEmailEnter,
    renderBackButtonComponent,
    customStyle: { containerStyle, headerStyle, subTextStyle } = {},
  } = props;
  return (
    <div className={classes.container} style={containerStyle}>
      {renderBackButtonComponent({ onClick: onBackClick })}
      <div style={headerStyle}>{`Forgot Password?`}</div>
      <div className={classes.headerSubText}>{`Let’s get you a new one`}</div>
      <div style={subTextStyle}>
        {`Please enter your email address below and we’ll send a new password to this address.`}
      </div>

      <div className={classes.innerContainer}>
        <div className={classes.inputsContainer}>
          <div className={classes.inputContainer}>
            <FormTextInput
              size={"large"}
              textInputLabel={"Email*"}
              value={email}
              placeholder={"Enter your email"}
              name={"email"}
              updateInputField={updateInputField}
              customValidation={checkEmailVerification}
              ref={ref => updateInpurRef(email, ref)}
              error={"Enter email address"}
              onEnter={onEmailEnter}
            />
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <Button
            variant={"destructive"}
            size={"large"}
            onClick={onSendClick}
            isFullWidth={true}
          >
            {"Send password"}
          </Button>
        </div>
      </div>
    </div>
  );
});

ForgotPasswordScreen.displayName = "ForgotPasswordScreen";

const ConfirmationScreen = React.memo(props => {
  const {
    data: { email } = {},
    onSignClick,
    customStyle: { containerStyle } = {},
  } = props;

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.confirmationHeaderText}>
        {`Your new password has been sent!`}
      </div>
      <div className={classes.confimationSubTextContainer}>
        <div className={classes.confirmationSubText}>
          {`We have sent a new password on the provided email address`}
        </div>
        <div
          className={classes.confirmationSubText1}
        >{`If you didn’t recieve the mail, please contact your administrator`}</div>
      </div>
      <div className={classes.bottomContainer}>
        <Button variant={"destructive"} size={"large"} onClick={onSignClick}>
          {"Sign in"}
        </Button>
      </div>
    </div>
  );
});

ConfirmationScreen.displayName = "ConfirmationScreen";

class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isSent: false };
    this.inputRefs = {};
  }

  UNSAFE_componentWillMount = () => {
    const { resetScreen, loginData } = this.props;
    resetScreen("forgotPassword", { email: loginData.email });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  onSendClick = () => {
    const { sendForgotPassword, showErrorToast } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    sendForgotPassword({
      successCallBack: () => {
        this.setState({ isSent: true });
      },
    });
  };

  onBackClick = () => {
    const { goToBack } = this.props;

    goToBack();
  };

  updateInpurRef = (key, ref) => {
    this.inputRefs[key] = ref;
  };

  onEmailEnter = () => {
    this.onSendClick();
  };

  render() {
    const { isSent } = this.state;

    return !isSent ? (
      <ForgotPasswordScreen
        {...this.props}
        onBackClick={this.onBackClick}
        onSendClick={this.onSendClick}
        updateInpurRef={this.updateInpurRef}
        onEmailEnter={this.onEmailEnter}
      />
    ) : (
      <ConfirmationScreen {...this.props} onSignClick={this.onBackClick} />
    );
  }
}

export default ForgotPassword;
