import React, { PureComponent } from "react";

import classes from "./Groupedview.scss";

import classNames from "classnames";

import { colors } from "Constants/colorConstants";

import { AnimateHeight, BenchmarkView } from "UIComponents";
import { ArrowIcon } from "SvgComponents";
import { getElementValue } from "modules/Services";

const getElementValueMemoize = _.memoize(getElementValue, params =>
  JSON.stringify(params)
);
class Groupedview extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEnable: props.isDefaultEnabled,
    };
  }

  onCheckboxClicked = selectedValues => {
    const {
      props: { nodes, parentNode },
    } = this;

    this.props.onItemSelected({
      selections: selectedValues,
      nodes,
      parentNode,
    });
  };

  onAccordianClick = () => {
    const { isDefaultEnabled } = this.props;
    if (!isDefaultEnabled) {
      this.setState({ isEnable: !this.state.isEnable });
    }
  };

  render() {
    const {
      props: {
        selectedValues,
        parentNode,
        nestedGroupedValue,
        readOnly,
        isDefaultEnabled,
        mainContainerStyle,
        headerContainerStyle,
        headerLabelStyle,
        banchmarksContainerStyle,
        mainContentContainerStyle,
        childrenContainerStyle,
        rootNodeDepth,
        showHeader,
        dynamicLevelContainerStyle,
      },
      state: { isEnable },
    } = this;

    const numOfSelectedValues = _.get(selectedValues, "length", 0);
    const selectedNumberString =
      numOfSelectedValues > 0 ? ` (${numOfSelectedValues})` : "";

    const arrowStyle = classNames(
      { [classes.arrowContainer]: true },
      { [classes.openArrow]: isEnable },
      { [classes.closeArrow]: !isEnable }
    );

    const titleStyle = classNames(
      { [classes.headerLabel]: true },
      { [classes.headerLabelOpen]: isEnable }
    );

    return (
      <div className={classes.mainContainer} style={mainContainerStyle}>
        {showHeader && (
          <div
            className={classes.headerContainer}
            style={headerContainerStyle}
            onClick={this.onAccordianClick}
          >
            <div
              className={titleStyle}
              style={headerLabelStyle}
            >{`${parentNode.label}${selectedNumberString}`}</div>
            {!isDefaultEnabled && (
              <div className={arrowStyle}>
                <ArrowIcon width={12} height={6} fill={colors.gray48} />
              </div>
            )}
          </div>
        )}

        <div
          className={classes.banchmarksContainer}
          style={banchmarksContainerStyle}
        >
          <AnimateHeight
            duration={300}
            height={isEnable || readOnly ? "auto" : 0}
            style={{ width: "100%" }}
          >
            <div
              className={classes.mainContentContainer}
              style={mainContentContainerStyle}
            >
              <BenchmarkView
                value={nestedGroupedValue}
                startDepth={rootNodeDepth}
                mode={"view"}
                isTagging={true}
                updateInputField={this.onCheckboxClicked}
                selectedValues={selectedValues}
                childrenContainerStyle={childrenContainerStyle}
                dynamicLevelContainerStyle={dynamicLevelContainerStyle}
              ></BenchmarkView>
            </div>
          </AnimateHeight>
        </div>
      </div>
    );
  }
}

export default Groupedview;

Groupedview.defaultProps = {
  isDefaultEnabled: false,
  showHeader: true,
};
