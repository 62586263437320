import React from "react";
import classes from "./DropDown.scss";
import { I18nHOC } from "UIComponents";
import ACLStore from "lib/aclStore";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
} from "Constants/stringConstants";
import { Dropdown, DropdownMenu } from "@toddle-design/web";
import {
  AddOutlined,
  FaOutlined,
  LeLibraryOutlined,
  SaOutlined,
  UnitLibraryOutlined,
  UnitPlanOutlined,
  ImportOutlined,
} from "@toddle-design/web-icons";

const getMenuItems = ({ t, label, curriculumType }) => {
  const importSubGroup = {
    label: t("common:import_from"),
    key: "IMPORT",
    isItemGroup: true,
    options: [
      {
        label: t("common:unit"),
        key: "IMPORT_FROM_UNITS",
        isDisable: false,
        icon: <UnitPlanOutlined variant={"subtle"} />,
        isVisible: ACLStore.can("TeacherPortal:ImportLeFromOtherUnits"),
      },
      {
        label: t("community:community"),
        key: "SEARCH_COMMUNITY",
        icon: <UnitLibraryOutlined variant={"subtle"} />,
        isDisable: false,
        isVisible: ACLStore.can("TeacherPortal:Community"),
      },
      {
        label: t("common:library"),
        isDisable: false,
        key: "SEARCH_LE_LIBRARY",
        icon: <LeLibraryOutlined variant={"subtle"} />,
        isVisible:
          ACLStore.can("TeacherPortal:LELibrary") &&
          _.isEqual(curriculumType, CURRICULUM_TYPE_PYP),
      },
      {
        label: t("common:library"),
        isDisable: false,
        key: "SEARCH_LE_LIBRARY",
        icon: <LeLibraryOutlined variant={"subtle"} />,
        isVisible:
          ACLStore.can("TeacherPortal:LELibrary") &&
          !_.isEqual(curriculumType, CURRICULUM_TYPE_PYP),
      },
    ],
  };

  const shouldUseImportSubGroup =
    importSubGroup.options.filter(
      option => !option.isDisable && !!option.isVisible
    ).length > 1;

  return [
    {
      label: t("common:create_new"),
      icon: <AddOutlined variant={"subtle"} />,
      isDisable: false,
      key: "CREATE",
      isVisible: true,
    },
    {
      isDivider: true,
      key: "DIVIDER",
    },
    shouldUseImportSubGroup
      ? importSubGroup
      : {
          label: t("classRoom:import_unit"),
          icon: <ImportOutlined variant={"subtle"} />,
          isVisible: ACLStore.can("TeacherPortal:ImportLeFromOtherUnits"),
        },
  ];
};

const DropDown = ({
  renderButtonComponent,
  onItemClick,
  t,
  label,
  curriculumType,
  groupType,
  assessmentTypeGroup,
}) => {
  let menuItems = getMenuItems({ t, label, curriculumType });

  if (curriculumType != "IB_PYP") {
    //Remove Search community option for non IB PYP curriculum
    menuItems = _.map(menuItems, menuItem => {
      if (_.get(menuItem, "key", "") === "IMPORT") {
        const currentOptions = _.get(menuItem, "options", []);
        const updatedOptions = _.filter(
          currentOptions,
          option => !_.includes(["SEARCH_COMMUNITY"], option.key)
        );
        return { ...menuItem, options: updatedOptions };
      } else {
        return menuItem;
      }
    });
  }

  if (
    groupType == "ASSESSMENT" &&
    _.includes([CURRICULUM_TYPE_DP, CURRICULUM_TYPE_MYP], curriculumType)
  ) {
    //Remove Create Assessment Option and Add Summative Assessment and Formative Assessment Option

    menuItems = _.filter(
      menuItems,
      item => !_.includes(["CREATE", "DIVIDER"], item.key)
    );
    menuItems.unshift({
      label: t("common:create"),
      key: "CREATE",
      isItemGroup: true,
      options: [
        {
          label: t("common:formative_assessment"),
          isDisable: false,
          key: "CREATE_FMT_ASSESSMENT",
          icon: <FaOutlined variant={"subtle"} />,
          isVisible: true,
        },
        {
          label: t("common:summative_assessment"),
          isDisable: false,
          key: "CREATE_SMT_ASSESSMENT",
          icon: <SaOutlined variant={"subtle"} />,
          isVisible: true,
        },
      ],
    });
  }

  if (groupType == "ASSESSMENT" && curriculumType == "UBD") {
    // Remove Create Assessment Option and Add Summative Assessment and Formative Assessment Option
    menuItems = _.filter(menuItems, item => item.key != "CREATE");

    if (assessmentTypeGroup == "SECOND_STAGE_ASSESSMENT") {
      menuItems.unshift({
        label: t("common:create_new_with_label", {
          label: t("common:stage_2_assessment"),
        }),
        isDisable: false,
        icon: <AddOutlined variant={"subtle"} />,
        key: "CREATE_PT_ASSESSMENT",
        isVisible: true,
      });
    } else if (assessmentTypeGroup == "THIRD_STAGE_ASSESSMENT") {
      menuItems.unshift({
        label: t("common:create_new_with_label", {
          label: t("common:stage_3_assessment"),
        }),
        isDisable: false,
        icon: <AddOutlined variant={"subtle"} />,
        key: "CREATE_PRI_ASSESSMENT",
        isVisible: true,
      });
    } else {
      menuItems.unshift({
        label: t("common:create_new_with_label", {
          label: t("common:assessment_label"),
        }),
        isDisable: false,
        icon: <AddOutlined variant={"subtle"} />,
        key: "CREATE_PT_ASSESSMENT",
        isVisible: true,
      });
    }
  }

  menuItems = _.reduce(
    menuItems,
    (result, menuItem) => {
      if (!_.get(menuItem, "isItemGroup")) {
        // current menuItem is not an item group
        if (
          !_.get(menuItem, "isDisable", false) &&
          !!_.get(menuItem, "isVisible", true)
        ) {
          result.push(menuItem);
        }
      } else {
        const currentOptions = _.get(menuItem, "options", []);
        const updatedOptions = _.filter(
          currentOptions,
          option =>
            !!_.get(option, "isVisible", true) &&
            !_.get(option, "isDisable", false)
        );
        if (_.get(updatedOptions, "length", 0) > 0) {
          // add the current itemGroup only if it has atleast one option in it
          result.push({ ...menuItem, options: updatedOptions });
        } else {
        }
      }
      return result;
    },
    []
  );

  return (
    <div className={classes.container}>
      {_.size(menuItems) > 1 ? (
        <Dropdown
          overlay={
            <DropdownMenu
              options={menuItems}
              onClick={({ key }) => onItemClick(key)}
            />
          }
        >
          {renderButtonComponent()}
        </Dropdown>
      ) : (
        <div
          className={classes.buttonComponent}
          onClick={() =>
            onItemClick(_.get(menuItems, ["0", "value"], "CREATE"))
          }
        >
          {renderButtonComponent({ singleButton: true })}
        </div>
      )}
    </div>
  );
};

export default I18nHOC({ resource: ["community", "classRoom"] })(DropDown);
