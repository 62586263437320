import React from "react";
import classes from "./LibraryItem.scss";
import DropDown from "./DropDown";
import { CommentIcon } from "SvgComponents";

import { ResourceImagePlaceHolder, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import { getPlannerTemplateFromFragment } from "modules/CommonGraphqlHelpers";
import { getElementValue } from "modules/Services";
import { PlanathonSubmissionStars } from "AppComponents";

const getValue = ({ fields, key }) => {
  return _.find(fields, item => item.uid == key) || {};
};

const TextComponent = ({ value, isFirst }) => {
  return (
    <div className={classes.othertextInnercontainer}>
      {!isFirst && value && <span className={classes.dot}>{`•`}</span>}
      <span className={classes.otherText}>{value}</span>
    </div>
  );
};

const ItemOtherDetails = ({ fields, templateId, t }) => {
  const template = _.get(
    getPlannerTemplateFromFragment(templateId),
    "body",
    {}
  );

  const { value: groupSizeKey } = getValue({ fields, key: "size" });

  const { value: documentationKey } = getValue({
    fields,
    key: "documentation",
  });

  const { value: measureAssessingKey } = getValue({
    fields,
    key: "measureAssessing",
  });

  const { value: assessmentTypeKey } = getValue({
    fields,
    key: "assessmentType",
  });

  const {
    field_list: { measureAssessing, size, documentation } = {},
  } = template;

  const { value: duration = 0 } = getValue({
    fields,
    key: "duration",
  });

  const durationValue = getElementValue({
    fieldUID: "duration",
    valueKeys: duration,
  });

  const assessmentTypeValue = getElementValue({
    fieldUID: "assessmentType",
    valueKeys: assessmentTypeKey,
  });

  const documentationValue = getElementValue({
    fieldUID: "documentation",
    valueKeys: documentationKey,
    fieldObj: documentation,
  });

  const groupSizeValue = getElementValue({
    fieldUID: "size",
    valueKeys: groupSizeKey,
    fieldObj: size,
    t,
  });

  const toolValue = getElementValue({
    fieldUID: "measureAssessing",
    valueKeys: measureAssessingKey,
    fieldObj: measureAssessing,
    t,
  });

  return (
    <div className={classes.othertextcontainer}>
      <TextComponent value={durationValue} isFirst={true} />
      {/* <TextComponent value={assessmentTypeValue} isFirst={!!!durationValue} /> */}

      <TextComponent value={groupSizeValue} isFirst={!durationValue} />
      <TextComponent value={toolValue} />
      <TextComponent value={documentationValue} />
    </div>
  );
};

class LibraryItem extends React.PureComponent {
  render() {
    const {
      onCardClicked,
      item,
      onDeleteClick,
      onDuplicateClick,
      onPrintClick,
      readOnly,
      onAddToCommunityClick,
      t,
    } = this.props;

    const createdBy = _.get(item, "createdBy", {});
    const {
      paritaStarStatus,
      kristenStarStatus,
      juryStarStatus,
      paritaProcessed,
      kristenProcessed,
      juryProcessed,
    } = item || {};
    return (
      <div
        onClick={() => onCardClicked(item)}
        key={item.id}
        className={classes.item}
      >
        <div className={classes.itemImageContainer}>
          <ResourceImagePlaceHolder
            id={item.id}
            type={item.assessmentType}
            imageUrl={item.image.value}
          />
        </div>

        <div className={classes.itemContentContainer}>
          <div className={classes.itemDetailContainer}>
            <div className={classes.itemDetailLeftContainer}>
              <div className={classes.titleContainer}>
                <div className={classes.title}>
                  {!item.title.value ? t("common:untitled") : item.title.value}
                </div>
                <div className={classes.commentIcon}>
                  <CommentIcon width={24} height={24} fill={colors.strokeOne} />
                </div>
              </div>

              <div className={classes.createdBy}>
                {`${_.get(createdBy, "firstName", t("common:unknown"))} ${_.get(
                  createdBy,
                  "lastName",
                  ""
                )}`}
              </div>
            </div>
            <PlanathonSubmissionStars
              parentId={item.id}
              parentType={"ASSESSMENT"}
              paritaStarStatus={paritaStarStatus}
              kristenStarStatus={kristenStarStatus}
              juryStarStatus={juryStarStatus}
              paritaProcessed={paritaProcessed}
              kristenProcessed={kristenProcessed}
              juryProcessed={juryProcessed}
            />
          </div>
          <div className={classes.bottomContainer}>
            <ItemOtherDetails
              fields={item.fields}
              templateId={item.templateId}
              t={t}
            />

            {!readOnly && (
              <div className={classes.menuContainer}>
                <DropDown
                  item={item}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onCardClicked}
                  onDuplicateClick={onDuplicateClick}
                  onPrintClick={onPrintClick}
                  onAddToCommunityClick={onAddToCommunityClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(LibraryItem);
