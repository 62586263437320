import {
  getUserInfoQuery,
  getCommunityConstantsQuery,
  getCommunityTagsQuery,
  getGlobalSchoolsQuery,
} from "./OnBoardingQuery";
import client from "apolloClient";
import {
  communityActivityFragment,
  schoolFragment,
} from "./OnBoardingFragments";

export const getUserInfoFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getUserInfoQuery,
      variables: {
        id,
      },
    });
    // console.log("queryData::::", queryData);
  } catch (e) {
    console.error(e);
    return false;
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCommunityConstantsFromCache = () => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCommunityConstantsQuery,
      variables: {},
    });
  } catch (e) {
    console.error(e);
    return false;
  }
  return _.get(queryData, "community.constants", {});
};

export const getCommunityTagsFromCache = ({ searchText, types } = {}) => {
  try {
    return client.readQuery({
      query: getCommunityTagsQuery,
      variables: {
        searchText,
        types,
      },
    });
  } catch (e) {
    // console.error(e);
    return {};
  }
};

export const writeCommunityTagsInCache = ({ data, searchText, types }) => {
  try {
    client.writeQuery({
      query: getCommunityTagsQuery,
      data,
      variables: { searchText, types },
    });
  } catch (e) {
    console.error(e);
  }
};

export const writeUserInfoToCache = ({ userId, data }) => {
  try {
    client.writeQuery({
      query: getUserInfoQuery,
      variables: {
        id: userId,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateCommunityActivityInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `CommunityActivity:${id}`,
      fragment: communityActivityFragment.communityActivity,
      fragmentName: "communityActivityItem",
      variables: {
        id,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateSchoolTenureInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SchoolTenure:${id}`,
      fragment: schoolFragment.schoolTenure,
      fragmentName: "schoolTenureItem",
      variables: {
        id,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getGlobalSchoolsFromCache = ({ searchText }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getGlobalSchoolsQuery,
      variables: {
        searchText,
      },
    });
  } catch (e) {
    console.error(e);
    return false;
  }
  return queryData;
};

export const writeGlobalSchoolsInCache = ({ searchText, data }) => {
  try {
    client.writeQuery({
      query: getGlobalSchoolsQuery,
      variables: {
        searchText,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};
