import { withAsyncRouteLoading } from "UIComponents";

export default store => {
  return {
    path: ":curriculumTypeSubTabs",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./CurriculumTypeSubTabs").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-course"
          );
        })
    ),
    childRoutes: [],
  };
};
