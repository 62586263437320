import React from "react";
import classes from "./Header.scss";
import { UIButton, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import { ToddleIcon, LeftIcon } from "SvgComponents";

const Header = React.memo(props => {
  const { goToBack, onClickAdd, t } = props;
  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.absoluteContainer} onClick={goToBack}>
          <div className={classes.leftIcon}>
            <LeftIcon />
          </div>
          <ToddleIcon />
        </div>
        <div className={classes.detailContainer}>
          <div className={classes.title}>{`Schools`}</div>
        </div>
      </div>
      <div className={classes.rightContainer} onClick={onClickAdd}>
        <UIButton type={"hollow"} color={"pink"}>
          {`Create School`}
        </UIButton>
      </div>
    </div>
  );
});

export default I18nHOC({ resource: [] })(Header);
