import React from "react";
import classNames from "classnames";

import classes from "./ChildModal.scss";
import { UIButton, UIModal, ProfileCascade, I18nHOC } from "UIComponents";

import { fontStyle } from "Constants";

const profileCascadeStyle = {
  customStyle: {
    width: 80,
    height: 80,
  },
};

const styles = {
  doneButton: {
    textAlign: "center",
    ...fontStyle.demiBold,
    fontSize: "1.6rem",
    lineHeight: 1.25,
    height: "56px",
    width: "320px",
  },
  cancelButton: {
    textAlign: "center",
    ...fontStyle.medium,
    fontSize: "1.6rem",
    lineHeight: 1.25,
    height: "48px",
    minWidth: "100px",
  },
};

// ChildModal is used for
// 1. remove child confirmation
// 2. To display information after successfully adding child
const ChildModal = props => {
  const {
    childId,
    firstName,
    profileImage,
    t,
    onDoneClick,
    onRemoveClick,
    onCloseClick,
    fullName,
    isRemoveModal = false,
  } = props;

  const renderProfileCascade = () => {
    return (
      <div className={classes.iconContainer}>
        <ProfileCascade
          items={[
            {
              id: childId,
              value: isRemoveModal ? fullName : firstName,
              imageUrl: profileImage,
            },
          ]}
          customStyle={profileCascadeStyle.customStyle}
        />
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={classes.buttonContainer}>
        {!isRemoveModal ? (
          <UIButton
            color="blue"
            containerStyle={styles.doneButton}
            onClick={onDoneClick}
          >
            {t("common:done")}
          </UIButton>
        ) : (
          <React.Fragment>
            <div className={classes.cancelButton}>
              <UIButton
                color="grey"
                containerStyle={styles.cancelButton}
                onClick={onCloseClick}
              >
                {t("common:cancel")}
              </UIButton>
            </div>
            <UIButton
              color="pink"
              containerStyle={styles.cancelButton}
              onClick={() => onRemoveClick(childId)}
            >
              {t("common:remove")}
            </UIButton>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderTextMsg = () => {
    return (
      <div className={classes.textMsg}>
        {isRemoveModal
          ? ` ${t("common:remove_with_label", {
              label: fullName,
            })}?`
          : t("common:description_childmodal", {
              studentName: firstName,
            })}
      </div>
    );
  };

  const renderInfoText = () => {
    return (
      <div className={infoClass}>
        {t("common:remove_user_from_account_confirmation", {
          user: fullName,
        })}
      </div>
    );
  };

  const modalClass = classNames({
    [classes.successModalContent]: !isRemoveModal,
    [classes.removeModalContent]: isRemoveModal,
  });

  const infoClass = classNames({
    [classes.infoText]: !isRemoveModal,
    [classes.removeChildInfo]: isRemoveModal,
  });

  return (
    <UIModal
      isOpen={true}
      modalContent={modalClass}
      onRequestClose={isRemoveModal ? onCloseClick : null}
    >
      <div className={classes.container}>
        {renderProfileCascade()}
        {renderTextMsg()}
        {isRemoveModal && renderInfoText()}
        {renderButtons()}
      </div>
    </UIModal>
  );
};

export default I18nHOC({ resource: ["common"] })(ChildModal);
