import React from "react";
import classes from "./StudentTemplates.scss";
import PropTypes from "prop-types";
import {
  TemplateAttachment,
  FullScreenLoader,
  UseTemplateModal,
  UIButton,
  I18nHOC,
  MediaModal,
} from "UIComponents";
import { SNPv2_VISITOR_MODULE_PERM } from "Constants/permissionConstants";
import { createAttachmentGroupsV2, replaceEditPreview } from "modules/Services";
import { getWorkBookUrl } from "Utils";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import ACLStore from "lib/aclStore";

const StudentTemplates = React.memo(
  ({
    value,
    onUseTemplateClick,
    label,
    canCopy,
    createAttachmentGroupsV2,
    submissionId,
    canTurnIn,
    userType,
    templateContainerStyle,
    myWorkAttachments,
    assignmentCourseId,
    t,
    templateAttachmentWrapperStyle,
    showMediaCard,
    attachmentBottomComponent,
    onClickCreatePost,
    showAddToJournal,
    attachmentResponseObject,
    onMediaClick,
    isSubmitted,
    resetStudentTemplate,
    isScheduled,
    isArchivedClass,
  }) => {
    // local state
    const [isLoading, setLoading] = React.useState(false);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [isTemplateModal, toggleTemplateModal] = React.useState(false);
    const [mediaModalMode, setMediaModalMode] = React.useState("close");

    const shouldUseWorkbook = ACLStore.can("FeatureFlag:Workbook");

    const toggleMediaModal = isOpen => {
      if (!isOpen) {
        setMediaModalMode("close");
      }
    };

    const _useTemplateClick = async ({
      templateGroup,
      shouldShowEditResponse,
      showPreview,
    }) => {
      // don't open workbook for parent
      if (
        typeof onUseTemplateClick === "function" &&
        userType !== "parent" &&
        !showPreview
      ) {
        onUseTemplateClick(templateGroup, shouldShowEditResponse, isSubmitted);
        return;
      }

      const template = _.get(templateGroup, ["attachments", "0"]);

      // open media modal for parent
      if (userType === "staff" || userType === "parent" || showPreview) {
        setSelectedTemplate([template]);
        const mediaModes = ["IMAGE", "VIDEO", "AUDIO", "WORKBOOK", "FILE"];
        const isMedia = mediaModes.includes(_.get(template, "type", null));
        if (isMedia) {
          if (showPreview) setMediaModalMode("view");
          else setMediaModalMode("edit");
        } else {
          openTemplateModal(getTemplateToOpen(templateGroup));
        }
        return;
      }

      const hasPermissions = !!_.get(template, "metadata.permission", false);
      const isImage = _.get(template, "type", null) === "IMAGE";
      const shouldOpenInWorkbook = shouldUseWorkbook && isImage;
      if ((!canCopy || !hasPermissions) && !shouldOpenInWorkbook) {
        openTemplateModal(getTemplateToOpen(templateGroup));
        return;
      }
      setLoading(true);

      try {
        let returnedTemplate;
        if (
          shouldShowEditResponse ||
          userType === "parent" ||
          userType === "staff"
        ) {
          returnedTemplate = templateGroup;
        } else {
          const result = await makeTemplateCopy(templateGroup.id);

          returnedTemplate = _.get(result, ["0"], {});
        }

        if (!_.isEmpty(returnedTemplate)) {
          const returnedAttachment = _.get(returnedTemplate, [
            "attachments",
            "0",
          ]);
          setSelectedTemplate([returnedAttachment]);
          const isImage = _.get(returnedAttachment, "type", "") === "IMAGE";
          if (isImage) {
            setMediaModalMode("edit");
          } else {
            openTemplateModal(getTemplateToOpen(returnedTemplate));
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const getTemplateToOpen = templateGroup => {
      const template = _.get(templateGroup, ["attachments", "0"]);
      const isLinkType = _.get(template, "type", "") === "LINK";
      if (isLinkType) {
        if (userType === "parent") {
          const { url = "" } = template;
          template.url = replaceEditPreview(url);
        }
        return template;
      } else {
        return [template];
      }
    };

    const openTemplateModal = templateToOpen => {
      setSelectedTemplate(templateToOpen);
      toggleTemplateModal(true);
    };

    const makeTemplateCopy = async templateGroupId => {
      const input = {
        parentId: submissionId,
        parentType: "STUDENT_ASSIGNMENT_SUBMISSION",
        studentTemplateId: templateGroupId,
        copyFromstudentTemplate: canCopy,
      };
      try {
        const result = await createAttachmentGroupsV2({
          input,
        });
        return result;
      } catch (error) {
        console.error(error);
      }
    };
    const isSelectedTemplatePdf =
      _.get(_.first(selectedTemplate), "type") === "FILE" &&
      _.get(_.first(selectedTemplate), "mimeType") === "application/pdf";

    const isEditable =
      (canTurnIn && userType === "student" && mediaModalMode === "edit") ||
      (userType === "staff" &&
        !ACLStore.can(SNPv2_VISITOR_MODULE_PERM) &&
        !isSelectedTemplatePdf); // Template should not be editable for staff with visitor designation or if the template is a pdf

    const showMediaModal =
      mediaModalMode === "edit" || mediaModalMode === "view";

    return (
      <div className={classes.container} style={templateContainerStyle}>
        {showMediaModal && (
          <MediaModal
            toggleMediaModal={toggleMediaModal}
            attachments={selectedTemplate}
            activeIndex={0}
            showWorkBook={isEditable}
            mode={isEditable ? "edit" : "view"}
          />
        )}

        {selectedTemplate && isTemplateModal && (
          <UseTemplateModal
            open={true}
            onClose={() => toggleTemplateModal(false)}
            attachment={selectedTemplate}
            rightActionButton={
              canTurnIn &&
              userType === "student" && (
                <UIButton
                  size="sm"
                  color="pink"
                  type="filled"
                  onClick={() => {
                    toggleTemplateModal(false);
                  }}
                >
                  {t("common:save_exit")}
                </UIButton>
              )
            }
            shouldShowTabIcon={userType !== "parent"}
          />
        )}
        {isLoading && <FullScreenLoader />}
        {label && <div className={classes.title}>{label}</div>}
        {_.map(value, templateGroup => {
          const templateId = _.get(templateGroup, "id", "");
          const template = _.get(templateGroup, ["attachments", "0"], {});
          const submittedTemplate = _.find(myWorkAttachments, item => {
            return _.get(item, "studentTemplate.id", "") === templateId;
          });

          const shouldShowEditResponse = !_.isEmpty(submittedTemplate);
          const isFeedbackGiven = _.get(
            attachmentResponseObject,
            _.get(submittedTemplate, "id")
          );

          const onUseTemplateClickLocal = (event, showPreview = false) => {
            event.stopPropagation();
            if (isFeedbackGiven) {
              onMediaClick(submittedTemplate, false, true);
            }

            _useTemplateClick({
              templateGroup: shouldShowEditResponse
                ? submittedTemplate
                : templateGroup,
              shouldShowEditResponse,
              showPreview,
            });
          };

          return (
            <TemplateAttachment
              canTurnIn={canTurnIn}
              attachment={template}
              key={templateGroup.id}
              onUseTemplateClick={onUseTemplateClickLocal}
              userType={userType}
              shouldShowEditResponse={shouldShowEditResponse}
              assignmentCourseId={assignmentCourseId}
              wrapperStyle={templateAttachmentWrapperStyle}
              showMediaCard={showMediaCard}
              attachmentBottomComponent={() =>
                _.isFunction(attachmentBottomComponent) &&
                attachmentBottomComponent({ attachment: submittedTemplate })
              }
              onClickCreatePost={() =>
                onClickCreatePost(
                  _.get(submittedTemplate, ["attachments", "0"], {})
                )
              }
              showAddToJournal={showAddToJournal}
              isFeedbackGiven={isFeedbackGiven}
              submittedTemplate={submittedTemplate}
              resetStudentTemplate={resetStudentTemplate}
              isSubmitted={isSubmitted}
              isScheduled={isScheduled}
              isArchivedClass={isArchivedClass}
            />
          );
        })}
      </div>
    );
  }
);

StudentTemplates.propTypes = {
  submissionId: PropTypes.number,
  canCopy: PropTypes.bool,
};

StudentTemplates.defaultProps = {
  canCopy: false,
  canTurnIn: false,
  templateContainerStyle: {},
};

const mapActionCreators = {
  createAttachmentGroupsV2,
};

const mapStateToProps = (state, ownProps) => {
  return {
    userType: _.get(state, "login.userInfo.user_type"),
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common", "classRoom"] })
)(StudentTemplates);
