import PropTypes from "prop-types";
import React from "react";

const CameraIconV2 = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 28 24">
      <path
        d="M21 9C21 8.44771 21.4477 8 22 8H23C23.5523 8 24 8.44772 24 9C24 9.55229 23.5523 10 23 10H22C21.4477 10 21 9.55229 21 9Z"
        fill={props.fill}
      />
      <path
        d="M14 7C10.6863 7 8 9.68629 8 13C8 16.3137 10.6863 19 14 19C17.3137 19 20 16.3137 20 13C20 9.68629 17.3137 7 14 7ZM10 13C10 10.7909 11.7909 9 14 9C16.2091 9 18 10.7909 18 13C18 15.2091 16.2091 17 14 17C11.7909 17 10 15.2091 10 13Z"
        fill={props.fill}
      />
      <path
        d="M7.55279 1.65836C8.06096 0.642006 9.09975 0 10.2361 0H17.7639C18.9002 0 19.939 0.642007 20.4472 1.65836L21.3416 3.44721C21.511 3.786 21.8573 4 22.2361 4H23C25.7614 4 28 6.23858 28 9V19C28 21.7614 25.7614 24 23 24H5C2.23858 24 0 21.7614 0 19V9C0 6.23858 2.23858 4 5 4H5.76393C6.1427 4 6.48897 3.786 6.65836 3.44721L7.55279 1.65836ZM10.2361 2C9.8573 2 9.51103 2.214 9.34164 2.55279L8.44721 4.34164C7.93904 5.35799 6.90025 6 5.76393 6H5C3.34315 6 2 7.34315 2 9V19C2 20.6569 3.34315 22 5 22H23C24.6569 22 26 20.6569 26 19V9C26 7.34315 24.6569 6 23 6H22.2361C21.0998 6 20.061 5.35799 19.5528 4.34164L18.6584 2.55279C18.489 2.214 18.1427 2 17.7639 2H10.2361Z"
        fill={props.fill}
      />
    </svg>
  );
};

CameraIconV2.defaultProps = {
  height: 24,
  width: 28,
};

CameraIconV2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default CameraIconV2;
