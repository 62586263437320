import React from "react";

const PremiumFeaturesIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 20 20">
      <g fill={props.fill && props.fill}>
        <path d="M16.25,16.5 C17.2164983,16.5 18,17.2835017 18,18.25 C18,19.2164983 17.2164983,20 16.25,20 L3.75,20 C2.78350169,20 2,19.2164983 2,18.25 C2,17.2835017 2.78350169,16.5 3.75,16.5 L16.25,16.5 Z M16.25,17.5 L3.75,17.5 C3.33578644,17.5 3,17.8357864 3,18.25 C3,18.6642136 3.33578644,19 3.75,19 L16.25,19 C16.6642136,19 17,18.6642136 17,18.25 C17,17.8357864 16.6642136,17.5 16.25,17.5 Z M10.6266361,0.277875508 L10.7007263,0.370323932 L14.4052544,5.64 C14.664035,6.00724197 15.1565131,6.12068703 15.5418094,5.91161205 L15.6357032,5.85258437 L18.6229926,3.71 C19.2250985,3.27530436 20.037135,3.76782779 19.9986837,4.47725449 L19.9872905,4.58 L18.2666629,14.275 C18.2047115,14.6626283 17.8930972,14.9488061 17.5119014,14.9938105 L17.4063491,15 L2.59823775,15 C2.21594829,15 1.88123815,14.7440997 1.76712302,14.3855058 L1.73967934,14.275 L0.015053268,4.57897845 C-0.118748042,3.84333967 0.666574421,3.30832965 1.29338795,3.65728828 L1.38173808,3.71322669 L4.37542862,5.85258437 C4.74049405,6.11358023 5.2377935,6.05263974 5.53067162,5.72880369 L5.59943755,5.64240186 L9.30339347,0.372826105 C9.62812712,-0.089575414 10.2608387,-0.122581852 10.6266361,0.277875508 Z M10.0029474,1.116556 L6.4192976,6.21496583 C5.85404916,7.02435069 4.76461896,7.24922611 3.93052666,6.75520473 L3.79400918,6.66618834 L1.05367749,4.70788619 L2.70645682,14 L17.2998425,14 L18.9492394,4.70662427 L16.2185265,6.66518331 C15.4172091,7.24314226 14.3118056,7.08738611 13.6867644,6.34435401 L13.5871732,6.21510269 L10.0029474,1.116556 Z M10,7.5 C11.3814103,7.5 12.5,8.61858974 12.5,10 C12.5,11.3814103 11.3814103,12.5 10,12.5 C8.61858974,12.5 7.5,11.3814103 7.5,10 C7.5,8.61858974 8.61858974,7.5 10,7.5 Z M10,8.5 C9.17087449,8.5 8.5,9.17087449 8.5,10 C8.5,10.8291255 9.17087449,11.5 10,11.5 C10.8291255,11.5 11.5,10.8291255 11.5,10 C11.5,9.17087449 10.8291255,8.5 10,8.5 Z" />
      </g>
    </svg>
  );
};

PremiumFeaturesIcon.defaultProps = {
  height: 20,
  width: 20,
};

export default PremiumFeaturesIcon;
