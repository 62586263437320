import { colors, colorPalette } from "./colorConstants";
import MimeDb from "./mimeTypeConstants";
import EventEmitter from "eventemitter3";
import {
  DiagnosticAssessmentColored,
  BenchmarkAssessmentColored,
  FormativeAssessmentColored,
  OngoingAssessmentColored,
  PriorAssessmentColored,
  SummativeAssessmentColored,
  LearningExperienceColored,
  PerformanceTasksColored,
  SupplementaryEvidenceColored,
  GeneralAssessmentColored,
  FaOutlined,
  SaOutlined,
  LeOutlined,
  QuickTaskOutlined,
  AddOutlined,
  UploadOutlined,
} from "@toddle-design/web-icons";
import {
  PlaceholderOneRectangleIllustration,
  PlaceholderTwoRectangleIllustration,
  PlaceholderThreeRectangleIllustration,
  PlaceholderFourRectangleIllustration,
  PlaceholderFiveRectangleIllustration,
  PlaceholderSixRectangleIllustration,
  PlaceholderSevenRectangleIllustration,
  PlaceholderEightRectangleIllustration,
  PlaceholderOneIllustration,
  PlaceholderTwoIllustration,
  PlaceholderThreeIllustration,
  PlaceholderFourIllustration,
  PlaceholderFiveIllustration,
  PlaceholderSixIllustration,
  PlaceholderSevenIllustration,
  PlaceholderEightIllustration,
} from "@toddle-design/theme";
import {
  QuickTaskFilledIcon,
  LEFilledIcon,
  Formative,
  Summative,
} from "SvgComponents";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_PYP,
  CATEGORY_COURSE,
  CATEGORY_DIPLOMA,
  PLAN_TYPE_360,
  PLAN_TYPE_360_PILOT,
  PLAN_TYPE_FREE,
  PLAN_TYPE_PRO,
  PLAN_TYPE_PRO_PILOT,
  PLAN_TYPE_DEMO,
} from "./stringConstants";
import {
  workbookUrl,
  websiteUrl,
  intercomAppId,
  microsoftClientId,
  zoomClientId,
  lexClientId,
  cleverClientId,
  classLinkClientId,
  configMyp,
  configUbd,
  dummyUserToken,
  publicUserToken,
  demoMypOrg,
  getLexSsoUrl,
  getClassLinkSsoUrl,
} from "config";

export const PLACEHOLDER_SQUARE_IMAGE = [
  PlaceholderOneIllustration,
  PlaceholderTwoIllustration,
  PlaceholderThreeIllustration,
  PlaceholderFourIllustration,
  PlaceholderFiveIllustration,
  PlaceholderSixIllustration,
  PlaceholderSevenIllustration,
  PlaceholderEightIllustration,
];

export const PLACEHOLDER_SQUARE_IMAGE_COUNT = _.size(PLACEHOLDER_SQUARE_IMAGE);

export const PLACEHOLDER_RECT_IMAGE = [
  PlaceholderOneRectangleIllustration,
  PlaceholderTwoRectangleIllustration,
  PlaceholderThreeRectangleIllustration,
  PlaceholderFourRectangleIllustration,
  PlaceholderFiveRectangleIllustration,
  PlaceholderSixRectangleIllustration,
  PlaceholderSevenRectangleIllustration,
  PlaceholderEightRectangleIllustration,
];

export const PLACEHOLDER_RECT_IMAGE_COUNT = _.size(PLACEHOLDER_RECT_IMAGE);

export { fontStyles as fontStyle } from "@toddle-design/theme";
export { colors, colorPalette, MimeDb };
export const CLOUD_URL = "https://cloud.toddleapp.com";
export const PRIVACY_URL = "https://www.toddleapp.com/pc";
export const TERM_URL = "https://www.toddleapp.com/tou";
export const PLANATHON_TERM_URL = "https://www.toddleapp.com/tncp";
export const HELP_CENTER_URL = "https://help.toddleapp.com";

export const WEBSITE_URL = websiteUrl;
export const SCHEDULE_MEETING_URL_FREE_DEMO =
  "https://meetings.hubspot.com/toddle/demo-call";
export const INTERCOM_APP_ID = intercomAppId;
export const PusherEvent = new EventEmitter();
export const PresenceEvent = new EventEmitter();
export const BlurFieldEvent = new EventEmitter();
export const AudioPlayerEvent = new EventEmitter();
export const MessengerEvent = new EventEmitter();
export const DragDropEvent = new EventEmitter();

export const SHARE_URL =
  "http://www.toddleapp.com/planathon?utm_source=facebook&utm_medium=planathon&utm_campaign=share_planathon";
export const PLANATHON_SUBMISSION_URL = `http://www.toddleapp.com/planathon?utm_source=facebook&utm_medium=planathon&utm_campaign=planathon_submission`;

export const TWITTER_TITLE =
  "I’m participating in #ToddlePlanathon! Join me and let’s make PYP awesomer, together 🙂\nRegister at www.toddleapp.com/planathon\n@toddle_edu #ibpyp #pypchat";

export const FB_TITLE =
  "I’m participating in the Toddle Planathon! Join me and let’s make PYP awesomer, together :)\nRegister at www.toddleapp.com/planathon";
export const FB_HASH_TAG = "#toddleplanathon";

export const FONT_URL =
  "https://fast.fonts.net/jsapi/8d436f36-1b8b-45c4-b990-c8c5d2f12edd.js";
export const GOOGLE_CLIENT_ID =
  "328967318361-jutd87l49b97g76d2kareu14tqtjp00d.apps.googleusercontent.com";
export const GOOGLE_DEVELOPER_KEY = "AIzaSyDZ23YQu6gpqLi-za6VtmsvtxExhaVkrqU";

export const MICROSOFT_CLIENT_ID = microsoftClientId;

export const ZOOM_CLIENT_ID = zoomClientId;

export const CLEVER_CLIENT_ID = cleverClientId;

export const LEX_CLIENT_ID = lexClientId;

export const CLASSLINK_CLIENT_ID = classLinkClientId;

export const LEX_CODE_CHALLENGE = `g5R5mtyNrXaeA2RkInoLuaFMhy-umzEV8nVY8MBUHbs`;

export const WORK_BOOK_URL = workbookUrl;

export const mypConfig = configMyp;

export const ubdConfig = configUbd;

export const redirectUri = encodeURI(window.location.origin);

export const ZOOM_AUTH_URL = `https://zoom.us/oauth/authorize?client_id=${ZOOM_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}`;

export const LEX_SSO_URL = getLexSsoUrl(
  LEX_CLIENT_ID,
  redirectUri,
  LEX_CODE_CHALLENGE
);

export const CLASSLINK_SSO_URL = getClassLinkSsoUrl(
  CLASSLINK_CLIENT_ID,
  redirectUri
);

export const MICROSOFT_SCOPES = [
  "files.read.all",
  "user.read",
  "OnlineMeetings.ReadWrite",
  "Calendars.ReadWrite",
];
export const BAR_URL = `${CLOUD_URL}/assets/website/planathon/bar.svg`;
export const LOGIN_IMAGES_LIST = [`${CLOUD_URL}/assets/webapp/sign_in_bg.png`];

export const GOOGLE_ANALYTICS_ID = "UA-84152542-3";
export const DEFAULT_PROFILE_PIC = `${CLOUD_URL}/assets/webapp/profile_image.png`;
export const THUMB_URL_CUSTOM = `${CLOUD_URL}/thumber/`;

export const MAX_FILE_SIZE_VIEWER_LIMIT = 26214400;
export const GOOGLE_FILE_VIEWER_URL = "https://docs.google.com/viewer?url=";
export const MS_FILE_VIEWER_URL =
  "https://view.officeapps.live.com/op/embed.aspx?src=";
export const MISC_FILE_EXTENSION_MIMETYPE_MAPPING = {
  key: "application/x-iwork-keynote-sffkey",
  numbers: "application/x-iwork-numbers-sffnumbers",
  pages: "application/x-iwork-pages-sffpages",
  csv: "text/csv",
};
export const MS_FILE_VIEWER_SUPPORTED_MIMETYPE = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
];

export const GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE = [
  "application/vnd.google-apps.document",
  "application/vnd.google-apps.file",
  "application/vnd.google-apps.presentation",
  "application/vnd.google-apps.spreadsheet",
];
export const GOOGLE_PICKER_MIME_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  ...GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.google-apps.drawing",
];

export const DEMO_GRADE_ICON = `${CLOUD_URL}/assets/webapp/ToddleDemoGrade.svg`;
export const PLANNATHON_LARGE_ICON = `${CLOUD_URL}/assets/webapp/img_planthon_widget_Freemium.png`;
export const PLANNATHON_SMALL_ICON = `${CLOUD_URL}/assets/webapp/img_planthon_widget_premium.png`;
export const PLANATHON_SUBMISSION_SUCCESS_ICON = `${CLOUD_URL}/assets/webapp/img_planthon_widget_submission.png`;
export const PLANATHON_SUBMISSION_LARGE_ICON = `${CLOUD_URL}/assets/webapp/Planathon_Submission_Large.svg`;
export const PLANATHON_SUBMISSION_SMALL_ICON = `${CLOUD_URL}/assets/webapp/Banner_Planathon_Submission.svg`;
export const COMMUNITY_IMAGE_URL = `${CLOUD_URL}/assets/webapp/Toddle_community_home.png`;
export const COMMUNITY_CONTRIBUTE_URL = `${CLOUD_URL}/assets/webapp/Community/contributions_and_feedback.png`;
export const COMMUNITY_BANNER_VIDEO_IMAGE = `${CLOUD_URL}/assets/webapp/Community/toddle_communit_pyphome_1.jpg`;
export const COMMUNITY_DISCUSSION_EMPTY = `${CLOUD_URL}/assets/webapp/Community/no_discussions_yet.png`;
export const COMMUNITY_BANNER_BG_IMAGE = `${CLOUD_URL}/assets/webapp/Community/community_banner_bg_image.png`;
export const COMMUNITY_HOME_IMAGE = `${CLOUD_URL}/assets/webapp/Community/community_world.png`;
export const COMMUNITY_SIGN_UP_IMAGE = `${CLOUD_URL}/assets/webapp/Community/community_banner_new.png`;
export const PR_COMMENT_TEMPLATES_EMPTY = `${CLOUD_URL}/assets/webapp/PRCommentTemplateEmpty.png`;

export const BTN_CARD_ANNOUCMENT_ICON_URL = `${CLOUD_URL}/assets/webapp/BtnCardAnnouncement.svg`;
export const BTN_CARD_CALENDER_ICON_URL = `${CLOUD_URL}/assets/webapp/BtnCardCalendar.svg`;
export const BTN_CARD_CLASSROOM_REVIEW_ICON_URL = `${CLOUD_URL}/assets/webapp/BtnCardClassroomReview.svg`;
export const BTN_CARD_CLASSROOM_TODO_ICON_URL = `${CLOUD_URL}/assets/webapp/BtnCardClassroomTodo.svg`;

export const PROJECT_INTRODUCTION_ICON_URL = `${CLOUD_URL}/assets/webapp/Personal%2BProject.svg`;
export const PROJECT_SUPERVISOR_GUIDANCE_ICON_URL = `${CLOUD_URL}/assets/webapp/Supervisor%2BGuide.svg`;
export const PROJECT_STUDENT_GUIDANCE_ICON_URL = `${CLOUD_URL}/assets/webapp/Stutdent%2BGuide.svg`;
export const PROJECT_REPORT_TEMPLATE_ICON_URL = `${CLOUD_URL}/assets/webapp/Project%2Breport%2Btemplates.svg`;
export const PROJECT_ESTABLISH_DEADLINE_ICON_URL = `${CLOUD_URL}/assets/webapp/Deadline.svg`;
export const PROJECT_INVITE_ICON_URL = `${CLOUD_URL}/assets/webapp/Invite.svg`;

export const SCHOOL_ONBOARDING_CURRICULUM_SETUP_1 = `${CLOUD_URL}/assets/webapp/Home_Announcement_V3.svg`;
export const SCHOOL_ONBOARDING_CURRICULUM_SETUP_2 = `${CLOUD_URL}/assets/webapp/SchoolOnBoardingSetpsLogo.svg`;
export const SCHOOL_ONBOARDING_CURRICULUM_SETUP_3 = `${CLOUD_URL}/assets/webapp/SchoolOnBoardingSetpsLogo.svg`;

export const SETUP_TURNITIN_API_KEY_HELP_CENTER_URL = `${HELP_CENTER_URL}/en/articles/6006463-how-do-i-set-up-the-api-key-and-url-on-turnitin`;
export const transcriptHelpVideoImageUrl = `${CLOUD_URL}/thumber/fit-in/700x700/https://cloud.toddleapp.com/s/content/H1qa0PEAO.png`;

export const PLANATHON_UNIT_PLAN_SUBMISSION_MAX_LIMIT = 6;

export const POST_MEDIA_TYPES = {
  IMAGE: "photo",
  MULTI_IMAGE: "album",
  LINK: "link",
  FILE: "file",
  NOTE: "note",
  VIDEO: "video",
  AUDIO: "audio",
  WORKBOOK: "workbook",
};

export const PICKER_DIRECT_DOWNLOADABLE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "video/mp4",
  "video/avi",
  "video/wmv",
  "audio/mp3",
  "audio/mpeg",
  "audio/m4a",
  "audio/vnd.wav",
  "audio/wav",
  "audio/x-wav",
  "audio/wave",
];

export const userRoles = [
  { label: "Teacher", value: "teacher" },
  { label: "Admin", value: "admin" },
];

export const ASSESSMENT_TYPE_PARENT_GROUP_MAPPING = {
  THIRD_STAGE_ASSESSMENT: ["fmt", "pri"],
  SECOND_STAGE_ASSESSMENT: ["pt", "se"],
};

export const ROASTER_OPTIONS = [
  {
    label: "Manual and upload",
    value: "MANUALANDUPLOAD",
  },
  {
    label: "Sync with Wonde",
    value: "WONDE",
  },
  {
    label: "Sync with Veracross",
    value: "VERACROSS",
  },
  {
    label: "Sync with iSAMS",
    value: "ISAMS",
  },
  {
    label: "Sync with Power School",
    value: "POWERSCHOOL",
  },
  {
    label: "Sync with Engage",
    value: "ENGAGE",
  },
  {
    label: "Sync with Clever",
    value: "CLEVER",
  },
  {
    label: "Sync with Google sheets",
    value: "SHEET",
  },
];

export const LE_ASSESSMENT_TYPE_MAPPING = {
  dig: {
    color: colors.yellow20,
    label: "Diagnostic Assessment",
    localeLabel: "common:diagnostic_assessment",
    abbrevation: "Da",
    shortLabel: "common:diagnostic_assessment_short",
    bgColor: colors.blue29,
    icon: DiagnosticAssessmentColored,
  },
  fmt: {
    color: colors.blueTwo,
    label: "Formative Assessment",
    localeLabel: "common:formative_assessment",
    abbrevation: "Fa",
    shortLabel: "common:formative_assessment_short",
    bgColor: colors.violet63,
    icon: FormativeAssessmentColored,
    filledIcon: Formative,
    filledIconColor: colors.yellow50,
    outlinedIcon: FaOutlined,
    decorativeBGColor: colors.decorativeSurfaceThreeL,
    avatarIconColor: "yellow",
  },
  smt: {
    color: colors.blue29,
    label: "Summative Assessment",
    localeLabel: "common:summative_assessment",
    abbrevation: "Sa",
    shortLabel: "common:summative_assessment_short",
    bgColor: colors.teal20,
    icon: SummativeAssessmentColored,
    filledIcon: Summative,
    filledIconColor: colors.teal42,
    outlinedIcon: SaOutlined,
    decorativeBGColor: colors.decorativeSurfaceFiveL,
    avatarIconColor: "teal",
  },
  pri: {
    color: colors.yellow20,
    label: "Prior Assessment",
    localeLabel: "classRoom:pre_assessment",
    abbrevation: "Pa",
    shortLabel: "common:prior_assessment_short",
    bgColor: colors.blue29,
    icon: PriorAssessmentColored,
  },
  ong: {
    color: colors.blueTwo,
    label: "Ongoing Assessment",
    localeLabel: "common:ongoing_assessment",
    abbrevation: "Oa",
    shortLabel: "common:ongoing_assessment_short",
    bgColor: colors.teal20,
    icon: OngoingAssessmentColored,
  },
  le: {
    color: colors.yellow38,
    label: "Learning Experience",
    localeLabel: "common:le_label",
    abbrevation: "",
    icon: LearningExperienceColored,
    filledIcon: LEFilledIcon,
    filledIconColor: colors.violet63,
    outlinedIcon: LeOutlined,
    decorativeBGColor: colors.decorativeSurfaceTwoL,
    avatarIconColor: "violet",
  },
  qt: {
    color: colors.yellow38,
    label: "Quick Task",
    localeLabel: "common:quick_task",
    abbrevation: "Qt",
    icon: GeneralAssessmentColored,
    filledIcon: QuickTaskFilledIcon,
    filledIconColor: colors.blue40,
    outlinedIcon: QuickTaskOutlined,
    decorativeBGColor: colors.decorativeSurfaceOneL,
    avatarIconColor: "blue",
  },
  se: {
    color: colors.yellow20,
    label: "Supplementary Evidence",
    localeLabel: "common:supplementary_evidence",
    bgColor: colors.blue29,
    icon: SupplementaryEvidenceColored,
  },
  pt: {
    color: colors.yellow20,
    label: "Performance Task(s)",
    localeLabel: "common:performance_task",
    bgColor: colors.blue29,
    icon: PerformanceTasksColored,
  },
  ba: {
    color: colors.yellow20,
    label: "Benchmark Assessment",
    localeLabel: "common:benchmark_assessment",
    bgColor: colors.blue29,
    icon: BenchmarkAssessmentColored,
  },
  afl: {
    color: colors.yellow20,
    label: "Assessment For Learning",
    localeLabel: "common:assessment_for_learning",
    bgColor: colors.blue29,
  },
  aol: {
    color: colors.yellow20,
    label: "Assessment Of Learning",
    localeLabel: "common:assessment_of_learning",
    bgColor: colors.blue29,
  },
};

export const PLANNER_ELEMENT_NODE_LABEL_MAPPING = {
  MYP_ATL: "skill",
  UBD_ATL: "skill",
  MYP_GLOBAL_CONTEXT: "global context",
  MYP_RELATED_CONCEPT: "related concept",
  MYP_KEY_CONCEPT: "key concept",
  UBD_LEARNING_STANDARD: "standard",
  MYP_OBJECTIVES: "objective / strand",
};

export const ROLES = [
  { label: "PYP Coordinator", value: "admin" },
  { label: "Teacher", value: "teacher" },
];

export const SUBJECT_ICONS = [
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_english.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_hindi.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_music.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_math.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_science.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_french.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_socialscience.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_generic.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_physicaleducation.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_civics.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_dance.png",
  "https://cloud.toddleapp.com/s/content/SyioAglTV.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_physics.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_language.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_bio.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_arts.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_chemistry.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_spanish.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_geography.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_mandarin.png",
  "https://cloud.toddleapp.com/assets/webapp/fm_subject_icns/icon_subjects_history.png",
];

export const getGenderOptions = t => {
  return [
    { label: t("common:male"), value: "1" },
    { label: t("common:female"), value: "2" },
  ];
};

export const GENDER = [
  { label: "Male", value: "1" },
  { label: "Female", value: "2" },
];

export const IBIS_GENDER = [
  { label: "M", value: "1" },
  { label: "F", value: "2" },
  { label: "X", value: "3" },
];

export const NOTIFICATION_CATEGORY = [
  {
    label: "common:primary",
    value: "PRIMARY",
    userTypes: ["staff", "parent"],
    filterValue: ["PRIMARY"],
    paidOnly: false,
  },
  // primary tab with clubbed filter for student web
  {
    label: "common:primary",
    value: "PRIMARY",
    filterValue: ["PRIMARY", "UPDATES"],
    userTypes: ["student"],
    paidOnly: false,
  },
  {
    label: "common:meetings",
    value: "MEETINGS",
    filterValue: ["MEETINGS"],
    perm: "FeatureFlag:EnableMeetingsTabInNotification",
    userTypes: ["student"],
    paidOnly: false,
  },
  {
    label: "common:updates",
    value: "UPDATES",
    filterValue: ["UPDATES"],
    userTypes: ["staff"],
    paidOnly: false,
  },
  { label: "common:community", value: "COMMUNITY" },
  {
    label: "common:announcement_plural",
    value: "ANNOUNCEMENTS",
    filterValue: ["ANNOUNCEMENTS"],
    perm: "Common:Announcement",
    userTypes: ["staff", "parent"],
    paidOnly: true,
  },
  {
    label: "common:reports",
    value: "REPORTS",
    filterValue: ["REPORTS"],
    perm: "TeacherPortal:ProgressReport",
    userTypes: ["staff"],
    paidOnly: true,
  },
];

export const ORGANIZATION_PLANS = [
  { value: 6, label: "Demo" },
  { value: 1, label: "Free" },
  { value: 3, label: "Pro Pilot" },
  { value: 2, label: "Pro" },
  { value: 5, label: "360 Pilot" },
  { value: 4, label: "360" },
];

export const CURRICULUM_PLANS = [
  { value: "6", label: "Demo" },
  { value: "1", label: "Free" },
  { value: "3", label: "Pro Pilot" },
  { value: "2", label: "Pro" },
  { value: "5", label: "360 Pilot" },
  { value: "4", label: "360" },
];

export const PAID_PLAN_IDS = ["2", "4"];

export const CURRICULUM_PLANS_CONSTANTS_MAPPING = {
  PLAN_DEMO: "DEMO",
  PLAN_FREE: "FREE",
  PLAN_PRO_PILOT: "PRO_PILOT",
  PLAN_PRO: "PRO",
  PLAN_360_PILOT: "360_PILOT",
  PLAN_360: "360",
};

export const CURRICULUM_TYPE_ORDER_MAPPING = {
  [CURRICULUM_TYPE_PYP]: 0,
  [CURRICULUM_TYPE_MYP]: 1,
  [CURRICULUM_TYPE_UBD]: 2,
  [CURRICULUM_TYPE_DP]: 3,
};

export const CURRICULUM_PLAN_ORDER_MAPPING = {
  [PLAN_TYPE_FREE]: 0,
  [PLAN_TYPE_DEMO]: 1,
  [PLAN_TYPE_PRO_PILOT]: 2,
  [PLAN_TYPE_PRO]: 3,
  [PLAN_TYPE_360_PILOT]: 4,
  [PLAN_TYPE_360]: 5,
};

export const CURRICULUM_PLAN_LABEL_MAPPING_FOR_EXTERNAL_API = {
  [PLAN_TYPE_FREE]: "FREE",
  [PLAN_TYPE_DEMO]: "DEMO",
  [PLAN_TYPE_PRO_PILOT]: "PILOT_PRO",
  [PLAN_TYPE_PRO]: "PRO",
  [PLAN_TYPE_360_PILOT]: "PILOT_360",
  [PLAN_TYPE_360]: "360",
};

export const LANGUAGE_OPTIONS = [
  { label: "English", value: "en", perm: true },
  { label: "Spanish", value: "es", perm: true },
  {
    label: "Turkish",
    value: "tr",
    perm: true,
  },
];

export const POLLING_CONFIGURATION = {
  enabled: true,
  url: "https://ipv4.icanhazip.com",
  interval: 5000,
  timeout: 5000,
};

export const USER_TYPE_ENTITY_MAPPING = {
  staff: { entityType: "STAFF", type: "Staff" },
  student: { entityType: "STUDENT", type: "Student" },
  parent: { entityType: "FAMILY_MEMBER", type: "Parent" },
  super_admin: { entityType: "STAFF", type: "Staff" },
};

export const DUMMY_USER_INFO = {
  id: "-7",
  email: "public.access@toddleapp.com",
  token: dummyUserToken,
  org_id: "-7",
  jwt: dummyUserToken,
  user_type: "staff",
  userEntityType: "STAFF",
  portalType: "COMMUNITY",
  enabledMixPanel: true,
};

export const PUBLIC_USER_INFO = {
  id: "-8",
  email: "public.platform.access@toddleapp.com",
  token: publicUserToken,
  org_id: "-8",
  jwt: publicUserToken,
  user_type: "staff",
  userEntityType: "STAFF",
  portalType: "PUBLIC",
  enabledMixPanel: true,
};

export const SOCIAL_TYPES = {
  FACEBOOK: "FACEBOOK",
  TWITTER: "TWITTER",
  LINKEDIN: "LINKEDIN",
};

export const DEMO_MYP_ORG = demoMypOrg;

export const UNIT_TYPE_LOCALE = {
  ibInquiry: "common:transdisciplinary",
  ibStandAlone: "common:subject_specific",
  ibMYPSubjectSpecific: "common:subject_specific",
  ibMYPInterdisciplinary: "common:interdisciplinary",
  ubdInquiry: "common:interdisciplinary",
  ubdSubjectSpecific: "common:subject_specific",
  ibDPSubjectSpecific: "common:subject_specific",
};

export const SUMMARY_EMPTY_STATE_URL = `${CLOUD_URL}/assets/webapp/summary_empty_state.svg`;

export const COUNT_KEYS = {
  ASSIGNED: ["THIS_WEEK", "NO_DUE_DATE", "LATER"],
  OVERDUE: ["THIS_WEEK", "LAST_WEEK", "EARLIER"],
};

export const CONSOLIDATED_TODOS_COUNT_KEYS = ["UPCOMING", "OVERDUE", "NODUE"];

export const SIMILARITY_FILE_MIMETYPE = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/postscript",
  "text/rtf",
  "application/rtf",
  "text/html",
  "application/wordperfect",
  "application/vnd.oasis.opendocument.text",
  "text/plain",
  "application/x-hwp",
  "application/vnd.google-apps.presentation",
  "application/vnd.google-apps.spreadsheet",
  "application/vnd.google-apps.document",
];

export const SIMILARITY_ERROR_CODE_MAP = {
  UNSUPPORTED_FILETYPE: "classRoom:unsupported_file_format",
  CANNOT_EXTRACT_TEXT: "classRoom:word_count_less_than_20",
  TOO_LITTLE_TEXT: "classRoom:word_count_less_than_20",
  TOO_MANY_PAGES: "classRoom:file_pages_more_than_800",
  FILE_LOCKED: "classRoom:file_is_password_protected",
  CORRUPT_FILE: "classRoom:corrupt_file",
  ERR_FR_MAX_BODY_LENGTH_EXCEEDED: "classRoom:file_size_more_than_100",
  UNKNOWN_ERROR: "common:unknown_error",
  FAILED_TO_EXPORT: "classRoom:limited_access_or_file_not_found",
  CANNOT_REACH_TURNITIN: "classRoom:cannot_reach_turnitin",
};

export const CURRICULUM_NAME_BY_TYPE = {
  [CURRICULUM_TYPE_DP]: "common:DP",
  [CURRICULUM_TYPE_MYP]: "common:MYP",
  [CURRICULUM_TYPE_UBD]: "common:UBD",
  [CURRICULUM_TYPE_PYP]: "common:PYP",
};

export const REGISTRATION_CATEGORIES = {
  [CATEGORY_COURSE]: "common:dp_course",
  [CATEGORY_DIPLOMA]: "common:diploma",
};

export const FAMILY_INVITE_LANGUAGE_OPTIONS = [
  { label: "Arabic", value: "ar" },
  { label: "English", value: "en" },
  { label: "Kazakh", value: "kaz" },
  { label: "Mandarin", value: "mdr" },
  { label: "Spanish", value: "es" },
  { label: "Turkish", value: "tr" },
  { label: "Russian", value: "ru" },
  { label: "Japanese", value: "jpn" },
];

export const FAMILY_INVITE_EMAIL_LANGUAGE_OPTIONS = [
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  // { label: "Russian", value: "ru" },
  // { label: "Turkish", value: "tr" },
  // { label: "Arabic", value: "ar" },
  // { label: "Mandarin", value: "mdr" },
];

export const STUDENT_INVITE_LANGUAGE_OPTIONS = [
  { label: "English", value: "en" },
  { label: "Russian", value: "ru" },
];

export const encryptionKey = "TealLabs20!2";

export const ICONS = {
  AddOutlined: AddOutlined,
  UploadOutlined: UploadOutlined,
};

export const GRAPHQL_ONGOING_QUERY_NETWORK_STATUS = [1, 2];

export const ORGANIZATION_LOGO_PLACEHOLDER_URL =
  "https://cloud.toddleapp.com/assets/webapp/img_school_logo_placeholder.png";
