import PropTypes from "prop-types";
import React from "react";

const RotateIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24">
      <path
        d="M19.5 1.5C19.9142 1.5 20.25 1.83579 20.25 2.25V6.75C20.25 7.16421 19.9142 7.5 19.5 7.5H15C14.5858 7.5 14.25 7.16421 14.25 6.75C14.25 6.33579 14.5858 6 15 6H17.6627C14.4297 2.94535 9.33189 3.00074 6.16644 6.16619C4.38068 7.95194 3.58445 10.3517 3.7786 12.6876C3.81291 13.1004 3.50609 13.4629 3.0933 13.4972C2.68051 13.5315 2.31807 13.2247 2.28376 12.8119C2.05463 10.0551 2.99502 7.21629 5.10578 5.10553C8.86565 1.34566 14.9323 1.29851 18.75 4.96408V2.25C18.75 1.83579 19.0858 1.5 19.5 1.5ZM20.9068 10.5023C21.3195 10.4679 21.682 10.7747 21.7163 11.1875C21.9456 13.9444 21.0052 16.7834 18.8943 18.8942C15.1344 22.6541 9.06773 22.7012 5.25002 19.0356V21.75C5.25002 22.1642 4.91423 22.5 4.50002 22.5C4.0858 22.5 3.75002 22.1642 3.75002 21.75V17.25C3.75002 16.8358 4.0858 16.5 4.50002 16.5H9.00002C9.41423 16.5 9.75002 16.8358 9.75002 17.25C9.75002 17.6642 9.41423 18 9.00002 18H6.33763C9.57072 21.0544 14.6683 20.9989 17.8337 17.8336C19.6195 16.0477 20.4157 13.6479 20.2215 11.3118C20.1872 10.899 20.494 10.5366 20.9068 10.5023Z"
        fill={props.fill}
      />
    </svg>
  );
};

RotateIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: "#ffffff",
};

RotateIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default RotateIcon;
