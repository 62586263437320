import React from "react";

const ScrollDownSvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 40 40">
      <g strokeWidth="1">
        <g id="Group-26-Copy">
          <circle id="Oval" fill="#FFFFFF" cx="20" cy="20" r="20"></circle>
          <path
            d="M13.4912895,16.8414827 C13.0359912,16.3861845 12.2967088,16.3861845 11.8414466,16.8414466 C11.3861845,17.2967088 11.3861845,18.0359912 11.8414466,18.4912534 L19.1750129,25.8262196 C19.3984637,26.0474571 19.6943536,26.1674 19.9993,26.1674 C20.305338,26.1674 20.6013032,26.0476879 20.8231819,25.828016 L28.1585871,18.4912196 C28.6138155,18.0359912 28.6138155,17.2967088 28.1585534,16.8414466 C27.7032912,16.3861845 26.9640088,16.3861845 26.5087466,16.8414466 L19.9993361,23.3508572 L13.4912895,16.8414827 Z"
            id="Path"
            fill="#F75961"
          ></path>
        </g>
      </g>
    </svg>
  );
};

ScrollDownSvg.defaultProps = {
  height: 40,
  width: 40,
};

export default ScrollDownSvg;
