import React from "react";
import classes from "./CreateDropDown.scss";
import { ButtonDropdown, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import { ArrowIcon } from "SvgComponents";

const BUTTONS = [
  {
    value: "create",
    label: "create_new_with_label",
  },
  {
    value: "pick",
    label: "add_resource_bank",
  },
];

const buttonDropDownStyle = {
  containerStyle: {
    marginTop: 12,
  },
  itemStyle: {
    height: 40,
  },
  textStyle: {
    fontSize: 14,
  },
};

class CreateDropDown extends React.PureComponent {
  render() {
    const { clickAction, t, unitPlanId } = this.props;
    let menuItems = [];
    _.forEach(BUTTONS, (item, index) => {
      let label;
      if (item.label === "create_new_with_label") {
        label = t(`common:${item.label}`, {
          label: t("common:resource_label").toLowerCase(),
        });
      } else {
        label = t(item.label);
      }
      if (item.value == "pick" && !unitPlanId) {
        return;
      }
      menuItems.push({
        label,
        clickAction: () => clickAction(item.value),
        svg: null,
        isDisable: false,
        style: {
          borderWidth: index < item.length ? 0 : 1,
        },
      });
    });

    return (
      <div className={classes.container}>
        <ButtonDropdown
          authTabs={menuItems}
          containerStyle={buttonDropDownStyle.containerStyle}
          itemStyle={buttonDropDownStyle.itemStyle}
          textStyle={buttonDropDownStyle.textStyle}
          buttonComponent={
            <div className={classes.selectedValue}>
              <div className={classes.selectedText}>{t("common:add")}</div>
              <div className={classes.arrowContainer}>
                <ArrowIcon fill={colors.blue29} width={6} height={4} />
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(CreateDropDown);
