import React from "react";
import {
  Dropdown,
  DropdownMenu,
  ButtonDisclosure,
  Tooltip,
} from "@toddle-design/web";
import { AssessmentApplicableOutlined } from "@toddle-design/web-icons";
import { getIconFromTemplate } from "Assessments/modules/Utils";
import update from "immutability-helper";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { useI18n } from "Hooks";

const CustomDropdown = props => {
  const {
    updateInputField,
    fieldKey,
    fieldTypeConfig,
    options,
    onChangeDropDownMenuOption,
    label,
    isDisabled,
  } = props;

  const { t } = useI18n(["classRoom"]);

  const shouldStoreArrayValue = _.get(
    fieldTypeConfig,
    "shouldStoreArrayValue",
    false
  );

  const updatedOptions = _.map(options, option => {
    const innerOptions = _.get(option, "options", []);

    if (!_.isEmpty(innerOptions)) {
      const updatedInnerOptions = _.map(innerOptions, option => {
        const { icon } = option;
        return {
          ...option,
          icon: getIconFromTemplate(icon),
        };
      });
      return update(option, {
        options: {
          $set: updatedInnerOptions,
        },
      });
    } else {
      return option;
    }
  });

  const handleOnDropDownClick = e => {
    const { key, keyPath } = e;
    const params = {
      [`${fieldKey}`]: !_.isEmpty(keyPath) ? _.last(keyPath) : key,
    };

    if (shouldStoreArrayValue) {
      params[fieldKey] = [params[fieldKey]];
    }

    updateInputField?.(params, { nodes: options }, false);
    onChangeDropDownMenuOption?.({
      e,
      params,
    });
  };

  return (
    <ConditionalWrapper
      condition={isDisabled}
      wrapper={
        <Tooltip
          tooltip={t("classRoom:only_one_assessment_tool_can_be_added")}
        />
      }
      type="wrapperAsElement"
    >
      <div
        style={{
          display: "inline-block",
          cursor: "not-allowed",
        }}
      >
        <Dropdown
          overlay={
            <DropdownMenu
              options={updatedOptions}
              onClick={handleOnDropDownClick}
            />
          }
          disabled={isDisabled}
        >
          <ButtonDisclosure
            icon={<AssessmentApplicableOutlined />}
            size={"large"}
            variant={"outlined-subtle"}
            style={isDisabled ? { pointerEvents: "none" } : {}}
          >
            {label}
          </ButtonDisclosure>
        </Dropdown>
      </div>
    </ConditionalWrapper>
  );
};

export default CustomDropdown;
