import React from "react";

const SchoolPolicy = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 100 100">
      <g
        id="School-Policies"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <path
          d="M46.9897,92.6976 C48.8947,94.2396 51.6587,94.2396 53.5637,92.6976 L71.1277,78.4676 C82.7607,69.0426 89.4317,55.0506 89.4317,40.0796 L89.4317,21.7806 C89.4317,19.5006 87.9717,17.5026 85.8007,16.8076 L54.3857,6.7676 C53.0427,6.3376 51.6597,6.1226 50.2767,6.1226 C48.8937,6.1226 47.5107,6.3376 46.1657,6.7676 L14.7527,16.8076 C12.5797,17.5026 11.1207,19.5006 11.1207,21.7806 L11.1207,40.0796 C11.1207,55.0516 17.7927,69.0436 29.4247,78.4676 L46.9897,92.6976 Z M87.6647,10.9766 C92.3837,12.4856 95.5527,16.8276 95.5527,21.7806 L95.5527,40.0796 C95.5527,56.9056 88.0547,72.6316 74.9807,83.2236 L57.4167,97.4536 C55.3477,99.1296 52.8127,99.9676 50.2767,99.9676 C47.7407,99.9676 45.2057,99.1296 43.1357,97.4536 L25.5717,83.2236 C12.4977,72.6316 5.0007,56.9056 5.0007,40.0796 L5.0007,21.7806 C5.0007,16.8276 8.1697,12.4856 12.8877,10.9766 L44.3017,0.9366 C48.2117,-0.3124 52.3427,-0.3124 56.2507,0.9366 L87.6647,10.9766 Z M64.56,32.4256 C65.754,31.2306 67.693,31.2306 68.887,32.4256 C70.082,33.6206 70.082,35.5586 68.887,36.7536 L44.809,60.8316 C44.236,61.4056 43.457,61.7286 42.645,61.7286 C41.833,61.7286 41.055,61.4056 40.481,60.8316 L31.665,52.0156 C30.47,50.8206 30.47,48.8826 31.665,47.6876 C32.862,46.4916 34.799,46.4936 35.994,47.6876 L36.786,48.4796 L42.645,54.3396 L58.649,38.3366 L64.56,32.4256 Z"
          id="Combined-Shape"
        />
      </g>
    </svg>
  );
};

SchoolPolicy.defaultProps = {
  fill: "white",
  height: 20,
  width: 20,
};

export default SchoolPolicy;
