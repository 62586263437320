import React from "react";
import classes from "./VideoPlayer.scss";
import ReactPlayer from "react-player";

const VideoPlayer = React.memo(
  ({ videoUrl, isHLSUrl, autoPlay, getPlayerRef, onReady }) => {
    return (
      <div className={classes.container}>
        <ReactPlayer
          url={videoUrl}
          controls={true}
          className="reactPlayer"
          config={{ forceHLS: isHLSUrl }}
          playing={autoPlay}
          ref={ref => {
            if (getPlayerRef) {
              getPlayerRef(ref);
            }
          }}
          onReady={onReady}
        />
      </div>
    );
  }
);

VideoPlayer.displayName = "VideoPlayer";

VideoPlayer.defaultProps = {
  autoPlay: false,
};

export default VideoPlayer;
