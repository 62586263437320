import React, { useState } from "react";
import classes from "./PostSeenLikeModal.scss";
import { UIModal, I18nHOC } from "UIComponents";
import { IconButton, Tabs, Avatar } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";
import { graphql, compose } from "react-apollo";
import _ from "lodash";
import { AVATAR_COLORS } from "Constants/colorConstants";
import { getPostSeenLikesDetailsQuery } from "AppComponents/Post/modules/PostQuery";

const UserItem = props => {
  const { user, subText } = props;
  return (
    <div className={classes.userContainer}>
      <Avatar
        size={"small"}
        iconType={"individual"}
        color={AVATAR_COLORS[user?.id % AVATAR_COLORS.length]}
        src={user?.profileImage}
        shade={"light"}
      />
      <div className={classes.userDetailsContainer}>
        <div className={classes.usernameText}>
          {`${user?.firstName} ${user?.lastName}`}
        </div>
        <div className={classes.userSubText}>{subText}</div>
      </div>
    </div>
  );
};

const PostSeenLikeModal = props => {
  const {
    isModalOpen,
    closeSeenLikeModal,
    likes,
    views,
    likesCount,
    viewsCount,
    t,
  } = props;

  const TABS = [
    {
      label: t("journal:views_with_count", { count: viewsCount }),
      value: "views",
    },
    {
      label: t("journal:liked_with_count", { count: likesCount }),
      value: "liked",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(
    _.get(TABS[0], "value", "views")
  );

  let userList = [];
  if (selectedTab == "liked")
    userList = _.map(likes, like => _.get(like, "user", {}));
  else if (selectedTab == "views")
    userList = _.map(views, view => _.get(view, "user", {}));

  const getUserSubText = user => {
    const { students, t } = props;
    if (user.type == "staff") {
      return t("common:teacher");
    } else if (user.type == "parent") {
      const children = _.get(user, "children", []);
      const intersection = _.intersectionBy(children, students, "id") || [];
      let childrenString = "";
      if (intersection.length == 1) {
        childrenString = t("common:apostrophe", {
          name: _.get(intersection[0], "firstName", ""),
        });
      } else if (intersection.length == 2) {
        childrenString = t("journal:label_and_label_apostrophe", {
          nameOne: intersection[0].firstName,
          nameTwo: _.get(intersection[1], "firstName"),
        });
      } else if (intersection.length == 0) {
        childrenString = "";
      } else {
        const more = intersection.length - 2;
        childrenString = t("journal:label_count_more_student", {
          name: _.get(intersection[0], "firstName", ""),
          count: intersection.length - 2,
        });
      }

      return `${childrenString}Family`;
    } else if (user.type == "student") {
      return t("common:student");
    }
  };

  return (
    <UIModal isOpen={isModalOpen} modalContent={classes.modalContainer}>
      <div className={classes.modalBodyContainer}>
        <div className={classes.modalHeaderContainer}>
          <div className={classes.likeSeenTitle}>
            {t("journal:liked_views")}
          </div>
          <IconButton
            icon={<CloseOutlined />}
            onClick={closeSeenLikeModal}
            variant="plain"
          />
        </div>
        <div className={classes.tabsContainer}>
          <Tabs
            options={TABS}
            onChange={value => {
              setSelectedTab(value);
            }}
            value={selectedTab}
            type={"fixed"}
            name={"like_seen_tabs"}
          />
        </div>
        {(likesCount > 0 || viewsCount > 0) && (
          <div className={classes.taggedListContainer}>
            {_.map(userList, user => (
              <UserItem
                key={user.id}
                user={user}
                subText={getUserSubText(user)}
              />
            ))}
          </div>
        )}
      </div>
    </UIModal>
  );
};

export default compose(
  I18nHOC({ resource: ["journal"] }),
  graphql(getPostSeenLikesDetailsQuery, {
    name: "getPostSeenLikesDetails",
    options: ({ postId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: postId },
    }),
    props: ({ getPostSeenLikesDetails, ownProps: { postId } }) => {
      const post = _.get(getPostSeenLikesDetails, "node", {});
      const likes = _.map(_.get(post, "likes.edges", []), edge => edge.node);
      const likesCount = _.get(post, "likes.totalCount", 0);
      const views = _.map(_.get(post, "seen.edges", []), edge => edge.node);
      const viewsCount = _.get(post, "seen.totalCount", 0);
      const students = _.map(
        _.get(post, "students.edges", []),
        edge => edge.node
      );
      let templateId = "";
      if (post && post.unitPlan) {
        templateId = post.unitPlan.templateId;
      }
      return {
        getPostSeenLikesDetails,
        post,
        likes,
        likesCount,
        views,
        viewsCount,
        students,
        templateId,
        isData: !_.isEmpty(post),
        isLoading:
          getPostSeenLikesDetails["networkStatus"] == 1 ||
          getPostSeenLikesDetails["networkStatus"] == 2 ||
          getPostSeenLikesDetails["networkStatus"] == 4,
      };
    },
  })
)(PostSeenLikeModal);
