import PropTypes from "prop-types";
import React from "react";

const TickIcon = props => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 48 48"
      width={props.width}
      height={props.height}
    >
      <g id="Page-1">
        <g id="iPhone-7-Plus-Copy">
          <g id="Group-9">
            <path
              id="spam"
              fill={props.fill}
              d="M24,48A24,24,0,1,1,48,24,24.02416,24.02416,0,0,1,24,48ZM24,3.9999A20.0001,20.0001,0,1,0,44,24,20.02279,20.02279,0,0,0,24,3.9999Z"
            />
            <path
              id="status"
              fill={props.fill}
              d="M35.32416,16.58185a2.12292,2.12292,0,0,0-2.9202,0L29.19607,19.766v.0039l-8.22,8.16419-3.384-3.35609a1.627,1.627,0,0,0-.1761-.1119l-.8961-.888a2.12482,2.12482,0,0,0-2.916,0,2.03317,2.03317,0,0,0,.0039,2.8959l3.23609,3.216.024-.036,2.6481,2.63219a2.07146,2.07146,0,0,0,2.916,0L30.20017,24.566v.0039l5.124-5.09189a2.0429,2.0429,0,0,0,.6039-1.452A2.0183,2.0183,0,0,0,35.32416,16.58185Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

TickIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

TickIcon.defaultProps = {
  fill: "#7ed321",
  height: 24,
  width: 24,
};

export default TickIcon;
