import React from "react";
import PropTypes from "prop-types";

const PauseIcon = props => {
  return (
    <svg viewBox="0 0 10 14" width={props.width} height={props.height}>
      <g id="Group-36" fill={props.fill && props.fill}>
        <rect id="Rectangle" x="0" y="0" width="3" height="14" rx="1.5"></rect>
        <rect
          id="Rectangle-Copy-6"
          x="7"
          y="0"
          width="3"
          height="14"
          rx="1.5"
        ></rect>
      </g>
    </svg>
  );
};

PauseIcon.defaultProps = {
  height: 14,
  width: 10,
};
PauseIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default PauseIcon;
