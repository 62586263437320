import React from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";

const CommunityShareIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.89885 2.84742V3.30384L8.44433 3.34535C5.7662 3.58995 3.80294 4.56164 2.50088 6.18444C1.19071 7.81734 0.495391 10.1772 0.499884 13.2999C0.499998 13.379 0.525017 13.4172 0.546272 13.439C0.573099 13.4666 0.617993 13.491 0.67551 13.4981C0.733027 13.5051 0.782483 13.4923 0.815175 13.4719C0.841079 13.4558 0.874554 13.4249 0.893735 13.3481C1.31876 11.6481 2.14776 10.3182 3.42204 9.39736C4.68848 8.4822 6.34765 8.00506 8.37687 7.91579L8.89885 7.89283V8.41531V10.5C8.89885 10.6718 9.10114 10.7636 9.2304 10.6505L14.8297 5.75109C14.9207 5.67144 14.9207 5.52984 14.8297 5.45019L9.2304 0.550824C9.10114 0.437723 8.89885 0.529517 8.89885 0.701272V2.84742Z"
        stroke={props.fill}
      />
    </svg>
  );
};

CommunityShareIcon.defaultProps = {
  height: 15,
  width: 16,
  fill: colors.salmon60,
};

CommunityShareIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default CommunityShareIcon;
