import React, { PureComponent, Component } from "react";
import classes from "./Workbook.scss";
import PropTypes from "prop-types";
import {
  FileViewer,
  DialogueBox,
  UIModal,
  I18nHOC,
  FullScreenLoader,
} from "UIComponents";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import {
  getWorkBookUrl,
  getWorkBookMessagePayload,
  getAttachmentIdForUploadedAttachment,
  WORKBOOK_MODE_TYPES,
  WORKBOOK_IFRAME_ID_SELECTORS,
} from "Utils";
import { getAttachment } from "modules/Services";
import { setToastMsg } from "Login/modules/LoginModule";
import WorkbookIframePortal from "./WorkbookIframePortal";
import { GiveFeedbackIcon } from "SvgComponents";
import { WORK_BOOK_URL } from "Constants";
import { SNPv2_VISITOR_PORTAL_PERM_GROUP } from "Constants/permissionConstants";
import ACLStore from "lib/aclStore";
import _ from "lodash";

const styles = {
  modalBodyStyle: {
    marginTop: "0",
    padding: "8px 48px 48px 48px",
    flex: "0",
  },
  cancelButtonStyle: {
    flexBasis: "0%",
    marginRight: "0",
  },
  cancelButtonProps: {
    color: "transparent",
    type: "hollow",
    disableHover: true,
  },
};

const DIALOGUE_INFO = {
  DISCARD: {
    title: ({ t }) => {
      return t("common:discard_changes");
    },
    modalBody: ({ t }) => {
      return (
        <div className={classes.modalBody}>
          {t("classRoom:discard_feedback_warning_msg")}
        </div>
      );
    },
    confirmButtonText: t => t("common:discard_exit"),
    cancelButtonText: t => t("common:cancel"),
  },
};

class Workbook extends PureComponent {
  constructor(props) {
    super(props);

    //initialize variable
    this.loadWorkbookCachedVersion = ACLStore.can(
      "FeatureFlag:WorkbookPreloadWeb"
    );
    this.isWorkBookPreLoaded = false;
    this.isWorkbookIntialised = false;
    this.iframeRef = null;
    this.countLoadingEvents = 0;

    this.state = {
      currentDialogAction: null,
      showFullScreenLoader: true,
      isLoaded: false,
      waitForRef: false,
    };
    this.invokeWorkbookCustomLoader({ isLoading: true });
    if (this.loadWorkbookCachedVersion) {
      //check if workbook-global-iframe is already present
      const { iframeIdSelector, parentRef } = this.props;
      const getIframe = document.getElementById(iframeIdSelector);
      if (getIframe && _.get(getIframe, "src", "")) {
        this.isWorkBookPreLoaded = true;
        this.iframeRef = getIframe;
        this.isWorkbookIntialised = true;
      }
      // this.state.waitForRef = false;

      if (
        _.isEqual(
          iframeIdSelector,
          WORKBOOK_IFRAME_ID_SELECTORS["CLASSROOM"]
        ) &&
        (_.isNull(parentRef) || _.isNull(parentRef?.current))
      ) {
        // this.waitForRef = true;
        this.state.waitForRef = true;
        // this.setState({ waitForRef: true });
        this.iframeRef = null;
      }
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.handleMessageFromWorkbook, false);
    if (!this.loadWorkbookCachedVersion) return;
    if (this.state.waitForRef) {
      const { parentRef } = this.props;

      if (!_.isNull(parentRef) && !_.isNull(parentRef?.current)) {
        this.updateTheatreModeStyles();
        // this.waitForRef = false;
        if (this.state.waitForRef) {
          this.setState({ waitForRef: false });
        }
      } else {
        return;
      }
    }

    this.onWorkbookMount();
  }

  UNSAFE_componentWillUpdate() {
    if (!this.loadWorkbookCachedVersion) return;
    const { iframeIdSelector, parentRef } = this.props;

    if (
      _.isEqual(iframeIdSelector, WORKBOOK_IFRAME_ID_SELECTORS["CLASSROOM"])
    ) {
      this.updateTheatreModeStyles();
      if (!_.isNull(parentRef) && !_.isNull(parentRef?.current)) {
        // this.waitForRef = false;
        if (this.state.waitForRef) {
          this.setState({ waitForRef: false });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.loadWorkbookCachedVersion) {
      if (this.state.waitForRef) {
        const { parentRef } = this.props;

        if (!_.isNull(parentRef) && !_.isNull(parentRef?.current)) {
          this.updateTheatreModeStyles();
          // this.waitForRef = false;
          if (this.state.waitForRef) {
            this.setState({ waitForRef: false });
          }
        }
      }
      if (
        !_.isEqual(
          _.get(this.props, "attachment", {}),
          _.get(prevProps, "attachment", {})
        )
      ) {
        this.onWorkbookMount();
      }
    }
    if (
      !_.isEqual(
        _.get(prevState, "showFullScreenLoader"),
        _.get(this.state, "showFullScreenLoader")
      )
    ) {
      this.invokeWorkbookCustomLoader({
        isLoading: this.state.showFullScreenLoader,
      });
    }
  }

  invokeWorkbookCustomLoader = ({ isLoading }) => {
    const { invokeWorkbookCustomLoader } = this.props;
    if (invokeWorkbookCustomLoader) {
      invokeWorkbookCustomLoader({
        isLoading,
      });
    }
  };

  onWorkbookMount = () => {
    if (this.iframeRef && this.iframeRef.style) {
      this.updateTheatreModeStyles();
      this.iframeRef.style.display = "block";
      if (this.isWorkBookPreLoaded || this.isWorkbookIntialised) {
        this.handleMessageEventToWorkbook();
      }
      this.setState({ showFullScreenLoader: false, isLoaded: true });
      if (
        this.iframeRef &&
        !_.isEqual(document.activeElement, this.iframeRef)
      ) {
        this.iframeRef?.focus();
      }
    }
  };

  updateTheatreModeStyles = () => {
    const { iframeIdSelector } = this.props;
    if (
      _.isEqual(iframeIdSelector, WORKBOOK_IFRAME_ID_SELECTORS["CLASSROOM"])
    ) {
      const TheatreModeComponent = document.getElementById(
        WORKBOOK_IFRAME_ID_SELECTORS["THEATRE_MODE"]
      );
      if (TheatreModeComponent) {
        TheatreModeComponent.style.height = "0%";
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener(
      "message",
      this.handleMessageFromWorkbook,
      false
    );
    if (!this.loadWorkbookCachedVersion) return;
    //send clear canvas method to workbook
    this.sendMessage({ MESSAGE_TOKEN: "RESET_SETTINGS" });
    if (this.iframeRef && this.iframeRef.style) {
      this.iframeRef.style.display = "none";
      if (_.isEqual(document.activeElement, this.iframeRef)) {
        this.iframeRef?.blur();
      }
    }

    const { iframeIdSelector } = this.props;
    if (
      _.isEqual(iframeIdSelector, WORKBOOK_IFRAME_ID_SELECTORS["CLASSROOM"])
    ) {
      const TheatreModeComponent = document.getElementById(
        WORKBOOK_IFRAME_ID_SELECTORS["THEATRE_MODE"]
      );
      if (TheatreModeComponent) {
        TheatreModeComponent.style.height = "100%";
      }
    }
  }
  /* iframe messaging code starts here */
  // Send a message to the child iframe
  sendMessage = msg => {
    if (!_.isString(msg)) {
      msg = JSON.stringify(msg);
    }
    if (this.iframeRef) {
      this.iframeRef?.contentWindow?.postMessage(msg, "*");
    }
  };

  handleMessageEventToWorkbook = () => {
    const {
      attachment,
      workbookMode,
      imageOrientation,
      disableArr,
    } = this.props;
    const attachmentId = getAttachmentIdForUploadedAttachment({ attachment });
    const isLocalAttachment = _.startsWith(attachmentId, "NEW");
    const workbookUrl = _.get(attachment, "metadata.workbookUrl", "");
    if (!attachment || _.isEmpty(attachment) || _.isNull(attachment)) {
      this.sendMessage({ MESSAGE_TOKEN: "CLEAR_WORKBOOK" });
    }

    switch (workbookMode) {
      case WORKBOOK_MODE_TYPES["ADD_OR_EDIT_STUDENT_TEMPLATE"]:
      case WORKBOOK_MODE_TYPES["ADD_OR_EDIT_ATTACHMENT"]:
        this.sendMessage({
          MESSAGE_TOKEN: this.isWorkbookIntialised
            ? "LOAD_WORKBOOK"
            : "INIT_WORKBOOK",
          PAYLOAD: getWorkBookMessagePayload({
            mode: workbookMode,
            attachmentId: isLocalAttachment ? null : attachmentId,
            attachmentObj: isLocalAttachment
              ? _.omit(attachment, ["id", "attachmentId"])
              : {},
            workbookUrl,
          }),
        });
        break;

      default:
        this.sendMessage({
          MESSAGE_TOKEN: this.isWorkbookIntialised
            ? "LOAD_WORKBOOK"
            : "INIT_WORKBOOK",
          PAYLOAD: getWorkBookMessagePayload({
            imageOrientation,
            attachmentId,
            mode: workbookMode,
            disableArr,
            workbookUrl,
          }),
        });
        break;
    }
    if (!this.isWorkbookIntialised) {
      this.isWorkbookIntialised = true;
      if (
        this.iframeRef &&
        !_.isEqual(document.activeElement, this.iframeRef)
      ) {
        this.iframeRef?.focus();
      }
    }
  };

  handleMessageFromWorkbook = async e => {
    const {
      onDiscardDirectly,
      onUpdateSuccessfully,
      attachment,
      onAfterDiscardDirectly,
      updateAttachments,
      setToastMsg,
    } = this.props;
    const message = e.data;
    const attachmentData =
      _.first(_.get(attachment, "attachments", [])) || attachment;
    const attachmentId = getAttachmentIdForUploadedAttachment({
      attachment: attachmentData,
    });

    switch (message) {
      case "DISCARD":
        this.setDialogBoxState("DISCARD");
        break;

      case "DISCARD_DIRECTLY":
        if (onDiscardDirectly) {
          onDiscardDirectly();
        }
        if (onAfterDiscardDirectly) {
          onAfterDiscardDirectly();
        }
        break;

      case "UPDATE_SUCCESSFUL":
        if (onUpdateSuccessfully) {
          onUpdateSuccessfully();
        }
        if (attachmentId) {
          const refetchedAttachment = await getAttachment({ id: attachmentId });
          if (updateAttachments) {
            updateAttachments({
              oldAttachment: attachmentData,
              newAttachment: refetchedAttachment,
            });
          }
        }
        if (onDiscardDirectly) {
          onDiscardDirectly();
        }

        break;

      case "WORKBOOK_IS_LOADING":
        this.countLoadingEvents += 1;
        this.setState({ showFullScreenLoader: true });
        break;

      case "WORKBOOK_IS_LOADED":
        this.countLoadingEvents -= 1;
        if (this.countLoadingEvents <= 0) {
          this.setState({ showFullScreenLoader: false, isLoaded: true });
          this.countLoadingEvents = 0;
        }
        break;

      case "WORKBOOK_LOAD_FROM_LOCAL_DIALOG": {
        this.setState({ showFullScreenLoader: false, isLoaded: true });
        this.countLoadingEvents = 0;
        break;
      }

      case "WORKBOOK_INTERNAL_ERROR":
        setToastMsg("toastMsgs:something_went_wrong");
        if (onDiscardDirectly) {
          onDiscardDirectly();
        }
        break;

      default:
        try {
          // trying to fetch the object returned from workbook
          const val = message.slice(
            "NEW_ATTACHMENT".length + 1,
            message.length
          );
          const newAttachment = _.get(JSON.parse(val), "attachemnt_data", {});
          if (onUpdateSuccessfully) {
            onUpdateSuccessfully(_.omit(newAttachment));
          }
          if (updateAttachments) {
            if (!_.isEmpty(attachmentData)) {
              updateAttachments({
                oldAttachment: attachmentData,
                newAttachment,
              });
            }
          }
          if (onDiscardDirectly) {
            onDiscardDirectly();
          }
        } catch (e) {}
    }
  };
  /* iframe messaging code ends here */

  setDialogBoxState = currentDialogAction => {
    this.setState(prevState => ({
      currentDialogAction,
    }));
  };

  onCloseDialogBox = () => {
    this.setDialogBoxState(null);
  };

  onDialogConfirmClick = () => {
    const { onDiscardDirectly } = this.props;
    if (onDiscardDirectly) {
      onDiscardDirectly();
    }
  };

  getCorrectFileByWorkbookMode = () => {
    const {
      attachment,
      workbookMode,
      imageOrientation,
      disableArr,
    } = this.props;
    const attachmentData =
      _.first(_.get(attachment, "attachments", [])) || attachment;
    let file = {
      ...(attachmentData || {}),
    };
    const attachmentId = getAttachmentIdForUploadedAttachment({
      attachment: attachmentData,
    });
    const isLocalAttachment = _.startsWith(attachmentId, "NEW");

    /**Snp visitors can't open workbook using url as they don't have feature flags.
     * So, open workbook using public url
     */
    if (ACLStore.can(SNPv2_VISITOR_PORTAL_PERM_GROUP)) {
      return { ...file, url: _.get(attachmentData, "metadata.publicUrl", "") };
    }

    switch (workbookMode) {
      case WORKBOOK_MODE_TYPES["ADD_OR_EDIT_STUDENT_TEMPLATE"]:
      case WORKBOOK_MODE_TYPES["ADD_OR_EDIT_ATTACHMENT"]:
        file = {
          url: this.loadWorkbookCachedVersion
            ? WORK_BOOK_URL
            : getWorkBookUrl({
                mode: workbookMode,
                attachmentId: isLocalAttachment ? null : attachmentId,
                attachmentObj: isLocalAttachment
                  ? _.omit(attachment, ["id", "attachmentId", "isRead"])
                  : {},
              }),
          mimeType: "image/jpg",
          type: "IMAGE",
        };
        break;

      default:
        file = {
          ...file,
          url: this.loadWorkbookCachedVersion
            ? WORK_BOOK_URL
            : getWorkBookUrl({
                imageOrientation,
                attachmentId,
                mode: workbookMode,
                disableArr,
              }),
        };
        break;
    }

    return file;
  };

  iframeLoadEvent = iframeReference => {
    const { iframeIdSelector } = this.props;

    if (iframeReference) {
      this.iframeRef = iframeReference;
    } else {
      const getIframe = document.getElementById(iframeIdSelector);
      if (getIframe) {
        this.iframeRef = getIframe;
      }
    }

    this.handleMessageEventToWorkbook();
  };

  workbookCachedIframeComponent = () => {
    const {
      t,
      shouldShowGiveFeedbackButton,
      iframeIdSelector,
      parentRef,
    } = this.props;
    const { isLoaded, waitForRef } = this.state;
    return (
      <>
        {!waitForRef && !this.isWorkBookPreLoaded && (
          <WorkbookIframePortal
            onIframeLoad={this.iframeLoadEvent}
            iframeIdSelector={iframeIdSelector}
            parentRef={parentRef}
          />
        )}

        {shouldShowGiveFeedbackButton && isLoaded && (
          <div className={classes.container}>
            <div
              className={classes.feedbackButton}
              onClick={this.onClickGiveFeedback}
            >
              <GiveFeedbackIcon width={16} height={16} />
              <span className={classes.feedbackButtonText}>
                {t("common:give_feedback_text")}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  renderComponent = () => {
    const {
      t,
      shouldShowGiveFeedbackButton,
      invokeWorkbookCustomLoader,
    } = this.props;
    const { currentDialogAction, showFullScreenLoader, isLoaded } = this.state;

    return (
      <>
        {this.loadWorkbookCachedVersion ? (
          this.workbookCachedIframeComponent()
        ) : (
          <FileViewer
            file={this.getCorrectFileByWorkbookMode()}
            onClickGiveFeedback={this.onClickGiveFeedback}
            shouldShowGiveFeedbackButton={
              shouldShowGiveFeedbackButton && isLoaded
            }
          />
        )}

        {currentDialogAction && (
          <DialogueBox
            modalTitle={DIALOGUE_INFO[currentDialogAction].title({
              t,
            })}
            modalBody={DIALOGUE_INFO[currentDialogAction].modalBody({ t })}
            button1={DIALOGUE_INFO[currentDialogAction].cancelButtonText(t)}
            button2={DIALOGUE_INFO[currentDialogAction].confirmButtonText(t)}
            onClickButton1={this.onCloseDialogBox}
            toggleDialogueBoxDisplay={this.onCloseDialogBox}
            onClickButton2={this.onDialogConfirmClick}
            button1Props={styles.cancelButtonProps}
            button1Styles={styles.cancelButtonStyle}
            modalBodyStyle={styles.modalBodyStyle}
            size={"auto"}
          />
        )}

        {!invokeWorkbookCustomLoader && showFullScreenLoader && (
          <FullScreenLoader />
        )}
      </>
    );
  };

  onClickGiveFeedback = () => {
    const { setShowGiveFeedbackModal } = this.props;
    if (setShowGiveFeedbackModal) {
      setShowGiveFeedbackModal();
    }
  };

  render() {
    const { isFullScreen } = this.props;
    return (
      <>
        {isFullScreen ? (
          <UIModal isFullScreen={true} isOpen={true}>
            {this.renderComponent()}
          </UIModal>
        ) : (
          this.renderComponent()
        )}
      </>
    );
  }
}
const mapActionCreators = {
  setToastMsg,
};

Workbook.defaultProps = {
  attachment: {},
  workbookMode: WORKBOOK_MODE_TYPES["WORKBOOK_MULTIPAGE_ADD_OR_EDIT"],
  imageOrientation: 0,
  isFullScreen: true,
  disableArr: [],
  iframeIdSelector: WORKBOOK_IFRAME_ID_SELECTORS["DEFAULT"],
};

Workbook.propTypes = {
  workbookMode: PropTypes.string,
  onDialogConfirmClick: PropTypes.func,
  onUpdateSuccessfully: PropTypes.func,
  onDiscardDirectly: PropTypes.func,
  attachment: PropTypes.object,
  imageOrientation: PropTypes.number,
  isFullScreen: PropTypes.bool,
  disableArr: PropTypes.array,
  iframeIdSelector: PropTypes.string,
};
export default compose(
  connect(null, mapActionCreators),
  I18nHOC({ resource: "common" })
)(Workbook);
