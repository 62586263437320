import React from "react";
import classes from "./PlannerResourceSelection.scss";
import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";
import { withLoader, I18nHOC } from "UIComponents";
import classNames from "classnames";
import { AttachmentList } from "Attachment";
import { NoDataComponent } from "UIComponents";
import { getOrganizationResourcesFeedQuery } from "OrganizationResources/modules/OrganizationResourcesQuery";
import { getOrganizationResourcesFromCache } from "OrganizationResources/modules/OrganizationResourcesGraphqlHelpers";
import { getOrgResourcesAttachments } from "Utils";

const styles = {
  attachmentContainer: {
    marginTop: 16,
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    gridColumnGap: 24,
  },
};

const PlannerResourceSelection = React.memo(props => {
  const { resources, selectedItems, updateSelectedItems, t } = props;

  const isEmptyResources = _.size(resources) == 0;

  const containerStyle = classNames({
    [classes.container]: true,
    [classes.noDataContainer]: isEmptyResources,
  });

  return (
    <div className={containerStyle}>
      {isEmptyResources ? (
        <NoDataComponent
          emptyText={t("classRoom:no_resource_in_unit")}
          isSearch={false}
          emptyHeaderText={""}
        />
      ) : (
        <div className={classes.resourceCard}>
          <AttachmentList
            selectedItems={selectedItems}
            updateSelectedItems={updateSelectedItems}
            value={getOrgResourcesAttachments({ resources })}
            cardType={"horizontal"}
            selectedItemType={"OBJECT"}
            mode={"select"}
            attachmentContainerStyle={styles.attachmentContainer}
          />
        </div>
      )}
    </div>
  );
});

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    nodeId: ownProps.parentId,
    type: ownProps.parentType,
  };
};

const filters = { visibleTo: [], types: "PLANNER_RESOURCE" };

export default compose(
  I18nHOC({ resource: ["classRoom"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationResourcesFeedQuery, {
    name: "getOrganizationResourcesFeed",
    options: ({ nodeId, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        parentId: nodeId,
        parentType: type,
        types: filters.types,
        visibleTo: filters.visibleTo,
      },
    }),
    props({ getOrganizationResourcesFeed, ownProps: { nodeId, type } }) {
      const organizationResourcesData = getOrganizationResourcesFromCache({
        parentId: nodeId,
        parentType: type,
        filters,
      });
      return {
        isData: !_.isEmpty(organizationResourcesData),
        resources: _.get(
          organizationResourcesData,
          "organizationResources",
          []
        ),
        isLoading:
          getOrganizationResourcesFeed["networkStatus"] == 1 ||
          getOrganizationResourcesFeed["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(PlannerResourceSelection);
