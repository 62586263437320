import gql from "graphql-tag";
import { fragments } from "./Fragments";

export const removeCourseMutation = gql`
  mutation deleteCourse($id: ID!, $deletedBy: ID!) {
    platform {
      deleteCourse(input: { id: $id, deletedBy: $deletedBy }) {
        isSuccess
      }
    }
  }
`;

export const editCourseMutation = gql`
  mutation editCourse(
    $id: ID!
    $updatedBy: ID
    $title: String
    $grades: [String!]
    $subjectIds: [ID!]
    $profileImage: String
    $studentsToAdd: [ID!]
    $studentsToRemove: [ID!]
    $collaboratorsAddWithId: [ID!]
    $collaboratorsRemoveWithId: [ID!]
    $isArchived: Boolean
    $removedTags: [ID!]
    $addedTags: [ID!]
    $studentsWithSubjectLevel: [StudentsWithSubjectLevelInput!]
    $academicYears: UpdateArrayInput
  ) {
    platform {
      updateCourse(
        input: {
          id: $id
          title: $title
          grades: $grades
          updatedBy: $updatedBy
          subjectIds: $subjectIds
          profileImage: $profileImage
          studentsToAdd: $studentsToAdd
          studentsToRemove: $studentsToRemove
          collaboratorsAddWithId: $collaboratorsAddWithId
          collaboratorsRemoveWithId: $collaboratorsRemoveWithId
          isArchived: $isArchived
          removedTags: $removedTags
          addedTags: $addedTags
          studentsWithSubjectLevel: $studentsWithSubjectLevel
          academicYears: $academicYears
        }
      ) {
        ...courseItemDetail
      }
    }
  }
  ${fragments.courseDetails}
`;

export const addCourseMutation = gql`
  mutation addCourse(
    $title: String
    $grades: [String!]
    $isActive: Boolean
    $subjectIds: [ID!]
    $isClassroom: Boolean
    $studentsToAdd: [ID!]
    $profileImage: String
    $curriculumProgramId: ID
    $newStudents: [UserInput!]
    $collaboratorsAddWithId: [ID!]
    $tags: [ID!]
    $academicYears: [ID!]
    $subjectGroupIds: [ID!]
    $responseLanguageIds: [ID!]
    $subjectLevelIds: [ID!]
    $studentsWithSubjectLevel: [StudentsWithSubjectLevelInput!]
  ) {
    platform {
      addCourse(
        input: {
          title: $title
          grades: $grades
          isActive: $isActive
          subjectIds: $subjectIds
          isClassroom: $isClassroom
          newStudents: $newStudents
          profileImage: $profileImage
          studentsToAdd: $studentsToAdd
          curriculumProgramId: $curriculumProgramId
          collaboratorsAddWithId: $collaboratorsAddWithId
          tags: $tags
          academicYears: $academicYears
          subjectGroupIds: $subjectGroupIds
          responseLanguageIds: $responseLanguageIds
          subjectLevelIds: $subjectLevelIds
          studentsWithSubjectLevel: $studentsWithSubjectLevel
        }
      ) {
        ...courseItem
      }
    }
  }
  ${fragments.courseItem}
`;

export const sendCourseStudentsFamilyInviteCodeToTeacherMutation = gql`
  mutation sendCourseStudentsFamilyInviteCodeToTeacher($courseId: ID!) {
    platform {
      sendCourseStudentsFamilyInviteCodeToTeacher(courseId: $courseId)
    }
  }
`;
