import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";

export const MAXIMUM_ACRONYM_LENGTH = 4;

export const getCurriculumProgramName = ({ type }) => {
  switch (type) {
    case CURRICULUM_TYPE_PYP: {
      return "Primary Years Programme";
    }
    case CURRICULUM_TYPE_MYP: {
      return "Middle Years Programme";
    }
    case CURRICULUM_TYPE_UBD: {
      return "Understanding by Design";
    }
    case CURRICULUM_TYPE_DP: {
      return "Diploma Programme";
    }
  }
};

export const getCurriculumProgramAcronym = ({ type }) => {
  switch (type) {
    case CURRICULUM_TYPE_PYP: {
      return "PYP";
    }
    case CURRICULUM_TYPE_MYP: {
      return "MYP";
    }
    case CURRICULUM_TYPE_UBD: {
      return "UbD";
    }
    case CURRICULUM_TYPE_DP: {
      return "DP";
    }
  }
};
