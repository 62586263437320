import React from "react";
import classes from "./Footer.scss";
import { I18nHOC, ProfileCascade } from "UIComponents";

const profileCascadeStyle = {
  customStyle: {
    width: "80px",
    height: "80px",
    fontSize: "1.4rem",
  },
};

const Footer = ({ originalCreatedBy, t, goToCommunityRoute }) => {
  const { firstName = "", lastName = "", profileImage, id } =
    originalCreatedBy || {};
  const name = `${firstName} ${lastName}`;
  const schoolName = _.get(
    originalCreatedBy,
    "schoolTenures.0.school.name",
    ""
  );

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.leftContainer}>
          {t("common:originally_created_by")}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.userInfoCard}>
            <div className={classes.listProfile}>
              <ProfileCascade
                customStyle={profileCascadeStyle.customStyle}
                items={[{ imageUrl: profileImage, value: name }]}
              />
            </div>

            <div className={classes.content}>
              <div
                className={classes.name}
                onClick={() =>
                  goToCommunityRoute({
                    route: `profile/${id}`,
                    isOpenInNewTab: true,
                  })
                }
              >
                {name}
              </div>
              <div className={classes.schoolName}>{schoolName}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["common"] })(Footer);
