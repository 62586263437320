import React from "react";
import PropTypes from "prop-types";
import { isSafari } from "Utils";
import _ from "lodash";

export default class OauthPopup extends React.PureComponent {
  closePopUp = () => {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  };
  createPopup = () => {
    const { url, title, width, height, onCode, onAccessDenied } = this.props;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const isSafariOrNot = isSafari();
    this.externalWindow = window.open(
      url,
      "_blank",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    this.codeCheck = setInterval(() => {
      try {
        const params = new URL(_.get(this.externalWindow, "location"))
          .searchParams;
        const code = params.get("code");
        const error = params.get("error");

        if (!error && !code) {
          return;
        }
        if (!_.isEqual(error, "access_denied")) {
          onCode(code, params);
        } else if (_.isEqual(error, "access_denied")) {
          onAccessDenied();
        }
        if (isSafariOrNot) {
          this.closePopUp();
        } else {
          setTimeout(() => this.closePopUp(), 350);
        }
        clearInterval(this.codeCheck);
      } catch (e) {}
    }, 20);

    if (this.externalWindow) {
      this.externalWindow.onpagehide = e => {
        if (isSafariOrNot && e.persisted) {
          this.props.onClose();
          clearInterval(this.codeCheck);
        }
      };
    }
    if (this.externalWindow) {
      this.externalWindow.onbeforeunload = () => {
        if (!isSafariOrNot) {
          this.props.onClose();
          clearInterval(this.codeCheck);
        }
      };
    }
  };

  render() {
    return <div onClick={this.createPopup}> {this.props.children} </div>;
  }

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }
}

OauthPopup.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onCode: PropTypes.func,
};

OauthPopup.defaultProps = {
  onClose: () => {},
  width: 500,
  height: 500,
  url: "",
  title: "",
};
