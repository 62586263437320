import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  memo,
  useRef,
} from "react";

import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import {
  getUserThirdPartyAuthorizedStatus,
  generateThirdPartyRefreshToken,
} from "modules/Services";
import {
  GOOGLE_CLIENT_ID,
  ZOOM_CLIENT_ID,
  CLEVER_CLIENT_ID,
  LEX_CLIENT_ID,
  LEX_CODE_CHALLENGE,
  LEX_SSO_URL,
  CLASSLINK_SSO_URL,
} from "Constants";
import { FullScreenLoader, OauthPopup } from "UIComponents";
import ACLStore from "lib/aclStore";
import { setToastMsg } from "Login/modules/LoginModule";
const redirectUri = encodeURI(window.location.origin);

const SERVICES = {
  GOOGLE: {
    getAuthUrl: ({ userType } = {}) =>
      !ACLStore.can("FeatureFlag:CalendarGoogleMeet") || userType != "staff"
        ? `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/drive https%3A//www.googleapis.com/auth/userinfo.email
  &access_type=offline&response_type=code&redirect_uri=${redirectUri}&client_id=${GOOGLE_CLIENT_ID}&prompt=consent&cookiePolicy=single_host_origin`
        : `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/drive https%3A//www.googleapis.com/auth/userinfo.email https%3A//www.googleapis.com/auth/calendar
  &access_type=offline&response_type=code&redirect_uri=${redirectUri}&client_id=${GOOGLE_CLIENT_ID}&prompt=consent&cookiePolicy=single_host_origin`,
    refreshToken: true,
  },
  ZOOM: {
    getAuthUrl: () =>
      `https://zoom.us/oauth/authorize?client_id=${ZOOM_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=meeting:write+user:read`,
    refreshToken: true,
  },
  CLEVER: {
    getAuthUrl: () =>
      `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${CLEVER_CLIENT_ID}`,
    refreshToken: false,
  },
  LEX: {
    getAuthUrl: () => LEX_SSO_URL,
    refreshToken: false,
  },
  CLASSLINK: {
    getAuthUrl: () => CLASSLINK_SSO_URL,
    refreshToken: false,
  },
};

const ThirdPartyAuth = (props, ref) => {
  const {
    serviceType,
    onSuccessCallBack,
    getUserThirdPartyAuthorizedStatus,
    generateThirdPartyRefreshToken,
    userType,
  } = props;
  const authRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    onAuthClick: async ({ forceAuthorized = false } = {}) => {
      const { refreshToken, getAuthUrl } = _.get(SERVICES, serviceType, {});

      if (refreshToken) {
        setIsLoading(true);
        const authrizeStatus = forceAuthorized
          ? false
          : await getUserThirdPartyAuthorizedStatus({
              serviceType,
            });
        setIsLoading(false);
        if (!!authrizeStatus) {
          const accessToken = _.get(authrizeStatus, "accessToken");
          if (accessToken) {
            if (onSuccessCallBack) {
              onSuccessCallBack({ accessToken });
            }
            return;
          }
        }
      }

      if (!!authRef && !!authRef.current) {
        authRef.current.createPopup();
      }
    },
  }));

  const onAuthCallBack = async accessCode => {
    const { refreshToken } = _.get(SERVICES, serviceType, {});
    if (refreshToken) {
      setIsLoading(true);

      const response = await generateThirdPartyRefreshToken({
        accessCode,
        serviceType,
        redirectUri,
      });
      // console.log("response", response);
      const { accessToken } = response || {};
      setIsLoading(false);
      if (accessToken && onSuccessCallBack) {
        onSuccessCallBack({ accessToken });
      }
    } else {
      onSuccessCallBack({ accessCode, redirectUri });
    }
  };

  const onAccessDenied = () => {
    const { setToastMsg } = props;
    setToastMsg({
      msg: "toastMsgs:enable_dynamic_permission",
      locale_params: [
        { key: "permission", value: "google drive access", isPlainText: true },
      ],
    });
  };
  const { getAuthUrl } = _.get(SERVICES, serviceType, {});

  return (
    <React.Fragment>
      <OauthPopup
        url={getAuthUrl({ userType })}
        onCode={onAuthCallBack}
        onAccessDenied={onAccessDenied}
        ref={authRef}
      ></OauthPopup>
      {isLoading && <FullScreenLoader />}
    </React.Fragment>
  );
};

const mapActionCreators = {
  getUserThirdPartyAuthorizedStatus,
  generateThirdPartyRefreshToken,
  setToastMsg,
};

const mapStateToProps = state => {
  return {
    userType: _.get(state, "login.userInfo.user_type"),
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators, null, {
    withRef: true,
  })
)(memo(forwardRef(ThirdPartyAuth)));
