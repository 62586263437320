import React, { useState, useEffect } from "react";
import classes from "./ClassCardV2.scss";
import { I18nHOC, UIModal, DateSelector, TimeSelectorV2 } from "UIComponents";
import TaggedStudents from "../TaggedStudents";
import AddStudentsModal from "../AddStudentsModal";
import moment from "moment";
import { IconButton, Tooltip } from "@toddle-design/web";
import {
  DeleteOutlined,
  CalendarScheduleOutlined,
} from "@toddle-design/web-icons";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { getScheduleOrDeadlineTime } from "ClassRoom/modules/utils";

const styles = {
  timeSelectorDropdownContainerStyle: {
    width: "100%",
  },
  timeSelectorDropdownItemStyle: {
    display: "flex",
    width: "100%",
  },
  dateSelectorContainerStyle: {
    flex: 0,
    width: "136px",
    padding: "0",
    height: "48px",
  },
  timeComponentStyle: {
    padding: "0px",
    height: "48px",
  },
  dropdownParentStyle: {
    width: "136px",
  },
};

const defaultStartTime = "09:00 am";

const ClassCard = React.memo(props => {
  const {
    course,
    updateItem,
    courseId,
    onClickRemoveClass,
    t,
    portalType,
    setDisableButton,
    mode,
    assignmentId,
    assignmentTags,
    courseStudents,
  } = props;

  const { id, title, students, deadlineDate, deadlineTime } = course;
  const [showAddStudentsModal, changeShowAddStudentsModal] = useState(false);
  const selectedStudentsCount = _.get(students, "length", 0);
  const isAllStudentsSelected = selectedStudentsCount == _.size(courseStudents);

  useEffect(() => {
    // if both assignment tags are selected and selected students have only 1 tag,
    // then without changing the current selection, add all the students having other tag
    // to the selected students
    if (_.size(assignmentTags) === 2) {
      const selectedStudentsIds = _.map(students, student => student?.id);

      const studentTags = _.filter(courseStudents, student =>
        _.includes(selectedStudentsIds, student?.id)
      ).map(student => student?.tags?.[0]?.label);
      const uniqueStudentTags = [...new Set(studentTags)];

      if (_.size(uniqueStudentTags) === 1) {
        const studentsToBeAdded = _.filter(
          courseStudents,
          student => student?.tags?.[0]?.label !== uniqueStudentTags[0]
        ).map(student => _.omit(student, "tags"));

        if (_.size(studentsToBeAdded) > 0) {
          const newIncludedStudents = [...students, ...studentsToBeAdded];
          updateItem({ students: newIncludedStudents });
        }
      }
    } else if (_.size(assignmentTags) === 1 && selectedStudentsCount === 0) {
      updateItem({ students: courseStudents });
    }
  }, [students, assignmentTags]);

  const showTooltip = _.size(title) > 15;

  const { startTime } = getScheduleOrDeadlineTime({
    date: deadlineDate,
    defaultStartTime,
  });

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardInnerContainer}>
        <ConditionalWrapper
          condition={showTooltip}
          wrapper={<Tooltip tooltip={title} placement={"bottom"} />}
          type="wrapperAsElement"
        >
          <div className={classes.classNameLabel}>{title}</div>
        </ConditionalWrapper>
        <div
          className={classes.taggedStudentsContainer}
          onClick={() => changeShowAddStudentsModal(true)}
        >
          <TaggedStudents
            taggedStudents={students}
            isAllStudentsSelected={isAllStudentsSelected}
            showAvatarGroup={true}
            showTooltip={false}
          />
        </div>

        <div className={classes.dateTimeContainer}>
          <div className={classes.dateContainer}>
            <DateSelector
              dateSelectorContainerStyle={styles.dateSelectorContainerStyle}
              updateInputField={updateItem}
              value={deadlineDate}
              name={"deadlineDate"}
              isValueMomentObj={false}
              minDate={moment().subtract(1, "day")}
              placeholder={t("classRoom:due_date")}
              customInputIcon={
                <CalendarScheduleOutlined size="xx-small" variant="subtle" />
              }
            />
          </div>
          <div clasname={classes.timeContainer}>
            <TimeSelectorV2
              value={deadlineTime}
              SelectedTimeformat="HH:mm"
              name={"deadlineTime"}
              isValueMomentObj={false}
              updateInputField={updateItem}
              isDisabled={_.isEmpty(deadlineDate)}
              timeComponentStyle={{
                ...styles.timeComponentStyle,
                overflow: _.isEmpty(deadlineDate) ? "hidden" : "unset",
              }}
              dropdownParentStyle={styles.dropdownParentStyle}
              disableConfirmButton={setDisableButton}
              inputStyle={{ padding: "16px 20px" }}
              placeholder={t("common:due_time")}
              startTime={startTime}
            />
          </div>
        </div>
        <div className={classes.deleteClassBtn}>
          {courseId !== id && (
            <IconButton
              icon={<DeleteOutlined size={"xx-small"} variant={"subtle"} />}
              onClick={() => onClickRemoveClass(id)}
              size={"large"}
              variant={"outlined-subtle"}
            />
          )}
        </div>
      </div>
      {showAddStudentsModal && (
        <UIModal
          isOpen={showAddStudentsModal}
          modalContent={classes.addClassModalContainer}
          onRequestClose={() => changeShowAddStudentsModal(false)}
        >
          <AddStudentsModal
            students={students}
            onClickCancel={() => changeShowAddStudentsModal(false)}
            courseId={id}
            disableUpdateOnEmpty={true}
            onClickAdd={value => {
              updateItem({ students: value });
            }}
            portalType={portalType}
            mode={mode}
            assignmentId={assignmentId}
            assignmentTags={assignmentTags}
          />
        </UIModal>
      )}
    </div>
  );
});

ClassCard.displayName = ClassCard;

export default I18nHOC({ resource: ["common", "classRoom"] })(ClassCard);
