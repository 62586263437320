import React, { useState } from "react";
import classes from "./ModalComponent.scss";
import { Button, MediaCard, RadioButton, EmptyState } from "@toddle-design/web";
import { useI18n } from "Hooks";
import { NoSearchResultsIllustration } from "@toddle-design/theme";

const styles = {
  emptySubTextStyle: { whiteSpace: "pre-line" },
};

const MediaCardComponent = ({
  item,
  selectedAttachmentValue,
  onSelectAttachment,
}) => {
  const { id, name = "", thumbUrl, url, type, mimeType } = item;

  const isSelected = _.includes(selectedAttachmentValue, id);

  return (
    <MediaCard
      cardType={"medium"}
      name={name}
      thumbUrl={thumbUrl}
      url={url}
      type={type}
      mimeType={mimeType}
      onClick={() => onSelectAttachment({ value: id })}
      isActivated={isSelected}
      options={
        <RadioButton
          value={id}
          checked={isSelected}
          onChange={e => onSelectAttachment({ value: e.target.value })}
        />
      }
    />
  );
};

const ModalComponent = props => {
  const {
    updateValue,
    toggleModal,
    attachmentList,
    modalConfig: {
      label = "",
      subText = "",
      modalEmptySubText = "",
      modalEmptyTitle = "",
    },
  } = props;

  const { t } = useI18n(["project", "common"]);
  const [selectedAttachmentValue, setSelectedAttachmentValue] = useState([]);

  const onSelectAttachment = ({ value }) => {
    const newSelectedAttachment = value ? [value] : [];
    setSelectedAttachmentValue(newSelectedAttachment);
  };

  const onSelectClick = () => {
    updateValue(selectedAttachmentValue, {
      value: _.filter(attachmentList, item =>
        _.includes(selectedAttachmentValue, _.get(item, "id", ""))
      ),
    });
    toggleModal();
  };

  return (
    <>
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={"heading-4"}> {label}</div>
          <div className={"text-body-2"}>{subText}</div>
        </div>

        {!_.isEmpty(attachmentList) ? (
          <div className={classes.modalBody}>
            <div className={classes.modalInnerContainer}>
              {_.map(attachmentList, item => {
                return (
                  <MediaCardComponent
                    key={_.get(item, "id", "")}
                    item={item}
                    onSelectAttachment={onSelectAttachment}
                    selectedAttachmentValue={selectedAttachmentValue}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className={classes.emptyStateContainer}>
            <EmptyState
              illustration={NoSearchResultsIllustration}
              title={modalEmptyTitle}
              subtitle={modalEmptySubText}
              subtitleStyle={styles.emptySubTextStyle}
              size="small"
            />
          </div>
        )}
        <div className={classes.modalFooter}>
          <Button variant={"dismissive"} size={"large"} onClick={toggleModal}>
            {t("common:cancel")}
          </Button>
          <Button
            variant={"progressive"}
            size={"large"}
            disabled={_.isNull(selectedAttachmentValue)}
            onClick={onSelectClick}
          >
            {t("common:select")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ModalComponent;
