import React from "react";
import classes from "./InviteBox.scss";
import { UIButton, I18nHOC } from "UIComponents";

const InviteBox = React.memo(props => {
  const { onClickInvite, t } = props;
  return (
    <div className={classes.container}>
      <h4>
        <div className={classes.dividerText}>{t("common:or")}</div>
      </h4>
      <div className={classes.inviteBox}>
        <div className={classes.details}>
          <div className={classes.header}>{t("unitPlan:invite_to_toddle")}</div>
          <div className={classes.subText}>
            {t("unitPlan:invite_teacher_to_collaborate_msg")}
          </div>
        </div>
        <div className={classes.footer}>
          <UIButton color={"pink"} type={"hollow"} onClick={onClickInvite}>
            {t("unitPlan:invite_team_member")}
          </UIButton>
        </div>
      </div>
    </div>
  );
});

export default I18nHOC({ resource: ["unitPlan", "common"] })(InviteBox);
