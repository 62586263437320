import React from "react";
import classes from "./FilterHeader.scss";

import { ArrowIcon } from "SvgComponents";
import { SearchBar } from "UIComponents";

const SearchInputStyle = {
  customStyle: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderRadius: 0,
  },
  activeCustomStyle: {
    borderBottomWidth: 2,
    paddingBottom: 12,
  },
};

class FilterHeader extends React.PureComponent {
  onFilterClick = ({ key, value }) => {
    this.props.updateFilters({ key, value });
  };

  onSearchChange = value => {
    this.props.updateFilters({ key: "searchText", value });
  };

  closeSearchInput = () => {
    if (this.SearchBar) {
      this.SearchBar.closeSearchInput();
    }
  };

  render() {
    const { filters } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.filterContainer}>
          <div className={classes.leftContainer}>
            <SearchBar
              data-test-id="search-bar-organizations"
              placeholder={"Search organization"}
              searchTerm={filters.searchText}
              changeSearchTerm={this.onSearchChange}
              customStyle={SearchInputStyle.customStyle}
              activeCustomStyle={SearchInputStyle.activeCustomStyle}
              ref={ref => (this.SearchBar = ref)}
              shouldAnimate={true}
            />
          </div>
          <div className={classes.rightcontainer} />
        </div>
        {filters.searchText && (
          <div
            className={classes.searchText}
          >{`Search results for "${filters.searchText}"`}</div>
        )}
      </div>
    );
  }
}

export default FilterHeader;
