import moment from "moment";
import { ATTENDANCE_CONSTANTS } from "AttendanceDashboard/constants/stringConstants";

export const DAYS_IN_ORDER = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const getWeekDaysOptions = () => {
  const localLocale = moment();
  localLocale.locale("en");
  const startOfWeekLocal = localLocale.startOf("isoWeek"),
    startOfWeek = moment().startOf("isoWeek"),
    options = [];
  let count = 1,
    currVal,
    currLabel;
  while (count <= 7) {
    currLabel = startOfWeek.format("ddd");
    currVal = startOfWeekLocal.format("ddd");
    options.push({
      label: currLabel,
      value: currVal,
    });
    startOfWeek.add(1, "day");
    startOfWeekLocal.add(1, "day");
    count++;
  }
  return options;
};

export const getAcademicYearLabel = ({ startDate, endDate }) => {
  return (
    formatDate({ date: startDate, format: "YYYY" }) +
    "-" +
    formatDate({ date: endDate, format: "YY" })
  );
};

export const getWeekDaysArr = () => {
  const localLocale = moment();
  //localLocale.locale("en");
  const startOfWeek = localLocale.startOf("week"),
    options = [];
  let count = 1,
    currVal;
  while (count <= 7) {
    currVal = startOfWeek.format("ddd");
    options.push(currVal);
    startOfWeek.add(1, "day");
    count++;
  }
  return options;
};

export const getWeekStartDate = ({ startDate, weekStartDay, weekLength }) => {
  const currentDate = moment(startDate);
  let weekStartDate = moment(currentDate)
    .startOf("week")
    .add(weekStartDay, "day");

  const today = moment(currentDate).day();

  if (today < weekStartDay) {
    weekStartDate = moment(weekStartDate).subtract(1, "week");
  }
  const weekEndDate = moment(weekStartDate).add(weekLength - 1, "day");

  if (currentDate.diff(weekEndDate, "day") > 0) {
    weekStartDate = moment(weekStartDate).add(1, "week");
  }

  return weekStartDate;
};

export const isDayOutSideRange = ({ day, maxDate, minDate }) => {
  if (!!maxDate && !!minDate) {
    return !day.isBetween(minDate, maxDate, "days", "[]");
  } else if (!!maxDate && !minDate) {
    //no min date
    return !day.isSameOrBefore(maxDate);
  } else if (!maxDate && !!minDate) {
    return !day.isSameOrAfter(minDate);
  } else {
    return false;
  }
};

export const isDayBlocked = ({ date, operationalDays }) => {
  return !_.includes(operationalDays, moment(date).locale("en").format("ddd"));
};

export const formatDate = ({ date = new Date(), format = "DD-MM-YYYY" }) => {
  return moment(date).format(format);
};

export const getValidTimeIn12HourFormat = ({ inputStr, minuteStep }) => {
  /**
    Util function to handle all the cases like:
    input not containing numbers and incomplete input
  */
  const minuteArr = [];
  for (let i = 0; i <= 60; i += minuteStep) {
    minuteArr.push(i < 10 ? `0${i}` : `${i}`);
  }
  const inputWithOnlyDigits = _.replace(inputStr, /[^\d]/g, "");
  const inputWithDigitsAndColons = _.replace(inputStr, /[^\d:]/g, "");
  const firstTwoDigits = inputWithOnlyDigits.substring(0, 2);
  let hourStr = "";
  let minuteStr = "";
  let isAM = !_.includes(_.toUpper(inputStr), "P");
  if (!inputWithDigitsAndColons) {
    // if input string has no digits or colon, it is invalid
    return null;
  }
  if (_.includes(inputStr, firstTwoDigits) && _.parseInt(firstTwoDigits) < 25) {
    // hour has 2 digits and it is in the range of 01 to 24
    hourStr = inputWithOnlyDigits.substring(0, 2);
    minuteStr = inputWithOnlyDigits.substring(2, 4);
  } else {
    // hour has just one digit or it is more than 24
    hourStr = "0" + inputWithOnlyDigits.substring(0, 1);
    minuteStr = inputWithOnlyDigits.substring(1, 3);
  }
  if (_.parseInt(minuteStr) >= 60) {
    // minute value should be between 00 and 59
    minuteStr = "00";
  }
  if (!hourStr || hourStr == "00") {
    hourStr = "12";
  }
  if (_.parseInt(hourStr) > 12) {
    // converting hourStr in the range of 01 to 12
    hourStr = (_.parseInt(hourStr) - 12).toString();
    isAM = false;
  }
  if (!minuteStr) {
    minuteStr = "00";
  }
  // appending 0 at the front of hour and minute if necessary
  hourStr = ("0" + hourStr).slice(-2);
  minuteStr = ("0" + minuteStr).slice(-2);
  return `${hourStr}:${minuteStr} ${isAM ? "am" : "pm"}`;
};

export const getValueInAM_PMFormat = value => {
  /**
    Converts a time string input to a 
    12-hour time format string 
  */
  if (_.isEmpty(value)) {
    return "09:00 am";
  }
  const isValueIn24HourFormat = !(value.length > 5);
  const correctValue = isValueIn24HourFormat
    ? moment(value, "H:mm").format("hh:mm a")
    : value;
  return correctValue;
};

export const getNearestTimeFromGivenArray = ({
  val,
  minuteArr,
  selectedTimeFormat = "hh:mm a",
}) => {
  /** 
    Returns the current or the next greater value of time label
    for example:
    input: 10:05 am, minuteStep = 15, output: 10:15 am
    input: 10:15 am, minuteStep = 15, output: 10:15 am
  */
  const minuteVal = _.parseInt(val.substring(3, 5));
  const nextVal = _.first(_.filter(minuteArr, minute => minute >= minuteVal));
  let diff = 0;
  if (nextVal || nextVal === 0) {
    diff = nextVal - minuteVal;
  } else {
    diff = 60 - nextVal;
  }
  const newVal = moment(val, selectedTimeFormat)
    .add(diff, "minutes")
    .format(selectedTimeFormat);
  return newVal;
};

export const getDayNameOrIndex = (value, type = "index") => {
  const mappings = [
    [0, "sunday"],
    [1, "monday"],
    [2, "tuesday"],
    [3, "wednesday"],
    [4, "thursday"],
    [5, "friday"],
    [6, "saturday"],
  ];

  const match = _.find(mappings, ([day, name]) => {
    return type === "index" ? day === value : name === value;
  });
  return type === "index" ? match[1] : match[0];
};

export const getDaysNameOrIndexArray = ({ array, type }) => {
  const DAYS_IN_ORDER = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  if (type == "index") {
    return _.map(array, day => _.indexOf(DAYS_IN_ORDER, day));
  }
  return _.map(array, day => DAYS_IN_ORDER[day]);
};

export const getMinuteArr = ({ minuteStep }) => {
  const minuteArr = [];
  for (let i = 0; i <= 60; i += minuteStep) {
    minuteArr.push(i);
  }
  return minuteArr;
};

export const getTimelineLabels = ({
  interval,
  period,
  startTime = "00:00",
  minuteDifference,
  allowedDropdownOptionsStartTime,
  allowedDropdownOptionsEndTime,
}) => {
  const periodsInADay = moment.duration(1, "day").as(period);
  const timeLabels = [];
  const startTimeMoment = moment(
    allowedDropdownOptionsStartTime || "00:00",
    "HH:mm"
  );
  for (let i = 0; i <= periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);

    const isTimeOptionInvalid =
      allowedDropdownOptionsEndTime &&
      startTimeMoment.isAfter(moment(allowedDropdownOptionsEndTime, "HH:mm"));

    if (isTimeOptionInvalid) {
      break;
    }

    timeLabels.push({
      label: `${startTimeMoment.format("hh:mm a")}${
        minuteDifference ? ` (${i + minuteDifference}mins)` : ""
      }`,
      value: startTimeMoment.format("hh:mm a"),
    });
  }
  const startTimeInCorrectFormat = getValueInAM_PMFormat(startTime);
  const filteredTimeLabels = _.filter(timeLabels, timeLabel => {
    const difference = moment(startTimeInCorrectFormat, "hh:mm a").diff(
      moment(timeLabel.label, "hh:mm a")
    );
    // returning only those labels that have value greater than
    // or equal to the start time
    return difference <= 0;
  });
  return filteredTimeLabels;
};

export const isValueBeforeStartTime = ({ startTime, timeValue }) => {
  const startTimeIn12hourFormat = getValueInAM_PMFormat(startTime);
  const timeValueIn12HourFormat = getValueInAM_PMFormat(timeValue);
  return moment(timeValueIn12HourFormat, "hh:mm a").isBefore(
    moment(startTimeIn12hourFormat, "hh:mm a")
  );
};

export const getTimeRange = ({ start, end }) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};

export const getExcludedRange = ({ start = 0, excludedOptions, end }) => {
  const arr = [];
  for (let value = start; value <= end; value++) {
    if (excludedOptions.indexOf(value) < 0) {
      arr.push(value);
    }
  }
  return arr;
};

export const getDisabledHours = ({ startTime, endTime, getFixHours }) => {
  if (getFixHours) {
    return getExcludedRange({
      start: 0,
      excludedOptions: getFixHours(),
      end: 23,
    });
  } else if (!!startTime && !!endTime) {
    const startHour = moment(startTime).get("hour");
    const endHour = moment(endTime).get("hour");
    const excludedHours = getTimeRange({ start: startHour, end: endHour });
    return getExcludedRange({
      start: 0,
      excludedOptions: excludedHours,
      end: 23,
    });
  } else {
    return [];
  }
};

export const getDisabledMinutes = ({
  hours,
  startTime,
  endTime,
  getFixMinutes,
}) => {
  if (getFixMinutes) {
    return getExcludedRange({
      start: 0,
      excludedOptions: getFixMinutes(hours),
      end: 59,
    });
  } else if (!!startTime && !!endTime) {
    let disabledMinutes = [];
    const startHour = moment(startTime).get("hour");
    const endHour = moment(endTime).get("hour");
    if (hours == startHour) {
      const startMinute = moment(startTime).get("minute");
      disabledMinutes = getTimeRange({ start: 0, end: startMinute - 1 });
    } else if (hours == endHour) {
      const endMinute = moment(endTime).get("minute");
      disabledMinutes = getTimeRange({ start: endMinute + 1, end: 59 });
    }
    return disabledMinutes;
  } else {
    return [];
  }
};

export const getOperationalDaysOptions = ({
  schedulerConstants,
  operationalDaysFromConstants,
}) => {
  const { week_start_day = 0, week_length = 7 } = schedulerConstants || {};
  const weekDaysArr = getWeekDaysArr();
  if (!_.isUndefined(operationalDaysFromConstants)) {
    return operationalDaysFromConstants.map(day =>
      moment(weekDaysArr[day], "ddd").locale("en").format("ddd")
    );
  }
  const options = [];
  for (let i = 0; i < week_length; i++) {
    options.push(
      moment(weekDaysArr[week_start_day], "ddd")
        .locale("en")
        .add(i, "day")
        .format("ddd")
    );
  }
  return options;
};

export const getOperationalDaysOptionsMemoize = _.memoize(
  params => getOperationalDaysOptions(params),
  params => JSON.stringify(params)
);

export const getOperationalDays = ({
  schedulerConstants,
  operationalDaysFromConstants,
}) => {
  const options = getOperationalDaysOptionsMemoize({
    schedulerConstants,
    operationalDaysFromConstants,
  });
  return _.map(options, item => _.indexOf(DAYS_IN_ORDER, item));
};

export const getOperationalDaysMemoize = _.memoize(
  params => getOperationalDays(params),
  params => JSON.stringify(params)
);

export const createOperationalDaysValueSet = ({ operationalDaysValue }) => {
  const weekDaysArr = getWeekDaysArr();
  const operationalDays = _.map(operationalDaysValue, day =>
    _.indexOf(weekDaysArr, day)
  ).sort();
  return operationalDays;
};

export const getOperationalDates = ({
  weekStartDate,
  weekEndDate,
  schedulerConstants,
  operationalDaysFromConstants,
}) => {
  const operationalDays = getOperationalDaysOptions({
    schedulerConstants,
    operationalDaysFromConstants,
  });
  const dateArray = [];
  const currentDate = moment(weekStartDate);
  let dayCount = 1;
  while (
    dayCount <= 7 &&
    (weekEndDate
      ? moment(currentDate).isSameOrBefore(weekEndDate, "day")
      : true)
  ) {
    if (_.includes(operationalDays, currentDate.locale("en").format("ddd"))) {
      dateArray.push(currentDate.format("YYYY-MM-DD"));
    }
    dayCount++;
    currentDate.add(1, "days");
  }
  return {
    operationalDates: dateArray,
    startDate: _.first(dateArray),
    endDate: _.last(dateArray),
  };
};

const formatDateString = ({ start, end, options = {} }) => {
  // customize formating of different scenarios if required
  const {
    diffYearFormatFn,
    diffMonthFormatFn,
    diffDayFormatFn,
    sameDayFormatFn,
  } = options;
  // both the dates are same
  if (moment(start).isSame(end, "day")) {
    if (sameDayFormatFn) {
      return sameDayFormatFn(start);
    }
    return moment(start).format("DD MMM YYYY");
  } else if (moment(start).format("YYYY") !== moment(end).format("YYYY")) {
    // years are different
    if (diffYearFormatFn) {
      return diffYearFormatFn(start, end);
    }
    return `${moment(start).format("DD MMM YYYY")}-${moment(end).format(
      "DD MMM YYYY"
    )}`;
  } else if (moment(start).format("MMM") !== moment(end).format("MMM")) {
    // months are different
    if (diffMonthFormatFn) {
      return diffMonthFormatFn(start, end);
    }
    return `${moment(start).format("DD MMM")}-${moment(end).format(
      "DD MMM YYYY"
    )}`;
  } else {
    // days are different
    if (diffDayFormatFn) {
      return diffDayFormatFn(start, end);
    }
    return `${moment(start).format("DD")}-${moment(end).format("DD MMM YYYY")}`;
  }
};
export const formatDateStringMemoize = _.memoize(
  params => formatDateString(params),
  params => JSON.stringify(params)
);

const groupRotationDays = ({ rotationDays, noOfDaysinRotationCycle }) => {
  let currIndex = -1;
  return _.reduce(
    rotationDays,
    (result, obj, index) => {
      // first item of the group
      if (!(index % noOfDaysinRotationCycle)) {
        result.push({
          start: obj.date,
          items: [],
        });
        currIndex++;
      } else if (
        // last item of the group
        index % noOfDaysinRotationCycle ===
        noOfDaysinRotationCycle - 1
      ) {
        result[currIndex].end = obj.date;
      }
      result[currIndex].items.push(obj);
      return result;
    },
    []
  );
};

export const groupRotationDaysMemoize = _.memoize(
  params => groupRotationDays(params),
  params => JSON.stringify(params)
);

const _checkIsMomentIsoString = ({ dateString }) => {
  return moment(dateString, moment.ISO_8601, true).isValid();
};

export const checkIsMomentIsoString = _.memoize(
  params => _checkIsMomentIsoString(params),
  params => JSON.stringify(params)
);

const _getDateTimeStringsBasedOnFormat = ({ inputDateTimeString }) => {
  try {
    if (inputDateTimeString) {
      const dateLabelString = moment(inputDateTimeString, "YYYY-MM-DD").format(
        "Do, MMM YYYY"
      );
      const isDateTimeIsoFormat = checkIsMomentIsoString({
        dateString: inputDateTimeString,
      });
      if (isDateTimeIsoFormat) {
        const isValidTimeString = moment(
          inputDateTimeString,
          moment.ISO_8601,
          true
        ).isValid();
        return {
          dateString: moment(inputDateTimeString).format("YYYY-MM-DD"),
          timeString: isValidTimeString
            ? moment(inputDateTimeString).format("HH:mm")
            : null,
          dateLabelString,
          dateTimeString: isValidTimeString
            ? moment(inputDateTimeString, moment.ISO_8601).format(
                "Do, MMM YYYY hh:mm a"
              )
            : moment(inputDateTimeString, "YYYY-MM-DD").format("Do, MMM YYYY"),
        };
      } else {
        const isValidTimeString = moment(
          inputDateTimeString,
          "YYYY-MM-DD:HH:mm",
          true
        ).isValid();
        return {
          dateString: moment(inputDateTimeString, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
          dateLabelString,
          timeString: isValidTimeString
            ? moment(inputDateTimeString, "YYYY-MM-DD:HH:mm").format("HH:mm")
            : null,
          dateTimeString: isValidTimeString
            ? moment(inputDateTimeString, "YYYY-MM-DD:HH:mm").format(
                "Do, MMM YYYY hh:mm a"
              )
            : moment(inputDateTimeString, "YYYY-MM-DD").format("Do, MMM YYYY"),
        };
      }
    } else {
      return {
        dateString: null,
        timeString: null,
        dateTimeString: "",
        dateLabelString: "",
      };
    }
  } catch {
    return {
      dateString: null,
      timeString: null,
      dateTimeString: "",
      dateLabelString: "",
    };
  }
};

export const getDateTimeStringsBasedOnFormat = _.memoize(
  params => _getDateTimeStringsBasedOnFormat(params),
  params => JSON.stringify(params)
);

const _getTimeDifferenceFromToday = ({ dateTimeString, diffrenceKey }) => {
  const fromDateTimeString = moment(dateTimeString, "DD-MM-YYYY HH:mm");
  const today = moment();
  return today.diff(fromDateTimeString, diffrenceKey);
};

export const getTimeDifferenceFromToday = _.memoize(
  params => _getTimeDifferenceFromToday(params),
  params => JSON.stringify(params)
);

const getTimeTableTimeRange = ({ startTime, endTime }) =>
  startTime && endTime
    ? `${moment(startTime, "HH:mm:ss").format("h:mmA")} - 
  ${moment(endTime, "HH:mm:ss").format("h:mmA")}`
    : "--:--:-- - --:--:--";

export const getTimeTableTimeRangeMemoize = _.memoize(
  getTimeTableTimeRange,
  params => JSON.stringify(params)
);

export const getDatesDifferenceInDays = ({ startDate, endDate }) => {
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  const differentInMs = moment(momentEndDate).diff(momentStartDate);
  const msInADay = 86400 * 1000;

  return differentInMs / msInADay;
};

export const checkIsDateSame = ({ date1, date2 }) => {
  return moment(date1).isSame(date2, "day");
};

export const checkIsDateBeforeToday = day => {
  return moment().isBefore(day, "day");
};

export const checkIsDayBetweenRange = ({
  day,
  startDate,
  endDate,
  additionalDays = 0,
}) => {
  return moment(day).isBetween(
    moment(startDate).add(additionalDays, ATTENDANCE_CONSTANTS.DAY),
    moment(endDate),
    ATTENDANCE_CONSTANTS.DAY,
    "[]"
  );
};

export const getFormattedStartDate = ({ initialDate, format, startOfType }) => {
  if (startOfType) {
    return moment(initialDate).startOf(startOfType).format(format);
  }
  return moment(initialDate).format(format);
};

export const getTodaysDate = () => {
  return moment();
};

export const getPreviousDayDate = date => {
  return moment(date).subtract(1, "day");
};

export const getNextDayDate = date => {
  return moment(date).add(1, "day");
};
