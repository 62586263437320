import React from "react";
import classes from "./TaggedStudents.scss";
import { ProfileCascade, I18nHOC } from "UIComponents";
import { Tooltip } from "AppComponents/ArchivedComponents";
import { AvatarGroup, Avatar } from "@toddle-design/web";

const ProfileCadeStyle = {
  width: 32,
  height: 32,
  marginRight: 4,
};

const getProfileCascadeItems = students => {
  return _.map(students, student => {
    const { id, firstName, lastName, profileImage } = student;
    return {
      id: id,
      value: `${firstName} ${lastName}`,
      imageUrl: profileImage,
    };
  });
};

class TaggedStudents extends React.PureComponent {
  render() {
    const {
      taggedStudents = [],
      isAllStudentsSelected,
      t,
      showAvatarGroup,
      containerStyle,
    } = this.props;
    const moreLength = 1;

    const studentItems = _.map(taggedStudents, item => {
      return {
        name: `${item.firstName} ${item.lastName}`,
        isArchived: item.isArchived,
      };
    });
    return (
      <div className={classes.container} style={containerStyle}>
        {_.get(taggedStudents, "length", 0) > 0 ? (
          showAvatarGroup ? (
            <AvatarGroup
              shape={"square"}
              size={"small"}
              shade={"light"}
              iconType={"individual"}
            >
              {_.map(taggedStudents, student => (
                <Avatar
                  key={student.id}
                  name={`${student.firstName} ${student.lastName}`}
                  src={student.profileImage}
                />
              ))}
            </AvatarGroup>
          ) : (
            <ProfileCascade
              items={getProfileCascadeItems(taggedStudents)}
              customStyle={ProfileCadeStyle}
              length={moreLength}
            />
          )
        ) : (
          <div>{t("common:no_students_selected")}</div>
        )}
        {_.get(taggedStudents, "length", 0) > 0 && !showAvatarGroup && (
          <div className={classes.tagStudentNameContainer}>
            <Tooltip
              data={studentItems}
              keyToMap="name"
              tooltipPlacement={"bottom"}
              length={moreLength}
              isAllSelected={isAllStudentsSelected}
              allItemsText={t("common:all_label", {
                label: t("common:student_plural"),
              })}
              label={t("common:students")}
            />
          </div>
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(TaggedStudents);
