import React from "react";
import classes from "./Footer.scss";
import { I18nHOC, UIButton } from "UIComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import moment from "moment";

const innerConStyle = {
  UNIT_SELECTION: {
    padding: "16px 64px",
    maxWidth: "792px",
  },
  LE_CREATE_UNIT_SELECTION: {
    padding: "16px 64px",
    maxWidth: "792px",
  },
  SMT_CREATE_UNIT_SELECTION: {
    padding: "16px 64px",
    maxWidth: "792px",
  },
  FMT_CREATE_UNIT_SELECTION: {
    padding: "16px 64px",
    maxWidth: "792px",
  },
  ASSESSMENT_CREATE_UNIT_SELECTION: {
    padding: "16px 64px",
    maxWidth: "792px",
  },
  LE_SELECTION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  COMMUNITY_LE_SELECTION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  LE_LIBRARY_LE_SELECTION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  RESOURCE_SELECTION: {
    padding: "16px 64px",
    maxWidth: "1112px",
  },
  DISCUSSION_CREATION: {
    padding: "16px 64px",
    maxWidth: "728px",
  },
  SETTINGS: {
    padding: "16px 64px",
    maxWidth: "728px",
  },
  ASSESSMENT_DETAILS: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  QT_CREATION: { padding: "16px 60px", maxWidth: "unset" },
  LE_CREATION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  SMT_CREATION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  FMT_CREATION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  ASSESSMENT_CREATION: {
    padding: "16px 60px",
    maxWidth: "unset",
  },
  CLASS_SELECTION: { padding: "16px 64px", maxWidth: "1160px" },
  SCHEDULE_CALL_SETTINGS: {
    padding: "16px 64px",
    maxWidth: "728px",
  },
  UNIT_FROM_GRADE_SELECTION: {
    padding: "16px 64px",
    maxWidth: "792px",
  },
};

const Footer = React.memo(props => {
  const {
    isLast,
    isFirst,
    onClickNext,
    onClickPrevious,
    onClickCancel,
    onClickDone,
    isNextDisabled,
    mode,
    currentStep,
    courses,
    resourceSelection,
    t,
    onClickSchedule,
    isButtonDisabled,
  } = props;

  const isScheduled = _.find(
    courses,
    course => !_.isEmpty(course.state.scheduleDate)
  );

  const getScheduleText = () => {
    const scheduledCourses = _.filter(courses, course => {
      return !_.isEmpty(course.state.scheduleDate);
    });

    if (mode == "create") {
      let scheduleText = "";
      _.forEach(scheduledCourses, ({ title }, index) => {
        if (index == 0) scheduleText += title;
        else scheduleText += ` & ${title}`;
      });
      return t("common:scheduled_for_with_label", {
        label: scheduleText,
      });
    } else {
      return t("common:scheduled_at_with_label", {
        label: moment(_.first(scheduledCourses).state.scheduleDate).format(
          "Do, MMM YYYY"
        ),
      });
    }
  };

  const showScheduleButton =
    currentStep == "SETTINGS" &&
    (mode == "create" ||
      _.get(_.first(courses), "state.state", "") == "SCHEDULED");

  return (
    <div className={classes.container}>
      <div
        className={classes.innerContainer}
        style={innerConStyle[currentStep]}
      >
        <div
          data-test-id={"button-footer-discard-action"}
          className={classes.discardButton}
          onClick={onClickCancel}
        >
          {resourceSelection ? t("common:cancel") : t("common:discard")}
        </div>
        <div className={classes.rightButtonContainer}>
          {resourceSelection && !isFirst && (
            <div className={classes.cancelButtonText} onClick={onClickPrevious}>
              {t("common:previous")}
            </div>
          )}
          {showScheduleButton && (
            <div
              data-test-id={"button-footer-secondary-action"}
              className={classes.cancelButtonText}
              style={{ textDecoration: isScheduled && "underline" }}
              onClick={onClickSchedule}
            >
              {isScheduled ? getScheduleText() : t("common:schedule")}
            </div>
          )}
          {!isLast ? (
            <UIButton
              data-test-id={"button-footer-primary-action"}
              color={"pink"}
              onClick={onClickNext}
              isDisabled={isNextDisabled}
            >
              {t("common:next")}
            </UIButton>
          ) : (
            <UIButton
              data-test-id={"button-footer-primary-action"}
              color="pink"
              onClick={onClickDone}
              isDisabled={isButtonDisabled}
            >
              {resourceSelection
                ? t("common:add")
                : currentStep == "SCHEDULE_CALL_SETTINGS" && mode === "create"
                ? t("common:schedule")
                : mode == "edit"
                ? `${
                    isScheduled && showScheduleButton
                      ? `${t("common:schedule")} & `
                      : ``
                  }${t("common:update")}`
                : `${
                    isScheduled && showScheduleButton
                      ? `${t("common:schedule")} & `
                      : ``
                  }${t("common:post")}`}
            </UIButton>
          )}
        </div>
      </div>
    </div>
  );
});

Footer.displayName = "Footer";

Footer.defaultProps = {
  isButtonDisabled: false,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapActionCreators = {};

export default compose(
  I18nHOC({ resource: ["common", "teacherHomePage"] }),
  connect(mapStateToProps, mapActionCreators)
)(Footer);
