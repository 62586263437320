import React from "react";
import classes from "./ResourceList.scss";
import ResourceCard from "../ResourceCard";
import { Loading, NoDataComponent } from "UIComponents";

class ResourceList extends React.Component {
  fetchMoreData = () => {
    const containerDiv = this.containerDiv;
    if (
      _.get(
        this.props,
        "getResourceList.platform.resourceList.isMore",
        false
      ) &&
      this.props.getResourceList.networkStatus != 3
    ) {
      if (
        containerDiv.scrollHeight -
          containerDiv.scrollTop -
          containerDiv.clientHeight <
          5 &&
        containerDiv.scrollTop > 0
      ) {
        this.props.loadMoreListData();
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.scrollTopCounter !== this.props.scrollTopCounter) {
      this.setScrollToTop();
    }
  }

  setScrollToTop = () => {
    if (this.containerDiv) {
      this.containerDiv.scrollTop = 0;
    }
  };

  render() {
    const { resources } = _.get(
      this.props,
      "getResourceList.platform.resourceList",
      {}
    );
    const {
      selectedResources,
      toggleSelectedResources,
      id,
      multi,
      isLoading,
    } = this.props;
    return (
      <div className={classes.container}>
        {resources && resources.length > 0 ? (
          <div
            ref={ref => {
              this.containerDiv = ref;
            }}
            className={classes.resourceContentContainer}
            onScroll={this.fetchMoreData}
          >
            {_.map(resources, (resource, key) => {
              return (
                <ResourceCard
                  id={id}
                  key={key}
                  resource={resource}
                  selectedResources={selectedResources}
                  toggleSelectedResources={toggleSelectedResources}
                  multi={multi}
                />
              );
            })}
          </div>
        ) : isLoading ? null : (
          <NoDataComponent />
        )}
        {this.props.getResourceList.networkStatus == 3 ? (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ResourceList;
