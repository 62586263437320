import { withAsyncRouteLoading } from "UIComponents";
import UnitPlanDetails from "UnitPlanDetails";
import { injectReducer } from "store/reducers";
export default store => {
  return {
    path: "planathonunitplans",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("AdminUnitPlans/modules/AdminUnitPlansModule")
                .default;
              injectReducer(store, { key: "adminUnitPlans", reducer });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-planathon"
          );
        })
    ),
    childRoutes: [UnitPlanDetails(store)],
  };
};
