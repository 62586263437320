import React, { PureComponent } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import classes from "./InsightCountPopover.scss";
import { graphql, compose } from "react-apollo";
import { I18nHOC, Loading } from "UIComponents";
import { getAssessmentsQuery } from "UnitPlans/modules/UnitPlanQuery";
import { getAssessmentsFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import { Popover, ArrowContainer } from "react-tiny-popover-new";
import { colors } from "Constants";
import {
  LE_ASSESSMENT_TYPE_MAPPING,
  PLACEHOLDER_SQUARE_IMAGE_COUNT,
  PLACEHOLDER_SQUARE_IMAGE,
} from "Constants";
import { gotoAssessmentDetails } from "Assessments/modules/AssessmentModule";

class InsightCountPopover extends PureComponent {
  render() {
    const {
      showLEPopover,
      leAssessmentsData,
      selectedUnitId,
      onClosePopover,
      insightCountRef,
      gotoAssessmentDetails,
      isLoading,
      generateCard,
      t,
      isViewOnly,
    } = this.props;

    const goToAssessment = ({ id }) => {
      gotoAssessmentDetails({
        id,
        isOpenInNewTab: true,
        mode: "view",
      });
    };

    return (
      <Popover
        isOpen={showLEPopover}
        align={"center"}
        positions={["right"]}
        reposition={true}
        onClickOutside={() => {
          onClosePopover();
        }}
        containerStyle={{ zIndex: 1500 }}
        padding={40}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={colors.white}
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className={classes.lePopUpContainer}>
              <div className={classes.heading}>
                {t("common:associated_units_assessments_popover_heading")}
              </div>
              {isLoading ? (
                <Loading />
              ) : (
                <React.Fragment>
                  <div className={classes.assessmentContainer}>
                    {_.map(leAssessmentsData, (node, index) => {
                      const id = _.get(node, "id", 0);
                      const title = _.get(node, "title.value", "Untitled");
                      const imageUrl =
                        _.get(node, "image.value", null) ||
                        PLACEHOLDER_SQUARE_IMAGE[
                          id % PLACEHOLDER_SQUARE_IMAGE_COUNT
                        ];
                      const type = _.get(node, "assessmentType.value", "");
                      const typeLabel = _.get(
                        LE_ASSESSMENT_TYPE_MAPPING[type],
                        "label",
                        ""
                      );

                      return generateCard({
                        id,
                        index,
                        imageUrl,
                        title,
                        subTitle: typeLabel,
                        onClickOpenLinkSvg: isViewOnly
                          ? _.noop
                          : () => {
                              goToAssessment({ id });
                            },
                        assessmentCard: true,
                      });
                    })}
                  </div>
                </React.Fragment>
              )}
            </div>
          </ArrowContainer>
        )}
        childrenRef={{ current: insightCountRef[selectedUnitId] }}
        isChildrenRefPassed={true}
      ></Popover>
    );
  }
}

const mapActionCreatorsToProps = { gotoAssessmentDetails };

export default compose(
  connect(null, mapActionCreatorsToProps),
  I18nHOC({ resource: ["common", "planningInsights"] }),
  graphql(getAssessmentsQuery, {
    name: "getAssessments",
    options: ({ leAssessmentIds }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: leAssessmentIds,
      },
    }),
    props({ getAssessments, ownProps: { leAssessmentIds } }) {
      const data = getAssessmentsFromCache({
        ids: leAssessmentIds,
      });

      return {
        leAssessmentsData: data,
        isData: !_.isEmpty(data),
        isLoading:
          getAssessments["networkStatus"] == 1 ||
          getAssessments["networkStatus"] == 2,
      };
    },
  })
)(InsightCountPopover);

InsightCountPopover.defaultProps = {
  isViewOnly: false,
};
