import client from "apolloClient";
import {
  getSnPEvaluationCyclesQuery,
  getSnPCycleDetailsQuery,
  getFirstExperienceStatusQuery,
  getStandardsListQuery,
  getSnpSetDetailsQuery,
  getProgressReportsQuery,
  guidanceDocumentsQuery,
  getVisitorListQuery,
  getInvitedVisitorsListQuery,
  getSnPDocumentReviewStagesQuery,
  getSnPDocumentCategoryQuery,
  getVisitorCountQuery,
  getSnPDocumentConversationsQuery,
  getStandardConversationsQuery,
  getDocumentCategoryHelpTextQuery,
  getSuggestedEvidenceViewStatusQuery,
  getSnpPracticeEvidenceBasicDetailsQuery,
  getDocumentCategoryEvidenceBasicDetailsQuery,
  getStandardBasicDetailsQuery,
  getStandardEvidencesQuery,
  getDocumentCategoryTaggedPracticesQuery,
  getStandardDetailsQuery,
} from "./SnPQueries";
import {
  snpDocumentCategoryGroupFragment,
  snpEvaluationCycleFragment,
  evaluationCycleStandardFragments,
  snpPracticesFragment,
  snpDocumentCategoryFragment,
} from "./SnPFragments";
import {
  getUnitPlanResourceLibraryFromCache,
  getFeedUnitPlanItemFromCache,
  writeFeedUnitPlanItemToCache,
  writeUnitPlanResourceLibraryInCache,
} from "UnitPlans/modules/UnitPlanGraphqlHelpers";

import {
  getPostEvidenceDataFromCache,
  writePostEvidenceDataInCache,
} from "Evidence/modules/EvidenceGraphqlHelpers";

import {
  getIndividualStudentProgressReportDetailsFromCache,
  writeIndividualStudentProgressReportDetailsInCache,
} from "ProgressReportCommon/modules/ProgressReportGraphqlHelpers";

import {
  getAssignmentFeedItemFromCache,
  writeAssignmentFeedItemToCache,
} from "ClassRoom/modules/ClassRoomGraphqlHelpers";

import {
  getUpdatedTaggedPractices,
  getPracticeEvidencesQueryVariables,
  removeEvidenceFromCachedData,
  getDocumentCategoryEvidenceFeedQueryVariables,
} from "./SnPUtils";
import {
  EVIDENCE_TYPE_ASSESSMENT,
  EVIDENCE_TYPE_PROGRESS_REPORT,
  EVIDENCE_TYPE_POST,
  EVIDENCE_TYPE_UNIT,
  EVIDENCE_TYPE_ASSIGNMENT,
} from "Constants/stringConstants";
import {
  getUnitPlanItemFromCache,
  writeUnitPlanItemToCache,
} from "Scheduler/modules/SchedulerGraphqlHelpers";
import { FIELD_UID_CURRICULUM_MAPPING } from "UnitPlans/modules/UnitPlanModule";

export const getSnPEvaluationCyclesFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPEvaluationCyclesQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  return _.get(queryData, "node", {});
};

export const updateSnPCyclesInCache = ({ data, id, filters }) => {
  try {
    client.writeQuery({
      query: getSnPEvaluationCyclesQuery,
      variables: {
        id,
        filters,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
  return;
};

export const getGuidanceDocumentsFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: guidanceDocumentsQuery,
      variables,
    });
  } catch (e) {
    return {};
  }

  return queryData?.node ?? {};
};

export const getSnPCycleDetailsFromCache = ({ cycleId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getSnPCycleDetailsQuery,
      variables: { id: cycleId },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getFirstExperienceStatusFromCache = ({ userId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getFirstExperienceStatusQuery,
      variables: {
        id: userId,
      },
    });
  } catch (e) {
    return queryData;
  }

  return queryData;
};

export const writeFirstExperienceToCache = (userId, value) => {
  try {
    client.writeQuery({
      query: getFirstExperienceStatusQuery,
      data: {
        platform: {
          staff: {
            id: userId,
            isSnpExplainerVideoAvailable: value,
            __typename: "Staff",
          },
          __typename: "PlatformQuery",
        },
      },
    });
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const getStandardsListFromCache = ({ curriculumType }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStandardsListQuery,
      variables: { curriculumType },
    });
  } catch (e) {
    return {};
  }
  return queryData;
};

export const getSnpSetDetailsFromCache = ({ setId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getSnpSetDetailsQuery,
      variables: { setId },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node.frameworks", []);
};

export const writeStandardStatusToCache = ({ standardId, data }) => {
  try {
    client.writeFragment({
      id: `SnPEvaluationCycleStandard:${standardId}`,
      fragment: evaluationCycleStandardFragments.standardStatusDetails,
      fragmentName: "standardItem",
      data,
    });
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const getStandardStatusFromCache = ({
  standardId,
  showOptimisticResponse,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment(
      {
        id: `SnPEvaluationCycleStandard:${standardId}`,
        fragment: evaluationCycleStandardFragments.standardStatusDetails,
        fragmentName: "standardItem",
      },
      showOptimisticResponse
    );
  } catch (e) {
    console.error(e);
    return queryData;
  }

  return _.get(queryData, `userStatus`, {});
};

export const getProgressReportsFromCache = ({
  id,
  first,
  after,
  orderBy,
  filters,
  orderByDirection,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProgressReportsQuery,
      variables: {
        id,
        first,
        after,
        orderByDirection,
        orderBy,
        filters,
      },
    });
  } catch (e) {
    return queryData;
  }
  return queryData;
};

export const getSnPDocumentReviewStagesFromCache = ({
  cycleId,
  showOptimisticResponse = false,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery(
      {
        query: getSnPDocumentReviewStagesQuery,
        variables: {
          cycleId,
        },
      },
      showOptimisticResponse
    );
  } catch (e) {
    console.error(e);
  }

  return _.get(queryData, "node", {});
};

export const getSnPDocumentCategoryGroupFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `SnPDocumentCategoryGroup:${id}`,
      fragment: snpDocumentCategoryGroupFragment,
      fragmentName: "snpDocumentCategoryGroupItem",
    });
  } catch (e) {
    console.error(e);
  }

  return queryData;
};

export const writeSnPDocumentCategoryGroupInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SnPDocumentCategoryGroup:${id}`,
      fragment: snpDocumentCategoryGroupFragment,
      fragmentName: "snpDocumentCategoryGroupItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getSnPDocumentCategoryFromCache = ({
  id,
  showOptimisticResponse = false,
  first,
  after,
  orderBy,
  orderByDirection,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery(
      {
        query: getSnPDocumentCategoryQuery,
        variables: {
          id,
          first,
          after,
          orderBy,
          orderByDirection,
        },
      },
      showOptimisticResponse
    );
  } catch (e) {
    console.error(e);
  }

  return _.get(queryData, "node", {});
};

export const getVisitorListFromCache = variables => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getVisitorListQuery,
      variables,
    });
  } catch (e) {
    return;
  }

  return _.get(queryData, "node", {});
};

export const getInvitedVisitorsListFromCache = variables => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getInvitedVisitorsListQuery,
      variables,
    });
  } catch (e) {
    return;
  }

  return _.get(queryData, "node", {});
};

export const writeInvitedVisitorListInCache = ({ variables, data }) => {
  try {
    client.writeQuery({
      query: getInvitedVisitorsListQuery,
      variables,
      data,
    });
  } catch (e) {
    console.error(e);
  }
  return;
};

export const getPracticeListDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `SnPPractice:${id}`,
      fragment: snpPracticesFragment.practiceDetails,
      fragmentName: "practiceItem",
    });
  } catch (e) {
    return queryData;
  }
  return queryData;
};

export const updateCycleDetailsInCache = ({ data }) => {
  try {
    client.writeFragment({
      id: `SnPEvaluationCycle:${data.id}`,
      fragment: snpEvaluationCycleFragment.cycleDetails,
      fragmentName: "snpEvaluationCycleItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getVisitorCountFromCache = ({ organizationId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getVisitorCountQuery,
      variables: { organizationId },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getDocumentCategoryDetailsFromCache = ({
  id,
  first,
  after,
  orderBy,
  orderByDirection,
}) => {
  let fragmentData = {};
  try {
    fragmentData = client.readFragment({
      id: `SnPDocumentCategory:${id}`,
      fragment: snpDocumentCategoryFragment.allDetails,
      fragmentName: "snpDocumentCategoryItem",
      variables: { first, after, orderBy, orderByDirection },
    });
  } catch (e) {
    return {};
  }

  return fragmentData;
};

export const updateDocumentCategoryDetailsInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SnPDocumentCategory:${id}`,
      fragment: snpDocumentCategoryFragment.allDetails,
      fragmentName: "snpDocumentCategoryItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
  return;
};

export const getSnPDocumentConversationsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPDocumentConversationsQuery,
      variables: { id },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getStandardConversationsFromCache = ({ id }) => {
  let queryData;

  try {
    queryData = client.readQuery({
      query: getStandardConversationsQuery,
      variables: { id },
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

export const getUnreadMessageCountForConversation = ({
  id,
  parentType,
  screen = "DASHBOARD",
}) => {
  let conversationInfo;
  if (screen === "DASHBOARD") {
    conversationInfo = getStandardConversationsFromCache({ id, parentType });
  } else {
    conversationInfo = getSnPDocumentConversationsFromCache({
      id,
      parentType,
    });
  }

  return _.get(conversationInfo, "unreadMessageCount", 0);
};

export const writeDocumentCategoryDataToCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SnPDocumentCategory:${id}`,
      fragmentName: "responseItem",
      fragment: snpDocumentCategoryFragment.responseDetails,
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getSnpPracticeEvidenceBasicDetailsFromCache = ({
  id,
  filters,
}) => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getSnpPracticeEvidenceBasicDetailsQuery,
      variables: { id, filters },
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

export const getDocumentCategoryEvidenceBasicDetailsFromCache = ({
  id,
  filters,
}) => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getDocumentCategoryEvidenceBasicDetailsQuery,
      variables: { id, filters },
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

export const getDocumentCategoryHelpTextFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getDocumentCategoryHelpTextQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  return queryData?.node ?? {};
};

/**special graphql utility functions */

const updateAssessmentsInCache = ({
  unitPlanId,
  itemId,
  addedPractices,
  removedPractices,
  evaluationCycleId,
  userId,
}) => {
  const filters = {
    assessmentTypes: [],
    groupType: "LEARNING_ENGAGEMENT",
    searchText: "",
  };

  const data = getUnitPlanResourceLibraryFromCache({ id: unitPlanId, filters });

  const resourceLibrary = _.get(data, "resourceLibrary", {});

  const edges = _.get(resourceLibrary, "edges", []);

  const updatedEdges = _.map(edges, edge => {
    const node = _.get(edge, "node", {});

    if (
      _.get(edge, "type", "") !== "ASSESSMENT" ||
      _.get(node, "id", "") !== itemId
    )
      return edge;

    const taggedPractices = node.taggedPractices ?? [];

    const updatedTaggedPractices = getUpdatedTaggedPractices({
      taggedPractices,
      evaluationCycleId,
      addedPractices,
      removedPractices,
      userId,
    });

    const updatedNode = {
      ...node,
      taggedPractices: updatedTaggedPractices,
    };

    const updatedEdge = { ...edge, node: updatedNode };

    return updatedEdge;
  });

  const updatedResourceLibrary = { ...resourceLibrary, edges: updatedEdges };

  const updatedData = { ...data, resourceLibrary: updatedResourceLibrary };

  writeUnitPlanResourceLibraryInCache({
    id: unitPlanId,
    filters,
    data: updatedData,
  });
};

export const updateFeedInCache = ({
  itemId,
  itemType,
  addedPractices,
  removedPractices,
  evaluationCycleId,
  unitPlanId,
  userId,
  centralRepoInput,
  currentViewType,
  currentCurriculumType,
  assignmentFeedFilters,
}) => {
  switch (itemType) {
    case EVIDENCE_TYPE_UNIT: {
      let data = {};
      if (_.isEqual(currentViewType, "timelineView")) {
        data = getUnitPlanItemFromCache({
          id: itemId,
          uids: FIELD_UID_CURRICULUM_MAPPING[currentCurriculumType],
        });
      } else {
        data = getFeedUnitPlanItemFromCache({
          id: itemId,
          centralRepoInput,
        });
      }
      const taggedPractices = data.taggedPractices ?? [];

      const updatedTaggedPractices = getUpdatedTaggedPractices({
        taggedPractices,
        evaluationCycleId,
        addedPractices,
        removedPractices,
        userId,
      });

      const updatedData = {
        ...data,
        taggedPractices: updatedTaggedPractices,
      };

      if (_.isEqual(currentViewType, "timelineView"))
        writeUnitPlanItemToCache({ id: itemId, data: updatedData });
      else writeFeedUnitPlanItemToCache({ id: itemId, data: updatedData });
      break;
    }
    case EVIDENCE_TYPE_ASSESSMENT: {
      updateAssessmentsInCache({
        itemId,
        addedPractices,
        removedPractices,
        evaluationCycleId,
        unitPlanId,
        userId,
      });
      break;
    }
    case EVIDENCE_TYPE_POST: {
      const data = getPostEvidenceDataFromCache({ postId: itemId });

      const taggedPractices = data.taggedPractices ?? [];

      const updatedTaggedPractices = getUpdatedTaggedPractices({
        taggedPractices,
        evaluationCycleId,
        addedPractices,
        removedPractices,
        userId,
      });

      const updatedData = {
        ...data,
        taggedPractices: updatedTaggedPractices,
      };

      writePostEvidenceDataInCache({
        postId: itemId,
        data: updatedData,
      });
      break;
    }
    case EVIDENCE_TYPE_PROGRESS_REPORT: {
      const data = getIndividualStudentProgressReportDetailsFromCache({
        studentProgressReportId: itemId,
      });

      const taggedPractices = data.taggedPractices ?? [];

      const updatedTaggedPractices = getUpdatedTaggedPractices({
        taggedPractices,
        evaluationCycleId,
        addedPractices,
        removedPractices,
        userId,
      });

      const updatedData = {
        ...data,
        taggedPractices: updatedTaggedPractices,
      };

      writeIndividualStudentProgressReportDetailsInCache({
        data: updatedData,
        studentProgressReportId: itemId,
      });
      break;
    }

    case EVIDENCE_TYPE_ASSIGNMENT: {
      const data = getAssignmentFeedItemFromCache({
        id: itemId,
        variables: assignmentFeedFilters,
      });

      const taggedPractices = data.taggedPractices ?? [];

      const updatedTaggedPractices = getUpdatedTaggedPractices({
        taggedPractices,
        evaluationCycleId,
        addedPractices,
        removedPractices,
        userId,
      });

      const updatedData = {
        ...data,
        taggedPractices: updatedTaggedPractices,
      };

      writeAssignmentFeedItemToCache({ id: itemId, data: updatedData });
      break;
    }

    default: {
      return;
    }
  }
};

export const getSuggestedEvidenceViewStatusFromCache = ({
  staffId,
  practiceId,
  cycleId,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getSuggestedEvidenceViewStatusQuery,
      variables: { staffId, practiceId, cycleId },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const writeSuggestedEvidenceViewStatusInCache = ({
  staffId,
  practiceId,
  cycleId,
  isSuggestedEvidenceViewed,
}) => {
  try {
    client.writeQuery({
      query: getSuggestedEvidenceViewStatusQuery,
      variables: { staffId, practiceId, cycleId },
      data: {
        node: {
          id: staffId,
          snp: {
            isSuggestedEvidenceViewed,
            __typename: "StaffSnPWrapper",
          },
          __typename: "Staff",
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStandardEvidencesFromCache = ({ variables }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getStandardEvidencesQuery,
      variables,
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getStandardBasicDetailsFromCache = ({
  cycleId,
  filters,
  showOptimisticResponse,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery(
      {
        query: getStandardBasicDetailsQuery,
        variables: {
          cycleId,
          filters,
        },
      },
      showOptimisticResponse
    );
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getPracticeEvidencesFromCache = ({ variables }) => {
  let fragmentData = {};

  const { practiceId } = variables;

  try {
    fragmentData = client.readFragment({
      id: `SnPPractice:${practiceId}`,
      fragment: snpPracticesFragment.practiceEvidenceDetailsV2,
      fragmentName: "practiceItem",
      variables: _.omit(variables, "practiceId"),
    });
  } catch (e) {
    return {};
  }

  return fragmentData;
};

export const writePracticeDetailsInCache = ({ variables, data }) => {
  const { practiceId } = variables;

  try {
    client.writeFragment({
      id: `SnPPractice:${practiceId}`,
      fragment: snpPracticesFragment.practiceEvidenceDetailsV2,
      fragmentName: "practiceItem",
      variables: _.omit(variables, "practiceId"),
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getDocumentCategoryTaggedPracticesFromCache = ({ variables }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getDocumentCategoryTaggedPracticesQuery,
      variables,
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const writeDocumentCategoryTaggedPracticesInCache = ({
  variables,
  data,
}) => {
  try {
    client.writeQuery({
      query: getDocumentCategoryTaggedPracticesQuery,
      variables,
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const writeCategoryStatusToCache = ({ data }) => {
  const fragmentName = "statusDetailsItem";

  const fragment = snpDocumentCategoryFragment.statusDetails;

  try {
    client.writeFragment({
      id: `SnPDocumentCategory:${data.id}`,
      fragmentName,
      fragment,
      data,
    });
  } catch (e) {
    console.error(e);
  }
};
export const getStandardDetailsFromCache = ({ variables }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({ query: getStandardDetailsQuery, variables });
  } catch (e) {
    return {};
  }

  return queryData?.node ?? {};
};

export const getStandardEvidenceBasicDetailsFromCache = ({
  standardId,
  filters,
}) => {
  let fragmentData;
  try {
    fragmentData = client.readFragment(
      {
        id: `SnPEvaluationCycleStandard:${standardId}`,
        fragment: evaluationCycleStandardFragments.standardBasicDetails,
        fragmentName: "standardBasicDetailsItem",
        variables: {
          filters,
        },
      },
      true
    );
  } catch (e) {
    return {};
  }
  return fragmentData;
};

export const getDocumentCategoryEvidenceFeedDetailsFromCache = ({
  id,
  first,
  after,
  orderBy,
  orderByDirection,
}) => {
  let fragmentData = {};

  try {
    fragmentData = client.readFragment({
      id: `SnPDocumentCategory:${id}`,
      fragment: snpDocumentCategoryFragment.evidenceFeedDetails,
      fragmentName: "evidenceFeedDetailsItem",
      variables: { first, after, orderBy, orderByDirection },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  return fragmentData;
};

export const writeDocumentCategoryEvidenceFeedDetailsToCache = ({
  id,
  first,
  after,
  orderBy,
  orderByDirection,
  data,
}) => {
  try {
    client.writeFragment({
      id: `SnPDocumentCategory:${id}`,
      fragment: snpDocumentCategoryFragment.evidenceFeedDetails,
      fragmentName: "evidenceFeedDetailsItem",
      variables: { first, after, orderBy, orderByDirection },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const deletePracticeEvidenceFromCache = ({
  id,
  removedEvidence,
  cycleId,
  activeTab,
}) => {
  const variables = getPracticeEvidencesQueryVariables({
    practiceId: id,
    cycleId,
    activeTab,
    isSuggestedEvidenceTab: false,
    removedEvidence,
  });

  const practiceEvidences = getPracticeEvidencesFromCache({ variables });

  const updatedData = removeEvidenceFromCachedData({
    data: practiceEvidences,
    removedEvidence,
  });

  writePracticeDetailsInCache({ variables, data: updatedData });
};

export const deleteDocumentCategoryEvidenceFromCache = ({
  id,
  removedEvidence,
}) => {
  const variables = getDocumentCategoryEvidenceFeedQueryVariables({ id });
  const documentCategoryEvidence = getDocumentCategoryEvidenceFeedDetailsFromCache(
    variables
  );

  const updatedData = removeEvidenceFromCachedData({
    data: documentCategoryEvidence,
    removedEvidence,
  });

  writeDocumentCategoryEvidenceFeedDetailsToCache({
    ...variables,
    data: updatedData,
  });
};
