import React, { useState, Fragment, useEffect } from "react";
import { UIButton, I18nHOC, UIModal } from "UIComponents";
import classes from "./ContributeCommunity.scss";
import { MessengerEvent, CLOUD_URL } from "Constants";
import {
  EntitySelectionModal,
  EntitySelectionModalConfig,
} from "../../EntitySelectionModal";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { addToCommunity } from "Community/modules/CommunityModule";
import {
  checkCommunityActiveStatusAndGoToOnboarding,
  isUserAuthenticatedForAction,
} from "Community/modules/CommunityModule";
import { CancelIcon } from "SvgComponents";
import ContributeSuccessModal from "../ContributeSuccessModal";
import * as EventTracker from "lib/eventTracker";
import { withRouter } from "react-router";

const modalButtonStyle = {
  label: { fontSize: "1.4rem" },
  container: { minWidth: 248 },
};

const contributionSteps = [
  {
    url: `${CLOUD_URL}/assets/webapp/Community/contribute_1.svg`,
    text: "community:contribute_step_1",
  },
  {
    url: `${CLOUD_URL}/assets/webapp/Community/contribute_2.svg`,
    text: "community:contribute_step_2",
  },
  {
    url: `${CLOUD_URL}/assets/webapp/Community/contribute_3.svg`,
    text: "community:contribute_step_3",
  },
  {
    url: `${CLOUD_URL}/assets/webapp/Community/contribute_4.svg`,
    text: "community:contribute_step_4",
  },
];

const ContributeWelcomeModal = React.memo(
  ({ setContributeWelcomeModal, t, onItemClick, onClose }) => {
    const handleClick = type => {
      onItemClick(type);
      setContributeWelcomeModal(false);
    };

    return (
      <UIModal
        modalContent={classes.modalContent}
        isOpen={true}
        onRequestClose={onClose}
      >
        <div className={classes.modalContainer}>
          <div className={classes.cancelIcon} onClick={onClose}>
            <CancelIcon width={16} height={24} />
          </div>
          <div className={classes.headerContainer}>
            <div className={classes.headerText}>
              {t("community:contribute_to_toddle")}
            </div>
            <div className={classes.headerSubText}>
              {t("community:contribute_sub_text")}
            </div>
          </div>

          <div className={classes.steps}>
            {contributionSteps.map((step, index) => (
              <div className={classes.stepItem} key={step.text}>
                <div className={classes.stepImage}>
                  <img src={step.url} />
                </div>
                <div className={classes.stepCount}>{`Step ${index + 1}`}</div>
                <div className={classes.step}>{t(step.text)}</div>
              </div>
            ))}
          </div>

          <div className={classes.buttonsContainer}>
            <UIButton
              onClick={() => handleClick("UNIT_PLAN")}
              containerStyle={modalButtonStyle.container}
              labelStyle={modalButtonStyle.label}
              color="pink"
            >
              {t("community:contribute_unit")}
            </UIButton>
            <UIButton
              onClick={() => handleClick("ASSESSMENT")}
              containerStyle={modalButtonStyle.container}
              labelStyle={modalButtonStyle.label}
              color="blue"
            >
              {t("community:contribute_le")}
            </UIButton>
          </div>
        </div>
      </UIModal>
    );
  }
);

const unitSteps = EntitySelectionModalConfig([
  { key: "UNIT_SELECTION" },
  {
    key: "CONTRIBUTE_TO_COMMUNITY",
    props: { entityType: "UNIT_PLAN", entityId: "unitId" },
  },
]);

const leSteps = EntitySelectionModalConfig([
  { key: "UNIT_SELECTION" },
  { key: "LE_SELECTION" },
  {
    key: "CONTRIBUTE_TO_COMMUNITY",
    props: { entityType: "ASSESSMENT", entityId: "leId" },
  },
]);

const ContributeCommunity = ({
  addToCommunity,
  t,
  checkCommunityActiveStatusAndGoToOnboarding,
  buttonStyle = {},
  eventTarget,
  action,
  isUserAuthenticatedForAction,
  buttonConfig = {
    size: "sm",
    label: "community:contribute",
    color: "pink",
  },
}) => {
  const [showEntitySelectionModal, setEntitySelectionModal] = useState(false);
  const [contributeWelcomeModal, setContributeWelcomeModal] = useState();
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [type, setType] = useState("");
  const [entityId, setEntityId] = useState(null);

  useEffect(() => {
    if (action === "contribute") {
      showContributeWelcomeModal(true);
    }
  }, [action]);

  const setModalInfo = type => {
    setType(type);
    setEntitySelectionModal(true);
  };

  const onCloseContributeWelcomeModal = () => {
    setContributeWelcomeModal(false);
  };

  const handleSubmit = async data => {
    try {
      const result = await addToCommunity({
        ...(data || {}),
        eventTarget,
        portalType: "PLANNER",
      });
      if (result) {
        if (window.location.href.includes("community/home/profile")) {
          setIsProfilePage(true);
          MessengerEvent.emit("BIO_REFETCH", type);
        } else setIsProfilePage(false);

        setEntityId(data.id);
        setSuccessModal(true);
      }
      return result;
    } catch (err) {
      // throw new Error(err);
    }
  };

  const onItemClick = async value => {
    const isActiveCommunity = await checkCommunityActiveStatusAndGoToOnboarding();

    if (!isActiveCommunity) {
      return;
    }
    EventTracker.recordEvent({
      eventName: "Clicked contribute",
      eventData: {
        target: eventTarget,
        source: "community",
        entityType: value,
      },
    });

    setModalInfo(value);
  };

  const showContributeWelcomeModal = async () => {
    const hasAccess = await isUserAuthenticatedForAction();
    if (!hasAccess) return;
    setContributeWelcomeModal(true);
  };

  return (
    <Fragment>
      <UIButton
        containerStyle={{
          borderRadius: "8px",
          ...(buttonStyle.container || {}),
        }}
        labelStyle={{ ...(buttonStyle.label || {}) }}
        color={buttonConfig.color}
        size={buttonConfig.size}
        onClick={showContributeWelcomeModal}
      >
        {t(buttonConfig.label)}
      </UIButton>

      {showEntitySelectionModal && (
        <EntitySelectionModal
          setEntitySelectionModal={() => setEntitySelectionModal(false)}
          steps={type === "UNIT_PLAN" ? unitSteps : leSteps}
          handleSubmit={handleSubmit}
          portalType={"PLANNER"}
          unitPlanSelectionProps={{
            parentType: "STAFF",
            courseId: "",
            grades: [],
            unitTypes: ["ibStandAlone", "ibInquiry"],
          }}
        />
      )}
      {showSuccessModal && (
        <ContributeSuccessModal
          entityId={entityId}
          entityType={type}
          hideViewProfile={isProfilePage}
          setSuccessModal={setSuccessModal}
        />
      )}
      {contributeWelcomeModal && (
        <ContributeWelcomeModal
          setContributeWelcomeModal={setContributeWelcomeModal}
          onItemClick={onItemClick}
          onClose={onCloseContributeWelcomeModal}
          t={t}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const action = _.get(ownProps, "location.query.action");
  return { action, userId: _.get(state, "login.userInfo.id") };
};

const mapActionCreators = {
  addToCommunity,
  checkCommunityActiveStatusAndGoToOnboarding,
  isUserAuthenticatedForAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] })
)(ContributeCommunity);
