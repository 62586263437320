import React from "react";
import classes from "./ClassSelectionModal.scss";
import { getOrganizationCoursesQuery } from "modules/CommonQuery";
import { getOrganizationAllCoursesFromCache } from "modules/CommonGraphqlHelpers";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, UIButton, FullScreenLoader, I18nHOC } from "UIComponents";
import { getCurriculumProgramAcronymById } from "Platform/modules/PlatformUtils";
import {
  ToddleIcon,
  LogoTextSvg,
  Tick,
  PreKIcon,
  KIcon,
  EY2Icon,
  EY1Icon,
  Grade1Icon,
  Grade2Icon,
  Grade3Icon,
  Grade4Icon,
  Grade5Icon,
  Grade6Icon,
} from "SvgComponents";
import { colors } from "Constants";
import { getCountString } from "Utils";
import { getUserInfo } from "Login/modules/LoginModule";
import * as EventTracker from "lib/eventTracker";

const GradeIcon = ({ iconKey, fill }) => {
  switch (iconKey) {
    case "EY1Icon":
      return <EY1Icon fill={fill}></EY1Icon>;
    case "EY2Icon":
      return <EY2Icon fill={fill}></EY2Icon>;
    case "KIcon":
      return <KIcon fill={fill}></KIcon>;
    case "Grade1Icon":
      return <Grade1Icon fill={fill}></Grade1Icon>;
    case "Grade2Icon":
      return <Grade2Icon fill={fill}></Grade2Icon>;
    case "Grade3Icon":
      return <Grade3Icon fill={fill}></Grade3Icon>;
    case "Grade4Icon":
      return <Grade4Icon fill={fill}></Grade4Icon>;
    case "Grade5Icon":
      return <Grade5Icon fill={fill}></Grade5Icon>;
    case "Grade6Icon":
      return <Grade6Icon fill={fill}></Grade6Icon>;
    default:
      return null;
  }
};

const Card = React.memo(props => {
  const { course, isSelected, toggleSelectedClass } = props;
  const {
    title,
    id,
    grades,
    // collaborators: { totalCount: totalCollaborators }
  } = course;

  const constants = _.get(grades[0], "globalGrade.constants", {});

  const color = colors.blue29;
  const iconKey = _.get(constants, "iconKey", "");
  const ageGroup = _.get(constants, "ageGroup", "");
  return (
    <div
      className={classes.card}
      onClick={() => toggleSelectedClass(id)}
      style={isSelected ? { borderColor: color } : {}}
    >
      <div className={classes.tickIcon}>
        {isSelected && <Tick fillBackground={color} width={24} height={24} />}
      </div>

      <div className={classes.icon}>
        <GradeIcon fill={colors.gray48} iconKey={iconKey}></GradeIcon>
      </div>

      <div className={classes.classTitle}>{title}</div>
      <div className={classes.itemCounts}>
        {ageGroup}
        {/* {getCountString({
                count: totalCollaborators,
                singularText: "Teacher",
                pluralText: "Teachers"
            })} */}
      </div>
    </div>
  );
});

class ClassSelectionModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedClasses: _.map(props.selectedCourses, course => course.id),
    };
    EventTracker.recordEvent({
      eventName: "Viewed Page",
      eventData: {
        page: "Grade Selection",
        entity_type: "STAFF",
      },
    });
  }

  onClickDone = async () => {
    const { onClickDoneCourses, selectedCourses } = this.props;
    const propCourses = _.map(selectedCourses, course => course.id);
    const { selectedClasses: localCourses } = this.state;

    EventTracker.recordEvent({
      eventName: "Clicked Grade Selection",
      eventData: {
        entity_type: "STAFF",
      },
    });

    this.setState({ isLoading: true });
    await onClickDoneCourses({
      addedCourses: _.difference(localCourses, propCourses),
      removedCourses: _.difference(propCourses, localCourses),
    });
    this.setState({ isLoading: false });
  };

  toggleSelectedClass = id => {
    const { selectedClasses } = this.state;
    if (_.includes(selectedClasses, id)) {
      this.setState({
        selectedClasses: _.filter(selectedClasses, classId => classId != id),
      });
    } else {
      this.setState({ selectedClasses: [...selectedClasses, id] });
    }
  };

  render() {
    const { courses, t, curriculumProgramAcronym } = this.props;
    const { selectedClasses, isLoading } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.header}>
            <div className={classes.toddleIcon}>
              <ToddleIcon />
            </div>
            {/* <div className={classes.toddleLogoText}>
              <LogoTextSvg />
            </div> */}
          </div>
          <div className={classes.middleBody}>
            <div className={classes.selectLabel}>
              {t("teacherHomePage:select_your_grades_with_label", {
                label: curriculumProgramAcronym,
              })}
            </div>
            <div className={classes.subLabel}>
              {t("teacherHomePage:class_selection_subtext")}
            </div>
            <div className={classes.cardList}>
              {_.map(courses, course => {
                return (
                  <Card
                    key={course.id}
                    course={course}
                    toggleSelectedClass={this.toggleSelectedClass}
                    isSelected={_.includes(selectedClasses, course.id)}
                  />
                );
              })}
            </div>

            <UIButton
              size={"lg"}
              color={"pink"}
              onClick={this.onClickDone}
              isDisabled={_.isEmpty(selectedClasses)}
              containerStyle={{ height: 56, width: 256 }}
            >
              {t("common:done")}
            </UIButton>
          </div>
        </div>
        {isLoading && <FullScreenLoader />}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = state => {
  const userInfo = getUserInfo({ portalType: "PLANNER" });
  const userId = userInfo.id;

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  const curriculumProgramId = _.get(
    state,
    "platform.currentCurriculumProgram.id",
    ""
  );

  const curriculumProgramAcronym = getCurriculumProgramAcronymById({
    curriculumPrograms: organizationCurriculumPrograms,
  })[curriculumProgramId];

  const filters = {
    curriculumProgramIds: [curriculumProgramId],
  };
  return {
    isData: true,
    isLoading: false,
    userId: userId,
    organizationId: userInfo.org_id,
    organizationName: userInfo.org_name,
    filters,
    curriculumProgramAcronym,
  };
};

export default compose(
  I18nHOC({ resource: ["teacherHomePage"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationCoursesQuery, {
    name: "getOrganizationCourses",
    options: ({ organizationId, filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        filters,
      },
    }),
    props({
      getOrganizationCourses,
      ownProps: { organizationId, isData, isLoading, filters },
    }) {
      const queryData = getOrganizationAllCoursesFromCache({
        organizationId,
        filters,
      });
      const courses = _.map(
        _.get(queryData, "courses.edges", []),
        course => course.node
      );

      return {
        isData: !_.isEmpty(queryData) && isData,
        courses,
        isLoading:
          getOrganizationCourses["networkStatus"] == 1 ||
          getOrganizationCourses["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(ClassSelectionModal);
