import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import InternalTools from "./routes/InternalTools";
import TemplateEditor from "./routes/TemplateEditor";
import Organization from "./routes/Organization";
import OrganizationManagement from "./routes/OrganizationManagement";
import OrganizationManagementRoot from "./routes/OrganizationManagementRoot";
import OrganizationApproval from "./routes/OrganizationApproval";
import UserFeed from "./routes/User";
import StandardFeed from "./routes/Standard";
import UserApproval from "./routes/UserApproval";
import ProgressReportTemplateEditor from "./routes/ProgressReportTemplateEditor";
import OrganizationV2 from "./routes/OrganizationV2";
export default store => ({
  path: ":regionId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("Tooling/modules/ToolingModule").default;
            injectReducer(store, { key: "tooling", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "administrator-tooling"
        );
      })
  ),
  childRoutes: [
    TemplateEditor(store),
    Organization(store),
    InternalTools(store),
    ProgressReportTemplateEditor(store),
    UserApproval(store),
    StandardFeed(store),
    UserFeed(store),
    OrganizationManagement(store),
    OrganizationApproval(store),
    OrganizationManagementRoot(store),
    OrganizationV2(store),
  ],
});
