import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import SubMenuCurriculumWrapper from "Administrator/commonComponents/SubMenuCurriculumWrapper";

export default store => ({
  path: "teachers",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const module2 = require("TeacherSetup/modules/Module");
            const reducer = module2.default;
            const key = module2.NAME;
            injectReducer(store, { key, reducer });

            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
  childRoutes: [SubMenuCurriculumWrapper(store)],
});
