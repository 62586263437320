import React from "react";
import classes from "./TaggedFolders.scss";
import { LinkWithTooltip } from "UIComponents";
import { Tag } from "@toddle-design/web";
const TaggedFolders = React.memo(props => {
  const { folders } = props;
  return (
    <div className={classes.folderTagsContainer}>
      {_.map(folders, folder => {
        return (
          <div className={classes.folderTag}>
            <Tag size={"x-small"}>{folder.name}</Tag>
          </div>
        );
      })}
    </div>
  );
});

export default TaggedFolders;
