import React from "react";
import classes from "./PostDetail.scss";
import PostDetailComp from "./PostDetailComp";
import { CommentBox } from "AppComponents";
import { CancelIcon } from "SvgComponents";
import { I18nHOC, withLoader } from "UIComponents";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { getSettingValue } from "modules/PermissionModule";
import { getCourseIdForPostQuery } from "Post/modules/PostQuery";
import { getCourseIdForPostFromCache } from "Post/modules/PostGraphqlHelpers";
import LEPreview from "AppComponents/LEPreview";
import { ChevronLeftOutlined } from "@toddle-design/web-icons";
import { isJournalCardRevamp } from "Post/utils";
import AssessmentPreview from "./AssessmentPreview";

const styles = {
  wrapperStyle: {
    padding: "32px 40px 48px",
  },
};

class PostDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showComment: !!props.showComment,
      showLEPreview: !!props.showLEPreview,
      shouldGoBack: false,
      assessmentId: "",
    };
  }

  toggleCommentBox = () => {
    const { canOpenComment } = this.props;
    if (canOpenComment) {
      this.setState({ showComment: !this.state.showComment });
    }
  };

  toggleLEPreview = assessmentId => {
    this.setState({
      showLEPreview: !this.state.showLEPreview,
      shouldGoBack: true,
      assessmentId: assessmentId,
    });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { postId, showComment } = this.props;

    if (
      !_.isEqual(postId, nextProps.postId) ||
      !_.isEqual(showComment, nextProps.showComment)
    ) {
      this.setState({ showComment: !!nextProps.showComment });
    }
  };

  renderCommentHeaderComponent = () => {
    const { onCloseClick, t, showCloseBtn, customDetailText } = this.props;

    return (
      <div className={classes.headerDetailContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.headerTextWrapper}>
            <div className={classes.backButton} onClick={this.toggleCommentBox}>
              <ChevronLeftOutlined size={"x-small"} variant={"subtle"} />
            </div>
            <div className={classes.headerText}>
              {t("common:comment_plural")}
            </div>
          </div>
          {showCloseBtn && (
            <div className={classes.closeButton} onClick={onCloseClick}>
              <CancelIcon width={10} heigth={10} />
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { showComment, showLEPreview, shouldGoBack } = this.state;
    const {
      postId,
      onCloseClick,
      t,
      canCreateComment,
      isCurrentAcademicYearSelected = true,
      isRightPaneChat,
      mode = "edit", // disabling liking post and comment actions in view mode,
      assessmentId,
      postDetailContainerStyle,
    } = this.props;
    return showComment ? (
      <CommentBox
        deleteOtherComment={true}
        parentType={"POST"}
        nodeId={postId}
        type={"POST"}
        onClose={onCloseClick}
        disableTagging={true}
        toggleCommentBox={this.toggleCommentBox}
        canCreateComment={canCreateComment}
        renderHeaderComponent={this.renderCommentHeaderComponent}
        isCurrentAcademicYearSelected={isCurrentAcademicYearSelected}
        isRightPaneChat={isRightPaneChat}
        mode={mode}
        commentContainerStyle={{ ...postDetailContainerStyle, paddingTop: 0 }}
      />
    ) : showLEPreview ? (
      <React.Fragment>
        {isJournalCardRevamp() ? (
          <AssessmentPreview
            assessmentId={assessmentId || this.state.assessmentId}
            portalType={"PLANNER"}
            parentType={"JOURNAL"}
            showAttachmentItemV3={true}
            showBackButton={true}
            onClickBackButton={() => {
              shouldGoBack
                ? this.setState({ showLEPreview: !showLEPreview })
                : onCloseClick();
            }}
            innerContainerStyle={{ ...postDetailContainerStyle, maxWidth: 620 }}
          />
        ) : (
          <LEPreview
            assessmentId={assessmentId || this.state.assessmentId}
            portalType={"PLANNER"}
            parentType={"JOURNAL"}
            showAttachmentItemV3={true}
            showBackButton={true}
            onClickBackButton={() => {
              shouldGoBack
                ? this.setState({ showLEPreview: !showLEPreview })
                : onCloseClick();
            }}
            innerContainerStyle={{ ...postDetailContainerStyle, maxWidth: 620 }}
            wrapperStyle={styles.wrapperStyle}
          />
        )}
      </React.Fragment>
    ) : (
      <PostDetailComp
        {...this.props}
        detailsContainerStyle={{
          width: isJournalCardRevamp() ? "380px" : "initial",
          ..._.get(this.props, "detailsContainerStyle", {}),
        }}
        toggleCommentBox={this.toggleCommentBox}
        toggleLEPreview={this.toggleLEPreview}
      />
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const {
    id: userId,
    org_id: organizationId,
    userEntityType,
    user_type: userType,
  } = state.login.userInfo;
  const courseId = state.teacher.selected_class.selected_course;
  const {
    canCreateComment: canCreateCommentProps = true, // exposing can create comment as a prop
    shouldCallQueryForCourseId,
  } = ownProps;

  const isCommentEnabled = props => {
    const courseIdFromQuery = _.get(props, "courseIdFromQuery");
    return (
      canCreateCommentProps &&
      (userType === "staff" ||
        getSettingValue({
          name:
            userType === "student"
              ? "EnableCommentingOnPostByStudents"
              : "enableFamilyCommentCreation",
          courseId: courseIdFromQuery ? courseIdFromQuery : courseId,
          organizationId,
          userId,
          userEntityType,
        }))
    );
  };

  // Enable comment input field based on setting
  const canCreateComment = !shouldCallQueryForCourseId && isCommentEnabled();

  return {
    isData: true,
    isLoading: false,
    canCreateComment,
    isCommentEnabled,
    courseId,
    userType,
  };
};

export default compose(
  connect(mapStateToProps, {}),
  I18nHOC({ resource: ["common"] }),
  // Requirement for this Query: If Post Detail is opened from outside of the class,
  //                             then we don't get accurate courseId related with Post
  // Refer:  StudentProgressReport.js
  graphql(getCourseIdForPostQuery, {
    name: "getCourseIdForPost",
    // execute query based on shouldCallQueryForCourseId prop
    skip: ({ shouldCallQueryForCourseId }) => !shouldCallQueryForCourseId,
    options: ({ postId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: postId,
      },
    }),
    props({
      getCourseIdForPost,
      ownProps: { postId, isData, isLoading, isCommentEnabled },
    }) {
      const postDetails = getCourseIdForPostFromCache({ postId });
      const courseIdFromQuery = _.get(postDetails, "course.id", "");

      const canCreateComment =
        !_.isEmpty(courseIdFromQuery) &&
        isCommentEnabled({ courseIdFromQuery });

      return {
        isData: !_.isEmpty(postDetails) && isData,
        isLoading:
          getCourseIdForPost["networkStatus"] == 1 ||
          getCourseIdForPost["networkStatus"] == 2 ||
          isLoading,
        canCreateComment,
        courseIdFromQuery,
      };
    },
  }),
  withLoader
)(
  // Don't attach ref to PostDetail (withLoader will attach ref if customRef is passed in prop)
  // As per architecture of PostDetail, ref need's to be attached to PostDetailComp
  // To remove ref attached by withLoader. React.forwardRef is used below
  React.forwardRef((props, ref) => {
    return <PostDetail {...props} />;
  })
);

PostDetail.defaultProps = {
  canOpenComment: true,
};
