/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Badge } from "@toddle-design/web";
import { CaretDownFilled } from "@toddle-design/web-icons";

/**--relative-- */
import classes from "./DropdownItemHeader.scss";

const DropdownItemHeader = props => {
  const { isExpanded, onHeaderClick, totalValues, label } = props;

  const dropdownItemHeaderClasses = classNames(
    "text-label-2",
    classes.dropdownItemHeader,
    { [classes.expandedHeader]: isExpanded }
  );

  const accordionElement = (
    <div className={classes.iconContainer}>
      <CaretDownFilled size={"xx-small"} />
    </div>
  );

  return (
    <div className={dropdownItemHeaderClasses} onClick={onHeaderClick}>
      {label}
      <div className={classes.labelSiblingContainer}>
        <Badge
          type={"numeric"}
          variant={"filter"}
          value={totalValues}
          size={"large"}
        />
        {accordionElement}
      </div>
    </div>
  );
};

export default DropdownItemHeader;

DropdownItemHeader.displayName = "DropdownItemHeader";

DropdownItemHeader.propTypes = {
  isExpanded: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  totalValues: PropTypes.number,
  label: PropTypes.string,
};
