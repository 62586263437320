import gql from "graphql-tag";
import {
  pypElementFragment,
  curriculumProgramFragment,
} from "modules/CommonFragments";

export const progressReportBaseTemplateFragment = {
  baseTemplateItemDetails: gql`
    fragment baseTemplateItemDetails on ProgressReportBaseTemplate {
      id
      name
      body
    }
  `,
  baseTemplateList: gql`
    fragment baseTemplateList on ProgressReportBaseTemplate {
      id
      name
    }
  `,
};

export const templateItemDetailFragment = {
  templateItemDetail: gql`
    fragment templateItem on PlannerTemplate {
      id
      body
      unitType
      name
      type
    }
  `,
};

export const benchmarkSetFragment = {
  benchmarkSet: gql`
    fragment benchmarkSetItem on BenchmarkSet {
      id
      name
      subject
      levels {
        id
        value
      }
      benchmarks {
        rootNodeTypes
        nodes {
          ...benchmarkItem
        }
      }
    }
    ${pypElementFragment.benchmark}
  `,
};

export const organizationPresetFragment = {
  orgItem: gql`
    fragment organizationItem on OrganizationPreset {
      id
      name
      city
      ibCode
      country
      domains
      plannerFormat
      copyDataFromOrg
      isRostered
      isFree
      demoUnitPlanIds
    }
  `,
};

export const organizationFragment = {
  organizationList: gql`
    fragment organizationItem on Organization {
      id
      name
      logo
      code
      toddleBuddy
      isPaid
      currentAcademicYear {
        startDate
        endDate
      }
      academicYears {
        id
        startDate
        endDate
        isCurrentAcademicYear
      }
      constants {
        examSessionMonth
        scheduler
        additionalData
      }
      settings {
        name
        value
      }
      courses {
        totalCount
      }
      staff {
        totalCount
      }
      students {
        totalCount
      }
      tier {
        id
        plan
      }
      curriculumPrograms {
        id
        type
        rosteringMethod
        onboardingStatus
        label
        acronym
        buddy {
          firstName
          lastName
          id
        }
        ...subscriptionPlanDetailsItem
        onboardingSteps {
          step
          status
        }
        ...curriculumProgramBasicDetailsItem
      }
    }
    ${curriculumProgramFragment.subscriptionPlanDetails}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
  `,
};

// User Feed

export const teacherFragments = {
  teacherFeed: gql`
    fragment teacherItem on Staff {
      id
      profileImage
      firstName
      lastName
      email
      role
      designation {
        id
        title
      }
      status
      courses {
        id
        title
      }
      organization {
        id
        name
      }
    }
  `,
  teacherDetails: gql`
    fragment teacherItemDetail on Staff {
      id
      firstName
      lastName
      profileImage
      email
      designation {
        id
      }
      organization {
        id
        name
        tags {
          id
          key
          value
        }
      }
      entityTags {
        id
        key
        value
      }
    }
  `,
};

export const organizationStaffFeedFragment = {
  organizationStaffFeedEdge: gql`
    fragment organizationStaffFeedEdgeItem on Organization {
      id
      staff(
        first: 20
        after: $after
        filters: $filters
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        userCount {
          role
          totalCount
        }
        edges {
          node {
            id
            ... on Staff {
              ...teacherItem
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    ${teacherFragments.teacherFeed}
  `,
};

export const standardFragments = {
  standardFeed: gql`
    fragment standardItem on BenchmarkStandardSet {
      id
      title
      description
      globalSubjects
      curriculumName
      country
      state
      framework
    }
  `,
  globalSubjects: gql`
    fragment globalSubjects on GlobalSubject {
      id
      title
      icon
      sequence
    }
  `,
};

export const academicYearFragment = {
  academicYear: gql`
    fragment academicYear on AcademicYear {
      id
      startDate
      endDate
      isCurrentAcademicYear
    }
  `,
};
