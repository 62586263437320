import React from "react";
import PropTypes from "prop-types";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { I18nHOC, EmptyField } from "UIComponents";
import shortId from "shortid";
import InputCheckList from "./components/InputCheckList";

class UIInputCheckList extends UIBaseComponent {
  componentDidMount = () => {
    const { value } = this.props;

    if (value.length == 0) {
      this.handleInit();
    }

    if (this.shouldShowEditEmpty()) {
      this.updateValue([{ id: shortId.generate(), value: "" }]);
    }
  };

  handleInit = () => {
    const {
      options,
      fieldTypeConfig: { shouldInitOptions },
    } = this.props;
    if (shouldInitOptions && options.length > 0) {
      this.updateValue(
        _.map(options, option => {
          return { id: option.value, value: option.label, isSelected: false };
        })
      );
    } else {
      this.updateValue([{ id: shortId.generate(), value: "" }]);
    }
  };

  componentDidUpdate = prevProps => {
    if (
      _.get(this.props.value, "length", 0) == 0 ||
      !_.isEqualWith(prevProps.options, this.props.options, "id")
    ) {
      this.handleInit();
    }
  };

  shouldShowEditEmpty = () => {
    const { options, shouldInitOptions } = this.props;
    if (shouldInitOptions) {
      return _.get(options, "length", 0) == 0;
    } else {
      return false;
    }
  };

  renderEditEmpty = () => {
    const { emptyText, emptyEditText } = this.props;
    return <EmptyField title={emptyEditText || emptyText} />;
  };

  renderEdit = () => {
    const { value, fieldKey, t, listType } = this.props;

    return (
      <InputCheckList
        value={value}
        dndKey={`UI_INPUT_LIST:${fieldKey}`}
        onValueChange={newArr => this.updateValue(newArr)}
        itemPlaceholder={t("common:typeHere")}
        addItemText={t("common:add_another")}
        listType={listType}
      />
    );
  };
}

UIInputCheckList.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

UIInputCheckList.propTypes = {
  ...UIBaseComponent.propTypes,
};

export default I18nHOC({ resource: "common" })(UIInputCheckList);
