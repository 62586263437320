import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
import {
  getAssessmentQuery,
  getAssessmentOfUnitQuery,
  getParentAssesmentQuery,
} from "./AssessmentQuery";
import client from "apolloClient";

export const getAssessmentDetailsFromCache = assessmentId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssessmentQuery,
      variables: {
        id: assessmentId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAssessmentFieldFromCache = field_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PlannerField:${field_id}`,
      fragment: plannerFieldFragment.resourceLibraryField,
      fragmentName: "resourceLibraryFieldItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeAssessmentFieldFragment = ({ field_id, data }) => {
  client.writeFragment({
    id: `PlannerField:${field_id}`,
    fragment: plannerFieldFragment.resourceLibraryField,
    fragmentName: "resourceLibraryFieldItem",
    data: data,
  });
};

export const getAssessmentOfUnitFromCache = ({ unitPlanId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssessmentOfUnitQuery,
      variables: {
        id: unitPlanId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getParentAssessmentFromCache = ({
  parentId,
  parentType,
  searchText,
  grades,
  units,
  academicYears,
  excludeSnPEvidenceFilters,
  curriculumProgramIds,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getParentAssesmentQuery,
      variables: {
        id: parentId,
        type: parentType,
        searchText,
        grades,
        academicYears,
        units,
        curriculumProgramIds,
        excludeSnPEvidenceFilters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
