import React, { useState } from "react";
import classes from "./DescriptorTable.scss";
import classNames from "classnames";
import { DownArrowOutlined, UpArrowOutlined } from "@toddle-design/web-icons";

const DescriptorTable = ({ rowData, firstColumnTitle, secondColumnTitle }) => {
  const [sortingDirection, setSortingDirection] = useState("ASC");

  const updateSortingDirection = () => {
    if (_.isEqual(sortingDirection, "ASC")) setSortingDirection("DESC");
    else setSortingDirection("ASC");
  };

  const tableHeaderClass = classNames("text-label-2", classes.tableHeader);

  const columnWithSortingClass = classNames(
    classes.headerWithSorting,
    "text-label-2"
  );

  const descriptorTableClass = classNames(
    "text-body-2",
    classes.descriptorTable
  );
  const subTextClass = classNames("text-body-1", classes.subText);

  return (
    <table className={descriptorTableClass}>
      <col className={classes.column1} />
      <col className={classes.column2} />
      <tr>
        <th className={columnWithSortingClass} onClick={updateSortingDirection}>
          {firstColumnTitle} &nbsp;
          {_.isEqual(sortingDirection, "ASC") ? (
            <DownArrowOutlined variant="on" />
          ) : (
            <UpArrowOutlined variant="on" />
          )}
        </th>
        <th className={tableHeaderClass}>{secondColumnTitle}</th>
      </tr>
      {_.map(
        _.isEqual(sortingDirection, "ASC") ? rowData : [...rowData].reverse(),
        item => {
          const { achievementLevel, descriptor, subText } = item;
          return (
            <tr key={item?.id}>
              <td className={classes.tableCell}>
                <span className="text-label-2">{achievementLevel}</span>
                {subText && <div className={subTextClass}>{subText}</div>}
              </td>
              <td
                className={classes.tableCell}
                dangerouslySetInnerHTML={{ __html: descriptor }}
              />
            </tr>
          );
        }
      )}
    </table>
  );
};

export default DescriptorTable;
