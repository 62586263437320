import update from "immutability-helper";
import { writeLeFieldFragment } from "./LeGraphqlHelpers";
import {
  getUnitPlanDetailsFromCache,
  writeUnitPlanFragment,
} from "IBPlanner/modules/IBGraphqlHelpers";
import {
  getUnitPlanResourceLibraryFromCache,
  writeUnitPlanResourceLibraryInCache,
} from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import { getUniqlyCombinedGradesDetails } from "Course/modules/CourseGraphqlHelpers";
import {
  updateLeMutation,
  createLeMutation,
  deleteLeMutation,
  copyLEsMutation,
} from "./LeMutations";
import {
  goToRelativeRoute,
  openLinkInNewTab,
  getResolvedOptimisticValue,
} from "modules/Services";
import { setToastMsg } from "Login/modules/LoginModule";
import client from "apolloClient";
import { getRelativePath } from "Utils";

export const NAME = "le";

export const SET_CURRENT_STEP = "SET_CURRENT_STEP" + " " + NAME;
export const UPDATE_LE_IN_REDUX = "UPDATE_LE_IN_REDUX" + " " + NAME;

export const setCurrentStep = data => {
  return { type: SET_CURRENT_STEP, data };
};

export const updateLeInRedux = data => {
  return {
    type: UPDATE_LE_IN_REDUX,
    data,
  };
};

export const updateLeInCache = ({
  fieldUID,
  params,
  fieldObj,
  extraParams,
}) => {
  return (dispatch, getState) => {
    const data = {
      ...fieldObj,
      value: params.value,
      resolvedMinimalTree: getResolvedOptimisticValue({
        resolvedValue: _.get(fieldObj, "resolvedMinimalTree", {}),
        fieldUID,
        params: extraParams,
        value: _.get(params, "value", ""),
      }),
    };
    writeLeFieldFragment({ data, field_id: params.id });
  };
};

export const updateLe = params => {
  return (dispatch, getState) => {
    client.mutate({
      mutation: updateLeMutation,
      variables: {
        leId: getState().le.leData.leId,
        updatedBy: getState().login.userInfo.id,
        fields: params,
      },
    });
  };
};

export const gotoLeDetails = ({ id, mode = "view" }) => {
  return (dispatch, getState) => {
    const route = `le/${id}/${mode}`;
    if (mode == "view") {
      const path = getRelativePath(route);
      dispatch(openLinkInNewTab({ url: path }));
    } else {
      dispatch(goToRelativeRoute({ route }));
    }
  };
};

export const onClose = () => {
  return (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({
        replacePath: "le",
        type: "replace",
        route: "./",
      })
    );
  };
};

export const onClickEdit = () => {
  return (dispatch, getState) => {
    dispatch(goToRelativeRoute({ route: `../edit`, type: "replace" }));
  };
};

export const duplicateLe = ({ leId, title = null }) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const unitPlanData = getState().planner.unitPlanData;
    const unitPlanId = unitPlanData.unitPlanId;

    const unitPlanResourceDetails = getUnitPlanResourceLibraryFromCache({
      id: unitPlanId,
      groupType: "LEARNING_ENGAGEMENT",
    });
    const resourceEdges = _.get(
      unitPlanResourceDetails.resourceLibrary,
      "edges",
      []
    );

    try {
      await client.mutate({
        mutation: copyLEsMutation,
        variables: {
          learningEngagementIds: leId,
          copiedBy: userId,
          unitPlanId: unitPlanId,
          title: title,
        },
        update: (
          cache,
          {
            data: {
              planner: { copyLEs },
            },
          }
        ) => {
          const duplicateLes = _.map(copyLEs, item => {
            return {
              type: "LEARNING_ENGAGEMENT",
              node: item,
              __typename: "ResourceLibraryEdge",
            };
          });

          const updatedResourceEdges = [...resourceEdges, ...duplicateLes];
          const data = {
            ...unitPlanResourceDetails,
            resourceLibrary: {
              ...unitPlanResourceDetails.resourceLibrary,
              leCount: _.get(updatedResourceEdges, "length", 0),
              edges: updatedResourceEdges,
            },
          };

          writeUnitPlanResourceLibraryInCache({
            id: unitPlanId,
            groupType: "LEARNING_ENGAGEMENT",
            data,
          });

          dispatch(
            setToastMsg({
              msg: "toastMsgs:le_successfully_duplicated",
              type: "tick",
              position: "toast-bottom-left",
            })
          );
        },
      });
    } catch (e) {
      writeUnitPlanResourceLibraryInCache({
        id: unitPlanId,
        groupType: "LEARNING_ENGAGEMENT",
        data: unitPlanResourceDetails,
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:le_duplication_failed",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const createLe = ({ title = "", description = "" } = {}) => {
  return async (dispatch, getState) => {
    const userInfo = getState().login.userInfo;
    const userId = getState().login.userInfo.id;
    const unitPlanData = getState().planner.unitPlanData;
    const leData = getState().le.leData;
    const unitPlanId = unitPlanData.unitPlanId;
    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const grades = _.get(unitPlanDetails, "grades", "");

    const plannerTemplates = _.get(
      getUniqlyCombinedGradesDetails(grades),
      "plannerTemplates",
      []
    );

    let templateId = unitPlanDetails.leTemplate;

    if (!templateId) {
      templateId = _.get(
        _.find(
          plannerTemplates,
          template => template.type == "LEARNING_ENGAGEMENT"
        ),
        "id"
      );
    }

    const unitPlanResourceDetails = getUnitPlanResourceLibraryFromCache({
      id: unitPlanId,
      groupType: "LEARNING_ENGAGEMENT",
    });

    const resourceEdges = _.get(
      unitPlanResourceDetails.resourceLibrary,
      "edges",
      []
    );

    let result = "";
    try {
      await client.mutate({
        mutation: createLeMutation,
        variables: {
          templateId: templateId,
          fixedFields: {
            title: leData.title,
            duration: 0,
            description: leData.description,
          },
          unitPlanId: unitPlanId,
          createdBy: userId,
        },
        update: (
          cache,
          {
            data: {
              planner: { createLearningEngagement },
            },
          }
        ) => {
          result = createLearningEngagement.id;
          const updatedResourceEdges = [
            {
              type: "LEARNING_ENGAGEMENT",
              node: createLearningEngagement,
              __typename: "ResourceLibraryEdge",
            },
            ...resourceEdges,
          ];

          const data = {
            ...unitPlanResourceDetails,
            resourceLibrary: {
              ...unitPlanResourceDetails.resourceLibrary,
              leCount: _.get(updatedResourceEdges, "length", 0),
              edges: updatedResourceEdges,
            },
          };

          setTimeout(() =>
            writeUnitPlanResourceLibraryInCache({
              id: unitPlanId,
              groupType: "LEARNING_ENGAGEMENT",
              data,
            })
          );

          // dispatch(
          //   setToastMsg({
          //     msg: "Learning Engagement Successfully created",
          //     type: "tick"
          //   })
          // );
        },
      });
    } catch (e) {
      setTimeout(() =>
        writeUnitPlanResourceLibraryInCache({
          id: unitPlanId,
          groupType: "LEARNING_ENGAGEMENT",
          data: unitPlanResourceDetails,
        })
      );
    }
    return result;
  };
};

export const deleteLe = ({ leId }) => {
  return async (dispatch, getState) => {
    const unitPlanData = getState().planner.unitPlanData;
    const unitPlanId = unitPlanData.unitPlanId;

    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const unitPlanResourceDetails = getUnitPlanResourceLibraryFromCache({
      id: unitPlanId,
      groupType: "LEARNING_ENGAGEMENT",
    });
    const resourceEdges = _.get(
      unitPlanResourceDetails.resourceLibrary,
      "edges",
      []
    );

    const updatedResourceEdges = _.filter(resourceEdges, resource => {
      return !(
        resource.node.id == leId && resource.type == "LEARNING_ENGAGEMENT"
      );
    });

    const data = {
      ...unitPlanDetails,
      resourceLibrary: {
        ...unitPlanDetails.resourceLibrary,
        leCount: _.filter(
          updatedResourceEdges,
          item => item.type == "LEARNING_ENGAGEMENT"
        ).length,
        edges: updatedResourceEdges,
      },
    };

    // console.log(data);

    writeUnitPlanFragment({
      unitPlanId,
      data,
    });

    try {
      await client.mutate({
        mutation: deleteLeMutation,
        variables: {
          id: leId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteLearningEngagement: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteLearningEngagement },
            },
          }
        ) => {
          if (deleteLearningEngagement) {
            const updatedResourceEdges = _.filter(resourceEdges, resource => {
              return resource.node.id != leId;
            });
            const data = {
              ...unitPlanResourceDetails,
              resourceLibrary: {
                ...unitPlanResourceDetails.resourceLibrary,
                leCount: _.get(updatedResourceEdges, "length", 0),
                edges: updatedResourceEdges,
              },
            };

            setTimeout(() =>
              writeUnitPlanResourceLibraryInCache({
                id: unitPlanId,
                groupType: "LEARNING_ENGAGEMENT",
                data,
              })
            );
          }
        },
      });
    } catch (e) {
      setTimeout(() =>
        writeUnitPlanResourceLibraryInCache({
          id: unitPlanId,
          groupType: "LEARNING_ENGAGEMENT",
          data: unitPlanResourceDetails,
        })
      );
    }
  };
};

const REDUCER_HANDLERS = {
  [SET_CURRENT_STEP]: (state, action) => {
    return update(state, { currentStep: { $set: action.data } });
  },
  [UPDATE_LE_IN_REDUX]: (state, action) => {
    const params = action.data;
    Object.keys(params).map(key => {
      state = update(state, {
        leData: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
};

const initialState = {
  currentStep: "",
  leData: {
    leId: "",
    templateId: "",
    title: "",
    description: "",
    mode: "edit",
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
