import React from "react";
import update from "immutability-helper";
import { replace, push } from "react-router-redux";
import moment from "moment";
import { Router, browserHistory } from "react-router";
import { setToastMsg, goToCommunityHome } from "Login/modules/LoginModule";
import {
  goToBack,
  goToPlatformHomeFeatureRoute,
} from "modules/NavigationModule";
import {
  getUnitPlanDetailsFromCache,
  writeUnitPlanFragment,
} from "IBPlanner/modules/IBGraphqlHelpers";
import {
  defaultOrderConstants,
  initFilterObject,
  unitPlanDefaultFilters,
  updateGradeUnitPlanCount,
  updateSubjectForMypInterdisciplinaryUnit,
  getFlatUnitPlanFeedQueryVariables,
  getFlatUnitPlanQueryVariables,
  FIELD_UID_CURRICULUM_MAPPING,
} from "UnitPlans/modules/UnitPlanModule";
import { getYearlySchedulerQuery } from "Scheduler/modules/SchedulerQuery";
import {
  generateRandomId,
  extractLabelsForTranslation,
  getRelativePath,
} from "Utils";
import {
  getUnitPlanFeedFromCache,
  writeUnitPlanFeedInCache,
  writeUnitPlanCountInCache,
  getUnitPlanCountFromCache,
} from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import {
  goToRelativeRoute,
  getResolvedOptimisticValue,
  ASSESSMENT_TOOLS,
  getCurrentAcademicYear,
} from "modules/Services";
import { writeUnitPlanFieldFragment } from "./IBGraphqlHelpers";
import {
  getUnitPlanCollaboratorsDetailsQuery,
  getUnitPlanQuery,
} from "./IBQuery";
import { getRelatedConceptSetQuery } from "modules/CommonQuery";
import {
  shareUnitPlanSectionsMutation,
  createUnitPlanMutation,
  updateUnitPlanMutation,
  createResponseMutation,
  deleteResponseMutation,
  editResponseMutation,
  addRelatedConceptMutation,
  updateUnitPlanCollaboratorsMutation,
} from "./IBMutations";
import * as EventTracker from "lib/eventTracker";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
import { getOrganizationConstantsFromCache } from "modules/CommonGraphqlHelpers";
import { handleEntityTag } from "Community/modules/CommunityModule";
import client from "apolloClient";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import {
  getFlatUnitPlanFeedQuery,
  getFlatUnitPlansQuery,
} from "UnitPlans/modules/UnitPlanQuery";
import { getCourseOverviewElementSetsNotCoveredCountQuery } from "UnitPlans/routes/CourseUnitPlansOverview/modules/Queries";
import {
  mapleBearLikeOrg,
  getUnitPlanCentralRepoInputAndFilters,
} from "Courses/modules/utils";

Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.first = function () {
  return this[0];
};
export const NAME = "planner";

export const CHANGE_CURRENT_STEP = "CHANGE_CURRENT_STEP" + " " + NAME;
export const CHANGE_CURRENT_MODULE_STEP =
  "CHANGE_CURRENT_MODULE_STEP" + " " + NAME;

export const SET_MIDDLE_PANE_SCROLL_TOP =
  "SET_MIDDLE_PANE_SCROLL_TOP" + " " + NAME;

export const RESET_SCROLL_CURRENT_MODULE_STEP =
  "RESET_SCROLL_CURRENT_MODULE_STEP" + " " + NAME;
export const SET_MODULE_ID = "SET_MODULE_ID" + " " + NAME;
export const UPDATE_RESPONSES = "UPDATE_RESPONSES" + " " + NAME;
export const UPDATE_UNIT_PLAN_IN_REDUX =
  "UPDATE_UNIT_PLAN_IN_REDUX" + " " + NAME;
export const UPDATE_COMMENT = "UPDATE_COMMENT" + " " + NAME;

export const RESET_UNIT_PLAN_DATA = "RESET_UNIT_PLAN_DATA" + " " + NAME;

export const UPDATE_EVIDENCE_SEARCH_TEXT =
  "UPDATE_EVIDENCE_SEARCH_TEXT" + " " + NAME;
export const UPDATE_EVIDENCE_FILTERS = "UPDATE_EVIDENCE_FILTERS" + " " + NAME;
export const TOGGLE_ACTION_LOADING = "TOGGLE_ACTION_LOADING" + " " + NAME;

export const UnitPlanDetailsContext = React.createContext();
export const UnitConversationsContext = React.createContext();
export const UnitPlanFieldContext = React.createContext();
export const UnitPlanGradesDetailsContext = React.createContext();
export const PlannerElementsContext = React.createContext();

export const toggleActionLoading = data => {
  return {
    type: TOGGLE_ACTION_LOADING,
    data,
  };
};
export const changeCurrentStep = data => {
  return {
    type: CHANGE_CURRENT_STEP,
    data,
  };
};

export const changeCurrentModuleStep = data => {
  return {
    type: CHANGE_CURRENT_MODULE_STEP,
    data,
  };
};

export const setMiddlePaneScrollTop = data => {
  return {
    type: SET_MIDDLE_PANE_SCROLL_TOP,
    data,
  };
};

export const resetScrollCurrentModuleStep = data => {
  return {
    type: RESET_SCROLL_CURRENT_MODULE_STEP,
    data,
  };
};

export const setModuleId = data => {
  return {
    type: SET_MODULE_ID,
    data,
  };
};

export const resetUnitPlanData = data => {
  return {
    type: RESET_UNIT_PLAN_DATA,
    data,
  };
};

export const updateEvidenceSearchText = data => {
  return {
    type: UPDATE_EVIDENCE_SEARCH_TEXT,
    data,
  };
};

export const updateEvidenceFilters = data => {
  return {
    type: UPDATE_EVIDENCE_FILTERS,
    data,
  };
};

export const updateLocalResponses = data => {
  return {
    type: UPDATE_RESPONSES,
    data,
  };
};

export const updateCommentInRedux = data => {
  return {
    type: UPDATE_COMMENT,
    data,
  };
};

export const updateUnitPlanInRedux = data => {
  return {
    type: UPDATE_UNIT_PLAN_IN_REDUX,
    data,
  };
};

export const getUnitPlanDetailsValueFromFieldKey = ({
  unitPlanDetails,
  mappedDetailsFields,
  key,
}) => {
  switch (key) {
    case "dateDuration": {
      return _.reduce(
        mappedDetailsFields,
        (result, val) => {
          result[val] = _.has(unitPlanDetails[val], "value")
            ? _.get(unitPlanDetails[val], "value", null)
            : unitPlanDetails[val];
          return result;
        },
        {}
      );
    }
    default:
      return null;
  }
};

export const getUnitPlanTabs = ({
  t,
  modules,
  isCommunity,
  unitFlowCount = 0,
  isLocalisedTemplate = true,
  module_list,
  localisedTemplateId,
  isDemo,
}) => {
  if (isCommunity) {
    modules = _.filter(
      modules,
      item => !_.includes(["evidence", "reflection"], item)
    );

    if (unitFlowCount <= 0) {
      modules = _.filter(modules, item => !_.includes(["unitFlow"], item));
    }

    // if (ACLStore.can("TeacherPortal:Community")) {
    modules = [...(modules || []), "comments"];
    // }
  }

  if (isDemo) {
    modules = _.filter(modules, item => !_.includes(["evidence"], item));
  }

  return _.map(modules, key => {
    const count = 0;

    let label = isLocalisedTemplate
      ? t(
          extractLabelsForTranslation({
            keys: ["unitPlan", localisedTemplateId, key, "label"],
          })
        )
      : _.get(module_list, `${key}.label`, "");

    // To solve following issue
    // https://toddle-edu.atlassian.net/browse/FRE-122?atlOrigin=eyJpIjoiOGZmM2YxMzhjNWFlNDU0OWJkNTcwNDE0MDZhNDVmZGMiLCJwIjoiaiJ9
    if (key === "comments" && label === "") {
      label = "Comments"; //TODO: Need to review this
    }

    return { label, value: key, count };
  });
};

export const hasIncludeGivenPathInCurrentRoute = ({ path }) => {
  return _.includes(window.location.href, path);
};

export const goBackToPreviousScreen = ({ mode = "view" } = {}) => {
  return (dispatch, getState) => {
    //For unitplans,admin unitplans, community unit plans route - Because sometime they directly land to this page from email
    if (
      hasIncludeGivenPathInCurrentRoute({ path: "/adminunitplans" }) ||
      hasIncludeGivenPathInCurrentRoute({ path: "/unitlibrary" }) ||
      hasIncludeGivenPathInCurrentRoute({ path: "/community/home" })
    ) {
      dispatch(
        goToRelativeRoute({
          route: "../",
          replacePath: mode,
        })
      );
    } else if (hasIncludeGivenPathInCurrentRoute({ path: "/unitPlans" })) {
      const currentViewType = getState().unitPlans.currentViewType;

      dispatch(
        goToRelativeRoute({
          route: `../${currentViewType}`,
          replacePath: mode,
        })
      );
    }
    //For community/units route
    else if (hasIncludeGivenPathInCurrentRoute({ path: "/community/units" })) {
      dispatch(goToCommunityHome({ isForced: true }));
    } else {
      dispatch(goToBack());
    }
  };
};

export const getUnitPlanCollaboratorsDetails = ({ id }) => {
  return async (dispatch, getState) => {
    await client.query({
      query: getUnitPlanCollaboratorsDetailsQuery,
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });
  };
};
export const addRelatedConcept = ({
  subjects,
  concepts,
  relatedConcepts,
  plannerElementSetId,
}) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;
    try {
      const result = await client.mutate({
        mutation: addRelatedConceptMutation,
        variables: {
          relatedConcepts,
          plannerElementSetId,
        },
        refetchQueries: [
          {
            query: getRelatedConceptSetQuery,
            variables: {
              subjects,
              concepts,
              organizationId,
            },
          },
        ],
      });

      return _.get(result, "data.planner.addRelatedConcept", []);
    } catch (e) {
      return [];
    }
  };
};

export const updateUnitPlanInCache = ({
  fieldUID,
  params,
  fieldObj,
  extraParams,
  fieldTemplateObj,
}) => {
  return (dispatch, getState) => {
    const data = {
      ...fieldObj,
      value: params.value,
      resolvedMinimalTree: getResolvedOptimisticValue({
        resolvedValue: _.get(fieldObj, "resolvedMinimalTree", {}),
        fieldUID,
        fieldTemplateObj,
        params: extraParams,
        value: _.get(params, "value", ""),
      }),
    };

    client.writeFragment({
      id: `PlannerField:${params.id}`,
      fragment: plannerFieldFragment.unitplanField,
      fragmentName: "unitPlanFieldItem",
      data: data,
    });
  };
};

export const deleteResponse = ({ id, fieldId, fieldDetails }) => {
  return async dispatch => {
    const responses = fieldDetails.responses.edges;

    const updatedResponses = _.filter(responses, response => {
      return response.node.id !== id;
    });

    const responseUsersNode = {
      ...fieldDetails.responses,
      edges: updatedResponses,
      totalCount: fieldDetails.responses.totalCount - 1,
    };

    writeUnitPlanFieldFragment({
      fieldId,
      data: { ...fieldDetails, responses: responseUsersNode },
    });

    try {
      await client.mutate({
        mutation: deleteResponseMutation,
        variables: {
          id,
        },
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:deleted", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      console.error(e);
      writeUnitPlanFieldFragment({
        fieldId,
        data: fieldDetails,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw e;
    }
  };
};

export const editResponse = ({
  id,
  fieldId,
  initialPostIds,
  initialAttachmentIds,
}) => {
  return async (dispatch, getState) => {
    const message = _.trim(
      _.get(getState().planner.responses, `${fieldId}.message`, "")
    );

    const fieldEvidence = _.get(
      getState().planner.responses,
      `${fieldId}.evidence`,
      []
    );

    // Handle posts
    const updatedPostList = _.filter(fieldEvidence, evidence =>
      _.has(evidence, "attachments")
    );

    const updatedPostIds = _.map(updatedPostList, _.iteratee("id"));

    const addedPosts = _.difference(updatedPostIds, initialPostIds);
    const removedPosts = _.difference(initialPostIds, updatedPostIds);

    // Handle attachments
    const updatedAttachmentList = _.filter(
      fieldEvidence,
      evidence => !_.has(evidence, "attachments")
    );

    const updatedAttachmentIds = _.map(updatedAttachmentList, _.iteratee("id"));

    const addedAttachments = _.map(
      _.filter(updatedAttachmentList, evidence =>
        _.includes(evidence.id, "NEW_")
      ),
      evidence => _.omit(evidence, ["id", "isPost", "attachmentId"])
    );
    const removedAttachments = _.difference(
      initialAttachmentIds,
      updatedAttachmentIds
    );

    try {
      await client.mutate({
        mutation: editResponseMutation,
        variables: {
          id,
          message,
          evidence: {
            addedPosts,
            removedPosts,
            addedAttachments,
            removedAttachments,
          },
        },
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:updated", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw e;
    }
  };
};

export const createResponse = ({ fieldId, fieldDetails }) => {
  return async (dispatch, getState) => {
    const message = _.trim(
      _.get(getState().planner.responses, `${fieldId}.message`, "")
    );

    const fieldEvidence = _.get(
      getState().planner.responses,
      `${fieldId}.evidence`,
      []
    );

    if (!message && _.isEmpty(fieldEvidence)) {
      dispatch(setToastMsg("toastMsgs:couldnt_be_added"));
      return;
    }

    const userInfo = getState().login.userInfo;

    // Handle new Posts and Attachments
    const addedPosts = _.map(
      _.filter(fieldEvidence, evidence => evidence.isPost),
      _.iteratee("id")
    );
    const addedAttachments = _.map(
      _.filter(fieldEvidence, evidence => !evidence.isPost),
      evidence => _.omit(evidence, ["id", "isPost", "attachmentId"])
    );

    try {
      await client.mutate({
        mutation: createResponseMutation,
        variables: {
          fieldId,
          message,
          evidence: {
            addedPosts,
            addedAttachments,
          },
          createdBy: userInfo.id,
        },
        update: (
          cache,
          {
            data: {
              planner: { addResponseToField },
            },
          }
        ) => {
          const data = {
            ...fieldDetails,
            responses: addResponseToField,
          };

          writeUnitPlanFieldFragment({
            fieldId,
            data,
          });
        },
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:added", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw e;
    }
  };
};

export const getResourceTypes = ({ curriculumType = "IB_PYP" }) => {
  return (dispatch, getState) => {
    let resourcesTypes = [
      {
        key: "ASSESSMENT",
        label: "assessment_with_count",
        labelWithoutCount: "assessment_label",
        type: "ASSESSMENT",
        pluralText: "Assessments",
      },
      {
        key: "LEARNING_ENGAGEMENT",
        label: "le_with_count",
        labelWithoutCount: "le_label",
        type: "LEARNING_ENGAGEMENT",
        pluralText: "Learning Experiences",
      },
    ];

    if (curriculumType == "IB_PYP") {
      resourcesTypes = [
        {
          key: "ASSESSMENT",
          label: "le_with_count",
          labelWithoutCount: "le_label",
          type: "LEARNING_ENGAGEMENT",
          pluralText: "Learning Experiences",
        },
      ];
    }

    return resourcesTypes;
  };
};

//this function append key of isLock in given step_list and field_list data
export const lockFields = ({
  step_list,
  lockField = [],
  updatedStepField,
  updatedFieldList,
}) => {
  //if every field in step is locked then only lock the that particular step
  _.forEach(step_list, (step, stepKey) => {
    let isLocked = true;

    const fields = _.get(updatedStepField[stepKey], "fields", []);

    _.forEach(fields, field => {
      isLocked &= _.includes(lockField, field);
    });

    //update step list those are available in steps list
    updatedStepField[stepKey] = {
      ...step,
      isLocked,
    };
  });

  //lock all fields which has been specified in the template
  _.forEach(lockField, field => {
    const fieldData = updatedFieldList[field];
    const config = _.get(fieldData, "config", {});
    updatedFieldList[field] = {
      ...fieldData,
      config: {
        ...config,
        isLocked: true,
      },
    };
  });
};

//#hack for freemium
export const updateTemplate = ({
  t,
  tool,
  template,
  unitType,
  userType,
  courseId,
  unitPlanDetails,
  curriculumType = "IB_PYP",
  resolvedSubjectNodes = [],
  userLevelType,
  isImported,
  plannerElementSets,
}) => {
  const templateBody = _.get(template, "body", {});
  const stepList = _.get(templateBody, "step_list", {});
  const stageList = _.get(templateBody, "stage_list", {});
  const webElementPYP = _.get(templateBody, "webElementPYP", []);
  const moduleList = _.get(templateBody, "module_list", {});
  const fieldList = _.get(templateBody, "field_list", {});
  const modules = _.get(templateBody, "modules", []);
  const lockFieldsList = _.get(templateBody, `lock_fields`, []);
  const subjectsField = fieldList["subjects"];

  let updatedStepList = { ...stepList };
  let updateFieldList = _.cloneDeep(fieldList);
  const updatedStageList = { ...stageList };
  let updatedWebElementPYP = [...webElementPYP];
  let updatedModuleList = { ...moduleList };
  let updatedModules = [...modules];

  if (curriculumType == CURRICULUM_TYPE_PYP) {
    updatedStepList = {};
    _.forEach(stepList, (step, stepKey) => {
      let fields = [];
      _.forEach(_.get(step, "fields", []), field => {
        let fieldName = field;
        if (
          field == "fmtAssessments" ||
          field == "smtAssessments" ||
          field == "digAssessments"
        ) {
          fieldName = "les";
        }
        fields.push(fieldName);
      });

      // Add support for rubric in unit plan template for PYP
      if (stepKey == "ASSESSMENTTOOL") {
        if (tool) {
          fields = [
            ...fields,
            _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", ""),
          ];
        }
      }

      updatedStepList[stepKey] = { ...step, fields };
    });
    if (fieldList["les"]) {
      updateFieldList = {
        ...updateFieldList,
        les: { ...fieldList["les"], type: "IBAssessments" },
      };
    }

    if (fieldList["studentAction"]) {
      updateFieldList = {
        ...updateFieldList,
        studentAction: {
          ...(fieldList["studentAction"] || {}),
          type: "",
          viewType: "",
          config: {
            ...(_.get(fieldList["studentAction"], "config", {}) || {}),
            showReflection: false,
          },
        },
      };
    }

    updatedWebElementPYP = _.filter(
      webElementPYP,
      element => element.value != "assessment"
    );

    //Remove Theme step/field for standalone unit
    if (unitType == "ibStandAlone") {
      _.forEach(Object.keys(stageList), key => {
        const item = stageList[key];

        const steps = _.filter(item.steps, stepKey => stepKey != "THEME");

        updatedStageList[key] = { ...item, steps };
      });
      _.forEach(Object.keys(moduleList), key => {
        const item = moduleList[key];

        const viewSteps = _.filter(
          item.viewSteps,
          stepKey => stepKey != "THEME"
        );

        updatedModuleList[key] = { ...item, viewSteps };
      });

      _.forEach(Object.keys(updatedStepList), key => {
        const item = updatedStepList[key];

        const fields = _.filter(
          item.fields,
          fieldKey => fieldKey != "theme" && fieldKey != "focusAreas"
        );

        const mandatory = _.filter(
          item.mandatory,
          fieldKey => fieldKey != "theme" && fieldKey != "focusAreas"
        );

        updatedStepList[key] = { ...item, fields, mandatory };
      });

      if (subjectsField) {
        updateFieldList["subjects"] = {
          ...subjectsField,
          config: {
            ...subjectsField.config,
            fieldTypeConfig: {
              ...subjectsField.config.fieldTypeConfig,
              multi: false,
            },
          },
        };
      }
    }
  }
  //For a  MYP Interdisciplinary Planner dynamically addings steps/fields for each subject

  // Move this function to print
  else if (unitType == "ibMYPInterdisciplinary" && !_.isEmpty(templateBody)) {
    const disciplinaryStage = stageList["S_DISCIPLINARY"];
    const disciplinarySteps = disciplinaryStage.steps;
    const disciplinaryStep = stepList["DISCIPLINARY"];
    const unitDetailsModule = moduleList["unitDetails"];

    const subjectDisciplinarySteps = _.map(resolvedSubjectNodes, subject => {
      const stepKey = `DISCIPLINARY_${subject.id}`;
      let subjectDisciplinaryStep = {
        ...disciplinaryStep,
        label: t(
          extractLabelsForTranslation({
            keys: ["unitPlan", "MYPInterdisciplinary", "DISCIPLINARY", "label"],
          }),
          {
            label: subject.name,
          }
        ),
        subLabel: t(
          extractLabelsForTranslation({
            keys: ["unitPlan", "MYPInterdisciplinary", "DISCIPLINARY", "label"],
          }),
          {
            label: subject.name,
          }
        ),
        localisedLabel: t(
          extractLabelsForTranslation({
            keys: ["unitPlan", "MYPInterdisciplinary", "DISCIPLINARY", "label"],
          }),
          {
            label: subject.name,
          }
        ),
        skipLocalisation: true,
      };

      subjectDisciplinaryStep = update(subjectDisciplinaryStep, {
        styles: {
          viewStyle: {
            width: { $set: `${100 / _.size(resolvedSubjectNodes)}%` },
          },
        },
      });

      subjectDisciplinaryStep["fields"] = _.map(
        disciplinaryStep.fields,
        key => {
          const fieldKey = `${key}_${subject.id}`;
          updateFieldList[fieldKey] = {
            ...fieldList[key],
            staticKey: key,
            dynamicSubject: subject,
          };
          return fieldKey;
        }
      );
      updatedStepList[stepKey] = subjectDisciplinaryStep;

      return stepKey;
    });

    const disciplinaryStepEditIndex = _.indexOf(
      disciplinarySteps,
      "DISCIPLINARY"
    );

    const disciplinaryStepIndex = _.indexOf(
      unitDetailsModule.viewSteps,
      "DISCIPLINARY"
    );

    updatedStageList["S_DISCIPLINARY"] = {
      ...disciplinaryStage,
      steps: update(disciplinarySteps, {
        $splice: [[disciplinaryStepEditIndex, 1, ...subjectDisciplinarySteps]],
      }),
    };

    updatedModuleList = update(updatedModuleList, {
      unitDetails: {
        viewSteps: {
          $splice: [[disciplinaryStepIndex, 1, ...subjectDisciplinarySteps]],
        },
      },
    });
  }

  // Handles the unit sharing for non-staff user
  if (_.includes(["student", "parent"], userType)) {
    let sharedSteps = [];
    const tempModules = [];

    const sharedCoursesWithAUser = _.get(
      _.find(_.get(unitPlanDetails, "sharedCoursesWithUserGroup", []), {
        userType,
      }),
      "courses",
      []
    );
    const sharedCourseIds = _.map(sharedCoursesWithAUser, _.iteratee("id"));

    if (_.includes(sharedCourseIds, courseId)) {
      const enabledSectionsForAUser = _.find(
        _.get(unitPlanDetails, "enabledSections", []),
        { userType: "student_parent" } //we need to pass student_parent as we need to share same sections for both users
      );

      sharedSteps = _.get(enabledSectionsForAUser, "sections", []);
    }

    _.forEach(updatedModuleList, (module, key) => {
      const allowedModuleSteps = _.filter(
        _.get(module, "viewSteps", []),
        stepId => {
          const step = updatedStepList[stepId];
          const shareConfig = _.get(step, "shareConfig", {});

          if (_.has(shareConfig, "canShareWithUser")) {
            return shareConfig["canShareWithUser"];
          }

          return true;
        }
      );

      if (!_.isEmpty(_.intersection(allowedModuleSteps, sharedSteps))) {
        tempModules.push(key);
        updatedModuleList[key] = {
          ...updatedModuleList[key],
          viewSteps: _.intersection(allowedModuleSteps, sharedSteps),
        };
      }
      const viewStepList = _.get(updatedModuleList, `${key}.viewSteps`, []);
      const viewStepCount = viewStepList.length;
      //update card style for student and parent view
      _.forEach(viewStepList, (viewStep, index) => {
        //give the number of cards to be displayed in one row when sharing the unit
        //we are passing it this way as teacher can change the steps to be displayed to students or parents so the style may give empty space
        const cardsPerRow = 3;
        let width = 100 / cardsPerRow;
        const lastRowCardCount = viewStepList.length % cardsPerRow;
        if (lastRowCardCount > 0 && viewStepCount - index <= lastRowCardCount)
          width = 100 / lastRowCardCount;

        updatedStepList[viewStep] = {
          ...updatedStepList[viewStep],
          styles: {
            viewStyle: {
              width: width + "%",
            },
          },
        };
      });

      //uncomment the below code if we need to display unit flow in student and parent sharing

      // Handled the case specifically for unit flow
      // if (_.isEqual(key, "unitFlow")) {
      //   const unitFlowResources = _.get(
      //     unitPlanDetails,
      //     "unitFlowResources",
      //     []
      //   );

      //   // If the fetched unit flow resources is not empty then teacher
      //   // has shared resources with user
      //   const isUnitFlowResourcesShared = !_.isEmpty(unitFlowResources);
      //   if (isUnitFlowResourcesShared) {
      //     tempModules.push(key);
      //   }
      // }
    });

    // Using intersection to follow the template order of modules
    updatedModules = _.intersection(updatedModules, tempModules);
  }

  if (curriculumType == CURRICULUM_TYPE_UBD) {
    // Add support for rubric in unit plan template for UBD
    _.forEach(stepList, (step, stepKey) => {
      let fields = step.fields;

      if (stepKey == "ASSESSMENTTOOL") {
        if (tool) {
          fields = [
            ...fields,
            _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", ""),
          ];
        }

        updatedStepList[stepKey] = { ...step, fields };
      }
    });

    // This condition is added for fields and step of planner elements which can be
    // dynamically updated from database and skip the localisation of same
    if (!_.isEmpty(plannerElementSets)) {
      const plannerFields = {};
      const plannerSteps = {};

      // Update planner element field label and subtext
      _.forEach(updateFieldList, (field, fieldKey) => {
        if (_.get(field, "config.type", "") == "PLANNER_ELEMENT") {
          const elementLabel =
            plannerElementSets[
              _.get(field, "config.plannerElementConfig.type", "")
            ]?.label;

          plannerFields[fieldKey] = {
            ...field,
            skipLocalisation: true,
            config: {
              ...field.config,
              label: elementLabel,
              subtext: field.config.subTextLocale
                ? t(field.config.subTextLocale, {
                    label: elementLabel,
                  })
                : field.config.subtext,
              viewLabel: elementLabel,
              fieldTypeConfig: {
                ...field.config.fieldTypeConfig,
                plannerLabel: elementLabel,
              },
            },
          };
        }
      });

      // Update step label for planner elements. Choose only first field label as step label because
      // there can be two planner elements in a step e.g Theme and focus area
      _.forEach(updatedStepList, (step, stepKey) => {
        const firstFieldKey = _.first(_.get(step, "fields", []));
        const field = updateFieldList[firstFieldKey];

        if (_.get(field, "config.type", "") == "PLANNER_ELEMENT") {
          plannerSteps[stepKey] = {
            ...step,
            skipLocalisation: true,
            label:
              plannerElementSets[
                _.get(field, "config.plannerElementConfig.type", "")
              ]?.label,
          };
        }
      });

      updateFieldList = {
        ...updateFieldList,
        ...plannerFields,
      };
      updatedStepList = {
        ...updatedStepList,
        ...plannerSteps,
      };
    }

    if (unitType === "ubdSubjectSpecific" && !_.isEmpty(updateFieldList)) {
      const updatedSubjectsField = updateFieldList["subjects"];

      updateFieldList["subjects"] = {
        ...updatedSubjectsField,
        type: "SelectDropdown",
        config: {
          ...updatedSubjectsField.config,
          subtext: updatedSubjectsField.config.subTextLocale
            ? t(updatedSubjectsField.config.subTextLocale)
            : updatedSubjectsField.config.subtext,
          fieldTypeConfig: {
            ...updatedSubjectsField.config.fieldTypeConfig,
            multi: false,
            shouldStoreArrayValue: true,
          },
        },
      };
    }
  }

  //this will append isLock true
  //if unit is imported from maple bear lib than only lock fields
  /**
   * current locking mechanism condition is only applicable to maple bear
   * here cant apply general condition because of their own different config. on platform
   * one can create general condition and apply in OR in IF condition.
   */
  const mapleBearSpecCondition =
    mapleBearLikeOrg() && userLevelType === "educator" && isImported;

  if (mapleBearSpecCondition) {
    lockFields({
      step_list: stepList,
      lockField: lockFieldsList,
      updatedStepField: updatedStepList,
      updatedFieldList: updateFieldList,
    });
  }

  /**
   * if given organization is maple bear and unit plan is imported from lib and user is
   * educator than remove modules specified in template
   * one can have general condition to remove modules as above
   */
  const condToRemoveModules =
    mapleBearLikeOrg() &&
    _.includes(["global_team", "country_team"], userLevelType);
  if (condToRemoveModules) {
    const removeModuleList = _.get(templateBody, "remove_modules", []);

    updatedModuleList = _.omit(updatedModuleList, removeModuleList);
  }

  return getLocalisedTemplate({
    template: {
      ...template,
      body: {
        ...templateBody,
        modules: updatedModules,
        viewModules: updatedModules,
        module_list: updatedModuleList,
        stage_list: updatedStageList,
        step_list: updatedStepList,
        webElementPYP: updatedWebElementPYP,
        field_list: updateFieldList,
      },
    },
    t,
    templateType: "unitPlan",
  });
};

export const getHelpTextLabel = ({ uid, template }) => {
  const {
    helpEnabledFields,
    body: { step_list = {}, field_list = {} },
  } = template;
  const type = _.get(_.find(helpEnabledFields, { uid }), "type", "FIELD");

  switch (type) {
    case "FIELD": {
      const { config: { label = "", viewLabel = "" } = {} } =
        field_list[uid] ?? {};

      return viewLabel ? viewLabel : label;
    }
    case "STEP": {
      const { label = "" } = step_list[uid] ?? {};
      return label;
    }
    default:
      return "";
  }
};

export const getLocalisedTemplate = ({ template, t, templateType }) => {
  const isLocalisedTemplate = _.get(
    template,
    "body.isLocalisedTemplate",
    false
  );
  const body = _.get(template, "body", {});
  const localisedTemplateId = _.get(template, "body.localisedTemplateId", "");
  const localisationKeys = [
    "label",
    "viewLabel",
    "subLabel",
    "labelForStudent",
    "labelForParent",
    "subtext",
    "prompts",
    "responseHeaderText",
    "placeholder",
    "emptyText",
    "emptyEditText",
    "blockSubText",
  ];
  const localisationLists = [
    "field_list",
    "module_list",
    "stage_list",
    "step_list",
  ];
  if (isLocalisedTemplate) {
    _.forEach(body, (list, key) => {
      if (_.includes(localisationLists, key)) {
        _.forEach(list, (item, itemKey) => {
          if (key != "field_list") {
            body[key][itemKey] = getLocalisedItem({
              item,
              t,
              templateType,
              localisedTemplateId,
              itemKey,
              localisationKeys,
            });
          } else {
            if (!item.skipLocalisation) {
              body[key][itemKey] = {
                ...body[key][itemKey],
                config: getLocalisedConfig({
                  config: _.get(item, "config", {}),
                  t,
                  templateType,
                  localisedTemplateId,
                  itemKey,
                  localisationKeys,
                }),
              };
              if (_.has(item, "subFields")) {
                body[key][itemKey] = {
                  ...body[key][itemKey],
                  subFields: getLocalisedSubFields({
                    subFields: _.get(item, "subFields", {}),
                    t,
                    templateType,
                    localisedTemplateId,
                    itemKey,
                    localisationKeys,
                  }),
                };
              }
            }
          }
        });
      }
    });
  }
  return template;
};

export const getLocalisedSubFields = ({
  subFields,
  t,
  templateType,
  localisedTemplateId,
  localisationKeys,
}) => {
  return _.map(subFields, subField => {
    return {
      ...subField,
      config: getLocalisedConfig({
        config: _.get(subField, "config", {}),
        t,
        templateType,
        localisedTemplateId,
        itemKey: _.get(subField, "key", {}),
        localisationKeys,
      }),
    };
  });
};

export const getLocalisedConfig = ({
  config,
  t,
  templateType,
  localisedTemplateId,
  itemKey,
  localisationKeys,
  configType = "",
}) => {
  let fieldConfig = { ...config };
  _.forEach(config, (value, configKey) => {
    if (_.includes(localisationKeys, configKey)) {
      fieldConfig = {
        ...fieldConfig,
        [configKey]:
          value &&
          t(
            `unitPlanTemplate:${extractLabelsForTranslation({
              keys: [
                templateType,
                localisedTemplateId,
                itemKey,
                configType,
                configKey,
              ],
            })}`
          ),
      };
    }
    if (configKey == "fieldTypeConfig") {
      fieldConfig = {
        ...fieldConfig,
        fieldTypeConfig: getLocalisedConfig({
          config: _.get(config, "fieldTypeConfig", {}),
          t,
          templateType,
          localisedTemplateId,
          itemKey,
          localisationKeys,
          configType: "fieldTypeConfig",
        }),
      };
    }
  });
  return fieldConfig;
};

export const getLocalisedItem = ({
  item,
  t,
  templateType,
  localisedTemplateId,
  itemKey,
  localisationKeys,
}) => {
  let step = { ...item };
  _.forEach(item, (field, fieldKey) => {
    if (!item.skipLocalisation && _.includes(localisationKeys, fieldKey)) {
      step = {
        ...step,
        [fieldKey]:
          field &&
          t(
            `unitPlanTemplate:${extractLabelsForTranslation({
              keys: [templateType, localisedTemplateId, itemKey, fieldKey],
            })}`
          ),
      };
    }
  });
  return step;
};

export const reRouteToStep = step => {
  return (dispatch, getState) => {
    const path = getRelativePath(step);
    dispatch(replace(path));
  };
};

export const changeCurrentStepRoute = step => {
  return (dispatch, getState) => {
    const path = getRelativePath(`../${step}`);
    dispatch(push(path));
  };
};

export const goToNextStep = ({ stages, stage_list, current_step }) => {
  return (dispatch, getState) => {
    const nextStep = dispatch(
      getNextStep({ stages, stage_list, current_step })
    );
    dispatch(changeCurrentStep(nextStep));
  };
};

export const getNextStep = ({ stages, stage_list, current_step }) => {
  return (dispatch, getState) => {
    const current_stage = _.find(stages, (stage, index) => {
      return _.indexOf(stage_list[stage].steps, current_step) >= 0;
    });

    const current_stage_object = stage_list[current_stage];
    const currentIndex = _.indexOf(current_stage_object.steps, current_step);
    let nextStep = "";
    if (currentIndex == current_stage_object.steps.length - 1) {
      const currentStageIndex = _.indexOf(stages, current_stage);
      if (currentStageIndex != stages.length - 1) {
        nextStep = stage_list[stages[currentStageIndex + 1]].steps[0];
      } else {
        nextStep = "";
      }
    } else {
      nextStep = current_stage_object.steps[currentIndex + 1];
    }
    return nextStep;
  };
};

export const getPreviousStep = ({ stages, stage_list, current_step }) => {
  return (dispatch, getState) => {
    const current_stage = _.find(stages, (stage, index) => {
      return _.indexOf(stage_list[stage].steps, current_step) >= 0;
    });
    const current_stage_object = stage_list[current_stage];
    const currentIndex = _.indexOf(current_stage_object.steps, current_step);
    let previousStep = "";
    if (currentIndex == 0) {
      const currentStageIndex = _.indexOf(stages, current_stage);
      if (currentStageIndex != 0) {
        const previousStageSteps =
          stage_list[stages[currentStageIndex - 1]].steps;
        previousStep = previousStageSteps[previousStageSteps.length - 1];
      } else {
        previousStep = "";
      }
    } else {
      previousStep = current_stage_object.steps[currentIndex - 1];
    }
    return previousStep;
  };
};

export const isNextStep = ({ stages, stage_list, current_step }) => {
  return (dispatch, getState) => {
    const lastStage = stages.last();
    const lastStep = stage_list[lastStage].steps.last();
    return lastStep != current_step;
  };
};

export const isFirstStep = ({ stages, stage_list, current_step }) => {
  return (dispatch, getState) => {
    const firstStage = stages.first();
    const firstStep = stage_list[firstStage].steps.first();
    return firstStep == current_step;
  };
};

export const goToUnitPlanFeed = () => {
  return (dispatch, getState) => {
    dispatch(
      goToPlatformHomeFeatureRoute({
        route: `unitPlans`,
        navigateType: "replace",
      })
    );
  };
};

export const updateUnitPlanCollaborator = ({
  collaborators = [],
  grades = [],
  courses = { added: [], removed: [] },
  unitState,
  unitPlanId,
}) => {
  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      await client.mutate({
        mutation: updateUnitPlanCollaboratorsMutation,
        variables: {
          unitPlanId: unitPlanId
            ? unitPlanId
            : getState().planner.unitPlanData.unitPlanId,
          updatedBy: getState().login.userInfo.id,
          collaborators,
          grades,
          courses,
          state: unitState,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:entity_shared",
          type: "tick",
          locale_params: [
            { key: "entity", value: "common:unit_plan", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const shareUnitPlanSections = ({
  sharedCourses,
  sharedSections,
  selectedCourses,
  isShareUserTypeChanged,
  shareWithModalType,
  selectedUnitPlanSteps,
  shareWithUserType,
}) => {
  const unitPlanShareInput = {};

  // To Handle course sharing based on the userType
  if (!_.isEqual(sharedCourses, selectedCourses)) {
    unitPlanShareInput["sharedCoursesWithUserGroup"] = [
      {
        userType: shareWithModalType,
        courses: {
          added: _.difference(selectedCourses, sharedCourses),
          removed: _.difference(sharedCourses, selectedCourses),
        },
      },
    ];
  }

  // To Handle enabledSections based on the userType
  if (!_.isEqual(sharedSections, selectedUnitPlanSteps)) {
    unitPlanShareInput["enabledSections"] = [
      {
        userType: shareWithModalType,
        sections: {
          added: _.difference(selectedUnitPlanSteps, sharedSections),
          removed: _.difference(sharedSections, selectedUnitPlanSteps),
        },
      },
    ];
  }

  // Set share flag true if being share for the first time
  if (isShareUserTypeChanged) {
    unitPlanShareInput["shareWithUserType"] = shareWithUserType;
  }

  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      await client.mutate({
        mutation: shareUnitPlanSectionsMutation,
        variables: {
          unitPlanId: getState().planner.unitPlanData.unitPlanId,
          updatedBy: getState().login.userInfo.id,
          ...unitPlanShareInput,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:entity_shared",
          type: "tick",
          locale_params: [
            { key: "entity", value: "common:unit_plan", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const publishUnPublishInSampleUnits = ({
  unitPlanId,
  publishUnitInSampleUnits = null,
}) => {
  return async (dispatch, getState) => {
    if (!!unitPlanId && !_.isNil(publishUnitInSampleUnits)) {
      const flatUnitPlanFeedQueryVariables = dispatch(
        getFlatUnitPlanFeedQueryVariables()
      );
      await client.mutate({
        mutation: updateUnitPlanMutation,
        variables: {
          unitPlanId,
          updatedBy: getState().login.userInfo.id,
          publishUnitInSampleUnits,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getFlatUnitPlanFeedQuery,
            variables: flatUnitPlanFeedQueryVariables,
          },
        ],
      });
    }
  };
};

export const onDateChange = ({ unitPlan, unitPlanId, startDate, endDate }) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;

    const updatedUnitPlanDetails = {
      ...unitPlan,
      startDate,
      endDate,
    };

    writeUnitPlanFragment({
      unitPlanId,
      data: updatedUnitPlanDetails,
    });

    try {
      await client.mutate({
        mutation: updateUnitPlanMutation,
        variables: { unitPlanId, startDate, endDate, updatedBy: userId },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(
          setToastMsg({ msg: e.graphQLErrors[0].message, notString: true })
        );
      }

      writeUnitPlanFragment({
        unitPlanId,
        data: unitPlan,
      });
    }
  };
};

export const updateUnitPlan = (param = {}) => {
  return async (dispatch, getState) => {
    const { valuesToAdd, valuesToRemove, fieldUID } = param;
    let { unitPlanId } = param;
    if (!unitPlanId && !!getState().planner) {
      unitPlanId = getState().planner.unitPlanData.unitPlanId;
    }

    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const unitType = _.get(
      _.find(_.get(unitPlanDetails, "allFields", []), { uid: "unitType" }),
      "value",
      ""
    );

    if (fieldUID == "subjects" && unitType == "ibMYPInterdisciplinary") {
      dispatch(toggleActionLoading(true));
    }
    await client.mutate({
      mutation: updateUnitPlanMutation,
      variables: {
        unitPlanId,
        updatedBy: getState().login.userInfo.id,
        fields: _.omit(param, ["unitPlanId", "fieldUID"]),
      },
    });

    if (fieldUID == "tags") {
      dispatch(
        handleEntityTag({
          entityId: unitPlanId,
          entityType: "UNIT_PLAN",
          valuesToAdd,
          valuesToRemove,
        })
      );
    }

    //Add/Remove fields on subjects update for Interdisciplinary Unit
    if (fieldUID == "subjects" && unitType == "ibMYPInterdisciplinary") {
      await dispatch(
        updateSubjectForMypInterdisciplinaryUnit({
          unitPlanId,
          addedSubjects: valuesToAdd,
          removedSubjects: valuesToRemove,
        })
      );
      dispatch(toggleActionLoading(false));
    }
  };
};

export const updateUnitPlanDetails = ({
  unitPlanId,
  params = {},
  isCacheRequired = true,
  refetchQueries = false,
  awaitRefetchQueries = false,
  queriesToRefetch = [],
}) => {
  return async (dispatch, getState) => {
    let unitPlanDetails = {};

    if (isCacheRequired) {
      unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);

      const updatedUnitPlanDetails = {
        ...unitPlanDetails,
        ...params,
      };

      writeUnitPlanFragment({
        unitPlanId,
        data: updatedUnitPlanDetails,
      });
    }

    try {
      await client.mutate({
        mutation: updateUnitPlanMutation,
        variables: {
          updatedBy: getState().login.userInfo.id,
          unitPlanId,
          ...params,
        },
        awaitRefetchQueries,
        refetchQueries: refetchQueries ? queriesToRefetch : [],
      });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        if (isCacheRequired) {
          writeUnitPlanFragment({
            unitPlanId,
            data: unitPlanDetails,
          });
        }
      });
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const getUnitPlanDetails = ({ unitPlanId }) => {
  return async (dispatch, getState) => {
    const result = await client.query({
      query: getUnitPlanQuery,
      variables: {
        id: unitPlanId,
      },
      fetchPolicy: "network-only",
    });
    return result;
  };
};

export const createUnitPlan = ({
  collaborators = [],
  createMode = "teacher",
  grades = [],
  courses = [],
  curriculumType = "IB_PYP",
  unitState,
  academicYears = [],
}) => {
  return async (dispatch, getState) => {
    const unitPlanData = getState().planner.unitPlanData;

    const organizationId = getState().login.userInfo.org_id;
    const organizationConstants = getOrganizationConstantsFromCache(
      organizationId
    );
    const { leTemplate, assessmentTemplate } = organizationConstants;

    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id",
      ""
    );

    const fields = _.reduce(
      unitPlanData,
      (prevResult, obj, key) => {
        //  if (!_.includes(fixedUnitPlanIds, key))
        {
          if (obj?.value) {
            prevResult.push({ ...obj, uid: key });
          }
        }
        return prevResult;
      },
      []
    );

    dispatch(initFilterObject());

    const state = getState();
    const courseId = _.get(
      state,
      "teacher.selected_class.selected_course",
      null
    );

    const templateId = _.get(unitPlanData, "plannerType.value", "");

    const startDate = _.get(unitPlanData, "startDate.value", "");

    const endDate = _.get(unitPlanData, "endDate.value", "");

    /** MapleBear specific variables required for flat UnitPlans feed fetching logic */
    const {
      centralRepoInput,
      centralRepoFilters,
    } = getUnitPlanCentralRepoInputAndFilters({ courseId, organizationId });
    /* MapleBear end */

    try {
      const res = await client.mutate({
        mutation: createUnitPlanMutation,
        variables: {
          curriculumProgramId,
          templateId: templateId,
          createdBy: state.login.userInfo.id,
          grades,
          fields: fields,
          collaborators: collaborators,
          academicYears,
          leTemplate,
          assessmentTemplate,
          courses,
          startDate,
          endDate,
          state: unitState,
          showCollaborators: true,
          centralRepoInput,
        },
        refetchQueries: courseId
          ? [
              {
                query: getFlatUnitPlanFeedQuery,
                variables: dispatch(getFlatUnitPlanFeedQueryVariables()),
              },
              ...(_.includes([CURRICULUM_TYPE_DP], curriculumType)
                ? [
                    {
                      query: getCourseOverviewElementSetsNotCoveredCountQuery,
                      variables: {
                        id: courseId,
                        filters: { curriculumType },
                      },
                    },
                  ]
                : []),
              {
                query: getFlatUnitPlansQuery,
                variables: dispatch(getFlatUnitPlanQueryVariables({})),
              },
            ]
          : [],
      });
      EventTracker.recordEvent({
        eventName: "Created Unit",
        eventData: { unit_name: _.get(unitPlanData, "title.value", "") },
      });
      if (createMode == "teacher") {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:unit_successfully_action",
            locale_params: [
              { key: "action", value: "toastMsgs:created", isPlainText: false },
            ],
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      }
      return _.get(res, "data.planner.createUnitPlan.id", null);
    } catch (err) {
      console.error(err);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:unit_creation_failed",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

// export const createUnitPlan = ({
//   successCallBack,
//   failureCallBack,
//   collaborators = [],
//   createMode = "teacher",
//   grades = [],
//   courses = [],
//   curriculumType = "IB_PYP",
//   unitState,
//   academicYears = [],
// }) => {
//   return async (dispatch, getState) => {
//     const unitPlanData = getState().planner.unitPlanData;

//     const organizationId = getState().login.userInfo.org_id;
//     const organizationConstants = getOrganizationConstantsFromCache(
//       organizationId
//     );
//     const { leTemplate, assessmentTemplate } = organizationConstants;

//     const curriculumProgramId = _.get(
//       _.find(getState().platform.organizationCurriculumPrograms, {
//         type: curriculumType,
//       }),
//       "id",
//       ""
//     );

//     const fields = _.reduce(
//       unitPlanData,
//       (prevResult, obj, key) => {
//         //  if (!_.includes(fixedUnitPlanIds, key))
//         {
//           if (obj?.value) {
//             prevResult.push({ ...obj, uid: key });
//           }
//         }
//         return prevResult;
//       },
//       []
//     );

//     dispatch(initFilterObject());

//     const state = getState();
//     const staffId = state.login.userInfo.id;
//     const courseId = getState().teacher.selected_class.selected_course;
//     const type = "COURSE";
//     const filters = _.get(state, "unitPlans.filters", {
//       searchText: "",
//       isShowPrivate: true,
//     });

//     const staffData = getUnitPlanFeedFromCache({
//       id: courseId,
//       filters,
//       groupBy: "UNIT_TYPE",
//       type,
//       showCollaborators: true,
//     });

//     const edges = _.get(staffData, `unitPlans.edges`, []);

//     //const collaborators = state.planner.unitPlanData.collaborators;
//     // const newUnitPlan = {
//     //   id: `${generateRandomId()} loading`,
//     //   collaborators: {
//     //     totalCount: 0,
//     //     edges: [],
//     //     __typename: "UnitPlanCollaboratorConnection"
//     //   },
//     //   title: {
//     //     id: "temp",
//     //     value: unitPlanData.title.value,
//     //     __typename: "PlannerField"
//     //   },
//     //   theme: { id: "loading", value: "Loading...", __typename: "PlannerField" },
//     //   image: {
//     //     id: "image",
//     //     value: _.get(unitPlanData, "image.value", ""),
//     //     __typename: "PlannerField"
//     //   },
//     //   duration: { id: "", value: "", __typename: "PlannerField" },
//     //   subjects: { id: "", value: [], __typename: "PlannerField" },
//     //   resourceLibrary: {
//     //     leCount: 0,
//     //     assessmentCount: 0,
//     //     __typename: "ResourceLibraryConnection"
//     //   },
//     //   templateId: _.get(unitPlanData.selectTemplate, "value", ""),
//     //   __typename: "UnitPlan"
//     // };

//     // console.log(staffId, grade, staffData);

//     const totalCount = _.get(staffData, "unitPlans.totalCount", 0);
//     let e = null;

//     const templateId = _.get(unitPlanData, "plannerType.value", "");

//     const startDate = _.get(unitPlanData, "startDate.value", "");

//     const endDate = _.get(unitPlanData, "endDate.value", "");

//     /*In DP we need to add generic tags which includes subject level and response language
//     genric tags is a object which includes the common tags such as response language of subject and
//     it also includes the subject levels
//     By sending generic tags from here we are defining that this unit is mapped with this particular subject levels and response language
//     */
//     const genericTags = [
//       ..._.get(unitPlanData, "responseLanguage.value", []),
//       ..._.get(unitPlanData, "subjectLevel.value", []),
//     ];

//     const {
//       startDate: startDateAcademicYear,
//       endDate: endDateAcademicYear,
//     } = getCurrentAcademicYear({
//       organizationId,
//     });

//     const isMYP = _.isEqual(curriculumType, "IB_MYP");

//     try {
//       await client.mutate({
//         mutation: createUnitPlanMutation,
//         variables: {
//           curriculumProgramId,
//           templateId: templateId,
//           createdBy: state.login.userInfo.id,
//           grades,
//           fields: fields,
//           collaborators: collaborators,
//           academicYears,
//           leTemplate,
//           assessmentTemplate,
//           courses,
//           startDate,
//           endDate,
//           state: unitState,
//           showCollaborators: true,
//           ...(!_.isEmpty(genericTags) && { genericTags }),
//         },
//         refetchQueries: courseId
//           ? [
//               {
//                 query: getYearlySchedulerQuery,
//                 variables: {
//                   isMYP,
//                   id: courseId,
//                   endDate: endDateAcademicYear,
//                   startDate: startDateAcademicYear,
//                   searchText: filters.searchText,
//                   detailedUnitPlan: false,
//                   uids: FIELD_UID_CURRICULUM_MAPPING[curriculumType],
//                 },
//               },
//               _.includes([CURRICULUM_TYPE_DP], curriculumType) && {
//                 query: getFlatUnitPlansQuery,
//                 variables: {
//                   id: courseId,
//                   uids: [],
//                   orderByDirection: "ASC",
//                   orderBy: "START_DATE",
//                   filters: { courses: [courseId] },
//                 },
//               },
//             ]
//           : [],
//         // optimisticResponse: {
//         //   __typename: "Mutation",
//         //   planner: {
//         //     __typename: "PlannerMutations",
//         //     createUnitPlan: newUnitPlan
//         //   }
//         // },
//         update: (
//           cache,
//           {
//             data: {
//               planner: { createUnitPlan },
//             },
//           }
//         ) => {
//           let updatedStaffData;
//           if (createMode == "teacher") {
//             const fieldValue = _.get(createUnitPlan, "unitType.value", "");
//             const edgeIndex = _.findIndex(edges, item => {
//               return item.fieldValue === fieldValue;
//             });

//             if (edgeIndex < 0) {
//               updatedStaffData = update(staffData, {
//                 unitPlans: {
//                   totalCount: { $set: totalCount + 1 },
//                   edges: {
//                     $push: [
//                       {
//                         __typename: "GroupedUnitPlanEdge",
//                         fieldValue: fieldValue,
//                         type: "UNIT_TYPE",
//                         node: [createUnitPlan],
//                       },
//                     ],
//                   },
//                 },
//               });
//             } else {
//               updatedStaffData = update(staffData, {
//                 unitPlans: {
//                   totalCount: { $set: totalCount + 1 },
//                   edges: {
//                     [edgeIndex]: {
//                       node: {
//                         $splice: [[0, 0, createUnitPlan]],
//                       },
//                     },
//                   },
//                 },
//               });
//             }
//             setTimeout(() => {
//               writeUnitPlanFeedInCache({
//                 id: courseId,
//                 type,
//                 filters,
//                 groupBy: "UNIT_TYPE",
//                 data: updatedStaffData,
//               });
//             });
//             dispatch(updateGradeUnitPlanCount({ count: 1 }));
//           }

//           successCallBack(createUnitPlan);
//         },
//       });
//       EventTracker.recordEvent({
//         eventName: "Created Unit",
//         eventData: { unit_name: _.get(unitPlanData, "title.value", "") },
//       });
//     } catch (err) {
//       console.error(err);
//       failureCallBack();
//       e = err;
//       if (createMode == "teacher") {
//         setTimeout(() => {
//           writeUnitPlanFeedInCache({
//             id: courseId,
//             type,
//             filters,
//             groupBy: "UNIT_TYPE",
//             data: staffData,
//           });
//         });
//       }
//     }
//     if (e) {
//       console.error(e);
//       dispatch(
//         setToastMsg({
//           msg: "toastMsgs:unit_creation_failed",
//           type: "alert",
//           position: "toast-bottom-left",
//         })
//       );
//     } else {
//       if (createMode == "teacher") {
//         dispatch(
//           setToastMsg({
//             msg: "toastMsgs:unit_successfully_action",
//             locale_params: [
//               { key: "action", value: "toastMsgs:created", isPlainText: false },
//             ],
//             type: "tick",
//             position: "toast-bottom-left",
//           })
//         );
//       }
//     }
//   };
// };
const REDUCER_HANDLERS = {
  [CHANGE_CURRENT_STEP]: (state, action) => {
    // console.log(state, action);
    return update(state, {
      current_step: { $set: action.data },

      current_module_step:
        state.module_id && action.data
          ? { [state.module_id]: { $set: action.data } }
          : {},
    });
  },
  [UPDATE_EVIDENCE_FILTERS]: (state, action) => {
    const params = action.data;

    state = update(state, {
      evidenceFilters: { $set: params },
    });

    return state;
  },
  [CHANGE_CURRENT_MODULE_STEP]: (state, action) => {
    return update(state, {
      current_module_step: { [action.data.key]: { $set: action.data.value } },
    });
  },
  [RESET_SCROLL_CURRENT_MODULE_STEP]: (state, action) => {
    return update(state, {
      current_module_step: { $set: {} },
      middlePaneScrollTop: { $set: {} },
    });
  },

  [SET_MIDDLE_PANE_SCROLL_TOP]: (state, action) => {
    return update(state, {
      middlePaneScrollTop: { [action.data.key]: { $set: action.data.value } },
    });
  },

  [SET_MODULE_ID]: (state, action) => {
    return update(state, { module_id: { $set: action.data } });
  },
  [RESET_UNIT_PLAN_DATA]: (state, action) => {
    return update(state, {
      unitPlanData: { $set: _.cloneDeep(initialState.unitPlanData) },
    });
  },

  [UPDATE_EVIDENCE_SEARCH_TEXT]: (state, action) => {
    return update(state, { evidenceSearchText: { $set: action.data } });
  },
  [UPDATE_UNIT_PLAN_IN_REDUX]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, { unitPlanData: { [key]: { $set: params[key] } } });
    });
    return state;
  },
  [UPDATE_COMMENT]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        createComment: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [UPDATE_RESPONSES]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        responses: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [TOGGLE_ACTION_LOADING]: (state, action) => {
    return update(state, {
      isActionLoading: { $set: action.data },
    });
  },
};

const initialState = {
  current_step: null,
  module_id: null,
  library_scrolled_to_bottom: false,
  current_module_step: {},
  middlePaneScrollTop: {},
  isActionLoading: false,
  unitPlanData: {
    unitType: null,
    plannerType: null,
    templateId: "",
    unitPlanId: generateRandomId(),
    startDate: { value: moment().format("YYYY-MM-DD") },
    endDate: {
      value: moment().add(6, "week").format("YYYY-MM-DD"),
    },
    duration: { value: { week: 6 } },
    lois: { value: [{ id: generateRandomId(), value: "" }] },
    collaborators: [],
  },
  responses: {},
  evidenceFilters: [],
  evidenceSearchText: "",
  collaboratorSearchText: "",
  createComment: {
    message: "",
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
