import React from "react";
import classes from "./LevelEditorTable.scss";

import { connect } from "react-redux";
import {
  addNode,
  editNodeInCache,
  editNode,
  removeNode,
  editPlannerNodeInCache,
  removePlannerElementNode,
  updatePlannerElementNode,
  addPlannerNode,
} from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule";

import { AddCircleIcon, EmptySvgIcon } from "SvgComponents";
import { colors } from "Constants";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import { generateRandomId } from "Utils";
import { TableRow, HeaderRow } from "./TableRow";

import { includes as _includes } from "lodash";

import DeleteDialogueBox from "./DeleteDialogueBox";

const NoDataComponent = ({ noDataText }) => {
  return (
    <div className={classes.emptyState}>
      <EmptySvgIcon />
      <div className={classes.emptyText}>{noDataText}</div>
    </div>
  );
};

class LevelEditorTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      emptyNode: {},
      currentDropdownOpenId: null,
      currentDialogAction: "",
      currentGradeEditId: "",
    };
    this.editBoxRefs = {};
    this.focusedIds = {};
    this.gradeSelectorRef = {};
    this.gradesToBeRemoved = [];
    this.currentItemToDelete = {};
    this.warnings = [];
  }

  onDeleteClick = item => {
    this.currentItemToDelete = item;
    this.setState({ currentDialogAction: "DELETE_ITEM" });
    this.removeGradeSelectorBodyClickListener();
  };

  OnToggleDialogueClick = () => {
    this.currentItemToDelete = {};
    this.setState({ currentDialogAction: "" });
    this.addGradeSelectorBodyClickListener();
  };

  onDialogConfirmClick = async () => {
    const {
      removeNode,
      nodeType,
      editNodeInCache,
      editNode,
      updatePlannerElementNode,
      removePlannerElementNode,
      plannerElementSetConfig,
    } = this.props;

    const { currentDialogAction } = this.state;
    const arePlannerElementNodes = this.arePlannerElementNodes();

    switch (currentDialogAction) {
      case "DELETE_ITEM": {
        const { currentItemToDelete } = this;
        if (arePlannerElementNodes) {
          removePlannerElementNode({
            plannerElementSetConfig,
            node: currentItemToDelete,
          });
        } else {
          removeNode({ ...currentItemToDelete, nodeType });
        }
        break;
      }
      case "DELETE_GRADE": {
        const grades = _.get(this.currentItemToDelete, "grades", []);
        const updatedGrades = _.differenceBy(
          grades,
          this.gradesToBeRemoved,
          "id"
        );
        const updateParams = {
          ...this.currentItemToDelete,
          grades: updatedGrades,
        };
        if (arePlannerElementNodes) {
          const { currentItemToDelete, gradesToBeRemoved } = this;
          updatePlannerElementNode({
            node: currentItemToDelete,
            gradesToRemove: gradesToBeRemoved,
          });
        } else {
          editNodeInCache({
            params: updateParams,
            nodeType,
          });
          editNode({
            grades: updatedGrades,
            id: this.currentItemToDelete.id,
            nodeType,
          });
        }

        break;
      }
    }
  };

  componentDidMount = () => {
    this.initEmptyNode(this.props);
  };

  arePlannerElementNodes = () => {
    const { nodeParentType } = this.props;
    return nodeParentType === "PLANNER_ELEMENT";
  };

  updategradeSelectorRef = ({ ref, id }) => {
    this.gradeSelectorRef[id] = ref;
  };

  removeGradeSelectorBodyClickListener = () => {
    setTimeout(() => {
      const { currentDropdownOpenId } = this.state;

      if (this.gradeSelectorRef[currentDropdownOpenId]) {
        this.gradeSelectorRef[
          currentDropdownOpenId
        ].removeDocumentClickListener();
      }
    }, 100);
  };

  addGradeSelectorBodyClickListener = () => {
    setTimeout(() => {
      const { currentDropdownOpenId } = this.state;

      if (this.gradeSelectorRef[currentDropdownOpenId]) {
        this.gradeSelectorRef[currentDropdownOpenId].addDocumentClickListener();
      }
    }, 100);
  };

  onCloseGradeSelector = (shouldBlur = true) => {
    const { currentDropdownOpenId, currentGradeEditId } = this.state;

    if (this.gradeSelectorRef[currentDropdownOpenId]) {
      this.gradeSelectorRef[currentDropdownOpenId].onClose();

      if (shouldBlur) {
        this.onBlurInputField(currentGradeEditId);
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      levelId,
      // nodes,
      parentNode: { id: parentNodeId } = {},
      updateSelectedLevelParentId,
    } = this.props;
    const {
      levelId: nextLevelId,
      // nodes: nextNodes,
      parentNode: { id: nextParentNodeId } = {},
    } = nextProps;

    if (!nextParentNodeId) {
      setTimeout(() => {
        updateSelectedLevelParentId(nextLevelId);
      });
    }
    if (nextLevelId != levelId || nextParentNodeId != parentNodeId) {
      this.initEmptyNode(nextProps);
    }
    if (!_.isEqual(parentNodeId, nextParentNodeId)) {
      this.resetScrollPosition();
    }
  }

  resetScrollPosition = () => {
    if (this.scrollRef) {
      this.scrollRef.scrollTop = 0;
    }
  };

  updateEmptyNode = params => {
    this.setState({ emptyNode: params });
  };

  onGradeEditClick = (item, type) => {
    const { currentDropdownOpenId, currentGradeEditId } = this.state;
    const itemId = `${item.id}_${type}`;
    if (currentDropdownOpenId == itemId) {
      this.onBlurInputField(item.id);
    } else {
      this.onFocusInputField(item.id);
    }

    this.setState({
      currentDropdownOpenId: itemId,
      currentGradeEditId: item.id,
    });
  };

  initEmptyNode = props => {
    const { nodes } = props;

    if (nodes.length == 0) {
      this.addNewNode({ props });
    } else {
      this.updateEmptyNode({});
    }
  };

  updateInputField = (item, params) => {
    const { editNodeInCache, nodeType } = this.props;
    const updatedParams = {};
    _.map(params, (value, key) => {
      updatedParams[key] = value;
    });

    if (this.isNewItem(item.id)) {
      this.updateEmptyNode({ ...item, ...updatedParams });
    } else {
      if (this.arePlannerElementNodes()) {
        editPlannerNodeInCache({ params: { ...item, updatedParams } });
      } else {
        editNodeInCache({ params: { ...item, ...updatedParams }, nodeType });
      }
    }
  };

  getNodeValue = ({ nodeType, updatedParams }) => {
    switch (nodeType) {
      case "MYP_ATL":
      case "UBD_ATL":
        return {
          parent: updatedParams.parentId,
          label: updatedParams.label,
          depth: updatedParams.depth,
          code: updatedParams.code ?? null,
          associatedParents: [
            ..._.map(updatedParams.grades, gradeObj => ({
              id: gradeObj.id,
              type: "GRADE",
            })),
          ],
        };
      default:
        return {
          parent:
            updatedParams.parentId === updatedParams.rootNodeId
              ? null
              : updatedParams.parentId,
          label: updatedParams.label,
          depth: updatedParams.depth,
          code: updatedParams.code ?? null,
          associatedParents: [
            {
              type: "SUBJECT",
              id: updatedParams.rootNodeId,
            },
            ..._.map(updatedParams.grades, gradeObj => ({
              id: gradeObj.id,
              type: "GRADE",
            })),
          ],
        };
    }
  };

  onBlurInputField = async (id, params = {}, type) => {
    const {
      addNode,
      editNode,
      nodeType,
      nodes,
      editNodeInCache,
      addPlannerNode,
      updatePlannerElementNode,
      plannerElementSetConfig,
    } = this.props;

    const arePlannerElementNodes = this.arePlannerElementNodes();
    const { emptyNode } = this.state;
    let item = {};
    if (this.isNewItem(id)) {
      item = { ...emptyNode };
    } else {
      item = _.find(nodes, { id });
    }

    let updatedParams = {};
    //use forEach
    _.map(item, (value, key) => {
      if (value) {
        updatedParams[key] = value;
      }
    });
    updatedParams = { ...updatedParams, ...params };
    if (this.isNewItem(id)) {
      const totalCount = this.editBoxRefs[id].isValid();
      setTimeout(() => {
        this.focusedIds[id] -= 1;

        if (this.focusedIds[id] <= 0) {
          if (totalCount == 0) {
            this.updateEmptyNode({});
            if (arePlannerElementNodes) {
              const node = this.getNodeValue({ nodeType, updatedParams });
              addPlannerNode({
                plannerElementSetConfig,
                node,
                nodeType,
              });
            } else {
              addNode({
                ...updatedParams,
                nodeType,
                plannerElementSetConfig,
              });
            }
          } else {
            this.updateEmptyNode(updatedParams);
          }
        }
      }, 100);
    } else {
      if (!_.isEmpty(params)) {
        const isIncludeGrades = _.has(params, "grades");
        const isIncludeLabel = _.has(params, "label");
        if (isIncludeLabel && !_.get(params, "label", "")) {
          return;
        }
        const removedGrades = _.differenceBy(
          item.grades,
          params["grades"],
          "id"
        );
        if (!isIncludeGrades || type == "ADD") {
          if (arePlannerElementNodes) {
            updatePlannerElementNode({
              node: updatedParams,
              gradesToAdd: params.grades,
            });
          } else {
            editNodeInCache({
              params: updatedParams,
              nodeType,
            });
            editNode({
              ...params,
              id,
              nodeType,
            });
          }
          this.gradesToBeRemoved = [];
        } else {
          this.gradesToBeRemoved = removedGrades;
          this.currentItemToDelete = item;
          this.setState({ currentDialogAction: "DELETE_GRADE" });
          this.removeGradeSelectorBodyClickListener();
        }
      }
    }
  };

  onFocusInputField = id => {
    if (!this.focusedIds[id]) {
      this.focusedIds[id] = 0;
    }
    this.focusedIds[id] += 1;
  };

  addNewNode = params => {
    const props = _.get(params, "props", this.props);
    const {
      levelId,
      parentNode: { id: parentId, grades } = {},
      rootNode: { subject, id: rootNodeId } = {},
      levels,
    } = props;

    const { emptyNode } = this.state;

    if (
      !_.isEmpty(emptyNode) &&
      !emptyNode.label &&
      emptyNode.parentId == parentId
    ) {
      if (this.editBoxRefs[emptyNode.id]) {
        this.editBoxRefs[emptyNode.id].isValid();
      }
      return;
    }
    const id = `NEW${generateRandomId()}`;

    const depth = _.find(levels, l => l.id === levelId)?.depth ?? 0;
    this.updateEmptyNode({
      levelId,
      parentId,
      subject,
      depth,
      grades: [],
      label: "",
      id,
      isLocal: true,
      rootNodeId,
      atlCategory: { id: rootNodeId },
      benchmarkQuestionCount: [],
    });
  };

  onAddNodeClick = () => {
    setTimeout(() => {
      this.addNewNode();
    }, 150);
  };

  isNewItem = id => {
    return _.includes(id, "NEW");
  };

  getTitleText = () => {
    const { levelIdIndex, levels, rootNode, parentNode } = this.props;

    // const levelName = _.get(levels[levelIdIndex], "value", "");
    let detailString = "";
    // const previousLevelName = "";

    if (levelIdIndex > 0) {
      detailString = _.get(parentNode, "label", "");
    } else {
      detailString = _.get(rootNode, "label", "");
    }

    return `${detailString}`; //${levelName}`;
  };

  getNoDataText = () => {
    const { levelIdIndex, levels, rootNode, t } = this.props;
    const levelName = _.get(
      levels[levelIdIndex],
      "value",
      _.get(levels[levelIdIndex], "label", "")
    );
    if (levelIdIndex > 0) {
      const previousLevelName = _.get(levels[levelIdIndex - 1], "value", "");
      return t("academicSetup:sns_level_editor_no_data_text", {
        previousLevelName,
        rootNode: rootNode.label,
        levelName,
      });
    }
    return "";
  };

  updateEditBoxRefs = (id, ref) => {
    this.editBoxRefs[id] = ref;
  };

  updateScrollRef = ref => {
    this.scrollRef = ref;
  };

  shouldShowCode = () => {
    const { nodeType } = this.props;
    return _includes(
      [
        "BENCHMARK",
        "MYP_LEARNING_STANDARD",
        "UBD_LEARNING_STANDARD",
        "UBD_ATL",
        "MYP_ATL",
      ],
      nodeType
    );
  };

  render() {
    const {
      nodes = [],
      levelIdIndex,
      levels = [],
      parentNode,
      parentNodeId,
      nodeType,
      t,
      allNodes,
      showGrade,
    } = this.props;
    const parentGrades = parentNode?.grades ?? [];
    if (!parentNode.id) {
      const noDataText = this.getNoDataText();
      return <NoDataComponent noDataText={noDataText} />;
    }

    const levelName = _.get(
      levels[levelIdIndex],
      "value",
      _.get(levels[levelIdIndex], "label", "")
    );
    const { emptyNode, currentDialogAction, currentGradeEditType } = this.state;

    const titleText = this.getTitleText();
    const nodesLength = _.get(nodes, "length", 0);
    let updatedNodes = [...nodes];

    if (!_.isEmpty(emptyNode)) {
      updatedNodes = [...updatedNodes, emptyNode];
    }

    const showDescription =
      nodeType == "ATL" && levelIdIndex == levels.length - 1;

    const showCode = this.shouldShowCode();

    return (
      <div className={classes.container} ref={this.updateScrollRef}>
        <div className={classes.countText}>{`${nodesLength} ${levelName}`}</div>
        <div className={classes.tableContainer}>
          <HeaderRow
            showDescription={showDescription}
            showCode={showCode}
            titleText={titleText}
            levelName={levelName}
            t={t}
            nodeType={nodeType}
            showGrade={showGrade}
          />
          {_.map(updatedNodes, (item, index) => {
            const { id, isLocal } = item;
            const showDelete = !this.isNewItem(id);
            const isDisabled =
              this.isNewItem(parentNodeId) || (this.isNewItem(id) && !isLocal);
            return (
              <TableRow
                key={item.id}
                item={item}
                isDisabled={isDisabled}
                updateInputField={this.updateInputField}
                levelIdIndex={levelIdIndex}
                updateGradeRef={this.updateGradeRef}
                index={index}
                parentGrades={parentGrades}
                onBlurInputField={this.onBlurInputField}
                onFocusInputField={this.onFocusInputField}
                updateEditBoxRefs={this.updateEditBoxRefs}
                onGradeEditClick={this.onGradeEditClick}
                updategradeSelectorRef={this.updategradeSelectorRef}
                onDeleteClick={this.onDeleteClick}
                showDelete={showDelete}
                showDescription={showDescription}
                nodeType={nodeType}
                parentNodeId={parentNodeId}
                showCode={showCode}
                t={t}
                currentGradeEditType={currentGradeEditType}
                onCloseGradeSelector={this.onCloseGradeSelector}
                showGrade={showGrade}
              />
            );
          })}
        </div>

        <div className={classes.addButton} onClick={this.onAddNodeClick}>
          <AddCircleIcon width={16} height={16} fill={colors.blue29} />
          <div className={classes.addButtonText}>
            {t("common:add_with_label", { label: levelName })}
          </div>
        </div>
        {!!currentDialogAction && (
          <DeleteDialogueBox
            OnToggleDialogueClick={this.OnToggleDialogueClick}
            levelName={levelName}
            action={currentDialogAction}
            gradesToBeRemoved={this.gradesToBeRemoved}
            itemToDelete={this.currentItemToDelete}
            nodeType={nodeType}
            onConfirmClick={this.onDialogConfirmClick}
            levels={levels}
            levelIdIndex={levelIdIndex}
            nodes={allNodes}
          />
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  editNodeInCache,
  addNode,
  editNode,
  removeNode,
  removePlannerElementNode,
  updatePlannerElementNode,
  addPlannerNode,
};

const getParentAndChildNodes = ({ nodes, parentNodeId }) => {
  let childNodes = [];
  const parentNode = _.find(nodes, item => item.id == parentNodeId);
  if (parentNode) {
    const children = _.get(parentNode, "children", []);
    _.forEach(children, childId => {
      const childNode = _.find(nodes, item => item.id == childId);

      if (childNode) {
        childNodes = [...childNodes, childNode];
      }
    });
  }
  return { childNodes, parentNode };
};

const updateNodeGrades = ({
  node,
  rootNode,
  levelIdIndex,
  grades,
  nodeType,
}) => {
  if (levelIdIndex == 0) {
    return _.get(rootNode, "grades", []);
  } else {
    const nodeGrades = _.get(node, "grades", []);

    return _.filter(
      grades,
      option => _.findIndex(nodeGrades, item => item.id == option.id) >= 0
    );
  }
};

const LevelEditorTableWrapper = props => {
  const {
    nodes,
    parentNodeId,
    levelIdIndex,
    grades,
    rootNode,
    nodeType,
  } = props;
  const { childNodes, parentNode } = getParentAndChildNodes({
    nodes,
    parentNodeId,
  });
  const transformedProps = {
    allNodes: nodes,
    nodes: childNodes,
    parentNode: {
      ...parentNode,
      grades: updateNodeGrades({
        node: parentNode,
        rootNode,
        levelIdIndex,
        grades,
        nodeType,
      }),
    },
  };
  return <LevelEditorTable {...props} {...transformedProps} />;
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(null, mapActionCreators)
)(LevelEditorTableWrapper);
