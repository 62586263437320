import React from "react";
import PropTypes from "prop-types";

const MultiFileIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 16">
      <g>
        <g id="clone" fill={props.fill}>
          <path
            d="M9.4071,7.8565 L9.4031,13.1731 C9.3818,13.9103 8.7915,14.4872 8.057,14.4872 L2.6883,14.4872 C1.9525,14.4872 1.3622,13.9103 1.3381,13.1317 L1.3421,7.8138 C1.3622,7.0766 1.9525,6.4997 2.6883,6.4997 L8.057,6.4997 C8.7915,6.4997 9.3818,7.0779 9.4071,7.8565 M8.057,5.1628 L2.6883,5.1628 C1.2219,5.1628 0.0441,6.3127 0.012,7.731 L0,13.1904 L0.0093,13.2813 C0.0841,14.7075 1.2593,15.8253 2.6883,15.8253 L8.057,15.8253 C9.5206,15.8253 10.6985,14.6755 10.7346,13.2545 L10.7453,7.7951 L10.7359,7.7056 C10.6585,6.2793 9.4819,5.1628 8.057,5.1628"
            id="Fill-40"
          />
          <path
            d="M15.9907,3.1187 C15.9132,1.6925 14.7367,0.5746 13.3117,0.5746 L7.943,0.5746 C6.4767,0.5746 5.2988,1.7245 5.2694,3.1254 L5.2534,3.263 C5.2534,3.6236 5.5485,3.9053 5.9225,3.9053 C6.1535,3.9053 6.3725,3.7745 6.4874,3.5755 C6.5541,3.4713 6.5915,3.3538 6.5955,3.2269 C6.6156,2.4897 7.2072,1.9128 7.9417,1.9128 L13.3104,1.9128 C14.0449,1.9128 14.6352,2.4897 14.6619,3.2683 L14.6579,8.5849 C14.6352,9.3221 14.0449,9.899 13.3104,9.899 L12.6226,9.899 C12.2527,9.899 11.9535,10.1995 11.9535,10.5681 C11.9535,10.9367 12.2527,11.2372 12.6226,11.2372 L13.3104,11.2372 C14.774,11.2372 15.9519,10.0873 15.9867,8.6704 L16,3.2096 L15.9907,3.1187 Z"
            id="Fill-41"
          />
        </g>
      </g>
    </svg>
  );
};

MultiFileIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

MultiFileIcon.defaultProps = {
  height: 16,
  width: 16,
  fill: "#000",
};

export default MultiFileIcon;
