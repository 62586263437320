import client from "apolloClient";
import {
  organizationGeneratedTemplateFragment,
  progressReportTemplateFragment,
  organizationProgressReportTemplateFragment,
  progressReportFragment,
  courseProgressReportFragment,
  studentProgressReportFragment,
  progressReportRemarkFragment,
  publishedStudentProgressReportFragment,
  studentProgressReportAttendanceFragment,
  organizationProgressReportGroupFragment,
  setProgressReportFragment,
  progressReportTaskGroupFragment,
  studentProgressReportAttendanceSummaryFragment,
} from "./ProgressReportFragments";

import {
  getCourseStudentProgressReportFeedQuery,
  getCourseStudentProgressReportBasicDetailsQuery,
  getCourseStudentProgressReportTemplateQuery,
  getStudentProgressReportPublishedTemplateQuery,
  getStudentProgressReportDetailsQuery,
  getProgressReportTemplateQuery,
  getProgressReportTemplateFeedQuery,
  getOrganizationProgressReportGroupFeedQuery,
  getSetProgressReportFeedQuery,
  progressReportGeneratedTemplateQuery,
  getProgressReportTemplatesQuery,
  getCourseWiseStudentBasicDetailsQuery,
  getCourseWiseProgressReportMetricsQuery,
  getCourseDetailsQuery,
  getStudentProgressReportTemplateQuery,
  getStudentProgressReportBasicDetailsQuery,
  getSubjectGroupWiseReportMetricsQuery,
  getStudentProgressReportSubjectsQuery,
  getSetProgressReportGradeSubjectObjectivesQuery,
  getStudentProgressReportUnitsConfigurationQuery,
  getCourseWiseMetricsQuery,
  getOrganizationCourseFeedV1Query,
  getStudentsFromGradeQuery,
  getCourseProgressReportStudentsDetailQuery,
  getStaffCoursesQuery,
  getCourseProgressReportTaskBasicDetailsQuery,
} from "./ProgressReportQuery";

export const organizationGeneratedTemplateFragmentFromCache = ({
  organizationId,
  grades,
  curriculumType,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: progressReportGeneratedTemplateQuery,
      variables: {
        organizationId,
        grades,
        curriculumType,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getProgressReportDetailsFromCache = ({ id, skipParam }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProgressReportTemplateQuery,
      variables: {
        id,
        skipParam,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeProgressReportDetailsInCache = ({ id, data }) => {
  let queryData = {};

  try {
    queryData = client.writeFragment({
      id: `ProgressReportTemplate:${id}`,
      fragment: progressReportTemplateFragment.progressReportTemplateDetails,
      fragmentName: "progressReportTemplateItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationProgressReportTemplateFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProgressReportTemplateFeedQuery,
      variables: {
        organizationId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeOrganizationProgressReportTemplateInCache = ({
  organizationId,
  data,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.writeFragment({
      id: `Organization:${organizationId}`,
      variables: {
        filters,
      },
      fragment:
        organizationProgressReportTemplateFragment.organizationProgressReportTemplate,
      fragmentName: "organizationProgressReportTemplateItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationProgressReportGroupInCache = ({
  organizationId,
  searchText,
  academicYearId,
  curriculumProgramIds,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationProgressReportGroupFeedQuery,
      variables: {
        organizationId,
        searchText,
        academicYearId,
        curriculumProgramIds,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSetProgressReportsFromCache = ({ setId, searchText }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getSetProgressReportFeedQuery,
      variables: {
        id: setId,
        searchText,
      },
    });
    // console.log("QUERY DATA", queryData);
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
export const getSetProgressReportGradeSubjectObjectivesFromCache = ({
  setId,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSetProgressReportGradeSubjectObjectivesQuery,
      variables: {
        id: setId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getProgressReportFromCache = ({ progressReportId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `CourseProgressReportTask:${progressReportId}`,
      fragment: progressReportFragment.progressReportFeed,
      fragmentName: "progressReportItem",
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getProgressReportTemplatesFromCache = ({ id, filters }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProgressReportTemplatesQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeOrganizationProgressReportGroupInCache = ({
  organizationId,
  data,
  searchText,
  academicYearId,
  curriculumProgramIds,
}) => {
  client.writeFragment({
    id: `Organization:${organizationId}`,
    fragment:
      organizationProgressReportGroupFragment.organizationProgressReportGroup,
    fragmentName: "organizationProgressReportGroupItem",
    variables: {
      searchText,
      academicYearId,
      curriculumProgramIds,
    },
    data,
  });
};

export const writeSetProgressReportsInCache = ({ setId, data, searchText }) => {
  client.writeFragment({
    id: `ProgressReportTaskGroup:${setId}`,
    fragment: setProgressReportFragment.setProgressReport,
    fragmentName: "setProgressReportFragmentItem",
    variables: {
      searchText,
    },
    data,
  });
};

export const writeSetFeedProgressReportsInCache = ({ setId, data }) => {
  client.writeFragment({
    id: `ProgressReportTaskGroup:${setId}`,
    fragment: progressReportTaskGroupFragment.ProgressReportTaskGroupFeed,
    fragmentName: "progressReportTaskGroupItem",
    data,
  });
};

export const getCourseProgressReportFeedCountInCache = ({ courseId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Course:${courseId}`,
      fragment: courseProgressReportFragment.courseProgressReportFeedCount,
      fragmentName: "courseProgressReportItem",
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCourseProgressReportFeedInCache = ({
  courseId,
  searchText,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Course:${courseId}`,
      fragment: courseProgressReportFragment.courseProgressReportFeed,
      fragmentName: "courseProgressReportItem",
      variables: {
        searchText: searchText,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCourseStudentProgressReportFeedInCache = ({
  reportId,
  orderBy,
  orderByDirection,
  filters,
  courseFilters,
  orderByInput,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseStudentProgressReportFeedQuery,
      variables: {
        reportId,
        orderBy,
        orderByDirection,
        filters,
        courseFilters,
        orderByInput,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseProgressReportStudentsDetailFromCache = ({
  reportId,
  filters,
  orderBy,
  orderByDirection,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseProgressReportStudentsDetailQuery,
      variables: {
        reportId,
        filters,
        orderBy,
        orderByDirection,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSubjectGroupWiseReportMetricsFeedFromCache = ({
  reportId,
  searchText,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSubjectGroupWiseReportMetricsQuery,
      variables: {
        reportId,
        searchText,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseWiseReportMetricsFeedFromCache = ({
  reportId,
  searchText,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseWiseMetricsQuery,
      variables: {
        reportId,
        searchText,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseStudentProgressReportBasicDetailsFromCache = ({
  reportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseStudentProgressReportBasicDetailsQuery,
      variables: {
        reportId,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseStudentProgressReportTemplateFromCache = ({
  reportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseStudentProgressReportTemplateQuery,
      variables: {
        reportId,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentProgressReportTemplateFromCache = ({
  studentProgressReportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportTemplateQuery,
      variables: {
        studentProgressReportId,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentProgressReportDetailsFromCache = ({
  studentProgressReportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportDetailsQuery,
      variables: {
        studentProgressReportId,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentProgressReportPublishedTemplateFromCache = ({
  studentProgressReportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportPublishedTemplateQuery,
      variables: {
        studentProgressReportId,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeStudentProgressReportDetailsInCache = ({
  studentProgressReportId,
  data,
}) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `StudentProgressReport:${studentProgressReportId}`,
      fragment: studentProgressReportFragment.studentProgressReportDetails,
      fragmentName: "studentProgressReportItem",
      data,
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getStudentProgressReportRemarkFromCache = ({ remarkId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `StudentProgressReportRemark:${remarkId}`,
      fragment: progressReportRemarkFragment.progressReportRemark,
      fragmentName: "progressReportRemarkItem",
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentProgressReportRemarkInCache = ({ remarkId, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `StudentProgressReportRemark:${remarkId}`,
      fragment: progressReportRemarkFragment.progressReportRemark,
      fragmentName: "progressReportRemarkItem",
      data,
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const publishedStudentProgressReportFragmentFromCache = ({
  studentId,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `Student:${studentId}`,
      fragment:
        publishedStudentProgressReportFragment.publishedStudentProgressReport,
      fragmentName: "publishedStudentProgressReportItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentProgressReportStatusInCache = ({ reportId, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `StudentProgressReport:${reportId}`,
      fragment: studentProgressReportFragment.studentProgressReportStatus,
      fragmentName: "studentProgressReportStatusItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getStudentProgressReportSubjectsFromCache = ({
  studentProgressReportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportSubjectsQuery,
      variables: {
        studentProgressReportId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseWiseProgressReportMetricsFromCache = ({ setId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseWiseProgressReportMetricsQuery,
      variables: {
        id: setId,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseWiseStudentBasicDetailsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseWiseStudentBasicDetailsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    console.error(e);
  }
  if (queryData) return queryData;
  else return {};
};

export const getStudentProgressReportBasicDetailsFromCache = ({
  studentProgressReportId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportBasicDetailsQuery,
      variables: {
        studentProgressReportId,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentProgressReportUnitsConfigurationFromCache = ({
  id,
  duration,
  subjects,
  unitTypes,
  fieldUids,
  academicYears,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProgressReportUnitsConfigurationQuery,
      variables: {
        id,
        duration,
        subjects,
        unitTypes,
        fieldUids,
        academicYears,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getOrganizationCourseFeedV1FromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationCourseFeedV1Query,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getIndividualStudentProgressReportDetailsFromCache = ({
  studentProgressReportId,
}) => {
  let fragmentData = {};
  try {
    fragmentData = client.readFragment({
      id: `StudentProgressReport:${studentProgressReportId}`,
      fragment: studentProgressReportFragment.studentProgressReportFeed,
      fragmentName: "studentProgressReportItem",
    });
  } catch (e) {
    return {};
  }
  return fragmentData;
};

export const writeIndividualStudentProgressReportDetailsInCache = ({
  studentProgressReportId,
  data,
}) => {
  try {
    client.writeFragment({
      id: `StudentProgressReport:${studentProgressReportId}`,
      fragment: studentProgressReportFragment.studentProgressReportFeed,
      fragmentName: "studentProgressReportItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStudentsFromGradeFromCache = ({ gradeId, studentFilters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentsFromGradeQuery,
      variables: {
        id: gradeId,
        studentFilters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStaffCoursesFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffCoursesQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseProgressReportTaskBasicDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseProgressReportTaskBasicDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
