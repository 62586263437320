import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classes from "./LibraryBody.scss";
import { DialogueBox, I18nHOC, FullScreenLoader, UIModal } from "UIComponents";
import {
  importAssessment,
  updateFilters,
  cloneAssessment,
  gotoAssessmentDetails,
} from "Assessments/modules/AssessmentModule";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { AddToCommunityButton } from "AppComponents/Community";
import AddLE from "./AddLE";
import { updateCoachMarkStatus } from "modules/CoachMarksModule";
import { checkCommunityActiveStatusAndGoToOnboarding } from "Community/modules/CommunityModule";
import { EmptyLE, EmptyAssessment } from "SvgComponents";
import FeedsContainer from "./FeedsContainer";
import { SearchInput } from "@toddle-design/web";
import AddAssignment from "ClassRoom/components/AddAssignment";
import {
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
  colors,
} from "Constants";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getLELibraryLabelsMemoize } from "UnitPlans/modules/Utils";

const TYPE_DETAILS = {
  ASSESSMENT: {
    label: "common:assessment_label_plural",
    placeholder: "E.g. Sorting and classfiying etc.",
    noDataComp: () => <EmptyAssessment />,
  },
  LEARNING_ENGAGEMENT: {
    label: "common:le_label_plural",
    placeholder: "E.g. Readings: Myths, Legends etc.",
    noDataComp: () => <EmptyLE />,
  },
  SECOND_STAGE_ASSESSMENT: {
    label: "common:stage_2_assessment_plural",
    placeholder: "E.g. Sorting and classfiying etc.",
    noDataComp: () => <EmptyAssessment />,
  },
  THIRD_STAGE_ASSESSMENT: {
    label: "common:stage_3_assessment_plural",
    placeholder: "E.g. Sorting and classfiying etc.",
    noDataComp: () => <EmptyAssessment />,
  },
};

const getAssignmentModalData = ({ t }) => {
  return {
    mode: "create",
    creationMode: "ASSIGN_TO_CLASS",
    steps: [
      {
        label: t("common:edit_with_label", {
          label: t("common:post"),
        }),
        value: "ASSESSMENT_DETAILS",
      },
      {
        label: t("classRoom:post_settings"),
        value: "SETTINGS",
      },
    ],
    contentType: "ASSESSMENT",
    createHeader: t("common:assign_to_students"),
    updateUnitPlanDataInRedux: false,
  };
};

const searchInputStyle = {
  customStyle: {
    marginTop: 8,
    borderColor: colors.strokeOne,
    marginBottom: 40,
  },
  activeCustomStyle: {
    borderWidth: 1,
    paddingBottom: 12,
    borderColor: colors.blue29,
  },
};

const DIALOGS_INFO = {
  DELETE: {
    title: `common:delete_with_label`,
    message: `common:delete_msg_with_label`,
    confirmButtonText: "common:delete_agree",
    cancelButtonText: "common:agree_cancel",
    promptDetail: (t, label) => {},
  },
  DUPLICATE: {
    title: `common:duplicate_with_label`,
    message: "",
    confirmButtonText: "common:duplicate_agree",
    cancelButtonText: "common:cancel",
    isPromptBox: true,
    promptDetail: (t, label) => {
      return {
        placeholder: t("common:assessment_input_emptyText"),
        label: t("common:name_of_the_with_label", { label }),
        checkValidation: true,
      };
    },
  },
};

class LibraryBody extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentDialogAction: "",
      showAdd: false,
      isLoading: false,
      fullScreenLoading: false,
      showAssignToStudentsModal: false,
      clonedAssessmentId: null,
      item: {},
    };
    this.currentDialogItem = {};
    this.searchBar = null;
  }

  componentDidMount = () => {
    // this.resetSearchBar();
  };

  // resetSearchBar = () => {
  //   if (this.searchBar) {
  //     this.searchBar.cancelText();
  //   }
  // };

  OnToggleDialogueClick = () => {
    this.setState({
      currentDialogAction: "",
    });
    this.currentDialogItem = {};
  };

  onDialogConfirmClick = promptText => {
    const { currentDialogAction } = this.state;
    switch (currentDialogAction) {
      case "DELETE":
        this.props.onDeleteClick(this.currentDialogItem.id);
        break;
      case "DUPLICATE":
        this.props.onDuplicateClick(this.currentDialogItem.id, promptText);
    }
  };

  onPrintClick = item => {
    this.props.onPrintClick(item.id);
  };

  onCardClicked = ({ id, mode = "view" }) => {
    this.setState({ showAdd: false });

    this.props.onCardClicked({ id: id, mode });
  };

  onDeleteClick = item => {
    this.setState({
      currentDialogAction: "DELETE",
    });
    this.currentDialogItem = item;
  };

  onDuplicateClick = item => {
    this.setState({
      currentDialogAction: "DUPLICATE",
    });
    this.currentDialogItem = item;
  };

  onAddToCommunityClick = item => {
    this.currentDialogItem = item;
    this.setState({
      currentDialogAction: "ADD_TO_COMMUNITY",
    });
    const { id } = item || {};
    if (this.addToCommunityButtonRef) {
      this.addToCommunityButtonRef.showModal({
        eventTarget: "unit learning experience library",
        entity_id: id,
        entity_type: "ASSESSMENT",
      });
    }
  };

  onCloseAddToStudentModal = () => {
    this.setState({ showAssignToStudentsModal: false });
  };

  onAssignToStudentClick = async item => {
    const { cloneAssessment, courseAcademicYearsIds } = this.props;

    this.setState({ fullScreenLoading: true, item });
    const clonedAssessmentId = await cloneAssessment({
      sourceAssessmentId: _.get(item, "id"),
      portalType: "PLANNER",
      academicYears: courseAcademicYearsIds,
    });
    this.setState({ clonedAssessmentId });
    this.setState({ fullScreenLoading: false });
    if (clonedAssessmentId) {
      this.setState({ showAssignToStudentsModal: true });
    }
  };

  updatAddToCommunityButtonRef = ref => {
    this.addToCommunityButtonRef = _.get(ref, "refs.wrappedInstance", {});
  };

  setLoadingState = (value, key) => {
    if (key === "LOADING") this.setState({ isLoading: value });
    else if (key === "FULLSCREEN_LOADING")
      this.setState({ fullScreenLoading: value });
  };

  onLeSearchImportSuccess = () => {
    const { updateCoachMarkStatus } = this.props;
    updateCoachMarkStatus({
      type: "IMPORT_LE_FROM_COMMUNITY_UNIT_PLAN",
      portalType: "PLANNER",
    });
  };

  onCreateNewLESuccess = ({ id }) => {
    const { gotoAssessmentDetails } = this.props;
    gotoAssessmentDetails({
      id,
      mode: "create",
      isOpenInNewTab: false,
    });
  };

  onSearchChange = value => {
    const { updateFilters } = this.props;
    updateFilters({ searchText: value });
  };

  render() {
    const {
      props: {
        label,
        readOnly,
        list,
        t,
        unitPlanId,
        size,
        groupType,
        curriculumType,
        assessmentTypeGroup,
        assessmentTypes,
        assessmentTypeDetail,
        filters: { searchText = "" },
        isFilteredData,
        onPrintClick,
        courseId,
        course,
        i18n,
      },
    } = this;

    const {
      currentDialogAction,
      fullScreenLoading,
      isLoading,
      showAssignToStudentsModal,
      clonedAssessmentId,
      item,
    } = this.state;
    const { id, title, image } = item;
    const assignmentModalData = getAssignmentModalData({
      t,
    });
    const listLength = _.get(list, "length", 0);

    const assessmentType = assessmentTypeGroup
      ? assessmentTypeGroup
      : groupType;

    return (
      <React.Fragment>
        <div className={classes.mainContainer}>
          <AddLE
            assessmentTypes={assessmentTypes}
            curriculumType={curriculumType}
            portalType="COMMUNITY"
            list={list}
            label={label}
            readOnly={readOnly}
            unitPlanId={unitPlanId}
            eventTarget={"unit - learning experiences library"}
            setLoadingState={this.setLoadingState}
            isLoading={isLoading}
            onSearchOnCommunitySuccess={this.onLeSearchImportSuccess}
            showCoachmark={true}
            size={size}
            groupType={groupType}
            assessmentTypeGroup={assessmentTypeGroup}
            onCreateNewLESuccess={this.onCreateNewLESuccess}
          />

          {/* <SearchBar
            placeholder={t("common:search_for_with_label", {
              label: t(assessmentTypeDetail.label),
            })}
            ref={ref => (this.searchBar = ref)}
            searchTerm={searchText}
            changeSearchTerm={this.onSearchChange}
            shouldAnimate={true}
            wrapperType={"box"}
            customStyle={searchInputStyle.customStyle}
            activeCustomStyle={searchInputStyle.activeCustomStyle}
          /> */}
          <div className="mb-10">
            <SearchInput
              placeholder={getLELibraryLabelsMemoize({
                assessmentType,
                assessmentTypeDetail,
                labelType: "searchText",
                t,
                lng: _.get(i18n, "language", "en"),
              })}
              onChange={this.onSearchChange}
              // ref={ref => (this.searchBar = ref)}
            />
          </div>
          {readOnly && listLength == 0 && (
            <div className={classes.emptyText}>{`No ${label}`}</div>
          )}

          <FeedsContainer
            list={list}
            assessmentTypeDetail={assessmentTypeDetail}
            readOnly={readOnly}
            size={size}
            curriculumType={curriculumType}
            isLoading={this.props.isLoading}
            isData={isFilteredData}
            onDeleteClick={this.onDeleteClick}
            onDuplicateClick={this.onDuplicateClick}
            onPrintClick={onPrintClick}
            onAddToCommunityClick={this.onAddToCommunityClick}
            onAssignToStudentClick={this.onAssignToStudentClick}
            onCardClicked={this.onCardClicked}
            assessmentType={assessmentType}
          />

          {this.state.isLoading ? (
            <div className={classes.modalContent}>
              <FullScreenLoader />
            </div>
          ) : null}
          {!!currentDialogAction && currentDialogAction != "ADD_TO_COMMUNITY" && (
            <DialogueBox
              modalTitle={t(DIALOGS_INFO[currentDialogAction].title, { label })}
              showModal={true}
              onClickButton2={this.onDialogConfirmClick}
              modalBody={t(DIALOGS_INFO[currentDialogAction].message, {
                label: `${label}`,
              })}
              toggleDialogueBoxDisplay={this.OnToggleDialogueClick}
              button1={
                t(DIALOGS_INFO[currentDialogAction].cancelButtonText) ||
                t("common:cancel")
              }
              button2={t(DIALOGS_INFO[currentDialogAction].confirmButtonText)}
              isPromptBox={DIALOGS_INFO[currentDialogAction].isPromptBox}
              promptDetail={DIALOGS_INFO[currentDialogAction].promptDetail(
                t,
                label
              )}
            />
          )}
        </div>
        {fullScreenLoading && <FullScreenLoader />}
        {showAssignToStudentsModal && (
          <UIModal isOpen={true} isFullScreen={true}>
            <AddAssignment
              sourceAssessmentId={id}
              mode={assignmentModalData["mode"]}
              creationMode={assignmentModalData["creationMode"]}
              steps={assignmentModalData["steps"]}
              contentType={assignmentModalData["contentType"]}
              createHeader={assignmentModalData["createHeader"]}
              onClose={this.onCloseAddToStudentModal}
              portalType={"PLANNER"}
              assignmentDetails={{
                assignmentImage:
                  image.value ||
                  PLACEHOLDER_RECT_IMAGE[id % PLACEHOLDER_RECT_IMAGE_COUNT],
                assignmentTitle: title.value ?? t("common:untitled"),
              }}
              showImageTitleInHeader={true}
              clonedAssessmentId={clonedAssessmentId}
              courseId={courseId}
              course={course}
              updateUnitPlanDataInRedux={
                assignmentModalData["updateUnitPlanDataInRedux"]
              }
            />
          </UIModal>
        )}

        <AddToCommunityButton
          entityId={_.get(this.currentDialogItem, "id", "")}
          entityType="ASSESSMENT"
          entityName={_.get(this.currentDialogItem, "title.value", "")}
          ref={this.updatAddToCommunityButtonRef}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const filters = _.get(state, "assessment.filters", {});
  const { assessmentTypeGroup, groupType } = ownProps;

  const assessmentTypeDetail =
    TYPE_DETAILS[assessmentTypeGroup ? assessmentTypeGroup : groupType];
  const courseId = state.teacher.selected_class.selected_course;
  const courseDetails = getCourseDetailsFromCache({
    id: courseId,
  });
  const courseAcademicYearsIds = _.map(
    _.get(courseDetails, "academicYears", []),
    item => item.id
  );

  return {
    filters,
    assessmentTypeDetail,
    courseId,
    courseAcademicYearsIds,
    course: courseDetails,
  };
};

const mapActionCreators = {
  importAssessment,
  checkCommunityActiveStatusAndGoToOnboarding,
  updateCoachMarkStatus,
  gotoAssessmentDetails,
  updateFilters,
  cloneAssessment,
};

LibraryBody.propTypes = {
  list: PropTypes.array,
  onCardClicked: PropTypes.func,
  createText: PropTypes.string,
};

LibraryBody.defaultProps = {
  list: [],
  createText: "",
  size: "small",
};

export default compose(
  I18nHOC({ resource: ["common", "toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators)
)(LibraryBody);
