import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import SubMenuCurriculumWrapper from "Administrator/commonComponents/SubMenuCurriculumWrapper";

export default store => ({
  path: "students",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const module2 = require("./modules/StudentsModule");
            const reducer = module2.default;
            const key = module2.NAME;
            injectReducer(store, { key, reducer });

            const createStudentModule = require("./components/CreateStudent/containers/CreateStudentModule");
            injectReducer(store, {
              key: createStudentModule.NAME,
              reducer: createStudentModule.default,
            });
            const studentDetailsModule = require("./components/EditStudent/containers/StudentDetailsModule");
            injectReducer(store, {
              key: studentDetailsModule.NAME,
              reducer: studentDetailsModule.default,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
  childRoutes: [SubMenuCurriculumWrapper(store)],
});
