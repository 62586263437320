import client from "apolloClient";
import {
  getProjectGroupDeadlinesQuery,
  getProjectGroupGuidanceStatusQuery,
  getProjectGroupSelectedReportTemplateQuery,
  getProjectGroupProjectCountQuery,
  getProjectGroupProjectsQuery,
  getProjectDetailsQuery,
  getProjectConversationDetailsQuery,
  getProjectReportsQuery,
  getProjectFinalReportQuery,
  getProjectInfoQuery,
  getProjectsCountQuery,
  getMultiNodeProjectCountsQuery,
  getGuidingQuestionsQuery,
  getGradeWiseProjectCountQuery,
  getPlannerElementInsightSpecificsQuery,
  getProjectsWithEvidenceInsightsQuery,
  getPostsQuery,
  getProjectGroupStudentsQuery,
  getProjectGroupSupervisorsQuery,
  getProjectGroupStudentProjectPortfoliosCountQuery,
  getStudentProjectPortfolioDetailsQuery,
  getStudentProjectPortfolioInterviewsQuery,
  getStudentPortfolioProjectsQuery,
  getLearningOutcomesWithEvidencesQuery,
  getStudentPortfolioDetailsQuery,
  getProjectGroupProjectsWithFieldsQuery,
  getProjectGroupPortfolioWithStudentQuery,
  getProjectGroupStudentPortfolioIdQuery,
  getUserProjectGroupsQuery,
  getProjectGroupYearGroupQuery,
  getStudentProjectPortfolioQuery,
  getUserProjectGroupYearGroupsQuery,
  getUserProjectGroupsTotalCountQuery,
  getProjectGroupAssessmentToolDetailsQuery,
  getProjectGroupDetailsQuery,
  getProjectCriteriaDetailsQuery,
  getStudentsBulkDownloadReportsQuery,
  getProjectProjectReportsUnreadCountQuery,
  getProjectGroupSetupGuidanceDetailsQuery,
  getStudentTagDetailsQuery,
} from "./ProjectQueries";
import {
  deadlineFragments,
  projectFragments,
  guidingQuestionsFragment,
  projectAcademicHonestyFragments,
  studentPortfolioProgressFragment,
} from "./ProjectFragments";

export const getProjectGroupDeadlinesFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupDeadlinesQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getProjectReportsFromCache = ({ projectId }) => {
  try {
    const queryData = client.readQuery({
      query: getProjectReportsQuery,
      variables: {
        id: projectId,
      },
    });
    return queryData ? queryData : {};
  } catch (e) {
    console.warn("ERROR getProjectReportsFromCache=", e);
    return {};
  }
};
export const writeProjectReportsInCache = ({ projectId, data }) => {
  try {
    client.writeQuery({
      query: getProjectReportsQuery,
      variables: {
        id: projectId,
      },
      data,
    });
  } catch (e) {
    console.warn("could not write", e);
    return {};
  }
};

export const getProjectFinalReportFromCache = ({ projectId }) => {
  try {
    const queryData = client.readQuery({
      query: getProjectFinalReportQuery,
      variables: {
        id: projectId,
      },
    });
    return queryData ? queryData : {};
  } catch (e) {
    console.warn(e);
    return {};
  }
};

export const getProjectGroupSelectedReportTemplateFromCache = ({
  projectGroupId,
}) => {
  try {
    const queryData = client.readQuery({
      query: getProjectGroupSelectedReportTemplateQuery,
      variables: {
        projectGroupId,
      },
    });
    return queryData ? queryData : {};
  } catch (e) {
    console.warn("ERROR getProjectGroupSelectedReportTemplateFromCache", e);
    return {};
  }
};

export const writeProjectGroupDeadlinesInCache = ({
  projectGroupId,
  data,
  fetchStatusWiseProjects,
}) => {
  let queryData = {};
  try {
    queryData = client.writeQuery({
      query: getProjectGroupDeadlinesQuery,
      variables: {
        projectGroupId,
        fetchStatusWiseProjects,
      },
      data,
    });
  } catch (e) {
    console.warn("could not write", e);
    return {};
  }
  return queryData ? queryData : {};
};

export const writeProjectGroupDeadlineInCache = ({
  projectDeadlineId,
  data,
}) => {
  try {
    client.writeFragment({
      id: `ProjectDeadline:${projectDeadlineId}`,
      fragment: deadlineFragments.deadlineFragmentWithStatusCount,
      fragmentName: "projectDeadline",
      data,
    });
  } catch (e) {
    return {};
  }
};

export const getProjectGroupGuidanceStatusFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupGuidanceStatusQuery,
      variables: {
        id: id,
      },
    });
  } catch (e) {
    console.warn(e);
    return [];
  }
  if (queryData != null) {
    return queryData;
  } else {
    return [];
  }
};

export const getProjectConversationDetailsFromCache = ({ projectId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectConversationDetailsQuery,
      variables: {
        id: projectId,
      },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  if (queryData) {
    return queryData;
  }
  return {};
};

export const writeProjectGuidanceStatusInCache = ({
  projectGroupId,
  visibilityKey,
  isVisible,
}) => {
  let guidanceStatus = getProjectGroupGuidanceStatusFromCache({
    id: projectGroupId,
  });
  guidanceStatus = _.get(guidanceStatus, "node", {});
  client.writeQuery({
    query: getProjectGroupGuidanceStatusQuery,
    variables: {
      id: projectGroupId,
    },
    data: {
      node: { ...guidanceStatus, [visibilityKey]: isVisible },
    },
  });
};

export const getProjectGroupProjectCountFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupProjectCountQuery,
      variables: {
        id: id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getProjectGroupProjectsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupProjectsQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getStudentTagDetailsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentTagDetailsQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getProjectProjectReportsUnreadCountFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectProjectReportsUnreadCountQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const getProjectStudentInfoFragmentFromCache = ({
  projectId,
  projectGroupId,
  studentCourseFilters,
  projectGroupProjectFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Project:${projectId}`,
      fragment: projectFragments.projectStudentInfoFragment,
      fragmentName: "projectStudentInfo",
      variables: {
        id: projectGroupId,
        studentCourseFilters,
        filters: projectGroupProjectFilters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getStudentsBulkDownloadReportsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentsBulkDownloadReportsQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeProjectGroupProjectCountInCache = ({ id, filters, data }) => {
  try {
    client.writeQuery({
      query: getProjectGroupProjectCountQuery,
      variables: {
        id: id,
        filters,
      },
      data,
    });
  } catch (e) {
    console.warn("Unable to write ", e);
  }
};

export const getProjectDetailsFromCache = ({ projectId }) => {
  try {
    const queryData = client.readQuery({
      query: getProjectDetailsQuery,
      variables: {
        id: projectId,
      },
    });
    return queryData ? queryData : {};
  } catch (e) {
    console.warn(e);
    return {};
  }
};

export const writeProjectDetailsInCache = ({ projectId, data }) => {
  try {
    client.writeQuery({
      query: getProjectDetailsQuery,
      variables: {
        id: projectId,
      },
      data,
    });
  } catch (e) {
    console.warn(e);
  }
};

export const getProjectInfoFromCache = ({ projectId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectInfoQuery,
      variables: {
        projectId,
      },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData != null ? queryData : {};
};

export const writeProjectInfoInCache = ({ projectId, data }) => {
  try {
    client.writeFragment({
      id: `Project:${projectId}`,
      fragment: projectFragments.projectInfoFragment,
      fragmentName: "projectInfoItem",
      data: data,
    });
  } catch (e) {
    console.warn("could not write", e);
    return {};
  }
};

export const getProjectsCountFromCache = ({ projectGroupIds, id, type }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectsCountQuery,
      variables: {
        projectGroupIds,
        id,
        type,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getMultiNodeProjectCountsFromCache = ({ ids }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getMultiNodeProjectCountsQuery,
      variables: {
        ids,
      },
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getGuidingQuestionsFromCache = ({ projectGroupId, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getGuidingQuestionsQuery,
      variables: { projectGroupId, filters },
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};
export const writeGuidingQuestionsInCache = ({ data, variables }) => {
  try {
    client.writeQuery({
      query: getGuidingQuestionsQuery,
      variables,
      data,
    });
  } catch (e) {
    return {};
  }
};

export const writeGuidingQuestionFragmentInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `ProjectGroupGuidingQuestion:${id}`,
      fragment: guidingQuestionsFragment.guidingQuestions,
      fragmentName: "guidingQuestions",
      data,
    });
  } catch (e) {
    return {};
  }
};

export const getGuidingQuestionFragmentFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `ProjectGroupGuidingQuestion:${id}`,
      fragment: guidingQuestionsFragment.guidingQuestions,
      fragmentName: "guidingQuestions",
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getGradeWiseProjectCountFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getGradeWiseProjectCountQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getPlannerElementInsightSpecificsFromCache = ({
  projectGroupId,
  gradeFilters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getPlannerElementInsightSpecificsQuery,
      variables: { projectGroupId, gradeFilters },
    });
  } catch (e) {
    return {};
  }

  return queryData != null ? queryData : {};
};

export const getProjectsFromCache = ({ ids, filters, evidenceFilters }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getProjectsWithEvidenceInsightsQuery,
      variables: { ids, filters, evidenceFilters },
    });
  } catch (e) {
    return {};
  }

  return queryData != null ? queryData : {};
};

export const getPostsFromCache = ({ ids }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getPostsQuery,
      variables: { ids },
    });
  } catch (e) {
    return {};
  }

  return queryData != null ? queryData : {};
};

export const getProjectGroupSupervisorsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupSupervisorsQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getProjectGroupStudentsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupStudentsQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getProjectGroupStudentProjectPortfoliosCountFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupStudentProjectPortfoliosCountQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getStudentProjectPortfolioDetailsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProjectPortfolioDetailsQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getStudentProjectPortfolioInterviewsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProjectPortfolioInterviewsQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getStudentPortfolioProjectsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentPortfolioProjectsQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getProjectGroupPortfolioWithStudentFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupPortfolioWithStudentQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getStudentProjectPortfolioFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentProjectPortfolioQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getStudentProjectPortfolioInterviewFragmentFromCache = ({
  id,
}) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `ProjectAcademicHonesty:${id}`,
      fragment: projectAcademicHonestyFragments.interviewInfoFragment,
      fragmentName: "interviewInfoItem",
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getStudentPortfolioDetailsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentPortfolioDetailsQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getLearningOutcomesWithEvidencesFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getLearningOutcomesWithEvidencesQuery,
      variables: params,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const writeLearningOutcomesWithEvidencesInCache = ({ id, data }) => {
  let queryData = {};
  try {
    queryData = client.writeQuery({
      query: getLearningOutcomesWithEvidencesQuery,
      variables: {
        id,
      },
      data,
    });
  } catch (e) {
    return {};
  }
  return queryData ? queryData : {};
};

export const writeStudentProjectPortfolioProgressFragmentInCache = ({
  id,
  data,
}) => {
  try {
    client.writeFragment({
      id: `StudentProjectPortfolio:${id}`,
      fragment: studentPortfolioProgressFragment.studentPortfolioProgress,
      fragmentName: "portfolioProgress",
      data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const getStudentProjectPortfolioProgressFragmentInCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `StudentProjectPortfolio:${id}`,
      fragment: studentPortfolioProgressFragment.studentPortfolioProgress,
      fragmentName: "portfolioProgress",
    });
  } catch (e) {
    return {};
  }
  return queryData != null ? queryData : {};
};

export const getProjectGroupProjectsWithFieldsFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupProjectsWithFieldsQuery,
      variables,
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getProjectGroupStudentPortfolioIdFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupStudentPortfolioIdQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  return queryData ? queryData : {};
};

export const getUserProjectGroupsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUserProjectGroupsQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getProjectGroupYearGroupFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupYearGroupQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getProjectGroupPortfolioStudentsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupStudentsQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getUserProjectGroupYearGroupsFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUserProjectGroupYearGroupsQuery,
      variables,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getUserProjectGroupsTotalCountFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUserProjectGroupsTotalCountQuery,
      variables,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getProjectGroupAssessmentToolDetailsFromCache = ({
  id,
  assessmentToolFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupAssessmentToolDetailsQuery,
      variables: { id, assessmentToolFilters },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeProjectRatedCriteriaInCache = ({ id, data }) => {
  try {
    client.writeQuery({
      query: getProjectCriteriaDetailsQuery,
      variables: {
        id,
      },
      data: { node: data },
    });
  } catch (e) {
    console.warn("Unable to write : ", e);
  }
};

export const getProjectGroupDetailsFromCache = ({
  id,
  assessmentToolFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupDetailsQuery,
      variables: {
        id,
        assessmentToolFilters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getProjectGroupSetupGuidanceDetailsFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectGroupSetupGuidanceDetailsQuery,
      variables,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeProjectGroupSetupGuidanceDetailsInCache = ({
  variables,
  data,
}) => {
  try {
    client.writeQuery({
      query: getProjectGroupSetupGuidanceDetailsQuery,
      variables,
      data,
    });
  } catch (e) {
    console.warn("could not write", e);
    return {};
  }
};

export const getProjectCriteriaDetailsFromCache = ({
  id,
  studentCourseFilters = {},
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProjectCriteriaDetailsQuery,
      variables: {
        id,
        studentCourseFilters,
      },
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
