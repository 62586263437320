import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./Organization.scss";
import moment from "moment";
import {
  I18nHOC,
  UIButton,
  DialogueBox,
  withLoader,
  SearchBar,
  Loading,
  BulkUploadErrorModal,
  FullScreenLoader,
  UIModal,
} from "UIComponents";
import { getOrganizationFeedQuery } from "Tooling/modules/ToolingQueries";
import { getOrganizationFeedFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
import UpsertOrganization from "./components/EditOrgInfo";
import Header from "./components/Header";
import FeedList from "AppComponents/FeedList";
import { goToBack } from "modules/NavigationModule";
import {
  createOrganization,
  updateOrganization,
  bulkUpload,
  changeOrganizationSearchString,
  getOrganizationData,
  deleteOrganization,
  updateConfigurablePermissions,
  getDefaultAcademicYears,
  updateCurriculumPrograms,
  getValidAdminsPayload,
} from "Tooling/modules/ToolingModule";
import { onSwitchToOrganization } from "Login/modules/LoginModule";
import { checkScrollAtBottom, exportData } from "Utils";
import EditOrgSetting from "./components/EditOrgSetting/EditOrgSetting";
import ManageOrgFeatures from "./components/ManageOrgFeatures";
import UploadDPRoster from "./components/UploadDPRoster";
import { withPermission } from "lib/PermissionAware";
import ACLStore from "lib/aclStore";
import { CURRICULUM_TYPE_DP } from "Constants/stringConstants";

import {
  ENABLE_OLD_MANAGE_ORGANIZATION_ROSTERING,
  ENABLE_DP_ROASTER_SHEET,
} from "Constants/permissionConstants";

import UpdateOrgAcademicYears from "./components/UpdateOrgAcademicYears";

const OrganizationList = compose(withLoader)(FeedList);
const isDPUploadRosterAllowed = ACLStore.can(ENABLE_DP_ROASTER_SHEET);
const parentStyle = {
  gridTemplateColumns: "10% 24% 5% 8% 10% 10% 9% 6%",
  gridColumnGap: "20px",
};
const columnList = () => [
  {
    label: "Org. id",
    id: "id",
    type: "TEXTCOLUMN",
    style: { justifyItems: "center", justifySelf: "center" },
  },
  {
    label: "Organization name",
    id: "name",
    type: "NAMEWITHIMAGE",
    //style: { justifySelf: "center" }
  },
  {
    label: "Plan",
    id: "plan",
    type: "TEXTCOLUMN",
  },
  {
    label: "Total classes",
    id: "totalCourseCount",
    type: "TEXTCOLUMN",
    style: { justifyItems: "center", justifySelf: "center" },
  },
  {
    label: "Total staffs",
    id: "totalStaffCount",
    type: "TEXTCOLUMN",
    style: { justifyItems: "center", justifySelf: "center" },
  },
  {
    label: "Total students",
    id: "totalStudentCount",
    type: "TEXTCOLUMN",
    style: { justifyItems: "center", justifySelf: "center" },
  },
  {
    label: "Actions",
    locale: "common:action_plural",
    id: "action",
    type: "ACTIONS",
  },
  {
    label: "",
    id: "actionButton",
    type: "CUSTOM",
    style: { justifyItems: "flex-end" },
  },
];

const DIALOGS_INFO = {
  DELETE_ORGANIZATION: {
    title: "Delete School",
    message: ({ orgDetail }) => {
      return (
        <div>
          <div style={{ marginBottom: "16px", color: "red" }}>
            {`Are you sure you want to delete the School? This action can't be undone.`}
          </div>
          <div>
            <b>School name: </b>
            {orgDetail.name}
          </div>
          <div>
            <b>School id: </b>
            {orgDetail.id}
          </div>
        </div>
      );
    },
    confirmButtonText: "Delete",
    cancelButtonText: "Cancel",
  },
};

class Organization extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: "create",
      isCreate: true,
      modalOpen: false,
      settingModalOpen: false,
      manageOrgFeatureModal: false,
      uploadDPRosterModal: false,
      academicYearModalOpen: false,
      bulkUploadErrorModal: false,
      selectedOrgId: null,
      errors: [],
      isLoading: false,
      currentDialogAction: false,
    };
  }

  openModalCreateModeal = () => {
    this.setState({ modalOpen: true, mode: "create" });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      settingModalOpen: false,
      academicYearModalOpen: false,
      manageOrgFeatureModal: false,
      uploadDPRosterModal: false,
    });
  };

  onClickEditOrgAcademicYear = id => {
    this.setState({
      academicYearModalOpen: true,
      mode: "editAcademicYear",
      selectedOrgId: id,
    });
  };

  toggleDialogueBoxDisplay = () => {
    this.setState({
      currentDialogAction: false,
    });
  };

  onClickEditOrg = id => {
    this.setState({
      modalOpen: true,
      mode: "edit",
      selectedOrgId: id,
    });
  };

  onClickEditOrgSetting = id => {
    this.setState({
      settingModalOpen: true,
      mode: "editSetting",
      selectedOrgId: id,
    });
  };

  onClickDownloadData = async (name, presorg_id) => {
    try {
      const data = await this.props.getOrganizationData(presorg_id);
      exportData({ data, outputFormat: "ZIP", fileName: name });
    } catch (error) {
      console.error(error);
    }
  };

  changeSearchTerm = value => {
    this.props.changeOrganizationSearchString({ organizations: value });
  };

  toggleBulkUploadErrorModal = () => {
    this.setState({
      bulkUploadErrorModal: !this.state.bulkUploadErrorModal,
    });
  };

  onContentUploaded = async ({ data, type, organizationId }) => {
    const { getOrganizationFeed, bulkUpload } = this.props;
    this.setState({
      isLoading: true,
    });
    const { successful, errors } = await bulkUpload({
      url: data.url,
      type,
      organizationId,
    });
    if (successful) {
      await getOrganizationFeed.refetch();
      this.setState({
        isLoading: false,
      });
      // await refetch here;
    } else {
      this.setState({
        errors: errors,
        isLoading: false,
      });
      this.toggleBulkUploadErrorModal();
    }
  };

  onClickDeleteOrg = id => {
    this.setState({
      currentDialogAction: "DELETE_ORGANIZATION",
      selectedOrgId: id,
    });
  };

  onClickManageOrgFeatures = id => {
    this.setState({
      manageOrgFeatureModal: true,
      mode: "manageFeatures",
      selectedOrgId: id,
    });
  };

  onClickUploadDPRosterSheet = id => {
    this.setState({
      uploadDPRosterModal: true,
      mode: "uploadDPRoster",
      selectedOrgId: id,
    });
  };

  getActionMenu = item => {
    return [
      {
        label: "Manage curriculum programs",
        clickAction: () => this.onClickEditOrg(item.id),
        svg: null,
        isDisable: false,
      },
      {
        label: "Edit Setting",
        clickAction: () => this.onClickEditOrgSetting(item.id),
        svg: null,
        isDisable: false,
      },
      {
        label: "Manage academic years",
        clickAction: () => this.onClickEditOrgAcademicYear(item.id),
        svg: null,
        isDisable: false,
      },
      {
        label: "Manage Features",
        clickAction: () => this.onClickManageOrgFeatures(item.id),
        svg: null,
        isDisable: false,
      },
      {
        label: "Download school data",
        clickAction: () => this.onClickDownloadData(item.name, item.id),
        svg: null,
        isDisable: false,
      },
      ...(_.some(item.curriculumPrograms, ["type", "IB_DP"]) &&
      isDPUploadRosterAllowed
        ? [
            {
              label: "Upload DP Roster Sheet",
              clickAction: () => this.onClickUploadDPRosterSheet(item.id),
              svg: null,
              isDisable: false,
            },
          ]
        : []),
      {
        label: "Delete school",
        clickAction: () => this.onClickDeleteOrg(item.id),
        svg: null,
        isDisable: false,
      },

      // {
      //   renderComponent: (
      //     <DropzoneComponent
      //       multiple={false}
      //       renderComponent={
      //         <div className={classes.option}>{`Add Classes`}</div>
      //       }
      //       accept={[".csv", "text/csv"]}
      //       onSingleContentUploaded={data =>
      //         this.onContentUploaded({
      //           data,
      //           type: "COURSE",
      //           organizationId: item.id
      //         })
      //       }
      //     />
      //   )
      // },
      // {
      //   renderComponent: (
      //     <DropzoneComponent
      //       multiple={false}
      //       renderComponent={
      //         <div className={classes.option}>{`Add Staffs`}</div>
      //       }
      //       accept={[".csv", "text/csv"]}
      //       onSingleContentUploaded={data =>
      //         this.onContentUploaded({
      //           data,
      //           type: "STAFF",
      //           organizationId: item.id
      //         })
      //       }
      //     />
      //   )
      // },
      // {
      //   renderComponent: (
      //     <DropzoneComponent
      //       multiple={false}
      //       renderComponent={
      //         <div className={classes.option}>{`Add Students`}</div>
      //       }
      //       accept={[".csv", "text/csv"]}
      //       onSingleContentUploaded={data =>
      //         this.onContentUploaded({
      //           data,
      //           type: "STUDENT",
      //           organizationId: item.id
      //         })
      //       }
      //     />
      //   )
      // },
      // {
      //   renderComponent: (
      //     <DropzoneComponent
      //       multiple={false}
      //       renderComponent={
      //         <div className={classes.option}>{`Add Family Members`}</div>
      //       }
      //       accept={[".csv", "text/csv"]}
      //       onSingleContentUploaded={data =>
      //         this.onContentUploaded({ data, type: "PARENT" })
      //       }
      //     />
      //   )
      // }
    ];
  };

  getFeed = () => {
    const { organizations } = this.props;
    return _.map(organizations, organization => {
      const {
        id,
        name,
        logo,
        tier: { plan },
        courses: { totalCount: totalCourseCount },
        students: { totalCount: totalStudentCount },
        staff: { totalCount: totalStaffCount },
        additionalData,
      } = _.get(organization, "node", {}) || {};
      return {
        id: id,
        name: {
          name: name,
          profileImage: logo,
        },
        plan,
        totalCourseCount,
        totalStudentCount,
        totalStaffCount,
        additionalData: additionalData,
        action: this.getActionMenu(organization.node),
      };
    });
  };

  getCurriculumOptionsPayload = ({
    curriculumPrograms,
    shouldIncludeId = false,
  }) => {
    return _.map(curriculumPrograms, curriculumProgram => {
      const {
        type,
        acronym,
        rosteringMethod,
        toddleBuddy,
        plan,
        grades,
        name,
        id,
      } = curriculumProgram;

      return {
        ...(shouldIncludeId ? { id } : {}),
        curriculumType: type,
        rosteringMethod,
        toddleBuddyId: toddleBuddy,
        planId: plan,
        gradesToBeIncluded: grades,
        label: name,
        acronym,
      };
    });
  };

  createOrganization = async orgDetails => {
    const { getOrganizationFeed } = this.props;

    const academicYears = _.map(orgDetails["academicYears"], academicYear => {
      return {
        startDate: moment(academicYear?.dateDurationValue?.startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(academicYear?.dateDurationValue?.endDate).format(
          "YYYY-MM-DD"
        ),
      };
    });

    const admins = _.get(orgDetails, "admins", []);

    const validAdmins = getValidAdminsPayload({ admins });

    const curriculumPrograms = _.get(orgDetails, "curriculumPrograms", []);

    const dpCurriculumPrograms = _.find(
      curriculumPrograms,
      ({ type }) => type === CURRICULUM_TYPE_DP
    );

    const examSessionMonth = _.get(
      dpCurriculumPrograms,
      "examSessionMonth",
      null
    );

    const attachment = _.get(orgDetails, "logoAttachment");

    const { timezone } = orgDetails;

    const timezoneConstant = timezone
      ? [
          {
            name: "TIMEZONE",
            value: JSON.stringify(timezone),
          },
        ]
      : [];

    let updatedOrgDetails = {
      ...orgDetails,
      academicYears,
      admins: validAdmins,
      attachments: attachment ? [attachment] : [],
      examSessionMonth,
      curriculumOptions: this.getCurriculumOptionsPayload({
        curriculumPrograms,
      }),
      schedulerStartTime: moment(orgDetails["schedulerStartTime"]).format(
        "HH:mm"
      ),
      schedulerEndTime: moment(orgDetails["schedulerEndTime"]).format("HH:mm"),
      schedulerWeekLength: parseInt(orgDetails["schedulerWeekLength"]),
      organizationConstants: [
        {
          name: "OPERATIONAL_DAYS",
          value: JSON.stringify(orgDetails["operationalDays"]),
        },
        ...timezoneConstant,
      ],
    };

    updatedOrgDetails = _.omit(updatedOrgDetails, [
      "operationalDays",
      "logoAttachment",
      "curriculumPrograms",
      "toddleBuddy",
    ]);

    this.setState({ isLoading: true });
    try {
      await this.props.createOrganization(updatedOrgDetails);
      await getOrganizationFeed.refetch();
      this.closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getDpExamSessionMonth = ({ curriculumPrograms }) => {
    const dpCurriculumPrograms = _.find(
      curriculumPrograms,
      ({ type }) => type === CURRICULUM_TYPE_DP
    );

    return _.get(dpCurriculumPrograms, "examSessionMonth", null);
  };

  updateCurriculumPrograms = async orgDetails => {
    const { getOrganizationFeed, updateCurriculumPrograms } = this.props;

    this.setState({ isLoading: true });

    const { id, curriculumPrograms } = orgDetails;

    const examSessionMonth = this.getDpExamSessionMonth({ curriculumPrograms });

    const curriculumOptions = this.getCurriculumOptionsPayload({
      curriculumPrograms,
      shouldIncludeId: true,
    });

    this.setState({ isLoading: true });

    try {
      await updateCurriculumPrograms({
        id,
        curriculumOptions,
        examSessionMonth,
      });
      await getOrganizationFeed.refetch();
      this.closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onClickOperation = async orgDetails => {
    const { mode } = this.state;

    if (mode == "create") {
      this.createOrganization(orgDetails);
    } else {
      this.updateCurriculumPrograms(orgDetails);
    }
  };

  isSchoolAlreadyHaveMypUbd = id => {
    const { organizations } = this.props;
    const orgDetails = _.get(
      _.find(organizations, ({ node }) => {
        return node.id === id;
      }),
      "node",
      {}
    );
    const curriculumTypes = ["IB_MYP", "UBD"];
    const curriculumPrograms = _.get(orgDetails, "curriculumPrograms", []);

    const filterCurriculumPrograms = _.filter(
      curriculumPrograms,
      curriculumProgram =>
        _.some(
          curriculumTypes,
          curriculumType => curriculumProgram.type === curriculumType
        )
    );
    return _.size(filterCurriculumPrograms) === 0 ? false : true;
  };

  getOrgDetail = () => {
    const { organizations } = this.props;
    const { selectedOrgId } = this.state;
    const orgDetails = _.get(
      _.find(organizations, ({ node }) => {
        return node.id === selectedOrgId;
      }),
      "node",
      {}
    );
    const curriculumPrograms = _.get(orgDetails, "curriculumPrograms", []);
    const curriculumOptions = _.map(
      curriculumPrograms,
      ({
        id,
        buddy,
        rosteringMethod,
        subscriptionPlan,
        type,
        label,
        acronym,
      }) => ({
        id,
        curriculumType: type,
        planId: _.get(subscriptionPlan, "id", ""),
        toddleBuddyId: _.get(buddy, "id", ""),
        gradesToBeIncluded: [],
        rosteringMethod,
        label,
        acronym,
      })
    );

    const rosteringMethod = _.get(
      _.head(curriculumPrograms),
      "rosteringMethod",
      ""
    );

    return {
      ..._.pick(orgDetails, ["id", "name", "logo", "code", "toddleBuddy"]),
      planId: _.get(orgDetails, "tier.id"),
      additionalData: _.get(orgDetails, "constants.additionalData.note"),
      academicYears: _.get(orgDetails, "academicYears", []),
      schedulerStartTime: moment(
        _.get(orgDetails, "constants.scheduler.start_time_for_slot"),
        "HH:mm"
      ),
      schedulerEndTime: moment(
        _.get(orgDetails, "constants.scheduler.end_time_for_slot"),
        "HH:mm"
      ),
      schedulerStartDay: _.get(
        orgDetails,
        "constants.scheduler.week_start_day"
      ),
      locale: _.get(
        _.find(_.get(orgDetails, "settings", []), { name: "locale" }),
        "value",
        ""
      ),
      schedulerWeekLength: _.get(orgDetails, "constants.scheduler.week_length"),
      curriculumOptions,
      rosteringMethod,
      examSessionMonth: _.get(orgDetails, "constants.examSessionMonth", null),
    };
  };

  onScroll = e => {
    const { hasNextPage, networkStatus, loadMoreResult } = this.props;
    const containerDiv = e.target;

    if (
      containerDiv &&
      hasNextPage &&
      networkStatus != 3 &&
      checkScrollAtBottom(containerDiv)
    ) {
      loadMoreResult();
    }
  };

  onItemClick = id => {
    const { onSwitchToOrganization } = this.props;
    onSwitchToOrganization({ organizationId: id });
  };

  getFeedCustomComponent = ({ column, columnData, id }) => {
    switch (column.id) {
      case "actionButton":
        return (
          <UIButton
            size="sm"
            color={"blue"}
            onClick={() => this.onItemClick(id)}
          >{`View`}</UIButton>
        );
    }

    return null;
  };

  getToddleBuddies = () => {
    const { getOrganizationFeed } = this.props;
    return _.get(getOrganizationFeed, "platform.buddies", []);
  };

  getCreateOrgDetails = () => {
    const orgDetails = {
      academicYears: getDefaultAcademicYears(),
      schedulerStartTime: moment("08:00", "HH:mm"),
      schedulerEndTime: moment("15:00", "H:mm"),
      schedulerStartDay: 1,
      locale: "en",
      schedulerWeekLength: 5,
      rosteringMethod: "",
      examSessionMonth: null,
      operationalDays: [1, 2, 3, 4, 5],
    };

    return orgDetails;
  };

  onDialogConfirmClick = async () => {
    const { currentDialogAction } = this.state;
    this.setState({ isLoading: true });
    if (currentDialogAction === "DELETE_ORGANIZATION") {
      await this.props.deleteOrganization({ id: this.state.selectedOrgId });
    }
    this.setState({ isLoading: false });
  };

  onManageFeaturesSaveClick = async param => {
    const { getOrganizationFeed } = this.props;

    this.setState({ isLoading: true });
    await this.props.updateConfigurablePermissions({
      id: this.state.selectedOrgId,
      permissions: param,
    });
    await getOrganizationFeed.refetch();
    this.setState({ isLoading: false });
    this.closeModal();
  };

  onUploadDPRosterSaveClick = async param => {
    const curriculumProgram = _.find(param.orgDetail.curriculumOptions, {
      curriculumType: "IB_DP",
    });

    for (const sheet of _.get(param, "sheets", [])) {
      sheet.url &&
        (await this.props.bulkUpload({
          url: sheet.url,
          type: sheet.type,
          organizationId: param.orgDetail.id,
          inputs: {
            curriculumProgramId: _.get(curriculumProgram, "id", ""),
          },
        }));
    }
    this.closeModal();
  };

  render() {
    const { isData, isLoading, totalCount, networkStatus } = this.props;
    const {
      mode,
      modalOpen,
      settingModalOpen,
      academicYearModalOpen,
      currentDialogAction,
      bulkUploadErrorModal,
      manageOrgFeatureModal,
      uploadDPRosterModal,
      errors,
      isLoading: isStateLoading,
    } = this.state;
    return (
      <div className={classes.container} onScroll={this.onScroll}>
        <div className={classes.innerContainer}>
          <Header
            goToBack={this.props.goToBack}
            onClickAdd={this.openModalCreateModeal}
          ></Header>
          <div className={classes.header}>
            <div className={classes.leftHeader}>
              <SearchBar
                shouldAnimate={false}
                wrapperType={"box"}
                name="searchText"
                placeholder={`Search by organization name`}
                ref={ref => (this.searchBar = ref)}
                changeSearchTerm={this.changeSearchTerm}
                searchTerm={this.props.searchText}
              />
            </div>

            <div className={classes.rightHeader}>{`${totalCount} schools`}</div>
          </div>

          <OrganizationList
            parentStyle={parentStyle}
            columnList={columnList()}
            feedData={this.getFeed()}
            fetchMoreFeed={this.fetchMoreFeed}
            isData={isData}
            getCustomComponent={this.getFeedCustomComponent}
            // onItemClick={this.onItemClick}
            // isItemClickEnable={true}
            isLoading={isLoading}
          />
        </div>
        {modalOpen ? (
          <UIModal isOpen isFullScreen>
            <UpsertOrganization
              bulkUpload={this.props.bulkUpload}
              closeModal={this.closeModal}
              mode={mode}
              onClickOperation={this.onClickOperation}
              organizationDetails={
                mode == "create"
                  ? this.getCreateOrgDetails()
                  : this.getOrgDetail()
              }
              toddleBuddies={this.getToddleBuddies()}
            />
          </UIModal>
        ) : null}

        {settingModalOpen ? (
          <EditOrgSetting
            bulkUpload={this.props.bulkUpload}
            closeModal={this.closeModal}
            mode={mode}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {manageOrgFeatureModal ? (
          <ManageOrgFeatures
            closeModal={this.closeModal}
            mode={mode}
            onClickOperation={this.onManageFeaturesSaveClick}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {uploadDPRosterModal ? (
          <UploadDPRoster
            closeModal={this.closeModal}
            mode={mode}
            onClickOperation={this.onUploadDPRosterSaveClick}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {academicYearModalOpen ? (
          <UpdateOrgAcademicYears
            closeModal={this.closeModal}
            mode={mode}
            orgDetail={this.getOrgDetail()}
          />
        ) : null}

        {currentDialogAction ? (
          <DialogueBox
            modalTitle={DIALOGS_INFO[currentDialogAction].title}
            onClickButton2={this.onDialogConfirmClick}
            modalBody={DIALOGS_INFO[currentDialogAction].message({
              orgDetail: this.getOrgDetail(),
            })}
            toggleDialogueBoxDisplay={this.toggleDialogueBoxDisplay}
            button1={DIALOGS_INFO[currentDialogAction].cancelButtonText}
            button2={DIALOGS_INFO[currentDialogAction].confirmButtonText}
          />
        ) : null}

        {bulkUploadErrorModal ? (
          <BulkUploadErrorModal
            toggleBulkUploadErrorModal={this.toggleBulkUploadErrorModal}
            errors={errors}
          />
        ) : null}
        {networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        {isStateLoading && <FullScreenLoader></FullScreenLoader>}
      </div>
    );
  }
}

const mapActionCreators = {
  createOrganization,
  updateOrganization,
  goToBack,
  bulkUpload,
  changeOrganizationSearchString,
  onSwitchToOrganization,
  getOrganizationData,
  deleteOrganization,
  updateConfigurablePermissions,
  updateCurriculumPrograms,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    mode: ownProps.mode,
    searchText: state.tooling.searchString.organizations,
    perm: ENABLE_OLD_MANAGE_ORGANIZATION_ROSTERING,
  };
};

// export default Organization;
export default compose(
  I18nHOC({ resource: ["toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationFeedQuery, {
    name: "getOrganizationFeed",
    options: ({ searchText }) => ({
      fetchPolicy: "cache-and-network",
      variables: { filters: { searchText } },
    }),
    props({
      getOrganizationFeed,
      ownProps: { isData, isLoading, searchText },
    }) {
      const organizationDetails = getOrganizationFeedFromCache({
        filters: { searchText },
      });
      const organizations = _.get(organizationDetails, "edges", []);
      const totalCount = _.get(organizationDetails, "totalCount", 0);
      return {
        totalCount,
        organizations,
        getOrganizationFeed,
        hasNextPage: _.get(organizationDetails, "pageInfo.hasNextPage", false),
        networkStatus: getOrganizationFeed["networkStatus"],
        isData: !_.isEmpty(organizationDetails) && isData,
        isLoading:
          getOrganizationFeed["networkStatus"] == 1 ||
          getOrganizationFeed["networkStatus"] == 2 ||
          isLoading,
        loadMoreResult: () => {
          return getOrganizationFeed.fetchMore({
            query: getOrganizationFeedQuery,
            variables: {
              after: organizationDetails.pageInfo.endCursor,
              filters: { searchText },
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousFeed = previousResult.platform.organizationFeed;
              const nextFeed = fetchMoreResult.platform.organizationFeed;

              const previousEdges = previousFeed.edges;
              const nextEdges = nextFeed.edges;

              const previousPageInfo = previousFeed.pageInfo;
              const nextPageInfo = nextFeed.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;
              return {
                ...previousResult,
                platform: {
                  ...previousResult.platform,
                  organizationFeed: {
                    ...previousResult.platform.organizationFeed,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withPermission
)(Organization);
