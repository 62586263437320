import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import ProgressReportDetails from "ProgressReportDetails";
export default store => ({
  path: ":setId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "progress-report"
        );
      })
  ),
  childRoutes: [ProgressReportDetails(store)],
});
