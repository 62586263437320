import { injectReducer } from "store/reducers";

import UnitPlanDetails from "UnitPlanDetails";
import { withAsyncRouteLoading } from "UIComponents";

export default store => {
  return {
    path: "adminunitplans",
    component: withAsyncRouteLoading(
      () =>
        new Promise(cb => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("./modules/AdminUnitPlansModule").default;
              const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
                .default;
              injectReducer(store, { key: "adminUnitPlans", reducer });
              const plannerReducer = require("IBPlanner/modules/IBPlannerModule")
                .default;
              injectReducer(store, { key: "planner", reducer: plannerReducer });
              injectReducer(store, {
                key: "unitPlans",
                reducer: unitPlanModule,
              });

              cb(component);
            },
            "unitPlans"
          );
        })
    ),

    childRoutes: [UnitPlanDetails(store)],
  };
};
