import React from "react";
import { withTranslation } from "react-i18next";
import { getHOCDisplayName } from "Utils";

const I18nHOC = ({ resource }) => {
  return WrappedComponent => {
    class WithI18n extends React.PureComponent {
      render() {
        const { forwardedRef, ...rest } = this.props;
        return <WrappedComponent ref={forwardedRef} {...rest} />;
      }
    }

    WithI18n.displayName = `WithLoader(${getHOCDisplayName(WrappedComponent)})`;

    return withTranslation(resource, { withRef: true })(
      React.forwardRef(function withTranslationForwardRef(props, ref) {
        return <WithI18n {...props} forwardedRef={ref} />;
      })
    );
  };
};
export default I18nHOC;
