import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
export default store => ({
  path: "studentprofiletemplate",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/StudentProfileTemplatesModule")
              .default;
            injectReducer(store, { key: "studentProfileTemplate", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
});
