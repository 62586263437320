import React from "react";
import classes from "./TextMoreLess.scss";
import PropTypes from "prop-types";
import ReactTextMoreLess from "react-text-more-less";
import { I18nHOC } from "UIComponents";
import { getStringAutoLinks } from "Utils";

class TextMoreLess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  onClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const { collapsed } = this.state;
    const { value, minHeight, customTextStyle, moreText, t } = this.props;
    return (
      <ReactTextMoreLess
        collapsed={collapsed}
        text={
          collapsed ? (
            value
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: getStringAutoLinks(value),
              }}
            ></span>
          )
        }
        lessHeight={minHeight}
        showMoreText={moreText}
        showMoreElement={
          <span>
            ...{" "}
            <span
              onClick={this.onClick}
              className={classes.showMoreText}
              style={customTextStyle}
            >
              {t("see_more_noDots")}
            </span>
          </span>
        }
        showLessElement={
          <span
            onClick={this.onClick}
            className={classes.showLessText}
            style={customTextStyle}
          >
            {" "}
            {t("see_less_noDots")}
          </span>
        }
      />
    );
  }
}

TextMoreLess.defaultProps = {
  minHeight: 72,
  moreText: "...See More",
  customTextStyle: {},
};

TextMoreLess.propTypes = {
  minHeight: PropTypes.number,
  moreText: PropTypes.string,
};

export default I18nHOC({ resource: "common" })(TextMoreLess);
