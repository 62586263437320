import React, { memo } from "react";
import { I18nHOC, UIModal, withLoader, TextMoreLess } from "UIComponents";
import classes from "./ResourceViewModal.scss";
import { colors } from "Constants";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { getResourceQuery } from "modules/CommonQuery";
import { CancelIcon, DownloadIcon } from "SvgComponents";
import { downloadAttachmentZip } from "modules/Services";
import { AttachmentList } from "Attachment";

let ResourceViewModal = memo(
  ({ t, handleCancel, resource, downloadAttachmentZip }) => {
    const downloadAllAttachments = async () => {
      const { attachments = [] } = resource;
      const input = {
        attachmentsObjects: {
          _: {
            attachments,
            folderId: Math.random(),
            folderName: "Resources",
          },
        },
      };
      await downloadAttachmentZip(input);
    };

    const { title, description, attachments = [] } = resource;
    return (
      <div onClick={e => e.stopPropagation()} className={classes.container}>
        <div className={classes.containerWrapper}>
          <div className={classes.headerContainer}>
            <div className={classes.title}>{title}</div>

            <div className={classes.cancelIcon} onClick={handleCancel}>
              <CancelIcon width={16} height={16} />
            </div>
          </div>
          {!_.isEmpty(description) && (
            <div className={classes.descriptionContainer}>
              <div className={classes.descriptionTitle}>
                {t("resource_description")}
              </div>
              <div className={classes.description}>
                {!!description && (
                  <TextMoreLess value={description} minHeight={80} />
                )}
              </div>
            </div>
          )}
          {!_.isEmpty(attachments) && (
            <div className={classes.body}>
              <div className={classes.attachmentTitle}>
                <div className={classes.attachmentCount}>{`${t(
                  "attachments"
                )} (${attachments.length})`}</div>
                {attachments.length > 1 && (
                  <div
                    className={classes.downloadAttachments}
                    onClick={downloadAllAttachments}
                  >
                    <DownloadIcon fill={colors.blue29} />
                    <span>{`${t("download_all_attachments")}`}</span>
                  </div>
                )}
              </div>
              <AttachmentList
                value={attachments}
                cardType={"verticalHorizontal"}
                mode={"view"}
                attachmentContainerStyle={{
                  gridTemplateColumns: "repeat(1,1fr)",
                  position: "relative",
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

const mapStateToProps = state => {
  return {
    isLoading: false,
    isData: true,
    organizationId: state.login.userInfo.org_id,
    userId: _.get(state, "login.userInfo.id"),
  };
};

const mapActionCreators = {
  downloadAttachmentZip,
};

ResourceViewModal = compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getResourceQuery, {
    name: "resourceInfo",
    skip: ({ resourceId }) => !resourceId,
    options: ({ resourceId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: resourceId },
    }),
    props: ({ resourceInfo, ownProps }) => {
      const resource = _.get(resourceInfo, "node");
      // console.log("Resource:", resource);
      return {
        resource,
        ownProps,
        isData: ownProps.isData && !!resource,
        isLoading:
          resourceInfo["networkStatus"] == 1 ||
          resourceInfo["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  }),
  withLoader
)(ResourceViewModal);

const ResourceViewModalWrapper = React.memo(
  ({ setModalVisibility, resourceId }) => {
    const handleCancel = e => {
      if (e) {
        e.stopPropagation();
      }

      setModalVisibility(false);
    };
    return (
      <UIModal
        modalContent={classes.modalContent}
        isOpen={true}
        overlayContainer={classes.overlayContainer}
        onRequestClose={handleCancel}
      >
        <ResourceViewModal
          handleCancel={handleCancel}
          resourceId={resourceId}
        />
      </UIModal>
    );
  }
);

export default ResourceViewModalWrapper;
