export const checkScrollAtBottom = containerDiv => {
  // console.log(
  //   containerDiv.scrollHeight - containerDiv.scrollTop,
  //   containerDiv.clientHeight
  // );
  return (
    Math.abs(
      containerDiv.scrollHeight -
        containerDiv.scrollTop -
        containerDiv.clientHeight
    ) < 5 && containerDiv.scrollTop > 0
  );
};
