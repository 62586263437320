import React, { Fragment } from "react";
import classes from "./SearchBarWithTag.scss";
import { Tag } from "../Tags";
import { ArrowIcon, SearchIcon } from "SvgComponents";
import classNames from "classnames";
import SearchBar from "../SearchBar";
import { colors } from "Constants";

class SearchBarWithTag extends React.PureComponent {
  constructor(props) {
    super(props);
    this.searchBarRef = React.createRef();
  }

  handleKeyDown = event => {
    const {
      removeOnBackspace,
      addOnComma,
      onAddLabel,
      addOnEnter,
      addOnSpace,
    } = this.props;
    if (addOnSpace && event.keyCode === 32) {
      onAddLabel();
    }
    if (addOnComma && event.keyCode === 188) {
      onAddLabel();
    }
    if (addOnEnter && event.keyCode === 13) {
      onAddLabel();
    }
    if (removeOnBackspace && event.keyCode == 8) {
      // backspace
      this.removeLastOption();
    }
  };

  removeLastOption = () => {
    const { searchText, value: selectedTags } = this.props;
    const lastTag = selectedTags[selectedTags.length - 1];
    if (!searchText && selectedTags.length) this.props.removeOption(lastTag);
  };

  clearSearchBar = () => this.searchBarRef.current.cancelText();

  onBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const {
      isFocus,
      removeOption,
      handleFocus,
      placeholder,
      error,
      showErrorText,
      searchText,
      showArrow,
      value: selectedTags,
      onSearchChange,
      onAddLabel,
      styles = {},
      showSearchIcon,
      inlineView,
      showAddNewItem,
      showSearchIconInStart,
      onFocusInputField,
      tagTheme,
    } = this.props;

    const SearchInputStyle = {
      inputTextStyle: {
        fontSize: "1.8rem",
        ...(styles.searchBarInputText || {}),
      },
    };

    const containerClasses = classNames(
      { [classes.container]: true },
      { [classes.focusContainer]: isFocus },
      { [classes.errorContainer]: !!error }
    );

    const containerStyles = {
      ...(styles.searchBarWithTagContainer || {}),
      ...(error ? styles.errorStyle : {}),
      flexDirection: inlineView ? "row" : "column",
    };

    return (
      <Fragment>
        <div
          className={containerClasses}
          style={containerStyles}
          onClick={handleFocus}
        >
          {showSearchIconInStart ? (
            <span className={classes.searchIcon}>
              <SearchIcon fill={colors.black} height={16} width={16} />
            </span>
          ) : null}
          <TagList
            styles={styles}
            selectedTags={selectedTags}
            removeOption={removeOption}
            inlineView={inlineView}
            tagTheme={tagTheme}
          />
          <div
            style={{
              ...(styles.searchBarContainer || {}),
            }}
            className={classes.searchBarContainer}
          >
            <SearchBar
              ref={this.searchBarRef}
              placeholder={placeholder}
              changeSearchTerm={value =>
                onSearchChange ? onSearchChange(value) : null
              }
              showSearchIcon={showSearchIcon}
              showCancelIcon={!inlineView}
              handleKeyDown={this.handleKeyDown}
              {...SearchInputStyle}
              onFocus={onFocusInputField}
              inputTextStyle={styles.inputTextStyle}
              onBlur={this.onBlur}
            />

            {showAddNewItem ? (
              <div
                onClick={onAddLabel}
                className={classes.addNewLabel}
              >{`Add "${searchText}"`}</div>
            ) : null}
          </div>

          {/* {showArrow && (
            <div className={classes.rightContainer}>
              {!isFocus && (
                <div className={classes.arrowContainer} onClick={handleFocus}>
                  <ArrowIcon />
                </div>
              )}
            </div>
          )} */}
        </div>
        {!!error && showErrorText && (
          <div className={classes.errorText}>{error}</div>
        )}
      </Fragment>
    );
  }
}

const TagList = ({
  selectedTags,
  removeOption,
  styles,
  inlineView,
  tagTheme,
}) => {
  const tag = item => (
    <Tag
      styles={{ ...(styles.tag || {}) }}
      item={item}
      key={item.value}
      selected={true}
      removeOption={removeOption}
      showCloseButton={true}
      theme={tagTheme}
    />
  );
  return (
    _.get(selectedTags, "length", 0) > 0 && (
      <Fragment>
        {inlineView ? (
          <Fragment>{_.map(selectedTags, item => tag(item))}</Fragment>
        ) : (
          <div
            style={{ ...(styles.tagListContainer || {}) }}
            className={classes.tagListContainer}
          >
            {_.map(selectedTags, item => tag(item))}
          </div>
        )}
      </Fragment>
    )
  );
};

SearchBarWithTag.defaultProps = {
  showArrow: false,
  removeOnBackspace: false,
  showSearchIcon: true,
  addOnComma: false,
  showAddNewItem: false,
  addOnEnter: false,
  addOnSpace: false,
};

export default SearchBarWithTag;
