import gql from "graphql-tag";
import {
  resourcePlannerFragment,
  plannerFieldFragment,
} from "./UnitPlanFragments";
import { unitPlanFeedFragment } from "modules/CommonFragments";

export const deleteUnitPlanMutation = gql`
  mutation deleteUnitPlan($id: ID!, $centralRepo: Boolean) {
    planner {
      deleteUnitPlan(input: { id: $id, centralRepo: $centralRepo })
    }
  }
`;

export const updateUnitPlanForPlanathonMutation = gql`
  mutation updateUnitPlanForPlanathon(
    $unitPlanId: ID!
    $updatedBy: ID!
    $shortlistForPlanathon: Boolean
    $paritaStarStatus: Boolean
    $kristenStarStatus: Boolean
    $juryStarStatus: Boolean
    $paritaProcessed: Boolean
    $kristenProcessed: Boolean
    $juryProcessed: Boolean
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    planner {
      updateUnitPlan(
        input: {
          unitPlanId: $unitPlanId
          shortlistForPlanathon: $shortlistForPlanathon
          updatedBy: $updatedBy
          paritaStarStatus: $paritaStarStatus
          kristenStarStatus: $kristenStarStatus
          juryStarStatus: $juryStarStatus
          paritaProcessed: $paritaProcessed
          kristenProcessed: $kristenProcessed
          juryProcessed: $juryProcessed
          showCollaborators: true
        }
      ) {
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFeedFragment.unitPlanFeed}
`;

export const clearUnitPlanAssessmentToolMutation = gql`
  mutation clearUnitPlanAssessment($unitPlanId: ID!, $updatedBy: ID!) {
    planner {
      updateUnitPlan(
        input: {
          unitPlanId: $unitPlanId
          clearAssessmentTool: true
          updatedBy: $updatedBy
        }
      ) {
        id
        assessmentTool {
          id
        }
      }
    }
  }
`;

export const createPlannerResourceMutation = gql`
  mutation createPlannerResource(
    $title: String
    $type: String
    $description: String
    $createdBy: ID!
    $parentType: PLANNER_RESOURCE_PARENT_ENUM!
    $parentId: ID!
    $attachments: [AttachmentInput]
  ) {
    planner {
      createPlannerResource(
        input: {
          title: $title
          type: $type
          description: $description
          createdBy: $createdBy
          parentType: $parentType
          parentId: $parentId
          attachments: $attachments
        }
      ) {
        ...resourcePlannerItem
      }
    }
  }
  ${resourcePlannerFragment.resource}
`;

export const addPlannerResourceMutation = gql`
  mutation addPlannerResource(
    $createdBy: ID!
    $parentType: PLANNER_RESOURCE_PARENT_ENUM!
    $parentId: ID!
    $resourceIds: [ID!]!
  ) {
    planner {
      addPlannerResource(
        input: {
          createdBy: $createdBy
          parentType: $parentType
          parentId: $parentId
          resourceIds: $resourceIds
        }
      )
    }
  }
`;

export const updatePlannerResourceMutation = gql`
  mutation updatePlannerResource(
    $resourceId: ID!
    $title: String
    $type: String
    $description: String
    $updatedBy: ID!
    $isForced: Boolean
    $attachments: [AttachmentInput]
  ) {
    planner {
      updatePlannerResource(
        input: {
          resourceId: $resourceId
          title: $title
          type: $type
          description: $description
          updatedBy: $updatedBy
          isForced: $isForced
          attachments: $attachments
        }
      ) {
        warning
        response {
          ...resourcePlannerItem
        }
      }
    }
  }
  ${resourcePlannerFragment.resource}
`;

export const removePlannerResourceMutation = gql`
  mutation removePlannerResource(
    $resourceId: ID!
    $deletedBy: ID!
    $parentType: PLANNER_RESOURCE_PARENT_ENUM!
    $parentId: ID!
    $isForced: Boolean
  ) {
    planner {
      removePlannerResource(
        input: {
          resourceId: $resourceId
          deletedBy: $deletedBy
          parentType: $parentType
          parentId: $parentId
          isForced: $isForced
        }
      ) {
        warning
        response
      }
    }
  }
`;

export const copyUnitPlanMutation = gql`
  mutation copyUnitPlan(
    $unitPlanId: ID!
    $copiedBy: ID!
    $title: String
    $collaborators: [UnitPlanCollaboratorInput!]
    $academicYears: [ID!]
    $courses: [ID!]
    $grades: [ID!]
    $state: UNIT_PLAN_STATE
    $showCollaborators: Boolean! = true
    $centralRepoInput: UnitCentralRepoConfigInput
    $startDate: String
    $endDate: String
    $sourceOrganizationId: ID
    $curriculumProgramId: ID
  ) {
    planner {
      copyUnitPlan(
        input: {
          id: $unitPlanId
          copiedBy: $copiedBy
          title: $title
          collaborators: $collaborators
          academicYears: $academicYears
          courses: $courses
          grades: $grades
          state: $state
          startDate: $startDate
          endDate: $endDate
          curriculumProgramId: $curriculumProgramId
          sourceOrganizationId: $sourceOrganizationId
        }
      ) {
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFeedFragment.unitPlanFeed}
`;

export const importUnitPlanMutation = gql`
  mutation importUnitPlan($input: ImportUnitPlanInput!) {
    planner {
      importUnitPlan(input: $input)
    }
  }
`;

export const updateSubjectForMypInterdisciplinaryUnitMutation = gql`
  mutation updateSubjectForMypInterdisciplinaryUnit(
    $unitPlanId: ID!
    $addedSubjects: [ID!]
    $removedSubjects: [ID!]
  ) {
    planner {
      updateSubjectForMypInterdisciplinaryUnit(
        input: {
          unitPlanId: $unitPlanId
          addedSubjects: $addedSubjects
          removedSubjects: $removedSubjects
        }
      ) {
        id
        allFields {
          ...unitPlanFieldItem
        }
      }
    }
  }
  ${plannerFieldFragment.unitplanField}
`;
