import React from "react";
import ACLStore from "lib/aclStore";
import PAErrorComponent from "./PAErrorComponent";

class PAComponent extends React.Component {
  render() {
    const {
      errorText,
      errorSubText,
      errorImageSrc,
      redirectButtonText,
      onRedirectButtonClick,
    } = this.props;
    const fallbackReturnComp = this.props.showErrorComp ? (
      <PAErrorComponent
        errorText={errorText}
        errorSubText={errorSubText}
        errorImageSrc={errorImageSrc}
        redirectButtonText={redirectButtonText}
        onRedirectButtonClick={onRedirectButtonClick}
      />
    ) : null;
    const returnComp = ACLStore.can(this.props.perm, this.props.status)
      ? this.props.children
      : fallbackReturnComp;
    return returnComp;
  }
}

export default PAComponent;
