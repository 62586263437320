import React, { Fragment, useState } from "react";
import classes from "./ScoreCategoriesConformationModal.scss";
import { UIModal } from "UIComponents";
import { useI18n } from "Hooks";
import { CloseOutlined } from "@toddle-design/web-icons";
import { Button, IconButton, Checkbox } from "@toddle-design/web";
import classNames from "classnames";

const styles = {
  buttonStyle: {
    marginLeft: "20px",
  },
};

const ScoreCategoriesConformationModal = props => {
  const {
    setShowModal,
    isScoreCategoriesEnabled,
    setIsScoreCategoriesEnabled,
    isDeleteConfirmation = false,
    categoryDetail,
  } = props;
  const { t } = useI18n(["common", "classRoom", "academicSetup"]);

  const [checkboxesState, setCheckboxesState] = useState({
    taskCreationConfirmation: false,
    existingAssignmentConfirmation: false,
    deleteConfirmation: false,
  });

  const updateConfirmationState = confirmationState => {
    setCheckboxesState({
      ...checkboxesState,
      [confirmationState]: !checkboxesState[confirmationState],
    });
  };

  const renderDisableConditionBody = (
    <div>
      <div>{t("academicSetup:score_categories_disabled_text")}</div>
      <div className={classes.confirmationContainer}>
        <div className={classes.confirmationTextContainer}>
          <Checkbox
            isChecked={checkboxesState.taskCreationConfirmation}
            onChange={() => updateConfirmationState("taskCreationConfirmation")}
          >
            {t(
              "academicSetup:score_categories_teacher_assessment_confirmation"
            )}
          </Checkbox>
        </div>
        <div className={classes.confirmationTextContainer}>
          <Checkbox
            isChecked={checkboxesState.existingAssignmentConfirmation}
            onChange={() =>
              updateConfirmationState("existingAssignmentConfirmation")
            }
          >
            {t(
              "academicSetup:score_categories_existing_assignment_confirmation"
            )}
          </Checkbox>
        </div>
      </div>
    </div>
  );

  const renderDeleteConfirmation = (
    <div>
      <div>
        {t("academicSetup:score_categories_delete_text", {
          category: _.get(categoryDetail, "label", ""),
        })}
      </div>
      <div className={classes.confirmationContainer}>
        <div className={classes.confirmationTextContainer}>
          <Checkbox
            isChecked={checkboxesState.deleteConfirmation}
            onChange={() => updateConfirmationState("deleteConfirmation")}
          >
            {t("academicSetup:score_categories_delete_category_confirmation", {
              category: _.get(categoryDetail, "label", ""),
            })}
          </Checkbox>
        </div>
      </div>
    </div>
  );

  const modalContentClass = classNames({
    [classes.modalContent]: true,
    [classes.modalDisableContent]: isScoreCategoriesEnabled,
  });

  return (
    <UIModal
      isOpen={true}
      modalContent={modalContentClass}
      onRequestClose={() => setShowModal(false)}
    >
      <Fragment>
        <div className={classes.headContainer}>
          <div className={classes.headingText}>
            {isDeleteConfirmation
              ? t("academicSetup:delete_category")
              : !isScoreCategoriesEnabled
              ? t("common:are_you_sure")
              : t("academicSetup:disable_category_text")}
          </div>
          <IconButton
            icon={<CloseOutlined />}
            variant="plain"
            onClick={() => setShowModal(false)}
          />
        </div>
        <div className={classes.contentContainer}>
          {isDeleteConfirmation
            ? renderDeleteConfirmation
            : isScoreCategoriesEnabled
            ? renderDisableConditionBody
            : t("academicSetup:score_categories_enabled_text")}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            size="large"
            variant="outlined-subtle"
            onClick={() => setShowModal(false)}
          >
            {t("common:cancel")}
          </Button>
          <Button
            size="large"
            variant={
              isScoreCategoriesEnabled || isDeleteConfirmation
                ? "destructive"
                : "primary"
            }
            style={styles.buttonStyle}
            onClick={() => {
              setIsScoreCategoriesEnabled();
              setShowModal(false);
            }}
            disabled={
              isDeleteConfirmation
                ? !checkboxesState.deleteConfirmation
                : isScoreCategoriesEnabled
                ? !checkboxesState.existingAssignmentConfirmation ||
                  !checkboxesState.taskCreationConfirmation
                : false
            }
          >
            {isDeleteConfirmation
              ? t("common:delete")
              : isScoreCategoriesEnabled
              ? t("common:disable")
              : t("common:enable")}
          </Button>
        </div>
      </Fragment>
    </UIModal>
  );
};

export default ScoreCategoriesConformationModal;
