import { connect } from "react-redux";
import comp from "../components";
import { setScreenWithRole } from "./ChooseModule";

const mapActionCreators = {
  setScreenWithRole,
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, mapActionCreators)(comp);
