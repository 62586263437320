import ProjectGroup from "./routes/ProjectGroup";
import { routes } from "./config/routes";
import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";

const {
  projects: { path },
} = routes;

export default store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("Projects/modules/ProjectModules.js");
              injectReducer(store, {
                key: reducer.NAME,
                reducer: reducer.default,
              });
              const module = require("Projects/routes/Progress/modules/ProgressModule");
              injectReducer(store, {
                key: module.NAME,
                reducer: module.default,
              });

              resolve(component);
            },
            err => {
              reject(err);
            }
          );
        })
    ),
    childRoutes: [ProjectGroup(store)],
  };
};
