import { useCallback, useRef, useEffect } from "react";
import { debounce } from "lodash";
import { checkScrollAtBottom } from "services/checkScrollAtBottom";
/**
 * Keep in mind that useRef doesn't notify you when its content changes.
 * Mutating the .current property doesn't cause a re-render.
 * If you want to run some code when React attaches or detaches a ref to a DOM node,
 * you may want to use a useCallbackRef hook instead.
 *
 * USAGE:
 *
 * // Initialization
 * const [nodeRef, setNodeRef] = useCallbackRef();
 *
 * // Attaching with a DOM node
 * <div ref={setNodeRef}>I am a DOM node</div>
 *
 * Benefit: Now even if our div node is removed from DOM and,
 * then suppose new div node is created in next re-render
 * then again setNodeRef will be called and save new DOM node in our refference
 */

export const useCallbackRef = () => {
  const ref = useRef(null);
  const setRef = useCallback(node => {
    ref.current = node;
  }, []);

  return [ref, setRef];
};
export const useDebouncedCallback = (cb, delay, deps = []) => {
  /**
   * this hook to debouce the field values.
   */
  const callbackFunc = useCallback(debounce(cb, delay), deps);
  return [callbackFunc];
};

export const useMount = effect => {
  useEffect(effect, []);
};

/*

  when to use: when you are doing pagination
  where to use: you can you this custom hook in the component where you have defined(or about to define) handleScroll function.
  what not to do: you don't have to do onScroll event and pass it handleScroll function in the scrollable element, this hook will do it for you.

  how to use it:
    
    pass following parameters : 
      networkStatus(number),
      loadMoreFeed(function),
      hasNextPage(bool),
    above all are self explanatory.

    what to pass getScrollRef: here you have to pass function which will return ref of the element for which you want to add "scroll" event.

*/
export const useHandleScroll = ({
  networkStatus,
  loadMoreFeed,
  hasNextPage,
  getScrollRef,
  throttleTime = 300,
}) => {
  const loadMorePropRef = useRef({});

  loadMorePropRef.current = { hasNextPage, networkStatus, loadMoreFeed };
  useEffect(() => {
    const scrollRef = getScrollRef();
    const handleScroll = e => {
      const containerDiv = e.target;
      if (
        containerDiv &&
        loadMorePropRef.current.hasNextPage &&
        loadMorePropRef.current.networkStatus != 3 &&
        checkScrollAtBottom(containerDiv)
      ) {
        loadMorePropRef.current.loadMoreFeed();
      }
    };
    const handleScrollThrottle = _.throttle(handleScroll, throttleTime);

    scrollRef.addEventListener("scroll", handleScrollThrottle);
    return () => {
      scrollRef.removeEventListener("scroll", handleScrollThrottle);
    };
  }, []);
};
