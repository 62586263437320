import React from "react";
import { EmojiPicker, I18nHOC, EmptyView, Loading } from "UIComponents";
import PropTypes from "prop-types";
import classes from "./RichTextEditor.scss";
import {
  BigTextIcon,
  MediumTextIcon,
  SmallTextIcon,
  SmileyIcon,
  LinkIcon,
} from "./Icons";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { FONT_URL, colors } from "Constants";
import { addHttpInUrl, htmlToText, getStyleStrippedText } from "Utils";
import ACLStore from "lib/aclStore";
import LinkifyModal from "./components/LinkifyModal";
import {
  getAnchorElement,
  createLink,
  updateLink,
  isAnchorTag,
  getAttribute,
} from "./Utils";
import { NoFoldersIllustration } from "@toddle-design/theme";
import classNames from "classnames";

const SIZE_BUTTONS = [
  {
    name: "textsmall",
    title: "Text Small",
    image: SmallTextIcon,
    size: "1.6rem",
  },
  {
    name: "textmedium",
    title: "Text Medium",
    image: MediumTextIcon,
    size: "1.8rem",
  },
  { name: "textbig", title: "Text Big", image: BigTextIcon, size: "2rem" },
];

let wasEditorEmojiButtonClicked = false;
/**
  Custom Emoji Plugin
 */
const initEmojiPlugin = config => {
  /**
    Add Emoji button in the toolbar
   */
  let wasButtonPositionCalculated = false;
  config.editor.addButton("emojiButton", {
    title: "Open Emoji Picker",
    image: SmileyIcon,
    onclick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      const button = _.get(this, "$el.0");
      const container = _.get(config, "containerRef.current");
      if (button && container && !wasButtonPositionCalculated) {
        const pos = calculateXY(button, container);
        config.setState(prevState => ({
          targetElX: pos.x,
          isPickerOpen: true,
        }));
        wasButtonPositionCalculated = true;
        wasEditorEmojiButtonClicked = true;
      } else if (wasButtonPositionCalculated) {
        config.setState(prevState => ({
          isPickerOpen: !prevState.isPickerOpen,
        }));
      }
    },
  });
};

const CreateFontSizeButtons = editor => {
  _.forEach(SIZE_BUTTONS, item => {
    editor.addButton(item.name, {
      title: item.title,
      image: item.image,
      onclick: function () {
        editor.execCommand("fontSize", false, item.size);
      },
      onPostRender: function () {
        var _this = this; // reference to the button itself
        editor.on("NodeChange", function (e) {
          let fontSize = "";
          if (editor.selection && editor.dom) {
            const node = editor.selection.getNode();
            fontSize = editor.dom.getStyle(node, "font-size");
          }
          _this.active(fontSize == item.size);
        });
      },
    });
  });
};

const addLinkButtons = ({ editor, setState }) => {
  // add link button
  editor.addButton("my-link", {
    title: "Insert/Edit link",
    image: LinkIcon,
    onclick: function () {
      const node = editor.selection.getNode();
      let href = "";
      let title = "";
      let linkType = "URL";
      let filename = "";
      let mimeType = "";
      let fileType = "";
      /**
       * if node is an anchor tag
       * get its text content
       */
      if (isAnchorTag({ node })) {
        href = _.get(node, "href", "");
        title = _.get(node, "innerText", _.get(node, "textContent", ""));
        linkType = getAttribute({
          editor,
          element: node,
          attributeName: "data-link-type",
        });
        filename = getAttribute({
          editor,
          element: node,
          attributeName: "data-filename",
        });
        mimeType = getAttribute({
          editor,
          element: node,
          attributeName: "data-mimetype",
        });
        fileType = getAttribute({
          editor,
          element: node,
          attributeName: "data-filetype",
        });
      } else {
        /**
         * if node is not an anchor tag
         * simply get the text that is selected from the editor
         * selected text can also be html
         * in that case getContent will give you html mixed
         * remove html from it
         */
        title = htmlToText(editor.selection.getContent());
      }
      setState({
        isLinkModalOpen: true,
        linkModalUrl: href,
        linkModalTitle: title,
        linkModalLinkType: linkType,
        linkModalFilename: filename,
        linkModalMimeType: mimeType,
        linkModalFileType: fileType,
      });
    },
  });
  /**
   * add a delete-link button
   * used in popup that comes when you click a linkified text
   */
  editor.addButton("delete-link", {
    title: "Delete link",
    icon: "unlink",
    onclick: function () {
      editor.execCommand("Unlink", false);
    },
  });
  /**
   * add a new-tab button
   * used in popup that comes when you click a linkified text
   */
  editor.addButton("new-tab-link", {
    title: "Open link",
    icon: "newtab",
    onclick: function () {
      const node = editor.selection.getNode();
      /**
       * if node is an anchor tag & its href exists
       * then open in a new tab
       */
      if (isAnchorTag({ node })) {
        const href = _.get(node, "href", "");
        if (href) {
          window.open(href, "_blank");
        }
      }
    },
  });
};

const addContextToolbar = ({ editor }) => {
  editor.addContextToolbar(() => {
    /**
     * only show this popover if node is an anchor tag
     */
    const node = editor.selection.getNode();
    return isAnchorTag({ node });
  }, "new-tab-link | my-link delete-link");
};

const setupLinkPlugin = ({ editor, setState }) => {
  addLinkButtons({ editor, setState });
  /**
   * popup when the cursor is at a linkified text
   */
  addContextToolbar({ editor });
};

const calculateXY = (target, container) => {
  const targetRect = target.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const x = targetRect.left - containerRect.left;
  const y = targetRect.top - containerRect.top;
  return { x, y };
};

let script = document.getElementById("tinymce-script");
let LazyEditor = null;

class RichTextEditor extends UIBaseComponent {
  state = {
    ...this.state,
    isPickerOpen: false,
    targetElX: 0,
    isEditorLoading: true,
    isTinymceScriptLoading: script ? false : true,
    isEventListenerAdded: false,
  };
  containerRef = React.createRef();

  /*
    - Function - onTinymceScriptLoaded
    - Lazy load Editor from tinymce after script is loaded in componentDidMount.
  */
  onTinymceScriptLoaded({ isEventListenerAdded }) {
    import(
      /* webpackChunkName: "tinymce" */
      "@tinymce/tinymce-react"
    )
      .then(module => {
        LazyEditor = module.Editor;
        this.setState({
          isTinymceScriptLoading: false,
          isEditorLoading: false,
          isEventListenerAdded,
        });
      })
      .catch(() => {
        window.location.reload();
      });
  }
  /*
    - Here tinymce script is loaded first if not loaded previously.
    - Then we are importing Editor component dynamically from tinymce 
      in onTinymceScriptLoaded function.
  */
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);

    const { isTinymceScriptLoading } = this.state;
    if (!script) {
      script = document.createElement("script");
      script.id = "tinymce-script";
      script.src = "/tinymce/tinymce.min.js";
      script.defer = 1;
      document.head.appendChild(script);
      script.addEventListener("load", () => {
        this.onTinymceScriptLoaded({ isEventListenerAdded: true });
      });
    } else if (isTinymceScriptLoading) {
      script.addEventListener("load", () => {
        this.onTinymceScriptLoaded({ isEventListenerAdded: true });
      });
    } else {
      this.onTinymceScriptLoaded({ isEventListenerAdded: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);

    const { isEventListenerAdded } = this.state;
    if (isEventListenerAdded) {
      script.removeEventListener("load", () => {
        this.onTinymceScriptLoaded({ isEventListenerAdded: true });
      });
    }
  }

  changeValue = val => {
    this.updateValue(val);
  };

  getViewValue = () => {
    return this.props.value ? this.props.value : "N/A";
  };

  onEmojiSelect = e => {
    const { native: emoji } = e;
    this.editor.execCommand("mceInsertContent", false, emoji); // Insert emoji at cursor
  };

  handleClickOutside = event => {
    if (
      this.state.isPickerOpen &&
      !this.containerRef?.current?.contains(event.target)
    ) {
      this.setState(prevState => ({
        isPickerOpen: false,
      }));
    }
  };

  setup = editor => {
    const { bodyStyle } = this.props;
    this.editor = editor;
    if (ACLStore.can("Common:ShowEmoji")) {
      initEmojiPlugin({
        editor,
        setState: this.setState.bind(this),
        containerRef: this.containerRef,
      });
    }
    setupLinkPlugin({ editor, setState: this.setState.bind(this) });
    CreateFontSizeButtons(editor);

    editor.on("Init", function (ed) {
      if (editor && editor.dom) {
        editor.dom.addStyle(bodyStyle);
        const scriptId = editor.dom.uniqueId();

        const scriptElm = editor.dom.create("script", {
          id: scriptId,
          type: "text/javascript",
          src: FONT_URL,
        });

        editor.getDoc().getElementsByTagName("head")[0].appendChild(scriptElm);
      }

      //Hack to remove Auto Focus
      if (editor && editor.getBody()) {
        editor.getBody().setAttribute("contenteditable", false);
      }

      setTimeout(() => {
        if (editor && editor.getBody()) {
          editor.getBody().setAttribute("contenteditable", true);
        }
      }, 100);
    });
  };

  onClickSaveLink = params => {
    let { url = "" } = params;
    const {
      title = "",
      linkType = "URL",
      filename,
      mimeType,
      fileType,
    } = params;
    if (url) {
      if (!url.startsWith("http") && !url.startsWith("https")) {
        url = `//${url}`;
      }
      const anchorElement = getAnchorElement(this.editor);
      const linkAttr = {
        href: url,
        target: "_blank",
        "data-link-type": linkType,
        "data-filename": filename,
        "data-mimetype": mimeType,
        "data-filetype": fileType,
      };
      if (!anchorElement) {
        createLink({ editor: this.editor, linkAttr, title });
      } else {
        updateLink({ editor: this.editor, anchorElement, linkAttr, title });
      }
    }
    this.onCloseLinkModal();
  };

  onCloseLinkModal = () => {
    this.editor.focus();
    this.setState({
      isLinkModalOpen: false,
      linkModalUrl: "",
      linkModalTitle: "",
    });
  };

  renderViewEmpty = () => {
    const { t } = this.props;

    return (
      <EmptyView
        emptyImageUrl={NoFoldersIllustration}
        emptyHeaderText={t("common:no_label_added", {
          label: t("common:unit_summary"),
        })}
        size="small"
      />
    );
  };

  editorRef = ref => {
    if (ref) {
      this.updateInputRef(ref?.editor?.iframeElement);
    }
  };

  renderEdit = () => {
    const {
      value,
      height,
      plugins,
      toolbar,
      width,
      showMenubar,
      showStatusbar,
      toolbarItemsSize,
      autoresizeMaxHeight,
      readOnly,
      isLocked,
      disabled,
      topMargin,
    } = this.props;

    const { isEditorLoading } = this.state;
    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.disabledContainer]: disabled }
    );

    const getContentStyle = () => {
      if (disabled) {
        return `.mce-content-body { font-size : 1.6rem; cursor : not-allowed;}`;
      } else return "";
    };

    return isLocked ? (
      value ? (
        <div className={classes.lockContainer}>
          <div
            dangerouslySetInnerHTML={{ __html: this.getViewValue() }}
            className={classes.lockContainerText}
          ></div>
        </div>
      ) : (
        this.renderViewEmpty()
      )
    ) : (
      <div className={containerClass} ref={this.containerRef}>
        {this.state.isLinkModalOpen && (
          <LinkifyModal
            onClose={this.onCloseLinkModal}
            onClickSave={this.onClickSaveLink}
            url={this.state.linkModalUrl}
            title={this.state.linkModalTitle}
            linkType={this.state.linkModalLinkType}
            filename={this.state.linkModalFilename}
            mimeType={this.state.linkModalMimeType}
            fileType={this.state.linkModalFileType}
          />
        )}
        {ACLStore.can("Common:ShowEmoji") && (
          <EmojiPicker
            isOpen={this.state.isPickerOpen}
            onEmojiSelect={this.onEmojiSelect}
            popoverProps={{
              contentDestination: this.containerRef?.current,
              contentLocation: ({ popoverRect }) => {
                return {
                  top: topMargin || -popoverRect.height,
                  left: this.state.targetElX - popoverRect.width / 2 + 16, // +16 due to btn width of 32px
                };
              },
            }}
            onClickOutside={() => {
              if (!wasEditorEmojiButtonClicked) {
                this.setState(prevState => ({
                  isPickerOpen: true,
                }));
              } else {
                this.setState(prevState => ({
                  isPickerOpen: false,
                }));
              }
            }}
          >
            <div className={classes.emojiTarget} />
          </EmojiPicker>
        )}
        {!isEditorLoading ? (
          <LazyEditor
            value={value || ""}
            onEditorChange={this.changeValue}
            ref={this.editorRef}
            disabled={disabled}
            onFocusIn={e => this.onFocus({ value: e.target.value })}
            onFocusOut={e => this.onBlur({ value: e.target.value })}
            init={{
              plugins: plugins,
              toolbar: toolbar,
              height: height,
              width: width,
              setup: this.setup,
              menubar: showMenubar,
              statusbar: showStatusbar,
              toolbar_items_size: toolbarItemsSize,
              autoresize_min_height: height,
              autoresize_max_height: readOnly ? "auto" : autoresizeMaxHeight,
              link_title: false,
              target_list: false,
              forced_root_block: "div",
              default_link_target: "_blank",
              paste_word_valid_elements: "b,strong,i,em,h1,h2",
              invalid_elements: "img,svg",
              paste_data_images: false,
              paste_enable_default_filters: false,
              content_style: getContentStyle(),
              paste_preprocess: function (plugin, args) {
                const text = getStyleStrippedText({
                  text: args.content,
                  type: "RICH_TEXT_EDITOR",
                });
                args.content = `${text} `;
              },
              link_context_toolbar: true,
              browser_spellcheck: true,
              autoresize_bottom_margin: 0,
              anchor_top: false,
              anchor_bottom: false,
              relative_urls: false,
              urlconverter_callback: (url, node, on_save) => {
                return addHttpInUrl(url);
              },
              extended_valid_elements:
                "+@[data-link-type|data-filename|data-mimetype|data-filetype]",
            }}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  };
}

export default I18nHOC({ resource: ["common"] })(RichTextEditor);

RichTextEditor.propTypes = {
  ...UIBaseComponent.propTypes,
  height: PropTypes.number,
  plugins: PropTypes.string,
  showMenubar: PropTypes.bool,
  showStatusbar: PropTypes.bool,
  toolbarItemsSize: PropTypes.string,
  autoresizeMaxHeight: PropTypes.number,
  contentCss: PropTypes.array,
  bodyStyle: PropTypes.string,
};

RichTextEditor.defaultProps = {
  ...UIBaseComponent.defaultProps,
  height: 350,
  width: 864,
  plugins: "autolink autoresize lists paste",
  toolbar:
    "textsmall textmedium textbig  |  bold italic  underline  | alignjustify | bullist numlist | my-link emojiButton",
  showMenubar: false,
  showStatusbar: false,
  toolbarItemsSize: "small",
  autoresizeMaxHeight: 500,
  bodyStyle:
    ".mce-content-body {margin:24px !important; font-family: 'AvenirNextLTW01-Regular', 'Avenir Next LT W01 Demi','AvenirNextLTW01-UltraLi', 'Avenir Next W01 Thin','AvenirNextLTW01-Medium', 'Avenir Next LT W01 Bold','Avenir Next W01 Light' ,'AvenirNextLTW01-DemiCn','AvenirNextLTW01-MediumC_721311','AvenirNextLTW01-BoldCn' !important;line-height: 1.42857143;} .mce-content-body div{ unicode-bidi: plaintext;}",
};
