import gql from "graphql-tag";
import {
  templateItemDetailFragment,
  benchmarkSetFragment,
  organizationPresetFragment,
  organizationFragment,
  teacherFragments,
  organizationStaffFeedFragment,
  standardFragments,
  academicYearFragment,
  progressReportBaseTemplateFragment,
} from "./ToolingFragments";
import { designationFragment } from "modules/CommonFragments";

// Generic API for querying data required for internal tools
export const getInternalToolsGenericQuery = gql`
  query getInternalToolsGenericQuery(
    $apiType: INTERNAL_TOOL_API_TYPE_ENUM!
    $params: JSON!
  ) {
    platform {
      internalToolsGenericQuery(input: { apiType: $apiType, params: $params }) {
        isSuccess
        warning
        data
      }
    }
  }
`;

export const getProgressReportBaseTemplateListQuery = gql`
  query getProgressReportBaseTemplateList {
    documentation {
      progressReportBaseTemplateList {
        ...baseTemplateList
      }
    }
  }
  ${progressReportBaseTemplateFragment.baseTemplateList}
`;

export const getProgressReportBaseTemplateQuery = gql`
  query getProgressReportBaseTemplate($id: ID!) {
    node(id: $id, type: PROGRESS_REPORT_BASE_TEMPLATE) {
      id
      ...baseTemplateItemDetails
    }
  }
  ${progressReportBaseTemplateFragment.baseTemplateItemDetails}
`;

export const getOrganizationPlannerTemplateQuery = gql`
  query getOrganizationPlannerTemplateDetails {
    platform {
      organization {
        id
        plannerTemplate {
          ...templateItem
        }
      }
    }
  }
  ${templateItemDetailFragment.templateItemDetail}
`;

export const getAllOrganizationQuery1 = gql`
  query getAllOrganizationDetails {
    platform {
      allOrganization {
        id
        name
        code
        constants {
          rubricLevels
          isShowCommentToParents
          calendarEventTags
          newsEventTags
        }
      }
    }
  }
`;

export const getAllOrganizationQuery = gql`
  query getAllOrganizationDetails($searchText: String!, $after: String) {
    organizationManagement {
      organizationPresetFeed(
        first: 20
        searchText: $searchText
        after: $after
      ) {
        totalCount
        edges {
          node {
            ...organizationItem
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${organizationPresetFragment.orgItem}
`;

export const getOrganizationFeedQuery = gql`
  query getOrganizationFeed(
    $filters: OrganizationFeedConnectionFilter
    $after: String
  ) {
    platform {
      toddleBuddies
      buddies {
        id
        firstName
        lastName
      }
      organizationFeed(first: 20, filters: $filters, after: $after) {
        totalCount
        feedAnalytics
        onboardingAnalytics
        transitionAnalytics
        subscriptionPlanAnalytics
        edges {
          node {
            ...organizationItem
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${organizationFragment.organizationList}
`;

export const getOrganizationFeedTotalCountQuery = gql`
  query getOrganizationFeedTotalCount(
    $filters: OrganizationFeedConnectionFilter
    $after: String
  ) {
    platform {
      organizationFeed(first: 1, filters: $filters) {
        totalCount
      }
    }
  }
`;

export const getOrganizationApprovalRequestFeed = gql`
  query getOrganizationApprovalRequestFeed($searchText: String) {
    organizationManagement {
      organizationApprovalRequestFeed(filters: { searchText: $searchText }) {
        id
        name
        type
        ibCode
        city
        state
        country
        firstName
        lastName
        email
        role
        url
        webUrl
        createdAt
        designation {
          ...designationItem
        }
      }
    }
  }
  ${designationFragment.designation}
`;

export const getUserApprovalRequestFeed = gql`
  query getUserApprovalRequestFeed($searchText: String) {
    organizationManagement {
      userApprovalRequestFeed(filters: { searchText: $searchText }) {
        id
        firstName
        lastName
        email
        role
        url
        webUrl
        createdAt
        designation {
          ...designationItem
        }
        organizationPreset {
          ...organizationItem
        }
      }
    }
  }
  ${organizationPresetFragment.orgItem}
  ${designationFragment.designation}
`;

export const getBenchmarkSetFeed = gql`
  query getBenchmarkSetFeed {
    planner {
      benchmarkSetFeed {
        ...benchmarkSetItem
      }
    }
  }
  ${benchmarkSetFragment.benchmarkSet}
`;

export const getOrganizationSetting = gql`
  query getOrganizationSetting($id: ID!, $isForTools: Boolean) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        settings(isForTools: $isForTools) {
          name
          value
          type
          helpText
          label
        }
      }
    }
  }
`;

// User Feed

export const getAllOrganizationForFilter = gql`
  query getAllOrganizationDetailsForFilter {
    platform {
      allOrganization {
        id
        name
      }
    }
  }
`;

export const getUserFeedQuery = gql`
  query getUserFeed(
    $searchText: String!
    $organizations: [String]
    $after: String
  ) {
    platform {
      userFeed(
        first: 20
        filters: { searchText: $searchText, organizations: $organizations }
        after: $after
      ) {
        totalCount
        edges {
          node {
            ...teacherItem
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${teacherFragments.teacherFeed}
`;

export const getStaffDetailQuery = gql`
  query getStaff($ids: [ID!]) {
    platform {
      staff(ids: $ids) {
        ...teacherItemDetail
      }
    }
  }
  ${teacherFragments.teacherDetails}
`;

export const getTeacherFeedQuery = gql`
  query getTeacherFeed(
    $organizationId: ID!
    $after: String
    $filters: OrgConnectionFilterInput
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationStaffFeedEdgeItem
      }
    }
  }
  ${organizationStaffFeedFragment.organizationStaffFeedEdge}
`;

export const getCoursesOfTeacherQuery = gql`
  query getCoursesOfTeacher($ids: [ID!], $filters: CourseFilter) {
    platform {
      staff(ids: $ids) {
        id
        courses(filters: $filters) {
          id
          title
          profileImage
          owner {
            id
          }
        }
      }
    }
  }
`;

// Standards Feed Query

export const getStandardFeedQuery = gql`
  query getStandardFeed(
    $filters: standardBenchmarkSetFeedConnectionFilter
    $after: String
  ) {
    planner {
      standardBenchmarkSetFeed(first: 20, filters: $filters, after: $after) {
        totalCount
        edges {
          node {
            ...standardItem
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${standardFragments.standardFeed}
`;

export const getAllGlobalSubjectsQuery = gql`
  query getAllGlobalSubjectsQuery {
    platform {
      allGlobalSubjects {
        ...globalSubjects
      }
    }
  }
  ${standardFragments.globalSubjects}
`;

// Academic Year List

export const getAcademicYears = gql`
  query getAcademicYears($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        academicYears {
          ...academicYear
        }
      }
    }
  }
  ${academicYearFragment.academicYear}
`;

// Configurable Settings List

export const getConfigurableSettingsQuery = gql`
  query getConfigurableSettings($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        configurablePermission {
          id
          label
          isEnabled
        }
      }
    }
  }
`;
export const fetchAllClassesOfOrgQuery = gql`
  query allClassesOfOrganisation($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        courses {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  }
`;
