import {
  MediaCard,
  Dropdown,
  DropdownMenu,
  IconButton,
} from "@toddle-design/web";
import React, { useState } from "react";
import { compose } from "react-apollo";
import {
  DownloadOutlined,
  MoreVerticalDotsOutlined,
  DeleteOutlined,
} from "@toddle-design/web-icons";
import { I18nHOC, LinkWithTooltip } from "UIComponents";
import { downloadFromUrl } from "modules/Services";
import { connect } from "react-redux";
import { getThumbUrl, htmlToText } from "Utils";
import { ModelContent } from "../AttachmentItem/AttachmentItem";
import { DEFAULT_LINK_THUMB_URL } from "store/static";
import PropTypes from "prop-types";
import classes from "./MediaCardWrapper.scss";
import classNames from "classnames";

const optionsWithIcon = [
  {
    key: "download",
    label: "Download File",
    icon: <DownloadOutlined size="xx-small" />,
  },
  {
    key: "delete",
    isDestructive: true,
    label: "Delete File",
    icon: <DeleteOutlined size="xx-small" />,
  },
];

const getAllowDownload = type => {
  return !["WORKBOOK", "LINK"].includes(type);
};

const toolTipStyle = { maxWidth: 200, padding: 0 };

const MediaCardWrapper = ({
  activeAttachment,
  attachment,
  deleteAttachment,
  onClickAttachmentCard,
  mode,
  mediaCardSize,
  downloadFromUrl,
  attachmentBottomComponent,
  horizontalCardStyle,
  t,
  getViewModeExtraButtons,
  sourceType,
  updateAttachments,
  attachmentGroup,
  showActionsOnHover,
}) => {
  const [showMediaModal, setShowMediaModal] = useState(false);

  const isWorkbook = _.isEqual(_.get(attachment, "type", ""), "WORKBOOK");
  const uploadProgress = _.get(attachment, "progress", "");

  //disable media card click while while pdf is being converted to workbook
  let workbookClickDisabledUntilUpload = false;
  if (isWorkbook && _.isNumber(uploadProgress) && uploadProgress < 100) {
    workbookClickDisabledUntilUpload = true;
  }

  const workbookPageCount =
    isWorkbook && _.get(attachment, "metadata.numOfPages", 0);

  const workbookPageCountLabel = workbookPageCount
    ? t("common:workbook_page", {
        count: workbookPageCount,
      })
    : "";

  const allowDownload = getAllowDownload(attachment.type);
  const onCardActionClick = action => {
    switch (action) {
      case "delete":
        deleteAttachment(attachmentGroup.id);
        break;
      case "download":
        downloadFromUrl({
          attachment,
          shouldAskForSave: true,
          showProgress: mode !== "view",
        });
        break;
      default:
        console.warn("do nothing");
    }
  };

  const downloadIconClass = classNames({
    [classes.flex]: true,
    [classes.downloadIconCon]: mediaCardSize !== "small",
  });

  const alignConfig = { offset: [mediaCardSize === "small" ? 6 : 0, 0] };

  const getCardActions = () => {
    const options = optionsWithIcon.filter(option =>
      option.key === "download" ? allowDownload : true
    );
    if (mode === "edit") {
      const attachmentOptions = (
        <Dropdown
          getPopupContainer={el => {
            return el;
          }}
          overlay={
            <DropdownMenu
              onClick={e => {
                e.domEvent.stopPropagation();
                const action = e.key;
                onCardActionClick(action);
              }}
              options={options}
            />
          }
          placement={"bottomRight"}
        >
          <IconButton
            onClick={e => {
              e.stopPropagation();
            }}
            icon={
              <MoreVerticalDotsOutlined variant={"subtle"} size={"xx-small"} />
            }
            variant={"plain"}
          />
        </Dropdown>
      );
      return attachmentOptions;
    } else {
      return (
        <React.Fragment>
          {getViewModeExtraButtons && getViewModeExtraButtons?.({ attachment })}
          {allowDownload && (
            <LinkWithTooltip
              tooltip={t("common:download")}
              placement="topRight"
              mouseEnterDelay={0.5}
              alignConfig={alignConfig}
              tooltipContainerStyle={toolTipStyle}
            >
              <div className={downloadIconClass}>
                <DownloadOutlined
                  size={"xxx-small"}
                  variant={"subtle"}
                  onClick={e => {
                    e.stopPropagation();
                    onCardActionClick("download");
                  }}
                />
              </div>
            </LinkWithTooltip>
          )}
        </React.Fragment>
      );
    }
  };

  const handleOnClickMediaCard = e => {
    e.stopPropagation();
    //disable media card click while while pdf is being converted to workbook
    if (workbookClickDisabledUntilUpload) return;

    if (onClickAttachmentCard) {
      onClickAttachmentCard(attachment, attachmentGroup);
    } else {
      if (!showMediaModal) {
        setShowMediaModal(true);
      }
    }
  };

  const toggleMediaModal = () => setShowMediaModal(!showMediaModal);

  const { id, name, mimeType, type } = attachment;

  const { thumbUrl, isIcon } = getThumbUrl({
    attachment,
    width: 40,
    height: 40,
  });

  const isActiveAttachment = activeAttachment
    ? activeAttachment == _.get(attachmentGroup, "attachmentId") ||
      activeAttachment == id
    : false;
  // we are adding this only for LINK type to show default icon in DS mediaCard
  // we will remove this once we change all attachment cards to DS mediaCard.
  const isDefaultLinkThumbUrl =
    _.isEqual(type, "LINK") &&
    _.isEqual(_.get(attachment, "thumbUrl"), DEFAULT_LINK_THUMB_URL);

  return (
    <div style={horizontalCardStyle}>
      <MediaCard
        thumbUrl={isIcon || isDefaultLinkThumbUrl ? undefined : thumbUrl}
        mimeType={mimeType}
        name={htmlToText(name) || t("common:untitled")}
        size={
          attachment?.metadata?.fileSize ??
          attachment?.metadata?.size ??
          undefined
        }
        type={type}
        onClick={handleOnClickMediaCard}
        cardType={mediaCardSize}
        options={getCardActions()}
        isActivated={isActiveAttachment}
        bottomElement={attachmentBottomComponent()}
        pageCount={workbookPageCountLabel}
        showActionsOnHover={showActionsOnHover}
      />

      {showMediaModal && (
        <ModelContent
          attachments={[attachment]}
          toggleMediaModal={toggleMediaModal}
          mode={mode}
          sourceType={sourceType}
          updateAttachments={updateAttachments}
          onWorkbookUpdateSuccessfully={() => toggleMediaModal(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { attachment } = ownProps;
  if (_.get(attachment, "isPost", false)) {
    const attachmentObj = _.last(_.get(attachment, "attachments", []));
    const isWorkbook = _.isEqual(_.get(attachmentObj, "type", ""), "WORKBOOK");
    const isPdf =
      _.isEqual(_.get(attachmentObj, "type", ""), "FILE") &&
      _.isEqual(_.get(attachmentObj, "mimeType", ""), "application/pdf");

    if (isWorkbook || isPdf) {
      return {
        attachment: {
          ...attachmentObj,
          attachmentId: _.get(attachmentObj, "id"),
          isPost: true,
        },
        attachmentGroup: attachment,
      };
    } else {
      return {
        attachment: {
          ...attachmentObj,
          attachmentId: _.get(attachmentObj, "id"),
          id: attachment.id,
          isPost: true,
        },
        attachmentGroup: attachment,
      };
    }
  }

  return {
    attachment,
    attachmentGroup: attachment,
  };
};

MediaCardWrapper.propTypes = {
  mediaCardSize: PropTypes.string,
  showActionsOnHover: PropTypes.bool,
};

MediaCardWrapper.defaultProps = {
  mediaCardSize: "medium",
  showActionsOnHover: false,
};

const mapActionCreators = { downloadFromUrl };

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators, null, { withRef: true })
)(MediaCardWrapper);
