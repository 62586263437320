import React, { useRef, useEffect } from "react";
import classes from "./ColorTray.scss";
import { colors } from "Constants";
import { ColorDrop } from "UIComponents";
const ColorTray = React.memo(props => {
  const { onBlur, onColorSelected, selectedColor, options } = props;
  const node = useRef();

  return (
    <div ref={node} className={classes.colorTray}>
      {_.map(options, color => (
        <div
          key={color}
          className={classes.colorDropContainer}
          onClick={() => {
            onColorSelected(color);
          }}
        >
          <ColorDrop
            size={24}
            isSelected={color === selectedColor}
            color={color}
          />
        </div>
      ))}
    </div>
  );
});

ColorTray.defaultProps = {
  options: [
    colors.blue29,
    colors.salmon60,
    colors.yellow50,
    colors.violet63,
    colors.teal20,
    colors.pink39,
    colors.blue68,
    colors.gray48,
    colors.blueTwo,
    null, //No Colour
  ],
};

export default ColorTray;
