/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip } from "@toddle-design/web";

/**--relative-- */
import classes from "./CurriculumPlanDetails.scss";

const TooltipContent = props => {
  const { curriculumPrograms, showPlanNameInTooltip } = props;
  return (
    <ul className={classes.list}>
      {_.map(curriculumPrograms, ({ acronym, subscriptionPlan, id, type }) => {
        const curriculumProgramAcronym = acronym ?? type;

        const content = showPlanNameInTooltip
          ? `${curriculumProgramAcronym} (${subscriptionPlan.label})`
          : curriculumProgramAcronym;
        return (
          <li key={id} className={classes.listItem}>
            {content}
          </li>
        );
      })}
    </ul>
  );
};

const CurriculumPlanDetails = props => {
  const { curriculumPrograms, showPlanNameInTooltip } = props;

  const curriculumProgramsCount = _.size(curriculumPrograms);

  if (curriculumProgramsCount == 0) {
    const noProgramsClasses = classNames("text-body-1", classes.noPrograms);
    return <div className={noProgramsClasses}>{"N/A"}</div>;
  }

  const text =
    curriculumProgramsCount == 1
      ? `${curriculumProgramsCount} program`
      : `${curriculumProgramsCount} programs`;

  const programsCountClasses = classNames(
    "text-label-2",
    classes.programsCount
  );

  return (
    <Tooltip
      placement="bottom"
      tooltip={
        <TooltipContent
          showPlanNameInTooltip={showPlanNameInTooltip}
          curriculumPrograms={curriculumPrograms}
        />
      }
    >
      <div className={programsCountClasses}>{text}</div>
    </Tooltip>
  );
};

export default CurriculumPlanDetails;

CurriculumPlanDetails.defaultProps = {
  showPlanNameInTooltip: false,
};

CurriculumPlanDetails.propTypes = {
  showPlanNameInTooltip: PropTypes.bool,
};

CurriculumPlanDetails.displayName = "CurriculumPlanDetails";
