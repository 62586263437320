import React from "react";
import classes from "./AssessmentToolCreate.scss";
import { AddCircleIcon } from "SvgComponents";
import { I18nHOC } from "UIComponents";

const AssessmentToolCreate = React.memo(props => {
  const { onAssessmentToolCreateClick, name, hideChooseText, t } = props;
  return (
    <div className={classes.container}>
      <div
        className={classes.createButton}
        onClick={onAssessmentToolCreateClick}
      >
        <div className={classes.addIcon}>
          <AddCircleIcon width={20} height={20} />
        </div>

        <div className={classes.createText}>
          {t("common:create_new_with_label", { label: name })}
        </div>
      </div>
      {!hideChooseText && (
        <div className={classes.chooseText}>
          {t("unitPlan:or_choose_template_with_label", { label: name })}
        </div>
      )}
    </div>
  );
});

export default I18nHOC({ resource: ["unitPlan", "common"] })(
  AssessmentToolCreate
);
