import React, { PureComponent, useState } from "react";

import classes from "./TagFolders.scss";

import ScreenPanel from "../../ScreenPanel";

import { LinkWithTooltip, I18nHOC } from "UIComponents";

import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";

import { getCourseFoldersFromCache } from "Journal/modules/JournalGraphqlHelpers";

import { getCourseFolders } from "Journal/modules/JournalQuery";

import { CircularAlertIcon } from "SvgComponents";
import { FolderFilled } from "@toddle-design/web-icons";

import { colors } from "Constants";

import { Button, Checkbox } from "@toddle-design/web";
import { EmptyState } from "@toddle-design/web";
import { NoFoldersIllustration } from "@toddle-design/theme";

const FooterComponent = React.memo(props => {
  const { onDoneClick, t } = props;
  return (
    <div className={classes.footerButtonContainer}>
      <div />
      <Button size={"medium"} onClick={onDoneClick}>
        {t("common:done")}
      </Button>
    </div>
  );
});

const FoldersList = React.memo(props => {
  const { courseFolders, t, selectedFolders, onItemClicked } = props;

  const onFolderClicked = folder => {
    let result = _.cloneDeep(selectedFolders);

    if (!!_.find(selectedFolders, { id: folder.id })) {
      _.remove(result, item => item.id == folder.id);
    } else {
      result.push(folder);
    }

    onItemClicked(result);
  };

  let folders = selectedFolders;

  return (
    <div className={classes.listContainer}>
      {!_.isEmpty(courseFolders) ? (
        _.map(courseFolders, folder => {
          const isChecked = !!_.find(folders, { id: folder.id });
          return (
            <div
              key={folder.id}
              onClick={() => onFolderClicked(folder)}
              className={classes.folderItem}
            >
              <div className={classes.folderContent}>
                <div className={classes.folderIcon}>
                  <FolderFilled
                    style={{ color: folder.color }}
                    size="x-small"
                  />
                </div>
                <LinkWithTooltip
                  tooltip={folder.name}
                  href="#"
                  placement="top"
                  overlayStyle={{ maxWidth: 300 }}
                >
                  <div className={classes.folderName}>{folder.name}</div>
                </LinkWithTooltip>
              </div>
              <div className={classes.itemRightContainer}>
                <Checkbox isChecked={isChecked} />
              </div>
            </div>
          );
        })
      ) : (
        <div className={classes.emptyContainer}>
          <EmptyState
            illustration={NoFoldersIllustration}
            title={t("journal:no_folders_tagged")}
            subtitle={t("journal:create_folder_to_tag")}
          />
        </div>
      )}
    </div>
  );
});

class TagFolders extends PureComponent {
  constructor(props) {
    super(props);

    let {
      screenPanelProps: {
        mode,
        postDetails: { folders, addedFolders, removedFolders },
      },
    } = this.props;

    let selectedFolders = [];

    //if new then don't do any add remove
    if (mode === "create") {
      selectedFolders = folders;
    }

    //if update then do add remove on folders and setState selectedFolders
    else {
      const selectedFoldersCopy = _.cloneDeep(folders) || [];
      const addedFoldersCopy = _.cloneDeep(addedFolders) || [];

      selectedFolders = _.uniqWith(
        [...selectedFoldersCopy, ...addedFoldersCopy],
        _.isEqual
      );

      _.remove(selectedFolders, folder => {
        return !!_.find(removedFolders, { id: folder.id });
      });
    }

    this.state = {
      selectedFolders,
    };
  }

  onDoneClick = () => {
    const {
      screenPanelProps: { onCloseClick, updateFolderArray } = {},
    } = this.props;

    const { selectedFolders, addedFolders, removedFolders } = this.state;

    updateFolderArray(selectedFolders);

    onCloseClick();
  };

  onItemClicked = data => {
    this.setState({ selectedFolders: data });
  };

  render() {
    const {
      screenPanelProps,
      screenPanelProps: {
        mode,
        postDetails: { folders },
      },
      courseFolders,
      t,
    } = this.props;

    const { selectedFolders, addedFolders, removedFolders } = this.state;

    return (
      <ScreenPanel
        {...screenPanelProps}
        footerComponent={
          !_.isEmpty(courseFolders) && (
            <FooterComponent
              t={t}
              onDoneClick={this.onDoneClick}
              courseFolders={courseFolders}
            />
          )
        }
      >
        <FoldersList
          t={t}
          postCreationMode={mode}
          courseFolders={courseFolders}
          prevFolders={folders}
          selectedFolders={selectedFolders}
          addedFolders={addedFolders}
          removedFolders={removedFolders}
          onItemClicked={this.onItemClicked}
        />
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const courseIdFromParent = _.get(
    ownProps,
    "screenPanelProps.postDetails.courseId"
  );
  const organizationId = state.login.userInfo.org_id;
  const courseId = courseIdFromParent
    ? courseIdFromParent
    : state.teacher.selected_class.selected_course;

  return {
    courseId,
    organizationId,
  };
};

export default compose(
  I18nHOC({ resource: ["journal", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseFolders, {
    name: "getCourseFolders",
    options: ({ courseId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        courseId: courseId,
      },
    }),
    props({ getCourseFolders, ownProps: { courseId } }) {
      const data = getCourseFoldersFromCache(courseId);

      return {
        isData: !_.isEmpty(data),
        courseFolders: _.get(data, "folders", []),
        networkStatus: getCourseFolders.networkStatus,
        isLoading:
          getCourseFolders["networkStatus"] == 1 ||
          getCourseFolders["networkStatus"] == 2,
      };
    },
  })
)(TagFolders);
