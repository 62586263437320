import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
export default store => ({
  path: ":student_id",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("ProgressReport/routes/ProgressReportDetails/routes/StudentProgressReport/components")
              .default;
            const progressReportReducer = require("ProgressReport/modules/ProgressReportModule")
              .default;
            injectReducer(store, {
              key: "progressReport",
              reducer: progressReportReducer,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "transcript-chunk"
        );
      })
  ),
  childRoutes: [],
});
