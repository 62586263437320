import { useState } from "react";

const useHandleRightPane = ({
  fieldId = "",
  currentRightPane = "",
  showRightPane = false,
  rightPaneAnimationTime = 300,
}) => {
  const [state, setState] = useState({
    fieldId,
    currentRightPane,
    showRightPane,
  });

  const updateState = params => {
    setState(prevState => {
      return { ...prevState, ...params };
    });
  };

  const handleRightPane = ({ rightPane, id }) => {
    const { currentRightPane, fieldId } = state;
    if (fieldId == id && rightPane == currentRightPane) {
      setTimeout(() => {
        updateState({
          currentRightPane: "",
          fieldId: "",
          showRightPane: false,
        });
      }, rightPaneAnimationTime);
      return;
    }
    if (currentRightPane) {
      updateState({
        showRightPane: false,
      });
      setTimeout(() => {
        updateState({
          currentRightPane: rightPane,
          fieldId: id,
          showRightPane: rightPane ? true : false,
        });
      }, rightPaneAnimationTime + 30);
    } else {
      if (!rightPane) {
        setTimeout(() => {
          updateState({
            currentRightPane: "",
            fieldId: "",
            showRightPane: false,
          });
        }, rightPaneAnimationTime);
      } else {
        updateState({
          currentRightPane: rightPane,
          fieldId: id,
          showRightPane: true,
        });
      }
    }
  };

  const onCloseRightPane = () => {
    handleRightPane({ rightPane: "" });
  };

  return {
    onCloseRightPane,
    handleRightPane,
    updateState,
    currentRightPane: state.currentRightPane,
    fieldId: state.fieldId,
    showRightPane: state.showRightPane,
  };
};

export default useHandleRightPane;
