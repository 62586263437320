import { withAsyncRouteLoading } from "UIComponents";

const StudentCommunityProjectRoute = store => {
  return {
    path: "student-pool",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./StudentCommunityProject").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "student-pool"
          );
        })
    ),
    childRoutes: [],
  };
};
export default StudentCommunityProjectRoute;
