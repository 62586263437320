import React from "react";
import classes from "./GroupedKeyConceptsView.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { I18nHOC } from "UIComponents";
import PropTypes from "prop-types";
import { getJoinedArray } from "Utils";

class GroupedKeyConceptsView extends UIBaseComponent {
  renderView = () => {
    const { subjectGroup, nodes, t } = this.props;

    const subjectGroupId = _.get(subjectGroup, "id", "");
    const subjectGroupName = _.get(subjectGroup, "name", "");
    const conceptsOfSubjectGroup = [];
    const otherConcepts = [];

    _.forEach(nodes, node => {
      const associatedParents = _.map(node.associatedParents, parent => {
        return parent.id;
      });
      if (_.includes(associatedParents, subjectGroupId)) {
        conceptsOfSubjectGroup.push(node.label);
      } else {
        otherConcepts.push(node.label);
      }
    });

    return (
      <div className={classes.container}>
        {!_.isEmpty(conceptsOfSubjectGroup) && (
          <div className={classes.text}>
            <div className={classes.boldText}>
              {`${t("common:key_concepts_with_subject_group_name", {
                subjectGroupName,
              })}:`}
            </div>
            <div>{getJoinedArray(conceptsOfSubjectGroup)}</div>
          </div>
        )}
        {!_.isEmpty(otherConcepts) && (
          <div className={classes.text}>
            <div className={classes.boldText}>
              {`${t("common:other_subject_group_key_concepts_label")}:`}
            </div>
            <div>{getJoinedArray(otherConcepts)}</div>
          </div>
        )}
      </div>
    );
  };
}

GroupedKeyConceptsView.defaultProps = {
  ...UIBaseComponent.defaultProps,
  nodes: [],
  subjectGroup: {},
};

GroupedKeyConceptsView.propTypes = {
  ...UIBaseComponent.propTypes,
  nodes: PropTypes.any,
  subjectGroup: PropTypes.object,
};

export default I18nHOC({ resource: "common" })(GroupedKeyConceptsView);
