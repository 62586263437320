import gql from "graphql-tag";
import {
  resolvedFieldValueFragment,
  taggedPracticesFragment,
} from "modules/CommonFragments";

export const unitCardFragment = {
  unitCard: gql`
    fragment unitCardItem on UnitPlan {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      centralIdea {
        id
        value
      }
      theme {
        id
        value
      }
      unitType {
        id
        value
      }
      duration {
        id
        value
      }
      subjects {
        id
        value
      }
      centralIdea {
        id
        value
      }
      resourceLibrary {
        leCount
        assessmentCount
      }
      unitFlow {
        edges {
          node {
            id
            items {
              edges {
                type
              }
            }
          }
        }
      }
      collaborators: allCollaborators {
        edges {
          permission
          node {
            id
          }
        }
      }
      templateId
    }
  `,
  unitList: gql`
    fragment unitCardItem on UnitPlan {
      id
      title {
        id
        value
      }
      duration {
        id
        value
      }
    }
  `,
  scheduledUnitCard: gql`
    fragment unitCardItem on UnitPlan {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      theme @skip(if: $isMYP) {
        id
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      fields(uids: $uids) @include(if: $isMYP) {
        id
        uid
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      unitType {
        id
        value
      }
      duration {
        id
        value
      }
      subjects {
        id
        value
      }
      centralIdea {
        id
        value
      }
      resourceLibrary {
        leCount
        assessmentCount
      }
      templateId
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
};

export const unitPlanFragment = {
  unitPlanDetail: gql`
    fragment unitPlanItem on UnitPlan {
      id
      state
      templateId
      title {
        id
        value
      }
      image {
        id
        value
      }
      unitType {
        id
        value
      }
      theme {
        id
        value
      }
      subjects {
        id
        value
      }
      fields(uids: $uids) {
        id
        uid
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      curriculumProgram {
        id
        type
      }
      collaborators: allCollaborators {
        isEditPermission
      }
      isPublishedInSampleUnits
      isImported
      academicYears {
        id
        startDate
        endDate
        isCurrentAcademicYear
      }
      taggedPractices {
        ...taggedPracticesOnEvidence
      }
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${taggedPracticesFragment.taggedPractices}
  `,
};

export const weeklyItemFragment = {
  weeklyItem: gql`
    fragment itemNode on WeeklyScheduledItem {
      id
      itemType: type
      item {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
          image {
            id
            value
          }
        }
        ... on LearningEngagement {
          id
          title {
            id
            value
          }
          image {
            id
            value
          }
        }
        ... on Note {
          id
          message
        }
      }
    }
  `,
};

export const yearlyItemFragment = {
  yearlyItem: gql`
    fragment itemNode on YearlySchedule {
      id
      groupId
      startDate
      endDate
      unitPlan @skip(if: $detailedUnitPlan) {
        ...unitCardItem
      }
      unitPlan @include(if: $detailedUnitPlan) {
        ...unitPlanItem
      }
    }
    ${unitCardFragment.scheduledUnitCard}
    ${unitPlanFragment.unitPlanDetail}
  `,
};

export const slotFragment = {
  slot: gql`
    fragment slotItem on WeeklySchedulerTimeSlot {
      id
      date
      slotId
      startTime
      endTime
      period
      colorCode
      scheduledItems {
        ...itemNode
      }
    }
    ${weeklyItemFragment.weeklyItem}
  `,
};

export const courseSchedulerFragment = {
  courseWeeklyScheduler: gql`
    fragment courseItem on Course {
      id
      weeklySchedule(
        filters: { date: { startDate: $startDate, endDate: $endDate } }
      ) {
        ...slotItem
      }
    }
    ${slotFragment.slot}
  `,
};
