import { withAsyncRouteLoading } from "UIComponents";
import SchoolHandover from "./routes/SchoolHandover";
import ContractTraking from "./routes/ContractTracking";
export default store => ({
  path: "internalTools",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./InternalTools").default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "administrator-tooling"
        );
      })
  ),
  childRoutes: [SchoolHandover(store), ContractTraking(store)],
});
