/**--external-- */
import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
/**--internal-- */
import {
  getFilterPropFromStateMemoized,
  getDependentFilters,
  getMoreFiltersSelectionStatus,
} from "../modules/Utils";
import { useI18n } from "Hooks";
import { tabFiltersConfig } from "../modules/Config";

/**--relative-- */
import DynamicFiltersWrapper from "./DynamicFiltersWrapper";
import { CURRICULUM_TYPE_PYP } from "Constants/stringConstants";
import { getFiltersDisplayTextMemoized } from "./DynamicFiltersWrapper/utils";
import classes from "./Filters.scss";

const Filters = props => {
  const {
    initialAppliedFilters,
    updateFilterObject,
    updateFilterStatus,
    activeTab,
    curriculumProgramId,
    curriculumType,
    updateFilterItemsLocaleForActiveTab,
    filterStyle,
    plannerElementSets,
  } = props;

  const { t } = useI18n(["common", "snp"]);

  const [appliedFilters, setAppliedFilters] = useState(initialAppliedFilters);

  const [areMoreFiltersVisible, setAreMoreFiltersVisible] = useState(
    getMoreFiltersSelectionStatus({ appliedFilters: initialAppliedFilters })
  );

  const [tabWiseSearchText, setTabWiseSearchText] = useState({});

  const [filterOptions, setFilterOptions] = useState({});

  const [loadingFilters, setLoadingFilters] = useState([]);

  const [parentFilterObj, setParentFilterObj] = useState({});

  const searchText = _.get(tabWiseSearchText, activeTab, "");

  const activeTabAppliedFilters = _.get(appliedFilters, activeTab, {});

  const activeTabFilterOptions = _.get(filterOptions, `${activeTab}`, {});

  const areFiltersApplicable =
    _.size(
      _.get(
        tabFiltersConfig,
        `${activeTab}.curriculumWiseAvailableFilters.${curriculumType}`
      )
    ) > 0;

  const isSearchApplicable = _.includes(
    _.get(tabFiltersConfig, `${activeTab}.searchAvailableInCurriculums`, []),
    curriculumType
  );

  const areDependentFiltersReady = _.isEmpty(parentFilterObj);

  const areAllFiltersReady =
    !areFiltersApplicable ||
    (_.isEmpty(loadingFilters) &&
      !_.isEmpty(activeTabFilterOptions) &&
      areDependentFiltersReady);

  const showFilters = isSearchApplicable || areFiltersApplicable;

  const updateFilters = ({ appliedFilters, searchText }) => {
    const filterPropFromState = getFilterPropFromStateMemoized({
      appliedFilters,
      activeTab,
      curriculumType,
    });

    const filtersDisplayText = getFiltersDisplayTextMemoized({
      appliedFilters,
      allFiltersOptions: activeTabFilterOptions,
      activeTab,
      t,
      curriculumType,
      plannerElementSets,
    });

    const filterObjWithSearch = { ...filterPropFromState, searchText };

    updateFilterObject({ newFilterObj: filterObjWithSearch });

    updateFilterItemsLocaleForActiveTab({ data: filtersDisplayText });
  };

  useEffect(() => {
    if (!areAllFiltersReady) {
      updateFilterStatus({ loaded: false });
      return;
    }

    updateFilters({ appliedFilters: activeTabAppliedFilters, searchText });

    updateFilterStatus({ loaded: true });
  }, [areAllFiltersReady, activeTab]);

  useEffect(() => {
    if (!areAllFiltersReady) {
      return;
    }

    updateFilters({ appliedFilters: activeTabAppliedFilters, searchText });
  }, [activeTabAppliedFilters, searchText, areAllFiltersReady]);

  useEffect(() => {
    if (!areFiltersApplicable) {
      updateAppliedFilters({ newAppliedFilters: {} });
    }
  }, [areFiltersApplicable, activeTab]);

  const updateTabWiseSearchText = useCallback(
    value => {
      setTabWiseSearchText(previousState => {
        return {
          ...previousState,
          [activeTab]: value,
        };
      });
    },
    [activeTab]
  );

  const updateSearchText = useMemo(
    () =>
      _.debounce(value => {
        updateTabWiseSearchText(value);
      }, 300),

    [updateTabWiseSearchText]
  );

  const updateAppliedFilters = useCallback(
    ({ appliedFilters }) => {
      setAppliedFilters(prev => {
        return {
          ...prev,
          [activeTab]: {
            ...prev[activeTab],
            ...appliedFilters,
          },
        };
      });
    },
    [activeTab]
  );

  const updateLoadingFilters = ({ isReady, filter }) => {
    if (!isReady) {
      updateFilterStatus({ loaded: false });

      setLoadingFilters(loadingFilters => [...loadingFilters, filter]);
    } else {
      setLoadingFilters(loadingFilters =>
        _.filter(loadingFilters, loadingFilter => filter !== loadingFilter)
      );
    }
  };

  const updateFilterOptions = ({
    filterOptions: updatedFilterOptions,
    filter,
  }) => {
    setFilterOptions(filterOptions => {
      return {
        ...filterOptions,
        [activeTab]: {
          ...filterOptions[activeTab],
          [filter]: updatedFilterOptions,
        },
      };
    });
  };

  const updateParentFilter = ({ parent }) => {
    const dependentFilters = getDependentFilters({
      parent,
      activeTab,
      curriculumType,
    });

    setParentFilterObj({ parent, dependentFilters });
  };

  const markDependentFilterAsUpdated = ({ filter }) => {
    setParentFilterObj(parentFilterObj => {
      const { parent, dependentFilters } = parentFilterObj;
      const updatedDependentFilters = _.filter(
        dependentFilters,
        dependentFilter => dependentFilter !== filter
      );
      if (_.isEmpty(updatedDependentFilters)) {
        return {};
      }
      return { parent, dependentFilters: updatedDependentFilters };
    });
  };

  const updateMoreFiltersVisibilityStatus = useCallback(() => {
    setAreMoreFiltersVisible(areMoreFiltersVisible => {
      return {
        ...areMoreFiltersVisible,
        [activeTab]: !areMoreFiltersVisible[activeTab],
      };
    });
  }, [activeTab]);

  return (
    <div className={classes.filter} style={filterStyle}>
      {showFilters && (
        <DynamicFiltersWrapper
          activeTab={activeTab}
          appliedFilters={activeTabAppliedFilters}
          curriculumType={curriculumType}
          searchText={searchText}
          updateSearchText={updateSearchText}
          areMoreFiltersVisible={areMoreFiltersVisible[activeTab]}
          updateMoreFiltersVisibilityStatus={updateMoreFiltersVisibilityStatus}
          updateAppliedFilters={({ appliedFilters }) => {
            updateAppliedFilters({ appliedFilters });
          }}
          updateFilterStatus={updateFilterStatus}
          curriculumProgramId={curriculumProgramId}
          areFiltersApplicable={areFiltersApplicable}
          isSearchApplicable={isSearchApplicable}
          parentFilter={parent}
          updateFilterOptions={updateFilterOptions}
          markDependentFilterAsUpdated={markDependentFilterAsUpdated}
          updateParentFilter={updateParentFilter}
          updateLoadingFilters={updateLoadingFilters}
          parentFilterObj={parentFilterObj}
          plannerElementSets={plannerElementSets}
          updateFilterObject={updateFilterObject}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    {}
  );
  return { plannerElementSets };
};

export default connect(mapStateToProps)(Filters);

Filters.defaultProps = {
  initialAppliedFilters: {},
  updateFilterObject: () => {},
  updateFilterStatus: () => {},
  activeTab: "",
  curriculumProgramId: "",
  curriculumType: CURRICULUM_TYPE_PYP,
};

Filters.propTypes = {
  initialAppliedFilters: PropTypes.object,
  updateFilterObject: PropTypes.func,
  updateFilterStatus: PropTypes.func,
  activeTab: PropTypes.string,
  curriculumProgramId: PropTypes.string,
  curriculumType: PropTypes.string,
};
