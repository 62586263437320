import React, { createRef, useState } from "react";
import classes from "./AddPostDetails.scss";
import ScreenPanel from "../../ScreenPanel";
import { connect } from "react-redux";
import { CreatePost } from "Post";
import {
  UIButton,
  TextAreaInput,
  MediaPlaceholder,
  PYPElements,
  withLoader,
  MediaModal,
  I18nHOC,
  Note,
  AudioRecorderSelector,
  FullScreenLoader,
} from "UIComponents";
import { DisabledStateTooltip } from "AppComponents";
import { AttachmentList } from "Attachment/";

import { EditInWorkbookSvg } from "SvgComponents";
import classnames from "classnames";
import { colors } from "Constants";
import {
  TaggedStudentDetails,
  TaggedPractices,
  TaggedPracticesV2,
  UnitDetails,
  TaggedFolders,
} from "Post/components";
import { getTaggableElements, getFieldList } from "Post/modules/PostModule";
import { getSharedWithString, isJournalCardRevamp } from "Post/utils";
import { getSettingValue } from "modules/PermissionModule";
import { getElementData } from "modules/Services";

import { getAttachmentsByTypeFromAttachmentArr } from "Utils";
import { compose, graphql } from "react-apollo";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import {
  StudentAddOutlined,
  GoalsOutlined,
  FolderOutlined,
  ScopeAndSequenceOutlined,
  AddCircleOutlined,
  PencilLineOutlined,
  TeacherShareOutlined,
  ChevronRightOutlined,
  MicrophoneOutlined,
} from "@toddle-design/web-icons";

import Accordian from "UIComponents/Accordian";
import StudentResponse, {
  StudentResponseProfile,
} from "../StudentResponse/StudentResponse";
import { getStudentAssignmentDetailsQuery } from "ClassRoom/modules/ClassRoomQuery";
import { getStudentAssignmentDetailsFromCache } from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import { Tooltip } from "AppComponents/ArchivedComponents";
import { Trans } from "react-i18next";
import { Alert, IconButton, Button } from "@toddle-design/web";

const styles = {
  tagTypePreviousYearStyle: {
    cursor: "not-allowed",
  },
  customProfileCadeStyle: {
    width: "24px",
    height: "24px",
    fontSize: ".7rem",
  },
  customStudentNameStyle: {
    fontSize: "1.4rem",
  },
  studentResponseStyle: {
    marginTop: "0px",
  },
  noteTextAreaStyles: {
    borderWidth: 0,
    padding: 0,
    overflowY: "hidden",
  },
};

const FooterComponent = React.memo(props => {
  const {
    onPublishClick,
    onDraftClick,
    state,
    mode,
    t,
    isPost,
    addButtonLabel,
    userType,
    onMediaCreate,
    onSendForApprovalClick,
    isApprovalRequiredForStudentPosts,
    isAddAllResponsesToJournal,
    onAddAllResponsesToJournal,
  } = props;

  //Handle Different footer for post and other media creation based on isPost prop
  //AddButtonLabel only applicable non post media creation
  return (
    <div className={classes.footerButtonContainer}>
      {isPost ? (
        <React.Fragment>
          {/* Show draft button only to teachers */}
          {state == "DRAFT" &&
            userType == "staff" &&
            !isAddAllResponsesToJournal && (
              <UIButton
                color={"blue"}
                type={"filled"}
                size={"lg"}
                onClick={onDraftClick}
                containerStyle={{ width: "100%" }}
              >
                {t("common:save_as_draft")}
              </UIButton>
            )}
          {/* Show send for approval button to students and publish/update post button only to teachers */}
          {userType == "student" && isApprovalRequiredForStudentPosts ? (
            <Button size={"large"} isFullWidth onClick={onSendForApprovalClick}>
              {t("journal:send_for_approval")}
            </Button>
          ) : (
            <UIButton
              color={"pink"}
              type={"filled"}
              size={"lg"}
              onClick={
                isAddAllResponsesToJournal
                  ? onAddAllResponsesToJournal
                  : onPublishClick
              }
              containerStyle={{
                width: "100%",
              }}
            >
              {state == "DRAFT" || mode == "create"
                ? t("journal:publish")
                : t("journal:update_post")}
            </UIButton>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <UIButton
            color={"pink"}
            type={"filled"}
            size={"lg"}
            containerStyle={{ width: "100%" }}
            onClick={onMediaCreate}
          >
            {t(addButtonLabel)}
          </UIButton>
        </React.Fragment>
      )}
    </div>
  );
});
FooterComponent.displayName = "Footer";

const TagTypeHelper = React.memo(props => {
  const {
    title,
    dataLength,
    onAddOrEditClick,
    tagTypeStyle,
    icon,
    expandedContent,
    count,
    showTaggedStudents,
    showTaggedItems,
    customTagTypeStyle,
    disabled,
  } = props;

  const tagTypeTextClasses = classnames(classes.tagTypeText, {
    disabled,
  });

  return (
    <div
      className={classes.tagTypeContainer}
      style={{
        ...customTagTypeStyle,
        ...(tagTypeStyle ? tagTypeStyle : {}),
      }}
      onClick={dataLength === 0 ? onAddOrEditClick : undefined}
    >
      <div className={classes.tagtypeLeftContainer}>
        <div className={classes.iconContainer}>{icon}</div>
        <div className={tagTypeTextClasses}>{title}</div>
      </div>
      <div className={classes.tagTypeRightContainer}>
        {showTaggedItems && (
          <div>
            {showTaggedStudents ? (
              <div className={classes.expandedContentContainer}>
                {expandedContent}
              </div>
            ) : (
              count && <div className={classes.countContainer}>{count}</div>
            )}
          </div>
        )}
        <div
          className={classes.tagIconContainer}
          style={tagTypeStyle ? tagTypeStyle : {}}
          onClick={onAddOrEditClick}
        >
          {dataLength == 0 ? (
            <IconButton
              icon={<AddCircleOutlined size={"xx-small"} />}
              variant={"plain"}
              size={"medium"}
            ></IconButton>
          ) : (
            <IconButton
              icon={<PencilLineOutlined size={"xx-small"} />}
              variant={"plain"}
              size={"medium"}
            ></IconButton>
          )}
        </div>
      </div>
    </div>
  );
});

TagTypeHelper.displayName = "TagTypeHelper";

const TagType = React.memo(props => {
  const { dataLength, expandedContent, customExpandedContentStyle } = props;
  const [showTaggedItems, setShowTaggedItems] = useState(true);
  return (
    <React.Fragment>
      {dataLength == 0 ? (
        <TagTypeHelper
          {...props}
          customTagTypeStyle={{ padding: "0 24px" }}
          showTaggedItems={showTaggedItems}
        />
      ) : (
        <Accordian
          hideContainerBorder={true}
          showLabelBorder={false}
          customTitleComponent={
            <TagTypeHelper
              {...props}
              customTagTypeStyle={{ paddingRight: "24px" }}
              showTaggedItems={showTaggedItems}
            />
          }
          expandedContent={
            <div
              className={classes.tagDetailContainer}
              style={customExpandedContentStyle}
            >
              {expandedContent}
            </div>
          }
          showLeftArrow={true}
          mainContentContainerStyle={{ marginBottom: "0" }}
          headerStyle={{ padding: "0" }}
          getIsExpanded={value => setShowTaggedItems(value)}
        />
      )}
    </React.Fragment>
  );
});

TagType.displayName = "TagType";

const StudentTag = React.memo(props => {
  const {
    onAddOrEditClick,
    students,
    t,
    isCurrentAcademicYearSelected,
    isArchivedClass,
  } = props;
  const studentsLength = _.get(students, "length", 0);

  const tagOptionClasses = classnames(classes.tagOption, {
    disabled: isArchivedClass,
  });
  return (
    <div className={tagOptionClasses}>
      <DisabledStateTooltip
        isCurrentAcademicYear={isCurrentAcademicYearSelected}
        placement={"left"}
      >
        <TagType
          title={t("journal:tag_students")}
          dataLength={studentsLength}
          disabled={isArchivedClass || !isCurrentAcademicYearSelected}
          onAddOrEditClick={
            isCurrentAcademicYearSelected && !isArchivedClass
              ? onAddOrEditClick
              : null
          }
          tagTypeStyle={
            !isCurrentAcademicYearSelected
              ? styles.tagTypePreviousYearStyle
              : null
          }
          icon={<StudentAddOutlined size={"xx-small"} />}
          expandedContent={
            studentsLength > 0 && (
              <TaggedStudentDetails
                taggedStudents={students}
                customProfileCadeStyle={styles.customProfileCadeStyle}
                customStudentNameStyle={styles.customStudentNameStyle}
              />
            )
          }
          showTaggedStudents={studentsLength > 0}
        />
      </DisabledStateTooltip>
    </div>
  );
});
StudentTag.displayName = "StudentTag";

const PYPElementDetails = React.memo(props => {
  const {
    unitPlan,
    resourceItem,
    elementData,
    resourceType,
    selectFromAllPYP,
    t,
    taggableElements,
  } = props;

  const pypElements = (
    <div className={classes.detailContainer}>
      {!selectFromAllPYP && (
        <div className={classes.detailsHeaderText}>
          {t("journal:edit_post_pyp_elements")}
        </div>
      )}
      <PYPElements
        elementData={elementData}
        pypTemplateElements={taggableElements}
      />
    </div>
  );

  if (!!unitPlan || !!resourceItem)
    return (
      <React.Fragment>
        <UnitDetails
          unitPlan={unitPlan}
          resourceItem={resourceItem}
          resourceType={resourceType}
        />
        {pypElements}
      </React.Fragment>
    );

  if (!_.isEmpty(elementData)) return pypElements;

  return null;
});

PYPElementDetails.displayName = "PYPElementDetails";

const PYPElementDetailsWithLoader = compose(withLoader)(PYPElementDetails);
class PYPElementTag extends React.PureComponent {
  render() {
    const {
      onAddOrEditClick,
      elementPYP,
      t,
      unitPlan,
      resourceItem,
      elementData,
      resourceType,
      isData,
      isLoading,
      selectFromAllPYP,
      taggableElements,
      learningGoalsCount,
    } = this.props;
    const elementsLength = _.get(elementPYP, "length", 0);
    return (
      <div className={classes.pypTagOptions}>
        <TagType
          title={t("journal:tag_pyp_elements")}
          dataLength={elementsLength}
          onAddOrEditClick={onAddOrEditClick}
          icon={<GoalsOutlined size={"xx-small"} />}
          expandedContent={
            <PYPElementDetailsWithLoader
              t={t}
              unitPlan={unitPlan}
              resourceItem={resourceItem}
              elementData={elementData}
              resourceType={resourceType}
              isData={isData}
              isLoading={isLoading}
              selectFromAllPYP={selectFromAllPYP}
              taggableElements={taggableElements}
            />
          }
          count={
            learningGoalsCount > 0 &&
            t("journal:count_le_goal", {
              count: learningGoalsCount,
            })
          }
          customExpandedContentStyle={!unitPlan ? { marginTop: "0px" } : {}}
        />
      </div>
    );
  }
}

const TagStudents = React.memo(props => {
  const {
    postDetails: {
      students: { edges: students = [] } = {},
      isAddAllResponsesToJournal,
      isPrivate = true,
      isHiddenFromStudents = true,
    } = {},
    t,
    goToStep,
    toggleAlert,
  } = props;

  const taggedStudentsCount = _.get(students, "length", 0);
  const isSharedWithEnabled =
    taggedStudentsCount > 0 || isAddAllResponsesToJournal;

  const onShareWithClick = () => {
    if (isSharedWithEnabled) {
      goToStep("SHARE_WITH");
    } else {
      toggleAlert();
    }
  };

  return (
    <div className={classes.shareWithOption} onClick={onShareWithClick}>
      <div
        className={classes.innerContainer}
        style={{ opacity: isSharedWithEnabled ? 1 : 0.5 }}
      >
        <TeacherShareOutlined size={"xx-small"} />
        <div
          style={{
            justifyContent: "space-between",
            cursor: isSharedWithEnabled ? "pointer" : "not-allowed",
          }}
          className={classes.tagTypeContainer}
        >
          <div className={classes.tagTypeText}>{t("journal:shared_with")}</div>
          <div className={classes.tagSubTextContainer}>
            <div className={classes.tagSubText}>
              {getSharedWithString({
                isPrivate,
                isHiddenFromStudents,
                withPrefix: false,
                t,
              })}
            </div>
            <div className={classes.privateCheckBoxContainer}>
              <IconButton
                icon={<ChevronRightOutlined size={"xx-small"} />}
                variant={"plain"}
                size={"medium"}
                disabled={!isSharedWithEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

TagStudents.displayName = "TagStudents";

const FolderTags = React.memo(props => {
  const { folders, addedFolders, removedFolders, onAddOrEditClick, t } = props;

  const generateFolders = () => {
    let selectedFolders = [];

    //if new then don't do any add remove

    //if update then do add remove on folders and setState selectedFolders

    const selectedFoldersCopy = _.cloneDeep(folders) || [];
    const addedFoldersCopy = _.cloneDeep(addedFolders) || [];

    selectedFolders = _.uniqWith(
      [...selectedFoldersCopy, ...addedFoldersCopy],
      _.isEqual
    );

    _.remove(selectedFolders, folder => {
      return !!_.find(removedFolders, { id: folder.id });
    });
    return selectedFolders;
  };

  const selectedFolders = generateFolders();

  return (
    <div className={classes.tagOption}>
      <TagType
        title={t("journal:tag_folders")}
        dataLength={_.get(selectedFolders, "length", 0)}
        onAddOrEditClick={onAddOrEditClick}
        icon={<FolderOutlined size={"xx-small"} />}
        expandedContent={
          !_.isEmpty(selectedFolders) && (
            <TaggedFolders folders={selectedFolders}></TaggedFolders>
          )
        }
        count={
          _.size(selectedFolders) > 0 &&
          t("common:folder_count", { count: _.size(selectedFolders) })
        }
      />
    </div>
  );
});

FolderTags.displayName = "FolderTags";

const PracticesTags = React.memo(props => {
  const { practices, onAddOrEditClick, t } = props;

  return (
    <div className={classes.tagOption}>
      <TagType
        title={t("journal:tag_practices")}
        dataLength={_.get(practices, "length", 0)}
        onAddOrEditClick={onAddOrEditClick}
        icon={<ScopeAndSequenceOutlined size={"xx-small"} />}
        expandedContent={
          !_.isEmpty(practices) && (
            <TaggedPractices practices={practices}></TaggedPractices>
          )
        }
      />
    </div>
  );
});

PracticesTags.displayName = "PracticesTags";

const PracticesTagsV2 = React.memo(props => {
  const { taggedPractices, onAddOrEditClick, postTitle, t } = props;
  return (
    <div className={classes.tagOption}>
      <TagType
        title={t("common:tag_snp")}
        dataLength={_.size(taggedPractices)}
        onAddOrEditClick={onAddOrEditClick}
        icon={<ScopeAndSequenceOutlined size={"xx-small"} />}
        expandedContent={
          !_.isEmpty(taggedPractices) && (
            <TaggedPracticesV2
              taggedPractices={taggedPractices}
              postTitle={postTitle}
            />
          )
        }
        count={
          !_.isEmpty(taggedPractices) &&
          t("journal:count_practice", {
            count: _.get(taggedPractices[0], "taggedPractice.length"),
          })
        }
      />
    </div>
  );
});

PracticesTagsV2.displayName = "PracticeTagsV2";

class AddPostDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMediaModal: false,
      showTagModal: false,
      tagModalType: null,
      showFullScreenLoader: false,
      textAreaTitle: null,
      noteTitle: null,
      showAlert: false,
    };
    const { screenPanelProps: { postDetails } = {} } = this.props;
    // this audioContainerRef is used for styling of audioRecorderSelector
    this.audioContainerRef = createRef(null);
    const { attachments } = postDetails;
    const type = _.get(_.first(attachments), "type", "");
    this.isOnlyAudio = type === "AUDIO" && attachments.length === 1;

    this.updateTextAreaInputDebounce = _.debounce(
      this.updateTextAreaInputReduxState,
      1000
    );
    this.updateNoteTextDebounce = _.debounce(this.updateNote, 1000);
    this.textAreaInputRef = null;
  }

  componentDidMount() {
    const textAreaTitle = _.get(this, "props.textAreaTitle", "");
    const attachments = _.get(
      this,
      "props.screenPanelProps.postDetails.attachments",
      []
    );
    const noteTitle = _.get(attachments[0], "title", "");
    this.setState({
      textAreaTitle: textAreaTitle,
      noteTitle: noteTitle,
    });
  }

  componentWillUnmount() {
    this.updateTextAreaInputDebounce.cancel();
    this.updateNoteTextDebounce.cancel();
  }

  toggleMediaModal = value => {
    this.setState({
      showMediaModal: value,
    });
  };

  updateAudioAttachments = params => {
    const { screenPanelProps: { updatePost, postDetails } = {} } = this.props;
    const attachments = _.get(postDetails, "attachments", []);
    const newAudioAttachment = { ...params };
    updatePost({
      attachments: [
        ...attachments,
        _.omit(newAudioAttachment, [
          "__typename",
          "id",
          "name",
          "thumbUrl",
          "title",
        ]),
      ],
    });
  };

  updateTextAreaInputLocally = async ({ title = "" }) => {
    this.setState({
      textAreaTitle: title,
    });
    this.updateTextAreaInputDebounce();
  };

  updateTextAreaInputReduxState = async () => {
    const { updatePost } = this.props;
    updatePost({ title: this.state.textAreaTitle });
  };

  updateNoteTextLocally = params => {
    this.setState({
      noteTitle: _.get(params, "title", ""),
    });
    this.updateNoteTextDebounce(params);
  };

  updateNote = params => {
    const { screenPanelProps: { updatePost, postDetails } = {} } = this.props;
    const attachment = _.get(postDetails, "attachments[0]", {});

    updatePost({ attachments: [{ ...attachment, ...params }] });
  };

  toggleIsPrivate = () => {
    const {
      screenPanelProps: { updatePost, postDetails: { isPrivate = false } } = {},
    } = this.props;
    updatePost({ isPrivate: !isPrivate });
  };

  goToStep = step => {
    this.setState({ showTagModal: true, tagModalType: step });
  };

  onCloseTagModal = () => {
    this.setState({ showTagModal: false, tagModalType: null });
  };

  onDeleteAudioAttachment = () => {
    const { screenPanelProps: { updatePost, postDetails } = {} } = this.props;
    const attachments = _.get(postDetails, "attachments", []);
    const filteredAttachments = _.filter(
      attachments,
      attachment => attachment.type !== "AUDIO"
    );
    updatePost({ attachments: [...filteredAttachments] });
  };

  syncLocalStateWithRedux = (title, reduxNoteTitle) => {
    const { textAreaTitle, noteTitle } = this.state;
    const { updatePost } = this.props;
    if (!_.isNull(textAreaTitle) && !_.isEqual(title, textAreaTitle)) {
      if (this.updateTextAreaInputDebounce.cancel) {
        this.updateTextAreaInputDebounce.cancel();
      }
      updatePost({ title: textAreaTitle });
    }

    if (!_.isNull(noteTitle) && !_.isEqual(noteTitle, reduxNoteTitle)) {
      if (this.updateNoteTextDebounce.cancel) {
        this.updateNoteTextDebounce.cancel();
      }
      this.updateNote({ title: noteTitle });
    }
  };

  getStudents = students => {
    return _.map(students, student => {
      return {
        name: `${_.get(student, "firstName")} ${_.get(student, "lastName")}`,
      };
    });
  };

  getPlaceholder = () => {
    const { t, screenPanelProps: { postDetails } = {} } = this.props;
    const { isAddAllResponsesToJournal, customPlaceHolder } = postDetails;
    if (customPlaceHolder) {
      return t(customPlaceHolder);
    } else if (isAddAllResponsesToJournal) {
      return t("journal:write_common_caption_anectode_for_all_responses");
    } else {
      return t("journal:edit_post_placeholder_textarea");
    }
  };

  render() {
    const {
      screenPanelProps = {},

      elementData,
      selectFromAllPYP,
      isLoading,
      isData,
      showTagSnP,
      showTagSnpV2,
      t,
      userType,
      showTagPYPElements,
      showTagStudents,
      showTagFolder,
      isApprovalRequiredForStudentPosts,
      taggableElements,
      isCurrentAcademicYearSelected,
      isArchivedClass,
      toolResponses,
      ibPYPElementRatings,
      isUploading,
    } = this.props;

    const {
      postDetails: {
        title,
        students: { edges: students = [] } = {},
        attachments = [],
        elementPYP = [],
        unitPlan,
        resourceItem,
        resourceType,
        state,
        addedFolders,
        removedFolders,
        practices,
        taggedPractices,
        folders,
        isAddLEToJournal,
        isAddAllResponsesToJournal,
        items,
        studentsWithResponsePendingToPublish,
      } = {},
      onPublishClick,
      onDraftClick,
      onMediaCreate,
      onSendForApprovalClick,
      onAddAllResponsesToJournal,
      mode,
      mediaCreationOptions,
      setWorkbookMode,
      totalNewSnpCycles,
    } = screenPanelProps;

    const responseData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );
    const item = _.get(responseData, "item", {});
    const unitPlanFields = _.get(unitPlan, "allFields", []);
    const itemToolResponses = _.get(item, "toolResponses", {});
    const itemIbPYPElementRatings = _.get(item, "ibPYPElementRatings", []);

    const isResponseEmpty = _.isEmpty(_.get(item, "submission.response"));
    const isAttachmentGroupEmpty = _.isEmpty(
      _.get(item, "submission.attachmentGroups")
    );

    //To handle non post media creation
    const { enableTagging, enableCaption } = mediaCreationOptions;

    const attachmentType = _.get(attachments[0], "type", "");
    const reduxNoteTitle = _.get(attachments[0], "title", "");
    const showCaptionTextArea =
      (attachmentType != "NOTE" && enableCaption) || isAddLEToJournal;
    const showMediaPlaceholder = attachmentType != "NOTE";
    const showAudioRecorder =
      ["IMAGE", "LINK", "FILE", "WORKBOOK"].includes(attachmentType) ||
      isAddLEToJournal;
    const showEditWorkbookIcon = !!_.get(
      attachments[0],
      "metadata.workbookUrl",
      ""
    );
    const pendingStudentsCount = _.size(studentsWithResponsePendingToPublish);

    const tooltipComponentClass = classnames({
      [classes.hoverState]: pendingStudentsCount > 2,
      [classes.nonHoverState]: pendingStudentsCount === 2,
    });

    const basicDetailContainerClass = classnames({
      [classes.basicDetailContainer]: true,
      [classes.basicDetailWithStudentResponse]:
        isAddLEToJournal || isAddAllResponsesToJournal,
    });

    const comp = (
      <div className={tooltipComponentClass}>
        {pendingStudentsCount === 2
          ? `${_.get(
              this.getStudents(studentsWithResponsePendingToPublish)[0],
              "name"
            )} and ${_.get(
              this.getStudents(studentsWithResponsePendingToPublish)[1],
              "name"
            )}`
          : `${_.get(
              this.getStudents(studentsWithResponsePendingToPublish)[0],
              "name"
            )} and ${pendingStudentsCount - 1} ${t("snp:others")}`}
      </div>
    );

    const toggleAlert = () => {
      this.setState({ showAlert: !this.state.showAlert });
    };

    const {
      showMediaModal,
      showTagModal,
      tagModalType,
      showFullScreenLoader,
      textAreaTitle,
      noteTitle,
      showAlert,
    } = this.state;

    return (
      <React.Fragment>
        {showFullScreenLoader && <FullScreenLoader />}
        <ScreenPanel
          {...screenPanelProps}
          footerComponent={
            <FooterComponent
              t={t}
              {...mediaCreationOptions}
              onMediaCreate={onMediaCreate}
              onPublishClick={onPublishClick}
              onDraftClick={onDraftClick}
              isAddAllResponsesToJournal={isAddAllResponsesToJournal}
              onAddAllResponsesToJournal={onAddAllResponsesToJournal}
              state={state}
              mode={mode}
              userType={userType}
              onSendForApprovalClick={onSendForApprovalClick}
              isApprovalRequiredForStudentPosts={
                isApprovalRequiredForStudentPosts
              }
            />
          }
        >
          <div className={classes.container}>
            {showAlert && (
              <div className={classes.alertContainer}>
                <Alert
                  type={"warning"}
                  isClosable={true}
                  title={t("journal:shared_with_alert_msg")}
                  onClose={toggleAlert}
                />
              </div>
            )}
            <div className={basicDetailContainerClass}>
              {isAddAllResponsesToJournal && (
                <div className={classes.addAllResponsesToJournalContainer}>
                  <Trans
                    i18nKey={
                      "journal:responses_will_be_added_with_following_details"
                    }
                  >
                    Responses of
                    <Tooltip
                      data={this.getStudents(
                        studentsWithResponsePendingToPublish
                      )}
                      length={1}
                      tooltipPlacement="bottom"
                      moreJoinSymbol={`and `}
                      keyToMap="name"
                      label={t("common:students")}
                      trigger={pendingStudentsCount > 2 ? ["hover"] : [null]}
                      renderComponent={comp}
                    />
                    will be added to journal with following details:
                  </Trans>
                </div>
              )}
              {showCaptionTextArea && (
                <div className={classes.captionContainer}>
                  <TextAreaInput
                    name={"title"}
                    value={textAreaTitle}
                    minRows={5}
                    placeholder={this.getPlaceholder()}
                    updateInputField={this.updateTextAreaInputLocally}
                    onBlur={() =>
                      this.syncLocalStateWithRedux(title, reduxNoteTitle)
                    }
                    ref={ref => (this.textAreaInputRef = ref)}
                    textAreaStyles={{
                      border: "none",
                    }}
                  />
                  {showAudioRecorder &&
                  getAttachmentsByTypeFromAttachmentArr({
                    attachmentsArr: attachments,
                    type: "AUDIO",
                  }).length == 0 ? (
                    <div
                      ref={this.audioContainerRef}
                      style={{ width: "100%", height: "58px" }}
                    >
                      <AudioRecorderSelector
                        showValue={false}
                        updateInputField={this.updateAudioAttachments}
                        buttonComponent={
                          <div>
                            <MicrophoneOutlined
                              variant={"link"}
                              size={"x-small"}
                              disabled={isUploading}
                            />
                          </div>
                        }
                        buttonContainerStyle={{
                          display: "flex",
                          flexFlow: "row-reverse",
                          paddingRight: "16px",
                          height: "58px",
                          alignItems: "center",
                        }}
                        version={"v2"}
                        container={this.audioContainerRef}
                        disabled={isUploading}
                      />
                    </div>
                  ) : null}
                  {/* 
                    if this.isOnlyAudio is true, then display it in MediaPlaceholder
                    else display it in attachmentList inside text input box
                  */}
                  {this.isOnlyAudio ? null : (
                    <AttachmentList
                      value={getAttachmentsByTypeFromAttachmentArr({
                        attachmentsArr: attachments,
                        type: "AUDIO",
                      })}
                      name={"attachments"}
                      cardType={"horizontal"}
                      showCreateDropdown={false}
                      parentId={""}
                      parentType={""}
                      showSingleCardTheme={true}
                      attachmentContainerStyle={{
                        gridTemplateColumns: "unset",
                        marginBottom: "0px",
                      }}
                      onDeleteAudioAttachment={this.onDeleteAudioAttachment}
                    />
                  )}
                </div>
              )}
              {attachmentType == "NOTE" && !isAddLEToJournal && (
                <Note
                  value={noteTitle}
                  updateNote={this.updateNoteTextLocally}
                  placeholder={t("common:text_placeholder")}
                  noteName={"title"}
                  noteTextAreaStyles={styles.noteTextAreaStyles}
                  onBlur={() =>
                    this.syncLocalStateWithRedux(title, reduxNoteTitle)
                  }
                />
              )}
              {!!showMediaPlaceholder &&
                !isAddLEToJournal &&
                !isAddAllResponsesToJournal && (
                  <div className={classes.mediaPlaceHolderContainer}>
                    {showEditWorkbookIcon && (
                      <div
                        className={classes.editInWorkbookIconContainer}
                        onClick={() => this.toggleMediaModal(true)}
                      >
                        <div
                          onClick={() => setWorkbookMode("USE_TEMPLATE")}
                          className={classes.editInWorkbookIconWrapper}
                        >
                          <EditInWorkbookSvg
                            width={24}
                            height={24}
                            fill={colors.white}
                          />
                        </div>
                      </div>
                    )}
                    <MediaPlaceholder
                      attachments={
                        this.isOnlyAudio
                          ? attachments
                          : getAttachmentsByTypeFromAttachmentArr({
                              attachmentsArr: attachments,
                              type: "AUDIO",
                              except: true,
                            })
                      }
                      onClickMedia={() => this.toggleMediaModal(true)}
                      showVideoPlayer={true}
                      imageMaxWidth={584}
                      showMiddleContainerIcons={false}
                      showCarouselV2={isJournalCardRevamp()}
                      showIconCardsWithExtension={isJournalCardRevamp()}
                    />
                  </div>
                )}
            </div>
            {isAddLEToJournal && !isAddAllResponsesToJournal && (
              <div style={{ padding: "32px 0" }}>
                <Accordian
                  hideContainerBorder={true}
                  showLabelBorder={false}
                  customTitleComponent={
                    <StudentResponseProfile
                      student={_.get(item, "student", {})}
                      t={t}
                    />
                  }
                  expandedContent={
                    <div className={classes.studentResponseContainer}>
                      <StudentResponse
                        item={{
                          ...item,
                          unitPlanFields: unitPlanFields,
                          toolResponses: !_.isEmpty(itemToolResponses)
                            ? itemToolResponses
                            : toolResponses,
                          ibPYPElementRatings: !_.isEmpty(
                            itemIbPYPElementRatings
                          )
                            ? itemIbPYPElementRatings
                            : ibPYPElementRatings,
                        }}
                        showStudentProfile={false}
                        responseStyle={{ marginTop: "0" }}
                        evaluationTextStyle={
                          isAttachmentGroupEmpty && isResponseEmpty
                            ? styles.studentResponseStyle
                            : {}
                        }
                        attachmentsContainerStyle={
                          isResponseEmpty ? styles.studentResponseStyle : {}
                        }
                      />
                    </div>
                  }
                  showLeftArrow={true}
                  mainContentContainerStyle={{ marginBottom: "0" }}
                  headerStyle={{ padding: "0" }}
                />
              </div>
            )}
            {enableTagging && (
              <div className={classes.tagOptionContainer}>
                {showTagStudents && !isAddAllResponsesToJournal && (
                  <StudentTag
                    t={t}
                    onAddOrEditClick={() => this.goToStep("TAG_STUDENT")}
                    students={students}
                    isArchivedClass={isArchivedClass}
                    isCurrentAcademicYearSelected={
                      isCurrentAcademicYearSelected
                    }
                  />
                )}
                {showTagPYPElements && (
                  <div className={classes.pypElementDetail}>
                    <PYPElementTag
                      t={t}
                      onAddOrEditClick={() => this.goToStep("TAG_PYP_ELEMENT")}
                      elementPYP={elementPYP}
                      unitPlan={unitPlan}
                      resourceItem={resourceItem}
                      resourceType={resourceType}
                      elementData={elementData}
                      isData={isData}
                      isLoading={isLoading}
                      showLoaderBar={false}
                      selectFromAllPYP={selectFromAllPYP}
                      taggableElements={taggableElements}
                      learningGoalsCount={_.reduce(
                        elementPYP,
                        (acc, goal) => {
                          return acc + goal.value.length;
                        },
                        0
                      )}
                    />
                  </div>
                )}
                {showTagFolder && (
                  <FolderTags
                    t={t}
                    onAddOrEditClick={() => this.goToStep("TAG_FOLDERS")}
                    folders={folders}
                    addedFolders={addedFolders}
                    removedFolders={removedFolders}
                  />
                )}
                {showTagSnP && (
                  <PracticesTags
                    t={t}
                    practices={practices}
                    onAddOrEditClick={() => this.goToStep("TAG_PRACTICES")}
                  ></PracticesTags>
                )}
                {showTagSnpV2 && totalNewSnpCycles ? (
                  <PracticesTagsV2
                    t={t}
                    postTitle={title}
                    taggedPractices={taggedPractices}
                    onAddOrEditClick={() => this.goToStep("TAG_PRACTICES_V2")}
                  />
                ) : null}
                {/* Show shared with section only to staffs */}
                {userType == "staff" && (
                  <TagStudents
                    {...screenPanelProps}
                    t={t}
                    goToStep={this.goToStep}
                    toggleAlert={toggleAlert}
                  />
                )}
              </div>
            )}
          </div>
          {showMediaModal && (
            <MediaModal
              toggleMediaModal={this.toggleMediaModal}
              attachments={
                attachments.length ===
                getAttachmentsByTypeFromAttachmentArr({
                  attachmentsArr: attachments,
                  type: "AUDIO",
                }).length
                  ? attachments
                  : getAttachmentsByTypeFromAttachmentArr({
                      attachmentsArr: attachments,
                      type: "AUDIO",
                      except: true,
                    })
              }
            />
          )}
        </ScreenPanel>

        {showTagModal && (
          //Open Tagging Screens in the Modal using Create Post itself

          <CreatePost
            currentStep={tagModalType}
            isInnerModal={true}
            onClose={this.onCloseTagModal}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;
  const isUploading = !_.isEmpty(progressOfUploads);
  const postDetails = ownProps.screenPanelProps.postDetails;
  const updatePost = ownProps.screenPanelProps.updatePost;
  const isSnpV2Enabled = ownProps.screenPanelProps.isSnpV2Enabled;
  const { unitPlan = {}, elementPYP = [], studentIds = [] } = postDetails;

  const curriculumPrograms = [
    CURRICULUM_TYPE_MYP,
    CURRICULUM_TYPE_PYP,
    CURRICULUM_TYPE_UBD,
  ];

  const subjects = _.get(unitPlan, "subjects.value", []);
  let grades = _.get(unitPlan, "grades", []);
  const selectedGrades = state.teacher?.selected_class?.selected_grades;

  let selectFromAllPYP = postDetails?.selectFromAllPYP;
  if (!selectFromAllPYP && !_.isEmpty(elementPYP) && _.isEmpty(unitPlan))
    selectFromAllPYP = true;

  if (selectFromAllPYP) grades = selectedGrades;
  const taggedBenchmarksValue = _.get(
    _.find(elementPYP, item => item.fieldUID == "benchmarks"),
    "value",
    []
  );
  const userType = state.login.userInfo.user_type;
  const curriculumProgramType = state.platform.currentCurriculumProgramType;

  //  show tag pyp elements section to user based on setting
  const showTagPYPElements =
    getSettingValue({
      name: "EnablePYPElementsTaggingInPostByStudents",
      courseId: state.teacher.selected_class.selected_course,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    }) || userType != "student";
  const showTagStudents =
    getSettingValue({
      name: "EnableStudentTaggingInPostCreationForStudents",
      courseId: state.teacher.selected_class.selected_course,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    }) || userType !== "student";
  const showTagFolder =
    getSettingValue({
      name: "EnableTaggingFolderInPostByStudents",
      courseId: state.teacher.selected_class.selected_course,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    }) || userType !== "student";
  const isApprovalRequiredForStudentPosts =
    getSettingValue({
      name: "EnablePostApprovalRequiredForNewStudents",
      courseId: state.teacher.selected_class.selected_course,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    }) || userType != "student";
  const showTagSnP =
    curriculumProgramType == "IB_PYP" && !isSnpV2Enabled
      ? getSettingValue({
          name: "EnableSnPPostTagging",
          courseId: state.teacher.selected_class.selected_course,
          organizationId: state.login.userInfo.org_id,
          userId: state.login.userInfo.id,
          userEntityType: state.login.userInfo.userEntityType,
        }) && userType === "staff"
      : false;

  const showTagSnpV2 =
    _.includes(curriculumPrograms, curriculumProgramType) && isSnpV2Enabled;

  const isCurrentAcademicYearSelected = _.get(
    state,
    "platform.academicYearSelected.isCurrentAcademicYear",
    true
  );
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );
  const textAreaTitle = _.get(postDetails, "title", "");
  return {
    userType: userType,
    isData: true,
    isLoading: false,
    unitPlan: unitPlan,
    studentIds,
    grades,
    subjects,
    elementPYP,
    organizationId: state.login.userInfo.org_id,
    taggedBenchmarksValue,
    showTagPYPElements,
    showTagSnP,
    showTagSnpV2,
    elementData: getElementData({
      isPost: true,
      elements: elementPYP,
      field_list: getFieldList({ curriculumProgramType }),
      unitPlanFields: _.get(unitPlan, "fields", {}),
      deriveSubjectIdsFromNodes: true,
    }),
    showLoaderBar: false,
    showTagStudents,
    selectFromAllPYP,
    showTagFolder,
    isApprovalRequiredForStudentPosts,
    taggableElements: getTaggableElements({
      curriculumProgramType,
      unitPlan,
      plannerElementSets,
    }),
    isCurrentAcademicYearSelected,
    textAreaTitle,
    updatePost,
    isArchivedClass: _.get(state, "teacher.selected_class.isArchivedClass"),
    isUploading,
  };
};

export default compose(
  I18nHOC({ resource: ["journal", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getStudentAssignmentDetailsQuery, {
    skip: ({
      screenPanelProps: { isAddLEToJournal, isAddAllResponsesToJournal, mode },
    }) => mode === "edit" || !isAddLEToJournal || isAddAllResponsesToJournal,
    name: "getStudentAssignmentDetails",
    options: ({
      screenPanelProps: {
        postDetails: { studentAssignmentId },
      },
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: studentAssignmentId,
        showOnlySubmitted: false,
        userFilter: "ALL",
      },
    }),
    props({
      ownProps: {
        screenPanelProps: {
          postDetails: { studentAssignmentId },
        },
      },
    }) {
      const studentAssignmentData = getStudentAssignmentDetailsFromCache({
        id: studentAssignmentId,
        showOnlySubmitted: false,
        userFilter: "ALL",
      });

      const toolResponses = _.get(studentAssignmentData, "toolResponses", {});
      const ibPYPElementRatings = _.get(
        studentAssignmentData,
        "ibPYPElementRatings",
        []
      );
      return {
        toolResponses,
        ibPYPElementRatings,
      };
    },
  })
)(AddPostDetails);
