import {
  getMultiLevelNodeDraftQuery,
  getSubjectDetailsQuery,
  getPlannerElementSetQuery,
} from "./NodeEditorQueries";
import client from "apolloClient";

export const getMultiLevelNodeDraftFromCache = ({ draftId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getMultiLevelNodeDraftQuery,
      variables: {
        draftId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSubjectDetailsFromCache = ({ subject }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSubjectDetailsQuery,
      variables: { subject },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPlannerElementSetFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getPlannerElementSetQuery,
      variables: { id },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
