import React, { PureComponent } from "react";
import classes from "./SharedWithList.scss";
import { RadioIcon, ShareWithIconSvg } from "SvgComponents";
import { getSettingValue } from "modules/PermissionModule";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { I18nHOC } from "UIComponents";
import { updatePost } from "Post/modules/PostModule";
import { getCourseFeedDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { colors } from "Constants";
import { RadioButton } from "@toddle-design/web";

const hideHelper = {
  everyone: {
    text: "shared_with_sidebar_hidden_from_parents",
    key: "isPrivate",
    id: "everyone",
    label: "students_n_families",
  },
  parent: {
    id: "parent",
    text: "shared_with_sidebar_hidden_from_parents",
    key: "isPrivate",
    label: "only_families",
  },
  student: {
    id: "student",
    text: "shared_with_sidebar_hidden_from_students",
    key: "isHiddenFromStudents",
    label: "shared_with_sidebar_only_students",
  },
  private: {
    id: "private",
    text: "shared_with_sidebar_hidden_from_everyone",
    key: "isHiddenFromStudents",
    label: "private",
  },
};

class SharedWithList extends PureComponent {
  getDerivedData = type => {
    const { isPrivate, isHiddenFromStudents } = this.props.postDetails;
    const { courseId, organizationId, t, userId, userEntityType } = this.props;

    const isShowStudentsAllPosts = getSettingValue({
      name: "EnableViewEachOtherPostByStudents",
      courseId,
      organizationId,
      userId,
      userEntityType,
    });

    const courseDetails = getCourseFeedDetailsFromCache(courseId);

    const courseTitle = _.get(courseDetails, "title", "");

    let description = "";
    let isChecked = false;

    if (type == "everyone") {
      isChecked = !isPrivate && !isHiddenFromStudents;
      if (isShowStudentsAllPosts) {
        description = t("journal:shared_with_students_class_families", {
          courseTitle,
        });
      } else {
        description = t("shared_with_sidebar_des_students_families");
      }
    } else if (type == "student") {
      isChecked = (isPrivate || isHiddenFromStudents) && !isHiddenFromStudents;
      if (isShowStudentsAllPosts) {
        description = t("shared_with_sidebar_des_students_of_class", {
          courseTitle,
        });
      } else {
        description = t("shared_with_sidebar_des_tagged_students");
      }
    } else if (type == "parent") {
      isChecked = (isPrivate || isHiddenFromStudents) && !isPrivate;
      if (isShowStudentsAllPosts) {
        description = t(
          `shared_with_sidebar_des_visible_only_to_family_students`
        );
      } else {
        description = t(
          "shared_with_sidebar_des_visible_only_to_family_students"
        );
      }
    } else if (type == "private") {
      isChecked = isPrivate && isHiddenFromStudents;
      if (isShowStudentsAllPosts) {
        description = t("shared_with_sidebar_des_visible_only_to_teachers");
      } else {
        description = t("shared_with_sidebar_des_visible_only_to_teachers");
      }
    }

    return { isChecked, description };
  };

  onPressItem = type => {
    let param = {};
    if (type == "everyone") {
      param = { isHiddenFromStudents: false, isPrivate: false };
    } else if (type == "student") {
      param = { isHiddenFromStudents: false, isPrivate: true };
    } else if (type == "parent") {
      param = { isHiddenFromStudents: true, isPrivate: false };
    } else if (type == "private") {
      param = { isHiddenFromStudents: true, isPrivate: true };
    }

    this.props.updatePost(param);
  };

  renderElement = ({ item, t }) => {
    const { isChecked: checked, description } = this.getDerivedData(item.id);
    return (
      <div onClick={() => this.onPressItem(item.id)}>
        <div className={classes.itemContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <div className={classes.iconContainer}>
              <ShareWithIconSvg height={24} width={24} type={item.id} />
            </div>
            <div className={classes.nameContainer}>
              <div className={classes.photoHeading}>{t(item.label)}</div>
              <div className={classes.subText}>{description}</div>
            </div>
          </div>
          <div className={classes.svgContainer}>
            <RadioButton checked={checked} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className={classes.container}>
        {this.renderElement({ item: hideHelper["everyone"], t })}
        {this.renderElement({ item: hideHelper["parent"], t })}
        {this.renderElement({ item: hideHelper["student"], t })}
        {this.renderElement({ item: hideHelper["private"], t })}
      </div>
    );
  }
}

const mapActionCreators = {
  updatePost,
};

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;
  const courseId = state.teacher.selected_class.selected_course;
  const userInfo = state.login.userInfo;
  return {
    courseId,
    organizationId,
    userId: userInfo.id,
    userEntityType: userInfo.userEntityType,
    postDetails: state.post.postDetails,
  };
};

export default compose(
  I18nHOC({ resource: "journal" }),
  connect(mapStateToProps, mapActionCreators)
)(SharedWithList);
