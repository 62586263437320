import PropTypes from "prop-types";
import React from "react";

const QuickTaskFilledIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      fill={props.fill && props.fill}
      viewBox="0 0 32 32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5C18 6.10457 17.1046 7 16 7C14.8954 7 14 6.10457 14 5C14 3.89543 14.8954 3 16 3C17.1046 3 18 3.89543 18 5ZM27 25V11C27 8.8 25.2 7 23 7H19.5C18.9 7.9 18 8.6 17 8.9V10C17 10.5 16.5 11 16 11C15.5 11 15 10.5 15 10V8.9C14 8.6 13.1 7.9 12.5 7H9C6.8 7 5 8.8 5 11V25C5 27.2 6.8 29 9 29H23C25.2 29 27 27.2 27 25ZM11 15C10.4477 15 10 15.4477 10 16C10 16.5523 10.4477 17 11 17H21C21.5523 17 22 16.5523 22 16C22 15.4477 21.5523 15 21 15H11ZM10 21C10 20.4477 10.4477 20 11 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H11C10.4477 22 10 21.5523 10 21Z"
      />
    </svg>
  );
};

QuickTaskFilledIcon.defaultProps = {
  height: 20,
  width: 20,
};

QuickTaskFilledIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default QuickTaskFilledIcon;
