import React, { useState, useCallback, useEffect } from "react";
import classes from "./Sidebar.scss";
import classNames from "classnames";
import { colors } from "Constants";
import { AnimateHeight } from "UIComponents";
import { useI18n } from "Hooks";
import {
  getHTMLLabel,
  shouldNodeRender,
  getFirstChildId,
} from "NodeEditor/Utils";
import { connect } from "react-redux";
import { setSidebarSelectedItem } from "AppComponents/MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule";
import { ListDashedOutlined, CloseOutlined } from "@toddle-design/web-icons";
import { ArrowIcon } from "SvgComponents";

const animateStyle = { width: "100%" };

const Sidebar = props => {
  const {
    nodes,
    rootNodeId,
    placeholdersText,
    maxDepth,
    filters,
    shouldRenderNoDataViewPage,
    gradeOptions,
    setSidebarSelectedItem,
    sidebarSelectedItem,
    nodeRefs,
    filterNodeIds,
    maxSidebarDepth,
    isScrollManual,
  } = props;
  useEffect(() => {
    //on sidebar unmount, reset the sidebar selected item. resetting depth 1 is sufficient
    return () => {
      setSidebarSelectedItem({ 1: "" });
    };
  }, []);

  const { t } = useI18n(["common", "academicSetup"]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const containerStyle = classNames({
    [classes.containerOpen]: isSidebarOpen,
    [classes.containerClose]: !isSidebarOpen,
  });
  const innerContainerStyle = classNames({
    [classes.innerContainerHidden]: !isSidebarOpen,
    [classes.innerContainerShow]: isSidebarOpen,
  });

  const toggleSidebar = () => setIsSidebarOpen(isSidebarOpen => !isSidebarOpen);
  //this function helps in auto scroll of the node
  const memoizedSetSelectedNodeId = useCallback(
    ({ id, shouldRowScroll = false, depth = 1, params }) => {
      if (depth <= maxSidebarDepth) {
        const updatedParams = params
          ? params
          : {
              [depth]: id,
            };
        setSidebarSelectedItem(updatedParams);
      }

      if (shouldRowScroll && nodeRefs.current[id]) {
        isScrollManual.current = false;
        nodeRefs.current[id].scrollIntoView({
          block: "center",
        });
        setTimeout(() => {
          isScrollManual.current = true;
        }, 300);
      }
    },
    [nodeRefs, setSidebarSelectedItem, maxSidebarDepth, isScrollManual]
  );

  return (
    <div
      className={containerStyle}
      id={"COACHMARKS_SCOPE_SEQUENCE_VIEWER_OUTLINE"}
    >
      {isSidebarOpen ? (
        <div className={classes.closeButton} onClick={toggleSidebar}>
          <CloseOutlined size={"xx-small"} />
        </div>
      ) : (
        <div className={classes.openButton} onClick={toggleSidebar}>
          <ListDashedOutlined variant={"on"} size={"xx-small"} />
        </div>
      )}

      <div className={innerContainerStyle}>
        <div className={classes.header}>{t("common:outline")}</div>

        <div className={classes.itemsContainer}>
          {shouldRenderNoDataViewPage ? (
            <NoDataView />
          ) : (
            <RenderSideBarRows
              nodes={nodes}
              rootNodeId={rootNodeId}
              sidebarSelectedItem={sidebarSelectedItem}
              setSidebarSelectedItem={memoizedSetSelectedNodeId}
              placeholdersText={placeholdersText}
              maxDepth={maxDepth}
              filters={filters}
              filterNodeIds={filterNodeIds}
              maxSidebarDepth={maxSidebarDepth}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const NoDataView = () => {
  const { t } = useI18n("academicSetup");

  return (
    <div className={classes.noDataContainer}>
      {t("academicSetup:empty_nodes_message")}
    </div>
  );
};

/**
 * commented code is for Sub children kindly don't remove it
 */
const RenderSideBarRows = ({
  nodes,
  rootNodeId,
  sidebarSelectedItem,
  setSidebarSelectedItem,
  placeholdersText,
  maxDepth,
  filterNodeIds,
  filters,
  maxSidebarDepth,
}) => {
  const rootNodeChildren = _.get(nodes[rootNodeId], "children", []);
  const { t } = useI18n(["common"]);
  const depth2EmptyLabel =
    maxDepth < 1
      ? `${t("common:no")} ${t("common:children")}`
      : t("common:enter_with_label", {
          label: placeholdersText["2"],
        });

  return (
    <>
      {_.map(rootNodeChildren, childId => {
        const { depth, label, id, children } = nodes[childId];

        const itemContainer = classNames({
          [classes.itemContainer]: true,
          [classes.itemContainerSelected]: childId === sidebarSelectedItem[1],
        });

        const labelContainer = classNames({
          [classes.labelDepth1]: true,
          [classes.labelDepth1Selected]: childId === sidebarSelectedItem[1],
        });

        const arrowSvgContainer = classNames({
          [classes.arrowSvg]: true,
          [classes.arrowSvgSelected]: childId === sidebarSelectedItem[1],
        });

        const handleSetSelectedNodeId = () => {
          const firstChildId = getFirstChildId({
            nodes,
            nodeId: childId,
            filterNodeIds,
            upToDepth: maxSidebarDepth,
          });
          const params = firstChildId
            ? { [depth]: childId, [depth + 1]: firstChildId }
            : { [depth]: childId };
          setSidebarSelectedItem({
            shouldRowScroll: true,
            params,
            id: childId,
          });
        };

        if (depth === 1) {
          const shouldRowRender = shouldNodeRender({
            filterNodeIds,
            rootNodeId: id,
          });
          const isLabelFilled = !!label;

          if (!shouldRowRender || !isLabelFilled) return null;

          const newLabel = getHTMLLabel({
            searchText: filters.searchText,
            label,
            style: { color: colors.blue29 },
          });
          return (
            shouldRowRender && (
              <React.Fragment key={childId}>
                <div
                  className={itemContainer}
                  onClick={handleSetSelectedNodeId}
                >
                  <div
                    className={labelContainer}
                    style={!isLabelFilled ? { color: colors.gray31 } : {}}
                    dangerouslySetInnerHTML={{
                      __html: isLabelFilled
                        ? newLabel
                        : t("common:enter_with_label", {
                            label: placeholdersText["1"],
                          }),
                    }}
                  />
                  {maxSidebarDepth === 2 && (
                    <div className={arrowSvgContainer}>
                      <ArrowIcon width={12} height={12} fill={colors.black} />
                    </div>
                  )}
                </div>
                {maxSidebarDepth === 2 && (
                  <AnimateHeight
                    duration={300}
                    height={sidebarSelectedItem[1] === childId ? "auto" : 0}
                    style={animateStyle}
                  >
                    {_.size(children) > 0 ? (
                      _.map(children, childId => {
                        const { depth, label } = nodes[childId];

                        const newLabel = getHTMLLabel({
                          searchText: filters.searchText,
                          label,
                          style: { color: colors.blue29 },
                        });

                        const isLabelFilled = !!label;
                        const handleSetSelectedNodeId = () => {
                          setSidebarSelectedItem({
                            id: childId,
                            shouldRowScroll: true,
                            depth,
                          });
                        };
                        if (depth === 2) {
                          const shouldRowRender = shouldNodeRender({
                            filterNodeIds,
                            rootNodeId: childId,
                            depth,
                            showUpToDepth: true,
                            upToDepth: 2,
                          });

                          const depth2LabelContainer = classNames({
                            [classes.labelDepth2]: true,
                            [classes.labelDepth2Selected]:
                              childId === sidebarSelectedItem[2],
                          });

                          return (
                            shouldRowRender && (
                              <div
                                className={depth2LabelContainer}
                                onClick={handleSetSelectedNodeId}
                                key={childId}
                                style={
                                  isLabelFilled ? { color: colors.gray31 } : {}
                                }
                                dangerouslySetInnerHTML={{
                                  __html: isLabelFilled
                                    ? newLabel
                                    : t("common:enter_with_label", {
                                        label: placeholdersText["2"],
                                      }),
                                }}
                              />
                            )
                          );
                        }
                      })
                    ) : (
                      <div className={classes.labelDepth2EmptyLabel}>
                        {depth2EmptyLabel}
                      </div>
                    )}
                  </AnimateHeight>
                )}
              </React.Fragment>
            )
          );
        } else return null; // if depth one doesn't comes i.e if backend data values are faulty
      })}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const sidebarSelectedItem = state.multiLevelNodeEditor.sidebarSelectedItem;
  return { sidebarSelectedItem };
};
const mapDispatchToProps = {
  setSidebarSelectedItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
