import React, { memo } from "react";
import classes from "./GradesCell.scss";
import { MoreItemWithToolTip, FilterDropdown } from "UIComponents";
import { useI18n } from "Hooks";
import { colors, fontStyle } from "Constants";
import { CaretDownFilled } from "@toddle-design/web-icons";
import { getSortedArrayMemoized } from "NodeEditor/Utils";

const filterDropdownStyle = {
  filterBoxStyle: {
    borderWidth: 0,
    minWidth: "unset",
    padding: 0,
  },
  buttonDropDownContainerStyle: {
    top: "28px",
  },
  filterTextStyle: {
    ...fontStyle.medium,
    color: colors.gray31,
    fontSize: "1.4rem",
    marginRight: "0px",
  },
  nameTextStyle: {
    ...fontStyle.bold,
    color: colors.gray31,
    fontSize: "1.4rem",
  },
  emptyTextStyle: {
    ...fontStyle.bold,
    color: colors.gray31,
    fontSize: "1.4rem",
  },
  buttonComponentStyle: {
    justifyContent: "center",
  },
  parentContainerStyle: { flex: "1 1" },
  buttonParentStyle: { flex: "1 1" },
};

const styles = { tooltipStyle: { cursor: "pointer" } };

const GradesCell = ({ grades, gradeOptions, mode, onUpdateGrades }) => {
  const { t } = useI18n(["schoolSetup", "common"]);

  const filterGrades = _.filter(grades, ({ id }) => id in gradeOptions); // grades which are coming are the grades which are active.

  const gradeItems = _.map(filterGrades, ({ id }) =>
    _.get(gradeOptions[id], "label", "")
  );

  const gradeIds = _.map(filterGrades, ({ id }) => id);
  const isGradeEmpty = _.isEmpty(gradeItems);

  const updateFilterDropdown = value => {
    const newGrades = _.map(value, id => ({ id }));
    onUpdateGrades({ grades: newGrades, nodeGrades: newGrades });
  };

  const gradesLabelWrapper = (
    <div className={classes.tooltipSvgContainer}>
      <MoreItemWithToolTip
        items={gradeItems}
        tooltipPlacement={"right"}
        length={2}
        moreJoinSymbol={", +"}
        moreStyle={styles.tooltipStyle}
        noItemText={t("schoolSetup:no_label_selected", {
          label: t("common:lowercase", {
            text: t("common:grade_plural"),
          }),
        })}
        moreAll={false}
        trigger={["hover"]}
        stopPropagationOnLabelClick={false}
      />
      {mode === "edit" && (
        <div className={classes.svgContainer}>
          <CaretDownFilled variant={"subtle"} />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classes.container}
      style={isGradeEmpty ? { color: colors.yellow50 } : {}}
    >
      {mode === "edit" ? (
        <FilterDropdown
          options={getSortedArrayMemoized({ inputObject: gradeOptions })}
          label="grade_plural"
          value={gradeIds}
          updateInputField={updateFilterDropdown}
          allOptionLabel={"All"}
          isCheckList={true}
          {...filterDropdownStyle}
          shouldMountDropDownOnButtonClick={true}
          emptyOptionsDropdownText={t("common:no_label_found", {
            label: t("common:lowercase", {
              text: t("common:grade_plural"),
            }),
          })}
          buttonComponent={gradesLabelWrapper}
        />
      ) : (
        gradesLabelWrapper
      )}
    </div>
  );
};

export default memo(GradesCell, shouldRender);

const shouldRender = (prevProps, nextProps) => {
  return (
    prevProps.mode === nextProps.mode &&
    prevProps.grades === nextProps.grades &&
    prevProps.onUpdateGrades === nextProps.onUpdateGrades
  );
};
