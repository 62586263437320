import React from "react";
import classes from "./EditOrgSetting.scss";
import { graphql, compose } from "react-apollo";
import _ from "lodash";
import { connect } from "react-redux";
import { updateSettings } from "modules/Services";
import {
  UIButton,
  InputTextField,
  I18nHOC,
  Loading,
  withLoader,
  UIModal,
  RadioButtonList,
  FullScreenLoader,
  SelectDropdown,
} from "UIComponents";
import { getOrganizationSetting } from "Tooling/modules/ToolingQueries";

const radioOptions = [
  { label: "True", value: true },
  { label: "False", value: false },
];

const dropDownOptions = {
  locale: [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
  ],
};
class EditOrgSetting extends React.Component {
  constructor(props) {
    super(props);
    this.basicOrgDetails = React.createRef();
    let orgSettings = {};
    const settings = _.cloneDeep(props.settings);
    _.forEach(settings, setting => {
      let { name, value } = setting;
      if (value === "true") {
        value = true;
      }
      if (value === "false") {
        value = false;
      }
      orgSettings[name] = value;
    });
    this.state = {
      orgId: props.orgDetail.id,
      planId: props.orgDetail.planId,
      orgSettings,
      hint: false,
      hintId: "",
      isFullScreenLoading: false,
    };
  }

  close = () => {
    this.props.closeModal();
  };

  onClickOperation = async () => {
    const { updateSettings } = this.props;
    const { orgSettings, orgId } = this.state;
    let i = 0;
    const setting = [];
    const keys = _.keys(orgSettings);
    try {
      _.forEach(orgSettings, item => {
        if (item === true) {
          item = "true";
        }
        if (item === false) {
          item = "false";
        }
        setting.push({ name: keys[i], value: item });
        i++;
      });
      this.setState({ isFullScreenLoading: true });

      const isSuccess = await updateSettings({
        type: "org",
        settings: setting,
        id: orgId,
        isForTools: true,
      });
      this.setState({ isFullScreenLoading: false });

      if (isSuccess) {
        this.props.closeModal();
      }
    } catch (err) {
      console.error(err);
    }
  };

  updateOrgObject = params => {
    const { orgSettings } = this.state;
    this.setState({
      orgSettings: {
        ...(orgSettings || {}),
        ...(params || {}),
      },
    });
  };

  showHint = obj => {
    this.setState({
      hint: true,
      hintId: obj.name,
    });
  };

  closeHint = () => {
    this.setState({
      hint: false,
    });
  };

  renderSettings = () => {
    const { t, settings } = this.props;
    const { orgSettings, hint, hintId } = this.state;
    return _.map(settings, setting => {
      const { name, helpText, label, type } = setting;
      const value = _.get(orgSettings, name, "");
      return (
        <div key={name}>
          <div className={classes.horizontalRowInput}>
            <div className={classes.btnContainer}>
              {/* {!!helpText && (
                <button
                  className={classes.btn}
                  onMouseEnter={() =>
                    this.showHint({
                      name: name,
                      helpText: helpText
                    })
                  }
                  onMouseLeave={this.closeHint}
                >
                  i
                </button>
              )} */}
            </div>
            {hint && hintId == name ? (
              <div key={name} className={classes.talkbubble}>
                <div className={classes.talktext}>
                  <label>{helpText}</label>
                </div>
              </div>
            ) : null}
            <div className={classes.horizontalInputElement}>
              <div className={classes.labelText}>{label}</div>
            </div>
            {type == "TEXT" ? (
              <div className={classes.horizontalInputElement}>
                {name == "locale" ? (
                  <SelectDropdown
                    options={dropDownOptions[name] || []}
                    value={value}
                    name={name}
                    updateInputField={this.updateOrgObject}
                  />
                ) : (
                  <InputTextField
                    label={""}
                    error="Enter the Name"
                    name={name}
                    value={value}
                    updateInputField={this.updateOrgObject}
                    placeholder={t("schoolSetup:value_to_setting", {
                      label: t("schoolSetup:value"),
                    })}
                    inputStyle={classes.titleInput}
                  />
                )}
              </div>
            ) : (
              <div className={classes.horizontalRadioElement}>
                <div className={classes.label}></div>

                <div className={(classes.inputText, classes.inputRadioList)}>
                  <RadioButtonList
                    options={radioOptions}
                    label={""}
                    value={value}
                    name={name}
                    updateInputField={this.updateOrgObject}
                    showErrorText={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const { loading } = this.props;
    const { isFullScreenLoading } = this.state;
    return (
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>{`School Settings`}</div>
        <div className={classes.br}></div>
        <div className={classes.stepContainer}>
          <div className={classes.editStep2}>
            <div className={classes.set}>{this.renderSettings()}</div>
            {loading ? (
              <div className={classes.loadingOverlay}>
                <Loading />
              </div>
            ) : null}
            <div className={classes.modalFooter}>
              <UIButton
                type="hollow"
                color="grey"
                size="sm"
                onClick={this.close}
              >
                {`Cancel`}
              </UIButton>
              <div className={classes.lastButton}>
                <UIButton
                  color="blue"
                  size="sm"
                  onClick={this.onClickOperation}
                >
                  {"Save"}
                </UIButton>
              </div>
            </div>
          </div>
        </div>
        {!!isFullScreenLoading && <FullScreenLoader />}
      </div>
    );
  }
}
const EditModalWithLoading = withLoader(EditOrgSetting);

const EditOrgSettingComp = React.memo(props => {
  const { closeModal } = props;
  return (
    <UIModal
      isOpen={true}
      onRequestClose={closeModal}
      modalContent={classes.modalContent}
    >
      <EditModalWithLoading {...props} />
    </UIModal>
  );
});

const mapStateToProps = () => {
  return {
    isData: true,
    isLoading: false,
  };
};

const mapActionCreators = {
  updateSettings,
};

export default compose(
  I18nHOC({ resource: ["schoolSetup", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationSetting, {
    name: "getOrganizationSetting",
    options: ({ orgDetail }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: orgDetail.id, isForTools: true },
    }),
    props: ({ getOrganizationSetting, ownProps: { isData, isLoading } }) => {
      const settings = _.get(getOrganizationSetting, "node.settings", {});
      return {
        settings,
        isData: !_.isEmpty(settings) && isData,
        isLoading:
          getOrganizationSetting["networkStatus"] == 1 ||
          getOrganizationSetting["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(EditOrgSettingComp);
