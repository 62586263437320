import { withAsyncRouteLoading } from "UIComponents";
import StudentTranscript from "./routes/StudentTranscript";

export default store => ({
  path: "students",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "transcript-chunk"
        );
      })
  ),
  childRoutes: [StudentTranscript(store)],
});
