import gql from "graphql-tag";
import { staffFragment } from "modules/CommonFragments";

export const rubricElementsFragment = {
  descriptor: gql`
    fragment descriptorItem on DescriptorRubric {
      id
      label
      criteria {
        id
        label
      }
      indicator {
        id
        label
      }
    }
  `,
  indicator: gql`
    fragment indicatorItem on IndicatorRubric {
      id
      label
    }
  `,
  criteria: gql`
    fragment criteriaItem on CriteriaRubric {
      id
      label
    }
  `,
};

export const rubricFragment = {
  rubric: gql`
    fragment rubricItem on Rubric {
      id
      title
      criterias {
        ...criteriaItem
      }
      indicators {
        ...indicatorItem
      }
      descriptors {
        ...descriptorItem
      }
      createdBy {
        ...staffItem
      }
      lastEdited: updatedAt
    }
    ${rubricElementsFragment.criteria}
    ${rubricElementsFragment.indicator}
    ${rubricElementsFragment.descriptor}
    ${staffFragment.basicStaffDetails}
  `,
};

export const organizationFragment = {
  rubricList: gql`
    fragment organizationItem on Organization {
      id
      rubrics(filters: $filters) {
        ...rubricItem
      }
    }
    ${rubricFragment.rubric}
  `,
};
