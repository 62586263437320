import React from "react";
import classes from "./PickAttachmentModal.scss";

import ModalContainer from "./ModalContainer";
import { CancelIcon, EmptySvgIcon } from "SvgComponents";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { withLoader, UIButton, I18nHOC } from "UIComponents";
import { getPlannerResourcesOfNodeQuery } from "UnitPlans/modules/UnitPlanQuery";
import { getPlannerResourcesOfNodeFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";

class PickAttachmentModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.modalContainer = null;
    this.state = {
      selectedResources: [],
    };
  }

  updateSelectedResources = itemId => {
    let selectedResources = [...this.state.selectedResources];
    let resourceIndex = _.findIndex(
      selectedResources,
      resource => resource == itemId
    );
    if (resourceIndex == -1) {
      selectedResources.push(itemId);
    } else {
      _.remove(selectedResources, (resource, index) => {
        return index == resourceIndex;
      });
    }

    this.setState({ selectedResources });
  };

  onClickAdd = () => {
    const { resources } = this.props;
    this.props.pickResource({
      resourceIds: this.state.selectedResources,
      resources: _.filter(resources, ({ id }) =>
        _.includes(this.state.selectedResources, id)
      ),
    });
    this.props.onClickClose();
  };

  getEmptyState = () => {
    const { addNewReource, t } = this.props;
    return (
      <div className={classes.emptyState}>
        <EmptySvgIcon height={80} width={80} />
        <div className={classes.emptyText}>
          {t("resource_emptyText_with_label", {
            label: t("common:unit").toLowerCase(),
          })}
        </div>
        <div className={classes.addButton}>
          <UIButton color="blue" onClick={addNewReource} size="lg">
            {t("common:create_new_with_label", {
              label: t("common:resource_label").toLowerCase(),
            })}
          </UIButton>
        </div>
      </div>
    );
  };

  render() {
    const { onClickClose, canPick, addNewReource, resources, t } = this.props;
    let { selectedResources } = this.state;
    return (
      <React.Fragment>
        <div className={classes.header}>
          <div className={classes.title}>
            {t("common:select_with_label", {
              label: t("common:lowercase", {
                text: t("common:resource_label"),
              }),
            })}
          </div>
          <div className={classes.cancelIcon} onClick={onClickClose}>
            <CancelIcon width={16} heigth={16} />
          </div>
        </div>

        <div className={classes.formFieldsParentContainer}>
          <div className={classes.formFields}>
            {_.isEmpty(resources) ? (
              <React.Fragment>{this.getEmptyState()}</React.Fragment>
            ) : (
              <ModalContainer
                customRef={ref => (this.modalContainer = ref)}
                selectedResources={selectedResources}
                updateSelectedResources={this.updateSelectedResources}
                canPick={canPick}
                resources={resources}
              />
            )}
          </div>
        </div>
        {_.isEmpty(resources) ? null : (
          <div className={classes.buttonContainer}>
            <div className={classes.cancelDiv}>
              <UIButton color="grey" onClick={onClickClose} size="sm">
                {t("common:cancel")}
              </UIButton>
            </div>

            <UIButton
              color="pink"
              onClick={this.onClickAdd}
              size="sm"
              isDisabled={selectedResources.length == 0}
            >
              {t("common:add")}
            </UIButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const getResources = (nodeDetails, selectedResources) => {
  let unitResources = _.map(
    _.get(nodeDetails, "resources.edges", []),
    resource => resource.node
  );

  const resources = _.differenceBy(unitResources, selectedResources, "id");
  return resources;
};

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    nodeId:
      ownProps.unitPlanId ||
      _.get(state, "planner.unitPlanData.unitPlanId", ""),
    type: "UNIT_PLAN",
    selectedResources: ownProps.selectedResources,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getPlannerResourcesOfNodeQuery, {
    name: "getPlannerResourcesOfNode",
    options: ({ nodeId, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: nodeId, type },
    }),
    props({
      getPlannerResourcesOfNode,
      ownProps: { nodeId, type, selectedResources },
    }) {
      const nodeDetails = getPlannerResourcesOfNodeFromCache({ nodeId, type });
      return {
        isData: !_.isEmpty(nodeDetails),
        resources: getResources(nodeDetails, selectedResources),
        isLoading:
          getPlannerResourcesOfNode["networkStatus"] == 1 ||
          getPlannerResourcesOfNode["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(PickAttachmentModal);
