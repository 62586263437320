import React, { Fragment } from "react";

import classes from "./DialogueBox.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { InputTextField, TextAreaInput } from "UIComponents";
import { UIButton, UIModal } from "UIComponents";
import { isURL } from "Utils";
import { CloseOutlined } from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";

class DialogueBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { promptText: _.get(props, "promptDetail.promptText", "") };
  }

  onPromptTextChange = params => {
    this.setState({ promptText: params.promptText });
  };
  onClickContainer = event => {
    event.stopPropagation();
    event.preventDefault();
  };
  onClickButton2 = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.props.onClickButton2) {
      if (this.prompText) {
        if (this.props.promptDetail.checkValidation) {
          const errorCount = this.prompText.isValid();

          if (errorCount > 0) {
            return;
          }
        }
        this.props.onClickButton2(this.state.promptText);
      } else {
        this.props.onClickButton2();
      }
    }
    this.props.toggleDialogueBoxDisplay();
  };

  onClickButton1 = event => {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.onClickButton1) {
      this.props.onClickButton1();
    }
    this.props.toggleDialogueBoxDisplay();
  };

  onPromptEnter = () => {
    this.onClickButton2();
  };

  onCancelClick = e => {
    e && e.stopPropagation();
    if (this.props.toggleDialogueBoxDisplay) {
      this.props.toggleDialogueBoxDisplay({ isCancel: true });
    }
  };

  customValidation = value => {
    const {
      promptDetail: { customValidationType },
    } = this.props;
    switch (customValidationType) {
      case "URL":
        if (isURL(value)) return "";
        else return "Please enter valid url";
      // break;
      default:
        return "";
      // break;
    }
  };

  render() {
    const modalContentClass = classNames(
      classes.modalContent,
      this.props.size
        ? classes["Modal--" + this.props.size]
        : classes["Modal--md"],
      this.props.customModalContentClass
    );
    const {
      promptDetail: { customValidationType },
      promptBoxType,
      modalBodyStyle,
      button1Styles,
      button2Styles,
      button1Props,
      button2Props,
      warningTextStyles,
      headerTitleStyles,
      modalTitle,
      modalTitleSubText,
      modalTitleSubTextStyles,
      cancelButtonStyle,
    } = this.props;

    return (
      <UIModal
        isOpen={true}
        modalContent={modalContentClass}
        onRequestClose={this.onCancelClick}
      >
        <React.Fragment>
          {!!this.props.modalTitle && (
            <div
              className={classes.modalHeader}
              style={this.props.headerStyles}
            >
              <div style={headerTitleStyles}>
                {modalTitle}
                {!!modalTitleSubText && (
                  <div style={modalTitleSubTextStyles}>{modalTitleSubText}</div>
                )}
              </div>
              {this.props.showCloseButton && (
                <div style={cancelButtonStyle}>
                  <IconButton
                    icon={<CloseOutlined />}
                    onClick={this.onCancelClick}
                    size={"large"}
                    variant={"plain"}
                  />
                </div>
              )}
            </div>
          )}

          <div className={classes.modalBody} style={modalBodyStyle}>
            {this.props.warningText && (
              <div className={classes.warningText} style={warningTextStyles}>
                {this.props.warningText}
              </div>
            )}
            {this.props.isPromptBox ? (
              <Fragment>
                {promptBoxType == "InputTextField" ? (
                  <InputTextField
                    name="promptText"
                    placeholder={this.props.promptDetail.placeholder}
                    label={this.props.promptDetail.label}
                    updateInputField={this.onPromptTextChange}
                    value={this.state.promptText}
                    ref={ref => (this.prompText = ref)}
                    onEnter={this.onPromptEnter}
                    autoFocus={true}
                    customValidation={
                      customValidationType && this.customValidation
                    }
                  />
                ) : (
                  <TextAreaInput
                    name="promptText"
                    minRows={3}
                    maxRows={3}
                    placeholder={this.props.promptDetail.placeholder}
                    label={this.props.promptDetail.label}
                    updateInputField={this.onPromptTextChange}
                    ref={ref => (this.prompText = ref)}
                    value={this.state.promptText}
                    autoFocus={true}
                    onEnter={this.onPromptEnter}
                    customValidation={
                      customValidationType && this.customValidation
                    }
                  />
                )}
              </Fragment>
            ) : (
              this.props.modalBody
            )}
          </div>

          <div
            className={classes.modalFooter}
            style={this.props.footerContainerStyle}
          >
            {!!this.props.button1 && (
              <div className={classes.button1Div} style={button1Styles}>
                <UIButton
                  onClick={this.onClickButton1}
                  color="grey"
                  {...button1Props}
                >
                  {this.props.button1}
                </UIButton>
              </div>
            )}
            {!!this.props.button2 && (
              <div className={classes.button2Div} style={button2Styles}>
                <UIButton
                  onClick={this.onClickButton2}
                  color="pink"
                  {...button2Props}
                >
                  {this.props.button2}
                </UIButton>
              </div>
            )}
          </div>
        </React.Fragment>
      </UIModal>
    );
  }
}

DialogueBox.propTypes = {
  size: PropTypes.string,
  modalTitle: PropTypes.string,
  modalTitleSubText: PropTypes.string,
  modalBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  button1: PropTypes.string,
  button2: PropTypes.string,
  toggleDialogueBoxDisplay: PropTypes.func,
  onClickButton2: PropTypes.func,
  onClickButton1: PropTypes.func,
  isPromptBox: PropTypes.bool,
  promptBoxType: PropTypes.string,
  promptDetail: PropTypes.any,
  modalBodyStyle: PropTypes.object,
  button1Props: PropTypes.object,
  button2Props: PropTypes.object,
  warningTextStyles: PropTypes.object,
  modalTitleSubTextStyles: PropTypes.object,
  cancelButtonStyle: PropTypes.object,
};

DialogueBox.defaultProps = {
  isPromptBox: false,
  promptBoxType: "InputTextField",
  warningText: "",
  modalTitleSubText: "",
  footerContainerStyle: {},
  promptDetail: {
    label: "",
    placeholder: "",
    checkValidation: false,
    promptText: "",
  },
  modalBodyStyle: {},
  button1Props: {},
  button2Props: {},
  button1Styles: {},
  button2Styles: {},
  headerStyles: {},
  showCloseButton: true,
  warningTextStyles: {},
  headerTitleStyles: {},
  modalTitleSubTextStyles: {
    color: "#717171",
    fontSize: "1.4rem",
    marginTop: "6px",
    fontWeight: "500",
  },
  cancelButtonStyle: {},
};

export default DialogueBox;
