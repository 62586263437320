import React from "react";
import classes from "./GradeFilter.scss";
import { ArrowIcon } from "SvgComponents";
import { CheckListContainer, ButtonDropdown, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";

const styles = {
  buttonDropDownContainerStyle: {
    top: 48,
    right: 0,
    width: 200,
    maxHeight: 285,
    overflowY: "auto",
  },
  checkListContainerStyle: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    flexWrap: "unset",
  },
  checklistItemStyle: {
    flexShrink: "unset",
  },
};

class GradeFilter extends React.PureComponent {
  getCheckList = () => {
    const {
      grades,
      gradeFilters,
      updateGradeFilters,
      dropDownEmptyText,
      name,
      t,
    } = this.props;
    const gradeList = _.map(grades, item => {
      return { label: item.name, value: item.id };
    });
    const value = _.map(gradeFilters, item => {
      return item.id;
    });

    return (
      <CheckListContainer
        updateInputField={updateGradeFilters}
        value={value}
        options={gradeList}
        name={name}
        showAllOption={true}
        checkListContainerStyle={styles.checkListContainerStyle}
        emptyText={
          dropDownEmptyText == "defaultDropDownEmptyText"
            ? t("common:no_label_added", { label: t("common:grade_plural") })
            : dropDownEmptyText
        }
        emptyContainerStyle={{ height: "40px" }}
        listItemStyle={styles.checklistItemStyle}
        allOptionLabel={t("common:all_label", {
          label: t("common:grade_plural"),
        })}
      />
    );
  };

  getButtonComponnent = () => {
    const {
      gradeFilters = [],
      label,
      filterBoxStyle,
      filterTextStyle,
      gradeNameTextStyle,
      emptyGradeText,
      emptyTextStyle,
      arrowContainerStyle,
      labelComponent,
      t,
      grades,
    } = this.props;

    let gradeNameString =
      emptyGradeText == "defaultEmptyGradeText"
        ? t("common:na")
        : emptyGradeText;
    let isEmpty = true;
    if (!labelComponent) {
      if (gradeFilters.length == grades.length) {
        gradeNameString = t("common:all_label", {
          label: t("common:grade_plural"),
        }); //TO DO: to change
        isEmpty = false;
      } else {
        if (gradeFilters.length > 0) {
          gradeNameString = _.get(gradeFilters[0], "name", "-");
          isEmpty = false;
        }
        if (gradeFilters.length > 1) {
          gradeNameString = `${gradeNameString}, ${t("common:number_more", {
            number: gradeFilters.length - 1,
          })}`;
          isEmpty = false;
        }
      }
    }
    return (
      <div className={classes.filterBox} style={filterBoxStyle}>
        {labelComponent ? (
          labelComponent
        ) : (
          <div className={classes.filterText} style={filterTextStyle}>
            {label}
            <div
              className={classes.gradeNames}
              style={isEmpty ? emptyTextStyle : gradeNameTextStyle}
            >
              {gradeNameString}
            </div>
          </div>
        )}

        <div className={classes.bottomArrow} style={arrowContainerStyle}>
          <ArrowIcon width={10} height={5} />
        </div>
      </div>
    );
  };

  render() {
    const { buttonParentStyle, parentContainerStyle } = this.props;
    const buttonComponent = this.getButtonComponnent();
    return (
      <ButtonDropdown
        buttonParentStyle={buttonParentStyle}
        parentContainerStyle={parentContainerStyle}
        renderDropDownComponent={this.getCheckList}
        containerStyle={styles.buttonDropDownContainerStyle}
        buttonComponent={buttonComponent}
        shouldCloseOnSelfClick={false}
        shouldMountDropDownOnButtonClick={true}
      />
    );
  }
}

GradeFilter.defaultProps = {
  filterBoxStyle: {},
  filterTextStyle: {},
  gradeNameTextStyle: {},
  emptyGradeText: "defaultEmptyGradeText",
  emptyTextStyle: {},
  dropDownEmptyText: "defaultDropDownEmptyText",
  name: "gradeFilters",
};

export default I18nHOC({ resource: ["common"] })(GradeFilter);
