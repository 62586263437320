import React from "react";
import classes from "./InputSearchBar.scss";
import SearchSelectedItem from "./SearchSelectedItem";
import classNames from "classnames";
import { ArrowIcon } from "SvgComponents";
import { UIButton, I18nHOC } from "UIComponents";

export const SearchSelectedList = ({
  searchSelectedItems,
  removeOption,
  disabledIds,
  styles,
}) => {
  return _.map(searchSelectedItems, (item, index) => {
    const disabled = _.includes(disabledIds, item.id, false);
    return (
      <SearchSelectedItem
        item={item}
        key={item.id}
        removeOption={removeOption}
        styles={styles}
        disabled={disabled}
      />
    );
  });
};

class InputSearchBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.onUpdateSearchText = _.debounce(
      this.onUpdateSearchTextOnDebounce,
      200
    );
    this.input = {};
  }

  UNSAFE_componentWillMount = () => {
    if (this.props.searchText) {
      this.updateSearchText(this.props.searchText);
    }
  };

  clearText = () => {
    this.updateSearchText("");
    this.onUpdateSearchText();
  };

  updateSearchText = value => {
    this.setState({ searchText: value });
  };

  searchUpdate = e => {
    this.updateSearchText(e.target.value);
    this.onUpdateSearchText();
  };

  onUpdateSearchTextOnDebounce = () => {
    this.props.changeSearchTerm(this.state.searchText);
  };

  handleKeyDown = event => {
    if (event.keyCode == 8) {
      this.removeLastOption();
    }
  };

  removeLastOption = () => {
    if (!this.input.value && this.props.searchSelectedItems.length > 0) {
      this.props.removeOption(
        this.props.searchSelectedItems[
          this.props.searchSelectedItems.length - 1
        ]
      );
    }
  };
  render() {
    const {
      searchSelectedItems,
      onDoneClick,
      isFocus,
      removeOption,
      handleFocus,
      mode,
      inputSearchBarContainerStyle,
      singlePlaceholder,
      placeholder,
      error,
      errorStyle,
      showErrorText,
      t,
      disabledIds,
    } = this.props;

    const { searchText } = this.state;
    const placeholderTextValue =
      searchSelectedItems.length > 0 ? singlePlaceholder : placeholder;
    let updatedStyle = { ...(inputSearchBarContainerStyle || {}) };
    if (error) {
      updatedStyle = { ...updatedStyle, ...errorStyle };
    }
    const containerStyle = classNames(
      { [classes.container]: true },
      { [classes.focusContainer]: isFocus },
      { [classes.errorContainer]: !!error }
    );

    return (
      <React.Fragment>
        <div className={containerStyle} style={updatedStyle}>
          <div
            className={classes.leftContainer}
            ref={ref => this.props.customRef(ref)}
            onClick={handleFocus}
          >
            <SearchSelectedList
              searchSelectedItems={searchSelectedItems}
              removeOption={removeOption}
              disabledIds={disabledIds}
            />
            <input
              className={classes.textInputContainer}
              placeholder={placeholderTextValue}
              onKeyDown={e => this.handleKeyDown(e)}
              ref={ref => {
                this.input = ref;
              }}
              onFocus={handleFocus}
              value={searchText}
              onChange={e => this.searchUpdate(e)}
            />
          </div>

          <div className={classes.rightContainer}>
            {mode == "edit" &&
              (isFocus ? (
                <UIButton
                  color={"blue"}
                  type={"filled"}
                  size={"sm"}
                  className={classes.doneButton}
                  onClick={onDoneClick}
                >
                  {t("common:done")}
                </UIButton>
              ) : (
                <div className={classes.arrowContainer} onClick={handleFocus}>
                  <ArrowIcon />
                </div>
              ))}
          </div>
        </div>
        {!!error && showErrorText && (
          <div className={classes.errorText}>{t(error)}</div>
        )}
      </React.Fragment>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common", "planathon"] })(
  InputSearchBar
);
