import {
  addChildToFamilyMemberMutation,
  createFeedbackMutation,
  makeTagEntitySeenMutation,
  markStudentAsRequestedForFamilyInviteMutation,
  markUserCommunicationAsSeenMutation,
  markStudentRequestStateSeenMutation,
  updateCourseBookmarkMutation,
} from "./CoursesMutations";
import client from "apolloClient";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  getChildrenQuery,
  getPlatformUserDetailsQuery,
} from "modules/CommonQuery";
import { getOrganizationDetailsUnitPlanCount } from "Courses/modules/CoursesFragments";
import { getBasicDeviceInfo } from "Utils";
import {
  getSingleCourseV2FromCache,
  writeSingleCourseV2IntoCache,
} from "./CoursePaginationHelpers";

export const addChild = ({
  studentCode,
  userId,
  signInFlow,
  setErrorMessage,
}) => {
  return async function (dispatch, getState) {
    try {
      const res = await client.mutate({
        mutation: addChildToFamilyMemberMutation,
        variables: { inviteCode: studentCode, id: userId },
        refetchQueries: !signInFlow
          ? [
              {
                query: getPlatformUserDetailsQuery,
                variables: {
                  id: userId,
                  type: "FAMILY_MEMBER",
                  portalType: "PLANNER",
                },
              },
            ]
          : [],
        awaitRefetchQueries: true,
      });
      return _.get(res, "data.platform.addChildToFamilyMember", {});
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        const error = _.get(e, "graphQLErrors[0].message", "");
        if (setErrorMessage) {
          setErrorMessage(error);
        } else {
          dispatch(setToastMsg({ msg: error, notString: true }));
        }
      }
    }
  };
};

export const createFeedback = ({ email, description, attachments }) => {
  return async function (dispatch, getState) {
    const deviceInfo = getBasicDeviceInfo();
    const metadata = {
      includedInReport: deviceInfo,
      ...deviceInfo,
    };

    try {
      await client.mutate({
        mutation: createFeedbackMutation,
        variables: {
          input: { email, description, attachments, metadata },
        },
      });

      return { successfull: true };
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: "common:something_went_wrong_error" }));
      }
      return { successfull: false };
    }
  };
};

export const resetMBLibraryNewCount = ({ regions }) => {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const organizationId = state.login.userInfo.org_id;
      const res = await client.mutate({
        mutation: makeTagEntitySeenMutation,
        variables: {
          regions,
        },
        refetchQueries: [
          {
            query: getOrganizationDetailsUnitPlanCount,
            variables: {
              id: organizationId,
              regions,
            },
          },
        ],
      });
      return res;
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      }
    }
  };
};

export const setUserMessageToSeen = ({ messageType }) => {
  return async function (dispatch, getState) {
    try {
      const res = await client.mutate({
        mutation: markUserCommunicationAsSeenMutation,
        variables: {
          input: {
            messageType,
            appType: "WEB",
          },
        },
      });
      return res;
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      }
    }
  };
};

export const getCommonCourseUnitPlansCountFromCache = ({ id, regions }) => {
  try {
    const queryData = client.readQuery({
      query: getOrganizationDetailsUnitPlanCount,
      variables: {
        id,
        regions,
      },
    });
    if (queryData != null) {
      return _.get(queryData, "node", {});
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const markStudentAsRequestedForFamilyInvite = ({
  childIds,
  courseId,
}) => {
  return async function (dispatch, getState) {
    const userId = _.get(getState(), "login.userInfo.id");

    try {
      const res = await client.mutate({
        mutation: markStudentAsRequestedForFamilyInviteMutation,
        variables: {
          input: {
            childIds,
            courseId,
          },
        },
        refetchQueries: [
          {
            query: getChildrenQuery,
            variables: {
              id: userId,
              type: "FAMILY_MEMBER",
              portalType: "PLANNER",
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      return _.get(res, "data.platform.addChildToFamilyMember", {});
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: "common:something_went_wrong_error" }));
      }
    }
  };
};

export const markStudentRequestStateSeen = (childIds = []) => {
  return async function (dispatch, getState) {
    try {
      await client.mutate({
        mutation: markStudentRequestStateSeenMutation,
        variables: {
          input: {
            childIds,
          },
        },
      });
      return true;
    } catch (e) {
      console.error(e);
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: "common:something_went_wrong_error" }));
      }
    }
  };
};

export const updateBookmarkCourse = (courseIdInput, refetchCourseQuery) => {
  return async (dispatch, getState) => {
    const isBookmarkClicked = _.has(courseIdInput, "addCourseIds");
    const courseId = isBookmarkClicked
      ? _.get(courseIdInput, "addCourseIds", [])
      : _.get(courseIdInput, "removeCourseIds", []);
    const oldCourse = getSingleCourseV2FromCache({ courseId: courseId[0] });
    try {
      const isUpdateDone = await client.mutate({
        mutation: updateCourseBookmarkMutation,
        variables: {
          input: courseIdInput,
        },
        // refetchQueries: refetchCourseQuery,
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            updateCourseAsBookmark: true,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { updateCourseAsBookmark },
            },
          }
        ) => {
          if (updateCourseAsBookmark) {
            setTimeout(() => {
              writeSingleCourseV2IntoCache({
                courseId,
                data: {
                  ...oldCourse,
                  isBookmark: isBookmarkClicked,
                },
              });
            });
          }
        },
      });
      return isUpdateDone;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};
