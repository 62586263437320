import React from "react";
import classes from "./GrouppedGradeList.scss";
import GradeComponent from "./GradeComponent";
import { connect } from "react-redux";
import { getUserInfo } from "Login/modules/LoginModule";
import { graphql, compose } from "react-apollo";
import { withLoader } from "UIComponents";
import { onClassSelect } from "Teacher/modules/TeacherModule";
import { getOrganizationGradeBasicDetailsQuery } from "modules/CommonQuery";
import { getOrganizationBasicGradeDetailsFromCache } from "modules/CommonGraphqlHelpers";
import _ from "lodash";
import { EmptyState } from "@toddle-design/web";
import { NoClassesIllustration } from "@toddle-design/theme";

class GrouppedGradeList extends React.PureComponent {
  render() {
    const {
      groupedClassData,
      onClassSelect,
      showRightPane,
      handleSelect,
      ...rest
    } = this.props;
    return _.map(groupedClassData, (grade, index) => {
      return (
        <GradeComponent
          key={grade.id}
          grade={grade}
          onClassSelect={handleSelect || onClassSelect}
          showRightPane={showRightPane}
          {...rest}
        />
      );
    });
  }
}

const mapActionCreators = {
  onClassSelect,
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });
  const userId = userInfo.id;

  const currentCurriculumProgramType =
    state.platform.currentCurriculumProgramType;

  const curriculumProgramId = _.find(
    state.platform.organizationCurriculumPrograms,
    item => item.type === currentCurriculumProgramType
  )?.id;

  const filters = _.isEqual(ownProps.creationMode, "IMPORT_LE_FROM_LE_LIBRARY")
    ? { curriculumProgramIds: [curriculumProgramId] }
    : {};
  return {
    isData: true,
    isLoading: false,
    userId: userId,
    organizationId: userInfo.org_id,
    filters,
  };
};

const getReducedCourseList = ({ courses, grades, showArchivedCourses }) => {
  return _.reduce(
    grades,
    (result, grade) => {
      const gradeCourses = _.filter(courses, course => {
        const isCourseIncluded =
          _.findIndex(course.grades, { id: grade.id }) >= 0;
        const isCourseShown = showArchivedCourses
          ? true
          : !_.get(course, "isArchived");

        return isCourseIncluded && isCourseShown;
      });

      if (_.get(gradeCourses, "length", 0) > 0) {
        result.push({
          ...grade,
          courses: _.map(gradeCourses, course => {
            return {
              ...course,
              grades: _.filter(
                _.get(course, "grades", []),
                item => item.id == grade.id
              ),
            };
          }),
        });
      }

      return result;
    },
    []
  );
};

export default compose(
  connect(mapStateToProps, mapActionCreators),

  graphql(getOrganizationGradeBasicDetailsQuery, {
    name: "getOrganizationGrades",
    options: ({ organizationId, portalType, filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        portalType,
        filters,
      },
    }),
    props({
      getOrganizationGrades,
      ownProps: {
        organizationId,
        isData,
        isLoading,
        courses,
        filters,
        showArchivedCourses,
      },
    }) {
      const queryData = getOrganizationBasicGradeDetailsFromCache({
        organizationId,
        filters,
      });

      const grades = _.get(queryData, "grades", []);

      const groupedClassData = getReducedCourseList({
        courses,
        grades,
        showArchivedCourses,
      });

      return {
        isData: !_.isEmpty(queryData) && isData,
        groupedClassData,
        isLoading:
          getOrganizationGrades["networkStatus"] == 1 ||
          getOrganizationGrades["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(GrouppedGradeList);
