import React from "react";
import classes from "./NodeTreeEditor.scss";
import NodeTreeView from "./NodeTreeView";
import OutLine from "./OutLine";
import { connect } from "react-redux";
import {
  updateSearchFilters,
  initNodeList,
  initRootNode,
  updateEditNodeList,
  handleBenchmarkInBulk,
} from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule";

import {
  orderNodes,
  nodeFilterByGrades,
  buildSearchFlatTree,
  scrollToWithAnimation,
} from "Utils";
import { compose } from "react-apollo";
import { FullScreenLoader, DialogueBox, I18nHOC } from "UIComponents";
import { CoachMarks } from "AppComponents";

const DIALOGS_INFO = {
  DELETE: {
    title: t => t("common:delete"),
    message: t => t("common:action_msg", { action: t("common:delete") }),
    confirmButtonText: t => t("common:delete_agree"),
    cancelButtonText: t => t("common:agree_cancel"),
  },
  SAVE: {
    title: t => t("common:save"),
    message: t => t("common:action_msg", { action: t("common:save") }),
    confirmButtonText: t =>
      t("common:action_agree", { action: t("common:save") }),
    cancelButtonText: t => t("common:cancel"),
  },
  DISCARD: {
    title: t => t("common:discard_changes"),
    message: t => t("common:exit_without_save_prompt"),
    confirmButtonText: t => t("common:save_exit"),
    cancelButtonText: t => t("common:discard_exit"),
  },
};

const SEARCH_PLACEHOLDER_HASH = {
  MYP_OBJECTIVES: "academicSetup:search_for_yearly_objective",
  BENCHMARK: "academicSetup:search_for_standard",
  ATL: "academicSetup:search_for_skill",
};

class NodeTreeEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedNodeId: "",
      taggedNodeIds: props.value ? props.value : [],
      currentDialogAction: "",
      isCoachMarksRunning: false,
    };
    this.isAnimatingScroll = false;
    this.currentDialogItem = {};
    this.outlineRefs = {};
    this.nodesRef = {};

    props.updateRef(this);
  }

  getTaggedIds = () => {
    return _.uniq(this.state.taggedNodeIds);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { taggedNodeIds: oldTaggedNodeIds } = prevState;
    const { taggedNodeIds } = this.state;
    const { updateValueLocally } = this.props;
    const { value } = this.props; // here value is checked field which is reveived from props

    // if (oldTaggedNodeIds != value) {
    //   this.setState({
    //     taggedNodeIds: value
    //   });
    // }
    if (taggedNodeIds != oldTaggedNodeIds) {
      if (updateValueLocally) {
        updateValueLocally(taggedNodeIds);
      }
    }
  };

  onCheckBoxClick = ({ id }) => {
    const { taggedNodeIds } = this.state;
    const { onSubmitClick, shouldSaveOnUpdate } = this.props;
    let updatedToggleNodeIds = [];
    const valuesToAdd = [];
    const valuesToRemove = [];
    if (_.includes(taggedNodeIds, id)) {
      valuesToRemove.push(id);
      updatedToggleNodeIds = _.filter(taggedNodeIds, nodeId => nodeId != id);
    } else {
      valuesToAdd.push(id);
      updatedToggleNodeIds = [...taggedNodeIds, id];
    }
    this.setState({ taggedNodeIds: updatedToggleNodeIds });

    if (shouldSaveOnUpdate) {
      setTimeout(() => {
        onSubmitClick({
          valuesToAdd,
          valuesToRemove,
          shouldCloseModal: false,
        });
      });
    }
  };

  updateTaggedNodes = value => {
    this.setState({ taggedNodeIds: value });
  };

  componentDidMount = () => {
    const {
      initNodeList,
      filteredNodes,
      mode,
      rootNode,
      initRootNode,
    } = this.props;
    this.onFilterChanged({ searchText: "" });

    if (mode == "edit") {
      initRootNode(rootNode);
      initNodeList({ nodes: filteredNodes });
    }
  };

  onConceptualUnderStandingCheckBoxClick = () => {
    const { editRootNode, initRootNode } = this.props;
    const isExits = _.find(
      _.get(editRootNode, "snsChildren", []),
      item => item == "CONCEPTUAL_UNDERSTANDING"
    );
    let snsChildren = ["CONCEPTUAL_UNDERSTANDING", "LEARNING_OUTCOME"];
    if (isExits) {
      snsChildren = ["LEARNING_OUTCOME"];
    }
    initRootNode({ ...editRootNode, snsChildren });
  };

  onChangeOutline = ({ selectedNodeId, forcePosition }) => {
    if (selectedNodeId) {
      this.setState({ selectedNodeId });
    }
    if (forcePosition && this.nodesRef[selectedNodeId]) {
      const nodeTop = _.get(this.nodesRef[selectedNodeId], "offsetTop", 0);
      this.scrollToWithAnimation(
        this.scrollContainer,
        nodeTop - (this.scrollContainer.offsetTop + 40),
        forcePosition
      );
    }
  };

  scrollToWithAnimation = (scrollComp, value, forcePosition) => {
    if (this.isAnimatingScroll && !forcePosition) {
      return;
    }

    scrollToWithAnimation(scrollComp, value, 300);
    if (forcePosition) {
      this.isAnimatingScroll = true;
      setTimeout(() => {
        this.isAnimatingScroll = false;
      }, 300);
    }
  };

  checkScroll = ({ selectedNodeId }) => {
    const { mode } = this.props;
    if (this.outLineScrollRef && this.outlineRefs[selectedNodeId]) {
      const outLineNode = this.outlineRefs[
        selectedNodeId
      ].getBoundingClientRect();

      const scrollNode = this.outLineScrollRef.getBoundingClientRect();

      const relativeTop = outLineNode.bottom + 32 - scrollNode.top;

      if (this.outLineScrollRef.clientHeight < relativeTop) {
        this.scrollToWithAnimation(
          this.outLineScrollRef,
          this.outLineScrollRef.scrollTop +
            (relativeTop - this.outLineScrollRef.clientHeight)
        );
      } else if (outLineNode.top - 120 <= 0) {
        this.scrollToWithAnimation(
          this.outLineScrollRef,
          this.outLineScrollRef.scrollTop - Math.abs(outLineNode.top - 120)
        );
      }
    }
  };

  onFilterChanged = params => {
    const { updateSearchFilters } = this.props;
    updateSearchFilters(params);
  };

  onActionClick = ({ index, action, params }) => {
    this.currentDialogItem = { params, index };
    this.setState({ currentDialogAction: action });
  };

  OnToggleDialogueClick = ({ isCancel } = {}) => {
    const { currentDialogAction } = this.state;
    const { onBackConfirmClick } = this.props;
    switch (currentDialogAction) {
      case "DISCARD":
        if (!isCancel) {
          onBackConfirmClick();
        }
        break;
    }
    this.setState({ currentDialogAction: "" });
    this.currentDialogItem = {};
  };

  onDialogConfirmClick = async () => {
    const { currentDialogAction } = this.state;
    const {
      updateEditNodeList,
      handleBenchmarkInBulk,
      plannerElementSetConfig,
      onChangeMode,
    } = this.props;
    const { params, index } = this.currentDialogItem;
    switch (currentDialogAction) {
      case "SAVE":
      case "DISCARD": {
        const isSuccess = await handleBenchmarkInBulk({
          plannerElementSetConfig,
        });
        if (isSuccess) {
          onChangeMode("view");
        }
        break;
      }

      default:
        updateEditNodeList({
          action: currentDialogAction,
          index,
          params,
        });
    }
  };

  updateOutlineRef = ({ id, ref }) => {
    this.outlineRefs[id] = ref;
  };

  updateNodesRef = ({ id, ref }) => {
    this.nodesRef[id] = ref;
  };

  updateScrollContainerRef = ref => {
    this.scrollContainer = ref;
  };

  handleScroll = () => {
    if (this.scrollContainer && !this.isAnimatingScroll) {
      let currentNode = null;

      _.forEach(this.nodesRef, (node, id) => {
        if (node) {
          const { top: nodeTop } = node.getBoundingClientRect() || {};

          if (
            nodeTop <= this.scrollContainer.clientHeight * 0.75 &&
            nodeTop >= 0
          ) {
            currentNode = id;
          }
        }
      });
      if (currentNode) {
        this.onChangeOutline({ selectedNodeId: currentNode });
        setTimeout(() => {
          this.checkScroll({ selectedNodeId: currentNode });
        }, 50);
      }
    }
  };

  onStepChanged = ({ isDone, isSkipped }) => {
    if (isDone || isSkipped) {
      this.setState({ isCoachMarksRunning: false });
    } else if (!this.state.isCoachMarksRunning) {
      this.setState({ isCoachMarksRunning: true });
    }
  };

  updateOutlineScrollRef = ({ ref }) => {
    this.outLineScrollRef = ref;
  };

  getSearchPlaceHolder = () => {
    const { nodeType } = this.props;
    const searchPlaceHolder = SEARCH_PLACEHOLDER_HASH[nodeType];
    return searchPlaceHolder
      ? searchPlaceHolder
      : "academicSetup:search_for_standard";
  };

  render() {
    const {
      nodes,
      mode,
      filteredNodes,
      filters,
      isTagging,
      showInsights,
      updateGradeFilters,
      gradeFilters,
      rootOptionsGrades,
      editNodes,
      updateEditNodeList,
      isLoading,
      rootNode,
      editRootNode,
      theme,
      uptoDepth,
      showSelectedCount,
      value,
      t,
      nodeParentType,
      startDepth,
      showOutline,
      showSearchHeader,
      multiSelectConfig,
      nodeRenderType,
      insightsData,
      nodeType,
      showGrade,
    } = this.props;
    const {
      selectedNodeId,
      taggedNodeIds,
      currentDialogAction,
      isCoachMarksRunning,
    } = this.state;
    const { nodeTreeEditorContainerStyle } = theme;

    return (
      <div
        className={classes.container}
        id={"COACHMARKS_SCOPE_SEQUENCE_EDITOR"}
        style={nodeTreeEditorContainerStyle}
      >
        {mode == "edit" && (
          <CoachMarks
            type={"SCOPE_SEQUENCE_EDITOR"}
            onStepChanged={this.onStepChanged}
          />
        )}
        {(_.isNull(showOutline)
          ? uptoDepth > 1 || !uptoDepth
          : showOutline) && (
          <OutLine
            nodes={mode == "view" ? nodes : editNodes}
            selectedNodeId={selectedNodeId}
            onChangeOutline={this.onChangeOutline}
            updateOutlineRef={this.updateOutlineRef}
            updateOutlineScrollRef={this.updateOutlineScrollRef}
            mode={mode}
            theme={theme}
            startDepth={startDepth}
            taggedNodeIds={taggedNodeIds}
            showSelectedCount={showSelectedCount}
          />
        )}
        <NodeTreeView
          nodes={mode == "view" ? filteredNodes : editNodes}
          filters={filters}
          mode={mode}
          startDepth={startDepth}
          isTagging={isTagging}
          showInsights={showInsights}
          insightsData={insightsData}
          onFilterChanged={this.onFilterChanged}
          taggedNodeIds={taggedNodeIds}
          onCheckBoxClick={this.onCheckBoxClick}
          updateGradeFilters={updateGradeFilters}
          gradeFilters={gradeFilters}
          rootOptionsGrades={rootOptionsGrades}
          updateEditNodeList={updateEditNodeList}
          rootNode={mode == "view" ? rootNode : editRootNode}
          onActionClick={this.onActionClick}
          updateScrollContainerRef={this.updateScrollContainerRef}
          updateNodesRef={this.updateNodesRef}
          handleScroll={this.handleScroll}
          isCoachMarksRunning={isCoachMarksRunning}
          theme={theme}
          uptoDepth={uptoDepth}
          value={value}
          showSearchHeader={showSearchHeader}
          multiSelectConfig={multiSelectConfig}
          updateTaggedNodes={this.updateTaggedNodes}
          nodeRenderType={nodeRenderType}
          showOutline={showOutline}
          searchPlaceHolder={this.getSearchPlaceHolder()}
          nodeType={nodeType}
          showGrade={showGrade}
        />

        {isLoading && <FullScreenLoader />}
        {!!currentDialogAction && (
          <DialogueBox
            modalTitle={DIALOGS_INFO[currentDialogAction].title(t)}
            showModal={true}
            onClickButton2={this.onDialogConfirmClick}
            modalBody={DIALOGS_INFO[currentDialogAction].message(t)}
            toggleDialogueBoxDisplay={this.OnToggleDialogueClick}
            button1={DIALOGS_INFO[currentDialogAction].cancelButtonText(t)}
            button2={DIALOGS_INFO[currentDialogAction].confirmButtonText(t)}
          />
        )}
      </div>
    );
  }
}

const orderBenchmarksMemoize = _.memoize(
  params => orderNodes(params),
  params => JSON.stringify(params)
);

const nodeFilterByGradesMemoize = _.memoize(
  params => nodeFilterByGrades(params),
  params => JSON.stringify(params)
);

const buildSearchFlatTreeMemoize = _.memoize(
  params => buildSearchFlatTree(params),
  params => JSON.stringify(params)
);

const mapActionCreators = {
  updateSearchFilters,
  initNodeList,
  updateEditNodeList,
  initRootNode,
  handleBenchmarkInBulk,
};

const mapStateToProps = (state, ownProps) => {
  const {
    nodes,
    gradeFilters = [],
    mode,
    rootNode,
    nodeType,
    startDepth,
  } = ownProps;

  const filters = state.multiLevelNodeEditor.searchFilters;
  const searchTreeNodes = buildSearchFlatTreeMemoize({
    nodes: nodes,
    searchText: mode == "edit" ? "" : filters.searchText,
  });
  const orderedNodes = orderBenchmarksMemoize({
    nodes: searchTreeNodes,
    startDepth: !_.isNull(startDepth)
      ? startDepth
      : nodeType == "BENCHMARK"
      ? 0
      : 1,
    gradeSequences: _.get(rootNode, "grades", []),
  });

  let gradeFilterNodes = orderedNodes;
  if (mode == "view" && gradeFilters.length > 0) {
    gradeFilterNodes = nodeFilterByGradesMemoize({
      nodes: orderedNodes,
      grades: mode == "view" ? gradeFilters : [],
    });
  }
  return {
    nodes: orderedNodes,
    filteredNodes: gradeFilterNodes,
    editNodes: state.multiLevelNodeEditor.editNodes,
    isLoading: state.multiLevelNodeEditor.isLoading,
    editRootNode: state.multiLevelNodeEditor.rootNode,
    filters,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "academicSetup"] }),
  connect(mapStateToProps, mapActionCreators)
)(NodeTreeEditor);
