import _, { map as _map } from "lodash";
/**
 * This function creates locale keys from templates
 * Its a backward compatible function
 * Eg. localisedTemplateId is not used in PYP in that case localisedTemplateId will "" value and it will be discarded
 * When some undefined, empty string or null value is passed in keys array those values are discarded
 *
 * @param {Object} param0
 * @param {Array} param0.keys - list of key to join with _
 *
 * @returns {Boolean} - Returns locale key of template string
 */
export const extractLabelsForTranslation = ({ keys }) => {
  const keydata = [];
  _map(keys, data => {
    /**
     * When empty value comes it will discard
     * like in PYP localisedTemplateId will be "" so it will be discarded
     */
    if (data) {
      const splitdata = data.split("_");
      _map(splitdata, label => {
        if (!parseInt(label)) keydata.push(label);
      });
    }
  });
  keys = keydata.filter(Boolean);
  return keys.join("_");
};
