import React from "react";
import classes from "./ListCard.scss";
import { UIButton, ProfileCascade } from "UIComponents";

const profileCascadeStyle = {
  customStyle: {
    width: 40,
    height: 40,
  },
};

const ListCard = ({ item, checked, onClick }) => {
  const { label, value, url } = item;
  return (
    <div className={classes.cardContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.listProfile}>
          <ProfileCascade
            customStyle={profileCascadeStyle}
            items={[{ url, value: label }]}
          />
        </div>

        <div className={classes.content}>
          <div className={classes.label}>{label}</div>
          <div className={classes.value}>{value}</div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <UIButton
          onClick={() => onClick(item, checked)}
          size="sm"
          type="hollow"
        >
          {checked ? "Remove" : "Add"}
        </UIButton>
      </div>
    </div>
  );
};

export default ListCard;
