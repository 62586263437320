import React, { useRef } from "react";
import {
  UIModal,
  I18nHOC,
  ProfileCascade,
  Loading,
  withLoader,
} from "UIComponents";
import classes from "./LikeModal.scss";
import { colors } from "Constants";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import { getRethumbUrl } from "Utils";
import { getUserInfo } from "Login/modules/LoginModule";
import { getEntityLikesQuery } from "Community/modules/CommunityQuery";
import { LikeFillIcon, CancelIcon } from "SvgComponents";
import { checkScrollAtBottom } from "services/checkScrollAtBottom";
import { setActiveParent } from "Community/modules/CommunityModule";
import { getEntityLikedUsersFromCache } from "Community/modules/CommunityGraphqlHelpers";

const profileCascadeStyle = {
  width: 40,
  height: 40,
};
const loadingStyle = { width: 16, height: 16 };

const LikeModal = ({
  onCloseModal,
  t,
  action,
  networkStatus,
  loadMore,
  setActiveParent,
  isLoading,
  data,
}) => {
  const { title = "", url = "", users = [] } = data || {};

  const handleScroll = () => {
    if (
      scrollRef &&
      networkStatus != 3 &&
      checkScrollAtBottom(scrollRef.current)
    )
      loadMore();
  };

  const goToProfile = id => {
    setActiveParent({ value: "profile", route: `profile/${id}` });
  };

  const imageUrl = getRethumbUrl({
    width: 200,
    height: 200,
    imageUrl: url || RESOURCE_PLACEHOLDER_URL,
    fitIn: true,
  });

  const { countValue } = action || {};
  const scrollRef = useRef(null);

  return (
    <div onClick={e => e.stopPropagation()} className={classes.container}>
      <div className={classes.cancelButton} onClick={onCloseModal}>
        <CancelIcon width={16} height={16} fill={colors.white} />
      </div>

      <div className={classes.headerContainer}>
        <div className={classes.headerWrapper}>
          <div className={classes.header}>{title}</div>
          <div className={classes.likesCount}>
            <LikeFillIcon fill={colors.salmon60} width={16} height={16} />
            <span>
              {t("common:likes")}
              {countValue > 0 && ` (${countValue})`}
            </span>
          </div>
        </div>
        <div
          className={classes.profileContainer}
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
      </div>

      <div className={classes.body} ref={scrollRef} onScroll={handleScroll}>
        {_.map(users, user => {
          const {
            id,
            firstName,
            lastName,
            schoolName,
            profileImage = "",
          } = user;
          const name = `${firstName} ${lastName}`;
          return (
            <div key={id} className={classes.user}>
              <div className={classes.leftContainer}>
                <div className={classes.userProfile}>
                  <ProfileCascade
                    customStyle={profileCascadeStyle}
                    items={[
                      {
                        url: profileImage,
                        value: name,
                        id,
                      },
                    ]}
                  />
                </div>
                <div className={classes.userInfo}>
                  <div
                    onClick={() => goToProfile(id)}
                    className={classes.userName}
                  >
                    <span>{name}</span>
                  </div>
                  <div className={classes.userSchool}>{schoolName}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {networkStatus == 3 && (
        <div className={classes.loadingContainer}>
          <Loading loadingStyle={loadingStyle} />
        </div>
      )}
    </div>
  );
};

const LikeWithLoader = withLoader(LikeModal);

const LikeModalComponent = props => {
  const { updateModal } = props;
  const onCloseModal = e => {
    e.stopPropagation();
    updateModal({ type: "" });
  };
  return (
    <UIModal
      isOpen={true}
      onRequestClose={onCloseModal}
      modalContent={classes.modalContent}
    >
      <LikeWithLoader {...props} onCloseModal={onCloseModal} />
    </UIModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });
  return {
    isData: true,
    isLoading: false,
    orgId: _.get(userInfo, "org_id"),
  };
};

const mapActionCreators = {
  setActiveParent,
};

export default compose(
  I18nHOC({ resource: ["community", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getEntityLikesQuery, {
    name: "entityLikesQuery",
    skip: ({ entity }) => !entity,
    options: ({ entity, entityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: entity, entityType, after: "" },
    }),
    props: ({ entityLikesQuery, ownProps }) => {
      const result = getEntityLikedUsersFromCache(ownProps);
      const title = _.get(result, "node.title.value");
      const url = _.get(result, "node.image.value");
      let users = _.map(_.get(result, "node.likes.edges"), "node.user");
      users = _.map(users, user => ({
        ...user,
        schoolName: _.get(user, "schoolTenures.0.school.name"),
      }));

      const data = {
        title,
        url,
        users,
      };
      return {
        data,
        ownProps,
        networkStatus: entityLikesQuery.networkStatus,
        loadMore: () => {
          const pageInfo = _.get(entityLikesQuery, "node.likes.pageInfo", {});
          const { hasNextPage, endCursor } = pageInfo;
          if (!hasNextPage) return;

          return entityLikesQuery.fetchMore({
            query: getEntityLikesQuery,
            variables: {
              id: ownProps.entity,
              entityType: ownProps.entityType,
              after: endCursor || "",
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousNode = previousResult.node;
              const newNode = fetchMoreResult.node;

              const updatedResult = {
                ...previousResult,
                node: {
                  ...previousNode,
                  likes: {
                    ...previousNode.likes,
                    edges: [
                      ...previousNode.likes.edges,
                      ...newNode.likes.edges,
                    ],
                    pageInfo: newNode.likes.pageInfo,
                  },
                },
              };

              return updatedResult;
            },
          });
        },
        isData: ownProps.isData && !_.isEmpty(users),
        isLoading:
          entityLikesQuery["networkStatus"] == 1 ||
          entityLikesQuery["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  })
)(LikeModalComponent);
