import React, { useState, useRef, useContext } from "react";
import classes from "./TOKObject.scss";
import { setToastMsg } from "routes/modules/LoginModule";
import { useI18n, useHandleRightPane } from "Hooks";
import { TopHeader } from "AppComponents";
import {
  UIBaseComponent,
  UIModal,
  ImageDropzone,
  FormTextInput,
  FormTextAreaInput,
  I18nHOC,
  DialogueBox,
  MediaModal,
} from "UIComponents";
import {
  AddCircleOutlined,
  DeleteOutlined,
  HelpBulbOutlined,
  PencilLineOutlined,
} from "@toddle-design/web-icons";
import { Button, IconButton, Tooltip } from "@toddle-design/web";
import classNames from "classnames";
import { generateRandomId } from "Utils";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import HelperPane from "IBPlanner/routes/PlannerStep/components/HelperPane";
import { ProjectPlannerContext } from "Projects/modules/ProjectModules";
import { LabelComponent } from "UnitPlans/components/LabelComponent";

const RIGHT_PANE_ANIMATION_TIME = 300;

const DIALOGS_INFO = {
  DELETE: {
    title: "common:delete_with_label",
    label: "common:object",
    message: "common:delete_msg_with_label",
    confirmButtonText: "common:delete",
    cancelButtonText: "common:cancel",
  },
};

const styles = {
  imageStyles: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  imageContainer: {
    marginBottom: "20px",
  },
};

const ModalComponent = props => {
  const {
    value,
    modalTitleLabel,
    modalTitleEditLabel,
    onClose,
    updateObjectList,
    setToastMsg,
  } = props;
  const { t } = useI18n(["common", "project"]);
  const [objectDetails, setObjectDetails] = useState(() => {
    if (value) {
      return {
        id: value.id,
        imageUrl: value.imageUrl,
        title: value.title,
        source: value.source,
        remark: value.remark,
      };
    } else {
      return {
        id: generateRandomId(),
        imageUrl: "",
        title: "",
        source: "",
        remark: "",
      };
    }
  });

  const {
    currentRightPane,
    fieldId,
    onCloseRightPane,
    handleRightPane,
    showRightPane,
  } = useHandleRightPane({
    fieldId: "",
    currentRightPane: "",
    showRightPane: false,
    rightPaneAnimationTime: RIGHT_PANE_ANIMATION_TIME,
  });

  const {
    template: { body: { field_list = {} } = {}, helpEnabledFields = [] },
  } = useContext(ProjectPlannerContext);

  const titleRef = useRef({});
  const imageRef = useRef({});

  const updateObjectDetails = params => {
    setObjectDetails(prevState => ({ ...prevState, ...params }));
  };

  const onAddClick = () => {
    const titleError = titleRef.current.isValid();
    const imageError = imageRef.current.isValid();
    if (imageError) {
      setToastMsg({
        msg: "common:please_upload_a_image",
        type: "alert",
      });
    }

    const errorCount = titleError + imageError;

    if (errorCount === 0) {
      updateObjectList(objectDetails);
      onClose();
    }
  };

  const getIsHelpButtonActive = ({ uid }) => {
    return _.isEqual(currentRightPane, "help") && _.isEqual(fieldId, uid);
  };

  const setHelpText = ({ uid }) => {
    handleRightPane({
      rightPane: "help",
      id: uid,
    });
  };

  const getHelpTextDetails = ({ helpTextUid }) => {
    return _.find(helpEnabledFields, { uid: helpTextUid });
  };

  const rightPaneClasses = classNames(
    { [classes.rightPane]: true },
    { [classes.visible]: showRightPane },
    { [classes.hidden]: !showRightPane }
  );

  const getRightPaneComponent = () => {
    switch (currentRightPane) {
      case "help":
        return (
          <HelperPane
            onClose={onCloseRightPane}
            fieldObj={field_list[fieldId]}
            helperTextId={_.get(
              getHelpTextDetails({ helpTextUid: fieldId }),
              "helpText.id",
              null
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <UIModal isOpen={true} isFullScreen={true}>
      <div className={classes.modalContainer}>
        <TopHeader
          onBack={onClose}
          showBackButton={true}
          headerText={value ? modalTitleEditLabel : modalTitleLabel}
          showMiddleContainer={true}
        />
        <div className={classes.scrollContainer}>
          <div className={classes.innerContainer}>
            <ImageDropzone
              label={t("common:image")}
              isRequired={true}
              customRef={ref => (imageRef.current = ref)}
              showUnsplash={false}
              dropMessage={t("common:dropzone_empty_msg")}
              name={"imageUrl"}
              containerStyle={styles.imageContainer}
              imageStyle={styles.imageStyles}
              value={objectDetails.imageUrl}
              updateInputField={updateObjectDetails}
            />
            <div className={classes.inputField}>
              <FormTextInput
                isRequired={true}
                placeholder={t("project:object_title_placeholder")}
                customRef={ref => (titleRef.current = ref)}
                name={"title"}
                value={objectDetails.title}
                updateInputField={updateObjectDetails}
                size={"large"}
                labelComponent={
                  <LabelComponent
                    label={_.get(
                      field_list["TOKObjectsTitle"],
                      "config.label",
                      ""
                    )}
                    subText={_.get(
                      field_list["TOKObjectsTitle"],
                      "config.subText",
                      ""
                    )}
                    showHelper={
                      !_.isEmpty(
                        getHelpTextDetails({ helpTextUid: "TOKObjectsTitle" })
                      )
                    }
                    onHelpClick={() => setHelpText({ uid: "TOKObjectsTitle" })}
                    isHelpActive={getIsHelpButtonActive({
                      uid: "TOKObjectsTitle",
                    })}
                  />
                }
              />
            </div>
            <div className={classes.inputField}>
              <FormTextInput
                placeholder={t("project:object_source_placeholder")}
                name={"source"}
                value={objectDetails.source}
                updateInputField={updateObjectDetails}
                size={"large"}
                labelComponent={
                  <LabelComponent
                    label={_.get(
                      field_list["TOKObjectsSource"],
                      "config.label",
                      ""
                    )}
                    subText={_.get(
                      field_list["TOKObjectsSource"],
                      "config.subText",
                      ""
                    )}
                    showHelper={
                      !_.isEmpty(
                        getHelpTextDetails({ helpTextUid: "TOKObjectsSource" })
                      )
                    }
                    onHelpClick={() => setHelpText({ uid: "TOKObjectsSource" })}
                    isHelpActive={getIsHelpButtonActive({
                      uid: "TOKObjectsSource",
                    })}
                  />
                }
              />
            </div>
            <div className={classes.inputField}>
              <FormTextAreaInput
                name={"remark"}
                placeholder={t("project:object_remark_placeholder")}
                minRows={2}
                value={objectDetails.remark}
                updateInputField={updateObjectDetails}
                labelComponent={
                  <LabelComponent
                    label={_.get(
                      field_list["TOKObjectsRemark"],
                      "config.label",
                      ""
                    )}
                    subText={_.get(
                      field_list["TOKObjectsRemark"],
                      "config.subText",
                      ""
                    )}
                    showHelper={
                      !_.isEmpty(
                        getHelpTextDetails({ helpTextUid: "TOKObjectsRemark" })
                      )
                    }
                    onHelpClick={() => setHelpText({ uid: "TOKObjectsRemark" })}
                    isHelpActive={getIsHelpButtonActive({
                      uid: "TOKObjectsRemark",
                    })}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className={rightPaneClasses}>{getRightPaneComponent()}</div>
      </div>
      <footer className={classes.footerContainer}>
        <Button onClick={onClose} variant={"plain"}>
          {t(value ? "common:cancel" : "common:discard")}
        </Button>
        <Button onClick={onAddClick}>
          {t(value ? "common:save" : "common:add")}
        </Button>
      </footer>
    </UIModal>
  );
};

class TOKObject extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showModal: false,
      showMediaModal: false,
      currentDialogAction: "",
      currentObjectDetails: null,
    };
  }

  toggleModal = () => {
    const { showModal } = this.state;
    if (showModal) {
      this.setState({ showModal: !showModal, currentObjectDetails: null });
    } else {
      this.setState({ showModal: !showModal });
    }
  };
  updateObjectList = params => {
    const { value } = this.props;
    const newList = [
      params,
      ..._.filter(value || [], item => item.id !== params.id),
    ];
    this.updateValue(newList);
  };

  onDelete = () => {
    const { value } = this.props;
    const { currentObjectDetails } = this.state;
    this.updateValue(
      _.filter(value || [], item => item.id !== currentObjectDetails.id)
    );
  };

  closeDialogue = () => {
    this.setState({ currentDialogAction: "", currentObjectDetails: null });
  };

  onDeleteClick = item => {
    this.setState({
      currentObjectDetails: item,
      currentDialogAction: "DELETE",
    });
  };

  onEditClick = item => {
    this.setState({ currentObjectDetails: item });
    this.toggleModal();
  };

  toggleMediaModal = value => {
    this.setState({ showMediaModal: value });
    if (!value) {
      this.setState({ currentObjectDetails: null });
    }
  };

  showMediaModal = item => {
    this.setState({ currentObjectDetails: item });
    this.toggleMediaModal(true);
  };

  renderLabel = () => {
    const {
      value,
      label,
      editLabel,
      showHelper,
      isHelpActive,
      onHelpClick,
      helpEnabledFields,
      fieldKey,
      fieldId,
      getIsHelpActive,
    } = this.props;

    const { helpText } = _.find(helpEnabledFields, { uid: fieldKey }) || {};

    const editLabelClass = classNames({
      [classes.editLabel]: true,
      ["text-body-2"]: true,
    });
    return (
      <div className={classes.labelContainer}>
        <span className="text-label-2">{label}</span>
        <div className={classes.labelRightContainer}>
          {_.size(value) > 0 && (
            <span className={editLabelClass} onClick={this.toggleModal}>
              <AddCircleOutlined size={"xxx-small"} variant={"link"} />
              {editLabel}
            </span>
          )}
          {showHelper && (
            <IconButton
              icon={<HelpBulbOutlined />}
              variant={
                getIsHelpActive({ fielduid: fieldKey }) ? "primary" : "plain"
              }
              size={"large"}
              onClick={() =>
                onHelpClick({
                  fieldKey,
                  helperTextId: helpText.id,
                  id: fieldId,
                })
              }
            />
          )}
        </div>
      </div>
    );
  };

  renderEdit = () => {
    const {
      value,
      modalTitleLabel,
      setToastMsg,
      t,
      modalTitleEditLabel,
    } = this.props;
    const {
      showModal,
      currentDialogAction,
      showMediaModal,
      currentObjectDetails,
    } = this.state;

    const boxInnerContainerClasses = classNames({
      [classes.boxInnerContainer]: true,
      ["text-body-2"]: true,
    });

    const titleTextClass = classNames({
      [classes.titleText]: true,
      ["heading-6"]: true,
    });

    const remarkTextClass = classNames({
      [classes.remarkText]: true,
      ["text-body-2"]: true,
    });

    return (
      <div className={classes.container}>
        {_.size(value) > 0 ? (
          <div className={classes.objectListContainer}>
            {_.map(value, object => (
              <div className={classes.objectItemContainer} key={object.id}>
                <div
                  className={classes.objectImage}
                  style={{ backgroundImage: `url(${object.imageUrl})` }}
                  onClick={() => this.showMediaModal(object)}
                ></div>
                <div className={classes.objectInfoContainer}>
                  <div className={classes.textContainer}>
                    <div className={classes.titleRemarkContainer}>
                      <Tooltip
                        placement={"top"}
                        tooltip={
                          <div className={classes.tooltipText}>
                            {object?.title}
                          </div>
                        }
                      >
                        <span className={titleTextClass}>{object.title}</span>
                      </Tooltip>
                      <Tooltip
                        placement={"top"}
                        tooltip={
                          <div className={classes.tooltipText}>
                            {object?.remark}
                          </div>
                        }
                      >
                        <span className={remarkTextClass}>{object.remark}</span>
                      </Tooltip>
                    </div>
                    <div className={classes.actionsContainer}>
                      <IconButton
                        icon={<DeleteOutlined />}
                        variant={"plain"}
                        size={"small"}
                        onClick={() => this.onDeleteClick(object)}
                      />
                      <IconButton
                        icon={<PencilLineOutlined />}
                        variant={"plain"}
                        size={"small"}
                        onClick={() => this.onEditClick(object)}
                      />
                    </div>
                  </div>
                  {object.source && (
                    <Tooltip
                      placement={"top"}
                      tooltip={
                        <div className={classes.tooltipText}>
                          {t("common:source_colon_with_label", {
                            label: object.source,
                          })}
                        </div>
                      }
                    >
                      <span className={classes.sourceText}>
                        {t("common:source_colon_with_label", {
                          label: object.source,
                        })}
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.boxOuterContainer}>
            <div
              className={boxInnerContainerClasses}
              onClick={this.toggleModal}
            >
              <AddCircleOutlined variant={"link"} />
              {modalTitleLabel}
            </div>
          </div>
        )}
        {showModal && (
          <ModalComponent
            value={currentObjectDetails}
            modalTitleLabel={modalTitleLabel}
            modalTitleEditLabel={modalTitleEditLabel}
            onClose={this.toggleModal}
            updateObjectList={this.updateObjectList}
            setToastMsg={setToastMsg}
          />
        )}
        {currentDialogAction && (
          <DialogueBox
            modalTitle={t(DIALOGS_INFO[currentDialogAction].title, {
              label: t(DIALOGS_INFO[currentDialogAction].label),
            })}
            onClickButton2={this.onDelete}
            toggleDialogueBoxDisplay={this.closeDialogue}
            modalBody={t(DIALOGS_INFO[currentDialogAction].message, {
              label: t(DIALOGS_INFO[currentDialogAction].label),
            })}
            onClickButton1={this.closeDialogue}
            button1={t(DIALOGS_INFO[currentDialogAction].cancelButtonText)}
            button2={t(DIALOGS_INFO[currentDialogAction].confirmButtonText)}
          />
        )}
        {showMediaModal && (
          <MediaModal
            toggleMediaModal={this.toggleMediaModal}
            attachments={[
              { url: currentObjectDetails.imageUrl, type: "IMAGE" },
            ]}
          />
        )}
      </div>
    );
  };
}

TOKObject.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

const mapActionCreators = { setToastMsg };

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(null, mapActionCreators)
)(TOKObject);
