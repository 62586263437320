import React from "react";
import classes from "./MaxScoreView.scss";
import { AssessmentEvaluatedOutlined } from "@toddle-design/web-icons";
import { useI18n } from "Hooks";

const MaxScoreView = ({ value }) => {
  const isUsingScore = _.get(value, "isUsingScore", false);
  const maxScore = _.get(value, "maxScore", "");
  const { t } = useI18n(["common, classRoom"]);

  return isUsingScore && maxScore ? (
    <>
      <div className={classes.headerContainer}>
        <AssessmentEvaluatedOutlined />
        <div className={classes.headerText}>
          {t("common:point_based_assessment")}
        </div>
      </div>
      <div className={classes.assessmentDetailText}>
        {t("classRoom:max_score_label", {
          label: maxScore,
        })}
      </div>
    </>
  ) : null;
};

export default MaxScoreView;
