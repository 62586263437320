import React from "react";
import TagSnp from "AppComponents/TagSnpModalV2/TagSnp";
import { getPracticeListDetailsFromCache } from "SnP_v2/modules/SnPGraphqlHelpers";
import { getTransformedTaggedPractices } from "Post/utils";
import { getUpdatedTaggedPractices } from "SnP_v2/modules/SnPUtils";
import { connect } from "react-redux";

const TagPracticesV2 = props => {
  const { screenPanelProps, userId } = props;
  const {
    postDetails,
    onCloseClick,
    updatePost,
    curriculumProgramId,
  } = screenPanelProps;

  const { taggedPractices, title, attachments } = postDetails;
  const itemId = _.get(postDetails, "id", "");
  const itemType = "POST";

  const updateTaggedPractices = ({
    evaluationCycle,
    addedPractices,
    removedPractices,
  }) => {
    const addedPracticesDetails = _.map(addedPractices, id => {
      return { ...getPracticeListDetailsFromCache({ id }) };
    });

    const updatedTaggedPractices = getUpdatedTaggedPractices({
      taggedPractices: taggedPractices,
      evaluationCycleId: evaluationCycle.id,
      addedPractices: addedPracticesDetails,
      removedPractices: _.map(removedPractices, id => ({ id })),
      userId: userId,
    });

    updatePost({
      taggedPractices: updatedTaggedPractices,
    });
  };

  return (
    <TagSnp
      curriculumProgramId={
        curriculumProgramId || _.get(postDetails, "curriculumProgramId")
      }
      itemId={itemId}
      itemType={itemType}
      itemTitle={title}
      closeModal={onCloseClick}
      thumbnailData={{ attachments }}
      updateTaggedPractices={updateTaggedPractices}
      taggedPractices={getTransformedTaggedPractices({
        taggedPractices,
      })}
    />
  );
};

const mapStateToProps = state => {
  return {
    userId: _.get(state, "login.userInfo.id", ""),
  };
};

export default connect(mapStateToProps)(TagPracticesV2);
