import React from "react";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, NoDataComponent } from "UIComponents";
import { getOrganizationResourcesBasicDetailsQuery } from "AppComponents/OrganizationResources/modules/OrganizationResourcesQuery";
import { getOrganizationResourcesBasicDetailsFromCache } from "AppComponents/OrganizationResources/modules/OrganizationResourcesGraphqlHelpers";
import classes from "./OrganizationResourceSelection.scss";
import OrganizationResourceFeed from "./OrganizationResourcesFeed";

const OrganizationResourceSelection = props => {
  const {
    resources,
    selectedItems,
    updateSelectedItems,
    disabledItems,
    customEmptyViewElement,
    styles,
    cardType,
    types,
    customHeaderElement,
    cardWrapperElement,
    onClickView,
    t,
  } = props;

  const resourcesLength = _.size(resources);

  return (
    <div className={classes.container} style={styles.containerStyle}>
      {resourcesLength > 0 ? (
        <div className={classes.feedContainer}>
          <OrganizationResourceFeed
            items={resources}
            selectedItems={selectedItems}
            updateSelectedItems={updateSelectedItems}
            styles={styles}
            disabledItems={disabledItems}
            customHeaderElement={customHeaderElement}
            totalCount={resourcesLength}
            cardWrapperElement={cardWrapperElement}
            cardType={cardType}
            onClickView={onClickView}
            types={types}
          />
        </div>
      ) : customEmptyViewElement ? (
        customEmptyViewElement
      ) : (
        <NoDataComponent
          emptyHeaderText={t("common:no_with_label", {
            label: t("common:resources"),
          })}
        />
      )}
    </div>
  );
};

const EnhancedOrganizationResourceSelection = compose(
  I18nHOC({ resource: ["common"] }),
  graphql(getOrganizationResourcesBasicDetailsQuery, {
    name: "getOrganizationResourcesFeed",
    alias: "getOrganizationResourcesFeed",
    options: ({
      parentId,
      filters,
      parentType,
      types,
      visibleTo,
      resourceType,
    }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          parentId,
          filters,
          parentType,
          types,
          visibleTo,
          resourceType,
        },
      };
    },
    props({
      getOrganizationResourcesFeed,
      ownProps: {
        parentId,
        filters,
        parentType,
        types,
        visibleTo,
        resourceType,
      },
    }) {
      const queryData = getOrganizationResourcesBasicDetailsFromCache({
        parentId,
        filters,
        parentType,
        types,
        visibleTo,
        resourceType,
      });

      const resources = _.get(queryData, "node.organizationResources", []);

      return {
        resources,
        isData: !_.isEmpty(queryData),
        isLoading:
          getOrganizationResourcesFeed["networkStatus"] == 1 ||
          getOrganizationResourcesFeed["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(OrganizationResourceSelection);

export default EnhancedOrganizationResourceSelection;

EnhancedOrganizationResourceSelection.defaultProps = {
  parentType: "ORGANIZATION",
  types: ["SCHOOL_POLICIES", "TEACHER_RESOURCE"],
  visibleTo: ["TEACHER", "PARENT"],
  resourceType: "FILE",
  customEmptyViewElement: null,
  styles: {},
  cardType: "VIEW_AND_SELECT",
  isMultiSelect: true,
};
