import React from "react";
import commonClasses from "../common.scss";
import classes from "./PlanathonDetails.scss";
import { I18nHOC } from "UIComponents";
import classNames from "classnames";

import { PlanathonDetails } from "Planathon/data";
const PlanathonDetailsComp = props => {
  const { t } = props;
  const {
    title,
    description,
    steps,
    plusIcon,
    equalIcon,
    image,
    mobileImage,
  } = PlanathonDetails;
  const containerClass = classNames(
    { [commonClasses.container]: true },
    { [classes.container]: true }
  );

  return (
    <div className={containerClass}>
      <div className={commonClasses.title}>{t(title)}</div>
      <div className={commonClasses.description}>{t(description)}</div>
      <div className={classes.stepsContainer}>
        <img src={image} className={classes.desktopStepsImage}></img>
        <img src={mobileImage} className={classes.mobileStepsImage}></img>
        {/* <div className={classes.step}>
          <div className={classes.stepImage}>
            <img src={steps[0].image} height={"100%"}></img>
          </div>
          <div className={classes.stepTitle}>{steps[0].title}</div>
        </div>
        <div className={classes.stepImage}>
          <img src={plusIcon} className={classes.icon}></img>
        </div>
        <div className={classes.step}>
          <div className={classes.stepImage}>
            <img src={steps[1].image} height={"100%"}></img>
          </div>
          <div className={classes.stepTitle}>{steps[1].title}</div>
        </div>
        <div className={classes.stepImage}>
          <img src={equalIcon} className={classes.icon}></img>
        </div>

        <div className={classes.step}>
          <div className={classes.stepImage}>
            <img src={steps[2].image} height={"100%"}></img>
          </div>
          <div className={classes.stepTitle}>{steps[2].title}</div>
        </div> */}
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["planathon"] })(PlanathonDetailsComp);
