import { withAsyncRouteLoading } from "UIComponents";

export default () => {
  return {
    path: "switch-organization",
    component: withAsyncRouteLoading(
      () =>
        new Promise(cb => {
          require.ensure(
            [],
            require => {
              const Component = require("Login/components/OrganizationList")
                .default;
              cb(Component);
            },
            "org-switcher"
          );
        })
    ),
  };
};
