import { withAsyncRouteLoading } from "UIComponents";
import YearGroupConfig from "./routes/YearGroupConfig";
import YearGroupStudents from "./routes/YearGroupStudents";
import TranscriptHistory from "./routes/TranscriptHistory";

export default store => ({
  path: ":yeargroup_id",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "transcript-chunk"
        );
      })
  ),
  childRoutes: [
    YearGroupConfig(store),
    YearGroupStudents(store),
    TranscriptHistory(store),
  ],
});
