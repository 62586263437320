import React, { PureComponent } from "react";
import classes from "./AssessmentTool.scss";
import classNames from "classnames";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import {
  withLoader,
  FullScreenLoader,
  DialogueBox,
  I18nHOC,
  UIButton,
} from "UIComponents";
import RubricTable from "Rubric/components/RubricTable";
import SinglePointRubricTable from "SinglePointRubric/components/SinglePointRubricTable";
import ChecklistTable from "Checklist/components/ChecklistTable";
import Anecdotal from "./Anecdotal";
import MYPObjectiveRubric from "./MYPObjectiveRubric";
import { fontStyle, colors } from "Constants";
import {
  writeAssessmentToolInLocal,
  updateAssessmentTool,
  createAssessmentTool,
  deleteAssessmentTool,
} from "AssessmentToolSetup/modules/AssessmentToolSetupModule";
import { getAssessmentToolNodeDetailsQuery } from "modules/CommonQuery";

import { getOrganizationRubricsQuery } from "Rubric/modules/RubricQuery";
import { getOrganizationChecklistQuery } from "Checklist/modules/ChecklistQuery";
import { getOrganizationSinglePointRubricsQuery } from "SinglePointRubric/modules/SinglePointRubricQuery";
import { getAssessmentToolNodeDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { getOrganizationRubricsFromCache } from "Rubric/modules/RubricGraphqlHelpers";
import { getOrganizationChecklistsFromCache } from "Checklist/modules/ChecklistGraphqlHelpers";
import { getOrganizationSinglePointRubricsFromCache } from "SinglePointRubric/modules/SinglePointRubricGraphqlHelpers";
import { ASSESSMENT_TOOLS, updateField } from "modules/Services";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { LockOverlay } from "UIComponents";
import { createAttachment, deleteAttachment } from "modules/Services";
import {
  AssessmentToolChooseList,
  AssessmentToolPreviewCard,
} from "UnitPlans/components/LibraryCommonComponents";
import Exemplar from "./Exemplar";
import LabelComponent from "UnitPlans/components/LabelComponent";
import { AssessmentToolPreviewModal } from "UnitPlans/components/LibraryCommonComponents";
import { isAssessmentToolPreviewAvailable } from "Utils";
import { getAssessmentToolFilters } from "Administrator/modules/Utils";
import { TableColored } from "@toddle-design/web-icons";

const inputStyle = {
  labelStyle: {
    ...fontStyle.demiBold,
    fontSize: "1.4rem",
    lineHeight: 1.71,
    color: colors.blue29,
  },
};

class AssessmentTool extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isLoading: false,
      showTemplateModal: false,
      showToolList:
        props.mode == "create" &&
        _.get(
          _.find(ASSESSMENT_TOOLS, { type: props.type }),
          "hasTemplate",
          false
        ),
      showPreviewModal: false,
    };

    this.focusedCount = 0;
  }

  UNSAFE_componentWillMount = () => {
    this.checkAndCreateAssessmentTool();
  };

  checkAndCreateAssessmentTool = () => {
    const {
      mode,
      // assessmentId,
      parentId,
      parentType,
      type,
      createAssessmentTool,
      filters,
    } = this.props;

    if (
      mode == "create" &&
      !_.get(_.find(ASSESSMENT_TOOLS, { type: type }), "hasTemplate", false)
    ) {
      const assessmentToolData = {};

      switch (type) {
        //Need to pass selected objectiveIds in the mutation
        case "MYP_OBJECTIVE_RUBRIC": {
          const { options } = this.props;
          const { objectiveField: { value } = {} } = options || {};
          assessmentToolData["objectiveIds"] = value;
          break;
        }
      }
      createAssessmentTool({
        type,
        isGlobal: false,
        // assessmentId,
        parentId,
        parentType,
        assessmentToolData,
        filters,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { assessmentTool: oldassessmentTool } = prevProps;

    const { assessmentTool } = this.props;
    if (assessmentTool != oldassessmentTool) {
      this.updateValueLocally(assessmentTool);
    }
    if (prevProps.subType != this.props.subType) {
      this.checkAndCreateAssessmentTool();
    }
  };

  updateFocusedCount = value => {
    const updatedFocusedCount = this.focusedCount + value;

    if (this.focusedCount == 0 && updatedFocusedCount != 0) {
      this.onFocus();
    } else if (updatedFocusedCount == 0 && this.focusedCount != 0) {
      this.onBlur();
    }
    this.focusedCount = updatedFocusedCount;
  };

  saveAssessmentTool = async title => {
    const {
      toolId,

      type,
      createAssessmentTool,
      filters,
      curriculumProgramId,
    } = this.props;
    this.setState({ isLoading: true });
    await createAssessmentTool({
      copyToolId: toolId,
      title,
      type,
      mode: "COPY",
      filters,
      curriculumProgramId,
    });
    this.setState({ isLoading: false });
  };

  onClickToolSave = () => {
    this.setState({ showTemplateModal: true });
  };

  getPreviewModalBody = assessmentTool => {
    return this.renderTool({ assessmentTool, mode: "view" });
  };

  onCopyTool = async id => {
    const {
      type,
      createAssessmentTool,
      // assessmentId,
      parentId,
      parentType,
      filters,
    } = this.props;
    this.setState({ showToolList: false, isLoading: true });

    await createAssessmentTool({
      copyToolId: id,
      type,
      isGlobal: false,
      mode: "COPY",
      // assessmentId,
      parentId,
      parentType,
      filters,
    });
    this.setState({ showToolList: false, isLoading: false });
  };

  deleteAssessmentTool = ({ id }) => {
    const { deleteAssessmentTool, type, filters } = this.props;
    deleteAssessmentTool({ id, type, filters });
  };

  toggleToolList = () => {
    this.setState({ showToolList: !this.state.showToolList });
  };

  createAssessmentTool = async () => {
    const {
      type,
      createAssessmentTool,
      parentId,
      parentType,
      filters,
    } = this.props;
    this.setState({ isLoading: true });
    await createAssessmentTool({
      type,
      // assessmentId,
      parentId,
      parentType,
      isGlobal: false,
      filters,
    });
    this.setState({ showToolList: false, isLoading: false });
  };

  writeAssessmentToolInLocal = ({ params }) => {
    const { writeAssessmentToolInLocal, type, toolId } = this.props;
    writeAssessmentToolInLocal({ type, id: toolId, params });
  };

  updateAssessmentTool = ({ params, isDebounce }) => {
    const { updateAssessmentTool, type, toolId, updateField } = this.props;
    updateField({
      key: toolId,
      params: {
        id: toolId,
        params,
        type,
      },
      type,
      isDebounce,
      func: updateAssessmentTool,
    });
  };

  renderChildren = () => {
    const { toolList, mode, userId, type, t, assessmentTool } = this.props;
    const {
      showToolList,
      isLoading,
      showTemplateModal,
      showPreviewModal,
    } = this.state;
    return (
      <React.Fragment>
        {showToolList && (
          <AssessmentToolChooseList
            toolList={toolList}
            modal={mode != "create"}
            name={t(
              _.get(_.find(ASSESSMENT_TOOLS, { type: type }), "locale", "")
            )}
            userId={userId}
            onDeleteTool={this.deleteAssessmentTool}
            getPreviewModalBody={this.getPreviewModalBody}
            onCopyTool={this.onCopyTool}
          />
        )}
        {showTemplateModal && (
          <DialogueBox
            modalTitle={`${t("common:save_this_template_with_label", {
              label: t(
                _.get(_.find(ASSESSMENT_TOOLS, { type }), "locale", "")
              ).toLowerCase(),
            })}`}
            showModal={true}
            onClickButton2={title => this.saveAssessmentTool(title)}
            toggleDialogueBoxDisplay={() =>
              this.setState({ showTemplateModal: false })
            }
            button1={t("common:cancel")}
            button2={t("common:add")}
            isPromptBox={true}
            promptDetail={{
              placeholder: t("unitPlan:enter_template_title"),
              label: t("unitPlan:template_title"),
              checkValidation: true,
            }}
          />
        )}

        {showPreviewModal ? (
          <AssessmentToolPreviewModal
            getPreviewModalBody={this.getDialogPreview}
            tool={assessmentTool}
            title={t(_.get(_.find(ASSESSMENT_TOOLS, { type }), "locale", ""))}
            showChooseButton={false}
            onClickClose={() => this.setState({ showPreviewModal: false })}
          />
        ) : null}
        {isLoading && <FullScreenLoader></FullScreenLoader>}
      </React.Fragment>
    );
  };

  shouldShowViewEmpty = () => {
    const { assessmentTool, type } = this.props;
    switch (type) {
      case "RUBRIC":
        return _.get(assessmentTool, "indicators.length", 0) == 0;
      case "CHECKLISt":
        return (
          _.get(
            _.filter(_.get(assessmentTool, "checklistItems", []), item =>
              _.trim(item.label)
            ),
            "length",
            0
          ) == 0
        );
      case "SINGLE_POINT_RUBRIC":
        return _.get(assessmentTool, "standards.length", 0) == 0;
      case "EXEMPLAR": {
        const { message, attachments } = assessmentTool;

        return (
          _.get(message, "length", 0) == 0 &&
          _.get(attachments, "length", 0) == 0
        );
      }
    }
  };

  renderView = () => {
    const { mode, assessmentTool } = this.props;
    return (
      <div className={classes.container}>
        {this.renderTool({ mode, assessmentTool })}
      </div>
    );
  };

  shouldShowEditEmpty = () => {
    const { mode } = this.props;

    return mode == "create";
  };

  renderEditEmpty = () => {
    return null;
  };

  createAttachment = attachment => {
    const { createAttachment, toolId, type } = this.props;
    createAttachment({
      attachment,
      nodeId: toolId,
      type,
      parentType: type,
    });
  };

  deleteAttachment = attachmentId => {
    const { deleteAttachment, toolId, type } = this.props;
    deleteAttachment({
      attachmentId,
      nodeId: toolId,
      type,
    });
  };

  renderTool = ({ mode, assessmentTool }) => {
    const {
      type,
      setScrollToBottom,
      isAssessmentToolPreview,
      options,
      label,
      assessmentToolsConStyle,
      t,
      showAttachmentItemV3,
      horizontalAssessmentToolCardStyle,
    } = this.props;
    if (isAssessmentToolPreview && isAssessmentToolPreviewAvailable({ type })) {
      return showAttachmentItemV3 ? (
        <div
          className={classes.evaluationContentContainer}
          onClick={() => this.setState({ showPreviewModal: true })}
        >
          <div
            className={classes.evaluationContent}
            style={horizontalAssessmentToolCardStyle}
          >
            <div className={classes.evaluationIcon}>
              <TableColored size={"xx-small"} />
            </div>
            <div className={classes.evaluationTitle}>
              {t(_.get(_.find(ASSESSMENT_TOOLS, { type: type }), "locale", ""))}
            </div>
          </div>
        </div>
      ) : (
        <AssessmentToolPreviewCard
          type={type}
          onClick={() => this.setState({ showPreviewModal: true })}
          mode={mode}
          assessmentTool={assessmentTool}
          options={options}
          assessmentToolsConStyle={assessmentToolsConStyle}
        />
      );
    } else {
      switch (type) {
        case "RUBRIC":
          return (
            <RubricTable
              customRef={this.updateInputRef}
              mode={mode}
              rubric={assessmentTool}
              onClickToolSave={this.onClickToolSave}
              setScrollToBottom={setScrollToBottom}
            />
          );
        case "CHECKLIST":
          return (
            <ChecklistTable
              mode={mode}
              customRef={this.updateInputRef}
              checklist={assessmentTool}
              onClickToolSave={this.onClickToolSave}
              setScrollToBottom={setScrollToBottom}
            />
          );
        case "SINGLE_POINT_RUBRIC":
          return (
            <SinglePointRubricTable
              mode={mode}
              customRef={this.updateInputRef}
              rubric={assessmentTool}
              onClickToolSave={this.onClickToolSave}
              setScrollToBottom={setScrollToBottom}
            />
          );
        case "ANECDOTE":
          return (
            <Anecdotal
              {...this.props}
              ref={this.updateInputRef}
              anecdotal={assessmentTool.message}
              onClickToolSave={this.onClickToolSave}
              writeAssessmentToolInLocal={this.writeAssessmentToolInLocal}
              updateAssessmentTool={this.updateAssessmentTool}
            />
          );
        case "MYP_OBJECTIVE_RUBRIC":
          return (
            <MYPObjectiveRubric
              {...this.props}
              ref={this.updateInputRef}
              customRef={this.updateInputRef}
            />
          );
        case "EXEMPLAR":
          return (
            <Exemplar
              {...this.props}
              ref={this.updateInputRef}
              onFocus={this.onFocus}
              onClickToolSave={this.onClickToolSave}
              onBlur={this.onBlur}
              value={assessmentTool.message}
              attachments={assessmentTool.attachments}
              createAttachment={this.createAttachment}
              deleteAttachment={this.deleteAttachment}
              writeAssessmentToolInLocal={this.writeAssessmentToolInLocal}
              updateAssessmentTool={this.updateAssessmentTool}
            />
          );
        default:
          return null;
      }
    }
  };

  getDialogPreview = () => {
    const { type, assessmentTool, options } = this.props;
    switch (type) {
      case "RUBRIC":
        return <RubricTable mode={"view"} rubric={assessmentTool} />;
      case "CHECKLIST":
        return <ChecklistTable mode={"view"} checklist={assessmentTool} />;
      case "SINGLE_POINT_RUBRIC":
        return <SinglePointRubricTable mode={"view"} rubric={assessmentTool} />;
      case "MYP_OBJECTIVE_RUBRIC":
      case "MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC":
        return (
          <MYPObjectiveRubric
            options={options}
            assessmentTool={assessmentTool}
            mode={"view"}
          />
        );
      default:
        return null;
    }
  };

  renderEdit = () => {
    const { mode, assessmentTool, fieldLockedObject, toolId } = this.props;
    const { showToolList } = this.state;
    const { lockedDynamicFields } = fieldLockedObject;
    const lockedField = _.find(lockedDynamicFields, { id: toolId });

    return (
      !showToolList && (
        <div
          className={classes.container}
          onBlur={() => {
            setTimeout(() => this.updateFocusedCount(-1));
          }}
          onFocus={() => {
            this.updateFocusedCount(1);
          }}
        >
          {this.renderTool({ mode, assessmentTool })}

          {lockedField && (
            <LockOverlay userInfo={_.get(lockedField, "userInfo", {})} />
          )}
        </div>
      )
    );
  };
}

const mapActionCreators = {
  writeAssessmentToolInLocal,
  deleteAssessmentTool,
  updateAssessmentTool,
  updateField,
  createAttachment,
  deleteAttachment,
  createAssessmentTool,
};

const mapStateToProps = (state, ownProps) => {
  const toolId = ownProps.assessmentToolId;
  const mode = ownProps.mode == "view" ? "view" : !toolId ? "create" : "edit";

  /*Hack for MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC
    In the backend MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC and MYP_OBJECTIVE_RUBRIC rubirc are same entity which is MYP_OBJECTIVE_RUBRIC
  */
  const type = _.get(
    _.find(ASSESSMENT_TOOLS, { type: ownProps.type }),
    "entityType",
    ""
  );

  const curriculumProgramId = _.get(
    state,
    "platform.currentCurriculumProgram.id",
    null
  );
  const filters = getAssessmentToolFilters({ curriculumProgramId });

  return {
    assessmentId: ownProps.assessmentId
      ? ownProps.assessmentId
      : state.assessment.assessmentData.assessmentId,
    toolId,
    type,
    subType: ownProps.type,
    mode,
    organizationId: state.login.userInfo.org_id,
    userId: state.login.userInfo.id,
    filters,
    curriculumProgramId,
    isData: true,
    isLoading: false,
    portalType: "PLANNER",
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getAssessmentToolNodeDetailsQuery, {
    name: "getAssessmentToolNodeDetails",
    skip: ({ toolId }) => !toolId || _.includes(toolId, "NEW"),
    options: ({ toolId, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: toolId, type },
    }),
    props({
      getAssessmentToolNodeDetails,
      ownProps: { isData, isLoading, toolId, type },
    }) {
      const queryData = getAssessmentToolNodeDetailsFromCache({
        id: toolId,
        type,
      });
      return {
        assessmentTool: queryData,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          getAssessmentToolNodeDetails["networkStatus"] == 1 ||
          getAssessmentToolNodeDetails["networkStatus"] == 2 ||
          getAssessmentToolNodeDetails["networkStatus"] == 4 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationRubricsQuery, {
    name: "getOrganizationRubrics",
    skip: ({ mode, type }) => mode == "view" || type != "RUBRIC",
    options: ({ organizationId, filters, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, filters, portalType },
    }),
    props({
      getOrganizationRubrics,
      ownProps: { organizationId, filters, isData, isLoading },
    }) {
      const organizationDetails = getOrganizationRubricsFromCache({
        id: organizationId,
        filters,
      });
      const toolList = _.get(organizationDetails, "rubrics", []);
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        toolList,
        isLoading:
          getOrganizationRubrics["networkStatus"] == 1 ||
          getOrganizationRubrics["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationChecklistQuery, {
    name: "getOrganizationChecklist",
    skip: ({ mode, type }) => mode == "view" || type != "CHECKLIST",
    options: ({ organizationId, filters, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, filters, portalType },
    }),
    props({
      getOrganizationChecklist,
      ownProps: { organizationId, filters, isData, isLoading },
    }) {
      const organizationDetails = getOrganizationChecklistsFromCache({
        id: organizationId,
        filters,
      });
      const toolList = _.get(organizationDetails, "checklists", []);
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        toolList,
        isLoading:
          getOrganizationChecklist["networkStatus"] == 1 ||
          getOrganizationChecklist["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationSinglePointRubricsQuery, {
    name: "getOrganizationSinglePointRubrics",
    skip: ({ mode, type }) => mode == "view" || type != "SINGLE_POINT_RUBRIC",
    options: ({ organizationId, filters, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, filters, portalType },
    }),
    props({
      getOrganizationSinglePointRubrics,
      ownProps: { organizationId, filters, isData, isLoading },
    }) {
      const organizationDetails = getOrganizationSinglePointRubricsFromCache({
        id: organizationId,
        filters,
      });
      const toolList = _.get(organizationDetails, "singlePointRubrics", []);
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        toolList,
        isLoading:
          getOrganizationSinglePointRubrics["networkStatus"] == 1 ||
          getOrganizationSinglePointRubrics["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(AssessmentTool);
