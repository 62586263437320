import React, { Component } from "react";
import classes from "./HelperModal.scss";
import { UIButton } from "UIComponents";
const HelperModal = props => {
  return (
    <div className={classes.container}>
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>{"More Information"}</div>
        <div
          className={classes.modalBody}
          dangerouslySetInnerHTML={{ __html: props.helperText }}
        />
        <div className={classes.modalFooter}>
          <UIButton color="blue" onClick={props.onClose}>{`Close`}</UIButton>
        </div>
      </div>
    </div>
  );
};

export default HelperModal;
