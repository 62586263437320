import React from "react";
import classes from "./DropDown.scss";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
import { useTranslation } from "react-i18next";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { getSettingValue } from "modules/PermissionModule";
import { getIsCurrentAcademicYear } from "Platform/modules/PlatformModule";
import { Dropdown, DropdownMenu, IconButton } from "@toddle-design/web";
import { isJournalCardRevamp } from "Post/utils";
import { MoreVerticalDotsOutlined } from "@toddle-design/web-icons";
import classNames from "classnames";

const buttonDropDownContainerStyle = {
  marginTop: 4,
  minWidth: 148,
};

const buttonDropDownItemStyle = {
  height: 40,
};

const DropDown = React.memo(props => {
  const {
    id,
    createdByType,
    onPostActionClick,
    userType,
    canStudentEditPostOncePublished,
    getIsCurrentAcademicYear,
    isArchivedClass,
    isAddLEToJournal,
  } = props;
  const isCurrentAcademicYear = getIsCurrentAcademicYear();
  const { t } = useTranslation("common");
  const isCreatedByStudent = createdByType == "student";
  const teacherMenuItems = [
    {
      key: "IN_APPROVAL",
      label: isCreatedByStudent ? t("move_to_in_approval") : t("move_to_draft"),
      clickAction: () => {
        onPostActionClick({
          id: id,
          action: isCreatedByStudent ? "IN_APPROVAL" : "DRAFT",
        });
      },
      svg: null,
      isDisable: isArchivedClass,
    },
    {
      key: "POST_EDIT",
      label: t("edit"),
      clickAction: () => {
        onPostActionClick({
          id: id,
          action: "POST_EDIT",
          isAddLEToJournal: isAddLEToJournal,
        });
      },
      svg: null,
      isDisable: false,
    },
    {
      key: "POST_DELETE",
      label: t("delete"),
      clickAction: () => {
        onPostActionClick({ id: id, action: "DELETE" });
      },
      svg: null,
      isDisable: isArchivedClass,
      isDestructive: true,
    },
  ];

  const studentMenuItems = [
    {
      key: "POST_EDIT",
      label: t("edit"),
      clickAction: () => {
        onPostActionClick({
          id: id,
          action: "POST_EDIT",
          isAddLEToJournal: isAddLEToJournal,
        });
      },
      svg: null,
      isDisable: false,
    },
  ];

  if (
    userType === "student" &&
    (!canStudentEditPostOncePublished || !isCurrentAcademicYear)
  ) {
    return null;
  }

  const onMenuItemClick = menuItem => {
    menuItem?.domEvent?.stopPropagation();
    const selectedMenuData =
      userType == "student" ? studentMenuItems : teacherMenuItems;
    selectedMenuData.find(item => item.key == menuItem.key).clickAction();
  };

  const dropdownClass = classNames({
    [classes.dropdown]: !isJournalCardRevamp(),
  });
  return (
    <div className={classes.container}>
      {/* Show different dropdown items based on user type */}
      <Dropdown
        className={dropdownClass}
        onClick={e => e.stopPropagation()}
        overlay={
          <DropdownMenu
            onClick={onMenuItemClick}
            options={
              userType == "student" ? studentMenuItems : teacherMenuItems
            }
          />
        }
      >
        <IconButton
          icon={
            isJournalCardRevamp() ? <MoreVerticalDotsOutlined /> : <MoreIcon />
          }
          size={isJournalCardRevamp() ? "medium" : "small"}
          variant={"plain"}
        />
      </Dropdown>
    </div>
  );
});

const mapStateToProps = state => {
  const canStudentEditPostOncePublished = getSettingValue({
    name: "EnablePostEditingOncePublishedByStudents",
    courseId: state.teacher.selected_class.selected_course,
    organizationId: state.login.userInfo.org_id,
    userId: state.login.userInfo.id,
    userEntityType: state.login.userInfo.userEntityType,
  });
  return {
    canStudentEditPostOncePublished,
  };
};

const mapActionCreators = {
  getIsCurrentAcademicYear,
};

export default compose(connect(mapStateToProps, mapActionCreators))(DropDown);
