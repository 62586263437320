import React from "react";
import PropTypes from "prop-types";

const LangAcquisitionSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <path
        d="M2.5 6.5C2.5 4.29086 4.29086 2.5 6.5 2.5H25.5C27.7091 2.5 29.5 4.29086 29.5 6.5V21.5C29.5 23.7091 27.7091 25.5 25.5 25.5H18.5L16.424 28.8216C16.2282 29.1349 15.7718 29.1349 15.576 28.8216L13.7938 25.97C13.611 25.6776 13.2905 25.5 12.9458 25.5H6.5C4.29086 25.5 2.5 23.7091 2.5 21.5V6.5Z"
        fill="white"
        stroke="#FFAB00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9998 22.8999C20.6665 22.8999 24.4497 19.1167 24.4497 14.45C24.4497 9.78317 20.6665 6 15.9998 6C11.333 6 7.5498 9.78317 7.5498 14.45C7.5498 19.1167 11.333 22.8999 15.9998 22.8999Z"
        fill="#FFE6AA"
        stroke="#FFAB00"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0004 22.8999C17.8671 22.8999 19.3803 19.1167 19.3803 14.45C19.3803 9.78317 17.8671 6 16.0004 6C14.1336 6 12.6204 9.78317 12.6204 14.45C12.6204 19.1167 14.1336 22.8999 16.0004 22.8999Z"
        fill="#FFE6AA"
        stroke="#FFAB00"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75708 10.8889H23.2427"
        stroke="#FFAB00"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2427 18.1318L8.75708 18.1318"
        stroke="#FFAB00"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LangAcquisitionSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

LangAcquisitionSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default LangAcquisitionSGIcon;
