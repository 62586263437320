import React from "react";
import classes from "./CourseItem.scss";
import { ProfileCascade, MoreItemWithToolTip, UIButton } from "UIComponents";
import { TickPlain } from "SvgComponents";
import { colors } from "Constants";
import { useTranslation } from "react-i18next";

const profileCascadeStyle = {
  customStyle: {
    borderWidth: 0,
    width: 32,
    height: 32,
  },
};

const getCollaboratorList = collaborators => {
  return _.map(collaborators, item => {
    const { firstName, email } = item;
    return !!firstName ? firstName : email;
  });
};

const CourseItem = ({
  item,
  isSelected,
  onAddClick,
  collaborators,
  searchType,
}) => {
  const { id, title, profileImage } = item;
  const { t } = useTranslation(["unitPlan", "common"]);
  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <ProfileCascade
          items={[{ id, value: title, imageUrl: profileImage }]}
          customStyle={profileCascadeStyle.customStyle}
        />
        <div className={classes.titleNamecontainer}>
          <div className={classes.titleText}>{title}</div>
          <div className={classes.nameText}>
            <MoreItemWithToolTip
              items={getCollaboratorList(collaborators)}
              length={2}
              noItemText={t("common:no_with_label", {
                label: t(`common:${searchType}`),
              })}
            />
          </div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        {isSelected ? (
          <div className={classes.addedContainer}>
            <TickPlain fill={colors.blue29} width={14} height={14} />
            <div className={classes.addedText}>{t("common:added")}</div>
          </div>
        ) : (
          <UIButton
            color="blue"
            onClick={e => onAddClick(e, collaborators)}
            size="sm"
            type="hollow"
          >
            {t("common:add")}
          </UIButton>
        )}
      </div>
    </div>
  );
};

export default CourseItem;
