import React from "react";

const Grade2Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M26.5073,44.5576 C23.2603,44.5576 22.8113,42.7266 22.8113,41.7936 C22.8113,40.8266 23.0533,39.8596 24.7793,38.2706 L32.6913,31.0846 C33.9003,29.9796 34.6953,28.8046 34.6953,27.3196 C34.6953,25.5916 33.4863,24.3826 31.6543,24.3826 C30.2733,24.3826 29.2713,25.1086 28.6493,26.2136 C28.0963,27.2156 27.1293,28.1136 25.9203,28.1136 C24.3653,28.1136 23.2943,26.9396 23.2943,25.4886 C23.2943,24.9016 23.5363,24.2096 23.7783,23.7956 C25.3673,21.1006 28.4073,19.4426 31.9653,19.4426 C36.4573,19.4426 40.8083,21.8266 40.8083,26.9396 C40.8083,30.4286 38.7713,32.4666 36.5253,34.4706 L30.8603,39.5136 L38.3903,39.5136 C40.8083,39.5136 41.1893,41.2066 41.1893,42.0366 C41.1893,42.8656 40.8083,44.5576 38.3903,44.5576 L26.5073,44.5576 Z"></path>
      </g>
    </svg>
  );
};

Grade2Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default Grade2Icon;
