import React from "react";
import PropTypes from "prop-types";

const PlayIcon = props => {
  return (
    <svg
      viewBox="0 0 12 16"
      width={props.width}
      height={props.height}
      style={props.style}
    >
      <g id="Audio-Files-&amp;-Audio-Notes" stroke="none">
        <path
          d="M0.777350098,0.518233399 L10.7519246,7.16794971 C11.2114532,7.47430216 11.3356271,8.09517151 11.0292747,8.5547002 C10.9560398,8.66455248 10.8617768,8.75881544 10.7519246,8.83205029 L0.777350098,15.4817666 C0.547585754,15.6349428 0.237151082,15.5728559 0.0839748528,15.3430916 C0.0292190137,15.2609578 1.20887938e-17,15.1644539 0,15.0657415 L0,0.934258546 C7.72046149e-17,0.658116171 0.223857625,0.434258546 0.5,0.434258546 C0.598712493,0.434258546 0.695216339,0.46347756 0.777350098,0.518233399 Z"
          id="Rectangle"
          fill={props.fill && props.fill}
        ></path>
      </g>
    </svg>
  );
};

PlayIcon.defaultProps = {
  height: 16,
  width: 16,
};
PlayIcon.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
};
export default PlayIcon;
