/**--external-- */
import React, { useState } from "react";
import classNames from "classnames";
import { CaretDownFilled } from "@toddle-design/web-icons";

/**--internal -- */
import { CheckListContainer, AnimateHeight } from "UIComponents";
import { SearchBarContainer } from "AppComponents";

/**--relative-- */
import { filterSearchStyle, listItemStyle } from "./FilterListDropdownStyles";
import classes from "./FilterListDropdown.scss";
import { getMatchingOptions } from "./utils";

const Filter = props => {
  const {
    filterListLabel,
    values,
    options,
    getAllOptionLabelForFilter,
    isExpanded,
    searchText,
    expandFilter,
    updateInputField,
    label,
  } = props;

  const labelContainerClassList = classNames(
    {
      [classes.nestedFilterLabelContainer]: true,
    },
    {
      [classes.nestedFilterLabelContainerWithOption]: isExpanded,
    }
  );

  return (
    <div className={classes.nestedFilterLabelWithOptionContainer}>
      <div className={labelContainerClassList} onClick={expandFilter}>
        <div className={classes.nestedFilterLabel}>{label}</div>
        {!_.isEmpty(values) && (
          <div className={classes.selectedOptionsCount}>{_.size(values)}</div>
        )}
        <div>
          <CaretDownFilled variant={"default"} size={"xx-small"} />
        </div>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? "auto" : 0}>
        <CheckListContainer
          value={values}
          options={options}
          showAllOption={!searchText}
          listItemStyle={listItemStyle}
          updateInputField={updateInputField}
          allOptionLabel={getAllOptionLabelForFilter({
            label1: label,
            label2: filterListLabel,
          })}
          isMatchingTextEnabled={true}
          isSearchTextInState={false}
          searchText={searchText}
        />
      </AnimateHeight>
    </div>
  );
};

const FilterListDropdown = ({
  optionList,
  updateFilterList,
  valueList,
  getAllOptionLabelForFilter,
  getSearchPlaceholder,
  label: filterListLabel,
}) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(-1);
  const [searchText, setSearchText] = useState("");

  const updateActiveIndex = index => {
    if (index === activeFilterIndex) setActiveFilterIndex(-1);
    else setActiveFilterIndex(index);
  };

  const updateInputField = ({ values, listItemKey }) => {
    const filteredValueList = _.filter(
      valueList,
      ({ key }) => key !== listItemKey
    );
    const updatedValueList = [
      ...filteredValueList,
      { key: listItemKey, values },
    ];
    updateFilterList({
      updatedValueList,
    });
  };

  return (
    <div className={classes.nestedFilterContainer}>
      <SearchBarContainer
        searchTerm={searchText}
        changeSearchTerm={val => {
          setSearchText(val);
        }}
        {...filterSearchStyle}
        placeholder={getSearchPlaceholder({ label: filterListLabel })}
      />
      {_.map(optionList, ({ key, label, options }, index) => {
        const currentFilterValues = _.get(
          _.find(valueList, ["key", key]),
          "values",
          []
        );

        const matchingOptions = getMatchingOptions({
          options,
          searchText,
        });

        return !_.isEmpty(matchingOptions) ? (
          <Filter
            filterListLabel={filterListLabel}
            label={label}
            searchText={searchText}
            options={matchingOptions}
            updateInputField={values =>
              updateInputField({ values, listItemKey: key })
            }
            getAllOptionLabelForFilter={getAllOptionLabelForFilter}
            values={currentFilterValues}
            expandFilter={() => updateActiveIndex(index)}
            isExpanded={index === activeFilterIndex || !!_.size(searchText)}
          />
        ) : null;
      })}
    </div>
  );
};

export default FilterListDropdown;
