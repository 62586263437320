import React from "react";
import classes from "./PostDetailComp.scss";
import { graphql, compose } from "react-apollo";
import { withLoader, MediaModal, UIButton } from "UIComponents";
import { getPostDetailQuery } from "Post/modules/PostQuery";
import { getPostDetailsFromCache } from "Post/modules/PostGraphqlHelpers";
import {
  createPostLike,
  deletePostLike,
  updatePostStatus,
} from "Journal/modules/JournalModule";
import Details from "../Details";
import { connect } from "react-redux";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  checkPostDetailsVisibilityPermission,
  isJournalCardRevamp,
  hasTranslationPermission,
  getPostTranslationFilter,
} from "Post/utils";
import { colors } from "Constants";
import { CommentRemarkOutlined } from "@toddle-design/web-icons";
import ACLStore from "lib/aclStore";
import classNames from "classnames";

const ModelContent = ({
  attachments,
  toggleMediaModal,
  showMediaModal,
  activeIndex,
  toggleDownloadBeginOverlay,
}) => {
  return showMediaModal ? (
    <MediaModal
      toggleMediaModal={toggleMediaModal}
      attachments={attachments}
      activeIndex={activeIndex}
      toggleDownloadBeginOverlay={toggleDownloadBeginOverlay}
    />
  ) : null;
};

const RejectComment = ({ rejectComment }) => {
  return !_.isEmpty(rejectComment) ? (
    <div className={classes.rejectCommentContainer}>
      <div className={classes.rejectCommentIcon}>
        <CommentRemarkOutlined />
      </div>
      <span className={classes.rejectCommentText}>{rejectComment}</span>
    </div>
  ) : null;
};

class PostDetailComp extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMediaModal: false,
      attachments: [],
      showDownloadBeginOverlay: false,
    };
    this.containerDiv = React.createRef();
  }

  componentDidMount = () => {
    const { detail = {}, setToastMsg, onCloseClick, userInfo } = this.props;
    const { isDeleted } = detail;

    // Check whether a user has permission to see the post details
    const hasPermission = checkPostDetailsVisibilityPermission({
      userInfo,
      postDetails: detail,
    });

    if (!hasPermission) {
      onCloseClick();
      setToastMsg("toastMsgs:not_part_of_post");
    }

    //Handled if post is deleted
    if (isDeleted) {
      onCloseClick();
      setToastMsg("toastMsgs:trying_to_access_deleted_post");
    }
  };

  componentDidUpdate = prevProps => {
    const { detail = {}, setToastMsg, onCloseClick, userInfo } = this.props;
    const { isDeleted } = detail;

    // Check permission on previous data
    const prevHasPermission = checkPostDetailsVisibilityPermission({
      userInfo,
      postDetails: prevProps.detail,
    });

    // Check whether a user has permission to see the post details
    const hasPermission = checkPostDetailsVisibilityPermission({
      userInfo,
      postDetails: detail,
    });

    // Close only if new data has different permission than previous one
    if (prevHasPermission != hasPermission && !hasPermission) {
      onCloseClick();
      setToastMsg("toastMsgs:not_part_of_post");
    }

    //Handled if post is deleted and data is already in cache
    if (_.get(prevProps, "detail.isDeleted", false) != isDeleted && isDeleted) {
      onCloseClick();
      setToastMsg("toastMsgs:trying_to_access_deleted_post");
    }
  };

  goToScrollTop = () => {
    if (this.containerDiv.current) {
      this.containerDiv.current.scrollTop = 0;
    }
  };

  toggleMediaModal = (value, activeIndex) => {
    const {
      detail: { attachments },
    } = this.props;

    this.setState({
      showMediaModal: value,
      attachments: value ? attachments : [],
      activeIndex: activeIndex,
    });
  };

  toggleDownloadBeginOverlay = () => {
    this.setState({ showDownloadBeginOverlay: true });
  };

  onPostLikedClick = ({ isLiked }) => {
    const { createPostLike, deletePostLike, detail, postId } = this.props;

    if (isLiked) {
      deletePostLike({
        postId,
        postDetails: detail,
        fragmentName: "journalPostDetail",
      });
    } else {
      createPostLike({
        postId,
        postDetails: detail,
        fragmentName: "journalPostDetail",
      });
    }
  };

  onPostActionClick = ({ id, action, params }) => {
    const { mode = "edit" } = this.props;
    switch (action) {
      case "LIKE":
        if (mode == "edit") {
          this.onPostLikedClick({ id, isLiked: params.isLiked });
        }
        break;
      case "COMMENT":
        this.props.toggleCommentBox();
        break;
      case "PUBLISH":
      case "APPROVE":
        this.props.updatePostStatus(id, "PUBLISHED");
        if (this.props?.onCloseClick) this.props.onCloseClick();
        break;
    }
  };

  render() {
    const {
      postId,
      onCloseClick,
      detail = {},
      showLoaderBar,
      fullMedia,
      grades,
      taggedStudents,
      userInfo: { id: userId },
      toggleCommentBox,
      toggleLEPreview,
      onClickEditPost,
      detailsContainerStyle,
      showCloseBtn,
      mediaMaxWidth,
      mediaMaxHeight,
      attachmentContainerStyle,
      hideLike,
      hideComments,
      hideLearninGoals,
      hideUnitDetails,
      showTaggedFolders,
      courseIdFromQuery,
      portfolioStudentId,
      hideTaggedStudents,
      showPracticeStatus,
      isSidePaneContent = true,
      isViewFromPostPage = false,
    } = this.props;
    const {
      attachments,
      showMediaModal,
      activeIndex,
      showDownloadBeginOverlay,
    } = this.state;
    const state = _.get(detail, "state", "");
    const isPostApproveRejectCommentPermission = ACLStore.can(
      "FeatureFlag:NewApprovalFlowOfPost"
    );
    const isPostApproveRejectEnabled =
      _.isEqual(state, "REJECTED") && isPostApproveRejectCommentPermission;
    const rejectComment = _.get(detail, "rejectComment", "");

    const detailsClass = classNames({
      [classes.details]: !isJournalCardRevamp(),
      [classes.detailsV2]: isJournalCardRevamp(),
    });
    return [
      <div
        className={classes.container}
        ref={this.containerDiv}
        key="containerDiv"
      >
        <div className={classes.scrollContainer}>
          {isPostApproveRejectEnabled && (
            <RejectComment rejectComment={rejectComment} />
          )}
          <div className={detailsClass}>
            <Details
              detailsContainerStyle={detailsContainerStyle}
              postId={postId}
              onCloseClick={onCloseClick}
              showCloseBtn={showCloseBtn}
              detail={detail}
              toggleMediaModal={this.toggleMediaModal}
              showLoaderBar={showLoaderBar}
              fullMedia={fullMedia}
              grades={grades}
              taggedStudents={taggedStudents}
              onPostActionClick={this.onPostActionClick}
              userId={userId}
              toggleCommentBox={toggleCommentBox}
              toggleLEPreview={toggleLEPreview}
              mediaMaxWidth={mediaMaxWidth}
              mediaMaxHeight={mediaMaxHeight}
              attachmentContainerStyle={attachmentContainerStyle}
              hideLike={hideLike}
              hideComments={hideComments}
              hideLearninGoals={hideLearninGoals}
              hideUnitDetails={hideUnitDetails}
              hideTaggedStudents={hideTaggedStudents || isJournalCardRevamp()}
              showTaggedFolders={showTaggedFolders}
              courseIdFromQuery={courseIdFromQuery}
              portfolioStudentId={portfolioStudentId}
              showPracticeStatus={showPracticeStatus}
              showDownloadBeginOverlay={showDownloadBeginOverlay}
              isSidePaneContent={isSidePaneContent}
              isViewFromPostPage={isViewFromPostPage}
            />
          </div>
          <ModelContent
            attachments={attachments}
            toggleMediaModal={this.toggleMediaModal}
            showMediaModal={showMediaModal}
            activeIndex={activeIndex}
            toggleDownloadBeginOverlay={this.toggleDownloadBeginOverlay}
          />
        </div>
        {onClickEditPost && (
          <div className={classes.footer}>
            <UIButton
              color={"pink"}
              onClick={onClickEditPost}
              size={"lg"}
              containerStyle={{ width: "100%" }}
            >{`Edit post`}</UIButton>
          </div>
        )}
      </div>,
    ];
  }
}

const mapActionCreators = {
  createPostLike,
  deletePostLike,
  updatePostStatus,
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  const translationFilter = getPostTranslationFilter({
    userInfo: _.get(state, "login.userInfo", {}),
    shouldTranslate: false,
    handleTranslation: hasTranslationPermission(),
  });
  return {
    isData: true,
    isLoading: false,
    showLoaderBar: false,
    portfolioStudentId: ownProps?.portfolioStudentId,
    userInfo: state.login.userInfo,
    translationFilter,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getPostDetailQuery, {
    name: "getPostDetail",
    options: ({ postId, translationFilter }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: postId,
        translationFilter: translationFilter,
      },
    }),
    props({
      getPostDetail,
      ownProps: { postId, translationFilter, isData, isLoading },
    }) {
      const postDetails = getPostDetailsFromCache({
        postId,
        translationFilter,
      });
      const subjects = _.get(postDetails, "unitPlan.subjects.value", []);

      return {
        subjects,
        taggedStudents: _.get(postDetails, "students.edges", []),
        isData: !_.isEmpty(postDetails) && isData,
        networkStatus: getPostDetail.networkStatus,
        isLoading:
          getPostDetail["networkStatus"] == 1 ||
          getPostDetail["networkStatus"] == 2 ||
          getPostDetail["networkStatus"] == 4 ||
          isLoading,
        detail: postDetails,
      };
    },
  }),
  withLoader
)(PostDetailComp);
