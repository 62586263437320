import React from "react";

// Components
import CustomDropdown from "../CustomDropdown";

// Services
import { ASSESSMENT_TOOLS } from "modules/Services";

// Module
import { updateAssessmentInRedux } from "Assessments/modules/AssessmentModule";

// External libs.
import { connect } from "react-redux";

const handleDropdownMenuChange = props => {
  const { e, params, showAssessmentToolModal, updateAssessmentInRedux } = props;
  const assessmentToolModalMode = _.get(e, "key");

  updateAssessmentInRedux({
    assessmentToolModalMode,
  });

  const toggleAssessmentToolModal = () => {
    updateAssessmentInRedux({
      showAssessmentToolModal: !showAssessmentToolModal,
    });
  };

  if (_.isEqual(assessmentToolModalMode, "use_from_template")) {
    toggleAssessmentToolModal();
  } else {
    const type = _.get(params, "measureAssessing");
    const tool = _.find(ASSESSMENT_TOOLS, tool => tool.type == type);

    if (tool) {
      updateAssessmentInRedux({
        assessmentToolModalMode: "create_new",
      });
      toggleAssessmentToolModal();
    }
  }
};

const MeasureAssessingWrapper = props => {
  const { mode = "view", assessmentToolId } = props;

  const onChangeDropDownMenuOption = params =>
    handleDropdownMenuChange({ ...params, ...props });

  return (
    <>
      {mode !== "view" && (
        <CustomDropdown
          {...props}
          onChangeDropDownMenuOption={onChangeDropDownMenuOption}
          isDisabled={!_.isEmpty(assessmentToolId)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { showAssessmentToolModal } = state.assessment.assessmentData;
  return {
    showAssessmentToolModal,
  };
};

const mapActionCreators = {
  updateAssessmentInRedux,
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(MeasureAssessingWrapper);
