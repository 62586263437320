import React, { useState } from "react";
import classes from "./ProgressReportTemplateEditor.scss";
import { withLoader, SelectDropdown, UIButton } from "UIComponents";
import { goToBack } from "modules/NavigationModule";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { getProgressReportBaseTemplateListQuery } from "Tooling/modules/ToolingQueries";
import { updateBaseProgressReportTemplate } from "Tooling/modules/ToolingModule";
import { getProgressReportBaseTemplateListFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
import JsonEditorContainer from "./components/JsonEditorContainer";
import { withPermission } from "lib/PermissionAware";
import { FullScreenLoader } from "UIComponents";

const styles = {
  changeEditModeButton: {
    borderRadius: "0",
    margin: "32px 16px 0px",
  },
  exitButton: {
    borderRadius: "0",
    margin: "32px 0px 0px 16px",
  },
};

const getTemplateValues = allBasePRTemplateList => {
  return _.reduce(
    allBasePRTemplateList,
    (result, template) => {
      result.push({
        value: template.id,
        label: `${template.id}-${template.name}`,
      });
      return result;
    },
    []
  );
};

const ProgressReportTemplateEditor = props => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editorMode, setEditorMode] = useState("tree");
  const [renderEditor, setRenderEditor] = useState(true);
  const [templateData, setTemplateData] = useState({});
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const [isFullScreenLoader, setIsFullScreenLoader] = useState(false);
  const { allBasePRTemplateList, updateBaseProgressReportTemplate } = props;

  const changeEditModeButtonText =
    editorMode == "tree" ? "Edit as Text" : "Edit as Json";

  const changeEditMode = () => {
    setEditorMode(editorMode == "tree" ? "code" : "tree");
    setRenderEditor(false);
  };

  const handleDropDownSelection = id => {
    setSelectedTemplate(id.uid);
  };

  const updateTemplate = async () => {
    setIsFullScreenLoader(true);
    await updateBaseProgressReportTemplate(
      selectedTemplate,
      JSON.stringify(templateData)
    );
    setIsFullScreenLoader(false);
  };

  return (
    <React.Fragment>
      <div className={classes.body}>
        <div className={classes.header}>
          <div className={classes.dropDownContainer}>
            <SelectDropdown
              value={selectedTemplate}
              label={"Select a template"}
              name="uid"
              areOptionsEmptyAllowed={false}
              mode={"new"}
              placeholder="Name of Template"
              options={getTemplateValues(allBasePRTemplateList)}
              updateInputField={handleDropDownSelection}
              error="Unit name is Required"
              isDisabled={false}
            />
          </div>
          <div className={classes.buttonContainer}>
            <UIButton
              color="blue"
              containerStyle={styles.changeEditModeButton}
              onClick={changeEditMode}
            >
              {changeEditModeButtonText}
            </UIButton>
            <UIButton
              color="blue"
              isDisabled={disableUpdateButton}
              containerStyle={styles.changeEditModeButton}
              onClick={updateTemplate}
            >
              {"Update"}
            </UIButton>
            <UIButton
              color="blue"
              containerStyle={styles.exitButton}
              onClick={props.goToBack}
            >
              {"Exit"}
            </UIButton>
          </div>
        </div>
        <JsonEditorContainer
          templateData={templateData}
          setTemplateData={setTemplateData}
          renderEditor={renderEditor}
          setRenderEditor={setRenderEditor}
          editorMode={editorMode}
          selectedTemplate={selectedTemplate}
          setDisableUpdateButton={setDisableUpdateButton}
        />
      </div>
      {isFullScreenLoader ? <FullScreenLoader /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    perm: "ToolPortal:ManageTemplates",
  };
};

const mapActionCreators = {
  goToBack,
  updateBaseProgressReportTemplate,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getProgressReportBaseTemplateListQuery, {
    name: "getAllBaseProgressReportList",
    options: () => ({
      fetchPolicy: "cache-and-network",
      variables: {},
    }),
    props({ getAllBaseProgressReportList, ownProps: { isLoading, isData } }) {
      const allBasePRTemplateList = getProgressReportBaseTemplateListFromCache();
      return {
        allBasePRTemplateList,
        isData: !_.isEmpty(allBasePRTemplateList) && isData,
        isLoading:
          getAllBaseProgressReportList["networkStatus"] == 1 ||
          getAllBaseProgressReportList["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader,
  withPermission
)(ProgressReportTemplateEditor);
