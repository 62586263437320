import React from "react";
import classes from "./DeleteDialogueBox.scss";
import {
  UIModal,
  DialogueBox,
  UIButton,
  FullScreenLoader,
  CheckListContainer,
  I18nHOC,
} from "UIComponents";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { getBenchmarkAnalyticsQuery } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorQueries";
import { fontStyle } from "Constants";
import { colors } from "Constants";
import { Trans } from "react-i18next";

const CheckListContainerStyle = {
  listItemStyle: { flexBasis: "50%" },
  checkBoxStyles: { width: "24px", height: "24px" },
  checkBoxParentContainer: { alignItems: "center", padding: "0" },
  CheckBoxLabelStyles: {
    ...fontStyle.medium,
    color: colors.gray13,
    fontSize: "1.6rem",
  },
};
class DeleteDialogueBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedAnalytics: [] };
  }

  getTitle = () => {
    const { action, levelName, t } = this.props;
    let title = "";
    switch (action) {
      case "DELETE_GRADE":
        title = t("common:remove_with_label", {
          label: this.getGradeNames(),
        });

        break;
      case "DELETE_ITEM":
        title = t("common:delete_with_label", { label: levelName });
        break;
    }
    return title;
  };

  getGradeNames = () => {
    const { gradesToBeRemoved, t } = this.props;
    return t("common:join_array_with_and", {
      array: _.map(gradesToBeRemoved, item => item.name),
    });
  };

  getModalMessageBody = () => {
    const {
      action,
      itemToDelete: { label = "" } = {},
      analytics,
      t,
    } = this.props;
    let messageBody = "";
    const deleteString = this.getDeleteString();
    const analyticsLength = _.get(analytics, "length", 0);
    const gradeNames = this.getGradeNames();
    const subStringLabel =
      label.length > 40 && !!label ? `${label.substring(0, 40)}...` : label;
    switch (action) {
      case "DELETE_GRADE":
        messageBody = (
          <span>
            {analyticsLength == 0 ? (
              deleteString ? (
                <Trans i18nKey="academicSetup:confirm_delete_grade_msg">
                  Are you sure you want to remove
                  <span className={classes.labelText}>
                    ‘{{ subStringLabel }}’
                  </span>
                  from {{ gradeNames }}? Its {{ deleteString }} will also be
                  removed from {{ gradeNames }}. This action cannot be undone.
                </Trans>
              ) : (
                <Trans i18nKey="academicSetup:confirm_delete_grade_msg_without_delete_string">
                  Are you sure you want to remove
                  <span className={classes.labelText}>
                    ‘{{ subStringLabel }}’
                  </span>
                  from {{ gradeNames }}? This action cannot be undone.
                </Trans>
              )
            ) : (
              <Trans i18nKey="academicSetup:confirm_delete_grade_msg_without_delete_string">
                Are you sure you want to remove
                <span className={classes.labelText}>
                  ‘{{ subStringLabel }}’
                </span>
                from {{ gradeNames }}? This action cannot be undone.
              </Trans>
            )}
          </span>
        );
        break;
      case "DELETE_ITEM":
        messageBody = (
          <span>
            {analyticsLength == 0 ? (
              deleteString ? (
                <Trans i18nKey="academicSetup:confirm_delete_item_msg">
                  Are you sure you want to delete
                  <span className={classes.labelText}>
                    ‘{{ subStringLabel }}’
                  </span>
                  ? Its {{ deleteString }} will also be deleted. This action
                  cannot be undone.
                </Trans>
              ) : (
                <Trans i18nKey="academicSetup:confirm_delete_item_msg_without_delete_string">
                  Are you sure you want to delete
                  <span className={classes.labelText}>
                    ‘{{ subStringLabel }}’
                  </span>
                  ? This action cannot be undone.
                </Trans>
              )
            ) : (
              <Trans i18nKey="academicSetup:confirm_delete_item_msg_without_delete_string">
                Are you sure you want to delete
                <span className={classes.labelText}>
                  ‘{{ subStringLabel }}’
                </span>
                ? This action cannot be undone.
              </Trans>
            )}
          </span>
        );

        break;
    }
    return messageBody;
  };

  getCancelButtonName = () => {
    const { action, t } = this.props;
    let buttonName = t("common:agree_cancel");
    switch (action) {
      case "DELETE_GRADE":
        buttonName = t("common:agree_cancel_label", {
          label: t("common:grade"),
        });
        break;
      case "DELETE_ITEM":
        buttonName = t("common:agree_cancel");
        break;
    }
    return buttonName;
  };

  getConfirmButtonName = () => {
    const { action, t } = this.props;
    let buttonName = t("common:delete_agree");
    switch (action) {
      case "DELETE_GRADE":
        buttonName = t("common:remove_agree_label", {
          label: t("common:grade"),
        });
        break;
      case "DELETE_ITEM":
        buttonName = t("common:delete_agree");
        break;
    }
    return buttonName;
  };

  getCheckBoxHeaderText = () => {
    const { action, analytics, t } = this.props;
    let messageText = "";
    const gradeNames = this.getGradeNames();
    const analyticsLength = _.get(analytics, "length", 0);
    const deleteString = this.getDeleteString();
    switch (action) {
      case "DELETE_GRADE":
        messageText = `${
          deleteString
            ? t("academicSetup:remove_grade_from_gradeNames", {
                deleteString,
                gradeNames,
              })
            : t(
                "academicSetup:remove_grade_from_gradeNames_without_delete_string_and_analytics"
              )
        }${
          analyticsLength > 0
            ? t(
                "academicSetup:remove_grade_from_gradeNames_without_delete_string"
              )
            : ``
        }`;
        break;
      case "DELETE_ITEM":
        messageText = `${
          deleteString
            ? t("academicSetup:remove_item_with_delete_string", {
                deleteString,
              })
            : ``
        } ${
          analyticsLength > 0
            ? t("academicSetup:remove_item_without_delete_string")
            : ``
        }`;

        break;
    }
    return messageText;
  };

  getDeleteString = () => {
    const { levels, levelIdIndex, nodes, itemToDelete } = this.props;
    let deleteString = "";

    if (levelIdIndex == levels.length - 1) {
      return deleteString;
    }

    const array = _.range(levelIdIndex + 1, levels.length);

    const childCount = _.filter(
      nodes,
      node =>
        node.levelId == _.get(levels[levelIdIndex + 1], "id", "") &&
        node.parent == itemToDelete.id
    ).length;

    if (childCount == 0) {
      return deleteString;
    }

    _.forEach(array, (item, index) => {
      deleteString += _.get(levels[item], "value", "");

      if (index == array.length - 2) {
        deleteString += deleteString ? " and " : "";
      } else if (index < array.length - 1) {
        deleteString += ", ";
      }
    });
    return deleteString;
  };

  onConfirmClick = () => {
    const { onConfirmClick, OnToggleDialogueClick } = this.props;
    onConfirmClick();
    OnToggleDialogueClick();
  };

  onAnalyticsUpdated = params => {
    this.setState({ selectedAnalytics: params["analytics"] });
  };

  getAnalyticList = () => {
    const { analytics } = this.props;
    return _.map(analytics, item => {
      return { label: item, value: item };
    });
  };

  render() {
    const { OnToggleDialogueClick, analytics, isLoading, t } = this.props;

    if (isLoading) {
      return <FullScreenLoader />;
    }
    const { selectedAnalytics } = this.state;
    const title = this.getTitle();
    const analyticsLength = _.get(analytics, "length", 0);
    const messageBody = this.getModalMessageBody();
    const confirmButtonName = this.getConfirmButtonName();
    const cancelButtonName = this.getCancelButtonName();
    const checkBoxHeaderText = this.getCheckBoxHeaderText();
    const analyticList = this.getAnalyticList();
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={OnToggleDialogueClick}
      >
        <div className={classes.header}>
          <div className={classes.headerText}>{title}</div>
        </div>
        <div className={classes.body}>
          <div className={classes.bodyInnerContainer}>
            <div className={classes.messageText}>{messageBody}</div>
            {analyticsLength > 0 && (
              <div className={classes.warningMessage}>
                {t("common:checkbox_toggle_warning_msg")}
              </div>
            )}
            {analyticsLength > 0 && (
              <div className={classes.checkboxesHeaderText}>
                {checkBoxHeaderText}
              </div>
            )}
            {analyticsLength > 0 && (
              <div className={classes.checkBoxContainer}>
                <CheckListContainer
                  updateInputField={this.onAnalyticsUpdated}
                  value={selectedAnalytics}
                  listItemStyle={CheckListContainerStyle.listItemStyle}
                  options={analyticList}
                  name="analytics"
                  label=""
                  checkBoxStyles={CheckListContainerStyle.checkBoxStyles}
                  CheckBoxLabelStyles={
                    CheckListContainerStyle.CheckBoxLabelStyles
                  }
                  checkBoxParentContainer={
                    CheckListContainerStyle.checkBoxParentContainer
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.buttonContainer}>
            <UIButton color={"grey"} size="sm" onClick={OnToggleDialogueClick}>
              {cancelButtonName}
            </UIButton>
            <UIButton
              color={"pink"}
              size="sm"
              onClick={this.onConfirmClick}
              isDisabled={selectedAnalytics.length != analyticsLength}
            >
              {confirmButtonName}
            </UIButton>
          </div>
        </div>
      </UIModal>
    );
  }
}

const getGradeIds = ({ grades }) => {
  return _.map(grades, item => item.id);
};

const getGradeIdsMemoize = _.memoize(
  params => getGradeIds(params),
  params => JSON.stringify(params)
);

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.itemToDelete.id,
    grades: getGradeIdsMemoize({ grades: ownProps.gradesToBeRemoved }),
    type: ownProps.action == "DELETE_ITEM" ? "DELETE" : "UPDATE",
    analytics: [],
    isData: true,
    isLoading: false,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "academicSetup"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getBenchmarkAnalyticsQuery, {
    name: "getBenchmarkAnalytics",
    skip: ({ nodeType }) => nodeType != "BENCHMARK",
    options: ({ id, grades, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id,
        grades,
        type,
      },
    }),
    props({ getBenchmarkAnalytics }) {
      const data = _.get(getBenchmarkAnalytics, "node", {});
      return {
        isData: !_.isEmpty(data),
        analytics: _.get(data, "analytics", []),
        networkStatus: getBenchmarkAnalytics.networkStatus,
        isLoading:
          getBenchmarkAnalytics["networkStatus"] == 1 ||
          getBenchmarkAnalytics["networkStatus"] == 2,
      };
    },
  })
)(DeleteDialogueBox);
