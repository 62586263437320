import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import {
  getResourceFromUnitPlanQuery,
  getCourseAssessmentEvaluationIdQuery,
} from "AssessmentEvaluation/modules/AssessmentEvaluationQuery";
import {
  getResourceFromUnitplanFromCache,
  getCourseAssessmentEvaluationIdsFromCache,
} from "AssessmentEvaluation/modules/AssessmentEvaluationGraphqlHelpers";
import classes from "./AssessmentSelectionStep.scss";
import { UIButton, withLoader, I18nHOC } from "UIComponents";
import AssessmentFeed from "./AssessmentFeed";
// import classNames from "classnames";
import { EmptySvgIcon, ChevronIcon, CancelIcon } from "SvgComponents";
import { colors } from "Constants";

const EmptyView = ({ t, groupType }) => {
  return (
    <div className={classes.noDataContainer}>
      <EmptySvgIcon />
      <div className={classes.createText}>
        {groupType == "LEARNING_ENGAGEMENT"
          ? t("assessmentEvaluation:no_le_found")
          : t("assessmentEvaluation:no_assessments_found")}
      </div>
      <div className={classes.createSubText}>
        {groupType == "LEARNING_ENGAGEMENT"
          ? t("assessmentEvaluation:no_le_found_msg")
          : t("assessmentEvaluation:no_assessments_found_msg")}
      </div>
    </div>
  );
};

class AssessmentSelectionStep extends PureComponent {
  updateSelectedAssessments = selectedAssessments => {
    this.props.updateResourceItems({
      key: "selectedAssessments",
      value: selectedAssessments,
    });
    this.props.updateAddButtonState(
      _.get(selectedAssessments, "length", 0) > 0
    );
    this.props.onNextClick();
  };

  onBackClicked = () => {
    if (this.props.updateState) {
      this.props.updateState({ resourceItems: [] });
    }
    this.props.updateResourceItems({
      key: "selectedAssessments",
      value: [],
    });
    this.props.updateAddButtonState(false);
    this.props.onBackClick();
  };

  onClose = () => {
    const { onClose, updateState } = this.props;
    onClose();
    if (updateState) {
      updateState({ resourceItems: [] });
    }
  };

  getHeaderTitle = () => {
    const {
      filteredAssessments,
      groupType = "LEARNING_ENGAGEMENT",
      t,
      type,
    } = this.props;

    if (_.size(filteredAssessments) > 0) {
      if (_.isEqual(type, "ASSESSMENT_EVALUATION")) {
        return t("assessmentEvaluation:select_le_for_evaluation");
      } else if (_.isEqual(groupType, "LEARNING_ENGAGEMENT")) {
        return t("common:select_learning");
      } else {
        return t("common:select_with_label", {
          label: t("common:assessment"),
        });
      }
    }
    return "";
  };

  render() {
    const {
      filteredAssessments,
      resourceItems,
      // filteredNotYetEvaluatedItems,
      t,
      type,
      groupType = "LEARNING_ENGAGEMENT",
    } = this.props;

    const selectedAssessments = _.get(resourceItems, `selectedAssessments`, []);

    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.leftContainer}>
            <div
              className={classes.exitButton}
              onClick={() => this.onBackClicked()}
            >
              <span className={classes.backIcon}>
                {" "}
                <ChevronIcon fill={colors.salmon60} />
              </span>

              {t("common:back")}
            </div>
            <div className={classes.resourceFeedHeader}>
              {this.getHeaderTitle()}
            </div>
          </div>
          <div className={classes.cancelIcon} onClick={this.onClose}>
            <CancelIcon width={16} heigth={16} />
          </div>
        </div>
        {filteredAssessments.length > 0 ? (
          <Fragment>
            <div className={classes.resourceFeed}>
              <AssessmentFeed
                items={filteredAssessments}
                selectedAssessments={selectedAssessments}
                updateSelectedAssessments={this.updateSelectedAssessments}
                ref={ref => (this.resourceFeed = ref)}
                type={type}
              />
            </div>
          </Fragment>
        ) : (
          <EmptyView t={t} groupType={groupType} />
        )}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    unitPlanId: ownProps.unitPlan.id,
    unitPlanTitle: ownProps.unitPlan.title,
    resourceItems:
      ownProps.resourceItems ||
      _.get(state, "assessmentEvaluation.resourceItems", []),
    isData: true,
    isLoading: false,
    filteredAssessments: [],
  };
};

export const getAssessments = ({ data }) => {
  if (!data) {
    return [];
  }
  return _.map(data, item => {
    return item.node;
  });
};

const getFilteredAssessments = (assessmentEvaluationFeed, unitAssessments) => {
  const feedAssessmentIds = _.map(
    assessmentEvaluationFeed,
    item => item.node.assessment.id
  );
  const filteredAssessments = _.filter(unitAssessments, assessment => {
    if (
      _.findIndex(
        feedAssessmentIds,
        feedAssessmentId => feedAssessmentId == assessment.id
      ) == -1
    ) {
      return true;
    }
  });
  return filteredAssessments;
};

const getfilteredNotYetEvaluatedItems = filteredAssessments => {
  return _.filter(filteredAssessments, opt => opt.evaluations.totalCount == 0);
};

export default compose(
  I18nHOC({ resource: ["assessmentEvaluation", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getResourceFromUnitPlanQuery, {
    name: "getResourceFromUnitPlan",
    options: ({ unitPlanId, courseId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: unitPlanId, courseId: courseId },
    }),
    props({
      getResourceFromUnitPlan,
      ownProps: { unitPlanId, courseId, groupType = "LEARNING_ENGAGEMENT" },
    }) {
      const resourceDetails = getResourceFromUnitplanFromCache({
        unitPlanId,
        courseId,
      });

      const assessments = getAssessments({
        data: _.get(resourceDetails, `resourceLibrary.edges`, []),
      });

      const filteredAssessments = _.filter(assessments, item => {
        const assessmentType =
          _.get(item, "assessmentType.value", "le") || "le";
        if (_.isEqual(groupType, "ASSESSMENT")) {
          return !_.isEqual(assessmentType, "le");
        }
        return _.isEqual(assessmentType, "le");
      });

      return {
        filteredAssessments,
        isData: !!getResourceFromUnitPlan.node,
        isLoading:
          getResourceFromUnitPlan["networkStatus"] == 1 ||
          getResourceFromUnitPlan["networkStatus"] == 2,
      };
    },
  }),
  graphql(getCourseAssessmentEvaluationIdQuery, {
    name: "getCourseAssessmentEvaluationId",
    skip: ({ courseId }) => !courseId,
    options: ({ courseId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        courseId: courseId,
      },
    }),
    props({
      getCourseAssessmentEvaluationId,
      ownProps: { isData, isLoading, courseId, filteredAssessments },
    }) {
      const data = getCourseAssessmentEvaluationIdsFromCache({ courseId });
      return {
        isData: !_.isEmpty(data) && isData,
        filteredNotYetEvaluatedItems: getfilteredNotYetEvaluatedItems(
          filteredAssessments
        ),
        // getFilteredAssessments(
        //   _.get(data, "assessmentEvaluations.edges", []),
        //   assessments
        // ),
        isLoading:
          getCourseAssessmentEvaluationId["networkStatus"] == 1 ||
          getCourseAssessmentEvaluationId["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(AssessmentSelectionStep);
