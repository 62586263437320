import gql from "graphql-tag";
import { elementSetNotCoverageCountFragment } from "./Fragments";
import { plannerElementNodeFragment } from "modules/CommonFragments";

export const getCourseOverviewElementSetsNotCoveredCountQuery = gql`
  query getCourseOverviewElementSetsNotCoveredCount(
    $id: ID!
    $filters: InsightCurriculumFilter!
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        id
        genericYearlyInsight(filters: $filters) {
          elementSetsNotCovered {
            ...elementSetNotCoverageItemCount
          }
        }
      }
    }
  }
  ${elementSetNotCoverageCountFragment.elementSetNotCoverageCount}
`;

export const getPlannerElementNodesQuery = gql`
  query getPlannerElementNodes($ids: [ID!]) {
    multiNode(ids: $ids, type: PLANNER_ELEMENT_NODE) {
      id
      ... on PlannerElementNode {
        ...plannerElementNodeItem
      }
    }
  }
  ${plannerElementNodeFragment.plannerElementNode}
`;
