import React from "react";
import {
  AicnCalendar,
  AicnMobileSettings,
  AicnPOI,
  AicnNews,
} from "SvgComponents";
import {
  AcademicSetupDuotone,
  AttendanceClipboardDuotone,
  Announcements3D,
  Calendar3D,
  CheckShieldCardsDuotone,
  Integrations3D,
  JournalDuotone,
  OpenBookDuotone,
  ProgressReportDuotone,
  PurposeOfIntegrationDuotone,
  School3D,
  Settings3D,
  SummativeAssessmentDuotone,
  IbAandE3D,
  Transcripts3D,
  SettingsDuotone,
  LearnerProfileDuotone,
} from "@toddle-design/web-icons";
import {
  SNPv2_PERM,
  SNPv2_ALLOW_VISITOR_INVITE,
  OPERATIONAL_DAYS_AND_TIME_ZONE_PERM,
} from "Constants/permissionConstants";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";
import { colors } from "Constants";
import {
  getAdminMenuUid,
  removeIBPrefixFromCurriculumType,
} from "Administrator/modules/Utils";
import ACLStore from "lib/aclStore";
import _ from "lodash";
import { getCurriculumProgramLabel } from "Platform/modules/PlatformUtils";

export const AdminMenu = {
  rootNodes: [
    {
      id: "SCHOOL_CONFIGURATIONS",
      label: "common:school_configurations",
      subLabel: "common:school_configurations_message",
      levelId: "ROOT",
      children: [
        "CIRCULARS",
        "SNP",
        "TRASH",
        "YEAR_GROUP_MANGER",
        "INTEGRATION_SETTINGS",
        "SCHOOL_CALENDAR_V2",
        "SCHOOL_SETUP",
        "SETTINGS",
        "TRANSCRIPTS",
      ],
      uptoLevel: 1, // Specify till which level to go to display children as cards
    },
    {
      id: "CURRICULUM_CONFIGURATIONS",
      label: "common:program_configurations",
      subLabel: "common:program_configurations_message",
      levelId: "ROOT",
      uptoLevel: 2,
      isDynamicNode: true,
    },
  ],
  nodes: [
    {
      id: "IB_DP",
      label: "common:dp",
      children: [
        "DP_ACADEMIC_SETUP",
        "DP_SNP",
        "DP_CORE_SETUP",
        "DP_IBIS_EXAM_REGISTRATION",
        "DP_IB_E_COURSEWORK",
        "DP_EDUCATOR_CENTER",
        "DP_UNIT_PLANS",
        "DP_ATTENDANCE_DASHBOARD",
      ],
      curriculumPrograms: ["IB_DP"],
      levelId: "L0",
      isDynamicNode: true,
    },
    {
      id: "IB_PYP",
      label: "common:pyp",
      children: [
        "PYP_ACADEMIC_SETUP",
        "PYP_SNP",
        "PYP_EDUCATOR_CENTER",
        "PLANNING_INSIGHTS",
        "PYP_PROGRESS_REPORTS",
        "PYP_UNIT_PLANS",
        "PYP_ATTENDANCE_DASHBOARD",
      ],
      curriculumPrograms: ["IB_PYP"],
      levelId: "L0",
      isDynamicNode: true,
    },
    {
      id: "IB_MYP",
      label: "common:myp",
      children: [
        "MYP_ACADEMIC_SETUP",
        "MYP_SNP",
        "SUBJECT_SUMMARY",
        "MYP_EDUCATOR_CENTER",
        "MYP_PROGRESS_REPORTS",
        "MYP_UNIT_PLANS",
        "MYP_ATTENDANCE_DASHBOARD",
      ],
      curriculumPrograms: ["IB_MYP"],
      levelId: "L0",
      isDynamicNode: true,
    },
    {
      id: "UBD",
      label: "common:ubd",
      children: [
        "UBD_ACADEMIC_SETUP",
        "UBD_SNP",
        "UBD_EDUCATOR_CENTER",
        "UBD_PROGRESS_REPORTS",
        "UBD_UNIT_PLANS",
        "UBD_ATTENDANCE_DASHBOARD",
      ],
      curriculumPrograms: ["UBD"],
      levelId: "L0",
      isDynamicNode: true,
    },
    {
      id: "PLANNING_INSIGHTS",
      label: "teacherHomePage:planning_insights",
      children: [],
      levelId: "L1",
      route: "planningInsights",
      icon: <PurposeOfIntegrationDuotone color={"pink"} />,
      perm: "AdminPortal:PlanningInsights",
      isBottom: false,
      isDynamicNode: true,
    },
    {
      id: "POI",
      label: "teacherHomePage:poi",
      children: [],
      perm: "AdminPortal:POI",
      route: "poi",
      levelId: "L1",
      icon: <AicnPOI />,
    },
    {
      id: "PYP_UNIT_PLANS",
      label: "teacherHomePage:units_of_inquiry",
      children: [],
      curriculumPrograms: ["IB_PYP"],
      perm: "AdminPortal:UnitPlans",
      route: "adminunitplans",
      levelId: "L1",
      icon: <JournalDuotone color={"pink"} />,
      isDynamicNode: true,
    },
    {
      id: "MYP_UNIT_PLANS",
      label: "teacherHomePage:units_of_inquiry",
      children: [],
      curriculumPrograms: ["IB_MYP"],
      perm: "AdminPortal:UnitPlans",
      route: "adminunitplans",
      levelId: "L1",
      icon: <JournalDuotone color={"violet"} />,
      isDynamicNode: true,
    },
    {
      id: "UBD_UNIT_PLANS",
      label: "teacherHomePage:units_of_inquiry",
      children: [],
      curriculumPrograms: ["UBD"],
      perm: "AdminPortal:UnitPlans",
      route: "adminunitplans",
      levelId: "L1",
      icon: <JournalDuotone color={"teal"} />,
      isDynamicNode: true,
    },
    {
      id: "DP_UNIT_PLANS",
      label: "teacherHomePage:units_of_inquiry",
      children: [],
      curriculumPrograms: ["IB_DP"],
      perm: "AdminPortal:UnitPlans",
      route: "adminunitplans",
      levelId: "L1",
      icon: <JournalDuotone color={"yellow"} />,
      isDynamicNode: true,
    },
    {
      id: "SNP",
      label: "teacherHomePage:authorizationEvaluation",
      children: [],
      perm: "AdminPortal:SNP",
      route: "snp",
      levelId: "L1",
      icon: <IbAandE3D />,
      iconBgColor: colors.orange91,
    },
    {
      id: "PYP_SNP",
      label: "teacherHomePage:authorizationEvaluation",
      children: [],
      perm: SNPv2_PERM,
      route: "snp_v2",
      levelId: "L1",
      icon: <CheckShieldCardsDuotone color={"pink"} />,
      isDynamicNode: true,
    },
    {
      id: "MYP_SNP",
      label: "teacherHomePage:authorizationEvaluation",
      children: [],
      perm: SNPv2_PERM,
      route: "snp_v2",
      levelId: "L1",
      icon: <CheckShieldCardsDuotone color={"violet"} />,
      isDynamicNode: true,
    },
    {
      id: "UBD_SNP",
      label: "teacherHomePage:authorizationEvaluation",
      children: [],
      perm: SNPv2_PERM,
      route: "snp_v2",
      levelId: "L1",
      icon: <CheckShieldCardsDuotone color={"teal"} />,
      isDynamicNode: true,
    },
    {
      id: "DP_SNP",
      label: "teacherHomePage:authorizationEvaluation",
      children: [],
      perm: SNPv2_PERM,
      route: "snp_v2",
      levelId: "L1",
      icon: <CheckShieldCardsDuotone color={"yellow"} />,
      isDynamicNode: true,
    },
    {
      id: "PYP_PROGRESS_REPORTS",
      label: "common:progress_reports",
      children: [
        "CHECKLIST",
        "PROGRESS_REPORTS_TASKS",
        "PROGRESS_REPORT_TEMPLATE",
        "SAVED_COMMENT_TEMPLATES",
      ],
      route: "progressreports",
      levelId: "L1",
      icon: <ProgressReportDuotone color={"pink"} />,
      isDynamicNode: true,
    },
    {
      id: "PROGRESS_REPORTS_TASKS",
      label: "teacherHomePage:progress_report_set_plural",
      children: [],
      perm: "AdminPortal:ProgressReports",
      route: "reporttasks",
      levelId: "L2",
    },
    {
      id: "SAVED_COMMENT_TEMPLATES",
      label: "teacherHomePage:comments_bank",
      children: [],
      perm: "Common:CommentTemplate",
      route: "comment-templates",
    },
    {
      id: "CHECKLIST",
      label: "teacherHomePage:checklist",
      children: [],
      perm: "AdminPortal:TemplateChecklist",
      route: "checklist",
      levelId: "L2",
    },
    {
      id: "MYP_PROGRESS_REPORTS",
      label: "common:progress_reports",
      children: [
        "MYP_PROGRESS_REPORTS_TASKS",
        "MYP_PROGRESS_REPORT_TEMPLATE",
        "SAVED_COMMENT_TEMPLATES",
      ],
      route: "progressreports",
      levelId: "L1",
      curriculumPrograms: ["IB_MYP"],
      icon: <ProgressReportDuotone color={"violet"} />,
      isDynamicNode: true,
    },
    {
      id: "UBD_PROGRESS_REPORTS",
      label: "common:progress_reports",
      children: [
        "UBD_PROGRESS_REPORTS_TASKS",
        "UBD_PROGRESS_REPORT_TEMPLATE",
        "SAVED_COMMENT_TEMPLATES",
      ],
      route: "progressreports",
      levelId: "L1",
      curriculumPrograms: ["UBD"],
      icon: <ProgressReportDuotone color={"teal"} />,
      isDynamicNode: true,
    },
    {
      id: "DP_CORE_SETUP",
      label: "teacherHomePage:dp_core_setup",
      children: ["YEAR_GROUP_OVERVIEW", "MEMBERSHIP_STUDENTS"],
      levelId: "L1",
      perm: "AdminPortal:DPCoreSetup",
      route: "project-group-setup",
      curriculumPrograms: [CURRICULUM_TYPE_DP],
      icon: <ProgressReportDuotone color={"yellow"} />,
      isDynamicNode: true,
    },
    {
      id: "DP_IBIS_EXAM_REGISTRATION",
      label: "teacherHomePage:ibis_exam_registration",
      children: ["CLASS", "IBIS_SETTINGS"],
      levelId: "L1",
      perm: "AdminPortal:DPExamRegistration",
      route: "exam-registration",
      curriculumPrograms: [CURRICULUM_TYPE_DP],
      icon: <SettingsDuotone color={"yellow"} />,
      isDynamicNode: true,
    },
    {
      id: "DP_IB_E_COURSEWORK",
      label: "teacherHomePage:ib_ecoursework",
      children: [],
      levelId: "L1",
      perm: "AdminPortal:IbECoursework",
      route: "ecoursework",
      curriculumPrograms: [CURRICULUM_TYPE_DP],
      icon: <LearnerProfileDuotone color={"yellow"} />, // TODO: replace placeholder icon when DS icon is ready
      isDynamicNode: true,
    },
    {
      id: "DP_ACADEMIC_SETUP",
      label: "teacherHomePage:academic_setup",
      children: [
        "SUBJECTS_SECTION_DP",
        "ACADEMIC_ASSESSMENTS_DP",
        "ACADEMIC_TEMPLATES_DP",
      ],
      curriculumPrograms: [CURRICULUM_TYPE_DP],
      perm: "AdminPortal:DPAcademicSetup",
      levelId: "L1",
      route: "dpAcademicSetup",
      icon: <AcademicSetupDuotone color={"yellow"} />,
      isDynamicNode: true,
    },
    {
      id: "SUBJECTS_SECTION_DP",
      label: "common:subject_plural",
      children: ["DP_SUBJECTS", "DP_SUBJECT_STANDARD"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: [CURRICULUM_TYPE_DP],
    },
    {
      id: "ACADEMIC_ASSESSMENTS_DP",
      label: "common:assessment_label_plural",
      children: ["DP_SCORE_CATEGORIES"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: [CURRICULUM_TYPE_DP],
    },
    {
      id: "DP_SCORE_CATEGORIES",
      label: "common:point_based_assessment",
      children: [],
      levelId: "L2",
      route: "score-categories",
      perm: "AdminPortal:DPScoreCategories",
    },
    {
      id: "DP_SUBJECTS",
      label: "common:subject_plural",
      children: [],
      levelId: "L3",
      route: "subjects",
      perm: "AdminPortal:Subjects",
    },
    {
      id: "DP_SUBJECT_STANDARD",
      label: "common:content_standards",
      children: [],
      levelId: "L2",
      route: "dp-learning-standards",
      perm: "AdminPortal:DPLearningStandards",
    },
    {
      id: "ACADEMIC_TEMPLATES_DP",
      label: "common:template_plural",
      children: ["UNIT_PLAN_TEMPLATES"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_DP"],
    },
    {
      id: "YEAR_GROUP_OVERVIEW",
      label: "common:overview",
      children: [],
      levelId: "L2",
      perm: "AdminPortal:YearGroupManager",
      route: "overview",
    },
    // For Future reference where we'll have multi tabs in membership tab.
    // {
    //   id: "YEAR_GROUP_MEMBERSHIP",
    //   label: "common:membership",
    //   levelId: "L2",
    //   perm: "AdminPortal:YearGroupMembership",
    //   route: "membership",
    //   subMenu: ["MEMBERSHIP_STUDENTS"],
    // },
    {
      id: "MEMBERSHIP_STUDENTS",
      label: "common:student_plural",
      children: [],
      levelId: "L2",
      route: "membership",
      perm: "AdminPortal:YearGroupManager",
    },
    {
      id: "YEAR_GROUP_MANGER",
      label: "teacherHomePage:year_group_manager",
      children: ["YEAR_GROUP_OVERVIEW", "MEMBERSHIP_STUDENTS"],
      levelId: "L1",
      perm: "AdminPortal:YearGroupManager",
      route: "year-group-manager",
      curriculumPrograms: [CURRICULUM_TYPE_DP],
      icon: <Settings3D />,
      iconBgColor: colors.blue90,
    },
    {
      id: "SUBJECT_SUMMARY",
      label: "teacherHomePage:class_summary",
      children: [],
      perm: "AdminPortal:MYPProgressSummary",
      route: "progressSummary",
      levelId: "L1",
      curriculumPrograms: ["IB_MYP"],
      icon: <SummativeAssessmentDuotone color={"violet"} />,
      isDynamicNode: true,
    },
    {
      id: "MYP_PROGRESS_REPORTS_TASKS",
      label: "teacherHomePage:progress_report_set_plural",
      children: [],
      perm: "AdminPortal:MYPProgressReports",
      route: "reporttasks",
      levelId: "L2",
    },
    {
      id: "MYP_PROGRESS_REPORT_TEMPLATE",
      label: "teacherHomePage:progress_report_templates",
      children: [],
      levelId: "L2",
      route: "progressreporttemplate",
      perm: "AdminPortal:MYPProgressReports",
    },
    {
      id: "UBD_PROGRESS_REPORTS_TASKS",
      label: "teacherHomePage:progress_report_set_plural",
      children: [],
      perm: "AdminPortal:UBDProgressReports",
      route: "reporttasks",
      levelId: "L2",
    },
    {
      id: "UBD_PROGRESS_REPORT_TEMPLATE",
      label: "teacherHomePage:progress_report_templates",
      children: [],
      levelId: "L2",
      route: "progressreporttemplate",
      perm: "AdminPortal:UBDProgressReports",
    },
    {
      id: "PYP_EDUCATOR_CENTER",
      label: "teacherHomePage:educator_center",
      children: ["RESOURCES", "POLICIES"],
      levelId: "L1",
      route: "educatorcenter",
      curriculumPrograms: ["IB_PYP"],
      icon: <OpenBookDuotone color={"pink"} />,
      isDynamicNode: true,
    },
    {
      id: "MYP_EDUCATOR_CENTER",
      label: "teacherHomePage:educator_center",
      children: ["RESOURCES", "POLICIES"],
      levelId: "L1",
      route: "educatorcenter",
      curriculumPrograms: ["IB_MYP"],
      icon: <OpenBookDuotone color={"violet"} />,
      isDynamicNode: true,
    },
    {
      id: "UBD_EDUCATOR_CENTER",
      label: "teacherHomePage:educator_center",
      children: ["RESOURCES", "POLICIES"],
      levelId: "L1",
      route: "educatorcenter",
      curriculumPrograms: ["UBD"],
      icon: <OpenBookDuotone color={"teal"} />,
      isDynamicNode: true,
    },
    {
      id: "DP_EDUCATOR_CENTER",
      label: "teacherHomePage:educator_center",
      children: ["RESOURCES", "POLICIES"],
      levelId: "L1",
      route: "educatorcenter",
      curriculumPrograms: ["IB_DP"],
      icon: <OpenBookDuotone color={"yellow"} />,
      isDynamicNode: true,
    },
    {
      id: "CLASS",
      label: "common:class",
      children: [
        "IBIS_EXAM_REGISTRATION_OVERVIEW",
        "IBIS_EXAM_CANDIDATE_INFO",
        "IBIS_EXAM_SUBJECTS",
        "IBIS_EXAM_DETAILS",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_DP"],
    },
    {
      id: "IBIS_SETTINGS",
      label: "teacherHomePage:ibis_settings",
      children: ["IBIS_EXAM_PERMISSION", "IBIS_EXAM_SYNC"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_DP"],
    },
    {
      id: "IBIS_EXAM_REGISTRATION_OVERVIEW",
      label: "teacherHomePage:ibis_exam_registration_overview",
      children: [],
      levelId: "L3",
      route: "overview",
      perm: "AdminPortal:DPExamRegistration",
    },
    {
      id: "IBIS_EXAM_CANDIDATE_INFO",
      label: "teacherHomePage:ibis_exam_candidate_info",
      children: [],
      levelId: "L3",
      route: "candidate-info",
      perm: "AdminPortal:DPExamRegistration",
    },
    {
      id: "IBIS_EXAM_SUBJECTS",
      label: "common:subject_plural",
      children: [],
      levelId: "L3",
      route: "subjects",
      perm: "AdminPortal:DPExamRegistration",
    },
    {
      id: "IBIS_EXAM_DETAILS",
      label: "teacherHomePage:ibis_exam_details",
      children: [],
      levelId: "L3",
      route: "exam-details",
      perm: "AdminPortal:DPExamRegistration",
    },
    {
      id: "IBIS_EXAM_PERMISSION",
      label: "common:permission_plural",
      subLabel: "common:ibis_perm_sub_label",
      children: [],
      levelId: "L3",
      route: "permissions",
      perm: "AdminPortal:DPExamRegistration",
    },
    {
      id: "IBIS_EXAM_SYNC",
      label: "integration:sync",
      subLabel: "common:ibis_sync_sub_label",
      children: [],
      levelId: "L3",
      route: "sync",
      perm: "AdminPortal:DPExamRegistration",
    },
    {
      id: "RESOURCES",
      label: "common:resources",
      children: ["TEACHER_RESOURCE", "ADMIN_RESOURCE"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
    },
    {
      id: "POLICIES",
      label: "common:school_policy",
      children: ["TEACHER_SCHOOL_POLICIES", "PARENT_SCHOOL_POLICIES"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
    },
    {
      id: "TEACHER_SCHOOL_POLICIES",
      label: "teacherHomePage:shared_with_teachers",
      children: [],
      levelId: "L3",
      route: "teacherpolicies",
      perm: "AdminPortal:TeacherSchoolPolicies",
    },
    {
      id: "PARENT_SCHOOL_POLICIES",
      label: "teacherHomePage:shared_with_parents",
      children: [],
      levelId: "L3",
      route: "parentpolicies",
      perm: "AdminPortal:ParentSchoolPolicies",
    },
    {
      id: "ADMIN_RESOURCE",
      label: "teacherHomePage:admin_resources",
      children: [],
      levelId: "L3",
      route: "adminresources",
      perm: "AdminPortal:AdminResources",
    },
    {
      id: "TEACHER_RESOURCE",
      label: "teacherHomePage:teacher_resources",
      children: [],
      levelId: "L3",
      route: "teacherresources",
      perm: "AdminPortal:TeacherResources",
    },
    {
      id: "SCHOOL_CALENDAR",
      label: "teacherHomePage:school_calendar",
      children: [],
      levelId: "L1",
      perm: "AdminPortal:Calendar",
      route: "calendar",
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
      icon: <AicnCalendar />,
    },
    {
      id: "NEWS",
      label: "teacherHomePage:news",
      children: [],
      levelId: "L1",
      route: "news",
      perm: "AdminPortal:News",
      icon: <AicnNews />,
    },
    {
      id: "CIRCULARS",
      label: "teacherHomePage:announcements",
      children: [],
      levelId: "L1",
      route: "circulars",
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
      perm: "AdminPortal:Circular",
      icon: <Announcements3D />,
      iconBgColor: colors.violet94,
    },
    {
      id: "MOBILE_SETTINGS",
      label: "mobile_settings",
      children: [],
      levelId: "L1",
      icon: <AicnMobileSettings />,
    },
    {
      id: "TRASH",
      label: "common:trash",
      children: [
        "TRASH_UNIT_PLANS",
        "TRASH_LEARNING_EXPERIENCES",
        "TRASH_ORG_STUDENTS",
      ],
      route: "trash",
      levelId: "L1",
      icon: <Integrations3D />,
      iconBgColor: colors.blue90,
    },
    {
      id: "TRASH_UNIT_PLANS",
      label: "common:trash_unit_plans",
      children: [],
      levelId: "L2",
      route: "trashUnitPlans",
      perm: "AdminPortal:UnitsTrash",
    },
    {
      id: "TRASH_LEARNING_EXPERIENCES",
      label: "common:trash_learning_experiences",
      children: [],
      levelId: "L2",
      route: "trashLearningExperiences",
      perm: "AdminPortal:LesTrash",
    },
    {
      id: "TRASH_ORG_STUDENTS",
      label: "common:student_plural",
      children: [],
      levelId: "L2",
      route: "trashOrganizationStudents",
      perm: "AdminPortal:LesTrash",
    },
    {
      id: "SCHOOL_SETUP",
      label: "teacherHomePage:school_setup",
      children: ["SCHOOL_SETTINGS", "CLASS_ROSTER"],
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
      route: "schoolSetup",
      levelId: "L1",
      icon: <School3D />,
      iconBgColor: colors.blue90,
    },
    {
      id: "SCHOOL_SETTINGS",
      label: "common:school_settings",
      children: [
        "SCHOOL_INFORMATION",
        "OPERATIONAL_DAYS_AND_TIME_ZONE",
        "ACADEMIC_YEAR",
        "ACADEMIC_YEAR_TERMS",
        "GRADES_SETUP",
        "PERIODS_SETUP",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
    },
    {
      id: "CLASS_ROSTER",
      label: "teacherHomePage:class_roster",
      children: [
        "COURSE_SETUP",
        "TEACHER_SETUP",
        "STUDENTS",
        "FAMILY_MEMBERS",
        "VISITOR_SETUP",
        "SYNC",
        "DOWNLOAD",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
    },
    {
      id: "UBD_ACADEMIC_SETUP",
      label: "teacherHomePage:academic_setup",
      children: [
        "SUBJECTS_SECTION_UBD",
        "CONCEPTS_LPA_UBD",
        "ACADEMIC_ASSESSMENTS_UBD",
        "ACADEMIC_TEMPLATES_UBD",
      ],
      curriculumPrograms: ["UBD"],
      levelId: "L1",
      route: "ubdAcademicSetup",
      icon: <AcademicSetupDuotone color={"teal"} />,
      isDynamicNode: true,
    },
    {
      id: "PYP_ATTENDANCE_DASHBOARD",
      children: [],
      label: "attendance:attendance_manager",
      curriculumPrograms: ["IB_PYP"],
      perm: "AdminPortal:AttendanceDashboard",
      levelId: "L1",
      route: "attendance-dashboard",
      isDynamicNode: true,
      icon: <AttendanceClipboardDuotone color={"pink"} />,
    },
    {
      id: "MYP_ATTENDANCE_DASHBOARD",
      children: [],
      label: "attendance:attendance_manager",
      curriculumPrograms: ["IB_MYP"],
      perm: "AdminPortal:AttendanceDashboard",
      levelId: "L1",
      route: "attendance-dashboard",
      isDynamicNode: true,
      icon: <AttendanceClipboardDuotone color={"violet"} />,
    },
    {
      id: "UBD_ATTENDANCE_DASHBOARD",
      children: [],
      label: "attendance:attendance_manager",
      curriculumPrograms: ["UBD"],
      perm: "AdminPortal:AttendanceDashboard",
      levelId: "L1",
      route: "attendance-dashboard",
      isDynamicNode: true,
      icon: <AttendanceClipboardDuotone color={"teal"} />,
    },
    {
      id: "DP_ATTENDANCE_DASHBOARD",
      children: [],
      label: "attendance:attendance_manager",
      curriculumPrograms: ["IB_DP"],
      perm: "AdminPortal:AttendanceDashboard",
      levelId: "L1",
      route: "attendance-dashboard",
      isDynamicNode: true,
      icon: <AttendanceClipboardDuotone color={"yellow"} />,
    },
    {
      id: "SUBJECTS_SECTION_UBD",
      label: "common:subject_plural",
      children: ["UBD_SUBJECTS", "UBD_LEARNING_STANDARD", "UBD_CONTINUUM"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["UBD"],
    },
    {
      id: "CONCEPTS_LPA_UBD",
      label: "teacherHomePage:concepts_and_lpa",
      children: ["UBD_KEY_CONCEPTS", "UBD_LEARNER_PROFILE", "UBD_ATL"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["UBD"],
    },
    {
      id: "ACADEMIC_ASSESSMENTS_UBD",
      label: "common:assessment_label_plural",
      children: [
        "UBD_EVALUATION_CRITERIA",
        "UBD_ASSESSMENT_CRITERIA",
        "UBD_SCORE_CATEGORIES",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["UBD"],
    },
    {
      id: "ACADEMIC_TEMPLATES_UBD",
      label: "common:template_plural",
      children: [
        "STUDENT_PROFILE_TEMPLATES",
        "ASSESSMENT_TOOL",
        "UNIT_PLAN_TEMPLATES",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["UBD"],
    },
    {
      id: "UBD_EVALUATION_CRITERIA",
      label: "teacherHomePage:evaluation_criteria",
      children: [],
      levelId: "L3",
      route: "evaluationCriteria",
      perm: "AdminPortal:UBDEvaluationCriteria",
    },
    {
      id: "UBD_ASSESSMENT_CRITERIA",
      label: "teacherHomePage:assessment_criteria",
      children: [],
      levelId: "L3",
      route: "assessment-criteria",
      perm: "AdminPortal:UBDAssessmentCriteria",
    },
    {
      id: "MYP_SCORE_CATEGORIES",
      label: "common:point_based_assessment",
      children: [],
      levelId: "L2",
      route: "score-categories",
      perm: "AdminPortal:MYPScoreCategories",
    },
    {
      id: "PYP_SCORE_CATEGORIES",
      label: "common:point_based_assessment",
      children: [],
      levelId: "L2",
      route: "score-categories",
      perm: "AdminPortal:PYPScoreCategories",
    },
    {
      id: "UBD_SCORE_CATEGORIES",
      label: "common:point_based_assessment",
      children: [],
      levelId: "L2",
      route: "score-categories",
      perm: "AdminPortal:UBDScoreCategories",
    },
    {
      id: "SCHOOL_INFORMATION",
      label: "common:school_information",
      children: [],
      levelId: "L3",
      route: "school-information",
      perm: "AdminPortal:SchoolInformation",
    },
    {
      id: "OPERATIONAL_DAYS_AND_TIME_ZONE",
      label: "common:entity1_and_entity2",
      locale_keys: {
        entity1: "common:time_zone",
        entity2: "common:operational_days",
      },
      children: [],
      levelId: "L3",
      route: "operational-days-and-time-zone",
      perm: OPERATIONAL_DAYS_AND_TIME_ZONE_PERM,
    },
    {
      id: "ACADEMIC_YEAR",
      label: "common:academic_year",
      children: [],
      levelId: "L3",
      route: "academic-year",
      perm: "AdminPortal:AcademicYear",
    },
    {
      id: "ACADEMIC_YEAR_TERMS",
      label: "transcript:academic_years_and_terms",
      children: [],
      levelId: "L3",
      route: "academic-year-terms",
      perm: "AdminPortal:AcademicYearTerms",
    },
    {
      id: "STUDENTS",
      label: "common:student_plural",
      children: [],
      levelId: "L3",
      route: "students",
      perm: "AdminPortal:Students",
      hasSubTabs: true,
      showAllItems: true,
    },
    {
      id: "FAMILY_MEMBERS",
      label: "common:family_member_plural",
      children: [],
      levelId: "L3",
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
      route: "manage-family",
      perm: "AdminPortal:ManageFamilyMembers",
      hasSubTabs: true,
      showAllItems: true,
    },
    {
      id: "TEACHER_SETUP",
      label: "common:staff",
      children: [],
      levelId: "L3",
      route: "teachers",
      perm: "AdminPortal:Teachers",
      hasSubTabs: true,
      showAllItems: true,
    },
    {
      id: "VISITOR_SETUP",
      label: "snp:visitor_plural",
      children: [],
      levelId: "L3",
      route: "visitors",
      perm: SNPv2_ALLOW_VISITOR_INVITE,
    },
    {
      id: "COURSE_SETUP",
      label: "common:class_plural",
      children: [],
      levelId: "L3",
      route: "classes",
      perm: "AdminPortal:Courses",
      hasSubTabs: true,
      showAllItems: false,
    },
    {
      id: "GRADES_SETUP",
      label: "common:grade_plural",
      children: [],
      levelId: "L3",
      route: "grades",
      perm: "AdminPortal:Grades",
      hasSubTabs: true,
      showAllItems: false,
    },
    {
      id: "ATTENDANCE_SETUP",
      label: "common:attendance",
      children: [],
      levelId: "L2",
      route: "attendance",
      perm: "AdminPortal:AttendanceSetup",
    },
    {
      id: "PERIODS_SETUP",
      label: "scheduler:period_plural",
      children: [],
      levelId: "L3",
      route: "periods",
      perm: "AdminPortal:Periods",
    },
    {
      id: "SCHOOL_CALENDAR_V2",
      label: "configuration:school_calendar",
      children: [],
      levelId: "L1",
      perm: "AdminPortal:CalendarV2",
      route: "school-calendar",
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
      icon: <Calendar3D />,
      iconBgColor: colors.yellow88,
    },
    {
      id: "PYP_ACADEMIC_SETUP",
      label: "teacherHomePage:academic_setup",
      children: [
        "SUBJECTS_SECTION",
        "CONCEPTS_LPA",
        "ACADEMIC_ASSESSMENTS",
        "ACADEMIC_TEMPLATES",
      ],
      curriculumPrograms: ["IB_PYP"],
      levelId: "L1",
      route: "academicSetup",
      icon: <AcademicSetupDuotone color={"pink"} />,
      isDynamicNode: true,
    },
    {
      id: "SUBJECTS_SECTION",
      label: "common:subject_plural",
      children: [
        "SUBJECTS",
        "CONCEPTS_RELATED_CONCEPTS",
        "SCOPE_SEQUENCE",
        "CONTINUUM",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP"],
    },
    {
      id: "CONCEPTS_LPA",
      label: "teacherHomePage:atl_and_lpa",
      children: ["LEARNER_PROFILES", "ATL"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP"],
    },
    {
      id: "ACADEMIC_ASSESSMENTS",
      label: "common:assessment_label_plural",
      children: [
        "EVALUATION_CRITERIA",
        "ASSESSMENT_CRITERIA",
        "PYP_SCORE_CATEGORIES",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP"],
    },
    {
      id: "ACADEMIC_TEMPLATES",
      label: "common:template_plural",
      children: [
        "ASSESSMENT_TOOL",
        "STUDENT_PROFILE_TEMPLATES",
        "UNIT_PLAN_TEMPLATES",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_PYP"],
    },
    // settings - configuration
    {
      id: "SETTINGS",
      label: "common:settings",
      children: [
        "MODULE_SETTINGS",
        "JOURNAL",
        "ATTENDANCE",
        "OTHER",
        "FAMILY_SETTINGS",
      ],
      levelId: "L1",
      route: "settings",
      icon: <Settings3D />,
      iconBgColor: colors.blue90,
      perm: "AdminPortal:Settings",
    },
    {
      id: "TRANSCRIPTS",
      label: "common:transcripts",
      levelId: "L1",
      route: "transcripts",
      icon: <Transcripts3D />,
      iconBgColor: colors.yellow88,
      perm: "AdminPortal:Transcripts",
    },
    {
      id: "INTEGRATION_SETTINGS",
      label: "common:integrations",
      children: ["TURNITIN_SETTINGS"],
      levelId: "L1",
      route: "integration-settings",
      icon: <Integrations3D />,
      iconBgColor: colors.pink95,
      curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD", "IB_DP"],
    },
    // settings - children
    {
      id: "MODULE_SETTINGS",
      label: "common:module_settings",
      children: [],
      levelId: "L2",
      route: "module-settings",
      perm: "AdminPortal:ModuleSettings",
    },
    {
      id: "JOURNAL",
      label: "common:journal_settings",
      children: [],
      levelId: "L2",
      route: "journal",
      perm: "AdminPortal:JournalSettings",
    },
    {
      id: "ATTENDANCE",
      label: "common:attendance_settings",
      children: [],
      levelId: "L2",
      route: "attendance",
      perm: "AdminPortal:AttendanceSettings",
    },
    {
      id: "OTHER",
      label: "common:other_settings",
      children: [],
      levelId: "L2",
      route: "other",
      perm: "AdminPortal:OtherSettings",
    },
    {
      id: "FAMILY_SETTINGS",
      label: "common:family_settings_v2",
      children: [],
      levelId: "L2",
      route: "family-settings",
      perm: "AdminPortal:FamilySettings",
    },
    {
      id: "MYP_ACADEMIC_SETUP",
      label: "teacherHomePage:academic_setup",
      children: [
        "SUBJECTS_SECTION_MYP",
        "CONCEPTS_LPA_MYP",
        "ACADEMIC_ASSESSMENTS_MYP",
        "ACADEMIC_TEMPLATES_MYP",
      ],
      curriculumPrograms: ["IB_MYP"],
      levelId: "L1",
      route: "mypAcademicSetup",
      icon: <AcademicSetupDuotone color={"violet"} />,
      isDynamicNode: true,
    },
    {
      id: "SUBJECTS_SECTION_MYP",
      label: "common:subject_plural",
      children: [
        "SUBJECT_GROUPS_AND_SUBJECTS",
        "MYP_RELATED_CONCEPTS",
        "LEARNING_STANDARDS",
        "CONTINUUM_MYP",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_MYP"],
    },
    {
      id: "CONCEPTS_LPA_MYP",
      label: "teacherHomePage:concepts_and_lpa",
      children: ["MYP_KEY_CONCEPTS", "MYP_LEARNER_PROFILE", "MYP_ATL"],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_MYP"],
    },
    {
      id: "ACADEMIC_ASSESSMENTS_MYP",
      label: "common:assessment_label_plural",
      children: [
        "MYP_LOCAL_GRADES",
        "MYP_EVALUATION_CRITERIA",
        "MYP_ASSESSMENT_CRITERIA",
        "MYP_SCORE_CATEGORIES",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_MYP"],
    },
    {
      id: "ACADEMIC_TEMPLATES_MYP",
      label: "common:template_plural",
      children: [
        "ASSESSMENT_TOOL",
        "STUDENT_PROFILE_TEMPLATES",
        "UNIT_PLAN_TEMPLATES",
      ],
      levelId: "L2",
      isSubsection: true,
      curriculumPrograms: ["IB_MYP"],
    },
    {
      id: "TURNITIN_SETTINGS",
      label: "configuration:turnitin_settings",
      children: [],
      route: "turnitin",
      levelId: "L2",
      perm: "AdminPortal:TurnitinSettings",
    },
    {
      id: "SUBJECT_GROUPS_AND_SUBJECTS",
      label: "common:subject_groups_and_subjects",
      children: [],
      levelId: "L3",
      route: "subjectGroupsAndSubjects",
      perm: "AdminPortal:MYPSubjects",
    },
    {
      id: "CONTINUUM",
      label: "teacherHomePage:continuum",
      children: [],
      route: "continuum",
      levelId: "L3",
      perm: "AdminPortal:Continuum",
    },
    {
      id: "CONTINUUM_MYP",
      label: "teacherHomePage:continuum",
      children: [],
      route: "continuum",
      levelId: "L3",
      perm: "AdminPortal:MYPContinuum",
    },
    {
      id: "UBD_CONTINUUM",
      label: "teacherHomePage:continuum",
      children: [],
      route: "continuum",
      levelId: "L3",
      perm: "AdminPortal:UBDContinuum",
    },
    {
      id: "LEARNING_STANDARDS",
      label: "common:content_standards",
      children: [],
      levelId: "L3",
      route: "learning-standards",
      perm: "AdminPortal:MYPLearningStandards",
    },
    {
      id: "UBD_LEARNING_STANDARD",
      label: "common:content_standards",
      children: [],
      levelId: "L3",
      plannerElementType: "UBD_LEARNING_STANDARD",
      isDynamicPlannerElementLabel: true,
      route: "ubd-learning-standards",
      perm: "AdminPortal:UBDLearningStandards",
    },
    {
      id: "MYP_LOCAL_GRADES",
      label: "common:local_grades",
      children: [],
      levelId: "L3",
      route: "local-grades",
      perm: "AdminPortal:MYPLocalGrades",
    },
    {
      id: "MYP_EVALUATION_CRITERIA",
      label: "teacherHomePage:evaluation_criteria",
      children: [],
      levelId: "L3",
      route: "evaluationCriteria",
      perm: "AdminPortal:MYPEvaluationCriteria",
    },
    {
      id: "MYP_ASSESSMENT_CRITERIA",
      label: "teacherHomePage:assessment_criteria",
      children: [],
      levelId: "L3",
      route: "assessment-criteria",
      perm: "AdminPortal:MYPAssessmentCriteria",
    },
    {
      id: "MYP_KEY_CONCEPTS",
      label: "common:key_concepts",
      children: [],
      levelId: "L3",
      route: "key-concepts",
      perm: "AdminPortal:MYP_KeyConcepts",
    },
    {
      id: "MYP_RELATED_CONCEPTS",
      label: "common:related_concepts",
      children: [],
      levelId: "L3",
      route: "related-concepts",
      perm: "AdminPortal:MYP_RelatedConcepts",
    },
    {
      id: "MYP_LEARNER_PROFILE",
      label: "common:learner_profile_attributes",
      children: [],
      levelId: "L3",
      route: "learner-profile-attributes",
      perm: "AdminPortal:MYP_LearnerProfile",
    },
    {
      id: "UBD_LEARNER_PROFILE",
      label: "common:learner_profile_attributes",
      children: [],
      levelId: "L3",
      plannerElementType: "UBD_LEARNER_PROFILE",
      isDynamicPlannerElementLabel: true,
      route: "learner-profile-attributes",
      perm: "AdminPortal:UBDLearnerProfile",
    },
    {
      id: "SUBJECTS",
      label: "common:subject_plural",
      children: [],
      levelId: "L3",
      route: "subjects",
      perm: "AdminPortal:Subjects",
    },
    {
      id: "UBD_SUBJECTS",
      label: "common:subject_plural",
      children: [],
      levelId: "L3",
      route: "subjects",
      perm: "AdminPortal:UBD_Subjects",
    },
    {
      id: "UBD_KEY_CONCEPTS",
      label: "common:key_concepts",
      children: [],
      levelId: "L3",
      plannerElementType: "UBD_KEY_CONCEPT",
      isDynamicPlannerElementLabel: true,
      route: "key-concepts",
      perm: "AdminPortal:UBDKeyConcepts",
    },
    {
      id: "CONCEPTS_RELATED_CONCEPTS",
      label: "teacherHomePage:concepts_related_concepts",
      children: [],
      levelId: "L3",
      route: "concepts",
      perm: "AdminPortal:Concepts",
    },
    {
      id: "LEARNER_PROFILES",
      label: "common:lp_label",
      children: [],
      levelId: "L3",
      route: "learnerProfiles",
      perm: "AdminPortal:LearnerProfile",
    },
    {
      id: "SCOPE_SEQUENCE",
      label: "common:content_standards",
      children: [],
      levelId: "L3",
      route: "scopeandsequence",
      perm: "AdminPortal:ScopeAndSequance",
    },
    {
      id: "ATL",
      label: "common:atls_label",
      children: [],
      levelId: "L3",
      route: "atl",
      perm: "AdminPortal:ATL",
    },
    {
      id: "UBD_ATL",
      label: "common:skill_plural",
      plannerElementType: "UBD_ATL",
      isDynamicPlannerElementLabel: true,
      children: [],
      levelId: "L3",
      route: "atl",
      perm: "AdminPortal:UBD_Atls",
    },
    {
      id: "MYP_ATL",
      label: "common:approaches_to_learning",
      plannerElementType: "MYP_ATL",
      children: [],
      levelId: "L3",
      route: "atl",
      perm: "AdminPortal:MYP_Atls",
    },
    {
      id: "EVALUATION_CRITERIA",
      label: "teacherHomePage:evaluation_criteria",
      children: [],
      levelId: "L3",
      route: "evaluationCriteria",
      perm: "AdminPortal:EvaluationCriteria",
    },
    {
      id: "ASSESSMENT_CRITERIA",
      label: "teacherHomePage:assessment_criteria",
      children: [],
      levelId: "L3",
      route: "assessment-criteria",
      perm: "AdminPortal:AssessmentCriteria",
    },
    {
      id: "ASSESSMENT_TOOL",
      label: "teacherHomePage:assessment_tool",
      children: [],
      levelId: "L3",
      route: "assessmentTool",
      perm: "AdminPortal:AssessmentTool",
    },
    {
      id: "PROGRESS_REPORT_TEMPLATE",
      label: "teacherHomePage:progress_report_templates",
      children: [],
      levelId: "L2",
      route: "progressreporttemplate",
      perm: "AdminPortal:ProgressReportTemplates",
    },
    {
      id: "UNIT_PLAN_TEMPLATES",
      label: "teacherHomePage:unit_templates",
      children: [],
      levelId: "L3",
      route: "templates",
      perm: "AdminPortal:UnitPlanTemplates",
    },
    {
      id: "STUDENT_PROFILE_TEMPLATES",
      label: "teacherHomePage:student_profile_template",
      children: [],
      levelId: "L3",
      route: "studentprofiletemplate",
      perm: "AdminPortal:StudentProfileTemplates",
    },
    {
      id: "SYNC",
      label: "integration:sync",
      children: [],
      levelId: "L3",
      route: "sync",
      perm: "AdminPortal:ShowSyncLink",
    },
    {
      id: "DOWNLOAD",
      label: "integration:support_download_sheet",
      children: [],
      levelId: "L3",
      route: "download-sheet",
      perm: "AdminPortal:DownloadSheet",
    },
  ],
};

const getCurriculumProgramMenuNodes = ({
  dynamicNodes,
  curriculumPrograms,
}) => {
  const nodes = [];
  const childrenNodes = [];

  // generate menu for all the available curriculum's
  _.forEach(curriculumPrograms, curriculumProgram => {
    const {
      type: curriculumProgramType,
      id: curriculumProgramId,
    } = curriculumProgram;
    const curriculumProgramNodes = [];

    const matchedCurriculumNode = _.find(dynamicNodes, {
      id: curriculumProgramType,
    });

    _.forEach(matchedCurriculumNode?.children, childId => {
      const matchedNode = _.find(dynamicNodes, { id: childId });
      const uid = getAdminMenuUid({
        curriculumProgramId,
        nodeId: childId,
        isDynamicNode: true,
      });

      const route = `${curriculumProgramId}/${matchedNode?.route}`;

      const childNode = {
        ...matchedNode,
        uid,
        route,
      };

      curriculumProgramNodes.push(childNode);
    });

    const updatedLabel = getCurriculumProgramLabel({
      curriculumProgram,
      shouldIncludeAcronym: true,
    });

    const uid = getAdminMenuUid({
      curriculumProgramId,
      nodeId: curriculumProgramType,
      isDynamicNode: true,
    });

    const childrenUids = _.map(curriculumProgramNodes, node => {
      return node.uid;
    });

    const newCurriculumNode = {
      ...matchedCurriculumNode,
      curriculumProgramId,
      label: updatedLabel,
      uid,
      children: childrenUids,
    };

    childrenNodes.push(...curriculumProgramNodes);
    nodes.push(newCurriculumNode);
  });

  return {
    nodes,
    childrenNodes,
  };
};

const getSchoolConfigurationsMenuNodes = ({
  staticNodes,
  curriculumPrograms,
}) => {
  const schoolConfigurationsNodes = [];

  _.forEach(staticNodes, node => {
    const newNode = _.cloneDeep(node);
    const { id, perm, label, showAllItems } = newNode;

    switch (id) {
      case "COURSE_SETUP":
      case "TEACHER_SETUP":
      case "STUDENTS":
      case "GRADES_SETUP":
      case "FAMILY_MEMBERS":
        {
          const defaultValue = {
            children: [],
            levelId: "L4",
            parent: id,
            perm,
          };

          // generating sub-menu nodes for sidebar
          const subMenuNodes = _.map(curriculumPrograms, curriculumProgram => {
            const {
              id: curriculumProgramId,
              type: curriculumProgramType,
              acronym,
            } = curriculumProgram;
            const curriculumTypeWithoutPrefix = removeIBPrefixFromCurriculumType(
              curriculumProgramType
            );
            const nodeId = `${curriculumTypeWithoutPrefix}_${id}`;

            const uid = getAdminMenuUid({
              curriculumProgramId,
              nodeId,
              isDynamicNode: true,
            });

            const locale_keys = {
              item: label,
              curriculum: acronym ?? `common:${curriculumProgramType}`,
            };

            schoolConfigurationsNodes.push({
              ...defaultValue,
              id: nodeId,
              uid,
              label: "common:admin_curriculum_items",
              locale_keys,
              route: curriculumProgramId,
            });

            return uid;
          });

          if (showAllItems) {
            const nodeId = `ALL_${id}`;
            subMenuNodes.unshift(nodeId);
            schoolConfigurationsNodes.push({
              ...defaultValue,
              uid: nodeId,
              label: "common:all_with_label",
              locale_keys: {
                label,
              },
              id: nodeId,
            });
          }

          newNode["children"] = subMenuNodes;
        }
        break;
      case "YEAR_GROUP_MANGER":
        {
          const showYearGroupForMYP = ACLStore.can(
            "FeatureFlag:ShowYearGroupInMYP"
          );
          if (showYearGroupForMYP) {
            newNode["curriculumPrograms"] = [
              ...newNode["curriculumPrograms"],
              "IB_MYP",
            ];
          }
        }
        break;
    }
    schoolConfigurationsNodes.push({ ...newNode, uid: newNode.id });
  });

  return schoolConfigurationsNodes;
};

export const parseMenuItems = ({ curriculumPrograms }) => {
  const adminMenuNodes = _.cloneDeep(AdminMenu);
  const { rootNodes, nodes } = adminMenuNodes;

  const [dynamicNodes, staticNodes] = _.partition(
    nodes,
    node => node.isDynamicNode
  );

  const newAdminMenuRootNodes = [];
  const newAdminMenuNodes = [];

  _.forEach(rootNodes, node => {
    const newRootNode = _.cloneDeep(node);
    const { id } = node;
    newRootNode["uid"] = id;

    switch (id) {
      case "CURRICULUM_CONFIGURATIONS":
        {
          const {
            nodes: menuNodes,
            childrenNodes: childrenMenuNodes,
          } = getCurriculumProgramMenuNodes({
            dynamicNodes,
            curriculumPrograms,
          });
          newRootNode.children = _.map(menuNodes, "uid");

          newAdminMenuNodes.push(...menuNodes, ...childrenMenuNodes);
          newAdminMenuRootNodes.push(newRootNode);
        }
        break;
      case "SCHOOL_CONFIGURATIONS":
        {
          const menuNodes = getSchoolConfigurationsMenuNodes({
            staticNodes,
            curriculumPrograms,
          });

          newAdminMenuNodes.push(...menuNodes);
          newAdminMenuRootNodes.push(newRootNode);
        }
        break;
    }
  });

  return {
    rootNodes: newAdminMenuRootNodes,
    nodes: newAdminMenuNodes,
  };
};
