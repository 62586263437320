import gql from "graphql-tag";
import { assignmentFragment } from "./ClassRoomFragments";
export const createAssignmentMutation = gql`
  mutation createAssignment(
    $label: String
    $leId: ID
    $resource: AssignmentResourceInput
    $contentType: ASSIGNMENT_CONTENT_TYPE_ENUM
    $isClassDiscussionEnabled: Boolean
    $isStudentSubmissionEnabled: Boolean
    $isTeacherEvaluationEnabled: Boolean
    $isStudentEvaluationEnabled: Boolean
    $isSimilarityCheckEnabled: Boolean
    $isPrivate: Boolean
    $courses: [AssignmentCourseInput!]!
    $folderNames: [String!]
    $importedLeId: ID
    $studentIds: [ID!]
    $shouldDuplicateAcrossCourses: Boolean
    $curriculumProgramId: ID
    $source: String
    $unreadMessageCountFilters: UnreadMessageCountFilters
  ) {
    platform {
      createAssignmentV2(
        input: {
          label: $label
          leId: $leId
          resource: $resource
          contentType: $contentType
          isClassDiscussionEnabled: $isClassDiscussionEnabled
          isStudentSubmissionEnabled: $isStudentSubmissionEnabled
          isTeacherEvaluationEnabled: $isTeacherEvaluationEnabled
          isStudentEvaluationEnabled: $isStudentEvaluationEnabled
          isSimilarityCheckEnabled: $isSimilarityCheckEnabled
          isPrivate: $isPrivate
          courses: $courses
          folderNames: $folderNames
          importedLeId: $importedLeId
          shouldDuplicateAcrossCourses: $shouldDuplicateAcrossCourses
          curriculumProgramId: $curriculumProgramId
          source: $source
        }
      ) {
        response {
          ...assignmentItem
        }
        warning
      }
    }
  }
  ${assignmentFragment.assignmentFeed}
`;

export const updateAssignmentMutation = gql`
  mutation updateAssignment(
    $id: ID!
    $label: String
    $resource: UpdateAssignmentResourceInput
    $deadline: String
    $isClassDiscussionEnabled: Boolean
    $isStudentSubmissionEnabled: Boolean
    $isTeacherEvaluationEnabled: Boolean
    $isStudentEvaluationEnabled: Boolean
    $isSimilarityCheckEnabled: Boolean
    $isPrivate: Boolean
    $students: StudentsInput
    $publishedAt: String
    $folders: UpdateAssignmentFolderInput
    $leId: ID
  ) {
    platform {
      updateAssignmentV2(
        input: {
          id: $id
          label: $label
          leId: $leId
          resource: $resource
          deadline: $deadline
          isClassDiscussionEnabled: $isClassDiscussionEnabled
          isStudentSubmissionEnabled: $isStudentSubmissionEnabled
          isTeacherEvaluationEnabled: $isTeacherEvaluationEnabled
          isStudentEvaluationEnabled: $isStudentEvaluationEnabled
          isSimilarityCheckEnabled: $isSimilarityCheckEnabled
          isPrivate: $isPrivate
          students: $students
          publishedAt: $publishedAt
          folders: $folders
        }
      ) {
        response {
          ...assignmentItem
        }
        warning
      }
    }
  }
  ${assignmentFragment.assignmentEdit}
`;

export const deleteAssignmentMutation = gql`
  mutation deleteAssignment($id: ID!) {
    platform {
      deleteAssignment(input: { id: $id })
    }
  }
`;

export const updateStateForAssignmentMutation = gql`
  mutation updateStateForAssignment(
    $id: ID!
    $state: ASSIGNMENT_STATE_ENUM!
    $studentIds: [ID!]
    $unreadMessageCountFilters: UnreadMessageCountFilters
  ) {
    platform {
      updateStateForAssignment(input: { id: $id, state: $state }) {
        ...assignmentItem
      }
    }
  }
  ${assignmentFragment.assignmentFeed}
`;

export const editStudentAssignmentSubmissionMutation = gql`
  mutation editStudentAssignmentSubmission(
    $id: ID!
    $response: String
    $status: STUDENT_ASSIGNMENT_SUBMISSION_ENUM
    $statusMessage: CreateMessageInput
    $checkForSimilarity: Boolean
  ) {
    platform {
      editStudentAssignmentSubmission(
        input: {
          id: $id
          response: $response
          status: $status
          statusMessage: $statusMessage
          checkForSimilarity: $checkForSimilarity
        }
      ) {
        id
        status
      }
    }
  }
`;

export const updateElementRemarkMutation = gql`
  mutation updateElementRemarkMutation($input: [IBPYPElementRemarkInput!]) {
    planner {
      createElementRemarks(input: $input) {
        response {
          id
        }
      }
    }
  }
`;

export const editStudentAssignmentMutation = gql`
  mutation editStudentAssignment(
    $id: ID!
    $isEvaluated: Boolean
    $isSharedWithStudent: Boolean
    $isEvaluationApplicable: Boolean
  ) {
    platform {
      editStudentAssignment(
        input: {
          id: $id
          isEvaluated: $isEvaluated
          isSharedWithStudent: $isSharedWithStudent
          isEvaluationApplicable: $isEvaluationApplicable
        }
      ) {
        id
        isEvaluated
        isSharedWithStudent
        isEvaluationApplicable
        status
      }
    }
  }
`;

export const assignmentBulkActionMutation = gql`
  mutation assignmentBulkAction(
    $id: ID!
    $isEvaluated: Boolean
    $isSharedWithStudent: Boolean
    $isEvaluationApplicable: Boolean
    $isReturnStudentAssignment: Boolean
    $remarkForReturnStudentAssignment: String
    $studentAssignmentIds: [ID!]
  ) {
    platform {
      assignmentBulkAction(
        input: {
          id: $id
          isEvaluated: $isEvaluated
          isSharedWithStudent: $isSharedWithStudent
          isEvaluationApplicable: $isEvaluationApplicable
          isReturnStudentAssignment: $isReturnStudentAssignment
          remarkForReturnStudentAssignment: $remarkForReturnStudentAssignment
          studentAssignmentIds: $studentAssignmentIds
        }
      ) {
        response {
          id
          assignedStudents {
            id
            isEvaluated
            isSharedWithStudent
            isEvaluationApplicable
            status
          }
        }
        warning
      }
    }
  }
`;

export const sendStudentAssignementSubmissionFeedbackMutation = gql`
  mutation sendStudentAssignementSubmissionFeedback(
    $input: StudentAssignementSubmissionFeedbackInput!
  ) {
    platform {
      sendStudentAssignementSubmissionFeedback(input: $input)
    }
  }
`;

export const updateUserMessageStateMutation = gql`
  mutation updateUserMessageState($input: UserMessageUpdateStateInput!) {
    platform {
      updateUserMessageState(input: $input)
    }
  }
`;
