import {
  studentAssessmentEvaluationFragment,
  assessmentEvaluationFragment,
  unitPlanFragment,
  courseAssessmentEvaluationFragment,
} from "./AssessmentEvaluationFragments";
import {
  getCourseAssessmentEvaluationCountQuery,
  getCourseAssessmentEvaluationFeedQuery,
  getAssessmentEvaluationDetailsQuery,
  getAssessmentEvaluationInsightQuery,
  getStudentAssessmentEvaluationQuery,
} from "./AssessmentEvaluationQuery";
import client from "apolloClient";
export const getAssessmentEvaluationDetailsFromCache = ({ evaluationId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getAssessmentEvaluationDetailsQuery,
      variables: {
        id: evaluationId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAssessmentInsightFromCache = ({ evaluationId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getAssessmentEvaluationInsightQuery,
      variables: {
        id: evaluationId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentAssessmentEvaluationFromCache = ({
  studentEvaluationId,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getStudentAssessmentEvaluationQuery,
      variables: {
        id: studentEvaluationId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeStudentAssessmentEvaluationInCache = ({
  studentEvaluationId,
  data,
}) => {
  let queryData = {};

  try {
    queryData = client.writeFragment({
      id: `StudentAssessmentEvaluation:${studentEvaluationId}`,
      fragment: studentAssessmentEvaluationFragment.studentEvaluationDetails,
      fragmentName: "studentEvaluationItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getStudentAssessmentEvaluationOfFeedFromCache = ({
  studentEvaluationId,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `StudentAssessmentEvaluation:${studentEvaluationId}`,
      fragment: studentAssessmentEvaluationFragment.studentEvaluationFeed,
      fragmentName: "studentEvaluationItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeStudentAssessmentEvaluationOfFeedInCache = ({
  studentEvaluationId,
  data,
}) => {
  let queryData = {};

  try {
    queryData = client.writeFragment({
      id: `StudentAssessmentEvaluation:${studentEvaluationId}`,
      fragment: studentAssessmentEvaluationFragment.studentEvaluationFeed,
      fragmentName: "studentEvaluationItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getResourceFromUnitplanFromCache = ({ unitPlanId, courseId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `UnitPlan:${unitPlanId}`,
      fragment: unitPlanFragment.unitPlanDetails,
      fragmentName: "unitPlanItem",
      variables: {
        courseId: courseId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCourseAssessmentEvaluationFeedFromCache = ({
  courseId,
  unitPlanIds,
  state,
  userIds,
  searchText,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseAssessmentEvaluationFeedQuery,
      variables: {
        courseId,
        unitPlanIds,
        state,
        searchText,
        userIds,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeCourseAssessmentEvaluationFeed = ({
  courseId,
  unitPlanIds,
  state,
  userIds,
  searchText,
  data,
}) => {
  client.writeFragment({
    id: `Course:${courseId}`,
    fragment: courseAssessmentEvaluationFragment.courseAssessmentEvaluationFeed,
    fragmentName: "courseAssessmentEvaluationItem",
    variables: {
      unitPlanIds: unitPlanIds,
      state: state,
      searchText: searchText,
      userIds: userIds,
    },
    data,
  });
};

export const getCourseAssessmentEvaluationCountFromCache = ({ courseId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseAssessmentEvaluationCountQuery,
      variables: {
        courseId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeCourseAssessmentEvaluationCount = ({ courseId, data }) => {
  client.writeFragment({
    id: `Course:${courseId}`,
    fragment:
      courseAssessmentEvaluationFragment.courseAssessmentEvaluationCount,
    fragmentName: "courseAssessmentEvaluationItem",
    data,
  });
};

export const getCourseAssessmentEvaluationIdsFromCache = ({ courseId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Course:${courseId}`,
      fragment:
        courseAssessmentEvaluationFragment.courseAssessmentEvaluationIds,
      fragmentName: "courseAssessmentEvaluationItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};
