import { createContext } from "react";
import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import moment from "moment";
import {
  updateSnPEvaluationCycleMutation,
  createSnPEvaluationCycleMutation,
  upsertSnpSelfStudyResponseMutation,
  upsertSnpSelfStudySectionMutation,
  updateSelfStudyMutation,
  createSnPActionMutation,
  updateSnPActionMutation,
  deleteSnPActionMutation,
  handleSnPEvidenceInPracticeMutation,
} from "./SnPMutations";
import {
  getSnPEvaluationCyclesQuery,
  getSnPSetActionsQuery,
  getSnPPracticeActionDetailsQuery,
} from "./SnPQueries";
import {
  writeSelfStudyResponseInCache,
  getSelfStudyResponseFromCache,
  getSelfStudySectionFromCache,
  writeSelfStudySectionInCache,
  writeSnPSelfStudyDetailsInCache,
  getSnPSelfStudyDetailsFromCache,
  writePracticeActionDetailsInCache,
  getPracticeActionFragmentFromCache,
  getActionDetailsFromCache,
} from "./SnPGraphqlHelpers";
import { updateField } from "modules/Services";
import client from "apolloClient";

export const NAME = "snp";
export const UPDATE_PRACTICES_FILTER_OBJECT =
  "UPDATE_PRACTICES_FILTER_OBJECT" + " " + NAME;

export const updatePracticesFilterObject = data => {
  return { type: UPDATE_PRACTICES_FILTER_OBJECT, data: data };
};

export const PracticeViewContext = createContext({});

export const updateSnPEvaluationCycle = ({ id, data }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateSnPEvaluationCycleMutation,
        variables: {
          id,
          ...data,
        },
      });
    } catch (error) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const createSnPEvaluationCycle = input => {
  return async (dispatch, getState) => {
    try {
      const organizationId = getState().login.userInfo.org_id;
      await client.mutate({
        mutation: createSnPEvaluationCycleMutation,
        variables: {
          input: _.map(input, inp => {
            return { ...inp, templateType: "SELF_STUDY_2014" };
          }),
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getSnPEvaluationCyclesQuery,
            variables: {
              id: organizationId,
            },
          },
        ],
      });
    } catch (error) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const updateSelfStudyResponse = ({
  selfStudyId,
  id,
  questionId,
  value,
}) => {
  return async (dispatch, getState) => {
    const selfStudyResponseData = getSelfStudyResponseFromCache({ id });
    writeSelfStudyResponseInCache({
      id,
      data: { ...selfStudyResponseData, response: value },
    });
    dispatch(
      updateField({
        key: id,
        params: {
          selfStudyId,
          questionId,
          value,
        },
        type: "SELF_STUDY_TEMPLATE_RESPONSE",
        isDebounce: true,
      })
    );
  };
};

export const updateSelfStudyResponseOnServer = ({
  selfStudyId,

  questionId,
  value,
}) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: upsertSnpSelfStudyResponseMutation,
        variables: {
          selfStudyId,
          questionId,
          response: value,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const updateSelfStudySection = ({
  selfStudyId,
  id,
  sectionId,
  data,
  type,
}) => {
  return async (dispatch, getState) => {
    const selfStudySectionData = getSelfStudySectionFromCache({ id });

    writeSelfStudySectionInCache({
      id,
      data: { ...selfStudySectionData, ...data },
    });
    try {
      await client.mutate({
        mutation: upsertSnpSelfStudySectionMutation,
        variables: {
          selfStudyId,
          sectionId,
          type,
          ...data,
        },
      });
    } catch (e) {
      writeSelfStudySectionInCache({
        id,
        data: selfStudySectionData,
      });
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const updateSelfStudyDetails = ({ id, data }) => {
  return async (dispatch, getState) => {
    const selfStudyDetails = getSnPSelfStudyDetailsFromCache(id);

    writeSnPSelfStudyDetailsInCache({
      id,
      data: { ..._.omit(selfStudyDetails, ["responses", "sections"]), ...data },
    });

    try {
      await client.mutate({
        mutation: updateSelfStudyMutation,
        variables: {
          id,
          ...data,
        },
      });
    } catch (e) {
      writeSnPSelfStudyDetailsInCache({
        id,
        data: {
          ..._.omit(selfStudyDetails, ["responses", "sections"]),
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const createSnPAction = ({
  evaluationCycleId,
  practiceIds,
  fields,
  milestones,
  evidence,
}) => {
  return async (dispatch, getState) => {
    const fieldsArr = _.map(fields, (value, key) => {
      return { uid: key, value };
    });

    const milestonesArr = _.map(
      _.filter(milestones, milestone => milestone.value.trim() !== ""),
      milestone => {
        const { value, isAchieved } = milestone;

        return {
          message: value,
          isAchieved,
        };
      }
    );

    const evidenceArr = _.map(evidence, evidenceItem => {
      return _.omit(evidenceItem, ["id"]);
    });

    let result = null;
    try {
      result = await client.mutate({
        mutation: createSnPActionMutation,
        variables: {
          evaluationCycleId,
          practiceIds,
          fields: fieldsArr,
          milestones: milestonesArr,
          evidence: evidenceArr,
        },
        update: (
          cache,
          {
            data: {
              platform: { createSnPAction },
            },
          }
        ) => {
          _.map(practiceIds, practiceId => {
            let SnPPracticeDetails = getPracticeActionFragmentFromCache({
              id: practiceId,
              cycleId: evaluationCycleId,
            });
            if (_.isEmpty(SnPPracticeDetails.actions)) {
              SnPPracticeDetails = update(SnPPracticeDetails, {
                ["actions"]: {
                  $set: {
                    ["totalCount"]: 0,
                    ["edges"]: [],
                    ["__typename"]: "SnPActionConnection",
                  },
                },
              });
            }
            SnPPracticeDetails = update(SnPPracticeDetails, {
              actions: {
                edges: {
                  $push: [
                    {
                      ["node"]: createSnPAction,
                      ["__typename"]: "SnPActionEdge",
                    },
                  ],
                },
              },
            });
            SnPPracticeDetails = update(SnPPracticeDetails, {
              actions: {
                totalCount: { $set: SnPPracticeDetails.actions.totalCount + 1 },
              },
            });
            writePracticeActionDetailsInCache({
              id: practiceId,
              data: SnPPracticeDetails,
              cycleId: evaluationCycleId,
            });
          });
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      throw "Error_in_create_snp_action";
    }
    return result;
  };
};

export const updateSnPAction = ({
  actionId,
  fields,
  milestones,
  practiceIds,
  evidence,
  cycleId,
  practiceId: rightPanePracticeId,
}) => {
  return async (dispatch, getState) => {
    const queryResult = getActionDetailsFromCache({ id: actionId });
    const practicesFromQueryResult = _.get(queryResult, "practices", []);
    const milestonesFromQueryResult = _.get(queryResult, "milestones", []);
    const evidenceFromQueryResult = _.get(queryResult, "evidence", []);

    const fieldsArr = _.map(fields, (value, key) => {
      return { uid: key, value };
    });
    const addedPracticeIds = _.filter(practiceIds, practiceId => {
      return (
        _.findIndex(
          practicesFromQueryResult,
          practice => practice.id == practiceId
        ) < 0
      );
    });
    const removedPracticeIds = _.map(
      _.filter(practicesFromQueryResult, ({ id }) => {
        return _.findIndex(practiceIds, practiceId => practiceId == id) < 0;
      }),
      ({ id }) => {
        return id;
      }
    );
    const addedEvidence = _.map(
      _.filter(evidence, ({ id }) => {
        return (
          _.findIndex(
            evidenceFromQueryResult,
            evidenceItem => evidenceItem.id == id
          ) < 0
        );
      }),
      evidenceItem => {
        return _.omit(evidenceItem, ["id"]);
      }
    );
    const removedEvidence = _.map(
      _.filter(evidenceFromQueryResult, ({ id }) => {
        return _.findIndex(evidence, evidenceItem => evidenceItem.id == id) < 0;
      }),
      ({ id }) => {
        return id;
      }
    );
    const milestonesArr = _.filter(
      milestones,
      milestone => milestone.value.trim() !== ""
    );
    const addedMilestones = _.map(
      _.filter(milestonesArr, ({ id }) => {
        return (
          _.findIndex(
            milestonesFromQueryResult,
            milestone => milestone.id == id
          ) < 0
        );
      }),
      ({ value: message, isAchieved }) => {
        return { message, isAchieved };
      }
    );

    const removedMilestones = _.map(
      _.filter(milestonesFromQueryResult, ({ id }) => {
        return (
          _.findIndex(
            milestonesArr,
            ({ id: milestoneId }) => milestoneId == id
          ) < 0
        );
      }),
      ({ id }) => {
        return id;
      }
    );

    const updatedMilestones = _.map(
      _.filter(milestonesArr, ({ id, value, isAchieved }) => {
        return (
          _.findIndex(
            milestonesFromQueryResult,
            milestone =>
              milestone.id == id &&
              (milestone.message !== value ||
                milestone.isAchieved !== isAchieved)
          ) > -1
        );
      }),
      ({ id, value: message, isAchieved }) => {
        return { id, message, isAchieved };
      }
    );

    const setId = getState().login.userInfo.currentSnPSet;

    let result = null;
    try {
      result = await client.mutate({
        mutation: updateSnPActionMutation,
        variables: {
          id: actionId,
          fields: fieldsArr,
          addedPracticeIds,
          removedPracticeIds,
          addedMilestones,
          removedMilestones,
          updatedMilestones,
          addedEvidence,
          removedEvidence,
        },
        refetchQueries: [
          {
            query: getSnPSetActionsQuery,
            variables: {
              id: setId,
              evaluationCycleIds: cycleId,
            },
          },
          {
            query: getSnPPracticeActionDetailsQuery,
            variables: {
              id: rightPanePracticeId,
              evaluationCycleIds: cycleId,
            },
          },
        ],
        update: (
          cache,
          {
            data: {
              platform: { updateSnPAction },
            },
          }
        ) => {},
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      throw "Error_in_create_snp_action";
    }
    return result;
  };
};

export const microUpdateSnPAction = ({
  actionId,
  fields,
  updatedMilestones,
}) => {
  return async (dispatch, getState) => {
    let result = null;
    try {
      result = await client.mutate({
        mutation: updateSnPActionMutation,
        variables: {
          id: actionId,
          updatedMilestones,
          fields,
        },
        update: (
          cache,
          {
            data: {
              platform: { microUpdateSnPAction },
            },
          }
        ) => {},
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      throw "Error_in_create_snp_action";
    }
    return result;
  };
};

export const deleteSnPAction = ({ actionId }) => {
  return async (dispatch, getState) => {
    let result = null;
    try {
      result = await client.mutate({
        mutation: deleteSnPActionMutation,
        variables: {
          id: actionId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [],
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      throw "Error_in_create_snp_action";
    }
    return result;
  };
};

export const handleSnPEvidenceInPractice = ({
  id,
  removedEvidence,
  addedEvidence,
}) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: handleSnPEvidenceInPracticeMutation,
        variables: {
          id,
          removedEvidence,
          addedEvidence,
        },
        update: (
          cache,
          {
            data: {
              platform: { handleSnPEvidenceInPractice },
            },
          }
        ) => {},
      });
    } catch (e) {
      console.error(e);
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_PRACTICES_FILTER_OBJECT]: (state, action) => {
    const params = action.data;

    Object.keys(params).map((key, index) => {
      state = update(state, {
        practicesFilters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
};

const initialState = {
  practicesFilters: {
    searchText: "",
    showOnlyPracticeWithAction: false,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
