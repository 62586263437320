import { getMemoizedFunction } from "Utils";

const getTotalValuesInGroup = ({ valueList }) => {
  return _.reduce(
    valueList,
    (result, { values }) => {
      return result + _.size(values);
    },
    0
  );
};

export const getTotalValuesInGroupMemoized = getMemoizedFunction(
  getTotalValuesInGroup
);
