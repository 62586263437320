import PropTypes from "prop-types";
import React from "react";

const LineArrowIcon = props => {
  return (
    <svg viewBox="0 0 13 8" width={props.width} height={props.height}>
      <g
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        fill={props.fill && props.fill}
      >
        <path d="M13,3.9614 C13,3.9987 12.9854,4.0294 12.98,4.0628 C12.972,4.1149 12.9693,4.1696 12.948,4.2176 C12.9146,4.3017 12.8626,4.3751 12.7998,4.4378 L9.5072,7.7292 C9.2456,7.9907 8.8238,7.9907 8.5636,7.7292 C8.302,7.4689 8.302,7.0471 8.5636,6.7855 L10.7204,4.63 L0.6673,4.63 C0.2976,4.63 0,4.3311 0,3.9627 C0,3.5943 0.2976,3.2954 0.6673,3.2954 L10.7218,3.2954 L8.5636,1.1399 C8.302,0.8783 8.302,0.4565 8.5636,0.1962 C8.693,0.0654 8.8639,0 9.0347,0 C9.2056,0 9.3764,0.0654 9.5072,0.1962 L12.8052,3.4889 C12.8372,3.5223 12.8559,3.565 12.8813,3.6037 C12.9026,3.6384 12.932,3.6664 12.948,3.7038 C12.9814,3.7852 13,3.8719 13,3.9614 Z" />
      </g>
    </svg>
  );
};

LineArrowIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

LineArrowIcon.defaultProps = {
  height: 8,
  width: 13,
};

export default LineArrowIcon;
