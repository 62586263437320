import { getMemoizedFunction } from "Utils";
import { tabFiltersConfig } from "../../../modules/Config";

const getApplicableFiltersInfo = ({ moduleFacets, activeTab }) => {
  const entityTypeInfo = tabFiltersConfig[activeTab].entityTypeInfo;

  const filtersInfo = _.map(moduleFacets, moduleFace => {
    const { entityType, levels, attributes } = moduleFace;
    const { filterLocale, tabDisplayOrder, isCheckList } = entityTypeInfo[
      entityType
    ];

    return {
      filter: entityType,
      key: entityType + " " + activeTab, //here activeTab is added to make filters unique across different tabs
      levels,
      attributes,
      label: filterLocale,
      isCheckList,
      order: tabDisplayOrder,
    };
  });

  return _.sortBy(filtersInfo, ({ order }) => order);
};

export const getApplicableFiltersInfoMemoized = getMemoizedFunction(
  getApplicableFiltersInfo
);

const getFilterValuesToBeSelected = ({ filterOptions }) => {
  return _.map(filterOptions, ({ value }) => value);
};

export const getFilterValuesToBeSelectedMemoized = getMemoizedFunction(
  getFilterValuesToBeSelected
);

const getFilterListValuesToBeSelected = ({ filterListOptions }) => {
  return _.map(filterListOptions, ({ key, options }) => {
    const values = _.map(options, ({ value }) => value);
    return { key, values };
  });
};

export const getFilterListValuesToBeSelectedMemoized = getMemoizedFunction(
  getFilterListValuesToBeSelected
);

const getFiltersValuesToBeSelected = ({
  allFiltersOptions,
  filterKeyToLevelMapping,
  filterKeys,
}) => {
  const updatedFilters = _.reduce(
    filterKeys,
    (acc, key) => {
      const filterLevel = _.get(filterKeyToLevelMapping, key, 1);
      if (filterLevel === 1) {
        const filterOptions = _.get(allFiltersOptions, key, []);
        const selectedValues = getFilterValuesToBeSelectedMemoized({
          filterOptions,
        });

        return { ...acc, [key]: selectedValues };
      }
      const filterListOptions = _.get(allFiltersOptions, key, []);
      const selectedValues = getFilterListValuesToBeSelectedMemoized({
        filterListOptions,
      });
      return { ...acc, [key]: selectedValues };
    },
    {}
  );
  return updatedFilters;
};

export const getFiltersValuesToBeSelectedMemoized = getMemoizedFunction(
  getFiltersValuesToBeSelected
);

/**This util will update values such that if there are values having same labels,
 * then out of those values only one will remain and rest are discarded.
 */
export const getUniqueSelectedValues = ({ values, options }) => {
  const labels = _.reduce(
    options,
    (result, option) => {
      const { label, value } = option;
      const isOptionSelected = _.includes(values, value);
      if (isOptionSelected) {
        return [...result, label];
      }
      return result;
    },
    []
  );

  const uniqLabels = _.uniq(labels);

  const uniqValues = [];

  _.forEach(uniqLabels, label => {
    let option = _.find(options, ["label", label]);
    if (!_.isEmpty(option)) {
      uniqValues.push(option.value);
    }
  });

  return uniqValues;
};

export const checkIfFilterDisabled = ({
  parentFilterTotalSelectedOptions,
  totalOptions,
  selectOnlyOneOptionInParentFilter,
  isDependentFilter,
  areDependentFiltersLoaded,
}) => {
  if (isDependentFilter && !areDependentFiltersLoaded) return true;

  if (!totalOptions) return true;

  if (
    isDependentFilter &&
    selectOnlyOneOptionInParentFilter &&
    parentFilterTotalSelectedOptions !== 1
  )
    return true;

  return false;
};

const findLabel = ({ value, options }) => {
  const { label = "" } = _.find(options, ["value", value]) ?? {};
  return label;
};

export const findLabelMemoized = getMemoizedFunction(findLabel);
