import gql from "graphql-tag";
import { gradesItemFragment } from "./ScoreCategoriesFragments";

export const getSubjectBasedCoursesQuery = gql`
  query getSubjectBasedCourses($id: ID!, $filters: CourseFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        courses(filters: $filters) {
          edges {
            node {
              id
              title
              grades {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const getSubjectDetailsQuery = gql`
  query getSubjectsForLearningStandards(
    $organizationId: ID!
    $filters: SubjectFilterInput
    $pointAssessmentFilters: SubjectPointAssessmentSetFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        subjects(filters: $filters) {
          id
          name
          icon
          pointAssessmentSets(filters: $pointAssessmentFilters) {
            totalCount
          }
        }
      }
    }
  }
`;

export const getOrganizationPointSetQuery = gql`
  query getOrganizationPointSet($id: ID!, $filters: PointAssessmentSetFilter) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        pointAssessmentSets(filters: $filters) {
          totalCount
          edges {
            node {
              id
              label
              type
              associatedEntities {
                id
                ... on Course {
                  id
                  title
                }
                ... on Subject {
                  id
                  name
                }
                ... on Grade {
                  ...gradeItem
                }
              }
              categories {
                id
                label
                colorCode
                percentageWeight
                hlWeight
                slWeight
              }
            }
          }
        }
      }
    }
  }
  ${gradesItemFragment.gradeFragment}
`;
