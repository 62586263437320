import { withAsyncRouteLoading } from "UIComponents";
import UnitPlanDetails from "UnitPlanDetails";
import { AssessmentDetails } from "Assessments";
import Collection from "./routes/Collection";
import UnitQuickView from "./routes/UnitQuickView";
export default store => {
  return {
    path: ":entityType",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community-entity"
          );
        })
    ),
    childRoutes: [
      UnitQuickView(store),
      Collection(store),
      AssessmentDetails(store),
      UnitPlanDetails(store),
    ],
  };
};
