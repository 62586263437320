import gql from "graphql-tag";
import { subjectFragment } from "modules/CommonFragments";

export const organizationSubjectsFragment = {
  organizationSubjects: gql`
    fragment organizationItem on Organization {
      id
      subjects(filters: $filters) {
        ...subjectItem
      }
    }
    ${subjectFragment.subjectBenchmarkDetails}
  `,
};
