import React, { useState } from "react";
import { CheckListContainer, SearchBar } from "UIComponents";

const CheckListWithSearch = props => {
  const {
    updateInputField,
    checkListContainerStyle,
    listItemStyle,
    value,
    options,
    showAllOption,
    allItemStyle,
  } = props;

  const [searchText, setSearchText] = useState("");

  const matchingOptions = _.filter(options, ({ label }) => {
    return _.includes(_.toLower(label), _.toLower(searchText));
  });

  return (
    <React.Fragment>
      <SearchBar
        changeSearchTerm={setSearchText}
        placeholder={"Search organizations"}
      />
      {_.size(matchingOptions) ? (
        <CheckListContainer
          updateInputField={updateInputField}
          checkListContainerStyle={checkListContainerStyle}
          listItemStyle={listItemStyle}
          value={value}
          options={matchingOptions}
          showAllOption={showAllOption}
          allItemStyle={allItemStyle}
        />
      ) : (
        <div>No organizations</div>
      )}
    </React.Fragment>
  );
};

export default CheckListWithSearch;
