import React from "react";
import classes from "./Sidebar.scss";
import classNames from "classnames";

const SidebarItem = props => {
  const {
    label,
    itemClassList,
    updateActiveTab,
    selectedCount,
    id,
    showNotificationDot,
  } = props;

  const handleTabClick = () => {
    updateActiveTab(id);
  };

  return (
    <div className={itemClassList} onClick={handleTabClick}>
      <div className={classes.content}>
        <span className={classes.label}>{label}</span>
      </div>
      {selectedCount > 0 && (
        <span className={classes.itemCount}>{selectedCount}</span>
      )}
      {showNotificationDot && <span className={classes.dot} />}
    </div>
  );
};

SidebarItem.defaultProps = {
  showNotificationDot: false,
};

const Sidebar = props => {
  const {
    t,
    activeTab,
    updateActiveTab,
    label,
    evidenceOptions,
    suggestedEvidenceOptions,
    selectedCards,
  } = props;

  const generateSidebarTabs = ({ options }) => {
    return _.map(options, ({ key, config }) => {
      const isActiveTab = key === activeTab;
      const itemClassList = classNames(
        { [classes.item]: true },
        { [classes.activeItem]: isActiveTab }
      );

      return (
        <SidebarItem
          key={key}
          id={key}
          label={_.capitalize(t(config.label))}
          itemClassList={itemClassList}
          updateActiveTab={updateActiveTab}
          selectedCount={_.size(selectedCards[key])}
          showNotificationDot={config?.showNotificationDot}
        />
      );
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.addEvidenceLabel}>{t(`common:${label}`)}</div>

      {!_.isEmpty(suggestedEvidenceOptions) && (
        <>
          {generateSidebarTabs({ options: suggestedEvidenceOptions })}
          <hr className={classes.separator} />
        </>
      )}

      {generateSidebarTabs({ options: evidenceOptions })}
    </div>
  );
};

export default Sidebar;
