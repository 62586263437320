export const getCurriculumProgramsHavingBuddies = ({ curriculumPrograms }) => {
  return _.filter(curriculumPrograms, curriculumProgram => {
    const buddy = _.get(curriculumProgram, "buddy", {});
    return !_.isEmpty(buddy);
  });
};

export const getBuddyName = ({ buddy }) => {
  return `${_.get(buddy, "firstName", "")} ${_.get(buddy, "lastName", "")}`;
};
