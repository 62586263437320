import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import AssessmentDetails from "./AssessmentDetails";

export default store => {
  return {
    path: "assessments/:assessmentId/:mode",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./AssessmentDetails").default;
              const injectedModule = require("Assessments/modules/AssessmentModule");
              const OrganizationResourcesReducer = require("OrganizationResources/modules/OrganizationResourcesModule")
                .default;
              injectReducer(store, {
                key: injectedModule.NAME,
                reducer: injectedModule.default,
              });
              const reducer = require("UnitPlans/modules/UnitPlanModule")
                .default;
              injectReducer(store, { key: "unitPlans", reducer });
              injectReducer(store, {
                key: "organizationResource",
                reducer: OrganizationResourcesReducer,
              });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
  };
};

export { AssessmentDetails as AssessmentDetailsComponent };
