/**Use this function to create regex from user input */
/**This Util function will escape all special regex symbols and then create regex */

//ref: https://stackoverflow.com/questions/16168484/javascript-syntax-error-invalid-regular-expression

/**
 *
 * @param {string} text - text from which regex will be created
 * @param {string} flags - regex flags like "g","i","gi" etc.
 */
export const createRegex = ({ text, flags }) => {
  const sanitizedText = text.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
  return new RegExp(sanitizedText, flags);
};
