import { colors } from "Constants";

import hexToRgba from "hex-to-rgba";

export const overlayStyle = {
  backgroundColor: hexToRgba(colors.textDefault, 0.75),
};

export const containerStyle = {
  width: "unset",
  flex: 1,
};
