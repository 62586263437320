import React from "react";
import classes from "./CaptureImage.scss";
import ScreenPanel from "../../ScreenPanel";
import { I18nHOC, FullScreenLoader } from "UIComponents";
import { compose } from "react-apollo";
import Webcam from "react-webcam";
import { generateRandomId } from "Utils";
import { CameraIconV2, RotateIcon } from "SvgComponents";
import { checkIfMultipleCamerasIsPresent } from "Post/utils";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";
import _ from "lodash";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

function dataURLtoBlob(dataUrl) {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const CaptureImageButton = ({ onClick }) => {
  return (
    <div className={classes.captureImageButton} onClick={onClick}>
      <CameraIconV2 />
    </div>
  );
};

const FlipCameraButton = ({ onClick }) => {
  return (
    <div className={classes.flipCameraButton} onClick={onClick}>
      <RotateIcon />
    </div>
  );
};

class CaptureImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.state = {
      facingMode: FACING_MODE_USER,
      hasMultipleCameras: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      screenPanelProps: { updatePost },
      setToastMsg,
    } = this.props;
    checkIfMultipleCamerasIsPresent()
      .then(isPresent => {
        this.setState({
          hasMultipleCameras: isPresent,
          isLoading: false,
          facingMode: isPresent ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER,
        });
      })
      .catch(e => {
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
        });
        this.setState({ isLoading: false });
      });
    updatePost({ attachments: [] });
  }

  handleTakeScreenshotClick = () => {
    const {
      screenPanelProps: {
        updatePost,
        goToStep,
        postDetails: { attachments = [] },
      },
    } = this.props;
    // get base64 encoded string
    const dataUrl = this.webcamRef.current.getScreenshot();
    // generate random id
    const uploadId = generateRandomId();

    // get image resolution
    let imgHeight = 720;
    let imgWidth = 1280;

    if (_.get(this.webcamRef, "current.stream", null)) {
      const webcamSettings = this.webcamRef.current.stream
        ?.getVideoTracks?.()?.[0]
        ?.getSettings?.();
      imgHeight = _.get(webcamSettings, "height", imgHeight);
      imgWidth = _.get(webcamSettings, "width", imgWidth);
    }

    if (dataUrl) {
      // convert data url to blob
      const dataBlob = dataURLtoBlob(dataUrl);
      // create blob url from blob
      const blobUrl = URL.createObjectURL(dataBlob);
      // file name
      const filename = "IMG_".concat(uploadId);
      // create file object
      const file = new File([dataBlob], filename, {
        type: dataBlob.type,
        lastModified: Date.now(),
      });

      const attachment = {
        file: file,
        id: uploadId,
        metadata: { height: imgHeight, size: dataBlob.size, width: imgWidth },
        mimeType: dataBlob.type,
        name: filename,
        type: "IMAGE",
        url: blobUrl,
      };

      updatePost({ attachments: [...attachments, attachment], uploadId });
      if (goToStep) goToStep("POST_DETAIL");
    }
  };

  handleError = e => {
    const { setToastMsg } = this.props;
    const errorMessage = _.get(e, "message", "");
    if (!_.isEmpty(errorMessage)) {
      setToastMsg({
        msg: "toastMsgs:enable_dynamic_permission",
        locale_params: [
          { key: "permission", value: "camera", isPlainText: true },
        ],
      });
    }
    /*if (
      !_.isEmpty(errorMessage) &&
      _.isEqual(errorMessage, "Permission denied")
    ) {
      setToastMsg({
        msg: "toastMsgs:enable_dynamic_permission",
        locale_params: [
          { key: "permission", value: "camera", isPlainText: true },
        ],
      });
    } else {
      setToastMsg({
        msg: "toastMsgs:something_went_wrong",
      });
    }*/
  };

  flipCamera = () => {
    if (this.state.hasMultipleCameras) {
      this.setState(({ facingMode }) => ({
        facingMode:
          facingMode === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER,
      }));
    }
  };

  render() {
    const { screenPanelProps = {}, t } = this.props;
    const { facingMode, hasMultipleCameras, isLoading } = this.state;
    return (
      <ScreenPanel {...screenPanelProps} containerStyle={{ padding: 0 }}>
        {isLoading && <FullScreenLoader />}
        <div className={classes.captureImageContainer}>
          <Webcam
            className={classes.webcamScreen}
            ref={this.webcamRef}
            forceScreenshotSourceSize={false}
            imageSmoothing={true}
            mirrored={false}
            onUserMediaError={this.handleError}
            screenshotFormat={"image/jpeg"}
            screenshotQuality={0.92}
            videoConstraints={{
              facingMode: facingMode,
            }}
          />
          <div className={classes.captureCameraBtnContainer}>
            <div className={classes.mediaControls}>
              <CaptureImageButton onClick={this.handleTakeScreenshotClick} />
              <CaptureImageButton onClick={this.handleTakeScreenshotClick} />
            </div>
            {hasMultipleCameras ? (
              <div className={classes.rightSideCameraOptions}>
                <FlipCameraButton onClick={this.flipCamera} />
              </div>
            ) : null}
          </div>
        </div>
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "common" })
)(CaptureImage);
