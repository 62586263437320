import React from "react";
import PropTypes from "prop-types";
import classes from "./StudentSelection.scss";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, ProfileCascade } from "UIComponents";
import update from "immutability-helper";
import {
  getCourseUserMapFromCache,
  getAssignedStudentsFromCache,
} from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import {
  getCourseUserMapQuery,
  getAssignedStudentsQuery,
} from "ClassRoom/modules/ClassRoomQuery";
import { getUpdatedArchivedFilters, localSearch } from "Utils";
import { Avatar, Checkbox as DSCheckbox, Tag } from "@toddle-design/web";
import { GENERIC_TAGS_INFO } from "modules/Services";
import { AVATAR_COLORS } from "Constants/colorConstants";

const styles = {
  avatarStyles: {
    size: "medium",
    shape: "square",
    iconType: "individual",
    shade: "light",
    randomizeColor: true,
  },
};

const StudentSelectionItem = React.memo(
  ({ student, toggleCheckbox, isSelected, t }) => {
    const { firstName, lastName, profileImage, id, isArchived } = student;
    const name = `${firstName} ${lastName}`;
    const tags = _.get(student, "tags", []);
    return (
      <div className={classes.studentItemRow} onClick={toggleCheckbox}>
        <div className={classes.avatarContainer}>
          <div className={classes.avatarInnerContainer}>
            <ProfileCascade
              items={[{ id, value: name, imageUrl: profileImage }]}
              isArchived={isArchived}
              showAvatar={true}
              avatarStyles={styles.avatarStyles}
            />
            <div className={classes.labelTagContainer}>
              <div className={classes.labelInnerContainer}>
                <div className={classes.labelContainer}>{name}</div>
                {isArchived && (
                  <div className={classes.archivedText}>
                    {`(${t("common:archived")})`}
                  </div>
                )}
              </div>

              {!_.isEmpty(tags) && (
                <div className={classes.tagsContainer}>
                  {_.map(tags, item => {
                    return (
                      <Tag
                        key={item.label}
                        size={"xx-small"}
                        color={_.get(
                          GENERIC_TAGS_INFO,
                          `${item.label}.color`,
                          "teal"
                        )}
                      >
                        {item.label}
                      </Tag>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <DSCheckbox isChecked={isSelected} />
        </div>
      </div>
    );
  }
);

StudentSelectionItem.displayName = "StudentSelectionItem";

const StudentSelection = React.memo(
  ({
    includedStudents,
    students,
    t,
    updateStudentArray,
    selectedItemType,
    searchTerm,
  }) => {
    const toggleCheckbox = item => {
      let newIncludedStudents = [...includedStudents];
      const index = _.findIndex(newIncludedStudents, includedStudent => {
        return selectedItemType == "OBJECT"
          ? includedStudent.id == item.id
          : includedStudent == item.id;
      });
      if (index != -1) {
        newIncludedStudents = update(newIncludedStudents, {
          $splice: [[index, 1]],
        });
      } else {
        newIncludedStudents = update(newIncludedStudents, {
          $splice: [[index, 0, item]],
        });
      }
      updateStudentArray(newIncludedStudents);
    };

    const toggleAllCheckbox = () => {
      if (
        _.get(includedStudents, "length", 0) == _.get(students, "length", 0)
      ) {
        updateStudentArray([]);
      } else {
        updateStudentArray(
          selectedItemType == "OBJECT"
            ? students
            : _.map(students, student => student.id)
        );
      }
    };

    const isAllSelected =
      _.get(includedStudents, "length", 0) == _.get(students, "length", 0);

    return (
      <div className={classes.studentFeedContainer}>
        {_.isEmpty(searchTerm) && (
          <div className={classes.studentItemRow} onClick={toggleAllCheckbox}>
            <div className={classes.avatarContainer}>
              <div className={classes.avatarInnerContainer}>
                <Avatar
                  size={"medium"}
                  shade={"light"}
                  shape={"square"}
                  color={
                    AVATAR_COLORS[_.size(students) % _.size(AVATAR_COLORS)]
                  }
                  iconType={"group"}
                />
                <div className={classes.labelContainer}>
                  {t("common:all_label", {
                    label: t("common:student_plural"),
                  })}
                </div>
              </div>
              <DSCheckbox isChecked={isAllSelected} />
            </div>
          </div>
        )}
        {_.map(students, student => {
          const isSelected =
            _.findIndex(includedStudents, includedStudent =>
              selectedItemType == "OBJECT"
                ? includedStudent.id == student.id
                : includedStudent == student.id
            ) >= 0;
          return (
            <StudentSelectionItem
              student={student}
              toggleCheckbox={() => toggleCheckbox(student)}
              key={student.id}
              isSelected={isSelected}
              t={t}
            />
          );
        })}
      </div>
    );
  }
);

StudentSelection.propTypes = {
  includedStudents: PropTypes.array,
  isMultiSelect: PropTypes.bool,
  selectedItemType: PropTypes.string,
};

StudentSelection.defaultProps = {
  includedStudents: [],
  isMultiSelect: true,
  selectedItemType: "ID",
};

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const { mode } = ownProps;
  const searchText = _.get(ownProps, "searchTerm");
  const assignmentId = _.get(ownProps, "assignmentId");
  const assignmentTags = _.get(ownProps, "assignmentTags");

  const archivedFilters =
    getUpdatedArchivedFilters(
      {
        ...(mode === "create" ? { searchText } : {}),
      },
      { entityType: "ASSIGNMENT", entityId: assignmentId }
    ) || {};

  const courseUserMapFilters = {
    ...archivedFilters,
    tags: assignmentTags,
    userTypes: ["student"],
  };

  return {
    parentId: ownProps.parentId,
    isData: true,
    isLoading: false,
    courseUserMapFilters,
    searchText,
  };
};

const getStudents = ({ data }) => {
  if (!data) {
    return [];
  }

  const studentsList = _.map(data, item => {
    return {
      ..._.get(item, "node.user", {}),
      tags: _.get(item, "node.tags", []),
    };
  });

  const activeStudents = [];
  const archivedStudents = [];

  _.forEach(studentsList, student => {
    const { isArchived = false } = student;

    if (isArchived) {
      archivedStudents.push(student);
    } else {
      activeStudents.push(student);
    }
  });

  const allStudents = [...activeStudents, ...archivedStudents];

  return allStudents;
};

export default compose(
  I18nHOC({ replace: "common" }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseUserMapQuery, {
    name: "getCourseUserMap",
    skip: ({ parentType }) => parentType === "Organization",
    options: ({ parentId, portalType, courseUserMapFilters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: parentId,
        portalType,
        filters: courseUserMapFilters,
      },
    }),
    props({
      getCourseUserMap,
      ownProps: { isData, isLoading, parentId, courseUserMapFilters },
    }) {
      const courseData = getCourseUserMapFromCache({
        id: parentId,
        filters: courseUserMapFilters,
      });

      return {
        getCourseUserMap,
        students: getStudents({
          data: _.get(courseData, "userMap.edges", []),
        }),
        isData: !_.isEmpty(courseData) && isData,
        isLoading:
          getCourseUserMap["networkStatus"] == 1 ||
          getCourseUserMap["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getAssignedStudentsQuery, {
    name: "getAssignedStudents",
    skip: ({ mode, assignmentId }) =>
      _.isEmpty(assignmentId) || mode === "create",
    options: ({ assignmentId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: assignmentId,
      },
    }),
    props({
      getAssignedStudents,
      ownProps: {
        isData,
        isLoading,
        assignmentId,
        students: allStudents,
        searchText,
      },
    }) {
      const students = getAssignedStudentsFromCache({
        id: assignmentId,
      });

      const assignedStudents = _.get(students, "assignedStudents", []);

      const assignmentStudents = _.map(
        assignedStudents,
        ({ student }) => student
      );
      const studentsUnion = _.unionBy(assignmentStudents, allStudents, "id");

      const studentsToDisplay = _.filter(studentsUnion, student => {
        const { firstName, lastName } = student;

        const fullName = `${firstName} ${lastName}`;

        const index = localSearch({ text: _.lowerCase(fullName), searchText });
        return index > -1 || _.isEmpty(searchText);
      });

      return {
        getAssignedStudents,
        students: studentsToDisplay,
        isData: !_.isEmpty(assignedStudents) && isData,
        isLoading:
          getAssignedStudents["networkStatus"] == 1 ||
          getAssignedStudents["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(StudentSelection);
