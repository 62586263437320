// libraries
import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withRouter } from "react-router";

// css
import classes from "./MaxScoreTool.scss";

// queries
import { getOrganizationPointSetQuery } from "ScoreCategories/modules/ScoreCategoriesQueries";

// helpers
import { getOrganizationPointSetFromCache } from "ScoreCategories/modules/ScoreCategoriesGraphqlHelpers";

// ui components
import { UIBaseComponent, withLoader, I18nHOC } from "UIComponents";

// ds
import {
  Checkbox,
  Dropdown,
  ButtonDisclosure,
  DropdownMenu,
  TextInput,
  Tooltip,
  SelectDropdown,
} from "@toddle-design/web";
import { colors } from "Constants";

// utils
import {
  getCourseIdsMemoize,
  getDropdownItemsMemoize,
  getUnitPlanRelatedDataMemoize,
  getSelectedSubjectIdMemoize,
  getCourseDropdownItemsMemoize,
} from "./Utils";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { InformationOutlined } from "@toddle-design/web-icons";
import {
  checkIsScoreEnabledFromAdmin,
  checkCategorySetApplicableTo,
} from "ScoreCategories/modules/ScoreCategoriesModule";
import { getCourseDetailsQuery } from "ProgressSummary/modules/ProgressSummaryQuery";
import { getCourseDetailsFromCache } from "ProgressSummary/modules/ProgressSummaryGraphqlHelpers";
import { setMaxScoreValue } from "Teacher/modules/TeacherModule";

// inline styles
const styles = {
  inputStyle: {
    backgroundColor: colors.white,
    borderColor: colors.borderDefault,
    borderRadius: "6px",
    height: "40px",
    padding: "8px 16px",
    maxWidth: "172px",
  },
  buttonDisclosureStyle: {
    width: "100%",
    justifyContent: "space-between",
  },
  checkBoxStyle: {
    alignItems: "center",
  },
};

class MaxScoreTool extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      error: false,
    };
    this.dropdownRef = React.createRef();
    this.courseDropdownRef = React.createRef();
  }

  componentDidMount = () => {
    const {
      unitPlanSubjects,
      updateInputField,
      dependentFieldValue,
      setMaxScoreValue,
    } = this.props;

    // subject specific case
    if (_.size(unitPlanSubjects) === 1 && _.isNull(dependentFieldValue)) {
      updateInputField({
        subjects: [_.get(unitPlanSubjects, [0, "id"], null)],
      });
    }
    const maxScore = _.get(this.props, "value.maxScore", null);
    setMaxScoreValue(maxScore);
  };

  componentDidUpdate = prevProps => {
    const { selectedSubjectId, value: oldValue } = this.props;

    // IF, new subject is selected
    if (prevProps.selectedSubjectId !== selectedSubjectId) {
      // THEN, reset categoryId
      const value = {
        ...(oldValue || {}),
        categoryId: null,
        courseId: null,
      };
      this.updateValue(value);
    }
  };

  getExtraParams = categoryId => {
    const { value: oldValue, organizationPointSet } = this.props;

    const id = categoryId ? categoryId : _.get(oldValue, "categoryId");

    if (!id) {
      return null;
    }

    const categoryData = this.getCategoryData({ id });
    const node = _.get(organizationPointSet, ["edges", 0, "node"], {});

    return {
      id: _.get(categoryData, "id"),
      colorCode: _.get(categoryData, "colorCode"),
      label: _.get(categoryData, "label"),
      set: {
        id: _.get(node, "id"),
        associatedEntities: [..._.get(node, "associatedEntities", [])],
        __typename: "PointAssessmentSet",
      },
      __typename: "PointAssessmentSetCategory",
    };
  };

  getCategoryData = ({ id }) => {
    const { categories } = this.props;
    return (
      _.find(categories, {
        id,
      }) || {}
    );
  };

  getCourseData = ({ id }) => {
    const { paramsUnitId, selectedSubjectId } = this.props;
    const courseDropdownOptions = getCourseDropdownItemsMemoize({
      unitId: paramsUnitId,
      selectedSubjectId,
    });

    return (
      _.find(courseDropdownOptions, {
        key: id,
      }) || {}
    );
  };

  getCategoryTooltipText = () => {
    const {
      selectedSubjectId,
      categoryDropdownItems,
      courseId,
      t,
      unitPlanSubjects,
      isAdminPage,
      isCourseApplicableToCategorySet,
    } = this.props;

    if (!selectedSubjectId) {
      return t("classRoom:categories_for_subject_specific_only");
    }

    if (isCourseApplicableToCategorySet && selectedSubjectId && !courseId) {
      return t("toastMsgs:select_course");
    }

    if (_.isEmpty(categoryDropdownItems)) {
      const selectedSubject = _.find(unitPlanSubjects, {
        id: selectedSubjectId,
      });

      const text = isAdminPage
        ? "classRoom:category_not_defined_for_this_class_with_label"
        : "classRoom:category_not_defined_with_label";

      return t(text, {
        label: _.get(selectedSubject, "name"),
      });
    }

    return "common:disabled";
  };

  resetCategoryId = () => {
    const { value: oldValue } = this.props;

    const value = {
      ...(oldValue || {}),
      categoryId: null,
      courseId: null,
    };

    this.updateValue(value);
  };

  updateMaxScore = e => {
    const maxScore = _.get(e, "target.value");
    const { value: oldValue } = this.props;
    const maxScoreInt = parseInt(maxScore);
    // allow only digits
    const reg = /^\d+$/;

    if ((reg.test(maxScore) && maxScoreInt <= 1000) || !maxScore) {
      const value = {
        ...(oldValue || {}),
        maxScore: maxScore ? maxScoreInt.toString() : maxScore,
      };
      const extraParams = this.getExtraParams();
      this.updateValue(value, extraParams);
    }
  };

  updateCheckBoxValue = e => {
    const { value: oldValue } = this.props;
    const isUsingScore = _.get(e, "target.checked", false);

    const extraParams = null;
    const value = {
      ...(oldValue || {}),
      isUsingScore,
      categoryId: null,
      maxScore: null,
    };
    this.updateValue(value, extraParams);
  };

  updateCategory = e => {
    const { value: oldValue } = this.props;

    // none case
    if (e?.key === "-1") {
      const value = {
        ...(oldValue || {}),
        categoryId: null,
      };
      this.updateValue(value);
      return;
    }

    const categoryData = this.getCategoryData({ id: e.key });
    const categoryId = _.get(categoryData, "id");

    const value = {
      ...(oldValue || {}),
      categoryId: categoryId,
    };
    const extraParams = this.getExtraParams(categoryId);
    this.updateValue(value, extraParams);
  };

  updateCourse = e => {
    const { value: oldValue, setStateCourseId } = this.props;

    let courseId;
    if (e?.key === "-1") {
      // none case
      courseId = null;
    } else {
      courseId = _.get(e, "key");
    }

    const value = {
      ...(oldValue || {}),
      courseId,
    };

    this.updateValue(value);

    // fire query
    setStateCourseId(courseId);
  };

  renderLabel = () => {
    return null;
  };

  onBlur = e => {
    const value = _.get(e, "target.value", "");
    this.setState({ error: !value });
  };

  renderEdit = () => {
    const {
      value,
      categoryDropdownItems = [],
      isCategoryDropdownEnabled,
      t,
      isAdminPage,
      paramsUnitId,
      selectedSubjectId,
      categories,
      organizationId,
      isCourseApplicableToCategorySet,
      curriculumType,
      isLoading,
    } = this.props;

    const { isUsingScore = false, maxScore, categoryId, courseId } =
      value || {};

    const categoryData = categoryId
      ? this.getCategoryData({ id: categoryId })
      : {};

    // Formative assessment created from admin end and used in a class which is having different category set
    if (!isLoading && categoryId && _.isEmpty(categoryData)) {
      this.resetCategoryId();
    }

    const courseData = courseId ? this.getCourseData({ id: courseId }) : {};

    const isDisabledDropdown =
      !isCategoryDropdownEnabled ||
      !isUsingScore ||
      _.isEmpty(categoryDropdownItems);
    const isDisabledCoursesDropdown = !isUsingScore || !selectedSubjectId;

    const courseDropdownOptions = isAdminPage
      ? getCourseDropdownItemsMemoize({
          unitId: paramsUnitId,
          selectedSubjectId,
        })
      : null;

    const categoryTooltipText = this.getCategoryTooltipText();

    const areCategoriesAvailable = _.size(categories) > 0;

    const isCategoryDropdownVisible =
      checkIsScoreEnabledFromAdmin({ organizationId, curriculumType }) &&
      areCategoriesAvailable;

    const categoryLabel = _.get(
      categoryData,
      "label",
      t("common:select_category")
    );

    const getPopupContainer = () => {
      return this.courseDropdownRef.current;
    };

    return (
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <Checkbox
            isChecked={isUsingScore}
            onChange={this.updateCheckBoxValue}
            style={styles.checkBoxStyle}
          >
            <div className={classes.textContainer}>
              <div className={classes.scoringText}>
                {t("common:point_based_assessment")}
              </div>
              <div className={"text-body-3"}>
                {t("common:teacher_evaluation_only")}
              </div>
            </div>
          </Checkbox>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.maxScoreInputContainer}>
            <TextInput
              name={"maxScore"}
              placeholder={t("common:max_score_with_ex")}
              size={"medium"}
              onChange={this.updateMaxScore}
              value={maxScore ? maxScore : ""}
              disabled={!isUsingScore}
              error={this.state.error}
              onBlur={this.onBlur}
            />
          </div>
          {isAdminPage && isCourseApplicableToCategorySet && (
            <div className={classes.courseContainer}>
              <div className={classes.dropdown} ref={this.courseDropdownRef}>
                <Dropdown
                  getPopupContainer={() => {
                    return this.courseDropdownRef.current;
                  }}
                  overlay={
                    <DropdownMenu
                      options={[
                        ...courseDropdownOptions,
                        {
                          label: t("common:none"),
                          key: -1,
                        },
                      ]}
                      onClick={this.updateCourse}
                    />
                  }
                  disabled={isDisabledCoursesDropdown}
                >
                  <ButtonDisclosure
                    variant="outlined-subtle"
                    size={"medium"}
                    style={styles.buttonDisclosureStyle}
                  >
                    {_.get(courseData, "label", t("classRoom:select_a_class"))}
                  </ButtonDisclosure>
                </Dropdown>
              </div>
            </div>
          )}
          {isCategoryDropdownVisible ? (
            <div className={classes.categoryContainer}>
              <div className={classes.dropdown} ref={this.dropdownRef}>
                <ConditionalWrapper
                  condition={
                    isUsingScore &&
                    (_.isEmpty(categoryDropdownItems) ||
                      !isCategoryDropdownEnabled)
                  }
                  wrapper={
                    <Tooltip
                      getTooltipContainer={() => {
                        return this.dropdownRef.current;
                      }}
                      placement={"bottom"}
                      tooltip={categoryTooltipText}
                    />
                  }
                  type="wrapperAsElement"
                >
                  {curriculumType != "IB_DP" ? (
                    <Dropdown
                      getPopupContainer={getPopupContainer()}
                      overlay={
                        <DropdownMenu
                          options={categoryDropdownItems}
                          onClick={this.updateCategory}
                        />
                      }
                      disabled={isDisabledDropdown}
                    >
                      <ButtonDisclosure
                        variant="outlined-subtle"
                        size={"medium"}
                        style={styles.buttonDisclosureStyle}
                      >
                        {categoryLabel}
                      </ButtonDisclosure>
                    </Dropdown>
                  ) : (
                    <SelectDropdown
                      value={categoryLabel}
                      placeholder={categoryLabel}
                      isClearable={false}
                      onChange={this.updateCategory}
                      isDisabled={isDisabledDropdown}
                      options={categoryDropdownItems}
                    />
                  )}
                </ConditionalWrapper>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
}

const getCurriculumProgramIdFromType = ({
  orgCurriculumPrograms,
  curriculumProgramType,
}) => {
  return _.get(
    _.find(orgCurriculumPrograms, { type: curriculumProgramType }),
    "id",
    null
  );
};

const mapStateToProps = (state, ownProps) => {
  const { dependentFieldValue, stateCourseId } = ownProps;

  // --- ownProps params ---
  const paramsCourseId = _.get(ownProps, "params.course_id", null);
  const paramsUnitId = _.get(ownProps, "params.unit_id", null);

  // --- state ---
  const activePage = _.get(state, "login.activeTab", "");
  const orgCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms"
  );
  const currentCurriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType"
  );
  const adminUnitPlanCurriculumType = _.get(
    state,
    "adminUnitPlans.filters.curriculumType",
    ""
  );
  const currentCurriculumProgramId = getCurriculumProgramIdFromType({
    orgCurriculumPrograms,
    curriculumProgramType: currentCurriculumProgramType,
  });
  const adminUnitPlanCurriculumProgramId = getCurriculumProgramIdFromType({
    orgCurriculumPrograms,
    curriculumProgramType: adminUnitPlanCurriculumType,
  });

  const organizationId = _.get(state, "login.userInfo.org_id");
  const adminUnitPlanGradeId = _.get(
    state,
    "adminUnitPlans.filters.grades",
    ""
  );

  // --- CALCULATION: get selectedSubjectId  ---
  const assessmentId = _.get(ownProps, "parentId")
    ? _.get(ownProps, "parentId")
    : _.get(ownProps, "params.assessmentId");
  const { unitPlanSubjects } = getUnitPlanRelatedDataMemoize(assessmentId);
  const { selectedSubjectId } = getSelectedSubjectIdMemoize({
    dependentFieldValue,
  });

  // --- variables ---

  const curriculumProgramId = currentCurriculumProgramId
    ? currentCurriculumProgramId
    : adminUnitPlanCurriculumProgramId;

  const courseId = paramsCourseId ? paramsCourseId : stateCourseId;

  // --- conditionals ---
  const isAdminPage = activePage === "admin";

  const isCourseApplicableToCategorySet =
    checkCategorySetApplicableTo({
      organizationId,
    }) === "COURSE";

  const isCategoryDropdownEnabled =
    selectedSubjectId && (isCourseApplicableToCategorySet ? courseId : true);

  return {
    isData: true,
    isLoading: false,
    isAdminPage,
    isCategoryDropdownEnabled,
    selectedSubjectId,
    assessmentId,
    paramsUnitId,
    unitPlanSubjects,
    courseId,
    dependentFieldValue,
    variables: {
      id: _.get(state, "login.userInfo.org_id"),
      filters: {
        types: "SCORE",
        curriculumProgramIds: curriculumProgramId,
        associatedEntites: [
          {
            type: "SUBJECT",
            ids: selectedSubjectId,
          },
        ],
      },
    },
    curriculumType: currentCurriculumProgramType
      ? currentCurriculumProgramType
      : adminUnitPlanCurriculumType,
    organizationId,
    adminUnitPlanGradeId,
    isCourseApplicableToCategorySet,
    maxScoreLocal: _.get(state, "teacher.maxScore", null),
  };
};

const mapActionCreators = {
  setMaxScoreValue,
};

export const getAssociatedEntitiesVariablesMemoize = _.memoize(
  params => getAssociatedEntitiesVariables(params),
  params => JSON.stringify(params)
);

const getAssociatedEntitiesVariables = ({
  isCourseApplicableToCategorySet,
  courseId,
  isAdminPage,
  adminUnitPlanGradeId,
  gradeId,
}) => {
  if (isCourseApplicableToCategorySet) {
    return {
      type: "COURSE",
      ids: courseId,
    };
  }
  return {
    type: "GRADE",
    ids: isAdminPage ? adminUnitPlanGradeId : gradeId,
  };
};

export default compose(
  withRouter,
  I18nHOC({ resource: ["common", "classRoom", "toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseDetailsQuery, {
    name: "getCourseDetails",
    skip: ({ isAdminPage, isCourseApplicableToCategorySet }) =>
      isAdminPage || isCourseApplicableToCategorySet,
    options: ({ courseId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
      },
    }),
    props: ({ getCourseDetails, ownProps: { courseId, isLoading } }) => {
      const queryData = getCourseDetailsFromCache({ id: courseId });
      const grades = _.get(queryData, "grades", []);
      return {
        gradeId: _.get(_.head(grades), "id", ""),
        isData: true,
        isLoading:
          _.includes([1, 2], getCourseDetails.networkStatus) || isLoading,
      };
    },
  }),
  graphql(getOrganizationPointSetQuery, {
    name: "getOrganizationPointSet",
    skip: ({ isCategoryDropdownEnabled }) => !isCategoryDropdownEnabled,
    options: ({
      variables,
      gradeId,
      adminUnitPlanGradeId,
      isAdminPage,
      isCourseApplicableToCategorySet,
      courseId,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ...variables,
        filters: {
          ...variables.filters,
          associatedEntites: [
            ...variables.filters.associatedEntites,
            getAssociatedEntitiesVariablesMemoize({
              gradeId,
              adminUnitPlanGradeId,
              isAdminPage,
              isCourseApplicableToCategorySet,
              courseId,
            }),
          ],
        },
      },
    }),
    props({
      getOrganizationPointSet,
      ownProps: {
        variables,
        gradeId,
        isAdminPage,
        adminUnitPlanGradeId,
        isCourseApplicableToCategorySet,
        courseId,
        curriculumType,
        isLoading,
        t,
      },
    }) {
      const organizationPointSetData = getOrganizationPointSetFromCache({
        variables: {
          ...variables,
          filters: {
            ...variables.filters,
            associatedEntites: [
              ...variables.filters.associatedEntites,
              getAssociatedEntitiesVariablesMemoize({
                gradeId,
                adminUnitPlanGradeId,
                isAdminPage,
                isCourseApplicableToCategorySet,
                courseId,
              }),
            ],
          },
        },
      });

      const organizationPointSet = _.get(
        _.first(
          _.get(organizationPointSetData, "pointAssessmentSets.edges", [])
        ),
        "node",
        {}
      );

      const associatedEntities = _.get(
        organizationPointSet,
        "associatedEntities",
        []
      );
      const coursesIdsArr = getCourseIdsMemoize({
        associatedEntities,
        entityType: isCourseApplicableToCategorySet ? "Course" : "Grade",
      });

      const categories = _.get(organizationPointSet, "categories", []);
      const categoryDropdownItems = getDropdownItemsMemoize({
        categories,
        curriculumType,
        t,
      });

      return {
        organizationPointSet: _.get(
          organizationPointSetData,
          "pointAssessmentSets",
          []
        ),
        coursesIdsArr,
        categoryDropdownItems,
        categories,
        isData: true,
        isLoading:
          _.includes([1, 2], getOrganizationPointSet.networkStatus) ||
          isLoading,
      };
    },
  }),
  withLoader
)(MaxScoreTool);
