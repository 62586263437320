import React from "react";
import classes from "./AddSchool.scss";
import {
  InputTextField,
  UIButton,
  SelectDropdown,
  RadioButtonList,
} from "UIComponents";
import { ROLES } from "Constants";
import { isURL } from "Utils";
import DocumentSelector from "../components/DocumentSelector";

const DOCUMENT_INSTRUCTION = {
  candidate: {
    headerText:
      "To verify that you are an IB PYP candidate school, please provide any one of the following documents:",
    texts: [
      "A scanned copy of the email / letter from the IB confirming your school’s candidacy, or",
      "A screenshot from your school's website if your candidacy is declared on it",
    ],
  },
  authorised: {
    headerText:
      "To verify that you are an IB PYP authorised school, please provide any one of the following documents:",
    texts: [
      "A scanned copy of the email / letter from the IB confirming your school’s authorisation, or",
      "A screenshot from your school's website if your authorisation is declared on it",
    ],
  },
};

const SCHOOL_TYPES = [
  { label: "Candidate School", value: "candidate" },
  { label: "Authorised School ", value: "authorised" },
];
class AddSchool extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isUploading: false };
    this.inputRefs = {};
  }
  UNSAFE_componentWillMount = () => {
    const { resetScreen, updateInputField, getDesignationList } = this.props;
    resetScreen("addSchool");
    updateInputField({
      type: _.get(SCHOOL_TYPES[1], "value", ""),
    });
    getDesignationList();
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "organizationSelection" });
  };

  onRequestSchoolAccess = () => {
    const { createSchoolRequest, onNextClick, showErrorToast } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    onNextClick();
    createSchoolRequest();
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  urlValidation = value => {
    return !isURL(value) ? "Enter valid Website" : "";
  };

  updateInputDesignationField = params => {
    const { updateInputField, designationList } = this.props;
    const role = _.get(
      _.find(designationList, { id: params.designation }),
      "signup_role_level"
    );
    // console.log(params, role, designationList);
    updateInputField({ ...params, role });
  };

  updateLoadingState = state => {
    this.setState({ isUploading: state });
  };

  onEnterPress = ({ next }) => {
    if (this.inputRefs[next] && this.inputRefs[next].focus) {
      this.inputRefs[next].focus();
    }
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      checkEmailVerification,
      data: {
        name,
        type,
        ibCode,
        weburl,
        email,
        role,
        designation,
        firstName,
        lastName,
        attachment = {},
      } = {},
      renderErrorComponent,
      renderBackButtonComponent,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
      renderContactUsButton,
      progressOfUploads,
      designationList,
    } = this.props;
    const { isUploading } = this.state;
    const progress = _.get(progressOfUploads[attachment.id], "progress", 100);
    return (
      <div
        className={classes.container}
        style={{ ...containerStyle, paddingTop: 64 }}
      >
        {renderBackButtonComponent({ onClick: this.onBackClick })}
        <div style={headerStyle}> {`Add Your School`} </div>
        <div style={subTextStyle}>
          {`As of now, Toddle is available only for IB PYP authorised or candiate schools.`}{" "}
          {renderContactUsButton()}{" "}
          {`in case your school doesn’t follow the PYP and you want access to Toddle.`}
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.groupsContainer}>
            <div className={classes.groupContainer}>
              <div className={classes.groupHeaderContainer}>
                {`School Details`}
              </div>
              <div className={classes.inputsContainer}>
                <div className={classes.inputRadioList}>
                  <RadioButtonList
                    options={SCHOOL_TYPES}
                    label={"At what stage is your school in its PYP journey?"}
                    value={type}
                    name={"type"}
                    updateInputField={updateInputField}
                    showErrorText={true}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <InputTextField
                    label={"Your school's IB School Code / Candidacy Code"}
                    value={ibCode}
                    placeholder={`e.g. “001740”`}
                    name={"ibCode"}
                    updateInputField={updateInputField}
                    {...inputStyle}
                    ref={ref => (this.inputRefs["ibCode"] = ref)}
                    showErrorText={true}
                    renderErrorComponent={renderErrorComponent}
                    error={"Enter School Code"}
                    onEnter={() => this.onEnterPress({ next: "name" })}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <InputTextField
                    label={"Your school's name"}
                    value={name}
                    placeholder={`e.g. Toddle International School”`}
                    name={"name"}
                    updateInputField={updateInputField}
                    {...inputStyle}
                    ref={ref => (this.inputRefs["name"] = ref)}
                    showErrorText={true}
                    renderErrorComponent={renderErrorComponent}
                    error={"Enter School Name"}
                    onEnter={() => this.onEnterPress({ next: "weburl" })}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <InputTextField
                    label={"Your school's website"}
                    value={weburl}
                    placeholder={"e.g. “www.toddle.edu.in”"}
                    name={"weburl"}
                    updateInputField={updateInputField}
                    {...inputStyle}
                    ref={ref => (this.inputRefs["weburl"] = ref)}
                    showErrorText={true}
                    renderErrorComponent={renderErrorComponent}
                    error={"Enter your school website"}
                    customValidation={this.urlValidation}
                    onEnter={() => this.onEnterPress({ next: "firstName" })}
                  />
                </div>
                <DocumentSelector
                  progressOfUploads={progressOfUploads}
                  progress={progress}
                  instruction={type ? DOCUMENT_INSTRUCTION[type] : {}}
                  attachment={attachment}
                  updateInputField={updateInputField}
                  ref={ref => (this.inputRefs["attachment"] = ref)}
                  renderErrorComponent={renderErrorComponent}
                  renderContactUsButton={renderContactUsButton}
                  updateLoadingState={this.updateLoadingState}
                />
              </div>
            </div>
            <div className={classes.groupContainer}>
              <div
                className={classes.groupHeaderContainer}
              >{`Your Details`}</div>
              <div className={classes.inputsContainer}>
                <div
                  className={classes.inputContainer}
                  style={{ justifyContent: "space-between" }}
                >
                  <div className={classes.nameContainer}>
                    <InputTextField
                      label={"Your first name"}
                      value={firstName}
                      placeholder={"e.g. “Oliver”"}
                      name={"firstName"}
                      updateInputField={updateInputField}
                      {...inputStyle}
                      ref={ref => (this.inputRefs["firstName"] = ref)}
                      showErrorText={true}
                      renderErrorComponent={renderErrorComponent}
                      error={"Enter your first name"}
                      onEnter={() => this.onEnterPress({ next: "lastName" })}
                    />
                  </div>
                  <div className={classes.nameContainer}>
                    <InputTextField
                      label={"Your last name"}
                      value={lastName}
                      placeholder={"e.g. “Miles”"}
                      name={"lastName"}
                      updateInputField={updateInputField}
                      {...inputStyle}
                      ref={ref => (this.inputRefs["lastName"] = ref)}
                      showErrorText={true}
                      renderErrorComponent={renderErrorComponent}
                      error={"Enter your last name"}
                      onEnter={() => this.onEnterPress({ next: "email" })}
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <InputTextField
                    label={"Your work email address"}
                    value={email}
                    placeholder={"Enter your work email address"}
                    name={"email"}
                    updateInputField={updateInputField}
                    {...inputStyle}
                    ref={ref => (this.inputRefs["email"] = ref)}
                    customValidation={checkEmailVerification}
                    showErrorText={true}
                    renderErrorComponent={renderErrorComponent}
                    error={"Enter your work email address"}
                    onEnter={() => this.onEnterPress({ next: "designation" })}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <SelectDropdown
                    options={designationList}
                    label={"Your role at school"}
                    value={designation}
                    placeholder={"Select your role"}
                    name={"designation"}
                    updateInputField={this.updateInputDesignationField}
                    {...inputStyle}
                    ref={ref => (this.inputRefs["designation"] = ref)}
                    showErrorText={true}
                    renderErrorComponent={renderErrorComponent}
                    error={"Select your role"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <UIButton
              size="lg"
              type="filled"
              color="pink"
              onClick={this.onRequestSchoolAccess}
              isDisabled={isUploading}
            >
              {"Request Toddle Access"}
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSchool;
