import React, { PureComponent } from "react";
import classes from "./RelatedConceptViewList.scss";
import { getPypElementLabel } from "modules/Services";
import { EmptyField, LockOverlay, UIButton, I18nHOC } from "UIComponents";
import { AlertIcon } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";
const emptyFieldStyle = {
  containerStyle: {
    border: 0,
  },
};

class RelatedConceptViewList extends PureComponent {
  getGroupedRelatedConceptList = relatedConceptSets => {
    const {
      listStyle,
      groupedValue,
      listItemsStyle,
      listHeaderStyle,
    } = this.props;

    return _.map(relatedConceptSets, set => {
      const { label, id } = set;
      const value = groupedValue[id];

      const relatedConcepts = _.join(
        getPypElementLabel({
          type: "RELATED_CONCEPT",
          value,
        }),
        ", "
      );

      return (
        _.get(relatedConcepts, "length", 0) > 0 && (
          <div className={classes.list} key={id} style={listStyle}>
            <div className={classes.listHeader} style={listHeaderStyle}>
              {label}
            </div>
            <div className={classes.listItems} style={listItemsStyle}>
              {relatedConcepts}
            </div>
          </div>
        )
      );
    });
  };

  checkGroupRelatedConcept = relatedConceptSets => {
    const { groupedValue } = this.props;

    return (
      _.findIndex(
        relatedConceptSets,
        set => _.size(groupedValue[set.id]) > 0
      ) >= 0
    );
  };

  getEmptyComponent = ({ label, id, hasData }) => {
    const { onEditClick, t } = this.props;
    return (
      <EmptyField
        title={
          !!hasData
            ? `${t("addSubjects_label", { label: t(`common:${id}`) })}`
            : `${t("add_key_concepts_with_label", {
                label: t(`common:${id}`),
              })}`
        }
        showAdd={true}
        containerStyle={emptyFieldStyle.containerStyle}
        onAddNewClick={() => onEditClick({ actionId: id })}
        isDisabled={!hasData}
        renderIcon={!hasData && <AlertIcon fill={colors.gray72} />}
      />
    );
  };

  render() {
    const {
      t,
      mode,
      emptyText,
      readOnly,
      onEditClick,
      mainContainerStyle,
      headerContainerStyle,
      groupedRelatedConcepts,
      lockedDynamicFields,
    } = this.props;

    return _.map(groupedRelatedConcepts, group => {
      const { id, label, relatedConceptSets } = group;

      const showGroupRelatedConcept = this.checkGroupRelatedConcept(
        relatedConceptSets
      );

      if (!showGroupRelatedConcept && mode == "view") {
        return null;
      }
      const lockedField = _.find(lockedDynamicFields, { id });
      const mainContentContainerClasses = classNames(
        { [classes.mainContentContainer]: true },
        { [classes.mainContentContainerViewMode]: mode == "view" }
      );
      const innerContainerClasses = classNames(
        { [classes.innerContainer]: true },
        { [classes.innerContainerViewMode]: mode == "view" }
      );
      const headerLabelClasses = classNames(
        { [classes.headerLabel]: true },
        { "text-label-2": true },
        { [classes.headerLabelView]: mode == "view" }
      );
      const mainContainerClasses = classNames(
        { [classes.mainContainer]: true },
        { [classes.mainContainerView]: mode == "view" }
      );

      return (
        <div
          key={id}
          style={mainContainerStyle}
          className={mainContainerClasses}
        >
          <div className={classes.headerContainer} style={headerContainerStyle}>
            <div className={headerLabelClasses}>{t(`common:${id}`)}</div>
            {showGroupRelatedConcept && mode == "edit" && (
              <div
                onClick={() => onEditClick({ actionId: id })}
                className={classes.addButton}
              >
                <UIButton
                  size="sm"
                  type="hollow"
                  color="tertiary"
                  isDisabled={!!lockedField}
                >
                  {t("common:edit")}
                </UIButton>
              </div>
            )}
          </div>
          <div className={mainContentContainerClasses}>
            {showGroupRelatedConcept ? (
              <div className={innerContainerClasses}>
                {this.getGroupedRelatedConceptList(relatedConceptSets)}
              </div>
            ) : mode == "edit" ? (
              this.getEmptyComponent({
                label,
                id,
                hasData: _.get(relatedConceptSets, "length", 0) > 0,
              })
            ) : (
              <div className={classes.emptyText}>
                {t(`common:${emptyText}`)}
              </div>
            )}
            {(lockedField || readOnly) && (
              <LockOverlay userInfo={_.get(lockedField, "userInfo", {})} />
            )}
          </div>
        </div>
      );
    });
  }
}

RelatedConceptViewList.defaultProps = {
  emptyText: "",
  groupedValue: {},
  listItemsStyle: {},
  listHeaderStyle: {},
  mainContainerStyle: {},
  headerContainerStyle: {},
  groupedRelatedConcepts: [],
};

export default I18nHOC({ resource: ["unitPlan", "common"] })(
  RelatedConceptViewList
);
