import React from "react";
import classes from "./Details.scss";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { PYPElements, I18nHOC } from "UIComponents";

import BasicDetails from "../BasicDetails";
import ACLStore from "lib/aclStore";
import {
  TaggedStudentDetails,
  UnitDetails,
  TaggedFolders,
  TaggedPractices,
  TaggedPracticesV2,
} from "Post/components";
import { getTaggableElements, getFieldList } from "Post/modules/PostModule";
import { getSettingValue } from "modules/PermissionModule";

import { getElementData } from "modules/Services";
import {
  SNPv2_TEACHER_MODULE_PERM,
  SNPv2_PERM,
} from "Constants/permissionConstants";
import {
  GoalsOutlined,
  FolderOutlined,
  ScopeAndSequenceOutlined,
  StudentAddOutlined,
} from "@toddle-design/web-icons";
import { isJournalCardRevamp } from "Post/utils";
import { PostCard } from "Post";
import { colors } from "@toddle-design/theme";

const styles = {
  subDetailContainerStyle: {
    paddingLeft: "36px",
  },
};

const TagStudentDetails = ({ taggedStudents, t }) => {
  const isTagged = _.get(taggedStudents, "length", 0) > 0;
  return (
    <div className={classes.detailContainer}>
      <div className={classes.detailInnerContainer}>
        <div className={classes.iconContainer}>
          <StudentAddOutlined size={"xx-small"} />
        </div>
        <div className={classes.detailsHeaderText}>
          {t("journal:tagged_students")}
        </div>
      </div>
      <div className={classes.subDetailContainer}>
        {isTagged ? (
          <TaggedStudentDetails taggedStudents={taggedStudents} />
        ) : (
          <div className={classes.detailText}>
            {t("journal:no_students_tagged")}
          </div>
        )}
      </div>
    </div>
  );
};

class Details extends React.PureComponent {
  render() {
    const {
      detail,
      elementData,
      toggleMediaModal,
      onCloseClick,
      fullMedia,
      taggedStudents,
      t,
      onPostActionClick,
      userId,
      postId,
      showTagSnP,
      showTagSnpV2,
      userType,
      showCloseBtn,
      toggleCommentBox,
      toggleLEPreview,
      detailsContainerStyle,
      mediaMaxWidth,
      mediaMaxHeight,
      attachmentContainerStyle,
      hideLike,
      hideComments,
      hideLearninGoals,
      hideUnitDetails,
      hideTaggedStudents,
      showTaggedFolders,
      taggableElements,
      courseIdFromQuery,
      portfolioStudentId,
      selectFromAllPYP,
      showPracticeStatus,
      showPypElements,
      onJournalPostActionClick,
      showDownloadBeginOverlay,
      isSidePaneContent = true,
      isViewFromPostPage = false,
    } = this.props;

    const {
      unitPlan,
      resourceItem,
      resourceType,
      folders,
      practices,
      title: postTitle,
      taggedPractices: taggedPractices,
    } = detail;

    const hasElementData = _.some(elementData, element => {
      return !_.isEmpty(element);
    });

    const showLearningGoals = hasElementData && !_.isEmpty(elementData);

    const iconContainerStyle = {
      width: "32px",
      height: "32px",
      justifyContent: "center",
      borderRadius: "4px",
    };

    const dynamicLevelContainerStyle = {
      0: {
        color: colors.textDefault,
        fontWeight: 600,
      },
      1: {
        color: colors.textDefault,
      },
      3: {
        color: colors.textSubtle,
      },
    };

    const childrenContainerStyle = [
      { style: { gridRowGap: "16px" }, depth: 0 },
    ];

    return (
      <div className={classes.container} style={detailsContainerStyle}>
        {isJournalCardRevamp() ? (
          <PostCard
            item={detail}
            onPostActionClick={onPostActionClick}
            onCloseClick={onCloseClick}
            userId={userId}
            canEditOrDelete={false}
            isSidePaneContent={isSidePaneContent}
            containerStyle={{ width: "100%" }}
            portfolioStudentId={portfolioStudentId}
            showCloseBtn={showCloseBtn}
            toggleCommentBox={toggleCommentBox}
            isViewFromPostPage={isViewFromPostPage}
          />
        ) : (
          <BasicDetails
            detail={detail}
            toggleMediaModal={toggleMediaModal}
            onCloseClick={onCloseClick}
            fullMedia={fullMedia}
            onPostActionClick={onPostActionClick}
            userId={userId}
            postId={postId}
            toggleCommentBox={toggleCommentBox}
            toggleLEPreview={toggleLEPreview}
            showCloseBtn={showCloseBtn}
            mediaMaxWidth={mediaMaxWidth}
            mediaMaxHeight={mediaMaxHeight}
            attachmentContainerStyle={attachmentContainerStyle}
            hideLike={hideLike}
            hideComments={hideComments}
            courseIdFromQuery={courseIdFromQuery}
            portfolioStudentId={portfolioStudentId}
            showDownloadBeginOverlay={showDownloadBeginOverlay}
          />
        )}
        {hideTaggedStudents || userType === "parent"
          ? null
          : !_.isEmpty(taggedStudents) && (
              <TagStudentDetails t={t} taggedStudents={taggedStudents} />
            )}
        {hideUnitDetails || selectFromAllPYP || !showPypElements
          ? null
          : !_.isEmpty(unitPlan) &&
            !isJournalCardRevamp() && (
              <div className={classes.detailContainer}>
                <UnitDetails
                  unitPlan={unitPlan}
                  resourceItem={resourceItem}
                  resourceType={resourceType}
                  showIcon={true}
                  subDetailContainerStyle={styles.subDetailContainerStyle}
                  iconContainerStyle={{
                    ...iconContainerStyle,
                    backgroundColor: colors.blue96,
                  }}
                  iconVariant={"link"}
                  showResourceItem={false}
                />
              </div>
            )}
        {hideLearninGoals || !showPypElements
          ? null
          : showLearningGoals && (
              <div className={classes.detailContainer}>
                <div className={classes.detailInnerContainer}>
                  <div
                    className={classes.iconContainer}
                    style={{ backgroundColor: colors.teal96 }}
                  >
                    <GoalsOutlined size={"xx-small"} variant="success" />
                  </div>
                  <div className={classes.detailsHeaderText}>
                    {t("journal:tagged_pyp_elements")}
                  </div>
                </div>
                <div className={classes.pypElementsContainer}>
                  <PYPElements
                    elementData={elementData}
                    pypTemplateElements={taggableElements}
                    dynamicLevelContainerStyle={dynamicLevelContainerStyle}
                    childrenContainerStyle={childrenContainerStyle}
                  />
                </div>
              </div>
            )}
        {showTaggedFolders && showPypElements && !_.isEmpty(folders) && (
          <div className={classes.detailContainer}>
            <div className={classes.detailInnerContainer}>
              <div
                className={classes.iconContainer}
                style={{ backgroundColor: colors.pink98 }}
              >
                <FolderOutlined size={"xx-small"} variant="critical" />
              </div>
              <div className={classes.detailsHeaderText}>
                {t("journal:tagged_folders")}
              </div>
            </div>
            <div className={classes.subDetailContainer}>
              <TaggedFolders folders={folders}></TaggedFolders>
            </div>
          </div>
        )}
        {showTagSnP && !_.isEmpty(practices) && (
          <div className={classes.detailContainer}>
            <div className={classes.detailInnerContainer}>
              <div
                className={classes.iconContainer}
                style={{ backgroundColor: colors.surfaceSubtle }}
              >
                <ScopeAndSequenceOutlined size={"xx-small"} />
              </div>
              <div className={classes.detailsHeaderText}>
                {t("journal:tagged_practices")}
              </div>
            </div>
            <div className={classes.subDetailContainer}>
              <TaggedPractices practices={practices}></TaggedPractices>
            </div>
          </div>
        )}
        {showTagSnpV2 && !_.isEmpty(taggedPractices) && (
          <div className={classes.detailContainer}>
            <div className={classes.detailInnerContainer}>
              <div
                className={classes.iconContainer}
                style={{ backgroundColor: colors.surfaceSubtle }}
              >
                <ScopeAndSequenceOutlined size={"xx-small"} />
              </div>
              <div className={classes.detailsHeaderText}>
                {t("journal:tagged_practices")}
              </div>
            </div>
            <div className={classes.subDetailContainer}>
              <TaggedPracticesV2
                taggedPractices={taggedPractices}
                showPracticeStatus={showPracticeStatus}
                postTitle={postTitle}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const unitPlan = _.get(ownProps.detail, "unitPlan", {});
  const subjects = _.get(unitPlan, "subjects.value", []);
  let grades = _.get(unitPlan, "grades", []);
  const elementPYP = _.get(ownProps.detail, "elementPYP", []);

  const taggedBenchmarksValue = _.get(
    _.find(elementPYP, item => item.fieldUID == "benchmarks"),
    "value",
    []
  );
  const selectedGrades = state.teacher?.selected_class?.selected_grades;
  let selectFromAllPYP = false;
  if (!_.isEmpty(elementPYP) && _.isEmpty(unitPlan)) selectFromAllPYP = true;

  if (selectFromAllPYP) grades = selectedGrades;

  const userType = state.login.userInfo.user_type;
  const curriculumProgramType =
    state.platform.currentCurriculumProgramType ||
    _.get(ownProps, "detail.curriculumProgram.type");

  const isSnpV2Enabled =
    ACLStore.can(SNPv2_PERM) && ACLStore.can(SNPv2_TEACHER_MODULE_PERM);

  const isTaggingEnabled =
    getSettingValue({
      name: "EnableSnPPostTagging",
      courseId: state.teacher.selected_class.selected_course,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    }) && userType === "staff";

  const orgId = state.login.userInfo.org_id;
  // if showPypElements is false, then we will hide the Tagged Folders, Learning Goals, and Unit Details
  const showPypElements =
    userType !== "parent"
      ? true
      : getSettingValue({
          name: "ShowPypElementInFamilyApp",
          organizationId: orgId,
        });

  const showTagSnP = !isSnpV2Enabled && isTaggingEnabled;

  const showTagSnpV2 = isSnpV2Enabled;
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );
  return {
    isData: true,
    showLoaderBar: false,
    isLoading: false,
    userType: state.login.userInfo.user_type,
    organizationId: orgId,
    showPypElements,
    portfolioStudentId: ownProps?.portfolioStudentId,
    elementData: getElementData({
      isPost: true,
      elements: elementPYP,
      // unitPlanFields: unitPlanFields,
      field_list: getFieldList({ curriculumProgramType }),
      deriveSubjectIdsFromNodes: true,
    }),
    elementPYP: elementPYP,
    selectFromAllPYP,
    grades,
    subjects,
    taggedBenchmarksValue,
    unitPlan,
    showTagSnP,
    showTagSnpV2,
    taggableElements: getTaggableElements({
      curriculumProgramType,
      unitPlan,
      plannerElementSets,
    }),
  };
};

const ComposedDetailComponent = compose(
  I18nHOC({ resource: "journal" }),
  connect(mapStateToProps, mapActionCreators)
)(Details);

ComposedDetailComponent.defaultProps = {
  hideLearninGoals: false,
  hideTaggedStudents: false,
  hideUnitDetails: false,
  showTaggedFolders: true,
};

export default ComposedDetailComponent;
