import React, { Component } from "react";
import PropTypes from "prop-types";

const FeedbackNotificationIcon = props => {
  return (
    <svg width={props.width} height="32" viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.99999V3.89367C16.3748 3.87442 16.7829 3.88696 17.1434 3.97279C17.4643 4.04919 17.6665 4.16536 17.785 4.29211C17.8847 4.39886 18 4.59091 18 4.99999V8.99999C18 9.55225 18.4477 9.99995 18.9999 9.99999L22.0001 10.0002C22.5523 10.0002 23 10.4479 23 11.0002V15.0003C23 16.6572 21.6569 18.0003 20 18.0003H13V9.97982C13.4897 9.94793 14.0112 9.8672 14.4687 9.66382C14.9109 9.46727 15.3203 9.14977 15.606 8.66011C15.884 8.18342 16 7.62032 16 6.99999ZM12.0254 8.00032C12.8309 7.99975 13.3322 7.98033 13.6563 7.83625C13.7766 7.78275 13.8359 7.72523 13.8784 7.65238C13.9285 7.56656 14 7.37965 14 6.99999V2.99999C14 2.51115 14.3534 2.09396 14.8356 2.01359C15.3547 1.92708 16.5242 1.76946 17.6066 2.02718C18.1607 2.15911 18.771 2.41794 19.2463 2.92662C19.7403 3.45528 20 4.15906 20 4.99999V8.00005L22.0002 8.00018C23.657 8.00028 25 9.34339 25 11.0002V15.0003C25 17.7617 22.7614 20.0003 20 20.0003H13C12.7949 20.0003 12.597 19.9694 12.4107 19.9121C12.2854 19.9686 12.1464 20 12 20H8C7.44772 20 7 19.5523 7 19V9C7 8.44772 7.44772 8 8 8H12C12.0085 8 12.017 8.00011 12.0254 8.00032ZM11 10H9V18H11V10ZM7 6C5.34315 6 4 7.34315 4 9V19C4 20.6569 5.34315 22 7 22H13C14.1046 22 15 22.8954 15 24V26.3439L19.2948 22.7098C19.8365 22.2515 20.5231 22 21.2326 22H25C26.6569 22 28 20.6569 28 19V9C28 7.34315 26.6569 6 25 6H24C23.4477 6 23 5.55228 23 5C23 4.44772 23.4477 4 24 4H25C27.7614 4 30 6.23858 30 9V19C30 21.7614 27.7614 24 25 24H21.2326C20.9961 24 20.7672 24.0838 20.5867 24.2366L14.6459 29.2634C14.3487 29.5149 13.9325 29.571 13.5793 29.4072C13.226 29.2433 13 28.8894 13 28.5V24H7C4.23858 24 2 21.7614 2 19V9C2 6.23858 4.23858 4 7 4H10C10.5523 4 11 4.44772 11 5C11 5.55228 10.5523 6 10 6H7Z"
        fill={props.fill}
      />
    </svg>
  );
};

FeedbackNotificationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

FeedbackNotificationIcon.defaultProps = {
  width: 32,
  height: 32,
};

export default FeedbackNotificationIcon;
