import { withAsyncRouteLoading } from "UIComponents";

const loadNewComponent = () =>
  new Promise((resolve, reject) => {
    require.ensure(
      [],
      require => {
        const component = require("./components").default;
        resolve(component);
      },
      err => {
        reject(err);
      },
      "snp-guidance-tab"
    );
  });

const updateStore = () => ({
  path: "guidance-documents",
  component: withAsyncRouteLoading(loadNewComponent),
});

export default updateStore;
