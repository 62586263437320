import React, { useState } from "react";
import { SearchOutlined } from "@toddle-design/web-icons";
import classes from "./SearchInput.scss";
import { InputTextField } from "UIComponents";

const inputContainerStyle = {
  width: "100%",
  marginLeft: "12px",
};

const inputStyle = { padding: 0, border: "none" };

const SearchInput = ({
  searchText,
  updateSearchText,
  placeholder,
  containerStyle,
  searchContainerStyle,
}) => {
  const [searchValue, setSearchValue] = useState(searchText);

  const updateSearchValue = value => {
    setSearchValue(value);
    updateSearchText(value);
  };

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.searchContainer} style={searchContainerStyle}>
        <div className={classes.searchIcon}>
          <SearchOutlined size={"xxx-small"} variant={"default"} />
        </div>
        <InputTextField
          value={searchValue}
          updateInputField={updateSearchValue}
          placeholder={placeholder}
          containerStyle={inputContainerStyle}
          inputStyle={inputStyle}
        />
      </div>
    </div>
  );
};

SearchInput.defaultProps = {
  placeholder: "search",
};

export default SearchInput;
