import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import client from "apolloClient";
import { goToRelativeRoute } from "modules/Services";
import {
  setCommunityActiveTab,
  handleEntityTag,
} from "Community/modules/CommunityModule";
import { updateUnitPlanMutation } from "IBPlanner/modules/IBMutations";
import { generateRandomNumber } from "Utils";
import { trackEntitySearchMutation } from "./CommunityParentEntityMutations";

export const NAME = "communityParentEntity";

export const UPDATE_COMMUNITY_FILTERS = "UPDATE_COMMUNITY_FILTERS" + " " + NAME;

export const FILTERS_CONFIG = {
  GRADE: { label: "common:grade", key: "grades" },
  THEME: { label: "common:theme", key: "themes" },
  CATEGORY: { label: "common:topic", key: "categories" },
};

export const CONTAINER_STYLES = {
  UNIT_PLAN: {
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridColumnGap: "48px",
  },
  ASSESSMENT: {
    gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
    gridColumnGap: "42px",
  },
  LE_COLLECTION: {
    gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
    gridColumnGap: "42px",
  },
  STAFF: {
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridRowGap: "24px",
    gridColumnGap: "32px",
  },
};

export const GLOBAL_TABS = [
  {
    label: "common:unit_plural",
    profileLabel: "community:contributed_units",
    value: "units",
    entityType: "UNIT_PLAN",
    profileFirst: 4,
    feedLabel: "common:unit",
    communityActiveTab: "units",
    loadMoreProfileFirst: 8,
    eventPageName: "units",
    commonFirst: 12,
  },
  {
    label: "common:le_label_plural",
    profileLabel: "community:contributed_learning_experiences",
    value: "les",
    entityType: "ASSESSMENT",
    profileFirst: 6,
    feedLabel: "common:le_label",
    communityActiveTab: "les",
    loadMoreProfileFirst: 9,
    eventPageName: "learning experiences",
    commonFirst: 12,
    laneCount: 3,
    cardContainerStyle: CONTAINER_STYLES["ASSESSMENT"],
  },
];

export const GROUP_FEED_TYPES = [
  {
    label: "community:new_units_community",
    value: "nunits",
    laneCount: 3,
    cardContainerStyle: CONTAINER_STYLES["UNIT_PLAN"],
    communityActiveTab: "units",
    entityType: "UNIT_PLAN",
    eventPageName: "new units",
    feedLabel: "common:unit",
    commonFirst: 12,
    laneType: "NEW_UNIT_PLAN",
  },
  {
    label: "community:featured_collections",
    value: "fcollections",
    laneCount: 4,
    entity: "collections",
    cardContainerStyle: CONTAINER_STYLES["LE_COLLECTION"],
    entityType: "LE_COLLECTION",
    communityActiveTab: "les",
    eventPageName: "featured collections",
    feedLabel: "community:le_collection",
    commonFirst: 12,
  },
  {
    label: "community:new_les_week",
    value: "nles",
    laneCount: 4,
    cardContainerStyle: CONTAINER_STYLES["ASSESSMENT"],
    entityType: "ASSESSMENT",
    communityActiveTab: "les",
    eventPageName: "new learning experiences",
    feedLabel: "common:le_label",
    commonFirst: 12,
    laneType: "NEW_ASSESSMENT",
  },
  {
    label: "common:le_label_plural",
    profileLabel: "community:contributed_learning_experiences",
    value: "tles",
    entityType: "ASSESSMENT",
    feedLabel: "common:le_label",
    communityActiveTab: "les",
    eventPageName: "topic learning experiences",
    laneCount: 3,
    cardContainerStyle: CONTAINER_STYLES["ASSESSMENT"],
    commonFirst: 12,
  },
];

export const SEARCH_TABS = [
  {
    label: "community:all_results",
    value: "all",
    feedType: undefined,
    eventPageName: `all`,
  },
  ...GLOBAL_TABS,
  {
    label: "common:educator_plural",
    value: "educators",
    feedType: undefined,
    entityType: "STAFF",
    feedLabel: "common:educator",
    commonFirst: 24,
    eventPageName: `educators`,
  },
];

export const ALL_TABS = [...SEARCH_TABS, ...GROUP_FEED_TYPES];

export const getTabs = ({ t, parentEntityType, entityType }) => {
  let tabs = [];
  switch (parentEntityType) {
    case "bookmarked":
      tabs = _.map(GLOBAL_TABS, item => {
        const { eventPageName } = item || {};
        return {
          ...(item || {}),
          eventPageName: `bookmarks ${eventPageName}`,
        };
      });
      break;

    case "search":
      tabs = _.map(SEARCH_TABS, item => {
        const { eventPageName } = item || {};
        return {
          ...(item || {}),
          eventPageName: `search ${eventPageName}`,
        };
      });
      break;
  }
  return _.map(tabs, item => {
    const { label, value, feedLabel } = item;
    return { ...item, label: t(label), feedLabel };
  });
};

export const getCommunityFeedVariablesMemoize = _.memoize(
  params => getCommunityFeedVariables(params),
  params => JSON.stringify(params)
);

export const getCommunityFeedVariables = ({
  userId,
  communityFilters,
  first,
}) => {
  let { activeTab, searchText, entityType, feedType, filters, seed } =
    communityFilters || {};
  const tab = _.find(ALL_TABS, item => item.value == activeTab) || {};
  const { entityType: tabEntityType, feedType: tabFeedType, commonFirst } =
    tab || {};

  if (!entityType) {
    entityType = tabEntityType;
  }

  if (!feedType) {
    feedType = tabFeedType;
  }

  const gradeFilters = _.filter(
    _.get(filters, "grades", []),
    value => value != "ALL"
  );
  const themeFilters = _.filter(
    _.get(filters, "themes", []),
    value => value != "ALL"
  );
  const categoriesFilters = _.filter(
    _.get(filters, "categories", []),
    value => value != "ALL"
  );

  return {
    id: userId,
    entityTypes: entityType,
    first: first ? first : commonFirst,
    searchText,
    feedType,
    filters: {
      grades: gradeFilters.length > 0 ? gradeFilters : undefined,
      themes: themeFilters.length > 0 ? themeFilters : undefined,
      categories: categoriesFilters.length > 0 ? categoriesFilters : undefined,
    },
    seed,
  };
};

export const getCommunityGroupFeedVariablesMemoize = _.memoize(
  params => getCommunityGroupFeedVariables(params),
  params => JSON.stringify(params)
);

const getCommunityGroupFeedVariables = ({ userId, communityFilters }) => {
  const variables = getCommunityFeedVariables({
    userId,
    communityFilters,
  });

  return { ...variables };
};

export const getCommunityCombineFeedVariablesMemoize = _.memoize(
  params => getCommunityCombineFeedVariables(params),
  params => JSON.stringify(params)
);

const getCommunityCombineFeedVariables = ({
  userId,
  communityFilters,
  unitsFirst,
  lesFirst,
  educatorsFirst,
}) => {
  const variables = getCommunityFeedVariables({
    userId,
    communityFilters,
  });

  return { ...variables, unitsFirst, lesFirst, educatorsFirst };
};

export const gotoCollection = ({ id }) => {
  return (dispatch, getState) => {
    const route = `collection/${id}`;

    dispatch(goToRelativeRoute({ route, type: "push" }));
  };
};

export const gotoUnitPreview = ({ id, target }) => {
  return (dispatch, getState) => {
    let qs = "";
    if (target) {
      qs = `?target=${target}`;
    }
    const route = `unitpreview/${id}${qs}`;

    dispatch(goToRelativeRoute({ route, type: "push" }));
  };
};

export const onCloseUnitPreview = () => {
  return (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({
        replacePath: "unitpreview",
        type: "replace",
        route: "./",
      })
    );
  };
};

export const setCommunityActiveTabValue = ({ parentEntityType, value }) => {
  return async (dispatch, getState) => {
    if (parentEntityType == "featured") {
      dispatch(setCommunityActiveTab(value));
    }

    if (parentEntityType == "all" && (value == "les" || value == "units")) {
      dispatch(setCommunityActiveTab(value));
    }
  };
};

export const goToChildEntity = params => {
  return async (dispatch, getState) => {
    const { value, parentEntityType, type = "replace" } = params || {};

    dispatch(setCommunityActiveTabValue({ parentEntityType, value }));
    dispatch(
      updateCommunityFilters({
        id: parentEntityType,
        params: { activeTab: value },
      })
    );

    dispatch(
      goToRelativeRoute({
        route: `${parentEntityType}${value ? `/${value}` : ``}`,
        type,
        replacePath: `${parentEntityType}`,
      })
    );
  };
};

export const updateCommunityFilters = data => {
  return { type: UPDATE_COMMUNITY_FILTERS, data };
};

const REDUCER_HANDLERS = {
  [UPDATE_COMMUNITY_FILTERS]: (state, action) => {
    let params = action.data.params;

    let id = action.data.id;
    if (!state.communityFilters[id]) {
      state = update(state, {
        communityFilters: { [id]: { $set: { seed: state.feedSeed } } },
      });
    }
    Object.keys(params).map((key, index) => {
      if (!_.get(state.communityFilters[id], key, "")) {
        state = update(state, {
          communityFilters: {
            [id]: { [key]: { $set: {} } },
          },
        });
      }
      state = update(state, {
        communityFilters: {
          [id]: { [key]: { $set: params[key] } },
        },
      });
    });

    return state;
  },
};

const initialState = {
  communityFilters: {},
  feedSeed: generateRandomNumber(-1, 1),
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const updateUnitPlan = (data, params) => {
  return (dispatch, getState) => {
    const { unitPlanId, fieldUID, valuesToAdd, valuesToRemove } = params || {};
    client.mutate({
      mutation: updateUnitPlanMutation,
      variables: {
        unitPlanId,
        updatedBy: getState().login.userInfo.id,
        fields: data,
      },
    });
  };
};

export const trackEntitySearch = input => {
  return (dispatch, getState) => {
    const userLoggedIn = _.get(getState(), "login.userLoggedIn");
    if (!userLoggedIn) return;

    client.mutate({
      mutation: trackEntitySearchMutation,
      variables: input,
    });
  };
};
