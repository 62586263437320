import React from "react";
import classes from "./FilterCountBlock.scss";
import classNames from "classnames";

const FilterCountBlock = props => {
  const {
    filters,
    activeFilter,
    onFilterClick,
    containerStyle = {},
    filterContainerStyle = {},
    filterBoxStyle = {},
    labelStyle = {},
    countStyle = {},
    activeLabelStyle,
    activeCountStyle,
    colorCountText = false,
  } = props;

  return (
    !_.isEmpty(filters) && (
      <div className={classes.container} style={containerStyle}>
        {_.map(filters, ({ key, label, value, count, color }, index) => {
          const isActiveFilter = activeFilter == value;

          const filterBoxClasses = classNames({
            [classes.filterBox]: true,
            [classes.activeBorder]: isActiveFilter,
          });

          const showEmptyDiv = index < _.size(filters) - 1;

          return (
            <div
              key={key}
              className={classes.filterContainer}
              style={filterContainerStyle}
              onClick={() => {
                onFilterClick(value);
              }}
            >
              <div className={filterBoxClasses} style={filterBoxStyle}>
                <div
                  className={classes.count}
                  style={{
                    ...countStyle,
                    ...(isActiveFilter ? activeCountStyle : {}),
                    ...(colorCountText && { color }),
                  }}
                >
                  {count}
                </div>
                <div
                  className={classes.label}
                  style={{
                    ...labelStyle,
                    ...(isActiveFilter ? activeLabelStyle : {}),
                    color,
                  }}
                >
                  {label}
                </div>
              </div>
              {showEmptyDiv && <div className={classes.emptyDiv}></div>}
            </div>
          );
        })}
      </div>
    )
  );
};

FilterCountBlock.defaultProps = {
  activeLabelStyle: {},
  activeCountStyle: {},
};

export default FilterCountBlock;
