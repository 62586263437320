import React, { PureComponent } from "react";
import { compose } from "redux";
import classes from "./RubricTable.scss";
import { TextAreaInput, I18nHOC, LinkWithTooltip } from "UIComponents";
import { colors, fontStyle } from "Constants";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  createRubricIndicator,
  writeRubricElementLocal,
  deleteRubricIndicator,
  createRubricCriteria,
  deleteRubricCriteria,
} from "Rubric/modules/RubricModule";
import { updateField } from "modules/Services";

import { Tick } from "SvgComponents";
import { Button, IconButton, Tooltip } from "@toddle-design/web";
import { AddOutlined, DeleteOutlined } from "@toddle-design/web-icons";

const textAreaStyles = {
  cell: {
    border: "none",
    marginTop: 0,
    flexShrink: 0,
    color: colors.textSubtle,
    lineHeight: 1.57,
    width: "100%",
    fontSize: "1.6rem",
    padding: "16px",
    overflow: "hidden",
    minHeight: "100%",
    ...fontStyle.medium,
  },
  rowHeaderCell: {
    color: colors.textDefault,
    padding: "16px 0 16px 16px",
    ...fontStyle.demiBold,
  },
  headerCell: {
    color: colors.surfaceWhite,
    fontSize: "1.6rem",
    padding: "8px 16px",
    height: "unset",
    ...fontStyle.medium,
  },
};

const ColumnCell = React.memo(props => {
  const {
    setFocusedColumn,
    isEvaluation,
    isSelected,
    isStudentSelected,
    descriptor,
    updateEvaluationCell,
    showInsight,
    updateInputRef,
    setFocusedCell,
    refKey,
    writeRubricElement,
    responseCount,
    insightColor,
    mode,
    criteriaObjId,
    userType,
    cellStyle,
    studentArray,
    showInMediaManager,
    t,
  } = props;

  const disabled = _.includes(descriptor.id, "NEW");

  const cellClass = classNames(
    { [classes.cell]: true },
    { [classes.clickableCell]: isEvaluation && userType == "staff" },
    { [classes.clickableStudentCell]: isEvaluation && userType == "student" },
    {
      [classes.selectableCell]: isSelected,
    },
    {
      [classes.selectableStudentCell]: !isSelected && isStudentSelected,
    },
    {
      [classes.responsiveCell]: showInMediaManager,
    },
    {
      [classes.disabled]: disabled,
    }
  );

  const moreComponent = (
    <div className={classes.toolTipConainer}>
      {_.map(studentArray, (student, i) => {
        const { firstName, lastName } = student;
        return (
          <div className={classes.moreToolTipItem} key={i}>
            {firstName} {lastName}
          </div>
        );
      })}
    </div>
  );

  const isToolTipVisible = !_.isEmpty(studentArray);

  return (
    <div
      onFocus={() => {
        setFocusedColumn(criteriaObjId);
      }}
      className={cellClass}
      style={cellStyle}
      key={descriptor.id}
      onClick={
        isEvaluation
          ? () =>
              updateEvaluationCell({
                rowId: descriptor.indicator.id,
                columnId:
                  (userType == "staff" && isSelected) ||
                  (userType == "student" && isStudentSelected)
                    ? null
                    : descriptor.criteria.id,
                isSelected,
              })
          : null
      }
    >
      {isSelected || isStudentSelected ? (
        <div className={classes.tickSvgContainer}>
          {isStudentSelected && (
            <div
              style={{ marginRight: isSelected ? "8px" : "0px" }}
              className={classes.tickSvgComponent}
            >
              <Tick width={16} height={16} fillBackground={colors.yellow50} />
            </div>
          )}
          {isSelected && (
            <div className={classes.tickSvgComponent}>
              <Tick width={16} height={16} />
            </div>
          )}
        </div>
      ) : null}
      {!showInsight ? (
        <TextAreaInput
          value={descriptor.label}
          textAreaStyles={textAreaStyles.cell}
          updateInputRef={ref => updateInputRef({ ref, refKey })}
          onBlurInputField={() => setFocusedCell(null)}
          onFocusInputField={() => setFocusedCell(refKey)}
          emptyText={""}
          disabled={disabled}
          mode={mode}
          placeholder={t("common:definition")}
          updateInputField={value =>
            writeRubricElement({
              elementDetails: descriptor,
              id: descriptor.id,
              label: value,
              type: "DESCRIPTOR",
            })
          }
          className={classes.placeholderColor}
        />
      ) : (
        <div style={{ ...textAreaStyles.cell, paddingTop: 12 }}>
          <LinkWithTooltip
            tooltip={moreComponent}
            placement={"top"}
            isVisible={isToolTipVisible}
          >
            <div className={classes.insight}>
              <div
                className={classes.insightCount}
                style={{ color: insightColor }}
              >
                {responseCount}
              </div>
              <div className={classes.insightStudent}>
                {t("common:student", { count: responseCount })}
              </div>
            </div>
          </LinkWithTooltip>
          <div
            className={classes.insightDescriptor}
            dangerouslySetInnerHTML={{
              __html: descriptor.label,
            }}
          />
        </div>
      )}
    </div>
  );
});
class RubricTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inFocusColumn: null,
      inFocusRow: null,
    };
    if (props.customRef) {
      props.customRef(this);
    }
    this.focusedCell = null;
    this.cellRefs = {};
  }

  blur = () => {
    if (this.focusedCell && this.cellRefs[this.focusedCell]) {
      this.cellRefs[this.focusedCell].blur();
    }
  };

  writeRubricElement = ({ id, type, label, elementDetails }) => {
    this.props.writeRubricElementLocal({ id, type, label, elementDetails });
    this.props.updateField({
      key: id,
      params: { id, type, label },
      type: "RUBRIC_ELEMENT",
    });
  };

  createRubricIndicator = () => {
    const {
      rubric: { id: rubricId },
      createRubricIndicator,
      setScrollToBottom,
    } = this.props;
    createRubricIndicator({ rubricId });
    if (setScrollToBottom) {
      setTimeout(setScrollToBottom);
    }
  };

  createRubricCriteria = () => {
    const {
      rubric: { id: rubricId },
      createRubricCriteria,
    } = this.props;
    createRubricCriteria({ rubricId });
  };

  deleteRubricCriteria = id => {
    const {
      rubric: { id: rubricId },
      deleteRubricCriteria,
    } = this.props;
    deleteRubricCriteria({ id, rubricId });
  };

  deleteRubricIndicator = id => {
    const {
      rubric: { id: rubricId },
      deleteRubricIndicator,
    } = this.props;

    deleteRubricIndicator({ id, rubricId });
  };

  getHeaderRow = () => {
    const { firstColumnCellStyle, showInMediaManager } = this.props;

    const pseudoFirstColumnCellClass = classNames({
      [classes.pseudoFirstColumn]: true,
      [classes.responsiveCell]: showInMediaManager,
    });

    const emptyFirstColumnCellClass = classNames({
      [classes.emptyFirstColumnCell]: true,
      [classes.responsiveCell]: showInMediaManager,
    });

    return (
      <div className={classes.pseudoRow}>
        <div className={classes.headerRow}>
          <div
            className={pseudoFirstColumnCellClass}
            style={firstColumnCellStyle}
          >
            <div
              className={emptyFirstColumnCellClass}
              style={firstColumnCellStyle}
            ></div>
          </div>

          {this.getHeaderColumns()}
        </div>
      </div>
    );
  };

  getHeaderColumns = () => {
    const {
      mode,
      rubric,
      cellStyle,
      rowHeaderCellStyle,
      showInMediaManager,
      t,
    } = this.props;
    const criterias = _.get(rubric, "criterias", []);

    const pseudoColumnClass = classNames({
      [classes.pseudoColumn]: true,
      [classes.responsiveCell]: showInMediaManager,
    });

    return _.map(criterias, criteriaObj => {
      const refKey = `Criteria_${criteriaObj.id}`;

      const disabled = _.includes(criteriaObj.id, "NEW");

      const rowHeaderCellClass = classNames({
        [classes.rowHeaderCell]: true,
        [classes.rowHeaderCellEdit]: mode !== "view",
        [classes.responsiveCell]: showInMediaManager,
        [classes.disabled]: disabled,
      });
      return (
        <div
          className={pseudoColumnClass}
          style={cellStyle}
          onFocus={() => {
            this.setState({ inFocusColumn: criteriaObj.id });
          }}
          key={criteriaObj.id}
        >
          <div className={rowHeaderCellClass} style={rowHeaderCellStyle}>
            <TextAreaInput
              updateInputRef={ref => (this.cellRefs[refKey] = ref)}
              value={criteriaObj.label}
              mode={mode}
              textAreaStyles={{
                ...textAreaStyles.cell,
                ...textAreaStyles.headerCell,
              }}
              onBlurInputField={() => (this.focusedCell = null)}
              onFocusInputField={() => (this.focusedCell = refKey)}
              disabled={disabled}
              minRows={1}
              emptyText={""}
              placeholder={t("common:click_to_add_withLabel", {
                label: t("common:criteria"),
              })}
              updateInputField={value =>
                this.writeRubricElement({
                  elementDetails: criteriaObj,
                  id: criteriaObj.id,
                  label: value,
                  type: "CRITERIA",
                })
              }
              className={classes.placeholderTextOnSubtle}
            />
            {mode !== "view" && (
              <div className={classes.rowHeaderCellActionButton}>
                <Tooltip
                  tooltip={t("common:delete_with_label", {
                    label: t("common:criteria"),
                  })}
                >
                  <IconButton
                    icon={<DeleteOutlined variant="on" />}
                    variant={"plain"}
                    size={"small"}
                    onClick={() => this.deleteRubricCriteria(criteriaObj?.id)}
                    disabled={_.includes(criteriaObj.id, "NEW")}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  getRows = () => {
    const { mode, firstColumnCellStyle, showInMediaManager, t } = this.props;
    // const { inFocusRow } = this.state;
    // const { indicators } = this.props.rubric;
    const indicators = _.get(this.props.rubric, "indicators", []);

    return _.map(indicators, indicatorObj => {
      const refKey = `Indicator_${indicatorObj.id}`;
      const disabled = _.includes(indicatorObj.id, "NEW");

      const firstColumnCellClasses = classNames({
        [classes.firstColumnCell]: true,
        [classes.firstColumnCellEdit]: mode !== "view",
        [classes.disabled]: disabled,
        [classes.responsiveCell]: showInMediaManager,
      });

      return (
        <div
          className={classes.pseudoRow}
          key={indicatorObj.id}
          onFocus={() => {
            this.setState({ inFocusRow: indicatorObj.id });
          }}
        >
          <div className={classes.row}>
            <div
              className={firstColumnCellClasses}
              style={firstColumnCellStyle}
            >
              <TextAreaInput
                value={indicatorObj.label}
                mode={mode}
                onBlurInputField={() => (this.focusedCell = null)}
                onFocusInputField={() => (this.focusedCell = refKey)}
                textAreaStyles={{
                  ...textAreaStyles.cell,
                  ...textAreaStyles.rowHeaderCell,
                }}
                updateInputRef={ref => (this.cellRefs[refKey] = ref)}
                disabled={disabled}
                emptyText={""}
                placeholder={t("common:criteria_name_or_description")}
                updateInputField={value =>
                  this.writeRubricElement({
                    elementDetails: indicatorObj,
                    id: indicatorObj.id,
                    label: value,
                    type: "INDICATOR",
                  })
                }
                className={classes.placeholderColor}
              />

              {mode !== "view" && (
                <div className={classes.firstColumnCellActionButton}>
                  <Tooltip
                    tooltip={t("common:delete_with_label", {
                      label: t("common:row"),
                    })}
                  >
                    <IconButton
                      icon={<DeleteOutlined variant={"subtle"} />}
                      variant={"plain"}
                      size={"x-small"}
                      onClick={() =>
                        this.deleteRubricIndicator(indicatorObj.id)
                      }
                      disabled={_.includes(indicatorObj.id, "NEW")}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            {this.getColumns(indicatorObj.id)}
          </div>
        </div>
      );
    });
  };

  setFocusedColumn = columnId => {
    this.setState({ inFocusColumn: columnId });
  };

  updateInputRef = ({ ref, refKey }) => {
    this.cellRefs[refKey] = ref;
  };

  setFocusedCell = refKey => {
    this.focusedCell = refKey;
  };

  getColumns = indicatorId => {
    const {
      mode,
      isEvaluation,
      updateEvaluationCell,
      isEvaluatedByStudent,
      toolEvaluationResponses,
      showInsight,
      insights,
      insightColor,
      userType,
      cellStyle,
      showInMediaManager = false,
      t,
    } = this.props;
    // const { criterias, descriptors } = this.props.rubric;
    const criterias = _.get(this.props.rubric, "criterias", []);
    const descriptors = _.get(this.props.rubric, "descriptors", []);

    const groupedToolEvaluationResponse = _.groupBy(
      toolEvaluationResponses,
      toolEvaluationResponse => toolEvaluationResponse.creatorType
    );

    const staffToolEvaluationResponses = _.get(
      groupedToolEvaluationResponse,
      "STAFF",
      []
    );

    const studentToolEvaluationResponses = _.get(
      groupedToolEvaluationResponse,
      "STUDENT",
      []
    );

    return _.map(criterias, (criteriaObj, index) => {
      const descriptor = _.find(
        descriptors,
        des =>
          des.indicator.id == indicatorId && des.criteria.id == criteriaObj.id
      );

      const refKey = `Descriptor_${descriptor.id}`;

      const responseCount = _.get(
        _.find(
          insights,
          insight =>
            insight.rowId == descriptor.indicator.id &&
            insight.columnId == descriptor.criteria.id
        ),
        "responseCount",
        0
      );

      const studentArray = _.get(
        _.find(
          insights,
          insight =>
            _.isEqual(
              _.get(insight, "rowId", ""),
              _.get(descriptor, "indicator.id", "")
            ) &&
            _.isEqual(
              _.get(insight, "columnId"),
              _.get(descriptor, "criteria.id")
            )
        ),
        "studentsList",
        []
      );

      const isSelected =
        _.findIndex(
          staffToolEvaluationResponses,
          response =>
            response.rowId == descriptor.indicator.id &&
            response.columnId == descriptor.criteria.id
        ) >= 0;

      const isStudentSelected =
        _.findIndex(
          studentToolEvaluationResponses,
          response =>
            response.rowId == descriptor.indicator.id &&
            response.columnId == descriptor.criteria.id
        ) >= 0 && isEvaluatedByStudent;

      return (
        <ColumnCell
          t={t}
          criteriaObjId={criteriaObj.id}
          setFocusedColumn={this.setFocusedColumn}
          isEvaluation={isEvaluation}
          isSelected={isSelected}
          isStudentSelected={isStudentSelected}
          descriptor={descriptor}
          updateInputRef={this.updateInputRef}
          setFocusedCell={this.setFocusedCell}
          writeRubricElement={this.writeRubricElement}
          insightColor={insightColor}
          showInsight={showInsight}
          responseCount={responseCount}
          refKey={refKey}
          updateEvaluationCell={updateEvaluationCell}
          mode={mode}
          userType={userType}
          cellStyle={cellStyle}
          studentArray={studentArray}
          showInMediaManager={showInMediaManager}
        ></ColumnCell>
      );
    });
  };

  render() {
    const {
      mode,
      rubric,
      onClickToolSave,
      conStyle,
      innerConStyle,
      t,
      showSaveTemplateButton,
    } = this.props;

    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.viewContainer]: mode == "view" }
    );
    return (
      <React.Fragment>
        <div
          onBlur={() => {
            this.setState({ inFocusColumn: null, inFocusRow: null });
          }}
          className={containerClass}
          style={
            mode == "view"
              ? {
                  ...conStyle,
                  marginTop: 0,
                }
              : { ...conStyle }
          }
        >
          <div className={classes.innerContainer} style={innerConStyle}>
            {rubric ? (
              this.getHeaderRow()
            ) : (
              <div className={classes.emptyText} style={{ padding: "16px" }}>
                {t("common:no_label_defined", { label: "rubric" })}
              </div>
            )}
            {rubric ? this.getRows() : null}
          </div>
        </div>
        {mode != "view" && (
          <div className={classes.bottomContainer}>
            <div className={classes.borderLeftContainer}>
              <Button
                size={"medium"}
                variant={"outlined-subtle"}
                icon={<AddOutlined size={"xx-small"} />}
                onClick={this.createRubricIndicator}
              >
                {t("common:add_with_label", { label: t("common:row") })}
              </Button>
              <Button
                size={"medium"}
                variant={"outlined-subtle"}
                icon={<AddOutlined size={"xx-small"} />}
                onClick={this.createRubricCriteria}
              >
                {t("common:select_criterion_plural")}
              </Button>
            </div>

            {showSaveTemplateButton && (
              <div
                className={classes.saveTemplateButton}
                onClick={onClickToolSave}
              >
                {t("common:save_this_as_this", {
                  from_label: t("common:rubric"),
                  to_label: t("common:template"),
                })}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  createRubricCriteria,
  createRubricIndicator,
  deleteRubricCriteria,
  deleteRubricIndicator,
  writeRubricElementLocal,
  updateField,
};

const mapStateToProps = (state, ownProps) => {
  return {
    userType: state.login.userInfo.user_type,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(RubricTable);

RubricTable.defaultProps = {
  isEvaluation: false,
  toolEvaluationResponses: [],
  isEvaluatedByStudent: false,
  showInsight: false,
  insights: [],
  showSaveTemplateButton: true,
};
