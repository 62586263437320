/**--external-- */
import React, { memo } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

/**--internal-- */
import { LinkWithTooltip } from "UIComponents";

/**--relative-- */
import classes from "./EvidenceFeedHeader.scss";
import { tooltipContainerStyle } from "./EvidenceFeedHeaderStyles";

const MAX_VISIBLE_MORE_FILTERS = 4;

const MAX_VISIBLE_FILTERS = 2;

const EvidenceFeedHeader = memo(props => {
  const {
    count,
    t,
    headerTitle,
    countLocale,
    selectedFiltersItems,
    evidenceFeedCountStyle,
  } = props;

  const renderFeedHeaderWithTooltip = () => {
    const displayItems =
      _.size(selectedFiltersItems) <= MAX_VISIBLE_MORE_FILTERS
        ? selectedFiltersItems
        : [..._.slice(selectedFiltersItems, 0, MAX_VISIBLE_MORE_FILTERS)];

    const moreSelectedFiltersCount =
      _.size(selectedFiltersItems) - MAX_VISIBLE_MORE_FILTERS;

    return (
      <Trans i18nKey={"snp:evidence_header_with_trans_label"}>
        {{ totalItems: count }}
        {{
          evidenceLabel: t(countLocale, { count }),
        }}

        <LinkWithTooltip
          tooltip={
            <div>
              {_.map(displayItems, label => (
                <div key={label}>{label}</div>
              ))}
              {_.size(selectedFiltersItems) > MAX_VISIBLE_MORE_FILTERS && (
                <div className={classes.showMoreText}>{`+${t(
                  "common:more_withNumber_andLabel",
                  {
                    number: moreSelectedFiltersCount,
                    label: t("snp:filter", { count: moreSelectedFiltersCount }),
                  }
                )}`}</div>
              )}
            </div>
          }
          tooltipContainerStyle={tooltipContainerStyle}
          placement={"bottom"}
        >
          <div className={classes.filterCountText}>
            {{
              transLabel: t("common:lowercase", {
                text: t("snp:selected_filter", {
                  count: _.size(selectedFiltersItems),
                }),
              }),
            }}
          </div>
        </LinkWithTooltip>
      </Trans>
    );
  };

  const renderFeedHeader = () => {
    /**Whether we require evidence count in feed or not */
    const isCountBasedTitle = !!countLocale;

    /**If not then just return headerTitle */
    if (!isCountBasedTitle) return headerTitle;

    /**If yes,then return title based on count and total selected filters */

    if (!count) return null;

    switch (_.size(selectedFiltersItems)) {
      case 0: {
        return t("snp:evidence_header_with_label1_label2", {
          evidenceLabel: t(countLocale, { count }),
          count,
        });
      }
      case 1:
      case MAX_VISIBLE_FILTERS: {
        const displayTextLocaleParams = {
          count,
          evidenceLabel: t(countLocale, { count }),
          label1: selectedFiltersItems[0],
        };
        return t(
          "snp:evidence_header_with_label1_label2",
          displayTextLocaleParams
        );
      }
      default: {
        return renderFeedHeaderWithTooltip();
      }
    }
  };

  return (
    <div className={classes.evidenceFeedCount} style={evidenceFeedCountStyle}>
      {renderFeedHeader()}
    </div>
  );
});

EvidenceFeedHeader.displayName = "EvidenceCountHeader";

EvidenceFeedHeader.defaultProps = {
  isCountNeeded: true,
  customHeader: "",
  selectedFiltersItems: [],
  evidenceFeedCountStyle: {},
};

EvidenceFeedHeader.propTypes = {
  isCountNeeded: PropTypes.bool,
  customHeader: PropTypes.string,
  selectedFiltersItems: PropTypes.array,
  evidenceFeedCountStyle: PropTypes.object,
};

export default EvidenceFeedHeader;
