import PropTypes from "prop-types";
import React from "react";
import classes from "./RadioButtonList.scss";
import { RadioButton } from "UIComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";
class RadioButtonList extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  getAllOption = () => {
    const {
      listItemStyle,
      options,
      value,
      disabled,
      itemSelectedStyle,
      buttonColor,
      name,
      labelStyle,
    } = this.props;

    let allOptionLabelLocale = this.props.allOptionLabel;

    if (allOptionLabelLocale === "common:all") {
      allOptionLabelLocale = this.props.t(allOptionLabelLocale);
    }

    const optionValues = _.map(options, ({ value }) => value);

    const isChecked = _.size(value) === _.size(optionValues);

    return (
      <div
        className={classes.option}
        style={{
          ...listItemStyle,
          ...(isChecked ? itemSelectedStyle : {}),
        }}
        onClick={disabled ? null : () => this.updateValue(optionValues)}
      >
        <RadioButton
          label={allOptionLabelLocale}
          isChecked={isChecked}
          isDisabled={disabled}
          color={buttonColor}
          labelStyles={labelStyle}
          name={name}
        />
      </div>
    );
  };

  getOption = () => {
    const {
      listItemStyle,
      options,
      disabled,
      value,
      buttonColor,
      labelStyle,
      itemSelectedStyle,
      name,
      isOptionWithBorder,
    } = this.props;
    const listItem = classNames(
      { [classes.option]: true },
      { [classes.optionWithBorder]: isOptionWithBorder }
    );
    return _.map(options, (option, index) => {
      const { label, value: optionValue, subText } = option;
      return (
        <div
          key={index}
          className={listItem}
          style={{
            ...listItemStyle,
            ...(value === optionValue ? itemSelectedStyle : {}),
          }}
          onClick={disabled ? null : () => this.updateValue(optionValue)}
        >
          <RadioButton
            label={label}
            subText={subText}
            isChecked={value === optionValue}
            isDisabled={disabled}
            color={buttonColor}
            labelStyles={labelStyle}
            name={name}
          />
        </div>
      );
    });
  };

  shouldShowEditEmpty = () => {
    return _.get(this.props.options, "length", 0) == 0;
  };

  renderView = () => {
    const { viewTextStyle } = this.props;
    return (
      <div className={classes.viewText} style={viewTextStyle}>
        {this.getViewValue()}
      </div>
    );
  };

  getViewValue = () => {
    const { options, value, emptyText } = this.props;

    return value
      ? _.get(_.find(options, { value: value }), "label", "")
      : emptyText
      ? emptyText
      : "N/A";
  };

  renderEdit = () => {
    const { listContainerStyle, allOptionLabel } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.optionContainer} style={listContainerStyle}>
          {/* {allOptionLabel ? this.getAllOption() : null} */}
          {this.getOption()}
        </div>
      </div>
    );
  };
}

export default RadioButtonList;

RadioButtonList.defaultProps = {
  ...UIBaseComponent.defaultProps,
  options: [],
  buttonColor: "blue",
  labelStyle: {},
  itemSelectedStyle: {},
  listItemStyle: {},
  viewTextStyle: {},
};

RadioButtonList.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
};
