import gql from "graphql-tag";
import { organizationAttendanceOptionFragment } from "modules/CommonFragments";

export const createOrganizationAttendanceOptionMutation = gql`
  mutation createOrganizationAttendanceOption(
    $input: AttendanceOptionCreateInput!
  ) {
    platform {
      createAttendanceOption(input: $input) {
        ...organizationAttendanceOptionItem
      }
    }
  }
  ${organizationAttendanceOptionFragment.organizationAttendanceOption}
`;

export const updateOrganizationAttendanceOptionMutation = gql`
  mutation updateOrganizationAttendanceOption(
    $input: AttendanceOptionUpdateInput!
  ) {
    platform {
      updateAttendanceOption(input: $input) {
        ...organizationAttendanceOptionItem
      }
    }
  }
  ${organizationAttendanceOptionFragment.organizationAttendanceOption}
`;

export const deleteOrganizationAttendanceOptionMutation = gql`
  mutation deleteOrganizationAttendanceOption(
    $input: AttendanceOptionDeleteInput!
  ) {
    platform {
      deleteAttendanceOption(input: $input)
    }
  }
`;

export const setCurriculumProgramAttendanceRecordingTypeMutation = gql`
  mutation setCurriculumProgramAttendanceRecordingType(
    $curriculumProgramId: ID!
    $attendanceRecordingType: ATTENDANCE_RECORDING_TYPE
  ) {
    planner {
      setCurriculumProgramAttendanceRecordingType(
        input: {
          curriculumProgramId: $curriculumProgramId
          attendanceRecordingType: $attendanceRecordingType
        }
      )
    }
  }
`;
