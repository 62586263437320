import PropTypes from "prop-types";
import React from "react";
import classes from "./PAErrorComponent.scss";
import moment from "moment";
import { compose } from "react-apollo";
import { I18nHOC, UIButton } from "UIComponents";
import { connect } from "react-redux";
import { goToPlatformHomeRoute } from "modules/NavigationModule";

class PAErrorComponent extends React.Component {
  onCountdownComplete = () => {
    //this.context.router.push('/');
  };

  getFormattedTime = milliseconds => {
    return moment.utc(milliseconds).format("ss");
  };

  goToHomePage = () => {
    const { goToPlatformHomeRoute } = this.props;
    goToPlatformHomeRoute({});
  };

  render() {
    const { t } = this.props;
    const errorText = _.get(
      this.props,
      "errorText",
      t("common:sorry_you_dont_have_access_to_this_page")
    );
    const errorSubText = _.get(
      this.props,
      "errorSubText",
      t("common:contact_school_admin")
    );
    const errorImageSrc = _.get(
      this.props,
      "errorImageSrc",
      "http://cloud.toddleapp.com/assets/webapp/locked_page.png"
    );
    const redirectButtonText = _.get(
      this.props,
      "redirectButtonText",
      t("common:go_to_homepage")
    );
    const onRedirectButtonClick = _.get(
      this.props,
      "onRedirectButtonClick",
      this.goToHomePage
    );

    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.lockImage}>
            <img src={errorImageSrc} />
          </div>
          <div className={classes.text}>{errorText}</div>
          <div className={classes.subText}>{errorSubText}</div>
          <div className={classes.redirectButton}>
            <UIButton
              color="pink"
              className={classes.redirectButtonContainer}
              onClick={onRedirectButtonClick}
              size="sm"
            >
              {redirectButtonText}
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}

PAErrorComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapActionCreators = {
  goToPlatformHomeRoute,
};

export default compose(
  I18nHOC({ resource: ["common", "configuration"] }),
  connect(null, mapActionCreators)
)(PAErrorComponent);
