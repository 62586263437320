import React from "react";

const InfoIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 16">
      <g id="Page-1" fill={props.fill && props.fill}>
        <path
          d="M8,0 C12.412,0 16,3.588 16,8 C16,12.412 12.412,16 8,16 C3.588,16 0,12.412 0,8 C0,3.588 3.588,0 8,0 Z M8,1.333 C4.324,1.333 1.3333,4.324 1.3333,8 C1.3333,11.676 4.324,14.6667 8,14.6667 C11.676,14.6667 14.6667,11.676 14.6667,8 C14.6667,4.324 11.676,1.333 8,1.333 Z M8.0000525,6.4 C8.38646216,6.4 8.7,6.71290782 8.7,7.09931748 L8.7,10.9497637 C8.7,11.3361734 8.38646216,11.6501313 8.0000525,11.6501313 C7.61364284,11.6501313 7.3,11.3361734 7.3,10.9497637 L7.3,7.09931748 C7.3,6.71290782 7.61364284,6.4 8.0000525,6.4 Z M8.00080153,3.6 C8.49694656,3.6 8.9,4.00189724 8.9,4.5000573 C8.9,4.99810276 8.49694656,5.4 8.00080153,5.4 C7.50305344,5.4 7.1,4.99810276 7.1,4.5000573 C7.1,4.00189724 7.50305344,3.6 8.00080153,3.6 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </svg>
  );
};

InfoIcon.defaultProps = {
  height: 16,
  width: 16,
};

export default InfoIcon;
