import gql from "graphql-tag";
import { pypElementRatingFragment } from "modules/CommonFragments";

export const PlannerCreateIBPYPElementRatingsMutation = gql`
  mutation PlannerCreateIBPYPElementRatings(
    $input: [IBPYPElementRatingInput!]
  ) {
    planner {
      createIBPYPElementRatings(input: $input) {
        ...basicIBPYPElementRatingItem
      }
    }
  }
  ${pypElementRatingFragment.basicIBPYPElementRating}
`;

export const PlannerDeleteIBPYPElementRatingMutation = gql`
  mutation deleteMultipleIBPYPElementRatings(
    $input: [DeleteIBPYPElementRatingsInput!]
  ) {
    planner {
      deleteMultipleIBPYPElementRatings(input: $input)
    }
  }
`;
