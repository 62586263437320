import gql from "graphql-tag";
import { childFragment } from "modules/CommonFragments";

export const addChildToFamilyMemberMutation = gql`
  mutation addChildToFamilyMember($inviteCode: String!, $id: ID!) {
    platform {
      addChildToFamilyMember(inviteCode: $inviteCode) {
        ...childItem
        ...childDetailsItem
      }
    }
  }
  ${childFragment.basicChildDetails}
  ${childFragment.childDetails}
`;

export const createFeedbackMutation = gql`
  mutation createFeedback($input: CreateFeedbackInput!) {
    platform {
      createFeedback(input: $input) {
        id
      }
    }
  }
`;

export const makeTagEntitySeenMutation = gql`
  mutation makeTagEntitySeen($regions: [ID!]) {
    platform {
      makeTagEntitySeen(input: { regions: $regions, entityType: UNITPLAN })
    }
  }
`;

export const markUserCommunicationAsSeenMutation = gql`
  mutation markUserCommunicationAsSeen($input: UserCommunicationInput!) {
    platform {
      markUserCommunicationAsSeen(input: $input)
    }
  }
`;

export const markStudentAsRequestedForFamilyInviteMutation = gql`
  mutation markStudentAsRequestedForFamilyInviteMutation(
    $input: ChangeParentClassInviteStatusInput!
  ) {
    platform {
      changeParentClassInviteStatus(input: $input) {
        status
        message
      }
    }
  }
`;

export const markStudentRequestStateSeenMutation = gql`
  mutation markStudentRequestStateSeen(
    $input: MarkStudentRequestStateSeenInput!
  ) {
    platform {
      markStudentRequestStateSeen(input: $input)
    }
  }
`;

export const updateCourseBookmarkMutation = gql`
  mutation updateCourseBookmark($input: UpdateCourseAsBookmarkInput!) {
    platform {
      updateCourseAsBookmark(input: $input)
    }
  }
`;
