import gql from "graphql-tag";

export const organizationElementRatingsFragments = {
  basicOrganizationElementRatings: gql`
    fragment basicOrganizationElementRatingsItem on OrganizationElementRatings {
      id
      ibPYPParentElementId
      academicCriteriaSets {
        label
        id
        criteriaType
        academicCriteriaValues {
          label
          description
          id
          abbreviation
        }
      }
    }
  `,
};

export const plannerElementNodeFragments = {
  basicPlannerElementNode: gql`
    fragment basicPlannerElementNodeItem on PlannerElementNode {
      id
      type
      label
      subText
      displaySequence
      objectiveRubricDescriptors {
        id
        achievementLevel {
          id
          label
          subText
        }
        label
      }
    }
  `,
};
