import React from "react";

import EditUserModalContent from "./EditUserModalContent";
import { connect } from "react-redux";
import _ from "lodash";

import { graphql, compose, withApollo } from "react-apollo";
import { getFilteredTags } from "modules/CommonQuery";
import { getFilteredTagsFromCache } from "modules/CommonGraphqlHelpers";

import {
  withLoader,
  UIButton,
  UIModal,
  I18nHOC,
  FullScreenLoader,
} from "UIComponents";
import {
  onContentUploaded,
  updateTeacherObject,
  updateTeacherDetails,
  initTeacherObject,
} from "Tooling/modules/ToolingModule";
import { getStaffDetailQuery } from "Tooling/modules/ToolingQueries";
import classes from "./EditUserInfo.scss";
import { mapleBearLikeCentralOrgGlobalUser } from "Courses/modules/utils";

class EditUserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreenLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.initInRedux(this.props.getTeacherDetails.platform);
    document.addEventListener("keydown", this.escapePress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapePress);
  }

  escapePress = e => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code == 27) {
      this.close();
    }
  };

  onContentUploaded = params => {
    this.props.onContentUploaded(params);
  };

  updateDetails = async () => {
    const totalErrors = this.editModalContent.isValid();
    const { teacherDetails, updateTeacherDetails } = this.props;
    if (totalErrors == 0) {
      this.setState({ isFullScreenLoading: true });
      const isSuccess = await updateTeacherDetails(teacherDetails);
      this.setState({ isFullScreenLoading: false });
      if (isSuccess) {
        this.close();
      }
    } else {
      return;
    }
  };

  initInRedux = teacherDetails => {
    const staff = teacherDetails.staff[0];
    const params = {
      id: staff.id,
      lastName: staff.lastName,
      profileImage: staff.profileImage,
      firstName: staff.firstName,
      email: staff.email,
      designation: _.get(staff, "designation.id", undefined),
      organization: staff.organization,
      tags: _.map(staff.entityTags, tag => ({
        value: tag.id,
        label: `${tag.key} - ${tag.value}`,
      })),
    };
    this.props.updateTeacherObject(params);
  };

  close = () => {
    this.props.closeModal();
  };

  updateRedux = param => {
    this.props.updateTeacherObject(param);
  };

  render() {
    const { isFullScreenLoading } = this.state;
    const {
      teacherDetails,
      regionTags,
      isData,
      isLoading,
      designationList,
      userTags,
    } = this.props;

    const organizationTags = _.get(teacherDetails, "organization.tags", []);

    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={this.close}
      >
        <React.Fragment>
          <div className={classes.editStep}>
            <div className={classes.modalHeader}>{"Staff Profile"}</div>

            <EditUserModalContent
              customRef={ref => (this.editModalContent = ref)}
              updateRedux={this.updateRedux}
              close={this.close}
              onContentUploaded={this.onContentUploaded}
              teacherDetails={teacherDetails}
              isData={isData}
              isLoading={isLoading}
              designationList={designationList}
              tags={regionTags}
              showTags={mapleBearLikeCentralOrgGlobalUser({
                organizationTags,
                userTags,
              })}
            />
          </div>

          <div className={classes.modalFooter}>
            <UIButton size="sm" color="grey" onClick={this.close}>
              {"Cancel"}
            </UIButton>
            <div className={classes.lastButton}>
              <UIButton color="pink" size="sm" onClick={this.updateDetails}>
                {"Update"}
              </UIButton>
            </div>
          </div>
        </React.Fragment>
        {!!isFullScreenLoading && <FullScreenLoader />}
      </UIModal>
    );
  }
}

const mapActionCreators = {
  onContentUploaded,
  updateTeacherObject,
  updateTeacherDetails,
  initTeacherObject,
};

const mapStateToProps = (state, ownProps) => {
  return {
    teacherDetails: state.tooling.teacherDetails,
    teacherId: ownProps.teacherId,
    isData: true,
    isLoading: false,
    userTags: state.login.userInfo.tags || [],
  };
};

export default compose(
  I18nHOC({ resource: ["schoolSetup", "common"] }),
  connect(mapStateToProps, mapActionCreators),

  graphql(getStaffDetailQuery, {
    name: "getTeacherDetails",
    options: ({ teacherId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { ids: teacherId },
    }),
    props: ({ getTeacherDetails, ownProps: { isData, isLoading } }) => {
      return {
        getTeacherDetails,
        isData: getTeacherDetails.platform && isData,
        isLoading:
          getTeacherDetails["networkStatus"] == 1 ||
          getTeacherDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getFilteredTags, {
    name: "getFilteredTags",
    skip: ({ teacherDetails, userTags }) => {
      const organizationTags = _.get(teacherDetails, "organization.tags", []);
      return !mapleBearLikeCentralOrgGlobalUser({ userTags, organizationTags });
    },
    options: () => ({
      fetchPolicy: "cache-and-network",
      variables: {
        keys: ["REGION"],
      },
    }),
    props({ ownProps: { keys } }) {
      const queryData = getFilteredTagsFromCache({
        keys,
      });
      const tags = _.get(queryData, "platform.allTags", []);
      return {
        regionTags: tags,
      };
    },
  }),
  withLoader
)(EditUserInfo);
