import React from "react";
import classes from "./AddBlockDialogue.scss";
import { UIModal, UIButton, I18nHOC, LinkWithTooltip } from "UIComponents";
import { Checkbox } from "@toddle-design/web";
import { CancelIcon } from "SvgComponents";
import update from "immutability-helper";
import { plannerIcons } from "UnitPlans/components/plannerIcons";
import { InformationOutlined } from "@toddle-design/web-icons";
class AddBlockDialogue extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { includedBlocks: this.props.enabledSections };
  }

  includeBlock = blocInfo => {
    const { includedBlocks } = this.state,
      elemetIndex = _.indexOf(includedBlocks, blocInfo);

    if (elemetIndex > -1) {
      this.setState({
        includedBlocks: update(includedBlocks, {
          $splice: [[elemetIndex, 1]],
        }),
      });
    } else {
      this.setState({
        includedBlocks: update(includedBlocks, { $push: [blocInfo] }),
      });
    }
  };

  updateAssessmentSections = () => {
    const { onClose, updateAssessmentSections, enabledSections } = this.props;
    updateAssessmentSections({
      addedSections: _.difference(this.state.includedBlocks, enabledSections),
    });
    onClose();
  };

  render() {
    const { t, onClose, notIncludedSteps, step_list } = this.props;
    const { includedBlocks, blocks } = this.state;
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={onClose}
      >
        <div className={classes.headerContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.headerMainTxt}>
              {t("common:add_with_label", {
                label: t("common:block_plural"),
              })}
            </div>
          </div>
          <div className={classes.cancelIcon} onClick={() => onClose()}>
            <CancelIcon width={16} heigth={16} />
          </div>
        </div>
        <div className={classes.blocksContainer}>
          {_.map(notIncludedSteps, block => {
            const { label, blockSubText, icon } = step_list[block] || {};
            return (
              <div
                key={block}
                onClick={() => this.includeBlock(block)}
                className={classes.blockItem}
              >
                <div className={classes.blockIcon}>{plannerIcons[icon]}</div>
                <div className={classes.textContainer}>
                  <div className={classes.blockHeading}>
                    {label}
                    {_.isEqual(block, "pypElements") && (
                      <div className={classes.iconContainer}>
                        <LinkWithTooltip
                          tooltip={t(
                            "unitPlanTemplate:le_pypElements_iconText"
                          )}
                          placement={"bottom"}
                        >
                          <div className={classes.informationIcon}>
                            <InformationOutlined
                              size={"xx-small"}
                              variant={"subtle"}
                            />
                          </div>
                        </LinkWithTooltip>
                      </div>
                    )}
                  </div>
                  <div className={classes.blockSubText}>{blockSubText}</div>
                </div>
                <div className={classes.checkBox}>
                  <Checkbox isChecked={_.includes(includedBlocks, block)} />
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.footer}>
          <UIButton
            size="sm"
            color="pink"
            onClick={this.updateAssessmentSections}
          >
            {t("common:add")}
          </UIButton>
        </div>
      </UIModal>
    );
  }
}

export default I18nHOC({ resource: ["common", "unitPlanTemplate"] })(
  AddBlockDialogue
);
