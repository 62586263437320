import _ from "lodash";

const getNewStudentProgressReportPath = ({
  separator,
  checkingIndex,
  pathname,
}) => {
  let newPath = "/";
  if (!_.includes(pathname, "student-view")) {
    const routes = _.split(pathname, "/");
    const subArray = _.slice(routes, _.indexOf(routes, separator) + 1);
    newPath = _.replace(
      pathname,
      subArray[checkingIndex],
      `student-view/${subArray[checkingIndex]}`
    );
  }
  return newPath;
};

export default store => ({
  path: "*",
  onEnter(nextState, replace) {
    const { location: { pathname } = {} } = nextState;
    /**
     * @description it is handled in handleQueryParameters function
     */
    if (_.includes(pathname, "/sso")) {
      return;
    }
    //Handled old routes. Will remove in the next build
    if (!_.includes(pathname, "/platform")) {
      let newPath = _.replace(pathname, "/teacher", "/home");
      replace(`/platform${newPath}`);
    } else if (_.includes(pathname, "/reporttasks")) {
      //Admin end SPR
      replace(
        getNewStudentProgressReportPath({
          pathname,
          separator: "reporttasks",
          checkingIndex: 2,
        })
      );
    } else if (_.includes(pathname, "progressreport")) {
      //PYP SPR
      replace(
        getNewStudentProgressReportPath({
          pathname,
          separator: "progressreport",
          checkingIndex: 1,
        })
      );
    } else if (_.includes(pathname, "progress-report-sets")) {
      //MYP UBD SPR
      replace(
        getNewStudentProgressReportPath({
          pathname,
          separator: "progress-report-sets",
          checkingIndex: 2,
        })
      );
    } else {
      replace(`/`);
    }
  },
});
