import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import client from "apolloClient";
import {
  createOrganizationResourcesMutation,
  deleteOrganizationResourcesMutation,
  updateOrganizationResourceMutation,
  updateAttachmentMutation,
  publishResourcesMutation,
} from "./OrganizationResourcesMutations";
import {
  getOrganizationResourcesFromCache,
  writeOrganizationResourcesInCache,
  getChildResourcesFromCache,
  writeChildResourcesInCache,
} from "./OrganizationResourcesGraphqlHelpers";
import { generateRandomId } from "Utils";
import {
  getChildResourcesFeedQuery,
  getOrganizationSyncStatusQuery,
} from "./OrganizationResourcesQuery";

export const NAME = "organizationResource";
export const UPDATE_FILTERS = "UPDATE_REPORT_FILTERS" + " " + NAME;
export const INIT_FILTERS = "INIT_REPORT_FILTERS" + " " + NAME;

export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};

export const initFilters = () => {
  return {
    type: INIT_FILTERS,
  };
};

export const updateOrganizationResource = ({
  id,
  label,
  //shareWithParent = undefined
}) => {
  return async (dispatch, getState) => {
    const { searchText, visibleTo } = getState().organizationResource.filters;

    const childResourcesFromCache = getChildResourcesFromCache({
      id: id,
      searchText,
      visibleTo,
    });

    // let resourceVisibleTo = _.get(childResourcesFromCache, "visibleTo", []);

    // if (shareWithParent) {
    //   resourceVisibleTo = [...resourceVisibleTo, "PARENT"];
    // } else {
    //   resourceVisibleTo = _.filter(resourceVisibleTo, item => item != "PARENT");
    // }
    let updatedChildResource = { ...childResourcesFromCache };
    if (label) {
      updatedChildResource = { ...updatedChildResource, label };
    }

    // if (shareWithParent != undefined) {
    //   updatedChildResource = {
    //     ...updatedChildResource,
    //     visibleTo: _.uniq(resourceVisibleTo)
    //   };
    // }

    const currentFlowSection = _.get(
      getState(),
      "unitFlow.currentSection",
      null
    );

    writeChildResourcesInCache({
      id: id,
      searchText,
      visibleTo,
      data: updatedChildResource,
    });

    const input = {
      id,
      label,
    };

    try {
      await client.mutate({
        mutation: updateOrganizationResourceMutation,
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: currentFlowSection
          ? [
              {
                query: getChildResourcesFeedQuery,
                variables: { id: currentFlowSection, searchText: "" },
              },
            ]
          : [],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:updated", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (error) {
      writeChildResourcesInCache({
        id: id,
        searchText,
        visibleTo,
        data: childResourcesFromCache,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }

      console.error(error);
    }
  };
};

export const deleteOrganizationResources = ({
  ids,
  moduleId,
  moduleType,
  organizationResourcesFeedQueryVariables,
}) => {
  return async (dispatch, getState) => {
    const {
      searchText,
      parentFolderId: parentId,
      types,
      visibleTo,
      orderBy,
      orderByDirection,
    } = getState().organizationResource.filters;

    const currentFlowSection = _.get(
      getState(),
      "unitFlow.currentSection",
      null
    );

    const organizationResourcesFromCache = getOrganizationResourcesFromCache({
      parentId: moduleId,
      parentType: moduleType,
      filters: { searchText, visibleTo, types },
      orderBy,
      orderByDirection,
      ...organizationResourcesFeedQueryVariables,
    });
    const queryInput = { visibleTo, searchText };

    const childResourcesFromCache = getChildResourcesFromCache({
      id: parentId,
      ...queryInput,
      orderBy,
      orderByDirection,
    });

    try {
      await client.mutate({
        mutation: deleteOrganizationResourcesMutation,
        variables: {
          ids: ids,
        },
        awaitRefetchQueries: true,
        refetchQueries: currentFlowSection
          ? [
              {
                query: getChildResourcesFeedQuery,
                variables: { id: currentFlowSection, searchText: "" },
              },
            ]
          : [],
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            deleteOrganizationResource: true,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { deleteOrganizationResource },
            },
          }
        ) => {
          if (deleteOrganizationResource) {
            if (!parentId) {
              const oldResources = _.get(
                organizationResourcesFromCache,
                "organizationResources",
                []
              );
              setTimeout(() => {
                writeOrganizationResourcesInCache({
                  parentId: moduleId,
                  parentType: moduleType,
                  filters: { searchText, visibleTo, types },
                  orderBy,
                  orderByDirection,
                  data: {
                    ...organizationResourcesFromCache,
                    organizationResources: _.filter(
                      oldResources,
                      item => !_.includes(ids, item.id)
                    ),
                  },
                  ...organizationResourcesFeedQueryVariables,
                });
              });
            } else {
              const oldChildResources = _.get(
                childResourcesFromCache,
                "children",
                []
              );
              setTimeout(() => {
                writeChildResourcesInCache({
                  id: parentId,
                  ...queryInput,
                  orderBy,
                  orderByDirection,
                  data: {
                    ...childResourcesFromCache,
                    children: _.filter(
                      oldChildResources,
                      item => !_.includes(ids, item.id)
                    ),
                  },
                });
              });
            }
          }
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:successfully_with_label",
          locale_params: [
            { key: "label", value: "toastMsgs:deleted", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (error) {
      console.error(error);
      if (!parentId) {
        setTimeout(() => {
          writeOrganizationResourcesInCache({
            parentId: moduleId,
            parentType: moduleType,
            filters: { searchText, visibleTo, types },
            data: organizationResourcesFromCache,
          });
        });
      } else {
        setTimeout(() => {
          writeChildResourcesInCache({
            id: parentId,
            searchText,
            visibleTo,
            types: types,
            data: childResourcesFromCache,
          });
        });
      }
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const createOrganizationResources = ({
  parentId,
  types,
  visibleTo,
  organizationResources,
  moduleId,
  moduleType,
  putAfterItemId = null,
  organizationResourcesFeedQueryVariables = {},
  tags = [],
  isCountry = false,
}) => {
  return async (dispatch, getState) => {
    const {
      searchText,
      orderBy,
      orderByDirection,
    } = getState().organizationResource.filters;

    const updatedOrganizationResources = _.map(organizationResources, item => {
      return { ...item, visibleTo: visibleTo, type: types };
    });

    try {
      return await client.mutate({
        mutation: createOrganizationResourcesMutation,
        variables: {
          parentId: moduleId,
          parentType: moduleType,
          parentResourceId: parentId,
          organizationResources: updatedOrganizationResources,
          shouldHandleSequence: true,
          putAfterItemId,
          tags,
          isCountry,
        },
        update: (
          cache,
          {
            data: {
              platform: { createOrganizationResources },
            },
          }
        ) => {
          if (!parentId) {
            const organizationResourcesFromCache = getOrganizationResourcesFromCache(
              {
                parentId: moduleId,
                parentType: moduleType,
                filters: {
                  searchText,
                  visibleTo,
                  types: types,
                },
                orderBy,
                orderByDirection,
                ...organizationResourcesFeedQueryVariables,
              }
            );
            const oldResources = _.get(
              organizationResourcesFromCache,
              "organizationResources",
              []
            );

            writeOrganizationResourcesInCache({
              parentId: moduleId,
              parentType: moduleType,
              filters: { searchText, visibleTo, types: types },
              orderBy,
              orderByDirection,
              data: {
                ...organizationResourcesFromCache,
                organizationResources: _.unionBy(
                  oldResources,
                  createOrganizationResources,
                  "id"
                ),
              },
              ...organizationResourcesFeedQueryVariables,
            });
          } else {
            const queryInput = { visibleTo, searchText };
            const childResourcesFromCache = getChildResourcesFromCache({
              id: parentId,
              ...queryInput,
              orderBy,
              orderByDirection,
            });
            const oldChildResources = _.get(
              childResourcesFromCache,
              "children",
              []
            );

            writeChildResourcesInCache({
              id: parentId,
              ...queryInput,
              orderBy,
              orderByDirection,
              data: {
                ...childResourcesFromCache,
                children: _.unionBy(
                  oldChildResources,
                  createOrganizationResources,
                  "id"
                ),
              },
              ...organizationResourcesFeedQueryVariables,
            });
          }
        },
      });
      // dispatch(
      //   setToastMsg({
      //     msg: "toastMsgs:successfully_with_label",
      //     locale_params: [
      //       { key: "label", value: "toastMsgs:added", isPlainText: false }
      //     ],
      //     type: "tick",
      //     position: "toast-bottom-left"
      //   })
      // );
    } catch (error) {
      console.error(error);
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const updateAttachment = params => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: updateAttachmentMutation,
        variables: {
          input: params,
        },
      });

      return { ...response, successful: true };
    } catch (e) {
      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const publishResources = ({ metadata, type }) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;

    try {
      const response = await client.mutate({
        mutation: publishResourcesMutation,
        variables: {
          input: {
            entityType: "RESOURCES",
            metaData: metadata,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getOrganizationSyncStatusQuery,
            variables: {
              id: organizationId,
              type,
            },
          },
        ],
      });

      /**
       * if response is true that means job is created else
       * there is a job which is already running.
       */

      const statusResponse = _.get(
        response,
        "data.integration.integrationCreateJobAndTask",
        false
      );

      if (statusResponse) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:publish_in_progress",
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      } else {
        dispatch(setToastMsg("toastMsgs:publish_in_progress_take_time"));
      }
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};
const REDUCER_HANDLERS = {
  [INIT_FILTERS]: (state, action) => {
    return update(state, {
      filters: { $set: initialState.filters },
    });
  },
  [UPDATE_FILTERS]: (state, action) => {
    const { key, value } = action.data;
    return update(state, {
      filters: { [key]: { $set: value } },
    });
  },
};

const initialState = {
  filters: {
    searchText: "",
    parentFolderId: null,
    types: "TEACHER_RESOURCE",
    visibleTo: ["TEACHER"],
    resourceTags: [],
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
