import {
  coursePostFragment,
  courseStudentFragment,
  journalPostFragment,
  studentPostFragment,
} from "./PostFragments";

import {
  getCoursePostFeedQuery,
  getPostOfStudentQuery,
  getPostDetailQuery,
  getCourseIdForPostQuery,
  getCourseFilteredStudentQuery,
  getNodePostQuery,
  getParentJournalFeedQuery,
} from "./PostQuery";

import client from "apolloClient";

export const getStudentJournalFeedFromCache = ({
  studentId,
  filters,
  uids,
  translationFilter,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getPostOfStudentQuery,
      variables: {
        studentId,
        filters,
        ...(uids && { uids }),
        translationFilter,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseJournalFeedFromCache = ({
  courseId,
  filters,
  uids,
  translationFilter,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getCoursePostFeedQuery,
      variables: {
        id: courseId,
        filters,
        ...(uids && { uids }),
        translationFilter,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeCourseJournalFeedToCache = ({
  courseId,
  filters,
  data,
  uids,
  translationFilter = {},
}) => {
  client.writeFragment({
    id: `Course:${courseId}`,
    fragment: coursePostFragment.coursePost,
    fragmentName: "courseItem",
    variables: {
      filters,
      ...(uids && { uids }),
      translationFilter,
    },
    data: data,
  });
};

export const getPostDetailFromFeedCache = ({ postId }) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `Post:${postId}`,
      fragment: journalPostFragment.journalPost,
      fragmentName: "postItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getPostDetailsFromCache = ({ postId, translationFilter = {} }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getPostDetailQuery,
      variables: {
        id: postId,
        translationFilter,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseIdForPostFromCache = ({ postId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getCourseIdForPostQuery,
      variables: {
        id: postId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writePostDetailFragment = ({
  postId,
  data,
  fragmentName = "journalPost",
}) => {
  client.writeFragment({
    id: `Post:${postId}`,
    fragment: journalPostFragment[fragmentName],
    fragmentName: "postItem",
    data: data,
  });
};

export const getCourseFilteredStudentFromCache = ({ courseId, filters }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getCourseFilteredStudentQuery,
      variables: {
        id: courseId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getNodePostFromCache = ({ parentId, parentType, filters }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getNodePostQuery,
      variables: {
        id: parentId,
        type: parentType,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getParentJournalFeedFromCache = ({ filters, parentId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getParentJournalFeedQuery,
      variables: {
        id: parentId,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
