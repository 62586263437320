import React, { useRef, useEffect } from "react";
import classes from "./CheckListItem.scss";
import { DotsHorizontalOutlined } from "@toddle-design/web-icons";
import { useI18n } from "Hooks";
import { ButtonDropdown } from "UIComponents";
import _ from "lodash";
import { Checkbox, TextAreaInput } from "@toddle-design/web";

const UnfocusedItemStyle = {
  cursor: "pointer",
  caretColor: "transparent",
  borderBottom: "0px solid transparent",
};

const FocusedItemStyle = {
  borderBottom: "1px solid #ebebeb",
  borderRadius: "0px",
};

const CheckListItem = ({
  item,
  onActionClick,
  updateItem,
  removeFocuedItem,
  isFocused,
}) => {
  const { t } = useI18n(["common"]);
  const inputRef = useRef();

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const focusedItemStyle = isFocused ? FocusedItemStyle : UnfocusedItemStyle;

  const onTextAreaClick = e => {
    if (isFocused) e.stopPropagation();
  };

  return (
    <div
      className={classes.checklistItemContainer}
      onClick={() => updateItem({ id: item.id, type: "SELECTION" })}
    >
      <div className={classes.leftContainer}>
        <Checkbox isChecked={item.isSelected} />
        <div className={"text-body-1"} onClick={e => onTextAreaClick(e)}>
          <TextAreaInput
            name={item.id}
            value={item.value}
            minRows="1"
            style={{ padding: "0px", border: "0px", ...focusedItemStyle }}
            placeholder={t("common:typeHere")}
            onBlur={removeFocuedItem}
            ref={inputRef}
            onChange={e => {
              updateItem({ id: item.id, type: "LABEL", label: e.target.value });
            }}
          />
        </div>
      </div>

      <div
        onClick={e => e.stopPropagation()}
        className={classes.rightContainer}
      >
        <ButtonDropdown
          authTabs={[
            {
              label: t("common:edit"),
              value: "EDIT",
            },
            {
              label: t("common:delete"),
              value: "DELETE",
            },
          ]}
          containerStyle={{ top: "20px" }}
          onItemClick={value => onActionClick({ value, id: item.id })}
          buttonComponent={<DotsHorizontalOutlined />}
        />
      </div>
    </div>
  );
};

export default CheckListItem;
