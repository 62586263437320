import { withAsyncRouteLoading } from "UIComponents";
import CurriculumTypeSubTabs from "./routes/CurriculumTypeSubTabs";
export default store => {
  return {
    path: ":curriculumType",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./CurriculumType").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-course"
          );
        })
    ),
    childRoutes: [CurriculumTypeSubTabs(store)],
  };
};
