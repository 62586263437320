import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import Guidance from "./routes/Guidance";
import SchoolDocuments from "./routes/SchoolDocuments";
import Dashboard from "./routes/Dashboard";

export default store => ({
  path: "cycles/:cycleId/view",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            const visitorSetupModule = require("VisitorSetup/modules/Module");
            const visitorSetupKey = visitorSetupModule.NAME;
            const visitorSetupReducer = visitorSetupModule.default;

            injectReducer(store, {
              key: visitorSetupKey,
              reducer: visitorSetupReducer,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "cycle-details"
        );
      })
  ),

  childRoutes: [Guidance(store), Dashboard(store), SchoolDocuments(store)],
});
