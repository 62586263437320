import {
  checklistItemFragment,
  organizationChecklistFragment,
  checklistFragment,
} from "./ChecklistFragments";
import { getOrganizationChecklistQuery } from "./ChecklistQuery";
import client from "apolloClient";

const checklistFragmentNameMapping = {
  CHECKLIST_ITEM: {
    typeName: "ChecklistItem",
    fragment: "checklistItem",
    fragmentName: "checklistItem",
  },
  CHECKLIST_OPTION: {
    typeName: "ChecklistOptionItem",
    fragment: "checklistOption",
    fragmentName: "checklistOption",
  },
};

export const getOrganizationChecklistsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationChecklistQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeOrganizationChecklistInCache = ({ id, data, filters }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${id}`,
      fragment: organizationChecklistFragment.checklists,
      fragmentName: "organizationItem",
      data,
      variables: { filters },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getChecklistElementFromCache = ({ id, type }) => {
  let queryData = {};
  //try {
  const typeName = checklistFragmentNameMapping[type].typeName;
  const fragment = checklistFragmentNameMapping[type].fragment;
  const fragmentName = checklistFragmentNameMapping[type].fragmentName;
  queryData = client.readFragment({
    id: `${typeName}:${id}`,
    fragment: checklistItemFragment[fragment],
    fragmentName: fragmentName,
  });
  // } catch (e) {
  //   return {};
  // }
  if (queryData != null) return queryData;
  else return {};
};

export const writeChecklistElementInCache = ({ id, data, type }) => {
  let queryData = {};
  //  try {

  const typeName = checklistFragmentNameMapping[type].typeName;
  const fragment = checklistFragmentNameMapping[type].fragment;
  const fragmentName = checklistFragmentNameMapping[type].fragmentName;
  queryData = client.writeFragment({
    id: `${typeName}:${id}`,
    fragment: checklistItemFragment[fragment],
    fragmentName: fragmentName,
    data,
  });
  // } catch (e) {
  //   return {};
  // }
  if (queryData != null) return queryData;
  else return {};
};
