/**--external-- */
import React, { useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";
import { SearchInput, EmptyState, IconButton } from "@toddle-design/web";
import { NoSearchInFilterIllustration } from "@toddle-design/theme";
import { ChevronLeftOutlined, LogoutOutlined } from "@toddle-design/web-icons";

/**--internal-- */
import { I18nHOC, PaginationLoader, Loading } from "UIComponents";
import { ToddleIcon, LogoTextSvg } from "SvgComponents";
import {
  getUserDetailsWithLinkedIdentitiesQuery,
  getUserDetailsWithLinkedIdentitiesV1Query,
} from "modules/CommonQuery";
import {
  getUserDetailsWithLinkedIdentitiesFromCache,
  getUserDetailsWithLinkedIdentitiesV1FromCache,
} from "modules/CommonGraphqlHelpers";
import {
  onSelectOrganization,
  fetchMoreIdentities,
  updateIdentityScreenFilters,
  logoutUser,
  getUseNewIdentityFlowStatus,
} from "Login/modules/LoginModule";
import { ORGANIZATION_LOGO_PLACEHOLDER_URL } from "Constants";
import { useHandleScroll } from "Utils/CustomHooks";
import { prepareLoadMoreFeed } from "Utils";
import { goToBack } from "modules/NavigationModule";

/**--relative-- */
import classes from "./OrganizationList.scss";
import OrganizationCard from "./OrganizationCard";
import {
  getDefaultDesignation,
  getUserLinkedIdentitiesDetailsFromIdentityPool,
  getMatchingIdentities,
} from "./OrganizationListUtils";
import _ from "lodash";

/**
 * This component is used at 2 places:
 * 1. After sign in -> All requests will be handled by REST Api.
 * 2. On switch-organization route(By selecting switch organization from platform switcher) -> All requests will be handled by Graphql Api.
 */
const OrganizationList = props => {
  const {
    userInfo,
    t,
    fetchMoreIdentities,
    networkStatus,
    hasNextPage,
    updateIdentityScreenFilters,
    fetchIdentityUsingRestApi,
    showQueryLoader,
    isUserLoggedIn,
    goToBack,
  } = props;

  const linkedIdentities = _.get(userInfo, "linkedIdentities", []);

  const [searchText, setSearchText] = useState("");

  const scrollContainerRef = useRef();

  const getScrollContainerRef = () => {
    return scrollContainerRef.current;
  };

  useHandleScroll({
    networkStatus,
    loadMoreFeed: fetchMoreIdentities,
    getScrollRef: getScrollContainerRef,
    hasNextPage,
  });

  const updateSearchTextInRedux = useMemo(() => {
    return _.debounce(
      searchText =>
        updateIdentityScreenFilters({
          data: { searchText },
          fetchIdentityUsingRestApi,
        }),
      300
    );
  }, []);

  const onSearchTextChange = value => {
    setSearchText(value);
    updateSearchTextInRedux(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerLeftSection}>
          {isUserLoggedIn && (
            <IconButton
              variant="plain"
              icon={<ChevronLeftOutlined />}
              size="medium"
              onClick={goToBack}
            />
          )}

          <ToddleIcon width={40} height={40} />
          <div className={classes.toddleLogoText}>
            <LogoTextSvg scale={1.1} />
          </div>
        </div>

        <IconButton
          icon={<LogoutOutlined />}
          variant="outlined"
          size="medium"
          onClick={logoutUser}
        />
      </div>

      <div className={classes.title}>{`Select a school`}</div>

      <div className={classes.searchContainer}>
        <SearchInput
          placeholder={"Search school"}
          size="large"
          value={searchText}
          onChange={onSearchTextChange}
        />
      </div>
      {showQueryLoader && (
        <div className={classes.loaderContainer}>
          <Loading />
        </div>
      )}

      <div className={classes.scrollContainer} ref={scrollContainerRef}>
        {!showQueryLoader && (
          <div className={classes.gridContainer}>
            {_.isEmpty(linkedIdentities) && (
              <div className={classes.emptyStateContainer}>
                <EmptyState
                  illustration={NoSearchInFilterIllustration}
                  title="No school found"
                />
              </div>
            )}
            {_.map(linkedIdentities, linkedIdentity => {
              const { name: organizationName, logo, id } = _.pick(
                _.get(linkedIdentity, "organization", {}),
                ["logo", "name", "id"]
              );

              const linkedIdentityTitle = _.get(
                linkedIdentity,
                "designation.title",
                ""
              );

              const onSelectOrganization = () => {
                props.onSelectOrganization(linkedIdentity);
              };

              return (
                <OrganizationCard
                  name={organizationName}
                  logo={
                    _.isEmpty(logo) ? ORGANIZATION_LOGO_PLACEHOLDER_URL : logo
                  }
                  key={id}
                  linkedIdentityTitle={linkedIdentityTitle}
                  onSelectOrganization={onSelectOrganization}
                  t={t}
                />
              );
            })}
          </div>
        )}

        {networkStatus === 3 && <PaginationLoader />}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const identityPoolDetails = _.get(state, "login.identityPoolDetails", null);

  let userInfo = {};

  let isIdentityPaginationRequestActive = false;

  let hasNextPage = false;

  let fetchIdentityUsingRestApi = false;

  let isLoading = false;

  const searchText = _.get(state, "login.identityScreenFilters.searchText", "");

  const isUserLoggedIn = _.get(state, "login.userLoggedIn", false);

  if (!_.isEmpty(identityPoolDetails)) {
    userInfo = getUserLinkedIdentitiesDetailsFromIdentityPool({
      identityPoolDetails,
    });

    hasNextPage = _.get(identityPoolDetails, "pageInfo.hasNextPage", false);

    isIdentityPaginationRequestActive = _.get(
      identityPoolDetails,
      "isFetchingMoreData",
      false
    );

    isLoading = _.get(identityPoolDetails, "isLoading", false);

    fetchIdentityUsingRestApi = true;
  }

  const userId =
    _.get(state, "login.organizationSwitcherDetails.userId", "") ||
    _.get(state, "login.userInfo.id", "");

  const userEntityType =
    _.get(state, "login.organizationSwitcherDetails.userEntityType", "") ||
    _.get(state, "login.userInfo.userEntityType", "");

  const networkStatus = isIdentityPaginationRequestActive ? 3 : 7;

  const useNewIdentityPoolFlow = getUseNewIdentityFlowStatus();

  const queryVariables = useNewIdentityPoolFlow
    ? {
        id: userId,
        type: userEntityType,
        searchText,
        first: 20,
      }
    : { id: userId, type: userEntityType };

  return {
    queryVariables,
    showQueryLoader: isLoading,
    shouldSkipQuery: !_.isEmpty(identityPoolDetails),
    userInfo,
    networkStatus,
    hasNextPage,
    fetchIdentityUsingRestApi,
    isUserLoggedIn,
    useNewIdentityPoolFlow: useNewIdentityPoolFlow,
    searchText,
  };
};

const mapActionCreators = {
  onSelectOrganization,
  fetchMoreIdentities,
  updateIdentityScreenFilters,
  goToBack,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getUserDetailsWithLinkedIdentitiesQuery, {
    name: "getUserDetailsWithLinkedIdentities",
    alias: "getUserDetailsWithLinkedIdentities",
    skip: ({ shouldSkipQuery, useNewIdentityPoolFlow }) => {
      return shouldSkipQuery || !useNewIdentityPoolFlow;
    },
    options: ({ queryVariables }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: queryVariables,
      };
    },
    props: ({
      getUserDetailsWithLinkedIdentities,
      ownProps: { queryVariables },
    }) => {
      const { networkStatus } = getUserDetailsWithLinkedIdentities;

      const data = getUserDetailsWithLinkedIdentitiesFromCache(queryVariables);

      const linkedIdentitiesEdges = _.get(
        data,
        "linkedIdentityUsersV2.edgesV2",
        []
      );

      const { endCursor, hasNextPage } = _.get(
        data,
        "linkedIdentityUsersV2.pageInfo",
        {}
      );

      const linkedIdentities = _.map(linkedIdentitiesEdges, ({ node }) => {
        const { designation, type } = node;

        const finalDesignation = !_.isEmpty(designation)
          ? designation
          : getDefaultDesignation({ userType: type });

        return {
          ...node,
          designation: finalDesignation,
        };
      });

      const isLoading = _.includes([1, 2], networkStatus);

      const showQueryLoader = isLoading && _.isEmpty(data);

      return {
        showQueryLoader,
        userInfo: {
          ..._.omit(data, ["linkedIdentityUsersV2"]),
          linkedIdentities,
        },
        hasNextPage,
        networkStatus,
        fetchMoreIdentities: prepareLoadMoreFeed({
          query: getUserDetailsWithLinkedIdentitiesQuery,
          queryData: getUserDetailsWithLinkedIdentities,
          key: "linkedIdentityUsersV2",
          variables: {
            after: endCursor,
            ...queryVariables,
          },
          edgesKey: "edgesV2",
        }),
      };
    },
  }),
  graphql(getUserDetailsWithLinkedIdentitiesV1Query, {
    name: "getUserDetailsWithLinkedIdentitiesV1",
    alias: "getUserDetailsWithLinkedIdentitiesV1",
    skip: ({ useNewIdentityPoolFlow }) => {
      return useNewIdentityPoolFlow;
    },
    options: ({ queryVariables }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: queryVariables,
      };
    },
    props: ({
      getUserDetailsWithLinkedIdentitiesV1,
      ownProps: { queryVariables, searchText },
    }) => {
      const { networkStatus } = getUserDetailsWithLinkedIdentitiesV1;

      const data = getUserDetailsWithLinkedIdentitiesV1FromCache(
        queryVariables
      );

      const linkedIdentities = _.get(data, "linkedIdentities", []);

      const matchingLinkedIdentities = getMatchingIdentities({
        linkedIdentities,
        searchText,
      });

      const isLoading = _.includes([1, 2], networkStatus);

      const showQueryLoader = isLoading && _.isEmpty(data);

      return {
        userInfo: {
          /**
           * This query is not paginated, so we are filtering query data and returning
           * matching data
           */
          ..._.omit(data, ["linkedIdentities"]),
          linkedIdentities: matchingLinkedIdentities,
        },
        showQueryLoader,
        hasNextPage: false,
        networkStatus,
        fetchMoreIdentities: _.noop,
      };
    },
  })
)(OrganizationList);
