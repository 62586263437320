import gql from "graphql-tag";
import {
  staffOnBoardingFragment,
  tagsOnBoardingFragment,
  gradeFragment,
  workshopCategoryOnBoardingFragment,
} from "./OnBoardingFragments";

export const getUserInfoQuery = gql`
  query getUser($id: ID!) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        ...staffItem
      }
    }
  }
  ${staffOnBoardingFragment.staff}
`;

export const getCommunityConstantsQuery = gql`
  query getCommunityConstants {
    community {
      constants {
        grades: globalGrades {
          ...globalGradeItem
        }
        topics: tags(types: TOPIC, isGlobalOnly: true) {
          ...tagInfo
        }
        interests: tags(types: INTEREST) {
          ...tagInfo
        }
        categories: tags(types: CATEGORY) {
          ...tagInfo
        }
        workshopCategories {
          ...workshopCategoryItem
        }
      }
    }
  }
  ${tagsOnBoardingFragment.tag}
  ${gradeFragment.globalGrade}
  ${workshopCategoryOnBoardingFragment.workshopCategory}
`;

export const getCommunityTagsQuery = gql`
  query getCommunitySkills($searchText: String, $types: [TAG_TYPE_ENUM!]) {
    community {
      constants {
        tags(types: $types, searchText: $searchText, first: 30) {
          ...tagInfo
        }
      }
    }
  }
  ${tagsOnBoardingFragment.tag}
`;

export const getGlobalSchoolsQuery = gql`
  query getGlobalSchools($searchText: String, $after: String) {
    community {
      constants {
        schools: globalSchools(
          searchText: $searchText
          first: 20
          after: $after
        ) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              name
              country
            }
          }
        }
      }
    }
  }
`;

export const getUserRoleInSchoolQuery = gql`
  query getUserRoleInSchool($id: ID!) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        interests: tags(tagTypes: INTEREST) {
          ...tagInfo
        }
      }
    }
  }
  ${tagsOnBoardingFragment.tag}
`;
