import gql from "graphql-tag";
import {
  plannerFieldFragment,
  staffDetailUnitPlanFeedFragment,
  resourceNodeFragment,
  unitPlanResourceLibraryFragment,
  plannerFieldsOfNodeFragment,
  courseDetailUnitPlanFeedFragment,
  plannerOrganizationResourceNodeFragment,
} from "./UnitPlanFragments";
import {
  unitPlanFeedFragment,
  gradeFragment,
  subjectGroupFragment,
  courseFragment,
} from "modules/CommonFragments";
import { unitPlanFragment } from "Scheduler/modules/SchedulerFragment";

export const getFlatUnitPlanFeedQuery = gql`
  query getFlatUnitPlanFeed(
    $id: ID!
    $after: String
    $filters: UnitPlansV2FeedFilters
    $showCollaborators: Boolean!
    $first: Int
    $orderBy: UnitPlanOrderByEnum
    $orderByDirection: OrderByDirectionEnum
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseFlatUnitPlanFeedItem
      }
    }
  }
  ${courseDetailUnitPlanFeedFragment.courseFlatUnitPlanFeed}
`;

export const getFlatUnitPlanTitleQuery = gql`
  query getFlatUnitPlanTitle(
    $id: ID!
    $after: String
    $filters: UnitPlansV2FeedFilters
    $orderBy: UnitPlanOrderByEnum
    $orderByDirection: OrderByDirectionEnum
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseFlatUnitPlanTitleItem
      }
    }
  }
  ${courseDetailUnitPlanFeedFragment.courseFlatUnitPlanTitle}
`;

export const getUnitPlanFeedQuery = gql`
  query getUnitPlanFeed(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UnitPlanFilter
    $groupBy: UNIT_PLAN_FEED_GROUPING_ENUM
    $showCollaborators: Boolean!
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    node(id: $id, type: $type) {
      id
      ... on Course {
        ...courseUnitPlanFeedItem
      }
      ... on Staff {
        ...staffDetailUnitPlanFeedItem
      }
    }
  }
  ${staffDetailUnitPlanFeedFragment.staffDetailUnitPlanFeed}
  ${courseDetailUnitPlanFeedFragment.courseUnitPlanFeed}
`;

export const getNodeUnitPlanFeedQuery = gql`
  query getNodeUnitPlanFeed(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UnitPlansV2FeedFilters!
    $after: String
    $first: Int
    $showCollaborators: Boolean! = false
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    node(id: $id, type: $type) {
      id
      ... on Organization {
        unitPlansV2(first: $first, after: $after, filters: $filters) {
          totalCount
          edges {
            node {
              ...unitPlanItem
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${unitPlanFeedFragment.unitPlanFeed}
`;

// export const getUnitPlanFeedCountQuery = gql`
//   query getUnitPlanFeedCount($ids: [ID!], $grades: [String!]) {
//     platform {
//       staff(ids: $ids) {
//         ...staffUnitPlanFeedCountItem
//       }
//     }
//   }
//   ${staffDetailUnitPlanFeedFragment.staffUnitPlanFeedCount}
// `;

export const getUnitPlanFeedCountQuery = gql`
  query getUnitPlanFeedCount(
    $id: ID!
    $filters: UnitPlanFilter
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      id
      ... on Course {
        ...courseUnitPlanFeedCountItem
      }
      ... on Staff {
        ...staffUnitPlanFeedCountItem
      }
    }
  }
  ${staffDetailUnitPlanFeedFragment.staffUnitPlanFeedCount}
  ${courseDetailUnitPlanFeedFragment.courseUnitPlanFeedCount}
`;

export const getPlannerResourcesOfNodeQuery = gql`
  query getPlannerResourcesOfNode($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on UnitPlan {
        ...unitPlanNodeItem
      }
      ... on Assessment {
        ...assessmentNodeItem
      }
      ... on LearningEngagement {
        ...leNodeItem
      }
    }
  }

  ${resourceNodeFragment.le}
  ${resourceNodeFragment.assessment}
  ${resourceNodeFragment.unitPlan}
`;

export const getPlannerOrganizationResourcesOfNodeQuery = gql`
  query getPlannerOrganizationResourcesOfNode(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      id
      ... on UnitPlan {
        ...unitPlanOrganizationResourceNodeItem
      }
      ... on Assessment {
        ...assessmentOrganizationResourceNodeItem
      }
    }
  }

  ${plannerOrganizationResourceNodeFragment.assessmentOrganizationResourceNode}
  ${plannerOrganizationResourceNodeFragment.unitPlanOrganizationResourceNode}
`;

export const getPlannerFieldsOfNodeQuery = gql`
  query getPlannerFieldsOfNode(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $uids: [String!]
  ) {
    node(id: $id, type: $type) {
      id
      ... on UnitPlan {
        ...unitPlanNodeItem
      }
      ... on Assessment {
        ...assessmentNodeItem
      }
      ... on LearningEngagement {
        ...leNodeItem
      }
      ... on Project {
        ...projectNodeItem
      }
    }
  }
  ${plannerFieldsOfNodeFragment.unitPlan}
  ${plannerFieldsOfNodeFragment.le}
  ${plannerFieldsOfNodeFragment.assessment}
  ${plannerFieldsOfNodeFragment.project}
`;

export const getUnitPlanResourceLibraryQuery = gql`
  query getUnitPlanResourceLibrary($id: ID!, $filters: resourceLibraryFilter) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        ...unitPlanResourceLibraryItem
      }
    }
  }
  ${unitPlanResourceLibraryFragment.unitPlanResourceLibrary}
`;

export const getUnitPlansQuery = gql`
  query getUnitPlans(
    $ids: [ID!]
    $uids: [String!]
    $filters: UnitInsightFilters
  ) {
    multiNode(ids: $ids, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        id
        title {
          ...unitplanFieldBasicDetailsItem
        }
        image {
          ...unitplanFieldBasicDetailsItem
        }
        fields(uids: $uids) {
          ...unitplanFieldBasicDetailsItem
        }
        unitInsights(filters: $filters) {
          assessments {
            edges {
              node {
                id
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${plannerFieldFragment.unitplanFieldBasicDetails}
`;

export const getAssessmentsQuery = gql`
  query getAssessments($ids: [ID!]) {
    multiNode(ids: $ids, type: ASSESSMENT) {
      ... on Assessment {
        id
        type
        title {
          ...assessmentFieldItem
        }
        image {
          ...assessmentFieldItem
        }
        assessmentType {
          ...assessmentFieldItem
        }
      }
    }
  }
  ${plannerFieldFragment.assessmentFieldItem}
`;

export const getPlannerElementNodeQuery = gql`
  query getPlannerElementNode($id: ID!) {
    node(id: $id, type: PLANNER_ELEMENT_NODE) {
      ... on PlannerElementNode {
        id
        label
        type
      }
    }
  }
`;

export const getPlannerFieldResponsesQuery = gql`
  query getPlannerFieldResponses(
    $id: ID!
    $uids: [String!]
    $type: ENTITY_TYPE_ENUM!
    $responseFilter: ResponseFilter
  ) {
    node(id: $id, type: $type) {
      id
      ... on UnitPlan {
        id
        fields(uids: $uids) {
          ...unitPlanFieldItem
        }
      }
    }
  }
  ${plannerFieldFragment.unitPlanFieldResponses}
`;

export const getNodeUnitPlanEvidenceFeedQuery = gql`
  query getNodeUnitPlanEvidenceFeed(
    $filters: UnitPlansV2FeedFilters!
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $after: String
    $first: Int
  ) {
    node(id: $id, type: $type) {
      id
      ... on Organization {
        unitPlansV2(first: $first, after: $after, filters: $filters) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              ...unitPlanItem
            }
          }
        }
      }
    }
  }
  ${unitPlanFeedFragment.unitPlanEvidenceFeed}
`;

export const getFlatUnitPlansQuery = gql`
  query getFlatUnitPlans(
    $id: ID!
    $uids: [String!]
    $first: Int
    $after: String
    $orderByDirection: OrderByDirectionEnum
    $orderBy: UnitPlanOrderByEnum
    $filters: UnitPlansV2FeedFilters!
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        id
        flatUnitPlans(
          first: $first
          after: $after
          filters: $filters
          orderByDirection: $orderByDirection
          orderBy: $orderBy
        ) {
          edges {
            node {
              id
              startDate
              endDate
              grades
              ...unitPlanItem
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${unitPlanFragment.unitPlanDetail}
`;

export const getMypRepoOrganizationDetailsQuery = gql`
  query getMypRepoOrganizationDetails($id: ID!) {
    node(id: $id, type: IB_MYP_CURRICULUM_PROGRAM) {
      id
      ... on IbMypCurriculumProgram {
        id
        repoOrganization {
          id
          subjectGroups {
            ...subjectGroupBasicDetailsItem
          }
          grades {
            ...gradeItem
          }
        }
      }
    }
  }
  ${subjectGroupFragment.subjectGroupBasicDetails}
  ${gradeFragment.basicGradeDetails}
`;

export const getCentralRepoOrgDetailsQuery = gql`
  query getCentralRepoOrgDetails($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        repoOrganization {
          subjects {
            id
            name
          }
          grades {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * not written in general fragment file because this is specific to publish unit plan only
 */
export const getStaffCoursesBasicDetailsQuery = gql`
  query getStaffCourses(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: CourseFilter
    $first: Int
    $after: String
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        coursesV2(
          orderBy: TITLE
          orderByDirection: ASC
          filters: $filters
          first: $first
          after: $after
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
          }
          edges {
            node {
              ...courseGradeItem
              tags {
                id
                key
                value
              }
            }
          }
        }
      }
    }
  }
  ${courseFragment.courserGradesDetails}
`;
