import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, UIModal, I18nHOC } from "UIComponents";

import {
  generateNestedSelectedData,
  getPYPElementSetFromNodes,
  getTopParent,
  mergeDeep,
} from "Utils";
import Groupedview from "./Groupedview";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { EmptyField } from "UIComponents";

import Levels from "MultiLevelNodeEditor/routes/Levels/components";
import classes from "./NestedSelectComponent.scss";
import _ from "lodash";
import { plannerElementsUnitPlanPropsMemoize } from "NodeEditor/Utils";

const generateNestedSelectedDataMemoize = _.memoize(
  ({ nodes, value }) => {
    return generateNestedSelectedData({
      ...getPYPElementSetFromNodes({ nodes }),
      value,
    });
  },
  params => JSON.stringify(params)
);

class NestedSelectComponent extends UIBaseComponent {
  constructor(props) {
    super(props);
    if (props.customRef) {
      props.customRef(this);
    }
    this.state = {
      ...this.state,
      openSelectionModal: false,
      parentNode: {},
      modalState: "",
    };
  }

  blur = ({ field }) => {
    const { uid, id } = field;
    const { parentNode } = this.state;
    if (parentNode.id == id) {
      this.onSelectionModalCancelClicked();
    }
  };

  onAddNewClicked = parentNode => {
    this.setState({
      openSelectionModal: !this.state.openSelectionModal,
      modalState: "add",
      parentNode: parentNode,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { onBlurInputField, onFocusInputField } = this.props;
    const { parentNode } = this.state;
    const { parentNode: prevparentNode } = prevState;
    if (prevState.openSelectionModal != this.state.openSelectionModal) {
      if (this.state.openSelectionModal) {
        // onFocusInputField({ others: { id: parentNode.id } });
      } else {
        // onBlurInputField({ others: { id: prevparentNode.id } });
      }
    }
  };

  onEditClicked = parentNode => {
    this.setState({
      openSelectionModal: !this.state.openSelectionModal,
      modalState: "edit",
      parentNode: parentNode,
    });
  };

  isValidValue = () => {
    const { nodes } = this.props;
    return !_.isEmpty(nodes);
  };

  onSubmitClicked = result => {
    const { plannerElementSetData } = this.props;
    const { parentNode } = this.state;
    const value = this.props.value ? _.cloneDeep(this.props.value) : [];
    const updatedValue = _.uniq([
      ..._.filter(
        value,
        id =>
          !_.includes(
            _.map(result.nodes, item => item.id),
            id
          )
      ),
      ...(result.selections || []),
    ]);
    const { valuesToAdd, valuesToRemove, shouldCloseModal } = result;
    if (shouldCloseModal) {
      this.setState({
        openSelectionModal: !this.state.openSelectionModal,
        parentNode: {},
      });
    }

    //TODO: Chaitu why is settimeout needed here?
    setTimeout(() => {
      this.updateValue(updatedValue, {
        nodes: _.filter(result.nodes, node => !node.isDummy),
        rootNodeId: parentNode.id,
        rootNodeKey: plannerElementSetData.type,
        valuesToAdd,
        valuesToRemove,
      });
    });
  };

  onSelectionModalCancelClicked = () => {
    this.setState({
      openSelectionModal: !this.state.openSelectionModal,
      parentNode: {},
    });
  };

  onEditClick = () => {
    const { parentNodes } = this.props;
    this.onEditClicked(_.first(parentNodes));
  };

  renderOptions = () => {
    const {
      props: {
        parentNodes,
        value,
        nodes,
        fieldLockedObject,
        plannerLabel = "",
        emptyTextSelectSubgroupLocale,
        plannerElementSetData,
        showEditButtonAtHeader,
        groupedViewConfig,
        isLocked,
      },
    } = this;
    const { lockedDynamicFields } = fieldLockedObject;

    const nestedValue = generateNestedSelectedDataMemoize({
      value,
      nodes,
    });

    return (
      <div className={classes.subjectBenchMarkContainer}>
        {_.map(parentNodes, (parentNode, index) => {
          let nestedGroupedValue = nestedValue;

          //Handled for Objectives and ATLS. Not handled for Interdisciplinary Criteria
          if (!_.isNull(plannerElementSetData.groupedByType)) {
            //Handled for Objectives in which they are groupedBy subject groups
            if (
              plannerElementSetData.type != plannerElementSetData.groupedByType
            ) {
              nestedGroupedValue = _.filter(nestedValue, node =>
                _.find(node.associatedParents, { id: parentNode.id })
              );
            }
            //Handled for ATLS in which they are groupedBy ATL root nodes
            else if (!parentNode.isDummy) {
              nestedGroupedValue = _.get(
                _.find(nestedValue, {
                  id: parentNode.id,
                }),
                "children",
                []
              );
            }
          }

          const lockedField = _.find(lockedDynamicFields, {
            id: parentNode.id,
          });

          let rootNodeDepth = _.get(
            _.first(_.orderBy(nodes, "depth")),
            "depth",
            1
          );
          if (!_.isNil(_.get(parentNode, "depth", null))) {
            rootNodeDepth = _.get(parentNode, "depth", 0) + 1;
          }

          return (
            <Groupedview
              key={parentNode.id}
              parentNode={parentNode}
              emptyTextSelectSubgroupLocale={emptyTextSelectSubgroupLocale}
              plannerLabel={plannerLabel}
              nestedGroupedValue={nestedGroupedValue}
              onAddNewClicked={this.onAddNewClicked}
              lockedField={lockedField}
              isLocked={isLocked}
              onAddClicked={this.onEditClicked}
              showEditButtonAtHeader={showEditButtonAtHeader}
              rootNodeDepth={rootNodeDepth}
              {...groupedViewConfig}
            />
          );
        })}
      </div>
    );
  };

  renderModal = () => {
    const {
      state: { parentNode: parentNode, openSelectionModal },
      props: {
        value,
        nodes,
        plannerElementSetData,
        t,
        label,
        emptyTextAddSubgroupLocale,
        multiSelectConfig,
        nodeRenderType,
        curriculumType,
        dummyRootNode,
      },
    } = this;
    let filteredNodes = [];
    let filteredValue = value;
    let filters = {};
    if (!_.isNull(plannerElementSetData.groupedByType)) {
      //Handled for Objectives in which they are groupedBy subject groups
      if (plannerElementSetData.type != plannerElementSetData.groupedByType) {
        filteredNodes = _.filter(
          nodes,
          nodeItem =>
            !!_.find(nodeItem.associatedParents, { id: parentNode.id })
        );

        filteredValue = _.filter(
          value,
          val => !!_.find(filteredNodes, { id: val })
        );
        filters = {
          associatedParents: [
            { ids: [parentNode.id], type: plannerElementSetData.groupedByType },
          ],
        };
        if (parentNode.selectedObjectiveIds) {
          filters = {
            ...filters,
            parentNodes: parentNode.selectedObjectiveIds,
          };
        }
      }
      //Handled for ATLS in which they are groupedBy ATL root nodes
      else if (!parentNode.isDummy) {
        filteredValue = _.filter(value, val => {
          const topParent = getTopParent({ nodeId: val, nodes });
          return topParent == parentNode.id;
        });

        filters = {
          parentNodes: [parentNode.id],
        };
      }
    }
    //Handled for Interdisciplinary Criteria in which they are groupedBy ATL root nodes
    if (plannerElementSetData.subType) {
      filters = { ...filters, subTypes: [plannerElementSetData.subType] };
    }

    //Merge filter with prop filters
    if (!_.isNil(plannerElementSetData.filters)) {
      filters = mergeDeep(plannerElementSetData.filters, filters);
    }

    const rootNode = {
      ...parentNode,
      subject: parentNode.isDummy ? null : parentNode.id,
    };

    const newNodeEditorProps = plannerElementsUnitPlanPropsMemoize({
      filters,
      nodes: _.filter(nodes, node => _.includes(filteredValue, node.id)),
      parentNode,
      curriculumType,
      plannerElementType: plannerElementSetData.type,
    });

    return (
      openSelectionModal && (
        <UIModal
          isOpen={true}
          overlayContainer={classes.overlayContainer}
          isFullScreen={true}
        >
          <Levels
            label={label}
            updateValueLocally={this.updateValueLocally}
            value={filteredValue}
            emptyText={t(emptyTextAddSubgroupLocale, {
              label: parentNode.label,
            })}
            isTagging={true}
            rootNode={rootNode}
            mode={"view"}
            nodeType={plannerElementSetData.type}
            nodeParentType={plannerElementSetData.parentType}
            plannerElementSetConfig={{ ...plannerElementSetData, filters }}
            onBackClick={this.onSelectionModalCancelClicked}
            onSubmitClick={this.onSubmitClicked}
            showSearchHeader={false}
            multiSelectConfig={multiSelectConfig}
            nodeRenderType={nodeRenderType}
            // following props required for new SNS editor
            {...newNodeEditorProps}
            dummyRootNode={dummyRootNode}
          />
        </UIModal>
      )
    );
  };

  shouldShowEditEmpty = () => {
    const {
      props: { parentNodes },
    } = this;

    return _.isEmpty(parentNodes);
  };

  renderEditEmpty = () => {
    const { t, emptyTextLocale, plannerLabel } = this.props;
    return <EmptyField title={(t(emptyTextLocale), { plannerLabel })} />;
  };

  renderEdit = () => {
    return (
      <div className={classes.container}>
        {this.renderOptions()}
        {this.renderModal()}
      </div>
    );
  };
}

const mapActionCreators = {};

/*

    plannerElementSetData:{
      type: oneOf(['MYP_ATL', 'MYP_OBJECTIVES','MYP_LEARNING_STANDARD']),
      parentType: oneOf(['PLANNER_ELEMENT','SUBJECT_GROUP','SUBJECT']),
      groupedByType: oneOf(['MYP_ATL', 'SUBJECT_GROUP','SUBJECT'])
    }

    //Those nodes on which you want to group
    parentNodes :[{id:"1",label:"Mathematics"},{id:"2",label:"Science"}]


  */
const mapStateToProps = (state, ownProps) => {
  return {
    nodes: _.get(ownProps.resolvedValue, "nodes", []),
    parentNodes: _.get(ownProps, "options.parentNodes", []) || [],
    dummyRootNode: _.get(ownProps, "options.dummyRootNode", []) || [],
    plannerElementSetData:
      _.get(ownProps, "options.plannerElementSetData", []) || {},
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "unitPlan" })
)(NestedSelectComponent);

//Need Review @kunal
