import {
  studentAssessmentsFragment,
  courseStudentFragment,
  studentPypEvaluationFragment,
  studentPostFragment,
  studentGoalFragments,
} from "./StudentPortfolioFragment";

import gql from "graphql-tag";

export const getStudentAssessmentFeedQuery = gql`
  query getStudentAssessmentsFeed($studentId: ID!) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentAssessmentsItem
      }
    }
  }
  ${studentAssessmentsFragment.studentAssessmentsFeed}
`;

export const getCourseAllStudentQuery = gql`
  query getCourseAllStudent($id: ID!, $filters: CourseFilter, $after: String) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseStudentFragment.courseStudent}
`;

export const getCourseStudentPortfolioQuery = gql`
  query getCourseStudentPortfolio(
    $id: ID!
    $filters: CourseFilter
    $after: String
    $postFilters: PostFilterInput
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseStudentFragment.courseStudentPortfolio}
`;

export const getCourseStudentListWithInviteCodesQuery = gql`
  query getCourseStudentPortfolio(
    $id: ID!
    $filters: CourseFilter
    $after: String
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseStudentFragment.courseStudentWithInviteCode}
`;

export const getStudentPypElementRatingsQuery = gql`
  query getStudentPypElementRatings(
    $studentId: ID!
    $elementTypes: [IB_PYP_ELEMENT_RATING_TYPE_ENUM!]
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentPypEvaluationItem
      }
    }
  }
  ${studentPypEvaluationFragment.studentPypElementRatings}
`;

export const getStudentPypElementRemarksQuery = gql`
  query getStudentPypElementRemarks(
    $studentId: ID!
    $elementTypes: [IB_PYP_ELEMENT_RATING_TYPE_ENUM!]
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentPypEvaluationItem
      }
    }
  }
  ${studentPypEvaluationFragment.studentPypElementRemarks}
`;

export const getStudentPypTaggedPostQuery = gql`
  query getStudentPypTaggedPost(
    $studentId: ID!
    $fields: [ID!]
    $searchText: String
    $pypElements: [PostPypElementFilter]
    $unitPlanIds: [ID!]
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentPostItem
      }
    }
  }
  ${studentPostFragment.studentPypTaggedPost}
`;

export const getStudentGoalsQuery = gql`
  query getStudentGoals($studentId: ID!, $last: Int, $after: String) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentGoalsItem
      }
    }
  }
  ${studentGoalFragments.goalFeed}
`;
