import gql from "graphql-tag";
import { staffFragment } from "modules/CommonFragments";

export const singlePointRubricElementsFragment = {
  singlePointRubricStandard: gql`
    fragment singlePointRubricStandardItem on SinglePointRubricStandard {
      id
      title
      subTitle
    }
  `,
  singlePointRubricCriteria: gql`
    fragment singlePointRubricCriteriaItem on SinglePointRubricCriteria {
      id
      title
      subTitle
      isCriteriaStandardColumn
    }
  `,
};

export const singlePointRubricFragment = {
  singlePointRubric: gql`
    fragment singlePointRubricItem on SinglePointRubric {
      id
      title
      criterias {
        ...singlePointRubricCriteriaItem
      }
      standards {
        ...singlePointRubricStandardItem
      }
      createdBy {
        ...staffItem
      }
      updatedAt
    }
    ${singlePointRubricElementsFragment.singlePointRubricCriteria}
    ${singlePointRubricElementsFragment.singlePointRubricStandard}
    ${staffFragment.basicStaffDetails}
  `,
};

export const organizationFragment = {
  singlePointRubricList: gql`
    fragment organizationItem on Organization {
      id
      singlePointRubrics(filters: $filters) {
        ...singlePointRubricItem
      }
    }
    ${singlePointRubricFragment.singlePointRubric}
  `,
};
