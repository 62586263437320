import React from "react";
import classNames from "classnames";
import classes from "./Card.scss";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
import { getTimeSince } from "Utils";
import {
  ResourceImagePlaceHolder,
  I18nHOC,
  MoreItemWithToolTip,
} from "UIComponents";
import { getRethumbUrl, localeParamsHelper } from "Utils";
import { LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";

const getRethumb = ({ imageUrl, imageThumbSize }) => {
  const rethumpUrl = imageUrl
    ? getRethumbUrl({
        width: imageThumbSize,
        height: imageThumbSize,
        imageUrl: imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${rethumpUrl})`;
};

const getSpecifiedIcon = ({ notificationDetails, metadata, type }) => {
  const {
    postType,
    contentType,
    contentSubType,
    primaryResourceType,
    projectGroupType = "",
  } = metadata || {};
  const subCategories = _.get(notificationDetails, "subCategories");

  let key;
  if (type === "POST") {
    key = postType;
  } else if (
    type === "ASSIGNMENT_CREATE" ||
    type === "ASSIGNMENT_SHARED_WITH_STUDENT"
  ) {
    if (contentType == "ASSESSMENT" && contentSubType) {
      key = contentSubType;
    } else {
      key = contentType;
    }
  } else if (type === "MULTIPLE_RESOURCE_SHARED_WITH_STUDENT") {
    key = primaryResourceType === "FILE" ? "FILE" : "FOLDER";
  } else if (!_.isEmpty(projectGroupType)) {
    key = projectGroupType;
  }
  return _.get(subCategories, key, {});
};

const ImagePlaceHolder = ({
  imageUrl,
  imageThumbSize,
  icon,
  bgColor,
  containerStyle,
  // userName
}) => {
  const rethumpUrl = imageUrl
    ? getRethumb({
        imageUrl: imageUrl,
        imageThumbSize,
      })
    : null;

  return (
    <div
      className={classes.imageContainer}
      style={{
        ...containerStyle,
        backgroundImage: rethumpUrl,
        backgroundColor: bgColor,
      }}
    >
      {/* {!imageUrl && _.first(userName) && (
        <span className={classes.initials}>{_.first(userName)}</span>
      )} */}
      {!!icon &&
        (rethumpUrl ? <div className={classes.overlay}>{icon}</div> : icon)}
      {/* <span className={classes.iconWrapper}>{icon} </span> */}
    </div>
  );
};

class Card extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  renderNotifications = ({
    message,
    tokens,
    localizationKey,
    type,
    metadata,
  }) => {
    const { t } = this.props;
    if (!localizationKey) {
      return message;
    }

    switch (type) {
      case "CIRCULAR_ADDED": {
        return message;
      }
      case "SNP_CHAT": {
        const { cycleName, cycleType = "EVALUATION" } = metadata;

        const localeParam = localeParamsHelper({ params: tokens, t });

        return t(`notifications:${localizationKey}`, {
          ...localeParam,
          cycleName,
          cycleType,
        });
      }
      default: {
        const localeParam = localeParamsHelper({ params: tokens, t });
        return t(`notifications:${localizationKey}`, localeParam);
      }
    }
  };

  renderNotificationTitle = ({ title, localizationKey, tokens, type }) => {
    const { t } = this.props;

    if (localizationKey && type != "CIRCULAR_ADDED") {
      const localeParam = localeParamsHelper({ params: tokens, t });
      return t(localizationKey, localeParam);
    }

    return title;
  };

  render() {
    const {
      props: { item, onNotificationClicked, notificationDetails, t, user_type },
    } = this;
    const regex = /"([^"]+)"/g;

    const {
      type,
      isRead,
      message,
      createdAt,
      icons,
      course,
      localizationKey,
      tokens,
      metadata,
      createdBy,
      courses,
      category,
    } = item;

    const coursesTitles = _.map(courses, item => {
      return `${_.get(item, "title", "")}`;
    });

    const containerClass = classNames(
      { [classes.mainContainer]: true },
      { [isRead ? classes.isRead : classes.notRead]: true }
    );

    const { label, useDefaultImage, studentLabel } = notificationDetails || {};

    const sliceIcons = _.slice(icons, 0, 3);
    const iconsLength = _.get(sliceIcons, "length", 0);
    const { title, titleLocalizationKey } = metadata || {};
    const { firstName, lastName } = createdBy || {};

    const { svgIcon, bgColor } = _.get(notificationDetails, "subCategories")
      ? getSpecifiedIcon({ metadata, notificationDetails, type })
      : notificationDetails;

    const iconContainerClass = classNames(
      { [classes.iconContainer]: true },
      { [classes.communityIconContainer]: category == "COMMUNITY" }
    );

    const contentContainerClass = classNames(
      { [classes.contentContainer]: true },
      { [classes.communityContentContainer]: category == "COMMUNITY" }
    );

    const labelLocalizationKey = titleLocalizationKey
      ? `notifications:${titleLocalizationKey}`
      : undefined;

    let newLabel = labelLocalizationKey || label;

    if (user_type == "student") {
      newLabel = labelLocalizationKey || studentLabel || label;
    }

    if (user_type === "parent" && type === "CHAT_MESSAGE_SENT") {
      newLabel = "notifications:teacher_message_sent";
    }

    const defaultImage = useDefaultImage ? RESOURCE_PLACEHOLDER_URL : "";

    return (
      <div
        className={containerClass}
        onClick={e => onNotificationClicked(item)}
      >
        <div className={contentContainerClass}>
          <div
            className={iconContainerClass}
            style={
              iconsLength > 1
                ? {
                    transform: `translate(${-(iconsLength - 1) * 8}px, 0px)`,
                  }
                : {}
            }
          >
            {!isRead && <div className={classes.readDot}></div>}
            {iconsLength > 0 ? (
              _.map(sliceIcons, (icon, index) => {
                return (
                  <ImagePlaceHolder
                    key={index}
                    containerStyle={
                      iconsLength > 1
                        ? {
                            border: "1px solid white",
                            transform: `translate(${index * 8}px, 0px) scale(${
                              1 - iconsLength * 0.1 + index * 0.1
                            })`,
                          }
                        : {}
                    }
                    imageUrl={_.get(icon, "url", defaultImage)}
                    // userName={_.get(icon, "userName")}
                    icon={svgIcon}
                    imageThumbSize={128}
                    bgColor={bgColor}
                  />
                );
              })
            ) : (
              <ImagePlaceHolder
                imageThumbSize={128}
                icon={svgIcon}
                bgColor={bgColor}
                imageUrl={defaultImage}
              />
            )}
          </div>

          <div className={classes.contentTextContainer}>
            {category != "COMMUNITY" && (
              <div className={classes.contentHeaderContainer}>
                <div className={classes.contentHeader}>
                  {this.renderNotificationTitle({
                    title,
                    localizationKey: newLabel,
                    tokens,
                    type,
                  })}
                </div>
                <div className={classes.duration}>
                  {getTimeSince(createdAt, t)}
                </div>
              </div>
            )}

            <div
              dangerouslySetInnerHTML={{
                __html: this.renderNotifications({
                  message,
                  tokens,
                  localizationKey,
                  type,
                  metadata,
                }),
              }}
              className={classes.content}
            />
            {category != "COMMUNITY" ? (
              <div className={classes.detailContainer}>
                {type == "CIRCULAR_ADDED" && (
                  <span>{`${firstName} ${lastName}`}</span>
                )}
                {type == "CIRCULAR_ADDED" &&
                  _.get(coursesTitles, "length", 0) > 0 && (
                    <span className={classes.dotContainer}>{`•`}</span>
                  )}
                {_.get(coursesTitles, "length", 0) > 0 && (
                  <MoreItemWithToolTip
                    items={coursesTitles}
                    tooltipPlacement={"bottom"}
                    length={1}
                    allItemsText={t("common:all_label", {
                      label: t("common:course_plural"),
                    })}
                  />
                )}
              </div>
            ) : (
              <div
                className={classes.duration}
                style={category == "COMMUNITY" ? { marginTop: 0 } : {}}
              >
                {getTimeSince(createdAt, t)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({
  resource: [
    "common",
    "project",
    "notifications",
    "journal",
    "assessmentEvaluation",
    "classRoom",
  ],
})(Card);
