import React, { useRef } from "react";
import classes from "./UnitCard.scss";
import { getRethumbUrl } from "Utils";
import { Tick } from "SvgComponents";
import { LinkWithTooltip } from "UIComponents";
import classNames from "classnames";
import { getDefaultUnitPlanImage } from "UnitPlans/modules/Utils";
import { colors } from "Constants";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const getRethumb = ({ cardDimensions }) => {
  return imageUrl => {
    const rethumpUrl = imageUrl
      ? getRethumbUrl({
          width: cardDimensions.width,
          height: cardDimensions.height,
          imageUrl: imageUrl,
          fitIn: false,
        })
      : "";
    return `url(${rethumpUrl})`;
  };
};

const UnitPlanDetailText = ({ detailsString, theme }) => {
  // const learningExpString = getCountString({
  //   count: _.filter(resourceLibrary, item => item.type == "LEARNING_ENGAGEMENT")
  //     .length,
  //   singularText: "Learning Experience",
  //   pluralText: "Learning Experiences",
  //   shouldShowZero: true
  // });
  // const assessmentString = getCountString({
  //   count: _.filter(resourceLibrary, item => item.type == "ASSESSMENT").length,
  //   singularText: "Assessment",
  //   pluralText: "Assessments",
  //   shouldShowZero: true
  // });

  // const planDetailStrings = [learningExpString, assessmentString];
  return (
    <div
      className={classes.planDetailTextContainer}
      style={theme.planDetailTextContainer}
    >
      <div className={classes.planDetailText}>{detailsString}</div>
      {/* {_.map(planDetailStrings, (item, index) => {
        return [
          <div className={classes.planDetailText} key={`Text${index}`}>
            {item}
          </div>,
          index < planDetailStrings.length - 1 && (
            <div className={classes.dot} key={`Dot${index}`} />
          )
        ];
      })} */}
    </div>
  );
};

const UnitCard = ({
  item,
  onItemClick,
  theme = {},
  emptySubTitle,
  emptyTitle,
  isSelected,
  isDisabled,
  detailsString,
  cardDimensions,
  showGrades,
  showSubTitleTooltip,
  hideDefaultActions,
  t,
}) => {
  const titleRef = useRef();

  let displayTitle = !!item.title ? item.title : emptyTitle;
  let displaySubTitle = !!item.subTitle ? item.subTitle : emptySubTitle;
  const displayImage = _.get(item, "image", "");
  const id = _.get(item, "id");

  if (showGrades) {
    const gradeName = _.get(item, "grade", "");
    displaySubTitle = displaySubTitle
      ? `${gradeName} • ${displaySubTitle}`
      : gradeName;
  }

  const onUnitPlanClick = () => {
    onItemClick(item);
  };

  const containerStyle = classNames(
    { [classes.container]: true },
    { [classes.containerSelected]: isSelected },
    { [classes.containerDisable]: isDisabled }
  );

  const subTitleElement = (
    <div className={classes.subtitleContainer} style={theme.subtitleContainer}>
      {displaySubTitle}
    </div>
  );

  const scrollWidth = titleRef.current?.scrollWidth;
  const clientWidth = titleRef?.current?.clientWidth;

  // if scrollWidth > clientWidth, it means that text is truncated
  const showTooltip = scrollWidth > clientWidth;

  return (
    <div
      className={containerStyle}
      style={theme.container}
      onClick={onUnitPlanClick}
    >
      {isSelected && !hideDefaultActions ? (
        <div className={classes.tickIcon}>
          <Tick fill={colors.blue29} />
        </div>
      ) : null}
      <div
        className={classes.imageContainer}
        style={{
          backgroundImage: getDefaultUnitPlanImage({
            id,
            imageUrl: displayImage,
            getRethumb: getRethumb({ cardDimensions }),
          }),
          ...theme.imageContainer,
        }}
      />
      <div className={classes.textContainer}>
        <ConditionalWrapper
          condition={showTooltip}
          wrapper={
            <LinkWithTooltip tooltip={displayTitle} mouseEnterDelay={0.5} />
          }
          type={"wrapperAsElement"}
        >
          <div
            style={theme.titleContainer}
            className={classes.titleContainer}
            ref={titleRef}
          >
            {displayTitle}
          </div>
        </ConditionalWrapper>
        {showSubTitleTooltip ? (
          <LinkWithTooltip
            tooltip={displaySubTitle}
            placement={"bottom"}
            href={null}
          >
            {subTitleElement}
          </LinkWithTooltip>
        ) : (
          subTitleElement
        )}

        <UnitPlanDetailText detailsString={detailsString} theme={theme} />
      </div>
    </div>
  );
};

export default UnitCard;

UnitCard.defaultProps = {
  theme: {
    container: {},
    planDetailTextContainer: {},
    subtitleContainer: {},
    imageContainer: {},
  },
  isViewable: false,
  showGrades: false,
  showSubTitleTooltip: false,
  isSelected: false,
  cardDimensions: {
    width: 290,
    height: 144,
  },
  emptySubTitle: "",
};
