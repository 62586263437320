import React, { PureComponent } from "react";

import classes from "./SubjectBenchmarks.scss";

import { UIButton, I18nHOC } from "UIComponents";

import { LockOverlay, BenchmarkView } from "UIComponents";
import { getElementValue } from "modules/Services";

const getElementValueMemoize = _.memoize(getElementValue, params =>
  JSON.stringify(params)
);
class SubjectBenchmarks extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      props: {
        lockedField,
        subject,
        isEmpty,
        getEmptyComponent,
        onAddClicked,
        hasData,
        value,
        nodes,
        pypType,
        rootNodes,
        t,
        readOnly,
      },
    } = this;

    const elementValue = getElementValueMemoize({
      valueKeys: value,
      fieldUID: "benchmarks",
      benchmarkSet: { nodes, rootNodes },
    });

    return (
      <div className={classes.mainContainer}>
        <div className={classes.headerContainer}>
          <div className={"text-label-2"}>{subject.label}</div>
          {!isEmpty && (
            <div
              onClick={() => onAddClicked(subject)}
              className={classes.addButton}
            >
              <UIButton
                size="sm"
                type="hollow"
                color="tertiary"
                isDisabled={!!lockedField}
              >
                {t("edit")}
              </UIButton>
            </div>
          )}
        </div>
        <div className={classes.mainContentContainer}>
          {!isEmpty ? (
            <div className={classes.innerContainer}>
              <BenchmarkView
                value={elementValue}
                startDepth={pypType == "benchmarks" ? 1 : 2}
                mode={"view"}
              ></BenchmarkView>
            </div>
          ) : (
            getEmptyComponent({ subject, hasData })
          )}
          {lockedField && (
            <LockOverlay userInfo={_.get(lockedField, "userInfo", {})} />
          )}
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(SubjectBenchmarks);
