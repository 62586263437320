export const getAnchorElement = (editor, selectedElement) => {
  selectedElement = selectedElement || editor.selection.getNode();
  return editor.dom.getParent(selectedElement, "a[href]");
};

export const createLink = ({ editor, linkAttr, title }) => {
  editor.execCommand(
    "mceInsertContent",
    false,
    editor.dom.createHTML("a", linkAttr, title)
  );
};

export const updateLink = ({ editor, anchorElement, linkAttr, title }) => {
  if (title) {
    if (anchorElement.hasOwnProperty("innerText")) {
      anchorElement.innerText = title;
    } else {
      anchorElement.textContent = title;
    }
  }
  editor.dom.setAttribs(anchorElement, linkAttr);
  editor.selection.select(anchorElement);
};

export const isAnchorTag = ({ node }) => {
  if (!node) {
    return false;
  }
  return node.nodeName.toLowerCase() === "a";
};

export const getAttribute = ({
  editor,
  element,
  attributeName,
  defaultValue,
}) => {
  return editor.dom.getAttrib(element, attributeName, defaultValue);
};
