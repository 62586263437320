import React from "react";

const AicnPOI = props => {
  return (
    <svg width="63px" height="63px" viewBox="0 0 63 63">
      <g
        id="Subjects"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-31" transform="translate(-18.000000, -19.000000)">
          <rect
            id="Rectangle"
            fillRule="nonzero"
            x="0"
            y="0"
            width="100"
            height="100"
          />
          <g
            id="Programme-of-Inquiry"
            transform="translate(19.000000, 19.000000)"
          >
            <g
              id="Group-9"
              transform="translate(15.000000, 48.600000)"
              stroke="#F75961"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
            >
              <polyline id="Path" points="0.8 13 3.9 0.5 23.4 0.5 26.5 13" />
              <path d="M25,6.8 L2.3,6.8" id="Path" />
            </g>
            <path
              d="M51,17.3 L4.3,17.3 L4.3,46.5 C4.3,47.9238576 5.37614237,49 6.8,49 L48.5,49 C49.8612813,49 51,47.8871613 51,46.5 L51,17.3 Z"
              id="Path"
              stroke="#F75961"
            />
            <path
              d="M54.9,17.3 L2.6,17.3 C1.3,17.3 0.3,16.3 0.3,15 L0.3,15 C0.3,13.7 1.3,12.7 2.6,12.7 L54.9,12.7 L54.9,17.3 L54.9,17.3 Z"
              id="Path"
              stroke="#F75961"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.1,23.9 C42.7,23.9 37.4,18.7 37.4,12.2 C37.4,5.8 42.6,0.5 49.1,0.5 C55.6,0.5 60.8,5.7 60.8,12.2 C60.8,18.6 55.5,23.9 49.1,23.9 Z"
              id="Path"
              stroke="#F75961"
              fill="#F9F9FB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g
              id="Group-5"
              transform="translate(48.000000, 7.000000)"
              fill="#F75961"
            >
              <g id="Group-6" transform="translate(0.500000, 0.000000)">
                <g id="Group-8">
                  <rect
                    id="Rectangle"
                    x="0.15"
                    y="3"
                    width="1.5"
                    height="7"
                    rx="0.75"
                  />
                  <circle id="Oval" cx="0.9" cy="0.9" r="1" />
                </g>
              </g>
            </g>
            <g
              id="Group"
              transform="translate(10.000000, 24.000000)"
              fill="#F75961"
              fillRule="nonzero"
            >
              <path
                d="M0.9,2 C0.56862915,2 0.3,1.70898509 0.3,1.35 C0.3,0.991014913 0.56862915,0.7 0.9,0.7 L15.3,0.7 C15.6313708,0.7 15.9,0.991014913 15.9,1.35 C15.9,1.70898509 15.6313708,2 15.3,2 L0.9,2 Z"
                id="Path"
              />
              <path
                d="M0.9,6.8 C0.56862915,6.8 0.3,6.50898509 0.3,6.15 C0.3,5.79101491 0.56862915,5.5 0.9,5.5 L15.3,5.5 C15.6313708,5.5 15.9,5.79101491 15.9,6.15 C15.9,6.50898509 15.6313708,6.8 15.3,6.8 L0.9,6.8 Z"
                id="Path"
              />
              <path
                d="M0.9,11.55 C0.541014913,11.55 0.25,11.2589851 0.25,10.9 C0.25,10.5410149 0.541014913,10.25 0.9,10.25 L15.3,10.25 C15.6589851,10.25 15.95,10.5410149 15.95,10.9 C15.95,11.2589851 15.6589851,11.55 15.3,11.55 L0.9,11.55 Z"
                id="Path"
              />
              <path
                d="M0.9,16.3 C0.56862915,16.3 0.3,16.0089851 0.3,15.65 C0.3,15.2910149 0.56862915,15 0.9,15 L15.3,15 C15.6313708,15 15.9,15.2910149 15.9,15.65 C15.9,16.0089851 15.6313708,16.3 15.3,16.3 L0.9,16.3 Z"
                id="Path"
              />
            </g>
            <rect
              id="Rectangle"
              stroke="#F75961"
              strokeWidth="1.2"
              x="30.6"
              y="29.8"
              width="13.1"
              height="9.2"
              rx="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AicnPOI;
