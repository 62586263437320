import React from "react";
import { UIModal, I18nHOC, UIButton } from "UIComponents";
import classes from "./SubjectEditModal.scss";
import SubjectGroupSelect from "../SubjectGroupSelect";
import { colors } from "Constants";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";
import { compose } from "react-apollo";

const SubjectEditModal = React.memo(props => {
  const { value, options, t, onCancelClick, onSaveClick, setToastMsg } = props;
  let subjectGroupRef = null;

  const onClickSaveLocally = () => {
    const totalCounts = subjectGroupRef.isValid();
    if (totalCounts === 0) {
      onSaveClick({
        subjectGroupPair: _.get(subjectGroupRef, "state.subjectGroupPair", []),
      });
      onCancelClick();
    } else {
      setToastMsg({
        msg: `toastMsgs:rectify_errors_with_count`,
        locale_params: [
          { key: "count", value: totalCounts, isPlainText: true },
        ],
        position: "toast-top-center",
        type: "alert",
        fill: colors.yellow50,
      });
    }
  };

  return (
    <UIModal isOpen={true}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.innerScrollContainer}>
            <div className={classes.header}>{`Edit Subjects`}</div>
            <SubjectGroupSelect
              value={value}
              options={options}
              ref={ref => {
                subjectGroupRef = ref;
              }}
              isSubjectEditModal={true}
            />
          </div>
        </div>
        <div className={classes.footer}>
          <UIButton onClick={onCancelClick} size={"sm"}>
            {t("common:cancel")}
          </UIButton>
          <UIButton
            onClick={onClickSaveLocally}
            containerStyle={{ marginLeft: 16 }}
            color="pink"
            size={"sm"}
          >
            {t("common:save")}
          </UIButton>
        </div>
      </div>
    </UIModal>
  );
});
SubjectEditModal.displayName = "SubjectEditModal";
const mapActionCreators = {
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(null, mapActionCreators)
)(SubjectEditModal);
