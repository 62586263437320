import React from "react";
import { getRethumbUrl } from "Utils";
import classes from "./ValueComponentWithIcon.scss";

const styles = {
  valueComponentContainerStyle: { display: "flex", alignItems: "center" },
};

const getRethumb = ({ imageUrl, size = 24 }) => {
  const thumbnailUrl = imageUrl
    ? getRethumbUrl({
        width: size,
        height: size,
        imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${thumbnailUrl})`;
};

export default function ValueComponentWithIcon({ value }) {
  const {
    label,
    icon,
    iconType,
    valueIconSize,
    valueIconStyle = {},
    labelStyle = {},
  } = value;

  // Hack: Keep the class name as it is to have non-breaking
  // style as it relates to react-select value component's class.
  return (
    <div className={"Select-value"}>
      <div
        style={styles.valueComponentContainerStyle}
        className={"Select-value-label"}
      >
        {_.isEqual(iconType, "COMPONENT") ? (
          <div className={classes.icon} style={valueIconStyle}>
            {React.cloneElement(icon, { size: valueIconSize })}
          </div>
        ) : (
          <div
            style={{
              background: getRethumb({ imageUrl: icon, size: valueIconSize }),
              ...valueIconStyle,
            }}
            className={classes.icon}
          />
        )}
        <span className={classes.label} style={labelStyle}>
          {label}
        </span>
      </div>
    </div>
  );
}
