import React from "react";
import classes from "./Header.scss";
import { CancelIcon, ChevronIcon } from "SvgComponents";
import { colors } from "Constants";
import { I18nHOC } from "UIComponents";
class Header extends React.PureComponent {
  render() {
    const {
      title,
      onCloseClick,
      showBack,
      onBackClick,
      showPreview,
      t,
      titleTextStyle,
      leftContainerStyles,
      rightContainerStyles,
    } = this.props;
    return (
      <div className={classes.container}>
        {!!showBack && (
          <div className={classes.backContainer} onClick={onBackClick}>
            <ChevronIcon fill={colors.salmon60} />
            <div className={classes.backBtnText}>{t("common:back")}</div>
          </div>
        )}
        <div className={classes.titleContainer}>
          <div style={leftContainerStyles} className={classes.leftContainer}>
            <div style={titleTextStyle} className={classes.titleText}>
              {title}
            </div>
          </div>
          <div style={rightContainerStyles} className={classes.rightContainer}>
            {!!showPreview && (
              <div className={classes.previewButton}>{t("common:preview")}</div>
            )}
            {!!showPreview && <div className={classes.separatorline} />}
            <div className={classes.closeButton} onClick={onCloseClick}>
              <CancelIcon width={16} heigth={16} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(Header);
