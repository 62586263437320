import PropTypes from "prop-types";
import React from "react";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import {
  TextInput,
  PasswordTextInput,
  FormInputError,
} from "@toddle-design/web";
import classes from "./InputTextField.scss";
import classNames from "classnames";

const inputStyle = {
  height: "100%",
};

class FormTextInput extends UIBaseComponent {
  constructor(props) {
    super(props);
  }
  handleKeyPress = e => {
    const { handleKeyPress } = this.props;
    if (e.key === "Enter") {
      let param = {};
      param[e.target.name] = e.target.value;
      if (this.props.onEnter) {
        this.props.onEnter(param);
      }
    }
    if (handleKeyPress) {
      handleKeyPress(e);
    }
  };

  renderEdit = () => {
    const { size, disabled, type, value, textInputLabel, ...rest } = this.props;

    const errorMessage = this.state.error ? this.state.error : "";

    const InputComponent = type === "password" ? PasswordTextInput : TextInput;

    const containerClass = classNames("w-full", classes.containerClass);

    return (
      <div className={containerClass}>
        <InputComponent
          {...rest}
          size={size}
          style={inputStyle}
          onChange={e => this.updateValue(e.target.value)}
          ref={this.updateInputRef}
          error={errorMessage}
          label={textInputLabel}
          disabled={disabled}
          onKeyPress={this.handleKeyPress}
          onBlur={e => this.onBlur({ value: e.target.value })}
          onFocus={e => this.onFocus({ value: e.target.value })}
          value={value ? value : ""}
        />
        {errorMessage && (
          <FormInputError error={errorMessage} className={classes.errorClass} />
        )}
      </div>
    );
  };
}

FormTextInput.defaultProps = {
  ...UIBaseComponent.defaultProps,
  inputStyle: {},
  type: "text",
  size: "medium",
  autoFocus: false,
  showErrorText: false,
  ["data-test-id"]: "input-form-text",
};

FormTextInput.propTypes = {
  ...UIBaseComponent.propTypes,
  onBlur: PropTypes.func,
  inputStyle: PropTypes.object,
  onEnter: PropTypes.func,
  updateInputRef: PropTypes.func,
  showErrorText: PropTypes.bool,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
};

export default FormTextInput;
