import React, { useState } from "react";
import classes from "./AddEvidenceModal.scss";
import classNames from "classnames";
import { UIModal, I18nHOC } from "UIComponents";
import Sidebar from "./Sidebar";
import CardContainer from "./CardContainer";
import Footer from "./Footer";
import {
  getActiveTabConfigMemoized,
  getSelectedEvidenceCountMemoized,
  getAddButtonDisabilityStatusMemoized,
} from "./AddEvidenceModalUtils.js";

const AddEvidenceModal = props => {
  const {
    template,
    curriculumProgramType,
    onRequestClose,
    modalContent,
    overlayContainer,
    initialTab,
    styles,
    actionsStyles,
    onClickView,
    parentIdConfig,
    onClickCancel,
    onClickAdd,
    customEvidences,
    initialAppliedFilters,
    selectAllOptionsInitially,
    initiallySelectedEvidence,
    addButtonLabel,
    t,
  } = props;

  const sidebarLabel = _.get(template, "label", "");
  const evidenceOptions = _.get(template, "evidenceOptions", []);
  const suggestedEvidenceOptions = _.get(
    template,
    "suggestedEvidenceOptions",
    []
  );

  const [activeTab, setActiveTab] = useState(
    initialTab || _.get(_.first(evidenceOptions), "key", "")
  );

  const allOptions = [...evidenceOptions, ...suggestedEvidenceOptions];

  const [selectedCards, changeSelectedCards] = useState(
    initiallySelectedEvidence ?? {}
  );

  const activeTabConfig = getActiveTabConfigMemoized({
    allOptions,
    activeTab,
  });

  const activeTabEvidenceTypes = _.get(activeTabConfig, "evidenceTypes", []);

  const selectedEvidenceCount = getSelectedEvidenceCountMemoized({
    selectedCards,
  });

  const isAddButtonDisabled = getAddButtonDisabilityStatusMemoized({
    initiallySelectedEvidence,
    selectedCards,
  });

  const handleOnClickAdd = () => {
    onClickAdd({ evidences: selectedCards });
    onClickCancel();
  };

  const showSidebar = _.size(allOptions) > 1;

  const containerClass = classNames(
    {
      [classes.container]: true,
    },
    {
      [classes.noSidebarContainer]: !showSidebar,
    }
  );

  const footerClass = classNames(
    {
      [classes.footer]: true,
    },
    { [classes.noSidebarFooter]: !showSidebar }
  );

  return (
    <UIModal
      isOpen={true}
      onRequestClose={onRequestClose}
      modalContent={modalContent}
      overlayContainer={overlayContainer}
    >
      <div className={containerClass}>
        {showSidebar && (
          <Sidebar
            label={sidebarLabel}
            suggestedEvidenceOptions={suggestedEvidenceOptions}
            evidenceOptions={evidenceOptions}
            activeTab={activeTab}
            selectedCards={selectedCards}
            updateActiveTab={setActiveTab}
            t={t}
          />
        )}
        <div className={classes.rightPane}>
          <CardContainer
            activeTabConfig={activeTabConfig}
            activeTab={activeTab}
            selectedCards={selectedCards}
            changeSelectedCards={changeSelectedCards}
            activeTabEvidenceTypes={activeTabEvidenceTypes}
            parentIdConfig={parentIdConfig}
            onClickView={onClickView}
            styles={styles}
            actionsStyles={actionsStyles}
            customEvidences={customEvidences}
            selectAllOptionsInitially={selectAllOptionsInitially}
            initialAppliedFilters={initialAppliedFilters}
            curriculumProgramType={curriculumProgramType}
            t={t}
          />
        </div>
        <div className={footerClass}>
          <Footer
            onClickCancel={onClickCancel}
            onClickAdd={handleOnClickAdd}
            isAddButtonDisabled={isAddButtonDisabled}
            selectedEvidenceCount={selectedEvidenceCount}
            addButtonLabel={addButtonLabel}
            t={t}
          />
        </div>
      </div>
    </UIModal>
  );
};

AddEvidenceModal.defaultProps = {
  initiallySelectedEvidence: {},
  addButtonLabel: null,
};

export default I18nHOC({
  resource: ["common", "snp", "progressReport", "schoolSetup"],
})(AddEvidenceModal);
