import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";
import ProjectDetails from "Projects/routes/ProjectDetails";

const {
  progress: { path },
} = routes;

export default store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components/Progress").default;

              resolve(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [ProjectDetails(store)],
  };
};
