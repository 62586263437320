import gql from "graphql-tag";
import {
  organizationFragment,
  attachmentFragment,
} from "modules/CommonFragments";
import {
  likeConnectionNodefragment,
  entityRatingFragment,
  entitySavedByNodeFragment,
  communitySchoolBasicFragment,
} from "Community/modules/CommunityFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
export const unitPlanFragment = {
  unitPlanItemBannerFeed: gql`
    fragment unitPlanItemBannerFeed on UnitPlan {
      id
      title {
        id
        value
      }
      resolvedGrades {
        id
        name
      }
      theme {
        id
        value
        resolvedMinimalTree {
          ... on ResolvedFieldThemeSet {
            id
            themes {
              id
              label
            }
          }
        }
      }
      image {
        id
        value
      }
      contributedFromSchool {
        ...communitySchoolItem
      }
      collaborators: allCollaborators {
        edges {
          node {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
    }
    ${communitySchoolBasicFragment.communitySchool}
  `,
  unitPlanFeed: gql`
    fragment unitPlanItem on UnitPlan {
      id
      owner {
        id
      }
      title {
        id
        value
      }
      resolvedGrades {
        id
        name
      }
      theme {
        id
        value
        resolvedMinimalTree {
          ... on ResolvedFieldThemeSet {
            id
            themes {
              id
              label
            }
          }
        }
      }
      image {
        id
        value
      }
      contributedFromSchool {
        ...communitySchoolItem
      }
      collaborators: allCollaborators {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
      communityComments {
        totalCount
      }

      ...unitSavedByNodeItem
      ...unitLikeConnectionNodeItem
    }
    ${communitySchoolBasicFragment.communitySchool}
    ${likeConnectionNodefragment.unitPlan}
    ${entitySavedByNodeFragment.unitPlan}
  `,
  combineUnitPlanFeed: gql`
    fragment unitPlanItem on UnitPlan {
      id
      owner {
        id
      }
      title {
        id
        value
      }
      resolvedGrades {
        id
        name
      }
      image {
        id
        value
      }
      contributedFromSchool {
        ...communitySchoolItem
      }
      collaborators: allCollaborators {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
      communityComments {
        totalCount
      }
      ...unitSavedByNodeItem
      ...unitLikeConnectionNodeItem
    }
    ${communitySchoolBasicFragment.communitySchool}
    ${likeConnectionNodefragment.unitPlan}
    ${entitySavedByNodeFragment.unitPlan}
  `,
  unitPlanQuickViewDetails: gql`
    fragment unitPlanQuickViewItem on UnitPlan {
      id
      templateId
      resolvedGrades {
        id
        name
      }
      title {
        ...unitPlanFieldItem
      }
      theme {
        ...unitPlanFieldItem
      }
      fields(uids: $uids) {
        ...unitPlanFieldItem
      }
      image {
        ...unitPlanFieldItem
      }
      contributedFromSchool {
        ...communitySchoolItem
      }
      subjects {
        ...unitPlanFieldItem
      }
      communityComments {
        totalCount
      }
      collaborators: allCollaborators {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
      createdBy {
        id
        email
      }
      isDeleted
      ...unitLikeConnectionNodeItem
      ...unitSavedByNodeItem
    }
    ${communitySchoolBasicFragment.communitySchool}
    ${plannerFieldFragment.unitplanField}
    ${likeConnectionNodefragment.unitPlan}
    ${entitySavedByNodeFragment.unitPlan}
  `,
};

export const assessmentFragment = {
  assessmentFeed: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      grades {
        id
        name
      }
      duration {
        id
        value
      }
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      image {
        id
        value
      }
      communityComments {
        totalCount
      }
      fields(uids: ["measureAssessing"]) {
        id
        uid
        resolvedMinimalTree {
          id
          ... on ResolvedFieldUndefinedEntity {
            undefinedEntity
          }
        }
      }
      ...assessmenLikeConnectionNodeItem
      ...assessmentSavedByNodeItem
    }
    ${likeConnectionNodefragment.assessment}
    ${entitySavedByNodeFragment.assessment}
  `,
};

export const plannerResourceFragment = {
  plannerResource: gql`
    fragment plannerResourceItem on PlannerResource {
      id
      resourceTitle: title
      attachments {
        id
        url
      }
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      entityRatings {
        ...entityRatingItem
      }
    }
    ${entityRatingFragment.entityRating}
  `,
};

export const leCollectionFragment = {
  leCollectionFeed: gql`
    fragment leCollectionItem on LECollection {
      id
      name
      grades {
        id
        name
      }
      imageAttachment: image {
        ...attachmentItem
      }
      assessments {
        totalCount
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const peopleFragment = {
  peopleFeed: gql`
    fragment peopleItem on Staff {
      id
      firstName
      lastName
      profileImage
      schools: schoolTenures(currentSchool: true) {
        id
        school {
          id
          name
        }
      }
      units: communityEntities(entityTypes: UNIT_PLAN, feedType: BY_ME) {
        totalCount
      }
      les: communityEntities(entityTypes: ASSESSMENT, feedType: BY_ME) {
        totalCount
      }
    }
  `,
};
