import {
  UIBaseComponent,
  I18nHOC,
  ButtonDropdown,
  InputTextField,
} from "UIComponents";
import React, { createRef } from "react";
import classes from "./TimeSelectorV2.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { ClockOutlined } from "@toddle-design/web-icons";
import { Tooltip } from "@toddle-design/web";
import { colors } from "Constants";
import {
  getValidTimeIn12HourFormat,
  getValueInAM_PMFormat,
  getNearestTimeFromGivenArray,
  getMinuteArr,
  getTimelineLabels,
  isValueBeforeStartTime,
  getExcludedRange,
  getDisabledMinutes,
} from "Utils";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const styles = {
  dropdownItemStyle: {
    width: "100%",
    height: "40px",
    padding: "12px 15px",
    fontSize: "12px",
  },
  dropdownContainerStyle: {
    width: "100%",
    maxHeight: "204px",
    overflowY: "scroll",
    top: "56px",
    borderRadius: "4px",
  },
  buttonInputStyle: {
    border: "unset",
  },
  dropdownParentStyle: {
    display: "unset",
    flexShrink: "inherit",
    flexGrow: "inherit",
  },
  dropdownItemTextStyle: {
    fontSize: "1.2rem",
  },
  buttonInputErrorStyle: {
    border: "unset",
    backgroundColor: "rgba(255, 180, 0, 0.1)",
  },
  buttonInputFocusStyle: {
    width: "100%",
    position: "absolute",
    bottom: "0",
    borderBottom: `1px solid ${colors.blue29}`,
  },
  buttonInputExtraMargin: {
    marginLeft: "16px",
  },
  timeComponentNormalStyle: {
    border: "1px solid #dbdbdb",
    borderRadius: "4px",
  },
  timeComponentDisabledStyle: {
    backgroundColor: "#f9f9f9",
  },
  // timeSvgStyle: { position: "absolute", zIndex: "10" }
};

class TimeSelectorV2 extends UIBaseComponent {
  constructor(props) {
    super(props);
    const {
      value,
      minuteStep,
      startTime,
      minuteDifference,
      allowedDropdownOptionsStartTime,
      allowedDropdownOptionsEndTime,
    } = this.props;

    this.state = {
      ...this.state,
      isOpen: false,
      typedValue: getValueInAM_PMFormat(value),
      timelineLabels: getTimelineLabels({
        interval: minuteStep,
        period: "m",
        startTime,
        minuteDifference,
        allowedDropdownOptionsStartTime,
        allowedDropdownOptionsEndTime,
      }),
      isValueInvalid: false,
      isInputFocused: false,
      showDropdown: false,
    };
    this.listRefs = null;
    this.dropdownRef = createRef(null);
  }

  componentDidMount() {
    const { timelineLabels } = this.state;
    this.listRefs = timelineLabels.reduce((acc, current, index) => {
      acc[_.get(current, "label", "")] = createRef();
      return acc;
    }, {});
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      value,
      startTime,
      disableConfirmButton,
      minuteStep,
      minuteDifference,
      allowedDropdownOptionsStartTime,
      allowedDropdownOptionsEndTime,
    } = this.props;
    if (prevProps.value !== value) {
      this.setState({
        typedValue: getValueInAM_PMFormat(value),
      });
    }

    if (
      prevProps.startTime !== startTime ||
      prevProps.allowedDropdownOptionsStartTime !==
        allowedDropdownOptionsStartTime
    ) {
      this.setState({
        isValueInvalid: false,
        timelineLabels: getTimelineLabels({
          interval: minuteStep,
          period: "m",
          startTime,
          minuteDifference,
          allowedDropdownOptionsStartTime,
          allowedDropdownOptionsEndTime,
        }),
      });
      if (disableConfirmButton) {
        disableConfirmButton(false);
      }
    }
  }

  updateLocalValue = value => {
    const hour = moment(value).get("hour");
    const minute = moment(value).get("minute");
    const { startTime, endTime, getFixMinutes } = this.props;
    const disabledMinutes = getDisabledMinutes({
      hours: hour,
      startTime,
      endTime,
      getFixMinutes,
    });
    if (disabledMinutes.indexOf(minute) >= 0) {
      const excludedMinutes = getExcludedRange({
        start: 0,
        excludedOptions: disabledMinutes,
        end: 59,
      });
      const temp = value.set({ hour: hour, minute: excludedMinutes[0] });
      this.updateValueLocally(temp);
    } else {
      this.updateValueLocally(value);
    }
  };

  updateValueLocally = value => {
    const {
      storeValueInSeconds,
      isValueMomentObj,
      SelectedTimeformat,
    } = this.props;
    let localValue = value;

    if (storeValueInSeconds) {
      const duration = moment(value).diff(moment().startOf("day"), "seconds");
      localValue = duration;
    }
    this.updateValue(
      isValueMomentObj
        ? localValue
        : moment(localValue).format(SelectedTimeformat)
    );
  };

  constructTimeFromDuration = durationInSeconds => {
    const dateValue = moment().startOf("day").add(durationInSeconds, "s");
    return dateValue;
  };

  getValue = () => {
    const {
      value,
      storeValueInSeconds,
      isValueMomentObj,
      SelectedTimeformat,
    } = this.props;

    return storeValueInSeconds
      ? this.constructTimeFromDuration(value)
      : isValueMomentObj
      ? value
      : moment(value, SelectedTimeformat);
  };

  onUpdateTimeInput = param => {
    const { minuteStep, disableConfirmButton } = this.props;
    if (
      this.dropdownRef &&
      _.get(this.dropdownRef, "current") &&
      _.get(this.dropdownRef, "current.updateShowDropdown")
    ) {
      // show dropdown when user is typing
      this.dropdownRef.current.updateShowDropdown(true);
    }
    this.setState(() => ({
      typedValue: param["typedValue"],
      isValueInvalid: false,
    }));
    // disableConfirmButton is a function passed from parent component
    // to disable the "update" button
    if (disableConfirmButton) {
      disableConfirmButton(false);
    }
    // getValidTimeIn12HourFormat returns the validated and corrected time in 12 hour format
    // returns null in case of incorrect input
    const validTime = getValidTimeIn12HourFormat({
      inputStr: param["typedValue"],
      minuteStep,
    });
    if (!validTime) {
      this.setState({
        isValueInvalid: true,
      });
      if (disableConfirmButton) {
        disableConfirmButton(true);
      }
      return;
    }
    // getNearestTimeFromGivenArray returns the next greater time than val time
    const nearestTime = getNearestTimeFromGivenArray({
      val: validTime,
      minuteArr: getMinuteArr({ minuteStep }),
    });
    this.scrollElementIntoViewLocal(nearestTime);
  };

  onFocusInput = () => {
    const { onFocusChange } = this.props;
    this.setState({ isInputFocused: true });
    if (onFocusChange) {
      setTimeout(() => onFocusChange(true), 100);
    }
  };

  scrollElementIntoViewLocal = (val = null) => {
    const { typedValue } = this.state;
    const { minuteStep } = this.props;
    const dropdownListRefs = this.listRefs;
    const minuteArr = getMinuteArr({ minuteStep });

    if (typedValue) {
      // getNearestTimeFromGivenArray returns the next greater time than val time
      const nearestVal = getNearestTimeFromGivenArray({
        val: val
          ? getValueInAM_PMFormat(val)
          : getValueInAM_PMFormat(typedValue),
        minuteArr,
      });
      if (
        dropdownListRefs &&
        dropdownListRefs[nearestVal] &&
        dropdownListRefs[nearestVal].current
      ) {
        this._scrollElementIntoView(dropdownListRefs[nearestVal].current);
      }
    }
  };

  _scrollElementIntoView = element => {
    if (!element) return;
    element.parentNode.scrollTop = element.offsetTop;
  };

  onBlur = val => {
    // set the time if it is valid and set the isInputFocused state as false
    const typedValue = _.get(val, "params.typedValue");
    const { minuteStep, startTime, value } = this.props;
    const initialTime = getValueInAM_PMFormat(value);
    // getValidTimeIn12HourFormat returns the validated and corrected time in 12 hour format
    // returns null in case of incorrect input
    const validTime = getValidTimeIn12HourFormat({
      inputStr: typedValue,
      minuteStep,
    });

    const isValueInvalid = !(
      moment(typedValue, "hh:mm a", true).isValid() && validTime
    );
    const isValidTimeBeforeStartTime = isValueBeforeStartTime({
      startTime,
      timeValue: validTime,
    });

    if (isValueInvalid || isValidTimeBeforeStartTime) {
      let time = validTime;

      if (isValidTimeBeforeStartTime) {
        time = initialTime;
      }

      this.setState({
        typedValue: time,
      });
    }

    if (validTime !== initialTime) {
      // update the time only if it is changed to some other time
      this.updateLocalValue(moment(validTime, "hh:mm a"));
    }

    this.setState({
      isInputFocused: false,
    });
  };

  onEnterPress = val => {
    const typedValue = _.get(val, "typedValue", null)
      ? _.get(val, "typedValue", null)
      : _.get(val, "params.typedValue");
    const { minuteStep, disableConfirmButton, startTime } = this.props;
    // getValidTimeIn12HourFormat returns the validated and corrected time in 12 hour format
    // returns null in case of incorrect input
    const validTime = getValidTimeIn12HourFormat({
      inputStr: typedValue,
      minuteStep,
    });
    const isValueInvalid =
      !validTime || isValueBeforeStartTime({ startTime, timeValue: validTime });
    this.setState({
      typedValue: validTime !== null ? validTime : "",
      isValueInvalid,
      isInputFocused: false,
    });
    // disableConfirmButton is a function passed from parent component
    // to disable the "update" button
    if (isValueInvalid && disableConfirmButton) {
      disableConfirmButton(true);
      return;
    }
    this.scrollElementIntoViewLocal();
    this.updateLocalValue(moment(validTime, "hh:mm a"));
  };

  getButtonComponent() {
    const { typedValue, isInputFocused } = this.state;
    const {
      inputStyle,
      isDisabled,
      showInputBorderOnFocus,
      showInputIcon,
      placeholder,
      placeholder_locale,
      t,
      focusedInputTextStyle,
    } = this.props;
    const overallInputStyle = {
      ...styles.buttonInputStyle,
      ...inputStyle,
      ...(showInputIcon ? styles.buttonInputExtraMargin : {}),
      ...(isInputFocused ? focusedInputTextStyle : {}),
    };
    return (
      <div style={{ position: "relative" }}>
        <InputTextField
          placeholder={
            placeholder === "common:select_with_label"
              ? t(placeholder, { label: t(placeholder_locale) })
              : placeholder
          }
          name={"typedValue"}
          value={isDisabled ? "" : typedValue}
          updateInputField={this.onUpdateTimeInput}
          onBlurInputField={this.onBlur}
          onEnter={this.onEnterPress}
          inputStyle={overallInputStyle}
          disabled={isDisabled}
          onFocusInputField={this.onFocusInput}
        />
        {isInputFocused && showInputBorderOnFocus && (
          <div style={styles.buttonInputFocusStyle} />
        )}
      </div>
    );
  }

  onDropDownItemClick = value => {
    const { disableConfirmButton, startTime } = this.props;
    const isValueInvalid = isValueBeforeStartTime({
      startTime,
      timeValue: value,
    });
    this.setState({
      typedValue: value,
      isValueInvalid,
    });
    if (disableConfirmButton) {
      disableConfirmButton(isValueInvalid);
    }
    this.updateLocalValue(moment(value, "hh:mm a"));
  };

  onUpdateShowDropDown = val => {
    const { value } = this.props;
    this.scrollElementIntoViewLocal(value);
    this.setState({ showDropdown: val });
  };
  renderEdit = () => {
    const {
      minuteStep,
      isDisabled,
      value,
      showInputIcon,
      timeComponentStyle,
      dropdownContainerStyle,
      dropdownItemStyle,
      showBorder,
      alwaysShowTooltip,
      positionDropdownDivWithPopover,
      dropdownParentContainerStyle,
      dropdownParentStyle,
      dropdownActiveTabStyle,
      dropdownItemTextStyle,
    } = this.props;
    const {
      typedValue,
      timelineLabels,
      isValueInvalid,
      showDropdown,
    } = this.state;
    let overallTimeComponentStyle = isValueInvalid
      ? {
          ...timeComponentStyle,
          ...styles.timeComponentNormalStyle,
          ...styles.buttonInputErrorStyle,
        }
      : showBorder
      ? { ...styles.timeComponentNormalStyle, ...timeComponentStyle }
      : { ...timeComponentStyle };
    overallTimeComponentStyle = isDisabled
      ? { ...overallTimeComponentStyle, ...styles.timeComponentDisabledStyle }
      : { ...overallTimeComponentStyle };
    return (
      <div className={classes.container}>
        <ConditionalWrapper
          type="wrapperAsElement"
          condition={isValueInvalid}
          wrapper={
            <Tooltip defaultVisible placement="top" tooltip={"Invalid time"} />
          }
        >
          <div
            className={classes.timeComponent}
            style={overallTimeComponentStyle}
          >
            {showInputIcon && (
              <div className={classes.timeSvg}>
                <ClockOutlined size="xx-small" disabled={isDisabled} />
              </div>
            )}
            <ButtonDropdown
              ref={this.dropdownRef}
              authTabs={timelineLabels}
              buttonComponent={this.getButtonComponent()}
              value={value}
              containerStyle={{
                ...styles.dropdownContainerStyle,
                ...dropdownContainerStyle,
              }}
              itemStyle={{ ...styles.dropdownItemStyle, ...dropdownItemStyle }}
              activeValue={getValidTimeIn12HourFormat({
                inputStr: typedValue,
                minuteStep,
              })}
              highlightActiveElement={true}
              onItemClick={(value, parentTab) => {
                this.onDropDownItemClick(value);
              }}
              displayHandle={false}
              positionDropdownDivWithPopover={positionDropdownDivWithPopover}
              popoverProps={{
                positions: ["bottom", "right"],
                containerStyle:
                  positionDropdownDivWithPopover && !showDropdown
                    ? { zIndex: 9 }
                    : { zIndex: 10 },
              }}
              buttonParentStyle={{
                ...styles.dropdownParentStyle,
                ...dropdownParentStyle,
              }}
              parentContainerStyle={dropdownParentContainerStyle}
              dropdownListRefs={this.listRefs}
              disabled={isDisabled}
              textStyle={{
                ...styles.dropdownItemTextStyle,
                ...dropdownItemTextStyle,
              }}
              onUpdateShowDropDown={this.onUpdateShowDropDown}
              dropdownActiveTabStyle={dropdownActiveTabStyle}
            />
          </div>
        </ConditionalWrapper>
      </div>
    );
  };
}

TimeSelectorV2.defaultProps = {
  ...UIBaseComponent.defaultProps,
  isDisabled: false,
  SelectedTimeformat: "hh:mm a",
  minuteStep: 15,
  showSecond: false,
  showMinute: true,
  isValueMomentObj: true,
  storeValueInSeconds: false,
  placeholder: "common:select_with_label",
  placeholder_locale: "common:time",
  showInputIcon: true,
  use12Hours: false,
  startTime: "00:00",
  showBorder: true,
  alwaysShowTooltip: false,
  showInputBorderOnFocus: false,
  onFocusChange: null,
  positionDropdownDivWithPopover: false,
  dropdownParentContainerStyle: {},
  dropdownActiveTabStyle: {},
  dropdownItemTextStyle: {},
  allowedDropdownOptionsStartTime: "00:00",
  minuteDifference: 0,
  focusedInputTextStyle: {},
};

TimeSelectorV2.propTypes = {
  ...UIBaseComponent.propTypes,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  minuteStep: PropTypes.number,
  SelectedTimeformat: PropTypes.string,
  endTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.object]),
  showInputIcon: PropTypes.bool,
  use12Hours: PropTypes.bool,
  showBorder: PropTypes.bool,
  alwaysShowTooltip: PropTypes.bool,
  showInputBorderOnFocus: PropTypes.bool,
  onFocusChange: PropTypes.func,
  positionDropdownDivWithPopover: PropTypes.bool,
  dropdownParentContainerStyle: PropTypes.object,
  placeholder: PropTypes.string,
  placeholder_locale: PropTypes.string,
  dropdownActiveTabStyle: PropTypes.object,
  dropdownItemTextStyle: PropTypes.object,
  allowedDropdownOptionsStartTime: PropTypes.string,
  minuteDifference: PropTypes.number,
  focusedInputTextStyle: PropTypes.object,
};

export default I18nHOC({ resource: ["common"] })(TimeSelectorV2);
