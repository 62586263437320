import React, { useState } from "react";
import {
  UIModal,
  UIButton,
  I18nHOC,
  FullScreenLoader,
  NoDataComponent,
} from "UIComponents";
import { LEPreview, LeFeed } from "AppComponents";
import classes from "./LeSearchModal.scss";
import { importAssessment } from "Assessments/modules/AssessmentModule";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";

const LeSearchModal = ({
  onLeSearchModalClose,
  t,
  importAssessment,
  portalType,
  unitPlanId,
  onSuccess,
  eventTarget,
  assessmentTypes,
  curriculumType,
  importFromLeLibrary,
  groupType,
}) => {
  const [assessmentId, setAssessmentId] = useState("");
  const [loading, setLoading] = useState(false);

  const onAddClick = async () => {
    setLoading(true);
    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const unitPlanAcademicYearsIds = _.map(
      _.get(unitPlanDetails, "academicYears", []),
      item => item.id
    );
    const importId = await importAssessment({
      sourceAssessmentId: assessmentId,
      unitId: unitPlanId,
      eventTarget: eventTarget || "learning experience search modal",
      assessmentTypes,
      academicYears: unitPlanAcademicYearsIds,
      userInfoPortalType: portalType,
    });
    setLoading(false);
    if (importId) {
      onLeSearchModalClose();
      if (onSuccess) {
        onSuccess(importId);
      }
    }
  };

  return (
    <UIModal
      isOpen={true}
      onRequestClose={onLeSearchModalClose}
      modalContent={classes.modalContent}
    >
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <div className={classes.leftPane}>
            <LeFeed
              importFromLeLibrary={importFromLeLibrary}
              showSubText={importFromLeLibrary}
              shouldIncludeQuickTasks={false}
              assessmentId={assessmentId}
              setAssessmentId={setAssessmentId}
              portalType={portalType}
              curriculumType={curriculumType}
              groupType={groupType}
            />
          </div>
          <div className={classes.rightPane}>
            {assessmentId ? (
              <LEPreview
                assessmentId={assessmentId}
                portalType={portalType}
                parentType={"UNIT_PLAN"}
                attachmentItemHeight={100}
              />
            ) : (
              <NoDataComponent
                emptyText={t("common:select_to_preview")}
                emptyHeaderText=""
                isSearch={false}
              />
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <UIButton
            type="filled"
            size="sm"
            color="grey"
            containerStyle={{ marginRight: "16px" }}
            onClick={onLeSearchModalClose}
          >
            {t("common:cancel")}
          </UIButton>
          <UIButton
            type="filled"
            size="sm"
            color="pink"
            isDisabled={!assessmentId}
            onClick={onAddClick}
          >
            {t("common:add")}
          </UIButton>
        </div>
      </div>
      {!!loading && <FullScreenLoader />}
    </UIModal>
  );
};

const mapStateToProps = state => {
  return {
    curriculumType: state.platform.currentCurriculumProgramType,
  };
};

const mapActionCreators = {
  importAssessment,
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators)
)(LeSearchModal);
