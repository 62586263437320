import React from "react";
import _ from "lodash";
import commonClasses from "../common.scss";
import classes from "./ParticipantSteps.scss";

import classNames from "classnames";
import { UIButton, I18nHOC } from "UIComponents";
import { BAR_URL } from "Constants";
import { downloadFileFromUrl } from "Utils";
import { ParticipantSteps, handbookUrl } from "Planathon/data";
const ParticipantStepsComp = props => {
  const { t } = props;
  const { title, steps, othersText, image } = ParticipantSteps;
  const containerClass = classNames(
    { [commonClasses.container]: true },
    { [classes.container]: true }
  );

  return (
    <div className={containerClass}>
      <div className={commonClasses.title}>{t(title)}</div>
      <div className={classes.innerContainer}>
        <div className={classes.stepsContainer}>
          {_.map(steps, (step, index) => {
            const { title, color } = step;
            return (
              <div className={classes.step} key={index}>
                <div
                  className={classes.stepCount}
                  style={{ backgroundColor: color }}
                >
                  {t("common:step_count", { countStep: index + 1 })}
                </div>
                <div className={classes.stepDotContainer}>
                  <div
                    className={classes.stepDot}
                    style={{ backgroundColor: color }}
                  ></div>
                  {steps.length - 1 != index && (
                    <div className={classes.verticalLine}></div>
                  )}
                </div>
                <div className={classes.stepDetailsContainer}>
                  <div
                    className={classes.mobileStepCount}
                    style={{ backgroundColor: color }}
                  >
                    {t("common:step_count", { countStep: index + 1 })}
                  </div>
                  <div className={classes.stepDetails}>{t(title)}</div>
                </div>
              </div>
            );
          })}
        </div>
        <img className={classes.stepsImage} src={image}></img>
      </div>

      <div className={classes.strips}>
        <img src={BAR_URL}></img>
      </div>

      <div className={classes.othersText}>{t(othersText)}</div>
      <div
        className={classes.button}
        onClick={() =>
          downloadFileFromUrl({
            name: "Toddle_Planathon_Handbook.pdf",
            url: handbookUrl,
          })
        }
      >
        <UIButton color="blue">{t("planathon:download_handbook")}</UIButton>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["planathon", "common"] })(
  ParticipantStepsComp
);
