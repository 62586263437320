import React, {
  useState,
  useEffect,
  Fragment,
  memo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  UIButton,
  I18nHOC,
  TextAreaInput,
  SearchBox,
  FullScreenLoader,
  withLoader,
  UILabel,
  FormTextAreaInput,
} from "UIComponents";
import classes from "./AddToCommunity.scss";
import { colors } from "Constants";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { Collaborator } from "AppComponents";
import Guidelines from "./Guidelines";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import { getRethumbUrl } from "Utils";
import {
  getUnitDetailsQuery,
  getLEDetailsQuery,
} from "Community/modules/CommunityQuery";
import {
  getUnitInfoFromCache,
  getLEInfoFromCache,
} from "Community/modules/CommunityGraphqlHelpers";
import { addToCommunity } from "Community/modules/CommunityModule";
import { getOrganizationGradesQuery } from "GradesSetup/modules/Queries";
import { getOrganizationGradesFromCache } from "GradesSetup/modules/GraphqlHelpers";
import { getCommunityTagsQuery } from "OnBoarding/modules/OnBoardingQuery";
import { getCommunityTagsFromCache } from "OnBoarding/modules/OnBoardingGraphqlHelpers";
import { createNewTag } from "OnBoarding/modules/OnBoardingModule";
import { getUserInfo, setToastMsg } from "Login/modules/LoginModule";
import { Trans } from "react-i18next";

const shareButtonStyle = {
  marginLeft: 16,
};

const cancelButtonStyle = {};

const collaboratorStyle = {
  inputContainerStyle: {
    position: "relative",
  },
  inputSearchBarContainerStyle: {
    maxHeight: "unset",
    borderColor: colors.strokeOne,
  },
  errorStyle: {
    borderColor: colors.yellow50,
  },
};

const searchBoxStyles = {
  searchBarInputText: {
    fontSize: "1.6rem",
  },
  searchBarWithTagContainer: {
    paddingLeft: "16px",
  },
  searchBarContainer: {
    paddingLeft: 0,
  },
  tag: {
    container: {
      height: "32px",
      margin: "5px",
    },
  },
};

const getLECount = resources =>
  _.get(resources, "leCount", 0) + _.get(resources, "assessmentCount", 0);

const aboutMaxLimit = 500;

const AddToCommunity = memo(
  ({
    entityId,
    entityType,
    t,
    setAddCommunityModalVisibility,
    entityData = {},
    addToCommunity,
    updateState,
    showFooter,
    grades,
    topics,
    createNewTag,
    userId,
    getCommunityTopics,
    portalType,
    setSuccessModal,
    isFormInValid,
    updateRef,
    setToastMsg,
    selectedGrades,
    entityName,
    eventTarget,
  }) => {
    const [inputData, setInputData] = useState(null);
    const [showLoading, setLoading] = useState(false);

    const modalBodyRef = useRef();
    const scrollRefs = useRef({});

    useEffect(() => {
      const { fields = [], collaborators } = entityData || {};

      const collaboratorList = getCollaborators(collaborators);
      const tags = _.find(fields, { uid: "tags" }) || {};
      const about = _.find(fields, { uid: "about" }) || {};

      const data = {
        about: about.value || "",
        tags: [...(tags.value || [])],
        grades: [...(selectedGrades || [])],
        collaborators: collaboratorList,
      };
      setInputData(data);

      if (updateState)
        updateState({
          ...entityData,
          entityType,
          entityName: _.get(entityData, "title.value", ""),
          ...data,
        });
    }, []);

    const handleConfirm = async () => {
      try {
        if (isFormInValid()) {
          setToastMsg({
            msg: "toastMsgs:rectify_errors_with_count_plural",
            // locale_params: [
            //   { key: "count", value: errorCount, isPlainText: true }
            // ],
            type: "alert",
            position: "toast-top-center",
          });
          return;
        }

        setLoading(true);
        const result = await addToCommunity({
          ...(inputData || {}),
          unitId: entityType === "UNIT_PLAN" && entityId,
          leId: entityType === "ASSESSMENT" && entityId,
          entityType,
          entityName,
          eventTarget,
        });
        if (result) {
          setInputData({});
          setAddCommunityModalVisibility(false);
          if (setSuccessModal) setSuccessModal(true);
        }
      } finally {
        setLoading(false);
      }
    };

    const handleCancel = () => setAddCommunityModalVisibility(false);

    const getCollaborators = collaborators => {
      return _.map(_.get(collaborators, "edges", []), collaborator => {
        return {
          ...collaborator.node,
          permission: collaborator.permission,
        };
      });
    };

    const { title = {}, resourceLibrary } = entityData;
    const theme = _.get(entityData, "theme.resolvedMinimalTree.themes.0.label");
    const profileImage =
      _.get(entityData, "image.value", "") || RESOURCE_PLACEHOLDER_URL;
    const imageUrl = profileImage
      ? getRethumbUrl({
          width: 200,
          height: 200,
          imageUrl: profileImage,
          fitIn: true,
        })
      : "";

    const header = title["value"]
      ? t(
          entityType === "UNIT_PLAN"
            ? "community:share_the_unit"
            : "community:share_the_le",
          { label: title["value"] }
        )
      : t("common:untitled");

    const updateInputData = update => {
      setInputData(prev => ({ ...prev, ...update }));
      if (updateState) updateState(update);
    };

    const handleAddLabel = async value => {
      const newTag = await createNewTag({
        label: value,
        user: userId,
        type: "TOPIC",
      });
      updateInputData({
        tags: [..._.get(inputData, "tags", []), newTag.value],
      });
      return newTag;
    };

    const onSearchChange = async searchText => {
      if (getCommunityTopics) await getCommunityTopics.refetch({ searchText });
    };

    const updateUnitSummary = update => {
      const [key, value] = Object.entries(update)[0];
      if (_.get(value, "length", 0) > aboutMaxLimit)
        updateInputData({ [key]: value.slice(0, aboutMaxLimit) });
      else updateInputData(update);
    };

    const onFocusInput = id => {
      setTimeout(() => {
        if (scrollRefs.current[id] && modalBodyRef.current) {
          const scrollPosition = scrollRefs.current[id].offsetTop;
          const offset = scrollPosition - 220;
          modalBodyRef.current.scrollTop = offset;
        }
      }, 300);
    };

    const tagsPlaceholder = _.get(inputData, "tags.length", 0)
      ? t("common:add_with_label", {
          label: t("common:tags"),
        })
      : t("community:tags_placeholder");

    const tagsSubText = t("community:tags_subText", {
      label: t(
        `community:${
          entityType === "UNIT_PLAN" ? "unit" : "learning_experience"
        }`
      ),
    });

    if (!inputData) return null;
    return (
      <div onClick={e => e.stopPropagation()} className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.headerContainer}>
            <div className={classes.headerWrapper}>
              <div className={classes.header}>{header}</div>
              {entityType === "UNIT_PLAN" && (
                <Fragment>
                  <div className={classes.theme}>{theme}</div>
                  <div className={classes.leCount}>
                    {t("common:le_with_count", {
                      count: getLECount(resourceLibrary),
                    })}
                  </div>
                </Fragment>
              )}
            </div>
            <div
              className={classes.profileContainer}
              style={{ backgroundImage: `url(${imageUrl})` }}
            ></div>
          </div>
          <div className={classes.body} ref={modalBodyRef}>
            <div className={classes.bodyWrapper}>
              {entityType === "UNIT_PLAN" && (
                <div className={classes.block}>
                  <FormTextAreaInput
                    value={inputData.about}
                    minRows={5}
                    name="about"
                    placeholder={t("community:about_the_unit_placeholder")}
                    label={t("community:unit_summary")}
                    updateInputField={updateUnitSummary}
                    maxLength={aboutMaxLimit}
                    isRequired={true}
                    ref={ref => updateRef({ ref, key: "about" })}
                  />
                </div>
              )}

              <div className={classes.block}>
                <SearchBox
                  removeOnBackspace={true}
                  updateInputField={updateInputData}
                  styles={searchBoxStyles}
                  name="grades"
                  label={t("common:grade_plural")}
                  subText={t("community:select_grades_subtext")}
                  placeholder={t("common:add_with_label", {
                    label: t("common:grade_plural"),
                  })}
                  isRequired={true}
                  ref={ref => updateRef({ ref, key: "grades" })}
                  options={[...(grades || [])]}
                  value={inputData["grades"]}
                  showInDropdown={true}
                  inlineView={true}
                  resultType={"LINE"}
                />
              </div>

              <div
                className={classes.block}
                ref={ref => (scrollRefs.current["tags"] = ref)}
              >
                <SearchBox
                  removeOnBackspace={true}
                  styles={searchBoxStyles}
                  name="tags"
                  label={t("common:tags")}
                  placeholder={tagsPlaceholder}
                  subText={tagsSubText}
                  onSearchChange={onSearchChange}
                  isRequired={true}
                  onAddLabel={handleAddLabel}
                  ref={ref => updateRef({ ref, key: "tags" })}
                  options={[...(topics || [])]}
                  value={inputData["tags"]}
                  updateInputField={updateInputData}
                  inlineView={true}
                  showInDropdown={true}
                  allowAddNewOption={true}
                  addOnComma={true}
                  resultType={"LINE"}
                  onFocusInputField={() => onFocusInput("tags")}
                />
              </div>

              {entityType === "UNIT_PLAN" && (
                <div
                  className={classes.block}
                  ref={ref => (scrollRefs.current["collaborators"] = ref)}
                >
                  <UILabel
                    label={`${t("common:collaborators")}*`}
                    isRequired={true}
                  ></UILabel>
                  <Collaborator
                    collaborators={inputData["collaborators"]}
                    {...collaboratorStyle}
                    showDoneButton={false}
                    showCollaboratorList={false}
                    closeDropDownOnOutSideClick={true}
                    singlePlaceholder={t("planathon:add_collaborators")}
                    placeholder={t("planathon:add_collaborators")}
                    showSelectedCollaboratorInSearchBox={true}
                    isRequired={true}
                    customRef={ref => updateRef({ ref, key: "collaborators" })}
                    searchListTypes={["INDIVIDUAL"]}
                    mode={"search"}
                    onCollaboratorsUpdate={value =>
                      updateInputData({ collaborators: value })
                    }
                    portalType={portalType}
                    onCollaboratorToggle={() => onFocusInput("collaborators")}
                  />
                </div>
              )}
            </div>
          </div>

          {showFooter && (
            <div className={classes.footer}>
              <div className={classes.termsAndConditions}>
                <Trans i18nKey="community:terms_and_conditions_statement">
                  {`By pressing the 'Share' button you are agreeing to Toddle
                  Community's`}
                  <a
                    href="https://www.toddleapp.com/pp/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  and
                  <a
                    href="https://www.toddleapp.com/communityterms/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </Trans>
              </div>
              <div className={classes.buttonContainer}>
                <UIButton
                  containerStyle={cancelButtonStyle}
                  onClick={handleCancel}
                  size="sm"
                >
                  {t("common:cancel")}
                </UIButton>
                <UIButton
                  onClick={handleConfirm}
                  containerStyle={shareButtonStyle}
                  color="pink"
                  size="sm"
                >
                  {t("common:share")}
                </UIButton>
              </div>
            </div>
          )}
        </div>
        <Guidelines entityType={entityType} />
        {showLoading && (
          <div className={classes.loadingContainer}>
            <FullScreenLoader />
          </div>
        )}
      </div>
    );
  }
);

AddToCommunity.defaultProps = {
  showFooter: true,
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });

  return {
    isLoading: false,
    isData: true,
    organizationId: userInfo.org_id,
    userId: userInfo.id,
  };
};

const mapActionCreators = {
  addToCommunity,
  createNewTag,
  setToastMsg,
};

const Component = compose(
  I18nHOC({ resource: ["community", "common", "planathon"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getUnitDetailsQuery, {
    name: "unitInfo",
    skip: ({ entityId, entityType }) => entityType !== "UNIT_PLAN" || !entityId,
    options: ({ entityId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: entityId, portalType },
    }),
    props: ({ unitInfo, ownProps }) => {
      const entityData = getUnitInfoFromCache({
        id: _.get(ownProps, "entityId"),
      });
      const grades = _.get(entityData, "resolvedGrades", []);

      const gradeIds = [];
      _.forEach(grades, grade => {
        const globalGradeId = _.get(grade, "globalGrade.id", "");
        if (globalGradeId) {
          gradeIds.push(globalGradeId);
        }
      });
      return {
        entityData,
        ownProps,
        selectedGrades: gradeIds,
        isData: ownProps.isData && !_.isEmpty(entityData),
        isLoading:
          unitInfo["networkStatus"] == 1 ||
          unitInfo["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  }),
  graphql(getLEDetailsQuery, {
    name: "leInfo",
    skip: ({ entityId, entityType }) =>
      entityType !== "ASSESSMENT" || !entityId,
    options: ({ entityId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: entityId, portalType },
    }),
    props: ({ leInfo, ownProps }) => {
      const entityData = getLEInfoFromCache({
        id: _.get(ownProps, "entityId"),
      });
      const grades = _.get(entityData, "resolvedGrades", []);
      const gradeIds = [];
      _.forEach(grades, grade => {
        const globalGradeId = _.get(grade, "globalGrade.id", "");
        if (globalGradeId) {
          gradeIds.push(globalGradeId);
        }
      });
      return {
        entityData,
        ownProps,
        selectedGrades: gradeIds,
        isData: ownProps.isData && !_.isEmpty(entityData),
        isLoading:
          leInfo["networkStatus"] == 1 ||
          leInfo["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  }),
  graphql(getOrganizationGradesQuery, {
    name: "getOrganizationGrades",
    skip: ({ entityId }) => !entityId,
    options: ({ organizationId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, portalType },
    }),
    props: ({
      getOrganizationGrades,
      ownProps: { organizationId, isLoading, isData },
    }) => {
      let organization = _.get(getOrganizationGrades, "node", {});
      organization = getOrganizationGradesFromCache({ id: organizationId });

      const grades = _.filter(
        _.map(_.get(organization, "grades", []), ({ id, globalGrade }) => {
          const { name, id: globalGradeId } = globalGrade || {};
          return {
            label: name,
            value: globalGradeId,
          };
        }),
        ({ label, value }) => label && value
      );
      return {
        grades,
        getOrganizationGrades,
        isData: !_.isEmpty(organization) && isData,
        isLoading:
          getOrganizationGrades["networkStatus"] == 1 ||
          getOrganizationGrades["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getCommunityTagsQuery, {
    name: "getCommunityTopics",
    options: ({ searchText }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        searchText,
        types: ["TOPIC", "CATEGORY"],
      },
    }),
    props: ({ getCommunityTopics, ownProps }) => {
      const topics = _.get(getCommunityTopics, "community.constants.tags", []);
      return {
        topics,
        getCommunityTopics,
        ownProps,
      };
    },
  }),
  withLoader
)(AddToCommunity);

const AddToCommunityHOC = forwardRef((props, ref) => {
  const refList = useRef({});

  useImperativeHandle(ref, () => ({
    isInValid: isFormInValid,
  }));

  const updateRef = ({ ref, key }) => {
    refList.current[key] = ref;
  };

  const isFormInValid = () => {
    const { entityType } = props;
    const errors = _.reduce(
      refList.current,
      (acc, ref, key) => {
        if (
          entityType === "ASSESSMENT" &&
          (key === "about" || key === "collaborators")
        )
          return acc;
        return Number(ref.isValid()) + acc;
      },
      0
    );
    return !!errors;
  };

  return (
    <Component {...props} isFormInValid={isFormInValid} updateRef={updateRef} />
  );
});

export default AddToCommunityHOC;
