import React from "react";
import classes from "./JournalFeed.scss";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { withLoader, Loading, I18nHOC } from "UIComponents";
import {
  getCoursePostFeedQuery,
  getParentJournalFeedQuery,
} from "Post/modules/PostQuery";
import {
  getCourseJournalFeedFromCache,
  getParentJournalFeedFromCache,
} from "Post/modules/PostGraphqlHelpers";
import { EmptySvgIcon } from "SvgComponents";
import { FeedCard, PostCard } from "Post";
import classNames from "classnames";
import { checkScrollAtBottom } from "Utils";
import ActionBar from "../ActionBar";
import {
  checkPostDetailsVisibilityPermission,
  isJournalCardRevamp,
  hasTranslationPermission,
  getPostTranslationFilter,
} from "Post/utils";
import { HiddenIfPreviousYear } from "AppComponents";
import { getFieldUids } from "Post/modules/PostModule";
import _ from "lodash";
import { getSettingValue } from "modules/PermissionModule";
import { EmptyState } from "@toddle-design/web";
import { EvidenceIllustration } from "@toddle-design/theme";

const LoadingStyle = {
  width: 24,
  height: 24,
};

const JournalFeedComponent = ({
  feedList,
  onPostActionClick,
  userInfo,
  currentCommentPostId,
  isArchivedClass,
}) => {
  const { id: userId, user_type: userType } = userInfo;

  // Filter out only permitted posts
  const updatedFeedList = _.filter(feedList, item => {
    return checkPostDetailsVisibilityPermission({
      userInfo,
      postDetails: item.node,
    });
  });

  return (
    <div className={classes.centerContainer}>
      {/* A student can edit or delete its own post but teacher can do to any post   */}
      {_.map(updatedFeedList, item => {
        return (
          <React.Fragment key={item.node.id}>
            {isJournalCardRevamp() ? (
              <PostCard
                item={item.node}
                onPostActionClick={onPostActionClick}
                userId={userId}
                currentCommentPostId={currentCommentPostId}
                canEditOrDelete={
                  userType == "staff" || userId == item.node.createdBy.id
                }
                isArchivedClass={isArchivedClass}
              />
            ) : (
              <FeedCard
                item={item.node}
                onPostActionClick={onPostActionClick}
                userId={userId}
                currentCommentPostId={currentCommentPostId}
                canEditOrDelete={
                  userType == "staff" || userId == item.node.createdBy.id
                }
                isArchivedClass={isArchivedClass}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const LoadingComponent = ({ networkStatus }) => {
  return (
    <div className={classes.loadingContainer}>
      {networkStatus == 3 ? <Loading loadingStyle={LoadingStyle} /> : null}
    </div>
  );
};

const NoDataComponent = ({ filters, t, userType }) => {
  let emptyText = ``;
  if (filters.state == "IN_APPROVAL") {
    // Show empty text in in-approval based on user type
    emptyText =
      userType == "student"
        ? t("journal:no_feed_button_label", {
            buttonName: t("journal:createPost"),
          })
        : t("no_feed_in_approval");
  } else if (userType === "parent") {
    emptyText = t("journal:no_posts_available");
  } else {
    emptyText = t("journal:no_feed_button_label", {
      buttonName: t("journal:createPost"),
    });
  }

  return (
    <div className={classes.emptyState}>
      <EmptyState
        illustration={EvidenceIllustration}
        title={t("journal:nothing_here_yet")}
        subtitle={emptyText}
      />
    </div>
  );
};

class JournalFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showActionBar: true };
  }

  fetchMoreFeed = () => {
    const { hasNextPage, networkStatus, loadMorePosts } = this.props;
    if (hasNextPage && networkStatus != 3) {
      loadMorePosts();
    }
  };

  isShowActionBar = () => {
    const { showActionBar } = this.state;
    const {
      filters,
      totalCount,
      userInfo: { user_type: userType },
      isArchivedClass,
    } = this.props;
    // show action bar to approve un-approved posts only to teacher in "In Approval" tab
    return (
      userType == "staff" &&
      filters.state == "IN_APPROVAL" &&
      totalCount > 1 &&
      showActionBar &&
      !isArchivedClass
    );
  };

  onActionBarCloseClick = () => {
    this.setState({ showActionBar: false });
  };

  goScrollToTop = () => {
    if (this.journalFeedContainer) {
      this.journalFeedContainer.scrollTop = 0;
    }
  };

  onScroll = () => {
    if (
      this.journalFeedContainer &&
      checkScrollAtBottom(this.journalFeedContainer)
    ) {
      this.fetchMoreFeed();
    }
  };

  render() {
    const {
      feedList,
      networkStatus,
      onPostActionClick,
      currentCommentPostId,
      filters,
      totalCount,
      userInfo,
      t,
      inlineContainerStyle,
      isArchivedClass,
    } = this.props;
    const { user_type: userType } = userInfo;

    const containerStyle = classNames(
      { [classes.container]: classes.container },
      { [classes.containerWithAction]: !!this.isShowActionBar() }
    );

    return (
      <React.Fragment>
        <div
          className={containerStyle}
          style={inlineContainerStyle}
          ref={ref => (this.journalFeedContainer = ref)}
          onScroll={this.onScroll}
        >
          {feedList.length > 0 ? (
            <JournalFeedComponent
              feedList={feedList}
              fetchMoreFeed={this.fetchMoreFeed}
              updateContainerDivRef={this.updateContainerDivRef}
              onPostActionClick={onPostActionClick}
              userInfo={userInfo}
              currentCommentPostId={currentCommentPostId}
              isArchivedClass={isArchivedClass}
            />
          ) : (
            <NoDataComponent t={t} filters={filters} userType={userType} />
          )}
          <LoadingComponent networkStatus={networkStatus} />
        </div>
        {!!this.isShowActionBar() && (
          <HiddenIfPreviousYear>
            <ActionBar
              totalCount={totalCount}
              onActionClick={onPostActionClick}
              onCloseClick={this.onActionBarCloseClick}
            />
          </HiddenIfPreviousYear>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedAcademicYear = _.get(
    state,
    "platform.academicYearSelected",
    {}
  );
  const curriculumProgramType = state.platform.currentCurriculumProgram.type;
  const fieldUids = getFieldUids({ curriculumProgramType });

  const translationFilter = getPostTranslationFilter({
    userInfo: _.get(state, "login.userInfo", {}),
    shouldTranslate: false,
    handleTranslation: hasTranslationPermission(),
  });
  return {
    userInfo: state.login.userInfo,
    selectedAcademicYearId: _.get(selectedAcademicYear, "id", ""),
    fieldUids,
    translationFilter,
  };
};

export default compose(
  connect(mapStateToProps, null),
  I18nHOC({ resource: ["journal", "common"] }),
  graphql(getCoursePostFeedQuery, {
    name: "getCoursePostFeed",
    skip: ({ courseId }) => !courseId,
    options: ({ courseId, filters, fieldUids, translationFilter }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
        filters: filters,
        uids: fieldUids,
        translationFilter: translationFilter,
      },
    }),
    props({
      getCoursePostFeed,
      ownProps: { courseId, filters, fieldUids, translationFilter },
    }) {
      const course = getCourseJournalFeedFromCache({
        courseId,
        filters,
        uids: fieldUids,
        translationFilter,
      });
      const postObject = _.get(getCoursePostFeed, "node.posts", {});
      const pageInfo = _.get(postObject, "pageInfo", {});

      return {
        getCoursePostFeed,
        hasNextPage: _.get(pageInfo, "hasNextPage", false),
        feedList: _.get(postObject, "edges", []),
        isData: !_.isEmpty(course),
        networkStatus: getCoursePostFeed.networkStatus,
        totalCount: _.get(postObject, "totalCount", 0),
        isLoading:
          getCoursePostFeed["networkStatus"] == 1 ||
          getCoursePostFeed["networkStatus"] == 2,
        loadMorePosts: () => {
          const endCursor = _.get(pageInfo, "endCursor", null);
          return getCoursePostFeed.fetchMore({
            query: getCoursePostFeedQuery,
            variables: {
              filters: filters,
              after: endCursor,
              id: courseId,
              uids: fieldUids,
              translationFilter: translationFilter,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousCourse = previousResult.node;
              const newCourse = fetchMoreResult.node;

              return {
                ...previousResult,
                node: {
                  ...previousCourse,
                  posts: {
                    ...previousCourse.posts,
                    pageInfo: {
                      ...previousCourse.posts.pageInfo,
                      ...newCourse.posts.pageInfo,
                    },
                    edges: [
                      ...previousCourse.posts.edges,
                      ...newCourse.posts.edges,
                    ],
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(getParentJournalFeedQuery, {
    name: "getParentJournalFeed",
    skip: ({ parentId }) => !parentId,
    options: ({ parentId, filters, selectedAcademicYearId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: parentId,
        filters: {
          ...filters,
          academicYears: [selectedAcademicYearId],
        },
      },
    }),
    props({
      getParentJournalFeed,
      ownProps: { filters, parentId, selectedAcademicYearId },
    }) {
      const modifiedFilters = {
        ...filters,
        academicYears: [selectedAcademicYearId],
      };
      const postFromCache = getParentJournalFeedFromCache({
        filters: modifiedFilters,
        parentId,
      });
      const postObject = _.get(getParentJournalFeed, "node.posts", {});
      const pageInfo = _.get(postObject, "pageInfo", {});

      return {
        getParentJournalFeed,
        hasNextPage: _.get(pageInfo, "hasNextPage", false),
        feedList: _.get(postObject, "edges", []),
        isData: !_.isEmpty(postFromCache),
        networkStatus: getParentJournalFeed.networkStatus,
        totalCount: _.get(postObject, "totalCount", 0),
        isLoading:
          getParentJournalFeed["networkStatus"] == 1 ||
          getParentJournalFeed["networkStatus"] == 2,
        loadMorePosts: () => {
          const endCursor = _.get(pageInfo, "endCursor", null);
          return getParentJournalFeed.fetchMore({
            query: getParentJournalFeedQuery,
            variables: {
              filters: modifiedFilters,
              after: endCursor,
              id: parentId,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousPosts = previousResult.node;
              const newResult = fetchMoreResult.node;

              return {
                ...previousResult,
                node: {
                  ...previousPosts,
                  posts: {
                    ...previousPosts.posts,
                    pageInfo: {
                      ...previousPosts.posts.pageInfo,
                      ...newResult.posts.pageInfo,
                    },
                    edges: [
                      ...previousPosts.posts.edges,
                      ...newResult.posts.edges,
                    ],
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withLoader
)(JournalFeed);
