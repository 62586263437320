import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { I18nHOC, UIModal, FullScreenLoader } from "UIComponents";
import classes from "./AddToCommunityButton.scss";
import {
  AddToCommunity,
  JoinCommunityModal,
  ContributeSuccessModal,
} from "AppComponents/Community";

import { compose } from "react-apollo";
import { connect } from "react-redux";
import { checkCommunityActiveStatusAndGoToOnboarding } from "Community/modules/CommunityModule";
import ACLStore from "lib/aclStore";
import * as EventTracker from "lib/eventTracker";

let eventData = {};

const AddToCommunityButton = (
  {
    entityId,
    entityType,
    checkCommunityActiveStatusAndGoToOnboarding,
    children,
    entityName,
  },
  ref
) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);
  const [showJoinCommunityModal, setShowJoinCommunityModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal: ({ eventTarget, entity_id, entity_type } = {}) => {
      setAddCommunityModalVisibility(true);
      eventData = {
        target: eventTarget,
        entity_id,
        entity_type,
        source: "community",
      };
      // console.log("event data>>>", eventData);
      EventTracker.recordEvent({
        eventName: "Clicked contribute",
        eventData,
      });
    },
  }));
  const setAddCommunityModalVisibility = async (value, e) => {
    if (e) {
      e.stopPropagation();
    }

    if (value) {
      setShowFullScreenLoader(true);

      const isActiveCommunity = await checkCommunityActiveStatusAndGoToOnboarding(
        {
          isForced: true,
          openOnBoarding: false,
          isNetworkOnly: true,
        }
      );
      setShowFullScreenLoader(false);
      if (!isActiveCommunity) {
        setShowJoinCommunityModal(true);
      } else {
        setModalVisibility(value);
      }
    } else {
      setModalVisibility(false);
      eventData = {};
    }
  };

  const onCloseJoinCommunityModal = () => {
    setShowJoinCommunityModal(false);
    eventData = {};
  };

  if (!ACLStore.can("TeacherPortal:Community")) {
    return null;
  }

  return (
    <Fragment>
      {children ? (
        <div
          className={classes.container}
          onClick={e => setAddCommunityModalVisibility(true, e)}
        >
          {children}
        </div>
      ) : null}
      {modalVisibility && (
        <UIModal
          modalContent={classes.modalContent}
          isOpen={true}
          onRequestClose={e => setAddCommunityModalVisibility(false, e)}
        >
          <AddToCommunity
            entityId={entityId}
            entityType={entityType}
            setAddCommunityModalVisibility={setAddCommunityModalVisibility}
            setSuccessModal={setSuccessModal}
            entityName={entityName}
            eventTarget={_.get(eventData, "target")}
          />
        </UIModal>
      )}
      {showSuccessModal && (
        <ContributeSuccessModal
          setSuccessModal={setSuccessModal}
          entityId={entityId}
          entityType={entityType}
        />
      )}
      {showFullScreenLoader && <FullScreenLoader />}
      {showJoinCommunityModal && (
        <JoinCommunityModal
          onClose={onCloseJoinCommunityModal}
          eventData={eventData}
        />
      )}
    </Fragment>
  );
};

const mapActionCreators = {
  checkCommunityActiveStatusAndGoToOnboarding,
};

export default compose(
  connect(null, mapActionCreators, null, {
    withRef: true,
  }),
  I18nHOC({ resource: ["common"] })
)(forwardRef(AddToCommunityButton));
