import React, { useState, useRef, Fragment } from "react";
import {
  UIModal,
  UIButton,
  I18nHOC,
  ProgressSteps,
  FullScreenLoader,
} from "UIComponents";
import classes from "./EntitySelectionModal.scss";
import { colors } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import AssessmentSelection from "../AssessmentSelection";
import UnitSelection from "../UnitSelection";
import StepHeader from "../StepHeader";
import GradeOrCourseSelection from "../GradeOrCourseSelection";
import AddToCommunity from "../Community/AddToCommunity";
import { CancelIcon, LeftIcon, ToddleIcon } from "SvgComponents";
import { setToastMsg } from "Login/modules/LoginModule";

const customStyles = {
  unitSelection: {
    itemFeedContainer: {
      gridTemplateColumns: "repeat(auto-fill, 302px)",
    },
    feedContainer: {
      padding: 0,
    },
  },
  gradeOrCourseSelection: {
    list: {
      gridTemplateColumns: "repeat(3, 320px)",
      justifyContent: "center",
    },
  },
  cancelButton: {
    marginRight: "16px",
  },
};

const EntitySelectionModal = ({
  t,
  setEntitySelectionModal,
  steps,
  mode,
  handleSubmit,
  shouldGroupScheduledFeed,
  portalType,
  setToastMsg,
  isFullScreen,
  modalTitle,
  unitPlanSelectionProps,
}) => {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [data, setData] = useState({});
  const [
    selectedUnitCurriculumProgramId,
    setSelectedUnitCurriculumProgramId,
  ] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const handleProceed = async () => {
    if (isLastStep()) {
      try {
        const currentStep = steps[currentStepIdx];
        const { validateFromComponent } = currentStep;
        if (validateFromComponent && ref.current.isInValid()) {
          setToastMsg({
            msg: "toastMsgs:rectify_errors_with_count_plural",
            // locale_params: [
            //   { key: "count", value: errorCount, isPlainText: true }
            // ],
            type: "alert",
            position: "toast-top-center",
          });
          return;
        }

        setLoading(true);
        const result = await handleSubmit(
          data,
          selectedUnitCurriculumProgramId
        );
        if (result) {
          setEntitySelectionModal(false);
          setData({});
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else goToNextStep();
  };

  const handleCancel = () => {
    setEntitySelectionModal(false);
    setData({});
  };

  const isLastStep = () => currentStepIdx === steps.length - 1;

  const goToPreviousStep = () =>
    setCurrentStepIdx(prev => (prev > 0 ? prev - 1 : 0));

  const goToNextStep = () =>
    setCurrentStepIdx(prev =>
      prev < steps.length - 1 ? prev + 1 : steps.length - 1
    );

  const gotToStep = idx => setCurrentStepIdx(idx);

  const updateState = updatedState =>
    setData(prev => ({ ...prev, ...(updatedState || {}) }));

  const updateSelectedItems = (
    unitId,
    templateid,
    title,
    subtitle,
    image,
    curriculumProgramId
  ) => {
    updateState({ [key]: unitId });
    setSelectedUnitCurriculumProgramId(curriculumProgramId);
  };

  const currentStepData = () => {
    const currentStep = steps[currentStepIdx];
    const { key, value: stepValue, props = {} } = currentStep;

    switch (stepValue) {
      case "UNIT_SELECTION": {
        return (
          <UnitSelection
            updateSelectedItems={updateSelectedItems}
            styles={customStyles.unitSelection}
            cardDimensions={{ width: 304, height: 280 }}
            selectedItems={data[key] || ""}
            shouldGroupScheduledFeed={shouldGroupScheduledFeed}
            grades={data.gradeId ? [data.gradeId] : []}
            portalType={portalType}
            {...unitPlanSelectionProps}
          />
        );
      }
      case "LE_SELECTION":
        return (
          <AssessmentSelection
            unitPlanId={data["unitId"]}
            styles={customStyles.unitSelection}
            updateSelectedItems={leId =>
              updateState({
                [key]: leId,
              })
            }
            selectedItems={data[key]}
            portalType={portalType}
          />
        );
      case "CONTRIBUTE_TO_COMMUNITY": {
        const { entityId, entityType } = props;
        return (
          <AddToCommunity
            entityId={data[entityId]}
            entityType={entityType}
            updateState={updateState}
            showFooter={false}
            portalType={portalType}
            ref={node => (ref.current = node)}
          />
        );
      }
      case "GRADE_SELECTION": {
        return (
          <GradeOrCourseSelection
            styles={customStyles.gradeOrCourseSelection}
            handleSelect={props => {
              updateState({
                [key]: props["gradeId"],
                courseId: props["courseId"],
              });
              goToNextStep();
            }}
            portalType={portalType}
          />
        );
      }
    }
  };

  const currentStep = steps[currentStepIdx];
  const { styles = {}, key } = currentStep;
  const disableNextButton = key && _.isEmpty(data[key]);

  return (
    <UIModal
      isFullScreen={isFullScreen}
      modalContent={isFullScreen ? {} : classes.modalContent}
      isOpen={true}
    >
      <div onClick={e => e.stopPropagation()} className={classes.container}>
        <Header
          steps={steps}
          mode={mode}
          currentStepIdx={currentStepIdx}
          goToPreviousStep={goToPreviousStep}
          handleCancel={handleCancel}
          currentStep={currentStep}
          t={t}
          isFullScreen={isFullScreen}
          modalTitle={modalTitle}
          gotToStep={gotToStep}
          disableNextButton={disableNextButton}
          setToastMsg={setToastMsg}
        />

        <div className={classes.body} style={{ ...(styles.body || {}) }}>
          {currentStepData()}
        </div>

        <Footer
          currentStep={currentStep}
          handleCancel={handleCancel}
          handleProceed={handleProceed}
          isLastStep={isLastStep}
          data={data}
          t={t}
          isFullScreen={isFullScreen}
          disableNextButton={disableNextButton}
        />
      </div>
      {loading && <FullScreenLoader />}
    </UIModal>
  );
};

const Header = ({
  steps,
  mode,
  currentStepIdx,
  goToPreviousStep,
  handleCancel,
  currentStep,
  t,
  isFullScreen,
  modalTitle,
  gotToStep,
  disableNextButton,
  setToastMsg,
}) => {
  const isIntermediateStep = steps.length > 1 && currentStepIdx !== 0;
  const { label: title, styles = {} } = currentStep;

  const onClickStep = step => {
    if (step === currentStep["value"]) return;
    const matchedStepIdx = _.findIndex(steps, ["value", step]);
    if (matchedStepIdx < currentStepIdx) gotToStep(matchedStepIdx);
    else {
      if (disableNextButton) {
        setToastMsg({
          msg: "toastMsgs:please_select_atleast_one_item_error",
          type: "alert",
          position: "toast-top-center",
        });
      } else {
        gotToStep(matchedStepIdx);
      }
    }
  };

  return (
    <Fragment>
      {isFullScreen ? (
        <div className={classes.headerTabContainer}>
          <div className={classes.headerTabRow}>
            <div className={classes.leftIconContainer} onClick={handleCancel}>
              <div className={classes.headerTabBackButton}>
                <LeftIcon width={12} height={12} />
              </div>
              <div className={classes.logo}>
                <ToddleIcon />
              </div>
            </div>
            <div
              className={classes.headerTab}
              style={{ ...(styles.header || {}) }}
            >
              {modalTitle}
            </div>
          </div>
          {_.get(steps, "length", 0) > 1 && (
            <StepHeader
              steps={_.map(steps, step => ({ ...step, label: t(step.label) }))}
              currentStep={currentStep["value"]}
              onClickStep={onClickStep}
            />
          )}
        </div>
      ) : (
        <Fragment>
          {_.get(steps, "length", 0) && mode === "ADD" && (
            <div className={classes.progressBarContainer}>
              <ProgressSteps
                steps={steps.map((_, i) => i)}
                currentStep={currentStepIdx}
                minHeight={4}
              />
            </div>
          )}

          {isIntermediateStep && (
            <div className={classes.backButton} onClick={goToPreviousStep}>
              <LeftIcon width={12} height={12} fill={colors.blue29} />
              <span className={classes.backButtonText}>{t("common:back")}</span>
            </div>
          )}

          <div className={classes.cancelIcon} onClick={handleCancel}>
            <CancelIcon width={16} height={24} />
          </div>

          {!!title && (
            <div
              className={classes.header}
              style={{ ...(styles.header || {}) }}
            >
              {t(title)}
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const Footer = ({
  currentStep,
  handleCancel,
  handleProceed,
  isLastStep,
  t,
  data,
  isFullScreen,
  disableNextButton,
}) => {
  const { FooterContent, buttonLabel, showFooter = true, key } = currentStep;

  return (
    <Fragment>
      {showFooter && (
        <div className={classes.footer}>
          {FooterContent ? <FooterContent /> : null}
          <div
            className={classes.buttonContainer}
            style={
              isFullScreen
                ? {
                    padding: "8px 60px",
                    maxWidth: "1160px",
                    justifyContent: "space-between",
                  }
                : { justifyContent: "flex-end" }
            }
          >
            {isFullScreen ? (
              <div className={classes.fullScreenButton} onClick={handleCancel}>
                {t("common:cancel")}{" "}
              </div>
            ) : (
              <UIButton
                containerStyle={customStyles.cancelButton}
                onClick={handleCancel}
                size={"sm"}
              >
                {t("common:cancel")}
              </UIButton>
            )}
            <UIButton
              containerStyle={customStyles.nextButton}
              onClick={handleProceed}
              color="pink"
              isDisabled={disableNextButton}
              size={"sm"}
            >
              {buttonLabel
                ? t(buttonLabel)
                : isLastStep()
                ? t("common:done")
                : t("common:next")}
            </UIButton>
          </div>
        </div>
      )}
    </Fragment>
  );
};

EntitySelectionModal.defaultProps = {
  mode: "ADD",
  steps: [],
  shouldGroupScheduledFeed: false,
  isFullScreen: false,
  unitPlanSelectionProps: {
    //Props for Unit Plan Selection Step
    parentType: "STAFF", //From where you want to fetch unit plans. E.g - COURSE,STAFF
  },
};

const mapStateToProps = state => {
  return {};
};

const mapActionCreators = {
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: ["community", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(EntitySelectionModal);
