import _ from "lodash";
import moment from "moment";
import { pusherAppKey } from "config";

export const SENTRY_URL =
  "https://2cb0b982daf444ae91d6bfbf6da5a9de@sentry.io/1330341";

export const OLD_SERVER_PROD_URL = "https://apis-sls.toddleapp.com";
export const CLOUD_URL = "https://cloud.toddleapp.com";

export const THUMB_URL = `${CLOUD_URL}/thumber/200x200/`;
export const THUMB_URL_CUSTOM = `${CLOUD_URL}/thumber/`;
export const DEFAULT_THUMB_IMAGE = `${CLOUD_URL}/assets/webapp/image_holder.png`;
export const DEFAULT_PROFILE_PIC = `${CLOUD_URL}/assets/webapp/profile_image.png`;
export const DEFAULT_SUBJECT_THUMB_IMAGE = `${CLOUD_URL}/assets/webapp/fm_subject_icns/icon_subjects_generic.png`;

export const DEFAULT_LINK_THUMB_URL =
  "https://cloud.toddleapp.com/assets/images/linkDefaultImage.png";

export const PUSHER_APP_KEY = pusherAppKey;

export const PUSHER_CLUSTER = "ap2";

export const GOOGLE_CLIENT_ID =
  "328967318361-jutd87l49b97g76d2kareu14tqtjp00d.apps.googleusercontent.com";
export const GOOGLE_DEVELOPER_KEY = "AIzaSyDZ23YQu6gpqLi-za6VtmsvtxExhaVkrqU";

export const PDFTRON_LICENSE_KEY =
  "Teacher Tools Private Limited(toddleapp.com):OEM:Toddle Classroom Management module::B+:AMS(20220928):E2A542620437F80A7360B13AC9A2537860616F5C97006A45BB129CFA54F538A6428231F5C7";

export const GRADE_LIST = ["5", "6", "7", "8"];

export const QUIZ_TAGS = {};

export const LOGIN_IMAGES_LIST = [
  `${CLOUD_URL}/assets/webapp/login_page_image_1.jpg`,
  `${CLOUD_URL}/assets/webapp/login_page_image_2.jpg`,
  `${CLOUD_URL}/assets/webapp/login_page_image_3.jpg`,
  `${CLOUD_URL}/assets/webapp/login_page_image_4.jpg`,
];

export const COVER_SHEET_BG_URL = `${CLOUD_URL}/assets/webapp/CoverSheet.png`;
export const RESOURCE_PLACEHOLDER_URL = `${CLOUD_URL}/assets/webapp/icn_LE.png`;
export const MOBILE_SIGNUP_IMAGE = `${CLOUD_URL}/assets/webapp/img_mobile_signup.png`;
export const ACADEMIC_YEAR = [
  { label: "Mid - Term 2017", value: "Mid - Term 2017" },
  { label: "End - Term 2017", value: "End - Term 2017" },
];

export const ChartColor = [
  "#ff7690",
  "#67a7ff",
  "#ffb96e",
  "#65dbc2",
  "#8d8cdf",
  "#121212",
];

export const PERIOD_COLORS = [
  "#e3e0f6",
  "#F3E2F2",
  "#ffe4cc",
  "#E0F2E5",
  "#fff0cc",
  "#f7cccc",
  "#d1ecef",
  "#d3e4ee",
];

export const EventTypeAndColor = {
  whole_school_events: "#ff7690",
  "PYP - GRADE 1- 7": "#ffb96e",
  holidays_and_vacations: "#67a7ff",
  IBDP: "#8d8cdf",
  "GSEB  & GHSEB (8 to 12)": "#fa9f47",
  "CIE - IGCSE 8-10": "#65dbc2",
};

export const ROOMTYPE_TO_COLOR = {
  "Activity Time": "#20B796",
  Arts: "#ff304c",
  "Play 1": "#20B796",
  "Play 2": "#2387FB",
  "Play 3": "#34c240",
  "Play 4": "#ff304c",
  "Play 5": "#FF9421",
  SEL: "#10ACBD",
  Dance: "#414173",
  Gym: "#FFD200",
  Inquiry: "#24CCA8",
  Literacy: "#AFAFB5",
  Math: "#fa9f47",
  Mindfulness: "#0090e0",
  Music: "#6464DC",
  "Outdoor Play": "#999",
};

export const BackgroundGradient = [
  "linear-gradient(-90deg, #7873F5 0%, #FC6076 100%)",
  "linear-gradient(90deg, #FF9944 0%, #FC6076 84%)",
  "linear-gradient(62deg, #66A6FF 0%, #89F7FE 100%)",
  "linear-gradient(-90deg, #8DDAD5 0%, #00CDAC 100%)",
  "linear-gradient(41deg, #FF9944 0%, #F7CE68 100%)",
];

export const YOUTUBE_RESOURCE_KEY = "AIzaSyDfJbtrn7dqPkFOmHfKyk8DdP0fvH0A_0o";

export const ATTACHMENT_TYPE_TO_NAME = {
  jpg: "JPG IMAGE",
  jpeg: "JPEG IMAGE",
  pdf: "PDF",
  png: "PNG IMAGE",
  key: "KEYNOTE",
  pages: "PAGES",
  doc: "DOCUMENT",
  docx: "DOCUMENT",
  numbers: "NUMBERS",
};

export const FILE_TYPE_TO_ATTACHMENT_NAME = {
  "image/jpg": "image",
  "image/jpeg": "image",
  "application/pdf": "attachment",
  "image/png": "image",
};

export const CHART_DATE_FILTER = [
  {
    label: `Last 7 Days`,
    value: {
      startDate: moment(moment().subtract(1, "weeks")).add(1, "days"),
      endDate: moment(),
    },
  },
  {
    label: `Last 15 Days`,
    value: {
      startDate: moment(moment().subtract(2, "weeks")).add(1, "days"),
      endDate: moment(),
    },
  },
  {
    label: `Last Month`,
    value: {
      startDate: moment(moment().subtract(1, "months")).add(1, "days"),
      endDate: moment(),
    },
  },
  {
    label: `Last Year`,
    value: {
      startDate: moment(moment().subtract(1, "years")).add(1, "days"),
      endDate: moment(),
    },
  },
];

export const COLOR_CODING_FOR_MASTERY = {
  0: "#AFAFB5",
  1: "#FFD200",
  2: "#FF9421",
  3: "#5CE2C6",
  4: "#21BB9A",
};

export const UNIT_LIST = {
  1: "Unit 1",
  2: "Unit 2",
  3: "Unit 3",
  4: "Unit 4",
  5: "Unit 5",
  6: "Unit 6",
};

export const MathSVG = {
  "Angles and Parallel Lines": "Angles and Parallel Lines",
  Averages: "Averages",
  Circles: "Circles",
  "Congruency of Triangles": "Congruency of Triangles",
  Constructions: "Constructions",
  Decimals: "Decimals",
  "Equations and Inequations": "Equations and Inequations",
  "Factorisation of Algebraic Expressions":
    "Factorisation of Algebraic Expressions",
  "Factors and Multiples": "Factors and Multiples",
  Formula: "Formula",
  Fractions: "Fractions",
  "Fundamental Concepts of Algebraic Expressions":
    "Fundamental Concepts of Algebraic Expressions",
  "Fundamental Concepts of Geometry": "Fundamental Concepts of Geometry",
  Graphs: "Graphs",
  Numbers: "Numbers",
  Percent: "Percent",
  "Perimeter and Area": "Perimeter and Area",
  "Polygons-Quadrilaterals and their Types":
    "Polygons-Quadrilaterals and their Types",
  "Powers and Roots": "Powers and Roots",
  "Profit and Loss": "Profit and Loss",
  "Ratio and Proportion": "Ratio and Proportion",
  "Sets and Operations on Sets": "Sets and Operations on Sets",
  "Simple Interest": "Simple Interest",
  "Simplification of Algebraic Expressions":
    "Simplification of Algebraic Expressions",
  "Special Products": "Special Products",
  "Symmetry, Reflection and Rotation": "Symmetry, Reflection and Rotation",
  "Tabulation and Representation of Data":
    "Tabulation and Representation of Data",
  Triangle: "Triangle",
  "Unitary Method": "Unitary Method",
  "Venn Diagrams": "Venn Diagrams",
  "Volume and Surface Area": "Volume and Surface Area",
};

export const NEWS_STATUS = [
  { label: "common:draft", value: "DRAFT" },
  { label: "common:published", value: "PUBLISHED" },
];
export const ADMIN_GRADES = ["1", "2", "3", "4", "5", "6", "7", "8"];

export const GRADE_LIST_TODDLE = [
  { value: "PT", label: "Parent Toddler" },
  { value: "PG", label: "Play Group" },
  { value: "NUR", label: "Pre-K" },
  { value: "JKG", label: "K-1" },
  { value: "SKG", label: "K-2" },
];

export const TYPE_TO_COLOR = {
  Inquiry: "#ff304c",
  Arts: "#20B796",
  PD: "#2387FB",
  Literacy: "#34c240",
  SEL: "#ff304c",
  Math: "#FF9421",
};

export const PICKER_DIRECT_DOWNLOADABLE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const PICKER_DEFAULT_IMAGES = {
  DOCS: `${CLOUD_URL}/assets/webapp/docs_new_icn.png`,
  SHEET: `${CLOUD_URL}/assets/webapp/sheets_new_icn.png`,
  SLIDES: `${CLOUD_URL}/assets/webapp/slides_new_icn.png`,
  UNKNOWN: `${CLOUD_URL}/assets/webapp/unknow_new_icn.png`,
  PDF: `${CLOUD_URL}/assets/webapp/pdf_new_icn.png`,
  VIDEO: `${CLOUD_URL}/assets/webapp/video_new_icn1.png`,
  AUDIO: `${CLOUD_URL}/assets/webapp/audio_icn.png`,
  ZIP: `${CLOUD_URL}/assets/webapp/ZIP.png`,
  NOTE: `${CLOUD_URL}/assets/webapp/note_icn.png`,
  EPUB: `${CLOUD_URL}/assets/webapp/epub_icn.png`,
  DRAWING: `${CLOUD_URL}/assets/webapp/drawings_new_icn.png`,
  WORKBOOK: `${CLOUD_URL}/assets/webapp/workbook_outlined.png`,
  IMAGE: `${CLOUD_URL}/assets/webapp/image_holder.png`,
};

export const ATTACHMENT_OMIT_ATTRIBUTES = [
  "__typename",
  "streamUrl",
  "createdBy",
  "similarityReport",
  "isRead",
];

export const ATTACHMENT_INPUT_ATTRIBUTES = [
  "id",
  "type",
  "title",
  "url",
  "name",
  "mimeType",
  "thumbUrl",
  "createdBy",
  "description",
  "metadata",
  "parentId",
  "parentType",
  "studentTemplateId",
  "similarityReport",
];

//Use this object for creation of attachment in cache
export const ATTACHMENT_CREATE_ATTRIBUTES = {
  similarityReport: null,
  isRead: true,
};
