import { filter as _filter } from "lodash";
import { mergeSelectedNodes } from "Utils";
import { getSubjectFromCache } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorGraphqlHelpers";

const getNodeChildrenByType = ({
  nodeType,
  firstLevelNodeIds,
  nodeChildren,
}) => {
  switch (nodeType) {
    case "MYP_ATL":
    case "UBD_ATL":
      return nodeChildren[0].children;
    default:
      return firstLevelNodeIds;
  }
};

export const getCombinedNodes = ({ nodes, subject }) => {
  const firstLevelNodeIds = _filter(nodes, n => n.parent === null).map(
    n => n.id
  );
  const nodeChildren = _.filter(nodes, node => node.id == subject.id);
  //adding Subject as Root Node due the requriment of level Editor component and tree hiearchy

  return [
    {
      ...subject,
      children: getNodeChildrenByType({
        nodeType: subject.type,
        firstLevelNodeIds,
        nodeChildren: nodeChildren,
      }),
      depth: 0,
    },
    ...nodes,
  ];
};

const getTabs = levels => {
  return _.map(levels, item => {
    return { label: item.value || item.label, value: item.id };
  });
};

export const getTabsMemoize = _.memoize(
  params => getTabs(params),
  params => JSON.stringify(params)
);

const getRootNode = ({ sortedRootGrades, rootNode, subjectId }) => {
  return {
    ...(getSubjectFromCache(subjectId) || {}),
    ...rootNode,

    grades: sortedRootGrades,
  };
};

const getRootNodeMemoize = _.memoize(
  params => getRootNode(params),
  params => JSON.stringify(params)
);

const orderBySequenceNodes = nodes => {
  return _.orderBy(nodes, ["displaySequence"], ["asc"]);
};

export const orderBySequenceNodesMemoize = _.memoize(
  params => orderBySequenceNodes(params),
  params => JSON.stringify(params)
);

const getSequenceOfGrades = ({ allGrades, currentGrades }) => {
  return _.filter(
    allGrades,
    option => _.findIndex(currentGrades, item => item.id == option.id) >= 0
  );
};

const getUpdatedRootNode = ({ rootNode, subjectId, organizationGrades }) => {
  const sortedRootGrades = getSequenceOfGrades({
    allGrades: organizationGrades,
    currentGrades: _.get(rootNode, "grades", []),
  });

  return getRootNodeMemoize({
    rootNode,
    sortedRootGrades,
    subjectId,
  });
};

export const getUpdatedRootNodeMemoize = _.memoize(
  params => getUpdatedRootNode(params),
  params => JSON.stringify(params)
);

const updateSelectedNodesType = ({ selectedNodes, rootNode }) => {
  const snsChildren = _.get(rootNode, "snsChildren", []);
  const updatedSelectedNodes = [];
  _.forEach(selectedNodes, item => {
    updatedSelectedNodes.push({
      ...(item || {}),
      type: _.includes(snsChildren, item.type) ? item.type : "LEARNING_OUTCOME",
    });
  });
  return updatedSelectedNodes;
};

export const updateSelectedNodesTypeMemoize = _.memoize(
  params => updateSelectedNodesType(params),
  params => JSON.stringify(params)
);

export const mergeSelectedNodesMemoize = _.memoize(
  params => mergeSelectedNodes(params),
  params => JSON.stringify(params)
);

export const getLevelsProps = ({ sourceType }) => {
  let sourceTypeBasedConfig = {};

  switch (sourceType) {
    case "SNP":
      sourceTypeBasedConfig = {
        mode: "view",
        showEditButton: false,
        showBackButton: false,
        enableBreadCrumbs: false,
      };
      break;
    default:
      sourceTypeBasedConfig = {};
  }

  return sourceTypeBasedConfig;
};

export const addGradesToNodes = ({ nodes }) => {
  return _.map(nodes, ({ associatedParents, ...node }) => ({
    ...node,
    grades: _filter(associatedParents, p => p.__typename === "Grade"),
  }));
};

export const getStartDepth = ({ nodes, rootNode }) => {
  //It will help to filter outline nodes
  let startDepth = _.get(_.first(nodes), "depth", 0);

  if (!_.isNull(_.get(rootNode, "depth", null))) {
    startDepth = _.get(rootNode, "depth", 0) + 1;
  }

  return startDepth;
};

export const getShowOutlineStatus = ({ nodes, startDepth }) => {
  //it counts the nummber of elements with depth 1
  const startDepthCount = _.countBy(nodes, { depth: startDepth }).true;
  //Here we assume that nodes are coming in the sorted order from backend
  const endDepth = _.get(_.last(nodes), "depth", 0);

  const depthDifference = endDepth - startDepth;

  return startDepthCount > 1 && depthDifference > 0;
};
