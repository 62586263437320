import PropTypes from "prop-types";
import React from "react";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { colors, fontStyle } from "Constants";

/**
 *Note: Below is short documentation of PhoneInputField Component
 * 1. react-phone-input-2 allows to type only number (no alphabet,no special digit characters etc...)
 * 2. You need to pass buttonStyle and InputStyle separately because that's how react-phone-input-2 is build
 *    i.e You can customize styles of Button and Input Field differently
 * 3. Only Object Styles are used in this component.
 *    If you want to use class then class should be type "String"(as per below link) which is not possible in our case
 * 4. To add more props refer --> https://www.npmjs.com/package/react-phone-input-2
 */
class PhoneInputField extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.state, code: "" };
  }

  onChange = (phoneValue, { countryCode, dialCode }) => {
    if (!this.state.code) {
      this.updateValue(phoneValue);
    } else {
      this.updateValue(countryCode === this.state.code ? phoneValue : dialCode);
    }
    this.setState({ code: countryCode });
  };

  renderEdit = () => {
    const {
      initialCountry,
      buttonStyle,
      errorButtonStyle,
      inputStyle,
      errorInputStyle,
      value,
      name,
      placeholder,
      containerStyle,
      focusButtonStyle,
      focusInputStyle,
      defaultMask,
      alwaysDefaultMask,
      disableSearchIcon,
      enableSearch,
    } = this.props;
    const { error, isFocused } = this.state;

    // merge errorStyle
    const mergedErrInputStyle = { ...inputStyle, ...errorInputStyle };
    const mergedErrButtonStyle = { ...buttonStyle, ...errorButtonStyle };

    // merge focusStyle
    const mergedFocusInputStyle = { ...inputStyle, ...focusInputStyle };
    const mergedFocusButtonStyle = {
      ...buttonStyle,
      ...focusButtonStyle,
    };

    /**
     * Note: below is explanation of buttonStyle and inputStyle prop of PhoneInput
     * if(error)        show errorStyle   (aka. mergedErr___Style)
     * else if(isFocus) show focusStyle   (aka. mergedFocus___Style)
     * else             show defaultStyle (aka. __Style)
     */

    return (
      <div>
        <PhoneInput
          country={initialCountry}
          defaultMask={defaultMask}
          alwaysDefaultMask={alwaysDefaultMask}
          name={name}
          value={value ? value : ""}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placeholder={placeholder}
          containerStyle={containerStyle}
          buttonStyle={
            error
              ? mergedErrButtonStyle
              : isFocused
              ? mergedFocusButtonStyle
              : buttonStyle
          }
          inputStyle={
            error
              ? mergedErrInputStyle
              : isFocused
              ? mergedFocusInputStyle
              : inputStyle
          }
          disableSearchIcon={disableSearchIcon}
          enableSearch={enableSearch}
        />
      </div>
    );
  };
}

const defaultStyles = {
  containerStyle: {
    width: "100%",
  },
  inputStyle: {
    width: "100%",
    height: "48px",
    backgroundColor: "inherit",
    fontSize: "1.6rem",
    borderColor: colors.strokeTwo,
    borderRadius: "8px",
    ...fontStyle.regular,
  },
  buttonStyle: {
    borderColor: colors.strokeTwo,
    borderRadius: 0,
    borderRight: "none",
    borderLeft: "none",
    marginLeft: "8px",
    width: "48px",
    backgroundColor: colors.white,
  },
  focusStyle: {
    borderColor: colors.blue29,
  },
  errorStyle: {
    borderColor: colors.salmon60,
  },
};

PhoneInputField.defaultProps = {
  ...UIBaseComponent.defaultProps,
  initialCountry: "in",
  containerStyle: defaultStyles.containerStyle,
  buttonStyle: defaultStyles.buttonStyle,
  inputStyle: defaultStyles.inputStyle,
  errorButtonStyle: defaultStyles.errorStyle,
  errorInputStyle: defaultStyles.errorStyle,
  focusButtonStyle: defaultStyles.focusStyle,
  focusInputStyle: defaultStyles.focusStyle,
  defaultMask: ".................",
  alwaysDefaultMask: true,
  disableSearchIcon: true,
  enableSearch: false,
};

PhoneInputField.propTypes = {
  ...UIBaseComponent.propTypes,
  initialCountry: PropTypes.string,
  containerStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  errorButtonStyle: PropTypes.object,
  errorInputStyle: PropTypes.object,
  focusButtonStyle: PropTypes.object,
  focusInputStyle: PropTypes.object,
  defaultMask: PropTypes.string,
  alwaysDefaultMask: PropTypes.bool,
  disableSearchIcon: PropTypes.bool,
  enableSearch: PropTypes.bool,
};

export default PhoneInputField;
