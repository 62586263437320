import PropTypes from "prop-types";
import React from "react";

const LockIconV2 = props => {
  const { width, height, fill } = props;
  return (
    <svg viewBox="0 0 18 18" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.3332C0 9.28041 2.30706 6.6665 5.30769 6.6665H11.9423C14.9429 6.6665 17.25 9.28041 17.25 12.3332C17.25 15.3859 14.9429 17.9998 11.9423 17.9998H5.30769C2.30706 17.9998 0 15.3859 0 12.3332ZM5.30769 7.99984C3.13333 7.99984 1.32692 9.91926 1.32692 12.3332C1.32692 14.7471 3.13333 16.6665 5.30769 16.6665H11.9423C14.1167 16.6665 15.9231 14.7471 15.9231 12.3332C15.9231 9.91926 14.1167 7.99984 11.9423 7.99984H5.30769Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56354 1.33333C5.93957 1.33333 4.64431 2.63486 4.64431 4.26667V7.33333C4.64431 7.70152 4.34726 8 3.98084 8C3.61442 8 3.31738 7.70152 3.31738 7.33333V4.26667C3.31738 1.89848 5.20673 0 7.56354 0H9.68661C12.0434 0 13.9328 1.89848 13.9328 4.26667V7.33333C13.9328 7.70152 13.6357 8 13.2693 8C12.9029 8 12.6058 7.70152 12.6058 7.33333V4.26667C12.6058 2.63486 11.3106 1.33333 9.68661 1.33333H7.56354Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62521 13.3335C8.9918 13.3335 9.28899 13.632 9.28899 14.0002V15.3335C9.28899 15.7017 8.9918 16.0002 8.62521 16.0002C8.25861 16.0002 7.96143 15.7017 7.96143 15.3335V14.0002C7.96143 13.632 8.25861 13.3335 8.62521 13.3335Z"
        fill={fill}
      />
    </svg>
  );
};

LockIconV2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

LockIconV2.defaultProps = {
  height: 16,
  width: 16,
  fill: "#484848",
};

export default LockIconV2;
