import PropTypes from "prop-types";
import React from "react";

const InfoTextIcon = props => {
  const { width, height, fill } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 4 10">
      <path
        d="M2 0.625C2.51777 0.625 2.9375 1.04473 2.9375 1.5625C2.9375 2.08027 2.51777 2.5 2 2.5C1.48223 2.5 1.0625 2.08027 1.0625 1.5625C1.0625 1.04473 1.48223 0.625 2 0.625Z"
        fill={fill}
      />
      <path
        d="M3.25 9.375C3.59518 9.375 3.875 9.09518 3.875 8.75C3.875 8.40482 3.59518 8.125 3.25 8.125H2.625L2.625 4.375C2.625 4.02982 2.34518 3.75 2 3.75H0.75C0.404822 3.75 0.125 4.02982 0.125 4.375C0.125 4.72018 0.404822 5 0.75 5H1.375L1.375 8.75C1.375 9.09518 1.65482 9.375 2 9.375H3.25Z"
        fill={fill}
      />
    </svg>
  );
};

InfoTextIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

InfoTextIcon.defaultProps = {
  height: 10,
  width: 4,
  fill: "#222222",
};

export default InfoTextIcon;
