import update from "immutability-helper";
import { replace, goBack, go } from "react-router-redux";
import {
  updateCurrentScreen,
  fetchInvitedUserByToken,
  fetchInvitedParentByToken,
  verifyClassInviteLinkAndNavigateToCreateParent,
  authenticateUserWithServer,
  logoutUser,
  setActiveTab,
  setInviteToCommunityStatus,
  setRetentionModalStatus,
  switchChild,
  setProductUpdateDirectLinkOpen,
  setHelpCenterVideoDirectLinkOpen,
  updateInvitedFamilyData,
  familyInviteTokenSignIn,
  handleAcademicYearForParent,
  setAuthRegion,
} from "Login/modules/LoginModule";
import { getChildrenFromCache } from "modules/CommonGraphqlHelpers";
import {
  getAdminParentNodeMemoize,
  getAdminNode,
} from "Administrator/modules/AdministratorModule";
import { goToRelativeRoute, openLinkInNewTab } from "modules/Services";
import { setCurrentCurriculumProgram } from "Platform/modules/PlatformModule";
import request from "superagent";
import ACLStore from "lib/aclStore";
import {
  isTouchableV2,
  goToAppStore,
  getQueryString,
  getBackendServerUrl,
} from "Utils";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
} from "Constants/stringConstants";
import { LEX_SSO_URL } from "Constants";
import { getUserAcadYearConstantsMemoize } from "Login/components/ChildrenPage/Utils";
import { ROUTE_TYPES } from "AppComponents/GlobalSearchBar/components/Utils";

import { getProjectGroupTypeFromId } from "Projects/modules/ProjectModules";

export const NAME = "navigation";

export const UPDATE_NAVIGATION_OBJECT = "UPDATE_NAVIGATION_OBJECT" + " " + NAME;
export const SET_LOCATION_BEFORE_ON_BOARDING =
  "SET_LOCATION_BEFORE_ON_BOARDING" + " " + NAME;

export const CAMPAIGN_KEYWORDS = [
  { key: "utm_source", value: "direct" },
  { key: "utm_medium" },
  { key: "utm_campaign" },
  { key: "utm_content" },
  { key: "utm_term" },
];

export const updateNavigationObject = data => {
  return { type: UPDATE_NAVIGATION_OBJECT, data };
};

export const setLocationBeforeOnBoarding = data => {
  return { type: SET_LOCATION_BEFORE_ON_BOARDING, payload: data };
};

export const getCurrentTab = () => {
  return (dispatch, getState) => {
    const currentTabArray = getState().router.locationBeforeTransitions.pathname.split(
      "/"
    );
    return currentTabArray[currentTabArray.length - 1];
  };
};

export const handleQueryParameters = ({ query = {} } = {}) => {
  return (dispatch, getState) => {
    campaignParams({ query });
    if (_.isEmpty(query)) {
      return;
    }
    const { metadata } = query;

    let metadataObj = {};
    try {
      metadataObj = metadata ? JSON.parse(metadata) : {};
    } catch (e) {
      metadataObj = {};
    }

    dispatch(
      navigate({
        params: { ...query, metadata: metadataObj },
        navigateType: "replace",
      })
    );
  };
};

export const campaignParams = ({ query = {} }) => {
  const params = {};
  const first_params = {};
  _.forEach(CAMPAIGN_KEYWORDS, campaign => {
    const { value, key } = campaign;
    const queryValue = query[key] ? query[key] : value;
    if (queryValue) {
      params[`${key} [last touch]`] = queryValue;
      first_params[`${key} [first touch]`] = queryValue;
    }
  });

  localStorage.campaignParams = JSON.stringify({ params, first_params });
};

export const getPathFromFullPath = (full_path = "") => {
  const path_arr = _.split(full_path, "/");
  const path = _.last(path_arr);

  return path;
};

export const getQueryUpdatedQueryString = ({
  url,
  params,
  paramsToBeRemoved = [],
}) => {
  if (url) {
    //paramsToBeRemoved refers to array of paramKeys that need to be removed from url
    const currentQueryString = _.replace(url, "?", "");
    const currenQueryArray = _.split(currentQueryString, "&");

    let currenQueryParams = {};
    _.forEach(currenQueryArray, arrValue => {
      const paramValue = _.split(arrValue, "=");
      if (_.get(paramValue, "length", 0) > 1) {
        currenQueryParams = {
          ...(currenQueryParams || {}),
          [paramValue[0]]: paramValue[1],
        };
      }
    });

    params = { ...(currenQueryParams || {}), ...(params || {}) };
  }
  const nullKeys = _.filter(
    Object.keys(params || {}),
    key => !_.get(params, key, "") || _.includes(paramsToBeRemoved, key)
  );

  params = _.omit(params, [...nullKeys]);

  const queryString = _.join(
    _.map(params, (val, key) => `${key}=${val}`),
    "&"
  );
  return queryString;
};

/**
 * @deprecated: This function isn't used anymore.
 * To Handle old /home routes and replace it with curriculum program type
 */
export const replaceCurrentHomePathWitCurriculumType = ({
  curriculumProgramType,
  currentCurriculumProgramType,
}) => {
  return (dispatch, getState) => {
    const currentLocation = window.location.pathname;
    if (currentCurriculumProgramType == "home") {
      const updatedLocation = _.replace(
        currentLocation,
        "platform/home",
        `platform/${curriculumProgramType}`
      );
      dispatch(
        goToRelativeRoute({
          route: updatedLocation,
          type: "replace",
        })
      );
    } else {
      dispatch(goToPlatformHomeRoute({ curriculumProgramType }));
    }
  };
};

export const updateQueryParameters = ({
  params,
  navigateType = "push",
  paramsToBeRemoved,
}) => {
  return (dispatch, getState) => {
    const queryString = getQueryUpdatedQueryString({
      url: _.get(window, "location.search", ""),
      params,
      paramsToBeRemoved,
    });

    dispatch(
      goToRelativeRoute({ route: `?${queryString}`, type: navigateType })
    );
  };
};

const goToSnpChat = ({ metadata, dispatch, getState }) => {
  const chatType = _.get(metadata, "item", "");
  const reviewStageId = _.get(metadata, "review_stage_id", "");
  const categoryGroupId = _.get(metadata, "category_group_id", "");
  const documentCategoryId = _.get(metadata, "document_category_id", "");
  const conversationParentId = _.get(metadata, "conversationParentId", "");

  const cycleId = _.get(metadata, "cycleId", "");

  const conversationId = _.get(metadata, "conversationId", "");

  const roles = ACLStore.getPermissionsArray();

  const isAdmin = _.includes(roles, "AdminPortal");

  dispatch(
    updateNavigationObject({
      snpChatDetails: {
        conversationId,
        reviewStageId,
        categoryGroupId,
        documentCategoryId,
        parentType: chatType,
        conversationParentId,
      },
    })
  );

  const adminSchoolDocumentsRoute = `snp_v2/cycles/${cycleId}/view/school-documents`;
  const adminEvidenceDashboardRoute = `snp_v2/cycles/${cycleId}/view/evidence-dashboard`;
  const teacherEvidenceDashboardRoute = `snp_v2/cycles/${cycleId}/view/evidence-dashboard`;
  const visitorEvidenceDashboardRoute = `platform/snp/cycles/${cycleId}/view/evidence-dashboard`;
  const visitorSchoolDocumentsRoute = `platform/snp/cycles/${cycleId}/view/school-documents`;

  switch (chatType) {
    case "SNP_STANDARD_SCHOOL_INTERNAL": {
      if (isAdmin) {
        dispatch(goToAdminChildRoute({ route: adminEvidenceDashboardRoute }));
      } else {
        const curriculumProgramId = _.get(
          metadata,
          "curriculum_program_id",
          ""
        );

        const currentCurriculumType = _.get(
          getState(),
          "platform.currentCurriculumProgramType",
          CURRICULUM_TYPE_PYP
        );

        const curriculumPrograms = _.get(
          getState(),
          "platform.organizationCurriculumPrograms",
          []
        );

        const currentCurriculumProgramId = _.get(
          _.find(curriculumPrograms, {
            type: currentCurriculumType,
          }),
          "id",
          null
        );

        /**Can't use strict inequality as one of the variables can be number sometimes, and other is always string*/
        if (currentCurriculumProgramId != curriculumProgramId) {
          const currentCurriculumProgram =
            _.find(curriculumPrograms, ({ id }) => id == curriculumProgramId) ??
            {};

          dispatch(setCurrentCurriculumProgram(currentCurriculumProgram));
        }
        dispatch(
          goToPlatformHomeChildRoute({ route: teacherEvidenceDashboardRoute })
        );
      }
      break;
    }
    case "SNP_DOCUMENT_SCHOOL_VISITING": {
      if (isAdmin) {
        dispatch(goToAdminChildRoute({ route: adminSchoolDocumentsRoute }));
      } else {
        dispatch(goToRoute({ route: visitorSchoolDocumentsRoute }));
      }
      break;
    }
    case "SNP_STANDARD_SCHOOL_VISITING": {
      if (isAdmin) {
        dispatch(goToAdminChildRoute({ route: adminEvidenceDashboardRoute }));
      } else {
        dispatch(goToRoute({ route: visitorEvidenceDashboardRoute }));
      }
      break;
    }
    case "SNP_DOCUMENT_VISITING_INTERNAL": {
      dispatch(goToRoute({ route: visitorSchoolDocumentsRoute }));
      break;
    }
    case "SNP_STANDARD_VISITING_INTERNAL": {
      dispatch(goToRoute({ route: visitorEvidenceDashboardRoute }));
    }
  }
};

export const navigate = ({
  params,
  navigateType = "push",
  callback,
  target,
} = {}) => {
  return (dispatch, getState) => {
    let type = _.toUpper(_.get(params, "type", ""));
    const metadata = _.get(params, "metadata", {});
    const userType = _.get(params, "usertype", "staff");
    const userLoggedIn = getState().login.userLoggedIn;

    // for FamilyPortal notification's take userType from redux
    const isParent =
      _.get(getState(), "login.userInfo.user_type", "") === "parent";

    // for student classroom revamp take userType from redux
    const isStudent =
      _.get(getState(), "login.userInfo.user_type", "") === "student";

    //get code from redirect uri -- clever will return code
    const code = _.get(params, "code", "");
    const scope = _.get(params, "scope");

    if (code && _.includes(scope, "district_admins")) {
      type = "CLEVER_LOGIN";
    }

    if (
      code &&
      _.includes(scope, "openid") &&
      !_.includes(scope, "googleapis")
    ) {
      type = "LEX_LOGIN";
    }

    if (_.get(params, "source", null) === "lex") {
      window.location.href = LEX_SSO_URL;
    }

    if (code && _.get(params, "source", false)) {
      if (params.source.includes("IRMAK_SCHOOL")) {
        type = "IRMAK_LOGIN";
      }
    }

    if (code && _.includes(window.location.href, "classlink")) {
      type = "CLASSLINK_LOGIN";
    }

    const {
      authenticatedRoute = false,
      studentId,
      taskId,
      parentId,
      assignmentId,
      studentAssignmentId,
      assessmentId,
      conversationType,
      unitPlanId,
      chatId,
      conversationId,
      entityId,
      entityType,
      itemId,
      item_id,
      projectGroupId,
      course_id,
      parentResourceId,
      parentResourceType,
      sprId,
      courseId,
      portfolioId,
      curriculumType,
      curriculumProgramId,
    } = metadata || {};

    if (curriculumProgramId && curriculumType) {
      dispatch(
        setCurrentCurriculumProgram({
          id: curriculumProgramId,
          type: curriculumType,
        })
      );
    }

    const mobileDevice = isTouchableV2();
    const queryString = getQueryString(params);

    if ((!authenticatedRoute || !!getState().login.userInfo.id) && type) {
      switch (type) {
        case "COLLABORATOR":
          dispatch(
            goToUnitPlanDetail({
              unitPlanId: metadata.unitPlanId,
              courseId: metadata.courseId,
              navigateType,
            })
          );
          break;
        case "UNIT_PLAN_SHARED":
        case "UNIT_PLAN":
          dispatch(
            goToUnitPlanDetail({
              unitPlanId: metadata.unitPlanId,
              courseId: metadata.courseId,
              navigateType,
              mode: "view",
            })
          );
          break;

        case "NAVIGATE_TO_UNIT_PLAN":
          dispatch(
            goToPlatformHomeFeatureRoute({ ...metadata, route: "unitPlans" })
          );
          break;

        case "IB_PYP":
        case "IB_MYP":
        case "UBD":
        case "COURSE":
        case "HOME_ANNOUNCEMENT_MODULE":
        case "HOME_ATTENDANCE_MODULE":
        case "HOME_CLASSROOM_MODULE":
        case "HOME_CALENDAR_MODULE":
        case "HOME_FAMILY_MESSAGING_MODULE":
        case "ADMIN_PANEL_MODULE":
        case "ADMIN_SCHOOL_SETUP_MODULE":
        case "ADMIN_ACADEMIC_SETUP_MODULE":
        case "ADMIN_BIN_MODULE":
        case "ADMIN_ANNOUNCEMENT_MODULE":
        case "ADMIN_UNIT_PLANNING_MODULE":
        case "ADMIN_REPORT_MODULE":
        case "ADMIN_EDUCATION_CENTER_MODULE":
        case "ADMIN_PLANNING_INSIGHT_MODULE":
        case "ADMIN_SCHOOL_CALENDAR_MODULE":
        case "ADMIN_SETTINGS_MODULE":
        case "ADMIN_AUTH_EVALUATION":
        case "HOME_AUTH_EVALUATION":
        case "WEEKLY_SCHEDULE_PYP_MODULE":
        case "WEEKLY_SCHEDULE_MYP_MODULE":
        case "WEEKLY_SCHEDULE_UBD_MODULE":
        case "PLANNING_INSIGHTS_MODULE":
        case "SUBJECT_STANDARDS_MODULE":
        case "CLASSROOM_MODULE":
        case "JOURNAL_MODULE":
        case "CONTINUUM_MODULE_MYP":
        case "CONTINUUM_MODULE_PYP":
        case "CONTINUUM_MODULE_UBD":
        case "CALENDAR_MODULE":
        case "ATTENDANCE_MODULE":
        case "REPORT_MODULE":
        case "CPS_MODULE":
        case "TEACHER_RESOURCES_MODULE":
        case "UNIT_LIBRARY_MODULE":
        case "LE_LIBRARY_MODULE":
        case "STUDENT_PROFILE_MODULE":
        case "ASSES_LEARNING_MODULE":
        case "TEACHER_POLICIES_MODULE":
        case "TEACHER_NOTIFICATION_SETTINGS_MODULE":
        case "MANAGE_FAMILY_MODULE":
        case "ATL_MODULE":
        case "CONCEPT_INSIGHT_MODULE":
        case "THEME_DESCRIPTOR_MODULE":
        case "LPA_INSIGHT_MODULE":
        case "SUBJECT_INSIGHT_MODULE":
        case "CLASS_SETTINGS_MODULE":
        case "POI_MODULE":
          dispatch(switchBetweenModules({ metadata }));
          break;

        case "LEARNING_ENGAGEMENT":
          dispatch(
            goToResourceDetails({
              id: metadata.leId,
              type: "le",
              unitPlanId: metadata.unitPlanId,
              courseId: metadata.courseId,
              navigateType,
            })
          );
          break;

        case "LE":
        case "ASSESSMENT":
          dispatch(
            goToResourceDetails({
              id: metadata.assessmentId,
              type: "assessment",
              unitPlanId: metadata.unitPlanId,
              courseId: metadata.courseId,
              navigateType,
            })
          );
          break;

        case "COMMENT":
          dispatch(openComment({ metadata, navigateType, isParent }));
          break;

        case "SIGNUP":
          if (_.isEmpty(metadata)) {
            dispatch(
              updateCurrentScreen({
                type: "organizationSelection",
                navigateType: "replace",
              })
            );
          } else {
            dispatch(goToInviteSignUp({ metadata }));
          }
          break;

        case "INVITE_FAMILY":
          if (mobileDevice) {
            // redirect to family app
            setTimeout(goToAppStore, 20);

            window.location = encodeURI(
              `toddle-family://login/?${queryString}`
            );
          } else {
            //if user already logged in as teacher/student/parent --> logout user
            dispatch(updateInvitedFamilyData(params));
            if (userLoggedIn) {
              logoutUser({ redirectUri: window.location.href });
            }

            dispatch(setAuthRegion(_.get(params, "region", null)));

            if (_.isEmpty(metadata)) {
              dispatch(
                updateCurrentScreen({
                  type: "organizationSelection",
                  navigateType: "replace",
                })
              );
            } else {
              if (_.isEmpty(metadata.login_token)) {
                dispatch(goToParentInviteSignUp({ metadata }));
              } else {
                // magic link sign in
                dispatch(
                  familyInviteTokenSignIn({ login_token: metadata.login_token })
                );
              }
            }
          }
          break;
        case "FAMILY_CLASS_INVITE":
          if (mobileDevice) {
            // redirect to family app
            setTimeout(goToAppStore, 20);

            window.location = encodeURI(
              `toddle-family://login/?${queryString}`
            );

            return;
          }

          if (userLoggedIn && !isParent) {
            logoutUser({ redirectUri: window.location.href });
          }

          dispatch(setAuthRegion(_.get(params, "region", null)));

          dispatch(updateInvitedFamilyData(params));
          if (!_.isEmpty(metadata.login_token)) {
            dispatch(
              familyInviteTokenSignIn({
                login_token: metadata.login_token,
                isClassInvite: true,
              })
            );
          } else if (!_.isEmpty(metadata.token)) {
            dispatch(goToParentInviteSignUp({ metadata }));
          } else {
            dispatch(
              verifyClassInviteLinkAndNavigateToCreateParent({
                link: window.location.href,
                paramsToBeRemoved: ["metadata"],
              })
            );
          }
          break;
        case "APPROVE_TEACHER":
        case "REJECT_TEACHER":
          dispatch(goToAbsoluteAdminChildNode({ childId: "TEACHER_SETUP" }));
          break;

        case "SHOW_UNIT_PLAN":
          dispatch(getUnitPlanIdFromPreset({ metadata }));
          break;

        case "POST":
          dispatch(
            updateNavigationObject({
              postDetail: {
                rightPane: "POST_DETAIL",
                rightPaneId: metadata.parentId.toString(),
                currentTab: "PUBLISHED",
                courseId: isParent ? _.get(metadata, "courseId", null) : null,
              },
            })
          );
          if (isParent && !ACLStore.can("Common:EnableCoursesForFamily")) {
            dispatch(
              handleParentNotification({
                studentId,
                route: "journal",
              })
            );
          } else {
            dispatch(
              goToPlatformHomeFeatureRoute({ ...metadata, route: "journal" })
            );
          }
          break;

        case "POST_APPROVAL_WITH_COMMENT":
          metadata.parentType = "POST";
          metadata.item_id = metadata.postId;
          dispatch(openComment({ metadata, navigateType, isParent }));
          break;

        case "POST_LIKE":
          dispatch(
            updateNavigationObject({
              postDetail: {
                rightPane: "POST_DETAIL",
                rightPaneId: metadata.postId,
                currentTab: "PUBLISHED",
              },
            })
          );
          dispatch(
            goToPlatformHomeFeatureRoute({ ...metadata, route: "journal" })
          );
          break;

        case "POST_REJECT_WITH_COMMENT":
        case "POST_APPROVAL":
          dispatch(
            updateNavigationObject({
              postDetail: {
                currentTab: "IN_APPROVAL",
              },
            })
          );

          dispatch(
            goToPlatformHomeFeatureRoute({ ...metadata, route: "journal" })
          );
          break;

        case "FAMILY_CONNECTED":
          break;

        case "GOAL_CREATED":
        case "GOAL_MILESTONE_COMPLETED":
        case "GOAL_COMPLETED":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `studentPortfolio/${studentId}/goals`,
            })
          );
          break;

        case "STUDENT_ASSESSMENT_EVALUATION": {
          const {
            assessmentEvaluationId,
            studentAssessmentEvaluationId,
          } = metadata;

          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `assessmentEvaluation/${assessmentEvaluationId}/${studentAssessmentEvaluationId}`,
            })
          );
          break;
        }

        case "PROGRESS_REPORT_EVALUTATION":
        case "PROGRESS_REPORT_RE_EVALUTATION":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `progressreport${taskId ? `/${taskId}` : ``}`,
            })
          );
          break;

        case "PROGRESS_REPORT":
          dispatch(
            handleParentNotification({
              studentId,
              route: `progress-report${sprId ? `/${sprId}` : ``}`,
            })
          );
          break;

        case "TEACHER_RESOURCE":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `teacherresources${parentId ? `/${parentId}` : ``}`,
            })
          );
          break;

        case "TEACHER_SCHOOL_POLICY":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `teacherpolicies${parentId ? `/${parentId}` : ``}`,
            })
          );
          break;

        case "STUDENT_PROFILE":
        case "PARENT":
        case "STUDENT_ADDED":
        case "STUDENT_ADDED_TO_PARENT":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `studentPortfolio/${studentId}`,
            })
          );
          break;

        case "SEND_INVITATION":
          dispatch(updateNavigationObject({ showInvitationBox: true }));
          dispatch(goToPlatformHomeChildRoute({ route: "courses" }));
          break;

        case "CIRCULAR_ADDED":
          dispatch(
            goToPlatformHomeChildRoute({
              ...metadata,
              route: `circulars${parentId ? `/${parentId}` : ``}`,
            })
          );

          break;
        case "ASSIGNMENT_MESSAGE_SENT":
          {
            const classroomRevampRoute = `classroom/classroom-details/${assignmentId}/${studentAssignmentId}/?notificationType="ASSIGNMENT_MESSAGE"`;
            dispatch(
              goToPlatformHomeFeatureRoute({
                ...metadata,
                route: isStudent
                  ? classroomRevampRoute
                  : `classroom/classroom-details/${assignmentId}/${studentAssignmentId}`,
              })
            );
          }
          break;
        case "ASSIGNMENT_GROUP_MESSAGE_SENT": {
          const classroomRevampRoute = `classroom/classroom-details/${assignmentId}/${studentAssignmentId}/?notificationType="ASSIGNMENT_GROUP_MESSAGE"`;
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: isStudent
                ? classroomRevampRoute
                : `classroom/classroom-details/${assignmentId}/classdiscussion`,
            })
          );
          break;
        }
        case "CHAT_MESSAGE_SENT":
          dispatch(
            goToPlatformHomeChildRoute({
              ...metadata,
              route: `familyconversations/${chatId}`,
            })
          );

          break;
        case "STUDENT_ASSIGNMENT_SUBMISSION":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `classroom/classroom-details/${assignmentId}/${studentAssignmentId}`,
            })
          );
          break;
        case "STUDENT_ASSIGNMENT_SUBMISSION_FEEDBACK":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `classroom/classroom-details/${item_id}/response`,
            })
          );
          break;
        case "ALL_STUDENT_ASSIGNMENT_SUBMISSION":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `classroom/classroom-details/${assignmentId}/allsubmissions`,
            })
          );
          break;
        case "STUDENT_ASSIGNMENT_SHARED":
        case "ASSIGNMENT_SHARED_WITH_STUDENT":
        case "ASSIGNMENT_CREATE":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route:
                metadata.contentType == "ASSIGNMENT_MEETING"
                  ? "classroom"
                  : `classroom/classroom-details/${item_id}`,
            })
          );
          break;
        case "ZOOM_MEETING_CREATE":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `classroom`,
            })
          );
          break;
        case "ENTITY_RATING":
        case "ENTITY_LIKE":
          dispatch(
            goToCommunityEntity({
              entityId,
              entityType,
              isOpenInNewTab: false,

              params: { target: target },
            })
          );

          break;

        case "COMMUNITY_COMMENT_REPLY":
        case "COMMUNITY_COMMENT":
          dispatch(
            goToCommunityEntity({
              entityId,
              entityType,
              isOpenInNewTab: false,
              isFullView: true,
              childRoute: "comments",
              params: { target: target, section: "comments" },
            })
          );
          break;

        case "URL_LOGIN": {
          const userLoggedIn = _.get(getState(), "login.userLoggedIn");
          if (!userLoggedIn) {
            dispatch(
              authenticateUserWithServer({
                idToken: metadata.token,
                method: "url",
                userType,
              })
            );
          }
          break;
        }

        case "CLEVER_LOGIN": {
          const userLoggedIn = _.get(getState(), "login.userLoggedIn");
          if (userLoggedIn) {
            logoutUser({
              redirectionOption: "integration_sso",
              redirectUri: encodeURI(window.location.href),
            });
          }
          dispatch(
            authenticateUserWithServer({
              idToken: code,
              method: "clever",
              redirectUri: encodeURI(window.location.origin),
            })
          );

          break;
        }

        case "LEX_LOGIN": {
          const userLoggedIn = _.get(getState(), "login.userLoggedIn");
          if (userLoggedIn) {
            logoutUser({
              redirectionOption: "integration_sso",
              redirectUri: encodeURI(window.location.href),
            });
          }

          dispatch(
            authenticateUserWithServer({
              idToken: code,
              method: "lex",
              redirectUri: encodeURI(window.location.origin),
            })
          );

          break;
        }

        case "CLASSLINK_LOGIN": {
          const userLoggedIn = _.get(getState(), "login.userLoggedIn");
          if (userLoggedIn) {
            logoutUser({
              redirectionOption: "integration_sso",
              redirectUri: encodeURI(window.location.href),
            });
          }
          dispatch(
            authenticateUserWithServer({
              idToken: code,
              method: "classlink",
              userType: "staff",
              redirectUri: encodeURI(window.location.origin),
            })
          );
          break;
        }

        case "IRMAK_LOGIN": {
          const userLoggedIn = _.get(getState(), "login.userLoggedIn");
          if (userLoggedIn) {
            logoutUser({
              redirectionOption: "integration_sso",
              redirectUri: encodeURI(window.location.href),
            });
          }
          dispatch(
            authenticateUserWithServer({
              idToken: _.get(params, "code"),
              method: "irmak",
              source: _.get(params, "source"),
              redirectUri: encodeURI(window.location.origin),
            })
          );

          break;
        }

        case "INVITE_TO_COMMUNITY":
          dispatch(updateQueryParameters({ params: { type: undefined } }));
          dispatch(setInviteToCommunityStatus(true));
          break;
        case "HELP_CENTER_VIDEO": {
          const videoId = _.get(params, "videoId");
          dispatch(setHelpCenterVideoDirectLinkOpen(videoId));
          break;
        }
        case "PRODUCT_UPDATES": {
          const productId = _.get(params, "productId");
          dispatch(setProductUpdateDirectLinkOpen(productId));
          break;
        }
        case "RETENTION_MAILER":
          dispatch(setRetentionModalStatus(true));
          break;

        case "CALENDAR_EVENT_ITEM":
          dispatch(
            updateNavigationObject({
              calendarEventModal: { id: item_id },
            })
          );
          break;
        case "STUDENT_ASSIGNMENT_SUBMISSION_UNHAND":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `classroom/classroom-details/${item_id}/response`,
            })
          );
          break;
        case "PERSONAL_PROJECT_STATUS_UPDATE":
        case "COMMUNITY_PROJECT_STATUS_UPDATE":
        case "PERSONAL_PROJECT_INVITATION":
        case "COMMUNITY_PROJECT_INVITATION":
        case "SERVICE_AS_ACTION_INVITATION":
        case "PERSONAL_PROJECT_REPORT_STATUS_UPDATE":
        case "COMMUNITY_PROJECT_REPORT_STATUS_UPDATE":
        case "SERVICE_AS_ACTION_ACTIVITY_APPROVAL":
        case "SERVICE_AS_ACTION_IN_PROGRESS":
        case "SERVICE_AS_ACTION_IN_PROGRESS_AFTER_APPROVAL":
        case "SERVICE_AS_ACTION_REVIEW_REQUESTED":
        case "SERVICE_AS_ACTION_COMPLETED":
        case "SERVICE_AS_ACTION_PENDING_FOR_APPROVAL":
        case "PERSONAL_PROJECT_DEADLINE":
        case "COMMUNITY_PROJECT_DEADLINE":
        case "NAVIGATE_TO_PROJECT":
        case "DP_PROJECT_DEADLINE":
        case "PROJECT_APPROVED":
        case "PROJECT_MARKED_AS_COMPLETED":
        case "PROJECT_OVERALL_STATUS_CHANGED":
        case "PROJECT_SUPERVISEE_ASSIGNED":
        case "PROJECT_SUPERVISOR_ASSIGNED":
        case "PROJECT_DEADLINE_REMINDER":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `progress/${itemId}`,
            })
          );
          break;
        case "PROJECT_COMMENT_MENTION":
          dispatch(openComment({ metadata, navigateType, isParent }));
          break;

        case "SNP_CHAT": {
          goToSnpChat({ metadata, dispatch, getState });
          break;
        }

        case "FILE_RESOURCE_SHARED_WITH_STUDENT":
        case "FOLDER_RESOURCE_SHARED_WITH_STUDENT":
        case "MULTIPLE_RESOURCE_SHARED_WITH_STUDENT":
          dispatch(
            goToPlatformHomeFeatureRoute({
              ...metadata,
              route: `student-drive${
                parentResourceType == "FOLDER" ? `/${parentResourceId}` : ""
              }`,
            })
          );
          break;
        case "CAS_ADVISOR_ASSIGNED":
        case "CAS_ADVISEE_ASSIGNED":
        case "CAS_MARKED_AS_COMPLETE":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `portfolios`,
            })
          );
          break;
        case "CAS_ADVISOR_FEEDBACK_ADDED":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `portfolios/${portfolioId}/interviews`,
            })
          );
          break;
        case "CAS_LO_ACHIEVED":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `portfolios/${portfolioId}/evidences`,
            })
          );
          break;
        case "CAS_PROJECT_APPROVAL_REQUESTED":
        case "CAS_PROJECT_APPROVED":
        case "CAS_PROJECT_REJECTED":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `portfolios/${portfolioId}/studentPortfolio/${itemId}`,
            })
          );
          break;
        case "EE_SUPERVISOR_ASSIGNED":
        case "EE_PROPOSAL_APPROVAL_REQUESTED":
        case "EE_PROPOSAL_APPROVED":
        case "EE_MARKED_AS_COMPLETE":
        case "TOK_EXHIBITION_SUPERVISOR_ASSIGNED":
        case "TOK_ESSAY_SUPERVISOR_ASSIGNED":
        case "TOK_EXHIBITION_PROPOSAL_APPROVED":
        case "TOK_ESSAY_PROPOSAL_APPROVED":
        case "TOK_EXHIBITION_MARKED_AS_COMPLETE":
        case "TOK_ESSAY_MARKED_AS_COMPLETE":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `progress/${itemId}`,
            })
          );
          break;
        case "EE_FINAL_ESSAY_SUBMITTED":
        case "TOK_ESSAY_FINAL_DRAFT_SUBMITTED":
        case "TOK_EXHIBITION_FINAL_DRAFT_SUBMITTED":
        case "PROJECT_FINAL_REPORT_SUBMITTED":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `progress/${itemId}/report`,
            })
          );
          break;
        case "EE_REFLECTION_MARKED_AS_COMPLETE":
        case "TOK_ESSAY_INTERACTION_MARKED_AS_COMPLETE":
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId,
              route: `progress/${itemId}/rppf`,
            })
          );
          break;

        case "ATTENDANCE_REMINDER": {
          dispatch(goToAttendanceRoute({ params }));
          break;
        }

        //no redirection needed for these notifications
        case "PROJECT_REMOVED_AS_COLLABORATOR": {
          break;
        }

        //Should remove this default
        default:
          dispatch(updateCurrentScreen({ ...params }));
      }
    }
    if (callback) {
      callback();
    }
  };
};

export const handleParentNotification = ({ studentId = "", route = "" }) => {
  return async (dispatch, getState) => {
    const childID = _.get(getState(), "login.userInfo.childID", "");
    const userId = _.get(getState(), "login.userInfo.id", "");

    /** __FamilyPortal__
     *  childID: current active child Id
     *  studentId: child Id associated with notification
     */
    if (childID != studentId && !_.isNil(studentId)) {
      const childrenFromCache = getChildrenFromCache({
        id: userId,
        type: "FAMILY_MEMBER",
      });
      const allChildren = _.get(childrenFromCache, "children", []);

      const childData = _.find(allChildren, {
        id: _.toString(studentId),
      });

      if (_.isEmpty(childData)) {
        dispatch(
          goToPlatformHomeChildRoute({
            route: route,
          })
        );
        return;
      }

      // switch child
      dispatch(switchChild({ childData }));

      const allCourses = _.get(childData, "allCourses");
      const orgAcademicYears = _.get(
        childData,
        "organization.academicYears",
        {}
      );
      const organizationCurriculumPrograms = _.get(
        childData,
        "organization.curriculumPrograms",
        []
      );

      const { currentAcadYearObj } = getUserAcadYearConstantsMemoize({
        allCourses,
        orgAcademicYears,
        organizationCurriculumPrograms,
      });

      const academicYearCurriculumProgram = !_.isEmpty(
        currentAcadYearObj.curriculumPrograms
      )
        ? currentAcadYearObj.curriculumPrograms
        : childData.curriculumPrograms;

      const academicYearCurriculumProgramId = _.get(
        academicYearCurriculumProgram,
        "[0].id"
      );

      const routePath = `/platform/${academicYearCurriculumProgramId}/${route}`;

      dispatch(
        goToRelativeRoute({
          route: routePath,
        })
      );

      dispatch(handleAcademicYearForParent());
    } else {
      dispatch(
        goToPlatformHomeChildRoute({
          route: route,
        })
      );
    }
  };
};

export const goToLoginHomeScreen = ({ params, isOpenInNewTab }) => {
  return async (dispatch, getState) => {
    const { type } = params || {};
    const queryString = getQueryUpdatedQueryString({ url: "", params });
    const routePath = `/${queryString ? `?${queryString}` : ``}`;
    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
        })
      );
      dispatch(
        updateCurrentScreen({
          type,
          navigateType: "replace",
        })
      );
    }
  };
};

export const goToAttendanceRoute = ({ params }) => {
  return async (dispatch, getState) => {
    const curriculumProgramId = _.get(
      params,
      "course.curriculumProgram.id",
      ""
    );
    const courseId = _.get(params, "course.id", "");

    const date = _.get(params, "metadata.date", "");

    dispatch(
      updateNavigationObject({
        attendanceDate: date,
      })
    );

    dispatch(
      goToRelativeRoute({
        route: `platform/${curriculumProgramId}/courses/${courseId}/attendance`,
        type: "replace",
        replacePath: "platform",
      })
    );
  };
};

export const getUnitPlanIdFromPreset = ({ metadata }) => {
  return async (dispatch, getState) => {
    const { presetId } = metadata;
    const token = _.get(JSON.parse(localStorage.userInfo), "token", "");
    const result = await request
      .post(
        getBackendServerUrl({
          token,
          path: `/auth/sample/unit/fetch`,
        })
      )
      .send({
        presetId,
        token,
      });
    if (result) {
      const {
        body: { course_id, unit_plan_id },
      } = result;

      dispatch(
        goToUnitPlanDetail({
          courseId: course_id,
          unitPlanId: unit_plan_id,
          navigateType: "replace",
          mode: "view",
        })
      );
    }
  };
};
export const goToInviteSignUp = ({ metadata }) => {
  return (dispatch, getState) => {
    const { token } = metadata || {};

    if (token) {
      dispatch(fetchInvitedUserByToken({ token }));
    }
  };
};

export const goToParentInviteSignUp = ({ metadata }) => {
  return (dispatch, getState) => {
    const { token, inviteCode } = metadata || {};

    if (token) {
      dispatch(fetchInvitedParentByToken({ token, inviteCode }));
    }
  };
};

export const goToUnitPlanDetail = ({
  unitPlanId,
  courseId,
  navigateType,
  mode = "edit",
  isOpenInNewTab,
}) => {
  return (dispatch, getState) => {
    dispatch(
      goToPlatformHomeFeatureRoute({
        courseId,
        navigateType,
        route: `unitPlans/${unitPlanId}/${mode}`,
        isOpenInNewTab,
      })
    );
  };
};

export const goToStudentAssessmentEvaluation = ({
  studentAssessmentEvaluationId,
  assessmentEvaluationId,
  courseId,
  navigateType,
}) => {
  return (dispatch, getState) => {
    dispatch(
      goToPlatformHomeFeatureRoute({
        courseId,
        navigateType,
        route: `assessmentEvaluation/${assessmentEvaluationId}/${studentAssessmentEvaluationId}`,
      })
    );
  };
};

export const goToResourceDetails = ({
  id,
  unitPlanId,
  type,
  courseId,
  navigateType,
  isOpenInNewTab,
}) => {
  return (dispatch, getState) => {
    dispatch(
      goToPlatformHomeFeatureRoute({
        courseId,
        navigateType,
        isOpenInNewTab,
        route: `unitPlans/${unitPlanId}/${type}/${id}/view`,
      })
    );
  };
};

export const switchBetweenModules = ({ metadata }) => {
  return (dispatch, getState) => {
    let endPoint = _.get(metadata, "endPoint", "");
    const type = _.get(metadata, "routeType", "HOMEROUTE");
    const curriculumProgram = _.get(metadata, "curriculumProgram", null);

    const curriculumPrograms = getState().platform
      .organizationCurriculumPrograms;

    if (type === ROUTE_TYPES.HOMEROOTROUTE) {
      const organizationCurriculumPrograms = getState().platform
        .organizationCurriculumPrograms;
      const curriculumProgram = _.find(organizationCurriculumPrograms, {
        type: endPoint,
      });
      dispatch(
        goToPlatformHomeRoute({ curriculumProgramId: curriculumProgram.id })
      );
    } else if (type === ROUTE_TYPES.HOMEROUTE) {
      dispatch(goToPlatformHomeChildRoute({ route: endPoint }));
    } else if (type === ROUTE_TYPES.ADMINROUTE) {
      const curriculumProgramId = _.get(
        _.find(curriculumPrograms, {
          type: curriculumProgram,
        }),
        "id",
        null
      );

      endPoint = curriculumProgram
        ? `${curriculumProgramId}/${endPoint}`
        : endPoint;

      dispatch(goToAdminChildRoute({ route: endPoint }));
    } else if (type === ROUTE_TYPES.FEATUREROUTE) {
      dispatch(goToPlatformHomeFeatureRoute({ ...metadata, route: endPoint }));
    }
  };
};

export const openComment = ({
  metadata,
  navigateType,
  isParent = false,
} = {}) => {
  return (dispatch, getState) => {
    const parentType = _.toUpper(_.get(metadata, "parentType", ""));
    switch (parentType) {
      case "ASSESSMENT":
        dispatch(updateNavigationObject({ showAssessmentCommentBox: true }));
        dispatch(
          goToResourceDetails({
            id: metadata.parentId,
            type: "assessment",
            unitPlanId: metadata.unitPlanId,
            courseId: metadata.courseId,
            navigateType,
          })
        );
        break;
      case "LEARNING_ENGAGEMENT":
        dispatch(updateNavigationObject({ showLeCommentBox: true }));
        dispatch(
          goToResourceDetails({
            id: metadata.parentId,
            type: "le",
            unitPlanId: metadata.unitPlanId,
            courseId: metadata.courseId,
            navigateType,
          })
        );
        break;
      case "FIELD":
        dispatch(
          updateNavigationObject({
            unitPlanCommentBox: {
              showUnitPlanCommentBox: true,
              fieldId: metadata.parentId,
            },
            unitPlanStepRoute: {
              fieldId: metadata.parentId,
            },
          })
        );
        dispatch(
          goToUnitPlanDetail({
            unitPlanId: metadata.unitPlanId,
            courseId: metadata.courseId,
            navigateType,
          })
        );
        break;

      case "POST":
        dispatch(
          updateNavigationObject({
            postDetail: {
              rightPane: "COMMENT",
              rightPaneId: metadata.item_id,
              courseId: isParent ? _.get(metadata, "course_id", null) : null,
            },
          })
        );

        if (isParent && !ACLStore.can("Common:EnableCoursesForFamily")) {
          dispatch(
            handleParentNotification({
              studentId: _.get(metadata, "student_id", ""),
              route: "journal",
            })
          );
        } else {
          dispatch(
            goToPlatformHomeFeatureRoute({
              courseId: _.get(metadata, "course_id", ""),
              route: "journal",
            })
          );
        }

        break;
      case "PROJECT":
      case "STUDENT_PROJECT_PORTFOLIO":
        if (!_.isEmpty(_.get(metadata, "projectGroupType", ""))) {
          switch (metadata.projectGroupType) {
            case "PERSONAL":
            case "COMMUNITY":
            case "SERVICE_AS_ACTION":
              {
                dispatch(
                  updateNavigationObject({
                    projectCommentBox: {
                      showProjectCommentBox: true,
                      fieldId: metadata.parentId,
                    },
                  })
                );
                dispatch(
                  goToPlatformHomeProjectFeatureRoute({
                    projectGroupId: metadata.projectGroupId,
                    route: `progress/${metadata.itemId}`,
                  })
                );
              }
              break;

            case "DP_CAS":
              dispatch(
                goToPlatformHomeProjectFeatureRoute({
                  projectGroupId: metadata.projectGroupId,
                  route: `portfolios/${metadata.portfolioId}/studentPortfolio/${metadata.itemId}/process-journal`,
                })
              );
              break;

            case "DP_EE":
            case "DP_TOK_EXHIBITION":
            case "DP_TOK_ESSAY":
              dispatch(
                goToPlatformHomeProjectFeatureRoute({
                  projectGroupId: metadata.projectGroupId,
                  route: `progress/${metadata.itemId}/process-journal`,
                })
              );
              break;
          }
        } else {
          dispatch(
            goToPlatformHomeProjectFeatureRoute({
              projectGroupId: metadata.projectGroupId,
              route: `progress/${metadata.itemId}`,
            })
          );
        }
        break;
    }
  };
};

export const _doTransitionBasedOnRoles = ({
  userType,
  curriculumProgramId,
}) => {
  return (dispatch, getState) => {
    const roles = ACLStore.getPermissionsArray();

    if (
      _.includes(roles, "TeacherPortal") ||
      _.includes(roles, "StudentPortal") ||
      userType === "parent"
    ) {
      dispatch(
        goToPlatformHomeRoute({
          curriculumProgramId,
          navigateType: "replace",
        })
      );
    } else if (_.includes(roles, "AdminPortal")) {
      dispatch(goToAdminRoute());
    } else if (_.includes(roles, "VisitorPortal")) {
      dispatch(goToRoute({ route: "platform/snp" }));
    }
  };
};

export const goToAbsoluteAdminChildNode = ({ childId }) => {
  return (dispatch, getState) => {
    const parentNode = getAdminParentNodeMemoize({ childId });
    const childNode = getAdminNode(childId);

    const route = `/platform/administrator/${parentNode.route}/${childNode.route}`;

    if (childNode && parentNode) {
      dispatch(
        goToRelativeRoute({
          route,
        })
      );
    }
  };
};

export const goToClassRoster = ({ isOpenInNewTab = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentCurriculumProgramId = _.get(
      state,
      "platform.currentCurriculumProgram.id"
    );
    const childId = "COURSE_SETUP";
    const parentNode = getAdminParentNodeMemoize({ childId });
    const childNode = getAdminNode(childId);

    const route = `/platform/administrator/${parentNode.route}/${childNode.route}/${currentCurriculumProgramId}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: route }));
    } else if (childNode && parentNode) {
      dispatch(
        goToRelativeRoute({
          route,
        })
      );
    }
  };
};

export const goToAdminRoute = ({ navigateType = "push" } = {}) => {
  return (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({
        route: `/platform/administrator`,
        type: navigateType,
      })
    );
  };
};

export const goToAdminChildRoute = ({
  route,
  isOpenInNewTab = false,
  navigateType = "push",
}) => {
  return (dispatch, getState) => {
    const routePath = `/platform/administrator/${route}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
          type: navigateType,
        })
      );
    }
  };
};

export const goToToolRoute = ({ navigateType = "push" } = {}) => {
  return dispatch => {
    dispatch(
      goToRelativeRoute({ route: "/platform/regions", type: navigateType })
    );
  };
};

export const navigateToToolsPortal = () => {
  return dispatch => {
    dispatch(goToToolRoute());
  };
};

export const goToPlatformHomeRoute = ({
  navigateType = "push",
  curriculumProgramId,
} = {}) => {
  return (dispatch, getState) => {
    const currentCurriculumProgramType = getState().platform
      .currentCurriculumProgramType;
    const organizationCurriculumPrograms = getState().platform
      .organizationCurriculumPrograms;

    const currentCurriculumProgram = _.find(organizationCurriculumPrograms, {
      type: currentCurriculumProgramType,
    });

    dispatch(
      goToRelativeRoute({
        route: `/platform/${
          curriculumProgramId ?? currentCurriculumProgram?.id
        }`,
        type: navigateType,
      })
    );
  };
};

export const goToPlatformHomeChildRoute = ({
  route,
  isOpenInNewTab,
  navigateType = "push",
}) => {
  return (dispatch, getState) => {
    const { currentCurriculumProgram } = getState().platform;

    const routePath = `/platform/${currentCurriculumProgram.id}/${route}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
          type: navigateType,
        })
      );
    }
  };
};

export const goToPlatformHomeFeatureRoute = ({
  courseId,
  route,
  navigateType = "push",
  isOpenInNewTab,
  course_id,
}) => {
  return (dispatch, getState) => {
    const courseIdToRedirect =
      courseId || course_id
        ? courseId || course_id
        : getState().teacher
        ? getState().teacher.selected_class.selected_course
        : "";
    if (!courseIdToRedirect) {
      return;
    }

    const currentCurriculumProgram = getState().platform
      .currentCurriculumProgram;

    const routePath = `/platform/${currentCurriculumProgram.id}/courses/${courseIdToRedirect}/${route}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
          type: navigateType,
        })
      );
    }
  };
};

export const goToPlatformHomeProjectFeatureRoute = ({
  projectGroupId,
  route,
  navigateType = "push",
  isOpenInNewTab,
}) => {
  return async (dispatch, getState) => {
    const curriculumProgramType = dispatch(
      getCurriculumTypeFromProjectGroupId({
        projectGroupId,
      })
    );

    const organizationCurriculumPrograms = getState().platform
      .organizationCurriculumPrograms;
    const curriculumProgram = _.find(organizationCurriculumPrograms, {
      type: curriculumProgramType,
    });

    const routePath = `/platform/${curriculumProgram.id}/projects/${projectGroupId}/${route}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
          type: navigateType,
        })
      );
    }
  };
};

export const goToCommunityRoute = ({ route, isOpenInNewTab }) => {
  return (dispatch, getState) => {
    const routePath = `/community/home/${route}`;
    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
        })
      );
    }
  };
};

export const goToRoute = ({ route, isOpenInNewTab }) => {
  return (dispatch, getState) => {
    const routePath = `/${route}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
        })
      );
    }
  };
};

export const goToCommunityEntity = ({
  entityType,
  entityId,
  isOpenInNewTab = true,
  params,
  isFullView,
  childRoute,
}) => {
  return (dispatch, getState) => {
    let routePath = "";
    let url = "";
    switch (entityType) {
      case "UNIT_PLAN":
        if (isFullView) {
          url = `/community/home/featured/all/${entityId}/view${
            childRoute ? `/${childRoute}` : ``
          }`;
        } else {
          url = `/community/home/featured/all/unitpreview/${entityId}`;
        }
        break;
      case "ASSESSMENT":
        url = `/community/home/featured/all/assessment/${entityId}/view`;
        break;
      case "LE_COLLECTION":
        url = `/community/home/featured/all/collection/${entityId}`;
        break;
    }
    const queryString = getQueryUpdatedQueryString({ url, params });
    routePath = `${url}${queryString ? `?${queryString}` : ``}`;
    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
        })
      );
    }
  };
};

export const goToBack = () => {
  return (dispatch, getState) => {
    dispatch(goBack());
  };
};

export const goToIndex = index => {
  return (dispatch, getState) => {
    dispatch(go(index));
  };
};

export const goToCommunityOnboarding = ({
  saveLocation = true,
  isOpenInNewTab = false,
  search = "",
} = {}) => {
  return async (dispatch, getState) => {
    if (saveLocation) {
      dispatch(
        setLocationBeforeOnBoarding(
          _.get(getState(), "router.locationBeforeTransitions.pathname", "")
        )
      );
    }
    const route = `/community/onboarding${search}`;
    if (isOpenInNewTab) {
      dispatch(
        openLinkInNewTab({
          url: route,
        })
      );
    } else {
      dispatch(replace(route));
    }
    if (!isOpenInNewTab) {
      dispatch(setActiveTab("community"));
    }
  };
};

export const goToSnpHomeChildRoute = ({
  route,
  isOpenInNewTab,
  navigateType = "push",
}) => {
  return (dispatch, getState) => {
    const routePath = `/platform/snp/${route}`;

    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: routePath }));
    } else {
      dispatch(
        goToRelativeRoute({
          route: routePath,
          type: navigateType,
        })
      );
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_NAVIGATION_OBJECT]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        navigationObject: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [SET_LOCATION_BEFORE_ON_BOARDING]: (state, action) => {
    return update(state, {
      locationBeforeOnboarding: { $set: action.payload },
    });
  },
};

const initialState = {
  navigationObject: {
    showLeCommentBox: null,
    showAssessmentCommentBox: null,
    unitPlanCommentBox: null,
    unitPlanStepRoute: null,
    postDetail: null,
    chatBox: null,
    calendarEventModal: null,
    projectCommentBox: null,
    snpChatDetails: null,
    attendanceDate: null,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const getCurriculumTypeFromProjectGroupId = ({ projectGroupId }) => {
  return (dispatch, getState) => {
    const projectGroupType = getProjectGroupTypeFromId({
      state: getState(),
      projectGroupId,
    });

    const projectGroups = _.get(getState(), "login.userInfo.projectGroups", []);

    const curriculumType = _.get(
      _.find(projectGroups, { type: projectGroupType }),
      "curriculumProgram.type",
      CURRICULUM_TYPE_MYP
    );

    return curriculumType;
  };
};
