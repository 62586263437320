import PropTypes from "prop-types";
import React from "react";
import { colors } from "Constants";

const AlertIcon = props => {
  return (
    <svg viewBox="0 0 16 15" width={props.width} height={props.height}>
      <g stroke="none" strokeWidth="1" fill={props.fill} fillRule="evenodd">
        <g transform="translate(-1194.000000, -801.000000)">
          <path d="M1202.0006,802.5332 C1201.8726,802.5332 1201.5553,802.5679 1201.3646,802.8999 L1195.4344,813.1698 C1195.2437,813.5018 1195.3704,813.7937 1195.4344,813.9044 C1195.4984,814.0151 1195.689,814.2724 1196.0717,814.2724 L1207.9295,814.2724 C1208.3121,814.2724 1208.5014,814.0151 1208.5654,813.9044 C1208.6294,813.7937 1208.7574,813.5018 1208.5654,813.1698 L1202.6379,802.8999 C1202.4459,802.5679 1202.1286,802.5332 1202.0006,802.5332 Z M1207.9295,815.6056 L1196.0717,815.6056 C1195.3224,815.6056 1194.6531,815.219 1194.2798,814.5697 C1193.9065,813.9231 1193.9065,813.1498 1194.2811,812.5032 L1200.21,802.2333 C1200.5834,801.5866 1201.2526,801.2 1202.0006,801.2 C1202.7485,801.2 1203.4178,801.5866 1203.7925,802.2333 L1209.72,812.5032 C1210.0933,813.1511 1210.0933,813.9244 1209.72,814.571 C1209.3467,815.219 1208.6774,815.6056 1207.9295,815.6056 Z M1202.1138,810.5067 C1201.7458,810.5067 1201.4472,810.208 1201.4472,809.84 L1201.4472,806.1696 C1201.4472,805.8017 1201.7458,805.503 1202.1138,805.503 C1202.4817,805.503 1202.7804,805.8017 1202.7804,806.1696 L1202.7804,809.84 C1202.7804,810.208 1202.4817,810.5067 1202.1138,810.5067 Z M1202.9136,812.0612 C1202.9136,812.5025 1202.5549,812.8611 1202.1136,812.8611 C1201.6723,812.8611 1201.3137,812.5025 1201.3137,812.0612 C1201.3137,811.6199 1201.6723,811.2613 1202.1136,811.2613 C1202.5549,811.2613 1202.9136,811.6199 1202.9136,812.0612 Z" />
        </g>
      </g>
    </svg>
  );
};

AlertIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

AlertIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: colors.yellow50,
};

export default AlertIcon;
