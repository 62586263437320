import React, { useState } from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";

import classes from "./ProductUpdatesModal.scss";
import { UIModal, I18nHOC } from "UIComponents";
import { Button, IconButton } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";

import {
  goToPlatformHomeChildRoute,
  updateQueryParameters,
} from "modules/NavigationModule";
import {
  setProductUpdateDirectLinkOpen,
  setProductUpdateModalOpen,
} from "Login/modules/LoginModule";

import {
  handleVote,
  resetProductUpdateNewContent,
  getProductUpdatesFilters,
} from "ProductUpdates/modules/ProductUpdatesModules";
import {
  getProductUpdatesQuery,
  getProductUpdateQuery,
} from "ProductUpdates/modules/ProductUpdatesQueries";

import ProductUpdatesItem from "../ProductUpdatesItem";

import * as EventTracker from "lib/eventTracker";

const PRODUCT_UPDATES = "PRODUCT_UPDATES";
const NEW_PRODUCT_UPDATES = "NEW_PRODUCT_UPDATES";

const ProductUpdatesModal = props => {
  const resetProductUpdateNewContentMutation = () => {
    const { resetProductUpdateNewContent, variables, filters } = props;
    resetProductUpdateNewContent({ variables, filters });
  };

  const onCloseModalClick = () => {
    const {
      productUpdateModalId,
      setProductUpdateDirectLinkOpen,
      updateQueryParameters,
      currentCurriculumProgramType,
      productUpdates,
      setProductUpdateModalOpen,
    } = props;

    if (productUpdateModalId) {
      updateQueryParameters({
        params: { type: undefined, productId: undefined },
      });
    }
    resetProductUpdateNewContentMutation();

    EventTracker.recordEvent({
      eventName: productUpdateModalId
        ? "Product Updates"
        : "New Product Updates",
      eventData: {
        entity_id: _.head(productUpdates)?.id,
        entity_name: _.head(productUpdates)?.title,
        entity_type: productUpdateModalId
          ? PRODUCT_UPDATES
          : NEW_PRODUCT_UPDATES,
        active_curriculum_program: currentCurriculumProgramType,
      },
    });

    setProductUpdateDirectLinkOpen(false);
    setProductUpdateModalOpen(false);
  };

  const onViewAllUpdatesClick = () => {
    const { goToPlatformHomeChildRoute } = props;

    goToPlatformHomeChildRoute({ route: "product-updates" });
    onCloseModalClick();
  };

  // render functions
  const renderHeader = () => {
    const { t } = props;
    return (
      <div className={classes.headerContainer}>
        <div className={classes.leftHeaderWrapper}>
          <div className={classes.headerText}>{t("common:whats_new")}</div>
        </div>
        <div className={classes.rightHeaderWrapper}>
          <Button
            onClick={onViewAllUpdatesClick}
            variant={"outlined-subtle"}
            size={"small"}
            className={classes.rightHeaderElement}
          >
            {t("common:see_all_updates")}
          </Button>
          <IconButton
            onClick={onCloseModalClick}
            variant={"outlined-subtle"}
            size={"small"}
            icon={<CloseOutlined />}
            className={classes.rightHeaderElement}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const { handleVote, productUpdates } = props;

    return (
      <div className={classes.content}>
        {_.map(productUpdates, productUpdate => {
          return (
            <div className={classes.contentInnerContainer}>
              <ProductUpdatesItem
                item={productUpdate}
                source="MODAL"
                handleVote={handleVote}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderModal = () => {
    return (
      <UIModal
        isOpen={true}
        onRequestClose={onCloseModalClick}
        modalContent={classes.modalContent}
      >
        <div className={classes.container}>
          {renderHeader()}
          {renderContent()}
        </div>
      </UIModal>
    );
  };

  const { networkStatus, productUpdates } = props;
  return (
    <React.Fragment>
      {!_.isEmpty(productUpdates) && networkStatus === 7 ? renderModal() : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const planCategory = _.get(state, "login.userInfo.orgPlan", "Free");
  const userCurriculumPrograms = _.get(ownProps, "userCurriculumPrograms", []);

  const filters = getProductUpdatesFilters({
    curriculumPrograms: userCurriculumPrograms,
    planCategory,
  });

  return {
    // productUpdateModalId will be present if user has copied link and opened it
    productUpdateModalId: _.get(state, "login.productUpdateModalId", ""),
    variables: {
      filters,
      orderBy: "CREATED_AT",
      first: 3,
      onlyNewContent: true,
    },
    currentCurriculumProgramType: _.get(
      state,
      "platform.currentCurriculumProgramType",
      ""
    ),
    filters,
  };
};

const mapActionCreators = {
  goToPlatformHomeChildRoute,
  handleVote,
  setProductUpdateDirectLinkOpen,
  updateQueryParameters,
  setProductUpdateModalOpen,
  resetProductUpdateNewContent,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getProductUpdatesQuery, {
    // query to get new product updates
    name: "productUpdates",
    skip: ({ productUpdateModalId }) => productUpdateModalId,
    options: ({ variables }) => ({
      fetchPolicy: "cache-and-network",
      variables,
    }),
    props: ({ productUpdates }) => {
      const productUpdatesCms = _.get(productUpdates, "cms", {});
      const productUpdatesData = _.map(
        _.get(productUpdatesCms, "productUpdatesFeed.edges", []),
        "node"
      );

      return {
        networkStatus: _.get(productUpdates, "networkStatus"),
        productUpdates: productUpdatesData,
      };
    },
  }),
  graphql(getProductUpdateQuery, {
    // query to get single product update
    name: "productUpdate",
    skip: ({ productUpdateModalId }) => !productUpdateModalId,
    options: ({ productUpdateModalId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: productUpdateModalId,
      },
    }),
    props: ({ productUpdate }) => {
      const productUpdateNode = _.get(productUpdate, "node", {});

      return {
        networkStatus: _.get(productUpdate, "networkStatus"),
        productUpdates: _.toArray({ productUpdateNode }),
      };
    },
  })
)(ProductUpdatesModal);
