import gql from "graphql-tag";
import { courseFragment } from "Course/modules/CourseFragments";
export const staffCourseFragment = {
  staffCourse: gql`
    fragment staffItem on Staff {
      id
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
        ...courseItem
      }
    }
    ${courseFragment.courseUnitPlanFeedCount}
  `,
  StaffCourseStudents: gql`
    fragment staffItem on Staff {
      id
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
        ...courseItem
      }
    }
    ${courseFragment.StaffCourseStudents}
  `,
  staffCourseBasicDetails: gql`
    fragment staffItem on Staff {
      id
      courses(filters: $filters, orderBy: TITLE, orderByDirection: ASC) {
        ...courseItem
      }
      allGrades {
        id
        name
      }
    }
    ${courseFragment.courseBasicDetails}
  `,
  staffCourseGenericFoldersBasicDetails: gql`
    fragment staffItem on Staff {
      id
      courses {
        ...courseItem
      }
    }
    ${courseFragment.courseGenericFoldersBasicDetailsFragment}
  `,
};

export const studentCourseFragment = {
  studentCourse: gql`
    fragment studentCourseItem on Student {
      id
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
        ...courseFeedItem
      }
    }
    ${courseFragment.courseFeed}
  `,
  studentCourseTags: gql`
    fragment studentCourseTags on Student {
      courseMap(filters: $courseIdsFilter) {
        id
        course {
          id
        }
        tags {
          id
          label
        }
      }
    }
  `,
};
