import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classNames from "classnames";

import classes from "./ParentJournal.scss";
import { withLoader } from "UIComponents";
import ScreenBaseComponent from "AppComponents/ScreenBaseComponent";

import { updateNavigationObject } from "modules/NavigationModule";
import { createPostLike, deletePostLike } from "Journal/modules/JournalModule";
import { getStudentBasicDetailsQuery } from "StudentProfileCommon/modules/StudentProfileQuery";
import { getStudentDetailsFromCache } from "StudentProfileCommon/modules/StudentProfileGraphqlHelpers";

import { PostDetail } from "Post";
import JournalFeed from "Journal/components/JournalFeed";
import TopHeader from "./TopHeader";
import FolderFilter from "./FolderFilter";

class ParentJournal extends ScreenBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      activeCourse: "ALL",
      coursesForDropdown: [],
      filters: {},
      activeFolder: "",
    };
  }

  componentDidMount() {
    const {
      courses,
      journalFilters,
      postNavigationDetail,
      updateNavigationObject,
    } = this.props;

    this.setState({
      coursesForDropdown: courses,
    });

    if (!_.isEmpty(postNavigationDetail)) {
      // this block will be executed if user is landed from notification center
      const {
        rightPane = "",
        rightPaneId = "",
        courseId = null,
      } = postNavigationDetail;

      // set activeCourse and open rightPane
      this.setState({
        filters: {
          ...(journalFilters || {}),
          courseIds: _.toString(courseId),
          folderIds: null,
        },
        activeCourse: _.toString(courseId),
        activeFolder: null,
      });
      this.handleRightPane({ rightPane: rightPane, id: rightPaneId });

      updateNavigationObject({ postDetail: null });
    } else {
      // if only one course is present then it should be selected by default in filters
      const selectedCourse =
        _.size(courses) === 1 ? _.get(_.head(courses), "value", "ALL") : "ALL";

      this.setState({
        filters: {
          ...(journalFilters || {}),
          courseIds: selectedCourse !== "ALL" ? selectedCourse : null,
        },
        activeCourse: selectedCourse,
      });
    }
  }

  handleCourseChange = param => {
    const { showRightPane } = this.state;

    this.setState(state => ({
      filters: {
        ...state.filters,
        courseIds: param !== "ALL" ? param : null,
        folderIds: null,
      },
      activeCourse: param,
      activeFolder: null,
    }));

    if (showRightPane) {
      this.closeRightPane();
    }
  };

  handleFolderChange = ({ folder }) => {
    const { showRightPane } = this.state;

    this.setState(state => ({
      filters: {
        ...state.filters,
        folderIds: folder !== "" ? folder : null,
      },
      activeFolder: folder,
    }));

    if (showRightPane) {
      this.closeRightPane();
    }
  };

  onPostLikedClick = ({ id, isLiked, item }) => {
    const { createPostLike, deletePostLike } = this.props;

    if (isLiked) {
      deletePostLike({ postId: id, postDetails: item });
    } else {
      createPostLike({ postId: id, postDetails: item });
    }
  };

  onPostActionClick = ({ id, action, params, item }) => {
    const { isCurrentAcademicYear } = this.props;

    switch (action) {
      case "POST_DETAIL":
      case "COMMENT":
        this.handleRightPane({ rightPane: action, id });
        break;
      case "LIKE": {
        if (isCurrentAcademicYear)
          this.onPostLikedClick({ id, isLiked: params.isLiked, item });
        break;
      }
    }
  };

  getRightPaneComponent = () => {
    const { currentRightPane, rightPaneId, t } = this.state;
    const { isCurrentAcademicYear } = this.props;

    switch (currentRightPane) {
      case "POST_DETAIL":
      case "COMMENT":
        return (
          <PostDetail
            postId={rightPaneId}
            onCloseClick={this.closeRightPane}
            fullMedia={true}
            customRef={ref => this.customRightPaneScrollRef(ref)}
            showComment={currentRightPane == "COMMENT"}
            customDetailText={t("common:view_with_label_lowercase", {
              label: t("common:post"),
            })}
            isRightPaneChat={true}
            canCreateComment={isCurrentAcademicYear}
            mode={isCurrentAcademicYear ? "edit" : "view"} // disabling liking post and comment actions in view mode
          />
        );
    }
    return null;
  };

  renderMiddlePane = () => {
    const {
      showRightPane,
      rightPaneId,
      currentRightPane,
      filters,
    } = this.state;
    const { parentId } = this.props;
    const middlePaneStyle = classNames(
      { [classes.smallMiddlePane]: showRightPane },
      { [classes.fullMiddlePane]: !showRightPane }
    );

    const currentCommentPostId =
      currentRightPane == "COMMENT" ? rightPaneId : null;

    return (
      <div className={middlePaneStyle}>
        <JournalFeed
          onPostActionClick={this.onPostActionClick}
          currentCommentPostId={currentCommentPostId}
          filters={filters}
          parentId={parentId}
        />
      </div>
    );
  };

  render() {
    const { childName } = this.props;
    const { coursesForDropdown, activeCourse, activeFolder } = this.state;

    return (
      <div className={classes.container}>
        <TopHeader
          childName={childName}
          handleCourseChange={this.handleCourseChange}
          coursesForDropdown={coursesForDropdown}
          activeCourse={activeCourse}
        />
        <div className={classes.bodyContainer}>
          <div className={classes.leftContainer}>
            <FolderFilter
              courseId={activeCourse}
              activeFolder={activeFolder}
              handleFolderChange={this.handleFolderChange}
            />
          </div>
          {this.renderMiddlePane()}
          {this.renderRightPane()}
        </div>
      </div>
    );
  }
}

const mapActionCreators = {
  createPostLike,
  deletePostLike,
  updateNavigationObject,
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = _.get(state, "login.userInfo", {});
  const studentId = _.get(userInfo, "childID", "");
  const userId = _.get(userInfo, "id", "");
  const childName = _.get(userInfo, "childName", "");
  const selectedAcademicYear = _.get(
    state,
    "platform.academicYearSelected",
    {}
  );
  const isCurrentAcademicYear = _.get(
    selectedAcademicYear,
    "isCurrentAcademicYear",
    true
  );
  return {
    parentId: userId,
    studentId,
    childName,
    postNavigationDetail: _.get(
      state,
      "navigation.navigationObject.postDetail",
      {}
    ),
    journalFilters: {
      studentIds: [studentId],
      showPrivate: false,
      state: "PUBLISHED",
      orderBy: "PUBLISHED_AT",
    },
    selectedAcademicYearId: _.get(selectedAcademicYear, "id", ""),
    isCurrentAcademicYear,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getStudentBasicDetailsQuery, {
    name: "getStudentBasicDetails",
    options: ({ studentId, selectedAcademicYearId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        studentId,
        courseFilters: { academicYear: selectedAcademicYearId },
      },
    }),
    props({
      getStudentBasicDetails,
      ownProps: { studentId, selectedAcademicYearId },
    }) {
      const studentDetails = getStudentDetailsFromCache({
        studentId,
        courseFilters: { academicYear: selectedAcademicYearId },
      });
      const studentCourses = _.get(studentDetails, "courses", []);

      // create array for FilterDropdown
      const courses = _.map(studentCourses, courseItem => ({
        value: courseItem.id,
        label: courseItem.title,
      }));

      return {
        courses,
        isData: !_.isEmpty(studentDetails),
        isLoading:
          getStudentBasicDetails["networkStatus"] == 1 ||
          getStudentBasicDetails["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(ParentJournal);
