import gql from "graphql-tag";
import { userfragment } from "./fragments";

export const getUserInfoQuery = gql`
  query getUserInfo($ids: [ID!]) {
    platform {
      user(ids: $ids) {
        ...userItem
      }
    }
  }
  ${userfragment.user}
`;

export const getPermissionsQuery = gql`
  query getPermissions($userId: ID!, $groups: [String]!) {
    platform {
      permissions(userId: $userId, groups: $groups)
    }
  }
`;
