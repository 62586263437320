import { UIBaseComponent, I18nHOC } from "UIComponents";
import React from "react";
import classes from "./SubjectGroupWithSubjectViewOnly.scss";
import PropTypes from "prop-types";
import {
  ArtSGIcon,
  DesignSGIcon,
  IndiAndSocSGIcon,
  LangAcquisitionSGIcon,
  LangAndLitSGIcon,
  MathematicsSGIcon,
  PAndHEduSGIcon,
  ScienceSGIcon,
} from "SvgComponents";
import classNames from "classnames";
import { Tooltip } from "@toddle-design/web";

const iconSize = 28;

const ICON_SUBJECT_GROUP_MAPPING = [
  {
    iconKey: "ARTS",
    icon: <ArtSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "L&L",
    icon: <LangAndLitSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "LA",
    icon: <LangAcquisitionSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "DESIGN",
    icon: <DesignSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "SCI",
    icon: <ScienceSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "MATH",
    icon: <MathematicsSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "PHE",
    icon: <PAndHEduSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "I&S",
    icon: <IndiAndSocSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "COMMON",
    icon: <IndiAndSocSGIcon width={iconSize} height={iconSize} />,
  },
];

class SubjectGroupWithSubjectViewOnly extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  renderEdit = () => {
    const { value } = this.props;
    const textClass = classNames("text-subtitle-1", classes.textContainer);

    return (
      <div className={classes.container}>
        <div className={classes.boxContainer}>
          {_.get(
            _.find(ICON_SUBJECT_GROUP_MAPPING, {
              iconKey: _.get(value, "subjectGroup.constants.iconKey", "COMMON"),
            }),
            "icon",
            ""
          )}
          <Tooltip tooltip={_.get(value, "subjectGroup.name", "")}>
            <div className={textClass}>
              {_.get(value, "subjectGroup.name", "")}
            </div>
          </Tooltip>
        </div>
        <div className={classes.pipe} />
        <div className={classes.boxContainer}>
          <img
            src={_.get(value, "subject.icon", "")}
            className={classes.iconContainer}
          />

          <Tooltip tooltip={_.get(value, "subject.name", "")}>
            <div className={textClass}>{_.get(value, "subject.name", "")}</div>
          </Tooltip>
        </div>
      </div>
    );
  };
}

SubjectGroupWithSubjectViewOnly.defaultProps = {
  ...UIBaseComponent.defaultProps,
  value: {},
};

SubjectGroupWithSubjectViewOnly.propTypes = {
  ...UIBaseComponent.propTypes,
  value: PropTypes.object,
};

export default I18nHOC({ resource: ["common"] })(
  SubjectGroupWithSubjectViewOnly
);
