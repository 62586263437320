import { withAsyncRouteLoading } from "UIComponents";
import SnPDetails from "./routes/SnPDetails";
import CycleCreate from "./routes/CycleCreate";
import CycleDetails from "./routes/CycleDetails";
import { injectReducer } from "store/reducers";

export default store => ({
  path: "snp",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("SnP/modules/SnPModule").default;
            injectReducer(store, { key: "snp", reducer });
            const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
              .default;
            injectReducer(store, { key: "unitPlans", reducer: unitPlanModule });

            const assessmentModule = require("Assessments/modules/AssessmentModule");
            injectReducer(store, {
              key: assessmentModule.NAME,
              reducer: assessmentModule.default,
            });
            const post = require("Post/modules/PostModule").default;

            injectReducer(store, {
              key: "post",
              reducer: post,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "snp"
        );
      })
  ),

  childRoutes: [CycleCreate(store), SnPDetails(store), CycleDetails(store)],
});
