import React, { Fragment } from "react";
import classes from "./CommentPane.scss";
import { CancelIcon, NoCommentIcon, VoiceIcon } from "SvgComponents";
import {
  TextAreaInput,
  ProfileCascade,
  TaggableInputField,
  AudioRecorderSelector,
} from "UIComponents";
import CommentItem from "./CommentItem";
import { Loading, I18nHOC } from "UIComponents";
import { withLoader, UIButton } from "UIComponents";
import { colors } from "Constants";
import { scrollToWithAnimation } from "Utils";
import { AttachmentList } from "Attachment";
import { DisabledStateTooltip } from "AppComponents";
import { MicrophoneOutlined } from "@toddle-design/web-icons";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { Button } from "@toddle-design/web";
import { EmptyState } from "@toddle-design/web";
import { ChatIllustration } from "@toddle-design/theme";

const loadingStyle = {
  width: 24,
  height: 24,
};
class CommentFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMessageBox: false,
      replyText: "",
      parentMessageId: "",
    };

    const { comments } = this.props;
    const commentsCount = _.get(comments, "length", 0);

    this.refList = {};
  }

  UNSAFE_componentWillMount = () => {
    this.props.markCommentRead();
  };

  componentDidMount = () => {
    const { isNodeDeleted, onClose, setToastMsg } = this.props;
    if (isNodeDeleted) {
      onClose();
      setToastMsg("toastMsgs:access_deleted_entity");
    }
  };

  updateReply = params => this.setState({ ...params });

  submitReply = async () => {
    const { onSubmitReply } = this.props;
    const { replyText, parentMessageId, replyItemId } = this.state;

    try {
      this.setState({ showMessageBox: false });
      await onSubmitReply({
        parentMessageId,
        message: replyText,
        itemType: "REPLY",
        replyItemId,
      });

      this.setState({ replyText: "" });
    } catch (err) {
      this.setState({ showMessageBox: true });
    }
  };

  showMessageBox = (parentMessageId, createdBy, replyItemId) => {
    const { scrollToPosition } = this.props;
    const { firstName, lastName, id } = createdBy;
    const preText = `@[${firstName} ${lastName}](${id}) `;

    this.setState({
      showMessageBox: true,
      parentMessageId,
      replyItemId,
      replyText: preText,
    });
    const textBoxRef = this.refList[parentMessageId];
    const itemNode = _.get(textBoxRef, "parentNode");
    const conversationOffset = _.get(itemNode, "offsetTop", 0);
    const conversationHeight = _.get(itemNode, "clientHeight", 0);
    if (scrollToPosition && textBoxRef)
      scrollToPosition(conversationOffset + conversationHeight - 300);
  };

  handleBlur = () => {
    const { replyText } = this.state;
    const filteredText = replyText.replace(/^@\[.*?\]\(.*?\)/, "");
    if (!filteredText.trim())
      this.setState({
        showMessageBox: false,
        replyText: "",
      });
  };

  render() {
    const {
      comments,
      totalCounts,
      customScrollRef,
      fetchMoreFeed,
      networkStatus,
      customItemRef,
      onClickEdit,
      editCommentId,
      feedStyle,
      editComment,
      onCommentDataChange,
      onCommentEdit,
      deleteComment,
      onCancelEdit,
      userInfo,
      collaborators,
      onCommentInputRef,
      disableTagging,
      commentItemContainerStyle,
      hasNextPage,
      scrollable,
      hideEmptyView,
      deleteOtherComment,
      nodeId,
      parentType,
      t,
      seeMoreContainerStyle,
      allowReply,
      onSubmitReply,
      sendOnEnter,
      showProfileAvatar,
      theme,
      goToCommunityRoute,
      canCreateAudioComment,
      commentBoxContainerStyle,
      commentItemStyle = {},
      nagivateToProfile,
      commentBoxInnerWrapper,
      unveilMaxHeight,
      lastMessageId,
      isPublicAccess,
      isRightPaneChat,
      mode,
    } = this.props;
    const { replyText, parentMessageId, showMessageBox } = this.state;

    const commentsCount = _.get(comments, "length", 0);

    return commentsCount > 0 ? (
      <div
        className={classes.CommentFeed}
        ref={ref => {
          customScrollRef(ref);
          this.container = ref;
        }}
        onScroll={fetchMoreFeed}
        style={feedStyle}
      >
        {networkStatus == 3 && (
          <div className={classes.loadingAnimationContainer}>
            <div className={classes.loadingAnimation} />
          </div>
        )}

        {!scrollable && hasNextPage && networkStatus !== 3 && (
          <div
            className={classes.seeMoreContainer}
            onClick={() => fetchMoreFeed()}
            style={seeMoreContainerStyle}
          >
            {`View ${totalCounts - commentsCount} more`}
          </div>
        )}

        {_.map(comments, (item, index) => {
          const itemNode = _.get(item, "node", {});
          const itemId = _.get(itemNode, "id", "");
          const itemNodeAttachments = _.get(itemNode, "item.attachments", []);
          const isEditing = editComment.id == itemId;
          const messageReplies = [
            ..._.get(itemNode, "messageReplies.edges", []),
          ].reverse();

          const label = _.get(isEditing ? editComment : itemNode, "label", "");
          const attachments = isEditing
            ? _.get(editComment, "attachments", [])
            : itemNodeAttachments;
          return (
            <div
              key={itemId}
              className={
                theme === "COMMUNITY_VIEW" ? classes.commentWrapper : ""
              }
            >
              <CommentItem
                deleteOtherComment={deleteOtherComment}
                onClickDelete={() => deleteComment({ comment_id: itemId })}
                onClickEdit={() => onClickEdit(itemNode)}
                editCommentId={editCommentId}
                item={itemNode}
                key={itemId}
                customItemRef={ref => customItemRef(ref, index)}
                label={label}
                attachments={attachments}
                isEditing={isEditing}
                onCancelEdit={onCancelEdit}
                onCommentEdit={onCommentEdit}
                onCommentDataChange={onCommentDataChange}
                userInfo={userInfo}
                collaborators={collaborators}
                onCommentInputRef={onCommentInputRef}
                parentNode={this.container}
                disableTagging={disableTagging}
                commentItemContainerStyle={commentItemContainerStyle}
                itemId={itemId}
                allowReply={allowReply}
                onSubmitReply={onSubmitReply}
                showMessageBox={createdBy => {
                  if (isPublicAccess) return;
                  this.showMessageBox(itemId, createdBy);
                }}
                goToCommunityRoute={goToCommunityRoute}
                canCreateAudioComment={canCreateAudioComment}
                commentItemStyle={commentItemStyle}
                nagivateToProfile={nagivateToProfile}
                unveilMaxHeight={unveilMaxHeight}
                shouldCollapseMessage={lastMessageId !== itemId}
                isRightPaneChat={isRightPaneChat}
                mode={mode}
              />
              {messageReplies.length > 0 &&
                _.map(messageReplies, (replyItem, replyItemIndex) => {
                  const replyItemNode = _.get(replyItem, "node", {});
                  const replyItemId = _.get(replyItemNode, "id", "");
                  const replyItemNodeAttachments = _.get(
                    replyItemNode,
                    "item.attachments",
                    []
                  );
                  const isReplyEditing = editComment.id == replyItemId;
                  const replyLabel = _.get(
                    isReplyEditing ? editComment : replyItemNode,
                    "label",
                    ""
                  );
                  const customCommentItemContainerStyle = {
                    ...(commentItemContainerStyle || {}),
                    paddingLeft: "62px",
                  };
                  const attachments = isReplyEditing
                    ? _.get(editComment, "attachments", [])
                    : replyItemNodeAttachments;
                  return (
                    <Fragment key={replyItemId}>
                      <div
                        className={classes.replyDivider}
                        style={{ ...commentItemStyle.replyDivider }}
                      ></div>
                      <CommentItem
                        deleteOtherComment={deleteOtherComment}
                        onClickDelete={() =>
                          deleteComment({
                            comment_id: replyItemId,
                            parentMessageId: itemId,
                            itemType: "REPLY",
                          })
                        }
                        onClickEdit={() => onClickEdit(replyItemNode)}
                        editCommentId={editCommentId}
                        item={replyItemNode}
                        key={replyItemId}
                        customItemRef={ref =>
                          customItemRef(ref, replyItemIndex)
                        }
                        label={replyLabel}
                        attachments={attachments}
                        isEditing={isReplyEditing}
                        onCancelEdit={onCancelEdit}
                        onCommentEdit={onCommentEdit}
                        onCommentDataChange={onCommentDataChange}
                        userInfo={userInfo}
                        collaborators={collaborators}
                        onCommentInputRef={onCommentInputRef}
                        parentNode={this.container}
                        disableTagging={disableTagging}
                        commentItemContainerStyle={
                          customCommentItemContainerStyle
                        }
                        itemId={replyItemId}
                        allowReply={allowReply}
                        onSubmitReply={onSubmitReply}
                        showMessageBox={createdBy => {
                          if (isPublicAccess) return;
                          this.showMessageBox(itemId, createdBy, replyItemId);
                        }}
                        goToCommunityRoute={goToCommunityRoute}
                        canCreateAudioComment={canCreateAudioComment}
                        commentItemStyle={commentItemStyle}
                        nagivateToProfile={nagivateToProfile}
                        unveilMaxHeight={unveilMaxHeight}
                        shouldCollapseMessage={lastMessageId !== replyItemId}
                        isRightPaneChat={isRightPaneChat}
                        mode={mode}
                      />
                    </Fragment>
                  );
                })}
              <div ref={ref => (this.refList[itemId] = ref)}></div>
              {showMessageBox && parentMessageId === itemId && (
                <div
                  style={{
                    ...commentBoxContainerStyle,
                    marginLeft: "62px",
                  }}
                >
                  <div
                    className={classes.commentBoxInnerWrapper}
                    style={{ ...(commentBoxInnerWrapper || {}) }}
                  >
                    <TaggableInputField
                      name={"replyText"}
                      value={replyText}
                      onEnterPress={this.submitReply}
                      updateInputField={this.updateReply}
                      allowSubmitOnEnter={true}
                      allowShiftEnter={true}
                      userInfo={userInfo}
                      collaborators={collaborators}
                      parentNode={this.containerRef}
                      disableTagging={disableTagging}
                      showProfileAvatar={showProfileAvatar}
                      onBlur={this.handleBlur}
                      mentionInputConStyle={{ border: "none" }}
                      // mentionInputConStyle={{ ...(commentBoxInnerWrapper || {}) }}
                    />
                  </div>
                </div>
              )}
              <div
                className={classes.divider}
                style={{ ...commentItemStyle.divider }}
              ></div>
            </div>
          );
        })}
      </div>
    ) : (
      <NoCommentComp t={t} hideEmptyView={hideEmptyView} />
    );
  }
}

const CommentFeedHoc = withLoader(CommentFeed);

const NoCommentComp = ({ hideEmptyView, t }) => {
  return !hideEmptyView ? (
    <div className={classes.noCommentContainer}>
      <EmptyState
        illustration={ChatIllustration}
        subtitle={t("no_comment_in_thread")}
      />
    </div>
  ) : null;
};

const commentBoxStyle = {
  customContainerStyle: {
    alignItems: "flex-start",
  },
  customStyle: {
    borderWidth: 0,
    width: 32,
    height: 32,
  },
  textAreaStyles: {
    marginTop: 0,
    borderWidth: 0,
    padding: 0,
    paddingRight: 32,
  },
};
class CommentPane extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerDiv = null;
    this.feedList = null;
    this.state = {
      height: 0,
      editComment: {},
      searchString: "",
      uploadStatus: false,
      localCommentText: "",
    };
    this.feedItem = {};
    this.lastMessageId = React.createRef();
    this.updateLocalCommentDebounced = _.debounce(() => {
      const label = this.state.localCommentText;
      this.props.updateLocalComment({ label });
    }, 200);
  }

  componentDidMount = () => {
    if (this.containerDiv) {
      const height = this.containerDiv.getBoundingClientRect().height;
      if (this.state.height != height) {
        this.setState({ height });
      }
    }
    setTimeout(this.setScrollToBottom);
  };

  componentDidUpdate = prevProps => {
    if (prevProps.isData != this.props.isData) {
      setTimeout(this.setScrollToBottom);
    }
    /**
     * this will handle when we have made change in redux for createCommentData label and that change is not 
       reflected in local state.
       e.g. case 1: when you update in local state you are updating redux also 
            see fn updateLocalCommentDebounced
            case 2: when you are updating redux then how local state will know that localCommentText has updated
            in redux

            So, to solve 2nd case this the condition.
     * */

    if (
      prevProps.createCommentData.label != this.props.createCommentData.label &&
      this.state.localCommentText != this.props.createCommentData.label
    ) {
      this.setState({ localCommentText: this.props.createCommentData.label });
    }
    /**
     * when you send comment the scrolling is not happening optimistically. it happens after mutation is done
     * so to solve that this condition will take care.
     *  how ?
     * -> So, when you click send we are doing createComment mutation and in that we are making comment label empty
     * so I have used that for checking if we should scroll or not.
     */
    if (
      (prevProps.createCommentData.label !=
        this.props.createCommentData.label &&
        _.isEmpty(this.props.createCommentData.label)) ||
      (_.size(prevProps.createCommentData.attachments) !=
        _.size(this.props.createCommentData.attachments) &&
        _.isEmpty(this.props.createCommentData.attachments))
    ) {
      setTimeout(this.setScrollToBottom);
    }
  };

  setScrollToBottom = () => {
    if (this.feedList) {
      this.feedList.scrollTop = this.feedList.scrollHeight;
    }
  };

  updateLocalCommentText = params => {
    const value = params.label;
    this.setState({ localCommentText: value }, () => {
      this.updateLocalCommentDebounced();
    });
  };

  updateLocalComment = param => {
    this.props.updateLocalComment(param);
    if (param && param["searchString"]) {
      this.setState({ searchString: param["searchString"] });
    }
    setTimeout(() => {
      if (this.containerDiv) {
        const height = this.containerDiv.getBoundingClientRect().height;
        if (this.state.height != height) {
          this.setState({ height });
        }
      }
    });
  };

  fetchMoreFeed = () => {
    const { isPublicAccess } = this.props;
    if (isPublicAccess) return;

    if (this.feedList) {
      //below two conditions were to make taggableInputField remove it's focus when scroll
      // so that we can hide suggestions when comments are scrolled
      // if (this.taggableInputField) this.taggableInputField.removeFocus();
      // if (this.editCommentInputRef) this.editCommentInputRef.removeFocus();
      //console.log(this.feedList.scrollTop);
      if (
        this.feedList.scrollTop == 0 &&
        this.props.hasNextPage &&
        this.props.getCommentData.networkStatus != 3
      ) {
        this.props.fetchMoreFeed().then(() => {
          const scrollIndex =
            this.props.totalCounts == this.props.comments.length
              ? (this.props.totalCounts % 10) - 1
              : 9;
          if (scrollIndex >= 0) {
            this.feedList.scrollTop =
              this.feedItem[scrollIndex].getBoundingClientRect().top -
              this.feedList.getBoundingClientRect().top;
          }
        });
      }
    }
  };

  onCommentEdit = async () => {
    const { editComment } = this.state;
    const { updateComment, deleteComment } = this.props;
    const tempComment = { ...editComment };
    try {
      this.setState({ editComment: {} });
      if (!_.trim(editComment.label) && _.isEmpty(editComment.attachments)) {
        await deleteComment({ comment_id: editComment.id });
      } else {
        await updateComment({
          comment_id: editComment.id,
          comment: _.trim(editComment.label),
          attachments: editComment.attachments,
        });
      }
    } catch (e) {
      this.setState({ editComment: { ...tempComment } });
    }
  };

  onCommentDataChange = params => {
    const { editComment } = this.state;
    if (editComment) {
      this.setState({ editComment: { ...editComment, ...params } });
    }
  };

  onEnterPress = async () => {
    const { createComment, isPublicAccess } = this.props;
    if (isPublicAccess) return;
    try {
      this.setState({ localCommentText: "" });
      this.lastMessageId.current = await createComment();
      this.editCommentInputRef = null;
    } catch (err) {
      console.error(err);
    }
  };

  onSubmitReply = async data => {
    const { createComment } = this.props;
    try {
      this.lastMessageId.current = await createComment(data);
    } catch (err) {
      console.error(err);
    }
  };

  onCancelEdit = () => {
    this.setState({ editComment: {} });
    this.editCommentInputRef = null;
  };

  onClickEdit = comment => {
    this.setState({
      editComment: {
        id: comment.id,
        label: comment.label,
        attachments: _.get(comment, "item.attachments", []),
      },
    });
  };

  onCommentInputRef = commentInputRef => {
    this.editCommentInputRef = commentInputRef;
  };

  onAddOthersClicked = () => {
    if (this.taggableInputField) this.taggableInputField.addOthers();
  };

  render() {
    const {
      comments,
      createCommentData,
      onClose,
      headerStyle,
      feedStyle,
      isData,
      isLoading,
      commentBoxContainerStyle,
      renderHeaderComponent,
      markCommentRead,
      userInfo,
      collaborators,
      disableTagging,
      sendOnEnter,
      commentItemContainerStyle,
      showProfileAvatar,
      hasNextPage,
      scrollable,
      hideEmptyView,
      totalCounts,
      t,
      isNodeDeleted,
      setToastMsg,
      deleteOtherComment,
      canCreateComment,
      nodeId,
      parentType,
      commentPaneContainerStyle,
      seeMoreContainerStyle,
      allowReply,
      theme,
      goToCommunityRoute,
      canCreateAudioComment,
      commentItemStyle,
      nagivateToProfile,
      scrollToPosition,
      sendButtonText,
      commentBoxInnerWrapper,
      bottomButtonsContainer,
      sendButtonStyle = {},
      unveilMaxHeight,
      isPublicAccess,
      isCurrentAcademicYearSelected,
      isRightPaneChat,
      mode,
      uploadFileType,
    } = this.props;

    const { editComment, uploadStatus, localCommentText } = this.state;
    const label = localCommentText || "";
    const attachments = createCommentData.attachments || [];

    const isCreateEmpty = _.isEmpty(label) && _.isEmpty(attachments);
    const placeholder =
      theme === "COMMUNITY_VIEW"
        ? comments.length
          ? t("common:write_a_comment")
          : t("common:first_to_comment")
        : null;
    const characterLimit = 8000 - this.state.localCommentText?.length;

    return (
      <div
        className={classes.container}
        ref={ref => (this.containerRef = ref)}
        style={commentPaneContainerStyle}
      >
        {renderHeaderComponent ? (
          renderHeaderComponent({ totalCount: totalCounts })
        ) : (
          <div className={classes.headerContainer} style={headerStyle}>
            <div className={classes.headerText}>
              {t("common:comment_plural")}
            </div>
            <div className={classes.closeButton} onClick={onClose}>
              <CancelIcon width={10} heigth={10} />
            </div>
          </div>
        )}
        <div
          className={classes.commentfeedcontainer}
          // style={{ marginBottom: this.state.height }}
        >
          <CommentFeedHoc
            t={t}
            deleteOtherComment={deleteOtherComment}
            isNodeDeleted={isNodeDeleted}
            markCommentRead={markCommentRead}
            feedStyle={feedStyle}
            userInfo={userInfo}
            hasNextPage={hasNextPage}
            totalCounts={totalCounts}
            scrollable={scrollable}
            isData={isData}
            isLoading={isLoading}
            onClickEdit={this.onClickEdit}
            networkStatus={this.props.getCommentData.networkStatus}
            comments={comments}
            deleteComment={this.props.deleteComment}
            editCommentId={this.state.comment_id}
            customItemRef={(ref, index) => (this.feedItem[index] = ref)}
            fetchMoreFeed={this.fetchMoreFeed}
            customScrollRef={ref => (this.feedList = ref)}
            showLoaderBar={false}
            loadingStyle={loadingStyle}
            editComment={editComment}
            onCommentDataChange={this.onCommentDataChange}
            onCommentEdit={this.onCommentEdit}
            onCancelEdit={this.onCancelEdit}
            collaborators={collaborators}
            onCommentInputRef={this.onCommentInputRef}
            disableTagging={disableTagging}
            commentItemContainerStyle={commentItemContainerStyle}
            hideEmptyView={hideEmptyView}
            onClose={onClose}
            setToastMsg={setToastMsg}
            parentType={parentType}
            nodeId={nodeId}
            seeMoreContainerStyle={seeMoreContainerStyle}
            allowReply={allowReply}
            onSubmitReply={this.onSubmitReply}
            sendOnEnter={sendOnEnter}
            showProfileAvatar={showProfileAvatar}
            theme={theme}
            goToCommunityRoute={goToCommunityRoute}
            canCreateAudioComment={canCreateAudioComment}
            commentBoxContainerStyle={commentBoxContainerStyle}
            commentItemStyle={commentItemStyle}
            nagivateToProfile={nagivateToProfile}
            scrollToPosition={scrollToPosition}
            commentBoxInnerWrapper={commentBoxInnerWrapper}
            unveilMaxHeight={unveilMaxHeight}
            lastMessageId={this.lastMessageId.current}
            isPublicAccess={isPublicAccess}
            isRightPaneChat={isRightPaneChat}
            mode={mode}
          />
        </div>

        {canCreateComment && (
          <div
            style={commentBoxContainerStyle}
            className={classes.commentBoxContainer}
            ref={ref => (this.containerDiv = ref)}
          >
            {/* <div className={classes.profileImage}>
            <ProfileCascade
              items={[{ id: id, value: first_name, imageUrl: profile_image }]}
              customStyle={commentBoxStyle.customStyle}
              customContainerStyle={commentBoxStyle.customContainerStyle}
            />
          </div> */}

            {/* <TextAreaInput /> */}
            <div
              className={classes.commentBoxOuterWrapper}
              ref={ref => (this.commentContainerRef = ref)}
            >
              <div
                className={classes.commentBoxInnerWrapper}
                style={{ ...(commentBoxInnerWrapper || {}) }}
              >
                <div className={classes.commentAudioBtnWrapper}>
                  <TaggableInputField
                    ref={ref => (this.taggableInputField = ref)}
                    commentContainerRef={this.commentContainerRef}
                    name={"label"}
                    value={label}
                    onEnterPress={this.onEnterPress}
                    updateInputField={this.updateLocalCommentText}
                    allowSubmitOnEnter={sendOnEnter}
                    allowShiftEnter={true}
                    userInfo={userInfo}
                    collaborators={collaborators}
                    parentNode={this.containerRef}
                    disableTagging={disableTagging}
                    showProfileAvatar={showProfileAvatar}
                    mentionInputConStyle={{ border: "none" }}
                    placeholder={placeholder}
                  />
                  {canCreateAudioComment && (
                    <div className={classes.audioBtnWrapper}>
                      <AudioRecorderSelector
                        parentType={parentType}
                        parentId={nodeId}
                        showValue={false}
                        header={t("common:voice_and_record")}
                        updateInputField={param => {
                          this.updateLocalComment({
                            attachments: [...attachments, param],
                          });
                        }}
                        buttonComponent={
                          <MicrophoneOutlined
                            variant={"link"}
                            size={"x-small"}
                            disabled={
                              this.state.uploadStatus &&
                              uploadFileType == "AUDIO"
                            }
                          />
                        }
                        uploadStatus={value =>
                          this.setState({ uploadStatus: value })
                        }
                        version={"v1"}
                        disabled={
                          this.state.uploadStatus && uploadFileType == "AUDIO"
                        }
                      />
                    </div>
                  )}
                </div>
                <AttachmentList
                  value={attachments}
                  name={"attachments"}
                  cardType={"horizontal"}
                  showCreateDropdown={false}
                  updateInputField={this.updateLocalComment}
                  parentId={nodeId}
                  parentType={parentType}
                  clubbingCards={3}
                  showSingleCardTheme={true}
                  cardStyle={{ height: 80 }}
                  attachmentContainerStyle={{
                    gridTemplateColumns: isRightPaneChat
                      ? "repeat(1,1fr)"
                      : _.get(attachments, "length", 0) > 1
                      ? "repeat(auto-fill, minmax(88px, 1fr))"
                      : "repeat(1,1fr)",
                    gridGap: "12px",
                  }}
                  containerStyle={{ padding: "0 8px 16px" }}
                  isRightPaneChat={isRightPaneChat}
                  showLocalAttachmentsInViewMode={false}
                />
                {characterLimit < 0 ? (
                  <div className={classes.exceedCharacterCountContainer}>
                    {characterLimit}
                  </div>
                ) : null}
              </div>
            </div>

            {!sendOnEnter ? (
              <div
                className={classes.bottomButtonsContainer}
                style={{ ...(bottomButtonsContainer || {}) }}
              >
                <DisabledStateTooltip
                  isCurrentAcademicYear={isCurrentAcademicYearSelected}
                  placement={"topLeft"}
                >
                  <div style={{ ...sendButtonStyle }}>
                    <Button
                      variant={"primary"}
                      size={"medium"}
                      disabled={
                        isCreateEmpty ||
                        uploadStatus ||
                        !isCurrentAcademicYearSelected ||
                        characterLimit < 0
                      }
                      className={classes.sendButton}
                      onClick={this.onEnterPress}
                    >
                      {sendButtonText ? sendButtonText : t("send")}
                    </Button>
                  </div>
                </DisabledStateTooltip>
                {!disableTagging ? (
                  <Button variant={"inline"} onClick={this.onAddOthersClicked}>
                    {t("add_others_conversation")}
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

CommentPane.defaultProps = {
  disableTagging: false,
  sendOnEnter: false,
  scrollable: true,
  canCreateComment: true,
  sendButtonText: null,
  isCurrentAcademicYearSelected: true,
  isRightPaneChat: false,
  mode: "edit",
};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;
  const uploadFileType = _.get(
    _.values(progressOfUploads)[0],
    "attachment.type",
    ""
  );
  return {
    uploadFileType,
  };
};

export default compose(
  connect(mapStateToProps, null),
  I18nHOC({ resource: "common" })
)(CommentPane);
