/**--external-- */
import React from "react";
import PropTypes from "prop-types";

/**--internal-- */
import { useI18n } from "Hooks";
import { colors } from "Constants";
import { VIEW_MODE } from "Constants/stringConstants";
import { getWeekDaysOptions, getDaysNameOrIndexArray } from "Utils";

/**--relative-- */
import { CheckListContainer } from "UIComponents";
import {
  checkBoxLabelStyles,
  listItemStyle,
  checkboxItemStyle,
} from "./OperationalDaysStyles";

const OperationalDays = props => {
  const { t } = useI18n(["common"]);

  const { updateOperationalDays, operationalDays, mode } = props;

  const updateInputField = value => {
    const operationalDaysIndices = getDaysNameOrIndexArray({
      array: value,
      type: "index",
    });
    updateOperationalDays(operationalDaysIndices);
  };

  return (
    <CheckListContainer
      label={t("common:operationalDays")}
      options={getWeekDaysOptions()}
      updateInputField={updateInputField}
      value={getDaysNameOrIndexArray({ array: operationalDays })}
      checkboxItemStyle={checkboxItemStyle}
      listItemStyle={listItemStyle}
      CheckBoxLabelStyles={checkBoxLabelStyles}
      fillColor={colors.violet63}
      disabled={mode === VIEW_MODE}
    />
  );
};

export default OperationalDays;

OperationalDays.propTypes = {
  updateOperationalDays: PropTypes.func.isRequired,
  operationalDays: PropTypes.array,
};
