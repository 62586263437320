import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./LEPreview.scss";
import { connect } from "react-redux";

import { getAssessmentQuery } from "Assessments/modules/AssessmentQuery";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC } from "UIComponents";

import { getPlannerTemplateFromCache } from "modules/CommonGraphqlHelpers";
import { getPlannerTemplateQuery } from "modules/CommonQuery";

import { ASSESSMENT_TOOLS, getElementValue } from "modules/Services";
import { GetLESteps, getRethumbUrl } from "Utils";
import FieldComponent from "UnitPlans/components/IBcomponents/FieldComponent";
import LabelComponent from "UnitPlans/components/LabelComponent";
import {
  fontStyle,
  colors,
  PLACEHOLDER_SQUARE_IMAGE,
  PLACEHOLDER_SQUARE_IMAGE_COUNT,
} from "Constants";
import { getMYPObjectiveRubricOptions } from "modules/Services";
import { getLocalisedTemplate } from "UnitPlans/routes/UnitPlanDetails/routes/EditUnitPlan/routes/IBPlanner/modules/IBPlannerModule";
import { ChevronLeftOutlined } from "@toddle-design/web-icons";
import { filterFieldsBasedOnConfig } from "ClassRoom/modules/utils";
import { TableOutlined } from "@toddle-design/web-icons";
import { renderToString } from "react-dom/server";
import { getShowAssessmentTool } from "ClassRoom/modules/utils";

const styleObj = {
  assessmentToolsConStyle: {
    maxWidth: "575px",
    overflowX: "hidden",
  },
};

const isCheckValue = ({ value, fieldKey }) => {
  if (fieldKey == "voiceInstruction") {
    return !_.isEmpty(value);
  }
  return true;
};

const getOptions = ({
  fieldKey,
  defaultOptions,
  field_list,
  assessmentFields,
  unitPlanFields,
}) => {
  switch (fieldKey) {
    case "mypObjectiveRubric":
      return getMYPObjectiveRubricOptions({
        field_list,
        assessmentFields,
        unitPlanFields,
      });

    default:
      return defaultOptions || [];
  }
};

const UpperCon = React.memo(
  ({ t, LETitle, firstName, lastName, imageURL, id, showAttachmentItemV3 }) => {
    const rethumpUrl = `url(${getRethumbUrl({
      width: 512,
      height: 384,
      imageUrl:
        imageURL ||
        PLACEHOLDER_SQUARE_IMAGE[id % PLACEHOLDER_SQUARE_IMAGE_COUNT],
      fitIn: true,
    })})`;

    return (
      <div
        className={classes.upperCon}
        style={{ alignItems: showAttachmentItemV3 && "flex-start" }}
      >
        <div className={classes.BFlexDivCol}>
          {!showAttachmentItemV3 && (
            <div className={classes.previewLabel}>{t("common:preview")}</div>
          )}
          <div className={classes.leTitle}>{LETitle}</div>
          <div className={classes.createdByText}>
            {t("common:by_with_label", {
              label: `${firstName} ${lastName}`,
            })}
          </div>
        </div>
        <div
          className={classes.imageContainer}
          style={{
            backgroundImage: rethumpUrl,
          }}
        ></div>
      </div>
    );
  }
);

const Fields = React.memo(
  ({
    t,
    fields,
    stepLabel,
    field_list,
    assessmentFields,
    unitPlanFields,
    unitPlanAllFields,
    assessmentId,
    assessmentToolId,
    portalType,
    attachmentItemHeight,
    showAttachmentItemV3,
    assessmentDetails,
  }) => {
    const [showPreviewModal, updateShowPreviewModal] = useState(false);
    return _.map(fields, fieldKey => {
      const field = field_list[fieldKey] ? field_list[fieldKey] : {};
      if (fieldKey == "title") {
        return null;
      }
      const {
        viewType,
        config: {
          viewLabel,
          label,
          styles,
          options,
          fieldTypeConfig = {},
        } = {},
      } = field;
      const fieldData = _.find(assessmentFields, { uid: fieldKey });
      const toolType = _.get(
        _.find(assessmentFields, { uid: "measureAssessing" }),
        "value",
        null
      );

      const unitPlanFieldValue = _.get(
        _.find(unitPlanFields, { uid: fieldKey }),
        "value",
        ""
      );

      const resolvedValue = _.get(fieldData, "resolvedMinimalTree", "") || {};
      let subjectIds = [];
      if (fieldKey == "benchmarks") {
        subjectIds = _.get(
          _.find(unitPlanFields, { uid: "subjects" }),
          "value",
          ""
        );
      }

      const assessmentFieldValue = _.get(fieldData, "value", "");

      let value = getElementValue({
        fieldUID: fieldKey,
        valueKeys: assessmentFieldValue,
        unitPlanFieldValue,
        fieldObj: field,
        subjectIds: subjectIds,
        resolvedValue: resolvedValue,
        t,
      });

      const style = {
        ..._.get(styles, "style", {}),
        ..._.get(styles, "viewStyle", {}),
      };

      const checkValue = isCheckValue({
        value,
        fieldKey,
      });
      if (!checkValue) {
        return null;
      }
      const assessmentTool = _.get(assessmentDetails, "assessmentTool");

      const allFields = _.get(assessmentDetails, "allFields");
      const assessmentToolHasData = getShowAssessmentTool({
        assessmentToolType: toolType,
        assessmentTool: assessmentTool,
        allFields,
        checkInterdisciplinaryRubric: true,
      });

      if (fieldKey === "measureAssessing") {
        if (assessmentToolHasData) {
          value = `
            <div class=${classes.assessmentToolTypeContainer}>
              ${renderToString(<TableOutlined />)} 
              <span class=${classes.assessmentToolTypeValue}>${value}</span>
            </div>`;
        } else {
          return null;
        }
      }

      // if step label and field label are same, do not show LabelComponent
      const isNotDuplicateLabel =
        _.lowerCase(stepLabel) !== _.lowerCase(viewLabel) &&
        _.lowerCase(stepLabel) !== _.lowerCase(label);

      return (
        <div key={fieldKey} className={classes.item} style={style}>
          <FieldComponent
            {...fieldTypeConfig}
            defaultOptions={getOptions({
              fieldKey,
              defaultOptions: options,
              field_list,
              assessmentFields,
              unitPlanFields: unitPlanAllFields,
            })}
            customTextStyle={{
              ...fontStyle.medium,
              color: colors.gray48,
              fontSize: "1.4rem",
              lineHeight: 1.71,
            }}
            attachments={_.get(fieldData, "attachments", [])}
            type={viewType}
            mode={"view"}
            parentType={"ASSESSMENT"}
            parentId={assessmentId}
            assessmentToolId={assessmentToolId}
            labelComponent={
              isNotDuplicateLabel && fieldKey !== "measureAssessing" ? (
                <LabelComponent
                  label={viewLabel || label}
                  labelStyle={{
                    ...fontStyle.demiBold,
                    fontSize: "1.4rem",
                    lineHeight: 1.71,
                    color: colors.blue29,
                  }}
                />
              ) : null
            }
            value={value}
            fieldId={_.get(fieldData, "id", "")}
            fieldKey={fieldKey}
            portalType={portalType}
            attachmentItemHeight={attachmentItemHeight}
            isAssessmentToolPreview={true}
            toolType={toolType}
            assessmentToolsConStyle={styleObj.assessmentToolsConStyle}
            showAttachmentItemV3={showAttachmentItemV3}
          />
        </div>
      );
    });
  }
);

const Steps = React.memo(
  ({
    view_steps,
    step_list,
    field_list,
    assessmentFields,
    unitPlanFields,
    unitPlanAllFields,
    assessmentId,
    assessmentToolId,
    portalType,
    attachmentItemHeight,
    t,
    showAttachmentItemV3,
    assessmentDetails,
  }) => {
    return _.map(view_steps, key => {
      const { label, fields, subtext } = step_list[key] || {};
      // if the only field is measureAssessing in assessment tool step, do not show assessment tool step
      const hideStepContainer =
        _.size(fields) === 1 && _.first(fields) === "measureAssessing";

      return !hideStepContainer ? (
        <div key={key} className={classes.stepContainer}>
          <div className={classes.stepLabel}>
            {label}
            {subtext && <div className={classes.stepSubText}>{subtext}</div>}
          </div>
          <div className={classes.formContainer}>
            <Fields
              t={t}
              fields={fields}
              stepLabel={label}
              field_list={field_list}
              assessmentFields={assessmentFields}
              unitPlanFields={unitPlanFields}
              unitPlanAllFields={unitPlanAllFields}
              assessmentId={assessmentId}
              assessmentToolId={assessmentToolId}
              portalType={portalType}
              attachmentItemHeight={attachmentItemHeight}
              showAttachmentItemV3={showAttachmentItemV3}
              assessmentDetails={assessmentDetails}
            />
          </div>
        </div>
      ) : null;
    });
  }
);

const LEPreview = React.memo(
  ({
    step_list,
    view_steps,
    field_list,
    assessmentDetails,
    assessmentFields,
    unitPlanFields,
    unitPlanAllFields,
    assessmentId,
    portalType,
    attachmentItemHeight,
    assessmentToolId,
    t,
    showAttachmentItemV3,
    showBackButton,
    onClickBackButton,
    innerContainerStyle,
    wrapperStyle,
  }) => {
    const {
      createdBy: { firstName, lastName },
      title: { value: LETitle },
      image: { value: imageURL },
      id,
    } = assessmentDetails;
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer} style={innerContainerStyle}>
          {showBackButton && (
            <div className={classes.backButtonContainer}>
              <div className={classes.lePreviewHeader}>
                <div
                  className={classes.innerHeader}
                  onClick={onClickBackButton}
                >
                  <div className={classes.backIcon}>
                    <ChevronLeftOutlined size={"xx-small"} variant={"subtle"} />
                  </div>
                  <div className={classes.backText}>{t("common:back")}</div>
                </div>
              </div>
            </div>
          )}
          <div className={classes.wrapper} style={wrapperStyle}>
            <UpperCon
              t={t}
              LETitle={LETitle}
              firstName={firstName}
              lastName={lastName}
              imageURL={imageURL}
              id={id}
              showAttachmentItemV3={showAttachmentItemV3}
            />
            <div className={classes.lowerCon}>
              <Steps
                t={t}
                view_steps={view_steps}
                step_list={step_list}
                field_list={field_list}
                assessmentFields={assessmentFields}
                unitPlanFields={unitPlanFields}
                unitPlanAllFields={unitPlanAllFields}
                assessmentId={assessmentId}
                assessmentToolId={assessmentToolId}
                portalType={portalType}
                attachmentItemHeight={attachmentItemHeight}
                showAttachmentItemV3={showAttachmentItemV3}
                assessmentDetails={assessmentDetails}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapActionCreators = {};

const mapStateToProps = state => {
  const userType = state.login.userInfo.user_type;

  return {
    userType,
    isData: true,
    isLoading: false,
  };
};

const templateSelector = _.memoize(
  templates => templates,
  templates => JSON.stringify(templates)
);

const getDynamicTemplate = ({ template, assessmentFields, t, userType }) => {
  let dynamicTemplate = _.cloneDeep(template);
  if (!_.isEmpty(template)) {
    const step_list = dynamicTemplate["step_list"];
    const field_list = dynamicTemplate["field_list"];
    const tool = _.get(
      _.find(assessmentFields, { uid: "measureAssessing" }),
      "value",
      null
    );
    const dynamicStep = step_list["assessmentTool"];
    if (dynamicStep && !!tool) {
      dynamicStep["fields"] = [
        ...dynamicStep["fields"],
        _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", ""),
      ];
      dynamicStep["isMaxium"] = true;
    }

    let view_steps = _.get(dynamicTemplate, "view_steps", []);
    if (!_.isEmpty(view_steps)) {
      _.forEach(view_steps, step => {
        const stepFields = _.get(step_list, `${step}.fields`, []);
        const filteredStepFields = filterFieldsBasedOnConfig({
          fields: stepFields,
          field_list,
          userType,
        });
        if (_.isEmpty(filteredStepFields)) {
          view_steps = _.filter(view_steps, viewStep => {
            return viewStep != step;
          });
          dynamicTemplate["view_steps"] = view_steps;
        }
      });
    }
  }
  const localisedTemplate = getLocalisedTemplate({
    template: { body: dynamicTemplate },
    t,
    templateType: "le",
  });

  return dynamicTemplate;
};

LEPreview.propTypes = {
  parentType: PropTypes.string,
};

LEPreview.defaultProps = {
  parentType: "UNIT_PLAN",
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlanTemplate", "common"] }),
  graphql(getAssessmentQuery, {
    name: "getAssessment",
    options: ({ assessmentId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: assessmentId, portalType },
    }),
    props({ getAssessment, ownProps: { isData, isLoading, assessmentId } }) {
      const assessmentDetails = getAssessmentDetailsFromCache(assessmentId);
      const assessmentToolId = _.get(
        assessmentDetails,
        "assessmentTool.id",
        null
      );
      const templateId = _.get(assessmentDetails, "templateId", null);
      const unitPlanTemplateId = _.get(
        assessmentDetails,
        "unitPlan.templateId",
        null
      );

      return {
        assessmentDetails,
        unitPlanTemplateId,
        assessmentToolId,
        templateId,
        isData: !_.isEmpty(assessmentDetails) && isData,
        isLoading:
          getAssessment["networkStatus"] == 1 ||
          getAssessment["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerTemplateQuery, {
    name: "getPlannerTemplate",
    skip: ({ templateId, unitPlanTemplateId }) =>
      !templateId && !unitPlanTemplateId,
    options: ({ templateId, unitPlanTemplateId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: templateSelector(
          _.filter([templateId, unitPlanTemplateId], id => id)
        ),
      },
    }),
    props({
      getPlannerTemplate,
      ownProps: {
        userType,
        isLoading,
        isData,
        templateId,
        unitPlanTemplateId,
        assessmentDetails,
        parentType,
        t,
      },
    }) {
      const unitPlanFields = _.get(assessmentDetails, "unitPlan.fields", []);
      const unitPlanAllFields = _.get(
        assessmentDetails,
        "unitPlan.allFields",
        []
      );
      const assessmentFields = _.get(assessmentDetails, "allFields", []);
      const filterTemplateIds = _.filter(
        [templateId, unitPlanTemplateId],
        id => id
      );
      const templates = getPlannerTemplateFromCache(filterTemplateIds, true);
      const templateFromQuery = _.get(
        _.find(templates, { id: templateId }),
        "body",
        {}
      );

      const template = getDynamicTemplate({
        template: templateFromQuery,
        assessmentFields,
        t,
        userType,
      });

      const listSteps = GetLESteps({
        userType,
        template,
        resourceDetails: assessmentDetails,
        unitPlanFields,
        parentType,
        isPreview: true,
      });
      return {
        step_list: listSteps.stepList,
        view_steps: listSteps.viewSteps,
        field_list: _.get(template, "field_list", {}),
        assessmentFields,
        unitPlanFields,
        unitPlanAllFields,
        isData: !_.isEmpty(templates) && isData,
        isLoading:
          getPlannerTemplate["networkStatus"] == 1 ||
          getPlannerTemplate["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(LEPreview);
