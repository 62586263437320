import gql from "graphql-tag";
import {
  organizationFragment,
  singlePointRubricFragment,
} from "./SinglePointRubricFragments";

export const getOrganizationSinglePointRubricsQuery = gql`
  query getOrganizationSinglePointRubrics(
    $id: ID!
    $filters: AssessmentToolFilterInput
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.singlePointRubricList}
`;

// export const getSinglePointRubricDetailsQuery = gql`
//   query getSinglePointRubricDetails($id: ID!) {
//     node(id: $id, type: SinglePointRubric) {
//       ...singlePointRubricItem
//     }
//   }
//   ${singlePointRubricFragment.singlePointRubricFragment}
// `;
