import React from "react";
import { colors } from "Constants";
import classes from "./ProgressSteps.scss";

// steps is an array of step ids

const ProgressSteps = ({
  steps,
  currentStep,
  onClick,
  minHeight = 8,
  containerStyle = {},
  currentStepBackgroundColor,
}) => {
  return (
    <div
      className={classes.progressBarContainer}
      style={{ minHeight: minHeight, ...containerStyle }}
    >
      {_.map(steps, stepId => {
        const backgroundColor =
          currentStep == stepId ? currentStepBackgroundColor : colors.strokeTwo;
        return (
          <div
            key={stepId}
            className={classes.progressBar}
            style={{ backgroundColor: backgroundColor }}
            onClick={() => {
              onClick ? onClick(stepId) : null;
            }}
          />
        );
      })}
    </div>
  );
};

ProgressSteps.defaultProps = {
  currentStepBackgroundColor: colors.teal20,
};

export default ProgressSteps;
