import PropTypes from "prop-types";
import React from "react";

const LockedScreenIcon = props => {
  return (
    <svg
      viewBox="0 0 100 100"
      width={props.width}
      height={props.height}
      fill={props.fill && props.fill}
    >
      <path d="M50.9590291,0 L49.6467887,0 C35.2379992,0 23.5397032,11.2980519 22.9009157,25.5451406 L22.8734417,26.773347 L22.8734417,41.9953344 L22.5625068,42.8890344 C17.997861,48.6549027 15.3661037,55.6526512 15.03549,63.0956376 L15,64.6970911 L15,64.8283155 C15,83.810219 30.0657843,99.2978234 48.8830117,99.9767872 L50.1716849,100 L50.3029089,100 C69.3940486,100 84.902728,84.9528135 85.582575,66.1182285 L85.6058178,64.8283155 L85.6058178,64.6970911 C85.6058178,57.1945131 83.27837,50.080984 78.9917302,44.1431463 L78.043311,42.8890344 L77.732376,41.9953344 L77.732376,26.773347 C77.732376,11.9528778 65.7794983,0 50.9590291,0 Z M50.3029089,55.4178515 C53.1522038,55.4178515 55.4915925,57.4982833 55.4915925,60.0321776 L55.4915925,74.3387972 C55.4915925,76.8726924 53.1522038,78.9531241 50.3029089,78.9531241 C47.453614,78.9531241 45.1142253,76.8726924 45.1142253,74.3387972 L45.1142253,60.0321776 C45.1142253,57.4982833 47.453614,55.4178515 50.3029089,55.4178515 Z M50.9590291,7.6182839 C61.4647974,7.6182839 70.1140922,16.2675787 70.1140922,26.773347 L70.1140922,35.4520578 L67.9469063,34.1834612 C62.7546917,31.1441161 56.7007178,29.5254065 50.4341329,29.5254065 L50.3029089,29.5254065 C44.1297075,29.5254065 38.1917825,31.1535565 32.7672018,34.1966047 L30.6229497,35.3994778 L30.6229497,26.773347 C30.6229497,16.2458384 39.1624678,7.6182839 49.6467887,7.6182839 L50.9590291,7.6182839 Z" />
    </svg>
  );
};

LockedScreenIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

LockedScreenIcon.defaultProps = {
  height: 17,
  width: 17,
};

export default LockedScreenIcon;
