/**--external-- */
import React from "react";
import classNames from "classnames";
import { Button } from "@toddle-design/web";
import propTypes from "prop-types";
import { AddOutlined, DeleteOutlined } from "@toddle-design/web-icons";

/**--internal-- */
import { I18nHOC } from "UIComponents";

/**--relative-- */
import AdminOptions from "../AdminOptions";
import classes from "./Step4.scss";

const Step4 = props => {
  const { admins, t, addAdmin, deleteAdmin, updateAdmin } = props;

  const titleClasses = classNames("heading-3", classes.title);
  const descriptionClasses = classNames("text-body-2", classes.description);
  const adminLabelClasses = classNames("text-label-1", classes.adminLabel);

  const totalAdmins = _.size(admins);

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <div className={classes.headerContent}>
          <h3 className={titleClasses}>
            {t("common:add_with_label", { label: t("common:admin") })}
          </h3>
          <p className={descriptionClasses}>
            {t("onBoarding:add_admin_details")}
          </p>
        </div>
        <Button
          onClick={addAdmin}
          icon={<AddOutlined size="xx-small" />}
          size="medium"
          variant="outlined"
        >
          {t("common:add_with_label", { label: t("common:admin") })}
        </Button>
      </header>
      <main className={classes.adminContainer}>
        {_.map(admins, (admin, index) => {
          const isLastAdmin = index === totalAdmins - 1;

          return (
            <div key={admin.id} className={classes.adminOptions}>
              <div className={classes.adminOptionsHeader}>
                <p className={adminLabelClasses}>
                  {t("onBoarding:admin_with_number", {
                    number: totalAdmins - index,
                  })}
                </p>
                {!isLastAdmin && (
                  <Button
                    onClick={() => deleteAdmin({ id: admin.id })}
                    icon={<DeleteOutlined size="xx-small" />}
                    size="small"
                    variant="plain"
                  >
                    {t("common:delete")}
                  </Button>
                )}
              </div>
              <AdminOptions updateAdmin={updateAdmin} admin={admin} />
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default I18nHOC({ resource: ["onBoarding"] })(Step4);

Step4.propTypes = {
  admins: propTypes.array,
  t: propTypes.func,
  addAdmin: propTypes.func,
  deleteAdmin: propTypes.func,
  updateAdmin: propTypes.func,
};
