import React from "react";
import LockedScreen from "./LockedScreen";
import LockedScreenModal from "./LockedScreenModal";
import { FullScreenLoader } from "UIComponents";
import { SCHEDULE_MEETING_URL } from "modules/PermissionModule";
import { createPlanUpgradeRequest, openLinkInNewTab } from "modules/Services";
import { WEBSITE_URL } from "Constants";
import { connect } from "react-redux";
import * as EventTracker from "lib/eventTracker";

const LockedScreenWrapper = React.memo(props => {
  const {
    screenType,
    isActionLoading,
    createPlanUpgradeRequest,
    openLinkInNewTab,
    featureKey,
  } = props;
  const createPlanUpgradeRequestLocal = () => {
    createPlanUpgradeRequest({ screen: featureKey });
    EventTracker.recordEvent({
      eventName: "Clicked Schedule Demo",
      eventData: { from: "premium page" },
    });
    openLinkInNewTab({ url: SCHEDULE_MEETING_URL });
  };

  const onSeePlansClickedLocally = () => {
    openLinkInNewTab({ url: `${WEBSITE_URL}/pricing/` });
  };

  return (
    <React.Fragment>
      {screenType == "modal" ? (
        <LockedScreenModal
          {...props}
          createPlanUpgradeRequest={createPlanUpgradeRequestLocal}
          onSeePlansClicked={onSeePlansClickedLocally}
        ></LockedScreenModal>
      ) : (
        <LockedScreen
          {...props}
          createPlanUpgradeRequest={createPlanUpgradeRequestLocal}
          onSeePlansClicked={onSeePlansClickedLocally}
        ></LockedScreen>
      )}
      {isActionLoading && <FullScreenLoader></FullScreenLoader>}
    </React.Fragment>
  );
});

LockedScreenWrapper.displayName = "LockedScreenWrapper";

const mapActionCreators = {
  createPlanUpgradeRequest,
  openLinkInNewTab,
};

const mapStateToProps = state => {
  return {
    isActionLoading: state.app_services.isActionLoading,
  };
};

export default connect(mapStateToProps, mapActionCreators)(LockedScreenWrapper);
