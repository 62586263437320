import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import classes from "./ImageDropzone.scss";
import PropTypes from "prop-types";
import { UploadIcon, DeleteIcon } from "SvgComponents";
import { DropzoneComponent, MediaModal, I18nHOC, UIButton } from "UIComponents";
import { getRethumbUrl } from "Utils";
import classNames from "classnames";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { ResourceBank } from "AppComponents";
import ACLStore from "lib/aclStore";
import { CreateDropDown } from "Attachment";
import { uploadFileV2 } from "modules/Services";
import { ProgressIndicator, Button } from "@toddle-design/web";
import { UploadOutlined, AddOutlined } from "@toddle-design/web-icons";
const DROP_DOWN_OPTIONS = [
  { key: "upload_device", label: "common:upload_device" },
  { key: "add_google_drive", label: "common:add_google_drive" },
  { key: "add_one_drive", label: "common:add_one_drive" },
];

const styles = {
  buttonDropDownContainerStyle: {
    top: "158px",
    width: "100%",
  },
  buttonComponentStyle: {
    width: "100%",
  },
  buttonDropdownParentContainerStyle: {
    width: "100%",
  },
  hoverContainerStyles: {
    width: "100%",
  },
};

const isLocalUrl = url => {
  return _.includes(url, "blob");
};

const getRethumb = (localUrl, imageUrl) => {
  let rethumpUrl = "";
  if (localUrl) {
    rethumpUrl = localUrl;
  } else {
    rethumpUrl = imageUrl
      ? getRethumbUrl({
          width: 700,
          height: 700,
          imageUrl: imageUrl,
          fitIn: true,
        })
      : "";
  }
  return `url(${rethumpUrl})`;
};

class ImageDropZone extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      uploadObj: {},
      uploadingImage: false,
      showMediaModal: false,
      showResourceBank: false,
      fileCount: 0,
    };
  }
  onContentUploaded = params => {
    if (params.length > 0) {
      this.updateValue(params[0].url);
    }
    this.onUploaded();
  };

  onSingleContentUploaded = async (params, uploadId) => {
    this.updateValue(params.url);
    this.onUploaded();
  };

  onUploaded = () => {
    this.setState({ uploadingImage: false, uploadObj: {} });
    this.onBlur();
  };

  onDropContent = params => {
    const { onDropContent } = this.props;
    this.setState({ uploadObj: params });
    onDropContent && onDropContent();
  };

  updateFilesCount = count => {
    const fileCount = this.state.fileCount;
    this.setState({
      uploadingImage: fileCount + count > 0,
      fileCount: fileCount + count,
    });
    this.onFocus();
  };

  onCancelPressed = e => {
    let data = {};
    data[this.props.name] = "";
    this.setState({ uploadObj: {} });
    this.updateValue("");
    e.stopPropagation();
  };

  onAddFromUnsplash = e => {
    this.toggleResourceBank(true);
    e.stopPropagation();
  };

  dropzoneRenderComponent = () => {
    const {
      t,
      noDataCenterContainerStyle,
      dropZoneContainerStyle,
      customDropZoneText,
      buttonSize,
      buttonWrapperStyle,
      dropZoneTextStyle,
      isAssessmentCreationRevamp = false,
    } = this.props;
    return (
      <div className={classes.dropZoneContainer} style={dropZoneContainerStyle}>
        <div className={classes.dropZoneText} style={dropZoneTextStyle}>
          {customDropZoneText
            ? t(customDropZoneText)
            : t("common:photo_dragging_here")}
        </div>

        <div
          className={classes.noDataCenterContainer}
          style={noDataCenterContainerStyle}
        >
          <div className={classes.orContainer}>
            <div className={classes.searchLine} />
            <div className={classes.orText}>or</div>
            <div className={classes.searchLine} />
          </div>
        </div>
        <div className={classes.buttonWrapper} style={buttonWrapperStyle}>
          {isAssessmentCreationRevamp ? (
            <React.Fragment>
              <Button
                variant={"outlined-subtle"}
                icon={<UploadOutlined size={"xxx-small"} />}
                size={buttonSize}
              >
                {t("common:upload_with_label")}
              </Button>
              <Button
                variant={"outlined-subtle"}
                icon={<AddOutlined size={"xxx-small"} />}
                onClick={this.onAddFromUnsplash}
                size={buttonSize}
              >
                {"Unsplash"}
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <UIButton color="blue" type="hollow">
                {t("common:upload_device")}
              </UIButton>
              <UIButton
                color="blue"
                type="hollow"
                onClick={this.onAddFromUnsplash}
              >
                {t("common:add_from_unsplash")}
              </UIButton>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  dropzoneRenderOldComponent = () => {
    const {
      dropMessage,
      cardSize,
      dropZoneText,
      dropZoneTextStyle,
      dropZoneSubText,
      dropZoneSubTextStyle,
      customUploadIconElement,
      t,
    } = this.props;
    const uploadIconClass = classNames(
      { [classes.uploadIcon]: true },
      { [classes.smallUploadIcon]: cardSize == "small" }
    );
    const updatedDropZoneText = !_.isEmpty(dropZoneText)
      ? dropZoneText
      : t("common:upload_with_label", {
          label: t("common:file"),
        });
    const updatedDropZoneSubText = !_.isEmpty(dropZoneSubText)
      ? dropZoneSubText
      : t("common:or_drop_it_here");
    return (
      <div className={classes.dropZoneContainer}>
        {customUploadIconElement ? (
          customUploadIconElement
        ) : (
          <div className={uploadIconClass}>
            <UploadIcon width={32} height={32} />
          </div>
        )}

        <div className={classes.dropZoneText} style={dropZoneTextStyle}>
          {updatedDropZoneText}
        </div>
        <div className={classes.dropZoneSubText} style={dropZoneSubTextStyle}>
          {updatedDropZoneSubText}
        </div>
      </div>
    );
  };

  renderDropZone = () => {
    const {
      showUnsplash,
      parentId,
      parentType,
      cardSize,
      accept,
      showAllImportOptions = false,
    } = this.props;
    const renderComponent =
      showUnsplash && ACLStore.can("TeacherPortal:SearchFromUnsplash")
        ? this.dropzoneRenderComponent()
        : this.dropzoneRenderOldComponent();

    const buttonDropDownContainer = {
      ...styles.buttonDropDownContainerStyle,
      ...(cardSize == "small" ? { top: "136px" } : {}),
    };
    return (
      <DropzoneComponent
        parentType={parentType}
        parentId={parentId}
        accept={accept}
        onDropContent={this.onDropContent}
        onContentUploaded={this.onContentUploaded}
        multiple={false}
        updateFilesCount={this.updateFilesCount}
        disableClick={showAllImportOptions}
        renderComponent={
          showAllImportOptions ? (
            <CreateDropDown
              parentId={parentId}
              dropDownOptions={DROP_DOWN_OPTIONS}
              buttonComponent={renderComponent}
              parentType={parentType}
              addAttachment={this.onContentUploaded}
              accept={accept}
              onDropContent={this.onDropContent}
              multiselect={false}
              updateFilesCount={this.updateFilesCount}
              buttonDropDownContainerStyle={buttonDropDownContainer}
              buttonComponentStyle={styles.buttonComponentStyle}
              buttonDropdownParentContainerStyle={
                styles.buttonDropdownParentContainerStyle
              }
              hoverContainerStyles={styles.hoverContainerStyles}
              enableShareModal={false}
            />
          ) : (
            renderComponent
          )
        }
      />
    );
  };

  toggleMediaModal = value => {
    this.setState({ showMediaModal: value });
  };

  toggleResourceBank = (value = false) => {
    this.setState({ showResourceBank: value });
  };

  addResources = () => {};

  renderEdit = () => {
    let component = this.renderDropZone();
    const {
      uploadBoxStyles,
      progressOfUploads,
      value,
      imageStyle,
      cardSize,
      isDisabled,
      parentType,
      parentId,
      uploadingObjRedux,
    } = this.props;
    const {
      uploadObj: uploadObjState,
      uploadingImage,
      showMediaModal,
      showResourceBank,
    } = this.state;

    let uploadObj =
      (!!parentType && !!parentId) || !_.isEmpty(uploadingObjRedux)
        ? _.get(uploadingObjRedux, "attachment", {})
        : uploadObjState;

    let progressValue = parseInt(
      _.get(
        progressOfUploads,
        `${this.state.uploadObj.id}.progress`,
        uploadingImage ? "0" : "100"
      )
    );

    const url = value || uploadObj.url;
    const uploadImageComponentClass = classNames(
      { [classes.uploadImageComponent]: true },
      { [classes.smallUploadImageComponent]: cardSize == "small" }
    );

    return (
      <div className={uploadImageComponentClass} style={uploadBoxStyles}>
        {!!url ? (
          <div
            style={{
              backgroundImage: getRethumb(uploadObj.url, value),
              ...(imageStyle || {}),
            }}
            className={classes.imageBox}
            onClick={() => this.toggleMediaModal(true)}
          >
            {progressValue >= 100 && !isDisabled && (
              <div
                className={classes.deleteSvg}
                onClick={e => {
                  this.onCancelPressed(e);
                }}
              >
                <DeleteIcon height={16} width={16} />
              </div>
            )}
            {(progressValue < 100 || uploadingImage) && (
              <div className={classes.uploadingContainer}>
                <div className={classes.progressBar}>
                  <ProgressIndicator
                    variant="preview-loader"
                    progress={progressValue}
                  ></ProgressIndicator>
                </div>
              </div>
            )}
          </div>
        ) : (
          component
        )}
        {isDisabled && <div className={classes.disbaledImageContainer}></div>}
        {showMediaModal && (
          <MediaModal
            toggleMediaModal={this.toggleMediaModal}
            attachments={[{ url, type: "IMAGE" }]}
          />
        )}
        {!!showResourceBank && (
          <ResourceBank
            id={"CREATE_DROPDOWN"}
            onDropContent={this.onDropContent}
            multi={false}
            onSingleContentUploaded={this.onSingleContentUploaded}
            updateFilesCount={this.updateFilesCount}
            closeResource={this.toggleResourceBank}
            onAddClick={this.addResources}
            parentType={parentType}
            parentId={parentId}
          />
        )}
      </div>
    );
  };
}

ImageDropZone.propTypes = {
  ...UIBaseComponent.propTypes,
  onCancelPressed: PropTypes.func,
  customUploadIconElement: PropTypes.element,
  dropZoneTextStyle: PropTypes.object,
  dropZoneSubTextStyle: PropTypes.object,
  onDropContent: PropTypes.func,
};

ImageDropZone.defaultProps = {
  ...UIBaseComponent.defaultProps,
  imageStyle: {},
  cardSize: "large",
  dropMessage: "or drag them in",
  showUnsplash: true,
  shouldUpload: true,
  accept: "image/jpeg,image/png,image/jpg",
  buttonSize: "medium",
  customUploadIconElement: null,
  dropZoneTextStyle: {},
  dropZoneSubTextStyle: {},
  onDropContent: _.noop,
};

const mapActionCreators = {
  uploadFileV2,
};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;

  return {
    progressOfUploads,
    uploadingObjRedux:
      _.find(
        Object.values(progressOfUploads),
        item =>
          item.parentType == ownProps.parentType &&
          item.parentId == ownProps.parentId
      ) || {},
  };
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators)
)(ImageDropZone);
