/**--external-- */
import React from "react";
import classNames from "classnames";

/**--relative-- */
import { getOrganizationStatus } from "./OrganizationStatusUtils";
import classes from "./OrganizationStatus.scss";

const OrganizationStatus = props => {
  const { academicYears } = props;
  const currentAcademicYear = _.find(
    academicYears,
    ({ isCurrentAcademicYear }) => isCurrentAcademicYear
  );

  const status = getOrganizationStatus({ academicYear: currentAcademicYear });

  switch (status) {
    case "IN_30_7": {
      const statusClasses = classNames(
        "text-body-1",
        classes.in30Days,
        classes.status
      );
      return <p className={statusClasses}>{`30 days left`}</p>;
    }
    case "IN_7_0": {
      const statusClasses = classNames(
        "text-body-1",
        classes.in7Days,
        classes.status
      );
      return <p className={statusClasses}>{`7 days left`}</p>;
    }
    case "OVERDUE": {
      const statusClasses = classNames(
        "text-body-1",
        classes.overdueStatus,
        classes.status
      );
      return <p className={statusClasses}>{`Overdue`}</p>;
    }
    default: {
      return null;
    }
  }
};

export default OrganizationStatus;

OrganizationStatus.displayName = "OrganizationStatus";
