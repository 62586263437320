import React from "react";
import PropTypes from "prop-types";
import { I18nHOC, LinkWithTooltip } from "UIComponents";
import classes from "./TaggedPracticesV2.scss";
import { StarFilled } from "@toddle-design/web-icons";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import styles from "./TaggedPracticesV2Styles";
import { getSnPEvaluationCyclesFromCache } from "SnP_v2/modules/SnPGraphqlHelpers";
import { getSettingValue } from "modules/PermissionModule";
import { Trans } from "react-i18next";

const ApprovedPracticeMessage = props => {
  const { itemTypeLabel, itemTitle, cycleType, cycleName } = props;
  return (
    <LinkWithTooltip
      tooltip={
        <Trans i18nKey={"snp:evidence_approved_for_practice_label"}>
          {{ evidenceTypeLabel: itemTypeLabel }}

          <div className={classes.highlightText}>{{ title: itemTitle }}</div>

          {{ cycleType }}

          <div className={classes.highlightText}>{{ cycleName }}</div>
        </Trans>
      }
      tooltipContainerStyle={styles.tooltipContainerStyle}
      placement={"bottom"}
      alignConfig={{ offset: [4, -8] }}
    >
      <div className={classes.approvedPractice}>
        <StarFilled size={"xxx-small"} className={classes.starIcon} />
      </div>
    </LinkWithTooltip>
  );
};

const TaggedPracticesV2 = props => {
  const {
    taggedPractices,
    t,
    showPracticeStatus,
    snpEvaluationCycles,
    postTitle,
    organizationId,
  } = props;

  const requirementLabelSetting = getSettingValue({
    organizationId,
    name: "AppSettingSnpRequirementLabel",
  });

  const requirementLabel = t(`snp:${requirementLabelSetting}`, {
    defaultValue: requirementLabelSetting,
  });

  return (
    <div className={classes.container}>
      {_.map(
        taggedPractices,
        ({ taggedPractice, evaluationCycle: { id: cycleId } }, index) => {
          const { title, cycleType } =
            _.find(snpEvaluationCycles, ["id", cycleId]) ?? {};

          return (
            <div className={classes.nodeContainer} key={index}>
              <div className={classes.practicesContainer}>
                {_.map(
                  taggedPractice,
                  ({
                    practice: { id, code, type, label, localizedLabel },
                    evidenceStatus,
                  }) => {
                    return (
                      <div className={classes.practice} key={id}>
                        <div className={classes.practiceHeader}>
                          <span className={classes.code}>{`${code}`} </span>
                          <span className={classes.type}>{`(${
                            type == "REQUIREMENTS" ? requirementLabel : type
                          })`}</span>
                          {evidenceStatus === "APPROVED" &&
                            showPracticeStatus && (
                              <ApprovedPracticeMessage
                                itemTypeLabel={t("common:post")}
                                cycleType={cycleType}
                                cycleName={title}
                                itemTitle={postTitle || t("common:untitled")}
                              />
                            )}
                        </div>
                        <div>
                          {t(`snpTemplate:${label}`, {
                            defaultValue: localizedLabel,
                          })}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  const curriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType",
    ""
  );

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  const curriculumProgramId = _.get(
    _.find(
      organizationCurriculumPrograms,
      ({ type }) => type === curriculumProgramType
    ),
    "id",
    ""
  );

  const { snpEvaluationCycles } = getSnPEvaluationCyclesFromCache({
    id: organizationId,
    filters: {
      isArchived: false,
      curriculumProgramId,
    },
  });

  return { snpEvaluationCycles, organizationId };
};

export default compose(
  I18nHOC({ resources: ["snpTemplate", "snp", "common"] }),
  connect(mapStateToProps)
)(TaggedPracticesV2);

TaggedPracticesV2.defaultProps = {
  showPracticeStatus: true,
};

TaggedPracticesV2.propTypes = {
  showPracticeStatus: PropTypes.bool,
};
