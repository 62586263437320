import PropTypes from "prop-types";
import React from "react";

const Summative = ({ fill, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5625 7.08332H32.4375C33.7147 7.08332 34.75 8.11867 34.75 9.39582C34.75 10.673 33.7147 11.7083 32.4375 11.7083H18.5625C17.2854 11.7083 16.25 10.673 16.25 9.39582C16.25 8.11867 17.2854 7.08332 18.5625 7.08332ZM13.6859 7.08332C14.552 5.26034 16.4101 3.99999 18.5625 3.99999H32.4375C34.59 3.99999 36.4481 5.26034 37.3141 7.08332H37.8333C41.2391 7.08332 44 9.84423 44 13.25V37.9167C44 43.0253 39.8586 47.1667 34.75 47.1667H16.25C11.1414 47.1667 7 43.0253 7 37.9167V13.25C7 9.84422 9.76091 7.08332 13.1667 7.08332H13.6859ZM15.85 31.3971C15.527 31.1121 15.242 31.0361 14.995 31.0361C14.349 31.0361 13.874 31.5491 13.874 32.1571C13.874 32.4421 13.988 32.8031 14.406 33.1451C15.318 33.9431 16.705 34.3421 18.092 34.3421C20.733 34.3421 22.994 32.9361 22.994 30.0481C22.994 27.4071 20.79 26.6471 19.061 26.1151C17.541 25.6401 16.496 25.2791 16.496 24.0441C16.496 22.7331 17.769 22.2011 18.871 22.2011C19.384 22.2011 19.992 22.3531 20.676 22.8281C21.075 23.1131 21.265 23.1321 21.474 23.1321C22.082 23.1321 22.519 22.6571 22.519 22.0871C22.519 21.7641 22.405 21.4221 21.93 21.0801C21.17 20.5101 20.049 20.2061 18.966 20.2061C16.553 20.2061 14.064 21.4221 14.064 24.1581C14.064 26.6661 15.831 27.4641 17.465 27.9771L17.6123 28.0234C19.2369 28.5333 20.562 28.9492 20.562 30.3141C20.562 31.7581 19.251 32.3091 18.13 32.3091C17.332 32.3091 16.515 31.9861 15.85 31.3971ZM36.8612 34.1711C36.4622 34.1711 35.9682 34.0191 35.6452 33.2401L34.6762 30.9221H28.5012L27.5322 33.2591C27.2282 34.0191 26.7342 34.1711 26.3352 34.1711C25.6892 34.1711 25.1382 33.7341 25.1382 32.9931C25.1382 32.8031 25.1762 32.5941 25.3092 32.2901L30.1542 21.5171C30.6102 20.5101 31.1802 20.3771 31.6362 20.3771C32.0922 20.3771 32.6812 20.5101 33.1372 21.5171L37.9442 32.2521C38.0772 32.5561 38.0962 32.7841 38.0962 32.9361C38.0962 33.7151 37.5072 34.1711 36.8612 34.1711ZM33.8402 28.8701L31.6172 23.4741L29.3562 28.8701H33.8402Z"
        fill={fill}
      />
    </svg>
  );
};

Summative.defaultProps = {
  fill: "#04363D",
  width: "24",
  height: "24",
};

Summative.propTypes = {
  fill: PropTypes.string,
};

export default Summative;
