import React from "react";
import classes from "./FeatureCard.scss";
import classNames from "classnames";
import ImageCarousel from "../../ImageCarousel";
import { I18nHOC } from "UIComponents";

const FeatureCard = React.memo(props => {
  const { feature, photoOnLeft, t } = props;
  const { title, description, images } = feature;
  const containerClass = classNames(
    { [classes.container]: true },
    { [classes.reverseContainer]: !photoOnLeft }
  );
  const textContainerClass = classNames(
    { [classes.textContainer]: true },
    { [classes.reverseTextContainer]: !photoOnLeft }
  );

  return (
    <div className={containerClass}>
      <div className={textContainerClass}>
        <div className={classes.title}>{t(title)}</div>
        <div className={classes.description}>{t(description)}</div>
      </div>
      <div className={classes.imageContainer}>
        <ImageCarousel
          images={images}
          imageClass={classes.image}
        ></ImageCarousel>
      </div>
    </div>
  );
});

export default I18nHOC({ resource: "blockScreen" })(FeatureCard);
