import React from "react";

const ScopeAndSequenceSvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 100 100">
      <g stroke="none" strokeWidth="1" fill={props.fill} fillRule="evenodd">
        <path d="M87.4481,83.5092 L87.4481,14.6582 C87.4481,9.9502 83.6191,6.1212 78.9111,6.1212 L21.7251,6.1212 C16.9811,6.1212 13.1211,9.9502 13.1211,14.6582 L13.1211,83.5092 C13.1211,89.2152 17.7631,93.8572 23.4691,93.8572 L77.1011,93.8572 C82.8061,93.8572 87.4481,89.2152 87.4481,83.5092 Z M78.9111,0.0002 C86.9941,0.0002 93.5691,6.5752 93.5691,14.6582 L93.5691,83.5092 C93.5691,92.5902 86.1811,99.9772 77.1011,99.9772 L23.4691,99.9772 C14.3871,99.9772 7.0001,92.5902 7.0001,83.5092 L7.0001,14.6582 C7.0001,6.5752 13.6051,0.0002 21.7251,0.0002 L78.9111,0.0002 Z M70.6024,33.0941 C72.2924,33.0941 73.6634,34.4641 73.6634,36.1551 C73.6634,37.8441 72.2924,39.2151 70.6024,39.2151 L49.5764,39.2151 C47.8854,39.2151 46.5154,37.8451 46.5154,36.1551 C46.5154,34.4651 47.8854,33.0941 49.5764,33.0941 L70.6024,33.0941 Z M70.6024,60.7621 C72.2924,60.7621 73.6634,62.1321 73.6634,63.8231 C73.6634,65.5121 72.2924,66.8831 70.6024,66.8831 L49.5764,66.8831 C47.8854,66.8831 46.5154,65.5131 46.5154,63.8231 C46.5154,62.1331 47.8854,60.7621 49.5764,60.7621 L70.6024,60.7621 Z M34.9764,33.0941 C36.6664,33.0941 38.0374,34.4641 38.0374,36.1541 C38.0374,37.8441 36.6664,39.2151 34.9764,39.2151 L29.9664,39.2151 C28.2764,39.2161 26.9064,37.8451 26.9064,36.1551 C26.9064,34.4651 28.2764,33.0941 29.9664,33.0941 L34.9764,33.0941 Z M34.9764,60.7621 C36.6664,60.7621 38.0374,62.1321 38.0374,63.8221 C38.0374,65.5121 36.6664,66.8831 34.9764,66.8831 L29.9664,66.8831 C28.2764,66.8841 26.9064,65.5131 26.9064,63.8241 C26.9064,62.1331 28.2764,60.7621 29.9664,60.7621 L34.9764,60.7621 Z" />
      </g>
    </svg>
  );
};

ScopeAndSequenceSvg.defaultProps = {
  fill: "white",
  height: 20,
  width: 20,
};

export default ScopeAndSequenceSvg;
