import { cloneElement } from "react";

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
  type = "wrapperAsFunction",
  ...otherProps
}) => {
  switch (type) {
    case "wrapperAsFunction": {
      return condition
        ? wrapper({ children, ...otherProps })
        : cloneElement(children, { ...otherProps });
    }
    case "wrapperAsElement": {
      return condition
        ? cloneElement(wrapper, { children, ...otherProps })
        : cloneElement(children, { ...otherProps });
    }
  }
};

export default ConditionalWrapper;

/*
Sample usage for `wrapperAsFunction`
      <ConditionalWrapper
        condition={addLink}
        wrapper={children => <a href={link}>{children}</a>}
      >
        <span> This will be link based on a condition </span>
      </ConditionalWrapper
*/

/* 
Sample usage for `wrapperAsElement`
      <ConditionalWrapper
        condition={addLink}
        wrapper={<a href={link} />}
      >
        <span> This will be link based on a condition </span>
      </ConditionalWrapper

*/
