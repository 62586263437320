import client from "apolloClient";
import { getCurriculumAttendanceOptionsQuery } from "modules/CommonQuery";

export const writeOrganizationAttendanceOptionsToCache = ({
  id,
  curriculumId,
  filter,
  data,
}) => {
  try {
    client.writeQuery({
      query: getCurriculumAttendanceOptionsQuery,
      variables: {
        id,
        curriculumId,
        filter,
      },
      data,
    });
  } catch (e) {}
};
