import React from "react";
import { ToastContainer, ToastMessage } from "react-toastr";
import { CancelIcon } from "SvgComponents";
import { colors } from "Constants";
import classes from "./Toastr.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  TickCircleFilled,
  WarningTriangleFilled,
} from "@toddle-design/web-icons";
import { Button } from "@toddle-design/web";
import _ from "lodash";

const ToastMessageFactory = React.createFactory(ToastMessage.animation);

const PositionToAnimationTypeMap = {
  "toast-bottom-right": {
    showAnimation: "slideInRight",
    hideAnimation: "slideOutRight",
  },
  "toast-bottom-left": {
    showAnimation: "slideInLeft",
    hideAnimation: "slideOutLeft",
  },
  "toast-bottom-center": {
    showAnimation: "slideInUp",
    hideAnimation: "slideOutDown",
  },
  "toast-top-center": {
    showAnimation: "slideInDown",
    hideAnimation: "slideOutUp",
  },
};

class Toastr extends React.PureComponent {
  showToast = ({
    message,
    type = "alert",
    timeOut,
    onClick,
    closeButton,
    theme,
    actions = [],
  }) => {
    const { position } = this.props;

    const buttonComponent = () => {
      const buttonContainerClass = classNames(classes.buttonContainer, {
        [classes.multiItemContainer]: actions.length !== 1,
      });
      return (
        <div className={buttonContainerClass}>
          {_.map(actions, (action, index) => {
            const onClickButtonHandler = e => {
              e.stopPropagation();
              action.onClick?.();
            };
            const showDot = actions.length !== index + 1;
            return (
              <div className={classes.buttonInnerContainer} key={action.name}>
                <Button
                  variant={"inline-progressive"}
                  onClick={onClickButtonHandler}
                  size={"small"}
                >
                  {action.label}
                </Button>
                {showDot && <div className={classes.textDot}></div>}
              </div>
            );
          })}
        </div>
      );
    };

    const innerContainerClass = classNames(classes.innerContainer, {
      [classes.innerItemContainer]: actions.length !== 1,
    });

    const containerClass = classNames(classes.container, {
      [classes.dark_bg]: theme === "dark",
    });
    const alertTextClass = classNames(
      classes.alertText,
      theme === "dark" ? classes.darkText : classes.lightText
    );
    this.toastContainer.success(
      "",
      <div className={containerClass}>
        <div className={classes.alertMessage} onClick={onClick}>
          {type && (
            <div className={classes.Svg}>
              {type === "alert" ? (
                <WarningTriangleFilled variant={"warning"} />
              ) : (
                <TickCircleFilled variant={"link"} />
              )}
            </div>
          )}
          <div className={innerContainerClass}>
            <div
              className={alertTextClass}
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
            {actions.length >= 1 && buttonComponent()}
          </div>
        </div>
        {closeButton && (
          <div className={classes.closeButton}>
            <CancelIcon
              fill={theme === "dark" ? colors.white : colors.black}
              width={10}
              height={10}
            />
          </div>
        )}
      </div>,
      {
        timeOut: closeButton ? 30000 : timeOut,
        onClick,
        ...PositionToAnimationTypeMap[position],
      }
    );
  };

  render() {
    const toastContainerClass = this.props.position;

    return (
      <div className={classes.toastr}>
        <ToastContainer
          toastMessageFactory={ToastMessageFactory}
          ref={ref => {
            this.toastContainer = ref;
          }}
          className={toastContainerClass}
          preventDuplicates={false}
        />
      </div>
    );
  }
}

Toastr.propTypes = {
  position: PropTypes.string,
};

Toastr.defaultProps = {
  position: "toast-bottom-right",
  closeButton: false,
  theme: "dark",
};

export default Toastr;
