import React from "react";
import { connect } from "react-redux";
import { ProgressSteps, UIModal, I18nHOC, UIButton } from "UIComponents";
import classes from "./AssessmentSelectModal.scss";

import AssessmentSelectionStep from "./AssessmentSelectionStep";
import StudentSelectionStep from "./StudentSelectionStep";

import UnitSelection from "../UnitSelection";
import { compose } from "react-apollo";
import { CancelIcon } from "SvgComponents";

const unitSelectionStepStyles = {
  itemFeedContainer: {
    gridTemplateColumns: "repeat(auto-fill, 272px)",
  },
};
class AssessmentSelectModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleScroll = React.createRef();
    this.state = {
      selectionStep: 1,
      selectedUnit: null,
      isAddButtonEnabled: false,
    };
  }

  onStepChanged = ({ selectionStep, unit }) => {
    if (!_.includes(this.props.steps, selectionStep)) {
      return;
    }
    if (unit) {
      this.setState({ selectionStep, selectedUnit: unit });
    } else {
      this.setState({ selectionStep });
    }
  };

  goToStep = step => {
    this.setState({ selectionStep: step });
  };

  onClose = () => {
    const { onClose, updateState } = this.props;
    onClose();
    updateState({ resourceItems: [] });
  };

  UNSAFE_componentWillMount = () => {
    this.props.mode === "EDIT"
      ? this.onStepChanged({ selectionStep: 3 })
      : null;
  };

  getSelectionStep = () => {
    const { selectionStep, selectedUnit } = this.state;
    const {
      createItemMutation,
      updateState,
      courseId,
      updateResourceItems,
      onClose,
      mode,
      itemsToEdit,
      isAddButtonEnabled,
      type,
      resourceItems,
      excludeUnitPlanIds,
      t,
      unitPlanSelectionProps,
      assessmentEvaluationId,
      groupType,
      emptySubTitle,
    } = this.props;

    switch (selectionStep) {
      case 1:
        return (
          <div className={classes.unitSelectionWrapper}>
            <div className={classes.resourceFeedHeader}>
              <div className={classes.resourceFeedHeaderText}>
                {t("common:select_with_label", {
                  label: t("common:unit"),
                })}
              </div>
              <div className={classes.cancelIcon} onClick={onClose}>
                <CancelIcon width={16} heigth={16} />
              </div>
            </div>
            <div
              className={classes.scrollContainer}
              onScroll={e => this.handleScroll?.current?.(e)}
            >
              <UnitSelection
                grades={[]}
                updateSelectedItems={unitId => {
                  this.onStepChanged({
                    selectionStep: 2,
                    unit: { id: unitId },
                  });
                }}
                {...unitPlanSelectionProps}
                handleScroll={this.handleScroll}
                isQueryPaginated={true}
                isRadioBehavior={true}
                styles={unitSelectionStepStyles}
                excludeUnitPlanIds={excludeUnitPlanIds}
                selectedItems={_.get(selectedUnit, "id", "")}
                emptySubTitle={emptySubTitle}
              />
            </div>
          </div>
        );
      // break;
      case 2:
        return (
          <AssessmentSelectionStep
            onNextClick={() => this.onStepChanged({ selectionStep: 3 })}
            onBackClick={() => this.onStepChanged({ selectionStep: 1 })}
            unitPlan={selectedUnit}
            createItemMutation={createItemMutation}
            updateState={updateState}
            updateResourceItems={updateResourceItems}
            courseId={courseId}
            onClose={onClose}
            updateAddButtonState={this.updateAddButtonState}
            isAddButtonEnabled={isAddButtonEnabled}
            type={type}
            resourceItems={resourceItems}
            groupType={groupType}
          />
        );
      // break;
      case 3:
        return (
          <StudentSelectionStep
            onBackClick={() => this.onStepChanged({ selectionStep: 2 })}
            createItemMutation={createItemMutation}
            updateState={updateState}
            mode={mode}
            itemsToEdit={itemsToEdit}
            updateResourceItems={updateResourceItems}
            courseId={courseId}
            onClose={onClose}
            updateAddButtonState={this.updateAddButtonState}
            isAddButtonEnabled={isAddButtonEnabled}
            type={type}
            resourceItems={resourceItems}
            assessmentEvaluationId={assessmentEvaluationId}
          />
        );
      // break;

      default:
        return <div />;
      // break;
    }
  };

  isLastStep = () => {
    const { steps } = this.props;
    const { selectionStep } = this.state;
    return selectionStep == steps[steps.length - 1];
  };

  onClickAdd = async () => {
    const {
      createItemMutation,
      onClose,
      mode,
      includedStudents,
      itemsToEdit,
      type,
      onClickAdd,
    } = this.props;

    try {
      if (type == "ASSESSMENT_EVALUATION") {
        if (mode === "ADD") {
          await createItemMutation({});
        } else {
          const selectedStudentIds = _.reduce(
            itemsToEdit,
            (result, { isActive, id }) => {
              if (isActive) {
                result.push(id);
              }
              return result;
            },
            []
          );

          await createItemMutation({
            addedStudents: _.difference(includedStudents, selectedStudentIds),
            removedStudents: _.difference(selectedStudentIds, includedStudents),
          });
        }
      }
      if (onClickAdd) {
        onClickAdd();
      }
      onClose();
    } catch {}
  };

  updateAddButtonState = state => {
    this.setState({ isAddButtonEnabled: state });
  };

  render() {
    const selectionStepScreen = this.getSelectionStep();
    const { selectionStep, isAddButtonEnabled } = this.state;
    const { onClose, mode, steps, t } = this.props;
    return (
      <UIModal
        modalContent={classes.modalContent}
        isOpen={true}
        onRequestClose={onClose}
      >
        <React.Fragment>
          {mode === "ADD" ? (
            <div className={classes.progressBarContainer}>
              <ProgressSteps
                steps={steps}
                currentStep={selectionStep}
                minHeight={4}
              />
            </div>
          ) : null}

          {selectionStepScreen}
          {this.isLastStep() && (
            <div className={classes.footer}>
              <UIButton
                size="sm"
                color="pink"
                onClick={this.onClickAdd}
                isDisabled={!isAddButtonEnabled}
              >
                {mode === "ADD" ? t("common:add") : t("common:update")}
              </UIButton>
            </div>
          )}
        </React.Fragment>
      </UIModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    includedStudents: _.get(
      state,
      `assessmentEvaluation.resourceItems.includedStudents`,
      []
    ),
    courseId: ownProps.courseId,
  };
};

AssessmentSelectModal.defaultProps = {
  steps: [1, 2, 3],
  unitPlanSelectionProps: {
    //Props for Unit Plan Selection Step
    parentType: "COURSE", //From where you want to fetch unit plans. E.g - COURSE,STAFF
  },
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps)
)(AssessmentSelectModal);
