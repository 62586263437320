import React from "react";
import classes from "./TagStudents.scss";
import ScreenPanel from "../../ScreenPanel";
import { SearchInput, Button } from "@toddle-design/web";
import { I18nHOC } from "UIComponents";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { getCourseFilteredStudentQuery } from "Post/modules/PostQuery";
import StudentList from "./StudentList";
import { getCourseFilteredStudentFromCache } from "Post/modules/PostGraphqlHelpers";
import { updateStudentListFilters } from "Post/modules/PostModule";
import { getCountString, getUpdatedArchivedFilters } from "Utils";

const FooterComponent = React.memo(props => {
  const { onDoneClick, selectedStudentIds, t } = props;
  const countString = t("journal:studentSelected", {
    count: selectedStudentIds.length,
  });
  return (
    <div className={classes.footerButtonContainer}>
      <div className={classes.footerText}>{countString}</div>
      <Button size={"medium"} onClick={onDoneClick}>
        {t("common:done")}
      </Button>
    </div>
  );
});
class TagStudents extends React.PureComponent {
  constructor(props) {
    super(props);
    const selectedStudents = _.get(
      props.screenPanelProps,
      "postDetails.students.edges",
      []
    );

    const selectedStudentIds = _.map(selectedStudents, item => {
      return item.node.id;
    });

    this.state = {
      prevSelectedStudentIds: selectedStudentIds,
      selectedStudentIds: selectedStudentIds,
    };
  }

  onSearchTextChange = value => {
    const { updateStudentListFilters } = this.props;
    updateStudentListFilters({ searchText: value });
  };

  isAllSelected = () => {
    const { students = [] } = this.props;
    const { selectedStudentIds } = this.state;

    return (
      _.get(
        _.intersection(
          selectedStudentIds,
          _.map(students, item => item.node.id)
        ),
        "length",
        0
      ) == students.length
    );
  };

  onItemCheckBoxClick = item => {
    const {
      students,
      userInfo: { id: userId, user_type: userType },
    } = this.props;
    const { selectedStudentIds } = this.state;
    let updatedSelectedStudentIds = [];

    if (item.id == "ALL") {
      const isAllSelected = this.isAllSelected();
      // if not student uncheck all checkboxes otherwise  uncheck all except the student's checkox
      if (isAllSelected) {
        updatedSelectedStudentIds = userType == "student" ? [userId] : [];
      } else {
        updatedSelectedStudentIds = _.map(students, item => {
          return item.node.id;
        });
      }
    } else {
      const isChecked = _.includes(selectedStudentIds, item.id);

      // Make sure the selectedStudentId list doesn't update when user clicks on its own checkbox
      if (isChecked) {
        updatedSelectedStudentIds =
          userType == "student" && userId == item.id
            ? selectedStudentIds
            : _.filter(selectedStudentIds, id => id != item.id);
      } else {
        updatedSelectedStudentIds = [...selectedStudentIds, item.id];
      }
    }

    this.setState({ selectedStudentIds: updatedSelectedStudentIds });
  };

  onDoneClick = () => {
    this.props.updateStudentListFilters({ searchText: "" });
    setTimeout(() => {
      const {
        screenPanelProps: { updatePost, onCloseClick } = {},
        students,
      } = this.props;
      const { selectedStudentIds, prevSelectedStudentIds } = this.state;
      let selectedStudent = [];
      _.forEach(students, item => {
        if (_.includes(selectedStudentIds, item.node.id)) {
          selectedStudent.push(item);
        }
      });

      const studentsEmpty = _.get(selectedStudent, "length", 0) == 0;

      let privateState = {};

      //Here we will not update the isPrivate and isHiddenFromStudents in case prev and new tagged student
      // are more than one

      if (prevSelectedStudentIds.length > 0 && selectedStudentIds.length > 0) {
      } else {
        privateState = {
          isPrivate: studentsEmpty,
          isHiddenFromStudents: studentsEmpty,
        };
      }

      updatePost({
        students: { edges: selectedStudent },
        ...privateState,
      });
      onCloseClick();
    });
  };

  render() {
    const {
      screenPanelProps,
      isData,
      isLoading,
      students,
      filters,
      userInfo,
      t,
    } = this.props;
    const { selectedStudentIds } = this.state;
    const searchText = _.get(filters, "searchText", "");
    const isAllSelected = this.isAllSelected();
    return (
      <ScreenPanel
        {...screenPanelProps}
        footerComponent={
          !(_.isEmpty(students) && !searchText) && (
            <FooterComponent
              t={t}
              onDoneClick={this.onDoneClick}
              selectedStudentIds={selectedStudentIds}
            />
          )
        }
      >
        <div className={classes.container}>
          {!(_.isEmpty(students) && !searchText) && (
            <div className={classes.searchContainer}>
              <SearchInput
                value={searchText}
                placeholder={t("search_for_student")}
                onChange={this.onSearchTextChange}
              />
            </div>
          )}
          <StudentList
            userInfo={userInfo}
            isData={isData}
            isLoading={isLoading}
            students={students}
            selectedStudentIds={selectedStudentIds}
            onItemCheckBoxClick={this.onItemCheckBoxClick}
            isAllSelected={isAllSelected}
            searchText={filters.searchText}
            showLoaderBar={false}
          />
        </div>
      </ScreenPanel>
    );
  }
}

const mapActionCreators = { updateStudentListFilters };

const mapStateToProps = (state, ownProps) => {
  const courseIdFromParent = _.get(
    ownProps,
    "screenPanelProps.postDetails.courseId"
  );
  return {
    courseId: courseIdFromParent
      ? courseIdFromParent
      : state.teacher.selected_class.selected_course,
    filters: state.post.studentListFilters,
    userInfo: state.login.userInfo,
    postId: _.get(ownProps, "screenPanelProps.postDetails.id"),
  };
};

export default compose(
  I18nHOC({ resource: ["journal", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseFilteredStudentQuery, {
    name: "getCourseFilteredStudent",
    options: ({ courseId, filters, postId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
        filters: getUpdatedArchivedFilters(filters, {
          entityType: "JOURNAL",
          entityId: postId,
        }),
      },
    }),
    props({
      getCourseFilteredStudent,
      ownProps: {
        courseId,
        filters,
        postId,
        userInfo: { id: userId, user_type: userType },
      },
    }) {
      const data = getCourseFilteredStudentFromCache({
        courseId,
        filters: getUpdatedArchivedFilters(filters, {
          entityType: "JOURNAL",
          entityId: postId,
        }),
      });
      let students = _.get(data, "students.edges", []);
      // Arrange the students list for student with its name on top
      if (
        userType == "student" &&
        !_.isEmpty(_.get(data, "students.edges", []))
      ) {
        let student = _.find(_.get(data, "students.edges", []), item =>
          _.isEqual(item.node.id, userId)
        );

        let restStudents = _.filter(
          _.get(data, "students.edges", []),
          item => !_.isEqual(item.node.id, userId)
        );

        students = _.isEmpty(student)
          ? restStudents
          : [student, ...restStudents];
      }

      return {
        isData: !_.isEmpty(data),
        students,
        networkStatus: getCourseFilteredStudent.networkStatus,
        isLoading:
          getCourseFilteredStudent["networkStatus"] == 1 ||
          getCourseFilteredStudent["networkStatus"] == 2,
      };
    },
  })
)(TagStudents);
