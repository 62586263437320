import PropTypes from "prop-types";
import React from "react";

const BottomArrow = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill={props.fill && props.fill}
    >
      <path
        d="M2.8,4.9c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l5.5,5.5c0.2,0.2,0.5,0.2,0.7,0c0,0,0,0,0,0l5.5-5.5c0.2-0.2,0.2-0.5,0-0.7
      s-0.5-0.2-0.7,0L8,10L2.8,4.9z"
      />
    </svg>
  );
};

BottomArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

BottomArrow.defaultProps = {
  width: 16,
  height: 16,
};
export default BottomArrow;
