import { fontStyle } from "Constants";

export const styles = {
  onBoardedFeedListStyles: {
    parentStyle: {
      gridTemplateColumns: "280px repeat(7,1fr) 120px",
      justifyItems: "center",
    },
  },
  onBoardingFeedListStyles: {
    parentStyle: {
      gridTemplateColumns: "280px repeat(5,1fr) 120px",
      justifyItems: "center",
    },
  },
  transitionFeedListStyles: {
    parentStyle: {
      gridTemplateColumns: "280px 132px repeat(2,1fr) 120px",
      justifyItems: "center",
    },
  },
  countStyle: {
    ...fontStyle.demiBold,
  },
  labelStyle: {
    ...fontStyle.demiBold,
  },
};
