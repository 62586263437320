import gql from "graphql-tag";
import {
  staffFragment,
  studentFragment,
  attachmentFragment,
  unitPlanFeedFragment,
  resourceLibraryFragment,
  evidenceInterfaceFragment,
  resolvedFieldValueFragment,
} from "modules/CommonFragments";
import { organizationResourceFragment } from "OrganizationResources/modules/OrganizationResourcesFragments";

export const evidenceResponseFeedPostFragment = {
  postEvidence: gql`
    fragment postItem on Post {
      id
      title
      attachments {
        ...attachmentItem
      }
      items {
        item {
          id
          ... on StudentAssignment {
            id
            assignment {
              id
              content {
                id
                ... on Assessment {
                  id
                  assessmentType {
                    id
                    value
                  }
                }
              }
            }
          }
        }
        itemType
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const responseFragment = {
  response: gql`
    fragment responseItem on Response {
      id
      message
      createdBy {
        id
        ... on Staff {
          ...staffItem
        }
        ... on Student {
          ...studentItem
        }
      }
      evidence {
        ...allEvidenceTypesItem
      }
      createdAt
    }
    ${staffFragment.basicStaffDetails}
    ${studentFragment.basicStudentDetails}
    ${evidenceInterfaceFragment.allTypes}
  `,
};

export const plannerFieldFragment = {
  unitplanField: gql`
    fragment unitPlanFieldItem on PlannerField {
      id
      uid
      value
      comments {
        totalCount
        unreadCount
      }
      resolvedMinimalTree {
        ...resolvedMinimalTreeItem
      }
      responses: allResponses {
        totalCount
        edges {
          node {
            ...responseItem
          }
        }
      }
    }
    ${responseFragment.response}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  unitPlanFieldResponses: gql`
    fragment unitPlanFieldItem on PlannerField {
      id
      uid
      responses: allResponses(filters: $responseFilter) {
        totalCount
        edges {
          node {
            ...responseItem
          }
        }
      }
    }
    ${responseFragment.response}
  `,
  resourceLibraryField: gql`
    fragment resourceLibraryFieldItem on PlannerField {
      id
      uid
      value
      resolvedMinimalTree {
        ...resolvedMinimalTreeItem
      }
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  unitplanFieldBasicDetails: gql`
    fragment unitplanFieldBasicDetailsItem on PlannerField {
      id
      uid
      value

      resolvedMinimalTree {
        ...resolvedMinimalTreeItem
      }
    }

    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  assessmentFieldItem: gql`
    fragment assessmentFieldItem on PlannerField {
      id
      value
    }
  `,

  projectFieldItem: gql`
    fragment projectFieldItem on PlannerField {
      id
      uid
      value
      parent
      children
      pseudoFieldUid
      attachments {
        ...attachmentItem
      }
      comments {
        totalCount
        unreadCount
        unreadCountV2
      }
      resolvedMinimalTree {
        ...resolvedMinimalTreeItem
      }
      responses: allResponses {
        totalCount
        edges {
          node {
            ...responseItem
          }
        }
      }
    }
    ${responseFragment.response}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${attachmentFragment.attachment}
  `,
  unitPlanAllFields: gql`
    fragment unitPlanAllFields on PlannerField {
      id
      uid
      value
      resolvedMinimalTree {
        ...resolvedMinimalTreeItem
      }
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  fieldsResolvedMinimalTreeFragment: gql`
    fragment fieldsResolvedMinimalTreeFragmentItem on PlannerField {
      id
      uid
      value
      children
      attachments {
        ...attachmentItem
      }
      comments {
        unreadCountV2
      }
      resolvedMinimalTree {
        id
        type
        ... on ResolvedFieldPlannerElementSet {
          id
          type
          ... on ResolvedFieldPlannerElementSet {
            id
            type
            nodes {
              id
              label
              type
            }
          }
        }
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const unitPlanResourceLibraryFragment = {
  unitPlanResourceLibrary: gql`
    fragment unitPlanResourceLibraryItem on UnitPlan {
      id
      resourceLibrary(filters: $filters) {
        leCount
        assessmentCount
        edges {
          type
          node {
            ...resourceLibraryItem
          }
        }
      }
    }
    ${resourceLibraryFragment.resourceFeed}
  `,
};

export const resourcePlannerFragment = {
  resource: gql`
    fragment resourcePlannerItem on PlannerResource {
      id
      title
      type
      description
      usedCount
      createdBy {
        ...staffItem
      }
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
    ${staffFragment.basicStaffDetails}
  `,
};

export const resourceConnectionFragment = {
  resourceConnection: gql`
    fragment resourceConnectionItem on PlannerResourceConnection {
      totalCount
      edges {
        node {
          ...resourcePlannerItem
        }
      }
    }
    ${resourcePlannerFragment.resource}
  `,
};

export const staffDetailUnitPlanFeedFragment = {
  staffUnitPlanFeedCount: gql`
    fragment staffUnitPlanFeedCountItem on Staff {
      id
      unitPlans(filters: $filters) {
        totalCount
      }
    }
  `,
  staffDetailUnitPlanFeed: gql`
    fragment staffDetailUnitPlanFeedItem on Staff {
      ...staffItem
      unitPlans(filters: $filters, groupBy: $groupBy) {
        totalCount
        edges {
          fieldValue
          type
          node {
            ...unitPlanItem
          }
        }
      }
    }
    ${staffFragment.basicStaffDetails}
    ${unitPlanFeedFragment.unitPlanFeed}
  `,
};

export const courseDetailUnitPlanFeedFragment = {
  courseUnitPlanFeedCount: gql`
    fragment courseUnitPlanFeedCountItem on Course {
      id
      unitPlans(filters: $filters) {
        totalCount
      }
    }
  `,
  courseUnitPlanFeed: gql`
    fragment courseUnitPlanFeedItem on Course {
      id
      unitPlans(filters: $filters, groupBy: $groupBy) {
        totalCount
        edges {
          fieldValue
          type
          node {
            ...unitPlanItem
          }
        }
      }
    }
    ${unitPlanFeedFragment.unitPlanFeed}
  `,
  courseFlatUnitPlanFeed: gql`
    fragment courseFlatUnitPlanFeedItem on Course {
      id
      flatUnitPlans(
        first: $first
        after: $after
        filters: $filters
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        totalCount
        unitTypeCount {
          type
          count
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...unitPlanItem
          }
        }
      }
    }
    ${unitPlanFeedFragment.unitPlanFeed}
  `,
  courseFlatUnitPlanTitle: gql`
    fragment courseFlatUnitPlanTitleItem on Course {
      id
      flatUnitPlans(
        after: $after
        filters: $filters
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            title {
              id
              value
            }
          }
        }
      }
    }
  `,
};

export const unitPlanFragment = {
  unitPlanFields: gql`
    fragment unitPlanFields on UnitPlan {
      id
      allFields {
        id
        uid
        value
      }
    }
  `,
};

export const plannerFieldsOfNodeFragment = {
  unitPlan: gql`
    fragment unitPlanNodeItem on UnitPlan {
      id
      fields(uids: $uids) {
        ...unitPlanFieldItem
      }
    }
    ${plannerFieldFragment.unitplanField}
  `,
  assessment: gql`
    fragment assessmentNodeItem on Assessment {
      id
      fields(uids: $uids) {
        ...resourceLibraryFieldItem
      }
    }
    ${plannerFieldFragment.resourceLibraryField}
  `,
  le: gql`
    fragment leNodeItem on LearningEngagement {
      id
      fields(uids: $uids) {
        ...resourceLibraryFieldItem
      }
    }
    ${plannerFieldFragment.resourceLibraryField}
  `,
  project: gql`
    fragment projectNodeItem on Project {
      id
      fields(filters: { uids: $uids }) {
        ...projectFieldItem
      }
    }
    ${plannerFieldFragment.projectFieldItem}
  `,
};

export const resourceNodeFragment = {
  unitPlan: gql`
    fragment unitPlanNodeItem on UnitPlan {
      id
      resources {
        ...resourceConnectionItem
      }
    }
    ${resourceConnectionFragment.resourceConnection}
  `,
  assessment: gql`
    fragment assessmentNodeItem on Assessment {
      id
      resources {
        ...resourceConnectionItem
      }
    }
    ${resourceConnectionFragment.resourceConnection}
  `,
  le: gql`
    fragment leNodeItem on LearningEngagement {
      id
      resources {
        ...resourceConnectionItem
      }
    }
    ${resourceConnectionFragment.resourceConnection}
  `,
};

export const plannerOrganizationResourceNodeFragment = {
  unitPlanOrganizationResourceNode: gql`
    fragment unitPlanOrganizationResourceNodeItem on UnitPlan {
      id
      organizationResources {
        ...organizationResourceItem
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
  assessmentOrganizationResourceNode: gql`
    fragment assessmentOrganizationResourceNodeItem on Assessment {
      id
      organizationResources {
        ...organizationResourceItem
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
};
