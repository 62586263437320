import {
  getBenchmarkSetQuery,
  getOrganizationPypConstantsQuery,
} from "modules/CommonQuery";
import { getSettingValue } from "modules/PermissionModule";
import { getTaggableElements } from "Post/modules/PostModule";
import {
  fetchAssessmentQuery,
  fetchLEQuery,
  getUnitPlanQuery,
} from "Post/modules/PostQuery";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { I18nHOC, withLoader } from "UIComponents";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import TagLearningGoalsList from "./TagLearningGoalsList";
import { CURRICULUM_TYPE_PYP } from "Constants/stringConstants";

import { getPlannerElementSetsOfCurriculumFromCache } from "modules/CommonGraphqlHelpers";
import { getPlannerElementSetsOfCurriculumQuery } from "modules/CommonQuery";
import ACLStore from "lib/aclStore";

const getActionObj = t => {
  return {
    id: "action",
    uid: "action",
    resolvedMinimalTree: {
      id: "action",
      type: "ACTION",
      action: [
        {
          id: "action",
          label: t("common:action_label"),
        },
      ],
    },
  };
};

const plannerElementQueryVariables = ({ courseId, taggableElements }) => {
  const plannerElementTypes = [];
  const subjectPlannerElementTypes = [];
  const subjectGradePlannerElementTypes = [];
  const gradePlannerElementTypes = [];
  let subjectPlannerElementFilters = undefined;
  let subjectGradePlannerElementFilters = undefined;
  let gradePlannerElementFilters = undefined;

  const courseDetails = getCourseDetailsFromCache({
    id: courseId,
  });
  const subjects = _.get(courseDetails, "subjects", []);
  const grades = _.get(courseDetails, "grades", []);
  _.forEach(taggableElements, element => {
    if (element.isGlobal) {
      plannerElementTypes.push(element.elementType);
    }
  });
  _.forEach(taggableElements, element => {
    if (!element.isGlobal && !element.isGradeSpecific) {
      subjectPlannerElementTypes.push(element.elementType);
    }
  });
  _.forEach(taggableElements, element => {
    if (
      !element.isGlobal &&
      element.isGradeSpecific &&
      element.isSubjectSpecific
    ) {
      subjectGradePlannerElementTypes.push(element.elementType);
    }
  });
  _.forEach(taggableElements, element => {
    if (
      !element.isGlobal &&
      element.isGradeSpecific &&
      !element.isSubjectSpecific
    ) {
      gradePlannerElementTypes.push(element.elementType);
    }
  });

  subjectPlannerElementFilters = {
    associatedParents: [
      {
        ids: _.map(subjects, item => item.id),
        type: "SUBJECT",
      },
    ],
  };
  subjectGradePlannerElementFilters = {
    associatedParents: _.filter(
      [
        {
          ids: _.map(subjects, item => item.id),
          type: "SUBJECT",
        },
        {
          ids: _.map(grades, item => item.id),
          type: "GRADE",
        },
      ],
      item => !_.isEmpty(item.ids)
    ),
  };
  gradePlannerElementFilters = {
    associatedParents: _.filter(
      [
        {
          ids: _.map(grades, item => item.id),
          type: "GRADE",
        },
      ],
      item => !_.isEmpty(item.ids)
    ),
  };

  return {
    plannerElementTypes,
    subjectPlannerElementTypes,
    subjectGradePlannerElementTypes,
    subjectPlannerElementFilters,
    subjectGradePlannerElementFilters,
    gradePlannerElementTypes,
    gradePlannerElementFilters,
  };
};

const plannerElementQueryVariablesMemoize = _.memoize(
  plannerElementQueryVariables,
  params => JSON.stringify(params)
);

const mapActionCreators = {};
const mapStateToProps = state => {
  const organizationId = state?.login?.userInfo?.org_id;
  const selectedGrades = state.teacher?.selected_class?.selected_grades;
  const curriculumType =
    state.platform.currentCurriculumProgramType || "IB_PYP";
  const {
    resourceItem,
    unitPlan,
    resourceType,
    selectFromAllPYP,
  } = state.post.postDetails;
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );
  let USER_TAGGABLE_ELEMENTS = getTaggableElements({
    curriculumProgramType: curriculumType,
    unitPlan,
    plannerElementSets,
  });
  const curriculum = _.find(state.platform.organizationCurriculumPrograms, {
    type: curriculumType,
  });
  const userType = state.login.userInfo.user_type;
  // show pyp elements to user based on setting
  if (userType == "student") {
    const omitPYPElements = getSettingValue({
      name: "OmittedPYPElementsForTaggingInPostByStudents",
      courseId: state.teacher.selected_class.selected_course,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    });

    USER_TAGGABLE_ELEMENTS = _.filter(USER_TAGGABLE_ELEMENTS, element => {
      return (
        omitPYPElements.findIndex(omitElement => element.value == omitElement) <
        0
      );
    });
  }

  const uids = _.map(USER_TAGGABLE_ELEMENTS, element => {
    return element.value;
  });

  let plannerElementVariables = {};
  if (curriculumType != "IB_PYP") {
    plannerElementVariables = plannerElementQueryVariablesMemoize({
      courseId: state.teacher.selected_class.selected_course,
      taggableElements: USER_TAGGABLE_ELEMENTS,
    });
  }

  const enableActioninPYP = ACLStore.can("FeatureFlag:EnableAction");

  if (!enableActioninPYP) {
    _.pull(uids, "action");
  }

  const commonProps = {
    uids: uids,
    isData: true,
    isLoading: false,
    showLoaderBar: false,
    taggableElements: USER_TAGGABLE_ELEMENTS,
    curriculumId: _.get(curriculum, "id", ""),
    curriculumNodeInterfaceType: _.get(curriculum, "nodeInterfaceType", ""),
    ...plannerElementVariables,
    curriculumType,
    enableActioninPYP,
  };

  if (selectFromAllPYP) {
    return {
      ...commonProps,
      taggableElements: USER_TAGGABLE_ELEMENTS,
      selectFromAllPYP,
      organizationId,
      selectedGrades,
    };
  }

  if (!resourceItem || !resourceType) {
    return {
      id: _.get(unitPlan, "id", ""),
      parentType: "UNIT_PLAN",
      ...commonProps,
    };
  } else {
    return {
      id: _.get(resourceItem, "id", ""),
      parentType: resourceType,
      ...commonProps,
    };
  }
};

export default compose(
  I18nHOC({ resource: ["journal", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getUnitPlanQuery, {
    name: "getUnitPlan",
    skip: ({ parentType, selectFromAllPYP }) =>
      parentType != "UNIT_PLAN" || selectFromAllPYP,
    options: ({ id, uids }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: id,
        uids: uids,
      },
    }),
    props({
      getUnitPlan,
      ownProps: { isData, isLoading, t, enableActioninPYP },
    }) {
      const fields = _.get(getUnitPlan, "node.fields", {});
      if (!_.isEmpty(fields) && isData && !enableActioninPYP) {
        fields.push(getActionObj(t));
      }

      return {
        getUnitPlan,
        fields,
        isData: !_.isEmpty(fields) && isData,
        isLoading:
          getUnitPlan["networkStatus"] == 1 ||
          getUnitPlan["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(fetchAssessmentQuery, {
    name: "getAssessment",
    skip: ({ parentType, selectFromAllPYP }) =>
      parentType != "ASSESSMENT" || selectFromAllPYP,
    options: ({ id, uids }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: id,
        uids: uids,
      },
    }),
    props({
      getAssessment,
      ownProps: { isData, isLoading, t, enableActioninPYP },
    }) {
      const fields = _.get(getAssessment, "node.fields", {});
      if (!_.isEmpty(fields) && isData && !enableActioninPYP) {
        fields.push(getActionObj(t));
      }

      return {
        getAssessment,
        fields,
        isData: !_.isEmpty(fields) && isData,
        isLoading:
          getAssessment["networkStatus"] == 1 ||
          getAssessment["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(fetchLEQuery, {
    name: "getLE",
    skip: ({ parentType, selectFromAllPYP }) =>
      parentType != "LEARNING_ENGAGEMENT" || selectFromAllPYP,
    options: ({ id, uids }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: id,
        uids: uids,
      },
    }),
    props({ getLE, ownProps: { isData, isLoading, t, enableActioninPYP } }) {
      const fields = _.get(getLE, "node.fields", {});
      if (!_.isEmpty(fields) && isData && !enableActioninPYP) {
        fields.push(getActionObj(t));
      }

      return {
        getLE,
        fields,
        isData: !_.isEmpty(fields) && isData,
        isLoading:
          getLE["networkStatus"] == 1 ||
          getLE["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationPypConstantsQuery, {
    name: "getOrganizationPypConstants",
    skip: ({ selectFromAllPYP, curriculumType }) =>
      !selectFromAllPYP || curriculumType !== CURRICULUM_TYPE_PYP,
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
      },
    }),
    props({
      getOrganizationPypConstants,
      ownProps: { t, isData, isLoading, enableActioninPYP },
    }) {
      const pypElement = _.get(
        getOrganizationPypConstants,
        "node.pypElement",
        {}
      );
      const fields = [];
      _.forOwn(pypElement, (value, key) => {
        let fieldObj = {};
        fieldObj = {
          uid:
            key === "learnerProfiles"
              ? "lp"
              : key === "actions" && enableActioninPYP
              ? "action"
              : key,
          resolvedMinimalTree: {
            [key]: value?.nodes ?? [],
          },
        };
        fields.push(fieldObj);
      });
      if (!_.isEmpty(fields) && isData && !enableActioninPYP) {
        fields.push(getActionObj(t));
      }

      return {
        getOrganizationPypConstants,
        fields,
        isData: !_.isEmpty(fields) && isData,
        isLoading:
          getOrganizationPypConstants["networkStatus"] == 1 ||
          getOrganizationPypConstants["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getBenchmarkSetQuery, {
    name: "getBenchmarkSet",
    skip: ({ selectFromAllPYP, curriculumType }) =>
      !selectFromAllPYP || curriculumType !== CURRICULUM_TYPE_PYP,
    options: ({ selectedGrades, organizationId }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: { grades: selectedGrades, organizationId },
      };
    },
    props({ getBenchmarkSet, ownProps: { isData, isLoading } }) {
      const benchmarkData = _.get(
        getBenchmarkSet,
        "node.pypElement.benchmarks.nodes",
        []
      );
      return {
        isData: !_.isEmpty(benchmarkData) && isData,
        getBenchmarkSet,
        benchmarkData: benchmarkData,
        isLoading:
          getBenchmarkSet["networkStatus"] == 1 ||
          getBenchmarkSet["networkStatus"] == 2 ||
          getBenchmarkSet["networkStatus"] == 4 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerElementSetsOfCurriculumQuery, {
    name: "getPlannerElementSetsOfCurriculum",
    skip: ({ curriculumType, selectFromAllPYP, plannerElementTypes }) =>
      !selectFromAllPYP ||
      curriculumType == CURRICULUM_TYPE_PYP ||
      _.isEmpty(plannerElementTypes),
    options: ({
      curriculumId,
      plannerElementTypes,
      curriculumNodeInterfaceType,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: plannerElementTypes,
      },
    }),
    props({
      getPlannerElementSetsOfCurriculum,
      ownProps: {
        isData,
        isLoading,
        curriculumId,
        plannerElementTypes,
        curriculumNodeInterfaceType,
      },
    }) {
      const curriculumData = getPlannerElementSetsOfCurriculumFromCache({
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: plannerElementTypes,
      });

      return {
        plannerElements: _.get(curriculumData, "plannerElementSets", []),
        isData: !_.isEmpty(curriculumData) && isData,
        isLoading:
          getPlannerElementSetsOfCurriculum["networkStatus"] == 1 ||
          getPlannerElementSetsOfCurriculum["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerElementSetsOfCurriculumQuery, {
    name: "getPlannerElementSetsOfCurriculum",
    skip: ({ curriculumType, selectFromAllPYP, subjectPlannerElementTypes }) =>
      !selectFromAllPYP ||
      curriculumType == CURRICULUM_TYPE_PYP ||
      _.isEmpty(subjectPlannerElementTypes),
    options: ({
      curriculumId,
      subjectPlannerElementTypes,
      curriculumNodeInterfaceType,
      subjectPlannerElementFilters,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: subjectPlannerElementTypes,
        filters: subjectPlannerElementFilters,
      },
    }),
    props({
      getPlannerElementSetsOfCurriculum,
      ownProps: {
        isData,
        isLoading,
        curriculumId,
        subjectPlannerElementFilters,
        curriculumNodeInterfaceType,
        subjectPlannerElementTypes,
      },
    }) {
      const curriculumData = getPlannerElementSetsOfCurriculumFromCache({
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: subjectPlannerElementTypes,
        filters: subjectPlannerElementFilters,
      });

      return {
        subjectPlannerElements: _.get(curriculumData, "plannerElementSets", []),
        isData: !_.isEmpty(curriculumData) && isData,
        isLoading:
          getPlannerElementSetsOfCurriculum["networkStatus"] == 1 ||
          getPlannerElementSetsOfCurriculum["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerElementSetsOfCurriculumQuery, {
    name: "getPlannerElementSetsOfCurriculum",
    skip: ({
      curriculumType,
      selectFromAllPYP,
      subjectGradePlannerElementTypes,
    }) =>
      !selectFromAllPYP ||
      curriculumType == CURRICULUM_TYPE_PYP ||
      _.isEmpty(subjectGradePlannerElementTypes),
    options: ({
      curriculumId,
      subjectGradePlannerElementTypes,
      curriculumNodeInterfaceType,
      subjectGradePlannerElementFilters,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: subjectGradePlannerElementTypes,
        filters: subjectGradePlannerElementFilters,
      },
    }),
    props({
      getPlannerElementSetsOfCurriculum,
      ownProps: {
        isData,
        isLoading,
        curriculumId,
        subjectGradePlannerElementFilters,
        curriculumNodeInterfaceType,
        subjectGradePlannerElementTypes,
      },
    }) {
      const curriculumData = getPlannerElementSetsOfCurriculumFromCache({
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: subjectGradePlannerElementTypes,
        filters: subjectGradePlannerElementFilters,
      });

      return {
        subjectGradePlannerElements: _.get(
          curriculumData,
          "plannerElementSets",
          []
        ),
        isData: !_.isEmpty(curriculumData) && isData,
        isLoading:
          getPlannerElementSetsOfCurriculum["networkStatus"] == 1 ||
          getPlannerElementSetsOfCurriculum["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerElementSetsOfCurriculumQuery, {
    name: "getPlannerElementSetsOfCurriculum",
    skip: ({ curriculumType, selectFromAllPYP, gradePlannerElementTypes }) =>
      !selectFromAllPYP ||
      curriculumType == CURRICULUM_TYPE_PYP ||
      _.isEmpty(gradePlannerElementTypes),
    options: ({
      curriculumId,
      gradePlannerElementTypes,
      curriculumNodeInterfaceType,
      gradePlannerElementFilters,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: gradePlannerElementTypes,
        filters: gradePlannerElementFilters,
      },
    }),
    props({
      getPlannerElementSetsOfCurriculum,
      ownProps: {
        isData,
        isLoading,
        curriculumId,
        gradePlannerElementFilters,
        curriculumNodeInterfaceType,
        gradePlannerElementTypes,
      },
    }) {
      const curriculumData = getPlannerElementSetsOfCurriculumFromCache({
        id: curriculumId,
        type: curriculumNodeInterfaceType,
        elementTypes: gradePlannerElementTypes,
        filters: gradePlannerElementFilters,
      });

      return {
        gradePlannerElements: _.get(curriculumData, "plannerElementSets", []),
        isData: !_.isEmpty(curriculumData) && isData,
        isLoading:
          getPlannerElementSetsOfCurriculum["networkStatus"] == 1 ||
          getPlannerElementSetsOfCurriculum["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(TagLearningGoalsList);
