import PropTypes from "prop-types";
import React from "react";
import classes from "./LabelComponent.scss";
import { DetailsIcon, ArrowIcon } from "SvgComponents";
import {
  HelpBulbOutlined,
  CommentBubbleOutlined,
} from "@toddle-design/web-icons";
import { HelperModal, I18nHOC, AnimateHeight } from "UIComponents";
import { colors } from "Constants";
import classNames from "classnames";
import { plannerIcons } from "UnitPlans/components/plannerIcons";
import { IconButton, Badge, MediaCard, Button } from "@toddle-design/web";

const PromptComponent = ({
  propmtsEnable,
  prompts,
  togglePropmts,
  promptsId,
  promptsName,
  isReflection,
  t,
}) => {
  const arrowStyle = classNames(
    { [classes.arrowContainer]: true },
    { [classes.openArrow]: propmtsEnable },
    { [classes.closeArrow]: !propmtsEnable }
  );

  return (
    <div className={classes.propmtsContainer} id={promptsId}>
      <div
        className={classes.propmtLabelArrowContainer}
        onClick={togglePropmts}
      >
        <div className={classes.propmtLabelText}>
          {isReflection
            ? `${t("IB_reflection_prompts")}`
            : `${t("IB_prompts")}`}
        </div>
        <div className={arrowStyle}>
          <ArrowIcon fill={colors.blue29} />
        </div>
      </div>
      <AnimateHeight duration={300} height={propmtsEnable ? "auto" : 0}>
        <div className={classes.prompTextContainer}>
          <div
            className={classes.promptText}
            dangerouslySetInnerHTML={{ __html: prompts }}
          />
        </div>
      </AnimateHeight>
    </div>
  );
};
class LabelComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showHelperModal: false, propmtsEnable: true };
  }

  getCommentIcon() {
    if (this.props.unreadCommentCount > 0) {
      return (
        <Badge value={this.props.unreadCommentCount} type={"numeric"}>
          <CommentBubbleOutlined />
        </Badge>
      );
    } else {
      return <CommentBubbleOutlined />;
    }
  }

  togglePropmts = () => {
    this.setState({ propmtsEnable: !this.state.propmtsEnable });
  };
  render() {
    const {
      isCommentActive,
      isHelpActive,
      unreadCommentCount,
      labelComponent,
      label,
      labelStyle,
      onHelpClick,
      subText,
      helperText,
      onCommentClick,
      showComment,
      showHelper,
      labelClass,
      subtextClass,
      subTextStyle,
      showDetails,
      isDetailsActive,
      labelContainerStyle = {},
      onDetailsClick,
      extraRightButtonComponent,
      prompts,
      icon,
      commentButtonId,
      helpButtonId,
      promptsId,
      labelCoachmarkId,
      promptsName,
      t,
      isReflection,
      isPageHeader,
      isSectionHeader,
      isFieldHeader,
      commentIconSize,
      customCommentIconVariant,
      showCommentIconAsActivated,
      showCustomIcon,
      customIconElement,
      renderBottomComp = null,
    } = this.props;

    const iconSvg = plannerIcons[icon];
    const { showHelperModal, propmtsEnable } = this.state;

    const detailsClass = classNames(
      { [classes.labelDetails]: true },
      { [classes.iconGrey]: !isDetailsActive },
      { [classes.iconBlue]: isDetailsActive }
    );

    const labelClasses = classNames(
      {
        [classes.inputLabel]:
          true && !isPageHeader && !isSectionHeader && !isFieldHeader,
      },
      {
        "heading-6":
          true && !isPageHeader && !isSectionHeader && !isFieldHeader,
      },
      { [classes[labelClass]]: !!labelClass },
      { "heading-5": isPageHeader },
      { "heading-5": isSectionHeader },
      { "text-label-2": isFieldHeader }
    );

    const subTextClasses = classNames(
      { [classes.subText]: true },
      { "text-subtitle-2": true },
      { [classes[subtextClass]]: !!subtextClass }
    );

    const commentIconVariant = !_.isEmpty(customCommentIconVariant)
      ? customCommentIconVariant
      : isCommentActive
      ? "primary"
      : "plain";

    return (
      (!!label || !!labelComponent) && (
        <div className={classes.container}>
          {labelComponent ? (
            labelComponent
          ) : label ? (
            <div
              className={classes.labelContainer}
              style={{
                marginBottom: iconSvg ? 20 : 0,
              }}
            >
              <div className={classes.leftContainer}>
                {iconSvg && (
                  <div className={classes.iconContainer}>{iconSvg}</div>
                )}
                <div className={classes.labelAndSubLabelContainer}>
                  <div
                    className={labelClasses}
                    style={labelStyle}
                    id={labelCoachmarkId}
                  >
                    {label}
                  </div>
                  {subText ? (
                    <div style={subTextStyle} className={subTextClasses}>
                      {subText}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className={classes.buttonContainer}>
                {showDetails ? (
                  <div
                    className={detailsClass}
                    onClick={() => onDetailsClick()}
                  >
                    <div className={classes.detailIcon}>
                      <DetailsIcon />
                    </div>
                    {`See Selected PYP elements`}
                  </div>
                ) : null}
                {extraRightButtonComponent && (
                  <div className={classes.extraButton}>
                    {extraRightButtonComponent}
                  </div>
                )}

                {showCustomIcon && customIconElement}

                {showComment ? (
                  <IconButton
                    icon={this.getCommentIcon()}
                    size={commentIconSize}
                    variant={commentIconVariant}
                    isActivated={showCommentIconAsActivated}
                    onClick={() => onCommentClick()}
                  />
                ) : null}

                {showHelper ? (
                  <IconButton
                    icon={<HelpBulbOutlined />}
                    variant={isHelpActive ? "primary" : "plain"}
                    size={"large"}
                    onClick={() =>
                      onHelpClick
                        ? onHelpClick()
                        : this.setState({ showHelperModal: true })
                    }
                    id={helpButtonId}
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {!!prompts && (
            <PromptComponent
              propmtsEnable={propmtsEnable}
              prompts={prompts}
              togglePropmts={this.togglePropmts}
              promptsId={promptsId}
              promptsName={promptsName}
              t={t}
              isReflection={isReflection}
            />
          )}
          {showHelperModal && (
            <HelperModal
              helperText={helperText}
              onClose={() => this.setState({ showHelperModal: false })}
            />
          )}

          {renderBottomComp ? renderBottomComp : null}
        </div>
      )
    );
  }
}

LabelComponent.defaultProps = {
  labelStyle: {},
  labelContainerStyle: {},
  showHelper: false,
  label: "",
  subText: "",
  helperText: "",
  showComment: false,
  showDetails: false,
  isCommentActive: false,
  isDetailsActive: false,
  isHelpActive: false,
  totalCommentCount: 0,
  unreadCommentCount: 0,
  labelClass: "",
  subtextClass: "",
  subTextStyle: {},
  commentIconColor: "",
  showEdit: false,
  prompts: "",
  promptsName: "",
  isPageHeader: false,
  isSectionHeader: false,
  isFieldHeader: false,
  commentIconSize: "medium",
  showCommentIconAsActivated: false,
  customCommentIconVariant: "",
};

LabelComponent.propTypes = {
  subText: PropTypes.string,
  helperText: PropTypes.string,
  labelComponent: PropTypes.element,
  labelStyle: PropTypes.object,
  showHelper: PropTypes.bool,
  showComment: PropTypes.bool,
  onHelpClick: PropTypes.func,
  onCommentClick: PropTypes.func,
  isCommentActive: PropTypes.bool,
  isHelpActive: PropTypes.bool,
  labelClass: PropTypes.string,
  subtextClass: PropTypes.string,
  onEditClick: PropTypes.func,
  showEdit: PropTypes.bool,
  prompts: PropTypes.any,
  isPageHeader: PropTypes.bool,
  isSectionHeader: PropTypes.bool,
  isFieldHeader: PropTypes.bool,
  commentIconSize: PropTypes.string,
  showCommentIconAsActivated: PropTypes.bool,
  customCommentIconVariant: PropTypes.string,
};

export { LabelComponent };

export default I18nHOC({ resource: "unitPlan" })(LabelComponent);
