import {
  MS_FILE_VIEWER_SUPPORTED_MIMETYPE,
  GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE,
  WORK_BOOK_URL,
} from "Constants";
import ACLStore from "lib/aclStore";
import { canOpenOfficeFileInPdftron } from "./PdftronViewerHelpers";

export const GetTheatreModeType = ({ media }) => {
  const mediaType = _.get(media, "type", "");
  const mediaMimeType = _.get(media, "mimeType", "");
  const url = _.get(media, "url", "");
  const urlRegx = new RegExp(
    "(docs.google.com)(://[A-Za-z]+-my.sharepoint.com)?",
    "i"
  );

  switch (mediaType) {
    case "WORKBOOK":
      return "WORKBOOKVIEWER";
    case "IMAGE":
      if (url.includes(WORK_BOOK_URL)) return "FILEVIEWER";
      return "IMAGEVIEWER";

    case "FILE":
      if (_.isEqual(mediaMimeType, "application/pdf")) return "PDFTRONVIEWER";
      if (
        _.includes(GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE, mediaMimeType) ||
        _.includes(MS_FILE_VIEWER_SUPPORTED_MIMETYPE, mediaMimeType)
      ) {
        return canOpenOfficeFileInPdftron() ? "PDFTRONVIEWER" : "FILEVIEWER";
      }
      break;
    case "LINK":
      if (urlRegx.test(url)) return "FILEVIEWER";
      return "MEDIAPLACEHOLDER";
    default:
      return "MEDIAPLACEHOLDER";
  }
};
