import React from "react";
import classes from "./Step.scss";
import {
  InputTextField,
  SelectDropdown,
  CheckListContainer,
} from "UIComponents";
import { emailValidation } from "Utils";
class Step extends React.PureComponent {
  onTextChanged = data => {
    const { id, name } = this.props;
    if (this.props.onUpdateInputField) {
      this.props.onUpdateInputField({ [id]: data[name] });
    }
  };

  checkEmailVerification = value => {
    const isValidEmail = !!value && emailValidation(value);

    if (!isValidEmail) {
      return "Please enter a valid email address";
    } else {
      return "";
    }
  };

  render() {
    const {
      id,
      label,
      name,
      placeholder,
      value,
      customRef,
      type,
      options,
      disabled,
      error,
      multi,
      allowAddOption,
    } = this.props;
    let Comp = InputTextField;
    switch (type) {
      case "selectDropDown":
        Comp = SelectDropdown;
        break;
      case "checklist":
        Comp = CheckListContainer;
        break;
      default:
        Comp = InputTextField;
    }

    return (
      <div className={classes.container}>
        <Comp
          label={label}
          name={name}
          placeholder={placeholder}
          updateInputField={this.onTextChanged}
          value={value}
          ref={ref => customRef({ ref, id })}
          options={options}
          addButtonText={`+ Create`}
          multi={multi}
          customValidation={id == "email" ? this.checkEmailVerification : null}
          allowAddOption={allowAddOption}
          disabled={disabled}
          error={error}
        />
      </div>
    );
  }
}

export default Step;
