import React from "react";
import classes from "./MoreItem.scss";
import { colors } from "Constants";
import { ResourceImagePlaceHolder, I18nHOC } from "UIComponents";
import _ from "lodash";

const placeHolderStyle = {
  borderColor: colors.gray72,
};

const MoreItem = ({ moreItemsCount, itemText, onMoreClick, t }) => {
  return (
    <div className={classes.container} onClick={onMoreClick}>
      <div className={classes.innerContainer}>
        <div className={classes.imagePlaceHolderContainer}>
          <ResourceImagePlaceHolder
            customContainerStyle={placeHolderStyle}
            icon={
              <div className={classes.moreTextIcon}>{`+${moreItemsCount}`}</div>
            }
          />
        </div>
        <div className={classes.moreText}>
          {/* {`+${moreItemsCount} mores ${itemText}${
            moreItemsCount > 1 ? "s" : ""
          }`} */}
          {`+${t("common:more_withNumber_andLabel", {
            number: moreItemsCount,
            label: itemText,
          })}`}
        </div>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: "common" })(MoreItem);
