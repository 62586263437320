import {
  DownArrowOutlined,
  BentArrowBottomLeftOutlined,
  UpArrowOutlined,
} from "@toddle-design/web-icons";
import React from "react";
import classes from "./Footer.scss";

const KeyNameComp = props => {
  const { icon: Icon } = props;
  return (
    <span className={classes.keyNameChamp}>
      <Icon className={classes.icon} />
    </span>
  );
};

const Footer = props => {
  return (
    <div className={classes.container}>
      <div className={classes.navigator}>
        <div className={classes.navigatorIcon}>
          <KeyNameComp icon={UpArrowOutlined} />
          <KeyNameComp icon={DownArrowOutlined} />
        </div>
        <div className={classes.navigateText}>to navigate</div>
      </div>
      <div className={classes.enter}>
        <KeyNameComp icon={BentArrowBottomLeftOutlined} />
        <div className={classes.enterText}>to select</div>
      </div>
    </div>
  );
};

export default Footer;
