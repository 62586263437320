import gql from "graphql-tag";

export const generateAttendanceReportMutation = gql`
  mutation generateAttendanceReport(
    $startDate: String!
    $endDate: String!
    $courseIds: [ID!]
    $curriculumId: ID
    $academicYearId: ID
  ) {
    platform {
      generateAttendanceReport(
        input: {
          startDate: $startDate
          endDate: $endDate
          courseIds: $courseIds
          curriculumId: $curriculumId
          academicYearId: $academicYearId
        }
      ) {
        url
      }
    }
  }
`;
