import React, { Fragment } from "react";
import classes from "./PlatformSwitcher.scss";
import { I18nHOC, ButtonDropdown } from "UIComponents";
import { TwistyIcon } from "SvgComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { goToCommunityHome, getUserInfo } from "Login/modules/LoginModule";
import { goToPlatformHomeRoute, goToRoute } from "modules/NavigationModule";
import * as EventTracker from "lib/eventTracker";
import {
  navigateToAdminPortal,
  setActiveTabValue,
} from "Teacher/modules/TeacherModule";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { CoachMarks } from "AppComponents";
import {
  toddleMenuDropdownStyles,
  optionSvgStyle,
} from "./PlatformSwitcherStyles";
import { setAcademicYearSelected } from "Platform/modules/PlatformModule";
import moment from "moment";
import {
  getCurrentAcademicYear,
  goToRelativeRoute,
  getTotalLinkedIdentities,
} from "modules/Services";
import { STATUS_SETUP_COMPLETE } from "SchoolOnboardSetup/modules/stringConstants";
import {
  getLabelWithStyles,
  memoizedGetMenuItems,
  getFilteredMenuItems,
  getAcademicYearLabel,
  isCurrentAcademicYearEndInSameYear,
} from "./PlatformSwitcherUtils";
import { getOrganizationFreeStatus } from "Platform/modules/PlatformUtils";

let buttonDropDownRef = null;

const SwitchContainer = ({
  activeLabel,
  orgName,
  activeValue,
  showIcon,
  customStyles = {},
  academicYearSelected: { startDate, endDate },
  isTeacherPage,
  isOnboarding,
  t,
}) => {
  return (
    <div className={classes.container}>
      <div
        className={classes.titleContainer}
        id={"COACHMARKS_PLATFORM_SWITCHER_V2_BUTTON"}
      >
        <div className={classes.title} style={customStyles.title}>
          {isOnboarding
            ? t("onBoarding:school_onboarding")
            : isTeacherPage
            ? `${activeLabel} - ${getAcademicYearLabel({ startDate, endDate })}`
            : activeLabel}
        </div>
        {showIcon && (
          <div className={classes.dropdownArrow}>
            <TwistyIcon />
          </div>
        )}
      </div>
      {activeValue !== "TODDLE_COMMUNITY" && (
        <div className={classes.orgName} style={customStyles.orgName}>
          {orgName}
        </div>
      )}
    </div>
  );
};

const PlatformSwitcher = React.memo(props => {
  const {
    t,
    activeValue,
    goToCommunityHome,
    goToPlatformHomeRoute,
    orgName,
    navigateToAdminPortal,
    isOrganizationFree,
    userType,
    validCurriculumPrograms,
    totalLinkedIdentities,
    goToRoute,
    organizationAcademicYears,
    setAcademicYearSelected,
    academicYearSelected,
    setActiveTabValue,
    isTeacherPage,
    activeTab,
    isAdminPage,
    goToRelativeRoute,
    organizationCurriculumPrograms,
    isOnboarding = false,
    isDevUser,
    i18n,
    isCommunityPage,
  } = props;

  const language = _.get(i18n, "language", "en");

  const menuItems = memoizedGetMenuItems({
    validCurriculumPrograms,
    totalLinkedIdentities,
    isOrganizationFree,
    academicYearSelected,
    organizationAcademicYears,
    t,
    isAdminPage,
    organizationCurriculumPrograms,
    isOnboarding,
    isDevUser,
    lng: language,
    resources: i18n.store.data,
    isCommunityPage,
  });

  const filteredMenuItems = getFilteredMenuItems({
    menuItems,
  });

  const onToddleMenuItemClick = (value, { parentTab }) => {
    const menuItem = _.find(filteredMenuItems, menuItem => {
      if (!_.isEmpty(menuItem.children)) {
        return !_.isEmpty(_.find(menuItem.children, { value }));
      } else {
        return menuItem.value === value;
      }
    });

    /**
     * This will be true when user clicks on Academic year
     * switcher repeatedly
     */
    if (_.isEmpty(menuItem)) {
      return;
    }

    addMenuEvent({ platformType: menuItem.type });

    switch (menuItem.type) {
      case "TODDLE_PYP_PLATFORM":
      case "TODDLE_MYP_PLATFORM":
      case "TODDLE_UBD_PLATFORM":
      case "TODDLE_DP_PLATFORM":
        goToPlatformHomeRoute({
          curriculumProgramId: menuItem?.value,
        });
        break;
      case "TODDLE_COMMUNITY":
        goToCommunityHome({ isForced: true });
        break;
      case "ADMIN_PORTAL":
        navigateToAdminPortal();
        break;
      case "SWITCH_ORGANIZATION": {
        goToRoute({
          route: "platform/switch-organization",
        });
        break;
      }
      case "CONTINUE_SCHOOL_SETUP":
        goToRelativeRoute({
          route: "/school-onboard-setup",
          type: "push",
        });
        break;
      default:
        if (_.get(parentTab, "value", "") == "ACADEMIC_SWITCHER") {
          const selectedYear = _.filter(organizationAcademicYears, item => {
            return item.id == value;
          });
          setAcademicYearSelected(selectedYear[0]);

          goToRelativeRoute({
            route: "/",
          });

          //------BELOW CODE COULD BE USEFUL IN FUTURE------
          // update selectedAcademicYearId in local storage, because of which the academic year will persist on refresh
          /*
          const selectedYearId = _.get(selectedYear, ["0", "id"]);
          if (selectedYearId) {
            manageAcademicYear.setInLocalStorage(selectedYearId);
          }
          */

          if (
            activeTab == "familyconversations" &&
            !selectedYear.isCurrentAcademicYear
          ) {
            setActiveTabValue("unitPlans");
          }
        }
    }
  };

  const addMenuEvent = ({ platformType } = {}) => {
    const eventData = { platform_type: platformType };
    if (activeValue == "TODDLE_COMMUNITY") {
      eventData.source = "community";
    }
    EventTracker.recordEvent({
      eventName: "Clicked platform switcher",
      eventData,
    });
  };

  const onUpdateShowMenuDropDown = (value, isOpen) => {
    if (!isOpen && !!value) {
      addMenuEvent({ platformType: undefined });
    }
  };

  const updateButtonDropDownRef = ref => {
    buttonDropDownRef = ref;
  };

  const onCoachmarkStepChanged = ({ isDone }) => {
    if (isDone && buttonDropDownRef) {
      buttonDropDownRef.updateShowDropdown(true);
    }
  };

  const activeMenuItem =
    _.find(filteredMenuItems, ({ value }) => value === activeValue) || {};

  const areAllCurriculumsOnboarded = _.every(organizationCurriculumPrograms, {
    onboardingStatus: STATUS_SETUP_COMPLETE,
  });

  /**
   * Sometimes we can't find curriculum program having onboarding status STATUS_SETUP_COMPLETE due to
   * bad data. So, ?? is used to set onboardedCurriculum as {} for those cases
   */

  const onboardedCurriculum =
    _.find(
      organizationCurriculumPrograms,
      ({ onboardingStatus }) => onboardingStatus == STATUS_SETUP_COMPLETE
    ) ?? {};

  const { acronym: onboardedCurriculumAcronym } = onboardedCurriculum;

  if (filteredMenuItems.length <= 1) {
    const { label, customStyles } = getLabelWithStyles({
      t,
      userType,
      activeValue,
      menuItems,
    });
    return (
      <SwitchContainer
        orgName={orgName}
        activeLabel={label}
        activeValue={activeValue}
        customStyles={customStyles}
        academicYearSelected={academicYearSelected}
        isTeacherPage={isTeacherPage}
        isOnboarding={isOnboarding}
        t={t}
      />
    );
  }

  const localeParam = _.get(activeMenuItem, "localeParams", {});

  const activeLabel = t(_.get(activeMenuItem, "labelLocale"), localeParam);

  const { startDate, endDate } = academicYearSelected;
  const menuDropDownStyle = toddleMenuDropdownStyles({
    isAcademicYearEndInSameYear: isCurrentAcademicYearEndInSameYear({
      startDate,
      endDate,
    }),
  });

  return (
    <Fragment>
      <ButtonDropdown
        ref={updateButtonDropDownRef}
        authTabs={filteredMenuItems}
        highlightActiveElement={true}
        activeValue={activeValue}
        onUpdateShowDropDown={onUpdateShowMenuDropDown}
        buttonComponent={
          <SwitchContainer
            orgName={orgName}
            activeLabel={activeLabel}
            customStyles={_.get(activeMenuItem, "customStyles")}
            activeValue={activeValue}
            showIcon={true}
            academicYearSelected={academicYearSelected}
            isTeacherPage={isTeacherPage}
            isOnboarding={isOnboarding}
            t={t}
          />
        }
        {...menuDropDownStyle}
        onItemClick={onToddleMenuItemClick}
        optionSvgStyle={optionSvgStyle}
      />
      {filteredMenuItems.length > 1 && (
        <CoachMarks
          type={
            areAllCurriculumsOnboarded
              ? `PLATFORM_SWITCHER_V2_BUTTON`
              : `PLATFORM_SWITCHER_SCHOOL_ONBOARDING`
          }
          continuous={false}
          params={{
            target: `#COACHMARKS_PLATFORM_SWITCHER_V2_BUTTON`,
            curriculum: onboardedCurriculumAcronym,
          }}
          onStepChanged={onCoachmarkStepChanged}
        />
      )}
    </Fragment>
  );
});

const mapActionCreators = {
  goToPlatformHomeRoute,
  goToRoute,
  goToCommunityHome,
  navigateToAdminPortal,
  setAcademicYearSelected,
  setActiveTabValue,
  goToRelativeRoute,
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: "PLANNER" });
  const organizationId = _.get(userInfo, "org_id");
  const orgName = _.get(
    getOrganizationDetailsFromCache(organizationId),
    "name",
    ""
  );

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  const userId = userInfo.id ?? "";

  const userEntityType = userInfo.userEntityType ?? "";

  const totalLinkedIdentities = getTotalLinkedIdentities({
    id: userId,
    type: userEntityType,
  });

  const validCurriculumPrograms = _.isEmpty(ownProps.userCurriculumPrograms)
    ? organizationCurriculumPrograms
    : ownProps.userCurriculumPrograms;

  const isOrganizationFree = getOrganizationFreeStatus({
    curriculumPrograms: organizationCurriculumPrograms,
  });

  const organizationAcademicYears = _.get(
    getOrganizationDetailsFromCache(organizationId),
    "academicYears",
    []
  );
  const currentAcademicYear = getCurrentAcademicYear({ organizationId });
  const filteredAcademicYears = _.filter(organizationAcademicYears, year => {
    if (year.isCurrentAcademicYear) return true;
    return moment(currentAcademicYear.startDate).isAfter(
      moment(year.endDate),
      "day"
    );
  });
  const academicYearSelected = _.get(
    state,
    "platform.academicYearSelected",
    currentAcademicYear
  );

  const activePage = _.get(state, "login.activeTab", "");
  const activeTab = _.get(state, "teacher.activeTab", "");
  const isDevUser = _.get(state, "login.userInfo.isDevUser", false);

  return {
    nodeId: ownProps.parentId,
    type: ownProps.parentType,
    orgName,
    isOrganizationFree,
    userType: _.get(userInfo, "user_type"),
    validCurriculumPrograms,
    organizationCurriculumPrograms,
    totalLinkedIdentities,
    organizationAcademicYears: filteredAcademicYears,
    academicYearSelected,
    isTeacherPage: activePage == "teacher",
    activeTab,
    isAdminPage: activePage === "admin",
    isCommunityPage: activePage === "community",
    isDevUser,
  };
};

PlatformSwitcher.displayName = "PlatformSwitcher";

export default compose(
  I18nHOC({ resource: ["common", "onBoarding"] }),
  connect(mapStateToProps, mapActionCreators)
)(PlatformSwitcher);
