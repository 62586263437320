import PAComponent from "./PAComponent";
import React from "react";
import { getHOCDisplayName } from "Utils";

function PAHOC(WrappedComponent) {
  class WithPermission extends React.Component {
    render() {
      const {
        shouldCheckPermission,
        errorText,
        errorSubText,
        errorImageSrc,
        redirectButtonText,
        onRedirectButtonClick,
      } = this.props;
      //console.log("showErrorComp", this.props);
      return shouldCheckPermission ? (
        <PAComponent
          perm={this.props.perm}
          showErrorComp={this.props.showErrorComp}
          status={this.props.status}
          errorText={errorText}
          errorSubText={errorSubText}
          errorImageSrc={errorImageSrc}
          redirectButtonText={redirectButtonText}
          onRedirectButtonClick={onRedirectButtonClick}
        >
          <WrappedComponent {...this.props} />
        </PAComponent>
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }

  WithPermission.displayName = `WithPermission(${getHOCDisplayName(
    WrappedComponent
  )})`;
  WithPermission.defaultProps = {
    shouldCheckPermission: true,
  };
  return WithPermission;
}

export default PAHOC;
