import React from "react";

import classes from "./NestedChecklist.scss";

import { connect } from "react-redux";
import { EmptyField } from "UIComponents";
import Groupedview from "./Groupedview";
import {
  mergeSelectedNodes,
  getTopParent,
  generateNestedSelectedData,
  getPYPElementSetFromNodes,
} from "Utils";

import UIBaseComponent from "UIComponents/UIBaseComponent";

const generateNestedSelectedDataMemoize = _.memoize(
  ({ nodes, value }) => {
    return generateNestedSelectedData({
      ...getPYPElementSetFromNodes({ nodes }),
      value,
    });
  },
  params => JSON.stringify(params)
);

class NestedChecklist extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  onItemSelected = ({ selections, parentNode, nodes }) => {
    const {
      props: { value, plannerElementSetData },
    } = this;

    const updatedValue = _.uniq([
      ..._.filter(
        value,
        id =>
          !_.includes(
            _.map(nodes, item => item.id),
            id
          )
      ),
      ...(selections || []),
    ]);

    this.updateValue(updatedValue, {
      nodes,
      rootNodeId: parentNode.id,
      rootNodeKey: plannerElementSetData.type,
    });
  };

  renderOptions = () => {
    const {
      props: {
        optionsValue,
        parentNodes,
        value,
        nodes,
        readOnly,
        plannerElementSetData,
        selectedNodes,
        groupedViewConfig = {},
      },
    } = this;

    const nestedValue = generateNestedSelectedDataMemoize({
      value: optionsValue,
      nodes,
    });

    return (
      <div className={classes.subjectBenchmarkContainer}>
        {_.map(parentNodes, (parentNode, key) => {
          let nestedGroupedValue = [];
          let nestedNodes = [];

          //Handled for Objectives in which they are groupedBy subject groups
          if (
            plannerElementSetData.type != plannerElementSetData.groupedByType
          ) {
            nestedGroupedValue = _.filter(nestedValue, node =>
              _.find(node.associatedParents, { id: parentNode.id })
            );

            nestedNodes = _.filter(nodes, node =>
              _.find(node.associatedParents, { id: parentNode.id })
            );
          }
          //Handled for ATLS in which they are groupedBy ATL root nodes
          else {
            nestedGroupedValue = _.get(
              _.find(nestedValue, {
                id: parentNode.id,
              }),
              "children",
              []
            );

            nestedNodes = _.filter(nodes, node => {
              const topParent = getTopParent({
                nodeId: node.id,
                nodes: nodes,
              });
              return topParent == parentNode.id;
            });
          }

          let filteredNodes = [];
          let filteredValue = [];

          //Handled for Objectives in which they are groupedBy subject groups
          if (
            plannerElementSetData.type != plannerElementSetData.groupedByType
          ) {
            filteredNodes = _.filter(
              selectedNodes,
              nodeItem =>
                !!_.find(nodeItem.associatedParents, { id: parentNode.id })
            );

            filteredValue = _.filter(
              value,
              val => !!_.find(filteredNodes, { id: val })
            );
          }
          //Handled for ATLS in which they are groupedBy ATL root nodes
          else {
            filteredValue = _.filter(value, val => {
              const topParent = getTopParent({
                nodeId: val,
                nodes: selectedNodes,
              });
              return topParent == parentNode.id;
            });
          }

          const rootNodeDepth = _.get(
            _.first(_.orderBy(nestedNodes, "depth")),
            "depth",
            1
          );

          return (
            !_.isEmpty(nestedGroupedValue) && (
              <Groupedview
                nodes={nestedNodes}
                key={parentNode.id}
                parentNode={parentNode}
                selectedValues={filteredValue}
                onItemSelected={this.onItemSelected}
                readOnly={readOnly}
                nestedGroupedValue={nestedGroupedValue}
                rootNodeDepth={rootNodeDepth}
                {...groupedViewConfig}
              />
            )
          );
        })}
      </div>
    );
  };

  shouldShowEditEmpty = () => {
    const {
      props: { nodes, parentNodes },
    } = this;

    return _.isEmpty(parentNodes) || _.isEmpty(nodes);
  };

  renderEditEmpty = () => {
    const emptyText = this.getEditEmptyText();
    return <EmptyField title={emptyText} />;
  };

  getEditEmptyText = () => {
    const { emptyText, emptyParentNodeText, nodes } = this.props;
    if (_.isEmpty(nodes)) {
      return emptyText;
    } else {
      return emptyParentNodeText || emptyText;
    }
  };

  renderEdit = () => {
    const {
      props: { parentNodes },
    } = this;
    return (
      <div className={classes.mainContainer}>
        {this.renderOptions(parentNodes)}
      </div>
    );
  };
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const options = ownProps.options;

  const selectedNodes = _.get(ownProps, "resolvedValue.nodes", []) || [];

  const nodes = _.get(options, "nodes", []) || [];
  const optionsValue = _.get(options, "value", []) || [];

  /*
    //Those ids which are ticked
    value : [7,8] 

    //Flat tree nodes which are created from value
    selectedNodes : [{id:"1",label:"Phase 1–2"},{id:"7",label:"Listening"},{id:"8",label:"Reading"}]  
    

    //All ids
    optionsValue:[7,8,9] 

    // Flat tree nodes which are created from optionsValue
    nodes:[{id:"1",label:"Phase 1–2"},{id:"7",label:"Listening"},{id:"8",label:"Reading"},{id:"2",label:"Phase 3–4"},{id:"9",label:"Speaking"}]
    

    plannerElementSetData:{
      type: oneOf(['MYP_ATL', 'MYP_OBJECTIVES','MYP_LEARNING_STANDARD']),
      parentType: oneOf(['PLANNER_ELEMENT','SUBJECT_GROUP','SUBJECT']),
      groupedByType: oneOf(['MYP_ATL', 'SUBJECT_GROUP','SUBJECT'])
    }

    //Those nodes on which you want to group
    parentNodes :[{id:"1",label:"Mathematics"},{id:"2",label:"Science"}]


  */

  return {
    plannerElementSetData:
      _.get(ownProps, "options.plannerElementSetData", {}) || {},
    optionsValue,
    nodes,
    selectedNodes,
    parentNodes: _.get(ownProps, "options.parentNodes", []) || [],
  };
};

export default connect(mapStateToProps, mapActionCreators)(NestedChecklist);

//Need Review @kunal
