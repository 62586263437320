import {
  CalendarOutlined,
  AttendanceOutlined,
  ClassProgressSummaryOutlined,
  CommentSquareLeftClassroomOutlined,
  DeleteOutlined,
  FamilyOutlined,
  GridOutlined,
  InsightsOutlined,
  JournalOutlined,
  TickFrameOutlined,
  LeLibraryOutlined,
  ProgressReportOutlined,
  SettingsOutlined,
  TeacherResourceOutlined,
  UnitLibraryOutlined,
  UnitPlanOutlined,
  OpenBookOutlined,
  AssessmentandEvaluationOutlined,
  PolicyTickOutlined,
  NotificationSettingsOutlined,
  AtlDuotone,
  CalendarScheduleWeekOutlined,
  SortOutlined,
  LoudspeakerOutlined,
  CalendarDateOutlined,
  PresentationOutlined,
  SetupOutlined,
  InsightsSquareOutlined,
  AssessmentEvaluatedOutlined,
  ClassSettingsOutlined,
  KeyRelatedConceptsDuotone,
  SubjectGenericDuotone,
  LearnerProfileDuotone,
  ProgrammeofInquiryDuotone,
  CommentDotsRightOutlined,
  ThemeDescriptorInsightsDuotone,
  AssessmentOutlined,
  StudentOutlined,
  StudentEvaluateOutlined,
} from "@toddle-design/web-icons";

//Here, we can't import Component from "UIComponents" as it is breaking other dependencies from Utils folder
import CurriculumProgramLogo from "UIComponents/CurriculumProgramLogo";
import React from "react";
import {
  getRecentSearchesFromStorageByMigration,
  setRecentSearchesInStorage,
} from "services/localStorage";

import ACLStore from "lib/aclStore";
import classes from "./Utils.scss";
import { colors } from "@toddle-design/theme";

import { getOrganizationCurriculumProgramsFromCache } from "modules/CommonGraphqlHelpers";
import { getUserInfo } from "Login/modules/LoginModule";

const commonStyle = {
  iconStyle: {
    height: 32,
    borderRadius: 8,
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const CURRICULUM_PROGRAMME_MAPPING = {
  IB_MYP: "MYP",
  IB_PYP: "PYP",
  UBD: "UbD",
  IB_DP: "DP",
};

const CURRICULUM_PROGRAMME_ICON_STYLES = {
  IB_PYP: {
    color: colors.decorativeSurfaceFourL,
    className: classes.pypModule,
  },
  IB_MYP: {
    color: colors.decorativeSurfaceTwoL,
    className: classes.mypModule,
  },
  UBD: {
    color: colors.decorativeSurfaceOneL,
    className: classes.ubdModule,
  },
  IB_DP: {
    color: colors.decorativeSurfaceThreeL,
    className: classes.dpModule,
  },
};

export const ROUTE_TYPES = {
  HOMEROOTROUTE: "HOMEROOTROUTE",
  HOMEROUTE: "HOMEROUTE",
  ADMINROUTE: "ADMINROUTE",
  FEATUREROUTE: "FEATUREROUTE",
};

const LOCAL_SEARCH_STORAGE_COUNT = 25;

/**
 * this is constants of all modules searchable
 */
export const typesConfig = {
  COURSE: {
    id: "COURSE",
    title: "",
    subTitle: "teacherHomePage:units_of_inquiry",
    searchText: [],
    order: 23,
  },
  UNIT_PLAN: {
    id: "UNIT_PLAN",
    title: "common:unit_plan_plural",
    subTitle: "teacherHomePage:units_of_inquiry",
    searchText: [],
    perms: [],
    isPath: true,
    path: [
      { label: "variable", value: "courseName" },
      { label: "text", value: "teacherHomePage:units_of_inquiry" },
    ],
  },
  STUDENT_PROFILE: {
    id: "STUDENT_PROFILE",
    title: "common:student_plural",
    subTitle: "teacherHomePage:student_profile",
    searchText: [],
    perms: ["TeacherPortal:StudentPortfolio"],
    isPath: true,
    path: [
      { label: "variable", value: "courseName" },
      { label: "text", value: "teacherHomePage:student_profile" },
    ],
  },
  CLASSROOM: {
    id: "CLASSROOM",
    title: "common:classroom",
    subTitle: "",
    searchText: [],
  },
  PARENT: {
    id: "PARENT",
    title: "",
    subTitle: "teacherHomePage:student_profile",
    searchText: [],
  },
  LE: {
    id: "LE",
    title: "common:le_and_assessments",
    subTitle: "common:le_and_assessments",
    searchText: [],
    isPath: true,
    path: [
      { label: "variable", value: "courseName" },
      { label: "text", value: "teacherHomePage:units_of_inquiry" },
      { label: "variable", value: "unitName" },
    ],
    perms: ["AdminPortal", "TeacherPortal"],
  },
  IB_PYP: {
    id: "IB_PYP",
    title: "common:pyp_home",
    curriculumPrograms: ["IB_PYP"],
    order: 1,
  },
  IB_MYP: {
    id: "IB_MYP",
    title: "common:myp_home",
    curriculumPrograms: ["IB_MYP"],
    order: 1,
  },
  UBD: {
    id: "UBD",
    title: "common:ubd_home",
    curriculumPrograms: ["UBD"],
    order: 1,
  },
  HOME_ANNOUNCEMENT_MODULE: {
    id: "HOME_ANNOUNCEMENT_MODULE",
    title: "common:announcement_plural",
    subTitle: "common:home_wth_label",
    searchText: ["anno", "circ", "noti"],
    perms: ["Common:Announcement"],
    order: 4,
  },
  HOME_ATTENDANCE_MODULE: {
    id: "HOME_ATTENDANCE_MODULE",
    title: "attendance:attendance_manager",
    subTitle: "common:home_wth_label",
    searchText: ["atte", "roll", "call", "abse", "pres"],
    perms: ["TeacherPortal:AttendanceManager"],
    order: 5,
  },
  HOME_CLASSROOM_MODULE: {
    id: "HOME_CLASSROOM_MODULE",
    title: "classRoom:classroom_review",
    subTitle: "common:home_wth_label",
    searchText: ["class", "assig", "strea", "work", "homew", "disc", "zoo"],
    perms: ["TeacherPortal:ClassroomTodo", "StudentPortal:Todos"],
    order: 7,
  },
  HOME_CALENDAR_MODULE: {
    id: "HOME_CALENDAR_MODULE",
    title: "common:calendar",
    subTitle: "common:home_wth_label",
    searchText: ["calen", "sche", "call", "zoo", "disc"],
    perms: ["Common:PlatformCalendar"],
    order: 6,
  },
  HOME_FAMILY_MESSAGING_MODULE: {
    id: "HOME_FAMILY_MESSAGING_MODULE",
    title: "configuration:family_messaging",
    subTitle: "common:home_wth_label",
    searchText: ["fam", "mess", "pare", "ptm", "talk"],
    perms: ["TeacherPortal:FamilyCommunications"],
    order: 8,
  },
  HOME_AUTH_EVALUATION: {
    id: "HOME_AUTH_EVALUATION",
    title: "teacherHomePage:authorizationEvaluation",
    subTitle: "common:home_wth_label",
    curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD"],
    perms: ["Common:SnpV2"],
    order: 20,
    customRoute: () => {
      const isAdmin = ACLStore.can("AdminPortal");
      if (isAdmin) {
        return ROUTE_TYPES.ADMINROUTE;
      }
    },
  },
  ADMIN_PANEL_MODULE: {
    id: "ADMIN_PANEL_MODULE",
    title: "common:admin_portal",
    searchText: ["adm", "pane", "contr", "pref", "setu", "sett"],
    perms: ["AdminPortal"],
    order: 3,
  },
  ADMIN_SCHOOL_SETUP_MODULE: {
    id: "ADMIN_SCHOOL_SETUP_MODULE",
    title: "teacherHomePage:school_setup",
    subTitle: "common:admin_portal",
    searchText: ["setu", "scho", "clas", "cour", "sync", "grad", "user"],
    perms: ["AdminPortal"],
    order: 9,
  },
  ADMIN_ACADEMIC_SETUP_MODULE: {
    id: "ADMIN_ACADEMIC_SETUP_MODULE",
    title: "teacherHomePage:academic_setup",
    subTitle: "common:admin_portal",
    searchText: ["acade", "setu", "sns", "benc", "atl", "approa", "subj"],
    perms: ["AdminPortal"],
    curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD"],
    order: 10,
  },
  ADMIN_BIN_MODULE: {
    id: "ADMIN_BIN_MODULE",
    title: "common:trash",
    subTitle: "common:admin_portal",
    searchText: ["bin", "arch", "rest", "undel", "dele", "tras"],
    perms: ["AdminPortal:UnitsTrash", "AdminPortal:LesTrash"],
    order: 14,
  },
  ADMIN_ANNOUNCEMENT_MODULE: {
    id: "ADMIN_ANNOUNCEMENT_MODULE",
    title: "common:announcement_plural",
    subTitle: "common:admin_portal",
    searchText: ["anno", "circ", "noti"],
    perms: ["AdminPortal:Circular"],
    order: 13,
  },
  ADMIN_UNIT_PLANNING_MODULE: {
    id: "ADMIN_UNIT_PLANNING_MODULE",
    title: "teacherHomePage:units_of_inquiry",
    subTitle: "common:admin_portal",
    searchText: ["unit", "list", "plan"],
    perms: ["AdminPortal:UnitPlans"],
    curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD"],
    order: 11,
  },
  ADMIN_REPORT_MODULE: {
    id: "ADMIN_REPORT_MODULE",
    title: "common:report_card",
    subTitle: "common:admin_portal",
    searchText: ["repo", "progr", "resu", "asse", "rate"],
    perms: ["AdminPortal"],
    curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD"],
    order: 12,
  },
  ADMIN_EDUCATION_CENTER_MODULE: {
    id: "ADMIN_EDUCATION_CENTER_MODULE",
    title: "teacherHomePage:educator_center",
    subTitle: "common:admin_portal",
    searchText: ["edu", "cent"],
    perms: ["AdminPortal"],
    curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD"],
    order: 15,
  },
  ADMIN_PLANNING_INSIGHT_MODULE: {
    id: "ADMIN_PLANNING_INSIGHT_MODULE",
    title: "common:planning_insights",
    subTitle: "common:admin_portal",
    searchText: ["plan", "ins"],
    perms: ["AdminPortal:PlanningInsights"],
    curriculumPrograms: ["IB_PYP"],
    order: 16,
  },
  ADMIN_SCHOOL_CALENDAR_MODULE: {
    id: "ADMIN_SCHOOL_CALENDAR_MODULE",
    title: "configuration:school_calendar",
    subTitle: "common:admin_portal",
    searchText: ["cal"],
    perms: ["AdminPortal"],
    order: 19,
  },
  ADMIN_SETTINGS_MODULE: {
    id: "ADMIN_SETTINGS_MODULE",
    title: "common:settings",
    subTitle: "common:admin_portal",
    searchText: ["set"],
    perms: ["AdminPortal:Settings"],
    order: 17,
  },
  ADMIN_AUTH_EVALUATION: {
    id: "ADMIN_AUTH_EVALUATION",
    title: "teacherHomePage:authorizationEvaluation",
    subTitle: "common:admin_portal",
    perms: ["AdminPortal", "Common:SnpV2"],
    permsCheckMode: "AND",
    curriculumPrograms: ["IB_PYP", "IB_MYP", "UBD"],
    order: 20,
  },
  WEEKLY_SCHEDULE_PYP_MODULE: {
    id: "WEEKLY_SCHEDULE_PYP_MODULE",
    title: "teacherHomePage:weekly_planning",
    subTitle: "teacherHomePage:weekly_planning",
    searchText: ["week", "sched", "plan", "time"],
    perms: ["TeacherPortal:Scheduler"],
    curriculumPrograms: ["IB_PYP"],
    order: 29,
  },
  WEEKLY_SCHEDULE_MYP_MODULE: {
    id: "WEEKLY_SCHEDULE_MYP_MODULE",
    title: "teacherHomePage:weekly_planning",
    subTitle: "teacherHomePage:weekly_planning",
    searchText: ["week", "sched", "plan", "time"],
    perms: ["TeacherPortal:SchedulerMYP"],
    curriculumPrograms: ["IB_MYP"],
    order: 29,
  },
  WEEKLY_SCHEDULE_UBD_MODULE: {
    id: "WEEKLY_SCHEDULE_UBD_MODULE",
    title: "teacherHomePage:weekly_planning",
    subTitle: "teacherHomePage:weekly_planning",
    searchText: ["week", "sched", "plan", "time"],
    perms: ["TeacherPortal:SchedulerUBD"],
    curriculumPrograms: ["UBD"],
    order: 29,
  },
  PLANNING_INSIGHTS_MODULE: {
    id: "PLANNING_INSIGHTS_MODULE",
    title: "common:insights",
    subTitle: "teacherHomePage:planning_insights",
    searchText: ["insi", "plan", "anal"],
    perms: ["TeacherPortal:PlanningInsights", "AdminPortal:PlanningInsights"],
    order: 31,
  },
  SUBJECT_STANDARDS_MODULE: {
    id: "SUBJECT_STANDARDS_MODULE",
    title: "teacherHomePage:sns",
    subTitle: "teacherHomePage:sns",
    searchText: ["sns", "scop", "benc", "outc", "lear"],
    order: 30,
    perms: ["TeacherPortal:ScopeAndSequance"],
  },
  CLASSROOM_MODULE: {
    id: "CLASSROOM_MODULE",
    title: "teacherHomePage:classRoom",
    subTitle: "teacherHomePage:class_stream",
    searchText: ["class", "assi", "stre", "work", "home", "disc", "zoo"],
    perms: ["Common:Classroom"],
    order: 21,
  },
  CONTINUUM_MODULE_PYP: {
    id: "CONTINUUM_MODULE_PYP",
    title: "teacherHomePage:continuum",
    subTitle: "teacherHomePage:continuum",
    searchText: ["con", "conti"],
    perms: ["TeacherPortal:Continuum"],
    curriculumPrograms: ["IB_PYP"],
    order: 22,
  },
  CONTINUUM_MODULE_MYP: {
    id: "CONTINUUM_MODULE_MYP",
    title: "teacherHomePage:continuum",
    subTitle: "teacherHomePage:continuum",
    searchText: ["con", "conti"],
    perms: ["TeacherPortal:MYPContinuum"],
    curriculumPrograms: ["IB_MYP"],
    order: 22,
  },
  CONTINUUM_MODULE_UBD: {
    id: "CONTINUUM_MODULE_UBD",
    title: "teacherHomePage:continuum",
    subTitle: "teacherHomePage:continuum",
    searchText: ["con", "conti"],
    perms: ["TeacherPortal:UBDContinuum"],
    curriculumPrograms: ["UBD"],
    order: 22,
  },
  JOURNAL_MODULE: {
    id: "JOURNAL_MODULE",
    title: "teacherHomePage:journal",
    subTitle: "teacherHomePage:journal",
    searchText: ["jour", "portf", "clas", "post", "evid"],
    perms: ["TeacherPortal:Journal", "StudentPortal:Journal"],
    order: 22,
  },
  CALENDAR_MODULE: {
    id: "CALENDAR_MODULE",
    title: "common:calendar",
    subTitle: "common:calendar",
    searchText: ["cale", "sche", "call", "zoo", "disc"],
    perms: ["TeacherPortal", "AdminPortal"],
    order: 25,
  },
  ATTENDANCE_MODULE: {
    id: "ATTENDANCE_MODULE",
    title: "common:attendance",
    subTitle: "common:attendance",
    searchText: ["atte", "roll", "call", "abs", "pres"],
    perms: ["TeacherPortal:Attendance"],
    order: 24,
  },
  REPORT_MODULE: {
    id: "REPORT_MODULE",
    title: "common:reports",
    subTitle: "teacherHomePage:progress_report",
    searchText: ["repo", "prog", "resu", "asse", "rate"],
    perms: ["TeacherPortal:ProgressReport"],
    order: 28,
  },
  CPS_MODULE: {
    id: "CPS_MODULE",
    title: "teacherHomePage:class_progress_summary",
    subTitle: "teacherHomePage:class_progress_summary",
    perms: ["TeacherPortal:ClassProgressSummary"],
    searchText: ["prog", "summ", "clas", "asse", "rat"],
    order: 27,
  },
  TEACHER_RESOURCES_MODULE: {
    id: "TEACHER_RESOURCES_MODULE",
    title: "teacherHomePage:teacher_resources",
    subTitle: "teacherHomePage:teacher_resources",
    searchText: ["reso", "book", "poli", "docu", "guid"],
    perms: ["TeacherPortal:TeacherResources"],
    order: 33,
  },
  UNIT_LIBRARY_MODULE: {
    id: "UNIT_LIBRARY_MODULE",
    title: "teacherHomePage:unit_library",
    subTitle: "teacherHomePage:unit_library",
    searchText: ["libr", "unit", "arch", "prev"],
    perms: ["TeacherPortal:UnitLibrary"],
    order: 34,
  },
  LE_LIBRARY_MODULE: {
    id: "LE_LIBRARY_MODULE",
    title: "common:learning_experience_library",
    subTitle: "common:learning_experience_library",
    perms: [
      "TeacherPortal:LELibrary",
      "TeacherPortal:LELibraryMyp",
      "TeacherPortal:LELibraryUbd",
    ],
    order: 35,
  },
  STUDENT_PROFILE_MODULE: {
    id: "STUDENT_PROFILE_MODULE",
    title: "teacherHomePage:student_profile",
    subTitle: "teacherHomePage:student_profile",
    searchText: ["prof", "stu", "list", "code"],
    perms: ["TeacherPortal:StudentPortfolio"],
    order: 26,
  },
  ASSES_LEARNING_MODULE: {
    id: "ASSES_LEARNING_MODULE",
    title: "teacherHomePage:assess_learning",
    subTitle: "teacherHomePage:assess_learning",
    searchText: ["as", "lear"],
    perms: ["TeacherPortal:AssessmentEvaluation"],
    order: 32,
  },
  TEACHER_POLICIES_MODULE: {
    id: "TEACHER_POLICIES_MODULE",
    title: "teacherHomePage:school_policy",
    subTitle: "teacherHomePage:school_policy",
    searchText: ["pol"],
    perms: ["TeacherPortal:TeacherSchoolPolicies"],
    order: 34,
  },
  CLASS_SETTINGS_MODULE: {
    id: "CLASS_SETTINGS_MODULE",
    title: "common:class_settings",
    subTitle: "common:class_settings",
    perms: ["TeacherPortal:ClassSetting"],
    order: 34,
  },
  TEACHER_NOTIFICATION_SETTINGS_MODULE: {
    id: "TEACHER_NOTIFICATION_SETTINGS_MODULE",
    title: "common:notification_settings",
    subTitle: "common:notification_settings",
    searchText: ["not"],
    perms: ["Common:NotificationConfiguration"],
    order: 36,
  },
  MANAGE_FAMILY_MODULE: {
    id: "MANAGE_FAMILY_MODULE",
    title: "common:manage_family_members",
    subTitle: "common:manage_family_members",
    searchText: ["fami"],
    perms: ["TeacherPortal:ManageFamilyMembers"],
    order: 37,
  },
  ATL_MODULE: {
    id: "ATL_MODULE",
    title: "common:atls_label",
    isPath: true,
    path: [
      { label: "text", value: "teacherHomePage:planning_insights" },
      { label: "text", value: "common:atls_label" },
    ],
    subTitle: "common:atls_label",
    searchText: ["atl"],
    perms: ["TeacherPortal:PlanningInsights"],
    order: 38,
  },
  CONCEPT_INSIGHT_MODULE: {
    id: "CONCEPT_INSIGHT_MODULE",
    title: "common:concept_plural",
    subTitle: "common:concept_plural",
    isPath: true,
    path: [
      { label: "text", value: "teacherHomePage:planning_insights" },
      { label: "text", value: "common:concept_plural" },
    ],
    searchText: ["conc"],
    perms: ["TeacherPortal:PlanningInsights"],
    order: 38,
  },
  THEME_DESCRIPTOR_MODULE: {
    id: "THEME_DESCRIPTOR_MODULE",
    title: "POI:theme_descriptors",
    subTitle: "POI:theme_descriptors",
    isPath: true,
    path: [
      { label: "text", value: "teacherHomePage:planning_insights" },
      { label: "text", value: "POI:theme_descriptors" },
    ],
    searchText: ["theme"],
    perms: ["TeacherPortal:PlanningInsights"],
    order: 38,
  },
  LPA_INSIGHT_MODULE: {
    id: "LPA_INSIGHT_MODULE",
    title: "common:lp_label",
    subTitle: "common:lp_label",
    isPath: true,
    path: [
      { label: "text", value: "teacherHomePage:planning_insights" },
      { label: "text", value: "common:lp_label" },
    ],
    searchText: ["lear"],
    perms: ["TeacherPortal:PlanningInsights"],
    order: 38,
  },
  SUBJECT_INSIGHT_MODULE: {
    id: "SUBJECT_INSIGHT_MODULE",
    title: "common:subject_plural",
    subTitle: "common:subject_plural",
    isPath: true,
    path: [
      { label: "text", value: "teacherHomePage:planning_insights" },
      { label: "text", value: "common:subject_plural" },
    ],
    searchText: ["sub"],
    perms: ["TeacherPortal:PlanningInsights"],
    order: 38,
  },
  POI_MODULE: {
    id: "POI_MODULE",
    title: "teacherHomePage:poi",
    subTitle: "teacherHomePage:poi",
    isPath: true,
    path: [
      { label: "text", value: "teacherHomePage:planning_insights" },
      { label: "text", value: "teacherHomePage:poi" },
    ],
    perms: ["TeacherPortal:POI"],
    order: 39,
  },
  ALL_STUDENT_ASSIGNMENT_SUBMISSION: {
    id: "ALL_STUDENT_ASSIGNMENT_SUBMISSION",
    title: "",
    subTitle: "",
    isPath: true,
    path: [
      { label: "variable", value: "courseName" },
      { label: "text", value: "teacherHomePage:class_stream" },
    ],
    searchText: [],
    perms: [],
    order: 54,
  },
  STUDENT_ASSIGNMENT_SUBMISSION: {
    id: "STUDENT_ASSIGNMENT_SUBMISSION",
    title: "",
    subTitle: "",
    isPath: true,
    path: [
      { label: "variable", value: "courseName" },
      { label: "text", value: "teacherHomePage:class_stream" },
    ],
    searchText: [],
    perms: [],
    order: 54,
  },
  MODULES: {
    id: "MODULES",
    title: "common:modules",
    subTitle: "common:modules",
    searchText: [],
  },
};

export const typesModulesList = [
  typesConfig.IB_PYP.id,
  typesConfig.IB_MYP.id,
  typesConfig.UBD.id,
  typesConfig.HOME_ANNOUNCEMENT_MODULE.id,
  typesConfig.HOME_ATTENDANCE_MODULE.id,
  typesConfig.HOME_CLASSROOM_MODULE.id,
  typesConfig.HOME_CALENDAR_MODULE.id,
  typesConfig.HOME_FAMILY_MESSAGING_MODULE.id,
  typesConfig.HOME_AUTH_EVALUATION.id,
  typesConfig.ADMIN_PANEL_MODULE.id,
  typesConfig.ADMIN_SCHOOL_SETUP_MODULE.id,
  typesConfig.ADMIN_ACADEMIC_SETUP_MODULE.id,
  typesConfig.ADMIN_BIN_MODULE.id,
  typesConfig.ADMIN_ANNOUNCEMENT_MODULE.id,
  typesConfig.ADMIN_UNIT_PLANNING_MODULE.id,
  typesConfig.ADMIN_REPORT_MODULE.id,
  typesConfig.ADMIN_EDUCATION_CENTER_MODULE.id,
  typesConfig.ADMIN_PLANNING_INSIGHT_MODULE.id,
  typesConfig.ADMIN_SCHOOL_CALENDAR_MODULE.id,
  typesConfig.ADMIN_SETTINGS_MODULE.id,
  typesConfig.ADMIN_AUTH_EVALUATION.id,
];

export const modulesAsFiltersList = [
  typesConfig.MODULES.id,
  typesConfig.UNIT_PLAN.id,
  typesConfig.STUDENT_PROFILE.id,
  typesConfig.LE.id,
  typesConfig.REPORT_MODULE.id,
];

export const courseBasedModules = [
  typesConfig.COURSE.id,
  typesConfig.WEEKLY_SCHEDULE_PYP_MODULE.id,
  typesConfig.WEEKLY_SCHEDULE_MYP_MODULE.id,
  typesConfig.WEEKLY_SCHEDULE_UBD_MODULE.id,
  typesConfig.PLANNING_INSIGHTS_MODULE.id,
  typesConfig.SUBJECT_STANDARDS_MODULE.id,
  typesConfig.CLASSROOM_MODULE.id,
  typesConfig.JOURNAL_MODULE.id,
  typesConfig.CONTINUUM_MODULE_PYP.id,
  typesConfig.CONTINUUM_MODULE_MYP.id,
  typesConfig.CONTINUUM_MODULE_UBD.id,
  typesConfig.CALENDAR_MODULE.id,
  typesConfig.ATTENDANCE_MODULE.id,
  typesConfig.REPORT_MODULE.id,
  typesConfig.CPS_MODULE.id,
  typesConfig.TEACHER_RESOURCES_MODULE.id,
  typesConfig.UNIT_LIBRARY_MODULE.id,
  typesConfig.LE_LIBRARY_MODULE.id,
  typesConfig.STUDENT_PROFILE_MODULE.id,
  typesConfig.ASSES_LEARNING_MODULE.id,
  typesConfig.TEACHER_POLICIES_MODULE.id,
  typesConfig.TEACHER_NOTIFICATION_SETTINGS_MODULE.id,
  typesConfig.MANAGE_FAMILY_MODULE.id,
  typesConfig.ATL_MODULE.id,
  typesConfig.CONCEPT_INSIGHT_MODULE.id,
  typesConfig.THEME_DESCRIPTOR_MODULE.id,
  typesConfig.LPA_INSIGHT_MODULE.id,
  typesConfig.SUBJECT_INSIGHT_MODULE.id,
  typesConfig.CLASS_SETTINGS_MODULE.id,
  typesConfig.POI_MODULE.id,
];

const CURRICULUM_PROGRAMME = [
  typesConfig.IB_PYP.id,
  typesConfig.IB_MYP.id,
  typesConfig.UBD.id,
];

const HOME_ROOT_ROUTES = [
  ...CURRICULUM_PROGRAMME,
  typesConfig.ADMIN_PANEL_MODULE.id,
];

/**
 * Utils functions
 */

export const getDynamicTypesConfig = () => {
  const userInfo = getUserInfo();

  const organizationId = _.get(userInfo, "org_id");

  const curriculumProgramsObj = getOrganizationCurriculumProgramsFromCache(
    organizationId
  );

  const curriculumPrograms = _.get(
    curriculumProgramsObj,
    "curriculumPrograms",
    []
  );

  const updatedTypesConfig = { ...typesConfig };

  _.forEach(curriculumPrograms, ({ type, label, acronym }) => {
    const curriculumProgramNode = typesConfig[type];
    if (!_.isEmpty(curriculumProgramNode)) {
      updatedTypesConfig[type] = {
        ...curriculumProgramNode,
        skipTitleLocalization: true,
        title: label,
        acronym,
      };
    }
  });

  return updatedTypesConfig;
};

export const getModuleItem = ({ type, orgId, t, curriculumProgram }) => {
  const typesConfig = getDynamicTypesConfig();

  const { title, subTitle, order, skipTitleLocalization } = typesConfig[type];

  const localizedTitle = skipTitleLocalization ? title : t(title);

  const shouldAddSuffix = curriculumProgram && order != 1;

  const { acronym } = typesConfig[curriculumProgram] ?? {};

  return {
    id: shouldAddSuffix ? `${curriculumProgram}_${type}` : `0_${type}`,
    type,
    title: shouldAddSuffix ? `${localizedTitle} - ${acronym}` : localizedTitle,
    subTitle: t(subTitle),
    metadata: {
      curriculumProgram: shouldAddSuffix ? curriculumProgram : null,
    },
    order,
  };
};

export const getCourseModuleItem = ({ type, courseId, orgId, title }) => {
  const typesConfig = getDynamicTypesConfig();

  const { order, perms = [] } = typesConfig[type];

  return {
    id: `${courseId}_${type}_${orgId}`,
    type,
    metadata: { courseId, courseName: title, name: title },
    title,
    order,
    perms,
  };
};

export const getModulesFiltersList = ({ orgId, t }) => {
  return modulesAsFiltersList.reduce((acc, type) => {
    const typesConfig = getDynamicTypesConfig();

    const { perms = [], permsCheckMode = "OR" } = typesConfig[type];

    //check permissions
    //1st case is open for all perms array is empty
    //2nd check permissions array
    const isModuleEnabled =
      !_.get(perms, "length", 0) || permsCheckMode === "AND"
        ? ACLStore.canAll(perms)
        : ACLStore.can(perms);

    if (isModuleEnabled) {
      acc.push(getModuleItem({ type, orgId, t }));
    }
    return acc;
  }, []);
};

const typesModulesListWithHomeRoutes = ({ curriculumProgramType }) => {
  const typesId = [];

  _.forEach(typesModulesList, module => {
    const typesConfig = getDynamicTypesConfig();

    const { curriculumPrograms = [] } = typesConfig[module];

    //if size of program and if there is intersection between org program and
    //module program is there than only append to array.
    if (_.size(curriculumPrograms)) {
      if (_.size(_.intersection(curriculumPrograms, curriculumProgramType))) {
        typesId.push(module);
      }
    } else {
      typesId.push(module);
    }
  });

  return typesId;
};

export const getModuleTypeBySearchTerm = ({
  term = "",
  orgId,
  module,
  curriculumProgramType,
  t,
}) => {
  if (!term && !module) {
    return [];
  }

  const typesConfig = getDynamicTypesConfig();

  //add home routes in typesmodules list
  const newRoutes = typesModulesListWithHomeRoutes({
    curriculumProgramType,
  });

  return newRoutes.reduce((acc, key) => {
    const value = typesConfig[key];
    const {
      perms = [],
      permsCheckMode = "OR",
      title,
      curriculumPrograms = [],
    } = value;

    //check permissions
    //1st case is open for all perms array is empty
    //2nd check permissions array
    const isModuleEnabled =
      !_.get(perms, "length", 0) || permsCheckMode === "AND"
        ? ACLStore.canAll(perms)
        : ACLStore.can(perms);

    if (isModuleEnabled) {
      if (_.size(curriculumPrograms)) {
        //find intersection of org curriculum and module curriculum
        const intersectedPrograms = _.intersection(
          curriculumPrograms,
          curriculumProgramType
        );

        //for each intersected program append module of that curriculum
        _.forEach(intersectedPrograms, curr => {
          acc.push(
            getModuleItem({ type: key, orgId, t, curriculumProgram: curr })
          );
        });
      } else {
        acc.push(getModuleItem({ type: key, orgId, t }));
      }
    }

    return acc;
  }, []);
};

export const getCoursesBySearchTerm = ({ term = "", data = [], orgId, t }) => {
  if (!term) {
    return [];
  }

  return data.reduce((acc, currentValue) => {
    const {
      title = "",
      id: courseId,
      curriculumProgram: { id: curriculumProgramId } = {},
    } = currentValue;

    acc.push(
      {
        id: `${courseId}_COURSE_${orgId}`,
        type: typesConfig.COURSE.id,
        metadata: {
          courseId,
          curriculum: curriculumProgramId,
          courseName: title,
          name: title,
        },
        // displayText: `Class: ${title}`,
        title,
        order: typesConfig.COURSE.order,
      },
      ...classModules({ courseId, orgId, title, course: currentValue })
    );

    return acc;
  }, []);
};

//this function will filter recent search data
export const filterRecentSearches = ({ recentSearches, module, t }) => {
  return _.reduce(
    recentSearches,
    (result, search) => {
      const type = search.type;
      const curriculumProgram = _.get(search, "metadata.curriculumProgram", "");
      const item = getModuleItem({ type, t, curriculumProgram });

      const moduleItem = _.first(parseResult({ result: [item], t }));
      const nonModuleItem = _.first(parseResult({ result: [search], t }));

      if (module) {
        if (module === typesConfig.MODULES.id) {
          if (_.includes(typesModulesList, type)) {
            result.push(moduleItem);
          }
        } else if (module === type) {
          result.push(nonModuleItem);
        }
      } else {
        if (_.includes(typesModulesList, type)) {
          result.push(moduleItem);
        } else {
          result.push(nonModuleItem);
        }
      }

      return result;
    },
    []
  );
};

const checkIsModuleCourseRelevant = ({ key, course }) => {
  const typesConfig = getDynamicTypesConfig();

  const value = typesConfig[key];
  const { curriculumPrograms = [] } = value;
  if (_.isEmpty(curriculumPrograms)) {
    return true;
  } else {
    const courseCurriculumProgram = _.get(course, "curriculumProgram.type", "");
    return _.includes(curriculumPrograms, courseCurriculumProgram);
  }
};

/**
 * this function will search by course module name
 * if match then it will return that module for all classes
 */
export const getCourseBasedModule = ({ term = "", orgId, courses = [], t }) => {
  if (!term) {
    return [];
  }

  const typesConfig = getDynamicTypesConfig();

  return _.reduce(
    courseBasedModules,
    (result, key) => {
      const value = typesConfig[key];
      const { perms = [], permsCheckMode = "OR", subTitle } = value;

      //check permissions
      //1st case is open for all perms array is empty
      //2nd check permissions array
      const isModuleEnabled =
        !_.get(perms, "length", 0) || permsCheckMode === "AND"
          ? ACLStore.canAll(perms)
          : ACLStore.can(perms);
      const modules = [];

      if (isModuleEnabled) {
        _.forEach(courses, course => {
          const { id: courseId, title = "" } = course;

          if (checkIsModuleCourseRelevant({ key, course })) {
            const courseModuleItem = getCourseModuleItem({
              type: key,
              courseId,
              orgId,
              title,
            });

            modules.push(courseModuleItem);
          }
        });
      }

      result.push(...modules);
      return result;
    },
    []
  );
};

const getRecentSearches = ({ userId }) => {
  const recentSearches = getRecentSearchesFromStorageByMigration() || [];

  const currentUserSearches = _.get(
    recentSearches,
    `data.${userId}.searches`,
    []
  );

  //return total history , current history and index (necessary )
  return {
    currentUserSearches,
  };
};

export const getRecentSearchItems = ({ userId, module, t }) => {
  const { currentUserSearches } = getRecentSearches({ userId });
  const filteredSearches = filterRecentSearches({
    recentSearches: currentUserSearches,
    module,
    t,
  });
  const SLICE_RESULT_COUNT = module ? 5 : 3;

  const results = _.slice(filteredSearches, 0, SLICE_RESULT_COUNT);
  return results;
};

export const changeLocalStorageData = ({ data, userId }) => {
  //get current user searches
  const { currentUserSearches } = getRecentSearches({ userId });

  //find id of this data in history if id found then no need to append it
  const indexOfData = _.findIndex(
    currentUserSearches,
    his => his.id === data.id
  );

  if (indexOfData > -1) {
    currentUserSearches.splice(indexOfData, 1);
  }

  currentUserSearches.unshift(data);

  const historyLength = _.get(currentUserSearches, "length", 0);

  if (historyLength > LOCAL_SEARCH_STORAGE_COUNT) {
    currentUserSearches.pop();
  }

  //if no history found for user we will append new data
  //else make change in current data
  const updatedHistory = {
    [userId]: {
      searches: currentUserSearches,
    },
  };

  setRecentSearchesInStorage(updatedHistory);
};

//this array will always be there if class name match with search text
const classModules = ({ courseId, orgId, title, course }) => {
  return _.reduce(
    courseBasedModules,
    (result, type) => {
      const module = getCourseModuleItem({ type, courseId, orgId, title });
      const { perms = [], permsCheckMode = "OR" } = module;
      //check permissions
      //1st case is open for all perms array is empty
      //2nd check permissions array
      const isModuleEnabled =
        !_.get(perms, "length", 0) || permsCheckMode === "AND"
          ? ACLStore.canAll(perms)
          : ACLStore.can(perms);

      const isModuleCourseRelevant = checkIsModuleCourseRelevant({
        key: type,
        course,
      });

      if (isModuleEnabled && isModuleCourseRelevant) {
        const { perms, permsCheckMode, ...rest } = module;
        result.push(rest);
      }

      return result;
    },
    []
  );
};

const createPath = ({ type, t, metadata }) => {
  const typesConfig = getDynamicTypesConfig();

  const { path } = typesConfig[type];
  let text = "";
  _.forEach(path, data => {
    text += text ? ` / ` : "";
    if (data.label === "text") {
      text += `${t(data.value)}`;
    } else {
      text += `${metadata[data.value]}`;
    }
  });

  return text;
};

//this function will return image for different type
export const getImage = ({ type, metadata = {} }) => {
  const { curriculumProgram } = metadata;

  const curriculumStyles = curriculumProgram
    ? CURRICULUM_PROGRAMME_ICON_STYLES[curriculumProgram]
    : {};
  const {
    color: curriculumColor,
    className: curriculumClassName,
  } = curriculumStyles;

  const typesConfig = getDynamicTypesConfig();

  switch (type) {
    case typesConfig.ADMIN_PANEL_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceOneL}
          icon={
            <SortOutlined className={classes.adminSettings} size="xx-small" />
          }
        />
      );
    case typesConfig.IB_PYP.id:
    case typesConfig.IB_MYP.id:
    case typesConfig.UBD.id: {
      const curriculumProgramNode = typesConfig[type];

      const { acronym, id } = curriculumProgramNode;

      return (
        <CurriculumProgramLogo type={id} acronym={acronym} height="32px" />
      );
    }

    case typesConfig.ADMIN_ANNOUNCEMENT_MODULE.id:
      return (
        <IconComponent
          icon={
            <LoudspeakerOutlined className={classes.violet63} size="xx-small" />
          }
          color={colors.decorativeSurfaceTwoL}
        />
      );
    case typesConfig.HOME_ANNOUNCEMENT_MODULE.id:
      return (
        <IconComponent
          icon={
            <LoudspeakerOutlined className={classes.violet63} size="xx-small" />
          }
          color={colors.decorativeSurfaceTwoL}
        />
      );
    case typesConfig.HOME_ATTENDANCE_MODULE.id:
      return (
        <IconComponent
          icon={
            <AttendanceOutlined
              className={classes.adminSettings}
              size="xx-small"
            />
          }
          color={colors.decorativeSurfaceOneL}
        />
      );
    case typesConfig.HOME_CALENDAR_MODULE.id:
      return (
        <IconComponent
          icon={
            <CalendarDateOutlined
              className={classes.yellow50}
              size="xx-small"
            />
          }
          color={colors.decorativeSurfaceThreeL}
        />
      );
    case typesConfig.HOME_CLASSROOM_MODULE.id:
      return (
        <IconComponent
          icon={
            <PresentationOutlined className={classes.red66} size="xx-small" />
          }
          color={colors.decorativeSurfaceFourL}
        />
      );
    case typesConfig.ADMIN_SCHOOL_SETUP_MODULE.id:
      return (
        <IconComponent
          icon={<SetupOutlined className={classes.yellow50} size="xx-small" />}
          color={colors.decorativeSurfaceThreeL}
        />
      );
    case typesConfig.ADMIN_ACADEMIC_SETUP_MODULE.id:
      return (
        <IconComponent
          color={curriculumColor || colors.decorativeSurfaceOneL}
          icon={
            <SetupOutlined
              className={curriculumClassName || classes.adminSettings}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_BIN_MODULE.id:
      return (
        <IconComponent
          icon={<DeleteOutlined className={classes.yellow50} size="xx-small" />}
          color={colors.yellow96}
        />
      );
    case typesConfig.ADMIN_UNIT_PLANNING_MODULE.id:
      return (
        <IconComponent
          color={curriculumColor || colors.decorativeSurfaceOneL}
          icon={
            <UnitPlanOutlined
              className={curriculumClassName || classes.blue40}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_REPORT_MODULE.id:
      return (
        <IconComponent
          color={curriculumColor || colors.decorativeSurfaceFourL}
          icon={
            <ProgressReportOutlined
              className={curriculumClassName || classes.red66}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_EDUCATION_CENTER_MODULE.id:
      return (
        <IconComponent
          color={curriculumColor || colors.decorativeSurfaceFiveL}
          icon={
            <OpenBookOutlined
              className={curriculumClassName || classes.teal42}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_PLANNING_INSIGHT_MODULE.id:
      return (
        <IconComponent
          color={curriculumColor || colors.decorativeSurfaceFourL}
          icon={
            <InsightsSquareOutlined
              className={curriculumClassName || classes.red66}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_SETTINGS_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceOneL}
          icon={<SettingsOutlined className={classes.blue40} size="xx-small" />}
        />
      );
    case typesConfig.HOME_FAMILY_MESSAGING_MODULE.id:
      return (
        <IconComponent
          color={colors.green94}
          icon={
            <CommentDotsRightOutlined
              className={classes.green42}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_SCHOOL_CALENDAR_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceTwoL}
          icon={
            <CalendarScheduleWeekOutlined
              className={classes.violet63}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.ADMIN_AUTH_EVALUATION.id:
    case typesConfig.HOME_AUTH_EVALUATION.id:
      return (
        <IconComponent
          color={curriculumColor || colors.decorativeSurfaceThreeL}
          icon={
            <AssessmentEvaluatedOutlined
              className={curriculumClassName || classes.yellow50}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.WEEKLY_SCHEDULE_PYP_MODULE.id:
    case typesConfig.WEEKLY_SCHEDULE_MYP_MODULE.id:
    case typesConfig.WEEKLY_SCHEDULE_UBD_MODULE.id:
      return (
        <IconComponent
          color={curriculumColor || colors.surfaceLight}
          icon={
            <CalendarScheduleWeekOutlined
              variant="subtle"
              size="xx-small"
              className={curriculumClassName}
            />
          }
        />
      );
    case typesConfig.PLANNING_INSIGHTS_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<InsightsSquareOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.SUBJECT_STANDARDS_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<AssessmentOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.COURSE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<UnitPlanOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.CLASSROOM_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={
            <CommentSquareLeftClassroomOutlined
              variant="subtle"
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.JOURNAL_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<JournalOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.CONTINUUM_MODULE_PYP.id:
    case typesConfig.CONTINUUM_MODULE_MYP.id:
    case typesConfig.CONTINUUM_MODULE_UBD.id:
      return (
        <IconComponent
          color={curriculumColor || colors.surfaceLight}
          icon={
            <TickFrameOutlined
              variant="subtle"
              className={curriculumClassName}
              size="xx-small"
            />
          }
        />
      );
    case typesConfig.CALENDAR_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<CalendarOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.ATTENDANCE_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<AttendanceOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.REPORT_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<ProgressReportOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.CPS_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={
            <ClassProgressSummaryOutlined variant="subtle" size="xx-small" />
          }
        />
      );
    case typesConfig.TEACHER_RESOURCES_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<TeacherResourceOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.UNIT_LIBRARY_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<UnitLibraryOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.LE_LIBRARY_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<LeLibraryOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.STUDENT_PROFILE_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<StudentOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.ASSES_LEARNING_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={
            <AssessmentandEvaluationOutlined variant="subtle" size="xx-small" />
          }
        />
      );
    case typesConfig.TEACHER_POLICIES_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<PolicyTickOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.CLASS_SETTINGS_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<ClassSettingsOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.TEACHER_NOTIFICATION_SETTINGS_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={
            <NotificationSettingsOutlined variant="subtle" size="xx-small" />
          }
        />
      );
    case typesConfig.MANAGE_FAMILY_MODULE.id:
      return (
        <IconComponent
          color={colors.surfaceLight}
          icon={<FamilyOutlined variant="subtle" size="xx-small" />}
        />
      );
    case typesConfig.ATL_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceFourL}
          icon={<AtlDuotone color="pink" size="xx-small" />}
        />
      );
    case typesConfig.CONCEPT_INSIGHT_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceFourL}
          icon={<KeyRelatedConceptsDuotone color="pink" size="xx-small" />}
        />
      );
    case typesConfig.THEME_DESCRIPTOR_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceFourL}
          icon={<ThemeDescriptorInsightsDuotone color="pink" size="xx-small" />}
        />
      );
    case typesConfig.LPA_INSIGHT_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceTwoL}
          icon={<LearnerProfileDuotone color="violet" size="xx-small" />}
        />
      );
    case typesConfig.SUBJECT_INSIGHT_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceFiveL}
          icon={<SubjectGenericDuotone color="teal" size="xx-small" />}
        />
      );
    case typesConfig.POI_MODULE.id:
      return (
        <IconComponent
          color={colors.decorativeSurfaceOneL}
          icon={<ProgrammeofInquiryDuotone color="blue" size="xx-small" />}
        />
      );
    default:
      return null;
  }
};

export const getChipImage = ({ type }) => {
  switch (type) {
    case typesConfig.MODULES.id:
      return <GridOutlined size="xxx-small" />;
    case typesConfig.UNIT_PLAN.id:
      return <UnitPlanOutlined size="xxx-small" />;
    case typesConfig.STUDENT_PROFILE.id:
      return <StudentEvaluateOutlined size="xxx-small" />;
    case typesConfig.LE.id:
      return <AssessmentOutlined size="xxx-small" />;
    case typesConfig.REPORT_MODULE.id:
      return <ClassProgressSummaryOutlined size="xxx-small" />;
    case typesConfig.PLANNING_INSIGHTS_MODULE.id:
      return <InsightsOutlined size="xxx-small" />;
  }
};

export const decideRoute = ({ type }) => {
  const typesConfig = getDynamicTypesConfig();

  const module = typesConfig[type];
  const { customRoute } = module;
  if (customRoute) {
    const customRouteResult = customRoute();
    if (customRouteResult && _.includes(ROUTE_TYPES, customRouteResult)) {
      return customRouteResult;
    }
  }

  if (_.includes(CURRICULUM_PROGRAMME, type)) {
    return ROUTE_TYPES.HOMEROOTROUTE;
  } else if (_.includes(type, "HOME")) {
    return ROUTE_TYPES.HOMEROUTE;
  } else if (_.includes(type, "ADMIN")) {
    return ROUTE_TYPES.ADMINROUTE;
  } else {
    return ROUTE_TYPES.FEATUREROUTE;
  }
};

const MAP_END_POINT = {
  [typesConfig.IB_PYP.id]: "IB_PYP",
  [typesConfig.IB_MYP.id]: "IB_MYP",
  [typesConfig.UBD.id]: "UBD",
  [typesConfig.HOME_ANNOUNCEMENT_MODULE.id]: "circulars",
  [typesConfig.HOME_ATTENDANCE_MODULE.id]: "courses/attendance-manager",
  [typesConfig.HOME_CLASSROOM_MODULE.id]: "classroomTodos",
  [typesConfig.HOME_CALENDAR_MODULE.id]: "courses/my-calendar",
  [typesConfig.HOME_FAMILY_MESSAGING_MODULE.id]: "familyconversations",
  [typesConfig.ADMIN_SCHOOL_SETUP_MODULE.id]: "schoolSetup/school-information",
  [typesConfig.ADMIN_ACADEMIC_SETUP_MODULE.id]: "academicSetup/subjects",
  [typesConfig.ADMIN_BIN_MODULE.id]: "trash/trashUnitPlans",
  [typesConfig.ADMIN_ANNOUNCEMENT_MODULE.id]: "circulars",
  [typesConfig.ADMIN_UNIT_PLANNING_MODULE.id]: "adminunitplans",
  [typesConfig.ADMIN_REPORT_MODULE.id]: "progressreports",
  [typesConfig.ADMIN_AUTH_EVALUATION.id]: "snp_v2",
  [typesConfig.HOME_AUTH_EVALUATION.id]: "snp_v2",
  [typesConfig.WEEKLY_SCHEDULE_PYP_MODULE.id]: "scheduler/weekly",
  [typesConfig.WEEKLY_SCHEDULE_MYP_MODULE.id]: "scheduler/weekly",
  [typesConfig.WEEKLY_SCHEDULE_UBD_MODULE.id]: "scheduler/weekly",
  [typesConfig.PLANNING_INSIGHTS_MODULE.id]: "planningInsights",
  [typesConfig.SUBJECT_STANDARDS_MODULE.id]: "scopeandsequence",
  [typesConfig.CLASSROOM_MODULE.id]: "classroom",
  [typesConfig.JOURNAL_MODULE.id]: "journal",
  [typesConfig.CONTINUUM_MODULE_PYP.id]: "continuum",
  [typesConfig.CONTINUUM_MODULE_MYP.id]: "continuum",
  [typesConfig.CONTINUUM_MODULE_UBD.id]: "continuum",
  [typesConfig.CALENDAR_MODULE.id]: "my-calendar",
  [typesConfig.ATTENDANCE_MODULE.id]: "attendance",
  [typesConfig.REPORT_MODULE.id]: "progressreport",
  [typesConfig.CPS_MODULE.id]: "classProgressSummary",
  [typesConfig.TEACHER_RESOURCES_MODULE.id]: "teacherresources",
  [typesConfig.UNIT_LIBRARY_MODULE.id]: "unitlibrary",
  [typesConfig.LE_LIBRARY_MODULE.id]: "lelibrary",
  [typesConfig.STUDENT_PROFILE_MODULE.id]: "studentPortfolio",
  [typesConfig.ADMIN_EDUCATION_CENTER_MODULE.id]: "educatorcenter",
  [typesConfig.ADMIN_PLANNING_INSIGHT_MODULE.id]: "planningInsights",
  [typesConfig.ADMIN_SCHOOL_CALENDAR_MODULE.id]: "school-calendar",
  [typesConfig.ADMIN_SETTINGS_MODULE.id]: "settings",
  [typesConfig.ASSES_LEARNING_MODULE.id]: "assessmentEvaluation",
  [typesConfig.TEACHER_POLICIES_MODULE.id]: "teacherpolicies",
  [typesConfig.TEACHER_NOTIFICATION_SETTINGS_MODULE.id]:
    "notification-settings",
  [typesConfig.MANAGE_FAMILY_MODULE.id]: "manage-family",
  [typesConfig.ATL_MODULE.id]: "planningInsights/atls",
  [typesConfig.CONCEPT_INSIGHT_MODULE.id]: "planningInsights/keyConcepts",
  [typesConfig.THEME_DESCRIPTOR_MODULE.id]: "planningInsights/themeFocusAreas",
  [typesConfig.LPA_INSIGHT_MODULE.id]: "planningInsights/learnerProfile",
  [typesConfig.SUBJECT_INSIGHT_MODULE.id]: "planningInsights/subjects",
  [typesConfig.POI_MODULE.id]: "planningInsights/poi",
  [typesConfig.COURSE.id]: "unitPlans",
  [typesConfig.CLASS_SETTINGS_MODULE.id]: "settings",
};

export const mapTypeToEndPoint = ({ type }) => {
  return MAP_END_POINT[type] || "";
};

const IconComponent = props => {
  const { icon, color } = props;

  return (
    <div style={{ backgroundColor: color, ...commonStyle.iconStyle }}>
      {icon}
    </div>
  );
};

const getFilterData = ({ state, searchText = "", module }) => {
  const academicYearSelectedId = _.get(state, "academicYearSelected.id", {});

  const curriculumType = state.currentCurriculumProgramType || "IB_PYP";

  const curriculumPrograms = state.organizationCurriculumPrograms;

  const currentCurriculumProgramId = _.get(
    _.find(curriculumPrograms, {
      type: curriculumType,
    }),
    "id",
    null
  );

  const platformSearchEntities = module ? [module] : [];

  return {
    searchText,
    platformSearchEntities,
    maxCountPerType: 10,
    scope: "PLATFORM",
    academicYear: academicYearSelectedId,
    curriculum: currentCurriculumProgramId,
    metadata: {},
  };
};

export const getFilterDataMemoized = _.memoize(
  params => getFilterData(params),
  params => JSON.stringify(params)
);

export const parseResult = ({ result, t }) => {
  const typesConfig = getDynamicTypesConfig();

  const parsedData = _.map(result, data => {
    let metadata = _.get(data, "metadata", {});
    const imageUrl = _.get(data, "metadata.imageUrl", "");

    const type = data.type;

    const isPath = typesConfig[type].isPath;

    const order = _.get(data, "order", 1);

    const endPoint = mapTypeToEndPoint({ type });
    const routeType = decideRoute({ type });

    const title = _.get(data, "metadata.name", data.title);

    metadata = {
      ...metadata,
      endPoint,
      routeType,
    };

    const subTitle = HOME_ROOT_ROUTES.includes(type)
      ? ""
      : isPath
      ? createPath({ type, t, metadata })
      : `${t(typesConfig[type].subTitle)}`;

    return {
      id: data.id,
      type,
      title,
      subTitle,
      category: t(typesConfig[type].subTitle),
      order,
      imageUrl,
      metadata,
    };
  });

  return parsedData;
};

/**
 * memoization for utils function
 */
export const getParseResultMemoized = _.memoize(
  params => parseResult(params),
  params => JSON.stringify(params)
);

export const getCoursesBySearchTermMemoized = _.memoize(
  params => getCoursesBySearchTerm(params),
  params => JSON.stringify(params)
);

export const getModuleTypeBySearchTermMemoized = _.memoize(
  params => getModuleTypeBySearchTerm(params),
  params => JSON.stringify(params)
);

export const getCourseBasedModuleMemoized = _.memoize(
  params => getCourseBasedModule(params),
  params => JSON.stringify(params)
);
