import PropTypes from "prop-types";
import React from "react";

const CreateAssessmentSvg = props => {
  const { fill, fill1, width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.3415 12.5H71.875C80.5044 12.5 87.5 19.4956 87.5 28.125V78.125C87.5 86.7544 80.5044 93.75 71.875 93.75H28.125C19.4956 93.75 12.5 86.7544 12.5 78.125V28.125C12.5 19.4956 19.4956 12.5 28.125 12.5H28.6585C29.9454 8.85877 33.4181 6.25 37.5 6.25H62.5C66.5819 6.25 70.0546 8.85877 71.3415 12.5ZM34.375 15.625C34.375 13.8991 35.7741 12.5 37.5 12.5H62.5C64.2259 12.5 65.625 13.8991 65.625 15.625C65.625 17.3509 64.2259 18.75 62.5 18.75H37.5C35.7741 18.75 34.375 17.3509 34.375 15.625Z"
        fill={fill}
        // fill="#008392"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.125 43.75C53.125 42.0241 51.7259 40.625 50 40.625C48.2741 40.625 46.875 42.0241 46.875 43.75V53.125H37.5C35.7741 53.125 34.375 54.5241 34.375 56.25C34.375 57.9759 35.7741 59.375 37.5 59.375H46.875V68.75C46.875 70.4759 48.2741 71.875 50 71.875C51.7259 71.875 53.125 70.4759 53.125 68.75V59.375H62.5C64.2259 59.375 65.625 57.9759 65.625 56.25C65.625 54.5241 64.2259 53.125 62.5 53.125H53.125V43.75Z"
        fill={fill1}
      />
    </svg>
  );
};

CreateAssessmentSvg.defaultProps = {
  height: 24,
  width: 24,
};

CreateAssessmentSvg.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
};

export default CreateAssessmentSvg;
