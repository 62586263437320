import React from "react";
import classes from "./MultiSelectComponent.scss";
import PropTypes from "prop-types";
import { EmptyField, LinkWithTooltip } from "UIComponents";
import { Tick, InfoIcon } from "SvgComponents";
import update from "immutability-helper";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";
import { colors } from "Constants";

const MULTI_SELECT_COMPONENT_V2_TICK_DIMENSION = 17.5;

class MultiSelectComponent extends UIBaseComponent {
  isValidValue = value => {
    return !!value && value.length > 0;
  };

  onClickItem = params => {
    const {
      isGroupedOptions,
      options,
      minimumSelectedOptionsCount,
      onMinimumSelectedOptionsRemove,
    } = this.props;
    let selectedFocusAreas = this.props.value ? this.props.value : [];

    if (this.props.multi) {
      switch (params["operation"]) {
        case "add":
          selectedFocusAreas = update(selectedFocusAreas, {
            $push: [params.value],
          });
          break;
        case "remove": {
          if (minimumSelectedOptionsCount) {
            if (_.size(selectedFocusAreas) == minimumSelectedOptionsCount) {
              onMinimumSelectedOptionsRemove &&
                onMinimumSelectedOptionsRemove();
              break;
            }
          }
          const index = _.indexOf(this.props.value, params["value"]);
          selectedFocusAreas = update(selectedFocusAreas, {
            $splice: [[index, 1]],
          });
          break;
        }
      }
    } else {
      switch (params["operation"]) {
        case "add":
          selectedFocusAreas = [params.value];
          break;
        case "remove":
          selectedFocusAreas = [];
          break;
      }
    }

    if (selectedFocusAreas != this.props.value) {
      this.updateValue(selectedFocusAreas, {
        nodes: !isGroupedOptions
          ? options
          : _.reduce(
              options,
              (result, opt) => {
                result = _.concat(result, opt.children);
                return result;
              },
              []
            ),
      });
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (_.xorBy(this.props.options, nextProps.options, "value").length > 0) {
      const updatedValue = _.filter(
        nextProps.value,
        val => _.findIndex(nextProps.options, { value: val }) != -1
      );

      this.updateValue(updatedValue, { nodes: this.props.options });
    }
  };

  updateNodesRef = ({ ref, id }) => {
    const { updateNodesRef } = this.props;
    if (updateNodesRef) {
      updateNodesRef({ ref, id });
    }
  };

  getSelectList = () => {
    const {
      isGroupedOptions,
      options,
      listContainerStyle,
      groupedLabelStyle,
      groupedOptionsContainerStyle,
      groupedHeaderStyle,
      fieldTypeConfig,
      isMultiSelectComponentV2,
    } = this.props;

    const useListStyle = _.get(fieldTypeConfig, "useListStyle", "flex");

    const listContainer = classNames(
      { [classes.fieldCompFlex]: useListStyle === "flex" },
      {
        [classes.fieldCompGrid]:
          useListStyle === "grid" || isMultiSelectComponentV2,
      },
      { [classes.listContainerV2]: isMultiSelectComponentV2 }
    );

    const groupedOptionsContainer = classNames(
      { [classes.groupedOptionsContainer]: true },
      { [classes.groupedOptionsContainerV2]: isMultiSelectComponentV2 }
    );

    const groupedHeader = classNames(
      { [classes.groupedHeader]: true },
      { [classes.groupedHeaderV2]: isMultiSelectComponentV2 }
    );

    if (!isGroupedOptions) {
      //Iterate over flat options
      return this.getFlatOptionList({ options });
    } else {
      //Iterate over groups (Lens)
      return (
        <div
          className={groupedOptionsContainer}
          style={groupedOptionsContainerStyle}
        >
          {_.map(options, ({ children, id, label, showInfo }) => {
            return (
              <div
                key={id}
                className={classes.groupedOption}
                ref={ref => this.updateNodesRef({ ref, id })}
              >
                <div className={groupedHeader} style={groupedHeaderStyle}>
                  <div
                    className={classes.groupedLabel}
                    style={groupedLabelStyle}
                  >
                    {label}
                  </div>
                </div>

                <div className={listContainer} style={listContainerStyle}>
                  {this.getFlatOptionList({ options: children })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  getFlatOptionList = ({ options }) => {
    const {
      unSelectedCompStyle,
      tickDimension,
      iconStyle,
      textContainerStyle,
      showSubtextUnderInfo,
      fieldTypeConfig,
      selectedTickStyle,
      activeTickColor,
      tickContainerStyle,
      isInputListType,
      textItemStyle,
      isMultiSelectComponentV2,
    } = this.props;

    if (!_.isEmpty(options)) {
      return _.map(options, (itemObject, index) => {
        return (
          <ListItem
            listItemStyle={this.props.listItemStyle}
            item={itemObject}
            key={itemObject.value ? itemObject.value : itemObject.id}
            value={itemObject.value ? itemObject.value : itemObject.id}
            isSelected={_.includes(
              this.props.value,
              itemObject.value ? itemObject.value : itemObject.id
            )}
            onClickItem={this.onClickItem}
            unSelectedCompStyle={unSelectedCompStyle && unSelectedCompStyle}
            tickDimension={
              tickDimension
                ? tickDimension
                : isMultiSelectComponentV2
                ? MULTI_SELECT_COMPONENT_V2_TICK_DIMENSION
                : tickDimension
            }
            error={this.state.error}
            iconStyle={iconStyle && iconStyle}
            textContainerStyle={textContainerStyle}
            showSubtextUnderInfo={showSubtextUnderInfo}
            fieldTypeConfig={fieldTypeConfig}
            selectedTickStyle={selectedTickStyle}
            activeTickColor={activeTickColor}
            tickContainerStyle={tickContainerStyle}
            textItemStyle={textItemStyle}
            isMultiSelectComponentV2={isMultiSelectComponentV2}
          />
        );
      });
    } else {
      return <EmptyField title={this.props.emptyText} />;
    }
  };

  renderEdit = () => {
    const focusAreaListComponent = this.getSelectList(this.props.options);
    const {
      listContainerStyle,
      fieldTypeConfig,
      isMultiSelectComponentV2,
      isGroupedOptions,
    } = this.props;
    const useListStyle = _.get(fieldTypeConfig, "useListStyle", "flex");

    const listContainer = classNames(
      { [classes.fieldCompFlex]: useListStyle === "flex" },
      {
        [classes.fieldCompGrid]:
          useListStyle === "grid" || isMultiSelectComponentV2,
      },
      { [classes.listContainerV2]: isMultiSelectComponentV2 }
    );

    return (
      <div className={classes.componentContainer}>
        <div className={listContainer} style={listContainerStyle}>
          {focusAreaListComponent}
        </div>
      </div>
    );
  };
}

const ListItem = ({
  item,
  value,
  isSelected,
  onClickItem,
  listItemStyle,
  textStyle,
  error,
  unSelectedCompStyle,
  tickDimension,
  iconStyle,
  textContainerStyle,
  showSubtextUnderInfo,
  fieldTypeConfig,
  selectedTickStyle,
  activeTickColor,
  tickContainerStyle,
  textItemStyle,
  isMultiSelectComponentV2,
}) => {
  // possible values (top, center)
  const tickPostion = _.get(fieldTypeConfig, "tickPosition", "top");
  const params = {
    value: value,
    operation: isSelected ? "remove" : "add",
  };
  const listItemContainerStyle = classNames(
    { [classes.listItemCompActive]: isSelected },
    { [classes.listItemComp]: !isSelected },
    { [classes.listItemContainerStyleV2]: isMultiSelectComponentV2 },
    { [classes.error]: !!error }
  );
  const textClass = classNames(
    { [classes.titleTextComp]: true },
    { [classes.titleTextBold]: true },
    { [classes.titleTextDemiBold]: !!item.icon },
    { [classes.titleTextV2]: isMultiSelectComponentV2 }
  );

  const tickContainerClass = classNames(
    { [classes.tickAndHelperComp]: true },
    { [classes.tickTop]: tickPostion === "top" },
    { [classes.tickCenter]: tickPostion === "center" },
    { [classes.tickTopV2]: isMultiSelectComponentV2 && tickPostion === "top" }
  );

  const unSelectedCompClass = classNames({
    [classes.unSelectedComp]: isMultiSelectComponentV2,
  });

  const selectedTickCompClass = classNames(
    { [classes.selectedTickComp]: isSelected },
    { [classes.selectedTickCompV2]: isMultiSelectComponentV2 }
  );

  return (
    <div
      className={listItemContainerStyle}
      style={listItemStyle}
      onClick={e => {
        e.stopPropagation();
        onClickItem(params);
      }}
    >
      <div className={tickContainerClass} style={tickContainerStyle}>
        {isSelected ? (
          <div className={selectedTickCompClass} style={selectedTickStyle}>
            {tickDimension ? (
              <Tick
                width={tickDimension}
                height={tickDimension}
                fillBackground={activeTickColor && activeTickColor}
              />
            ) : (
              <Tick fillBackground={activeTickColor && activeTickColor} />
            )}
          </div>
        ) : (
          (unSelectedCompStyle || isMultiSelectComponentV2) && (
            <div
              className={unSelectedCompClass}
              style={unSelectedCompStyle}
            ></div>
          )
        )}
      </div>
      <div className={textClass} style={textItemStyle}>
        {item.icon ? (
          <React.Fragment>
            <div
              style={{ ...iconStyle, backgroundImage: `url(${item.icon})` }}
            />
            <div style={textContainerStyle}> {item.label} </div>
          </React.Fragment>
        ) : (
          item.label
        )}
      </div>

      {item.subText ? (
        showSubtextUnderInfo ? (
          <LinkWithTooltip
            tooltip={item.subText}
            placement={"bottom"}
            trigger={["hover"]}
            tooltipContainerStyle={{ width: 284 }}
          >
            <div className={classes.infoIcon}>
              <InfoIcon fill={colors.gray48} width={14} height={14} />
            </div>
          </LinkWithTooltip>
        ) : (
          <div className={classes.subText} style={textStyle}>
            {item.subText}
          </div>
        )
      ) : null}
    </div>
  );
};

/*
If isGroupedOptions is true then options will be look like this:
[
  { id: "1", label: "Group 1", children: [] },
  { id: "1", label: "Group 1", children: [] }
];

If isGroupedOptions is false then options will be look like this;
[
  { value: "1", label: "Group 1" },
  { value: "1", label: "Group 1"}
];
*/

MultiSelectComponent.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
  listItemStyle: PropTypes.object,
  multi: PropTypes.bool,
  isGroupedOptions: PropTypes.bool, //To handle grouped view (MYP Key Concepts),
  showSubtextUnderInfo: PropTypes.bool, //To show sub text under info icon
  selectedTickStyle: PropTypes.object,
};
MultiSelectComponent.defaultProps = {
  ...UIBaseComponent.defaultProps,
  options: [],
  multi: true,
  listItemStyle: {},
  showSubtextUnderInfo: false,
  isGroupedOptions: false,
  selectedTickStyle: {},
};

export default MultiSelectComponent;
