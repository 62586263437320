import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import ScopeAndSequence from "Course/routes/ScopeAndSequence";
import TeacherSetup from "TeacherSetup";
import Courses from "./routes/Courses";
import Planathon from "./routes/Planathon";
import PlanathonUnitPlans from "./routes/PlanathonUnitPlans";
import ClassRoomTodos from "./routes/ClassRoomTodos";
import Projects from "./routes/Projects";
import { CircularRoute } from "../Administrator/routes";
import ParentJournal from "./routes/ParentJournal";
import NotificationSettings from "./routes/NotificationSettings";
import FamilyConversations from "FamilyConversations";
import ParentProgressReport from "./routes/ParentProgressReport";
import ParentSettings from "./routes/ParentSettings";
import { FamilyPortalSchoolPolicy } from "OrganizationResources";
import ProgressReportSets from "./routes/ProgressReportSets";
import ProductUpdates from "./routes/ProductUpdates";
import ToddleAcademy from "./routes/ToddleAcademy";
import Todos from "./routes/Todos";
import StudentProgressSummary from "./routes/StudentProgressSummary";
import ManageUnitPublishing from "./routes/ManageUnitPublishing";
import SubjectCenter from "./routes/SubjectCenter";
import ContentSkills from "./routes/ContentSkills";
import Timetable from "./routes/Timetable";
import AttendanceSummary from "./routes/AttendanceSummary";
import ProjectGroupTypes from "./routes/ProjectGroupTypes";

import SnP_v2 from "Administrator/routes/SnP_v2";
import AdminEducatorCenter from "Administrator/routes/AdminEducatorCenter";
import CurriculumProgram from "Administrator/routes/CurriculumProgram";

import { getRelativePath } from "Utils";

export default store => ({
  path: ":curriculumProgramId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("Teacher/modules/TeacherModule").default;
            injectReducer(store, { key: "teacher", reducer });
            const circularModule = require("Circulars/modules/CircularsModule");
            injectReducer(store, {
              key: circularModule.NAME,
              reducer: circularModule.default,
            });

            const classRoomReducer = require("ClassRoom/modules/ClassRoomModule")
              .default;

            const adminReducer = require("Administrator/modules/AdministratorModule");
            injectReducer(store, {
              key: "classRoom",
              reducer: classRoomReducer,
            });

            injectReducer(store, {
              key: adminReducer.NAME,
              reducer: adminReducer.default,
            });

            cb(component);
          },
          err => {
            reject(err);
          },
          "teacher-course"
        );
      })
  ),

  childRoutes: [
    Courses(store),
    ClassRoomTodos(store),
    ScopeAndSequence(store),
    TeacherSetup(store),
    Planathon(store),
    PlanathonUnitPlans(store),
    CircularRoute(store),
    ParentJournal(store),
    Projects(store),
    ProjectGroupTypes(store),
    NotificationSettings(store),
    FamilyConversations(store),
    ParentProgressReport(store),
    ParentSettings(store),
    FamilyPortalSchoolPolicy(store),
    StudentProgressSummary(store),
    ProgressReportSets(store),
    SnP_v2(store),
    ProductUpdates(store),
    ToddleAcademy(store),
    Todos(store),
    ManageUnitPublishing(store),
    SubjectCenter(store),
    ContentSkills(store),
    Timetable(store),
    AttendanceSummary(store),
    AdminEducatorCenter(store),
    CurriculumProgram(store),
  ],
});
