import React from "react";
import PropTypes from "prop-types";

const IndiAndSocIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <circle cx="16" cy="16" r="11.5" stroke="#F2768E" />
      <circle cx="16" cy="16" r="3.5" fill="white" stroke="#F2768E" />
      <circle cx="16" cy="5" r="2.5" fill="#FCCED6" stroke="#F2768E" />
      <circle
        cx="27"
        cy="16"
        r="2.5"
        transform="rotate(90 27 16)"
        fill="#FCCED6"
        stroke="#F2768E"
      />
      <circle cx="16" cy="27" r="2.5" fill="#FCCED6" stroke="#F2768E" />
      <circle
        cx="5"
        cy="16"
        r="2.5"
        transform="rotate(90 5 16)"
        fill="#FCCED6"
        stroke="#F2768E"
      />
    </svg>
  );
};

IndiAndSocIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

IndiAndSocIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default IndiAndSocIcon;
