import React from "react";
import PropTypes from "prop-types";
import classes from "./Attachment.scss";
import AttachmentList from "./components/AttachmentList";

import UIBaseComponent from "UIComponents/UIBaseComponent";

class Attachment extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = { localAttachments: [] };
  }

  addLocalAttachment = attachment => {
    this.setState({
      localAttachments: [...this.state.localAttachments, attachment],
    });
  };

  addAttachment = async (attachments, uploadId) => {
    const { addAttachment } = this.props;
    const localAttachments = _.filter(
      this.state.localAttachments,
      attachment => attachment.id != uploadId
    );

    await addAttachment({ ...attachments[0], id: uploadId });
    this.setState({ localAttachments });
  };

  deleteAttachment = attachmentId => {
    const { deleteAttachment } = this.props;
    deleteAttachment(attachmentId);
  };

  renderFeed = () => {
    const { value, mode } = this.props;
    const { localAttachments } = this.state;

    return (
      <AttachmentList
        attachments={[...value, ...localAttachments]}
        addAttachment={this.addAttachment}
        deleteAttachment={this.deleteAttachment}
        addLocalAttachment={this.addLocalAttachment}
        mode={mode}
      />
    );
  };

  renderView = () => {
    return <div className={classes.container}>{this.renderFeed()}</div>;
  };

  renderEdit = () => {
    return <div className={classes.container}>{this.renderFeed()}</div>;
  };
}

Attachment.propTypes = {
  ...UIBaseComponent.propTypes,
  addAttachment: PropTypes.func,
  deleteAttachment: PropTypes.func,
  value: PropTypes.array,
};

export default Attachment;
