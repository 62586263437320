import PropTypes from "prop-types";
import React from "react";

const UnitIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9305 2.94666C12.4422 2.4584 11.8091 2.45188 11.6475 2.45188C11.3089 2.45188 11.0345 2.17742 11.0345 1.83886C11.0345 1.50029 11.3089 1.22583 11.6475 1.22583L11.6521 1.22583C11.8668 1.22578 12.918 1.22555 13.767 2.04971C14.4188 2.55864 14.7126 3.35781 14.7126 4.10706V13.1185C14.7126 14.7237 13.2927 15.9998 11.6475 15.9998H3.06513C1.4311 15.9998 0 14.7953 0 13.1185V4.10706C0 2.43029 1.4311 1.22583 3.06513 1.22583C3.4037 1.22583 3.67816 1.50029 3.67816 1.83886C3.67816 2.17742 3.4037 2.45188 3.06513 2.45188C2.00185 2.45188 1.22605 3.20911 1.22605 4.10706V13.1185C1.22605 14.0165 2.00185 14.7737 3.06513 14.7737H11.6475C12.6996 14.7737 13.4866 13.9655 13.4866 13.1185V4.10706C13.4866 3.6436 13.3005 3.23189 12.9962 3.00361C12.973 2.9862 12.951 2.96717 12.9305 2.94666Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06445 1.83908C3.06445 0.826186 3.89064 0 4.90353 0H9.80775C10.8206 0 11.6468 0.826186 11.6468 1.83908C11.6468 2.85198 10.8206 3.67816 9.80775 3.67816H4.90353C3.89064 3.67816 3.06445 2.85198 3.06445 1.83908ZM4.90353 1.22605C4.56777 1.22605 4.29051 1.50332 4.29051 1.83908C4.29051 2.17484 4.56777 2.45211 4.90353 2.45211H9.80775C10.1435 2.45211 10.4208 2.17484 10.4208 1.83908C10.4208 1.50332 10.1435 1.22605 9.80775 1.22605H4.90353Z"
        fill={props.fill}
      />
    </svg>
  );
};

UnitIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

UnitIcon.defaultProps = {
  height: 16,
  width: 15,
};

export default UnitIcon;
