import { styles } from "Tooling/commonStyles";
import {
  PLAN_TYPE_360,
  PLAN_TYPE_360_PILOT,
  PLAN_TYPE_DEMO,
  PLAN_TYPE_FREE,
  PLAN_TYPE_PRO,
  PLAN_TYPE_PRO_PILOT,
} from "Constants/stringConstants";

const {
  feedListItemStyle,
  feedListJustifyStartStyle,
  feedListJustifyCenterStyle,
} = styles;

export const CURRICULUM_PLANS_OPTIONS = [
  { key: "", label: "All" },
  { key: "6", label: "Demo" },
  { key: "1", label: "Free" },
  { key: "2", label: "Pro" },
  { key: "4", label: "360" },
  { key: "3", label: "Pro Pilot" },
  { key: "5", label: "360 Pilot" },
];

export const ONBOARDED_SCHOOL_PAGE_COLUMNS = [
  {
    id: "SCHOOL_DETAILS",
    label: "School name",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyStartStyle,
    },
  },
  {
    id: "TODDLE_BUDDIES",
    label: "Toddle buddies",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyCenterStyle,
    },
  },
  {
    id: PLAN_TYPE_DEMO,
    label: "Demo",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: PLAN_TYPE_FREE,
    label: "Free plan",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: PLAN_TYPE_PRO,
    label: "Pro plan",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: PLAN_TYPE_PRO_PILOT,
    label: "Pro pilot",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: PLAN_TYPE_360_PILOT,
    label: "360 pilot",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: PLAN_TYPE_360,
    label: "360 plan",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: "ACTIONS",
    label: "Actions",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
];

export const ONBOARDING_SCHOOL_PAGE_COLUMNS = [
  {
    id: "SCHOOL_DETAILS",
    label: "School name",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyStartStyle,
    },
  },
  {
    id: "TODDLE_BUDDIES",
    label: "Toddle buddies",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyCenterStyle,
    },
  },
  {
    id: "SETUP_COMPLETE",
    label: "Setup complete",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: "NOT_STARTED",
    label: "Not started",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: "IN_PROGRESS",
    label: "In progress",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: "QC_INITIATED",
    label: "Review requested",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
  {
    id: "ACTIONS",
    label: "Actions",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
];

export const TRANSITION_SCHOOL_PAGE_COLUMNS = [
  {
    id: "SCHOOL_DETAILS",
    label: "School name",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyStartStyle,
    },
  },
  {
    id: "TODDLE_BUDDIES",
    label: "Toddle buddies",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyCenterStyle,
    },
  },
  {
    id: "PROGRAMS",
    label: "Programs",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyCenterStyle,
    },
  },
  {
    id: "STATUS",
    label: "Status",
    type: "CUSTOM",
    style: {
      ...feedListItemStyle,
      ...feedListJustifyStartStyle,
    },
  },
  {
    id: "ACTIONS",
    label: "Actions",
    type: "CUSTOM",
    style: { ...feedListItemStyle, ...feedListJustifyCenterStyle },
  },
];

export const getOrganizationBuddies = ({ curriculumPrograms }) => {
  return _.map(curriculumPrograms, ({ id, type, buddy }) => {
    return { id, type, buddy };
  });
};

export const getCurriculumProgramsGroupedByOnboardingStatus = ({
  curriculumPrograms,
}) => {
  return _.groupBy(curriculumPrograms, curriculumProgram => {
    return _.get(curriculumProgram, "onboardingStatus", "");
  });
};

export const getCurriculumProgramsGroupedByPlan = ({ curriculumPrograms }) => {
  return _.groupBy(curriculumPrograms, curriculumProgram => {
    return _.get(curriculumProgram, "subscriptionPlan.type", "");
  });
};

export const ALLOWED_TOOLS_BY_REGION = {
  ["TOOL_EU_WEST_1"]: ["ORGANIZATIONS", "MANAGE-SCHOOLS", "USERS"],
  ["TOOL_US_EAST_1"]: ["ORGANIZATIONS", "MANAGE-SCHOOLS", "USERS"],
  ["COMMON"]: [
    "TEMPLATE-EDITOR",
    "INTERNAL-TOOLS",
    "ORGANIZATION-APPROVAL",
    "STANDARDS",
    "PR-TEMPLATE-EDITOR",
  ],
};
