// export {default} from "./EstablishDeadlines";
import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";

const {
  establishDeadlines: { path },
} = routes;

const EstablishDeadlinesRoute = store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise(resolve => {
          require.ensure(
            [],
            require => {
              const component = require("./EstablishDeadlines").default;
              resolve(component);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [],
  };
};
export default EstablishDeadlinesRoute;
