import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "react-apollo";
import { resetPasswordDetails } from "Administrator/modules/CommonModule";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  InputTextField,
  UIButton,
  UIModal,
  I18nHOC,
  FullScreenLoader,
} from "UIComponents";
import classes from "./ChangePassword.scss";
import { Checkbox } from "@toddle-design/web";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: {
        id: this.props.teacherId,
        password: "",
        confirm_password: "",
        is_password_updated: false,
        isFullScreenLoading: false,
      },
      showDialog: false,
      isNotValid: false,
    };
    this.inputRefs = {};
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
      isNotValid: false,
    });
  };

  close = () => {
    this.props.closeModal();
  };

  onSave = async () => {
    const errorCount = this.isValid();
    if (errorCount > 0) {
      return;
    }

    this.setState({ isFullScreenLoading: true });
    const isSuccess = await this.props.resetPasswordDetails({
      ...this.state.itemDetails,
    });
    this.setState({ isFullScreenLoading: false });
    if (isSuccess) {
      this.close();
    }
  };

  confirmPasswordValidation = value => {
    const { itemDetails: { password } = {} } = this.state;

    if (!_.isEqual(password, value)) {
      return "Password and confirm password do not match";
    }
    return "";
  };

  passwordValidation = value => {
    if (value.length < 8) {
      return "Password should be atleast 8 characters in length";
    }
    return "";
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  toggleCheckBox = () => {
    this.setState({
      itemDetails: {
        ...this.state.itemDetails,
        is_password_updated: !this.state.itemDetails.is_password_updated,
      },
    });
  };

  updateOrgObject = param => {
    const obj = { ...this.state.itemDetails };

    if (_.has(param, "confirm_password")) {
      obj.confirm_password = param.confirm_password;
    }
    if (_.has(param, "password")) {
      obj.password = param.password;
    }
    this.setState({ itemDetails: obj });
  };

  render() {
    const { t } = this.props;
    const {
      password,
      confirm_password,
      is_password_updated,
    } = this.state.itemDetails;
    const { showDialog, isNotValid, isFullScreenLoading } = this.state;
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={this.close}
      >
        <React.Fragment>
          <div className={classes.editStep}>
            <div className={classes.modalHeader}>
              {t("common:reset_with_label", { label: t("common:password") })}
            </div>
          </div>

          <div className={classes.editStep2}>
            <div className={classes.horizontalRowInput}>
              <div className={classes.horizontalInputElement}>
                <InputTextField
                  label={t("common:password")}
                  error="Enter password"
                  name={"password"}
                  value={password}
                  updateInputField={this.updateOrgObject}
                  placeholder="Enter Password"
                  inputStyle={classes.titleInput}
                  customValidation={this.passwordValidation}
                  ref={ref => (this.inputRefs["password"] = ref)}
                  showErrorText={true}
                  type={"password"}
                />
              </div>

              <div className={classes.horizontalInputElement}>
                <InputTextField
                  label={t("schoolSetup:confirm_password")}
                  error={"Enter confirm password"}
                  name={"confirm_password"}
                  value={confirm_password}
                  updateInputField={this.updateOrgObject}
                  placeholder="Confirm your Password"
                  inputStyle={classes.titleInput}
                  customValidation={this.confirmPasswordValidation}
                  ref={ref => (this.inputRefs["confirm_password"] = ref)}
                  showErrorText={true}
                  type={"password"}
                />
              </div>
            </div>

            <div className={classes.horizontalRow}>
              <div className={classes.horizontalInputElement}>
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    size={"xxx-small"}
                    isChecked={is_password_updated}
                    onChange={this.toggleCheckBox}
                  >
                    {t("common:change_password_requiured")}
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className={classes.modalFooter}>
              <UIButton size="sm" color="grey" onClick={this.close}>
                {t("common:cancel")}
              </UIButton>
              <div className={classes.lastButton}>
                <UIButton color="pink" size="sm" onClick={this.onSave}>
                  {t("common:save")}
                </UIButton>
              </div>
            </div>
          </div>
        </React.Fragment>
        {!!isFullScreenLoading && <FullScreenLoader />}
      </UIModal>
    );
  }
}

const mapActionCreators = {
  resetPasswordDetails,
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: ["schoolSetup", "common"] }),
  connect(null, mapActionCreators)
)(ChangePassword);
