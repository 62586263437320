import React, { useState } from "react";
import classes from "./ActionButtons.scss";
import { I18nHOC, DialogueBox, LinkWithTooltip } from "UIComponents";
import {
  LikeIcon,
  BookmarkIconSvg,
  CommentIcon,
  LikeFillIcon,
  BookmarkFillIconSvg,
  CommunityShareIcon,
} from "SvgComponents";
import { connect } from "react-redux";
import { compose } from "react-apollo";

import { colors } from "Constants";
import Popover from "react-tiny-popover";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ShareModal, LikeModal } from "AppComponents/Community";
import {
  updateFollow,
  checkCommunityActiveStatusAndGoToOnboarding,
  updateLike,
  isUserAuthenticatedForAction,
} from "Community/modules/CommunityModule";
import { goToRelativeRoute } from "modules/Services";

import * as EventTracker from "lib/eventTracker";

const LIKED_USERS_COUNT = 10;

let modalParams = {};

const ACTIONS = {
  COMMENT: {
    value: "COMMENT",
    label: "common:comment",
    showCount: true,
    getIcon: ({ fillColor, width, height } = {}) => {
      return (
        <CommentIcon
          fill={fillColor}
          width={width || 20}
          height={height || 20}
          showEmpty={true}
        />
      );
    },
    eventActionName: "Clicked comment",
    isWholeButtonClick: true,
  },
  SAVE: {
    value: "SAVE",
    label: "common:bookmark",
    showCount: false,
    getIcon: ({ fillColor, isFillIcon, width, height } = {}) => {
      return isFillIcon ? (
        <BookmarkFillIconSvg
          fill={fillColor}
          width={width || 14}
          height={height || 16}
        />
      ) : (
        <BookmarkIconSvg
          fill={fillColor}
          width={width || 14}
          height={height || 16}
        />
      );
    },
    isWholeButtonClick: true,
  },
  LIKE: {
    value: "LIKE",
    label: "common:like_label",
    showCount: true,
    getIcon: ({ fillColor, isFillIcon, width, height } = {}) => {
      return isFillIcon ? (
        <LikeFillIcon
          fill={fillColor}
          width={width || 16}
          height={height || 14}
        />
      ) : (
        <LikeIcon fill={fillColor} width={width || 16} height={height || 14} />
      );
    },
    eventActionName: "Clicked like",
    isWholeButtonClick: false,
  },
  SHARE: {
    value: "SHARE",
    label: "common:share",
    showCount: false,
    getIcon: ({ fillColor, width, height } = {}) => {
      return (
        <CommunityShareIcon
          fill={fillColor}
          width={width || 16}
          height={height || 15}
        />
      );
    },
    isWholeButtonClick: true,
  },
};

const DIALOGS_INFO = {
  UNSAVE: {
    title: t => t(`common:remove`),
    message: t =>
      t("common:action_msg", {
        action: t("community:remove_from_bookmark"),
      }),
    confirmButtonText: "common:remove_agree",
    cancelButtonText: "common:agree_cancel",
    promptDetail: () => {},
  },
};

const getCount = ({ counts, actionName, communityComments, likes }) => {
  switch (actionName) {
    case "LIKE":
    case "LIKE_COUNT":
      return _.get(likes, "totalCount", 0);

    case "COMMENT":
      return _.get(communityComments, "totalCount", 0);
  }

  return _.get(counts, actionName, 0);
};

const getColor = ({ buttonType, actionName, isFillIcon, isOnHover }) => {
  let color = colors.black;
  if (
    (buttonType == "NORMAL_BUTTON" ||
      buttonType == "NORMAL_WITH_TEXT_BUTTON") &&
    !isFillIcon &&
    isOnHover
  ) {
    color = "#000000";
  } else if (buttonType == "SQUARE_BUTTON") {
    color = colors.white;
  } else if (isFillIcon && actionName == "LIKE") {
    color = colors.salmon60;
  } else if (isFillIcon && actionName == "SAVE") {
    color = colors.yellow50;
  } else if (
    buttonType == "NORMAL_WITH_TEXT_BUTTON" ||
    buttonType == "ROUND_BUTTON"
  ) {
    switch (actionName) {
      case "SHARE":
        color = colors.pink39;
        break;

      case "COMMENT":
        color = colors.blue29;
        break;

      case "LIKE":
        color = colors.salmon60;
        break;

      case "SAVE":
        color = colors.yellow50;
        break;
    }
  }

  return color;
};

const showFillIcon = ({ userId, actionName, savedBy, likes }) => {
  let isFillIcon = false;
  switch (actionName) {
    case "LIKE":
      isFillIcon = _.get(likes, "isByMe", false);
      break;

    case "SAVE":
      isFillIcon = _.find(savedBy, item => _.get(item, "id") == userId);
      break;
  }

  return isFillIcon;
};

const getActionModalStatus = ({ isFilled, type, queryVariables }) => {
  let modalType = "";
  let isForced = false;
  switch (type) {
    case "SAVE": {
      const fromBookmarkFeed = _.get(queryVariables, "feedType", "") == "SAVED";
      modalType = fromBookmarkFeed ? "UNSAVE" : "";
      isForced = !fromBookmarkFeed;
      break;
    }

    case "LIKE":
    case "COMMENT":
      modalType = type;
      isForced = true;
      break;
    default:
      modalType = type;
  }
  return { modalType, isForced };
};

const getActions = ({
  actionTypes,
  buttonType,
  entityRatings,
  userId,
  savedBy,
  queryVariables,
  communityComments,
  counts,
  likes,
  theme,
}) => {
  const actions = [];
  _.forEach(actionTypes, key => {
    const action = ACTIONS[key] || {};
    const { actionIconStyles } = theme || {};
    let { showCount } = action;

    const actionStyle = _.get(actionIconStyles, key, {});
    const {
      width,
      height,
      isOnHover,
      showCommentAlways,
      showCount: showCountTheme,
      showIcon: showIconTheme,
    } = actionStyle || {};

    showCount =
      typeof showCountTheme === "boolean" ? showCountTheme : showCount;

    let countValue = getCount({
      counts,
      actionName: key,
      entityRatings,
      communityComments,
      likes,
    });
    if (countValue == 0 && key == "COMMENT") {
      if (!showCommentAlways) {
        return;
      } else {
        showCount = false;
        countValue = 1;
      }
    }

    const isFilled = showFillIcon({
      entityRatings,
      userId,
      actionName: key,
      savedBy,
      likes,
    });

    const color = getColor({
      buttonType,
      actionName: key,
      isFillIcon: isFilled,
      isOnHover,
    });
    const actionModalStatus = getActionModalStatus({
      isFilled,
      type: key,
      queryVariables,
    });

    if (!_.isEmpty(action)) {
      actions.push({
        ...action,
        icon: action.getIcon({
          fillColor: color,
          isFillIcon: isFilled,
          width,
          height,
          buttonType,
        }),
        color,
        ...actionModalStatus,
        countValue,
        showIcon: typeof showIconTheme === "boolean" ? showIconTheme : true,
        showCount,
        isFilled,
      });
    }
  });
  return actions;
};

const onForceActionClick = async params => {
  const {
    type,
    updateFollow,
    entityType,
    entityId,
    queryVariables,
    goToRelativeRoute,
    checkCommunityActiveStatusAndGoToOnboarding,
    eventTarget,
    entityName,
    updateLike,
    onCommentClick,
    onLikeClick,
  } = params;

  if (checkCommunityActiveStatusAndGoToOnboarding) {
    const isActiveCommunity = await checkCommunityActiveStatusAndGoToOnboarding();
    if (!isActiveCommunity) {
      return;
    }
  }

  let eventActionName = "";

  switch (type) {
    case "SAVE": {
      const { isBookmarked } =
        (await updateFollow({
          entityType,
          entityId,
          queryVariables,
        })) || {};
      eventActionName = isBookmarked ? "Bookmarked" : "Removed from bookmarks";
      break;
    }
    case "COMMENT":
      if (onCommentClick) {
        onCommentClick();
      } else {
        goToRelativeRoute({
          route:
            entityType == "UNIT_PLAN"
              ? `unitpreview/${entityId}?section=comments`
              : `assessment/${entityId}/view?section=comments`,
        });
      }

      break;

    case "LIKE": {
      const { isLiked } =
        (await updateLike({
          entityType,
          entityId,
        })) || {};
      eventActionName = isLiked ? "Liked" : "Removed like";
      if (onLikeClick) onLikeClick(isLiked);
      break;
    }
  }

  if (eventActionName) {
    EventTracker.recordEvent({
      eventName: eventActionName,
      eventData: {
        entity_id: entityId,
        entity_type: entityType,
        source: "community",
        target: eventTarget,
        entity_name: entityName,
      },
    });
  }
};

const onModalActionClick = params => {
  const {
    updateModal,
    modalType,
    type,
    entityId,
    entityType,
    eventTarget,
    entityName,
  } = params;
  const { eventActionName } = ACTIONS[type] || {};
  updateModal({ type: modalType, params });

  if (eventActionName) {
    EventTracker.recordEvent({
      eventName: eventActionName,
      eventData: {
        entity_id: entityId,
        entity_type: entityType,
        source: "community",
        taget: eventTarget,
        entity_name: entityName,
      },
    });
  }
};

const onActionButtonClick = async params => {
  const {
    event,
    isForced,
    type,
    isUserAuthenticatedForAction,
    userLoggedIn,
  } = params;
  event.stopPropagation();

  const hasAccess = await isUserAuthenticatedForAction();
  if (!hasAccess) return;

  if (isForced) {
    onForceActionClick(params);
  } else {
    onModalActionClick(params);
  }
};

const TootlTipComponent = ({ type, likes, component, buttonType }) => {
  let tootlTipComponent = null;
  switch (type) {
    case "LIKE": {
      const totalLikeCount = _.get(likes, "totalCount", 0);
      const likedUsers = _.map(
        _.map(_.get(likes, "edges"), "node.user"),
        user => `${user.firstName} ${user.lastName}`
      );
      tootlTipComponent =
        totalLikeCount > 0 ? (
          <div>
            {_.map(_.slice(likedUsers, 0, LIKED_USERS_COUNT), item => (
              <div key={item}>{item}</div>
            ))}
            {totalLikeCount > LIKED_USERS_COUNT && (
              <div>{`and ${totalLikeCount - LIKED_USERS_COUNT} more`}</div>
            )}
          </div>
        ) : null;
      break;
    }
  }

  return tootlTipComponent ? (
    <LinkWithTooltip
      tooltip={tootlTipComponent}
      placement={"bottom"}
      href="#"
      id="tooltip-likes"
      dataHtml={true}
      alignConfig={buttonType == "ROUND_BUTTON" ? { offset: [0, 24] } : {}}
    >
      {component}
    </LinkWithTooltip>
  ) : (
    component
  );
};

const ActionButtons = React.memo(props => {
  const [currentModal, setCurrentModal] = useState("");
  const {
    entity,
    entityType,
    entityRatings,
    userId,
    t,
    updateModalStatus,
    buttonType,
    actionTypes,
    counts,
    savedBy,
    updateFollow,
    queryVariables,
    communityComments,
    goToRelativeRoute,
    checkCommunityActiveStatusAndGoToOnboarding,
    eventTarget,
    entityName,
    likes,
    updateLike,
    theme,
    onCommentClick,
    onLikeClick,
    userLoggedIn,
    customConfig = {},
    isUserAuthenticatedForAction,
  } = props;

  const updateModal = async ({ type, params } = {}) => {
    if (type == "SHARE") {
      const isActiveCommunity = await checkCommunityActiveStatusAndGoToOnboarding();
      if (!isActiveCommunity) {
        return;
      }
    }
    setCurrentModal(type);
    if (updateModalStatus) {
      updateModalStatus(!!type);
    }
    modalParams = _.cloneDeep(params);
  };

  const onDialogConfirmClick = () => {
    const { modalType } = modalParams || {};
    switch (modalType) {
      case "UNSAVE":
        onForceActionClick(modalParams);
        break;
    }
  };

  const OnToggleDialogueClick = () => {
    updateModal({ type: "", params: {} });
    modalParams = {};
  };

  const containerClass = classNames({
    [classes.container]: true,
    [classes.roundContainer]: buttonType == "ROUND_BUTTON",
    [classes.normalWithTextContainer]: buttonType == "NORMAL_WITH_TEXT_BUTTON",
    [classes.normalContainer]: buttonType == "NORMAL_BUTTON",
  });

  const buttonContainerClasses = classNames({
    [classes.button]: true,
    [classes.buttonContainer]: buttonType == "SQUARE_BUTTON",
    [classes.roundButtonContainer]: buttonType == "ROUND_BUTTON",
  });

  const countClasses = classNames({
    [classes.countText]: true,
    [classes.countNormalText]: buttonType == "NORMAL_BUTTON",
    [classes.countRoundText]: buttonType == "ROUND_BUTTON",
  });

  const textClasses = classNames(
    { [classes.titleText]: true },
    {
      [classes.normalWithTextTitleText]:
        buttonType == "NORMAL_WITH_TEXT_BUTTON",
    }
  );

  const actions = getActions({
    actionTypes,
    buttonType,
    entityRatings,
    userId,
    savedBy,
    queryVariables,
    communityComments,
    counts,
    likes,
    theme,
    entityType,
  });

  const { countStyle, textStyle, containerStyle } = theme || {};

  return (
    <div className={containerClass} style={containerStyle}>
      {_.map(actions, action => {
        let {
          showCount,
          value,
          color,
          icon,
          label,
          isForced,
          modalType,
          countValue,
          showIcon,
          isFilled,
          isWholeButtonClick,
        } = action;

        const buttonId = _.get(customConfig, [value, "id"], "");
        const isIconHoverAnimation =
          (buttonType == "NORMAL_BUTTON" ||
            buttonType == "NORMAL_WITH_TEXT_BUTTON") &&
          !isFilled;
        const iconClasses = classNames({
          [classes.iconContainer]: true,
          [classes.normalIconContainer]: isIconHoverAnimation,
          [classes.likeNormalIconContainer]:
            isIconHoverAnimation && value == "LIKE",
          [classes.shareNormalIconContainer]:
            isIconHoverAnimation && value == "SHARE",
          [classes.saveNormalIconContainer]:
            isIconHoverAnimation && value == "SAVE",
          [classes.commentNormalIconContainer]:
            isIconHoverAnimation && value == "COMMENT",
        });

        const onActionClick = ({ event, clickedFrom }) => {
          if (!userLoggedIn && clickedFrom === "COUNT") {
            return;
          }
          if (clickedFrom == "COUNT" && !isWholeButtonClick) {
            isForced = false;
          }
          onActionButtonClick({
            event,
            type: value,
            updateModal,
            entityType,
            entityId: entity,
            updateFollow,
            queryVariables,
            isForced,
            modalType,
            goToRelativeRoute,
            checkCommunityActiveStatusAndGoToOnboarding,
            eventTarget,
            entityName,
            updateLike,
            clickedFrom,
            onCommentClick,
            onLikeClick,
            userLoggedIn,
            isUserAuthenticatedForAction,
          });
        };

        const shouldClickOnWholeButton =
          isWholeButtonClick || buttonType == "ROUND_BUTTON";

        const iconComponent = showIcon ? (
          <div
            className={iconClasses}
            onClick={event => onActionClick({ event, clickedFrom: "ICON" })}
          >
            <span /> {icon}
          </div>
        ) : null;

        const textComponent =
          buttonType == "NORMAL_WITH_TEXT_BUTTON" ? (
            <div className={textClasses} style={textStyle}>
              {t(label, { count: countValue })}
            </div>
          ) : null;

        const countComponent = showCount ? (
          <div
            className={countClasses}
            style={{
              backgroundColor: buttonType == "ROUND_BUTTON" ? color : "unset",
              color:
                buttonType == "ROUND_BUTTON" ? colors.white : colors.gray31,
              ...(countStyle || {}),
            }}
          >
            {countValue}
          </div>
        ) : null;

        const countTextComponent = (
          <div
            className={classes.countTextContainer}
            onClick={event => onActionClick({ event, clickedFrom: "COUNT" })}
            style={{
              marginLeft:
                showIcon && buttonType == "NORMAL_WITH_TEXT_BUTTON"
                  ? "8px"
                  : "0px",
            }}
          >
            {countComponent}
            {textComponent}
          </div>
        );

        const tootlTipComponent = (
          <TootlTipComponent
            type={value}
            likes={likes}
            component={countTextComponent}
            buttonType={buttonType}
          />
        );

        const buttonComponent = (
          <div
            id={buttonId}
            className={buttonContainerClasses}
            key={value}
            onClick={
              shouldClickOnWholeButton
                ? event =>
                    onActionClick({
                      event,
                      clickedFrom: "COUNT",
                      target: "BUTTON",
                    })
                : null
            }
          >
            {iconComponent}
            {tootlTipComponent}
          </div>
        );

        return buttonComponent;
      })}

      {!!currentModal && DIALOGS_INFO[currentModal] && (
        <DialogueBox
          customModalContentClass={classes.dialogContent}
          modalTitle={DIALOGS_INFO[currentModal].title(t)}
          showModal={true}
          onClickButton2={onDialogConfirmClick}
          modalBody={DIALOGS_INFO[currentModal].message(t)}
          toggleDialogueBoxDisplay={OnToggleDialogueClick}
          footerContainerStyle={{ marginTop: "40px" }}
          button1={
            t(DIALOGS_INFO[currentModal].cancelButtonText) || t("common:cancel")
          }
          button2={t(DIALOGS_INFO[currentModal].confirmButtonText)}
        />
      )}
      {currentModal === "SHARE" && (
        <ShareModal
          entityId={entity}
          entityType={entityType}
          closeShareModal={e => {
            if (e) e.stopPropagation();
            updateModal({ type: "" });
          }}
          portalType={"COMMUNITY"}
        />
      )}

      {currentModal == "LIKE" && _.get(likes, "totalCount", 0) > 0 && (
        <LikeModal
          action={_.find(actions, ["value", "LIKE"])}
          updateModal={updateModal}
          entity={entity}
          entityType={entityType}
          entityRatings={entityRatings}
          userId={userId}
          currentModal={currentModal}
          eventTarget={eventTarget}
        />
      )}
    </div>
  );
});

ActionButtons.propTypes = {
  buttonType: PropTypes.oneOf([
    "ROUND_BUTTON",
    "SQUARE_BUTTON",
    "NORMAL_BUTTON",
    "NORMAL_WITH_TEXT_BUTTON",
  ]),
};

ActionButtons.defaultProps = {
  buttonType: "SQUARE_BUTTON",
  actionTypes: ["LIKE", "SAVE", "SHARE"],
  theme: {},
};

const mapStateToProps = (state, props) => {
  const userId = state.login.userInfo.id;
  return {
    userId,
    userLoggedIn: state.login.userLoggedIn,
  };
};

const mapActionCreators = {
  updateFollow,
  goToRelativeRoute,
  checkCommunityActiveStatusAndGoToOnboarding,
  updateLike,
  isUserAuthenticatedForAction,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common", "community"] })
)(ActionButtons);
