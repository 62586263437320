import PropTypes from "prop-types";
import React from "react";

const CheckTickOff = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24">
      <g
        id="Page-3"
        stroke="none"
        strokeWidth="1"
        fill={props.fill && props.fill}
        fillRule="evenodd"
      >
        <path
          d="M12,23.75 C5.51065419,23.75 0.25,18.4893458 0.25,12 C0.25,5.51065419 5.51065419,0.25 12,0.25 C18.4893458,0.25 23.75,5.51065419 23.75,12 C23.75,18.4893458 18.4893458,23.75 12,23.75 Z M12,22.25 C17.6609187,22.25 22.25,17.6609187 22.25,12 C22.25,6.33908131 17.6609187,1.75 12,1.75 C6.33908131,1.75 1.75,6.33908131 1.75,12 C1.75,17.6609187 6.33908131,22.25 12,22.25 Z"
          id="untick"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

CheckTickOff.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

CheckTickOff.defaultProps = {
  height: 48,
  width: 48,
};

export default CheckTickOff;
