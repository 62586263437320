import React from "react";
import classes from "./AttachmentCardV2.scss";
import AttachmentCard from "../AttachmentCard";
import AttachmentItemV2 from "../AttachmentItemV2";
import AttachmentItem from "../AttachmentItem";

const getShowVerticalCard = ({ attachment, cardType }) => {
  if (cardType == "verticalHorizontal") return true;
  else {
    const attachmentType = _.get(attachment, "type", "");
    if (attachmentType == "IMAGE" || attachmentType == "NOTE") return true;
    else if (attachmentType == "AUDIO") return false;
    else if (!_.isEmpty(_.get(attachment, "thumbUrl", ""))) return true;
    else return false;
  }
};

const getShowAttachmentItem = ({ attachment }) => {
  const attachmentType = _.get(attachment, "type", "");
  if (attachmentType == "IMAGE" || attachmentType == "NOTE") return false;
  else return true;
};

const getShowAttachmentItemMemoize = _.memoize(
  params => getShowAttachmentItem(params),
  params => JSON.stringify(params)
);

const getShowVerticalCardMemoize = _.memoize(
  params => getShowVerticalCard(params),
  params => JSON.stringify(params)
);

const AttachmentCardV2 = React.memo(
  ({
    attachment,
    isSelected,
    cardStyle,
    mode,
    showOverlayIcons,
    onClickMedia,
    showSingleCardTheme,
    deleteAttachment,
    updateSelectedItems,
    iconContainerStyle,
    onToggleMedia,
    itemStyles,
    cardType,
    clubingPlusCard,
    isDisabledMode,
    toolTipText,
    lengthOfClubbedAttachment,
    attachmentResponseObject,
    attachmentBottomComponent,
  }) => {
    const showVerticalCard = getShowVerticalCardMemoize({
      attachment,
      cardType,
    });

    const showAttachmentItemV2 = cardType == "verticalHorizontal";
    const showAttachmentItem = getShowAttachmentItemMemoize({ attachment });

    return (
      <div className={classes.container} key={attachment.id}>
        {showVerticalCard && (
          <AttachmentCard
            cardStyle={cardStyle}
            attachment={attachment}
            deleteAttachment={deleteAttachment}
            mode={mode}
            showOverlayIcons={showOverlayIcons}
            onClickMedia={onClickMedia}
            showDownloadIcon={!showAttachmentItemV2 && !showAttachmentItem}
            showSingleCardTheme={showSingleCardTheme}
            iconContainerStyle={iconContainerStyle}
            onToggleMedia={onToggleMedia}
            itemStyles={itemStyles}
            updateSelectedItems={updateSelectedItems}
            isSelected={isSelected}
            clubingPlusCard={clubingPlusCard}
            isDisabledMode={isDisabledMode}
            toolTipText={toolTipText}
            lengthOfClubbedAttachment={lengthOfClubbedAttachment}
            attachmentResponseObject={attachmentResponseObject}
          />
        )}
        {showAttachmentItemV2 ? (
          <AttachmentItemV2
            attachment={attachment}
            mode={mode}
            deleteAttachment={deleteAttachment}
            isSelected={isSelected}
            updateSelectedItems={updateSelectedItems}
            horizontalCardStyle={{
              padding: "unset",
              border: "unset",
              background: "unset",
            }}
            isDisabledMode={isDisabledMode}
            attachmentBottomComponent={params =>
              _.isFunction(attachmentBottomComponent) &&
              attachmentBottomComponent({
                ...params,
                attachment:
                  _.get(attachment, "attachments.0", null) || attachment,
              })
            }
          />
        ) : (
          showAttachmentItem && (
            <AttachmentItem
              isSelected={isSelected}
              attachment={attachment}
              mode={mode}
              updateSelectedItems={updateSelectedItems}
              deleteAttachment={deleteAttachment}
              customContainerStyle={{
                border: "none",
                height: "40px",
              }}
              showSingleCardTheme={showSingleCardTheme}
            />
          )
        )}
      </div>
    );
  }
);

export default AttachmentCardV2;
