import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import makeRootReducer from "./reducers";
import routeChange from "./middlewares/RouteChange";
import { browserHistory } from "./history";
import { isDevToolsExtensionEnabled } from "config";
// import { loadingBarMiddleware } from "react-redux-loading-bar";

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const initialState = window.___INITIAL_STATE__;

export const creatingStore = () => {
  // ======================================================
  // Middleware Configuration
  // ======================================================

  const middleware = [thunk, routerMiddleware(browserHistory), routeChange];
  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  if (isDevToolsExtensionEnabled) {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers);
    });
  }

  return store;
};

export function injectAsyncReducer(store, name, asyncReducer) {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
}

const store = creatingStore();

export { store, browserHistory };
