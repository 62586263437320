import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { UIButton, UIModal, I18nHOC } from "UIComponents";
import classes from "./AssessmentToolPreviewModal.scss";

class AssessmentToolPreviewModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  onCopyTool = () => {
    const { onCopyTool, onClickClose } = this.props;
    onCopyTool();
    onClickClose();
  };

  render() {
    const {
      onClickClose,
      title,
      getPreviewModalBody,
      toolId,
      tool,
      showChooseButton,
      t,
    } = this.props;

    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={onClickClose}
      >
        <React.Fragment>
          <div className={classes.modalHeader}>
            <span>{title ? title : t("common:no_title")}</span>
            <span className={classes.actionButtons}>
              <UIButton
                type={"hollow"}
                containerStyle={{ marginRight: 16 }}
                size={"sm"}
                onClick={onClickClose}
              >
                {t("common:cancel")}
              </UIButton>
              {showChooseButton && (
                <UIButton color={"blue"} size={"sm"} onClick={this.onCopyTool}>
                  {t("common:choose")}
                </UIButton>
              )}
            </span>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.innerWrapper}>
              {getPreviewModalBody(tool)}
            </div>
          </div>
        </React.Fragment>
      </UIModal>
    );
  }
}

AssessmentToolPreviewModal.propTypes = {
  showChooseButton: PropTypes.bool,
};

AssessmentToolPreviewModal.defaultProps = {
  showChooseButton: true,
};

export default I18nHOC({ resource: "common" })(AssessmentToolPreviewModal);
