import React from "react";
import moment from "moment";
import { extendMoment } from "moment-range";
import {
  getYearDurationFormat,
  getMonthDurationFormat,
  localSearch,
} from "Utils";
import { colors } from "Constants";
import {
  QuickTaskFilledIcon,
  DiscussionFilledIcon,
  CreateAssessmentSvg,
} from "SvgComponents";
import request from "superagent";
import {
  CalenderScheduleYearFilled,
  VideoFilled,
} from "@toddle-design/web-icons";
import { Acronym } from "@toddle-design/web";
import { getOrganizationConstantsFromCache } from "modules/CommonGraphqlHelpers";
import { getSettingValue } from "modules/PermissionModule";

export const START_PICKER_START_TIME = "00:00";
export const START_PICKER_END_TIME = "23:30";
export const END_PICKER_END_TIME = "23:59";

const colorPool = [
  { backgroundColor: colors.violet63, color: colors.white },
  { backgroundColor: colors.teal42, color: colors.white },
  { backgroundColor: colors.blue29, color: colors.white },
  { backgroundColor: colors.gray13, color: colors.white },
  { backgroundColor: colors.pink30, color: colors.white },
  { backgroundColor: colors.blue15, color: colors.white },
  { backgroundColor: colors.yellow14, color: colors.white },
  { backgroundColor: colors.teal20, color: colors.white },
  { backgroundColor: colors.teal33, color: colors.white },
  { backgroundColor: colors.salmon60, color: colors.white },
  { backgroundColor: colors.yellow38, color: colors.white },
  { backgroundColor: colors.pink59, color: colors.white },
];

const Moment = extendMoment(moment);

export const generateDateTimeString = ({ dateString, timeString }) => {
  if (dateString && timeString) {
    return `${dateString}:${timeString}`;
  } else if (dateString) {
    return `${dateString}`;
  } else {
    return null;
  }
};

const getCalendarParentColorMap = ({ calendarIds }) => {
  const colorsInColorPool = colorPool.length;
  return _.reduce(
    calendarIds,
    (result, calendarId, index) => {
      let colorSet = _.head(colorPool);
      if (index >= colorsInColorPool) {
        // repeat color from pool if colorPool exhausted
        colorSet = colorPool[index % colorsInColorPool];
      } else {
        colorSet = colorPool[index];
      }
      result[calendarId] = colorSet;
      return result;
    },
    {}
  );
};

export const getCalendarEventsViewSettingValue = ({ organizationId }) => {
  return getSettingValue({
    name: "ShowAssignmentInCalendarBasedOn",
    organizationId,
  });
};

const getAssessmentIcon = ({
  userType,
  assessmentType,
  colorSetBackgroundColor,
}) => {
  let icon = null;
  let backgroundColor = null;

  switch (assessmentType) {
    case "smt":
      backgroundColor = colors.teal90;
      icon = (
        <Acronym
          background={userType === "staff" ? colors.white : colors.teal42}
          size={"xxx-small"}
          color={
            userType === "staff" ? colorSetBackgroundColor : backgroundColor
          }
          name={"SA"}
        />
      );
      break;
    case "fmt":
      backgroundColor = colors.yellow88;

      icon = (
        <Acronym
          background={userType == "staff" ? colors.white : colors.yellow50}
          size={"xxx-small"}
          color={
            userType === "staff" ? colorSetBackgroundColor : backgroundColor
          }
          name={"FA"}
        />
      );
      break;
    case "qt":
      backgroundColor = colors.blue90;
      icon = (
        <Acronym
          background={userType == "staff" ? colors.white : colors.blue40}
          size={"xxx-small"}
          color={
            userType === "staff" ? colorSetBackgroundColor : backgroundColor
          }
          name={"QT"}
        />
      );
      break;
    case "pt":
    case "ba":
    case "pri":
      icon = (
        <CreateAssessmentSvg
          fill={userType == "staff" ? colors.white : colors.blue29}
          fill1={userType == "staff" ? colors.blue29 : colors.white}
        />
      );
      backgroundColor = colors.blue90;
      break;
    default:
      backgroundColor = colors.violet94;
      if (_.isEmpty(assessmentType) || _.isEqual(assessmentType, "le")) {
        icon = (
          <Acronym
            background={userType == "staff" ? colors.white : colors.violet63}
            color={
              userType === "staff" ? colorSetBackgroundColor : backgroundColor
            }
            size={"xxx-small"}
            name={"LE"}
          />
        );
      } else {
        icon = (
          <CreateAssessmentSvg
            fill={userType == "staff" ? colors.white : colors.blue29}
            fill1={userType == "staff" ? colors.blue29 : colors.white}
          />
        );
      }
  }

  return { icon, backgroundColor };
};

export const getCalendarParentColorMapMemoize = _.memoize(
  params => getCalendarParentColorMap(params),
  params => JSON.stringify(params)
);

const getEventCountForDate = ({ isoDate, events }) => {
  const filteredEvents = _.filter(
    events,
    event =>
      moment(_.get(event, "startTime", null))
        .startOf("day")
        .toDate()
        .toISOString() === isoDate
  );
  return filteredEvents.length;
};

export const getWeekDays = ({
  week,
  visibleDays,
  selectedDate,
  calendarEvents,
}) => {
  return _.reduce(
    visibleDays,
    (result, item) => {
      const momentDate = moment(selectedDate).isoWeek(week).day(item);
      const isoDateString = momentDate.startOf("day").toDate().toISOString();
      result.push({
        day: momentDate.format("ddd")[0],
        date: momentDate.format("DD"),
        dateString: isoDateString,
        isToday:
          isoDateString === moment().startOf("day").toDate().toISOString(),
        isSelected: isoDateString === selectedDate,
        eventCount: getEventCountForDate({
          isoDate: isoDateString,
          events: calendarEvents,
        }),
      });
      return result;
    },
    []
  );
};

export const getStartEndDateOfWeek = ({ week, visibleDays, year }) => {
  const startDate = moment(year).isoWeek(week).day(_.min(visibleDays));
  const endDate = moment(year).isoWeek(week).day(_.max(visibleDays));
  return { startDate, endDate };
};

export const getDurationText = ({ startDate, endDate }) => {
  return `${getMonthDurationFormat({
    startDate,
    endDate,
  })}, ${getYearDurationFormat({ startDate, endDate })}`;
};

export const getStartEndTimeOfDateRange = ({ startDate, endDate }) => {
  const startDateTime = startDate.startOf("day").toDate().toISOString();
  const endDateTime = endDate.endOf("day").toDate().toISOString();
  return { startDateTime, endDateTime };
};

const getFormatedCalendarEvents = ({ calendarEvents, orgId }) => {
  const formattedEvents = _.reduce(
    calendarEvents,
    (result, event) => {
      const item = _.get(event, "item");
      const itemType = _.get(event, "itemType");
      const { title } = getEventMetadata({
        item,
        orgId,
        itemType,
      });
      const calendars = _.get(event, "calendars", []);
      const isSchoolCalendarEvent = itemType === "SCHOOL_CALENDAR_EVENT";

      const newEvent = {
        id: _.get(event, "id", null),
        title,
        // Note:  moment().toDate() is returns the Date with the system timezone.
        start: isSchoolCalendarEvent
          ? new Date(
              moment(moment(_.get(event, "startTime")))
                .utc()
                .toISOString()
            )
          : moment(_.get(event, "startTime")).toDate(),
        end: isSchoolCalendarEvent
          ? new Date(
              moment(moment(_.get(event, "endTime")))
                .add(1, "minute")
                .toISOString()
            )
          : moment(_.get(event, "endTime")).toDate(),

        allDay: isSchoolCalendarEvent ? true : undefined,
        itemType,
        item,
        calendars,
      };

      result.push(newEvent);
      return result;
    },
    []
  );

  return formattedEvents;
};

export const getFormatedCalendarEventsMemoize = _.memoize(
  params => getFormatedCalendarEvents(params),
  params => JSON.stringify(params)
);

export const getStartEndTimeText = ({ startDate, endDate }) => {
  const isStartEndDateSame =
    moment(startDate).startOf("day").toDate().toISOString() ===
    moment(endDate).startOf("day").toDate().toISOString();

  if (isStartEndDateSame) {
    return `${moment(startDate).format("hh:mm")} - ${moment(endDate).format(
      "hh:mm a"
    )}`;
  } else {
    return `${moment(startDate).format("hh:mm a")} - ${moment(endDate).format(
      "hh:mm a"
    )}`;
  }
};

/**
 * Always return same interger hash for same course
 */
// const getCourseHashForColor = ({ colorsInColorPool, textToHash }) => {
//   let hashSeed = 2344;
//   let i = textToHash.length;

//   while (i) {
//     hashSeed = (hashSeed * 33) ^ textToHash.charCodeAt(--i);
//   }
//   /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
//    * integers. Since we want the results to be always positive, convert the
//    * signed int to an unsigned by doing an unsigned bitshift. */
//   return (hashSeed >>> 0) % colorsInColorPool;
// };

export const getEventMetadata = ({
  item,
  itemType,
  userType,
  orgId,
  calendars,
  calendarParentColorMap,
}) => {
  const { content, contentType } = item;
  let backgroundColor = "#e6e6e6";
  let eventIcon = "";
  let color = "#000";
  let title = "";
  let description = "";
  let assessmentType;
  let assessmentIconDetails;
  if (itemType === "ASSIGNMENT") {
    description = _.get(content, "label", "");
    assessmentType = _.get(content, "assessmentType.value", "le");
    switch (contentType) {
      case "ASSIGNMENT_DISCUSSION": {
        title = "Class Discussion";
        backgroundColor = colors.green85;
        color = colors.black;
        eventIcon = (
          <DiscussionFilledIcon
            fill={userType === "staff" ? colors.white : colors.green42}
          />
        );
        break;
      }
      case "ASSIGNMENT_RESOURCE": {
        title = _.get(content, "assignmentTitle", null);
        backgroundColor = colors.blue90;
        color = colors.black;
        eventIcon = (
          <QuickTaskFilledIcon
            fill={userType === "staff" ? colors.white : colors.blue40}
          />
        );
        break;
      }
      case "ASSESSMENT": {
        title = _.get(content, "title.value", null);
        color = colors.black;
        if (userType !== "staff") {
          assessmentIconDetails = getAssessmentIcon({
            userType,
            assessmentType,
          });
          eventIcon = _.get(assessmentIconDetails, "icon");
          backgroundColor = _.get(assessmentIconDetails, "backgroundColor");
        }
        break;
      }
      case "ASSIGNMENT_MEETING": {
        title = _.get(content, "assignmentTitle", null);
        backgroundColor = colors.pink95;
        color = colors.white;
        eventIcon = (
          <VideoFilled
            style={{
              color: userType === "staff" ? colors.white : colors.pink48,
            }}
          />
        );

        break;
      }
    }

    /**
     * if user is staff then  show course wise colors
     */
    if (userType === "staff" && contentType !== "ASSIGNMENT_MEETING") {
      const calendarCourseId = _.get(
        _.find(calendars, { parentType: "COURSE" }),
        "id"
      );
      const colorSet = _.get(calendarParentColorMap, calendarCourseId);
      backgroundColor = _.get(colorSet, "backgroundColor");
      color = _.get(colorSet, "color");

      assessmentIconDetails = getAssessmentIcon({
        userType,
        assessmentType,
        colorSetBackgroundColor: backgroundColor,
      });

      eventIcon = _.get(assessmentIconDetails, "icon");
    }
  } else if (itemType === "CALENDAR_EVENT_ITEM") {
    title = _.get(item, "label");
    description = _.get(item, "description", "");
    backgroundColor = userType === "staff" ? colors.pink48 : colors.pink95;
    color = userType === "staff" ? colors.white : colors.black;
    eventIcon = (
      <VideoFilled
        style={{
          color: userType === "staff" ? colors.white : colors.pink48,
        }}
      />
    );
  } else if (itemType === "SCHOOL_CALENDAR_EVENT") {
    title = _.get(item, "title");
    eventIcon = <CalenderScheduleYearFilled variant="on" size="xxx-small" />;
    backgroundColor = _.get(item, "tag.colorCode");
    if (!backgroundColor && orgId) {
      // if no background color then use the color from org constant

      // get event tags from cache
      const calendarEventTags = _.get(
        getOrganizationConstantsFromCache(orgId),
        "calendarEventTags",
        {}
      );

      // assign color where item's category matches tag name
      backgroundColor = _.get(
        _.find(calendarEventTags, tag => {
          return tag.name === _.get(item, "category");
        }),
        "color_code"
      );
    }
  }
  return { backgroundColor, color, eventIcon, title, description };
};

export const getCourseWiseUsersDetails = (coursesArr, usersArr) => {
  let segregatedArr = [],
    students = [],
    filteredStudent;
  _.forEach(coursesArr, course => {
    students = [];
    _.forEach(_.get(course, "allStudents.edges", []), student => {
      if (
        !_.isEmpty(
          (filteredStudent = _.find(
            usersArr,
            user => user.id === student.node.id
          ))
        )
      ) {
        students.push(filteredStudent);
      }
    });
    segregatedArr.push({
      id: course.id,
      title: course.title,
      students,
      totalStudents: _.get(course, "allStudents.edges", []),
    });
  });
  return segregatedArr;
};

export const getFilteredCourseListMemoize = _.memoize(
  params => getFilteredCourseList(params),
  params => JSON.stringify(params)
);

const getFilteredCourseList = ({ courseList, searchedText }) => {
  return _.filter(courseList, ({ label }) => {
    const index = localSearch({ text: label, searchText: searchedText });

    return index > -1 || _.isEmpty(searchedText);
  });
};

export const getButtonLabelTextMemoize = _.memoize(
  params => getButtonLabelText(params),
  params => JSON.stringify(params)
);

const getButtonLabelText = ({ value, courseList, t }) => {
  const selectedIds = _.size(value);
  if (selectedIds === 0) {
    return t("calendar:no_class");
  } else if (selectedIds === _.size(courseList)) {
    return t("common:all_classes_plural");
  } else {
    return t("common:classes_with_count", {
      count: selectedIds,
    });
  }
};

export const getCourseOptionsMemoize = _.memoize(
  params => getCourseOptions(params),
  params => JSON.stringify(params)
);

const getCourseOptions = ({ courseList }) => {
  return _.reduce(
    courseList,
    (courseOptions, course) => {
      const gradeLabel = _.get(course, "grade.name");
      const idx = _.findIndex(
        courseOptions,
        courseOption => _.get(courseOption, "label") === gradeLabel
      );

      const courseLabel = _.get(course, "label", "");
      const courseValue = _.get(course, "value", "");
      const gradeValue = _.get(course, "grade.id", "");

      if (idx !== -1) {
        courseOptions[idx]?.options.push({
          label: courseLabel,
          value: courseValue,
        });
      } else {
        courseOptions.push({
          label: gradeLabel,
          value: gradeValue,
          options: [
            {
              label: courseLabel,
              value: courseValue,
            },
          ],
        });
      }
      return courseOptions;
    },
    []
  );
};

const getCourseList = ({ courses }) => {
  return _.reduce(
    courses,
    (result, course) => {
      result.push({
        courseId: _.get(course, "id"),
        label: _.get(course, "title"),
        value: _.get(course, ["calendar", "id"], null),
        students: _.get(course, ["students", "edges"], []),
        grade: _.get(course, ["grade"], {}),
      });
      return result;
    },
    []
  );
};

export const getCourseListMemoize = _.memoize(
  params => getCourseList(params),
  params => JSON.stringify(params)
);

const getCourseFolderList = ({ courseFolders }) => {
  return _.reduce(
    courseFolders,
    (result, folder) => {
      result.push({ label: folder.name, value: folder.id });
      return result;
    },
    []
  );
};

export const getCourseFolderListMemoize = _.memoize(
  params => getCourseFolderList(params),
  params => JSON.stringify(params)
);

const getCourseIdsFromSelectedCalendarIds = ({ courseList, calendarIds }) => {
  return _.reduce(
    calendarIds,
    (result, calendarId) => {
      const courseId = _.get(
        _.find(courseList, { value: calendarId }),
        "courseId"
      );

      result.push(courseId);
      return result;
    },
    []
  );
};

export const getCourseIdsFromSelectedCalendarIdsMemoize = _.memoize(
  params => getCourseIdsFromSelectedCalendarIds(params),
  params => JSON.stringify(params)
);

export const getGooleScopeOfUser = async ({ accessToken }) => {
  try {
    const { body } = await request
      .get(`https://www.googleapis.com/oauth2/v3/tokeninfo`)
      .query(`access_token=${accessToken}`);
    return body;
  } catch (err) {
    // console.log(err);
  }
};

export const checkUserAuthScopes = async ({ accessToken, scopes }) => {
  try {
    const userScopeData = await getGooleScopeOfUser({ accessToken });
    const userScopes = _.split(_.get(userScopeData, "scope", null), " ");
    if (
      !userScopes ||
      _.intersection(userScopes, scopes).length !== scopes.length
    ) {
      return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

export const isWeekNumberInAcademicYear = ({
  weekNumber,
  visibleDays,
  yearStartDate,
  yearEndDate,
  selectedDate,
}) => {
  const { startDate, endDate } = getStartEndDateOfWeek({
    week: weekNumber,
    visibleDays,
    year: selectedDate,
  });
  if (
    startDate.diff(moment(yearStartDate), "day") >= -6 &&
    endDate.diff(moment(yearEndDate), "day") <= -1
  ) {
    return true;
  }
  return false;
};

export const findSchoolCalendarEvent = ({ calendarEvents }) =>
  _.find(calendarEvents, event => {
    return event.itemType === "SCHOOL_CALENDAR_EVENT";
  });

export const findSchoolCalendarEventMemoize = _.memoize(
  params => findSchoolCalendarEvent(params),
  params => JSON.stringify(params)
);

// returns true if date is between start and end
export const isDateInRange = (date, start, end) => {
  const range = Moment().range(start, end);
  return range.contains(new Date(date));
};

const getSelectedDateEvents = ({ calendarEvents, selectedDate }) =>
  _.filter(calendarEvents, event => {
    return isDateInRange(selectedDate, event.startTime, event.endTime);
  });

export const getSelectedDateEventsMemoize = _.memoize(
  params => getSelectedDateEvents(params),
  params => JSON.stringify(params)
);

const getUpdatedStartAndEndDateEvents = ({ events, selectedDate }) => {
  return _.map(events, event => {
    // check if event is a school calendar event
    if (event.itemType === "SCHOOL_CALENDAR_EVENT") {
      // check if selected date falls between start and end
      if (
        isDateInRange(
          selectedDate,
          moment(event.start).format("YYYY-MM-DD"),
          moment(event.end).format("YYYY-MM-DD")
        )
      ) {
        // [hack] assign start date and end date to selected date inorder to show in current selected date's pane

        // convert to system timezone using toDate() and then send this data to big calendar
        return Object.assign({}, event, {
          start: moment(selectedDate).toDate(),
          end: moment(selectedDate).toDate(),
        });
      }
    }
    return event;
  });
};

export const getUpdatedStartAndEndDateEventsMemoize = _.memoize(
  params => getUpdatedStartAndEndDateEvents(params),
  params => JSON.stringify(params)
);
