import React from "react";
import classes from "./Chip.scss";
import { CloseOutlined } from "@toddle-design/web-icons";
import { I18nHOC } from "UIComponents";

const ActionChip = props => {
  const { icon, label, onClose = () => {}, t } = props;
  return (
    <div className={classes.chipContainer}>
      <div className={classes.chipIcon}>{icon}</div>
      <div className="text-body-2 whitespace-nowrap">{t(label)}</div>
      <CloseOutlined
        className={classes.chipCloseIcon}
        onClick={onClose}
        role="button"
        size="xxx-small"
        // tabIndex="0"
      />
    </div>
  );
};

export default I18nHOC({ resource: ["common", "teacherHomePage"] })(ActionChip);
