import React from "react";
import classes from "./ValueComponent.scss";
import { CancelIcon } from "SvgComponents";
import { colors } from "Constants";

const ValueComponent = ({ data }) => {
  const { id, children, disabled, onClick, onRemove, value } = data;
  const colorValue = colors[_.get(value, "color", "")];

  const containerStyle = {
    backgroundColor: colorValue ? `${colorValue}1A` : "",
    padding: disabled ? "0 12px 0 12px" : "0 0 0 12px",
  };
  const handleOnMouseDown = e => {
    e.stopPropagation();
    onRemove(value);
  };
  const handleOnTouchEnd = e => {
    e.stopPropagation();
    e.preventDefault();
    onRemove(value);
  };
  const renderRemoveButton = (
    <div
      className={classes.removeButton}
      onMouseDown={handleOnMouseDown}
      onTouchEnd={handleOnTouchEnd}
    >
      <CancelIcon width={8} height={8} fill={colors.gray72} />
    </div>
  );

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.itemContainer}>
        <div className={classes.labelContainer}>
          <div
            className={classes.labelText}
            style={colorValue ? { color: colorValue } : {}}
          >{`${value.label}`}</div>
        </div>
        {!disabled && renderRemoveButton}
      </div>
    </div>
  );
};

export default ValueComponent;
