import update from "immutability-helper";

const keys = {
  USER_INFO: "userInfo",
  RECENT_SEARCHES: "recentSearches",
};

//this is structure for storage initial state for recent search in local storage
export const recentSearchInitialState = {
  version: 2,
  data: {},
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const clearLocalStorageForKey = key => {
  localStorage.removeItem(key);
};

export const getJsonItem = key => {
  try {
    const item = localStorage.getItem(key);
    if (!item) {
      return;
    }
    return JSON.parse(item);
  } catch (err) {
    console.error(err);
    return;
  }
};

/**
 * @param {Object} params
 * @param {string} params.key -
 * @param {*} params.value -
 */
export const setJsonItem = ({ key, value }) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (err) {
    console.error(err);
  }
};

export const getUserInfofromStorage = () => {
  return getJsonItem(keys.USER_INFO);
};

export const setUserInfoInStorage = value => {
  setJsonItem({ key: keys.USER_INFO, value });
};

export const setRecentSearchesInStorage = value => {
  const updatedData = update(recentSearchInitialState, {
    data: { $set: value },
  });
  setJsonItem({ key: keys.RECENT_SEARCHES, value: updatedData });
};

export const getRecentSearchesFromStorage = () => {
  return getJsonItem(keys.RECENT_SEARCHES);
};

export const migrationFunction = () => {
  const recentItems = getRecentSearchesFromStorage();

  const currentVersion = _.get(recentItems, "version", 1);

  if (currentVersion === 1) {
    clearLocalStorageForKey(keys.RECENT_SEARCHES);
  }
};

export const getRecentSearchesFromStorageByMigration = () => {
  //this function is match the version diff
  //there are some keys in global search which is not in prev. version
  //so that might break the module there for clear storage
  migrationFunction();

  return getRecentSearchesFromStorage();
};
