import PropTypes from "prop-types";
import React from "react";

const AddCircleIcon = props => {
  return (
    <svg viewBox="0 0 48 48" width={props.width} height={props.height}>
      <g id="Page-1" fill={props.fill && props.fill}>
        <g id="Macbook" transform="translate(-453.000000, -225.000000)">
          <path
            d="M477,228.999 C465.972,228.999 456.99999,237.972 456.99999,249 C456.99999,260.028 465.972,269.0001 477,269.0001 C488.028,269.0001 497.00001,260.028 497.00001,249 C497.00001,237.972 488.028,228.999 477,228.999 M477,273 C463.764,273 453,262.236 453,249 C453,235.764 463.764,225 477,225 C490.236,225 501,235.764 501,249 C501,262.236 490.236,273 477,273 Z M484.79199,246.999 L480.20799,246.999 L479.00001,246.999 L479.00001,245.793 L479.00001,241.209 C479.00001,240.105 478.104,239.208 477,239.208 C475.896,239.208 474.99999,240.105 474.99999,241.209 L474.99999,245.793 L474.99999,246.999 L473.79201,246.999 L469.20801,246.999 C468.10401,246.999 467.208,247.896 467.208,249 C467.208,250.104 468.10401,251.001 469.20801,251.001 L473.79201,251.001 L474.99999,251.001 L474.99999,252.2079 L474.99999,256.7919 C474.99999,257.8959 475.896,258.792 477,258.792 C478.104,258.792 479.00001,257.8959 479.00001,256.7919 L479.00001,252.2079 L479.00001,251.001 L480.20799,251.001 L484.79199,251.001 C485.89599,251.001 486.792,250.104 486.792,249 C486.792,247.896 485.89599,246.999 484.79199,246.999 Z"
            id="Add"
          />
        </g>
      </g>
    </svg>
  );
};

AddCircleIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

AddCircleIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default AddCircleIcon;
