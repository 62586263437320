import { sprigClientId } from "config";

const interceptAndVerifyStudy = e => {
  // TODO handle verification of manually triggered surveys

  // TODO improve the following by determining what survey is about to be displayed and validating accordingly

  // verfication of triggered surveys by pageURL regex events
  try {
    const pageUrlEvents = _.filter(
      _.get(window, "Sprig._config.pageUrlEvents", []),
      event => event?.matchType === "regex"
    );
    const currentLocation = window.location.href;
    const urlRegexes = _.map(pageUrlEvents, "pattern");

    const isValid = _.some(urlRegexes, urlRegex => {
      const pattern = new RegExp(urlRegex);
      return pattern.test(currentLocation);
    });

    if (!isValid) {
      dismissActiveSurvey();
    }
  } catch (err) {
    // console.log(err);
  }
};

const addSprigListener = () => {
  if (window.Sprig && window.Sprig?.addListener) {
    try {
      window.Sprig.addListener(
        window.Sprig.UPDATES.SURVEY_APPEARED,
        interceptAndVerifyStudy
      );
    } catch (err) {
      // console.log(err);
    }
  }
};

const loadSprig = useProdKey => {
  const sprigEnvironmentID = sprigClientId;

  const abz = function (l, e, a, p) {
    if (window.Sprig) return;
    window.Sprig = function () {
      S._queue.push(arguments);
    };
    var S = window.Sprig;
    S.appId = a;
    S._queue = [];
    window.UserLeap = S;
    a = l.createElement("script");
    a.async = 1;
    a.src = e + "?id=" + S.appId;
    p = l.getElementsByTagName("script")[0];
    p.parentNode.insertBefore(a, p);
  };

  abz(document, "https://cdn.sprig.com/shim.js", sprigEnvironmentID);
  setTimeout(addSprigListener, 2000);
};

const loadUserInfo = (userId, userEmail) => {
  if (window.Sprig) {
    window.Sprig("setUserId", userId);
    window.Sprig("setEmail", userEmail);
  }
};

const logoutUser = () => {
  if (window.Sprig) {
    window.Sprig("logoutUser");
    window.Sprig?.removeAllListeners && window.Sprig?.removeAllListeners();
  }
};

const setAttributes = attributes => {
  if (window.Sprig) {
    window.Sprig("setAttributes", attributes);
  }
};

const removeAttributes = attributeKeys => {
  if (window.Sprig) {
    window.Sprig("removeAttributes", attributeKeys);
  }
};

const displaySurvey = surveyId => {
  if (window.Sprig) {
    window.Sprig("displaySurvey", surveyId);
  }
};

const trackEvents = eventsName => {
  if (window.Sprig) {
    _.forEach(eventsName, eventName => {
      window.Sprig("track", eventName);
    });
  }
};

const dismissActiveSurvey = () => {
  if (window.Sprig) {
    window.Sprig?.dismissActiveSurvey();
  }
};

export {
  loadSprig,
  loadUserInfo,
  logoutUser,
  trackEvents,
  displaySurvey,
  dismissActiveSurvey,
  setAttributes,
  removeAttributes,
};
