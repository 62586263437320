import React, { useState, useRef } from "react";
import classes from "./ParentLoginForm.scss";
import { PhoneInputField, FormTextInput } from "UIComponents";
import { colors } from "Constants";
import { Button, SegmentControl } from "@toddle-design/web";

export const TABS = [
  {
    key: "email",
    label: "Sign in with email",
    value: "EMAIL",
  },
  {
    key: "phone",
    label: "Sign in with phone",
    value: "PHONE",
  },
];

const styles = {
  tabContainerStyle: {
    display: "flex",
    width: "100%",
    border: `1px solid ${colors.strokeTwo}`,
    padding: "4px",
    borderRadius: "8px",
  },
  optionStyle: {
    width: "50%",
    backgroundColor: colors.white,
    borderRadius: "6px",
  },
  selectedOptionStyle: {
    backgroundColor: colors.blue29,
  },
  selectedTextStyle: {
    color: colors.toddleBG1,
  },
  textStyle: {
    fontSize: "1.4rem",
    color: colors.gray48,
    lineHeight: 1.36,
  },
  editContainerStyle: {
    display: "block",
  },
};

const ParentLoginForm = props => {
  const {
    renderBackButtonComponent,
    customStyle: { containerStyle, headerStyle } = {},
    updateCurrentScreen,
    data: { email, password, phoneNo, phonePassword } = {},
    inputStyle,
    renderErrorComponent,
    updateInputField,
    currentParentLoginTab,
    setCurrentParentLoginTab,
  } = props;

  // ref - email form fields
  const withEmailRef = useRef([]);
  withEmailRef.current = _.map(
    Array(2),
    (_, i) => (withEmailRef.current[i] = React.createRef())
  );

  // ref - phone form fields
  const withPhoneRef = useRef([]);
  withPhoneRef.current = _.map(
    Array(2),
    (_, i) => (withPhoneRef.current[i] = React.createRef())
  );

  // onClick Event - Functions
  const onBackClick = () => {
    updateCurrentScreen({ type: "parentLogin" });
  };

  const onForgotPasswordClick = () => {
    const { updateCurrentScreen } = props;
    currentParentLoginTab === "PHONE"
      ? updateCurrentScreen({ type: "forgotPhonePassword" })
      : updateCurrentScreen({ type: "forgotPassword" });
  };

  const onSignInClick = () => {
    const { onSignInClick, showErrorToast, onPhoneSignInClick } = props;
    const errorCount = isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    if (currentParentLoginTab === "PHONE") {
      onPhoneSignInClick();
    } else {
      onSignInClick();
    }
  };

  // UISegment Tab Change
  const handleTabChange = value => {
    setCurrentParentLoginTab(value);
    updateInputField({});
  };

  // Validation Functions
  const isValid = () => {
    let errorCount = 0;
    const formRef =
      currentParentLoginTab === "PHONE" ? withPhoneRef : withEmailRef;

    _.map(Array(2), (_, i) => {
      if (formRef.current[i].current) {
        errorCount += formRef.current[i].current.isValid();
      }
    });

    return errorCount;
  };

  const checkEmailVerification = value => {
    const { checkEmailVerification } = props;
    return checkEmailVerification(value);
  };

  const checkPhoneVerification = value => {
    return value.length < 6 ? "Please enter a valid phone number" : "";
  };

  return (
    <div className={classes.container} style={containerStyle}>
      {renderBackButtonComponent({
        onClick: onBackClick,
        buttonText: "Family account",
      })}
      <div style={headerStyle}> {"Sign in Toddle"} </div>
      <div className={classes.segmentContainer}>
        <SegmentControl
          options={TABS}
          value={currentParentLoginTab}
          onChange={handleTabChange}
        />
      </div>
      <div className={classes.formComponent}>
        {currentParentLoginTab === "PHONE" ? (
          <>
            <div className={classes.inputContainer}>
              <PhoneInputField
                label={"Phone number"}
                value={phoneNo}
                placeholder={"Enter your phone number"}
                name={"phoneNo"}
                key={"phoneNo"}
                updateInputField={updateInputField}
                ref={withPhoneRef.current[0]}
                showErrorText={true}
                renderErrorComponent={renderErrorComponent}
                error={"Enter phone number"}
                labelContainerStyle={inputStyle.labelContainerStyle}
                labelStyle={inputStyle.labelStyle}
                customValidation={checkPhoneVerification}
                editContainerStyle={styles.editContainerStyle}
              />
            </div>
            <div className={classes.inputContainer}>
              <FormTextInput
                size={"large"}
                textInputLabel={"Password*"}
                value={phonePassword}
                placeholder={"Enter Password"}
                name={"phonePassword"}
                key={"phonePassword"}
                type={"password"}
                updateInputField={updateInputField}
                {...inputStyle}
                ref={withPhoneRef.current[1]}
                error={"Enter password"}
                onEnter={onSignInClick}
              />
              <div
                className={classes.forgotPassword}
                onClick={onForgotPasswordClick}
              >{`Forgot password?`}</div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.inputContainer}>
              <FormTextInput
                size={"large"}
                textInputLabel={"Email*"}
                value={email}
                placeholder={"Enter your email"}
                name={"email"}
                key={"email"}
                updateInputField={updateInputField}
                ref={withEmailRef.current[0]}
                customValidation={checkEmailVerification}
                error={"Enter email address"}
              />
            </div>
            <div className={classes.inputContainer}>
              <FormTextInput
                type={"password"}
                size={"large"}
                textInputLabel={"Password*"}
                value={password}
                placeholder={"Enter Password"}
                name={"password"}
                key={"password"}
                updateInputField={updateInputField}
                ref={withEmailRef.current[1]}
                error={"Enter password"}
                onEnter={onSignInClick}
              />
              <div
                className={classes.forgotPassword}
                onClick={onForgotPasswordClick}
              >{`Forgot password?`}</div>
            </div>
          </>
        )}
        <div className={classes.bottomContainer}>
          <Button
            variant={"destructive"}
            size={"large"}
            onClick={onSignInClick}
            isFullWidth={true}
          >
            {"Sign in"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ParentLoginForm;
