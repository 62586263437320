import update from "immutability-helper";
import client from "apolloClient";
import { markConversationAsReadMutation } from "./ChatContainerMutation";
import { updateNavigationObject } from "modules/NavigationModule";
import { getConversationsUnreadCountQuery } from "./ChatContainerQuery";
import { getConversationsQuery } from "AppComponents/ChatContainer/modules/ChatContainerQuery";
import {
  getConversationsFromCache,
  writeConversationsFragment,
} from "./ChatContainerHelpers";
import { generateRandomId, getTaggedUserIds } from "Utils";
import moment from "moment";
import {
  getUserBasicDetailsFromCache,
  writeMessageFragment,
  readMessageFragment,
} from "modules/CommonGraphqlHelpers";
import {
  createMessageMutation,
  updateMessageMutation,
  deleteMessageMutation,
} from "modules/CommonMutations";
import { ATTACHMENT_CREATE_ATTRIBUTES } from "store/static";

export const INTIAL_RECORDS_NUMBER = 15;
const EXCLUDE_ITEM_TYPES_FOR_CONVERSATION_READ = [
  "STUDENT_ASSIGNMENT_SUBMISSION_FEEDBACK",
];

const USER_BASIC_DETAILS_PICK_KEYS = [
  "id",
  "firstName",
  "lastName",
  "email",
  "profileImage",
  "__typename",
];

export const getChatQueryVariables = ({
  id,
  showAllMessages,
  endCursor,
  showInstructionMessage,
  extraFilters = null,
}) => {
  let chatQueryVars = {
    id,
    filters: {
      showInstructionMessage,
    },
  };
  if (!showAllMessages) chatQueryVars["first"] = INTIAL_RECORDS_NUMBER;
  if (endCursor) chatQueryVars["after"] = endCursor;
  if (extraFilters) {
    chatQueryVars.filters = { ...chatQueryVars.filters, ...extraFilters };
  }
  return chatQueryVars;
};

export const NAME = "conversation";
export const UPDATE_CHAT_INFO = "UPDATE_CHAT_INFO" + " " + NAME;
export const UPDATE_TEXT_BOX_FOCUS_STATE = "UPDATE_TEXT_BOX" + " " + NAME;

export const createMessage = params => {
  return async (dispatch, getState) => {
    const parentId = _.get(params, "id", "");
    const label = _.get(params, "label", "");
    const showAllMessages = _.get(params, "showAllMessages", false);
    const showInstructionMessage = _.get(
      params,
      "showInstructionMessage",
      true
    );
    const extraFilters = _.get(params, "extraFilters", {});

    const collaborators = _.get(params, "collaborators", []);

    const chatQueryVariables = getChatQueryVariables({
      id: parentId,
      showAllMessages,
      showInstructionMessage,
      extraFilters,
    });
    const mainData = getConversationsFromCache(chatQueryVariables);
    const userType = getState().login.userInfo.user_type;

    const attachmentCreatedBy = _.includes(["student", "staff"], userType)
      ? _.capitalize(userType)
      : "FamilyMember";

    const attachments = _.map(params.attachments, attachment => {
      return {
        ...ATTACHMENT_CREATE_ATTRIBUTES,
        id: generateRandomId(),
        ...attachment,
        thumbUrl: null,
        title: null,
        streamUrl: null,
        parentType: "MESSAGE_ITEM",
        metadata: null,
        createdBy: {
          id: generateRandomId(),
          type: userType,
          __typename: attachmentCreatedBy,
        },
        __typename: "Attachment",
      };
    });

    const newMessage = {
      id: generateRandomId(),
      label,
      isPin: false,
      isRead: false,
      itemType: "ATTACHMENTS",
      type: "NORMAL",
      item: {
        id: generateRandomId(),
        attachments,
        __typename: "AttachmentSet",
      },
      messageReplies: {
        edges: [],
        __typename: "MessageRepliesConnection",
      },
      __typename: "Message",
      createdBy: {
        ..._.pick(
          getUserBasicDetailsFromCache({
            id: getState().login.userInfo.id,
            type: getState().login.userInfo.userEntityType,
          }),
          USER_BASIC_DETAILS_PICK_KEYS
        ),
        schoolTenures: [],
        type: userType,
      },
      createdAt: moment().toISOString(),
      isDeleted: false,
    };

    const variables = {
      parentId,
      parentType: "CONVERSATION",
      label: _.trim(label),
      subEntityType: "MESSAGE",
      attachments: params.attachments,
    };

    const messageType = _.get(params, "type", "");
    if (!_.isEmpty(messageType)) {
      variables.type = messageType;
      newMessage.type = messageType;
    }

    const taggedUsers = _.isEmpty(collaborators)
      ? []
      : getTaggedUserIds({
          comment: label,
          collaborators,
        });

    if (!_.isEmpty(taggedUsers)) {
      variables["taggedUsers"] = taggedUsers;
    }

    try {
      const response = await client.mutate({
        mutation: createMessageMutation,
        variables,
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            createMessage: newMessage,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { createMessage },
            },
          }
        ) => {
          const ConversationMessageEdge = {
            node: createMessage,
            __typename: "ConversationMessageEdge",
          };

          const dataTosend = update(mainData, {
            messages: {
              edges: { $unshift: [ConversationMessageEdge] },
              totalCount: {
                $set: _.get(mainData, "messages.totalCount", 0) + 1,
              },
            },
          });

          setTimeout(() => {
            writeConversationsFragment({
              ...chatQueryVariables,
              data: dataTosend,
            });
          });
        },
      });

      return { ...response, successful: true };
    } catch (e) {
      setTimeout(() => {
        writeConversationsFragment({
          ...chatQueryVariables,
          data: mainData,
        });
      });

      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const getConversations = ({ conversationId, parentId, parentType }) => {
  return async (dispatch, getState) => {
    const chatInfo = getState().conversation.chatInfo;
    const shouldMarkConversationAsRead = _.get(
      chatInfo,
      "shouldMarkConversationAsRead",
      true
    );

    if (
      chatInfo.parentId == parentId.toString() &&
      parentType == chatInfo.parentType
    ) {
      dispatch(
        updateNavigationObject({
          chatBox: {
            conversationId,
          },
        })
      );
      if (shouldMarkConversationAsRead) {
        client.mutate({
          mutation: markConversationAsReadMutation,
          variables: {
            id: conversationId,
            excludeItemTypes: EXCLUDE_ITEM_TYPES_FOR_CONVERSATION_READ,
          },
        });
      }
    } else {
      client.query({
        query: getConversationsUnreadCountQuery,
        variables: {
          id: conversationId,
        },
        fetchPolicy: "network-only",
      });
    }
  };
};

export const updateChatInfo = data => {
  return {
    type: UPDATE_CHAT_INFO,
    data,
  };
};

export const updateTextboxFocus = data => {
  return {
    type: UPDATE_TEXT_BOX_FOCUS_STATE,
    data,
  };
};

export const updateMessage = params => {
  const mainMessage = readMessageFragment({ id: params.id });
  const conversationId = _.get(params, "conversationId", "");
  const showAllMessages = _.get(params, "showAllMessages", false);
  const showInstructionMessage = _.get(params, "showInstructionMessage", true);
  const extraFilters = _.get(params, "extraFilters", {});
  const isPinToggle = _.get(params, "isPinToggle", false);

  const chatQueryVariables = getChatQueryVariables({
    id: conversationId,
    showAllMessages,
    showInstructionMessage,
    extraFilters,
  });

  let refetchQuery = {};

  if (isPinToggle) {
    refetchQuery = {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getConversationsQuery,
          variables: chatQueryVariables,
        },
      ],
    };
  }

  let attachments = _.map(params.attachments, attachment => {
    return {
      id: generateRandomId(),
      ...attachment,
      __typename: "Attachment",
    };
  });

  const taggedUsers = _.isEmpty(params.collaborators)
    ? []
    : getTaggedUserIds({
        comment: params.label,
        collaborators: params.collaborators,
      });

  if (!_.isEmpty(taggedUsers)) {
    params["taggedUsers"] = taggedUsers;
  }

  let updatedData = update(mainMessage, {
    type: { $set: params.type },
    isPin: { $set: params.isPin },
    label: { $set: params.label },
    item: {
      attachments: { $set: attachments },
    },
  });

  const filteredParams = _.omit(params, ["collaborators"]);

  writeMessageFragment({
    nodeId: params.id,
    data: updatedData,
  });

  return async () => {
    try {
      const response = await client.mutate({
        mutation: updateMessageMutation,
        variables: { ...filteredParams },
        ...refetchQuery,
        // variables: { ...params },
      });
      return { ...response, successful: true };
    } catch (e) {
      setTimeout(() => {
        writeMessageFragment({
          nodeId: params.id,
          data: mainMessage,
        });
      });
      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const deleteMessage = ({
  id,
  conversationId,
  showAllMessages,
  showInstructionMessage,
  extraFilters,
}) => {
  return async (dispatch, getState) => {
    const chatQueryVariables = getChatQueryVariables({
      id: conversationId,
      showAllMessages,
      showInstructionMessage,
      extraFilters,
    });
    const mainData = getConversationsFromCache(chatQueryVariables);
    const includeDeletedMessage = _.get(
      extraFilters,
      "includeDeletedMessage",
      false
    );

    try {
      const response = await client.mutate({
        mutation: deleteMessageMutation,
        variables: { id },
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            deleteMessage: true,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { deleteMessage },
            },
          }
        ) => {
          if (deleteMessage) {
            const indexOfElemDelete = _.findIndex(
              _.get(mainData, "messages.edges", []),
              message => {
                return message.node.id == id;
              }
            );

            const updateType = includeDeletedMessage // condition if deleted message should be shown as deleted
              ? { [indexOfElemDelete]: { node: { isDeleted: { $set: true } } } } // setting isDelete for message true
              : { $splice: [[indexOfElemDelete, 1]] }; // removing the message

            const updatedData = update(mainData, {
              messages: {
                edges: updateType,
                totalCount: {
                  $set: _.get(mainData, "messages.totalCount", 0) - 1,
                },
              },
              pinMessages: {
                edges: { $splice: [[indexOfElemDelete, 1]] },
                totalCount: {
                  $set: _.get(mainData, "pinMessages.totalCount", 0) - 1,
                },
              },
            });

            setTimeout(() => {
              writeConversationsFragment({
                ...chatQueryVariables,
                data: updatedData,
              });
            });
          }
        },
      });

      return { ...response, successful: true };
    } catch (e) {
      setTimeout(() => {
        writeConversationsFragment({
          ...chatQueryVariables,
          data: mainData,
        });
      });
      if (e.networkError) {
        return {
          successful: false,
          errors: ["toastMsgs:no_internet_connection"],
        };
      } else {
        return {
          successful: false,
          errors: ["toastMsgs:something_went_wrong"],
        };
      }
    }
  };
};

export const markConversationAsRead = ({
  id,
  excludeItemTypes,
  includeItemTypes,
}) => {
  return async () => {
    try {
      const response = await client.mutate({
        mutation: markConversationAsReadMutation,
        variables: { id, excludeItemTypes, includeItemTypes },
      });

      return { ...response, successful: true };
    } catch (e) {
      return {
        successful: false,
        errors: ["Something went wrong"],
      };
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_CHAT_INFO]: (state, action) => {
    return update(state, { chatInfo: { $set: action.data } });
  },
  [UPDATE_TEXT_BOX_FOCUS_STATE]: (state, action) => {
    return update(state, { isTextFocus: { $set: action.data } });
  },
};

const initialState = {
  isTextFocus: false,
  chatInfo: {
    parentId: null,
    parentType: "",
    conversationId: null,
    shouldMarkConversationAsRead: true,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
