import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";
import { I18nHOC, withLoader } from "UIComponents";
import UnitSelection from "AppComponents/UnitSelection";
import { getUnitTypeOptions } from "UnitPlans/modules/UnitPlanModule";
import { getOrganizationBasicCoursesQuery } from "modules/CommonQuery";
import { getOrganizationBasicCoursesFromCache } from "modules/CommonGraphqlHelpers";
import { getLabelValueForDropdown } from "modules/Services";
import classes from "./UnitSelectionWrapper.scss";

const styles = {
  cardTheme: {
    container: {
      width: "320px",
      height: "276px",
      flex: "0 0 auto",
      borderRadius: "6px",
    },
    imageContainer: {
      height: "200px",
    },
  },
  unitFeedStyle: {
    itemFeedContainer: {
      gridTemplateColumns: "repeat(auto-fill, 320px)",
      gap: "24px",
    },

    containerStyle: {
      maxWidth: "100%",
      overflowY: "auto",
      paddingTop: "48px",
      alignItems: "center",
    },
    headerContainerStyle: {
      width: "100%",
      maxWidth: "1008px",
      marginLeft: "auto",
      marginRight: "auto",
      margin: "40px 0",
    },
    feedContainerStyle: {
      maxWidth: "1008px",
      margin: "0 auto",
      minHeight: "100%",
    },
  },
  customSearchBarStyle: {
    maxWidth: "280px",
  },
};

const CustomHeaderElement = ({ count, t }) => {
  return (
    <div className={classes.customHeaderElement}>
      {t("common:label_unit_plural", {
        count: count,
      })}
    </div>
  );
};

const UnitSelectionWithLoader = withLoader(UnitSelection);

const UnitSelectionWrapper = props => {
  const {
    t,
    courseId,
    organizationId,
    curriculumType,
    coursesList,
    selectedItems,
    setSelectedItems,
    selectedCourses,
    selectedUnitTypes,
    isData,
    isLoading,
    curriculumId,
  } = props;

  const [searchText, setSearchText] = useState("");

  const unitTypeOptions = getUnitTypeOptions({
    organizationId,
    curriculumType,
    t,
  });

  const unitTypeOptionsArr = _.map(unitTypeOptions, option => ({
    ...option,
    key: option.value,
  }));

  useEffect(() => {
    const unitTypesValues = _.map(unitTypeOptionsArr, ({ value }) => value);
    if (_.isEmpty(selectedCourses) && _.isEmpty(selectedUnitTypes)) {
      setSelectedItems(prevItems => {
        return {
          ...prevItems,
          selectedUnitTypes: unitTypesValues,
          selectedCourses: [courseId],
        };
      });
    }
  }, []);

  const updateSelectedUnitTypes = unitType => {
    setSelectedItems(prevItems => {
      return {
        ...prevItems,
        selectedUnitTypes: unitType,
      };
    });
  };

  const updateSelectedCourses = courses => {
    setSelectedItems(prevItems => {
      return {
        ...prevItems,
        selectedCourses: courses,
      };
    });
  };

  const updateSelectedItems = params => {
    const { id, templateId, title, subTitle, image } = params;
    setSelectedItems(prev => {
      return {
        ...prev,
        unitPlanId: id,
        unitPlanTemplateId: templateId,
        unitPlanTitle: title,
        unitPlanSubTitle: subTitle,
        unitPlanImage: image,
      };
    });
  };

  const handleUnitSearch = value => setSearchText(value);

  const coursesLabelValue = getLabelValueForDropdown({
    selectedItems: selectedCourses,
    itemList: coursesList,
    t,
    noneSelectedText: t("common:select"),
  });

  const unitTypeLabelValue = getLabelValueForDropdown({
    selectedItems: selectedUnitTypes,
    itemList: unitTypeOptionsArr,
    t,
    noneSelectedText: t("common:select"),
  });

  return (
    <div className={classes.wrapperContainer}>
      <UnitSelectionWithLoader
        updateSelectedItems={(id, templateId, title, subTitle, image) =>
          updateSelectedItems({ id, templateId, title, subTitle, image })
        }
        cardDimensions={{ width: 320, height: 200 }}
        customHeaderElement={<CustomHeaderElement t={t} />}
        selectedItems={selectedItems.unitPlanId}
        courseId={courseId}
        isSearchEnabled={true}
        showFilters={true}
        customSearchBarStyle={styles.customSearchBarStyle}
        cardTheme={styles.cardTheme}
        styles={styles.unitFeedStyle}
        unitTypeLabelValue={unitTypeLabelValue}
        coursesLabelValue={coursesLabelValue}
        unitTypeDropdownOptionsArr={unitTypeOptionsArr}
        coursesDropdownOptionsArr={coursesList}
        courses={selectedCourses}
        unitType={selectedUnitTypes}
        updateSelectedUnitTypes={updateSelectedUnitTypes}
        updateSelectedCourses={updateSelectedCourses}
        handleUnitSearch={handleUnitSearch}
        parentType={"EVIDENCE"}
        queryType={"ORGANIZATION"}
        parentId={organizationId}
        searchText={searchText}
        filters={{
          searchText,
          courses: selectedCourses,
          ...(_.size(selectedUnitTypes) === 1 && {
            type: _.first(selectedUnitTypes),
          }),
          curriculumProgramIds: [curriculumId],
        }}
        isData={isData}
        isLoading={isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { selectedItems } = ownProps;
  const courseId = state.teacher.selected_class.selected_course;
  const organizationId = state.login.userInfo.org_id;

  const curriculumPrograms = state.platform.organizationCurriculumPrograms;
  const curriculumType = state.platform.currentCurriculumProgramType;
  const currentCurriculumProgram = _.find(curriculumPrograms, {
    type: curriculumType,
  });
  const curriculumId = _.get(currentCurriculumProgram, "id", "");

  const selectedCourses = _.get(selectedItems, "selectedCourses", []);
  const selectedUnitTypes = _.get(selectedItems, "selectedUnitTypes", []);

  return {
    courseId,
    organizationId,
    curriculumId,
    curriculumType,
    isData: true,
    isLoading: false,
    selectedCourses,
    selectedUnitTypes,
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, null),
  graphql(getOrganizationBasicCoursesQuery, {
    name: "getOrganizationBasicCourses",
    options: ({ organizationId, curriculumId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        filters: {
          curriculumProgramIds: curriculumId,
        },
      },
    }),
    props({
      getOrganizationBasicCourses,
      ownProps: { organizationId, curriculumId, isData, isLoading },
    }) {
      const data = getOrganizationBasicCoursesFromCache({
        organizationId,
        filters: {
          curriculumProgramIds: curriculumId,
        },
      });
      const courses = _.get(data, "courses", {});

      const coursesList = _.map(_.get(courses, "edges", []), ({ node }) => {
        const { id, title } = node;
        return { key: id, label: title, value: id };
      });

      const networkStatus = _.get(getOrganizationBasicCourses, "networkStatus");
      return {
        coursesList,
        isData: !_.isEmpty(data) && isData,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  })
)(UnitSelectionWrapper);
