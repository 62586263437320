import gql from "graphql-tag";
import {
  studentFragment,
  staffFragment,
  organizationAttendanceOptionFragment,
} from "modules/CommonFragments";

export const studentAttendanceFragment = {
  studentAttendance: gql`
    fragment studentAttendanceItem on StudentDateAttendance {
      student {
        id
      }
      remark
      value {
        ...organizationAttendanceOptionItem
      }
      date
      id
      period {
        id
        label
      }
    }
    ${organizationAttendanceOptionFragment.organizationAttendanceOption}
  `,
};

export const selectedPeriodFragment = {
  selectedPeriod: gql`
    fragment selectedPeriodItem on SelectedPeriod {
      id
      period {
        id
        label
      }
      rotationDay
      day
      teachers {
        ...staffItem
      }
      isSelected
      location
      subject
    }
    ${staffFragment.basicStaffDetails}
  `,
};

export const studentOverAllAttendanceFragment = {
  studentOverAllAttendance: gql`
    fragment studentOverAllAttendanceItem on CourseAttendanceSummary {
      totalDays
      studentAttendanceSummary {
        student {
          id
        }
        attendanceCount {
          totalCount
          value {
            ...organizationAttendanceOptionItem
          }
        }
      }
    }
    ${organizationAttendanceOptionFragment.organizationAttendanceOption}
  `,
};

export const courseAttendanceFragment = {
  courseAttendance: gql`
    fragment courseAttendanceItem on Course {
      id
      title
      students(filters: { searchText: $searchText, archivalState: ALL }) {
        edges {
          node {
            ...studentItem
          }
        }
      }
      studentAttendanceSummary(
        filters: {
          searchText: $searchText
          dates: { startDate: $startDate, endDate: $endDate }
          periodIds: $periodIds
        }
      ) {
        ...studentAttendanceItem
      }
      attendanceSummary(
        filters: {
          searchText: $searchText
          dates: { startDate: $courseStartDate, endDate: $courseEndDate }
        }
      ) {
        ...studentOverAllAttendanceItem
      }
    }
    ${studentFragment.basicStudentDetails}
    ${studentAttendanceFragment.studentAttendance}
    ${studentOverAllAttendanceFragment.studentOverAllAttendance}
  `,
  courseSingleDateAttendance: gql`
    fragment courseAttendanceItem on Course {
      id
      title
      students {
        edges {
          node {
            ...studentWithFamilyMembersItem
          }
        }
      }
      studentAttendanceSummary(
        filters: {
          dates: { startDate: $date, endDate: $date }
          showDeletedRemark: $showDeletedRemark
          periodIds: $periodIds
          courseIds: $courseIds
        }
      ) {
        ...studentAttendanceItem
      }
    }
    ${studentFragment.basicStudentDetailsWithFamilyMembers}
    ${studentAttendanceFragment.studentAttendance}
  `,
  coursePeriodList: gql`
    fragment coursePeriodListItem on Course {
      periods(filters: $filters) {
        ...selectedPeriodItem
      }
    }
    ${selectedPeriodFragment.selectedPeriod}
  `,
  coursePeriodListV2: gql`
    fragment coursePeriodListItemV2 on Course {
      periodsV2(filters: $filters) {
        edges {
          node {
            ...selectedPeriodItem
          }
        }
        edgeInfo {
          lastModifiedAt
          lastModifiedBy {
            ...staffItem
          }
        }
      }
    }
    ${selectedPeriodFragment.selectedPeriod}
    ${staffFragment.basicStaffDetails}
  `,
};
