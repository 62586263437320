/**-- external -- */
import React from "react";
import PropTypes from "prop-types";

/**-- internal -- */
import { useI18n } from "Hooks";

/**-- relative -- */
import classes from "./FilterSelectionText.scss";
import { findLabel } from "./FilterSelectionTextUtils";

const FilterSelectionText = ({ values, options }) => {
  const optionLabel = findLabel({ value: _.first(values), options });

  const totalValues = optionLabel ? _.size(values) : 0;
  const totalOptions = _.size(options);

  const { t } = useI18n(["common"]);

  switch (totalValues) {
    case 0: {
      return t("common:select");
    }
    case 1: {
      return <div className={classes.selectedOptions}>{optionLabel}</div>;
    }
    case totalOptions: {
      return t("common:all");
    }
    default: {
      return (
        <div className={classes.moreThanOneOptionSelected}>
          <div className={classes.selectedOptions}>{`${optionLabel}`}</div>
          <div className={classes.moreTextContainer}>{`, ${t(
            "common:number_more",
            {
              number: totalValues - 1,
            }
          )} `}</div>
        </div>
      );
    }
  }
};

export default FilterSelectionText;

FilterSelectionText.defaultProps = {
  values: [],
  options: [],
};

FilterSelectionText.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
};
