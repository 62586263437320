import React from "react";
import classes from "./ActionBar.scss";
import { connect } from "react-redux";
import { getCountString } from "Utils";
import { UIButton, I18nHOC } from "UIComponents";
import { CloseIcon } from "SvgComponents";

import { colors } from "Constants";
const ActionIcons = [
  {
    action: "REJECT_ALL",
    label: "journal:return_all",
    color: colors.gray48,
    buttonType: "text",
  },
  {
    action: "APPROVE_ALL",
    label: "journal:approve_all",
    color: "blue",
    buttonType: "normal",
  },
];

const ActionButtons = React.memo(props => {
  const { onActionClick, onCloseClick, t } = props;
  return (
    <div className={classes.actionButtonsContainer}>
      {_.map(ActionIcons, item => {
        return item.buttonType == "normal" ? (
          <UIButton
            key={item.action}
            size={"sm"}
            type={"filled"}
            color={item.color}
            onClick={() => onActionClick({ action: item.action })}
          >
            {t(item.label)}
          </UIButton>
        ) : (
          <div
            key={item.action}
            className={classes.buttonText}
            style={{ color: item.color }}
            onClick={() => onActionClick({ action: item.action })}
          >
            {t(item.label)}
          </div>
        );
      })}
    </div>
  );
});

class ActionBar extends React.PureComponent {
  render() {
    const {
      selectedItems,
      totalCount,
      onActionClick,
      onCloseClick,
      t,
    } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <div className={classes.countText}>
            {t("journal:count_posts_pending_approval", { count: totalCount })}
          </div>
          <div>
            <ActionButtons
              t={t}
              selectedItems={selectedItems}
              onActionClick={onActionClick}
              onCloseClick={onCloseClick}
            />
          </div>
        </div>
        <div className={classes.cancelIcon} onClick={onCloseClick}>
          <CloseIcon width={16} height={16} />
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common", "journal"] })(ActionBar);
