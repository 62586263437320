import React from "react";
import classes from "./CreatePost.scss";
import update from "immutability-helper";
import classNames from "classnames";
import {
  AddAttachment,
  AddPostDetails,
  TagStudents,
  TagUnit,
  TagResource,
  TagPYPElement,
  SharedWith,
  TagFolders,
  TagPractices,
  TagPracticesV2,
  VoiceRecorder,
  CaptureVideo,
  CaptureImage,
  ReorderImages,
} from "./components/Screens";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import {
  updatePost,
  resetPost,
  updateOrEditPost,
  updateFolderArray,
  createMedia,
  updateAssignmentToJournal,
  updateStudentListFilters,
} from "Post/modules/PostModule";
import {
  DialogueBoxV2,
  withLoader,
  I18nHOC,
  FullScreenLoader,
  UIModal,
} from "UIComponents";
import { setToastMsg } from "Login/modules/LoginModule";
import { getPostDetailQuery } from "Post/modules/PostQuery";
import { getUserBasicDetailsFromCache } from "modules/CommonGraphqlHelpers";

import { getCourseFeedDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import ACLStore from "lib/aclStore";
import { Workbook } from "AppComponents";
import { getAttachment } from "modules/Services";
import {
  WORKBOOK_MODE_TYPES,
  getAttachmentsByTypeFromAttachmentArr,
} from "Utils";
import getEvaluationCyclesGraphql from "SnP_v2/modules/EnhanceQueries/getEvaluationCyclesGraphql";

import {
  SNPv2_TEACHER_MODULE_PERM,
  SNPv2_PERM,
} from "Constants/permissionConstants";
import { editStudentAssignmentSubmission } from "ClassRoom/modules/ClassRoomModule";
import { writeStudentAssigmentSubmissionInCache } from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import { hasTranslationPermission, getPostTranslationFilter } from "Post/utils";

const DIALOGS_INFO = {
  DISCARD: {
    title: "journal:discard_dialog_title",
    message: "journal:discard_dialog_message",
    confirmButtonText: "common:continue_editing_agree",
    cancelButtonText: "common:discard",
    button1CancelProps: {
      variant: "destructive",
      size: "large",
    },
    button2ConfirmProps: {
      variant: "progressive",
      size: "large",
    },
  },
};

const STEP_DETAILS = {
  CREATE_POST: [
    {
      title: "create_post",
      containerStyle: { maxWidth: 900 },
      screen: props => <AddAttachment screenPanelProps={props} />,
    },
  ],
  CAPTURE_VIDEO: [
    {
      title: "",
      screen: props => <CaptureVideo screenPanelProps={props} />,
    },
  ],
  REORDER_IMAGES: [
    {
      title: "",
      containerStyle: { maxWidth: 800 },
      screen: props => <ReorderImages screenPanelProps={props} />,
    },
  ],
  CAPTURE_IMAGE: [
    {
      title: "",
      screen: props => <CaptureImage screenPanelProps={props} />,
    },
  ],
  POST_DETAIL: [
    {
      title: "",
      containerStyle: { maxWidth: 632, padding: "32px 0" },
      showPreview: false,
      screen: props => <AddPostDetails screenPanelProps={props} />,
    },
  ],
  EDIT_POST: [
    {
      title: "edit_post",
      showPreview: false,
      containerStyle: { maxWidth: 632, padding: "32px 0" },
      screen: props => <AddPostDetails screenPanelProps={props} />,
    },
  ],
  TAG_STUDENT: [
    {
      title: "tag_students",
      screen: props => <TagStudents screenPanelProps={props} />,
    },
  ],
  TAG_PYP_ELEMENT: [
    {
      title: "tag_pyp_elements",
      screen: props => <TagUnit screenPanelProps={props} />,
    },
    {
      title: "select_learning_goals_from_unit",
      screen: props => <TagResource screenPanelProps={props} />,
    },
    {
      title: "tag_PYP_elements_stage",
      screen: props => <TagPYPElement screenPanelProps={props} />,
    },
  ],
  SHARE_WITH: [
    {
      title: "shared_with",
      screen: props => <SharedWith screenPanelProps={props} />,
    },
  ],
  TAG_FOLDERS: [
    {
      title: "tag_folders",
      screen: props => <TagFolders screenPanelProps={props} />,
    },
  ],
  TAG_PRACTICES: [
    {
      title: "tag_practices",
      screen: props => <TagPractices screenPanelProps={props}></TagPractices>,
    },
  ],
  TAG_PRACTICES_V2: [
    {
      title: "tag_practices",
      screen: props => (
        <TagPracticesV2 screenPanelProps={props}></TagPracticesV2>
      ),
    },
  ],
  VOICE_RECORDER: [
    {
      title: "",
      screen: props => <VoiceRecorder screenPanelProps={props} />,
    },
  ],
};
class CreatePost extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: "CREATE_POST",
      stepCurrentIndexes: {},
      stepList: [],

      currentDialogAction: "",

      isReorderEdit: false,
      isFullScreenLoading: false,
      workbookMode: null,
    };
  }

  isSnpV2Enabled =
    ACLStore.can(SNPv2_PERM) && ACLStore.can(SNPv2_TEACHER_MODULE_PERM);

  UNSAFE_componentWillMount = () => {
    const {
      resetPost,
      mode,
      updatePost,
      currentStep,
      isInnerModal,
      postEditDetail,
      setToastMsg,
      userInfo: { id: userId, userEntityType, user_type: userType },
      postCreateDetail,
      curriculumProgramId,
    } = this.props;

    //Handled if post is deleted

    if (_.get(postEditDetail, "isDeleted", false)) {
      this.onCloseClick();
      setToastMsg("toastMsgs:trying_to_access_deleted_post");
    } else {
      const updatedCurrentStep = currentStep
        ? currentStep
        : mode == "edit"
        ? "EDIT_POST"
        : "CREATE_POST";

      this.goToStep(updatedCurrentStep);
      if (!isInnerModal) {
        resetPost();

        this.setPostDetailsInStore();

        // Set student user by default tagged when creates a post
        if (mode == "create") {
          if (userType == "student") {
            updatePost({
              curriculumProgramId,
              students: {
                edges: [
                  {
                    node: getUserBasicDetailsFromCache({
                      id: userId,
                      type: userEntityType,
                    }),
                    __typename: "StudentsEdge",
                  },
                ],
              },
            });
          } else {
            updatePost({ curriculumProgramId });
          }
        }
        if (postCreateDetail) {
          updatePost({ ...(postCreateDetail || {}), curriculumProgramId }); // set post create detail in redux if passed from parent component
        }
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      postEditDetail,
      setToastMsg,
      isInnerModal,
      updatePost,
    } = this.props;
    const { currentStep } = this.state;
    const isDeleted = _.get(postEditDetail, "isDeleted", false);

    //Handled if post is deleted and data is already in cache
    if (
      _.get(prevProps, "postEditDetail.isDeleted", false) != isDeleted &&
      isDeleted
    ) {
      this.onCloseClick();
      setToastMsg("toastMsgs:trying_to_access_deleted_post");
    }
    if (prevProps.isLoading != this.props.isLoading && !isInnerModal) {
      this.setPostDetailsInStore();
    }

    if (
      currentStep === "CREATE_POST" &&
      prevState.currentStep !== "CREATE_POST"
    ) {
      updatePost({ attachments: [] });
    }
  };

  componentWillUnmount = () => {
    const { updateStudentListFilters } = this.props;
    updateStudentListFilters({ searchText: "" });
  };

  addPostAttachment = attachment => {
    const {
      updatePost,
      postDetails: { attachments = [] },
    } = this.props;

    updatePost({
      attachments: [
        _.omit(attachment, ["streamUrl", "__typename", "isPost", "isRead"]),
        ...attachments,
      ],
    });
  };

  updatePostAttachments = newAttachment => {
    const {
      updatePost,
      postDetails: { attachments = [] },
    } = this.props;
    const filteredAttachments = _.filter(
      attachments,
      attachment =>
        _.get(attachment, "id", "") !== _.get(newAttachment, "id", "")
    );
    const newAttachments = [
      _.omit(newAttachment, ["__typename", "streamUrl", "isRead"]),
      ...filteredAttachments,
    ];
    updatePost({
      attachments: newAttachments,
    });
  };

  onWorkbookUpdateSuccessfully = async attachment => {
    if (attachment) {
      const newAttachment = { ..._.omit(attachment, ["streamUrl", "isRead"]) };
      this.addPostAttachment(newAttachment);
      this.goToStep("POST_DETAIL");
    } else {
      const {
        postDetails: { attachments },
      } = this.props;
      const sentAttachmentId = _.get(
        _.first(
          getAttachmentsByTypeFromAttachmentArr({
            attachmentsArr: attachments,
            type: "AUDIO",
            except: true,
          })
        ),
        "id",
        ""
      );
      const fetchedAttachment = await getAttachment({ id: sentAttachmentId });
      this.updatePostAttachments(fetchedAttachment);
    }
  };

  onWorkbookDiscardDirectly = () => {
    this.setWorkbookMode(null);
  };

  setPostDetailsInStore = () => {
    const { postEditDetail, mode, updatePost } = this.props;
    if (mode == "edit") {
      const { resourceItem, resourceType } = postEditDetail;
      let isResourceSkipped = false;

      if (!_.get(resourceItem, "id", "")) {
        isResourceSkipped = true;
      }

      const updatedPostEditDetail = {
        ..._.omit(postEditDetail, ["taggedPractices"]),
        taggedPractices: _.get(postEditDetail, "taggedPractices", []),
      };

      updatePost({
        ...updatedPostEditDetail,
        isResourceSkipped,
        resourceItem: !_.isEmpty(resourceItem)
          ? { ...resourceItem, type: resourceType }
          : null,
      });
    }
  };

  getNormalizedPostDetails = postDetails => {
    const {
      id,
      attachments = [],
      elementPYP = [],
      folders = [],
      students = {},
      resourceType = null,
      resourceItem = {},
      unitPlan = {},
      title = "",
      isPrivate = true,
      isHiddenFromStudents = true,
      taggedPractices = [],
    } = postDetails || {};

    const firstAttachment = _.first(attachments);
    const attachmentType = _.get(firstAttachment, "type", "");
    const isNote = attachmentType === "NOTE";
    const attachmentContent = _.get(
      firstAttachment,
      isNote ? "title" : "url",
      ""
    );

    const taggedElementsValue = _.flatten(
      _.map(elementPYP, element => element.value)
    );
    const folderIds = _.map(folders, folder => folder.id);

    const studentEdges = _.get(students, "edges", []);
    const studentIds = _.map(studentEdges, student => student?.node?.id);

    const resourceId = _.get(resourceItem, "id");

    const unitPlanId = _.get(unitPlan, "id");

    const practiceIds = _.flatten(
      _.map(taggedPractices, evaluationCycleObject => {
        const { taggedPractice } = evaluationCycleObject;
        return _.map(taggedPractice, practiceObject => {
          return _.get(practiceObject, "practice.id");
        });
      })
    );

    return {
      id,
      attachmentContent,
      taggedElementsValue,
      folderIds,
      studentIds,
      title,
      isPrivate,
      isHiddenFromStudents,
      resourceId,
      resourceType,
      unitPlanId,
      practiceIds,
    };
  };

  didPostDetailChange = () => {
    const {
      postDetails: postLocalObject,
      postEditDetail: postServerObject,
    } = this.props;
    const normalizedLocalPost = this.getNormalizedPostDetails(postLocalObject);
    const normalizedServerPost = this.getNormalizedPostDetails(
      postServerObject
    );
    const isChanged = !_.isEqual(normalizedLocalPost, normalizedServerPost);
    return isChanged;
  };

  onCloseClick = () => {
    const { stepList } = this.state;

    if (_.get(stepList, "length") > 0) {
      const lastStep = _.last(stepList);
      if (_.includes(["POST_DETAIL", "EDIT_POST"], lastStep)) {
        const postDetailsChanged = this.didPostDetailChange();
        if (postDetailsChanged) {
          this.triggerDialogAction("DISCARD");
          return;
        }
      }
    }

    if (_.get(stepList, "length") > 1) {
      this.goToPreviousStep();
    } else {
      this.props.onClose("CLOSE");
    }
  };

  onSendForApprovalClick = async () => {
    const {
      updateOrEditPost,
      updatePost,
      postDetails: { state },
      postCreateDetail,
    } = this.props;
    const courseIdFromParent = _.get(postCreateDetail, "courseId", "");

    updatePost({ isPrivate: false, isHiddenFromStudents: false });
    try {
      updatePost({ state: "IN_APPROVAL" });
      this.setState({ isFullScreenLoading: true });
      await updateOrEditPost({
        isSnpV2Practices: this.isSnpV2Enabled,
        courseIdFromParent,
      });
      this.props.onClose();
    } catch (e) {
      console.error(e);
      updatePost({ state: state });
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  publishDraftPost = async () => {
    const { updateOrEditPost, updatePost, postCreateDetail } = this.props;

    const courseIdFromParent = _.get(postCreateDetail, "courseId", "");

    updatePost({ state: "PUBLISHED" });
    await updateOrEditPost({
      isSnpV2Practices: this.isSnpV2Enabled,
      courseIdFromParent,
    });
    this.props.onClose();
  };

  onPublishClick = async () => {
    const {
      postDetails: { state },
      updateOrEditPost,
      onClose,
      userType,
      updatePost,
      postCreateDetail,
    } = this.props;
    const courseIdFromParent = _.get(postCreateDetail, "courseId", "");
    if (userType === "student") {
      updatePost({ isPrivate: false, isHiddenFromStudents: false });
    }
    try {
      if (state == "DRAFT") {
        this.setState({ isFullScreenLoading: true });
        await this.publishDraftPost();
      } else {
        this.setState({ isFullScreenLoading: true });
        await updateOrEditPost({
          isSnpV2Practices: this.isSnpV2Enabled,
          courseIdFromParent,
        });
        onClose();
      }
    } catch (e) {
      // console.error(e);
      updatePost({ state: state });
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  onAddAllResponsesToJournal = async () => {
    const {
      updatePost,
      updateAssignmentToJournal,
      onClose,
      studentAssignmentIds,
    } = this.props;
    try {
      updatePost({ state: "PUBLISHED" });
      this.setState({ isFullScreenLoading: true });
      await updateAssignmentToJournal({ studentAssignmentIds });
      onClose();
    } catch (e) {
      this.setState({ isFullScreenLoading: false });
    }
  };

  triggerDialogAction = action => {
    this.setState({ currentDialogAction: action });
  };

  OnToggleDialogueClick = () => {
    this.setState({ currentDialogAction: "" });
  };

  onDialogLeftButtonClick = () => {
    const { currentDialogAction, stepList } = this.state;
    const {
      updatePost,
      postDetails: { state },
      resetPost,
    } = this.props;
    const lastStep = _.last(stepList);
    try {
      switch (currentDialogAction) {
        case "DISCARD":
          if (lastStep === "POST_DETAIL") {
            resetPost();
          }
          if (_.get(stepList, "length") > 1) {
            this.goToPreviousStep();
          } else {
            this.props.onClose("CLOSE");
          }
          break;
      }
    } catch (e) {
      // console.error(e);
      updatePost({ state: state });
    }
  };

  onDraftClick = async () => {
    const { updateOrEditPost, updatePost, postCreateDetail } = this.props;
    const courseIdFromParent = _.get(postCreateDetail, "courseId", "");

    try {
      updatePost({ state: "DRAFT" });
      this.setState({ isFullScreenLoading: true });
      await updateOrEditPost({
        isSnpV2Practices: this.isSnpV2Enabled,
        courseIdFromParent,
      });
      this.props.onClose();
    } catch (e) {
      // console.error(e);
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  onMediaCreate = async () => {
    const { createMedia, parentId, parentType } = this.props;
    try {
      this.setState({ isFullScreenLoading: true });
      await createMedia({ parentId, parentType });
      this.props.onClose();
    } catch (e) {
      // console.error(e);
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  goToPreviousStep = () => {
    const { stepList, currentStep, stepCurrentIndexes } = this.state;

    const updatedStepList = _.initial(stepList);

    this.setState({
      currentStep: updatedStepList[updatedStepList.length - 1],
      stepList: updatedStepList,
      stepCurrentIndexes: update(stepCurrentIndexes, {
        [currentStep]: { $set: 0 },
      }),
    });
  };

  goToStep = stepName => {
    const { stepList } = this.state;
    const updatedStepList = [...stepList, stepName];
    this.setState({ currentStep: stepName, stepList: updatedStepList });
  };

  // goToPage = (stepName, stepIndex) => {
  //   const { stepList } = this.state;
  //   const updatedStepList = [...stepList, stepName];
  //   this.setState({
  //     currentStep: stepName,
  //     stepList: updatedStepList,
  //     stepCurrentIndexes: stepIndex
  //   });
  // };

  goToFinalScreen = () => {
    const { stepCurrentIndexes, currentStep } = this.state;
    this.setState({
      stepCurrentIndexes: update(stepCurrentIndexes, {
        [currentStep]: { $set: 2 },
      }),
      selectFromAllPYP: true,
    });
  };

  goToNextScreen = () => {
    const { stepCurrentIndexes, currentStep } = this.state;
    const currentIndex = this.getCurrentStepScreenIndex();
    const stepDetails = this.getCurrentStepDetail();
    const stepScreensLength = _.get(stepDetails, "length", 0);

    if (currentIndex >= stepScreensLength - 1) {
      this.onCloseClick();
      return;
    }

    this.setState({
      stepCurrentIndexes: update(stepCurrentIndexes, {
        [currentStep]: { $set: currentIndex + 1 },
      }),
    });
  };

  goToPreviousScreen = () => {
    const { updatePost } = this.props;
    const { stepCurrentIndexes, currentStep, selectFromAllPYP } = this.state;
    const currentIndex = this.getCurrentStepScreenIndex();
    if (currentIndex < 1) {
      return;
    }

    this.setState({
      stepCurrentIndexes: update(stepCurrentIndexes, {
        [currentStep]: { $set: selectFromAllPYP ? 0 : currentIndex - 1 },
      }),
      selectFromAllPYP: false,
    });
    updatePost({ selectFromAllPYP: false });
  };

  getCurrentStepScreenIndex = () => {
    const { currentStep, stepCurrentIndexes } = this.state;
    return _.get(stepCurrentIndexes, currentStep, 0);
  };

  getCurrentStepDetail = () => {
    const { currentStep } = this.state;
    return STEP_DETAILS[currentStep];
  };

  getCurrentScreenDetail = () => {
    const currentStepDetail = this.getCurrentStepDetail();
    const currentStepIndex = this.getCurrentStepScreenIndex();
    return currentStepDetail[currentStepIndex] || {};
  };

  addWorkbookMediaOption = mediaOptions => {
    return [
      ..._.slice(mediaOptions, 0, 4),
      "WORKBOOK",
      ..._.slice(mediaOptions, 4, mediaOptions.length),
    ];
  };

  renderScreen = () => {
    const {
      title,
      showPreview,
      screen,
      containerStyle,
    } = this.getCurrentScreenDetail();
    const currenStepScreenIndex = this.getCurrentStepScreenIndex();
    const { stepList, workbookMode } = this.state;
    const {
      postDetails,
      updatePost,
      updateFolderArray,
      mode,
      t,
      isInnerModal,
      mediaCreationOptions,
      createPostTitle,
      totalNewSnpCycles,
      curriculumProgramId,
      postCreateDetail,
    } = this.props;

    const items = _.get(postDetails, "items", []);
    const responseData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );
    const item = _.get(responseData, "item", {});
    const itemContent = _.get(item, "assignment.content", {});

    const { creationTitle, isPost } = mediaCreationOptions;

    //To show different screen title for non post medias
    const updatedTitle = !isPost
      ? creationTitle
      : mode == "create"
      ? "create_post"
      : "edit_post";

    const props = {
      onCloseClick: this.onCloseClick,
      goToNextScreen: this.goToNextScreen,
      goToFinalScreen: this.goToFinalScreen,
      goToFirstScreen: this.goToFirstScreen,
      containerStyle,
      modalHeaderTitle: title ? t(title) : title,
      screenTitle: createPostTitle
        ? createPostTitle
        : updatedTitle
        ? t(updatedTitle)
        : updatedTitle,
      showScreenTitle: _.get(stepList, "length", 0) == 1, //Show only in the first step
      showBack: currenStepScreenIndex > 0,
      goToPreviousScreen: this.goToPreviousScreen,
      showPreview: showPreview,
      updatePost: updatePost,
      isInnerModal: isInnerModal,
      updateFolderArray: updateFolderArray,
      postDetails: postDetails,
      mediaCreationOptions,
      goToStep: this.goToStep,
      onSendForApprovalClick: this.onSendForApprovalClick,
      onPublishClick: this.onPublishClick,
      onDraftClick: this.onDraftClick,
      onMediaCreate: this.onMediaCreate,
      onAddAllResponsesToJournal: this.onAddAllResponsesToJournal,
      mode: mode,
      isSnpV2Enabled: this.isSnpV2Enabled,
      curriculumProgramId,
      totalNewSnpCycles,
      workbookMode,
      setWorkbookMode: this.setWorkbookMode,
      isAddLEToJournal: _.get(postDetails, "isAddLEToJournal"),
      assessmentId:
        _.get(postDetails, "assessmentId") || _.get(itemContent, "id", ""),
      LETitle:
        _.get(postDetails, "LETitle", "") ||
        _.get(itemContent, "title.value", ""),
      assessmentType:
        _.get(postDetails, "assessmentType") ||
        _.get(itemContent, "assessmentType.value", "le"),
    };

    const { currentDialogAction } = this.state;

    return (
      <React.Fragment>
        {screen ? screen(props) : null}
        {!!currentDialogAction && (
          <DialogueBoxV2
            modalTitle={t(DIALOGS_INFO[currentDialogAction].title)}
            showModal={true}
            onClickButton2={this.onDialogConfirmClick}
            onClickButton1={this.onDialogLeftButtonClick}
            modalBody={t(DIALOGS_INFO[currentDialogAction].message)}
            toggleDialogueBoxDisplay={this.OnToggleDialogueClick}
            button1={
              t(DIALOGS_INFO[currentDialogAction].cancelButtonText) ||
              t("common:cancel")
            }
            button2={t(DIALOGS_INFO[currentDialogAction].confirmButtonText)}
            isPromptBox={DIALOGS_INFO[currentDialogAction].isPromptBox}
            promptDetail={t(DIALOGS_INFO[currentDialogAction].promptDetail)}
            button1Props={{
              ...DIALOGS_INFO[currentDialogAction].button1CancelProps,
            }}
            button2Props={{
              ...DIALOGS_INFO[currentDialogAction].button2ConfirmProps,
            }}
          />
        )}
      </React.Fragment>
    );
  };

  onReoderImageModalClose = isDone => {
    const { isReorderEdit } = this.state;
    const { updatePost } = this.props;

    if (!isReorderEdit && isDone) {
      this.goToStep("POST_DETAIL");
    } else if (!isReorderEdit) {
      updatePost({ attachments: [] });
    }
    this.setState({ showReorderModal: false });
  };

  renderWorkbook = () => {
    const {
      postDetails: { attachments },
    } = this.props;
    const attachmentForWorkbook =
      _.first(
        getAttachmentsByTypeFromAttachmentArr({
          attachmentsArr: attachments,
          type: "WORKBOOK",
        })
      ) || {};
    return (
      <Workbook
        workbookMode={WORKBOOK_MODE_TYPES["WORKBOOK_MULTIPAGE_ADD_OR_EDIT"]}
        attachment={attachmentForWorkbook}
        onUpdateSuccessfully={this.onWorkbookUpdateSuccessfully}
        onDiscardDirectly={this.onWorkbookDiscardDirectly}
      />
    );
  };

  setWorkbookMode = mode => {
    this.setState({
      workbookMode: mode,
    });
  };

  render() {
    const { isFullScreenLoading, workbookMode } = this.state;

    const { isInnerModal, isCurrentAcademicYearSelected } = this.props;
    const modalContentClass = classNames({
      [classes.modalContent]: isInnerModal,
    });
    const containerClasses = classNames({
      [classes.container]: true,
      [classes.adjustedHeight]: !isCurrentAcademicYearSelected,
      [classes.fullHeight]: isCurrentAcademicYearSelected,
    });
    return (
      <UIModal
        isFullScreen={!isInnerModal}
        isOpen={true}
        modalContent={modalContentClass}
      >
        <div className={containerClasses}>
          {/* {this.renderScreen()} */}
          {!workbookMode && this.renderScreen()}
          <div className={classes.fixedDiv} />
          {workbookMode && this.renderWorkbook()}
          {isFullScreenLoading && <FullScreenLoader />}
        </div>
      </UIModal>
    );
  }
}

export { CreatePost as CreatePostBase };

const mapActionCreators = {
  updatePost,
  resetPost,
  updateOrEditPost,
  updateFolderArray,
  createMedia,
  setToastMsg,
  updateAssignmentToJournal,
  editStudentAssignmentSubmission,
  updateStudentListFilters,
};
const mapStateToProps = (state, ownProps) => {
  const selected_class = state.teacher.selected_class;
  const courseDetails = getCourseFeedDetailsFromCache(
    selected_class.selected_course
  );

  const curriculum = _.get(courseDetails, "curriculumProgram", {});

  const isCurrentAcademicYearSelected = _.get(
    state,
    "platform.academicYearSelected.isCurrentAcademicYear",
    true
  );

  const translationFilter = getPostTranslationFilter({
    userInfo: _.get(state, "login.userInfo", {}),
    shouldTranslate: false,
    handleTranslation: hasTranslationPermission(),
  });

  return {
    userInfo: state.login.userInfo,
    postDetails: state.post.postDetails,
    mode: ownProps.postId ? "edit" : "create",
    isData: true,
    isLoading: false,
    showLoaderBar: false,
    userType: state.login.userInfo.user_type,
    curriculumProgramId:
      _.get(ownProps, "postCreateDetail.curriculumProgramId") ||
      _.get(curriculum, "id"),
    isCurrentAcademicYearSelected,
    organizationId: state.login.userInfo.org_id,
    loginActiveTab: _.get(state, "login.activeTab", ""),
    translationFilter: translationFilter,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getPostDetailQuery, {
    name: "getPostDetail",
    skip: ({ postId }) => !postId,
    options: ({ postId, translationFilter }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: postId,
        translationFilter: translationFilter,
      },
    }),
    props({ getPostDetail, ownProps: { postId, isData, isLoading } }) {
      return {
        isData:
          _.isEqual(postId, _.get(getPostDetail, "node.id", false)) && isData,
        networkStatus: getPostDetail.networkStatus,
        isLoading:
          getPostDetail["networkStatus"] == 1 ||
          getPostDetail["networkStatus"] == 2 ||
          getPostDetail["networkStatus"] == 4 ||
          isLoading,
        postEditDetail: getPostDetail.node,
      };
    },
  }),
  I18nHOC({ resource: ["journal", "common", "toastMsgs"] }),
  getEvaluationCyclesGraphql,
  withLoader
)(CreatePost);

//MediaCreationOptions to handle non post medias
CreatePost.defaultProps = {
  totalNewSnpCycles: 0,
  mediaCreationOptions: {
    enableTagging: true,
    enableCaption: true,
    isPost: true,
    addButtonLabel: "",
    creationTitle: "",
    addMediaOptions: [
      "PHOTO",
      "VIDEO",
      "VOICE",
      "FILE",
      "WORKBOOK",
      "NOTE",
      "LINK",
    ],
    showInstructionsWithVoice: false,
  },
  studentAssignmentIds: [],
};
