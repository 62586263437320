import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";
import Supervisors from "./routes/Supervisors";
import Students from "./routes/Students";
import Projects from "./routes/Projects";
import StudentCommunityProject from "./routes/StudentCommunityProject";
import { injectReducer } from "store/reducers";

const {
  invite: { path },
} = routes;

const InviteRoute = store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components/Invite").default;
              const reducer = require("./modules/InviteModules");
              injectReducer(store, {
                key: reducer.NAME,
                reducer: reducer.default,
              });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "invite"
          );
        })
    ),
    childRoutes: [
      Supervisors(store),
      Students(store),
      Projects(store),
      StudentCommunityProject(store),
    ],
  };
};
export default InviteRoute;
