import { SOCIAL_TYPES } from "Constants";

export const getSharerURL = (type, url, text) => {
  const encodedPageURL = encodeURIComponent(url);
  switch (type) {
    case SOCIAL_TYPES.FACEBOOK:
      return `https://www.facebook.com/sharer/sharer.php?u=${encodedPageURL}${
        text && `&quote=${text}`
      }`;
    case SOCIAL_TYPES.TWITTER:
      return `https://twitter.com/intent/tweet?url=${encodedPageURL}${
        text && `&text=${text}`
      }`;
    case SOCIAL_TYPES.LINKEDIN:
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedPageURL}`;
  }
};
