import React from "react";
import classes from "./ProfileModal.scss";
import { CancelIcon } from "SvgComponents";
import { colors } from "Constants";
import { I18nHOC } from "UIComponents";
const ProfileModal = props => {
  const { t } = props;
  const { name, image, content } = props.profileDetails;

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.closeBtn} onClick={props.closeEnquiryModal}>
          <CancelIcon fill={colors.gray72} />
        </div>
        <div className={classes.leftContainer}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.title}>{name}</div>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: t(content),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default I18nHOC({ resource: ["planathon"] })(ProfileModal);
