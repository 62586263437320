import { withAsyncRouteLoading } from "UIComponents";

export default () => ({
  path: "operational-days-and-time-zone",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const Component = require("./components").default;
            resolve(Component);
          },
          err => {
            reject(err);
          },
          "operational-days-and-time-zone-chunk"
        );
      })
  ),
});
