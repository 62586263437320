import PropTypes from "prop-types";
import React from "react";

const NoAttachmentIcon = props => {
  return (
    <svg viewBox="0 0 16 16" width={props.width} height={props.height}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-156.000000, -8482.000000)" fill={props.fill}>
          <g transform="translate(156.000000, 8482.000000)">
            <g transform="translate(6.600000, 3.400000)">
              <path d="M1.40809412,5.34541176 C1.06174118,5.34541176 0.780611765,5.064 0.780611765,4.71764706 L0.780611765,1.26635294 C0.780611765,0.92 1.06174118,0.639529412 1.40809412,0.639529412 C1.75444706,0.639529412 2.03548235,0.92 2.03548235,1.26635294 L2.03548235,4.71764706 C2.03548235,5.064 1.75444706,5.34541176 1.40809412,5.34541176 Z" />
              <path d="M2.14804706,7.42889412 C2.14804706,7.83792941 1.81675294,8.168 1.40894118,8.168 C0.999811765,8.168 0.668517647,7.83792941 0.668517647,7.42889412 C0.668517647,7.01976471 0.999811765,6.68969412 1.40894118,6.68969412 C1.81675294,6.68969412 2.14804706,7.01976471 2.14804706,7.42889412 Z" />
            </g>
            <path d="M8,1.333 C4.324,1.333 1.3333,4.324 1.3333,8 C1.3333,11.676 4.324,14.6667 8,14.6667 C11.676,14.6667 14.6667,11.676 14.6667,8 C14.6667,4.324 11.676,1.333 8,1.333 M8,16 C3.588,16 0,12.412 0,8 C0,3.588 3.588,0 8,0 C12.412,0 16,3.588 16,8 C16,12.412 12.412,16 8,16" />
          </g>
        </g>
      </g>
    </svg>
  );
};

NoAttachmentIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

NoAttachmentIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: "#000000",
};

export default NoAttachmentIcon;
