import React, { useState } from "react";

import classes from "./TagPractices.scss";

import ScreenPanel from "../../ScreenPanel";

import { UIButton, SearchBar } from "UIComponents";
import update from "immutability-helper";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import PracticeList from "./PracticeList";
import { I18nHOC } from "UIComponents";
import { getPracticeListDetailsFromCache } from "SnP/modules/SnPGraphqlHelpers";

const FooterComponent = React.memo(props => {
  const { onDoneClick, selectedPractices, t } = props;

  return (
    <div className={classes.footerButtonContainer}>
      <div className={classes.footerText}>
        {t("journal:count_tagged_practice", {
          count: selectedPractices.length,
        })}
      </div>
      <UIButton
        color={"pink"}
        type={"filled"}
        size={"sm"}
        onClick={onDoneClick}
      >
        {t("common:done")}
      </UIButton>
    </div>
  );
});

const TagPractices = React.memo(props => {
  const {
    screenPanelProps,
    screenPanelProps: {
      mode,
      updatePost,
      onCloseClick,
      postDetails: { practices },
    },
    t,
  } = props;

  const [searchText, changeSearchText] = useState("");

  const [selectedPractices, toggleSelectedPractices] = useState(
    _.map(practices, ({ id }) => id)
  );
  const onDoneClick = () => {
    const practices = _.map(selectedPractices, id =>
      getPracticeListDetailsFromCache({ id })
    );
    updatePost({ practices });
    onCloseClick();
  };

  const toggleSelectedPracticesLocally = ({ value, id }) => {
    if (value) {
      toggleSelectedPractices([...selectedPractices, id]);
    } else {
      const index = _.indexOf(selectedPractices, id);

      toggleSelectedPractices(
        update(selectedPractices, {
          $splice: [[index, 1]],
        })
      );
    }
  };
  return (
    <ScreenPanel
      {...screenPanelProps}
      footerComponent={
        <FooterComponent
          t={t}
          onDoneClick={onDoneClick}
          selectedPractices={selectedPractices}
        />
      }
    >
      <div className={classes.container}>
        <div className={classes.searchContainer}>
          <SearchBar
            placeholder={t("common:search_for_with_label", {
              label: t("common:practice"),
            })}
            changeSearchTerm={changeSearchText}
          />
        </div>
        <PracticeList
          searchText={searchText}
          selectedPractices={selectedPractices}
          toggleSelectedPractices={toggleSelectedPracticesLocally}
        ></PracticeList>
      </div>
    </ScreenPanel>
  );
});

const mapActionCreators = {};

const mapStateToProps = state => {
  return {};
};

export default compose(
  I18nHOC({ resource: ["journal", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(TagPractices);
