import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";

const {
  serviceAsActionInsights: { path },
} = routes;

export default store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise(resolve => {
          require.ensure(
            [],
            require => {
              const component = require("./components/ServiceAsActionInsights")
                .default;
              resolve(component);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [],
  };
};
