import React from "react";
import classes from "./Textview.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";
import { getStringAutoLinks } from "Utils";

class Textview extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.textContainer = null;
  }

  componentDidMount() {
    if (this.textContainer) {
      this.textContainer.setAttribute("dir", "auto");
    }
  }
  onClick = event => {
    if (_.get(event, "target.tagName", "").toLowerCase() === "a") {
      event.stopPropagation();
    }
  };

  renderView = () => {
    const {
      value,
      customTextStyle,
      applyDefaultClass = true,
      isWordWrapRequired = false, // This prop is required for word wrap for text area elements
    } = this.props;
    const richTextClasses = classNames({
      [classes.richText]: applyDefaultClass,
      [classes.customRichText]: !applyDefaultClass,
      [classes.whiteSpacePreWrap]: isWordWrapRequired,
    });
    return (
      <div
        className={classes.detailText}
        onClick={this.onClick}
        style={customTextStyle}
        ref={ref => (this.textContainer = ref)}
      >
        {Array.isArray(value) ? (
          _.join(value, ", ")
        ) : (
          <div
            className={richTextClasses}
            dangerouslySetInnerHTML={{
              __html: getStringAutoLinks(value),
            }}
          />
        )}
      </div>
    );
  };
}

// Textview.defaultProps = {
//   applyDefaultClass: true
// };

export default Textview;
