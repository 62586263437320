import client from "apolloClient";
import { updateUserCoursesMutation } from "./CourseMutations";
import { getPlatformUserDetailsQuery } from "modules/CommonQuery";
import { setToastMsg, getUserInfo } from "Login/modules/LoginModule";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { getYearDurationFormat } from "Utils";
import * as Sentry from "@sentry/browser";
import { getUserIdAndEntityTypeByUserMemoize } from "Teacher/modules/TeacherModule";
import ACLStore from "lib/aclStore";
import update from "immutability-helper";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";

// constants
export const NAME = "course";
export const UPDATE_COURSE_NAVIGATION_MENU =
  "UPDATE_COURSE_NAVIGATION_MENU" + " " + NAME;
export const RESET_COURSE_NAVIGATION_MENU =
  "RESET_COURSE_NAVIGATION_MENU" + " " + NAME;

//Action creators
export const updateCourseNavigationMenu = data => {
  return {
    type: UPDATE_COURSE_NAVIGATION_MENU,
    data,
  };
};

export const resetCourseNavigationMenu = () => {
  return {
    type: RESET_COURSE_NAVIGATION_MENU,
  };
};

export const getUserCoursesFilters = _.memoize(
  ({
    organizationCurriculumPrograms,
    currentCurriculumProgramId,
    archivalState = "ALL",
  }) => {
    const curriculumProgram = _.find(organizationCurriculumPrograms, {
      id: currentCurriculumProgramId,
    });
    const curriculumProgramIds = _.filter(
      curriculumProgram ? [curriculumProgram.id] : [],
      Boolean
    );
    if (_.size(curriculumProgramIds) === 0) {
      Sentry.addBreadcrumb({
        category: "console",
        level: Sentry.Severity.Info,
        message: JSON.stringify({ curriculumProgram }, null, 4),
      });
    }
    return {
      curriculumProgramIds,
      archivalState,
    };
  },
  params => JSON.stringify(params)
);

export const getUserCoursesFiltersV2 = _.memoize(
  ({
    organizationCurriculumPrograms,
    currentCurriculumProgramId,
    archivalState = "ALL",
  }) => {
    const curriculumProgram = _.find(organizationCurriculumPrograms, {
      id: currentCurriculumProgramId,
    });
    const curriculumProgramIds = _.filter(
      curriculumProgram ? [currentCurriculumProgramId] : [],
      Boolean
    );
    if (_.size(curriculumProgramIds) === 0) {
      Sentry.addBreadcrumb({
        category: "console",
        level: Sentry.Severity.Info,
        message: JSON.stringify({ curriculumProgram }, null, 4),
      });
    }
    return {
      curriculumProgramIds,
      archivalState,
    };
  },
  params => JSON.stringify(params)
);

/**
 *
 * @description returns default query vars
 */
export const getUserCoursesVars = ({
  userInfo,
  platform,
  isCurriculumProgramFree,
}) => {
  const {
    currentCurriculumProgramType,
    currentCurriculumProgram,
    organizationCurriculumPrograms,
    academicYearSelected = {},
  } = platform;
  const {
    isCurrentAcademicYear = true,
    id: academicYear = "",
  } = academicYearSelected;

  const { userId, userEntityType } = getUserIdAndEntityTypeByUserMemoize({
    userInfo,
  });

  const filters = {
    ...getUserCoursesFilters({
      currentCurriculumProgramType,
      currentCurriculumProgramId: currentCurriculumProgram.id,
      organizationCurriculumPrograms,
    }),
    ...(!isCurrentAcademicYear && { academicYear }),
  };

  if (
    userEntityType == "STUDENT" &&
    ACLStore.can("FeatureFlag:StudentArchivedClasses")
  ) {
    filters["includeRemovedCoursesForStudent"] = true;
  }

  return {
    id: userId,
    type: userEntityType,
    filters,
    isCurriculumProgramFree,
  };
};

export const updateUserCourses = ({
  removedCourses = [],
  addedCourses = [],
  portalType,
}) => {
  return async (dispatch, getState) => {
    const userInfo = getUserInfo({ portalType });
    const userId = userInfo.id;

    const state = getState();

    const courseFilters = getUserCoursesFilters({
      currentCurriculumProgramType: state.platform.currentCurriculumProgramType,
      currentCurriculumProgramId: state.platform.currentCurriculumProgram.id,
      organizationCurriculumPrograms:
        state.platform.organizationCurriculumPrograms,
    });

    try {
      await client.mutate({
        mutation: updateUserCoursesMutation,
        variables: {
          id: userId,
          removedCourses,
          addedCourses,
          portalType,
          filters: courseFilters,
          isCurriculumProgramFree: getCurriculumProgramFreeStatus({ state }),
        },
        refetchQueries: [
          {
            query: getPlatformUserDetailsQuery,
            variables: {
              id: userId,
              type: userInfo.userEntityType,
              portalType,
            },
          },
        ],
      });
    } catch (error) {
      console.error(error);
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }

      throw error;
    }
  };
};

export const getAcademicYearsListAndGradeList = ({
  organizationId,
  curriculumProgramId,
}) => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const academicYears = _.get(organizationDetails, "academicYears", []);

  const academicYearsList = _.map(academicYears, academicYear => {
    const { id, startDate, endDate } = academicYear;
    return {
      label: getYearDurationFormat({ startDate, endDate }),
      value: id,
    };
  });

  const defaultAcademicYear = _.get(
    _.find(academicYears, { isCurrentAcademicYear: true }),
    "id",
    ""
  );

  const gradeList = _.map(
    _.filter(
      _.get(organizationDetails, "grades", []),
      grade => grade.curriculumProgram.id === curriculumProgramId
    ),
    item => ({
      label: item.name,
      value: item.id,
    })
  );

  return {
    academicYearsList,
    defaultAcademicYear,
    gradeList,
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_COURSE_NAVIGATION_MENU]: (state, action) => {
    const { data } = action;
    _.forOwn(data, (value, key) => {
      state = update(state, { navigationMenu: { [key]: { $set: value } } });
    });
    return state;
  },
  [RESET_COURSE_NAVIGATION_MENU]: state => {
    const { navigationMenu } = state;
    _.forOwn(navigationMenu, (value, key) => {
      state = update(state, { navigationMenu: { [key]: { $set: [] } } });
    });
    return state;
  },
};

const initialState = {
  navigationMenu: {
    topMenuList: [],
    bottomMenuList: [],
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
