import PropTypes from "prop-types";
import React from "react";

const UploadIcon = props => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 51.872 47.94667"
      width={props.width}
      height={props.height}
    >
      <title>upload</title>
      <g id="Page-1">
        <g id="Macbook-web-Copy-7">
          <g id="Group-3">
            <g id="Group">
              <g id="upload">
                <path
                  fill={props.fill && props.fill}
                  id="Shape"
                  d="M42.18667,12.56533a15.02565,15.02565,0,0,0-27.84-4.992A8.60732,8.60732,0,0,0,12.768,7.424a8.23511,8.23511,0,0,0-8.224,8.224,8.98968,8.98968,0,0,0,.17067,1.70667A11.44414,11.44414,0,0,0,0,26.58133a12.23489,12.23489,0,0,0,3.104,8.096,11.07846,11.07846,0,0,0,7.68,3.86133h9.25867a1.44,1.44,0,1,0,0-2.88H10.912c-4.36267-.26667-8.032-4.416-8.032-9.088a8.54934,8.54934,0,0,1,4.23467-7.36A1.45176,1.45176,0,0,0,7.744,17.46133a5.22538,5.22538,0,0,1-.32-1.83467,5.35352,5.35352,0,0,1,5.344-5.344,5.25258,5.25258,0,0,1,1.824.32,1.44666,1.44666,0,0,0,1.80267-.736,12.14457,12.14457,0,0,1,23.072,4,1.43835,1.43835,0,0,0,1.19467,1.28A10.38042,10.38042,0,0,1,39.68,35.648H31.81867a1.44,1.44,0,1,0,0,2.88H39.84a12.76148,12.76148,0,0,0,8.55467-4.224,13.27589,13.27589,0,0,0-6.208-21.73867Z"
                  transform="translate(0 -0.04267)"
                />
                <path
                  fill={props.fill && props.fill}
                  id="Shape-2"
                  data-name="Shape"
                  d="M34.58133,27.94667a1.43568,1.43568,0,0,0,0-2.03733l-7.62667-7.62667a1.45708,1.45708,0,0,0-1.01333-.42667,1.41244,1.41244,0,0,0-1.01333.42667l-7.62667,7.62667a1.44538,1.44538,0,0,0,1.01333,2.464,1.40509,1.40509,0,0,0,1.01333-.42667l5.17333-5.17333v23.776a1.44,1.44,0,1,0,2.88,0v-23.776l5.17334,5.17333A1.42289,1.42289,0,0,0,34.58133,27.94667Z"
                  transform="translate(0 -0.04267)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

UploadIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

UploadIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default UploadIcon;
