import { getMemoizedFunction } from "Utils";

const getActiveTabConfig = ({ allOptions, activeTab }) => {
  return _.get(
    _.find(allOptions, option => option.key === activeTab),
    "config",
    {}
  );
};

const getSelectedEvidenceCount = ({ selectedCards }) => {
  return _.sum(_.map(selectedCards, cardType => _.size(cardType)));
};

const getSelectedEvidences = ({ selectedCards, evidenceKeyToTypeMapping }) => {
  const selectedCardKeys = _.keys(selectedCards);

  return _.flatMap(selectedCardKeys, key => {
    const evidenceTypes = evidenceKeyToTypeMapping[key];
    const currentTabSelectedCards = _.get(selectedCards, key, []);

    return _.map(currentTabSelectedCards, id => {
      return { id, type: evidenceTypes[0] };
    });
  });
};

export const getSelectedEvidencesMemoized = getMemoizedFunction(
  getSelectedEvidences
);

const getEvidenceDifference = ({
  selectedEvidences,
  taggedEvidences,
  considerAddedEvidences = true,
}) => {
  let oldEvidences, newEvidences;
  if (considerAddedEvidences) {
    newEvidences = selectedEvidences;
    oldEvidences = taggedEvidences;
  } else {
    newEvidences = taggedEvidences;
    oldEvidences = selectedEvidences;
  }
  const addedEvidences = _.filter(newEvidences, ({ id, type }) => {
    return !_.find(
      oldEvidences,
      ({ id: itemId, type: itemType }) => itemId === id && itemType === type
    );
  });
  return addedEvidences;
};

export const getEvidenceDifferenceMemoized = getMemoizedFunction(
  getEvidenceDifference
);

export const transformUpdatedEvidence = ({
  currentTabTaggedEvidences /**previously selected Evidences */,
  updatedEvidence,
}) => {
  let evidenceToUpdate;

  evidenceToUpdate = _.filter(
    updatedEvidence,
    id => !_.includes(currentTabTaggedEvidences, id)
  );

  return evidenceToUpdate;
};

export const getEvidenceKeyToTypeMapping = ({ allOptions }) => {
  return _.reduce(
    allOptions,
    (result, { key, config }) => {
      result[key] = config.evidenceTypes;
      return result;
    },
    {}
  );
};

export const getIndependentFiltersArguments = ({ curriculumProgramId }) => {
  return {
    atlOptions: { filters: { depth: 1 } },
    subjectOptions: {
      filters: { curriculumProgramIds: [curriculumProgramId] },
    },
    gradeOptions: { filters: { curriculumProgramIds: [curriculumProgramId] } },
    classOptions: { filters: { curriculumProgramIds: [curriculumProgramId] } },
  };
};

const getPreviouslySelectedCards = ({ evidenceOptions, taggedEvidences }) => {
  return _.reduce(
    evidenceOptions,
    (result, option) => {
      const evidenceTab = _.get(option, "key", "");
      const evidenceTypes = _.get(option, "config.evidenceTypes", []);

      const currentTabTaggedEvidences = _.filter(taggedEvidences, ({ type }) =>
        _.includes(evidenceTypes, type)
      );

      result[evidenceTab] = _.map(currentTabTaggedEvidences, evidence => {
        return _.get(evidence, "id", "");
      });
      return result;
    },
    {}
  );
};

const getAddButtonDisabilityStatus = ({
  initiallySelectedEvidence,
  selectedCards,
}) => {
  const initialFeedTypes = _.keys(initiallySelectedEvidence);

  const isInitialEvidencePresent = _.some(initialFeedTypes, feedType => {
    const feedEvidence = initiallySelectedEvidence[feedType];
    return !_.isEmpty(feedEvidence);
  });

  const selectedEvidenceFeedTypes = _.keys(selectedCards);

  const isEvidencePresent = _.some(selectedEvidenceFeedTypes, feedType => {
    const feedEvidence = selectedCards[feedType];
    return !_.isEmpty(feedEvidence);
  });

  return !(isEvidencePresent || isInitialEvidencePresent);
};

export const getAddButtonDisabilityStatusMemoized = getMemoizedFunction(
  getAddButtonDisabilityStatus
);

export const getActiveTabConfigMemoized = getMemoizedFunction(
  getActiveTabConfig
);

export const getSelectedEvidenceCountMemoized = getMemoizedFunction(
  getSelectedEvidenceCount
);

export const getEvidenceKeyToTypeMappingMemoized = getMemoizedFunction(
  getEvidenceKeyToTypeMapping
);

export const getPreviouslySelectedCardsMemoized = getMemoizedFunction(
  getPreviouslySelectedCards
);
