import gql from "graphql-tag";
import {
  toolResponseFragment,
  pypElementRatingFragment,
  remarkFragment,
} from "modules/CommonFragments";
import { assessmentToolFragment } from "modules/AssessmentTool/AssessmentToolFragment";
import {
  organizationElementRatingsFragments,
  plannerElementNodeFragments,
} from "./Fragments";

export const getAssessmentToolForProjectGroupQuery = gql`
  query getAssessmentToolForProjectGroup($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        assessmentTool {
          id
          ... on MypObjectiveRubric {
            ...mypObjectiveRubricItem
          }
        }
      }
    }
  }
  ${assessmentToolFragment.mypObjectiveRubric}
`;

export const getAssessedDataForProjectQuery = gql`
  query getAssessedDataForProject($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        students {
          id
        }
        ibPYPElementRatings {
          ...ratingItem
        }
        toolResponses(userFilter: ALL) {
          ...toolResponseItem
        }
      }
    }
  }
  ${toolResponseFragment.toolResponse}
  ${pypElementRatingFragment.studentElementRating}
`;

export const getProjectAssessmentRemarkQuery = gql`
  query getProjectAssessmentRemark($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        assessmentRemarks {
          ...remarkMessage
        }
      }
    }
  }
  ${remarkFragment.remarkMessageFragment}
`;

export const getPlannerElementSetNodeQuery = gql`
  query getPlannerElementSetNode($id: ID!, $filters: PlannerElementFilters) {
    node(id: $id, type: PLANNER_ELEMENT_SET) {
      id
      ... on PlannerElementSet {
        id
        type
        nodes(filters: $filters) {
          ...basicPlannerElementNodeItem
        }
      }
    }
  }
  ${plannerElementNodeFragments.basicPlannerElementNode}
`;

export const getOrganizationAcademicCriteriaValuesQuery = gql`
  query getOrganizationAcademicCriteriaValues(
    $id: ID!
    $filters: RatingLevelsFilter
    $finalGradeFilters: RatingLevelsFilter
    $fetchFinalGrade: Boolean!
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        constants {
          ratingLevels(filters: $filters) {
            ...basicOrganizationElementRatingsItem
          }
          finalGradeRatingLevels: ratingLevels(filters: $finalGradeFilters)
            @include(if: $fetchFinalGrade) {
            ...basicOrganizationElementRatingsItem
          }
        }
      }
    }
  }
  ${organizationElementRatingsFragments.basicOrganizationElementRatings}
`;

export const getProjectAcademicCriteriaValueQuery = gql`
  query getProjectAcademicCriteriaValue($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        ibPYPElementRatings {
          ...basicIBPYPElementRatingItem
        }
      }
    }
  }
  ${pypElementRatingFragment.basicIBPYPElementRating}
`;
