import PropTypes from "prop-types";
import React from "react";

const SearchIcon = props => {
  return (
    <svg
      fill={props.fill && props.fill}
      width={props.width}
      height={props.height}
      viewBox="0 0 48 48"
    >
      <g id="Page-4" stroke="none">
        <g transform="translate(-879.000000, -1023.000000)">
          <path d="M926.321243,1067.04199 L916.980337,1057.70108 C924.077038,1048.86258 923.025665,1036.01808 914.586127,1028.45128 C906.146589,1020.88448 893.263921,1021.23587 885.249413,1029.25148 C877.234905,1037.26708 876.885275,1050.1498 884.453228,1058.5883 C892.02118,1067.0268 904.865826,1068.07642 913.703356,1060.97851 L923.044091,1070.31917 C923.94906,1071.22414 925.416305,1071.22414 926.321273,1070.31917 C927.226242,1069.4142 927.226242,1067.94696 926.321273,1067.04199 L926.321243,1067.04199 Z M900.390271,1061.04454 C893.653528,1061.04473 887.580013,1056.98678 885.001808,1050.76291 C882.423604,1044.53904 883.848444,1037.37494 888.611926,1032.61122 C893.375408,1027.84751 900.539442,1026.42233 906.763438,1029.00023 C912.987434,1031.57814 917.045677,1037.65145 917.045809,1044.3882 C917.035522,1053.58266 909.584736,1061.03381 900.390271,1061.04454 Z" />
        </g>
      </g>
    </svg>
  );
};

SearchIcon.defaultProps = {
  width: 24,
  height: 24,
};

SearchIcon.propTypes = {
  fill: PropTypes.string,
  scale: PropTypes.number,
};

export default SearchIcon;
