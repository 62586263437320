import gql from "graphql-tag";
import { academicHonestyFragment } from "./Fragments";

export const createAcademicHonestyMutation = gql`
  mutation createAcademicHonesty($input: ProjectAcademicHonestyCreateInput!) {
    documentation {
      createProjectAcademicHonesty(input: $input) {
        ...academicHonestyItem
      }
    }
  }
  ${academicHonestyFragment.basicDetails}
`;

export const updateAcademicHonestyMutation = gql`
  mutation updateAcademicHonesty($input: ProjectAcademicHonestyUpdateInput!) {
    documentation {
      updateProjectAcademicHonesty(input: $input) {
        id
      }
    }
  }
`;

export const deleteAcademicHonestyMutation = gql`
  mutation deleteAcademicHonesty($input: ProjectAcademicHonestyDeleteInput!) {
    documentation {
      deleteProjectAcademicHonesty(input: $input)
    }
  }
`;
