import React from "react";
import classes from "./AssessmentLibraryEdit.scss";
import LibraryBody from "UnitPlans/components/LibraryCommonComponents/LibraryBody";
import { UILabel, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";

class AssessmentLibraryEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: _.get(props.options, "assessmentType", "le"),
    };
  }

  onTabClicked = selectedType => {
    this.setState({ selectedType });
  };

  filterList = assessments => {
    const {
      state: { selectedType },
    } = this;
    return _.filter(assessments, item => {
      return _.get(item, "assessmentType.value") === selectedType;
    });
  };

  onCardClicked = ({ id, mode }) => {
    this.props.showModal({ id, mode });
  };

  onDuplicateClick = async (id, promptText) => {
    const { assessmentTypes, importAssessment, unitPlanId } = this.props;
    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    //@kunal where is this used? in UI?
    const unitPlanAcademicYearsIds = _.map(
      _.get(unitPlanDetails, "academicYears", []),
      item => item.id
    );

    importAssessment({
      sourceAssessmentId: id,
      title: promptText,
      isCopy: true,
      groupType: this.props.groupType,
      assessmentTypes,
      academicYears: unitPlanAcademicYearsIds,
    });
  };

  render() {
    const {
      props: {
        assessments,
        deleteAssessment,

        getPrintFile,
        resource,
        options: { nodes = [] } = {},
        t,
        readOnly,
        unitPlanId,
        size,
        groupType,
        curriculumType,
        assessmentTypeGroup,
        assessmentTypes,
        isLoading,
        isFilteredData,
      },
      state: { selectedType },
    } = this;

    return (
      <div className={classes.container}>
        <UILabel
          label={this.props.label}
          subText={this.props.subText}
          labelComponent={this.props.labelComponent}
          labelStyle={this.props.labelStyle}
          labelContainerStyle={this.props.labelContainerStyle}
        />

        <div className={classes.assessmentList}>
          {/* <div className={classes.tabsContainer}>
            <AssessmentTypeTabs
              nodes={nodes}
              assessments={assessments}
              selectedType={selectedType}
              onTabClicked={this.onTabClicked}
            />
          </div> */}
          <LibraryBody
            isFilteredData={isFilteredData}
            size={size}
            list={assessments}
            createText={t("common:add_with_label", {
              label: t(_.get(resource, "labelWithoutCount", "")),
            })}
            curriculumType={curriculumType}
            onCardClicked={this.onCardClicked}
            onDuplicateClick={this.onDuplicateClick}
            onDeleteClick={id =>
              deleteAssessment({
                assessmentId: id,
                groupType,
                assessmentTypes,
              })
            }
            isLoading={isLoading}
            assessmentTypes={assessmentTypes}
            assessmentTypeGroup={assessmentTypeGroup}
            onPrintClick={id => getPrintFile({ id: id, type: "assessment" })}
            label={t(_.get(resource, "labelWithoutCount", ""))}
            readOnly={readOnly}
            groupType={groupType}
            unitPlanId={unitPlanId}
          />
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(AssessmentLibraryEdit);
