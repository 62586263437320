import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import classes from "./PdfTronViewer.scss";
import { FullScreenLoader, I18nHOC, UIButton, DialogueBox } from "UIComponents";
import {
  GiveFeedbackIcon,
  TickIcon,
  DownloadIcon,
  PrintIcon,
} from "SvgComponents";
import { uploadFileV2 } from "modules/Services";
import { generateRandomId, getFilteredDisableElementsInPdftron } from "Utils";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { updateAttachmentPdfTron } from "./modules/PdfTronViewerModule";
import { fontStyle, colors } from "Constants";
import {
  customizer,
  getpdfTronEditModeSupported,
  cleanAttachmentObject,
} from "./modules/PdfTronViewerUtils";
import { PdfTronViewerConfig } from "./modules/PdfTronViewerConfig.js";
import _ from "lodash";
import PropTypes from "prop-types";
import DialogBox from "./components/DialogBox";
import { PDFTRON_LICENSE_KEY } from "store/static";
import { setToastMsg } from "Login/modules/LoginModule";

const styles = {
  buttonContainer: {
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "12px",
    cursor: "pointer",
  },
  buttonLabel: {
    ...fontStyle.demiBold,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.salmon60,
  },
  myButton: {
    marginLeft: "4px",
  },

  saveButtonStyle: {
    marginLeft: "12px",
    borderRadius: "4px",
    padding: "2px 12px",
    backgroundColor: colors.link29,
    display: "flex",
    alignItems: "center",
    fontFamily: "Avenir Next",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    cursor: "pointer",
  },

  tickIcon: {
    marginRight: "8px",
    display: "inline-block",
    position: "relative",
    top: "2px",
  },

  feedbackButtonStyle: {
    marginLeft: "4px",
    borderRadius: "4px",
    padding: "2px 20px",
    backgroundColor: colors.salmon60,
    display: "flex",
    alignItems: "center",
    fontFamily: "Avenir Next",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    cursor: "pointer",
  },

  giveFeedbackIcon: {
    marginRight: "8px",
    display: "inline-block",
    position: "relative",
    top: "2px",
  },

  downloadIcon: {
    cursor: "pointer",
  },

  printIcon: {
    cursor: "pointer",
    marginLeft: "16px",
  },
};
var annotationString = "";

const PdfTronViewer = React.memo(
  ({
    file,
    shouldShowGiveFeedbackButton,
    uploadFileV2,
    updateAttachmentPdfTron,
    t,
    closeViewer,
    pdftronViewerMode,
    annotationUserInfo,
    onDiscardDirectly,
    isPdftronEnabled,
    changePdftronEnable,
    studentDetails,
    onUpdateSuccessfully,
    setToastMsg,
  }) => {
    const viewer = useRef(null);
    const [instance, setInstance] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentDialogAction, setCurrentDialogAction] = useState(false);
    const [dialogueBoxState, setDialougeBoxState] = useState("DISCARD");
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

    const [pdftronEditModeEnabled, setPdftronEditModeEnabled] = useState(
      getpdfTronEditModeSupported({ file })
    );

    const changeEditToViewMode = ({ instance }) => {
      setPdftronEditModeEnabled(true);

      if (instance) {
        const {
          annotationManager,
          Tools: { ToolNames },
        } = instance.Core;

        annotationManager.enableReadOnlyMode();

        instance.UI.disableElements(
          PdfTronViewerConfig[pdftronViewerMode]["TOGGLE_ELEMENTS"]
        );
        instance.UI.setToolMode(ToolNames.PAN);
      }
    };

    const onClickGiveFeedback = () => {
      setPdftronEditModeEnabled(false);

      if (instance) {
        const { annotationManager } = instance.Core;

        annotationManager.disableReadOnlyMode();

        instance.UI.disableElements(["giveFeedbackButton"]);

        instance.UI.enableElements(
          PdfTronViewerConfig[pdftronViewerMode]["TOGGLE_ELEMENTS"]
        );

        instance.UI.setToolbarGroup("toolbarGroup-Shapes");
      }
    };

    const changeAnnotation = async () => {
      const { annotationManager } = instance.Core;

      let newAnnotationString = "";
      await annotationManager
        .exportAnnotations({
          links: false,
          widgets: false,
        })
        .then(xfdfString => (newAnnotationString = xfdfString));

      if (annotationString !== newAnnotationString) {
        setCurrentDialogAction(true);
      } else {
        if (_.isEqual("TEACHER_EDITOR", pdftronViewerMode)) {
          onDiscardDirectly();
          changeEditToViewMode({ instance });
        } else {
          closeViewer();
        }
      }
    };

    const downloadDocument = async ({ instance }) => {
      try {
        setIsLoading(true);
        const { annotationManager, SaveOptions } = instance.Core;
        const xfdfString = await annotationManager.exportAnnotations({
          links: false,
          widgets: false,
        });
        const saveOptions = SaveOptions;
        const options = {
          filename: `${_.get(file, "name", "sample")}.${_.get(
            file,
            "metadata.fileExtension",
            "pdf"
          )}`,
          xfdfString,
          flags: saveOptions.LINEARIZED,
          downloadType: "pdf",
        };

        await instance.UI.downloadPdf(options);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setToastMsg("toastMsgs:something_went_wrong");
      }
    };

    const printDocument = ({ instance }) => {
      try {
        instance.UI.printInBackground({ includeAnnotations: true });
      } catch (err) {
        setToastMsg("toastMsgs:something_went_wrong");
      }
    };

    const onSuccessfulSave = () => {
      onUpdateSuccessfully?.();
      if (_.isEqual("TEACHER_EDITOR", pdftronViewerMode)) {
        onDiscardDirectly();
        changeEditToViewMode({ instance });
      } else {
        closeViewer();
      }
    };

    const saveDocument = async ({ instance }) => {
      try {
        setIsLoading(true);

        const { documentViewer, annotationManager } = instance.Core;

        const xfdfString = await annotationManager.exportAnnotations({
          links: false,
          widgets: false,
        });

        /** Check if annotationString has changed, if not, then don't create a new copy
         *  of the pdf. If it has changed, then save the updated pdf to cloud and set the
         *  new url to the attachment
         */
        if (annotationString === xfdfString) {
          onSuccessfulSave();
          return;
        }

        const data = await documentViewer
          .getDocument()
          .getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: "application/pdf" });

        const url = await uploadFileV2({
          file: blob,
          attachment: file,
          uploadId: generateRandomId(),
        });

        let newFile = file;
        const other = {
          metadata: { oldUrls: [newFile.url] },
        };
        _.mergeWith(newFile, other, customizer);
        _.set(newFile, "url", url);
        newFile = cleanAttachmentObject({ attachment: newFile });

        await updateAttachmentPdfTron({
          newAttachment: newFile,
        });

        onSuccessfulSave();
      } catch (err) {
        setIsLoading(false);
        setToastMsg("toastMsgs:something_went_wrong");
      }
    };

    // fullscreen button

    // const fullScreenButton = {
    //   type: "actionButton",
    //   dataElement: "fullScreenButton",
    //   img:
    //     '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - full screen</title><path class="cls-1" d="M4.22,4.22H9.78V2H2V9.78H4.22ZM9.78,19.78H4.22V14.22H2V22H9.78ZM22,14.22H19.78v5.56H14.22V22H22ZM19.78,9.78H22V2H14.22V4.22h5.56Z"></path></svg>',
    //   onClick: () => {
    //     instance.UI.toggleFullScreen();
    //   },
    // };

    const ExitButton = () => {
      return (
        <UIButton
          size={"lg"}
          type={"filled"}
          color={"white"}
          containerStyle={styles.buttonContainer}
          labelStyle={styles.buttonLabel}
          onClick={() => {
            changeAnnotation();
          }}
        >
          {shouldShowGiveFeedbackButton ? t("cancel") : t("exit_label")}
        </UIButton>
      );
    };

    const exitElement = {
      type: "customElement",
      dataElement: "cancelButton",

      render: () => <ExitButton />,
    };

    const SaveButton = () => {
      return (
        <UIButton
          size={"lg"}
          type={"filled"}
          containerStyle={styles.saveButtonStyle}
          labelStyle={styles.addButtonLabelStyle}
          style={styles.myButton}
          onClick={() => {
            saveDocument({ filename: `${file.name}.pdf`, instance: instance });
          }}
        >
          <span style={styles.tickIcon}>
            <TickIcon fill={colors.white} width={18} height={18} />
          </span>

          {t("common:done")}
        </UIButton>
      );
    };

    const saveElement = {
      type: "customElement",
      dataElement: "saveButton",
      render: () => <SaveButton />,
    };

    const GiveFeedbackButton = () => {
      return (
        <UIButton
          size={"lg"}
          type={"filled"}
          containerStyle={styles.feedbackButtonStyle}
          labelStyle={styles.addButtonLabelStyle}
          style={styles.myButton}
          onClick={() => {
            changePdftronEnable(true);
            onClickGiveFeedback();
          }}
        >
          <span style={styles.giveFeedbackIcon}>
            <GiveFeedbackIcon width={16} height={16} />
          </span>

          {t("common:annotate")}
        </UIButton>
      );
    };

    const giveFeedbackButton = {
      type: "customElement",
      dataElement: "giveFeedbackButton",
      render: () => <GiveFeedbackButton />,
    };

    const DownloadButton = () => {
      return (
        <div
          style={styles.downloadIcon}
          onClick={() => downloadDocument({ instance })}
        >
          <DownloadIcon width={20} height={20} fill={colors.iconSubtle} />
        </div>
      );
    };

    const downloadButton = {
      type: "customElement",
      dataElement: "downloadButton",
      title: "Download",
      render: () => <DownloadButton />,
    };

    const PrintButton = () => {
      return (
        <div
          style={styles.printIcon}
          onClick={() => printDocument({ instance })}
        >
          <PrintIcon width={20} height={20} fill={colors.iconSubtle} />
        </div>
      );
    };

    const printButton = {
      type: "customElement",
      title: "Print",
      dataElement: "printButton",
      render: () => <PrintButton />,
    };

    const teacherLoading = ({ instance }) => {
      const { annotationManager } = instance.Core;

      instance.UI.setHeaderItems(header => {
        header.push(downloadButton);
        header.push(printButton);
        header.push({ type: "divider" });
        header.push(giveFeedbackButton);
        header.push(exitElement);
        header.push(saveElement);
      });

      annotationManager.enableReadOnlyMode();

      const filteredDisableElements = getFilteredDisableElementsInPdftron(
        PdfTronViewerConfig[pdftronViewerMode]["PERMANENT_DISABLE_ELEMENTS"]
      );

      instance.UI.disableElements(filteredDisableElements);
    };

    const studentLoading = ({ instance }) => {
      const { annotationManager } = instance.Core;

      instance.UI.setHeaderItems(header => {
        header.push(downloadButton);
        header.push(printButton);
        header.push({ type: "divider" });
        header.push(exitElement);
        header.push(saveElement);
      });

      annotationManager.enableReadOnlyMode();
      instance.UI.setToolbarGroup("selectToolButton");

      const filteredDisableElements = getFilteredDisableElementsInPdftron(
        PdfTronViewerConfig[pdftronViewerMode]["PERMANENT_DISABLE_ELEMENTS"]
      );

      instance.UI.disableElements(filteredDisableElements);

      if (shouldShowGiveFeedbackButton && pdftronEditModeEnabled) {
        onClickGiveFeedback();
      }
    };

    const viewerLoading = ({ instance }) => {
      const { annotationManager } = instance.Core;

      instance.UI.setHeaderItems(header => {
        header.push(downloadButton);
        header.push(printButton);
        header.push({ type: "divider" });
        header.push(exitElement);
      });

      annotationManager.enableReadOnlyMode();

      const filteredDisableElements = getFilteredDisableElementsInPdftron(
        PdfTronViewerConfig[pdftronViewerMode]["PERMANENT_DISABLE_ELEMENTS"]
      );

      instance.UI.disableElements(filteredDisableElements);
    };

    useEffect(() => {
      WebViewer(
        {
          path: "/webviewer",
          l: PDFTRON_LICENSE_KEY,
          annotationUser: annotationUserInfo.id,
          fullAPI: true,
        },
        viewer.current
      ).then(async instance => {
        setInstance(instance);

        var iframeDoc = instance.UI.iframeWindow.document;
        var header = iframeDoc.querySelector('[data-element="header"]');
        var headerTools = iframeDoc.querySelector(
          '[data-element="toolsHeader"]'
        );
        if (header) {
          header.style.opacity = "0";
        }
        if (headerTools) {
          headerTools.style.opacity = "0";
        }
      });
    }, []);

    const checkAndLoadPdf = async ({ instance }) => {
      const { PDFNet } = instance.Core;

      await instance.Core.PDFNet.initialize(PDFTRON_LICENSE_KEY);

      const pdfDocument = await PDFNet.PDFDoc.createFromURL(file.url);

      pdfDocument.isEncrypted().then(data => {
        if (data) {
          setIsLoading(false);
        }
      });

      instance.UI.loadDocument(pdfDocument, {
        filename: `${_.get(file, "name", "sample")}.${_.get(
          file,
          "metadata.fileExtension",
          "pdf"
        )}`,
      });
    };

    useEffect(() => {
      if (instance) {
        setIsLoading(true);
        setIsDocumentLoaded(false);
        const {
          documentViewer,
          annotationManager,
          Tools: { ToolNames },
        } = instance.Core;

        checkAndLoadPdf({ instance });

        documentViewer.addEventListener("documentLoaded", () => {
          setIsDocumentLoaded(true);
          annotationManager
            .exportAnnotations({ links: false, widgets: false })
            .then(xfdfString => {
              annotationString = xfdfString;
            });
          var iframeDoc = instance.UI.iframeWindow.document;
          var header = iframeDoc.querySelector('[data-element="header"]');
          var headerTools = iframeDoc.querySelector(
            '[data-element="toolsHeader"]'
          );
          if (header) {
            header.style.opacity = "1";
          }
          if (headerTools) {
            headerTools.style.opacity = "1";
          }
          /** Set initial default tool mode to Pan(scrolling) tool. */
          instance.UI.setToolMode(ToolNames.PAN);
        });

        documentViewer.addEventListener("annotationsLoaded", () => {
          setIsLoading(false);
        });
      }
      return () => {
        if (instance) {
          const { documentViewer } = instance.Core;

          documentViewer.removeEventListener("annotationsLoaded");
        }
      };
    }, [instance, isPdftronEnabled]);

    useEffect(() => {
      if (instance) {
        if (
          shouldShowGiveFeedbackButton &&
          pdftronEditModeEnabled &&
          isDocumentLoaded
        ) {
          instance.UI.enableElements(["giveFeedbackButton"]);
        } else {
          instance.UI.disableElements(["giveFeedbackButton"]);
        }
      }

      return () => {};
    }, [
      instance,
      shouldShowGiveFeedbackButton,
      pdftronEditModeEnabled,
      isDocumentLoaded,
    ]);

    useEffect(() => {
      if (instance) {
        const { annotationManager } = instance.Core;

        annotationManager.setAnnotationDisplayAuthorMap(userId => {
          if (_.isEqual(annotationUserInfo.id, userId)) {
            return `${_.get(annotationUserInfo, "first_name", "")} ${_.get(
              annotationUserInfo,
              "last_name",
              ""
            )}`;
          } else if (_.isEqual("STUDENT", annotationUserInfo.userEntityType)) {
            return "Teacher";
          } else {
            return "Student";
          }
        });

        switch (pdftronViewerMode) {
          case "TEACHER_EDITOR":
            teacherLoading({ instance });
            break;
          case "STUDENT_EDITOR":
            studentLoading({ instance });
            break;
          case "VIEWER":
            viewerLoading({ instance });
            break;
          default:
            viewerLoading({ instance });
        }
      }
    }, [instance]);

    return (
      <div className={classes.pdftron}>
        <div className={classes.webviewer} ref={viewer}></div>

        {currentDialogAction && (
          <DialogBox
            dialogueBoxState={dialogueBoxState}
            setCurrentDialogAction={setCurrentDialogAction}
            changePdftronEnable={changePdftronEnable}
            onClickGiveFeedback={onClickGiveFeedback}
            setDialougeBoxState={setDialougeBoxState}
            pdftronViewerMode={pdftronViewerMode}
            onDiscardDirectly={onDiscardDirectly}
            changeEditToViewMode={changeEditToViewMode}
            instance={instance}
            closeViewer={closeViewer}
            t={t}
            studentDetails={studentDetails}
          />
        )}

        {isLoading && <FullScreenLoader />}
      </div>
    );
  }
);

PdfTronViewer.displayName = "PdfTronViewer";

const mapActionCreators = {
  uploadFileV2,
  updateAttachmentPdfTron,
  setToastMsg,
};

PdfTronViewer.propTypes = {
  isPdftronEnabled: PropTypes.bool,
};

PdfTronViewer.defaultProps = {
  isPdftronEnabled: false,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(null, mapActionCreators)
)(PdfTronViewer);
