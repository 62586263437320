import PropTypes from "prop-types";
import React from "react";

const IncrementArrow = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill={props.fill && props.fill}
    >
      <path d="M9.39073 5.57962C9.23221 5.7129 8.99566 5.69244 8.86238 5.53392L5.19025 1.16641C5.1085 1.06678 4.89573 1.03725 4.75614 1.17853L1.12987 5.53258C0.997324 5.69172 0.760869 5.71328 0.601727 5.58074C0.442585 5.4482 0.421021 5.21174 0.553563 5.0526L4.18818 0.688539C4.19288 0.682889 4.19775 0.677379 4.20278 0.672015C4.61667 0.23064 5.36645 0.201244 5.76848 0.688716L9.43643 5.05126C9.56972 5.20978 9.54926 5.44634 9.39073 5.57962Z" />
    </svg>
  );
};

IncrementArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

IncrementArrow.defaultProps = {
  width: 16,
  height: 16,
};
export default IncrementArrow;
