import client from "apolloClient";
import {
  getOrganizationAssignmentsProgressSummaryQuery,
  getCourseDetailsQuery,
  getStudentsPypElementRatingsQuery,
  getAcademicCriteriaSetsQuery,
} from "./ProgressSummaryQuery";

export const getOrganizationAssignmentsProgressSummaryFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationAssignmentsProgressSummaryQuery,
      variables,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", []);
  else return {};
};

export const getStudentsPypElementRatingsFromCache = ({
  studentIds,
  elementTypes,
  academicCriteriaSetId,
  subjectIds,
  parentTypes,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentsPypElementRatingsQuery,
      variables: {
        studentIds,
        academicCriteriaSetId,
        elementTypes,
        subjectIds,
        parentTypes,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getAcademicCriteriaSetsFromCache = ({
  organizationId,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAcademicCriteriaSetsQuery,
      variables: {
        organizationId,
        filters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
