import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "attendance",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const { AttendanceSetup } = require("./components");
            const reducer = require("./modules/Module").default;
            injectReducer(store, { key: "attendanceSetup", reducer });
            cb(AttendanceSetup);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
});
