export const getGlobalGradesGroupedByCurriculumProgram = ({ grades }) => {
  const gradesGroupedByCurriculumProgram = _.groupBy(
    grades,
    ({ curriculumProgram }) => {
      return _.get(curriculumProgram, "id", "");
    }
  );

  const curriculumProgramIds = _.keys(gradesGroupedByCurriculumProgram);

  return _.reduce(
    curriculumProgramIds,
    (result, curriculumProgramId) => {
      const grades = gradesGroupedByCurriculumProgram[curriculumProgramId];
      return {
        ...result,
        [curriculumProgramId]: _.map(grades, grade =>
          _.get(grade, "globalGrade", {})
        ),
      };
    },
    {}
  );
};
