import React from "react";
import classes from "./FilterHeader.scss";
import { ButtonDropdown, MoreItemWithToolTip, I18nHOC } from "UIComponents";
import { ArrowIcon } from "SvgComponents";

import CheckListWithSearch from "./CheckListWithSearch";

const styles = {
  coursesButtonDropDownContainerStyle: {
    top: 40,
    right: 0,
    minWidth: 200,
    width: "max-content",
    maxHeight: 300,
    padding: 16,
    paddingTop: 22,
    paddingBottom: 22,
    overflowY: "auto",
    borderRadius: 8,
  },
  buttonDropDownContainerStyle: {
    minWidth: 240,
    top: 40,
    right: 0,
  },
  checklistContainerStyle: {
    flexDirection: "column",
  },
  checklistItemContainerStyle: {
    flexBasis: "unset",
  },
};

class FilterHeader extends React.PureComponent {
  getButtonComponnent = ({ label, valueLabels }) => {
    return (
      <div className={classes.filterBox}>
        <div
          style={{ marginRight: "4px" }}
          className={classes.filterLabelText}
        >{`${label}`}</div>
        <div className={classes.filterText}>
          {_.isArray(valueLabels) ? (
            <MoreItemWithToolTip items={valueLabels} length={3} />
          ) : (
            valueLabels
          )}
        </div>
        <div className={classes.bottomArrow}>
          <ArrowIcon width={10} height={5} />
        </div>
      </div>
    );
  };

  getOrganizationnValueLabels = () => {
    const { filters, organizationList } = this.props;
    const filterValue = filters["organizations"];
    if (filterValue.length == 0) {
      return "No Organizations";
    } else if (filterValue.length == organizationList.length) {
      return "All Organizations";
    } else {
      return _.map(filterValue, id =>
        _.get(_.find(organizationList, { value: id }), "label", "")
      );
    }
  };

  render() {
    const { updateFilters, filters, organizationList } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.filtersContainer}>
          <div className={classes.filterandlabelcontainer}>
            <ButtonDropdown
              containerStyle={styles.coursesButtonDropDownContainerStyle}
              dropdownComponent={
                <CheckListWithSearch
                  updateInputField={value =>
                    updateFilters({ organizations: value })
                  }
                  checkListContainerStyle={styles.checklistContainerStyle}
                  listItemStyle={styles.checklistItemContainerStyle}
                  value={filters.organizations}
                  options={organizationList}
                  showAllOption={true}
                  allItemStyle={styles.allStyle}
                />
              }
              shouldCloseOnSelfClick={false}
              buttonComponent={this.getButtonComponnent({
                label: "Organizations :",
                valueLabels: this.getOrganizationnValueLabels(),
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(FilterHeader);
