import React from "react";
import { connect } from "react-redux";
import classes from "./OrganizationManagementRoot.scss";
import { PAComponent } from "lib/PermissionAware";
import { onClickNavigate } from "../../modules/ToolingModule";
import { ApolloProvider, graphql, compose, withApollo } from "react-apollo";
const communcationsObj = [
  {
    route: "organizationPreset",
    label: "School Preset",
    perm: "ToolPortal:ManageOrganization",
    icon: "https://cloud.toddleapp.com/assets/webapp/organization_icon.png",
  },
  {
    route: "organizationApproval",
    label: "Activate User",
    perm: "ToolPortal:ManageOrganization",
    icon: "https://cloud.toddleapp.com/assets/webapp/organization_icon.png",
  },
  {
    route: "userApproval",
    label: "User Approval",
    perm: "ToolPortal:ManageOrganization",
    icon: "https://cloud.toddleapp.com/assets/webapp/organization_icon.png",
  },
  // ,{
  //   route: "benchmarkAuthoring",
  //   label: "Benchmark Authoring",
  //   icon: "https://cloud.toddleapp.com/assets/webapp/authoring_icon.png"
  // },
];
class OrganizationManagementRoot extends React.Component {
  constructor(props) {
    super(props);
  }

  onClickNavigate = link => {
    this.props.onClickNavigate({ link, type: "replace" });
  };

  render() {
    const communcationsList = communcationsObj.map((obj, key) => {
      const comp = (
        <div className={classes.container} key={key}>
          <div
            className={classes.component}
            key={key}
            onClick={() => {
              this.onClickNavigate(obj.route);
            }}
          >
            <div
              className={classes.componentIcon}
              style={{
                backgroundImage:
                  "url(https://cloud.toddleapp.com/thumber/300x300/" +
                  obj.icon +
                  ")",
              }}
            />
            <div className={classes.componentLabel}>{obj.label}</div>
          </div>
        </div>
      );
      return obj.perm ? (
        <PAComponent perm={obj.perm}>{comp}</PAComponent>
      ) : (
        comp
      );
    });
    return this.props.children ? (
      <div className={classes.childContainer}>{this.props.children}</div>
    ) : (
      <div className={classes.body}>{communcationsList}</div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapActionCreators = {
  onClickNavigate,
};
// export default OrganizationManagementRoot;
export default compose(connect(mapStateToProps, mapActionCreators))(
  OrganizationManagementRoot
);

// class OrganizationManagementRoot extends React.Component {
//   render() {
//     return null;
//   }
// }
// export default OrganizationManagementRoot;
