import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import OrganizationChildResources from "OrganizationResources/routes/OrganizationChildResouces";

export default store => ({
  path: "schoolpolicies",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("OrganizationResources/EducatorCenterParent")
              .default;
            const reducer = require("OrganizationResources/modules/OrganizationResourcesModule")
              .default;
            const teacherReducer = require("Teacher/modules/TeacherModule")
              .default;
            injectReducer(store, { key: "teacher", reducer: teacherReducer });
            injectReducer(store, { key: "organizationResource", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "organization-educatorCenter"
        );
      })
  ),
  childRoutes: [OrganizationChildResources(store)],
});
