import React from "react";
import classNames from "classnames";
import classes from "./List.scss";
import Card from "../../Card";

class List extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  renderCards = data => {
    const {
      props: { onNotificationClicked, notificationTypes, user_type },
    } = this;

    return _.map(data, item => {
      return (
        <Card
          key={item.id}
          onNotificationClicked={onNotificationClicked}
          item={item}
          user_type={user_type}
          notificationDetails={_.get(
            notificationTypes,
            _.get(item, "type", ""),
            {}
          )}
        />
      );
    });
  };

  render() {
    const {
      props: { label, data },
    } = this;

    return (
      <div className={classes.mainContainer}>
        <div className={classes.listContainer}>{this.renderCards(data)}</div>
      </div>
    );
  }
}

List.defaultProps = {
  label: "",
  data: [],
};

export default List;
