import gql from "graphql-tag";
import { userFragment } from "modules/CommonFragments";
export const unitPlanFragment = {
  unitPlanDetails: gql`
    fragment unitPlanItem on UnitPlan {
      id
      planathonCriteriaStatus
      title {
        id
        value
      }
      theme {
        id
        value
      }
      duration {
        id
        value
      }
      image {
        id
        value
      }
      createdBy {
        ...userItem
      }
      leLibrary: resourceLibrary(filters: { groupType: LEARNING_ENGAGEMENT }) {
        leCount
      }
      assessmentLibrary: resourceLibrary(filters: { groupType: ASSESSMENT }) {
        assessmentCount
      }
      collaborators: allCollaborators {
        totalCount
        edges {
          permission
          node {
            ...userItem
          }
        }
      }
    }
    ${userFragment.basicUserDetails}
  `,
};
