import React from "react";
import classes from "./LoginHome.scss";

const styles = {
  containerStyle: { paddingTop: "20px", paddingBottom: "12px" },
  tradeMarkStyle: { marginTop: "60px", marginBottom: "12px" },
};

import {
  School3D,
  Student3D,
  Family3D,
  RightArrowOutlined,
} from "@toddle-design/web-icons";
import { colors, fontStyle } from "Constants";

import classNames from "classnames";

class LoginHome extends React.PureComponent {
  onTeacherLoginClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "teacherLogin", userType: "staff" });
  };

  onStudentLoginClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "studentLogin", userType: "student" });
  };

  onFamilyLoginClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "parentLogin", userType: "parent" });
  };

  onVisitorLoginLink = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({
      type: "visitorLogin",
      userType: "staff",
      role: "visitor",
    });
  };

  render() {
    const {
      renderTradeMarkText,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
      renderRightSideLogo,
    } = this.props;

    const accountContainerClass = classNames(classes.accountContainer, "group");

    return (
      <div
        className={classes.container}
        style={{ ...containerStyle, ...styles.containerStyle }}
      >
        {/* Render for smaller screen */}
        {renderRightSideLogo()}
        <div style={{ ...headerStyle, fontSize: "3.2rem" }}>
          Welcome to Toddle!
        </div>
        <div
          style={{
            ...subTextStyle,
            color: colors.gray48,
            ...fontStyle.regular,
          }}
        >
          Choose an account type to proceed
        </div>
        <div className={classes.buttonsContainer}>
          <div
            data-test-id="button-login-teacher"
            className={accountContainerClass}
            onClick={this.onTeacherLoginClick}
          >
            <div className={classes.accountIcon}>
              <School3D />
            </div>
            <div className={classes.accountDetails}>
              <div className={classes.accountTitle}>{"Teacher account"}</div>
              <div className={classes.accountDescription}>
                {"I’m an educator/school admin/IT specialist"}
              </div>
            </div>
            <div className={classes.accountActionIcon}>
              <RightArrowOutlined size="xx-small" variant="subtle" />
            </div>
          </div>
          <div
            data-test-id="button-login-student"
            className={accountContainerClass}
            onClick={this.onStudentLoginClick}
          >
            <div className={classes.accountIcon}>
              <Student3D />
            </div>
            <div className={classes.accountDetails}>
              <div className={classes.accountTitle}>{"Student account"}</div>
              <div className={classes.accountDescription}>
                {"I’m a student"}
              </div>
            </div>
            <div className={classes.accountActionIcon}>
              <RightArrowOutlined size="xx-small" variant="subtle" />
            </div>
          </div>
          <div
            data-test-id="button-login-family"
            className={accountContainerClass}
            onClick={this.onFamilyLoginClick}
          >
            <div className={classes.accountIcon}>
              <Family3D />
            </div>
            <div className={classes.accountDetails}>
              <div className={classes.accountTitle}>{"Family account"}</div>
              <div className={classes.accountDescription}>
                {"I’m a family member"}
              </div>
            </div>
            <div className={classes.accountActionIcon}>
              <RightArrowOutlined size="xx-small" variant="subtle" />
            </div>
          </div>
        </div>
        <div className={classes.signInAsVisitor}>
          {`Here as a programme evaluation team member?`}
          <div
            data-test-id="button-login-visitor"
            className={classes.signInText}
            onClick={this.onVisitorLoginLink}
          >{`Sign in here`}</div>
        </div>
        {renderTradeMarkText({
          align: "left",
          containerStyle: styles.tradeMarkStyle,
        })}
      </div>
    );
  }
}

export default LoginHome;
