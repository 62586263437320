import PropTypes from "prop-types";
import React from "react";
import classes from "./ButtonDropdown.scss";
import { LinkWithTooltip } from "UIComponents";
import { MoreIcon, RightIcon } from "SvgComponents";
import classNames from "classnames";
import { Popover } from "react-tiny-popover-new";

const styles = {
  linkStyles: {
    color: "unset",
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "unset",
  },
};

class ButtonDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: props.showDropdown,
    };
    this._isMounted = false;
    this.popoverProps = {
      isOpen: true,
      align: "start",
      positions: ["bottom", "top"],
      padding: 12,
      boundaryInset: 0,
      reposition: true,
      containerStyle: { zIndex: 10 },
      currentClickedTab: null,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.setState({ showDropdown: this.props.showDropdown });
  };
  componentDidUpdate = (prevProps, prevState) => {
    const { showDropdown } = this.state;
    if (!prevState.showDropdown && showDropdown) {
      this.addDocumentClickListener();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.removeDocumentClickListener();
  };

  handleEscKey = event => {
    if (event.keyCode == 27) {
      this.updateShowDropdown(false);
    }
  };

  handleDocumentMouseClick = event => {
    const {
      closeOnOutSideClick,
      shouldCloseOnButtonClick,
      keepDropdownOpenOnMultiSelect,
      shouldCloseOnSelfClick,
      onDropdownClosed,
      shouldCheckWithParentContainer,
    } = this.props;
    const { currentClickedTab } = this.state;

    // dropdown close condition on outside click
    // (if condition results into true then we simply return without further execution)

    if (!this.dropDownRef || !closeOnOutSideClick) {
      return;
    }

    // dropdown close condition on self click and also handles special case (when requirement is to keep dropdown opened,
    // so user can select multiple items from list)
    // (if condition results into true then we simply return without further execution)

    if (
      this.dropDownRef &&
      (this.dropDownRef.contains(event.target) ||
        (shouldCheckWithParentContainer &&
          _.includes(event.path, this.dropDownRef)) ||
        (keepDropdownOpenOnMultiSelect && event.defaultPrevented)) &&
      (!shouldCloseOnSelfClick || !_.isEmpty(currentClickedTab))
    ) {
      return;
    }

    // dropdown close condition on Button click
    // (if condition results into true then we simply return without further execution)
    if (
      this.buttonRef &&
      this.buttonRef.contains(event.target) &&
      !shouldCloseOnButtonClick
    ) {
      return;
    }

    event.stopPropagation();

    if (this.state.showDropdown && this._isMounted) {
      this.removeDocumentClickListener();
      // here we are setting dropdown state to false, to hide dropdown
      this.updateShowDropdown(!this.state.showDropdown);
      this.setState({ currentClickedTab: null });
      if (onDropdownClosed) {
        onDropdownClosed();
      }
    }
  };

  addDocumentClickListener = () => {
    document.addEventListener("click", this.handleDocumentMouseClick, false);
    document.addEventListener("keydown", this.handleEscKey, false);
  };

  removeDocumentClickListener = () => {
    document.removeEventListener("click", this.handleDocumentMouseClick, false);
    document.removeEventListener("keydown", this.handleEscKey, false);
  };

  handleButtonClick = event => {
    const { isButtonDisabled, disabled } = this.props;
    if (isButtonDisabled || disabled || event.defaultPrevented) {
      event.preventDefault();
      return;
    }
    event.preventDefault();
    // event.stopPropagation();

    // if(!this.state.showDropdown)
    this.updateShowDropdown(true);
  };

  updateShowDropdown = value => {
    const { showDropdown } = this.state;
    if (this.props.onUpdateShowDropDown) {
      this.props.onUpdateShowDropDown(value, showDropdown);
    }
    this.setState({ showDropdown: value });
  };

  onClose = () => {
    this.setState({ showDropdown: false });
  };

  onTabClick = ({ event, tab, parentTab = {} }) => {
    const { onItemClick } = this.props;
    const { currentClickedTab } = this.state;

    if (tab.isDisable) {
      return;
    }

    if (!_.isEmpty(parentTab)) {
      event.preventDefault();
    }

    if (
      _.get(tab, "children") &&
      _.get(currentClickedTab, "value") != tab.value
    ) {
      this.setState({ currentClickedTab: tab });
      return;
    }
    if (onItemClick) {
      onItemClick(tab.value, { parentTab });
    } else {
      tab.clickAction(tab.value, { parentTab });
    }
    if (!_.isEmpty(currentClickedTab)) {
      this.setState({ currentClickedTab: null });
    }
  };

  renderMenu = ({
    tabs,
    optionSvgStyle,
    activeValue,
    onItemClick,
    parentTab,
    dropdownListRefs,
  }) => {
    const { currentClickedTab } = this.state;
    const {
      dropdownName,
      isRightSubMenu,
      subMenuContainerStyle,
      highlightActiveElement,
      highlightStyle,
      dropdownActiveTabStyle,
    } = this.props;
    return _.map(tabs, (tab, index) => {
      const buttonStyle = classNames(
        "group",
        { [classes.dropDownItem]: true },
        { [classes.activeDropDownItem]: tab.value == activeValue },
        { [classes.disableTab]: tab.isDisable },
        {
          [classes.highlightActiveElement]:
            highlightActiveElement && tab.value == activeValue,
        }
      );
      const childrenTabs = _.get(tab, "children", []);
      const showSubMenu = _.get(currentClickedTab, "value", "") == tab.value;

      return (
        <div
          data-test-id={`${dropdownName}-${tab.value}`}
          ref={dropdownListRefs ? dropdownListRefs[_.get(tab, "label")] : null}
          key={index}
          className={buttonStyle}
          style={{
            position: _.get(childrenTabs, "length", 0) ? "relative" : "unset",
            ...this.props.itemStyle,
            ...(tab.style || {}),
            ...(highlightActiveElement && (highlightStyle || {})),
            ...(tab.value === activeValue ? dropdownActiveTabStyle : {}),
          }}
          onClick={event => {
            // TODO: remove this event.stopPropagation
            event.preventDefault();
            event.stopPropagation();
            this.onTabClick({ event, tab, parentTab });
          }}
        >
          {tab.renderComponent ? (
            tab.renderComponent
          ) : (
            <LinkWithTooltip
              isVisible={!_.isEmpty(tab.tooltip)}
              tooltip={tab.tooltip}
              linkStyles={{
                ...styles.linkStyles,
                ...(tab.isDisable ? { cursor: "not-allowed" } : {}),
              }}
            >
              {tab.svg ? (
                <div className={classes.optionSvg} style={optionSvgStyle}>
                  {tab.svg}
                </div>
              ) : null}
              <div
                className={classes.dropDownItemText}
                style={{ ...this.props.textStyle, ...(tab.textStyle || {}) }}
              >
                {tab.label}
              </div>
              {_.get(childrenTabs, "length", 0) > 0 && (
                <div className={classes.rightIconContainer}>
                  <RightIcon width={12} height={12} />
                </div>
              )}
            </LinkWithTooltip>
          )}

          {_.get(childrenTabs, "length", 0) > 0 && showSubMenu ? (
            <div
              className={classes.subMenuContainer}
              style={{
                left: isRightSubMenu ? `calc(100% + 2px)` : `unset`,
                right: !isRightSubMenu ? `calc(100% + 2px)` : `unset`,
                ...(subMenuContainerStyle || {}),
              }}
            >
              {this.renderMenu({
                tabs: childrenTabs,
                optionSvgStyle,
                activeValue,
                onItemClick,
                parentTab: tab,
              })}
            </div>
          ) : null}
        </div>
      );
    });
  };

  getAuthTabs = () => {
    const {
      authTabs: tabs,
      onItemClick,
      getAuthTabs,
      renderDropDownComponent,
      optionSvgStyle,
      activeValue,
      dropdownListRefs,
    } = this.props;

    if (renderDropDownComponent) {
      return renderDropDownComponent();
    }
    const authTabs = getAuthTabs ? getAuthTabs() : tabs;
    return this.renderMenu({
      tabs: authTabs,
      optionSvgStyle,
      activeValue,
      onItemClick,
      dropdownListRefs,
    });
  };

  updateDropDownRef = ref => {
    const { updateDropDownRef } = this.props;
    this.dropDownRef = ref;
    if (updateDropDownRef) {
      updateDropDownRef(ref);
    }
  };

  updateButtonDropDownRef = ref => {
    const { updateButtonDropDownRef } = this.props;

    if (updateButtonDropDownRef) {
      updateButtonDropDownRef(ref);
    }
  };

  updateButtonComponentRef = ref => {
    this.buttonRef = ref;
  };

  getDropdownDivStyle = () => {
    const { displayHandle } = this.props;
    const { showDropdown } = this.state;
    if (displayHandle) {
      return { display: showDropdown ? "flex" : "none" };
    } else {
      return { visibility: showDropdown ? "visible" : "hidden" };
    }
  };

  render() {
    const {
      disabled,
      shouldMountDropDownOnButtonClick,
      buttonComponentStyle,
      moreButtonClass,
      positionDropdownDivWithPopover,
      containerClass,
      buttonDropdownClass,
      label,
    } = this.props;

    const popoverProps = { ...this.popoverProps, ...this.props.popoverProps };

    const buttonStyle = classNames(
      { [classes.moreSvg]: true },
      { [classes.buttonFocus]: this.state.showDropdown },
      { [moreButtonClass]: true }
    );

    const containerClasses = classNames({
      [classes.container]: true,
      [containerClass]: !!containerClass,
    });

    const buttonDropdownClasses = classNames({
      [classes.buttonDropdown]: true,
      [buttonDropdownClass]: !!buttonDropdownClass,
    });

    const { showDropdown } = this.state;

    const renderButtonComponent = (
      <div
        className={classes.buttonComponent}
        ref={this.updateButtonComponentRef}
        style={buttonComponentStyle}
      >
        {this.props.buttonComponent ? (
          React.cloneElement(this.props.buttonComponent, { showDropdown })
        ) : (
          <div className={buttonStyle} style={this.props.moreIconStyle}>
            <MoreIcon width={16} height={16} />
          </div>
        )}
      </div>
    );

    // console.log('dropdownComponent==',this.props.dropdownComponent);
    const renderDropdownDivComp = (
      <div
        className={classes.dropDownDiv}
        style={{
          ...this.props.containerStyle,
          ...this.getDropdownDivStyle(),
        }}
        onClick={e => e.stopPropagation()}
        ref={this.updateDropDownRef}
      >
        {(!shouldMountDropDownOnButtonClick || showDropdown) &&
          (this.props.dropdownComponent
            ? this.props.dropdownComponent
            : this.getAuthTabs())}
      </div>
    );

    return (
      <div className={containerClasses} style={this.props.parentContainerStyle}>
        {!_.isEmpty(label) && <div className={classes.labelStyle}>{label}</div>}
        <div
          className={buttonDropdownClasses}
          style={{
            ...this.props.buttonParentStyle,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={this.handleButtonClick}
          ref={this.updateButtonDropDownRef}
        >
          {positionDropdownDivWithPopover ? (
            <Popover {...popoverProps} content={renderDropdownDivComp}>
              {renderButtonComponent}
            </Popover>
          ) : (
            <>{renderButtonComponent}</>
          )}
        </div>
        {!positionDropdownDivWithPopover && renderDropdownDivComp}
      </div>
    );
  }
}
/**
 * By default dropdown will close on outside click or self click according to props (shouldCloseOnSelfClick, closeOnOutSideClick)
 * But incase you want dropdown to keep open (e.g. you may wish to select multiple items one after another while keeping dropdown open)
 * then set keepDropdownOpenOnMultiSelect prop to true
 */
ButtonDropdown.defaultProps = {
  containerStyle: {},
  itemStyle: {},
  textStyle: {},
  parentContainerStyle: {},
  optionSvgStyle: {},
  shouldCloseOnSelfClick: true,
  moreIconStyle: {},
  disabled: false,
  displayHandle: true,
  isButtonDisabled: false,
  shouldMountDropDownOnButtonClick: false,
  closeOnOutSideClick: true,
  shouldCloseOnButtonClick: true,
  activeValue: "",
  buttonComponentStyle: {},
  keepDropdownOpenOnMultiSelect: false,
  showDropdown: false,
  positionDropdownDivWithPopover: false,
  popoverProps: {},
  containerClass: "",
  buttonDropdownClass: "",
  highlightActiveElement: false,
  isRightSubMenu: true,
  highlightStyle: {},
  label: "",
  dropdownName: "dropdownmenu",
};

ButtonDropdown.propTypes = {
  buttonComponent: PropTypes.element,
  dropdownComponent: PropTypes.element,
  itemStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  optionSvgStyle: PropTypes.object,
  parentContainerStyle: PropTypes.object,
  authTabs: PropTypes.array,
  onUpdateShowDropDown: PropTypes.func,
  onItemClick: PropTypes.func,
  textStyle: PropTypes.object,
  shouldCloseOnSelfClick: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  renderDropDownComponent: PropTypes.func,
  getAuthTabs: PropTypes.func,
  shouldMountDropDownOnButtonClick: PropTypes.bool,
  closeOnOutSideClick: PropTypes.bool,
  keepDropdownOpenOnMultiSelect: PropTypes.bool,
  showDropdown: PropTypes.bool,
  positionDropdownDivWithPopover: PropTypes.bool,
  popoverProps: PropTypes.object,
  highlightActiveElement: PropTypes.bool,
  isRightSubMenu: PropTypes.bool,
  highlightStyle: PropTypes.object,
  label: PropTypes.string,
  dropdownName: PropTypes.string,
};

export default ButtonDropdown;
