import React from "react";
import {
  I18nHOC,
  UIBaseContentEditable,
  CriteriaRating,
  LinkWithTooltip,
} from "UIComponents";
import { compose } from "react-apollo";
import classes from "./RubricTable.scss";
import { connect } from "react-redux";
import classNames from "classnames";
import { colors } from "Constants";
import { getStyleStrippedText } from "Utils";
import { Tick, InfoIcon } from "SvgComponents";
import { getCriteriaSetOfPypType, updateField } from "modules/Services";
import { writeAssessmentToolElementLocal } from "modules/AssessmentTool/AssessmentToolModule";
import {
  createPypElementRating,
  deletePypElementRating,
} from "AssessmentEvaluation/modules/AssessmentEvaluationModule";

const styles = {
  tooltipContainerStyle: {
    width: "500px",
  },
};
/*
  Todo : Handle Locking state
*/
const textAreaStyles = {
  cell: {
    padding: 0,
    color: colors.gray31,
    lineHeight: 1.57,
    fontSize: "1.2rem",
  },
};

class RubricTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.cellRefs = {};
    this.focusedCell = null;
    if (props.customRef) {
      props.customRef(this);
    }
  }

  blur = () => {
    if (this.focusedCell && this.cellRefs[this.focusedCell]) {
      this.cellRefs[this.focusedCell].blur();
    }
  };

  getHeaderRow = () => {
    const { achievementLevels } = this.props;

    return (
      <thead>
        <tr>
          <th className={classes.headerRowColumnCell}></th>
          {_.map(achievementLevels, level => {
            return (
              <th key={level.id} className={classes.headerRowCell}>
                {level.label}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  /**
   * This function creates and returns the body elements of the MYP rubric table depending
   * on whether rating is enabled or not.
   */
  getTableBody = () => {
    const {
      isEvaluation,
      t,
      isEvaluationEnable,
      isEvaluated,
      userType,
      seggregatedObjectives,
      parentId,
      parentType,
      studentId,
      studentIds = [],
      ratingElementSubType,
      ibPYPElementRatings,
      createPypElementRating,
      deletePypElementRating,
      getCriteriaSetOfPypType,
      showInsight,
      rubricRatingContainerStyle,
      rubricRatingTextContainerStyle,
      rubricRatingCompContainerStyle,
      showRatingContainerInViewMode,
    } = this.props;

    // currently we are picking the first criteria set as there is no distinguishing key
    // to filter out the appropriate criteria set. Later when such key is implemented then
    // we need to change this picking logic

    const criteriaSet = _.find(
      getCriteriaSetOfPypType({
        type: "MYP_OBJECTIVES",
        subType: ratingElementSubType,
      }),
      ({ criteriaType }) => {
        return criteriaType == "IB_DEFINED";
      }
    );

    const objectiveNameClass = classNames(
      { [classes.objectiveName]: true },
      { "text-label-2": true },
      { [classes.objectiveNameSpacing]: showInsight }
    );

    const academicCriteriaValues = _.get(
      criteriaSet,
      "academicCriteriaValues",
      []
    );
    const criteriaSetId = _.get(criteriaSet, "id", "");
    return _.map(seggregatedObjectives, (objectives, index) => {
      const nonLeafObjective = _.first(
        _.filter(objectives, objective => !objective.isLeaf)
      );
      const ibPypElementId = _.get(nonLeafObjective, "id", "");
      const selectedCriteria = _.find(ibPYPElementRatings, rating => {
        return (
          rating.ibPYPElementId === ibPypElementId &&
          rating.academicCriteriaSet.id === criteriaSetId
        );
      });

      const handleCriteriaClick = criteriaId => {
        if (
          selectedCriteria &&
          _.get(selectedCriteria, "academicCriteriaValue.id", "") === criteriaId
        ) {
          deletePypElementRating({
            studentIds,
            studentId,
            parentId,
            parentType,
            type: "MYP_OBJECTIVES",
            id: ibPypElementId,
            setId: criteriaSetId,
          });
        } else {
          createPypElementRating({
            parentId,
            parentType,
            studentId,
            studentIds,
            criteriaValue: criteriaId,
            type: "MYP_OBJECTIVES",
            id: ibPypElementId,
            setId: criteriaSetId,
            isPublished: isEvaluated,
            criteriaSet,
            academicCriteriaValue: _.find(academicCriteriaValues, {
              id: criteriaId,
            }),
          });
        }
      };
      return isEvaluation ? (
        <React.Fragment key={index}>
          <div className={objectiveNameClass}>{nonLeafObjective.label}</div>
          <div className={classes.tableWrapper}>
            <table className={classes.innerContainer}>
              {this.getHeaderRow()}
              <tbody>
                {_.map(objectives, objective => {
                  return this.getTableRow({ objective, isEvaluation });
                })}
              </tbody>
            </table>
          </div>
          {(isEvaluationEnable || isEvaluated) &&
          (userType === "staff" || showRatingContainerInViewMode) ? (
            <div
              className={classes.ratingContainer}
              style={rubricRatingContainerStyle}
            >
              <div
                className={classes.ratingTextContainer}
                style={rubricRatingTextContainerStyle}
              >
                <div className={classes.finalRatingHeaderText}>
                  {t("unitPlan:best_fit_rating_for_text_normal", {
                    label: _.get(nonLeafObjective, "label", ""),
                  })}
                  {userType === "staff" ? (
                    <div className={classes.alertIconContainer}>
                      <LinkWithTooltip
                        tooltip={t(
                          userType === "staff"
                            ? "classRoom:rubric_rating_tooltip"
                            : "classRoom:rubric_rating_tooltip_for_reader"
                        )}
                        href="#"
                        tooltipContainerStyle={styles.tooltipContainerStyle}
                        placement="right"
                      >
                        <InfoIcon
                          width={"16px"}
                          height={"16px"}
                          fill={colors.gray48}
                        />
                      </LinkWithTooltip>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                className={classes.ratingCompContainer}
                style={rubricRatingCompContainerStyle}
              >
                <CriteriaRating
                  shouldUpdateOnValueChnage={true}
                  isEvaluation={isEvaluationEnable}
                  options={academicCriteriaValues}
                  value={_.get(
                    selectedCriteria,
                    "academicCriteriaValue.id",
                    ""
                  )}
                  isViewMode={showRatingContainerInViewMode}
                  onCriteriaClick={handleCriteriaClick}
                />
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <tbody>
          {_.map(objectives, objective => {
            return this.getTableRow({ objective, isEvaluation });
          })}
        </tbody>
      );
    });
  };

  writeAssessmentToolElement = ({ id, type, data }) => {
    this.props.writeAssessmentToolElementLocal({ id, type, data });
    this.props.updateField({
      key: id,
      params: { id, type, data },
      type: "ASSESSMENT_TOOL_ELEMENT",
    });
  };

  updateInputRef = ({ ref, refKey }) => {
    this.cellRefs[refKey] = ref;
  };

  setFocusedCell = refKey => {
    this.focusedCell = refKey;
  };

  getTableRow = ({ objective }) => {
    const {
      achievementLevels,
      assessmentTool,
      mode,
      t,
      isEvaluationEnable,
      updateEvaluationCell,
      toolEvaluationResponses,
      isEvaluatedByStudent,
      userType,
      isEvaluation,
      showInsight,
      insights,
      insightColor,
    } = this.props;
    const { id, label, isLeaf, subText } = objective;
    const { descriptors } = assessmentTool;
    if (!isLeaf && isEvaluation) {
      return null;
    }

    const groupedToolEvaluationResponse = _.groupBy(
      toolEvaluationResponses,
      toolEvaluationResponse => toolEvaluationResponse.creatorType
    );

    const staffToolEvaluationResponses = _.get(
      groupedToolEvaluationResponse,
      "STAFF",
      []
    );

    const studentToolEvaluationResponses = _.get(
      groupedToolEvaluationResponse,
      "STUDENT",
      []
    );

    const headerColumnCellClass = classNames(
      { [classes.headerColumnCell]: true },
      { [classes.mergedCell]: !isLeaf }
    );
    return (
      <tr key={id}>
        <td
          className={headerColumnCellClass}
          colSpan={isLeaf ? 1 : achievementLevels.length + 1}
        >
          {label}
        </td>
        {isLeaf &&
          _.map(achievementLevels, level => {
            const descriptor =
              _.find(descriptors, descriptor => {
                return (
                  descriptor.objective.id == objective.id &&
                  descriptor.achievementLevel.id == level.id
                );
              }) || {};

            const objectiveId = _.get(descriptor, "objective.id", "");
            const achievementLevelId = _.get(
              descriptor,
              "achievementLevel.id",
              ""
            );
            descriptor.label = getStyleStrippedText({ text: descriptor.label });

            const isSelected =
              _.findIndex(
                staffToolEvaluationResponses,
                response =>
                  response.rowId == objectiveId &&
                  response.columnId == achievementLevelId
              ) >= 0;
            const isStudentSelected =
              _.findIndex(
                studentToolEvaluationResponses,
                response =>
                  response.rowId == objectiveId &&
                  response.columnId == achievementLevelId
              ) >= 0 && isEvaluatedByStudent;

            const responseCount = _.get(
              _.find(
                insights,
                insight =>
                  insight.rowId == objectiveId &&
                  insight.columnId == achievementLevelId
              ),
              "responseCount",
              0
            );

            const studentArray = _.get(
              _.find(
                insights,
                insight =>
                  _.isEqual(
                    _.get(insight, "rowId", ""),
                    _.get(descriptor, "objective.id", "")
                  ) &&
                  _.isEqual(
                    _.get(insight, "columnId"),
                    _.get(descriptor, "achievementLevel.id")
                  )
              ),
              "studentsList",
              []
            );

            const moreComponent = (
              <div className={classes.toolTipConainer}>
                {_.map(studentArray, (student, i) => {
                  const { firstName, lastName } = student;
                  return (
                    <div className={classes.moreToolTipItem} key={i}>
                      {firstName} {lastName}
                    </div>
                  );
                })}
              </div>
            );

            const isToolTipVisible = !_.isEmpty(studentArray);

            const cellClass = classNames(
              { [classes.bodyRowCell]: true },
              {
                [classes.clickableCell]:
                  isEvaluationEnable && userType == "staff",
              },
              {
                [classes.clickableStudentCell]:
                  isEvaluationEnable && userType == "student",
              },
              {
                [classes.selectableCell]: isSelected,
              },
              {
                [classes.selectableStudentCell]:
                  !isSelected && isStudentSelected,
              }
            );

            const refKey = `Descriptor-${_.get(descriptor, "id", "")}`;
            return (
              <td
                key={level.id}
                className={cellClass}
                onClick={
                  isEvaluationEnable
                    ? () =>
                        updateEvaluationCell({
                          rowId: descriptor.objective.id,
                          columnId:
                            (userType == "staff" && isSelected) ||
                            (userType == "student" && isStudentSelected)
                              ? null
                              : descriptor.achievementLevel.id,
                          isSelected,
                        })
                    : null
                }
              >
                {isSelected || isStudentSelected ? (
                  <div className={classes.tickSvgContainer}>
                    {isStudentSelected && (
                      <div
                        style={{ marginRight: isSelected ? "8px" : "0px" }}
                        className={classes.tickSvgComponent}
                      >
                        <Tick
                          width={16}
                          height={16}
                          fillBackground={colors.yellow50}
                        />
                      </div>
                    )}
                    {isSelected && (
                      <div className={classes.tickSvgComponent}>
                        <Tick
                          width={16}
                          height={16}
                          fillBackground={colors.violet63}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
                {/* <TextAreaInput
                  value={descriptor.label}
                  textAreaStyles={_.merge(textAreaStyles.cell)}
                  updateInputRef={ref => this.updateInputRef({ ref, refKey })}
                  onBlurInputField={() => this.setFocusedCell(null)}
                  onFocusInputField={() => this.setFocusedCell(refKey)}
                  emptyText={""}
                  name={"label"}
                  disabled={_.includes(descriptor.id, "NEW")}
                  mode={mode}
                  placeholder={t("common:click_to_add_withLabel", {
                    label: t("common:definition")
                  })}
                  updateInputField={params =>
                    this.writeAssessmentToolElement({
                      id: descriptor.id,
                      data: params,
                      type: "MYP_OBJECTIVE_DESCRIPTOR"
                    })
                  }
                /> */}
                {!showInsight ? (
                  <UIBaseContentEditable
                    value={descriptor.label}
                    style={_.merge(textAreaStyles.cell)}
                    textStyle={_.merge(textAreaStyles.cell)}
                    updateInputRef={ref => this.updateInputRef({ ref, refKey })}
                    onBlurInputField={() => this.setFocusedCell(null)}
                    onFocusInputField={() => this.setFocusedCell(refKey)}
                    name={"label"}
                    disabled={_.includes(descriptor.id, "NEW")}
                    mode={mode}
                    placeholder={t("common:click_to_add_withLabel", {
                      label: t("common:definition"),
                    })}
                    allowEnter={true}
                    shouldPasteAsPlainText={false}
                    shouldParseInPlainText={false}
                    updateInputField={params => {
                      this.writeAssessmentToolElement({
                        id: descriptor.id,
                        data: params,
                        type: "MYP_OBJECTIVE_DESCRIPTOR",
                      });
                    }}
                  />
                ) : (
                  <div style={{ ...textAreaStyles.cell }}>
                    <LinkWithTooltip
                      tooltip={moreComponent}
                      placement={"top"}
                      isVisible={isToolTipVisible}
                    >
                      <div className={classes.insight}>
                        <div
                          className={classes.insightCount}
                          style={{ color: insightColor }}
                        >
                          {responseCount}
                        </div>
                        <div className={classes.insightStudent}>
                          {t("common:student", { count: responseCount })}
                        </div>
                      </div>
                    </LinkWithTooltip>
                    <div
                      className={classes.insightDescriptor}
                      dangerouslySetInnerHTML={{
                        __html: descriptor.label,
                      }}
                    />
                  </div>
                )}
              </td>
            );
          })}
      </tr>
    );
  };
  render() {
    const { mode, isEvaluation } = this.props;
    return (
      <div
        className={classes.container}
        style={mode == "view" ? { marginTop: 0 } : {}}
      >
        {mode == "edit" && (
          <div className={classes.noteContainer}>
            <span className={classes.noteLabel}>{`Note:`}</span>
            <span>{`You can edit each cell of the rubric to include task specific clarifications`}</span>
          </div>
        )}
        {!isEvaluation ? (
          <div className={classes.tableWrapper}>
            <table className={classes.innerContainer}>
              {this.getHeaderRow()}
              {this.getTableBody()}
            </table>
          </div>
        ) : (
          this.getTableBody()
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  writeAssessmentToolElementLocal,
  updateField,
  getCriteriaSetOfPypType,
  createPypElementRating,
  deletePypElementRating,
};

const getSeggregatedObjectives = ({ isEvaluation, objectives }) => {
  if (isEvaluation) {
    const objectivesObj = _.keyBy(objectives, "id");
    const seggregatedObjectives = _.reduce(
      objectives,
      (result, currObj) => {
        if (!currObj.isLeaf) {
          const groupedObjectives = [];
          groupedObjectives.push(currObj);
          const { children } = currObj;
          _.forEach(children, childrenId => {
            if (objectivesObj[childrenId]) {
              groupedObjectives.push(objectivesObj[childrenId]);
            }
          });
          result.push(groupedObjectives);
        }
        return result;
      },
      []
    );
    return seggregatedObjectives;
  }
  return [objectives];
};
const getSeggregatedObjectivesMemoize = _.memoize(
  params => getSeggregatedObjectives(params),
  params => JSON.stringify(params)
);

const mapStateToProps = (state, ownProps) => {
  const { objectives, isEvaluation } = ownProps;
  const seggregatedObjectives = getSeggregatedObjectivesMemoize({
    objectives,
    isEvaluation,
  });
  return {
    userType: state.login.userInfo.user_type,
    seggregatedObjectives,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "classRoom"] }),
  connect(mapStateToProps, mapActionCreators)
)(RubricTable);

//Need Review @kunal
