import gql from "graphql-tag";

export const markConversationAsReadMutation = gql`
  mutation markConversationAsRead(
    $id: ID!
    $excludeItemTypes: [MESSAGE_TYPE_ENUM!]
    $includeItemTypes: [MESSAGE_TYPE_ENUM!]
  ) {
    platform {
      markConversationAsReadV2(
        input: { conversation: $id, excludeItemTypes: $excludeItemTypes }
      ) {
        ... on Conversation {
          id
          unreadMessageCount
          unreadMessageCounts(
            filters: { includeItemTypes: $includeItemTypes }
          ) {
            itemType
            count
          }
        }
      }
    }
  }
`;
