import { withAsyncRouteLoading } from "UIComponents";
import UnitPlanDetails from "UnitPlanDetails";
import { AssessmentDetails } from "Assessments";

export default store => ({
  path: ":standardId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "snp"
        );
      })
  ),

  childRoutes: [AssessmentDetails(store), UnitPlanDetails(store)],
});
