import React, { useState } from "react";
import { SearchBar, withLoader } from "UIComponents";
import { graphql, compose } from "react-apollo";
import { searchContainerStyle } from "./StandardListWithSearchStyles";
import classes from "./StandardListWithSearch.scss";

import { getSnpSetDetailsQuery } from "SnP_v2/modules/SnPQueries";
import { getSnpSetDetailsFromCache } from "SnP_v2/modules/SnPGraphqlHelpers";
import StandardList from "./StandardList";
import { getStandardsMemoized, getFilteredStandardsMemoized } from "./utils";

const StandardListWithSearch = props => {
  const [searchText, setSearchText] = useState("");
  const {
    updateSelectedPractices,
    t,
    standards,
    practiceListsCollection,

    itemDetails,

    cycleDetails,
  } = props;

  const matchingStandards = getFilteredStandardsMemoized({
    standards,
    searchText,
  });

  return (
    <div className={classes.container}>
      <SearchBar
        placeholder={t("common:search_for_with_label", {
          label: t("snp:practices_or_requirements"),
        })}
        customStyle={searchContainerStyle}
        changeSearchTerm={setSearchText}
      />
      {_.size(matchingStandards) ? (
        <div className={classes.standardsContainer}>
          <div className={classes.standardsScrollContainer}>
            <StandardList
              practiceListsCollection={practiceListsCollection}
              updateSelectedPractices={updateSelectedPractices}
              areAllStandardsOpen={!!_.size(searchText)}
              standards={matchingStandards}
              itemDetails={itemDetails}
              cycleDetails={cycleDetails}
            />
          </div>
        </div>
      ) : (
        <div className={classes.noDataText}>
          {t("common:no_with_label", { label: t("common:practice_plural") })}
        </div>
      )}
    </div>
  );
};

export default compose(
  graphql(getSnpSetDetailsQuery, {
    name: "getSnpSetDetails",
    alias: "getSnpSetDetails",
    options: ({ setId }) => ({
      fetchPolicy: "cache-first", //standards and practices will not change, so they only needed to fetch once.
      variables: {
        setId,
      },
    }),
    props({ getSnpSetDetails: { networkStatus }, ownProps: { setId, t } }) {
      const queryData = getSnpSetDetailsFromCache({ setId });

      const standards = getStandardsMemoized({ frameworks: queryData, t });

      return {
        isData: !_.isEmpty(queryData),
        standards,
        isLoading: _.includes([1, 2], networkStatus),
      };
    },
  }),
  withLoader
)(StandardListWithSearch);
