/**--external-- */
import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";

import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";

/**--internal-- */
import classes from "./CurriculumProgramLogo.scss";

const FONT_SIZE_TO_HEIGHT_RATIO = 0.3125;

const CurriculumProgramLogo = ({ type, acronym, height }) => {
  /**
   * Set font size based on container height
   */
  const fontSize = Math.floor(parseInt(height) * FONT_SIZE_TO_HEIGHT_RATIO);

  const containerClasses = classNames(classes.container, {
    [classes.pypContainer]: type == CURRICULUM_TYPE_PYP,
    [classes.mypContainer]: type == CURRICULUM_TYPE_MYP,
    [classes.ubdContainer]: type == CURRICULUM_TYPE_UBD,
    [classes.dpContainer]: type == CURRICULUM_TYPE_DP,
  });

  const containerStyle = {
    height: height,
    fontSize,
    width: height,
  };

  return (
    <div className={containerClasses} style={containerStyle}>
      {acronym}
    </div>
  );
};

CurriculumProgramLogo.propTypes = {
  type: propTypes.string.isRequired,
  acronym: propTypes.string.isRequired,
};

export default CurriculumProgramLogo;
