import gql from "graphql-tag";
import {
  staffFragment,
  studentFragment,
  attachmentFragment,
  pypElementRatingFragment,
  toolResponseFragment,
  studentResponseFragment,
} from "modules/CommonFragments";

import { assessmentFragment } from "Assessments/modules/AssessmentFragments";

export const studentAssessmentEvaluationFragment = {
  studentEvaluationFeed: gql`
    fragment studentEvaluationItem on StudentAssessmentEvaluation {
      id
      student {
        ...studentItem
      }
      submittedByStudent
      isComplete
      isActive
    }
    ${studentFragment.basicStudentDetails}
  `,
  studentEvaluationStatus: gql`
    fragment studentEvaluationItem on StudentAssessmentEvaluation {
      id
      student {
        id
        firstName
        lastName
        isArchived
      }
      isComplete
      isActive
      submittedByStudent
    }
  `,
  studentEvaluationDetails: gql`
    fragment studentEvaluationItem on StudentAssessmentEvaluation {
      id
      student {
        ...studentItem
      }
      toolResponses(userFilter: ALL) {
        ...toolResponseItem
      }
      ibPYPElementRatings {
        ...ratingItem
      }
      isComplete
      isActive
      submittedByStudent
      evaluateIBPYPElements
      remark
      studentRemark
      studentAttachments {
        ...attachmentItem
      }
      media {
        id
        attachments {
          ...attachmentItem
        }
        items {
          item {
            id
            ...studentResponseItem
          }
          itemType
        }
      }
      assessmentEvaluation {
        isEvaluatedByStudent
        isComplete
      }
      assessment {
        id
        curriculumProgram {
          id
          type
        }
      }
    }
    ${pypElementRatingFragment.studentElementRating}
    ${attachmentFragment.attachment}
    ${toolResponseFragment.toolResponse}
    ${studentFragment.basicStudentDetails}
    ${studentResponseFragment.studentResponse}
  `,
};

export const assessmentEvaluationFragment = {
  evaluationFeed: gql`
    fragment evaluationItem on AssessmentEvaluation {
      id
      isComplete
      isEvaluatedByStudent
      createdBy {
        ...staffItem
      }
      assessment {
        ...assessmentItem
      }
      studentEvaluations {
        ...studentEvaluationItem
      }
      createdAt
    }
    ${assessmentFragment.assessmentEvaluationFeed}
    ${studentAssessmentEvaluationFragment.studentEvaluationStatus}
    ${staffFragment.basicStaffDetails}
  `,
  evaluationDetails: gql`
    fragment evaluationItem on AssessmentEvaluation {
      id
      isComplete
      studentEvaluations {
        ...studentEvaluationItem
      }
      isEvaluatedByStudent
      assessment {
        ...assessmentItem
      }
    }
    ${assessmentFragment.assessmentEvaluationDetails}
    ${studentAssessmentEvaluationFragment.studentEvaluationFeed}
  `,
  evalutionInsight: gql`
    fragment evaluationItem on AssessmentEvaluation {
      id
      insights {
        rowId
        columnId
        studentResponseCount
        teacherResponseCount
      }
    }
  `,
};

export const courseAssessmentEvaluationFragment = {
  courseAssessmentEvaluationCount: gql`
    fragment courseAssessmentEvaluationItem on Course {
      id
      assessmentEvaluations {
        totalCount
      }
    }
  `,
  courseAssessmentEvaluationIds: gql`
    fragment courseAssessmentEvaluationItem on Course {
      id
      assessmentEvaluations {
        edges {
          node {
            assessment {
              id
            }
          }
        }
      }
    }
  `,
  courseAssessmentEvaluationFeed: gql`
    fragment courseAssessmentEvaluationItem on Course {
      id
      assessmentEvaluations(
        filters: {
          userIds: $userIds
          state: $state
          unitPlanIds: $unitPlanIds
          searchText: $searchText
        }
      ) {
        totalCount
        edges {
          node {
            ...evaluationItem
          }
        }
      }
    }
    ${assessmentEvaluationFragment.evaluationFeed}
  `,
};

export const unitPlanFragment = {
  unitPlanDetails: gql`
    fragment unitPlanItem on UnitPlan {
      id
      resourceLibrary {
        edges {
          type
          node {
            ...assessmentItem
          }
        }
      }
    }
    ${assessmentFragment.assessmentEvaluationModalFeed}
  `,
};
