import React from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";

const AttachmentPhotoSvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.46154C0 1.55611 1.5561 0 3.46152 0H14.5384C16.4438 0 17.9999 1.55611 17.9999 3.46154V14.5385C17.9999 16.4439 16.4438 18 14.5384 18H3.46152C1.5561 18 0 16.4439 0 14.5385V3.46154ZM3.46152 1.38462C2.3208 1.38462 1.38461 2.32081 1.38461 3.46154V14.5385C1.38461 15.6792 2.3208 16.6154 3.46152 16.6154H14.5384C15.6791 16.6154 16.6153 15.6792 16.6153 14.5385V3.46154C16.6153 2.32081 15.6791 1.38462 14.5384 1.38462H3.46152Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4616 4.15384C11.6969 4.15384 11.077 4.77375 11.077 5.53846C11.077 6.30316 11.6969 6.92307 12.4616 6.92307C13.2263 6.92307 13.8462 6.30316 13.8462 5.53846C13.8462 4.77375 13.2263 4.15384 12.4616 4.15384ZM9.69238 5.53846C9.69238 4.00905 10.9322 2.76923 12.4616 2.76923C13.991 2.76923 15.2308 4.00905 15.2308 5.53846C15.2308 7.06786 13.991 8.30769 12.4616 8.30769C10.9322 8.30769 9.69238 7.06786 9.69238 5.53846Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81816 10.1126C6.88371 9.17816 5.33941 9.07361 4.30592 9.88953L4.29614 9.8971L1.11155 12.3202C0.807263 12.5517 0.372907 12.4927 0.141386 12.1884C-0.0901349 11.8841 -0.0311487 11.4498 0.273136 11.2183L3.45304 8.79875C5.04688 7.54471 7.36975 7.71493 8.78846 9.12479L16.1187 15.971C16.3981 16.2319 16.413 16.67 16.1521 16.9495C15.8911 17.2289 15.453 17.2439 15.1736 16.9829L7.83515 10.129C7.82939 10.1236 7.82373 10.1182 7.81816 10.1126Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5771 11.2846C15.0236 10.8695 14.3014 10.9086 13.7821 11.428L12.259 12.9511C11.9886 13.2214 11.5503 13.2214 11.2799 12.9511C11.0096 12.6807 11.0096 12.2424 11.2799 11.972L12.803 10.4489C13.8059 9.44596 15.2976 9.34607 16.4054 10.175L17.7186 11.1427C18.0264 11.3695 18.092 11.8029 17.8652 12.1107C17.6384 12.4185 17.205 12.4842 16.8972 12.2573L15.5771 11.2846Z"
        fill={props.fill}
      />
    </svg>
  );
};

AttachmentPhotoSvg.defaultProps = {
  height: 32,
  width: 32,
};
AttachmentPhotoSvg.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
};
export default AttachmentPhotoSvg;
