import React, { useCallback } from "react";
import TableRow from "../TableRow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  handleDragAndDrop,
  shouldNodeRender,
  updateGradesOnDrop,
  getUniqueUpdatedNodes,
  selectionBox,
} from "NodeEditor/Utils";

const TableRows = props => {
  const {
    nodes,
    setNodes,
    rootNodeId,
    mode,
    maxDepth,
    indexCountArray,
    droppableSnapshot,
    setNodesRef,
    placeholdersText,
    changeSelectedSidebarItem,
    gradeOptions,
    filterNodesIds,
    filters,
    updateNodesInDraft,
    userId,
    currentEditorConfig,
    filterNodeIds,
    organizationTags,
    inputForMultiLevelTagsQuery,
    organizationTagsObjectMap,
    isActionDisabled,
    isCoachmarksRunning,
    showSelectionBox,
    onCheckBoxClick,
    taggedNodeIds,
    uptoDepth,
    showUptoDepth,
    selectedNodes,
    selectedTaggedIds,
    nodeType,
    maxSidebarDepth,
    isScrollManual,
  } = props;

  const rootNode = nodes[rootNodeId];
  const hasChildren = !_.isEmpty(_.get(rootNode, "children", []));
  const depth = _.get(rootNode, "depth");

  const shouldRowRender = shouldNodeRender({
    filterNodeIds,
    rootNodeId,
    showUptoDepth,
    uptoDepth,
    depth,
  });

  const shouldShowSelectionBox = selectionBox({
    hasChildren,
    showSelectionBox,
    depth,
    uptoDepth,
    showUptoDepth,
    selectedNodes,
    selectedTaggedIds,
    nodeId: rootNodeId,
  });

  return (
    <>
      {rootNode && rootNode.depth !== 0 && shouldRowRender && (
        <TableRow
          nodes={nodes}
          setNodes={setNodes}
          nodeId={rootNodeId}
          mode={mode}
          maxDepth={maxDepth}
          indexCountArray={indexCountArray}
          droppableSnapshot={droppableSnapshot}
          setNodesRef={setNodesRef}
          placeholdersText={placeholdersText}
          changeSelectedSidebarItem={changeSelectedSidebarItem}
          gradeOptions={gradeOptions}
          filterNodesIds={filterNodesIds}
          filters={filters}
          updateNodesInDraft={updateNodesInDraft}
          organizationTags={organizationTags}
          inputForMultiLevelTagsQuery={inputForMultiLevelTagsQuery}
          organizationTagsObjectMap={organizationTagsObjectMap}
          userId={userId}
          currentEditorConfig={currentEditorConfig}
          isActionDisabled={isActionDisabled}
          isCoachmarksRunning={isCoachmarksRunning}
          showSelectionBox={shouldShowSelectionBox}
          onCheckBoxClick={onCheckBoxClick}
          taggedNodeIds={taggedNodeIds}
          hasChildren={hasChildren}
          maxSidebarDepth={maxSidebarDepth}
          isScrollManual={isScrollManual}
        />
      )}
      {hasChildren &&
        _.map(rootNode.children, childId => {
          return (
            <TableRows
              key={childId}
              nodes={nodes}
              setNodes={setNodes}
              rootNodeId={childId}
              mode={mode}
              maxDepth={maxDepth}
              indexCountArray={indexCountArray}
              droppableSnapshot={droppableSnapshot}
              setNodesRef={setNodesRef}
              placeholdersText={placeholdersText}
              changeSelectedSidebarItem={changeSelectedSidebarItem}
              gradeOptions={gradeOptions}
              filterNodesIds={filterNodesIds}
              filters={filters}
              updateNodesInDraft={updateNodesInDraft}
              organizationTags={organizationTags}
              inputForMultiLevelTagsQuery={inputForMultiLevelTagsQuery}
              organizationTagsObjectMap={organizationTagsObjectMap}
              userId={userId}
              currentEditorConfig={currentEditorConfig}
              filterNodeIds={filterNodeIds}
              isActionDisabled={isActionDisabled}
              isCoachmarksRunning={isCoachmarksRunning}
              showSelectionBox={showSelectionBox}
              showUptoDepth={showUptoDepth}
              taggedNodeIds={taggedNodeIds}
              onCheckBoxClick={onCheckBoxClick}
              uptoDepth={uptoDepth}
              selectedNodes={selectedNodes}
              selectedTaggedIds={selectedTaggedIds}
              maxSidebarDepth={maxSidebarDepth}
              isScrollManual={isScrollManual}
            />
          );
        })}
    </>
  );
};

const TableBody = props => {
  const indexCountArray = [];

  const {
    nodes,
    setNodes,
    setSidebarSelectedItem,
    updateNodesInDraft,
    maxSidebarDepth,
  } = props;

  const handleOnDragEnd = result => {
    const updatedNodes = handleDragAndDrop({
      result,
      nodes,
      setNodes,
      indexCountArray,
    });

    if (_.size(updatedNodes) > 0) {
      //sometimes we get duplicate objects in updateNodes array. so we need to make them unique list of objects
      let uniqueUpdatedNodes = getUniqueUpdatedNodes({
        previouslyUpdateNodes: updatedNodes,
      });

      // while drag and drop there will be hierarchically change in grades.
      const updatedGradesNodes = updateGradesOnDrop({
        nodeId: result.draggableId,
        nodes,
        setNodes,
      });
      uniqueUpdatedNodes = getUniqueUpdatedNodes({
        previouslyUpdateNodes: uniqueUpdatedNodes,
        latestUpdateNodes: updatedGradesNodes,
      });

      updateNodesInDraft({ updatedNodes: uniqueUpdatedNodes });
    }
  };

  const changeSelectedSidebarItem = ({ params }) => {
    setSidebarSelectedItem(params);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={"TABLE_BODY"}>
        {(provided, snapshot) => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            <TableRows
              {...props}
              indexCountArray={indexCountArray}
              droppableSnapshot={snapshot}
              changeSelectedSidebarItem={changeSelectedSidebarItem}
              maxSidebarDepth={maxSidebarDepth}
            />
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableBody;
