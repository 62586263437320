import { createRegex, generateRandomId } from "Utils";
import ReactDOMServer from "react-dom/server";
import React from "react";
import ACLStore from "lib/aclStore";
import {
  CURRICULUM_TYPE_DP,
  ELEMENT_TYPE_DP_SYLLABUS,
  ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK,
} from "Constants/stringConstants";
import _ from "lodash";

export const columnIds = {
  label: "LABEL",
  code: "CODE",
  grade: "GRADE",
  tag: "TAG",
  starQues: "STAAR",
};

export const nodeTypeSettingsNameMap = {
  BENCHMARK: "BenchmarkPYPEditorColumns",
  MYP_LEARNING_STANDARD: "BenchmarkMYPEditorColumns",
  UBD_LEARNING_STANDARD: "BenchmarkUBDEditorColumns",
  DP_SUBJECT_STANDARD: "BenchmarkDPEditorColumns",
  DP_SYLLABUS: "SyllabusDPEditorColumns",
};

const modesList = ["view", "edit"];

export const getFilteredColumns = ({ allowedColumns, mode }) => {
  return _.filter(allowedColumns, col => {
    const perm = _.get(col, "perm", true);
    const colModes = _.get(col, "modes", modesList);
    return perm && _.includes(colModes, mode);
  });
};

const getSortedArray = ({ inputObject = {} }) =>
  _.orderBy(_.values(inputObject), "displaySequence");

export const getSortedArrayMemoized = _.memoize(
  params => getSortedArray(params),
  params => JSON.stringify(params)
);

const PLANNER_ELEMENTS_CONFIG = {
  columns: [
    { id: columnIds.label, label: "scopeAndSequence:learning_standards" },
    { id: columnIds.code, label: "scopeAndSequence:code" },
    { id: columnIds.grade, label: "scopeAndSequence:grades" },
    { id: columnIds.tag, label: "scopeAndSequence:tags" },
  ],
  errorCheckFields: ["label", "grades"],
  errorCheckFieldLabels: {
    label: "scopeAndSequence:standard_label_missing_warning",
    grades: "scopeAndSequence:standard_grades_missing_warning",
  },
  addRootNodeTagging: true,
};

const additionalColumns = {
  GRADE: {
    id: columnIds.grade,
    label: "scopeAndSequence:grades",
    style: { maxWidth: "300px" },
    hide: [ELEMENT_TYPE_DP_SYLLABUS, ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK], //we need to hide this column for DP_SYLLABUS but can be handled for other planner elements if needed
    displayOrder: 3,
  },
  TAG: {
    id: columnIds.tag,
    label: "scopeAndSequence:tags",
    style: { maxWidth: "300px" },
    hide: [ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK],
    displayOrder: 4,
  },
  CODE: {
    id: columnIds.code,
    label: "scopeAndSequence:code",
    style: { maxWidth: "250px" },
    hide: [ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK],
    displayOrder: 2,
  },
};

export const columnLabels = ({ t, nodeType }) => {
  return _.reduce(
    additionalColumns,
    (result, item) => {
      if (!_.includes(_.get(item, "hide", []), nodeType))
        result.push({ value: item.id, label: t(item.label) });
      return result;
    },
    []
  );
};

// This function for getting max depth is used only while configuring and subject using showEditLevels component
export const maxLevelLengthByNodeType = ({ nodeType }) => {
  const canAddDeeperLevels = ACLStore.can("FeatureFlag:DeeperSNSLevels");

  switch (nodeType) {
    case "MYP_LEARNING_STANDARD":
    case "UBD_LEARNING_STANDARD":
    case "BENCHMARK":
    case "DP_SUBJECT_STANDARD":
      return canAddDeeperLevels ? 5 : 3;
    default:
      return 3;
  }
};

export const getRootNodeIds = ({ nodes }) => {
  return _.reduce(
    nodes,
    (result, node) => {
      if (_.isEmpty(node.parent)) {
        result.push(node.id);
      }
      return result;
    },
    []
  );
};

//This is a generic configuration for the NodeEditor for ATLs and Content Standards / Learning Standards
const getNodeEditorConfig = ({
  configuredColumns,
  nodeType,
  openedFrom,
  showStarQues,
  addRootNodeTagging,
  nodeEditorConfigProp,
}) => {
  const COLUMNS = {
    BENCHMARK: {
      columns: [
        {
          id: columnIds.label,
          label: "scopeAndSequence:standards_plural",
        },
        {
          id: columnIds.code,
          label: "scopeAndSequence:code",
        },
        {
          id: columnIds.grade,
          label: "scopeAndSequence:grades",
        },
        {
          id: columnIds.tag,
          label: "scopeAndSequence:tags",
        },
        {
          id: columnIds.starQues,
          label: "academicSetup:benchmarks_question_label",
          modes: ["view"],
          perm: ACLStore.can("TeacherPortal:PlannerBenchmarkQuestions"),
        },
      ],
      errorCheckFields: ["label", "grades"],
      errorCheckFieldLabels: {
        label: "scopeAndSequence:standard_label_missing_warning",
        grades: "scopeAndSequence:standard_grades_missing_warning",
      },
    },
    MYP_LEARNING_STANDARD: PLANNER_ELEMENTS_CONFIG,

    UBD_LEARNING_STANDARD: PLANNER_ELEMENTS_CONFIG,
    DP_SUBJECT_STANDARD: PLANNER_ELEMENTS_CONFIG,

    ATL: {
      columns: [
        {
          id: columnIds.grade,
          label: "scopeAndSequence:grades",
        },
        {
          id: columnIds.label,
          label: "common:atls_label",
        },
      ],
    },
    UNIT_PLAN: {
      columns: [
        {
          id: columnIds.label,
          label: "scopeAndSequence:standards_plural",
          displayOrder: 1,
        },
        {
          id: columnIds.starQues,
          label: "academicSetup:benchmarks_question_label",
          modes: ["view"],
          perm:
            ACLStore.can("TeacherPortal:PlannerBenchmarkQuestions") &&
            showStarQues,
          style: { maxWidth: "10%" },
          displayOrder: 5,
        },
      ],
      canEdit: false,
      showSelectionBox: true,
      warningText: false,
      addRootNodeTagging,
      ...nodeEditorConfigProp,
    },
    PROGRESS_REPORT: {
      columns: [
        {
          id: columnIds.label,
          label: "scopeAndSequence:standards_plural",
          displayOrder: 1,
        },
        {
          id: columnIds.starQues,
          label: "academicSetup:benchmarks_question_label",
          modes: ["view"],
          perm:
            ACLStore.can("TeacherPortal:PlannerBenchmarkQuestions") &&
            showStarQues,
          style: { maxWidth: "10%" },
          displayOrder: 5,
        },
      ],
      tableStyle: { minWidth: "unset" },
      canEdit: false,
      showUptoDepth: true,
      showHeader: false,
      showSidebar: true,
      warningText: false,
      addRootNodeTagging,
    },
  };

  const nodeEditorConfig = COLUMNS[openedFrom || nodeType];
  if (openedFrom && _.size(configuredColumns) > 0) {
    let finalColumns = nodeEditorConfig.columns;
    _.forEach(configuredColumns, col => {
      finalColumns.push(additionalColumns[col]);
    });
    finalColumns = _.sortBy(finalColumns, ["displayOrder"]);

    nodeEditorConfig.columns = finalColumns;
  }

  return nodeEditorConfig;
};

export const getNodeEditorConfigMemoize = _.memoize(
  params => getNodeEditorConfig(params),
  params => JSON.stringify(params)
);

// give params and it will update the node data
export const updateNode = ({ setNodes, nodeId, params = {} }) => {
  setNodes(prevNodes => {
    return {
      ...prevNodes,
      [nodeId]: {
        ...prevNodes[nodeId],
        ...params,
      },
    };
  });
};

//we click on add new row button on table header, we should add it as a first child
export const onAddFirstRow = ({ rootNodeId, nodes, setNodes, userId }) => {
  const newNodes = { ...nodes };
  const rootNode = newNodes[rootNodeId];
  // create a new node
  const newNode = {
    id: "NEW_" + Date.now() + `${userId}`,
    label: "",
    parentId: rootNodeId,
    children: [],
    depth: 1,
    code: "",
    grades: [],
    tags: [],
    isNew: true,
  };
  //insert it in first position of rootNode's children array
  rootNode.children.unshift(newNode.id);
  newNodes[rootNodeId] = rootNode;
  newNodes[newNode.id] = newNode;
  //finally update the state
  setNodes(newNodes);
  const updatedNodesForMutation = [rootNode, newNode];
  return updatedNodesForMutation;
};

export const addChildrenRecursively = ({ nodes, nodeId, resultArray }) => {
  //1) push this nodeId to the resultArray
  resultArray.push(nodeId);
  //2) run this function for its children
  const node = nodes[nodeId];
  if (!_.isEmpty(node.children)) {
    for (const childNodeId of node.children) {
      addChildrenRecursively({
        nodes,
        nodeId: childNodeId,
        resultArray,
      });
    }
  }
};

export const onDeleteClick = ({
  nodes,
  setNodes,
  selectedNodeIds,
  rootNodeId,
  userId,
}) => {
  //1) remove these ids from children array of their parent and store all the parentIds
  const updatedParentNodes = {};
  for (const nodeId of selectedNodeIds) {
    const parentId = nodes[nodeId].parentId;
    const parentNode = nodes[parentId];
    const childPosition = getChildPosition({
      nodes,
      childId: nodeId,
    });
    parentNode.children.splice(childPosition, 1);
    updatedParentNodes[parentNode.id] = parentNode;
  }
  //2)for each of these selectedNodeIds find their children recursively and add their ids in an array
  const nodeIdsToBeDeleted = [];
  for (const nodeId of selectedNodeIds) {
    addChildrenRecursively({ nodes, nodeId, resultArray: nodeIdsToBeDeleted });
  }
  //3) calculate final updated parent nodes
  _.forOwn(updatedParentNodes, (value, key) => {
    if (_.includes(nodeIdsToBeDeleted, key)) {
      delete updatedParentNodes[key];
    }
  });
  //4) update the local state
  let updatedNodes = { ...nodes };
  _.forOwn(updatedNodes, (value, key) => {
    if (_.includes(nodeIdsToBeDeleted, key)) {
      delete updatedNodes[key];
    }
  });

  if (_.size(updatedNodes) === 1) {
    // i.e There is only 1 node i.e it contains only root node
    updatedNodes = onAddFirstRow({
      rootNodeId,
      nodes: updatedNodes,
      setNodes,
      userId,
    });
    const inputForMutation = {
      updatedNodes,
      removedNodes: nodeIdsToBeDeleted,
    };
    return inputForMutation;
  } else {
    setNodes(updatedNodes);
    const inputForMutation = {
      updatedNodes: [..._.values(updatedParentNodes)],
      removedNodes: nodeIdsToBeDeleted,
    };
    return inputForMutation;
  }
};

export const getSelectedStandardsCount = ({ nodes, filterNodeIds = {} }) => {
  const count = _.reduce(
    nodes,
    (result, value, key) => {
      const isChecked = _.get(value, "isChecked", false);
      const nodeId = _.get(value, "id", false);
      if (isChecked) {
        result[1].push(nodeId);
        return [result[0] + 1, result[1], result[2]];
      } else {
        return result;
      }
    },
    [0, [], []]
  );
  return count;
};

//returns position of the childelement wrt its parentId
export const getChildPosition = ({ nodes, childId }) => {
  const parentId = nodes[childId].parentId;
  const parentNode = nodes[parentId];
  const childIndex = _.findIndex(parentNode.children, id => id == childId);
  return childIndex;
};

export const getParentDepthDifference = ({ nodes, nodeId }) => {
  const node = nodes[nodeId];
  const parentNode = nodes[node.parentId];
  return node.depth - parentNode.depth;
};

export const onLeftIndentNode = ({ nodes, setNodes, nodeId, maxDepth }) => {
  const node = nodes[nodeId];
  const childPosition = getChildPosition({
    nodes,
    childId: nodeId,
  });
  //if the node is of depth 1 (example Strand) or if it has children, then dont left indent node
  if (node.depth == 1 || !_.isEmpty(node.children)) {
    //cannot indent
    return false;
  } else {
    //get difference of depth of this node and its parentId (extra layer of security)
    const depthDifference = getParentDepthDifference({ nodes, nodeId });
    if (depthDifference == 1) {
      //it can be indented left
      const updatedNode = nodes[nodeId];
      const parentNode = nodes[node.parentId];
      const grandParentNode = nodes[parentNode.parentId];

      //1) decrease the depth of this node by 1
      updatedNode.depth = updatedNode.depth - 1;

      //2) update current node's parentId to its grand parentId
      updatedNode.parentId = grandParentNode.id;

      //3) update current node's parentId's children. (chop off children, from current node and rest of all including current node)
      const remainingChildren = parentNode.children.splice(
        childPosition,
        Infinity
      );
      //4) update current node's children to those remaining children
      updatedNode.children = remainingChildren.splice(1, Infinity);
      //5) update the remaining children's parentId to current node
      const childNodes = {};

      for (const childId of updatedNode.children) {
        const childNode = nodes[childId];
        childNode.parentId = updatedNode.id;
        childNodes[childId] = childNode;
      }

      //6)Insert current node's Id at a position after its parentId in its grand parentId's children array
      const parentsPosition = getChildPosition({
        nodes,
        childId: parentNode.id,
      });
      grandParentNode.children.splice(parentsPosition + 1, 0, updatedNode.id);

      //finally set all the updated nodes in the state
      setNodes(prevNodes => {
        return {
          ...prevNodes,
          [grandParentNode.id]: grandParentNode,
          [parentNode.id]: parentNode,
          [updatedNode.id]: updatedNode,
          ...childNodes,
        };
      });
      const updatedNodesForMutation = [
        grandParentNode,
        parentNode,
        updatedNode,
        ..._.values(childNodes),
      ];
      return updatedNodesForMutation;
    } else {
      //cannot indent
      return false;
    }
  }
};

export const onLeftIndentGrades = ({ nodes, setNodes, nodeId }) => {
  const newNodes = _.keyBy(nodes, "id");
  const currentNode = newNodes[nodeId];
  const currentNodeGrades = currentNode.grades;
  const updatedNodes = [];

  let updateParentNodeGrades = [...currentNodeGrades];
  for (const childId of currentNode.children) {
    const childNode = newNodes[childId];
    // Grades we'll pick which are not present in the parent grades i.e down to up flow
    const childNodeGrades = childNode.grades;
    const filterGrades = _.unionBy(
      childNodeGrades,
      updateParentNodeGrades,
      "id"
    );

    updateParentNodeGrades = filterGrades;
  }
  currentNode.grades = updateParentNodeGrades;
  updatedNodes.push(currentNode);

  //finally set all the updated nodes in the state
  setNodes(prevNodes => {
    return {
      ...prevNodes,
      ...newNodes,
    };
  });

  return updatedNodes;
};

export const onRightIndentNode = ({ nodes, setNodes, nodeId, maxDepth }) => {
  const node = nodes[nodeId];

  const childPosition = getChildPosition({
    nodes,
    childId: nodeId,
  });
  const depth = node.depth;
  if (depth == maxDepth || childPosition == 0) {
    return false;
  } else {
    //get difference of depth of this node and its parentId
    const depthDifference = getParentDepthDifference({ nodes, nodeId });
    //this if condition is extra layer of security
    if (depthDifference == 1) {
      //it can be indented right
      const updatedNode = nodes[nodeId];
      const parentNode = nodes[node.parentId];
      const updatedParentNodeId = parentNode.children[childPosition - 1];
      let updatedParentNode = nodes[updatedParentNodeId];

      //1) increase depth of this node by 1
      updatedNode.depth = updatedNode.depth + 1;

      //2) append this nodeId and all its children ids as an array at the end of its above elements children array
      updatedParentNode.children = _.union(
        updatedParentNode.children,
        [updatedNode.id],
        updatedNode.children
      );
      //3) update the parentId of the node to the prev node's id
      updatedNode.parentId = updatedParentNodeId;

      //4) update parentId of all of its children to the id of the above element
      const childNodes = {};
      const updatedNodeChildren = _.get(updatedNode, "children", []);
      for (const childId of updatedNodeChildren) {
        const childNode = nodes[childId];
        childNode.parentId = updatedParentNodeId;
        childNodes[childId] = childNode;
      }

      //5) Clear these children from the node's children
      updatedNode.children = [];
      // 6) remove this child from parentId node's children
      parentNode.children.splice(childPosition, 1);

      //finally set all the updated nodes in the state
      setNodes(prevNodes => {
        return {
          ...prevNodes,
          [parentNode.id]: parentNode,
          [updatedParentNodeId]: updatedParentNode,
          [updatedNode.id]: updatedNode,
          ...childNodes,
        };
      });
      const updatedNodesForMutation = [
        parentNode,
        updatedParentNode,
        updatedNode,
        ..._.values(childNodes),
      ];
      return updatedNodesForMutation;
    } else {
      return false;
    }
  }
};
export const onRightIndentGrades = ({ nodes, setNodes, nodeId }) => {
  const newNodes = { ..._.keyBy(nodes, "id") };
  const node = newNodes[nodeId];
  const parentNode = newNodes[node.parentId];
  const updatedNodes = [];

  const currentNodeGrades = node["grades"];
  const parentNodeGrades = parentNode["grades"];

  const gradesCopyToParent = _.differenceBy(
    currentNodeGrades,
    parentNodeGrades,
    "id"
  );

  parentNode.grades = [...parentNode.grades, ...gradesCopyToParent];
  updatedNodes.push(parentNode);
  setNodes(nodes => {
    return {
      ...nodes,
      ...newNodes,
    };
  });

  return updatedNodes;
};
/**
 * 1) Sometimes we get duplicate objects in updateNodes array. so we need to make them unique list of objects.
 * 2) This func will pick the latest updated node
 */
export const getUniqueUpdatedNodes = ({
  previouslyUpdateNodes,
  latestUpdateNodes = [],
}) => {
  const updatedNodes = [...previouslyUpdateNodes, ...latestUpdateNodes];
  const uniqueUpdatedNodes = [
    ...new Map(_.map(updatedNodes, node => [node.id, node])).values(),
  ];
  return uniqueUpdatedNodes;
};

/**
 * We can select any org grade no matter what depth of the node will be.
 * Grade will be updated hierarchically both upwards and downwards as
 * the child grade is dependent upon parent grade and vice versa.
 */
export const onUpdateNodeGrades = ({ setNodes, nodeId, nodes, nodeGrades }) => {
  const newNodes = { ...nodes };
  const updatedNodes = [];
  const currentNode = nodes[nodeId];
  const currentNodeParentId = currentNode.parentId;
  const currentNodeChildren = currentNode.children;

  currentNode["grades"] = [...nodeGrades];
  updatedNodes.push(currentNode);

  addNodeGradesUpwards({
    parentId: currentNodeParentId,
    filterGrades: nodeGrades,
    nodes: newNodes,
    updatedNodes,
  });

  removeNodeGradesDownwards({
    children: currentNodeChildren,
    filterGrades: nodeGrades,
    nodes: newNodes,
    updatedNodes,
  });

  setNodes(newNodes);
  return updatedNodes;
};

const addNodeGradesUpwards = ({
  parentId,
  filterGrades,
  nodes,
  updatedNodes,
}) => {
  const depth = _.get(nodes, `[${parentId}].depth`, 0);

  const currentNodeGrades = nodes[parentId]["grades"];
  const updatedGrades = _.unionBy(filterGrades, currentNodeGrades, "id"); // grades which are not present in parent grades

  if (_.size(updatedGrades) > _.size(currentNodeGrades)) {
    nodes[parentId]["grades"] = [...updatedGrades];
    updatedNodes.push(nodes[parentId]);
  }

  if (depth === 0) return;

  const currentNodeParentId = nodes[parentId]["parentId"];
  return addNodeGradesUpwards({
    parentId: currentNodeParentId,
    filterGrades,
    nodes: nodes,
    updatedNodes,
  });
};

const removeNodeGradesDownwards = ({
  children,
  filterGrades,
  nodes,
  updatedNodes,
}) => {
  if (!children) return;

  for (const childId of children) {
    const childNode = nodes[childId];
    const currentNodeGrades = _.get(childNode, "grades", []);

    const intersectionGrades = _.intersectionBy(
      filterGrades,
      currentNodeGrades,
      "id"
    );

    if (_.size(intersectionGrades) < _.size(currentNodeGrades)) {
      childNode["grades"] = intersectionGrades;
      updatedNodes.push(childNode);
    }

    removeNodeGradesDownwards({
      children: _.get(childNode, "children", []),
      filterGrades,
      nodes,
      updatedNodes,
    });
  }
};

export const onAddNewNode = ({
  nodes,
  setNodes,
  nodeId,
  maxDepth,
  userId,
  isActionDisabled, // tells if filters are selected
}) => {
  const sourceNode = nodes[nodeId];
  const sourceParentNode = nodes[sourceNode.parentId];
  const { grades, tags } = sourceNode;
  // create a new node
  const newNode = {
    id: "NEW_" + Date.now() + `${userId}`,
    label: "",
    parentId: sourceNode.parentId,
    children: [],
    depth: sourceNode.depth,
    code: "",
    grades: isActionDisabled ? _.cloneDeep(grades) : [], // add sources node grades and tags if filters are selected
    tags: isActionDisabled ? _.cloneDeep(tags) : [],
    isNew: true,
  };
  //insert it in a position after the sourceNode in the sourceNode's parentId's children array
  const sourceChildPosition = getChildPosition({
    nodes,
    childId: sourceNode.id,
  });
  sourceParentNode.children.splice(sourceChildPosition + 1, 0, newNode.id);

  //finally update the state
  setNodes(prevNodes => {
    return {
      ...prevNodes,
      [sourceParentNode.id]: sourceParentNode,
      [newNode.id]: newNode,
    };
  });
  const updatedNodesForMutation = [sourceParentNode, newNode];
  return [updatedNodesForMutation, newNode.id];
};

export const handleDragAndDrop = ({
  result,
  nodes,
  setNodes,
  indexCountArray,
}) => {
  const nodeId = result.draggableId;

  let sourceNode = nodes[nodeId];

  const sourceIndex = result.source.index;
  const destinationIndex = result.destination && result.destination.index;

  const destinationNodeId = indexCountArray[destinationIndex - 1];
  let destinationNode = nodes[destinationNodeId];
  if (destinationNode) {
    const dragDirection = destinationIndex - sourceIndex > 0 ? "down" : "up";
    //compare depths of both the nodes. both nodes should be of same depth
    if (
      sourceNode.depth == destinationNode.depth &&
      sourceIndex != destinationIndex &&
      ((_.isEmpty(destinationNode.children) && dragDirection == "down") ||
        dragDirection == "up")
    ) {
      //the item can be drag and dropped. we follow these steps to change the data
      //1) remove this dragged node from its parentId's children array
      let sourceParentNode = nodes[sourceNode.parentId];
      const sourceChildPosition = getChildPosition({
        nodes,
        childId: sourceNode.id,
      });
      sourceParentNode.children.splice(sourceChildPosition, 1);

      //2) set this dragged node's parentId to the destination node's parentId
      sourceNode.parentId = destinationNode.parentId;

      //3) insert this node's id in the children element of the destination node's parentId a position after the destination node if the drag direction is down and vice versa
      let destinationParentNode = nodes[destinationNode.parentId];
      const destinationChildPosition = getChildPosition({
        nodes,
        childId: destinationNode.id,
      });

      if (dragDirection == "down") {
        destinationParentNode.children.splice(
          destinationChildPosition + 1,
          0,
          sourceNode.id
        );
      } else if (dragDirection == "up") {
        destinationParentNode.children.splice(
          destinationChildPosition,
          0,
          sourceNode.id
        );
      }

      //4 finally update the state with all these changes
      setNodes(prevNodes => {
        return {
          ...prevNodes,
          [sourceParentNode.id]: sourceParentNode,
          [sourceNode.id]: sourceNode,
          [destinationParentNode.id]: destinationParentNode,
        };
      });

      const updatedNodesForMutation = [
        sourceParentNode,
        sourceNode,
        destinationParentNode,
      ];
      return updatedNodesForMutation;
    } else if (
      sourceNode.depth - destinationNode.depth == 1 &&
      dragDirection == "down"
    ) {
      //1) remove this node from its parentId's children
      let sourceParentNode = nodes[sourceNode.parentId];
      const sourceChildPosition = getChildPosition({
        nodes,
        childId: sourceNode.id,
      });
      sourceParentNode.children.splice(sourceChildPosition, 1);

      //2) update this node's parentId as destionation node's id
      sourceNode.parentId = destinationNode.id;

      //3) append this node's id as a first child id of the destination node
      destinationNode.children.unshift(sourceNode.id);

      //4 finally update the state with all these changes
      setNodes(prevNodes => {
        return {
          ...prevNodes,
          [sourceParentNode.id]: sourceParentNode,
          [sourceNode.id]: sourceNode,
          [destinationNode.id]: destinationNode,
        };
      });

      const updatedNodesForMutation = [
        sourceParentNode,
        sourceNode,
        destinationNode,
      ];
      return updatedNodesForMutation;
    } else if (
      sourceNode.depth - destinationNode.depth >= 1 &&
      dragDirection == "up" &&
      !(
        nodes[destinationNode.parentId].depth == 0 &&
        nodes[destinationNode.parentId].children[0] == destinationNode.id
      )
    ) {
      //1) find the node which is just above destination node.

      let nodeJustAboveDestination =
        nodes[indexCountArray[destinationIndex - 2]];

      //2) if it is of same level, make this node as chldren of its parentId
      if (sourceNode.depth == nodeJustAboveDestination.depth) {
        //1) remove source node from its parentId's children
        let sourceParentNode = nodes[sourceNode.parentId];
        const sourceChildPosition = getChildPosition({
          nodes,
          childId: sourceNode.id,
        });
        sourceParentNode.children.splice(sourceChildPosition, 1);

        //2) make the parentId of source Node as nodeJustAboveDestination's parentId
        sourceNode.parentId = nodeJustAboveDestination.parentId;

        //3) insert our sourceNode in a position after nodeJustAboveDestination in its parentId
        let nodeJustAboveDestinationParent =
          nodes[nodeJustAboveDestination.parentId];

        const nodeJustAboveChildPosition = getChildPosition({
          nodes,
          childId: nodeJustAboveDestination.id,
        });
        nodeJustAboveDestinationParent.children.splice(
          nodeJustAboveChildPosition + 1,
          0,
          sourceNode.id
        );

        //finally update all these data
        setNodes(prevNodes => {
          return {
            ...prevNodes,
            [sourceParentNode.id]: sourceParentNode,
            [sourceNode.id]: sourceNode,
            [nodeJustAboveDestinationParent.id]: nodeJustAboveDestinationParent,
          };
        });

        const updatedNodesForMutation = [
          sourceParentNode,
          sourceNode,
          nodeJustAboveDestinationParent,
        ];
        return updatedNodesForMutation;
      }
      //if the node just above destination node is of one level higher? then make this node as its children
      else if (
        sourceNode.depth - nodeJustAboveDestination.depth == 1 &&
        _.isEmpty(nodeJustAboveDestination.children)
      ) {
        //1) remove source node from its parentId's children
        let sourceParentNode = nodes[sourceNode.parentId];
        const sourceChildPosition = getChildPosition({
          nodes,
          childId: sourceNode.id,
        });
        sourceParentNode.children.splice(sourceChildPosition, 1);
        //2) make the nodeJustAboveDestination as its new parentId
        sourceNode.parentId = nodeJustAboveDestination.id;
        //3) push this node as the nodeJustAboveDestination's children
        nodeJustAboveDestination.children.push(sourceNode.id);
        //4) Finally update all this data
        setNodes(prevNodes => {
          return {
            ...prevNodes,
            [sourceParentNode.id]: sourceParentNode,
            [sourceNode.id]: sourceNode,
            [nodeJustAboveDestination.id]: nodeJustAboveDestination,
          };
        });

        const updatedNodesForMutation = [
          sourceParentNode,
          sourceNode,
          nodeJustAboveDestination,
        ];
        return updatedNodesForMutation;
      } else if (nodeJustAboveDestination.depth - sourceNode.depth == 1) {
        //1) remove source node from its parentId's children
        let sourceParentNode = nodes[sourceNode.parentId];
        const sourceChildPosition = getChildPosition({
          nodes,
          childId: sourceNode.id,
        });
        sourceParentNode.children.splice(sourceChildPosition, 1);
        //2) make the parentId as grand parentId of the nodeJustAboveDestination
        sourceNode.parentId = nodes[nodeJustAboveDestination.parentId].parentId;
        //3) make this source ndoe as the last child of the grandparent node
        let grandParentNode =
          nodes[nodes[nodeJustAboveDestination.parentId].parentId];
        grandParentNode.children.push(sourceNode.id);
        //4) finally update all this data
        setNodes(prevNodes => {
          return {
            ...prevNodes,
            [sourceParentNode.id]: sourceParentNode,
            [sourceNode.id]: sourceNode,
            [grandParentNode.id]: grandParentNode,
          };
        });

        const updatedNodesForMutation = [
          sourceParentNode,
          sourceNode,
          grandParentNode,
        ];
        return updatedNodesForMutation;
      }
    } else if (
      destinationNode.depth - sourceNode.depth >= 1 &&
      dragDirection == "down"
    ) {
      //1) check if the destination is not sourceNode's child, and it is the last child of its parentId
      if (
        destinationNode.parentId != sourceNode.id &&
        nodes[destinationNode.parentId].children[
          nodes[destinationNode.parentId].children.length - 1
        ] == destinationNode.id
      ) {
        //2) if yes, then
        //3) iterate through its parents until we find same level parentId as our sourceNode
        let destinationNodeParent = nodes[destinationNode.parentId];
        while (destinationNodeParent.depth != sourceNode.depth) {
          destinationNodeParent = nodes[destinationNodeParent.parentId];
        }
        //4) remove sourceNode from its parentId, and make its parentId as this newly found node's
        let sourceParentNode = nodes[sourceNode.parentId];
        const sourceChildPosition = getChildPosition({
          nodes,
          childId: sourceNode.id,
        });
        sourceParentNode.children.splice(sourceChildPosition, 1);

        sourceNode.parentId = destinationNodeParent.parentId;
        //5) go to the newly found node's parentId and append this node's id in its children array
        let newlyFoundNodeParent = nodes[destinationNodeParent.parentId];
        const destinationNodeParentChildPosition = getChildPosition({
          nodes,
          childId: destinationNodeParent.id,
        });
        newlyFoundNodeParent.children.splice(
          destinationNodeParentChildPosition + 1,
          0,
          sourceNode.id
        );
        //finally update the state with all this data
        setNodes(prevNodes => {
          return {
            ...prevNodes,
            [sourceParentNode.id]: sourceParentNode,
            [sourceNode.id]: sourceNode,
            [newlyFoundNodeParent.id]: newlyFoundNodeParent,
          };
        });

        const updatedNodesForMutation = [
          sourceParentNode,
          sourceNode,
          newlyFoundNodeParent,
        ];
        return updatedNodesForMutation;
      }
    } else {
      // console.log(
      //   "Cannot be dragged,SND",
      //   sourceNode.depth,
      //   "DND",
      //   destinationNode.depth,
      //   "drag direction",
      //   dragDirection
      // );
    }
  }
};
const updateNodeGradesHierarchicallyUpwards = ({
  node,
  nodes,
  updatedNodes,
  childGrades,
}) => {
  const { depth } = node;
  if (depth === 0) return;

  const currentNode = node;
  const parentNode = nodes[currentNode.parentId];

  const extraChildGradesOfCurrentNode = _.differenceBy(
    childGrades,
    parentNode.grades,
    "id"
  );

  if (_.size(extraChildGradesOfCurrentNode) > 0) {
    parentNode["grades"] = [
      ...parentNode["grades"],
      ...extraChildGradesOfCurrentNode,
    ];

    updatedNodes.push(parentNode);

    return updateNodeGradesHierarchicallyUpwards({
      node: parentNode,
      nodes,
      updatedNodes,
      childGrades: extraChildGradesOfCurrentNode,
    });
  } else return; // i.e if there is no change in grades on the current node. It won't have any effect on the above grades of the parent.
};

/**
 * User has a freedom to select the grade no matter what at what depth would be. The grades which are
 * selected are hierarchically dependent to the parent. As the current row which has been dropped will
 * be having the grades that may not present in the parent grades hence all parents needs to be updated.
 * This action will be recursive.
 */
export const updateGradesOnDrop = ({ nodeId, nodes = {}, setNodes }) => {
  const newNodes = { ...nodes };
  const updatedNodes = [];
  const currentNode = newNodes[nodeId];

  const parentNode = newNodes[currentNode.parentId];

  if (parentNode.depth > 0) {
    const extraChildGradesOfCurrentNode = _.differenceBy(
      currentNode.grades,
      parentNode.grades,
      "id"
    );

    if (_.size(extraChildGradesOfCurrentNode) > 0) {
      parentNode["grades"] = [
        ...parentNode["grades"],
        ...extraChildGradesOfCurrentNode,
      ];

      updatedNodes.push(parentNode);

      updateNodeGradesHierarchicallyUpwards({
        node: parentNode,
        nodes: newNodes,
        updatedNodes,
        childGrades: extraChildGradesOfCurrentNode,
      });
    }
  }
  setNodes(newNodes);
  return updatedNodes;
};

//This func always return Bool
export const shouldNodeRender = ({
  filterNodeIds,
  rootNodeId,
  showUptoDepth,
  uptoDepth,
  depth,
}) => {
  const isRootNodeInFilterNodes = rootNodeId in filterNodeIds ? true : false;
  let shouldRender = isRootNodeInFilterNodes;
  if (showUptoDepth) {
    shouldRender = isRootNodeInFilterNodes && depth <= uptoDepth;
  }
  return shouldRender;
};

export const selectionBox = ({
  hasChildren,
  showSelectionBox,
  depth,
  uptoDepth,
  showUptoDepth,
  selectedNodes,
  selectedTaggedIds,
  nodeId,
}) => {
  if (showSelectionBox) {
    if (hasChildren) {
      const node = _.find(selectedNodes, ({ id }) => id == nodeId);
      const nodeChildren = _.get(node, "children", []);
      const isChildSelected = _.size(
        _.intersection(nodeChildren, selectedTaggedIds)
      );
      return _.includes(selectedTaggedIds, nodeId) && !isChildSelected;
    } else {
      return true;
    }
  }
  if (showUptoDepth) return uptoDepth == depth || !hasChildren;
  return false;
};

export const onBulkRemoveGrades = ({
  nodes,
  setNodes,
  selectedNodeIds,
  itemsList,
}) => {
  const updatedNodes = [];
  const newNodes = { ...nodes };
  const gradeList = _.map(itemsList, ({ id }) => ({ id }));

  _.map(selectedNodeIds, nodeId => {
    const currentNode = newNodes[nodeId];
    const currentNodeGrades = currentNode.grades;
    const currentNodeChildren = currentNode.children;

    currentNode["grades"] = _.differenceBy(currentNodeGrades, gradeList, "id");
    const currentNodeNewGrades = currentNode["grades"];

    if (_.size(currentNodeNewGrades) < _.size(currentNodeGrades)) {
      updatedNodes.push(currentNode);

      removeNodeGradesDownwards({
        children: currentNodeChildren,
        filterGrades: currentNodeNewGrades,
        nodes: newNodes,
        updatedNodes,
      });
    }
  });

  setNodes(newNodes);
  return updatedNodes;
};

export const onBulkAddGrades = ({
  nodes,
  setNodes,
  selectedNodeIds,
  itemsList,
}) => {
  const newGrades = _.map(itemsList, ({ id }) => ({ id }));
  const newNodes = { ...nodes };
  const updatedNodes = [];

  _.map(selectedNodeIds, nodeId => {
    const currentNode = newNodes[nodeId];
    const currentNodeParentId = currentNode.parentId;
    const currentNodeGrades = currentNode["grades"];
    const updatedGrades = _.unionBy(newGrades, currentNodeGrades, "id");

    if (_.size(updatedGrades) > _.size(currentNodeGrades)) {
      currentNode["grades"] = [...updatedGrades];
      updatedNodes.push(currentNode);
    }

    addNodeGradesUpwards({
      parentId: currentNodeParentId,
      filterGrades: newGrades,
      nodes: newNodes,
      updatedNodes,
    });
  });
  setNodes(newNodes);
  return updatedNodes;
};

export const onBulkAddTags = ({
  nodes,
  setNodes,
  selectedNodeIds,
  itemsList,
}) => {
  const updatedNodes = [];
  //we need to map on selected tags to include only id in every tag object and remove label
  const updatedSelectedTags = _.map(itemsList, tag => {
    return { id: tag.id };
  });
  for (const id of selectedNodeIds) {
    const node = nodes[id];
    //push all these tags into the node
    node.tags.push(...updatedSelectedTags);
    //make them unique
    const uniqueTags = [
      ...new Map(_.map(node.tags, tag => [tag.id, tag])).values(),
    ];
    node.tags = uniqueTags;
    //push this node into updatedNodes
    updatedNodes.push(node);
  }
  //finally set the state with new data
  setNodes(prevNodes => {
    return {
      ...prevNodes,
      ..._.keyBy(updatedNodes, "id"),
    };
  });
  return updatedNodes;
};

export const onBulkRemoveTags = ({
  nodes,
  setNodes,
  selectedNodeIds,
  itemsList,
}) => {
  const updatedNodes = [];

  const removedTagIds = _.map(itemsList, tag => {
    return tag.id;
  });
  for (const id of selectedNodeIds) {
    const node = nodes[id];
    //remove those tags whose id is present in removedTagIds array
    node.tags = _.filter(
      node.tags,
      tag => !_.includes(removedTagIds, _.get(tag, "id", ""))
    );
    //push this node into updatedNodes
    updatedNodes.push(node);
  }
  //finally set the state with new data
  setNodes(prevNodes => {
    return {
      ...prevNodes,
      ..._.keyBy(updatedNodes, "id"),
    };
  });
  return updatedNodes;
};

const regexHelper = ({ searchText }) =>
  createRegex({ text: searchText ?? "", flags: "gi" });

export const getHTMLLabel = ({ label, searchText, style = {} }) =>
  !searchText || !label
    ? label
    : label.replace(regexHelper({ searchText }), val => {
        return ReactDOMServer.renderToStaticMarkup(<b style={style}>{val}</b>);
      });

export const isActionDisableValidate = ({ filters }) => {
  const { searchText = "", tags = [], grades = [] } = filters;
  return !_.isEmpty(searchText) || !_.isEmpty(tags) || !_.isEmpty(grades);
};

//Bulk rows that are copied from google docs excel sheet are being paste into NodeEditor.js.
export const pasteBulkBenchmarkItems = ({
  nodes,
  setNodes,
  nodeId,
  cellName,
  isNewRow = true,
  itemsList,
  userId,
  isActionDisabled,
}) => {
  const newNodes = { ...nodes };
  const currentNode = newNodes[nodeId];
  const itemsListLength = _.size(itemsList);
  const updatedNodes = [];
  const currentNodeParentId = currentNode.parentId;
  const { grades, tags } = currentNode;
  const parentNode = newNodes[currentNodeParentId];

  const parentNodeChildrenIds = parentNode.children;
  const currentNodeIndex = getChildPosition({ nodes, childId: nodeId });

  if (isNewRow) {
    currentNode[cellName] = _.head(itemsList); // add data in the current column
    updatedNodes.push(currentNode);

    const appendChildIds = [];
    for (let i = 0; i < itemsListLength - 1; i++) {
      const newNode = {
        id: "NEW_" + Date.now() + userId + i, //for unique id
        label: itemsList[i + 1],
        parentId: currentNodeParentId,
        children: [],
        depth: currentNode.depth,
        code: "",
        grades: isActionDisabled ? _.cloneDeep(grades) : [], // add sources node grades and tags if filters are selected
        tags: isActionDisabled ? _.cloneDeep(tags) : [],
        isNew: true,
      };

      const newNodeId = newNode.id;
      updatedNodes.push(newNode);
      newNodes[newNodeId] = newNode;
      appendChildIds.push(newNodeId);
    }
    parentNode["children"].splice(currentNodeIndex + 1, 0, ...appendChildIds); // append new nodes child ID's

    updatedNodes.push(parentNode);
    setNodes(newNodes);
  } else {
    // The data will be overwritten on current nodes column i.e no new nodes will be created
    for (
      let i = currentNodeIndex, j = 0;
      i < _.size(parentNodeChildrenIds);
      i++, j++
    ) {
      if (j === itemsListLength) break;
      const currentNode = newNodes[parentNodeChildrenIds[i]];
      currentNode[cellName] = itemsList[j];

      updatedNodes.push(currentNode);
    }

    setNodes(newNodes);
  }

  return updatedNodes;
};

//This function is used to get the whole node which is required to update the draft
export const getUpdatedNodeForDraft = ({
  cellName,
  nodeId,
  nodes,
  labelValue,
}) => {
  const currentNode = nodes[nodeId];
  currentNode[cellName] = labelValue;
  const updateNode = [currentNode];

  return updateNode;
};

// Function to add parentId in depth 1 nodes for planner elements case and modify the object to NodeEditor format
export const modifyPlannerElements = ({
  nodesData,
  rootNodes,
  plannerElementParentNode,
  addRootNode,
}) => {
  let subjectNode = {};
  if (addRootNode)
    subjectNode = {
      ...plannerElementParentNode,
      children: rootNodes,
      depth: 0,
      parentId: null,
    };

  const updatedBenchmarks = _.map(nodesData, node => {
    if (!_.has(node, "parentId")) node.parentId = _.get(node, "parent");
    if (!_.has(node, "grades"))
      node.grades = _.filter(_.get(node, "associatedParents", []), item => {
        if (_.get(item, "__typename") == "Grade") return item;
      });
    if (!_.has(node, "tags")) node.tags = _.get(node, "genericTags", []);
    if (_.includes(rootNodes, node.id) && addRootNode)
      node.parentId = plannerElementParentNode.id;
    return node;
  });

  return !addRootNode ? updatedBenchmarks : [subjectNode, ...updatedBenchmarks];
};

const plannerElementsUnitPlanProps = ({
  filters,
  nodes,
  parentNode,
  curriculumType,
}) => {
  const isNewEditor =
    ACLStore.can("FeatureFlag:NewNodeEditorPlannerElement") ||
    _.includes([CURRICULUM_TYPE_DP], curriculumType);
  if (!isNewEditor) return {};

  const associatedParents = _.get(filters, "associatedParents", []);
  const grades = _.get(
    _.find(associatedParents, ({ type }) => type == "GRADE"),
    "ids",
    []
  );
  const strandList = [];
  const selectedNodes = _.map(_.cloneDeep(nodes), node => {
    if (node.depth == 1) {
      node.parent = parentNode.id;
      strandList.push(node.id);
    }
    return node;
  });
  const tags = _.get(filters, "tags", []);
  const selectedTaggedIds = _.map(selectedNodes, ({ id }) => id);
  return {
    grades,
    tags,
    selectedNodes,
    selectedTaggedIds,
    addRootNodeTagging: true,
    openedFrom: "UNIT_PLAN",
  };
};

export const plannerElementsUnitPlanPropsMemoize = _.memoize(
  params => plannerElementsUnitPlanProps(params),
  params => JSON.stringify(params)
);

export const getMultiLevelNodeTagsFilters = ({ nodeType }) => {
  switch (nodeType) {
    case ELEMENT_TYPE_DP_SYLLABUS:
      return {
        types: ["SUBJECT_LEVEL"],
      };
    default:
      return {
        types: ["GENERIC"],
      };
  }
};

export const getPlannerElementConfig = ({ nodeType }) => {
  switch (nodeType) {
    case ELEMENT_TYPE_DP_SYLLABUS:
    case ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK:
      return { maxSidebarDepth: 2 };
    default:
      return { maxSidebarDepth: 1 };
  }
};

//this function returns the first renderable child id
export const getFirstChildId = ({
  nodes,
  nodeId,
  filterNodeIds,
  upToDepth,
}) => {
  const children = _.get(nodes, [nodeId, "children"]) || [];
  let firstChildId;
  for (const childId of children) {
    const childNodeDepth = _.get(nodes[childId], "depth", "");
    const isChildVisible = shouldNodeRender({
      filterNodeIds,
      rootNodeId: childId,
      showUpToDepth: true,
      upToDepth,
      depth: childNodeDepth,
    });
    if (isChildVisible) {
      firstChildId = childId;
      break;
    }
  }
  return firstChildId;
};

export const getOrganisationTagsFromSubjectMemoize = _.memoize(
  params => getOrganisationTagsFromSubject(params),
  params => JSON.stringify(params)
);

export const getOrganisationTagsFromSubject = ({ subjectVariants }) => {
  return _.reduce(
    subjectVariants,
    (result, variant) => {
      if (variant.level) result.push(variant.level);
      return result;
    },
    []
  );
};
