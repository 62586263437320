import React from "react";
import classes from "./ScreenPanel.scss";
import ScreenHeader from "./ScreenHeader";
import Footer from "./Footer";
import classNames from "classnames";
import ModalHeader from "./ModalHeader";
class ScreenPanel extends React.PureComponent {
  render() {
    const {
      children,
      screenTitle,
      onCloseClick,
      showBack,
      showScreenTitle,
      goToPreviousScreen,
      showPreview,
      footerComponent,
      isInnerModal,
      modalHeaderTitle,
      containerStyle = {},
      rightContainerContent,
      titleTextStyle,
      leftContainerStyles,
      rightContainerStyles,
      assessmentId,
      LETitle,
      assessmentType,
    } = this.props;

    const innerScrollContainer = classNames(
      { [classes.innerScrollContainer]: true },
      { [classes.modalInnerScrollContainer]: isInnerModal }
    );

    return (
      <div className={classes.container}>
        {!isInnerModal && (
          //Only for Full Screen Steps
          <ScreenHeader
            title={screenTitle}
            onBackClick={onCloseClick}
            showTitle={showScreenTitle}
            rightContainerContent={rightContainerContent}
            assessmentId={assessmentId}
            LETitle={LETitle}
            assessmentType={assessmentType}
          />
        )}
        {isInnerModal && (
          //Only for Modal Screen Steps
          <div
            className={classes.headerContainerStyle}
            style={{ ...containerStyle }}
          >
            <ModalHeader
              title={modalHeaderTitle}
              titleTextStyle={titleTextStyle}
              onCloseClick={onCloseClick}
              showBack={showBack}
              onBackClick={goToPreviousScreen}
              showPreview={showPreview}
              leftContainerStyles={leftContainerStyles}
              rightContainerStyles={rightContainerStyles}
            />
          </div>
        )}
        <div className={classes.scrollContainer}>
          <div className={innerScrollContainer} style={{ ...containerStyle }}>
            {children}
          </div>
        </div>
        {footerComponent && (
          <Footer
            footerComponent={footerComponent}
            containerStyle={containerStyle}
          />
        )}
      </div>
    );
  }
}

export default ScreenPanel;
