import React from "react";

const Grade4Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M33.2949,26.5078 L33.1909,26.5078 L27.3889,34.7638 L33.2949,34.7638 L33.2949,26.5078 Z M31.1189,21.8438 C32.6739,19.7008 33.9529,19.4258 34.9879,19.4258 C36.1639,19.4258 38.6499,19.9788 38.6499,23.7438 L38.6499,34.7638 L39.8589,34.7638 C42.2769,34.7638 42.5879,36.3868 42.5879,37.1128 C42.5879,37.8378 42.2769,39.4608 39.8589,39.4608 L38.6859,39.4608 L38.6859,41.5348 C38.6859,44.1248 36.8539,44.5738 35.9899,44.5738 C35.0919,44.5738 33.2619,44.1248 33.2619,41.5348 L33.2619,39.4608 L25.0039,39.4608 C21.9299,39.4608 21.4119,37.3208 21.4119,36.3868 C21.4119,35.7308 21.5839,34.8668 22.3459,33.7968 L31.1189,21.8438 Z"></path>
      </g>
    </svg>
  );
};

Grade4Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default Grade4Icon;
