import React from "react";
import { colors } from "Constants";
import classes from "./Header.scss";
import { MediaPlaceholder, ResourceImagePlaceHolder } from "UIComponents";
import classNames from "classnames";
import {
  EVIDENCE_TYPE_UNIT,
  EVIDENCE_TYPE_POST,
  EVIDENCE_TYPE_ASSESSMENT,
  EVIDENCE_TYPE_PROGRESS_REPORT,
  ASSIGNMENT_TYPE_QUICK_TASK,
  ASSIGNMENT_TYPE_LE,
  ASSIGNMENT_TYPE_FORMATIVE,
  ASSIGNMENT_TYPE_SUMMATIVE,
  ASSIGNMENT_TYPE_PERFORMANCE_TASK,
  ASSIGNMENT_TYPE_PRE_ASSESSMENT,
  ASSIGNMENT_TYPE_SUPPLEMENTARY,
  ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE,
} from "Constants/stringConstants";
import { containerStyle, mediaPlaceholderContainerStyle } from "./HeaderStyles";
import {
  PostIcon,
  ProgressReportCardIcon,
  LEFilledIcon,
  Summative,
  Formative,
  CreateAssessmentSvg,
  QuickTaskFilledIcon,
} from "SvgComponents";

const IMAGE_DIMENSION = 48;

const SVG_DIMENSION = 24;

const ASSIGNMENT_COMPONENT_MAPPING = {
  [ASSIGNMENT_TYPE_QUICK_TASK]: QuickTaskFilledIcon,
  [ASSIGNMENT_TYPE_FORMATIVE]: Formative,
  [ASSIGNMENT_TYPE_LE]: LEFilledIcon,
  [ASSIGNMENT_TYPE_SUMMATIVE]: Summative,
  [ASSIGNMENT_TYPE_PERFORMANCE_TASK]: CreateAssessmentSvg,
  [ASSIGNMENT_TYPE_PRE_ASSESSMENT]: CreateAssessmentSvg,
  [ASSIGNMENT_TYPE_SUPPLEMENTARY]: CreateAssessmentSvg,
  [ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE]: QuickTaskFilledIcon,
};

const ASSIGNMENT_CLASS_MAPPING = {
  [ASSIGNMENT_TYPE_QUICK_TASK]: classes.qt,
  [ASSIGNMENT_TYPE_LE]: classes.le,
  [ASSIGNMENT_TYPE_FORMATIVE]: classes.formative,
  [ASSIGNMENT_TYPE_SUMMATIVE]: classes.summative,
  [ASSIGNMENT_TYPE_PRE_ASSESSMENT]: classes.preAssessment,
  [ASSIGNMENT_TYPE_PERFORMANCE_TASK]: classes.performanceTask,
  [ASSIGNMENT_TYPE_SUPPLEMENTARY]: classes.supplementary,
  [ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE]: classes.qt,
};

const Thumbnail = ({ itemType, thumbnailData }) => {
  const getThumbnailConfig = () => {
    switch (itemType) {
      case EVIDENCE_TYPE_UNIT: {
        const id = _.get(thumbnailData, "id", "");
        const url = _.get(thumbnailData, "value", "");

        return {
          thumbnailElement: (
            <ResourceImagePlaceHolder
              id={id}
              imageUrl={url}
              imageThumbSize={IMAGE_DIMENSION}
              containerStyle={containerStyle}
            />
          ),
        };
      }

      case EVIDENCE_TYPE_ASSESSMENT: {
        const id = _.get(thumbnailData, "id", "");
        const url = _.get(thumbnailData, "value", "");

        return {
          thumbnailElement: (
            <ResourceImagePlaceHolder
              id={id}
              imageUrl={url}
              imageThumbSize={IMAGE_DIMENSION}
              containerStyle={containerStyle}
            />
          ),
        };
      }

      case EVIDENCE_TYPE_POST: {
        const attachments = _.get(thumbnailData, "attachments", []);

        const showPostIcon =
          _.isEmpty(attachments) ||
          (_.size(attachments) === 1 && attachments[0].type === "NOTE");

        if (showPostIcon) {
          return {
            thumbnailElement: (
              <PostIcon
                fill={colors.violet63}
                height={SVG_DIMENSION}
                width={SVG_DIMENSION}
              />
            ),
            applyShadow: true,
          };
        }

        return {
          thumbnailElement: (
            <MediaPlaceholder
              attachments={attachments}
              imageMaxHeight={IMAGE_DIMENSION}
              imageMaxWidth={IMAGE_DIMENSION}
              resolutionMutliplier={2}
              containerStyle={mediaPlaceholderContainerStyle}
            />
          ),
        };
      }

      case EVIDENCE_TYPE_PROGRESS_REPORT: {
        const thumbnailElement = (
          <ProgressReportCardIcon
            width={SVG_DIMENSION * 2}
            height={SVG_DIMENSION * 2}
            arrowStrokeColor={colors.purple63}
          />
        );
        return {
          thumbnailElement,
          applyShadow: true,
          applyProgressReportStyle: true,
        };
      }

      case ASSIGNMENT_TYPE_QUICK_TASK:
      case ASSIGNMENT_TYPE_LE:
      case ASSIGNMENT_TYPE_FORMATIVE:
      case ASSIGNMENT_TYPE_SUPPLEMENTARY:
      case ASSIGNMENT_TYPE_SUMMATIVE:
      case ASSIGNMENT_TYPE_PRE_ASSESSMENT:
      case ASSIGNMENT_TYPE_PERFORMANCE_TASK:
      case ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE: {
        const Component = ASSIGNMENT_COMPONENT_MAPPING[itemType];

        const assignmentIconClasses = classNames(
          classes.assignmentIconContainer,
          ASSIGNMENT_CLASS_MAPPING[itemType]
        );

        const thumbnailElement = (
          <div className={assignmentIconClasses}>
            <Component
              fill={colors.white}
              height={SVG_DIMENSION}
              width={SVG_DIMENSION}
            />
          </div>
        );

        return {
          thumbnailElement,
        };
      }
    }
  };

  const {
    thumbnailElement = null,
    applyShadow = false,
    applyProgressReportStyle = false,
  } = getThumbnailConfig();

  const thumbnailClassList = classNames(classes.thumbnail, {
    [classes.thumbnailShadow]: applyShadow,
    [classes.progressReportThumbnail]: applyProgressReportStyle,
  });

  return <div className={thumbnailClassList}>{thumbnailElement}</div>;
};

const Header = props => {
  const {
    itemType,
    thumbnailData,
    closeButtonElement,
    itemTitle,
    itemLabel,
    t,
  } = props;

  const evidenceTitle =
    itemType === "STUDENT_PROGRESS_REPORT"
      ? t("common:with_report_card_evidence", { studentName: itemTitle })
      : t("common:with_evidence", {
          evidenceName: itemLabel,
          evidenceTitle: itemTitle || "Untitled",
        });

  return (
    <div className={classes.header}>
      <Thumbnail itemType={itemType} thumbnailData={thumbnailData} />
      <div className={classes.info}>
        <div className={classes.title}>{`${t("common:tag_snp")}`}</div>

        <div className={classes.subTitle}>{evidenceTitle}</div>
      </div>
      {closeButtonElement}
    </div>
  );
};

export default Header;
