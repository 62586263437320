import client from "apolloClient";
import {
  singlePointRubricFragment,
  organizationFragment,
  singlePointRubricElementsFragment,
} from "./SinglePointRubricFragments";
import { getOrganizationSinglePointRubricsQuery } from "./SinglePointRubricQuery";

const fragmentNameMapping = {
  STANDARD: {
    typeName: "SinglePointRubricStandard",
    fragment: "singlePointRubricStandard",
    fragmentName: "singlePointRubricStandardItem",
  },
  CRITERIA: {
    typeName: "SinglePointRubricCriteria",
    fragment: "singlePointRubricCriteria",
    fragmentName: "singlePointRubricCriteriaItem",
  },
};

export const getOrganizationSinglePointRubricsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationSinglePointRubricsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
export const writeOrganizationSinglePointRubricsInCache = ({
  id,
  data,
  filters,
}) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${id}`,
      fragment: organizationFragment.singlePointRubricList,
      fragmentName: "organizationItem",
      data,
      variables: { filters },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
export const getSinglePointRubricElementFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment: singlePointRubricElementsFragment[fragment],
      fragmentName: fragmentName,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeSinglePointRubricElementInCache = ({ id, data, type }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;
    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment: singlePointRubricElementsFragment[fragment],
      fragmentName: fragmentName,
      data,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
