import gql from "graphql-tag";
import { attachmentFragment, userFragment } from "modules/CommonFragments";

export const commonFragment = {
  readUser: gql`
    fragment readUserItem on User {
      id
      firstName
      lastName
    }
  `,
};

export const fragments = {
  circularsFeed: gql`
    fragment circularsItem on Circular {
      id
      title
      description
      isPublished
      publishedAt
      sharedWith
      isRead
      courses {
        id
        title
        isArchived
      }
      updatedAt
      createdBy {
        ...userItem
      }
      state
      readBy {
        ...readUserItem
      }
      isV2
    }
    ${commonFragment.readUser}
    ${userFragment.basicUser}
  `,
  circularDetails: gql`
    fragment circularsItemDetail on Circular {
      id
      title
      description
      isPublished
      publishedAt
      courses {
        id
        title
        isArchived
      }
      updatedAt
      sharedWith
      isRead
      createdBy {
        ...userItem
      }
      attachments {
        ...attachmentItem
      }
      state
      isDeleted
      sendEmail
      readBy {
        ...readUserItem
      }
      isV2
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUser}
    ${commonFragment.readUser}
  `,
};

export const organizationFragment = {
  organizationCircularFeed: gql`
    fragment organizationCircularFeedItem on Organization {
      id
      circularFeed(
        count: $count
        after: $after
        filters: $filters
        orderByField: PUBLISHED_AT
        orderByValue: DESC
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...circularsItem
          }
        }
        totalCount
      }
    }
    ${fragments.circularsFeed}
  `,
};
