import { getMemoizedFunction } from "Utils";

const getCycleIdToSetIdMapping = ({ cycles }) => {
  //returns mapping of cycle id to set id
  return _.reduce(
    cycles,
    (acc, cycle) => {
      const cycleId = _.get(cycle, "id", "");
      const setId = _.get(cycle, "set.id", "");
      acc[cycleId] = setId;
      return acc;
    },
    {}
  );
};

export const getCycleIdToSetIdMappingMemoized = getMemoizedFunction(
  getCycleIdToSetIdMapping
);

const getTaggedPracticesForCycle = ({ taggedPractices, cycleId }) => {
  return _.find(
    taggedPractices,
    ({ evaluationCycle }) => evaluationCycle.id === cycleId
  );
};

export const getPracticeWrappersForCycle = ({ taggedPractices, cycleId }) => {
  const taggedPracticesForCycle = getTaggedPracticesForCycle({
    taggedPractices,
    cycleId,
  });

  return _.get(taggedPracticesForCycle, "taggedPractice", []);
};

const getDisabledPracticeWrappers = ({ practiceWrappers, isAdmin, userId }) => {
  if (isAdmin) return [];
  return _.filter(practiceWrappers, ({ taggedBy, evidenceStatus }) => {
    return taggedBy.id !== userId || evidenceStatus === "APPROVED";
  });
};

const getAddedPractices = ({ selectedPractices, taggedPractices }) => {
  return _.filter(
    selectedPractices,
    practiceId => !_.includes(taggedPractices, practiceId)
  );
};

export const getAddedPracticesMemoized = getMemoizedFunction(getAddedPractices);

const getRemovedPractices = ({ selectedPractices, taggedPractices }) => {
  return _.filter(
    taggedPractices,
    practiceId => !_.includes(selectedPractices, practiceId)
  );
};

export const getRemovedPracticesMemoized = getMemoizedFunction(
  getRemovedPractices
);

export const trimSnpCodeLabels = ({ code }) => {
  const redundantCharacters = [" ", "(", ")"];

  return _.trim(code, redundantCharacters);
};

const getCycleIdToSelectedPracticesCountMapping = ({
  cycles,
  taggedPractices,
}) => {
  return _.reduce(
    cycles,
    (result, { id }) => {
      const practiceWrappers = getPracticeWrappersForCycle({
        taggedPractices,
        cycleId: id,
      });

      result[id] = _.size(practiceWrappers);

      return result;
    },
    {}
  );
};

export const getCycleIdToSelectedPracticesCountMappingMemoized = getMemoizedFunction(
  getCycleIdToSelectedPracticesCountMapping
);

const getDisabledPracticesForCycle = ({
  practiceWrappers,
  userId,
  isAdmin,
}) => {
  const disabledPracticeWrappers = getDisabledPracticeWrappers({
    practiceWrappers,
    isAdmin,
    userId,
  });

  return _.map(disabledPracticeWrappers, ({ practice }) => practice.id);
};

export const getDisabledPracticesForCycleMemoized = getMemoizedFunction(
  getDisabledPracticesForCycle
);

const getPracticesForCycle = ({ practiceWrappers, userId, isAdmin }) => {
  const disabledPracticesForCycle = getDisabledPracticesForCycleMemoized({
    practiceWrappers,
    userId,
    isAdmin,
  });

  const taggedPracticesForCycle = _.map(
    practiceWrappers,
    ({ practice }) => practice.id
  );

  return {
    taggedPracticesForCycle: _.difference(
      taggedPracticesForCycle,
      disabledPracticesForCycle
    ),
    disabledPracticesForCycle,
  };
};

export const getPracticesForCycleMemoized = getMemoizedFunction(
  getPracticesForCycle
);

export const getApprovedPracticesForCycle = ({ practiceWrappers }) => {
  const approvedPracticeWrappers = _.filter(
    practiceWrappers,
    ({ evidenceStatus }) => evidenceStatus === "APPROVED"
  );
  return _.map(approvedPracticeWrappers, ({ practice: { id } }) => id);
};
