import React from "react";
import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";
import {
  UIButton,
  I18nHOC,
  UILabel,
  EmptyField,
  withPusherBind,
  withLoader,
  UIModal,
} from "UIComponents";
import classes from "./OrganizationResourcesSelection.scss";
import { AttachmentList, CreateDropDown } from "Attachment";
import SelectResourceDialogue from "./Components/SelectResourceDialogue";
import {
  createOrganizationResources,
  deleteOrganizationResources,
} from "OrganizationResources/modules/OrganizationResourcesModule";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { getPlannerOrganizationResourcesOfNodeQuery } from "UnitPlans/modules/UnitPlanQuery";
import { getPlannerOrganizationResourcesOfNodeFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import { AlertIcon, ResourcesLineIcon } from "SvgComponents";
import { colors } from "Constants";
import { getLabelValue } from "Utils";
import AddAssignment from "ClassRoom/components/AddAssignment";

const cardTypes = ["horizontal", "horizontalV2", "verticalHorizontal"];

const DROP_DOWN_OPTIONS = [
  { key: "upload_device", label: "common:upload_device" },
  { key: "add_link", label: "common:add_link" },
  { key: "add_google_drive", label: "common:add_google_drive" },
  { key: "add_one_drive", label: "common:add_one_drive" },
  { key: "add_note", label: "common:add_note" },
  {
    key: "select_resource",
    label: "common:select_unit_resources",
    icon: <ResourcesLineIcon width={16} height={16} fill={colors.gray48} />,
  },
  { key: "upload_from_unit_plan", label: "upload_from_unit_plan" },
];

const buttonDropDownContainerStyle = {
  top: 40,
  right: 0,
  left: "initial",
};

const addButtonStyle = {
  minWidth: "80px",
  height: "32px",
  marginLeft: "8px",
};

const resourceModalData = {
  steps: [
    {
      label: "Select Unit",
      value: "UNIT_SELECTION",
      heading: "Select Unit",
    },
    {
      label: "Select resources",
      value: "RESOURCE_SELECTION",
      heading: "Select Resources",
    },
  ],
};

const styles = {
  containerStyle: {
    gridGap: "40px 24px",
  },
  horizontalCardStyle: {
    padding: "unset",
    border: "unset",
    height: "40px",
    background: "unset",
  },
  attachmentContainerStyle: {
    gridTemplateColumns: "repeat(2,1fr)",
  },
};

const getAttachmentListStyles = params => {
  const {
    cardType,
    attachments,
    isResourceUploading,
    containerStyle,
    horizontalCardStyle,
    attachmentContainerStyle,
  } = params;
  const applyAttachmentCustomStyles = _.includes(cardTypes, cardType);

  if (!_.isEmpty(attachments) || isResourceUploading) {
    if (applyAttachmentCustomStyles) {
      return {
        containerStyle: styles.containerStyle,
        horizontalCardStyle: styles.horizontalCardStyle,
        attachmentContainerStyle: styles.attachmentContainerStyle,
      };
    } else {
      return {
        containerStyle,
        horizontalCardStyle,
        attachmentContainerStyle,
      };
    }
  } else {
    return {};
  }
};

class OrganizationResourcesSelection extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectResourceDialogue: false,
      showAddResourceFromUnitModal: false,
    };
  }

  toggleSelectUnitResourceDialogue = () => {
    this.setState({
      selectResourceDialogue: !this.state.selectResourceDialogue,
    });
  };

  toggleAddResourceFromUnitModal = () => {
    this.setState({
      showAddResourceFromUnitModal: !this.state.showAddResourceFromUnitModal,
    });
  };

  deleteAssessmentResource = vals => {
    const {
      deleteOrganizationResources,
      organizationResources,
      parentType,
      parentId,
    } = this.props;

    const data = _.differenceBy(organizationResources, vals, "id");

    deleteOrganizationResources({
      ids: _.map(data, item => item.id),
      moduleType: parentType,
      moduleId: parentId,
    });
  };

  addSelectedResources = selectedResources => {
    const { parentId, parentType, createOrganizationResources } = this.props;

    createOrganizationResources({
      parentId: null,
      moduleId: parentId,
      moduleType: parentType,
      types: "PLANNER_RESOURCE",
      organizationResources: _.map(selectedResources, resource => {
        return {
          resourceType: "ORGANIZATION_RESOURCE",
          label: "",
          itemId: resource.id,
          visibleTo: [],
          type: "PLANNER_RESOURCE",
        };
      }),
    });
  };

  onContentUploaded = async (params, uploadId) => {
    const {
      createOrganizationResources,
      unitPlanId,
      t,
      parentId,
      parentType,
    } = this.props;

    //TODO: Fix this logic

    const moduleId = unitPlanId ? unitPlanId : parentId;

    const moduleType = unitPlanId ? "UNIT_PLAN" : parentType;

    const organizationResources = [];

    if (_.size(params) > 0) {
      _.forEach(params, param => {
        const attachment = [
          "id",
          "name",
          "title",
          "type",
          "mimeType",
          "url",
          "metadata",
          "thumbUrl",
        ].reduce((result, key) => ({ ...result, [key]: param[key] }), {});
        organizationResources.push({
          label: getLabelValue({ item: param, t }),
          resourceType: "FILE",
          attachment,
        });
      });
    }

    const res = await createOrganizationResources({
      types: "PLANNER_RESOURCE",
      parentId: null,
      visibleTo: [],
      moduleId,
      moduleType,
      organizationResources,
    });

    if (_.get(res, "data", null) && moduleType == "UNIT_PLAN") {
      this.addSelectedResources(
        _.get(res, "data.platform.createOrganizationResources", [])
      );
    }
  };

  onDropDownOptionClick = ({ key }) => {
    switch (key) {
      case "select_resource":
        this.toggleSelectUnitResourceDialogue();
        break;
      case "upload_from_unit_plan":
        this.toggleAddResourceFromUnitModal();
        break;
    }
  };

  getAttachmentsToRender = ({ organizationResources }) => {
    return _.reduce(
      organizationResources,
      (result, resource) => {
        const resourceToAdd = _.get(resource, "item", null)
          ? _.get(resource, "item.attachment", null)
          : _.get(resource, "attachment", null);

        if (resourceToAdd) {
          resourceToAdd.name =
            _.get(resource, "item.label", null) ||
            _.get(resource, "label", null) ||
            resourceToAdd.name;

          result.push({ ...resourceToAdd, id: resource.id });
        }

        return result;
      },
      []
    );
  };

  getEmptyState = () => {
    // In view mode of unit plan only text should come in empty state
    const { mode, t, showViewEmpty, showEditEmpty } = this.props;
    const showEmpty = mode == "view" ? showViewEmpty : showEditEmpty;
    return showEmpty ? (
      mode == "view" ? (
        <div className={classes.emptyTextContainer}>
          <AlertIcon fill={colors.yellow50} width={16} height={16} />
          <div className={classes.emptyText}>
            {t("common:no_resources_yet")}
          </div>
        </div>
      ) : (
        <EmptyField title={t("common:no_resources_yet")} />
      )
    ) : null;
  };

  getFilteredDropDown = () => {
    const { unitPlanId, assessmentType } = this.props;

    if (unitPlanId) {
      return _.filter(
        DROP_DOWN_OPTIONS,
        item => !_.includes(["upload_from_unit_plan"], item.key)
      );
    } else {
      if (assessmentType === "qt") {
        return _.filter(
          DROP_DOWN_OPTIONS,
          item => !_.includes(["select_resource", "add_note"], item.key)
        );
      }
      return _.filter(
        DROP_DOWN_OPTIONS,
        item =>
          !_.includes(["select_resource", "upload_from_unit_plan"], item.key)
      );
    }
  };

  getAttachmentList = () => {
    const {
      organizationResources,
      mode,
      cardType,
      parentId,
      cardStyle,
      labelComponent,
      label,
      toolTipText,
      isDisabledModeObj,
      resourcesLabelContainerStyle,
      isResourceUploading,
    } = this.props;

    const attachments = this.getAttachmentsToRender({ organizationResources });

    const {
      containerStyle = {},
      horizontalCardStyle = {},
      attachmentContainerStyle = {},
    } = getAttachmentListStyles({
      cardType,
      attachments,
      isResourceUploading,
      containerStyle: this.props.containerStyle,
      horizontalCardStyle: this.props.horizontalCardStyle,
      attachmentContainerStyle: this.props.attachmentContainerStyle,
    });

    return (
      <AttachmentList
        value={attachments}
        cardType={cardType}
        mode={mode}
        showCreateDropdown={false}
        clubbingCards={27}
        showSingleCardTheme={false}
        parentId={parentId}
        parentType={"ORG_RESOURCES"}
        cardStyle={cardStyle}
        labelComponent={
          _.get(labelComponent, "props.label") !== label && labelComponent
        }
        labelContainerStyle={resourcesLabelContainerStyle}
        updateInputField={value => this.deleteAssessmentResource(value)}
        containerStyle={containerStyle}
        horizontalCardStyle={horizontalCardStyle}
        attachmentContainerStyle={attachmentContainerStyle}
        toolTipText={toolTipText}
        isDisabledModeObj={isDisabledModeObj}
        shouldCallMutation={true}
      />
    );
  };

  render() {
    const {
      t,
      unitPlanId,
      parentId,
      parentType,
      organizationResources,
      label,
      mode,
      nodeId,
      ResourceslabelContainerStyle,
      isShowIconsInBar,
      showV2Icons,
      isAssessmentCreationRevamp,
    } = this.props;

    const { selectResourceDialogue, showAddResourceFromUnitModal } = this.state;

    return (
      <div className={classes.container}>
        {isAssessmentCreationRevamp && this.getAttachmentList()}
        <div className={classes.topContainer}>
          <div
            className={classes.labelContainer}
            style={ResourceslabelContainerStyle}
          >
            <UILabel labelStyle={{ margin: 0 }} label={label} />
          </div>

          {mode == "edit" && (
            <CreateDropDown
              dropDownOptions={this.getFilteredDropDown()}
              buttonDropDownContainerStyle={buttonDropDownContainerStyle}
              buttonComponent={
                <UIButton
                  color="pink"
                  containerStyle={addButtonStyle}
                  size="sm"
                >
                  {t("common:add")}
                </UIButton>
              }
              exportAsPdf={true}
              parentType={"ORG_RESOURCES"}
              parentId={nodeId}
              addAttachment={this.onContentUploaded}
              onDropDownOptionClick={this.onDropDownOptionClick}
              isShowIconsInBar={isShowIconsInBar}
              showV2Icons={showV2Icons}
            />
          )}
        </div>

        {selectResourceDialogue && (
          <SelectResourceDialogue
            parentType={parentType}
            parentId={parentId}
            unitPlanId={unitPlanId}
            onClose={this.toggleSelectUnitResourceDialogue}
            addSelectedResources={this.addSelectedResources}
          />
        )}

        {!isAssessmentCreationRevamp &&
          (organizationResources.length > 0
            ? this.getAttachmentList()
            : this.getEmptyState())}

        {showAddResourceFromUnitModal && (
          <UIModal
            isOpen={showAddResourceFromUnitModal}
            modalContent={classes.addResourceModalContainer}
            onRequestClose={() => this.toggleAddResourceFromUnitModal()}
          >
            <AddAssignment
              mode={"create"}
              resourceSelection={true}
              steps={resourceModalData["steps"]}
              onClose={() => this.toggleAddResourceFromUnitModal()}
              onDoneResourceSelection={params => {
                this.onContentUploaded(params);
              }}
            ></AddAssignment>
          </UIModal>
        )}
      </div>
    );
  }
}

OrganizationResourcesSelection.propTypes = {
  ...UIBaseComponent.propTypes,
};

OrganizationResourcesSelection.defaultProps = {
  ...UIBaseComponent.defaultProps,
  mode: "edit",
  cardType: "verticalHorizontal",
  cardStyle: {
    height: 232,
  },
};

const mapActionCreators = {
  createOrganizationResources,
  deleteOrganizationResources,
};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;

  const keys = Object.keys(progressOfUploads);
  const isResourceUploading = _.some(keys, key => {
    const resource = progressOfUploads[key];
    return resource?.parentType == "ORG_RESOURCES";
  });

  return {
    nodeId: ownProps.parentId,
    type: ownProps.parentType,
    organizationResources: ownProps.value,
    isData: !_.isEmpty(ownProps.value),
    isResourceUploading,
  };
};

const ComposedOrganizationResourcesSelection = compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getPlannerOrganizationResourcesOfNodeQuery, {
    name: "getPlannerOrganizationResourcesOfNode",
    skip: ({ value }) => value,
    options: ({ nodeId, type, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: nodeId, type, portalType },
    }),
    props({
      getPlannerOrganizationResourcesOfNode,
      ownProps: { nodeId, type },
    }) {
      const nodeDetails = getPlannerOrganizationResourcesOfNodeFromCache({
        nodeId,
        type,
      });

      return {
        isData: !_.isEmpty(nodeDetails),
        onPusherEventTriggered: () =>
          getPlannerOrganizationResourcesOfNode.refetch(),
        organizationResources: _.get(nodeDetails, "organizationResources", []),

        isLoading:
          getPlannerOrganizationResourcesOfNode["networkStatus"] == 1 ||
          getPlannerOrganizationResourcesOfNode["networkStatus"] == 2,
      };
    },
  }),
  withPusherBind(["assessmentResourcesUpdated"]),
  withLoader
)(OrganizationResourcesSelection);

ComposedOrganizationResourcesSelection.propTypes = {
  ...UIBaseComponent.propTypes,
};

ComposedOrganizationResourcesSelection.defaultProps = {
  ...UIBaseComponent.defaultProps,
  mode: "edit",
  cardType: "vertical",
  showViewEmpty: true,
};

export default ComposedOrganizationResourcesSelection;
