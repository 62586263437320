import React, { memo } from "react";
import classes from "./EULADetails.scss";
import { UIButton } from "UIComponents";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { getLatestTurnitinEulaQuery } from "modules/CommonQuery";
import { withLoader } from "UIComponents";
import { acceptTurnitinEula } from "modules/Services";
import { getSettingValue } from "modules/PermissionModule";

const EULADetails = props => {
  const { onRequestClose, latestEulaDetails, acceptTurnitinEula, t } = props;

  const { content, version, locale } = latestEulaDetails;

  const onClickAgree = async event => {
    const { onClickAgree } = props;
    const acceptanceResponce = await acceptTurnitinEula({
      input: { version, locale },
    });
    if (acceptanceResponce) onClickAgree();
    onRequestClose(event);
  };

  return (
    <div
      className={classes.container}
      onClick={event => event.stopPropagation()} //To prevent modal from executing clicks on underlying Table
    >
      <div className={classes.header}>
        {t("classRoom:turnitin_eula_modal_title")}
      </div>
      <div className={classes.contentContainer}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div className={classes.footer}>
        <UIButton size={"sm"} color={"blue"} onClick={onClickAgree}>
          {t("common:i_agree")}
        </UIButton>
      </div>
    </div>
  );
};
const EULADetailsMemoised = memo(EULADetails);

const mapStateToProps = state => {
  const locale = getSettingValue({
    name: "locale",
    organizationId: state.login.userInfo.org_id,
    userId: state.login.userInfo.id,
    userEntityType: state.login.userInfo.userEntityType,
  });
  return {
    isData: true,
    isLoading: false,
    locale,
  };
};

const mapActionCreators = {
  acceptTurnitinEula,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getLatestTurnitinEulaQuery, {
    name: "getLatestTurnitinEula",
    options: ({ locale }) => ({
      fetchPolicy: "network-only",
      variables: {
        input: { locale },
      },
    }),
    props({ getLatestTurnitinEula, ownProps: { isData, isLoading } }) {
      const latestEulaDetails = _.get(
        getLatestTurnitinEula,
        "integration.getLatestTurnitinEula",
        {}
      );
      return {
        latestEulaDetails,
        isData: !_.isEmpty(latestEulaDetails) && isData,
        isLoading:
          getLatestTurnitinEula["networkStatus"] == 1 ||
          getLatestTurnitinEula["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(EULADetailsMemoised);
