import React, { useEffect } from "react";
import { compose, graphql } from "react-apollo";
import classes from "./JsonEditorContainer.scss";
import { updateBaseProgressReportTemplate } from "Tooling/modules/ToolingModule";
import { getProgressReportBaseTemplateQuery } from "Tooling/modules/ToolingQueries";
import { getProgressReportBaseTemplateFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
import { connect } from "react-redux";
import { withLoader, JsonEditor } from "UIComponents";

const styles = {
  html: {
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      maxHeight: "550px",
    },
  },
};

const JsonEditorContainer = props => {
  const {
    templateData,
    setTemplateData,
    renderEditor,
    setRenderEditor,
    editorMode,
    selectedTemplate,
    templateJson,
    setDisableUpdateButton,
  } = props;

  useEffect(() => {
    if (selectedTemplate != null) {
      setTemplateData(templateJson.body);
      setDisableUpdateButton(true);
      setRenderEditor(false);
    }
  }, [selectedTemplate, templateJson]);

  useEffect(() => {
    if (selectedTemplate != null)
      setDisableUpdateButton(_.isEqual(templateJson.body, templateData));
  }, [templateData]);

  const handleChildEditorMount = isRenderEditor => {
    setRenderEditor(isRenderEditor);
  };

  const handleChange = val => {
    setTemplateData(val);
  };

  const getTemplateName = () => {
    return selectedTemplate != null ? templateJson.name : null;
  };
  const handleJsonFormatError = error => {
    console.error(error);
  };

  return (
    <div className={classes.container}>
      <JsonEditor
        renderEditor={renderEditor}
        value={templateData}
        htmlElementProps={styles.html}
        mode={editorMode}
        parentChildMount={handleChildEditorMount}
        onChange={handleChange}
        name={getTemplateName()}
        onError={handleJsonFormatError}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
  };
};

const mapActionCreators = {
  updateBaseProgressReportTemplate,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getProgressReportBaseTemplateQuery, {
    skip: props => props.selectedTemplate == null,
    name: "getBaseProgressReportJson",
    options: props => ({
      fetchPolicy: "cache-and-network",
      variables: { id: props.selectedTemplate },
    }),
    props({
      getBaseProgressReportJson,
      ownProps: { isData, isLoading, selectedTemplate },
    }) {
      const templateJson = getProgressReportBaseTemplateFromCache({
        id: selectedTemplate,
      });
      return {
        templateJson,
        isData: !_.isEmpty(templateJson) && isData,
        isLoading:
          getBaseProgressReportJson["networkStatus"] == 1 ||
          getBaseProgressReportJson["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(JsonEditorContainer);
