import update from "immutability-helper";
import request from "superagent";
import axios from "axios";
// import LogRocket from "logrocket";
import { goToAdminRoute } from "modules/NavigationModule";
import { Sentry } from "services/sentry";
import { goToPlatformHomeRoute } from "modules/NavigationModule";
import { push, replace } from "react-router-redux";
import client from "apolloClient";
import { getPermissionsQuery } from "QueryMutationFragment/CommonQueryMutationFragment/queries";
import ACLStore from "lib/aclStore";
import Microsoft from "lib/microsoft";
import { userRoles } from "store/componentPermissionsMap";
import { getStaffBasicDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { SNPv2_VISITOR_PORTAL_PERM_GROUP } from "Constants/permissionConstants";
import { setPusherConnection } from "modules/PusherModule";
import { initGA, setGA, getBackendServerUrl } from "Utils";

// import { loadHelpCrunch } from "lib/helpCrunch";
import { loadIntercom } from "lib/intercom";
import {
  INTERCOM_APP_ID,
  DUMMY_USER_INFO,
  PUBLIC_USER_INFO,
  mypConfig,
  ubdConfig,
} from "Constants";
import { loadUpvoty, identifyUserUpvoty } from "lib/upvoty";
import * as EventTracker from "lib/eventTracker";
import {
  handleQueryParameters,
  updateQueryParameters,
} from "modules/NavigationModule";
import { loadTracker } from "lib/eventTracker";
import {
  loadSprig,
  loadUserInfo as loadUserInfoSprig,
  logoutUser as logoutUserFromSprig,
} from "lib/sprigStudies";
import {
  GOOGLE_ANALYTICS_ID,
  USER_TYPE_ENTITY_MAPPING,
  CURRICULUM_PLANS_CONSTANTS_MAPPING,
  CURRICULUM_TYPE_ORDER_MAPPING,
  CURRICULUM_PLAN_ORDER_MAPPING,
  CURRICULUM_PLAN_LABEL_MAPPING_FOR_EXTERNAL_API,
} from "Constants";
import { goToRelativeRoute } from "modules/Services";
import {
  getUserInfofromStorage,
  setUserInfoInStorage,
  clearLocalStorage,
} from "services/localStorage";
import i18next from "lib/i18n";
import querystringify from "querystringify";
import { addChild } from "Courses/modules/CoursesModule";
import { setAcademicYearSelected } from "Platform/modules/PlatformModule";
import {
  enableUpvoty,
  enableMixPanel,
  enableGoogleAnalytics,
  enableSprig,
} from "config";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import { CURRICULUM_SUBSCRIPTION_PLAN_PERM } from "Constants/permissionConstants";

// if (__PROD__) loadHelpCrunch();
// if (__PROD__)

// Constants
export const NAME = "login";
export const AUTH_SUPERADMIN_SUCCESS = "AUTH_SUPERADMIN_SUCCESS" + " " + NAME;
export const REMOVE_SUPERADMIN_AUTH = "REMOVE_SUPERADMIN_AUTH" + " " + NAME;
export const SET_DESIGNATION_LIST = "SET_DESIGNATION_LIST" + " " + NAME;
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS" + " " + NAME;
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS" + " " + NAME;
export const SET_ORGANIZATION_PLANNER_FORMAT =
  "SET_ORGANIZATION_PLANNER_FORMAT" + " " + NAME;

export const UPDATE_SYSTEM_CONSTANTS = "UPDATE_SYSTEM_CONSTANTS" + " " + NAME;
export const SET_LOADING_STATE = "SET_LOADING_STATE" + " " + NAME;

export const SET_TOAST_MSG = "SET_TOAST_MSG" + " " + NAME;
export const CLEAR_TOAST_MSG = "CLEAR_TOAST_MSG" + " " + NAME;
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB" + " " + NAME;
export const UPDATE_INPUT_FIELD = "UPDATE_INPUT_FIELD" + " " + NAME;
export const SET_CURRENT_SCREEN = "SET_CURRENT_SCREEN" + " " + NAME;
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR" + " " + NAME;
export const INVITE_TO_COMMUNITY_STATUS =
  "INVITE_TO_COMMUNITY_STATUS" + " " + NAME;

export const SET_HELP_CENTER_VIDEO_DIRECT_LINK =
  "SET_HELP_CENTER_VIDEO_DIRECT_LINK" + " " + NAME;
export const RETENTION_MODAL_STATUS = "RETENTION_MODAL_STATUS" + " " + NAME;
export const BIFURCATION_MODAL_STATUS = "BIFURCATION_MODAL_STATUS" + " " + NAME;

export const STATUS_CODES = {
  SUP_USR_EXSTS: "Your account already exists. ",
  SUP_DOM_MIS_MTCH_NO_ORG: "Domain doesn't match",
  SUP_DOM_MIS_MTCH_HS_ORG:
    "Domain doesn't match with your school domain, you can send approve request to admin",
};

export const UPDATE_ORGANIZATION_FILTERS =
  "UPDATE_ORGANIZATION_FILTERS" + " " + NAME;
export const INIT_ORGANIZATION_FILTERS =
  "INIT_ORGANIZATION_FILTERS" + " " + NAME;

export const UPDATE_ORGANIZATION_LIST_DATA =
  "UPDATE_ORGANIZATION_LIST_DATA" + " " + NAME;

export const SET_BIFURCATION_ACTION = "SET_BIFURCATION_ACTION" + " " + NAME;
export const SET_REDIRECT_URL = "SET_REDIRECT_URL" + " " + NAME;
export const SET_IS_USER_FROM_PUBLIC_ACCESS =
  "SET_IS_USER_FROM_PUBLIC_ACCESS" + " " + NAME;
export const SET_CHILD_STATUS = "SET_CHILD_STATUS" + " " + NAME;
export const SET_PRODUCT_UPDATE_DIRECT_LINK =
  "SET_PRODUCT_UPDATE_DIRECT_LINK" + " " + NAME;
export const SET_PRODUCT_UPDATE_MODAL_OPEN =
  "SET_PRODUCT_UPDATE_MODAL_OPEN" + " " + NAME;
export const SET_LAST_CHILD_STATUS = "SET_LAST_CHILD_STATUS" + " " + NAME;
export const SET_CHILD_DATA = "SET_CHILD_DATA" + " " + NAME;
export const SET_PARENT_LOGIN_TAB = "SET_PARENT_LOGIN_TAB" + " " + NAME;
export const SET_AUTH_REGION = "SET_AUTH_REGION" + " " + NAME;
export const SET_PARENT_INVITE_CODE = "SET_PARENT_INVITE_CODE" + " " + NAME;
export const UPDATE_USER_INFO = "UPDATE_USER_INFO" + " " + NAME;
export const UPDATE_INVITED_FAMILY_DATA =
  "UPDATE_INVITED_FAMILY_DATA" + " " + NAME;
export const UPDATE_INVITED_FAMILY_CHILD_DATA =
  "UPDATE_INVITED_FAMILY_CHILD_DATA" + " " + NAME;

export const UPDATE_ORGANIZATION_SWITCHER_DETAILS =
  "UPDATE_ORGANIZATION_SWITCHER_DETAILS" + " " + NAME;

export const SET_IDENTITY_POOL_DETAILS =
  "SET_IDENTITY_POOL_DETAILS" + " " + NAME;

export const SET_IDENTITY_SCREEN_FILTERS =
  "SET_IDENTITY_SCREEN_FILTERS" + " " + NAME;

export const SET_PORTAL_TYPE = "SET_PORTAL_TYPE" + " " + NAME;

// Actions Creator
export const initAllThirdPartyModules = () => {
  i18next();
  //initGoogleAnalytics();
  // initLogRocket();
  // initMixPanel();
  loadIntercom();
};

export const initGoogleAnalytics = () => {
  if (enableGoogleAnalytics) {
    initGA();
  }
};

/* export const initLogRocket = options => {
  try {
    // console.log("init log rocket");
    if (__PROD__) {
      LogRocket.init("toddle/toddle-teacher-portal", options);
    }
    if (__STAGING__) {
      LogRocket.init("mbqrca/tod-staging", options);
    }
  } catch (e) {}
}; */

export const initMixPanel = () => {
  if (!window.mixpanel) {
    if (!enableMixPanel) loadTracker();
    if (enableMixPanel) loadTracker(enableMixPanel);
    loadCampaignParams();
  }
};

export const initSprig = () => {
  if (!window.Sprig) {
    if (!enableSprig) loadSprig();
    if (enableSprig) loadSprig(enableSprig);
  }
};

export const loadCampaignParams = () => {
  const { params, first_params } = localStorage.campaignParams
    ? JSON.parse(localStorage.campaignParams)
    : {};

  if (!_.isEmpty(params)) {
    EventTracker.recordTrait({ data: params });
    EventTracker.recordTraitOnce({ data: first_params });
    EventTracker.recordRegister({ data: params });
  }
  delete localStorage.campaignParams;
};

export const updateOrganizationListData = data => {
  return { type: UPDATE_ORGANIZATION_LIST_DATA, payload: data };
};

export const setDesignationList = data => {
  return { type: SET_DESIGNATION_LIST, payload: data };
};
export const authSuperAdminSuccess = data => {
  return { type: AUTH_SUPERADMIN_SUCCESS, payload: data };
};

export const updateOrganizationFilters = data => {
  return {
    type: UPDATE_ORGANIZATION_FILTERS,
    data,
  };
};

export const initOrganizationFilters = () => {
  return {
    type: INIT_ORGANIZATION_FILTERS,
  };
};

export const authUserSuccessAction = data => {
  return {
    type: AUTH_USER_SUCCESS,
    payload: data,
  };
};

export const starChatWidgetForVisitors = () => {
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: INTERCOM_APP_ID,
    });
  }
};

export const starChatWidget = ({
  user_id,
  first_name,
  last_name,
  email,
  org_id,
  org_name,
  org_plan,
  Is_IB_PYP,
  Is_IB_MYP,
  Is_UBD,
  job_title,
  Is_IB_Evaluator,
  CURRICULUM_PROGRAMS_WITH_PLAN,
}) => {
  // console.log('OrgPlan',org_plan);
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: INTERCOM_APP_ID,
      email: email,
      job_title,
      Is_IB_PYP,
      Is_UBD,
      Is_IB_MYP,
      Is_IB_Evaluator,
      CURRICULUM_PROGRAMS_WITH_PLAN,
      name: `${first_name} ${last_name}`,
      user_id: `${org_id}_${user_id}`,
      company: {
        id: `${org_id}`,
        name: `${org_name}`,
        plan: `${org_plan}`,
      },
    });
  }
};

export const openChat = () => {
  if (window.Intercom) {
    window.Intercom("show");
  }
};
function stopChatWidget() {
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
}
export const openNewConversation = prefilledMessage => {
  if (window.Intercom) {
    window.Intercom("showNewMessage", prefilledMessage);
  }
};

export const updateInputField = (screenName, data) => {
  return { type: UPDATE_INPUT_FIELD, payload: { screenName, data } };
};

export const setCurrentScreen = data => {
  return { type: SET_CURRENT_SCREEN, data: data };
};

export const setInviteToCommunityStatus = status => {
  return { type: INVITE_TO_COMMUNITY_STATUS, data: status };
};

export const setHelpCenterVideoDirectLinkOpen = value => {
  return { type: SET_HELP_CENTER_VIDEO_DIRECT_LINK, data: value };
};

export const setRetentionModalStatus = status => {
  return { type: RETENTION_MODAL_STATUS, data: status };
};

export const setBifurcationModalStatus = status => {
  return { type: BIFURCATION_MODAL_STATUS, data: status };
};

export const setToastMsg = data => {
  return { type: SET_TOAST_MSG, payload: data };
};

export const setLoginError = error => {
  return { type: SET_LOGIN_ERROR, payload: error };
};

export const clearToast = () => {
  return { type: CLEAR_TOAST_MSG };
};

export function logoutUserSuccess(data) {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: data,
  };
}
export function setLoadingState(data) {
  return {
    type: SET_LOADING_STATE,
    payload: data,
  };
}

export const setActiveTab = data => {
  return { type: SET_ACTIVE_TAB, payload: data };
};
export const updateUserInfo = data => {
  return { type: UPDATE_USER_INFO, payload: data };
};
export const updateInvitedFamilyData = data => {
  return { type: UPDATE_INVITED_FAMILY_DATA, payload: data };
};
export const updateInvitedFamilyChildData = data => {
  return { type: UPDATE_INVITED_FAMILY_CHILD_DATA, payload: data };
};

export const updateOrganizationSwitcherDetails = data => {
  return {
    type: UPDATE_ORGANIZATION_SWITCHER_DETAILS,
    payload: data,
  };
};

export const logoutUser = ({ redirectionOption, redirectUri } = {}) => {
  // Raven.setUserContext();
  stopChatWidget();

  //Delete JWT, Teacher Prefs
  clearLocalStorage();

  //Reset ACL Store // Optional
  ACLStore.clearAclStore();
  //Mark user as Loging in Redux //Optional
  //dispatch(logoutUserSuccess());

  //Remove User Identity from EventTracker
  EventTracker.recordEvent({ eventName: "Logged Out" });
  EventTracker.clearIdentity();

  //Remove User Identity from Sprig
  logoutUserFromSprig();

  //Microsoft.logout();
  //Refresh Browser
  // dispatch(replace("/"));
  if (redirectionOption === "integration_sso" || redirectUri) {
    window.location.href = redirectUri;
  } else {
    window.location.href = "/";
  }
};

export const getUseNewIdentityFlowStatus = () => {
  const userInfo = getUserInfofromStorage();

  return _.get(userInfo, "useNewIdentityFlow", false);
};

export const isUserLoggedOut = () => {
  /**User is considered to be logged out, if either user
   * has not chosen organization(identity) or
   * there is no user info in localStorage
   */

  const userInfo = getUserInfofromStorage();
  if (_.isEmpty(userInfo)) return true;

  /**Consider isIdentitySelected only when it is present */
  /**It will be present when user has opened organization switcher screen
   * and has not selected any organization(identity)
   */
  const isIdentitySelected = _.has(userInfo, "isIdentitySelected")
    ? userInfo["isIdentitySelected"]
    : true;

  return !isIdentitySelected;
};

export const setBifurcationAction = data => {
  return {
    type: SET_BIFURCATION_ACTION,
    payload: data,
  };
};

export const setRedirectUrl = data => {
  return {
    type: SET_REDIRECT_URL,
    payload: data,
  };
};

export const setUserComingFromPublicAccess = data => {
  return {
    type: SET_IS_USER_FROM_PUBLIC_ACCESS,
    payload: data,
  };
};

export const setIsLastChildRemoved = data => {
  return {
    type: SET_LAST_CHILD_STATUS,
    payload: data,
  };
};

export const setChildStatus = data => {
  return {
    type: SET_CHILD_STATUS,
    payload: data,
  };
};

export const setProductUpdateDirectLinkOpen = data => {
  return {
    type: SET_PRODUCT_UPDATE_DIRECT_LINK,
    payload: data,
  };
};

export const setProductUpdateModalOpen = data => {
  return {
    type: SET_PRODUCT_UPDATE_MODAL_OPEN,
    payload: data,
  };
};

export const setChildData = data => {
  return {
    type: SET_CHILD_DATA,
    payload: data,
  };
};

export const setCurrentParentLoginTab = data => {
  return {
    type: SET_PARENT_LOGIN_TAB,
    payload: data,
  };
};

export const setIdentityPoolDetails = data => {
  return {
    type: SET_IDENTITY_POOL_DETAILS,
    payload: data,
  };
};

export const setIdentityScreenFilters = data => {
  return {
    type: SET_IDENTITY_SCREEN_FILTERS,
    payload: data,
  };
};

export const setAuthRegion = data => {
  return {
    type: SET_AUTH_REGION,
    payload: data,
  };
};

export const setPortalType = data => {
  return {
    type: SET_PORTAL_TYPE,
    payload: data,
  };
};

export const setParentInviteCode = data => {
  return {
    type: SET_PARENT_INVITE_CODE,
    payload: data,
  };
};

// THUNKS

export const goToCommunityHome = ({ isForced = false } = {}) => {
  return async (dispatch, getState) => {
    let locationBeforeOnboarding = "/community/home/featured/all";
    if (!isForced) {
      locationBeforeOnboarding =
        _.get(getState(), "community.locationBeforeOnboarding", "") ||
        locationBeforeOnboarding;
    }
    //console.log("locationBeforeOnboarding", locationBeforeOnboarding);

    dispatch(
      goToRelativeRoute({
        route: locationBeforeOnboarding,
      })
    );
    dispatch(setActiveTab("community"));
  };
};

export const goToToddlePlateform = () => {
  return async (dispatch, getState) => {
    dispatch(goToPlatformHomeRoute());
    dispatch(setActiveTab("teacher"));
  };
};

export const updateCurrentScreen = queryParams => {
  return (dispatch, getState) => {
    let {
      type,
      navigateType,
      usertype,
      paramsToBeRemoved,
      role,
      region,
    } = queryParams;
    const params = _.pick(queryParams, [
      "type",
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
      "metadata",
    ]);

    if (!navigateType) {
      navigateType = "push";
    }
    if (usertype) {
      params["usertype"] = usertype;
    }
    if (role) {
      params["role"] = role;
    }

    if (region) {
      params["region"] = region;
    }

    switch (type) {
      case "loginHome":
        navigateType = "replace";
        delete params["usertype"];
        break;

      case "requestSuccess":
        navigateType = "replace";
        delete params["usertype"];
        break;
    }

    dispatch(
      updateQueryParameters({
        params,
        navigateType: navigateType,
        paramsToBeRemoved,
      })
    );
    dispatch(setCurrentScreen(type));
  };
};

export const isSettingEnabled = ({
  settings,
  settingName,
  valueCheck = false,
}) => {
  const value = _.get(
    _.find(settings, item => item.name == settingName),
    "value",
    "false"
  );

  if (!valueCheck) {
    return !value || value == "false" ? true : false;
  } else {
    return (value && value != "false") || value == "true" ? true : false;
  }
};

const getCurriculumProgramsWithPlanEnums = ({ curriculumPrograms }) => {
  const sortedCurriculumPrograms = curriculumPrograms.sort(
    (firstCurriculum, secondCurriculum) => {
      const firstCurriculumOrder =
        CURRICULUM_TYPE_ORDER_MAPPING[firstCurriculum.type];

      const secondCurriculumOrder =
        CURRICULUM_TYPE_ORDER_MAPPING[secondCurriculum.type];

      if (firstCurriculumOrder === secondCurriculumOrder) {
        const firstCurriculumSubscriptionPlanType = _.get(
          firstCurriculum,
          "subscriptionPlan.type"
        );

        const secondCurriculumSubscriptionPlanType = _.get(
          secondCurriculum,
          "subscriptionPlan.type"
        );

        return (
          CURRICULUM_PLAN_ORDER_MAPPING[firstCurriculumSubscriptionPlanType] -
          CURRICULUM_PLAN_ORDER_MAPPING[secondCurriculumSubscriptionPlanType]
        );
      }

      return firstCurriculumOrder - secondCurriculumOrder;
    }
  );

  const curriculumProgramWithPlans = _.join(
    _.uniq(
      _.map(sortedCurriculumPrograms, ({ type, subscriptionPlan }) => {
        const { type: subscriptionPlanType } = subscriptionPlan;

        return `${type}_${CURRICULUM_PLAN_LABEL_MAPPING_FOR_EXTERNAL_API[subscriptionPlanType]}`;
      })
    ),
    ","
  );

  return curriculumProgramWithPlans;
};

const getCurriculumProgramWithPlansPayload = ({
  userCurriculumPrograms,
  curriculumPrograms,
}) => {
  let updatedUserCurriculumPrograms = [...(userCurriculumPrograms ?? [])];

  /** Assuming that if user doesn't belong to any course,
   *   he/she is part of all curriculum programs  */
  if (_.isEmpty(updatedUserCurriculumPrograms)) {
    updatedUserCurriculumPrograms = curriculumPrograms;
  }

  const userCurriculumProgramTypes = _.map(
    updatedUserCurriculumPrograms,
    ({ type }) => type
  );

  const curriculumProgramsWithPlanEnums = getCurriculumProgramsWithPlanEnums({
    curriculumPrograms: userCurriculumPrograms,
  });

  const areCurriculumPlansEnabled = ACLStore.can(
    CURRICULUM_SUBSCRIPTION_PLAN_PERM
  );

  const Is_IB_PYP = _.includes(userCurriculumProgramTypes, CURRICULUM_TYPE_PYP);
  const Is_IB_MYP = _.includes(userCurriculumProgramTypes, CURRICULUM_TYPE_MYP);
  const Is_UBD = _.includes(userCurriculumProgramTypes, CURRICULUM_TYPE_UBD);

  const curriculumProgramWithPlansPayload = {
    CURRICULUM_PROGRAMS_WITH_PLAN: curriculumProgramsWithPlanEnums,
  };

  return {
    Is_IB_PYP,
    Is_IB_MYP,
    Is_UBD,
    ...(areCurriculumPlansEnabled ? curriculumProgramWithPlansPayload : {}),
  };
};

export const authUserSuccess = data => {
  return (dispatch, getState) => {
    const settings = _.get(data, "settings", []);
    const user_type = _.get(data, "user_type", "staff");
    const userLoggedIn = _.get(getState(), "login.userLoggedIn");

    const userCurriculumPrograms = data?.userCurriculumPrograms ?? [];
    const curriculumPrograms = data?.curriculumPrograms ?? [];

    const curriculumProgramWithPlansPayload = getCurriculumProgramWithPlansPayload(
      { userCurriculumPrograms, curriculumPrograms }
    );

    if (data.shouldInitThirdParty) {
      if (data.org_id && data.organization_id == null) {
        // To only start chat when authUserSuccess is called after graphql call

        //Sentry
        if (isSettingEnabled({ settings, settingName: "disableSentry" })) {
          Sentry.configureScope(scope => {
            scope.setUser({
              id: data.id,
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
              org_id: data.org_id,
              org_name: data.org_name,
              /**
               * @deprecated
               */
              orgPlan: data.orgPlan,
              designation: data.designation,
              user_type: data.user_type,
              ...curriculumProgramWithPlansPayload,
            });
            scope.setTag("org_id", data.org_id);
            scope.setTag("user_type", data.user_type);
          });
        }

        //Log Rocket
        // if (
        //   isSettingEnabled({
        //     settings,
        //     settingName: "enableLogRocket",
        //     valueCheck: true
        //   }) &&
        //   user_type == "staff"
        // ) {
        //   initLogRocket();
        //   LogRocket.identify(`${data.org_id}_${data.id}`, {
        //     org_id: `${data.org_id}`,
        //     name: `${data.first_name} ${data.last_name}`,
        //     plan: `${data.orgPlan}`,
        //     Is_IB_PYP,
        //     Is_IB_MYP,
        //     Is_UBD,
        //     job_title: data.designation
        //   });
        // }

        //MixPanel
        if (
          isSettingEnabled({ settings, settingName: "disableMixPanel" }) &&
          user_type == "staff"
        ) {
          initMixPanel();
          EventTracker.identifyUser({ identityString: data.email });
          /**
           * @deprecated
           */
          EventTracker.recordTrait({
            data: {
              uid: data.id,
              fname: data.first_name,
              lname: data.last_name,
              email: data.email,
              user_type: "user",
              role: data.designation,
              org_id: data.org_id,
              org_name: data.org_name,
              org_plan: data.orgPlan,
              ...curriculumProgramWithPlansPayload,
            },
          });
          EventTracker.setOrganization({ data });
          EventTracker.recordEvent({ eventName: "Logged In" });
        } else {
          window.mixpanel = null;
          window._kmq = null;
        }

        //Intercom
        if (
          isSettingEnabled({ settings, settingName: "disableIntercom" }) &&
          user_type == "staff"
        ) {
          starChatWidget({
            user_id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            org_id: data.org_id,
            org_name: data.org_name,
            ...curriculumProgramWithPlansPayload,
            /**
             * @deprecated
             */
            org_plan: data.orgPlan,

            job_title: data.designation,
            Is_IB_Evaluator: ACLStore.can(SNPv2_VISITOR_PORTAL_PERM_GROUP),
          });
        } else {
          stopChatWidget();
        }

        //Ably
        if (isSettingEnabled({ settings, settingName: "disableAbly" })) {
          dispatch(setPusherConnection(data.id));
        }

        //Google Analytics
        if (
          isSettingEnabled({
            settings,
            settingName: "disableGoogleAnalytics",
          }) &&
          user_type != "student"
        ) {
          initGoogleAnalytics();
          setGA({ userId: data.id, dimension1: user_type });

          // window[`ga-disable-${GOOGLE_ANALYTICS_ID}`] = !isSettingEnabled({
          //   settings,
          //   settingName: "disableGoogleAnalytics"
          // });
        }

        if (isSettingEnabled({ settings, settingName: "disableUpVoty" })) {
          if (enableUpvoty) {
            loadUpvoty();
            // UpVoty
            identifyUserUpvoty(
              `${data.org_id}_${data.id}`,
              `${data.first_name} ${data.last_name}`,
              `${data.email}`,
              `${data.profile_image}`
            );
          }
        }

        // sprig
        if (
          isSettingEnabled({ settings, settingName: "disableSprig" }) &&
          user_type == "staff"
        ) {
          initSprig();
          loadUserInfoSprig(data.id, data.email);
        }
      }
    }

    dispatch(
      authUserSuccessAction({
        ...(data || {}),
        userLoggedIn: _.get(getState(), "login.userLoggedIn"),
      })
    );
  };
};

export const getUserDetailsForStorage = ({
  staffDetails = {},
  organization,
}) => {
  const userType =
    _.get(staffDetails, "type", "staff") || staffDetails.user_type;
  return {
    id: _.get(staffDetails, "id", ""),
    first_name: staffDetails.firstName || staffDetails.first_name,
    last_name: staffDetails.lastName || staffDetails.last_name,
    email: staffDetails.email,
    profile_image: staffDetails.profileImage || staffDetails.profile_image,
    designation: _.get(staffDetails, "designation.title", ""),
    token: staffDetails.jwt,
    org_id: _.get(organization, "id", "") || staffDetails.org_id,
    jwt: _.get(staffDetails, "jwt", ""),
    //TODO: remove this field when curriculum program subscription plan is enabled
    orgIsFree: _.get(organization, "tier.isFree", false),
    user_type: _.get(staffDetails, "type", "staff") || staffDetails.user_type,
    userEntityType: _.get(USER_TYPE_ENTITY_MAPPING[userType], "entityType", ""),
    isDevUser: _.get(staffDetails, "isDevUser", false),
  };
};

export const hasCommunityDataLoaded = ({ userLoggedIn }) => {
  const userInfo = getUserInfofromStorage() || {};
  const communityData = _.find(_.get(userInfo, "userIdentityDetails", []), {
    portalType: "COMMUNITY",
  });

  return userLoggedIn ? !!communityData : true; // true is used when a user is on community page and not logged in (Viewing in Public access mode)
};

export const getUserInfo = ({ portalType } = {}) => {
  const userInfo = getUserInfofromStorage() || {};
  let portalUserInfo = null;
  if (isCommunityRoute()) {
    if (!portalType) {
      portalType = "COMMUNITY";
    }
    if (_.isEmpty(userInfo)) {
      portalUserInfo = _.cloneDeep(DUMMY_USER_INFO);
    }
  }

  if (isPublicPlatformRoute()) {
    portalUserInfo = _.cloneDeep(PUBLIC_USER_INFO);
  }

  //Handled only for staff. Because userIdentityDetails is not exposed for student
  if (
    !!portalType &&
    _.isEmpty(portalUserInfo) &&
    userInfo.user_type == "staff"
  ) {
    portalUserInfo = _.find(
      _.get(userInfo, "userIdentityDetails", []),
      item => item.portalType == portalType
    );
    if (!_.isEmpty(portalUserInfo) && !_.has(portalUserInfo, ["user_type"])) {
      portalUserInfo["user_type"] = "staff";
      portalUserInfo["userEntityType"] = "STAFF";
    }
  }

  const outputUserInfo = _.cloneDeep(portalUserInfo || userInfo || {});

  outputUserInfo.portalType = portalType;
  outputUserInfo.userIdentityDetails = _.get(
    userInfo,
    "userIdentityDetails",
    []
  );

  return outputUserInfo;
};

/**
 * __FamilyPortal__
 * Below function extract's necessary data
 * then updates redux store
 * then updates local storage
 */
export const switchChild = ({ childData }) => {
  return (dispatch, getState) => {
    // reset academic year

    const firstName = _.get(childData, "firstName", "");
    const lastName = _.get(childData, "lastName", "");
    const childID = _.get(childData, "id", "");
    const childProfileImage = _.get(childData, "profileImage", "");
    const jwt = _.get(childData, "jwt", "");
    const org_id = _.get(childData, "organization.id", "");
    const org_name = _.get(childData, "organization.name", "");
    const orgIsFree = _.get(childData, "organization.tier.isFree", "");
    const allCourses = _.get(childData, "allCourses", []);

    const childName = `${firstName} ${lastName}`;

    const childDataObj = {
      jwt,
      org_id,
      //TODO: remove this field when curriculum program subscription plan is enabled
      orgIsFree,
      childName,
      childID,
      childProfileImage,
      token: jwt,
    };

    // update redux store
    dispatch(
      setChildData({
        ...childDataObj,
        org_name,
        childFirstName: firstName,
      })
    );

    // manage local storage
    const storageData = getUserInfofromStorage() || {};
    const newStorageData = {
      ...storageData,
      ...childDataObj,
    };
    setUserInfoInStorage(newStorageData);
  };
};

//------BELOW CODE COULD BE USEFUL IN FUTURE------
// Below methods help to manage selectedAcademicYearId in local storage
/*
export const manageAcademicYear = {
  getFromLocalStorage: () => {
    const storageData = getUserInfofromStorage() || {};
    return _.get(storageData, "selectedAcademicYearId", "");
  },
  setInLocalStorage: academicYearId => {
    const storageData = getUserInfofromStorage() || {};
    const newStorageData = {
      ...storageData,
      selectedAcademicYearId: academicYearId,
    };
    setUserInfoInStorage(newStorageData);
  },
  resetToNullInLocalStorage: () => {
    const storageData = getUserInfofromStorage() || {};
    const newStorageData = {
      ...storageData,
      selectedAcademicYearId: null,
    };
    setUserInfoInStorage(newStorageData);
  },
};
*/

export const handleAcademicYearForParent = () => {
  return async (dispatch, getState) => {
    // reset academic year (latest academic year will be set in Platform.js)
    dispatch(
      setAcademicYearSelected({
        id: null,
        startDate: null,
        endDate: null,
        isCurrentAcademicYear: true,
        showPreviousYearMsg: true,
      })
    );
  };
};

export const setUserInfo = ({
  staffDetails,
  organization,
  settings,
  portalType,
  shouldSaveInLocalStorage,
  userCurriculumPrograms,
}) => {
  return async (dispatch, getState) => {
    const userLoggedIn =
      (getState().login.userLoggedIn || !_.isEmpty(staffDetails)) &&
      _.get(staffDetails, "id") != _.get(DUMMY_USER_INFO, "id") &&
      _.get(staffDetails, "id") != _.get(PUBLIC_USER_INFO, "id");

    let userParams = getUserDetailsForStorage({
      staffDetails,
      organization,
    });

    // __FamilyPortal__ - childID will be present only when FamilyMember is LoggedIn
    const childID = _.get(staffDetails, "childID", "");
    if (childID) {
      /** __FamilyPortal__
       * In family portal 2 jwt are stored
       * 1. parentToken: jwt of parent
       * 2. token: jwt of child
       * */
      const childName = _.get(staffDetails, "childName", "");
      const childProfileImage = _.get(staffDetails, "childProfileImage", "");
      const parentToken = _.get(staffDetails, "parentToken", null);
      userParams = {
        ...userParams,
        childID,
        childName,
        childProfileImage,
        parentToken,
      };
    }

    if (shouldSaveInLocalStorage) {
      const userStorageParams = {
        id: staffDetails.id,
        ...userParams,
        userIdentityDetails: _.map(staffDetails.userIdentityDetails, detail => {
          const { linkedUser: user, portalType } = detail || {};
          return {
            ...getUserDetailsForStorage({
              staffDetails: user,
              organization: _.get(user, "organization", {}),
            }),
            portalType,
          };
        }),
        portalType,
        enabledMixPanel: isSettingEnabled({
          settings,
          settingName: "disableMixPanel",
        }),
        useNewIdentityFlow: getUseNewIdentityFlowStatus(),
      };
      if (userLoggedIn) {
        setUserInfoInStorage(userStorageParams);
      }
    }

    const defaultSnpSetId = 2;
    const snpSetDetails = _.first(_.get(organization, "snpSet"));

    const curriculumPrograms = _.get(organization, "curriculumPrograms", []);

    dispatch(
      authUserSuccess({
        ...userParams,
        org_name: _.get(organization, "name", ""),
        //TODO: remove this field when curriculum program subscription plan is enabled
        orgPlan: _.get(organization, "tier.plan", ""),
        childFirstName: _.get(staffDetails, "childFirstName", ""),
        tags: _.get(staffDetails, "entityTags", []),
        //TODO: remove this field when curriculum program subscription plan is enabled
        orgIsFree: _.get(organization, "tier.isFree", false),
        currentSnPSet: _.get(snpSetDetails, "id", defaultSnpSetId),
        personalProjectId: _.get(organization, "personalProject.id", ""),
        communityProjectId: _.get(organization, "communityProject.id", ""),
        serviceAsActionId: _.get(organization, "serviceAsAction.id", ""),
        userCurriculumPrograms,
        settings,
        portalType,
        shouldInitThirdParty: shouldSaveInLocalStorage,
        userLoggedIn,
        curriculumPrograms,
      })
    );
  };
};

export const checkAuthentication = (params = {}) => {
  return async (dispatch, getState) => {
    const isUserLoggedIn = !isUserLoggedOut();

    if (isUserLoggedIn) {
      dispatch(setCurrentUser(params));
    } else {
      await dispatch(setLoadingState(false));
    }
  };
};

/**It will update current user according to params.
 * params contain new user's id
 */
export const setCurrentUser = (params = {}) => {
  return async dispatch => {
    const userInfo = getUserInfofromStorage();
    const currentUserId = userInfo.id;

    /**If there is no params,continue with current user authentication */
    if (_.isEmpty(params)) {
      await dispatch(_onUserAuthentication(userInfo));
    } else {
      const incomingUserId = params.id ?? "";
      /**If current user's id is same as new user's id
       * then continue with current user authentication.
       */
      if (currentUserId === incomingUserId) {
        await dispatch(_onUserAuthentication(userInfo));
      } else {
        /**If both are different, means current user has multiple identities.
         * So, authenticate via organization switcher
         */
        dispatch(
          authenticateUserWithMultipleIdentities({
            data: { id: currentUserId },
            hasUserLoggedOut: false,
            userParams: {
              userEntityType: userInfo.userEntityType,
              useNewIdentityFlow: getUseNewIdentityFlowStatus(),
            },
          })
        );
      }
    }
  };
};

export const handleApiError = ({ error }) => {
  return async (dispatch, getState) => {
    console.error(error);
    dispatch(setLoadingState(false));
    if (!error.response) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } else {
      const msg = _.get(
        error,
        "response.data.errors.0.message",
        "toastMsgs:something_went_wrong"
      );
      switch (msg) {
        case "URL_TOKEN_INVALID_OR_EXPIRED":
          dispatch(setLoginError(msg));
          break;
        default:
          dispatch(
            setToastMsg({
              msg,
              notString: true,
            })
          );
      }
    }
  };
};

export const makeApiRequest = ({ url, requestObj }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingState(true));
      return await axios.post(
        getBackendServerUrl({
          path: url,
        }),
        requestObj
      );
    } catch (error) {
      dispatch(handleApiError({ error }));
    } finally {
      dispatch(setLoadingState(false));
    }
  };
};

export const isCommunityRoute = () => {
  return _.includes(window.location.href, "/community");
};

export const isPublicPlatformRoute = () => {
  return _.includes(window.location.href, "platform/public");
};

export const getSampleOrgConfig = () => {
  return [mypConfig, ubdConfig];
};

export const getSampleOrgPortalType = ({ courseId }) => {
  const sampleUnitCourse = getSampleOrgConfig();
  if (!_.isEmpty(sampleUnitCourse)) {
    const sampleUnitPortalType = _.get(
      _.find(sampleUnitCourse, course => course.demoCourse == courseId),
      "portalType",
      null
    );
    return sampleUnitPortalType;
  }
};

export const getDesignationList = () => {
  return async (dispatch, getState) => {
    dispatch(setLoadingState(true));

    try {
      const response = await axios.get(
        getBackendServerUrl({
          path: "/auth/getDesignations",
        })
      );
      const data = _.get(response, "data", {});
      const designationList = _.map(data, designation => {
        const { id, title, description } = designation;
        return {
          ...designation,
          label: title,
          value: id,
          subText: description,
        };
      });
      dispatch(setDesignationList(designationList));
    } catch (error) {
      dispatch(handleApiError({ error }));
    } finally {
      dispatch(setLoadingState(false));
    }
  };
};

export const getOrganizationList = () => {
  return async (dispatch, getState) => {
    const {
      edges = [],
      pageInfo: { endCursor } = {},
      searchText,
      lastSearchText,
    } = _.get(getState().login, "organizationListData", {});
    dispatch(
      updateOrganizationListData({
        isLoading: true,
      })
    );

    const isLastSearchMatch = _.isEqual(lastSearchText, searchText);
    const after = isLastSearchMatch ? endCursor : "";
    try {
      const requestObj = {
        first: 10,
        after,
        searchText,
      };
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/fetchListedOrganizations",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      let newEdges = _.get(data, "edges", []);
      if (after) {
        newEdges = _.uniqBy([...edges, ...newEdges], "node.id");
      }

      dispatch(
        updateOrganizationListData({
          ...data,
          edges: newEdges,
          isLoading: false,
          lastSearchText: searchText,
        })
      );
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const verifyDomain = ({ email } = {}) => {
  return async (dispatch, getState) => {
    try {
      const { selectedSchool: { id: listedOrganizationId } = {} } = _.get(
        getState().login,
        "formData.organizationSelection",
        {}
      );

      const requestObj = {
        email,
        listedOrganizationId,
      };
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/signUp/verifyDomain",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      const oldData = _.get(getState().login, "formData.createAccount", {});

      dispatch(updateInputField("createAccount", { ...oldData, ...data }));
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const fetchInvitedUserByToken = ({ token } = {}) => {
  return async (dispatch, getState) => {
    try {
      const requestObj = {
        token,
      };
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/signUp/fetchInvitedUserByToken",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      dispatch(
        updateInputField("organizationSelection", {
          selectedSchool: {
            name: _.get(data, "organizationName", ""),
            id: _.get(data, "organizationId", ""),
          },
        })
      );
      dispatch(updateInputField("createAccount", {}));
      dispatch(updateInputField("createAccount", { ...data, token }));
      dispatch(updateCurrentScreen({ type: "createAccount" }));
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const fetchInvitedParentByToken = ({ token, inviteCode } = {}) => {
  return async (dispatch, getState) => {
    try {
      const requestObj = {
        token,
        userType: "parent",
        inviteCode,
        region: _.get(getState(), "login.authRegion", null),
      };
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/signUp/fetchInvitedUserByToken",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      if (!_.isEmpty(data.login_token)) {
        dispatch(familyInviteTokenSignIn({ login_token: data.login_token }));
      } else {
        dispatch(updateInputField("createParentAccount", {}));
        dispatch(updateInputField("createParentAccount", data));
        dispatch(updateCurrentScreen({ type: "createParentAccount" }));
      }
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const navigateToCreateParent = data => {
  return async (dispatch, _) => {
    dispatch(updateInputField("createParentAccount", {}));
    dispatch(updateInputField("createParentAccount", data));
    dispatch(updateCurrentScreen({ type: "createParentAccount" }));
  };
};

export const verifyClassInviteLinkAndNavigateToCreateParent = ({ link }) => {
  return async (dispatch, getState) => {
    const response = await dispatch(verifyClassInviteLink(link));
    const userLoggedIn = _.get(getState(), "login.userLoggedIn");
    const invitedFamiyData = _.get(getState(), "login.invitedFamilyData");

    if (response) {
      dispatch(
        updateInvitedFamilyData({ ...invitedFamiyData, verified: true })
      );
      dispatch(navigateToCreateParent({}));
    } else {
      dispatch(updateInvitedFamilyData({}));

      userLoggedIn
        ? dispatch(goToRelativeRoute({ route: "/", type: "replace" }))
        : dispatch(
            updateCurrentScreen({
              type: "loginHome",
              paramsToBeRemoved: ["metadata"],
            })
          );
    }
  };
};

export const verifyClassInviteLink = link => {
  return async (dispatch, getState) => {
    const url = "/auth/verifyClassInviteLink";

    const requestObj = {
      inviteLink: link,
      region: _.get(getState(), "login.authRegion", null),
    };

    const response = await dispatch(makeApiRequest({ url, requestObj }));
    return response;
  };
};

export const familyInviteTokenSignIn = ({
  login_token,
  isClassInvite = false,
}) => {
  return async (dispatch, getState) => {
    const res = await dispatch(
      authenticateUserWithServer({
        idToken: login_token,
        method: "url",
        userType: "parent",
      })
    );
    if (!isClassInvite) {
      dispatch(addChildFromInvitedData());
    } else {
      dispatch(updateInvitedFamilyChildData({}));
    }
  };
};

export const handleVerifyParentInviteCode = () => {
  return async (dispatch, getState) => {
    const { inviteCode } = _.get(
      getState().login,
      "formData.verifyParentInviteCode",
      {}
    );

    const requestObj = {
      inviteCode,
    };
    const url = "/auth/verifyParentInviteCode";
    const response = await dispatch(makeApiRequest({ url, requestObj }));

    const isSuccess = _.get(response, "data.isSuccess", false);
    const region = _.get(response, "data.region");

    if (isSuccess) {
      dispatch(setAuthRegion(region));
      dispatch(setParentInviteCode(inviteCode));
      dispatch(updateCurrentScreen({ type: "createParentAccount" }));
    } else {
      const msg = _.get(response, "data.message", "");
      if (msg) {
        dispatch(
          setToastMsg({
            msg,
            notString: true,
          })
        );
      }
    }
  };
};

// this function is called for normal signup flow and for class invite flow
export const handleCreateParentAccount = () => {
  return async (dispatch, getState) => {
    const { email } = _.get(
      getState().login,
      "formData.createParentAccount",
      {}
    );

    // region will be present in normal signup flow and for class invite flow
    const region = _.get(getState(), "login.authRegion", null);

    // parentInviteCode will be present if user has navigated through normal signup flow
    const parentInviteCode = _.get(getState(), "login.parentInviteCode", null);

    const requestObj = {
      email,
      userType: "parent",
    };

    if (region) {
      // if region is sent, then it will add verification code in the table(user_email_verify_code) of passed region
      requestObj["region"] = region;
    }

    if (parentInviteCode) {
      // consider scenario where parent is invited from via email
      // Now, if parent is doing normal signup flow with the email which is already invited by parent
      // In that case, below parentInviteCode will help to get the region of student. And it can be verified that parent and student are in same region or not
      requestObj["parentInviteCode"] = parentInviteCode;
    }

    const url = "/auth/v2/generateEmailVerificationCode";
    const response = await dispatch(makeApiRequest({ url, requestObj }));
    const isSuccess = _.get(response, "data.isSuccess", false);
    if (isSuccess) {
      dispatch(
        updateInputField("verifyOTP", {
          email,
          otp: "",
        })
      );
      dispatch(updateCurrentScreen({ type: "verifyOTP" }));
    } else {
      const msg = _.get(response, "data.message", "");
      dispatch(
        setToastMsg({
          msg,
          notString: true,
        })
      );
    }
  };
};

export const generateOTPForParent = ({
  showMessage = false,
  isPhone = false,
} = {}) => {
  return async (dispatch, getState) => {
    // showMessage will be true when Resend button is clicked
    // isPhone will be true, if forgot password cycle is invoked for Phone Login
    if (isPhone) {
      const { phoneNo } = _.get(
        getState().login,
        "formData.forgotPhonePassword",
        {}
      );

      dispatch(
        updateInputField("verifyPhoneOTP", {
          phoneNo,
          otp: "",
        })
      );

      const requestObj = {
        phoneNumber: `+${phoneNo}`,
        userType: "parent",
        callType: "RegeneratePassword",
      };
      const url = "/auth/v2/mobileUserOTPGenerate";
      const response = await dispatch(makeApiRequest({ url, requestObj }));
      const isSuccess = _.get(response, "data.isSuccess", false);
      if (isSuccess && showMessage) {
        dispatch(
          setToastMsg({
            msg: `toastMsgs:sent_successfully`,
            type: "tick",
          })
        );
      } else if (isSuccess) {
        dispatch(updateCurrentScreen({ type: "verifyPhoneOTP" }));
      }
    } else {
      const { email } = _.get(
        getState().login,
        "formData.createParentAccount",
        {}
      );

      const requestObj = {
        email,
        userType: "parent",
        region: _.get(getState(), "login.authRegion", null),
        parentInviteCode: _.get(getState(), "login.parentInviteCode", null),
      };

      // this api is called whenever parent clicks on Resend Code button
      const url = "/auth/v2/generateEmailVerificationCode";
      const response = await dispatch(makeApiRequest({ url, requestObj }));
      const isSuccess = _.get(response, "data.isSuccess", false);
      if (isSuccess && showMessage) {
        dispatch(
          setToastMsg({
            msg: `toastMsgs:sent_successfully`,
            type: "tick",
          })
        );
      }
    }
  };
};

export const verifyParentOTP = ({ isPhone = false } = {}) => {
  return async (dispatch, getState) => {
    // isPhone will be true, if forgot password cycle is invoked for Phone Login
    if (isPhone) {
      const { phoneNo, otp } = _.get(
        getState().login,
        "formData.verifyPhoneOTP",
        {}
      );

      const requestObj = {
        phoneNumber: `+${phoneNo}`,
        OTP: otp,
      };
      const url = "/auth/v2/mobileUserOTPAuthenticate";
      const response = await dispatch(makeApiRequest({ url, requestObj }));
      const isSuccess = _.get(response, "data", false);
      if (isSuccess) {
        dispatch(updateCurrentScreen({ type: "resetPassword" }));
        dispatch(
          updateInputField("resetPassword", {
            phoneNo,
            otp,
          })
        );
      }
    } else {
      const { email } = _.get(
        getState().login,
        "formData.createParentAccount",
        {}
      );
      const { otp } = _.get(getState().login, "formData.verifyOTP", {});

      const requestObj = {
        email,
        userType: "parent",
        code: otp,
        region: _.get(getState(), "login.authRegion", null),
      };
      const url = "/auth/v2/verifyEmailCode";
      const response = await dispatch(makeApiRequest({ url, requestObj }));
      const isSuccess = _.get(response, "data.isSuccess", false);
      if (isSuccess) {
        dispatch(handleParentSignUp());
      } else {
        dispatch(setToastMsg("toastMsgs:invalid_otp"));
      }
    }
  };
};

export const handleParentSignUp = () => {
  return async (dispatch, getState) => {
    const { firstName, lastName, email, password } = _.get(
      getState().login,
      "formData.createParentAccount",
      {}
    );

    // region will be present if user has navigated through class invite link
    const region = _.get(getState(), "login.authRegion", null);

    // parentInviteCode will be present if user has navigated through normal signup flow
    const parentInviteCode = _.get(getState(), "login.parentInviteCode", null);

    const requestObj = {
      userDetails: {
        firstName,
        lastName,
        email,
        password,
      },
      userType: "parent",
      method: "local",
    };

    if (region) {
      // if region is sent then it will create parent account in that region
      requestObj["region"] = region;
    }

    if (parentInviteCode) {
      // if student's parentInviteCode is sent in api, then it will add student to the parent
      requestObj["parentInviteCode"] = parentInviteCode;
    }

    const url = "/auth/v2/signUp";
    const response = await dispatch(makeApiRequest({ url, requestObj }));
    if (response?.status == "200") {
      dispatch(validateUser({ ...response.data, identityUserType: "parent" }));
    } else {
      dispatch(setToastMsg("toastMsgs:invalid_otp"));
    }
  };
};

export const generateOTPForUnauthenticatedUser = ({
  showMessage = false,
} = {}) => {
  return async (dispatch, getState) => {
    const { id } = _.get(getState().login, "formData.verifyOTP", {});
    try {
      const requestObj = {
        id,
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/signUp/generateOTPForUnauthenticatedUser",
        }),
        requestObj
      );
      const isSuccess = _.get(response, "data", false);
      dispatch(setLoadingState(false));
      if (isSuccess && showMessage) {
        dispatch(
          setToastMsg({
            msg: `toastMsgs:sent_successfully`,
            type: "tick",
          })
        );
      }
      console.error(response);
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const verifyUnauthenticatedUserOTP = () => {
  return async (dispatch, getState) => {
    const { id, otp, pypCoordinatorId } = _.get(
      getState().login,
      "formData.verifyOTP",
      {}
    );
    const { weburl = "", url = "" } = _.get(
      getState().login,
      "formData.addIdentification",
      {}
    );

    try {
      const requestObj = {
        id,
        otp,
        weburl,
        url,
        pypCoordinatorId,
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/signUp/verifyUnauthenticatedUserOTP",
        }),
        requestObj
      );
      dispatch(setLoadingState(false));
      const data = _.get(response, "data", {});

      if (_.get(data, "data.isSuccess", false)) {
        const { email: userEmail, firstName } = _.get(
          getState().login,
          "formData.createAccount",
          {}
        );
        EventTracker.aliasUser({ identityString: userEmail });

        const state = _.get(data, "data.state", false);
        const isDomainValid = _.get(data, "data.isDomainValid", false);
        if (state == "REQ_ACC_NO_ORG" || state == "REQ_ACC_HS_ORG") {
          dispatch(
            updateInputField("requestSuccess", {
              status: state,
              isDomainValid,
              firstName,
            })
          );
          dispatch(updateCurrentScreen({ type: "requestSuccess" }));
        } else if (state == "USR_CRTD") {
          dispatch(
            validateUser({ ...response.data, identityUserType: "staff" })
          );
        } else {
          dispatch(setToastMsg("toastMsgs:something_went_wrong"));
        }
      } else {
        dispatch(setToastMsg("toastMsgs:invalid_otp"));
      }
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const createPYPCoordinator = () => {
  return async (dispatch, getState) => {
    const { id, email, firstName, lastName } = _.get(
      getState().login,
      "formData.addPYPDetails",
      {}
    );
    const { selectedSchool: { id: listedOrganizationId } = {} } = _.get(
      getState().login,
      "formData.organizationSelection",
      {}
    );
    try {
      const requestObj = {
        id,
        listedOrganizationId,
        email,
        firstName,
        lastName,
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/signUp/createPYPCoordinator",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      dispatch(
        updateInputField("verifyOTP", {
          pypCoordinatorId: data.id,
          otp: "",
        })
      );

      dispatch(updateCurrentScreen({ type: "verifyOTP" }));
      dispatch(setLoadingState(false));
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const createInvitedUser = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedSchool: { id: listedOrganizationId } = {} } = _.get(
        getState().login,
        "formData.organizationSelection",
        {}
      );
      const {
        firstName,
        lastName,
        email,
        role,
        designation,
        password,
        stateCode,
        token,
      } = _.get(getState().login, "formData.createAccount", {});
      //console.log(stateCode);
      const requestObj = {
        firstName,
        lastName,
        email,
        role,
        designation,
        password,
        organizationId: listedOrganizationId,
        token,
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/signUp/createInvitedUser",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      if (_.get(data, "data.isSuccess", false)) {
        EventTracker.aliasUser({ identityString: email });
        dispatch(validateUser({ ...response.data, identityUserType: "staff" }));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      dispatch(setLoadingState(false));
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const createInvitedParent = () => {
  return async (dispatch, getState) => {
    try {
      const loginState = getState().login;
      const {
        firstName,
        lastName,
        email,
        role,
        designation,
        password,
        userId,
      } = _.get(loginState, "formData.createParentAccount", {});
      const requestObj = {
        firstName,
        lastName,
        email,
        role,
        designation,
        password,
        token: userId,
        region: _.get(getState(), "login.authRegion", null),
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/signUp/createInvitedParent",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});
      if (_.get(data, "data.isSuccess", false)) {
        EventTracker.aliasUser({ identityString: email });
        dispatch(
          validateUser({ ...response.data, identityUserType: "parent" })
        );
        dispatch(addChildFromInvitedData());
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      dispatch(setLoadingState(false));
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const addChildFromInvitedData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const invitedFamilyData = state.login.invitedFamilyData;
    const familyInviteCode = _.get(
      invitedFamilyData,
      "metadata.inviteCode",
      ""
    );
    const userId = _.get(state.login, "userInfo.id");
    if (_.isEmpty(invitedFamilyData) || !userId || !familyInviteCode) {
      return;
    }

    const childData = await dispatch(
      addChild({
        studentCode: familyInviteCode,
        userId,
        signInFlow: false,
      })
    );

    dispatch(updateInvitedFamilyData({}));
    dispatch(updateInvitedFamilyChildData(childData));
  };
};

export const checkIsPYPCoordinator = ({ role, designation }) => {
  return designation.toString() == "2";
};

export const createUnauthenticatedUser = () => {
  return async (dispatch, getState) => {
    try {
      const { selectedSchool: { id: listedOrganizationId } = {} } = _.get(
        getState().login,
        "formData.organizationSelection",
        {}
      );
      const {
        firstName,
        lastName,
        email,
        role,
        password,
        stateCode,
        designation,
      } = _.get(getState().login, "formData.createAccount", {});

      const requestObj = {
        firstName,
        lastName,
        email,
        role,
        designation,
        password,
        listedOrganizationId,
      };

      const query = querystringify.parse(_.get(window, "location.search", ""));
      if (query.utm_token) requestObj.utmToken = query.utm_token;
      if (query.utm_source) requestObj.platform = query.utm_source;

      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/signUp/createUnauthenticatedUser",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});

      dispatch(setLoadingState(false));

      dispatch(
        updateInputField("verifyOTP", {
          email,
          id: data.id,
          otp: "",
          stateCode,
        })
      );
      dispatch(updateInputField("addPYPDetails", {}));
      dispatch(updateInputField("addPYPDetails", { id: data.id }));

      if (stateCode == "SUP_DOM_MIS_MTCH_NO_ORG") {
        dispatch(updateInputField("addIdentification", { role, designation }));
        dispatch(updateCurrentScreen({ type: "addIdentification" }));
      } else if (
        !data.isFirstUser ||
        checkIsPYPCoordinator({ role, designation })
      ) {
        dispatch(updateCurrentScreen({ type: "verifyOTP" }));
      } else {
        dispatch(updateCurrentScreen({ type: "addPYPDetails" }));
      }
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const signInUsingCode = ({ code, codeType = "CODE" }) => {
  return async (dispatch, getState) => {
    try {
      const requestObj = {
        classCode: code,
        codeType,
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/student/checkClassCode",
        }),
        requestObj
      );
      const data = _.get(response, "data", {});

      if (_.get(data, "isStudentSignIn", false)) {
        dispatch(validateUser({ ...data, identityUserType: "student" }));
      } else {
        dispatch(
          setToastMsg({
            msg: "Please enter student sign in code",
            notString: true,
          })
        );
      }
    } catch (error) {
      dispatch(handleApiError({ error }));
    } finally {
      dispatch(setLoadingState(false));
    }
  };
};

export const createSchoolRequest = () => {
  return async (dispatch, getState) => {
    try {
      const {
        name,
        type,
        ibCode,

        firstName,
        lastName,
        email,
        role,
        weburl,
        url,
        designation,
      } = _.get(getState().login, "formData.addSchool", {});

      const requestObj = {
        name,
        type,
        ibCode,
        firstName,
        lastName,
        email,
        role,
        designation,
        weburl,
        url,
      };
      dispatch(setLoadingState(true));
      const response = await axios.post(
        getBackendServerUrl({
          path: "/auth/signUp/createSchoolRequest",
        }),
        requestObj
      );
      dispatch(setLoadingState(false));
      const isSuccess = _.get(response, "data", false);
      if (isSuccess) {
        dispatch(updateInputField("addSchool", {}));
        dispatch(
          updateInputField("requestSuccess", {
            status: "SCHOOL_ADDED",
            firstName,
          })
        );
        dispatch(updateCurrentScreen({ type: "requestSuccess" }));
        EventTracker.aliasUser({ identityString: email });
      }
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const sendForgotPassword = params => {
  return async (dispatch, getState) => {
    dispatch(setLoadingState(true));
    const { successCallBack, userType = "staff" } = params || {};
    const { email } = _.get(getState().login, "formData.forgotPassword", {});
    try {
      const requestObj = {
        email: email,
        userType,
      };
      const req = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/forgotPassword",
        }),
        requestObj
      );

      dispatch(setLoadingState(false));
      if (successCallBack) {
        successCallBack();
      }
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const sendResetPassword = params => {
  return async (dispatch, getState) => {
    const { successCallBack, userType = "staff" } = params || {};
    const { phoneNo = "" } = _.get(
      getState().login,
      "formData.resetPassword",
      {}
    );

    if (userType === "parent" && phoneNo) {
      const { password, phoneNo, otp, identityId } = _.get(
        getState().login,
        "formData.resetPassword",
        {}
      );

      const requestObj = {
        phoneNumber: `+${phoneNo}`,
        OTP: otp,
        password,
        userType,
        identityId,
      };
      const url = "/auth/v2/changePassword";
      const response = await dispatch(makeApiRequest({ url, requestObj }));
      const isSuccess = _.get(response, "data.isSuccess", false);
      if (isSuccess) {
        dispatch(
          updateInputField("parentLoginForm", {
            phoneNo,
            phonePassword: password,
          })
        );
        dispatch(
          authenticateUserWithServer({
            method: "local",
            userType,
            isPhoneSignIn: true,
          })
        );
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    } else {
      let formDataKey;

      if (getState().login.currentScreen === "visitorSignup") {
        formDataKey = "visitorSignup";
      } else {
        formDataKey = "resetPassword";
      }

      const { password, id, token, identityId } = _.get(
        getState(),
        `login.formData.${formDataKey}`,
        {}
      );

      try {
        const requestObj = {
          id: id,
          identityId,
          token: token,
          password: password,
          userType,
          isCheckForSuperAdmin: true,
        };
        dispatch(setLoadingState(true));
        const req = await axios.post(
          getBackendServerUrl({
            path: "/auth/v2/resetPassword",
          }),
          requestObj
        );

        await dispatch(
          validateUser({ ...req.data, identityUserType: userType })
        );
        dispatch(setLoadingState(false));
        if (successCallBack) {
          successCallBack();
        }
      } catch (error) {
        dispatch(handleApiError({ error }));
      }
    }
  };
};

/**It will check whether user account exists
 * or user has reset password or not*/
export const isUserAccountExists = async ({ email, region }) => {
  try {
    const requestObj = {
      email,
      userSource: "VISITOR_INVITE_EMAIL",
      region,
    };

    const response = await axios.post(
      getBackendServerUrl({
        path: "/auth/v2/signUp/verifyDomain",
      }),
      requestObj
    );
    const data = _.get(response, "data", {});
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const updateIdentityScreenFilters = ({
  data,
  fetchIdentityUsingRestApi,
}) => {
  return async dispatch => {
    dispatch(setIdentityScreenFilters(data));

    if (fetchIdentityUsingRestApi) {
      dispatch(fetchIdentitiesOnChangeFilters());
    }
  };
};

export const fetchMoreIdentities = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const searchText = _.get(
      state,
      "login.identityScreenFilters.searchText",
      ""
    );

    const { pageInfo, edges: oldEdges } = _.get(
      state,
      "login.identityPoolDetails",
      {}
    );

    const { hasNextPage, endCursor } = pageInfo;

    if (!hasNextPage) {
      return;
    }

    const filters = { first: 20, searchText, after: endCursor };

    dispatch(
      setIdentityPoolDetails({
        pageInfo,
        edges: oldEdges,
        isFetchingMoreData: true,
      })
    );

    const { edges = [], pageInfo: updatedPageInfo = {} } = await dispatch(
      fetchUserIdentities({ filters })
    );

    const updatedEdges = [...oldEdges, ...edges];

    const updatedData = {
      edges: updatedEdges,
      pageInfo: updatedPageInfo,
      isFetchingMoreData: false,
    };

    dispatch(setIdentityPoolDetails(updatedData));
  };
};

export const getUserDetailsFromIdentity = ({
  identityId,
  id: userId,
  userType,
}) => {
  return async dispatch => {
    const { token } = getUserInfofromStorage();

    const requestPayload = { identityId, userId, token, userType };

    const response = await dispatch(
      makeApiRequest({
        url: "/auth/identity/user/get",
        requestObj: requestPayload,
      })
    );

    return response.data;
  };
};

/**It will authenticate users which are  part of
 * more than one organization
 */
export const authenticateUserWithMultipleIdentities = ({
  userParams,
  data,
  hasUserLoggedOut = true,
}) => {
  return async dispatch => {
    /**If user is LoggedIn while authenticating, we don't need
     * to set current user's jwt,id,usertype in localStorage.
     * This data is used to query user's linked identities.
     */
    if (hasUserLoggedOut) {
      /**isIdentitySelected is used to track whether user has chosen
       * any identity after opening organization switcher.It will be removed
       * after authentication.
       */
      const updatedUserParams = { ...userParams, isIdentitySelected: false };
      setUserInfoInStorage(updatedUserParams);
    }

    dispatch(setLoadingState(false));

    dispatch(
      updateOrganizationSwitcherDetails({
        isVisible: true,
        userId: data.id,
        userEntityType: userParams.userEntityType,
      })
    );
  };
};

/**
 * This util will decide next authentication flow after Organization is selected based on following conditions:
 * A. If user is logged in and opens select organization screen -> onSwitchToOrganizationLocally
 * B. If new identity flow is enabled -> setUserFromIdentityPoolUserList
 * C. If old identity flow is enabled -> setLinkedIdentity
 */
export const onSelectOrganization = params => {
  return async dispatch => {
    const useNewIdentityFlow = getUseNewIdentityFlowStatus();

    /**
     * Note: Can't use redux state for login as, sometimes this util will be called
     * before redux state for login is set.
     */
    const isUserLoggedIn = !isUserLoggedOut();

    if (isUserLoggedIn) {
      dispatch(onSwitchToOrganizationLocally(params));
    } else if (useNewIdentityFlow) {
      dispatch(setUserFromIdentityPoolUserList(params));
    } else {
      dispatch(setLinkedIdentity(params));
    }
  };
};

/**It will update jwt,userId,usertype according to
 * chosen identity from organization switcher,
 */
export const setLinkedIdentity = params => {
  return async dispatch => {
    try {
      dispatch(setLoadingState(true));

      const { id, jwt, type } = params;
      const data = {
        id,
        token: jwt,
        user_type: type,
        userEntityType: _.get(USER_TYPE_ENTITY_MAPPING[type], "entityType", ""),
        useNewIdentityFlow: false,
      };

      setUserInfoInStorage(data);

      dispatch(
        updateOrganizationSwitcherDetails({
          isVisible: false,
          userId: "",
          userEntityType: "",
        })
      );

      await dispatch(_onUserAuthentication(data));
    } catch (e) {
      console.error(e);
    }
  };
};

/**
 * This function will fetch user details based on selected identity, and updates redux and
 * localStorage. From here, user is considered as logged in
 */

export const setUserFromIdentityPoolUserList = params => {
  return async dispatch => {
    const userDetails = await dispatch(getUserDetailsFromIdentity(params));

    dispatch(setLoadingState(true));

    if (_.isEmpty(userDetails)) {
      return;
    }

    const { token, data } = userDetails;

    const { id, user_type: userType } = data;

    const userParams = {
      token,
      id: _.toString(id),
      user_type: userType,
      userEntityType: _.get(
        USER_TYPE_ENTITY_MAPPING[userType],
        "entityType",
        ""
      ),
      useNewIdentityFlow: true,
    };

    setUserInfoInStorage(userParams);

    dispatch(
      updateOrganizationSwitcherDetails({
        isVisible: false,
      })
    );

    dispatch(setIdentityScreenFilters({}));

    dispatch(setIdentityPoolDetails(null));

    await dispatch(_onUserAuthentication(data));
  };
};

export const fetchUserIdentities = ({ filters }) => {
  return async dispatch => {
    const {
      id: identityId,
      token,
      identityUserType,
    } = getUserInfofromStorage();

    /**
     * Can't use makeApiRequest as we don't need to change loading status
     */

    try {
      const response = await axios.post(
        getBackendServerUrl({ path: "/auth/identity/user/list" }),
        {
          identityId,
          token,
          userType: identityUserType,
          filters,
        }
      );

      return response?.data ?? {};
    } catch (e) {
      dispatch(handleApiError({ error: e }));
      return {};
    }
  };
};

export const fetchIdentitiesOnChangeFilters = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const identityPoolDetails = _.get(state, "login.identityPoolDetails", {});

    dispatch(
      setIdentityPoolDetails({ ...identityPoolDetails, isLoading: true })
    );

    const searchText = _.get(
      state,
      "login.identityScreenFilters.searchText",
      ""
    );

    const { edges, pageInfo } = await dispatch(
      fetchUserIdentities({ filters: { first: 20, searchText } })
    );

    const updatedState = getState();

    const updatedSearchText = _.get(
      updatedState,
      "login.identityScreenFilters.searchText",
      ""
    );

    /**
     * To avoid race condition, we compare latest search Text with search Text before request.
     * Here, during an ongoing request, if user triggers another request, search text will be different in redux.
     * Request for which old and new searchText is same, corresponding data will be added in redux.
     */
    if (searchText === updatedSearchText) {
      const data = { edges, pageInfo, isLoading: false };
      dispatch(setIdentityPoolDetails(data));
    }
  };
};

const authenticateUserWithIdentityPool = ({ userParams, data }) => {
  return async dispatch => {
    setUserInfoInStorage({ ...userParams, isIdentitySelected: false });

    const { edges, pageInfo } = await dispatch(
      fetchUserIdentities({ filters: { first: 20 } })
    );

    const updatedData = { ...data, edges, pageInfo };

    const userEdges = _.get(updatedData, "edges", []);

    const totalIdentities = _.size(userEdges);

    if (totalIdentities === 1) {
      const { identity_id: identityId, user_id, user_type: userType } = _.get(
        _.first(userEdges),
        "node",
        {}
      );

      dispatch(
        setUserFromIdentityPoolUserList({ identityId, userType, id: user_id })
      );

      return;
    }

    dispatch(setIdentityPoolDetails(updatedData));
    dispatch(
      updateOrganizationSwitcherDetails({
        isVisible: true,
      })
    );
    dispatch(setLoadingState(false));
  };
};

export const validateUser = params => {
  return async dispatch => {
    const { data, token, identityUserType } = params;

    const {
      user_type: userType,
      identity_id: identityId,
      flow: identityFlow,
    } = data;

    const userParams = {
      token,
      id: _.toString(data.id || data.identity_id),
      user_type: params.data.user_type,
      userEntityType: _.get(
        USER_TYPE_ENTITY_MAPPING[userType],
        "entityType",
        ""
      ),
      identityUserType,
    };

    switch (userType) {
      case "parent": {
        /** __FamilyPortal__
         * In family portal 2 jwt are stored
         * 1. parentToken: jwt of parent
         * 2. token: jwt of child (will be available after querying "getPlatformUserDetailsQuery" in ChildrenPage.js)
         * Note: Because of below condition parentToken will be stored in local storage for user_type = parent
         * */

        const updatedUserParams = {
          ...userParams,
          parentToken: token,
        };
        setUserInfoInStorage(updatedUserParams);
        await dispatch(_onUserAuthentication(data));
        return;
      }

      case "super_admin": {
        await dispatch(
          _onSAUserAuthentication({ token: userParams.token, ...data })
        );
        return;
      }

      case "identity_user": {
        const updatedUserParams = { ...userParams, useNewIdentityFlow: true };
        await dispatch(
          authenticateUserWithIdentityPool({
            userParams: updatedUserParams,
            data,
          })
        );
        break;
      }

      default: {
        if (identityId && identityFlow === "identityV1") {
          const updatedParams = { ...userParams, useNewIdentityFlow: false };
          dispatch(
            authenticateUserWithMultipleIdentities({
              userParams: updatedParams,
              data,
            })
          );
        } else {
          setUserInfoInStorage(userParams);
          await dispatch(_onUserAuthentication(data));
        }
      }
    }
  };
};

export const authenticateUserWithServer = params => {
  return async (dispatch, getState) => {
    await dispatch(setLoadingState(true));
    const {
      idToken,
      source,
      method = "google",
      userType = "staff",
      isPhoneSignIn = false,
      redirectUri = "",
    } = params;

    let requestObj = {
      method: method,
      userType,
      //userType: "staff",
      isCheckForSuperAdmin: true,
      redirectUri,
    };

    if (
      method == "google" ||
      method == "microsoft" ||
      method == "url" ||
      method === "clever" ||
      method === "lex" ||
      method === "classlink" ||
      method === "irmak"
    ) {
      requestObj = {
        ...requestObj,
        idToken,
      };

      //condition for irmak
      if (method === "irmak") {
        requestObj = {
          ...requestObj,
          source,
        };
      }

      // TODO:- Temporarily we are not allowing forced signup for parent, we will allow this in near future once backend is compatible
      // if (userType === "parent") {
      //   requestObj = {
      //     ...requestObj,
      //     isForcedSignUp: true,
      //   };
      // }
    } else if (method === "local" && isPhoneSignIn) {
      const phoneNo = _.get(
        getState().login,
        "formData.parentLoginForm.phoneNo",
        ""
      );
      const phonePassword = _.get(
        getState().login,
        "formData.parentLoginForm.phonePassword",
        ""
      );

      requestObj = {
        ...requestObj,
        phoneNumber: `+${phoneNo}`,
        password: phonePassword,
      };
    } else if (method == "local") {
      let email, password;
      if (userType === "parent") {
        email = _.get(getState().login, "formData.parentLoginForm.email", "");
        password = _.get(
          getState().login,
          "formData.parentLoginForm.password",
          ""
        );
      } else {
        email = _.get(getState().login, "formData.loginForm.email", "");
        password = _.get(getState().login, "formData.loginForm.password", "");
      }

      requestObj = {
        ...requestObj,
        email: email,
        password: password,
      };
    }

    try {
      const req = await axios.post(
        getBackendServerUrl({
          path: "/auth/v2/signIn",
        }),
        requestObj
      );

      const responseData = _.get(req, "data.data", {});

      const { is_password_updated = false, user_type: userType } = responseData;

      if (
        !_.includes(["super_admin"], userType) &&
        !is_password_updated &&
        method == "local"
      ) {
        dispatch(setLoadingState(false));
        dispatch(updateCurrentScreen({ type: "resetPassword" }));
        dispatch(updateInputField("resetPassword", {}));
        dispatch(
          updateInputField("resetPassword", {
            id: req.data.data.id,
            identityId: req.data.data.identity_id,
            token: req.data.token,
          })
        );

        return false;
      }

      dispatch(
        validateUser({ ...req.data, identityUserType: requestObj.userType })
      );
    } catch (error) {
      dispatch(handleApiError({ error }));
    }
  };
};

export const onSwitchToUserFromSA = params => {
  return async (dispatch, getState) => {
    await dispatch(setLoadingState(true));
    try {
      const token = getState().login.sa.token;
      const userId = params.userId;
      const req = await axios.post(
        getBackendServerUrl({
          token,
          path: "/auth/super_admin/get_user",
        }),
        { token, userId }
      );
      const userParams = {
        token: req.data.token,
        id: _.toString(req.data.data.id),
        user_type: req.data.data.user_type,
        userEntityType: _.get(
          USER_TYPE_ENTITY_MAPPING[req.data.data.user_type],
          "entityType",
          ""
        ),
      };
      await dispatch({ type: REMOVE_SUPERADMIN_AUTH });
      setUserInfoInStorage(userParams);
      await dispatch(_onUserAuthentication(req.data.data));
    } catch (error) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      dispatch(setLoadingState(false));
    }
  };
};

export const onSwitchToOrganization = params => {
  return async (dispatch, getState) => {
    dispatch(setLoadingState(false));
    const state = getState();
    try {
      const portalType = _.get(state, "login.portalType", null);
      const userInfo = getUserInfo({ portalType });
      const token = userInfo.token;
      const organizationId = params.organizationId;
      const req = await axios.post(
        getBackendServerUrl({
          token,
          path: "/auth/super_admin/get_user",
        }),
        { token, organizationId }
      );

      const userParams = {
        token: req.data.token,
        id: _.toString(req.data.data.id),
        user_type: req.data.data.user_type,
        userEntityType: _.get(
          USER_TYPE_ENTITY_MAPPING[req.data.data.user_type],
          "entityType",
          ""
        ),
        useNewIdentityFlow: getUseNewIdentityFlowStatus(),
      };

      setUserInfoInStorage(userParams);
      window.location.href = "/";
    } catch (error) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(setLoadingState(false));
    }
  };
};

export const onSwitchToOrganizationLocally = params => {
  return async dispatch => {
    const { id, jwt, type } = params;

    const data = {
      id,
      token: jwt,
      user_type: type,
      userEntityType: _.get(USER_TYPE_ENTITY_MAPPING[type], "entityType", ""),
      useNewIdentityFlow: getUseNewIdentityFlowStatus(),
    };

    try {
      setUserInfoInStorage(data);
      window.location.href = "/";
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};
const _onSAUserAuthentication = data => {
  return async (dispatch, getState) => {
    await dispatch(authSuperAdminSuccess(data));
    await dispatch(setLoadingState(false));
  };
};

const _onUserAuthentication = data => {
  return async (dispatch, getState) => {
    await dispatch(
      authUserSuccessAction({ ...(data || {}), userLoggedIn: true })
    );

    await dispatch(_fetchData());

    await dispatch(setLoadingState(false));
    await dispatch(_doTransition());
  };
};

export const _fetchData = () => {
  return async (dispatch, getState) => {
    const user_type = getState().login.userInfo.user_type;
    if (user_type !== "parent") {
      await dispatch(_getUserPermissions());
    }
  };
};

const _doTransition = () => {
  return (dispatch, getState) => {
    const currentPath = _.get(
      getState().router,
      "locationBeforeTransitions.pathname",
      ""
    );

    const query = _.get(
      getState().router,
      "locationBeforeTransitions.query",
      {}
    );

    const { metadata, user_id: userId = "" } = query;

    const userData = getUserInfofromStorage();

    /**params are valid if they do not contain user id or user id is same current logged in user's id.
     * Second scenario will occur when user is part of multiple organizations.
     */
    const isValidUser = !userId || userId === userData.id;

    let metadataObj = {};
    try {
      metadataObj = metadata ? JSON.parse(metadata) : {};
    } catch {}
    const redirectUrl = _.get(query, "redirect_url");

    if (redirectUrl) {
      dispatch(goToRelativeRoute({ route: redirectUrl }));
    } else if (
      !_.isEmpty(query) &&
      metadataObj.authenticatedRoute &&
      isValidUser
    ) {
      dispatch(handleQueryParameters({ query }));
    } else if (currentPath == "/") {
      //check for family invite code, if present preserve it
      const familyInviteCode = _.get(metadataObj, "inviteCode");
      if (!_.isEmpty(familyInviteCode)) {
        dispatch(
          goToRelativeRoute({
            route: `platform?preserve_query_params=true&&config=${JSON.stringify(
              { metadata: metadataObj }
            )}`,
            queryParamsToPreserve: ["preserve_query_params", "config"],
          })
        );
      } else {
        dispatch(goToRelativeRoute({ route: "platform" }));
      }
    } else if (_.includes(currentPath, "/sso")) {
      dispatch(goToRelativeRoute({ route: "../../" }));
    }
  };
};

export const _getUserPermissions = () => {
  return async (dispatch, getState) => {
    try {
      const portalType = "PLANNER";
      const userInfo = getUserInfo({ portalType });
      const { data } = await client.query({
        query: getPermissionsQuery,
        variables: {
          userId: userInfo.id,
          groups: getState().login.permissionGroups,
          portalType,
        },
      });
      ACLStore.update(data.platform.permissions);
    } catch (e) {
      console.error(e);
    }
  };
};

// Reducers
const REDUCER_HANDLERS = {
  [AUTH_SUPERADMIN_SUCCESS]: (state, action) => {
    const data = action.payload;
    return update(state, {
      sa: {
        loggedIn: { $set: true },
        token: { $set: data.token },
        orgList: { $set: data.org_list },
      },
    });
  },
  [REMOVE_SUPERADMIN_AUTH]: (state, action) => {
    return update(state, {
      sa: {
        loggedIn: { $set: false },
        token: { $set: null },
        orgList: { $set: [] },
      },
    });
  },
  [AUTH_USER_SUCCESS]: (state, action) => {
    const inData = action.payload;
    Object.keys(inData).map((key, index) => {
      const data = inData[key];

      state = update(state, {
        userInfo: { [key]: { $set: data } },
        userLoggedIn: { $set: _.get(inData, "userLoggedIn", false) },
      });
    });
    return state;
  },
  [LOGOUT_USER_SUCCESS]: (state, action) => {
    const newState = Object.assign({}, state, {
      userLoggedIn: false,
    });
    return newState;
  },
  [SET_LOADING_STATE]: (state, action) => {
    return update(state, { isLoading: { $set: action.payload } });
  },
  [SET_TOAST_MSG]: (state, action) => {
    state = update(state, {
      toast: { $set: initialState.toast },
    });
    if (action.payload !== null && typeof action.payload === "object") {
      Object.keys(action.payload).map((key, index) => {
        state = update(state, {
          toast: { [key]: { $set: action.payload[key] } },
        });
      });
    } else {
      state = update(state, {
        toast: { msg: { $set: action.payload } },
      });
    }
    state = update(state, {
      toast: { showToast: { $set: true } },
    });
    return state;
  },
  [SET_ACTIVE_TAB]: (state, action) => {
    //  console.log(action.payload);
    return Object.assign({}, state, { activeTab: action.payload });
  },
  [SET_DESIGNATION_LIST]: (state, action) => {
    return Object.assign({}, state, { designationList: action.payload });
  },
  [CLEAR_TOAST_MSG]: (state, action) => {
    return update(state, { toast: { showToast: { $set: false } } });
  },

  [UPDATE_INPUT_FIELD]: (state, action) => {
    const { screenName, data } = action.payload;

    const keys = Object.keys(data);
    if (!_.get(state.formData, screenName, "") || _.isEmpty(data)) {
      state = update(state, { formData: { [screenName]: { $set: {} } } });
    }
    _.forEach(keys, key => {
      state = update(state, {
        formData: {
          [screenName]: { [key]: { $set: data[key] } },
        },
      });
    });

    return state;
  },
  [SET_CURRENT_SCREEN]: (state, action) => {
    return update(state, { currentScreen: { $set: action.data } });
  },

  [INVITE_TO_COMMUNITY_STATUS]: (state, action) => {
    return update(state, { inviteToCommunityModal: { $set: action.data } });
  },

  [SET_HELP_CENTER_VIDEO_DIRECT_LINK]: (state, action) => {
    return update(state, { helpCenterVideoModalId: { $set: action.data } });
  },

  [RETENTION_MODAL_STATUS]: (state, action) => {
    return update(state, { retentionModalStatus: { $set: action.data } });
  },

  [BIFURCATION_MODAL_STATUS]: (state, action) => {
    return update(state, { bifurcationModalStatus: { $set: action.data } });
  },

  [INIT_ORGANIZATION_FILTERS]: (state, action) => {
    return update(state, {
      organizationFilters: { $set: initialState.organizationFilters },
    });
  },
  [UPDATE_ORGANIZATION_FILTERS]: (state, action) => {
    const { key, value } = action.data;
    return update(state, {
      organizationFilters: { [key]: { $set: value } },
    });
  },
  [UPDATE_ORGANIZATION_LIST_DATA]: (state, action) => {
    const inData = action.payload;
    _.map(Object.keys(inData), key => {
      state = update(state, {
        organizationListData: { [key]: { $set: inData[key] } },
      });
    });
    return state;
  },
  [SET_LOGIN_ERROR]: (state, action) => {
    return update(state, {
      loginError: { $set: action.payload },
    });
  },
  [SET_BIFURCATION_ACTION]: (state, action) => {
    return update(state, { bifurcationAction: { $set: action.payload } });
  },
  [SET_REDIRECT_URL]: (state, action) => {
    return update(state, { redirectUrl: { $set: action.payload } });
  },
  [SET_IS_USER_FROM_PUBLIC_ACCESS]: (state, action) => {
    return update(state, {
      isUserComingFromPublicAccess: { $set: action.payload },
    });
  },
  [SET_LAST_CHILD_STATUS]: (state, action) => {
    return update(state, {
      isLastChildRemoved: { $set: action.payload },
    });
  },
  [SET_CHILD_STATUS]: (state, action) => {
    return update(state, {
      isChildPresent: { $set: action.payload },
    });
  },
  [SET_PRODUCT_UPDATE_DIRECT_LINK]: (state, action) => {
    return update(state, { productUpdateModalId: { $set: action.payload } });
  },
  [SET_PRODUCT_UPDATE_MODAL_OPEN]: (state, action) => {
    return update(state, {
      shouldProductUpdatesModalOpen: { $set: action.payload },
    });
  },
  [SET_CHILD_DATA]: (state, action) => {
    const inData = action.payload;
    Object.keys(inData).map((key, index) => {
      const data = inData[key];

      state = update(state, {
        userInfo: { [key]: { $set: data } },
      });
    });
    return state;
  },
  [SET_PARENT_LOGIN_TAB]: (state, action) => {
    return update(state, {
      currentParentLoginTab: { $set: action.payload },
    });
  },
  [SET_AUTH_REGION]: (state, action) => {
    return update(state, {
      authRegion: { $set: action.payload },
    });
  },
  [SET_PARENT_INVITE_CODE]: (state, action) => {
    return update(state, {
      parentInviteCode: { $set: action.payload },
    });
  },
  [UPDATE_USER_INFO]: (state, action) => {
    const data = action.payload;
    Object.keys(data).map((key, index) => {
      state = update(state, {
        userInfo: { [key]: { $set: data[key] } },
      });
    });
    return state;
  },
  [UPDATE_ORGANIZATION_SWITCHER_DETAILS]: (state, action) => {
    const data = action.payload;
    return update(state, {
      organizationSwitcherDetails: { $set: data },
    });
  },
  [UPDATE_INVITED_FAMILY_DATA]: (state, action) => {
    return update(state, {
      invitedFamilyData: { $set: action.payload },
    });
  },
  [UPDATE_INVITED_FAMILY_CHILD_DATA]: (state, action) => {
    return update(state, {
      invitedFamilyChildData: { $set: action.payload },
    });
  },
  [SET_IDENTITY_POOL_DETAILS]: (state, action) => {
    return update(state, {
      identityPoolDetails: { $set: action.payload },
    });
  },
  [SET_IDENTITY_SCREEN_FILTERS]: (state, action) => {
    const { payload } = action;

    const keys = _.keys(payload);

    const { identityScreenFilters } = state;

    const updatedIdentityScreenFilters = _.isEmpty(payload)
      ? {}
      : _.reduce(
          keys,
          (result, key) => {
            const filterValue = payload[key];

            return { ...result, [key]: filterValue };
          },
          identityScreenFilters
        );

    return update(state, {
      identityScreenFilters: { $set: updatedIdentityScreenFilters },
    });
  },
  [SET_PORTAL_TYPE]: (state, action) => {
    return update(state, {
      portalType: { $set: action.payload },
    });
  },
};

// Export Reducer
const initialState = {
  organizationFilters: {
    searchText: "",
  },
  activeTab: null,
  tags: [],
  currentScreen: "loginHome",
  formData: {
    loginForm: {
      email: "",
      password: "",
    },
    requestSuccess: {
      firstName: "Gautam",
      isDomainValid: true,
      status: "REQ_ACC_NO_ORG",
    },
    studentLogin: {
      code: "",
    },
  },
  userInfo: {
    id: "",
    user_type: "",
    userEntityType: "STAFF",
    first_name: "",
    last_name: "",
    gender: "F",
    is_admin: false,
    email: "",
    profile_image: "",

    grades: [],

    // isAdmin: false,
  },

  toast: {
    msg: "",
    type: "alert",
    position: "toast-bottom-left",
    showToast: false,
    closeButton: false,
    actions: [],
    timeOut: 2000,
  },
  lastLoginUserType: "student",
  //roles:[],
  permissionGroups: ["Dashboards", "ToolPortal", "Common"],
  userLoggedIn: false,
  isLoading: true,
  designationList: [],
  sa: {
    loggedIn: false,
    token: null,
    orgList: [],
  },
  organizationListData: {},
  inviteToCommunityModal: false,
  retentionModalStatus: false,
  bifurcationModalStatus: false,
  organizationSwitcherDetails: {
    isVisible: false,
    userEntityType: "",
    userId: "",
  },
  bifurcationAction: null,
  redirectUrl: null,
  isChildPresent: false,
  invitedFamilyData: {},
  invitedFamilyChildData: {},
  identityPoolDetails: null,
  identityScreenFilters: {},
  authRegion: null,
  portalType: null,
  parentInviteCode: null,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
