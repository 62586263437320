import gql from "graphql-tag";

export const userfragment = {
  user: gql`
    fragment userItem on User {
      id
      firstName
      lastName
      email
      type
      profileImage
      ... on Staff {
        courses {
          id
          title
          profileImage
        }
      }
      ... on FamilyMember {
        children {
          id
          firstName
          lastName
          profileImage
        }
      }
      ... on Parent {
        children {
          id
        }
      }
      ... on Student {
        courses {
          id
          title
          profileImage
        }
      }
    }
  `,
};

export const basicUserFragment = {
  user: gql`
    fragment userItem on User {
      id
      firstName
      lastName
      email
      type
      profileImage
    }
  `,
};

export const attachmentFragment = {
  attachment: gql`
    fragment attachmentItem on Attachment {
      id
      type
      title
      url
      name
      mimeType
      thumbUrl
      streamUrl
      description
      metadata
    }
  `,
};
