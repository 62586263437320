import { fragments } from "./CircularsFragment";
import gql from "graphql-tag";

export const editCircularMutation = gql`
  mutation sendCircular(
    $description: String
    $title: String!
    $courses: [ID!]
    $attachments: [AttachmentInput]
    $updatedBy: ID!
    $isPublished: Boolean
    $publishedAt: String
    $sharedWith: [CIRCULAR_SHARED_WITH_ENUM!]
    $id: ID!
    $sendEmail: Boolean
  ) {
    school {
      updateCircular(
        input: {
          id: $id
          description: $description
          title: $title
          courses: $courses
          updatedBy: $updatedBy
          isPublished: $isPublished
          publishedAt: $publishedAt
          attachments: $attachments
          sharedWith: $sharedWith
          sendEmail: $sendEmail
        }
      ) {
        ...circularsItemDetail
      }
    }
  }
  ${fragments.circularDetails}
`;

export const createCircularMutation = gql`
  mutation sendCircular(
    $description: String
    $title: String!
    $courses: [ID!]
    $attachments: [AttachmentInput]
    $createdBy: ID!
    $isPublished: Boolean
    $publishedAt: String
    $sharedWith: [CIRCULAR_SHARED_WITH_ENUM!]
    $sendEmail: Boolean
  ) {
    school {
      createCircular(
        input: {
          description: $description
          title: $title
          courses: $courses
          attachments: $attachments
          createdBy: $createdBy
          isPublished: $isPublished
          sharedWith: $sharedWith
          publishedAt: $publishedAt
          sendEmail: $sendEmail
        }
      ) {
        ...circularsItemDetail
      }
    }
  }
  ${fragments.circularDetails}
`;

export const resetUserCircularNewCountMutation = gql`
  mutation resetUserCircularNewCount(
    $ids: [ID!]
    $curriculumProgramIds: [ID!]
  ) {
    platform {
      resetUserCircularNewCount(
        ids: $ids
        curriculumProgramIds: $curriculumProgramIds
      )
    }
  }
`;

export const markCircularReadForUserMutation = gql`
  mutation markCircularReadForUser(
    $isMarkAllRead: Boolean
    $circularIds: [ID!]
    $curriculumProgramIds: [ID!]
  ) {
    platform {
      markCircularReadForUser(
        isMarkAllRead: $isMarkAllRead
        circularIds: $circularIds
        curriculumProgramIds: $curriculumProgramIds
      )
    }
  }
`;
