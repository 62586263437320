import React, { PureComponent } from "react";
import { BenchmarkView, Accordian } from "UIComponents";
import { getElementValue } from "modules/Services";
import classes from "./SubjectBenchmarks.scss";

const getElementValueMemoize = _.memoize(getElementValue, params =>
  JSON.stringify(params)
);

const styles = {
  nodeLabelStyle: [
    {
      depth: 3,
      style: {
        marginBottom: "12px",
      },
    },
  ],
};

class SubjectBenchmarks extends PureComponent {
  state = {
    selectedValues: this.props.selectedValues,
  };

  debouncedOnItemSelected = _.debounce(
    params => this.props.onItemSelected(params),
    100
  );

  componentDidUpdate = prevProps => {
    const { selectedValues } = this.props;
    if (!_.isEqual(prevProps.selectedValues, selectedValues)) {
      this.setState({ selectedValues });
    }
  };

  onCheckboxClicked = selectedValues => {
    const {
      props: { subject, nodes, rootNodes },
    } = this;

    this.setState({ selectedValues }, () => {
      this.debouncedOnItemSelected({
        subject,
        selections: selectedValues,
        nodes,
        rootNodes,
      });
    });
  };

  getExpandedContent = ({ elementValue }) => {
    const {
      props: { isReverseCheckbox, checkboxItemStyle, nodeSpanStyle },
      state: { selectedValues },
    } = this;

    return (
      <BenchmarkView
        value={elementValue}
        startDepth={1}
        mode={"view"}
        isTagging={true}
        updateInputField={this.onCheckboxClicked}
        selectedValues={selectedValues}
        isReverseCheckbox={isReverseCheckbox}
        checkboxItemStyle={checkboxItemStyle}
        nodeSpanStyle={nodeSpanStyle}
        nodeLabelStyle={styles.nodeLabelStyle}
      />
    );
  };

  render() {
    const {
      props: {
        subject,
        nodes,
        rootNodes,
        showAccordian,
        hideContainerBorder,
        showLabelBorder,
        showCountSeperate,
        customTitleStyle,
        isExpanded,
        fieldUID,
      },
      state: { selectedValues },
    } = this;

    const elementValue = _.compact(
      getElementValueMemoize({
        valueKeys: _.map(nodes, node => node.id),
        fieldUID: fieldUID,
        benchmarkSet: { nodes, rootNodes },
      })
    );

    const numOfSelectedValues = _.get(selectedValues, "length", 0);
    const selectedNumberString =
      numOfSelectedValues > 0 && !showCountSeperate
        ? ` (${numOfSelectedValues})`
        : "";

    if (showAccordian)
      return (
        <Accordian
          title={`${subject.label}${selectedNumberString}`}
          getExpandedContent={() => this.getExpandedContent({ elementValue })}
          hideContainerBorder={hideContainerBorder}
          showLabelBorder={showLabelBorder}
          count={showCountSeperate ? numOfSelectedValues : 0}
          customTitleStyle={customTitleStyle}
          isExpanded={isExpanded}
        />
      );

    if (!_.isEmpty(elementValue))
      return (
        <div className={classes.onSearchSectionContainer}>
          <div className={classes.onSearchSectionSubHeader}>
            <span
              dangerouslySetInnerHTML={{
                __html: subject.label,
              }}
            />
          </div>
          <div className={classes.onSearchExpandedContainer}>
            {this.getExpandedContent({ elementValue })}
          </div>
          <div className={classes.dividerNew} />
        </div>
      );

    return null;
  }
}

SubjectBenchmarks.defaultProps = {
  isReverseCheckbox: false,
  searchTerm: "",
  hideContainerBorder: false,
  showLabelBorder: true,
  showCountSeperate: false,
  checkboxItemStyle: {},
  customTitleStyle: {},
  nodeSpanStyle: {},
  isExpanded: false,
  showAccordian: true,
  fieldUID: "benchmarks",
};

export default SubjectBenchmarks;
