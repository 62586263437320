import gql from "graphql-tag";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
import { organizationResourceFragment } from "OrganizationResources/modules/OrganizationResourcesFragments";
import {
  expertProfileFragment,
  organizationFragment,
  plannerTemplateFragment,
  courseFragment,
  curriculumProgramFragment,
  academicYearFragment,
} from "modules/CommonFragments";

import {
  likeConnectionNodefragment,
  entitySavedByNodeFragment,
  communitySchoolBasicFragment,
} from "Community/modules/CommunityFragments";

export const sharingUnitPlanFragment = {
  unitSections: gql`
    fragment unitSectionsSharingItem on UnitPlan {
      id
      enabledSections {
        userType
        sections
      }
      sharedCoursesWithUserGroup {
        userType
        courses {
          ...courseGradeItem
        }
      }
      isSharedWithStudents
      isSharedWithFamilies
      shareWithUserType
    }
    ${courseFragment.courserGradesDetails}
  `,
  unitFlow: gql`
    fragment unitFlowSharingItem on UnitPlan {
      id
      unitFlowResources {
        id
      }
    }
  `,
};

export const staffUnitPlanFragment = {
  staffCourses: gql`
    fragment staffCoursesItem on Staff {
      id
      firstName
      lastName
      profileImage
      email
      isArchived
      roles
      courses {
        id
        title
      }
    }
  `,
  staffMinorDetails: gql`
    fragment staffCollaboratorItem on Staff {
      id
    }
  `,
};

export const unitPlanFragment = {
  unitPlanDetails: gql`
    fragment unitPlanItem on UnitPlan {
      id
      templateId
      startDate
      endDate
      state
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      createdAt
      cascadeUpdatedBy {
        id
        firstName
        lastName
        profileImage
      }
      cascadeUpdateAt
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      ...unitSectionsSharingItem
      ...unitFlowSharingItem
      grades
      assessmentTool {
        id
      }
      courses {
        ...courseGradeSubjectItem
      }
      unitType {
        ...unitPlanFieldItem
      }
      title {
        ...unitPlanFieldItem
      }
      theme {
        ...unitPlanFieldItem
      }
      duration {
        ...unitPlanFieldItem
      }
      image {
        ...unitPlanFieldItem
      }
      subjects {
        ...unitPlanFieldItem
      }
      owner {
        id
      }
      allFields {
        ...unitPlanFieldItem
      }
      leLibrary: resourceLibrary(filters: { groupType: LEARNING_ENGAGEMENT }) {
        leCount
      }
      assessmentLibrary: resourceLibrary(filters: { groupType: ASSESSMENT }) {
        assessmentCount
      }
      unitFlow {
        totalCount
      }
      resources {
        totalCount
      }
      organization {
        ...organizationItem
      }
      academicYears {
        ...academicYearItem
      }
      contributedFromSchool {
        ...communitySchoolItem
      }
      organizationResources {
        ...organizationResourceItem
      }
      collaborators: allCollaborators {
        totalCount
        edges {
          permission
          node {
            ...staffCoursesItem
          }
        }
      }
      expertProfile {
        ...expertItem
      }
      ...unitLikeConnectionNodeItem
      ...unitSavedByNodeItem
      isDeleted
      isImported
      presetId
      leTemplate
      assessmentTemplate
      paritaStarStatus
      kristenStarStatus
      juryStarStatus
      paritaProcessed
      kristenProcessed
      juryProcessed
      submittedToCommunity
      communityComments {
        totalCount
      }
    }
    ${academicYearFragment.academicYear}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${organizationFragment.organizationBasicDetails}
    ${plannerFieldFragment.unitplanField}
    ${staffUnitPlanFragment.staffCourses}
    ${expertProfileFragment.expert}
    ${likeConnectionNodefragment.unitPlan}
    ${entitySavedByNodeFragment.unitPlan}
    ${communitySchoolBasicFragment.communitySchool}
    ${courseFragment.courseGradeSubjectDetails}
    ${organizationResourceFragment.organizationResource}
    ${sharingUnitPlanFragment.unitSections}
    ${sharingUnitPlanFragment.unitFlow}
  `,
  unitPlanCollaborator: gql`
    fragment unitPlanItem on UnitPlan {
      id
      collaborators: allCollaborators {
        totalCount
        edges {
          permission
          node {
            ...staffCollaboratorItem
          }
        }
      }
    }
    ${staffUnitPlanFragment.staffMinorDetails}
  `,
  unitPlanCollaboratorDetails: gql`
    fragment unitPlanItem on UnitPlan {
      id
      collaborators: allCollaborators {
        totalCount
        edges {
          permission
          node {
            ...staffCoursesItem
          }
        }
      }
    }
    ${staffUnitPlanFragment.staffCourses}
  `,

  unitSummaryPlanDetails: gql`
    fragment unitSummaryPlanItem on UnitPlan {
      id
      grades
      unitType {
        ...unitPlanFieldItem
      }

      allFields {
        ...unitPlanFieldItem
      }

      template {
        ...templateItem
      }

      curriculumProgram {
        id
        type
      }
    }
    ${plannerFieldFragment.unitplanField}
    ${plannerTemplateFragment.plannerTemplate}
  `,
};
