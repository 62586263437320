import PropTypes from "prop-types";
import React from "react";
import classes from "./ProfileCascade.scss";
import { DEFAULT_PROFILE_PIC, colorPalette } from "Constants";
import { getRethumbUrl } from "Utils";
import { MoreItemWithToolTip } from "UIComponents";
import classNames from "classnames";
import { Avatar, AvatarGroup } from "@toddle-design/web";
import { AVATAR_COLORS } from "Constants/colorConstants";

const getMarginLeft = (length, itemLength, marginValue) => {
  const remainingLength = itemLength - length;
  return ((remainingLength > 0 ? length + 1 : itemLength) - 1) * -marginValue;
};

const getTrimValue = value => {
  return value.length >= 1
    ? value.toUpperCase().charAt(0) + (value.length >= 2 ? value.charAt(1) : "")
    : "";
};

const getBackgroundcolor = (id, shouldDynamicColor) => {
  let colorIndex = 0;
  if (shouldDynamicColor) {
    colorIndex = id ? parseInt(id) % colorPalette.length : 0;
  }
  return colorPalette[colorIndex];
};

const getStyle = (index, length, remainingLength, marginValue) => {
  return {
    right: index * marginValue,
    zIndex: index,
  };
};

const getRethumb = (imageUrl, imageSize) => {
  const rethumpUrl = imageUrl
    ? getRethumbUrl({
        width: imageSize,
        height: imageSize,
        imageUrl: imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${rethumpUrl})`;
};

const COLORS = _.filter(AVATAR_COLORS, color => color !== "neutral");

const AvatarComp = props => {
  const { items, avatarStyles, isArchived, isDisabled = false } = props;
  const { id, value, imageUrl } = _.first(items);
  const { size, color, shape, iconType, shade, randomizeColor } = avatarStyles;

  const avatarColor = randomizeColor ? COLORS[id % _.size(COLORS)] : color;
  const avatarIconType = !_.isEqual(iconType, "none") ? iconType : "";

  const avatarClass = classNames({
    [classes.avatarContainer]: true,
    [classes.archived]: isArchived,
    [classes.disabled]: isDisabled,
  });

  return (
    <div className={avatarClass}>
      <Avatar
        name={_.upperCase(value)}
        size={size}
        shape={shape}
        iconType={avatarIconType}
        shade={shade}
        src={imageUrl}
        color={avatarColor}
      />
    </div>
  );
};

const ProfileComp = ({
  value,
  style,
  textStyle,
  onItemClick,
  id,
  coachmarkId,
  isArchived,
  imageUrl,
}) => {
  const onClick = e => {
    if (onItemClick) {
      e.stopPropagation();
      onItemClick(e, { id });
    }
  };

  const containerClasses = classNames({
    [classes.profileContainer]: true,
    [classes.archived]: isArchived,
  });

  return (
    <div
      id={coachmarkId}
      style={{ ...(style || {}), cursor: onItemClick ? "pointer" : "unset" }}
      className={containerClasses}
      onClick={onClick}
    >
      {value && !imageUrl && (
        <div className={classes.profileText} style={textStyle}>
          {value}
        </div>
      )}
    </div>
  );
};

const CascadeComp = ({
  items,
  customStyle,
  length,
  customTextStyle,
  shouldDynamicColor,
  customContainerStyle,
  onItemClick,
  showCount,
  itemsTotalCount,
  count,
  isArchived,
  marginValue,
  imageSize,
  customMoreTextContainerStyle,
  customMoreTextStyle,
  showAvatar,
  avatarStyles,
  isDisabled,
}) => {
  // Used avatarGroup(DS) if there are more than one profile
  if (showAvatar && items?.length > 1) {
    return (
      <AvatarGroup max={1} shade="light">
        {_.map(items, ({ id, value, imageUrl }) => (
          <Avatar key={id} name={value} src={imageUrl} />
        ))}
      </AvatarGroup>
    );
  }

  const sliceItems = _.slice(items, 0, length);
  const remainingLength = (itemsTotalCount || items.length) - length;
  let marginLeft = 0;

  const newItems =
    remainingLength > 0
      ? [
          ...sliceItems,
          {
            id: 2,
            value: `+${remainingLength}`,

            isMore: true,
          },
        ]
      : sliceItems;
  const profileObj = _.map(newItems, (item, index) => {
    let style = getStyle(index, length, remainingLength, marginValue);
    let textStyle = {};
    let value = "";
    if (!item.imageUrl && item.value) {
      let backgroundColor = item.backgroundColor;
      if (!backgroundColor) {
        backgroundColor = getBackgroundcolor(item.id, shouldDynamicColor);
      }
      style.backgroundColor = backgroundColor;
    } else {
      if (item.imageUrl) {
        style.backgroundImage = getRethumb(item.imageUrl, imageSize);
      } else {
        style.backgroundImage = `url(${DEFAULT_PROFILE_PIC})`;
      }

      textStyle.color = "transparent";
    }
    if (item.value) {
      value = !item.isMore ? getTrimValue(item.value) : item.value;
    }

    style.fontSize = `${_.get(customStyle, "width", 40) / 4}px`;

    return showAvatar ? (
      <AvatarComp
        items={items}
        isArchived={isArchived}
        avatarStyles={avatarStyles}
        isDisabled={isDisabled}
      />
    ) : (
      <ProfileComp
        style={{
          ...style,
          ...customStyle,
          ...(item.isMore ? customMoreTextContainerStyle : {}),
        }}
        index={index}
        value={value}
        key={index}
        id={item.id}
        imageUrl={item.imageUrl}
        textStyle={{
          ...customTextStyle,
          ...textStyle,
          ...(item.isMore ? customMoreTextStyle : {}),
        }}
        onItemClick={onItemClick}
        coachmarkId={item.coachmarkId}
        isArchived={isArchived}
      />
    );
  });

  return (
    <div
      className={classes.cascadeContainer}
      style={{
        marginRight: getMarginLeft(length, items.length, marginValue),
        ...customContainerStyle,
      }}
    >
      {profileObj}
      {showCount && count > 0 && (
        <div className={classes.countCon}>{count}</div>
      )}
    </div>
  );
};

const getValues = items => {
  return _.map(items, item => {
    return item.value;
  });
};

class ProfileCascade extends React.PureComponent {
  render() {
    const {
      items,
      customStyle,
      length,
      customTextStyle,
      customContainerStyle,
      customMoreTextContainerStyle,
      customMoreTextStyle,
      shouldDynamicColor,
      showToolTip,
      mainContainerStyle,
      onItemClick,
      showCount,
      count,
      toolTipMaxLength,
      itemsTotalCount,
      isArchived,
      marginValue,
      imageSize,
      showAvatar,
      avatarStyles,
      isDisabled,
    } = this.props;
    const cascadeComp = (
      <CascadeComp
        items={items}
        customStyle={customStyle}
        length={length}
        customTextStyle={customTextStyle}
        shouldDynamicColor={shouldDynamicColor}
        customContainerStyle={customContainerStyle}
        onItemClick={onItemClick}
        showCount={showCount}
        count={count}
        itemsTotalCount={itemsTotalCount}
        isArchived={isArchived}
        marginValue={marginValue}
        imageSize={imageSize}
        customMoreTextContainerStyle={customMoreTextContainerStyle}
        customMoreTextStyle={customMoreTextStyle}
        showAvatar={showAvatar}
        avatarStyles={avatarStyles}
        isDisabled={isDisabled}
      />
    );
    return (
      <div className={classes.container} style={mainContainerStyle}>
        {showToolTip ? (
          <MoreItemWithToolTip
            items={getValues(items)}
            length={0}
            tooltipPlacement={"bottom"}
            renderComponent={cascadeComp}
            itemsTotalCount={itemsTotalCount}
            maxLength={toolTipMaxLength}
          />
        ) : (
          cascadeComp
        )}
      </div>
    );
  }
}

ProfileCascade.defaultProps = {
  length: 2,
  items: [],
  customStyle: {},
  customTextStyle: {},
  customMoreTextContainerStyle: {},
  customMoreTextStyle: {},
  customContainerStyle: {},
  shouldDynamicColor: false,
  showToolTip: false,
  mainContainerStyle: {},
  showCount: false,
  count: 0,
  toolTipMaxLength: 10,
  itemsTotalCount: 0,
  isArchived: false,
  marginValue: 12,
  imageSize: 240,
  avatarStyles: {
    size: "x-small",
    color: "neutral",
    shape: "circle",
    iconType: "none",
    shade: "light",
    randomizeColor: true,
  },
};

ProfileCascade.propTypes = {
  length: PropTypes.number,
  items: PropTypes.array,
  customStyle: PropTypes.object,
  customTextStyle: PropTypes.object,
  customMoreTextContainerStyle: PropTypes.object,
  customMoreTextStyle: PropTypes.object,
  customContainerStyle: PropTypes.object,
  shouldDynamicColor: PropTypes.bool,
  showToolTip: PropTypes.bool,
  showCount: PropTypes.bool,
  count: PropTypes.number,
  itemsTotalCount: PropTypes.number,
  isArchived: PropTypes.bool,
  marginValue: PropTypes.number,
  imageSize: PropTypes.number,
  avatarStyles: PropTypes.object,
};

export default ProfileCascade;
