import { UIBaseComponent, I18nHOC } from "UIComponents";
import React from "react";
import classes from "./TimeSelector.scss";
import PropTypes from "prop-types";
import TimePicker from "./TimePicker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import classNames from "classnames";
import { ClockOutlined, CloseOutlined } from "@toddle-design/web-icons";

const offsets = [
  {
    position: "bottomLeft",
    offset: [-32, 48],
  },
  {
    position: "topLeft",
    offset: [-32, 48],
  },
];

class TimeSelector extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.state, isOpen: false };
  }

  handlePickerStates = ({ open }) => {
    if (open) {
      this.onFocus();
    } else {
      this.onBlur();
    }
    this.setState({ isOpen: open });
  };

  blur = () => {
    if (this._calendar) {
      this._calendar.blur();
    }
  };

  updateLocalValue = value => {
    const hour = moment(value).get("hour");
    const minute = moment(value).get("minute");
    const disabledMinutes = this.disabledMinutes(hour);
    this._calendar.open = false;
    if (disabledMinutes.indexOf(minute) >= 0) {
      const excludedMinutes = this.excludedRange({
        start: 0,
        excludedOptions: disabledMinutes,
        end: 59,
      });
      const temp = value.set({ hour: hour, minute: excludedMinutes[0] });
      this.updateValueLocally(temp);
    } else {
      this.updateValueLocally(value);
    }
  };

  updateValueLocally = value => {
    const {
      storeValueInSeconds,
      isValueMomentObj,
      SelectedTimeformat,
    } = this.props;
    let localValue = value;

    if (storeValueInSeconds) {
      const hours = value.hour();
      const minute = value.minute();
      const seconds = value.second();
      const duration = hours * 3600 + minute * 60 + seconds;

      localValue = duration;
    }
    this.updateValue(
      isValueMomentObj
        ? localValue
        : moment(localValue).format(SelectedTimeformat)
    );
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  excludedRange = ({ start = 0, excludedOptions, end }) => {
    const arr = [];
    for (let value = start; value <= end; value++) {
      if (excludedOptions.indexOf(value) < 0) {
        arr.push(value);
      }
    }
    return arr;
  };

  disabledHours = () => {
    const { startTime, endTime, getFixHours } = this.props;
    if (this.props.getFixHours) {
      return this.excludedRange({
        start: 0,
        excludedOptions: getFixHours(),
        end: 23,
      });
    } else if (!!startTime && !!endTime) {
      const startHour = moment(startTime).get("hour");
      const endHour = moment(endTime).get("hour");
      const excludedHours = this.range(startHour, endHour);
      return this.excludedRange({
        start: 0,
        excludedOptions: excludedHours,
        end: 23,
      });
    } else {
      return [];
    }
  };

  disabledMinutes = h => {
    const { startTime, endTime, getFixMinutes } = this.props;
    if (getFixMinutes) {
      return this.excludedRange({
        start: 0,
        excludedOptions: this.props.getFixMinutes(h),
        end: 59,
      });
    } else if (!!startTime && !!endTime) {
      let disabledMinutes = [];
      const startHour = moment(startTime).get("hour");
      const endHour = moment(endTime).get("hour");
      if (h == startHour) {
        const startMinute = moment(startTime).get("minute");
        disabledMinutes = this.range(0, startMinute - 1);
      } else if (h == endHour) {
        const endMinute = moment(endTime).get("minute");
        disabledMinutes = this.range(endMinute + 1, 59);
      }
      return disabledMinutes;
    } else {
      return [];
    }
  };

  constructTimeFromDuration = durationInSeconds => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minute = Math.floor((durationInSeconds - hours * 3600) / 60);

    const dateValue = moment().startOf("day").add(hours, "h").add(minute, "m");

    return dateValue;
  };

  getValue = () => {
    const {
      value,
      storeValueInSeconds,
      isValueMomentObj,
      SelectedTimeformat,
    } = this.props;

    return storeValueInSeconds
      ? this.constructTimeFromDuration(value)
      : isValueMomentObj
      ? value
      : moment(value, SelectedTimeformat);
  };

  onClickReset = () => {
    this.updateLocalValue(this.constructTimeFromDuration(0));
  };

  renderEdit = () => {
    const borderElemClass = classNames({
      [classes.borderElem]: true,
      [classes.timeComponentSelected]: this.state.isOpen,
    });

    const {
      minuteStep,
      showSecond,
      isDisabled,
      showMinute,
      SelectedTimeformat,
      value,
      placeholder,
      t,
      storeValueInSeconds,
      showInputIcon,
      showBorderElem,
      timeComponentStyle,
      use12Hours,
      showResetButton,
    } = this.props;

    return (
      <div className={classes.container}>
        {showBorderElem && <div className={borderElemClass} />}
        <div className={classes.timeComponent} style={timeComponentStyle}>
          {showInputIcon && (
            <div className={classes.timeSvg}>
              <ClockOutlined size="xx-small" />
            </div>
          )}
          <TimePicker
            value={value ? moment(this.getValue()) : null}
            allowEmpty={false}
            showSecond={showSecond}
            onChange={props => this.updateLocalValue(props)}
            disabledHours={() => this.disabledHours()}
            disabledMinutes={h => this.disabledMinutes(h)}
            showMinute={showMinute}
            minuteStep={minuteStep}
            placeholder={
              placeholder === "common:select_with_label"
                ? t(placeholder, {
                    label: t("common:lowercase", { text: t("common:time") }),
                  })
                : placeholder
            }
            inputReadOnly={true}
            hideDisabledOptions
            ref={c => (this._calendar = c)}
            disabled={isDisabled}
            showHeader={false}
            offsets={offsets}
            onOpen={this.handlePickerStates}
            onClose={this.handlePickerStates}
            dropDownWidth={230}
            format={SelectedTimeformat}
            getPopupContainer={node => node}
            defaultOpenValue={
              storeValueInSeconds ? moment().startOf("day") : moment()
            }
            use12Hours={use12Hours}
          />
        </div>
        {showResetButton && !_.isEqual(value, 0) && (
          <CloseOutlined
            variant="subtle"
            size="xx-small"
            className={classes.resetButton}
            onClick={this.onClickReset}
          />
        )}
      </div>
    );
  };
}

TimeSelector.defaultProps = {
  ...UIBaseComponent.defaultProps,
  isDisabled: false,
  SelectedTimeformat: "HH:MM",
  minuteStep: 5,
  showSecond: false,
  showMinute: true,
  isValueMomentObj: true,
  storeValueInSeconds: false,
  placeholder: "common:select_with_label",
  showInputIcon: true,
  use12Hours: false,
  showBorderElem: true,
  showResetButton: false,
};

TimeSelector.propTypes = {
  ...UIBaseComponent.propTypes,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  minuteStep: PropTypes.number,
  startTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]),

  SelectedTimeformat: PropTypes.string,
  endTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.object]),
  showInputIcon: PropTypes.bool,
  use12Hours: PropTypes.bool,
  showBorderElem: PropTypes.bool,
  showResetButton: PropTypes.bool,
};

export default I18nHOC({ resource: ["common"] })(TimeSelector);
