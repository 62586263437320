import React from "react";

const Grade3Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M28.1309,31.5508 C28.1309,30.8258 28.4769,29.3058 30.6879,29.3058 C33.5899,29.3058 34.4859,27.9578 34.4859,26.5418 C34.4859,24.7798 33.0019,23.8478 31.4119,23.8478 C30.2029,23.8478 29.3749,24.3308 28.5099,25.3668 C27.9919,25.9888 27.3359,26.5078 26.2659,26.5078 C24.8499,26.5078 23.7089,25.4708 23.7089,24.0548 C23.7089,23.2598 23.9849,22.6388 24.7109,21.8438 C26.2989,20.1158 28.9939,19.1138 31.7929,19.1138 C36.1109,19.1138 40.3949,21.3248 40.3949,26.0238 C40.3949,28.7188 38.6679,30.7218 36.2829,31.3438 L36.2829,31.4468 C39.0819,32.1038 40.9829,34.3838 40.9829,37.1128 C40.9829,42.4678 36.2479,44.8858 31.7579,44.8858 C28.6489,44.8858 26.0239,43.9878 24.1579,42.1558 C23.3629,41.3628 23.0179,40.6028 23.0179,39.7388 C23.0179,38.2528 24.2269,37.1478 25.6429,37.1478 C26.5409,37.1478 27.1639,37.5628 28.1989,38.5988 C29.2009,39.6008 30.2029,40.0148 31.4829,40.0148 C33.1409,40.0148 35.0389,39.1858 35.0389,36.9058 C35.0389,35.1788 33.6229,33.8308 30.6879,33.8308 C28.4769,33.8308 28.1309,32.3118 28.1309,31.5508"></path>
      </g>
    </svg>
  );
};

Grade3Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default Grade3Icon;
