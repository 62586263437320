import React from "react";

const Student = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      //   style="enable-background:new 0 0 18 18;"
    >
      <g fill={props.fill}>
        <path
          d="M4,7c0,1.52,0.7,2.87,1.78,3.79C3.54,11.96,2,14.3,2,17c0,0.55,0.45,1,1,1s1-0.45,1-1c0-2.76,2.24-5,5-5s5,2.24,5,5
   c0,0.55,0.45,1,1,1s1-0.45,1-1c0-2.7-1.54-5.04-3.78-6.21C13.3,9.87,14,8.52,14,7c0-1.98-1.17-3.77-2.97-4.57
   C10.39,2.14,9.71,2,9,2C6.24,2,4,4.24,4,7z M9,4c0.42,0,0.83,0.09,1.22,0.26C11.3,4.74,12,5.81,12,7c0,1.65-1.35,3-3,3S6,8.65,6,7
   S7.35,4,9,4z"
        />
        <path d="M16,0H3C2.45,0,2,0.45,2,1s0.45,1,1,1h6h6v5c0,0.55,0.45,1,1,1s1-0.45,1-1V1C17,0.45,16.55,0,16,0z" />
      </g>
    </svg>
  );
};

const Parent = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      width={props.width}
      height={props.height}
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      //   style="enable-background:new 0 0 18 18;"
    >
      <path
        fill="#231F20"
        d="M15.03,11.49c1.36-0.8,1.92-1.87,1.96-1.94c0.2-0.38,0.12-0.85-0.18-1.16c-0.32-0.33-1.24-1.46-1.24-2.36
	c0-2.98-2.43-4.06-4.06-4.06c-0.14,0-1.28,0.02-2.33,0.69c-0.15-0.09-0.3-0.17-0.46-0.24c-0.67-0.3-1.38-0.45-2.11-0.45
	c-2.87,0-5.21,2.34-5.21,5.21c0,1.65,0.78,3.1,1.98,4.06C1.37,12.37,0,14.53,0,17c0,0.55,0.45,1,1,1s1-0.45,1-1
	c0-2.54,2.07-4.61,4.61-4.61s4.61,2.07,4.61,4.61c0,0.55,0.45,1,1,1s1-0.45,1-1c0-1.79-0.72-3.42-1.88-4.61h0.05
	c2.54,0,4.61,2.07,4.61,4.61c0,0.55,0.45,1,1,1s1-0.45,1-1C18,14.7,16.82,12.67,15.03,11.49z M11.5,3.97
	c0.49,0.01,2.07,0.18,2.07,2.06c0,1.25,0.71,2.41,1.25,3.1c-0.5,0.52-1.53,1.25-3.31,1.25c-0.01,0-0.02,0-0.02,0
	c-0.03,0-0.06,0-0.1,0h-0.71c0.7-0.89,1.14-1.99,1.14-3.21c0-1.13-0.38-2.2-1.02-3.08C11.18,3.98,11.51,3.97,11.5,3.97z M3.4,7.18
	c0-1.77,1.44-3.21,3.21-3.21c0.45,0,0.89,0.09,1.3,0.27c1.16,0.51,1.91,1.67,1.91,2.93c0,1.77-1.44,3.21-3.21,3.21
	C4.84,10.39,3.4,8.95,3.4,7.18z"
      />
    </svg>
  );
};

const Everyone = props => {
  return (
    <svg
      version="1.1"
      height={props.height}
      width={props.width}
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      //   style="enable-background:new 0 0 18 18;"
    >
      <path
        d="M9,0C4.04,0,0,4.04,0,9s4.04,9,9,9s9-4.04,9-9S13.96,0,9,0z M9,16c-0.04,0-0.08-0.01-0.12-0.01
	c-0.13-0.61-0.41-1.28-1.03-1.28c-1.14,0-1.14-2.29-3.43-2.29c-1.62,0,0-1.14,0-2.29C4.43,9.1,3.02,8.93,2,8.94
	C2.04,5.11,5.16,2,9,2c2.6,0,4.86,1.43,6.07,3.53c-1.01-0.69-2.64-2.19-2.64,0.04c0,1.14-1.53-1.03-2.29,0
	c-0.12,0.16-2.29,0.71-2.29,1.14C7.86,7.86,8.05,9.05,9,9c0.52-0.03,1.14,1.14,2.29,2.29c0.43,0.43,0.81,2.12,1.14,2.29
	c1.06,0.52,2.29-3.43,2.29-4.57c0-0.86,0.65-0.8,1.26-0.56C15.99,8.62,16,8.81,16,9C16,12.86,12.86,16,9,16z"
      />
    </svg>
  );
};

const Private = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
    >
      <g id="Lock" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="icn_lock"
          transform="translate(5.000000, 0.500000)"
          fill={props.fill}
          fillRule="nonzero"
          stroke={props.fill}
          strokeWidth="0.8"
        >
          <path
            d="M11.1832193,0 L10.775114,0 C6.29399597,0 2.65583849,3.50239609 2.45717627,7.91899358 L2.4486319,8.29973758 L2.4486319,13.0185537 L2.35193147,13.2956007 C0.932331545,15.0830199 0.113857844,17.2523219 0.0110373501,19.5596477 L0,20.0560982 L0,20.0967778 C0,25.9811679 4.68544271,30.7823252 10.5375802,30.992804 L10.9383561,31 L10.9791667,31 C16.9164906,31 21.7396732,26.3353722 21.9511048,20.4966508 L21.9583333,20.0967778 L21.9583333,20.0560982 C21.9583333,17.7302991 21.2344996,15.525105 19.9013592,13.6843753 L19.6064019,13.2956007 L19.5097014,13.0185537 L19.5097014,8.29973758 C19.5097014,3.70539211 15.7923693,0 11.1832193,0 Z M10.9791667,11.514544 C15.7156284,11.514544 19.5482447,15.33485 19.5482447,20.0560982 L19.5482447,20.0967778 C19.5482447,24.8180261 15.7156284,28.6383321 10.9791667,28.6383321 L10.9383561,28.6383321 C6.20189436,28.6383321 2.36927809,24.8180261 2.36927809,20.0967778 L2.36927809,20.0560982 C2.36927809,15.33485 6.20189436,11.514544 10.9383561,11.514544 L10.9791667,11.514544 Z M10.9791667,19.1193411 C11.6296951,19.1193411 12.1638057,19.5928031 12.1638057,20.1694636 L12.1638057,23.4253458 C12.1638057,24.0020065 11.6296951,24.4754685 10.9791667,24.4754685 C10.3286383,24.4754685 9.79452761,24.0020065 9.79452761,23.4253458 L9.79452761,20.1694636 C9.79452761,19.5928031 10.3286383,19.1193411 10.9791667,19.1193411 Z M11.1832193,2.36166801 C14.450502,2.36166801 17.1404233,5.04294938 17.1404233,8.29973758 L17.1404233,10.9901379 L16.4664309,10.596873 C14.8516577,9.65467601 12.9688784,9.15287602 11.0199772,9.15287602 L10.9791667,9.15287602 C9.05930766,9.15287602 7.21261939,9.65760251 5.52558064,10.6009475 L4.85872053,10.9738381 L4.85872053,8.29973758 C4.85872053,5.03620989 7.51450149,2.36166801 10.775114,2.36166801 L11.1832193,2.36166801 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const ShareWithIconSvg = props => {
  const { type } = props;

  if (type == "student") {
    return <Student {...props} />;
  } else if (type == "parent") {
    return <Parent {...props} />;
  } else if (type == "everyone") {
    return <Everyone {...props} />;
  } else if (type == "private") {
    return <Private {...props} />;
  }

  return <Everyone {...props} />;
};

ShareWithIconSvg.defaultProps = {
  fill: "black",
  height: 32,
  width: 32,
};

export default ShareWithIconSvg;
