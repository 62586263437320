import PropTypes from "prop-types";
import React from "react";
import loadScript from "load-script";
const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";

import { ThirdPartyAuth } from "AppComponents";

export default class GooglePickerChooser extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    viewId: PropTypes.string,
    authImmediate: PropTypes.bool,
    origin: PropTypes.string,
    onChange: PropTypes.func,
    createPicker: PropTypes.func,
    multiselect: PropTypes.bool,
    navHidden: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    viewId: "DOCS",
    authImmediate: false,
    multiselect: false,
    navHidden: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    if (!document.getElementById("googlePickerChooserScript")) {
      loadScript(
        GOOGLE_SDK_URL,
        { attrs: { id: "googlePickerChooserScript" } },
        this.onApiLoad
      );
    }
  }

  onApiLoad() {
    window?.gapi?.load("picker");
  }

  isGoogleReady() {
    return !!window?.gapi;
  }

  isGooglePickerReady() {
    return !!window.google?.picker;
  }

  successCallback = ({ accessToken }) => {
    let youtubeVideoView;

    let myDriveView;
    let starredView;
    let sharedWithMeView;

    let shareDriveView;
    const { updateAccessToken } = this.props;
    if (updateAccessToken) {
      updateAccessToken({ accessToken });
    }

    if (this.props.pickerType === "YoutubeVideo") {
      youtubeVideoView = new window.google.picker.VideoSearchView();
      youtubeVideoView.setSite("https://www.youtube.com");
    } else {
      let googleViewId = window.google.picker.ViewId[this.props.viewId];

      myDriveView = new window.google.picker.DocsView(googleViewId)
        .setMimeTypes(this.props.mimeTypes.join(","))
        .setIncludeFolders(true)
        .setParent("root")
        .setLabel("My Drive");

      starredView = new window.google.picker.DocsView(googleViewId)
        .setMimeTypes(this.props.mimeTypes.join(","))
        .setIncludeFolders(true)
        .setStarred(true)
        .setLabel("Starred");

      sharedWithMeView = new window.google.picker.DocsView(googleViewId)
        .setMimeTypes(this.props.mimeTypes.join(","))
        .setOwnedByMe(false)
        .setIncludeFolders(true);

      shareDriveView = new window.google.picker.DocsView(googleViewId)
        .setMimeTypes(this.props.mimeTypes.join(","))
        .setIncludeFolders(true)
        .setEnableDrives(true);
    }

    const picker = new window.google.picker.PickerBuilder()
      .setOAuthToken(accessToken)
      // .setDeveloperKey(this.props.developerKey)
      .setCallback(this.props.onChange);

    if (this.props.pickerType === "YoutubeVideo") {
      picker.addView(youtubeVideoView);
    } else {
      picker.addView(myDriveView);
      picker.addView(starredView);
      picker.addView(sharedWithMeView);
    }

    if (this.props.origin) {
      picker.setOrigin(this.props.origin);
    }

    if (this.props.navHidden && !this.props.enableShareDrive) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
    }

    if (this.props.enableShareDrive) {
      picker.addView(shareDriveView);
      picker.enableFeature(window.google.picker.Feature.SUPPORT_DRIVES);
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
    }

    picker.build().setVisible(true);
  };

  updateThirPartyAuthRef = ref => {
    const { setInnerGooglePickerRef } = this.props;
    // TODO: remove the wrappedInstance as it will be deprecated soon
    const authRef = _.get(ref, "refs.wrappedInstance", {});
    this.authPopUpRef = authRef;
    if (setInnerGooglePickerRef) {
      setInnerGooglePickerRef(authRef);
    }
  };

  onAuthClick = e => {
    e.stopPropagation();
    if (
      !this.isGoogleReady() ||
      !this.isGooglePickerReady() ||
      this.props.disabled
    ) {
      return null;
    }

    if (this.authPopUpRef) {
      this.authPopUpRef.onAuthClick();
    }
  };

  render() {
    return (
      <div onClick={this.onAuthClick} className={this.props.classStyle}>
        <React.Fragment>
          <ThirdPartyAuth
            serviceType="GOOGLE"
            ref={this.updateThirPartyAuthRef}
            onSuccessCallBack={this.successCallback}
          />
          {this.props.children ? (
            this.props.children
          ) : (
            <button>Open google chooser</button>
          )}
        </React.Fragment>
      </div>
    );
  }
}
