import React from "react";
import classes from "./TagUnit.scss";
import ScreenPanel from "../../ScreenPanel";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { UIButton, I18nHOC } from "UIComponents";
import { getCourseUnitQuery } from "Post/modules/PostQuery";
import { updatePost } from "Post/modules/PostModule";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import moment from "moment";
import UnitList from "./UnitList";

const FooterComponent = React.memo(props => {
  const {
    onNextClicked,
    selectedUnitId,
    t,
    showDoneButton,
    onDoneClicked,
    selectFromAllPYP,
  } = props;

  return (
    <div className={classes.footerButtonContainer}>
      <UIButton
        color={"pink"}
        type={"filled"}
        size={"sm"}
        isDisabled={
          _.isEmpty(selectedUnitId) && !selectFromAllPYP && !showDoneButton
        }
        onClick={
          _.isEmpty(selectedUnitId) && !selectFromAllPYP
            ? onDoneClicked
            : onNextClicked
        }
      >
        {showDoneButton && _.isEmpty(selectedUnitId) && !selectFromAllPYP
          ? t("done")
          : t("next")}
      </UIButton>
    </div>
  );
});

FooterComponent.displayName = "FooterComponent";

class TagUnit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedUnitId: props.unitPlanId,
      showDoneButton: false,
      selectFromAllPYP: props.unitPlanId ? false : props.selectFromAllPYP,
    };
  }

  onNextClick = () => {
    const {
      screenPanelProps: { goToNextScreen },
    } = this.props;
    goToNextScreen();
  };

  onDoneClicked = () => {
    const { updatePost, screenPanelProps } = this.props;
    updatePost({
      unitPlan: null,
      isResourceSkipped: false,
      resourceId: null,
      resourceType: null,
      resourceItem: undefined,
      elementPYP: undefined,
    });
    screenPanelProps.onCloseClick();
  };

  onNextClicked = () => {
    const {
      state: { selectedUnitId, selectFromAllPYP },
      props: {
        unitPlanId: propUnitPlanId,
        screenPanelProps: { goToNextScreen, goToFinalScreen },
        unitPlans,
      },
    } = this;

    if (selectFromAllPYP) {
      const updatePostParams = {
        selectFromAllPYP,
        unitPlan: null,
        resourceId: null,
        resourceType: null,
        resourceItem: undefined,
      };
      if (_.isEmpty(propUnitPlanId)) this.props.updatePost(updatePostParams);
      else
        this.props.updatePost({
          ...updatePostParams,
          elementPYP: null,
        });
      goToFinalScreen();
      return;
    }

    if (!_.isEmpty(propUnitPlanId) && propUnitPlanId == selectedUnitId) {
      this.props.updatePost({
        unitPlan: _.find(unitPlans, item => item.id == selectedUnitId),
      });
    } else {
      this.props.updatePost({
        isResourceSkipped: false,
        resourceId: null,
        resourceType: null,
        resourceItem: undefined,
        elementPYP: undefined,
        unitPlan: _.find(unitPlans, item => item.id == selectedUnitId),
      });
    }
    goToNextScreen();
  };

  onUnitClicked = unit => {
    const { selectedUnitId } = this.state;
    this.setState(
      {
        selectedUnitId: unit.id !== selectedUnitId ? unit.id : null,
        showDoneButton: !_.isEmpty(this.props.unitPlanId),
        selectFromAllPYP: false,
      }
      // this.onNextClicked
    );
  };

  onSelectFromAllPYP = () => {
    const {
      state: { selectFromAllPYP },
    } = this;

    this.setState({
      selectFromAllPYP: !selectFromAllPYP,
      selectedUnitId: null,
    });
  };

  render() {
    const {
      props: {
        screenPanelProps,
        unitPlans,
        isLoading,
        isData,
        t,
        curriculumProgramType,
      },
      state: { selectedUnitId, showDoneButton, selectFromAllPYP },
    } = this;

    const modalHeaderTitle = screenPanelProps.modalHeaderTitle;

    return (
      <ScreenPanel
        {...screenPanelProps}
        modalHeaderTitle={modalHeaderTitle}
        footerComponent={
          <FooterComponent
            t={t}
            onNextClicked={this.onNextClicked}
            selectedUnitId={selectedUnitId}
            selectFromAllPYP={selectFromAllPYP}
            showDoneButton={showDoneButton}
            onDoneClicked={this.onDoneClicked}
          />
        }
      >
        <div className={classes.container}>
          <UnitList
            selectedUnitId={selectedUnitId}
            selectFromAllPYP={selectFromAllPYP}
            unitPlans={unitPlans}
            onItemClicked={this.onUnitClicked}
            isData={isData}
            isLoading={isLoading}
            showLoaderBar={false}
            onSelectFromAllPYP={this.onSelectFromAllPYP}
            curriculumProgramType={curriculumProgramType}
          />
        </div>
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {
  updatePost,
};

const mapStateToProps = (state, ownProps) => {
  // const organizationId = state.login.userInfo.org_id;
  const courseIdFromParent = _.get(
    ownProps,
    "screenPanelProps.postDetails.courseId"
  );
  const courseId = courseIdFromParent
    ? courseIdFromParent
    : state.teacher.selected_class.selected_course;
  const curriculumProgramType = state.platform?.currentCurriculumProgramType;
  const {
    unitPlan = {},
    selectFromAllPYP,
    elementPYP = [],
  } = state.post.postDetails;

  let selectFromAllPYPNew = selectFromAllPYP;
  if (!selectFromAllPYP && !_.isEmpty(elementPYP) && _.isEmpty(unitPlan))
    selectFromAllPYPNew = true;

  const courseDetails = getCourseDetailsFromCache({ id: courseId });
  const currentAcademicYear = _.get(courseDetails, "academicYear", null);
  const startDate = moment(_.get(currentAcademicYear, "startDate", "")).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(_.get(currentAcademicYear, "endDate", "")).format(
    "YYYY-MM-DD"
  );
  const userType = state.login.userInfo.user_type;

  return {
    endDate,
    startDate,
    mode: state.post.postCreationMode,
    unitPlanId: _.get(unitPlan, "id", ""),
    templateId: _.get(unitPlan, "templateId", ""),
    courseId,
    unitPlan,
    elementPYP,
    selectFromAllPYP: selectFromAllPYPNew,
    curriculumProgramType,
    userId: state.login.userInfo.id,
    userType,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "journal"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseUnitQuery, {
    name: "getCourseUnit",
    options: ({ courseId, userType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        filters: {
          searchText: "",
          isShowPrivate: true,
          showAllPublishedUnits: userType === "student",
        },
        id: courseId,
        groupBy: "UNIT_TYPE",
      },
    }),
    props({ getCourseUnit, ownProps: { unitPlan } }) {
      const courseDetail = _.get(getCourseUnit, "node", {});
      let unitPlans = [];
      _.forEach(_.get(courseDetail, "unitPlans.edges", []), item => {
        if (!_.isEmpty(item.node)) {
          unitPlans = [
            ...(unitPlans || []),
            ...(_.get(item, "node", []) || []),
          ];
        }
      });

      return {
        unitPlans: !_.isEmpty(unitPlan)
          ? _.uniqBy([unitPlan, ...unitPlans], "id")
          : unitPlans,
        isData: !_.isEmpty(courseDetail),
        isLoading:
          getCourseUnit["networkStatus"] == 1 ||
          getCourseUnit["networkStatus"] == 2,
      };
    },
  })
)(TagUnit);
