import React, { PureComponent } from "react";

import classes from "./OrganizationModal.scss";

import { ProgressSteps, UIButton, UIModal } from "UIComponents";
import { CancelIcon, ChevronIcon } from "SvgComponents";
import { colors } from "Constants";
import { getCountString } from "Utils";
import Step from "./Step";

const Footer = React.memo(props => {
  const {
    onClose,
    onSaveClick,
    collaboratorToggle,
    doneButtonText,
    numberOfErrors,
    nextButtonName,
  } = props;

  return (
    <div className={classes.footerButtonContainer}>
      <div className={classes.footerLeftContainer}>
        {numberOfErrors > 0 ? (
          <div>
            {getCountString({
              count: numberOfErrors,
              singularText: "error",
              pluralText: "errors",
              postfix: "",
            })}
          </div>
        ) : null}
      </div>
      <div className={classes.footerRightContainer}>
        <div className={classes.leftFooterButton}>
          <UIButton
            color="grey"
            size="sm"
            onClick={onClose}
          >{`Cancel`}</UIButton>
        </div>
        <UIButton
          isDisabled={collaboratorToggle}
          color="pink"
          size="sm"
          onClick={onSaveClick}
        >
          {doneButtonText}
        </UIButton>
      </div>
    </div>
  );
});

const Header = React.memo(props => {
  const { showBack, title, onClose, onBackClicked } = props;
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerLeftContainer}>
        {/* <div className={classes.backButtonContainer}>
            {showBack && (
              <div className={classes.backButton} onClick={onBackClicked}>
                <span className={classes.backIcon}>
                  <ChevronIcon fill={colors.salmon60} />
                </span>
  
                {`Back`}
              </div>
            )}
          </div> */}
        {title && <div className={classes.titleText}>{title}</div>}
      </div>
      <div className={classes.cancelIcon} onClick={onClose}>
        <CancelIcon width={16} heigth={16} />
      </div>
    </div>
  );
});

class OrganizationModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      numberOfErrors: 0,
    };

    this.stepRefs = {};
  }

  onUpdateInputField = data => {
    this.setState(prevState => {
      return { data: { ...prevState.data, ...data }, numberOfErrors: 0 };
    });
  };

  customRef = ({ id, ref }) => {
    this.stepRefs[id] = ref;
  };

  getValue = key => {
    const { data } = this.state;

    switch (key) {
      case "domains":
        return data.domains || [];
      default:
        return _.get(data, key, "");
    }
  };

  valueValidation = () => {
    const { steps } = this.props;

    let errorCount = 0;

    _.forEach(steps, step => {
      const ref = this.stepRefs[step.ref];
      if (step.validationCheck && ref) {
        errorCount += ref.isValid();
      }
    });

    return errorCount;
  };

  renderSteps = () => {
    const { getOptions, steps } = this.props;

    return _.map(steps, step => {
      return (
        <Step
          id={step.id}
          label={step.label}
          error={step.error}
          customRef={this.customRef}
          name={step.name}
          multi={step.multi}
          allowAddOption={step.allowAddOption}
          onUpdateInputField={this.onUpdateInputField}
          placeholder={step.placeholder}
          type={step.type}
          value={this.getValue(step.id)}
          options={getOptions(step.id)}
          disabled={step.disabled}
        />
      );
    });
  };

  onSaveClick = () => {
    const { onSaveClick } = this.props;
    const { data } = this.state;
    const numberOfErrors = this.valueValidation();
    this.setState({ numberOfErrors: numberOfErrors });
    if (numberOfErrors === 0) {
      onSaveClick && onSaveClick(data);
    }
  };

  render() {
    const { onClose, onSaveClick, title, doneButtonText } = this.props;
    const { data, numberOfErrors } = this.state;
    return (
      <UIModal
        isOpen={true}
        isFullScreen={true}
        modalContent={classes.modalContent}
        onRequestClose={onClose}
      >
        <div className={classes.modalBody}>
          <Header
            title={title}
            onClose={onClose}
            showBack={false}
            onBackClicked={this.onBackClicked}
          />
          <div className={classes.list}>
            <div className={classes.listContent}>{this.renderSteps()}</div>
          </div>
        </div>

        <Footer
          doneButtonText={doneButtonText}
          onClose={onClose}
          onSaveClick={() => this.onSaveClick()}
          numberOfErrors={numberOfErrors}
        />
      </UIModal>
    );
  }
}

OrganizationModal.defaultProps = {
  doneButtonText: "Save",
};

export default OrganizationModal;
