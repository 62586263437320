import classNames from "classnames";
import { colors } from "Constants";
import React from "react";
import { ArrowIcon } from "SvgComponents";
import {
  Accordian,
  ButtonDropdown,
  HighlightedSubstringText,
  SearchBar,
} from "UIComponents";
import { Checkbox, EmptyState } from "@toddle-design/web";
import SubjectBenchmarks from "UnitPlans/components/IBcomponents/BenchmarkCheckList/SubjectBenchmarks";
import SearchFilter from "./SearchFilter";
import classes from "./TagLearningGoalsList.scss";
import styles from "./TagLearningGoalsListStyles";
import {
  getPYPElementsRowsFromField,
  getPYPElementsRowsFromUID,
  isEmpty,
  buildSlimTree,
  getUniqAssociatedParentsOfNodes,
  mergeAssociatedParentsWithNodes,
} from "Utils";
import _ from "lodash";
import { CHECKED, UNCHECKED, INTERMEDIATE } from "Constants/stringConstants";
import ACLStore from "lib/aclStore";
import { GoalIllustration } from "@toddle-design/theme";

const getAllSelectedCheckboxState = ({
  allSelectedPYPElements,
  selectedElementsPYP,
}) => {
  let checkboxState = UNCHECKED;

  if (
    _.isEqual(
      allSelectedPYPElements,
      _.map(selectedElementsPYP, item =>
        _.pick(item, ["fieldUID", "value", "parentType"])
      )
    )
  ) {
    checkboxState = CHECKED;
  } else if (!_.isEmpty(selectedElementsPYP)) {
    checkboxState = INTERMEDIATE;
  }
  return checkboxState;
};

const getAllSelectedCheckboxStateMemoize = _.memoize(
  params => getAllSelectedCheckboxState(params),
  params => JSON.stringify(params)
);

const emptyStateSubtitleStyle = {
  maxWidth: "300px",
  lineHeight: "24px",
};

class TagLearningGoalsList extends React.PureComponent {
  constructor(props) {
    super(props);
    const { updateRef, screenPanelProps } = this.props;
    if (updateRef) {
      updateRef(this);
    }
    const elementsPYP = _.get(screenPanelProps, "postDetails.elementPYP", []);
    const initialFields = this.getInitialFields(props);

    this.state = {
      selectedElementsPYP: elementsPYP ?? [],
      searchTerm: "",
      searchFilterData: [],
      selectedFilterValue: {},
      showSearchFilter: false,
      //
      initialFields,
      filteredFields: [],
      allSelectedPYPElements: this.getAllFilteredSelectedElements({
        initialFields,
      }),
    };
  }

  componentDidMount() {
    const { searchTerm, selectedFilterValue } = this.state;
    this.updateFields({ searchTerm, selectedFilterValue });
    // Will update the count in footer when updating post
    this.updateSelectedElementsCount();
  }

  getCurrentParentType = () => {
    const { selectFromAllPYP, parentType } = this.props;
    return selectFromAllPYP
      ? "ALL"
      : parentType == "UNIT_PLAN"
      ? "UNIT_PLAN"
      : "RESOURCE";
  };

  getAllFilteredSelectedElements = ({ initialFields }) => {
    const { taggableElements } = this.props;

    const selectedElementsPYP = [];
    _.forEach(taggableElements, taggableElement => {
      const fieldUID = taggableElement.value;
      const field = _.find(
        initialFields,
        field => _.get(field, "uid") == fieldUID
      );

      const nodes = _.get(field, "nodes");
      let value = [];
      if (taggableElement.isNestedView) {
        value = _.map(
          _.filter(
            nodes,
            node => node.isLeaf || _.isEmpty(_.get(node, "children"))
          ),
          item => item.id
        );
      } else {
        value = _.map(nodes, item => item.id);
      }

      if (!_.isEmpty(value)) {
        selectedElementsPYP.push({
          fieldUID: taggableElement.value,
          value,
          parentType: this.getCurrentParentType(),
        });
      }
    });

    return selectedElementsPYP;
  };

  toggleSelectAll = () => {
    const { selectedElementsPYP, allSelectedPYPElements } = this.state;
    if (!_.isEmpty(selectedElementsPYP)) {
      this.setState({ selectedElementsPYP: [] });
    } else {
      this.setState({
        selectedElementsPYP: allSelectedPYPElements,
      });
    }
    setTimeout(() => {
      this.updateSelectedElementsCount();
    }, 100);
  };

  onNextClick = () => {
    const {
      screenPanelProps: { goToNextScreen, updatePost },
    } = this.props;

    updatePost({
      elementPYP: this.updatedSelectedElements(),
    });

    goToNextScreen();
  };

  updatedSelectedElements = () => {
    const { curriculumType } = this.props;
    const { selectedElementsPYP, initialFields } = this.state;

    const updatedSelectedElements = [];
    _.forEach(selectedElementsPYP, item => {
      const fieldUID = _.get(item, "fieldUID");
      const field = _.find(
        initialFields,
        field => _.get(field, "uid") == fieldUID
      );

      const nodeKey = curriculumType == "IB_PYP" ? fieldUID : "nodes";

      updatedSelectedElements.push({
        ...(item || {}),
        resolvedMinimalTree: { [nodeKey]: _.get(field, "nodes") },
      });
    });

    return updatedSelectedElements;
  };

  togglePYPElement = (fieldUID, value) => {
    const { parentType, taggableElements, selectFromAllPYP } = this.props;
    const { selectedElementsPYP } = this.state;
    const taggablePYPElement = _.find(taggableElements, {
      value: fieldUID,
    });

    const currentParentType = this.getCurrentParentType();
    let selectedElementsPYPClone = _.cloneDeep(selectedElementsPYP);

    const selectedElement = _.find(selectedElementsPYPClone, { fieldUID });
    if (
      fieldUID === "action" &&
      selectedElement?.value?.length > 0 &&
      !ACLStore.can("FeatureFlag:EnableAction")
    ) {
      selectedElementsPYPClone = _.filter(
        selectedElementsPYPClone,
        item => item.fieldUID !== "action"
      );
    } else if (selectedElement) {
      const index = _.findIndex(selectedElementsPYPClone, { fieldUID });
      let ids = selectedElementsPYPClone[index].value;
      if (taggablePYPElement.isNestedView) {
        ids = value;
      }
      // If ID exists, remove it else add it (Toggling)
      else if (ids.includes(value)) ids = ids.filter(id => id !== value);
      else ids.push(value);
      selectedElementsPYPClone[index].value = ids;
    } else {
      value = taggablePYPElement.isNestedView ? value : [value];
      selectedElementsPYPClone.push({
        fieldUID,
        value,
        parentType: currentParentType,
      });
    }

    this.setState(
      {
        selectedElementsPYP: selectedElementsPYPClone,
      },
      this.updateSelectedElementsCount
    );
  };

  updateSelectedElementsCount = () => {
    const { updateSelectedElementsCount } = this.props;
    const { selectedElementsPYP } = this.state;

    let selectedCount = 0;
    _.forEach(selectedElementsPYP, item => {
      selectedCount += item.value.length;
    });
    updateSelectedElementsCount(selectedCount);
  };

  updateFields = ({ searchTerm, selectedFilterValue }) => {
    const { initialFields } = this.state;
    const filteredFields = _.map(initialFields, field => {
      const rows = getPYPElementsRowsFromField({
        uid: field.uid,
        searchTerm,
        selectedFilterValue,
        initialFields,
      });
      return {
        uid: field.uid,
        rows,
      };
    });

    this.setState({
      filteredFields,
      searchTerm,
      selectedFilterValue,
      showSearchFilter: false,
    });
  };

  setNestedState = (uid, subjectId, selections) => {
    let { nestedState } = this.getNestedElementData({ uid });
    nestedState = {
      ...nestedState,
      [subjectId]: selections,
    };
    const benchmarkStateArray = _.map(nestedState, item => item);
    this.togglePYPElement(uid, _.flatten(benchmarkStateArray));
  };

  processTaggableNodes = ({ field, taggableElement }) => {
    const key =
      taggableElement?.value === "lp"
        ? "learnerProfiles"
        : taggableElement?.value;

    const nodes =
      _.get(field, [
        "resolvedMinimalTree",
        key === "action" && ACLStore.can("FeatureFlag:EnableAction")
          ? "actions"
          : key,
      ]) ||
      _.get(field, ["resolvedMinimalTree", "nodes"]) ||
      _.get(field, "nodes") ||
      [];
    let updatedNodes = _.cloneDeep(nodes);

    if (!_.get(taggableElement, "isSubjectSpecific")) {
      return { nodes, updatedNodes };
    }

    let associatedParentNodes = getUniqAssociatedParentsOfNodes({ nodes });
    associatedParentNodes = _.filter(
      associatedParentNodes,
      item => _.get(item, "__typename") == "Subject"
    );

    updatedNodes = mergeAssociatedParentsWithNodes({
      associatedParentNodes,
      nodes: updatedNodes,
    });

    return { nodes, updatedNodes };
  };

  getInitialFields = ({
    fields,
    benchmarkData,
    taggableElements,
    plannerElements,
    subjectPlannerElements,
    subjectGradePlannerElements,
    gradePlannerElements,
    curriculumType,
    selectFromAllPYP,
  }) => {
    const newFields = _.filter(
      [
        ...(fields || []),
        selectFromAllPYP
          ? {
              uid: "benchmarks",
              resolvedMinimalTree: {
                benchmarks: benchmarkData,
              },
            }
          : null,
      ],
      item => !!item
    );
    const filteredInitialFields = [];
    const combinePlannerElements = [
      ...(subjectPlannerElements || []),
      ...(subjectGradePlannerElements || []),
      ...(gradePlannerElements || []),
      ...(plannerElements || []),
    ];
    _.forEach(taggableElements, taggableElement => {
      if (selectFromAllPYP && curriculumType != "IB_PYP") {
        const field = _.find(combinePlannerElements, {
          type: taggableElement.elementType,
        });
        const { nodes, updatedNodes } = this.processTaggableNodes({
          field,
          taggableElement,
        });
        filteredInitialFields.push({
          id: field.id,
          uid: taggableElement.value,
          resolvedMinimalTree: {
            nodes: updatedNodes,
          },
          nodes,
          isNestedView: taggableElement.isNestedView,
        });
      } else {
        const field = _.find(newFields, { uid: taggableElement.value });
        const { nodes, updatedNodes } = this.processTaggableNodes({
          field,
          taggableElement,
        });
        if (field)
          filteredInitialFields.push({
            ...field,
            resolvedMinimalTree: {
              nodes: updatedNodes,
            },
            nodes,
            isNestedView: taggableElement.isNestedView,
          });
      }
    });

    return filteredInitialFields;
  };

  getSelectedCount = uid => {
    const { selectedElementsPYP } = this.state;
    let selectedCount = 0;
    const selectedElementPYP = _.find(selectedElementsPYP, {
      fieldUID: uid,
    });
    if (selectedElementPYP) {
      selectedCount = selectedElementPYP.value?.length;
    }
    return selectedCount;
  };

  onSearchChange = searchTerm => {
    this.setState({ searchTerm });
  };

  getNestedElementData = ({ uid }) => {
    const { selectedElementsPYP, initialFields, filteredFields } = this.state;
    const { taggableElements } = this.props;
    const taggablePYPElement = _.find(taggableElements, {
      value: uid,
    });
    if (!taggablePYPElement) return {};

    const nestedNodes = getPYPElementsRowsFromUID({
      uid: uid,
      applySearchFilter: false,
      applyDropdownFilter: false,
      initialFields,
      filteredFields,
    });
    if (_.isEmpty(nestedNodes)) return {};

    const rootNodes = _.filter(nestedNodes, {
      depth: taggablePYPElement.startDepth,
    });
    const nestedState = {};

    _.forEach(rootNodes, rootNode => {
      let nodes = [];
      nodes = buildSlimTree({
        nodes: nestedNodes,
        selIds: [rootNode.id],
      });
      const selectedValues = _.intersection(
        _.get(_.find(selectedElementsPYP, { fieldUID: uid }), "value", []),
        _.map(nodes, item => item.id)
      );
      nestedState[rootNode.id] = selectedValues;
    });

    return {
      nestedState,
      rootNodes,
    };
  };

  renderNestedElement = ({ uid } = {}) => {
    const {
      selectedElementsPYP,
      searchTerm,
      selectedFilterValue,
      initialFields,
      filteredFields,
    } = this.state;
    const { t, taggableElements } = this.props;
    const taggablePYPElement = _.find(taggableElements, {
      value: uid,
    });
    if (!taggablePYPElement) return null;

    const benchmarks = getPYPElementsRowsFromUID({
      uid: uid,
      applySearchFilter: true,
      applyDropdownFilte: true,
      initialFields,
      filteredFields,
    });
    if (_.isEmpty(benchmarks)) return null;

    let selectedCount = 0;
    const selectedElementPYP = _.find(selectedElementsPYP, {
      fieldUID: uid,
    });
    if (selectedElementPYP) {
      selectedCount = selectedElementPYP?.value?.length;
    }

    // When searching don't show Accordian
    if (_.isEmpty(searchTerm))
      return (
        <Accordian
          key={uid}
          title={
            taggablePYPElement.localisedLabel || t(taggablePYPElement.label)
          }
          count={selectedCount}
          getExpandedContent={() => this.getExpandedNestedElement({ uid })}
          hideContainerBorder={true}
          showAccordian={_.isEmpty(searchTerm)}
          customTitleStyle={styles.mainHeaderStyles}
          isExpanded={!_.isEmpty(selectedFilterValue)}
          mainContentContainerStyle={styles.mainContentContainerStyle}
        />
      );
    else
      return (
        <div className={classes.onSearchSectionContainer} key={uid}>
          <div className={classes.onSearchSectionHeader}>
            {taggablePYPElement.localisedLabel || t(taggablePYPElement.label)}
          </div>
          {this.getExpandedNestedElement({ uid })}
        </div>
      );
  };

  getExpandedNestedElement = ({ uid }) => {
    const {
      searchTerm,
      selectedFilterValue,
      initialFields,
      filteredFields,
    } = this.state;
    const { taggableElements } = this.props;
    const taggablePYPElement = _.find(taggableElements, {
      value: uid,
    });
    if (!taggablePYPElement) return null;

    const benchmarks = getPYPElementsRowsFromUID({
      uid: uid,
      applySearchFilter: true,
      applyDropdownFilte: true,
      initialFields,
      filteredFields,
    });
    if (_.isEmpty(benchmarks)) return null;

    const roots = _.filter(benchmarks, {
      depth: taggablePYPElement.startDepth,
    });
    const { nestedState } = this.getNestedElementData({ uid });
    let nodesComponents = [];

    nodesComponents = _.map(roots, (subject, index) => {
      const { id, label } = subject;
      let nodes = [];
      nodes = buildSlimTree({
        nodes: benchmarks,
        selIds: [subject.id],
      });
      const rootNodes = _.map(
        _.filter(
          nodes,
          item => item.depth === taggablePYPElement.startDepth + 1
        ),
        node => node.id
      );

      return (
        <SubjectBenchmarks
          key={id}
          selectedValues={nestedState[subject.id]}
          subject={{
            value: subject,
            label: label,
            id: id,
            subjectId: subject.subject,
          }}
          nodes={nodes}
          rootNodes={rootNodes}
          onItemSelected={data => {
            this.setNestedState(
              uid,
              data.subject.id,
              _.get(data, "selections", [])
            );
          }}
          isReverseCheckbox={true}
          hideContainerBorder={true}
          showLabelBorder={index !== roots.length - 1}
          showAccordian={!searchTerm}
          showCountSeperate={true}
          checkboxItemStyle={styles.checkboxItemStyle}
          customTitleStyle={styles.customTitleStyle}
          nodeSpanStyle={styles.nodeSpanStyle}
          isExpanded={!_.isEmpty(selectedFilterValue)}
        />
      );
    });

    return nodesComponents;
  };

  renderSections = () => {
    const { t, taggableElements } = this.props;
    const {
      searchTerm,
      selectedFilterValue,
      initialFields,
      filteredFields,
    } = this.state;
    const taggablePYPElements = taggableElements;

    const sections = [];
    _.forEach(taggablePYPElements, TAGGABLE_PYP_ELEMENT => {
      if (TAGGABLE_PYP_ELEMENT.isNestedView) {
        sections.push(
          this.renderNestedElement({ uid: TAGGABLE_PYP_ELEMENT.value })
        );
        return;
      }

      const rows = getPYPElementsRowsFromUID({
        uid: TAGGABLE_PYP_ELEMENT.value,
        initialFields,
        filteredFields,
      });
      if (_.isEmpty(rows)) return;

      const selectedCount = this.getSelectedCount(TAGGABLE_PYP_ELEMENT.value);
      const expandedContent = this.renderRows(TAGGABLE_PYP_ELEMENT.value);

      sections.push(
        <div
          key={TAGGABLE_PYP_ELEMENT.value}
          className={classes.goalGroupContainer}
        >
          {_.isEmpty(searchTerm) ? (
            <Accordian
              title={
                TAGGABLE_PYP_ELEMENT.localisedLabel ||
                t(TAGGABLE_PYP_ELEMENT.label)
              }
              count={selectedCount}
              expandedContent={expandedContent}
              hideContainerBorder={true}
              showAccordian={_.isEmpty(searchTerm)}
              customTitleStyle={styles.mainHeaderStyles}
              isExpanded={!_.isEmpty(selectedFilterValue)}
            />
          ) : (
            <div className={classes.onSearchSectionContainer}>
              <div className={classes.onSearchSectionHeader}>
                {/* localised labels are the labels which will be coming from the database and hance we dont need to localise it
                    in the below localised label will be only available when isDynamicPlannerElement is true else there will
                     be no key of localised label
                */}
                {TAGGABLE_PYP_ELEMENT.localisedLabel ||
                  t(TAGGABLE_PYP_ELEMENT.label)}
              </div>
              {expandedContent}
            </div>
          )}
        </div>
      );
    });

    return sections;
  };

  renderRows = uid => {
    const {
      selectedElementsPYP,
      searchTerm,
      initialFields,
      filteredFields,
    } = this.state;
    const rows = getPYPElementsRowsFromUID({
      uid,
      initialFields,
      filteredFields,
    });

    return _.map(rows, row => {
      const isChecked = _.find(selectedElementsPYP, {
        fieldUID: uid,
      })?.value?.includes(row.id);

      return (
        <div
          key={row.id}
          className={classes.pypElementRowContainer}
          onClick={() => this.togglePYPElement(uid, row.id)}
        >
          <div className={classes.pypElementText}>
            {_.isEmpty(searchTerm) ? (
              row.label
            ) : (
              <HighlightedSubstringText
                text={row.label}
                highlight={searchTerm}
              />
            )}
          </div>
          <div className={classes.checkBoxContainer}>
            {this.renderCheckbox(isChecked)}
          </div>
        </div>
      );
    });
  };

  setSearchFocusState = state => {
    this.setState({ searchFocused: state });
  };

  renderCheckbox = isChecked => {
    return <Checkbox isChecked={isChecked} />;
  };

  renderSearchBar = () => {
    const { t, taggableElements } = this.props;
    const {
      searchTerm,
      selectedFilterValue,
      searchFocused,
      initialFields,
      filteredFields,
    } = this.state;

    // Search filter label
    let selectedFilterLabel = t("journal:all_pyp_elements");
    if (!_.isEmpty(selectedFilterValue)) {
      const taggableElement = _.find(taggableElements, {
        value: selectedFilterValue?.uid,
      });
      selectedFilterLabel = taggableElement.isNestedView
        ? `${selectedFilterValue.subTopicLabel}`
        : taggableElement.localisedLabel || t(taggableElement?.label);
    }

    // Search filter data
    const filteredTaggablePYPElements = [];
    _.forEach(taggableElements, TAGGABLE_PYP_ELEMENT => {
      const { value, isNestedView } = TAGGABLE_PYP_ELEMENT;
      // Don't show action in filter options
      if (["action"].includes(value)) return;

      if (isNestedView) {
        const { rootNodes } = this.getNestedElementData({
          uid: value,
        });
        if (!_.isEmpty(rootNodes))
          filteredTaggablePYPElements.push({
            ...TAGGABLE_PYP_ELEMENT,
            rootNodes,
          });
        return;
      }
      const rows = getPYPElementsRowsFromUID({
        uid: TAGGABLE_PYP_ELEMENT.value,
        applySearchFilter: false,
        applyDropdownFilter: false,
        initialFields,
        filteredFields,
      });
      if (!_.isEmpty(rows))
        filteredTaggablePYPElements.push(TAGGABLE_PYP_ELEMENT);
    });

    const isSearbarActive = searchFocused || !_.isEmpty(searchTerm);
    const searchBarStyle = classNames(
      { [classes.searchBar]: true },
      { [classes.searchBarActive]: isSearbarActive }
    );
    const searchBarContainerStyle = classNames(
      { [classes.searchBarContainer]: true },
      { [classes.searchBarMargin]: !_.isEmpty(searchTerm) }
    );
    return (
      <div className={searchBarContainerStyle}>
        <div className={searchBarStyle}>
          <SearchBar
            placeholder={t("journal:search_learning_goal")}
            searchTerm={searchTerm}
            changeSearchTerm={searchTermParam =>
              this.updateFields({
                searchTerm: searchTermParam,
                selectedFilterValue,
              })
            }
            customStyle={styles.searchCustomStyle}
            activeCustomStyle={styles.searchActiveCustomStyle}
            showCancelIcon={true}
            debounceDuration={300}
            onFocus={() => this.setSearchFocusState(true)}
            onBlur={() => this.setSearchFocusState(false)}
          />
        </div>
        <ButtonDropdown
          shouldCloseOnSelfClick={true}
          closeOnOutSideClick={true}
          buttonComponent={
            <div style={styles.searchButtonContainer}>
              <div style={styles.searchFilterText}>{selectedFilterLabel}</div>
              <div style={styles.arrowIcon}>
                <ArrowIcon width={12} height={6} fill={colors.gray48} />
              </div>
            </div>
          }
          buttonParentStyle={styles.buttonParentStyle}
          buttonComponentStyle={styles.buttonComponentStyle}
          parentContainerStyle={styles.searchFilterContainer}
          dropdownComponent={
            <SearchFilter
              searchFilterData={filteredTaggablePYPElements}
              selectedFilterValue={selectedFilterValue}
              setSelectedFilterValue={selectedFilterValueParam =>
                this.updateFields({
                  selectedFilterValue: selectedFilterValueParam,
                  searchTerm,
                })
              }
            />
          }
        />
      </div>
    );
  };

  renderSelectAll = () => {
    const { t } = this.props;
    const { allSelectedPYPElements, selectedElementsPYP } = this.state;
    const checkboxState = getAllSelectedCheckboxStateMemoize({
      allSelectedPYPElements,
      selectedElementsPYP,
    });
    const isChecked = checkboxState === "checked";
    const isIndeterminate = checkboxState === "intermediate";

    return (
      <div
        className={classes.allSelectCheckBoxRow}
        onClick={this.toggleSelectAll}
      >
        <div className={classes.selectAllText}>{t("common:select_all")}</div>
        <div className={classes.checkBoxContainer}>
          <Checkbox isChecked={isChecked} isIndeterminate={isIndeterminate} />
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { selectedFilterValue, searchTerm } = this.state;
    const sections = this.renderSections();
    const emptyLearningGoals = isEmpty(sections);

    return (
      <div className={classes.container}>
        {!(emptyLearningGoals && !searchTerm) && this.renderSearchBar()}
        {emptyLearningGoals ? (
          searchTerm ? (
            <div className={classes.noElementText}>
              {t("journal:no_learning_goals_found")}
            </div>
          ) : (
            <div className={classes}>
              <EmptyState
                illustration={GoalIllustration}
                title={t("classRoom:nothing_here_yet")}
                subtitle={t("journal:learning_goals_empty_text")}
                size="small"
                subtitleStyle={emptyStateSubtitleStyle}
              />
            </div>
          )
        ) : (
          <React.Fragment>
            {_.isEmpty(selectedFilterValue) ? this.renderSelectAll() : null}
            {sections}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TagLearningGoalsList;
