import PropTypes from "prop-types";
import React from "react";

const ReplyIconSvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <path
          d="M20.2170136,8.77118552 L4.77355166,8.77118552 L10.5091618,2.11424434 L8.74585575,0.0676923077 L0,10.2183529 L8.74579337,20.3690136 L10.5090994,18.3224615 L4.77355166,11.6655204 L20.217076,11.6655204 C25.3047641,11.6655204 29.4439298,16.4695747 29.4439298,22.3745158 C29.4439298,25.1517104 28.2339181,27.5117466 27.2188382,29.0024253 L26.4457856,30.1377738 L28.4023392,31.9323801 L29.1753918,30.7970317 C30.4355556,28.9463167 31.9377466,25.9827692 31.9377466,22.3745158 C31.9376218,14.8735566 26.6797661,8.77118552 20.2170136,8.77118552 Z"
          id="Path"
        ></path>
      </g>
    </svg>
  );
};

ReplyIconSvg.defaultProps = {
  width: 20,
  height: 20,
};

ReplyIconSvg.propTypes = {
  fill: PropTypes.string,
  scale: PropTypes.number,
};

export default ReplyIconSvg;
