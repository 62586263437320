import React from "react";
import classes from "./SubmissionTable.scss";
import { UIBaseComponent, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FieldComponentContext } from "Projects/modules/ProjectModules";
import FieldComponent from "../FieldComponent";
import { downloadAttachmentZip } from "modules/Services";
import { IconButton } from "@toddle-design/web";
import { DownArrowOutlined } from "@toddle-design/web-icons";
import classNames from "classnames";
import { generateRandomId } from "Utils";

class SubmissionTable extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  updateInputFieldLocally = ({ params, extraParams }) => {
    const { updateInputField } = this.props;
    updateInputField(params, extraParams);
  };

  onDownloadClick = async ({ fields, downloadIds, zipFileName }) => {
    const { downloadAttachmentZip } = this.props;

    let attachments = [];

    _.map(downloadIds, downloadId => {
      const fieldData = _.find(fields, { uid: downloadId });
      const value = _.get(fieldData, "value", []);
      attachments = _.concat(attachments, value ? value : []);
    });

    const input = {
      attachmentsObjects: {
        _: {
          attachments,
          folderId: generateRandomId(),
          folderName: "Draft Files",
        },
      },
      showAttachmentName: true,
      zipFileName,
    };
    if (!_.isEmpty(attachments)) {
      await downloadAttachmentZip(input);
    }
  };

  renderActions = ({ fields, actionId, actionConfig }) => {
    switch (actionId) {
      case "download": {
        const { downloadIds = [], fileName = "download" } = actionConfig;
        return (
          <IconButton
            onClick={() =>
              this.onDownloadClick({
                fields,
                downloadIds,
                zipFileName: fileName,
              })
            }
            icon={<DownArrowOutlined />}
            variant={"outlined-subtle"}
            size={"medium"}
          />
        );
      }
      default:
        return null;
    }
  };

  renderEdit = () => {
    const {
      tableConfig = {},
      childrenList = [],
      mode,
      showSingleAttachment,
      getFieldValue,
      getUpdatedConfig,
      helpEnabledFields,
      getIsHelpActive,
      onHelpClick,
      t,
      updateSelectedTemplate,
    } = this.props;

    const {
      columnIds = [],
      columnList = {},
      rowIds = [],
      rowList = {},
      gridRowStyle = {},
    } = tableConfig;

    const rowSubtextStyles = classNames(
      { ["text-body-2"]: true },
      { [classes.rowSubtext]: true }
    );

    return (
      <FieldComponentContext.Consumer>
        {({ fields }) => {
          return (
            <div className={classes.container}>
              <div className={classes.tableHeader} style={gridRowStyle}>
                {_.map(columnIds, columnId => {
                  const columnData = columnList[columnId];

                  const { actionList, label = "" } = columnData;
                  return (
                    <div
                      key={columnId}
                      className={classes.headerLabelContainer}
                    >
                      <div className="text-label-2"> {t(label)} </div>
                      {_.map(actionList, action => {
                        const { actionId, actionConfig = {} } = action;
                        return this.renderActions({
                          fields,
                          actionId,
                          actionConfig,
                        });
                      })}
                    </div>
                  );
                })}
              </div>
              <div className={classes.tableBody}>
                {_.map(rowIds, rowId => {
                  const rowData = rowList[rowId];
                  const rowDataColumnIds = _.get(rowData, "columnIds", []);
                  const { label = "", subtext = "" } = rowData;
                  return (
                    <div
                      key={rowId}
                      className={classes.tableRow}
                      style={gridRowStyle}
                    >
                      <div className={classes.rowLabelContainer}>
                        <div className="text-label-2"> {t(label)} </div>
                        <div className={rowSubtextStyles}>
                          {subtext && t(subtext)}
                        </div>
                      </div>
                      {_.map(rowDataColumnIds, dataId => {
                        const fieldDetails = _.find(childrenList, {
                          id: dataId,
                        });
                        const fieldData = _.find(fields, { uid: dataId });

                        const { id: fieldId } = fieldData;

                        const {
                          config = {},
                          type,
                          id,
                          disabled = false,
                        } = fieldDetails;

                        const updateConfig = getUpdatedConfig({
                          config,
                          fielduid: id,
                        });

                        const value = getFieldValue({
                          type,
                          fielduid: id,
                        });

                        return (
                          <FieldComponent
                            {...updateConfig}
                            name={id}
                            key={id}
                            customRef={this.setFieldRef}
                            mode={mode}
                            fieldId={fieldId}
                            disabled={disabled}
                            fieldKey={id}
                            type={type}
                            value={value}
                            showSingleAttachment={showSingleAttachment}
                            updateInputField={this.updateInputFieldLocally}
                            getFieldValue={getFieldValue}
                            getUpdatedConfig={getUpdatedConfig}
                            helpEnabledFields={helpEnabledFields}
                            getIsHelpActive={getIsHelpActive}
                            onHelpClick={onHelpClick}
                            updateSelectedTemplate={updateSelectedTemplate}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </FieldComponentContext.Consumer>
    );
  };
}

SubmissionTable.defaultProps = {
  ...UIBaseComponent.defaultProps,
  showSingleAttachment: false,
};

const mapActionCreators = {
  downloadAttachmentZip,
};

export default compose(
  I18nHOC({ resource: ["common", "project"] }),
  withRouter,
  connect(null, mapActionCreators)
)(SubmissionTable);
