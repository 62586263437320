import client from "apolloClient";

import { removeChildForFamilyMemberMutation } from "./ParentSettingsMutation";
import { getChildrenQuery } from "modules/CommonQuery";

export const removeChildForFamilyMember = function ({ childId, userId }) {
  return async function (dispatch, getState) {
    try {
      await client.mutate({
        mutation: removeChildForFamilyMemberMutation,
        variables: { studentId: childId },
        refetchQueries: [
          {
            query: getChildrenQuery,
            variables: {
              id: userId,
              type: "FAMILY_MEMBER",
              portalType: "PLANNER",
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (e) {
      console.error(e);
    }
  };
};
