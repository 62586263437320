import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import StudentViewDetails from "./routes/StudentViewDetails";
import SubjectViewDetails from "./routes/SubjectViewDetails";
import Insights from "./routes/Insights";
import { getRelativePath } from "Utils";

export default store => ({
  path: ":reportId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ProgressReport/modules/ProgressReportModule")
              .default;
            injectReducer(store, { key: "progressReport", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-progressreport"
        );
      })
  ),
  onEnter(nextState, replace) {
    if (_.last(nextState.routes).path == ":reportId") {
      replace(getRelativePath("./student-view"));
    }
  },
  childRoutes: [
    StudentViewDetails(store),
    SubjectViewDetails(store),
    Insights(store),
  ],
});
