import gql from "graphql-tag";
import { leFragment } from "LearningEngagement/modules/LeFragments";
import { assessmentFragment } from "Assessments/modules/AssessmentFragments";
import {
  yearlyItemFragment,
  unitCardFragment,
  courseSchedulerFragment,
} from "./SchedulerFragment";

export const getLeDetailsCardQuery = gql`
  query getLeDetailCard($id: ID!) {
    node(id: $id, type: LEARNING_ENGAGEMENT) {
      id
      ... on LearningEngagement {
        ...leItem
      }
    }
  }
  ${leFragment.leSchedulerCard}
`;

export const getAssessmentDetailsCardQuery = gql`
  query getAssessmentDetailsCard($id: ID!) {
    node(id: $id, type: ASSESSMENT) {
      id
      ... on Assessment {
        ...assessmentItem
      }
    }
  }
  ${assessmentFragment.assessmentSchedulerCard}
`;

export const getUnitPlanListQuery = gql`
  query getUnitPlanList($id: ID!, $uids: [String!], $isMYP: Boolean = false) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        id
        unitPlans {
          edges {
            fieldValue
            type
            node {
              ...unitCardItem
            }
          }
        }
      }
    }
  }
  ${unitCardFragment.scheduledUnitCard}
`;

export const getUnitPlanResourceSelectionFeedQuery = gql`
  query getUnitPlanList($id: ID!, $grade: String) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        unitPlans(filters: { grade: $grade }) {
          edges {
            fieldValue
            type
            node {
              ...unitCardItem
            }
          }
        }
      }
    }
  }
  ${unitCardFragment.unitCard}
`;

export const getYearlySchedulerQuery = gql`
  query getYearlyScheduler(
    $id: ID!
    $uids: [String!]
    $endDate: String!
    $searchText: String
    $startDate: String!
    $isMYP: Boolean = false
    $detailedUnitPlan: Boolean = false
    $unitPlanTypes: [ID!]
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        id
        yearlySchedule(
          filters: {
            date: { startDate: $startDate, endDate: $endDate }
            searchText: $searchText
            unitTypes: $unitPlanTypes
          }
        ) {
          ...itemNode
        }
      }
    }
  }
  ${yearlyItemFragment.yearlyItem}
`;

export const getWeeklySchedulerQuery = gql`
  query getWeeklyScheduler($id: ID!, $startDate: String!, $endDate: String!) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseSchedulerFragment.courseWeeklyScheduler}
`;
