import React, { Fragment } from "react";
import { SearchBar, ButtonDropdown, I18nHOC } from "UIComponents";
import classes from "./SearchSelect.v2.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { fontStyle, colors } from "Constants";
import classNames from "classnames";
import GridCard from "./GridCard";
import ListCard from "./ListCard";
import NoDataComponent from "../NoDataComponent";
import { compose } from "react-apollo";

const customStyles = {
  buttonDropdown: {
    button: { width: "100%" },
    dropdown: {
      width: "100%",
      padding: "16px",
      position: "absolute",
      top: "calc(100% - 4px)",
      maxHeight: "240px",
      overflowY: "auto",
    },
  },
};
class SearchSelect extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      searchText: "",
    };
  }

  filterOptions = ({ filter } = {}) => {
    const { options, value } = this.props;
    const { searchText } = this.state;
    let filteredOptions = [...options];

    if (filter) {
      filteredOptions = _.filter(filteredOptions, item => {
        if (filter === "SELECTED") return _.includes(value, item.id);
        else if (filter === "NOT_SELECTED") return !_.includes(value, item.id);
      });
    }

    if (searchText)
      filteredOptions = _.filter(
        filteredOptions,
        item =>
          _.includes(_.toLower(item.label), _.toLower(searchText)) ||
          _.includes(_.toLower(item.value), _.toLower(searchText))
      );

    return filteredOptions;
  };

  onSearchChange = value => {
    const { onSearchChange } = this.props;
    this.setState({ searchText: value });
    if (onSearchChange) onSearchChange(value);
  };

  toggleItem = (item, checked) => {
    const { options, value } = this.props;
    const updatedValue = checked
      ? _.filter(value, id => id != item.id)
      : [...value, item.id];

    this.updateValue(updatedValue, options);
  };

  searchComponent = () => {
    const { placeholder, styles = {}, viewMode } = this.props;

    const searchContainerClasses = classNames(
      {
        [classes.searchContainer]: true,
      },
      {
        [classes.searchContainerContacts]: viewMode === "GRID_CARD",
      }
    );
    return (
      <div className={searchContainerClasses}>
        <SearchBar
          placeholder={placeholder}
          changeSearchTerm={this.onSearchChange}
        />
      </div>
    );
  };

  getNoResultText = () => {
    const { getNoResultText, noDataText } = this.props;
    const { searchText } = this.state;
    if (getNoResultText) {
      return getNoResultText({ searchText });
    }
    return noDataText;
  };

  resultComponent = ({ filter, isButtonDropdown } = {}) => {
    const { viewMode, value = [], gridActionType, t } = this.props;
    const filteredOptions = this.filterOptions({ filter });

    const listResultClasses = classNames({
      [classes.listResultContainer]: true,
      [classes.buttonDropdown]: isButtonDropdown,
    });

    if (_.get(filteredOptions, "length", 0) == 0) {
      return (
        <NoDataComponent
          emptyText={this.getNoResultText()}
          emptyHeaderText={""}
          containerStyle={{ flex: "1 1 240px" }}
        />
      );
    }

    if (viewMode === "LIST_CARD")
      return (
        <div className={listResultClasses}>
          {_.map(filteredOptions, item => (
            <ListCard
              checked={filter === "SELECTED"}
              key={item.key}
              item={item}
              onClick={this.toggleItem}
            />
          ))}
          {!filteredOptions.length && filter === "NOT_SELECTED" && (
            <div className={classes.noResult}>No results.</div>
          )}
        </div>
      );

    if (viewMode === "GRID_CARD")
      return (
        <div className={classes.gridResultContainer}>
          {_.map(filteredOptions, item => (
            <GridCard
              key={item.key}
              item={item}
              onClick={this.toggleItem}
              checked={_.includes(value, item.id)}
              gridActionType={gridActionType}
              t={t}
            />
          ))}
        </div>
      );
  };

  renderEdit = () => {
    const { viewMode } = this.props;

    return (
      <div className={classes.container}>
        {viewMode === "LIST_CARD" ? (
          <Fragment>
            <ButtonDropdown
              shouldCloseOnButtonClick={false}
              shouldCloseOnSelfClick={false}
              buttonParentStyle={customStyles.buttonDropdown.button}
              containerStyle={customStyles.buttonDropdown.dropdown}
              buttonComponent={this.searchComponent()}
              dropdownComponent={this.resultComponent({
                filter: "NOT_SELECTED",
                isButtonDropdown: true,
              })}
            />
            {this.resultComponent({ filter: "SELECTED" })}
          </Fragment>
        ) : (
          <Fragment>
            {this.searchComponent()}
            {this.resultComponent()}
          </Fragment>
        )}
      </div>
    );
  };
}

SearchSelect.propTypes = {
  ...UIBaseComponent.propTypes,
  // viewMode: React.PropTypes.oneOfType(["LIST_CARD", "GRID_CARD"])
};

SearchSelect.defaultProps = {
  ...UIBaseComponent.defaultProps,
  viewMode: "GRID_CARD",
  gridActionType: "TICK",
  options: [],
  noDataText: "",
};

export default compose(
  I18nHOC({
    resource: ["common"],
  })
)(SearchSelect);
