import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import UnitPlanDetails from "./routes/UnitPlanDetails";
import UnitPlanCreate from "./routes/UnitPlanCreate";
import YearlyInsight from "YearlyInsight";
import CourseUnitPlansOverview from "./routes//CourseUnitPlansOverview";
import CourseUnitPlansTimelineTable from "./routes/CourseUnitPlansTimelineTable";
import CardView from "./routes/CardView";

export default store => ({
  path: "unitPlans",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const plannerReducer = require("IBPlanner/modules/IBPlannerModule")
              .default;
            injectReducer(store, { key: "planner", reducer: plannerReducer });

            const courseOverviewReducer = require("./routes/CourseUnitPlansOverview/modules/Module")
              .default;
            injectReducer(store, {
              key: "courseOverview",
              reducer: courseOverviewReducer,
            });

            const multiLevelNodeEditorReducer = require("MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule");
            injectReducer(store, {
              key: "multiLevelNodeEditor",
              reducer: multiLevelNodeEditorReducer.default,
            });

            const subjectSetupReducer = require("SubjectsSetup/modules/SubjectsModule")
              .default;
            injectReducer(store, {
              key: "subjectsSetup",
              reducer: subjectSetupReducer,
            });

            const schedulerReducer = require("Scheduler/modules/SchedulerModule")
              .default;
            injectReducer(store, {
              key: "scheduler",
              reducer: schedulerReducer,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "unitPlans"
        );
      })
  ),
  childRoutes: [
    CourseUnitPlansOverview(store),
    CourseUnitPlansTimelineTable(store),
    CardView(store),
    UnitPlanCreate(store),
    YearlyInsight(store),
    UnitPlanDetails(store),
  ],
});
