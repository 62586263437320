import _ from "lodash";
import { push } from "react-router-redux";
import update from "immutability-helper";
import client from "apolloClient";
import { sendCourseStudentsFamilyInviteCodeToTeacherMutation } from "CourseSetup/modules/Mutations";
import { getStudentFamilyDetails } from "StudentSetup/modules/GraphqlHelpers";
import { setToastMsg } from "Login/modules/LoginModule";
import { studentFragments } from "StudentSetup/modules/StudentFragments";
import { resetPasswordMutation } from "modules/CommonMutations";
export const NAME = "adminCommon";
export const CHANGE_SEARCH_TERM = "CHANGE_SEARCH_TERM" + " " + NAME;
export const RESET_ITEM = "RESET_ITEM" + " " + NAME;
export const UPDATE_SELECTED_LIST = "UPDATE_SELECTED_LIST" + " " + NAME;
export const UPDATE_LOADING = "UPDATE_LOADING" + " " + NAME;
export const INIT_SELECTED_LIST = "INIT_SELECTED_LIST" + " " + NAME;
export const SET_EXCLUDED_COURSEIDS = "SET_EXCLUDED_COURSEIDS" + " " + NAME;
export const SET_EXCLUDED_IDS = "SET_EXCLUDED_IDS" + " " + NAME;
export const UPDATE_FAMILY_EMAILS = "UPDATE_FAMILY_EMAILS" + " " + NAME;
export const INIT_FAMILY_EMAILS = "INIT_FAMILY_EMAILS" + " " + NAME;
export const TOGGLE_ACTION_LOADING = "TOGGLE_ACTION_LOADING" + " " + NAME;

// PURE ACTIONS

export const changeSearchTerm = value => {
  return { type: CHANGE_SEARCH_TERM, payload: value };
};

export const setExcludedCourseIds = value => {
  return { type: SET_EXCLUDED_COURSEIDS, payload: value };
};

export const setExcludedIds = value => {
  return { type: SET_EXCLUDED_IDS, payload: value };
};

export const resetItem = value => {
  return { type: RESET_ITEM, payload: value };
};

export const toggleActionLoading = data => {
  return {
    type: TOGGLE_ACTION_LOADING,
    data,
  };
};

export const updateSelectedList = data => {
  return {
    type: UPDATE_SELECTED_LIST,
    payload: data,
  };
};

export const updateLoading = data => {
  return {
    type: UPDATE_LOADING,
    payload: data,
  };
};

export const initSelectedList = data => {
  return {
    type: INIT_SELECTED_LIST,
  };
};

export const updateFamilyEmails = data => {
  return {
    type: UPDATE_FAMILY_EMAILS,
    payload: data,
  };
};

export const initFamilyEmail = () => {
  return {
    type: INIT_FAMILY_EMAILS,
  };
};

//  Thunks

export const sendCourseStudentsFamilyInviteCodeToTeacher = courseId => {
  return (dispatch, getState) => {
    client
      .mutate({
        mutation: sendCourseStudentsFamilyInviteCodeToTeacherMutation,
        variables: {
          courseId: courseId,
        },
      })
      .then(res => {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:success_invite_family",
          })
        );
      })
      .catch(err => {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

export const resetPasswordDetails = params => {
  params.is_password_updated = !params.is_password_updated;

  return async (dispatch, getState) => {
    dispatch(toggleActionLoading(true));
    try {
      await client.mutate({
        mutation: resetPasswordMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};
// ------------------------------------
// Reducer Handlers
// ------------------------------------
const REDUCER_HANDLERS = {
  [CHANGE_SEARCH_TERM]: (state, action) => {
    const type = action.payload.type;
    return update(state, {
      searchTerm: { [type]: { $set: action.payload.searchText } },
    });
  },
  [SET_EXCLUDED_COURSEIDS]: (state, action) => {
    return update(state, { excludedCourseIds: { $push: [action.payload] } });
  },
  [SET_EXCLUDED_IDS]: (state, action) => {
    const type = action.payload.type;
    return update(state, {
      excludedIds: { [type]: { $set: action.payload.value } },
    });
  },
  [UPDATE_SELECTED_LIST]: (state, action) => {
    const params = action.payload;
    const value = params.value;
    const opt = params.opt;
    const index = params.index;
    const type = params.type;
    if (value) {
      state = update(state, { selectedList: { [type]: { $push: [opt] } } });
    } else {
      state = update(state, {
        selectedList: { [type]: { $splice: [[index, 1]] } },
      });
    }
    return state;
  },
  [RESET_ITEM]: (state, action) => {
    const type = action.payload.type;
    return update(state, {
      searchTerm: { [type]: { $set: "" } },
      selectedList: { [type]: { $set: [] } },
      excludedCourseIds: { $set: [] },
      excludedIds: { $set: initialState.excludedIds },
    });
  },
  [UPDATE_LOADING]: (state, action) => {
    const params = action.payload;
    Object.keys(params).map((key, index) => {
      state = update(state, { isLoading: { [key]: { $set: params[key] } } });
    });
    return state;
  },
  [INIT_SELECTED_LIST]: (state, action) => {
    return update(state, { selectedList: { $set: initialState.selectedList } });
  },
  [UPDATE_FAMILY_EMAILS]: (state, action) => {
    const params = action.payload;
    state = update(state, {
      familyEmails: { $set: action.payload.familyEmails },
    });
    return state;
  },
  [INIT_FAMILY_EMAILS]: (state, action) => {
    return update(state, { familyEmails: { $set: initialState.familyEmails } });
  },
  [TOGGLE_ACTION_LOADING]: (state, action) => {
    return update(state, {
      isActionLoading: { $set: action.data },
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  searchTerm: {
    courses: "",
    teachers: "",
    students: "",
  },
  selectedList: {
    courses: [],
    teachers: [],
    students: [],
  },
  isLoading: {
    createCourse: false,
  },
  excludedCourseIds: [],
  excludedIds: {
    courses: [],
    teachers: [],
    students: [],
  },
  familyEmails: [],
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
