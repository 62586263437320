import React, { useState } from "react";
import classes from "./DropdownSetting.scss";
import { UIButton } from "UIComponents";
import classNames from "classnames";
import { PencilLineOutlined } from "@toddle-design/web-icons";
import { IconButton, SelectDropdown } from "@toddle-design/web";

const DROP_DOWN_VALUE_TYPE_MAP = {
  INT: "intValue",
  STRING: "stringValue",
  BOOLEAN: "booleanValue",
  JSON: "jsonValue",
};

const DropdownSetting = ({ t, onUpdateSetting, setting }) => {
  const selectOptions = _.get(setting, "valueSet.possibleValues", []);

  const dropDownValueType = _.get(setting, "valueSet.dropdownValueType", "");
  const stringValue = _.get(
    setting,
    DROP_DOWN_VALUE_TYPE_MAP[dropDownValueType]
  );

  const settingValueObj = _.find(
    selectOptions,
    option => option.value === stringValue
  );

  const [settingValue, setSettingValue] = useState({
    label: t(`common:${settingValueObj?.label}`),
    value: settingValueObj?.value,
  });
  const [mode, setMode] = useState("view");

  const settingSelect = settingValueObj => {
    setSettingValue(settingValueObj);
  };

  const onEditIconClick = () => {
    setMode("edit");
  };
  const onSaveClick = () => {
    setMode("view");
    onUpdateSetting({
      value: settingValue?.value,
      setting,
    });
  };

  const selectButtonContainerClass = classNames(
    { [classes.selectButtonContainer]: true },
    { [classes.selectButtonContainerWithPadding]: mode === "view" }
  );

  const actionButtonsContainerClass = classNames(
    { [classes.actionButtonsContainer]: true },
    { [classes.actionButtonsContainerEditMode]: mode === "edit" }
  );

  return (
    <div className={classes.buttonsContainer}>
      <div className={selectButtonContainerClass}>
        {mode === "edit" ? (
          <div className={classes.dropdownContainer}>
            <SelectDropdown
              options={_.map(selectOptions, option => {
                return {
                  ...option,
                  label: t(`common:${option.label}`),
                };
              })}
              onChange={settingSelect}
              value={settingValue}
              isClearable={false}
            />
          </div>
        ) : (
          <div className={classes.selectedSettingText}>
            {settingValue?.label}
          </div>
        )}
      </div>
      <div className={actionButtonsContainerClass}>
        {mode == "edit" ? (
          <UIButton type="filled" size="sm" color="blue" onClick={onSaveClick}>
            {t("common:save")}
          </UIButton>
        ) : (
          <IconButton
            icon={<PencilLineOutlined />}
            variant={"plain"}
            size={"small"}
            onClick={onEditIconClick}
          />
        )}
      </div>
    </div>
  );
};

export default DropdownSetting;
