import React from "react";
import { ChevronLeftOutlined, PrintOutlined } from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";
import { ToddleIcon } from "SvgComponents";
import { getPrintFile } from "modules/Services";
import { connect } from "react-redux";
import { compose } from "redux";
import { I18nHOC } from "UIComponents";
import classes from "./Header.scss";

const Header = React.memo(props => {
  const {
    selectedItems,
    onClickBack,
    t,
    mode,
    createHeader,
    showImageTitleInHeader,
    assignmentDetails,
    currentStep,
    getPrintFile,
  } = props;

  const onClickPrint = () => {
    const assessmentId = _.get(selectedItems, "selectedAssessmentId");
    getPrintFile({ id: assessmentId, type: "assessment" });
  };

  const isLESelectionStep = _.isEqual(currentStep, "LE_SELECTION");

  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.leftInnerContainer}>
          <div className={classes.chevronIconContainer} onClick={onClickBack}>
            <ChevronLeftOutlined size={"x-small"} />
          </div>
          <ToddleIcon width={40} height={40} />
        </div>
        <div className={classes.details}>
          <div className={classes.title}>
            {mode == "create"
              ? createHeader
              : t(`common:edit_with_label`, {
                  label: t(`common:lowercase`, { text: t(`common:classwork`) }),
                })}
          </div>
          {showImageTitleInHeader && (
            <div className={classes.assignmentTitle}>
              {assignmentDetails.assignmentTitle}
            </div>
          )}
        </div>
      </div>
      <div className={classes.rightContainer}>
        {isLESelectionStep && (
          <div className={classes.rightIconContainer}>
            <IconButton
              onClick={onClickPrint}
              icon={<PrintOutlined size={"x-small"} variant={"subtle"} />}
              variant={"outlined-subtle"}
              size={"medium"}
              disabled={_.isEmpty(selectedItems.selectedAssessmentId)}
            />
          </div>
        )}
      </div>
    </div>
  );
});

Header.displayName = "Header";

const mapStateToProps = state => {
  return {};
};

const mapActionCreators = {
  getPrintFile,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common"] })
)(Header);
