import React, { useState, useEffect, useRef } from "react";
import { SearchItem } from "../SearchItem/SearchItem";
import { useKey, usePrevious } from "react-use";

export const SearchList = props => {
  const { data = [], onSelectItem, searchText, searchState } = props;
  const [cursor, setCursor] = useState(0);
  const dataLen = data.length;
  const prevDataLen = usePrevious(dataLen);

  const ref = useRef([]);

  useEffect(() => {
    //reset cursor on data update
    if (dataLen != prevDataLen && cursor != 0) {
      setCursor(0);
    }
  }, [dataLen]);

  const scrollIntoView = ({ index }) => {
    const selectedRef = ref?.current?.[index];
    selectedRef?.scrollIntoView({
      // behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const onKeyUp = () => {
    if (!data.length) {
      return;
    }

    let updatedCursor;
    if (cursor == 0 || cursor == "search") {
      updatedCursor = data.length - 1;
    } else {
      updatedCursor = cursor - 1;
    }

    setCursor(updatedCursor);
    scrollIntoView({ index: updatedCursor });
  };
  const onKeyDown = () => {
    if (!data.length) {
      return;
    }

    let updatedCursor;
    if (cursor == data.length - 1 || cursor == "search") {
      updatedCursor = 0;
    } else {
      updatedCursor = cursor + 1;
    }
    setCursor(updatedCursor);
    scrollIntoView({ index: updatedCursor });
  };

  const onSearchItemFocus = ({ index, id }) => {
    const { mousePosition } = searchState;
    if (mousePosition == null) {
      return;
    }
    setCursor(index);
  };

  const onEnter = () => {
    const item = data[cursor];

    if (!item) {
      console.error("Invalid item selection at:", { cursor });
      return;
    }
    onSelectItem({ item });
  };
  useKey("ArrowUp", onKeyUp, {}, [cursor, data]);
  useKey("ArrowDown", onKeyDown, {}, [cursor, data]);
  useKey("Enter", onEnter, {}, [cursor, data]);

  return (
    <div className="flex flex-col" role="list">
      {data.map((item, index) => {
        const { id } = item;
        return (
          <SearchItem
            key={id}
            item={item}
            onSelectItem={onSelectItem}
            searchText={searchText}
            uiState={{
              isSelected: cursor == index,
              index,
              cursor,
            }}
            onFocusIn={() => {
              onSearchItemFocus({ index });
            }}
            onFocusOut={() => {
              onSearchItemFocus({ index: "search" });
            }}
            ref={ref}
          />
        );
      })}
    </div>
  );
};
