import React, { Fragment } from "react";
import classes from "./Header.scss";
import {
  ToddleIcon,
  SearchIcon,
  NotificationBell,
  BookmarkIconSvg,
  BookmarkFillIconSvg,
  LogoutIcon,
} from "SvgComponents";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { colors, fontStyle } from "Constants";
import NotificationCenter from "../NotificationCenter";
import {
  TabList,
  ProfileCascade,
  I18nHOC,
  ButtonDropdown,
  UIButton,
} from "UIComponents";
import { COMMUNITY_TABS } from "Community/modules/CommunityModule";
import { logoutUser } from "Login/modules/LoginModule";
import { PlatformSwitcher, CoachMarks } from "AppComponents";
import { ContributeCommunity } from "AppComponents/Community";
import { updateOnBoardingObject } from "OnBoarding/modules/OnBoardingModule";
import {
  goToToddlePlateform,
  goToCommunityHome,
} from "Login/modules/LoginModule";
import { goToRelativeRoute } from "modules/Services";

import {
  setActiveParent,
  setCommunityActiveTab,
} from "Community/modules/CommunityModule";
import { goToChildEntity } from "CommunityParentEntity/modules/CommunityParentEntityModule";
import { CommunitySignUpModal } from "AppComponents/Community";
import { goToLoginHomeScreen } from "modules/NavigationModule";
import classNames from "classnames";
import * as EventTracker from "lib/eventTracker";
import { isMobile } from "Utils";
import { getCommunityUserDetailsFromCache } from "Community/modules/CommunityGraphqlHelpers";
import { UserCurriculumProgramsContext } from "Platform/modules/PlatformModule";
import { getCoachMarkStatusDetails } from "modules/CoachMarksModule.js";
import { getNotificationsCountQuery } from "modules/CommonQuery";
import querystringify from "querystringify";
import { isTablet } from "Utils";

const tabListStyle = {
  containerStyle: {
    gridTemplateColumns: "unset",
    height: "100%",
    pointerEvents: "auto",
  },
  activeTabContainerStyle: {
    fontSize: isTablet() ? "1.4rem" : "1.6rem",
    color: colors.black,
    ...fontStyle.demiBold,
    padding: "0 16px",
    height: "100%",
    alignItems: "center",
    display: "flex",
  },
  tabContainerStyle: {
    color: colors.gray48,
    ...fontStyle.medium,
    padding: "0 16px",
    borderColor: "transparent",
    height: "100%",
    alignItems: "center",
    display: "flex",
    fontSize: isTablet() ? "1.4rem" : "1.6rem",
  },
};

const profileCascadeStyle = {
  customStyle: {
    width: 40,
    height: 40,
  },
};

const profileCascadeDropdownStyle = {
  customStyle: {
    width: 40,
    height: 40,
  },
};

const profileDropdownStyles = {
  position: "absolute",
  // top: 50,
  top: "calc(100% + 11px)",
  // height: 214,
  width: 320,
  padding: 0,
  borderRadius: 4,
  background: colors.white,
  border: `1px solid ${colors.strokeTwo}`,
  boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.1)",
};

const notificationDropdownStyle = {
  width: 400,
  padding: 0,
  overflow: "hidden",
  top: 48,
  right: -200,
  borderRadius: 16,
  boxShadow: "0 0 24px 8px rgba(0, 0, 0, 0.05)",
};

const UIButtonSignInContainerStyle = {
  borderRadius: "4px",
  minWidth: "77px",
};

const UIButtonJoinForFreeContainerStyle = {
  borderRadius: "4px",
  maxWidth: "111px",
};

const NotificationCount = ({ notificationCount }) => {
  return notificationCount > 0 ? (
    <div className={classes.notificationCount}>
      <div className={classes.notificationCountContent}>
        {notificationCount}
      </div>
    </div>
  ) : null;
};

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showJoinModal: false };
    this.redirectUrl = encodeURIComponent(_.get(location, "pathname", "/"));
  }

  onCloseJoinCommunityModal = () => {
    this.setState({ showJoinModal: false });
    this.target = undefined;
  };

  checkAndopenJoinCommunityModal = ({ target }) => {
    const { userLoggedIn } = this.props;

    if (!userLoggedIn) {
      this.target = target;
      this.setState({ showJoinModal: true });
      return true;
    }
    return false;
  };

  onSearchClick = () => {
    const { setActiveParent } = this.props;
    // const isOpenJoinModal = this.checkAndopenJoinCommunityModal({
    //   target: "search"
    // });
    // if (!!isOpenJoinModal) {
    //   return;
    // }

    setActiveParent({ value: "search", route: `search/all` });
  };

  onBookBarkClick = () => {
    const { setActiveParent } = this.props;
    const isOpenJoinModal = this.checkAndopenJoinCommunityModal({
      target: "bookmark",
    });
    if (isOpenJoinModal) {
      return;
    }

    setActiveParent({ value: "bookmarked", route: `bookmarked/units` });
  };

  onProfileClick = (id, type) => {
    const { setActiveParent } = this.props;
    const isOpenJoinModal = this.checkAndopenJoinCommunityModal({
      target: "profile",
    });
    if (isOpenJoinModal) {
      return;
    }

    const baseRoute = type === "SCHOOL_PROFILE" ? "schoolprofile" : "profile";
    setActiveParent({ value: baseRoute, route: `${baseRoute}/${id}` });
  };

  setCommunityActiveTabInTabList = tab => {
    // const isOpenJoinModal = this.checkAndopenJoinCommunityModal({
    //   target: "header tabs"
    // });
    // if (!!isOpenJoinModal) {
    //   return;
    // }
    const {
      setCommunityActiveTab,
      setActiveParent,
      goToChildEntity,
    } = this.props;
    const tabItem = _.find(COMMUNITY_TABS, item => item.value == tab) || {};
    const { isChildEntity, value, parentValue } = tabItem;

    if (isChildEntity) {
      setActiveParent({ value: parentValue, route: `${parentValue}/${value}` });
      goToChildEntity({ parentEntityType: parentValue, value, type: "push" });
    } else {
      setActiveParent({ value: parentValue });
    }
    setCommunityActiveTab(value);
  };

  getTabs = () => {
    const { t } = this.props;
    return _.map(COMMUNITY_TABS, item => {
      return {
        ...item,
        label: t(item.label),
        // label: t("common:all_label", { label: t(item.label) })
      };
    });
  };

  onToddleMenuItemClick = value => {
    const {
      goToCommunityHome,
      goToToddlePlateform,
      setActiveParent,
    } = this.props;
    switch (value) {
      case "TODDLE_PLATFORM":
        goToToddlePlateform();
        break;
      case "TODDLE_COMMUNITY":
        goToCommunityHome({ isForced: true });
        setActiveParent({ value: "featured", route: `featured/all` });
        break;
    }
  };

  onLogoClick = async () => {
    if (isMobile()) return;

    const { goToCommunityHome, setActiveParent } = this.props;

    goToCommunityHome({ isForced: true });
    setActiveParent({ value: "featured", route: `featured/all` });
  };

  onSigUpClick = e => {
    const { goToLoginHomeScreen } = this.props;
    e.stopPropagation();
    const target = "header button";
    EventTracker.recordEvent({
      eventName: "Clicked join for free",
      eventData: {
        target,
        source: "community",
      },
    });
    const currentQuery = querystringify.parse(
      _.get(window, "location.search", "")
    );
    goToLoginHomeScreen({
      isOpenInNewTab: false,
      params: {
        target,
        type: "organizationSelection",
        usertype: "staff",
        redirect_url: this.redirectUrl,
        action: "community",
        is_public_access: true,
        utm_source: currentQuery.utm_source,
        utm_token: currentQuery.utm_token,
      },
    });
  };

  onSignInClick = e => {
    const { goToLoginHomeScreen } = this.props;
    e.stopPropagation();
    const target = "header button";
    EventTracker.recordEvent({
      eventName: "Clicked sign in",
      eventData: {
        target,
        source: "community",
      },
    });
    const currentQuery = querystringify.parse(
      _.get(window, "location.search", "")
    );
    goToLoginHomeScreen({
      isOpenInNewTab: false,
      params: {
        target,
        type: "teacherLogin",
        usertype: "staff",
        redirect_url: this.redirectUrl,
        action: "community",
        is_public_access: true,
        utm_source: currentQuery.utm_source,
        utm_token: currentQuery.utm_token,
      },
    });
  };

  render() {
    const {
      // onLogoClick,
      activeTab,
      userInfo,
      t,
      activeParent,
      userLoggedIn,
      notificationCount,
      coachmarkRenderStatus,
      getCoachMarkStatusDetails,
      bookmarkStatus,
      isCommunityEntityPage,
    } = this.props;
    const { showJoinModal } = this.state;
    const { id: userId, firstName, profileImage, communityDetails } =
      userInfo || {};
    const { aboutMe } = communityDetails || {};
    const tabs = this.getTabs();
    const rightIconClasses = classNames(
      { [classes.rightIconButton]: true },
      { [classes.rightIconWithoutLogin]: !userLoggedIn }
    );
    const coachMarksStatusDetails = getCoachMarkStatusDetails({
      type: "COMMUNITY_VIEW_BOOKMARKS",
      portalType: "COMMUNITY",
    });
    const { count } = coachMarksStatusDetails || {};

    return (
      <Fragment>
        {coachmarkRenderStatus && !isCommunityEntityPage && (
          <Fragment>
            {!aboutMe && (
              <CoachMarks
                type={"COMMUNITY_PROFILE_UPDATE"}
                continuous={true}
                params={{ target: "#COACHMARKS_COMMUNITY_PROFILE_UPDATE" }}
                isTransparentOverlay={true}
                disableScrolling={false}
                disableScrollParentFix={false}
                disableOverlay={true}
                startAfterTime={3000}
              />
            )}

            <CoachMarks
              type={"COMMUNITY_CONTRIBUTE_BUTTON"}
              continuous={true}
              params={{ target: "#COACHMARKS_COMMUNITY_CONTRIBUTE_BUTTON" }}
              isTransparentOverlay={true}
              disableScrolling={false}
              disableScrollParentFix={false}
              disableOverlay={true}
              startAfterTime={3000}
            />

            {/* show it in first visit, then show it when user bookmarks for the first time */}
            {(count < 2 || bookmarkStatus) && (
              <CoachMarks
                type={"COMMUNITY_VIEW_BOOKMARKS"}
                continuous={true}
                params={{
                  target: "#COACHMARKS_COMMUNITY_VIEW_BOOKMARKS_BUTTON",
                }}
                isTransparentOverlay={true}
                disableScrolling={false}
                disableScrollParentFix={false}
                disableOverlay={true}
                startAfterTime={3000}
              />
            )}
          </Fragment>
        )}

        <div className={classes.container}>
          <div className={classes.logoContainer} onClick={this.onLogoClick}>
            <div className={classes.logo}>
              <ToddleIcon />
            </div>
            <UserCurriculumProgramsContext.Consumer>
              {userCurriculumPrograms => {
                return (
                  <PlatformSwitcher
                    activeValue={"TODDLE_COMMUNITY"}
                    userCurriculumPrograms={userCurriculumPrograms}
                  />
                );
              }}
            </UserCurriculumProgramsContext.Consumer>
          </div>
          <div className={classes.middleContainer}>
            <TabList
              tabs={tabs}
              activeTab={activeTab}
              toggleTab={this.setCommunityActiveTabInTabList}
              {...tabListStyle}
            />
          </div>
          <div className={classes.rightContainer}>
            <div className={rightIconClasses} onClick={this.onSearchClick}>
              <SearchIcon
                width={16}
                height={16}
                fill={activeParent == "search" ? colors.black : undefined}
              ></SearchIcon>
            </div>
            {userLoggedIn ? (
              <Fragment>
                <div
                  id="COACHMARKS_COMMUNITY_VIEW_BOOKMARKS_BUTTON"
                  className={rightIconClasses}
                  onClick={this.onBookBarkClick}
                >
                  {activeParent == "bookmarked" ? (
                    <BookmarkFillIconSvg
                      width={14}
                      height={16}
                      fill={colors.black}
                    ></BookmarkFillIconSvg>
                  ) : (
                    <BookmarkIconSvg
                      width={14}
                      height={16}
                      fill={null}
                    ></BookmarkIconSvg>
                  )}
                </div>
                <div className={rightIconClasses}>
                  <ButtonDropdown
                    buttonComponent={
                      <div className={classes.notificationIcon}>
                        <NotificationBell
                          width={20}
                          height={20}
                        ></NotificationBell>
                        <NotificationCount
                          notificationCount={notificationCount}
                        />
                      </div>
                    }
                    shouldCloseOnSelfClick={false}
                    shouldMountDropDownOnButtonClick={true}
                    dropdownComponent={<NotificationCenter />}
                    containerStyle={notificationDropdownStyle}
                    buttonParentStyle={{ position: "relative" }}
                  />
                </div>

                <div
                  className={rightIconClasses}
                  id="COACHMARKS_COMMUNITY_PROFILE_UPDATE"
                >
                  <ButtonDropdown
                    buttonComponent={
                      <ProfileCascade
                        items={[
                          {
                            id: userId,
                            value: firstName,
                            imageUrl: profileImage,
                          },
                        ]}
                        customStyle={profileCascadeStyle.customStyle}
                      />
                    }
                    shouldMountDropDownOnButtonClick={true}
                    dropdownComponent={
                      <ProfileDropdown
                        onProfileClick={this.onProfileClick}
                        userInfo={userInfo}
                        t={t}
                      />
                    }
                    containerStyle={profileDropdownStyles}
                    buttonParentStyle={{ position: "relative" }}
                  />
                </div>
                <div
                  className={classes.contributeButtonContainer}
                  id="COACHMARKS_COMMUNITY_CONTRIBUTE_BUTTON"
                >
                  <ContributeCommunity eventTarget="home page header" />
                </div>
              </Fragment>
            ) : (
              <div className={classes.headerButtonsContainer}>
                <UIButton
                  className={classes.signInButton}
                  color="blue"
                  onClick={this.onSignInClick}
                  containerStyle={{
                    borderRadius: "4px",
                    minWidth: "77px",
                  }}
                  size="sm"
                  type={"hollow"}
                >
                  {t("common:sign_in")}
                </UIButton>
                <UIButton
                  containerStyle={{
                    borderRadius: "4px",
                    maxWidth: "111px",
                  }}
                  color="pink"
                  onClick={this.onSigUpClick}
                  size="sm"
                >
                  {t("common:join_for_free")}
                </UIButton>
              </div>
            )}
          </div>
        </div>

        {!!showJoinModal && (
          <CommunitySignUpModal
            onClose={this.onCloseJoinCommunityModal}
            target={this.target}
          />
        )}
      </Fragment>
    );
  }
}

const ProfileDropdown = ({ userInfo, t, onProfileClick }) => {
  const { id: userId, firstName, profileImage } = userInfo || {};
  // const { school } = currentSchools[0] || [];
  // const { id: schoolId, name: schoolName } = school || {};

  return (
    <div className={classes.profileDropdowncontainer}>
      <div
        className={classes.dropdownItem}
        onClick={() => onProfileClick(userId, "PROFILE")}
      >
        <ProfileCascade
          items={[{ id: userId, value: firstName, imageUrl: profileImage }]}
          customStyle={profileCascadeDropdownStyle.customStyle}
        />
        <div className={classes.itemContent}>
          <div className={classes.name}>{firstName}</div>
          <div className={classes.itemLink}>
            {t("community:see_your_profile")}
          </div>
        </div>
      </div>

      <div onClick={logoutUser} className={classes.dropdownItem}>
        <div className={classes.itemContent}>
          <div className={classes.signout}>
            <LogoutIcon />
            <span className={classes.signoutText}>
              {t("community:signout")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const userId = _.get(state, "login.userInfo.id");
  const coachmarkRenderStatus = _.get(state, "community.coachmarkRenderStatus");
  const userInfo = getCommunityUserDetailsFromCache({
    id: userId,
  });
  return {
    activeParent: state.community.activeParent,
    activeTab: state.community.activeTab,
    userId,
    userEntityType: "STAFF",
    isData: true,
    isLoading: false,
    userLoggedIn: state.login.userLoggedIn,
    coachmarkRenderStatus,
    userInfo,
    bookmarkStatus: _.get(
      state.coachMarks.coachMarksViewStatus,
      "bookmarkStatus",
      false
    ),
  };
};

const mapActionCreators = {
  setActiveParent,
  updateOnBoardingObject,
  goToRelativeRoute,
  goToChildEntity,
  setCommunityActiveTab,
  goToToddlePlateform,
  goToCommunityHome,
  goToLoginHomeScreen,
  getCoachMarkStatusDetails,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] }),
  graphql(getNotificationsCountQuery, {
    name: "getNotificationsCount",
    options: ({ userId, userEntityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: userEntityType,
      },
      context: {
        useHttpLink: true,
      },
    }),
    props({
      getNotificationsCount,
      ownProps: { isData, isLoading, userId, userEntityType },
    }) {
      const notification = _.get(
        getNotificationsCount,
        "node.notification",
        {}
      );

      return {
        getNotificationsCount,
        onPusherEventTriggered: () => getNotificationsCount.refetch(),
        isData: !_.isEmpty(notification) && isData,
        notificationCount: _.get(notification, "newCount", 0),
        isLoading:
          _.includes([1, 2], getNotificationsCount.networkStatus) || isLoading,
      };
    },
  })
)(Header);
