export const getBasicInfo = state => {
  const { activeTab, userLoggedIn } = _.get(state, "login", {});
  const isCommunity = activeTab == "community";

  const isPublicAccess = isCommunity && !userLoggedIn;

  return {
    isPublicAccess,
    isCommunity,
    userLoggedIn,
  };
};
