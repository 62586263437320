import PropTypes from "prop-types";
import React from "react";

const GoogleDriveIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 14"
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <path
          d="M15.910947,8.87914222 C16.0286469,9.08175705 16.0290074,9.33249937 15.9159948,9.53392526 L13.6141491,13.6382596 C13.4972979,13.8465785 13.2770883,13.9755528 13.038248,13.9755528 L2.96214322,13.9755528 C2.71818927,13.9755528 2.4954425,13.8406079 2.38231081,13.6312264 L0.0843962215,9.5339249 C-0.0298940952,9.33013678 -0.0279907412,9.08114544 0.0894133148,8.87914257 L5.0590674,0.328429559 C5.17693197,0.125772038 5.39553341,0 5.62993475,0 L10.3703951,0 C10.6056334,0 10.8230821,0.125107359 10.9412842,0.328467007 L15.910947,8.87914222 Z M6.77369493,1.3205522 C7.20898467,2.07473054 7.93330696,3.3311957 7.90243852,3.27770534 C8.21460839,3.81864882 8.39376627,4.12669542 8.59310249,4.46452865 C8.79244292,4.80236902 9.00844999,5.16404569 9.42005424,5.84974933 C9.37183921,5.76942649 10.4982831,7.64484553 11.0418255,8.55064454 L14.1926165,8.55064454 L9.99048511,1.3205522 L6.77369493,1.3205522 Z M8.00017915,6.04790304 L6.49854471,8.55064454 L9.50178745,8.55064454 L8.00017915,6.04790304 Z M2.97040142,11.980138 C3.36973601,11.2871929 4.56885618,9.20729871 4.78389839,8.84292563 C5.02419753,8.43575656 6.88670193,5.3364373 7.23400623,4.75814893 L5.62811758,1.97739837 L1.42061417,9.21679268 L2.97040142,11.980138 Z M14.2127162,9.87119675 L5.7135211,9.87119675 L4.10588387,12.6549699 L12.6514886,12.6549699 L14.2127162,9.87119675 Z"
          id="Shape"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

GoogleDriveIcon.defaultProps = {
  width: 16,
  height: 14,
};
GoogleDriveIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
export default GoogleDriveIcon;
