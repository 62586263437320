export const PdfTronViewerConfig = {
  TEACHER_EDITOR: {
    PERMANENT_DISABLE_ELEMENTS: [
      "viewControlsButton",
      "toggleNotesButton",
      "searchButton",
      "menuButton",
      "toolbarGroup-Edit",
      "toolbarGroup-View",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Forms",
      "notesPanel",
      "searchPanel",
      "signatureToolGroupButton",
      "rubberStampToolGroupButton",
      "annotationContentOverlay",
      "saveButton",
      "cancelButton",
      "toolsHeader",
      "ribbons",
      "thumbnailControl",
      "thumbMultiDelete",
      "giveFeedbackButton",
      "fileAttachmentToolGroupButton",
      "stickyToolButton",
      "stickyToolGroupButton",
      "polygonCloudToolGroupButton",
    ],
    TOGGLE_ELEMENTS: [
      "saveButton",
      "cancelButton",
      "toolsHeader",
      "ribbons",
      "thumbnailControl",
      "thumbMultiDelete",
    ],
  },

  STUDENT_EDITOR: {
    PERMANENT_DISABLE_ELEMENTS: [
      "viewControlsButton",
      "toggleNotesButton",
      "searchButton",
      "menuButton",
      "toolbarGroup-Edit",
      "toolbarGroup-View",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Forms",
      "notesPanel",
      "searchPanel",
      "signatureToolGroupButton",
      "rubberStampToolGroupButton",
      "annotationContentOverlay",
      "saveButton",
      "toolsHeader",
      "ribbons",
      "thumbnailControl",
      "thumbMultiDelete",
      "fileAttachmentToolGroupButton",
      "stickyToolButton",
      "stickyToolGroupButton",
      "polygonCloudToolGroupButton",
    ],
    TOGGLE_ELEMENTS: [
      "saveButton",
      "toolsHeader",
      "ribbons",
      "thumbnailControl",
      "thumbMultiDelete",
    ],
  },
  VIEWER: {
    PERMANENT_DISABLE_ELEMENTS: [
      "viewControlsButton",
      "toggleNotesButton",
      "searchButton",
      "menuButton",
      "toolbarGroup-Edit",
      "toolbarGroup-View",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Forms",
      "toolsHeader",
      "ribbons",
      "searchButton",
      "thumbnailControl",
      "thumbMultiDelete",
      "menuButton",
      "notesPanel",
      "searchPanel",
      "signatureToolGroupButton",
      "rubberStampToolGroupButton",
      "annotationContentOverlay",
      "documentControl",
      "signaturePanelButton",
      "addNewOutlineButtonContainer",
      "stickyToolButton",
    ],
  },
};
