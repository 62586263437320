import React from "react";
import classes from "./CommentItem.scss";
import moment from "moment";
import {
  ProfileCascade,
  UIButton,
  TaggableInputField,
  I18nHOC,
  AudioRecorderSelector,
  TextAreaInput,
  Unveil,
} from "UIComponents";
import DropDown from "../DropDown";
import classNames from "classnames";
import { AttachmentList } from "Attachment";
import { getTimeSince, getCommentDisplayContent } from "Utils";
import { VoiceIcon, ReplyIconSvg } from "SvgComponents";
import { colors } from "Constants";
import { MicrophoneOutlined } from "@toddle-design/web-icons";
import { compose } from "react-apollo";
import { connect } from "react-redux";

const defaultStyles = {
  profileCascadeStyle: {
    borderWidth: 0,
    width: 40,
    height: 40,
  },
};

const MENU_ITEMS = (t, clickAction) => [
  {
    label: t("edit"),
    clickAction: () => clickAction("EDIT"),
    svg: null,
    isDisable: false,
    id: "EDIT",
    style: {
      borderWidth: 0,
    },
  },
  {
    label: t("delete"),
    clickAction: () => clickAction("DELETE"),
    svg: null,
    id: "DELETE",
    isDisable: false,
    style: {
      borderWidth: 0,
    },
  },
];

class CommentItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showDropDown: false, uploadStatus: false };
  }

  renderMenuItem = t => {
    const {
      userInfo: { id: user_id, user_type },
      item: {
        createdBy: { id, type },
      },
      deleteOtherComment,
    } = this.props;

    if (id == user_id) {
      return MENU_ITEMS(t, this.clickAction);
    } else if (user_type == "staff" && type != "staff" && deleteOtherComment) {
      return _.filter(MENU_ITEMS(t, this.clickAction), item => {
        return item.id != "EDIT";
      });
    }

    return [];
  };

  clickAction = action => {
    const { onClickDelete, onClickEdit } = this.props;
    switch (action) {
      case "EDIT":
        onClickEdit();
        break;
      case "DELETE":
        onClickDelete();
        break;
      default:
        break;
    }
  };

  render() {
    const {
      item,
      customItemRef,
      userInfo,
      isEditing,
      label,
      attachments,
      onCommentDataChange,
      onCommentEdit,
      onCancelEdit,
      collaborators,
      onCommentInputRef,
      parentNode,
      disableTagging,
      commentItemContainerStyle,
      commentItemStyle = {},
      itemId,
      t,
      allowReply,
      showMessageBox,
      nagivateToProfile,
      goToCommunityRoute,
      canCreateAudioComment,
      unveilMaxHeight,
      shouldCollapseMessage,
      isRightPaneChat,
      mode,
      uploadFileType,
    } = this.props;

    const { id: user_id } = userInfo;
    const { showDropDown, isCommentLoading, uploadStatus } = this.state;
    const { createdAt, createdBy } = item;
    const {
      firstName,
      lastName,
      profileImage,
      id,
      type,
      schoolTenures,
    } = createdBy;
    const enableDropDown = user_id == id && showDropDown;
    const schoolName = _.get(schoolTenures, "0.school.name");

    const moreContainerStyle = classNames(
      { [classes.moreContainer]: true },
      { [classes.visible]: enableDropDown }
    );

    const timeTextContainerStyle = classNames(
      { [classes.timeText]: true }
      // { [classes.hidden]: enableDropDown }
    );

    const containerStyle = classNames(
      { [classes.normalcontainer]: !isEditing },
      { [classes.editCotainer]: isEditing }
    );
    const textAreaStyle = classNames({
      [classes.messageText]: true,
      [classes.editMessageText]: isEditing,
    });

    const nameTextClasses = classNames({
      [classes.nameText]: true,
      [classes.underlineOnHover]: nagivateToProfile,
    });

    const menuItems = this.renderMenuItem(t);
    const characterLimit = 8000 - label?.length;

    return (
      <div
        style={commentItemContainerStyle}
        className={containerStyle}
        ref={ref => customItemRef(ref)}
      >
        <div className={classes.leftContainer}>
          <ProfileCascade
            items={[{ id, value: firstName, imageUrl: profileImage }]}
            customStyle={{
              ...defaultStyles.profileCascadeStyle,
              ...commentItemStyle.profileCascadeStyle,
            }}
          />
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.headerContainer}>
            <div className={classes.headerContentWrapper}>
              <div className={classes.nameContainer}>
                <div
                  className={nameTextClasses}
                  style={{ ...commentItemStyle.nameText }}
                  onClick={() =>
                    nagivateToProfile
                      ? goToCommunityRoute({
                          route: `profile/${id}`,
                          isOpenInNewTab: true,
                        })
                      : null
                  }
                >{`${firstName} ${lastName}`}</div>

                <div
                  className={timeTextContainerStyle}
                  style={{ ...commentItemStyle.timeTextContainerStyle }}
                >
                  <span className={classes.dot}>{`•`}</span>
                  {getTimeSince(new Date(createdAt), t)}
                </div>
              </div>

              {nagivateToProfile && (
                <div
                  className={classes.schoolName}
                  style={{ ...commentItemStyle.schoolName }}
                >
                  {schoolName}
                </div>
              )}
            </div>

            {!isEditing && mode == "edit" && (
              <div
                className={moreContainerStyle}
                style={commentItemStyle.moreContainer}
              >
                {_.get(menuItems, "length", 0) > 0 ? (
                  <DropDown
                    onUpdateShowDropDown={value =>
                      this.setState({ showDropDown: value })
                    }
                    item={item}
                    menuItems={menuItems}
                  />
                ) : null}
              </div>
            )}
          </div>
          {isCommentLoading ? (
            <div className={classes.loadingAnimationContainer}>
              <div className={classes.loadingAnimation} />
            </div>
          ) : isEditing ? (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                ...commentItemStyle.messageContainer,
              }}
            >
              <div
                className={classes.commentBoxOuterWrapper}
                ref={ref => (this.commentContainerRef = ref)}
              >
                <div className={classes.commentBoxInnerWrapper}>
                  <div className={classes.commentAudioBtnWrapper}>
                    <TaggableInputField
                      name={"label"}
                      commentContainerRef={this.commentContainerRef}
                      value={label}
                      updateInputField={onCommentDataChange}
                      userInfo={userInfo}
                      collaborators={collaborators}
                      ref={onCommentInputRef}
                      parentNode={parentNode}
                      disableTagging={disableTagging}
                      mentionInputConStyle={{ border: "none" }}
                    />
                    {canCreateAudioComment && (
                      <div className={classes.audioBtnWrapper}>
                        <AudioRecorderSelector
                          parentType={"MESSAGE"}
                          parentId={itemId}
                          showValue={false}
                          header={t("common:voice_and_record")}
                          updateInputField={param => {
                            onCommentDataChange({
                              attachments: [...attachments, param],
                            });
                          }}
                          buttonComponent={
                            <MicrophoneOutlined
                              variant={"link"}
                              size={"x-small"}
                              disabled={
                                this.state.uploadStatus &&
                                uploadFileType == "AUDIO"
                              }
                            />
                          }
                          uploadStatus={value =>
                            this.setState({ uploadStatus: value })
                          }
                          disabled={
                            this.state.uploadStatus && uploadFileType == "AUDIO"
                          }
                        />
                      </div>
                    )}
                  </div>
                  <AttachmentList
                    value={attachments}
                    name={"attachments"}
                    cardType={"vertical"}
                    showCreateDropdown={false}
                    updateInputField={onCommentDataChange}
                    parentId={itemId}
                    parentType={"MESSAGE"}
                    clubbingCards={2}
                    showSingleCardTheme={true}
                    cardStyle={{ height: 80 }}
                    attachmentContainerStyle={{
                      gridTemplateColumns: isRightPaneChat
                        ? "repeat(1,1fr)"
                        : _.get(attachments, "length", 0) > 1
                        ? "repeat(auto-fill, minmax(88px, 1fr))"
                        : "repeat(1,1fr)",
                      gridGap: "12px",
                    }}
                    containerStyle={{ padding: "0 8px 16px" }}
                    isRightPaneChat={isRightPaneChat}
                  />
                </div>
                {characterLimit < 0 ? (
                  <div className={classes.exceedCharacterCountContainer}>
                    {characterLimit}
                  </div>
                ) : null}
              </div>
              <div className={classes.buttonsCotainer}>
                <div className={classes.cancelButton} onClick={onCancelEdit}>
                  {t("common:cancel")}
                </div>
                <UIButton
                  type="filled"
                  size="sm"
                  color="pink"
                  onClick={onCommentEdit}
                  isDisabled={uploadStatus || characterLimit < 0}
                >
                  {t("common:save_changes")}
                </UIButton>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                minHeight: "40px",
                ...commentItemStyle.messageContainer,
              }}
            >
              <div className={classes.textAttachmentsCon}>
                <Unveil
                  maxHeight={unveilMaxHeight}
                  showCollapse={true}
                  collapsed={
                    String(itemId).startsWith("NEW_")
                      ? false
                      : shouldCollapseMessage
                  }
                >
                  <div
                    className={classes.textContainer}
                    style={{ ...commentItemStyle.textContainer }}
                    dangerouslySetInnerHTML={{
                      __html: getCommentDisplayContent(label),
                    }}
                  />
                </Unveil>
                <AttachmentList
                  value={attachments}
                  cardType={"vertical"}
                  mode={"view"}
                  cardStyle={{ height: 80 }}
                  attachmentContainerStyle={{
                    gridTemplateColumns: isRightPaneChat
                      ? "repeat(1,1fr)"
                      : _.get(attachments, "length", 0) > 1
                      ? "repeat(auto-fill, minmax(88px, 1fr))"
                      : "repeat(1,1fr)",
                    gridGap: "12px",
                    marginTop: "4px",
                    position: "relative",
                  }}
                  clubbingCards={2}
                  showSingleCardTheme={true}
                  isRightPaneChat={isRightPaneChat}
                />
              </div>
            </div>
          )}
          {allowReply && (
            <div
              className={classes.replyContainer}
              style={{ ...commentItemStyle.replyContainer }}
            >
              <div
                className={classes.replyButtonContainer}
                onClick={() => showMessageBox(createdBy)}
              >
                <ReplyIconSvg fill={colors.gray48} width={10} height={10} />
                <div className={classes.replyButtonText}>
                  {t("community:reply")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CommentItem.defaultProps = {
  allowReply: false,
  nagivateToProfile: false,
};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;
  const uploadFileType = _.get(
    _.values(progressOfUploads)[0],
    "attachment.type",
    ""
  );
  return {
    uploadFileType,
  };
};

export default compose(
  connect(mapStateToProps, null),
  I18nHOC({ resource: "common" })
)(CommentItem);
