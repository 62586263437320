import gql from "graphql-tag";

import {
  snpEvaluationCycleFragment,
  snpSetFrameworkFragment,
  snpStandardsFragment,
  progressReportsConnectionFragment,
  guidanceDocumentsFragment,
  visitorFragment,
  snpDocumentCategoryFragment,
  snpDocumentCategoryGroupFragment,
  evaluationCycleStandardFragments,
  snpPracticesFragment,
} from "./SnPFragments";

export const getSnPEvaluationCyclesQuery = gql`
  query getSnPEvaluationCycles($id: ID!, $filters: SnPEvaluationCycleFilter) {
    node(type: ORGANIZATION, id: $id) {
      id
      ... on Organization {
        id
        snpEvaluationCycles(filters: $filters) {
          ...snpEvaluationCycleItem
        }
      }
    }
  }
  ${snpEvaluationCycleFragment.cycleDetails}
`;

export const getSnPCycleDetailsQuery = gql`
  query getSnPCycleDetails($id: ID!) {
    node(id: $id, type: SnP_EVALUATION_CYCLE) {
      id
      ... on SnPEvaluationCycle {
        ...snpEvaluationCycleItem
      }
    }
  }
  ${snpEvaluationCycleFragment.cycleDetails}
`;

export const guidanceDocumentsQuery = gql`
  query guidanceDocumentsQuery($id: ID!, $filters: AttachmentFilterInput!) {
    node(id: $id, type: SnP_EVALUATION_CYCLE) {
      id
      ... on SnPEvaluationCycle {
        ...guidanceDocuments
      }
    }
  }
  ${guidanceDocumentsFragment}
`;

export const getFirstExperienceStatusQuery = gql`
  query getFirstExperienceStatus($id: ID!) {
    platform {
      staff(ids: [$id]) {
        id
        isSnpExplainerVideoAvailable
      }
    }
  }
`;

export const getExplainerVideoQuery = gql`
  query getHelpResource {
    globalConstants {
      snpExplainerVedioUrl
    }
  }
`;

export const getStandardsListQuery = gql`
  query getSnpSet($curriculumType: CURRICULUM_PROGRAM_TYPE_ENUM) {
    platform {
      organization {
        snpSet(filters: { curriculumType: $curriculumType }) {
          id
          title
        }
      }
    }
  }
`;

// To get Framework, Standards, Practices Template of current set
export const getSnpSetDetailsQuery = gql`
  query getSnpSetDetails($setId: ID!) {
    node(id: $setId, type: SnP_SET) {
      id
      ... on SnPSet {
        ...snpSetItem
      }
    }
  }
  ${snpSetFrameworkFragment.frameworkDetails}
`;

export const getSnPDocumentReviewStagesQuery = gql`
  query getSnPDocumentReviewStages($cycleId: ID!) {
    node(id: $cycleId, type: SnP_EVALUATION_CYCLE) {
      ... on SnPEvaluationCycle {
        id
        documentReviewStage {
          id
          stageType
          title
          localeLabelKey
          displaySequence
          documentCategoryGroup {
            ...snpDocumentCategoryGroupItem
          }
        }
      }
    }
  }
  ${snpDocumentCategoryGroupFragment}
`;

export const getSnPDocumentCategoryQuery = gql`
  query getSnPDocumentCategory(
    $id: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
  ) {
    node(id: $id, type: SnP_DOCUMENT_CATEGORY) {
      ... on SnPDocumentCategory {
        ...snpDocumentCategoryItem
      }
    }
  }
  ${snpDocumentCategoryFragment.allDetails}
`;

export const getSnPDocumentConversationsQuery = gql`
  query getSnPDocumentConversations($id: ID!) {
    node(id: $id, type: SnP_DOCUMENT_CATEGORY) {
      ... on SnPDocumentCategory {
        ...documentConversations
      }
    }
  }
  ${snpDocumentCategoryFragment.documentConversations}
`;

export const getProgressReportsQuery = gql`
  query getProgressReportsQuery(
    $id: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
    $filters: studentProgressReportFeedFilter!
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        studentProgressReports(
          first: $first
          after: $after
          orderBy: $orderBy
          orderByDirection: $orderByDirection
          filters: $filters
        ) {
          ...progressReportsConnection
        }
      }
    }
  }
  ${progressReportsConnectionFragment}
`;

// To get the list of all visitors of the organization
export const getVisitorListQuery = gql`
  query getVisitorList(
    $organizationId: ID!
    $first: Int
    $after: String
    $searchText: String
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        staff(
          first: $first
          after: $after
          filters: { roles: [visitor], searchText: $searchText }
          orderBy: $orderBy
          orderByDirection: $orderByDirection
        ) {
          edges {
            node {
              ... on Staff {
                ...visitorItem
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
  ${visitorFragment.visitorFeedDetails}
`;

export const getVisitorCountQuery = gql`
  query getVisitorCount($organizationId: ID!) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        staff(filters: { roles: [visitor] }) {
          totalCount
        }
      }
    }
  }
`;

// To get the list of all visitors that are added to a cycle
export const getInvitedVisitorsListQuery = gql`
  query getInvitedVisitorsList(
    $cycleId: ID!
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
  ) {
    node(id: $cycleId, type: SnP_EVALUATION_CYCLE) {
      id
      ... on SnPEvaluationCycle {
        id
        visitors(orderBy: $orderBy, orderByDirection: $orderByDirection) {
          ...visitorItem
        }
      }
    }
  }
  ${visitorFragment.visitorFeedDetails}
`;

export const getStandardConversationsQuery = gql`
  query getStandardConversationsQuery($id: ID!) {
    node(id: $id, type: SnP_EVALUATION_CYCLE_STANDARD) {
      id
      ... on SnPEvaluationCycleStandard {
        ...standardConversations
      }
    }
  }
  ${evaluationCycleStandardFragments.standardConversations}
`;

export const getDocumentCategoryHelpTextQuery = gql`
  query getDocumentCategoryHelpTextQuery(
    $id: ID!
    $locale: String
    $attachmentFilters: AttachmentFilterInput!
  ) {
    node(id: $id, type: SnP_DOCUMENT_CATEGORY) {
      id
      ... on SnPDocumentCategory {
        ...documentCategoryHelpText
      }
    }
  }
  ${snpDocumentCategoryFragment.documentCategoryHelpText}
`;

export const getSuggestedEvidenceViewStatusQuery = gql`
  query getSuggestedEvidenceViewStatus(
    $staffId: ID!
    $practiceId: ID!
    $cycleId: ID!
  ) {
    node(id: $staffId, type: STAFF) {
      id
      ... on Staff {
        id
        snp {
          isSuggestedEvidenceViewed(
            filters: { practiceId: $practiceId, evaluationCycleId: $cycleId }
          )
        }
      }
    }
  }
`;

export const getSnpPracticeEvidenceBasicDetailsQuery = gql`
  query getSnpPracticeEvidenceBasicDetailsQuery(
    $id: ID!
    $filters: SnpPracticeEvidenceFilters!
  ) {
    node(id: $id, type: SnP_PRACTICE) {
      id
      ... on SnPPractice {
        ...evidenceBasicDetailsItem
      }
    }
  }
  ${snpPracticesFragment.evidenceBasicDetails}
`;

export const getDocumentCategoryEvidenceBasicDetailsQuery = gql`
  query getDocumentCategoryEvidenceBasicDetailsQuery(
    $id: ID!
    $filters: SnPDocumentCategoryEvidenceFilter
  ) {
    node(id: $id, type: SnP_DOCUMENT_CATEGORY) {
      id
      ... on SnPDocumentCategory {
        ...evidenceBasicDetailsItem
      }
    }
  }
  ${snpDocumentCategoryFragment.evidenceBasicDetails}
`;

/** To fetch a cycles standard's id and status details */
export const getStandardBasicDetailsQuery = gql`
  query getStandardBasicDetails(
    $cycleId: ID!
    $filters: SnpPracticeEvidenceFilters!
  ) {
    node(id: $cycleId, type: SnP_EVALUATION_CYCLE) {
      id
      ... on SnPEvaluationCycle {
        ...cycleItem
      }
    }
  }
  ${snpEvaluationCycleFragment.cycleStandardBasicDetails}
`;

/** To fetch evidences of a standard */
export const getStandardEvidencesQuery = gql`
  query getStandardEvidences(
    $standardId: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
    $applyTeacherDashboardOrdering: Boolean
    $evidenceFilters: SnpPracticeEvidenceFilters!
  ) {
    node(id: $standardId, type: SnP_STANDARD) {
      id
      ... on SnPStandard {
        ...snpStandardItem
      }
    }
  }
  ${snpStandardsFragment.standardEvidenceDetails}
`;

/** To fetch evidences of a practice */
export const getPracticeEvidencesQuery = gql`
  query getPracticeEvidences(
    $practiceId: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
    $applyTeacherDashboardOrdering: Boolean
    $evidenceFilters: SnpPracticeEvidenceFilters!
  ) {
    node(id: $practiceId, type: SnP_PRACTICE) {
      id
      ... on SnPPractice {
        ...practiceItem
      }
    }
  }
  ${snpPracticesFragment.practiceEvidenceDetailsV2}
`;

export const getDocumentCategoryTaggedPracticesQuery = gql`
  query getDocumentCategoryTaggedPractices(
    $categoryId: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
    $applyTeacherDashboardOrdering: Boolean
    $evidenceFilters: SnpPracticeEvidenceFilters!
  ) {
    node(id: $categoryId, type: SnP_DOCUMENT_CATEGORY) {
      id
      ... on SnPDocumentCategory {
        ...snpDocumentCategoryItem
      }
    }
  }
  ${snpDocumentCategoryFragment.taggedPractices}
`;

export const getStandardDetailsQuery = gql`
  query getStandardDetails(
    $id: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
    $applyTeacherDashboardOrdering: Boolean
    $evidenceFilters: SnpPracticeEvidenceFilters!
  ) {
    node(id: $id, type: SnP_EVALUATION_CYCLE_STANDARD) {
      ... on SnPEvaluationCycleStandard {
        ...standardDetailsItem
      }
    }
  }
  ${evaluationCycleStandardFragments.standardDetails}
`;

export const getDocumentCategoryEvidenceFeedDetailsQuery = gql`
  query getDocumentCategoryEvidenceFeedDetails(
    $id: ID!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
  ) {
    node(id: $id, type: SnP_DOCUMENT_CATEGORY) {
      ... on SnPDocumentCategory {
        ...evidenceFeedDetailsItem
      }
    }
  }
  ${snpDocumentCategoryFragment.evidenceFeedDetails}
`;
