import React from "react";
import { I18nHOC } from "UIComponents";
import { NoCycles } from "SvgComponents";
import classes from "./NoEvidenceView.scss";

const NoEvidenceView = props => {
  const { t, isSuggestedEvidencesTab } = props;

  const label = isSuggestedEvidencesTab
    ? t("snp:no_evidence_suggested")
    : t("snp:no_evidence_created");

  return (
    <div className={classes.container}>
      <NoCycles />
      <div className={classes.labels}>
        <div className={classes.label}>{label}</div>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["snp"] })(NoEvidenceView);

NoEvidenceView.defaultProps = {
  isSuggestedEvidencesTab: false,
};
