import React, { PureComponent } from "react";
import classes from "./ResourceCardV1.scss";
import { convertDurationToString } from "Utils";
import { LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { ResourceImagePlaceHolder, I18nHOC } from "UIComponents";
import classNames from "classnames";
import { compose } from "react-apollo";
import { connect } from "react-redux";

class ResourceCardV1 extends PureComponent {
  onCardClick = e => {
    const { onContainerClick, isDisabled } = this.props;
    if (!isDisabled && onContainerClick) {
      e.stopPropagation();
      onContainerClick();
    }
  };
  render() {
    const {
      item,
      index,
      theme,
      rightComponent,
      imageThumbSize,
      onContainerClick,
      isDisabled,
      showTypeLabel,
      customSubTitle,
      customImageElement,
      t,
    } = this.props;
    const key = item.id;
    const typeKey = item.assessmentType?.value || "le";
    const typeObj = LE_ASSESSMENT_TYPE_MAPPING[typeKey] ?? {};

    const title = item.title.value ?? t("common:untitled");
    const { createdBy } = item;
    const duration = convertDurationToString(_.get(item, "duration.value", ""));

    const sectionItemStyle = classNames(
      { [classes.sectionItem]: true },
      { [classes.containerDisable]: isDisabled }
    );

    return (
      <div
        key={key}
        className={sectionItemStyle}
        style={theme.sectionItem}
        onClick={this.onCardClick}
      >
        <div className={classes.imageContainer} style={theme.imageContainer}>
          {customImageElement ? (
            customImageElement
          ) : (
            <ResourceImagePlaceHolder
              id={item.id}
              type={item.assessmentType}
              imageUrl={_.get(item, "image.value", "")}
              imageThumbSize={imageThumbSize}
              containerStyle={theme.imageThumbnail}
            />
          )}
        </div>
        <div
          className={classes.sectionItemDetails}
          style={theme.sectionItemDetails}
        >
          {!!showTypeLabel && (
            <div
              className={classes.sectionItemType}
              style={{ color: `${typeObj.color}`, ...theme.sectionItemType }}
            >
              {`${index + 1}# ${t(typeObj.localeLabel)}`}
            </div>
          )}
          {title ? (
            <div
              className={classes.sectionLabel}
              style={theme.sectionLabel}
            >{`${title}`}</div>
          ) : null}
          <div
            className={classes.sectionAuthorAndDuration}
            style={theme.sectionAuthorAndDuration}
          >
            {createdBy ? (
              <span>
                {t("common:by_with_label", {
                  label: `${createdBy.firstName} ${createdBy.lastName}`,
                })}
              </span>
            ) : null}
            {_.get(item, "duration.value", "") ? (
              <span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {`${duration}`}
              </span>
            ) : null}
            {customSubTitle ? customSubTitle : null}
          </div>
        </div>
        {rightComponent ? (
          <div className={classes.rightContainer} style={theme.rightContainer}>
            {rightComponent}
          </div>
        ) : null}
      </div>
    );
  }
}

ResourceCardV1.defaultProps = {
  theme: {
    sectionItem: {},
    rightContainer: {},
    imageContainer: {},
    sectionItemType: {},
    sectionLabel: {},
    sectionAuthorAndDuration: {},
    sectionItemDetails: {},
  },
  showTypeLabel: true,
  customSubTitle: false,
  customImageElement: null,
};

export default compose(I18nHOC({ resource: ["unitPlan", "common"] }))(
  ResourceCardV1
);
