import PropTypes from "prop-types";
import React from "react";

import { colors } from "Constants";

const CloseIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      {props.type !== "hollow" ? (
        <path
          d="M16,0 C24.83656,0 32,7.16344 32,16 C32,24.83656 24.83656,32 16,32 C7.16344,32 0,24.83656 0,16 C0,7.16344 7.16344,0 16,0 Z M21.80589,10.18837 C21.56081,9.93721 21.1562,9.93721 20.90249,10.18837 L20.90249,10.18837 L16.00076,15.091 L11.09903,10.18837 L11.01975,10.12056 C10.76953,9.93972 10.41616,9.96233 10.18708,10.18837 C9.93764,10.43863 9.93764,10.84833 10.18708,11.09777 L10.18708,11.09777 L15.08881,16.00205 L10.18708,20.90124 L10.11973,20.98122 C9.94014,21.2334 9.96258,21.58706 10.18708,21.81236 C10.3088,21.93835 10.47506,22 10.64133,22 C10.80849,22 10.97048,21.93835 11.09558,21.81236 L11.09558,21.81236 L16.00076,16.91055 L20.90249,21.81236 L20.98213,21.88026 C21.09382,21.96055 21.22547,22 21.35164,22 C21.52308,22 21.68507,21.93835 21.80589,21.81236 C22.06388,21.56202 22.06388,21.15322 21.80589,20.90124 L21.80589,20.90124 L16.90416,16.00205 L21.80589,11.09777 L21.87555,11.01845 C22.0613,10.76799 22.03808,10.41361 21.80589,10.18837 Z"
          fill={props.fill}
        />
      ) : (
        <path
          d="M17.08 15.87C17.41 16.2 17.41 16.74 17.08 17.07C16.91 17.24 16.69 17.32 16.48 17.32C16.26 17.32 16.04 17.24 15.87 17.07L9.07001 10.27L2.27001 17.07C2.11001 17.24 1.89001 17.32 1.67001 17.32C1.46001 17.32 1.24001 17.24 1.07001 17.07C0.74001 16.74 0.74001 16.2 1.07001 15.87L7.87001 9.07L1.07001 2.27C0.74001 1.94 0.74001 1.4 1.07001 1.07C1.40001 0.730002 1.94001 0.730002 2.27001 1.07L9.07001 7.87L15.87 1.07C16.21 0.730002 16.75 0.730002 17.08 1.07C17.41 1.4 17.41 1.94 17.08 2.27L10.28 9.07L17.08 15.87Z"
          fill={props.fill}
        />
      )}
    </svg>
  );
};

CloseIcon.defaultProps = {
  height: 32,
  width: 32,
  type: "filled",
};

CloseIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string,
};

export default CloseIcon;
