import React, { useState, useEffect } from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { UIModal, I18nHOC, FullScreenLoader } from "UIComponents";
import { setToastMsg } from "Login/modules/LoginModule";
import StepHeader from "AppComponents/StepHeader";
import StepContainer from "./components/StepContainer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import classes from "./ImportResourceModal.scss";

const ImportResourceModal = props => {
  const {
    steps,
    isFullScreen,
    createHeader,
    mode,
    resourceSelection,
    t,
    setToastMsg,
    onClose,
    createItemMutation,
  } = props;

  const getSelectedItems = () => {
    return {
      unitPlanId: "",
      unitPlanTemplateId: "",
      unitPlanTitle: "",
      unitPlanSubTitle: "",
      unitPlanImage: "",
      selectedAssessmentId: "",
      selectedAssessmentIds: [],
      selectedCourses: [],
      selectedUnitTypes: [],
    };
  };

  const [currentStep, setCurrentStep] = useState(steps[0].value);
  const [selectedItems, setSelectedItems] = useState(getSelectedItems());
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const isLast = steps.last().value == currentStep;
  const isFirst = steps.first().value == currentStep;

  const onClickNext = () => {
    setCurrentStep(
      steps.next({ current: currentStep, findKey: "value" }).value
    );
  };

  const onClickPrevious = () => {
    setCurrentStep(
      steps.previous({ current: currentStep, findKey: "value" }).value
    );
  };

  const onClickCancel = () => {
    onClose();
  };

  const onClickDone = async () => {
    switch (currentStep) {
      case "LE_SELECTION": {
        if (selectedItems.isMultiSelect) {
          const assessmentIds = _.get(
            selectedItems,
            "selectedAssessmentIds",
            []
          );
          const resourceItems = _.map(assessmentIds, id => {
            return { itemId: id, type: "ASSESSMENT" };
          });
          try {
            setIsFullScreenLoading(true);
            await createItemMutation(resourceItems);
            onClose();
          } catch {
            setIsFullScreenLoading(false);
          }
        } else {
          const selectedAssessmentId = _.get(
            selectedItems,
            "selectedAssessmentId",
            ""
          );
          createItemMutation(selectedAssessmentId);
        }
      }
    }
  };

  const handleNextButtonValidation = step => {
    isNextDisabled
      ? setToastMsg("toastMsgs:please_select_atleast_one_item_error")
      : setCurrentStep(step);
  };

  const onClickStep = step => {
    const previousStep = steps.previous({
      current: currentStep,
      findKey: "value",
    });

    if (_.get(previousStep, "value", "") === step) {
      setCurrentStep(step);
    } else {
      switch (currentStep) {
        case "UNIT_SELECTION":
        case "COMMUNITY_LE_SELECTION":
        case "LE_LIBRARY_LE_SELECTION":
        case "LE_SELECTION":
          handleNextButtonValidation(step);
          break;
      }
    }
  };

  const updateIsNextDisabled = () => {
    switch (currentStep) {
      case "UNIT_SELECTION": {
        const isUnitIdEmpty = _.isEmpty(_.get(selectedItems, "unitPlanId", ""));
        setIsNextDisabled(isUnitIdEmpty);
        break;
      }
      case "LE_SELECTION": {
        if (selectedItems.isMultiSelect) {
          const isSelectedAssessmentIdsEmpty = _.isEmpty(
            _.get(selectedItems, "selectedAssessmentIds", "")
          );
          setIsNextDisabled(isSelectedAssessmentIdsEmpty);
        } else {
          const isSelectedAssessmentIdEmpty = _.isEmpty(
            _.get(selectedItems, "selectedAssessmentId", "")
          );
          setIsNextDisabled(isSelectedAssessmentIdEmpty);
        }
        break;
      }
    }
  };

  useEffect(() => {
    updateIsNextDisabled();
  }, [steps, selectedItems, currentStep]);

  // check if current step has multi select enabled for le feed cards and to show LE Preview or not
  useEffect(() => {
    const currentStepData = _.find(steps, step => step.value === currentStep);
    const isMultiSelect = _.get(currentStepData, "config.isMultiSelect", false);
    const showLEPreview = _.get(currentStepData, "config.showLEPreview", true);
    setSelectedItems(prev => {
      return {
        ...prev,
        isMultiSelect,
        showLEPreview,
      };
    });
  }, [currentStep]);

  return (
    <UIModal isOpen={true} isFullScreen={isFullScreen}>
      <Header
        createHeader={createHeader}
        onClickBack={onClickCancel}
        selectedItems={selectedItems}
        currentStep={currentStep}
        mode={mode}
      />
      {_.size(steps) > 1 && (
        <StepHeader
          steps={steps}
          currentStep={currentStep}
          onClickStep={onClickStep}
        ></StepHeader>
      )}
      <StepContainer
        currentStep={currentStep}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <Footer
        isLast={isLast}
        isFirst={isFirst}
        onClickNext={onClickNext}
        onClickPrevious={onClickPrevious}
        onClickCancel={onClickCancel}
        onClickDone={onClickDone}
        isNextDisabled={isNextDisabled}
        mode={mode}
        currentStep={currentStep}
        steps={steps}
        resourceSelection={resourceSelection}
        t={t}
        isDoneButtonDisabled={isNextDisabled}
      />

      {isFullScreenLoading && <FullScreenLoader />}
    </UIModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapActionCreators = {
  setToastMsg,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common"] })
)(ImportResourceModal);
