import { AttachmentList } from "Attachment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { I18nHOC, ProfileCascade, TextMoreLess, UIModal } from "UIComponents";
import classes from "./StudentResponse.scss";
import { TableColored, GoalsOutlined } from "@toddle-design/web-icons";
import { Button } from "@toddle-design/web";
import { ASSESSMENT_TOOLS, getCriteriaSetOfPypType } from "modules/Services";
import { AssessmentToolPreviewModalV2 } from "UnitPlans/components/LibraryCommonComponents";
import _ from "lodash";
import {
  getAttachmentsToDisplayWithPostConfig,
  checkAchievementCriteriaExists,
  checkAssessmentHasPypEvaluation,
} from "ClassRoom/modules/utils";
import classNames from "classnames";
import PypEvaluation from "AssessmentEvaluation/components/PypEvaluation";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";

const styles = {
  profileCascadeStyle: {
    borderWidth: 0,
    width: 20,
    height: 20,
  },
  rubricConStyle: {
    width: "100%",
  },
  avatarStyle: {
    size: "x-small",
    shade: "light",
    randomizeColor: true,
  },
};

export const StudentResponseProfile = props => {
  const { student, t } = props;
  const { id, firstName, lastName, profileImage } = student;
  return (
    <div className={classes.profileContainer}>
      <ProfileCascade
        items={[
          {
            id: id,
            value: `${firstName} ${lastName ?? ""}`,
            imageUrl: profileImage,
          },
        ]}
        showAvatar={true}
        avatarStyles={styles.avatarStyle}
      />

      <div className={classes.name}>
        {t("common:response_with_name", { name: firstName })}
      </div>
    </div>
  );
};

const StudentResponseEvaluation = props => {
  const {
    assessmentToolType,
    evaluationTextStyle,
    isPreviewCard,
    setShowAssessmentTool,
    t,
    isAttachmentGroupEmpty,
    setShowRateLearningGoalsModal,
    showRateLearningGoals,
  } = props;

  const evaluationContentContainerClass = classNames({
    [classes.evaluationContentContainer]: !isPreviewCard,
    [classes.evaluationContentContainerPreview]: isPreviewCard,
  });

  const evaluationContentClass = classNames({
    [classes.evaluationContent]: true,
    [classes.evaluationContentPreview]: isPreviewCard,
  });
  const showAssessmentToolCard =
    !_.isEqual(assessmentToolType, "NONE") &&
    !_.isEqual(assessmentToolType, null);

  return (
    (showAssessmentToolCard || showRateLearningGoals) && (
      <div
        className={classes.evaluationContainer}
        style={{
          marginLeft: isPreviewCard && !isAttachmentGroupEmpty ? "16px" : "",
        }}
      >
        {!isPreviewCard && (
          <div className={classes.evaluationText} style={evaluationTextStyle}>
            {t("common:evaluation")}
          </div>
        )}
        <div className={evaluationContentContainerClass}>
          {showAssessmentToolCard && (
            <div
              className={evaluationContentClass}
              onClick={() => setShowAssessmentTool(true)}
            >
              <div className={classes.evaluationIcon}>
                <TableColored size={"xx-small"} />
              </div>
              <div className={classes.evaluationTitle}>
                {t(
                  _.get(
                    _.find(
                      ASSESSMENT_TOOLS,
                      item => _.get(item, "type") == assessmentToolType
                    ),
                    "locale",
                    ""
                  )
                )}
              </div>
            </div>
          )}
          {showRateLearningGoals && (
            <div
              className={evaluationContentClass}
              onClick={() => setShowRateLearningGoalsModal(true)}
              style={{
                marginLeft:
                  isPreviewCard && showAssessmentToolCard ? "16px" : "",
              }}
            >
              <div className={classes.evaluationIcon}>
                <GoalsOutlined size={"xx-small"} variant={"link"} />
              </div>
              <div className={classes.evaluationTitle}>
                {t("common:learning_goals")}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const RateOnLearningGoalsModalContent = props => {
  const {
    isComplete,
    parentId,
    parentType,
    showToggleStudentPypEvaluateStatus,
    evaluateIBPYPElements,
    studentDetails,
    assessmentFields,
    ibPYPElementRatings,
    isEvaluated,
    curriculumProgramType,
    course,
    t,
    setShowRateLearningGoalsModal,
  } = props;
  return (
    <React.Fragment>
      <div className={classes.modalHeader}>
        <span>{t("common:learning_goals")}</span>
        <span className={classes.actionButtons}>
          <Button
            variant={"outlined"}
            size={"medium"}
            onClick={() => setShowRateLearningGoalsModal(false)}
          >
            {t("common:cancel")}
          </Button>
        </span>
      </div>
      <div className={classes.modalBody}>
        <div className={classes.innerWrapper}>
          <PypEvaluation
            isComplete={isComplete}
            parentId={parentId}
            parentType={parentType}
            showToggleStudentPypEvaluateStatus={
              showToggleStudentPypEvaluateStatus
            }
            evaluateIBPYPElements={evaluateIBPYPElements}
            studentDetails={studentDetails}
            assessmentFields={assessmentFields}
            ibPYPElementRatings={ibPYPElementRatings}
            isEvaluated={isEvaluated}
            curriculumProgramType={curriculumProgramType}
            course={course}
            isPreviewMode={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const RateOnLearningGoalsPreviewModal = props => {
  const { setShowRateLearningGoalsModal } = props;
  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      onRequestClose={() => setShowRateLearningGoalsModal(false)}
    >
      <RateOnLearningGoalsModalContent {...props} />
    </UIModal>
  );
};

const StudentResponse = props => {
  const {
    t,
    item,
    showSeeMore,
    containerStyle,
    attachmentListContainerStyle,
    responseStyle,
    horizontalCardStyle,
    showStudentProfile,
    showEvaluation,
    isPreviewCard,
    evaluationTextStyle,
    attachmentsContainerStyle,
    course,
    curriculumProgramType,
    getCriteriaSetOfPypType,
  } = props;

  const student = _.get(item, "student", {});
  const studentAssignmentId = _.get(item, "id", "");
  const content = _.get(item, "assignment.content", {});
  const assessmentToolId = _.get(content, "assessmentTool.id", "");
  const assessmentToolType = _.get(content, "assessmentToolType", "");
  const submission = _.get(item, "submission", {});
  const response = _.get(submission, "response", "");
  const attachmentGroups = _.get(submission, "attachmentGroups", {});
  const attachmentsToDisplay = getAttachmentsToDisplayWithPostConfig({
    attachmentGroups,
  });
  const toolResponses = _.get(item, "toolResponses", {});
  const ibPYPElementRatings = _.get(item, "ibPYPElementRatings", []);
  const isEvaluatedByStudent = _.get(
    item,
    "assignment.isStudentEvaluationEnabled",
    true
  );
  const isTeacherEvaluationEnabled = _.get(
    item,
    "assignment.isTeacherEvaluationEnabled",
    true
  );

  const commentMessage = _.get(
    _.find(
      _.get(item, "elementRemarks", []),
      ({ ibPYPElementType }) => ibPYPElementType === "STUDENT_ASSIGNMENT"
    ),
    "message",
    ""
  );
  const assessmentFields = _.get(
    content,
    "allFields",
    _.get(content, "fields", [])
  );
  const field_list = _.get(content, "template.body.field_list", []);
  const unitPlanFields = _.get(item, "unitPlanFields", []);

  const [showAssessmentTool, setShowAssessmentTool] = useState(false);
  const [showRateLearningGoalsModal, setShowRateLearningGoalsModal] = useState(
    false
  );

  const isEmptyAchievementCriteria = checkAchievementCriteriaExists({
    assessmentFields,
    curriculumProgramType,
    getCriteriaSetOfPypType,
  });

  const showRateLearningGoals =
    isTeacherEvaluationEnabled &&
    checkAssessmentHasPypEvaluation({
      assessmentDetails: content,
      curriculumProgramType,
    }) &&
    !isEmptyAchievementCriteria;

  const onClickResponse = e => {
    const { onClickResponse } = props;
    if (onClickResponse) {
      onClickResponse(e);
    }
  };

  const isAttachmentGroupEmpty = _.isEmpty(attachmentGroups);
  const isResponseEmpty = _.isEmpty(response);

  const innerContainerClass = classNames({
    [classes.innerContainer]: true,
    [classes.innerContainerPreview]: isPreviewCard,
  });

  const wrapperClass = classNames({
    [classes.wrapper]: !isPreviewCard,
    [classes.wrapperPreview]: isPreviewCard,
  });

  return (
    <div
      className={classes.container}
      style={containerStyle}
      onClick={onClickResponse}
    >
      <div className={innerContainerClass}>
        {showStudentProfile && (
          <StudentResponseProfile student={student} t={t} />
        )}
        {!_.isEmpty(response) && (
          <div className={classes.response} style={responseStyle}>
            {showSeeMore ? (
              <TextMoreLess value={response} minHeight={48} />
            ) : (
              response
            )}
          </div>
        )}

        <div className={wrapperClass}>
          {!isAttachmentGroupEmpty && (
            <div
              className={classes.attachmentListContainer}
              style={attachmentsContainerStyle}
            >
              <AttachmentList
                value={attachmentsToDisplay}
                mode={"view"}
                cardType={"horizontalV3"}
                showCreateDropdown={false}
                containerStyle={attachmentListContainerStyle}
                horizontalCardStyle={horizontalCardStyle}
              />
            </div>
          )}
          <StudentResponseEvaluation
            assessmentToolType={assessmentToolType}
            evaluationTextStyle={evaluationTextStyle}
            showEvaluation={showEvaluation}
            isPreviewCard={isPreviewCard}
            setShowAssessmentTool={setShowAssessmentTool}
            t={t}
            isAttachmentGroupEmpty={isAttachmentGroupEmpty}
            setShowRateLearningGoalsModal={setShowRateLearningGoalsModal}
            showRateLearningGoals={showRateLearningGoals}
          />
        </div>
        {showAssessmentTool && (
          <AssessmentToolPreviewModalV2
            id={assessmentToolId}
            type={assessmentToolType}
            mode={"view"}
            toolResponses={toolResponses}
            title={t(
              _.get(
                _.find(
                  ASSESSMENT_TOOLS,
                  item => item.type == assessmentToolType
                ),
                "locale",
                ""
              )
            )}
            showChooseButton={false}
            onClickClose={() => setShowAssessmentTool(false)}
            isEvaluatedByStudent={isEvaluatedByStudent}
            unitPlanFields={unitPlanFields}
            assessmentFields={assessmentFields}
            field_list={field_list}
            parentId={studentAssignmentId}
            parentType={"STUDENT_ASSIGNMENT"}
            studentid={_.get(student, "id", "")}
            ibPYPElementRatings={ibPYPElementRatings}
            isTeacherEvaluationEnabled={isTeacherEvaluationEnabled}
            commentMessage={commentMessage}
          />
        )}
        {showRateLearningGoalsModal && (
          <RateOnLearningGoalsPreviewModal
            isComplete={true}
            parentId={studentAssignmentId}
            parentType={"STUDENT_ASSIGNMENT"}
            showToggleStudentPypEvaluateStatus={false}
            evaluateIBPYPElements={true}
            studentDetails={student}
            assessmentFields={assessmentFields}
            ibPYPElementRatings={ibPYPElementRatings}
            isEvaluated={true}
            curriculumProgramType={curriculumProgramType}
            course={course}
            setShowRateLearningGoalsModal={setShowRateLearningGoalsModal}
            t={t}
          />
        )}
      </div>
    </div>
  );
};

StudentResponse.defaultProps = {
  showStudentProfile: true,
  showEvaluation: true,
};

const mapStateToProps = (state, ownProps) => {
  const courseId = _.get(state, "teacher.selected_class.selected_course");
  const course = getCourseDetailsFromCache({ id: courseId });
  const curriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType",
    "IB_PYP"
  );

  return {
    course,
    curriculumProgramType,
  };
};

const mapActionCreators = {
  getCriteriaSetOfPypType,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators)
)(StudentResponse);
