import React, { useState } from "react";
import classes from "./FilterHeader.scss";
import { SearchBar, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import {
  CloseOutlined,
  InformationFilled,
  SettingsOutlined,
} from "@toddle-design/web-icons";
import {
  Button,
  FilterLabelButton,
  ChecklistDropdown,
} from "@toddle-design/web";
import { getLabelValueForMultiFilterLabelButton } from "Utils";
import { columnLabels, getSortedArrayMemoized } from "NodeEditor/Utils";
import {
  ELEMENT_TYPE_DP_SYLLABUS,
  ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK,
} from "Constants/stringConstants";

const styles = {
  searchBarContainer: {
    width: 400,
    alignItems: "center",
  },
};

const FilterHeader = props => {
  const {
    filters,
    updateFilters,
    gradeOptions,
    organizationTagsObjectMap,
    draftId,
    mode,
    loginActiveTab,
    showWarningText,
    configuredColumns,
    openedFrom,
    onUpdateSettings,
    disableFilters,
    t,
    nodeType,
  } = props;

  const [isMessageOpen, setIsWarningMessageOpen] = useState(true);

  const toggleIsMessageOpen = () =>
    setIsWarningMessageOpen(isMessageOpen => !isMessageOpen);

  const handleChangeSearchTerm = value =>
    updateFilters({
      key: "searchText",
      data: value,
    });
  const handleChangeGrades = value =>
    updateFilters({
      key: "grades",
      data: value,
    });
  const handleChangeTags = value =>
    updateFilters({
      key: "tags",
      data: value,
    });

  const handleColumnsChange = value => {
    onUpdateSettings({ value });
  };

  const getSelectedOptionsLabels = ({ options, value }) => {
    const selectedOptionsLabels = _.map(
      _.filter(options, item => _.includes(value, item.value)),
      option => option.label
    );

    return selectedOptionsLabels;
  };

  const configureColumnsDropdown = !_.isEmpty(
    columnLabels({ t, nodeType })
  ) && {
    options: columnLabels({ t, nodeType }),
    label: "configure_columns",
    value: configuredColumns,
    onChange: handleColumnsChange,
    isCheckList: true,
    hideValue: true,
    buttonComponent: (
      <Button icon={<SettingsOutlined />} size={"small"} variant={"plain"}>
        {t("common:configure_columns")}
      </Button>
    ),
  };

  const FILTER_DROPDOWN_CONFIG = [
    {
      options: getSortedArrayMemoized({ inputObject: gradeOptions }),
      value: filters.grades,
      onChange: handleChangeGrades,
      showAllOption: true,
      noResultLabel: t("common:no_label_found", {
        label: t("common:lowercase", {
          text: t("common:grade_plural"),
        }),
      }),
      hide: [ELEMENT_TYPE_DP_SYLLABUS, ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK],
      buttonComponent: (
        <FilterLabelButton
          size={"small"}
          variant={"plain"}
          label={t("common:grade_plural")}
          labelValue={getLabelValueForMultiFilterLabelButton({
            options: getSortedArrayMemoized({ inputObject: gradeOptions }),
            selectedOptions: getSelectedOptionsLabels({
              options: gradeOptions,
              value: filters.grades,
            }),
            t,
            allText: t("common:all_label", {
              label: t("common:grade_plural"),
            }),
            noneText: t("common:none"),
          })}
        />
      ),
      isSearchEnabled: true,
    },
    {
      options: getSortedArrayMemoized({
        inputObject: organizationTagsObjectMap,
      }),
      value: filters.tags,
      onChange: handleChangeTags,
      showAllOption: true,
      noResultLabel: t("common:no_label_found", {
        label: t("common:lowercase", {
          text: t("common:tags"),
        }),
      }),
      hide: [ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK],
      buttonComponent: (
        <FilterLabelButton
          size={"small"}
          variant={"plain"}
          label={t("common:tags")}
          labelValue={getLabelValueForMultiFilterLabelButton({
            options: getSortedArrayMemoized({
              inputObject: organizationTagsObjectMap,
            }),
            selectedOptions: getSelectedOptionsLabels({
              options: organizationTagsObjectMap,
              value: filters.tags,
            }),
            t,
            allText: t("common:all_label", {
              label: t("common:tags"),
            }),
            noneText: t("common:none"),
          })}
        />
      ),
      isSearchEnabled: true,
    },
  ];

  let finalFilters = FILTER_DROPDOWN_CONFIG;
  if (openedFrom && configureColumnsDropdown)
    finalFilters = [...finalFilters, configureColumnsDropdown];

  const renderFilterDropDowns = _.map(finalFilters, filterDropdownProps => {
    if (!_.includes(_.get(filterDropdownProps, "hide", []), nodeType)) {
      const { buttonComponent, ...rest } = filterDropdownProps;
      return (
        <React.Fragment key={filterDropdownProps.label}>
          <ChecklistDropdown
            {...rest}
            disabled={disableFilters}
            checklistContainerClassName={classes.dropdownClasses}
          >
            {buttonComponent}
          </ChecklistDropdown>
        </React.Fragment>
      );
    }
  });

  const warningText = (
    <div className={classes.warningContainer}>
      <div className={classes.svgContainerWarning}>
        <InformationFilled variant="warning" />
      </div>
      <div className={classes.warningText}>
        {t("scopeAndSequence:draft_exists_text")}
      </div>
      <div className={classes.svgContainerClose} onClick={toggleIsMessageOpen}>
        <CloseOutlined size={"xx-small"} />
      </div>
    </div>
  );

  const isWarningMessageOpen =
    draftId &&
    showWarningText &&
    mode == "view" &&
    _.includes(["admin", "school-onboarding"], loginActiveTab) &&
    isMessageOpen;

  return (
    <div className={classes.container}>
      <div className={classes.filtersContainer}>
        <div className={classes.leftContainer}>
          <SearchBar
            placeholder={
              _.includes(
                [
                  "DP_SYLLABUS",
                  "DP_SUBJECT_STANDARD",
                  "DP_KNOWLEDGE_FRAMEWORK",
                ],
                nodeType
              )
                ? t("common:search")
                : t("academicSetup:search_for_standard")
            }
            customStyle={styles.searchBarContainer}
            searchTerm={filters.searchText}
            changeSearchTerm={handleChangeSearchTerm}
            shouldAnimate={false}
            wrapperType={"box"}
            debounceDuration={600}
          />
        </div>
        <div className={classes.rightContainer}>{renderFilterDropDowns}</div>
      </div>
      {isWarningMessageOpen && warningText}
    </div>
  );
};

export default compose(
  I18nHOC({
    resource: ["academicSetup", "common", "scopeAndSequence"],
  })
)(FilterHeader);
