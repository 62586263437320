import PropTypes from "prop-types";
import React from "react";
import classes from "./MultiField.scss";
import classNames from "classnames";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { LabelComponent } from "UnitPlans/components/LabelComponent";
import FieldComponent from "../FieldComponent";
import update from "immutability-helper";
import { fontStyle } from "Constants";
import { EmptyField, I18nHOC } from "UIComponents";

const styles = {
  labelStyle: {
    fontSize: "1.4rem",
    ...fontStyle.medium,
  },
  labelContainerStyle: {
    marginBottom: "4px",
  },
  fieldLabel: {
    fontSize: "1.4rem",
    ...fontStyle.medium,
  },
};

class MultiField extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.fieldRefs = {};
  }

  updateInputFieldLocally = ({ params }) => {
    let updatedValue = {};
    const { value } = this.props;

    Object.keys(params).map((key, index) => {
      updatedValue = update(value, {
        [key]: {
          $set: params[key],
        },
      });
    });

    this.updateValue(updatedValue);
  };

  onFocusInputField = ({ key }) => {
    const { onFocusInputField } = this.props;
    onFocusInputField({ others: { id: key } });
  };

  onBlurInputField = ({ key }) => {
    const { onBlurInputField } = this.props;
    onBlurInputField({ others: { id: key } });
  };

  blur = () => {
    _.map(this.fieldRefs, ref => {
      if (ref) {
        ref.blur();
      }
    });
  };

  setFieldRef = (key, ref) => {
    this.fieldRefs[key] = ref;
  };

  renderEditEmpty = () => {
    const { emptyText, emptyContainerStyle } = this.props;
    return (
      !!emptyText && (
        <EmptyField containerStyle={emptyContainerStyle} title={emptyText} />
      )
    );
  };

  renderEdit = () => {
    const {
      subFields,
      subFieldAlignment = "horizontal",
      value,
      mode,
      containerStyle,
      fieldLockedObject,
      helpEnabledFields,
      onHelpClick,
      getIsHelpActive,
      t,
    } = this.props;

    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.rowContainer]: subFieldAlignment == "vertical" }
    );

    const { lockedDynamicFields } = fieldLockedObject;

    return (
      <div className={containerClass} style={containerStyle}>
        {_.map(subFields, field => {
          const { key, config, type, viewType } = field;
          const lockedField = _.find(lockedDynamicFields, { id: key });
          const { label, subtext } = config;
          const subFieldValue = (value || {})[key];

          const helper = _.find(helpEnabledFields, eachField => {
            return _.get(eachField, "uid", "") === key;
          });

          const helperTextId = _.get(helper, "helpText.id", "");

          const labelComponent = (
            <LabelComponent
              {...config}
              label={label}
              showHelper={!_.isEmpty(helper)}
              isHelpActive={getIsHelpActive({ fielduid: key })}
              onHelpClick={() =>
                onHelpClick({
                  fieldKey: key,
                  helperTextId: helperTextId,
                  id: key,
                })
              }
              t={t}
              isFieldHeader={true}
            />
          );

          return (
            <FieldComponent
              {...config}
              value={subFieldValue}
              key={key}
              labelComponent={labelComponent}
              subText={subtext}
              customRef={this.setFieldRef}
              onFocusInputField={this.onFocusInputField}
              onBlurInputField={this.onBlurInputField}
              type={mode == "edit" ? type : viewType}
              fieldKey={key}
              fieldLockedObject={{ lockedField }}
              updateInputField={this.updateInputFieldLocally}
              containerStyle={{ marginTop: 0 }}
              mode={mode}
              styles={styles}
              // fieldTypeConfig={styles}
            />
          );
        })}
      </div>
    );
  };

  shouldShowViewEmpty = () => {
    return false;
  };

  shouldShowEditEmpty = () => {
    const { subFields } = this.props;
    return _.isEmpty(subFields);
  };

  renderView = () => {
    return this.renderEdit();
  };
}

MultiField.defaultProps = {
  ...UIBaseComponent.defaultProps,
  containerStyle: {},
  isHelpActive: _.noop,
  getIsHelpActive: _.noop,
};

MultiField.propTypes = {
  ...UIBaseComponent.propTypes,
  isHelpActive: PropTypes.func,
  getIsHelpActive: PropTypes.func,
};

export default I18nHOC({ resource: "unitPlan" })(MultiField);

//Need Review @kunal
