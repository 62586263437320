import { colors as themeColors } from "@toddle-design/theme";
const localColors = {
  /* not present in theme */
  strokeOne: "#ebebeb", //colors.borderSubtle
  strokeTwo: "#dbdbdb", //colors.borderDefault
  salmon60: "#f75961", //possibly replace it with colors.red66 everywhere
  warning50: "#ffab00",
  link29: "#008392",

  /* different color hex in theme for same keys */
  iconDefault: "#484848",
  iconDisabled: "#dbdbdb",
};

export const colors = { ...themeColors, ...localColors };

export const colorPalette = [
  colors.seaweed,
  colors.blue29,
  colors.yellow50,
  colors.salmon60,
  colors.blue29,
  colors.violet63,
];

export const fontWeight = {
  AvenirNextMedium: "500",
  AvenirNextDemiBold: "600",
  AvenirNextBold: "bold",
};

export const AVATAR_COLORS = ["violet", "blue", "yellow", "pink", "teal"];

export const AVATAR_TO_NORMAL_COLORS_MAPPING = {
  violet: colors.violet46,
  blue: colors.blue23,
  yellow: colors.yellow26,
  pink: colors.pink39,
  teal: colors.blue23,
};
