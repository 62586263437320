import gql from "graphql-tag";

export const similarityFragment = {
  similarityItem: gql`
    fragment similarityItem on SimilarityReport {
      status
      submissionId
      errorCode
      overallMatchPercentage
    }
  `,
};

export const taggedPracticesFragment = {
  taggedPractices: gql`
    fragment taggedPracticesOnEvidence on SnPTaggedPractice {
      evaluationCycle {
        id
      }
      taggedPractice {
        taggedBy {
          id
        }
        practice {
          id
        }
        evidenceStatus
      }
    }
  `,
};

export const courseFragment = {
  basicCourserDetails: gql`
    fragment courseItem on Course {
      id
      title
      isArchived
    }
  `,
  courserGradesDetails: gql`
    fragment courseGradeItem on Course {
      id
      title
      profileImage
      isArchived
      grades {
        id
        name
      }
    }
  `,
  courseGradeSubjectDetails: gql`
    fragment courseGradeSubjectItem on Course {
      id
      title
      profileImage
      isArchived
      grades {
        id
        name
      }
      subjects {
        id
      }
    }
  `,
};

export const nodeEditorFragment = {
  MultiLevelNodeBenchmarkDetails: gql`
    fragment MultiLevelNodeBenchmarkItem on MultiLevelNodeBenchmark {
      id
      label
      parentId
      children
      depth
      code
      grades {
        id
        name
      }
      tags {
        id
      }
      benchmarkQuestionCount {
        count
        url
        color
        yearSplit
      }
      # type # causing issue with the type in PlannerElementNode but this is not used in NodeEditor
      subjectId
    }
  `,
  MultiLevelNodePlannerElementDetails: gql`
    fragment MultiLevelNodePlannerElementItem on MultiLevelNodePlannerElement {
      id
      label
      parentId
      children
      depth
      code
      grades {
        id
        name
        __typename
      }
      tags {
        id
        __typename
      }
      __typename
    }
  `,
};

export const notificationConnectionFragment = {
  notificationConnection: gql`
    fragment notificationConnectionItem on NotificationConnection {
      totalCount
      newCount
      earlierCount
      categorisedNewCount {
        category
        count
      }
    }
  `,
};

export const pageInfoFragment = {
  pageInfo: gql`
    fragment pageInfoItem on PageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  `,
};

export const settingFragment = {
  setting: gql`
    fragment settingItem on GenericSetting {
      name
      value
    }
  `,
};

export const notificationFragment = {
  staffCountItem: gql`
    fragment staffCountItem on Staff {
      id
      notification(notificationCategoryFilter: $notificationCategoryFilter) {
        ...notificationConnectionItem
      }
    }
    ${notificationConnectionFragment.notificationConnection}
  `,
  studentCountItem: gql`
    fragment studentCountItem on Student {
      id
      notification(notificationCategoryFilter: $notificationCategoryFilter) {
        ...notificationConnectionItem
      }
    }
    ${notificationConnectionFragment.notificationConnection}
  `,
  parentCountItem: gql`
    fragment parentCountItem on FamilyMember {
      id
      notification(notificationCategoryFilter: $notificationCategoryFilter) {
        ...notificationConnectionItem
      }
    }
    ${notificationConnectionFragment.notificationConnection}
  `,
  notificationItem: gql`
    fragment notificationItem on Notification {
      id
      message
      type
      isNew
      isRead
      metadata
      category
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        profileImage
      }
      icons {
        type
        url
      }
      course {
        ...courseItem
        grades {
          id
          name
        }
        curriculumProgram {
          id
          type
        }
      }
      courses {
        id
        title
        curriculumProgram {
          id
          type
        }
      }
      updatedAt
      localizationKey
      tokens
    }
    ${courseFragment.basicCourserDetails}
  `,
};

export const notificationListNodeFragment = {
  staff: gql`
    fragment staffItem on Staff {
      id
      notification(
        appType: WEB
        notificationCategoryFilter: $notificationCategoryFilter
      ) {
        ...notificationConnectionItem
        edge(first: 10, after: $after) {
          cursor
          node {
            ...notificationItem
          }
        }
        pageInfo(first: 10, after: $after) {
          ...pageInfoItem
        }
      }
    }
    ${pageInfoFragment.pageInfo}
    ${notificationConnectionFragment.notificationConnection}
    ${notificationFragment.notificationItem}
  `,
  student: gql`
    fragment studentItem on Student {
      id
      notification(
        appType: WEB
        notificationCategoryFilter: $notificationCategoryFilter
      ) {
        ...notificationConnectionItem
        edge(first: 10, after: $after) {
          cursor
          node {
            ...notificationItem
          }
        }
        pageInfo(first: 10, after: $after) {
          ...pageInfoItem
        }
      }
    }
    ${pageInfoFragment.pageInfo}
    ${notificationConnectionFragment.notificationConnection}
    ${notificationFragment.notificationItem}
  `,
  parent: gql`
    fragment parentItem on FamilyMember {
      id
      notification(
        appType: WEB
        notificationCategoryFilter: $notificationCategoryFilter
      ) {
        ...notificationConnectionItem
        edge(first: 10, after: $after) {
          cursor
          node {
            ...notificationItem
          }
        }
        pageInfo(first: 10, after: $after) {
          ...pageInfoItem
        }
      }
    }
    ${pageInfoFragment.pageInfo}
    ${notificationConnectionFragment.notificationConnection}
    ${notificationFragment.notificationItem}
  `,
};

export const designationFragment = {
  designation: gql`
    fragment designationItem on StaffDesignation {
      id
      title
      description
      roles
    }
  `,
};

export const parentFragment = {
  basicParentDetails: gql`
    fragment parentItem on FamilyMember {
      id
      firstName
      lastName
      profileImage
      type
      Email: email
      isDevUser
    }
  `,
};

export const periodFragment = {
  period: gql`
    fragment periodItem on Period {
      id
      label
      type
      startTime
      endTime
      sequence
    }
  `,
};

export const periodSetFragment = {
  periodSet: gql`
    fragment periodSetItem on PeriodSet {
      id
      count
      periods {
        ...periodItem
      }
    }
    ${periodFragment.period}
  `,
};

export const curriculumFragment = {
  curriculum: gql`
    fragment curriculumItem on Curriculum {
      id
      label
      type
    }
  `,
};

export const curriculumProgramFragment = {
  curriculumProgramBasicDetails: gql`
    fragment curriculumProgramBasicDetailsItem on CurriculumProgram {
      id
      type
      nodeInterfaceType
      label
      acronym
    }
  `,
  subscriptionPlanDetails: gql`
    fragment subscriptionPlanDetailsItem on CurriculumProgram {
      id
      type
      subscriptionPlan {
        id
        type
        isFree
        label
      }
    }
  `,
  periodSet: gql`
    fragment curriculumProgramItem on CurriculumProgram {
      id
      type
      nodeInterfaceType
      label
      acronym
      curriculum {
        ...curriculumItem
      }
      periodSet(filter: $periodSetFilter) {
        ...periodSetItem
      }
      attendanceRecordingType
    }
    ${curriculumFragment.curriculum}
    ${periodSetFragment.periodSet}
  `,
};

export const childFragment = {
  basicChildInfo: gql`
    fragment childItem on Student {
      id
      firstName
      lastName
    }
  `,
  basicChildDetails: gql`
    fragment childItem on Student {
      id
      email
      jwt
      profileImage
      firstName
      lastName
      isDevUser
      parentStatus(parentId: $id)
      isRequestAccepted
    }
  `,

  childDetails: gql`
    fragment childDetailsItem on Student {
      curriculumProgram {
        id
        type
      }
      curriculumPrograms {
        id
        type
      }
      allCourses {
        id
        academicYears {
          id
        }
        curriculumProgram {
          ...curriculumProgramBasicDetailsItem
        }
      }
      organization {
        id
        name
        tier {
          isFree
        }
        academicYears {
          id
          isCurrentAcademicYear
        }
      }
    }
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
  `,
};

export const coachMarkFragment = {
  coachMark: gql`
    fragment coachMarkItem on CoachMarkUserStatus {
      id
      coachMark
      isViewed
      count
    }
  `,
};

export const staffFragment = {
  basicStaffDetailsCommunity: gql`
    fragment staffItem on Staff {
      id
      firstName
      lastName
      profileImage
      email
      showPitchScreen
      showClassSelectionScreen
      showBifurcationScreen
      isRequestedForPlanathon
      schoolTenures(currentSchool: true) {
        id
        school {
          id
          name
        }
      }
    }
  `,
  basicStaffDetails: gql`
    fragment staffItem on Staff {
      id
      firstName
      lastName
      profileImage
      email
      type
      showPitchScreen
      showClassSelectionScreen
      showBifurcationScreen
      isDevUser
      entityTags {
        id
        key
        value
      }
    }
  `,
  staffCoachMarks: gql`
    fragment staffCoachMarksItem on Staff {
      id
      coachMarkStatus {
        ...coachMarkItem
      }
    }
    ${coachMarkFragment.coachMark}
  `,
  userIdentityBasicDetail: gql`
    fragment userIdentityBasicDetailItem on Staff {
      id
      firstName
      lastName
      profileImage
    }
  `,
  /**
   * @deprecated
   */
  userLinkedIdentityDetail: gql`
    fragment linkedIdentityDetailItem on Staff {
      id
      type
      designation {
        id
        title
      }
      organization {
        id
        name
        logo
      }
      jwt
    }
  `,
};

export const selectedPeriodFragment = {
  selectedPeriods: gql`
    fragment selectedPeriodItem on SelectedPeriod {
      id
      isSelected
      day
      rotationDay
      location
      course {
        ...courseItem
      }
      period {
        ...periodItem
      }
      subject
    }
    ${courseFragment.basicCourserDetails}
    ${periodFragment.period}
  `,
  selectedPeriodsInfo: gql`
    fragment selectedPeriodInfoItem on SelectedPeriod {
      id
      isSelected
      period {
        id
      }
    }
  `,
};

export const studentFragment = {
  basicStudentDetails: gql`
    fragment studentItem on Student {
      id
      firstName
      lastName
      profileImage
      email
      type
      isDevUser
      isArchived
    }
  `,
  basicStudentDetailsWithFamilyMembers: gql`
    fragment studentWithFamilyMembersItem on Student {
      id
      firstName
      lastName
      profileImage
      email
      type
      isDevUser
      isArchived
      familyMembers {
        id
        firstName
        lastName
      }
    }
  `,
  basicStudentDetailsWithBasicCourse: gql`
    fragment studentWithBasicCourseItem on Student {
      id
      firstName
      lastName
      profileImage
      email
      type
      isDevUser
      isArchived
      courses(filters: $courseFilters) {
        id
        title
      }
    }
  `,
  studentCoachMarks: gql`
    fragment studentCoachMarksItem on Student {
      id
      coachMarkStatus {
        ...coachMarkItem
      }
    }
    ${coachMarkFragment.coachMark}
  `,
  studentPeriodList: gql`
    fragment studentPeriodListItem on Student {
      id
      firstName
      lastName
      enrolledPeriods(filters: $periodFilters) {
        ...selectedPeriodItem
      }
    }
    ${selectedPeriodFragment.selectedPeriods}
  `,
  studentPeriodListInfo: gql`
    fragment studentPeriodListInfoItem on Student {
      id
      enrolledPeriods(filters: $periodFilters) {
        ...selectedPeriodInfoItem
      }
    }
    ${selectedPeriodFragment.selectedPeriodsInfo}
  `,
};

export const schoolInfoFragment = {
  userSchoolInfo: gql`
    fragment userSchoolInfo on Staff {
      schoolTenures(currentSchool: true) {
        id
        school {
          id
          name
        }
      }
    }
  `,
};

export const userFragment = {
  basicUser: gql`
    fragment userItem on User {
      id
      firstName
      lastName
      type
      email
      profileImage
      ... on Staff {
        role
        courses {
          id
          title
        }
      }
    }
  `,
  basicUserDetails: gql`
    fragment userItem on User {
      id
      firstName
      lastName
      profileImage
      email
      type
      ... on Staff {
        ...userSchoolInfo
      }
    }
    ${schoolInfoFragment.userSchoolInfo}
  `,
  /**
   * @deprecated
   */
  linkedIdentityDetails: gql`
    fragment linkedIdentityDetailsItem on User {
      id
      type
      ... on Staff {
        designation {
          id
          title
        }
        organization {
          id
          name
          logo
        }
        jwt
      }
      ... on Student {
        organization {
          id
          name
          logo
        }
        jwt
      }
    }
  `,
};

export const identityUserFragment = {
  basicDetails: gql`
    fragment identityUserBasicDetailsItem on IdentityUser {
      id
      type
      organization {
        id
        name
        logo
      }
      jwt
      designation {
        id
        title
      }
    }
  `,
};

export const academicYearFragment = {
  academicYear: gql`
    fragment academicYearItem on AcademicYear {
      id
      startDate
      endDate
      isCurrentAcademicYear
    }
  `,
};

export const unitTypeFragment = {
  unitType: gql`
    fragment unitTypeItem on UnitType {
      id
      feedLabel
      name
      curriculum {
        ...curriculumItem
      }
    }
    ${curriculumFragment.curriculum}
  `,
};

export const plannerTemplateFragment = {
  plannerTemplate: gql`
    fragment templateItem on PlannerTemplate {
      id
      body
      name
      type
      plannerName
      plannerFormat
      unitType
      curriculum {
        ...curriculumItem
      }
      helpEnabledFields {
        id
        uid
        helpText {
          id
        }
        type
      }
      subType
    }
    ${curriculumFragment.curriculum}
  `,
  plannerTemplateBasicDetails: gql`
    fragment templateBasicDetailsItem on PlannerTemplate {
      id
      name
      type
      plannerName
      assessmentType
      plannerFormat
      unitType
      curriculumProgram {
        id
      }
      curriculum {
        ...curriculumItem
      }
    }
    ${curriculumFragment.curriculum}
  `,
};

export const plannerTemplateHelpTextFragment = {
  plannerTemplateHelpText: gql`
    fragment helpTextItem on HelpText {
      id
      title
      subtitle
      content
    }
  `,
};

export const focusAreaFragment = {
  focusArea: gql`
    fragment focusAreaItem on FocusArea {
      id
      label
    }
  `,
};

export const pypElementFragment = {
  theme: gql`
    fragment themeItem on PlannerTheme {
      id
      label
      descriptor
      focusAreas {
        ...focusAreaItem
      }
    }
    ${focusAreaFragment.focusArea}
  `,
  lp: gql`
    fragment lpItem on PlannerLearnerProfile {
      id
      label
      subText
      levelId
    }
  `,
  concept: gql`
    fragment conceptItem on PlannerConcept {
      id
      label
      subText
      levelId
    }
  `,
  atl: gql`
    fragment atlItem on PlannerATL {
      id
      label
      children
      parent
      levelId
      levels {
        id
        value
      }
      depth
      isLeaf
      displaySequence
      atlCategory {
        id
      }
      subText
      grades {
        id
        name
      }
    }
  `,
  benchmark: gql`
    fragment benchmarkItem on PlannerBenchmark {
      id
      label
      children
      parent
      benchmarkQuestionCount {
        count
        color
        url
        yearSplit
      }
      levels {
        id
        value
        count
      }
      subject
      levelId
      displaySequence
      depth
      type
      code
      grades {
        id
        name
      }
      isLeaf
      genericTags: tags {
        id
        label
        icon {
          id
          url
        }
      }
    }
  `,
  benchmarkV2: gql`
    fragment benchmarkItem on PlannerBenchmark {
      id
      label
      children
      parentId: parent
      benchmarkQuestionCount {
        count
        color
        url
        yearSplit
      }
      subject
      depth
      code
      grades {
        id
        name
      }
      genericTags: tags {
        id
        label
      }
    }
  `,
  benchmarkStandard: gql`
    fragment benchmarkStandardItem on StandardBenchmark {
      id
      label
      children
      parent
      parentId
      subject
      levelId
      displaySequence
      depth
      type
      code
      grades {
        id
        name
      }
      isLeaf
    }
  `,
  relatedConcept: gql`
    fragment relatedConceptItem on PlannerRelatedConcept {
      id
      label
      elementId
      elementType
      parent
      children
    }
  `,
  action: gql`
    fragment actionItem on Action {
      id
      label
      subText
      children
      parent
      levels {
        id
        value
      }
      levelId
    }
  `,
  plannerElementNode: gql`
    fragment plannerElementNode on PlannerElementNode {
      id
      label
      depth
      children
      type
    }
  `,
};

export const standardSetFragment = {
  standardSet: gql`
    fragment standardSetItem on BenchmarkStandardSet {
      id
      title
      description
    }
  `,
  standardSetDetails: gql`
    fragment standardSetItem on BenchmarkStandardSet {
      id
      title
      description
      framework
      snsChildren
      benchmarks {
        rootNodes
        levels {
          id
          value
        }
        maxDepth
        nodes {
          ...benchmarkStandardItem
        }
      }
    }
    ${pypElementFragment.benchmarkStandard}
  `,
  standardSetFeed: gql`
    fragment standardSetItem on BenchmarkStandardSet {
      id
      title
      description
      framework
      snsChildren
    }
  `,
};

export const atlsCategoryFragment = {
  basicAtlCategoryDetails: gql`
    fragment atlCategoryItem on ATLCategory {
      id
      label
      subText
      grades {
        id
      }
    }
  `,
};

export const plannerElementDepthFragment = {
  plannerElementDepthLabels: gql`
    fragment plannerElementDepthLabelItem on PlannerElementDepthLabel {
      id
      depth
      label
      totalNodes
    }
  `,
};

export const thirdPartyAccountFragment = {
  thirdPartyAccount: gql`
    fragment thirdPartyAccountItem on LinkedThirdPartyAccount {
      id
      serviceType
      accessToken
    }
  `,
};

export const genericTagFragment = {
  genericTag: gql`
    fragment basicGenericTagItem on GenericTag {
      id
      type
      label
    }
  `,
};

export const subjectFragment = {
  basicSubjectDetails: gql`
    fragment subjectItem on Subject {
      id
      name
    }
  `,
  subjectWithSubjectGroup: gql`
    fragment subjectWithSubjectGroupItem on Subject {
      id
      name
      type
      icon
      grades {
        id
        name
      }
      subjectGroup {
        id
        name
      }
      variants {
        level {
          ...basicGenericTagItem
        }
        responseLanguages {
          ...basicGenericTagItem
        }
      }
    }
    ${genericTagFragment.genericTag}
  `,
  subjectDetails: gql`
    fragment subjectItem on Subject {
      id
      name
      icon
      grades {
        id
        name
      }
    }
  `,
  subjectBenchmarkDetails: gql`
    fragment subjectItem on Subject {
      id
      name
      icon
      grades {
        id
        name
      }
      benchmarkRootNode {
        ...benchmarkItem
      }
      globalSubject
      framework
      snsChildren
      standardSet {
        ...standardSetItem
      }
      subjectLevelLabels {
        cuLevelName
        strandLevelName
        loLevelName
      }
      type
      tags {
        id
        key
        value
      }
    }
    ${pypElementFragment.benchmark}
    ${standardSetFragment.standardSet}
  `,
  subjectPlannerElementDepth: gql`
    fragment subjectPlannerElementDepthItem on Subject {
      id
      name
      icon
      grades {
        id
        name
      }
      plannerElementDepthLabelSets(filters: $plannerElementDepthLabelFilter) {
        plannerElementDepthLabels {
          ...plannerElementDepthLabelItem
        }
      }
      tags {
        id
        key
        value
      }
    }
    ${plannerElementDepthFragment.plannerElementDepthLabels}
  `,
};

export const gradeFragment = {
  basicGradeDetails: gql`
    fragment gradeItem on Grade {
      id
      name
      globalGrade {
        id
        uid
        name
        constants
      }
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
    }
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
  `,
  gradeDetails: gql`
    fragment gradeItem on Grade {
      id
      name
      plannerTemplates {
        ...templateItem
      }
      subjects {
        ...subjectItem
        benchmarkRootNode {
          ...benchmarkItem
        }
        subjectGroup {
          id
        }
      }
      atlCategories {
        ...atlCategoryItem
      }
    }
    ${pypElementFragment.benchmark}
    ${subjectFragment.subjectBenchmarkDetails}
    ${plannerTemplateFragment.plannerTemplate}
    ${atlsCategoryFragment.basicAtlCategoryDetails}
  `,
  gradeUnitDetails: gql`
    fragment gradeItem on Grade {
      id
      name
      unitPlanCount(innerUserIdFilter: $id)
        @include(if: $isCurriculumProgramFree)
      globalGrade {
        id
        constants
      }
    }
  `,
};

export const subjectGroupFragment = {
  subjectGroupBasicDetails: gql`
    fragment subjectGroupBasicDetailsItem on SubjectGroup {
      id
      name
      constants
    }
  `,
  subjectGroupWithSubjects: gql`
    fragment subjectGroupItem on SubjectGroup {
      id
      name
      subjects(filters: $subjectFilters) {
        ...subjectItem
      }
      curriculumProgram {
       ...curriculumProgramBasicDetailsItem
      }
    }
    ${subjectFragment.subjectDetails}
    ${curriculumProgramFragment.curriculumProgramBasicDetails},
  `,
};

export const plannerElementNodeFragment = {
  plannerElementNode: gql`
    fragment plannerElementNodeItem on PlannerElementNode {
      id
      displaySequence
      label
      type
      subText
      depth
      children
      parent
      isLeaf
      code
      metadata
      updatedAt
      associatedParents {
        id
        ... on Grade {
          id
          name
        }
        ... on Subject {
          id
          name
        }
        ... on SubjectGroup {
          ...subjectGroupBasicDetailsItem
        }
        ... on PlannerElementNode {
          id
          label
          type
        }
      }
      tags: genericTags {
        id
        label
        icon {
          id
          url
        }
      }
    }
    ${subjectGroupFragment.subjectGroupBasicDetails}
  `,
  plannerElementNodeWithChild: gql`
    fragment plannerElementNodeWithChildItem on PlannerElementNode {
      id
      label
      type
      associatedParents {
        id
        ... on Course {
          id
          title
        }
      }
      associatedChildPlannerElementNodes {
        id
        label
        type
        fields {
          id
          uid
          value
        }
      }
    }
  `,
  plannerElementDepthLabels: gql`
    fragment plannerElementDepthLabelItem on PlannerElementDepthLabel {
      id
      depth
      label
      totalNodes
    }
  `,
};

export const plannerElementNodeWithDepthLabelFragment = {
  plannerElementNode: gql`
    fragment plannerElementNodeItem on PlannerElementNode {
      id
      label
      type
      subText
      depth
      children
      parent
      isLeaf
      code
      plannerElementDepthLabelSets(
        filters: $plannerElementDepthLabelSetFilters
      ) {
        plannerElementDepthLabels {
          totalNodes
          label
          depth
          id
        }
      }
      associatedParents {
        id
        ... on Grade {
          id
          name
        }
        ... on Subject {
          id
          name
        }
        ... on SubjectGroup {
          id
          name
        }
        ... on PlannerElementNode {
          id
          label
          type
        }
      }
    }
  `,
};

export const plannerElementSetWithDepthLabelFragment = {
  plannerElementSetWithNodes: gql`
    fragment plannerElementSetWithNodesItem on PlannerElementSet {
      id
      label
      type
      depthLabels {
        id
        depth
        label
      }
      nodes(filters: $filters) {
        ...plannerElementNodeItem
      }
    }
    ${plannerElementNodeWithDepthLabelFragment.plannerElementNode}
  `,
};

export const genericPlannerElementSetFragment = {
  basicDetails: gql`
    fragment genericPlannerElementSetBasicDetailsItem on GenericPlannerElementSet {
      id
      label
      type
    }
  `,
};

export const plannerElementSetFragment = {
  plannerElementSetWithNodes: gql`
    fragment plannerElementSetWithNodesItem on PlannerElementSet {
      id
      label
      type
      nodes(filters: $filters) {
        ...plannerElementNodeItem
      }
    }
    ${plannerElementNodeFragment.plannerElementNode}
  `,
  plannerElementSetWithNodesAndChild: gql`
    fragment plannerElementSetWithNodesAndChildItem on PlannerElementSet {
      id
      label
      type
      nodes(filters: $filters) {
        ...plannerElementNodeWithChildItem
      }
    }
    ${plannerElementNodeFragment.plannerElementNodeWithChild}
  `,
  plannerElementSetBasicDetails: gql`
    fragment plannerElementSetBasicDetailsItem on PlannerElementSet {
      id
      label
      type
    }
  `,
  plannerElementAssociatedChildPlannerElementNodes: gql`
    fragment plannerElementAssociatedChildPlannerElementNodesItem on PlannerElementNode {
      id
      label
      type
    }
  `,
};

export const organizationGradesFragment = {
  organizationGrades: gql`
    fragment organizationGradesItem on Organization {
      id
      grades(filters: $filters) {
        ...gradeItem
      }
    }
    ${gradeFragment.basicGradeDetails}
  `,
};

export const organizationCoursesFragment = {
  organizationCourses: gql`
    fragment organizationCourseItem on Organization {
      id
      courses(orderBy: UPDATED_AT, orderByDirection: ASC, filters: $filters) {
        edges {
          node {
            ...courseGradeItem
            grades {
              id
              globalGrade {
                id
                constants
              }
            }
            collaborators: allCollaborators {
              totalCount
            }
          }
        }
      }
    }
    ${courseFragment.courserGradesDetails}
  `,
  organizationBasicCourses: gql`
    fragment organizationCourseItem on Organization {
      id
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
        edges {
          node {
            ...courseItem
          }
        }
      }
    }
    ${courseFragment.basicCourserDetails}
  `,
};

export const criteriaValueFragment = {
  criteriaValue: gql`
    fragment criteriaValueItem on AcademicCriteriaValue {
      id
      label
      abbreviation
      description
      color
    }
  `,
};

export const criteriaSetFragment = {
  criteriaSet: gql`
    fragment criteriaSetItem on AcademicCriteriaSet {
      id
      label
      academicCriteriaValues {
        ...criteriaValueItem
      }
      grades {
        ...gradeItem
      }
      criteriaType
    }
    ${gradeFragment.basicGradeDetails}
    ${criteriaValueFragment.criteriaValue}
  `,
};

export const attachmentFragment = {
  attachment: gql`
    fragment attachmentItem on Attachment {
      id
      name
      type
      mimeType
      url
      thumbUrl
      title
      metadata
      streamUrl
      parentType
      isRead
      similarityReport {
        ...similarityItem
      }
      createdBy {
        id
        type
      }
    }
    ${similarityFragment.similarityItem}
  `,
  basicDetails: gql`
    fragment attachmentBasicDetailsItem on Attachment {
      id
      url
      thumbUrl
      metadata
      name
      type
    }
  `,
};

export const pypElementRatingFragment = {
  organizationElementRating: gql`
    fragment ratingItem on OrganizationElementRatings {
      id
      ibPYPElementType
      levelId
      ibPYPParentElementId
      academicCriteriaSets {
        ...criteriaSetItem
      }
      subType
    }
    ${criteriaSetFragment.criteriaSet}
  `,
  studentElementRating: gql`
    fragment ratingItem on IBPYPElementRating {
      id
      value
      ibPYPElementId
      ibPYPElementType

      academicCriteriaSet {
        ...criteriaSetItem
      }
      academicCriteriaValue {
        ...criteriaValueItem
      }
    }

    ${criteriaSetFragment.criteriaSet}
    ${criteriaValueFragment.criteriaValue}
  `,
  studentElementRatingSlim: gql`
    fragment ratingItem on IBPYPElementRating {
      id
      ibPYPElementId
      ibPYPElementType
      academicCriteriaSet {
        id
      }
      academicCriteriaValue {
        id
      }
    }
  `,
  studentElementRatingHistory: gql`
    fragment ratingItem on IBPYPElementRating {
      id
      parent {
        id
        ... on StudentAssessmentEvaluation {
          id
          assessment {
            id
            title {
              id
              value
            }
            assessmentType {
              id
              value
            }
            image {
              id
              value
            }
          }
          assessmentEvaluation {
            id
          }
        }
        ... on StudentProgressReport {
          id

          courseProgressReportTask {
            id
            title
          }
        }
        ... on Post {
          id
          title
          attachments {
            ...attachmentItem
          }
        }
        ... on StudentAssignment {
          id
          assignment {
            id
            contentType
            content {
              id
              ... on Assessment {
                id
                title {
                  id
                  value
                }
                assessmentType {
                  id
                  value
                }
                image {
                  id
                  value
                }
              }
              ... on AssignmentResource {
                id
                label
              }
            }
          }
        }
      }
      createdBy {
        ...staffItem
      }
      publishedAt
      parentType
      academicCriteriaValue {
        ...criteriaValueItem
      }
    }
    ${staffFragment.basicStaffDetails}
    ${criteriaValueFragment.criteriaValue}
    ${attachmentFragment.attachment}
  `,
  basicIBPYPElementRating: gql`
    fragment basicIBPYPElementRatingItem on IBPYPElementRating {
      id
      ibPYPElementId
      value
      academicCriteriaSet {
        id
        label
      }
      academicCriteriaValue {
        id
        label
        abbreviation
      }
    }
  `,
  elementRemark: gql`
    fragment remarkItem on IbPypElementRemark {
      id
      message
      ibPYPElementId
      ibPYPElementType
    }
  `,
};

export const toolResponseFragment = {
  toolResponse: gql`
    fragment toolResponseItem on AssessmentToolResponse {
      id
      rowId
      columnId
      subjectiveResponse
      creatorType
    }
  `,
};

export const organizationPypElementSetFragment = {
  conceptSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        concepts {
          rootNodes
          nodes {
            ...conceptItem
          }
        }
      }
    }
    ${pypElementFragment.concept}
  `,
  lpSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        learnerProfiles {
          rootNodes
          nodes {
            ...lpItem
          }
        }
      }
    }
    ${pypElementFragment.lp}
  `,
  atlSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        atls(filters: { grades: $grades }) {
          rootNodes
          nodes {
            ...atlItem
          }
        }
      }
    }
    ${pypElementFragment.atl}
  `,
  relatedConceptSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        relatedConcept(
          filters: { subjectIds: $subjects, conceptIds: $concepts }
        ) {
          rootNodes
          nodes {
            ...relatedConceptItem
          }
        }
      }
    }
    ${pypElementFragment.relatedConcept}
  `,
  benchmarkSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        benchmarks(filters: { subjects: $subjects, grades: $grades }) {
          rootNodes
          nodes {
            ...benchmarkItem
          }
        }
      }
    }
    ${pypElementFragment.benchmark}
  `,

  actionSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        actions {
          rootNodes
          nodes {
            ...actionItem
          }
        }
      }
    }
    ${pypElementFragment.action}
  `,
  themeSet: gql`
    fragment organizationPypElementSetItem on Organization {
      id
      pypElement {
        themes {
          ...themeItem
        }
      }
    }
    ${pypElementFragment.theme}
  `,
};

export const pypPlannerElementSetFragment = {
  lpSet: gql`
    fragment lpSetItem on PlannerLearnerProfileSet {
      id
      rootNodes
      nodes {
        ...lpItem
      }
    }
    ${pypElementFragment.lp}
  `,
  conceptSet: gql`
    fragment conceptSetItem on PlannerConceptSet {
      id
      rootNodes
      nodes {
        ...conceptItem
      }
    }
    ${pypElementFragment.concept}
  `,
  atlSet: gql`
    fragment atlSetItem on ATLSet {
      id
      rootNodes
      nodes(filters: { grades: $grades }) {
        ...atlItem
      }
    }
    ${pypElementFragment.atl}
  `,
  relatedConceptSet: gql`
    fragment relatedConceptSetItem on PlannerRelatedConceptSet {
      id
      rootNodes
      nodes {
        ...relatedConceptItem
      }
    }
    ${pypElementFragment.relatedConcept}
  `,
};

export const organizationFragment = {
  organizationBasicDetails: gql`
    fragment organizationItem on Organization {
      id
      name
      logo
    }
  `,
  organizationPypConstants: gql`
    fragment organizationItem on Organization {
      id
      pypElement {
        learnerProfiles {
          rootNodes
          nodes {
            ...lpItem
          }
        }
        themes {
          ...themeItem
        }
        concepts {
          rootNodes
          nodes {
            ...conceptItem
          }
        }
        atls {
          rootNodes
          nodes {
            ...atlItem
          }
        }
        relatedConcept {
          rootNodes
          nodes {
            ...relatedConceptItem
          }
        }
        actions {
          rootNodes
          nodes {
            ...actionItem
          }
        }
      }
    }
    ${pypElementFragment.relatedConcept}
    ${pypElementFragment.atl}
    ${pypElementFragment.lp}
    ${pypElementFragment.concept}
    ${pypElementFragment.theme}
    ${pypElementFragment.action}
  `,
  curriculumPrograms: gql`
    fragment curriculumProgramsItem on Organization {
      id
      curriculumPrograms {
        id
        label
        acronym
        ...subscriptionPlanDetailsItem
        ... on IbMypCurriculumProgram {
          id
          nodeInterfaceType
          type
          subjectGroups {
            ...subjectGroupBasicDetailsItem
            subjects {
              id
              icon
              name
            }
            globalSubjectGroup {
              id
              constants
            }
          }
          plannerElementSets {
            ...plannerElementSetBasicDetailsItem
          }
        }
        ... on IbPypCurriculumProgram {
          id
          nodeInterfaceType
          type
          plannerElementSets {
            ...genericPlannerElementSetBasicDetailsItem
          }
        }
        ... on UBDCurriculumProgram {
          id
          nodeInterfaceType
          type
          plannerElementSets {
            ...plannerElementSetBasicDetailsItem
          }
        }
        ... on IbDpCurriculumProgram {
          id
          nodeInterfaceType
          type
          plannerElementSets {
            ...plannerElementSetBasicDetailsItem
          }
        }
      }
    }
    ${curriculumProgramFragment.subscriptionPlanDetails}
    ${subjectGroupFragment.subjectGroupBasicDetails}
    ${plannerElementSetFragment.plannerElementSetBasicDetails}
    ${genericPlannerElementSetFragment.basicDetails}
  `,
  organizationConstants: gql`
    fragment organizationItem on Organization {
      id
      constants {
        calendarEventTags
        newsEventTags
        rubricLevels
        isEvaluatePypElement
        singlePointRubricCriterias
        projectGroupDefaultGrades
        ratingLevels {
          ...ratingItem
        }
        academicCriteriaSets {
          ...criteriaSetItem
        }
        assessmentTemplate
        leTemplate
        settings
        enabledFeaturesList
        scheduler
      }
      settings {
        ...settingItem
      }
    }
    ${settingFragment.setting}
    ${criteriaSetFragment.criteriaSet}
    ${pypElementRatingFragment.organizationElementRating}
  `,
  organizationDetails: gql`
    fragment organizationItem on Organization {
      id
      name
      settings {
        name
        value
      }
      personalProject {
        id
      }
      communityProject {
        id
      }
      serviceAsAction {
        id
      }
      grades {
        ...gradeItem
      }
      unitTypes {
        ...unitTypeItem
      }
      academicYears {
        ...academicYearItem
      }
      templates {
        ...templateBasicDetailsItem
      }
      tags {
        id
        key
        value
      }
      subjects {
        id
        name
      }
      repoOrganization {
        commonCourseId
      }
    }
    ${plannerTemplateFragment.plannerTemplateBasicDetails}
    ${academicYearFragment.academicYear}
    ${gradeFragment.basicGradeDetails}
    ${unitTypeFragment.unitType}
  `,
  organizationSubjects: gql`
    fragment organizationItem on Organization {
      id
      subjects(filters: $filters) {
        ...subjectItem
      }
    }
    ${subjectFragment.subjectDetails}
  `,
  organizationSubjectGroups: gql`
    fragment organizationItem on Organization {
      id
      subjectGroups(filters: $filters) {
        ...subjectGroupItem
      }
    }
    ${subjectGroupFragment.subjectGroupWithSubjects}
  `,
  organizationGlobalConstants: gql`
    fragment organizationGlobalConstantItem on OrganizationGlobalConstants {
      id
      name
      value
      curriculumProgram {
        id
        type
      }
    }
  `,
};

export const attachmentGroupsFragment = {
  attachmentGroup: gql`
    fragment attachmentGroupItem on AttachmentGroup {
      id
      attachments {
        ...attachmentItem
      }
      studentTemplate {
        id
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const attachmentNodeFragment = {
  plannerField: gql`
    fragment plannerFieldNodeItem on PlannerField {
      id
      uid
      value
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
  `,

  exemplar: gql`
    fragment exemplarNodeItem on Exemplar {
      id
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const attachmentGroupsNodeFragment = {
  studentAssignmentSubmissionAttachmentGroups: gql`
    fragment studentAssignmentSubmissionAttachmentGroupsItem on StudentAssignmentSubmission {
      id
      attachmentGroups {
        ...attachmentGroupItem
      }
    }
    ${attachmentGroupsFragment.attachmentGroup}
  `,
  studentTemplateAttachmentGroups: gql`
    fragment studentTemplateAttachmentGroupsItem on ResolvedFieldStudentTemplate {
      id
      attachmentGroups {
        ...attachmentGroupItem
      }
    }
    ${attachmentGroupsFragment.attachmentGroup}
  `,
};

export const commentReplyMessageFragment = {
  commentReplyMessage: gql`
    fragment commentReplyMessageItem on MessageRepliesConnection {
      edges {
        node {
          id
          label
          createdAt
          createdBy {
            ...userItem
          }
        }
      }
    }
    ${userFragment.basicUserDetails}
  `,
};

export const messageFragment = {
  message: gql`
    fragment messageItem on Message {
      id
      label
      itemType
      type
      isRead
      isPin
      item {
        id
        ... on AttachmentSet {
          id
          attachments {
            ...attachmentItem
          }
        }
        ... on Assessment {
          id
          title {
            id
            uid
            value
          }
          description {
            id
            uid
            value
          }
          assessmentTool {
            id
            ... on Rubric {
              id
              title
              criterias {
                id
                label
              }
              indicators {
                id
                label
              }
              descriptors {
                id
                label
                criteria {
                  id
                  label
                }
                indicator {
                  id
                  label
                }
              }
              createdBy {
                ...staffItem
              }
            }
            ... on Checklist {
              id
              title
              checklistItems {
                id
                label
              }
              checklistOptionItems {
                id
                label
              }
              createdBy {
                ...staffItem
              }
            }
            ... on SinglePointRubric {
              id
              title
              criterias {
                id
                title
                subTitle
                isCriteriaStandardColumn
              }
              standards {
                id
                title
                subTitle
              }
              createdBy {
                ...staffItem
              }
            }
          }
          fields(
            uids: [
              "goals"
              "voiceInstruction"
              "studentTemplate"
              "measureAssessing"
            ]
          ) {
            id
            uid
            value
            resolvedMinimalTree {
              id
              ... on ResolvedFieldVoiceInstruction {
                id
                attachment {
                  ...attachmentItem
                }
              }
              ... on ResolvedFieldStudentTemplate {
                id
                attachmentGroups {
                  ...attachmentGroupItem
                }
              }
            }
          }
          resources {
            totalCount
            edges {
              node {
                id
                attachments {
                  ...attachmentItem
                }
              }
            }
          }
        }
        ... on AssignmentResource {
          id
          assignmentTitle: title
          label
          voiceInstruction {
            ...attachmentItem
          }
          attachments {
            ...attachmentItem
          }
        }
        ... on StudentAssignmentSubmission {
          id
        }
      }
      createdAt
      createdBy {
        ...userItem
      }
      messageReplies {
        ...commentReplyMessageItem
      }
      isDeleted
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${attachmentGroupsFragment.attachmentGroup}
    ${commentReplyMessageFragment.commentReplyMessage}
    ${staffFragment.basicStaffDetails}
  `,
  basicMessage: gql`
    fragment basicMessageItem on Message {
      id
      label
      itemType
      createdBy {
        ...userItem
      }
      createdAt
      type
    }
    ${userFragment.basicUserDetails}
  `,
};

export const commentConnectionFragment = {
  commentConnection: gql`
    fragment commentConnectionItem on CommentConnection {
      totalCount
      unreadCount
      unreadCountV2
      pageInfo(first: 10, after: $after) {
        endCursor
        hasNextPage
      }
      edges: messageEdges(first: 10, after: $after) {
        node {
          ...messageItem
        }
      }
    }
    ${messageFragment.message}
  `,
  totalCommentConnection: gql`
    fragment commentConnectionItem on CommentConnection {
      totalCount
      unreadCount
    }
  `,
  basicCommentConnection: gql`
    fragment basicCommentConnectionItem on CommentConnection {
      pageInfo(first: $first, after: $after) {
        hasNextPage
        endCursor
      }
      messageEdges(first: $first, after: $after) {
        node {
          ...basicMessageItem
        }
      }
    }
    ${messageFragment.basicMessage}
  `,
};

export const commentNodeFragment = {
  plannerField: gql`
    fragment plannerFieldNodeItem on PlannerField {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  assessment: gql`
    fragment assessmentNodeItem on Assessment {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  le: gql`
    fragment leNodeItem on LearningEngagement {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  flowSection: gql`
    fragment flowSectionItem on PlannerFlowSection {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  post: gql`
    fragment postItem on Post {
      id
      isDeleted
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  basicCommentPost: gql`
    fragment basicCommentPostItem on Post {
      id
      comments {
        ...basicCommentConnectionItem
      }
    }
    ${commentConnectionFragment.basicCommentConnection}
  `,
  studentGoalTask: gql`
    fragment studentGoalTaskItem on GoalTask {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  communityUnitPlan: gql`
    fragment communityUnitPlanItem on UnitPlan {
      id
      title {
        id
        value
      }
      communityComments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
  communityAssessment: gql`
    fragment communityAssessmentItem on Assessment {
      id
      title {
        id
        value
      }
      communityComments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.commentConnection}
  `,
};

export const totalCommentNodeFragment = {
  plannerField: gql`
    fragment plannerFieldNodeItem on PlannerField {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  assessment: gql`
    fragment assessmentNodeItem on Assessment {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  le: gql`
    fragment leNodeItem on LearningEngagement {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  flowSection: gql`
    fragment flowSectionItem on PlannerFlowSection {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  post: gql`
    fragment postItem on Post {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  studentGoalTask: gql`
    fragment studentGoalTaskItem on GoalTask {
      id
      comments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  communityUnitPlan: gql`
    fragment communityUnitPlanItem on UnitPlan {
      id
      communityComments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
  communityAssessment: gql`
    fragment communityAssessmentItem on Assessment {
      id
      communityComments {
        ...commentConnectionItem
      }
    }
    ${commentConnectionFragment.totalCommentConnection}
  `,
};

export const expertProfileFragment = {
  expert: gql`
    fragment expertItem on ExpertProfile {
      id
      firstName
      lastName
      organization
      profileImage
      description
    }
  `,
};

export const resourceLibraryFragment = {
  resourceFeed: gql`
    fragment resourceLibraryItem on FlowSectionItem {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      createdBy {
        id
        firstName
        lastName
      }
      templateId
      ... on Assessment {
        assessmentType {
          id
          value
        }
        fields(
          uids: ["duration", "size", "measureAssessing", "assessmentType"]
        ) {
          id
          uid
          value
        }
        isImported
        paritaStarStatus
        kristenStarStatus
        juryStarStatus
        paritaProcessed
        kristenProcessed
        juryProcessed
        taggedPractices {
          ...taggedPracticesOnEvidence
        }
      }

      ... on LearningEngagement {
        fields(uids: ["duration", "size", "documentation"]) {
          id
          uid
          value
        }
      }
    }
    ${taggedPracticesFragment.taggedPractices}
  `,
};

export const fieldScoreFragment = {
  fieldScore: gql`
    fragment fieldScoreItem on ResolvedFieldScore {
      id
      type
      isUsingScore
      maxScore
      category {
        id
        label
        colorCode
        set {
          id
          associatedEntities {
            id
            ... on Course {
              id
              title
            }
            ... on Subject {
              id
              name
            }
          }
        }
      }
    }
  `,
};

export const resolvedFieldValueFragment = {
  resolvedMinimalTree: gql`
    fragment resolvedMinimalTreeItem on ResolvedFieldValue {
      id
      type
      ... on ResolvedFieldBenchmarkSet {
        id
        benchmarks {
          ...benchmarkItem
        }
      }
      ... on ResolvedFieldConceptSet {
        id
        concepts {
          ...conceptItem
        }
      }
      ... on ResolvedFieldActionSet {
        id
        actions {
          ...actionItem
        }
      }
      ... on ResolvedFieldLPSet {
        id
        learnerProfiles {
          ...lpItem
        }
      }
      ... on ResolvedFieldThemeSet {
        id
        themes {
          ...themeItem
        }
      }
      ... on ResolvedFieldSubjectSet {
        id
        subjects {
          ...subjectItem
          subjectGroup {
            id
          }
        }
      }
      ... on ResolvedFieldATLSet {
        id
        atls {
          ...atlItem
        }
      }
      ... on ResolvedFieldRelatedConceptSet {
        id
        relatedConcepts {
          ...relatedConceptItem
        }
      }
      ... on ResolvedFieldVoiceInstruction {
        id
        attachment {
          ...attachmentItem
        }
      }
      ... on ResolvedFieldVideoAttachment {
        id
        attachment {
          ...attachmentItem
        }
      }
      ... on ResolvedFieldStudentTemplate {
        id
        attachmentGroups {
          ...attachmentGroupItem
        }
      }
      ... on ResolvedFieldTags {
        id
        tags {
          id
          label
        }
      }
      ... on ResolvedFieldGenericTags {
        id
        genericTags {
          id
          label
          __typename
        }
        __typename
      }
      ... on ResolvedFieldPlannerElementSet {
        id
        type
        nodes {
          ...plannerElementNodeItem
        }
      }
      ... on ResolvedFieldSubjectGroup {
        id
        type
        subjectGroups {
          ...subjectGroupBasicDetailsItem
        }
      }
      ... on ResolvedFieldScore {
        ...fieldScoreItem
      }
      ... on ResolvedFieldObjectiveLevel {
        id
        nodes {
          id
          label
          associatedParents {
            id
          }
        }
      }
      ... on ResolvedFieldAttachments {
        id
        type
        attachments {
          ...attachmentItem
        }
      }
    }

    ${subjectGroupFragment.subjectGroupBasicDetails}
    ${plannerElementNodeFragment.plannerElementNode}
    ${pypElementFragment.benchmark}
    ${pypElementFragment.concept}
    ${pypElementFragment.action}
    ${pypElementFragment.lp}
    ${pypElementFragment.theme}
    ${pypElementFragment.atl}
    ${pypElementFragment.relatedConcept}
    ${subjectFragment.subjectBenchmarkDetails}
    ${attachmentFragment.attachment}
    ${attachmentGroupsFragment.attachmentGroup}
    ${fieldScoreFragment.fieldScore}
  `,
};

export const unitPlanFeedFragment = {
  unitPlanFeed: gql`
    fragment unitPlanItem on UnitPlan {
      id
      state
      startDate
      endDate
      isPublishedInSampleUnits
      isImported
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      owner {
        id
      }
      title {
        id
        value
      }
      grades
      theme {
        id
        value
        resolvedMinimalTree {
          ... on ResolvedFieldThemeSet {
            id
            themes {
              id
              label
              __typename
            }
            __typename
          }
          __typename
        }
      }
      duration {
        id
        value
      }
      unitType {
        id
        value
      }
      image {
        id
        value
      }
      templateId
      subjects {
        id
        value
        resolvedMinimalTree {
          ... on ResolvedFieldSubjectSet {
            id
            subjects {
              id
              name
              __typename
            }
            __typename
          }
          __typename
        }
      }
      expertProfile {
        ...expertItem
      }
      resourceLibrary {
        leCount
        assessmentCount
      }
      assessmentTemplate
      leTemplate
      collaborators: allCollaborators @include(if: $showCollaborators) {
        totalCount
        isEditPermission
        edges {
          permission
          node {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
      taggedPractices {
        ...taggedPracticesOnEvidence
      }
      academicYears {
        ...academicYearItem
      }
      submittedToCommunity
      fields(uids: ["hours", "subjectLevel", "objectiveLevel"]) {
        id
        uid
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      createdAt
      updatedAt
      centralRepoConfig(input: $centralRepoInput) {
        newVersionId
        isSeen
        derivedStatus
        publishedDate
      }
      cascadeUpdatedBy {
        id
        firstName
        lastName
        profileImage
      }
      cascadeUpdateAt
    }
    ${academicYearFragment.academicYear}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${expertProfileFragment.expert}
    ${taggedPracticesFragment.taggedPractices}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  unitPlanEvidenceFeed: gql`
    fragment unitPlanItem on UnitPlan {
      id
      state
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      title {
        id
        value
      }
      resolvedGrades {
        id
        name
      }
      theme {
        id
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      unitType {
        id
        value
      }
      image {
        id
        value
      }
      subjects {
        id
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      templateId
    }
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
};

export const teacherFragments = {
  teacherFeed: gql`
    fragment teacherItem on Staff {
      id
      profileImage
      firstName
      lastName
      email
      role
      designation {
        ...designationItem
      }
      status
      courses {
        id
        title
      }
    }
    ${designationFragment.designation}
  `,
  teacherDetails: gql`
    fragment teacherItemDetail on Staff {
      id
      firstName
      lastName
      profileImage
      email
      designation {
        id
      }
    }
  `,
};

export const organizationUnitPlanFeedFragment = {
  organizationUnitPlanFeed: gql`
    fragment organizationUnitPlanFeedItem on Organization {
      id
      unitPlans(
        type: $type
        scheduledStatus: $scheduledStatus
        grades: $grades
        searchText: $searchText
        academicYears: $academicYears
        getPlanathonUnits: $getPlanathonUnits
        users: $users
        removePlanathonUnits: $removePlanathonUnits
        createdBy: $createdBy
        curriculumProgramIds: $curriculumProgramIds
        subjects: $subjects
      ) {
        totalCount
        edges {
          node {
            ...unitPlanItem
          }
        }
      }
    }
    ${unitPlanFeedFragment.unitPlanFeed}
  `,
  organizationUnitPlanFeedV2: gql`
    fragment organizationUnitPlanFeedItem on Organization {
      id
      unitPlans: unitPlansV2(first: 20, after: $after, filters: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...unitPlanItem
          }
        }
      }
    }
    ${unitPlanFeedFragment.unitPlanFeed}
  `,
  organizationUnitPlanFeedCount: gql`
    fragment organizationUnitPlanFeedCountItem on Organization {
      id
      unitPlans(
        type: $type
        scheduledStatus: $scheduledStatus
        grades: $grades
        searchText: $searchText
        academicYears: $academicYears
        getPlanathonUnits: $getPlanathonUnits
        users: $users
        removePlanathonUnits: $removePlanathonUnits
      ) {
        totalCount
      }
    }
  `,
};

export const organizationAttendanceOptionFragment = {
  organizationAttendanceOption: gql`
    fragment organizationAttendanceOptionItem on AttendanceOption {
      id
      label
      abbreviation
      color
      status
      isDefault
      sendSMS
      sendEmail
    }
  `,
};

export const studentAttendanceSummaryFragment = {
  studentAttendanceSummary: gql`
    fragment studentAttendanceSummaryItem on Student {
      id
      attendanceSummary(
        courseId: $courseId
        startDate: $startDate
        endDate: $endDate
        groupingType: $groupingType
      ) {
        startDate
        endDate
        attendanceCount {
          totalCount
          value {
            ...organizationAttendanceOptionItem
          }
        }
      }
    }
    ${organizationAttendanceOptionFragment.organizationAttendanceOption}
  `,
};

export const settingsNodeFragment = {
  organization: gql`
    fragment organizationItem on Organization {
      id
      settings {
        ...settingItem
      }
    }
    ${settingFragment.setting}
  `,
  course: gql`
    fragment courseItem on Course {
      id
      settings {
        ...settingItem
      }
    }
    ${settingFragment.setting}
  `,
  staff: gql`
    fragment staffItem on Staff {
      id
      settings {
        ...settingItem
      }
    }
    ${settingFragment.setting}
  `,
  student: gql`
    fragment studentItem on Student {
      id
      settings {
        ...settingItem
      }
    }
    ${settingFragment.setting}
  `,
  parent: gql`
    fragment parentItem on FamilyMember {
      id
      settings {
        ...settingItem
      }
    }
    ${settingFragment.setting}
  `,
};

export const zoomMeetingFragment = {
  zoomMeeting: gql`
    fragment zoomMeetingItem on ZoomMeeting {
      id
      label
      hostUrl
      participantUrl
      createdBy {
        ...staffItem
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
};

export const zoomMeetingNodeFragment = {
  courseZoomMeeting: gql`
    fragment courseZoomMeetingItem on Course {
      id
      ... on Course {
        id
        zoomMeeting {
          ...zoomMeetingItem
        }
      }
    }
    ${zoomMeetingFragment.zoomMeeting}
  `,
};

export const meetingFragment = {
  meetingDetails: gql`
    fragment meetingDetailsItem on Meeting {
      id
      label
      createdBy {
        ...staffItem
      }
      createdAt
      urls {
        type
        ... on OtherMeetingUrls {
          url
        }
        ... on ZoomMeetingUrls {
          joinUrl
          startUrl
        }
        ... on MicrosoftTeamMeetingUrls {
          joinUrl
        }
        ... on GoogleMeetingUrls {
          type
          url
        }
      }
      type
    }
    ${staffFragment.basicStaffDetails}
  `,
};

export const userIdentityDetailFragment = {
  userIdentityDetail: gql`
    fragment userIdentityDetailItem on UserIdentityDetail {
      linkedUser {
        id
        firstName
        lastName
        email
        jwt
        organization {
          id
        }
      }
      portalType
    }
  `,
};

export const genericFolderFragment = {
  genericFolderDetails: gql`
    fragment genericFolderItem on GenericFolder {
      id
      name
      color
      createdBy {
        ...userItem
      }
      typeDetails(filters: $filters) {
        id
        ... on FolderAssignmentTypeDetail {
          id
          type
          totalCount
          stateWiseCount {
            state
            count
          }
        }
      }
    }
    ${userFragment.basicUserDetails}
  `,
  genericFolderBasicDetails: gql`
    fragment genericFolderItem on GenericFolder {
      id
      name
      color
    }
  `,
};

export const evidenceInterfaceFragment = {
  allTypes: gql`
    fragment allEvidenceTypesItem on EvidenceInterface {
      id
      ... on Attachment {
        ...attachmentItem
      }
      ... on Post {
        id
        title
        attachments {
          ...attachmentItem
        }
        items {
          item {
            id
            ... on StudentAssignment {
              id
              assignment {
                id
                content {
                  id
                  ... on Assessment {
                    id
                    assessmentType {
                      id
                      value
                    }
                  }
                }
              }
            }
          }
          itemType
        }
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const remarkFragment = {
  remarkMessageFragment: gql`
    fragment remarkMessage on Remark {
      id
      message
      createdAt
      isLocked
    }
  `,
};

export const signatureAttachmentFragment = {
  signature: gql`
    fragment signature on Attachment {
      id
      url
      type
      name
    }
  `,
};

export const signatureFragment = {
  studentSignature: gql`
    fragment studentSignature on Student {
      id
      firstName
      lastName
      signature {
        ...signature
      }
    }
    ${signatureAttachmentFragment.signature}
  `,
  supervisorSignature: gql`
    fragment supervisorSignature on Staff {
      id
      firstName
      lastName
      signature {
        ...signature
      }
    }
    ${signatureAttachmentFragment.signature}
  `,
};

export const staffUnitPlanFragment = {
  staffCourses: gql`
    fragment staffCoursesItem on Staff {
      id
      firstName
      lastName
      profileImage
      email
      roles
      type
      isArchived
      courses {
        id
        title
      }
    }
  `,
};

export const studentResponseFragment = {
  studentResponse: gql`
    fragment studentResponseItem on StudentAssignment {
      id
      assignment {
        id
        content {
          id
          ... on Assessment {
            id
            assessmentType {
              id
              value
            }
          }
        }
      }
      submission {
        id
        attachmentGroups {
          id
          attachments {
            ...attachmentItem
          }
        }
      }
      post {
        id
      }
    }
  `,
};

export const yearGroupFragments = {
  basicYearGroupDetails: gql`
    fragment basicYearGroupDetails on YearGroup {
      id
      name
      curriculumProgram {
        id
        type
      }
      grade {
        id
        name
        displaySequence
      }
      students {
        totalCount
      }
      examSession {
        id
        session
        year
      }
      selectedTranscriptTerms {
        id
      }
      isArchived
      archivedAt
    }
  `,
};

export const projectGroupFragments = {
  basicProjectGroupDetails: gql`
    fragment basicProjectGroupDetails on ProjectGroup {
      id
      name
      type
      subType
      curriculumProgram {
        id
        type
      }
    }
  `,
};
