import React from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { setActiveTab } from "Teacher/modules/TeacherModule";
import { withFeaturePermissionHandled } from "UIComponents";
class StudentPortfolioReport extends React.PureComponent {
  UNSAFE_componentWillMount = () => {
    this.props.setActiveTab("studentPortfolioReport");
  };
  render() {
    return null;
  }
}

const mapActionCreators = {
  setActiveTab,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default compose(
  withFeaturePermissionHandled({
    key: "PORTFOLIO_REPORTS",
    route: "studentPortfolioReport",
  }),
  connect(mapStateToProps, mapActionCreators)
)(StudentPortfolioReport);
