import React, { memo } from "react";
import classes from "./MultiCheckSelection.scss";
import { withLoader, MultiSelectComponent } from "UIComponents";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { getOrganizationGradeBasicDetailsQuery } from "modules/CommonQuery";
import { getOrganizationBasicGradeDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { fontStyle, colors } from "Constants";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";

const styles = {
  listContainerStyle: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    gap: "24px",
    margin: "24px 0px 0px 0px",
    height: "unset",
    "--active-border-color": colors.interactiveTwoDefault,
  },
  listItemStyle: {
    width: "100%",
    minHeight: "unset",
    margin: "0px",
    padding: "16px",
    flexDirection: "row",
    alignItems: "center",
  },
  fieldTypeConfig: {
    tickPosition: "center",
  },
  textContainerStyle: {
    ...fontStyle.medium,
  },
  selectedTickStyle: {
    display: "flex",
    alignItems: "center",
  },
  unSelectedCompStyle: {
    border: `1px solid ${colors.borderSubtle}`,
    borderRadius: "50%",
    width: "16px",
    height: "16px",
  },
  tickContainerStyle: {
    display: "flex",
    alignItems: "center",
    right: 16,
  },
  textItemStyle: {
    paddingRight: "20px",
  },
};

const REPOSITORY_LABEL_MAP = {
  SUBMITTED_WORK: "student_paper_repository",
  INTERNET: "current_and_archived_website_content",
  PUBLICATION: "periodicals_journals_and_publications",
};

const MYP_OTHER_MODULES_ARRAY = [
  {
    id: "MYP_PERSONAL_PROJECT",
    name: "myp_personal_project",
  },
  {
    id: "MYP_COMMUNITY_PROJECT",
    name: "myp_community_project",
  },
];

const DP_OTHER_MODULES_ARRAY = [
  {
    id: "DP_CAS",
    name: "creativity_activity_service",
  },
  {
    id: "DP_EE",
    name: "extended_essay",
  },
  {
    id: "DP_TOK_ESSAY",
    name: "theory_of_knowledge_essay",
  },
  {
    id: "DP_TOK_EXHIBITION",
    name: "theory_of_knowledge_exhibition",
  },
  {
    id: "INTERNAL_ASSESSMENT",
    name: "internal_assessment",
  },
  {
    id: "EXTERNAL_ASSESSMENT",
    name: "external_assessment",
  },
];

const MultiCheckSelection = props => {
  const {
    t,
    grades,
    onUpdateSetting,
    subSettings,
    setToastMsg,
    curriculumPrograms,
  } = props;

  const onUpdateInputField = ({ value, setting }) => {
    if (
      setting.permission === "TurnitinSearchRepositoriesForClassroom" ||
      setting.permission === "TurnitinSearchRepositoriesForOtherModules"
    ) {
      onUpdateSetting({
        setting,
        value: {
          ..._.get(setting, "jsonValue", {}),
          searchRepositories: value,
        },
      });
      return;
    }
    onUpdateSetting({
      setting,
      value: value,
    });
  };

  const getOptions = ({ permission, isTextLocalized, value }) => {
    let options;
    switch (permission) {
      case "TurnitinEnabledGrades":
        options = grades;
        break;
      case "TurnitinSearchRepositoriesForClassroom":
      case "TurnitinSearchRepositoriesForOtherModules":
        options = _.map(_.get(value, "availableRepositories", []), repoId => {
          return {
            id: repoId,
            name: REPOSITORY_LABEL_MAP[repoId],
          };
        });
        break;
      case "TurnitinEnabledForOtherModules": {
        const organizationCurriculums = _.map(
          curriculumPrograms,
          curriculum => curriculum.type
        );

        options = [
          ...(_.includes(organizationCurriculums, CURRICULUM_TYPE_MYP)
            ? MYP_OTHER_MODULES_ARRAY
            : []),
          ...(_.includes(organizationCurriculums, CURRICULUM_TYPE_DP)
            ? DP_OTHER_MODULES_ARRAY
            : []),
        ];
        break;
      }
    }
    options = _.map(options, option => {
      return {
        ...option,
        label: isTextLocalized
          ? t(`configuration:${option.name}`)
          : option.name,
      };
    });

    return options;
  };

  const getMinimumSelectedOptionsCount = ({ permission }) => {
    switch (permission) {
      case "TurnitinEnabledGrades":
        return 0;
      case "TurnitinSearchRepositoriesForClassroom":
      case "TurnitinSearchRepositoriesForOtherModules": {
        return 1;
      }
      case "TurnitinEnabledForOtherModules":
        return 0;
    }
  };

  const getMinimumSelectedOptionsAlertMessage = ({ permission }) => {
    switch (permission) {
      case "TurnitinSearchRepositoriesForClassroom":
      case "TurnitinSearchRepositoriesForOtherModules":
        return "configuration:atleast_one_repository_alert_text";
    }
  };

  const onMinimumSelectedOptionsRemove = ({ permission }) => {
    setToastMsg({
      msg: getMinimumSelectedOptionsAlertMessage({ permission }),
      type: "alert",
    });
  };

  const getValue = ({ permission, value }) => {
    switch (permission) {
      case "TurnitinSearchRepositoriesForClassroom":
      case "TurnitinSearchRepositoriesForOtherModules": {
        return _.get(value, "searchRepositories", []);
      }
      default: {
        return value;
      }
    }
  };

  return (
    <div className={classes.settingContainer}>
      {_.map(subSettings, setting => {
        const label = _.get(setting, "label", null);
        const permission = _.get(setting, "permission", "");
        const isTextLocalized = _.get(
          setting,
          "metadata.isTextLocalized",
          true
        );
        const jsonValue = _.get(setting, "jsonValue", []);
        const options = getOptions({
          permission,
          isTextLocalized,
          value: jsonValue,
        });
        const value = getValue({ permission, value: jsonValue });

        return (
          <div className={classes.jsonSettingContainer} key={setting.id}>
            {label && (
              <div className={classes.checkListTitle}>
                {isTextLocalized ? t(`configuration:${label}`) : label}
              </div>
            )}
            <MultiSelectComponent
              options={options}
              value={value}
              updateInputField={params => {
                onUpdateInputField({ value: params, setting });
              }}
              listContainerStyle={styles.listContainerStyle}
              listItemStyle={styles.listItemStyle}
              fieldTypeConfig={styles.fieldTypeConfig}
              textContainerStyle={styles.textContainerStyle}
              selectedTickStyle={styles.selectedTickStyle}
              tickDimension={16}
              minimumSelectedOptionsCount={getMinimumSelectedOptionsCount({
                permission,
              })}
              onMinimumSelectedOptionsRemove={() =>
                onMinimumSelectedOptionsRemove({ permission })
              }
              activeTickColor={colors.interactiveTwoDefault}
              unSelectedCompStyle={styles.unSelectedCompStyle}
              tickContainerStyle={styles.tickContainerStyle}
              textItemStyle={styles.textItemStyle}
            />
          </div>
        );
      })}
    </div>
  );
};

const MultiCheckSelectionMemoised = memo(MultiCheckSelection);

const mapStateToProps = (state, ownProps) => {
  const subSettings = _.get(ownProps, "subSettings", []);
  const isGradeRequire = !_.isEmpty(
    _.find(subSettings, setting =>
      _.get(setting, "metadata.isGradeRequired", false)
    )
  );
  const userInfo = state.login.userInfo;
  const userId = userInfo.id;
  const containerStyle = _.get(ownProps, "containerStyle", {});
  const curriculumPrograms = state.platform.organizationCurriculumPrograms;

  return {
    isData: true,
    isLoading: false,
    userId: userId,
    organizationId: userInfo.org_id,
    isGradeRequire,
    containerStyle,
    curriculumPrograms,
  };
};

const mapActionCreators = {
  setToastMsg,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationGradeBasicDetailsQuery, {
    name: "getOrganizationGrades",
    skip: ({ isGradeRequire }) => !isGradeRequire,
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
      },
    }),
    props({
      getOrganizationGrades,
      ownProps: { organizationId, isData, isLoading, courses },
    }) {
      const queryData = getOrganizationBasicGradeDetailsFromCache({
        organizationId,
      });

      const grades = _.get(queryData, "grades", []);

      return {
        isData: !_.isEmpty(queryData) && isData,
        grades,
        isLoading:
          getOrganizationGrades["networkStatus"] == 1 ||
          getOrganizationGrades["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(MultiCheckSelectionMemoised);
