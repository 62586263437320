import React from "react";
import { I18nHOC } from "UIComponents";
import classes from "./Header.scss";
import { Trans } from "react-i18next";
import ACLStore from "lib/aclStore";
import { isChrome, getUserAgent } from "Utils/DeviceDetectHelpers";
import { BoltFilled } from "@toddle-design/web-icons";

const micPermission = "FeatureFlag:ShowMicOption";

const KeyNameComp = props => {
  const { label } = props;
  return <span className={classes.keyNameContainer}>{label}</span>;
};

const Header = props => {
  const { t } = props;

  const showMicText = ACLStore.can(micPermission) && isChrome();
  const isMac = _.includes(getUserAgent(), "Mac");

  const keyToSearch = isMac ? "Cmd" : "Ctrl";

  return (
    <div className="mb-3 mt-6 px-10 flex flex-initial flex-col">
      <div className="mb-1 flex flex-initial">
        <div className={classes.quickSearchIcon}>
          <BoltFilled className={classes.iconColor} />
        </div>
        <div className="text-label-2 flex flex-initial items-center ml-2">
          {t("integration:jump_to")}
        </div>
      </div>
      <div className={classes.textBody}>
        {showMicText ? (
          <Trans key={"integration:global_search_header_text_with_mic"}>
            Press <KeyNameComp label={keyToSearch} /> <KeyNameComp label="/" />{" "}
            anywhere on Toddle to start a quick search and{" "}
            <KeyNameComp label={keyToSearch} /> <KeyNameComp label="Shift" />{" "}
            <KeyNameComp label="/" /> to start a quick audio search.
          </Trans>
        ) : (
          <Trans key={"integration:global_search_header_text"}>
            Press <KeyNameComp label={keyToSearch} /> <KeyNameComp label="/" />{" "}
            anywhere on Toddle to start a quick search.
          </Trans>
        )}
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["integration"] })(Header);
