import React from "react";
import classes from "./DropDown.scss";
import { I18nHOC } from "UIComponents";
import { Dropdown, DropdownMenu, IconButton } from "@toddle-design/web";
import { DotsHorizontalOutlined } from "@toddle-design/web-icons";

const DropDown = ({ t, isActive, onClickEdit, onClickDelete }) => {
  const menuItems = isActive
    ? [
        {
          label: t("delete"),
          key: "DELETE",
        },
      ]
    : [
        {
          label: t("edit"),
          key: "EDIT",
        },
        {
          label: t("delete"),
          key: "DELETE",
        },
      ];

  const onActionClick = key => {
    switch (key) {
      case "EDIT":
        onClickEdit();
        break;
      case "DELETE":
        onClickDelete();
        break;
    }
  };

  return (
    <div className={classes.container}>
      <Dropdown
        overlay={
          <DropdownMenu
            options={menuItems}
            onClick={e => onActionClick(e.key)}
          />
        }
      >
        <IconButton
          size="medium"
          variant="inline"
          icon={<DotsHorizontalOutlined />}
        />
      </Dropdown>
    </div>
  );
};

export default I18nHOC({ resource: "common" })(DropDown);
