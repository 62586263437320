import React from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { I18nHOC } from "UIComponents";
import { goToPlatformHomeRoute } from "modules/NavigationModule";
import { logoutUser } from "Login/modules/LoginModule";
import { setIsPageNotFound } from "modules/Services";
import { Button } from "@toddle-design/web";
import { Error404Illustration } from "@toddle-design/theme";
import { EmptyState } from "@toddle-design/web";

class PageNotFound extends React.Component {
  goToHomePage = () => {
    const { goToPlatformHomeRoute, setIsPageNotFound } = this.props;
    goToPlatformHomeRoute({ navigateType: "replace" });
    setIsPageNotFound(false);
  };

  render() {
    const { t } = this.props;
    const errorText = _.get(
      this.props,
      "errorText",
      // "Woops, that’s an error"
      t("common:page_not_found_header_text")
    );
    const errorSubText = _.get(
      this.props,
      "errorSubText",
      // "We couldn’t find the page you were looking for."
      t("common:page_not_found_sub_text")
    );
    const errorImageSrc = _.get(
      this.props,
      "errorImageSrc",
      Error404Illustration
    );

    const onRedirectButtonClick = _.get(
      this.props,
      "onRedirectButtonClick",
      this.goToHomePage
    );

    return (
      <EmptyState
        secondaryButton={
          <Button onClick={logoutUser}>{t("common:logout")}</Button>
        }
        primaryButton={
          <Button onClick={onRedirectButtonClick}>
            {t("common:go_to_homepage")}
          </Button>
        }
        illustration={errorImageSrc}
        size="large"
        title={errorText}
        subtitle={errorSubText}
      />
    );
  }
}

const mapActionCreators = {
  goToPlatformHomeRoute,
  setIsPageNotFound,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(null, mapActionCreators)
)(PageNotFound);
