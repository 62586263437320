import gql from "graphql-tag";

export const associatedChildPlannerElementNodeFragment = {
  associatedChildPlannerElementNode: gql`
    fragment associatedChildPlannerElementNodeItem on PlannerElementNode {
      id
      label
      type
      fields {
        id
        uid
        value
      }
    }
  `,
};

export const gradesItemFragment = {
  gradeFragment: gql`
    fragment gradeItem on Grade {
      id
      name
    }
  `,
};
