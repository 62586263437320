import React, { useState } from "react";
import { UIButton, I18nHOC, UIModal, withLoader } from "UIComponents";
import classes from "./InviteToCommunity.scss";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import * as EventTracker from "lib/eventTracker";
import { COMMUNITY_IMAGE_URL } from "Constants";
import { getUsersNotOnCommunityQuery } from "Community/modules/CommunityQuery";
import { inviteUserToCommunity } from "Community/modules/CommunityModule";
import { getUsersNotOnCommunityFromCache } from "Community/modules/CommunityGraphqlHelpers";
import {
  getUserInfo,
  setInviteToCommunityStatus,
} from "Login/modules/LoginModule";
import { ToddleIcon, CancelIcon } from "SvgComponents";

const inviteButtonStyles = {
  containerStyle: {
    minWidth: "72px",
    height: "38px",
    borderRadius: "4px",
    borderWidth: "1px",
  },
};

const InviteToCommunity = ({
  t,
  users,
  inviteUserToCommunity,
  setInviteToCommunityStatus,
  userId,
  name,
}) => {
  const [invited, setInvited] = useState([]);

  const invite = async emails => {
    setInvited(prev => _.uniq([...prev, ...emails]));
    await inviteUserToCommunity({ emails });
    EventTracker.recordEvent({
      eventName: "Invite to Community",
      eventData: {
        source: "community",
        entity_id: userId,
        entity_name: name,
        entity_type: "STAFF",
      },
    });
  };

  const inviteAll = () => {
    const emailList = _.map(users, "email");
    invite(emailList);
  };

  const inviteCount = users.length - invited.length;
  const disableInviteAll = users.length === invited.length;

  return (
    <div className={classes.container}>
      <div
        className={classes.leftContainer}
        style={{
          backgroundImage: `url(${COMMUNITY_IMAGE_URL})`,
        }}
      >
        <div className={classes.logoContainer}>
          <div className={classes.logo}>
            <ToddleIcon />
          </div>
          <div className={classes.toddleText}>{t("common:toddle")}</div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.title}>
          {t("community:invite_community_title")}
        </div>

        <div className={classes.inviteRow}>
          <div className={classes.inviteRowLeftContainer}>
            <div className={classes.subTitle}>
              {t("community:teachers_yet_to_join")}
            </div>
            <div className={classes.teachersCount}>
              {t("community:count_with_teachers", { count: inviteCount })}
            </div>
          </div>
          <UIButton
            size="sm"
            color="pink"
            onClick={inviteAll}
            containerStyle={{ minWidth: "88px" }}
            isDisabled={disableInviteAll}
          >
            {t("community:invite_all")}
          </UIButton>
        </div>

        <div className={classes.gridResultContainer}>
          {_.map(users, user => (
            <GridCard
              user={user}
              t={t}
              onClick={invite}
              isInvited={_.includes(invited, user.email)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const GridCard = ({
  user: { firstName, lastName, email } = {},
  isInvited,
  onClick,
  t,
}) => {
  const name = `${firstName || ""} ${lastName || ""}`;
  return (
    <div className={classes.cardContainer}>
      <div className={classes.row}>
        <div className={classes.name}>{name}</div>
        <div className={classes.email}>{email}</div>
      </div>
      <UIButton
        {...inviteButtonStyles}
        color="blue"
        type="hollow"
        onClick={() => onClick([email])}
        isDisabled={isInvited}
      >
        {t(isInvited ? "common:invited" : "common:invite")}
      </UIButton>
    </div>
  );
};

const InviteToCommunityLoader = withLoader(InviteToCommunity);

const InviteToCommunityComponent = props => {
  const { setInviteToCommunityStatus } = props;
  const handleCancel = () => setInviteToCommunityStatus(false);
  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      overlayContainer={classes.overlayContainer}
    >
      <div className={classes.cancelIcon} onClick={handleCancel}>
        <CancelIcon width={12} height={12} />
      </div>

      <InviteToCommunityLoader {...props} />
    </UIModal>
  );
};

const mapStateToProps = state => {
  const userInfo = getUserInfo({ portalType: "PLANNER" });
  const name = `${_.get(userInfo, "first_name", "")} ${_.get(
    userInfo,
    "last_name",
    ""
  )}`;
  return {
    isData: true,
    isLoading: false,
    userId: userInfo.id,
    orgId: userInfo.org_id,
    name,
  };
};

const mapActionCreators = {
  inviteUserToCommunity,
  setInviteToCommunityStatus,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["community", "common"] }),
  graphql(getUsersNotOnCommunityQuery, {
    name: "getNonCommunityUsers",
    options: ({ orgId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: orgId, portalType: "PLANNER" },
    }),
    props: ({ getNonCommunityUsers, ownProps }) => {
      const users = getUsersNotOnCommunityFromCache({ id: ownProps.orgId });
      return {
        users,
        ownProps,
        isData: ownProps.isData && !_.isEmpty(users),
        isLoading:
          getNonCommunityUsers["networkStatus"] == 1 ||
          getNonCommunityUsers["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  })
  // withLoader
)(InviteToCommunityComponent);
