import { checklistFragment, checklistItemFragment } from "./ChecklistFragments";
import gql from "graphql-tag";
export const createChecklistMutation = gql`
  mutation createChecklist(
    $title: String
    $checklistItems: [ChecklistItemInput]
    $checklistOptionItems: [ChecklistOptionItemInput]
    $createdBy: ID
    $checklistId: ID
    $copyChecklist: Boolean!
    $isGlobal: Boolean
    $parentId: ID
    $parentType: ASSESSMENT_TOOL_PARENT_TYPE_ENUM
    $curriculumProgramId: ID
  ) {
    planner {
      createChecklist(
        input: {
          isGlobal: $isGlobal
          title: $title
          checklistItems: $checklistItems
          checklistOptionItems: $checklistOptionItems
          createdBy: $createdBy
          checklistId: $checklistId
          copyChecklist: $copyChecklist
          parentId: $parentId
          parentType: $parentType
          curriculumProgramId: $curriculumProgramId
        }
      ) {
        ...checklist
      }
    }
  }
  ${checklistFragment.checklist}
`;

export const createChecklistItemMutation = gql`
  mutation createChecklistItem(
    $checklistId: ID!
    $label: String!
    $createdBy: ID!
  ) {
    planner {
      createChecklistItem(
        input: {
          checklistId: $checklistId
          label: $label
          createdBy: $createdBy
        }
      ) {
        ...checklistItem
      }
    }
  }
  ${checklistItemFragment.checklistItem}
`;

export const createChecklistOptionItemMutation = gql`
  mutation createChecklistOptionItem(
    $checklistId: ID!
    $label: String!
    $createdBy: ID!
  ) {
    planner {
      createChecklistOptionItem(
        input: {
          checklistId: $checklistId
          label: $label
          createdBy: $createdBy
        }
      ) {
        ...checklistOption
      }
    }
  }
  ${checklistItemFragment.checklistOption}
`;

export const deleteChecklistMutation = gql`
  mutation deleteChecklist($id: ID!) {
    planner {
      deleteChecklist(input: { id: $id })
    }
  }
`;

export const deleteChecklistOptionMutation = gql`
  mutation deleteChecklistOption($id: ID!, $deletedBy: ID!, $checklistId: ID!) {
    planner {
      deleteChecklistOption(
        input: { deletedBy: $deletedBy, checklistId: $checklistId, id: $id }
      )
    }
  }
`;

export const deleteChecklistItemMutation = gql`
  mutation deleteChecklistItem($id: ID!, $deletedBy: ID!, $checklistId: ID!) {
    planner {
      deleteChecklistItem(
        input: { deletedBy: $deletedBy, checklistId: $checklistId, id: $id }
      )
    }
  }
`;

export const updateChecklistMutation = gql`
  mutation updateChecklist($id: ID!, $title: String!) {
    planner {
      updateChecklist(input: { id: $id, title: $title }) {
        id
      }
    }
  }
`;

export const updateChecklistOptionMutation = gql`
  mutation updateChecklistOptionItem(
    $id: ID!
    $updatedBy: ID!
    $label: String!
  ) {
    planner {
      updateChecklistOptionItem(
        input: { updatedBy: $updatedBy, id: $id, label: $label }
      ) {
        id
      }
    }
  }
`;

export const updateChecklistItemMutation = gql`
  mutation updateChecklistItem($id: ID!, $updatedBy: ID!, $label: String!) {
    planner {
      updateChecklistItem(
        input: { updatedBy: $updatedBy, id: $id, label: $label }
      ) {
        id
      }
    }
  }
`;
