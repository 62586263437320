import { injectReducer } from "store/reducers";
import AssessmentCriteria from "AssessmentCriteria";
import StudentProfileTemplates from "Administrator/commonComponents/StudentProfileTemplates";
import EvaluationCriteria from "EvaluationCriteria";
import SubjectsSetup from "./routes/SubjectsSetup";
import AssessmentToolSetup from "AssessmentToolSetup";
import LearnerProfilesSetup from "./routes/LearnerProfilesSetup";
import ConceptsSetup from "./routes/ConceptsSetup";
import TemplateGradeMapping from "Administrator/commonComponents/TemplateGradeMapping";
import AdminScopeAndSequence from "./routes/AdminScopeAndSequence";
import AdminATLs from "./routes/AdminATLs";
import { withAsyncRouteLoading } from "UIComponents";
import Continuum from "Continuum";
import ScoreCategories from "ScoreCategories";
import { updateChildRoutes } from "Administrator/modules/AdministratorModule";

export default store => ({
  path: "academicSetup",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/AcademicSetupModule").default;
            injectReducer(store, { key: "academicSetup", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
  onEnter(nextState) {
    const { routes } = nextState;
    store.dispatch(
      updateChildRoutes({
        routes,
        path: "academicSetup",
        pathId: "PYP_ACADEMIC_SETUP",
      })
    );
  },
  childRoutes: [
    StudentProfileTemplates(store),
    AssessmentCriteria(store),
    EvaluationCriteria(store),
    AdminATLs(store),
    SubjectsSetup(store),
    LearnerProfilesSetup(store),
    ConceptsSetup(store),
    AssessmentToolSetup(store),
    TemplateGradeMapping(store),
    AdminScopeAndSequence(store),
    Continuum(store),
    ScoreCategories(store),
  ],
});
