import React from "react";
import classes from "./UIInputNumberField.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { AddOutlined, MinusOutlined } from "@toddle-design/web-icons";
import { colors } from "@toddle-design/theme";

const isNumber = evt => {
  const charCode = evt.which;

  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

class UIInputNumberField extends UIBaseComponent {
  constructor(props) {
    super(props);
    if (_.isNull(props.value)) {
      this.updateValue(0);
    }
  }
  isValidValue = value => {
    return value != 0 && _.trim(_.toString(value));
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      let param = {};
      param[e.target.name] = e.target.value;
      if (this.props.onEnter) {
        this.props.onEnter(param);
      }
    }

    return isNumber(e);
  };

  shouldShowViewEmpty = () => {
    return false;
  };

  onBlurLocal = e => {
    const { isStringInput } = this.props;
    let value = e.target.value;
    const re = /^[0-9\b]+$/;
    if ((!_.trim(value) || !re.test(value)) && !isStringInput) {
      value = 0;
    }
    const updatedValue = isStringInput ? value : parseInt(value);
    this.updateValue(updatedValue);
    this.onBlur({ value: updatedValue });
  };

  handleChange = e => {
    const { isStringInput } = this.props;
    const re = /^[0-9\b]+$/;
    let value = e.target.value;

    if (re.test(value) || isStringInput || !!!value) {
      this.updateValue(value);
    }
  };

  increaseValue = () => {
    const { value } = this.props;

    const updatedValue = parseInt(value ? value : 0) + 1;
    this.updateValue(updatedValue);
  };

  decreaseValue = () => {
    const { value } = this.props;
    const updatedValue = parseInt(value ? value : 0) - 1;
    this.updateValue(updatedValue >= 0 ? updatedValue : 0);
  };

  renderEdit = () => {
    const {
      inputStyle,
      disabled,
      value,
      name,
      numberLabel,
      errorStyle,
      showControlButtons,
      fallbackValue,
      inputType,
      maxLength,
    } = this.props;
    const { error } = this.state;
    let updatedInputStyle = { ...inputStyle };

    if (disabled) {
      updatedInputStyle = { ...inputStyle, cursor: "not-allowed" };
    }
    if (!!error) {
      updatedInputStyle = { ...updatedInputStyle, ...errorStyle };
    }

    let inputText = classNames(
      { [classes.inputText]: true },
      { [classes.disabledInputBox]: disabled },
      { [classes.error]: !!error }
    );
    return (
      <div className={classes.container}>
        {numberLabel && <div className={classes.labelText}>{numberLabel}</div>}
        <div className={classes.numFieldContainer}>
          {showControlButtons && (
            <div
              unselectable="on"
              className={classes.button}
              onClick={this.decreaseValue}
            >
              <MinusOutlined variant={"subtle"} />
            </div>
          )}
          <input
            onKeyPress={e => this.handleKeyPress(e)}
            disabled={disabled}
            style={updatedInputStyle}
            className={inputText}
            type={inputType}
            value={value ? value : fallbackValue}
            onChange={e => this.handleChange(e)}
            onBlur={this.onBlurLocal}
            name={name}
            min={0}
            maxLength={maxLength}
          />
          {showControlButtons && (
            <div
              unselectable="on"
              className={classes.button}
              onClick={this.increaseValue}
            >
              <AddOutlined variant={"subtle"} />
            </div>
          )}
        </div>
      </div>
    );
  };
}
UIInputNumberField.defaultProps = {
  ...UIBaseComponent.defaultProps,
  inputStyle: {},
  value: 0,
  numberLabel: "",
  showControlButtons: true,
  fallbackValue: 0,
  inputType: "text",
  maxLength: "3",
  isStringInput: false,
};

UIInputNumberField.propTypes = {
  ...UIBaseComponent.propTypes,
  onBlur: PropTypes.func,
  inputStyle: PropTypes.object,
  onEnter: PropTypes.func,
  numberLabel: PropTypes.string,
  maxLength: PropTypes.string,
  isStringInput: PropTypes.bool,
};

export default UIInputNumberField;
