import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import classes from "./ShareSettingsStep.scss";
import {
  UIModal,
  SwitchButton,
  I18nHOC,
  SelectDropdown,
  withLoader,
  LinkWithTooltip,
} from "UIComponents";
import classNames from "classnames";
import { colors } from "Constants";
import update from "immutability-helper";
import AddClassModal from "../AddClassModal";
import ClassCard from "../ClassCard";
import {
  getAssessmentDetailsQuery,
  getMultiCourseUserMapQuery,
} from "ClassRoom/modules/ClassRoomQuery";
import {
  getAssessmentDetailsFromCache,
  getMultiCourseUserMapFromCache,
} from "ClassRoom/modules/ClassRoomGraphqlHelpers";
import ACLStore from "lib/aclStore";
import { getSettingValue } from "modules/PermissionModule";
import {
  WarningTriangleOutlined,
  InformationOutlined,
} from "@toddle-design/web-icons";
import { Button } from "@toddle-design/web";
import {
  getShowAssessmentTool,
  getScoreData,
  getUpdatedCoursesStudentsForHLSL,
  getCourseStudents,
  checkAssessmentHasPypEvaluation,
  getSubjectNameMemoize,
} from "ClassRoom/modules/utils";
import {
  ASSESSMENT_TYPE_QUICK_TASK,
  CURRICULUM_TYPE_PYP,
} from "Constants/stringConstants";
import { getCourseIdsMemoize } from "UnitPlans/components/IBcomponents/MaxScoreTool/Utils";
import { checkCategorySetApplicableTo } from "ScoreCategories/modules/ScoreCategoriesModule";

const updatedClassItem = ({ id, params, courses }) => {
  const index = _.findIndex(courses, { id });
  return update(courses, {
    [index]: { $set: { ...courses[index], ...params } },
  });
};

const getCourses = ({
  isUsingScoreAssessment,
  scoreCategoryId,
  courseIdsOfScoreCategorySet,
  courses,
  isCourseApplicableToCategorySet,
}) => {
  // if score is enable and category is selected, then keep only relevant courses
  if (
    isCourseApplicableToCategorySet &&
    isUsingScoreAssessment &&
    scoreCategoryId
  ) {
    return _.filter(courses, course => {
      return _.includes(courseIdsOfScoreCategorySet, _.get(course, "id"));
    });
  } else {
    return courses;
  }
};

const getCoursesMemoize = _.memoize(
  params => getCourses(params),
  params => JSON.stringify(params)
);

const ShareSettingsStep = React.memo(props => {
  const {
    curriculumProgram,
    isClassDiscussionEnabled,
    updateSelectedItems,
    courses,
    showAddClassButton,
    courseId,
    isStudentSubmissionEnabled,
    isStudentEvaluationEnabled,
    isTeacherEvaluationEnabled,
    contentType,
    t,
    genericFolders,
    folderNames,
    portalType,
    assessmentToolAvailable,
    studentTemplates,
    selectedTimeSlot,
    leHasPypEvaluation,
    setDisableButton,
    mode,
    assignmentId,
    isSimilarityCheckEnabled,
    isTurnitinEnabledForGrade,
    isQt,
    isPrivate,
    isUsingScoreAssessment,
    courseIdsOfScoreCategorySet,
    scoreCategoryId,
    scoreCategoryLabel,
    subjectName,
    assignmentTags,
    coursesUserMap,
    isCourseApplicableToCategorySet,
  } = props;

  const [showAddClassModal, changeShowAddClassModal] = useState(false);
  const [coursesSearchText, changeCoursesSeachText] = useState("");

  const newCourses = getCoursesMemoize({
    isUsingScoreAssessment,
    scoreCategoryId,
    courseIdsOfScoreCategorySet,
    courses,
    isCourseApplicableToCategorySet,
  });
  if (_.size(newCourses) !== _.size(courses)) {
    updateSelectedItems({
      courses: [...newCourses],
    });
  }

  useEffect(() => {
    if (
      mode == "create" &&
      (assessmentToolAvailable || isUsingScoreAssessment)
    ) {
      updateSelectedItems({ isTeacherEvaluationEnabled: true });
    }
  }, [assessmentToolAvailable, isUsingScoreAssessment]);

  //To filter out students based on HL/SL
  useEffect(() => {
    if (!_.isEmpty(coursesUserMap)) {
      const updatedCourses = getUpdatedCoursesStudentsForHLSL({
        courses,
        coursesUserMap,
      });
      updateSelectedItems({
        courses: updatedCourses,
      });
    }
  }, [coursesUserMap]);

  const enableClassroomPrivateSetting = ACLStore.can(
    "FeatureFlag:EnableClassroomPrivateSetting"
  );

  const allowTeacherEvaluationSettings =
    assessmentToolAvailable || leHasPypEvaluation || isUsingScoreAssessment;

  const curriculumType = _.get(curriculumProgram, "type", "IB_PYP");

  const onClickAddClasses = params => {
    updateSelectedItems({
      courses: [...courses, ...params],
    });
    changeShowAddClassModal(false);
  };

  const getTeacherEvalSubTitle = () => {
    if (isQt) {
      return allowTeacherEvaluationSettings
        ? t("classRoom:enable_teacher_eval_qt")
        : t("classRoom:le_not_have_assessment_tool");
    } else
      return allowTeacherEvaluationSettings
        ? t("classRoom:enable_teacher_eval")
        : t("classRoom:le_not_have_assessment_tool_tagged_pyp_elements");
  };

  const onClickRemoveClass = id => {
    const removeIndex = _.findIndex(courses, course => course.id === id);
    updateSelectedItems({
      courses: update(courses, { $splice: [[removeIndex, 1]] }),
    });
  };

  const classRoomDiscussionCard = classNames({ [classes.settingCard]: true });

  const teacherEvalSettingCard = classNames(
    { [classes.settingCard]: true },
    { [classes.lowOpacity]: !allowTeacherEvaluationSettings }
  );

  const studentEvalSettingCard = classNames(
    { [classes.settingCard]: enableClassroomPrivateSetting },
    { [classes.lowOpacity]: !assessmentToolAvailable },
    { [classes.settingCardLast]: !enableClassroomPrivateSetting }
  );

  const showEvaluation =
    curriculumType !== "IB_MYP" || ACLStore.can("Common:MYPClassroomRating");

  return (
    <div className={classes.container}>
      <div className={classes.coursesContainer}>
        {_.isEmpty(courses) ? (
          <div>{t("common:no_class_added")}</div>
        ) : (
          _.map(courses, (course, i) => {
            const courseStudents = getCourseStudents({
              coursesUserMap,
              courseId: course.id,
            });
            return (
              <div>
                {i === 0 ? (
                  <div className={classes.title}>
                    {t("common:class_plural")}
                  </div>
                ) : null}
                <ClassCard
                  course={course}
                  key={course.id}
                  showDeadline={true}
                  courseId={courseId}
                  onClickRemoveClass={onClickRemoveClass}
                  updateItem={params =>
                    updateSelectedItems({
                      courses: updatedClassItem({
                        id: course.id,
                        params,
                        courses,
                      }),
                    })
                  }
                  t={t}
                  portalType={portalType}
                  setDisableButton={setDisableButton}
                  mode={mode}
                  assignmentId={assignmentId}
                  assignmentTags={assignmentTags}
                  courseStudents={courseStudents}
                />
              </div>
            );
          })
        )}
      </div>
      {showAddClassButton && (
        <div className={classes.button}>
          <Button
            size="medium"
            variant="dismissive"
            onClick={() => changeShowAddClassModal(true)}
          >
            {t("common:add_with_label", { label: t("common:class") })}
          </Button>
        </div>
      )}

      <SelectDropdown
        options={genericFolders}
        label={t("classRoom:tag_folder")}
        value={folderNames}
        placeholder={t("classRoom:tag_folder_placeholder")}
        updateInputField={value =>
          updateSelectedItems({ folderNames: value ? value : [] })
        }
        containerStyle={{
          marginTop: "48px",
          marginBottom: "48px",
        }}
        menuContainerStyle={{ zIndex: 100 }}
        allowAddOption={true}
        multi={true}
      />

      {showAddClassModal && (
        <UIModal
          isOpen={showAddClassModal}
          modalContent={classes.addClassModalContainer}
          onRequestClose={() => changeShowAddClassModal(false)}
        >
          <AddClassModal
            curriculumProgram={curriculumProgram}
            onClickClose={() => changeShowAddClassModal(false)}
            onClickAddClasses={onClickAddClasses}
            coursesSearchText={coursesSearchText}
            changeCoursesSeachText={changeCoursesSeachText}
            courses={courses}
            portalType={portalType}
            selectedTimeSlot={selectedTimeSlot}
            courseIdsOfScoreCategorySet={courseIdsOfScoreCategorySet}
            scoreCategoryLabel={scoreCategoryLabel}
            subjectName={subjectName}
          />
        </UIModal>
      )}
      <div className={classes.title}>{t("common:settings")}</div>
      <div className={classes.settings}>
        <div className={classes.settingCard}>
          <div className={classes.settingLeftContainer}>
            <div className={classes.settingTitle}>
              {t("classRoom:online_submission")}
            </div>
            <LinkWithTooltip
              tooltip={t("classRoom:enable_student_to_submit")}
              placement={"bottom"}
              id="tooltip-InformationOutlined"
            >
              <InformationOutlined size={"xx-small"} variant={"subtle"} />
            </LinkWithTooltip>
          </div>
          <div className={classes.switch}>
            <SwitchButton
              data-test-id={"switch-enabled-student-to-submit"}
              isDisabled={_.get(studentTemplates, "length", 0) > 0}
              updateInputField={value =>
                updateSelectedItems({
                  isStudentSubmissionEnabled: value,
                  ...(!value && { isSimilarityCheckEnabled: value }),
                })
              }
              value={isStudentSubmissionEnabled}
            />
          </div>
        </div>
        <div className={classRoomDiscussionCard}>
          <div className={classes.settingLeftContainer}>
            <div className={classes.settingTitle}>
              {t("classRoom:class_discussion")}
            </div>
            <LinkWithTooltip
              tooltip={t("classRoom:enable_student_discussion")}
              placement={"bottom"}
              id="tooltip-InformationOutlined"
            >
              <InformationOutlined size={"xx-small"} variant={"subtle"} />
            </LinkWithTooltip>
          </div>
          <div className={classes.switch}>
            <SwitchButton
              data-test-id={"switch-enabled-student-discussion"}
              isDisabled={contentType == "ASSIGNMENT_DISCUSSION"}
              updateInputField={value =>
                updateSelectedItems({ isClassDiscussionEnabled: value })
              }
              value={isClassDiscussionEnabled}
              onColor={colors.blue29}
            />
          </div>
        </div>
        {isTurnitinEnabledForGrade && isStudentSubmissionEnabled && (
          <div className={classes.settingCard}>
            <div className={classes.settingLeftContainer}>
              <div className={classes.settingTitle}>
                {t("classRoom:check_for_similarity")}
              </div>
              <LinkWithTooltip
                tooltip={t("classRoom:enable_similarity_check_subtext")}
                placement={"bottom"}
                id="tooltip-InformationOutlined"
              >
                <InformationOutlined size={"xx-small"} variant={"subtle"} />
              </LinkWithTooltip>
            </div>
            <div className={classes.switch}>
              <SwitchButton
                data-test-id={"switch-enabled-similarity-check"}
                updateInputField={value =>
                  updateSelectedItems({
                    isSimilarityCheckEnabled: value,
                  })
                }
                value={isSimilarityCheckEnabled}
              />
            </div>
          </div>
        )}
        {contentType == "ASSESSMENT" && showEvaluation && (
          <div className={teacherEvalSettingCard}>
            <div className={classes.settingLeftContainer}>
              <div className={classes.settingTitle}>
                {t("common:teacher_evaluation")}
              </div>
              <LinkWithTooltip
                tooltip={getTeacherEvalSubTitle()}
                placement={"bottom"}
                id="tooltip-InformationOutlined"
              >
                <InformationOutlined size={"xx-small"} variant={"subtle"} />
              </LinkWithTooltip>
            </div>
            <div className={classes.switch}>
              {allowTeacherEvaluationSettings ? (
                <SwitchButton
                  data-test-id={"switch-enabled-teacher-evaluation"}
                  updateInputField={value =>
                    updateSelectedItems({ isTeacherEvaluationEnabled: value })
                  }
                  value={isTeacherEvaluationEnabled}
                />
              ) : (
                <div className={classes.warningTriangleStyle}>
                  <LinkWithTooltip
                    tooltip={t(
                      "unitPlanTemplate:le_measureAssessing_emptyViewText"
                    )}
                    placement={"bottom"}
                    id="tooltip-InformationOutlined"
                  >
                    <WarningTriangleOutlined
                      size={"x-small"}
                      variant={"warning"}
                    />
                  </LinkWithTooltip>
                </div>
              )}
            </div>
          </div>
        )}
        {contentType == "ASSESSMENT" && showEvaluation && (
          <div className={studentEvalSettingCard}>
            <div className={classes.settingLeftContainer}>
              <div className={classes.settingTitle}>
                {t("common:student_evaluation")}
              </div>
              <LinkWithTooltip
                tooltip={
                  assessmentToolAvailable
                    ? t("classRoom:enable_student_eval")
                    : t("classRoom:le_not_have_assessment_tool")
                }
                placement={"bottom"}
                id="tooltip-InformationOutlined"
              >
                <InformationOutlined size={"xx-small"} variant={"subtle"} />
              </LinkWithTooltip>
            </div>
            <div className={classes.switch}>
              {assessmentToolAvailable ? (
                <SwitchButton
                  data-test-id={"switch-enabled-student-evaluation"}
                  updateInputField={value =>
                    updateSelectedItems({ isStudentEvaluationEnabled: value })
                  }
                  value={isStudentEvaluationEnabled}
                />
              ) : (
                <div className={classes.warningTriangleStyle}>
                  <LinkWithTooltip
                    tooltip={t(
                      "unitPlanTemplate:le_measureAssessing_emptyViewText"
                    )}
                    placement={"bottom"}
                    id="tooltip-InformationOutlined"
                  >
                    <WarningTriangleOutlined
                      size={"x-small"}
                      variant={"warning"}
                    />
                  </LinkWithTooltip>
                </div>
              )}
            </div>
          </div>
        )}
        {enableClassroomPrivateSetting ? (
          <div className={classes.settingCardLast}>
            <div className={classes.settingLeftContainer}>
              <div className={classes.settingTitle}>
                {t("classRoom:private_post")}
              </div>
              <LinkWithTooltip
                tooltip={t("classRoom:post_private_teacher")}
                placement={"bottom"}
                id="tooltip-InformationOutlined"
              >
                <InformationOutlined size={"xx-small"} variant={"subtle"} />
              </LinkWithTooltip>
            </div>
            <div className={classes.switch}>
              <SwitchButton
                data-test-id={"switch-enabled-private-post"}
                updateInputField={value =>
                  updateSelectedItems({ isPrivate: value })
                }
                value={isPrivate}
                onColor={colors.blue29}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => {
  const curriculumProgram = _.get(ownProps, "curriculumProgram", {});
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  const turnitinEnabledGrades = getSettingValue({
    name: "TurnitinEnabledGrades",
    organizationId,
  });
  const selectedGrades = _.get(
    state,
    "teacher.selected_class.selected_grades",
    []
  );
  const isTurnitinEnabledForGrade = !_.isEmpty(
    _.intersection(turnitinEnabledGrades, selectedGrades)
  );

  const courseIds = _.map(ownProps?.courses, item => item.id);
  const isCourseApplicableToCategorySet =
    checkCategorySetApplicableTo({
      organizationId,
    }) === "COURSE";

  return {
    isData: true,
    isLoading: false,
    curriculumProgram,
    isTurnitinEnabledForGrade,
    courseIds,
    isCourseApplicableToCategorySet,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "classRoom"] }),
  connect(mapStateToProps),
  graphql(getAssessmentDetailsQuery, {
    skip: ({ contentType, leId }) => contentType != "ASSESSMENT" || !leId,
    name: "getAssessmentDetails",
    options: ({ leId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: leId,
      },
    }),
    props({
      getAssessmentDetails,
      ownProps: {
        leId,
        isData,
        isLoading,
        curriculumProgram,
        curriculumType,
        isCourseApplicableToCategorySet,
      },
    }) {
      const assessmentDetails = getAssessmentDetailsFromCache({
        id: leId,
      });

      const assessmentToolAvailable = getShowAssessmentTool(assessmentDetails);

      const leHasPypEvaluation = checkAssessmentHasPypEvaluation({
        assessmentDetails,
        curriculumProgramType: _.get(
          curriculumProgram,
          "type",
          curriculumType || CURRICULUM_TYPE_PYP
        ),
      });
      const isQt = _.isEqual(
        _.get(assessmentDetails, "type"),
        ASSESSMENT_TYPE_QUICK_TASK
      );

      const allFields = _.get(assessmentDetails, "allFields");
      const {
        scoreAssociatedEntities,
        isUsingScoreAssessment,
        scoreCategoryId,
        scoreCategoryLabel,
      } = getScoreData({
        allFields,
      });
      const courseIdsOfScoreCategorySet = getCourseIdsMemoize({
        associatedEntities: scoreAssociatedEntities,
        entityType: isCourseApplicableToCategorySet ? "Course" : "Grade",
      });

      const assignmentTags = _.get(
        _.find(allFields, field => {
          return _.get(field, "uid") === "subjectLevel";
        }),
        "value"
      );
      const subjects = _.find(allFields, field => field.uid === "subjects");
      const subjectName = getSubjectNameMemoize({ subjects });

      return {
        assessmentToolAvailable,
        isUsingScoreAssessment,
        scoreCategoryId,
        scoreCategoryLabel,
        subjectName,
        isQt,
        leHasPypEvaluation,
        courseIdsOfScoreCategorySet,
        assignmentTags,
        isData: !_.isEmpty(assessmentDetails) && isData,
        isLoading:
          getAssessmentDetails["networkStatus"] == 1 ||
          getAssessmentDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getMultiCourseUserMapQuery, {
    skip: ({ courseIds }) => _.isEmpty(courseIds),
    name: "getMultiCourseUserMap",
    options: ({ courseIds, portalType, assignmentTags }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: courseIds,
        portalType,
        filters: {
          tags: assignmentTags,
          userTypes: ["student"],
        },
      },
    }),
    props({
      getMultiCourseUserMap,
      ownProps: { courseIds, isData, isLoading, assignmentTags },
    }) {
      const coursesUserMap = getMultiCourseUserMapFromCache({
        ids: courseIds,
        filters: {
          tags: assignmentTags,
          userTypes: ["student"],
        },
      });
      return {
        isData: !_.isEmpty(coursesUserMap) && isData,
        coursesUserMap,
        isLoading:
          getMultiCourseUserMap["networkStatus"] == 1 ||
          getMultiCourseUserMap["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(ShareSettingsStep);
