import React from "react";
import PropTypes from "prop-types";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { I18nHOC, EmptyField, EmptyView } from "UIComponents";
import { DragAndDropList as DRAG_AND_DROP_LIST } from "@toddle-design/web";
import shortId from "shortid";
import { NoFoldersIllustration } from "@toddle-design/theme";
import UIInputList from "./UIInputList";
class DragAndDropList extends UIBaseComponent {
  componentDidMount = () => {
    const { value } = this.props;

    if (value.length == 0) {
      this.handleInit();
    }

    if (this.shouldShowEditEmpty()) {
      this.updateValue([{ id: shortId.generate(), value: "" }]);
    }
  };

  handleInit = () => {
    const { options, shouldInitOptions } = this.props;
    if (shouldInitOptions && options.length > 0) {
      this.updateValue(
        _.map(options, option => {
          return { id: option.value, value: option.label, isSelected: false };
        })
      );
    } else {
      this.updateValue([{ id: shortId.generate(), value: "" }]);
    }
  };
  componentDidUpdate = prevProps => {
    if (
      _.get(this.props.value, "length", 0) == 0 ||
      !_.isEqualWith(prevProps.options, this.props.options, "id")
    ) {
      this.handleInit();
    }
  };

  shouldShowEditEmpty = () => {
    const { options, shouldInitOptions } = this.props;
    if (shouldInitOptions) {
      return _.get(options, "length", 0) == 0;
    } else {
      return false;
    }
  };

  renderEditEmpty = () => {
    const { emptyText, emptyEditText } = this.props;
    return <EmptyField title={emptyEditText || emptyText} />;
  };

  renderViewEmpty = () => {
    const { emptyText } = this.props;
    return (
      <EmptyView
        emptyImageUrl={NoFoldersIllustration}
        emptyHeaderText={emptyText}
        size="small"
      />
    );
  };

  renderEdit = () => {
    const {
      value,
      fieldKey,
      showListItemNumber,
      t,
      isDisabled = false,
    } = this.props;

    return isDisabled ? (
      <div style={{ pointerEvents: "none", flex: 1 }}>
        {_.size(value) ? (
          <UIInputList value={value} mode="view" showListItemNumber={false} />
        ) : (
          this.renderViewEmpty()
        )}
      </div>
    ) : (
      <DRAG_AND_DROP_LIST
        value={value}
        dndKey={`UI_INPUT_LIST:${fieldKey}`}
        onValueChange={newArr => this.updateValue(newArr)}
        listType={showListItemNumber ? "numberList" : "checklist"}
        itemPlaceholder={t("common:typeHere")}
        addItemText={t("common:add_another")}
      />
    );
  };
}

DragAndDropList.defaultProps = {
  ...UIBaseComponent.defaultProps,
  showListItemNumber: true,
  shouldInitOptions: false,
};

DragAndDropList.propTypes = {
  ...UIBaseComponent.propTypes,
  showListItemNumber: PropTypes.bool,
  shouldInitOptions: PropTypes.bool,
};

export default I18nHOC({ resource: "common" })(DragAndDropList);
