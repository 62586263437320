import React from "react";
import classes from "./NoDataComponent.scss";
import { NoSearchIcon, EmptySvgIcon } from "SvgComponents";
import { colors } from "Constants";
import { UIButton, I18nHOC } from "UIComponents";
import PropTypes from "prop-types";

const CreateButtonStyle = {
  minWidth: 240,
  height: 48,
  marginTop: 24,
};

const NoDataComponent = ({
  emptyText,
  emptyHeaderText,
  isSearch,
  createText,
  onCreateClick,
  containerStyle,
  emptyIcon,
  t,
  emptyIconSize,
  emptyHeaderTextStyle,
  noSearchTextStyle,
}) => {
  if (emptyHeaderText === "noResultHeading") {
    emptyHeaderText = t("common:noResultHeading");
  }
  if (emptyText === "emptyText_search") {
    emptyText = t("common:emptyText_search");
  }
  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.noSearchTopContainer}>
        <div className={classes.noSearchIcon}>
          {emptyIcon ? (
            emptyIcon
          ) : isSearch ? (
            <NoSearchIcon fill={colors.strokeTwo} width={56} height={56} />
          ) : (
            <EmptySvgIcon width={emptyIconSize} height={emptyIconSize} />
          )}
        </div>
        {!!emptyHeaderText && (
          <div
            className={classes.noSearchDataText}
            style={emptyHeaderTextStyle}
          >
            {emptyHeaderText}
          </div>
        )}
        {!!emptyText && (
          <div
            className={classes.noSearchText}
            style={noSearchTextStyle}
            dangerouslySetInnerHTML={{ __html: emptyText }}
          ></div>
        )}
        {!!createText && (
          <UIButton
            color="pink"
            onClick={onCreateClick}
            containerStyle={CreateButtonStyle}
          >{`${createText}`}</UIButton>
        )}
      </div>
    </div>
  );
};

NoDataComponent.propTypes = {
  emptyText: PropTypes.string,
  emptyHeaderText: PropTypes.any,
  isSearch: PropTypes.bool,
  noSearchTextStyle: PropTypes.object,
};

NoDataComponent.defaultProps = {
  containerStyle: {},
  createText: "",
  isSearch: true,
  emptyHeaderText: `noResultHeading`,
  emptyText: `emptyText_search`,
  noSearchTextStyle: {},
};

export default I18nHOC({ resource: "common" })(NoDataComponent);
