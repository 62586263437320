import React from "react";

const NewLinkIcon = props => {
  const { width, height, fill } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0104 4.97134C11.2476 5.20755 11.2476 5.59051 11.0104 5.82671L5.84847 10.9678C5.61131 11.204 5.2268 11.204 4.98964 10.9678C4.75248 10.7316 4.75248 10.3487 4.98964 10.1125L10.1516 4.97134C10.3887 4.73514 10.7733 4.73514 11.0104 4.97134Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90513 6.96744C4.14229 7.20364 4.14229 7.5866 3.90513 7.82281L2.62982 9.09297L2.62059 9.10197C1.41775 10.25 1.40432 12.1582 2.62982 13.3787L2.63886 13.3879C3.79009 14.5844 5.70245 14.5993 6.92815 13.3835L8.19837 12.0581C8.42995 11.8165 8.81436 11.8076 9.05697 12.0383C9.29958 12.2689 9.30852 12.6518 9.07694 12.8934L7.80163 14.224L7.79177 14.2341C6.10383 15.9152 3.40766 15.9301 1.76644 14.2296C0.0830802 12.5482 0.0696483 9.86637 1.77538 8.23322L3.04629 6.96744C3.28345 6.73123 3.66797 6.73123 3.90513 6.96744Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2245 1.76133C15.932 3.39594 15.917 6.08123 14.2291 7.76236L12.9538 9.03253C12.7166 9.26873 12.3321 9.26873 12.0949 9.03253C11.8578 8.79632 11.8578 8.41336 12.0949 8.17715L13.3702 6.90699C14.5957 5.68643 14.5823 3.77831 13.3795 2.63024L13.3702 2.62123C12.1447 1.40067 10.2289 1.41405 9.07617 2.61204L9.06713 2.62123L7.79183 3.8914C7.55467 4.1276 7.17016 4.1276 6.933 3.8914C6.69584 3.65519 6.69584 3.27223 6.933 3.03603L8.20391 1.77024C9.84367 0.0713836 12.5363 0.0847613 14.2245 1.76133Z"
        fill={fill}
      />
    </svg>
  );
};

NewLinkIcon.defaultProps = {
  height: 16,
  width: 16,
};

export default NewLinkIcon;
