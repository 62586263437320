import { withAsyncRouteLoading } from "UIComponents";

const StudentRoute = store => {
  return {
    path: "students",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./Students").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "students"
          );
        })
    ),
    childRoutes: [],
  };
};
export default StudentRoute;
