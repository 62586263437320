import React from "react";

const ResumeIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <rect
          width="4"
          height="20"
          y="2"
          fill={props.fill}
          fillRule="nonzero"
          rx="2"
        />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M9.56652882,2.07698857 L22.8013936,11.1759581 C23.2564994,11.4888433 23.3717919,12.111423 23.0589067,12.5665288 C22.9895544,12.6674048 22.9022696,12.7546897 22.8013936,12.8240419 L9.56652882,21.9230114 C9.11142303,22.2358967 8.48884331,22.1206041 8.17595808,21.6654983 C8.06135295,21.4988 8,21.3012631 8,21.0989695 L8,2.90103049 C8,2.34874574 8.44771525,1.90103049 9,1.90103049 C9.20229356,1.90103049 9.39983045,1.96238344 9.56652882,2.07698857 Z"
        />
      </g>
    </svg>
  );
};

ResumeIcon.defaultProps = {
  height: 24,
  width: 24,
  fill: "#F75961",
};

export default ResumeIcon;
