import {
  createOrganizationV2Mutation,
  createOrganizationPresetMutation,
  updateOrganizationPresetMutation,
  deleteOrganizationPresetMutation,
  approveOrganizationMutation,
  approveUserMutation,
  deleteApproveSchoolRequestMutation,
  deleteApproveUserRequestMutation,
  updateOrganizationMutation,
  updateOrganizationAcademicYearMutation,
  createOrganizationAcademicYearMutation,
  editTeacherMutation,
  createOrganizationInvitedUserMutation,
  editStandardInfoMutation,
  deleteOrganizationMutation,
  updateConfigurablePermissionMutation,
  deleteStandardMutation,
  internalToolsGenericMutation,
  updateBaseProgressReportTemplateMutation,
  updateOrganizationPaidStatusMutation,
  updateCurriculumProgramsMutation,
  validateInternalToolsModulePasswordMutation,
} from "./ToolingMutations";
import {
  deleteUserMutation,
  addUserMutation,
  removeCoursesFromUserMutation,
  addCoursesToUserMutation,
} from "QueryMutationFragment/CommonQueryMutationFragment/mutations";
import update from "immutability-helper";
import {
  getOrganizationPlannerTemplateQuery,
  getAllOrganizationQuery,
  getOrganizationApprovalRequestFeed,
  getUserApprovalRequestFeed,
  getTeacherFeedQuery,
  getUserFeedQuery,
  getStandardFeedQuery,
  getAcademicYears,
  getOrganizationFeedQuery,
  fetchAllClassesOfOrgQuery,
  getProgressReportBaseTemplateQuery,
} from "./ToolingQueries";
import axios from "axios";
import { goToRelativeRoute } from "modules/Services";
import { bulkUploadByFileMutation } from "modules/CommonMutations";
import { setToastMsg, getUserInfo } from "Login/modules/LoginModule";
import client from "apolloClient";
import { updateLoading } from "Administrator/modules/CommonModule";
import { colors } from "Constants";
import moment from "moment";
import { generateRandomId, getBackendServerUrl, dispatchError } from "Utils";

export const NAME = "tooling";

export const CHANGE_ORGANIZATION_SEARCH_STRING =
  "CHANGE_ORGANIZATION_SEARCH_STRING" + " " + NAME;

export const CHANGE_USER_SEARCH_STRING =
  "CHANGE_USER_SEARCH_STRING" + " " + NAME;

export const CHANGE_STANDARD_SEARCH_STRING =
  "CHANGE_STANDARD_SEARCH_STRING" + " " + NAME;

export const SET_IS_LOADING_STATE = "SET_IS_LOADING_STATE" + " " + NAME;
export const TOGGLE_ACTION_LOADING = "TOGGLE_ACTION_LOADING" + " " + NAME;
export const UPDATE_FILTERS = "UPDATE_FILTERS" + " " + NAME;
export const UPDATE_SUBJECT_FILTERS = "UPDATE_SUBJECT_FILTERS" + " " + NAME;
export const CHANGE_SEARCH_TERM = "CHANGE_SEARCH_TERM" + " " + NAME;
export const INIT_SEARCH_TERM = "INIT_SEARCH_TERM" + " " + NAME;
export const UPDATE_TEACHER_OBJECT = "UPDATE_TEACHER_OBJECT" + " " + NAME;
export const UPDATE_TEACHER_STEP = "UPDATE_TEACHER_STEP" + " " + NAME;
export const INIT_TEACHER_OBJECT = "INIT_TEACHER_OBJECT" + " " + NAME;
export const TOGGLE_TEACHER_TABS = "TOGGLE_TEACHER_TABS" + " " + NAME;
export const INIT_REMOVED_COURSES = "INIT_REMOVED_COURSES" + " " + NAME;
export const TOGGLE_REMOVED_COURSES = "TOGGLE_REMOVED_COURSES" + " " + NAME;
export const TOGGLE_MANAGE_COURSE_ROUTE =
  "TOGGLE_MANAGE_COURSE_ROUTE" + " " + NAME;
export const UPDATE_ORGANIZATION_FILTERS =
  "UPDATE_ORGANIZATION_FILTERS" + " " + NAME;
export const SET_IS_PASSWORD_VAILD = "SET_IS_PASSWORD_VAILD" + " " + NAME;
export const SET_IS_LOADING = "SET_IS_LOADING" + " " + NAME;

export const updateOrganizationFilters = data => {
  return { type: UPDATE_ORGANIZATION_FILTERS, data };
};

export const changeOrganizationSearchString = payload => {
  return { type: CHANGE_ORGANIZATION_SEARCH_STRING, payload };
};

export const changeUserSearchString = payload => {
  return { type: CHANGE_USER_SEARCH_STRING, payload };
};

export const changeStandardSearchString = payload => {
  return { type: CHANGE_STANDARD_SEARCH_STRING, payload };
};

export const setIsLoadingState = payload => {
  return { type: SET_IS_LOADING_STATE, payload };
};

export const initSearchTerm = () => {
  return {
    type: INIT_SEARCH_TERM,
  };
};

const staffLabelMapping = [
  { key: "admin", locale: "common:admin_count" },
  { key: "teacher", locale: "common:teacher_count" },
];

const userStatus = [
  { key: "ROSTERED", label: "joined_count", color: colors.blue29 },
  { key: "INVITED", label: "invited_count", color: colors.yellow50 },
  { key: "REQUESTED", label: "requested_count", color: colors.salmon60 },
];

//User Feed

export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};
export const updateSubjectFilters = data => {
  return {
    type: UPDATE_SUBJECT_FILTERS,
    data,
  };
};

export const getTeacherRolesCount = ({ userCount, t }) => {
  const outPutString = _.reduce(
    staffLabelMapping,
    (result, { key, locale }) => {
      const count = _.get(_.find(userCount, { role: key }), "totalCount", 0);
      result.push(t ? t(locale, { count }) : locale);
      return result;
    },
    []
  );

  return _.join(outPutString, ", ");
};

export const getTeacherStatusCount = ({ userStatusCount, t }) => {
  const outPutString = _.reduce(
    userStatus,
    (result, { key, label, color }) => {
      const count = _.get(
        _.find(userStatusCount, { status: key }),
        "totalCount",
        0
      );

      if (count > 0) {
        const comp = `<span style="color:${color}">${t(`common:${label}`, {
          count,
        })}</span>`;
        result.push(comp);
      }
      return result;
    },
    []
  );

  return _.join(outPutString, ", ");
};

export const toggleManageCourseRoute = data => {
  return {
    type: TOGGLE_MANAGE_COURSE_ROUTE,
    data: data,
  };
};

export const toggleActionLoading = data => {
  return {
    type: TOGGLE_ACTION_LOADING,
    data,
  };
};

export const toggleTeacherTabs = data => {
  return {
    type: TOGGLE_TEACHER_TABS,
    payload: data,
  };
};

export const toggleRemovedCourses = data => {
  return {
    type: TOGGLE_REMOVED_COURSES,
    payload: data,
  };
};

export const initRemovedCourses = data => {
  return {
    type: INIT_REMOVED_COURSES,
    payload: data,
  };
};

export const changeSearchTerm = value => {
  return { type: CHANGE_SEARCH_TERM, payload: value };
};

export const updateTeacherObject = data => {
  return {
    type: UPDATE_TEACHER_OBJECT,
    payload: data,
  };
};

export const initTeacherObject = () => {
  return {
    type: INIT_TEACHER_OBJECT,
  };
};

export const updateTeacherStep = data => {
  return {
    type: UPDATE_TEACHER_STEP,
    payload: data,
  };
};

export const onContentUploaded = values => {
  return (dispatch, getState) => {
    dispatch(updateTeacherObject({ profileImage: values }));
  };
};

//

export const onClickNavigate = data => {
  return (dispatch, getState) => {
    if (typeof data === "object") {
      const { link, type } = data;

      dispatch(goToRelativeRoute({ route: link, type }));
    } else dispatch(goToRelativeRoute({ route: data, type: "push" }));
  };
};

export const updateBaseProgressReportTemplate = (id, body) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateBaseProgressReportTemplateMutation,
        variables: {
          id,
          body,
        },
        refetchQueries: [
          {
            query: getProgressReportBaseTemplateQuery,
            variables: {
              id,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:template_successfully_updated",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};

const getLocaleParams = portType => {
  switch (portType) {
    case "ADD_NEW_TEMPLATES_TO_ORGANIZATION":
      return [
        { key: "label", value: "Template ids", isPlainText: true },
        { key: "action", value: "were added", isPlainText: true },
      ];
    case "UPDATE_EXISTING_TEMPLATE":
      return [
        { key: "label", value: "Template id", isPlainText: true },
        { key: "action", value: "updated", isPlainText: true },
      ];
    case "REMOVE_TEMPLATES_FROM_ORGANIZATION":
      return [
        { key: "label", value: "Template ids", isPlainText: true },
        { key: "action", value: "were removed", isPlainText: true },
      ];
    case "ADD_HELP_TEXT":
      return [
        { key: "label", value: "Help Text", isPlainText: true },
        { key: "action", value: "added", isPlainText: true },
      ];
    default:
      return [
        { key: "label", value: "Entity", isPlainText: true },
        { key: "action", value: "Created", isPlainText: true },
      ];
  }
};

export const excuteInternalToolsGenericApi = input => {
  return async (dispatch, getState) => {
    let data = null;
    let rowsCount = null;
    try {
      const response = await client.mutate({
        mutation: internalToolsGenericMutation,
        variables: input,
      });
      const warning = _.get(
        response,
        "data.platform.internalToolsGenericMutation.warning"
      );
      data = _.get(response, "data.platform.internalToolsGenericMutation.data");
      // const isSuccess = _.get(
      //   response,
      //   "data.platform.internalToolsGenericMutation.isSuccess"
      // );
      rowsCount = _.get(data, "rowsAffected", null);

      if (rowsCount) {
        setTimeout(() => {
          dispatch(
            setToastMsg({ type: "success", msg: rowsCount, notString: true })
          );
        }, 2000);
      }
      if (_.isEmpty(warning)) {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:action_successfully",
            locale_params: getLocaleParams(input.params.portType),
          })
        );
      } else {
        console.error(warning);
        dispatch(setToastMsg({ msg: warning.message, notString: true }));
      }
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
    if (_.get(data, "failIds", null)) return data;
  };
};

export const validateInternalToolsModulePassword = params => {
  return async (dispatch, getState) => {
    dispatch(setIsLoading({ isLoadingPasswordState: true }));
    let isPasswordValid = false;
    try {
      const response = await client.mutate({
        mutation: validateInternalToolsModulePasswordMutation,
        variables: params,
      });
      isPasswordValid = _.get(
        response,
        "data.platform.validateInternalToolsModulePassword",
        false
      );
      if (!isPasswordValid) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:password_is_invalid",
            type: "alert",
            position: "toast-bottom-left",
          })
        );
      }
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg({ msg: e.message, notString: true }));
    } finally {
      dispatch(setIsLoading({ isLoadingPasswordState: false }));
      dispatch(
        setIsPasswordValid({ type: params.input.type, isPasswordValid })
      );
    }
    return isPasswordValid;
  };
};

export const createOrganization = orgDetails => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: createOrganizationV2Mutation,
        variables: orgDetails,
      });

      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:action_successfully",
          locale_params: [
            { key: "label", value: "School", isPlainText: true },
            { key: "action", value: "toastMsgs:created", isPlainText: false },
          ],
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      throw e;
    }
  };
};

export const updateOrganization = orgDetails => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateOrganizationMutation,
        variables: orgDetails,
        refetchQueries: [
          {
            query: getOrganizationFeedQuery,
            variables: {
              searchText: getState().tooling.searchString.organizations,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const updateCurriculumPrograms = ({
  curriculumOptions,
  id,
  examSessionMonth,
}) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: updateCurriculumProgramsMutation,
        variables: {
          id,
          curriculumOptions,
          examSessionMonth,
        },
      });

      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
    } catch (e) {
      dispatchError({ dispatch, e });
      throw e;
    }
  };
};

export const updateOrganizationPaidStatus = input => {
  return async (dispatch, getState) => {
    try {
      const {
        tab,
        toddleBuddy,
        plan: planType,
        onboardingFilter,
        transitionFilter,
      } = getState().tooling.organizationFilters;
      const searchText = getState().tooling.searchString.organizations;

      const filters = {
        searchText,
        feedType: tab,
        onboardingStatus:
          onboardingFilter && tab == "ONBOARDING"
            ? [onboardingFilter]
            : undefined,
        toddleBuddy: toddleBuddy || undefined,
        planType: planType || undefined,
        transitionStatus:
          transitionFilter && tab == "TRANSITION"
            ? transitionFilter
            : undefined,
      };

      await client.mutate({
        mutation: updateOrganizationPaidStatusMutation,
        variables: input,
        refetchQueries: [
          {
            query: getOrganizationFeedQuery,
            variables: {
              filters,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const updateOrganizationAcademicYear = academicYear => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: updateOrganizationAcademicYearMutation,
        variables: academicYear,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAcademicYears,
            variables: {
              id: academicYear.orgId,
            },
          },
        ],
      });

      const warning = _.get(
        response,
        "data.organizationManagement.manageOrganizationAcademicYear.warning"
      );
      const data = _.get(
        response,
        "data.organizationManagement.manageOrganizationAcademicYear.response"
      );
      if (_.isEmpty(warning)) {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:saved_successfully",
          })
        );
      } else {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:academic_years_clash",
          })
        );
      }
      return data;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const createOrganizationAcademicYear = academicYear => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: createOrganizationAcademicYearMutation,
        variables: academicYear,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getAcademicYears,
            variables: {
              id: academicYear.id,
            },
          },
        ],
      });

      const warning = _.get(
        response,
        "data.organizationManagement.manageOrganizationAcademicYear.warning"
      );
      const data = _.get(
        response,
        "data.organizationManagement.manageOrganizationAcademicYear.response"
      );
      if (_.isEmpty(warning)) {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:saved_successfully",
          })
        );
      } else {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:academic_years_clash",
          })
        );
      }
      return data;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const getOrganizationData = presorg_id => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const portalType = _.get(state, "login.portalType", null);

      const userInfo = getUserInfo({ portalType });

      const accessToken = _.get(userInfo, "token", "");
      const response = await axios.post(
        getBackendServerUrl({
          token: accessToken,
          path: "/auth/tools/getAcademicYearPortData",
        }),
        { Authorization: `Bearer ${accessToken}`, org_id: presorg_id }
      );
      const data = _.get(response, "data.yearData", {});
      return data;
    } catch (error) {
      return error;
    }
  };
};

export const bulkUpload = ({ url, type, organizationId, inputs }) => {
  return async dispatch => {
    try {
      const response = await client.mutate({
        mutation: bulkUploadByFileMutation,
        variables: {
          url,
          type,
          organizationId,
          inputs,
        },
      });
      const bulkUploadFileData = _.get(
        response,
        "data.platform.bulkUploadByFile",
        undefined
      );

      const responseSuccessful = _.get(bulkUploadFileData, "successful", false);

      let returnObject = {
        type: "success",
        msg: "toastMsgs:successfully_with_label",
        locale_params: [
          { key: "label", value: "toastMsgs:added", isPlainText: false },
        ],
      };
      if (!responseSuccessful) {
        returnObject = "toastMsgs:couldnt_be_added";
      }

      dispatch(setToastMsg(returnObject));

      return bulkUploadFileData;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return {
        successful: false,
        errors: ["Something went wrong"],
      };
    }
  };
};

export const createOrganizationPreset = orgDetails => {
  return async (dispatch, getState) => {
    // let params = {};
    // params["createCourse"] = true;
    // let courseDetails = getState().createCourse.courseDetails;
    // let communModule = getState().adminCommon.selectedList;
    // let studentsToAdd = getState().adminCommon.selectedList.students.map(
    //   student => student.node.id
    // );
    // let collaboratorsAddWithId = getState().adminCommon.selectedList.teachers.map(
    //   teacher => teacher.node.id
    // );
    // dispatch(updateLoading(params));
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      await client.mutate({
        mutation: createOrganizationPresetMutation,
        variables: orgDetails,
        refetchQueries: [
          {
            query: getAllOrganizationQuery,
            variables: {
              searchText: getState().tooling.searchString.orgnizationManagement,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:school_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:created", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:create", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }

    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

export const updateOrganizationPreset = orgDetails => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      await client.mutate({
        mutation: updateOrganizationPresetMutation,
        variables: orgDetails,
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:school_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:updated", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:update", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

export const deleteOrganizationPreset = orgDetails => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      await client.mutate({
        mutation: deleteOrganizationPresetMutation,
        variables: orgDetails,
        refetchQueries: [
          {
            query: getAllOrganizationQuery,
            variables: {
              searchText: getState().tooling.searchString.orgnizationManagement,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:school_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:deleted", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:delete", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

export const approveOrganization = orgDetails => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      await client.mutate({
        mutation: approveOrganizationMutation,
        variables: orgDetails,
        refetchQueries: [
          {
            query: getOrganizationApprovalRequestFeed,
            variables: {
              searchText: getState().tooling.searchString.organizationApproval,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:school_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:approved", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:approve", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

export const deleteApproveSchoolRequest = orgDetails => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      await client.mutate({
        mutation: deleteApproveSchoolRequestMutation,
        variables: orgDetails,
        refetchQueries: [
          {
            query: getOrganizationApprovalRequestFeed,
            variables: {
              searchText: getState().tooling.searchString.organizationApproval,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:school_request_rejected",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:delete", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

//approveUserMutation
export const approveUser = userDetails => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      const userId = userDetails.id;
      const orgId = _.get(userDetails, "organizationPreset.id", "");
      await client.mutate({
        mutation: approveUserMutation,
        variables: { presetOrgId: orgId, unauthenticatedUserId: userId },
        refetchQueries: [
          {
            query: getUserApprovalRequestFeed,
            variables: {
              searchText: getState().tooling.searchString.organizationApproval,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:user_approved",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:approve", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

export const deleteApproveUser = userDetails => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: true }));
    try {
      await client.mutate({
        mutation: deleteApproveUserRequestMutation,
        variables: userDetails,
        refetchQueries: [
          {
            query: getUserApprovalRequestFeed,
            variables: {
              searchText: getState().tooling.searchString.organizationApproval,
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:user_rejected",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:delete", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
    dispatch(setIsLoadingState({ key: "orgnizationManagement", value: false }));
  };
};

// User Feed

export const removeCourseFromTeacher = teacherId => {
  return (dispatch, getState) => {
    const courseIds = getState().adminCommon.selectedList.courses.map(
      course => course.id
    );
    const organizationId = getState().login.userInfo.org_id;
    const searchText = getState().tooling.searchText;
    const filters = getState().tooling.filters;
    const { orderByDirection, orderBy } = _.get(
      getState().app_services,
      "orderByDirectionObj.adminTeacherFeed",
      {}
    );
    client
      .mutate({
        mutation: removeCoursesFromUserMutation,
        variables: {
          userId: teacherId,
          courseIds: courseIds,
        },
        refetchQueries: [
          {
            query: getTeacherFeedQuery,
            variables: {
              organizationId,
              filters: { searchText, ...filters },
              orderByDirection,
              orderBy,
            },
          },
        ],
      })
      .then(res => {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "successfully_removed_label_from_course",
            locale_params: [
              { key: "label", value: "toastMsgs:teachers", isPlainText: false },
            ],
          })
        );
        dispatch(initRemovedCourses());
      })
      .catch(err => {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

export const addCoursesToTeacher = teacherId => {
  return (dispatch, getState) => {
    const courseIds = getState().adminCommon.selectedList.courses.map(
      course => course.id
    );
    const organizationId = getState().login.userInfo.org_id;
    const searchText = getState().tooling.searchText;
    const filters = getState().tooling.filters;
    const { orderByDirection, orderBy } = _.get(
      getState().app_services,
      "orderByDirectionObj.adminTeacherFeed",
      {}
    );
    client
      .mutate({
        mutation: addCoursesToUserMutation,
        variables: {
          userId: teacherId,
          courseIds: courseIds,
          role: "collaborator",
        },
        refetchQueries: [
          {
            query: getTeacherFeedQuery,
            variables: {
              organizationId,
              filters: { ...filters, searchText },
              orderByDirection,
              orderBy,
            },
          },
        ],
      })
      .then(res => {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:added_courses_to_teacher",
          })
        );
      })
      .catch(err => {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      });
  };
};

export const onClickCreateTeacher = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const params = {};
      params["createTeacher"] = true;
      const organizationId = getState().login.userInfo.org_id;
      const teacherDetails = getState().tooling.teacherDetails;
      const courses = getState().adminCommon.selectedList.courses.map(
        student => student.id
      );

      const searchText = getState().tooling.searchText;
      const filters = getState().tooling.filters;
      const { orderByDirection, orderBy } = _.get(
        getState().app_services,
        "orderByDirectionObj.adminTeacherFeed",
        {}
      );
      dispatch(toggleActionLoading(true));
      client
        .mutate({
          mutation: addUserMutation,
          variables: {
            email: teacherDetails.email,
            userType: "staff",
            courses: courses,
            firstName: teacherDetails.firstName,
            lastName: teacherDetails.lastName,
            profileImage: teacherDetails.profileImage,
            designation: teacherDetails.designation,
            staffRelationMap: [],
          },
          refetchQueries: [
            {
              query: getTeacherFeedQuery,
              variables: {
                organizationId,
                filters: { ...filters, searchText },
                orderByDirection,
                orderBy,
              },
            },
          ],
        })
        .then(res => {
          params["createTeacher"] = false;
          dispatch(updateLoading(params));
          dispatch(toggleActionLoading(false));
          // dispatch(initTeacherObject());
          dispatch(
            setToastMsg({
              type: "success",
              msg: "toastMsgs:saved_successfully",
            })
          );
          resolve();
        })
        .catch(err => {
          // console.log(err);
          dispatch(toggleActionLoading(false));
          dispatch(setToastMsg("toastMsgs:something_went_wrong"));
          reject();
        });
    });
  };
};

export const updateTeacherDetails = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: editTeacherMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const updateStandardInfo = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: editStandardInfoMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const deleteUser = ({ teacherId, callback }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: deleteUserMutation,
        variables: {
          id: teacherId,
        },
      });
      await callback();
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:removed_staff_from_team",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const createOrganizationInvitedUser = ({ invitedUsers }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createOrganizationInvitedUserMutation,
        variables: {
          users: invitedUsers,
        },
      });

      return _.get(result, "data.platform.addUsers", {});
    } catch (e) {
      // console.log(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
    }
  };
};

export const deleteOrganization = params => {
  return async (dispatch, getState) => {
    try {
      const {
        tab,
        toddleBuddy,
        plan,
        onboardingFilter,
        transitionFilter,
      } = getState().tooling.organizationFilters;
      const searchText = getState().tooling.searchString.organizations;

      const filters = {
        searchText,
        feedType: tab,
        onboardingStatus:
          onboardingFilter && tab == "ONBOARDING"
            ? [onboardingFilter]
            : undefined,
        toddleBuddy: toddleBuddy || undefined,
        planType: plan || undefined,
        transitionStatus:
          transitionFilter && tab == "TRANSITION"
            ? transitionFilter
            : undefined,
      };

      await client.mutate({
        mutation: deleteOrganizationMutation,
        variables: params,
        refetchQueries: [
          {
            query: getOrganizationFeedQuery,
            variables: { filters },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:school_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:deleted", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:deleted", isPlainText: false },
          ],
        })
      );
      dispatch(
        setIsLoadingState({ key: "orgnizationManagement", value: false })
      );
      throw err;
    }
  };
};
export const deleteStandard = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: deleteStandardMutation,
        variables: params,
        refetchQueries: [
          {
            query: getStandardFeedQuery,
            variables: {
              filters: {
                searchText: getState().tooling.searchString.standards,
                subjects: getState().tooling.subjectFilter,
              },
            },
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:item_successfully_removed_from",
          locale_params: [
            { key: "fromItem", value: "Standard", isPlainText: true },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:failed_to_action",
          locale_params: [
            { key: "action", value: "toastMsgs:delete", isPlainText: false },
          ],
        })
      );
      // dispatch(
      //   setIsLoadingState({ key: "standards", value: false })
      // );
      throw err;
    }
  };
};
export const updateConfigurablePermissions = params => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateConfigurablePermissionMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:saved_successfully",
        })
      );
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

//This Function is used to check if academic years are valid
//It just checks if any academic years are over lapping with each other or not
export const checkAcademicYears = ({ academicYears }) => {
  const updatedAcademicYears = _.map(academicYears, academicYear => {
    return {
      startDate: moment(academicYear?.dateDurationValue?.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(academicYear?.dateDurationValue?.endDate).format(
        "YYYY-MM-DD"
      ),
    };
  });

  const sortedAcademicYears = _.sortBy(updatedAcademicYears, "startDate");

  //This is true if any Dates are over lapped
  const isOverlapped = _.some(
    sortedAcademicYears,
    (item, index) =>
      index > 0 &&
      moment(item.startDate).isBefore(sortedAcademicYears[index - 1]?.endDate)
  );

  const areDatedValid = !isOverlapped;
  return areDatedValid;
};

//This function is used to check if 3 academic years are created
// 1 Prev, 1 Current, 1 Next while creating the DP Org
export const checkDPAcademicYears = ({ academicYears }) => {
  if (_.size(academicYears) < 3) {
    return false;
  }

  const now = new Date();

  const currentYear = _.find(
    academicYears,
    academicYear =>
      moment(academicYear?.startDate).isBefore(now) &&
      moment(academicYear?.endDate).isAfter(now)
  );

  const prevYear = _.find(academicYears, academicYear =>
    moment(academicYear?.endDate).isBefore(currentYear?.startDate)
  );

  const nextYear = _.find(academicYears, academicYear =>
    moment(academicYear?.startDate).isAfter(currentYear?.endDate)
  );

  if (!currentYear || !prevYear || !nextYear) {
    return false;
  }

  return true;
};

export const getDefaultAcademicYears = () => {
  const currentAYStartDate = moment().format("YYYY-MM-DD");
  const currentAYEndDate = moment(currentAYStartDate)
    .add(1, "years")
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const prevAYEndDate = moment(currentAYStartDate)
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const prevAYStartDate = moment(prevAYEndDate)
    .subtract(1, "years")
    .add(1, "days")
    .format("YYYY-MM-DD");

  const nextAYStartDate = moment(currentAYEndDate)
    .add(1, "days")
    .format("YYYY-MM-DD");

  const nextAYEndDate = moment(nextAYStartDate)
    .add(1, "years")
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const academicYears = [
    {
      id: generateRandomId(),
      dateDurationValue: {
        startDate: prevAYStartDate,
        endDate: prevAYEndDate,
      },
    },
    {
      id: generateRandomId(),
      isCurrentAcademicYear: true,
      dateDurationValue: {
        startDate: currentAYStartDate,
        endDate: currentAYEndDate,
      },
    },
    {
      id: generateRandomId(),
      dateDurationValue: {
        startDate: nextAYStartDate,
        endDate: nextAYEndDate,
      },
    },
  ];

  return academicYears;
};

export const transformAcademicYears = ({ academicYears }) => {
  return _.map(
    academicYears,

    ({ id, isCurrentAcademicYear, startDate, endDate }) => {
      return {
        id,
        isCurrentAcademicYear,
        dateDurationValue: {
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        },
      };
    }
  );
};
export const getValidAdminsPayload = ({ admins }) => {
  return _.reduce(
    admins,
    (result, admin) => {
      const { firstName, lastName, email } = admin;
      const isAdminDataPresent =
        _.trim(firstName) || _.trim(lastName) || _.trim(email);
      if (isAdminDataPresent) {
        return [...result, { firstName, lastName, email }];
      }
      return result;
    },
    []
  );
};

export const fetchAllClassesOfOrg = params => {
  return async (dispatch, getState) => {
    try {
      const result = await client.query({
        query: fetchAllClassesOfOrgQuery,
        variables: params,
      });
      return _.get(result, "data.node.courses.edges", false);
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:invalid_org_id"));
    }
  };
};

export const setIsPasswordValid = dispatch => {
  return {
    type: SET_IS_PASSWORD_VAILD,
    data: dispatch,
  };
};
export const setIsLoading = dispatch => {
  return {
    type: SET_IS_LOADING,
    data: dispatch,
  };
};

const REDUCER_HANDLERS = {
  [CHANGE_ORGANIZATION_SEARCH_STRING]: (state, action) => {
    const params = action.payload;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        searchString: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [CHANGE_USER_SEARCH_STRING]: (state, action) => {
    const params = action.payload;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        searchString: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [CHANGE_STANDARD_SEARCH_STRING]: (state, action) => {
    const params = action.payload;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        searchString: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [SET_IS_LOADING_STATE]: (state, action) => {
    const key = action.payload.key;
    const value = action.payload.value;

    return update(state, {
      isLoading: { [key]: { $set: value } },
    });
  },
  //User Feed
  [TOGGLE_TEACHER_TABS]: (state, action) => {
    return update(state, { teacherTab: { $set: action.payload } });
  },
  [INIT_REMOVED_COURSES]: (state, action) => {
    return update(state, {
      removedCourses: { $set: initialState.removedCourses },
    });
  },

  [TOGGLE_REMOVED_COURSES]: (state, action) => {
    const data = action.payload;
    const operation = data.operation;
    const value = data.value;
    const itemIndex = data.index;
    if (operation == "add") {
      state = update(state, { removedCourses: { $push: [value] } });
    } else {
      state = update(state, { removedCourses: { $splice: [[itemIndex, 1]] } });
    }
    return state;
  },
  [CHANGE_SEARCH_TERM]: (state, action) => {
    return update(state, { searchText: { $set: action.payload } });
  },
  [INIT_SEARCH_TERM]: (state, action) => {
    return update(state, { searchText: { $set: initialState.searchText } });
  },
  [UPDATE_FILTERS]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        filters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },

  [UPDATE_SUBJECT_FILTERS]: (state, action) => {
    const { selectedSubjectIds } = action.data;
    return update(state, {
      ["subjectFilter"]: { $set: selectedSubjectIds },
    });
  },
  [UPDATE_TEACHER_OBJECT]: (state, action) => {
    const params = action.payload;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        teacherDetails: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [INIT_TEACHER_OBJECT]: (state, action) => {
    return update(state, {
      teacherDetails: { $set: initialState.teacherDetails },
    });
  },
  [UPDATE_TEACHER_STEP]: (state, action) => {
    return update(state, { teacherStep: { $set: action.payload } });
  },
  [TOGGLE_MANAGE_COURSE_ROUTE]: (state, action) => {
    return update(state, { manageCourseRoute: { $set: action.data } });
  },
  [TOGGLE_ACTION_LOADING]: (state, action) => {
    return update(state, {
      isActionLoading: { $set: action.data },
    });
  },
  [UPDATE_ORGANIZATION_FILTERS]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        organizationFilters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [SET_IS_PASSWORD_VAILD]: (state, action) => {
    const { isPasswordValid, type } = action.data;
    state = update(state, {
      ["passwordValidityStatus"]: {
        [type]: { $set: isPasswordValid },
      },
    });
    return state;
  },
  [SET_IS_LOADING]: (state, action) => {
    const { isLoadingPasswordState } = action.data;
    state = update(state, {
      ["isLoadingPasswordState"]: { $set: isLoadingPasswordState },
    });
    return state;
  },
  //
};

const initialState = {
  searchString: {
    orgnizationManagement: "",
    orgnizationApproval: "",
    userApproval: "",
    organizations: "",
    users: "",
    standards: "",
  },
  // searchString:"",
  organizationSearchString: "",
  isLoading: {
    orgnizationManagement: false,
  },
  subjectFilter: [], // subjects filter standard
  searchText: "",
  filters: {
    organizations: [],
  },
  organizationFilters: {
    tab: "ONBOARDED",
    toddleBuddy: "",
    plan: "",
    onboardingFilter: "",
    transitionFilter: "",
  },
  teacherDetails: {
    id: null,
    firstName: "",
    profileImage: null,
    lastName: "",
    email: "",
  },
  teacherTab: "classes",
  removedCourses: [],
  manageCourseRoute: "courseList",
  teacherStep: 2,
  isActionLoading: false,
  passwordValidityStatus: {
    SCHOOL_CONTRACT_PASSWORD: false,
    TEMPLATE_DEPLOYMENT_AUTOMATION_TOOL_PASSWORD: false,
  },
  isLoadingPasswordState: false,
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
