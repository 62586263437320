import React from "react";
import { compose } from "redux";
import {
  TooltipBody,
  Button,
  TooltipContent,
  TooltipTitle,
  TooltipFooter,
  TextButton,
  TooltipFooterButtonContainer,
  ToolTipCountText,
  Beacon,
  ArrowButtonLeft,
  ArrowButtonRight,
} from "./CoachMarkStyles";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import {
  handleCoachMarkCallback,
  initCoachMarks,
} from "modules/CoachMarksModule";
import { CoachmarkChevronIcon } from "SvgComponents";
import { I18nHOC } from "UIComponents";
import { STATUS, EVENTS, ACTIONS, LIFECYCLE } from "react-joyride";
import PropTypes from "prop-types";

const Tooltip = ({
  continuous,
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  size,
  startCountIndex = 1,
  t,
}) => {
  const {
    title,
    content,
    showSkipButton,
    nextButtonText,
    skipButtonText,
    nextButtonSize = "sm",
    tootTipWidth = "36vw",
    theme,
    showNextButton = true,
  } = step;
  //console.log(tooltipProps, size);
  const currentCount = index - startCountIndex + 1;
  const totalCount = size - startCountIndex;
  const {
    containerStyle,
    titleStyle,
    contentStyle,
    buttonContainerStyle,
    nextButtonStyle,
    skipButtonStyle,
  } = theme || {};

  return (
    <TooltipBody
      {...tooltipProps}
      tootTipWidth={tootTipWidth}
      style={containerStyle}
    >
      {title && <TooltipTitle style={titleStyle}>{title}</TooltipTitle>}
      {content && (
        <TooltipContent style={contentStyle}>{content}</TooltipContent>
      )}
      <TooltipFooter>
        <ToolTipCountText>
          {currentCount > 1 && (
            <ArrowButtonLeft {...backProps} rotation={"180deg"}>
              <CoachmarkChevronIcon height={8} width={5} />
            </ArrowButtonLeft>
          )}
          {continuous && index >= startCountIndex
            ? `${currentCount} / ${totalCount}`
            : ``}
          {currentCount >= 1 && currentCount < totalCount && (
            <ArrowButtonRight {...primaryProps} rotation={"0deg"}>
              <CoachmarkChevronIcon height={8} width={5} />
            </ArrowButtonRight>
          )}
        </ToolTipCountText>
        <TooltipFooterButtonContainer style={buttonContainerStyle}>
          {((showSkipButton && !isLastStep) || skipButtonText) && (
            <TextButton {...skipProps} style={skipButtonStyle}>
              {skipButtonText ? skipButtonText : t("common:skip")}
            </TextButton>
          )}
          {showNextButton ? (
            <Button
              {...primaryProps}
              size={nextButtonSize}
              style={nextButtonStyle}
            >
              {nextButtonText
                ? nextButtonText
                : !isLastStep
                ? t("common:next")
                : t("common:got_it")}
            </Button>
          ) : null}
        </TooltipFooterButtonContainer>
      </TooltipFooter>
    </TooltipBody>
  );
};
const typesOfCoachMarksForTimeOut = [
  "COMMUNITY_PROFILE_UPDATE",
  "COMMUNITY_VIEW_BOOKMARKS",
  "COMMUNITY_CONTRIBUTE_BUTTON",
  "COMMUNITY_PROFILE_VIEW",
  "COMMUNITY_IMPORT_LE",
  "COMMUNITY_LE_BOOKMARK_BUTTON",
  "COMMUNITY_UNIT_BOOKMARK_BUTTON",
  "COMMUNITY_SHARE_BUTTON",
];

class CoachMarks extends React.PureComponent {
  constructor(props) {
    super(props);

    this.scrollEvent = null;
    this.isScrolling = false;
    if (props.customRef) {
      props.customRef(this);
    }
  }
  componentDidMount = async () => {
    const {
      initCoachMarks,
      type,
      params,
      onStepChanged,
      continuous = true,
      startAfterTime,
      onCoachmarksInit,
      portalType,
    } = this.props;

    await initCoachMarks({
      type,
      params,
      onStepChanged,
      continuous,
      startAfterTime,
      onCoachmarksInit,
      portalType,
    });

    // this is to close the specific types of coach marks after 10 seconds automatically.
    if (typesOfCoachMarksForTimeOut.includes(type)) {
      setTimeout(() => {
        const { coachMarksObj: { steps } = {} } = this.props;
        this.handleJoyrideCallback({
          lifecycle: LIFECYCLE.COMPLETE,
          action: ACTIONS.CLOSE,
          size: _.get(steps, "length", 0),
          status: STATUS.SKIPPED,
        });
      }, 10000);
    }
  };

  componentWillUnmount = () => {
    this.removeScrollEventListener();
  };

  addScrollEventListener = () => {
    const { continuous } = this.props;
    if (!continuous) {
      window.addEventListener("scroll", this.handleScroll, true);
    }
  };

  addDocumentClickListener = () => {
    const { isTransparentOverlay } = this.props;
    if (isTransparentOverlay) {
      document.addEventListener("click", this.handleDocumentMouseClick, false);
    }
  };

  removeDocumentClickListener = () => {
    const { isTransparentOverlay } = this.props;
    if (isTransparentOverlay) {
      document.removeEventListener(
        "click",
        this.handleDocumentMouseClick,
        false
      );
    }
  };

  handleDocumentMouseClick = event => {
    if (this.toolTipRef && this.toolTipRef.contains(event.target)) {
      return;
    }
    const { coachMarksObj: { steps } = {} } = this.props;
    this.handleJoyrideCallback({
      lifecycle: LIFECYCLE.COMPLETE,
      action: ACTIONS.CLOSE,
      size: _.get(steps, "length", 0),
      status: STATUS.SKIPPED,
    });
  };

  addListeners = () => {
    this.addScrollEventListener();
    this.addDocumentClickListener();
  };

  removeListeners = () => {
    this.removeDocumentClickListener();
    this.removeScrollEventListener();
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { coachMarksObj: { run } = {} } = this.props;
    const { coachMarksObj: { run: nextRun } = {} } = nextProps;
    if (run && !nextRun) {
      this.removeListeners();
    } else if (!run && nextRun) {
      this.addListeners();
    }
  };

  removeScrollEventListener = () => {
    const { continuous } = this.props;
    if (!continuous) {
      window.removeEventListener("scroll", this.handleScroll, true);
    }
  };

  handleScroll = e => {
    const {
      coachMarksObj: { run, steps, disableOnScroll } = {},
      continuous,
    } = this.props;

    if (!run || continuous || disableOnScroll) {
      return;
    }

    const targetElement = document.querySelector(_.get(steps[0], "target", ""));

    if (!targetElement || !e.target || !e.target.contains?.(targetElement)) {
      return;
    }

    if (!this.isScrolling) {
      this.isScrolling = true;
      this.changeBeaconVisibilty(false);
    } else {
      if (this.scrollEvent) {
        window.clearTimeout(this.scrollEvent);
      }
      // Set a timeout to run after scrolling ends
      this.scrollEvent = setTimeout(() => {
        // Run the callback
        // console.log("SCROLL STOP", this.isScrolling);
        this.isScrolling = false;
        //console.log(targetElement.getBoundingClientRect().top);
        if (targetElement && targetElement.getBoundingClientRect().top < 64) {
          this.changeBeaconVisibilty(false);
        } else {
          this.changeBeaconVisibilty(true);
        }
      }, 100);
    }
  };

  changeBeaconVisibilty = isVisible => {
    // console.log(this.beaconRef);
    if (this.beaconRef) {
      // console.log(this.beaconRef.style);
      this.beaconRef.style.visibility = isVisible ? "visible" : "hidden";
    }
  };
  getHelpers = helpers => {
    this.helpers = helpers;
  };

  handleJoyrideCallback = data => {
    const {
      handleCoachMarkCallback,
      type,
      coachMarksObj: { coachMarkId } = {},
      portalType,
      continuous,
    } = this.props;

    handleCoachMarkCallback({
      type,
      data,
      coachMarkId,
      portalType,
      continuous,
    });
  };

  updateBeaconRef = ref => {
    this.beaconRef = ref;
  };

  isRunning = () => {
    const { run } = this.props.coachMarksObj || {};
    return run;
  };

  updateToolTipRef = ref => {
    this.toolTipRef = ref;
  };

  render() {
    const {
      coachMarksObj,
      continuous = true,
      type,
      disableOverlay,
      spotlightPadding,
      disableScrolling = true,
      t,
      isTransparentOverlay,
      onClickBeacon,
      disableOverlayClose,
      disableScrollParentFix = true,
    } = this.props;
    const {
      steps,
      stepIndex,
      run,
      beaconStyles,
      startCountIndex,
      zIndex,
      wrapperZIndex,
    } = coachMarksObj || {};

    const localizedStep = _.map(steps, step => {
      if (step.hasOwnProperty("nextButtonText")) {
        return {
          ...step,
          title: step.title({ t }),
          content: step.content({ t }),
          nextButtonText: step.nextButtonText({ t }),
        };
      } else {
        return {
          ...step,
          title: step.title({ t }),
          content: step.content({ t }),
        };
      }
    });

    return run ? (
      <Joyride
        key={type}
        callback={this.handleJoyrideCallback}
        continuous={continuous}
        getHelpers={this.getHelpers}
        run={run}
        //scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={localizedStep || []}
        stepIndex={stepIndex}
        tooltipComponent={props => (
          <Tooltip
            {...props}
            startCountIndex={startCountIndex}
            t={t}
            ref={this.updateToolTipRef}
          />
        )}
        disableOverlay={disableOverlay || false}
        floaterProps={{
          disableAnimation: true,
          styles: {
            // floaterWithAnimation: {
            //   opacity: 1,
            //   transition: 'opacity 0',
            //   visibility: 'visible',
            // },
            arrow: {
              margin: "32px",
              length: 16,
              spread: 22,
            },
            wrapper: {
              cursor: "pointer",
              zIndex: wrapperZIndex ? wrapperZIndex : continuous ? 1002 : 1001,
            },
          },
        }}
        beaconComponent={props => (
          <Beacon
            {...props}
            updateRef={this.updateBeaconRef}
            onClick={e => {
              if (onClickBeacon) onClickBeacon();
              props.onClick(e);
            }}
            style={beaconStyles}
          />
        )}
        disableOverlayClose={!continuous && disableOverlayClose}
        disableScrollParentFix={disableScrollParentFix}
        disableScrolling={disableScrolling}
        styles={{
          // spotlight: {
          //   // background: 'red'
          //   transition: 'opacity 2s ease 0s'
          // },
          overlay: isTransparentOverlay
            ? { backgroundColor: "transparent" }
            : {},
          options: {
            zIndex: zIndex ? zIndex : continuous ? 1003 : 1002,
          },
        }}
        spotlightPadding={spotlightPadding || 4}
      />
    ) : null;
  }
}

const mapActionCreators = { handleCoachMarkCallback, initCoachMarks };

const mapStateToProps = (state, ownProps) => {
  const isCommunity = state.login.activeTab == "community";
  const { portalType } = ownProps || {};
  return {
    coachMarksObj: _.get(state.coachMarks.coachMarksObj, ownProps.type, {}),
    portalType: portalType ? portalType : isCommunity ? "COMMUNITY" : "PLANNER",
  };
};

export default compose(
  I18nHOC({ resource: ["common", "blockScreen", "snp"] }),
  connect(mapStateToProps, mapActionCreators)
)(CoachMarks);

CoachMarks.propTypes = {
  disableOverlayClose: PropTypes.bool,
};

CoachMarks.defaultProps = {
  disableOverlayClose: true,
};
