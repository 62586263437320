import { UIBaseComponent, I18nHOC } from "UIComponents";
import React from "react";
import classes from "./SubjectGroupWithSubjectView.scss";
import PropTypes from "prop-types";
import {
  ArtSGIcon,
  DesignSGIcon,
  IndiAndSocSGIcon,
  LangAcquisitionSGIcon,
  LangAndLitSGIcon,
  MathematicsSGIcon,
  PAndHEduSGIcon,
  ScienceSGIcon,
} from "SvgComponents";

const iconSize = 24;

const ICON_SUBJECT_GROUP_MAPPING = [
  {
    iconKey: "ARTS",
    icon: <ArtSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "L&L",
    icon: <LangAndLitSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "LA",
    icon: <LangAcquisitionSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "DESIGN",
    icon: <DesignSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "SCI",
    icon: <ScienceSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "MATH",
    icon: <MathematicsSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "PHE",
    icon: <PAndHEduSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "I&S",
    icon: <IndiAndSocSGIcon width={iconSize} height={iconSize} />,
  },
  {
    iconKey: "COMMON",
    icon: <IndiAndSocSGIcon width={iconSize} height={iconSize} />,
  },
];

class SubjectGroupWithSubjectView extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  renderEdit = () => {
    const { value, t } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.containerWithLabelAndIcon}>
          <div className={"heading-6"}>{t("common:subject_group")}</div>
          <div className={classes.boxContainer}>
            {_.get(
              _.find(ICON_SUBJECT_GROUP_MAPPING, {
                iconKey: _.get(
                  value,
                  "subjectGroup.constants.iconKey",
                  "COMMON"
                ),
              }),
              "icon",
              ""
            )}

            <div className={classes.textContainer}>
              {_.get(value, "subjectGroup.name", "")}
            </div>
          </div>
        </div>
        <div className={classes.containerWithLabelAndIcon}>
          <div className={"heading-6"}>{t("common:subject")}</div>
          <div className={classes.boxContainer}>
            <img
              src={_.get(value, "subject.icon", "")}
              className={classes.iconContainer}
            />

            <div className={classes.textContainer}>
              {_.get(value, "subject.name", "")}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

SubjectGroupWithSubjectView.defaultProps = {
  ...UIBaseComponent.defaultProps,
  value: {},
};

SubjectGroupWithSubjectView.propTypes = {
  ...UIBaseComponent.propTypes,
  value: PropTypes.object,
};

export default I18nHOC({ resource: ["common"] })(SubjectGroupWithSubjectView);
