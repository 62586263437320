/* DOCUMENTATION :
   Props used :
    label -> It is the text which will be displayed inside the button
    type -> To state whether you want a filled or hollow button
    button_state ->To state whether button is normal or disabled
    color->To state the color , out of grey,pink,yellow,blue
    size-> It will decide the size of your box.It has default value small
*/
import React from "react";
import classNames from "classnames";
import classes from "./UIButton.scss";
import PropTypes from "prop-types";
import { colors } from "Constants";

const COLOR_MAPPING = {
  grey: {
    color: colors.gray48,
    textColor: colors.gray48,
    hoverColor: colors.gray31,
    clickColor: colors.gray31,
    border: "2px solid",
  },
  tertiary: {
    color: colors.strokeTwo,
    hoverColor: colors.salmon60,
    textColor: colors.gray48,
    clickColor: "#e35259",
    border: "1px solid",
  },
  pink: {
    color: colors.salmon60,
    textColor: colors.salmon60,
    hoverColor: colors.salmon60,
    clickColor: "#e35259",
    border: "1px solid",
  },
  blue: {
    color: colors.blue29,
    textColor: colors.blue29,
    hoverColor: colors.blue29,
    clickColor: "#248a94",
  },
  orchid: {
    color: colors.violet63,
    textColor: colors.violet63,
    hoverColor: colors.violet63,
    clickColor: "#7a7aef",
  },
  cannonPink: {
    color: colors.pink39,
    textColor: colors.pink39,
    hoverColor: colors.pink39,
    clickColor: "#bf628e",
  },
  isDisabled: {
    backgroundColor: colors.gray72,
    color: colors.white,
  },
  white: {
    color: colors.white,
    textColor: colors.white,
    hoverColor: colors.white,
    clickColor: colors.white,
    hoverTextColor: colors.gray13,
    border: `1px solid`,
  },
  greyTwo: {
    color: colors.strokeOne,
    textColor: colors.gray48,
    hoverColor: colors.blue29,
    clickColor: colors.blue29,
    border: "2px solid",
  },
  greyThree: {
    color: colors.strokeOne,
    textColor: colors.gray48,
    hoverColor: colors.toddleBG1,
    hoverTextColor: colors.gray48,
    clickColor: colors.toddleBG1,
    border: "2px solid",
  },
  transparent: {
    color: "transparent",
    textColor: colors.gray13,
    hoverColor: "transparent",
    hoverTextColor: colors.gray13,
    clickColor: "transparent",
    border: "none",
  },
};

const COLOR = [
  "grey",
  "tertiary",
  "pink",
  "blue",
  "white",
  "greyTwo",
  "greyThree",
  "transparent",
];
//outline chrome  text-steel hover-pink
const SIZES = ["lg", "sm"];
const BUTTON_STATE = ["disabled", "normal"];
const BUTTON_TYPE = ["filled", "hollow"];

const getDisabledStyle = ({ type, isDisabled }) => {
  const style = {};
  if (isDisabled) {
    const isDisabledMap = COLOR_MAPPING.isDisabled;
    style.borderColor = isDisabledMap.backgroundColor;
    style.color = isDisabledMap.color;
    style.cursor = "not-allowed";
    if (type === "filled") {
      style.backgroundColor = isDisabledMap.backgroundColor;
    } else {
      style.color = colors.gray72;
      style.fill = colors.gray72;
      style.backgroundColor = colors.white;
    }
  }
  return style;
};

const getNormalStyle = ({ color, type, isDisabled }) => {
  const style = {};
  const colorVal = COLOR_MAPPING[color].color;
  const textColor = COLOR_MAPPING[color].textColor;
  style.borderColor = colorVal;
  switch (type) {
    case "filled":
      style.backgroundColor = colorVal;
      style.color = "white";
      style.fill = "white";
      break;

    case "hollow":
      style.background = "none";
      style.color = textColor;
      style.fill = textColor;
      break;
  }
  return style;
};

const getHoverStyle = ({ color, type, hover, isDisabled, disableHover }) => {
  const style = {};
  if (isDisabled && disableHover) return;
  if (hover) {
    const hoverColorVal = COLOR_MAPPING[color].hoverColor;
    const clickColor = COLOR_MAPPING[color].clickColor;
    let textColor = "white";
    let fillColor = "white";
    switch (type) {
      case "filled":
        style.borderColor = clickColor;
        style.backgroundColor = clickColor;

        break;
      case "hollow":
        textColor = COLOR_MAPPING[color].hoverTextColor || textColor;
        fillColor = COLOR_MAPPING[color].hoverTextColor || fillColor;
        style.borderColor = hoverColorVal;
        style.background = hoverColorVal;

        break;
    }
    style.color = textColor;
    style.fill = fillColor;
  }
  return style;
};

const getActiveStyle = ({ color, active, isDisabled }) => {
  const style = {};
  if (active) {
    const clickColor = COLOR_MAPPING[color].clickColor;
    style.borderColor = clickColor;
    if (!isDisabled) style.backgroundColor = clickColor;
    style.color = COLOR_MAPPING[color].hoverTextColor || "white";
    style.fill = COLOR_MAPPING[color].hoverTextColor || "white";
  }

  return style;
};

const getStyle = ({
  containerStyle,
  color,
  type,
  hover,
  active,
  isDisabled,
  disableHover,
}) => {
  return {
    ...getNormalStyle({ color, type, isDisabled }),
    ...getHoverStyle({ color, type, hover, isDisabled, disableHover }),
    ...getActiveStyle({ color, active, isDisabled }),
    ...getDisabledStyle({ type, isDisabled }),
    ...containerStyle,
  };
};

class UIButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hover: false, active: false };
  }

  handleClick(event) {
    const { isDisabled, onClick } = this.props;

    if (isDisabled) {
      event.stopPropagation();
      return;
    }

    if (onClick) {
      onClick(event);
    }
  }

  toggleHover = val => {
    if (this.state.hover != val) {
      this.setState({ hover: val });
    }
    if (!val) {
      this.toggleActive(false);
    }
  };

  toggleActive = val => {
    if (this.state.active != val) {
      this.setState({ active: val });
    }
  };

  render = () => {
    const {
      children,
      size,
      isDisabled,
      type,
      color,
      labelStyle,
      containerStyle,
      id,
      className,
      disableHover,
    } = this.props;

    const { hover, active } = this.state;

    const boxclass = classNames({
      [classes[size]]: !!size,
      [className]: !!className,
    });
    const labelclass = classes[size + "Label"];
    const isText = typeof children == "string";

    return (
      <div
        data-test-id={this.props["data-test-id"]}
        id={id}
        className={boxclass}
        style={getStyle({
          containerStyle,
          color,
          type,
          hover,
          active,
          isDisabled,
          disableHover,
        })}
        onClick={e => this.handleClick(e)}
        onMouseEnter={() => this.toggleHover(true)}
        onMouseLeave={() => this.toggleHover(false)}
        onMouseOver={() => this.toggleHover(true)}
        onMouseOut={() => this.toggleHover(false)}
        onMouseDown={() => this.toggleActive(true)}
        onMouseUp={() => this.toggleActive(false)}
      >
        {isText ? (
          <div style={labelStyle} className={labelclass}>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    );
  };
}

UIButton.propTypes = {
  color: PropTypes.oneOf(COLOR),
  size: PropTypes.oneOf(SIZES),
  type: PropTypes.oneOf(BUTTON_TYPE),
  className: PropTypes.string,
  button_state: PropTypes.oneOf(BUTTON_STATE),
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  labelStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  disableHover: PropTypes.bool,
};

UIButton.defaultProps = {
  color: "grey",
  size: "lg",
  type: "filled",
  isActive: true,
  className: "",
  isDisabled: false,
  disableHover: false,
};

export default UIButton;
