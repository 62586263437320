import gql from "graphql-tag";
import {
  assessmentFragment,
  entityRatingsNodeFragment,
  unitPlanDetailFragment,
  learningExperienceDetailFragment,
  organizationMembersFragment,
  likeConnectionfragment,
  resourceRequestFragment,
} from "Community/modules/CommunityFragments";
import {
  staffFragment,
  userIdentityDetailFragment,
  notificationFragment,
  curriculumProgramFragment,
} from "modules/CommonFragments";

export const getAssessmentQuery = gql`
  query getAssessment($id: ID!) {
    node(id: $id, type: ASSESSMENT) {
      id
      ... on Assessment {
        ...assessmentItem
      }
    }
  }
  ${assessmentFragment.assessmentDetails}
`;

export const getRatingConstantsQuery = gql`
  query getRatings($entityTypes: [COMMUNITY_ENTITY_TYPES_ENUM!]) {
    community {
      constants {
        ratings(entityTypes: $entityTypes) {
          id
          label
          icon
          isGlobal
          color
        }
      }
    }
  }
`;

export const getEntityRatingQuery = gql`
  query getEntityRatings($id: ID!, $entityType: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $entityType) {
      id
      ... on UnitPlan {
        title {
          id
          value
        }
        ...unitRatingsNodeItem
      }
      ... on Assessment {
        title {
          id
          value
        }
        ...assessmentRatingsNodeItem
      }
    }
  }
  ${entityRatingsNodeFragment.unitPlan}
  ${entityRatingsNodeFragment.assessment}
`;

export const getUnitDetailsQuery = gql`
  query getUnitDetails($id: ID!) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        ...unitPlanDetails
      }
    }
  }
  ${unitPlanDetailFragment.unitPlan}
`;

export const getLEDetailsQuery = gql`
  query getLEDetails($id: ID!) {
    node(id: $id, type: ASSESSMENT) {
      id
      ... on Assessment {
        ...leDetails
      }
    }
  }
  ${learningExperienceDetailFragment.le}
`;

export const getUserCommunityStatusQuery = gql`
  query userCommunityStatus($id: ID!) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        id
        communityDetails {
          id
          onboardingStatus
        }
      }
    }
  }
`;

export const getOrganizationMembersQuery = gql`
  query getSchool($id: ID!, $filters: OrgConnectionFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationMembers
      }
    }
  }
  ${organizationMembersFragment.members}
`;

export const getCommunityUserDetailsQuery = gql`
  query getCommunityUserDetailsQuery($id: ID!) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        ...staffItem
        ...staffCoachMarksItem
        communityDetails {
          id
          aboutMe
        }
        experience: schoolTenures {
          id
        }
        schoolTenures(currentSchool: true) {
          id
          school {
            id
            name
          }
        }
        certificates: communityActivities {
          id
        }
        firstLoggedInAt
        jwt
        organization {
          id
          name
          curriculumPrograms {
            ...subscriptionPlanDetailsItem
          }
        }
        userIdentityDetails {
          ...userIdentityDetailItem
        }
      }
    }
  }
  ${curriculumProgramFragment.subscriptionPlanDetails}
  ${staffFragment.basicStaffDetails}
  ${userIdentityDetailFragment.userIdentityDetail}
  ${staffFragment.staffCoachMarks}
`;

export const getCommunityOrganizationGradesQuery = gql`
  query getOrganizationGrades($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      ... on Organization {
        id
        grades {
          id
          name
          globalGrade {
            id
            name
            constants
            displaySequence
          }
        }
      }
    }
  }
`;

export const getEntityLikesQuery = gql`
  query getEntityLikes(
    $id: ID!
    $entityType: ENTITY_TYPE_ENUM!
    $after: String
  ) {
    node(id: $id, type: $entityType) {
      ... on UnitPlan {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        likes {
          ...likeUserConnection
        }
      }
      ... on Assessment {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        likes {
          ...likeUserConnection
        }
      }
    }
  }
  ${likeConnectionfragment.likeUserConnection}
`;

export const getEntityBasicInfoQuery = gql`
  query getEntityBasicInfo($id: ID!, $entityType: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $entityType) {
      id
      ... on UnitPlan {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        createdBy {
          id
          firstName
          lastName
        }
        organization {
          id
          name
        }
        copiedUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "copied_url"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
        facebookShareUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "facebook"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
        twitterShareUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "twitter"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
        linkedinShareUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "linkedin"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
      }
      ... on Assessment {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        createdBy {
          id
          firstName
          lastName
        }
        copiedUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "copied_url"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
        facebookShareUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "facebook"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
        twitterShareUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "twitter"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
        linkedinShareUrl: shareableUrl(
          shortUrl: true
          query: {
            utm_source: "linkedin"
            utm_medium: "social_media"
            utm_campaign: "public_sharing"
          }
        )
      }
    }
  }
`;

export const getUsersNotOnCommunityQuery = gql`
  query getEntityBasicInfo($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        staff(filters: { joinedCommunity: false }) {
          edges {
            node {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const getResourceRequestQuery = gql`
  query getEntityBasicInfo($types: [COMMUNITY_ENTITY_TYPES_ENUM!]) {
    community {
      survey {
        contentKeywords(types: $types) {
          ...resourceRequestItem
        }
      }
    }
  }
  ${resourceRequestFragment.resourceRequest}
`;
