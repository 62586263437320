// ---------------------------------------------- START: academic year curriculum mapping ----------------------------------------------
const CURRICULUM_ORDER = {
  IB_PYP: 0,
  IB_MYP: 1,
  UBD: 2,
  IB_DP: 3,
};

export const getSortedCurriculums = ({ curriculumPrograms }) => {
  return [...(curriculumPrograms || [])].sort((a, b) => {
    if (CURRICULUM_ORDER[a.type] != CURRICULUM_ORDER[b.type]) {
      return CURRICULUM_ORDER[a.type] - CURRICULUM_ORDER[b.type];
    } else {
      return Number(a.id) - Number(b.id);
    }
  });
};

const getSortedMapping = ({ mapping }) => {
  const sortedMapping = {};

  const academicYearIds = _.keys(mapping);

  _.forEach(academicYearIds, academicYearId => {
    const curriculumPrograms = _.get(
      mapping,
      [academicYearId, "curriculumPrograms"],
      []
    );

    sortedMapping[academicYearId] = {
      ...mapping[academicYearId],
      // sort the curriculumPrograms array
      curriculumPrograms: getSortedCurriculums({ curriculumPrograms }),
    };
  });

  return sortedMapping;
};

const getUserAcadYearConstants = ({
  allCourses,
  orgAcademicYears,
  organizationCurriculumPrograms,
}) => {
  const mapping = {};
  const currentOrgAcademicYearId = _.get(
    _.find(orgAcademicYears, {
      isCurrentAcademicYear: true,
    }),
    "id"
  );

  // loop over allCourses and create a mapping object
  _.forEach(allCourses, course => {
    const academicYears = _.get(course, "academicYears");
    const length = _.size(academicYears);

    // One course can be inside multiple academic years, hence loop over all academic years
    for (let i = 0; i < length; i++) {
      // get academic year id
      const academicYearId = _.get(academicYears, [i, "id"]);

      const courseCurriculum = _.get(course, "curriculumProgram", {});

      const hasAcademicYearIdKey = mapping[academicYearId];
      if (!_.isEmpty(courseCurriculum)) {
        if (!hasAcademicYearIdKey) {
          // if academic year object does not exist, then create it
          mapping[academicYearId] = {
            curriculumPrograms: [],
            isCurrentAcademicYear: null,
          };
        }

        mapping[academicYearId].curriculumPrograms = _.union(
          _.get(mapping, [academicYearId, "curriculumPrograms"]),
          [courseCurriculum]
        );

        mapping[academicYearId].isCurrentAcademicYear =
          academicYearId === currentOrgAcademicYearId;
      }
    }
  });

  const academicYearIds = _.keys(mapping);

  _.forEach(academicYearIds, academicYearId => {
    const { curriculumPrograms } = mapping[academicYearId];
    mapping[academicYearId].curriculumPrograms = _.map(
      curriculumPrograms,
      ({ id: curriculumProgramId, ...rest }) => {
        const subscriptionPlan = _.get(
          _.find(
            organizationCurriculumPrograms,
            ({ id }) => id == curriculumProgramId
          ),
          "subscriptionPlan",
          {}
        );
        return { id: curriculumProgramId, ...rest, subscriptionPlan };
      }
    );
  });

  const academicYearCurriculumProgramsMapping = getSortedMapping({ mapping });

  return {
    academicYearCurriculumProgramsMapping,
    currentAcadYearObj:
      academicYearCurriculumProgramsMapping[currentOrgAcademicYearId] || {},
  };
};

export const getUserAcadYearConstantsMemoize = _.memoize(
  params => getUserAcadYearConstants(params),
  params => JSON.stringify(params)
);
// ---------------------------------------------- END: academic year curriculum mapping ----------------------------------------------
