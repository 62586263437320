import { colors } from "Constants";

export const checkboxItemStyle = {
  width: "24px",
  height: "24px",
  border: `1px solid ${colors.strokeTwo}`,
  borderRadius: "4px",
};

export const listItemStyle = {
  flexBasis: "unset",
};

export const checkBoxLabelStyles = {
  fontSize: "1.4rem",
  lineHeight: "2rem",
  position: "relative",
  marginRight: "16px",
  color: colors.gray13,
};
