import React, { useRef } from "react";
import classes from "./ForgotPhonePassword.scss";
import { PhoneInputField, UIButton } from "UIComponents";

const styles = {
  editContainerStyle: {
    display: "block",
  },
};

const ForgotPhonePassword = props => {
  const {
    renderBackButtonComponent,
    updateCurrentScreen,
    customStyle: {
      containerStyle,
      headerStyle,
      subTextStyle,
      buttonStyle,
    } = {},
    data: { phoneNo } = {},
    updateInputField,
    inputStyle,
    renderErrorComponent,
  } = props;

  const phoneRef = useRef();

  const onBackClick = () => {
    updateCurrentScreen({ type: "parentLoginForm" });
    updateInputField({});
  };

  const onSendClick = () => {
    const { showErrorToast, generateOTPForParent } = props;
    const errorCount = isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    generateOTPForParent({ isPhone: true });
  };

  const isValid = () => {
    const errorCount = phoneRef.current.isValid();
    return errorCount;
  };

  const checkPhoneVerification = value => {
    if (value.length < 6) {
      return "Please enter a valid phone number";
    } else {
      return "";
    }
  };

  return (
    <div className={classes.container} style={containerStyle}>
      {renderBackButtonComponent({ onClick: onBackClick })}
      <div style={headerStyle}>{`Forgot Password?`}</div>
      <div className={classes.headerSubText}>{`Let’s get you a new one`}</div>
      <div style={subTextStyle}>
        {`Please enter your phone number below and we’ll send a One-time password(OTP) to this phone number.`}
      </div>

      <div className={classes.innerContainer}>
        <div className={classes.inputsContainer}>
          <div className={classes.inputContainer}>
            <PhoneInputField
              label={"Phone number"}
              value={phoneNo}
              placeholder={"Enter your phone number"}
              name={"phoneNo"}
              updateInputField={updateInputField}
              ref={phoneRef}
              showErrorText={true}
              renderErrorComponent={renderErrorComponent}
              error={"Enter phone number"}
              labelContainerStyle={inputStyle.labelContainerStyle}
              labelStyle={inputStyle.labelStyle}
              customValidation={checkPhoneVerification}
              editContainerStyle={styles.editContainerStyle}
            />
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <UIButton
            type="filled"
            color="pink"
            onClick={onSendClick}
            containerStyle={buttonStyle}
          >
            {"Send OTP"}
          </UIButton>
        </div>
      </div>
    </div>
  );
};

export default ForgotPhonePassword;
