import client from "apolloClient";
import {
  getCommunityCommonFeedQuery,
  getCommunityGroupFeedQuery,
  getCommunityCombineFeedQuery,
  getCollectionQuery,
} from "./CommunityParentEntityQuery";

export const getCommunityCommonFeedFromCache = ({
  id,
  entityTypes,
  searchText,
  first,
  feedType,
  filters,
  seed,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCommunityCommonFeedQuery,
      variables: {
        id,
        entityTypes,
        searchText,
        first,
        feedType,
        filters,
        seed,
      },
    });
  } catch (e) {
    //console.log("error", e, queryData);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCommunityGroupFeedFromCache = ({ id, searchText, seed }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCommunityGroupFeedQuery,
      variables: {
        id,
        searchText,
        seed,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCommunityCombineFeedFromCache = ({
  id,
  searchText,
  unitsFirst,
  lesFirst,
  educatorsFirst,
  seed,
  showEducators,
  showLeCollections,
  leCollectionsFirst,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCommunityCombineFeedQuery,
      variables: {
        id,
        searchText,
        unitsFirst,
        lesFirst,
        educatorsFirst,
        seed,
        showEducators,
        showLeCollections,
        leCollectionsFirst,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeCommunityCommonFeedToCache = ({
  id,
  entityTypes,
  searchText,
  first,
  feedType,
  data,
  filters,
  seed,
}) => {
  try {
    client.writeQuery({
      query: getCommunityCommonFeedQuery,
      variables: {
        id,
        entityTypes,
        searchText,
        first,
        feedType,
        filters,
        seed,
      },
      data,
    });
  } catch (e) {
    // console.log(e);
  }
};

export const getCollectionFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCollectionQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    //  console.log(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
