import React, { useRef, useState, useEffect } from "react";
import classes from "./SearchBarWithItem.scss";
import { ArrowIcon } from "SvgComponents";
import classNames from "classnames";
import { ProfileCascade } from "UIComponents";

export const SearchBarWithItem = props => {
  const {
    updateSearchText,
    placeholder,
    error,
    selectedItem,
    itemType,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef();

  const containerStyle = classNames({
    [classes.container]: true,
    [classes.focusContainer]: isFocused,
    [classes.errorContainer]: Boolean(error),
  });

  const arrowContainerStyle = classNames({
    [classes.arrowContainer]: true,
    [classes.upArrowContainer]: isFocused,
  });

  const [selectedLabel, setSelectedLabel] = useState("");

  useEffect(() => {
    if (selectedItem && !isFocused) {
      const text = selectedItem.label || "";
      setSelectedLabel(text);
      resetInputValue(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const resetInputValue = text => {
    updateSearchText("");
    setIsFocused(false);
    inputRef.current.value = text || selectedLabel;
  };

  const setFocus = () => {
    if (!isFocused) {
      inputRef.current.focus();
      inputRef.current.select();
      setIsFocused(isFocused => !isFocused);
    }
  };
  const inputRefValue = inputRef.current?.value ?? "";

  return (
    <div className={containerStyle}>
      <div className={classes.leftContainer} onClick={setFocus}>
        {itemType == "UserWithProfileImage" && (
          <div className={classes.profileIconContainer}>
            <ProfileCascade
              items={[
                {
                  id: selectedItem?.value,
                  // when the search bar is focused we are supposed to show the empty icon if something else apart from selected value is written
                  //if search bar is on blur state than we need to display the selected item
                  value: isFocused
                    ? inputRefValue === selectedLabel
                      ? selectedLabel
                      : ""
                    : selectedLabel,
                  imageUrl: selectedItem?.profileImage,
                },
              ]}
              shouldDynamicColor={true}
            />
          </div>
        )}
        <input
          className={classes.textInputContainer}
          placeholder={placeholder}
          ref={inputRef}
          onBlur={() => resetInputValue("")}
          onChange={e => updateSearchText(e.target.value)}
        />
      </div>
      <div
        className={classes.rightContainer}
        onClick={() => {
          setFocus();
          setIsFocused(isFocused => !isFocused);
        }}
      >
        <div className={arrowContainerStyle}>
          <ArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default SearchBarWithItem;
