import { injectReducer } from "store/reducers";
import { getNextMenuRoute } from "Administrator/modules/AdministratorModule";
import { getRelativePath } from "Utils";
import { withAsyncRouteLoading } from "UIComponents";
import ModuleSettings from "./routes/ModuleSettings";
import Journal from "./routes/Journal";
import Attendance from "./routes/Attendance";
import Other from "./routes/Other";
import FamilySettings from "./routes/FamilySettings";

export default store => ({
  path: "settings",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components/Settings").default;
            const reducer = require("./modules/SettingsModule").default;
            injectReducer(store, { key: "settings", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-settings"
        );
      })
  ),
  onEnter(nextState, replace) {
    if (_.last(nextState.routes).path == "settings") {
      const route = getNextMenuRoute("SETTINGS");
      if (route) {
        const path = getRelativePath(route);
        replace(path);
      } else {
        replace(getRelativePath("../"));
      }
    }
  },
  childRoutes: [
    ModuleSettings(store),
    Journal(store),
    Attendance(store),
    Other(store),
    FamilySettings(store),
  ],
});
