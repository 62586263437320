import PropTypes from "prop-types";
import React from "react";

const LogoutIcon = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 15 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <g id="V00" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-211.000000, -40.000000)"
        fill={props.fill && props.fill}
        fillRule="nonzero"
      >
        <path
          d="M215.49223,42.3466551 C215.3341,42.0735385 214.984505,41.9803237 214.711389,42.138454 C212.41682,43.4669743 211,45.908596 211,48.5714286 C211,52.6741153 214.325885,56 218.428571,56 C222.531258,56 225.857143,52.6741153 225.857143,48.5714286 C225.857143,45.9192998 224.451759,43.48604 222.171771,42.1535879 C221.899298,41.9943513 221.549328,42.0861477 221.390092,42.3586208 C221.230855,42.6310938 221.322652,42.9810636 221.595125,43.1403002 C223.525206,44.2682628 224.714286,46.3266367 224.714286,48.5714286 C224.714286,52.0429327 221.900076,54.8571429 218.428571,54.8571429 C214.957067,54.8571429 212.142857,52.0429327 212.142857,48.5714286 C212.142857,46.3175907 213.341622,44.2521207 215.284029,43.1274966 C215.537637,42.9806613 215.636126,42.6687369 215.522294,42.406253 L215.49223,42.3466551 Z M218.428571,40 C218.135522,40 217.893996,40.2205944 217.860987,40.5047879 L217.857143,40.5714286 L217.857143,47 C217.857143,47.3155913 218.11298,47.5714286 218.428571,47.5714286 C218.72162,47.5714286 218.963147,47.3508342 218.996156,47.0666406 L219,47 L219,40.5714286 C219,40.2558373 218.744163,40 218.428571,40 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

LogoutIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

LogoutIcon.defaultProps = {
  height: 16,
  width: 16,
};

export default LogoutIcon;
