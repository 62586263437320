import { AssessmentDetails } from "Assessments";
import { withAsyncRouteLoading } from "UIComponents";
import { LeDetails } from "LearningEngagement";
export default store => {
  return {
    path: ":flow_id",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;

              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
    childRoutes: [AssessmentDetails(store), LeDetails(store)],
  };
};
