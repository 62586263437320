import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { UILabel, RichTextEditor, I18nHOC } from "UIComponents";
import classes from "./TeacherQuestions.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { getStringAutoLinks } from "Utils";

class TeacherQuestions extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  loiList = () => {
    const { options, t } = this.props;
    return (
      _.get(options, "length", 0) > 0 && (
        <div className={classes.loiContainer}>
          <div className={classes.loiLabel}>{t("unitPlan:this_unit_loi")}</div>
          <div className={classes.optionList}>
            {_.map(options, (loi, index) => {
              return (
                <div key={loi.id} className={classes.option}>
                  <div className={classes.dot} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getStringAutoLinks(loi.value),
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      )
    );
  };

  getTeacherQuestions = () => {
    const {
      height,
      width,
      value,
      name,
      updateInputField,
      onFocusInputField,
      onBlurInputField,
    } = this.props;

    return (
      <div className={classes.inputListContainer}>
        {this.loiList()}
        <RichTextEditor
          value={value}
          name={name}
          updateInputField={updateInputField}
          height={height}
          width={width}
          onBlurInputField={onBlurInputField}
          onFocusInputField={onFocusInputField}
        />
      </div>
    );
  };
  renderEdit = () => {
    return (
      <div className={classes.container}>{this.getTeacherQuestions()}</div>
    );
  };
}

export default I18nHOC({ resource: "unitPlan" })(TeacherQuestions);
