import React from "react";
import classes from "./LockedScreenModal.scss";
import { LOCKED_SCREEN_CONFIG } from "modules/PermissionModule";
import { UIButton, UIModal, I18nHOC } from "UIComponents";
import { PremiumIcon, CancelIcon } from "SvgComponents";
import ACLStore from "lib/aclStore";
import { connect } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const LockedScreenModal = React.memo(props => {
  const {
    featureKey,
    onClose,
    onSeePlansClicked,
    createPlanUpgradeRequest,
  } = props;
  const lockedScreenObject = LOCKED_SCREEN_CONFIG[featureKey] || {};
  const {
    heading,
    subHeading,
    subText,
    upgradeText,
    customFooter,
    showContactButton,
    smallContainer,
  } = lockedScreenObject;
  const { t } = useTranslation("blockScreen");
  const isAdmin = ACLStore.can("AdminPortal");

  const onClickTalkPlanUpgrade = async () => {
    await createPlanUpgradeRequest();
    onClose();
  };
  const containerStyle = classNames(
    { [classes.container]: true },
    {
      [classes.containerSmall]: smallContainer || _.isEmpty(lockedScreenObject),
    }
  );
  const modalContentStyle = classNames(
    { [classes.modalContent]: true },
    {
      [classes.smallModalContent]:
        smallContainer || _.isEmpty(lockedScreenObject),
    }
  );

  const premiumSubTextStyle = classNames(
    { [classes.premiumSubText]: true },
    {
      [classes.premiumSubTextMedium]: _.isEmpty(lockedScreenObject),
    }
  );

  const premiumContainerStyle = classNames(
    { [classes.premiumContainer]: true },
    {
      [classes.premiumContainerSmall]: _.isEmpty(lockedScreenObject),
    }
  );

  return (
    <UIModal
      isOpen={true}
      modalContent={modalContentStyle}
      onRequestClose={onClose}
    >
      <div className={containerStyle}>
        <div
          className={classes.cancelIcon}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <CancelIcon width={16} heigth={16} />
        </div>
        <div className={classes.middleBody}>
          <div className={premiumContainerStyle}>
            <PremiumIcon></PremiumIcon>
            <div className={premiumSubTextStyle}>
              {subText
                ? t(subText)
                : !_.isEmpty(lockedScreenObject)
                ? t("blockScreen:feature_not_included_current")
                : t("blockScreen:feature_not_included_premium")}
            </div>
          </div>
          {heading && <div className={classes.heading}>{t(heading)}</div>}
          {subHeading && (
            <div className={classes.subHeading}>{t(subHeading)}</div>
          )}
        </div>
        <div className={classes.footer}>
          {customFooter ? (
            <React.Fragment>
              <div
                style={{ marginTop: "32px" }}
                className={classes.upgradeContainer}
              >
                <div className={classes.upgradeText}>{t(upgradeText)}</div>
              </div>

              {showContactButton && (
                <div className={classes.upgradeButton}>
                  <UIButton
                    size={"lg"}
                    color={"pink"}
                    onClick={onClickTalkPlanUpgrade}
                  >
                    {t("blockScreen:talk_to_our_team")}
                  </UIButton>
                </div>
              )}
            </React.Fragment>
          ) : isAdmin ? (
            <React.Fragment>
              <div className={classes.upgradeContainer}>
                <div className={classes.upgradeText}>{`${t(
                  "blockScreen:upgrade_subscription"
                )}!`}</div>
                <div onClick={onSeePlansClicked} className={classes.plansText}>
                  {t("blockScreen:see_our_plans")}
                </div>
              </div>

              <div className={classes.upgradeButton}>
                <UIButton
                  size={"lg"}
                  color={"pink"}
                  onClick={onClickTalkPlanUpgrade}
                >
                  {t("blockScreen:talk_to_our_team")}
                </UIButton>
              </div>
            </React.Fragment>
          ) : (
            <div className={classes.teacherText}>
              {!_.isEmpty(lockedScreenObject)
                ? t("blockScreen:feature_not_included_premium_admin")
                : t("blockScreen:contact_admin_upgrade_subscription")}
            </div>
          )}
        </div>
      </div>
    </UIModal>
  );
});

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapActionCreators)(LockedScreenModal);
