export const getQueryUpdatedQueryString = ({ url, params }) => {
  if (url) {
    const currentQueryString = _.replace(url, "?", "");
    const currenQueryArray = _.split(currentQueryString, "&");

    let currenQueryParams = {};
    _.forEach(currenQueryArray, arrValue => {
      const paramValue = _.split(arrValue, "=");
      if (_.get(paramValue, "length", 0) > 1) {
        currenQueryParams = {
          ...(currenQueryParams || {}),
          [paramValue[0]]: paramValue[1],
        };
      }
    });

    params = { ...(currenQueryParams || {}), ...(params || {}) };
  }
  const nullKeys = _.filter(
    Object.keys(params || {}),
    key => !_.get(params, key, "")
  );

  params = _.omit(params, nullKeys);

  const queryString = _.join(
    _.map(params, (val, key) => `${key}=${val}`),
    "&"
  );
  return queryString;
};
