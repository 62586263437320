import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";

export default store => {
  return {
    path: "profile",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("./modules/ProfileModule").default;
              injectReducer(store, { key: "platformProfile", reducer });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-profile"
          );
        })
    ),
    childRoutes: [],
  };
};
