import gql from "graphql-tag";
import {
  staffFragment,
  attachmentFragment,
  studentFragment,
  genericFolderFragment,
  attachmentGroupsFragment,
  toolResponseFragment,
  pypElementRatingFragment,
  meetingFragment,
  settingFragment,
  resolvedFieldValueFragment,
  taggedPracticesFragment,
  genericTagFragment,
} from "modules/CommonFragments";
import { rubricFragment } from "Rubric/modules/RubricFragments";
import { checklistFragment } from "Checklist/modules/ChecklistFragments";
import { singlePointRubricFragment } from "SinglePointRubric/modules/SinglePointRubricFragments";
import { organizationResourceFragment } from "OrganizationResources/modules/OrganizationResourcesFragments";
import { assessmentToolFragment } from "modules/AssessmentTool/AssessmentToolFragment";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";

export const assessmentFragment = {
  presetAssessmentDetail: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        uid
        value
      }
      image {
        id
        uid
        value
      }
      fields(uids: $presetAssessmentUids) {
        id
        uid
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      unitPlan {
        id
        subjects {
          id
          value
        }
        grades
        organization {
          id
        }
        title {
          id
          uid
          value
        }
        image {
          id
          uid
          value
        }
        allFields {
          ...unitPlanAllFields
        }
      }
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${plannerFieldFragment.unitPlanAllFields}
  `,
};

export const studentAssigmentSubmissionFragment = {
  studentAssigmentSubmission: gql`
    fragment studentAssigmentSubmissionItem on StudentAssignmentSubmission {
      id
      response
      attachmentGroups {
        ...attachmentGroupItem
      }
      attachments {
        ...attachmentItem
      }
      status
      submittedAt
      returnedAt
    }
    ${attachmentGroupsFragment.attachmentGroup}
    ${attachmentFragment.attachment}
  `,
};
export const studentAssignmentFragment = {
  studentAssignmentDetails: gql`
    fragment studentAssignmentItem on StudentAssignment {
      id
      student {
        ...studentItem
      }
      state
      isSubmitted
      conversation {
        id
        unreadMessageCount
        unreadMessageCounts {
          itemType
          count
        }
      }
      submission(showOnlySubmitted: $showOnlySubmitted) {
        ...studentAssigmentSubmissionItem
      }
      toolResponses(userFilter: $userFilter) {
        ...toolResponseItem
      }
      ibPYPElementRatings {
        ...ratingItem
      }
      elementRemarks {
        ...remarkItem
      }
      isEvaluated
      evaluatedAt
      isEvaluationApplicable
      status
      assignment {
        id
        isSimilarityCheckEnabled
        course {
          id
          grade {
            id
          }
          subjects {
            id
            name
          }
        }
        curriculumProgram {
          id
          type
        }
      }
      post {
        id
        publishedAt
        state
      }
    }
    ${pypElementRatingFragment.elementRemark}
    ${studentAssigmentSubmissionFragment.studentAssigmentSubmission}
    ${studentFragment.basicStudentDetails}
    ${toolResponseFragment.toolResponse}
    ${pypElementRatingFragment.studentElementRating}
  `,
  allSubmissionFragment: gql`
    fragment studentAssignmentItem on StudentAssignment {
      id
      student {
        ...studentItem
      }
      submission(showOnlySubmitted: false) {
        ...studentAssigmentSubmissionItem
      }
      ibPYPElementRatings {
        id
        value
        ibPYPElementId
        ibPYPElementType
      }
      isEvaluated
      isSubmitted
      isEvaluationApplicable
      isSharedWithStudent
      status
      post {
        id
      }
      conversation {
        id
      }
    }
    ${studentAssigmentSubmissionFragment.studentAssigmentSubmission}
    ${studentFragment.basicStudentDetails}
  `,
  studentAssignmentBasicDetails: gql`
    fragment studentAssignmentItem on StudentAssignment {
      id
      status
      isSubmitted
      isEvaluated
      state
      conversation {
        id
        unreadMessageCount
      }
      assignment {
        id
        label
        contentType
        isStudentSubmissionEnabled
        deadline
        assignedStudents {
          id
          state
        }
        course {
          id
          title
        }
        content {
          ... on Assessment {
            id
            assessmentType {
              id
              value
            }
            title {
              id
              value
            }
          }
          ... on AssignmentResource {
            id
            label
          }
        }
        curriculumProgram {
          id
          curriculum {
            id
            type
          }
        }
        classDiscussion {
          id
          unreadMessageCount
        }
      }
    }
  `,
};

export const assignmentFragment = {
  assignmentFeed: gql`
    fragment assignmentItem on Assignment {
      id
      contentType
      state {
        state
        publishedAt
        updatedAt
      }
      createdAt
      isStudentSubmissionEnabled
      isTeacherEvaluationEnabled
      isClassDiscussionEnabled
      isSimilarityCheckEnabled
      isPrivate
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
          image {
            id
            value
          }
          description {
            id
            value
          }
          assessmentToolType
        }
        ... on AssignmentResource {
          id
          label
          assignmentTitle: title
          attachments {
            ...attachmentItem
          }
          meeting {
            ...meetingDetailsItem
          }
        }
      }
      deadline
      classDiscussion {
        id
        unreadMessageCount
      }
      assignedStudents(studentIds: $studentIds) {
        id
        student {
          ...studentItem
        }
        conversation {
          id
          unreadMessageCount
          unreadMessageCounts(filters: $unreadMessageCountFilters) {
            itemType
            count
          }
        }
        submission {
          id
          status
        }
        state
        isEvaluated
        isSharedWithStudent
        isEvaluationApplicable
        isSubmitted
      }
      createdBy {
        ...staffItem
      }
      counts {
        totalAssignedStudents
        totalSubmissions
      }
      curriculumProgram {
        id
        type
      }
      taggedPractices {
        ...taggedPracticesOnEvidence
      }
    }
    ${attachmentFragment.attachment}
    ${meetingFragment.meetingDetails}
    ${studentFragment.basicStudentDetails}
    ${staffFragment.basicStaffDetails}
    ${taggedPracticesFragment.taggedPractices}
  `,
  assignmentReview: gql`
    fragment assignmentItem on Assignment {
      id
      contentType
      state {
        state
        publishedAt
        updatedAt
      }
      createdAt
      isStudentSubmissionEnabled
      isTeacherEvaluationEnabled
      isClassDiscussionEnabled
      deadline
      course {
        id
        title
      }
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
          assessmentToolType
        }
        ... on AssignmentResource {
          id
          assignmentTitle: title
          label
          meeting {
            ...meetingDetailsItem
          }
        }
      }
      curriculumProgram {
        id
        type
      }
      classDiscussion {
        id
        unreadMessageCount
      }
      assignedStudents(studentIds: $studentIds) {
        id
        student {
          ...studentItem
        }
        conversation {
          id
          unreadMessageCount
        }
        submission {
          id
          status
        }
        isEvaluated
        isSharedWithStudent
        isEvaluationApplicable
        isSubmitted
      }
      createdBy {
        ...staffItem
      }
      counts {
        totalAssignedStudents
        totalSubmissions
      }
    }
    ${studentFragment.basicStudentDetails}
    ${staffFragment.basicStaffDetails}
    ${meetingFragment.meetingDetails}
  `,
  assignmentEdit: gql`
    fragment assignmentItem on Assignment {
      id
      contentType
      isClassDiscussionEnabled
      isStudentSubmissionEnabled
      isTeacherEvaluationEnabled
      isStudentEvaluationEnabled
      isSimilarityCheckEnabled
      isPrivate
      folders {
        ...genericFolderItem
      }
      content {
        id
        ... on Assessment {
          id
        }
        ... on AssignmentResource {
          id
          label
          title
          attachments {
            ...attachmentItem
          }
          studentTemplates {
            ...attachmentGroupItem
          }
          voiceInstruction {
            ...attachmentItem
          }
          meeting {
            ...meetingDetailsItem
          }
        }
      }
      state {
        state
        publishedAt
        updatedAt
      }
      deadline
      assignedStudents {
        id
        isSubmitted
        student {
          ...studentItem
        }
      }
    }
    ${genericFolderFragment.genericFolderBasicDetails}
    ${attachmentFragment.attachment}
    ${meetingFragment.meetingDetails}
    ${studentFragment.basicStudentDetails}
    ${attachmentGroupsFragment.attachmentGroup}
  `,
  assignmentDetails: gql`
    fragment assignmentItem on Assignment {
      id
      contentType
      isClassDiscussionEnabled
      isStudentSubmissionEnabled
      isTeacherEvaluationEnabled
      isStudentEvaluationEnabled
      isSimilarityCheckEnabled
      isPrivate
      publishedAt
      state {
        state
        publishedAt
      }
      content {
        id
        ... on Assessment {
          id
          image {
            id
            value
          }
          title {
            id
            uid
            value
          }
          description {
            id
            uid
            value
          }
          presetAssessment {
            ...assessmentItem
          }
          unitPlan {
            id
            templateId
          }
          assessmentToolType
          assessmentTool {
            id
            ... on Rubric {
              ...rubricItem
            }
            ... on Checklist {
              ...checklist
            }
            ... on SinglePointRubric {
              ...singlePointRubricItem
            }
            ... on MypObjectiveRubric {
              ...mypObjectiveRubricItem
            }
          }
          allFields {
            id
            uid
            value
            resolvedMinimalTree {
              ...resolvedMinimalTreeItem
            }
          }
          fields(
            uids: ["goals", "voiceInstruction", "studentTemplate", "score"]
          ) {
            id
            uid
            value
            resolvedMinimalTree {
              id
              ... on ResolvedFieldVoiceInstruction {
                id
                attachment {
                  ...attachmentItem
                }
              }
              ... on ResolvedFieldScore {
                id
                maxScore
                isUsingScore
                category {
                  id
                  label
                  colorCode
                }
              }
              ... on ResolvedFieldStudentTemplate {
                id
                attachmentGroups {
                  ...attachmentGroupItem
                }
              }
            }
          }
          organizationResources {
            ...organizationResourceItem
          }
          resources {
            totalCount
            edges {
              node {
                id
                attachments {
                  ...attachmentItem
                }
              }
            }
          }
          assessmentType {
            id
            value
          }
          templateId
        }
        ... on AssignmentResource {
          id
          label
          assignmentTitle: title
          voiceInstruction {
            ...attachmentItem
          }
          attachments {
            ...attachmentItem
          }
          studentTemplates {
            ...attachmentGroupItem
          }
        }
      }
      deadline
      classDiscussion {
        id
        unreadMessageCount
      }
      assignedStudents(studentIds: $studentIds) {
        id
        isSubmitted
        student {
          ...studentItem
        }
        submission(showOnlySubmitted: $showOnlySubmitted) {
          id
          status
          response
          submittedAt
        }
        toolResponses(userFilter: $userFilter) {
          ...toolResponseItem
        }
        ibPYPElementRatings {
          id
          value
          ibPYPElementId
          ibPYPElementType
        }
        conversation {
          id
          unreadMessageCount
          unreadMessageCounts(filters: $unreadMessageCountFilters) {
            itemType
            count
          }
        }
        isEvaluated
        isSharedWithStudent
        isEvaluationApplicable
        status
      }
      counts {
        totalAssignedStudents
        totalSubmissions
      }
      isDeleted
      curriculumProgram {
        id
        type
      }
      course {
        id
        grade {
          id
        }
        subjects {
          id
          name
        }
        settings {
          ...settingItem
        }
        grades {
          id
        }
      }
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
    }
    ${settingFragment.setting}
    ${studentFragment.basicStudentDetails}
    ${rubricFragment.rubric}
    ${checklistFragment.checklist}
    ${singlePointRubricFragment.singlePointRubric}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${assessmentToolFragment.mypObjectiveRubric}
    ${attachmentFragment.attachment}
    ${attachmentGroupsFragment.attachmentGroup}
    ${organizationResourceFragment.organizationResource}
    ${assessmentFragment.presetAssessmentDetail}
    ${toolResponseFragment.toolResponse}
  `,
  assignmentInsights: gql`
    fragment assignmentItem on Assignment {
      id
      label
      createdAt
      deadline
      isStudentSubmissionEnabled
      contentType
      curriculumProgram {
        id
        type
      }
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
        }
        ... on AssignmentResource {
          id
          assignmentTitle: title
          label
        }
      }
    }
  `,
  assignmentTodos: gql`
    fragment assignmentItem on Assignment {
      id
      label
      createdAt
      deadline
      isStudentSubmissionEnabled
      isClassDiscussionEnabled
      contentType
      createdBy {
        id
        firstName
        lastName
      }
      classDiscussion {
        id
        unreadMessageCount
      }
      course {
        id
      }
      publishedAt
      updatedAt
      state {
        state
        publishedAt
        updatedAt
      }
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
        }
        ... on AssignmentResource {
          id
          assignmentTitle: title
          label
          meeting {
            ...meetingDetailsItem
          }
        }
      }
      curriculumProgram {
        id
        type
      }
    }
    ${meetingFragment.meetingDetails}
  `,
  assignmentSelectionFeed: gql`
    fragment assignmentItem on Assignment {
      id
      contentType
      createdAt
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          image {
            id
            value
          }
          assessmentType {
            id
            value
          }
        }
        ... on AssignmentResource {
          id
          quickTaskTitle: title
        }
      }
    }
  `,
  assignmentEvalutionInsight: gql`
    fragment assignmentEvaluationItem on Assignment {
      id
      insights {
        rowId
        columnId
        studentResponseCount
        teacherResponseCount
        studentsByTeacher {
          ...studentItem
        }
        studentsByStudent {
          ...studentItem
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
};

export const courseAssignmentFragment = {
  courseAssignmentFeed: gql`
    fragment courseAssignmentItem on Course {
      id
      assignments(
        first: 10
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: {
          createdBy: $createdBy
          contentTypes: $contentTypes
          searchText: $searchText
          states: $states
          folderIds: $folderIds
          showUpcoming: $showUpcoming
          assessmentTypes: $assessmentTypes
        }
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...assignmentItem
          }
        }
      }
    }
    ${assignmentFragment.assignmentFeed}
  `,
  teacherReviewFilterCount: gql`
    fragment teacherReviewFilterCountItem on Organization {
      id
      LAST_WEEK: assignments(
        filters: {
          states: $states
          deadlineDates: {
            startDate: $lastWeekStartDate
            endDate: $lastWeekEndDate
          }
          archiveDates: {
            startDate: $archiveLastWeekStartDate
            endDate: $archiveLastWeekEndDate
          }
          contentTypes: $contentTypes
          courseIds: $courseIds
          createdBy: $createdBy
          searchText: $searchText
        }
      ) {
        totalCount
      }
      EARLIER: assignments(
        filters: {
          states: $states
          deadlineDates: { endDate: $earlierEndDate }
          archiveDates: { endDate: $archiveEarlierEndDate }
          contentTypes: $contentTypes
          courseIds: $courseIds
          createdBy: $createdBy
          searchText: $searchText
        }
      ) {
        totalCount
      }
      THIS_WEEK: assignments(
        filters: {
          states: $states
          deadlineDates: {
            startDate: $thisWeekStartDate
            endDate: $thisWeekEndDate
          }
          archiveDates: {
            startDate: $archiveThisWeekStartDate
            endDate: $archiveThisWeekEndDate
          }
          contentTypes: $contentTypes
          courseIds: $courseIds
          createdBy: $createdBy
          searchText: $searchText
        }
      ) {
        totalCount
      }
      NO_DUE_DATE: assignments(
        filters: {
          states: $states
          isNoDeadlineDate: true
          contentTypes: $contentTypes
          courseIds: $courseIds
          createdBy: $createdBy
          searchText: $searchText
        }
      ) {
        totalCount
      }
    }
  `,
  teacherClassroomReview: gql`
    fragment courseAssignmentItem on Organization {
      id
      assignments(
        first: 10
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: {
          createdBy: $createdBy
          contentTypes: $contentTypes
          searchText: $searchText
          courseIds: $courseIds
          states: $states
          showUpcoming: $showUpcoming
          deadlineDates: $deadlineDates
          archiveDates: $archiveDates
          isNoDeadlineDate: $isNoDeadlineDate
        }
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...assignmentItem
          }
        }
      }
    }
    ${assignmentFragment.assignmentReview}
  `,
  familyStudentTodos: gql`
    fragment familyStudentTodosItem on FamilyMember {
      id
      assignments(
        first: $first
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: {
          searchText: $searchText
          states: $states
          courseIds: $courseIds
          status: $status
          deadlineDates: $deadlineDates
          archiveDates: $archiveDates
          isNoDeadlineDate: $isNoDeadlineDate
          contentTypes: $contentTypes
          assessmentTypes: $assessmentTypes
          folderIds: $folderIds
          academicYears: $academicYears
        }
        studentIds: $studentIds
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            isSubmitted
            isEvaluated
            status
            evaluatedAt
            state
            conversation {
              id
              unreadMessageCount
            }
            student {
              ...studentItem
            }
            submission {
              id
              status
              submittedAt
              returnedAt
            }
            assignment {
              ...assignmentItem
            }
            assignmentState {
              publishedAt
              updatedAt
            }
          }
        }
      }
    }
    ${assignmentFragment.assignmentTodos}
    ${studentFragment.basicStudentDetails}
  `,
  studentInsights: gql`
    fragment studentInsightsItem on Student {
      id
      assignments(
        first: 10
        after: $after
        orderBy: PUBLISHED_AT
        orderByDirection: DESC
        filters: {
          contentTypes: $contentTypes
          searchText: $searchText
          states: $states
          folderIds: $folderIds
          courseIds: [$courseIds]
          status: $status
          assessmentTypes: $assessmentTypes
        }
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            isSubmitted
            isEvaluated
            evaluatedAt
            status
            student {
              ...studentItem
            }
            submission {
              id
              status
              submittedAt
              returnedAt
            }

            assignment {
              ...assignmentItem
            }
          }
        }
      }
    }
    ${assignmentFragment.assignmentInsights}
    ${studentFragment.basicStudentDetails}
  `,
  studentTodos: gql`
    fragment studentTodosItem on Student {
      id
      assignments(
        first: 10
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: {
          contentTypes: $contentTypes
          assessmentTypes: $assessmentTypes
          folderIds: $folderIds
          searchText: $searchText
          states: $states
          courseIds: $courseIds
          status: $status
          deadlineDates: $deadlineDates
          archiveDates: $archiveDates
          isNoDeadlineDate: $isNoDeadlineDate
          academicYears: $academicYears
          courseArchivalState: ALL
        }
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            isSubmitted
            isEvaluated
            status
            evaluatedAt
            state
            conversation {
              id
              unreadMessageCount
            }
            student {
              ...studentItem
            }
            submission {
              id
              status
              submittedAt
              returnedAt
            }
            assignment {
              ...assignmentItem
            }
            assignmentState {
              publishedAt
              updatedAt
            }
          }
        }
      }
    }
    ${assignmentFragment.assignmentTodos}
    ${studentFragment.basicStudentDetails}
  `,
  studentTodosFilterCount: gql`
    fragment studentTodosFilterCountItem on Student {
      id
      LAST_WEEK: assignments(
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: {
            startDate: $lastWeekStartDate
            endDate: $lastWeekEndDate
          }
          archiveDates: {
            startDate: $archiveLastWeekStartDate
            endDate: $archiveLastWeekEndDate
          }
        }
      ) {
        totalCount
      }
      EARLIER: assignments(
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: { endDate: $earlierEndDate }
          archiveDates: { endDate: $archiveEarlierEndDate }
        }
      ) {
        totalCount
      }
      THIS_WEEK: assignments(
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: {
            startDate: $thisWeekStartDate
            endDate: $thisWeekEndDate
          }
          archiveDates: {
            startDate: $archiveThisWeekStartDate
            endDate: $archiveThisWeekEndDate
          }
        }
      ) {
        totalCount
      }
      LATER: assignments(
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: { startDate: $laterStartDate }
          archiveDates: { startDate: $archiveLaterStartDate }
        }
      ) {
        totalCount
      }
      NO_DUE_DATE: assignments(
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          isNoDeadlineDate: true
        }
      ) {
        totalCount
      }
    }
  `,
  familyStudentTodosFilterCount: gql`
    fragment familyStudentTodosFilterCountItem on FamilyMember {
      id
      LAST_WEEK: assignments(
        studentIds: $studentIds
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: {
            startDate: $lastWeekStartDate
            endDate: $lastWeekEndDate
          }
          archiveDates: {
            startDate: $archiveLastWeekStartDate
            endDate: $archiveLastWeekEndDate
          }
        }
      ) {
        totalCount
      }
      EARLIER: assignments(
        studentIds: $studentIds
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: { endDate: $earlierEndDate }
          archiveDates: { endDate: $archiveEarlierEndDate }
        }
      ) {
        totalCount
      }
      THIS_WEEK: assignments(
        studentIds: $studentIds
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: {
            startDate: $thisWeekStartDate
            endDate: $thisWeekEndDate
          }
          archiveDates: {
            startDate: $archiveThisWeekStartDate
            endDate: $archiveThisWeekEndDate
          }
        }
      ) {
        totalCount
      }
      LATER: assignments(
        studentIds: $studentIds
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          deadlineDates: { startDate: $laterStartDate }
          archiveDates: { startDate: $archiveLaterStartDate }
        }
      ) {
        totalCount
      }
      NO_DUE_DATE: assignments(
        studentIds: $studentIds
        filters: {
          academicYears: $academicYears
          states: $states
          status: $status
          courseIds: $courseIds
          contentTypes: $contentTypes
          isNoDeadlineDate: true
        }
      ) {
        totalCount
      }
    }
  `,
  courseAssignmentStateWiseCount: gql`
    fragment courseAssignmentItem on Course {
      id
      assignments {
        stateWiseCount {
          state
          count
        }
      }
    }
  `,
};

export const studentInsightsFragment = {
  studentDetailsWithStats: gql`
    fragment studentItem on Student {
      id
      firstName
      lastName
      profileImage
      email
      type
      assignments(filters: $studentAssignmentFilters) {
        summaryCounts {
          evaluationPending
          assigned
          overdue
        }
      }
    }
  `,
};

export const courseStudentFragment = {
  courseStudentPortfolio: gql`
    fragment courseItem on Course {
      id
      students(
        after: $after
        filters: $filters
        orderBy: TITLE
        orderByDirection: ASC
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...studentItem
          }
        }
      }
    }
    ${studentInsightsFragment.studentDetailsWithStats}
  `,
};

export const organizationAssignmentFragment = {
  organizationAssignmentFeed: gql`
    fragment organizationAssignmentItem on Organization {
      assignments(
        first: $first
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: $filters
      ) {
        stateWiseCount {
          state
          count
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...assignmentItem
          }
        }
      }
    }
    ${assignmentFragment.assignmentSelectionFeed}
  `,
};

export const courseUserMapFragment = {
  courseUserMap: gql`
    fragment courseItem on Course {
      id
      userMap(filters: $filters) {
        edges {
          node {
            id
            user {
              id
              ... on Student {
                ...studentItem
              }
            }
            tags {
              id
              ...basicGenericTagItem
            }
          }
        }
      }
    }
    ${studentFragment.basicStudentDetails}
    ${genericTagFragment.genericTag}
  `,
};
