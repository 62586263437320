/**
 * Name: EmojiPicker
 * Type: UI Component
 * Function: A reusable UI component to show emoji picker.
 * It uses emoji-mart (https://github.com/missive/emoji-mart)
 * and react-tiny-popover (https://github.com/alexkatz/react-tiny-popover) as dependencies.
 * Takes a child (react component or HTMLElement) that react-tiny-popover uses as target.
 * Emoji Picker Popup will be attached around this passed child.
 * Output: Renders an emoji picker as a popup.
 */

import React, { useState, useEffect } from "react";
import classes from "./EmojiPicker.scss";
import PropTypes from "prop-types";
import Popover from "react-tiny-popover";

const EmojiPicker = ({
  onEmojiSelect,
  isOpen,
  pickerProps,
  popoverProps,
  children,
  onClickOutside,
  ...props
}) => {
  const [LazyEmojiPicker, setLazyEmojiPicker] = useState();

  useEffect(() => {
    /*
      - This function is custom implementation of React lazy.
      - Picker component from emoji-mart will be loaded only when this component renders.
      - After Picker component is loaded, it is set as state of this component (LazyEmojiPicker).
    */
    const loadEmojiPicker = async () => {
      try {
        await import(
          /* webpackChunkName: "emojipicker" */ "emoji-mart/css/emoji-mart.css"
        );
        await import(/* webpackChunkName: "emojipicker" */ "emoji-mart").then(
          module => {
            setLazyEmojiPicker(() => module.Picker);
          }
        );
      } catch (e) {
        window.location.reload();
      }
    };
    loadEmojiPicker();
  }, []);

  return (
    <Popover
      containerStyle={{
        zIndex: 2999,
      }}
      transitionDuration={0.1}
      {...popoverProps}
      isOpen={isOpen}
      content={
        LazyEmojiPicker && (
          <div className={classes.pickerContainer}>
            <LazyEmojiPicker {...pickerProps} onSelect={onEmojiSelect} />
          </div>
        )
      }
      onClickOutside={onClickOutside}
    >
      {children}
    </Popover>
  );
};

EmojiPicker.propTypes = {
  /**
   * Function that will be invoked when user selects an emoji from the picker
   * and will contain emoji information (Object) as an argument.
   */
  onEmojiSelect: PropTypes.func,
  /**
   * Boolean to show emoji picker
   */
  isOpen: PropTypes.bool,
  /**
   * Props that will be passed down to the emoji picker.
   * Check valid props here -> (https://github.com/missive/emoji-mart#picker)
   */
  pickerProps: PropTypes.object,
  /**
   * Props that will be passed down to the popover.
   * Check valid props here -> (https://github.com/alexkatz/react-tiny-popover#api)
   */
  popoverProps: PropTypes.object,
  /**
   * React component or HTMLElement, popover will use to track
   * Check children prop here -> (https://github.com/alexkatz/react-tiny-popover#api)
   */
  children: PropTypes.node.isRequired,
  /**
   * Will be fired when clicked outside the emoji picker or target element
   */
  onClickOutside: PropTypes.func,
};

EmojiPicker.defaultProps = {
  isOpen: false,
};

export default EmojiPicker;
