export const getCountString = ({
  count,
  singularText,
  pluralText,
  prefix,
  postfix,
  shouldShowZero,
  zeroText,
  showCount = true,
}) => {
  let outPutSrting = "";
  if (count == 0) {
    outPutSrting = zeroText
      ? zeroText
      : `${prefix} ${shouldShowZero ? "0" : "No"} ${pluralText} ${postfix}`;
  } else if (count == 1) {
    outPutSrting = `${prefix} 1 ${singularText} ${postfix}`;
  } else {
    outPutSrting = `${prefix} ${count} ${pluralText} ${postfix}`;
  }
  if (!showCount) {
    outPutSrting = _.replace(outPutSrting, count, "");
  }

  return _.replace(outPutSrting, new RegExp("undefined", "g"), "");
};
