import React from "react";
import classes from "./SingleSetting.scss";
import classNames from "classnames";
import { SwitchButton } from "UIComponents";
import RightComponent from "../RightComponent";
import { compose } from "react-apollo";
import { goToRelativeRoute } from "modules/Services";
import { connect } from "react-redux";
import MultiCheckSelection from "../MultiCheckSelection";
import DropdownSetting from "../DropdownSetting";
import { Tooltip } from "@toddle-design/web";
import { InformationOutlined } from "@toddle-design/web-icons";

const styles = {
  containerStyle: {
    gridColumnStart: 1,
  },
  overlayInnerStyles: {
    maxWidth: "200px",
  },
};

const SingleSetting = React.memo(
  ({
    subModule,
    subSettings,
    allSettings,
    onUpdateSetting,
    subModuleTitleStyles,
    t,
    goToRelativeRoute,
    platform,
    isDisabled,
  }) => {
    const rightComponent = _.get(subModule, "metadata.rightComponent", "");

    const renderValue = setting => {
      const uiType = _.get(setting, "uiType", "");
      const permission = _.get(setting, "permission");
      const isSettingDisabled = _.get(setting, "status") === "DISABLED";
      switch (uiType) {
        case "BOOLEAN": {
          const uiType = _.get(setting, "uiType");
          const settingValueStyles = classNames({
            [classes.settingValue]: true,
            [classes.fullWidth]: uiType === "DROPDOWN",
          });
          const isTextLocalized = _.get(
            setting,
            "metadata.isTextLocalized",
            true
          );
          const booleanValue = _.get(setting, "booleanValue");
          const tooltip = _.get(setting, "metadata.tooltip");

          const renderLabel = () => {
            return (
              <div>
                {isTextLocalized
                  ? t(`configuration:${setting.label}`)
                  : _.get(setting, "label", "")}
              </div>
            );
          };

          const renderTooltip = () => {
            if (!tooltip) {
              return null;
            }
            return (
              <Tooltip
                tooltip={t(`configuration:${tooltip}`)}
                placement="right"
                overlayInnerStyle={styles.overlayInnerStyles}
              >
                <div className={classes.infoIcon}>
                  <InformationOutlined variant={"subtle"} />
                </div>
              </Tooltip>
            );
          };

          return (
            <div className={classes.settingContainer}>
              <div className={classes.settingTitle}>
                {renderLabel()}
                {renderTooltip()}
              </div>
              <div className={settingValueStyles}>
                <div>
                  <SwitchButton
                    isDisabled={!permission || isSettingDisabled || isDisabled} //isDisabled - if parentConfiguration is disabled, isSettingDisabled - if this configuration is disabled, !permission - id permission is not there
                    value={booleanValue}
                    updateInputField={value => {
                      if (onUpdateSetting) {
                        onUpdateSetting({
                          value,
                          setting,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
        case "MULTI_SELECT_SETTING": {
          const settingId = _.get(setting, "id", "");
          const subSettings = _.filter(
            allSettings,
            setting => _.get(setting, "parentID", "") == settingId
          );
          return (
            <MultiCheckSelection
              subModule={setting}
              t={t}
              allSettings={allSettings}
              onUpdateSetting={onUpdateSetting}
              subSettings={subSettings}
              containerStyle={styles.containerStyle}
            />
          );
        }
        case "DROPDOWN": {
          const isTextLocalized = _.get(
            setting,
            "metadata.isTextLocalized",
            true
          );
          return (
            <div className={classes.settingContainer}>
              <div className={classes.settingTitle}>
                {isTextLocalized
                  ? t(`configuration:${setting.label}`)
                  : _.get(setting, "label", "")}
              </div>
              <DropdownSetting
                t={t}
                onUpdateSetting={onUpdateSetting}
                setting={setting}
              />
            </div>
          );
        }
        case "NA": {
          return "-";
        }
        default:
          return null;
      }
    };

    const goToManageFolders = () => {
      if (platform === "TEACHER_WEB") {
        goToRelativeRoute({
          route: "manage-folders",
        });
      } else if (platform === "ADMIN") {
      }
    };

    const renderRightComponent = () => {
      switch (rightComponent) {
        case "MANAGE_FOLDERS": {
          return (
            <RightComponent
              label={t("journal:manage_folders")}
              onClick={goToManageFolders}
            />
          );
        }
        default: {
          return null;
        }
      }
    };
    const isSubmoduleTextLocalized = _.get(
      subModule,
      "metadata.isTextLocalized",
      true
    );
    const label = _.get(subModule, "label", "");
    const subText = _.get(subModule, "subText", null);
    const showGridLayout = _.get(subModule, "metadata.showGridLayout", true);

    const titleContainerSubmoduleClasses = classNames({
      [classes.titleContainerSubmodule]: true,
      [classes.titleContainerSubmoduleNotLabel]: !label,
    });

    const settingsContainerClasses = classNames({
      [classes.settingsContainer]: showGridLayout,
    });

    return (
      <div className={classes.container}>
        <div className={titleContainerSubmoduleClasses}>
          <div className={classes.leftContainer}>
            {label ? (
              <div
                className={classes.titleSubmodule}
                style={subModuleTitleStyles}
              >
                {isSubmoduleTextLocalized ? t(`configuration:${label}`) : label}
              </div>
            ) : null}
            {subText && (
              <div className={classes.subTitle}>
                {isSubmoduleTextLocalized
                  ? t(`configuration:${subText}`)
                  : subText}
              </div>
            )}
          </div>
          <div className={classes.rightContainer}>{renderRightComponent()}</div>
        </div>
        <div className={settingsContainerClasses}>
          {_.map(subSettings, setting => {
            return renderValue(setting);
          })}
        </div>
      </div>
    );
  }
);

const mapActionCreators = {
  goToRelativeRoute,
};

export default compose(connect(null, mapActionCreators))(SingleSetting);
