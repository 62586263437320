import React from "react";
import shortid from "shortid";
import ThemeSelectComponent from "../ThemeSelectComponent";
import SubjectGroupSelect from "../SubjectGroupSelect";
import ResponsesComponent from "../ResponsesComponent";
import KeyConcepts from "../KeyConcepts";
import MultiSelectWithModal from "../MultiSelectWithModal";
import AssessStudentLearning from "../AssessStudentLearning";
import TeacherQuestions from "../TeacherQuestions";
import AssessmentLibrary from "Assessments/components/AssessmentLibrary";
import IBSkills from "../IBSkills";
import NestedSelectComponent from "../NestedSelectComponent";
import Duration from "../Duration";
import AssessmentTool from "../AssessmentTool";
import BenchmarkCheckList from "../BenchmarkCheckList";
import NestedChecklist from "../NestedChecklist";
import IBReflection from "../IBReflection";
import LeLibrary from "LearningEngagement/components/LeLibrary";
import IBResources from "../IBResources";
import RelatedConcepts from "../RelatedConcepts";
import MultiSelectQuestions from "../MultiSelectQuestions";
import { SearchBoxWithTagQuery } from "AppComponents/Community";
import OrganizationResourcesSelection from "../OrganizationResourcesSelection";
import CustomSelectDropDown from "../CustomSelectDropdown";
import CustomMediaCard from "../CustomMediaCard";
import MeasureAssessingWrapper from "../MeasureAssessingWrapper";
import ToggleSwitchInput from "../ToggleSwitchInput";
import ListModal from "../ListModal";
import TOKObject from "../TOKObject";
import MaxScoreTool from "../MaxScoreTool";
import MaxScoreView from "../MaxScoreView";
import SubjectGroupWithSubjectView from "../SubjectGroupWithSubjectView";
import SubjectGroupWithSubjectViewOnly from "../SubjectGroupWithSubjectViewOnly";
import SwitcherComponent from "../SwitcherComponent";
import CriteriaRubricAssessmentWithComments from "../CriteriaRubricAssessmentWithComments";
import SubmissionItem from "../SubmissionItem";
import SubmissionTable from "../SubmissionTable";
import MultiWindowTable from "../MultiWindowTable";
import {
  RichTextEditor,
  TextAreaInput,
  ImageDropzone,
  UISegmentedControl,
  UIInputNumberField,
  RadioButtonList,
  FormSelectDropdown,
  CheckListContainer,
  Listview,
  Textview,
  BenchmarkView,
  TimeSelector,
  AudioRecorderSelector,
  DateSelector,
  StudentTemplates,
  DateDurationSelector,
  DragAndDropList,
  UIInputCheckList,
  GroupedKeyConceptsView,
  FormTextInput,
  FormTextAreaInput,
  NotAddedEmptyView,
} from "UIComponents";
import MultiSelectComponent from "UIComponents/MultiSelectComponent";
import { Collaborator, ActionTable } from "AppComponents";
import { AttachmentList } from "Attachment";
import BenchmarksComponent from "../BenchmarksComponent";
import { colors, fontStyle } from "Constants";
import MultiField from "../MultiField";
import ACLStore from "lib/aclStore";
import { getCurriculumBasedScorePermissionMemoize } from "ScoreCategories/modules/ScoreCategoriesModule";
import DynamicChildren from "../DynamicChildren";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import SelectAttachmentComponent from "../SelectAttachmentComponent";
import LineSeparator from "../LineSeparator";
const MODAL_FIELD_TIMEOUT = 15;
const TEXT_FIELD_TIMEOUT = 4;

const styles = {
  submissionItemLabelStyle: {
    marginBottom: "24px",
  },
};

const segmentedControlStyle = {
  containerStyle: {
    //width: "max-content"
  },
};

const checkListStyle = {
  checkBoxStyles: {
    width: 20,
    height: 20,
  },
};

const defaultViewStyle = {
  labelStyle: {
    fontSize: "1.6rem",
    ...fontStyle.bold,
  },
  labelContainerStyle: {
    marginBottom: 4,
  },
};

const reflectionComponentStyle = {
  labelContainerStyle: {
    padding: 24,
    marginBottom: 0,
    border: "1px solid",
    borderColor: colors.strokeTwo,
    borderRadius: "4px 4px 0 0",
  },
};

const audioRecorderSelectorStyles = {
  innerContainerStyle: { padding: "0 60px" },
};

const defaultEditStyle = {};

const SelectDropdownDynamicOptions = props => {
  const { updateInputField, fieldKey, fieldTypeConfig, value } = props;

  const shouldStoreArrayValue = _.get(
    fieldTypeConfig,
    "shouldStoreArrayValue",
    false
  );
  const updateDropdownValue = (params, extraParams) => {
    if (shouldStoreArrayValue) {
      params[fieldKey] = [params[fieldKey]];
    }
    //to get the value of resolve minimal tree we need the subjects in nodes object of extra params(second argument of update input field)
    updateInputField(params, { nodes: extraParams });
  };
  return (
    <FormSelectDropdown
      {...props}
      value={shouldStoreArrayValue ? value[0] : value}
      label={props.label}
      subText={props.subText}
      areOptionsEmptyAllowed={true}
      options={props.options}
      name={props.fieldKey}
      updateInputField={updateDropdownValue}
      placeholder={props.placeholder}
      {...props.fieldTypeConfig}
    />
  );
};

const TypeComponent = props => {
  switch (props.type) {
    case "MultiWindowTable": {
      return <MultiWindowTable {...props} />;
    }

    case "IBTeacherQuestions":
      return (
        <TeacherQuestions
          {...props}
          label={props.label}
          subText={props.subText}
          height={170}
          width={"100%"}
          options={props.options}
          value={props.value}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "ActionTable": {
      return (
        <ActionTable
          {...props}
          value={props.value ? props.value : { rows: [], columns: [] }}
        />
      );
    }
    case "CustomSelectDropdown": {
      return (
        <CustomSelectDropDown
          {...props}
          {...props.field_config}
          {...props.styles}
        />
      );
    }
    case "DurationSelector":
      return (
        <TimeSelector
          {...props}
          storeValueInSeconds={true}
          SelectedTimeformat="H [h] mm [m]"
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : 0}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          timeComponentStyle={props.timeComponentStyle}
        />
      );
    case "DateSelector":
      return <DateSelector {...props} />;
    case "DateDurationSelector":
      return (
        <DateDurationSelector
          {...props}
          isDisabled={props.isLocked}
          minDate={props.options?.minDate}
          maxDate={props.options?.maxDate}
          value={props.value ?? { startDate: null, endDate: null }}
          updateInputField={(params, extraParams) => {
            props.updateInputField(
              //when dateDuration is used as a Field we return an object
              //with key as dateDuration. eg : {dateDuration : {startDate: moment..}}
              //else when it is mapped with details fields we return {startDate : moment..}

              props.name && props.isMappedWithDetailsFields
                ? params[props.name]
                : params,
              {
                isMappedWithDetailsFields: props.isMappedWithDetailsFields,
              }
            );
          }}
        />
      );
    case "AttachmentList":
      return (
        <AttachmentList
          {...props}
          {...props.options}
          name={props.fieldKey}
          cardType={
            props.showAttachmentItemV3 ? "horizontalV3" : props.cardType
          }
          parentId={props.fieldId}
          value={props.value ? props.value : []}
          horizontalCardStyle={props.studentTemplateCardStyle}
          emptyComponent={<NotAddedEmptyView />}
          deleteEntityLabel={props.label}
        ></AttachmentList>
      );

    case "InputNumberList":
      return (
        <DragAndDropList
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : []}
          placeholder={props.placeholder}
          name={props.fieldKey}
          isDisabled={props.isLocked}
        />
      );
    case "UIInputCheckList":
      return (
        <UIInputCheckList
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : []}
          placeholder={props.placeholder}
          name={props.fieldKey}
          isDisabled={props.isLocked}
          listType={props.fieldTypeConfig.listType}
        />
      );
    case "IBResourceList":
      return (
        <IBResources
          {...props}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          label={props.label}
          subText={props.subText}
          parentId={props.parentId}
          parentType={props.parentType}
          mode={props.mode}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
        />
      );

    case "IBReflection":
      return (
        <IBReflection
          {...props}
          responses={props.responses}
          label={props.label}
          subText={props.subText}
          value={props.value}
          mode={props.mode}
          name={props.fieldKey}
          options={props.options}
          updateInputField={props.updateInputField}
          labelStyle={props.labelStyle}
          labelContainerStyle={
            props.mode == "view"
              ? props.labelContainerStyle
              : {
                  ...props.labelContainerStyle,
                  ...reflectionComponentStyle.labelContainerStyle,
                }
          }
        />
      );
    case "InputField": {
      return (
        <FormTextInput
          {...props}
          isRequired={props.isRequired}
          onBlurInputField={props.onBlurInputField}
          onFocusInputField={props.onFocusInputField}
          label={props.label}
          subText={props.subText}
          value={props.value}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          disabled={props.isLocked || props.disabled}
          size="large"
        />
      );
    }
    case "InputNumberField":
      return (
        <UIInputNumberField
          {...props}
          label={props.label}
          subText={props.subText}
          type={"number"}
          value={props.value}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          numberLabel={props.subLabel}
        />
      );
    case "Duration":
      return (
        <Duration
          {...props}
          label={props.label}
          subText={props.subText}
          type={"number"}
          value={props.value}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          numberLabel={props.subLabel}
        />
      );
    case "TextArea": {
      return (
        <FormTextAreaInput
          {...props}
          onBlurInputField={props.onBlurInputField}
          onFocusInputField={props.onFocusInputField}
          label={props.label}
          subText={props.subText}
          value={props.value}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    }
    case "ImageDropzone":
      return (
        <ImageDropzone
          {...props}
          onBlurInputField={props.onBlurInputField}
          onFocusInputField={props.onFocusInputField}
          label={props.label}
          subText={props.subText}
          value={props.value}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          parentId={props.parentId}
          parentType={"IMAGE_DROPZONE"}
          isDisabled={props.isLocked}
          isAssessmentCreationRevamp={props.isAssessmentCreationRevamp}
        />
      );

    case "SegmentControl":
      return (
        <UISegmentedControl
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          isDisabled={props.isDisabled}
          options={props.options}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          containerStyle={{
            ..._.get(props, "fieldTypeConfig.containerStyle", {}),
            ...segmentedControlStyle.containerStyle,
          }}
        />
      );
    case "IBTheme":
    case "MultiSelectWithIcon":
      return (
        <ThemeSelectComponent
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          isDisabled={props.isDisabled}
          options={props.options}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "MultiSelectWithModal":
      return (
        <MultiSelectWithModal
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          options={props.options}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "IBConcepts":
      return (
        <KeyConcepts
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          options={props.options}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "IBMultiSelectQuestion":
      return (
        <MultiSelectQuestions
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          mode={props.mode}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
          options={props.options}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "IBRelatedConcepts":
      return (
        <RelatedConcepts
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          mode={props.mode}
          name={props.fieldKey}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
          options={props.options}
          updateInputField={props.updateInputField}
        />
      );

    case "IBMultiSelect":
    case "MultiSelect": {
      return (
        <MultiSelectComponent
          {...props}
          label={props.label}
          subText={props.subText}
          multi={props.multi}
          value={props.value}
          emptyText={props.emptyText}
          options={props.options}
          name={props.fieldKey}
          listItemStyle={_.get(props.styles, "listItemStyle", {})}
          updateInputField={props.updateInputField}
        />
      );
    }
    case "RichText":
      return (
        <RichTextEditor
          {...props}
          label={props.label}
          subText={props.subText}
          onBlurInputField={props.onBlurInputField}
          onFocusInputField={props.onFocusInputField}
          value={props.value}
          width={"100%"}
          height={170}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );

    case "IBAssessLearning":
      return (
        <AssessStudentLearning
          {...props}
          label={props.label}
          subText={props.subText}
          options={props.options}
          value={props.value}
          width={"100%"}
          height={170}
          placeholder={props.placeholder}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "Collaborator":
      return (
        <Collaborator
          {...props}
          {...props.fieldTypeConfig}
          {...props.styles}
          collaborators={props.value || []}
          mode={"edit"}
        />
      );
    case "IBBenchmarks":
      return (
        <BenchmarksComponent
          {...props}
          label={props.label}
          subText={props.subText}
          options={props.options}
          value={props.value}
          resolvedValue={props.resolvedValue}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
          readOnly={props.readOnly}
        />
      );
    case "IBAssessments":
      return (
        <AssessmentLibrary
          {...props}
          label={props.label}
          subText={props.subText}
          options={props.options}
          name={props.fieldKey}
          mode={props.mode}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
        />
      );
    case "IBLearningEngagements":
      return (
        <LeLibrary
          {...props}
          label={props.label}
          subText={props.subText}
          options={props.options}
          name={props.fieldKey}
          mode={props.mode}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
          maxItemCount={props.maxItemCount || 2}
        />
      );
    case "IBSkills":
      return (
        <IBSkills
          {...props}
          label={props.label}
          subText={props.subText}
          options={props.options}
          value={props.value}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );

    case "NestedSelectComponent":
      return (
        <NestedSelectComponent
          {...props}
          showEditButtonAtHeader={
            props.isLocked ? true : props.showEditButtonAtHeader
          }
        />
      );

    case "MultiField":
      return <MultiField {...props} value={props.value || {}} />;

    case "DynamicChildren":
      return <DynamicChildren {...props} />;
    case "SelectAttachmentComponent":
      return <SelectAttachmentComponent {...props} />;

    case "SwitcherComponent":
      return <SwitcherComponent {...props} />;
    case "RadioButtonList":
      return (
        <RadioButtonList
          {...props}
          label={props.label}
          subText={props.subText}
          value={props.value}
          options={props.options}
          name={props.fieldKey}
          listContainerStyle={_.get(props.styles, "listContainerStyle", {})}
          listItemStyle={_.get(props.styles, "listItemStyle", {})}
          isOptionWithBorder={_.get(
            props,
            "fieldTypeConfig.isOptionWithBorder",
            false
          )}
          updateInputField={props.updateInputField}
        />
      );
    case "CheckList": {
      let checkListValue = props.value ? props.value : [];
      /* Added this case to specifically handle The case of DP pedgogical approaches where we need 
       the backend structure of input list and view mode of checklist */
      if (props.isInputListType) {
        checkListValue = _.map(
          _.filter(props.value, { isSelected: true }),
          val => val.id
        );
      }
      const updateFields = value => {
        if (props.isInputListType)
          props.updateInputField({
            [props.fieldKey]: _.map(props.options, option => {
              return {
                id: option.id,
                value: option.label,
                isSelected: _.includes(value[props.fieldKey], option.id),
              };
            }),
          });
        else if (props.configType == "PLANNER_ELEMENT")
          props.updateInputField(value, {
            nodes: props.options,
          });
        else
          props.updateInputField(value, {
            isMappedWithDetailsFields: props.isMappedWithDetailsFields,
          });
      };
      return (
        <CheckListContainer
          {...props}
          mode={props.mode}
          label={props.label}
          subText={props.subText}
          emptyText={props.emptyText}
          value={checkListValue}
          options={props.options}
          name={props.fieldKey}
          checkListContainerStyle={_.get(
            props.styles,
            "listContainerStyle",
            {}
          )}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
          updateInputField={updateFields}
          checkBoxStyles={checkListStyle.checkBoxStyles}
          listItemStyle={_.get(props.styles, "listItemStyle", {})}
        />
      );
    }
    case "BenchmarkCheckList":
      return (
        <BenchmarkCheckList
          {...props}
          name={props.fieldKey}
          options={props.options}
          resolvedValue={props.resolvedValue}
        />
      );
    case "NestedCheckList":
      return <NestedChecklist {...props} />;
    case "SelectDropdown":
      return <SelectDropdownDynamicOptions {...props} />;
    case "MeasureAssessing": {
      return props.isAssessmentCreationRevamp ? (
        <MeasureAssessingWrapper {...props} />
      ) : (
        <SelectDropdownDynamicOptions {...props} options={props.options} />
      );
    }

    case "Exemplar":
    case "Rubrics":
    case "Anecdotal":
    case "AssessmentSinglePointRubric":
    case "AssessmentChecklist":
    case "AssessmentTool": {
      return props?.isAssessmentCreationRevamp ? (
        <CustomMediaCard {...props} updateInputField={props.updateInputField} />
      ) : (
        <AssessmentTool
          {...props}
          label={props.label}
          showViewEmpty={false}
          subText={props.subText}
          mode={props.mode}
          type={props.toolType}
          labelStyle={props.labelStyle}
          labelContainerStyle={props.labelContainerStyle}
          parentType={props.parentType}
          parentId={props.parentId}
          value={props.value ? props.value : ""} //
          fieldId={props.fieldId ? props.fieldId : ""} //
          options={props.options ? props.options : {}}
          name={props.fieldKey ? props.fieldKey : ""} //
          updateInputField={
            props.updateInputField ? props.updateInputField : ""
          } //
          assessmentToolId={props.assessmentToolId}
          assessmentToolsConStyle={props.assessmentToolsConStyle}
          showAttachmentItemV3={props.showAttachmentItemV3}
          horizontalAssessmentToolCardStyle={
            props.horizontalAssessmentToolCardStyle
          }
        />
      );
    }
    case "ListView":
      return (
        <Listview
          {...props}
          mode={props.mode}
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : []}
          name={props.fieldKey}
          labelStyle={props.labelStyle}
          customTextStyle={props.contentStyle}
          labelContainerStyle={props.labelContainerStyle}
        />
      );
    case "TextView":
      return (
        <Textview
          {...props}
          mode={props.mode}
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : ""}
          name={props.fieldKey}
          labelStyle={props.labelStyle}
          customTextStyle={props.textViewcustomTextStyle}
          labelContainerStyle={props.labelContainerStyle}
          applyDefaultClass={!props.isApplyCustomTextStyle}
        />
      );
    case "ScopeAndSequence":
    case "Nestedview":
      return (
        <BenchmarkView
          {...props}
          mode={props.mode}
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : []}
          name={props.fieldKey}
        />
      );

    case "AudioRecorder":
      return (
        <AudioRecorderSelector
          {...props}
          mode={props.mode}
          label={props.label}
          subText={props.subText}
          value={props.value ? props.value : {}}
          name={props.fieldKey}
          labelStyle={props.labelStyle}
          labelContainerStyle={{
            marginBottom: 16,
            ...props.labelContainerStyle,
          }}
          parentId={props.parentId}
          parentType={props.parentType}
          {...audioRecorderSelectorStyles}
        />
      );

    case "SearchBoxWithTagQuery":
      return (
        <SearchBoxWithTagQuery
          {...props}
          label={props.label}
          subText={props.subText}
          options={props.options}
          value={props.value}
          name={props.fieldKey}
          updateInputField={props.updateInputField}
        />
      );
    case "ResourceList":
      return (
        <OrganizationResourcesSelection
          {...props}
          mode={props.mode}
          label={props.label}
          subText={props.subText}
          cardType={
            props.showAttachmentItemV3 ? "horizontalV3" : props.cardType
          }
          showAttachmentItemV3={props.showAttachmentItemV3}
          horizontalCardStyle={props.resourceContainerStyle}
        />
      );
    case "GroupedConceptsView":
      return <GroupedKeyConceptsView {...props} />;
    case "SubjectGroupSelect":
      return <SubjectGroupSelect {...props} />;
    case "SubjectGroupSubjectView":
      return <SubjectGroupWithSubjectView {...props} />;
    case "SubjectGroupSubjectViewOnly":
      return <SubjectGroupWithSubjectViewOnly {...props} />;
    case "StudentTemplate":
      return <StudentTemplates {...props} />;
    case "ToggleSwitch":
      return <ToggleSwitchInput {...props} />;
    case "ListModal":
      return (
        <ListModal
          {...props}
          value={props.value}
          options={props.options}
          label={props.label}
          editLabel={props.editLabel}
          modalTitleLabel={props.modalTitleLabel}
          modalSearchPlaceholder={props.modalSearchPlaceholder}
          updateInputField={props.updateInputField}
          fieldTypeConfig={props.fieldTypeConfig}
        />
      );
    case "MaxScoreTool": {
      const scorePermission = getCurriculumBasedScorePermissionMemoize({
        curriculumType: props.curriculumType,
      });
      return ACLStore.can(scorePermission) ? (
        <MaxScoreTool
          {...props}
          value={props.value}
          updateInputField={props.updateInputField}
          dependentFieldValue={props.dependentFieldValue}
          parentId={props.parentId}
        />
      ) : null;
    }
    case "TOKObject":
      return (
        <TOKObject
          {...props}
          value={props.value}
          label={props.label}
          editLabel={props.editLabel}
          modalTitleLabel={props.modalTitleLabel}
          modalTitleEditLabel={props.modalTitleEditLabel}
          updateInputField={props.updateInputField}
        />
      );
    case "MaxScoreToolView":
      return <MaxScoreView value={props.value} />;
    case "CriteriaRubricAssessment":
      return (
        <CriteriaRubricAssessmentWithComments
          {...props}
          onSelectMark={props.onSelectRubricAssessmentCriteriaRating}
        />
      );

    case "SubmissionItem":
      return (
        <SubmissionItem
          {...props}
          labelContainerStyle={{
            ...styles.submissionItemLabelStyle,
            ...props.labelContainerStyle,
          }}
        />
      );

    case "SubmissionTable":
      return <SubmissionTable {...props} />;

    case "LineSeparator":
      return <LineSeparator {...props} />;

    default:
      return null;
  }
};
class FieldComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fieldRef = null;
    this.reflectionRef = null;
    this.timer = null;
    this.otherField = {};
  }

  startTimer = () => {
    const { isModalField, shouldBlurOnInActive } = this.props;
    const { isReflection } = this.otherField;
    let inActiveTimeout = TEXT_FIELD_TIMEOUT;
    if (isReflection || isModalField) {
      inActiveTimeout = MODAL_FIELD_TIMEOUT;
    }

    if (shouldBlurOnInActive) {
      this.timer = setTimeout(this.onTimeout, inActiveTimeout * 60 * 1000);
    }
  };

  onTimeout = () => {
    const { isReflection } = this.otherField;

    if (isReflection) {
      if (this.reflectionRef) {
        this.reflectionRef.blur({ field: this.otherField });
      }
    } else {
      if (this.fieldRef) {
        this.fieldRef.blur({ field: this.otherField });
      }
    }
  };

  stopTimer = () => {
    clearTimeout(this.timer);
  };

  restartTimer = () => {
    if (this.timer) {
      this.stopTimer();
      this.startTimer();
    }
  };

  updateInputFieldLocally = value => {
    this.restartTimer();
  };

  updateInputField = (value, extraParams, isWarningReq = true) => {
    const {
      isRequired = false,
      updateInputField,
      hasAddRemoveValues,
    } = this.props;
    updateInputField({
      params: value,
      isMutation: !isRequired,
      isDebounce: !hasAddRemoveValues,
      extraParams,
      hasAddRemoveValues,
      isWarningReq,
    });
    this.restartTimer();
  };

  onBlurInputField = ({ params = {}, others = {} } = {}) => {
    const {
      onBlurInputField,
      fieldKey,
      isRequired = false,
      updateInputField,
      hasAddRemoveValues,
    } = this.props;
    const value = params[fieldKey];
    if (isRequired && !!value) {
      updateInputField({
        params,
        isMutation: true,
        isDebounce: false,
        hasAddRemoveValues,
      });
    }
    if (onBlurInputField) {
      onBlurInputField({ key: fieldKey, id: _.get(others, "id", null) });
    }
    this.stopTimer();
  };

  onFocusInputField = ({ others = {} } = {}) => {
    const { fieldKey, onFocusInputField } = this.props;

    const id = _.get(others, "id", null);
    if (onFocusInputField) {
      onFocusInputField({ key: fieldKey, id });
    }
    this.otherField = {
      id,
      uid: fieldKey,
      isReflection: _.includes(id, "Reflection"),
    };
    this.startTimer();
  };

  setFieldRef = ref => {
    const { customRef, fieldKey } = this.props;
    this.fieldRef = ref;
    if (customRef) {
      customRef(fieldKey, ref);
    }
  };

  setReflectionRefs = ref => {
    const { reflectionRefs, fieldKey } = this.props;
    this.reflectionRef = ref;
    if (reflectionRefs) {
      reflectionRefs(fieldKey, ref);
    }
  };

  render() {
    const { props } = this;
    const {
      mode,
      defaultOptions,
      getOptions,
      fieldKey,
      fieldId,
      createAttachment,
      deleteAttachment,
      createResponse,
      deleteResponse,
      fieldTypeConfig,
      portalType,
      contentStyle,
      customTextStyle,
      assessmentToolsConStyle,
      showAttachmentItemV3,
      resourceContainerStyle,
      studentTemplateCardStyle,
      horizontalAssessmentToolCardStyle,
      textViewcustomTextStyle,
      isApplyTextViewcustomTextStyle,
      isLocked,
      placeholder,
      disabled,
      t,
    } = this.props;
    let { styles = {} } = this.props;

    if (mode == "view") {
      styles = { ...defaultViewStyle, ...styles };
    } else {
      styles = { ...defaultEditStyle, ...styles };
    }

    const typeComponent = (
      <TypeComponent
        {..._.omit(props, props.type != "IBReflection" ? ["responses"] : [])}
        options={
          getOptions
            ? getOptions({
                fieldKey,
                defaultOptions,
              })
            : defaultOptions || []
        }
        createAttachment={attachment =>
          createAttachment({
            attachment,
            fieldId,
          })
        }
        deleteAttachment={attachmentId =>
          deleteAttachment({
            fieldId,
            attachmentId,
          })
        }
        name={props.fieldKey}
        isLocked={isLocked}
        customRef={this.setFieldRef}
        labelStyle={styles.labelStyle}
        labelContainerStyle={styles.labelContainerStyle}
        updateInputFieldLocally={this.updateInputFieldLocally}
        updateInputField={this.updateInputField}
        onBlurInputField={this.onBlurInputField}
        onFocusInputField={this.onFocusInputField}
        portalType={portalType}
        contentStyle={contentStyle}
        textViewcustomTextStyle={textViewcustomTextStyle}
        {...fieldTypeConfig}
        assessmentToolsConStyle={assessmentToolsConStyle}
        showAttachmentItemV3={showAttachmentItemV3}
        studentTemplateCardStyle={studentTemplateCardStyle}
        resourceContainerStyle={resourceContainerStyle}
        horizontalAssessmentToolCardStyle={horizontalAssessmentToolCardStyle}
        isApplyCustomTextStyle={isApplyTextViewcustomTextStyle}
        placeholder={disabled ? t("common:not_added_yet") : placeholder}
      />
    );

    return (
      <React.Fragment>
        {typeComponent}
        {props.showReflection && (
          <ResponsesComponent
            mode={props.mode}
            fieldId={props.fieldId}
            parentId={props.parentId}
            coachMarkId={`COACHMARKS_REFLECTIONS_${fieldId}`}
            customRef={this.setReflectionRefs}
            options={JSON.stringify(defaultOptions)}
            {...fieldTypeConfig}
            createResponse={createResponse}
            deleteResponse={deleteResponse}
            fieldKey={props.fieldKey}
            isReflectionField={props.isReflectionField}
            name={props.fieldKey}
            unitPlanCourses={props.unitPlanCourses}
            responses={props.responses}
            fieldLockedObject={props.fieldLockedObject}
            onFocusInputField={this.onFocusInputField}
            updateInputFieldLocally={this.updateInputFieldLocally}
            onBlurInputField={this.onBlurInputField}
            onMediaClick={props.onMediaClick}
            curriculumType={props.curriculumType}
          />
        )}
      </React.Fragment>
    );
  }
}

FieldComponent.defaultProps = {
  mode: "edit",
  isModalField: false,
  shouldBlurOnInActive: true,
  readOnly: false,
  assessmentToolsConStyle: {},
};
export default compose(I18nHOC({ resource: ["common"] }))(FieldComponent);
