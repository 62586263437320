import React, { useRef, useEffect } from "react";
import classes from "./ListItem.scss";
import {
  DotsHorizontalOutlined,
  TickCircleFilled,
} from "@toddle-design/web-icons";
import { useI18n } from "Hooks";
import { ButtonDropdown } from "UIComponents";
import { TextAreaInput } from "@toddle-design/web";
import { colors } from "@toddle-design/theme";

const UnfocusedItemStyle = {
  cursor: "pointer",
  backgroundColor: colors.white,
  caretColor: "transparent",
};

const ListItem = ({
  item,
  onActionClick,
  updateItem,
  removeFocuedItem,
  isFocused,
}) => {
  const { t } = useI18n(["common"]);
  const inputRef = useRef();

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const focusedItemStyle = isFocused ? {} : UnfocusedItemStyle;

  const onTextAreaClick = e => {
    if (isFocused) e.stopPropagation();
  };

  return (
    <div
      className={classes.checklistItemContainer}
      onClick={() => updateItem({ id: item.id, type: "SELECTION" })}
    >
      <div className={"text-body-1"} onClick={e => onTextAreaClick(e)}>
        <TextAreaInput
          name={item.id}
          value={item.value}
          minRows="1"
          style={{ padding: "0px", border: "0px", ...focusedItemStyle }}
          placeholder={t("common:typeHere")}
          onBlur={removeFocuedItem}
          ref={inputRef}
          onChange={e => {
            updateItem({ id: item.id, type: "LABEL", label: e.target.value });
          }}
        />
      </div>
      <div className={classes.rightContainer}>
        <div
          onClick={e => e.stopPropagation()}
          className={classes.actionButton}
        >
          <ButtonDropdown
            authTabs={[
              {
                label: t("common:edit"),
                value: "EDIT",
              },
              {
                label: t("common:delete"),
                value: "DELETE",
              },
            ]}
            containerStyle={{ top: "20px" }}
            onItemClick={value => onActionClick({ value, id: item.id })}
            buttonComponent={<DotsHorizontalOutlined />}
          />
        </div>
        <div className={classes.iconContainer}>
          {item.isSelected ? (
            <TickCircleFilled variant={"link"} />
          ) : (
            <div className={classes.unselectedComponent} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
