import React, { Component } from "react";
import classes from "./ProfileAvtar.scss";
import PropTypes from "prop-types";
import { getRethumbUrl } from "services";
import { colorPalette } from "Constants";
import classNames from "classnames";

class ProfileAvtar extends React.Component {
  render() {
    const { name, index, showCount, count, isArchived } = this.props;
    const profileText = name.charAt(0).toUpperCase() + name.charAt(1);
    const colorIndex = parseInt(index) % colorPalette.length;
    const profileColor = colorPalette[colorIndex];

    const containerClasses = classNames({
      [classes.profileIcon]: true,
      [classes.archived]: isArchived,
    });

    return (
      <div
        className={containerClasses}
        style={{
          width: this.props.size,
          height: this.props.size,
          borderRadius: this.props.size / 2,
          backgroundColor: !this.props.profileImage && profileColor,
          backgroundImage:
            this.props.profileImage &&
            `url(${getRethumbUrl(
              this.props.size,
              this.props.size,
              this.props.profileImage,
              1,
              1
            )})`,
        }}
      >
        {!this.props.profileImage && (
          <div
            className={classes.profileText}
            style={{
              fontSize: this.props.fontSize
                ? this.props.fontSize
                : this.props.size / 2,
            }}
          >{`${profileText}`}</div>
        )}
        {showCount && count > 0 && (
          <div className={classes.countCon}>{count}</div>
        )}
      </div>
    );
  }
}

ProfileAvtar.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string,
  profileImage: PropTypes.string,
  showCount: PropTypes.bool,
  count: PropTypes.number,
  isArchived: PropTypes.bool,
};

ProfileAvtar.defaultProps = {
  size: 36,
  name: "",
  index: 0,
  fontSize: 14,
  showCount: false,
  count: 0,
  isArchived: false,
};

export default ProfileAvtar;
