import gql from "graphql-tag";
import {
  gradeFragment,
  subjectFragment,
  curriculumProgramFragment,
} from "modules/CommonFragments";

export const userMapFragments = {
  userMapItem: gql`
    fragment userMapItem on CourseUserMapConnection {
      edges {
        node {
          id
          user {
            ... on Student {
              id
              firstName
              lastName
              isArchived
            }
          }
          tags {
            id
            label
          }
        }
      }
    }
  `,
};

export const fragments = {
  courseItem: gql`
    fragment courseItem on Course {
      id
      title
      profileImage
      owner {
        id
        firstName
        lastName
        email
      }
      isArchived
      academicYears {
        id
        startDate
        endDate
      }
      genericTags {
        id
        label
        type
      }
      students: userMap(filters: { archivalState: ALL, userTypes: [student] }) {
        ...userMapItem
      }
      posts: allPosts {
        totalCount
      }
      grades {
        ...gradeItem
      }
      subjects {
        ...subjectWithSubjectGroupItem
      }
      subjectGroups {
        id
        name
      }
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      collaborator: allCollaborators(filters: { archivalState: ALL }) {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
            isArchived
          }
        }
      }
    }
    ${gradeFragment.basicGradeDetails}
    ${subjectFragment.subjectWithSubjectGroup}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${userMapFragments.userMapItem}
  `,
  courseDetails: gql`
    fragment courseItemDetail on Course {
      id
      title
      grades {
        id
        name
      }
      profileImage
      subjects {
        ...subjectWithSubjectGroupItem
      }
    }
    ${subjectFragment.subjectWithSubjectGroup}
  `,
  postAnalyticsItem: gql`
    fragment courseItem on Course {
      id
      title
      profileImage
      collaborator {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
};

export const organizationCourseFeedFragment = {
  organizationCourseFeedEdge: gql`
    fragment organizationCourseFeedEdgeItem on Organization {
      id
      courses(
        first: 20
        after: $after
        filters: $filters
        orderBy: UPDATED_AT
        orderByDirection: DESC
      ) {
        totalCount
        edges {
          node {
            ...courseItem
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    ${fragments.courseItem}
  `,
};

// TODO: Added this fragment temporarily. Used in
// getOrganizationGradesQuery in Course setup
export const gradeWithCurriculumFragment = gql`
  fragment gradeItem on Grade {
    id
    name
    curriculumProgram {
      id
      type
    }
  }
`;

// TODO: Added this fragment temporarily. Used in
// getOrganizationGradesQuery in Course setup
export const organizationGradesFragment = gql`
  fragment organizationItem on Organization {
    id
    grades(filters: $filters) {
      ...gradeItem
    }
  }
  ${gradeWithCurriculumFragment}
`;
