import React, { useLayoutEffect } from "react";
import { getAssessmentToolNodeDetailsQuery } from "modules/CommonQuery";
import { getAssessmentToolNodeDetailsFromCache } from "modules/CommonGraphqlHelpers";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC } from "UIComponents";
import classes from "./SelectEditTemplate.scss";

const SelectTemplate = props => {
  const {
    t,
    renderTool,
    assessmentTool,
    updateCurrentStep,
    onCopyTool,
    updateFooterDetails,
    toggleAssessmentToolModal,
    updateHeaderDetails,
  } = props;

  const handleEditTemplateClick = () => {
    updateCurrentStep("EDIT_TEMPLATE");
    updateFooterDetails({
      showButton1: false,
      showButton2: false,
      showButton3: true,
      button3Label: t("common:done"),
      button3Variant: "progressive",
      button3ClickAction: () => {
        updateCurrentStep("SELECT_TEMPLATE");
      },
    });
    updateHeaderDetails({
      showCloseButton: false,
    });
  };

  const handleUseTemplateClick = () => {
    const toolId = _.get(assessmentTool, "id");
    onCopyTool(toolId);
    toggleAssessmentToolModal();
  };

  const title = _.get(assessmentTool, "title", "");
  const templateName = !_.isEmpty(title) ? title : t("common:untitled");

  useLayoutEffect(() => {
    updateHeaderDetails({
      title: templateName,
      showBackButton: true,
      showCloseButton: true,
      backButtonClickAction: () => updateCurrentStep("TEMPLATE_LIST"),
    });

    updateFooterDetails({
      showFooter: true,
      showButton1: true,
      showButton2: false,
      showButton3: true,
      button1Variant: "inline-progressive",
      button1Label: t("academicSetup:edit_template"),
      button1ClickAction: handleEditTemplateClick,
      button3Label: t("classRoom:use_template"),
      button3Variant: "primary",
      button3ClickAction: handleUseTemplateClick,
    });
  }, []);

  return (
    <div className={classes.container}>
      {renderTool({ mode: "view", assessmentTool })}
    </div>
  );
};

const EditTemplate = props => {
  const {
    t,
    renderTool,
    assessmentTool,
    updateCurrentStep,
    updateHeaderDetails,
  } = props;

  const title = _.get(assessmentTool, "title", "");
  const templateName = !_.isEmpty(title) ? title : t("common:untitled");

  useLayoutEffect(() => {
    updateHeaderDetails({
      title: templateName,
      showBackButton: true,
      backButtonClickAction: () => updateCurrentStep("SELECT_TEMPLATE"),
    });
  }, []);

  return (
    <div className={classes.container}>{renderTool({ assessmentTool })}</div>
  );
};

const SelectEditTemplate = props => {
  const { currentStep } = props;

  switch (currentStep) {
    case "SELECT_TEMPLATE": {
      return <SelectTemplate {...props} />;
    }
    case "EDIT_TEMPLATE": {
      return <EditTemplate {...props} />;
    }
  }
};

export default compose(
  I18nHOC([{ resource: ["common", "academicSetup"] }]),
  graphql(getAssessmentToolNodeDetailsQuery, {
    name: "getAssessmentToolNodeDetails",
    options: ({ toolId, type }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: toolId, type },
    }),
    props({
      getAssessmentToolNodeDetails,
      ownProps: { isData, isLoading, toolId, type },
    }) {
      const queryData = getAssessmentToolNodeDetailsFromCache({
        id: toolId,
        type,
      });
      const networkStatus = _.get(
        getAssessmentToolNodeDetails,
        "networkStatus"
      );
      return {
        assessmentTool: queryData,
        isData: !_.isEmpty(queryData) && isData,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  }),
  withLoader
)(SelectEditTemplate);
