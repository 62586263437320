/**--external-- */
import React, { useState, useRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { Button } from "@toddle-design/web";
import { AddOutlined, DeleteOutlined } from "@toddle-design/web-icons";
import propTypes from "prop-types";

/**--internal-- */
import { I18nHOC } from "UIComponents";

import ACLStore from "lib/aclStore";
import { ENABLE_MULTIPLE_CURRICULUM_PROGRAMS } from "Constants/permissionConstants";

/**--relative-- */
import CurriculumOptions from "../CurriculumOptions";
import classes from "./Step3.scss";
import {
  CURRICULUM_PROGRAMS,
  getAvailableCurriculumProgramTypes,
} from "./Step3Utils";

const TOTAL_REQUIRED_FIELDS = 6;

const Step3 = props => {
  const {
    t,
    curriculumPrograms,
    toddleBuddies,
    updateCurriculumProgramInputRefs,
    mode,
    customRef,
    addCurriculumProgram,
  } = props;

  const curriculumProgramNodes = useRef([]);

  const updateCurriculumProgramNodes = (ref, index) => {
    curriculumProgramNodes.current[index] = ref;
  };

  useImperativeHandle(customRef, () => {
    return {
      isValid: () => {
        const totalErrors = _.reduce(
          curriculumProgramNodes.current ?? [],
          (result, node) => {
            return result + (node?.isValid?.() ?? 0);
          },
          0
        );

        return totalErrors;
      },
    };
  });

  const [curriculumProgramOptions, setCurriculumProgramOptions] = useState(() =>
    getAvailableCurriculumProgramTypes({ curriculumPrograms })
  );

  const allowMultipleCurriculumPrograms = ACLStore.can(
    ENABLE_MULTIPLE_CURRICULUM_PROGRAMS
  );

  /**
   * While deleting curriculum program, add it's type in
   * curriculumOptions
   */
  const deleteCurriculumProgram = (payload, curriculumProgramIndex) => {
    if (!allowMultipleCurriculumPrograms) {
      const currentCurriculumProgram =
        curriculumPrograms[curriculumProgramIndex];

      const { type } = currentCurriculumProgram;

      const curriculumProgramTypeObject = _.find(
        CURRICULUM_PROGRAMS,
        ({ value }) => value == type
      );

      if (!_.isEmpty(curriculumProgramTypeObject)) {
        setCurriculumProgramOptions(curriculumProgramOptions => [
          ...curriculumProgramOptions,
          curriculumProgramTypeObject,
        ]);
      }
    }

    props.deleteCurriculumProgram(payload);
  };

  /**
   * While curriculum program is updated, if it's type is updated, then
   * remove new type from curriculumOptions and add old type in curriculumOptions
   */
  const updateCurriculumProgram = (payload, curriculumProgramIndex) => {
    if (!allowMultipleCurriculumPrograms) {
      const newCurriculumProgramType = payload.type;

      if (newCurriculumProgramType) {
        const currentCurriculumProgram =
          curriculumPrograms[curriculumProgramIndex];

        const { type: oldCurriculumProgramType } = currentCurriculumProgram;

        const curriculumProgramTypeObject = _.find(
          CURRICULUM_PROGRAMS,
          ({ value }) => value == oldCurriculumProgramType
        );

        const updatedCurriculumProgramOptions = _.filter(
          curriculumProgramOptions,
          ({ value }) => value !== newCurriculumProgramType
        );

        if (!_.isEmpty(curriculumProgramTypeObject)) {
          updatedCurriculumProgramOptions.push(curriculumProgramTypeObject);
        }

        setCurriculumProgramOptions(updatedCurriculumProgramOptions);
      }
    }
    props.updateCurriculumProgram(payload);
  };

  const getCurriculumProgramOptions = ({ type }) => {
    if (_.isEmpty(type)) {
      return curriculumProgramOptions;
    }

    const curriculumProgramTypeObject = _.find(
      CURRICULUM_PROGRAMS,
      ({ value: curriculumProgramType }) => type === curriculumProgramType
    );

    return _.uniq([...curriculumProgramOptions, curriculumProgramTypeObject]);
  };

  const titleClasses = classNames("heading-3", classes.title);

  const descriptionClasses = classNames("text-body-2", classes.description);

  const curriculumLabelClasses = classNames(
    "text-label-1",
    classes.curriculumLabel
  );

  const getDeleteButtonVisibilityStatus = ({ index }) => {
    if (mode === "edit") {
      return true;
    }

    return index !== totalCurriculumPrograms - 1;
  };

  const toddleBuddiesOptions = _.map(
    toddleBuddies,
    ({ firstName, lastName, id }) => ({
      label: `${firstName} ${lastName}`,
      value: id,
    })
  );

  const totalCurriculumPrograms = _.size(curriculumPrograms);

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <div className={classes.headerContent}>
          <h3 className={titleClasses}>
            {t("onBoarding:add_programme_details")}
          </h3>
          <p className={descriptionClasses}>
            {t("onBoarding:add_programme_description")}
          </p>
        </div>
        <Button
          variant={"outlined"}
          size="medium"
          disabled={
            allowMultipleCurriculumPrograms
              ? false
              : _.size(curriculumPrograms) >= 4
          }
          onClick={addCurriculumProgram}
          icon={<AddOutlined size={"xx-small"} />}
        >
          {t("common:add_with_label", { label: t("common:programme") })}
        </Button>
      </header>
      <main className={classes.curriculumContainer}>
        {_.map(curriculumPrograms, (curriculumProgram, index) => {
          const onDeleteButtonClick = () => {
            deleteCurriculumProgram({ id: curriculumProgram.id }, index);
          };

          return (
            <div
              key={curriculumProgram.id}
              className={classes.curriculumOptions}
            >
              <div className={classes.curriculumOptionsHeader}>
                <p className={curriculumLabelClasses}>
                  {t("onBoarding:programme_with_number", {
                    number: totalCurriculumPrograms - index,
                  })}
                </p>
                {getDeleteButtonVisibilityStatus({ index }) && (
                  <Button
                    onClick={onDeleteButtonClick}
                    icon={<DeleteOutlined size="xx-small" />}
                    size="small"
                    variant="plain"
                  >
                    {t("common:delete")}
                  </Button>
                )}
              </div>
              <CurriculumOptions
                curriculumProgram={curriculumProgram}
                updateCurriculumProgram={payload =>
                  updateCurriculumProgram(payload, index)
                }
                curriculumProgramOptions={getCurriculumProgramOptions({
                  type: curriculumProgram.type,
                })}
                toddleBuddiesOptions={toddleBuddiesOptions}
                curriculumProgramIndex={index * TOTAL_REQUIRED_FIELDS}
                updateCurriculumProgramInputRefs={
                  updateCurriculumProgramInputRefs
                }
                customRef={ref => updateCurriculumProgramNodes(ref, index)}
                mode={mode}
              />
            </div>
          );
        })}
      </main>
    </div>
  );
};

Step3.propTypes = {
  t: propTypes.func,
  curriculumPrograms: propTypes.array,
  toddleBuddies: propTypes.array,
  updateCurriculumProgramInputRefs: propTypes.func,
  mode: propTypes.bool,
  customRef: propTypes.object,
};

export default I18nHOC({ resource: ["onBoarding", "common"] })(Step3);
