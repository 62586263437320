import gql from "graphql-tag";
import { platformCalendarEventFragments } from "./MyCalendarFragments";

export const createPlatformCalendarEventMutation = gql`
  mutation createPlatformCalendarEvent(
    $label: String!
    $startAt: String
    $description: String
    $durationInMin: Int
    $recurrenceSetting: RecurrenceSettingInput
    $meeting: MeetingInput
    $courseIds: [ID!]
    $userIds: [ID!]
  ) {
    platform {
      createPlatformCalendarEvent(
        input: {
          label: $label
          startAt: $startAt
          description: $description
          durationInMin: $durationInMin
          recurrenceSetting: $recurrenceSetting
          meeting: $meeting
          courseIds: $courseIds
          userIds: $userIds
        }
      ) {
        response {
          ...platformCalendarEventItem
        }
        warning
      }
    }
  }
  ${platformCalendarEventFragments.platformCalendarEventDetails}
`;

export const deletePlatformCalendarEventMutation = gql`
  mutation deletePlatformCalendarEvent(
    $id: ID!
    $actionFor: CALENDAR_EVENT_ACTION_FOR
  ) {
    platform {
      deletePlatformCalendarEvent(input: { id: $id, actionFor: $actionFor }) {
        response
      }
    }
  }
`;

export const editPlatformCalendarEventMutation = gql`
  mutation editPlatformCalendarEvent(
    $id: ID!
    $label: String!
    $startAt: String
    $description: String
    $durationInMin: Int
    $recurrenceSetting: RecurrenceSettingInput
    $meeting: MeetingInput
    $removedCourseIds: [ID!]
    $addedCourseIds: [ID!]
    $removedUserIds: [ID!]
    $addedUserIds: [ID!]
    $actionFor: CALENDAR_EVENT_ACTION_FOR
  ) {
    platform {
      editPlatformCalendarEvent(
        input: {
          id: $id
          label: $label
          startAt: $startAt
          description: $description
          durationInMin: $durationInMin
          recurrenceSetting: $recurrenceSetting
          meeting: $meeting
          removedCourseIds: $removedCourseIds
          addedCourseIds: $addedCourseIds
          removedUserIds: $removedUserIds
          addedUserIds: $addedUserIds
          actionFor: $actionFor
        }
      ) {
        response {
          ...platformCalendarEventItem
        }
        warning
      }
    }
  }
  ${platformCalendarEventFragments.platformCalendarEventDetails}
`;

export const updatePlatformCalendarEventBasicDetailsMutation = gql`
  mutation updatePlatformCalendarEventBasicDetails(
    $id: ID!
    $startAt: String
    $durationInMin: Int
    $itemType: CALENDAR_EVENT_ITEM_TYPE_ENUM!
  ) {
    platform {
      updatePlatformCalendarEventBasicDetails(
        input: {
          id: $id
          startAt: $startAt
          durationInMin: $durationInMin
          itemType: $itemType
        }
      ) {
        response {
          ...platformCalendarEventItem
        }
        warning
      }
    }
  }
  ${platformCalendarEventFragments.platformCalendarEventDetails}
`;
