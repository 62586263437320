import React, { useState, useCallback } from "react";
import { I18nHOC, FullScreenLoader } from "UIComponents";
import PropTypes from "prop-types";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import TagPractices from "./TagPractices";
import EvaluationCycles from "./EvaluationCycles";
import { getSnPEvaluationCyclesFromCache } from "SnP_v2/modules/SnPGraphqlHelpers";
import { getSnpV2Cycles } from "SnP_v2/modules/SnPUtils";
import { tagPractices } from "SnP_v2/modules/SnPModule";
import classes from "./TagSnp.scss";
import ACLStore from "lib/aclStore";
import { CloseOutlined } from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";

import {
  getCycleIdToSetIdMappingMemoized,
  getPracticesForCycleMemoized,
  getAddedPracticesMemoized,
  getRemovedPracticesMemoized,
  getCycleIdToSelectedPracticesCountMappingMemoized,
  getApprovedPracticesForCycle,
  getPracticeWrappersForCycle,
} from "./TagSnpUtils";

/**
 * @params
 *  cycles - list of evaluation cycles corresponding to current curriculum program
 *  tagPracticeMutation - mutation handler for tagging practice with evidence(item)
 *  itemId - id of evidence
 *  closeTagSnP - To close Parent Container of TagSnP
 *  updateTaggedPractices - Function for updating Tagged Practice State outside TagSnP
 *  taggedPractices - data structure having info of previously selected practices
 */

/**data structure info: */
/**taggedPractices -
 * [{evaluationCycle-Object,
 *   taggedPractice:[{
 *                      taggedBy:{id},
 *                      practice:{id},
 *                      evidenceStatus:String}]
 * }] */

const TagSnp = props => {
  const {
    cycles,
    t,
    tagPractices,
    itemId,
    itemType,
    itemGroupType,
    itemTitle,
    thumbnailData,
    closeModal,
    taggedPractices,
    steps,
    parentCycleId,
    parentSetId,
    userId,
    showApprovedPractices,
    assignmentFeedFilters,
  } = props;

  const isAdmin = ACLStore.can("AdminPortal");

  const [currentCycle, setCurrentCycle] = useState(parentCycleId);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const cycleIdToSetIdMapping = getCycleIdToSetIdMappingMemoized({
    cycles,
  });

  const goToNextPage = () => {
    setCurrentStepIndex(currentStepIndex => currentStepIndex + 1);
  };

  const goToPreviousPage = () => {
    setCurrentStepIndex(currentStepIndex => currentStepIndex - 1);
  };

  const updateCurrentCycle = useCallback(({ id }) => {
    setCurrentCycle(id);
    goToNextPage();
  }, []);

  const practiceWrappers = getPracticeWrappersForCycle({
    taggedPractices,
    cycleId: currentCycle,
  });

  const {
    taggedPracticesForCycle,
    disabledPracticesForCycle,
  } = getPracticesForCycleMemoized({
    practiceWrappers,
    userId,
    isAdmin,
  });

  const approvedPractices = showApprovedPractices
    ? getApprovedPracticesForCycle({
        practiceWrappers,
      })
    : [];

  const cycleIdToTaggedPracticesCountMapping = getCycleIdToSelectedPracticesCountMappingMemoized(
    { cycles, taggedPractices }
  );

  const updateTaggedPractices = async ({ selectedPractices }) => {
    setIsLoading(true);

    try {
      await tagPractices({
        evaluationCycleId: currentCycle,
        addedPractices: getAddedPracticesMemoized({
          selectedPractices,
          taggedPractices: taggedPracticesForCycle,
        }),

        removedPractices: getRemovedPracticesMemoized({
          selectedPractices,
          taggedPractices: taggedPracticesForCycle,
        }),
        itemId,
        itemType: itemGroupType ? itemGroupType : itemType,
        assignmentFeedFilters,
      });
      setIsLoading(false);
      closeModal();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleTaggedPractices = data => {
    if (props.updateTaggedPractices) {
      const selectedPractices = _.get(data, "selectedPractices", []);

      const addedPractices = getAddedPracticesMemoized({
        selectedPractices,
        taggedPractices: taggedPracticesForCycle,
      });

      const removedPractices = getRemovedPracticesMemoized({
        selectedPractices,
        taggedPractices: taggedPracticesForCycle,
      });

      props.updateTaggedPractices({
        evaluationCycle: { id: currentCycle },
        addedPractices,
        removedPractices,
      });
      closeModal();
    } else updateTaggedPractices(data);
  };

  const closeButtonElement = (
    <IconButton
      onClick={closeModal}
      icon={<CloseOutlined />}
      variant={"plain"}
      size={"large"}
    />
  );

  const currentCycleDetails = _.find(cycles, ["id", currentCycle]) ?? "";

  const { title: cycleName = "", cycleType = "" } = currentCycleDetails;

  const practiceListsCollection = {
    approvedPractices,
    taggedPractices: taggedPracticesForCycle,
    disabledPractices: disabledPracticesForCycle,
  };

  const getCurrentScreen = () => {
    const currentStep = steps[currentStepIndex];
    switch (currentStep) {
      case "CYCLE_LIST": {
        return (
          <EvaluationCycles
            updateCurrentCycle={updateCurrentCycle}
            cycles={cycles}
            closeButtonElement={closeButtonElement}
            cycleIdToTaggedPracticesCountMapping={
              cycleIdToTaggedPracticesCountMapping
            }
            t={t}
          />
        );
      }
      case "STANDARD_LIST": {
        return (
          <TagPractices
            itemType={itemType}
            itemTitle={itemTitle}
            setId={cycleIdToSetIdMapping[currentCycle] ?? parentSetId}
            goToPreviousPage={goToPreviousPage}
            cycleDetails={{ cycleName, cycleType }}
            thumbnailData={thumbnailData}
            closeButtonElement={closeButtonElement}
            handleTaggedPractices={handleTaggedPractices}
            practiceListsCollection={practiceListsCollection}
            showBackButton={_.size(cycles) > 1}
            t={t}
          />
        );
      }
    }
  };

  return (
    <div className={classes.container}>
      {getCurrentScreen()}

      {isLoading ? <FullScreenLoader /> : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  const curriculumProgramId = ownProps.curriculumProgramId;
  const userId = _.get(state, "login.userInfo.id", "");

  const data = getSnPEvaluationCyclesFromCache({
    id: organizationId,
    filters: {
      isArchived: false,
      curriculumProgramId,
    },
  });
  const cycles = _.get(data, "snpEvaluationCycles", []);

  const newSnpCycles = getSnpV2Cycles({ cycles });

  return {
    cycles: newSnpCycles,
    userId,
  };
};

const mapActionCreators = {
  tagPractices,
};

export default compose(
  I18nHOC({ resources: ["snpTemplate", "snp", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(TagSnp);

TagSnp.defaultProps = {
  taggedPractices: [],
  showApprovedPractices: true,
  handleTaggingExternally: false,
  parentCycleId: "",
  parentSetId: "",
  itemGroupType: "",
  steps: ["CYCLE_LIST", "STANDARD_LIST"],
};

TagSnp.propTypes = {
  itemGroupType: PropTypes.string,
  showApprovedPractices: PropTypes.bool,
};
