import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import classes from "./Checkbox.scss";
import { CheckboxOn } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";

class Checkbox extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      isChecked,
      label,
      isDisabled,
      checkBoxParentContainer,
      checkboxStyle,
      fill,
      style,
      offFill,
      labelStyles,
      onCheckboxClick,
      checkboxItemStyle,
    } = this.props;
    let checkboxContainer = classNames(
      { [classes.checkboxContainer]: true },
      { [classes.checkboxContainerActive]: isChecked }
    );
    let checkboxContainerDisable = classNames(
      { [classes.checkboxContainerDisable]: true },
      { [classes.checkboxContainerDisableActive]: isChecked }
    );

    return (
      <div
        className={isDisabled ? checkboxContainerDisable : checkboxContainer}
        style={checkBoxParentContainer}
        onClick={onCheckboxClick}
      >
        <div
          style={checkboxStyle}
          className={isDisabled ? classes.disableCheckbox : classes.checkbox}
        >
          {isChecked ? (
            <div
              style={{
                backgroundColor: fill,
                opacity: isDisabled ? 0.5 : 1,
                height: style.height,
                width: style.width,
                ...checkboxItemStyle,
              }}
            >
              <CheckboxOn />
            </div>
          ) : (
            <div
              style={{
                opacity: isDisabled ? 0.5 : 1,
                height: style.height,
                width: style.width,
                border: `2px solid ${offFill}`,
                ...checkboxItemStyle,
              }}
            />
          )}
        </div>
        {!!label && (
          <div className={classes.checkboxLabel} style={labelStyles}>
            {label}
          </div>
        )}
      </div>
    );
  }
}

Checkbox.defaultProps = {
  offFill: colors.gray72,
  fill: colors.teal20,
  style: {
    height: "16px",
    width: "16px",
  },
  checkboxStyle: {},
  checkboxItemStyle: {},
  onCheckboxClick: () => {},
};

Checkbox.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.object,
};

export default Checkbox;
