import gql from "graphql-tag";

import {
  gradeFragment,
  staffFragment,
  studentFragment,
  userFragment,
  genericFolderFragment,
  settingFragment,
  subjectFragment,
  genericTagFragment,
  subjectGroupFragment,
  curriculumProgramFragment,
  academicYearFragment,
  projectGroupFragments,
} from "modules/CommonFragments";

export const courseFragment = {
  courseFeed: gql`
    fragment courseFeedItem on Course {
      id
      title
      grades {
        id
        name
        globalGrade {
          id
          uid
          name
          constants
        }
      }
      calendar {
        id
      }
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      academicYears {
        ...academicYearItem
      }
      subjects {
        id
        name
      }
      subjectGroups {
        id
        name
      }
      projectGroups {
        ...basicProjectGroupDetails
      }
      isDemo
      isRemovedCourseForLoggedInUser
      isArchived
      genericTags(filters: { types: SUBJECT_LEVEL }) {
        id
        label
      }
    }
    ${academicYearFragment.academicYear}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${projectGroupFragments.basicProjectGroupDetails}
  `,
  courseDetails: gql`
    fragment courseDetailsItem on Course {
      id
      title
      settings {
        ...settingItem
      }
      organization {
        id
      }
      collaborators: allCollaborators {
        edges {
          node {
            id
          }
        }
      }
      students: allStudents {
        edges {
          node {
            id
          }
        }
      }
      isDemo
      subjects {
        ...subjectWithSubjectGroupItem
      }
      subjectGroups {
        ...subjectGroupBasicDetailsItem
        type
      }
      grades {
        id
        name
        subjects {
          id
          name
        }
      }
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      academicYears {
        ...academicYearItem
      }
      genericTags {
        ...basicGenericTagItem
      }
      projectGroups {
        ...basicProjectGroupDetails
      }
      isArchived
      tags {
        id
        key
        value
      }
      studentDriveFiles: studentDriveV2(
        filters: { visibleTo: [STUDENT], types: STUDENT_DRIVE }
      ) {
        totalCount
      }
    }
    ${academicYearFragment.academicYear}
    ${subjectFragment.subjectWithSubjectGroup}
    ${subjectGroupFragment.subjectGroupBasicDetails}
    ${settingFragment.setting}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${genericTagFragment.genericTag}
    ${projectGroupFragments.basicProjectGroupDetails}
  `,
  courseStudentsFolders: gql`
    fragment courseItem on Course {
      id
      title
      students {
        edges {
          node {
            ...studentItem
          }
        }
      }
      genericFolders(type: $folderType) {
        ...genericFolderItem
      }
      tags {
        id
        key
        value
      }
    }
    ${studentFragment.basicStudentDetails}
    ${genericFolderFragment.genericFolderBasicDetails}
  `,
  courseUnitPlanFeedCount: gql`
    fragment courseItem on Course {
      id
      title
      isArchived
      grades {
        ...gradeItem
      }
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      calendar {
        id
      }
      isDemo
      academicYears {
        ...academicYearItem
      }
      subjectGroups {
        id
        name
      }
      projectGroups {
        ...basicProjectGroupDetails
      }
      owner {
        id
      }
      profileImage
      isRemovedCourseForLoggedInUser
      unitPlans {
        edges {
          fieldValue
          type
          node {
            id
          }
        }
        totalCount
      }
      tags {
        id
        key
        value
      }
      subjects {
        id
        name
      }
    }
    ${academicYearFragment.academicYear}
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${gradeFragment.gradeUnitDetails}
    ${projectGroupFragments.basicProjectGroupDetails}
  `,
  courseCollaborator: gql`
    fragment courseItem on Course {
      id
      collaborators: allCollaborators {
        totalCount
        edges {
          node {
            ...staffItem
          }
        }
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
  StaffCourseStudents: gql`
    fragment courseItem on Course {
      id
      title
      allStudents(filters: { searchText: $searchText }) {
        totalCount
        edges {
          node {
            ...userItem
            familyMembers {
              ...userItem
            }
          }
        }
      }
    }
    ${userFragment.basicUserDetails}
  `,
  courseBasicDetails: gql`
    fragment courseItem on Course {
      id
      title
      profileImage
      grades {
        id
      }
      students(orderBy: TITLE, orderByDirection: ASC) {
        totalCount
        edges {
          node {
            ...studentItem
          }
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
  courseGenericFoldersDetailsFragment: gql`
    fragment courseItem on Course {
      id
      genericFolders(type: $folderType) {
        ...genericFolderItem
      }
    }
    ${genericFolderFragment.genericFolderDetails}
  `,
  courseGenericFoldersBasicDetailsFragment: gql`
    fragment courseItem on Course {
      id
      genericFolders(type: $folderType) {
        ...genericFolderItem
      }
    }
    ${genericFolderFragment.genericFolderBasicDetails}
  `,
  courseUnitPlansCountFragment: gql`
    fragment courseUniPlansItem on Course {
      unitPlans {
        totalCount
      }
    }
  `,
  courseStudentFoldersCountFragment: gql`
    fragment courseStudentFoldersItem on Course {
      studentDriveV2(filters: {}) {
        resourceTypeBasedCount {
          totalCount
        }
      }
    }
  `,
  courseAssesmentEvaluationCountFragment: gql`
    fragment courseAssesmentEvaluationCountItem on Course {
      assessmentEvaluations {
        totalCount
      }
    }
  `,
};
