/** SNPv2 Feature Permissions */
export const SNPv2_PERM = "Common:SnpV2";
export const SNPv2_TEACHER_MODULE_PERM = "TeacherPortal:SnpV2";
export const SNPv2_VISITOR_MODULE_PERM = "VisitorPortal:SnpV2";
export const SNPv2_ALLOW_VISITOR_INVITE = "AdminPortal:AllowSnpVisitorInvite";
export const SNPv2_VISITOR_PORTAL_PERM_GROUP = "VisitorPortal";

export const CURRICULUM_SUBSCRIPTION_PLAN_PERM =
  "FeatureFlag:EnableCurriculumSubscriptionPlan";

export const ENABLE_MULTIPLE_CURRICULUM_PROGRAMS =
  "FeatureFlag:EnableMultipleCurriculumPrograms";

export const OPERATIONAL_DAYS_AND_TIME_ZONE_PERM =
  "AdminPortal:EnableOperationalDaysAndTimeZone";

export const ENABLE_OLD_MANAGE_ORGANIZATION_ROSTERING =
  "ToolPortal:EnableOldOrganizationRostering";

export const ENABLE_DP_ROASTER_SHEET = "FeatureFlag:DPUploadRosterAllowed";
