import { ELEMENT_TYPE_MYP_OBJECTIVES } from "Constants/stringConstants";
import { getMemoizedFunction } from "Utils";
import { tabFiltersConfig } from "../../modules/Config";
import { getFilterLabel } from "../../modules/Utils";
import { getCourseDetailFromCache } from "../../modules/GraphqlHelpers";

/**it will return all filters in which any option is selected */
const getActiveFilters = ({ appliedFilters }) => {
  const filterKeys = _.keys(appliedFilters);

  const activeFiltersKeys = _.reduce(
    filterKeys,
    (result, filterKey) => {
      switch (filterKey) {
        case "COURSE": {
          const totalSelectedOptions = _.reduce(
            appliedFilters[filterKey],
            (result, { values }) => {
              return result + _.size(values);
            },
            0
          );
          if (totalSelectedOptions) return [...result, filterKey];
          break;
        }
        default: {
          if (_.size(appliedFilters[filterKey])) {
            return [...result, filterKey];
          }
        }
      }

      return result;
    },
    []
  );

  return activeFiltersKeys;
};

const getActiveFiltersMemoized = getMemoizedFunction(getActiveFilters);

const getSelectedMypObjectivesLabels = ({ filterValues, filterOptions }) => {
  let filterValueLabels = [];

  _.forEach(filterValues, ({ key, values }) => {
    const optionsObj =
      _.find(filterOptions, ({ key: optionKey }) => optionKey == key) ?? {};

    const { label: subjectLabel, options } = optionsObj;

    if (key == "INTERDISCIPLINARY_CRITERIA") {
      const labels = _.reduce(
        options,
        (result, { label, value }) => {
          if (_.includes(values, value)) {
            return [...result, `${subjectLabel} - ${label}`];
          }
          return result;
        },
        []
      );

      filterValueLabels = [...filterValueLabels, ...labels];
    } else {
      _.forEach(values, ({ key, values }) => {
        const optionsObj =
          _.find(options, ({ key: optionKey }) => optionKey == key) ?? {};

        const { label: yearObjectiveLabel, options: objectives } = optionsObj;

        const updatedYearObjectiveLabel = _.join(
          _.slice(_.split(yearObjectiveLabel, " "), 0, 2),
          " "
        );

        const labels = _.reduce(
          objectives,
          (result, { label, value }) => {
            if (_.includes(values, value)) {
              return [
                ...result,
                `${subjectLabel} - ${updatedYearObjectiveLabel} - ${label}`,
              ];
            }
            return result;
          },
          []
        );

        filterValueLabels = [...filterValueLabels, ...labels];
      });
    }
  });

  return filterValueLabels;
};

const getSelectedMypObjectivesLabelsMemoized = getMemoizedFunction(
  getSelectedMypObjectivesLabels
);

/**it will map selected values to their corresponding labels
 * and then return an object where filter entity is key and all selected labels is value
 * Ex {COURSE:["3A","3B"],UNIT_PLAN:["React"]} */
const getActiveFilterSelectedLabels = ({
  activeFilters,
  allFiltersOptions,
  appliedFilters,
}) => {
  return _.reduce(
    activeFilters,
    (result, filterKey) => {
      const filterOptions = allFiltersOptions[filterKey];

      let filterValueLabels;

      const filterValues = appliedFilters[filterKey];

      switch (filterKey) {
        case "COURSE": {
          filterValueLabels = _.reduce(
            filterValues,
            (result, { values, key }) => {
              const selectedValuesLabels = _.map(values, value => {
                const { options } = _.find(filterOptions, ["key", key]);
                const filterValueLabel =
                  _.find(options, ["value", value])?.label ?? "";
                return filterValueLabel;
              });
              return [...result, ...selectedValuesLabels];
            },
            []
          );
          break;
        }

        case ELEMENT_TYPE_MYP_OBJECTIVES: {
          filterValueLabels = getSelectedMypObjectivesLabelsMemoized({
            filterValues,
            filterOptions,
          });

          break;
        }

        default: {
          filterValueLabels = _.map(filterValues, value => {
            const filterValueLabel =
              _.find(filterOptions, ["value", value])?.label ?? "";
            return filterValueLabel;
          });
        }
      }

      /**_.uniq is required as we need to maintain only
       * one instance of duplicate labels
       */
      result[filterKey] = _.uniq(filterValueLabels);
      return result;
    },
    {}
  );
};

const getActiveFilterSelectedLabelsMemoized = getMemoizedFunction(
  getActiveFilterSelectedLabels
);

/**It will append type of filter in label */
const getLocalizedLabel = ({
  labels,
  activeTab,
  entityType,
  t,
  curriculumType,
  plannerElementSets,
}) => {
  let label;

  if (_.size(labels) === 1) label = labels[0];
  else label = t("common:join_array_with_and", { array: labels });

  const filterData = tabFiltersConfig[activeTab].entityTypeInfo[entityType];

  const {
    feedLocale,
    feedLocaleSuffix,
    isLabelPresentInSetLabels,
  } = filterData;

  const filterTypeLabel = getFilterLabel({
    plannerElementSets,
    curriculumType,
    entityType,
    t,
    filterLocale: feedLocale,
    isLabelPresentInSetLabels,
    localeParams: {
      count: _.size(labels),
    },
  });

  const localeParams = {
    filterType: filterTypeLabel,
    filterLabel: label,
  };

  if (feedLocaleSuffix) {
    localeParams.filterSuffix = t(feedLocaleSuffix);
  }

  return t("snp:evidence_display_text", localeParams);
};

const getMoreFiltersDisplayTextList = ({
  activeFilters,
  activeFiltersSelectedValuesLabels,
  activeTab,
  t,
  curriculumType,
  plannerElementSets,
}) => {
  return _.reduce(
    activeFilters,
    (result, filterKey) => {
      const labels = activeFiltersSelectedValuesLabels[filterKey];
      const transformedLabels = _.map(labels, label =>
        getLocalizedLabel({
          labels: [label],
          activeTab,
          entityType: filterKey,
          t,
          curriculumType,
          plannerElementSets,
        })
      );
      return [...result, ...transformedLabels];
    },
    []
  );
};

/**It will create text based on
 * the relation of filter with evidence
 * Ex. label is `3A class` and filter is class.
 *  Now class and post has parent-child relationship.
 *  So, output will be `in 3A class` */
const getDisplayTextForEvidence = ({ activeTab, filter, label, t }) => {
  const entityRelationWithEvidence =
    tabFiltersConfig[activeTab].entityTypeInfo[filter].relationWithEvidence;

  switch (entityRelationWithEvidence) {
    case "parent": {
      return [t("common:in_label", { label })];
    }
    case "taggable": {
      return [t("snp:tagged_with_label", { label })];
    }
    case "category": {
      return [t("snp:for_label", { label })];
    }
  }
};

const getLocalizedTextForTwoFilters = ({
  activeTab,
  activeFilters,
  activeFiltersSelectedValuesLabels,
  curriculumType,
  plannerElementSets,
  t,
}) => {
  /**There are two kinds of relationship between filters.
   *  Either both are siblings or parent-child.
   * `order` field is used to find  correct relationship.*/
  const orderOfFirstEntity =
    tabFiltersConfig[activeTab].entityTypeInfo[activeFilters[0]].hierarchyOrder;

  const orderOfSecondEntity =
    tabFiltersConfig[activeTab].entityTypeInfo[activeFilters[1]].hierarchyOrder;

  const label1 = getLocalizedLabel({
    labels: activeFiltersSelectedValuesLabels[activeFilters[0]],
    activeTab,
    entityType: activeFilters[0],
    t,
    curriculumType,
    plannerElementSets,
  });

  const label2 = getLocalizedLabel({
    labels: activeFiltersSelectedValuesLabels[activeFilters[1]],
    activeTab,
    entityType: activeFilters[1],
    t,
    curriculumType,
    plannerElementSets,
  });

  /**if both filters are siblings, then connect corresponding labels with `and`*/
  if (orderOfFirstEntity === orderOfSecondEntity) {
    return t("common:join_array_with_and", {
      array: [label1, label2],
    });
  }
  /**else connect them with `in` */
  return t("snp:label1_in_label2", { label1, label2 });
};

const getLocalizedTextForOneSelectedOption = ({
  activeFilters,
  activeFiltersSelectedValuesLabels,
  activeTab,
  t,
  curriculumType,
  plannerElementSets,
}) => {
  const filter = activeFilters[0];
  const labels = activeFiltersSelectedValuesLabels[filter];

  const localizedLabel = getLocalizedLabel({
    labels,
    activeTab,
    entityType: activeFilters[0],
    t,
    curriculumType,
    plannerElementSets,
  });

  return getDisplayTextForEvidence({
    activeTab,
    filter,
    label: localizedLabel,
    t,
  });
};

const getLocalizedTextForTwoSelectedOptions = ({
  activeFilters,
  activeTab,
  activeFiltersSelectedValuesLabels,
  t,
  curriculumType,
  plannerElementSets,
}) => {
  let localizedLabel;
  if (_.size(activeFilters) === 1) {
    const entityType = activeFilters[0];
    const labels = activeFiltersSelectedValuesLabels[entityType];
    localizedLabel = getLocalizedLabel({
      labels,
      activeTab,
      entityType,
      t,
      curriculumType,
      plannerElementSets,
    });
  } else {
    localizedLabel = getLocalizedTextForTwoFilters({
      activeTab,
      activeFilters,
      activeFiltersSelectedValuesLabels,
      t,
      curriculumType,
      plannerElementSets,
    });
  }
  return getDisplayTextForEvidence({
    activeTab,
    filter: activeFilters[0],
    label: localizedLabel,
    t,
  });
};

export const getFiltersDisplayText = ({
  appliedFilters,
  activeTab,
  allFiltersOptions,
  t,
  curriculumType,
  plannerElementSets,
}) => {
  const activeFilters = getActiveFiltersMemoized({
    appliedFilters,
  });

  const activeFeedTitleFilters = _.intersection(
    _.get(tabFiltersConfig, `${activeTab}.feedTitleFilters`, []),
    activeFilters
  );

  const activeFiltersSelectedValuesLabels = getActiveFilterSelectedLabelsMemoized(
    {
      activeFilters: activeFeedTitleFilters,
      allFiltersOptions,
      appliedFilters,
    }
  );

  const totalSelectedValues = _.reduce(
    activeFilters,
    (result, key) => {
      return result + _.size(activeFiltersSelectedValuesLabels[key]);
    },
    0
  );

  switch (totalSelectedValues) {
    case 0: {
      return [];
    }
    case 1: {
      return getLocalizedTextForOneSelectedOption({
        activeFilters: activeFeedTitleFilters,
        activeFiltersSelectedValuesLabels,
        activeTab,
        t,
        curriculumType,
        plannerElementSets,
      });
    }
    case 2: {
      return getLocalizedTextForTwoSelectedOptions({
        activeFilters: activeFeedTitleFilters,
        activeTab,
        activeFiltersSelectedValuesLabels,
        t,
        curriculumType,
        plannerElementSets,
      });
    }
    default: {
      return getMoreFiltersDisplayTextList({
        activeFilters,
        activeFiltersSelectedValuesLabels,
        activeTab,
        t,
        curriculumType,
        plannerElementSets,
      });
    }
  }
};

export const getFiltersDisplayTextMemoized = getMemoizedFunction(
  getFiltersDisplayText
);

/**
 * It will return data structure that will have necessary info for computing dependent filters
 */
export const getParentFiltersObj = ({
  parentFilters,
  entityTypeInfo,
  plannerElementSets,
  t,
  curriculumType,
  appliedFilters,
}) => {
  return _.reduce(
    parentFilters,
    (result, filter) => {
      let values = [];

      const { filterLocale, isLabelPresentInSetLabels } =
        entityTypeInfo[filter] ?? {};

      const label = getFilterLabel({
        plannerElementSets,
        filterLocale,
        isLabelPresentInSetLabels,
        t,
        curriculumType,
        entityType: filter,
      });

      switch (filter) {
        case "COURSE": {
          values = _.flatMap(appliedFilters[filter], ({ values }) => values);

          const allSubjects = [];

          _.forEach(values, courseId => {
            const courseDetails = getCourseDetailFromCache({ courseId });

            const subjects = _.map(
              _.get(courseDetails, "subjects", []),
              ({ id }) => id
            );

            allSubjects.push(...subjects);
          });

          return {
            ...result,
            [filter]: { values, label, subjects: _.uniq(allSubjects) },
          };
        }

        default: {
          values = appliedFilters[filter];

          return {
            ...result,
            [filter]: { values, label },
          };
        }
      }
    },
    {}
  );
};
