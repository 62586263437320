import client from "apolloClient";
import { organizationSubjectsFragment } from "./MultiLevelNodeEditorFragments";
import {
  subjectFragment,
  pypPlannerElementSetFragment,
} from "modules/CommonFragments";
import {
  getBenchmarkStandardSetsQuery,
  getOrganizationSubjectsQuery,
  getStandardBenchmarkSetQuery,
  getAllGlobalSubjectsQuery,
  getPypAtlSetQuery,
} from "./MultiLevelNodeEditorQueries";

export const getPypAtlSetFromCache = ({ id, grades }) => {
  try {
    const queryData = client.readQuery({
      query: getPypAtlSetQuery,
      variables: {
        id,
        grades,
      },
    });
    return _.get(queryData, "node", {});
  } catch (error) {
    return {};
  }
};

export const writePypAtlSetInCache = ({ id, data, grades }) => {
  try {
    client.writeFragment({
      id: `ATLSet:${id}`,
      fragment: pypPlannerElementSetFragment.atlSet,
      fragmentName: "atlSetItem",
      data,
      variables: { grades },
    });
  } catch (err) {}
};

export const getBenchmarkSetDetailsFromCache = ({ organizationId, id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStandardBenchmarkSetQuery,
      variables: {
        organizationId,
        id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null)
    return _.get(queryData, "planner.standardBenchmarkSet", {});
  else return {};
};

export const getOrganizationSubjectsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationSubjectsQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    console.error({ e });
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeOrganizationSubjectsInCache = ({
  data,
  filters,
  organizationId,
}) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${organizationId}`,
      variables: { filters },
      fragment: organizationSubjectsFragment.organizationSubjects,
      fragmentName: "organizationItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
export const getBenchmarkStandardSetsFromCache = ({
  globalSubjects,
  searchText,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getBenchmarkStandardSetsQuery,
      variables: {
        globalSubjects,
        searchText,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "globalConstants", {});
  else return {};
};

export const getAllGlobalSubjectsFromCache = ({ filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAllGlobalSubjectsQuery,
      variables: { filters },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getSubjectFromCache = subjectId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Subject:${subjectId}`,
      fragment: subjectFragment.subjectBenchmarkDetails,
      fragmentName: "subjectItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeSubjectInCache = ({ subjectId, data }) => {
  try {
    client.writeFragment({
      id: `Subject:${subjectId}`,
      fragment: subjectFragment.subjectBenchmarkDetails,
      fragmentName: "subjectItem",
      data: data,
    });
  } catch (e) {}
};
