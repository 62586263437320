import React from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import classes from "./TopHeader.scss";
import { colors } from "Constants";
import {
  ToddleIcon,
  ChevronIcon,
  LockScreenIcon,
  PreferencesIcon,
} from "SvgComponents";
import GradeFilter from "../GradeFilter";
import {
  UIButton,
  Checkbox,
  LinkWithTooltip,
  I18nHOC,
  DropzoneComponent,
  ButtonDropdown,
  DialogueBox,
} from "UIComponents";
import { isFeatureLocked } from "modules/PermissionModule";
import ACLStore from "lib/aclStore";
import { BulkUploadErrorModal } from "UIComponents";
import { bulkUploadByFile } from "modules/Services";
import classNames from "classnames";
import { updateSnsSettingsAction } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule";
import { goToBack } from "modules/NavigationModule";
import { SegmentControl } from "@toddle-design/web";
import { disableEditForMapleBearChildOrg } from "Courses/modules/utils";
import _ from "lodash";

const BULK_UPLOAD_BUTTONS = t => [
  // {
  //   label: t("common:import_label", { label: t("common:benchmarks_label") }),
  //   type: "BENCHMARK",
  //   perm: "AdminPortal:SnSBulkUpload"
  // }
];
const DIALOGS_INFO = {
  RECONFIGURE: {
    title: ({ t }) =>
      t("scopeAndSequence:reconfigure") +
      " " +
      t("scopeAndSequence:scope_and_sequence"),
    message: ({ t }) => {
      return (
        <div>
          <div
            style={{ color: `${colors.jaguar}`, margin: "0px 0px 24px 0px" }}
          >
            {t("scopeAndSequence:reconfigure_warning_msg")}
          </div>
          <div
            style={{ color: `${colors.yellow50}`, margin: "0px 0px 24px 0px" }}
          >
            {t("scopeAndSequence:reconfigure_warning_msg_desc")}
          </div>
        </div>
      );
    },
    confirmButtonText: ({ t }) => t("common:remove"),
    cancelButtonText: ({ t }) => t("common:cancel"),
    dialogContentClass: classes.dialogBoxContent,
  },
};
DIALOGS_INFO.RECONFIGURE.message.displayName = "RECONFIGURE_SNS";
class TopHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      benchmarkBulkUploadErrorModal: false,
      errors: [],
      dailogueState: "",
    };
  }

  onChangeMode = () => {
    const { mode, onChangeMode } = this.props;
    onChangeMode(mode == "edit" ? "view" : "edit");
  };

  onContentUploaded = async ({ data, type }) => {
    const { successful, errors } = await this.props.bulkUploadByFile({
      url: data.url,
      type,
    });
    if (!successful) {
      this.setState({
        errors: errors,
      });
      this.toggleBulkUploadErrorModal();
    }
  };

  toggleBulkUploadErrorModal = () => {
    this.setState({
      benchmarkBulkUploadErrorModal: !this.state.benchmarkBulkUploadErrorModal,
    });
  };
  renderMenuItems = () => {
    const { t } = this.props;
    const MENU_ITEMS = [
      {
        label: t("scopeAndSequence:reconfigure_standards"),
        value: "RECONFIGURE",
      },
      {
        label: t("scopeAndSequence:rename_hierarchy_levels"),
        value: "EDIT_HIERARCHY",
      },
    ];

    if (ACLStore.can("AdminPortal:SnSAppendBulkUpload"))
      MENU_ITEMS.push({
        label: t("scopeAndSequence:append_standards"),
        value: "APPEND",
      });
    return MENU_ITEMS;
  };

  onSettingsItemClick = value => {
    if (value === "RECONFIGURE") {
      this.setState({ dailogueState: value });
    } else if (value === "APPEND") {
      this.props.updateSnsSettingsAction(value);
      this.props.goToBack();
    } else {
      this.props.updateSnsSettingsAction(value);
      this.props.goToBack();
    }
  };
  toggleDialogueBoxDisplay = () => {
    this.setState({
      dailogueState: "",
    });
  };
  onDialogConfirmClick = () => {
    const { dailogueState } = this.state;
    this.props.updateSnsSettingsAction(dailogueState);
    this.props.goToBack();
  };

  onChangeTab = param => {
    const { tabs, changeTab } = this.props;
    changeTab(_.find(tabs, { value: param }));
  };

  render() {
    const {
      t,
      tabs,
      currentTab,
      changeTab,
      title,
      subTitle,
      onBackClick,
      mode,
      grades,
      gradeFilters,
      updateGradeFilters,
      isAdmin,
      isTagging,
      onSaveClick,
      showNodeTree,
      description,
      editRootNode,
      onConceptualUnderStandingCheckBoxClick,
      showDetails,
      isSetPreview,
      onClickChoose,
      showEditButton = true,
      disableEditButton = false,
      showBackButton = true,
      enableBreadCrumbs = true,
      loginActiveTab,
    } = this.props;
    const tabsLength = _.get(tabs, "length", 0);
    const isLocked = isFeatureLocked("SNS_EDITOR");

    const { dailogueState } = this.state;
    const isSchoolOnboardingTab = loginActiveTab == "school-onboarding";

    const titleTextClass = classNames({
      [classes.titleText]: true,
      [classes.highlightText]: enableBreadCrumbs,
    });

    return (
      <>
        {dailogueState && (
          <DialogueBox
            customModalContentClass={
              DIALOGS_INFO[dailogueState].dialogContentClass
            }
            modalTitle={DIALOGS_INFO[dailogueState].title({ t })}
            onClickButton2={this.onDialogConfirmClick}
            modalBody={DIALOGS_INFO[dailogueState].message({
              t,
            })}
            toggleDialogueBoxDisplay={this.toggleDialogueBoxDisplay}
            button1={DIALOGS_INFO[dailogueState].cancelButtonText({ t })}
            button2={DIALOGS_INFO[dailogueState].confirmButtonText({ t })}
          />
        )}
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            {showBackButton ? (
              <div className={classes.logoContainer} onClick={onBackClick}>
                <div className={classes.chevronIconContainer}>
                  <ChevronIcon fill={colors.gray48} />
                </div>
                <ToddleIcon />
              </div>
            ) : null}
            <div className={classes.textAndAttributionContainer}>
              <div className={classes.textContainer}>
                <div
                  className={titleTextClass}
                  onClick={enableBreadCrumbs && onBackClick}
                >
                  {title}
                </div>
                {!_.isEmpty(subTitle) && (
                  <>
                    <div className={classes.slashIcon}>{`/`}</div>
                    <div className={classes.subTitleText}>{subTitle}</div>
                  </>
                )}
              </div>
              {!!description && (
                <LinkWithTooltip placement="top" tooltip={description}>
                  <div className={classes.descriptionText}>{description}</div>
                </LinkWithTooltip>
              )}
            </div>
          </div>
          <div className={classes.rightContainer}>
            {tabsLength > 1 && mode == "edit" && !showNodeTree && (
              <SegmentControl
                options={tabs}
                value={currentTab}
                onChange={this.onChangeTab}
              />
            )}
          </div>
          <div className={classes.buttonsContainer}>
            {mode == "edit" && showNodeTree && showDetails && (
              <div
                className={classes.checkBoxContainer}
                onClick={onConceptualUnderStandingCheckBoxClick}
              >
                <Checkbox
                  isChecked={_.includes(
                    _.get(editRootNode, "snsChildren", []),
                    "CONCEPTUAL_UNDERSTANDING"
                  )}
                  label={t("common:enable_with_label", {
                    label: t("academicSetup:cu"),
                  })}
                />
              </div>
            )}
            {mode == "view" && !showNodeTree && (
              <GradeFilter
                label={t("common:filter_by_label", {
                  label: t("common:label_with_colon", {
                    label: t("common:grade_plural"),
                  }),
                })}
                grades={grades}
                gradeFilters={gradeFilters}
                updateGradeFilters={updateGradeFilters}
              />
            )}
            {isTagging && (
              <UIButton
                size={"sm"}
                type={"filled"}
                color={"pink"}
                onClick={() => onSaveClick({ shouldCloseModal: true })}
              >
                {t("common:save_exit")}
              </UIButton>
            )}

            {isSchoolOnboardingTab && showEditButton && !isSetPreview && (
              <div
                id={"COACHMARKS_SCOPE_SEQUENCE_EDITOR_PREFERENCES_BUTTON"}
                className={classes.preferencesContainer}
              >
                <ButtonDropdown
                  authTabs={this.renderMenuItems()}
                  buttonComponent={<PreferencesIcon />}
                  onItemClick={this.onSettingsItemClick}
                />
              </div>
            )}
            {isTagging ||
              (showNodeTree && mode == "edit" && (
                <UIButton
                  size={"sm"}
                  type={"filled"}
                  color={"pink"}
                  onClick={() => onSaveClick({ shouldCloseModal: true })}
                  isDisabled={!showDetails && showNodeTree && mode == "edit"}
                >
                  {t("common:save_exit")}
                </UIButton>
              ))}
            {isAdmin &&
              (!showNodeTree || mode == "view") &&
              !isTagging &&
              !isSetPreview && (
                <div className={classes.buttonContainer}>
                  {_.map(BULK_UPLOAD_BUTTONS(t), ({ label, type, perm }) => {
                    const isEnabled = ACLStore.can(perm);
                    return (
                      isEnabled && (
                        <DropzoneComponent
                          key={type}
                          multiple={false}
                          renderComponent={
                            <UIButton color={"pink"} size={"lg"}>
                              {label}
                            </UIButton>
                          }
                          accept={[".csv", "text/csv"]}
                          onSingleContentUploaded={data =>
                            this.onContentUploaded({ data, type })
                          }
                        />
                      )
                    );
                  })}

                  {this.state.benchmarkBulkUploadErrorModal ? (
                    <BulkUploadErrorModal
                      toggleBulkUploadErrorModal={
                        this.toggleBulkUploadErrorModal
                      }
                      errors={this.state.errors}
                    />
                  ) : null}

                  {showEditButton && !disableEditForMapleBearChildOrg() && (
                    <UIButton
                      size={"lg"}
                      type={"hollow"}
                      color={"pink"}
                      onClick={this.onChangeMode}
                      isDisabled={disableEditButton}
                    >
                      <div className={classes.editButton}>
                        <div className={classes.editText}>
                          {mode == "edit"
                            ? t("common:view")
                            : t("common:modify")}
                        </div>

                        {isLocked && (
                          <div className={classes.lockIcon}>
                            <LockScreenIcon height={14} width={14} />
                          </div>
                        )}
                      </div>
                    </UIButton>
                  )}
                </div>
              )}
            {isSetPreview && (
              <UIButton size={"sm"} color={"pink"} onClick={onClickChoose}>
                {t("common:choose")}
              </UIButton>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapActionCreators = {
  bulkUploadByFile,
  updateSnsSettingsAction,
  goToBack,
};
const mapStateToProps = (state, ownProps) => {
  const { activeTab } = state.login;
  return {
    loginActiveTab: activeTab,
  };
};
export default compose(
  I18nHOC({ resource: ["common", "academicSetup"] }),
  connect(mapStateToProps, mapActionCreators)
)(TopHeader);

TopHeader.defaultProps = {
  hideBackButton: false,
};
