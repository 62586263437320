import React from "react";
import PropTypes from "prop-types";
import classes from "./SideBar.scss";
import { colors } from "Constants";
import { ToddleIcon, LeftIcon } from "SvgComponents";
import { I18nHOC } from "UIComponents";

class SideBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { currentStep: props.steps.first() };
  }
  changeCurrentStep = currentStep => {
    if (this.state.currentStep != currentStep) {
      this.setState({ currentStep });
    }
  };
  render() {
    const {
      steps,
      step_list,
      currentStep,
      onClickStep,
      onClose,
      showBackIcon,
      type,
    } = this.props;

    return (
      <div className={classes.container}>
        {showBackIcon && (
          <div className={classes.backIcon} onClick={onClose}>
            <LeftIcon fill={colors.gray72} />
            <ToddleIcon />
          </div>
        )}
        {/* <div className={classes.itemListHeader}>{`OUTLINE`}</div> */}
        <div className={classes.itemList}>
          {_.map(steps, key => {
            const step = step_list[key] || {};
            // const { label } = step;
            const label = _.get(step_list[key], "label", "");
            const isActive = currentStep == key;
            const customStyle = showBackIcon ? {} : {};
            const isActiveStyle = isActive
              ? { borderColor: colors.salmon60, color: colors.salmon60 }
              : {};
            return (
              <div
                onClick={() => onClickStep(key)}
                id={`COACHMARKS_${type}_${key}`}
                className={classes.item}
                key={key}
                style={{ ...customStyle, ...isActiveStyle }}
              >
                {label}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: "unitPlanTemplate" })(SideBar);
