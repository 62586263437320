import React, { Fragment } from "react";

// Design System
import { ChevronRightOutlined } from "@toddle-design/web-icons";

// External
import classNames from "classnames";

// Internal
import classes from "./BreadCrumbs.scss";

const BreadCrumbs = props => {
  const { hierarchy = [], postHierarchy = [], onHierarchyClick } = props;

  const updatedHierarchy = [...hierarchy, ...postHierarchy];
  const hierarchyLength = _.size(updatedHierarchy);

  return (
    <div className={classes.hierarchyContainer}>
      {_.map(updatedHierarchy, ({ label, id }, idx) => {
        const isLast = hierarchyLength - 1 == idx;
        const breadCrumbLabelClasses = classNames(classes.breadCrumbLabel, {
          [classes.parent]: !isLast,
          [classes.child]: isLast,
        });

        const onClick = () => {
          onHierarchyClick(id);
        };

        return (
          <Fragment key={id}>
            <div className={breadCrumbLabelClasses} onClick={onClick}>
              {label}
            </div>
            {!isLast && (
              <div className={classes.iconContainer}>
                <ChevronRightOutlined size={"xxx-small"} variant={"subtle"} />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;
