import gql from "graphql-tag";
import { designationFragment } from "modules/CommonFragments";
export const teacherFragments = {
  teacherFeed: gql`
    fragment teacherItem on Staff {
      id
      profileImage
      firstName
      lastName
      email
      role
      designation {
        ...designationItem
      }
      status
      courses(filters: { archivalState: ALL }) {
        id
        title
        isArchived
      }
      isArchived
    }
    ${designationFragment.designation}
  `,
  teacherDetails: gql`
    fragment teacherItemDetail on Staff {
      id
      firstName
      lastName
      profileImage
      email
      designation {
        id
      }
      entityTags {
        id
        key
        value
      }
    }
  `,
};

export const organizationStaffFeedFragment = {
  organizationStaffFeedEdge: gql`
    fragment organizationStaffFeedEdgeItem on Organization {
      id
      staff(
        first: 20
        after: $after
        filters: $filters
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        userCount {
          role
          totalCount
        }
        edges {
          node {
            id
            ... on Staff {
              ...teacherItem
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    ${teacherFragments.teacherFeed}
  `,
};
