import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
  ELEMENT_TYPE_MYP_KEY_CONCEPT,
  ELEMENT_TYPE_MYP_ATL,
  ELEMENT_TYPE_MYP_LEARNER_PROFILE,
  ELEMENT_TYPE_MYP_OBJECTIVES,
  CURRICULUM_TYPE_UBD,
  ELEMENT_TYPE_UBD_ATL,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";

const academicYearFilterConfig = {
  parent: "ACADEMIC_YEAR",
  selectOnlyOneOptionInParentFilter: true,
};

export const tabFiltersConfig = {
  UNIT_EVIDENCE: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_UBD,
    ],
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: [
        "COURSE",
        "CONCEPT",
        "LEARNER_PROFILE",
        "ATL",
        "SUBJECT",
        "ACADEMIC_YEAR",
        "UNIT_PLAN",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "COURSE",
        ELEMENT_TYPE_MYP_KEY_CONCEPT,
        ELEMENT_TYPE_MYP_LEARNER_PROFILE,
        ELEMENT_TYPE_MYP_ATL,
        ELEMENT_TYPE_MYP_OBJECTIVES,
        "SUBJECT",
        "ACADEMIC_YEAR",
        "UNIT_PLAN",
      ],
      [CURRICULUM_TYPE_UBD]: [
        "COURSE",
        ELEMENT_TYPE_UBD_ATL,
        "SUBJECT",
        "UNIT_PLAN",
        "ACADEMIC_YEAR",
      ],
    },
    entityTypeInfo: {
      COURSE: {
        filterLocale: "common:class_plural",
        feedLocale: "common:class",
        tabDisplayOrder: 1,
        hierarchyOrder: 1,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      CONCEPT: {
        filterLocale: "common:concept_plural",
        feedLocale: "common:concept",
        tabDisplayOrder: 3,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_KEY_CONCEPT]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:concept_plural",
        feedLocale: "common:concept",
        tabDisplayOrder: 3,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      LEARNER_PROFILE: {
        filterLocale: "common:lp_label",
        feedLocale: "common:lp_label",
        tabDisplayOrder: 4,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_LEARNER_PROFILE]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:lp_label",
        feedLocale: "common:lp_label",
        tabDisplayOrder: 4,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      ATL: {
        filterLocale: "common:atls_label",
        feedLocale: "common:atls_label",
        tabDisplayOrder: 5,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_ATL]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:atls_label",
        feedLocale: "common:atls_label",
        tabDisplayOrder: 5,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      SUBJECT: {
        filterLocale: "common:learning_standards",
        feedLocale: "common:learning_standards",
        isLabelPresentInSetLabels: true,
        tabDisplayOrder: 6,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_OBJECTIVES]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:myp_objectives",
        feedLocale: "common:myp_objectives",
        tabDisplayOrder: 7,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      UNIT_PLAN: {
        filterLocale: "common:unit_plural",
        feedLocale: "common:unit",
        tabDisplayOrder: 2,
        hierarchyOrder: 2,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: 0,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
      [ELEMENT_TYPE_UBD_ATL]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        isLabelPresentInSetLabels: true,
        tabDisplayOrder: 5,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
    },
    feedTitleFilters: [
      "SUBJECT",
      "CONCEPT",
      ELEMENT_TYPE_MYP_KEY_CONCEPT,
      "LEARNER_PROFILE",
      ELEMENT_TYPE_MYP_LEARNER_PROFILE,
      "ATL",
      ELEMENT_TYPE_MYP_ATL,
      ELEMENT_TYPE_UBD_ATL,
      ELEMENT_TYPE_MYP_OBJECTIVES,
      "UNIT_PLAN",
      "COURSE",
      ELEMENT_TYPE_UBD_ATL,
    ],
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_PYP]: ["COURSE", "ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_MYP]: ["COURSE", "ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_UBD]: ["COURSE", "ACADEMIC_YEAR"],
    },
    dependentFilterConfig: [
      {
        filter: "UNIT_PLAN",
        parents: [
          { parent: "COURSE", selectOnlyOneOptionInParentFilter: false },
          academicYearFilterConfig,
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_OBJECTIVES,
        parents: [
          {
            parent: "COURSE",
            selectOnlyOneOptionInParentFilter: false,
          },
          academicYearFilterConfig,
        ],
      },
      {
        filter: "COURSE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "CONCEPT",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_KEY_CONCEPT,
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_MYP },
        ],
      },
      {
        filter: "ATL",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_ATL,
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_MYP },
        ],
      },
      {
        filter: "LEARNER_PROFILE",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_LEARNER_PROFILE,
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_MYP },
        ],
      },
      {
        filter: "SUBJECT",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_UBD },
          {
            parent: "COURSE",
            selectOnlyOneOptionInParentFilter: false,
            curriculumType: CURRICULUM_TYPE_MYP,
          },
        ],
      },
    ],
    enum: "SNP_EVIDENCE_UNITS",
    placeholder: "common:search",
  },
  CLASSROOM: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_DP,
    ],
    provideFiltersInFilterProp: true,
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: [
        "COURSE",
        "ACTIVITY_TYPE",
        "ACADEMIC_YEAR",
        "FOLDER",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "COURSE",
        "ACTIVITY_TYPE",
        "ACADEMIC_YEAR",
        "FOLDER",
      ],
      [CURRICULUM_TYPE_DP]: [
        "COURSE",
        "ACTIVITY_TYPE",
        "ACADEMIC_YEAR",
        "FOLDER",
      ],
    },
    entityTypeInfo: {
      COURSE: {
        filterLocale: "common:class_plural",
        feedLocale: "common:class",
        tabDisplayOrder: 1,
        hierarchyOrder: 1,
        relationWithEvidence: "parent",
        isCheckList: true,
      },
      FOLDER: {
        filterLocale: "common:folders",
        feedLocale: "common:folder",
        tabDisplayOrder: 2,
        hierarchyOrder: 2,
        relationWithEvidence: "parent",
        isCheckList: true,
      },
      ACTIVITY_TYPE: {
        filterLocale: "common:post_type",
        feedLocale: "common:type",
        tabDisplayOrder: 3,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "category",
      },
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: 0,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
    },
    feedTitleFilters: ["ACTIVITY_TYPE", "FOLDER", "COURSE"],
    selectOnlyOneOptionInParentFilter: true,
    enum: "SNP_EVIDENCE_CLASSROOM",
    placeholder: "common:search",
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_PYP]: ["COURSE", "ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_MYP]: ["COURSE", "ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_DP]: ["COURSE", "ACADEMIC_YEAR"],
    },
    dependentFilterConfig: [
      {
        filter: "FOLDER",
        parents: [
          { parent: "COURSE", selectOnlyOneOptionInParentFilter: true },
          academicYearFilterConfig,
        ],
      },
      {
        filter: "COURSE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "ACTIVITY_TYPE",
        parents: [academicYearFilterConfig],
      },
    ],
  },
  UNIT_PLANS: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_DP,
    ],
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: [
        "GRADE",
        "UNIT_PLAN_TYPE",
        "UNIT_PLAN_THEME",
        "SUBJECT",
        "ACADEMIC_YEAR",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "GRADE",
        "UNIT_PLAN_TYPE",
        "SUBJECT",
        "ACADEMIC_YEAR",
      ],
      [CURRICULUM_TYPE_DP]: ["GRADE", "SUBJECT", "ACADEMIC_YEAR"],
    },
    entityTypeInfo: {
      GRADE: {
        filterLocale: "common:grade_plural",
        feedLocale: "common:grade",
        tabDisplayOrder: 1,
        hierarchyOrder: 1,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      UNIT_PLAN_TYPE: {
        filterLocale: "common:unit_type_label",
        feedLocale: "common:unit_type_label",
        feedLocaleSuffix: "common:unit_plural",
        tabDisplayOrder: 2,
        hierarchyOrder: 2,
        isCheckList: true,
        relationWithEvidence: "category",
      },
      UNIT_PLAN_THEME: {
        filterLocale: "common:theme_plural",
        feedLocale: "common:theme",
        tabDisplayOrder: 3,
        hierarchyOrder: 2,
        isCheckList: true,
        relationWithEvidence: "category",
      },
      SUBJECT: {
        filterLocale: "common:subject_plural",
        feedLocale: "common:subject",
        hierarchyOrder: 2,
        tabDisplayOrder: 4,
        isCheckList: true,
        relationWithEvidence: "category",
      },
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        hierarchyOrder: 0,
        tabDisplayOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
    },
    feedTitleFilters: ["UNIT_PLAN_TYPE", "SUBJECT", "UNIT_PLAN_THEME", "GRADE"],

    enum: "SNP_EVIDENCE_UNIT_PLANS",
    placeholder: "common:search",
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_PYP]: ["ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_MYP]: ["ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_DP]: ["ACADEMIC_YEAR"],
    },

    dependentFilterConfig: [
      {
        filter: "GRADE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "UNIT_PLAN_TYPE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "UNIT_PLAN_THEME",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "SUBJECT",
        parents: [academicYearFilterConfig],
      },
    ],
  },
  LEARNING_EXPERIENCES: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_DP,
    ],
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: ["GRADE", "ACADEMIC_YEAR", "UNIT_PLAN"],
      [CURRICULUM_TYPE_MYP]: ["GRADE", "ACADEMIC_YEAR", "UNIT_PLAN"],
      [CURRICULUM_TYPE_DP]: ["GRADE", "ACADEMIC_YEAR", "UNIT_PLAN"],
    },
    entityTypeInfo: {
      GRADE: {
        feedLocale: "common:grade",
        filterLocale: "common:grade_plural",
        tabDisplayOrder: 1,
        hierarchyOrder: 2,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      UNIT_PLAN: {
        filterLocale: "common:unit_plural",
        feedLocale: "common:unit",
        tabDisplayOrder: 2,
        hierarchyOrder: 1,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: 0,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
    },
    feedTitleFilters: ["UNIT_PLAN", "GRADE"],
    selectOnlyOneOptionInParentFilter: false,
    enum: "SNP_EVIDENCE_ASSESSMENTS",
    placeholder: "common:search",
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_PYP]: ["ACADEMIC_YEAR", "GRADE"],
      [CURRICULUM_TYPE_MYP]: ["ACADEMIC_YEAR", "GRADE"],
      [CURRICULUM_TYPE_DP]: ["ACADEMIC_YEAR", "GRADE"],
    },
    dependentFilterConfig: [
      {
        filter: "GRADE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "UNIT_PLAN",
        parents: [
          academicYearFilterConfig,
          { parent: "GRADE", selectOnlyOneOptionInParentFilter: false },
        ],
      },
    ],
  },
  PROGRESS_REPORTS: {
    searchAvailableInCurriculums: [CURRICULUM_TYPE_PYP, CURRICULUM_TYPE_MYP],
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: [
        "GRADE",
        "ACADEMIC_YEAR",
        "PROGRESS_REPORT_TASK_GROUP",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "GRADE",
        "ACADEMIC_YEAR",
        "PROGRESS_REPORT_TASK_GROUP",
      ],
    },
    feedTitleFilters: ["PROGRESS_REPORT_TASK_GROUP", "GRADE"],

    selectOnlyOneOptionInParentFilter: false,
    enum: "SNP_EVIDENCE_PROGRESS_REPORTS",

    entityTypeInfo: {
      GRADE: {
        filterLocale: "common:grade_plural",
        feedLocale: "common:grade",
        tabDisplayOrder: 2,
        hierarchyOrder: 1,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: 0,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
      PROGRESS_REPORT_TASK_GROUP: {
        filterLocale: "progressReport:report_set_plural",
        feedLocale: "progressReport:report_set",
        tabDisplayOrder: 1,
        hierarchyOrder: 2,
        isCheckList: true,
        relationWithEvidence: "category",
      },
    },
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_PYP]: ["ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_MYP]: ["ACADEMIC_YEAR"],
    },

    placeholder: "common:search",
    dependentFilterConfig: [
      {
        filter: "GRADE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "PROGRESS_REPORT_TASK_GROUP",
        parents: [academicYearFilterConfig],
      },
    ],
  },
  SCHOOL_POLICY: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_DP,
    ],
    filtersApplicable: false,
    placeholder: "common:search",
    placeholderLocaleParams: "common:file",
  },
  TEACHER_RESOURCES: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_DP,
    ],
    filtersApplicable: false,
    placeholder: "common:search",
    placeholderLocaleParams: "common:file",
  },
  SNS: {
    searchAvailableInCurriculums: [CURRICULUM_TYPE_PYP, CURRICULUM_TYPE_MYP],
    filtersApplicable: false,
    placeholder: "common:search",
  },
  POI_INSIGHTS: {
    placeholder: "common:search",
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: "ACADEMIC_YEAR",
    },
    entityTypeInfo: {
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: 0,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
    },
    searchAvailableInCurriculums: [CURRICULUM_TYPE_PYP],
    enum: "SNP_PLANNING_INSIGHTS",
  },
  MYP_PLANNING_INSIGHTS: {
    placeholder: "common:search",
    searchAvailableInCurriculums: [CURRICULUM_TYPE_MYP],
    entityTypeInfo: {
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: 0,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
      SUBJECT_GROUP: {
        filterLocale: "common:subject_group",
        tabDisplayOrder: 1,
        isCheckList: false,
      },
    },
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_MYP]: ["ACADEMIC_YEAR", "SUBJECT_GROUP"],
    },
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_MYP]: ["ACADEMIC_YEAR"],
    },
    enum: "SNP_PLANNING_INSIGHTS",
    dependentFilterConfig: [
      {
        filter: "SUBJECT_GROUP",
        parents: [academicYearFilterConfig],
      },
    ],
  },
  suggested_evidence: {
    placeholder: "common:search",
  },
  PROGRESS_REPORT_POST: {
    searchAvailableInCurriculums: [
      CURRICULUM_TYPE_PYP,
      CURRICULUM_TYPE_MYP,
      CURRICULUM_TYPE_UBD,
    ],
    curriculumWiseAvailableFilters: {
      [CURRICULUM_TYPE_PYP]: [
        "COURSE",
        "CONCEPT",
        "LEARNER_PROFILE",
        "ATL",
        "SUBJECT",
        "UNIT_PLAN",
      ],
      [CURRICULUM_TYPE_MYP]: [
        "COURSE",
        ELEMENT_TYPE_MYP_KEY_CONCEPT,
        ELEMENT_TYPE_MYP_LEARNER_PROFILE,
        ELEMENT_TYPE_MYP_ATL,
        ELEMENT_TYPE_MYP_OBJECTIVES,
        "SUBJECT",
        "UNIT_PLAN",
      ],
      [CURRICULUM_TYPE_UBD]: [
        "COURSE",
        ELEMENT_TYPE_UBD_ATL,
        "SUBJECT",
        "UNIT_PLAN",
      ],
    },
    entityTypeInfo: {
      COURSE: {
        filterLocale: "common:class_plural",
        feedLocale: "common:class",
        tabDisplayOrder: 0,
        hierarchyOrder: 1,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
      CONCEPT: {
        filterLocale: "common:concept_plural",
        feedLocale: "common:concept",
        tabDisplayOrder: 2,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_KEY_CONCEPT]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:concept_plural",
        feedLocale: "common:concept",
        tabDisplayOrder: 2,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      LEARNER_PROFILE: {
        filterLocale: "common:lp_label",
        feedLocale: "common:lp_label",
        tabDisplayOrder: 3,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_LEARNER_PROFILE]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:lp_label",
        feedLocale: "common:lp_label",
        tabDisplayOrder: 3,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      ATL: {
        filterLocale: "common:atls_label",
        feedLocale: "common:atls_label",
        tabDisplayOrder: 4,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_ATL]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:atls_label",
        feedLocale: "common:atls_label",
        tabDisplayOrder: 4,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_UBD_ATL]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        isLabelPresentInSetLabels: true,
        tabDisplayOrder: 2,
        hierarchyOrder: 1,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      SUBJECT: {
        filterLocale: "common:learning_standards",
        feedLocale: "common:learning_standards",
        isLabelPresentInSetLabels: true,
        tabDisplayOrder: 5,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      [ELEMENT_TYPE_MYP_OBJECTIVES]: {
        baseSetType: "PLANNER_ELEMENT_SET",
        filterLocale: "common:myp_objectives",
        feedLocale: "common:myp_objectives",
        tabDisplayOrder: 6,
        hierarchyOrder: 3,
        isCheckList: true,
        relationWithEvidence: "taggable",
      },
      ACADEMIC_YEAR: {
        filterLocale: "common:academic_year",
        feedLocale: "common:academic_year",
        tabDisplayOrder: -1,
        hierarchyOrder: 0,
        isCheckList: false,
        relationWithEvidence: "parent",
      },
      UNIT_PLAN: {
        filterLocale: "common:unit_plural",
        feedLocale: "common:unit",
        tabDisplayOrder: 1,
        hierarchyOrder: 2,
        isCheckList: true,
        relationWithEvidence: "parent",
      },
    },
    feedTitleFilters: [
      "SUBJECT",
      "CONCEPT",
      ELEMENT_TYPE_MYP_KEY_CONCEPT,
      "LEARNER_PROFILE",
      ELEMENT_TYPE_MYP_LEARNER_PROFILE,
      "ATL",
      ELEMENT_TYPE_MYP_ATL,
      ELEMENT_TYPE_UBD_ATL,
      ELEMENT_TYPE_MYP_OBJECTIVES,
      "UNIT_PLAN",
      "COURSE",
      ELEMENT_TYPE_UBD_ATL,
    ],
    curriculumWiseParentFilters: {
      [CURRICULUM_TYPE_PYP]: ["COURSE", "ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_MYP]: ["COURSE", "ACADEMIC_YEAR"],
      [CURRICULUM_TYPE_UBD]: ["COURSE", "ACADEMIC_YEAR"],
    },
    dependentFilterConfig: [
      {
        filter: "UNIT_PLAN",
        parents: [
          { parent: "COURSE", selectOnlyOneOptionInParentFilter: false },
          academicYearFilterConfig,
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_OBJECTIVES,
        parents: [
          {
            parent: "COURSE",
            selectOnlyOneOptionInParentFilter: false,
          },
          academicYearFilterConfig,
        ],
      },
      {
        filter: "COURSE",
        parents: [academicYearFilterConfig],
      },
      {
        filter: "CONCEPT",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_KEY_CONCEPT,
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_MYP },
        ],
      },
      {
        filter: "ATL",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_ATL,
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_MYP },
        ],
      },
      {
        filter: "LEARNER_PROFILE",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
        ],
      },
      {
        filter: ELEMENT_TYPE_MYP_LEARNER_PROFILE,
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_MYP },
        ],
      },
      {
        filter: "SUBJECT",
        parents: [
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_PYP },
          { ...academicYearFilterConfig, curriculumType: CURRICULUM_TYPE_UBD },
          {
            parent: "COURSE",
            selectOnlyOneOptionInParentFilter: false,
            curriculumType: CURRICULUM_TYPE_MYP,
          },
        ],
      },
    ],
    enum: "SNP_EVIDENCE_UNITS",
    placeholder: "common:search",
  },
};

export const filterLabelConfig = {
  FOLDER: {
    attributes: ["folderName"],
  },
  UNIT_PLAN: {
    attributes: ["unitPlanTitle.value"],
  },
  SUBJECT: {
    attributes: ["subjectName"],
  },
  CONCEPT: {
    attributes: ["conceptLabel"],
  },
  LEARNER_PROFILE: {
    attributes: ["lpaLabel"],
  },
  ATL: {
    attributes: ["atlLabel"],
  },
  ACTIVITY_TYPE: {
    attributes: ["activityLabel"],
  },
  SUBJECT_GROUP: {
    attributes: ["subjectGroupName"],
  },
  GRADE: {
    attributes: ["gradeName"],
  },
  UNIT_PLAN_TYPE: {
    attributes: ["unitPlanTypeLabel"],
  },
  UNIT_PLAN_THEME: {
    attributes: ["themeLabel"],
  },
};
