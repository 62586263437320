import gql from "graphql-tag";
import { organizationResourceFragment } from "./OrganizationResourcesFragments";
import { attachmentFragment } from "modules/CommonFragments";

export const createOrganizationResourcesMutation = gql`
  mutation createOrganizationResources(
    $parentId: ID!
    $parentResourceId: ID
    $parentType: ORGANIZATION_RESOURCE_PARENT_TYPE
    $organizationResources: [OrganizationResourceInput!]
    $shouldHandleSequence: Boolean
    $putAfterItemId: ID
    $tags: [ID!]
    $isCountry: Boolean
  ) {
    platform {
      createOrganizationResources(
        input: {
          parentId: $parentId
          parentResourceId: $parentResourceId
          parentType: $parentType
          organizationResources: $organizationResources
          shouldHandleSequence: $shouldHandleSequence
          putAfterItemId: $putAfterItemId
          tags: $tags
          isCountry: $isCountry
        }
      ) {
        ...organizationResourceItem
      }
    }
  }
  ${organizationResourceFragment.organizationResource}
`;

export const deleteOrganizationResourcesMutation = gql`
  mutation deleteOrganizationResource($ids: [ID!]) {
    platform {
      deleteOrganizationResource(input: { ids: $ids })
    }
  }
`;

export const updateOrganizationResourceMutation = gql`
  mutation updateOrganizationResource(
    $input: OrganizationResourceUpdateInput!
  ) {
    platform {
      updateOrganizationResource(input: $input) {
        ...organizationResourceItem
      }
    }
  }
  ${organizationResourceFragment.organizationResource}
`;

export const updateMultiOrganizationResourcesMutation = gql`
  mutation updateMultiOrganizationResources(
    $input: [OrganizationResourceUpdateInput!]
  ) {
    platform {
      updateMultiOrganizationResources(input: $input) {
        ...organizationResourceItem
      }
    }
  }
  ${organizationResourceFragment.organizationResource}
`;

export const updateAttachmentMutation = gql`
  mutation updateAttachment($input: AttachmentUpdateInput!) {
    platform {
      updateAttachment(input: $input) {
        ...attachmentItem
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const publishResourcesMutation = gql`
  mutation publishResources($input: IntegrationCreateJobAndTaskInput!) {
    integration {
      integrationCreateJobAndTask(input: $input)
    }
  }
`;
