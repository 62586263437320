import React, { PureComponent } from "react";
import classes from "./AssessmentToolPreviewCard.scss";
import RubricTable from "Rubric/components/RubricTable";
import ChecklistTable from "Checklist/components/ChecklistTable";
import SinglePointRubricTable from "SinglePointRubric/components/SinglePointRubricTable";
import MYPObjectiveRubric from "UnitPlans/components/IBcomponents/AssessmentTool/MYPObjectiveRubric";
import { colors } from "Constants";
import { ExpandArrowsFourOutlined } from "@toddle-design/web-icons";

class AssessmentToolPreviewCard extends PureComponent {
  updateMYPRubricRef = ref => {
    this.mypRubricRef = ref;
  };

  getAssessmentTool = () => {
    const {
      type,
      assessmentTool,
      options,
      assessmentToolsConStyle,
    } = this.props;
    switch (type) {
      case "RUBRIC":
        return (
          <RubricTable
            mode={"view"}
            rubric={assessmentTool}
            firstColumnCellStyle={{ minWidth: "140px" }}
            conStyle={assessmentToolsConStyle}
          />
        );

      case "CHECKLIST":
        return (
          <ChecklistTable
            mode={"view"}
            checklist={assessmentTool}
            containerStyle={assessmentToolsConStyle}
          />
        );

      case "SINGLE_POINT_RUBRIC":
        return (
          <SinglePointRubricTable
            mode={"view"}
            rubric={assessmentTool}
            containerStyle={assessmentToolsConStyle}
          />
        );

      case "MYP_OBJECTIVE_RUBRIC":
      case "MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC":
        return (
          <MYPObjectiveRubric
            options={options}
            assessmentTool={assessmentTool}
            mode={"view"}
            conStyle={assessmentToolsConStyle}
            customRef={this.updateMYPRubricRef}
          />
        );

      default:
        return null;
    }
  };

  showLoading = () => {
    const { type } = this.props;
    if (
      type == "MYP_OBJECTIVE_RUBRIC" ||
      type == "MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC"
    ) {
      return !this.mypRubricRef;
    }
    return false;
  };

  onClick = () => {
    if (this.showLoading()) {
      return;
    }
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  render() {
    return (
      <div className={classes.previewContainer} onClick={this.onClick}>
        {!this.showLoading() ? (
          <div className={classes.expandIconContainer}>
            <div className={classes.expandIcon}>
              <ExpandArrowsFourOutlined size={"xx-small"} variant={"on"} />
            </div>
          </div>
        ) : null}
        {this.getAssessmentTool()}
      </div>
    );
  }
}

export default AssessmentToolPreviewCard;

AssessmentToolPreviewCard.defaultProps = {
  type: "RUBRIC",
  assessmentToolsConStyle: {
    overflowX: "unset",
  },
};
