import { buildSearchFlatTree } from "Utils";
import { colors } from "Constants";
import { buildSlimTree } from "Utils";

export const getPYPElementsRowsFromField = ({
  uid,
  applySearchFilter = true,
  applyDropdownFilter = true,
  initialFields = [],
  searchTerm,
  selectedFilterValue,
} = {}) => {
  const field = _.find(initialFields, { uid }) || {};
  const resolvedMinimalTree = field?.resolvedMinimalTree;
  let rows = [];

  const key = field?.uid === "lp" ? "learnerProfiles" : field?.uid;
  rows = _.get(resolvedMinimalTree, key, []);
  if (_.isEmpty(rows)) rows = _.get(resolvedMinimalTree, "nodes", []);

  // Apply dropdown filter
  if (applyDropdownFilter && !_.isEmpty(selectedFilterValue)) {
    const filterUid = selectedFilterValue.uid;
    if (uid !== filterUid) return [];

    // Filter benchmark if subject filter is applied
    if (field.isNestedView) {
      rows = buildSlimTree({
        nodes: rows,
        selIds: [selectedFilterValue.value],
      });
    }
  }

  // Apply search term filter to data
  let filteredRows = [];
  if (applySearchFilter && !_.isEmpty(searchTerm)) {
    if (field.isNestedView)
      filteredRows = buildSearchFlatTree({
        searchText: searchTerm,
        nodes: rows,
        matchingTextStyle: {
          color: colors.blue29,
        },
      });
    else {
      _.forEach(rows, row => {
        if (row.label?.toLowerCase()?.includes(searchTerm.toLowerCase()))
          filteredRows.push(row);
      });
    }
    rows = filteredRows;
  }

  return rows;
};

export const arrayToSentence = arr => {
  return (
    arr.slice(0, -2).join(", ") +
    (arr.slice(0, -2).length ? ", " : "") +
    arr.slice(-2).join(" and ")
  );
};

export const getPYPElementsRowsFromUID = ({
  uid,
  applySearchFilter = true,
  applyDropdownFilter = true,
  initialFields,
  filteredFields,
}) => {
  if (applySearchFilter && applyDropdownFilter) {
    return _.get(_.find(filteredFields, { uid }), "rows");
  }

  const field = _.find(initialFields, { uid });
  let rows = [];
  const resolvedMinimalTree = field?.resolvedMinimalTree;

  const key = field?.uid === "lp" ? "learnerProfiles" : field?.uid;
  rows = _.get(resolvedMinimalTree, key, []);
  if (_.isEmpty(rows)) rows = _.get(resolvedMinimalTree, "nodes", []);

  return rows ?? [];
};

export function isEmpty(array) {
  let isArrayEmpty = true;
  _.forEach(array, item => {
    if (!_.isEmpty(item)) isArrayEmpty = false;
  });

  return isArrayEmpty;
}
