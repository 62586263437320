import React, { useRef } from "react";
import classes from "./CreateParentAccount.scss";
import { FormTextInput } from "UIComponents";
import { Button } from "@toddle-design/web";
import { isNewFamilySignUpFlowEnabled } from "config";

const PASSWORD_VALIDATION_TEXT =
  "Password should be atleast 8 characters in length";

const CreateAccountParent = props => {
  const {
    renderBackButtonComponent,
    updateInputField,
    inputStyle,
    data: {
      password,
      email,
      firstName,
      lastName,
      confirmPassword,
      userId,
    } = {},
    customStyle: {
      containerStyle,
      headerStyle,
      subTextStyle,
      buttonStyle,
    } = {},
    updateCurrentScreen,
    renderErrorComponent,
    t,
    isFamilyClassInviteRoute,
    isFamilyInviteRoute,
    updateInvitedFamilyData,
  } = props;

  // create array of refs for 5 input fields
  const inputRef = useRef([]);
  inputRef.current = _.map(
    Array(5),
    (_, i) => (inputRef.current[i] = React.createRef())
  );

  const getScreenTypeOnBackClick = () => {
    if (isNewFamilySignUpFlowEnabled) {
      // 1. for class invite, we don't need to go to verifyParentInviteCode because we have region in the url of class invite
      // 2. for email invite, we navigate user to login screen,
      //       If parent decides to create account after navigating to login screen,
      //       then it will work as normal signup flow because we have cleared the context of invite parent from redux and params
      return isFamilyClassInviteRoute || isFamilyInviteRoute
        ? "parentLogin"
        : "verifyParentInviteCode";
    } else {
      return "parentLogin";
    }
  };

  // onClick functions
  const onBackClick = () => {
    // clear redux state of invite family
    if (isFamilyInviteRoute) {
      updateInvitedFamilyData({});
    }

    updateCurrentScreen({
      type: getScreenTypeOnBackClick(),
      // clear params from url for invite family
      paramsToBeRemoved: isFamilyInviteRoute ? ["metadata", "region"] : [],
    });
    updateInputField({});
  };

  const onSubmitClick = () => {
    const {
      showErrorToast,
      handleCreateParentAccount,
      createInvitedParent,
    } = props;
    const errorCount = isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    if (!!userId) {
      // this function will be called for invited parent (via email)
      createInvitedParent();
    } else {
      // this function will be called for class invite
      // this function will be called for normal signup flow
      handleCreateParentAccount();
    }
  };

  // validation functions
  const isValid = () => {
    let errorCount = 0;
    _.map(
      Array(5),
      (_, i) => (errorCount += inputRef.current[i].current.isValid())
    );
    return errorCount;
  };

  const checkEmailVerification = value => {
    const { checkEmailVerification } = props;
    return checkEmailVerification(value);
  };

  const passwordValidation = value => {
    if (value.length < 8) {
      return PASSWORD_VALIDATION_TEXT;
    }
    return "";
  };

  const confirmPasswordValidation = value => {
    if (!_.isEqual(password, value)) {
      return "Password and confirm password do not match";
    }
    return "";
  };

  // onEnter Event function
  const onEnterPress = value => {
    if (inputRef.current[value] && inputRef.current[value].current.focus) {
      inputRef.current[value].current.focus();
    }
  };

  const getCurrentAndMaxStep = () => {
    if (isNewFamilySignUpFlowEnabled) {
      return {
        currentStep: isFamilyInviteRoute || isFamilyClassInviteRoute ? null : 2,
        maxStep: isFamilyInviteRoute || isFamilyClassInviteRoute ? null : 2,
      };
    } else {
      return {
        currentStep: null,
        maxStep: null,
      };
    }
  };

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.headerButtonContainer}>
        {renderBackButtonComponent({
          onClick: onBackClick,
          buttonText: "Family account",
          ...getCurrentAndMaxStep(),
        })}
      </div>
      <div style={headerStyle}> {"Create your Toddle account"} </div>
      <div style={subTextStyle}>
        {
          "View and celebrate your child's learning moments, communicate with teachers and receive school updates, all from your Toddle account"
        }
      </div>
      <React.Fragment>
        <div className={classes.emailContainer}>
          <FormTextInput
            size={"large"}
            textInputLabel={"Email ID*"}
            value={email}
            name={"email"}
            updateInputField={updateInputField}
            error={"Enter email address"}
            ref={inputRef.current[0]}
            customValidation={checkEmailVerification}
            onEnter={() => onEnterPress(1)}
            disabled={!!userId}
          />
        </div>
        <div className={classes.inputRowContainer}>
          <FormTextInput
            size={"large"}
            textInputLabel={"First name*"}
            value={firstName}
            name={"firstName"}
            updateInputField={updateInputField}
            error={"Enter first name"}
            ref={inputRef.current[1]}
            onEnter={() => onEnterPress(2)}
          />
          <FormTextInput
            size={"large"}
            textInputLabel={"Last name*"}
            value={lastName}
            name={"lastName"}
            updateInputField={updateInputField}
            error={"Enter last name"}
            ref={inputRef.current[2]}
            onEnter={() => onEnterPress(3)}
          />
        </div>

        <div className={classes.passwordContainer}>
          <FormTextInput
            size={"large"}
            textInputLabel={"Create a password*"}
            value={password}
            name={"password"}
            updateInputField={updateInputField}
            type={"password"}
            error={"Enter password"}
            ref={inputRef.current[3]}
            onEnter={() => onEnterPress(4)}
            customValidation={passwordValidation}
          />
        </div>
        <div className={classes.passwordContainer}>
          <FormTextInput
            size={"large"}
            textInputLabel={"Confirm password*"}
            value={confirmPassword}
            name={"confirmPassword"}
            updateInputField={updateInputField}
            type={"password"}
            ref={inputRef.current[4]}
            customValidation={confirmPasswordValidation}
            error={"Enter confirm password"}
            onEnter={onSubmitClick}
          />
        </div>
        <div className={classes.bottomContainer}>
          <Button
            size={"large"}
            variant={"destructive"}
            isFullWidth={true}
            onClick={onSubmitClick}
          >
            {"Continue"}
          </Button>
        </div>
      </React.Fragment>
    </div>
  );
};

export default CreateAccountParent;
