import React, { useState } from "react";
import { graphql, compose } from "react-apollo";
import classes from "./AddClassModal.scss";
import { GroupedList, I18nHOC } from "UIComponents";
import { getStaffCoursesBasicDetailsQuery } from "Teacher/modules/TeacherQuery";
import { getStaffCoursesBasicDetailsFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { connect } from "react-redux";
import { getUserInfo } from "Login/modules/LoginModule";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getUserCoursesFilters } from "Course/modules/CourseModule";
import { getUpdatedArchivedFilters } from "Utils";
import {
  getMatchingIdsDataMemoize,
  getSuperMinusSubMemoize,
} from "modules/Services";
import { Button, IconButton, Avatar } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";
import { AVATAR_COLORS } from "Constants/colorConstants";
import { checkCategorySetApplicableTo } from "ScoreCategories/modules/ScoreCategoriesModule";

const getClassesList = (classesFromQuery, allGrades) => {
  return _.reduce(
    allGrades,
    (result, grade) => {
      const gradeCourses = _.filter(classesFromQuery, course => {
        const isCourseIncluded =
          _.findIndex(course.node.grades, { id: grade.id }) >= 0;

        return isCourseIncluded;
      });

      if (_.get(gradeCourses, "length", 0) > 0) {
        const { name, ...rest } = grade;
        result.push({
          ...rest,
          label: name,
          options: _.map(gradeCourses, course => {
            const { title, profileImage, students, id, isArchived } = _.get(
              course,
              "node",
              {}
            );
            return {
              id,
              title,
              value: id,
              profileImage,
              students,
              isArchived,
              icon: (
                <Avatar
                  size={"x-small"}
                  shade={"light"}
                  iconType={"group"}
                  color={AVATAR_COLORS[id % _.size(AVATAR_COLORS)]}
                  src={profileImage}
                />
              ),
            };
          }),
        });
      }

      return result;
    },
    []
  );
};

const AddClassModal = React.memo(
  ({
    classesFromQuery,
    isData,
    isLoading,
    onClickAddClasses,
    onClickClose,
    coursesSearchText,
    changeCoursesSeachText,
    selectedTimeSlot,
    t,
    allGrades,
    scoreCategoryLabel,
    subjectName,
  }) => {
    const [selectedClasses, setSelectedClasses] = useState([]);

    const updateSelectedItems = items => {
      const updatedItems = _.map(items, item => {
        const totalStudents = _.get(item, "students", []);
        const state = {
          state: "PUBLISHED",
          scheduleDate: _.get(selectedTimeSlot, "startDate", null),
          scheduleTime: _.get(selectedTimeSlot, "startTime", null),
        };
        return {
          ..._.omit(item, "icon"),
          totalStudents,
          state,
        };
      });
      setSelectedClasses(updatedItems);
    };

    // case 1 : classesFromQueryIsEmpty ? hideSearchInput + hideFooter
    // case 2 : searchTextIsNotEmpty and classesFromQueryisEmpty ? hideFooter
    // (it means that user has searched something but got no result back)
    // case 3 : searchTextIsEmpty and classesFromQueryIsNotEmpty ? showSearchInput + showFooter
    const showEmptyState = _.isEmpty(classesFromQuery);
    const showSearchInput = !showEmptyState || !_.isEmpty(coursesSearchText);

    return (
      <div className={classes.container}>
        <div className={classes.headerCon}>
          <div className={classes.addClassesLabel}>
            {t("common:add_with_label", { label: t("common:class") })}
          </div>

          <IconButton
            icon={<CloseOutlined />}
            variant={"plain"}
            onClick={onClickClose}
          />
        </div>
        <div className={classes.modalBody}>
          <GroupedList
            items={getClassesList(classesFromQuery, allGrades)}
            showSearchInput={showSearchInput}
            searchValue={coursesSearchText}
            onChangeSearchValue={changeCoursesSeachText}
            selectedItems={selectedClasses}
            setSelectedItems={updateSelectedItems}
            isData={isData}
            isLoading={isLoading}
            showEmptyState={showEmptyState}
            emptyItemsTitle={t("classRoom:yet_to_add_a_class")}
            emptyItemsSubtitle={t("classRoom:no_classes_to_select")}
            emptySearchTitle={t("common:noResultHeading")}
            emptySearchSubtitle={t("common:no_items_matching_your_search")}
            category={scoreCategoryLabel}
            subject={subjectName}
          />
        </div>
        {!showEmptyState && (
          <div className={classes.footerCon}>
            <Button
              size={"medium"}
              onClick={() => onClickAddClasses(selectedClasses)}
              variant={"progressive"}
            >
              {t("common:add")}
            </Button>
          </div>
        )}
      </div>
    );
  }
);

AddClassModal.displayName = "AddClassModal";

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType }) || {};
  const courseId = _.get(ownProps.params, "course_id", undefined);

  const organizationId = _.get(userInfo, "org_id");

  const currentCurriculumProgramType =
    state.platform.currentCurriculumProgramType;
  const currentCurriculumProgramId = state.platform.currentCurriculumProgram.id;
  const organizationCurriculumPrograms =
    state.platform.organizationCurriculumPrograms;
  let staffCourseFilter = {};

  if (courseId) {
    const courseDetails = getCourseDetailsFromCache({
      id: courseId,
    });
    const curriculumProgramId = _.get(courseDetails, "curriculumProgram.id");
    staffCourseFilter = { curriculumProgramIds: [curriculumProgramId] };
  } else {
    staffCourseFilter = getUserCoursesFilters({
      currentCurriculumProgramType,
      organizationCurriculumPrograms,
      currentCurriculumProgramId,
    });
  }

  staffCourseFilter = getUpdatedArchivedFilters(
    {
      ...staffCourseFilter,
      searchText: ownProps.coursesSearchText,
    },
    {}
  );

  const isCourseApplicableToCategorySet =
    checkCategorySetApplicableTo({
      organizationId,
    }) === "COURSE";

  return {
    staffCourseFilter,
    userId: userInfo.id,
    isCourseApplicableToCategorySet,
  };
};

const getStudents = classItem => {
  const studentsEdges = _.get(classItem, "students.edges", []);
  return _.map(studentsEdges, ({ node }) => node);
};

const getRestructuredData = ({ classes }) => {
  return _.map(classes, classItem => {
    return {
      node: {
        ...classItem,
        students: getStudents(classItem),
      },
    };
  });
};
export const getRestructuredDataMemoize = _.memoize(
  params => getRestructuredData(params),
  params => JSON.stringify(params)
);

export default compose(
  I18nHOC({ resource: ["common", "classRoom"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getStaffCoursesBasicDetailsQuery, {
    name: "getStaffCoursesBasicDetails",
    options: ({ userId, staffCourseFilter, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        filters: staffCourseFilter,
        portalType,
      },
    }),
    props({
      getStaffCoursesBasicDetails,
      ownProps: {
        userId,
        courses,
        staffCourseFilter,
        courseIdsOfScoreCategorySet,
        isCourseApplicableToCategorySet,
      },
    }) {
      const queryData = getStaffCoursesBasicDetailsFromCache({
        userId,
        filters: staffCourseFilter,
      });
      const allClasses = _.get(queryData, "courses", []);
      const allGrades = _.get(queryData, "allGrades", []);

      let classesToDisplay = [];
      let gradesToConsider = [];
      if (!_.isEmpty(allClasses)) {
        // courses array is a state, and will contain selected classes details
        const selectedClasses = courses;

        // if score category is selected then classes associated with that category should be shown in modal
        // otherwise, show all classes
        const classesToConsider =
          !_.isEmpty(courseIdsOfScoreCategorySet) &&
          isCourseApplicableToCategorySet
            ? getMatchingIdsDataMemoize({
                arrayOfObject: allClasses,
                idsArray: courseIdsOfScoreCategorySet,
              })
            : allClasses;

        gradesToConsider =
          !_.isEmpty(courseIdsOfScoreCategorySet) &&
          !isCourseApplicableToCategorySet
            ? getMatchingIdsDataMemoize({
                arrayOfObject: allGrades,
                idsArray: courseIdsOfScoreCategorySet,
              })
            : allGrades;

        // get unselected classes
        const unselectedClasses = getSuperMinusSubMemoize({
          superSet: classesToConsider,
          subSet: selectedClasses,
        });

        // restructure data
        classesToDisplay = getRestructuredDataMemoize({
          classes: unselectedClasses,
        });
      }

      return {
        networkStatus: getStaffCoursesBasicDetails.networkStatus,
        isData: !_.isEmpty(queryData),
        classesFromQuery: classesToDisplay,
        allGrades: gradesToConsider,
        isLoading:
          getStaffCoursesBasicDetails["networkStatus"] == 1 ||
          getStaffCoursesBasicDetails["networkStatus"] == 2,
      };
    },
  })
)(AddClassModal);
