import React, { useState, useEffect, useRef } from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import classes from "./ScheduleCallSettings.scss";
import {
  InputTextField,
  I18nHOC,
  DateSelector,
  TimeSelector,
  UIModal,
  UIButton,
  RadioButtonList,
  FullScreenLoader,
  OauthPopup,
} from "UIComponents";
import ClassCard from "../ClassCard";
import AddClassModal from "../AddClassModal";
import moment from "moment";
import {
  getUserZoomAuthorizedStatus,
  generateZoomRefreshToken,
} from "modules/Services";
import { ZOOM_AUTH_URL, redirectUri } from "Constants";
import update from "immutability-helper";
import Microsoft from "lib/microsoft";

const styles = {
  radioButtonOptionStyle: {
    flexBasis: "unset",
    marginRight: "24px",
  },
  radioButtonConStyle: {
    marginBottom: "24px",
  },
  videoOptionLabelStyle: {
    marginBottom: "28px",
  },
};

const VIDEO_CALL_OPTIONS = [
  {
    label: "common:zoom",
    value: "ZOOM",
  },
  {
    label: "common:microsoft_teams",
    value: "MICROSOFT",
  },
  {
    label: "common:add_meeting_link",
    value: "OTHER",
  },
];

const MeetingTypeComponent = React.memo(
  ({
    meetingType,
    meetingURL,
    meetingChangesLocal,
    isZoomAuthorized,
    isMicrosoftAuthorized,
    onClickLinkZoom,
    onClickLinkMicrosoft,
    t,
  }) => {
    switch (meetingType) {
      case "OTHER":
        return (
          <InputTextField
            name="url"
            placeholder={t("common:enter_with_label", {
              label: t("common:link"),
            })}
            updateInputField={meetingChangesLocal}
            value={meetingURL}
            isRequired={true}
          />
        );
      case "ZOOM":
        return (
          <React.Fragment>
            {isZoomAuthorized ? (
              <div className={classes.linkedTextCon}>
                {t("common:zoom_link_generate_auto")}
              </div>
            ) : (
              <UIButton
                color={"blue"}
                type={"hollow"}
                containerStyle={{ marginTop: 16 }}
                onClick={onClickLinkZoom}
              >
                {t("common:link_zoom_account")}
              </UIButton>
            )}
          </React.Fragment>
        );
      case "MICROSOFT":
        return (
          <React.Fragment>
            {!_.isEmpty(isMicrosoftAuthorized) ? (
              <div className={classes.linkedTextCon}>
                {t("common:zoom_link_generate_auto")}
              </div>
            ) : (
              <UIButton
                color={"blue"}
                type={"hollow"}
                containerStyle={{ marginTop: 16 }}
                onClick={onClickLinkMicrosoft}
              >
                {t("common:link_microsoft_account")}
              </UIButton>
            )}
          </React.Fragment>
        );
    }
  }
);

const ScheduleCallSettings = React.memo(props => {
  const {
    resource,
    meeting,
    updateSelectedItems,
    courses,
    courseId,
    portalType,
    showAddClassButton,
    getUserZoomAuthorizedStatus,
    generateZoomRefreshToken,
    t,
    curriculumProgram,
  } = props;

  const title = _.get(resource, "title", "");
  const meetingURL = _.get(meeting, "url", "");
  const meetingType = _.get(meeting, "type", "");
  const isZoomAuthorized = _.get(meeting, "isZoomAuthorized", false);
  const isMicrosoftAuthorized = _.get(meeting, "microsoftAuthToken", "");

  const scheduleDate = _.get(meeting, "scheduleDate", "");
  const scheduleTime = _.get(meeting, "scheduleTime", "");

  const [showAddClassModal, changeShowAddClassModal] = useState(false);
  const [coursesSearchText, changeCoursesSeachText] = useState("");
  const [showFullScreenLoader, toggleFullScreenLoader] = useState(false);

  const authPopUpRef = useRef(null);

  useEffect(() => {
    const getStatus = async () => {
      toggleFullScreenLoader(true);
      const values = await Promise.all([
        getZoomAuthStatusLocal(),
        getMicrosoftAuthorizeStatus(),
      ]);
      meetingChangesLocal({
        isZoomAuthorized: values[0],
        microsoftAuthToken: values[1],
      });
      toggleFullScreenLoader(false);
    };
    getStatus();
  }, []);

  const resourceChangesLocal = params => {
    updateSelectedItems({
      resource: { ...resource, ...params },
    });
  };

  const meetingChangesLocal = params => {
    updateSelectedItems({
      meeting: { ...meeting, ...params },
    });
  };

  const getZoomAuthStatusLocal = async () => {
    const zoomAuthorizedStatus = await getUserZoomAuthorizedStatus();
    return zoomAuthorizedStatus;
  };

  const getMicrosoftAuthorizeStatus = async () => {
    return new Promise((resolve, reject) => {
      Microsoft.getUserProfile({
        successCallback: accessToken => {
          resolve(accessToken);
        },
        failureCallback: () => {
          resolve("");
        },
      });
    });
  };

  const updatedClassItem = ({ id, params }) => {
    const courseIndex = _.findIndex(courses, { id });
    updateSelectedItems({
      courses: update(courses, {
        [courseIndex]: { $set: { ...courses[courseIndex], ...params } },
      }),
    });
  };

  const onClickAddClasses = params => {
    updateSelectedItems({
      courses: [...courses, ...params],
    });
    changeShowAddClassModal(false);
  };

  const onClickRemoveClass = id => {
    const removeIndex = _.findIndex(courses, course => course.id === id);
    updateSelectedItems({
      courses: update(courses, { $splice: [[removeIndex, 1]] }),
    });
  };

  const onClickLinkZoom = () => {
    if (authPopUpRef.current) {
      authPopUpRef.current.createPopup();
    }
  };

  const onClickLinkMicrosoft = () => {
    Microsoft.loginPopup({
      successCallback: accessToken => {
        meetingChangesLocal({ microsoftAuthToken: accessToken });
      },
      failureCallback: () => {
        onClickLinkMicrosoft();
      },
    });
  };

  const onAuthCallBack = async accessCode => {
    toggleFullScreenLoader(true);
    const isSuccess = await generateZoomRefreshToken({
      accessCode,
      redirectUri,
    });
    toggleFullScreenLoader(false);
    if (isSuccess) {
      meetingChangesLocal({ isZoomAuthorized: true });
    }
  };

  const clonedVideoCallOptions = _.map(VIDEO_CALL_OPTIONS, item => {
    return {
      ...item,
      label: t(`${item.label}`),
    };
  });

  return (
    <div className={classes.container}>
      <OauthPopup
        url={ZOOM_AUTH_URL}
        onCode={onAuthCallBack}
        ref={authPopUpRef}
      ></OauthPopup>
      <div className={classes.inputField}>
        <InputTextField
          label={t("common:video_meeting_title")}
          name="title"
          placeholder={t("common:enter_with_label", {
            label: t("common:video_meeting_title"),
          })}
          updateInputField={resourceChangesLocal}
          value={title}
          isRequired={true}
        />
      </div>
      <RadioButtonList
        label={t("common:Video_call_location")}
        containerStyle={styles.radioButtonConStyle}
        labelContainerStyle={styles.videoOptionLabelStyle}
        options={clonedVideoCallOptions}
        name={"type"}
        value={meetingType}
        updateInputField={meetingChangesLocal}
        isRequired={true}
        showErrorText={true}
        listItemStyle={styles.radioButtonOptionStyle}
      />
      <MeetingTypeComponent
        meetingType={meetingType}
        meetingURL={meetingURL}
        meetingChangesLocal={meetingChangesLocal}
        isZoomAuthorized={isZoomAuthorized}
        isMicrosoftAuthorized={isMicrosoftAuthorized}
        onClickLinkZoom={onClickLinkZoom}
        onClickLinkMicrosoft={onClickLinkMicrosoft}
        t={t}
      />
      <div className={classes.horizontalRowContainer}>
        <DateSelector
          updateInputField={meetingChangesLocal}
          value={scheduleDate}
          name={"scheduleDate"}
          isValueMomentObj={false}
          label={t("common:date")}
          minDate={moment().subtract(1, "day")}
        ></DateSelector>
        <TimeSelector
          value={scheduleTime}
          SelectedTimeformat="HH:mm"
          label={t("common:time")}
          name={"scheduleTime"}
          isValueMomentObj={false}
          updateInputField={meetingChangesLocal}
          isDisabled={_.isEmpty(scheduleDate)}
          startTime={moment().format("HH:mm")}
        ></TimeSelector>
      </div>
      <div className={classes.coursesContainer}>
        {_.map(courses, course => {
          return (
            <ClassCard
              course={course}
              key={course.id}
              courseId={courseId}
              onClickRemoveClass={onClickRemoveClass}
              updateItem={params => updatedClassItem({ id: course.id, params })}
              t={t}
              portalType={portalType}
            ></ClassCard>
          );
        })}
      </div>
      {showAddClassButton && (
        <UIButton
          size="sm"
          color={"blue"}
          type={"hollow"}
          containerStyle={{ marginTop: 16, width: "max-content" }}
          onClick={() => changeShowAddClassModal(true)}
        >
          {t("common:add_with_label", { label: t("common:class") })}
        </UIButton>
      )}
      {showAddClassModal && (
        <UIModal
          isOpen={showAddClassModal}
          modalContent={classes.addClassModalContainer}
          onRequestClose={() => changeShowAddClassModal(false)}
        >
          <AddClassModal
            curriculumProgram={curriculumProgram}
            onClickClose={() => changeShowAddClassModal(false)}
            onClickAddClasses={onClickAddClasses}
            coursesSearchText={coursesSearchText}
            changeCoursesSeachText={changeCoursesSeachText}
            courses={courses}
            portalType={portalType}
            subText={t("classRoom:add_class_subText1")}
          />
        </UIModal>
      )}
      {!!showFullScreenLoader && <FullScreenLoader />}
    </div>
  );
});

const mapActionCreators = {
  getUserZoomAuthorizedStatus,
  generateZoomRefreshToken,
};

export default compose(
  I18nHOC({ resource: ["common", "classRoom"] }),
  connect(null, mapActionCreators)
)(ScheduleCallSettings);
