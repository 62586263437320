import client from "apolloClient";
import { getLELibraryFeedQuery } from "./LELibraryQueries";

export const getLELibraryFeedFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getLELibraryFeedQuery,
      variables: params,
    });
  } catch (e) {
    console.error(e);
  }
  return queryData != null ? queryData : {};
};
