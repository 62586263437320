import React from "react";
import classes from "./CheckboxGroup.scss";
import classNames from "classnames";
import { CheckListContainer, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import { compose } from "react-apollo";
import { multiLineEllipsis } from "Utils";
import { Checkbox } from "@toddle-design/web";

const styles = {
  labelStyle: {
    flexBasis: "30%",
  },
  content: {
    color: colors.gray48,
    fontSize: "1.4rem",
  },
};

class CheckboxGroup extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleGroupCheckboxUpdate = (group, status, disabledList) => {
    const { value, options } = this.props;
    const selectedGroup = _.find(options, ({ label }) => label === group);
    let selectedGroupValues = _.map(
      selectedGroup.options,
      option => option.value
    );

    if (disabledList) {
      selectedGroupValues = _.filter(
        selectedGroupValues,
        selectedGroupValue =>
          _.findIndex(
            disabledList,
            disabledItem => disabledItem === selectedGroupValue
          ) === -1
      );
    }

    let updatedValues = [];

    if (status) updatedValues = _.uniq([...value, ...selectedGroupValues]);
    else
      updatedValues = _.filter(
        value,
        id => !_.includes(selectedGroupValues, id)
      );

    this.props.updateInputField(updatedValues);
  };

  handleCheckboxUpdate = _updatedValues => {
    const [updatedValues] = Object.values(_updatedValues);
    this.props.updateInputField(updatedValues);
  };

  toggleSelectAllOptions = (status, disabledList) => {
    const { options } = this.props;
    let updatedValues = [];

    if (status) {
      _.forEach(options, group => {
        const { options: groupOptions } = group;
        updatedValues = [...updatedValues, ..._.map(groupOptions, "value", [])];
      });
      updatedValues = _.uniq(updatedValues);
    } else updatedValues = [];
    if (disabledList) {
      updatedValues = _.filter(
        updatedValues,
        updatedValue =>
          _.findIndex(
            disabledList,
            disabledItem => disabledItem === updatedValue
          ) === -1
      );
    }

    this.props.updateInputField(updatedValues);
  };

  getSelectAllStatus = () => {
    const { options, value } = this.props;
    let allOptionsSelected = true;
    const groupStatus = {};

    _.forEach(options, group => {
      const { label, options: groupOptions } = group;
      const allSelectedInGroup = _.every(
        groupOptions,
        option =>
          _.includes(value, option.value) ||
          _.includes(this.props.disabledList, option.value)
      );
      groupStatus[label] = allSelectedInGroup;
      allOptionsSelected = allOptionsSelected && allSelectedInGroup;
    });
    return {
      allOptionsSelected,
      groupStatus,
    };
  };

  render() {
    const {
      options,
      value,
      searchText,
      t,
      checkboxGroupName,
      checkboxItemStyle,
      disabledList,
      tooltipPosition,
      checkboxItemLabelStyle,
    } = this.props;

    if (!options.length && searchText)
      return <div className={classes.noMatchFound}>No match found.</div>;

    const { allOptionsSelected, groupStatus } = this.getSelectAllStatus();
    return (
      <div style={{ width: "100%" }}>
        <div className={classes.header}>
          <div className={classes.groupName}>
            {t("all_with_label", { label: checkboxGroupName })}
          </div>
          <div>
            <Checkbox
              isChecked={allOptionsSelected}
              onChange={() =>
                this.toggleSelectAllOptions(!allOptionsSelected, disabledList)
              }
              style={{
                alignItems: "center",
              }}
              size="x-small"
            >
              <span style={checkboxItemLabelStyle}>
                {t("select_all_with_label", { label: checkboxGroupName })}
              </span>
            </Checkbox>
          </div>
        </div>

        {_.map(options, group => {
          const { label: groupName, options: groupOptions } = group;

          return (
            <div key={group.label} className={classes.container}>
              <div className={classes.header}>
                <div className={classes.groupName}>{groupName}</div>
                <div>
                  <Checkbox
                    isChecked={groupStatus[groupName]}
                    onChange={() =>
                      this.handleGroupCheckboxUpdate(
                        groupName,
                        !groupStatus[groupName],
                        disabledList
                      )
                    }
                    size="x-small"
                  >
                    <span style={checkboxItemLabelStyle}>
                      {t("all_classes_with_label", { label: groupName })}
                    </span>
                  </Checkbox>
                </div>
              </div>

              <CheckListContainer
                name={groupName}
                options={groupOptions}
                value={value}
                updateInputField={this.handleCheckboxUpdate}
                checkboxItemStyle={checkboxItemStyle}
                checkboxItemLabelStyle={checkboxItemLabelStyle}
                listItemStyle={{ flexBasis: "50%" }}
                fillColor={colors.blue29}
                checkBoxStyles={{ width: "18px", height: "18px" }}
                CheckBoxLabelStyles={{
                  ...multiLineEllipsis({ lineHeight: 1.4, lineCount: 1 }),
                }}
                labelStyle={styles.labelStyle}
                showAllOption={false}
                disabledList={disabledList}
                tooltipPosition={tooltipPosition}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default compose(I18nHOC({ resource: "common" }))(CheckboxGroup);
