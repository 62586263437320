import { colors, fontStyle } from "Constants";

export const filterSearchStyle = {
  customStyle: {
    paddingBottom: "4px",
    border: "unset",
    borderBottom: `1px solid ${colors.gray72}`,
    borderRadius: 0,
    ...fontStyle.medium,
  },

  searchSvgStyle: {
    marginTop: "6px",
    marginRight: "8px",
    marginLeft: 0,
  },

  searchIconStyle: {
    height: 16,
    width: 16,
  },

  cancelIconStyle: {
    height: 12,
    width: 12,
  },

  deleteSvgStyle: {
    marginRight: 0,
  },
};

export const listItemStyle = {
  flexBasis: "100%",
  margin: "10px 0",
};
