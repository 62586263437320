import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "subject-timeline",
  component: withAsyncRouteLoading(
    () =>
      new Promise(cb => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          "planning-insights"
        );
      })
  ),
});
