import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { getResourceFromUnitPlanQuery } from "AssessmentEvaluation/modules/AssessmentEvaluationQuery";
import {
  getAssessmentOfUnitQuery,
  getParentAssesmentQuery,
} from "Assessments/modules/AssessmentQuery";
import {
  getAssessmentOfUnitFromCache,
  getParentAssessmentFromCache,
} from "Assessments/modules/AssessmentGraphqlHelpers";
import { getResourceFromUnitplanFromCache } from "AssessmentEvaluation/modules/AssessmentEvaluationGraphqlHelpers";
import classes from "./AssessmentSelectionStep.scss";
import { withLoader, I18nHOC, Loading } from "UIComponents";
import AssessmentFeed from "./AssessmentFeed";
import { EmptySvgIcon } from "SvgComponents";
import { checkScrollAtBottom } from "Utils";

const EmptyView = ({ t }) => {
  return (
    <div className={classes.noDataContainer}>
      <EmptySvgIcon />
      <div className={classes.createText}>
        {t("assessmentEvaluation:no_le_found")}
      </div>
      <div className={classes.createSubText}>
        {t("assessmentEvaluation:no_le_found_msg")}
      </div>
    </div>
  );
};

class AssessmentSelectionStep extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleScroll = e => {
    const containerDiv = e.target;
    const { hasNextPage, areMoreAssessmentsLoading, loadMoreFeed } = this.props;

    if (
      containerDiv &&
      hasNextPage &&
      !areMoreAssessmentsLoading &&
      checkScrollAtBottom(containerDiv)
    ) {
      loadMoreFeed();
    }
  };

  render() {
    const {
      assessments,
      selectedItems,
      t,
      type,
      styles = {},
      updateSelectedItems,
      portalType,
      areMoreAssessmentsLoading,
      cardType,
      isMultiSelect,
      disabledItems = [],
      customCardStyles,
      assessmentsCount,

      onClickView,
      customEmptyViewElement,

      cardWrapperElement,
      customHeaderElement,
    } = this.props;

    const assessmentsLength = _.size(assessments);

    return (
      <div
        className={classes.container}
        style={styles.stepContainer}
        onScroll={this.handleScroll}
      >
        {assessmentsLength > 0 ? (
          <div className={classes.resourceFeed} style={styles.feedContainer}>
            <AssessmentFeed
              items={assessments}
              selectedItems={selectedItems}
              updateSelectedItems={updateSelectedItems}
              type={type}
              portalType={portalType}
              itemFeedContainer={styles.itemFeedContainer}
              cardType={cardType}
              isMultiSelect={isMultiSelect}
              disabledItems={disabledItems}
              customCardStyles={customCardStyles}
              onClickView={onClickView}
              cardWrapperElement={cardWrapperElement}
              customHeaderElement={customHeaderElement}
              t={t}
            />
            {areMoreAssessmentsLoading && (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            )}
          </div>
        ) : customEmptyViewElement ? (
          customEmptyViewElement
        ) : (
          <EmptyView t={t} />
        )}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    assessments: [],
  };
};

const getAssessments = ({ data }) => {
  if (!data) {
    return [];
  }
  return _.map(data, item => {
    return item.node;
  });
};

const ComposedAssessmentSelection = compose(
  I18nHOC({ resource: ["assessmentEvaluation", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getResourceFromUnitPlanQuery, {
    name: "getResourceFromUnitPlan",
    skip: ({ type, parentType }) =>
      type != "ASSESSMET_EVALUATION" || parentType !== "UNIT_PLAN",
    options: ({ unitPlanId, courseId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: unitPlanId, courseId: courseId, portalType },
    }),
    props({ getResourceFromUnitPlan, ownProps: { unitPlanId, courseId } }) {
      const resourceDetails = getResourceFromUnitplanFromCache({
        unitPlanId,
        courseId,
      });
      return {
        assessments: getAssessments({
          data: _.get(resourceDetails, `resourceLibrary.edges`, []),
        }),
        isData: !!getResourceFromUnitPlan.node,
        isLoading:
          getResourceFromUnitPlan["networkStatus"] == 1 ||
          getResourceFromUnitPlan["networkStatus"] == 2,
      };
    },
  }),
  graphql(getAssessmentOfUnitQuery, {
    name: "getAssessmentOfUnit",
    skip: ({ type, parentType }) =>
      type == "ASSESSMET_EVALUATION" || parentType !== "UNIT_PLAN",
    options: ({ unitPlanId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: unitPlanId, portalType },
    }),
    props({ getAssessmentOfUnit, ownProps: { unitPlanId } }) {
      const unitPlanDetails = getAssessmentOfUnitFromCache({ unitPlanId });
      return {
        assessments: getAssessments({
          data: _.get(unitPlanDetails, `resourceLibrary.edges`, []),
        }),
        isData: !_.isEmpty(unitPlanDetails),
        isLoading:
          getAssessmentOfUnit["networkStatus"] == 1 ||
          getAssessmentOfUnit["networkStatus"] == 2,
      };
    },
  }),
  graphql(getParentAssesmentQuery, {
    name: "getParentAssesment",
    skip: ({ parentType }) => parentType !== "ORGANIZATION",
    options: ({
      parentId,
      parentType,
      searchText,
      grades,
      academicYears,
      portalType,
      units,
      curriculumProgramIds,
      excludeSnPEvidenceFilters,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: parentId,
        type: parentType,
        searchText,
        grades,
        academicYears,
        portalType,
        units,
        curriculumProgramIds,
        excludeSnPEvidenceFilters,
      },
    }),
    props({
      getParentAssesment,
      ownProps: {
        parentId,
        parentType,
        searchText,
        grades,
        units,
        academicYears,
        curriculumProgramIds,
        excludeSnPEvidenceFilters,
        portalType,
        isData,
        isLoading,
      },
    }) {
      const organizationAssesments = getParentAssessmentFromCache({
        parentId,
        parentType,
        searchText,
        grades,
        units,
        academicYears,
        excludeSnPEvidenceFilters,
        curriculumProgramIds,
      });
      const assessments = getAssessments({
        data: _.get(organizationAssesments, "assessments.edges", []),
      });
      const assessmentsCount = _.get(
        organizationAssesments,
        "assessments.totalCount",
        0
      );
      return {
        assessments,
        assessmentsCount,
        hasNextPage: _.get(
          organizationAssesments,
          "assessments.pageInfo.hasNextPage",
          false
        ),
        areMoreAssessmentsLoading: getParentAssesment["networkStatus"] === 3,
        isData: !_.isEmpty(organizationAssesments) && isData,
        isLoading:
          getParentAssesment["networkStatus"] == 1 ||
          getParentAssesment["networkStatus"] == 2 ||
          isLoading,
        loadMoreFeed: () => {
          return getParentAssesment.fetchMore({
            query: getParentAssesmentQuery,
            variables: {
              id: parentId,
              type: parentType,
              searchText,
              grades,
              academicYears,
              curriculumProgramIds,
              excludeSnPEvidenceFilters,
              portalType,
              after: _.get(
                getParentAssesment.node,
                "assessments.pageInfo.endCursor",
                ""
              ),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousItems = previousResult.node.assessments;
              const nextItems = fetchMoreResult.node.assessments;

              const previousEdges = previousItems.edges;
              const nextEdges = nextItems.edges;

              const previousPageInfo = previousItems.pageInfo;
              const nextPageInfo = nextItems.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;

              return {
                ...previousResult,
                node: {
                  ...previousResult.node,
                  assessments: {
                    ...previousItems,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withLoader
)(AssessmentSelectionStep);

ComposedAssessmentSelection.defaultProps = {
  isMultiSelect: false,
  cardType: "listCards",
  parentType: "UNIT_PLAN",
};

export default ComposedAssessmentSelection;
