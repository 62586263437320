import gql from "graphql-tag";
import { userCoursesPageFragment } from "./CoursesFragments";

export const getUserCoursesV2Query = gql`
  query getUserCoursesV2(
    $userId: ID!
    $first: Int
    $after: String
    $courseFilters: CourseFilter
    $archivedCourseFilters: CourseFilter
  ) {
    node(id: $userId, type: STAFF) {
      id
      ...staffCourses
      __typename
    }
  }

  ${userCoursesPageFragment}
`;
