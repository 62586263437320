import React from "react";
import PropTypes from "prop-types";

const FilterIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99023 18.666C15.0977 18.666 19.3066 14.4473 19.3066 9.33984C19.3066 4.24219 15.0879 0.0234375 9.99023 0.0234375C4.88281 0.0234375 0.673828 4.24219 0.673828 9.33984C0.673828 14.4473 4.89258 18.666 9.99023 18.666ZM9.99023 17.3184C5.57617 17.3184 2.02148 13.7637 2.02148 9.33984C2.02148 4.92578 5.56641 1.37109 9.99023 1.37109C14.4043 1.37109 17.959 4.92578 17.9688 9.33984C17.9785 13.7637 14.4141 17.3184 9.99023 17.3184ZM4.92188 7.44531H15.0879C15.4297 7.44531 15.6836 7.2207 15.6836 6.87891C15.6836 6.53711 15.4297 6.3125 15.0879 6.3125H4.92188C4.56055 6.3125 4.31641 6.53711 4.31641 6.87891C4.31641 7.2207 4.56055 7.44531 4.92188 7.44531ZM6.2793 10.4922H13.7207C14.0723 10.4922 14.3262 10.2676 14.3262 9.92578C14.3262 9.59375 14.0723 9.35938 13.7207 9.35938H6.2793C5.92773 9.35938 5.67383 9.59375 5.67383 9.92578C5.67383 10.2676 5.92773 10.4922 6.2793 10.4922ZM7.69531 13.5586H12.3047C12.6562 13.5586 12.9102 13.334 12.9102 12.9922C12.9102 12.6504 12.6562 12.4258 12.3047 12.4258H7.69531C7.34375 12.4258 7.08984 12.6504 7.08984 12.9922C7.08984 13.334 7.34375 13.5586 7.69531 13.5586Z"
        fill={props.fill}
      />
    </svg>
  );
};

FilterIcon.defaultProps = {
  fill: "#686868",
  height: 19,
  width: 20,
};

FilterIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default FilterIcon;
