import React from "react";
import { colors } from "Constants";
import { AddAssessment, AddNote, TagElements } from "SvgComponents";
import {
  ActionsDuotone,
  AtlDuotone,
  ContentDuotone,
  DifferentiationDuotone,
  ObjectivesDuotone,
  ReflectionPriorToTeachingDuotone,
  ReflectionAfterTeachingDuotone,
  FormativeAssessmentDuotone,
  InquiryQuestionsDuotone,
  KeyConceptDuotone,
  LearnerProfileDuotone,
  LearningStandardsDuotone,
  RelatedConceptsDuotone,
  StatementOfInquiryDuotone,
  SubjectFocusDuotone,
  SummativeAssessmentDuotone,
  IndisciplinaryCriteriaDuotone,
  InterdisciplinaryLEandTeachingStrategiesDuotone,
  LeAndTeachingStrategiesDuotone,
  PurposeOfIntegrationDuotone,
  ReflectionDuringTeachingDuotone,
  SubjectGenericDuotone,
  AssessmentsDuotone,
  FormsOfIntegrationDuotone,
  LearningExperienceDuotone,
  TransdisciplinaryThemeDuotone,
  PriorLearningDuotone,
  CognitiveDevelopmentDuotone,
  ResourceDuotone,
  SelfReflectionDuotone,
  SelfAssessmentDuotone,
  StudentAgencyDuotone,
  GlobalContextDuotone,
} from "@toddle-design/web-icons";

export const plannerIcons = {
  PicnActions: <ActionsDuotone color="blue" hasBackground={true} />,
  PicnATL: <AtlDuotone color="blue" hasBackground={true} />,
  PicnKeyConcepts: <KeyConceptDuotone color="yellow" hasBackground={true} />,
  PicnRelatedConcepts: (
    <RelatedConceptsDuotone color="blue" hasBackground={true} />
  ),
  PicnLOI: <ActionsDuotone color="blue" hasBackground={true} />,
  PicnLP: <LearnerProfileDuotone color="pink" hasBackground={true} />,
  PicnTheme: (
    <TransdisciplinaryThemeDuotone color="pink" hasBackground={true} />
  ),
  PicnPriorLearning: (
    <PriorLearningDuotone color="yellow" hasBackground={true} />
  ),
  PicnTQues: <InquiryQuestionsDuotone color="blue" hasBackground={true} />,
  PicnLearningGoals: <ObjectivesDuotone color="pink" hasBackground={true} />,
  PicnCentralIdea: (
    <PurposeOfIntegrationDuotone color="yellow" hasBackground={true} />
  ),
  PicnConnections: (
    <InterdisciplinaryLEandTeachingStrategiesDuotone
      color="pink"
      hasBackground={true}
    />
  ),
  PicnInReflections: (
    <ReflectionAfterTeachingDuotone color="yellow" hasBackground={true} />
  ),
  PicnLE: <CognitiveDevelopmentDuotone color="yellow" hasBackground={true} />,
  PicnOnAssmnts: <AssessmentsDuotone color="blue" hasBackground={true} />,
  PicnResources: <ResourceDuotone color="blue" hasBackground={true} />,
  PicnOnReflections: (
    <SelfReflectionDuotone color="pink" hasBackground={true} />
  ),
  PicnSelfAssmnt: <SelfAssessmentDuotone color="blue" hasBackground={true} />,
  PicnStudentAgency: (
    <StudentAgencyDuotone color="yellow" hasBackground={true} />
  ),
  AddAssessment: <AddAssessment width={32} height={32} fill={colors.gray48} />,
  AddNote: <AddNote width={32} height={32} fill={colors.gray48} />,
  TagElements: <TagElements width={32} height={32} fill={colors.gray48} />,

  PicnATLV2: <AtlDuotone color="pink" hasBackground={true} />,
  PicnContent: <ContentDuotone color="blue" hasBackground={true} />,
  PicnDifferentiations: (
    <DifferentiationDuotone color="violet" hasBackground={true} />
  ),
  PicnFormative: (
    <FormativeAssessmentDuotone color="yellow" hasBackground={true} />
  ),
  PicnGlobalContext: (
    <GlobalContextDuotone color="yellow" hasBackground={true} />
  ),
  PicnInquiryQues: (
    <InquiryQuestionsDuotone color="yellow" hasBackground={true} />
  ),
  PicnKeyConceptsV2: <KeyConceptDuotone color="violet" hasBackground={true} />,
  PicnLPA: <LearnerProfileDuotone color="violet" hasBackground={true} />,
  PicnLearningStandards: (
    <LearningStandardsDuotone color="blue" hasBackground={true} />
  ),
  PicnObjective: <ObjectivesDuotone color="violet" hasBackground={true} />,
  PicnPriorReflection: (
    <ReflectionPriorToTeachingDuotone color="pink" hasBackground={true} />
  ),
  PicnRelatedConceptsV2: (
    <RelatedConceptsDuotone color="yellow" hasBackground={true} />
  ),
  PicnStatementOfInquiry: (
    <StatementOfInquiryDuotone color="blue" hasBackground={true} />
  ),
  PicnSubjectFocus: <SubjectFocusDuotone color="pink" hasBackground={true} />,
  PicnSummative: (
    <SummativeAssessmentDuotone color="yellow" hasBackground={true} />
  ),

  PicnInterdisCriteria: (
    <IndisciplinaryCriteriaDuotone color="blue" hasBackground={true} />
  ),
  PicnInterLearnExpTeachStrat: (
    <InterdisciplinaryLEandTeachingStrategiesDuotone
      color="yellow"
      hasBackground={true}
    />
  ),
  PicnLearnExpTeachStrat: (
    <LeAndTeachingStrategiesDuotone color="pink" hasBackground={true} />
  ),
  PicnPurposeIntegration: (
    <PurposeOfIntegrationDuotone color="blue" hasBackground={true} />
  ),
  PicnDuringReflection: (
    <ReflectionDuringTeachingDuotone color="violet" hasBackground={true} />
  ),
  PicnDisciplinarySubGeneric: (
    <SubjectGenericDuotone color="yellow" hasBackground={true} />
  ),
  PicnAssessments: <AssessmentsDuotone color="yellow" hasBackground={true} />,
  PicnFormsIntegration: (
    <FormsOfIntegrationDuotone color="pink" hasBackground={true} />
  ),
  PicnLEV2: <LearningExperienceDuotone color="pink" hasBackground={true} />,
  PicnAfterReflection: (
    <ReflectionAfterTeachingDuotone color="blue" hasBackground={true} />
  ),
  ObjectivesBlueDuotone: (
    <ObjectivesDuotone color="blue" hasBackground={true} />
  ),
  ReflectionPriorToTeachingDuotone: (
    <ReflectionPriorToTeachingDuotone color="pink" hasBackground={true} />
  ),
  ReflectionAfterTeachingDuotone: (
    <ReflectionAfterTeachingDuotone color="blue" hasBackground={true} />
  ),
};
