import UnitPlanDetails from "UnitPlanDetails";
import { withAsyncRouteLoading } from "UIComponents";
import YearlyInsight from "./routes/YearlyInsight";

export default store => ({
  path: "yearly",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-scheduler"
        );
      })
  ),
  childRoutes: [YearlyInsight(store), UnitPlanDetails(store)],
});
