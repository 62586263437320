import { injectReducer } from "store/reducers";
import TrashLearningExperiences from "./routes/TrashLearningExperiences";
import TrashUnitPlans from "./routes/TrashUnitPlans";
import TrashOrganizationStudents from "./routes/TrashOrgStudents";
import { getNextMenuRoute } from "Administrator/modules/AdministratorModule";
import { getRelativePath } from "Utils";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "trash",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components/Trash").default;
            const reducer = require("./modules/TrashModule").default;
            injectReducer(store, { key: "trash", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-trash"
        );
      })
  ),
  onEnter(nextState, replace) {
    if (_.last(nextState.routes).path == "trash") {
      const route = getNextMenuRoute("TRASH");
      if (route) {
        replace(getRelativePath(route));
      } else {
        replace(getRelativePath("../"));
      }
    }
  },
  childRoutes: [
    TrashLearningExperiences(store),
    TrashUnitPlans(store),
    TrashOrganizationStudents(store),
  ],
});
