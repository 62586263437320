import { withAsyncRouteLoading } from "UIComponents";
import ClassRoomDetails from "ClassRoom/routes/ClassRoomDetails";
import Unit from "./routes/Unit";
import Post from "./routes/Post";
import Insight from "./routes/Insight";
import ProgressReport from "./routes/ProgressReport";
import ScopeAndSequence from "Course/routes/ScopeAndSequence";
import AssessmentDetails from "Assessments/components/AssessmentDetails";
import PlanningInsights from "PlanningInsights";

export default store => ({
  path: "links",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "teacher-course"
        );
      })
  ),
  childRoutes: [
    ClassRoomDetails(store),
    Unit(store),
    Post(store),
    Insight(store),
    ProgressReport(store),
    ScopeAndSequence(store),
    AssessmentDetails(store),
    PlanningInsights(store),
  ],
});
