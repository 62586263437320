import React, { useState, useEffect } from "react";
import { compose } from "react-apollo";
import classes from "./ScheduleAssignment.scss";
import { I18nHOC, DateSelector, TimeSelectorV2 } from "UIComponents";
import moment from "moment";
import update from "immutability-helper";
import ACLStore from "lib/aclStore";
import { Button, IconButton } from "@toddle-design/web";
import {
  CloseOutlined,
  CalendarScheduleOutlined,
} from "@toddle-design/web-icons";
import { getScheduleOrDeadlineTime } from "ClassRoom/modules/utils";

const styles = {
  timeSelectorDropdownContainerStyle: {
    width: "100%",
  },
  timeSelectorDropdownItemStyle: {
    display: "flex",
    width: "100%",
  },
  timeComponentStyle: {
    padding: "0",
  },
  dateSelectorContainerStyle: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  timeSelectorInputStyle: {
    padding: "16px 20px",
  },
};

const minuteStep = 15;

const ScheduleAssignment = React.memo(
  ({ courses, onClickDone, onClickClose, t }) => {
    const [coursesLocally, changeCoursesLocally] = useState(courses);
    const [isDoneButtonDisabled, setIsDoneButtonDisabled] = useState(false);

    //on first render update default value of schedule date and schedule time if its empty
    useEffect(() => {
      changeCoursesLocally(prev => {
        return _.map(prev, obj => {
          const scheduleDate = _.get(obj, "state.scheduleDate", "");
          const scheduleTime = _.get(obj, "state.scheduleTime", "");

          const { time, isCurrentDateSelected } = getScheduleOrDeadlineTime({
            date: scheduleDate,
          });

          const scheduleDateLocal = scheduleDate
            ? scheduleDate
            : moment().add(1, "days").format("YYYY-MM-DD");

          let scheduleTimeLocal = null;

          if (scheduleTime) {
            scheduleTimeLocal = scheduleTime;
          } else {
            if (isCurrentDateSelected) {
              scheduleTimeLocal = time;
            } else {
              scheduleTimeLocal = "00:00";
            }
          }

          return {
            ...obj,
            state: {
              ...obj.state,
              scheduleDate: scheduleDateLocal,
              scheduleTime: scheduleTimeLocal,
            },
          };
        });
      });
    }, []);

    let scrollContainer;

    const updateClassItem = ({ id, params }) => {
      if (_.isEmpty(_.get(params, "scheduleTime", ""))) {
        const scheduleDate = _.get(params, "scheduleDate", "");

        const { time } = getScheduleOrDeadlineTime({
          date: scheduleDate,
        });

        if (scheduleDate) {
          params = { ...params, scheduleTime: time };
        }
      }

      changeCoursesLocally(prev => {
        const index = _.findIndex(prev, { id });
        return update(prev, {
          [index]: {
            state: {
              $set: { ...prev[index].state, ...params },
            },
          },
        });
      });
    };

    const showReset = _.find(
      coursesLocally,
      course => !_.isEmpty(course.state.scheduleDate)
    );

    const resetScheduleData = () => {
      showReset &&
        changeCoursesLocally(prev => {
          return _.map(prev, prevCourseObj => {
            return {
              ...prevCourseObj,
              state: {
                ...prevCourseObj.state,
                scheduleDate: "",
                scheduleTime: null,
              },
            };
          });
        });
    };

    const onClickDoneLocally = () => {
      onClickDone({ courses: coursesLocally });
      onClickClose();
    };

    const onFocusDateSelector = () => {
      setTimeout(() => {
        const calendarEle = _.first(
          document.getElementsByClassName("SingleDatePicker_picker")
        );

        const scrollClientTop =
          scrollContainer && scrollContainer.getBoundingClientRect
            ? _.get(scrollContainer.getBoundingClientRect(), "top", 0)
            : 0;

        const calendarEleClientTop =
          calendarEle && calendarEle.getBoundingClientRect
            ? _.get(calendarEle.getBoundingClientRect(), "top", 0)
            : 0;

        const topPosition =
          _.get(scrollContainer, "scrollTop", 0) +
          (calendarEleClientTop - scrollClientTop) -
          104;

        if (scrollContainer) {
          scrollContainer.scrollTop = topPosition;
        }
      }, 300);
    };

    const isLECreationRevamp = ACLStore.can("FeatureFlag:RevampLeCreation");

    return (
      <div className={classes.container}>
        <div className={classes.headerCon}>
          <div className={classes.headerLabel}>
            {`${t("common:schedule")} ${t("common:post")}`}
          </div>

          <IconButton
            icon={<CloseOutlined />}
            variant="plain"
            onClick={onClickClose}
          />
        </div>
        <div className={classes.modalBody} ref={ref => (scrollContainer = ref)}>
          {_.map(
            coursesLocally,
            ({ id, title, state: { scheduleDate, scheduleTime } }) => {
              if (scheduleDate === null && scheduleTime === null) return;

              const { startTime } = getScheduleOrDeadlineTime({
                date: scheduleDate,
              });

              return (
                <div key={id} className={classes.classCard}>
                  <div className={classes.classLabel}>{title}</div>
                  <div className={classes.dateTimeCon}>
                    <DateSelector
                      updateInputField={params =>
                        updateClassItem({
                          id,
                          params,
                        })
                      }
                      value={scheduleDate}
                      name={"scheduleDate"}
                      isValueMomentObj={false}
                      label={t("common:date")}
                      minDate={moment().subtract(1, "day")}
                      onFocusInputField={onFocusDateSelector}
                      dateSelectorContainerStyle={
                        styles.dateSelectorContainerStyle
                      }
                      customInputIcon={
                        <CalendarScheduleOutlined
                          size="xx-small"
                          variant="subtle"
                        />
                      }
                    ></DateSelector>
                    <TimeSelectorV2
                      value={scheduleTime}
                      SelectedTimeformat="HH:mm"
                      label={t("common:time")}
                      name={"scheduleTime"}
                      isValueMomentObj={false}
                      updateInputField={params =>
                        updateClassItem({
                          id,
                          params,
                        })
                      }
                      isDisabled={_.isEmpty(scheduleDate)}
                      dropdownItemStyle={styles.timeSelectorDropdownItemStyle}
                      dropdownContainerStyle={
                        styles.timeSelectorDropdownContainerStyle
                      }
                      disableConfirmButton={setIsDoneButtonDisabled}
                      timeComponentStyle={styles.timeComponentStyle}
                      inputStyle={styles.timeSelectorInputStyle}
                      startTime={startTime}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className={classes.footerCon}>
          <div className={classes.resetButton} onClick={resetScheduleData}>
            {showReset && t("common:reset")}
          </div>
          <Button
            variant={isLECreationRevamp ? "primary" : "destructive"}
            onClick={onClickDoneLocally}
            disabled={isDoneButtonDisabled}
            size={"medium"}
          >
            {t("common:done")}
          </Button>
        </div>
      </div>
    );
  }
);

export default compose(I18nHOC({ resource: ["common"] }))(ScheduleAssignment);
