import React from "react";

const KIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M36.5605,20.5308 C37.3885,19.7018 38.1835,19.4258 38.9105,19.4258 C40.4645,19.4258 41.6385,20.5308 41.6385,22.0508 C41.6385,22.7068 41.4315,23.5708 40.4295,24.4688 L33.1405,31.0328 L41.4645,39.2538 C42.3305,40.1168 42.6405,40.8438 42.6405,41.6388 C42.6405,43.3298 41.3965,44.5738 39.6695,44.5738 C38.9435,44.5738 38.0805,44.2988 37.2165,43.3648 L27.2325,32.9678 L27.1645,32.9678 L27.1645,41.3278 C27.1645,44.1248 25.1955,44.5738 24.2615,44.5738 C23.3305,44.5738 21.3595,44.1248 21.3595,41.3278 L21.3595,22.6728 C21.3595,19.8748 23.3305,19.4258 24.2615,19.4258 C25.1955,19.4258 27.1645,19.8748 27.1645,22.6728 L27.1645,29.7198 L27.2675,29.7198 L36.5605,20.5308 Z"></path>
      </g>
    </svg>
  );
};

KIcon.defaultProps = {
  height: 64,
  width: 64,
};

export default KIcon;
