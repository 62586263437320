export const CURRICULUM_TYPE_MYP = "IB_MYP";
export const CURRICULUM_TYPE_PYP = "IB_PYP";
export const CURRICULUM_TYPE_UBD = "UBD";
export const CURRICULUM_TYPE_DP = "IB_DP";

// Entity Types
export const ENTITY_TYPE_PYP_PLANNER_ELEMENT = "PYP_PLANNER_ELEMENT";

// Registration Categories
export const CATEGORY_DIPLOMA = "DIPLOMA";
export const CATEGORY_COURSE = "COURSE";

//Node types
export const NODE_TYPE_IB_MYP_CURRICULUM_PROGRAM = "IB_MYP_CURRICULUM_PROGRAM";
export const NODE_TYPE_IB_DP_CURRICULUM_PROGRAM = "IB_DP_CURRICULUM_PROGRAM";

//Planner element types
export const PLANNER_ELEMENT_DP_CAS_STRANDS = "DP_CAS_STRANDS";
export const PLANNER_ELEMENT_DP_CAS_SERVICE_TYPES = "DP_CAS_SERVICE_TYPES";
export const PLANNER_ELEMENT_DP_CAS_LEARNING_OUTCOMES =
  "DP_CAS_LEARNING_OUTCOMES";
export const PLANNER_ELEMENT_DP_TOK_ESSAY_TITLE = "DP_TOK_TITLE";
export const PLANNER_ELEMENT_DP_TOK_EXHIBITION_PROMPT = "DP_TOK_PROMPT";
export const PLANNER_ELEMENT_DP_TOK_EXHIBITION_THEME = "DP_TOK_THEME";
export const PLANNER_ELEMENT_MYP_GLOBAL_CONTEXT = "MYP_GLOBAL_CONTEXT";
export const PLANNER_ELEMENT_MYP_EXPLORATION = "MYP_EXPLORATION";
export const PLANNER_ELEMENT_MYP_SERVICE_ACTION_TYPE =
  "MYP_SERVICE_ACTION_TYPE";
export const PLANNER_ELEMENT_MYP_SERVICE_ACTION_OUTCOME =
  "MYP_SERVICE_ACTION_OUTCOME";

export const ELEMENT_TYPE_PYP_LEARNING_STANDARD = "PYP_LEARNING_STANDARD";
export const ELEMENT_TYPE_MYP_LEARNING_STANDARD = "MYP_LEARNING_STANDARD";
export const ELEMENT_TYPE_UBD_LEARNING_STANDARD = "UBD_LEARNING_STANDARD";
export const ELEMENT_TYPE_DP_LEARNING_STANDARD = "DP_SUBJECT_STANDARD";
export const ELEMENT_TYPE_PYP_KEY_CONCEPT = "PYP_KEY_CONCEPT";
export const ELEMENT_TYPE_MYP_KEY_CONCEPT = "MYP_KEY_CONCEPT";
export const ELEMENT_TYPE_UBD_KEY_CONCEPT = "UBD_KEY_CONCEPT";
export const ELEMENT_TYPE_PYP_LEARNER_PROFILE = "PYP_LEARNER_PROFILE";
export const ELEMENT_TYPE_MYP_LEARNER_PROFILE = "MYP_LEARNER_PROFILE";
export const ELEMENT_TYPE_UBD_LEARNER_PROFILE = "UBD_LEARNER_PROFILE";
export const ELEMENT_TYPE_PYP_RELATED_CONCEPT = "PYP_RELATED_CONCEPT";
export const ELEMENT_TYPE_MYP_RELATED_CONCEPT = "MYP_RELATED_CONCEPT";
export const ELEMENT_TYPE_UBD_RELATED_CONCEPT = "UBD_RELATED_CONCEPT";
export const ELEMENT_TYPE_PYP_ATL = "PYP_ATL";
export const ELEMENT_TYPE_MYP_ATL = "MYP_ATL";
export const ELEMENT_TYPE_MYP_OBJECTIVES = "MYP_OBJECTIVES";
export const ELEMENT_TYPE_MYP_SUBJECT = "MYP_SUBJECT";
export const ELEMENT_TYPE_UBD_SUBJECT = "UBD_SUBJECT";
export const ELEMENT_TYPE_UBD_ATL = "UBD_ATL";
export const UBD_SUBJECT_SPECIFIC = "ubdSubjectSpecific";
export const UBD_INQUIRY = "ubdInquiry";
export const ELEMENT_TYPE_MYP_SERVICE_ACTION_OUTCOME =
  "MYP_SERVICE_ACTION_OUTCOME";
export const MYP_INTERDISCIPLINARY = "ibMYPInterdisciplinary";
export const ASSESSMENT_TYPE_QUICK_TASK = "QUICK_TASK";
export const ELEMENT_TYPE_DP_SYLLABUS = "DP_SYLLABUS";
export const ELEMENT_TYPE_DP_SUBJECT_STANDARD = "DP_SUBJECT_STANDARD";
export const ELEMENT_TYPE_DP_KNOWLEDGE_FRAMEWORK = "DP_KNOWLEDGE_FRAMEWORK";

//Template Field
export const TEMPLATE_FIELD_SERVICE_ACTION_OUTCOMES_MYP =
  "serviceActionOutcomeMYP";
export const TEMPLATE_FIELD_ATLS_MYP = "atlsMYP";
export const TEMPLATE_FIELD_OBJECTIVES_MYP = "objectivesMYP";
export const TEMPLATE_FIELD_CAS_LEARNING_OUTCOMES_DP = "casLearningOutcomes";

export const EVIDENCE_TYPE_POST = "POST";
export const EVIDENCE_TYPE_ASSESSMENT = "ASSESSMENT";
export const EVIDENCE_TYPE_UNIT = "UNIT_PLAN";
export const EVIDENCE_TYPE_PROGRESS_REPORT = "STUDENT_PROGRESS_REPORT";
export const EVIDENCE_TYPE_ASSIGNMENT = "ASSIGNMENT";

export const ASSIGNMENT_TYPE_QUICK_TASK = "qt";
export const ASSIGNMENT_TYPE_LE = "le";
export const ASSIGNMENT_TYPE_SUMMATIVE = "smt";
export const ASSIGNMENT_TYPE_FORMATIVE = "fmt";
export const ASSIGNMENT_TYPE_PERFORMANCE_TASK = "pt";
export const ASSIGNMENT_TYPE_PRE_ASSESSMENT = "pri";
export const ASSIGNMENT_TYPE_SUPPLEMENTARY = "se";
export const ASSIGNMENT_TYPE_ASSIGNMENT_RESOURCE = "ASSIGNMENT_RESOURCE";

//check box state
export const CHECKED = "checked";
export const UNCHECKED = "unchecked";
export const INTERMEDIATE = "intermediate";
export const MYP_SUBJECT_SPECIFIC = "ibMYPSubjectSpecific";
export const ELEMENT_TYPE_LEARNER_PROFILE = "LEARNER_PROFILE";
export const ELEMENT_TYPE_CONCEPT = "CONCEPT";
export const ELEMENT_TYPE_ATL = "ATL";
export const ELEMENT_TYPE_PYP_BENCHMARK = "BENCHMARK";
export const SUBJECT = "SUBJECT";
export const AUTOMATIC = "AUTOMATIC";
export const MANUAL = "MANUAL";

//constants for progress report configuration (used for advisorComment and attendance)
export const SEPARATE_PAGE = "separatePage";
export const PROGRESS_SUMMARY_PAGE = "progressSummaryPage";

//Attachment type constants
export const ATTACHMENT_TYPE_LINK = "LINK";
export const ATTACHMENT_TYPE_PHOTO = "PHOTO";
export const ATTACHMENT_TYPE_VOICE = "VOICE";
export const ATTACHMENT_TYPE_VIDEO = "VIDEO";
export const ATTACHMENT_TYPE_FILE = "FILE";
export const ATTACHMENT_TYPE_NOTE = "NOTE";

export const ATTACHMENT_TYPE_WORKBOOK = "WORKBOOK";
export const SEPARATE = "separate";
export const CUMULATIVE = "cumulative";
export const SUBJECT_REPORT_PAGE = "subjectReportPage";
export const PERCENTAGE = "percentage";
export const NUMBER = "number";

//subscription plan types
export const PLAN_TYPE_360 = "PLAN_360";
export const PLAN_TYPE_360_PILOT = "PLAN_360_PILOT";
export const PLAN_TYPE_DEMO = "PLAN_DEMO";
export const PLAN_TYPE_FREE = "PLAN_FREE";
export const PLAN_TYPE_PRO = "PLAN_PRO";
export const PLAN_TYPE_PRO_PILOT = "PLAN_PRO_PILOT";

//DP grade uids

export const DP_1 = "DP 1";
export const DP_2 = "DP 2";
export const SCORE = "score";
export const ASSESSMENT_TOOL = "assessmentTool";
export const LEARNING_GOALS = "learningGoals";

//Unit types

export const UNIT_TYPE_DP = "ibDPSubjectSpecific";

//Screen modes

export const VIEW_MODE = "VIEW";
export const EDIT_MODE = "EDIT";

//Subject Types

export const SUBJECT_TYPE_GENERIC = "GENERIC";
export const SUBJECT_TYPE_TOK = "TOK";
export const SUBJECT_TYPE_OTHERS = "OTHERS";

//Generic Constants

export const CACHE_AND_NETWORK = "cache-and-network";

//constants related to transcripts
export const SCHOOL_NAME = "SCHOOL_NAME";
export const SCHOOL_LOGO = "SCHOOL_LOGO";
export const TRANSCRIPT_TITLE = "TRANSCRIPT_TITLE";
export const ISSUE_DATE = "ISSUE_DATE";
export const SCHOOL_LOGO_CONTAINER = "SCHOOL_LOGO_CONTAINER";
export const IB_LOGO_IMAGE = "IB_LOGO_IMAGE";
export const TRANSCRIPT_HEADER = "TRANSCRIPT_HEADER";
