import React, { createRef } from "react";
import classes from "./FeedCard.scss";
import {
  ProfileCascade,
  MediaPlaceholder,
  TextMoreLess,
  I18nHOC,
  LinkWithTooltip,
  MediaModal,
} from "UIComponents";
import { colors, LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { getTimeSince, isAudioFile } from "Utils";
import {
  CommentRemarkOutlined,
  WarningCircleOutlined,
  PortfolioOutlined,
  LockOutlined,
  StudentOutlined,
  FamilyOutlined,
  GlobeOutlined,
  TeacherTagOutlined,
} from "@toddle-design/web-icons";
import classNames from "classnames";
import { AttachmentList } from "Attachment/";
import { getSharedWithString, getSharedWithStringV2 } from "Post/utils";
import { FooterButtons } from "../FooterButtons";
import ACLStore from "lib/aclStore";
import { Tooltip } from "AppComponents/ArchivedComponents";
import StudentResponse from "AppComponents/Post/routes/CreatePost/components/Screens/StudentResponse/StudentResponse";
import { CreateAssessmentSvg } from "SvgComponents";

const avatarStyle = {
  size: "medium",
  shade: "light",
  randomizeColor: true,
};

const style = {
  containerStyle: {
    padding: "16px",
    margin: "0 24px",
    borderWidth: 1,
    borderColor: colors.borderDefault,
    borderRadius: "8px",
    overflow: "hidden",
  },
  attachmentListContainerStyle: {
    gridTemplateColumns: "repeat(auto-fill, 192px)",
    gridAutoFlow: "column",
  },
  horizontalCardStyle: {
    width: "192px",
  },
  responseStyle: {
    marginTop: "16px",
  },
  moreStyle: {
    color: colors.textDefault,
  },
};

const getStudents = taggedStudents => {
  return _.map(taggedStudents, item => {
    return {
      isArchived: _.get(item, "node.isArchived"),
      name: `${item.node.firstName} ${item.node.lastName}`,
    };
  });
};

export const removeSelfMemoize = _.memoize(
  ({ taggedStudents, studentId }) => {
    return _.filter(taggedStudents, v => v.node.id !== studentId);
  },
  params => JSON.stringify(params)
);

const TaggedText = ({
  taggedStudents,
  elementPYP,
  createdByName,
  attachments,
  date,
  isPrivate,
  isHiddenFromStudents,
  userType,
  t,
  studentId,
  isOpenedFromStudentPortfolio,
  childName,
}) => {
  let taggedStudentsList;

  // if feed card is opened from student portfolio then remove self
  if (userType === "staff" && !isOpenedFromStudentPortfolio) {
    taggedStudentsList = taggedStudents;
  } else {
    // self should be removed from the list
    taggedStudentsList = removeSelfMemoize({ taggedStudents, studentId });
  }

  const hasTaggedStudents = !_.isEmpty(taggedStudentsList);

  let showTagged;
  if (userType === "staff") {
    showTagged = hasTaggedStudents;
  } else if (userType === "parent") {
    // dont show tagged if child itself is the creator of the post
    // showTagged = createdByName !== childName;

    showTagged = false;
  } else if (userType === "student") {
    showTagged = false;
  }

  const isPortfolio = taggedStudents.length > 0;
  const isPYPElement = !!elementPYP && elementPYP.length > 0;
  const timeTextClass = classNames(
    { [classes.timeText]: true },
    { [classes.evidenceTimeText]: !isPortfolio }
  );
  const hideEvidenceIndicator = userType !== "staff";

  const getSharedWithIcons = type => {
    switch (type) {
      case "everyone":
        return <GlobeOutlined size={"xxx-small"} variant={"subtle"} />;
      case "student":
        return <StudentOutlined size={"xxx-small"} variant={"subtle"} />;
      case "family":
        return <FamilyOutlined size={"xxx-small"} variant={"subtle"} />;
      case "private":
        return <LockOutlined size={"xxx-small"} variant={"subtle"} />;
      default:
        return null;
    }
  };

  const comp = (
    <div className={classes.taggedStudentsCountContainer}>
      {`${_.size(taggedStudentsList)} ${t("common:student_plural")}`}
    </div>
  );

  return (
    <div className={classes.taggedTextContainer}>
      <span className={classes.taggedCreatedByText}>
        <div className={classes.taggedCreatedByTopContainer}>
          {createdByName}
        </div>

        {/* show shared with text to only staff */}
        <div className={classes.taggedCreatedByBottomContainer}>
          <span className={timeTextClass}>{getTimeSince(date, t)}</span>
          {userType === "staff" && (
            <React.Fragment>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>
              <div className={classes.iconContainer}>
                <LinkWithTooltip
                  tooltip={getSharedWithString({
                    isPrivate,
                    isHiddenFromStudents,
                    withPrefix: true,
                    t,
                  })}
                  placement={"bottom"}
                >
                  <div className={classes.icon}>
                    {getSharedWithIcons(
                      getSharedWithStringV2({ isPrivate, isHiddenFromStudents })
                    )}
                  </div>
                </LinkWithTooltip>
              </div>
            </React.Fragment>
          )}

          {showTagged && (
            <div className={classes.taggedStudentsContainer}>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>

              <div className={classes.iconContainer}>
                <div className={classes.icon}>
                  <TeacherTagOutlined size={"xxx-small"} variant={"subtle"} />
                </div>
              </div>

              <div className={classes.taggedText}>
                {_.size(taggedStudentsList) === 1 ? (
                  _.get(getStudents(taggedStudentsList)[0], "name")
                ) : (
                  <Tooltip
                    data={getStudents(taggedStudentsList)}
                    length={0}
                    tooltipPlacement="bottom"
                    moreStyle={style.moreStyle}
                    moreJoinSymbol={`& `}
                    keyToMap="name"
                    label={t("common:students")}
                    renderComponent={comp}
                    trigger={["hover"]}
                    containerStyle={style.toolTipContainerStyle}
                    valueStyle={style.toolTipContainerStyle}
                  />
                )}
              </div>
            </div>
          )}

          {isPYPElement && !hideEvidenceIndicator && (
            <div className={classes.evidenceContainer}>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>
              <div className={classes.iconContainer}>
                <div className={classes.icon}>
                  <PortfolioOutlined size={"xxx-small"} variant={"subtle"} />
                </div>
              </div>
              <div className={classes.evidenceText}>{t("common:evidence")}</div>
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

const ModelContent = ({
  attachments,
  toggleMediaModal,
  showMediaModal,
  activeIndex,
  toggleDownloadBeginOverlay,
}) => {
  return showMediaModal ? (
    <MediaModal
      toggleMediaModal={toggleMediaModal}
      attachments={attachments}
      activeIndex={activeIndex}
      toggleDownloadBeginOverlay={toggleDownloadBeginOverlay}
    />
  ) : null;
};

class FeedCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMediaModal: false,
      showDownloadBeginOverlay: false,
    };
    this.embedPlayerRef = createRef();
  }

  onPostActionClick = params => {
    const { item, onPostActionClick } = this.props;
    onPostActionClick({ ...params, item });

    if (
      this.embedPlayerRef &&
      this.embedPlayerRef.current &&
      (params?.action === "POST_DETAIL" ||
        params?.action === "COMMENT" ||
        params?.action === "POST_EDIT")
    ) {
      this.embedPlayerRef?.current?.pause();
    }
  };

  onStudentResponseClick = id => {
    this.onPostActionClick({ id, action: "POST_DETAIL" });
  };

  onLEClick = (id, assessmentId) => {
    this.onPostActionClick({
      id,
      action: "LE_PREVIEW",
      assessmentId: assessmentId,
    });
  };

  toggleDownloadBeginOverlay = () => {
    this.setState({ showDownloadBeginOverlay: true });
  };

  toggleMediaModal = (value, activeIndex) => {
    this.setState({
      showMediaModal: value,
      activeIndex: activeIndex,
    });
  };

  onMediaClick = ({ activeIndex = 0 }) => {
    this.toggleMediaModal(true, activeIndex);
  };

  openPostDetails = () => {
    const { item } = this.props;
    const id = _.get(item, "id", "");
    const items = _.get(item, "items", {});
    const responseData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );

    !_.isEmpty(responseData)
      ? this.onStudentResponseClick(id)
      : this.onPostActionClick({ id, action: "POST_DETAIL" });
  };

  updateEmbedPlayerRef = ref => {
    if (this.embedPlayerRef) this.embedPlayerRef.current = ref;
  };

  render() {
    const {
      item: {
        title,
        id,
        createdBy: {
          id: createdById,
          firstName,
          lastName,
          profileImage,
          type: createdByType,
        },
        elementPYP,
        createdAt,
        publishedAt,
        students: { edges: taggedStudents },
        likes,
        comments,
        attachments,
        state,
        isPrivate,
        isHiddenFromStudents,
        rejectComment,
        approveOrRejectBy,
        items,
      },
      currentCommentPostId,
      onPostActionClick,
      userId,
      userType,
      childName,
      studentId,
      isOpenedFromStudentPortfolio,
      canEditOrDelete,
      t,
      isArchivedClass,
      imageMaxWidth,
    } = this.props;
    const {
      showMediaModal,
      activeIndex,
      showDownloadBeginOverlay,
    } = this.state;

    const responseData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );
    const item = _.get(responseData, "item", {});
    const itemType = _.get(responseData, "itemType", "");
    const content = _.get(item, "assignment.content", {});
    const assessmentType = _.get(content, "assessmentType.value", "");
    const assessmentId = _.get(content, "id", "");
    const isCommentOpen = currentCommentPostId == id;
    const isPublished = state == "PUBLISHED";

    const isPostApproveRejectCommentPermission = ACLStore.can(
      "FeatureFlag:NewApprovalFlowOfPost"
    );

    const isPostApproveRejectEnabled =
      _.isEqual(state, "REJECTED") && isPostApproveRejectCommentPermission;

    const studentResponseHasAudioAttachment =
      _.size(attachments) === 1 && !_.isEmpty(responseData);

    const attachmentContainer = classNames(
      { [classes.attachmentContainer]: true },
      { [classes.audioAttachmentContainer]: isAudioFile({ attachments }) },
      {
        [classes.rejectedPost]: isPostApproveRejectEnabled,
      }
    );

    const titleTextContainer = classNames(
      { [classes.titleTextContainer]: true },
      {
        [classes.rejectedPost]: isPostApproveRejectEnabled,
      }
    );

    const studentResponseContainerClass = classNames({
      [classes.studentResponseContainer]: true,
      [classes.studentResponseContainerWithAudio]: studentResponseHasAudioAttachment,
    });

    const teacherFirstName = _.get(approveOrRejectBy, "firstName", "");
    const teacherLastName = _.get(approveOrRejectBy, "lastName", "");

    const LEAssessmentType = LE_ASSESSMENT_TYPE_MAPPING[assessmentType];
    const Icon = _.get(LEAssessmentType, "filledIcon") || CreateAssessmentSvg;
    const iconColor = _.get(LEAssessmentType, "filledIconColor", colors.blue29);
    const isImageCarousel = _.filter(attachments, { type: "IMAGE" }).length > 1;
    const isPortraitImage =
      !isImageCarousel &&
      _.get(attachments, "[0].type", "") === "IMAGE" &&
      _.get(attachments, "[0].metadata.height", 0) >
        _.get(attachments, "[0].metadata.width", 0);
    const isVideo = _.get(attachments, "[0].type", "") === "VIDEO";
    // if isOnlyAudioAttachmentPresent is true, then display it in MediaPlaceholder
    // else display it in AttachmentList below TestMoreLess as it will be an audio caption
    return (
      <div className={classes.container}>
        {isPostApproveRejectEnabled && (
          <div>
            <div className={classes.rejectTextContainer}>
              <WarningCircleOutlined variant={"on"} />
              <span className={classes.rejectText}>
                {t("journal:teacher_returned_for_review", {
                  label: `${teacherFirstName} ${teacherLastName}`,
                })}
              </span>
            </div>
            {!_.isEmpty(rejectComment) && (
              <div className={classes.rejectCommentContainer}>
                <div className={classes.rejectCommentIcon}>
                  <CommentRemarkOutlined />
                </div>
                <span className={classes.rejectCommentText}>
                  {rejectComment}
                </span>
              </div>
            )}
          </div>
        )}
        {!_.isEmpty(responseData) && (
          <div className={classes.leContainer}>
            <span className={classes.leInnerContainer}>
              <div
                className={classes.assessmentContainer}
                onClick={() => this.onLEClick(id, assessmentId)}
              >
                <div className={classes.leIconContainer}>
                  <Icon
                    width={20}
                    height={20}
                    fill={iconColor}
                    fill1={colors.white}
                  />
                </div>
                <div className={classes.assessmentTitle}>
                  {_.get(content, "title.value", "")}
                </div>
              </div>
            </span>
          </div>
        )}
        <div
          className={classes.createdByWrapper}
          onClick={this.openPostDetails}
        >
          <div className={classes.createdByContainer}>
            <div className={classes.createdByLeftContainer}>
              <ProfileCascade
                items={[
                  {
                    id: createdById,
                    value: `${firstName} ${lastName ?? ""}`,
                    imageUrl: profileImage,
                  },
                ]}
                showAvatar={true}
                avatarStyles={avatarStyle}
              />
              <TaggedText
                t={t}
                taggedStudents={taggedStudents}
                elementPYP={elementPYP}
                createdByName={`${firstName} ${lastName}`}
                attachments={attachments}
                date={isPublished ? publishedAt : createdAt}
                isPrivate={isPrivate}
                isHiddenFromStudents={isHiddenFromStudents}
                userType={userType}
                childName={childName}
                studentId={studentId}
                isOpenedFromStudentPortfolio={isOpenedFromStudentPortfolio}
                isStudentResponse={!_.isEmpty(responseData)}
              />
            </div>
            {/* <div className={classes.createdByRightContainer}>
            {elementPYP && elementPYP.length > 0 && (
              <div className={classes.tagSvgIcon}>
                <EvidenceIcon />
              </div>
            )}
            {!!isPrivate ? (
              <HideFromParentIcon />
            ) : (
              <VisibleToParentIcon fill={colors.blue29} />
            )}
          </div> */}
          </div>
          {title && (
            <div className={titleTextContainer}>
              <div className={classes.titleText}>
                <TextMoreLess value={title} minHeight={230} />
              </div>
            </div>
          )}
        </div>
        <div className={attachmentContainer}>
          {!_.isEmpty(responseData) ? (
            <div className={studentResponseContainerClass}>
              {studentResponseHasAudioAttachment && (
                <MediaPlaceholder
                  attachments={attachments}
                  hasAudioCaption={true}
                  containerStyle={{ marginBottom: "8px" }}
                />
              )}
              <StudentResponse
                item={item}
                showSeeMore={true}
                containerStyle={{
                  ...style.containerStyle,
                  margin: isAudioFile({ attachments }) ? "0px" : "0 24px",
                }}
                attachmentListContainerStyle={
                  style.attachmentListContainerStyle
                }
                horizontalCardStyle={style.horizontalCardStyle}
                showEvaluation={true}
                responseStyle={style.responseStyle}
                onClickResponse={() => this.onStudentResponseClick(id)}
                isPreviewCard={true}
              />
            </div>
          ) : _.get(attachments, "length", 0) > 0 ? (
            <MediaPlaceholder
              attachments={attachments}
              imageMaxWidth={imageMaxWidth}
              imageMaxHeight={
                isPortraitImage ? window.screen.height * 0.6 : 300
              }
              iconContainerStyle={{ width: "12%" }}
              onClickMedia={this.onMediaClick}
              hasAudioCaption={true}
              audioCaptionContainerStyles={{
                padding: "0px 16px",
              }}
              showOverlayIcons={isVideo}
              showDownloadBeginOverlay={showDownloadBeginOverlay}
              getEmbedPlayerRef={this.updateEmbedPlayerRef}
            />
          ) : (
            <div className={classes.noAttachment}>
              {t("common:noAttachment")}
            </div>
          )}
        </div>
        <ModelContent
          attachments={attachments}
          toggleMediaModal={this.toggleMediaModal}
          showMediaModal={showMediaModal}
          activeIndex={activeIndex}
          toggleDownloadBeginOverlay={this.toggleDownloadBeginOverlay}
        />
        <FooterButtons
          userType={userType}
          id={id}
          likes={likes}
          comments={comments}
          state={state}
          userId={userId}
          isCommentOpen={isCommentOpen}
          onPostActionClick={this.onPostActionClick}
          canEditOrDelete={canEditOrDelete}
          createdByType={createdByType}
          t={t}
          createdByName={firstName}
          isArchivedClass={isArchivedClass}
          isAddLEToJournal={_.isEqual(itemType, "STUDENT_ASSIGNMENT")}
        />
      </div>
    );
  }
}

FeedCard.defaultProps = {
  imageMaxWidth: 580,
};

const mapStateToProps = (state, ownProps) => {
  return {
    childName: _.get(state, "login.userInfo.childName", ""),
    userType: state.login.userInfo.user_type,
    // for family portal student's id is stored in "childID"
    // for student portfolio in educator app, the student id is passed from parent component as "portfolioStudentId"
    // for student portal the id of student is stored in "id"
    studentId:
      state.login.userInfo.childID ||
      ownProps?.portfolioStudentId ||
      state.login.userInfo.id,
    isOpenedFromStudentPortfolio: !!ownProps?.portfolioStudentId,
  };
};

export default compose(
  connect(mapStateToProps, null),
  I18nHOC({ resource: ["journal", "common"] })
)(FeedCard);
