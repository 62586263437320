import { colors } from "Constants";
import { exportData } from "Utils";

export const BENCHMARK_TYPES = {
  LEARNING_OUTCOME: { label: "Learning Outcomes", color: colors.salmon60 },
  CONCEPTUAL_UNDERSTANDING: {
    label: "Conceptual Understanding",
    color: colors.blue29,
  },
};

export const isStrand = ({ depth }) => {
  return depth == 1;
};

export const LevelTypeMapping = {
  scopeandsequence: {
    nodeType: "BENCHMARK",
    valueType: "benchmarks",
    listEmptyText: t =>
      t("common:feed_empty_message", { label: t("common:subject_plural") }),
    listHeaderText: t => t("common:subject_plural"),
    levelStartIndex: 0,
    maxLevelLength: 3,
    showIcon: true,
    rootLevel: {
      id: "L0",
      value: "Subject",
      count: 0,
      __typename: "BenchmarkLevel",
    },
    canUpdateRootNode: false,
    rootNodeTitle: t => t("common:content_standards"),
    rootMenuItems: t => [{ label: t("common:settings"), value: "SETTINGS" }],
    levelDetails: {
      L0: {
        placeholder: t => t("common:for_example_label", { label: "Science" }),
      },
      L1: {
        placeholder: t => t("announcement:enter_title"),
      },
      L2: {
        placeholder: t => t("announcement:enter_title"),
      },
      L3: {
        placeholder: t => t("announcement:enter_title"),
      },
      default: t => t("announcement:enter_title"),
    },
    showAddBtnWhenEmpty: false,
  },
  atl: {
    nodeType: "ATL",
    valueType: "atls",
    showIcon: false,
    listEmptyText: t =>
      t("common:feed_empty_message", {
        label: t("academicSetup:skill_category_plural"),
      }),
    listHeaderText: t => t("academicSetup:skill_category_plural"),
    levelStartIndex: 1,
    maxLevelLength: 2,
    rootLevel: {
      id: "L1",
      value: "Category",
      __typename: "ATLLevel",
    },
    canUpdateRootNode: true,
    rootNodeTitle: t => t("academicSetup:skill_category"),
    rootMenuItems: t => [
      { label: t("common:settings"), value: "SETTINGS" },
      { label: t("common:delete"), value: "DELETE" },
    ],
    levelDetails: {
      L0: {
        placeholder: t =>
          t("common:for_example_label", { label: "Communication Skills" }),
      },
      L1: {
        placeholder: t =>
          t("common:for_example_label", {
            label: t("academicSetup:category"),
          }),
      },
      L2: {
        placeholder: t =>
          t("common:for_example_label", {
            label: t("academicSetup:skill_plural"),
          }),
      },
      L3: {
        placeholder: t =>
          t("common:for_example_label", {
            label: t("academicSetup:sub_skill_plural"),
          }),
      },
    },
    showAddBtnWhenEmpty: true,
  },
};

export const headerDataMapping = {
  showSubjects: ({ t, loginActiveTab }) => ({
    headerTitle: t("scopeAndSequence:scope_and_sequence"),
    headerDescription:
      loginActiveTab == "teacher"
        ? t("scopeAndSequence:define_scope_sequence_teacher")
        : t("scopeAndSequence:define_scope_sequence"),
    headerDescriptionStyle: { fontSize: "18px", marginBottom: 32 },
  }),
  showMenu: ({ subjectLabel, t }) => ({
    headerTitle: t("scopeAndSequence:scope_sequence_with_subject", {
      label: subjectLabel,
    }),
    headerDescription: t("scopeAndSequence:menu_header_description"),
    headerDescriptionStyle: { fontSize: "16px", marginBottom: "68px" },
  }),
  showMenuPlannerElements: ({ subjectLabel, t }) => ({
    headerTitle: t("scopeAndSequence:scope_sequence_with_subject", {
      label: subjectLabel,
    }),
    headerDescription: t(
      "scopeAndSequence:menu_header_description_planner_elements"
    ),
    headerDescriptionStyle: { fontSize: "16px", marginBottom: "68px" },
  }),
  showSelection: ({ subjectLabel, t }) => ({
    headerTitle: t("scopeAndSequence:pick_from_set"),
    headerDescription: t("scopeAndSequence:import_sns_header_description", {
      subjectLabel,
    }),
    headerDescriptionStyle: {
      marginBottom: "44px",
    },
  }),
  showUpload: ({ t }) => ({
    headerTitle: t("scopeAndSequence:bulk_upload_title"),
    headerDescription: t("scopeAndSequence:upload_sns_description"),
    headerDescriptionStyle: {
      marginBottom: "64px",
    },
  }),
  showUploadReconfigure: ({ subjectLabel, t }) => ({
    headerTitle: t("scopeAndSequence:append_sns", { subjectLabel }),
    headerDescription: t("scopeAndSequence:append_sns_description"),
    headerDescriptionStyle: {
      marginBottom: "64px",
    },
  }),

  showEditLevels: ({ subjectLabel, t }) => ({
    headerTitle: t("scopeAndSequence:start_define_sns_hierarchy"),
    headerDescription: t("scopeAndSequence:define_sns_max_levels", {
      label: subjectLabel,
    }),
    headerDescriptionStyle: {
      marginBottom: "64px",
    },
  }),
  showEditLevelsV2: ({ subjectLabel, t }) => ({
    headerTitle: t("scopeAndSequence:define_sns_hierarchy"),
    headerDescription: t("scopeAndSequence:define_sns_rename_levels", {
      label: subjectLabel,
    }),
    headerDescriptionStyle: {
      marginBottom: "64px",
    },
  }),
};

export const onDownloadTemplateClick = async ({
  subjectId,
  getSubjectSnSTemplateData,
  nodeType,
}) => {
  const result = await getSubjectSnSTemplateData(subjectId, nodeType);
  const snsTemplateData = result.data.snsUploadTemplate;
  exportData({
    data: snsTemplateData,
    outputFormat: "XLSX",
    fileName: snsTemplateData.filename,
  });
};

export const onDownloadExistingSnsClick = async ({
  subjectId,
  nodeType,
  plannerElementSetId,
  getSubjectExistingSnSTemplateData,
}) => {
  const result = await getSubjectExistingSnSTemplateData({
    subjectId,
    nodeType,
    plannerElementSetId,
  });
  const snsTemplateData = _.get(result, "data.yearData");
  exportData({
    data: snsTemplateData,
    outputFormat: "XLSX",
    fileName: snsTemplateData.filename,
  });
};
