import React from "react";
import update from "immutability-helper";
import { colors } from "Constants";
import { getOrganizationFreeStatus } from "./PlatformUtils";
import { CURRICULUM_TYPE_PYP } from "Constants/stringConstants";
import { CURRICULUM_SUBSCRIPTION_PLAN_PERM } from "Constants/permissionConstants";
import ACLStore from "lib/aclStore";
import { goToRelativeRoute } from "modules/Services";
import { getSortedCurriculums } from "Login/components/ChildrenPage/Utils";
import _ from "lodash";

export const NAME = "teacher";

//Constants
export const SET_ORGANIZATION_CURRICULUM_PROGRAMS =
  "SET_ORGANIZATION_CURRICULUM_PROGRAMS" + " " + NAME;
export const SET_ORGANIZATION_PLANNER_ELEMENT_SETS =
  "SET_ORGANIZATION_PLANNER_ELEMENT_SETS" + " " + NAME;

export const SET_ORGANIZATION_PLANNER_ELEMENT_SET_LABELS =
  "SET_ORGANIZATION_PLANNER_ELEMENT_SET_LABELS" + " " + NAME;

export const SET_CURRENT_CURRICULUM_PROGRAM_TYPE =
  "SET_CURRENT_CURRICULUM_PROGRAM_TYPE" + " " + NAME;

export const SET_CURRENT_CURRICULUM_PROGRAM =
  "SET_CURRENT_CURRICULUM_PROGRAM" + " " + NAME;

export const SET_CURRENT_PLANNER_ELEMENT_SETS =
  "SET_CURRENT_PLANNER_ELEMENT_SETS" + " " + NAME;

export const SET_ACADEMIC_YEAR_SELECTED =
  "SET_ACADEMIC_YEAR_SELECTED" + " " + NAME;

export const SET_SAMPLE_ORG_PORTAL_TYPE =
  "SET_SAMPLE_ORG_PORTAL_TYPE" + " " + NAME;

export const SET_DEMO_ORG_CURRICULUM_ID =
  "SET_DEMO_ORG_CURRICULUM_ID" + " " + NAME;

export const SET_ORGANIZATION_SUBJECT_LEVEL_TAGS =
  "SET_ORGANIZATION_SUBJECT_LEVEL_TAGS" + " " + NAME;

export const PROGRAM_LABEL_COLOR_MAPPING = {
  IB_PYP: {
    label: "PYP",
    color: colors.salmon60,
  },
  IB_MYP: {
    label: "MYP",
    color: colors.blue29,
  },
  UBD: { label: "UbD", color: colors.pink30 },
  IB_DP: { label: "DP", color: colors.pink30 },
};

export const UserCurriculumProgramsContext = React.createContext(); //Context to store User curriculum programs

//Action Creators
export const setOrganizationCurriculumPrograms = data => {
  return {
    type: SET_ORGANIZATION_CURRICULUM_PROGRAMS,
    data,
  };
};

/**
 * @deprecated since version 0.0. Will be deleted in version 0.0
 * Use setCurrentPlannerElementSets instead.
 */
export const setOrganizationPlannerElementSets = data => {
  return {
    type: SET_ORGANIZATION_PLANNER_ELEMENT_SETS,
    data,
  };
};

/**
 * @deprecated since version 0.0. Will be deleted in version 0.0
 * Use setCurrentPlannerElementSets instead.
 */
export const setOrganizationPlannerElementSetLabels = data => {
  return {
    type: SET_ORGANIZATION_PLANNER_ELEMENT_SET_LABELS,
    data,
  };
};

/**
 * @deprecated since version 0.0. Will be deleted in version 0.0
 * Use setCurriculumProgram instead.
 */
export const setCurrentCurriculumProgramType = data => {
  return {
    type: SET_CURRENT_CURRICULUM_PROGRAM_TYPE,
    data,
  };
};

export const setCurrentCurriculumProgram = data => {
  return {
    type: SET_CURRENT_CURRICULUM_PROGRAM,
    data,
  };
};

export const setCurrentPlannerElementSets = data => {
  return {
    type: SET_CURRENT_PLANNER_ELEMENT_SETS,
    data,
  };
};

export const setAcademicYearSelected = data => {
  return {
    type: SET_ACADEMIC_YEAR_SELECTED,
    data,
  };
};

export const setSampleOrgPortalType = data => {
  return {
    type: SET_SAMPLE_ORG_PORTAL_TYPE,
    data,
  };
};

export const setDemoOrgCurriculumId = data => {
  return {
    type: SET_DEMO_ORG_CURRICULUM_ID,
    data,
  };
};

export const setOrganizationSubjectLevels = data => {
  return {
    type: SET_ORGANIZATION_SUBJECT_LEVEL_TAGS,
    data,
  };
};

export const getIsCurrentAcademicYear = () => {
  return (dispatch, getState) => {
    const state = getState();
    return !!_.get(
      state,
      "platform.academicYearSelected.isCurrentAcademicYear",
      true
    );
  };
};

export const isAllowedForCurriculum = ({ allowedCurriculums }) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentCurriculumProgramType =
      state.platform?.currentCurriculumProgramType ?? "";
    return _.includes(allowedCurriculums, currentCurriculumProgramType);
  };
};

export const getCurriculumProgramFreeStatus = ({
  state,
  tabConfigurations,
}) => {
  const isFeatureEnabled = ACLStore.can(CURRICULUM_SUBSCRIPTION_PLAN_PERM);

  if (!isFeatureEnabled) {
    return _.get(state, "login.userInfo.orgIsFree", false);
  }

  const activeTab = _.get(state, "login.activeTab", "");

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  switch (activeTab) {
    case "teacher": {
      const currentCurriculumProgramId = _.get(
        state,
        "platform.currentCurriculumProgram.id",
        {}
      );

      const currentCurriculumProgram = _.find(
        organizationCurriculumPrograms,
        ({ id }) => id == currentCurriculumProgramId
      );

      /**
       * For cases when this util is called before active curriculum program is set, organization will be treated as paid.
       * Some components in ChildrenPage are shown for free curriculums. To avoid rendering them before state is set, return value is set false.
       **/
      if (_.isEmpty(currentCurriculumProgram)) {
        return false;
      }
      return _.get(currentCurriculumProgram, "subscriptionPlan.isFree", false);
    }
    case "community": {
      /**
       * Community will be decided as paid if at least one of the PYP curriculum is paid
       */
      const pypCurriculumPrograms = _.filter(
        organizationCurriculumPrograms,
        ({ type }) => type == CURRICULUM_TYPE_PYP
      );

      return getOrganizationFreeStatus({
        curriculumPrograms: pypCurriculumPrograms,
      });
    }
    case "admin": {
      const { curriculumProgramId } = _.get(tabConfigurations, "admin", {});
      const activeCurriculumProgram = _.find(
        organizationCurriculumPrograms,
        ({ id }) => id == curriculumProgramId
      );

      if (_.isEmpty(activeCurriculumProgram)) {
        return getOrganizationFreeStatus({
          curriculumPrograms: organizationCurriculumPrograms,
        });
      }

      return _.get(activeCurriculumProgram, "subscriptionPlan.isFree", false);
    }

    default: {
      /**
       *This is used for visitor and onboarding active tab
       */
      return getOrganizationFreeStatus({
        curriculumPrograms: organizationCurriculumPrograms,
      });
    }
  }
};

const getPlannerElementSetsGroupedByType = ({
  organizationCurriculumPrograms,
  curriculumProgramId,
}) => {
  const { plannerElementSets } = _.find(organizationCurriculumPrograms, {
    id: curriculumProgramId,
  });

  return _.reduce(
    plannerElementSets,
    (result, plannerElementSet) => {
      result[plannerElementSet.type] = plannerElementSet;
      return result;
    },
    {}
  );
};

/**
 * This function returns the validity status for the curriculum program
 * identifier in the route.
 *
 * An existing route can be like this:
 *
 * /platform/{curriculumProgramId}/...
 *
 * curriculumProgramId in the route can be
 *    * any random string (Invalid input),
 *    * any random number (Invalid input),
 *    * a valid curriculum program type (Old route)
 *    * a valid curriculum program id,
 *
 * @param {object} param0
 * @param {string} param0.curriculumPrograms - The valid curriculum programs
 * @param {string} param0.curriculumProgramId - The curriculum program identifier present in route
 * @returns {string} - Status enum of the curriculum program identifier in the route
 */
export const getCurriculumProgramPathValidity = ({
  validCurriculumPrograms,
  curriculumProgramIdPath,
}) => {
  if (_.isNaN(new Number(curriculumProgramIdPath))) {
    const curriculumProgram = _.find(validCurriculumPrograms, {
      type: curriculumProgramIdPath,
    });

    return _.isEmpty(curriculumProgram)
      ? "INVALID_CURRICULUM_PROGRAM_TYPE"
      : "VALID_CURRICULUM_PROGRAM_TYPE";
  } else {
    const curriculumProgram = _.find(validCurriculumPrograms, {
      id: curriculumProgramIdPath,
    });

    return _.isEmpty(curriculumProgram)
      ? "INVALID_CURRICULUM_PROGRAM_ID"
      : "VALID_CURRICULUM_PROGRAM_ID";
  }
};

export const getValidCurriculumProgram = ({
  status,
  validCurriculumPrograms,
  curriculumProgramIdPath,
}) => {
  const sortedCurriculumPrograms = getSortedCurriculums({
    curriculumPrograms: validCurriculumPrograms,
  });

  switch (status) {
    case "VALID_CURRICULUM_PROGRAM_ID":
      return _.find(sortedCurriculumPrograms, {
        id: curriculumProgramIdPath,
      });
    case "INVALID_CURRICULUM_PROGRAM_TYPE":
    case "INVALID_CURRICULUM_PROGRAM_ID":
      return _.first(sortedCurriculumPrograms);
    case "VALID_CURRICULUM_PROGRAM_TYPE":
      return _.find(sortedCurriculumPrograms, {
        type: curriculumProgramIdPath,
      });
  }
};

export const redirectToValidCurriculumProgramRoute = ({
  status,
  validCurriculumProgram,
  curriculumProgramIdPath,
}) => {
  return dispatch => {
    const oldPathname = window.location.pathname;

    switch (status) {
      case "INVALID_CURRICULUM_PROGRAM_TYPE":
      case "INVALID_CURRICULUM_PROGRAM_ID":
      case "VALID_CURRICULUM_PROGRAM_TYPE":
        {
          const newPathname = _.chain(oldPathname)
            .split("/")
            .map(path =>
              path === curriculumProgramIdPath
                ? validCurriculumProgram.id
                : path
            )
            .join("/")
            .value();

          dispatch(
            goToRelativeRoute({
              type: "replace",
              route: newPathname,
            })
          );
        }
        break;
    }
  };
};

export const setupCurriculumProgramDependencies = ({
  curriculumPrograms,
  curriculumProgramIdPath,
}) => {
  return (dispatch, getState) => {
    const organizationCurriculumPrograms = getState().platform
      .organizationCurriculumPrograms;

    const validCurriculumPrograms = _.isEmpty(curriculumPrograms)
      ? organizationCurriculumPrograms
      : curriculumPrograms;

    const status = getCurriculumProgramPathValidity({
      validCurriculumPrograms,
      curriculumProgramIdPath,
    });

    const validCurriculumProgram = getValidCurriculumProgram({
      status,
      validCurriculumPrograms,
      curriculumProgramIdPath,
    });

    // setup planner element sets
    const plannerElementSets = getPlannerElementSetsGroupedByType({
      organizationCurriculumPrograms,
      curriculumProgramId: validCurriculumProgram.id,
    });
    dispatch(setCurrentPlannerElementSets(plannerElementSets));

    // setup curriculum program
    dispatch(setCurrentCurriculumProgram(validCurriculumProgram));

    // redirect to valid curriculum program route
    dispatch(
      redirectToValidCurriculumProgramRoute({
        status,
        validCurriculumProgram,
        curriculumProgramIdPath,
      })
    );
  };
};

// Reducers
const REDUCER_HANDLERS = {
  [SET_ORGANIZATION_CURRICULUM_PROGRAMS]: (state, action) => {
    return update(state, {
      organizationCurriculumPrograms: { $set: action.data },
    });
  },

  [SET_ORGANIZATION_PLANNER_ELEMENT_SETS]: (state, action) => {
    return update(state, {
      organizationPlannerElementSets: { $set: action.data },
    });
  },
  [SET_ORGANIZATION_PLANNER_ELEMENT_SET_LABELS]: (state, action) => {
    return update(state, {
      organizationPlannerElementSetLabels: { $set: action.data },
    });
  },
  [SET_CURRENT_CURRICULUM_PROGRAM_TYPE]: (state, action) => {
    return update(state, {
      currentCurriculumProgramType: { $set: action.data },
    });
  },
  [SET_CURRENT_CURRICULUM_PROGRAM]: (state, action) => {
    return update(state, {
      currentCurriculumProgramType: { $set: action.data.type },
      currentCurriculumProgram: { $set: action.data },
    });
  },
  [SET_CURRENT_PLANNER_ELEMENT_SETS]: (state, action) => {
    return update(state, {
      currentPlannerElementSets: { $set: action.data },
    });
  },
  [SET_ORGANIZATION_SUBJECT_LEVEL_TAGS]: (state, action) => {
    return update(state, {
      organizationSubjectLevels: { $set: action.data },
    });
  },
  [SET_ACADEMIC_YEAR_SELECTED]: (state, action) => {
    const data = _.pick(action.data, [
      "id",
      "startDate",
      "endDate",
      "isCurrentAcademicYear",
      "showPreviousYearMsg",
    ]);
    return update(state, {
      academicYearSelected: {
        $set: { ...state.academicYearSelected, ...data },
      },
    });
  },
  [SET_SAMPLE_ORG_PORTAL_TYPE]: (state, action) => {
    return update(state, {
      sampleOrgPortalType: { $set: action.data },
    });
  },
  [SET_DEMO_ORG_CURRICULUM_ID]: (state, action) => {
    return update(state, {
      demoOrgCurriculumId: {
        $set: { ...state.demoOrgCurriculumId, ...action.data },
      },
    });
  },
};

// Export Reducer
const initialState = {
  organizationCurriculumPrograms: [],
  organizationPlannerElementSets: [],
  organizationPlannerElementSetLabels: [],
  currentCurriculumProgram: {
    id: null,
    type: null,
  },
  currentPlannerElementSets: {},
  currentCurriculumProgramType: null,
  curriculumPeriodDetails: [],
  organizationSubjectLevels: [],
  academicYearSelected: {
    id: null,
    startDate: null,
    endDate: null,
    isCurrentAcademicYear: true,
    showPreviousYearMsg: true,
  },
  sampleOrgPortalType: "",
  demoOrgCurriculumId: {
    IB_MYP: null,
    UBD: null,
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
