import React from "react";
import PropTypes from "prop-types";
import classes from "./AttachmentFeed.scss";
import { AttachmentItem } from "Attachment";
import { AnimateHeight, I18nHOC } from "UIComponents";
import { getCountString } from "Utils";
import classNames from "classnames";

const NUMBER_OF_ITEMS_IN_ROW = 2;
const FEED_COLLAPSE_LENGTH = 2;

class AttachmentFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { seeAll: false };
  }

  toggelOpenAll = () => {
    this.setState({ seeAll: true });
  };

  toggelCloseAll = () => {
    this.setState({ seeAll: false });
  };
  render() {
    const {
      attachments = [],
      cardmode,
      containerStyle = {},
      t,
      mode,
      attachmentItemHeight,
    } = this.props;

    const { seeAll } = this.state;
    const attachmentsCount = _.get(attachments, "length", 0);
    const lessOrMore = attachmentsCount > FEED_COLLAPSE_LENGTH;
    const getAnimationData = ({ all, length }) => {
      let height = "auto";
      let duration = 300;
      if (length == 0) {
        height = 0;
      } else {
        if (!all && length > FEED_COLLAPSE_LENGTH) {
          height = FEED_COLLAPSE_LENGTH * attachmentItemHeight;
          duration = 300;
        }
      }
      return { height, duration };
    };

    const animationData = getAnimationData({
      all: seeAll || !lessOrMore,
      length: attachmentsCount,
    });

    const countString = t("moreResource", {
      count: attachmentsCount - FEED_COLLAPSE_LENGTH,
    });

    const seeOthersStyle = classNames(
      { [classes.seeOthers]: true },
      { [classes.seeOthersDisable]: cardmode == "select" }
    );

    return (
      <AnimateHeight
        duration={animationData.duration}
        height={animationData.height}
      >
        <div className={classes.container} style={containerStyle}>
          {_.map(attachments, (attachment, index) => {
            if (index >= FEED_COLLAPSE_LENGTH && !seeAll) {
              return null;
            }

            return (
              <AttachmentItem
                key={attachment.id}
                attachment={attachment}
                mode={mode}
              />
            );
          })}
        </div>
        {lessOrMore && !seeAll && (
          <div
            className={seeOthersStyle}
            onClick={this.toggelOpenAll}
          >{`+${countString}`}</div>
        )}
        {lessOrMore && seeAll && (
          <div className={seeOthersStyle} onClick={this.toggelCloseAll}>
            {t("common:see_less")}
          </div>
        )}
      </AnimateHeight>
    );
  }
}

AttachmentFeed.propTypes = {
  attachmentItemHeight: PropTypes.number,
};

AttachmentFeed.defaultProps = {
  attachmentItemHeight: 72,
};

export default I18nHOC({ resource: ["unitPlan", "common"] })(AttachmentFeed);
