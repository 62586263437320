import React from "react";
import classes from "./AddEvidenceModalWrapper.scss";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { AddEvidenceModal } from "AppComponents";
import { evidenceModalTemplate } from "./AddEvidenceModalWrapperTemplate";
import {
  styles,
  overlayComponentStyles,
} from "./AddEvidenceModalWrapperStyles";
import { getAddEvidenceModalTemplateMemoized } from "./AddEvidenceModalWrapperUtils";
import { openLinkInNewTab, getCurrentAcademicYear } from "modules/Services";
import { FullScreenLoader } from "UIComponents";
import { updateLocalResponses } from "IBPlanner/modules/IBPlannerModule";
import update from "immutability-helper";
import {
  getPostDetailFromCache,
  getUnitPlanDetailsFromCache,
} from "IBPlanner/modules/IBGraphqlHelpers";
import { withRouter } from "react-router";
import { useI18n } from "Hooks";

const AddEvidenceModalWrapper = props => {
  const {
    currentAcademicYear,
    curriculumType,
    curriculumProgramId,
    onRequestClose,
    openLinkInNewTab,
    template,
    orgId,
    courses,
    unitPlanId,
    fieldId,
    localResponsesObject,
  } = props;

  const { t } = useI18n(["common"]);

  const responseObject = localResponsesObject[fieldId] || {};

  const [postEvidences, nonPostEvidences] = _.partition(
    _.get(responseObject, "evidence", []),
    evidence => _.has(evidence, "attachments")
  );

  const selectedPostIds = _.map(postEvidences, ({ id }) => id);

  const parentIdConfig = {
    UNIT_EVIDENCE: orgId,
  };

  const onClickAddEvidence = params => {
    const { updateLocalResponses } = props;
    const selectedPostsIds = _.get(params, "evidences.UNIT_EVIDENCE", []);

    const selectedPosts = _.map(selectedPostsIds, id => {
      return { ...getPostDetailFromCache(id), isPost: true };
    });

    updateLocalResponses({
      [fieldId]: update(responseObject, {
        evidence: evidence =>
          update(evidence || [], {
            $set: [...selectedPosts, ...nonPostEvidences],
          }),
      }),
    });

    onRequestClose();
  };

  const onClickEvidenceView = ({ data }) => {
    openLinkInNewTab({
      url: `/platform/links/post/${data.id}?preserve_query_params=true`,
    });
  };

  const initialAppliedFilters = {
    UNIT_EVIDENCE: {
      ACADEMIC_YEAR: currentAcademicYear ? [currentAcademicYear] : [],
      COURSE: courses,
      UNIT_PLAN: unitPlanId ? [unitPlanId] : [],
    },
  };

  const initiallySelectedEvidence = {
    UNIT_EVIDENCE: selectedPostIds,
  };

  const addButtonLabel = _.isEmpty(selectedPostIds)
    ? t("common:add")
    : t("common:update");

  return !_.isEmpty(initialAppliedFilters) ? (
    <AddEvidenceModal
      template={template}
      curriculumProgramType={curriculumType}
      onClickCancel={onRequestClose}
      onClickAdd={onClickAddEvidence}
      onRequestClose={onRequestClose}
      modalContent={classes.addEvidenceModalCon}
      overlayContainer={classes.addEvidenceOverlayCon}
      parentIdConfig={parentIdConfig}
      onClickView={onClickEvidenceView}
      styles={styles}
      actionsStyles={overlayComponentStyles}
      curriculumProgramId={curriculumProgramId}
      initialAppliedFilters={initialAppliedFilters}
      initiallySelectedEvidence={initiallySelectedEvidence}
      addButtonLabel={addButtonLabel}
    />
  ) : (
    <FullScreenLoader />
  );
};

const mapStateToProps = (state, ownProps) => {
  const orgId = _.get(state, "login.userInfo.org_id", "");
  const currentAcademicYear = _.get(
    getCurrentAcademicYear({ organizationId: orgId }),
    "id",
    ""
  );
  const curriculumType = _.get(ownProps, "curriculumType", "");
  const curriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms"
  );
  const curriculumProgramId = _.get(
    _.find(curriculumPrograms, curriculum => curriculum.type == curriculumType),
    "id",
    ""
  );

  const unitPlanId = _.get(ownProps, "params.unit_id", null);

  const template = getAddEvidenceModalTemplateMemoized({
    template: evidenceModalTemplate,
    curriculumProgramId,
  });

  const unitPlanData = unitPlanId
    ? getUnitPlanDetailsFromCache(unitPlanId)
    : {};

  const courseId = _.get(ownProps, "params.course_id", null);

  let courses = {};

  //When the modal is opened from within a course, Default course should be the course itself
  //And grade will be the grades to which course is mapped with
  if (courseId) {
    const courseDetails = _.find(
      _.get(unitPlanData, "courses", []),
      item => !item.isArchived && item.id == courseId
    );
    const grades = _.get(courseDetails, "grades", []);
    courses = _.map(grades, item => {
      return {
        key: item.id,
        values: [courseId],
      };
    });
  } else {
    //When the Modal is opened from Admin portal, i.e out of course then the default course filter will
    //be all the courses to which unit plan is mapped with
    courses = _.map(_.get(unitPlanData, "grades", []), id => {
      const courseIds = _.map(
        _.filter(_.get(unitPlanData, "courses", []), item => !item.isArchived),
        course => {
          if (_.some(course?.grades, { id })) {
            return course?.id;
          } else return;
        }
      );
      return {
        key: id,
        values: _.filter(courseIds, Boolean),
      };
    });
  }

  return {
    currentAcademicYear,
    curriculumProgramId,
    orgId,
    isData: true,
    isLoading: false,
    template,
    localResponsesObject: state?.planner?.responses,
    courses,
    unitPlanId,
  };
};

const mapActionCreators = { updateLocalResponses, openLinkInNewTab };

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators)
)(AddEvidenceModalWrapper);
