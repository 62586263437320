import React, { useState } from "react";

import classes from "./DiscussionStep.scss";
import {
  RichTextEditor,
  InputTextField,
  AudioRecorderSelector,
  I18nHOC,
} from "UIComponents";
import { AttachmentList } from "Attachment";
import AddAssignment from "../AddAssignment";
import { UIModal } from "UIComponents";
import { useCallbackRef, getAttachmentIdForUploadedAttachment } from "Utils";
import { PAComponent } from "lib/PermissionAware";
import { GOOGLE_PICKER_MIME_TYPES } from "Constants";
import ACLStore from "lib/aclStore";

const ADD_EVIDENCE_OPTIONS = [
  { key: "upload_device", label: "upload_device" },
  { key: "upload_from_unit_plan", label: "upload_from_unit_plan" },
  { key: "add_link", label: "add_link" },
  { key: "add_google_drive", label: "add_google_drive" },
  { key: "add_one_drive", label: "add_one_drive" },
];

const ADD_TEMPLATE_OPTIONS = [
  {
    key: "upload_device",
    label: "upload_images_from_device",
    accept: ["image/png", "image/jpg", "image/jpeg"],
  },
  {
    key: "add_google_drive",
    label: "add_google_drive",
    accept: GOOGLE_PICKER_MIME_TYPES,
  },
];

const addTemplateOptionsOnPerm = () => {
  //permission to add workbook in LE/quick task
  if (ACLStore.can("FeatureFlag:Workbook")) {
    return _.concat(
      [
        {
          key: "create_template_multipage",
          label: "create_template_multipage",
          perm: "FeatureFlag:Workbook",
        },
      ],
      [...ADD_TEMPLATE_OPTIONS]
    );
  }
  return ADD_TEMPLATE_OPTIONS;
};

const resourceModalData = {
  steps: [
    {
      label: "Select Unit",
      value: "UNIT_SELECTION",
      heading: "Select Unit",
    },
    {
      label: "Select resources",
      value: "RESOURCE_SELECTION",
      heading: "Select Resources",
    },
  ],
};

const audioRecorderSelectorStyles = {
  innerContainerStyle: { maxWidth: "600px", padding: "unset" },
};

const DicussionStep = React.memo(props => {
  const [showAddResourceModal, changeShowAddResourceModal] = useState(false);
  const [audioRecorderNodeRef, setAudioRecorderNodeRef] = useCallbackRef();
  const {
    resource: { label, attachments, title, voiceInstruction, studentTemplates },
    updateSelectedItems,
    canAddAttachment,
    contentType,
    getDiscussionPromptRef,
    t,
    setUploadingStatus,
  } = props;

  const onAttachmentListItemClick = ({ key }) => {
    if (key === "upload_from_unit_plan") {
      changeShowAddResourceModal(true);
    }
  };
  const studentTemplateValues = _.map(studentTemplates, group => {
    return { ...group, isPost: true };
  });

  const updateAttachments = ({ oldAttachment, newAttachment }) => {
    const oldAttachmentId = getAttachmentIdForUploadedAttachment({
      attachment: oldAttachment,
    });

    const newStudentTemplateGroups = _.map(
      studentTemplateValues,
      templateGroup => {
        const newTemplateGroup = { ...templateGroup };
        const attachmentsArr = _.map(
          _.get(newTemplateGroup, "attachments", []),
          attachment => {
            const newAttachment = { ...attachment };
            if ("attachmentId" in newAttachment && "id" in newAttachment) {
              if (
                newAttachment["attachmentId"] !== newAttachment["id"] &&
                !_.startsWith(newAttachment["attachmentId"], "NEW")
              ) {
                newAttachment["id"] = newAttachment["attachmentId"];
              }
            }
            return newAttachment;
          }
        );
        const foundIndex = _.findIndex(
          attachmentsArr,
          attachment => attachment.id === oldAttachmentId
        );
        if (foundIndex !== -1) {
          attachmentsArr[foundIndex] = {
            ..._.omit(newAttachment, ["streamUrl", "__typename", "isRead"]),
          };
          newTemplateGroup.attachments = attachmentsArr;
        }
        return newTemplateGroup;
      }
    );
    updateSelectedItems({ studentTemplates: newStudentTemplateGroups });
  };

  return (
    <div className={classes.container}>
      {contentType === "ASSIGNMENT_RESOURCE" && (
        <div className={classes.inputField}>
          <InputTextField
            label={t("common:title")}
            name="title"
            placeholder={t("common:enter_with_label", {
              label: t("common:title"),
            })}
            updateInputField={updateSelectedItems}
            value={title}
            isRequired={true}
          />
        </div>
      )}
      <div className={classes.inputField}>
        <RichTextEditor
          label={
            contentType == "ASSIGNMENT_RESOURCE"
              ? t("classRoom:task_guidance")
              : t("classRoom:discussion_prompt")
          }
          subText={
            contentType == "ASSIGNMENT_DISCUSSION"
              ? t("classRoom:student_response_to_discussion_prompts")
              : ""
          }
          customRef={ref => getDiscussionPromptRef(ref)}
          value={label}
          name={"label"}
          height={240}
          width={"100%"}
          updateInputField={updateSelectedItems}
          showErrorText={true}
        ></RichTextEditor>
      </div>

      <div className={classes.inputField}>
        <AudioRecorderSelector
          label={t("classRoom:voice_instruction")}
          value={voiceInstruction}
          name={"voiceInstruction"}
          updateInputField={updateSelectedItems}
          parentType={"ASSIGNMENT_VOICE_INSTRUCTION"}
          uploadStatus={setUploadingStatus}
          {...audioRecorderSelectorStyles}
        />
      </div>

      <div className={classes.attachmentField}>
        <AttachmentList
          exportAsPdf={true}
          mode={canAddAttachment ? "edit" : "view"}
          value={attachments || []}
          label={t("common:add_resource_plural")}
          name={"attachments"}
          updateInputField={updateSelectedItems}
          updateLoadingState={setUploadingStatus}
          parentType={"ASSIGNMENT"}
          parentId={`create`}
          dropDownOptions={ADD_EVIDENCE_OPTIONS}
          onDropDownOptionClick={onAttachmentListItemClick}
        />
      </div>

      {contentType == "ASSIGNMENT_RESOURCE" && (
        <PAComponent perm={"TeacherPortal:UseStudentTemplate"}>
          <div className={classes.attachmentField}>
            <AttachmentList
              exportAsPdf={true}
              shouldCallMutation={false}
              mode={canAddAttachment ? "edit" : "view"}
              value={studentTemplateValues}
              label={t("classRoom:studentTemplate_label")}
              name={"studentTemplates"}
              updateInputField={param => {
                const studentTemplates = _.get(param, "studentTemplates", []);
                updateSelectedItems({
                  ...param,
                  isStudentSubmissionEnabled:
                    _.get(studentTemplates, "length", 0) > 0,
                });
              }}
              sourceType={"STUDENT_TEMPLATE"}
              updateLoadingState={setUploadingStatus}
              parentType={"parent_type='ASSIGNMENT_STUDENT_TEMPLATE'"}
              parentId={`create`}
              dropDownOptions={addTemplateOptionsOnPerm()}
              shouldCreateAttachmentGroup={true}
              subText={t("classRoom:student_template_subtext")}
              updateAttachments={updateAttachments}
              addMediaText={t("classRoom:add_template")}
            />
          </div>
        </PAComponent>
      )}

      {showAddResourceModal && (
        <UIModal
          isOpen={showAddResourceModal}
          modalContent={classes.addResourceModalContainer}
          onRequestClose={() => changeShowAddResourceModal(false)}
        >
          <AddAssignment
            mode={"create"}
            resourceSelection={true}
            steps={resourceModalData["steps"]}
            onClose={() => changeShowAddResourceModal(false)}
            onDoneResourceSelection={param => {
              updateSelectedItems({ attachments: [...attachments, ...param] });
            }}
          ></AddAssignment>
        </UIModal>
      )}
    </div>
  );
});

DicussionStep.displayName = "DicussionStep";
export default I18nHOC({ resource: ["common", "classRoom"] })(DicussionStep);
