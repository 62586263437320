import React from "react";
import classes from "./ReportItem.scss";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { I18nHOC, UIButton, LinkWithTooltip } from "UIComponents";
import moment from "moment";
import { goToRelativeRoute } from "modules/Services";
import { getPrintFile } from "modules/Services";
import { ProgressReportCardImage } from "ProgressReportCommon/components";
import { DownloadCloudOutlined } from "@toddle-design/web-icons";

const filteredNodesStyle = {
  boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
};

const styles = {
  buttonStyle: {
    height: "48px",
    width: "160px",
  },
};

const ReportItem = props => {
  const {
    t,
    filteredNodes,
    updatedAt,
    publishedProgressReportId,
    studentProgressReportId,
    getPrintFile,
    index,
    curriculumProgramType,
  } = props;
  const title = _.get(filteredNodes, "prTitle.value", "");

  const handleViewClick = () => {
    const { goToRelativeRoute } = props;
    goToRelativeRoute({
      route: studentProgressReportId,
    });
  };

  const handleDownloadClick = () => {
    getPrintFile({
      id: publishedProgressReportId,
      type: "progressreport",
      isDownload: true,
      name: title,
      metadata: {
        studentProgressReportId: studentProgressReportId,
        curriculumProgramType: curriculumProgramType,
      },
    });
  };

  return (
    <div className={classes.itemContainer}>
      <ProgressReportCardImage index={index} />
      <div className={classes.detailsContainer}>
        <div>
          <div className={classes.setName}>{title}</div>
          <div className={classes.label}>
            {t("progressReport:generated_on")}:&nbsp;
            {moment(updatedAt).format("DD MMM, YYYY")}
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.downloadIcon}>
          <LinkWithTooltip
            tooltip={t("common:download")}
            placement={"bottom"}
            href={null}
          >
            <div onClick={handleDownloadClick}>
              <DownloadCloudOutlined size="x-small" variant="subtle" />
            </div>
          </LinkWithTooltip>
        </div>
        <div className={classes.viewReport}>
          <UIButton
            size={"sm"}
            color={"blue"}
            type={"filled"}
            onClick={handleViewClick}
            containerStyle={styles.buttonStyle}
          >
            {t("progressReport:view_report")}
          </UIButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    childID: _.get(state, "login.userInfo.childID", ""),
  };
};

const mapActionCreators = {
  goToRelativeRoute,
  getPrintFile,
};

export default compose(
  I18nHOC({ resource: ["common", "progressReport"] }),
  connect(mapStateToProps, mapActionCreators)
)(ReportItem);
