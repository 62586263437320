import React from "react";
import { SearchList } from "../SearchList/SearchList";
import classes from "./SearchResult.scss";
import { withLoader, EmptyView, I18nHOC } from "UIComponents";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { getGlobalSearchResult } from "../../modules/GlobalSearchBarQueries";
import { getGlobalSearchResultFromCache } from "../../modules/GlobalSearchBarHelper";
import { NoSearchResultsIllustration } from "@toddle-design/theme";
import {
  typesConfig,
  getParseResultMemoized,
  getFilterDataMemoized,
  getCourseBasedModuleMemoized,
  getModuleTypeBySearchTermMemoized,
  getCoursesBySearchTermMemoized,
} from "../Utils";
import { getUserCoursesVars } from "Course/modules/CourseModule";
import { getUserCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import elasticlunr from "elasticlunr";

const style = {
  emptyViewStyle: {
    containerStyle: {
      padding: "unset",
    },
  },
};

const COUNT_OF_FIRST_N_RESULT = 40;

//first clear stop words in our case who , we ,are might be come in Unit plan and LE
elasticlunr.clearStopWords();

//initialize instance of elasticlunr
const index = elasticlunr(function () {
  this.addField("title");
  this.addField("category");
  this.setRef("id");

  // remove stemmer from pipeline to allow matching partial words,
  // refer to https://github.com/weixsong/elasticlunr.js/issues/16
  this.pipeline.remove(elasticlunr.stemmer);
});

const addAllDataToIndex = ({ allData }) => {
  _.forEach(allData, data => index.addDoc(data));
};

const addChangedDataToIndex = ({ changedData }) => {
  _.forEach(changedData, data => index.addDoc(data));
};

const addAllDataToIndexMemoized = _.memoize(
  params => addAllDataToIndex(params),
  params => JSON.stringify(params)
);

const addAllChangedDataToIndexMemoized = _.memoize(
  params => addChangedDataToIndex(params),
  params => JSON.stringify(params)
);

export const SearchResults = props => {
  const {
    suggestions = [],
    localSuggestions = [],
    term,
    searchState,
    onSuggestionClick,
    t,
  } = props;

  //merge data by id if suggestions from local and backend r same
  const mergedData = _.merge(
    _.keyBy(suggestions, "id"),
    _.keyBy(localSuggestions, "id")
  );

  addAllDataToIndexMemoized({ allData: localSuggestions });

  addAllChangedDataToIndexMemoized({ changedData: suggestions });

  const searchResult = index.search(term, {
    fields: {
      title: { boost: 2 },
      category: { boost: 1 },
    },
    expand: true,
  });

  const totalData = _.chain(searchResult)
    .reduce((result, data) => {
      const indexedData = mergedData[data.ref];

      if (indexedData) {
        indexedData.score = data.score;
        result.push(indexedData);
      }
      return result;
    }, [])
    .orderBy(result => result.order, "asc")
    .orderBy(result => result.score, "desc")
    .splice(0, COUNT_OF_FIRST_N_RESULT)
    .value();

  return (
    <div className="mt-4 overflow-y-auto flex-1">
      {!_.get(totalData, "length", 0) ? (
        <div className={classes.emptyResult}>
          <EmptyView
            emptyImageUrl={NoSearchResultsIllustration}
            size={"small"}
            emptyHeaderText={`${t("common:no_results")}!`}
            emptyText={t("integration:try_modifying_your_search")}
            {...style.emptyViewStyle}
          />
        </div>
      ) : (
        <SearchList
          data={totalData}
          onSelectItem={onSuggestionClick}
          searchText={term}
          searchState={searchState}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { module, term, t } = ownProps;

  const {
    login: { userInfo },
    platform,
  } = state;

  const curriculumPrograms = state.platform.organizationCurriculumPrograms;

  const curriculumProgramType = _.map(curriculumPrograms, data => data.type);

  /**
   * This logic will not work for other admin end as we are using curriculum details from teacher end.
   * TODO:Need to be fixed by global search team
   *  */
  const currentCurriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType",
    ""
  );

  const currentCurriculumProgramId = _.get(
    _.find(
      curriculumPrograms,
      ({ type }) => type == currentCurriculumProgramType
    ),
    "id",
    ""
  );

  const tabConfigurations = {
    admin: { curriculumProgramId: currentCurriculumProgramId },
  };

  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });

  const { courses = [] } = getUserCoursesFromCache(
    getUserCoursesVars({ userInfo, platform, isCurriculumProgramFree })
  );

  const coursesSuggestions = (() => {
    if (module) {
      return [];
    }

    return getCoursesBySearchTermMemoized({
      term,
      data: courses,
      orgId: userInfo.org_id,
      t,
    });
  })();
  // console.log({ coursesSuggestions });
  const modulesSuggestions = (() => {
    if (module && module != typesConfig.MODULES.id) {
      return [];
    }
    return getModuleTypeBySearchTermMemoized({
      term,
      orgId: userInfo.org_id,
      module,
      curriculumProgramType,
      t,
    });
  })();

  const courseBasedModules = module
    ? []
    : getCourseBasedModuleMemoized({
        term,
        courses,
        orgId: userInfo.org_id,
        t,
      });

  const localData = [
    ...modulesSuggestions,
    ...coursesSuggestions,
    ...courseBasedModules,
  ];
  const localSuggestions = getParseResultMemoized({ result: localData, t });

  return {
    isData: true,
    isLoading: false,
    filters: getFilterDataMemoized({
      state: state.platform,
      searchText: term,
      module,
    }),
    localSuggestions,
    term,
  };
};

export default compose(
  I18nHOC({
    resource: [
      "common",
      "teacherHomePage",
      "classRoom",
      "configuration",
      "attendance",
      "integration",
    ],
  }),
  connect(mapStateToProps, null),
  graphql(getGlobalSearchResult, {
    name: "getGlobalSearchResultQuery",
    options: ({ filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: filters,
    }),
    skip: props => {
      const { module } = props;
      return module == typesConfig.MODULES.id;
    },
    props: ({
      getGlobalSearchResultQuery,
      ownProps: { isData, isLoading, filters, t },
    }) => {
      const result = getGlobalSearchResultFromCache({
        filters,
      });

      const queryResult = _.get(result, "platform.platformSearch", []);

      const suggestions = getParseResultMemoized({
        result: queryResult,
        t,
      });

      return {
        suggestions,
        isData: !_.isEmpty(result) && isData,
        isLoading:
          _.includes([1, 2], getGlobalSearchResultQuery["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  withLoader
)(SearchResults);
