import React, { Component } from "react";
import PropTypes from "prop-types";
import classes from "./StudentModalFeed.scss";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import UserNameAvatar from "../UserNameAvatar";
import {
  map as _map,
  findIndex as _findIndex,
  get as _get,
  isEmpty as _isEmpty,
  filter as _filter,
} from "lodash";
import update from "immutability-helper";
import { getCourseAllStudentQuery } from "StudentPortfolio/modules/StudentPortfolioQuery";
import { getCourseAllStudentsFromCache } from "StudentPortfolio/modules/StudentPortfolioGraphqlHelpers";
import { getUpdatedArchivedFilters } from "Utils";
import { Checkbox } from "@toddle-design/web";

const StudentModalFeedItem = ({ student, toggleCheckbox, isSelected }) => {
  const { firstName, lastName, profileImage, id, isArchived } = student;
  const name = `${firstName} ${lastName}`;
  return (
    <div className={classes.studentItemRow} onClick={toggleCheckbox}>
      <UserNameAvatar
        id={id}
        profileImage={profileImage}
        name={name}
        isArchived={isArchived}
      />
      <div className={classes.checkBox}>
        <Checkbox isChecked={isSelected} size={"xx-small"} />
      </div>
    </div>
  );
};

class StudentModalFeed extends Component {
  UNSAFE_componentWillMount() {
    const {
      students,
      showEmptyScreen,
      updateStudentArray,
      shouldDefaulAllSelected,
      mode,
    } = this.props;
    if (students.length == 0) {
      showEmptyScreen();
    } else if (shouldDefaulAllSelected) {
      let studentsToSend = students;

      if (mode === "EDIT") {
        studentsToSend = _.filter(students, student => student.isActive);
      }

      const selectedStudentIds = _.map(studentsToSend, student => student.id);
      updateStudentArray(selectedStudentIds);
    }
  }

  toggleCheckbox = id => {
    const { updateStudentArray, includedStudents } = this.props;
    let newIncludedStudents = [...includedStudents];
    const index = _findIndex(newIncludedStudents, function (includedStudentId) {
      return includedStudentId == id;
    });
    if (index != -1) {
      //student was included before now is exclude ,
      //remove id from array
      newIncludedStudents = update(newIncludedStudents, {
        $splice: [[index, 1]],
      });
    } else {
      //student is excluded from this assessment
      //add id to array
      newIncludedStudents = update(newIncludedStudents, {
        $splice: [[index, 0, id]],
      });
    }
    updateStudentArray(newIncludedStudents);
  };

  toggleAllCheckbox = () => {
    const { updateStudentArray, includedStudents, students } = this.props;

    const allStudentsIds = _map(students, student => student.id);
    if (includedStudents.length == 0) {
      updateStudentArray(allStudentsIds);
    } else if (includedStudents.length == allStudentsIds.length) {
      updateStudentArray([]);
    } else {
      updateStudentArray(allStudentsIds);
    }
  };

  getSelectAllText = () => {
    const { includedStudents, students, t } = this.props;

    const allStudentsIds = _map(students, student => student.id);
    let selectAllText = "";
    if (includedStudents.length == 0) {
      selectAllText = t("common:select_all");
    } else if (includedStudents.length == allStudentsIds.length) {
      selectAllText = t("common:unselect_all");
    } else {
      selectAllText = t("common:select_all");
    }
    return selectAllText;
  };

  render() {
    const { students, includedStudents, t } = this.props;
    const selectAllText = this.getSelectAllText();
    return (
      <div className={classes.studentFeedContainer}>
        <div className={classes.headerRow}>
          <div className={classes.countText}>
            {t("common:selected_of_total_label", {
              label: t("common:student_plural"),
              selected: includedStudents.length,
              total: students.length,
            })}
          </div>
          <div
            className={classes.selectAllButton}
            onClick={this.toggleAllCheckbox}
          >
            {selectAllText}
          </div>
        </div>
        {_map(students, student => {
          const isSelected =
            _findIndex(
              includedStudents,
              includedStudent => includedStudent == student.id
            ) >= 0;
          return (
            <StudentModalFeedItem
              student={student}
              toggleCheckbox={() => this.toggleCheckbox(student.id)}
              key={student.id}
              isSelected={isSelected}
            />
          );
        })}
      </div>
    );
  }
}

StudentModalFeed.propTypes = {
  includedStudents: PropTypes.array,
};

StudentModalFeed.defaultProps = {
  includedStudents: [],
  shouldDefaulAllSelected: true,
};

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const { studentList } = ownProps;
  return {
    students: !_.isEmpty(studentList) ? [...studentList] : [],
    selectedItems: ownProps.selectedItems,
    courseId: ownProps.courseId,
    isData: true,
    isLoading: false,
  };
};

const getStudents = ({ data }) => {
  if (!data) {
    return [];
  }
  return _map(data, item => {
    return item.node;
  });
};

const getAllStudents = ({ mode, students, itemsToEdit }) => {
  if (mode === "EDIT") {
    return _.unionBy(itemsToEdit, students, "id");
  } else {
    return students;
  }
};

export default compose(
  I18nHOC({ replace: "common" }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCourseAllStudentQuery, {
    name: "getCourseOfStudents",
    skip: ({ fetchStudentList = true, courseId }) =>
      !fetchStudentList || _.isEmpty(courseId),
    options: ({
      courseId,
      reportId,
      assessmentEvaluationId,
      type,
      modalMode,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
        filters: getUpdatedArchivedFilters(
          {},
          modalMode === "EDIT_STUDENTS"
            ? {
                entityId:
                  type === "ASSESSMENT_EVALUATION"
                    ? assessmentEvaluationId
                    : reportId,
                entityType: type,
              }
            : {}
        ),
      },
    }),
    props({
      getCourseOfStudents,
      ownProps: {
        isData,
        isLoading,
        courseId,
        itemsToEdit,
        mode,
        reportId,
        type,
        assessmentEvaluationId,
        modalMode,
      },
    }) {
      const courseData = getCourseAllStudentsFromCache({
        id: courseId,
        filters: getUpdatedArchivedFilters(
          {},
          modalMode === "EDIT_STUDENTS"
            ? {
                entityId:
                  type === "ASSESSMENT_EVALUATION"
                    ? assessmentEvaluationId
                    : reportId,
                entityType: type,
              }
            : {}
        ),
      });

      return {
        getCourseOfStudents,
        students: getAllStudents({
          mode,
          students: getStudents({
            data: _get(courseData, "students.edges", []),
          }),
          itemsToEdit,
        }),
        isData: !_isEmpty(courseData) && isData,
        isLoading:
          getCourseOfStudents["networkStatus"] == 1 ||
          getCourseOfStudents["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(StudentModalFeed);
