import update from "immutability-helper";
import { setToastMsg } from "Login/modules/LoginModule";
import { TeacherMenu } from "Teacher/TeacherMenu";
import { createUserPlanathonRequestMutation } from "modules/CommonMutations";
import client from "apolloClient";
import {
  getStaffBasicDetailsFromCache,
  writeStaffBasicDetailsInCache,
} from "modules/CommonGraphqlHelpers";
import {
  getCourseFeedDetailsFromCache,
  getCourseDetailsFromCache,
} from "Course/modules/CourseGraphqlHelpers";
import { getUserCoursesFilters } from "Course/modules/CourseModule";
import { getUserCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import {
  goToAdminRoute,
  goToPlatformHomeFeatureRoute,
  goToPlatformHomeChildRoute,
} from "modules/NavigationModule";
import { isNumber, replaceLabelKeysWithValue } from "Utils";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import ACLStore from "lib/aclStore";
import { getUserCourseTagsFilters } from "Courses/modules/utils";
import { goToRelativeRoute } from "modules/Services";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import {
  PROJECT_GROUP_TYPE_DP_CAS,
  PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
  PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
  PROJECT_GROUP_TYPE_DP_EE,
} from "Projects/Constants/stringConstants";

// ------------------------------------
// Constants
// ------------------------------------
export const NAME = "teacher";

export const CHANGE_DRAWER_STATE = "CHANGE_DRAWER_STATE" + " " + NAME;

export const SET_DASHBOARD_COACHMARK_VISIBILITY =
  "SET_DASHBOARD_COACHMARK_VISIBILITY" + " " + NAME;

export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT" + " " + NAME;

export const SET_GRADE_GROUPS = "SET_GRADE_GROUPS" + " " + NAME;

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB" + " " + NAME;

export const TOGGLE_DAILY_CALENDAR_STATE =
  "TOGGLE_DAILY_CALENDAR_STATE" + " " + NAME;

export const TOGGLE_NOTIFICATION_CENTER_STATE =
  "TOGGLE_NOTIFICATION_CENTER_STATE" + " " + NAME;

export const SET_HOMEPAGE_SIDEBAR = "SET_HOMEPAGE_SIDEBAR" + " " + NAME;

export const UPDATE_WIDGET_FILTERS = "UPDATE_WIDGET_FILTERS" + " " + NAME;

export const ON_USER_COMMUNICATION_MODAL_CLOSE = `ON_USER_COMMUNICATION_MODAL_CLOSE ${NAME}`;

export const ON_USER_COMMUNICATION_ENUM_SELECT = `ON_USER_COMMUNICATION_ENUM_SELECT ${NAME}`;

export const SET_MAX_SCORE_VALUE = "SET_MAX_SCORE_VALUE" + " " + NAME;

//PURE ACTIONS

export const updateWidgetFiltersObj = data => {
  return {
    type: UPDATE_WIDGET_FILTERS,
    payload: data,
  };
};

export const toggleDailyCalendarState = data => {
  return { type: TOGGLE_DAILY_CALENDAR_STATE };
};

export const toggleNotificationCenterState = data => {
  return { type: TOGGLE_NOTIFICATION_CENTER_STATE };
};

export const setHomePageSidebar = data => {
  return { type: SET_HOMEPAGE_SIDEBAR, data };
};

export const setNotificationCount = data => {
  return { type: SET_NOTIFICATION_COUNT, data };
};

export const setActiveTabValue = data => {
  return { type: SET_ACTIVE_TAB, payload: data };
};

export const setGradeGroups = data => {
  return { type: SET_GRADE_GROUPS, data: data };
};

export const onUserCommunicationModalClose = data => {
  return { type: ON_USER_COMMUNICATION_MODAL_CLOSE, payload: data };
};

export const onUserCommunicationEnumSelect = data => {
  return { type: ON_USER_COMMUNICATION_ENUM_SELECT, payload: data };
};

export const resetGradeGroups = () => {
  return (dispatch, getState) =>
    dispatch(
      setGradeGroups({
        selected_grades: null,
        selected_course: "",
        isDemo: false,
      })
    );
};

export const changeDrawerState = payload => {
  return { type: CHANGE_DRAWER_STATE, payload };
};

export const setDashboardCoachmarkVisibility = payload => {
  return { type: SET_DASHBOARD_COACHMARK_VISIBILITY, payload };
};

export const setMaxScoreValue = value => {
  return { type: SET_MAX_SCORE_VALUE, value };
};

export const getTeacherNode = id => {
  const nodes = _.get(TeacherMenu, "nodes", []);
  return _.find(nodes, item => item.id == id);
};

export const getUserIdAndEntityTypeByUserMemoize = _.memoize(
  ({ userInfo }) => {
    return {
      //in case of parent as user we need to fetch the courses of selected student so we are passing student id in userId
      userId: userInfo.user_type == "parent" ? userInfo.childID : userInfo.id,
      userEntityType:
        userInfo.user_type == "parent" ? "STUDENT" : userInfo.userEntityType,
    };
  },
  params => JSON.stringify(params)
);

const getDPRootNodeName = ({ courseProjectGroups, user_type }) => {
  if (user_type === "staff") {
    if (_.size(courseProjectGroups) > 0) {
      const { type } = _.first(courseProjectGroups);
      switch (type) {
        case PROJECT_GROUP_TYPE_DP_CAS:
          return "teacherPortalIBDPCASRootNodes";
        case PROJECT_GROUP_TYPE_DP_TOK_ESSAY:
        case PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION:
          return "teacherPortalIBDPTOKRootNodes";
        case PROJECT_GROUP_TYPE_DP_EE:
          return "teacherPortalIBDPEERootNodes";
      }
    }
    return "teacherPortalIBDPRootNodes";
  } else if (user_type === "student") {
    if (_.size(courseProjectGroups) > 0) {
      const { type } = _.first(courseProjectGroups);
      switch (type) {
        case PROJECT_GROUP_TYPE_DP_CAS:
          return "studentPortalIBDPCASRootNodes";
        case PROJECT_GROUP_TYPE_DP_TOK_ESSAY:
        case PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION:
          return "studentPortalIBDPTOKRootNodes";
        case PROJECT_GROUP_TYPE_DP_EE:
          return "studentPortalIBDPEERootNodes";
      }
    }
    return "studentPortalIBDPRootNodes";
  }

  return null;
};

//it will return the first node from the calculated navigation menu
const getFallbackDefaultNode = ({ menuList }) => {
  const menuItem = _.first(menuList);
  if (_.size(_.get(menuItem, "children", [])) > 0) {
    return getFallbackDefaultNode({ menuList: menuItem.children });
  } else {
    return menuItem?.id;
  }
};

//get the default tab to navigate from the teacher menu based on user and curriculum
export const getDefaultSelectedNodeId = ({
  curriculumType,
  user_type,
  isCoreCourse,
}) => {
  return (dispatch, getState) => {
    const {
      defaultTeacherNodeId,
      defaultPYPStudentNodeId,
      defaultMYPStudentNodeId,
      defaultParentNodeId,
      defaultUBDStudentNodeId,
    } = TeacherMenu;

    const { topMenuList = [], bottomMenuList = [] } = _.get(
      getState(),
      "course.navigationMenu",
      {}
    );

    let defaultNodeId = null;

    switch (user_type) {
      case "staff": {
        switch (true) {
          case CURRICULUM_TYPE_PYP === curriculumType:
          case CURRICULUM_TYPE_MYP === curriculumType:
          case CURRICULUM_TYPE_UBD === curriculumType:
          case CURRICULUM_TYPE_DP === curriculumType && !isCoreCourse:
            defaultNodeId = defaultTeacherNodeId;
            break;
        }
        break;
      }
      case "student": {
        switch (true) {
          case CURRICULUM_TYPE_PYP === curriculumType:
          case CURRICULUM_TYPE_DP === curriculumType && !isCoreCourse:
            defaultNodeId = defaultPYPStudentNodeId;
            break;
          case CURRICULUM_TYPE_MYP === curriculumType:
            defaultNodeId = defaultMYPStudentNodeId;
            break;
          case CURRICULUM_TYPE_UBD === curriculumType:
            defaultNodeId = defaultUBDStudentNodeId;
            break;
        }
        break;
      }
      case "parent": {
        defaultNodeId = defaultParentNodeId;
        break;
      }
    }

    return (
      defaultNodeId ||
      getFallbackDefaultNode({
        menuList: [...topMenuList, ...bottomMenuList],
      }) ||
      "UNIT_PLANS"
    );
  };
};

const getNodePermission = ({ node, user_type }) => {
  let perm = null;
  switch (user_type) {
    case "staff":
      perm = _.get(node, "perm", null);
      break;
    case "student":
      perm = _.get(node, "studentPerm", null);
      break;
    case "parent":
      perm = _.get(node, "parentPerm", null);
  }
  return perm;
};

export const DISABLE_ACADEMIC_YEAR_DEPENDENT_MENU_ITEMS = [
  "FAMILY_COMMUNICATIONS",
  "CLASS_PROGRESS_SUMMARY",
  "STUDENT_PORTFOLIO",
  "SETTINGS",
];

/**
 * use this fn to give value to your dynamic route
 * This fn will be useful to you when you have dynamic routes in TeacherMenu's nodes property
 * for ex. we have node like below in nodes array of TeacherMenu
 *
 * {
      id: "CAS_PROJECT",
      label: "cas",
      isBottom: false,
      children: [],
      route: "projects/:projectGroupId",
      levelId: "L1",
      icon: <StudentPortfolioSvg />,
      perm: "Common:CASProjects",
      studentPerm: "Common:CASProjects",
    },
 * So here you can see that in the route we have "projects/:projectGroupId" so here projectGroupId is dynamic
 * So to replace that projectGroupId with its actual value this fn will be useful


 *  when to use ?
 * ->  whenever you are using route property of node(from nodes array of TeacherMenu) before using node just
 pre-process nodes first by this fn.
 *
 *
 *  How to use ?
 * ->  You have to just give nodes from TeacherMenu and the dynamicRouteParams which is an object of all the
 * dynamic Parameter's value.
 * Eg. for above example dynamicRouteParams will be { CAS_PROJECT:{ projectGroupId:100 }}. so here key "CAS_PROJECT" is the
 * id of node for which we want to replace dynamic routes and value for key "CAS_PROJECT" is its all dynamic params in the route.
 * Here we have only one "projectGroupId". But if you have route property in node is like "projects/:projectGroupId/progress/:projectId" then in
 * that case dynamicRouteParams object will look like { CAS_PROJECT:{ projectGroupId:100 ,projectId: 200}}.
 *
 * NOTE: Just make sure that you give key value pair in dynamicRouteParams object such that the key should be the id of node
 * for which you want to pre-process route property (in above example "CAS_PROJECT") and the value should be an object of dynamic params
 * with name of dynamic param and its value(for above ex { projectGroupId:100} will be the object here projectGroupId is our dynamic param).
 */
export const changeDynamicRouteWithValue = ({
  dynamicRouteParams = {},
  nodes,
}) => {
  return _.map(nodes, node => {
    if (_.get(dynamicRouteParams, node.id, null)) {
      const routeList = _.split(node.route, "/");
      const routeParams = dynamicRouteParams[node.id];

      const newRoute = _.map(routeList, routeItem => {
        //checking if it is dynamic route or not
        if (routeItem[0] === ":") {
          const routeItemLabel = _.trim(routeItem, ":");
          //checking if we have that dynamic param
          if (_.get(routeParams, routeItemLabel, null)) {
            return routeParams[routeItemLabel];
          } else {
            console.error(
              `Dynamic route params not provided for "${routeItemLabel}"`
            );
            return "";
          }
        }
        return routeItem;
      });
      return { ...node, route: _.join(newRoute, "/") };
    }

    return node;
  });
};

export const getCourseProjectGroupIdByType = ({
  courseProjectGroups,
  types,
}) => {
  return _.get(
    _.find(courseProjectGroups, courseProjectGroup =>
      _.includes(types, courseProjectGroup.type)
    ),
    "id",
    ""
  );
};

const getDynamicMenuItems = ({ isLocked, node, params = {} }) => {
  const isDynamicNode = _.get(node, "isDynamicNode", false);

  if (isDynamicNode) {
    const { dynamicNodeConfig: { type = "", id = "" } = {}, label } = node;

    switch (type) {
      case "PROJECT_GROUP": {
        const { courseProjectGroups } = params;
        const menuItems = [];

        _.forEach(courseProjectGroups, courseProjectGroup => {
          menuItems.push({
            ...node,
            id: replaceLabelKeysWithValue({
              label: id,
              key: "projectGroupType",
              value: courseProjectGroup.type,
            }),
            label: replaceLabelKeysWithValue({
              label,
              key: "projectGroupType",
              value: courseProjectGroup.name,
            }),
            route: `projects/${courseProjectGroup.id}`,
            isLocked,
            children: [],
          });
        });

        return menuItems;
      }
    }
  }
  return [{ ...node, isLocked, children: [] }];
};

//this fn will give you menu for course you select and if you keep shouldReturnMenuRoutes true the it will return the
//routes of that menu items.
export const getMenu = ({
  isBottom,
  isCurriculumProgramFree,
  isDemoCourse,
  user_type,
  curriculumType,
  disabledMenuItems = [],
  courseProjectGroups,
}) => {
  const activeMenu = [];

  if (isDemoCourse && !isCurriculumProgramFree) {
    return activeMenu;
  }
  let rootNodes = [];
  switch (user_type) {
    case "staff":
      if (curriculumType === CURRICULUM_TYPE_MYP) {
        rootNodes = _.get(TeacherMenu, "teacherPortalIBMYPRootNodes", []);
      } else if (curriculumType === CURRICULUM_TYPE_UBD) {
        rootNodes = _.get(TeacherMenu, "teacherPortalUBDRootNodes", []);
      } else if (curriculumType === CURRICULUM_TYPE_DP) {
        const nodeName = getDPRootNodeName({ courseProjectGroups, user_type });
        rootNodes = _.get(TeacherMenu, nodeName, []);
      } else {
        rootNodes = isCurriculumProgramFree
          ? _.get(TeacherMenu, "teacherPoralIBPYPFreemiumRootNodes", [])
          : _.get(TeacherMenu, "teacherPortalIBPYPRootNodes", []);
      }

      break;
    case "student":
      if (curriculumType === CURRICULUM_TYPE_PYP) {
        rootNodes = _.get(TeacherMenu, "studentPortalIBPYPRootNodes", []);
      } else if (curriculumType === CURRICULUM_TYPE_MYP) {
        rootNodes = _.get(TeacherMenu, "studentPortalIBMYPRootNodes", []);
      } else if (curriculumType === CURRICULUM_TYPE_UBD) {
        rootNodes = _.get(TeacherMenu, "studentPortalUBDRootNodes", []);
      } else if (curriculumType === CURRICULUM_TYPE_DP) {
        const nodeName = getDPRootNodeName({ courseProjectGroups, user_type });
        rootNodes = _.get(TeacherMenu, nodeName, []);
      } else {
        rootNodes = _.get(TeacherMenu, "studentPortalIBPYPRootNodes", []);
      }

      break;
    case "parent":
      if (curriculumType != CURRICULUM_TYPE_PYP) {
        rootNodes = _.get(TeacherMenu, "parentPortalIBMYPRootNodes", []);
      } else if (curriculumType === CURRICULUM_TYPE_UBD) {
        rootNodes = _.get(TeacherMenu, "parentPortalUBDRootNodes", []);
      } else {
        rootNodes = _.get(TeacherMenu, "parentPortalIBPYPRootNodes", []);
      }

      break;
  }

  const nodes = _.get(TeacherMenu, "nodes", []);

  _.forEach(rootNodes, rootNodeId => {
    const currentNode = _.find(
      nodes,
      node =>
        node.id == rootNodeId &&
        (node.isBottom || false) == isBottom &&
        !disabledMenuItems.includes(node.id)
    );

    if (currentNode) {
      const children = [];

      let isLocked = true;
      let nodeChildren = [];

      if (curriculumType === CURRICULUM_TYPE_MYP) {
        nodeChildren = currentNode.curriculumIBMYPChildren;
      } else if (curriculumType === CURRICULUM_TYPE_UBD) {
        nodeChildren = currentNode.curriculumUBDChildren;
      } else if (curriculumType === CURRICULUM_TYPE_DP) {
        nodeChildren = currentNode.curriculumDPChildren;
      } else {
        nodeChildren = currentNode.children;
      }

      if (_.size(nodeChildren) > 0) {
        _.forEach(nodeChildren, childId => {
          let childNode = _.find(
            nodes,
            node => node.id == childId && !disabledMenuItems.includes(childId)
          );

          //Temp : Later yearly planning in PYP It will be deprecated completely
          if (childId == "SCHEDULER") {
            childNode = {
              ...childNode,

              label: ACLStore.can("TeacherPortal:EnableYearlySchedulerInPYP")
                ? "teacherHomePage:weekly_yearly_planning"
                : "teacherHomePage:weekly_planning",
            };
          }

          const perm = getNodePermission({ node: childNode, user_type });

          if (childNode && perm && ACLStore.can(perm)) {
            const isChildLock = ACLStore.isLocked(perm);
            const menuItems = getDynamicMenuItems({
              isLocked: isChildLock,
              node: childNode,
              params: { courseProjectGroups },
            });

            children.push(...menuItems);
          }
        });
        if (children.length > 0) {
          activeMenu.push({ ...currentNode, children: children });
        }
        // if (children.length > 0) {
        //   if (!isLocked) {
        //     activeMenu.push({ ...currentNode, children: children, isLocked });
        //   } else {
        //     activeMenu.push({ ...currentNode, isLocked, children: [] });
        //   }
        // }
      } else {
        const perm = getNodePermission({ node: currentNode, user_type });
        if (perm && ACLStore.can(perm)) {
          isLocked = ACLStore.isLocked(perm);
          const menuItems = getDynamicMenuItems({
            isLocked,
            node: currentNode,
            params: { courseProjectGroups },
          });

          activeMenu.push(...menuItems);
        }
      }
    }
  });

  return activeMenu;
};

export const checkActiveTabRouteForCurrentMenuList = ({ currentActiveTab }) => {
  return (dispatch, getState) => {
    const { topMenuList = [], bottomMenuList = [] } = _.get(
      getState(),
      "course.navigationMenu",
      {}
    );
    const routeList = [];

    const getMenuListRoutes = menuList => {
      _.forEach(menuList, menu => {
        if (_.size(_.get(menu, "children", [])) > 0) {
          getMenuListRoutes(menu.children);
        } else {
          routeList.push(menu.route);
        }
      });
    };

    getMenuListRoutes([...topMenuList, ...bottomMenuList]);

    return _.includes(routeList, currentActiveTab);
  };
};

export const getDefaultRoute = ({
  user_type,
  defaultNodeId,
  userLastSelectedTab,
}) => {
  return (dispatch, getState) => {
    const { topMenuList = [], bottomMenuList = [] } = _.get(
      getState(),
      "course.navigationMenu",
      {}
    );

    let node = {};
    const getDefaultNode = ({ menuList }) => {
      _.forEach(menuList, menu => {
        if (_.size(_.get(menu, "children", [])) > 0) {
          getDefaultNode({ menuList: menu.children });
        } else if (
          menu.id === defaultNodeId &&
          ACLStore.can(getNodePermission({ node: menu, user_type }))
        ) {
          node = menu;
          return;
        }
      });
    };

    getDefaultNode({ menuList: [...topMenuList, ...bottomMenuList] });

    const routeTab = dispatch(
      checkActiveTabRouteForCurrentMenuList({
        currentActiveTab: userLastSelectedTab,
      })
    );

    if (routeTab) {
      return userLastSelectedTab;
    } else {
      return _.get(
        node,
        "route",
        user_type === "staff" ? "unitPlans" : "classroom"
      );
    }
  };
};

export const onClassSelect = (
  course_id,
  isDemo = false,
  sourceFrom = "platform"
) => {
  return (dispatch, getState) => {
    dispatch(
      goToRelativeRoute({
        route: `${course_id}`,
      })
    );
  };
};

export const setGradeSection = course_id => {
  return (dispatch, getState) => {
    const selectedClass = getCourseDetailsFromCache({ id: course_id });
    const param = {};
    param["selected_grades"] = _.map(selectedClass.grades, grade => grade.id);
    param["selected_course"] = selectedClass.id;
    param["isDemo"] = selectedClass.isDemo;
    param["isArchivedClass"] = selectedClass.isArchived;
    param["isRemovedCourseForLoggedInUser"] = _.get(
      getCourseFeedDetailsFromCache(course_id),
      "isRemovedCourseForLoggedInUser",
      false
    );

    dispatch(setGradeGroups(param));
  };
};

export const setAndCheckCourse = ({ course_id, archivedStudentsList }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { id, childID, user_type, isDevUser } = state.login.userInfo;
    let { userEntityType } = state.login.userInfo;
    const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });
    if (!isNumber(course_id)) {
      dispatch(
        goToPlatformHomeChildRoute({
          route: "courses",
          navigateType: "replace",
        })
      );
      return;
    }

    const { collaborators, students, isDemo } = getCourseDetailsFromCache({
      id: course_id,
    });

    //tags are for hierarchy organizations
    const userTags = getState().login.userInfo.tags || [];

    //when user type is parent we need to fetch the detils for the selected student so we are allocating student id to userid
    const isUserTypeParent = user_type == "parent";
    const userId = isUserTypeParent ? childID : id;
    userEntityType = isUserTypeParent ? "STUDENT" : userEntityType;
    const currentCurriculumProgramType = getState().platform
      .currentCurriculumProgramType;
    const currentCurriculumProgramId =
      state.platform.currentCurriculumProgram.id;
    const organizationCurriculumPrograms = getState().platform
      .organizationCurriculumPrograms;

    // console.log("isDevUser", isDevUser);
    let hasAccess = false;

    //For Demo Class, it's true
    if (isDemo || isDevUser) {
      hasAccess = true;
    } else {
      if (user_type != "staff") {
        hasAccess =
          !!_.find(
            _.get(students, "edges", []),
            ({ node }) => node.id == userId
          ) || archivedStudentsList.includes(userId);
      } else {
        const selectedAcademicYear = _.get(
          getState(),
          "platform.academicYearSelected",
          {}
        );
        const {
          isCurrentAcademicYear = true,
          id: academicYear = "",
        } = selectedAcademicYear;
        const filters = {
          ...getUserCoursesFilters({
            currentCurriculumProgramType,
            currentCurriculumProgramId,
            organizationCurriculumPrograms,
          }),
          ...(!isCurrentAcademicYear && { academicYear }),
          ...getUserCourseTagsFilters({ userTags }),
        };

        const userCourses = getUserCoursesFromCache({
          id: userId,
          type: userEntityType,
          filters,
          isCurriculumProgramFree,
        });

        const isCourseInSelectedAcademicYear = _.map(
          _.get(userCourses, "courses", []),
          course => course.id
        ).includes(course_id);

        hasAccess =
          !!_.find(
            _.get(collaborators, "edges", []),
            ({ node }) => node.id == id
          ) && isCourseInSelectedAcademicYear;
      }
    }

    if (hasAccess) {
      dispatch(setGradeSection(course_id));
    } else {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:not_authorized_for_class",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      dispatch(
        goToPlatformHomeChildRoute({
          route: "courses",
          navigateType: "replace",
        })
      );
    }
  };
};

export const setActiveTab = route => {
  return (dispatch, getState) => {
    const node = getTeacherNodeByRouteName(route);
    const perm = _.get(node, "perm", "");

    dispatch(setActiveTabValue(route));
  };
};

export const getTeacherNodeByRouteName = route => {
  const nodes = _.get(TeacherMenu, "nodes", []);
  return _.find(nodes, item => item.route == route);
};

export const doTransition = (value, courseId) => {
  return (dispatch, getState) => {
    const course_id = courseId
      ? courseId
      : getState().teacher.selected_class.selected_course;
    dispatch(setActiveTab(value));
    dispatch(
      goToPlatformHomeFeatureRoute({ courseId: course_id, route: `${value}` })
    );
  };
};

export const navigateToClassSelector = ({ type }) => {
  return (dispatch, getState) => {
    dispatch(
      goToPlatformHomeChildRoute({ route: "courses", navigateType: type })
    );
    dispatch(setGradeGroups({ isArchivedClass: false }));
  };
};

export const navigateToAdminPortal = () => {
  return (dispatch, getState) => {
    dispatch(goToAdminRoute());
  };
};

export const createUserPlanathonRequest = () => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().login.userInfo.id;
      const userData = getStaffBasicDetailsFromCache(userId);
      await client.mutate({
        mutation: createUserPlanathonRequestMutation,
        variables: {},

        update: (
          cache,
          {
            data: {
              platform: { createUserPlanathonRequest },
            },
          }
        ) => {
          if (createUserPlanathonRequest) {
            writeStaffBasicDetailsInCache({
              userId,
              data: { ...userData, isRequestedForPlanathon: true },
            });
          }
        },
      });
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      console.error(e);
      throw e;
    }
  };
};

const getDisabledMenuItems = ({
  user_type,
  unitPlansCount,
  studentFilesCount,
  assessmentEvaluationsCount,
}) => {
  const disabledMenuItems = [];
  switch (user_type) {
    case "student":
      if (unitPlansCount === 0) {
        disabledMenuItems.push("UNIT_PLANS");
      }
      if (studentFilesCount === 0) {
        disabledMenuItems.push("STUDENT_DRIVE");
      }
      break;
    case "staff":
      if (
        assessmentEvaluationsCount === 0 &&
        ACLStore.can("FeatureFlag:HideAssessLearningForNewClasses")
      ) {
        disabledMenuItems.push("ASSESSMENT_EVALUATION");
      }
      break;
    default:
      break;
  }
  return disabledMenuItems;
};

export const getDisabledMenuItemsMemoize = _.memoize(
  params => getDisabledMenuItems(params),
  params => JSON.stringify(params)
);

const REDUCER_HANDLERS = {
  [CHANGE_DRAWER_STATE]: (state, action) => {
    return Object.assign({}, state, { isDrawerOpen: action.payload });
  },

  [SET_DASHBOARD_COACHMARK_VISIBILITY]: (state, action) => {
    return Object.assign({}, state, { showDashboardCoachmark: action.payload });
  },

  [SET_NOTIFICATION_COUNT]: (state, action) => {
    return update(state, { notification_count: { $set: action.data } });
  },
  [SET_GRADE_GROUPS]: (state, action) => {
    const params = action.data;

    Object.keys(params).map((key, index) => {
      state = update(state, {
        selected_class: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },

  [SET_ACTIVE_TAB]: (state, action) => {
    return Object.assign({}, state, { activeTab: action.payload });
  },

  [TOGGLE_DAILY_CALENDAR_STATE]: (state, action) => {
    return update(state, {
      isDailyCalendarOpen: { $set: !state.isDailyCalendarOpen },
      isNotificationCenterOpen: { $set: false },
    });
  },

  [TOGGLE_NOTIFICATION_CENTER_STATE]: (state, action) => {
    return update(state, {
      isNotificationCenterOpen: { $set: !state.isNotificationCenterOpen },
      isDailyCalendarOpen: { $set: false },
    });
  },

  [SET_HOMEPAGE_SIDEBAR]: (state, action) => {
    let showSidebar = _.get(action, "data.showSidebar", false);
    let contentType = _.get(action, "data.contentType", null);
    const oldContentType = _.get(state, "sidebarConfig.contentType", null);
    if (oldContentType === contentType) {
      showSidebar = false;
      contentType = null;
    }
    return update(state, {
      sidebarConfig: {
        $set: {
          showSidebar: showSidebar,
          contentType: contentType,
        },
      },
    });
  },
  [UPDATE_WIDGET_FILTERS]: (state, action) => {
    Object.keys(action.payload).map((key, index) => {
      state = update(state, {
        widgetFilters: { [key]: { $set: action.payload[key] } },
      });
    });
    return state;
  },
  [ON_USER_COMMUNICATION_MODAL_CLOSE]: (state, action) => {
    return update(state, {
      userPopupMessage: {
        $set: {
          selectedUserMessageEnum: null,
          allowMessagePopUpOpen: false,
        },
      },
    });
  },
  [ON_USER_COMMUNICATION_ENUM_SELECT]: (state, action) => {
    const { selectedUserMessageEnum } = action.payload;
    return update(state, {
      userPopupMessage: {
        $set: {
          selectedUserMessageEnum,
        },
      },
    });
  },
  [SET_MAX_SCORE_VALUE]: (state, action) => {
    const { value } = action;
    return update(state, { maxScore: { $set: value } });
  },
};

const initialState = {
  isDrawerOpen: false,
  showDashboardCoachmark: false,
  notification_count: 0,
  selected_class: {
    selected_grades: null,
    selected_course: "",
    isDemo: false,
  },
  userPopupMessage: {
    selectedUserMessageEnum: null,
    // When user logs in, this true value will allow it to show any pending message modals
    // once the user closes the modal, this is set to false and no further dropdowns
    // are shown unless user explicitly clicks on something which opens the modal
    // In the explicit click case, selectedUserMessageEnum is set and used for showing the
    // message
    allowMessagePopUpOpen: true,
  },
  permissionGroups: {
    staff: [
      "Dashboards",
      "TeacherPortal",
      "AdminPortal",
      "FeatureFlag",
      "VisitorPortal",
    ],
    student: ["StudentPortal", "FeatureFlag"],
    parent: ["FamilyPortal"],
  },
  activeTab: null,
  isDailyCalendarOpen: false,
  isNotificationCenterOpen: false,
  widgetFilters: {
    activeWidgetTab: "UPCOMING",
    isFromWidget: true,
  },
  maxScore: "",
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
