import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "timetable-configuration",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/TimetableConfigurationModule")
              .default;
            injectReducer(store, { key: "timetableConfiguration", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-timetable-configuration"
        );
      })
  ),
  childRoutes: [],
});
