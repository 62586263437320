import request from "superagent";
import { push } from "react-router-redux";
import update from "immutability-helper";

export const NAME = "choose";

// PURE ACTIONS
export const setScreenWithRole = path => {
  return (dispatch, getState) => {
    if (path == "administrator" || path == "todden") {
      dispatch(push(`/${path}`));
    } else if (path == "teacher") {
      dispatch(push(`/${path}/classes`));
    }
  };
};

// ------------------------------------
// Reducer Handlers
// ------------------------------------
const REDUCER_HANDLERS = {};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
