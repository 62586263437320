import PropTypes from "prop-types";
import React from "react";

const ProgressSummarySvg = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 87 100"
      fill={props.fill && props.fill}
    >
      <path d="M0 14.4898C0 6.26852 6.30252 0 14.5684 0H72.0212C80.1439 0 87 6.12949 87 14.4898V83.4694C87 92.507 79.4664 100 70.3797 100H16.6203C7.53365 100 0 92.507 0 83.4694V14.4898ZM14.5684 6.12245C9.7022 6.12245 6.15566 9.64985 6.15566 14.4898V83.4694C6.15566 89.1257 10.9333 93.8776 16.6203 93.8776H70.3797C76.0667 93.8776 80.8443 89.1257 80.8443 83.4694V14.4898C80.8443 9.78888 77.0307 6.12245 72.0212 6.12245H14.5684Z" />
      <path d="M63.0281 19.9951C64.2774 20.4652 64.9069 21.8536 64.4342 23.0962L52.9436 53.3003C52.6256 54.1363 51.8677 54.7279 50.9757 54.8366C50.0837 54.9453 49.2046 54.5531 48.6931 53.8182L36.1662 35.8215L26.5469 53.5894C25.9136 54.7592 24.4469 55.1968 23.2708 54.567C22.0947 53.9371 21.6547 52.4783 22.288 51.3085L33.7785 30.084C34.1775 29.3471 34.9329 28.8694 35.7731 28.8227C36.6133 28.776 37.4175 29.1671 37.8965 29.8552L50.0491 47.3143L59.9101 21.3936C60.3828 20.1511 61.7788 19.5249 63.0281 19.9951Z" />
      <path d="M35.908 36.1225C38.6278 36.1225 40.8325 33.9296 40.8325 31.2245C40.8325 28.5194 38.6278 26.3265 35.908 26.3265C33.1883 26.3265 30.9835 28.5194 30.9835 31.2245C30.9835 33.9296 33.1883 36.1225 35.908 36.1225Z" />
      <path d="M50.6816 57.3469C53.4013 57.3469 55.6061 55.154 55.6061 52.449C55.6061 49.7439 53.4013 47.551 50.6816 47.551C47.9619 47.551 45.7571 49.7439 45.7571 52.449C45.7571 55.154 47.9619 57.3469 50.6816 57.3469Z" />
      <path d="M62.1722 27.1429C64.8919 27.1429 67.0967 24.95 67.0967 22.2449C67.0967 19.5398 64.8919 17.3469 62.1722 17.3469C59.4524 17.3469 57.2476 19.5398 57.2476 22.2449C57.2476 24.95 59.4524 27.1429 62.1722 27.1429Z" />
      <path d="M24.4175 57.3469C27.1372 57.3469 29.342 55.154 29.342 52.449C29.342 49.7439 27.1372 47.551 24.4175 47.551C21.6977 47.551 19.4929 49.7439 19.4929 52.449C19.4929 55.154 21.6977 57.3469 24.4175 57.3469Z" />
      <path d="M17.2359 71.2245C17.2359 69.5338 18.6138 68.1633 20.3137 68.1633H66.2759C67.9758 68.1633 69.3538 69.5338 69.3538 71.2245C69.3538 72.9152 67.9758 74.2857 66.2759 74.2857H20.3137C18.6138 74.2857 17.2359 72.9152 17.2359 71.2245Z" />
    </svg>
  );
};

ProgressSummarySvg.defaultProps = {
  height: 20,
  width: 20,
};

export default ProgressSummarySvg;
