import React, { Fragment, cloneElement, useEffect, useState } from "react";
import { useMount } from "Hooks";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { useHandleRightPane } from "Hooks";
import HelperPane from "IBPlanner/routes/PlannerStep/components/HelperPane";
import { goToRelativeRoute } from "modules/Services";
import { getRoleValue } from "Projects/config/constants";
import classes from "./ProjectGroup.scss";
import classNames from "classnames";
import {
  getProjectGroupGuidanceStatusQuery,
  getProjectGroupProjectCountQuery,
  getProjectGroupStudentProjectPortfoliosCountQuery,
  getProjectGroupYearGroupQuery,
  getGradeWiseProjectCountQuery,
} from "Projects/modules/ProjectQueries";
import {
  getProjectGroupGuidanceStatusFromCache,
  getProjectGroupProjectCountFromCache,
  getProjectGroupStudentProjectPortfoliosCountFromCache,
  getProjectGroupYearGroupFromCache,
  getGradeWiseProjectCountFromCache,
} from "Projects/modules/ProjectGraphqlHelpers";
import { withLoader, I18nHOC, LinkWithTooltip } from "UIComponents";
import { getPathFromFullPath, goToBack } from "modules/NavigationModule";
import { TopHeader } from "AppComponents";
import {
  getProjectGroupTypeFromId,
  updateYearGroupId,
  updateProjectModalAndDialogueValue,
  isStudentIEAssessment,
  getHelpTextLabel,
} from "Projects/modules/ProjectModules";
import {
  PROJECT_GROUP_TYPE_PERSONAL,
  PROJECT_GROUP_TYPE_COMMUNITY,
  PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
  PROJECT_GROUP_TYPE_DP_CAS,
  ROLE_ADMIN,
  ROLE_SUPERVISOR,
  PROJECT_STATUS_PENDING_FOR_APPROVAL,
  ROLE_STUDENT,
} from "Projects/Constants/stringConstants";
import { Button, Tabs } from "@toddle-design/web";
import YearGroupName from "Projects/components/YearGroupName";
import { AddOutlined, InsightsSquareOutlined } from "@toddle-design/web-icons";
import { CURRICULUM_TYPE_MYP } from "Constants/stringConstants";
import { setToastMsg } from "Login/modules/LoginModule";
import { getOrganizationTemplatesWithHelpEnabledFieldsQuery } from "modules/CommonQuery";
import { getOrganizationTemplatesWithHelpEnabledFieldsFromCache } from "modules/CommonGraphqlHelpers";
import ACLStore from "lib/aclStore";

const styles = {
  pendingApprovalCountMultiDigitStyle: {
    width: "28px",
    height: "28px",
  },
  leftContainerStyle: {
    maxWidth: "320px",
  },
};

const RIGHT_PANE_ANIMATION_TIME = 300;

const RightContainer = ({
  projectGroupType,
  onRightContainerClick,
  t,
  pendingProjectApprovalCount,
  userTypeValue,
  projectGroupId,
  goToRelativeRoute,
  orgGradesList,
  setToastMsg,
  updateProjectModalAndDialogueValue,
}) => {
  switch (projectGroupType) {
    case PROJECT_GROUP_TYPE_SERVICE_AS_ACTON: {
      const onClickCreate = () => {
        if (_.isEmpty(orgGradesList)) {
          setToastMsg({
            msg: "toastMsgs:please_tell_your_admin_to_assign_class",
            type: "alert",
          });
          return;
        }

        goToRelativeRoute({
          route: `./projects/${projectGroupId}/create-project`,
          type: "push",
          replacePath: "projects",
        });
      };

      return (
        <div className={classes.headerButtonContainer}>
          {userTypeValue !== ROLE_STUDENT && (
            <div className={classes.insightButtonContainer}>
              <Button
                variant={"outlined-subtle"}
                icon={<InsightsSquareOutlined />}
                onClick={onRightContainerClick}
              >
                {t("common:insights")}
              </Button>
            </div>
          )}
          <Button
            variant={"primary"}
            icon={<AddOutlined />}
            onClick={onClickCreate}
          >
            {t("common:create_with_label", {
              label: t("project:new_activity"),
            })}
          </Button>
        </div>
      );
    }
    case PROJECT_GROUP_TYPE_DP_CAS: {
      const pendingApprovalClass = classNames({
        ["text-subtitle-2"]: true,
        [classes.pendingApprovalContainer]: true,
      });
      return (
        userTypeValue !== ROLE_STUDENT && (
          <LinkWithTooltip
            placement={"bottom"}
            tooltip={t("project:no_pending_approvals_tooltip")}
            isVisible={!pendingProjectApprovalCount}
          >
            <Button
              variant={"outlined-subtle"}
              onClick={onRightContainerClick}
              disabled={!pendingProjectApprovalCount}
            >
              <span className={pendingApprovalClass}>
                <span
                  className={classes.projectApprovalCountContainer}
                  style={
                    pendingProjectApprovalCount > 9
                      ? styles.pendingApprovalCountMultiDigitStyle
                      : {}
                  }
                >
                  <span className={classes.projectApprovalCount}>
                    {pendingProjectApprovalCount}
                  </span>
                </span>
                {t("project:pending_approvals")}
              </span>
            </Button>
          </LinkWithTooltip>
        )
      );
    }
    case PROJECT_GROUP_TYPE_COMMUNITY: {
      return (
        userTypeValue !== ROLE_STUDENT && (
          <Button
            variant={"primary"}
            icon={<AddOutlined />}
            onClick={() =>
              updateProjectModalAndDialogueValue({
                displayCreateEditProjectModel: true,
                modalMode: "CREATE",
              })
            }
          >
            {t("common:create_with_label", {
              label: t("project:project"),
            })}
          </Button>
        )
      );
    }
    default:
      return null;
  }
};

const ProjectGroup = props => {
  const {
    goToBack,
    isVisible,
    userTypeValue,
    projectGroupType,
    goToRelativeRoute,
    projectCount,
    portfolioCount,
    pendingProjectApprovalTotalCount,
    router: { location: { pathname = null } = {} } = {},
    t,
    params: { portfolioId },
    yearGroupId,
    updateYearGroupId,
    projectGroupId,
    orgGradesList,
    setToastMsg,
    courseId,
    updateProjectModalAndDialogueValue,
    template,
    projectGroupSubType,
  } = props;

  const {
    currentRightPane,
    fieldId,
    showRightPane,
    onCloseRightPane,
    handleRightPane,
  } = useHandleRightPane({
    fieldId: "",
    currentRightPane: "",
    showRightPane: false,
    rightPaneAnimationTime: RIGHT_PANE_ANIMATION_TIME,
  });

  const [currentFieldInfo, setCurrentFieldInfo] = useState({
    helperTextId: "",
    currentFieldKey: "",
  });

  const onHelpClick = params => {
    const { helperTextId, id, fieldKey } = params;
    handleRightPane({
      rightPane: "help",
      id,
    });
    setCurrentFieldInfo(prevState => ({
      ...prevState,
      currentFieldKey: fieldKey,
      helperTextId,
    }));
  };

  const rightPaneClasses = classNames(
    { [classes.visible]: showRightPane },
    { [classes.hidden]: !showRightPane },
    { [classes.rightPane]: true }
  );

  const renderRightPaneComponent = currentRightPane => {
    switch (currentRightPane) {
      case "help":
        return (
          <HelperPane
            fieldKey={currentFieldInfo.currentFieldKey}
            helperTextId={currentFieldInfo.helperTextId}
            onClose={onCloseRightPane}
            label={getHelpTextLabel({
              template,
              uid: currentFieldInfo.currentFieldKey,
            })}
          />
        );
      default:
        return null;
    }
  };

  const projectGroupConfig = _.get(
    template,
    `body.projectGroupConfig.${userTypeValue}`,
    {}
  );

  const path = getPathFromFullPath(pathname);
  const currentPath = _.last(_.split(pathname, "/"));
  const toggleTab = value => {
    onCloseRightPane();
    goToRelativeRoute({
      route: path == userTypeValue ? `../../${value}` : `../${value}`,
      type: "replace",
    });
  };

  const tabs = _.get(projectGroupConfig, "tabs", []);

  let updatedTabs = _.map(tabs, tab => {
    return {
      label: t(tab.name),
      value:
        t(tab.name) === t("project:guidance")
          ? `guidance/${userTypeValue}`
          : tab.path,
    };
  });

  if (!ACLStore.can("FeatureFlag:isCasInsightEnabled")) {
    updatedTabs = _.filter(updatedTabs, tab => tab.value !== "casInsights");
  }

  const onRightContainerClick = () => {
    switch (projectGroupType) {
      case PROJECT_GROUP_TYPE_DP_CAS: {
        goToRelativeRoute({
          route: "./projectApproval",
        });
        break;
      }
      case PROJECT_GROUP_TYPE_SERVICE_AS_ACTON: {
        goToRelativeRoute({
          route: "../insights",
          type: "push",
        });
        break;
      }
      default:
        return null;
    }
  };

  const getDefaultRouteValue = () => {
    if (_.isEqual(ROLE_ADMIN, userTypeValue)) {
      return _.get(tabs, "0.path", "setup");
    }
    if (_.isEqual(projectGroupType, PROJECT_GROUP_TYPE_DP_CAS)) {
      return "portfolio";
    } else {
      return "progress";
    }
  };

  const goToDefaultProjectPage = () => {
    const defaultRoute = _.get(
      projectGroupConfig,
      "defaultRoute",
      getDefaultRouteValue()
    );

    const projectsCreated =
      PROJECT_GROUP_TYPE_DP_CAS === projectGroupType
        ? portfolioCount > 0
        : projectCount > 0;

    if (ROLE_ADMIN === userTypeValue && !projectsCreated) {
      const path = _.get(tabs, "0.path", "setup");
      goToRelativeRoute({
        route: `./${path}`,
        type: "replace",
      });
    } else {
      goToRelativeRoute({ route: `./${defaultRoute}`, type: "replace" });
    }
  };

  useEffect(() => {
    if (!props.children && !_.isEmpty(projectGroupConfig)) {
      goToDefaultProjectPage();
    }
  }, [props.children, projectGroupConfig, projectGroupType]);

  useMount(() => {
    updateYearGroupId(yearGroupId);
  });

  if (props.children) {
    return (
      <Fragment>
        {/* todo : update the tablist component where the hover effect can be applied */}
        {/* We need to hide the top header in case of CAS student portfolios in all the sides(student,advisor,coordinator) as we are creating a dynamic header for that page */}
        {/* we need to hide top header in CAS projectApproval screen in coordinator side */}
        {!(
          (_.includes(
            ["studentPortfolio", "projectApproval", portfolioId],
            currentPath
          ) &&
            _.includes([PROJECT_GROUP_TYPE_DP_CAS], projectGroupType)) ||
          (isStudentIEAssessment({
            role: userTypeValue,
            projectGroupSubType,
          }) &&
            _.includes(pathname, "progress"))
        ) && (
          <TopHeader
            onBack={goToBack}
            leftContainerStyle={styles.leftContainerStyle}
            //remove back button if we are inside the course
            showBackButton={_.isEmpty(courseId)}
            headerText={<YearGroupName />}
            showMiddleContainer={true}
            showRightContainer={_.includes(
              ["progress", "portfolios"],
              currentPath
            )}
            middleContainer={
              _.size(updatedTabs) > 1 ? (
                <Tabs
                  name="tabs"
                  onChange={toggleTab}
                  options={updatedTabs}
                  value={
                    _.isEqual(path, userTypeValue)
                      ? `guidance/${userTypeValue}`
                      : path
                  }
                />
              ) : null
            }
            rightContainer={
              <RightContainer
                projectGroupType={projectGroupType}
                onRightContainerClick={onRightContainerClick}
                t={t}
                pendingProjectApprovalCount={pendingProjectApprovalTotalCount}
                userTypeValue={userTypeValue}
                projectGroupId={projectGroupId}
                goToRelativeRoute={goToRelativeRoute}
                orgGradesList={orgGradesList}
                setToastMsg={setToastMsg}
                updateProjectModalAndDialogueValue={
                  updateProjectModalAndDialogueValue
                }
              />
            }
          />
        )}
        {cloneElement(props.children, {
          showHeader: false,
          template,
          projectGroupSubType,
          projectGroupType,
          onCloseRightPane,
          onHelpClick,
          currentRightPane,
          fieldId,
          currentFieldInfo,
        })}

        <div className={rightPaneClasses}>
          {renderRightPaneComponent(currentRightPane)}
        </div>
      </Fragment>
    );
  }

  return null;
};

ProjectGroup.displayName = "ProjectGroup";

const mapActionCreators = {
  goToRelativeRoute,
  goToBack,
  updateYearGroupId,
  setToastMsg,
  updateProjectModalAndDialogueValue,
};

const mapStateToProps = (state, ownProps) => {
  const projectGroupId = ownProps.params.projectGroup;
  const courseId = ownProps.params.course_id;

  const {
    login: { userInfo },
  } = state;
  const userTypeValue = getRoleValue({ user_type: userInfo.user_type });
  const projectGroupType = getProjectGroupTypeFromId({ state, projectGroupId });
  const userId = userInfo.id;
  const curriculumProgramId = _.find(
    state.platform.organizationCurriculumPrograms,
    item => item.type === CURRICULUM_TYPE_MYP
  )?.id;
  const curriculumProgramIds = [curriculumProgramId];
  const userType = userInfo.user_type === "staff" ? "STAFF" : "STUDENT";

  const organizationId = _.get(state, "login.userInfo.org_id", null);
  const currentCurriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType",
    null
  );

  const templateFilters = {
    templateType: _.includes([PROJECT_GROUP_TYPE_DP_CAS], projectGroupType)
      ? "PORTFOLIO"
      : "PROJECT",
    curriculumIds: currentCurriculumProgramType
      ? [currentCurriculumProgramType]
      : [],
    subTypes: projectGroupType ? [projectGroupType] : [],
  };

  return {
    projectGroupId,
    isGuidanceVisible: false,
    userTypeValue,
    projectGroupType,
    userId,
    curriculumProgramIds,
    userType,
    courseId,
    organizationId,
    templateFilters,
    courses: courseId ? [courseId] : [],
  };
};

export default compose(
  I18nHOC({
    resource: ["project", "common"],
  }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationTemplatesWithHelpEnabledFieldsQuery, {
    name: "getOrganizationTemplates",
    options: ({ organizationId, templateFilters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: organizationId,
        filters: templateFilters,
      },
    }),
    props: ({
      getOrganizationTemplates,
      ownProps: { organizationId, templateFilters, isData, isLoading },
    }) => {
      const data = getOrganizationTemplatesWithHelpEnabledFieldsFromCache({
        id: organizationId,
        filters: templateFilters,
      });

      const template = _.first(_.get(data, "node.templates", []));
      // const template = require("templates/PlannerTemplates/toddle_dp_cas_portfolio.json");

      return {
        isData: !_.isEmpty(data) && isData,
        isLoading:
          _.includes([1, 2], getOrganizationTemplates["networkStatus"]) ||
          isLoading,
        template,
      };
    },
  }),
  graphql(getProjectGroupGuidanceStatusQuery, {
    name: "getProjectGroupGuidanceStatus",
    options: ({ projectGroupId, userTypeValue }) => {
      const isSupervisor = userTypeValue === ROLE_SUPERVISOR;
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: projectGroupId,
          isSupervisorGuidance: isSupervisor,
        },
        context: {
          useHttpLink: true,
        },
      };
    },
    props({
      getProjectGroupGuidanceStatus,
      ownProps: { isLoading, projectGroupId, userTypeValue },
    }) {
      const isSupervisor = userTypeValue == ROLE_SUPERVISOR;
      let projectGroupGuidanceStatus = getProjectGroupGuidanceStatusFromCache({
        id: projectGroupId,
      });
      projectGroupGuidanceStatus = _.get(
        projectGroupGuidanceStatus,
        "node",
        {}
      );
      const isVisible = isSupervisor
        ? projectGroupGuidanceStatus.showSupervisorGuidance
        : projectGroupGuidanceStatus.showStudentGuidance;
      return {
        isVisible,
        isData: !_.isEmpty(projectGroupGuidanceStatus),
        isLoading:
          getProjectGroupGuidanceStatus["networkStatus"] == 1 ||
          getProjectGroupGuidanceStatus["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getProjectGroupProjectCountQuery, {
    name: "getProjectGroupProjectCount",
    skip: ({ userTypeValue, projectGroupType }) =>
      !(
        ROLE_ADMIN === userTypeValue &&
        !_.includes([PROJECT_GROUP_TYPE_DP_CAS], projectGroupType)
      ),
    options: ({ projectGroupId, courses }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: projectGroupId,
          filters: { courses },
        },
      };
    },
    props({
      getProjectGroupProjectCount,
      ownProps: { isLoading, projectGroupId, courses },
    }) {
      const result = getProjectGroupProjectCountFromCache({
        id: projectGroupId,
        filters: { courses },
      });
      const projectCount = _.get(result, "node.projects.totalCount", "");
      return {
        projectCount,
        isData: !_.isEmpty(result),
        isLoading:
          getProjectGroupProjectCount["networkStatus"] == 1 ||
          getProjectGroupProjectCount["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getProjectGroupStudentProjectPortfoliosCountQuery, {
    name: "getProjectGroupStudentProjectPortfoliosCount",
    skip: ({ userTypeValue, projectGroupType }) =>
      !(
        ROLE_ADMIN === userTypeValue &&
        _.includes(PROJECT_GROUP_TYPE_DP_CAS, projectGroupType)
      ),
    options: ({ projectGroupId }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: projectGroupId,
        },
      };
    },
    props({
      getProjectGroupStudentProjectPortfoliosCount,
      ownProps: { isLoading, projectGroupId, isData },
    }) {
      const result = getProjectGroupStudentProjectPortfoliosCountFromCache({
        id: projectGroupId,
      });
      const portfolioCount = _.get(
        result,
        "node.studentProjectPortfolio.totalCount",
        ""
      );
      return {
        portfolioCount,
        isData: !_.isEmpty(result) && isData,
        isLoading:
          _.includes(
            [1, 2],
            getProjectGroupStudentProjectPortfoliosCount["networkStatus"]
          ) || isLoading,
      };
    },
  }),
  graphql(getProjectGroupProjectCountQuery, {
    name: "getProjectGroupProjectCount",
    skip: ({ projectGroupType }) =>
      projectGroupType !== PROJECT_GROUP_TYPE_DP_CAS,
    options: ({ params: { projectGroup }, courses }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: projectGroup,
          filters: { status: PROJECT_STATUS_PENDING_FOR_APPROVAL, courses },
        },
      };
    },
    props({
      getProjectGroupProjectCount,
      ownProps: {
        params: { projectGroup },
        isData,
        isLoading,
        courses,
      },
    }) {
      const result = getProjectGroupProjectCountFromCache({
        id: projectGroup,
        filters: { status: PROJECT_STATUS_PENDING_FOR_APPROVAL, courses },
      });
      const pendingProjectApprovalTotalCount = _.get(
        result,
        "node.projects.totalCount",
        ""
      );

      return {
        pendingProjectApprovalTotalCount,
        isData: !_.isEmpty(result) && isData,
        isLoading:
          getProjectGroupProjectCount["networkStatus"] == 1 ||
          getProjectGroupProjectCount["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getProjectGroupYearGroupQuery, {
    name: "getProjectGroupYearGroup",
    options: ({ projectGroupId }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: projectGroupId },
    }),
    props({
      getProjectGroupYearGroup,
      ownProps: { isData, isLoading, projectGroupId },
    }) {
      const data = getProjectGroupYearGroupFromCache({ id: projectGroupId });
      const projectGroupSubType = _.get(data, "node.subType", "");

      return {
        isData: !_.isEmpty(data) && isData,
        isLoading:
          _.includes([1, 2], getProjectGroupYearGroup["networkStatus"]) ||
          isLoading,
        yearGroupId: _.get(data, "node.yearGroup.id", null),
        projectGroupSubType,
      };
    },
  }),
  graphql(getGradeWiseProjectCountQuery, {
    name: "getGradeWiseProjectCount",
    skip: ({ projectGroupType }) =>
      projectGroupType !== PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
    options: ({
      userId,
      userType,
      curriculumProgramIds,
      params: { projectGroup },
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: userType,
        filters: { projectGroupIds: [projectGroup] },
        allGradesFilters: { curriculumProgramIds },
      },
    }),
    props({
      getGradeWiseProjectCount,
      ownProps: {
        userId,
        userType,
        params: { projectGroup },
        curriculumProgramIds,
        isData,
        isLoading,
      },
    }) {
      const data = getGradeWiseProjectCountFromCache({
        id: userId,
        type: userType,
        filters: { projectGroupIds: [projectGroup] },
        allGradesFilters: { curriculumProgramIds },
      });
      return {
        isData: !_.isEmpty(data) && isData,
        isLoading:
          _.includes([1, 2], getGradeWiseProjectCount["networkStatus"]) ||
          isLoading,
        orgGradesList: _.get(data, "node.allGrades", []),
      };
    },
  }),
  withLoader
)(ProjectGroup);
