import React from "react";
import classes from "./InputItem.scss";
import { colors } from "Constants";
import classNames from "classnames";
import { TextAreaInput, UIButton } from "UIComponents";
import DropDown from "../DropDown";
import { Checkbox } from "@toddle-design/web";

const textAreaStyles = {
  border: "none",
  resize: "none",
  wordBreak: "keep-all",
  color: colors.gray48,
  padding: 0,
};

class InputItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textArea = null;

    this.state = { isEditing: false, defaultValue: "" };
  }
  updateInputValue = params => {
    const { updateListValue, index } = this.props;
    updateListValue({ text: params["textArea"], index: index, action: "EDIT" });
  };

  toggleInputSelection = () => {
    const { updateListValue, index, isSelected } = this.props;
    updateListValue({
      isSelected: !isSelected,
      index: index,
      action: "SELECTION",
    });
  };

  onEnterPress = value => {
    const {
      updateListValue,
      index,
      isLast,
      thinInteractions,
      onItemBlur,
      id,
      shouldCreateOnEnter,
    } = this.props;

    if (
      value &&
      shouldCreateOnEnter &&
      (thinInteractions || (!thinInteractions && isLast))
    ) {
      updateListValue({
        text: "",
        index: index + 1,
        action: "ADD",
      });
    } else {
      onItemBlur({ id, index });
    }
  };

  onComplete = () => {
    const { updateListValue, index } = this.props;
    updateListValue({ isComplete: true, index });
  };

  onKeyDown = event => {
    if (event.keyCode == 8) {
      const value = event.target.value;
      if (!value) {
        const { updateListValue, index, thinInteractions } = this.props;
        thinInteractions ? updateListValue({ index, action: "REMOVE" }) : null;
      }
    }
  };

  inputRef = ref => {
    this.textArea = ref;
  };

  focus = () => {
    if (this.textArea) {
      this.textArea.focus();
      this.textArea.setSelectionRange(
        this.props.value.length,
        this.props.value.length
      );
    }
  };

  blur = () => {
    if (this.textArea) {
      this.textArea.blur();
    }
  };

  renderContent = () => {
    const {
      index,
      placeholder,
      value,
      id,
      isLast,
      isEditing,
      showListItemNumber,
      onEditClicked,
      onItemBlur,
      updateListValue,
      thinInteractions,
      onBlur,
      onFocus,
      mode,
      hasSelection,
      isSelected,
      isCurrentFocused,
      shouldUpdateMaxRows,
    } = this.props;

    if (isEditing && !isLast && !thinInteractions) {
      textAreaStyles.border = `1px solid ${colors.yellow50}`;
      textAreaStyles.padding = "16px";
    } else {
      textAreaStyles.border = "none";
      textAreaStyles.padding = "0px";
    }

    return (
      <div
        className={classes.textAreaContainer}
        style={isCurrentFocused ? { borderColor: colors.gray48 } : {}}
      >
        {showListItemNumber && !isEditing && mode !== "view" ? (
          <div className={classes.numberContainer}>{index + 1}</div>
        ) : (
          mode === "view" && (
            <span className={classes.viewNumberCon}>{`${index + 1}. `}</span>
          )
        )}
        {hasSelection && !isEditing && mode !== "view" && (
          <Checkbox
            isChecked={isSelected}
            size={"xx-small"}
            onChange={this.toggleInputSelection}
          />
        )}

        <div className={classes.inputContainer}>
          {thinInteractions || isLast || isEditing ? (
            <TextAreaInput
              minRows={1}
              textAreaStyles={textAreaStyles}
              name="textArea"
              placeholder={placeholder}
              value={value}
              updateInputField={this.updateInputValue}
              onEnterPress={this.onEnterPress}
              allowShiftEnter={true}
              onKeyDown={this.onKeyDown}
              updateInputRef={this.inputRef}
              focus={true}
              onFocusInputField={onFocus}
              onBlurInputField={onBlur}
              className={isEditing ? classes.editTextArea : null}
              mode={mode}
              maxRows={shouldUpdateMaxRows ? 3 : null}
            />
          ) : (
            <div>{value}</div>
          )}
          {isEditing && (
            <div className={classes.buttonsCotainer}>
              <div
                className={classes.cancelButton}
                onClick={() => {
                  this.updateInputValue({
                    textArea: this.state.defaultValue,
                  });
                  onItemBlur({ id, index });
                }}
              >{`Cancel`}</div>
              <UIButton
                type="filled"
                size="sm"
                color="pink"
                onClick={() => this.onEnterPress(value)}
              >{`Save Changes`}</UIButton>
            </div>
          )}
        </div>
        {!isLast && !isEditing && !thinInteractions ? (
          <div className={classes.moreIcon}>
            <DropDown
              onUpdateShowDropDown={value =>
                this.setState({ showDropDown: value })
              }
              // item={item}
              onClickDelete={() => {
                updateListValue({
                  index,
                  action: "REMOVE",
                });
                setTimeout(() => this.onComplete(), 0);
              }}
              onClickEdit={() => onEditClicked({ id, index })}
            />
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { isLast, isEditing, listItemSvg, thinInteractions } = this.props;

    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.containerBg]: isEditing }
    );

    return (
      <div className={containerClass}>
        {listItemSvg && !isEditing ? (
          <div className={classes.listItemSvgContainer}>{listItemSvg}</div>
        ) : null}
        {this.renderContent()}
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.isEditing !== this.props.isEditing && nextProps.isEditing) {
      this.setState({ defaultValue: this.props.value });
    }
  };
}

export default InputItem;
