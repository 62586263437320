import { withAsyncRouteLoading } from "UIComponents";

export default store => {
  return {
    path: "pdresources",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community-entity"
          );
        })
    ),
  };
};
