import PropTypes from "prop-types";
import React from "react";

const HowWeExpressOurselves = props => {
  const cls1 = { fill: "#fff" };
  const cls2 = { fill: "#dbdbdb" };

  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 250 250">
      <title>How we express ourselves active copy</title>
      <rect
        style={cls1}
        x="85.98"
        y="144.48"
        width="13.51"
        height="59.46"
        rx="2.25"
        ry="2.25"
        transform="translate(41.25 -16.31) rotate(12.9)"
      />
      <path d="M96.21,146.14h0a3.06,3.06,0,0,1,.68.08l4.28,1a3,3,0,0,1,2.28,3.64L92.22,199.91a3,3,0,0,1-3,2.36,3.07,3.07,0,0,1-.68-.08l-4.28-1A3,3,0,0,1,82,197.57L93.25,148.5a3,3,0,0,1,3-2.36m0-1.52a4.56,4.56,0,0,0-4.44,3.54L80.53,197.23A4.56,4.56,0,0,0,84,202.7l4.28,1a4.56,4.56,0,0,0,5.46-3.43l11.24-49.07a4.56,4.56,0,0,0-3.43-5.46l-4.28-1a4.57,4.57,0,0,0-1-.12Z" />
      <rect
        style={cls1}
        x="134.78"
        y="144.56"
        width="13.51"
        height="59.46"
        rx="2.25"
        ry="2.25"
        transform="translate(317.93 313.19) rotate(167.3)"
      />
      <path d="M138.14,146.21a3.06,3.06,0,0,1,3,2.37l11.07,49.11a3,3,0,0,1-2.3,3.63l-4.28,1A3,3,0,0,1,142,200l-11.07-49.11a3,3,0,0,1,2.3-3.63l4.28-1a3.07,3.07,0,0,1,.67-.08m0-1.52a4.57,4.57,0,0,0-1,.11l-4.28,1a4.56,4.56,0,0,0-3.45,5.45l11.07,49.11a4.56,4.56,0,0,0,5.45,3.45l4.28-1a4.56,4.56,0,0,0,3.45-5.45l-11.07-49.11a4.56,4.56,0,0,0-4.45-3.56Z" />
      <rect
        style={cls1}
        x="31.1"
        y="46.21"
        width="172.3"
        height="124.33"
        rx="8.08"
        ry="8.08"
        transform="translate(0.19 -0.2) rotate(0.1)"
      />
      <path d="M47.56,46.08l0,1.52,139.53.24A14.87,14.87,0,0,1,202,62.74l-.16,91.56a14.89,14.89,0,0,1-14.89,14.84l-139.53-.24A14.88,14.88,0,0,1,32.54,154l.16-91.56A14.89,14.89,0,0,1,47.56,47.6V46.08m0,0A16.39,16.39,0,0,0,31.18,62.44L31,154a16.39,16.39,0,0,0,16.36,16.41l139.53.24h0a16.39,16.39,0,0,0,16.38-16.36l.16-91.56a16.39,16.39,0,0,0-16.36-16.41L47.59,46.08Z" />
      <rect
        style={cls2}
        x="191.13"
        y="124.04"
        width="27.7"
        height="21.28"
        rx="10.64"
        ry="10.64"
        transform="translate(26.04 -33.06) rotate(9.84)"
      />
      <path d="M53.9,78.84h0l.3,0a2.66,2.66,0,0,1-.29,5.31l-.3,0a2.66,2.66,0,0,1,.29-5.31m0-1.52a4.18,4.18,0,0,0-.45,8.34l.46,0a4.18,4.18,0,0,0,.45-8.34l-.46,0Z" />
      <path d="M57.29,82.82h-.08a.76.76,0,0,1-.67-.84l1.68-15.2.43-.34a1,1,0,0,1,.85-.06l5.29,2.15a.76.76,0,1,1-.57,1.41l-4.6-1.87L58,82.14A.76.76,0,0,1,57.29,82.82Z" />
      <rect
        style={cls1}
        x="189.76"
        y="121"
        width="27.7"
        height="21.28"
        rx="10.64"
        ry="10.64"
        transform="translate(25.5 -32.87) rotate(9.84)"
      />
      <path d="M200.44,122h0a9.22,9.22,0,0,1,1.57.14l6.32,1.1a9.12,9.12,0,0,1-1.55,18.11,9.22,9.22,0,0,1-1.57-.14l-6.32-1.1A9.12,9.12,0,0,1,200.44,122m0-1.52a10.64,10.64,0,0,0-1.81,21.13l6.32,1.1a10.64,10.64,0,0,0,3.64-21l-6.32-1.1a10.7,10.7,0,0,0-1.83-.16Z" />
      <path d="M201.71,127.91h0m-.12.67,4.1,3.42-5,1.84.91-5.26m-.27-1.86a1.13,1.13,0,0,0-1.12.94l-1.14,6.59a1.13,1.13,0,0,0,1.5,1.25l6.28-2.31a1.13,1.13,0,0,0,.33-1.93L202.05,127a1.11,1.11,0,0,0-.72-.26Z" />
      <line style={cls1} x1="45.24" y1="152.19" x2="45.16" y2="156.5" />
      <path d="M45.16,157h0a.51.51,0,0,1-.5-.51l.08-4.31a.51.51,0,0,1,.51-.5h0a.51.51,0,0,1,.5.51l-.08,4.31A.51.51,0,0,1,45.16,157Z" />
      <line style={cls1} x1="47.35" y1="154.38" x2="43.04" y2="154.31" />
      <path d="M47.35,154.89h0L43,154.81a.51.51,0,0,1,0-1h0l4.31.08a.51.51,0,0,1,0,1Z" />
      <line style={cls1} x1="184.26" y1="60.32" x2="180.91" y2="63.94" />
      <path d="M180.91,64.44a.51.51,0,0,1-.37-.85L183.88,60a.51.51,0,0,1,.72,0,.51.51,0,0,1,0,.72l-3.35,3.62A.51.51,0,0,1,180.91,64.44Z" />
      <line style={cls1} x1="184.39" y1="63.8" x2="180.77" y2="60.45" />
      <path d="M184.39,64.31a.51.51,0,0,1-.34-.13l-3.62-3.35a.51.51,0,0,1,0-.72.52.52,0,0,1,.72,0l3.62,3.35a.51.51,0,0,1-.34.88Z" />
      <circle
        style={cls1}
        cx="188.69"
        cy="71"
        r="2.49"
        transform="translate(114.41 258.43) rotate(-89)"
      />
      <path d="M188.69,68.52l0,1a1.47,1.47,0,0,1,1.45,1.5,1.48,1.48,0,0,1-1.5,1.45,1.47,1.47,0,0,1-1.45-1.5,1.48,1.48,0,0,1,1.47-1.45v-1m0,0a2.49,2.49,0,0,0,0,5h0a2.49,2.49,0,0,0,0-5Z" />
      <circle
        style={cls1}
        cx="170.02"
        cy="64.34"
        r="2.43"
        transform="matrix(0.02, -1, 1, 0.02, 102.72, 233.22)"
      />
      <path d="M170,61.91l0,1a1.42,1.42,0,1,1-1.44,1.39A1.42,1.42,0,0,1,170,62.92v-1m0,0a2.43,2.43,0,0,0,0,4.86h0a2.43,2.43,0,0,0,0-4.86Z" />
      <circle
        style={cls1}
        cx="62.49"
        cy="156.78"
        r="3.23"
        transform="translate(-95.36 216.53) rotate(-89)"
      />
      <path d="M62.49,153.56l0,1a2.21,2.21,0,0,1,2.17,2.25A2.23,2.23,0,0,1,62.45,159h0a2.21,2.21,0,0,1-2.17-2.25,2.22,2.22,0,0,1,2.21-2.17v-1m0,0a3.23,3.23,0,0,0-.06,6.45h.06a3.23,3.23,0,0,0,.06-6.45Z" />
      <circle
        style={cls1}
        cx="43.98"
        cy="136.71"
        r="2.47"
        transform="translate(-93.47 178.3) rotate(-89)"
      />
      <path d="M44,134.24l0,1a1.46,1.46,0,1,1-1.48,1.43A1.46,1.46,0,0,1,44,135.25v-1m0,0a2.47,2.47,0,0,0,0,4.94h0a2.47,2.47,0,0,0,0-4.94Z" />
      <path
        style={cls2}
        d="M111.76,82.77h45.5a5.68,5.68,0,0,1,5.68,5.68v28.88a28.27,28.27,0,0,1-28.27,28.27H118.15a6.39,6.39,0,0,1-6.39-6.39V82.77a0,0,0,0,1,0,0Z"
        transform="translate(27.74 -26.84) rotate(12.41)"
      />
      <path
        style={cls1}
        d="M108.31,76.22h46.6a2.29,2.29,0,0,1,2.29,2.29v31.91a28.64,28.64,0,0,1-28.64,28.64h-9.92A12.62,12.62,0,0,1,106,126.44V78.51a2.29,2.29,0,0,1,2.29-2.29Z"
        transform="translate(26.2 -25.76) rotate(12.41)"
      />
      <path d="M130.36,139.69a26.36,26.36,0,0,1-25.74-32l7-31.84a5.42,5.42,0,0,1,6.44-4.12l40.92,9a5.41,5.41,0,0,1,4.12,6.44l-7,31.84A26.4,26.4,0,0,1,130.36,139.69ZM116.91,73.11a3.89,3.89,0,0,0-3.79,3.05l-7,31.84a24.83,24.83,0,1,0,48.5,10.67l7-31.84a3.89,3.89,0,0,0-3-4.63l-40.92-9A3.88,3.88,0,0,0,116.91,73.11Z" />
      <path d="M139.91,126.53l-.16,0a.76.76,0,0,1-.58-.91,11.24,11.24,0,1,0-22-4.83.76.76,0,0,1-1.48-.33,12.76,12.76,0,1,1,24.93,5.49A.76.76,0,0,1,139.91,126.53Z" />
      <path d="M122,97.65a2.06,2.06,0,0,1-.45,0,2.11,2.11,0,0,1-1.6-2.5A2.1,2.1,0,1,1,122,97.65Zm0-2.17.06.09A.08.08,0,0,0,122,95.47Z" />
      <path d="M145.43,102.8a2.13,2.13,0,0,1-.46,0,2.11,2.11,0,1,1,.46,0Zm0-2.17.06.09A.08.08,0,0,0,145.44,100.63Z" />
      <path
        style={cls1}
        d="M94.68,83.86h19.4A15.89,15.89,0,0,1,130,99.75V121.1a25.59,25.59,0,0,1-25.59,25.59h0A25.59,25.59,0,0,1,78.79,121.1V99.75A15.89,15.89,0,0,1,94.68,83.86Z"
        transform="translate(2.03 -1.8) rotate(1)"
      />
      <path d="M104.29,147.46h-.47a26.35,26.35,0,0,1-25.89-26.81L78.5,88a5.36,5.36,0,0,1,1.65-3.79A5.51,5.51,0,0,1,84,82.74l41.89.73A5.41,5.41,0,0,1,131.2,89l-.57,32.6A26.38,26.38,0,0,1,104.29,147.46ZM83.9,84.26A3.88,3.88,0,0,0,80,88.07l-.57,32.6a24.83,24.83,0,0,0,24.39,25.26h0a24.91,24.91,0,0,0,25.26-24.39l.57-32.6a3.89,3.89,0,0,0-3.82-4L84,84.26Z" />
      <path
        style={cls2}
        d="M104,137.79h0a19.91,19.91,0,0,1-19.79-20l.13-21.4a3.61,3.61,0,0,1,3.64-3.59l32.59.19a3.61,3.61,0,0,1,3.59,3.64L124,118A19.91,19.91,0,0,1,104,137.79Z"
      />
      <path d="M104.16,128.67h-.23a12.77,12.77,0,0,1-12.54-13,.76.76,0,0,1,.76-.75h0a.76.76,0,0,1,.75.77,11.24,11.24,0,0,0,11,11.44h.2a11.26,11.26,0,0,0,11.24-11,.76.76,0,0,1,.76-.75h0a.76.76,0,0,1,.75.77,12.76,12.76,0,0,1-12.76,12.54Z" />
      <path d="M92.4,104.13h0a2.1,2.1,0,0,1,0-4.2h0a2.1,2.1,0,0,1,2.07,2.14h0A2.1,2.1,0,0,1,92.4,104.13Zm1.08-2.08h0ZM92.4,102h0Z" />
      <path d="M116.4,104.55h0a2.1,2.1,0,0,1,0-4.2h0a2.1,2.1,0,0,1,1.43,3.61A2.09,2.09,0,0,1,116.4,104.55Zm0-2.18.08.08A.07.07,0,0,0,116.4,102.37Z" />
    </svg>
  );
};

export default HowWeExpressOurselves;
