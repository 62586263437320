import { colors } from "@toddle-design/theme";
import moment from "moment";
import ACLStore from "lib/aclStore";
import { getDaysNameOrIndexArray, groupRotationDaysMemoize } from "Utils";
import { getRotationDayRangeMemoize } from "AttendanceManager/modules/AttendanceManagerModule";
import { ATTENDANCE_GLOBAL_CONSTANTS } from "modules/Services";
import { ATTENDANCE_CONSTANTS } from "AttendanceDashboard/constants/stringConstants";
import {
  getFormattedStartDate,
  formatDate,
  getTodaysDate,
  getPreviousDayDate,
  getNextDayDate,
} from "Utils/DateTimeHelpers";

const dateFormat = "YYYY-MM-DD";
const MAX_READABLE_TEXT_LENGTH = 100;

export const sendReminderInitialObj = {
  checkedCourseIds: [], // Explicitly selected course ids
  selectedCourseIds: [], // To track the selected course ids based on different scenarios
  deSelectedCourseIds: [], // De selected course ids when any status category is enabled
  activeDropdownOption: null, // Status category selected
  availableClassesCount: 0, // Count of classes available on the count
};

const dateViewTypes = ["DAILY", "WEEKLY", "ROTATION_CYCLE"];

export const studentTabSortOptions = [
  {
    key: ATTENDANCE_CONSTANTS.FIRST_NAME_AZ,
    label: "common:first_name_az",
    direction: ATTENDANCE_CONSTANTS.ASC,
    sortEntity: ATTENDANCE_CONSTANTS.FIRST_NAME,
  },
  {
    key: ATTENDANCE_CONSTANTS.FIRST_NAME_ZA,
    label: "common:first_name_za",
    direction: ATTENDANCE_CONSTANTS.DESC,
    sortEntity: ATTENDANCE_CONSTANTS.FIRST_NAME,
  },
  {
    key: ATTENDANCE_CONSTANTS.LAST_NAME_AZ,
    label: "common:last_name_az",
    direction: ATTENDANCE_CONSTANTS.ASC,
    sortEntity: ATTENDANCE_CONSTANTS.LAST_NAME,
  },
  {
    key: ATTENDANCE_CONSTANTS.LAST_NAME_ZA,
    label: "common:last_name_za",
    direction: ATTENDANCE_CONSTANTS.DESC,
    sortEntity: ATTENDANCE_CONSTANTS.LAST_NAME,
  },
];

const dateViewTypeOptionsMapping = [
  {
    key: ATTENDANCE_CONSTANTS.DAILY,
    label: "common:daily",
  },
  {
    key: ATTENDANCE_CONSTANTS.WEEKLY,
    label: "common:weekly",
  },
  {
    key: ATTENDANCE_GLOBAL_CONSTANTS.ROTATION_CYCLE,
    label: "common:rotation_cycle",
  },
  {
    key: ATTENDANCE_CONSTANTS.MONTHLY,
    label: "common:monthly",
  },
  {
    key: ATTENDANCE_CONSTANTS.YEARLY,
    label: "common:yearly",
  },
  {
    key: ATTENDANCE_CONSTANTS.DIVIDER,
  },
  {
    key: ATTENDANCE_CONSTANTS.CUSTOM_RANGE,
    label: "common:custom_range",
  },
];

const attendanceOptionStatusFieldMapping = {
  MARKED: "markedCount",
  NOT_MARKED: "notMarkedCount",
  PARTIALLY_MARKED: "partiallyMarkedCount",
};

export const ACTION_DROPDOWN_ATTENDANCE_OPTIONS = [
  {
    key: "mark_attendance",
    editAttendanceLabel: "attendance:edit_attendance",
    markAttendanceLabel: "attendance:mark_attendance",
    icon: "StudentEditOutlined",
  },
  {
    key: "assign_teacher",
    label: "attendance:assign_teacher",
    icon: "SetupOutlined",
  },
  {
    key: "send_reminder",
    label: "attendance:send_reminder",
    icon: "EnvelopePlainOutlined",
  },
];

const ATTENDANCE_DETAIL_OPTION_ONCE_A_DAY = [
  {
    key: "MARKED",
    label: "attendance:classes_marked",
    statusLabel: "Marked",
    textColor: colors.black,
  },
  {
    key: "NOT_MARKED",
    label: "attendance:classes_not_marked",
    statusLabel: "Not marked",
    textColor: colors.textCritical,
  },
  {
    key: "PARTIALLY_MARKED",
    label: "attendance:classes_partially_marked",
    statusLabel: "Partially marked",
    textColor: colors.textWarning,
  },
];

const ATTENDANCE_DETAIL_STUDENT_TAB_OVERVIEW = [
  {
    key: "OVERALL_PRESENCE",
    subLabel: "attendance:overall_presence",
    textColor: colors.black,
  },
  {
    key: "PERFECT_ATTENDANCE",
    subLabel: "attendance:with_perfect_attendance",
    textColor: colors.textLink,
  },
  {
    key: "MORE_THAN_90",
    subLabel: "attendance:with_more_than_percent_presence",
    textColor: colors.textLink,
  },
  {
    key: "LESS_THAN_90",
    subLabel: "attendance:with_less_than_percent_presence",
    textColor: colors.textCritical,
  },
];

export const checkSMSSendVisible = () => {
  return ACLStore.can("Common:AttendanceSMS");
};

export const checkEmailSendVisible = () => {
  return ACLStore.can("Common:AttendanceMarkedEmail");
};

export const getHeaderTabs = ({ t }) => {
  const excusalFeatureFlag = ACLStore.can("Common:AttendanceExcusals");
  const attendanceDashboardTab = [
    {
      label: t("common:class_plural"),
      value: ATTENDANCE_CONSTANTS.CLASSES,
    },
    {
      label: t("common:student_plural"),
      value: ATTENDANCE_CONSTANTS.STUDENTS,
    },
  ];
  if (excusalFeatureFlag) {
    attendanceDashboardTab.push({
      label: t("attendance:excusals"),
      value: ATTENDANCE_CONSTANTS.EXCUSALS,
    });
  }
  return attendanceDashboardTab;
};

export const getAttendanceExcusalHeaderData = ({ t }) => {
  const dropdownOptions = _.map(
    studentTabSortOptions,
    ({ key, label, direction, sortEntity }) => {
      return {
        key,
        label: t(label),
        direction,
        sortEntity,
      };
    }
  );
  return [
    {
      key: ATTENDANCE_CONSTANTS.STUDENT,
      entity: ATTENDANCE_CONSTANTS.STUDENT,
      value: t("common:student_name"),
      style: {
        width: "180px",
      },
      sortOptions: dropdownOptions,
      sortType: "dropdown",
    },
    {
      key: ATTENDANCE_CONSTANTS.DURATION,
      entity: ATTENDANCE_CONSTANTS.DURATION,
      value: t("common:duration"),
      style: {
        width: "120px",
      },
    },
    {
      key: ATTENDANCE_CONSTANTS.EXCUSAL,
      entity: ATTENDANCE_CONSTANTS.EXCUSAL,
      value: t("attendance:excusal"),
      style: {
        width: "416px",
      },
    },
    {
      key: ATTENDANCE_CONSTANTS.ATTENDANCE,
      entity: ATTENDANCE_CONSTANTS.ATTENDANCE,
      value: t("attendance:attendance"),
      style: {
        width: "352px",
      },
    },
  ];
};

export const getClassesDropdownOptions = ({ t }) => {
  return [
    {
      label: "Partially marked",
      key: "PARTIALLY_MARKED",
    },
    {
      label: "Not marked",
      key: "NOT_MARKED",
    },
  ];
};

export const getAttendanceOptionsMenuItem = ({ attendanceOptions }) => {
  return _.map(attendanceOptions, ({ id, label }) => {
    return {
      value: id,
      label,
    };
  });
};

export const getReasonTextTruncated = ({ reason }) => {
  const isReasonTextLong = reason.length > MAX_READABLE_TEXT_LENGTH;
  return {
    isReadMore: isReasonTextLong,
    reasonText: _.slice(reason, 0, MAX_READABLE_TEXT_LENGTH),
  };
};

const commonObjectKeys = {
  alignment: "center",
  entity: "",
  isSticky: false,
  sortOptions: [],
  sortType: "",
  style: {},
  uiType: "Text",
};

export const getAttendanceTableColumnListMemoize = _.memoize(
  params => getAttendanceTableColumnList(params),
  params => JSON.stringify(params)
);

export const getExcusalMenuItem = ({ t }) => {
  const excusalMenuSubItem = [
    {
      id: ATTENDANCE_CONSTANTS.ONGOING,
      key: ATTENDANCE_CONSTANTS.ONGOING,
      label: t("attendance:today"),
      isActive: true,
      isSubMenuItem: true,
    },
    {
      id: ATTENDANCE_CONSTANTS.UPCOMING,
      key: ATTENDANCE_CONSTANTS.UPCOMING,
      label: t("common:upcoming"),
      isActive: false,
      isSubMenuItem: true,
    },
    {
      id: ATTENDANCE_CONSTANTS.EARLIER,
      key: ATTENDANCE_CONSTANTS.EARLIER,
      label: t("classRoom:earlier"),
      isActive: false,
      isSubMenuItem: true,
    },
  ];
  return [
    {
      id: ATTENDANCE_CONSTANTS.NEW_EXCUSALS,
      key: ATTENDANCE_CONSTANTS.NEW_EXCUSALS,
      label: t("attendance:new_excusals"),
    },
    {
      id: ATTENDANCE_CONSTANTS.MARKED_EXCUSALS,
      key: ATTENDANCE_CONSTANTS.MARKED_EXCUSALS,
      label: t("attendance:marked_excusals"),
      subMenu: excusalMenuSubItem,
    },
  ];
};

const getAttendanceTableColumnList = ({
  attendanceOptions,
  t,
  isAttendanceOnceADay,
}) => {
  const attendanceOptionsColumns = _.map(attendanceOptions, ({ id, label }) => {
    return {
      ...commonObjectKeys,
      key: id,
      value: label,
    };
  });

  const sortOptions = [
    {
      key: ATTENDANCE_CONSTANTS.LABEL_AZ,
      label: t("common:class_name_az"),
      direction: ATTENDANCE_CONSTANTS.ASC,
      sortEntity: ATTENDANCE_CONSTANTS.TITLE,
    },
    {
      key: ATTENDANCE_CONSTANTS.LABEL_ZA,
      label: t("common:class_name_za"),
      direction: ATTENDANCE_CONSTANTS.DESC,
      sortEntity: ATTENDANCE_CONSTANTS.TITLE,
    },
  ];

  return [
    {
      key: ATTENDANCE_CONSTANTS.ROW_PREFIX_KEY,
      value: "",
      alignment: "center",
      isSticky: true,
      uiType: ATTENDANCE_CONSTANTS.ROW_PREFIX,
    },
    {
      alignment: "left",
      entity: "CLASS",
      isSticky: true,
      key: "class_name",
      sortOptions,
      sortType: "dropdown",
      value: t("common:class_plural"),
      uiType: isAttendanceOnceADay ? "Text" : "Checkbox",
    },
    {
      alignment: "left",
      entity: ATTENDANCE_CONSTANTS.ATTENDANCE_MARKED_STATUS,
      isSticky: false,
      key: "status",
      sortOptions: [],
      sortType: ATTENDANCE_CONSTANTS.TOGGLE,
      style: { maxWidth: "148px" },
      value: t("common:status"),
      uiType: "Text",
    },
    {
      alignment: "left",
      entity: "TEACHER",
      isSticky: false,
      key: "teacher_name",
      sortOptions: [],
      sortType: "",
      style: { maxWidth: "232px" },
      value: isAttendanceOnceADay
        ? t("attendance:taken_by")
        : t("attendance:teacher_responsible"),
      uiType: "AvatarWithText",
    },
    {
      alignment: "center",
      entity: "",
      isSticky: false,
      key: "total_students",
      sortOptions: [],
      sortType: "",
      style: {},
      value: t("assessmentEvaluation:total_students"),
      uiType: "Text",
    },
    ...attendanceOptionsColumns,
    {
      alignment: "center",
      entity: "",
      isSticky: false,
      key: "actions",
      sortOptions: [],
      sortType: "",
      style: {},
      value: t("common:action_plural"),
      uiType: isAttendanceOnceADay ? "Button" : "ActionButton",
    },
  ];
};

export const getSelectedPeriod = ({
  periods,
  isRotationalCycle,
  currentDayRotationNumber,
  currentDayNumber,
}) => {
  return _.find(periods, ({ rotationDay, day }) =>
    isRotationalCycle
      ? rotationDay === currentDayRotationNumber
      : day === currentDayNumber
  );
};

export const commonStudentsObjectKey = {
  alignment: "center",
  entity: "",
  isSticky: false,
  sortOptions: [],
  sortType: "",
  style: { minWidth: "80px" },
  uiType: "MarkStudentAttendance",
};

export const studentColumnObject = {
  alignment: "left",
  entity: "STUDENT",
  isSticky: true,
  key: "student_name",
  sortOptions: [],
  sortType: "dropdown",
  style: { minWidth: "196px" },
  value: "Student Name",
  uiType: "AvatarWithSubText",
};

export const getFormattedPeriodName = ({ periodsIndex }) => {
  let periodTextFormatted = "";
  const periodsIndexLength = _.size(periodsIndex);

  _.map(periodsIndex, (ind, index) => {
    if (index === periodsIndexLength - 1) {
      periodTextFormatted += `P${ind + 1}`;
    } else {
      periodTextFormatted += `P${ind + 1}, `;
    }
  });

  if (periodsIndexLength > 1) {
    let continuousPeriodCount = 1;
    for (var i = 1; i < periodsIndex.length; i++) {
      continuousPeriodCount += periodsIndex[i] === periodsIndex[i - 1] + 1;
    }
    if (continuousPeriodCount === periodsIndexLength) {
      periodTextFormatted = `P${periodsIndex[0] + 1} - P${
        periodsIndex[periodsIndexLength - 1] + 1
      }`;
    }
  }

  return periodTextFormatted;
};

export const getAttendanceExcusalStatus = isNewExcusal => {
  return isNewExcusal
    ? ATTENDANCE_CONSTANTS.PENDING
    : ATTENDANCE_CONSTANTS.MARKED;
};

export const getStatusByDuration = (isNewExcusal, childKeyStatus) => {
  return isNewExcusal ? ATTENDANCE_CONSTANTS.ONGOING : childKeyStatus;
};

export const getExcusalDurationTime = ({
  durationType,
  startDate,
  endDate,
}) => {
  switch (durationType) {
    case ATTENDANCE_CONSTANTS.SINGLE_DAY:
      return formatDate({
        date: startDate,
        format: ATTENDANCE_CONSTANTS.DD_MMM,
      });

    case ATTENDANCE_CONSTANTS.PARTIAL_DAY:
      return `${formatDate({
        date: startDate,
        format: ATTENDANCE_CONSTANTS.DD_MMM,
      })},`;

    case ATTENDANCE_CONSTANTS.MULTIPLE_DAYS:
      return `${formatDate({
        date: startDate,
        format: ATTENDANCE_CONSTANTS.DD_MMM,
      })} - ${formatDate({
        date: endDate,
        format: ATTENDANCE_CONSTANTS.DD_MMM,
      })}`;
  }
};

export const getExcusalCountNumber = ({
  key,
  organizationExcusalCountdata: excusalCountData,
}) => {
  switch (key) {
    case ATTENDANCE_CONSTANTS.NEW_EXCUSALS:
      return _.get(excusalCountData, "pendingOngoingExcusals.totalCount", 0);

    case ATTENDANCE_CONSTANTS.ONGOING:
      return _.get(excusalCountData, "markedOngoingExcusals.totalCount", 0);

    case ATTENDANCE_CONSTANTS.UPCOMING:
      return _.get(excusalCountData, "markedUpcomingExcusals.totalCount", 0);

    case ATTENDANCE_CONSTANTS.EARLIER:
      return _.get(excusalCountData, "markedEarlierExcusals.totalCount", 0);

    default:
      return 0;
  }
};

export const getExcusalDate = (
  parentKey,
  childKey,
  academicYearDate,
  dateType
) => {
  const isNewExcusal = parentKey === ATTENDANCE_CONSTANTS.NEW_EXCUSALS;
  const isMarkedExcusal = parentKey === ATTENDANCE_CONSTANTS.MARKED_EXCUSALS;

  const today = getTodaysDate();
  const previousDay = getPreviousDayDate();
  const nextDay = getNextDayDate();

  const isEarlierExcusal =
    isMarkedExcusal &&
    childKey === ATTENDANCE_CONSTANTS.EARLIER &&
    dateType === ATTENDANCE_CONSTANTS.START;
  const isUpcomingExcusal =
    isMarkedExcusal &&
    childKey === ATTENDANCE_CONSTANTS.EARLIER &&
    dateType === ATTENDANCE_CONSTANTS.END;
  const isAcedamicYearDate =
    isNewExcusal || isEarlierExcusal || isUpcomingExcusal;

  if (isAcedamicYearDate) {
    return formatDate({
      date: academicYearDate,
      format: ATTENDANCE_CONSTANTS.YYYY_MM_DD,
    });
  } else if (childKey === ATTENDANCE_CONSTANTS.ONGOING) {
    return formatDate({
      date: today,
      format: ATTENDANCE_CONSTANTS.YYYY_MM_DD,
    });
  } else if (childKey === ATTENDANCE_CONSTANTS.UPCOMING) {
    return formatDate({
      date: nextDay,
      format: ATTENDANCE_CONSTANTS.YYYY_MM_DD,
    });
  } else {
    return formatDate({
      date: previousDay,
      format: ATTENDANCE_CONSTANTS.YYYY_MM_DD,
    });
  }
};

export const getExcusalTabEmptyStateLabel = key => {
  switch (key) {
    case ATTENDANCE_CONSTANTS.NEW_EXCUSALS:
      return "attendance:no_new_excusals_received";
    case ATTENDANCE_CONSTANTS.ONGOING:
      return "attendance:no_excusals_today";
    case ATTENDANCE_CONSTANTS.UPCOMING:
      return "attendance:no_upcoming_excusals";
    case ATTENDANCE_CONSTANTS.EARLIER:
      return "attendance:no_earlier_excusals";
  }
};

export const getStudentsTabAttendanceTableColumnListMemoize = _.memoize(
  params => getStudentsTabAttendanceTableColumnList(params),
  params => JSON.stringify(params)
);

const getStudentsTabAttendanceTableColumnList = ({
  attendanceOptions,
  isPeriodAttendanceView,
  selectedPeriods,
  isAttendanceOnceADay,
  t,
}) => {
  let tableColumns = [];

  if (isPeriodAttendanceView && !isAttendanceOnceADay) {
    tableColumns = _.map(selectedPeriods, period => {
      return {
        ...commonStudentsObjectKey,
        key: period.id,
        value: period.label,
      };
    });
  } else if (!isPeriodAttendanceView) {
    tableColumns = _.map(attendanceOptions, attendanceOption => {
      return {
        ...commonStudentsObjectKey,
        key: attendanceOption.id,
        value: attendanceOption.label,
        uiType: "Text",
      };
    });
    const overAllPresenceColumn = {
      ...commonStudentsObjectKey,
      key: "PRESENCE",
      entity: ATTENDANCE_CONSTANTS.PRESENT_PERCENTAGE,
      value: `${t("attendance:presence")} (in %)`,
      uiType: "Text",
      sortType: ATTENDANCE_CONSTANTS.TOGGLE,
    };
    tableColumns.push(overAllPresenceColumn);
  }

  const dropdownOptions = _.map(
    studentTabSortOptions,
    ({ key, label, direction, sortEntity }) => {
      return {
        key,
        label: t(label),
        direction,
        sortEntity,
      };
    }
  );

  const studentColumnObjectLocalized = {
    ...studentColumnObject,
    sortOptions: dropdownOptions,
  };

  return [studentColumnObjectLocalized, ...tableColumns];
};

export const getAttendanceDetailsStripOptionsMemoize = _.memoize(
  params => getAttendanceDetailsStripOptions(params),
  params => JSON.stringify(params)
);

const getAttendanceDetailsStripOptions = ({
  attendanceDetailType,
  t,
  periods,
  attendanceOverviewOnceADay,
  attendanceOptions,
  studentsAttendanceOverview,
  isAttendanceOnceADay,
}) => {
  switch (attendanceDetailType) {
    case ATTENDANCE_CONSTANTS.CLASS_EACH_PERIOD:
      return _.reduce(
        periods,
        (periodsData, period) => {
          if (period.type === "REGULAR") {
            const periodStripData = {
              id: period.id,
              label: period.label,
              totalCount: _.get(period, "selectedCourses.totalCount", 0),
              markedCount: _.get(
                period,
                "selectedCourses.statusWiseCourseAttendanceCount.markedCount",
                0
              ),
            };

            periodsData.push(periodStripData);
          }
          return periodsData;
        },
        []
      );

    case "CLASS_ONCE_A_DAY":
      return _.map(ATTENDANCE_DETAIL_OPTION_ONCE_A_DAY, option => {
        return {
          id: option.key,
          subLabel: t(option.label),
          subLabelColor: option.textColor,
          optionCount: _.get(
            attendanceOverviewOnceADay,
            `statusWiseCourseAttendanceCount.${
              attendanceOptionStatusFieldMapping[`${option.key}`]
            }`,
            0
          ),
          totalCount: _.get(
            attendanceOverviewOnceADay,
            "courses.totalCount",
            0
          ),
        };
      });
    case "STUDENTS_TAB_OVERVIEW":
      return _.map(ATTENDANCE_DETAIL_STUDENT_TAB_OVERVIEW, option => {
        return {
          id: option.key,
          subLabel: t(option.subLabel),
          subLabelColor: option.textColor,
          optionLabel: _.get(studentsAttendanceOverview, `${option.key}`, 0),
          optionSubLabel: option.key === "OVERALL_PRESENCE" ? "%" : "Students",
        };
      });
    case "STUDENTS_PERIOD_ATTENDANCE": {
      const markedBlock = {
        id: "MARKED",
        subLabel: t("attendance:students_marked"),
        subLabelColor: colors.black,
        optionCount: _.get(
          studentsAttendanceOverview,
          "totalStudentsMarked",
          0
        ),
        totalCount: _.get(studentsAttendanceOverview, "studentsCount", 0),
      };

      const anomalyBlock = {
        id: "ANOMALY",
        subLabel: t("attendance:with_an_anomaly"),
        subLabelColor: colors.black,
        optionCount: _.get(
          studentsAttendanceOverview,
          "anomalyStudentsCount",
          0
        ),
      };

      const attendanceSummary = _.get(
        studentsAttendanceOverview,
        "attendanceOptionsCount",
        []
      );
      const attendanceOptionsBlocks = _.map(
        attendanceOptions,
        ({ id, label, color }) => {
          const attendanceOptionSummaryDetail = _.find(
            attendanceSummary,
            ({ value }) => value?.id === id
          );

          return {
            id,
            subLabel: label,
            subLabelColor: color,
            optionCount: _.get(attendanceOptionSummaryDetail, "totalCount", 0),
          };
        }
      );

      return isAttendanceOnceADay
        ? [markedBlock, ...attendanceOptionsBlocks]
        : [markedBlock, anomalyBlock, ...attendanceOptionsBlocks];
    }
  }
};

export const getInitialSelectedDatesMemoize = _.memoize(
  params => getInitialSelectedDates(params),
  params => JSON.stringify(params)
);

const getInitialSelectedDates = ({
  dateViewType,
  academicYearStartDate,
  noOfRotationalDay,
  rotationDays,
  isCurrentAcademicYear,
  academicYearEndDate,
}) => {
  const initialDate = !isCurrentAcademicYear ? academicYearEndDate : undefined;

  const currentDate = formatDate({ date: initialDate, format: dateFormat });

  switch (dateViewType) {
    case ATTENDANCE_CONSTANTS.DAILY:
      return {
        startDate: null,
        endDate: currentDate,
      };
    case ATTENDANCE_CONSTANTS.WEEKLY:
      return {
        startDate: getFormattedStartDate({
          initialDate,
          format: dateFormat,
          startOfType: ATTENDANCE_CONSTANTS.WEEK,
        }),
        endDate: currentDate,
      };
    case ATTENDANCE_CONSTANTS.MONTHLY:
      return {
        startDate: getFormattedStartDate({
          initialDate,
          format: dateFormat,
          startOfType: ATTENDANCE_CONSTANTS.MONTH,
        }),
        endDate: currentDate,
      };
    case ATTENDANCE_CONSTANTS.YEARLY:
      return {
        startDate: formatDate({
          date: academicYearStartDate,
          format: dateFormat,
        }),
        endDate: currentDate,
      };
    case ATTENDANCE_GLOBAL_CONSTANTS.ROTATION_CYCLE: {
      const groupedRotationDays = groupRotationDaysMemoize({
        rotationDays,
        noOfDaysinRotationCycle: noOfRotationalDay,
      });

      const { index } = getRotationDayRangeMemoize({
        groupedRotationDays,
      });

      const selectedDateRangeObj = _.get(groupedRotationDays, `${index}`, {});

      return {
        startDate: selectedDateRangeObj?.start
          ? selectedDateRangeObj?.start
          : currentDate,
        endDate: selectedDateRangeObj?.end
          ? selectedDateRangeObj?.end
          : currentDate,
      };
    }
  }
};

export const generatePreviousDatesMemoize = _.memoize(
  params => generatePreviousDates(params),
  params => JSON.stringify(params)
);

const generatePreviousDates = ({
  dateViewType,
  startDate,
  endDate,
  noOfRotationalDay,
  rotationDays,
}) => {
  let newStartDate = startDate;
  let newEndDate = endDate;

  switch (dateViewType) {
    case "DAILY":
      newStartDate = null;
      newEndDate = moment(endDate).subtract(1, "day").format(dateFormat);
      break;
    case "WEEKLY": {
      const previousWeekDate = moment(startDate).subtract(1, "day");
      newStartDate = moment(previousWeekDate)
        .startOf("week")
        .format(dateFormat);
      newEndDate = moment(previousWeekDate).endOf("week").format(dateFormat);
      break;
    }
    case "MONTHLY": {
      const previousWeekDate = moment(startDate).subtract(1, "day");
      newStartDate = moment(previousWeekDate)
        .startOf("month")
        .format(dateFormat);
      newEndDate = moment(previousWeekDate).endOf("month").format(dateFormat);
      break;
    }
    case "ROTATION_CYCLE": {
      const groupedRotationDays = groupRotationDaysMemoize({
        rotationDays,
        noOfDaysinRotationCycle: noOfRotationalDay,
      });

      const findDateIndex = _.findIndex(groupedRotationDays, ({ start, end }) =>
        moment(startDate).isBetween(start, end, "date", "[]")
      );

      const selectedDateObj = _.get(
        groupedRotationDays,
        `${findDateIndex - 1}`,
        {}
      );

      newStartDate = selectedDateObj?.start
        ? selectedDateObj?.start
        : moment().format("YYYY-MM-DD");
      newEndDate = selectedDateObj?.end
        ? selectedDateObj?.end
        : moment().format("YYYY-MM-DD");
      break;
    }
  }

  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
};

export const generateNextDatesMemoize = _.memoize(
  params => generateNextDates(params),
  params => JSON.stringify(params)
);

const generateNextDates = ({
  dateViewType,
  startDate,
  endDate,
  noOfRotationalDay,
  rotationDays,
}) => {
  let newStartDate = startDate;
  let newEndDate = endDate;

  switch (dateViewType) {
    case "DAILY":
      newStartDate = null;
      newEndDate = moment(endDate).add(1, "day").format(dateFormat);
      break;
    case "WEEKLY": {
      const previousWeekDate = moment(endDate).add(1, "day");
      newStartDate = moment(previousWeekDate)
        .startOf("week")
        .format(dateFormat);

      const isAfter = moment(previousWeekDate)
        .endOf("week")
        .isAfter(moment(), "date");
      newEndDate = isAfter
        ? moment().format(dateFormat)
        : moment(previousWeekDate).endOf("week").format(dateFormat);
      break;
    }
    case "MONTHLY": {
      const previousWeekDate = moment(endDate).add(1, "day");
      newStartDate = moment(previousWeekDate)
        .startOf("month")
        .format(dateFormat);
      const isAfter = moment(previousWeekDate)
        .endOf("month")
        .isAfter(moment(), "date");
      newEndDate = isAfter
        ? moment().format(dateFormat)
        : moment(previousWeekDate).endOf("month").format(dateFormat);
      break;
    }
    case "ROTATION_CYCLE": {
      const groupedRotationDays = groupRotationDaysMemoize({
        rotationDays,
        noOfDaysinRotationCycle: noOfRotationalDay,
      });

      const findDateIndex = _.findIndex(groupedRotationDays, ({ start, end }) =>
        moment(startDate).isBetween(start, end, "date", "[]")
      );

      const selectedDateObj = _.get(
        groupedRotationDays,
        `${findDateIndex + 1}`,
        {}
      );

      newStartDate = selectedDateObj?.start
        ? selectedDateObj?.start
        : moment().format("YYYY-MM-DD");
      newEndDate = selectedDateObj?.end
        ? selectedDateObj?.end
        : moment().format("YYYY-MM-DD");
      break;
    }
  }

  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
};

export const getAttendanceGlobalConstantsMemoize = _.memoize(
  params => getAttendanceGlobalConstants(params),
  params => JSON.stringify(params)
);

const getAttendanceGlobalConstants = ({
  globalConstants,
  curriculumProgramId,
}) => {
  return _.reduce(
    globalConstants,
    (globalConstantsValue, globalConstant) => {
      const globalConstantType = _.get(globalConstant, "name", "");
      const globalConstantValue = _.get(globalConstant, "value");

      if (globalConstantType === "OPERATIONAL_DAYS") {
        globalConstantsValue["operationalWeekDays"] = globalConstantValue;
      } else if (globalConstantType === "ROUTINE_MODE") {
        globalConstantsValue["routineMode"] = globalConstantValue;
      } else if (
        globalConstantType === "ATTENDANCE_RECORDING_TYPE" &&
        _.get(globalConstant, "curriculumProgram.id", "") ===
          curriculumProgramId
      ) {
        globalConstantsValue["attendanceRecordType"] = globalConstantValue;
      } else if (globalConstantType === "NO_OF_DAYS_IN_ROTATION_CYCLE") {
        globalConstantsValue["noOfRotationalDay"] = globalConstantValue;
      }

      return globalConstantsValue;
    },
    {}
  );
};

export const getRotationDaysHashMapMemoize = _.memoize(
  params => getRotationDaysHashMap(params),
  params => JSON.stringify(params)
);

const getRotationDaysHashMap = ({ rotationDays }) => {
  return _.reduce(
    rotationDays,
    (rotationDaysHashMap, rotationDayData) => {
      const date = _.get(rotationDayData, "date");
      const rotationDayNumber = _.get(rotationDayData, "rotationDay");

      rotationDaysHashMap[`${date}`] = rotationDayNumber;

      return rotationDaysHashMap;
    },
    {}
  );
};

export const getClassLabel = ({ coursesEdges, selectedId }) => {
  const selectedCourseEdge = _.find(
    coursesEdges,
    ({ node }) => node?.id === selectedId
  );

  return _.get(selectedCourseEdge, "node.title", "");
};

export const getFilterHeaderLabelsMemoize = _.memoize(
  params => getFilterHeaderLabels(params),
  params => JSON.stringify(params)
);

const getFilterHeaderLabels = ({
  startDate,
  endDate,
  rotationDaysHashMap,
  isRotationalCycle,
  dateViewType,
  t,
}) => {
  let dateLabel = "";
  let subDateLabel = "";

  switch (dateViewType) {
    case ATTENDANCE_CONSTANTS.DAILY: {
      dateLabel = moment(endDate).format("D MMMM YYYY");
      if (isRotationalCycle) {
        const date = moment(endDate).format("YYYY-MM-DD");

        const rotationDayNumber = _.get(rotationDaysHashMap, `${date}`);
        subDateLabel = rotationDayNumber
          ? t("common:rotation_day_text", {
              day: _.get(rotationDaysHashMap, `${date}`),
            })
          : "";
      }
      break;
    }
    case ATTENDANCE_CONSTANTS.WEEKLY:
    case ATTENDANCE_GLOBAL_CONSTANTS.ROTATION_CYCLE:
    case ATTENDANCE_CONSTANTS.CUSTOM_RANGE:
    case ATTENDANCE_CONSTANTS.YEARLY: {
      dateLabel = `${moment(startDate).format(
        ATTENDANCE_CONSTANTS.D_MMM
      )} - ${moment(endDate).format(ATTENDANCE_CONSTANTS.D_MMM_YYYY)}`;
      if (isRotationalCycle && dateViewType !== ATTENDANCE_CONSTANTS.YEARLY) {
        const startDateRotationDay = _.get(rotationDaysHashMap, `${startDate}`);
        const endDateRotationDay = _.get(rotationDaysHashMap, `${endDate}`);

        if (startDateRotationDay && endDateRotationDay) {
          subDateLabel = `${t("common:rotation_day_text", {
            day: startDateRotationDay,
          })} - ${t("common:rotation_day_text", {
            day: endDateRotationDay,
          })}`;
        }
      }
      break;
    }
    case ATTENDANCE_CONSTANTS.MONTHLY: {
      dateLabel = moment(startDate).format(ATTENDANCE_CONSTANTS.MMMM_YYYY);
      break;
    }
  }
  return {
    dateLabel,
    subDateLabel,
  };
};

export const getRowValueMemoize = _.memoize(
  params => getRowValue(params),
  params => JSON.stringify(params)
);

const getRowValue = ({
  rowType,
  rowData,
  t,
  isAttendanceOnceADay,
  isRotationalCycle,
  currentDayNumber,
  currentDayRotationNumber,
}) => {
  switch (rowType) {
    case "class_name":
      return isAttendanceOnceADay
        ? { label: _.get(rowData, "title", ""), labelColor: colors.textDefault }
        : {
            label: _.get(rowData, "title", ""),
            courseId: _.get(rowData, "id", ""),
          };
    case "status": {
      const attendanceMarkedStatus = _.get(rowData, "attendanceMarkedStatus");
      const { statusLabel, textColor } =
        _.find(
          ATTENDANCE_DETAIL_OPTION_ONCE_A_DAY,
          option => option.key === attendanceMarkedStatus
        ) || {};

      return { label: statusLabel || "-", labelColor: textColor };
    }
    case "total_students":
      return {
        label: _.get(rowData, "allStudents.totalCount", 0),
      };
    case "actions": {
      const periods = _.get(rowData, "periods", []);

      const selectedPeriod = getSelectedPeriod({
        periods,
        isRotationalCycle,
        currentDayRotationNumber,
        currentDayNumber,
      });

      const teachers = _.get(selectedPeriod, "teachers", []);

      const teacherIds = _.map(teachers, ({ id }) => id);

      return {
        teacherIds,
        subject: _.get(selectedPeriod, "subject"),
        location: _.get(selectedPeriod, "location"),
        selectedClassId: _.get(rowData, "id", ""),
        selectedClassLabel: _.get(rowData, "title", ""),
        attendanceMarkedStatus: _.get(rowData, "attendanceMarkedStatus", ""),
      };
    }
    case "teacher_name": {
      const periods = _.get(rowData, "periods", []);
      const attendanceData = _.head(
        _.get(rowData, "attendance.attendance", [])
      );

      const selectedPeriod = getSelectedPeriod({
        periods,
        isRotationalCycle,
        currentDayRotationNumber,
        currentDayNumber,
      });

      const teachers = _.get(selectedPeriod, "teachers", []);

      const teachersCount = _.size(teachers);

      const firstTeacher = _.head(teachers);
      const teacherFirstName = isAttendanceOnceADay
        ? _.get(attendanceData, "createdBy.firstName", "")
        : _.get(firstTeacher, "firstName", "");
      const teacherLastName = isAttendanceOnceADay
        ? _.get(attendanceData, "createdBy.lastName", "")
        : _.get(firstTeacher, "lastName", "");

      const teacherLabelOnceADay = teacherFirstName
        ? `${teacherFirstName} ${teacherLastName}`
        : "-";
      const teacherLabelEachPeriod =
        teachersCount > 0
          ? teachersCount === 1
            ? `${teacherFirstName} ${teacherLastName}`
            : t("attendance:teacher_and_count_others", {
                teacher:
                  _.size(teacherFirstName) <= 8
                    ? teacherFirstName
                    : `${teacherFirstName.substring(0, 5)}...`,
                count: teachersCount - 1,
              })
          : "-";

      const teacherLabel = isAttendanceOnceADay
        ? teacherLabelOnceADay
        : teacherLabelEachPeriod;

      const avatarDataEachPeriod = _.map(
        teachers,
        ({ id, profileImage, firstName, lastName }) => {
          return {
            id,
            imageUrl: profileImage,
            value: `${firstName} ${lastName}`,
          };
        }
      );

      const avatarDataOnceADay = _.size(attendanceData)
        ? [
            {
              id: _.get(attendanceData, "createdBy.id", ""),
              imageUrl: _.get(attendanceData, "createdBy.profileImage", ""),
              value: `${teacherFirstName} ${teacherLastName}`,
            },
          ]
        : [];

      return {
        avatarData: isAttendanceOnceADay
          ? avatarDataOnceADay
          : avatarDataEachPeriod,
        teacherLabel,
      };
    }
    default: {
      const statusWiseCount = _.get(
        rowData,
        "attendanceSummary.statusWiseCount",
        []
      );

      const attendanceOptionObject = _.find(
        statusWiseCount,
        ({ value, period }) =>
          value.id == rowType &&
          (isAttendanceOnceADay ? _.isNull(period) : true)
      );

      return {
        label: _.size(statusWiseCount)
          ? _.get(attendanceOptionObject, "totalCount", 0)
          : "-",
      };
    }
  }
};

export const getSelectedTabBasedLabels = ({ selectedTab, t }) => {
  switch (selectedTab) {
    case ATTENDANCE_CONSTANTS.STUDENTS:
      return {
        firstDropdownOptionsLabel: "common:classes_with_count",
        firstDropdownNoOptionSelectedLabel: t("common:no_class_plural"),
        firstDropdownAllOptionSelectedText: t("common:all_classes_plural"),
      };
    case ATTENDANCE_CONSTANTS.CLASSES:
    case ATTENDANCE_CONSTANTS.EXCUSALS:
      return {
        firstDropdownOptionsLabel: "onBoarding:grades_with_count",
        firstDropdownNoOptionSelectedLabel: t("common:no_grades"),
        firstDropdownAllOptionSelectedText: t("common:all_grades"),
      };
  }
};

export const checkIsActiveTabStudents = ({ activeTab }) => {
  return activeTab === ATTENDANCE_CONSTANTS.STUDENTS;
};

export const checkIsActiveTabClasses = ({ activeTab }) => {
  return activeTab === ATTENDANCE_CONSTANTS.CLASSES;
};

export const checkIsActiveTabExcusals = ({ activeTab }) => {
  return activeTab === ATTENDANCE_CONSTANTS.EXCUSALS;
};

export const checkIsRotationCycle = ({ routineMode }) => {
  return routineMode === ATTENDANCE_GLOBAL_CONSTANTS.ROTATION_CYCLE;
};

export const getEnrolledClassesOptions = ({ enrolledPeriods }) => {
  return _.map(enrolledPeriods, ({ course }) => {
    return {
      key: _.get(course, "id"),
      label: _.get(course, "title"),
    };
  });
};

export const getTodaysEnrolledPeriods = ({
  selectedPeriods,
  isRotationalCycle,
  rotationDay,
  operationalDay,
}) => {
  return _.filter(
    selectedPeriods,
    ({ rotationDay: rotationDayNumber, day }) => {
      if (isRotationalCycle) {
        return rotationDayNumber === rotationDay;
      } else {
        return day === operationalDay;
      }
    }
  );
};

export const getSelectedOptionSendEmail = ({
  selectedAttendanceOption: optionId,
  attendanceOptions,
}) => {
  return _.find(attendanceOptions, ({ id, sendEmail }) => {
    return id === optionId && sendEmail;
  });
};

export const checkStudentIncluded = ({ studentFamilyIds, studentId }) => {
  return _.includes(studentFamilyIds, studentId);
};

export const checkIsPeriodEnrolled = ({
  enrolledPeriods,
  isRotationalCycle,
  rotationDay,
  operationalDay,
}) => {
  return _.filter(enrolledPeriods, ({ node }) => {
    const { rotationDay: rotationDayNumber, day, isSelected } = node || {};
    if (isRotationalCycle) {
      return rotationDay === rotationDayNumber && isSelected;
    }
    return day === operationalDay && isSelected;
  });
};

export const getUpdatedAttendanceDataMemoize = _.memoize(
  params => getUpdatedAttendanceData(params),
  params => JSON.stringify(params)
);

const getUpdatedAttendanceData = ({
  dateWiseAttendance,
  studentsAttendanceDetail,
  attendanceOptions,
}) => {
  let sendSMS = false;
  const updatedStudentsAttendance = _.reduce(
    studentsAttendanceDetail,
    (updatedStudentsAttendance, attendanceDetail) => {
      const studentDetail = _.find(
        dateWiseAttendance,
        ({ student }) => student?.id === attendanceDetail.studentId
      );

      const dateWiseValue = _.head(_.get(studentDetail, "dateWiseValue", []));

      const attendanceOption = _.get(dateWiseValue, "value.id", null);
      const remark = _.get(studentDetail, "remark", "");

      if (
        remark != attendanceDetail.remark ||
        attendanceDetail.attendanceOptionId !== attendanceOption
      ) {
        const selectedAttendanceOption = _.find(
          attendanceOptions,
          ({ id }) => id === attendanceDetail.attendanceOptionId
        );
        const sendSMSOption = _.get(selectedAttendanceOption, "sendSMS", false);

        if (sendSMSOption) {
          sendSMS = true;
        }

        updatedStudentsAttendance.push({
          studentId: attendanceDetail.studentId,
          remark: attendanceDetail.remark,
          optionId: attendanceDetail.attendanceOptionId,
        });
      }
      return updatedStudentsAttendance;
    },
    []
  );

  return {
    updatedStudentsAttendance,
    sendSMS,
  };
};

export const getGradesOptionsMemoize = _.memoize(
  params => getGradesOptions(params),
  params => JSON.stringify(params)
);

const getGradesOptions = ({ grades }) => {
  return _.map(grades, ({ id, name }) => {
    return {
      value: id,
      label: name,
    };
  });
};

export const getCoursesOptionsMemoize = _.memoize(
  params => getCoursesOptions(params),
  params => JSON.stringify(params)
);

const getCoursesOptions = ({ courses }) => {
  return _.map(courses, ({ node }) => {
    return {
      value: node.id,
      label: node.title,
    };
  });
};

export const getDateViewTypeOptionsMemoize = _.memoize(
  params => getDateViewTypeOptions(params),
  params => JSON.stringify(params)
);

const getDateViewTypeOptions = ({ t, isRotationalCycle }) => {
  return _.reduce(
    dateViewTypeOptionsMapping,
    (dateViewTypeOptions, option) => {
      if (!isRotationalCycle && option.key === "ROTATION_CYCLE") {
        return dateViewTypeOptions;
      }
      dateViewTypeOptions.push({ key: option.key, label: t(option.label) });
      return dateViewTypeOptions;
    },
    []
  );
};

export const checkPeriodAttendanceViewMemoize = _.memoize(
  params => checkPeriodAttendanceView(params),
  params => JSON.stringify(params)
);

const checkPeriodAttendanceView = ({ isAttendanceOnceADay, dateViewType }) => {
  return isAttendanceOnceADay
    ? _.includes(dateViewTypes, dateViewType)
    : dateViewType === "DAILY";
};

export const getStudentAttendanceOverviewDataMemoize = _.memoize(
  params => getStudentAttendanceOverviewData(params),
  params => JSON.stringify(params)
);

const getStudentAttendanceOverviewData = ({
  queryDataNode,
  isPeriodAttendanceView,
}) => {
  if (isPeriodAttendanceView) {
    return {
      studentsCount: _.get(queryDataNode, "students.totalCount", 0),
      totalStudentsMarked: _.get(
        queryDataNode,
        "attendanceSummaryCount.marked",
        0
      ),
      anomalyStudentsCount: _.get(
        queryDataNode,
        "anomalyStudents.totalCount",
        0
      ),
      attendanceOptionsCount: _.get(
        queryDataNode,
        "attendanceSummaryCount.attendanceCount",
        []
      ),
    };
  }

  const annualAttendanceSummaryData = _.head(
    _.get(queryDataNode, "annualAttendanceSummaryData", [])
  );
  const attendanceSummary = _.get(
    annualAttendanceSummaryData,
    "json_build_object",
    {}
  );

  return {
    studentsCount: _.get(queryDataNode, "students.totalCount", 0),
    OVERALL_PRESENCE: _.get(attendanceSummary, "overall_presence", 0),
    PERFECT_ATTENDANCE: _.get(attendanceSummary, "100%", 0),
    MORE_THAN_90: _.get(attendanceSummary, "above_90", 0),
    LESS_THAN_90: _.get(attendanceSummary, "below_90", 0),
  };
};

export const getStudentEnrolledPeriodsMemoize = _.memoize(
  params => getStudentEnrolledPeriods(params),
  params => JSON.stringify(params)
);

const getStudentEnrolledPeriods = ({
  enrolledPeriods,
  currentRotationDay,
  currentDayNumber,
  rowType,
}) => {
  return _.reduce(
    enrolledPeriods,
    (
      studentEnrolledPeriods,
      { isSelected, period, rotationDay, day, course }
    ) => {
      const isCurrentDay = _.isNull(day)
        ? currentRotationDay === rotationDay
        : currentDayNumber === day;

      const isPeriodEnrolled =
        isSelected && isCurrentDay && period?.id === rowType;
      if (isPeriodEnrolled) {
        studentEnrolledPeriods.push({
          key: course?.id,
          label: course?.title,
        });
      }
      return studentEnrolledPeriods;
    },
    []
  );
};

const getSelectedAttendanceOptionStudentRecord = ({
  studentAttendanceRecords,
  activeAttendanceOption,
}) => {
  const selectedAttendanceOptionRecord = _.find(
    studentAttendanceRecords,
    ({ value }) => value?.id === activeAttendanceOption
  );

  return selectedAttendanceOptionRecord
    ? selectedAttendanceOptionRecord
    : _.head(studentAttendanceRecords);
};

export const getStudentTabRowValueMemoize = _.memoize(
  params => getStudentTabRowValue(params),
  params => JSON.stringify(params)
);

const getStudentTabRowValue = ({
  rowData,
  rowType,
  isAttendanceOnceADay,
  isPeriodAttendanceView,
  rotationDaysHashMap,
  endDate,
  activeBlock,
}) => {
  const { firstName, lastName, profileImage, courses, familyMembers } = rowData;
  switch (rowType) {
    case "student_name": {
      return {
        studentName: `${firstName} ${lastName}`,
        profileImage,
        courses,
      };
    }
    case "PRESENCE": {
      let presenceCount = 0,
        totalCount = 0;

      const studentAttendanceSummary = _.get(
        rowData,
        "studentAttendanceSummary",
        []
      );

      _.forEach(studentAttendanceSummary, attendanceSummary => {
        const attendanceSummaryStatus = _.get(
          attendanceSummary,
          "value.status",
          "NA"
        );
        const attendanceSummaryCount = _.get(
          attendanceSummary,
          "totalCount",
          0
        );
        if (attendanceSummaryStatus === "PRESENT") {
          presenceCount = attendanceSummaryCount;
        }
        totalCount += attendanceSummaryCount;
      });

      return {
        recordCount:
          totalCount !== 0
            ? parseFloat((presenceCount / totalCount) * 100).toFixed(2)
            : 0,
        attendanceRecordId: rowType,
        recordCountColor: colors.textLink,
      };
    }
    default: {
      const studentDateAttendance = _.get(rowData, "studentDateAttendance", []);
      const studentAttendanceSummary = _.get(
        rowData,
        "studentAttendanceSummary",
        []
      );
      if (isPeriodAttendanceView && !isAttendanceOnceADay) {
        const selectedAttendanceOption = _.toNumber(activeBlock);
        const isAttendanceOptionTabActive = _.isNumber(
          selectedAttendanceOption
        );

        const studentAttendanceRecords = _.filter(
          studentDateAttendance,
          ({ period }) => period?.id === rowType
        );

        const studentAttendanceRecordsCount = _.size(studentAttendanceRecords);

        const studentAttendanceRecord =
          studentAttendanceRecordsCount > 1 && isAttendanceOptionTabActive
            ? getSelectedAttendanceOptionStudentRecord({
                studentAttendanceRecords,
                activeAttendanceOption: activeBlock,
              })
            : _.head(studentAttendanceRecords);

        const { firstName, lastName, profileImage } = rowData;

        const enrolledPeriods = _.get(rowData, "enrolledPeriods", []);

        const currentRotationDay = _.get(rotationDaysHashMap, `${endDate}`);
        const currentDay = moment(endDate).locale("en").format("ddd");

        const currentDayNumber = _.head(
          getDaysNameOrIndexArray({
            array: [currentDay],
            type: "index",
          })
        );

        const studentsEnrolledPeriod = getStudentEnrolledPeriodsMemoize({
          enrolledPeriods,
          currentDayNumber,
          currentRotationDay,
          rowType,
        });

        return {
          studentId: _.get(rowData, "id", ""),
          studentsEnrolledPeriod,
          firstName,
          lastName,
          profileImage,
          familyMembers,
          isAttendanceMarked: !_.isUndefined(studentAttendanceRecord),
          isRemarked: _.get(studentAttendanceRecord, "remark", ""),
          attendanceLabel: _.get(studentAttendanceRecord, "value.abbreviation"),
          attendanceOptionLabel: _.get(
            studentAttendanceRecord,
            "value.label",
            ""
          ),
          selectedClassId: _.get(studentAttendanceRecord, "course.id", ""),
          color: _.get(studentAttendanceRecord, "value.color"),
          attendanceRecordId: _.get(studentAttendanceRecord, "value.id"),
        };
      } else if (!isPeriodAttendanceView) {
        const studentAttendanceRecord = _.find(
          studentAttendanceSummary,
          ({ value }) => value?.id === rowType
        );

        return {
          recordCount: _.get(studentAttendanceRecord, "totalCount", 0),
          attendanceRecordId: _.get(studentAttendanceRecord, "value.id", ""),
          recordCountColor: colors.textDefault,
        };
      } else if (isPeriodAttendanceView && isAttendanceOnceADay) {
        const studentAttendanceRecord = _.find(
          studentDateAttendance,
          ({ date, period }) => moment(date).isSame(rowType) && _.isNull(period)
        );

        const { firstName, lastName, profileImage } = rowData;

        const attendanceClass = _.isUndefined(studentAttendanceRecord)
          ? _.get(_.head(courses), "id", "")
          : _.get(studentAttendanceRecord, "course.id");

        return {
          studentId: _.get(rowData, "id", ""),
          firstName,
          lastName,
          profileImage,
          familyMembers,
          isAttendanceMarked: !_.isUndefined(studentAttendanceRecord),
          isRemarked: _.get(studentAttendanceRecord, "remark", ""),
          attendanceLabel: _.get(studentAttendanceRecord, "value.abbreviation"),
          attendanceOptionLabel: _.get(
            studentAttendanceRecord,
            "value.label",
            ""
          ),
          color: _.get(studentAttendanceRecord, "value.color"),
          attendanceRecordId: _.get(studentAttendanceRecord, "value.id"),
          attendanceClass,
        };
      }
    }
  }
};

export const getSelectedClassesCountMemoize = _.memoize(
  params => getSelectedClassesCount(params),
  params => JSON.stringify(params)
);

const getSelectedClassesCount = ({
  sendReminderObj = {},
  selectedPeriodDetail,
}) => {
  const {
    activeDropdownOption,
    checkedCourseIds,
    deSelectedCourseIds,
    notTeacherAssignedCourses = 0,
  } = sendReminderObj;

  const checkedCoursesCount = _.size(checkedCourseIds);
  const deSelectedCoursesCount = _.size(deSelectedCourseIds);

  if (_.isNull(activeDropdownOption)) {
    return checkedCoursesCount;
  }

  switch (activeDropdownOption) {
    case "ALL":
      return (
        _.get(selectedPeriodDetail, "selectedCourses.totalCount", 0) +
        checkedCoursesCount -
        deSelectedCoursesCount -
        notTeacherAssignedCourses
      );
    case "PARTIALLY_MARKED":
      return (
        _.get(
          selectedPeriodDetail,
          "selectedCourses.statusWiseCourseAttendanceCount.partiallyMarkedCount",
          0
        ) +
        checkedCoursesCount -
        deSelectedCoursesCount -
        notTeacherAssignedCourses
      );
    case "NOT_MARKED":
      return (
        _.get(
          selectedPeriodDetail,
          "selectedCourses.statusWiseCourseAttendanceCount.notMarkedCount",
          0
        ) +
        checkedCoursesCount -
        deSelectedCoursesCount -
        notTeacherAssignedCourses
      );
  }
};

export const getStudentsCountMemoize = _.memoize(
  params => getStudentsCount(params),
  params => JSON.stringify(params)
);

const getStudentsCount = ({ studentsAttendanceOverview, activeBlock }) => {
  switch (activeBlock) {
    case "ANOMALY":
      return _.get(studentsAttendanceOverview, "anomalyStudentsCount", 0);
    case "PERFECT_ATTENDANCE":
    case "MORE_THAN_90":
    case "LESS_THAN_90":
      return _.get(studentsAttendanceOverview, `${activeBlock}`, 0);
    default: {
      const attendanceOptionsCount = _.get(
        studentsAttendanceOverview,
        "attendanceOptionsCount",
        []
      );
      const attendanceOption = _.find(
        attendanceOptionsCount,
        ({ value }) => value?.id === activeBlock
      );
      return _.get(attendanceOption, "totalCount", 0);
    }
  }
};

export const getEmptyStateLabelMemoize = _.memoize(
  params => getEmptyStateLabel(params),
  params => JSON.stringify(params)
);

const getEmptyStateLabel = ({
  activeBlock,
  attendanceOptions,
  t,
  searchValue,
}) => {
  const commonSearchedNameEmptyStateLabel = t(
    "attendance:no_student_found_with_searched_name"
  );

  switch (activeBlock) {
    case ATTENDANCE_CONSTANTS.ANOMALY:
      return searchValue
        ? commonSearchedNameEmptyStateLabel
        : t("attendance:no_student_have_anomaly_yet");

    case ATTENDANCE_CONSTANTS.PERFECT_ATTENDANCE:
      return searchValue
        ? t("attendance:no_student_with_searched_name_perfect_attendance")
        : t("attendance:no_student_have_perfect_attendance");

    case ATTENDANCE_CONSTANTS.MORE_THAN_90:
      return searchValue
        ? t("attendance:no_student_with_searched_name_more_than_90_percent")
        : t("attendance:no_student_have_more_than_90_percent");

    case ATTENDANCE_CONSTANTS.LESS_THAN_90:
      return searchValue
        ? t("attendance:no_student_with_searched_name_less_than_90_percent")
        : t("attendance:no_student_have_less_than_90_percent");

    default: {
      if (_.toNumber(activeBlock)) {
        const selectedAttendanceOption = _.find(
          attendanceOptions,
          ({ id }) => id === activeBlock
        );

        return searchValue
          ? commonSearchedNameEmptyStateLabel
          : t("attendance:no_students_marked_with_category_yet", {
              category: _.get(selectedAttendanceOption, "label", ""),
              lowercase: true,
            });
      }
      return commonSearchedNameEmptyStateLabel;
    }
  }
};

export const checkWorkingDayMemoize = _.memoize(
  params => checkWorkingDay(params),
  params => JSON.stringify(params)
);

const checkWorkingDay = ({
  dateViewType,
  operationalWeekDays,
  date,
  holidayEvents,
}) => {
  const currentDay = moment(date).locale("en").format("ddd");
  const currentDayNumber = _.head(
    getDaysNameOrIndexArray({
      array: [currentDay],
      type: "index",
    })
  );

  const isOperationalDay = _.includes(operationalWeekDays, currentDayNumber);

  const currentDateHolidayEvent = _.find(holidayEvents, ({ start, end }) =>
    moment(date).isBetween(start, end, "day", "[]")
  );

  return {
    isWorkingDay:
      dateViewType !== "DAILY" ||
      (isOperationalDay && !currentDateHolidayEvent),
    isOperationalDay,
    currentDateHolidayEvent,
  };
};
