import React from "react";
import PropTypes from "prop-types";

const LinkIcon = props => {
  return (
    <svg
      viewBox="0 0 16 16"
      width={props.width}
      height={props.height}
      fill={props.fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5627,4.41199698 L9.6959,5.91852698 C9.7399,6.34056698 9.6625,6.77729698 9.4328,7.17128698 L5.6571,13.640807 C5.0841,14.623787 3.8167,14.955017 2.8364,14.383387 L2.4183,14.138977 C1.9455,13.862517 1.6076,13.416437 1.4661,12.880867 C1.3258,12.346637 1.4006,11.791037 1.6757,11.318247 L5.4514,4.84872698 C5.8347,4.19295698 6.5279,3.82567698 7.2371,3.82567698 C7.5897,3.82567698 7.9476,3.91649698 8.2721,4.10614698 L8.6888,4.35055698 L8.6902,4.35055698 C8.8157,4.42401698 8.9292,4.50949698 9.0334,4.60298698 L9.7012,3.44236698 C9.5917,3.35822698 9.4862,3.26874698 9.3633,3.19662698 L8.9466,2.95221698 C7.3252,2.00796698 5.2431,2.55688698 4.2988,4.17559698 L0.5231,10.645117 C-0.4211,12.263837 0.1278,14.348667 1.7452,15.292917 L2.1632,15.537327 C2.7001,15.849847 3.2878,15.998097 3.8688,15.998097 C5.0387,15.998097 6.1793,15.395757 6.811,14.313937 L10.5867,7.84441698 C11.2278,6.74524698 11.1663,5.43771698 10.5627,4.41199698 Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2539,0.704610348 L13.8372,0.461540348 C13.0559,0.00611034805 12.1397,-0.116769652 11.2609,0.112950348 C10.3807,0.344010348 9.6448,0.902270348 9.1894,1.68359035 L5.4138,8.15310035 C4.8007,9.20286035 4.8248,10.4449503 5.3537,11.4493003 L6.2966,9.81189035 C6.3046,9.47532035 6.386,9.13609035 6.5677,8.82623035 L10.3434,2.35671035 C10.6185,1.88259035 11.0659,1.54469035 11.6001,1.40445035 C11.7764,1.35771035 11.9554,1.33500035 12.133,1.33500035 C12.4923,1.33500035 12.8462,1.42983035 13.1641,1.61547035 L13.5808,1.85855035 C14.0549,2.13367035 14.3928,2.58109035 14.533,3.11532035 C14.6733,3.65089035 14.5985,4.20648035 14.3234,4.67928035 L10.549,11.1488003 C9.9747,12.1317803 8.7099,12.4643403 7.727,11.8900403 L7.3089,11.6469603 C7.1834,11.5735103 7.0685,11.4867003 6.963,11.3918703 L6.2952,12.5524803 C6.4061,12.6379603 6.5116,12.7287803 6.6358,12.8009003 L7.0538,13.0439703 C7.5907,13.3578303 8.1797,13.5060803 8.7607,13.5060803 C9.9307,13.5060803 11.0712,12.9024003 11.7016,11.8219203 L15.476,5.35241035 C16.4215,3.73503035 15.8726,1.64886035 14.2539,0.704610348 Z"
      />
    </svg>
  );
};

LinkIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

LinkIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default LinkIcon;
