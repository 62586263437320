import React from "react";

const BookmarkFillIconSvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 14 16">
      <g stroke="none" strokeWidth="1" fill={props.fill} fillRule="evenodd">
        <path
          d="M11.578947,0 L1.754386,0 C0.77193,0 0,0.88 0,2 L0,14 C0,14.72 0.350877,15.4 0.912281,15.76 C1.192982,15.92 1.473684,16 1.754386,16 C2.070175,16 2.421053,15.88 2.701754,15.68 L6.736842,12.76 L10.631579,15.64 C10.912281,15.88 11.22807,16 11.578947,16 C11.859649,16 12.140351,15.92 12.421053,15.76 C12.982456,15.4 13.333333,14.72 13.333333,14 L13.333333,2 C13.333333,0.88 12.561404,0 11.578947,0 Z"
          id="Path"
        ></path>
      </g>
    </svg>
  );
};

BookmarkFillIconSvg.defaultProps = {
  height: 14,
  width: 16,
};

export default BookmarkFillIconSvg;
