import gql from "graphql-tag";
import {
  snpSetFragment,
  snpStandardFragment,
  snpPracticeFragment,
  snpEvaluationCycleFragment,
  snpSelfStudyFragment,
  snpActionFragment,
} from "./SnPFragments";

export const getSnPCycleDetailsQuery = gql`
  query getSnPCycleDetails($id: ID!) {
    node(id: $id, type: SnP_EVALUATION_CYCLE) {
      id
      ... on SnPEvaluationCycle {
        ...snpEvaluationCycleItem
      }
    }
  }
  ${snpEvaluationCycleFragment.evaluationCycleDetails}
`;

export const getSnPSelfStudyTemplateQuery = gql`
  query getSnPSelfStudyTemplate($id: ID!) {
    node(id: $id, type: SnP_SELF_STUDY_TEMPLATE) {
      id
      ... on SnPSelfStudyTemplate {
        id
        template
      }
    }
  }
`;

export const getSnPSelfStudyDetailsQuery = gql`
  query getSnPSelfStudyDetails($id: ID!) {
    node(id: $id, type: SnP_SELF_STUDY) {
      id
      ... on SnPSelfStudy {
        ...snpSelfStudyItem
      }
    }
  }
  ${snpSelfStudyFragment.selfStudyDetails}
`;

export const getSnPSetPracticesQuery = gql`
  query getSnPSetPracticesQuery($id: ID!, $searchText: String) {
    node(id: $id, type: SnP_SET) {
      id
      ... on SnPSet {
        ...snpSetItem
      }
    }
  }
  ${snpSetFragment.snpSetPractices}
`;

export const getSnPSetActionsQuery = gql`
  query getSnPSetActions($id: ID!, $evaluationCycleIds: [ID!]) {
    node(id: $id, type: SnP_SET) {
      id
      ... on SnPSet {
        ...snpSetItem
      }
    }
  }
  ${snpSetFragment.snpSetActions}
`;

export const getSnPActionDetailsQuery = gql`
  query getSnPActionDetails($id: ID!) {
    node(id: $id, type: SnP_ACTION) {
      id
      ... on SnPAction {
        ...snpActionItem
      }
    }
  }
  ${snpActionFragment.actionFullDetails}
`;

export const getSnPActionTableDetailsQuery = gql`
  query getSnPActionDetails($id: ID!) {
    node(id: $id, type: SnP_ACTION) {
      id
      ... on SnPAction {
        ...snpActionItem
      }
    }
  }
  ${snpActionFragment.actionTableDetails}
`;

export const getSnPSetFrameworksQuery = gql`
  query getSnPSetFrameworksQuery($id: ID!) {
    node(id: $id, type: SnP_SET) {
      id
      ... on SnPSet {
        ...snpSetItem
      }
    }
  }
  ${snpSetFragment.snpSetFrameworks}
`;

export const getSnPStandardPracticesQuery = gql`
  query getSnPStandardPractices(
    $id: ID!
    $searchText: String
    $showOnlyPracticeWithAction: Boolean
    $evaluationCycleId: ID
  ) {
    node(id: $id, type: SnP_STANDARD) {
      id
      ... on SnPStandard {
        ...snpStandardItem
      }
    }
  }
  ${snpStandardFragment.standardDetails}
`;

export const getSnPPracticeDetailsQuery = gql`
  query getSnPPracticeDetails(
    $id: ID!
    $evaluationCycleIds: [ID!]
    $evaluationCycleId: ID
  ) {
    node(id: $id, type: SnP_PRACTICE) {
      id
      ... on SnPPractice {
        ...snpPracticeItem
      }
    }
  }
  ${snpPracticeFragment.practiceDetail}
`;

export const getSnPPracticeActionDetailsQuery = gql`
  query getSnPPracticeActionDetails($id: ID!, $evaluationCycleIds: [ID!]) {
    node(id: $id, type: SnP_PRACTICE) {
      id
      ... on SnPPractice {
        ...snpPracticeItem
      }
    }
  }
  ${snpPracticeFragment.practiceActionDetails}
`;

export const getSnPPracticeSelfStudyResponseDetailsQuery = gql`
  query getSnPPracticeSelfStudyResponseDetails(
    $id: ID!
    $evaluationCycleIds: [ID!]
  ) {
    node(id: $id, type: SnP_PRACTICE) {
      id
      ... on SnPPractice {
        ...snpPracticeItem
      }
    }
  }
  ${snpPracticeFragment.practiceSelfStudyDetails}
`;

export const getSnPEvaluationCyclesQuery = gql`
  query getSnPEvaluationCycles($id: ID!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        snpEvaluationCycles {
          ...snpEvaluationCycleItem
        }
      }
    }
  }
  ${snpEvaluationCycleFragment.evaluationCycle}
`;
