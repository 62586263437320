import client from "apolloClient";

import { getCourseFolders, getCourseFoldersDetailsQuery } from "./JournalQuery";

export const getCourseFoldersFromCache = courseId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseFolders,
      variables: {
        courseId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCourseFoldersDetailsFromCache = courseId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseFoldersDetailsQuery,
      variables: {
        courseId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
