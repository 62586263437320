import { setToastMsg } from "Login/modules/LoginModule";

export const dispatchError = ({ dispatch, e }) => {
  const msg = e.networkError
    ? "toastMsgs:no_internet_connection"
    : "toastMsgs:something_went_wrong";

  dispatch(
    setToastMsg({
      msg,
      type: "alert",
      position: "toast-bottom-left",
    })
  );
};
