import React from "react";
import classes from "./Loading.scss";
import loadingsvg from "./loading.svg";
import classNames from "classnames";
import PropTypes from "prop-types";

const Loading = props => {
  const { position, loadingStyle, containerStyle } = props;
  var containerClass = classNames(
    position == "center-bottom" ? classes.containerBottomCenter : null,
    position == "center" ? classes.containerCenter : null
  );

  return (
    <div className={containerClass} style={containerStyle}>
      <img
        className={classes.loadingImg}
        style={loadingStyle}
        src={loadingsvg}
      />
    </div>
  );
};

Loading.propTypes = {
  containerStyle: PropTypes.object,
  loadingStyle: PropTypes.object,
  position: PropTypes.string,
};

Loading.defaultProps = {
  position: "center",
  loadingStyle: {},
  containerStyle: {},
};

export default Loading;
