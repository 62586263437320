import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import EditAcademicYear from "./routes/EditAcademicYear";

export default store => ({
  path: "academic-year-terms",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const module = require("./modules/AcademicYearTermsModule");
            const reducer = module.default;
            const key = module.NAME;
            injectReducer(store, { key, reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
  childRoutes: [EditAcademicYear(store)],
});
