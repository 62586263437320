import gql from "graphql-tag";

export const calendarEventTagFragment = {
  calendarEventTag: gql`
    fragment calendarEventTagItem on CalendarEventTag {
      id
      label
      colorCode
      uid
    }
  `,
};

export const fragments = {
  calendar: gql`
    fragment CalendarItem on CalendarEvent {
      id
      start: startDate
      end: endDate
      title
      location
      category
      description
      grades
    }
  `,
  rotationDay: gql`
    fragment rotationDayItem on RotationDay {
      date
      rotationDay
    }
  `,
  calendarEvent: gql`
    fragment calendarEvent on CalendarEvent {
      id
      start: startDate
      end: endDate
      title
      location
      category
      description
      grades
      tag {
        ...calendarEventTagItem
      }
    }
    ${calendarEventTagFragment.calendarEventTag}
  `,
};
