import PropTypes from "prop-types";
import React from "react";

const LockIcon = props => {
  return (
    <svg
      viewBox="0 0 32 36"
      width={props.width}
      height={props.height}
      fill={props.fill && props.fill}
    >
      <g>
        <path d="M16,0 C22.5254567,0 27.8343928,5.20854437 27.9962001,11.6950252 L28,12 C30.209139,12 32,13.790861 32,16 L32,32 C32,34.209139 30.209139,36 28,36 L4,36 C1.790861,36 2.705415e-16,34.209139 0,32 L0,16 C-2.705415e-16,13.790861 1.790861,12 4,12 C4,5.372583 9.372583,0 16,0 Z M16,19 C14.3431458,19 13,20.3431458 13,22 L13,22 L13,26 C13,27.6568542 14.3431458,29 16,29 C17.6568542,29 19,27.6568542 19,26 L19,26 L19,22 C19,20.3431458 17.6568542,19 16,19 Z M16,4 C11.581722,4 8,7.581722 8,12 L24,12 C24,7.581722 20.418278,4 16,4 Z" />
      </g>
    </svg>
  );
};

LockIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

LockIcon.defaultProps = {
  height: 36,
  width: 32,
};

export default LockIcon;
