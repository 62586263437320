import React from "react";
import classes from "./GradeSelector.scss";

import { ArrowIcon, DeleteIcon } from "SvgComponents";
import { colors } from "Constants";
import { Checkbox, UIButton, I18nHOC } from "UIComponents";

const checkBoxStyle = {
  height: "20px",
  width: "20px",
};

const GradeList = React.memo(props => {
  const { selectedGrades, grades, type, onItemClick, t } = props;
  const updatedGrades =
    type == "ADD"
      ? [
          {
            id: "ALL",
            name: t("common:all_label", { label: t("common:grade_plural") }),
          },
          ...grades,
        ]
      : grades;
  const headerText =
    type == "ADD"
      ? t("common:add_with_label", { label: t("common:grade_plural") })
      : t("common:remove_with_label", { label: t("common:grade_plural") });
  return (
    <div
      className={classes.listInnerContainer}
      style={type == "ADD" ? { paddingBottom: "56px" } : {}}
    >
      <div className={classes.rowContainer}>
        <div className={classes.headerText}>{headerText}</div>
      </div>
      {_.map(updatedGrades, grade => {
        const isAll = grade.id == "ALL";
        const isSelected = isAll
          ? _.intersectionBy(selectedGrades, grades, "id").length ==
            grades.length
          : !!_.find(selectedGrades, item => item.id == grade.id);
        return (
          <div
            className={classes.rowContainer}
            key={grade.id}
            onClick={
              type == "ADD"
                ? () => onItemClick({ grade, isSelected, type })
                : null
            }
          >
            <div className={classes.gradeName}>{grade.name}</div>
            <div
              className={classes.iconContainer}
              onClick={
                type == "REMOVE"
                  ? e => {
                      e.stopPropagation();
                      onItemClick({ grade, isSelected, type });
                    }
                  : null
              }
            >
              {type == "REMOVE" ? (
                <DeleteIcon />
              ) : (
                <Checkbox
                  style={checkBoxStyle}
                  offFill={colors.strokeTwo}
                  fill={colors.teal20}
                  isChecked={isSelected}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
});

class GradeSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedGrades: [],
      currentDeletingIds: [],
      gradeList:
        props.type == "ADD"
          ? _.differenceBy(props.options, props.value, "id")
          : [],
    };
  }

  componentDidUpdate = prevProps => {
    const { value: prevValue } = prevProps;
    const { value, type, options } = this.props;

    if (!_.isEqual(prevValue, value)) {
      const gradeList =
        type == "ADD" ? _.differenceBy(options, value, "id") : [];
      this.setState({ gradeList });
    }
  };

  onItemClick = async ({ grade, type, isSelected }) => {
    const { value, onBlurInputField, nodeId, onClose } = this.props;

    if (type == "REMOVE") {
      onBlurInputField(
        nodeId,
        {
          grades: _.filter(value, item => item.id != grade.id),
        },
        type
      );
    } else if (type == "ADD") {
      const { selectedGrades, gradeList } = this.state;
      let updatedGrades = [];
      const isAll = grade.id == "ALL";
      if (isSelected) {
        updatedGrades = isAll
          ? []
          : _.filter(selectedGrades, item => item.id != grade.id);
      } else {
        updatedGrades = isAll ? gradeList : [...selectedGrades, grade];
      }
      this.setState({ selectedGrades: updatedGrades });
    }
  };

  onAddClick = e => {
    e.stopPropagation();
    const { selectedGrades } = this.state;
    const {
      value,
      options,
      onClose,
      onBlurInputField,
      nodeId,
      type,
    } = this.props;

    onBlurInputField(
      nodeId,
      {
        grades: _.intersectionBy(options, [...value, ...selectedGrades], "id"),
      },
      type
    );
    this.setState({ selectedGrades: [] });
    onClose(false);
  };

  onCancelClick = e => {
    e.stopPropagation();
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { selectedGrades, gradeList } = this.state;
    const { value, type, onClose, t } = this.props;
    const grades = type == "ADD" ? gradeList : value;
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.arrowContainer}>
            <ArrowIcon
              fill={colors.white}
              width={20}
              height={10}
              stroke={colors.strokeOne}
            />
          </div>
          <div className={classes.listContainer}>
            <GradeList
              selectedGrades={selectedGrades}
              grades={grades}
              type={type}
              t={t}
              onItemClick={this.onItemClick}
            />
            {type == "ADD" && (
              <div className={classes.footerContainer}>
                <div className={classes.buttonContainer}>
                  <UIButton
                    color={"grey"}
                    type={"filled"}
                    size={"sm"}
                    onClick={this.onCancelClick}
                  >
                    {t("common:cancel")}
                  </UIButton>
                  <UIButton
                    color={"blue"}
                    type={"filled"}
                    size={"sm"}
                    isDisabled={selectedGrades.length == 0}
                    onClick={this.onAddClick}
                  >
                    {t("common:add")}
                  </UIButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(GradeSelector);
