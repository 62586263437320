import PropTypes from "prop-types";
import React from "react";

const EditIcon = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 12 12"
      width={props.width}
      height={props.height}
    >
      <g fill={props.fill && props.fill}>
        <path
          d="M3.384048,10.265465H0.500002c-0.276142,0-0.499999-0.223858-0.499999-0.5l0-0.001744
      c0-0.276142,0.223857-0.5,0.499999-0.5h2.884046c0.276142,0,0.5,0.223858,0.5,0.5v0.001745
    C3.884048,10.041607,3.66019,10.265465,3.384048,10.265465z"
        />
        <path
          d="M11.531395,2.157146c-0.561524-0.563481-1.636638-0.563481-2.202075,0L5.331149,6.156295
      C5.102235,6.386188,4.951582,6.674776,4.897777,6.991734L4.626798,8.56185c-0.02837,0.164349,0.026413,0.330654,0.14674,0.447067
      c0.093914,0.090001,0.216197,0.139893,0.346306,0.139893l1.63566-0.339458c0.294458-0.062609,0.562503-0.20837,0.775765-0.420654
      l4.015779-4.015779c0.294458-0.294458,0.454893-0.686742,0.452937-1.103483
      C11.997049,2.851714,11.830745,2.456495,11.531395,2.157146z M10.837805,3.664653L6.823982,7.679454
      C6.746699,7.755759,6.651807,7.807607,6.546154,7.830107L5.740064,8.003261l0.145761-0.842287
      c0.019566-0.112501,0.072392-0.214241,0.154566-0.295436l3.997193-4.000128c0.105653-0.104674,0.245544-0.162392,0.39424-0.162392
    c0.151631,0,0.289567,0.059674,0.406959,0.177066C11.05498,3.096281,11.054002,3.448456,10.837805,3.664653z"
        />
      </g>
    </svg>
  );
};

EditIcon.defaultProps = {
  height: 22,
  width: 24,
};

EditIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
};

export default EditIcon;
