import React from "react";
import PropTypes from "prop-types";

const AicnCalendar = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 60 51">
      <g
        id="Subjects"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-35" transform="translate(-19.000000, -25.000000)">
          <rect
            id="Rectangle-Copy-5"
            fillRule="nonzero"
            x="0"
            y="0"
            width="100"
            height="100"
          />
          <g id="Group-7" transform="translate(20.000000, 25.000000)">
            <circle
              id="Oval"
              fill="#6464DC"
              fillRule="nonzero"
              cx="10.25"
              cy="23.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-5"
              fill="#6464DC"
              fillRule="nonzero"
              cx="10.25"
              cy="31.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-2"
              fill="#6464DC"
              fillRule="nonzero"
              cx="33.25"
              cy="23.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-6"
              fill="#6464DC"
              fillRule="nonzero"
              cx="33.25"
              cy="31.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy"
              fill="#6464DC"
              fillRule="nonzero"
              cx="18.25"
              cy="23.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-7"
              fill="#6464DC"
              fillRule="nonzero"
              cx="18.25"
              cy="31.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-3"
              fill="#6464DC"
              fillRule="nonzero"
              cx="40.25"
              cy="23.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-8"
              fill="#6464DC"
              fillRule="nonzero"
              cx="40.25"
              cy="31.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy"
              fill="#6464DC"
              fillRule="nonzero"
              cx="25.25"
              cy="23.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-9"
              fill="#6464DC"
              fillRule="nonzero"
              cx="25.25"
              cy="31.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-4"
              fill="#6464DC"
              fillRule="nonzero"
              cx="48.25"
              cy="23.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-10"
              fill="#6464DC"
              fillRule="nonzero"
              cx="48.25"
              cy="31.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-5"
              fill="#6464DC"
              fillRule="nonzero"
              cx="10.25"
              cy="39.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-6"
              fill="#6464DC"
              fillRule="nonzero"
              cx="33.25"
              cy="39.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-7"
              fill="#6464DC"
              fillRule="nonzero"
              cx="18.25"
              cy="39.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-8"
              fill="#6464DC"
              fillRule="nonzero"
              cx="40.25"
              cy="39.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-9"
              fill="#6464DC"
              fillRule="nonzero"
              cx="25.25"
              cy="39.25"
              r="1.25"
            />
            <circle
              id="Oval-Copy-10"
              fill="#6464DC"
              fillRule="nonzero"
              cx="48.25"
              cy="39.25"
              r="1.25"
            />
            <path
              d="M51.6,49.8 L6.5,49.8 C2.9,49.8 1.70530257e-13,46.9 1.70530257e-13,43.3 L1.70530257e-13,9.8 C1.70530257e-13,6.2 2.9,3.3 6.5,3.3 L51.6,3.3 C55.2,3.3 58.1,6.2 58.1,9.8 L58.1,43.3 C58,46.9 55.2,49.8 51.6,49.8 Z"
              id="Path"
              stroke="#6464DC"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M51.6,3.4 L6.5,3.4 C2.9,3.4 0,6.3 0,9.9 L0,12.8 L58,12.8 L58,9.9 C58,6.3 55.2,3.4 51.6,3.4 Z"
              id="Path"
              stroke="#6464DC"
              fillOpacity="0.1"
              fill="#6464DC"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.8,5.7 L16.8,0.5"
              id="Path"
              stroke="#6464DC"
              fill="#FFFFFF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M41.3,5.7 L41.3,0.5"
              id="Path"
              stroke="#6464DC"
              fill="#FFFFFF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

AicnCalendar.defaultProps = {
  height: 51,
  width: 60,
};

AicnCalendar.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default AicnCalendar;
