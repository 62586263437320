import React from "react";
import PropTypes from "prop-types";
import classes from "./MiddlePane.scss";
import classNames from "classnames";
import { compose } from "redux";
import { FieldComponent } from "UnitPlans/components/IBcomponents";
import {
  getMYPObjectiveRubricOptions,
  getPypElementLabel,
  checkEmptyElementValue,
  extractDeletedPypElements,
  pypElementMapping,
  ASSESSMENT_TOOLS,
  SIZE,
  EVALUATION_METHOD,
} from "modules/Services";
import CreateDropDown from "UnitPlans/components/LibraryCommonComponents/CreateDropDown";
import { PrintIcon, DeleteIcon, Alert } from "SvgComponents";
import {
  DialogueBox,
  FullScreenLoader,
  LinkWithTooltip,
  I18nHOC,
} from "UIComponents";
import { AssessmentToolCreate } from "UnitPlans/components/LibraryCommonComponents";
import LabelComponent from "UnitPlans/components/LabelComponent";
import { connect } from "react-redux";
import {
  enityEnumToLocalTypeMapping,
  ResourceDetailsContext,
  getPlannerFieldMutationParams,
} from "UnitPlans/modules/UnitPlanModule";
import { getFieldLockedObject } from "modules/PusherModule";
import { CoachMarks } from "AppComponents";
import {
  extractLabelsForTranslation,
  getPYPElementSetFromNodes,
  generateRandomId,
} from "Utils";
import AddBlockDialogue from "../AddBlockDialogue";
import {
  LE_ASSESSMENT_TYPE_MAPPING,
  ASSESSMENT_TYPE_PARENT_GROUP_MAPPING,
  colors,
} from "Constants";
import ACLStore from "lib/aclStore";
import WarningMsg from "ClassRoom/components/WarningMsg";
import { InformationOutlined } from "@toddle-design/web-icons";

const MODAL_FIELD_UIDS = ["resources"];
const EMPTY_ARRAY = [];
const styles = {
  labelComponentLabelStyle: { paddingRight: "0px" },
  warningMsgStyle: { textAlign: "start" },
  warningIconStyle: {
    marginTop: "-8px",
    marginRight: "8px",
  },
  containerStyle: {
    padding: "16px 0px",
  },
};

const DIALOGS_INFO = {
  MEASURE_ASSESSING: {
    title: ({ t }) => t("unitPlan:change_assessment_tool"),
    message: ({ t, oldLabel, newLabel }) =>
      t("unitPlan:changing_assessment_tool_msg_with_label", {
        oldLabel,
        newLabel,
      }),
    confirmButtonText: ({ t }) => t("common:yes"),
    cancelButtonText: ({ t }) => t("common:no"),
  },
  HL: {
    title: ({ t, tag }) =>
      t("unitPlan:remove_tag_warning_title", {
        tag: t(`common:${tag}`),
      }),
    message: ({ t, tag, assessmentTypeLabel }) =>
      t("unitPlan:remove_tag_warning_msg", {
        tag: t(`common:${tag}`),
        assessmentType: t(assessmentTypeLabel),
      }),
    confirmButtonText: ({ t, tag }) =>
      t("unitPlan:remove_tag", {
        tag: t(`common:${tag}`),
      }),
    cancelButtonText: ({ t }) => t("common:cancel"),
  },
  SL: {
    title: ({ t, tag }) =>
      t("unitPlan:remove_tag_warning_title", {
        tag: t(`common:${tag}`),
      }),
    message: ({ t, tag, assessmentTypeLabel }) =>
      t("unitPlan:remove_tag_warning_msg", {
        tag: t(`common:${tag}`),
        assessmentType: t(assessmentTypeLabel),
      }),
    confirmButtonText: ({ t, tag }) =>
      t("unitPlan:remove_tag", {
        tag: t(`common:${tag}`),
      }),
    cancelButtonText: ({ t }) => t("common:cancel"),
  },
};

class MiddlePane extends React.PureComponent {
  constructor(props) {
    super(props);
    props.customRef(this);
    this.Refs = {};

    this.container = null;
    this.updatedField = {};
    this.sectionToDelete = "";
    this.blockText = "Add ";
    this.state = {
      scrolledToBottom: false,
      currentDialogAction: "",
      addBlockDialogueBox: false,
      isLoading: false,
      showDeleteSectionConfirmation: false,
    };
  }

  blurFields = ({ field } = {}) => {
    const { uid, id } = field;
    const fieldRef = this.Refs[uid];

    if (fieldRef) {
      fieldRef.blur({ field });
    }
  };

  isValid = () => {
    if (this.Refs["title"]) {
      return this.Refs["title"].isValid();
    } else {
      return 0;
    }
  };

  setScrollToBottom = () => {
    // if (this.container) {
    //   this.container.scrollTop =
    //     this.container.scrollHeight - this.container.clientHeight;
    // }
  };

  getFlatAttachments = ({ attachmentGroups }) => {
    return _.map(attachmentGroups, attachmentGroup => {
      return {
        ..._.get(attachmentGroup, "attachments[0]", {}),
        id: _.get(attachmentGroup, "id", 0),
      };
    });
  };

  updateField = ({
    params,
    isMutation = false,
    isDebounce = true,
    hasAddRemoveValues = false,
    extraParams = {},

    isWarningReq = true,
  }) => {
    _.map(params, (obj, key) => {
      const fieldObj = _.find(this.props.resourceFields, { uid: key }) || {};
      const fieldTemplateObj = this.props.field_list[key];
      const { portalType } = this.props;
      const { id, value: oldValue } = fieldObj;
      const value = { id, value: obj };
      let shouldUpdate = true;
      if (key == "measureAssessing" && isWarningReq) {
        shouldUpdate = this.shouldClearAssessmentTool({ value: obj, key });
      }

      if (key === "subjectLevel" && isWarningReq) {
        shouldUpdate = this.shouldShowDialogueBox({
          key,
          oldValue,
          newValue: obj,
          fieldObj,
        });
      }
      if (key == "voiceInstruction" || key == "studentTemplate") {
        isMutation = false;
      }

      if (shouldUpdate) {
        this.props.updateFieldInCache({
          fieldUID: key,
          params: { ...value, uid: key },
          fieldObj,
          fieldTemplateObj,
          extraParams,
        });
        const mutationParams = getPlannerFieldMutationParams({
          id,
          oldValue,
          hasAddRemoveValues,
          newValue: obj,
        });
        if (isMutation) {
          this.props.updateField({
            key: id,
            params: { ...(mutationParams || {}), portalType },
            type: this.props.parentType,
            isDebounce,
          });
        }
      }
    });
  };

  shouldClearAssessmentTool = ({ value, key }) => {
    const { assessmentToolId, resourceFields } = this.props;
    const oldValue = _.get(
      _.find(resourceFields, { uid: "measureAssessing" }),
      "value",
      ""
    );

    if (value != oldValue && !!assessmentToolId) {
      this.updatedField = { key, newValue: value, oldValue };

      this.setState({ currentDialogAction: "MEASURE_ASSESSING" });
      return false;
    } else {
      return true;
    }
  };

  shouldShowDialogueBox = params => {
    const { assessmentMode } = this.props;

    const { key, oldValue, newValue, fieldObj } = params;

    const isValueRemoved = _.size(newValue) < _.size(oldValue);

    if (isValueRemoved && assessmentMode === "edit") {
      const genericTags = _.get(
        fieldObj,
        "resolvedMinimalTree.genericTags",
        []
      );

      const removedTag = _.filter(genericTags, ({ id }) => id != newValue);
      const removedTagLabel = _.get(_.first(removedTag), "label", "");

      this.updatedField = { key, newValue, oldValue };

      this.setState({
        currentDialogAction: removedTagLabel,
      });
      return false;
    } else {
      return true;
    }
  };

  onBlurInputField = ({ key: uid, id }) => {
    const { toggleFocusedField } = this.props;
    toggleFocusedField({ isFocused: false, focusedField: { uid, id } });
  };

  onFocusInputField = ({ key: uid, id }) => {
    const { toggleFocusedField } = this.props;
    toggleFocusedField({ isFocused: true, focusedField: { uid, id } });
  };

  getOptions = ({
    fieldKey,
    defaultOptions,
    fieldObj,
    value,
    curriculumType,
  }) => {
    const {
      t,
      resourceFields: assessmentFields,
      field_list,
      unitPlanFields,
      organizationSubjectLevels,
    } = this.props;
    switch (fieldKey) {
      case "measureAssessing":
        return this.getMeasureAssessingOptions({ fieldKey, defaultOptions, t });
      case "lois":
      case "goalList":
        return this.getNotPypChecklistOptions({ fieldKey });
      case "lp":
      case "concepts":
        return this.getPypElementOptions({ fieldKey });
      case "atls":
      case "benchmarks":
        return this.getBenchmarkOptions({ fieldKey });
      case "assessmentType":
        return this.getAssessmentTypeOptions({
          fieldKey,
          value,
          curriculumType,
        });
      case "mypObjectiveRubric":
        return getMYPObjectiveRubricOptions({
          unitPlanFields,
          field_list,
          assessmentFields,
        });

      case "subjects":
        return this.getResolveMinimalTreeOptions({
          fieldKey,
          fieldObj,
          nodeType: "subjects",
        });

      case "scoreSubject":
        return [
          { label: t("common:none"), value: "" },
          ...(this.getResolveMinimalTreeOptions({
            fieldKey: "subjects",
            fieldObj,
            nodeType: "subjects",
          }) || []),
        ];
      case "subjectLevel": {
        return _.map(organizationSubjectLevels, node => {
          return { label: node.label, value: node.id };
        });
      }
      default: {
        //Handled Planner Elements
        const type = _.get(fieldObj, "config.type");
        if (type == "PLANNER_ELEMENT") {
          return this.getPlannerElementOptions({ fieldKey, fieldObj });
        } else {
          return defaultOptions || [];
        }
      }
    }
  };

  getLabel = ({ fieldKey, value }) => {
    switch (fieldKey) {
      case "lois":
      case "goalList":
        return this.getNotPypChecklistLabel({ value, fieldKey });
      case "lp":
      case "concepts":
      case "atls":
      case "benchmarks":
        return getPypElementLabel({ value, type: pypElementMapping[fieldKey] });

      default:
        return "";
    }
  };

  getUnitPlanFieldValue = ({ fieldKey }) => {
    const { unitPlanFields } = this.props;
    return _.get(_.find(unitPlanFields, { uid: fieldKey }), "value", "");
  };

  getUnitPlanFieldResolvedValue = ({ fieldKey }) => {
    const { unitPlanFields } = this.props;
    return _.get(
      _.find(unitPlanFields, { uid: fieldKey }),
      "resolvedMinimalTree",
      ""
    );
  };

  getResouceFieldValue = ({ fieldKey }) => {
    const { resourceFields } = this.props;
    return _.get(_.find(resourceFields, { uid: fieldKey }), "value", "");
  };

  getResouceFieldResolveValue = ({ fieldKey }) => {
    const { resourceFields } = this.props;
    return _.get(
      _.find(resourceFields, { uid: fieldKey }),
      "resolvedMinimalTree",
      ""
    );
  };

  getMeasureAssessingOptions = ({ fieldKey, defaultOptions, t }) => {
    return this.getOptionsLocale({ options: defaultOptions, key: fieldKey, t });
  };

  getNotPypChecklistOptions = ({ fieldKey }) => {
    let unitPlanLois = this.getUnitPlanFieldValue({ fieldKey });
    unitPlanLois = _.filter(unitPlanLois, item => _.trim(item.value) != "");

    return _.map(unitPlanLois, (loi, index) => {
      return { label: loi.value, value: loi.id };
    });
  };

  getNotPypChecklistLabel = ({ value, fieldKey }) => {
    const unitPlanLois = this.getUnitPlanFieldValue({ fieldKey });
    return _.get(_.find(unitPlanLois, { id: value }), "value", "");
  };

  getPypElementOptions = ({ fieldKey }) => {
    const unitPlanPypValues = this.getUnitPlanFieldValue({ fieldKey });
    const fieldValues = this.getResouceFieldValue({ fieldKey });
    const values = _.uniq([
      ...(unitPlanPypValues || []),
      ...(fieldValues || []),
    ]);
    return _.map(values, (value, index) => {
      return {
        label: this.getLabel({ fieldKey, value }),
        value,
      };
    });
  };

  getPlannerElementOptions = ({ fieldKey, fieldObj }) => {
    const { plannerElements } = this.props;
    const plannerElementConfig = _.get(
      fieldObj,
      "config.plannerElementConfig",
      {}
    );

    const { type, filteredBy, groupedBy } = plannerElementConfig;

    const unitPlanFieldValue = this.getUnitPlanFieldValue({ fieldKey }) || [];

    const resourceFieldValue = this.getResouceFieldValue({ fieldKey }) || [];

    const resourceSubjects =
      this.getResouceFieldValue({ fieldKey: "subjects" }) || [];

    const unitPlanSubjects =
      this.getUnitPlanFieldValue({ fieldKey: "subjects" }) || [];

    const unitPlanSubjectResolvedValues = _.get(
      this.getResouceFieldResolveValue({
        fieldKey: "subjects",
      }),
      "subjects",
      []
    );

    const resourceSubjectGroups = _.reduce(
      resourceSubjects,
      (result, subjectId) => {
        const subjectGroup = _.get(
          _.find(unitPlanSubjectResolvedValues, { id: subjectId }),
          "subjectGroup",
          {}
        );

        if (!_.isEmpty(subjectGroup)) {
          result = _.unionBy(result, [subjectGroup], "id");
        }

        return result;
      },
      []
    );

    const unitPlanFieldValueNodes = _.get(
      this.getUnitPlanFieldResolvedValue({
        fieldKey,
      }),
      "nodes",
      []
    );

    const resourceFieldValueNodes = _.get(
      this.getResouceFieldResolveValue({ fieldKey }),
      "nodes",
      []
    );

    const nodes = _.uniqBy(
      [...unitPlanFieldValueNodes, ...resourceFieldValueNodes],
      "id"
    );

    const value = _.uniq([...unitPlanFieldValue, ...resourceFieldValue]);

    let parentNodes = [];
    switch (type) {
      case "MYP_ATL":
      case "UBD_ATL":
        return {
          parentNodes: _.filter(nodes, ({ parent }) => !parent),

          value,
          nodes,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: type,
          },
        };
      case "MYP_OBJECTIVES": {
        parentNodes = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = _.map(node.associatedParents, parent => {
              return { id: parent.id, label: parent.name };
            });

            result = _.unionBy(result, associatedParents, "id");
            return result;
          },
          []
        );

        if (!_.isEmpty(resourceSubjects)) {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(resourceSubjects, node.id)
          );
        }

        const groupedByType = "SUBJECT";

        return {
          parentNodes,
          value,
          nodes,
          plannerElementSetData: {
            type,
            groupedByType,
            parentType: "PLANNER_ELEMENT",
          },
        };
      }
      case "MYP_LEARNING_STANDARD":
      case "UBD_LEARNING_STANDARD":
        parentNodes = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = _.map(node.associatedParents, parent => {
              return { id: parent.id, label: parent.name };
            });

            result = _.unionBy(result, associatedParents, "id");
            return result;
          },
          []
        );
        if (!_.isEmpty(resourceSubjects)) {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(resourceSubjects, node.id)
          );
        } else {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(unitPlanSubjects, node.id)
          );
        }

        return {
          parentNodes,
          value,
          nodes,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: "SUBJECT",
          },
        };
      case "MYP_RELATED_CONCEPT":
        parentNodes = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = _.map(node.associatedParents, parent => {
              return { id: parent.id, label: parent.name };
            });

            result = _.unionBy(result, associatedParents, "id");
            return result;
          },
          []
        );

        if (!_.isEmpty(resourceSubjects)) {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(resourceSubjects, node.id)
          );
        } else {
          parentNodes = _.filter(parentNodes, node =>
            _.includes(unitPlanSubjects, node.id)
          );
        }

        return {
          parentNodes,
          value,
          nodes,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: "SUBJECT",
          },
        };
      case "DP_PEDAGOGICAL_APPROACH": {
        const plannerElementSet =
          _.find(plannerElements, {
            type: type,
          }) || {};

        const plannerElementNodes = _.get(plannerElementSet, "nodes", []);
        return _.map(plannerElementNodes, node => {
          return { ...node, value: node.id };
        });
      }
      default:
        return _.map(nodes, (node, index) => {
          return {
            label: node.label,
            value: node.id,
          };
        });
    }
  };

  getResolveMinimalTreeOptions = ({ fieldKey, fieldObj, nodeType }) => {
    const unitPlanFieldValueNodes = _.get(
      this.getUnitPlanFieldResolvedValue({
        fieldKey,
      }),
      nodeType,
      []
    );

    return _.map(unitPlanFieldValueNodes, node => {
      return { label: node.name || node.label, value: node.id };
    });
  };

  getBenchmarkOptions = ({ fieldKey }) => {
    const { subjects, grades } = this.props;
    const unitPlanBenchmarks = this.getUnitPlanFieldValue({ fieldKey });
    const unitPlanResolvedValue = this.getUnitPlanFieldResolvedValue({
      fieldKey,
    });
    const subjectIds = _.map(subjects, item => item.id);

    let benchmarkSet;
    switch (fieldKey) {
      case "atls": {
        benchmarkSet = getPYPElementSetFromNodes({
          nodes: _.get(unitPlanResolvedValue, "atls", []),
        });
        break;
      }
      default: {
        benchmarkSet = getPYPElementSetFromNodes({
          nodes: _.filter(
            _.get(unitPlanResolvedValue, "benchmarks", []),
            item => _.includes(subjectIds, item.subject)
          ),
        });
      }
    }

    return fieldKey == "atls"
      ? { benchmarkSet }
      : {
          unitPlanBenchmarks,
          benchmarkSet,
          subjects,
          grades,
        };
  };

  getAssessmentTypeOptions = ({ fieldKey, value, curriculumType }) => {
    const { unitPlanTemplate, t } = this.props;

    const assessmentTypes = _.get(unitPlanTemplate, "assessmentTypes", []);
    const isLocalisedTemplate = _.get(
      unitPlanTemplate,
      "isLocalisedTemplate",
      true
    );

    let assessmentTypeOptionsToShow = [...assessmentTypes];

    if (isLocalisedTemplate) {
      assessmentTypeOptionsToShow = _.map(assessmentTypeOptionsToShow, item => {
        item.label = t(
          _.get(LE_ASSESSMENT_TYPE_MAPPING[item.value], "localeLabel", "")
        );
      });
    }

    /**
     * In UBD Assessment type options will be shown based on parent stage
     * **/

    // if (curriculumType === "UBD") {
    //   assessmentTypeOptionsToShow = _.reduce(
    //     assessmentTypeOptionsToShow,
    //     (result, option) => {
    //       _.map(ASSESSMENT_TYPE_PARENT_GROUP_MAPPING, grp => {
    //         if (_.includes(grp, value) && _.includes(grp, option.value)) {
    //           result.push(option);
    //         }
    //       });

    //       return result;
    //     },
    //     []
    //   );
    // }

    return assessmentTypeOptionsToShow;
  };

  getLabelSubtext = ({ key }) => {
    const { assessmentToolId, field_list, t } = this.props;
    const { config: { subtext } = {} } = field_list[key] ? field_list[key] : {};
    // hack to show different student template subtext if school has permissions
    if (key === "studentTemplate") {
      if (ACLStore.can("TeacherPortal:UseStudentTemplate")) {
        return t("unitPlan:student_template_subtext");
      }
    }
    if (_.get(_.find(ASSESSMENT_TOOLS, { key }), "hasTemplate", false)) {
      const name = _.get(_.find(ASSESSMENT_TOOLS, { key }), "locale", "");
      const isCreating = !assessmentToolId;
      return isCreating
        ? `${t("unitPlan:create_new_template_msg_with_label", {
            label: t(name),
          })}`
        : subtext;
    } else return subtext;
  };

  getExtraRightButtonComponent = ({ key }) => {
    const { field_list, onClickPrint, t, unitPlanId } = this.props;

    return (
      <ResourceDetailsContext.Consumer>
        {resourceDetails => {
          const isComplete = checkEmptyElementValue({
            fieldKey: key,
            fieldObj: _.get(field_list, key, {}),
            data: resourceDetails,
            unitPlanFields: _.get(resourceDetails, "allFields", []),
            isUnitPlan: true,
          });
          const assessmentToolId = _.get(
            resourceDetails,
            "assessmentTool.id",
            ""
          );
          const resourceTitle = _.get(resourceDetails, "title.value", "");
          const resourceId = _.get(resourceDetails, "id", "");

          if (key == "resources" && isComplete) {
            return (
              <CreateDropDown
                unitPlanId={unitPlanId}
                clickAction={value =>
                  this.Refs[key] ? this.Refs[key].onDropdownAdd(value) : null
                }
              />
            );
          } else if (
            _.get(_.find(ASSESSMENT_TOOLS, { key }), "canPrint", false)
          ) {
            const name = _.get(_.find(ASSESSMENT_TOOLS, { key }), "locale", "");
            const isCreating = !assessmentToolId;
            const canPrint = _.get(
              _.find(ASSESSMENT_TOOLS, { key }),
              "canPrint",
              false
            );
            const isNewlyCreated = _.includes(assessmentToolId, "NEW");

            return isCreating ? (
              <AssessmentToolCreate
                onAssessmentToolCreateClick={() => {
                  if (this.Refs[key]) {
                    this.Refs[key].createAssessmentTool();
                  }
                }}
                name={t(name)}
                hideChooseText={true}
              />
            ) : (
              <React.Fragment>
                {canPrint && (
                  <LinkWithTooltip
                    tooltip={`${t("common:print_with_label", {
                      label: t(
                        _.get(_.find(ASSESSMENT_TOOLS, { key }), "locale")
                      ),
                    })}`}
                    href="#"
                    placement="top"
                  >
                    <div
                      className={classes.print}
                      style={{
                        cursor: isNewlyCreated ? "not-allowed" : "pointer",
                      }}
                      onClick={() =>
                        !isNewlyCreated &&
                        onClickPrint({
                          type: _.get(
                            _.find(ASSESSMENT_TOOLS, { key }),
                            "printType"
                          ),
                          id:
                            key === "mypObjectiveRubric"
                              ? resourceId
                              : assessmentToolId,
                          title: resourceTitle,
                        })
                      }
                    >
                      <PrintIcon />
                    </div>
                  </LinkWithTooltip>
                )}
                <div
                  className={classes.chooseTemplateText}
                  onClick={() => {
                    this.Refs[key] ? this.Refs[key].toggleToolList() : null;
                  }}
                >
                  {t("unitPlan:choose_another_template")}
                </div>
              </React.Fragment>
            );
          } else {
            return null;
          }
        }}
      </ResourceDetailsContext.Consumer>
    );
  };

  saveTool = ({ tool, title }) => {
    const key = _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", "");

    if (this.Refs[key]) {
      this.Refs[key].saveAssessmentTool(title);
    }
  };

  getFieldLockedObject = ({ key }) => {
    const { lockedFields } = this.props;
    let uids = [];
    switch (key) {
      case "rubric":
      case "exemplar":
      case "anecdotal":
      case "checklist":
      case "measureAssessing":
      case "singlePointRubric":
        uids = [
          "rubric",
          "exemplar",
          "anecdotal",
          "checklist",
          "measureAssessing",
          "singlePointRubric",
        ];
        break;
      default:
        uids = [key];
    }

    return getFieldLockedObject({ uids, lockedFields });
  };

  setFieldRef = (key, ref) => {
    this.Refs[key] = ref;
  };

  checkIsModalField = key => {
    return _.includes(MODAL_FIELD_UIDS, key);
  };

  renderCoachMarks = ({ id, key, index, resourceIndex, resourceType }) => {
    return (
      <React.Fragment>
        {index == resourceIndex && (
          <CoachMarks
            type={`RESOURCE`}
            params={{ target: `#COACHMARKS_RESOURCE_${id}`, resourceType }}
            continuous={false}
          />
        )}
      </React.Fragment>
    );
  };

  getOptionsLocale = ({ options, key, t }) => {
    const clonedOptions = _.cloneDeep(options);
    switch (key) {
      case "measureAssessing":
        _.map(clonedOptions, option => {
          if (option.value === "") {
            option.label = t(`common:none`);
          } else {
            option.label = t(
              _.get(
                _.find(ASSESSMENT_TOOLS, { type: option.value }),
                "locale",
                ""
              )
            );
          }
        });
        return clonedOptions;
      case "size":
        _.map(clonedOptions, option => {
          option.label = t(
            _.get(_.find(SIZE, { type: option.value }), "locale", "")
          );
        });
        return clonedOptions;
      case "evaluationType":
        _.map(clonedOptions, option => {
          option.label = t(
            _.get(
              _.find(EVALUATION_METHOD, { type: option.value }),
              "locale",
              ""
            )
          );
        });
        return clonedOptions;
      default:
        return clonedOptions;
    }
  };

  getValue = ({ fieldData, key, fieldObj }) => {
    switch (key) {
      case "voiceInstruction":
        return _.get(fieldData, "resolvedMinimalTree.attachment", "");
      case "studentTemplate":
        return _.map(
          _.get(fieldData, "resolvedMinimalTree.attachmentGroups", []),
          group => {
            return { ...group, isPost: true };
          }
        );
      default:
        /**
         * this "if condition" will add default options which are present in template
         * in the backend with data structure like [{ id, value,isSelected },{...}]
         * which is for UIInputList.
         *
         * when it will run ?
         * -> It will run only when value for field is null. So, this is the dependency from backend data.
         * We have another dependency from template to run this "if condition". To make run this condition,
         * in field you have to have options with [{labe,value}] form, showDefaultTemplateOptions boolean, and field type
         * should be "InputNumberList".
         */
        if (
          !_.isEmpty(fieldData) &&
          fieldObj.type === "InputNumberList" &&
          _.get(fieldObj, "config.showDefaultTemplateOptions", false) &&
          !fieldData.value
        ) {
          const options = _.map(
            _.get(fieldObj, "config.options", []),
            option => ({
              id: generateRandomId(),
              isSelected: false,
              value: option.label,
            })
          );
          this.updateField({
            extraParams: {},
            hasAddRemoveValues: false,
            isDebounce: true,
            isWarningReq: true,
            isMutation: true,
            params: {
              [key]: options,
            },
          });
          return options;
        }
        return _.get(fieldData, "value", "");
    }
  };

  getSubFields = ({ fieldKey, fieldObj }) => {
    const subFields = _.get(fieldObj, "subFields", []);

    return _.reduce(
      subFields,
      (result, subField) => {
        const { key } = subField;
        const unitPlanFieldValue = this.getUnitPlanFieldValue({ fieldKey });
        const options = [];
        _.forEach(_.get(unitPlanFieldValue, `${key}`, []), option => {
          if (!_.isEmpty(option.value)) {
            options.push({ label: option.value, value: option.id });
          }
        });

        if (!_.isEmpty(options)) {
          result.push({
            ...subField,
            config: { ...subField.config, defaultOptions: options },
          });
        }

        return result;
      },
      []
    );
  };

  getDependentFieldValue = ({ dependentFieldId }) => {
    const { field_list, resourceFields } = this.props;
    const dependentFieldData = _.find(resourceFields, {
      uid: dependentFieldId,
    });

    if (_.isEmpty(dependentFieldData)) {
      return null;
    }

    return this.getValue({
      fieldData: dependentFieldData,
      key: dependentFieldId,
      fieldObj: field_list[dependentFieldId],
    });
  };

  renderFieldComponent = fields => {
    const {
      field_list,
      resourceFields,
      createAttachment,
      deleteAttachment,
      assessmentToolId,
      resourceId,
      parentType,
      t,
      readOnly,
      unitPlanId,
      isLocalisedTemplate,
      portalType,
      localisedTemplateId,
      curriculumType,
      assessmentType,
      orgId,
    } = this.props;

    return _.map(fields, (key, index) => {
      let {
        type,

        config: {
          label,
          subLabel,
          placeholder,
          options,
          emptyText,
          styles,
          hasAddRemoveValues = false,
          isRequired = false,
          prompts,
          defaultOptions = {},
          sourceType,
          fieldTypeConfig,
          dependentFieldId,
        } = {},
      } = field_list[key] ? field_list[key] : {};

      if (label === "Name of the Assessment" && key === "title") {
        label = !isLocalisedTemplate
          ? label
          : label &&
            t(
              extractLabelsForTranslation({
                keys: ["le", localisedTemplateId, "assessment", key, "label"],
              })
            );
      }

      options = this.getOptionsLocale({ options, key, t });

      const fieldData = _.find(resourceFields, { uid: key });
      const fieldId = _.get(fieldData, "id", "");
      const subText = this.getLabelSubtext({ key });

      const resourceIndex = _.findIndex(
        fields,
        keyName => keyName == "resources"
      );
      const value = this.getValue({
        fieldData,
        key,
        fieldObj: field_list[key],
      });
      const toolType = _.get(
        _.find(resourceFields, { uid: "measureAssessing" }),
        "value",
        null
      );

      let dependentFieldValue;
      if (dependentFieldId) {
        dependentFieldValue = this.getDependentFieldValue({
          dependentFieldId,
        });
      }

      const fieldOptions = this.getOptions({
        fieldKey: key,
        defaultOptions: options,
        fieldObj: field_list[key],
        value,
        curriculumType,
      });

      return (
        <div
          style={_.get(styles, "style", {})}
          className={classes.item}
          key={key}
        >
          {this.renderCoachMarks({
            id: fieldId,
            key,
            index,
            resourceIndex,
            resourceType: parentType,
          })}
          <WrapperFieldLabelComponent
            portalType={portalType}
            readOnly={readOnly}
            assessmentType={assessmentType}
            createAttachment={createAttachment}
            deleteAttachment={deleteAttachment}
            fieldLockedObject={this.getFieldLockedObject({
              key,
            })}
            subFields={this.getSubFields({
              fieldKey: key,
              fieldObj: field_list[key],
            })}
            unitPlanId={unitPlanId}
            isModalField={this.checkIsModalField(key)}
            customRef={this.setFieldRef}
            type={type}
            label={label}
            subText={subText}
            assessmentToolId={assessmentToolId}
            hasAddRemoveValues={hasAddRemoveValues}
            extraRightButtonComponent={this.getExtraRightButtonComponent}
            parentId={resourceId}
            setScrollToBottom={this.setScrollToBottom}
            parentType={parentType}
            isRequired={isRequired}
            subLabel={subLabel}
            emptyText={emptyText}
            attachments={_.get(fieldData, "attachments", EMPTY_ARRAY)}
            value={JSON.stringify(value)}
            resolvedValue={JSON.stringify(
              _.get(fieldData, "resolvedMinimalTree", "")
            )}
            fieldId={fieldId}
            fieldKey={key}
            defaultOptions={JSON.stringify(fieldOptions)}
            // defaultOptions={options}
            placeholder={placeholder}
            updateInputField={this.updateField}
            onBlurInputField={this.onBlurInputField}
            onFocusInputField={this.onFocusInputField}
            styles={styles}
            prompts={prompts}
            toolType={toolType}
            sourceType={sourceType}
            fieldTypeConfig={fieldTypeConfig}
            dependentFieldValue={dependentFieldValue}
            orgId={orgId}
            curriculumType={curriculumType}
          />
        </div>
      );
    });
  };

  toggleDeleteDialogueBoxDisplay = () => {
    this.setState({ currentDialogAction: "" });
  };

  toggleDeleteSectionConfirmation = () => {
    this.setState({
      showDeleteSectionConfirmation: !this.state.showDeleteSectionConfirmation,
    });
  };

  toddleAddBlockDialogue = () => {
    this.setState({ addBlockDialogueBox: !this.state.addBlockDialogueBox });
  };

  onConfirmUpdate = async () => {
    const { currentDialogAction } = this.state;

    const { portalType, field_list } = this.props;
    const { key, oldValue, newValue } = this.updatedField;
    const fieldObj = _.find(this.props.resourceFields, { uid: key }) || {};
    const id = _.get(fieldObj, "id", "");
    const value = { id, value: newValue, portalType };
    this.setState({ isLoading: true });
    if (_.isEqual(currentDialogAction, "MEASURE_ASSESSING")) {
      await this.props.clearAssessmentTool();
    }
    this.setState({ isLoading: false });
    const fieldTemplateObj = field_list[key];
    this.props.updateFieldInCache({ params: value, fieldObj });

    this.props.updateField({
      key: id,
      params: value,
      fieldTemplateObj,
      type: this.props.parentType,
    });
  };

  resetEnteredData = () => {
    let emptyValue = "";

    if (this.sectionToDelete == "assessmentTool") {
      this.props.clearAssessmentTool();
    } else if (this.sectionToDelete == "pypElements") {
      emptyValue = [];
    }

    const { step_list } = this.props;

    const fields = _.reduce(
      _.get(step_list[this.sectionToDelete], "fields", []),
      (result, value) => {
        if (value) {
          result[value] = emptyValue;
          return result;
        }
      },
      {}
    );

    this.updateField({
      params: {
        ...fields,
      },
      isMutation: true,
      isDebounce: false,
      hasAddRemoveValues: true,
      isWarningReq: false,
    });
  };

  confirmDeleteUpdate = () => {
    const { updateAssessmentSections } = this.props;
    updateAssessmentSections({ removedSections: [this.sectionToDelete] });
    this.resetEnteredData();

    this.toggleDeleteSectionConfirmation();
    this.sectionToDelete = "";
  };

  gettMeasureAssessingModalLabel = () => {
    const { key, oldValue, newValue } = this.updatedField;
    if (_.isEmpty(key)) return {};
    const { field_list, t } = this.props;
    const { config: { options } = {} } = field_list[key];

    const oldLabel = _.get(_.find(options, { value: oldValue }), "label", "");
    const newLabel = _.get(_.find(options, { value: newValue }), "label", "");
    return {
      oldLabel,
      newLabel,
    };
    // return `Changing the assessment tool from ${oldLabel} to ${newLabel} will discard the ${oldLabel} you have created for this assessment. Are you sure you want to proceed?`;
  };

  confirDeleteSectionBody = () => {
    const { step_list, t } = this.props;

    const label = _.get(step_list[this.sectionToDelete], "label", "");

    return t("unitPlan:remove_block_comformation_label", { label });
  };

  deleteSection = key => {
    this.sectionToDelete = key;
    this.toggleDeleteSectionConfirmation();
  };

  getBlockText = () => {
    const { configurableSteps, step_list, t } = this.props;
    const stepArray = [];

    _.forEach(configurableSteps, key => {
      const { label } = step_list[key] || {};

      stepArray.push(label);
    });

    return t("common:add_array_with_and", {
      array: stepArray,
    });
  };

  render() {
    const {
      step_list,
      steps,
      onScroll,
      setStepsRef,
      t,
      isLocalisedTemplate,
      enabledSections,
      notIncludedSteps,
      updateAssessmentSections,
      warningMsg,
      assessmentType,
    } = this.props;
    const { isLoading } = this.state;
    const {
      currentDialogAction,
      showDeleteSectionConfirmation,
      addBlockDialogueBox,
    } = this.state;

    const assessmentTypeLabel =
      LE_ASSESSMENT_TYPE_MAPPING[assessmentType]?.localeLabel;

    const {
      oldLabel = "",
      newLabel = "",
    } = this.gettMeasureAssessingModalLabel();

    return (
      <React.Fragment>
        <div
          className={classes.container}
          ref={ref => (this.container = ref)}
          onScroll={e => onScroll(e)}
        >
          {warningMsg ? (
            <div className={classes.warningMsg}>
              <WarningMsg
                svg={<Alert />}
                msg={warningMsg}
                containerStyle={styles.containerStyle}
                msgContainerStyle={styles.warningMsgStyle}
                svgContainerStyle={styles.warningIconStyle}
              />
            </div>
          ) : null}
          <div className={classes.innerContainer}>
            {_.map(steps, key => {
              const { fields, isMaxium, subtext, isConfigurable } =
                step_list[key] || {};

              const label = _.get(step_list[key], "label", "");

              const stepContainerClass = classNames(
                { [classes.stepContainer]: true },
                { [classes.maxiumContainer]: isMaxium }
              );
              return (
                <div
                  className={stepContainerClass}
                  key={key}
                  ref={ref => setStepsRef({ ref, key })}
                >
                  <div className={classes.stepLabel}>
                    <div className={classes.headerTxtContainer}>
                      <div className={classes.textContainer}>
                        {label}
                        {_.isEqual(key, "pypElements") && (
                          <div className={classes.iconContainer}>
                            <LinkWithTooltip
                              tooltip={t(
                                "unitPlanTemplate:le_pypElements_iconText"
                              )}
                              placement={"bottom"}
                            >
                              <div className={classes.informationIcon}>
                                <InformationOutlined
                                  size={"xx-small"}
                                  variant={"subtle"}
                                />
                              </div>
                            </LinkWithTooltip>
                          </div>
                        )}
                      </div>

                      {isConfigurable && (
                        <div
                          className={classes.deleteBtn}
                          onClick={() => this.deleteSection(key)}
                        >
                          <DeleteIcon
                            fill={colors.gray48}
                            height={16}
                            width={16}
                          />
                        </div>
                      )}
                    </div>

                    {subtext && (
                      <div className={classes.stepSubText}>{subtext}</div>
                    )}
                  </div>

                  <div className={classes.formContainer}>
                    {this.renderFieldComponent(fields)}
                  </div>
                </div>
              );
            })}
            {_.get(notIncludedSteps, "length", 0) != 0 && (
              <div className={classes.stepContainer}>
                <div className={classes.stepLabel}>
                  {t("common:more_label", {
                    label: t("common:option_plural"),
                  })}
                </div>
                <div className={classes.stepSubText}>{this.getBlockText()}</div>
                <div
                  className={classes.addBlockBtn}
                  onClick={this.toddleAddBlockDialogue}
                >
                  {t("common:add_with_label", {
                    label: t("common:block_singular_plural"),
                  })}
                </div>
              </div>
            )}
          </div>
          {addBlockDialogueBox && (
            <AddBlockDialogue
              step_list={step_list}
              isLocalisedTemplate={isLocalisedTemplate}
              notIncludedSteps={notIncludedSteps}
              updateAssessmentSections={updateAssessmentSections}
              enabledSections={enabledSections}
              onClose={this.toddleAddBlockDialogue}
            />
          )}
          {showDeleteSectionConfirmation && (
            <DialogueBox
              modalTitle={"Remove block"}
              showModal={true}
              onClickButton2={this.confirmDeleteUpdate}
              modalBody={this.confirDeleteSectionBody()}
              toggleDialogueBoxDisplay={this.toggleDeleteSectionConfirmation}
              button1={t("common:no")}
              button2={t("common:yes")}
            />
          )}
          {currentDialogAction && (
            <DialogueBox
              modalTitle={DIALOGS_INFO[currentDialogAction]?.title({
                t,
                tag: currentDialogAction,
                assessmentTypeLabel,
              })}
              showModal={true}
              onClickButton2={this.onConfirmUpdate}
              modalBody={DIALOGS_INFO[currentDialogAction]?.message({
                t,
                tag: currentDialogAction,
                assessmentTypeLabel,
                oldLabel,
                newLabel,
              })}
              toggleDialogueBoxDisplay={this.toggleDeleteDialogueBoxDisplay}
              button1={DIALOGS_INFO[currentDialogAction]?.cancelButtonText({
                t,
              })}
              button2={DIALOGS_INFO[currentDialogAction]?.confirmButtonText({
                t,
                tag: currentDialogAction,
              })}
            />
          )}
        </div>
        {isLoading && <FullScreenLoader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parentType, t } = ownProps;
  const organizationSubjectLevels = _.get(
    state,
    "platform.organizationSubjectLevels",
    []
  );
  return {
    lockedFields:
      state.pusher.lockedFields[enityEnumToLocalTypeMapping[parentType]],
    orgId: _.get(state, "login.userInfo.org_id"),
    organizationSubjectLevels,
  };
};

const mapActionCreators = {};

MiddlePane.propTypes = {
  isLocalisedTemplate: PropTypes.bool,
};

MiddlePane.defaultProps = {
  isLocalisedTemplate: true,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlanTemplate", "unitPlan", "common"] })
)(MiddlePane);

const WrapperFieldLabelComponent = React.memo(props => {
  const {
    label,
    subText,
    extraRightButtonComponent,
    fieldKey,
    fieldId,
    prompts,
    defaultOptions,
    value,
    resolvedValue,
    portalType,
    sourceType,
  } = props;
  const labelComponent = label && (
    <LabelComponent
      label={label}
      labelStyle={styles.labelComponentLabelStyle}
      labelCoachmarkId={
        fieldKey == "resources" ? `COACHMARKS_RESOURCE_${fieldId}` : undefined
      }
      promptsName={"Planning Prompts"}
      prompts={prompts}
      showHelper={false}
      showComment={false}
      subText={subText}
      extraRightButtonComponent={extraRightButtonComponent({
        key: fieldKey,
      })}
    />
  );

  return (
    <FieldComponent
      {...props}
      value={JSON.parse(value)}
      resolvedValue={JSON.parse(resolvedValue)}
      labelComponent={labelComponent}
      defaultOptions={JSON.parse(defaultOptions)}
      portalType={portalType}
      sourceType={sourceType}
    />
  );
});

WrapperFieldLabelComponent.displayName = "WrapperFieldLabelComponent";
