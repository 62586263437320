import React from "react";
import GooglePickerChooser from "./GooglePickerChooser";
import PropTypes from "prop-types";
import { compose } from "react-apollo";
import { GoogleDriveIcon } from "SvgComponents";
import { I18nHOC } from "UIComponents";
import {
  GOOGLE_DEVELOPER_KEY,
  GOOGLE_CLIENT_ID,
  PICKER_DIRECT_DOWNLOADABLE_TYPES,
  colors,
  GOOGLE_PICKER_MIME_TYPES,
} from "Constants";
import {
  generateRandomId,
  getFileType,
  getFileName,
  getImageDimensions,
  getExportableFileUrl,
  getGoogleFileDetails,
} from "Utils";
import {
  uploadFile,
  setProgress,
  downloadFromUrl,
  removeProgressElement,
} from "modules/Services";
import { connect } from "react-redux";
import DriveShareModal from "../DriveShareModal";

const START_PROGRESS = 50;

class GooglePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { accessToken: "", files: [], showShareModal: false };
  }

  updateAccessToken = ({ accessToken }) => {
    this.setState({ accessToken });
  };

  updateFileCount(count, isError = false) {
    const { updateFilesCount } = this.props;

    if (updateFilesCount) {
      updateFilesCount(count, isError);
    }
  }

  getSendObject = async ({ file, item, isLink }) => {
    const mimeType = file.mimeType ? file.mimeType : file.type;
    const url = isLink ? file.url : item;
    const attachmentType =
      this.shouldExportAsLink(mimeType) || isLink
        ? "LINK"
        : getFileType(mimeType).text;
    const dimensions = await getImageDimensions({ url, type: attachmentType });
    const metadata = {
      size: _.get(file, "size", 0),
      ...dimensions,
      serviceType: "GOOGLE",
      fileType: _.get(file, "type", 0),
    };
    if (file.permission) {
      metadata.permission = file.permission;
    }
    return {
      type: attachmentType,
      url: url,
      name: getFileName(file.name),
      mimeType: mimeType,
      metadata,
    };
  };

  async onFileDownloadedFromDrive({ file, uploadId, startProgress }) {
    const {
      onContentLoaded,
      onFailedAllowedMimeType,
      shouldUpload,
      parentType,
      parentId,
      portalType,
      uploadFile,
      onContentUploaded,
      onSingleContentUploaded,
      removeProgressElement,
    } = this.props;

    if (onContentLoaded) {
      // Handle your own upload
      const url = URL.createObjectURL(file);
      const sendObj = await this.getSendObject({ file, item: url });

      onContentLoaded({ ...sendObj, url, file });
      removeProgressElement({ id: uploadId });
      this.updateFileCount(-1);
    } else {
      if (shouldUpload) {
        // Uploading asset to our server. only when file has allowed mimetype

        return new Promise(async resolve => {
          let sendObj = await this.getSendObject({ file });
          let uploadStartTime = Date.now();

          const { promise } = uploadFile({
            file,
            uploadId,
            startProgress,
            parentType,
            parentId,
            attachment: sendObj,
            portalType,
          });

          promise
            .then(async item => {
              sendObj = await this.getSendObject({ file, item });
              let uploadTime = Date.now() - uploadStartTime;
              const metadata = _.get(sendObj, "metadata", {});
              const updatedMetadata = { ...metadata, uploadTime };
              sendObj = { ...sendObj, metadata: updatedMetadata };
              if (onContentUploaded) {
                await onContentUploaded(sendObj);
              }
              if (onSingleContentUploaded) {
                await onSingleContentUploaded(sendObj, uploadId, {
                  parentId,
                  parentType,
                });
              }
              removeProgressElement({ id: uploadId });
              this.updateFileCount(-1);
              resolve(sendObj);
            })
            .catch(e => {
              //console.log(e);
              this.updateFileCount(-1, true);
            });
        });
      }
    }
  }

  shouldExportAsLink = mimeType => {
    const { exportAsPdf, enableShareModal } = this.props;

    if (enableShareModal) {
      return false;
    }

    if (_.includes(PICKER_DIRECT_DOWNLOADABLE_TYPES, mimeType)) {
      return false;
    }

    return !exportAsPdf;
  };

  async chooseFiles(data) {
    const { enableShareModal } = this.props;
    //console.log("enableShareModal", enableShareModal);

    // action is picking a resource
    if (data[window.google.picker.Response.ACTION] == "cancel") {
      this.onClose();
      return;
    }

    if (
      data[window.google.picker.Response.ACTION] ==
      window.google.picker.Action.PICKED
    ) {
      if (enableShareModal) {
        this.setState({ showShareModal: true, files: data.docs });
      } else {
        this.processChosenFiles({ files: data.docs });
      }
    }
  }

  getThumbUrl = ({ fileId, accessToken, thumbnailSize }) => {
    return `https://drive.google.com/thumbnail?id=${fileId}&access_token=${accessToken}&sz=${thumbnailSize}&blob=true`;
  };

  handleOnMultipleFileUpload = async ({ sendObjList, files }) => {
    const { onMultipleFilesUploaded } = this.props;

    if (_.size(sendObjList) == _.size(files) && onMultipleFilesUploaded) {
      const values = _.filter(await Promise.all(sendObjList), urlObj => urlObj);
      onMultipleFilesUploaded(values);
    }
  };

  processChosenFiles = ({ files }) => {
    const {
      pickerType,
      onContentLoaded,
      thumbnailSize,
      onDropContent,
      downloadFromUrl,
      parentType,
      parentId,
      enableShareModal,
      t,
    } = this.props;
    const { accessToken } = this.state;

    try {
      // Picker type is youtube
      if (pickerType == "YoutubeVideo") {
        files.map((file, key) => {
          if (onContentLoaded) {
            onContentLoaded(file.url);
          }
        });
      } else {
        //picker type is google drive
        const sendObjList = [];

        files.map(async (file, key) => {
          this.updateFileCount(1);

          const fileUploadId = generateRandomId();
          const fileDetails = await getGoogleFileDetails({
            fileId: file.id,
            accessToken,
          });
          const thumbUrl = _.get(fileDetails, "thumbnailLink");

          const isLink = enableShareModal
            ? _.isEqual(file.exportAs, "LINK")
            : this.shouldExportAsLink(file.mimeType);
          let sendObj = await this.getSendObject({
            file,
            item: thumbUrl,
            isLink,
          });

          if (onDropContent) {
            // Here we have either thumb-url or link url(actual file url) as file url
            onDropContent({ ...sendObj, id: fileUploadId });
          }

          if (!isLink) {
            // File is not of type link, i.e may be exportAsPdf is true or it is inside the allowed mimetype
            const fileUrl = enableShareModal
              ? file.url
              : await getExportableFileUrl({
                  file,
                  accessToken,
                });

            // Starting the download of file from google drive.
            downloadFromUrl({
              size: file.sizeBytes,
              downloadUrl: fileUrl,
              name: file.name,
              parentType: parentType,
              parentId: parentId,
              attachment: sendObj,
              headers: [
                { label: "Authorization", value: `Bearer ${accessToken}` },
              ],
              fileUploadId: fileUploadId,
              showErrorMessage: true,
              onContentLoadFailed: async ({ isCancelled }) => {
                if (!isCancelled && !enableShareModal) {
                  sendObj = await this.getSendObject({
                    file,
                    item: thumbUrl,
                    isLink: true,
                  });
                  this.onContentLoaded({ sendObj, fileUploadId });
                } else {
                  this.updateFileCount(-1, true);
                }
              },
              onLoaded: async fileBlob => {
                if (fileBlob) {
                  fileBlob.name = _.get(file, "name", "Untitled");
                  if (!fileBlob.type) {
                    fileBlob.mimeType = _.get(file, "mimeType");
                  }
                }
                const sendObj = this.onFileDownloadedFromDrive({
                  file: fileBlob,
                  uploadId: fileUploadId,
                  startProgress: START_PROGRESS,
                });
                sendObjList.push(sendObj);

                // Sends all files' sendObj together as an array
                this.handleOnMultipleFileUpload({ sendObjList, files });
              },
              startProgress: START_PROGRESS,
            });
          } else {
            this.onContentLoaded({ sendObj, fileUploadId });
            sendObjList.push(sendObj);
            this.handleOnMultipleFileUpload({ sendObjList, files });
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  onContentLoaded = ({ sendObj, fileUploadId }) => {
    const {
      parentId,
      parentType,
      onSingleContentUploaded,
      onContentUploaded,
      onContentLoaded,
    } = this.props;

    if (onContentLoaded) {
      onContentLoaded(sendObj);
    }
    // we have a link of file
    if (onContentUploaded) {
      onContentUploaded(sendObj);
    }
    if (onSingleContentUploaded) {
      onSingleContentUploaded(sendObj, fileUploadId, {
        parentId,
        parentType,
      });
    }
    this.updateFileCount(-1);
  };

  onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  toggleSharingFormatModal = () => {
    const { showShareModal } = this.state;

    if (showShareModal) {
      this.onClose();
    }

    this.setState({ showShareModal: !showShareModal });
  };

  getMimeTypesArray = ({ accept }) => {
    if (!(_.isNull(accept) || _.isUndefined(accept)) && _.isString(accept)) {
      return _.split(accept, ",");
    }
    return accept;
  };

  render() {
    const {
      enableShareModal,
      renderComponent,
      classStyle,
      multiselect,
      navHidden,
      authImmediate,
      pickerType,
      onClickEvent,
      enableShareDrive,
      sourceType,
      accept,
      setInnerGooglePickerRef,
      defaultExportType,
      attachmentExportOptions,
    } = this.props;
    const { files, showShareModal, accessToken } = this.state;
    const mimeTypes = this.getMimeTypesArray({ accept });

    return (
      <React.Fragment>
        <GooglePickerChooser
          clientId={GOOGLE_CLIENT_ID}
          developerKey={GOOGLE_DEVELOPER_KEY}
          onChange={data => {
            this.chooseFiles(data);
          }}
          mimeTypes={mimeTypes}
          multiselect={multiselect}
          navHidden={navHidden}
          authImmediate={authImmediate}
          pickerType={pickerType}
          viewId={"DOCS"}
          classStyle={classStyle}
          onClickEvent={onClickEvent}
          enableShareDrive={enableShareDrive}
          updateAccessToken={this.updateAccessToken}
          setInnerGooglePickerRef={setInnerGooglePickerRef}
        >
          {renderComponent ? (
            renderComponent
          ) : (
            <div className={classStyle}>
              <GoogleDriveIcon fill={colors.gray48} />
            </div>
          )}
        </GooglePickerChooser>
        {enableShareModal && showShareModal && (
          <DriveShareModal
            processChosenFiles={this.processChosenFiles}
            onClickClose={this.toggleSharingFormatModal}
            files={files}
            accessToken={accessToken}
            sourceType={sourceType}
            defaultExportType={defaultExportType}
            attachmentExportOptions={attachmentExportOptions}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  uploadFile,
  setProgress,
  downloadFromUrl,
  removeProgressElement,
};

GooglePicker.propTypes = {
  accept: PropTypes.array,
  enableShareModal: PropTypes.bool,
  onContentLoaded: PropTypes.func, //handle content upload on your own
  onContentUploaded: PropTypes.func,
  onContentUploadProgress: PropTypes.func,
  updateFilesCount: PropTypes.func,
  renderComponent: PropTypes.element,
  multiselect: PropTypes.bool,
  navHidden: PropTypes.bool,
  authImmediate: PropTypes.bool,
  onClickEvent: PropTypes.func,
  onSingleContentUploaded: PropTypes.func,
  onDropContent: PropTypes.func,
  pickerType: PropTypes.oneOf(["GoogleDrive", "YoutubeVideo"]),
  thumbnailSize: PropTypes.string,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  enableShareDrive: PropTypes.bool,
  exportAsPdf: PropTypes.bool,
  sourceType: PropTypes.oneOf(["RESOURCE", "STUDENT_TEMPLATE"]),
};

GooglePicker.defaultProps = {
  accept: [],
  shouldUpload: true,
  multiselect: true,
  navHidden: true,
  authImmediate: false,
  pickerType: "GoogleDrive",
  thumbnailSize: "w500-h2500",
  parentType: "",
  parentId: null,
  enableShareDrive: true,
  exportAsPdf: false,
  enableShareModal: true,
  sourceType: "RESOURCE",
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(null, mapActionCreators)
)(GooglePicker);
