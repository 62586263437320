import React, { useState } from "react";
import classes from "./UploadDPRoster.scss";
import { connect } from "react-redux";
import { UIButton, UIModal, withLoader, DropzoneComponent } from "UIComponents";
import ACLStore from "lib/aclStore";

const SHEETS = [
  {
    label: "Upload Batch",
    type: "DP_ROSTER_YEAR_GROUPS",
  },
  {
    label: "Upload Subjects",
    type: "DP_ROSTER_SUBJECTS",
  },
  {
    label: "Upload Teachers",
    type: "DP_ROSTER_STAFF",
    perm: "FeatureFlag:DPBulkUploadSchoolSetupRoster",
  },
  {
    label: "Upload Classes",
    type: "DP_ROSTER_COURSES",
    perm: "FeatureFlag:DPBulkUploadSchoolSetupRoster",
  },
  {
    label: "Upload Students",
    type: "DP_ROSTER_STUDENTS",
    perm: "FeatureFlag:DPBulkUploadSchoolSetupRoster",
  },
  {
    label: "Upload Admins",
    type: "DP_ROSTER_ADMIN",
    perm: "FeatureFlag:DPBulkUploadSchoolSetupRoster",
  },
];

const UploadDPRoster = props => {
  const [sheets, setSheets] = useState(SHEETS);
  const [loading, setLoading] = useState(false);

  const onClickSave = async () => {
    setLoading(true);
    await props.onClickOperation({
      sheets,
      orgDetail: props.orgDetail,
    });
    setLoading(false);
  };

  const onClickCancel = () => {
    props.closeModal();
  };

  const onContentUploaded = ({ data, type }) => {
    setSheets(prev =>
      prev.map(sheet =>
        sheet.type === type
          ? { ...sheet, url: data.url, name: data.name }
          : sheet
      )
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.modalBody}>
          <div className={classes.modalHeader}>{`Upload DP Roster Sheet`}</div>

          <div className={classes.mainBody}>
            {_.map(sheets, sheet => {
              return (
                (_.isEmpty(sheet.perm) || ACLStore.can(sheet.perm)) && (
                  <div className={classes.uploadCsv}>
                    <DropzoneComponent
                      key={sheet.type}
                      multiple={false}
                      renderComponent={
                        <>
                          {sheet.name && <span>{sheet.name}.csv</span>}
                          <UIButton color={"pink"} size={"lg"}>
                            {sheet.label}
                          </UIButton>
                        </>
                      }
                      accept={[".csv", "text/csv"]}
                      onSingleContentUploaded={data =>
                        onContentUploaded({ data, type: sheet.type })
                      }
                    />
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>

      <div className={classes.modalFooter}>
        <div className={classes.rightPaneFooter}>
          <UIButton
            className={classes.cancelButton}
            size="sm"
            color="grey"
            onClick={onClickCancel}
          >
            {`Cancel`}
          </UIButton>
          <div className={classes.lastButton}>
            <UIButton
              className={classes.saveButton}
              isDisabled={loading}
              size="sm"
              color="blue"
              onClick={onClickSave}
            >
              {`Save changes`}
            </UIButton>
          </div>
        </div>
      </div>
    </>
  );
};

const UploadDPRosterLoading = withLoader(UploadDPRoster);

const UploadDPRosterComp = props => {
  const { closeModal } = props;
  return (
    <UIModal
      isOpen={true}
      onRequestClose={closeModal}
      modalContent={classes.modalContent}
    >
      <UploadDPRosterLoading {...props} />
    </UIModal>
  );
};

const UploadDPRosterMemoComp = React.memo(UploadDPRosterComp);

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    id: ownProps.orgDetail.id,
  };
};

const mapActionCreators = {};

export default connect(
  mapStateToProps,
  mapActionCreators
)(UploadDPRosterMemoComp);
