import React from "react";
import { RadioButtonList, I18nHOC } from "UIComponents";
import _ from "lodash";
import { extractLabelsForTranslation } from "Utils";
class Anecdotal extends React.PureComponent {
  updateAssessmentTool = params => {
    const { writeAssessmentToolInLocal, updateAssessmentTool } = this.props;
    writeAssessmentToolInLocal({ params });
    updateAssessmentTool({ params, isDebounce: true });
  };

  render() {
    const props = this.props;
    _.map(props.options, option => {
      option.label = props.t(
        extractLabelsForTranslation({
          keys: ["anecdotal", option.value, "label"],
        })
      );
    });
    return (
      <RadioButtonList
        mode={props.mode}
        value={props.anecdotal}
        options={props.options ? props.options : []}
        name={"message"}
        listContainerStyle={_.get(props.styles, "listContainerStyle", {})}
        listItemStyle={_.get(props.styles, "listItemStyle", {})}
        updateInputField={this.updateAssessmentTool}
      />
    );
  }
}

export default I18nHOC({ resource: "unitPlan" })(Anecdotal);
