import React, { useEffect } from "react";
import classes from "./EvaluationCycles.scss";
import { styles } from "./EvaluationCyclesStyles";
import CycleCard from "AppComponents/SnPCyclesFeed/CycleCard";
import ArrowWithCount from "./ArrowWithCount";

const EvaluationCycles = props => {
  const {
    updateCurrentCycle,
    cycles,
    closeButtonElement,
    t,
    cycleIdToTaggedPracticesCountMapping,
  } = props;

  const totalCycles = _.size(cycles);

  const firstCycleId = _.get(_.first(cycles), "id", "");

  useEffect(() => {
    /**To show Practices when there is only 1 cycle */
    if (totalCycles === 1) updateCurrentCycle({ id: firstCycleId });
  }, [totalCycles, firstCycleId, updateCurrentCycle]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{`${t("snp:select_cycle")}`}</div>
        {closeButtonElement}
      </div>
      <div className={classes.cyclesContainer}>
        <div className={classes.cyclesScrollContainer}>
          {_.map(cycles, cycle => {
            return (
              <CycleCard
                key={cycle.id}
                cycle={cycle}
                onClickCard={updateCurrentCycle}
                cardStyles={styles.cardStyles}
                cycleActionsElement={
                  <ArrowWithCount
                    count={cycleIdToTaggedPracticesCountMapping[cycle.id]}
                  />
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EvaluationCycles;
