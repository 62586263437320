import gql from "graphql-tag";
import { rubricFragment } from "Rubric/modules/RubricFragments";
import { checklistFragment } from "Checklist/modules/ChecklistFragments";
import { singlePointRubricFragment } from "SinglePointRubric/modules/SinglePointRubricFragments";
import { attachmentFragment } from "modules/CommonFragments";

export const mypObjectiveRubricDescriptorFragmnet = {
  mypObjectiveRubricDescriptor: gql`
    fragment mypObjectiveRubricDescriptorItem on MypObjectiveRubricDescriptor {
      id
      label
      objective {
        id
      }
      achievementLevel {
        id
      }
    }
  `,
};

export const mypCriteriaWithDescriptorFragmnet = {
  mypCriteriaWithDescriptor: gql`
    fragment mypCriteriaWithDescriptorItem on PlannerElementNode {
      id
      objectiveRubricDescriptors {
        ...mypObjectiveRubricDescriptorItem
      }
    }
    ${mypObjectiveRubricDescriptorFragmnet.mypObjectiveRubricDescriptor}
  `,
};

export const assessmentToolFragment = {
  anecdote: gql`
    fragment anecdoteItem on Anecdote {
      id
      message
    }
  `,
  exemplar: gql`
    fragment exemplarItem on Exemplar {
      id
      message
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
  `,
  mypObjectiveRubric: gql`
    fragment mypObjectiveRubricItem on MypObjectiveRubric {
      id
      label
      descriptors {
        ...mypObjectiveRubricDescriptorItem
      }
    }
    ${mypObjectiveRubricDescriptorFragmnet.mypObjectiveRubricDescriptor}
  `,
};

export const assessmentToolParentNodeFragment = {
  assessment: gql`
    fragment assessmentNodeItem on Assessment {
      id
      assessmentTool {
        id
        ... on Rubric {
          ...rubricItem
        }
        ... on Checklist {
          ...checklist
        }
        ... on Anecdote {
          ...anecdoteItem
        }
        ... on Exemplar {
          ...exemplarItem
        }
        ... on SinglePointRubric {
          ...singlePointRubricItem
        }
      }
    }
    ${assessmentToolFragment.exemplar}
    ${assessmentToolFragment.anecdote}
    ${checklistFragment.checklist}
    ${rubricFragment.rubric}
    ${singlePointRubricFragment.singlePointRubric}
  `,
  unitPlan: gql`
    fragment unitPlanNodeItem on UnitPlan {
      id
      assessmentTool {
        id
        ... on Rubric {
          ...rubricItem
        }
        ... on Checklist {
          ...checklist
        }
        ... on Anecdote {
          ...anecdoteItem
        }
        ... on Exemplar {
          ...exemplarItem
        }
        ... on SinglePointRubric {
          ...singlePointRubricItem
        }
      }
    }
    ${assessmentToolFragment.exemplar}
    ${assessmentToolFragment.anecdote}
    ${checklistFragment.checklist}
    ${rubricFragment.rubric}
    ${singlePointRubricFragment.singlePointRubric}
  `,
};
