import React, { useState } from "react";
import { UIModal, I18nHOC, withLoader, FullScreenLoader } from "UIComponents";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";

import classes from "./FamilyInviteChildSelection.scss";
import { Button } from "@toddle-design/web";
import { markStudentAsRequestedForFamilyInvite } from "Courses/modules/CoursesModule";
import ApprovalRequestSentModal from "./components/ApprovalRequestSentModal";
import StudentContent from "./components/StudentContent";
import { getChildrenQuery } from "modules/CommonQuery";
import { getChildrenFromCache } from "modules/CommonGraphqlHelpers";

const FamilyInviteChildSelection = props => {
  const {
    t,
    onClose,
    allChildren,
    courseId,
    onChildChangeClick,
    markStudentAsRequestedForFamilyInvite,
  } = props;

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [approvalRequestSentModal, setApprovalRequestSentModal] = useState(
    false
  );
  const [isLoading, setLoading] = useState(false);

  const handleSelectionChange = id => setSelectedStudent(id);

  const handleSubmit = async () => {
    setLoading(true);
    await markStudentAsRequestedForFamilyInvite({
      courseId,
      childIds: [selectedStudent?.id],
    });
    setLoading(false);
    setApprovalRequestSentModal(true);
  };

  const onConfirmClose = () => {
    onClose();
    // this function is called from the parent home page and this
    // shows the portal for this latest selected student
    if (_.get(selectedStudent, "id") && onChildChangeClick) {
      onChildChangeClick(_.get(selectedStudent, "id"));
    }
  };

  const onDismissInvitation = () => {
    onClose();
  };

  if (approvalRequestSentModal) {
    return (
      <ApprovalRequestSentModal
        student={selectedStudent}
        onClose={onConfirmClose}
      />
    );
  }

  return (
    <UIModal isOpen={true} modalContent={classes.modalContent}>
      <div>
        <div className={classes.container}>
          <StudentContent
            handleSelectionChange={handleSelectionChange}
            selectedStudent={selectedStudent}
            courseId={courseId}
            allChildren={allChildren}
            searchText={searchText}
            setSearchText={setSearchText}
            onClose={onClose}
          />
        </div>
        <div className={classes.footer}>
          <div className={classes.footerWrapper}>
            <Button
              onClick={onDismissInvitation}
              className={classes.cancel}
              variant="outlined"
            >
              {t("common:dismiss_invitation")}
            </Button>
            <Button
              disabled={selectedStudent === null || isLoading}
              className={classes.confirm}
              onClick={handleSubmit}
            >
              {t("common:confirm_selection")}
            </Button>
          </div>
        </div>
        {isLoading && <FullScreenLoader />}
      </div>
    </UIModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const courseId = ownProps.courseId;
  const userId = _.get(state.login, "userInfo.id");
  const childrenDataPresent = ownProps.childrenDataPresent;

  return {
    courseId,
    userId,
    isData: true,
    isLoading: false,
    childrenDataPresent,
  };
};

const mapActionCreators = { markStudentAsRequestedForFamilyInvite };

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["integration", "common"] }),
  graphql(getChildrenQuery, {
    name: "getChildren",
    options: ({ userId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: "FAMILY_MEMBER",
        portalType,
      },
    }),
    skip: ({ childrenDataPresent }) => childrenDataPresent,
    props({ getChildren, ownProps: { userId, portalType } }) {
      const childrenData = getChildrenFromCache({
        id: userId,
        type: "FAMILY_MEMBER",
        portalType,
      });
      const children = _.get(childrenData, "children", []);

      return {
        allChildren: children,
      };
    },
  }),
  withLoader
)(FamilyInviteChildSelection);
