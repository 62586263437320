import React from "react";
import { UIBaseComponent } from "UIComponents";
import { ToggleSwitch } from "@toddle-design/web";

class SwitchButton extends UIBaseComponent {
  renderEdit = () => {
    const { value, isDisabled } = this.props;

    return (
      <ToggleSwitch
        data-test-id={this.props["data-test-id"]}
        onChange={e => this.updateValue(e.target.checked)}
        disabled={isDisabled}
        checked={value}
      />
    );
  };
}

SwitchButton.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

export default SwitchButton;
