import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classes from "./PostSelection.scss";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, Loading } from "UIComponents";
import { EmptySvgIcon } from "SvgComponents";
import PostFeed from "./PostFeed";
import { getNodePostQuery } from "Post/modules/PostQuery";
import { getNodePostFromCache } from "Post/modules/PostGraphqlHelpers";
import { checkScrollAtBottom } from "Utils";
import { getPostsMemoize } from "./Utils";

const EmptyView = ({ t }) => {
  return (
    <div className={classes.noDataContainer}>
      <EmptySvgIcon />
      <div className={classes.noPostsText}>{t("journal:no_posts")}</div>
    </div>
  );
};

class PostSelection extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleScroll = e => {
    const containerDiv = e.target;
    const { hasNextPage, areMorePostLoading, loadMoreFeed } = this.props;

    if (
      containerDiv &&
      hasNextPage &&
      !areMorePostLoading &&
      checkScrollAtBottom(containerDiv)
    ) {
      loadMoreFeed();
    }
  };

  render() {
    const {
      posts,
      areMorePostLoading,
      isMultiSelect,
      t,
      selectedItems,
      updateSelectedItems,
      styles,
      disabledItems = [],

      customHeaderElement,
      cardWrapperElement,

      totalCount,

      customEmptyViewElement,
      openViewMode,
    } = this.props;
    const postsLength = _.get(posts, "length", 0);

    return (
      <div
        className={classes.container}
        onScroll={this.handleScroll}
        style={styles.containerStyle}
      >
        {postsLength > 0 ? (
          <div className={classes.feedContainer}>
            <PostFeed
              items={posts}
              selectedItems={selectedItems}
              updateSelectedItems={updateSelectedItems}
              isMultiSelect={isMultiSelect}
              styles={styles}
              disabledItems={disabledItems}
              customHeaderElement={customHeaderElement}
              totalCount={totalCount}
              cardWrapperElement={cardWrapperElement}
              openViewMode={openViewMode}
            />
            {areMorePostLoading && (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            )}
          </div>
        ) : customEmptyViewElement ? (
          customEmptyViewElement
        ) : (
          <EmptyView t={t} />
        )}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
  };
};

PostSelection.defaultProps = {
  isMultiSelect: false,
  styles: {},
};

export default compose(
  I18nHOC({ resource: ["common", "journal"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getNodePostQuery, {
    name: "getNodePost",
    options: ({ parentId, parentType, filters }) => {
      return {
        /**HACK:To hide previously selected posts,we can not use cache-and-network policy.
         * TODO: find better solution to fix issue
         */
        fetchPolicy: "network-only",
        variables: {
          id: parentId,
          type: parentType,
          filters,
        },
      };
    },
    props({
      getNodePost,
      ownProps: { isData, isLoading, parentId, parentType, filters },
    }) {
      const data = getNodePostFromCache({
        parentId,
        parentType,
        filters,
      });

      const posts = getPostsMemoize({ postsEdges: _.get(data, "posts.edges") });
      const totalCount = _.get(data, "posts.totalCount", 0);

      return {
        posts,
        hasNextPage: _.get(data, "posts.pageInfo.hasNextPage", false),
        areMorePostLoading: getNodePost["networkStatus"] === 3,

        totalCount,
        isData: isData && !_.isEmpty(data),
        isLoading:
          isLoading ||
          getNodePost["networkStatus"] == 1 ||
          getNodePost["networkStatus"] == 2,
        loadMoreFeed: () => {
          return getNodePost.fetchMore({
            query: getNodePostQuery,
            variables: {
              id: parentId,
              type: parentType,
              filters,
              after: _.get(getNodePost.node, "posts.pageInfo.endCursor", ""),
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousItems = previousResult.node.posts;
              const nextItems = fetchMoreResult.node.posts;

              const previousEdges = previousItems.edges;
              const nextEdges = nextItems.edges;

              const previousPageInfo = previousItems.pageInfo;
              const nextPageInfo = nextItems.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;

              return {
                ...previousResult,
                node: {
                  ...previousResult.node,
                  posts: {
                    ...previousItems,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withLoader
)(PostSelection);

PostSelection.defaultProps = {
  customEmptyViewElement: null,
};
