import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import { routes } from "Projects/config/routes";
import ProjectDetailsStep from "./routes/ProjectDetailsStep";
import ProjectReport from "./routes/ProjectReport";
import AcademicHonesty from "./routes/AcademicHonesty";
import Assessment from "./routes/Assessment";
import ProcessJournal from "./routes/ProcessJournal";
import RPPF from "./routes/RPPF";

const {
  projectDetails: { path },
} = routes;

const ProjectDetailsRoute = store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const plannerReducer = require("IBPlanner/modules/IBPlannerModule")
                .default;
              injectReducer(store, { key: "planner", reducer: plannerReducer });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [
      Assessment(store),
      AcademicHonesty(store),
      ProjectReport(store),
      ProcessJournal(store),
      RPPF(store),
      ProjectDetailsStep(store),
    ],
  };
};

export default ProjectDetailsRoute;
