import update from "immutability-helper";

/*

 - you can use this HOF for loadMoreFeed fn which we use in pagination
 - query: it will be your query for which you want loadMoreFeed fn eg. getProjectGroupSupervisorsQuery 
 - variable: it will be your query variables 
 - queryData: here queryData will be object that return by graphql HOC in props({obj,ownProps}) eg. getProjectGroupSupervisors
 - nodeKey: default value is "node"
 - key: this will be your entity name like students,supervisors for ex if your query response is like this 
            node:{
              id:15,
              projects:{
                totalCount:100,
                edges:[...],
                pageInfo:{
                  hasNextPage:true,
                  "endCursor":"some string"
                }
              }

            }
      then your key will be "projects".

 see Example at: src/routes/routes/Teacher/routes/Projects/routes/Setup/routes/Invite/routes/Supervisors/components/SupervisorList/SupervisorList.js

*/

const prepareLoadMoreFeed = ({
  query,
  variables,
  queryData,
  key,
  nodeKey = "node",
  edgesKey = "edges",
}) => {
  return () =>
    queryData.fetchMore({
      query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const nextItems = fetchMoreResult[nodeKey][key];

        const nextEdges = nextItems[edgesKey];

        const nextPageInfo = nextItems.pageInfo;

        const nextEndCursor = nextPageInfo.endCursor;
        const nextHasNextPage = nextPageInfo.hasNextPage;

        return update(previousResult, {
          [nodeKey]: {
            [key]: {
              [edgesKey]: { $push: nextEdges },
              pageInfo: {
                endCursor: { $set: nextEndCursor },
                hasNextPage: { $set: nextHasNextPage },
              },
            },
          },
        });
      },
    });
};

export default prepareLoadMoreFeed;
