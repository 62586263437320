import PropTypes from "prop-types";
import React from "react";

const RightIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 26 48"
      version="1.1"
    >
      <g id="Page-1" stroke="none" strokeWidth="1">
        <g id="Artboard" transform="translate(-125.000000, -101.000000)">
          <g
            id="lock-(1)"
            transform="translate(125.000000, 101.000000)"
            fill={props.fill && props.fill}
          >
            <path
              d="M0.585,0.585 C-0.195,1.365 -0.195,2.6331 0.585,3.4131 L21.174,24.0021 L0.585,44.5869 C-0.195,45.3669 -0.195,46.635 0.585,47.415 C1.365,48.195 2.6331,48.195 3.4131,47.415 L25.4181,25.4142 C25.8063,25.0221 26.0022,24.51 26.0022,24.0021 C26.0022,23.49 25.8063,22.9779 25.4181,22.5858 L3.4131,0.585 C2.6331,-0.195 1.365,-0.195 0.585,0.585 Z"
              id="right_arrow"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
RightIcon.defaultProps = {
  width: 16,
  height: 16,
};
RightIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default RightIcon;
