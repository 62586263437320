import { combineReducers } from "redux";
import { routerReducer as router } from "react-router-redux";
import loginReducer from "routes/modules/LoginModule";
import servicesReducer from "modules/Services";
import navigationReducer from "modules/NavigationModule";
import coachmarksReducer from "modules/CoachMarksModule";
import pusherReducer from "modules/PusherModule";
import platformReducer from "Platform/modules/PlatformModule";
import ResourceBankReducer from "modules/ResourceBankModule";
import conversationReducer from "ChatContainer/modules/ChatContainerModule";
import myCalendarReducer from "MyCalendar/modules/MyCalendarModule";
import { loadingBarReducer } from "react-redux-loading-bar";
import client from "../apolloClient.js";
import AssessmentReducer from "Assessments/modules/AssessmentModule";
import OrganizationResourcesReducer from "OrganizationResources/modules/OrganizationResourcesModule";
import UnitPlansReducer from "UnitPlans/modules/UnitPlanModule";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    // Add sync reducers here
    // apollo:client.reducer(),
    router,
    login: loginReducer,
    app_services: servicesReducer,
    navigation: navigationReducer,
    coachMarks: coachmarksReducer,
    loadingBar: loadingBarReducer,
    pusher: pusherReducer,
    resourceBank: ResourceBankReducer,
    conversation: conversationReducer,
    myCalendar: myCalendarReducer,
    platform: platformReducer,
    assessment: AssessmentReducer,
    organizationResource: OrganizationResourcesReducer,
    unitPlans: UnitPlansReducer,
    ...asyncReducers,
  });
};
export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};
export default makeRootReducer;
