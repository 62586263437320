import React, { useState } from "react";
import classes from "./ResourceViewItem.scss";

import { ResourceImagePlaceHolder, I18nHOC } from "UIComponents";
import _ from "lodash";
import {
  LinkIcon,
  FileViewIcon,
  ImageIcon,
  NoAttachmentIcon,
  MultiFileIcon,
} from "SvgComponents";
import { colors } from "Constants";
import { ResourceViewModal } from "AppComponents";

const placeHolderStyle = {
  borderColor: colors.gray72,
};
const Icon = ({ type }) => {
  const color = colors.teal20;
  switch (type) {
    case "LINK":
      return <LinkIcon width={16} height={16} fill={color} />;
    case "IMAGE":
      return <ImageIcon width={16} height={16} fill={color} />;
    case "FILE":
      return <FileViewIcon width={16} height={16} fill={color} />;
    case "MULTI":
      return <MultiFileIcon width={16} height={16} fill={color} />;
  }
  return <NoAttachmentIcon width={16} height={16} fill={color} />;
};
const ResourceViewItem = ({ item, t }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const setResourceModalVisibility = e => {
    if (e) {
      e.stopPropagation();
    }
    setModalVisibility(true);
  };
  const {
    title,
    createdBy: { firstName, lastName },
    attachments,
    id,
  } = item;
  let type = "";
  let addedString = "";

  switch (_.get(attachments, "length", 0)) {
    case 0:
      type = "";
      addedString = ""; //"No attachments";
      break;
    case 1:
      type = attachments[0].type;
      addedString = t("common:attachmentWithCount", { count: 1 });
      break;
    default:
      type = "MULTI";
      addedString = t("common:attachmentWithCount", {
        count: attachments.length,
      });
  }
  return (
    <div className={classes.container} onClick={setResourceModalVisibility}>
      <div className={classes.innerContainer}>
        <div className={classes.imagePlaceHolderContainer}>
          <ResourceImagePlaceHolder
            customContainerStyle={placeHolderStyle}
            icon={<Icon type={type} />}
          />
        </div>
        <div className={classes.textContainer}>
          <div className={classes.titleText}>{title}</div>
          <div className={classes.nameText}>
            {item.createdBy ? (
              <span>{`${t("common:by_with_label", {
                label: `${firstName} ${lastName}`,
              })}`}</span>
            ) : null}
            {addedString ? (
              <span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {`${addedString}`}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {modalVisibility && (
        <ResourceViewModal
          setModalVisibility={setModalVisibility}
          resourceId={id}
        />
      )}
    </div>
  );
};

export default I18nHOC({ resource: "common" })(ResourceViewItem);
