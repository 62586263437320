import React, { useState, useEffect } from "react";
import classes from "./ActionForSelectModal.scss";
import { colors } from "Constants";
import { UIModal, UILabel, RadioButtonList, UIButton } from "UIComponents";
import PropTypes from "prop-types";

const RECURRING_DELETE_OPTIONS = [
  { key: "calendar:all_meetings", value: "ALL" },
  { key: "calendar:this_meeting_only", value: "THIS" },
  { key: "calendar:this_and_following_meetings", value: "THIS_FOLLOWING" },
];

const createRecurringDeleteOptions = t =>
  _.map(RECURRING_DELETE_OPTIONS, option => ({
    label: t(option.key),
    value: option.value,
  }));

const styles = {
  recurringLabelStyle: {
    fontSize: "2.4rem",
    marginBottom: "30px",
  },
  recurringListContainerStyle: {
    flexDirection: "column",
    flexWrap: "initial",
  },
  recurringItemLabelStyle: {
    color: colors.gray31,
    fontSize: "1.8rem",
  },
  cancelButtonContainerStyle: {
    border: "0px",
    marginRight: "5px",
  },
};

const ActionForSelectModal = ({ t, onClose, onAction, mode }) => {
  const [recurringOption, setRecurringOption] = useState("ALL");
  const onRecurringDeletionModalClose = () => {
    onClose(false);
  };
  const onRecurringSelect = ({ actionFor }) => {
    setRecurringOption(actionFor);
  };
  return (
    <UIModal
      isOpen={true}
      onRequestClose={onRecurringDeletionModalClose}
      modalContent={classes.modalContent}
    >
      <div className={classes.recurringOptionsContainer}>
        <UILabel
          labelStyle={styles.recurringLabelStyle}
          label={
            mode === "edit"
              ? t("calendar:update_recurring_meeting")
              : t("calendar:delete_recurring_meeting")
          }
        ></UILabel>
        <div>
          <RadioButtonList
            options={createRecurringDeleteOptions(t)}
            label=""
            value={recurringOption}
            name={"actionFor"}
            listContainerStyle={styles.recurringListContainerStyle}
            labelStyle={styles.recurringItemLabelStyle}
            updateInputField={onRecurringSelect}
          />
        </div>
        <div className={classes.recurringButtonsContainer}>
          <UIButton
            color="grey"
            type="hollow"
            containerStyle={styles.cancelButtonContainerStyle}
            onClick={onRecurringDeletionModalClose}
          >
            {t("common:cancel")}
          </UIButton>
          <UIButton color="pink" onClick={() => onAction(recurringOption)}>
            {mode === "edit" ? t("common:update") : t("common:delete")}
          </UIButton>
        </div>
      </div>
    </UIModal>
  );
};

ActionForSelectModal.propTypes = {
  t: PropTypes.func,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  mode: PropTypes.string,
};

ActionForSelectModal.defaultProps = {
  mode: "delete",
};

export default ActionForSelectModal;
