import PropTypes from "prop-types";
import React from "react";
import classes from "./Unveil.scss";
import { AnimateHeight, I18nHOC } from "UIComponents";
class Unveil extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.collapsed,
      showCollapse: false,
    };
  }
  componentDidMount = () => {
    if (this.childrenWrapper) {
      this.showCollapseHeight({ childrenWrapper: this.childrenWrapper });
    }
  };

  componentDidUpdate = () => {
    const { updateHeightOnComponentUpdate } = this.props;

    if (this.animateHeightChildrenWrapper && updateHeightOnComponentUpdate) {
      this.showCollapseHeight({
        childrenWrapper: this.animateHeightChildrenWrapper.children[0],
      });
    }
  };

  showCollapseHeight = ({ childrenWrapper }) => {
    const { maxHeight } = this.props;

    setTimeout(() => {
      this.setState({
        showCollapse: childrenWrapper.clientHeight > maxHeight,
      });
    }, 50);
  };

  toggleCollapsed = e => {
    e.stopPropagation();
    const { onToggleCollapse } = this.props;
    if (onToggleCollapse) {
      onToggleCollapse();
    } else {
      this.setState({ collapsed: !this.state.collapsed });
    }
  };

  getHeight = () => {
    const { collapsed, showCollapse } = this.state;
    const { maxHeight } = this.props;

    if (showCollapse) {
      return collapsed ? maxHeight : "auto";
    }

    return "auto";
  };

  onAnimationStart = () => {
    const { onAnimationStart } = this.props;
    if (onAnimationStart) {
      onAnimationStart();
    }
  };

  onAnimationEnd = () => {
    const { onAnimationEnd } = this.props;
    if (onAnimationEnd) {
      onAnimationEnd();
    }
  };

  render() {
    const {
      children,
      animateOpacity,
      containerStyle,
      animateHeightStyle,
      t,
    } = this.props;
    const { collapsed, showCollapse } = this.state;

    const height = this.getHeight();
    return (
      <div
        className={classes.container}
        ref={ref => (this.childrenWrapper = ref)}
        style={containerStyle}
      >
        <AnimateHeight
          height={height}
          duration={300}
          onAnimationStart={this.onAnimationStart}
          onAnimationEnd={this.onAnimationEnd}
          animateOpacity={animateOpacity}
          style={animateHeightStyle}
          customRef={ref => (this.animateHeightChildrenWrapper = ref)}
        >
          {children}
        </AnimateHeight>
        {showCollapse && (
          <div className={classes.collapseText} onClick={this.toggleCollapsed}>
            {collapsed
              ? t("common:see_more_noDots")
              : t("common:see_less_noDots")}
          </div>
        )}
      </div>
    );
  }
}

Unveil.defaultProps = {
  maxHeight: 188,
  animateOpacity: true,
  containerStyle: {},
  animateHeightStyle: {},
  collapsed: true,
  updateHeightOnComponentUpdate: false,
};

Unveil.propTypes = {
  maxHeight: PropTypes.number,
  animateOpacity: PropTypes.bool,
};

export default I18nHOC({ resource: "common" })(Unveil);
