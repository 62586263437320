import gql from "graphql-tag";
import {
  courseFragment,
  unitPlanFeedFragment,
  pypElementFragment,
} from "modules/CommonFragments";
import { responseFragment } from "UnitPlans/modules/UnitPlanFragments";
import { staffUnitPlanFragment, sharingUnitPlanFragment } from "./IBFragments";

export const createUnitPlanMutation = gql`
  mutation createUnitPlan(
    $templateId: ID!
    $leTemplate: String
    $assessmentTemplate: String
    $startDate: String
    $endDate: String
    $grades: [ID!]!
    $createdBy: ID!
    $fields: [PlannerFieldInput]
    $fixedFields: CreateUnitPlanFixedFieldInput
    $collaborators: [UnitPlanCollaboratorInput!]
    $academicYears: [ID!]
    $curriculumProgramId: ID
    $courses: [ID!]
    $state: UNIT_PLAN_STATE!
    $showCollaborators: Boolean! = false
    $centralRepoInput: UnitCentralRepoConfigInput
  ) {
    planner {
      createUnitPlan(
        input: {
          leTemplate: $leTemplate
          assessmentTemplate: $assessmentTemplate
          startDate: $startDate
          endDate: $endDate
          templateId: $templateId
          grades: $grades
          createdBy: $createdBy
          fields: $fields
          fixedFields: $fixedFields
          collaborators: $collaborators
          courses: $courses
          curriculumProgramId: $curriculumProgramId
          state: $state
          academicYears: $academicYears
        }
      ) {
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFeedFragment.unitPlanFeed}
`;

export const updateUnitPlanMutation = gql`
  mutation updateUnitPlan(
    $fields: [UpdatePlannerFieldInput!]
    $updatedBy: ID!
    $unitPlanId: ID!
    $startDate: String
    $endDate: String
    $publishUnitInSampleUnits: Boolean
  ) {
    planner {
      updateUnitPlan(
        input: {
          updatedBy: $updatedBy
          fields: $fields
          unitPlanId: $unitPlanId
          startDate: $startDate
          endDate: $endDate
          publishUnitInSampleUnits: $publishUnitInSampleUnits
        }
      ) {
        id
        startDate
        endDate
      }
    }
  }
`;

export const updateUnitPlanCollaboratorsMutation = gql`
  mutation updateUnitPlanCollaborators(
    $collaborators: [UnitPlanCollaboratorInput!]
    $updatedBy: ID!
    $unitPlanId: ID!
    $grades: [ID!]
    $courses: UpdateArrayInput
    $state: UNIT_PLAN_STATE
  ) {
    planner {
      updateUnitPlan(
        input: {
          updatedBy: $updatedBy
          collaborators: $collaborators
          unitPlanId: $unitPlanId
          grades: $grades
          courses: $courses
          state: $state
        }
      ) {
        id
        collaborators: allCollaborators {
          totalCount
          edges {
            permission
            node {
              ...staffCoursesItem
            }
          }
        }
        state
        courses {
          id
          title
        }
      }
    }
  }
  ${staffUnitPlanFragment.staffCourses}
`;

export const shareUnitPlanSectionsMutation = gql`
  mutation shareUnitPlanSectionsMutation(
    $updatedBy: ID!
    $unitPlanId: ID!
    $shareWithUserType: SHARE_USER_TYPE_ENUM
    $enabledSections: [UnitPlanSharingEnabledSectionsInput!]
    $sharedCoursesWithUserGroup: [EntitySharingCoursesWithUserGroupInput!]
  ) {
    planner {
      updateUnitPlan(
        input: {
          updatedBy: $updatedBy
          unitPlanId: $unitPlanId
          enabledSections: $enabledSections
          shareWithUserType: $shareWithUserType
          sharedCoursesWithUserGroup: $sharedCoursesWithUserGroup
        }
      ) {
        ...unitSectionsSharingItem
      }
    }
  }
  ${courseFragment.courserGradesDetails}
  ${sharingUnitPlanFragment.unitSections}
`;

export const createResponseMutation = gql`
  mutation createResponse(
    $createdBy: ID!
    $fieldId: ID!
    $message: String!
    $evidence: GenericEvidenceInput
  ) {
    planner {
      addResponseToField(
        input: {
          fieldId: $fieldId
          message: $message
          createdBy: $createdBy
          evidence: $evidence
        }
      ) {
        totalCount
        edges {
          node {
            ...responseItem
          }
        }
      }
    }
  }
  ${responseFragment.response}
`;

export const editResponseMutation = gql`
  mutation editResponse(
    $id: ID!
    $message: String!
    $evidence: GenericEvidenceInput
  ) {
    planner {
      editFieldResponse(
        input: { responseId: $id, message: $message, evidence: $evidence }
      ) {
        ...responseItem
      }
    }
  }
  ${responseFragment.response}
`;

export const deleteResponseMutation = gql`
  mutation deleteResponse($id: ID!) {
    planner {
      deleteFieldResponse(input: { id: $id })
    }
  }
`;

export const addRelatedConceptMutation = gql`
  mutation addRelatedConcept(
    $plannerElementSetId: ID
    $relatedConcepts: [RelatedConceptInsertInput!]
  ) {
    planner {
      addRelatedConcept(
        input: {
          relatedConcepts: $relatedConcepts
          plannerElementSetId: $plannerElementSetId
        }
      ) {
        ...relatedConceptItem
      }
    }
  }
  ${pypElementFragment.relatedConcept}
`;
