import PropTypes from "prop-types";
import React from "react";
import classes from "./UILabel.scss";
import classNames from "classnames";

class UILabel extends React.PureComponent {
  render() {
    const {
      label,
      labelComponent,
      subText,
      labelStyle,
      labelClass,
      subtextClass,
      subTextStyle,
      labelContainerStyle,
    } = this.props;
    const labelClasses = classNames(
      { [classes.inputLabel]: true },
      { [classes[labelClass]]: !!labelClass }
    );

    const subTextClasses = classNames(
      { [classes.subText]: true },
      { [classes[subtextClass]]: !!subtextClass }
    );

    return (
      (!!label || !!labelComponent) && (
        <div className={classes.container} style={labelContainerStyle}>
          {labelComponent ? (
            labelComponent
          ) : !!label ? (
            <div className={classes.labelContainer}>
              <div className={labelClasses} style={labelStyle}>
                {label}
              </div>
            </div>
          ) : null}
          {!!subText && !labelComponent ? (
            <div
              className={subTextClasses}
              style={subTextStyle}
              dangerouslySetInnerHTML={{ __html: subText }}
            ></div>
          ) : null}
        </div>
      )
    );
  }
}

UILabel.defaultProps = {
  labelStyle: {},
  label: "",
  subText: "",
  labelClass: "",
  subtextClass: "",
  subTextStyle: {},
};

UILabel.propTypes = {
  subText: PropTypes.string,
  labelComponent: PropTypes.element,
  labelStyle: PropTypes.object,
  labelClass: PropTypes.string,
  subtextClass: PropTypes.string,
  subTextStyle: PropTypes.object,
};

export default UILabel;
