import Course from "./routes/Course";
import AttendenceManager from "AttendanceManager";
import AttendanceDashboard from "AttendanceDashboard";
import { withAsyncRouteLoading } from "UIComponents";
import { AssessmentDetails } from "Assessments";
import MyCalendar from "Course/routes/MyCalendar";
import ClassRoomDetails from "ClassRoom/routes/ClassRoomDetails";
import { injectReducer } from "store/reducers";
import Projects from "../Projects";

export default store => {
  return {
    path: "courses",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const classRoomReducer = require("ClassRoom/modules/ClassRoomModule")
                .default;
              const organizationResoucesReducer = require("AppComponents/OrganizationResources/modules/OrganizationResourcesModule")
                .default;
              const organizationResoucesReducerKey = require("AppComponents/OrganizationResources/modules/OrganizationResourcesModule")
                .NAME;
              injectReducer(store, {
                key: "classRoom",
                reducer: classRoomReducer,
              });
              injectReducer(store, {
                key: organizationResoucesReducerKey,
                reducer: organizationResoucesReducer,
              });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-course"
          );
        })
    ),
    childRoutes: [
      AttendenceManager(store),
      AttendanceDashboard(store),
      MyCalendar(store),
      Course(store),
      AssessmentDetails(store),
      ClassRoomDetails(store),
      Projects(store),
    ],
  };
};
