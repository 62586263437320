import { colors, fontStyle } from "Constants";

import { STYLES_MAPPING } from "ProductUpdates/modules/ProductUpdatesModules";

export const styles = {
  buttonDropDownContainerStyle: {
    top: "40px",
    width: "184px",
    borderRadius: "8px",
    padding: "0px",
  },
};

export const getTagStyles = colorTheme => {
  const { color, background, borderColor } = _.get(
    STYLES_MAPPING,
    [colorTheme],
    {}
  );

  return {
    container: {
      display: "flex",
      margin: "0px 8px 0px 0px",
      borderRadius: "4px",
      padding: "4px 12px",
      background,
      borderColor,
      alignItems: "center",
      justifyContent: "center",
      maxHeight: "24px",
      cursor: "default",
    },
    label: {
      fontSize: "12px",
      ...fontStyle.medium,
      color,
    },
  };
};

export const getModalStyles = source => {
  return (
    source === "MODAL" && {
      container: { padding: "24px 40px", margin: 0, borderBottom: "none" },
      title: { fontSize: "2.4rem" },
    }
  );
};
