import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import SubMenuCurriculumWrapper from "Administrator/commonComponents/SubMenuCurriculumWrapper";
import { getNextMenuRoute } from "Administrator/modules/AdministratorModule";
import { getRelativePath } from "Utils";

export default store => ({
  path: "grades",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/GradesModule").default;
            injectReducer(store, { key: "gradesSetup", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
  onEnter(nextState, replace) {
    if (_.last(nextState.routes).path == "grades") {
      const route = getNextMenuRoute("GRADES_SETUP");
      if (route) {
        replace(getRelativePath(route));
      } else {
        replace(getRelativePath("../"));
      }
    }
  },
  childRoutes: [SubMenuCurriculumWrapper(store)],
});
