import {
  getEntityRatingQuery,
  getRatingConstantsQuery,
  getUnitDetailsQuery,
  getLEDetailsQuery,
  getUserCommunityStatusQuery,
  getCommunityUserDetailsQuery,
  getCommunityOrganizationGradesQuery,
  getEntityLikesQuery,
  getUsersNotOnCommunityQuery,
  getResourceRequestQuery,
} from "./CommunityQuery";
import { unitPlanFeedFragment } from "modules/CommonFragments";
import {
  entitySavedByNodeFragment,
  likeConnectionNodefragment,
  resourceRequestFragment,
} from "./CommunityFragments";
import client from "apolloClient";
import { assessmentFragment } from "Assessments/modules/AssessmentFragments";

const savedByFragmentMapping = {
  UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: "unitPlan",
    fragmentName: "unitSavedByNodeItem",
  },
  ASSESSMENT: {
    typeName: "Assessment",
    fragment: "assessment",
    fragmentName: "assessmentSavedByNodeItem",
  },
};

const likesFragmentMapping = {
  UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: "unitPlan",
    fragmentName: "unitLikeConnectionNodeItem",
  },
  ASSESSMENT: {
    typeName: "Assessment",
    fragment: "assessment",
    fragmentName: "assessmenLikeConnectionNodeItem",
  },
};
export const getEntityRatingFromCache = ({ id, entityType }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getEntityRatingQuery,
      variables: {
        id,
        entityType,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getRatingConstantsFromCache = ({ entityTypes }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getRatingConstantsQuery,
      variables: { entityTypes },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeEntityRatingToCache = ({ id, entityType, data }) => {
  try {
    client.writeQuery({
      query: getEntityRatingQuery,
      variables: {
        id,
        entityType,
      },
      data,
    });
  } catch (e) {}
};

export const getUnitInfoFromCache = ({ id }) => {
  try {
    const data = client.readQuery({
      query: getUnitDetailsQuery,
      variables: {
        id,
      },
    });
    return _.get(data, "node");
  } catch (e) {
    return {};
  }
};

export const getLEInfoFromCache = ({ id }) => {
  try {
    const data = client.readQuery({
      query: getLEDetailsQuery,
      variables: {
        id,
      },
    });
    return _.get(data, "node");
  } catch (e) {
    return {};
  }
};

export const writeUnitPlanFragment = async ({
  unitPlanId,
  data,
  showCollaborators = true,
  centralRepoInput,
}) => {
  try {
    await client.writeFragment({
      id: `UnitPlan:${unitPlanId}`,
      fragment: unitPlanFeedFragment.unitPlanFeed,
      fragmentName: "unitPlanItem",
      data,
      variables: { showCollaborators, centralRepoInput },
    });
  } catch (err) {
    console.error(err);
  }
};

export const writeAssessmentFragment = async ({ assessmentId, data }) => {
  try {
    await client.writeFragment({
      id: `Assessment:${assessmentId}`,
      fragment: assessmentFragment.assessmentDetails,
      fragmentName: "assessmentItem",
      data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const writeSaveByNodeToCache = ({ entityType, entityId, data }) => {
  try {
    const { typeName, fragment, fragmentName } = savedByFragmentMapping[
      entityType
    ];
    client.writeFragment({
      id: `${typeName}:${entityId}`,
      fragment: entitySavedByNodeFragment[fragment],
      fragmentName: fragmentName,
      data,
    });
  } catch (e) {}
};

export const getSaveByNodeFromCache = ({ entityType, entityId }) => {
  let queryData = {};

  try {
    const { typeName, fragment, fragmentName } = savedByFragmentMapping[
      entityType
    ];
    queryData = client.readFragment({
      id: `${typeName}:${entityId}`,
      fragment: entitySavedByNodeFragment[fragment],
      fragmentName: fragmentName,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getUserCommunityStatusFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUserCommunityStatusQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeUserCommunityStatusToCache = ({ id, data }) => {
  try {
    client.writeQuery({
      query: getUserCommunityStatusQuery,
      variables: {
        id,
      },
      data,
    });
  } catch (e) {}
};

export const getCommunityUserDetailsFromCache = ({ id }) => {
  try {
    const data = client.readQuery({
      query: getCommunityUserDetailsQuery,
      variables: {
        id,
      },
    });
    return _.get(data, "node");
  } catch (e) {
    return {};
  }
};

export const writeCommunityUserDetailsToCache = ({ id, data }) => {
  try {
    client.writeQuery({
      query: getCommunityUserDetailsQuery,
      variables: {
        id,
      },
      data,
    });
  } catch (e) {
    return {};
  }
};

export const getCommunityOrganizationGradesFromCache = ({ id }) => {
  try {
    const data = client.readQuery({
      query: getCommunityOrganizationGradesQuery,
      variables: {
        id,
      },
    });
    return _.get(data, "node");
  } catch (e) {
    return {};
  }
};

export const writeLikesNodeToCache = ({ entityType, entityId, data }) => {
  try {
    const { typeName, fragment, fragmentName } = likesFragmentMapping[
      entityType
    ];
    client.writeFragment({
      id: `${typeName}:${entityId}`,
      fragment: likeConnectionNodefragment[fragment],
      fragmentName: fragmentName,
      data,
    });
  } catch (e) {}
};

export const getLikesNodeFromCache = ({ entityType, entityId }) => {
  let queryData = {};

  try {
    const { typeName, fragment, fragmentName } = likesFragmentMapping[
      entityType
    ];
    queryData = client.readFragment({
      id: `${typeName}:${entityId}`,
      fragment: likeConnectionNodefragment[fragment],
      fragmentName: fragmentName,
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getEntityLikedUsersFromCache = ({ entityType, entity }) => {
  try {
    return client.readQuery({
      query: getEntityLikesQuery,
      variables: {
        id: entity,
        entityType,
        after: "",
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const getUsersNotOnCommunityFromCache = ({ id }) => {
  try {
    const data = client.readQuery({
      query: getUsersNotOnCommunityQuery,
      variables: {
        id,
      },
    });
    return _.map(_.get(data, "node.staff.edges", []), user => ({
      ...user.node,
    }));
  } catch (e) {
    return {};
  }
};

export const getResourceRequestDataFromCache = ({ types }) => {
  try {
    const data = client.readQuery({
      query: getResourceRequestQuery,
      variables: {
        types,
      },
    });
    return _.get(data, "community.survey.contentKeywords", []);
  } catch (e) {
    return {};
  }
};

export const writeResourceRequestDataToCache = ({ types, data }) => {
  try {
    client.writeQuery({
      query: getResourceRequestQuery,
      variables: types,
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const writeResourceRequestInCache = ({ id, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `SurveyContentKeyword:${id}`,
      fragment: resourceRequestFragment.resourceRequest,
      fragmentName: "resourceRequestItem",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
