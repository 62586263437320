import React from "react";
import { AttachmentList } from "AppComponents/Attachment";
import classes from "./AttachmentListWrapper.scss";
import { map as _map, find as _find } from "lodash";
import { getOrgResourcesAttachments } from "Utils";

const AttachmentListWrapper = props => {
  const {
    organizationResources = [],
    toggleSelectResources,
    selectedResources,
  } = props;
  const updateSelectedItems = selectedAttachments => {
    const selectedOrganizationResources = _map(
      selectedAttachments,
      attachment => {
        return _find(organizationResources, oR =>
          attachment?.id
            ? _.isEqual(_.get(oR, "attachment.id", null), attachment?.id)
            : false
        );
      }
    );
    toggleSelectResources(selectedOrganizationResources);
  };
  return (
    <div className={classes.container}>
      <AttachmentList
        selectedItems={_map(selectedResources, resource => resource.attachment)}
        updateSelectedItems={updateSelectedItems}
        value={getOrgResourcesAttachments({ resources: organizationResources })}
        cardType={"verticalHorizontal"}
        selectedItemType={"OBJECT"}
        itemStyles={{
          backgroundPosition: "center",
        }}
        containerStyle={{
          gridGap: "40px 24px",
        }}
        cardStyle={{
          height: 160,
        }}
        mode={"select"}
      />
    </div>
  );
};

export default AttachmentListWrapper;
