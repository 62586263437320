import { withAsyncRouteLoading } from "UIComponents";
import ProjectDetails from "Projects/routes/ProjectDetails";

export default store => {
  return {
    path: "projectApproval",
    component: withAsyncRouteLoading(
      () =>
        new Promise((cb, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              cb(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [ProjectDetails(store)],
  };
};
