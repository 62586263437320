import PropTypes from "prop-types";
import React from "react";

const UploadIconV2 = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 12"
      fill={props.fill && props.fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0151 4.70741C14.2987 5.11077 15.2668 6.17294 15.471 7.45301L15.4724 7.46199L15.4733 7.46941C15.5447 8.03488 15.4721 8.59652 15.2635 9.14752C14.7541 10.5139 13.3305 11.3341 11.9413 11.3341H3.41995C2.35845 11.3341 1.24214 10.7776 0.777757 9.78188L0.774479 9.77485L0.771962 9.76899C0.570288 9.30329 0.407747 8.65067 0.559797 8.02448C0.771648 6.88349 1.7558 6.01571 2.90779 5.85305C3.00645 3.03971 5.26059 0.747273 7.93905 0.682028C10.2687 0.616686 12.4294 2.28647 13.0151 4.70741ZM12.0353 5.03379C11.5881 3.00779 9.81035 1.6524 7.96721 1.70452L7.96517 1.70457C5.78783 1.75713 3.8796 3.71949 3.93409 6.14611L3.93423 6.15237L3.93422 6.15752C3.93422 6.31383 3.8727 6.45108 3.82259 6.53375C3.79304 6.58247 3.747 6.64658 3.67933 6.70462C3.61674 6.75831 3.48611 6.84839 3.29908 6.84839C2.44405 6.84839 1.70427 7.46386 1.56934 8.22056L1.56586 8.24009L1.56175 8.25587C1.48007 8.5792 1.55662 8.99205 1.7137 9.35764C1.97684 9.91395 2.67142 10.3113 3.41995 10.3113H11.9413C12.9689 10.3113 13.9623 9.69676 14.2995 8.79103L14.3005 8.78839C14.4531 8.38574 14.5015 7.99346 14.4538 7.60516C14.3061 6.70128 13.5817 5.91031 12.5821 5.64721C12.2121 5.60244 12.0602 5.27112 12.0353 5.03379Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43829 4.78195C7.43829 4.4992 7.66882 4.27051 7.95256 4.27051C8.23629 4.27051 8.46682 4.4992 8.46682 4.78195V8.6696C8.46682 8.95234 8.23629 9.18104 7.95256 9.18104C7.66882 9.18104 7.43829 8.95234 7.43829 8.6696V4.78195Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6598 4.12218C7.83285 4.00304 8.06143 4.0008 8.23681 4.11651L9.68725 5.07347C9.92392 5.22962 9.9886 5.54715 9.83133 5.78262C9.67419 6.0179 9.35515 6.08186 9.11866 5.92583L7.95865 5.16049L6.85521 5.92016C6.62186 6.08081 6.30161 6.02313 6.13983 5.79098C5.97792 5.55866 6.03626 5.23992 6.26979 5.07914L7.6598 4.12218Z"
      />
    </svg>
  );
};

UploadIconV2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

UploadIconV2.defaultProps = {
  height: 20,
  width: 20,
};

export default UploadIconV2;
