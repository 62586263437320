import React from "react";
import { connect } from "react-redux";
import classes from "./AttachmentCard.scss";
import { RadialProgressBar, MediaModal, MediaPlaceholder } from "UIComponents";
import classNames from "classnames";
import { Tick, FeedbackResponseIcon } from "SvgComponents";
import { colors } from "Constants";
import { getStudentResponseData } from "ClassRoom/modules/utils";
import { openLinkInNewTab } from "modules/Services";

export const ModelContent = ({
  attachments,
  toggleMediaModal,
  showMediaModal,
  activeIndex,
  mode,
}) => {
  return showMediaModal ? (
    <div className={classes.modalContent}>
      <MediaModal
        toggleMediaModal={toggleMediaModal}
        attachments={attachments}
        activeIndex={activeIndex}
        mode={mode}
        onWorkbookUpdateSuccessfully={() => toggleMediaModal(false)}
      />
    </div>
  ) : null;
};

class AttachmentCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMediaModal: false,
      activeIndex: 0,
    };
  }

  showAttachment = () => {
    this.setState({ showMediaModal: true });
  };

  toggleMediaModal = (value, activeIndex = 0) => {
    this.setState({ showMediaModal: value, activeIndex });
  };

  deleteAttachment = () => {
    const { deleteAttachment, attachment } = this.props;
    deleteAttachment(attachment.id);
  };

  onClickMedia = ({ activeIndex = 0, event } = {}) => {
    const {
      onClickMedia,
      attachment,
      onToggleMedia,
      mode,
      updateSelectedItems,
      isSelected,
      openLinkInNewTab,
    } = this.props;
    const { postEntityItems } = attachment;

    if (event && event.stopPropagation) {
      event.stopPropagation();
    }

    if (mode === "select") {
      return updateSelectedItems({ attachment, isSelected });
    }

    if (onClickMedia) {
      onClickMedia(attachment);
    } else {
      if (!_.isEmpty(postEntityItems)) {
        const { postId } = getStudentResponseData(postEntityItems);
        openLinkInNewTab({
          url: `/platform/links/post/${postId}/?preserve_query_params=true`,
        });
      } else {
        this.toggleMediaModal(true, activeIndex);
        if (onToggleMedia) {
          onToggleMedia(true);
        }
      }
    }
  };

  render() {
    const {
      attachment,
      showOverlayIcons,
      mode,
      cardStyle,
      showSingleCardTheme,
      clubingPlusCard,
      lengthOfClubbedAttachment,
      showDownloadIcon,
      iconContainerStyle,
      itemStyles,
      isSelected,
      attachmentResponseObject,
      toolTipText,
      isDisabledMode,
    } = this.props;
    const { showMediaModal, activeIndex } = this.state;
    const progressValue = parseInt(_.get(attachment, `progress`, "101"));

    //The progress Value is between 0-100 when its being uploaded, and once uploaded
    //the attachment doesn't have progress in it, so by default its 101, which means its created
    const isAttachmentCreated = progressValue > 100;
    const { isPost, attachments = [], id, postEntityItems } = attachment;
    const isFeedbackGiven = _.get(attachmentResponseObject, id);
    const containerClass = classNames(classes.container, {
      [classes.singleCardCon]: showSingleCardTheme,
      [classes["is-selected"]]: isSelected,
    });

    return (
      <div className={containerClass} style={cardStyle}>
        {isFeedbackGiven ? (
          <span className={classes.notificationCounter}>
            <FeedbackResponseIcon width={16} height={16} fill={colors.white} />
          </span>
        ) : null}

        <MediaPlaceholder
          onClickMedia={this.onClickMedia}
          isDisabledMode={isDisabledMode}
          attachments={isPost ? attachments : [attachment]}
          imageMaxHeight={_.get(cardStyle, "height", 148)}
          imageMaxWidth={200}
          toolTipText={toolTipText}
          resolutionMutliplier={2}
          mode={mode}
          //fitIn={false}
          showOverlayIcons={progressValue >= 100 ? showOverlayIcons : false}
          scalableRatio={1}
          itemStyles={{ borderRadius: "4px", ...(itemStyles || {}) }}
          containerStyle={{ height: "unset", flex: 1, width: "100%" }}
          deleteAttachment={this.deleteAttachment}
          iconContainerStyle={{
            ...(clubingPlusCard ? { display: "none" } : {}),
            ...(iconContainerStyle || {}),
          }}
          showDownloadIcon={showDownloadIcon}
          postEntityItems={postEntityItems}
          isAttachmentCreated={isAttachmentCreated}
        />
        {isSelected && (
          <span className={classes["tick-container"]}>
            <Tick fill={colors.blue29} width={24} height={24} />
          </span>
        )}
        {progressValue < 100 && (
          <div className={classes.uploadingContainer}>
            <div className={classes.progressBar}>
              <RadialProgressBar
                value={progressValue}
                circleStroke={"#F0F0F3"}
                progressStroke={"#F0F0F3"}
              />
            </div>
          </div>
        )}

        <ModelContent
          attachments={isPost ? attachments : [attachment]}
          toggleMediaModal={this.toggleMediaModal}
          showMediaModal={showMediaModal}
          activeIndex={activeIndex}
        />
        {clubingPlusCard && (
          <div
            className={classes.overlayBackground}
            onClick={this.onClickMedia}
          >
            <div className={classes.clubCardPlusText}>
              {`+${lengthOfClubbedAttachment + 1}`}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  openLinkInNewTab,
};

const mapStateToProps = (state, ownProps) => {
  return {
    progressOfUploads: state.app_services.progressOfUploads,
  };
};

export default connect(mapStateToProps, mapActionCreators)(AttachmentCard);

AttachmentCard.defaultProps = {
  showDownloadIcon: true,
};
