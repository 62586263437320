import gql from "graphql-tag";
import {
  courseAssignmentFragment,
  assignmentFragment,
  studentAssignmentFragment,
  courseStudentFragment,
  organizationAssignmentFragment,
  courseUserMapFragment,
} from "./ClassRoomFragments";
import {
  resolvedFieldValueFragment,
  studentFragment,
} from "modules/CommonFragments";
export const getCourseAssignmentFeedQuery = gql`
  query getCourseAssignmentFeed(
    $courseId: ID!
    $createdBy: ID
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $searchText: String
    $after: String
    $states: [ASSIGNMENT_STATE_ENUM!]
    $orderBy: ASSIGNMENT_ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $folderIds: [ID!]
    $studentIds: [ID!]
    $showUpcoming: Boolean
    $assessmentTypes: [String!]
    $unreadMessageCountFilters: UnreadMessageCountFilters
  ) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseAssignmentItem
      }
    }
  }
  ${courseAssignmentFragment.courseAssignmentFeed}
`;

export const getTeacherReviewFilterCountQuery = gql`
  query getTeacherReviewFilterCount(
    $organizationId: ID!
    $states: [ASSIGNMENT_STATE_ENUM!]
    $courseIds: [ID!]
    $createdBy: ID
    $searchText: String
    $lastWeekStartDate: String
    $lastWeekEndDate: String
    $earlierEndDate: String
    $thisWeekStartDate: String
    $thisWeekEndDate: String
    $archiveLastWeekStartDate: String
    $archiveLastWeekEndDate: String
    $archiveEarlierEndDate: String
    $archiveThisWeekStartDate: String
    $archiveThisWeekEndDate: String
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...teacherReviewFilterCountItem
      }
    }
  }
  ${courseAssignmentFragment.teacherReviewFilterCount}
`;

export const getTeacherClassroomReviewQuery = gql`
  query getTeacherClassroomReview(
    $organizationId: ID!
    $courseIds: [ID!]
    $createdBy: ID
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $searchText: String
    $after: String
    $states: [ASSIGNMENT_STATE_ENUM!]
    $orderBy: ASSIGNMENT_ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $studentIds: [ID!]
    $showUpcoming: Boolean
    $deadlineDates: GenericDateRange
    $archiveDates: GenericDateRange
    $isNoDeadlineDate: Boolean
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...courseAssignmentItem
      }
    }
  }
  ${courseAssignmentFragment.teacherClassroomReview}
`;

export const getCourseAssignmentStateWiseCountQuery = gql`
  query getCourseAssignmentStateWiseCount($courseId: ID!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseAssignmentItem
      }
    }
  }
  ${courseAssignmentFragment.courseAssignmentStateWiseCount}
`;

export const getParentStudentCourseAssignmentStateWiseCountQuery = gql`
  query getCourseAssignmentStateWiseCount(
    $userId: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: StudentAssignmentFeedFilters
    $studentIds: [ID!]
  ) {
    node(id: $userId, type: $type) {
      id
      ... on FamilyMember {
        id
        assignments(filters: $filters, studentIds: $studentIds) {
          stateWiseCount {
            state
            count
          }
        }
      }
      ... on Student {
        id
        assignments(filters: $filters) {
          stateWiseCount {
            state
            count
          }
        }
      }
    }
  }
`;

export const getAssignmentEditDetailsQuery = gql`
  query getAssignmentEditDetails($id: ID!) {
    node(id: $id, type: ASSIGNMENT) {
      id
      ... on Assignment {
        ...assignmentItem
      }
    }
  }
  ${assignmentFragment.assignmentEdit}
`;

export const getAssignmentDetailsQuery = gql`
  query getAssignmentDetails(
    $id: ID!
    $studentIds: [ID!]
    $showOnlySubmitted: Boolean
    $presetAssessmentUids: [String!]
    $userFilter: TOOL_RESPONSE_USER_FILTER_ENUM
    $unreadMessageCountFilters: UnreadMessageCountFilters
  ) {
    node(id: $id, type: ASSIGNMENT) {
      id
      ... on Assignment {
        ...assignmentItem
      }
    }
  }
  ${assignmentFragment.assignmentDetails}
`;

export const getStudentAssignmentDetailsQuery = gql`
  query getStudentAssignmentDetails(
    $id: ID!
    $showOnlySubmitted: Boolean
    $userFilter: TOOL_RESPONSE_USER_FILTER_ENUM
  ) {
    node(id: $id, type: STUDENT_ASSIGNMENT) {
      id
      ... on StudentAssignment {
        ...studentAssignmentItem
      }
    }
  }
  ${studentAssignmentFragment.studentAssignmentDetails}
`;

export const getAssignmentAllSubmissionQuery = gql`
  query getAssignmentAllSubmission($id: ID!) {
    node(id: $id, type: ASSIGNMENT) {
      id
      ... on Assignment {
        id
        assignedStudents {
          ... on StudentAssignment {
            ...studentAssignmentItem
            toolResponses(userFilter: ALL) {
              id
              creatorType
            }
          }
        }
        counts {
          totalAssignedStudents
          totalSubmissions
        }
        curriculumProgram {
          id
          type
        }
      }
    }
  }
  ${studentAssignmentFragment.allSubmissionFragment}
`;

export const getAssessmentDetailsQuery = gql`
  query getAssessmentDetails($id: ID!) {
    node(id: $id, type: ASSESSMENT) {
      id
      ... on Assessment {
        assessmentToolType
        type
        assessmentTool {
          id
        }
        allFields {
          id
          uid
          value
          resolvedMinimalTree {
            ...resolvedMinimalTreeItem
          }
        }
      }
    }
  }
  ${resolvedFieldValueFragment.resolvedMinimalTree}
`;

export const getClassRoomInsightsQuery = gql`
  query getClassRoomInsights(
    $id: ID!
    $filters: CourseFilter
    $studentAssignmentFilters: StudentAssignmentFeedFilters
    $after: String
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseStudentFragment.courseStudentPortfolio}
`;

export const getStudentInsightsQuery = gql`
  query getStudentInsights(
    $studentId: ID!
    $courseIds: ID!
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $searchText: String
    $states: [ASSIGNMENT_STATE_ENUM!]
    $status: [STUDENT_ASSIGNMENT_STATUS_ENUM!]
    $folderIds: [ID!]
    $after: String
    $assessmentTypes: [String!]
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentInsightsItem
      }
    }
  }
  ${courseAssignmentFragment.studentInsights}
`;

export const getStudentTodosQuery = gql`
  query getStudentTodos(
    $studentId: ID!
    $courseIds: [ID!]
    $searchText: String
    $states: [ASSIGNMENT_STATE_ENUM!]
    $status: [STUDENT_ASSIGNMENT_STATUS_ENUM!]
    $after: String
    $deadlineDates: GenericDateRange
    $archiveDates: GenericDateRange
    $isNoDeadlineDate: Boolean
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $assessmentTypes: [String!]
    $folderIds: [ID!]
    $orderBy: ASSIGNMENT_ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $academicYears: [ID!]
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentTodosItem
      }
    }
  }
  ${courseAssignmentFragment.studentTodos}
`;

export const getFamilyStudentTodosQuery = gql`
  query getStudentTodos(
    $userId: ID!
    $courseIds: [ID!]
    $studentIds: [ID!]
    $searchText: String
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $assessmentTypes: [String!]
    $states: [ASSIGNMENT_STATE_ENUM!]
    $status: [STUDENT_ASSIGNMENT_STATUS_ENUM!]
    $after: String
    $deadlineDates: GenericDateRange
    $archiveDates: GenericDateRange
    $isNoDeadlineDate: Boolean
    $folderIds: [ID!]
    $first: Int
    $orderBy: ASSIGNMENT_ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $academicYears: [ID!]
  ) {
    node(id: $userId, type: FAMILY_MEMBER) {
      id
      ... on FamilyMember {
        ...familyStudentTodosItem
      }
    }
  }
  ${courseAssignmentFragment.familyStudentTodos}
`;

export const getStudentTodosFilterCountQuery = gql`
  query getStudentFilterCount(
    $courseIds: [ID!]
    $studentId: ID!
    $states: [ASSIGNMENT_STATE_ENUM!]
    $status: [STUDENT_ASSIGNMENT_STATUS_ENUM!]
    $lastWeekStartDate: String
    $lastWeekEndDate: String
    $earlierEndDate: String
    $thisWeekStartDate: String
    $thisWeekEndDate: String
    $laterStartDate: String
    $archiveLastWeekStartDate: String
    $archiveLastWeekEndDate: String
    $archiveEarlierEndDate: String
    $archiveThisWeekStartDate: String
    $archiveThisWeekEndDate: String
    $archiveLaterStartDate: String
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $academicYears: [ID!]
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentTodosFilterCountItem
      }
    }
  }
  ${courseAssignmentFragment.studentTodosFilterCount}
`;

export const getFamilyStudentTodosFilterCountQuery = gql`
  query getStudentFilterCount(
    $userId: ID!
    $studentIds: [ID!]
    $states: [ASSIGNMENT_STATE_ENUM!]
    $status: [STUDENT_ASSIGNMENT_STATUS_ENUM!]
    $lastWeekStartDate: String
    $lastWeekEndDate: String
    $earlierEndDate: String
    $thisWeekStartDate: String
    $thisWeekEndDate: String
    $laterStartDate: String
    $archiveLastWeekStartDate: String
    $archiveLastWeekEndDate: String
    $archiveEarlierEndDate: String
    $archiveThisWeekStartDate: String
    $archiveThisWeekEndDate: String
    $archiveLaterStartDate: String
    $courseIds: [ID!]
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $academicYears: [ID!]
  ) {
    node(id: $userId, type: FAMILY_MEMBER) {
      id
      ... on FamilyMember {
        ...familyStudentTodosFilterCountItem
      }
    }
  }
  ${courseAssignmentFragment.familyStudentTodosFilterCount}
`;

export const getOrganizationAssignmentFeedQuery = gql`
  query getOrganizationAssignmentFeed(
    $organizationId: ID!
    $first: Int
    $after: String
    $orderBy: ASSIGNMENT_ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $filters: AssignmentFeedFilters
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationAssignmentItem
      }
    }
  }
  ${organizationAssignmentFragment.organizationAssignmentFeed}
`;

export const getAssignmentEvaluationInsightQuery = gql`
  query getAssignmentEvaluationInsight($id: ID!) {
    node(id: $id, type: ASSIGNMENT) {
      id
      ... on Assignment {
        ...assignmentEvaluationItem
      }
    }
  }
  ${assignmentFragment.assignmentEvalutionInsight}
`;

export const getCourseUserMapQuery = gql`
  query getCourseUserMap($id: ID!, $filters: CourseUserMapFilter) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseUserMapFragment.courseUserMap}
`;

export const getMultiCourseUserMapQuery = gql`
  query getMultiCourseUserMap($ids: [ID!], $filters: CourseUserMapFilter) {
    multiNode(ids: $ids, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseUserMapFragment.courseUserMap}
`;

export const getAssignedStudentsQuery = gql`
  query getAssignedStudentsQuery($id: ID!) {
    node(id: $id, type: ASSIGNMENT) {
      id
      ... on Assignment {
        id
        assignedStudents {
          id
          student {
            ...studentItem
          }
        }
      }
    }
  }
  ${studentFragment.basicStudentDetails}
`;
