import React, { PureComponent } from "react";
import { getCurriculumAttendanceOptionsQuery } from "modules/CommonQuery";
import {
  updateLocalAttendanceOption,
  updateAttendanceOption,
  deleteAttendanceOption,
  createAttendanceOption,
} from "AttendanceSetup/modules/Module";
import { getCurrentAcademicYear } from "modules/Services";
import { updateChildId } from "Administrator/modules/AdministratorModule";
import { getCurriculumAttendanceOptionsQueryFromCache } from "modules/CommonGraphqlHelpers";
import { generateRandomId } from "Utils";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { ChildHeader } from "Administrator/components";
import classes from "./AttendanceSetup.scss";
import {
  UIButton,
  withLoader,
  InputTextField,
  I18nHOC,
  ColorSelector,
  ColorDrop,
  FullScreenLoader,
} from "UIComponents";
import {
  checkSMSSendVisible,
  checkEmailSendVisible,
} from "AttendanceDashboard/modules/Utils";
import classNames from "classnames";
import { PAComponent } from "lib/PermissionAware";
import AttendanceRecordingSettings from "./AttendanceRecordingSettings";
import ACLStore from "lib/aclStore";
import {
  AddCircleOutlined,
  DeleteOutlined,
  PencilLineOutlined,
} from "@toddle-design/web-icons";
import { Button, Checkbox } from "@toddle-design/web";
import { IconButton } from "@toddle-design/web";

class AttendanceSetup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mode: null,
      editingId: null,
      isFullScreenLoading: false,
    };
    this.inputRefs = {};
  }

  UNSAFE_componentWillMount = () => {
    const { updateChildId, canUpdateChildId = true } = this.props;
    if (canUpdateChildId) {
      updateChildId("ATTENDANCE_SETUP");
    }
  };

  onClickSave = async () => {
    const {
      updateAttendanceOption,
      createAttendanceOption,
      attendanceOptionsSetId,
      currentCurriculumProgramId,
      academicYearId,
    } = this.props;
    const { mode, editingId } = this.state;
    const errorCount = this.isValid();

    if (errorCount > 0) {
      return;
    }

    try {
      this.setState({ editingId: null, mode: null, isFullScreenLoading: true });
      switch (mode) {
        case "CREATE":
          await createAttendanceOption({
            currentCurriculumProgramId,
            attendanceOptionsSetId,
            academicYearId,
          });
          break;
        case "EDIT":
          await updateAttendanceOption();
          break;
      }
    } catch (e) {
      this.setState({ mode, editingId });
      console.error(e);
    } finally {
      this.setState({ isFullScreenLoading: false });
    }
  };

  deleteAttendanceOption = async id => {
    const {
      deleteAttendanceOption,
      attendanceOptionsSetId,
      currentCurriculumProgramId,
      academicYearId,
    } = this.props;

    try {
      await deleteAttendanceOption({
        id,
        attendanceOptionsSetId,
        currentCurriculumProgramId,
        academicYearId,
      });
    } catch (e) {
      console.error(e);
    }
  };

  editAttendanceOption = value => {
    const { updateLocalAttendanceOption } = this.props;

    this.setState({ editingId: value.id, mode: "EDIT" });
    updateLocalAttendanceOption(value);
  };

  updateAttendanceOption = value => {
    const { updateLocalAttendanceOption } = this.props;
    updateLocalAttendanceOption(value);
  };

  onClickAddAttendanceOption = () => {
    const { editingId } = this.state;
    const { updateLocalAttendanceOption } = this.props;

    // Shouldn't create an option when already editing another
    if (_.isEmpty(editingId)) {
      const tempOption = {
        id: generateRandomId(),
        color: null,
        abbreviation: "",
        isDefault: false,
        label: "",
      };

      updateLocalAttendanceOption(tempOption);
      this.setState({
        mode: "CREATE",
        editingId: tempOption.id,
      });
    }
  };

  abbreviationValidation = value => {
    if (_.size(value) > 2) {
      return "Abbreviation should be at most 2 characters in length";
    }
    return "";
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;

    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  renderAttendanceOptions = ({ isSMSColumnVisible, isEmailColumnVisible }) => {
    const { attendanceOption, t, attendanceOptions } = this.props;
    const { editingId, mode } = this.state;
    let updatedAttendanceOptions = attendanceOptions || [];

    if (mode == "CREATE") {
      updatedAttendanceOptions = _.concat(
        updatedAttendanceOptions,
        attendanceOption
      );
    }

    const rowClass = classNames({
      [classes.row]: true,
      [classes.rowSMSColumn]: isSMSColumnVisible || isEmailColumnVisible,
      [classes.rowEmailColumn]: isSMSColumnVisible && isEmailColumnVisible,
    });

    return _.map(updatedAttendanceOptions, option => {
      const checkEditingId = editingId == option.id;

      return (
        <div key={`${option.id}-activated`} className={rowClass}>
          <div
            className={classes.gridItemContainer}
            style={{ justifyContent: "left" }}
            key={`${option.id}-title`}
          >
            <div style={{ paddingRight: "24px" }}>
              <InputTextField
                name={"label"}
                inputStyle={{
                  paddingLeft: "16px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                mode={editingId == option.id ? "edit" : "view"}
                value={
                  editingId == option.id ? attendanceOption.label : option.label
                }
                updateInputField={value => {
                  this.updateAttendanceOption({
                    ...attendanceOption,
                    label: value.label,
                  });
                }}
                placeholder={t("common:enter_with_label_lowercase", {
                  label: t("common:title"),
                })}
                ref={ref => (this.inputRefs["label"] = ref)}
                type={"text"}
                error={"Enter title"}
                showErrorText={true}
              />
            </div>
          </div>

          <div
            className={classes.gridItemContainer}
            key={`${option.id}-abbreviation`}
          >
            <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
              <InputTextField
                inputStyle={{
                  paddingLeft: "16px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  textAlign: "center",
                }}
                mode={editingId == option.id ? "edit" : "view"}
                name={"abbreviation"}
                value={
                  editingId == option.id
                    ? attendanceOption.abbreviation
                    : option.abbreviation
                }
                updateInputField={value => {
                  this.updateAttendanceOption({
                    ...attendanceOption,
                    abbreviation: value.abbreviation,
                  });
                }}
                placeholder={t("common:enter_with_label_lowercase", {
                  label: t("schoolSetup:acronym"),
                })}
                ref={ref => (this.inputRefs["abbreviation"] = ref)}
                type={"text"}
                error={"Enter abbreviation"}
                customValidation={this.abbreviationValidation}
                showErrorText={true}
              />
            </div>
          </div>

          <div className={classes.gridItemContainer} key={`${option.id}-color`}>
            <ColorSelector
              name={"color"}
              colorDropSize={20}
              mode={editingId == option.id ? "edit" : "view"}
              value={
                editingId == option.id ? attendanceOption.color : option.color
              }
              editContainerStyle={{ justifyContent: "center" }}
              updateInputField={value => {
                this.updateAttendanceOption({
                  ...attendanceOption,
                  color: value.color,
                });
              }}
            />
          </div>

          <PAComponent
            perm={"Common:AttendanceSMS"}
            key={`${option.id}-sendSMS`}
          >
            <div className={classes.gridItemContainer}>
              {editingId == option.id ? (
                <Checkbox
                  isChecked={attendanceOption.sendSMS}
                  size={"xx-small"}
                  onChange={() => {
                    this.updateAttendanceOption({
                      ...attendanceOption,
                      sendSMS: !attendanceOption.sendSMS,
                    });
                  }}
                />
              ) : (
                <Checkbox
                  disabled={true}
                  isChecked={option.sendSMS}
                  size={"xx-small"}
                />
              )}
            </div>
          </PAComponent>

          <PAComponent
            perm={"Common:AttendanceMarkedEmail"}
            key={`${option.id}-sendEMAIL`}
          >
            <div className={classes.gridItemContainer}>
              <Checkbox
                isChecked={
                  checkEditingId ? attendanceOption.sendEmail : option.sendEmail
                }
                disabled={checkEditingId ? false : true}
                size={"xx-small"}
                onChange={() => {
                  this.updateAttendanceOption({
                    ...attendanceOption,
                    sendEmail: !attendanceOption.sendEmail,
                  });
                }}
              />
            </div>
          </PAComponent>

          <div key={"more"} className={classes.actionsContainer}>
            {editingId == option.id ? (
              <UIButton
                type="filled"
                size="sm"
                color="blue"
                onClick={this.onClickSave}
              >
                {t("common:save")}
              </UIButton>
            ) : (
              <React.Fragment>
                <IconButton
                  icon={<PencilLineOutlined />}
                  variant={"plain"}
                  size={"small"}
                  onClick={() => this.editAttendanceOption(option)}
                />

                {!option.isDefault && (
                  <IconButton
                    icon={<DeleteOutlined />}
                    variant={"plain"}
                    size={"small"}
                    onClick={() => this.deleteAttendanceOption(option.id)}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const { t, showHeader = true, addButtonLabel, fromSetting } = this.props;
    const { isFullScreenLoading, editingId } = this.state;
    const isSMSColumnVisible = checkSMSSendVisible();
    const isEmailColumnVisible = checkEmailSendVisible();

    const gridHeaderContainerClass = classNames({
      [classes.gridHeaderContainer]: true,
      [classes.gridHeaderSMSColumn]: isSMSColumnVisible || isEmailColumnVisible,
      [classes.gridHeaderEmailColumn]:
        isSMSColumnVisible && isEmailColumnVisible,
    });

    return (
      <div className={classes.container}>
        {showHeader && <ChildHeader />}
        {ACLStore.can("AdminPortal:ConfigAttedanceRecordingType") &&
        !fromSetting ? (
          <div className={classes.attendanceRecordingContainer}>
            <div className={classes.headingContainer}>
              <div className={classes.title}>
                {t("configuration:attendance_mode")}
              </div>
              <div className={classes.subTitle}>
                {t("configuration:attendance_mode_desc")}
              </div>
            </div>
            <AttendanceRecordingSettings
              containerRowStyle={{ border: "0px" }}
            />
          </div>
        ) : null}
        {!fromSetting ? (
          <div className={classes.attendanceSetupHeadingContainer}>
            <div className={classes.title}>
              {t("configuration:attendance_categories")}
            </div>
          </div>
        ) : null}
        <div className={gridHeaderContainerClass}>
          <div
            className={classes.gridHeaderLabel}
            style={{ justifyContent: "left" }}
          >
            {t("common:title")}
          </div>

          <div className={classes.gridHeaderLabel}>
            {t("common:abbreviation")}
          </div>

          <div className={classes.gridHeaderLabel}>{t("common:color")}</div>

          <PAComponent perm={"Common:AttendanceSMS"}>
            <div className={classes.twoLineTextContainer}>
              <div className={classes.gridHeaderLabel}>
                {t("attendance:sms")}
              </div>
              <div className={classes.subTextLabel}>
                {t("attendance:to_family")}
              </div>
            </div>
          </PAComponent>

          <PAComponent perm={"Common:AttendanceMarkedEmail"}>
            <div className={classes.twoLineTextContainer}>
              <div className={classes.gridHeaderLabel}>{t("common:email")}</div>
              <div className={classes.subTextLabel}>
                {t("attendance:to_family")}
              </div>
            </div>
          </PAComponent>

          <div className={classes.gridHeaderLabel}>
            {t("common:action_plural")}
          </div>
        </div>

        <div className={classes.gridDataContainer}>
          {this.renderAttendanceOptions({
            isSMSColumnVisible,
            isEmailColumnVisible,
          })}
        </div>

        {_.isNull(editingId) && (
          <div className={classes.addButtonContainer}>
            <Button
              icon={<AddCircleOutlined />}
              variant={"primary"}
              size={"medium"}
              onClick={this.onClickAddAttendanceOption}
              className={"mr-auto"}
            >
              {addButtonLabel
                ? addButtonLabel
                : t("academicSetup:add_an_option")}
            </Button>
          </div>
        )}
        {isFullScreenLoading && <FullScreenLoader />}
      </div>
    );
  }
}

const mapActionCreators = {
  updateChildId,
  updateLocalAttendanceOption,
  updateAttendanceOption,
  deleteAttendanceOption,
  createAttendanceOption,
};

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;
  const currentCurriculumProgram = state.platform.currentCurriculumProgram;
  const currentCurriculumProgramId = _.get(
    currentCurriculumProgram,
    "id",
    null
  );

  const currentAcademicYear = getCurrentAcademicYear({
    organizationId: organizationId,
  });
  const selectedAcademicYear = _.get(
    state,
    "platform.academicYearSelected",
    currentAcademicYear
  );
  const academicYearId = _.get(selectedAcademicYear, "id", null);

  const curriculumAttendanceOptionsVariables = {
    id: organizationId,
    curriculumId: currentCurriculumProgramId,
    filter: {
      academicYearId: academicYearId,
    },
  };

  return {
    organizationId,
    academicYearId,
    currentCurriculumProgramId,
    attendanceOption: state.attendanceSetup.attendanceOption,
    curriculumAttendanceOptionsVariables,
  };
};

export default compose(
  I18nHOC({
    resource: ["academicSetup", "schoolSetup", "common", "configuration"],
  }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCurriculumAttendanceOptionsQuery, {
    name: "getCurriculumAttendanceOptions",
    options: ({ curriculumAttendanceOptionsVariables }) => ({
      fetchPolicy: "cache-and-network",
      variables: curriculumAttendanceOptionsVariables,
    }),
    props: ({
      getCurriculumAttendanceOptions,
      ownProps: { curriculumAttendanceOptionsVariables },
    }) => {
      const curriculumAttendanceOptionsData = getCurriculumAttendanceOptionsQueryFromCache(
        curriculumAttendanceOptionsVariables
      );

      const currentCurriculumAttendanceOptionsData = _.head(
        _.get(curriculumAttendanceOptionsData, "node.curriculumPrograms")
      );
      return {
        attendanceOptionsSetId: _.get(
          currentCurriculumAttendanceOptionsData,
          "attendanceOptionSet.id",
          null
        ),
        attendanceOptions: _.get(
          currentCurriculumAttendanceOptionsData,
          "attendanceOptionSet.attendanceOptions",
          []
        ),
        isData: !_.isEmpty(
          _.get(
            currentCurriculumAttendanceOptionsData,
            "attendanceOptionSet.attendanceOptions",
            []
          )
        ),
        isLoading:
          getCurriculumAttendanceOptions["networkStatus"] == 1 ||
          getCurriculumAttendanceOptions["networkStatus"] == 2,
      };
    },
  }),
  withLoader
)(AttendanceSetup);
