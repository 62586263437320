import React from "react";
import classes from "./CheckListEditRow.scss";
import { InputTextField, I18nHOC } from "UIComponents";
import { colors, fontStyle } from "Constants";
import { isTouchable } from "Utils";
import { IconButton, Tooltip } from "@toddle-design/web";
import { DeleteOutlined } from "@toddle-design/web-icons";
import classNames from "classnames";

const inputStyle = {
  cell: {
    border: "none",
    marginTop: 0,
    flexShrink: 0,
    padding: "0 24px 0 0",
    color: colors.textDefault,
    lineHeight: 1.57,
    ...fontStyle.medium,
  },
  disbledCell: {
    color: colors.textDisabled,
    padding: 16,
  },
};

const CheckListOption = ({
  label,
  writeCheckListElement,
  index,
  id,
  shouldDisable,
  onColumnFocus,
  setFocusedCell,
  setCellRef,
  criteriaId,
  item,
  t,
  onOptionDeleteClick,
}) => {
  const refKey = `Option_${id}_Criteria_${criteriaId}`;

  const optionClass = classNames({
    [classes.option]: true,
    [classes.optionDisabled]: shouldDisable,
  });

  return (
    <div
      onFocus={() => {
        onColumnFocus ? onColumnFocus(id) : null;
      }}
      className={classes.columnItem}
    >
      <div className={optionClass}>
        <InputTextField
          disabled={shouldDisable}
          value={label}
          inputStyle={{
            ...inputStyle.cell,
            ...(shouldDisable && inputStyle.disbledCell),
          }}
          updateInputRef={ref => setCellRef({ ref, key: refKey })}
          onBlurInputField={() => setFocusedCell(null)}
          onFocusInputField={() => setFocusedCell(refKey)}
          placeholder={`${t("common:option")} ${index + 1}`}
          updateInputField={value =>
            writeCheckListElement({
              id: id,
              label: value,
              type: "CHECKLIST_OPTION",
              elementDetails: item,
            })
          }
          className={classes.placeholderColor}
        />
        {!shouldDisable && (
          <div className={classes.optionActionButton}>
            <Tooltip
              tooltip={t("common:delete_with_label", {
                label: t("common:option"),
              })}
            >
              <IconButton
                icon={<DeleteOutlined variant="subtle" />}
                variant={"plain"}
                size={"x-small"}
                onClick={() => onOptionDeleteClick(id)}
                disabled={_.includes(id, "NEW")}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

class CheckListEditRow extends React.PureComponent {
  render() {
    const {
      id,
      label,
      options,
      index,
      minOptionCount,
      onOptionDeleteClick,
      onItemDeleteClick,
      writeCheckListElement,
      onColumnFocus,
      onRowFocus,
      focusedColumn,
      focusedRow,
      setCellRef,
      setFocusedCell,
      item,
      t,
    } = this.props;

    const refKey = `Criteria_${id}`;
    const disabled = _.includes(id, "NEW");

    const labelContainerClass = classNames({
      [classes.labelContainer]: true,
      [classes.disabled]: disabled,
    });

    return (
      <div className={classes.container} onFocus={() => onRowFocus(id)}>
        <div className={classes.row}>
          <div className={labelContainerClass}>
            <InputTextField
              value={label}
              inputStyle={{
                ...inputStyle.cell,
              }}
              onBlurInputField={() => setFocusedCell(null)}
              onFocusInputField={() => setFocusedCell(refKey)}
              updateInputRef={ref => setCellRef({ ref, key: refKey })}
              disabled={disabled}
              placeholder={t("common:criteria")}
              updateInputField={value =>
                writeCheckListElement({
                  id: id,
                  label: value,
                  elementDetails: item,
                  type: "CHECKLIST_ITEM",
                })
              }
              className={classes.placeholderColor}
            />

            <div className={classes.labelActionButton}>
              <Tooltip
                tooltip={t("common:delete_with_label", {
                  label: t("common:criteria"),
                })}
              >
                <IconButton
                  icon={<DeleteOutlined variant="subtle" />}
                  variant={"plain"}
                  size={"x-small"}
                  onClick={() => onItemDeleteClick(item)}
                  disabled={_.includes(item?.id, "NEW")}
                />
              </Tooltip>
            </div>
          </div>

          <div className={classes.optionContainer}>
            {_.map(options, (item, optionIndex) => {
              const shouldDisable = index > 0 || _.includes(item.id, "NEW");
              return (
                <CheckListOption
                  setCellRef={setCellRef}
                  setFocusedCell={setFocusedCell}
                  onColumnFocus={onColumnFocus}
                  key={item.id}
                  criteriaId={id}
                  item={item}
                  label={item.label}
                  id={item.id}
                  optionIndex={optionIndex}
                  minOptionCount={minOptionCount}
                  onOptionDeleteClick={() => onOptionDeleteClick(item.id)}
                  writeCheckListElement={writeCheckListElement}
                  index={optionIndex}
                  shouldDisable={shouldDisable}
                  t={t}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(CheckListEditRow);
