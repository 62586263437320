import React from "react";
import PropTypes from "prop-types";

class RadialProgressBar extends React.PureComponent {
  constructor() {
    super();
    this.initialize = this.initialize.bind(this);
    this.generatePath = this.generatePath.bind(this);
    this.setInterval = this.setInterval.bind(this);
    this.state = { value: 0 };
  }

  clamp(n, min, max) {
    return Math.max(min, Math.min(max, n));
  }

  setInterval() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.state.value < this.props.value) {
        this.setState({ value: this.state.value + this.props.progressRate });
      } else {
        clearInterval(this.interval);
      }
    }, 1000 / this.props.fps);
  }

  initialize() {
    if (this.props.animated) {
      this.setState({ value: 0 }, this.setInterval);
    } else {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    if (this.props.animated) clearInterval(this.interval);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ value: 0 }, this.initialize);
  }

  getArc = (angle, radius) => {
    const center = this.props.size / 2;

    const x = center + radius * Math.cos(this.radians(angle));
    const y = center + radius * Math.sin(this.radians(angle));

    return `A  ${radius}  ${radius}  1 0 1  ${x},${y}`;
  };

  radians = degrees => {
    return (degrees / 180) * Math.PI;
  };

  generatePath(degrees, radius) {
    const firstAngle = degrees > 180 ? 90 : degrees - 90;
    const secondAngle = -270 + degrees - 180;

    // Arcs
    const firstArc = this.getArc(firstAngle, radius);
    const secondArc = degrees > 180 ? this.getArc(secondAngle, radius) : "";

    const center = this.props.size / 2;

    let end = "";
    let start = null;

    if (this.props.isArc) {
      start = `M  ${center} ${this.props.circleStrokeWidth / 2}`;
    } else {
      start = `M ${center} ${center}  L ${center} ${
        this.props.circleStrokeWidth / 2
      }`;
      end = "z";
    }

    return `${start} ${firstArc} ${secondArc} ${end}`;
  }

  render() {
    const center = this.props.size / 2;
    const radius = center - this.props.circleStrokeWidth / 2;
    let degrees,
      text = "";
    if (this.props.unit === "percent") {
      const percent = this.clamp(this.state.value, 0, 100);
      degrees = (percent / 100) * 360;
      degrees = this.clamp(degrees, 0, 359.9);
      text = this.props.formatText(percent);
    } else {
      degrees = this.state.value;
      degrees = this.clamp(degrees, 0, 359.9);
      text = this.props.formatText(degrees);
    }

    const pathDescription = this.generatePath(degrees, radius);
    const sectorFill = this.props.isArc ? "none" : this.props.progressStroke;
    const sectorStroke = this.props.isArc ? this.props.progressStroke : "none";
    return (
      <svg height={this.props.size} width={this.props.size}>
        <circle
          cx={center}
          cy={center}
          r={radius}
          stroke={this.props.circleStroke}
          strokeWidth={this.props.circleStrokeWidth}
          fill={this.props.circleFill}
        />
        <path
          d={pathDescription}
          fill={sectorFill}
          stroke={sectorStroke}
          strokeWidth={this.props.circleStrokeWidth}
        />
        {this.props.displayText && (
          <text x={center} y={center + radius / 2} textAnchor="middle">
            {text}
          </text>
        )}
      </svg>
    );
  }
}

RadialProgressBar.defaultProps = {
  size: 24,
  circleStrokeWidth: 2,
  circleStroke: "black",
  circleFill: "transparent",
  progressStroke: "black",
  unit: "percent",
  displayText: false,
  formatText: value => value,
  animated: false,
  fps: 60,
  progressRate: 1,
  value: 0,
  isArc: false,
};

RadialProgressBar.propTypes = {
  size: PropTypes.number,
  circleStrokeWidth: PropTypes.number,
  circleStroke: PropTypes.string,
  circleFill: PropTypes.string,
  progressStroke: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.oneOf(["degrees", "percent"]),
  displayText: PropTypes.bool,
  formatText: PropTypes.func,
  animated: PropTypes.bool,
  fps: PropTypes.number,
  progressRate: PropTypes.number,
  isArc: PropTypes.bool,
};

export default RadialProgressBar;
