import React from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import classes from "./ResourceEdit.scss";
import { DialogueBox, I18nHOC } from "UIComponents";
import {
  Header,
  StepHeader,
  Footer,
  SideBar,
} from "UnitPlans/components/LibraryCommonComponents";
import MiddlePane from "./components/MiddlePane";
import MiddlePaneV2 from "./components/MiddlePaneV2";
import {
  clearAssessmentTool,
  updateAssessmentSections,
  updateAssessmentInRedux,
} from "Assessments/modules/AssessmentModule";
import {
  createAttachment,
  deleteAttachment,
  updateField,
  ASSESSMENT_TOOLS,
  createAttachmentGroups,
  deleteAttachmentGroups,
} from "modules/Services";
import { scrollToWithAnimation } from "Utils";
import {
  toggleFocusedField,
  TYPE_TO_BLUR_FIELD_EVENTS_MAPPING,
} from "modules/PusherModule";
import {
  enityEnumToLocalTypeMapping,
  ResourceDetailsContext,
} from "UnitPlans/modules/UnitPlanModule";
import ScreenBaseComponent from "AppComponents/ScreenBaseComponent";
import { colors, BlurFieldEvent } from "Constants";
import { setToastMsg, getUserInfo } from "Login/modules/LoginModule";
import { compose } from "react-apollo";
import { getCountString } from "Utils";
import {
  CommentBox,
  UserTemplateSettingsModal,
  CoachMarks,
} from "AppComponents";
import UnitSummary from "UnitPlans/components/UnitSummary";
import ACLStore from "lib/aclStore";
import { isUnitPlanEditPermission } from "UnitPlans/modules/UnitPlanModule";
import { getIsFromClassroom } from "UnitPlans/modules/Utils";
import { getLECreationRevampTemplate } from "Assessments/modules/Utils";
import { getSettingValue } from "modules/PermissionModule";

class ResourceEdit extends ScreenBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      // currentRightPane: "unitSummary",
      // showRightPane: true,
      currentStep: "",
      showTemplateModal: false,
    };
    props.customRef(this);
    this.middlePane = null;
    this.stepsRefs = {};
    this.canCurrentStepUpdate = true;
  }

  UNSAFE_componentWillMount = () => {
    const { updateAssessmentInRedux, assessmentId } = this.props;
    updateAssessmentInRedux({
      assessmentId,
    });
  };

  componentDidMount = () => {
    const { parentType } = this.props;
    this.event =
      TYPE_TO_BLUR_FIELD_EVENTS_MAPPING[
        enityEnumToLocalTypeMapping[parentType]
      ];

    this.checkCurrentStep();
    BlurFieldEvent.on(this.event, this.blurFields);
  };

  componentWillUnmount = () => {
    BlurFieldEvent.off(this.event, this.blurFields);
  };

  blurFields = params => {
    if (this.middlePane) {
      this.middlePane.blurFields(params);
    }
  };

  setCurrentStep = step => {
    this.setState({ currentStep: step });
  };

  checkCurrentStep = () => {
    const scrollContainer = this.middlePane.container;
    let currentSection = null;
    if (scrollContainer) {
      _.map(this.stepsRefs, (ref, key) => {
        if (ref) {
          const { top } = ref.getBoundingClientRect();
          if (top < scrollContainer.clientHeight / 4) {
            currentSection = key;
          }
        }
      });
    }
    if (this.state.currentStep != currentSection && this.canCurrentStepUpdate) {
      this.setCurrentStep(currentSection);
    }
  };

  onClickStep = step => {
    const scrollContainer = this.middlePane.container;
    const stepNode = this.stepsRefs[step];
    if (scrollContainer && stepNode) {
      this.canCurrentStepUpdate = false;
      const top = stepNode.offsetTop - scrollContainer.offsetTop + 4;

      scrollToWithAnimation(scrollContainer, top);
      setTimeout(() => {
        this.setCurrentStep(step);
        this.canCurrentStepUpdate = true;
      }, 300);
    }
  };

  onMiddlePaneScroll = e => {
    this.checkCurrentStep();
  };

  resetMiddlePaneScroll = () => {
    // setTimeout(() => {
    //   if (this.middlePane && this.middlePane.container) {
    //     this.middlePane.container.scrollTop = 0;
    //   }
    // }, 100);
  };

  checkValidation = () => {
    const totalErrors = this.middlePane.isValid();
    if (totalErrors != 0) {
      const countText = getCountString({
        count: totalErrors,
        singularText: "error",
        pluralText: "errors",
      });
      this.props.setToastMsg({
        msg: `toastMsgs:rectify_errors_with_count`,
        locale_params: [
          { key: "count", value: totalErrors, isPlainText: true },
        ],
        position: "toast-top-center",
        type: "alert",
        fill: colors.yellow50,
      });
    }
    return totalErrors == 0;
  };

  onClickSave = () => {
    if (this.checkValidation()) {
      this.props.onClose({ onlyExit: false });
    }
  };

  onBack = () => {
    if (this.props.mode == "edit") {
      if (this.checkValidation()) {
        this.props.onClose({ onlyExit: true });
      }
    } else {
      this.props.onClose({ onlyExit: true });
    }
  };

  onClickToolSave = () => {
    this.setState({ showTemplateModal: true });
  };

  saveTool = params => {
    this.middlePane.saveTool(params);
  };

  toggleFocusedField = params => {
    const { parentType, toggleFocusedField } = this.props;
    toggleFocusedField({
      ...params,
      type: enityEnumToLocalTypeMapping[parentType],
    });
  };

  onStepChanged = ({ nextStep }) => {
    const { steps } = this.props;
    const nextTab = steps[_.get(nextStep, "stepIndex", 0)];
    this.onClickStep(nextTab);
  };

  createAttachment = ({ attachment, fieldId }) => {
    const { createAttachment, portalType } = this.props;

    createAttachment({
      attachment,
      nodeId: fieldId,
      type: "PLANNER_FIELD",
      parentType: "FIELD",
      portalType,
    });
  };

  deleteAttachment = ({ attachmentId, fieldId }) => {
    const { deleteAttachment } = this.props;
    deleteAttachment({
      attachmentId,
      nodeId: fieldId,
      type: "PLANNER_FIELD",
    });
  };

  setMiddlePaneRef = ref => {
    this.middlePane = ref;
  };

  onUnitSummaryClick = () => {
    this.handleRightPane({ rightPane: "unitSummary" });
  };

  onCommentClick = () => {
    this.handleRightPane({ rightPane: "comment" });
  };

  setStepsRef = ({ ref, key }) => {
    this.stepsRefs[key] = ref;
  };

  getRightPaneComponent = () => {
    const {
      resourceId,
      parentType,
      unitPlanFields,
      unitPlanTemplate,
      unitPlanId,
    } = this.props;
    const { currentRightPane } = this.state;

    switch (currentRightPane) {
      case "comment":
        return (
          <ResourceDetailsContext.Consumer>
            {resourceDetails => {
              return (
                <CommentBox
                  parentType={parentType}
                  nodeId={resourceId}
                  type={parentType}
                  headerStyle={{ border: "none" }}
                  feedStyle={{ paddingTop: 0 }}
                  commentBoxContainerStyle={{ minHeight: 70 }}
                  onClose={this.onCommentClick}
                  unitPlanId={unitPlanId}
                  collaborators={_.get(
                    resourceDetails,
                    "unitPlan.collaborators",
                    []
                  )}
                />
              );
            }}
          </ResourceDetailsContext.Consumer>
        );
      case "unitSummary":
        return (
          <UnitSummary
            unitPlanId={unitPlanId}
            onClose={this.onUnitSummaryClick}
          />
        );
    }
    return null;
  };

  updateAssessmentSections = params => {
    const { updateAssessmentSections } = this.props;

    updateAssessmentSections({
      addedSections: _.get(params, "addedSections", []),
      removedSections: _.get(params, "removedSections", []),
    });
  };

  createAttachmentGroups = params => {
    const { createAttachmentGroups, portalType } = this.props;

    createAttachmentGroups({
      input: {
        parentId: params.parentId,
        parentType: params.parentType,
        attachments: params.attachments,
      },
      portalType,
    });
  };

  deleteAttachmentGroups = params => {
    const { deleteAttachmentGroups } = this.props;

    if (!_.isEmpty(_.get(params, "attachmentGroupIds", [])))
      deleteAttachmentGroups({ attachmentGroupIds: params.attachmentGroupIds });
  };

  render() {
    const {
      props: {
        steps,
        isLocalisedTemplate,
        step_list,
        field_list,
        resourceFields,
        unitPlanFields,
        unitPlanTemplateFieldList,
        enabledSections,
        resourceId,
        title,
        totalCommentCount,
        unreadCommentCount,
        onClickPrint,
        updateField,
        assessmentToolId,
        parentType,
        clearAssessmentTool,
        subjects,
        grades,
        unitPlanTemplate,
        updateFieldInCache,
        isUnitPlanEditPermission,
        unitPlanId,
        notIncludedSteps,
        configurableSteps,
        t,
        renderAsComponent,
        portalType,
        sourceFrom,
        curriculumType,
        localisedTemplateId,
        warningMsg,
        assessmentType,
        isAssessmentCreationRevamp,
        plannerElements,
        assessmentMode,
        showTemplateConfigModal,
        toggleShowTemplateConfigModal,
        templateId,
        originalTemplate,
      },
      state: { currentStep, showTemplateModal },
      template,
    } = this;

    const tool = _.get(
      _.find(resourceFields, { uid: "measureAssessing" }),
      "value",
      null
    );

    const resourceTitle = _.get(
      _.find(resourceFields, { uid: "title" }),
      "value",
      t("common:untitled")
    );

    const { currentRightPane } = this.state;
    const isEditPermission = isUnitPlanEditPermission({ unitPlanId });

    return (
      <React.Fragment>
        {sourceFrom != "CLASSROOM" ? (
          <CoachMarks
            type={parentType}
            params={{ tabs: steps }}
            continuous={false}
            // onStepChanged={this.onStepChanged}
          />
        ) : null}

        <div
          className={classes.container}
          id={`COACHMARKS_${parentType}`}
          style={{ gridTemplateRows: renderAsComponent && "1fr" }}
        >
          {!renderAsComponent && (
            <Header
              onClickPrint={onClickPrint}
              parentType={parentType}
              resourceTitle={resourceTitle}
              onClose={this.onBack}
              unreadCommentCount={unreadCommentCount}
              totalCommentCount={totalCommentCount}
              onUnitSummaryClick={this.onUnitSummaryClick}
              onClickSave={this.onClickSave}
              onCommentClick={this.onCommentClick}
              isUnitSummaryActive={currentRightPane == "unitSummary"}
              isCommentActive={currentRightPane == "comment"}
              curriculumType={curriculumType}
              showPlannerOverview={!!unitPlanId}
            />
          )}
          <div className={classes.bodyContainer}>
            {!isAssessmentCreationRevamp && (
              <>
                <SideBar
                  isLocalisedTemplate={isLocalisedTemplate}
                  type={parentType}
                  steps={steps}
                  currentStep={currentStep}
                  onClickStep={this.onClickStep}
                  step_list={step_list}
                  ref={ref => (this.sideBarRef = ref)}
                  localisedTemplateId={localisedTemplateId}
                ></SideBar>
                <MiddlePane
                  localisedTemplateId={localisedTemplateId}
                  curriculumType={curriculumType}
                  notIncludedSteps={notIncludedSteps}
                  configurableSteps={configurableSteps}
                  deleteAttachmentGroups={this.deleteAttachmentGroups}
                  updateAssessmentSections={this.updateAssessmentSections}
                  enabledSections={enabledSections}
                  isLocalisedTemplate={isLocalisedTemplate}
                  createAttachmentGroups={this.createAttachmentGroups}
                  createAttachment={this.createAttachment}
                  onClickPrint={onClickPrint}
                  toggleFocusedField={this.toggleFocusedField}
                  unitPlanTemplate={unitPlanTemplate}
                  subjects={subjects}
                  grades={grades}
                  parentType={parentType}
                  deleteAttachment={this.deleteAttachment}
                  updateFieldInCache={updateFieldInCache}
                  updateField={updateField}
                  resourceId={resourceId}
                  assessmentToolId={assessmentToolId}
                  setStepsRef={this.setStepsRef}
                  unitPlanFields={unitPlanFields}
                  steps={steps}
                  field_list={field_list}
                  step_list={step_list}
                  currentStep={currentStep}
                  warningMsg={warningMsg}
                  clearAssessmentTool={clearAssessmentTool}
                  unitPlanTemplateFieldList={unitPlanTemplateFieldList}
                  resourceFields={resourceFields}
                  onClose={this.onBack}
                  customRef={this.setMiddlePaneRef}
                  onScroll={e => this.onMiddlePaneScroll(e)}
                  unitPlanId={unitPlanId}
                  portalType={portalType}
                  readOnly={
                    !isEditPermission &&
                    ACLStore.can("TeacherPortal:ShowLeReadOnly")
                  }
                  assessmentType={assessmentType}
                  plannerElements={plannerElements}
                  assessmentMode={assessmentMode}
                />
              </>
            )}

            {isAssessmentCreationRevamp && (
              <MiddlePaneV2
                localisedTemplateId={localisedTemplateId}
                curriculumType={curriculumType}
                notIncludedSteps={notIncludedSteps}
                configurableSteps={configurableSteps}
                deleteAttachmentGroups={this.deleteAttachmentGroups}
                updateAssessmentSections={this.updateAssessmentSections}
                enabledSections={enabledSections}
                isLocalisedTemplate={isLocalisedTemplate}
                createAttachmentGroups={this.createAttachmentGroups}
                createAttachment={this.createAttachment}
                onClickPrint={onClickPrint}
                toggleFocusedField={this.toggleFocusedField}
                unitPlanTemplate={unitPlanTemplate}
                subjects={subjects}
                grades={grades}
                parentType={parentType}
                deleteAttachment={this.deleteAttachment}
                updateFieldInCache={updateFieldInCache}
                updateField={updateField}
                resourceId={resourceId}
                assessmentToolId={assessmentToolId}
                setStepsRef={this.setStepsRef}
                unitPlanFields={unitPlanFields}
                steps={steps}
                field_list={field_list}
                step_list={step_list}
                currentStep={currentStep}
                warningMsg={warningMsg}
                clearAssessmentTool={clearAssessmentTool}
                unitPlanTemplateFieldList={unitPlanTemplateFieldList}
                resourceFields={resourceFields}
                onClose={this.onBack}
                customRef={this.setMiddlePaneRef}
                onScroll={e => this.onMiddlePaneScroll(e)}
                unitPlanId={unitPlanId}
                portalType={portalType}
                readOnly={
                  !isEditPermission &&
                  ACLStore.can("TeacherPortal:ShowLeReadOnly")
                }
                assessmentType={assessmentType}
                plannerElements={plannerElements}
                assessmentMode={assessmentMode}
              />
            )}
            {this.renderRightPane()}
          </div>
          {/* <StepHeader
          steps={steps}
          parentType={parentType}
          step_list={step_list}
          currentStep={currentStep}
          setCurrentStep={this.gotToStep}
        /> */}
          {/* <Footer
          isScrollAtBottom={isScrollAtBottom}
          showSaveTemplate={
            _.get(
              _.find(ASSESSMENT_TOOLS, { type: tool }),
              "hasTemplate",
              false
            ) &&
            !!assessmentToolId &&
            currentStep == "s3" &&
            parentType == "ASSESSMENT"
          }
          isMaxContainer={this.isMaxContainer()}
          isOptimum={this.isMaxContainer() && this.props.showRightPane}
          onClose={this.onBack}
          onClickDone={this.onClickSave}
          onClickToolSave={this.onClickToolSave}
          isFirstStep={isFirstStep}
          toolName={_.get(
            _.find(ASSESSMENT_TOOLS, { type: tool }),
            "label",
            ""
          ).toLowerCase()}
          isLastStep={isLastStep}
          onClickNext={this.onClickNext}
          onClickBack={this.onClickBack}
        /> */}
          {showTemplateModal && (
            <DialogueBox
              modalTitle={`Save ${_.get(
                _.find(ASSESSMENT_TOOLS, { type: tool }),
                "label",
                ""
              ).toLowerCase()} template`}
              showModal={true}
              onClickButton2={title => this.saveTool({ tool, title })}
              toggleDialogueBoxDisplay={() =>
                this.setState({ showTemplateModal: false })
              }
              button1={t("common:cancel")}
              button2={t("common:add")}
              isPromptBox={true}
              promptDetail={{
                placeholder: t("unitPlan:enter_template_title"),
                label: t("unitPlan:template_title"),
                checkValidation: true,
              }}
            />
          )}
        </div>

        {showTemplateConfigModal && (
          <UserTemplateSettingsModal
            onClose={toggleShowTemplateConfigModal}
            template={getLECreationRevampTemplate({
              template: originalTemplate,
              assessmentType,
              t,
              curriculumType,
            })}
            modalTitle={"common:configure"}
            templateId={templateId}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  updateField,
  createAttachment,
  deleteAttachment,
  toggleFocusedField,
  setToastMsg,
  clearAssessmentTool,
  isUnitPlanEditPermission,
  updateAssessmentSections,
  createAttachmentGroups,
  deleteAttachmentGroups,
  updateAssessmentInRedux,
};

const getFilteredStepList = ({
  steps,
  step_list,
  enabledSections,
  isAssessmentCreationRevamp,
}) => {
  const stepsList = {
    stepsToShow: [],
    notShownSteps: [],
    configurableSteps: [],
  };

  _.forEach(steps, step => {
    const isConfigurable =
      _.get(step_list[step], "isConfigurable", false) &&
      !isAssessmentCreationRevamp;

    if (isConfigurable) {
      stepsList.configurableSteps.push(step);
    }

    if (
      !isConfigurable ||
      (isConfigurable && _.includes(enabledSections, step))
    ) {
      stepsList.stepsToShow.push(step);
    } else {
      stepsList.notShownSteps.push(step);
    }
  });

  return stepsList;
};

const getUpdatedStepList = stepList => {
  // Responsible to remove studentTemplate

  if (!ACLStore.can("TeacherPortal:StudentTemplateInLE")) {
    const studentTemplateIndex = _.indexOf(
      _.get(stepList, "implementation.fields", []),
      "studentTemplate"
    );

    if (studentTemplateIndex > -1) {
      return update(stepList, {
        implementation: { fields: { $splice: [[studentTemplateIndex, 1]] } },
      });
    }
  }

  return stepList;
};

const getUpdatedStepListMemoize = _.memoize(
  params => getUpdatedStepList(params),
  params => JSON.stringify(params)
);

const mapStateToProps = (state, ownProps) => {
  const {
    template: originalTemplate,
    unitPlanTemplate,
    enabledSections,
    sourceFrom,
    portalType,
    templateId,
    assessmentType,
    t,
    curriculumType,
  } = ownProps;

  const isAssessmentCreationRevampReady = _.get(
    originalTemplate,
    "isAssessmentCreationRevampReady",
    false
  );

  const isAssessmentCreationRevamp = ACLStore.can(
    "FeatureFlag:AssessmentCreationRevamp"
  );

  const userInfo = getUserInfo({ portalType }) || {};
  const userId = userInfo.id;

  const userPreference =
    isAssessmentCreationRevamp &&
    getSettingValue({
      userId,
      name: templateId,
    });

  let isFromClassroom = getIsFromClassroom({
    sourceFrom,
    template: originalTemplate,
  });

  const template =
    isAssessmentCreationRevamp && !isAssessmentCreationRevampReady
      ? getLECreationRevampTemplate({
          t,
          template: originalTemplate,
          assessmentType,
          userPreference,
          isFromClassroom,
          curriculumType,
        })
      : originalTemplate;

  isFromClassroom = getIsFromClassroom({ sourceFrom, template });

  const steps = isFromClassroom
    ? _.get(template, "classroomEditSteps", [])
    : _.get(template, "steps", []);

  const getSteps = getFilteredStepList({
    steps,
    step_list: _.get(template, "step_list", {}),
    enabledSections,
    isAssessmentCreationRevamp,
  });

  return {
    step_list: getUpdatedStepListMemoize(_.get(template, "step_list", {})),
    steps: _.get(getSteps, "stepsToShow", []),
    notIncludedSteps: _.get(getSteps, "notShownSteps", []),
    configurableSteps: _.get(getSteps, "configurableSteps", []),
    field_list: _.get(template, "field_list", {}),
    localisedTemplateId: _.get(template, "localisedTemplateId", ""),
    unitPlanTemplateFieldList: _.get(unitPlanTemplate, "field_list", {}),
    isLocalisedTemplate: _.get(template, "isLocalisedTemplate", true),
    unitPlanTemplate,
    isAssessmentCreationRevamp,
    originalTemplate,
    template,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "common", "unitPlanTemplate"] }),
  connect(mapStateToProps, mapActionCreators)
)(ResourceEdit);
