import React, { useEffect, useRef, useState } from "react";
import { FormTextInput, UIButton, Loading } from "UIComponents";
import classes from "./VisitorSignUp.scss";
import { resetPasswordDetails } from "Administrator/modules/CommonModule";
import { isUserAccountExists } from "Login/modules/LoginModule";
import { connect } from "react-redux";

const VisitorSignUp = props => {
  const {
    updateInputField,
    setVisitorLoginForm,
    checkEmailVerification,
    data,
    customStyle: { headerStyle, subTextStyle },
    renderTradeMarkText,
    updateCurrentScreen,
  } = props;

  const onVisitorLoginLink = () => {
    updateCurrentScreen({
      type: "visitorLogin",
      userType: "staff",
      role: "visitor",
      paramsToBeRemoved: [
        "first_name",
        "last_name",
        "id",
        "email",
        "token",
        "screen",
        "user_id",
      ],
    });
  };

  useEffect(() => {
    const getUserAccountStatus = async () => {
      if (!_.isEmpty(data)) {
        const email = data.email;
        const { stateCode = "" } = await isUserAccountExists({
          email,
          region: data.region,
        });
        if (
          stateCode === "MULTIPLE_IDENTITY_PWD_UPDATED" ||
          stateCode === "SINGLE_IDENTITY_PWD_UPDATED"
        ) {
          setVisitorLoginForm("loginForm", { email });
          onVisitorLoginLink();
        } else {
          setIsLoading(false);
        }
      }
    };
    getUserAccountStatus();
  }, [data]);

  const [isLoading, setIsLoading] = useState(true);

  const emailRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();

  const handleSignIn = () => {
    const { password } = data;
    const errorCount = +_.isEmpty(password);
    const { showErrorToast, sendResetPassword } = props;
    showErrorToast({ errorCount });
    if (errorCount > 0) return;
    sendResetPassword();
  };

  const { email, name, password } = data;

  const signupFormElement = (
    <div className={classes.container}>
      <div
        className={classes.header}
        style={headerStyle}
      >{`Sign up for Toddle`}</div>
      <div
        className={classes.subHeader}
        style={subTextStyle}
      >{`Programme evaluation team member sign up`}</div>
      <div className={classes.innerContainer}>
        <div className={classes.inputsContainer}>
          <div className={classes.inputContainer}>
            <FormTextInput
              ref={ref => (emailRef.current = ref)}
              textInputLabel={"Email"}
              value={email}
              name={"email"}
              placeholder={"Enter your email"}
              updateInputField={updateInputField}
              disabled={true}
              customValidation={checkEmailVerification}
              size={"large"}
            />
          </div>
          <div className={classes.inputContainer}>
            <FormTextInput
              ref={ref => (usernameRef.current = ref)}
              textInputLabel={"Your name"}
              value={name}
              name={"name"}
              updateInputField={updateInputField}
              disabled={true}
              size={"large"}
            />
          </div>
          <div className={classes.inputContainer}>
            <FormTextInput
              ref={ref => (passwordRef.current = ref)}
              textInputLabel={"Create password*"}
              placeholder={"Enter password"}
              name={"password"}
              value={password}
              type={"password"}
              updateInputField={updateInputField}
              size={"large"}
              error={"Enter password"}
              onEnter={handleSignIn}
            />
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.accountText}>
            {`Already have a programme evaluation team member account?`}
            <div
              className={classes.signInText}
              onClick={onVisitorLoginLink}
            >{`Sign in`}</div>
          </div>
          <UIButton
            size={"sm"}
            type={"filled"}
            color={"pink"}
            onClick={handleSignIn}
          >{`Sign up`}</UIButton>
        </div>
      </div>
      {renderTradeMarkText({
        align: "left",
      })}
    </div>
  );

  const loaderElement = (
    <div className={classes.loaderContainer}>
      <Loading />
    </div>
  );

  return isLoading ? loaderElement : signupFormElement;
};

const mapActionCreators = {
  resetPasswordDetails,
};

export default connect(null, mapActionCreators)(VisitorSignUp);
