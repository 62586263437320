import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";

import classes from "./AddChildModal.scss";
import {
  I18nHOC,
  InputTextField,
  UIModal,
  UIButton,
  FullScreenLoader,
} from "UIComponents";

import { addChild } from "Courses/modules/CoursesModule";
import { IconButton } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";

const renderErrorComponent = error => {
  return <div className={classes.errorText}>{error}</div>;
};

const AddChildModal = props => {
  const { t, setSuccessModal, setAddChildModal, setChildDetails } = props;

  // local state's
  const [studentCode, setStudentCode] = useState("");
  const [showCustomError, setShowCustomError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);

  // ref
  const inputRef = useRef();

  // handle input field
  const handleInputCode = value => {
    setStudentCode(value.studentCode);
  };

  // validation function
  const customValidation = () => {
    if (showCustomError) {
      return errorMessage;
    }
  };

  // onClick function's
  const onCloseClick = () => {
    setAddChildModal(false);
    setStudentCode("");
  };

  const onSubmitClick = async () => {
    const { addChild, userId } = props;

    if (!studentCode) {
      inputRef.current.isValid();
    } else {
      // mutation
      setShowFullScreenLoader(true);
      const childData = await addChild({
        studentCode,
        userId,
        signInFlow: false,
        setErrorMessage,
      });
      setShowFullScreenLoader(false);

      if (!_.isEmpty(childData)) {
        // update state's
        setChildDetails(childData);
        setStudentCode("");
        setAddChildModal(false);
        setSuccessModal(true);
      } else {
        // display error
        setShowCustomError(true);
        inputRef.current.isValid();
      }
    }
  };

  return (
    <React.Fragment>
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={onCloseClick}
      >
        <div className={classes.modalContainer}>
          <div className={classes.header}>
            <div className={classes.headerTitle}>
              {t("common:add_with_label", { label: t("common:child") })}
            </div>
            <IconButton
              onClick={onCloseClick}
              icon={<CloseOutlined />}
              variant="plain"
              size="large"
            />
          </div>
          <div className={classes.description}>
            {t("common:add_another_child")}
          </div>
          <div className={classes.inputField}>
            <InputTextField
              placeholder={t("common:enter_with_label", {
                label: t("common:family_invite_code"),
              })}
              name={"studentCode"}
              value={studentCode}
              updateInputField={handleInputCode}
              ref={inputRef}
              showErrorText={true}
              renderErrorComponent={renderErrorComponent}
              customValidation={customValidation}
              error={t("common:enter_family_code_to_proceed")}
            />
          </div>
          <div className={classes.button}>
            <UIButton color="blue" onClick={onSubmitClick}>
              {t("common:add_with_label", { label: t("common:child") })}
            </UIButton>
          </div>
        </div>
      </UIModal>
      {showFullScreenLoader && <FullScreenLoader />}
    </React.Fragment>
  );
};

const mapActionCreators = {
  addChild,
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: _.get(state, "login.userInfo.id", ""),
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({
    resource: ["common"],
  })
)(AddChildModal);
