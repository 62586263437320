/**
 *
 * This utility returns the memoized form of a function
 *
 * @param {*} fn - the function that needs to be memoized
 * @returns - the memoized function
 */

export const getMemoizedFunction = fn => {
  return _.memoize(fn, params => JSON.stringify(params));
};
