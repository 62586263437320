import PropTypes from "prop-types";
import React from "react";

const ChevronIcon = props => {
  return (
    <svg viewBox="0 0 7 13" width={props.width} height={props.height}>
      <g
        transform="translate(-4.000000, -2.000000)"
        fill={props.fill && props.fill}
      >
        <path
          d="M1.19649491,11.8035051 C1.40647714,12.0134873 1.7478598,12.0134873 1.95784203,11.8035051 L7.50056534,6.26078178 L13.042158,11.8035051 C13.2521402,12.0134873 13.5935229,12.0134873 13.8035051,11.8035051 C14.0134873,11.5935229 14.0134873,11.2521402 13.8035051,11.042158 L7.88071394,5.11823615 C7.77515749,5.01372961 7.63729607,4.96099176 7.50056534,4.96099176 C7.36270393,4.96099176 7.22484251,5.01372961 7.11928606,5.11823615 L1.19649491,11.042158 C0.98651268,11.2521402 0.98651268,11.5935229 1.19649491,11.8035051 Z"
          id="Chevron_up-"
          transform="translate(7.500000, 8.460992) rotate(270.000000) translate(-7.500000, -8.460992) "
        />
      </g>
    </svg>
  );
};

ChevronIcon.defaultProps = {
  height: 13,
  width: 7,
};

ChevronIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
};

export default ChevronIcon;
