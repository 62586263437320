import React from "react";
import classNames from "classnames";
import classes from "./StepHeader.scss";

const StepHeader = React.memo(props => {
  const { steps, currentStep, onClickStep } = props;

  return (
    <div className={classes.container}>
      {_.map(steps, step => {
        const { label, value } = step;
        const stepClass = classNames(
          { [classes.step]: true },
          {
            [classes.activeStep]: value == currentStep,
          }
        );
        return (
          <div
            className={stepClass}
            key={value}
            onClick={() => onClickStep(value)}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
});

export default StepHeader;
