import React from "react";
import classes from "./DropDown.scss";
import { ButtonDropdown } from "UIComponents";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
const buttonDropDownContainerStyle = {
  top: 24,
  width: 120,
  right: 0,
};

const buttonDropDownItemStyle = {
  height: 32,
};

const DropDown = ({
  item,
  onClickDelete,
  onClickEdit,
  onUpdateShowDropDown,
}) => {
  const menuItems = [
    {
      label: "Edit",
      clickAction: () => {
        onClickEdit();
      },
      svg: null,
      isDisable: false,
      style: {
        borderWidth: 0,
      },
    },
    {
      label: "Delete",
      clickAction: () => {
        onClickDelete();
      },
      svg: null,
      isDisable: false,
    },
  ];

  return (
    <div className={classes.container}>
      <ButtonDropdown
        onUpdateShowDropDown={onUpdateShowDropDown}
        authTabs={menuItems}
        containerStyle={buttonDropDownContainerStyle}
        itemStyle={buttonDropDownItemStyle}
        buttonComponent={
          <MoreIcon width={16} height={16} fill={colors.gray48} />
        }
      />
    </div>
  );
};

export default DropDown;
