import React from "react";
import classes from "./LockScreen.scss";
import HeroComponent from "../HeroComponent";
import {
  LOCKED_SCREEN_CONFIG,
  ProductFeatures,
} from "modules/PermissionModule";
import ACLStore from "lib/aclStore";
import { connect } from "react-redux";
import { goToBack } from "modules/NavigationModule";
import Features from "../Features";
import CTAComponent from "../CTAComponent";
import { scrollToWithAnimation } from "Utils";

class LockScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.featuresRef = null;
  }

  onClickScrollDown = () => {
    if (this.scrollContainer && this.featuresRef) {
      const { top } = this.featuresRef.getBoundingClientRect();
      scrollToWithAnimation(this.scrollContainer, top);
    }
  };

  render() {
    const {
      featureKey,
      goToBack,
      createPlanUpgradeRequest,
      onSeePlansClicked,
    } = this.props;
    const lockedScreenObject = LOCKED_SCREEN_CONFIG[featureKey];
    const {
      isFullScreen = true,
      hasScrollable = false,
      showFeatures = false,

      productType,
    } = lockedScreenObject;

    const isAdmin = ACLStore.can("AdminPortal");
    const productFeatureObj = ProductFeatures[productType];
    return (
      <div
        className={classes.container}
        ref={ref => (this.scrollContainer = ref)}
        style={{
          padding: isFullScreen ? 0 : 40,
          overflowY: hasScrollable ? "auto" : "unset",
        }}
      >
        <HeroComponent
          {...lockedScreenObject}
          showScrollDownIcon={hasScrollable}
          createPlanUpgradeRequest={createPlanUpgradeRequest}
          isAdmin={isAdmin}
          goToBack={goToBack}
          onClickScrollDown={this.onClickScrollDown}
          isFullScreen={isFullScreen}
          onSeePlansClicked={onSeePlansClicked}
        ></HeroComponent>
        {showFeatures && (
          <div ref={ref => (this.featuresRef = ref)}>
            <Features {...productFeatureObj}></Features>
          </div>
        )}
        {showFeatures && (
          <CTAComponent
            createPlanUpgradeRequest={createPlanUpgradeRequest}
          ></CTAComponent>
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  goToBack,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapActionCreators)(LockScreen);
