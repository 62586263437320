import React, { createRef } from "react";
import classes from "./AttachmentItemV2.scss";
import {
  getThumbUrl,
  getThumbnailIcon,
  formatBytes,
  getLabelValue,
  htmlToText,
} from "Utils";
import { compose } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import { EyeIcon, FeedbackResponseIcon } from "SvgComponents";
import { colors } from "Constants";
import { I18nHOC, LinkWithTooltip, AudioRecorderSelector } from "UIComponents";
import { downloadFromUrl } from "modules/Services";
import { ModelContent } from "../AttachmentCard/AttachmentCard";
import {
  ArrowOutOutlined,
  DownloadOutlined,
  JournalAddOutlined,
  DeleteOutlined,
} from "@toddle-design/web-icons";

class AttachmentItemV2 extends React.PureComponent {
  state = {
    showMediaModal: false,
  };

  constructor(props) {
    super(props);
    this.attachmentItemRef = createRef(null);
  }

  showAttachment = () => {
    this.setState({ showMediaModal: true });
  };

  toggleMediaModal = value => {
    this.setState({ showMediaModal: value });
  };

  onClickAttachment = e => {
    e.stopPropagation();
    const { showMediaModal } = this.state;
    const {
      attachment,
      onClickAttachmentCard,
      mode,
      updateSelectedItems,
      isSelected,
      attachmentGroup,
    } = this.props;

    if (onClickAttachmentCard) {
      onClickAttachmentCard(attachment.id, attachmentGroup);
    } else {
      if (mode === "select") {
        updateSelectedItems({ attachment, isSelected });
      } else {
        if (!showMediaModal) {
          this.setState({ showMediaModal: true });
        }
      }
    }
  };

  onDownloadClick = e => {
    const { downloadFromUrl, attachment } = this.props;
    e.stopPropagation();
    downloadFromUrl({
      attachment: attachment,
      shouldAskForSave: true,
    });
  };

  onClickOpenInNewTab = e => {
    const { attachment } = this.props;
    e.stopPropagation();
    const attachmentUrl = _.get(attachment, "url", "");
    if (attachmentUrl) window.open(attachmentUrl, "_blank");
  };

  onClickCreatePostLocal = e => {
    e.stopPropagation();
    const { attachment, onClickCreatePost, disableActions } = this.props;

    if (disableActions) return;

    if (onClickCreatePost) {
      onClickCreatePost(attachment);
    }
  };

  onDeleteClick = e => {
    const { deleteAttachment, attachmentGroup } = this.props;
    const attachmentGroupId = _.get(attachmentGroup, "id", "");
    e.stopPropagation();
    if (deleteAttachment) {
      deleteAttachment(attachmentGroupId);
    }
  };

  render() {
    const {
      attachment,
      mode,
      activeAttachment,
      horizontalCardStyle = {},
      t,
      showCreatePostButton,
      showOpenLinkInNewTabIcon,
      showInlineAudioPlayer,
      isDisabledMode,
      showThumb,
      attachmentBottomComponent,
      horizontalCardInnerClass,
      disableActions,
      attachmentResponseObject,
      attachmentGroup,
      showDeleteForSelf,
      userId,
      parentId,
      parentType,
      isLastAttachment,
    } = this.props;
    const { showMediaModal } = this.state;
    const { type, id, metadata, mimeType, createdBy } = attachment;
    const fileSize = metadata?.size ?? 0;
    const iconSvg = getThumbnailIcon({ type, mimeType });
    const attachmentGroupId = _.get(attachmentGroup, "id", "");
    const isFeedbackGiven = _.get(attachmentResponseObject, attachmentGroupId);

    const { thumbUrl, isIcon, color } = getThumbUrl({
      attachment: attachment,
      width: 40,
      height: 40,
    });

    const isActiveAttachment = activeAttachment
      ? activeAttachment == _.get(attachmentGroup, "attachmentId") ||
        activeAttachment == id
      : false;

    const containerClass = classNames(
      { [classes.container]: true },
      {
        [classes.activeCon]: isActiveAttachment,
      }
    );

    const actionIconClass = classNames(
      { [classes.actionIconCon]: true },
      { [classes.showActionIconCon]: isActiveAttachment }
    );

    const addToJournalClasses = classNames({
      [classes.actionIconCon]: true,
      [classes.showActionIconCon]: isActiveAttachment,
      disabled: disableActions,
    });

    const attachmentContainerClass = classNames(
      { [classes.attachmentContainer]: true },
      { [horizontalCardInnerClass]: !!horizontalCardInnerClass }
    );

    const progressValue = parseInt(_.get(attachment, `progress`, "101"));

    const nameTooltipStyle = { maxWidth: 780, maxHeight: 400 };

    //The progress Value is between 0-100 when its being uploaded, and once uploaded
    //the attachment doesn't have progress in it, so by default its 101, which means its created
    const isAttachmentCreated = progressValue > 100;
    if (showInlineAudioPlayer) {
      return (
        <div
          className={classes.audioContainer}
          onClick={progressValue >= 100 ? this.onClickAttachment : null}
        >
          <AudioRecorderSelector
            mode={mode}
            value={attachment}
            onDeleteClick={this.onDeleteClick}
            parentId={parentId}
            parentType={parentType}
            isLastAttachment={isLastAttachment}
          />
        </div>
      );
    }

    return (
      <div className={classes.mainContainer}>
        <div className={containerClass} style={horizontalCardStyle}>
          {isFeedbackGiven && (
            <span className={classes.notificationCounter}>
              <FeedbackResponseIcon
                width={16}
                height={16}
                fill={colors.white}
              />
            </span>
          )}

          <div
            className={attachmentContainerClass}
            onClick={
              isDisabledMode || !isAttachmentCreated
                ? null
                : this.onClickAttachment
            }
            ref={this.attachmentItemRef}
          >
            {showThumb ? (
              <div
                className={classes.attachmentImageContainer}
                style={{
                  backgroundImage: `url(${thumbUrl})`,
                  backgroundColor: color,
                  backgroundSize: isIcon ? "35%" : "cover",
                }}
              />
            ) : (
              <div className={classes["file-icon-container"]}>
                <span className={classes["file-icon-box"]}>{iconSvg}</span>
              </div>
            )}

            <div className={classes.rightContainer}>
              <div className={classes.detailContainer}>
                <LinkWithTooltip
                  tooltipContainerStyle={nameTooltipStyle}
                  tooltip={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: htmlToText(
                          getLabelValue({ item: attachment, t }),
                          {
                            wordwrap: 130,
                            tags: {
                              a: { options: { ignoreHref: true } },
                              ul: { options: { itemPrefix: " " } },
                            },
                          }
                        ),
                      }}
                    />
                  }
                  href={"#"}
                  placement="top"
                >
                  <div
                    className={classes.nameText}
                    dangerouslySetInnerHTML={{
                      __html: htmlToText(
                        getLabelValue({ item: attachment, t }),
                        {
                          wordwrap: 130,
                          tags: {
                            a: { options: { ignoreHref: true } },
                            ul: { options: { itemPrefix: " " } },
                          },
                        }
                      ),
                    }}
                  />
                </LinkWithTooltip>
                <div className={classes.typeText}>
                  {type !== "WORKBOOK" && type}
                  {type === "WORKBOOK" &&
                  _.get(attachment, "metadata.numOfPages", 0)
                    ? `${t("common:workbook_page", {
                        count: _.get(attachment, "metadata.numOfPages", 1),
                      })}`
                    : fileSize > 0
                    ? ` (${formatBytes({ bytes: fileSize, decimals: 1 })})`
                    : null}
                </div>
              </div>

              {mode == "view" && showCreatePostButton && (
                <div
                  className={addToJournalClasses}
                  onClick={this.onClickCreatePostLocal}
                >
                  <LinkWithTooltip
                    tooltip={t("journal:add_to_journal")}
                    placement={"bottom"}
                    href="#"
                    id="tooltip-CreatePostSvg"
                  >
                    <div className={classes.showActionIconCon}>
                      <JournalAddOutlined
                        size={"xxx-small"}
                        variant={"subtle"}
                      />
                    </div>
                  </LinkWithTooltip>
                </div>
              )}

              {/* remove "open in new tab" option for workbook attachments */}
              {mode == "view" &&
                showOpenLinkInNewTabIcon &&
                type !== "WORKBOOK" && (
                  <div
                    className={actionIconClass}
                    onClick={this.onClickOpenInNewTab}
                  >
                    <LinkWithTooltip
                      tooltip={t("common:open_in_new_tab")}
                      placement={"bottom"}
                      href="#"
                      id="tooltip-OpenLinkSvg"
                    >
                      <ArrowOutOutlined size={"xxx-small"} variant={"subtle"} />
                    </LinkWithTooltip>
                  </div>
                )}

              {isAttachmentCreated &&
                (mode == "edit" ||
                  (showDeleteForSelf && userId == _.get(createdBy, "id"))) && (
                  <div
                    className={classes.deleteSvgIcon}
                    onClick={this.onDeleteClick}
                  >
                    <LinkWithTooltip
                      tooltip={t("common:delete")}
                      placement={"bottom"}
                      href="#"
                      id="tooltip-DeleteIcon"
                    >
                      <DeleteOutlined size={"xxx-small"} variant={"subtle"} />
                    </LinkWithTooltip>
                  </div>
                )}

              {/* remove "download" button for workbook attachments */}
              {isAttachmentCreated &&
                (mode == "view" || mode == "edit") &&
                type !== "LINK" &&
                type !== "WORKBOOK" && (
                  <div
                    className={actionIconClass}
                    onClick={this.onDownloadClick}
                  >
                    <LinkWithTooltip
                      tooltip={t("common:download")}
                      placement={"bottom"}
                      href="#"
                      id="tooltip-DownloadIcon"
                    >
                      <div className={classes.showActionIconCon}>
                        <DownloadOutlined
                          size={"xxx-small"}
                          variant={"subtle"}
                        />
                      </div>
                    </LinkWithTooltip>
                  </div>
                )}

              {mode === "select" && (
                <div
                  className={actionIconClass}
                  onClick={e => {
                    e.stopPropagation();
                    this.showAttachment();
                  }}
                >
                  <EyeIcon />
                </div>
              )}
              <ModelContent
                attachments={[attachment]}
                toggleMediaModal={this.toggleMediaModal}
                showMediaModal={showMediaModal}
                mode={mode}
              />
            </div>
          </div>
        </div>
        {_.isFunction(attachmentBottomComponent) &&
          attachmentBottomComponent({
            recheckButtonHoverClass: classes.recheckButton,
          })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { attachment } = ownProps;
  const userId = state.login.userInfo.id;

  if (_.get(attachment, "isPost", false)) {
    const attchementObj = _.last(attachment.attachments);
    const isWorkbook = _.isEqual(_.get(attchementObj, "type", ""), "WORKBOOK");
    if (isWorkbook) {
      return {
        attachment: { ...attchementObj, isPost: true },
        attachmentGroup: attachment,
        userId,
      };
    } else {
      return {
        attachment: { ...attchementObj, id: attachment.id, isPost: true },
        attachmentGroup: attachment,
        userId,
      };
    }
  }
  return {
    userId,
    attachment,
    attachmentGroup: attachment,
  };
};
const mapActionCreators = { downloadFromUrl };

AttachmentItemV2.defaultProps = {
  showThumb: false,
  showDownloadOnEdit: true,
  showDeleteIcon: false,
  showDeleteForSelf: false,
};

export default compose(
  I18nHOC({ resource: ["common", "journal"] }),
  connect(mapStateToProps, mapActionCreators, null, { withRef: true })
)(AttachmentItemV2);
