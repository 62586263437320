import { withAsyncRouteLoading } from "UIComponents";
import OnBoarding from "./routes/OnBoarding";
import Home from "./routes/Home";
import UnitPlanDetails from "UnitPlanDetails/index.v2";
import AssessmentDetails from "Assessments/components/AssessmentDetails/index.v2";
import { injectReducer } from "store/reducers";

export default store => {
  return {
    path: "community",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("Community/modules/CommunityModule")
                .default;
              injectReducer(store, { key: "community", reducer });

              const communityParentEntityReducer = require("CommunityParentEntity/modules/CommunityParentEntityModule")
                .default;
              injectReducer(store, {
                key: "communityParentEntity",
                reducer: communityParentEntityReducer,
              });

              const classRoomReducer = require("ClassRoom/modules/ClassRoomModule")
                .default;
              injectReducer(store, {
                key: "classRoom",
                reducer: classRoomReducer,
              });

              const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
                .default;
              injectReducer(store, {
                key: "unitPlans",
                reducer: unitPlanModule,
              });

              const assessmentModule = require("Assessments/modules/AssessmentModule");
              injectReducer(store, {
                key: assessmentModule.NAME,
                reducer: assessmentModule.default,
              });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community"
          );
        })
    ),
    childRoutes: [
      OnBoarding(store),
      Home(store),
      AssessmentDetails(store),
      UnitPlanDetails(store),
    ],
  };
};
