import gql from "graphql-tag";
import {
  attachmentFragment,
  studentFragment,
  userFragment,
  resolvedFieldValueFragment,
  toolResponseFragment,
  plannerTemplateFragment,
  pypElementRatingFragment,
} from "modules/CommonFragments";

import { folderFragments } from "Journal/modules/JournalFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";

const taggedPracticesFragment = {
  taggedPracticesDetails: gql`
    fragment taggedPracticesDetails on SnPTaggedPractice {
      evaluationCycle {
        id
      }
      taggedPractice {
        taggedBy {
          id
        }
        evidenceStatus
        practice {
          id
          code
          type
          label: localeLabelKey
          localizedLabel: label
        }
      }
    }
  `,
};

export const unitPlanFragment = {
  unitPlan: gql`
    fragment unitPlanItem on UnitPlan {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      unitType {
        id
        value
      }
      subjects {
        id
        value
      }

      templateId
    }
  `,
  unitPlanDetails: gql`
    fragment unitPlanItem on UnitPlan {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      centralIdea {
        id
        value
      }
      unitType {
        id
        value
      }
      subjects {
        id
        value
      }
      theme {
        id
        value
      }
      resourceLibrary {
        leCount
        assessmentCount
      }

      grades
      fields(
        uids: ["lp", "centralIdea", "concepts", "atls", "benchmarks", "lois"]
      ) {
        id
        uid
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      templateId
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  unitPlanDetailsWithoutFields: gql`
    fragment unitPlanItem on UnitPlan {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      centralIdea {
        id
        value
      }
      unitType {
        id
        value
      }
      subjects {
        id
        value
      }
      theme {
        id
        value
      }
      resourceLibrary {
        leCount
        assessmentCount
      }
      grades
      templateId
    }
  `,
};

export const likefragment = {
  like: gql`
    fragment likeItem on Like {
      id
      user {
        id
        firstName
        lastName
        profileImage
      }
    }
  `,
};

export const postSeenFragment = {
  postSeen: gql`
    fragment postSeenItem on Seen {
      id
      user {
        id
        firstName
        lastName
        profileImage
      }
    }
  `,
};
export const journalPostFragment = {
  journalPost: gql`
    fragment postItem on Post {
      id
      title
      attachments {
        ...attachmentItem
      }
      createdBy {
        ...userItem
      }
      createdAt
      publishedAt
      comments {
        totalCount
        unreadCount
      }
      likes {
        totalCount
        edges {
          node {
            ...likeItem
          }
        }
      }
      seen {
        totalCount
        edges {
          node {
            ...postSeenItem
          }
        }
      }
      state
      students {
        totalCount
        edges {
          node {
            ...studentItem
          }
        }
      }
      elementPYP {
        id
        fieldUID
        value
      }
      folders {
        ...folderItem
      }
      taggedPractices {
        ...taggedPracticesDetails
      }
      practices {
        id
        label: localeLabelKey
        code
      }
      isPrivate
      isHiddenFromStudents
      rejectComment
      approveOrRejectBy {
        ...userItem
      }
      items {
        item {
          ... on StudentAssignment {
            id
            isEvaluated
            isSharedWithStudent
            student {
              ...studentItem
            }
            submission {
              id
              response
              attachmentGroups {
                id
                attachments {
                  ...attachmentItem
                }
              }
            }
            toolResponses(userFilter: ALL) {
              ...toolResponseItem
            }
            ibPYPElementRatings {
              ...ratingItem
            }
            assignment {
              id
              isStudentEvaluationEnabled
              isTeacherEvaluationEnabled
              content {
                ... on Assessment {
                  id
                  title {
                    id
                    value
                  }
                  assessmentType {
                    id
                    value
                  }
                  assessmentTool {
                    id
                  }
                  assessmentToolType
                }
              }
            }
          }
        }
        itemType
      }
    }
    ${studentFragment.basicStudentDetails}
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${likefragment.like}
    ${postSeenFragment.postSeen}
    ${taggedPracticesFragment.taggedPracticesDetails}
    ${folderFragments.folderItem}
    ${toolResponseFragment.toolResponse}
    ${pypElementRatingFragment.studentElementRating}
  `,
  journalPostFeed: gql`
    fragment postItem on Post {
      id
      title
      titleTranslationItem(filters: $translationFilter) {
        label
        showTranslate
        sourceLanguage
        translatedLabel
      }
      attachments {
        ...attachmentItem
      }
      createdBy {
        ...userItem
      }
      createdAt
      publishedAt
      comments {
        totalCount
        unreadCount
      }
      likes {
        totalCount
        edges {
          node {
            ...likeItem
          }
        }
      }
      seen {
        totalCount
        edges {
          node {
            ...postSeenItem
          }
        }
      }
      state
      students {
        totalCount
        edges {
          node {
            ...studentItem
          }
        }
      }
      resourceType
      resourceItem {
        id
        title {
          id
          value
        }
        templateId
        ... on Assessment {
          assessmentType {
            id
            value
          }
        }
      }
      elementPYP {
        id
        fieldUID
        value
      }
      folders {
        ...folderItem
      }
      taggedPractices {
        ...taggedPracticesDetails
      }
      practices {
        id
        label: localeLabelKey
        code
      }
      isPrivate
      isHiddenFromStudents
      rejectComment
      approveOrRejectBy {
        ...userItem
      }
      items {
        item {
          ... on StudentAssignment {
            id
            isEvaluated
            isSharedWithStudent
            student {
              ...studentItem
            }
            submission {
              id
              response
              attachmentGroups {
                id
                attachments {
                  ...attachmentItem
                }
              }
            }
            toolResponses(userFilter: ALL) {
              ...toolResponseItem
            }
            ibPYPElementRatings {
              ...ratingItem
            }
            assignment {
              id
              isStudentEvaluationEnabled
              isTeacherEvaluationEnabled
              content {
                ... on Assessment {
                  id
                  title {
                    id
                    value
                  }
                  assessmentType {
                    id
                    value
                  }
                  assessmentTool {
                    id
                  }
                  assessmentToolType
                  fields(uids: $uids) {
                    id
                    uid
                    value
                    resolvedMinimalTree {
                      ...resolvedMinimalTreeItem
                    }
                  }
                }
              }
            }
          }
        }
        itemType
      }
    }
    ${studentFragment.basicStudentDetails}
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${likefragment.like}
    ${postSeenFragment.postSeen}
    ${taggedPracticesFragment.taggedPracticesDetails}
    ${folderFragments.folderItem}
    ${toolResponseFragment.toolResponse}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${pypElementRatingFragment.studentElementRating}
  `,
  journalPostDetail: gql`
    fragment postItem on Post {
      id
      title
      titleTranslationItem(filters: $translationFilter) {
        label
        showTranslate
        sourceLanguage
        translatedLabel
      }
      attachments {
        ...attachmentItem
      }
      createdBy {
        ...userItem
      }
      comments {
        totalCount
        unreadCount
      }
      likes {
        totalCount
        edges {
          node {
            ...likeItem
          }
        }
      }
      seen {
        totalCount
        edges {
          node {
            ...postSeenItem
          }
        }
      }
      createdAt
      publishedAt
      students {
        totalCount
        edges {
          node {
            ...studentItem
          }
        }
      }
      unitPlan {
        allFields {
          ...unitPlanAllFields
        }
        ...unitPlanItem
      }
      resourceType
      resourceItem {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        templateId
        ... on Assessment {
          assessmentType {
            id
            value
          }
        }
      }
      elementPYP {
        id
        fieldUID
        value
        parentType
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      folders {
        ...folderItem
      }
      taggedPractices {
        ...taggedPracticesDetails
      }
      practices {
        id
        label: localeLabelKey
        code
      }
      state
      isPrivate
      isDeleted
      isHiddenFromStudents
      rejectComment
      items {
        item {
          ... on StudentAssignment {
            id
            isEvaluated
            isSharedWithStudent
            student {
              ...studentItem
            }
            submission {
              id
              response
              attachmentGroups {
                id
                attachments {
                  ...attachmentItem
                }
              }
            }
            toolResponses(userFilter: ALL) {
              ...toolResponseItem
            }
            ibPYPElementRatings {
              ...ratingItem
            }
            elementRemarks {
              id
              message
              ibPYPElementId
              ibPYPElementType
            }
            assignment {
              id
              isStudentEvaluationEnabled
              isTeacherEvaluationEnabled
              content {
                ... on Assessment {
                  id
                  title {
                    id
                    value
                  }
                  assessmentType {
                    id
                    value
                  }
                  assessmentTool {
                    id
                  }
                  assessmentToolType
                  allFields {
                    id
                    uid
                    value
                    resolvedMinimalTree {
                      ...resolvedMinimalTreeItem
                    }
                  }
                  template {
                    ...templateItem
                  }
                }
              }
            }
          }
        }
        itemType
      }
      curriculumProgram {
        id
        type
      }
    }
    ${studentFragment.basicStudentDetails}
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
    ${unitPlanFragment.unitPlanDetails}
    ${folderFragments.folderItem}
    ${likefragment.like}
    ${postSeenFragment.postSeen}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${taggedPracticesFragment.taggedPracticesDetails}
    ${toolResponseFragment.toolResponse}
    ${plannerFieldFragment.unitPlanAllFields}
    ${plannerTemplateFragment.plannerTemplate}
    ${pypElementRatingFragment.studentElementRating}
  `,
  translationPostItem: gql`
    fragment translationPostItem on Post {
      id
      title
      translatedText: titleTranslationItem(filters: $translationFilter) {
        label
        translatedLabel
      }
    }
  `,
  postSeenLikesDetails: gql`
    fragment postItem on Post {
      id
      title
      students {
        edges {
          node {
            ...userItem
          }
        }
      }
      likes {
        totalCount
        edges {
          node {
            id
            user {
              id
              type
              ...userItem
              ... on FamilyMember {
                children {
                  ...userItem
                }
              }
            }
          }
        }
      }
      seen {
        totalCount
        edges {
          node {
            id
            user {
              id
              type
              ...userItem
              ... on FamilyMember {
                children {
                  ...userItem
                }
              }
            }
          }
        }
      }
    }
    ${userFragment.basicUserDetails}
  `,
  postSelection: gql`
    fragment postSelectionItem on Post {
      id
      title
      attachments {
        ...attachmentItem
      }
      createdBy {
        ...userItem
      }
      items {
        item {
          id
          ... on StudentAssignment {
            id
            assignment {
              id
              content {
                id
                ... on Assessment {
                  id
                  assessmentType {
                    id
                    value
                  }
                }
              }
            }
            submission {
              id
              attachmentGroups {
                id
                attachments {
                  ...attachmentItem
                }
              }
            }
          }
        }
        itemType
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
  `,
};

export const parentPostFragment = {
  parentPost: gql`
    fragment parentItem on FamilyMember {
      id
      posts(last: 5, after: $after, filters: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...postItem
          }
        }
      }
    }
    ${journalPostFragment.journalPost}
  `,
};

export const coursePostFragment = {
  coursePost: gql`
    fragment courseItem on Course {
      id
      posts(last: 5, after: $after, filters: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...postItem
          }
        }
      }
    }
    ${journalPostFragment.journalPostFeed}
  `,
};

export const courseStudentFragment = {
  courseStudent: gql`
    fragment courseItem on Course {
      id
      postCount(
        folderIds: $folderIds
        isShowHiddenFromStudents: $isShowHiddenFromStudents
      ) {
        draft
        published
        inApproval
        unTagged {
          draft
          published
          inApproval
        }
      }
      students: studentPostCount(
        folderIds: $folderIds
        includeRemovedStudentIds: $includeRemovedStudentIds
        archivalState: ALL
        isShowHiddenFromStudents: $isShowHiddenFromStudents
      ) {
        postCount
        draft
        published
        inApproval
        node: student {
          ...studentItem
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
  courseFiltersStudent: gql`
    fragment courseItem on Course {
      id
      students(filters: $filters) {
        edges {
          node {
            ...studentItem
          }
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
};

export const resourceFragments = {
  assessmentItem: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      assessmentType {
        id
        uid
        value
      }
    }
  `,
  leItem: gql`
    fragment leItem on LearningEngagement {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
    }
  `,
  assessmentDetails: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      allFields {
        id
        uid
        value
      }
      fields(
        uids: ["lp", "centralIdea", "concepts", "atls", "benchmarks", "lois"]
      ) {
        id
        uid
        value
      }
      assessmentType {
        id
        uid
        value
      }
    }
  `,
  leDetails: gql`
    fragment leItem on LearningEngagement {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      fields(
        uids: ["lp", "centralIdea", "concepts", "atls", "benchmarks", "lois"]
      ) {
        id
        uid
        value
      }
      allFields {
        id
        uid
        value
      }
    }
  `,
  assessmentDetailsVariable: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      allFields {
        id
        uid
        value
      }
      assessmentType {
        id
        uid
        value
      }
    }
  `,
  leDetailsVariable: gql`
    fragment leItem on LearningEngagement {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      allFields {
        id
        uid
        value
      }
    }
  `,
};

export const studentPostFragment = {
  studentPost: gql`
    fragment studentPostItem on Student {
      id
      posts(last: 5, after: $after, filters: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...postItem
          }
        }
      }
    }
    ${journalPostFragment.journalPostFeed}
  `,
};

export const nodePostSelectionFragment = {
  organizationPost: gql`
    fragment organizationPostItem on Organization {
      id
      posts(last: 20, after: $after, filters: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...postSelectionItem
          }
        }
      }
    }
    ${journalPostFragment.postSelection}
  `,
  studentPost: gql`
    fragment studentPostItem on Student {
      id
      posts(last: 21, after: $after, filters: $filters) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...postSelectionItem
          }
        }
      }
    }
    ${journalPostFragment.postSelection}
  `,
};
