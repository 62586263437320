import React, { useRef, useState } from "react";
import classes from "./StepContainer.scss";
import { I18nHOC, NoDataComponent } from "UIComponents";

import { connect } from "react-redux";
import { compose } from "react-apollo";

import {
  UnitSelection,
  UnitFromGradeSelection,
  PlannerResourceSelection,
  LEPreview,
  LeFeed,
  GradeOrCourseSelection,
} from "AppComponents";
import { AssessmentDetailsComponent } from "Assessments";
import DiscussionStep from "../DiscussionStep";
import ShareSettingsStep from "../ShareSettingsStep";
import ShareSettingsStepV2 from "../ShareSettingsStepV2";
import ScheduleCallSettings from "../ScheduleCallSettings";
import { colors, fontStyle } from "Constants";

const conStyle = {
  LE_SELECTION: {
    overflowY: "hidden",
  },
  COMMUNITY_LE_SELECTION: {
    overflowY: "hidden",
  },
  LE_LIBRARY_LE_SELECTION: {
    overflowY: "hidden",
  },
  ASSESSMENT_DETAILS: {
    overflowY: "hidden",
  },
};

const innerConStyle = {
  UNIT_SELECTION: {
    padding: "40px 64px",
    maxWidth: "792px",
  },
  UNIT_SELECTION_REVAMP: {
    padding: "40px 0",
    maxWidth: "1008px",
  },
  LE_CREATE_UNIT_SELECTION: {
    padding: "40px 64px",
    maxWidth: "792px",
  },
  LE_CREATE_UNIT_SELECTION_REVAMP: {
    padding: "40px 0",
    maxWidth: "1008px",
  },
  SMT_CREATE_UNIT_SELECTION: {
    padding: "40px 64px",
    maxWidth: "792px",
  },
  SMT_CREATE_UNIT_SELECTION_REVAMP: {
    padding: "40px 0",
    maxWidth: "1008px",
  },
  FMT_CREATE_UNIT_SELECTION: {
    padding: "40px 64px",
    maxWidth: "792px",
  },
  FMT_CREATE_UNIT_SELECTION_REVAMP: {
    padding: "40px 0",
    maxWidth: "1008px",
  },
  ASSESSMENT_CREATE_UNIT_SELECTION: {
    padding: "40px 64px",
    maxWidth: "792px",
  },
  ASSESSMENT_CREATE_UNIT_SELECTION_REVAMP: {
    padding: "40px 0",
    maxWidth: "1008px",
  },
  LE_SELECTION: {
    padding: 0,
    margin: 0,
    maxWidth: "unset",
    height: "unset",
  },
  COMMUNITY_LE_SELECTION: {
    padding: 0,
    margin: 0,
    maxWidth: "unset",
    height: "unset",
  },
  LE_LIBRARY_LE_SELECTION: {
    padding: 0,
    margin: 0,
    maxWidth: "unset",
    height: "unset",
  },
  RESOURCE_SELECTION: {
    padding: "40px 64px",
    maxWidth: "1112px",
  },
  DISCUSSION_CREATION: {
    padding: "40px 64px",
    maxWidth: "728px",
  },
  SETTINGS: {
    padding: "40px 64px",
    maxWidth: "728px",
  },
  SETTINGS_REVAMP: {
    padding: "0",
    maxWidth: "1008px",
  },
  CLASS_SELECTION: {
    padding: "20px 0 0",
  },
  ASSESSMENT_DETAILS: {
    padding: 0,
    margin: 0,
    width: "100%",
    minWidth: "100%",
    height: "100%",
  },
  SCHEDULE_CALL_SETTINGS: {
    padding: "40px 64px",
    maxWidth: "728px",
  },
  QT_CREATION: {
    padding: 0,
    margin: 0,
    width: "100%",
    minWidth: "100%",
    height: "100%",
  },
  LE_CREATION: {
    padding: 0,
    margin: 0,
    width: "100%",
    minWidth: "100%",
    height: "100%",
  },
  SMT_CREATION: {
    padding: 0,
    margin: 0,
    width: "100%",
    minWidth: "100%",
    height: "100%",
  },
  FMT_CREATION: {
    padding: 0,
    margin: 0,
    width: "100%",
    minWidth: "100%",
    height: "100%",
  },
  ASSESSMENT_CREATION: {
    padding: 0,
    margin: 0,
    width: "100%",
    minWidth: "100%",
    height: "100%",
  },
  SELECT_POST: {
    padding: 0,
    margin: 0,
    maxWidth: "unset",
    height: "unset",
  },
  UNIT_FROM_GRADE_SELECTION: {
    padding: "40px 64px",
    maxWidth: "792px",
  },
};

const unitSelectionStepStyles = {
  itemFeedContainer: {
    gridTemplateColumns: "repeat(auto-fill, 320px)",
  },
  helperTextStyle: {
    fontWeight: "normal",
    fontSize: "1.4rem",
    fontFamily: fontStyle.regular.fontFamily,
    marginTop: "-16px",
    marginBottom: "8px",
  },
};

const leSelectionStepStyles = {
  stepContainer: {
    padding: "16px 36px",
  },
};

const leFeedConStyles = {
  padding: "16px 20px 0",
};

const previewPaneStyle = {
  background: colors.toddleBG1,
  padding: "0 20px",
};

const gradeSelectionStyles = {
  list: {
    gridTemplateColumns: "repeat(3, 320px)",
    justifyContent: "center",
  },
};

const getHelperLabel = ({ currentStep, t }) => {
  switch (currentStep) {
    case "LE_CREATE_UNIT_SELECTION":
      return t("classRoom:created_assessment_unit_part", {
        label: t("common:le_label"),
      });
    case "SMT_CREATE_UNIT_SELECTION":
      return t("classRoom:created_assessment_unit_part", {
        label: t("common:summative_assessment"),
      });
    case "FMT_CREATE_UNIT_SELECTION":
      return t("classRoom:created_assessment_unit_part", {
        label: t("common:formative_assessment"),
      });
    case "ASSESSMENT_CREATE_UNIT_SELECTION":
      return t("classRoom:created_assessment_unit_part", {
        label: t("common:assessment"),
      });
    default:
      return "";
  }
};

const getInnerConStyles = ({ currentStep, isResourceSelection }) => {
  const currentStepRevamp = `${currentStep}_REVAMP`;

  const shouldUseRevampStyles =
    innerConStyle[currentStepRevamp] && !isResourceSelection;

  if (shouldUseRevampStyles) {
    return innerConStyle[currentStepRevamp];
  } else {
    return innerConStyle[currentStep];
  }
};

const StepItem = React.memo(props => {
  const {
    curriculumProgram,
    currentStep,
    selectedState,
    changeSelectedState,
    mode,
    courseId,
    getDiscussionPromptRef,
    setUploadingStatus,
    genericFolders,
    t,
    updateCourseId,
    portalType,
    selectedTimeSlot,
    courseList,
    setDisableButton,
    curriculumType,
    assignmentId,
    creationMode,
    updateUnitPlanDataInRedux,
    isLECreationRevamp,
    handleScroll,
    showTemplateConfigModal,
    toggleShowTemplateConfigModal,
  } = props;
  let stepPortalType;

  switch (currentStep) {
    case "UNIT_SELECTION":
    case "LE_CREATE_UNIT_SELECTION":
    case "SMT_CREATE_UNIT_SELECTION":
    case "FMT_CREATE_UNIT_SELECTION":
    case "ASSESSMENT_CREATE_UNIT_SELECTION":
      return (
        <UnitSelection
          updateSelectedItems={(id, templateId) => {
            changeSelectedState(prev => {
              return {
                ...prev,
                unitPlanId: id,
                unitPlanTemplateId: templateId,
                importedLeId: "",
              };
            });
          }}
          handleScroll={handleScroll}
          isQueryPaginated={true}
          helperLabel={getHelperLabel({ currentStep, t })}
          styles={unitSelectionStepStyles}
          cardDimensions={{ width: 320, height: 280 }}
          selectedItems={selectedState.unitPlanId}
          courseId={courseId}
          showAlert={true}
        />
      );

    case "RESOURCE_SELECTION":
      return (
        <PlannerResourceSelection
          parentId={selectedState.unitPlanId}
          parentType={"UNIT_PLAN"}
          updateSelectedItems={attachments => {
            changeSelectedState(prev => {
              return {
                ...prev,
                resource: { ...prev.resource, attachments },
              };
            });
          }}
          selectedItems={selectedState.resource.attachments}
        />
      );
    case "DISCUSSION_CREATION":
      return (
        <DiscussionStep
          canAddAttachment={selectedState.creationMode != "IMPORT_RESOURCES"}
          resource={selectedState.resource}
          contentType={selectedState.contentType}
          getDiscussionPromptRef={getDiscussionPromptRef}
          updateSelectedItems={param => {
            changeSelectedState(prev => {
              const { isStudentSubmissionEnabled, ...rest } = param;
              return {
                ...prev,
                isStudentSubmissionEnabled:
                  isStudentSubmissionEnabled || prev.isStudentSubmissionEnabled,
                resource: { ...prev.resource, ...rest },
              };
            });
          }}
          setUploadingStatus={setUploadingStatus}
        ></DiscussionStep>
      );
    case "SETTINGS":
      return isLECreationRevamp ? (
        <ShareSettingsStepV2
          curriculumType={curriculumType}
          curriculumProgram={curriculumProgram}
          isClassDiscussionEnabled={_.get(
            selectedState,
            "isClassDiscussionEnabled",
            false
          )}
          isStudentSubmissionEnabled={_.get(
            selectedState,
            "isStudentSubmissionEnabled",
            false
          )}
          isStudentEvaluationEnabled={_.get(
            selectedState,
            "isStudentEvaluationEnabled",
            false
          )}
          isTeacherEvaluationEnabled={_.get(
            selectedState,
            "isTeacherEvaluationEnabled",
            false
          )}
          isPrivate={_.get(selectedState, "isPrivate", false)}
          courses={selectedState.courses}
          genericFolders={genericFolders}
          folderNames={selectedState.folderNames}
          courseId={courseId}
          contentType={selectedState.contentType}
          leId={selectedState.leId}
          updateSelectedItems={param => {
            changeSelectedState(prev => {
              return {
                ...prev,
                ...param,
              };
            });
          }}
          portalType={portalType}
          studentTemplates={_.get(
            selectedState,
            "resource.studentTemplates",
            []
          )}
          selectedTimeSlot={selectedTimeSlot}
          setDisableButton={setDisableButton}
          mode={mode}
          assignmentId={assignmentId}
          isSimilarityCheckEnabled={selectedState.isSimilarityCheckEnabled}
        />
      ) : (
        <ShareSettingsStep
          curriculumType={curriculumType}
          curriculumProgram={curriculumProgram}
          showAddClassButton={mode == "create"}
          isClassDiscussionEnabled={_.get(
            selectedState,
            "isClassDiscussionEnabled",
            false
          )}
          isStudentSubmissionEnabled={_.get(
            selectedState,
            "isStudentSubmissionEnabled",
            false
          )}
          isStudentEvaluationEnabled={_.get(
            selectedState,
            "isStudentEvaluationEnabled",
            false
          )}
          isTeacherEvaluationEnabled={_.get(
            selectedState,
            "isTeacherEvaluationEnabled",
            false
          )}
          isPrivate={_.get(selectedState, "isPrivate", false)}
          courses={selectedState.courses}
          genericFolders={genericFolders}
          folderNames={selectedState.folderNames}
          courseId={courseId}
          contentType={selectedState.contentType}
          leId={selectedState.leId}
          updateSelectedItems={param => {
            changeSelectedState(prev => {
              return {
                ...prev,
                ...param,
              };
            });
          }}
          portalType={portalType}
          studentTemplates={_.get(
            selectedState,
            "resource.studentTemplates",
            []
          )}
          selectedTimeSlot={selectedTimeSlot}
          setDisableButton={setDisableButton}
          mode={mode}
          assignmentId={assignmentId}
          isSimilarityCheckEnabled={selectedState.isSimilarityCheckEnabled}
        />
      );
    case "CLASS_SELECTION":
      return (
        <GradeOrCourseSelection
          styles={gradeSelectionStyles}
          handleSelect={props => updateCourseId(props.gradeId)}
          portalType={portalType}
          groupClasses={true}
          selectedIds={[courseId]}
          showSelectedIcon={true}
          creationMode={creationMode}
        />
      );
    case "ASSESSMENT_DETAILS":
      return (
        <AssessmentDetailsComponent
          assessmentId={selectedState.leId}
          mode={"edit"}
          assessmentMode={mode}
          renderAsComponent={true}
          portalType={portalType}
          sourceFrom={"CLASSROOM"}
          unitPlanId={selectedState.unitPlanId}
          updateUnitPlanDataInRedux={updateUnitPlanDataInRedux}
        />
      );
    case "SCHEDULE_CALL_SETTINGS":
      return (
        <ScheduleCallSettings
          curriculumProgram={curriculumProgram}
          resource={selectedState.resource}
          courses={selectedState.courses}
          meeting={selectedState.meeting}
          courseId={courseId}
          updateSelectedItems={param => {
            changeSelectedState(prev => {
              return {
                ...prev,
                ...param,
              };
            });
          }}
          portalType={portalType}
          showAddClassButton={mode == "create"}
        />
      );
    case "QT_CREATION":
    case "LE_CREATION":
    case "SMT_CREATION":
    case "FMT_CREATION":
    case "ASSESSMENT_CREATION":
      return (
        <AssessmentDetailsComponent
          assessmentId={selectedState.leId}
          mode={"edit"}
          renderAsComponent={true}
          portalType={portalType}
          sourceFrom={"CLASSROOM"}
          showTemplateConfigModal={showTemplateConfigModal}
          toggleShowTemplateConfigModal={toggleShowTemplateConfigModal}
        />
      );
    case "UNIT_FROM_GRADE_SELECTION":
      return (
        <UnitFromGradeSelection
          courseList={courseList}
          updateSelectedItems={(id, templateId) => {
            changeSelectedState(prev => {
              return {
                ...prev,
                unitPlanId: id,
                unitPlanTemplateId: templateId,
                importedLeId: "",
              };
            });
          }}
          onCourseSelect={({ course }) => {
            changeSelectedState(prev => {
              return {
                ...prev,
                selectedCourse: course,
                unitPlanId: "",
                unitPlanTemplateId: "",
              };
            });
          }}
          unitSelectionStepStyles={unitSelectionStepStyles}
          cardDimensions={{ width: 320, height: 280 }}
          selectedItems={selectedState.unitPlanId}
          selectedCourse={_.get(selectedState, "selectedCourse.courseId", "")}
        />
      );
    case "SELECT_POST":
    case "LE_SELECTION":
    case "COMMUNITY_LE_SELECTION":
    case "LE_LIBRARY_LE_SELECTION":
      stepPortalType =
        currentStep == "COMMUNITY_LE_SELECTION"
          ? "COMMUNITY"
          : currentStep == "LE_LIBRARY_LE_SELECTION"
          ? "PLANNER"
          : portalType;

      return (
        <div className={classes.subInnerCon}>
          <div className={classes.stepPane}>
            <LeFeed
              courseId={courseId}
              courseList={courseList}
              unitPlanId={selectedState.unitPlanId}
              portalType={stepPortalType}
              assessmentId={selectedState.importedLeId}
              feedAsPosts={
                !_.includes(
                  ["COMMUNITY_LE_SELECTION", "LE_LIBRARY_LE_SELECTION"],
                  currentStep
                )
              }
              showHeaderText={false}
              curriculumType={curriculumType}
              importFromLeLibrary={currentStep == "LE_LIBRARY_LE_SELECTION"}
              setAssessmentId={importedLeId => {
                changeSelectedState(prev => {
                  return {
                    ...prev,
                    importedLeId,
                  };
                });
              }}
            />
          </div>
          <div className={classes.stepPane} style={previewPaneStyle}>
            {!_.isEmpty(selectedState.importedLeId) ? (
              <LEPreview
                assessmentId={selectedState.importedLeId}
                portalType={stepPortalType}
                parentType={"CLASSROOM"}
              />
            ) : (
              <NoDataComponent
                emptyText={t("common:select_post_to_preview")}
                emptyHeaderText=""
                isSearch={false}
              />
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
});

StepItem.displayName = StepItem;

const Header = React.memo(props => {
  const { heading, subHeading } = props;
  return (
    <div className={classes.header}>
      <div className={classes.heading}>{heading}</div>
      <div className={classes.subHeading}>{subHeading}</div>
    </div>
  );
});

Header.displayName = Header;

const StepContainer = React.memo(props => {
  const {
    t,
    curriculumProgram,
    currentStep,
    currentStepObj,
    changeSelectedState,
    mode,
    selectedState,
    courseId,
    resourceSelection,
    getDiscussionPromptRef,
    setUploadingStatus,
    genericFolders,
    updateCourseId,
    portalType,
    selectedTimeSlot,
    courseList,
    setDisableButton,
    curriculumType,
    assignmentId,
    creationMode,
    updateUnitPlanDataInRedux,
    isLECreationRevamp,
    showTemplateConfigModal,
    toggleShowTemplateConfigModal,
  } = props;

  const handleScroll = useRef(null);

  const innerConStyles = getInnerConStyles({
    currentStep,
    isResourceSelection: resourceSelection,
  });

  return (
    <div
      className={classes.container}
      onScroll={e => handleScroll?.current?.(e)}
      style={conStyle[currentStep]}
    >
      <div className={classes.innerContainer} style={innerConStyles}>
        {resourceSelection && <Header {...currentStepObj}></Header>}
        <StepItem
          mode={mode}
          courseList={courseList}
          curriculumProgram={curriculumProgram}
          currentStep={currentStep}
          selectedState={selectedState}
          changeSelectedState={changeSelectedState}
          courseId={courseId}
          getDiscussionPromptRef={getDiscussionPromptRef}
          setUploadingStatus={setUploadingStatus}
          genericFolders={genericFolders}
          t={t}
          handleScroll={handleScroll}
          updateCourseId={updateCourseId}
          portalType={portalType}
          selectedTimeSlot={selectedTimeSlot}
          setDisableButton={setDisableButton}
          curriculumType={curriculumType}
          assignmentId={assignmentId}
          creationMode={creationMode}
          updateUnitPlanDataInRedux={updateUnitPlanDataInRedux}
          isLECreationRevamp={isLECreationRevamp}
          showTemplateConfigModal={showTemplateConfigModal}
          toggleShowTemplateConfigModal={toggleShowTemplateConfigModal}
        ></StepItem>
      </div>
    </div>
  );
});

StepContainer.displayName = StepContainer;

const mapStateToProps = (state, ownProps) => {
  return { curriculumType: state.platform.currentCurriculumProgramType };
};

const mapActionCreators = {};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators)
)(StepContainer);
