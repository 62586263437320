import React, { useEffect, useState } from "react";
import classes from "./UseTemplateModal.scss";
import { UIModal, FileViewer, TheatreMode } from "UIComponents";
import UseTemplateHeader from "./components/UseTemplateHeader";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { markAttachmentAsRead } from "modules/Services";
import { connect } from "react-redux";

const UseTemplateModal = ({
  attachment,
  open,
  onClose,
  rightActionButton,
  shouldShowTabIcon,
  wrapInModal,
  attachmentBottomComponent,
  showSimilarityIndex,
  onPdftronUpdateSuccessful,
  isPDFAnnotationEnabled,
  markAttachmentAsRead,
}) => {
  const [isPdftronEnabled, changePdftronEnable] = useState(false);

  useEffect(() => {
    if (!attachment?.isRead && isPDFAnnotationEnabled) {
      markAttachmentAsRead({ id: attachment?.id });
    }
  }, [attachment, isPDFAnnotationEnabled, markAttachmentAsRead]);

  const onPdftronDiscardDirectly = () => {
    changePdftronEnable(false);
  };
  const mediaData = {
    attachments: [_.omit(attachment, ["presetAttachment"])],
    attachmentId: _.get(attachment, "id", ""),
    isPost: true,
  };

  return (
    <ConditionalWrapper
      condition={wrapInModal}
      wrapper={({ children }) => (
        <UIModal isOpen={open} isFullScreen={true}>
          {children}
        </UIModal>
      )}
    >
      <div className={classes.container}>
        <UseTemplateHeader
          title={attachment.name}
          onClose={onClose}
          shouldShowTabIcon={shouldShowTabIcon}
          rightActionButton={rightActionButton}
          url={attachment.url}
          attachmentBottomComponent={() =>
            _.isFunction(attachmentBottomComponent) &&
            attachmentBottomComponent({
              showSimilarityOnHeader: true,
            })
          }
          showSimilarityIndex={showSimilarityIndex}
        />
        <div className={classes.content}>
          {_.get(attachment, "mimeType", "") === "application/pdf" &&
          isPDFAnnotationEnabled ? (
            <TheatreMode
              currentMediaData={mediaData}
              setShowGiveFeedbackModal={true}
              isPdftronEnabled={isPdftronEnabled}
              changePdftronEnable={changePdftronEnable}
              shouldShowGiveFeedbackButton={true}
              onDiscardDirectly={onPdftronDiscardDirectly}
              onPdftronUpdateSuccessfullly={onPdftronUpdateSuccessful}
            />
          ) : (
            <FileViewer file={attachment} />
          )}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

UseTemplateModal.defaultProps = {
  wrapInModal: true,
  isPDFAnnotationEnabled: false,
};

const mapActionCreators = { markAttachmentAsRead };

export default connect(_, mapActionCreators)(UseTemplateModal);
