import React from "react";
import classes from "./TopHeader.scss";
import { UIButton } from "UIComponents";

class TopHeader extends React.PureComponent {
  render() {
    const { title, subTitle } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <div className={classes.titleText}>{title}</div>
          <div className={classes.subTitleText}>{subTitle}</div>
        </div>
        <div className={classes.rightContainer} />
      </div>
    );
  }
}

export default TopHeader;
