import { leFragment } from "./LeFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
import { getLeQuery } from "./LeQuery";
import client from "apolloClient";
export const getLeDetailsFromCache = leId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getLeQuery,
      variables: {
        id: leId,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getLeFieldFromCache = field_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PlannerField:${field_id}`,
      fragment: plannerFieldFragment.resourceLibraryField,
      fragmentName: "resourceLibraryFieldItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeLeFieldFragment = ({ field_id, data }) => {
  client.writeFragment({
    id: `PlannerField:${field_id}`,
    fragment: plannerFieldFragment.resourceLibraryField,
    fragmentName: "resourceLibraryFieldItem",
    data: data,
  });
};
