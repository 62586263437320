import React from "react";
import classes from "./AddPYPDetails.scss";

import { InputTextField, UIButton } from "UIComponents";

class AddPYPDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  onSkipClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "verifyOTP" });
  };

  onSaveClick = () => {
    const { createPYPCoordinator, showErrorToast } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    createPYPCoordinator();
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "createAccount" });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  onConfirmEmailEnter = () => {
    this.onSaveClick();
  };

  onEnterPress = ({ next }) => {
    if (this.inputRefs[next] && this.inputRefs[next].focus) {
      this.inputRefs[next].focus();
    }
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      checkEmailVerification,
      data: { firstName, lastName, email } = {},
      renderErrorComponent,
      renderBackButtonComponent,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
    } = this.props;
    return (
      <div
        className={classes.container}
        style={{ ...containerStyle, paddingTop: 64 }}
      >
        {renderBackButtonComponent({ onClick: this.onBackClick })}
        <div style={headerStyle}>{`Tell us about your PYP team`}</div>
        <div
          style={subTextStyle}
        >{`We need this information to setup your Toddle account`}</div>
        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <div
              style={{ justifyContent: "space-between" }}
              className={classes.inputContainer}
            >
              <div className={classes.nameContainer}>
                <InputTextField
                  label={"PYP Coordinator's first name"}
                  value={firstName}
                  placeholder={"Enter first name"}
                  name={"firstName"}
                  updateInputField={updateInputField}
                  {...inputStyle}
                  ref={ref => (this.inputRefs["firstName"] = ref)}
                  showErrorText={true}
                  renderErrorComponent={renderErrorComponent}
                  error={"Enter first name"}
                  onEnter={() => this.onEnterPress({ next: "lastName" })}
                />
              </div>
              <div className={classes.nameContainer}>
                <InputTextField
                  label={"PYP Coordinator's last name"}
                  value={lastName}
                  placeholder={"Enter last name"}
                  name={"lastName"}
                  updateInputField={updateInputField}
                  {...inputStyle}
                  ref={ref => (this.inputRefs["lastName"] = ref)}
                  showErrorText={true}
                  renderErrorComponent={renderErrorComponent}
                  error={"Enter last name"}
                  onEnter={() => this.onEnterPress({ next: "email" })}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <InputTextField
                label={"PYP Coordinator's work email"}
                value={email}
                placeholder={"Enter PYP coordinator's work email"}
                name={"email"}
                updateInputField={updateInputField}
                {...inputStyle}
                ref={ref => (this.inputRefs["email"] = ref)}
                showErrorText={true}
                customValidation={checkEmailVerification}
                renderErrorComponent={renderErrorComponent}
                error={"Enter email address"}
                onEnter={this.onConfirmEmailEnter}
              />
            </div>
          </div>
          <div className={classes.bottomContainer}>
            {/* <div
              className={classes.skipButton}
              onClick={this.onSkipClick}
            >{`Skip`}</div> */}
            <UIButton
              size="lg"
              type="filled"
              color="pink"
              onClick={this.onSaveClick}
            >
              {"Continue"}
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPYPDetails;
