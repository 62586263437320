import React from "react";
import classes from "./Stepper.scss";
import { IncrementArrow, DecrementArrow } from "SvgComponents";
import { InputTextField } from "UIComponents";

const styles = {
  stepperInputStyles: {
    border: "none",
    padding: 0,
    height: "48px",
    textAlign: "center",
  },
};

const Stepper = React.memo(({ value = 1, onValueChange, containerStyle }) => {
  const handleStepClick = offset => {
    const newStep = value + offset < 1 ? 1 : value + offset;
    onValueChange(newStep);
  };

  let isValidKey = true;
  const handleKeyPress = e => {
    isValidKey = !isNaN(+e.key);
  };
  const handleChange = value => {
    if (isValidKey) {
      const valueToUpdate = Math.max(+value, 1);
      onValueChange(valueToUpdate);
    } else {
      isValidKey = true;
    }
  };
  return (
    <div className={classes.stepContainer} style={containerStyle}>
      <div className={classes.stepValueWrapper}>
        <InputTextField
          value={value}
          updateInputField={handleChange}
          handleKeyPress={handleKeyPress}
          inputStyle={styles.stepperInputStyles}
        />
      </div>
      <div className={classes.stepperWrapper}>
        <div
          className={classes.incrementArrow}
          onClick={() => handleStepClick(1)}
        >
          <IncrementArrow />
        </div>
        <div
          className={classes.decrementArrow}
          onClick={() => handleStepClick(-1)}
        >
          <DecrementArrow />
        </div>
      </div>
    </div>
  );
});

export default Stepper;
