import React, { Component } from "react";
import PropTypes from "prop-types";

class ScalableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: {
        width: null,
        height: null,
      },
    };
  }

  componentDidMount() {
    this.onProps(this.props);
  }

  onProps(props) {
    if (!props.sourceWidth || !props.sourceHeight) {
      if (props.source) {
        // eslint-disable-next-line no-undef
        const source = resolveAssetSource(props.source);
        this.adjustSize(source.width, source.height, props);
      }
    } else {
      this.adjustSize(props.sourceWidth, props.sourceHeight, props);
    }
  }

  adjustSize(sourceWidth, sourceHeight, props) {
    const { width, height, maxHeight, scalableRatio } = props;
    let viewWidth, viewHeight;
    let ratio = 1;

    if (width && height) {
      ratio = Math.min(width / sourceWidth, height / sourceHeight);
    } else if (width) {
      ratio = width / sourceWidth;
    } else if (height) {
      ratio = height / sourceHeight;
    }

    viewWidth = sourceWidth * ratio;
    viewHeight = sourceHeight * ratio;

    if (maxHeight && sourceHeight * ratio > maxHeight) {
      viewHeight = maxHeight;
    }

    if (scalableRatio == 1) {
      viewHeight = sourceWidth * ratio;
    }

    this.setState({
      size: {
        width: viewWidth,
        height: viewHeight,
      },
    });
  }

  render() {
    const divStyle = {
      width: this.props.width,
      height: this.state.size.height,
    };
    return (
      <div style={{ ...divStyle, ...this.props.containerStyle }}>
        {this.props.children}
      </div>
    );
  }
}

export default ScalableView;

ScalableView.defaultProps = {
  maxHeight: 556,
  width: 556,
  height: 556,
  sourceHeight: 556,
  scalableRatio: 0,
  sourceWidth: 556,
  containerStyle: {},
};

ScalableView.propTypes = {
  maxHeight: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  sourceHeight: PropTypes.number,
  sourceWidth: PropTypes.number,
  scalableRatio: PropTypes.number,
  containerStyle: PropTypes.object,
};
