import React from "react";
import classes from "./NodeTreeRow.scss";
import {
  LeftIcon,
  CircularAlertIcon,
  LineArrowIcon,
  AddCircleIcon,
} from "SvgComponents";
import classNames from "classnames";
import { fontStyle, colors } from "Constants";
import {
  MoreItemWithToolTip,
  TextAreaInput,
  ButtonDropdown,
  ContentEditable,
  I18nHOC,
  LinkWithTooltip,
} from "UIComponents";
import GradeFilter from "MultiLevelNodeEditor/routes/Levels/components/GradeFilter";
import ACLStore from "lib/aclStore";
import { Checkbox } from "@toddle-design/web";

const commonStyles = {
  viewContainer1: {
    padding: 0,
  },
  viewContainer2: {
    padding: 0,
    paddingTop: 24,
    marginBottom: 24,
  },
  viewContainer3: {
    padding: 0,
    marginBottom: 24,
  },
};

const styles = {
  MYP_OBJECTIVES: [
    {
      depth: 1,
      labelDepth: 0,
      viewContainerStyle: commonStyles.viewContainer1,
    },
    {
      depth: 2,
      labelDepth: 1,
      viewContainerStyle: commonStyles.viewContainer2,
    },
    {
      depth: 3,
      labelDepth: 3,
      viewContainerStyle: commonStyles.viewContainer3,
    },
  ],
  //Temp To fix styles
  // MYP_ATL: [
  //   {
  //     depth: 1,
  //     labelDepth: 0,
  //     viewContainerStyle: commonStyles.viewContainer1,
  //   },
  //   {
  //     depth: 2,
  //     labelDepth: 1,
  //     viewContainerStyle: commonStyles.viewContainer2,
  //   },
  //   {
  //     depth: 3,
  //     labelDepth: 3,
  //     viewContainerStyle: commonStyles.viewContainer3,
  //   },
  // ],
};
const benchmarkCountConfig = {
  linkProps: {
    target: "__blank",
    rel: "noopener noreferrer",
  },
  linkStyles: {
    padding: "4px 4px 4px 4px",
    borderRadius: "24px",
    minWidth: 28,
    display: "inline-block",
    color: colors.white,
  },
};

const gradeFilterStyle = {
  filterBoxStyle: {
    border: "none",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 16px 0 0",
    height: "unset",
  },
  filterTextStyle: {
    color: colors.gray31,
    ...fontStyle.regular,
  },
  gradeNameTextStyle: {
    color: colors.gray31,
    ...fontStyle.demiBold,
  },
  buttonParentStyle: {
    width: "100%",
  },
  parentContainerStyle: {
    maxHeight: "40px",
  },
  emptyTextStyle: {
    color: colors.gray72,
  },
  arrowContainerStyle: {
    fill: colors.gray72,
  },
};

const textAreaStyles = {
  borderWidth: 0,
  flexShrink: 0,
  overflowY: "hidden",
  padding: "8px 16px",
};

const moreStyle = {
  color: colors.gray31,
  ...fontStyle.medium,
  fontSize: "1.4rem",
};

const gradeContainerStyle = {
  color: colors.blue29,
  fontSize: "1.4rem",
  ...fontStyle.medium,
  justifySelf: "flex-end",
};

const getGradeIdsArray = grades => {
  return _.map(grades, item => {
    return item.name;
  });
};

const clamp = (n, min, max) => {
  return Math.max(min, Math.min(max, n));
};

const ACTION_BUTTONS = t => {
  return [
    { label: t("common:move_up"), value: "MOVE_UP", isDisabled: false },
    { label: t("common:move_down"), value: "MOVE_DOWN", isDisabled: false },
    { label: t("common:delete"), value: "DELETE", isDisabled: false },
  ];
};

const buttonDropDownContainerStyle = {
  marginTop: 4,
  width: "max-content",
  left: 0,
};

const buttonDropDownItemStyle = {
  height: 40,
  fontSize: "1.4rem",
};

const moreIconStyle = {
  fill: colors.gray72,
};

const BulletComponent = React.memo(({ depth }) => {
  if (depth == 2) {
    return <div className={classes.bullet}>{`•`}</div>;
  } else if (depth > 2) {
    return <div className={classes.bullet}>{`—`}</div>;
  } else {
    return null;
  }
});

class NodeTreeRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { dropDownOpen: false };
  }
  onCheckBoxClick = () => {
    const { onCheckBoxClick, id, isTagging, showCheckBox } = this.props;

    if (!isTagging || !showCheckBox) {
      return;
    }
    onCheckBoxClick({ id });
  };

  renderCode = () => {
    const { code, mode, isStrand, index } = this.props;
    if (mode == "view") {
      return null;
    }
    const codeStyle = classNames(
      { [classes.code]: true },
      { [classes.codeEmpty]: !code && mode == "view" },
      {
        [classes.borderBottom]: mode == "edit" && isStrand,
      }
    );
    return (
      <div
        className={codeStyle}
        id={index == 1 ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_CODE" : null}
      >
        {mode == "view" ? (code ? code : "-") : this.renderCodeEdit()}
      </div>
    );
  };

  updateTreeCodeRowsRef = ref => {
    const { updateTreeCodeRowsRef, id } = this.props;
    updateTreeCodeRowsRef({ ref, id });
  };

  renderCodeEdit = () => {
    const { code, t } = this.props;

    return (
      <ContentEditable
        value={code}
        placeholder={t("common:code")}
        updateInputField={this.editNode}
        name={`code`}
      />
    );
  };

  renderGrade = () => {
    const {
      grades,
      mode,
      optionsGrades,
      isTagging,
      showInsights,
      isStrand,
      index,
      depth,
      showGrade,
      t,
    } = this.props;
    if (isTagging || showInsights || !showGrade) {
      return null;
    }
    const gradeIdsArray = getGradeIdsArray(grades);
    const labelComponent = (
      <MoreItemWithToolTip
        items={gradeIdsArray}
        moreStyle={moreStyle}
        length={_.get(gradeIdsArray, "length", 0) <= 2 ? 2 : 1}
        noItemText={
          _.get(grades, "length", 0) > 0 || mode == "edit"
            ? t("common:select_with_label", {
                label: t("common:grade_plural"),
              })
            : t("common:no_label_available", {
                label: t("common:grade_plural"),
              })
        }
        moreJoinSymbol={", "}
        joinSymbol={", "}
        containerStyle={{
          ...gradeContainerStyle,
          color: gradeIdsArray.length > 0 ? colors.gray31 : colors.gray72,
        }}
      />
    );

    const gradeStyle = classNames(
      { [classes.grade]: true },
      { [classes.gradeView]: mode == "view" },
      {
        [classes.borderBottom]: mode == "edit" && isStrand,
      }
    );

    return (
      <div
        className={gradeStyle}
        id={index == 1 ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_GRADE" : null}
      >
        {mode == "view" ? (
          depth > 1 ? (
            labelComponent
          ) : null
        ) : (
          <GradeFilter
            gradeFilters={grades}
            {...gradeFilterStyle}
            label=""
            emptyGradeText={t("common:grade_plural")}
            dropDownEmptyText={t("common:no_label_available", {
              label: t("common:grade_plural"),
            })}
            labelComponent={labelComponent}
            updateGradeFilters={this.updateGradeFilters}
            grades={optionsGrades}
          />
        )}
      </div>
    );
  };

  updateGradeFilters = filters => {
    const { updateGradeFilters, index, depth, type } = this.props;
    updateGradeFilters({ index, filters, depth, type });
  };

  renderBenchmarkCount = () => {
    const { nodeType, isLeaf, benchmarkQuestionCount } = this.props;

    let count = 0,
      color = colors.black,
      url = "#",
      yearSplit = [];

    if (benchmarkQuestionCount) {
      count = _.get(benchmarkQuestionCount, "count", "0");
      color = _.get(benchmarkQuestionCount, "color", `${colors.black}`);
      url = _.get(benchmarkQuestionCount, "url", `#`);
      yearSplit = _.get(benchmarkQuestionCount, "yearSplit", []);
    }
    const benchmarkDetail = (
      <>
        {_.map(yearSplit, obj => {
          const { year, count } = obj;

          return (
            <div className={classes.yearCountBenchmarkContainer}>
              <span className={classes.yearBenchmark}>{year}</span> {count}
            </div>
          );
        })}
      </>
    );
    if (
      nodeType === "BENCHMARK" &&
      isLeaf &&
      ACLStore.can("TeacherPortal:PlannerBenchmarkQuestions")
    ) {
      if (count === 0) {
        return (
          <div style={{ color: color }} className={classes.emptyBenchmarkCount}>
            {count}
          </div>
        );
      }
      return (
        <div className={classes.benckmarkCount}>
          <LinkWithTooltip
            tooltip={benchmarkDetail}
            placement="bottom"
            linkStyles={{
              ...benchmarkCountConfig.linkStyles,
              background: color,
            }}
            linkProps={{ ...benchmarkCountConfig.linkProps, href: url }}
          >
            {`${count}`}
          </LinkWithTooltip>
        </div>
      );
    }
    return null;
  };
  renderLabelView = () => {
    const { label, showCheckBox, isChecked, depth, code } = this.props;
    const size = depth === 1 ? "xx-small" : "xxx-small";

    return (
      <div className={classes.labelContainer}>
        {showCheckBox ? (
          <div className={classes.checkboxContainer}>
            <Checkbox size={size} isChecked={isChecked} />
          </div>
        ) : (
          <BulletComponent depth={depth} />
        )}
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
          <span
            className={classes.codeLabel}
            dangerouslySetInnerHTML={{
              __html: code ? ` (${code})` : ``,
            }}
          />
        </span>
      </div>
    );
  };

  renderLabel = () => {
    const { depth, mode, type, isStrand, subText } = this.props;

    const labelDepth = _.get(
      _.find(styles[type], { depth }),
      "labelDepth",
      null
    );
    const newDepth = !_.isNil(labelDepth) ? labelDepth : depth;

    const showSubText = _.get(
      _.find(styles[type], { depth }),
      "showSubText",
      false
    );

    const labelTextStyle = classNames(
      {
        [classes[`depthText${depth}`]]: true,
      },
      {
        [classes[`depthViewText${newDepth}`]]: mode == "view",
      },
      {
        [classes.borderBottom]: mode == "edit" && isStrand,
      }
    );
    return (
      <div
        className={labelTextStyle}
        style={{
          marginLeft: (depth - 2) * 24 - (mode == "view" && depth > 1 ? 24 : 0),
        }}
      >
        {mode == "view" ? this.renderLabelView() : this.renderLabelEdit()}
        {showSubText && subText && (
          <div className={classes.subText}>{subText}</div>
        )}
      </div>
    );
  };

  renderInsights = () => {
    const {
      t,
      id,
      isRootNode,
      showInsights,
      insightsData,
      showCoveredUnitsModal,
    } = this.props;

    if (_.isUndefined(showInsights)) {
      return null;
    }

    const element = _.find(insightsData.elementsCovered, element =>
      _.isEqual(element.plannerElementNode.id, id)
    );
    const insightCount = _.get(element, "totalCount", "-");
    const unitPlanIds = _.map(_.get(element, "unitPlans", []), unit => unit.id);
    const onClickInsightCount = () => {
      if (insightCount > 0) {
        showCoveredUnitsModal({ unitPlanIds, id });
      }
    };

    return isRootNode ? (
      <div className={classes.insightsCountHeader}>
        {t("common:no_of_times_addressed")}
      </div>
    ) : (
      <div
        className={classes.insightsCount}
        onClick={() => {
          onClickInsightCount();
        }}
      >
        {insightCount}
      </div>
    );
  };

  onEnterPress = () => {
    setTimeout(() => {
      const { label } = this.props;
      if (!_.trim(label)) {
        return;
      }
      this.addNode();
    }, 10);
  };

  addNode = () => {
    const {
      updateEditNodeList,
      index,
      depth,
      type,
      focusTreeLabel,
    } = this.props;

    const newObj = updateEditNodeList({
      index,
      action: depth == 1 ? "ADD_STRAND" : "ADD",
      params: { depth, type },
    });

    setTimeout(() => {
      if (newObj) {
        focusTreeLabel({ id: newObj.id });
      }
    }, 100);
  };

  updateTreeLabelRowsRef = ref => {
    const { updateTreeLabelRowsRef, id } = this.props;
    updateTreeLabelRowsRef({ ref, id });
  };

  getPlaceHolderText = () => {
    const { depth, type, typeDetail, t } = this.props;

    if (depth > 1) {
      return t("common:type_to_add_label", {
        label:
          type == "CONCEPTUAL_UNDERSTANDING"
            ? t("academicSetup:cu")
            : t("academicSetup:lo"),
      });
    } else if (depth == 1) {
      return t("common:type_to_add_label", {
        label: t("academicSetup:strand"),
      });
    }
    return t("common:type_to_add");
  };

  renderLabelEdit = () => {
    const { label } = this.props;

    return (
      <ContentEditable
        ref={this.updateTreeLabelRowsRef}
        value={label}
        placeholder={this.getPlaceHolderText()}
        updateInputField={this.editNode}
        name={`label`}
        onEnterPress={this.onEnterPress}
        onTabPressed={this.onTabPressed}
      />
    );
  };

  onTabPressed = ({ isShiftTab } = {}) => {
    const { depth } = this.props;
    if (depth <= 1) {
      return;
    }
    if (isShiftTab) {
      this.onLeftArrowClick();
    } else {
      this.onRightArrowClick();
    }
  };

  onLeftArrowClick = () => {
    this.onChangeDepth({ isIncrement: false });
  };

  onRightArrowClick = () => {
    this.onChangeDepth({ isIncrement: true });
  };

  onChangeDepth = ({ isIncrement }) => {
    const { depth } = this.props;
    this.editNode({
      depth: clamp(depth + (isIncrement ? 1 : -1), 2, 3),
    });
  };

  editNode = params => {
    const { updateEditNodeList, index } = this.props;
    updateEditNodeList({
      index,
      action: "EDIT",
      params,
    });
  };

  onButtonDropDownItemClick = action => {
    const {
      updateEditNodeList,
      index,
      depth,
      type,
      onActionClick,
    } = this.props;

    switch (action) {
      case "DELETE":
        onActionClick({ action: "DELETE", index, params: { depth, type } });
        break;
      default:
        updateEditNodeList({
          index,
          action: action,
          params: { depth, type },
        });
    }
  };

  onUpdateShowDropDown = value => {
    this.setState({ dropDownOpen: value });
  };

  getActionButtons = () => {
    const { isFirstNodeInDepth, isLastNodeInDepth, t } = this.props;
    let menuItems = ACTION_BUTTONS(t);

    if (isFirstNodeInDepth) {
      menuItems = _.filter(menuItems, item => item.value != "MOVE_UP");
    }
    if (isLastNodeInDepth) {
      menuItems = _.filter(menuItems, item => item.value != "MOVE_DOWN");
    }

    return menuItems;
  };

  renderActionButtons = () => {
    return (
      <ButtonDropdown
        onItemClick={this.onButtonDropDownItemClick}
        getAuthTabs={this.getActionButtons}
        containerStyle={buttonDropDownContainerStyle}
        itemStyle={buttonDropDownItemStyle}
        moreIconStyle={moreIconStyle}
        onUpdateShowDropDown={this.onUpdateShowDropDown}
        shouldMountDropDownOnButtonClick={true}
      />
    );
  };

  renderAction = () => {
    const { mode, isStrand, typeDetail, depth, index } = this.props;
    return mode == "edit" && !isStrand ? (
      <div className={classes.actionContainer}>
        <div className={classes.actionButtonContainer}>
          <div
            className={classes.arrows}
            id={
              index == 2 ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_HIERARCHY" : null
            }
          >
            <div
              className={classes.leftArrowContainer}
              onClick={this.onLeftArrowClick}
            >
              <LineArrowIcon />
            </div>
            <div
              className={classes.iconCotainer}
              onClick={this.onRightArrowClick}
            >
              <LineArrowIcon />
            </div>
          </div>
          {this.renderActionButtons()}
        </div>
        <div
          className={classes.depthIndicator}
          style={{
            backgroundColor: typeDetail.color,
            opacity: 0.05,
            marginLeft: (depth - 1) * 24,
          }}
        ></div>
      </div>
    ) : (
      <div />
    );
  };

  renderLeftMenu = () => {
    const { mode, isStrand, index, isCoachMarksRunning } = this.props;

    const { dropDownOpen } = this.state;
    return mode == "edit" ? (
      <div
        className={classes.leftMenuContainer}
        style={
          (index == 2 && isCoachMarksRunning) || dropDownOpen
            ? { visibility: "visible" }
            : undefined
        }
      >
        {!!isStrand && this.renderActionButtons()}
        <div
          className={classes.plusIcon}
          onClick={this.addNode}
          id={index == 2 ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_ADD_BUTTON" : null}
        >
          <AddCircleIcon width={16} height={16} />
        </div>
      </div>
    ) : null;
  };

  updateNodesRef = ref => {
    const { updateNodesRef, id, depth, startDepth } = this.props;

    if (depth != (startDepth ?? 1)) {
      return;
    }

    updateNodesRef({ ref, id });
  };
  render() {
    const {
      containerClass,
      isStrand,
      showBorderTop,
      typeDetail,
      mode,
      index,
      updateNodesRef,
      depth,
      type,
      isLastNodeInDepth,
      isFirstNodeInDepth,
    } = this.props;

    const containerStyle = classNames(
      { [classes.container]: true },
      { [classes.strandContainer]: isStrand }
    );

    const containerInnerStyle = classNames(
      { [containerClass]: true },
      { [classes.innerContainer]: true },
      { [classes.strandInnerContainer]: isStrand && mode == "edit" },
      { [classes.borderTop]: showBorderTop && mode == "edit" },
      { [classes[`innerViewContainer${depth}`]]: mode == "view" }
    );

    const viewContainerStyle = _.get(
      _.find(styles[type], { depth }),
      "viewContainerStyle",
      {}
    );

    return (
      <div
        className={containerStyle}
        ref={this.updateNodesRef}
        id={index === 2 ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_ROW" : null}
        onClick={this.onCheckBoxClick}
      >
        {this.renderLeftMenu()}
        <div
          className={containerInnerStyle}
          style={
            mode == "edit"
              ? { borderLeftColor: typeDetail.color }
              : {
                  marginBottom: isLastNodeInDepth ? 8 : 0,

                  ...(!isFirstNodeInDepth && depth == 2
                    ? {
                        borderTop: `1px solid ${colors.strokeOne}`,
                      }
                    : {}),
                  ...viewContainerStyle,
                }
          }
        >
          {this.renderAction()}

          {this.renderLabel()}
          {this.renderCode()}
          {this.renderGrade()}
          {this.renderInsights()}
          {this.renderBenchmarkCount()}
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common", "academicSetup"] })(NodeTreeRow);
