import React from "react";

const EY2Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M39.5825,19.5814 C41.0685,19.5814 42.3805,20.6514 42.3805,22.2754 C42.3805,22.8624 42.2075,23.6574 41.4485,24.6244 L33.8825,34.0204 L33.8825,41.4484 C33.8825,44.2814 31.9145,44.7304 30.9465,44.7304 C29.9445,44.7304 27.9755,44.2814 27.9755,41.4484 L27.9755,34.0204 L20.6175,24.7284 C19.7535,23.6234 19.6855,22.9314 19.6855,22.4484 C19.6855,20.8244 20.9625,19.5814 22.6215,19.5814 C23.4845,19.5814 24.3825,19.8914 25.5225,21.4124 L31.1535,28.9774 L36.8535,21.2734 C37.9245,19.8574 38.8235,19.5814 39.5825,19.5814 Z M53.748,19.2698 C58.239,19.2698 62.592,21.6538 62.592,26.7668 C62.592,30.2558 60.554,32.2938 58.309,34.2978 L52.643,39.3408 L60.174,39.3408 C62.592,39.3408 62.972,41.0338 62.972,41.8638 C62.972,42.6928 62.592,44.3848 60.174,44.3848 L48.29,44.3848 C45.043,44.3848 44.594,42.5538 44.594,41.6208 C44.594,40.6538 44.836,39.6868 46.562,38.0978 L54.474,30.9118 C55.683,29.8068 56.478,28.6318 56.478,27.1468 C56.478,25.4188 55.269,24.2098 53.437,24.2098 C52.056,24.2098 51.054,24.9358 50.432,26.0408 C49.879,27.0428 48.912,27.9408 47.703,27.9408 C46.148,27.9408 45.077,26.7668 45.077,25.3158 C45.077,24.7288 45.319,24.0368 45.561,23.6228 C47.15,20.9278 50.19,19.2698 53.748,19.2698 Z M15.02,19.9263 C17.438,19.9263 17.817,21.6193 17.817,22.4133 C17.817,23.2433 17.438,24.9353 15.02,24.9353 L6.729,24.9353 L6.729,29.5303 L14.501,29.5303 C16.92,29.5303 17.23,31.1533 17.23,31.8793 C17.23,32.6393 16.92,34.2623 14.501,34.2623 L6.729,34.2623 L6.729,39.3413 L15.641,39.3413 C18.059,39.3413 18.439,41.0343 18.439,41.8633 C18.439,42.6923 18.059,44.3843 15.641,44.3843 L4.414,44.3843 C2.1,44.3843 1.028,43.3133 1.028,40.9993 L1.028,23.3113 C1.028,20.9973 2.1,19.9263 4.414,19.9263 L15.02,19.9263 Z"></path>
      </g>
    </svg>
  );
};

EY2Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default EY2Icon;
