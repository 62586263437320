import gql from "graphql-tag";

export const createMultiLevelNodesDraftMutation = gql`
  mutation createMultiLevelNodesDraft(
    $subjectId: ID
    $nodeType: MULTI_LEVEL_NODE_TYPE!
    $rootNodes: [ID!]
    $nodes: [JSON!] = []
  ) {
    organizationManagement {
      createMultiLevelNodesDraft(
        input: {
          subjectId: $subjectId
          type: $nodeType
          rootNodes: $rootNodes
          nodes: $nodes
        }
      ) {
        draft {
          id
        }
      }
    }
  }
`;

export const deleteMultiLevelNodesDraftMutation = gql`
  mutation deleteMultiLevelNodesDraft($draftId: ID) {
    organizationManagement {
      deleteMultiLevelNodesDraft(input: { id: $draftId }) {
        isSuccess
        warning
      }
    }
  }
`;

export const updateMultiLevelNodesDraftMutation = gql`
  mutation updateMultiLevelNodes(
    $draftId: ID!
    $rootNodes: [ID!] = []
    $removedNodes: [ID!] = []
    $updatedNodes: [JSON!] = []
  ) {
    organizationManagement {
      updateMultiLevelNodesDraft(
        input: {
          id: $draftId
          rootNodes: $rootNodes
          removedNodes: $removedNodes
          updatedNodes: $updatedNodes
        }
      )
    }
  }
`;

export const publishMultiLevelNodeDraftMutation = gql`
  mutation publishMultiLevelNodeDraft($draftId: ID!, $plannerElementSetId: ID) {
    planner {
      publishMultiLevelNodeDraft(
        draftId: $draftId
        plannerElementSetId: $plannerElementSetId
      )
    }
  }
`;

export const createMultiLevelTagsMutation = gql`
  mutation createMultiLevelTags($input: [GenericTagInsertInput!]!) {
    platform {
      insertGenericTagsV2(input: $input) {
        id
        label
      }
    }
  }
`;
