import React, { useState } from "react";
import classes from "./TableBulkActions.scss";
import { useI18n } from "Hooks";
import { ButtonDropdown, DialogueBox, FullScreenLoader } from "UIComponents";
import {
  onBulkAddTags,
  onDeleteClick,
  onBulkRemoveTags,
  onBulkAddGrades,
  onBulkRemoveGrades,
  getSortedArrayMemoized,
} from "../../Utils/Utils";
import {
  TagOutlined,
  GraduationCapOutlined,
  DeleteOutlined,
} from "@toddle-design/web-icons";
import { colors } from "@toddle-design/theme";
import AddItemsModal from "../Table/Components/TableRow/Components/AddItemsModal/AddItemsModal";
import RemoveItemsModal from "../Table/Components/TableRow/Components/RemoveItemsModal/RemoveItemsModal";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { createMultiLevelTags } from "NodeEditor/Modules/NodeEditorModule";
import { Alert, Button } from "@toddle-design/web";

const dialogueBoxStyles = {
  modalBodyStyle: {
    paddingTop: "24px",
    paddingRight: "48px",
  },
  footerContainerStyle: {
    paddingRight: "48px",
  },
};

const dropDownStyles = {
  dropDownItemStyle: {
    width: "160px",
    height: "40px",
  },
  dropDownTextStyle: {
    fontSize: "1.4rem",
    color: colors.gray13,
  },
  containerStyle: {
    zIndex: 14,
  },
};

const DROPDOWN_CONFIG = ({ t }) => [
  {
    label: t("common:manage_with_label", { label: t("common:grade_plural") }),
    icon: <GraduationCapOutlined />,
    options: [
      {
        label: t("common:add_with_label", { label: t("common:grade_plural") }),
        value: "ADD_GRADES",
      },
      {
        label: t("common:remove_with_label", {
          label: t("common:grade_plural"),
        }),
        value: "REMOVE_GRADES",
      },
    ],
    id: "GRADES",
  },
  {
    label: t("common:manage_with_label", { label: t("common:tags") }),
    icon: <TagOutlined />,
    options: [
      {
        label: t("common:add_with_label", { label: t("common:tags") }),
        value: "ADD_TAGS",
      },
      {
        label: t("common:remove_with_label", { label: t("common:tags") }),
        value: "REMOVE_TAGS",
      },
    ],
    id: "TAGS",
  },
];

const dropdownHeader = ({ icon, label }) => (
  <Button icon={icon} variant={"outlined-subtle"} size={"small"}>
    {label}
  </Button>
);

const TableBulkActions = props => {
  const {
    nodes,
    setNodes,
    selectedStandardsCount,
    selectedNodeIds,
    updateNodesInDraft,
    rootNodeId,
    organizationTags,
    createMultiLevelTags,
    inputForMultiLevelTagsQuery,
    organizationTagsObjectMap,
    userId,
    gradeOptions,
  } = props;
  const [isDialogueBoxOpen, setIsDialogueBoxOpen] = useState(false);
  // Here items can be GRADES or TAGS
  const [addItemsModalState, setAddItemModalState] = useState("");
  const [removeItemsModalState, setRemoveItemsModalState] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useI18n(["scopeAndSequence", "common"]);

  const selectedStandardsText = t(
    "scopeAndSequence:standards_selected_with_count",
    { count: selectedStandardsCount }
  );

  const deleteText = t("common:delete");

  const dialogueBoxConfig = {
    modalTitle: t("common:delete_with_label", {
      label: t("scopeAndSequence:standards_plural"),
    }),
    modalBody: (
      <div>
        <div
          style={{
            color: `${colors.jaguar}`,
            margin: "0px 0px 24px 0px",
          }}
        >
          {t("scopeAndSequence:delete_strand_msg")}
        </div>
        <div className={classes.dialogBody}>
          <Alert
            type="warning"
            message={t("scopeAndSequence:delete_strand_msg_desc")}
          />
        </div>
      </div>
    ),
    cancelText: t("common:cancel"),
    confirmText: t("common:delete"),
    dialogContentClass: classes.dialogBoxContent,
  };

  const handleDeleteClick = () => {
    const { updatedNodes, removedNodes } = onDeleteClick({
      nodes,
      setNodes,
      selectedNodeIds,
      rootNodeId,
      userId,
    });
    updateNodesInDraft({ updatedNodes, removedNodes });
  };

  const toggleDialogueBoxDisplay = () => {
    setIsDialogueBoxOpen(prevState => !prevState);
  };
  //code related to Manage Tags or grades
  const hideAddItemsModal = () => {
    setAddItemModalState("");
    setItemsList([]);
  };
  const hideRemoveItemsModal = () => {
    setRemoveItemsModalState("");
    setItemsList([]);
  };
  const onDropdownItemClick = ({ value, id }) => {
    switch (value) {
      case "ADD_TAGS": {
        setAddItemModalState(id);
        setItemsList([]);
        break;
      }
      case "REMOVE_TAGS": {
        setRemoveItemsModalState(id);
        setItemsList([]);
        break;
      }
      case "ADD_GRADES": {
        setAddItemModalState(id);
        setItemsList([]);
        break;
      }
      case "REMOVE_GRADES": {
        setRemoveItemsModalState(id);
        setItemsList([]);
        break;
      }
      default:
        console.error("other case");
    }
  };

  const handleAddItems = () => {
    let updatedNodes = [];
    if (addItemsModalState === "GRADES") {
      updatedNodes = onBulkAddGrades({
        nodes,
        setNodes,
        selectedNodeIds,
        itemsList,
      });
    } else if (addItemsModalState === "TAGS") {
      updatedNodes = onBulkAddTags({
        nodes,
        setNodes,
        selectedNodeIds,
        itemsList,
      });
    }
    updateNodesInDraft({ updatedNodes });
    hideAddItemsModal();
  };
  //this function creates a new tag
  const onCreateNewTag = async value => {
    const input = [{ label: value }];
    setIsLoading(true);
    const newTag = await createMultiLevelTags({
      input,
      inputForMultiLevelTagsQuery,
    });
    setIsLoading(false);
    if (newTag) {
      setItemsList(prevTags => [...prevTags, newTag]);
    }
  };

  //code related to bulk remove tags
  const handleRemoveItems = () => {
    let updatedNodes = [];
    if (removeItemsModalState === "GRADES") {
      updatedNodes = onBulkRemoveGrades({
        nodes,
        setNodes,
        selectedNodeIds,
        itemsList,
      });
    } else if (removeItemsModalState === "TAGS") {
      updatedNodes = onBulkRemoveTags({
        nodes,
        setNodes,
        selectedNodeIds,
        itemsList,
      });
    }

    updateNodesInDraft({ updatedNodes });
    hideRemoveItemsModal();
  };
  //cumulative list of tags as input for remove tags modal
  const getCumulativeTagsList = () => {
    const cumulativeTagMap = new Map();
    for (const nodeId of selectedNodeIds) {
      const node = nodes[nodeId];
      const tags = _.get(node, "tags", []);
      for (const tag of tags) {
        if (!_.isEmpty(tag)) {
          cumulativeTagMap.set(tag.id, tag);
        }
      }
    }
    let cumulativeTagsList = [...cumulativeTagMap.values()];
    cumulativeTagsList = _.map(cumulativeTagsList, tag => {
      return { id: tag.id, label: organizationTagsObjectMap[tag.id].label };
    });
    return cumulativeTagsList;
  };

  // localized inner texts and relative functions attached
  const ITEMS_CONFIG = {
    TAGS: {
      ADD: {
        buttonText1: t("common:cancel"),
        buttonText2: t("common:add"),
        modalTitle: t("common:add_with_label", { label: t("common:tags") }),
        searchPlaceholderText: t("scopeAndSequence:enter_a_item", {
          label: t("common:tag"),
        }),
        noItemsAddedText: t("scopeAndSequence:no_item_added", {
          type: t("common:tags"),
        }),
        noItemsText: t("scopeAndSequence:no_item_created", {
          type: t("common:tags"),
        }),
        noItemsSubText: t("scopeAndSequence:go_ahead_tags_desc", {
          msg: t("scopeAndSequence:create_first_tag"),
        }),
        tag: t("common:tag"),
        isCreateItems: true,
        onCreateNewTag: onCreateNewTag,
      },
      REMOVE: {
        buttonText1: t("common:cancel"),
        buttonText2: t("common:save"),
        modalTitle: t("common:remove_with_label", { label: t("common:tags") }),
        noItemsText: t("scopeAndSequence:no_item_added", {
          type: t("common:tags"),
        }),
        noItemsSubText: t("scopeAndSequence:go_ahead_tags_desc", {
          msg: t("scopeAndSequence:add_first_tag"),
        }),
        tags: t("common:tags"),
        modalDescription: t("scopeAndSequence:remove_items_description", {
          item: t("common:tag"),
          items: t("common:tags"),
        }),
      },
    },
    GRADES: {
      ADD: {
        buttonText1: t("common:cancel"),
        buttonText2: t("common:add"),
        modalTitle: t("common:add_with_label", {
          label: t("common:grade_plural"),
        }),
        searchPlaceholderText: t("scopeAndSequence:enter_a_item", {
          label: t("common:grade"),
        }),
        noItemsAddedText: t("scopeAndSequence:no_item_added", {
          type: t("common:grade_plural"),
        }),
        noItemsText: t("scopeAndSequence:no_item_created", {
          type: t("common:grade_plural"),
        }),
        noItemsSubText: t("scopeAndSequence:go_ahead_tags_desc", {
          msg: t("scopeAndSequence:add_first_grade"),
        }),
        tag: t("common:grade_plural"),
        isCreateItems: false,
        onCreateNewTag: null,
      },
      REMOVE: {
        buttonText1: t("common:cancel"),
        buttonText2: t("common:save"),
        modalTitle: t("common:remove_with_label", {
          label: t("common:grade_plural"),
        }),
        noItemsText: t("scopeAndSequence:no_item_added", {
          type: t("common:grade_plural"),
        }),
        noItemsSubText: t("scopeAndSequence:go_ahead_tags_desc", {
          msg: t("scopeAndSequence:add_first_grade"),
        }),
        tags: t("common:grade_plural"),
        modalDescription: t("scopeAndSequence:remove_items_description", {
          item: t("common:grade"),
          items: t("common:grade_plural"),
        }),
      },
    },
  };

  const getCumulativeGradesList = () => {
    let grades = [];
    for (const nodeId of selectedNodeIds) {
      const currentNode = nodes[nodeId];
      const currentNodeGrades = currentNode.grades;

      grades = _.unionBy(currentNodeGrades, grades, "id");
    }

    const gradeItems = _.map(grades, ({ id }) => ({
      id,
      label: _.get(gradeOptions[id], "label", ""),
    }));
    return gradeItems;
  };

  const getCumulativeList = () => {
    if (removeItemsModalState === "TAGS") {
      return getCumulativeTagsList();
    } else if (removeItemsModalState === "GRADES") {
      return getCumulativeGradesList();
    }

    return []; // exception case
  };
  const cumulativeList = getCumulativeList();

  const renderDropdowns = _.map(DROPDOWN_CONFIG({ t }), obj => {
    const { label, icon, options, id } = obj;
    const buttonComponent = dropdownHeader({ label, icon });

    return (
      <React.Fragment key={id}>
        <ButtonDropdown
          authTabs={options}
          itemStyle={dropDownStyles.dropDownItemStyle}
          onItemClick={value => onDropdownItemClick({ value, id })}
          buttonComponent={buttonComponent}
          textStyle={dropDownStyles.dropDownTextStyle}
          containerStyle={dropDownStyles.containerStyle}
        />
      </React.Fragment>
    );
  });

  const getAddItems = () => {
    if (addItemsModalState === "GRADES") {
      return getSortedArrayMemoized({ inputObject: gradeOptions });
    } else if (addItemsModalState === "TAGS") {
      return organizationTags;
    }
    return []; // exception case
  };

  const filterAddItems = getAddItems();
  return (
    <div className={classes.container}>
      <div className={classes.selectedStandards}>{selectedStandardsText}</div>
      <div className={classes.actionsContainer}>
        {renderDropdowns}
        <Button
          variant={"outlined-subtle"}
          size={"small"}
          icon={<DeleteOutlined />}
          onClick={toggleDialogueBoxDisplay}
        >
          {deleteText}
        </Button>
      </div>
      {isDialogueBoxOpen && (
        <DialogueBox
          modalTitle={dialogueBoxConfig.modalTitle}
          showModal={true}
          onClickButton2={handleDeleteClick}
          modalBody={dialogueBoxConfig.modalBody}
          toggleDialogueBoxDisplay={toggleDialogueBoxDisplay}
          button1={dialogueBoxConfig.cancelText}
          button2={dialogueBoxConfig.confirmText}
          modalBodyStyle={dialogueBoxStyles.modalBodyStyle}
          footerContainerStyle={dialogueBoxStyles.footerContainerStyle}
          customModalContentClass={dialogueBoxConfig.dialogContentClass}
        />
      )}

      {addItemsModalState && (
        <AddItemsModal
          items={filterAddItems}
          onClickButton1={hideAddItemsModal}
          onClickButton2={handleAddItems}
          buttonText1={ITEMS_CONFIG[addItemsModalState].ADD.buttonText1}
          buttonText2={ITEMS_CONFIG[addItemsModalState].ADD.buttonText2}
          modalTitle={ITEMS_CONFIG[addItemsModalState].ADD.modalTitle}
          searchPlaceholderText={
            ITEMS_CONFIG[addItemsModalState].ADD.searchPlaceholderText
          }
          noItemsAddedText={
            ITEMS_CONFIG[addItemsModalState].ADD.noItemsAddedText
          }
          noItemsText={ITEMS_CONFIG[addItemsModalState].ADD.noItemsText}
          noItemsSubText={ITEMS_CONFIG[addItemsModalState].ADD.noItemsSubText}
          isCreateItems={ITEMS_CONFIG[addItemsModalState].ADD.isCreateItems}
          onCreateItemClick={
            ITEMS_CONFIG[addItemsModalState].ADD.onCreateNewTag
          }
          itemName={ITEMS_CONFIG[addItemsModalState].ADD.itemName}
          selectedItemsList={itemsList}
          setSelectedItemsList={setItemsList}
        />
      )}
      {removeItemsModalState && (
        <RemoveItemsModal
          items={cumulativeList}
          onClickButton1={hideRemoveItemsModal}
          onClickButton2={handleRemoveItems}
          buttonText1={ITEMS_CONFIG[removeItemsModalState].REMOVE.buttonText1}
          buttonText2={ITEMS_CONFIG[removeItemsModalState].REMOVE.buttonText2}
          modalTitle={ITEMS_CONFIG[removeItemsModalState].REMOVE.modalTitle}
          noItemsText={ITEMS_CONFIG[removeItemsModalState].REMOVE.noItemsText}
          noItemsSubText={
            ITEMS_CONFIG[removeItemsModalState].REMOVE.noItemsSubText
          }
          itemName={ITEMS_CONFIG[removeItemsModalState].REMOVE.tags}
          removedItemsList={itemsList}
          setRemovedItemsList={setItemsList}
          modalDescription={
            ITEMS_CONFIG[removeItemsModalState].REMOVE.modalDescription
          }
        />
      )}
      {isLoading && <FullScreenLoader />}
    </div>
  );
};

const mapActionCreators = {
  createMultiLevelTags,
};

export default compose(connect(null, mapActionCreators))(TableBulkActions);
