import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";

import classes from "./AddChildPage.scss";
import {
  UIButton,
  UIModal,
  withLoader,
  InputTextField,
  I18nHOC,
  FullScreenLoader,
} from "UIComponents";

import {
  setChildStatus,
  getUserInfo,
  logoutUser,
} from "Login/modules/LoginModule";
import { getChildrenQuery } from "modules/CommonQuery";
import { getChildrenFromCache } from "modules/CommonGraphqlHelpers";
import { addChild } from "Courses/modules/CoursesModule";

import ChildModal from "./ChildModal";

import { colors, fontStyle } from "Constants";

const commonStyles = {
  textAlign: "center",
  ...fontStyle.demiBold,
  fontWeight: "600",
  fontSize: "1.6rem",
  lineHeight: "2rem",
};

const styles = {
  addChildButton: {
    ...commonStyles,
    width: "320px",
    height: "56px",
  },
  doneButton: {
    ...commonStyles,
    height: "56px",
    width: "320px",
  },
  buttonStyle: {
    ...commonStyles,
    height: "20px",
    minWidth: "136px",
    backgroundColor: "none",
    color: colors.gray48,
    border: "none",
  },
  inputStyle: {
    height: "48px",
    textAlign: "center",
  },
};

const renderErrorComponent = error => {
  return <div className={classes.errorText}>{error}</div>;
};

const AddChildPage = props => {
  const { setChildStatus, children, t } = props;

  // local state
  const [studentCode, setStudentCode] = useState("");
  const [addChildModal, setAddChildModal] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [childDetails, setChildDetails] = useState({});
  const [showCustomError, setShowCustomError] = useState(false);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // ref
  const inputRef = useRef();

  // if children present then skip component render
  if (!_.isEmpty(children)) {
    setChildStatus(true);
    return null;
  }

  // handle input data
  const handleInputCode = value => {
    setStudentCode(value.studentCode);
  };

  // button click
  const onAddChildClick = async () => {
    const { addChild, userId } = props;

    if (!studentCode) {
      inputRef.current?.isValid();
    } else {
      setShowFullScreenLoader(true);

      const childData = await addChild({
        studentCode,
        userId,
        signInFlow: true,
        setErrorMessage,
      });

      setShowFullScreenLoader(false);

      if (!_.isEmpty(childData)) {
        setStudentCode("");
        setChildDetails(childData);
        setAddChildModal(false);
        setSuccessModal(true);
      } else {
        setShowCustomError(true);
        inputRef.current?.isValid();
      }
    }
  };

  const onDoneClick = () => {
    setChildStatus(true);
  };

  // util function
  const customValidation = () => {
    if (showCustomError) {
      return errorMessage;
    }
  };

  return (
    <div className={classes.container}>
      {/* Render Add Child Modal */}
      {addChildModal && (
        <UIModal isOpen={true} modalContent={classes.modalContent}>
          <div className={classes.modalContainer}>
            <div className={classes.header}>
              <div className={classes.headerTitle}>
                {`${t("common:welcome_to_toddle")}!`}
              </div>
            </div>
            <div className={classes.description}>
              {t("common:add_child_using_code_to_start")}
            </div>
            <div className={classes.inputField}>
              <InputTextField
                placeholder={t("common:enter_with_label", {
                  label: t("common:family_invite_code"),
                })}
                name={"studentCode"}
                value={studentCode}
                updateInputField={handleInputCode}
                ref={inputRef}
                showErrorText={true}
                renderErrorComponent={renderErrorComponent}
                customValidation={customValidation}
                error={t("common:enter_family_code_to_proceed")}
                inputStyle={styles.inputStyle}
              />
            </div>
            <div className={classes.button}>
              <UIButton
                color="blue"
                containerStyle={styles.addChildButton}
                onClick={onAddChildClick}
              >
                {t("common:add_with_label", { label: t("common:child") })}
              </UIButton>
            </div>
            <div className={classes.cancelAndLogoutButton}>
              <UIButton
                containerStyle={styles.buttonStyle}
                onClick={logoutUser}
              >
                {t("common:cancel_and_logout")}
              </UIButton>
            </div>
          </div>
        </UIModal>
      )}
      {/* Render Success Modal */}
      {successModal && (
        <ChildModal
          childId={childDetails.id}
          firstName={childDetails.firstName}
          profileImage={childDetails.profileImage}
          onDoneClick={onDoneClick}
        />
      )}
      {/* FullScreenLoader */}
      {showFullScreenLoader && <FullScreenLoader />}
    </div>
  );
};

const mapStateToProps = () => {
  const portalType = "PLANNER";
  const userInfo = getUserInfo({ portalType });
  const userId = _.get(userInfo, "id", "");
  return {
    userId,
    portalType,
  };
};

const mapActionCreators = {
  setChildStatus,
  addChild,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common"] }),
  graphql(getChildrenQuery, {
    name: "getChildren",
    options: ({ userId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: "FAMILY_MEMBER",
        portalType,
      },
    }),
    props({ getChildren, ownProps: { userId, portalType } }) {
      const childrenData = getChildrenFromCache({
        id: userId,
        type: "FAMILY_MEMBER",
        portalType,
      });
      const children = _.get(childrenData, "children", []);

      return {
        isData: !_.isEmpty(childrenData),
        isLoading:
          getChildren["networkStatus"] == 1 ||
          getChildren["networkStatus"] == 2,
        children,
      };
    },
  }),
  withLoader
)(AddChildPage);
