import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import Explore from "./routes/Explore";
import MyProgress from "./routes/MyProgress";
import SchoolProgress from "./routes/SchoolProgress";
import Module from "./routes/Module";

export default store => ({
  path: "toddle-academy",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ToddleAcademy/modules/ToddleAcademyModules.js");
            injectReducer(store, {
              key: reducer.NAME,
              reducer: reducer.default,
            });
            cb(component);
          },
          err => {
            reject(err);
          },
          "toddle-academy"
        );
      })
  ),
  childRoutes: [
    Explore(store),
    MyProgress(store),
    SchoolProgress(store),
    Module(store),
  ],
});
