import gql from "graphql-tag";
import {
  organizationGeneratedTemplateFragment,
  progressReportTemplateFragment,
  organizationProgressReportTemplateFragment,
  setProgressReportFragment,
  courseProgressReportFragment,
  courseStudentProgressReportFragment,
  studentProgressReportFragment,
  publishedStudentProgressReportFragment,
  organizationProgressReportGroupFragment,
  progressReportObjectiveFeedFragment,
  studentProgressReportConfigurationUnitsFragment,
} from "./ProgressReportFragments";
import { studentFragment } from "modules/CommonFragments";

export const progressReportGeneratedTemplateQuery = gql`
  query progressReportGeneratedTemplate(
    $organizationId: ID!
    $grades: [ID!]
    $curriculumType: CURRICULUM_PROGRAM_TYPE_ENUM
    $type: PROGRESS_REPORT_TEMPLATE_TYPE_ENUM
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationGeneratedTemplateItem
      }
    }
  }
  ${organizationGeneratedTemplateFragment.organizationGeneratedTemplate}
`;

export const getProgressReportTemplateQuery = gql`
  query getProgressReportTemplate($id: ID!, $skipParam: Boolean!) {
    node(id: $id, type: PROGRESS_REPORT_TEMPLATE) {
      id
      ... on ProgressReportTemplate {
        ...progressReportTemplateItem
      }
    }
  }
  ${progressReportTemplateFragment.progressReportTemplateDetails}
`;

export const getProgressReportTemplateFeedQuery = gql`
  query getProgressReportTemplateFeed(
    $organizationId: ID!
    $filters: ProgressReportTemplateFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationProgressReportTemplateItem
      }
    }
  }
  ${organizationProgressReportTemplateFragment.organizationProgressReportTemplate}
`;

export const getProgressReportTemplatesQuery = gql`
  query getProgressReportTemplates(
    $id: ID!
    $filters: ProgressReportTemplateFilter
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        progressReportTemplates(filters: $filters) {
          edges {
            node {
              id
              name
              grades {
                id
                name
              }
              isAttendanceEnabled
            }
          }
        }
      }
    }
  }
`;

export const getOrganizationCourseFeedQuery = gql`
  query getOrganizationCourseFeed($filters: CourseFilterInput) {
    platform {
      organization {
        id
        courses(filters: $filters) {
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
          edges {
            node {
              id
              title
              profileImage
              grade {
                id
              }
            }
          }
        }
      }
    }
  }
`;

//We fetch courses from Organization using node interface
export const getOrganizationCourseFeedV1Query = gql`
  query getOrganizationCourseFeedV1($id: ID!, $filters: CourseFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        courses(filters: $filters) {
          edges {
            node {
              id
              title
              grades {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const getSetProgressReportFeedQuery = gql`
  query getSetProgressReportFeed($id: ID!, $searchText: String) {
    node(id: $id, type: PROGRESS_REPORT_TASK_GROUP) {
      id
      ... on ProgressReportTaskGroup {
        ...setProgressReportFragmentItem
      }
    }
  }
  ${setProgressReportFragment.setProgressReport}
`;

export const getOrganizationProgressReportGroupFeedQuery = gql`
  query getOrganizationProgressReportGroupFeed(
    $organizationId: ID!
    $searchText: String
    $academicYearId: ID
    $after: String
    $curriculumProgramIds: [ID!]
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationProgressReportGroupItem
      }
    }
  }
  ${organizationProgressReportGroupFragment.organizationProgressReportGroup}
`;

export const downloadProgressReportGroupAsCsvQuery = gql`
  query downloadProgressReportGroupAsCsv($taskGroupId: ID!) {
    node(id: $taskGroupId, type: PROGRESS_REPORT_TASK_GROUP) {
      id
      ... on ProgressReportTaskGroup {
        downloadAsCsv
      }
    }
  }
`;

export const getStudentProgressReportSubjectsQuery = gql`
  query getStudentProgressReportSubjects($studentProgressReportId: ID!) {
    node(id: $studentProgressReportId, type: STUDENT_PROGRESS_REPORT) {
      id
      ... on StudentProgressReport {
        id
        reportSubjects {
          id
        }
      }
    }
  }
`;

export const getCourseProgressReportFeedQuery = gql`
  query getCourseProgressReportFeed($courseId: ID!, $searchText: String!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseProgressReportItem
      }
    }
  }
  ${courseProgressReportFragment.courseProgressReportFeed}
`;

export const getCourseProgressReportFeedCountQuery = gql`
  query getCourseProgressReportFeedCount($courseId: ID!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseProgressReportItem
      }
    }
  }
  ${courseProgressReportFragment.courseProgressReportFeedCount}
`;

export const getCourseStudentProgressReportFeedQuery = gql`
  query getCourseStudentProgressReportFeed(
    $reportId: ID!
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $filters: TaskStudentProgressReportFilter
    $courseFilters: CourseFilter
    $orderByInput: StudentProgressReportOrderByInput
  ) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        ...courseStudentProgressReportItem
      }
    }
  }
  ${courseStudentProgressReportFragment.courseStudentProgressReportFeed}
`;

export const getCourseProgressReportStudentsDetailQuery = gql`
  query getCourseStudentProgressReportStudentDetails(
    $reportId: ID!
    $filters: TaskStudentProgressReportFilter
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
  ) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        id
        studentProgressReport(
          filters: $filters
          orderBy: $orderBy
          orderByDirection: $orderByDirection
        ) {
          id
          student {
            ...studentItem
          }
          sectionCompleteStatus {
            section
            isCompleted
          }
          status
        }
      }
    }
  }
  ${studentFragment.basicStudentDetails}
`;

export const getCourseStudentProgressReportBasicDetailsQuery = gql`
  query getCourseStudentProgressReportBasicDetails($reportId: ID!) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        ...courseStudentProgressReportItem
      }
    }
  }
  ${courseStudentProgressReportFragment.courseStudentProgressReportBasicDetails}
`;

export const getCourseStudentProgressReportTemplateQuery = gql`
  query getCourseStudentProgressReportTemplate($reportId: ID!) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        ...courseStudentProgressReportItem
      }
    }
  }
  ${courseStudentProgressReportFragment.courseStudentProgressReportTemplate}
`;

export const getStudentProgressReportDetailsQuery = gql`
  query getStudentProgressReportDetails($studentProgressReportId: ID!) {
    node(id: $studentProgressReportId, type: STUDENT_PROGRESS_REPORT) {
      id
      ... on StudentProgressReport {
        ...studentProgressReportItem
      }
    }
  }
  ${studentProgressReportFragment.studentProgressReportDetails}
`;

export const getStudentProgressReportPublishedTemplateQuery = gql`
  query getStudentProgressReportPublishedTemplate(
    $studentProgressReportId: ID!
  ) {
    node(id: $studentProgressReportId, type: STUDENT_PROGRESS_REPORT) {
      id
      ... on StudentProgressReport {
        ...studentProgressReportItem
      }
    }
  }
  ${studentProgressReportFragment.studentProgressReportPublishedTemplate}
`;

export const getStudentProgressReportTemplateQuery = gql`
  query getStudentProgressReportTemplate($studentProgressReportId: ID!) {
    node(id: $studentProgressReportId, type: STUDENT_PROGRESS_REPORT) {
      id
      ... on StudentProgressReport {
        ...studentProgressReportItem
      }
    }
  }
  ${studentProgressReportFragment.studentProgressReportTemplate}
`;

export const getPublishedStudentProgressReportDetailsQuery = gql`
  query getPublishedStudentProgressReportDetails($studentId: ID!) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...publishedStudentProgressReportItem
      }
    }
  }
  ${publishedStudentProgressReportFragment.publishedStudentProgressReport}
`;

export const getCourseProgressReportTaskPublishIdsQuery = gql`
  query getCourseProgressReportTaskPublishIds($reportId: ID!) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        allPublishedProgressReport {
          id
        }
      }
    }
  }
`;

export const getStudentProgressReportPublishIdQuery = gql`
  query getStudentProgressReportPublishId($studentProgressReportId: ID!) {
    node(id: $studentProgressReportId, type: STUDENT_PROGRESS_REPORT) {
      id
      ... on StudentProgressReport {
        publishedReport {
          id
        }
      }
    }
  }
`;

export const getCourseWiseProgressReportMetricsQuery = gql`
  query getCourseWiseMetrics($id: ID!) {
    node(id: $id, type: PROGRESS_REPORT_TASK_GROUP) {
      id
      ... on ProgressReportTaskGroup {
        id
        title
        courseWiseMetrics {
          course {
            id
            title
          }
          statusCount {
            status
            count
          }
        }
      }
    }
  }
`;

export const getStudentProgressReportBasicDetailsQuery = gql`
  query getStudentProgressReportBasicDetails(
    $studentProgressReportId: ID!
    $courseFilters: CourseFilter
  ) {
    node(id: $studentProgressReportId, type: STUDENT_PROGRESS_REPORT) {
      id
      ... on StudentProgressReport {
        ...studentProgressReportItem
      }
    }
  }
  ${studentProgressReportFragment.studentProgressReportFeed}
`;

export const getCourseWiseStudentBasicDetailsQuery = gql`
  query getCourseWiseStudentBasicDetails(
    $id: ID!
    $filters: GroupStudentProgressReportFilter
  ) {
    node(id: $id, type: PROGRESS_REPORT_TASK_GROUP) {
      id
      ... on ProgressReportTaskGroup {
        studentProgressReports(filters: $filters) {
          id
          student {
            id
            firstName
            lastName
          }
          status
          sectionCompleteStatus {
            section
            isCompleted
          }
        }
      }
    }
  }
`;

export const getSubjectGroupWiseReportMetricsQuery = gql`
  query subjectGroupWiseMetrics($reportId: ID!, $searchText: String!) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        id
        subjectGroupWiseMetrics(filters: { searchText: $searchText }) {
          subjectGroup {
            id
            name
          }
          courseWiseStats {
            total
            pending
            completed
            notAssessed
            course {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const getCourseWiseMetricsQuery = gql`
  query courseWiseMetrics($reportId: ID!, $searchText: String!) {
    node(id: $reportId, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        id
        courseWiseMetrics(filters: { searchText: $searchText }) {
          total
          pending
          completed
          notAssessed
          course {
            id
            title
          }
        }
      }
    }
  }
`;

export const getCourseDetailsQuery = gql`
  query getCourseDetails($id: ID!) {
    node(id: $id, type: COURSE) {
      ... on Course {
        id
        title
        subjects {
          id
          name
        }
      }
    }
  }
`;

export const getSetProgressReportGradeSubjectObjectivesQuery = gql`
  query getSetProgressReportObjectiveFeed(
    $id: ID!
    $filters: TaskGroupSubjectGradeObjectiveFilter
  ) {
    node(id: $id, type: PROGRESS_REPORT_TASK_GROUP) {
      id
      ... on ProgressReportTaskGroup {
        ...progressReportObjectiveFeed
      }
    }
  }
  ${progressReportObjectiveFeedFragment.progressReportObjectiveFeed}
`;
export const getStudentProgressReportUnitsConfigurationQuery = gql`
  query getStudentProgressReportUnitsConfiguration(
    $id: ID!
    $duration: DurationFilter
    $subjects: [ID!]
    $unitTypes: [ID!]
    $fieldUids: [String!]
    $academicYears: [ID!]
  ) {
    node(id: $id, type: GRADE) {
      id
      ... on Grade {
        ...studentProgressReportConfigurationUnits
      }
    }
  }
  ${studentProgressReportConfigurationUnitsFragment.studentProgressReportConfigurationUnits}
`;

export const getDescriptorForCriterionQuery = gql`
  query getDescriptorForCriterion($input: DescriptorForCriterionInput!) {
    planner {
      getDescriptorForCriterion(input: $input)
    }
  }
`;

export const getStudentsFromGradeQuery = gql`
  query getStudentFromGrade($studentFilters: GradeStudentsFilters, $id: ID!) {
    node(id: $id, type: GRADE) {
      id
      ... on Grade {
        id
        students(filters: $studentFilters) {
          totalCount
          edges {
            node {
              id
              firstName
              lastName
              profileImage
            }
          }
        }
      }
    }
  }
`;

export const getStaffCoursesQuery = gql`
  query getStaffCourses($id: ID!, $filters: CourseFilter) {
    node(id: $id, type: STAFF) {
      id
      ... on Staff {
        courses(filters: $filters) {
          id
          title
        }
      }
    }
  }
`;

export const getCourseProgressReportTaskBasicDetailsQuery = gql`
  query getCourseProgressReportTaskBasicDetails($id: ID!) {
    node(id: $id, type: COURSE_PROGRESS_REPORT_TASK) {
      id
      ... on CourseProgressReportTask {
        title
        parentEntity {
          id
          ... on Grade {
            id
            name
          }
        }
      }
    }
  }
`;
