import React, { PureComponent } from "react";
import { compose } from "redux";
import classes from "./SinglePointRubricTable.scss";
import { TextAreaInput, I18nHOC } from "UIComponents";
import { colors, fontStyle } from "Constants";
import { connect } from "react-redux";
import classNames from "classnames";
import { updateField } from "modules/Services";
import { isTouchable } from "Utils";
import {
  writeSinglePointRubricElementLocal,
  createSinglePointRubricStandard,
  deleteSinglePointRubricStandard,
} from "SinglePointRubric/modules/SinglePointRubricModule";
import { Button, IconButton, Tooltip } from "@toddle-design/web";
import { AddOutlined, DeleteOutlined } from "@toddle-design/web-icons";

const textAreaStyles = {
  cellTitle: {
    border: "none",
    marginTop: 0,
    flexShrink: 0,
    padding: "0 24px 0 0",
    color: colors.textDefault,
    lineHeight: 1.57,
    fontSize: "1.6rem",
    width: "100%",
    ...fontStyle.bold,
    overflow: "hidden",
  },
  headerCellTitle: {
    lineHeight: 1.5,
    fontSize: "1.6rem",
    padding: 0,
    color: colors.surfaceWhite,
  },
  cellSubTitle: {
    border: "none",
    marginTop: 0,
    flexShrink: 0,
    padding: 0,
    color: colors.textSubtle,
    lineHeight: 1.57,
    fontSize: "1.6rem",
    width: "100%",
    ...fontStyle.medium,
  },
  headerSubCellTitle: {
    display: "flex",
    color: colors.surfaceWhite,
  },
  cellResponse: {
    border: "none",
    marginTop: 0,
    flexShrink: 0,
    padding: 0,
    color: colors.textDefault,
    lineHeight: 1.57,
    letterSpacing: "-0.1px",
    fontSize: "1.6rem",
    width: "100%",
    ...fontStyle.medium,
  },
};

class SinglePointRubricTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inFocusRow: null,
      inFocusCell: null,
    };
    if (props.customRef) {
      props.customRef(this);
    }
    this.cellRefs = {};
    this.focusedCell = null;
  }

  blur = () => {
    if (this.focusedCell && this.cellRefs[this.focusedCell]) {
      this.cellRefs[this.focusedCell].blur();
    }
  };

  writeSinglePointRubricElement = ({ id, type, params, elementDetails }) => {
    const { updateField, writeSinglePointRubricElementLocal } = this.props;
    writeSinglePointRubricElementLocal({ id, type, params, elementDetails });
    updateField({
      key: id,
      params: { id, type, params },
      type: "SINGLE_POINT_RUBRIC_ELEMENT",
    });
  };

  getHeaderRow = () => {
    const { mode, rubric, isEvaluation, t } = this.props;

    const criterias = _.get(rubric, "criterias", []);
    return (
      <div className={classes.pseudoRow}>
        <div className={classes.headerRow}>
          {_.map(criterias, criteriaObj => {
            const disabled = _.includes(criteriaObj.id, "NEW");
            const cellClass = classNames({
              [classes.rowHeaderCell]: true,
              [classes.focusedCell]:
                !isEvaluation && this.state.inFocusCell == criteriaObj.id,

              [classes.evalutionCell]: isEvaluation,
              [classes.disabled]: disabled,
            });

            const refTitleKey = `Header_${criteriaObj.id}_Title`;
            const refSubtitleKey = `Header_${criteriaObj.id}_Subtitle`;
            return (
              <div className={classes.pseudoColumn} key={criteriaObj.id}>
                <div
                  className={cellClass}
                  onFocus={() => this.setState({ inFocusCell: criteriaObj.id })}
                >
                  <TextAreaInput
                    value={criteriaObj.title}
                    mode={mode}
                    textAreaStyles={{
                      ...textAreaStyles.cellTitle,
                      ...textAreaStyles.headerCellTitle,
                    }}
                    onBlurInputField={() => (this.focusedCell = null)}
                    onFocusInputField={() => (this.focusedCell = refTitleKey)}
                    updateInputRef={ref => (this.cellRefs[refTitleKey] = ref)}
                    disabled={disabled}
                    minRows={1}
                    emptyText={""}
                    name={"title"}
                    placeholder={t("common:click_to_add_withLabel", {
                      label: t("common:standard"),
                    })}
                    updateInputField={params =>
                      this.writeSinglePointRubricElement({
                        id: criteriaObj.id,
                        params,
                        type: "CRITERIA",
                        elementDetails: criteriaObj,
                      })
                    }
                    className={classes.placeholderTextOnSubtle}
                  />
                  <TextAreaInput
                    value={criteriaObj.subTitle}
                    mode={mode}
                    textAreaStyles={{
                      ...textAreaStyles.cellSubTitle,
                      ...textAreaStyles.headerSubCellTitle,
                    }}
                    onBlurInputField={() => (this.focusedCell = null)}
                    onFocusInputField={() =>
                      (this.focusedCell = refSubtitleKey)
                    }
                    updateInputRef={ref =>
                      (this.cellRefs[refSubtitleKey] = ref)
                    }
                    containerStyle={{ marginTop: 4 }}
                    disabled={disabled}
                    minRows={1}
                    name={"subTitle"}
                    emptyText={""}
                    placeholder={t("common:definition")}
                    updateInputField={params =>
                      this.writeSinglePointRubricElement({
                        id: criteriaObj.id,
                        params,
                        type: "CRITERIA",
                        elementDetails: criteriaObj,
                      })
                    }
                    className={classes.placeholderColor}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  getRows = () => {
    const { mode } = this.props;
    const { inFocusRow } = this.state;

    const standards = _.get(this.props.rubric, "standards", []);
    return _.map(standards, standardObj => {
      return (
        <div
          className={classes.pseudoRow}
          key={standardObj.id}
          onFocus={() => {
            this.setState({ inFocusRow: standardObj.id });
          }}
        >
          <div className={classes.row}>{this.getColumns(standardObj)}</div>
        </div>
      );
    });
  };

  getColumns = standardObj => {
    const { mode, rubric, isEvaluation, t } = this.props;

    const criterias = _.get(rubric, "criterias", []);

    return _.map(criterias, (criteriaObj, index) => {
      const isCriteriaStandardColumn = criteriaObj.isCriteriaStandardColumn;

      const disabled = _.includes(standardObj.id, "NEW");
      const cellClass = classNames({
        [classes.cell]: true,
        [classes.focusedCell]:
          !isEvaluation &&
          this.state.inFocusCell == standardObj.id &&
          isCriteriaStandardColumn,
        [classes.evalutionCell]: isEvaluation,

        [classes.nonCriteriaCell]: !isCriteriaStandardColumn,
        [classes.nonCriteriaEvaluationCell]:
          isEvaluation && !isCriteriaStandardColumn,
        [classes.disabled]: disabled,
      });

      const refTitleKey = `Row_${standardObj.id}_Title`;
      const refSubtitleKey = `Row_${standardObj.id}_Subtitle`;

      return (
        <div
          className={cellClass}
          key={criteriaObj.id}
          onFocus={() => this.setState({ inFocusCell: standardObj.id })}
        >
          {isCriteriaStandardColumn ? (
            <React.Fragment>
              <TextAreaInput
                value={standardObj.title}
                textAreaStyles={{
                  ...textAreaStyles.cellTitle,
                }}
                onBlurInputField={() => (this.focusedCell = null)}
                onFocusInputField={() => (this.focusedCell = refTitleKey)}
                updateInputRef={ref => (this.cellRefs[refTitleKey] = ref)}
                minRows={1}
                emptyText={""}
                disabled={disabled}
                mode={mode}
                placeholder={t("unitPlan:enter_criteria_title")}
                name={"title"}
                updateInputField={params =>
                  this.writeSinglePointRubricElement({
                    id: standardObj.id,
                    params,
                    type: "STANDARD",
                    elementDetails: standardObj,
                  })
                }
                className={classes.placeholderColor}
              />
              <TextAreaInput
                value={standardObj.subTitle}
                textAreaStyles={{
                  ...textAreaStyles.cellSubTitle,
                }}
                onBlurInputField={() => (this.focusedCell = null)}
                onFocusInputField={() => (this.focusedCell = refSubtitleKey)}
                updateInputRef={ref => (this.cellRefs[refSubtitleKey] = ref)}
                minRows={1}
                containerStyle={{ marginTop: 8 }}
                emptyText={""}
                disabled={disabled}
                mode={mode}
                placeholder={t("common:click_to_add_withLabel", {
                  label: t("common:definition"),
                })}
                name={"subTitle"}
                updateInputField={params =>
                  this.writeSinglePointRubricElement({
                    id: standardObj.id,
                    params,
                    type: "STANDARD",
                    elementDetails: standardObj,
                  })
                }
                className={classes.placeholderColor}
              />

              {mode !== "view" && (
                <div className={classes.actionButton}>
                  <Tooltip
                    tooltip={t("common:delete_with_label", {
                      label: t("common:row"),
                    })}
                  >
                    <IconButton
                      icon={<DeleteOutlined variant="subtle" />}
                      variant={"plain"}
                      size={"x-small"}
                      onClick={() =>
                        this.deleteSinglePointRubricStandard(standardObj?.id)
                      }
                      disabled={_.includes(standardObj?.id, "NEW")}
                    />
                  </Tooltip>
                </div>
              )}
            </React.Fragment>
          ) : (
            isEvaluation &&
            this.getNonCriteriaEvaluationCell({ criteriaObj, standardObj })
          )}
        </div>
      );
    });
  };

  getNonCriteriaEvaluationCell = ({ standardObj, criteriaObj }) => {
    const {
      isEvaluationEnable,
      isEvaluatedByStudent,
      toolEvaluationResponses,
      updateEvaluationCell,
      userType,
      t,
    } = this.props;
    const teacherEvalution = _.get(
      _.find(
        toolEvaluationResponses,
        response =>
          response.rowId == standardObj.id &&
          response.columnId == criteriaObj.id &&
          response.creatorType == "STAFF"
      ),
      "subjectiveResponse"
    );

    const studentEvalution = _.get(
      _.find(
        toolEvaluationResponses,
        response =>
          response.rowId == standardObj.id &&
          response.columnId == criteriaObj.id &&
          response.creatorType == "STUDENT"
      ),
      "subjectiveResponse",
      ""
    );

    const teacherEvaluationCellClass = classNames({
      [classes.evaluationEditCell]: true,
      [classes.evaluationViewCell]:
        !isEvaluationEnable || userType == "student",
      [classes.teacherEvaluationCell]: true,
      [classes.emptyEvaluationCell]: isEvaluationEnable && !!!teacherEvalution,
    });

    const studentEvaluationCellClass = classNames({
      [classes.evaluationEditCell]: true,
      [classes.evaluationViewCell]: !isEvaluationEnable || userType == "staff",
      [classes.studentEvaluationCell]: true,
      [classes.emptyEvaluationCell]: isEvaluationEnable && !!!studentEvalution,
    });

    return (
      <React.Fragment>
        <div className={classes.evaluationCellContainer}>
          <div className={teacherEvaluationCellClass}>
            <div
              className={classes.tickSvgComponent}
              style={{ backgroundColor: colors.blue29 }}
            >
              {/* <Tick width={12} height={12} /> */}
            </div>
            <TextAreaInput
              value={teacherEvalution}
              textAreaStyles={{
                ...textAreaStyles.cellResponse,
                color:
                  !isEvaluationEnable && !!!teacherEvalution
                    ? colors.gray72
                    : "auto",
              }}
              updateInputField={value =>
                updateEvaluationCell({
                  subjectiveResponse: value,
                  rowId: standardObj.id,
                  columnId: criteriaObj.id,
                })
              }
              minRows={2}
              emptyText={"-"}
              mode={isEvaluationEnable && userType == "staff" ? "edit" : "view"}
              placeholder={t("assessmentEvaluation:click_to_type")}
              className={classes.placeholderColor}
            />
          </div>
          {isEvaluatedByStudent && (
            <div className={studentEvaluationCellClass}>
              <div
                className={classes.tickSvgComponent}
                style={{ backgroundColor: colors.yellow50 }}
              ></div>
              <TextAreaInput
                value={studentEvalution}
                textAreaStyles={{
                  ...textAreaStyles.cellResponse,
                  color: !!!studentEvalution ? colors.gray72 : "auto",
                }}
                updateInputField={value =>
                  updateEvaluationCell({
                    subjectiveResponse: value,
                    rowId: standardObj.id,
                    columnId: criteriaObj.id,
                  })
                }
                minRows={1}
                emptyText={"-"}
                mode={
                  isEvaluationEnable && userType == "student" ? "edit" : "view"
                }
                className={classes.placeholderColor}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  createSinglePointRubricStandard = () => {
    const {
      rubric: { id: rubricId },
      createSinglePointRubricStandard,
      setScrollToBottom,
    } = this.props;
    createSinglePointRubricStandard({ rubricId });
    if (setScrollToBottom) {
      setTimeout(setScrollToBottom);
    }
  };

  deleteSinglePointRubricStandard = id => {
    const {
      rubric: { id: rubricId },
      deleteSinglePointRubricStandard,
    } = this.props;

    deleteSinglePointRubricStandard({ id, rubricId });
  };

  render() {
    const {
      mode,
      rubric,
      onClickToolSave,
      containerStyle,
      t,
      showSaveTemplateButton,
    } = this.props;

    return (
      <React.Fragment>
        <div
          onBlur={() => {
            this.setState({ inFocusRow: null, inFocusCell: null });
          }}
          className={classes.container}
          style={
            mode == "view"
              ? { marginTop: 0, ...containerStyle }
              : { ...containerStyle }
          }
        >
          {rubric ? (
            <React.Fragment>
              {this.getHeaderRow()}
              {this.getRows()}
            </React.Fragment>
          ) : (
            <div className={classes.emptyText} style={{ padding: "16px" }}>
              {` No Single Point Rubric defined`}
            </div>
          )}
        </div>
        {mode != "view" && (
          <div className={classes.bottomContainer}>
            <Button
              size={"medium"}
              onClick={this.createSinglePointRubricStandard}
              variant={"outlined-subtle"}
              icon={<AddOutlined />}
            >
              {t("common:add_with_label", { label: t("common:row") })}
            </Button>
            {showSaveTemplateButton && (
              <div
                className={classes.saveTemplateButton}
                onClick={onClickToolSave}
              >
                {t("common:save_this_as_this", {
                  from_label: t("common:single_point_rubric"),
                  to_label: t("common:template"),
                })}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  updateField,
  writeSinglePointRubricElementLocal,
  createSinglePointRubricStandard,
  deleteSinglePointRubricStandard,
};

const mapStateToProps = (state, ownProps) => {
  return {
    userType: state.login.userInfo.user_type,
  };
};

export default compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(SinglePointRubricTable);

SinglePointRubricTable.defaultProps = {
  isEvaluation: false,
  isEvaluationEnable: false,
  toolEvaluationResponses: [],
  isEvaluatedByStudent: false,
  containerStyle: {},
  showSaveTemplateButton: true,
};
