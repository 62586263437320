import gql from "graphql-tag";
import { unitPlanEvidenceFragment } from "./EvidenceFragments";
export const getUnitPlanEvidenceQuery = gql`
  query getUnitPlanEvidence($fieldId: ID, $id: ID!, $uids: [String!]) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        ...unitPlanItem
      }
    }
  }
  ${unitPlanEvidenceFragment.unitPlanEvidence}
`;

export const getUnitPlanEvidenceWithFiltersQuery = gql`
  query getUnitPlanEvidenceWithFilters(
    $filters: PostsFilterCompulsoryUnitPlan
    $id: ID!
  ) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        ...unitPlanItem
      }
    }
  }
  ${unitPlanEvidenceFragment.unitPlanEvidenceWithFilter}
`;
