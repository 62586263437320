import gql from "graphql-tag";
import { gradesItemFragment } from "./ScoreCategoriesFragments";

export const createPointAssessmentSetMutation = gql`
  mutation createPointAssessmentSet($input: [CreatePointAssessmentSetInput!]) {
    planner {
      createPointAssessmentSet(input: $input) {
        warning
        response {
          id
          label
          type
          associatedEntities {
            id
            ... on Course {
              id
              title
            }
            ... on Subject {
              id
              name
            }
            ... on Grade {
              ...gradeItem
            }
          }
          categories {
            id
            label
            colorCode
            percentageWeight
            hlWeight
            slWeight
          }
        }
      }
    }
  }
  ${gradesItemFragment.gradeFragment}
`;

export const updatePointAssessmentSetMutation = gql`
  mutation updatePointassessmentSet($input: [UpdatePointAssessmentSetInput!]) {
    planner {
      updatePointAssessmentSet(input: $input) {
        warning
        response {
          id
          label
          type
          associatedEntities {
            id
            ... on Course {
              id
              title
            }
            ... on Subject {
              id
              name
            }
            ... on Grade {
              ...gradeItem
            }
          }
          categories {
            id
            label
            colorCode
            percentageWeight
            hlWeight
            slWeight
          }
        }
      }
    }
  }
  ${gradesItemFragment.gradeFragment}
`;

export const deletePointAssessmentSetMutation = gql`
  mutation deletePointAssessmentSet($input: DeletePointAssessmentSetInput!) {
    planner {
      deletePointAssessmentSet(input: $input)
    }
  }
`;
