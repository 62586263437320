import React from "react";
import classes from "./SubModule.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import MultiSetting from "./components/MultiSetting";
import SelectionList from "./components/SelectionList";
import SingleSetting from "./components/SingleSetting";
import {
  AttendanceSetup,
  AttendanceRecordingSettings,
} from "routes/routes/Administrator/routes/SchoolSetup/routes/AttendanceSetup/components";
import TextInputSetting from "./components/TextInputSetting";

const SETTING_BLOCK_MULTI_APP = "SETTING_BLOCK_MULTI_APP";
const SETTING_BLOCK_SINGLE_APP = "SETTING_BLOCK_SINGLE_APP";
const ATTENDANCE_CATEGORY = "ATTENDANCE_CATEGORY";
const SECTION = "SECTION";
const SELECTION_LIST = "SELECTION_LIST";
const ATTENDANCE_MODE = "ATTENDANCE_MODE";
const TURNITIN_API_SETTING = "TURNITIN_API_SETTING";

const SubModule = React.memo(
  ({
    subModule,
    allSettings,
    onUpdateSetting,
    subModuleTitleStyles,
    platform,
    isDisabled,
    showChooseCourses,
    classOptions,
    t,
    categories,
    isNotificationConfiguration,
    courseId,
    organizationId,
  }) => {
    const getIsSectionVisible = ({ subModule, allSettings }) => {
      //This will check the dependency of this configuration on other configuration based on the valueType of other configurations.
      const dependentConfigurationIds = _.get(
        subModule,
        "metadata.dependent_configuration_ids",
        []
      );

      let isSectionVisible = true;

      _.forEach(dependentConfigurationIds, configurationId => {
        const configuration = _.find(
          allSettings,
          setting => setting.id == configurationId
        );
        const valueType = _.get(configuration, "valueType", "");

        switch (valueType) {
          case "JSON": {
            const jsonValue = _.get(configuration, "jsonValue", null);
            if (_.isEmpty(jsonValue)) return (isSectionVisible = false);
          }
        }
      });

      return isSectionVisible;
    };

    const renderSetting = () => {
      const uiType = _.get(subModule, "uiType", "");
      const subSettings = _.filter(allSettings, { parentID: subModule.id });
      subSettings.sort((a, b) => a.displayOrder - b.displayOrder);

      switch (uiType) {
        case SETTING_BLOCK_MULTI_APP: {
          return (
            <MultiSetting
              subModule={subModule}
              subSettings={subSettings}
              allSettings={allSettings}
              onUpdateSetting={onUpdateSetting}
              subModuleTitleStyles={subModuleTitleStyles}
              key={_.get(subModule, "id")}
              showChooseCoursesFromParent={showChooseCourses}
              classOptions={classOptions}
              isDisabled={isDisabled}
              t={t}
              platform={platform}
              categories={categories}
              isNotificationConfiguration={isNotificationConfiguration}
              courseId={courseId}
              organizationId={organizationId}
            />
          );
        }
        case SETTING_BLOCK_SINGLE_APP:
          return (
            getIsSectionVisible({ subModule, allSettings }) && (
              <SingleSetting
                subModule={subModule}
                subSettings={subSettings}
                allSettings={allSettings}
                onUpdateSetting={onUpdateSetting}
                subModuleTitleStyles={subModuleTitleStyles}
                platform={platform}
                isDisabled={isDisabled}
                key={_.get(subModule, "id")}
                t={t}
              />
            )
          );
        case ATTENDANCE_MODE: {
          const isTextLocalized = _.get(
            subModule,
            "metadata.isTextLocalized",
            true
          );
          return (
            <div>
              <div
                className={classes.settingRowContainerSubmodule}
                style={{
                  borderBottom: 0,
                  paddingBottom: 0,
                }}
              >
                <div
                  className={classes.titleSubmodule}
                  style={subModuleTitleStyles}
                >
                  {isTextLocalized
                    ? t(`configuration:${subModule.label}`)
                    : _.get(subModule, "label", "")}
                </div>
                <div className={classes.descSubmodule}>
                  {isTextLocalized
                    ? t(`configuration:${subModule.description}`)
                    : _.get(subModule, "description", "")}
                </div>
              </div>
              <AttendanceRecordingSettings />
            </div>
          );
        }
        case ATTENDANCE_CATEGORY: {
          const isTextLocalized = _.get(
            subModule,
            "metadata.isTextLocalized",
            true
          );
          return (
            <div>
              <div
                className={classes.settingRowContainerSubmodule}
                style={{
                  borderBottom: 0,
                  paddingBottom: 0,
                }}
              >
                <div
                  className={classes.titleSubmodule}
                  style={subModuleTitleStyles}
                >
                  {isTextLocalized
                    ? t(`configuration:${subModule.label}`)
                    : _.get(subModule, "label", "")}
                </div>
              </div>
              <AttendanceSetup
                addButtonLabel={t("configuration:add_a_category")}
                showHeader={false}
                canUpdateChildId={false}
                fromSetting={true}
              />
            </div>
          );
        }
        case SECTION: {
          const isTextLocalized = _.get(
            subModule,
            "metadata.isTextLocalized",
            true
          );
          return (
            <>
              <div className={classes.sectionTitleContainer}>
                <div
                  className={classes.titleSubmodule}
                  style={subModuleTitleStyles}
                >
                  {isTextLocalized
                    ? t(`configuration:${subModule.label}`)
                    : _.get(subModule, "label", "")}
                </div>
              </div>
              <div>
                {_.map(subSettings, subSetting => {
                  return (
                    <SubModule
                      subModule={subSetting}
                      allSettings={allSettings}
                      subModuleTitleStyles={{
                        fontSize: "1.6rem",
                      }}
                      onUpdateSetting={onUpdateSetting}
                      key={_.get(subSetting, "id")}
                      isDisabled={isDisabled}
                    />
                  );
                })}
              </div>
            </>
          );
        }
        case SELECTION_LIST: {
          return (
            <SelectionList
              subModule={subModule}
              subModuleTitleStyles={subModuleTitleStyles}
              // onUpdateSetting={onUpdateSetting}
            />
          );
        }
        case TURNITIN_API_SETTING: {
          return (
            <TextInputSetting
              subModule={subModule}
              t={t}
              allSettings={allSettings}
              subSettings={subSettings}
              onUpdateSetting={onUpdateSetting}
            />
          );
        }
        default:
          return null;
      }
    };
    const uiType = _.get(subModule, "uiType", "");
    if (uiType === "NA") {
      return null;
    }
    return (
      <div className={classes.container}>
        <>{renderSetting()}</>
      </div>
    );
  }
);

SubModule.displayName = "SubModule";

SubModule.propTypes = {
  subModule: PropTypes.object,
  settings: PropTypes.array,
  allSettings: PropTypes.array,
  subModuleTitleStyles: PropTypes.object,
};

SubModule.defaultProps = {
  subModule: {},
  settings: [],
  allSettings: [],
  onUpdateSetting: () => {},
  subModuleTitleStyles: {},
};

export default SubModule;
