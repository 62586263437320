import { getOrganizationPypElementSetFromCache } from "modules/CommonGraphqlHelpers";
import update from "immutability-helper";
import ReactDOMServer from "react-dom/server";
import React from "react";
import { localSearch, createRegex } from "Utils";
import _ from "lodash";

/*
  input:{
    nodeId: [233],
    nodes:[{id:233,name:"ssj"},{id:234,name:"sksk"}]
  }
  output:{
   nodeId
  }
*/

export const checkIsNodeTopParent = ({ node }) => {
  const parentId = _.get(node, "parent", null);
  return _.isEmpty(parentId) || parentId == "0";
};
export const getTopParent = ({ nodeId, nodes }) => {
  const parentId = _.get(_.find(nodes, { id: nodeId }), "parent", null);

  if (!_.isEmpty(parentId) && !_.isEqual(parentId, "0")) {
    return getTopParent({ nodeId: parentId, nodes });
  } else {
    return nodeId;
  }
};

const getTupple = (lastId, result = {}, nodes) => {
  const node = nodes[lastId];
  result[node.levelId] = lastId;
  if (node.parent) {
    return getTupple(node.parent, result, nodes);
  }
  return result;
};

export const getNodeIdsSequencial = ({ nodes, rootNodes }) => {
  const result = [];
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }
  const traverseNodeIds = ({ result, nodes, nodeId }) => {
    const node = nodes[nodeId];
    result.push(nodeId);
    if (!_.isEmpty(_.get(node, "children", []))) {
      _.forEach(_.get(node, "children", []), child => {
        traverseNodeIds({ result, nodes, nodeId: child });
      });
    } else {
    }
  };

  _.forEach(rootNodes, child => {
    traverseNodeIds({ result, nodes, nodeId: child });
  });

  return result;
};

export const getChildrenIdsSequencial = ({
  nodeIds,
  nodes,
  includeParent = true,
}) => {
  const result = [];
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  const traverseNodeIds = ({ result, nodes, nodeId }) => {
    const node = nodes[nodeId];
    result.push(nodeId);
    if (!_.isEmpty(_.get(node, "children", []))) {
      _.forEach(_.get(node, "children", []), child => {
        traverseNodeIds({ result, nodes, nodeId: child });
      });
    } else {
    }
  };

  _.forEach(nodeIds, nodeId => {
    if (includeParent) {
      result.push(nodeId);
    }
    _.forEach(_.get(nodes[nodeId], "children", []), child => {
      traverseNodeIds({ result, nodes, nodeId: child });
    });
  });

  return result;
};

/*
  input:{
    value: [233,211,232]
  }
  output:{
    L1:[2,3],
    L2:[34,35],
    L3:[233,211,232]
  }
*/
export const generateSelectedLevelsData = (
  value,
  nodes,
  needToFilter,
  rootNodes
) => {
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }
  if (needToFilter) {
    value = filterSubjectBenchmarkValues({ value, nodes, rootNodes });
  }

  // console.log(value, nodes);
  return _.reduce(
    value,
    (result, item) => {
      _.forEach();
      const tupple = getTupple(item, {}, nodes);
      result = _.reduce(
        tupple,
        (result, nodeId, levelId) => {
          if (result[levelId]) {
            if (!_.includes(result[levelId], nodeId))
              result[levelId].push(nodeId);
          } else {
            result[levelId] = [];
            result[levelId].push(nodeId);
          }
          return result;
        },
        result
      );
      return result;
    },
    {}
  );
};

const generateNodeHierarchy = ({ nodeId, nodes, levels, selections }) => {
  const node = _.cloneDeep(nodes[nodeId]);
  if (node && !_.isEmpty(_.get(node, "children", []))) {
    if (selections) {
      const childLevelId = getChildLevelId(node.levelId, levels);
      node.children = _.filter(selections[childLevelId], item =>
        _.includes(_.get(node, "children", []), item)
      );
    }
    node.children = _.map(_.cloneDeep(_.get(node, "children", [])), childId => {
      return generateNodeHierarchy({
        nodeId: childId,
        nodes,
        levels,
        selections,
      });
    });
  }
  return node;
};

const generateArrayHierarchy = ({ values, nodes, levels, selections }) => {
  let result = [];
  result = _.map(values, nodeId => {
    return generateNodeHierarchy({ nodeId, nodes, levels, selections });
  });
  return result;
};

/*
  input: {
    L1:[2,3],
    L2:[34,35],
    L3:[233,211,232]
  }
  output:[
    {
      id: 2,
      children:[
        {
          id: 34,
          children:[
            {
              id: 233,
              children:null
            },
            {
              id: 211,
              children:null
            }
          ]
        }
      ]
    },
    {
      id: 3,
      children:[
        {
          id: 35,
          children:[
            {
              id: 232,
              children:null
            }
          ]
        }
      ]
    }
  ]
*/
export const generateHierarchicalSelectedLevelsData = ({
  selections,
  nodes,
  levels,
  rootNodes,
}) => {
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }
  const rootLevelId = levels[0].id;
  const result = generateArrayHierarchy({
    values: _.filter(rootNodes, item =>
      _.includes(selections[rootLevelId], item)
    ),
    nodes,
    levels,
    selections,
  });
  return result;
};

export const getChildLevelId = (levelId, levels) => {
  const levelIndex = _.findIndex(levels, { id: levelId });
  if (levelIndex + 1 < levels.length) {
    return levels[levelIndex + 1].id;
  }
  return null;
};

export const convertOldToNewBenchmarkValue = (value, levels) => {
  const lastLevelId = _.get(levels, `[${levels.length - 1}].id`, "L2");
  return _.map(value, item => item[lastLevelId]);
};

export const convertNewToOldBenchmarkValue = (value, nodes) => {
  //   return _.reduce(
  //     value,
  //     (result, item) => {
  //       const tupple = getTupple(item, {}, nodes);
  //       result.push(tupple);
  //       return result;
  //     },
  //     []
  //   );

  return _.map(value, item => {
    return getTupple(item, {}, nodes);
  });
};

export const filterSubjectBenchmarkValues = ({ value, nodes, rootNodes }) => {
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  let nodeIds = [];
  if (rootNodes) {
    nodeIds = getNodeIdsSequencial({
      nodes,
      rootNodes,
    });
  } else nodeIds = _.map(nodes, node => node.id);

  return _.filter(value, item => _.includes(nodeIds, item));
};

export const abstractOutBenchmarkFirstLevel = ({
  benchmarkSet,
  nodes,
  levels,
  rootNodes,
}) => {
  const getChildrenHierarchical = (result = [], children, nodes) => {
    // if (!_.isEmpty(children)) result = _.uniq(_.concat(result, children));

    if (!_.isEmpty(children))
      result = _.uniq(
        _.concat(
          result,
          _.reduce(
            children,
            (result, child) => {
              if (!_.isEmpty(nodes[child])) {
                result.push(child);
              }
              return result;
            },
            []
          )
        )
      );

    _.forEach(children, childId => {
      const node = nodes[childId];
      if (!_.isEmpty(_.get(node, "children", []))) {
        result = getChildrenHierarchical(
          result,
          _.get(node, "children", []),
          nodes
        );
      }
    });

    return result;
  };

  if (benchmarkSet) {
    nodes = benchmarkSet.benchmarks.nodes;
    levels = benchmarkSet.levels;
    rootNodes = benchmarkSet.benchmarks.rootNodes;
  }

  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  return _.map(rootNodes, (rootId, index) => {
    const node = nodes[rootId];
    const children = getChildrenHierarchical([], [rootId], nodes);
    return {
      id: rootId,
      subject: node.subject || rootId,
      root: node,
      levels: _.reduce(
        node.levels,
        (result, level) => {
          if (level.id !== node.levelId) {
            result.push(level);
          }
          return result;
        },
        []
      ),
      benchmarks: {
        nodes: _.map(children, childId => nodes[childId]),
        rootNodes: _.get(node, "children", []),
        allNodes: nodes,
      },
    };
  });
};

export const filterLevelsArray = ({ levels, levelId }) => {
  return _.reduce(
    levels,
    (result, level) => {
      if (levelId !== level.id) {
        result.push(level);
      }
      return result;
    },
    []
  );
};

export const getUpdatedBenchmarkSelections = ({
  id,
  value,
  selections,

  rootNodes,
  nodes,
}) => {
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  return _.reduce(
    rootNodes,
    (result, rootNode) => {
      const root = nodes[rootNode];
      const children = _.get(root, "children", []);
      const filteredData = filterSubjectBenchmarkValues({
        value,
        nodes,
        rootNodes: children,
      });

      if (root.id === id) {
        result = _.concat(result, selections);
      } else {
        result = _.concat(result, filteredData);
      }
      return result;
    },
    []
  );
};

/*
  input:{
    nodeId:11,
    nodes:[],
    selections:[11,23,44]
  }
  output:{
    id:11,
    children:[],
    label:"sksk"
  }
*/

const generateNestedNode = ({ nodeId, nodes, selections }) => {
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  const node = nodes[nodeId];
  const selectedChildren = _.filter(_.get(node, "children", []), nodeId =>
    _.includes(selections, nodeId)
  );

  const children = _.reduce(
    selectedChildren,
    (result, childId) => {
      const childNode = generateNestedNode({
        nodeId: childId,
        nodes,
        selections,
      });

      if (nodes[childId] && !_.isEmpty(childNode)) {
        result.push(childNode);
      }

      return result;
    },
    []
  );

  if (!_.isEmpty(children) || _.isEmpty(_.get(node, "children", []))) {
    return { ...node, children };
  }
};

/*
  input: {
   value:[78,56],
   nodes:[],
   rootNodes:[3,4]
  }
  output:[
    {
      id: 2,
      children:[
        {
          id: 34,
          children:[
            {
              id: 233,
              children:null
            },
            {
              id: 211,
              children:null
            }
          ]
        }
      ]
    },
    {
      id: 3,
      children:[
        {
          id: 35,
          children:[
            {
              id: 232,
              children:null
            }
          ]
        }
      ]
    }
  ]
*/

export const generateNestedSelectedData = ({ nodes, rootNodes, value }) => {
  const selections = generateSelectedArray({ nodes, value });

  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  nodes = generateParentChildRelationship({ nodes });
  rootNodes = _.reduce(
    nodes,
    (result, node) => {
      if (_.includes(rootNodes, node.id)) result.push(node.id);
      return result;
    },
    []
  );
  const selectedRootNodes = _.filter(rootNodes, nodeId =>
    _.includes(selections, nodeId)
  );
  return _.map(selectedRootNodes, nodeId => {
    return generateNestedNode({ nodeId, nodes, selections });
  });
};

/*
  input:{
    value: 211
  }
  output:[119,11,2]
*/

export const traverseToParent = ({ nodeId, nodes, result = [] }) => {
  result.push(nodeId);
  const node = _.get(nodes, `[${nodeId}]`, {});
  const parent = _.get(node, "parent", null);
  if (parent) {
    return traverseToParent({ nodeId: parent, nodes, result });
  }
  return result;
};

/*
  input:{
    value: [233,211,232]
  }
  output:[233,211,232,11,110,2,1,112,12]
*/
export const generateSelectedArray = ({ value, nodes }) => {
  if (Array.isArray(nodes)) {
    nodes = _.keyBy(nodes, "id");
  }

  return _.reduce(
    value,
    (result, nodeId) => {
      const parents = traverseToParent({ nodeId, result: [], nodes });

      result = _.unionBy(result, parents);
      return result;
    },
    []
  );
};

export const checkNodeExitsForGrades = ({ node, grades = [] }) => {
  const nodeGrades = _.get(node, "grades", []);

  return _.get(_.intersectionBy(nodeGrades, grades, "id"), "length", 0) > 0;
};

export const generateTreeOfNode = ({ nodeId, nodes, grades = [] }) => {
  const node = _.find(nodes, { id: nodeId });
  const outputNodes = [];

  if (grades.length > 0 && !checkNodeExitsForGrades({ node, grades })) {
    return outputNodes;
  }

  _.forEach(_.get(node, "children", []), childId => {
    const children = generateTreeOfNode({ nodeId: childId, nodes, grades });
    const childNode = _.find(nodes, { id: childId });
    let isInclude = true;
    if (grades.length > 0) {
      isInclude = checkNodeExitsForGrades({ node: childNode, grades });
    }
    if (isInclude) {
      outputNodes.push({ ...childNode, children });
    }
  });
  return outputNodes;
};

export const generateGradeWiseTreesOfNode = ({ nodeId, nodes, grades }) => {
  const gradeWiseTrees = {};
  _.forEach(grades, grade => {
    gradeWiseTrees[grade.id] = generateTreeOfNode({
      nodeId,
      nodes,
      grades: [grade],
    });
  });
  return gradeWiseTrees;
};

export const nodeFilterByGrades = ({ nodes, grades }) => {
  if (grades.length == 0) {
    return nodes;
  }
  const outputNodes = [];

  _.forEach(nodes, node => {
    const isInclude = checkNodeExitsForGrades({
      node,
      grades,
    });
    if (isInclude) {
      const outputChildren = [];
      const children = _.get(node, "children", []);

      _.forEach(children, childId => {
        const childNode = _.find(nodes, item => item.id == childId);

        if (childNode) {
          const isIncludeChild = checkNodeExitsForGrades({
            node: childNode,
            grades,
          });

          if (isIncludeChild) {
            outputChildren.push(childId);
          }
        }
      });

      outputNodes.push({ ...node, children: outputChildren });
    }
  });

  return outputNodes;
};

export const getBenchmarkSetOldFormat = ({
  subjects,
  grades,
  organizationId,
}) => {
  const queryData = getOrganizationPypElementSetFromCache({
    subjects,
    grades,
    organizationId,
    type: "BENCHMARK",
  });

  const benchmarks = _.get(queryData, "pypElement.benchmarks", {});

  return abstractOutBenchmarkFirstLevel({
    nodes: _.get(benchmarks, "nodes", []),
    rootNodes: _.get(benchmarks, "rootNodes", []),
  });
};

export const orderNodes = ({ nodes, gradeSequences, startDepth }) => {
  const benchmarks = _.cloneDeep(nodes);
  // console.log("Order Benchmark");
  //first find all the depth 0 thingies
  const depth0Items = _.filter(
    benchmarks,
    benchmark => _.get(benchmark, "depth", 1) == startDepth
  );

  let benchmarkArray = [];
  //call recursive function on each depth 0 item to create whatever needs to be created
  for (let i = 0; i < depth0Items.length; i++) {
    const rootNode = depth0Items[i];
    const currentFlatArray = recursiveFlatTreeBuilder({
      benchmarks: benchmarks,
      currentParent: depth0Items[i].id,
      currentArray: [],
      gradeSequences,
    });
    benchmarkArray.push({ ...depth0Items[i], rootNode });
    benchmarkArray = [
      ...benchmarkArray,
      ..._.map(currentFlatArray, array => {
        return { ...array, rootNode };
      }),
    ];
  }

  benchmarkArray = _.flattenDeep(benchmarkArray);

  return benchmarkArray;
};

const recursiveFlatTreeBuilder = data => {
  let childrenOfCurrent = _.filter(data.benchmarks, {
    parent: data.currentParent,
  });

  childrenOfCurrent = _.orderBy(
    childrenOfCurrent,
    ["type", "displaySequence"],
    ["asc", "asc"]
  );

  if (childrenOfCurrent == undefined || childrenOfCurrent.length == 0) {
    return data.currentArray;
  }

  for (let i = 0; i < childrenOfCurrent.length; i++) {
    const gradeIds = _.map(
      _.get(childrenOfCurrent[i], "grades", []),
      item => item.id
    );
    data.currentArray.push({
      ...childrenOfCurrent[i],
      grades: _.filter(_.get(data, "gradeSequences", []), item =>
        _.includes(gradeIds, item.id)
      ),
    });

    data.currentArray = recursiveFlatTreeBuilder({
      benchmarks: data.benchmarks,
      currentParent: childrenOfCurrent[i].id,
      currentArray: data.currentArray,
      gradeSequences: _.get(data, "gradeSequences", []),
    });
  }
  data.currentArray = _.flattenDeep(data.currentArray);

  return data.currentArray;
};
/**
 * filterNodeIdsByGrades
 * - fetching nodeIds which is filtered by grades.
 */

const getSelectedNodeIds = ({ selectedNodes }) => {
  let selectedNodeIds = [];
  if (!_.isEmpty(selectedNodes))
    selectedNodeIds = _.map(selectedNodes, ({ id }) => id);
  return selectedNodeIds;
};

export const filterNodeIdsByGrades = ({
  filterKeys = {},
  nodes = {},
  gradeIds = [],
  selectedTaggedIds,
}) => {
  if (_.isEmpty(gradeIds)) return filterKeys;

  const filterNewKeys = {};
  for (const filterKey in filterKeys) {
    const currentNode = nodes[filterKey];
    const currentId = currentNode.id;
    const currentNodeGrades = _.get(currentNode, "grades", []);

    const isGradeAvailable = _.some(currentNodeGrades, ({ id }) =>
      _.includes(gradeIds, id)
    );

    const isSelectedNode = _.includes(selectedTaggedIds, currentId);

    if (isGradeAvailable || isSelectedNode) {
      filterNewKeys[currentId] = currentId;
    }
  }
  return filterNewKeys;
};

/**
 * buildTagsFlatTree
 * The tags id's which will be filter. The relation will be parent child e.g if tags that has been found in level 3
 * its parent Ids will also be there (level 1 and level 2).
 */
export const buildTagsFlatTree = ({
  selectedTagIds,
  nodes,
  filteredKeys,
  selectedTaggedIds,
  rootNode,
}) => {
  if (_.isEmpty(selectedTagIds)) return filteredKeys;
  else {
    const newKeys = {};
    for (const nodeId in filteredKeys) {
      const { tags, parentId, parent } = nodes[nodeId];
      const isTagIdFound = _.some(tags, ({ id }) =>
        _.includes(selectedTagIds, id)
      );
      const isSelectedNode =
        _.includes(selectedTaggedIds, nodeId) && rootNode.id !== nodeId;
      if (isTagIdFound || isSelectedNode) {
        newKeys[nodeId] = nodeId;
        getNodeParentIds({
          keys: newKeys,
          parentId: parentId || parent,
          nodes,
        });
      }
    }

    return newKeys;
  }
};
const getNodeParentIds = ({ keys, parentId, nodes }) => {
  const currentNode = nodes[parentId];
  const { depth, id } = currentNode;
  keys[id] = id; // add the parent key
  if (depth === 0) return;
  const currentNodeParentId = currentNode.parentId || currentNode.parent;

  return getNodeParentIds({ keys, parentId: currentNodeParentId, nodes });
};

export const buildSearchFlatTree = data => {
  /**
   * isNewNodeEditor param is used to distinguish NodeEditor.js currently and this has been used
   * because there is a change in the internal keys of the objects like parentId
   * and NodeEditor.js required filter id's only not whole filter nodes object
   */
  const { isNewNodeEditor = false } = data;

  // if there is no text to search case it will return all node Ids
  if (!data.searchText && isNewNodeEditor) {
    const { nodes } = data;
    const allNodeKeys = {};
    for (const node in nodes) {
      allNodeKeys[node] = node;
    }
    return allNodeKeys;
  }

  if (!data.searchText) {
    return data.nodes;
  }

  const searchIds = _.map(
    _.filter(
      data.nodes,
      item =>
        _.includes(_.toLower(item.label), _.toLower(data.searchText)) ||
        _.includes(_.toLower(item.code), _.toLower(data.searchText))
    ),
    searchItem => searchItem.id
  );

  if (isNewNodeEditor) {
    return buildSlimTreeV1({
      nodes: data.nodes,
      selIds: searchIds,
    });
  }
  return buildSlimTree({
    nodes: data.nodes,
    selIds: searchIds,
    searchText: data.searchText,
    matchingTextStyle: data.matchingTextStyle,
    isNewNodeEditor,
  });
};
/***
 *  buildSlimTreeV1
 *  - This function is a util for NodeEditor.js currently. This function is capable of handling new keys like parentId.
 *  - The search working will be like Search will find parent child relation keys only.
 *  - NodeEditor.js will be used in ATL, SNS, learning Standards.
 */
export const buildSlimTreeV1 = data => {
  //arguments in function  ---- GIVE THESE TO THE FUNCTION
  const dbData = data.nodes;
  const selectedIds = data.selIds;

  //find parents for each id
  let finalIds = [];
  for (let i = 0; i < selectedIds.length; i++) {
    finalIds.push(selectedIds[i]);
    finalIds.push(
      recursiveUpperSlimTreeBuilderV1({
        dbData: dbData,
        currentId: selectedIds[i],
        idArray: [],
      })
    );

    finalIds.push(
      recursiveLowerSlimTreeBuilder({
        dbData: dbData,
        currentId: selectedIds[i],
        idArray: [],
      })
    );
  }

  finalIds = _.uniq(_.flattenDeep(finalIds));

  return _.keyBy(finalIds, obj => obj);
};

export const buildSlimTree = data => {
  //arguments in function  ---- GIVE THESE TO THE FUNCTION
  const dbData = data.nodes;
  const selectedIds = data.selIds;
  const matchingTextStyle = data.matchingTextStyle || {};
  const searchText = data.searchText;
  //find parents for each id
  let finalIds = [];
  for (let i = 0; i < selectedIds.length; i++) {
    finalIds.push(selectedIds[i]);

    finalIds.push(
      recursiveUpperSlimTreeBuilder({
        dbData: dbData,
        currentId: selectedIds[i],
        idArray: [],
      })
    );
    finalIds.push(
      recursiveLowerSlimTreeBuilder({
        dbData: dbData,
        currentId: selectedIds[i],
        idArray: [],
      })
    );
  }

  finalIds = _.uniq(_.flattenDeep(finalIds));

  const re = createRegex({ text: searchText ?? "", flags: "gi" });

  const returnData = [];
  for (let i = 0; i < finalIds.length; i++) {
    const rowData = _.find(dbData, { id: finalIds[i] });

    if (rowData && rowData.label) {
      returnData.push({
        ...rowData,
        label:
          !searchText || !rowData.label
            ? rowData.label
            : rowData.label.replace(re, val => {
                return ReactDOMServer.renderToStaticMarkup(
                  <b style={matchingTextStyle}>{val}</b>
                );
              }),
        code:
          !searchText || !rowData.code
            ? rowData.code
            : rowData.code.replace(re, val => {
                return ReactDOMServer.renderToStaticMarkup(
                  <b style={matchingTextStyle}>{val}</b>
                );
              }),
      });
    }
  }

  return returnData;
};

const recursiveLowerSlimTreeBuilder = data => {
  const childrenData = _.filter(data.dbData, { parent: data.currentId });
  if (childrenData == undefined || childrenData.length == 0) {
    return data.idArray;
  }

  for (let i = 0; i < childrenData.length; i++) {
    data.idArray.push(childrenData[i].id);
    data.idArray = recursiveLowerSlimTreeBuilder({
      dbData: data.dbData,
      currentId: childrenData[i].id,
      idArray: data.idArray,
    });
  }

  data.idArray = _.flattenDeep(data.idArray);
  return data.idArray;
};

const recursiveUpperSlimTreeBuilder = data => {
  const currentNode = _.find(data.dbData, { id: data.currentId });

  if (!currentNode) {
    return data.idArray;
  }

  const parentData = _.find(data.dbData, { id: currentNode.parent });

  if (parentData == undefined) {
    return data.idArray;
  }

  data.idArray.push(parentData.id);
  data.idArray = recursiveUpperSlimTreeBuilder({
    dbData: data.dbData,
    currentId: parentData.id,
    idArray: data.idArray,
  });

  data.idArray = _.flattenDeep(data.idArray);
  return data.idArray;
};

const recursiveUpperSlimTreeBuilderV1 = data => {
  /**
   * difference between recursiveUpperSlimTreeBuilder and recursiveUpperSlimTreeBuilderV1
   * is it's node containers parentId and from now on parent will parentId else
   * the functionality is the same.
   */

  const currentNode = _.find(data.dbData, { id: data.currentId });

  if (!currentNode) {
    return data.idArray;
  }

  const parentData = _.find(data.dbData, {
    id: currentNode.parentId,
  });

  if (parentData == undefined) {
    return data.idArray;
  }

  data.idArray.push(parentData.id);
  data.idArray = recursiveUpperSlimTreeBuilderV1({
    dbData: data.dbData,
    currentId: parentData.id,
    idArray: data.idArray,
  });

  data.idArray = _.flattenDeep(data.idArray);
  return data.idArray;
};

export const getPYPElementSetFromNodes = ({ nodes, startDepth }) => {
  return {
    rootNodes: _.map(
      _.filter(nodes, item =>
        startDepth
          ? item.depth == startDepth
          : !item.parent || item.parent == "0"
      ),
      node => node.id
    ),
    nodes,
  };
};

export const mergeSelectedNodes = ({ nodes = [], selectedNodes = [] }) => {
  let updatedNodes = _.uniqBy([...nodes, ...selectedNodes], "id");

  _.forEach(selectedNodes, selectedNode => {
    const parentNodeIndex = _.findIndex(
      updatedNodes,
      node => node.id == selectedNode.parent
    );

    if (parentNodeIndex >= 0) {
      const parentNode = updatedNodes[parentNodeIndex];
      const parentChildren = _.get(parentNode, "children", []);

      if (!_.includes(parentChildren, selectedNode.id)) {
        updatedNodes = update(updatedNodes, {
          $splice: [
            [
              parentNodeIndex,
              1,
              {
                ...parentNode,
                children: [...parentChildren, selectedNode.id],
              },
            ],
          ],
        });
      }
    }
  });

  return fixNodesChildren({ nodes: updatedNodes });
};

export const fixNodesChildren = ({ nodes }) => {
  const outputNodes = [];
  const nodeIds = _.map(nodes, item => item.id);
  _.forEach(nodes, node => {
    outputNodes.push({
      ...node,
      children: _.filter(_.get(node, "children", []), id =>
        _.includes(nodeIds, id)
      ),
    });
  });
  return outputNodes;
};

export const generateParentChildRelationship = ({ nodes }) => {
  const outputNodes = [];
  let hasDisplaySequence = true;
  _.forEach(nodes, node => {
    hasDisplaySequence = hasDisplaySequence && !!node.displaySequence;
    outputNodes.push({
      ...node,
      children: _.union(
        _.filter(node.children, id => {
          return nodes[id]?.parent == node.id;
        }),
        _.map(
          _.filter(nodes, item => item.parent == node.id),
          childItem => childItem.id
        )
      ),
    });
  });

  if (!hasDisplaySequence) {
    return outputNodes;
  }
  return _.orderBy(outputNodes, ["displaySequence"], ["asc"]);
};

/*
  Extract all the uniq associated parents from nodes.
   Used in MYP Objectives, MYP Learning Standard, MYP Related Concept in the Le/Assessment where we want to extract subjects/subject groups (Associated parent) from the nodes
*/

export const getUniqAssociatedParentsOfNodes = ({ nodes }) => {
  const associatedParentNodes = _.reduce(
    nodes,
    (result, node) => {
      if (checkIsNodeTopParent({ node })) {
        _.forEach(node.associatedParents, assParent => {
          if (assParent) {
            if (!result[assParent.id]) {
              result[assParent.id] = {
                ...assParent,
                label: assParent.name,
                depth: 0,
                parent: null,
                children: [],
              };
            }
            result[assParent.id].children.push(node.id);
          }
        });
      }
      return result;
    },
    {}
  );
  return _.map(associatedParentNodes, node => node);
};

/*
  Merge AssociatedParents with their nodes.
  Used in MYP Objectives, MYP Learning Standard, MYP Related Concept in the Le/Assessment where we add subject/subject group in the hierarchy
*/
export const mergeAssociatedParentsWithNodes = ({
  nodes,
  associatedParentNodes,
}) => {
  const resultNodes = [];
  _.forEach(nodes, node => {
    const updatedNode = { ...node };
    if (checkIsNodeTopParent({ node })) {
      updatedNode["parent"] = _.get(
        _.first(
          _.intersectionBy(associatedParentNodes, node.associatedParents, "id")
        ),
        "id",
        null
      );
    }

    resultNodes.push(updatedNode);
  });
  _.forEach(associatedParentNodes, parentNode => {
    resultNodes.push(parentNode);
  });

  return resultNodes;
};

/*
  Add depthOffSet in each node
*/

export const updateNodeDepthByOffset = ({ nodes, depthOffset }) => {
  return _.map(nodes, node => {
    return { ...node, depth: node.depth + depthOffset };
  });
};

/*
  Filter out RootNodes based from its parent
*/

export const filterOutRootNodes = ({ nodes }) => {
  return _.filter(nodes, node => checkIsNodeTopParent({ node }));
};
