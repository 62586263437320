import gql from "graphql-tag";
import { courseFragment } from "modules/CommonFragments";

export const getOrganizationDetailsUnitPlanCount = gql`
  query getOrganizationDetailsUnitPlanCount($id: ID!, $regions: [ID!]) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        repoOrganization {
          unitLibraryCount(regions: $regions)
        }
      }
    }
  }
`;

export const singleCourseV2Fragment = gql`
  fragment singleCourseV2 on Course {
    id
    title
    profileImage
    isArchived
    isBookmark
    grades {
      id
      name
      globalGrade {
        id
        displaySequence
      }
    }

    tags {
      id
      key
      value
    }

    genericTags(filters: { types: SUBJECT_LEVEL }) {
      id
      label
    }

    subjectGroups {
      id
      name
    }

    subjects {
      id
      name
    }

    unitPlans {
      totalCount
    }
    __typename
  }
`;
export const userCoursesPageFragment = gql`
  fragment staffCourses on Staff {
    id
    archivedCourses: coursesV2(filters: $archivedCourseFilters) {
      totalCount
    }
    coursesV2(
      first: $first
      after: $after
      orderBy: TITLE
      orderByDirection: ASC
      filters: $courseFilters
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...singleCourseV2
        }
      }

      __typename
    }

    __typename
  }
  ${singleCourseV2Fragment}
`;
