import React, { useState, useEffect } from "react";
import classes from "./CriteriaRating.scss";
import classNames from "classnames";

const CriteriaRating = ({
  isEvaluation,
  options,
  value,
  onCriteriaClick,
  isViewMode,
  containerStyle,
  shouldUpdateOnValueChnage,
}) => {
  const [selectedCritera, setSelectedCriteria] = useState(value);
  const [selectionTimerId, setSelectionTimerId] = useState(null);
  useEffect(() => {
    if (shouldUpdateOnValueChnage) {
      setSelectedCriteria(value);
    }
  }, [value, shouldUpdateOnValueChnage]);
  return (
    <div className={classes.ratingElementsContainer} style={containerStyle}>
      {_.map(options, option => {
        const isSelected = option.id === selectedCritera;
        const ratingElementClass = classNames(
          { [classes.ratingElement]: true },
          { [classes.ratingElementDisabled]: isViewMode },
          { [classes.ratingElementInteractable]: isEvaluation },
          { [classes.selectedRatingElement]: isSelected }
        );
        return (
          <div
            className={ratingElementClass}
            key={option.id}
            onClick={
              isEvaluation && !isViewMode
                ? () => {
                    const updatedCriteria =
                      option.id === selectedCritera ? "" : option.id;
                    setSelectedCriteria(updatedCriteria);
                    // clear action of pervious interaction
                    clearTimeout(selectionTimerId);
                    setSelectionTimerId(
                      setTimeout(() => {
                        // after new interactions if no criteria is selected, and if previously a critria was selected
                        // then we need to delete that criteria rating
                        if (!updatedCriteria && value) {
                          onCriteriaClick(value);
                        } else if (updatedCriteria) {
                          // create a new criteria rating with the updated value
                          onCriteriaClick(option.id);
                        }
                      }, 300)
                    );
                  }
                : null
            }
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

CriteriaRating.defaultProps = {
  containerStyle: {},
};

export default CriteriaRating;
