import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import StudentAssignment from "./routes/StudentAssignment";
import AllSubmissions from "./routes/AllSubmissions";
import ClassRoomMediaManager from "./routes/ClassRoomMediaManager";
export default store => ({
  path: "classroom-details/:classRoomId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ClassRoom/modules/ClassRoomModule")
              .default;

            injectReducer(store, { key: "classRoom", reducer });

            const post = require("Post/modules/PostModule").default;

            injectReducer(store, {
              key: "post",
              reducer: post,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-classRoom"
        );
      })
  ),
  childRoutes: [
    AllSubmissions(store),
    ClassRoomMediaManager(store),
    StudentAssignment(store),
  ],
});
