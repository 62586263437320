import React, { useState, useEffect } from "react";
import classes from "./ClassCard.scss";
import { I18nHOC, UIModal, DateSelector, TimeSelectorV2 } from "UIComponents";
import TaggedStudents from "../TaggedStudents";
import AddStudentsModal from "../AddStudentsModal";
import moment from "moment";

const styles = {
  timeSelectorDropdownContainerStyle: {
    width: "100%",
  },
  timeSelectorDropdownItemStyle: {
    display: "flex",
    width: "100%",
  },
  timeSelectorTimeComponentStyle: {
    padding: "0",
  },
};

const ClassCard = React.memo(props => {
  const {
    course,
    updateItem,
    courseId,
    onClickRemoveClass,
    t,
    portalType,
    showDeadline,
    setDisableButton,
    mode,
    assignmentId,
    assignmentTags,
    courseStudents,
  } = props;

  const { id, title, students, deadlineDate, deadlineTime } = course;
  const [showAddStudentsModal, changeShowAddStudentsModal] = useState(false);
  const selectedStudentsCount = _.get(students, "length", 0);
  const isAllStudentsSelected = selectedStudentsCount == _.size(courseStudents);

  useEffect(() => {
    // if both assignment tags are selected and selected students have only 1 tag,
    // then without changing the current selection, add all the students having other tag
    // to the selected students
    if (_.size(assignmentTags) === 2) {
      const selectedStudentsIds = _.map(students, student => student?.id);

      const studentTags = _.filter(courseStudents, student =>
        _.includes(selectedStudentsIds, student?.id)
      ).map(student => student?.tags?.[0]?.label);
      const uniqueStudentTags = [...new Set(studentTags)];

      if (_.size(uniqueStudentTags) === 1) {
        const studentsToBeAdded = _.filter(
          courseStudents,
          student => student?.tags?.[0]?.label !== uniqueStudentTags[0]
        ).map(student => _.omit(student, "tags"));

        if (_.size(studentsToBeAdded) > 0) {
          const newIncludedStudents = [...students, ...studentsToBeAdded];
          updateItem({ students: newIncludedStudents });
        }
      }
    } else if (_.size(assignmentTags) === 1 && selectedStudentsCount === 0) {
      updateItem({ students: courseStudents });
    }
  }, [students, assignmentTags]);

  return (
    <div className={classes.classCard}>
      <div className={classes.classTitleCon}>
        <div className={classes.classTitle}>{title}</div>
        {courseId !== id && (
          <div
            className={classes.removeBtn}
            onClick={() => onClickRemoveClass(id)}
          >
            {t("common:remove")}
          </div>
        )}
      </div>
      <div className={classes.studentInputField}>
        <div className={classes.selectStudentHeaderCon}>
          <div className={classes.headerLabel}>{`${
            _.get(students, "length", 0) > 0
              ? t("common:selected_students")
              : t("common:select_with_label", {
                  label: t("common:student_plural"),
                })
          }`}</div>
          <div
            className={classes.removeBtn}
            onClick={() => changeShowAddStudentsModal(true)}
          >
            {_.get(students, "length", 0) > 0
              ? t("common:edit")
              : t("common:add")}
          </div>
        </div>
        <TaggedStudents
          taggedStudents={students}
          isAllStudentsSelected={isAllStudentsSelected}
        />
      </div>
      {showDeadline && (
        <div className={classes.submissionDeadlineCon}>
          <div className={classes.submissionDeadlineHeaderCon}>
            <div className={classes.headerLabel}>
              {t("classRoom:submission_deadline")}
            </div>
            {!_.isEmpty(deadlineDate) && (
              <div
                className={classes.removeBtn}
                onClick={() => {
                  updateItem({ deadlineDate: "", deadlineTime: null });
                }}
              >
                {t("common:reset")}
              </div>
            )}
          </div>
          <div className={classes.horizontalRowContainer}>
            <DateSelector
              updateInputField={updateItem}
              value={deadlineDate}
              name={"deadlineDate"}
              isValueMomentObj={false}
              label={t("common:date")}
              minDate={moment().subtract(1, "day")}
            ></DateSelector>
            <TimeSelectorV2
              value={deadlineTime}
              SelectedTimeformat="HH:mm"
              label={t("common:time")}
              name={"deadlineTime"}
              isValueMomentObj={false}
              updateInputField={updateItem}
              isDisabled={_.isEmpty(deadlineDate)}
              dropdownItemStyle={styles.timeSelectorDropdownItemStyle}
              dropdownContainerStyle={styles.timeSelectorDropdownContainerStyle}
              timeComponentStyle={styles.timeSelectorTimeComponentStyle}
              disableConfirmButton={setDisableButton}
            />
          </div>
        </div>
      )}
      {showAddStudentsModal && (
        <UIModal
          isOpen={showAddStudentsModal}
          modalContent={classes.addClassModalContainer}
          onRequestClose={() => changeShowAddStudentsModal(false)}
        >
          <AddStudentsModal
            students={students}
            onClickCancel={() => changeShowAddStudentsModal(false)}
            courseId={id}
            disableUpdateOnEmpty={true}
            onClickAdd={value => {
              updateItem({ students: value });
            }}
            portalType={portalType}
            mode={mode}
            assignmentId={assignmentId}
            assignmentTags={assignmentTags}
          />
        </UIModal>
      )}
    </div>
  );
});

ClassCard.displayName = ClassCard;

export default I18nHOC({ resource: ["common", "classRoom"] })(ClassCard);
