import gql from "graphql-tag";

export const projectInfoFragment = gql`
  fragment projectInfo on Project {
    id
    status
    reportStatus
    completedDeadlines {
      completedAt
      deadline {
        id
        deadlineDate
      }
    }
    fields {
      id
      uid
      value
      resolvedMinimalTree {
        id
        type
        ... on ResolvedFieldPlannerElementSet {
          id
          type
          ... on ResolvedFieldPlannerElementSet {
            id
            type
            nodes {
              id
              label
              type
            }
          }
        }
      }
    }
    students {
      id
      firstName
      lastName
      profileImage
    }
    supervisors {
      id
      firstName
      lastName
      profileImage
    }
  }
`;
