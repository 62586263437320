import React from "react";
import { compose } from "react-apollo";
import IBSkillsModal from "./IBSkillsModal";
import classes from "./IBSkills.scss";
import { connect } from "react-redux";
import { getPYPElementSetFromNodes } from "Utils";
import { UIModal, EmptyField, I18nHOC } from "UIComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import SubjectBenchmarks from "../BenchmarksComponent/SubjectBenchmarks";

const emptyFieldStyle = {
  containerStyle: {
    border: 0,
  },
};

class IBSkills extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      modalVisible: false,
      rootId: null,
    };
  }

  blur = ({ field }) => {
    const { uid, id } = field;
    const { rootId } = this.state;
    if (rootId == id) {
      this.onIBSkillsModalCancelClicked();
    }
  };

  createData = result => {
    const {
      props: { value },
      state: { rootId },
    } = this;

    const updatedValue = _.uniq([
      ..._.filter(
        value,
        id =>
          !_.includes(
            _.map(result.nodes, item => item.id),
            id
          )
      ),
      ...(result.selections || []),
    ]);

    const { valuesToAdd, valuesToRemove } = result;
    this.updateValue(updatedValue, {
      nodes: result.nodes,
      rootNodeId: rootId,
      valuesToAdd,
      valuesToRemove,
      rootNodeKey: "atlCategory.id",
    });
  };

  onIBSkillsModalCancelClicked = () => {
    this.setState({ modalVisible: false });
  };

  onAddIBSkillsClicked = result => {
    this.createData(result);
    const { shouldCloseModal } = result;
    if (shouldCloseModal) {
      this.setState({ modalVisible: false });
    }
  };

  getNodesObject = nodes => {
    const result = {};
    _.forEach(nodes, (node, key) => {
      result[node.id] = node;
    });
    return result;
  };

  renderModal() {
    const {
      props,
      props: {
        value,
        totalCommentCount,
        unreadCommentCount,
        fieldId,
        nodes: nodesArray,
        grades,
      },
      state: { rootId, modalVisible },
    } = this;

    const nodes = _.keyBy(nodesArray, "id");
    const root = nodes[rootId];

    const filteredNodes = _.filter(
      nodes,
      nodeItem => _.get(nodeItem, "atlCategory.id", "") == rootId
    );

    const filteredValue = _.filter(value, valueId =>
      _.includes(
        _.map(filteredNodes, selectedNode => selectedNode.id),
        valueId
      )
    );

    return (
      <UIModal
        isOpen={modalVisible}
        isFullScreen={true}
        overlayContainer={classes.overlayContainer}
        onRequestClose={this.onIBSkillsModalCancelClicked}
      >
        <IBSkillsModal
          subText={props.subText}
          helperText={props.helperText}
          rootNode={root}
          updateValueLocally={this.updateValueLocally}
          value={filteredValue}
          fieldId={fieldId}
          totalCommentCount={totalCommentCount}
          unreadCommentCount={unreadCommentCount}
          showHelper={props.showHelper}
          showComment={props.showComment}
          onHelpClick={props.onHelpClick}
          onCommentClick={props.onCommentClick}
          onCancelClicked={this.onIBSkillsModalCancelClicked}
          onSubmitClicked={this.onAddIBSkillsClicked}
          grades={grades}
          selectedNodes={filteredNodes}
        />
      </UIModal>
    );
  }

  onEditClick = rootId => {
    this.setState({ modalVisible: true, rootId });
  };

  getEmptyComponent = () => {
    const { t } = this.props;
    return (
      <EmptyField
        title={t("add_atl_category")}
        containerStyle={emptyFieldStyle.containerStyle}
      />
    );
  };

  getEmptyComponentForSubject = ({ subject }) => {
    const { t } = this.props;
    return (
      <EmptyField
        title={t("select_subskills")}
        onAddNewClick={() => this.onEditClick(subject.id)}
        showAdd={true}
        containerStyle={emptyFieldStyle.containerStyle}
      />
    );
  };

  renderMainContent() {
    const {
      props: { nodes, rootNodes, fieldLockedObject },
    } = this;
    const { lockedDynamicFields } = fieldLockedObject;

    return !_.isEmpty(rootNodes) ? (
      <div className={classes.skillsContainer}>
        {_.map(rootNodes, rootNodeId => {
          const root = _.find(nodes, { id: rootNodeId }) || {};

          const innerLevels = _.reduce(
            _.get(root, "levels", []),
            (result, level) => {
              if (level.id != _.get(root, "levelId", "")) {
                result.push(level);
              }
              return result;
            },
            []
          );

          const filteredNodes = _.filter(
            nodes,
            nodeItem => _.get(nodeItem, "atlCategory.id", "") == root.id
          );

          const children = _.map(
            _.filter(filteredNodes, item => item.levelId == "L2"),
            node => node.id
          );
          const filteredValue = _.map(filteredNodes, atlItem => atlItem.id);

          const isEmpty = _.get(filteredValue, `length`, 0) == 0;
          const lockedField = _.find(lockedDynamicFields, { id: rootNodeId });

          return (
            <SubjectBenchmarks
              key={rootNodeId}
              isEmpty={isEmpty}
              lockedField={lockedField}
              value={filteredValue}
              pypType={"atls"}
              subject={{ id: root.id, value: root.id, label: root.label }}
              levels={innerLevels}
              nodes={nodes}
              rootNodes={children}
              isLoading={false}
              onDeleteClicked={null}
              onAddClicked={() => this.onEditClick(root.id)}
              getEmptyComponent={this.getEmptyComponentForSubject}
            />
          );
        })}
      </div>
    ) : (
      this.getEmptyComponent()
    );
  }

  renderEdit = () => {
    return (
      <div className={classes.container}>
        {this.renderMainContent()}
        {this.renderModal()}
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const atlSet = getPYPElementSetFromNodes({
    nodes: _.uniqBy(
      [
        ..._.get(ownProps.resolvedValue, "atls", []),
        ..._.get(ownProps, "options.atlCategories", []),
      ],
      "id"
    ),
  });

  return {
    grades: _.get(ownProps.options, "grades", undefined),
    organizationId: state.login.userInfo.org_id,
    nodes: _.get(atlSet, "nodes", []),
    rootNodes: _.map(
      _.get(ownProps, "options.atlCategories", []),
      item => item.id
    ),
    isLoading: false,
    isData: true,
  };
};

IBSkills.propTypes = {
  ...UIBaseComponent.propTypes,
};

IBSkills.defaultProps = {
  ...UIBaseComponent.defaultProps,
};

export default compose(
  connect(mapStateToProps, null),
  I18nHOC({ resource: "unitPlan" })
)(IBSkills);
