import React from "react";
import classes from "./FolderItem.scss";
import classNames from "classnames";
import { FolderFilled } from "@toddle-design/web-icons";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { Tooltip } from "@toddle-design/web";

const FolderItem = props => {
  const { isFolderSelected, folderDetails, handleFolderChange, key } = props;
  const { value: id, label: name, color = "" } = folderDetails;
  const folderClass = classNames({
    [classes.folderContainer]: !isFolderSelected,
    [classes.selectedFolderContainer]: isFolderSelected,
  });

  const showTooltip = _.size(name) > 25;
  return (
    <div
      className={folderClass}
      onClick={() => handleFolderChange({ folder: id })}
      key={key}
    >
      <div className={classes.leftContainer}>
        {id && (
          <div className={classes.folderIcon}>
            <FolderFilled style={{ color: color }} size="x-small" />
          </div>
        )}
        <ConditionalWrapper
          condition={showTooltip}
          wrapper={<Tooltip tooltip={name} placement={"top"} />}
          type={"wrapperAsElement"}
        >
          <div className={classes.folderText}>{name}</div>
        </ConditionalWrapper>
      </div>
      {isFolderSelected && <div className={classes.selectedBar} />}
    </div>
  );
};

export default FolderItem;
