import React from "react";
import PropTypes from "prop-types";
const CommentIcon = props => {
  return (
    <svg height={props.height} width={props.width} viewBox="0 0 32 32">
      {props.number > 0 || props.showEmpty ? (
        <g strokeWidth="1" fill={props.fill && props.fill}>
          <path d="M15.99994,4.89307c6.64612,0,12.0531,4.3562,12.0531,9.71072s-5.407,9.71072-12.0531,9.71072a14.88036,14.88036,0,0,1-2.64685-.23858,1.34316,1.34316,0,0,0-.90174.153L7.68817,26.92728l1.27625-3.12952a1.34327,1.34327,0,0,0-.512-1.63364A9.11569,9.11569,0,0,1,3.947,14.60379c0-5.35452,5.40686-9.71072,12.053-9.71072m0-1.34333c-7.39856,0-13.39636,4.94907-13.39636,11.05405A10.47529,10.47529,0,0,0,7.72052,23.2905L6.29706,26.781a1.20417,1.20417,0,0,0,1.714,1.50723l5.10241-2.89056a16.175,16.175,0,0,0,2.88648.26013c7.39868,0,13.39648-4.94907,13.39648-11.054s-5.9978-11.054-13.39648-11.054Z" />
        </g>
      ) : (
        <g fill={props.fill && props.fill}>
          <path d="M15.99994,4.89307c6.64612,0,12.0531,4.3562,12.0531,9.71072s-5.407,9.71072-12.0531,9.71072a14.88036,14.88036,0,0,1-2.64685-.23858,1.34316,1.34316,0,0,0-.90174.153L7.68817,26.92728l1.27625-3.12952a1.34327,1.34327,0,0,0-.512-1.63364A9.11569,9.11569,0,0,1,3.947,14.60379c0-5.35452,5.40686-9.71072,12.053-9.71072m0-1.34333c-7.39856,0-13.39636,4.94907-13.39636,11.05405A10.47529,10.47529,0,0,0,7.72052,23.2905L6.29706,26.781a1.20417,1.20417,0,0,0,1.714,1.50723l5.10241-2.89056a16.175,16.175,0,0,0,2.88648.26013c7.39868,0,13.39648-4.94907,13.39648-11.054s-5.9978-11.054-13.39648-11.054Z" />
          <path d="M15.39545,21.49777a.67188.67188,0,1,1,0-1.34375c3.3584,0,6.51465-1.55273,7.85449-3.86328a.67167.67167,0,1,1,1.16211.67383C22.81537,19.71848,19.27631,21.49777,15.39545,21.49777Z" />
        </g>
      )}

      {props.number > 0 ? (
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          fontSize="12px"
          fontFamily="Arial"
          fill={props.fill && props.fill}
          dy=".3em"
        >
          {props.number <= 99 ? props.number : "99+"}
        </text>
      ) : (
        <text />
      )}
    </svg>
  );
};

CommentIcon.defaultProps = {
  height: 32,
  width: 32,
  number: 0,
};

CommentIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  number: PropTypes.number,
  showEmpty: PropTypes.bool,
};

export default CommentIcon;
