import {
  courseSchedulerFragment,
  slotFragment,
  unitPlanFragment,
  weeklyItemFragment,
} from "./SchedulerFragment";
import {
  getWeeklySchedulerQuery,
  getAssessmentDetailsCardQuery,
  getLeDetailsCardQuery,
  getYearlySchedulerQuery,
} from "./SchedulerQuery";
import client from "apolloClient";

export const writeWeeklyItemInCache = ({ id, data }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `WeeklyScheduledItem:${id}`,
      fragment: weeklyItemFragment.weeklyItem,
      fragmentName: "itemNode",
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getLeCardDetailsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getLeDetailsCardQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAssessmentCardDetailsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssessmentDetailsCardQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getYearlySchedulerFromCache = ({
  id,
  uids,
  endDate,
  startDate,
  isMYP = false,
  detailedUnitPlan = false,
  searchText,
  unitPlanTypes,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getYearlySchedulerQuery,
      variables: {
        id,
        uids,
        isMYP,
        endDate,
        startDate,
        detailedUnitPlan,
        searchText,
        unitPlanTypes,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeTimeSlotFragment = ({ id, data }) => {
  client.writeFragment({
    id: `WeeklySchedulerTimeSlot:${id}`,
    fragment: slotFragment.slot,
    fragmentName: "slotItem",
    data: data,
  });
};

export const getCourseWeeklySchedulerFromCache = ({
  id,
  startDate,
  endDate,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getWeeklySchedulerQuery,
      variables: {
        id,
        startDate,
        endDate,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeCourseWeeklySchedulerFragment = ({
  courseId,
  data,
  startDate,
  endDate,
}) => {
  client.writeFragment({
    id: `Course:${courseId}`,
    fragment: courseSchedulerFragment.courseWeeklyScheduler,
    fragmentName: "courseItem",
    data: data,
    variables: {
      endDate: endDate,
      startDate: startDate,
    },
  });
};

export const getUnitPlanItemFromCache = ({ id, uids = [] }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `UnitPlan:${id}`,
      fragment: unitPlanFragment.unitPlanDetail,
      fragmentName: "unitPlanItem",
      variables: {
        id,
        uids,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeUnitPlanItemToCache = ({ id, data, uids }) => {
  try {
    client.writeFragment({
      id: `UnitPlan:${id}`,
      fragment: unitPlanFragment.unitPlanDetail,
      fragmentName: "unitPlanItem",
      variables: {
        id,
        uids,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};
