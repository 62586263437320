/**--external-- */
import { graphql } from "react-apollo";

/**--relative-- */
import {
  getNotificationsCountQuery,
  getPlannerElementSetsOfCurriculumQuery,
} from "modules/CommonQuery";
import { getPlannerElementSetsOfCurriculumFromCache } from "modules/CommonGraphqlHelpers";

export const getNotificationsCountQueryEnhancer = graphql(
  getNotificationsCountQuery,
  {
    name: "getNotificationsCount",
    alias: "getNotificationsCount",
    options: ({ userId, userEntityType, categoryTabs }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: userEntityType,
        notificationCategoryFilter: categoryTabs,
      },
      context: {
        useHttpLink: true,
      },
    }),
    props({ getNotificationsCount, ownProps: { isData, isLoading } }) {
      const notification = _.get(
        getNotificationsCount,
        "node.notification",
        {}
      );

      return {
        getNotificationsCount,
        onPusherEventTriggered: () => getNotificationsCount.refetch(),
        isData: !_.isEmpty(notification) && isData,
        notificationCount: _.get(notification, "newCount", 0),
        isLoading:
          getNotificationsCount["networkStatus"] == 1 ||
          getNotificationsCount["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }
);

const getPlannerElementSetsOfCurriculumVariables = ({
  curriculumId,
  curriculumNodeInterfaceType,
  plannerElementTypes,
  filters,
}) => {
  return {
    id: curriculumId,
    type: curriculumNodeInterfaceType,
    elementTypes: plannerElementTypes,
    ...(!_.isEmpty(filters) && { filters }),
  };
};

export const getPlannerElementSetsOfCurriculumEnhancer = graphql(
  getPlannerElementSetsOfCurriculumQuery,
  {
    name: "getPlannerElementSetsOfCurriculum",
    skip: ({ isStepWithPlannerElement, isDPLE }) =>
      !isStepWithPlannerElement && !isDPLE,
    options: ({
      curriculumId,
      plannerElementTypes,
      curriculumNodeInterfaceType,
      filters,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: getPlannerElementSetsOfCurriculumVariables({
        curriculumId,
        curriculumNodeInterfaceType,
        plannerElementTypes,
        filters,
      }),
    }),
    props({
      getPlannerElementSetsOfCurriculum,
      ownProps: {
        isData,
        isLoading,
        curriculumId,
        plannerElementTypes,
        curriculumNodeInterfaceType,
        filters,
      },
    }) {
      const plannerElementSetsOfCurriculumVariables = getPlannerElementSetsOfCurriculumVariables(
        {
          curriculumId,
          curriculumNodeInterfaceType,
          plannerElementTypes,
          filters,
        }
      );
      const curriculumData = getPlannerElementSetsOfCurriculumFromCache(
        plannerElementSetsOfCurriculumVariables
      );
      return {
        plannerElements: _.get(curriculumData, "plannerElementSets", []),
        isData: !_.isEmpty(curriculumData) && isData,
        isLoading:
          getPlannerElementSetsOfCurriculum["networkStatus"] == 1 ||
          getPlannerElementSetsOfCurriculum["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }
);
