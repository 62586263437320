import React from "react";
import PropTypes from "prop-types";

const NoCycles = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 321 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15.4314 190.007C-15.0498 139.76 0.554909 73.8045 54.0269 50.7096C133.576 16.3447 165.132 61.2861 207.785 57.2289C250.438 53.1717 309.978 61.6329 309.666 151.204C309.319 233.076 237.399 248.924 158.96 248.75C124.941 248.681 46.1552 240.636 15.4314 190.007Z"
        fill="#E7E7F9"
      />
      <path
        d="M203.97 199.405L204.49 170.657C204.524 168.473 202.791 166.67 200.606 166.635L171.859 166.115C169.674 166.08 167.871 167.814 167.836 169.999L167.663 178.564C167.628 180.228 165.79 181.165 164.403 180.263C161.594 178.46 158.127 177.94 156.046 181.026C154.243 183.661 154.902 187.233 157.537 189.036C159.999 190.735 162.149 189.695 164.368 188.481C165.79 187.719 167.524 188.759 167.489 190.354L167.351 198.746C167.316 200.931 169.05 202.734 171.235 202.768L199.982 203.289C202.097 203.323 203.935 201.589 203.97 199.405Z"
        fill="#C28200"
      />
      <path
        d="M121.959 183.315C124.109 180.367 122.514 177.281 119.913 175.235C118.596 174.195 118.873 172.149 120.433 171.594L132.189 166.808C132.189 166.808 131.287 164.728 130.837 163.514L127.195 154.741C126.398 153.77 124.837 153.7 123.971 154.741C122.306 156.752 120.503 158.555 117.382 157.619C114.573 156.787 113.151 154.151 113.775 151.273C114.573 147.632 118.041 146.8 121.335 147.493C122.722 147.771 123.901 146.696 123.866 145.413L120.433 136.05C119.983 134.732 119.809 133.033 118.908 132.79L88.8774 144.095C86.8314 144.858 85.7564 147.112 86.5193 149.192L97.7894 179.916L109.406 175.651C110.932 175.096 112.492 176.379 112.215 177.974C111.799 180.402 111.521 182.725 113.775 184.494C116.307 186.47 120.052 185.915 121.959 183.315Z"
        fill="#7474E0"
      />
      <path
        d="M144.152 161.815C145.366 160.983 145.366 159.457 144.152 158.486C141.552 156.371 139.54 153.804 141.621 149.955C142.349 148.603 144.187 147.701 145.297 147.667C150.533 147.389 152.405 150.996 151.677 155.296C151.435 156.752 152.683 157.931 154.07 157.723L162.323 154.325C164.438 153.458 165.479 150.996 164.577 148.88L154.105 123.566C153.03 120.931 150.013 119.717 147.377 120.792L118.249 133.033L123.242 145.101C123.311 146.384 121.578 147.528 120.225 147.285C115.891 146.557 112.007 148.707 113.324 154.463C113.498 155.261 114.434 156.856 115.648 157.307C119.497 158.833 121.959 157.48 124.04 154.81C124.872 153.735 126.432 153.77 127.265 154.671L132.258 166.739L144.152 161.815Z"
        fill="#7474E0"
      />
      <path
        d="M143.564 197.81L140.305 188.967C140.374 187.753 141.518 186.782 142.836 187.06C146.165 187.753 149.633 186.921 150.396 183.211C151.02 180.194 149.182 177.177 146.165 176.414C143.217 175.686 141.553 177.454 139.923 179.361C139.091 180.367 137.634 180.332 136.802 179.465L132.19 166.774L119.984 171.594C118.839 172.357 118.527 174.264 119.706 175.166C122.411 177.246 124.214 180.298 121.822 183.28C119.984 185.569 116.69 186.054 114.227 184.424C111.453 182.586 111.904 180.332 112.286 177.732C112.528 176.171 111.384 175.061 109.927 175.27L97.7559 179.708L109.13 210.432C109.893 212.478 112.147 213.553 114.227 212.79L141.241 202.872C143.252 202.144 144.292 199.856 143.564 197.81Z"
        fill="#7474E0"
      />
      <path
        d="M141.691 162.855C141.691 162.855 144.015 161.399 146.234 162.612C148.453 163.826 147.69 166.462 147.69 168.889C147.69 171.316 150.222 173.328 152.649 173.328C155.077 173.328 157.4 172.045 158.267 170.415C159.134 168.785 159.481 166.6 158.267 164.832C157.435 163.618 155.215 163.063 154.452 161.433C153.689 159.804 154.244 157.896 156.013 156.925L153.898 142.326L138.64 148.672L141.136 162.647L141.691 162.855Z"
        fill="#7474E0"
      />
      <path
        d="M120.848 181.199C122.998 178.252 121.403 175.165 118.802 173.12C117.485 172.079 117.762 170.033 119.323 169.478L131.078 164.693C131.078 164.693 130.177 162.612 129.726 161.399L126.085 152.625C125.287 151.654 123.727 151.585 122.86 152.625C121.195 154.637 119.392 156.44 116.271 155.504C113.462 154.671 112.04 152.036 112.665 149.158C113.462 145.517 116.93 144.684 120.224 145.378C121.611 145.655 122.79 144.58 122.756 143.297L119.323 133.935C118.872 132.617 118.698 130.918 117.797 130.675L87.7319 141.98C85.6859 142.742 84.6109 144.996 85.3738 147.077L96.6439 177.801L108.261 173.536C109.786 172.981 111.347 174.264 111.069 175.859C110.653 178.286 110.376 180.61 112.63 182.378C115.161 184.39 118.907 183.835 120.848 181.199Z"
        fill="#7474E0"
      />
      <path
        d="M143.044 159.734C144.258 158.902 144.258 157.376 143.044 156.405C140.443 154.29 138.432 151.724 140.513 147.875C141.241 146.522 143.079 145.621 144.188 145.586C149.425 145.309 151.297 148.915 150.569 153.215C150.326 154.671 151.575 155.85 152.962 155.642L161.215 152.244C163.33 151.377 164.37 148.915 163.469 146.8L152.996 121.485C151.921 118.85 148.904 117.636 146.269 118.711L117.071 130.952L122.064 143.02C122.134 144.303 120.4 145.447 119.047 145.205C114.713 144.476 110.829 146.626 112.147 152.383C112.32 153.18 113.256 154.775 114.47 155.226C118.319 156.752 120.781 155.4 122.862 152.729C123.694 151.654 125.255 151.689 126.087 152.591L131.08 164.693L143.044 159.734Z"
        fill="#9595E7"
      />
      <path
        d="M142.42 195.729L139.161 186.886C139.23 185.673 140.374 184.702 141.692 184.979C145.021 185.673 148.489 184.84 149.252 181.13C149.876 178.113 148.038 175.096 145.021 174.333C142.073 173.605 140.409 175.374 138.779 177.281C137.947 178.286 136.49 178.252 135.658 177.385L131.081 164.693L118.874 169.513C117.73 170.276 117.418 172.183 118.597 173.085C121.302 175.165 123.105 178.217 120.712 181.199C118.874 183.488 115.58 183.973 113.118 182.344C110.344 180.506 110.795 178.252 111.176 175.651C111.419 174.091 110.275 172.981 108.818 173.189L96.6465 177.628L108.021 208.351C108.783 210.397 111.037 211.472 113.118 210.709L140.131 200.792C142.143 200.064 143.183 197.775 142.42 195.729Z"
        fill="#9595E7"
      />
      <path
        d="M202.515 197.567L203.035 168.82C203.069 166.635 201.336 164.832 199.151 164.797L170.404 164.277C168.219 164.242 166.416 165.976 166.381 168.161L166.208 176.726C166.173 178.39 164.335 179.327 162.948 178.425C160.139 176.622 156.672 176.102 154.591 179.188C152.788 181.823 153.447 185.395 156.082 187.198C158.544 188.898 160.694 187.857 162.913 186.644C164.335 185.881 166.069 186.921 166.034 188.516L165.896 196.908C165.861 199.093 167.595 200.896 169.779 200.931L198.527 201.451C200.677 201.485 202.48 199.751 202.515 197.567Z"
        fill="#FFB41B"
      />
      <path
        d="M140.582 160.74C140.582 160.74 142.905 159.283 145.125 160.497C147.344 161.711 146.581 164.346 146.581 166.774C146.581 169.201 149.112 171.212 151.54 171.212C153.967 171.212 156.291 169.929 157.157 168.299C158.024 166.67 158.371 164.485 157.157 162.716C156.325 161.503 154.106 160.948 153.343 159.318C152.58 157.688 153.135 155.781 154.903 154.81L152.788 140.211L137.53 146.557L140.027 160.532L140.582 160.74Z"
        fill="#9595E7"
      />
      <path
        d="M165.653 156.301L166.936 153.978"
        stroke="#F75961"
        strokeWidth="1.1513"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.663 158.763L170.09 157.376"
        stroke="#F75961"
        strokeWidth="1.1513"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.592 197.983L147.309 200.445"
        stroke="#F75961"
        strokeWidth="1.1513"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.677 198.468L152.058 201.069"
        stroke="#F75961"
        strokeWidth="1.1513"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.679 187.06H186.875"
        stroke="#C28200"
        strokeWidth="2.706"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.6"
        d="M199.359 9.4093L194.713 15.3738L193.603 14.6455L182.194 31.4986L146.928 77.0642L151.089 80.2892L152.303 81.2254L148.037 88.0915L153.482 91.4551L152.719 92.7035C152.233 93.4664 152.129 94.3333 152.303 95.1309C152.476 95.9978 152.996 96.8301 153.828 97.3502L247.699 155.608L247.873 155.33C250.196 155.954 252.762 155.018 254.114 152.833L297.981 82.127C299.299 80.0117 298.64 77.3069 296.559 75.9892L239.515 40.5493L199.359 9.4093Z"
        fill="#A5A5EB"
      />
      <path
        d="M204.908 12.981L295.45 69.1578C297.53 70.4409 298.189 73.1804 296.871 75.2957L253.005 146.002C251.653 148.187 249.086 149.123 246.763 148.499C246.174 148.36 245.653 148.117 245.133 147.805L227.725 136.743L220.894 132.374L158.961 92.8769C157.054 91.6632 155.667 89.8947 154.938 87.9181C153.898 85.1093 154.106 81.8496 155.805 79.1101L195.441 15.2004C197.452 11.9754 201.718 11.0044 204.908 12.981Z"
        fill="#D8A932"
      />
      <path
        d="M191.452 0.774716C192.145 -0.0922098 193.394 -0.265595 194.295 0.427946L249.917 43.5662L281.057 67.7014C281.924 68.3949 282.098 69.6433 281.404 70.5449L270.793 84.2423L232.613 133.484L231.504 134.94C230.81 135.807 229.562 135.98 228.66 135.287L201.057 113.891L144.43 70.0247L141.864 68.0481C140.997 67.3546 140.823 66.1062 141.517 65.2046L175.535 21.3035L188.053 5.14402L191.452 0.774716Z"
        fill="#C4F5F0"
      />
      <path
        opacity="0.7"
        d="M191.798 9.86009C192.63 8.68107 194.26 8.40365 195.474 9.16655L254.043 47.45L286.605 71.1344L243.64 140.35L206.57 117.359L148.209 72.9723L181.013 24.7712L191.798 9.86009Z"
        fill="#6BDCDF"
      />
      <path
        d="M149.215 82.7165L241.352 139.899C242.635 140.697 244.3 140.28 245.063 139.032L287.334 70.9264C288.132 69.6433 287.715 67.9788 286.467 67.2159L194.33 9.99881C193.047 9.20124 191.383 9.61736 190.62 10.8657L148.348 79.0061C147.586 80.2545 147.967 81.919 149.215 82.7165Z"
        fill="white"
      />
      <path
        d="M286.051 63.3321L260.772 47.6581C256.715 45.1613 251.721 45.4387 248.045 48.0395L198.804 17.4891C196.897 16.31 194.4 16.8995 193.221 18.8068L151.608 85.9068C151.158 86.6697 151.019 87.5367 151.192 88.3342L293.16 73.0417C293.299 68.9498 289.831 65.6901 286.051 63.3321Z"
        fill="#FFCF6C"
      />
      <path
        d="M293.125 73.007C293.125 73.2844 293.16 73.5271 293.09 73.8045L246.554 148.776L152.683 90.5189C151.851 89.9987 151.331 89.2011 151.157 88.2995L154.902 87.8834L293.125 73.007Z"
        fill="#FFBD36"
      />
      <path
        d="M205.65 33.1747L200.019 42.25L270.499 85.9881L276.131 76.9129L205.65 33.1747Z"
        fill="white"
      />
      <path
        d="M210.628 43.1847L265.348 76.9601"
        stroke="#F97C82"
        strokeWidth="3.8923"
        strokeMiterlimit="10"
      />
      <path
        d="M317.432 173.813C318.85 173.813 319.998 172.664 319.998 171.247C319.998 169.83 318.85 168.681 317.432 168.681C316.015 168.681 314.866 169.83 314.866 171.247C314.866 172.664 316.015 173.813 317.432 173.813Z"
        stroke="#6BDCDF"
        strokeWidth="1.7519"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.089 35.7292C152.506 35.7292 153.655 34.5803 153.655 33.1631C153.655 31.7459 152.506 30.597 151.089 30.597C149.671 30.597 148.522 31.7459 148.522 33.1631C148.522 34.5803 149.671 35.7292 151.089 35.7292Z"
        stroke="#FFBF39"
        strokeWidth="1.7519"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.076 180.159C230.819 180.159 232.232 178.746 232.232 177.003C232.232 175.261 230.819 173.848 229.076 173.848C227.334 173.848 225.921 175.261 225.921 177.003C225.921 178.746 227.334 180.159 229.076 180.159Z"
        stroke="#FCB1BE"
        strokeWidth="2.1714"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.3262 198.711L69.7705 204.19"
        stroke="#FB93A6"
        strokeWidth="3.6541"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.7706 198.711L63.9795 204.19"
        stroke="#FB93A6"
        strokeWidth="3.6541"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3066 80.4972L40.988 85.1786"
        stroke="#FFAB00"
        strokeWidth="3.1177"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9881 80.4972L36.0293 85.1786"
        stroke="#FFAB00"
        strokeWidth="3.1177"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M276.167 139.413L279.149 156.544"
          stroke="#A5A5EB"
          strokeWidth="3.8923"
          strokeMiterlimit="10"
        />
        <g opacity="0.6">
          <path
            opacity="0.6"
            d="M272.492 139.968C285.381 139.968 295.83 129.52 295.83 116.631C295.83 103.742 285.381 93.293 272.492 93.293C259.603 93.293 249.154 103.742 249.154 116.631C249.154 129.52 259.603 139.968 272.492 139.968Z"
            fill="#A5A5EB"
          />
          <path
            opacity="0.49"
            d="M272.492 139.968C285.381 139.968 295.83 129.52 295.83 116.631C295.83 103.742 285.381 93.293 272.492 93.293C259.603 93.293 249.154 103.742 249.154 116.631C249.154 129.52 259.603 139.968 272.492 139.968Z"
            fill="#A5A5EB"
          />
          <path
            opacity="0.6"
            d="M272.492 139.968C285.381 139.968 295.83 129.52 295.83 116.631C295.83 103.742 285.381 93.293 272.492 93.293C259.603 93.293 249.154 103.742 249.154 116.631C249.154 129.52 259.603 139.968 272.492 139.968Z"
            fill="#A5A5EB"
          />
        </g>
        <path
          opacity="0.6"
          d="M283.173 177.558C280.294 178.078 277.555 176.136 277.035 173.258L272.631 148.048L283.034 146.21L287.438 171.42C287.958 174.333 286.051 177.073 283.173 177.558Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.6"
          d="M272.665 148.742L274.919 148.36L279.254 172.391C279.254 172.391 279.809 175.408 281.924 176.379C284.039 177.35 285.183 176.795 285.183 176.795C285.183 176.795 283.588 177.87 281.716 177.628C279.878 177.385 278.005 176.414 277.242 174.091C276.792 172.703 272.665 148.742 272.665 148.742Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.7"
          d="M278.49 94.6454C278.49 94.6454 285.703 103.245 281.784 115.798C277.866 128.386 262.261 132.617 253.037 127.693C253.037 127.693 258.1 137.922 271.069 139.171C284.038 140.454 291.563 128.976 291.563 128.976C291.563 128.976 297.909 119.093 293.679 108.62C289.517 98.1478 278.49 94.6454 278.49 94.6454Z"
          fill="#A5A5EB"
        />
      </g>
      <path
        d="M270.864 138.72L273.846 155.85"
        stroke="#2DADBA"
        strokeWidth="7.6114"
        strokeMiterlimit="10"
      />
      <path
        d="M267.186 139.309C280.075 139.309 290.524 128.861 290.524 115.972C290.524 103.083 280.075 92.6342 267.186 92.6342C254.297 92.6342 243.849 103.083 243.849 115.972C243.849 128.861 254.297 139.309 267.186 139.309Z"
        fill="#F9F9FB"
      />
      <path
        opacity="0.49"
        d="M267.186 139.309C280.075 139.309 290.524 128.861 290.524 115.972C290.524 103.083 280.075 92.6342 267.186 92.6342C254.297 92.6342 243.849 103.083 243.849 115.972C243.849 128.861 254.297 139.309 267.186 139.309Z"
        fill="white"
        stroke="white"
        strokeWidth="1.288"
        strokeMiterlimit="10"
      />
      <path
        d="M267.186 139.309C280.075 139.309 290.524 128.861 290.524 115.972C290.524 103.083 280.075 92.6342 267.186 92.6342C254.297 92.6342 243.849 103.083 243.849 115.972C243.849 128.861 254.297 139.309 267.186 139.309Z"
        stroke="#35C4CF"
        strokeWidth="5.152"
        strokeMiterlimit="10"
      />
      <path
        d="M277.868 176.899C274.99 177.42 272.25 175.478 271.73 172.599L267.326 147.389L277.729 145.551L282.133 170.762C282.653 173.64 280.746 176.379 277.868 176.899Z"
        fill="#35C4CF"
      />
      <path
        d="M264.864 147.597L279.879 144.927"
        stroke="#2DADBA"
        strokeWidth="5.0743"
        strokeMiterlimit="10"
      />
      <path
        d="M267.362 148.048L269.616 147.667L273.951 171.698C273.951 171.698 274.506 174.715 276.621 175.686C278.736 176.657 279.881 176.102 279.881 176.102C279.881 176.102 278.286 177.177 276.413 176.934C274.575 176.691 272.703 175.72 271.94 173.397C271.489 172.045 267.362 148.048 267.362 148.048Z"
        fill="#2DADBA"
      />
      <path
        opacity="0.7"
        d="M273.187 93.9866C273.187 93.9866 280.4 102.586 276.482 115.14C272.563 127.727 256.958 131.958 247.734 127.034C247.734 127.034 252.797 137.264 265.766 138.512C278.736 139.795 286.261 128.317 286.261 128.317C286.261 128.317 292.606 118.434 288.376 107.961C284.215 97.4889 273.187 93.9866 273.187 93.9866Z"
        fill="#C4F5F0"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M42.3747 164.242C41.057 166.427 38.1788 167.12 35.9941 165.803C33.8095 164.485 33.1159 161.607 34.4337 159.422L36.1328 156.336L44.0045 160.636L42.3747 164.242Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M55.5517 120.272L63.1806 113.857C63.4233 113.648 63.8395 113.822 63.8395 114.134L63.3887 124.017L61.7242 127.207L54.0605 123.115L55.5517 120.272Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M55.5522 120.272L35.3008 157.896L43.3112 162.3L63.3892 124.017L62.4876 124.329C62.0021 124.502 61.4473 124.26 61.2045 123.809L60.6497 122.734L59.8521 123.046C59.3666 123.219 58.7771 123.011 58.5344 122.56L57.9449 121.451L57.286 121.659C56.8005 121.832 56.2457 121.589 56.003 121.139L55.5522 120.272Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M60.6152 116.006L63.6668 117.567L63.8055 114.169C63.8055 114.169 63.8402 113.891 63.5628 113.787C63.3894 113.718 63.1813 113.856 63.1813 113.856L60.6152 116.006Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M35.3008 157.896L43.3112 162.3L43.866 161.225L35.8556 156.891L35.3008 157.896Z"
          fill="#A5A5EB"
        />
      </g>
      <path
        d="M39.6374 160.844C38.3197 163.029 35.4415 163.722 33.2568 162.404C31.0722 161.087 30.3786 158.208 31.6964 156.024L33.3955 152.938L41.2672 157.237L39.6374 160.844Z"
        fill="#FFAB00"
      />
      <path
        d="M52.8144 116.908L60.4433 110.493C60.686 110.285 61.1022 110.458 61.1022 110.77L60.6514 120.653L58.9869 123.844L51.3232 119.752L52.8144 116.908Z"
        fill="#FFE6AA"
      />
      <path
        d="M52.8149 116.908L32.5635 154.498L40.5739 158.902L60.6519 120.619L59.7503 120.931C59.2648 121.104 58.71 120.861 58.4672 120.41L57.9124 119.335L57.1148 119.648C56.6293 119.821 56.0398 119.613 55.7971 119.162L55.2076 118.052L54.5487 118.26C54.0632 118.434 53.5084 118.191 53.2657 117.74L52.8149 116.908Z"
        fill="#F75961"
      />
      <path
        d="M57.8779 112.608L60.9295 114.169L61.0682 110.77C61.0682 110.77 61.1029 110.493 60.8255 110.389C60.6521 110.319 60.444 110.458 60.444 110.458L57.8779 112.608Z"
        fill="#393939"
      />
      <path
        d="M32.5635 154.498L40.5392 158.902L41.1287 157.862L33.0836 153.527L32.5635 154.498Z"
        fill="#BB4146"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M78.1133 72.5833C80.2921 71.5464 81.5513 69.6404 80.9259 68.3262C80.3004 67.012 78.0272 66.7873 75.8484 67.8242C73.6696 68.8611 72.4103 70.767 73.0358 72.0812C73.6612 73.3954 75.9345 73.6202 78.1133 72.5833Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M77.5359 80.6012L75.5246 73.5965C75.1432 72.2787 75.7674 70.857 76.9811 70.2675C78.1948 69.678 79.6512 70.0247 80.4488 71.065L85.0608 76.9948L77.5359 80.6012Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M82.7741 81.8697C85.5926 80.5283 87.2238 78.0671 86.4173 76.3725C85.6108 74.6779 82.6721 74.3915 79.8535 75.7329C77.0349 77.0743 75.4038 79.5355 76.2103 81.2301C77.0168 82.9247 79.9555 83.2111 82.7741 81.8697Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M80.5898 80.4625C80.5898 80.4625 83.0519 79.3182 84.1616 79.5956L86.3116 88.8891L80.5898 80.4625Z"
          fill="#A5A5EB"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M105.277 77.1732C105.406 75.7233 103.562 74.3748 101.158 74.1611C98.7541 73.9474 96.7011 74.9496 96.5722 76.3994C96.4433 77.8492 98.2874 79.1978 100.691 79.4115C103.095 79.6251 105.148 78.623 105.277 77.1732Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M96.124 86.0455L97.9272 78.9714C98.274 77.6537 99.5224 76.7521 100.875 76.8561C102.227 76.9601 103.302 78.0004 103.476 79.3182L104.447 86.7737L96.124 86.0455Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M105.942 86.9162C106.109 85.0467 103.723 83.307 100.613 83.0306C97.5034 82.7542 94.8479 84.0457 94.6818 85.9153C94.5156 87.7848 96.9016 89.5244 100.011 89.8008C103.121 90.0772 105.776 88.7857 105.942 86.9162Z"
          fill="#A5A5EB"
        />
        <path
          opacity="0.5"
          d="M97.8584 87.2592C97.8584 87.2592 100.875 86.9124 101.673 87.71L98.1705 97.7317L97.8584 87.2592Z"
          fill="#A5A5EB"
        />
      </g>
      <path
        d="M76.9277 70.5394C79.1065 69.5024 80.3658 67.5965 79.7403 66.2823C79.1149 64.9681 76.8416 64.7433 74.6628 65.7803C72.484 66.8172 71.2248 68.7231 71.8502 70.0373C72.4757 71.3515 74.7489 71.5763 76.9277 70.5394Z"
        fill="#FFAB00"
      />
      <path
        d="M76.3591 78.5553L74.3479 71.5505C73.9664 70.2328 74.5906 68.811 75.8043 68.2215C77.018 67.632 78.4744 67.9788 79.272 69.0191L83.884 74.9489L76.3591 78.5553Z"
        fill="#FFAB00"
      />
      <path
        d="M81.5885 79.8258C84.4071 78.4844 86.0382 76.0232 85.2317 74.3286C84.4253 72.634 81.4866 72.3476 78.668 73.689C75.8494 75.0304 74.2182 77.4916 75.0247 79.1862C75.8312 80.8808 78.7699 81.1672 81.5885 79.8258Z"
        fill="#C28200"
      />
      <path
        d="M79.793 77.8271C79.793 77.8271 80.2784 76.6827 81.3534 76.9601L85.376 86.7737L79.793 77.8271Z"
        fill="#FFFAE3"
      />
      <path
        opacity="0.8"
        d="M74.2783 71.169C74.2783 71.169 75.2146 68.0134 79.1678 68.8457"
        stroke="#C28200"
        strokeWidth="1.1216"
        strokeMiterlimit="10"
      />
      <path
        d="M104.114 75.1476C104.243 73.6978 102.398 72.3492 99.9947 72.1356C97.5911 71.9219 95.538 72.924 95.4091 74.3739C95.2803 75.8237 97.1244 77.1722 99.528 77.3859C101.932 77.5996 103.985 76.5975 104.114 75.1476Z"
        fill="#F75961"
      />
      <path
        d="M94.9443 83.9996L96.7475 76.9601C97.0943 75.6424 98.3427 74.7408 99.6951 74.8448C101.047 74.9489 102.122 75.9892 102.296 77.3069L103.267 84.7625L94.9443 83.9996Z"
        fill="#F75961"
      />
      <path
        d="M104.745 84.8876C104.911 83.0181 102.525 81.2784 99.4157 81.002C96.3062 80.7256 93.6507 82.0171 93.4845 83.8866C93.3183 85.7562 95.7044 87.4958 98.8139 87.7722C101.923 88.0486 104.579 86.7571 104.745 84.8876Z"
        fill="#BB4146"
      />
      <path
        d="M97.9609 85.0746C97.9609 85.0746 98.9666 84.4851 99.7641 85.2826L98.273 95.3043L97.9609 85.0746Z"
        fill="#FFFAE3"
      />
      <path
        opacity="0.8"
        d="M96.8857 76.5787C96.8857 76.5787 99.8333 74.186 102.261 77.0642"
        stroke="#BB4146"
        strokeWidth="1.1216"
        strokeMiterlimit="10"
      />
      <path
        d="M179.316 183.453C181.882 183.453 183.962 181.373 183.962 178.807C183.962 176.24 181.882 174.16 179.316 174.16C176.749 174.16 174.669 176.24 174.669 178.807C174.669 181.373 176.749 183.453 179.316 183.453Z"
        fill="white"
      />
      <path
        d="M181.672 180.61C182.668 180.61 183.476 179.802 183.476 178.807C183.476 177.811 182.668 177.003 181.672 177.003C180.676 177.003 179.869 177.811 179.869 178.807C179.869 179.802 180.676 180.61 181.672 180.61Z"
        fill="#2B2B2B"
      />
      <path
        d="M189.856 183.453C192.422 183.453 194.502 181.373 194.502 178.807C194.502 176.24 192.422 174.16 189.856 174.16C187.289 174.16 185.209 176.24 185.209 178.807C185.209 181.373 187.289 183.453 189.856 183.453Z"
        fill="white"
      />
      <path
        d="M187.429 180.61C188.425 180.61 189.232 179.802 189.232 178.807C189.232 177.811 188.425 177.003 187.429 177.003C186.433 177.003 185.626 177.811 185.626 178.807C185.626 179.802 186.433 180.61 187.429 180.61Z"
        fill="#2B2B2B"
      />
    </svg>
  );
};

NoCycles.defaultProps = {
  height: 249,
  width: 321,
};

NoCycles.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default NoCycles;
