import { getMemoizedFunction, localSearch } from "Utils";

const trimSnpCodeLabels = ({ code }) => {
  const redundantCharacters = [" ", "(", ")"];

  return _.trim(code, redundantCharacters);
};

const getStandards = ({ frameworks, t }) => {
  return _.flatMap(frameworks, framework => {
    const standards = _.get(framework, "standards", []);
    const updatedStandards = _.map(standards, standard => {
      const label = _.get(standard, "label", "");
      const localizedLabel = _.get(standard, "localizedLabel", "");
      const code = _.get(standard, "code", "");
      const practicesV2 = _.get(standard, "practicesV2", []);
      const practices = _.map(
        _.get(practicesV2, "edges", []),
        ({ node }) => node
      );
      const updatedPractices = _.map(
        practices,
        ({ label, localizedLabel, code, ...rest }) => {
          const sanitizedCodeLabel = trimSnpCodeLabels({ code });
          return {
            ...rest,
            code: sanitizedCodeLabel,
            label: t(`snpTemplate:${label}`, { defaultValue: localizedLabel }),
          };
        }
      );
      const sanitizedCodeLabel = trimSnpCodeLabels({ code });
      return {
        ...standard,
        label: t("snp:code_label", {
          code: sanitizedCodeLabel,
          label: t(`snpTemplate:${label}`, { defaultValue: localizedLabel }),
        }),
        practices: updatedPractices,
      };
    });
    return updatedStandards;
  });
};

export const getStandardsMemoized = getMemoizedFunction(getStandards);

const getFilteredStandards = ({ standards, searchText }) => {
  const updatedStandards = [];
  _.forEach(standards, standard => {
    const practices = _.get(standard, "practices", []);
    const filteredPractices = _.filter(practices, practice => {
      const practiceLabel = _.get(practice, "label", "");

      return localSearch({ text: practiceLabel, searchText }) >= 0;
    });

    if (_.size(filteredPractices)) {
      const updatedStandard = { ...standard, practices: filteredPractices };
      updatedStandards.push(updatedStandard);
    }
  });

  return updatedStandards;
};

export const getFilteredStandardsMemoized = getMemoizedFunction(
  getFilteredStandards
);
