/**--external */
import React, { useRef } from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import classNames from "classnames";

/**--internal-- */
import { withLoader } from "UIComponents";
import { getApplicableFiltersQuery } from "../../modules/Queries";
import { getApplicableFiltersFromCache } from "../../modules/GraphqlHelpers";
import { tabFiltersConfig } from "../../modules/Config";
import { getUpdatedModuleFacets, getFilterLabel } from "../../modules/Utils";
import { getApplicableFiltersInfoMemoized } from "../DynamicFiltersWrapper/DynamicFilters/utils";
import SearchInput from "../DynamicFiltersWrapper/DynamicFilters/SearchInput";
import { ELEMENT_TYPE_MYP_OBJECTIVES } from "Constants/stringConstants";
import FilterGroup from "../DynamicFiltersWrapper/DynamicFilters/FilterGroup";
import { useI18n } from "Hooks";
import { Button } from "@toddle-design/web";

/**--relative-- */
import classes from "./DynamicFiltersWrapper.scss";
import DynamicFilter from "./DynamicFilters";
import MoreFiltersButton from "./MoreFiltersButton";
import {
  moreFilterGrpContainerStyle,
  moreFiltersStyle,
  containerStyle,
  filterGrpContainerStyle,
  buttonDropDownContainerStyle,
  searchStyles,
} from "./DynamicFiltersWrapperStyles";
import { getParentFiltersObj } from "./utils";

const DynamicFiltersWrapper = props => {
  const {
    isSearchApplicable,
    areFiltersApplicable,
    searchText,
    applicableFilters,
    placeholder,
    updateSearchText,
    areMoreFiltersVisible,
    updateMoreFiltersVisibilityStatus,
    appliedFilters,
    curriculumProgramId,
    updateAppliedFilters,
    activeTab,
    updateFilterOptions,
    updateParentFilter,
    markDependentFilterAsUpdated,
    parentFilterObj,
    dependentFiltersConfig,
    parentFilters,
    updateLoadingFilters,
    plannerElementSets,
    curriculumType,
  } = props;

  const { t } = useI18n(["common", "snp", "progressReport"]);

  const containerParentRef = useRef();

  const headerClasses = classNames({
    [classes.headerWithMarginBottom]: areMoreFiltersVisible,
    [classes.header]: areFiltersApplicable,
    [classes.headerWithOnlySearch]: isSearchApplicable && !areFiltersApplicable,
  });

  const entityTypeInfo = _.get(
    tabFiltersConfig,
    `${activeTab}.entityTypeInfo`,
    {}
  );

  const topRowFilters = _.slice(applicableFilters, 0, 2);

  const moreFilters = _.slice(applicableFilters, 2);

  const parentFiltersData = getParentFiltersObj({
    parentFilters,
    entityTypeInfo,
    plannerElementSets,
    t,
    curriculumType,
    appliedFilters,
  });

  const updateInputField = ({ values, key, isParent = false }) => {
    if (isParent) {
      updateParentFilter({ parent: key });
    }

    updateAppliedFilters({
      appliedFilters: { [key]: values },
    });
  };

  const renderFilter = ({ Component, config, extraProps = {} }) => {
    const filter = config.filter;

    const filterData = _.get(entityTypeInfo, filter, {});

    const dependentFilterParents = _.get(
      _.find(
        dependentFiltersConfig,
        ({ filter: dependentFilter }) => dependentFilter === filter
      ),
      "parents",
      []
    );

    const isParent = _.includes(parentFilters, filter);

    const { filterLocale, isLabelPresentInSetLabels } = filterData;

    const label = getFilterLabel({
      plannerElementSets,
      t,
      filterLocale,
      isLabelPresentInSetLabels,
      entityType: filter,
      curriculumType,
    });

    const filterClassList = classNames(classes.filter, {
      [classes.mypObjectivesFilter]: filter == ELEMENT_TYPE_MYP_OBJECTIVES,
    });

    return (
      <div className={filterClassList}>
        <Component
          entityType={filter}
          parentFiltersData={parentFiltersData}
          values={appliedFilters[filter]}
          dependentFilterParents={dependentFilterParents}
          markDependentFilterAsUpdated={markDependentFilterAsUpdated}
          parentFilterObj={parentFilterObj}
          {...config}
          label={label}
          isParent={isParent}
          containerParentRef={containerParentRef}
          curriculumProgramId={curriculumProgramId}
          updateInputField={updateInputField}
          updateFilterOptions={updateFilterOptions}
          updateLoadingFilters={updateLoadingFilters}
          baseSetType={filterData.baseSetType}
          curriculumType={curriculumType}
          {...extraProps}
        />
      </div>
    );
  };

  const resetFilters = () => {
    const activeTabFilterKeys = _.keys(appliedFilters);
    let updatedFilters = {};
    _.forEach(activeTabFilterKeys, key => {
      updatedFilters = {
        ...updatedFilters,
        [key]:
          key == "ACADEMIC_YEAR" ? _.get(appliedFilters, "ACADEMIC_YEAR") : [],
      };
    });
    updateAppliedFilters({ appliedFilters: updatedFilters });
  };

  return (
    <React.Fragment>
      <div className={headerClasses} ref={containerParentRef}>
        {isSearchApplicable && (
          <SearchInput
            searchText={searchText}
            placeholder={t(placeholder)}
            {...searchStyles}
            updateSearchText={updateSearchText}
            key={activeTab}
          />
        )}
        {areFiltersApplicable && (
          <React.Fragment>
            <FilterGroup
              filters={topRowFilters}
              showRemoveButton={false}
              containerStyle={filterGrpContainerStyle}
            >
              {({ config }) =>
                renderFilter({ Component: DynamicFilter, config })
              }
            </FilterGroup>

            {_.size(applicableFilters) > 2 && (
              <MoreFiltersButton
                onButtonClick={updateMoreFiltersVisibilityStatus}
                isActivated={areMoreFiltersVisible}
              />
            )}
            <div className={classes.resetButtonContainer}>
              <Button
                variant={"inline-destructive"}
                size={"large"}
                onClick={resetFilters}
              >
                {t("common:reset")}
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
      {areFiltersApplicable && _.size(moreFilters) > 0 && (
        <div
          className={classes.moreFilters}
          style={areMoreFiltersVisible ? {} : moreFiltersStyle}
        >
          <FilterGroup
            filters={moreFilters}
            containerStyle={moreFilterGrpContainerStyle}
          >
            {({ config }) =>
              renderFilter({
                Component: DynamicFilter,
                config,
                extraProps: {
                  buttonDropDownContainerStyle,
                  isFilterVisible: areMoreFiltersVisible,
                },
              })
            }
          </FilterGroup>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { activeTab, curriculumType } = ownProps;

  return {
    isData: true,
    isLoading: false,
    showLoadingIcon: false,
    containerStyle,
    module: _.get(tabFiltersConfig, `${activeTab}.enum`, ""),
    placeholder: _.get(tabFiltersConfig, `${activeTab}.placeholder`, ""),
    parentFilters: _.get(
      tabFiltersConfig,
      `${activeTab}.curriculumWiseParentFilters.${curriculumType}`,
      []
    ),
    dependentFiltersConfig: _.get(
      tabFiltersConfig,
      `${activeTab}.dependentFilterConfig`,
      {}
    ),
  };
};

export default compose(
  connect(mapStateToProps),
  graphql(getApplicableFiltersQuery, {
    name: "getApplicableFiltersQuery",
    alias: "getApplicableFiltersQuery",
    skip: ({ areFiltersApplicable }) => !areFiltersApplicable,
    options: ({ module }) => {
      return { fetchPolicy: "cache-first", variables: { module } };
    },
    props: ({
      getApplicableFiltersQuery: { networkStatus },
      ownProps: { module, isData, isLoading, activeTab, curriculumType },
    }) => {
      const data = getApplicableFiltersFromCache({ module });

      const moduleFacets = getUpdatedModuleFacets({
        data,
        activeTab,
        curriculumType,
      });

      return {
        data,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
        isData: !_.isEmpty(data) && isData,

        applicableFilters: getApplicableFiltersInfoMemoized({
          moduleFacets,
          activeTab,
        }),
      };
    },
  }),
  withLoader
)(DynamicFiltersWrapper);
