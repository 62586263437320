import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import EditSchoolProfile from "./routes/EditSchoolProfile";

export default store => {
  return {
    path: "schoolprofile/:id",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("Community/routes/Home/routes/SchoolProfile/modules/SchoolProfileModule")
                .default;
              injectReducer(store, { key: "communityProfile", reducer });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community"
          );
        })
    ),
    childRoutes: [EditSchoolProfile(store)],
  };
};
