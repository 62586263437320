/**--external-- */
import React from "react";
import { Dropdown, DropdownMenu, Button, IconButton } from "@toddle-design/web";
import {
  DotsHorizontalOutlined,
  ChevronRightOutlined,
} from "@toddle-design/web-icons";

/**--relative-- */
import classes from "./Actions.scss";

const Actions = props => {
  const { tabs, onTabClick, onViewClick } = props;
  return (
    <div className={classes.container}>
      <Dropdown overlay={<DropdownMenu onClick={onTabClick} options={tabs} />}>
        <IconButton
          icon={<DotsHorizontalOutlined />}
          variant="plain"
          size="small"
        />
      </Dropdown>
      <IconButton
        icon={<ChevronRightOutlined />}
        variant="plain"
        size="small"
        onClick={onViewClick}
      />
    </div>
  );
};

export default Actions;

Actions.displayName = "Actions";
