import React, { useEffect, useRef, useState } from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import classes from "./PictureInPicture.scss";
import Portal from "../Portal";
import { VideoPlayer } from "UIComponents";
import { Rnd } from "react-rnd";
import { setPictureInPictureConfig } from "modules/Services";
import { colors } from "Constants";
import { CancelIcon } from "SvgComponents";
import * as TimeTracker from "lib/timeTracker";

const validMediaTypes = ["VIDEO"];

const PIP_DEFAULT_WIDTH = 350;
const PIP_DEFAULT_HEIGHT = 240;
const PIP_DEFAULT_X = window.innerWidth - PIP_DEFAULT_WIDTH;
const PIP_DEFAULT_Y = window.innerHeight - PIP_DEFAULT_HEIGHT;

const PictureInPicture = React.memo(
  ({
    setPictureInPictureConfig,
    url,
    mediaType,
    showPip,
    videoCurrentTime,
    item,
  }) => {
    const [dimensions, setDimensions] = useState({
      w: PIP_DEFAULT_WIDTH,
      h: PIP_DEFAULT_HEIGHT,
    });

    const onReady = player => {
      if (mediaType === "VIDEO" && player && videoCurrentTime) {
        player.seekTo(videoCurrentTime, "seconds");
      }
    };

    if (!showPip || !validMediaTypes.includes(mediaType)) {
      return null;
    }

    const renderPlayer = () => {
      switch (mediaType) {
        case "VIDEO": {
          return (
            <VideoPlayer videoUrl={url} autoPlay={true} onReady={onReady} />
          );
        }
        default: {
          return null;
        }
      }
    };

    const onClickClose = e => {
      e.stopPropagation();
      setPictureInPictureConfig({
        showPip: false,
      });
      setDimensions({
        w: PIP_DEFAULT_WIDTH,
        h: PIP_DEFAULT_HEIGHT,
      });
      TimeTracker.stopTimer({
        page_name: "Toddle Academy Video",
        reset: true,
        entity_type: "TODDLE_ACADEMY_VIDEO",
        entity_id: _.get(item, "id"),
        entity_name: _.get(item, "label"),
        target: "toddle_academy_pip",
        source: null,
      });
    };

    return (
      <Portal>
        <Rnd
          bounds="window"
          lockAspectRatio={true}
          onResize={(e, direction, ref, delta, position) => {
            setDimensions({
              w: ref.offsetWidth,
              h: ref.offsetHeight,
            });
          }}
          minWidth={PIP_DEFAULT_WIDTH}
          minHeight={PIP_DEFAULT_HEIGHT}
          className={classes.rndContainer}
          default={{
            width: PIP_DEFAULT_WIDTH,
            height: PIP_DEFAULT_HEIGHT,
            x: PIP_DEFAULT_X,
            y: PIP_DEFAULT_Y,
          }}
        >
          <div
            className={classes.rndContent}
            style={{
              width: `${dimensions.w}px`,
              height: `${dimensions.h}px`,
            }}
          >
            <div className={classes.toolBar}>
              <div className={classes.closeButton} onClick={onClickClose}>
                <CancelIcon fill={colors.gray48} width={18} height={18} />
              </div>
            </div>
            <div className={classes.content}>{renderPlayer()}</div>
          </div>
        </Rnd>
      </Portal>
    );
  }
);

PictureInPicture.displayName = "PictureInPicture";

const mapStateToProps = state => {
  return {
    url: _.get(state, "app_services.pictureInPictureConfig.url", null),
    mediaType: _.get(
      state,
      "app_services.pictureInPictureConfig.mediaType",
      null
    ),
    showPip: _.get(state, "app_services.pictureInPictureConfig.showPip", false),
    item: _.get(state, "app_services.pictureInPictureConfig.item", {}),
    videoCurrentTime: _.get(
      state,
      "app_services.pictureInPictureConfig.videoCurrentTime",
      0
    ),
  };
};

const mapActionCreators = {
  setPictureInPictureConfig,
};

export default compose(connect(mapStateToProps, mapActionCreators))(
  PictureInPicture
);
