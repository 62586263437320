import { LANGUAGE_OPTIONS } from "Constants";
import ACLStore from "lib/aclStore";

export const localeParamsHelper = ({ params, t }) => {
  const localeParam = {};
  _.forEach(params, param => {
    param.isPlainText
      ? (localeParam[param.key] = param.value)
      : (localeParam[param.key] = t(param.value));
  });
  return localeParam;
};

export const getLanguageSelectorOptions = () => {
  const languages = _.filter(LANGUAGE_OPTIONS, language => {
    return (
      _.get(language, "perm", true) === true ||
      ACLStore.can(_.get(language, "perm"))
    );
  });

  return _.map(languages, ({ label, value }) => ({ key: value, label }));
};
