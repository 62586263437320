import { withAsyncRouteLoading } from "UIComponents";

import ToolingRoute from "Tooling";

const getRouteObject = store => {
  return {
    path: "regions",
    component: withAsyncRouteLoading(() => {
      return new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const Component = require("./components").default;
            resolve(Component);
          },
          err => {
            reject(err);
          },
          "regions"
        );
      });
    }),
    childRoutes: [ToolingRoute(store)],
  };
};

export default getRouteObject;
