import gql from "graphql-tag";
import {
  courseFragment,
  gradeFragment,
  studentFragment,
  staffFragment,
  attachmentFragment,
  taggedPracticesFragment,
  studentResponseFragment,
  academicYearFragment,
} from "modules/CommonFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";

export const studentProgressReportAttendanceSummaryFragment = {
  studentProgressReportAttendanceSummary: gql`
    fragment studentProgressReportAttendanceSummaryItem on StudentProgressReportAttendanceV2 {
      id
      attendanceType
      metric
      attendanceCount
      attendanceOptionId
      subjectId
    }
  `,
};

export const studentProgressReportAttendanceFragment = {
  studentProgressReportAttendanceV2: gql`
    fragment studentProgressReportAttendanceItemV2 on StudentProgressReportAttendanceWrapper {
      startDate
      endDate
      attendanceSummary {
        ...studentProgressReportAttendanceSummaryItem
      }
    }
    ${studentProgressReportAttendanceSummaryFragment.studentProgressReportAttendanceSummary}
  `,
};

export const progressReportRatingFragment = {
  progressReportRating: gql`
    fragment progressReportRatingItem on StudentProgressReportPypElementRating {
      id
      nodeId
      academicCriteriaSet
      academicCriteriaValue
      ibPYPElementId
      ibPYPElementType
    }
  `,
};

export const progressReportRemarkFragment = {
  progressReportRemark: gql`
    fragment progressReportRemarkItem on StudentProgressReportRemark {
      id
      nodeId
      message
      createdBy {
        ...staffItem
      }
      createdAt
      attachments {
        ... on Attachment {
          ...attachmentItem
        }
        ... on Post {
          id
          title
          attachments {
            ...attachmentItem
          }
          items {
            item {
              id
              ...studentResponseItem
            }
            itemType
          }
        }
      }
    }
    ${attachmentFragment.attachment}
    ${staffFragment.basicStaffDetails}
    ${studentResponseFragment.studentResponse}
  `,
};

export const organizationGeneratedTemplateFragment = {
  organizationGeneratedTemplate: gql`
    fragment organizationGeneratedTemplateItem on Organization {
      id
      progressReportGeneratedTemplate(
        grades: $grades
        curriculumType: $curriculumType
        type: $type
      )
    }
  `,
};

export const progressReportTemplateFragment = {
  progressReportTemplateFeed: gql`
    fragment progressReportTemplateItem on ProgressReportTemplate {
      id
      name
      progressReportTaskGroups {
        id
        title
        academicYear {
          ...academicYearItem
        }
      }
      grades {
        ...gradeItem
      }
      updatedAt
      filteredNodes
    }
    ${gradeFragment.basicGradeDetails}
    ${academicYearFragment.academicYear}
  `,
  progressReportTemplateDetails: gql`
    fragment progressReportTemplateItem on ProgressReportTemplate {
      id
      name @skip(if: $skipParam)
      grades @skip(if: $skipParam) {
        ...gradeItem
      }
      body
    }
    ${gradeFragment.basicGradeDetails}
  `,
};

export const organizationProgressReportTemplateFragment = {
  organizationProgressReportTemplate: gql`
    fragment organizationProgressReportTemplateItem on Organization {
      id
      progressReportTemplates(filters: $filters) {
        totalCount
        edges {
          node {
            ...progressReportTemplateItem
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    ${progressReportTemplateFragment.progressReportTemplateFeed}
  `,
};

export const progressReportSignatoriesFragment = {
  progressReportSignatories: gql`
    fragment progressReportSignatoriesItem on Signatory {
      id
      name
      designation
      signatureImage
    }
  `,
};

export const progressReportFragment = {
  progressReportFeed: gql`
    fragment progressReportItem on CourseProgressReportTask {
      id
      title
      gradeSubjectObjectivesIsComplete
      studentProgressReport {
        id
        status
        student {
          id
        }
      }
      course {
        ...courseItem
        grades {
          id
        }
      }
      parentEntity {
        id
        ... on Grade {
          id
          name
        }
      }
      unitPlans {
        id
        title {
          id
          value
        }
      }
      filteredNodes
      deadlineDate
      progressReportTaskGroup {
        attendanceMode
      }
      attendanceDates {
        startDate
        endDate
      }
      reportingDates {
        startDate
        endDate
      }
      createdAt
      signatories {
        ...progressReportSignatoriesItem
      }
    }
    ${courseFragment.basicCourserDetails}
    ${progressReportSignatoriesFragment.progressReportSignatories}
  `,
};

export const progressReportTaskGroupFragment = {
  ProgressReportTaskGroupFeed: gql`
    fragment progressReportTaskGroupItem on ProgressReportTaskGroup {
      id
      title
      subtitle
      attendanceMode
      createdAt
      deadlineDate
      footerDate
      courseProgressReportTasks {
        id
        course {
          ...courseItem
        }
        parentEntity {
          id
          ... on Grade {
            id
            name
          }
        }
      }
      template {
        id
        name
        grades {
          id
        }
      }
      filteredNodes
    }
    ${courseFragment.basicCourserDetails}
  `,
};

export const setProgressReportFragment = {
  setProgressReport: gql`
    fragment setProgressReportFragmentItem on ProgressReportTaskGroup {
      id
      title
      subtitle
      attendanceMode
      courseProgressReportTasks(searchText: $searchText) {
        ...progressReportItem
      }
    }
    ${progressReportFragment.progressReportFeed}
  `,
};

export const organizationProgressReportGroupFragment = {
  organizationProgressReportGroup: gql`
    fragment organizationProgressReportGroupItem on Organization {
      id
      progressReportTaskGroups(
        first: 12
        after: $after
        filters: {
          searchText: $searchText
          academicYearId: $academicYearId
          curriculumProgramIds: $curriculumProgramIds
        }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...progressReportTaskGroupItem
          }
        }
      }
    }
    ${progressReportTaskGroupFragment.ProgressReportTaskGroupFeed}
  `,
};

export const courseProgressReportFragment = {
  courseProgressReportFeed: gql`
    fragment courseProgressReportItem on Course {
      id
      courseProgressReportTasks(filters: { searchText: $searchText }) {
        totalCount
        edges {
          node {
            ...progressReportItem
          }
        }
      }
    }
    ${progressReportFragment.progressReportFeed}
  `,
  courseProgressReportFeedCount: gql`
    fragment courseProgressReportItem on Course {
      id
      courseProgressReportTasks {
        totalCount
      }
    }
  `,
};

export const studentProgressReportFragment = {
  studentProgressReportFeed: gql`
    fragment studentProgressReportItem on StudentProgressReport {
      id
      status
      sectionCompleteStatus {
        section
        isCompleted
      }
      student {
        ...studentWithBasicCourseItem
      }
      courseProgressReportTask {
        id
        title
        course {
          ...courseItem
          grades {
            id
          }
        }
        reportingDates {
          startDate
          endDate
        }
        parentEntity {
          id
          ... on Grade {
            id
            name
          }
          ... on Course {
            id
            title
          }
        }
        progressReportTaskGroup {
          attendanceMode
        }
      }
      taggedPractices {
        ...taggedPracticesOnEvidence
      }
      publishedReport {
        id
      }
    }
    ${courseFragment.basicCourserDetails}
    ${studentFragment.basicStudentDetailsWithBasicCourse}
    ${taggedPracticesFragment.taggedPractices}
  `,
  studentProgressReportStatus: gql`
    fragment studentProgressReportStatusItem on StudentProgressReport {
      id
      status
    }
  `,
  studentProgressReportPublishedTemplate: gql`
    fragment studentProgressReportItem on StudentProgressReport {
      id
      publishedReport {
        id
        template
        curriculumProgram {
          id
          type
        }
      }
    }
  `,
  studentProgressReportTemplate: gql`
    fragment studentProgressReportItem on StudentProgressReport {
      id
      template
    }
  `,
  studentProgressReportDetails: gql`
    fragment studentProgressReportItem on StudentProgressReport {
      id
      student {
        ...studentItem
      }
      attendanceSummaryV2 {
        ...studentProgressReportAttendanceItemV2
      }
      ratings {
        ...progressReportRatingItem
      }
      remarks {
        ...progressReportRemarkItem
      }
      completedSections
      status
      sectionCompleteStatus {
        section
        isCompleted
      }
    }
    ${progressReportRatingFragment.progressReportRating}
    ${progressReportRemarkFragment.progressReportRemark}
    ${studentFragment.basicStudentDetails}
    ${studentProgressReportAttendanceFragment.studentProgressReportAttendanceV2}
  `,
};

export const courseStudentProgressReportFragment = {
  courseStudentProgressReportFeed: gql`
    fragment courseStudentProgressReportItem on CourseProgressReportTask {
      id
      studentProgressReport(
        filters: $filters
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        orderByInput: $orderByInput
      ) {
        ...studentProgressReportItem
      }
    }

    ${studentProgressReportFragment.studentProgressReportFeed}
  `,
  courseStudentProgressReportBasicDetails: gql`
    fragment courseStudentProgressReportItem on CourseProgressReportTask {
      id
      title
      deadlineDate
      course {
        ...courseItem
        grades {
          id
        }
      }
      parentEntity {
        ... on Grade {
          id
          name
        }
        ... on Course {
          ...courseItem
        }
      }
      studentProgressReport {
        ...studentProgressReportStatusItem
      }
      unitPlans {
        id
      }
      reportSubjects {
        id
        name
      }
      completionStatusReportCount {
        count
        status
      }
      reportStatusCount {
        status
        count
      }
    }
    ${studentProgressReportFragment.studentProgressReportStatus}
    ${courseFragment.basicCourserDetails}
  `,
  courseStudentProgressReportTemplate: gql`
    fragment courseStudentProgressReportItem on CourseProgressReportTask {
      id
      template
    }
  `,
};

export const publishedStudentProgressReportFragment = {
  publishedStudentProgressReport: gql`
    fragment publishedStudentProgressReportItem on Student {
      id
      publishedProgressReports {
        id
        filteredNodes
        publishedAt
      }
    }
  `,
};

export const progressReportObjectiveFeedFragment = {
  progressReportObjectiveFeed: gql`
    fragment progressReportObjectiveFeed on ProgressReportTaskGroup {
      id
      gradeSubjectObjectivesWrapper(filters: $filters) {
        grades {
          id
          name
        }
        gradeSubjectObjectives {
          subject {
            id
            name
            associatedChildPlannerElementNodes(
              filters: { depth: 1, types: MYP_OBJECTIVES }
            ) {
              id
              label
            }
          }
          gradeObjective {
            grade {
              id
              name
            }
            objective {
              id
              label
            }
          }
        }
      }
    }
  `,
};
export const studentProgressReportConfigurationUnitsFeedFragment = {
  studentProgressReportConfigurationUnitsFeed: gql`
    fragment studentProgressReportConfigurationUnitsFeedItem on UnitPlan {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      fields(uids: $fieldUids) {
        ...unitplanFieldBasicDetailsItem
      }
    }
    ${plannerFieldFragment.unitplanFieldBasicDetails}
  `,
};
export const studentProgressReportConfigurationUnitsFragment = {
  studentProgressReportConfigurationUnits: gql`
    fragment studentProgressReportConfigurationUnits on Grade {
      id
      name
      unitPlansCovered: unitPlans(
        filters: {
          duration: $duration
          subjects: $subjects
          unitTypes: $unitTypes
        }
        academicYears: $academicYears
      ) {
        ...studentProgressReportConfigurationUnitsFeedItem
      }
      unitPlansNotCovered: unitPlans(
        filters: { subjects: $subjects, unitTypes: $unitTypes }
        academicYears: $academicYears
      ) {
        ...studentProgressReportConfigurationUnitsFeedItem
      }
    }
    ${studentProgressReportConfigurationUnitsFeedFragment.studentProgressReportConfigurationUnitsFeed}
  `,
};
