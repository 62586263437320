import React, { PureComponent } from "react";
import classes from "./ResponsesView.scss";
import ResponseItem from "./ResponseItem";

class ResponsesView extends PureComponent {
  render = () => {
    const { value } = this.props;

    return (
      <div className={classes.feedContainer}>
        {_.map(value, item => {
          return <ResponseItem item={item} key={item.id} />;
        })}
      </div>
    );
  };
}

export default ResponsesView;
