import gql from "graphql-tag";
import { studentReportFragments } from "./ParentProgressReportFragments";

export const getStudentProgressReportFeedQuery = gql`
  query getStudentProgressReportFeed(
    $id: ID!
    $filters: PublishedProgressReportsFilter
  ) {
    node(id: $id, type: STUDENT) {
      id
      ... on Student {
        id
        publishedProgressReports(filters: $filters) {
          ...studentReportItem
        }
      }
    }
  }
  ${studentReportFragments.reportFeed}
`;
