import React from "react";

const AicnNews = props => {
  return (
    <svg width="61px" height="48px" viewBox="0 0 61 48" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-14" transform="translate(0.000100, 0.000000)">
          <path
            d="M23.4911,20.9706 L49.4731,20.9706 C50.2201,20.9706 50.8281,20.3626 50.8281,19.6156 L50.8281,11.3516 C50.8281,10.6046 50.2201,9.9966 49.4731,9.9966 L23.4911,9.9966 C22.7441,9.9966 22.1361,10.6046 22.1361,11.3516 L22.1361,19.6156 C22.1361,20.3626 22.7441,20.9706 23.4911,20.9706"
            id="Fill-1"
            fill="#FEDCE3"
          />
          <path
            d="M20.9105,11.3519 C20.9105,9.9289 22.0675,8.7719 23.4915,8.7719 L49.4735,8.7719 C50.8955,8.7719 52.0535,9.9289 52.0535,11.3519 L52.0535,19.6159 C52.0535,21.0389 50.8955,22.1959 49.4735,22.1959 L23.4915,22.1959 C22.0675,22.1959 20.9105,21.0389 20.9105,19.6159 L20.9105,11.3519 Z M51.8065,30.1229 C51.6965,30.2309 51.5085,30.2979 51.3875,30.2859 L21.5405,30.2869 C21.2215,30.2959 20.9525,30.0559 20.9385,29.7389 C20.9235,29.4209 21.1695,29.1509 21.4865,29.1359 L51.4145,29.1369 C51.5685,29.1389 51.7115,29.2009 51.8185,29.3099 C51.9255,29.4209 51.9835,29.5659 51.9815,29.7189 C51.9795,29.8729 51.9165,30.0159 51.8065,30.1229 L51.8065,30.1229 Z M51.8065,38.2529 C51.6965,38.3609 51.5085,38.4299 51.3875,38.4159 L21.5405,38.4159 C21.2215,38.4209 20.9525,38.1849 20.9385,37.8689 C20.9235,37.5519 21.1695,37.2809 21.4865,37.2659 L51.4145,37.2669 C51.5685,37.2689 51.7115,37.3309 51.8185,37.4399 C51.9255,37.5509 51.9835,37.6959 51.9815,37.8499 C51.9795,38.0029 51.9165,38.1459 51.8065,38.2529 L51.8065,38.2529 Z M13.3445,40.8899 C13.3445,42.2859 12.8295,43.5969 11.8155,44.7879 L10.7195,46.0739 L54.5215,46.0739 C57.6655,46.0739 59.6195,44.0879 59.6195,40.8899 L59.6195,1.1529 L13.3445,1.1529 L13.3445,40.8899 Z"
            id="Fill-3"
            fill="#FFFFFF"
          />
          <path
            d="M12.1947,40.89 L12.1947,9.961 L1.1497,9.961 L1.1497,40.89 C1.1497,43.894 3.4717,46.074 6.6727,46.074 L6.9257,46.074 L7.1147,46.051 C10.1067,45.812 12.1947,43.69 12.1947,40.89"
            id="Fill-5"
            fill="#FEDCE3"
          />
          <path
            d="M51.4149,37.2665 L21.4869,37.2665 C21.1689,37.2815 20.9239,37.5515 20.9379,37.8685 C20.9529,38.1845 21.2219,38.4215 21.5409,38.4165 L51.3879,38.4155 C51.5089,38.4305 51.6959,38.3615 51.8069,38.2535 C51.9169,38.1465 51.9789,38.0035 51.9809,37.8495 C51.9829,37.6955 51.9259,37.5505 51.8179,37.4405 C51.7119,37.3305 51.5679,37.2685 51.4149,37.2665"
            id="Fill-10"
            fill="#F9778F"
          />
          <path
            d="M59.62,40.89 C59.62,44.088 57.666,46.074 54.521,46.074 L10.72,46.074 L11.816,44.787 C12.83,43.597 13.344,42.286 13.344,40.89 L13.344,1.153 L59.62,1.153 L59.62,40.89 Z M6.926,46.074 L6.672,46.074 C3.472,46.074 1.149,43.894 1.149,40.89 L1.149,9.961 L12.195,9.961 L12.195,40.89 C12.195,43.69 10.106,45.812 7.115,46.051 L6.926,46.074 Z M60.195,0.004 L12.716,8.8817842e-13 C12.418,0.029 12.194,0.276 12.195,0.575 L12.195,8.811 L0.575,8.811 C0.258,8.812 0,9.069 0,9.386 L0,40.89 C0,44.441 2.931,47.223 6.672,47.223 L54.521,47.223 C58.084,47.223 60.769,44.5 60.769,40.89 L60.769,0.578 C60.769,0.262 60.512,0.004 60.195,0.004 L60.195,0.004 Z"
            id="Fill-12"
            fill="#F9778F"
          />
          <path
            d="M51.4149,29.1366 L21.4859,29.1366 C21.1689,29.1506 20.9239,29.4216 20.9379,29.7386 C20.9529,30.0556 21.2219,30.2966 21.5409,30.2866 L51.3879,30.2856 C51.5089,30.2976 51.6959,30.2316 51.8069,30.1236 C51.9169,30.0156 51.9789,29.8726 51.9809,29.7196 C51.9829,29.5656 51.9259,29.4206 51.8179,29.3106 C51.7119,29.2006 51.5679,29.1386 51.4149,29.1366"
            id="Fill-15"
            fill="#F9778F"
          />
          <path
            d="M22.1361,11.3519 C22.1361,10.6049 22.7441,9.9969 23.4911,9.9969 L49.4731,9.9969 C50.2201,9.9969 50.8281,10.6049 50.8281,11.3519 L50.8281,19.6159 C50.8281,20.3629 50.2201,20.9709 49.4731,20.9709 L23.4911,20.9709 C22.7441,20.9709 22.1361,20.3629 22.1361,19.6159 L22.1361,11.3519 Z M23.4911,22.1959 L49.4731,22.1959 C50.8951,22.1959 52.0531,21.0389 52.0531,19.6159 L52.0531,11.3519 C52.0531,9.9289 50.8951,8.7719 49.4731,8.7719 L23.4911,8.7719 C22.0681,8.7719 20.9101,9.9289 20.9101,11.3519 L20.9101,19.6159 C20.9101,21.0389 22.0681,22.1959 23.4911,22.1959 L23.4911,22.1959 Z"
            id="Fill-17"
            fill="#F9778F"
          />
        </g>
      </g>
    </svg>
  );
};

export default AicnNews;
