import { getUserCoursesV2Query } from "./CoursePaginationQuery";
import client from "apolloClient";
import { singleCourseV2Fragment } from "./CoursesFragments";

export const getUserCoursesV2FromCache = ({
  userId,
  first,
  orderBy,
  orderByDirection,
  courseFilters,
  archivedCourseFilters,
}) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getUserCoursesV2Query,
      variables: {
        userId,
        first,
        orderBy,
        orderByDirection,
        courseFilters,
        archivedCourseFilters,
      },
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getSingleCourseV2FromCache = ({ courseId }) => {
  let singleCourse = {};
  try {
    singleCourse = client.readFragment({
      id: `Course:${courseId}`,
      fragment: singleCourseV2Fragment,
      fragmentName: "singleCourseV2",
    });
    return singleCourse;
  } catch (e) {
    return {};
  }
};

export const writeSingleCourseV2IntoCache = ({ courseId, data }) => {
  try {
    client.writeFragment({
      id: `Course:${courseId}`,
      fragment: singleCourseV2Fragment,
      fragmentName: "singleCourseV2",
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};
