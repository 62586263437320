import React from "react";
import classes from "./ProgressReportCardImage.scss";
import { getProgressReportCardImageStyle } from "ProgressReportCommon/utils";
import { ProgressReportCardIcon } from "SvgComponents";

const ProgressReportCardImage = props => {
  const { width, height, index } = props;

  const imageStyles = getProgressReportCardImageStyle[index % 5];

  return (
    <div
      className={classes.imageContainer}
      style={{ backgroundColor: imageStyles.backgroundColor }}
    >
      <ProgressReportCardIcon
        width={width}
        height={height}
        arrowStrokeColor={imageStyles.arrowStrokeColor}
      />
    </div>
  );
};

ProgressReportCardIcon.defaultProps = {
  height: 100,
  width: 100,
  index: 0,
};

export default ProgressReportCardImage;
