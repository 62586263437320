const getPosts = ({ postsEdges }) => {
  return _.map(postsEdges, item => {
    return item.node;
  });
};

export const getPostsMemoize = _.memoize(
  params => getPosts(params),
  params => JSON.stringify(params)
);
