import React from "react";
import styled, { keyframes } from "styled-components";
import { fontStyle, colors } from "Constants";

const TooltipBody = styled.div`
  display: flex;
  background-color: #fff;
  width: ${props => props.tootTipWidth};
  position: relative;
  padding: 32px;
  flex-direction: column;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Button = styled.div`
  background-color: ${colors.salmon60};
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.white};
  padding: ${props => (props.size == "sm" ? "12px 32px" : "12px 24px")};
  font-weight: ${fontStyle.demiBold.fontWeight};
  font-size: 1.4rem;
  :hover {
    background-color: #e35259;
  }
`;

const ArrowButtonRight = styled.div`
  fill: ${colors.gray48};
  cursor: pointer;
  transform: rotateY(${props => props.rotation});
  width: 12px;
  height: 16px;
  margin-left: 8px;
`;
const ArrowButtonLeft = styled.div`
  fill: ${colors.gray48};
  cursor: pointer;
  transform: rotateY(${props => props.rotation});
  width: 12px;
  height: 16px;
  margin-right: 8px;
`;

const TextButton = styled.div`
  color: ${colors.gray48};
  font-weight: ${fontStyle.demiBold.fontWeight};
  cursor: pointer;
  :hover {
    color: ${colors.gray48};
  }
`;

const TooltipContent = styled.div`
  display: flex;
  color: ${colors.gray48};
  font-weight: ${fontStyle.medium.fontWeight};
  font-size: 1.6rem;
  margin-bottom: 16px;
  white-space: pre-line;
`;

const TooltipTitle = styled.div`
  display: flex;
  color: ${colors.gray13};
  font-weight: ${fontStyle.bold.fontWeight};
  font-size: 2rem;
  margin-bottom: 8px;
`;

const TooltipFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ToolTipCountText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.gray48};
  font-size: 1.2rem;
  font-weight: ${fontStyle.medium.fontWeight};
`;

const TooltipFooterButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  justify-content: flex-end;
  align-items: center;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
   
  }

  55% {
  
    transform: scale(1.4);
  }
`;

const BeaconContainer = styled.span`
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
`;

const BeaconIcon = styled.span`
  animation: ${pulse} 2s ease-in-out infinite;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(247, 89, 97, 0.5) 0%,
    rgba(247, 89, 97, 0) 100%
  );
  display: flex;
  height: 16px;
  width: 16px;
  position: absolute;
`;

const BeaconDot = styled.span`
  display: flex;
  position: "relative";
  height: 8px;
  width: 8px;
  background-color: ${colors.salmon60};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

const Beacon = props => {
  return (
    <BeaconContainer
      {...props}
      ref={ref => (props.updateRef ? props.updateRef(ref) : null)}
    >
      <BeaconDot>
        <BeaconIcon />
      </BeaconDot>
    </BeaconContainer>
  );
};

export {
  TooltipBody,
  Button,
  TooltipContent,
  TooltipTitle,
  TooltipFooter,
  TextButton,
  TooltipFooterButtonContainer,
  ToolTipCountText,
  Beacon,
  ArrowButtonRight,
  ArrowButtonLeft,
};
