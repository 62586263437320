import shortid from "shortid";

export const generateRandomId = () => {
  return `NEW_${shortid.generate()}`;
};

export const generateRandomNumber = (min, max) => {
  try {
    return Number((Math.random() * (min - max) + max).toFixed(2));
  } catch (e) {
    return min;
  }
};
