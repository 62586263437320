import PropTypes from "prop-types";
import React from "react";

const CoachmarkChevronIcon = props => {
  return (
    <svg viewBox="0 0 6 10" width={props.width} height={props.height}>
      <g fill={props.fill && props.fill}>
        <path
          stroke={props.fill && props.fill}
          d="M1.1012414,1.0975 C0.9662529,1.2275 0.9662529,1.43885 1.1012414,1.56885 L4.6644207,5.00035 L1.1012414,8.43115 C0.9662529,8.56115 0.9662529,8.7725 1.1012414,8.9025 C1.23623,9.0325 1.4556903,9.0325 1.5906789,8.9025 L5.3989143,5.2357 C5.4660971,5.17035 5.5,5.085 5.5,5.00035 C5.5,4.915 5.4660971,4.82965 5.3989143,4.7643 L1.5906789,1.0975 C1.4556903,0.9675 1.23623,0.9675 1.1012414,1.0975 Z"
        />
      </g>
    </svg>
  );
};

CoachmarkChevronIcon.defaultProps = {
  height: 10,
  width: 6,
};

CoachmarkChevronIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
};

export default CoachmarkChevronIcon;
