import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import { NAME as commentTemplateReducerName } from "CommentTemplates/modules/CommentTemplatesModule";
import { NAME as progressReportReducerName } from "ProgressReport/modules/ProgressReportModule";
export default store => ({
  path: ":studentProgressReportId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const multiLevelNodeEditorReducer = require("MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule");
            const commentTemplatesReducer = require("CommentTemplates/modules/CommentTemplatesModule");
            const progressReportReducer = require("ProgressReport/modules/ProgressReportModule")
              .default;
            injectReducer(store, {
              key: "progressReport",
              reducer: progressReportReducer,
            });
            injectReducer(store, {
              key: "multiLevelNodeEditor",
              reducer: multiLevelNodeEditorReducer.default,
            });
            injectReducer(store, {
              key: commentTemplateReducerName,
              reducer: commentTemplatesReducer.default,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-studnetProgressreport"
        );
      })
  ),
  childRoutes: [],
});
