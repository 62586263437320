import React, { Fragment, useState } from "react";
import classes from "./SupervisorComment.scss";
import { useI18n } from "Hooks";
import { TextAreaInput } from "@toddle-design/web";
import classNames from "classnames";
import {
  PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
  PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
} from "Projects/Constants/stringConstants";

const getHeadingLabel = ({ projectGroupType, t }) => {
  switch (projectGroupType) {
    case PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION:
    case PROJECT_GROUP_TYPE_DP_TOK_ESSAY:
      return t("project:teacher_s_comment");
    default:
      return t("project:supervisor_s_comment");
  }
};

const SupervisorComment = ({
  assessmentRemarks,
  projectGroupType,
  headingLabel = null,
  updateRemarks,
  disabled = false,
}) => {
  const { t } = useI18n(["project", "common"]);

  const [supervisorComment, setSupervisorComment] = useState(
    assessmentRemarks?.message ?? ""
  );

  const headingClass = classNames({ [classes.heading]: true }, "text-label-2");

  return (
    <Fragment>
      <div className={headingClass}>
        {headingLabel ? headingLabel : getHeadingLabel({ projectGroupType, t })}
      </div>
      <TextAreaInput
        name={"remarks"}
        value={supervisorComment}
        onChange={e => setSupervisorComment(e.target.value)}
        onBlur={() => updateRemarks({ supervisorComment })}
        placeholder={t("common:typeHere")}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default SupervisorComment;
