import React from "react";
import classes from "./VerifyOTP.scss";

import { UIButton, OtpInput } from "UIComponents";

import { colors, fontStyle } from "Constants";
import { ChevronIcon } from "SvgComponents";

const NO_EMAIL_INSTRUCTIONS = [
  "The email might have landed in your spam folder (Get us out of there; we promise we won’t spam you).",
  "The email address you entered had a mistake or typo. (Happens to the best of us.)",
  "We are unable to deliver the email to this address (it can happen because of firewalls or filtering; please speak to the IT team in your school)",
];

const INPUT_LENGTH = 6;
class VerifyOTP extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showNotEmailText: false };
    this.inputRefs = {};
  }

  componentDidMount = () => {
    const { userType, generateOTPForUnauthenticatedUser } = this.props;
    if (userType !== "parent") {
      generateOTPForUnauthenticatedUser();
    }
  };

  onBackClick = () => {
    const { updateCurrentScreen, userType } = this.props;
    userType === "parent"
      ? updateCurrentScreen({ type: "createParentAccount" })
      : updateCurrentScreen({ type: "createAccount" });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  onConfirmClick = () => {
    const {
      verifyUnauthenticatedUserOTP,
      userType,
      verifyParentOTP,
    } = this.props;
    if (userType === "parent") {
      verifyParentOTP();
    } else {
      verifyUnauthenticatedUserOTP();
    }
  };

  onResendClick = () => {
    const {
      userType,
      generateOTPForParent,
      generateOTPForUnauthenticatedUser,
      updateInputField,
    } = this.props;
    updateInputField({ otp: "" });
    if (userType === "parent") {
      generateOTPForParent({ showMessage: true });
    } else {
      generateOTPForUnauthenticatedUser({ showMessage: true });
    }
  };

  onChangeOTP = otp => {
    const { updateInputField } = this.props;
    updateInputField({ otp });
  };

  onClickNotGetEmail = () => {
    this.setState({ showNotEmailText: !this.state.showNotEmailText });
  };

  onEnter = () => {
    const { data: { otp } = {} } = this.props;
    const isPending = _.get(otp, "length", 0) != INPUT_LENGTH;
    if (isPending) {
      return;
    }
    this.onConfirmClick();
  };

  getButtonText = stateCode => {
    const { userType } = this.props;
    if (userType === "parent") {
      return "Verify";
    } else if (stateCode === "SUP_DOM_MTCH") {
      return "Confirm";
    } else {
      return "Verify and Request Access";
    }
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      data: { email, otp, stateCode } = {},
      renderErrorComponent,
      renderBackButtonComponent,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
      renderCreateAccountPrivacyText,
      isLoading,
    } = this.props;
    const { showNotEmailText } = this.state;
    const buttonText = this.getButtonText(stateCode);

    return (
      <div className={classes.container} style={containerStyle}>
        {renderBackButtonComponent({ onClick: this.onBackClick })}
        <div style={headerStyle}> {`Verify your email address`} </div>
        <div style={subTextStyle}>
          {
            <span>
              We just emailed a six-digit code to{" "}
              <span className={classes.emailText}>{email}</span>
              {`. Enter
              the code below to confirm your email address. Make sure to keep
              this window open while you check your inbox. `}
              <span
                className={classes.resendButton}
                onClick={this.onResendClick}
              >
                Resend Code
              </span>
            </span>
          }
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <OtpInput
              inputStyle={{
                width: "100%",
                height: "112px",
                margin: "4px",
                fontSize: "3.2rem",
                borderRadius: 4,
                border: "1px solid",
                borderColor: colors.strokeOne,
                outline: "none",
                ...fontStyle.medium,
              }}
              focusStyle={{
                borderColor: colors.gray48,
                outline: "none",
              }}
              numInputs={INPUT_LENGTH}
              hasErrored={false}
              onChange={this.onChangeOTP}
              separator={<span />}
              isInputNum={false}
              shouldAutoFocus
              value={otp}
              isDisabled={!!isLoading}
              onEnter={this.onEnter}
            />
          </div>
          <div className={classes.bottomContainer}>
            {renderCreateAccountPrivacyText({
              buttonText,
              isRequesting: stateCode != "SUP_DOM_MTCH",
            })}
            <div className={classes.confirmButton}>
              <UIButton
                size="lg"
                type="filled"
                color="pink"
                onClick={this.onConfirmClick}
                isDisabled={_.get(otp, "length", 0) != INPUT_LENGTH}
              >
                {buttonText}
              </UIButton>
            </div>
          </div>
          <div
            className={classes.notGetEmailButtonContainer}
            onClick={this.onClickNotGetEmail}
          >
            <div className={classes.arrowIcon}>
              <ChevronIcon fill={colors.blue29} />
            </div>
            <div
              className={classes.notGetEmailButtonText}
            >{`Didn’t get an email?`}</div>
          </div>
          {!!showNotEmailText && (
            <div className={classes.noEmailText}>
              <div
                className={classes.instructionHeaderText}
              >{`If you don’t receive your six-digit code in your mailbox in the next few minutes, a few things could have happened:`}</div>
              <div className={classes.instructionsContainer}>
                {_.map(NO_EMAIL_INSTRUCTIONS, (text, index) => {
                  return (
                    <div className={classes.instructionContainer} key={index}>
                      <div className={classes.dot}>{`•`}</div>
                      <div className={classes.instructionText}>{text}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default VerifyOTP;
