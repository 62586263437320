import gql from "graphql-tag";
import { attachmentFragment, staffFragment } from "modules/CommonFragments";

export const organizationResourceFragment = {
  organizationResource: gql`
    fragment organizationResourceItem on OrganizationResource {
      id
      label
      attachment {
        ...attachmentItem
      }
      isImported: isImportedFromDiffOrg
      isDeleted
      type
      resourceType
      visibleTo
      sharedCoursesWithUserGroup {
        userType
        courses {
          id
        }
      }
      createdAt
      updatedAt
      studentDriveSharedCourses {
        id
      }
      item {
        ... on OrganizationResource {
          id
          label
          isImported: isImportedFromDiffOrg
          attachment {
            ...attachmentItem
          }
          isDeleted
          type
          resourceType
          visibleTo
        }
        ... on Assessment {
          id
          isImported
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
          image {
            id
            value
          }
          createdBy {
            ...staffItem
          }
          fields(uids: ["measureAssessing"]) {
            id
            uid
            value
            resolvedMinimalTree {
              id
              ... on ResolvedFieldUndefinedEntity {
                undefinedEntity
              }
            }
          }
          grades {
            id
            name
          }
        }
      }
      tags {
        id
        key
        value
      }
    }
    ${attachmentFragment.attachment}
    ${staffFragment.basicStaffDetails}
  `,
  unitFlowResources: gql`
    fragment unitFlowResourcesItem on OrganizationResource {
      id
      resourceType
      type
      label
      isImported: isImportedFromDiffOrg
      children {
        id
        label
        type
        resourceType
        item {
          id
          ... on OrganizationResource {
            id
            label
            type
            resourceType
          }
          ... on Assessment {
            id
          }
        }
      }
      sharedCoursesWithUserGroup {
        userType
        courses {
          id
          title
        }
      }
    }
  `,
  basicDetails: gql`
    fragment organizationResourceBasicDetailsItem on OrganizationResource {
      id
      label
      attachment {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
  `,
};

export const organizationChildResourceFragment = {
  childResourceFragment: gql`
    fragment organizationChildResourceItem on OrganizationResource {
      id
      label
      children(
        visibleTo: $visibleTo
        searchText: $searchText
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: $filters
      ) {
        ...organizationResourceItem
      }
      isDeleted
      visibleTo
      resourceType
      parentHierarchy {
        id
        label
      }
      sharedCoursesWithUserGroup {
        userType
        courses {
          id
          title
        }
      }
      tags {
        key
        id
        value
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
  childResourceFragmentV2: gql`
    fragment organizationChildResourceItem on OrganizationResource {
      id
      label
      children: childrenV2(
        first: $first
        after: $after
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        filters: $filters
      ) {
        resourceTypeBasedCount {
          folderCount
          fileCount
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...organizationResourceItem
          }
        }
      }
      isDeleted
      visibleTo
      resourceType
      parentHierarchy {
        id
        label
      }
      sharedCoursesWithUserGroup {
        userType
        courses {
          id
          title
        }
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
};

export const organizationResourceFeedFragment = {
  organizationResourceFeed: gql`
    fragment organizationResourceFeedItem on Organization {
      id
      organizationResources(
        types: $types
        visibleTo: $visibleTo
        searchText: $searchText
        filters: $organizationResourceFilter
      ) {
        ...organizationResourceItem
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
  unitPlanResourceFeed: gql`
    fragment unitPlanResourceFeedItem on UnitPlan {
      id
      organizationResources(searchText: $searchText) {
        ...organizationResourceItem
      }
      unitFlowResources {
        ...unitFlowResourcesItem
      }
    }
    ${organizationResourceFragment.organizationResource}
    ${organizationResourceFragment.unitFlowResources}
  `,
  assessmentResourceFeed: gql`
    fragment assessmentResourceFeedItem on Assessment {
      id
      organizationResources {
        ...organizationResourceItem
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
  studentDriveResourceFeed: gql`
    fragment studentDriveResourceFeedItem on Course {
      id
      organizationResources: studentDriveV2(
        filters: {
          types: $types
          visibleTo: $visibleTo
          searchText: $searchText
          showOnlyRecentlyShared: $showOnlyRecentlyShared
          searchParentResourceId: $searchParentResourceId
          searchParentResourceType: $searchParentResourceType
        }
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        first: $first
        after: $after
      ) {
        resourceTypeBasedCount {
          folderCount
          fileCount
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...organizationResourceItem
          }
        }
      }
    }
    ${organizationResourceFragment.organizationResource}
  `,
};
