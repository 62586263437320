import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./OrganizationManagement.scss";
import { withPermission } from "lib/PermissionAware";
import { FeedList } from "AppComponents";
import { getAllOrganizationQuery } from "../../modules/ToolingQueries";

import {
  changeOrganizationSearchString,
  createOrganizationPreset,
  updateOrganizationPreset,
  deleteOrganizationPreset,
  onClickNavigate,
} from "Tooling/modules/ToolingModule";
import { ChevronIcon } from "SvgComponents";
import { checkScrollAtBottom } from "services/checkScrollAtBottom";
import {
  ProgressSteps,
  withLoader,
  DialogueBox,
  SearchBar,
  UIModal,
  UIButton,
  Loading,
} from "UIComponents";

import OrganizationEditModal from "./OrganizationEditModal";

import OrganizationCreateModal from "./OrganizationCreateModal";

const addOrgButton = {
  borderRadius: 0,
  margin: "24px 16px",
};

const parentStyle = {
  gridTemplateColumns: "25% 20% 15% 15% 10% 5%",
  gridColumnGap: "16px",
};

[
  {
    id: "192",
    name: "Toddle International School",
    domains: ["toddleapp.com", "lifeofpie.com"],
    city: "Hogwarts",
    country: "Winterfell",
    ibCode: "51037",
  },
];

const columnList = [
  {
    label: "Name",
    id: "name",
    type: "TEXTCOLUMN",
    style: { justifySelf: "start" },
  },
  {
    label: "Domains",
    id: "domains",
    type: "TEXTCOLUMN",
    style: { justifySelf: "start" },
  },

  {
    label: "City",
    id: "city",
    type: "TEXTCOLUMN",
    style: { justifySelf: "start" },
  },
  {
    label: "Country",
    id: "country",
    type: "TEXTCOLUMN",
    style: { justifyContent: "start" },
  },
  {
    label: "IB Code",
    id: "ibCode",
    type: "TEXTCOLUMN",
    style: { justifyContent: "start" },
  },
  {
    label: "Actions",
    id: "action",
    type: "ACTIONS",
  },
];

class OrganizationManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      orgData: null,
      searchText: "",
      deletingOrg: false,
    };
  }

  getFeedCustomComponent = ({ column, columnData, id }) => {
    // const { status } = columnData;
    // switch (status) {
    //   case "REQUESTED":
    //     return this.renderActionButton({ columnData });
    //   default:
    //     return this.renderActionMenu({ columnData });
    // }

    return null;
  };

  deleteOrganization = async () => {
    const { orgData } = this.state;

    try {
      this.props.deleteOrganizationPreset({ id: orgData.id });
    } catch (err) {}
    this.setState({ deletingOrg: false });
  };

  getFeedData = () => {
    const { searchText } = this.state;
    const { organizations } = this.props;
    const filteredList = organizations;

    const feedData = _.map(filteredList, (organization, index) => {
      const id = organization.id + "-" + index;
      return {
        ...organization,
        id,
        domains: (organization.domains || []).join(", "),
        action: [
          {
            label: "Edit",
            clickAction: () =>
              this.setState({ showModal: true, orgData: organization }),
            svg: null,
            isDisable: false,
          },
          {
            label: "Delete",
            clickAction: () =>
              this.setState({ deletingOrg: true, orgData: organization }),
            svg: null,
            isDisable: false,
          },
        ],
      };
    });
    return feedData;
  };

  onClose = () => {
    this.setState({ showModal: false });
  };

  createOrganization = async data => {
    this.setState({ showAddModal: false });
    try {
      await this.props.createOrganizationPreset(data);

      //show success toast
    } catch (err) {
      console.error(err);
      this.setState({ showAddModal: true, orgData: data });
    }
  };

  editOrganization = async data => {
    this.setState({ showModal: false });
    try {
      await this.props.updateOrganizationPreset(data);

      //show success toast
    } catch (err) {
      console.error(err);
      this.setState({ showModal: true, orgData: data });
    }
  };

  renderEditModal() {
    const { showModal, orgData } = this.state;
    return showModal ? (
      <OrganizationEditModal
        onSaveClick={this.editOrganization}
        onClose={this.onClose}
        data={orgData}
        onUpdateInputField={this.onUpdateInputField}
      />
    ) : null;
  }

  renderAddModal() {
    const { showModal, showAddModal, orgData } = this.state;
    return showAddModal ? (
      <OrganizationCreateModal
        onSaveClick={this.createOrganization}
        onClose={() => this.setState({ showAddModal: false })}
        // data={orgData}
        // onUpdateInputField={this.onUpdateInputField}
      />
    ) : null;
  }

  onUpdateInputField = ({ id, data }) => {
    this.setState(prevState => {
      return { orgData: { ...prevState.orgData, [id]: data } };
    });
  };

  changeSearchTerm = value => {
    this.props.changeOrganizationSearchString({ orgnizationManagement: value });
  };

  onAddOrganizationClick = () => {
    this.setState({ showAddModal: true });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll, true);
  };

  handleScroll = e => {
    const containerDiv = e.target;
    const scrollRef = this.scrollContainer;
    if (this.props.organizations && containerDiv && containerDiv == scrollRef) {
      const hasNextPage = this.props.hasNextPage;
      if (
        hasNextPage &&
        this.props.getAllOrganization.networkStatus != 3 &&
        checkScrollAtBottom(containerDiv)
      ) {
        this.props.loadMoreOrganizations();
      }
    }
  };

  toggleDeleteDialogueBoxDisplay = () => {
    this.setState({ deletingOrg: false });
  };

  renderDeleteDialog = () => {
    return this.state.deletingOrg ? (
      <DialogueBox
        modalTitle="Delete School"
        showModal={true}
        onClickButton2={this.deleteOrganization}
        modalBody="Are you sure you want to delete the School? This action can't be undone."
        toggleDialogueBoxDisplay={this.toggleDeleteDialogueBoxDisplay}
        button1="Cancel"
        button2="Delete"
      />
    ) : null;
  };

  onBackClicked = () => {
    this.props.onClickNavigate({
      type: "replace",
      link: "organizationManagement",
    });
  };

  render() {
    const { organizations, organizationSearchString, loading } = this.props;
    return (
      <div
        ref={ref => (this.scrollContainer = ref)}
        className={classes.container}
      >
        <div className={classes.headerContainer}>
          <div
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            onClick={this.onBackClicked}
          >
            <div className={classes.backContainer}>
              <ChevronIcon />
            </div>
            <div className={classes.header}>{"School Preset"}</div>
          </div>
          <UIButton
            color={"pink"}
            type={"hollow"}
            state={"normal"}
            onClick={this.onAddOrganizationClick}
          >{`Add School`}</UIButton>
        </div>
        <div className={classes.searchContainer}>
          <SearchBar
            placeholder={"Search by school name"}
            searchTerm={organizationSearchString}
            changeSearchTerm={this.changeSearchTerm}
            shouldAnimate={false}
            wrapperType={"box"}
          />
        </div>

        <FeedList
          parentStyle={parentStyle}
          columnList={columnList}
          getCustomComponent={this.getFeedCustomComponent}
          feedData={this.getFeedData()}
          fetchMoreFeed={this.fetchMoreFeed}
        />
        {this.props.getAllOrganization.networkStatus == 3 && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}

        {this.renderEditModal()}
        {this.renderAddModal()}
        {this.renderDeleteDialog()}

        {loading ? (
          <div className={classes.loadingContainerFull}>
            <Loading />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapActionCreators = {
  changeOrganizationSearchString,
  createOrganizationPreset,
  updateOrganizationPreset,
  deleteOrganizationPreset,
  onClickNavigate,
};

const mapStateToProps = (state, ownProps) => {
  const organizationId = state.login.userInfo.org_id;
  const loading = state.tooling.isLoading.orgnizationManagement;
  return {
    // isLoading: true,
    // isData: false,
    perm: "ToolPortal:ManageOrganization",
    organizationSearchString: state.tooling.searchString.orgnizationManagement,
    organizationId,
    loading,
    // organizations: [
    //   {
    //     id: "192",
    //     name: "Toddle International School",
    //     domains: [{ title: "toddleapp.com" }, { title: "lifeofpie.com" }],
    //     city: "Hogwarts",
    //     country: "Winterfell",
    //     ibCode: "51037"
    //   }
    // ]
  };
};

// export default Organization;
export default compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getAllOrganizationQuery, {
    name: "getAllOrganization",
    options: ({ organizationSearchString }) => ({
      fetchPolicy: "cache-and-network",
      variables: { searchText: organizationSearchString },
    }),
    props({
      getAllOrganization,
      ownProps: { organizationSearchString, organizationId },
    }) {
      const organizations = _.map(
        _.get(
          getAllOrganization,
          "organizationManagement.organizationPresetFeed.edges",
          []
        ),
        edge => edge.node
      );
      return {
        // organizationData: _.reduce(
        //   _.get(getAllOrganization, "platform.allOrganization", []),
        //   (result, org) => {
        //     result[org.id] = org;
        //     return result;
        //   },
        //   {}
        // ),

        organizations,
        hasNextPage: _.get(
          getAllOrganization,
          "organizationManagement.organizationPresetFeed.pageInfo.hasNextPage",
          []
        ),
        getAllOrganization,
        isData: getAllOrganization.organizationManagement,
        isLoading:
          getAllOrganization["networkStatus"] == 1 ||
          getAllOrganization["networkStatus"] == 2,
        loadMoreOrganizations: () => {
          // console.log("LOAD MOREE");

          return getAllOrganization.fetchMore({
            query: getAllOrganizationQuery,
            variables: {
              after:
                getAllOrganization.organizationManagement.organizationPresetFeed
                  .pageInfo.endCursor,
              searchText: organizationSearchString,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              // console.log("previousResult:::", previousResult);
              // console.log("fetchMoreResult:::", fetchMoreResult);
              const previousStudents =
                previousResult.organizationManagement.organizationPresetFeed;
              const nextStudents =
                fetchMoreResult.organizationManagement.organizationPresetFeed;

              const previousEdges = previousStudents.edges;
              const nextEdges = nextStudents.edges;

              const previousPageInfo = previousStudents.pageInfo;
              const nextPageInfo = nextStudents.pageInfo;

              const nextEndCursor = nextPageInfo.endCursor;
              const nextHasNextPage = nextPageInfo.hasNextPage;
              return {
                ...previousResult,
                organizationManagement: {
                  ...previousResult.organizationManagement,
                  organizationPresetFeed: {
                    ...previousStudents,
                    edges: [...previousEdges, ...nextEdges],
                    pageInfo: {
                      ...previousPageInfo,
                      endCursor: nextEndCursor,
                      hasNextPage: nextHasNextPage,
                    },
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withLoader,
  withPermission
)(OrganizationManagement);
