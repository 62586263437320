import gql from "graphql-tag";

export const studentReportFragments = {
  reportFeed: gql`
    fragment studentReportItem on PublishedStudentProgressReport {
      id
      filteredNodes
      studentProgressReport {
        id
        updatedAt
      }
      curriculumProgram {
        id
        type
      }
    }
  `,
};
