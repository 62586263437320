import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";

export default store => ({
  path: "product-updates",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            const productUpdateModule = require("./modules/ProductUpdatesModules");
            injectReducer(store, {
              key: productUpdateModule.NAME,
              reducer: productUpdateModule.default,
            });

            cb(component);
          },
          err => {
            reject(err);
          },
          "product-updates"
        );
      })
  ),
});
