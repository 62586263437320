import React, { PureComponent } from "react";
import classes from "./ResponseItem.scss";
import classNames from "classnames";
import DropDown from "../DropDown";
import { getTimeSince, getFormattedEvidence } from "Utils";
import Textarea from "react-textarea-autosize";
import { AttachmentList, CreateDropDown } from "Attachment";
import { ProfileCascade, I18nHOC, Textview } from "UIComponents";
import {
  DeleteOutlined,
  PencilLineOutlined,
  AddCircleOutlined,
} from "@toddle-design/web-icons";
import { Button } from "@toddle-design/web";

const styles = {
  buttonDropDownContainer: {
    top: 40,
    left: 0,
  },
  customProfileCascade: {
    borderWidth: 0,
    width: 40,
    height: 40,
  },
};

class ResponseItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showDropdown: false };
  }

  updateInputField = ({ attachments, formattedEvidence }) => {
    const { onDeleteEvidenceClick } = this.props;

    const attachment = _.first(
      _.differenceBy(formattedEvidence, attachments, "id")
    );

    onDeleteEvidenceClick({
      id: attachment.id,
    });
  };

  getFormattedEvidence = () => {
    const { isActive, response, localResponseObj } = this.props;
    const { evidence } = isActive ? localResponseObj : response;

    return getFormattedEvidence({ evidence });
  };

  isDropDownEnabled = () => {
    const { mode, userInfo, response, lockByAuthor } = this.props;
    const { id: userId, user_type: userType } = userInfo;
    const {
      id: responseId,
      createdBy: { id: creatorId, type: creatorUserType },
    } = response;

    if (mode != "view") {
      if (lockByAuthor) {
        if (_.isEqual(userId, creatorId)) {
          return !_.includes(responseId, "NEW_");
        } else if (
          _.isEqual(userType, "staff") &&
          _.isEqual(creatorUserType, "student")
        ) {
          return !_.includes(responseId, "NEW_");
        }
      } else {
        return !_.includes(responseId, "NEW_");
      }
    }

    return false;
  };

  render() {
    const {
      t,
      subText,
      isActive,
      response,
      onClickEdit,
      showEvidence,
      onMediaClick,
      onCancelEdit,
      onClickDelete,
      dropDownOptions,
      localResponseObj,
      onResponseChange,
      onContentUploaded,
      onSaveEditClicked,
      createDropDownRef,
      responseItemContainerStyle,
      onDropDownOptionClick,
      attachmentListContainerStyle,
      showHeaderIcons,
    } = this.props;
    const { showDropDown } = this.state;

    const {
      id: responseId,
      createdBy: { id: creatorId, firstName, profileImage, lastName },
      createdAt,
    } = response;
    const enableDropDown = this.isDropDownEnabled();
    const formattedEvidence = this.getFormattedEvidence();
    const message = isActive ? localResponseObj.message : response.message;

    const moreContainerStyle = classNames(
      { [classes.moreContainer]: true },
      { [classes.visible]: showDropDown },
      { [classes.hidden]: !enableDropDown }
    );

    const containerStyle = classNames(
      { [classes.container]: true },
      { [classes.editContainer]: isActive }
    );
    const textAreaStyle = classNames({
      [classes.messageText]: true,
      [classes.editMessageText]: isActive,
    });

    const buttonsContainerClass = classNames(
      { [classes.buttonsContainer]: showEvidence },
      { [classes.buttonsContainerWithoutEvidence]: !showEvidence }
    );

    return (
      <div className={containerStyle} style={responseItemContainerStyle}>
        <div className={classes.headerContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.userInfo}>
              <ProfileCascade
                items={[
                  { id: creatorId, value: firstName, imageUrl: profileImage },
                ]}
                customStyle={styles.customProfileCascade}
              />
              <div className={classes.nameClassContainer}>
                <div
                  className={classes.nameText}
                >{`${firstName} ${lastName}`}</div>
                <div className={classes.subtext}>
                  {subText && <span>{subText}</span>}
                  {subText && <span>&nbsp;{"|"}&nbsp;</span>}
                  <span>{getTimeSince(new Date(createdAt), t)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.rightContainer}>
            <div className={moreContainerStyle}>
              {showHeaderIcons ? ( // Display edit and delete icons on hover on right side of card. If showHeader icon is false then a button dropdown is displayed.
                <div className={classes.iconContainer}>
                  <DeleteOutlined
                    className={classes.headerIcon}
                    onClick={() => onClickDelete({ id: responseId })}
                  />
                  <PencilLineOutlined
                    className={classes.headerIcon}
                    onClick={() => onClickEdit(response)}
                  />
                </div>
              ) : (
                <DropDown
                  isActive={isActive}
                  onClickEdit={() => onClickEdit(response)}
                  onClickDelete={() => onClickDelete({ id: responseId })}
                  onUpdateShowDropDown={value =>
                    this.setState({ showDropDown: value })
                  }
                />
              )}
            </div>
          </div>
        </div>
        {isActive ? (
          <Textarea
            className={textAreaStyle}
            style={{
              pointerEvents: isActive ? "auto" : "none",
            }}
            value={message}
            name={t("common:response")}
            onChange={e => onResponseChange(e.target.value)}
          />
        ) : message ? (
          <Textview
            mode={"view"}
            showViewEmpty={false}
            value={message}
            isWordWrapRequired={true}
          />
        ) : null}

        <div className={classes.evidenceFeed}>
          <AttachmentList
            value={formattedEvidence}
            showCreateDropdown={false}
            containerStyle={attachmentListContainerStyle}
            onClickMedia={onMediaClick}
            cardType={"horizontal"}
            updateInputField={params =>
              this.updateInputField({
                attachments: params,
                formattedEvidence,
              })
            }
            mode={isActive ? "edit" : "view"}
          />
        </div>
        {isActive && (
          <div className={buttonsContainerClass}>
            {showEvidence && (
              <CreateDropDown
                exportAsPdf={true}
                parentId={responseId}
                customRef={createDropDownRef}
                dropDownOptions={dropDownOptions}
                buttonDropDownContainerStyle={styles.buttonDropDownContainer}
                buttonComponent={
                  <Button
                    variant={"inline-progressive"}
                    icon={<AddCircleOutlined />}
                    size={"small"}
                  >
                    {t("common:add_with_label", {
                      label: t("common:evidence"),
                    })}
                  </Button>
                }
                parentType={"PLANNER_FIELD_RESPONSE"}
                addAttachment={onContentUploaded}
                onDropDownOptionClick={onDropDownOptionClick}
              />
            )}

            <div className={classes.rightButtons}>
              <div className={classes.cancelButton}>
                <Button
                  variant={"inline"}
                  onClick={onCancelEdit}
                  size={"small"}
                >
                  {t("common:cancel")}
                </Button>
              </div>
              <Button variant={"progressive"} onClick={onSaveEditClicked}>
                {t("common:save_changes")}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(ResponseItem);

ResponseItem.defaultProps = {
  showEvidence: true,
  responseItemContainerStyle: {},
  showHeaderIcons: false,
};
