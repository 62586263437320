import React, { useState, useMemo } from "react";
import classes from "./FilterDropdown.scss";
import {
  CheckListContainer,
  ButtonDropdown,
  I18nHOC,
  MoreItemWithToolTip,
  withLoader,
} from "UIComponents";
import { colors } from "Constants";
import PropTypes from "prop-types";
import { SearchBarContainer } from "AppComponents";
import { compose } from "react-apollo";
import { localSearch } from "Utils";
import { CaretDownFilled } from "@toddle-design/web-icons";

const moreItemWIthToolTipStyle = {
  moreStyle: {
    color: colors.gray31,
  },
};

const styles = {
  buttonDropDownContainerStyle: {
    top: 64,
    right: 0,
    width: "max-content",
    minWidth: "200px",
    maxHeight: 285,
    overflowY: "auto",
  },
  checkListContainerStyle: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    flexWrap: "unset",
  },
  checklistItemStyle: {
    flexShrink: "unset",
  },
  CheckBoxLabelStyles: {
    wordBreak: "break-word",
  },
};

const OptionsCheckList = React.memo(props => {
  const {
    value,
    options,
    name,
    showAllOption,
    dropDownEmptyText,
    allOptionLabel,
    updateInputField,
    showSearchBar,
    searchBarProps,
    checkListItemStyle,
    checkboxItemStyle,
    checkBoxParentContainer,
    checkListContainerStyle,
    isMatchingTextEnabled,
    isSearchTextInState,
    isSearchBasedOnQuery,
    CheckBoxLabelStyles,
    noResultText,
    t,
  } = props;
  const [searchText, setSearchText] = useState("");
  const optionToDisplay = useMemo(() => {
    const filteredList = _.filter(options, item => {
      const label = item.label;
      const index = localSearch({ text: label, searchText });

      return index > -1 || _.isEmpty(searchText);
    });

    return _.map(filteredList, item => {
      const { isArchived, label } = item;
      const updatedLabel = isArchived
        ? `${label} (${t("common:archived")})`
        : label;
      return { ...item, label: updatedLabel };
    });
  }, [options, searchText]);

  return (
    <>
      {showSearchBar && (
        <div
          className={classes.searchBarWrapper}
          style={{ ..._.get(searchBarProps, "searchBarWrapper", {}) }}
        >
          <SearchBarContainer
            changeSearchTerm={setSearchText}
            searchTerm={searchText}
            {...searchBarProps}
          />
        </div>
      )}
      {/**
      here if you are using just search or search based on query and if your search text didn't match any option then this
      will show no result found.
      */}
      {(showSearchBar || isSearchBasedOnQuery) &&
      (!_.isEmpty(searchText) || !_.isEmpty(props.searchText)) &&
      _.isEmpty(optionToDisplay) ? (
        <div
          className={classes.noResultText}
          style={_.get(searchBarProps, "noResultText", {})}
        >
          {noResultText ? noResultText : t("common:no_result_found")}
        </div>
      ) : (
        <CheckListContainer
          updateInputField={updateInputField}
          value={value}
          options={optionToDisplay}
          name={name}
          showAllOption={
            _.isEmpty(searchText) && _.isEmpty(props.searchText)
              ? showAllOption
              : false
          }
          checkListContainerStyle={{
            ...styles.checkListContainerStyle,
            ...checkListContainerStyle,
          }}
          emptyText={dropDownEmptyText}
          emptyContainerStyle={{ height: "40px" }}
          listItemStyle={{
            ...styles.checklistItemStyle,
            ...checkListItemStyle,
          }}
          allOptionLabel={allOptionLabel}
          checkboxItemStyle={checkboxItemStyle}
          checkBoxParentContainer={checkBoxParentContainer}
          CheckBoxLabelStyles={{
            ...styles.CheckBoxLabelStyles,
            ...CheckBoxLabelStyles,
          }}
          isMatchingTextEnabled={isMatchingTextEnabled}
          searchText={searchText}
        />
      )}
    </>
  );
});

OptionsCheckList.displayName = "OptionsCheckList";

const SearchBasedOnQueryOptionsCheckList = props => {
  /*
  Here in props you can see that there is prop called "graphqlQueryEnhancer".
  So
  - what is it?
  -> graphqlQueryEnhancer = graphql(query,queryParams)

  - why we have to pass graphqlQueryEnhancer instead of using graphql HOC ?
  -> Because we have our own query and it has different types of vars and form of vars
  e.g. queryVariables_1={ searchText, id}, queryVariables_2={ id,filters:{searchText}} }
  So there is no standardized form for vars. and for the query output we also have same problem
  e.g. queryResponse_1 = {
    node:{
      id,
      myList:[{...},{...}]
    }
  }
  queryResponse_2 = {
    node:{
      id,
      edges:[
        {
          node:{
            id,
            someInfo
          }
        },
        {...}
      ]
    }
  }
  so here you can see the problem.

  - How will I access searchText ?
  -> Don't worry we wil give you searchText in prop.
  e.g. graphql(query,{
    options:({searchText})=>{
      variables:{
        searchText
      }
    }
  })

  - What do I have to return ?
  -> You have to return options array with { value , label } form
  e.g.  options = [ { value:1 ,label:"PYP" } , { value:2 ,label:"MYP" },{ value:3 ,label:"UBD" }]

  you can see an example for graphqlQueryEnhancer at : src\routes\routes\Teacher\routes\Projects\modules\EnhanceQueries\getProjectGroupSupervisorsGraphql.js
  */

  const { searchBarProps, graphqlQueryEnhancer } = props;

  const [searchText, setSearchText] = useState("");
  const Component = useMemo(
    () => compose(graphqlQueryEnhancer, withLoader)(OptionsCheckList),
    []
  );
  const newProps = {
    ...props,
    showSearchBar: false,
    isData: true,
    isLoading: false,
    searchText,
  };
  return (
    <>
      <div
        className={classes.searchBarWrapper}
        style={{ ..._.get(searchBarProps, "searchBarWrapper", {}) }}
      >
        <SearchBarContainer
          changeSearchTerm={setSearchText}
          searchTerm={searchText}
          {...searchBarProps}
        />
      </div>
      <div className={classes.checkListContainerWithQuery}>
        <Component {...newProps} />
      </div>
    </>
  );
};

SearchBasedOnQueryOptionsCheckList.displayName =
  " SearchBasedOnQueryOptionsCheckList";

const ButtonComponent = React.memo(props => {
  const {
    nameTextStyle,
    emptyTextStyle,
    value,
    options,
    label,
    labelElement,
    t,
    filterBoxStyle,
    filterTextStyle,
    arrowContainerStyle,
    allOptionLabel,
    noOptionLabel,
    showAllOption,
    hideLabel,
    hideValue,
    tooltipStyles = {},
  } = props;

  const optionNames = _.map(
    _.filter(options, item => _.includes(value, item.value)),
    option => option.label
  );

  const noItemText = noOptionLabel
    ? noOptionLabel
    : t("common:no_with_label", {
        label: t(label),
      });

  const textComp = (
    <MoreItemWithToolTip
      items={optionNames}
      length={1}
      moreJoinSymbol={", "}
      trigger={["hover"]}
      noItemText={noItemText}
      {...moreItemWIthToolTipStyle}
      {...tooltipStyles}
    />
  );

  const isAll =
    _.get(value, "length", 0) > 0 &&
    _.get(options, "length", 0) == _.get(value, "length", 0) &&
    showAllOption;

  const renderLabel = hideLabel ? null : (
    <div className={classes.filterText} style={filterTextStyle}>
      {labelElement ? labelElement : label ? `${t(label)}:` : ``}
      <div
        className={classes.selectedOptionsLabels}
        style={_.isEmpty(value) ? emptyTextStyle : nameTextStyle}
      >
        {hideValue ? "" : isAll ? allOptionLabel : textComp}
      </div>
    </div>
  );
  return (
    <div className={classes.filterBox} style={filterBoxStyle}>
      {renderLabel}
      <div className={classes.bottomArrow} style={arrowContainerStyle}>
        <CaretDownFilled variant="subtle" />
      </div>
    </div>
  );
});

ButtonComponent.displayName = "ButtonComponent";

const FilterDropDown = React.memo(props => {
  const {
    buttonParentStyle,
    parentContainerStyle,
    isCheckList,
    // use this props if you are using checkList and you want search in it. And your search is implemented in backend
    //then you have to pass graphqlQueryEnhancer for more check OptionCheckListV2
    isSearchBasedOnQuery,
    options,
    updateInputField,
    name,
    showAllOption,
    allOptionLabel,
    buttonComponent,
    buttonDropDownContainerStyle,
    isDisabled,
    shouldMountDropDownOnButtonClick,
    emptyOptionsDropdownText,
  } = props;

  const onItemClick = selectedVal => {
    if (updateInputField) {
      if (name) {
        updateInputField({ [name]: [selectedVal] });
      } else {
        updateInputField([selectedVal]);
      }
    }
  };

  let updatedOptions = options;
  if (!isCheckList && showAllOption) {
    updatedOptions = [
      { label: allOptionLabel, value: "ALL" },
      ...(options || []),
    ];
  }
  return (
    <ButtonDropdown
      buttonParentStyle={buttonParentStyle}
      parentContainerStyle={parentContainerStyle}
      authTabs={!isCheckList ? updatedOptions : []}
      dropdownComponent={
        _.size(updatedOptions) === 0 && emptyOptionsDropdownText ? (
          <>{emptyOptionsDropdownText}</>
        ) : isCheckList ? (
          isSearchBasedOnQuery ? (
            <SearchBasedOnQueryOptionsCheckList {...props} />
          ) : (
            <OptionsCheckList {...props} />
          )
        ) : null
      }
      containerStyle={{
        ...styles.buttonDropDownContainerStyle,
        ...(buttonDropDownContainerStyle || {}),
      }}
      buttonComponent={
        buttonComponent ? (
          buttonComponent
        ) : (
          <ButtonComponent {...props} options={updatedOptions} />
        )
      }
      shouldCloseOnSelfClick={!isCheckList}
      onItemClick={onItemClick}
      disabled={isDisabled}
      shouldMountDropDownOnButtonClick={shouldMountDropDownOnButtonClick}
    />
  );
});

const FilterDropDownWrapper = props => {
  const { t, allOptionLabel } = props;
  return (
    <FilterDropDown
      {...props}
      allOptionLabel={allOptionLabel ? allOptionLabel : t("common:all")}
    />
  );
};

FilterDropDownWrapper.defaultProps = {
  filterBoxStyle: {},
  filterTextStyle: {},
  valueTextStyle: {},
  buttonDropDownContainerStyle: {},
  showAllOption: true,
  emptyTextStyle: {},
  dropDownEmptyText: "",
  name: "",
  label: "",
  isCheckList: true,
  // allOptionLabel: "",
  labelElement: null,
  showSearchBar: false,
  isDisabled: false,
  isSearchBasedOnQuery: false,
  hideLabel: false,
  shouldMountDropDownOnButtonClick: false,
  checkboxItemStyle: {},
  emptyOptionsDropdownText: "", // if the options props size is 0 and we want to show some text e.g No Grades Available
  checkListItemStyle: {},
  checkListTitleElement: null,
};

FilterDropDownWrapper.propTypes = {
  label: PropTypes.string,
  isCheckList: PropTypes.bool,
  labelElement: PropTypes.element,
  showSearchBar: PropTypes.bool,
  searchBarProps: PropTypes.object,
  allOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default I18nHOC({ resource: ["common"] })(FilterDropDownWrapper);
