import PropTypes from "prop-types";
import React from "react";

const WhoWeAre = props => {
  const cls1 = { fill: "#dbdbdb" };

  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 250 250">
      <title>who we are active copy</title>
      <path
        style={cls1}
        d="M178.19,86.28a1,1,0,0,1,.73.3l2.91,2.91a1,1,0,0,1,0,1.46l-2.91,2.91a1,1,0,0,1-1.46,0L174.55,91a1,1,0,0,1,0-1.46l2.91-2.91a1,1,0,0,1,.73-.3m0-1a2.05,2.05,0,0,0-1.46.6l-2.91,2.91a2.06,2.06,0,0,0,0,2.91l2.91,2.91a2.06,2.06,0,0,0,2.91,0l2.91-2.91a2.06,2.06,0,0,0,0-2.91l-2.91-2.91a2.05,2.05,0,0,0-1.46-.6Z"
      />
      <circle style={cls1} cx="162.01" cy="113.87" r="3.09" />
      <path
        style={cls1}
        d="M207.4,105.27a1,1,0,1,1-1,1,1,1,0,0,1,1-1m0-1a2.06,2.06,0,1,0,2.06,2.06,2.06,2.06,0,0,0-2.06-2.06Z"
      />
      <path
        style={cls1}
        d="M36.82,103.21a1,1,0,1,1-1,1,1,1,0,0,1,1-1m0-1a2.06,2.06,0,1,0,2.06,2.06,2.06,2.06,0,0,0-2.06-2.06Z"
      />
      <path
        style={cls1}
        d="M81.13,122.14a1,1,0,1,1-1,1,1,1,0,0,1,1-1m0-1a2.06,2.06,0,1,0,2.06,2.06,2.06,2.06,0,0,0-2.06-2.06Z"
      />
      <path
        style={cls1}
        d="M70.15,84.19a1,1,0,0,1,.79.38,1,1,0,0,1,.22.84l-1.24,7.06a1,1,0,0,1-1,.85,1,1,0,0,1-.65-.24l-5.49-4.61a1,1,0,0,1,.31-1.76l6.73-2.45a1,1,0,0,1,.36-.06m0-1a2.07,2.07,0,0,0-.71.13l-6.73,2.45a2.06,2.06,0,0,0-.62,3.51l5.49,4.61a2,2,0,0,0,1.32.48,2.06,2.06,0,0,0,2-1.7l1.24-7.06a2.06,2.06,0,0,0-2-2.42Z"
      />
      <path
        style={cls1}
        d="M120.54,48.79a.82.82,0,0,1,.61.29.77.77,0,0,1,.17.65l-.73,4.14a.8.8,0,0,1-.8.67.77.77,0,0,1-.51-.19l-3.22-2.7a.8.8,0,0,1,.24-1.37l3.95-1.44a.82.82,0,0,1,.28-.05m0-1a1.84,1.84,0,0,0-.63.11L116,49.31a1.83,1.83,0,0,0-.55,3.13l3.22,2.7a1.81,1.81,0,0,0,1.17.43,1.83,1.83,0,0,0,1.81-1.52l.73-4.14a1.84,1.84,0,0,0-1.8-2.15Z"
      />
      <rect
        style={cls1}
        x="80.49"
        y="62.03"
        width="4.37"
        height="1.03"
        transform="translate(-20.01 76.78) rotate(-45)"
      />
      <rect
        style={cls1}
        x="82.16"
        y="60.36"
        width="1.03"
        height="4.37"
        transform="translate(-20.01 76.78) rotate(-45)"
      />
      <rect
        style={cls1}
        x="159.82"
        y="62.03"
        width="4.37"
        height="1.03"
        transform="translate(3.22 132.88) rotate(-45)"
      />
      <rect
        style={cls1}
        x="161.49"
        y="60.36"
        width="1.03"
        height="4.37"
        transform="translate(3.22 132.88) rotate(-45)"
      />
      <path
        style={cls1}
        d="M92.51,201.92v-42a34.75,34.75,0,0,1,34.75-34.75h0A34.75,34.75,0,0,1,162,159.9v42H92.51"
      />
      <circle style={cls1} cx="127.26" cy="96.9" r="20.59" />
      <path
        style={cls1}
        d="M54.62,153.16h0a22.22,22.22,0,0,0-22.22,22.22v16a10.87,10.87,0,0,0,10.87,10.87H76.84V175.38A22.22,22.22,0,0,0,54.62,153.16Z"
      />
      <circle style={cls1} cx="54.62" cy="135.09" r="13.17" />
      <path
        style={cls1}
        d="M178.19,202.24V175.38a22.22,22.22,0,0,1,22.22-22.22h0a22.22,22.22,0,0,1,22.22,22.22v17.87a9,9,0,0,1-9,9Z"
      />
      <circle style={cls1} cx="200.41" cy="135.09" r="13.17" />
      <path d="M156.86,199.07a.77.77,0,0,1-.77-.77v-42a34,34,0,1,0-68,0v42a.77.77,0,1,1-1.54,0v-42a35.52,35.52,0,0,1,71,0v42A.77.77,0,0,1,156.86,199.07Z" />
      <path d="M122.11,114.65a21.36,21.36,0,1,1,21.36-21.36A21.39,21.39,0,0,1,122.11,114.65Zm0-41.19a19.82,19.82,0,1,0,19.82,19.82A19.84,19.84,0,0,0,122.11,73.46Z" />
      <path d="M72.21,199.07a.77.77,0,0,1-.77-.77V171.44a21.45,21.45,0,1,0-42.89,0V198.3a.77.77,0,1,1-1.54,0V171.44a23,23,0,0,1,46,0V198.3A.77.77,0,0,1,72.21,199.07Z" />
      <path d="M50,145.09a13.94,13.94,0,1,1,13.94-13.94A14,14,0,0,1,50,145.09Zm0-26.33a12.39,12.39,0,1,0,12.39,12.39A12.41,12.41,0,0,0,50,118.76Z" />
      <path d="M216.45,199.07a.77.77,0,0,1-.77-.77V171.44a21.45,21.45,0,1,0-42.89,0V198.3a.77.77,0,0,1-1.54,0V171.44a23,23,0,0,1,46,0V198.3A.77.77,0,0,1,216.45,199.07Z" />
      <path d="M194.23,145.09a13.94,13.94,0,1,1,13.94-13.94A14,14,0,0,1,194.23,145.09Zm0-26.33a12.39,12.39,0,1,0,12.39,12.39A12.41,12.41,0,0,0,194.23,118.76Z" />
    </svg>
  );
};

export default WhoWeAre;
