import React from "react";
import classes from "./AttachmentLoader.scss";
import { getThumbUrl } from "Utils";
import {
  timeHelper,
  sizeHelper,
  getTimeLeftInMinutes,
} from "Utils/AttachmentHelpers";
import { I18nHOC } from "UIComponents";

import { ProgressIndicator, IconButton } from "@toddle-design/web";

import { CloseOutlined, TickCircleFilled } from "@toddle-design/web-icons";

class AttachmentLoader extends React.PureComponent {
  render() {
    const { item = {}, t } = this.props;
    const { attachment = {}, status } = item;

    let { thumbUrl, isIcon, color } = getThumbUrl({
      attachment: attachment,
      width: 80,
      height: 80,
    });

    const { name = "", url } = attachment;

    const totalProgress = url && url.startsWith("blob:") ? 100 : 50;

    const size =
      attachment.metadata && attachment.metadata.size
        ? attachment.metadata.size / 1000
        : 0;

    let progressValue = parseInt(_.get(item, `progress`, "100"));
    const currentProgress =
      totalProgress === 100 ? progressValue : progressValue - 50;
    progressValue =
      totalProgress === 100 ? currentProgress : currentProgress * 2;
    const uploadedSize = (size * currentProgress) / totalProgress;
    const startTime = item.startTime;

    const timeLeft = getTimeLeftInMinutes({
      startTime: startTime,
      uploadedSize: uploadedSize,
      totalSize: size,
    });
    const displayUploadSize = sizeHelper({ size: uploadedSize });
    const displayTotalSize = sizeHelper({ size: size });
    const displayTime = timeHelper({ timeInMinutes: timeLeft });

    return (
      <div className={classes.container}>
        <div
          className={classes.imageContainer}
          style={{
            backgroundImage: `url(${thumbUrl})`,
            backgroundSize: isIcon ? "35%" : "cover",
            backgroundColor: color,
          }}
        />
        {status == "UPLOAD" && (
          <div className={classes.rightContainer}>
            <div className={classes.detailContainer}>
              <div className={classes.fileName}>{name}</div>
              <div className={classes.iconContainer}>
                {progressValue >= 0 &&
                  progressValue < 100 &&
                  item.cancelToken && (
                    <IconButton
                      onClick={item.cancelToken}
                      icon={<CloseOutlined />}
                      variant={"plain"}
                      size={"xx-small"}
                    />
                  )}
                {progressValue >= 100 && (
                  <div className={classes.iconContainer}>
                    <TickCircleFilled variant="success" size="xxx-small" />
                  </div>
                )}
              </div>
            </div>

            <ProgressIndicator
              variant="progress-bar"
              progress={progressValue}
            />

            <div className={classes.labelContainer}>
              <div>
                {t("common:uploaded_size_of_total_size", {
                  uploadedSize: displayUploadSize,
                  size: displayTotalSize,
                })}
              </div>
              {progressValue > 0 && progressValue < 100 && (
                <div>{t(displayTime.locale, { count: displayTime.time })}</div>
              )}
              {progressValue <= 0 && <div>{t("common:calculating")}</div>}
            </div>
          </div>
        )}
        {status == "DOWNLOAD" && (
          <div className={classes.processing}>
            <div className={classes.fileName}>
              {t("common:processing_file")}
            </div>
            <div className={classes.iconContainer}>
              {item.cancelToken && (
                <IconButton
                  onClick={item.cancelToken}
                  icon={<CloseOutlined />}
                  variant={"plain"}
                  size={"xx-small"}
                />
              )}
            </div>
          </div>
        )}
        {status == "SAVE" && (
          <div className={classes.saveContainer}>
            <div className={classes.statusText}>{t("common:saving")}</div>
            <div className={classes.fileName}>{name}</div>
          </div>
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common", "moment"] })(AttachmentLoader);
