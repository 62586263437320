import React from "react";
import classes from "./CustomSelectDropdown.scss";
import SearchBarWithItem from "./SearchBarWithItem";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import DropdownList from "./DropdownList";
import { ButtonDropdown } from "UIComponents";
import { connect } from "react-redux";
import _ from "lodash";
import { Query } from "react-apollo";

import { getStaffListQuery } from "modules/CommonQuery";
import { getStaffListFromCache } from "modules/CommonGraphqlHelpers";

//for the custom itemlist pass the item type in the template or from props
//add the same type in the itemList.js switch case

const styles = {
  buttonDropDownContainerStyle: {
    top: 56,
    boxShadow: "none",
    borderWidth: 0,
    width: "100%",
  },
  buttonParentStyle: {
    width: "100%",
  },
};

const getStaffsList = ({ staffData }) => {
  const staffs = staffData;
  return _.map(staffs, staff => {
    return {
      value: staff.node.id,
      label: staff.node.firstName + " " + staff.node.lastName,
      profileImage: staff.node.profileImage,
    };
  });
};
export class CustomSelectDropDown extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      isFocus: false,
      selectedValue: props.value,
      error: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedValue !== prevProps.selectedValue) {
      this.setState({ selectedValue: this.props.selectedValue });
    }
  }

  updateSearchText = _.debounce(searchtext => {
    this.setState({ searchText: searchtext });
  }, 300);

  isValid = () => {
    const { selectedValue } = this.state;
    if (selectedValue) {
      return 0;
    }
    this.setState({ error: true });
    return 1;
  };

  updateSelectedValue = ({ selectedValue }) => {
    this.setState({
      selectedValue,
      isFocus: false,
      searchText: "",
      error: false,
    });
    this.updateValue(selectedValue);
  };

  renderEdit = () => {
    const {
      placeholder,
      itemType,
      value,
      queryItemType,
      dropDownContainerStyle,
      inputContainerStyle,
      organizationId,
      grades,
    } = this.props;
    const { searchText, isFocus, selectedValue, error } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.middleContainer} style={inputContainerStyle}>
          <Query
            query={getStaffListQuery}
            skip={queryItemType !== "ORGANIZATION_SUPERVISOR"}
            variables={{
              organizationId,
              searchText,
              grades,
            }}
            fetchPolicy={"cache-and-network"}
          >
            {({ data, networkStatus }) => {
              const queryData = _.get(
                getStaffListFromCache({ organizationId, searchText, grades }),
                "node",
                []
              );

              const staffData = _.get(queryData, "staff.edges", []);
              const options = getStaffsList({ staffData });
              const selectedItem = _.find(
                options,
                opt => opt.value === selectedValue
              );
              return (
                <ButtonDropdown
                  dropdownComponent={
                    <DropdownList
                      options={options}
                      searchText={searchText}
                      itemType={itemType}
                      updateSelectedValue={this.updateSelectedValue}
                      value={value}
                      dropDownContainerStyle={dropDownContainerStyle}
                      isLoading={networkStatus == 1 || networkStatus == 2}
                    />
                  }
                  shouldCloseOnButtonClick={false}
                  containerStyle={styles.buttonDropDownContainerStyle}
                  buttonParentStyle={styles.buttonParentStyle}
                  buttonComponent={
                    <SearchBarWithItem
                      searchText={searchText}
                      updateSearchText={this.updateSearchText}
                      placeholder={placeholder}
                      selectedItem={selectedItem}
                      isFocus={isFocus}
                      itemType={itemType}
                      updateSelectedValue={this.updateSelectedValue}
                      error={error}
                    />
                  }
                />
              );
            }}
          </Query>
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;
  return {
    isData: true,
    isLoading: false,
    organizationId,
  };
};

export default connect(mapStateToProps, null)(CustomSelectDropDown);
