import React, { Fragment } from "react";
import classes from "./TopHeaderNavBar.scss";
import { ToddleIcon } from "SvgComponents";
import PropTypes from "prop-types";
import { ChevronLeftOutlined } from "@toddle-design/web-icons";
import classNames from "classnames";

const styles = {
  removeBorderStyle: {
    border: "unset",
  },
};

const TopHeader = props => {
  const {
    middleContainer,
    onBack,
    showMiddleContainer,
    showRightContainer,
    showToddleIcon,
    showLeftContainer,
    leftContainer,
    rightContainer,
    showBorder,
    showBackButton,
    headerText,
    containerStyle,
    subHeaderText,
    leftContainerStyle = {},
  } = props;

  const subHeaderTextClass = classNames({
    ["text-body-2"]: true,
    [classes.subHeaderText]: true,
  });

  return (
    <Fragment>
      <div
        className={classes.container}
        style={
          !showBorder
            ? { ...styles.removeBorderStyle, ...containerStyle }
            : containerStyle
        }
      >
        <div className={classes.leftContainer} style={leftContainerStyle}>
          {showBackButton && (
            <div className={classes.backButtonWrapper} onClick={onBack}>
              <div className={classes.headerTabBackButton}>
                <ChevronLeftOutlined size={"xx-small"} variant={"subtle"} />
              </div>
              {showToddleIcon && (
                <div className={classes.logo}>
                  <ToddleIcon height={32} width={32} />
                </div>
              )}
            </div>
          )}
          {headerText && (
            <div className={classes.header}>
              <div className={classes.headerText}>{headerText}</div>
              {subHeaderText && (
                <div className={subHeaderTextClass}>{subHeaderText}</div>
              )}
            </div>
          )}
          {showLeftContainer && leftContainer}
        </div>

        <div className={classes.middleContainer}>
          {showMiddleContainer && middleContainer}
        </div>

        <div className={classes.rightContainer}>
          {showRightContainer && rightContainer}
        </div>
      </div>
    </Fragment>
  );
};
TopHeader.defaultProps = {
  showMiddleContainer: false,
  showRightContainer: false,
  showBorder: true,
  showBackButton: true,
  showToddleIcon: true,
  showLeftContainer: false,
};
TopHeader.propTypes = {
  middleContainer: PropTypes.element,
  onBack: PropTypes.func,
  showMiddleContainer: PropTypes.bool,
  showRightContainer: PropTypes.bool,
  showToddleIcon: PropTypes.bool,
  rightContainer: PropTypes.element,
  showBorder: PropTypes.bool,
  showBackButton: PropTypes.bool,
  headerText: PropTypes.string,
  showLeftContainer: PropTypes.bool,
  leftContainer: PropTypes.element,
};
export default TopHeader;
