import gql from "graphql-tag";
import { staffFragment } from "modules/CommonFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";

export const likeConnectionfragment = {
  likeFeedConnection: gql`
    fragment likeFeedConnectionItem on LikeConnection {
      totalCount
      isByMe
      edges(first: 10) {
        node {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,
  likeUserConnection: gql`
    fragment likeUserConnection on LikeConnection {
      pageInfo(first: 10, after: $after) {
        hasNextPage
        endCursor
      }
      edges(first: 10, after: $after) {
        node {
          id
          user {
            id
            email
            firstName
            lastName
            profileImage
            ... on Staff {
              schoolTenures(currentSchool: true) {
                id
                school {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const likeConnectionNodefragment = {
  unitPlan: gql`
    fragment unitLikeConnectionNodeItem on UnitPlan {
      id
      likes {
        ...likeFeedConnectionItem
      }
    }
    ${likeConnectionfragment.likeFeedConnection}
  `,
  assessment: gql`
    fragment assessmenLikeConnectionNodeItem on Assessment {
      id
      likes {
        ...likeFeedConnectionItem
      }
    }
    ${likeConnectionfragment.likeFeedConnection}
  `,
};

export const entityRatingFragment = {
  entityRating: gql`
    fragment entityRatingItem on EntityRating {
      id
      rating {
        id
      }
      user {
        id
      }
    }
  `,
};

export const entityRatingsNodeFragment = {
  unitPlan: gql`
    fragment unitRatingsNodeItem on UnitPlan {
      id
      entityRatings {
        ...entityRatingItem
      }
    }
    ${entityRatingFragment.entityRating}
  `,
  assessment: gql`
    fragment assessmentRatingsNodeItem on Assessment {
      id
      entityRatings {
        ...entityRatingItem
      }
    }
    ${entityRatingFragment.entityRating}
  `,
};

export const assessmentFragment = {
  assessmentDetails: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      assessmentType {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      templateId
      assessmentTool {
        id
      }
      comments {
        totalCount
        unreadCount
      }
      isDeleted
      resources {
        totalCount
      }
      allFields {
        ...resourceLibraryFieldItem
      }
      unitPlan {
        id
        templateId
      }
    }
    ${staffFragment.basicStaffDetails}
    ${plannerFieldFragment.resourceLibraryField}
  `,
};

export const unitPlanDetailFragment = {
  unitPlan: gql`
    fragment unitPlanDetails on UnitPlan {
      id
      title {
        id
        value
      }
      resolvedGrades {
        id
        name
        globalGrade {
          id
          name
        }
      }
      grades
      theme {
        id
        value
        resolvedMinimalTree {
          ... on ResolvedFieldThemeSet {
            id
            themes {
              id
              label
            }
          }
        }
      }
      image {
        id
        value
      }
      collaborators: allCollaborators {
        totalCount
        edges {
          node {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
      leLibrary: resourceLibrary(filters: { groupType: LEARNING_ENGAGEMENT }) {
        leCount
      }
      assessmentLibrary: resourceLibrary(filters: { groupType: ASSESSMENT }) {
        assessmentCount
      }
      resourceLibrary {
        leCount
        assessmentCount
      }
      fields(uids: ["about", "tags", "grades"]) {
        id
        uid
        value
      }
    }
  `,
};

export const learningExperienceDetailFragment = {
  le: gql`
    fragment leDetails on Assessment {
      id
      title {
        id
        value
      }
      grades {
        id
        name
        globalGrade {
          id
          name
        }
      }

      image {
        id
        value
      }
      fields(uids: ["tags", "grades"]) {
        id
        uid
        value
      }
    }
  `,
};

export const entitySavedByNodeFragment = {
  unitPlan: gql`
    fragment unitSavedByNodeItem on UnitPlan {
      id
      savedBy {
        id
      }
    }
  `,
  assessment: gql`
    fragment assessmentSavedByNodeItem on Assessment {
      id
      savedBy {
        id
      }
    }
  `,
};

export const organizationMembersFragment = {
  members: gql`
    fragment organizationMembers on Organization {
      id
      staff(filters: $filters) {
        edges {
          node {
            id
            email
            type
            profileImage
            firstName
            lastName
          }
        }
      }
    }
  `,
};

export const communitySchoolBasicFragment = {
  communitySchool: gql`
    fragment communitySchoolItem on CommunitySchool {
      id
      name
      logo
    }
  `,
};

export const resourceRequestFragment = {
  resourceRequest: gql`
    fragment resourceRequestItem on SurveyContentKeyword {
      id
      contentKeyword
      contentType
      grades {
        id
        name
      }
      isUpvoted
      upvoteCount
      createdAt
    }
  `,
};
