import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UIModal, I18nHOC } from "UIComponents";
import classes from "./SelectionModal.scss";
import classNames from "classnames";
import SelectableCard from "../SelectableCard";
import { IconButton, Button } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";

const SelectionModal = props => {
  const {
    t,
    name,
    isEdit,
    options,
    headerLabel,
    onCancelClick,
    updateValueLocally,
    updateInputField,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const modalBodyElement = useRef(null);
  const optionElement = useRef(null);

  useEffect(() => {
    if (updateValueLocally) {
      updateValueLocally(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions(props.selectedOptions ?? []);
  }, []);

  const updateSelectedOptions = value => {
    const newSelectedOptions = [...selectedOptions];
    const index = _.indexOf(newSelectedOptions, value);

    if (index != -1) {
      newSelectedOptions.splice(index, 1);
    } else {
      newSelectedOptions.push(value);
    }

    setSelectedOptions(newSelectedOptions);
  };

  const closeModal = () => {
    setSelectedOptions([]);
    onCancelClick();
  };

  const saveOptions = () => {
    updateInputField({ [name]: [...selectedOptions] });
    closeModal();
  };

  const getOptionElements = ({ options }) => {
    return _.map(options, option => {
      return (
        <SelectableCard
          item={option}
          key={option.id}
          value={option.value}
          onSelect={updateSelectedOptions}
          isActive={_.includes(selectedOptions, option.value)}
        />
      );
    });
  };

  const shouldShowShadow = () => {
    if (modalBodyElement && optionElement) {
      return optionElement.scrollHeight > modalBodyElement.clientHeight;
    }

    return false;
  };

  const footerClasses = classNames(
    { [classes.modalFooter]: true },
    { [classes.modalFooterShadow]: shouldShowShadow() }
  );

  return (
    <UIModal isOpen={true} modalContent={classes.modalContainer}>
      <div className={classes.modalHeader}>
        <div className={classes.headerLabel}>{`${headerLabel}`}</div>
        <IconButton
          variant={"plain"}
          onClick={onCancelClick}
          icon={<CloseOutlined variant={"subtle"} size={"x-small"} />}
        />
      </div>
      <div className={classes.modalBody} ref={modalBodyElement}>
        <div className={classes.optionsContainer} ref={optionElement}>
          {getOptionElements({ options })}
        </div>
      </div>

      <div className={footerClasses}>
        <Button size={"medium"} variant={"subtle"} onClick={closeModal}>
          {t("common:cancel")}
        </Button>
        <Button
          size={"medium"}
          onClick={saveOptions}
          variant={"destructive"}
        >{`${isEdit ? t("common:update") : t("common:add")}`}</Button>
      </div>
    </UIModal>
  );
};

SelectionModal.propTypes = {
  updateInputField: PropTypes.func,
  onCancelClick: PropTypes.func,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  name: PropTypes.string,
  isEdit: PropTypes.bool,
};

SelectionModal.defaultProps = {
  isEdit: false,
};

export default I18nHOC({ resource: "common" })(SelectionModal);
