import PropTypes from "prop-types";
import React from "react";
import { TextAreaInput, FormInputError } from "@toddle-design/web";

import UIBaseComponent from "UIComponents/UIBaseComponent";
import classNames from "classnames";
import classes from "./TextAreaInput.scss";
class FormTextAreaInput extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      keysDown: {},
    };
  }

  handleKeyDown = event => {
    let kd = this.state.keysDown;
    kd[event.keyCode] = true;
    this.setState({ keysDown: kd });

    if (kd[16] && kd[13] && this.props.allowShiftEnter) {
      event.preventDefault();
      let updateVal = `${event.target.value}\r`;
      kd[event.keyCode] = false;
      this.setState({ keysDown: kd });
      this.updateValue(updateVal);
    } else if (event.key == "Enter" && this.props.onEnterPress) {
      event.preventDefault();
      this.props.onEnterPress(event.target.value);
      this.setState({ keysDown: {} });
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  handleKeyUp = e => {
    if (this.props.onEnterPress) {
      let kd = this.state.keysDown;
      kd[e.keyCode] = false;
      this.setState({ keysDown: kd });
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  };

  handleBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    } else {
      this.onBlur({ value: e.target.value });
    }
  };

  handleChange = e => {
    const { maxLength } = this.props;
    const text = e.target.value;
    if (text.length > maxLength) {
      this.setState({ error: "Character limit exceeded" });
    } else {
      this.setState({ error: "" });
    }
    this.updateValue(text);
  };

  renderEdit = () => {
    const { value, maxLength, textAreaStyle, ...rest } = this.props;
    const { error } = this.state;

    const maxLengthContainerClasses = classNames(
      "text-label-3 text-textSubtle",
      {
        invisible: !maxLength,
      }
    );

    const containerClass = classNames("w-full", classes.containerClass);
    const errorClass = classNames("flex justify-between", classes.errorClass);

    return (
      <div className={containerClass}>
        <TextAreaInput
          {...rest}
          label={""}
          value={value}
          maxLength={maxLength}
          ref={this.updateInputRef}
          onChange={this.handleChange}
          onKeyDown={e => this.handleKeyDown(e)}
          onKeyUp={e => this.handleKeyUp(e)}
          onBlur={e => this.handleBlur(e)}
          onFocus={e => this.onFocus({ value: e.target.value })}
          error={error}
          style={{ ...textAreaStyle, unicodeBidi: "plaintext" }}
        />
        <div className={errorClass}>
          {error && <FormInputError className="flex-1 mr-4" error={error} />}
          <span className={maxLengthContainerClasses}>
            {String(value).length} / {maxLength}
          </span>
        </div>
      </div>
    );
  };
}

FormTextAreaInput.defaultProps = {
  ...UIBaseComponent.defaultProps,
  textAreaStyles: {},
  minRows: 3,
  allowShiftEnter: false,
  showMaxCharacterLength: false,
  autoFocus: false,
};

FormTextAreaInput.propTypes = {
  ...UIBaseComponent.propTypes,
  showMaxLength: PropTypes.bool,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  maxLength: PropTypes.number,
  textAreaStyles: PropTypes.object,
  onEnterPress: PropTypes.func,
  allowShiftEnter: PropTypes.bool,
  inputRef: PropTypes.func,
  autoFocus: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FormTextAreaInput;
