import {
  assessmentToolFragment,
  mypObjectiveRubricDescriptorFragmnet,
} from "./AssessmentToolFragment";
import gql from "graphql-tag";

export const createMypObjectiveRubricMutation = gql`
  mutation createMypObjectiveRubric(
    $label: String
    $objectiveIds: [ID!]
    $parentId: ID!
    $parentType: ASSESSMENT_TOOL_PARENT_TYPE_ENUM!
  ) {
    planner {
      createMypObjectiveRubric(
        input: {
          parentId: $parentId
          parentType: $parentType
          label: $label
          objectiveIds: $objectiveIds
        }
      ) {
        ...mypObjectiveRubricItem
      }
    }
  }
  ${assessmentToolFragment.mypObjectiveRubric}
`;

export const createMypObjectiveRubricDescriptorsForObjectiveMutation = gql`
  mutation createMypObjectiveRubricDescriptorsForObjective(
    $objectiveIds: [ID!]
    $rubricId: ID!
  ) {
    planner {
      createMypObjectiveRubricDescriptorsForObjective(
        input: { rubricId: $rubricId, objectiveIds: $objectiveIds }
      ) {
        ...mypObjectiveRubricDescriptorItem
      }
    }
  }
  ${mypObjectiveRubricDescriptorFragmnet.mypObjectiveRubricDescriptor}
`;

export const deleteMypObjectiveRubricDescriptorsForObjectiveMutation = gql`
  mutation deleteMypObjectiveRubricDescriptorsForObjective(
    $objectiveIds: [ID!]
    $rubricId: ID!
  ) {
    planner {
      deleteMypObjectiveRubricDescriptorsForObjective(
        input: { rubricId: $rubricId, objectiveIds: $objectiveIds }
      ) {
        isSuccess
      }
    }
  }
`;

export const createExemplarMutation = gql`
  mutation createExemplar(
    $message: String!
    $createdBy: ID!
    $parentId: ID
    $parentType: ASSESSMENT_TOOL_PARENT_TYPE_ENUM
  ) {
    planner {
      createExemplar(
        input: {
          assessmentId: "DUMMY"
          message: $message
          createdBy: $createdBy
          parentId: $parentId
          parentType: $parentType
        }
      ) {
        ...exemplarItem
      }
    }
  }
  ${assessmentToolFragment.exemplar}
`;

export const updateExemplarMutation = gql`
  mutation updateExemplar($id: ID!, $message: String!, $updatedBy: ID!) {
    planner {
      updateExemplar(
        input: { id: $id, message: $message, updatedBy: $updatedBy }
      ) {
        id
      }
    }
  }
`;

export const createAnecdotalMutation = gql`
  mutation createAnecdote(
    $message: String
    $createdBy: ID!
    $parentId: ID
    $parentType: ASSESSMENT_TOOL_PARENT_TYPE_ENUM
  ) {
    planner {
      createAnecdote(
        input: {
          assessmentId: "DUMMY"
          message: $message
          createdBy: $createdBy
          parentId: $parentId
          parentType: $parentType
        }
      ) {
        ...anecdoteItem
      }
    }
  }
  ${assessmentToolFragment.anecdote}
`;

export const updateAnecdotalMutation = gql`
  mutation updateAnecdote($id: ID!, $message: String!, $updatedBy: ID!) {
    planner {
      updateAnecdote(
        input: { id: $id, message: $message, updatedBy: $updatedBy }
      ) {
        id
      }
    }
  }
`;

export const editMypObjectiveRubricDescriptorMutation = gql`
  mutation editMypObjectiveRubricDescriptor($id: ID!, $label: String) {
    planner {
      editMypObjectiveRubricDescriptor(input: { id: $id, label: $label }) {
        id
      }
    }
  }
`;
