import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";

export default store => {
  return {
    path: ":formId/:mode",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./ContractTrackForm").default;
              const reducer = require("./modules/ContractTrackFormModule")
                .default;
              injectReducer(store, { key: "contractTrackingForm", reducer });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "administrator-tooling"
          );
        })
    ),
    childRoutes: [],
  };
};
