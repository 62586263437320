import React from "react";
import PropTypes from "prop-types";

const LikeIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47511 2.87552L7.9009 3.92558L8.38958 2.90327C9.0288 1.56604 10.2556 0.5 11.5407 0.5C12.7728 0.5 13.7059 0.86412 14.3712 1.53494C15.4921 2.66851 15.5 4.33082 15.5 4.39787C15.5 7.58889 13.6516 9.84211 11.7246 11.3279C10.7639 12.0686 9.7978 12.6065 9.06579 12.9609C8.70042 13.1377 8.39506 13.2681 8.18011 13.3545C8.04769 13.4077 7.95014 13.444 7.89457 13.4641C6.87452 13.0093 4.82426 11.9699 3.13784 10.3462C1.42426 8.69643 0.139039 6.50037 0.591918 3.72288C0.910242 1.77138 2.39408 0.5 4.2947 0.5C6.11568 0.5 7.01823 1.74876 7.47511 2.87552Z"
        stroke={props.fill}
      />
    </svg>
  );
};

LikeIcon.defaultProps = {
  height: 18,
  width: 20,
};

LikeIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default LikeIcon;
