import React from "react";
import PropTypes from "prop-types";
import classes from "./VideoViewer.scss";
import FaClose from "react-icons/lib/fa/close";
import { VideoPlayer } from "UIComponents";
class VideoViewer extends React.PureComponent {
  onCloseClick = e => {
    e.stopPropagation();
    if (this.props.closeVideoViewer) {
      this.props.closeVideoViewer();
    }
  };

  render() {
    const { videoUrl, isHLSUrl } = this.props;
    return (
      <div className={classes.container} onClick={this.onCloseClick}>
        <div className={classes.topPanel}>
          <div className={classes.crossSvg} onClick={e => this.onCloseClick(e)}>
            <FaClose size={20} color="white" className={classes.closeIcon} />
          </div>
        </div>
        <div
          className={classes.videoViwerContainer}
          onClick={e => e.stopPropagation()}
        >
          <VideoPlayer
            videoUrl={videoUrl}
            isHLSUrl={isHLSUrl}
            getPlayerRef={ref => (this.videoPlayer = ref)}
          />
        </div>
      </div>
    );
  }
}

export default VideoViewer;

VideoViewer.defaultProps = {
  isHLSUrl: false,
};

VideoViewer.propTypes = {
  closeVideoViewer: PropTypes.func,
  videoUrl: PropTypes.string,
  isHLSUrl: PropTypes.bool,
};
