import { withAsyncRouteLoading } from "UIComponents";
import Module from "../Module";

export default store => ({
  path: "explore",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "toddle-academy"
        );
      })
  ),
  childRoutes: [Module(store)],
});
