import gql from "graphql-tag";

import {
  organizationFragment,
  studentDetailsFragment,
  studentOtherDetailsFragment,
} from "./StudentProfileFragments";

export const getStudentBasicDetailsQuery = gql`
  query getStudentBasicDetails($studentId: ID!, $courseFilters: CourseFilter) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentDetailsItem
      }
    }
  }
  ${studentDetailsFragment.studentDetails}
`;

export const getStudentNotesQuery = gql`
  query getStudentNotes($studentId: ID!) {
    node(id: $studentId, type: STUDENT) {
      ...studentNotesItem
    }
  }
  ${studentDetailsFragment.studentNotes}
`;

export const getStudentProfileResponsesQuery = gql`
  query getStudentProfileResponses($studentId: ID!) {
    node(id: $studentId, type: STUDENT) {
      ...studentProfileResponsesItem
    }
  }
  ${studentDetailsFragment.studentProfileResponses}
`;

export const getOrganizationStudentNoteCategoryQuery = gql`
  query getOrganizationStudentNoteCategory($organizationId: ID!) {
    node(id: $organizationId, type: ORGANIZATION) {
      ...organizationItem
    }
  }
  ${organizationFragment.studentNoteCategory}
`;

export const getOrganizationStudentProfileSectionQuery = gql`
  query getOrganizationStudentProfileSection(
    $organizationId: ID!
    $filters: StudentProfileTemplateSectionFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ...organizationItem
    }
  }
  ${organizationFragment.studentProfileSections}
`;

export const getStudentOtherDetailsQuery = gql`
  query getStudentOtherDetails($studentId: ID!) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentOtherDetailsItem
      }
    }
  }
  ${studentOtherDetailsFragment.studentOtherDetails}
`;

export const studentFamilyInfo = gql`
  query getStudentFamilyInfo($id: ID!, $filters: ChatFilter) {
    node(id: $id, type: STUDENT) {
      id
      ... on Student {
        id
        families {
          id
          createdAt
          status
          familyMember {
            ...familyInfo
            chats(filters: $filters) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
  ${studentDetailsFragment.familyInfo}
`;
