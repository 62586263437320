import client from "apolloClient";
import {
  unitPlanEvidenceFragment,
  postEvidenceFragment,
} from "./EvidenceFragments";
import { getUnitPlanEvidenceQuery } from "./EvidenceQuery";
export const getUnitPlanEvidenceFromCache = ({ id, fieldId, uids }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUnitPlanEvidenceQuery,
      variables: {
        id,
        fieldId,
        uids,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getUnitPlanEvidenceWithFiltersFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `UnitPlan:${id}`,
      fragment: unitPlanEvidenceFragment.unitPlanEvidenceWithFilter,
      fragmentName: "unitPlanItem",
      variables: {
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getPostEvidenceDataFromCache = ({ postId }) => {
  let fragmentData = {};
  try {
    fragmentData = client.readFragment({
      id: `Post:${postId}`,
      fragmentName: "postItem",
      fragment: postEvidenceFragment.postEvidence,
    });
  } catch (e) {
    return {};
  }
  return fragmentData;
};

export const writePostEvidenceDataInCache = ({ postId, data }) => {
  try {
    client.writeFragment({
      id: `Post:${postId}`,
      fragmentName: "postItem",
      fragment: postEvidenceFragment.postEvidence,
      data,
    });
  } catch (e) {
    console.error(e);
  }
};
