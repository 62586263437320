import gql from "graphql-tag";

import { resourceLibraryFragment } from "modules/CommonFragments";

export const updateAssessmentMutation = gql`
  mutation updateAssessment(
    $fields: [UpdatePlannerFieldInput!]
    $updatedBy: ID!
    $assessmentId: ID!
    $enabledSections: AssessmentEnabledSectionsInput
  ) {
    planner {
      updateAssessment(
        input: {
          updatedBy: $updatedBy
          fields: $fields
          assessmentId: $assessmentId
          enabledSections: $enabledSections
        }
      ) {
        id
        enabledSections
      }
    }
  }
`;

export const updateAssessmentForPlanathonMutation = gql`
  mutation updateAssessment(
    $paritaStarStatus: Boolean
    $kristenStarStatus: Boolean
    $juryStarStatus: Boolean
    $updatedBy: ID!
    $assessmentId: ID!
    $paritaProcessed: Boolean
    $kristenProcessed: Boolean
    $juryProcessed: Boolean
  ) {
    planner {
      updateAssessment(
        input: {
          updatedBy: $updatedBy
          assessmentId: $assessmentId
          paritaStarStatus: $paritaStarStatus
          kristenStarStatus: $kristenStarStatus
          juryStarStatus: $juryStarStatus
          paritaProcessed: $paritaProcessed
          kristenProcessed: $kristenProcessed
          juryProcessed: $juryProcessed
        }
      ) {
        ...resourceLibraryItem
      }
    }
  }
  ${resourceLibraryFragment.resourceFeed}
`;

export const clearAssessmentToolMutation = gql`
  mutation updateAssessment($updatedBy: ID!, $assessmentId: ID!) {
    planner {
      updateAssessment(
        input: {
          updatedBy: $updatedBy
          clearAssessmentTool: true
          assessmentId: $assessmentId
        }
      ) {
        id
        assessmentTool {
          id
        }
      }
    }
  }
`;

export const createAssessmentMutation = gql`
  mutation createAssessment(
    $curriculumProgramId: ID
    $templateId: ID!
    $createdBy: ID!
    $unitPlanId: ID!
    $type: ASSESSMENT_TYPE_ENUM
    $fixedFields: CreateAssessmentFixedFieldInput
    $fields: [PlannerFieldInput]
    $academicYears: [ID!]
  ) {
    planner {
      createAssessment(
        input: {
          fields: $fields
          curriculumProgramId: $curriculumProgramId
          templateId: $templateId
          createdBy: $createdBy
          fixedFields: $fixedFields
          unitPlanId: $unitPlanId
          academicYears: $academicYears
          type: $type
        }
      ) {
        ...resourceLibraryItem
      }
    }
  }
  ${resourceLibraryFragment.resourceFeed}
`;

export const deleteAssessmentMutation = gql`
  mutation deleteAssessment($id: ID!) {
    planner {
      deleteAssessment(input: { id: $id })
    }
  }
`;
