import React from "react";
import classes from "./MultiSetting.scss";
import classNames from "classnames";
import { SwitchButton } from "UIComponents";
import ChooseCourses from "../../../ChooseCourses";
import { getSettingValue } from "modules/PermissionModule";

const MultiSetting = React.memo(
  ({
    subModule,
    subSettings,
    allSettings,
    onUpdateSetting,
    subModuleTitleStyles,
    classOptions,
    t,
    platform,
    categories,
    showChooseCoursesFromParent,
    isDisabled,
    isNotificationConfiguration,
    courseId,
    organizationId,
  }) => {
    const columns = _.get(subModule, "metadata.columns");
    const isPermCategoryNotification =
      _.get(subModule, "permissionCategory", null) === "NOTIFICATION";

    subSettings.sort((a, b) => a.displayOrder - b.displayOrder);

    const renderValues = (setting, showChooseCourses) => {
      const isSettingDisabled = _.get(setting, "status") === "DISABLED";
      const uiType = _.get(setting, "uiType", "");
      switch (uiType) {
        case "BOOLEAN": {
          let booleanValue = _.get(setting, "booleanValue", false);
          const permission = _.get(setting, "permission");
          const columnNameStyles = classNames({
            [classes.columnNameChooseCourses]: showChooseCourses,
            [classes.switchStyle]: isPermCategoryNotification,
          });
          const selectedCourses = _.get(setting, "selectedCourses", []);
          // __FAMILYPORTAL__ - for family web don't to set booleanValue based on selectedCourses
          // Reason: There is no context of courses for parent in notification settings
          if (
            !showChooseCoursesFromParent &&
            isNotificationConfiguration &&
            platform !== "FAMILY_WEB"
          ) {
            booleanValue = !!_.find(selectedCourses, { id: courseId }) === true;
          }
          return (
            <>
              {showChooseCourses && (
                <ChooseCourses
                  t={t}
                  classOptions={classOptions}
                  selectedCourses={selectedCourses}
                  platform={platform}
                  categories={categories}
                  configurationId={_.get(setting, "id")}
                />
              )}
              <div className={columnNameStyles}>
                <div>
                  <SwitchButton
                    isDisabled={!permission || isSettingDisabled || isDisabled}
                    value={booleanValue}
                    updateInputField={value => {
                      let callMapCoursesMutation = false;
                      // __FAMILYPORTAL__ - for family web don't set --> callMapCoursesMutation
                      // Reason: There is no context of courses for parent in notification settings
                      if (
                        !showChooseCoursesFromParent &&
                        isNotificationConfiguration &&
                        platform !== "FAMILY_WEB"
                      ) {
                        callMapCoursesMutation = true;
                      }
                      if (onUpdateSetting) {
                        onUpdateSetting({
                          value,
                          setting,
                          callMapCoursesMutation,
                          selectedCourses,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </>
          );
        }
        case "NA": {
          return <div className={classes.platformType}>-</div>;
        }
        default:
          return null;
      }
    };

    const getSubModuleLabel = () => {
      const label = _.get(subModule, "label", "");

      const isSubModuleTextLocalized = _.get(
        subModule,
        "metadata.isTextLocalized",
        true
      );

      switch (label) {
        case "tagged_in_chat_notifications": {
          const snpModuleLabelSetting = getSettingValue({
            organizationId,
            name: "AppSettingSnpModuleLabel",
          });

          const snpModuleLabel = t(`snp:${snpModuleLabelSetting}`, {
            defaultValue: snpModuleLabelSetting,
          });
          return t(`configuration:${label}`, { moduleName: snpModuleLabel });
        }
        default: {
          return isSubModuleTextLocalized ? t(`configuration:${label}`) : label;
        }
      }
    };

    const subModuleTitleClasses = classNames({
      [classes.titleSubmodule]: true,
      [classes.submoduleNotification]: isPermCategoryNotification,
    });

    return (
      <div className={classes.container}>
        <div className={classes.settingRowContainerSubmodule}>
          <div className={subModuleTitleClasses} style={subModuleTitleStyles}>
            {getSubModuleLabel()}
          </div>
          <div className={classes.rightContainer}>
            {_.map(columns, column => {
              const showChooseCourses =
                _.get(subModule, "metadata.showChooseCourses", false) ===
                  true &&
                isPermCategoryNotification &&
                showChooseCoursesFromParent;
              const platformTypeStyles = classNames({
                [classes.platformType]: true,
                [classes.columnTypeChooseCourses]: showChooseCourses,
              });
              const columnNameStyles = classNames({
                [classes.columnName]: true,
                [classes.columnNameChooseCourses]: showChooseCourses,
              });
              return (
                <div className={platformTypeStyles} key={column.columnId}>
                  {showChooseCourses && (
                    <div className={classes.chooseCoursesColumnTitle}>
                      {t(`configuration:select_classes`)}
                    </div>
                  )}
                  {!isPermCategoryNotification && (
                    <div className={columnNameStyles}>
                      {t(`configuration:${column.label}`)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {_.map(subSettings, subSetting => {
          if (_.get(subSetting, "uiType", "NA") === "NA") {
            return null;
          }
          const platformSettings = _.filter(allSettings, {
            parentID: subSetting.id,
          });
          const isTextLocalized = _.get(
            subSetting,
            "metadata.isTextLocalized",
            true
          );
          return (
            <div className={classes.settingRowContainer}>
              <div className={classes.title}>
                {isTextLocalized
                  ? t(`configuration:${subSetting.label}`)
                  : _.get(subSetting, "label", "")}
              </div>
              <div className={classes.rightContainer}>
                {_.map(columns, column => {
                  const platformSetting = _.find(platformSettings, {
                    metadata: {
                      columnId: column.columnId,
                    },
                  });
                  if (!platformSetting) {
                    return <div className={classes.platformType}>-</div>;
                  }
                  const showChooseCourses =
                    _.get(
                      platformSetting,
                      "metadata.showChooseCourses",
                      false
                    ) === true &&
                    isPermCategoryNotification &&
                    showChooseCoursesFromParent;
                  const platformTypeStyles = classNames({
                    [classes.platformType]: true,
                    [classes.columnSystemNotificationType]:
                      column.columnId === "system_notification",
                    [classes.columnTypeChooseCourses]: showChooseCourses,
                  });
                  return (
                    <div className={platformTypeStyles}>
                      {renderValues(platformSetting, showChooseCourses)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);

MultiSetting.defaultProps = {
  showChooseCoursesFromParent: false,
};

export default MultiSetting;
