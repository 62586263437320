import React from "react";
import classes from "./EditAPIModal.scss";
import { UIModal } from "UIComponents";
import APIInputDetails from "../APIInputDetails";

const styles = {
  containerStyle: {
    padding: "40px",
  },
};

const EditAPIModal = props => {
  const { setShowEditDialog } = props;

  const onRequestClose = () => {
    setShowEditDialog(false);
  };

  return (
    <>
      <UIModal
        isOpen={true}
        onRequestClose={onRequestClose}
        modalContent={classes.modalContent}
      >
        <APIInputDetails
          {...props}
          action={"edit"}
          containerStyle={styles.containerStyle}
        />
      </UIModal>
    </>
  );
};

export default EditAPIModal;
