import i18next from "i18next";
import { LANGUAGE_OPTIONS } from "Constants";

export const loadLocalesBundle = async value => {
  const filterLanguage = _.filter(
    LANGUAGE_OPTIONS,
    language => language.value === value
  );
  if (_.isEmpty(filterLanguage)) {
    return;
  }
  await import(
    /* webpackChunkName: "[request]" */
    `Locales/${value}/index`
  )
    .then(resources => {
      _.forOwn(resources.default, (key, ns) => {
        i18next.addResourceBundle(value, ns, key);
      });
    })
    .catch(() => {
      window.location.reload();
    });
};
