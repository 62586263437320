import PropTypes from "prop-types";
import React from "react";

const FBIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 11 20">
      <g
        id="Website-Pages"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <path
          d="M3.17931507,20 L3.17931507,11.2500013 L0,11.2500013 L0,7.63043131 L3.17931507,7.63043131 L3.17931507,4.87172102 C3.17931507,1.73346983 5.04873923,0 7.90891436,0 C9.27889316,0 10.7119027,0.244557079 10.7119027,0.244557079 L10.7119027,3.32607019 L9.1330042,3.32607019 C7.57748134,3.32607019 7.09233269,4.29129581 7.09233269,5.28153544 L7.09233269,7.63043131 L10.5651789,7.63043131 L10.0100077,11.2500013 L7.09233269,11.2500013 L7.09233269,20 C5.35596178,19.9974955 4.865491,19.9974955 3.17931507,20 Z"
          id="Path"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

FBIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

FBIcon.defaultProps = {
  height: 20,
  width: 11,
};

export default FBIcon;
