import gql from "graphql-tag";
import { fragments } from "SchoolCalendar/modules/CalendarFragment";
import { academicYearFragment } from "modules/CommonFragments";

export const courseItemFragment = {
  courseItem: gql`
    fragment courseItem on Course {
      id
      title
      isDemo
      grade {
        id
        name
      }
      calendar {
        id
      }
      curriculumProgram {
        id
        type
      }
      academicYears {
        ...academicYearItem
      }
    }
    ${academicYearFragment.academicYear}
  `,
};

export const eventItemFragment = {
  assignmentItem: gql`
    fragment assignmentItem on Assignment {
      id
      contentType
      state {
        state
        publishedAt
        updatedAt
      }
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentType {
            id
            value
          }
        }
        ... on AssignmentResource {
          id
          label
          assignmentTitle: title
          meeting {
            id
            label
            createdBy {
              id
              firstName
              lastName
              profileImage
            }
            urls {
              type
              ... on OtherMeetingUrls {
                url
              }
              ... on ZoomMeetingUrls {
                joinUrl
                startUrl
              }
              ... on MicrosoftTeamMeetingUrls {
                joinUrl
              }
              ... on GoogleMeetingUrls {
                type
                url
              }
            }
            type
          }
        }
      }
    }
  `,
  calendarEventItem: gql`
    fragment calendarEventItem on CalendarEventItem {
      id
      label
      description
      meeting {
        id
        label
        createdBy {
          id
          firstName
          lastName
          profileImage
        }
        urls {
          type
          ... on OtherMeetingUrls {
            url
          }
          ... on ZoomMeetingUrls {
            joinUrl
            startUrl
          }
          ... on MicrosoftTeamMeetingUrls {
            joinUrl
          }
          ... on GoogleMeetingUrls {
            type
            url
          }
        }
        type
      }
    }
  `,
};

export const calendarEventFragment = {
  studentItem: gql`
    fragment studentItem on Student {
      id
      calendarEvents(filters: $filters) {
        id
        startTime
        endTime
        itemType
        item {
          id
          ... on Assignment {
            ...assignmentItem
          }

          ... on CalendarEventItem {
            ...calendarEventItem
          }

          ... on CalendarEvent {
            ...calendarEvent
          }
        }
        calendars {
          id
          parentType
          parent {
            ... on Course {
              ...courseItem
            }
          }
        }
      }
    }
    ${eventItemFragment.assignmentItem}
    ${eventItemFragment.calendarEventItem}
    ${fragments.calendarEvent}
    ${courseItemFragment.courseItem}
  `,
  staffItem: gql`
    fragment staffItem on Staff {
      id
      calendarEvents(filters: $filters) {
        id
        startTime
        endTime
        itemType
        item {
          id
          ... on Assignment {
            ...assignmentItem
          }

          ... on CalendarEventItem {
            ...calendarEventItem
          }

          ... on CalendarEvent {
            ...calendarEvent
          }
        }

        calendars {
          id
          parentType
          parent {
            ... on Course {
              ...courseItem
            }
          }
        }
      }
    }

    ${eventItemFragment.assignmentItem}
    ${eventItemFragment.calendarEventItem}
    ${fragments.calendarEvent}
    ${courseItemFragment.courseItem}
  `,
};

export const courseCalendarDetailFragment = {
  studentItem: gql`
    fragment studentItem on Student {
      id
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
        ...courseItem
      }
    }
    ${courseItemFragment.courseItem}
  `,
  staffItem: gql`
    fragment staffItem on Staff {
      id
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
        ...courseItem
      }
    }
    ${courseItemFragment.courseItem}
  `,
};

export const calendarEventDetailFragment = {
  studentItem: gql`
    fragment studentItem on Student {
      id
      profileImage
      firstName
      lastName
    }
  `,
  staffItem: gql`
    fragment staffItem on Staff {
      id
      profileImage
      firstName
      lastName
    }
  `,
  courseItem: gql`
    fragment courseItem on Course {
      id
      title
      allStudents {
        totalCount
        edges {
          node {
            id
            firstName
          }
        }
      }
    }
  `,
  meetingItem: gql`
    fragment meetingItem on Meeting {
      id
      label
      urls {
        ... on OtherMeetingUrls {
          type
          url
        }
        ... on ZoomMeetingUrls {
          type
          joinUrl
          startUrl
        }
        ... on MicrosoftTeamMeetingUrls {
          type
          joinUrl
          joinWebUrl
        }
        ... on GoogleMeetingUrls {
          type
          url
        }
      }
      type
    }
  `,
};

export const platformCalendarEventFragments = {
  platformCalendarEventDetails: gql`
    fragment platformCalendarEventItem on PlatformCalendarEvent {
      id
      startTime
      endTime
      calendars {
        id
        parentType
        parent {
          ... on Course {
            id
          }
          ... on Student {
            id
          }
        }
      }
      itemType
      item {
        ... on Assignment {
          id
        }
        ... on CalendarEventItem {
          id
        }
      }
    }
  `,
};
