import React from "react";
import classes from "./AttachmentItem.scss";
import classNames from "classnames";
import {
  Camera3D,
  Record3D,
  Video3D,
  Files3D,
  Notes3D,
  Link3D,
  Workbook3D,
} from "@toddle-design/web-icons";

class AttachmentItem extends React.PureComponent {
  getAttachmentIcon = () => {
    const { item: { type } = {}, isDropdownOpen } = this.props;
    const svgIconContainer = classNames(
      { [classes.svgIcon]: true },
      { [classes.activeIconContainer]: isDropdownOpen }
    );
    switch (type) {
      case "PHOTO":
        return (
          <Camera3D height={132} width={132} className={svgIconContainer} />
        );
      case "VOICE":
        return (
          <Record3D height={132} width={132} className={svgIconContainer} />
        );
      case "VIDEO":
        return (
          <Video3D height={132} width={132} className={svgIconContainer} />
        );
      case "FILE":
        return (
          <Files3D height={132} width={132} className={svgIconContainer} />
        );
      case "NOTE":
        return (
          <Notes3D height={132} width={132} className={svgIconContainer} />
        );
      case "LINK":
        return <Link3D height={132} width={132} className={svgIconContainer} />;
      case "WORKBOOK":
        return (
          <Workbook3D height={132} width={132} className={svgIconContainer} />
        );
    }
  };

  render() {
    const {
      item: { color, title, type } = {},
      onAttachmentSelected = () => {},
    } = this.props;

    return (
      <div
        className={classes.container}
        onClick={onAttachmentSelected.bind(null, type)}
      >
        <div
          className={classes.iconContainer}
          style={{ backgroundColor: color }}
        >
          {this.getAttachmentIcon()}
        </div>
        <div className={classes.iconText}>{title}</div>
      </div>
    );
  }
}

export default AttachmentItem;
