import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import classes from "./FeedList.scss";
import FeedListItem from "AppComponents/FeedListItem";
import {
  NoDataComponent,
  ButtonDropdown,
  I18nHOC,
  LinkWithTooltip,
  Loading,
} from "UIComponents";
import { map as _map } from "lodash";
import {
  CaretDownFilled,
  DownArrowOutlined,
  SettingsOutlined,
  UpArrowOutlined,
} from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";

const buttonDropDownContainerStyle = {
  top: 40,
  left: 0,
  minWidth: 200,
  maxWidth: 300,
  maxHeight: 300,
  overflowY: "auto",
};

const tooltipLinkStyle = {
  display: "flex",
  alignItems: "center",
};

class FeedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orderBy: {} };
  }

  changeColumnSortDirection = ({
    id,
    currentSortDirection,
    multi,
    menuItemEntity,
  }) => {
    const { updateSortedFieldsWithOrder } = this.props;
    const updatedSortDirection =
      currentSortDirection === "ASC" ? "DESC" : "ASC";
    updateSortedFieldsWithOrder({
      id,
      sortDirection: updatedSortDirection,
      multi,
      menuItemEntity,
    });
  };

  onSortByActionClick = ({ orderBy }) => {
    this.setState({ orderBy: { ...this.state.orderBy, ...orderBy } });
  };
  headerElements = columnList => {
    const {
      onSortByActionClick,
      t,
      parentStyle,
      headerStyle,
      headerCellCustomStyle,
      getCustomHeaderComponent,
      sortedFieldsWithOrder,
      showConfigureColumnsBtn,
    } = this.props;
    const totalSubtextCount = _.get(
      _.filter(columnList, item => !!item.subText),
      "length",
      0
    );
    return (
      <div
        className={classes.listHeader}
        style={{ ...parentStyle, ...headerStyle }}
      >
        {_map(columnList, column => {
          if (column.type == "CUSTOM" && getCustomHeaderComponent) {
            return getCustomHeaderComponent({ column });
          }

          const entity = column.entity;

          const isSortingEnabled = column.hasSortingEnabled;
          const isSortedColumn = _.has(sortedFieldsWithOrder, entity);

          const headerCellStyle = classNames(
            { [classes.headerItem]: true },
            { [classes.actionCell]: column.type == "ACTIONS" ? true : false },
            { [classes.intCell]: column.type == "INTCOLUMN" ? true : false },
            {
              [classes.headerItemWithSort]:
                !_.isEmpty(sortedFieldsWithOrder) && !isSortedColumn,
            }
          );
          const sortIconVariant = isSortedColumn ? "default" : "subtle";
          const showActionButton =
            column.type == "ACTIONS" && showConfigureColumnsBtn;
          const onConfigureClick = _.get(column, "onConfigureClick");

          const iconStyles = classNames(
            { [classes.sortIcon]: true },
            { [classes.enabledSortIcon]: isSortedColumn }
          );
          const sortByMenuItems = column.sortByMenuItems;
          const menuItems = _.cloneDeep(sortByMenuItems);
          _.map(
            menuItems,
            item =>
              (item.label = t(item.label, {
                label: t("common:lowercase", {
                  text: t(item.locale_label),
                }),
              }))
          );
          return (
            <div
              className={headerCellStyle}
              key={column.id}
              style={{
                ...(column.style || {}),
                ...(headerCellCustomStyle || {}),
              }}
            >
              <div className={classes.headerTitleContainer}>
                {_.get(menuItems, "length", 0) > 0 ? (
                  <ButtonDropdown
                    authTabs={menuItems}
                    containerStyle={buttonDropDownContainerStyle}
                    onItemClick={value => {
                      const extraParams = {
                        id: entity,
                        currentSortDirection: _.get(
                          sortedFieldsWithOrder,
                          `${entity}.direction`,
                          ""
                        ),
                      };
                      onSortByActionClick(value, extraParams);
                    }}
                    buttonComponent={
                      <div className={classes.sortLabelContainer}>
                        <div className={classes.headerLabelText}>
                          {column.locale ? t(column.locale) : column.label}
                        </div>
                        <div className={classes.arrowContainer}>
                          <CaretDownFilled size={"x-small"} />
                        </div>
                      </div>
                    }
                  />
                ) : (
                  <div className={classes.headerLabelText}>
                    {column.locale ? t(column.locale) : column.label}
                    {showActionButton && (
                      <IconButton
                        size={"small"}
                        icon={<SettingsOutlined />}
                        onClick={onConfigureClick}
                        variant={"plain"}
                      />
                    )}
                  </div>
                )}
                {isSortingEnabled && (
                  <div
                    className={iconStyles}
                    onClick={() =>
                      this.changeColumnSortDirection({
                        id: entity,
                        currentSortDirection: _.get(
                          sortedFieldsWithOrder,
                          `${entity}.direction`,
                          ""
                        ),
                        multi: _.get(menuItems, "length", 0) > 0,
                        menuItemEntity:
                          _.get(
                            sortedFieldsWithOrder,
                            `${entity}.menuItemEntity`,
                            ""
                          ) || _.get(column, "defaultMenuItemEntity", ""),
                      })
                    }
                  >
                    {_.get(sortedFieldsWithOrder, `${entity}.direction`, "") ===
                    "DESC" ? (
                      <LinkWithTooltip
                        tooltip={t("common:reverse_sort_direction")}
                        placement="bottom"
                        isVisible={isSortedColumn}
                        linkStyles={tooltipLinkStyle}
                      >
                        <DownArrowOutlined
                          size={"xx-small"}
                          variant={sortIconVariant}
                        />
                      </LinkWithTooltip>
                    ) : (
                      <LinkWithTooltip
                        tooltip={t("common:reverse_sort_direction")}
                        placement="bottom"
                        isVisible={isSortedColumn}
                        linkStyles={tooltipLinkStyle}
                      >
                        <UpArrowOutlined
                          size={"xx-small"}
                          variant={sortIconVariant}
                        />
                      </LinkWithTooltip>
                    )}
                  </div>
                )}
              </div>

              {totalSubtextCount > 0 && (
                <div className={classes.subText} style={column.subTextStyles}>
                  {column.subText}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  getOrderBy = ({ feedData }) => {
    const { orderBy } = this.state;
    if (!_.isEmpty(orderBy)) {
      const orderByKeys = [];
      const orderKeys = [];

      _.forEach(Object.keys(orderBy), key => {
        const item = orderBy[key];
        if (item.orderBy) {
          _.forEach(Object.keys(item.orderBy), orderByKey => {
            orderByKeys.push(orderByKey);
            orderKeys.push(item.orderBy[orderByKey]);
          });
        }
      });
      return _.orderBy(feedData, orderByKeys, orderKeys);
    }
    return feedData;
  };

  getRowComponent = feedData => {
    const { tooltipHrefValue } = this.props;

    feedData = this.getOrderBy({ feedData });
    const {
      parentStyle,
      rowWrapperClass,
      columnList,
      getCustomComponent,
      onItemClick,
      isItemClickEnable,
      useNewActionsDropdown,
      onActionMenuItemClick,
      useCustomFuncForDefault,
    } = this.props;
    return _map(feedData, (obj, key) => (
      <FeedListItem
        parentStyle={parentStyle}
        columnList={columnList}
        listItem={obj}
        key={obj.id}
        rowWrapperClass={rowWrapperClass}
        onItemClick={() => onItemClick && onItemClick(obj.id)}
        isItemClickEnable={isItemClickEnable}
        getCustomComponent={getCustomComponent}
        tooltipHrefValue={tooltipHrefValue}
        useNewActionsDropdown={useNewActionsDropdown}
        onActionMenuItemClick={onActionMenuItemClick}
        useCustomFuncForDefault={useCustomFuncForDefault}
      />
    ));
  };
  getRowsComponent = feedData => {
    //TODO: Handle the empty data case
    const { subHeaderStyle } = this.props;
    return _map(feedData, (subjectGroup, index) => {
      const subHeaderText = _.get(subjectGroup, "subHeaderText", "");
      const feedData = _.get(subjectGroup, "data", {});
      return (
        <React.Fragment>
          <div key={index} className={classes.subHeader} style={subHeaderStyle}>
            {subHeaderText}
          </div>
          {this.getRowComponent(feedData)}
        </React.Fragment>
      );
    });
  };

  render() {
    const {
      feedData,
      containerStyle,
      emptyComponent,
      showHeaderRow,
      countString,
      columnList,
      isGrouped,
      isData,
      isLoading,
      enableStickyHeader,
    } = this.props;

    const headerClass = classNames({
      [classes.headerContainer]: enableStickyHeader,
    });

    return (
      <div className={classes.container} style={containerStyle}>
        <div className={classes.containerList}>
          {countString && (
            <div className={classes.listCountText}>{countString}</div>
          )}
          <div className={headerClass}>
            {showHeaderRow && this.headerElements(columnList)}
          </div>
          <div className={classes.feedListContainer}></div>
          {!isGrouped && this.getRowComponent(feedData)}
          {isGrouped && this.getRowsComponent(feedData)}
          {!isData && isLoading && <Loading />}
          {feedData.length == 0 && isData && !isLoading ? (
            emptyComponent ? (
              emptyComponent
            ) : (
              <NoDataComponent />
            )
          ) : null}
          {/*<div className={classes.loadingContainer}>
            {this.props.getStudentFeed.networkStatus == 3 ? <Loading /> : null}
          </div>*/}
        </div>
      </div>
    );
  }
}

FeedList.defaultProps = {
  showHeaderRow: true,
  containerStyle: {},
  tooltipHrefValue: "#",
  isGrouped: false,
  subHeaderStyle: {},
  useNewActionsDropdown: false,
  useCustomFuncForDefault: false,
  showConfigureColumnsBtn: false,
};

FeedList.propTypes = {
  showHeaderRow: PropTypes.bool,
  containerStyle: PropTypes.object,
  tooltipHrefValue: PropTypes.string,
  isGrouped: PropTypes.bool,
  subHeaderStyle: PropTypes.object,
  useNewActionsDropdown: PropTypes.bool,
  showConfigureColumnsBtn: PropTypes.bool,
};

export default I18nHOC({ resource: ["common"] })(FeedList);
