import client from "apolloClient";
import {
  getOrganizationPointSetQuery,
  getSubjectBasedCoursesQuery,
  getSubjectDetailsQuery,
} from "./ScoreCategoriesQueries";

export const getSubjectBasedCoursesFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSubjectBasedCoursesQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getSubjectDetailsFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSubjectDetailsQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};

export const getOrganizationPointSetFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationPointSetQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "node", {});
};
