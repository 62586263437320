export const getStringFromArray = ({
  nameArray,
  andProps,
  moreText = "others",
  andText = "and",
}) => {
  let nameString = nameArray[0];
  const len = nameArray.length;

  if (!!andProps && len > andProps) {
    for (let i = 1; i < len; i++) {
      if (i < andProps) {
        nameString = `${nameString} , ${nameArray[i]}`; //  nameString + ',' + nameArray[i]
      } else if (i == len - 1) {
        nameString = `${nameString} ${andText} ${nameArray[i]}`; // nameString + ' and ' + nameArray[i]
      } else {
        nameString = `${nameString} ${andText} ${len - andProps} ${moreText}`;
        break;
      }
    }
  } else {
    for (let i = 1; i < len; i++) {
      if (i == len - 1) {
        nameString = `${nameString} ${andText} ${nameArray[i]}`; // nameString + ' and ' + nameArray[i]
      } else {
        nameString = `${nameString} , ${nameArray[i]}`; //nameString + ',' + nameArray[i]
      }
    }
  }

  return nameString;
};
