import React from "react";
import { colors } from "Constants";

function getHighlightedText({ text, highlight }) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {_.map(parts, (part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { color: colors.blue29 }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
}

export default getHighlightedText;
