import React, { Fragment, PureComponent } from "react";
import _ from "lodash";
import ACLStore from "lib/aclStore";
import classes from "./ResponsesComponent.scss";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { I18nHOC, FilterDropdown } from "UIComponents";
import { colors } from "Constants";
import PropTypes from "prop-types";
import ResponsesEdit from "./ResponsesEdit";
import ResponsesView from "./ResponsesView";
import { getUserCoursesFilters } from "Course/modules/CourseModule";
import { getUserCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getCurrentAcademicYear } from "modules/Services";

const getPermittedCourseList = ({
  userInfo,
  unitPlanCourses,
  currentCurriculumProgramType,
  currentCurriculumProgramId,
  organizationCurriculumPrograms,
  selectedAcademicYear,
}) => {
  const userId = userInfo.id;
  const userEntityType = userInfo.userEntityType;
  const {
    isCurrentAcademicYear = true,
    id: academicYear = "",
  } = selectedAcademicYear;

  const courseFilters = {
    ...getUserCoursesFilters({
      currentCurriculumProgramType,
      currentCurriculumProgramId,
      organizationCurriculumPrograms,
    }),
    ...(!isCurrentAcademicYear && { academicYear }),
  };

  const data = getUserCoursesFromCache({
    id: userId,
    type: userEntityType,
    filters: courseFilters,
  });

  const allCourses = _.get(data, "courses", []);
  const courses = _.filter(allCourses, { isDemo: false });

  // List of courses which the user is part of and the current
  // unit plan is shared with.
  return _.intersectionBy(unitPlanCourses, courses, "id");
};

const getPermittedCourseListMemoized = _.memoize(
  params => getPermittedCourseList(params),
  params => JSON.stringify(params.unitPlanCourses)
);

const styles = {
  courseButtonDropDownContainer: {
    top: 48,
    width: "100%",
    minWidth: 200,
    maxHeight: 300,
    overflowY: "auto",
  },
  filterBox: {
    width: "100%",
    height: 48,
    borderRadius: 4,
    flex: 1,
  },
  filterText: {
    color: colors.gray48,
    fontSize: "1.6rem",
    lineHeight: "2.6rem",
  },
  buttonParent: { flex: 1 },
  parentContainer: { flex: 1 },
};

class ResponsesComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedCourseIds: [],
    };
  }

  componentDidMount() {
    const { currentCourseId } = this.props;

    if (currentCourseId && this.checkCourseFilterVisibility()) {
      this.setState({
        selectedCourseIds: [currentCourseId],
      });
    }
  }

  getPermittedCourseListForFilter = () => {
    const { permittedCourses } = this.props;
    return _.map(permittedCourses, course => ({
      value: course.id,
      label: course.title,
    }));
  };

  updateSelectedCourses = ({ selectedCourseIds }) => {
    this.setState({ selectedCourseIds });
  };

  customRef = ref => {
    const { customRef } = this.props;
    customRef(ref);
  };

  checkCourseFilterVisibility = () => {
    const { userInfo, isReflectionField, showFilterOnResponses } = this.props;
    const { user_type: userType } = userInfo;

    return (
      ACLStore.can("TeacherPortal:UnitPlanSectionSharing") &&
      isReflectionField &&
      showFilterOnResponses &&
      _.isEqual(userType, "staff")
    );
  };

  render() {
    const { t, mode, responses = [] } = this.props;
    const { selectedCourseIds } = this.state;

    const courseList = this.getPermittedCourseListForFilter();
    const showCourseFilter = this.checkCourseFilterVisibility();

    // HACK: This prop is for withLoader which handles case for
    // jitter (PLN: 152) caused due to default styling and filter.
    // Basically it handles the case when filter is present by giving
    // a certain styling.
    const loadingContainerStyle = showCourseFilter
      ? { marginTop: 150, marginBottom: 150 }
      : { marginTop: 50, marginBottom: 50 };

    return mode == "edit" ? (
      <Fragment>
        {showCourseFilter && (
          <div className={classes.filterContainer}>
            <FilterDropdown
              options={courseList}
              showAllOption={false}
              labelElement={`${t("common:showing_with_label", {
                label: t("common:class_plural"),
              })}: `}
              name={"selectedCourseIds"}
              value={selectedCourseIds}
              filterBoxStyle={styles.filterBox}
              filterTextStyle={styles.filterText}
              buttonParentStyle={styles.buttonParent}
              parentContainerStyle={styles.parentContainer}
              allOptionLabel={t("common:all_with_label", {
                label: t("common:course_plural"),
              })}
              noOptionLabel={t("common:all_with_label", {
                label: t("common:course_plural"),
              })}
              buttonDropDownContainerStyle={
                styles.courseButtonDropDownContainer
              }
              updateInputField={this.updateSelectedCourses}
            />
          </div>
        )}
        <ResponsesEdit
          customRef={this.customRef}
          selectedCourseIds={selectedCourseIds}
          {..._.omit(this.props, [])}
          showCourseFilter={showCourseFilter}
          loadingContainerStyle={loadingContainerStyle}
        />
      </Fragment>
    ) : (
      <ResponsesView value={responses} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { options, responses, unitPlanCourses } = ownProps;
  const {
    currentCurriculumProgramType,
    currentCurriculumProgram,
    organizationCurriculumPrograms,
  } = state.platform;
  const userInfo = state.login.userInfo;

  const responseNodes = _.map(
    _.get(responses, "edges", []),
    _.iteratee("node")
  );

  const currentCourseId = _.get(
    state.teacher,
    "selected_class.selected_course",
    null
  );
  const organizationId = state.login.userInfo.org_id;
  const currentAcademicYear = getCurrentAcademicYear({
    organizationId: organizationId,
  });

  const selectedAcademicYear = _.get(
    state,
    "platform.academicYearSelected",
    currentAcademicYear
  );

  const permittedCourses = !_.isEmpty(currentCourseId)
    ? getPermittedCourseListMemoized({
        userInfo,
        unitPlanCourses,
        currentCurriculumProgramType,
        currentCurriculumProgramId: currentCurriculumProgram.id,
        organizationCurriculumPrograms,
        selectedAcademicYear,
      })
    : unitPlanCourses;

  return {
    currentCourseId,
    permittedCourses,
    responses: responseNodes,
    options: JSON.parse(options),
    userInfo: state.login.userInfo,
    localResponsesObject: state.planner.responses,
  };
};

const ResponsesComponentWrapper = compose(
  I18nHOC({ resource: ["unitPlan", "common"] }),
  connect(mapStateToProps, null)
)(ResponsesComponent);

ResponsesComponentWrapper.propTypes = {
  label: PropTypes.string,
  subText: PropTypes.string,
  labelStyle: PropTypes.object,
  labelComponent: PropTypes.element,
  responses: PropTypes.object,
  errorStyle: PropTypes.object,
  customValidation: PropTypes.func,
  error: PropTypes.string,
  mode: PropTypes.string,
};

ResponsesComponentWrapper.defaultProps = {
  label: "",
  subText: "",
  labelStyle: {},
  mode: "edit",
};

export default ResponsesComponentWrapper;
