import React, { Component } from "react";
import classes from "./MediaModal.scss";
import {
  ImageViewer,
  VideoViewer,
  UIModal,
  AudioViewer,
  AudioPlayer,
} from "UIComponents";
import FaClose from "react-icons/lib/fa/close";
import PropTypes from "prop-types";
import {
  hasAudioNote,
  addHttpsInUrl,
  canOpenOfficeFileInPdftron,
  getStringAutoLinks,
  getWorkbookModeForMediaModal,
  PDFTRON_VIEWER_MODE_TYPES,
  canViewPdfInPdftron,
} from "Utils";
import {
  MS_FILE_VIEWER_SUPPORTED_MIMETYPE,
  GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE,
  MAX_FILE_SIZE_VIEWER_LIMIT,
  MS_FILE_VIEWER_URL,
  GOOGLE_FILE_VIEWER_URL,
} from "Constants";
import { SNPv2_VISITOR_PORTAL_PERM_GROUP } from "Constants/permissionConstants";
import { downloadFromUrl, openLinkInNewTab } from "modules/Services";
import { connect } from "react-redux";
import { Workbook, PdfTronViewer } from "AppComponents";
import ACLStore from "lib/aclStore";

class MediaModal extends Component {
  constructor(props) {
    super(props);

    this.checkAndOpenInNewTab();
  }

  checkAndOpenInNewTab = () => {
    const {
      openLinkInNewTab,
      downloadFromUrl,
      attachments,
      toggleDownloadBeginOverlay,
      mode,
    } = this.props;
    const attachment = _.first(attachments);

    const type = _.get(attachment, "type", "");
    const mimeType = _.get(attachment, "mimeType", "");
    let url = _.get(attachment, "url", "");

    const size = _.get(attachment, "metadata.size", 0);

    if (type == "FILE" || type == "LINK") {
      let showOpenInView = true;
      let shouldOpenInPDFTron = false;

      if (type == "LINK") {
        url = addHttpsInUrl(url);
      } else if (type == "FILE") {
        // for google & office docs, if feature flag is enabled, use google viewer
        if (
          _.includes(
            [
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.google-apps.document",
            ],
            mimeType
          ) &&
          ACLStore.can("FeatureFlag:GoogleViewerSupport")
        ) {
          url = `${GOOGLE_FILE_VIEWER_URL}${url}`;
        } else if (mimeType == "application/pdf") {
          // check to open pdftron for pdf files
          shouldOpenInPDFTron = mode === "edit" ? true : canViewPdfInPdftron();
        } else if (
          _.includes(attachment.url, "blob:") ||
          !(
            _.includes(GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE, mimeType) ||
            _.includes(MS_FILE_VIEWER_SUPPORTED_MIMETYPE, mimeType)
          ) ||
          size >= MAX_FILE_SIZE_VIEWER_LIMIT
        ) {
          showOpenInView = false;
        } else if (
          _.includes(GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE, mimeType)
        ) {
          url = `${GOOGLE_FILE_VIEWER_URL}${url}`;
        } else if (_.includes(MS_FILE_VIEWER_SUPPORTED_MIMETYPE, mimeType)) {
          url = `${MS_FILE_VIEWER_URL}${url}`;
        }

        // check to open pdftron for msoffice files
        if (
          mimeType !== "application/pdf" &&
          (_.includes(GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE, mimeType) ||
            _.includes(MS_FILE_VIEWER_SUPPORTED_MIMETYPE, mimeType))
        ) {
          shouldOpenInPDFTron = canOpenOfficeFileInPdftron();
        }
      }

      if (!shouldOpenInPDFTron) {
        if (showOpenInView) {
          openLinkInNewTab({ url });
        } else {
          downloadFromUrl({
            attachment,
            shouldAskForSave: true,
          });
          toggleDownloadBeginOverlay?.();
        }
        this.closeMediaViewer();
      }
    }

    /**--Snp visitors can not open workbook as they do not have feature flags.
     * So, for them open workbook in new tab using public url-- */
    const isVisitor = ACLStore.can(SNPv2_VISITOR_PORTAL_PERM_GROUP);

    if (isVisitor && type === "WORKBOOK") {
      const url = _.get(attachment, "metadata.publicUrl", "");
      if (url) {
        openLinkInNewTab({ url });
      }
      this.closeMediaViewer();
    }
  };

  closeMediaViewer = event => {
    const { toggleMediaModal } = this.props;
    if (event) {
      event.stopPropagation();
    }
    if (toggleMediaModal) {
      toggleMediaModal(false);
    }
  };

  getMediaRender = attachment => {
    let mediaToRender = null;
    const {
      mode,
      onWorkbookUpdateSuccessfully,
      userInfo,
      showPdfTronfeedback,
      showPdfTronEditButton,
    } = this.props;
    const imageUrl = attachment.thumbUrl ? attachment.thumbUrl : attachment.url;

    switch (attachment.type) {
      case "IMAGE":
        mediaToRender = (
          <ImageViewer
            allowDrag={true}
            activeIndex={this.props.activeIndex}
            attachment={attachment}
            closeImageViewer={this.closeMediaViewer}
            attachmentsArray={_.filter(
              this.props.attachments,
              attachment => attachment.type == "IMAGE"
            )}
          />
        );
        break;
      case "VIDEO":
        mediaToRender = (
          <VideoViewer
            isHLSUrl={attachment.streamUrl}
            videoUrl={
              attachment.streamUrl ? attachment.streamUrl : attachment.url
            }
            closeVideoViewer={this.closeMediaViewer}
          />
        );
        break;
      case "AUDIO":
        mediaToRender = (
          <AudioViewer
            audioUrl={attachment.url}
            closeViewer={this.closeMediaViewer}
          />
        );
        break;
      case "LINK":
        mediaToRender = null;
        break;
      case "FILE":
        mediaToRender = (
          <PdfTronViewer
            file={attachment}
            key={attachment.id}
            shouldShowGiveFeedbackButton={
              showPdfTronEditButton ? showPdfTronfeedback : false
            }
            closeViewer={this.closeMediaViewer}
            pdftronViewerMode={
              showPdfTronEditButton
                ? PDFTRON_VIEWER_MODE_TYPES["STUDENT_EDITOR"]
                : PDFTRON_VIEWER_MODE_TYPES["VIEWER"]
            }
            annotationUserInfo={userInfo}
          />
        );
        break;
      case "NOTE": {
        const noteHeight = 360 - 48;
        const numOfLines = Math.floor(noteHeight / 22);
        mediaToRender = (
          <div className={classes.imageContainer}>
            <div className={classes.topPanel}>
              <div className={classes.crossSvg} onClick={this.closeMediaViewer}>
                <FaClose
                  size={20}
                  color="white"
                  className={classes.closeIcon}
                />
              </div>
            </div>
            <div className={classes.noteView}>
              {/* <div className={classes.noteViewWrapper}>{attachment.title}</div> */}
              <div className={classes.noteLinesContainer}>
                <div
                  className={classes.noteViewWrapper}
                  dangerouslySetInnerHTML={{
                    __html: getStringAutoLinks(_.get(attachment, "title")),
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
        break;
      }
      default:
        break;
    }
    return mediaToRender;
  };

  onDiscardDirectly = () => {
    this.closeMediaViewer();
  };

  onAfterDiscardDirectly = () => {
    const { onWorkbookDiscardDirectly } = this.props;
    if (onWorkbookDiscardDirectly) {
      onWorkbookDiscardDirectly();
    }
  };

  render() {
    const {
      showWorkBook,
      attachments,
      workbookMode,
      onWorkbookUpdateSuccessfully,
      mode,
      updateAttachments,
    } = this.props;

    const attachment = attachments ? attachments[0] : null;
    const renderMedia = this.getMediaRender(attachment);
    const isAudio = hasAudioNote({ attachments });
    const attachmentType = _.get(attachment, "type", "");
    const mimeType = _.get(attachment, "mimeType", "");
    return (showWorkBook && attachmentType === "IMAGE") ||
      attachmentType === "WORKBOOK" ? (
      <Workbook
        attachment={attachment}
        workbookMode={getWorkbookModeForMediaModal({
          attachmentType,
          mode,
          workbookMode,
        })}
        onUpdateSuccessfully={onWorkbookUpdateSuccessfully}
        onDiscardDirectly={this.onDiscardDirectly}
        onAfterDiscardDirectly={this.onAfterDiscardDirectly}
        updateAttachments={updateAttachments}
      />
    ) : (
      <UIModal
        isOpen={true}
        isFullScreen={true}
        modalContent={classes.modalContent}
        overlayContainer={classes.overlayContainer}
        onRequestClose={this.closeMediaViewer}
      >
        {renderMedia}
        {isAudio &&
          !(
            _.isEqual("application/pdf", mimeType) && canViewPdfInPdftron()
          ) && (
            <div className={classes.audioAttachment}>
              <AudioPlayer
                attachment={this.props.attachments[1]}
                mediaType={"secondary"}
              ></AudioPlayer>
            </div>
          )}
      </UIModal>
    );
  }
}

MediaModal.propTypes = {
  toggleMediaModal: PropTypes.func,
  attachments: PropTypes.array,
};

MediaModal.defaultProps = {
  attachments: [],
};
const mapStateToProps = (state, ownProps) => {
  const userInfo = _.get(state, "login.userInfo", "");
  return {
    userInfo,
  };
};

const mapActionCreators = {
  downloadFromUrl,
  openLinkInNewTab,
};

export default connect(mapStateToProps, mapActionCreators)(MediaModal);
