import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./CommentBox.scss";
import { getCommentsOfNodeFromCache } from "modules/CommonGraphqlHelpers";
import { getCommentOfNodeQuery } from "modules/CommonQuery";
import CommentPane from "./CommentPane";
import {
  updateLocalComment,
  createComment,
  updateComment,
  deleteComment,
  markCommentRead,
} from "modules/Services";
import { withPusherBind } from "UIComponents";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { setToastMsg } from "Login/modules/LoginModule";
import ACLStore from "lib/aclStore";
import PropTypes from "prop-types";
import { goToCommunityRoute } from "modules/NavigationModule";
import { getBasicInfo } from "Community/modules/utils";

class CommentBox extends React.Component {
  componentDidMount = () => {
    this.props.updateLocalComment({
      id: this.props.nodeId,
      commentData: { label: "", attachments: [] },
    });
  };
  updateLocalComment = params => {
    const { createCommentData, nodeId } = this.props;
    const commentData = _.get(createCommentData, [nodeId], {});
    const updatedCommentData = {
      ...commentData,
      ...params,
    };
    this.props.updateLocalComment({
      id: nodeId,
      commentData: updatedCommentData,
    });
  };

  markCommentRead = () => {
    const {
      nodeId,
      type,
      parentType,
      commentsKey,
      isCommunity,
      isCommentV2 = false, //Projects uses commentV2 for all their field comments instead of normal comments
    } = this.props;
    this.props.markCommentRead({
      nodeId,
      type,
      parentType,
      commentsKey,
      isCommunity,
      isCommentV2,
    });
  };

  createComment = async ({
    parentMessageId,
    message,
    itemType,
    replyItemId,
  } = {}) => {
    const {
      nodeId,
      type,
      parentType,
      unitPlanId,
      collaborators,
      commentsKey,
      getCommentOfNode,
    } = this.props;
    const entityName = _.get(getCommentOfNode, "node.title.value");
    return await this.props.createComment({
      nodeId,
      type,
      parentType,
      collaborators,
      commentsKey,
      parentMessageId,
      message,
      itemType,
      entityName,
      replyItemId,
    });
  };

  updateComment = async ({ comment_id, comment, attachments }) => {
    const { collaborators, comments, commentsKey } = this.props;
    const commentDetails = _.get(
      _.find(comments, ({ node: { id } }) => {
        return comment_id == id;
      }),
      "node",
      {}
    );

    await this.props.updateComment({
      comment_id,
      comment,
      collaborators,
      commentDetails,
      attachments,
      commentsKey,
    });
  };

  deleteComment = ({ comment_id, itemType, parentMessageId }) => {
    const {
      nodeId,
      type,
      parentType,
      commentsKey,
      getCommentOfNode,
    } = this.props;
    const entityName = _.get(getCommentOfNode, "node.title.value");
    this.props.deleteComment({
      nodeId,
      comment_id,
      type,
      parentType,
      commentsKey,
      itemType,
      parentMessageId,
      entityName,
    });
  };

  updateCommentPaneRef = ref => {
    this.commentPaneRef = ref;
  };

  render() {
    const {
      comments,
      createCommentData,
      onClose,
      userInfo,
      totalCounts,
      isData,
      isLoading,
      hasNextPage,
      headerStyle,
      feedStyle,
      commentBoxContainerStyle,
      commentContainerStyle,
      fetchMoreFeed,
      getCommentOfNode,
      nodeId,
      renderHeaderComponent,
      collaborators,
      disableTagging,
      sendOnEnter,
      commentItemContainerStyle,
      showProfileAvatar,
      scrollable,
      hideEmptyView,
      isNodeDeleted,
      setToastMsg,
      deleteOtherComment,
      canCreateComment,
      parentType,
      commentPaneContainerStyle,
      seeMoreContainerStyle,
      allowReply,
      theme,
      goToCommunityRoute,
      isCommunity,
      canCreateAudioComment,
      commentItemStyle,
      nagivateToProfile,
      scrollToPosition,
      sendButtonText,
      commentBoxInnerWrapper,
      bottomButtonsContainer,
      sendButtonStyle,
      unveilMaxHeight,
      isPublicAccess,
      isCurrentAcademicYearSelected,
      isRightPaneChat,
      mode, // disabling liking post and comment actions in view mode
    } = this.props;

    return (
      <div className={classes.container} style={commentContainerStyle}>
        <CommentPane
          ref={this.updateCommentPaneRef}
          deleteOtherComment={deleteOtherComment}
          markCommentRead={this.markCommentRead}
          renderHeaderComponent={renderHeaderComponent}
          headerStyle={headerStyle}
          feedStyle={feedStyle}
          commentBoxContainerStyle={commentBoxContainerStyle}
          bottomButtonsContainer={bottomButtonsContainer}
          comments={comments}
          createCommentData={createCommentData[nodeId] || {}}
          onClose={onClose}
          userInfo={userInfo}
          fetchMoreFeed={fetchMoreFeed}
          totalCounts={totalCounts}
          isData={isData}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          scrollable={scrollable}
          getCommentData={getCommentOfNode}
          updateLocalComment={this.updateLocalComment}
          createComment={this.createComment}
          updateComment={this.updateComment}
          deleteComment={this.deleteComment}
          collaborators={collaborators}
          disableTagging={disableTagging}
          sendOnEnter={sendOnEnter}
          commentItemContainerStyle={commentItemContainerStyle}
          showProfileAvatar={showProfileAvatar}
          hideEmptyView={hideEmptyView}
          isNodeDeleted={isNodeDeleted}
          setToastMsg={setToastMsg}
          canCreateComment={canCreateComment}
          nodeId={nodeId}
          parentType={parentType}
          commentPaneContainerStyle={commentPaneContainerStyle}
          seeMoreContainerStyle={seeMoreContainerStyle}
          allowReply={allowReply}
          theme={theme}
          goToCommunityRoute={goToCommunityRoute}
          isCommunity={isCommunity}
          canCreateAudioComment={canCreateAudioComment}
          commentItemStyle={commentItemStyle}
          nagivateToProfile={nagivateToProfile}
          scrollToPosition={scrollToPosition}
          sendButtonText={sendButtonText}
          commentBoxInnerWrapper={commentBoxInnerWrapper}
          sendButtonStyle={sendButtonStyle}
          unveilMaxHeight={unveilMaxHeight}
          isPublicAccess={isPublicAccess}
          isCurrentAcademicYearSelected={isCurrentAcademicYearSelected}
          isRightPaneChat={isRightPaneChat}
          mode={mode}
        />
      </div>
    );
  }
}

const mapActionCreators = {
  updateLocalComment,
  createComment,
  deleteComment,
  updateComment,
  markCommentRead,
  setToastMsg,
  goToCommunityRoute,
};

const mapStateToProps = (state, ownProps) => {
  const unitPlanId =
    _.get(state.planner, "unitPlanData.unitPlanId", "") || ownProps.unitPlanId;

  const userInfo = state.login.userInfo;

  const canCreateAudioComment =
    _.get(ownProps, "canCreateAudioComment", false) &&
    ACLStore.can("Common:AddAudioComment");

  const collaborators =
    unitPlanId || ownProps.collaborators
      ? getCollaborators({
          collaboratorValue: ownProps.collaborators,
          unitPlanId,
          userInfo,
        })
      : [];
  const isCommunity = state.login.activeTab == "community";
  const { isPublicAccess } = getBasicInfo(state);
  return {
    unitPlanId,
    collaborators,
    createCommentData: state.app_services.createComment,
    userInfo,
    isCommunity,
    commentsKey: isCommunity ? "communityComments" : "comments",
    canCreateAudioComment,
    isPublicAccess,
  };
};

const getCollaborators = ({ collaboratorValue, unitPlanId, userInfo }) => {
  const unitPlanData = unitPlanId
    ? getUnitPlanDetailsFromCache(unitPlanId)
    : {};

  const allCollaborators = collaboratorValue
    ? collaboratorValue
    : _.get(unitPlanData, "collaborators", []);
  const collaborators = _.filter(
    _.map(_.get(allCollaborators, "edges"), item => {
      return item.node;
    }),
    item => item.id != userInfo.id
  );

  collaborators.push({
    id: "all",
    firstName: "common:all",
    lastName: "",
    profileImage: "",
  });

  return collaborators;
};

const ComposedCommentBox = compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getCommentOfNodeQuery, {
    name: "getCommentOfNode",
    options: ({ nodeId, type, firstCount, isCommunity }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: nodeId,
        type: type,
        first: firstCount,
        isCommunity,
      },
    }),
    props({
      getCommentOfNode,
      ownProps: {
        nodeId,
        type,
        firstCount,
        parentType,
        markCommentRead,
        isCommunity,
        commentsKey,
      },
    }) {
      const nodeFields = getCommentsOfNodeFromCache({
        nodeId: nodeId,
        type,
        isCommunity,
      });
      const comments = _.get(nodeFields, commentsKey, []);
      const isNodeDeleted = _.get(nodeFields, "isDeleted", false);
      return {
        getCommentOfNode,
        onPusherEventTriggered: params => {
          if (nodeId == params.parentId && parentType == params.parentType) {
            getCommentOfNode.refetch();
            markCommentRead({
              nodeId,
              type,
              parentType,
              isCommunity,
              commentsKey,
            });
          }
        },
        isNodeDeleted,
        totalCounts: _.get(comments, "totalCount", 0),
        comments: [..._.get(comments, "edges", [])].reverse(),
        hasNextPage: _.get(comments, "pageInfo.hasNextPage", false),
        isData: !_.isEmpty(nodeFields),
        isLoading:
          getCommentOfNode["networkStatus"] == 1 ||
          getCommentOfNode["networkStatus"] == 2,
        fetchMoreFeed: () => {
          const endCursor = _.get(comments, "pageInfo.endCursor", null);

          return getCommentOfNode.fetchMore({
            query: getCommentOfNodeQuery,
            variables: {
              after: endCursor,
              id: nodeId,
              type,
              first: firstCount,
              isCommunity,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousField = previousResult.node;
              const newField = fetchMoreResult.node;

              return {
                ...previousResult,
                node: {
                  ...previousField,
                  [commentsKey]: {
                    ...previousField[commentsKey],
                    pageInfo: newField[commentsKey].pageInfo,
                    edges: [
                      ...previousField[commentsKey].edges,
                      ...newField[commentsKey].edges,
                    ],
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  withPusherBind(["nodeCommentFeedUpdate"])
)(CommentBox);

ComposedCommentBox.defaultProps = {
  deleteOtherComment: false,
  canCreateAudioComment: true,
  unveilMaxHeight: 104,
  mode: "edit",
};

ComposedCommentBox.propTypes = {
  deleteOtherComment: PropTypes.bool,
};

export default ComposedCommentBox;
