import React from "react";
import PropTypes from "prop-types";
import { colors } from "Constants";

const PrintIcon = props => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      width={props.width}
      height={props.height}
    >
      <g>
        <path
          d="M15,3h-2V2l0,0c0-0.6-0.4-1-1-1H4C3.4,1,3,1.4,3,2v1H1C0.4,3,0,3.4,0,4v5c0,0.6,0.4,1,1,1h2v4c0,0.6,0.4,1,1,1h8l0,0
       c0.6,0,1-0.4,1-1v-4h2l0,0c0.6,0,1-0.4,1-1V4l0,0C16,3.5,15.6,3,15,3z M4,2h8v1H4V2z M12,14H4v-4V8h8v2V14z M15,9h-2V8l0,0V7h-1
       l0,0H4l0,0H3v1v1H1V4h3h8h3V9z"
          fill={props.fill && props.fill}
        />
        <path
          d="M10.5,9.5h-5C5.2,9.5,5,9.7,5,10s0.2,0.5,0.5,0.5h5c0.3,0,0.5-0.2,0.5-0.5S10.8,9.5,10.5,9.5z"
          fill={props.fill && props.fill}
        />
        <path
          d="M10.5,11.8h-5C5.2,11.8,5,12,5,12.3s0.2,0.5,0.5,0.5h5c0.3,0,0.5-0.2,0.5-0.5S10.8,11.8,10.5,11.8z"
          fill={props.fill && props.fill}
        />
      </g>
    </svg>
  );
};

PrintIcon.defaultProps = {
  height: 20,
  width: 20,
};
PrintIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default PrintIcon;
