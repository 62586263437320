import React from "react";
import classes from "./ReorderImages.scss";
import {
  UIModal,
  UIButton,
  DropzoneComponent,
  NoDataComponent,
  I18nHOC,
} from "UIComponents";
import { connect } from "react-redux";
import { CancelIcon, AddIcon, MoveIcon } from "SvgComponents";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { colors } from "Constants";
import { getRethumbUrl } from "Utils";
import arrayMove from "array-move";
import ScreenPanel from "../../ScreenPanel";
import { compose } from "react-apollo";
import { setToastMsg } from "Login/modules/LoginModule";

const isLocalUrl = url => {
  return _.includes(url, "blob");
};

const getRethumb = imageUrl => {
  const rethumpUrl = isLocalUrl(imageUrl)
    ? imageUrl
    : imageUrl
    ? getRethumbUrl({
        width: 256,
        height: 256,
        imageUrl: imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${rethumpUrl})`;
};

const SortableItem = SortableElement(({ item, index, onCancelItemClick }) => {
  return (
    <div
      className={classes.itemContainer}
      style={{
        backgroundImage: getRethumb(item.url),
      }}
    >
      <div className={classes.overlayContainer}>
        <div className={classes.dragIconContainer}>
          <MoveIcon fill={colors.gray13} width={16} height={16} />
        </div>
      </div>
      <div
        className={classes.itemCloseIcon}
        onClick={() => onCancelItemClick(item)}
      >
        <CancelIcon width={7} height={7} />
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({ items, onCancelItemClick }) => {
  return (
    <div className={classes.imagesContainer}>
      {_.map(items, (item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          item={item}
          onCancelItemClick={onCancelItemClick}
        />
      ))}
    </div>
  );
});

class ReoderImagesModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      postDetails: { attachments = [] },
    } = props.screenPanelProps;
    this.state = { attachments };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      attachments: arrayMove(this.state.attachments, oldIndex, newIndex),
    });
  };
  updateAttachments = attachment => {
    this.setState({ attachments: [...this.state.attachments, attachment] });
  };

  onDoneClick = () => {
    const {
      screenPanelProps: { updatePost, goToStep },
      setToastMsg,
    } = this.props;
    const { attachments } = this.state;
    if (attachments.length > 10) {
      setToastMsg("toastMsgs:can_select_max_10_images");
    } else {
      goToStep("POST_DETAIL");
      updatePost({ attachments: attachments });
    }
  };

  onCancelItemClick = item => {
    this.setState({
      attachments: _.filter(
        this.state.attachments,
        attachment => attachment.url != item.url
      ),
    });
  };

  getFooter = () => {
    const { attachments } = this.state;
    const { t } = this.props;
    return (
      <div className={classes.footer}>
        <div className={classes.dropzoneContainer}>
          <DropzoneComponent
            multiple={true}
            parentId={null}
            parentType={"POST"}
            renderComponent={
              <div className={classes.addMoreContainer}>
                <div className={classes.addIconContainer}>
                  <AddIcon width={6} height={6} fill={colors.blue29} />
                </div>
                <div className={classes.addMoreText}>
                  {t("journal:add_more_images")}
                </div>
              </div>
            }
            onDropContent={this.updateAttachments}
            shouldUpload={false}
            accept={["image/jpeg", "image/png", "image/jpg"]}
          />
        </div>
        <UIButton
          size="sm"
          color="pink"
          onClick={this.onDoneClick}
          isDisabled={_.get(attachments, "length", 0) == 0}
        >
          {t("common:done")}
        </UIButton>
      </div>
    );
  };

  render() {
    const { attachments } = this.state;
    const { t, screenPanelProps = {} } = this.props;
    return (
      <ScreenPanel {...screenPanelProps} footerComponent={this.getFooter()}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.heading}>{t("journal:reorder_images")}</div>
            <div className={classes.headerSubText}>
              {t("journal:hold_drag_images")}
            </div>
          </div>

          {_.get(attachments, "length", 0) > 0 ? (
            <SortableList
              items={attachments}
              onSortEnd={this.onSortEnd}
              axis={"xy"}
              onCancelItemClick={this.onCancelItemClick}
              helperClass={classes.sortableHelper}
              distance={1}
            />
          ) : (
            <NoDataComponent
              emptyText={t("common:no_with_label", {
                label: t("common:image_plural"),
              })}
              isSearch={false}
              emptyHeaderText={""}
            />
          )}
        </div>
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {
  setToastMsg,
};
const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common", "journal"] })
)(ReoderImagesModal);
