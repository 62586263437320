export const nodeEditorConfigProp = { showHeader: false };

export const getDropdownOptions = ({ t }) => {
  return [
    {
      key: "EDIT",
      label: t("common:edit"),
    },
    {
      key: "DELETE",
      isDestructive: true,
      label: t("common:delete"),
    },
  ];
};
