import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import classes from "./ParentProgressReport.scss";
import { goToRelativeRoute } from "modules/Services";
import TopHeader from "./TopHeader";
import { I18nHOC, withLoader } from "UIComponents";
import ReportItem from "./ReportItem";
import { getStudentProgressReportFeedQuery } from "../modules/ParentProgressReportQueries";
import { getStudentProgressReportFeedFromCache } from "../modules/ParentProgressReportGraphqlHelpers";
import { withPermission } from "lib/PermissionAware";
import { NoDataComponent } from "UIComponents";
import { CURRICULUM_TYPE_PYP } from "Constants/stringConstants";

const ProgressReport = props => {
  const { t, goToRelativeRoute, publishedProgressReports } = props;
  const progressReportsCount = _.size(publishedProgressReports);

  const renderReports = () => {
    return (
      <div className={classes.reportContainer}>
        {progressReportsCount && (
          <div className={classes.count}>
            {t("progressReport:progress_report_count", {
              count: progressReportsCount,
            })}
          </div>
        )}
        {_.map(publishedProgressReports, (report, index) => {
          const studentProgressReportId = _.get(
            report,
            "studentProgressReport.id",
            ""
          );
          const publishedProgressReportId = _.get(report, "id", "");
          const updatedAt = _.get(
            report,
            "studentProgressReport.updatedAt",
            ""
          );

          const curriculumProgramType = _.get(
            report,
            "curriculumProgram.type",
            CURRICULUM_TYPE_PYP
          );
          return (
            <ReportItem
              studentProgressReportId={studentProgressReportId}
              publishedProgressReportId={publishedProgressReportId}
              filteredNodes={report.filteredNodes}
              updatedAt={updatedAt}
              index={index}
              curriculumProgramType={curriculumProgramType}
            />
          );
        })}
      </div>
    );
  };

  const renderNoDataComp = () => {
    return (
      <div className={classes.noDataContainer}>
        <NoDataComponent
          emptyText={""}
          emptyHeaderText={t("progressReport:no_progress_reports")}
          isSearch={false}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.children ? (
        props.children
      ) : (
        <div className={classes.topContainer}>
          <TopHeader
            title={t("progressReport:progress_report_plural")}
            onGoBackClick={() => goToRelativeRoute({ route: "../" })}
            showToddleIcon={true}
          />
          {!progressReportsCount ? renderNoDataComp() : renderReports()}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = _.get(state, "login.userInfo", {});
  const userType = _.get(userInfo, "user_type");
  const selectedAcademicYear = _.get(
    state,
    "platform.academicYearSelected",
    {}
  );
  const { id: selectedAcademicYearId } = selectedAcademicYear;
  let id;
  switch (userType) {
    case "student": {
      id = userInfo.id;
      break;
    }
    case "parent": {
      id = userInfo.childID;
      break;
    }
  }
  const curriculumPrograms = state.platform.organizationCurriculumPrograms;
  const curriculumProgramType = state.platform.currentCurriculumProgramType;
  const curriculumProgramId = _.get(
    _.find(curriculumPrograms, {
      type: curriculumProgramType,
    }),
    "id",
    null
  );

  return {
    userId: id,
    shouldCheckPermission: userType === "parent",
    showErrorComp: true,
    perm: "FamilyApp:ProgressReport",
    selectedAcademicYearId,
    curriculumProgramId,
    userType,
    curriculumProgramType,
  };
};

const mapActionCreators = {
  goToRelativeRoute,
};

export default compose(
  I18nHOC({
    resource: ["progressReport"],
  }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getStudentProgressReportFeedQuery, {
    name: "getStudentProgressReportFeed",
    options: ({
      userId,
      selectedAcademicYearId,
      curriculumProgramId,
      userType,
    }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        filters: {
          academicYear: selectedAcademicYearId,
          ...(userType == "student"
            ? { curriculumProgramIds: curriculumProgramId }
            : {}),
        },
      },
    }),
    props: ({
      getStudentProgressReportFeed,
      ownProps: {
        userId,
        selectedAcademicYearId,
        curriculumProgramId,
        userType,
      },
    }) => {
      const progressReportFeedFromCache = getStudentProgressReportFeedFromCache(
        {
          id: userId,
          filters: {
            academicYear: selectedAcademicYearId,
            ...(userType == "student"
              ? { curriculumProgramIds: curriculumProgramId }
              : {}),
          },
        }
      );

      const publishedProgressReports = _.get(
        progressReportFeedFromCache,
        "publishedProgressReports",
        []
      );

      return {
        isLoading:
          getStudentProgressReportFeed["networkStatus"] == 1 ||
          getStudentProgressReportFeed["networkStatus"] == 2,
        isData: !_.isEmpty(progressReportFeedFromCache),
        publishedProgressReports,
      };
    },
  }),
  withLoader,
  withPermission
)(ProgressReport);
