import React from "react";
import _ from "lodash";

import classes from "./PlanathonCategory.scss";
import ThemeCarousel from "./ThemeCarousel";
import { colors } from "Constants";
import classNames from "classnames";
import { CancelIcon } from "SvgComponents";
import { CategoryData } from "Planathon/data";
import { BAR_URL } from "Constants";
import { I18nHOC } from "UIComponents";
class PlanathonCategory extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const {
      subHeading,
      image,
      heading,
      gradeDetails,
      themeDetails,
    } = CategoryData;
    return (
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <ThemeCarousel></ThemeCarousel>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.categoryNumber}>{t(heading)}</div>
          <div className={classes.middleDetails}>
            <div className={classes.themeGradeContainer}>
              <div
                className={classes.number}
                style={{ color: gradeDetails.color }}
              >
                {gradeDetails.count}
              </div>
              <div className={classes.detailContainer}>
                <div className={classes.detailLabel}>
                  {t(gradeDetails.label)}
                </div>
                <div className={classes.detailSubLabel}>
                  {t(gradeDetails.subTitle)}
                </div>
              </div>
            </div>
            <CancelIcon fill={colors.gray72} width={24} height={24} />
            <div className={classes.themeGradeContainer}>
              <div
                className={classes.number}
                style={{ color: themeDetails.color }}
              >
                {themeDetails.count}
              </div>
              <div className={classes.detailContainer}>
                <div className={classes.detailLabel}>
                  {t(themeDetails.label)}
                </div>
                <div className={classes.detailSubLabel}>
                  {t(themeDetails.subTitle)}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.strips}>
            <img src={BAR_URL}></img>
          </div>
          <div className={classes.subText}>{t(subHeading)}</div>
          <div className={classes.imageContainer}>
            <img src={image}></img>
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["planathon", "common"] })(
  PlanathonCategory
);
