import React from "react";
import classes from "./NotAddedEmptyView.scss";
import classNames from "classnames";
import { useI18n } from "Hooks";

const NotAddedEmptyView = ({ emptyText, inputStyle }) => {
  const { t } = useI18n(["common"]);

  const emptyTextStyle = classNames({
    [classes.emptyText]: true,
    "text-body-1": true,
  });
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={emptyTextStyle} style={{ ...inputStyle }}>
          {emptyText ? emptyText : t("common:not_added_yet")}
        </div>
      </div>
    </div>
  );
};

export default NotAddedEmptyView;
