import React from "react";

const POISvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 100 100">
      <g stroke="none" strokeWidth="1" fill={props.fill} fillRule="evenodd">
        <path d="M82.6643,68.7924 L82.6643,6.1214 L17.3063,6.1214 L17.3063,68.7924 C17.3063,71.5204 19.5253,73.7404 22.2533,73.7404 L37.8673,73.7404 L65.2773,73.7404 L77.7163,73.7404 C80.4443,73.7404 82.6643,71.5204 82.6643,68.7924 Z M92.9103,0.0004 C94.6003,0.0004 95.9703,1.3704 95.9703,3.0604 C95.9703,4.7504 94.6003,6.1214 92.9103,6.1214 L88.7853,6.1214 L88.7853,68.7924 C88.7853,74.8964 83.8203,79.8614 77.7163,79.8614 L69.1543,79.8614 L73.0533,96.2074 C73.4453,97.8514 72.4303,99.5014 70.7873,99.8944 C70.5483,99.9514 70.3093,99.9784 70.0733,99.9784 C68.6893,99.9784 67.4353,99.0334 67.0993,97.6274 L66.9293,96.9134 L62.8613,79.8614 L40.2773,79.8614 L36.1773,96.8744 L36.0053,97.5874 C35.6103,99.2294 33.9583,100.2424 32.3133,99.8454 C30.6703,99.4494 29.6593,97.7964 30.0553,96.1524 L33.9813,79.8614 L22.2533,79.8614 C16.1503,79.8614 11.1853,74.8964 11.1853,68.7924 L11.1853,6.1214 L7.0603,6.1214 C5.3703,6.1214 3.9993,4.7504 3.9993,3.0604 C3.9993,1.3704 5.3703,0.0004 7.0603,0.0004 L14.2453,0.0004 L85.7253,0.0004 L92.9103,0.0004 Z M34.1287,33.8598 C32.4387,33.8598 31.0677,32.4898 31.0677,30.7998 C31.0677,29.1098 32.4387,27.7388 34.1287,27.7388 L65.8417,27.7388 C67.5317,27.7388 68.9017,29.1098 68.9017,30.7998 C68.9017,32.4898 67.5317,33.8598 65.8417,33.8598 L34.1287,33.8598 Z M34.1287,52.122 C32.4387,52.122 31.0677,50.752 31.0677,49.062 C31.0677,47.372 32.4387,46.001 34.1287,46.001 L65.8417,46.001 C67.5317,46.001 68.9017,47.372 68.9017,49.062 C68.9017,50.752 67.5317,52.122 65.8417,52.122 L34.1287,52.122 Z" />
      </g>
    </svg>
  );
};

POISvg.defaultProps = {
  fill: "white",
  height: 20,
  width: 20,
};

export default POISvg;
