import gql from "graphql-tag";
import {
  customAttachmentFragment,
  evaluationCycleStandardFragments,
  snpDocumentCategoryFragment,
} from "./SnPFragments";

export const createSnPEvaluationCycleMutation = gql`
  mutation createSnPEvaluationCycle($input: [SnpEvaluationCycleCreateInputV2]) {
    platform {
      createSnpEvaluationCycleV2(input: $input) {
        id
      }
    }
  }
`;

export const updateSnPEvaluationCycleMutation = gql`
  mutation updateSnPEvaluationCycle(
    $updatedCycle: SnpEvaluationCycleUpdateInputV2
  ) {
    platform {
      updateSnpEvaluationCycleV2(input: $updatedCycle) {
        id
      }
    }
  }
`;

export const deleteSnPEvaluationCycleMutation = gql`
  mutation deleteSnPEvaluationCycle($id: ID!) {
    platform {
      deleteSnpEvaluationCycle(input: { id: $id })
    }
  }
`;

export const updateFirstExperienceMutation = gql`
  mutation updateFirstExperience($value: Boolean!) {
    platform {
      addUserData(input: { isSnpExplainerVideoAvailable: $value }) {
        id
        isSnpExplainerVideoAvailable
      }
    }
  }
`;

export const handleSnPEvidenceInPracticeMutation = gql`
  mutation handleSnPEvidenceInPractice(
    $cycleId: ID!
    $practiceId: ID!
    $removedEvidence: [ID!]
    $addedEvidence: [SnPEvidenceAddInput!]
    $evidenceStatus: SNP_EVIDENCE_STATUS_ENUM
  ) {
    platform {
      handleSnPEvidenceInPractice(
        input: {
          evaluationCycleId: $cycleId
          id: $practiceId
          removedEvidence: $removedEvidence
          addedEvidence: $addedEvidence
          evidenceStatus: $evidenceStatus
        }
      ) {
        id
      }
    }
  }
`;

export const updateDocumentCategoryMutation = gql`
  mutation updateSnpDocumentCategoryV2(
    $input: SnpDocumentCategoryUpdateInput!
  ) {
    platform {
      updateSnpDocumentCategoryV2(input: $input) {
        ...responseItem
      }
    }
  }
  ${snpDocumentCategoryFragment.responseDetails}
`;

export const createAttachmentMutation = gql`
  mutation createAttachment($input: [AttachmentInput!]) {
    platform {
      createAttachment(input: $input) {
        ...attachmentItem
      }
    }
  }
  ${customAttachmentFragment.attachment}
`;

export const updateStandardStatusMutation = gql`
  mutation updateSnpEvaluationCycleStandard(
    $standardId: ID!
    $status: SNP_STATUS_ENUM
  ) {
    platform {
      updateSnpEvaluationCycleStandard(
        input: { id: $standardId, status: $status }
      ) {
        ...standardItem
      }
    }
  }
  ${evaluationCycleStandardFragments.standardStatusDetails}
`;

export const createDocumentCategoryMutation = gql`
  mutation createDocumentCategory(
    $input: SnpDocumentCategoryCreateInput!
    $first: Int
    $after: String
    $orderBy: OrderByEnum
    $orderByDirection: OrderByDirectionEnum
  ) {
    platform {
      createSnpDocumentCategoryV2(input: $input) {
        ...snpDocumentCategoryItem
      }
    }
  }
  ${snpDocumentCategoryFragment.allDetails}
`;

export const deleteDocumentCategoryMutation = gql`
  mutation deleteDocumentCategory($input: DeleteInput!) {
    platform {
      deleteSnpDocumentCategory(input: $input)
    }
  }
`;

export const updateVisitorsInCycleMutation = gql`
  mutation updateVisitorsInCycleMutation(
    $cycleId: ID!
    $addedVisitors: [ID!]
    $removedVisitors: [ID!]
  ) {
    platform {
      handleSnPEvaluationCycleVisitor(
        input: {
          evaluationCycleId: $cycleId
          addedVisitor: $addedVisitors
          removedVisitor: $removedVisitors
        }
      )
    }
  }
`;

export const handleSnPEvidenceInDocumentCategoryMutation = gql`
  mutation handleSnPEvidenceInDocumentCategory(
    $input: HandleSnPEvidenceInDocumentCategoryInput
  ) {
    platform {
      handleSnPEvidenceInDocumentCategory(input: $input) {
        id
      }
    }
  }
`;

export const tagSnpPracticesMutation = gql`
  mutation tagSnpPractices($input: TagSnPPracticeInput!) {
    platform {
      handleSnPPracticeTagging(input: $input) {
        id
      }
    }
  }
`;

export const updateSuggestedEvidenceViewStatusMutation = gql`
  mutation updateSuggestedEvidenceViewStatus(
    $staffId: ID!
    $practiceId: ID!
    $cycleId: ID!
  ) {
    platform {
      updateUserSnpEvidenceViewStatus(
        input: {
          userId: $staffId
          practiceId: $practiceId
          evaluationCycleId: $cycleId
        }
      )
    }
  }
`;

export const updateCategoryStatusMutation = gql`
  mutation updateCategoryStatus($input: SnpDocumentCategoryUpdateInput!) {
    platform {
      updateSnpDocumentCategoryV2(input: $input) {
        ...statusDetailsItem
      }
    }
  }
  ${snpDocumentCategoryFragment.statusDetails}
`;

export const updateSnpPracticeEvidenceStatusMutation = gql`
  mutation updateSnPPracticeEvidenceStatus(
    $input: SnPPracticeEvidenceStatusUpdateInput!
  ) {
    platform {
      updateSnPPracticeEvidenceStatus(input: $input) {
        id
      }
    }
  }
`;
