import { withAsyncRouteLoading } from "UIComponents";

import { injectReducer } from "store/reducers";

import ActionPlan from "./routes/ActionPlan";
import SelfStudy from "./routes/SelfStudy";
export default store => ({
  path: "cycleDetails/:cycleId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("SnP/modules/SnPModule").default;
            injectReducer(store, { key: "snp", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "snp"
        );
      })
  ),

  childRoutes: [SelfStudy(store), ActionPlan(store)],
});
