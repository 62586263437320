import React from "react";
import classes from "./ResourceList.scss";
import { GoalsIcon, Tick } from "SvgComponents";
import { colors } from "Constants";
import { ResourceImagePlaceHolder, I18nHOC } from "UIComponents";
import { LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import classNames from "classnames";

const styles = {
  imagePlaceholder: {
    height: "64px",
    width: "80px",
    flex: "inherit",
  },
};

class ResourceList extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  renderResources = () => {
    const {
      resources,
      resourceId,
      onItemClicked,
      t,
      curriculumProgramType,
    } = this.props;

    return _.get(resources, "length", 0) > 0 ? (
      _.map(resources, resource => {
        const assessmentType = _.get(resource, "assessmentType.value") || "le";
        const { color, localeLabel } =
          LE_ASSESSMENT_TYPE_MAPPING[assessmentType] || {};
        const isSelected = resource.id === resourceId;

        const unitContainerClass = classNames({
          [classes.unitContainerSelected]: isSelected,
          [classes.unitContainer]: true,
        });

        return (
          <div
            onClick={() => onItemClicked(resource)}
            className={unitContainerClass}
            key={resource.id}
          >
            <div className={classes.tickIconContainer}>
              {isSelected ? (
                <Tick width={20} height={20} fillBackground={colors.blue29} />
              ) : (
                <div className={classes.emptyTick} />
              )}
            </div>
            <ResourceImagePlaceHolder
              id={resource.id}
              imageUrl={_.get(resource, "image.value")}
              imageThumbSize={128}
              containerStyle={styles.imagePlaceholder}
            />
            <div className={classes.unitContentContainer}>
              <div
                style={{ color: color }}
                className={classes.resourceCentralIdea}
              >
                {t(localeLabel)}
              </div>
              <div className={classes.unitTitle}>{resource.title.value}</div>
            </div>
          </div>
        );
      })
    ) : (
      <div className={classes.noDataText}>
        {curriculumProgramType == "IB_PYP"
          ? t("journal:no_le_exist_unitplan")
          : t("journal:no_le_assessment")}
      </div>
    );
  };

  renderOrDivider = () => {
    const { t } = this.props;
    return (
      <div className={classes.orContainer}>
        <div className={classes.orContentLine} />
        <div className={classes.orText}>
          {t("common:uppercase", { text: t("common:or") })}
        </div>
        <div className={classes.orContentLine} />
      </div>
    );
  };

  getTagLEOrAssessmentText = () => {
    const { assessmentCount, t } = this.props;
    if (assessmentCount > 0) {
      return t("journal:select_elements_tag_assessment");
    }
    return t("journal:select_elements_tag_le");
  };

  render() {
    const { isResourceSkipped, onResourceSkipClicked, t } = this.props;
    const unitContainerClass = classNames({
      [classes.unitContainerSelected]: isResourceSkipped,
      [classes.unitContainer]: true,
    });

    return (
      <div className={classes.container}>
        <div onClick={onResourceSkipClicked} className={unitContainerClass}>
          <div className={classes.tickIconContainer}>
            {isResourceSkipped ? (
              <Tick width={20} height={20} fillBackground={colors.blue29} />
            ) : (
              <div className={classes.emptyTick} />
            )}
          </div>

          <div className={classes.goalsSvgContainer}>
            <GoalsIcon width={20} height={20} />
          </div>

          <div className={classes.unitContentContainer}>
            <div className={classes.unitTitle}>
              {t("journal:select_elements_tag_units")}
            </div>
          </div>
        </div>

        {this.renderOrDivider()}

        <div className={classes.selectUnitTitle}>
          {this.getTagLEOrAssessmentText()}
        </div>

        <div className={classes.listContent}>{this.renderResources()}</div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["journal", "common"] })(ResourceList);
