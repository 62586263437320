import React, { useRef, Fragment, useState } from "react";
import classNames from "classnames";
import classes from "./ButtonComponent.scss";
import { WarningCircleOutlined } from "@toddle-design/web-icons";
import { ArrowIcon } from "SvgComponents";
import { Popover } from "react-tiny-popover-new";
import { containerStyle } from "./ButtonComponentStyles";
import { FilterSelectionText } from "UIComponents";
import { getUniqueSelectedValues } from "../utils";
import { ELEMENT_TYPE_MYP_OBJECTIVES } from "Constants/stringConstants";

const MoreThanOneOption = ({ parent, label, t }) => {
  return (
    <div className={classes.moreThanOneOptionContainer}>
      <div>
        <WarningCircleOutlined size={"xx-small"} />
      </div>
      <div className={classes.noOptionTitle}>
        {t("snp:select_one_parent_option", {
          parent,
          label,
        })}
      </div>
    </div>
  );
};

const MessageComponent = ({ label }) => {
  return <div className={classes.noOptionMessage}>{label}</div>;
};

const getDisabledStateInfoElement = ({
  label,
  filterDisabilityStatusConfig,
  t,
}) => {
  const { stateCode, parentFilterLabel } = filterDisabilityStatusConfig;

  switch (stateCode) {
    case "LOADING": {
      return <MessageComponent label={t("snp:loading_options")} />;
    }

    case "NO_OPTIONS": {
      return (
        <MessageComponent
          label={t("common:no_with_label", {
            label: t("common:option_plural"),
          })}
        />
      );
    }

    case "PARENT_FILTER_MULTI_VALUE": {
      return (
        <MoreThanOneOption parent={parentFilterLabel} label={label} t={t} />
      );
    }

    default: {
      return null;
    }
  }
};

const ButtonComponent = ({
  label,
  values,
  entityType,
  options,
  filterDisabilityStatusConfig,
  showDropdown,
  containerParentRef,
  t,
}) => {
  const childrenRef = useRef(null);

  const [showPopover, setPopoverVisibility] = useState(false);

  const { isDisabled } = filterDisabilityStatusConfig;

  const arrowContainerClassList = classNames(classes.arrowContainer, {
    [classes.rotateArrow]: showDropdown,
  });

  const handleClick = () => {
    if (isDisabled) {
      setPopoverVisibility(prev => !prev);
    } else {
      setPopoverVisibility(false);
    }
  };

  const areAllOptionsSelected = _.size(options) == _.size(values);

  let uniqueValues = [];

  switch (entityType) {
    /**
     * Unique value criteria is not applicable to myp objectives
     * as there are objectives which have same labels but they are always unique
     */
    case ELEMENT_TYPE_MYP_OBJECTIVES: {
      uniqueValues = values;
      break;
    }

    default: {
      /**
       * If all options are selected then no need to filter out
       * duplicate values
       */

      uniqueValues = areAllOptionsSelected
        ? values
        : getUniqueSelectedValues({ values, options });
    }
  }

  return (
    <Fragment>
      <div
        className={classes.buttonComponent}
        ref={childrenRef}
        onClick={handleClick}
      >
        <div className={classes.buttonLabel}>{label}:</div>

        <div className={classes.selectionCount}>
          <FilterSelectionText values={uniqueValues} options={options} />
        </div>

        <div className={arrowContainerClassList}>
          <ArrowIcon height={10} width={10} />
        </div>
      </div>
      {showPopover && (
        <Popover
          isOpen={true}
          positions={["bottom"]}
          reposition={false}
          containerStyle={containerStyle}
          content={getDisabledStateInfoElement({
            label,
            filterDisabilityStatusConfig,
            t,
          })}
          containerParent={containerParentRef.current}
          childrenRef={{ current: childrenRef.current }}
          isChildrenRefPassed={true}
          onClickOutside={() => setPopoverVisibility(false)}
        />
      )}
    </Fragment>
  );
};

export default ButtonComponent;
