import gql from "graphql-tag";
import {
  studentAssessmentEvaluationFragment,
  assessmentEvaluationFragment,
  courseAssessmentEvaluationFragment,
  unitPlanFragment,
} from "./AssessmentEvaluationFragments";

export const getCourseAssessmentEvaluationFeedQuery = gql`
  query getCourseAssessmentEvaluationFeed(
    $courseId: ID!
    $userIds: [ID!]
    $unitPlanIds: [ID!]
    $state: ASSESSMENT_EVALUATION_STATE_ENUM
    $searchText: String!
  ) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseAssessmentEvaluationItem
      }
    }
  }
  ${courseAssessmentEvaluationFragment.courseAssessmentEvaluationFeed}
`;

export const getCourseAssessmentEvaluationCountQuery = gql`
  query getCourseAssessmentEvaluationCount($courseId: ID!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseAssessmentEvaluationItem
      }
    }
  }
  ${courseAssessmentEvaluationFragment.courseAssessmentEvaluationCount}
`;

export const getCourseAssessmentEvaluationIdQuery = gql`
  query getCourseAssessmentEvaluationId($courseId: ID!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseAssessmentEvaluationItem
      }
    }
  }
  ${courseAssessmentEvaluationFragment.courseAssessmentEvaluationIds}
`;

export const getStudentAssessmentEvaluationQuery = gql`
  query getStudentAssessmentEvaluation($id: ID!) {
    node(id: $id, type: STUDENT_ASSESSMENT_EVALUATION) {
      id
      ... on StudentAssessmentEvaluation {
        ...studentEvaluationItem
      }
    }
  }
  ${studentAssessmentEvaluationFragment.studentEvaluationDetails}
`;

export const getAssessmentEvaluationDetailsQuery = gql`
  query getAssessmentEvaluationDetails($id: ID!) {
    node(id: $id, type: ASSESSMENT_EVALUATION) {
      id
      ... on AssessmentEvaluation {
        ...evaluationItem
      }
    }
  }
  ${assessmentEvaluationFragment.evaluationDetails}
`;

export const getAssessmentEvaluationInsightQuery = gql`
  query getAssessmentEvaluationInsight($id: ID!) {
    node(id: $id, type: ASSESSMENT_EVALUATION) {
      id
      ... on AssessmentEvaluation {
        ...evaluationItem
      }
    }
  }
  ${assessmentEvaluationFragment.evalutionInsight}
`;

export const getResourceFromUnitPlanQuery = gql`
  query getUnitPlan($id: ID!, $courseId: ID) {
    node(id: $id, type: UNIT_PLAN) {
      id
      ... on UnitPlan {
        ...unitPlanItem
      }
    }
  }
  ${unitPlanFragment.unitPlanDetails}
`;

export const getYearlySchedulerUnitPlanQuery = gql`
  query getYearlyScheduler(
    $courseId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        yearlySchedule(
          filters: { date: { startDate: $startDate, endDate: $endDate } }
        ) {
          unitPlan {
            id
            title {
              id
              value
            }
          }
        }
      }
    }
  }
`;
