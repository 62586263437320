import React, { PureComponent } from "react";
import classes from "./CreateResponse.scss";
import classNames from "classnames";
import { colors } from "Constants";
import { getFormattedEvidence } from "Utils";
import { AddCircleIcon } from "SvgComponents";
import { AttachmentList, CreateDropDown } from "Attachment";
import { TextAreaInput, ProfileCascade, UIButton, I18nHOC } from "UIComponents";
import { Button } from "@toddle-design/web";
import { AddCircleOutlined } from "@toddle-design/web-icons";

const submitButtonContainerStyle = {
  minWidth: 80,
  height: 32,
};

const createBoxStyle = {
  PCCustomContainerStyle: {
    alignItems: "center",
  },
  PCCustomStyle: {
    borderWidth: 0,
    width: 40,
  },
  textAreaStyles: {
    marginTop: 0,
    borderWidth: 0,
    padding: 0,
    paddingRight: 24,
    color: colors.gray31,
    fontSize: 14,
    lineHeight: 1.7,
    overflow: "hidden",
  },
  containerStyle: {
    justifyContent: "center",
  },
};

const styles = {
  buttonDropDownContainer: {
    top: 40,
    left: 0,
  },
};

class CreateResponse extends PureComponent {
  constructor(props) {
    super(props);
    props.customRef(this);

    this.state = { error: "" };
  }

  isValid = () => {
    this.setState({ error: "Error" });
    return 1;
  };

  updateInputField = ({ attachments, formattedEvidence }) => {
    const { onDeleteEvidenceClick } = this.props;

    const attachment = _.first(
      _.differenceBy(formattedEvidence, attachments, "id")
    );

    onDeleteEvidenceClick({
      id: attachment.id,
    });
  };

  getFormattedEvidence = () => {
    const { isActive, localResponseObj } = this.props;
    const { evidence } = isActive ? localResponseObj || {} : {};

    return getFormattedEvidence({ evidence });
  };

  render() {
    const {
      t,
      isActive,
      onClickCancel,
      userInfo,
      showEvidence,
      onMediaClick,
      onBlurCreate,
      onFocusCreate,
      onSubmitClick,
      dropDownOptions,
      onResponseChange,
      localResponseObj,
      onContentUploaded,
      createDropDownRef,
      onDropDownOptionClick,
      attachmentListContainerStyle,
      responseCreateContainerStyle,
      isDisabled,
      submitButtonLabel = t("common:save"),
    } = this.props;
    const { error } = this.state;

    const formattedEvidence = this.getFormattedEvidence();
    const { id, first_name, profile_image } = userInfo || {};
    const profiles = [{ id: id, value: first_name, imageUrl: profile_image }];

    const { id: itemId, message } = isActive ? localResponseObj || {} : {};

    const textAreaClass = classNames(
      { [classes.textArea]: true },
      { [classes.textAreaEditMode]: isActive }
    );

    const buttonsContainerClass = classNames(
      { [classes.buttonsContainer]: showEvidence },
      { [classes.buttonsContainerWithoutEvidence]: !showEvidence }
    );

    const responseCreateContainerClass = classNames(
      { [classes.responseCreateContainer]: true },
      { [classes.responseCreateContainerEditMode]: isActive },
      { [classes.errorResponseCreateContainer]: !!error }
    );

    const hideCursorStyle = !isActive ? { caretColor: "transparent" } : {};

    return (
      <div
        className={responseCreateContainerClass}
        style={responseCreateContainerStyle}
      >
        <div className={classes.createTopContainer}>
          <div className={classes.profileImage}>
            <ProfileCascade
              items={profiles}
              customStyle={createBoxStyle.PCCustomStyle}
              customContainerStyle={createBoxStyle.PCCustomContainerStyle}
            />
          </div>
          <div className={textAreaClass}>
            <TextAreaInput
              minRows={1}
              value={message}
              autoFocus={isActive}
              onBlurInputField={onBlurCreate}
              onFocusInputField={onFocusCreate}
              textAreaStyles={{
                ...createBoxStyle.textAreaStyles,
                ...hideCursorStyle,
              }}
              containerStyle={createBoxStyle.containerStyle}
              placeholder={t("common:comment_textarea_emptyText")}
              updateInputField={message => onResponseChange(message)}
            />
          </div>
        </div>
        <div className={classes.evidenceFeed}>
          <AttachmentList
            mode={"edit"}
            cardType={"horizontal"}
            value={formattedEvidence}
            containerStyle={attachmentListContainerStyle}
            showCreateDropdown={false}
            onClickMedia={onMediaClick}
            updateInputField={params =>
              this.updateInputField({
                attachments: params,
                formattedEvidence,
              })
            }
          />
        </div>

        {isActive && (
          <div className={buttonsContainerClass}>
            {showEvidence && (
              <CreateDropDown
                parentId={itemId}
                exportAsPdf={true}
                customRef={createDropDownRef}
                dropDownOptions={dropDownOptions}
                buttonDropDownContainerStyle={styles.buttonDropDownContainer}
                buttonComponent={
                  <Button
                    variant={"inline-progressive"}
                    icon={<AddCircleOutlined />}
                    size={"small"}
                  >
                    {t("common:add_with_label", {
                      label: t("common:evidence"),
                    })}
                  </Button>
                }
                parentType={"PLANNER_FIELD_RESPONSE"}
                addAttachment={onContentUploaded}
                onDropDownOptionClick={onDropDownOptionClick}
              />
            )}
            <div className={classes.rightButtons}>
              <div className={classes.cancelButton}>
                <Button
                  variant={"inline"}
                  onClick={onClickCancel}
                  size={"small"}
                >
                  {t("common:cancel")}
                </Button>
              </div>
              <div
                onMouseDown={event => {
                  if (isDisabled) event.preventDefault();
                }}
              >
                <Button variant={"progressive"} onClick={onSubmitClick}>
                  {submitButtonLabel}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const CreateResponseWrapper = I18nHOC({ resource: ["common"] })(CreateResponse);

CreateResponseWrapper.defaultProps = {
  showEvidence: true,
  responseCreateContainerStyle: {},
};

export default CreateResponseWrapper;
