import React from "react";
import PropTypes from "prop-types";

const PlayIconV2 = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 100 100"
      fill={props.fill && props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.4895 78.1904V21.9299C18.4895 17.0252 23.6397 13.8515 27.9317 16.1596L78.2913 44.1456C82.5833 46.7422 82.5833 53.0896 78.2913 55.6862L27.9317 83.6722C23.6397 86.2689 18.4895 83.0952 18.4895 78.1904Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5671 18.6952C24.2305 17.4525 21.3542 19.1579 21.3542 21.9299V78.1904C21.3542 80.991 24.19 82.5716 26.4562 81.2005C26.4863 81.1823 26.5167 81.1647 26.5474 81.1476L76.8546 53.1907C79.2429 51.7075 79.2429 48.1243 76.8546 46.6411L26.5671 18.6952ZM15.625 21.9299C15.625 14.8984 23.0368 10.2578 29.2816 13.6161L29.3163 13.6348L29.3162 13.635L79.6758 41.621C79.7065 41.6381 79.7369 41.6557 79.767 41.6739C85.911 45.391 85.911 54.4409 79.767 58.158C79.7501 58.1682 79.733 58.1783 79.7159 58.1881C79.7026 58.1958 79.6892 58.2034 79.6758 58.2108L29.3585 86.1734C23.0511 89.9417 15.625 85.1813 15.625 78.1904V21.9299Z"
      />
    </svg>
  );
};

PlayIconV2.defaultProps = {
  height: 16,
  width: 16,
};
PlayIconV2.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
};
export default PlayIconV2;
