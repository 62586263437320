import React from "react";
import classes from "./FilterGroup.scss";

const FilterGroup = props => {
  const { filters, children, containerStyle } = props;

  return (
    <div className={classes.container} style={containerStyle}>
      {_.map(filters, config => {
        return (
          <React.Fragment key={config.key}>
            {children({ config })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

FilterGroup.defaultProps = {
  containerStyle: {},
};

export default FilterGroup;
