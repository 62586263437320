import React, { useState, useCallback, Fragment } from "react";
import { connect } from "react-redux";

import ActionsContainer from "./ActionsContainer";
import NoEvidenceView from "./NoEvidenceView";
import EvidenceFeedHeader from "./EvidenceFeedHeader";
import {
  PostSelection,
  UnitSelection,
  AssessmentSelection,
  AssignmentSelection,
  OrganizationResourceSelection,
} from "AppComponents";

import Filters from "./Filters";
import { Loading } from "UIComponents";

const CardContainer = props => {
  const {
    activeTabConfig,
    activeTab,
    selectedCards,
    changeSelectedCards,
    activeTabEvidenceTypes,
    curriculumProgramId,
    parentIdConfig,
    onClickView,
    actionsStyles,
    styles,
    customEvidences,
    initialAppliedFilters,
    selectAllOptionsInitially,
    curriculumType,
    t,
  } = props;

  const [filterObj, setFilterObj] = useState({});

  const [selectedFilterItemsLocale, setSelectedFilterItemsLocale] = useState(
    {}
  );

  const [filterStatusObj, setFilterStatusObj] = useState({});

  const updateFilterStatus = useCallback(
    ({ loaded }) => {
      setFilterStatusObj(prev => ({
        ...prev,
        [activeTab]: loaded,
      }));
    },
    [activeTab]
  );

  const updateFilterItemsLocaleForActiveTab = useCallback(
    ({ data }) => {
      setSelectedFilterItemsLocale(selectedFilterItemsLocale => {
        return { ...selectedFilterItemsLocale, [activeTab]: data };
      });
    },
    [activeTab]
  );

  const updateFilterObject = useCallback(
    ({ newFilterObj }) => {
      setFilterObj(filterObj => {
        return { ...filterObj, [activeTab]: newFilterObj };
      });
    },
    [activeTab]
  );

  const selectedEvidence = _.get(selectedCards, [activeTab], []);

  const updateSelectedEvidence = updatedEvidence => {
    changeSelectedCards(prev => {
      return {
        ...prev,
        [activeTab]: updatedEvidence,
      };
    });
  };

  const parentId = _.get(parentIdConfig, activeTab, "");
  const parentType = _.get(activeTabConfig, "parentType", "");
  const countLocale = _.get(activeTabConfig, "countLocale", "");

  const activeTabFilters = _.get(filterObj, activeTab, {});
  const configFilters = activeTabConfig?.filters ?? {};
  const filters = { ...activeTabFilters, ...configFilters };

  /**mark filters as ready when filters are loaded for current tab */
  const areFiltersLoaded = filterStatusObj[activeTab];

  const openEvidenceView = ({ data, evidenceType }) => {
    const type = evidenceType ? evidenceType : _.first(activeTabEvidenceTypes);
    onClickView({ evidenceType: type, data });
  };

  const evidenceFeedProps = {
    parentId,
    parentType,
    curriculumProgramId,
    customEmptyViewElement: <NoEvidenceView />,
    customHeaderElement: (
      <EvidenceFeedHeader
        t={t}
        countLocale={countLocale}
        selectedFiltersItems={selectedFilterItemsLocale[activeTab]}
      />
    ),
    updateSelectedItems: updateSelectedEvidence,
    selectedItems: selectedEvidence,
  };

  const getCurrentTabEvidenceFeed = () => {
    switch (activeTab) {
      case "UNIT_EVIDENCE":
      case "PROGRESS_REPORT_POST": {
        return (
          <PostSelection
            filters={filters}
            {...evidenceFeedProps}
            styles={styles.unitEvidence}
            cardWrapperElement={
              <ActionsContainer
                styles={actionsStyles.unitEvidence}
                onClickView={openEvidenceView}
                showBlackOverlay={true}
              />
            }
            isMultiSelect={true} //provide support for single item selection when needed
          />
        );
      }
      case "UNIT_PLANS": {
        return (
          <UnitSelection
            filters={filters}
            queryType={activeTabConfig.queryType}
            shouldGroupScheduledFeed={activeTabConfig.shouldGroupScheduledFeed}
            styles={styles.unitPlan.container}
            isMultiSelect={true}
            showGrades={activeTabConfig.showGrades}
            cardWrapperElement={
              <ActionsContainer
                styles={actionsStyles.unitPlan}
                showBlackOverlay={true}
                onClickView={openEvidenceView}
              />
            }
            showSubTitleTooltip={activeTabConfig.showSubTitleTooltip}
            cardTheme={styles.unitPlan.card}
            {...evidenceFeedProps}
          />
        );
      }
      case "LEARNING_EXPERIENCES": {
        return (
          <AssessmentSelection
            styles={styles.learningExperiences.container}
            cardType={"boxCards"}
            {...filters}
            type={activeTabConfig.type}
            isMultiSelect={true}
            customCardStyles={styles.learningExperiences.card}
            cardWrapperElement={
              <ActionsContainer
                styles={actionsStyles.learningExperiences || {}}
                showBlackOverlay={false}
                onClickView={openEvidenceView}
              />
            }
            {...evidenceFeedProps}
          />
        );
      }
      case "CLASSROOM":
        return (
          <AssignmentSelection
            filters={filters}
            orderBy={activeTabConfig.orderBy}
            orderByDirection={activeTabConfig.orderByDirection}
            styles={styles.classroom}
            customOverlayElement={
              <ActionsContainer
                styles={actionsStyles.learningExperiences}
                showBlackOverlay={false}
                onClickView={openEvidenceView}
              />
            }
            {...evidenceFeedProps}
          />
        );
      case "SCHOOL_POLICY":
      case "TEACHER_RESOURCES": {
        return (
          <OrganizationResourceSelection
            {...evidenceFeedProps}
            filters={filters}
            styles={styles.organizationResource}
            cardType={"VIEW_AND_SELECT"}
            types={activeTabEvidenceTypes}
            visibleTo={activeTabConfig.visibleTo}
            cardWrapperElement={
              <ActionsContainer
                styles={actionsStyles.resources}
                showBlackOverlay={true}
                onClickView={openEvidenceView}
              />
            }
          />
        );
      }
      default: {
        const elementKey = _.get(activeTabConfig, "elementKey", "");

        const actionsStyleKey = _.get(activeTabConfig, "actionsStyleKey", "");

        const actionIconsStyle = _.get(actionsStyles, actionsStyleKey, {});

        const isBlackOverlayRequired = _.get(
          activeTabConfig,
          "isBlackOverlayRequired",
          false
        );

        const activeTabElement = _.get(customEvidences, elementKey, null);

        return activeTabElement
          ? React.cloneElement(activeTabElement, {
              ...evidenceFeedProps,
              filters,
              cardWrapperElement: (
                <ActionsContainer
                  styles={actionIconsStyle || {}}
                  showBlackOverlay={isBlackOverlayRequired}
                  onClickView={openEvidenceView}
                />
              ),
            })
          : null;
      }
    }
  };

  const feedStyleKey = activeTabConfig.feedStyleKey;

  const { filter: filterStyle = {} } = styles[feedStyleKey] ?? {};

  return (
    <Fragment>
      <Filters
        initialAppliedFilters={initialAppliedFilters}
        selectAllOptionsInitially={selectAllOptionsInitially}
        updateFilterObject={updateFilterObject}
        activeTab={activeTab}
        updateFilterItemsLocaleForActiveTab={
          updateFilterItemsLocaleForActiveTab
        }
        filterStatus={filterStatusObj[activeTab] ?? false}
        updateFilterStatus={updateFilterStatus}
        curriculumProgramId={curriculumProgramId}
        curriculumType={curriculumType}
        filterStyle={filterStyle}
      />

      {areFiltersLoaded ? getCurrentTabEvidenceFeed() : <Loading />}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const curriculumType = _.get(ownProps, "curriculumProgramType", "");

  const curriculumPrograms = state.platform.organizationCurriculumPrograms;
  const curriculumProgramId = _.get(
    _.find(curriculumPrograms, {
      type: curriculumType,
    }),
    "id",
    null
  );

  return {
    curriculumProgramId,
    curriculumType,
  };
};

export default connect(mapStateToProps)(CardContainer);
