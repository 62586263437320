import React from "react";
import classes from "./PYPElements.scss";
import { colors, fontStyle } from "Constants";

import { Textview, Listview, BenchmarkView, I18nHOC } from "UIComponents";
import classNames from "classnames";
import _ from "lodash";
const CONTAINER_STYLES = {
  labelStyle: {
    fontSize: "1.6rem",
    ...fontStyle.demiBold,
  },
  labelContainerStyle: {
    marginBottom: 8,
  },
  containerStyle: {
    borderBottom: `1px solid ${colors.strokeTwo}`,
    padding: "8px 0",
  },
};

const PYPElementsDetails = ({
  pypTemplateElements,
  elementData,
  showViewEmpty,
  shouldPypElementEmptyContainer,
  t,
  itemContainerClass,
  theme,
  dynamicLevelContainerStyle = {},
  childrenContainerStyle = [],
}) => {
  let isPypElementEmpty = true;
  _.forEach(elementData, element => {
    if (_.get(element, "length", 0) != 0) {
      isPypElementEmpty = false;
      return false;
    }
  });

  const getValue = ({ value }) => {
    if (_.includes(value, "disciplinaryObjectivesMYP")) {
      return "disciplinaryObjectivesMYP";
    }
    if (_.includes(value, "disciplinaryLearningStandardMYP")) {
      return "disciplinaryLearningStandardMYP";
    }
    return value;
  };

  const getRenderComponent = ({ item }) => {
    let renderComponent = null;
    const values = _.get(elementData, item.value, []);
    const { labelStyle = {}, labelContainerStyle = {} } = theme || {};
    const value = getValue({ value: item.value });
    switch (value) {
      case "benchmarks":
      case "atls":
      case "atlsMYP":
      case "learningStandardMYP":
      case "objectivesMYP":
      case "disciplinaryObjectivesMYP":
      case "disciplinaryLearningStandardMYP":
      case "atlsUBD":
      case "learningStandardUBD":
        renderComponent = (
          <BenchmarkView
            key={item.value}
            label={item.localisedLabel || t(item.label)}
            value={values}
            mode={"view"}
            showViewEmpty={showViewEmpty}
            labelStyle={{ ...CONTAINER_STYLES.labelStyle, ...labelStyle }}
            labelContainerStyle={{
              ...CONTAINER_STYLES.labelContainerStyle,
              ...labelContainerStyle,
            }}
            containerStyle={CONTAINER_STYLES.containerStyle}
            dynamicLevelContainerStyle={{ ...dynamicLevelContainerStyle }}
            childrenContainerStyle={childrenContainerStyle}
          />
        );
        break;

      case "lois":
        renderComponent = (
          <Listview
            key={item.value}
            label={item.localisedLabel || t(item.label)}
            value={values}
            mode={"view"}
            showViewEmpty={showViewEmpty}
            labelStyle={{ ...CONTAINER_STYLES.labelStyle, ...labelStyle }}
            labelContainerStyle={{
              ...CONTAINER_STYLES.labelContainerStyle,
              ...labelContainerStyle,
            }}
            containerStyle={CONTAINER_STYLES.containerStyle}
          />
        );
        break;

      default:
        renderComponent = (
          <Textview
            key={item.value}
            label={item.localisedLabel || t(item.label)}
            mode={"view"}
            showViewEmpty={showViewEmpty}
            value={values}
            labelStyle={{ ...CONTAINER_STYLES.labelStyle, ...labelStyle }}
            labelContainerStyle={{
              ...CONTAINER_STYLES.labelContainerStyle,
              ...labelContainerStyle,
            }}
            containerStyle={CONTAINER_STYLES.containerStyle}
          />
        );
    }
    return renderComponent;
  };

  const containerContainerClasses = classNames({
    [classes.itemContainer]: true,
    [itemContainerClass]: !!itemContainerClass,
  });
  return isPypElementEmpty && shouldPypElementEmptyContainer ? (
    <div className={classes.subDetailEmptyContainer}>
      <div className={classes.detailText}>{t("common:no_pyp_elements")}</div>
    </div>
  ) : (
    <div className={classes.detailDataContainer}>
      {_.map(pypTemplateElements, item => {
        const renderComponent = getRenderComponent({ item });
        return renderComponent ? (
          <div className={containerContainerClasses}>{renderComponent}</div>
        ) : null;
      })}
    </div>
  );
};

export default I18nHOC({ resource: "common" })(PYPElementsDetails);

PYPElementsDetails.defaultProps = {
  showViewEmpty: false,
  shouldPypElementEmptyContainer: true,
};
