import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";

const getCourseIdsMemoize = _.memoize(
  params => getCourseIds(params),
  params => JSON.stringify(params)
);

const getCourseIds = ({ associatedEntities, entityType }) => {
  return _.map(
    _.filter(associatedEntities, c => _.get(c, "__typename") === entityType),
    c => _.get(c, "id")
  );
};

const getDropdownItemsMemoize = _.memoize(
  params => getDropdownItems(params),
  params => JSON.stringify(params)
);

const getDropdownItems = ({ categories, curriculumType, t }) => {
  const getDropdownItems = _.map(categories, category => {
    const categoryLabel = `${_.get(category, "label")}${
      curriculumType !== "IB_DP"
        ? ` (${_.get(category, "percentageWeight")}%)`
        : ""
    }`;
    let percentageSubLabel = "";
    if (curriculumType === "IB_DP") {
      const slWeight = _.get(category, "slWeight", "").toString();
      const hlWeight = _.get(category, "hlWeight", "").toString();

      if (slWeight) {
        percentageSubLabel += `${slWeight}% (SL)`;
        if (hlWeight) percentageSubLabel += `, `;
      }

      if (hlWeight) {
        percentageSubLabel += `${hlWeight}% (HL)`;
      }
    }
    return {
      label: categoryLabel,
      key: _.get(category, "id"),
      subtitle: percentageSubLabel,
    };
  });

  return [
    ...getDropdownItems,
    {
      label: t("common:none"),
      key: -1,
    },
  ];
};

const getUnitPlanRelatedData = assessmentId => {
  const assessmentDetails = getAssessmentDetailsFromCache(assessmentId);
  const unitPlanFields = _.get(assessmentDetails, "unitPlan.allFields", []);

  const unitPlanSubjects = _.get(
    _.find(unitPlanFields, { uid: "subjects" }),
    "resolvedMinimalTree.subjects",
    []
  );

  return {
    unitPlanSubjects,
  };
};

const getUnitPlanRelatedDataMemoize = _.memoize(
  params => getUnitPlanRelatedData(params),
  params => JSON.stringify(params)
);

/**
 *
 * @param {Array | String | Null} dependentFieldValue if subjects are radio button then Array, if dropdown then String
 * @param {Array} unitPlanSubjects
 * @returns {String | Null}
 */
/**
 * Test Cases
 * dependentFieldValue = null  -> return null
 * dependentFieldValue = ["7484","8937","7563"]  -> return null
 * dependentFieldValue = "6757"  -> return "6757"
 * dependentFieldValue = ["6757"]  -> return "6757"
 *
 */
const getSelectedSubjectId = ({ dependentFieldValue }) => {
  let dfv = dependentFieldValue;

  if (_.isNull(dfv)) {
    dfv = null;
  } else if (_.isArray(dfv)) {
    if (_.size(dfv) === 1) {
      dfv = _.first(dfv);
    } else {
      dfv = null;
    }
  }

  return {
    selectedSubjectId: dfv,
  };
};

const getSubjectIdsArr = course => {
  const subjects = _.get(course, "subjects", []);

  return _.map(subjects, subject => subject?.id);
};

const getSelectedSubjectIdMemoize = _.memoize(
  params => getSelectedSubjectId(params),
  params => JSON.stringify(params)
);

const getCourseDropdownItems = ({ unitId, selectedSubjectId }) => {
  const data = getUnitPlanDetailsFromCache(unitId);
  const courses = _.get(data, "courses", []);

  const filteredCourses = _.filter(courses, course =>
    _.includes(getSubjectIdsArr(course), selectedSubjectId)
  );

  return _.map(filteredCourses, course => ({
    label: _.get(course, "title"),
    key: _.get(course, "id"),
  }));
};

const getCourseDropdownItemsMemoize = _.memoize(
  params => getCourseDropdownItems(params),
  params => JSON.stringify(params)
);

export {
  getUnitPlanRelatedDataMemoize,
  getDropdownItemsMemoize,
  getCourseIdsMemoize,
  getSelectedSubjectIdMemoize,
  getCourseDropdownItemsMemoize,
};
