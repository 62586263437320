import React, { useState } from "react";
import classes from "./MoreFilters.scss";
import { useI18n } from "Hooks";
import { ButtonDropdown, CheckListContainer } from "UIComponents";
import { ArrowIcon } from "SvgComponents";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FilterOutlined } from "@toddle-design/web-icons";
import { Button, ChecklistDropdown } from "@toddle-design/web";

/**
 * This Component is used when a few filters are to be displayed in more dropdown.
 * Example can be seen in classroom - Import Learning Experience from LELibrary Modal
 * 
 * 
 * @param filtersInfo: An Array of filters Info
 * 
 * Ex : [
      {
        id: FILTER_NAME,
        name: FILTER_NAME,
        options: filterOptions,
        label: "common:label",
        showSearchBar: false
      },
      {
        id: FILTER_NAME,
        name: FILTER_NAME,
        options: filterOptions,
        label: "common:label",
        showSearchBar: false
      }
    ]
 * 
 * @param noOfFiltersOutOfMore: Number of filters to be displayed outside of More Dropdown
 * @param selectedMoreOptions: selected options in More dropdown
 * @param setSelectedMoreOptions: A function used to update Values of selected options in more dropdown
 * @param filterValues: An object with filter names as keys and selected filter values as values
 * 
 * Ex : [
 *      {filterOne : [1, 2, 3]
 *      filterTwo : ["ALL"]}
 *      ]
 * 
 * @param onChangeFilterValues: Function to update filter Values
 * @param getFilterDropdown : Function which takes filter, filter value, update filterValue as 
 * input and returns FilterDropDown
 * 
 * Ex : const getFilterDropdown = ({filter,onChangeFilterValues,filterValues}) => {
                return (
                        <FilterDropdown
                        key={filter.id}
                        name={filter.name}
                        options={filter.options}
                        label={filter.label}
                        showSearchBar={filter.showSearchBar}
                        isCheckList={filter.isCheckList}
                        showAllOption={filter.showAllOption}
                        updateInputField={onChangeFilterValues}
                        value={_.get(filterValues, `${filter.name}`, [])}
                        isSearchBasedOnQuery={filter.isSearchBasedOnQuery}
                        organizationId={filter.organizationId}
                        graphqlQueryEnhancer={filter.graphqlQueryEnhancer}
                        filterBoxStyle={styles.filterBoxStyle}
                        buttonDropDownContainerStyle={styles.buttonDropDownContainerStyle}
                        arrowContainerStyle={styles.arrowContainerStyle}
                        nameTextStyle={styles.nameTextStyle}
                        filterTextStyle={styles.filterTextStyle}
                        emptyTextStyle={styles.nameTextStyle}
                        />
                      );
                    };
 * 
 */

const styles = {
  containerStyle: {
    width: "252px",
    padding: "10px 20px",
  },
  listItemStyle: {
    flexBasis: "100%",
    margin: "10px 0",
  },
  parentContainerStyle: {
    marginLeft: 24,
  },
};

const CheckListTitle = () => {
  const { t } = useI18n(["common", "snp"]);
  return (
    <div className={classes.filterOptionTitle}>
      {t("common:select_with_label", {
        label: t("snp:filter_category"),
      })}
    </div>
  );
};

const ButtonComponent = props => {
  const { label, showDropdown } = props;
  const arrowClassList = classNames(classes.arrowContainer, {
    [classes.arrowContainerWithRotation]: showDropdown,
  });
  return (
    <div className={classes.buttonContainer}>
      <FilterOutlined size="xxx-small" variant="subtle" />
      {label}
      <div className={arrowClassList}>
        <ArrowIcon width={10} height={5} />
      </div>
    </div>
  );
};

const DropdownComponent = props => {
  const { moreFilters, updateSelectedOptions, selectedMoreOptions } = props;
  const { t } = useI18n(["common"]);

  const options = _.map(moreFilters, filter => {
    return { label: t(filter.label), value: filter.id };
  });

  return (
    <div className={classes.dropdownContainer}>
      <CheckListTitle />
      <CheckListContainer
        listItemStyle={styles.listItemStyle}
        updateInputField={updateSelectedOptions}
        options={options}
        value={selectedMoreOptions}
      />
    </div>
  );
};

const MoreFilters = props => {
  const {
    filtersInfo,
    filterValues,
    onChangeFilterValues,
    getFilterDropdown,
    selectedMoreOptions,
    setSelectedMoreOptions,
    noOfFiltersOutOfMore,
    groupType,
  } = props;

  const filtersOutOfMore = _.slice(filtersInfo, 0, noOfFiltersOutOfMore);
  const moreFilters = _.slice(
    filtersInfo,
    noOfFiltersOutOfMore,
    _.size(filtersInfo)
  );
  const [showDropdown, updateShowDropdown] = useState(false);

  const onUpdateShowDropdown = value => {
    updateShowDropdown(value);
  };

  const updateSelectedOptions = params => {
    setSelectedMoreOptions(prev => {
      const removedId = _.difference(prev, params);
      if (!_.isEmpty(removedId)) {
        onChangeFilterValues({ [_.first(removedId)]: [] });
      }

      return params;
    });
  };

  const { t } = useI18n(["common"]);

  const options = _.map(moreFilters, filter => {
    return { label: t(filter.label), value: filter.id, key: filter.id };
  });

  return (
    <div className={classes.container}>
      {!_.isEmpty(filtersOutOfMore) &&
        _.map(filtersOutOfMore, filter =>
          getFilterDropdown({
            filter,
            filterValues,
            onChangeFilterValues,
            t,
            groupType,
          })
        )}
      {!_.isEmpty(moreFilters) && (
        // <ButtonDropdown
        //   parentContainerStyle={styles.parentContainerStyle}
        //   containerStyle={styles.containerStyle}
        //   buttonComponent={
        //     <ButtonComponent
        //       label={t("common:more")}
        //       showDropdown={showDropdown}
        //     />
        //   }
        //   dropdownComponent={
        //     <DropdownComponent
        //       moreFilters={moreFilters}
        //       updateSelectedOptions={updateSelectedOptions}
        //       selectedMoreOptions={selectedMoreOptions}
        //     />
        //   }
        //   onUpdateShowDropDown={onUpdateShowDropdown}
        //   shouldCloseOnSelfClick={false}
        // />

        <ChecklistDropdown
          options={options}
          value={selectedMoreOptions}
          onChange={value => updateSelectedOptions(value)}
        >
          <Button
            icon={<FilterOutlined size={"xx-small"} variant={"subtle"} />}
            size={"medium"}
            variant={"outlined-subtle"}
          >
            {t("common:filters")}
          </Button>
        </ChecklistDropdown>
      )}
    </div>
  );
};

MoreFilters.propTypes = {
  filtersInfo: PropTypes.Array,
  noOfFiltersOutOfMore: PropTypes.number,
  selectedMoreOptions: PropTypes.Array,
  setSelectedMoreOptions: PropTypes.func,
  filterValues: PropTypes.object,
  onChangeFilterValues: PropTypes.func,
  getFilterDropdown: PropTypes.func,
};

MoreFilters.defaultProps = {
  filtersInfo: [],
  noOfFiltersOutOfMore: 1,
  selectedMoreOptions: [],
  filterValues: [],
};

export default MoreFilters;
