import gql from "graphql-tag";
import {
  attachmentFragment,
  staffFragment,
  courseFragment,
} from "modules/CommonFragments";

export const studentNoteCategoryFragment = {
  studentNoteCategory: gql`
    fragment studentNoteCategoryItem on StudentNoteCategory {
      id
      value
      metadata
    }
  `,
};

export const studentProfileQuestionFragment = {
  studentProfileQuestion: gql`
    fragment studentProfileQuestionItem on StudentProfileQuestion {
      id
      title
    }
  `,
};

export const studentProfileSectionFragment = {
  studentProfileSection: gql`
    fragment studentProfileSectionItem on StudentProfileSection {
      id
      title
      studentProfileQuestions {
        ...studentProfileQuestionItem
      }
    }
    ${studentProfileQuestionFragment.studentProfileQuestion}
  `,
};

export const studentNoteFragment = {
  studentNote: gql`
    fragment studentNoteItem on StudentNote {
      id
      title
      category {
        ...studentNoteCategoryItem
      }
      description
      createdBy {
        ...staffItem
      }
      createdAt
      attachments {
        ...attachmentItem
      }
    }
    ${staffFragment.basicStaffDetails}
    ${attachmentFragment.attachment}
    ${studentNoteCategoryFragment.studentNoteCategory}
  `,
};

export const studentProfileResponseFragment = {
  studentProfileResponse: gql`
    fragment studentProfileResponseItem on StudentProfileResponse {
      id
      title
      studentProfileQuestion {
        id
      }
      createdBy {
        ...staffItem
      }
      createdAt
      attachments {
        ...attachmentItem
      }
    }
    ${staffFragment.basicStaffDetails}
    ${attachmentFragment.attachment}
  `,
};

export const studentDetailsFragment = {
  studentNotes: gql`
    fragment studentNotesItem on Student {
      id
      studentNotes {
        ...studentNoteItem
      }
    }
    ${studentNoteFragment.studentNote}
  `,
  studentDetails: gql`
    fragment studentDetailsItem on Student {
      id
      firstName
      lastName
      profileImage
      email
      isArchived
      familyInviteCode
      parentStatus
      signInCode
      courses(orderBy: TITLE, orderByDirection: ASC, filters: $courseFilters) {
        ...courseItem
      }
      otherDetails {
        id
      }
    }
    ${courseFragment.basicCourserDetails}
  `,
  studentProfileResponses: gql`
    fragment studentProfileResponsesItem on Student {
      id
      studentProfileResponses {
        ...studentProfileResponseItem
      }
    }

    ${studentProfileResponseFragment.studentProfileResponse}
  `,
  familyInfo: gql`
    fragment familyInfo on FamilyMember {
      id
      firstName
      lastName
      email
      status
    }
  `,
};

export const organizationFragment = {
  studentNoteCategory: gql`
    fragment organizationItem on Organization {
      id
      constants {
        studentNoteCategory {
          ...studentNoteCategoryItem
        }
      }
    }
    ${studentNoteCategoryFragment.studentNoteCategory}
  `,
  studentProfileSections: gql`
    fragment organizationItem on Organization {
      id
      studentProfileSections(filters: $filters) {
        ...studentProfileSectionItem
      }
    }
    ${studentProfileSectionFragment.studentProfileSection}
  `,
};

export const personalContactsFragment = {
  personalContacts: gql`
    fragment personalContactsItem on PersonalContact {
      id
      firstName
      lastName
      email
      phoneNumber
      relationship
    }
  `,
};

export const addressFragment = {
  address: gql`
    fragment addressItem on Address {
      id
      line1
      line2
      city
      state
      zipcode
      country
    }
  `,
};

export const otherDetailsFragment = {
  otherDetails: gql`
    fragment otherDetailsItem on OtherUserDetails {
      id
      dob
      primaryNationality
      secondaryNationality
      languages
      birthPlace
      nationalId
    }
  `,
};

export const studentOtherDetailsFragment = {
  studentOtherDetails: gql`
    fragment studentOtherDetailsItem on Student {
      id
      firstName
      lastName
      gender
      email
      personalContacts {
        ...personalContactsItem
      }
      address {
        ...addressItem
      }
      otherDetails {
        ...otherDetailsItem
      }
    }
    ${personalContactsFragment.personalContacts}
    ${addressFragment.address}
    ${otherDetailsFragment.otherDetails}
  `,
};
