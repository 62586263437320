import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";

const {
  createProject: { path },
} = routes;

export default store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./CreateProject.js").default;

              resolve(component);
            },
            err => {
              reject(err);
            }
          );
        })
    ),
  };
};
