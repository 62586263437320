import React, { Component } from "react";
import PropTypes from "prop-types";

const HelperBulb = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 32 32">
      <g fill={props.fill && props.fill}>
        <path d="M16.19212,4.86218a7.47883,7.47883,0,0,1,4.85913,1.76288,6.662,6.662,0,0,1,2.43091,6.78082,8.54155,8.54155,0,0,1-1.53223,3.15387,11.6938,11.6938,0,0,0-2.45019,5.87348c-.55323-.00077-1.545-.00107-2.626-.00107-1.69067,0-3.59985.00073-4.391.00156a11.22444,11.22444,0,0,0-2.20886-5.53507,9.224,9.224,0,0,1-1.88538-4.925,7.16876,7.16876,0,0,1,5.31189-6.77606,10.09461,10.09461,0,0,1,2.4917-.33539m0-1.34332a11.38226,11.38226,0,0,0-2.82507.37741A8.525,8.525,0,0,0,7.04527,11.981a10.477,10.477,0,0,0,2.09509,5.63858c.98511,1.54889,1.97205,3.09323,2.006,5.04764a1.123,1.123,0,0,0,1.23951,1.10995H12.388c.72046-.00085,2.722-.00165,4.48572-.00165,1.21106,0,2.31018.00037,2.80481.00135h.00366a1.0132,1.0132,0,0,0,1.14685-.97995c.04163-2.12787,1.11487-3.83063,2.23352-5.48456a9.59338,9.59338,0,0,0,1.745-3.68784,8.0265,8.0265,0,0,0-2.91223-8.04443,8.83471,8.83471,0,0,0-5.70325-2.06122Z" />
        <path d="M10.80662,12.63251a.67227.67227,0,0,1-.67187-.668,5.45227,5.45227,0,0,1,3.999-5.0752.67149.67149,0,1,1,.334,1.30078,4.05777,4.05777,0,0,0-2.98925,3.76661.67186.67186,0,0,1-.668.67578Z" />
        <path d="M19.54979,26.10419h-.001l-7.09961-.0127a.67188.67188,0,0,1,.001-1.34375h.001l7.09961.0127a.67188.67188,0,0,1-.001,1.34375Z" />
        <path d="M18.69529,28.48114h0l-5.42675-.001a.67188.67188,0,1,1,0-1.34375h0l5.42675.001a.67188.67188,0,1,1,0,1.34375Z" />
      </g>
    </svg>
  );
};

HelperBulb.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

HelperBulb.defaultProps = {
  width: 32,
  height: 32,
};

export default HelperBulb;
