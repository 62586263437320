import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { UIButton, I18nHOC } from "UIComponents";
import { CancelIcon, Tick } from "SvgComponents";
import { colors } from "Constants";
import classes from "./SelectKCModal.scss";
import classNames from "classnames";
class SelectKCModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selctedKeyConcepts: [],
    };
    this.modalBody = null;
    this.listComponent = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedKeyConcepts: oldSelectedKeyConcepts } = prevState;
    const { selectedKeyConcepts } = this.state;
    const { updateValueLocally } = this.props;
    if (selectedKeyConcepts != oldSelectedKeyConcepts) {
      if (updateValueLocally) {
        updateValueLocally(selectedKeyConcepts);
      }
    }
  };

  UNSAFE_componentWillMount = () => {
    this.setState({
      selectedKeyConcepts: this.props.selectedKeyConcepts
        ? this.props.selectedKeyConcepts
        : [],
    });
  };

  onAddKC = value => {
    const selectedKeyConcepts = [...this.state.selectedKeyConcepts];

    const index = _.indexOf(selectedKeyConcepts, value);

    if (index != -1) {
      selectedKeyConcepts.splice(index, 1);
    } else {
      selectedKeyConcepts.push(value);
    }
    this.setState({ selectedKeyConcepts });
  };

  onCancelClick = () => {
    this.setState({ selctedKeyConcepts: [] });
    this.props.onCancelClick();
  };

  onAddClick = () => {
    const selectedKeyConcepts = [...this.state.selectedKeyConcepts];
    const storeKeyConcepts = this.props.selectedKeyConcepts;
    // let newKeyConcepts = [];
    // _.map(selectedKeyConcepts, selectedKC => {
    //   if (!this.checkIfKCExistInStore(selectedKC)) {
    //     let newKCStoreObj = {
    //       value: selectedKC,
    //       questions: _.reduce(
    //         this.props.questions,
    //         (prevResult, quesObj, index) => {
    //           prevResult[quesObj.value] = "";
    //           return prevResult;
    //         },
    //         {}
    //       )
    //     };
    //     newKeyConcepts.push(newKCStoreObj);
    //   } else {
    //     const storeObj = _.find(storeKeyConcepts, storeKCObj => {
    //       return storeKCObj.value == selectedKC;
    //     });
    //     newKeyConcepts.push(storeObj);
    //   }
    // });
    this.props.updateInputField({ [this.props.name]: selectedKeyConcepts });
    this.props.onCancelClick();
    this.setState({ selctedKeyConcepts: [] });
  };

  checkIfKCExistInStore = kc => {
    return _.indexOf(this.props.selectedKeyConcepts, kc) != -1;
  };

  getKCList = data => {
    const KCList = _.map(data, (itemObject, index) => {
      return (
        <KCItem
          item={itemObject}
          selectedKeyConcepts={this.state.selectedKeyConcepts}
          key={index}
          value={itemObject.value}
          onAddKC={this.onAddKC}
        />
      );
    });

    return KCList;
  };

  shouldShowShadow = () => {
    if (this.modalBody && this.listComponent) {
      return this.listComponent.scrollHeight > this.modalBody.clientHeight;
    }
    return false;
  };

  render() {
    const KCListComponent = this.getKCList(this.props.keyConcepts);
    const footerStyle = classNames(
      { [classes.modalFooter]: true },
      { [classes.modalFooterShadow]: this.shouldShowShadow() }
    );
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className={classes.modalHeader}>
          <div className={classes.headerTitle}>{`${this.props.addLabel}`}</div>
          <div className={classes.cancelIcon} onClick={this.onCancelClick}>
            <CancelIcon width={16} height={16} />
          </div>
        </div>
        <div className={classes.modalBody} ref={ref => (this.modalBody = ref)}>
          <div
            className={classes.fieldComp}
            ref={ref => (this.listComponent = ref)}
          >
            {KCListComponent}
          </div>
        </div>

        <div className={footerStyle}>
          <div className={classes.cancelButton}>
            <UIButton color="grey" onClick={this.onCancelClick} size="sm">
              {t("common:cancel")}
            </UIButton>
          </div>

          <UIButton color="pink" onClick={this.onAddClick} size="sm">{`${
            this.props.isEdit ? t("common:update") : t("common:add")
          }`}</UIButton>
        </div>
      </React.Fragment>
    );
  }
}

const KCItem = ({ item, value, selectedKeyConcepts, onAddKC, index }) => {
  const isKCSelected = (selectedKeyConcepts, item) => {
    return _.includes(selectedKeyConcepts, item.value);
  };

  return (
    <div
      key={index}
      className={
        isKCSelected(selectedKeyConcepts, item)
          ? classes.focusItemCompActive
          : classes.focusItemComp
      }
      onClick={() => {
        onAddKC(value);
      }}
    >
      <div className={classes.tickAndHelperComp}>
        {isKCSelected(selectedKeyConcepts, item) ? (
          <div className={classes.selectedTickComp}>
            <Tick fillBackground="#fff" fillTick={colors.teal20} />
          </div>
        ) : (
          <div className={classes.selectedTickComp} />
        )}
      </div>
      <div
        className={
          isKCSelected(selectedKeyConcepts, item)
            ? classes.titleTextSelected
            : classes.titleText
        }
      >
        {item.label}
      </div>
      {item.subText && (
        <div
          className={
            isKCSelected(selectedKeyConcepts, item)
              ? classes.subTextSelected
              : classes.subText
          }
        >
          {item.subText}
        </div>
      )}
    </div>
  );
};

SelectKCModal.propTypes = {
  updateInputField: PropTypes.func,
  onCancelClick: PropTypes.func,
  keyConcepts: PropTypes.array,
  selectedKeyConcepts: PropTypes.array,
  name: PropTypes.string,
  isEdit: PropTypes.bool,
};

SelectKCModal.defaultProps = {
  isEdit: false,
};

export default I18nHOC({ resource: "common" })(SelectKCModal);
