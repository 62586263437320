import React, { useContext, Fragment } from "react";
import classes from "./BenchmarkView.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { Checkbox } from "@toddle-design/web";
import classNames from "classnames";
import ACLStore from "lib/aclStore";

const SelectedValueContext = React.createContext();

const getCheckboxSize = depth => {
  if (depth > 2) {
    return "xxx-small";
  } else if (depth == 2) {
    return "xx-small";
  } else {
    return "x-small";
  }
};

const getChildrenContainerClass = ({ depth, isMYPRelatedConcept = false }) => {
  switch (depth) {
    case 0:
      return isMYPRelatedConcept
        ? classes.h0RCTreeContainer
        : classes.h0TreeContainer;
    case 1:
      return isMYPRelatedConcept
        ? classes.h1RCTreeContainer
        : classes.h1TreeContainer;
    case 2:
      return isMYPRelatedConcept
        ? classes.h2RCTreeContainer
        : classes.h2TreeContainer;
    default:
      return classes.h3TreeContainer;
  }
};

const getChildrenContainerStyle = ({
  startDepth,
  depth,
  calculatedDepth,
  childrenContainerStyle = [],
}) => {
  const defaultChildrenContainerStyle = !_.isUndefined(calculatedDepth)
    ? {
        //paddingLeft: Math.max(calculatedDepth - startDepth, 0) * 16
      }
    : { marginTop: 0 };

  const customStyle = _.get(
    _.find(childrenContainerStyle, { depth }),
    "style",
    {}
  );

  return _.isEmpty(customStyle)
    ? defaultChildrenContainerStyle
    : {
        ...defaultChildrenContainerStyle,
        ...customStyle,
      };
};

const getNodeLabelClass = ({ depth, isMYPRelatedConcept = false }) => {
  switch (depth) {
    case 0:
      return classes.h0Label;
    case 1:
      return isMYPRelatedConcept ? classes.h1RCLabel : classes.h1Label;
    case 2:
      return isMYPRelatedConcept ? classes.h2RCLabel : classes.h2Label;
    default:
      return classes.h3Label;
  }
};

const getNodeLabelStyle = ({
  depth,
  showCheckbox,
  nodeLabelStyle = [],
  isReverseCheckbox,
  dynamicLevelContainerStyle,
}) => {
  const defaultNodeLabelStyle = showCheckbox ? { cursor: "pointer" } : {};

  if (isReverseCheckbox && showCheckbox) {
    defaultNodeLabelStyle.flexDirection = "row-reverse";
  }

  let customStyle = _.get(_.find(nodeLabelStyle, { depth }), "style", {});
  if (!_.isEmpty(dynamicLevelContainerStyle)) {
    customStyle = {
      ...customStyle,
      ..._.get(dynamicLevelContainerStyle, depth, {}),
    };
  }
  return _.isEmpty(customStyle)
    ? defaultNodeLabelStyle
    : {
        ...defaultNodeLabelStyle,
        ...customStyle,
      };
};

const NodeTree = React.memo(props => {
  const {
    node,
    isTagging,
    loopDepth,
    startDepth,
    nodeLabelStyle,
    nodeSpanStyle,
    dynamicLevelContainerStyle,
    childrenContainerStyle,
    isReverseCheckbox,
    checkboxSize,
    searchTerm,
    checkboxItemStyle,
    isMYPRelatedConcept,
  } = props;

  const { id, label, children, depth, code, genericTags, tags } = node;

  const childrenContainerClass = getChildrenContainerClass({
    depth: depth + 1,
    isMYPRelatedConcept,
  });
  const nodeTags = !_.isEmpty(genericTags) ? genericTags : tags;

  const calculatedDepth = _.has(node, "depth") ? depth : loopDepth;
  const updatedChildrenContainerStyle = getChildrenContainerStyle({
    startDepth,
    depth: depth + 1,
    calculatedDepth,
    childrenContainerStyle,
  });

  const isLeaf =
    _.has(node, "isLeaf") && !_.isNull(node.isLeaf)
      ? node.isLeaf
      : _.get(children, "length", 0) == 0;

  return (
    <div className={classes.nodeBody}>
      <NodeItem
        id={id}
        label={label}
        isLeaf={isLeaf}
        isTagging={isTagging}
        depth={calculatedDepth}
        nodeLabelStyle={nodeLabelStyle}
        nodeSpanStyle={nodeSpanStyle}
        dynamicLevelContainerStyle={dynamicLevelContainerStyle}
        code={code}
        isReverseCheckbox={isReverseCheckbox}
        checkboxSize={checkboxSize}
        searchTerm={searchTerm}
        checkboxItemStyle={checkboxItemStyle}
        isMYPRelatedConcept={isMYPRelatedConcept}
        genericTags={nodeTags}
      />
      {!isLeaf && (
        <div
          className={childrenContainerClass}
          style={updatedChildrenContainerStyle}
        >
          {_.map(children, child => {
            return (
              <NodeTree
                node={child}
                key={child.id}
                isTagging={isTagging}
                startDepth={startDepth}
                loopDepth={loopDepth + 1}
                nodeLabelStyle={nodeLabelStyle}
                nodeSpanStyle={nodeSpanStyle}
                dynamicLevelContainerStyle={dynamicLevelContainerStyle}
                childrenContainerStyle={childrenContainerStyle}
                isReverseCheckbox={isReverseCheckbox}
                checkboxSize={checkboxSize}
                searchTerm={searchTerm}
                checkboxItemStyle={checkboxItemStyle}
                isMYPRelatedConcept={isMYPRelatedConcept}
              ></NodeTree>
            );
          })}
        </div>
      )}
    </div>
  );
});

const BulletComponent = React.memo(({ depth }) => {
  if (depth == 2) {
    return <div className={classes.bullet}>{`•`}</div>;
  } else if (depth > 2) {
    return <div className={classes.bullet}>{`—`}</div>;
  } else {
    return null;
  }
});

const NodeItem = React.memo(props => {
  const {
    label,
    depth,
    isLeaf,
    isTagging,
    id,
    isMYPRelatedConcept,
    nodeLabelStyle = [],
    code,
    isReverseCheckbox,
    checkboxItemStyle,
    nodeSpanStyle,
    genericTags,
    dynamicLevelContainerStyle,
  } = props;
  const showTags = ACLStore.can("FeatureFlag:ShowTagsBenchmarkView");
  const { selectedValues, toggleCheckbox } = useContext(SelectedValueContext);
  const nodeLabelClass = getNodeLabelClass({ depth, isMYPRelatedConcept });

  const isSelected = _.includes(selectedValues, id);
  const showCheckbox = isLeaf && isTagging;
  const updatedNodeLabelStyle = getNodeLabelStyle({
    depth,
    showCheckbox,
    nodeLabelStyle,
    isReverseCheckbox,
    dynamicLevelContainerStyle,
  });

  const bullet = classNames(
    { [classes.bullet]: !isReverseCheckbox },
    { [classes.bulletNoMargin]: isReverseCheckbox }
  );

  const tagChips = showTags ? (
    <span className={classes.tagsContainer}>
      {_.map(genericTags, ({ label, id, icon }) => {
        const iconURL = _.get(icon, "url");
        const tagContainerClasses = classNames(classes.tagContainer, {
          [classes.paddingLeft]: _.isEmpty(iconURL),
        });
        return (
          <div className={tagContainerClasses} key={id}>
            {iconURL && <img className={classes.tagImage} src={iconURL} />}
            {label}
          </div>
        );
      })}
    </span>
  ) : null;

  return (
    <div
      className={nodeLabelClass}
      style={updatedNodeLabelStyle}
      onClick={() => {
        if (showCheckbox) {
          toggleCheckbox({ id, isSelected });
        }
      }}
    >
      {showCheckbox ? (
        <div className={bullet}>
          <Checkbox
            // style={{ width: checkboxUpdatedSize, height: checkboxUpdatedSize }}
            // fill={colors.blue29}
            // offFill={colors.strokeTwo}
            isChecked={isSelected}
            size={getCheckboxSize(depth)}
            // checkboxItemStyle={checkboxItemStyle}
          />
        </div>
      ) : (
        <BulletComponent depth={depth}></BulletComponent>
      )}
      <span style={nodeSpanStyle} className={classes.spanStyle}>
        <div className={classes.labelContainer}>
          <span
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
          {tagChips}
        </div>
        <span
          className={classes.codeLabel}
          dangerouslySetInnerHTML={{
            __html: code && isTagging ? ` (${code})` : ``,
          }}
        />
      </span>
      {/* {`${label}`} */}
    </div>
  );
});

class BenchmarkView extends UIBaseComponent {
  toggleCheckbox = ({ id, isSelected }) => {
    let { selectedValues } = this.props;
    if (isSelected) {
      selectedValues = _.filter(selectedValues, val => val != id);
    } else {
      selectedValues = [...selectedValues, id];
    }
    this.updateValue(selectedValues);
  };

  renderView = () => {
    const {
      value,
      isTagging,
      startDepth,
      selectedValues,
      nodeLabelStyle,
      nodeSpanStyle,
      dynamicLevelContainerStyle,
      childrenContainerStyle,
      isReverseCheckbox,
      checkboxSize,
      searchTerm,
      checkboxItemStyle,
    } = this.props;

    const isMYPRelatedConcept =
      !_.isEmpty(value) &&
      _.get(value, "0.type", null) == "MYP_RELATED_CONCEPT";

    const childrenContainerClass = getChildrenContainerClass({
      depth: startDepth,
      isMYPRelatedConcept,
    });
    const updatedChildrenContainerStyle = getChildrenContainerStyle({
      depth: startDepth,
      startDepth,

      childrenContainerStyle,
    });
    return (
      <SelectedValueContext.Provider
        value={{ selectedValues, toggleCheckbox: this.toggleCheckbox }}
      >
        <div
          style={updatedChildrenContainerStyle}
          className={childrenContainerClass}
        >
          {_.map(value, node => {
            if (node) {
              return (
                <NodeTree
                  node={node}
                  key={node.id}
                  isTagging={isTagging}
                  loopDepth={startDepth}
                  startDepth={startDepth}
                  nodeLabelStyle={nodeLabelStyle}
                  nodeSpanStyle={nodeSpanStyle}
                  dynamicLevelContainerStyle={dynamicLevelContainerStyle}
                  childrenContainerStyle={childrenContainerStyle}
                  isReverseCheckbox={isReverseCheckbox}
                  checkboxSize={checkboxSize}
                  searchTerm={searchTerm}
                  checkboxItemStyle={checkboxItemStyle}
                  isMYPRelatedConcept={isMYPRelatedConcept}
                ></NodeTree>
              );
            }
          })}
        </div>
      </SelectedValueContext.Provider>
    );
  };
}

BenchmarkView.defaultProps = {
  ...UIBaseComponent.defaultProps,
  isTagging: false,
  startDepth: 0,
  selectedValues: [],
  depthOffset: 0,
  isReverseCheckbox: false,
  searchTerm: "",
  checkboxItemStyle: {},
  nodeSpanStyle: {},
  dynamicLevelContainerStyle: {},
};

BenchmarkView.propTypes = {
  ...UIBaseComponent.propTypes,
};

export default BenchmarkView;

/*
type nodeLabelStyle {
  depth: number
  style: React.CSSProperties
}
nodeLabelStyle[]
*/
