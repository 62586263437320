import PropTypes from "prop-types";
import React from "react";

const ReportAndPortfolioIcon = props => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 100 100">
      <g fill={props.fill}>
        <path
          d="M77.47,0.01H20.29c-8.12,0-14.73,6.58-14.73,14.66v68.85c0,9.08,7.39,16.47,16.47,16.47h53.63
        c9.08,0,16.47-7.39,16.47-16.47V14.67C92.13,6.59,85.55,0.01,77.47,0.01z M86.01,83.52c0,5.71-4.64,10.35-10.35,10.35H22.03
        c-5.71,0-10.35-4.64-10.35-10.35V14.67c0-4.71,3.86-8.54,8.61-8.54h57.19c4.71,0,8.54,3.83,8.54,8.54V83.52z"
        />
        <path
          d="M66.42,49.51c-1.69,0-3.06,1.37-3.06,3.06v23.07c0,1.69,1.37,3.06,3.06,3.06s3.06-1.37,3.06-3.06V52.57
        C69.48,50.88,68.11,49.51,66.42,49.51z"
        />
        <path
          d="M32.77,62.17c-1.69,0-3.06,1.37-3.06,3.06v10.41c0,1.69,1.37,3.06,3.06,3.06s3.06-1.37,3.06-3.06V65.23
        C35.83,63.54,34.46,62.17,32.77,62.17z"
        />
        <path
          d="M49.59,58.34c-1.69,0-3.06,1.37-3.06,3.06v14.24c0,1.69,1.37,3.06,3.06,3.06s3.06-1.37,3.06-3.06V61.4
        C52.65,59.71,51.28,58.34,49.59,58.34z"
        />
        <path
          d="M69.46,24.05c0-0.04-0.02-0.08-0.03-0.13c-0.02-0.11-0.04-0.22-0.07-0.32c-0.02-0.09-0.05-0.18-0.08-0.27
        c-0.03-0.09-0.07-0.18-0.12-0.27c-0.04-0.09-0.09-0.18-0.14-0.27c-0.05-0.08-0.1-0.16-0.16-0.23c-0.06-0.08-0.13-0.17-0.2-0.24
        c-0.06-0.07-0.13-0.13-0.19-0.19c-0.08-0.08-0.17-0.15-0.26-0.21c-0.04-0.03-0.06-0.06-0.1-0.08c-0.04-0.03-0.08-0.04-0.12-0.06
        c-0.09-0.06-0.19-0.11-0.29-0.16c-0.09-0.04-0.17-0.08-0.26-0.11c-0.09-0.03-0.19-0.06-0.29-0.09c-0.1-0.03-0.2-0.05-0.3-0.06
        c-0.09-0.01-0.18-0.02-0.28-0.03c-0.11-0.01-0.22-0.01-0.33,0c-0.05,0-0.1,0-0.14,0L51.57,22.8c-1.68,0.17-2.91,1.67-2.73,3.35
        c0.17,1.68,1.68,2.91,3.35,2.74l7.67-0.78c-0.03,0.03-0.05,0.06-0.08,0.09c-5.2,6.28-12.16,11.74-22.9,13.23
        c-1.88,0.26-3.88,0.41-6,0.41c-1.69,0-3.06,1.37-3.06,3.06c0,1.69,1.37,3.06,3.06,3.06c16.44,0,26.45-7.35,33.3-15.47l0.68,6.72
        c0.16,1.58,1.49,2.75,3.04,2.75c0.1,0,0.21-0.01,0.31-0.02c1.68-0.17,2.91-1.67,2.73-3.35L69.46,24.05z"
        />
      </g>
    </svg>
  );
};

ReportAndPortfolioIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

ReportAndPortfolioIcon.defaultProps = {
  height: 20,
  width: 20,
  fill: "white",
};

export default ReportAndPortfolioIcon;
