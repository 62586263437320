import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import ClassRoomDetails from "./routes/ClassRoomDetails";
import ManageFolders from "ManageFolders";
import ClassRoomInsights from "./routes/ClassRoomInsights";
import MyCalendar from "Course/routes/MyCalendar";

export default store => ({
  path: "classroom",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ClassRoom/modules/ClassRoomModule")
              .default;
            injectReducer(store, { key: "classRoom", reducer });

            const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
              .default;
            injectReducer(store, { key: "unitPlans", reducer: unitPlanModule });

            const assessmentModule = require("Assessments/modules/AssessmentModule");
            injectReducer(store, {
              key: assessmentModule.NAME,
              reducer: assessmentModule.default,
            });
            const post = require("Post/modules/PostModule").default;

            injectReducer(store, {
              key: "post",
              reducer: post,
            });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-classRoom"
        );
      })
  ),
  childRoutes: [
    MyCalendar(store),
    ManageFolders(store),
    ClassRoomInsights(store),
    ClassRoomDetails(store),
  ],
});
