import React from "react";
import classes from "./Listview.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { getStringAutoLinks } from "Utils";

class Listview extends UIBaseComponent {
  renderBulletList = value => {
    return (
      <ul className={classes.List}>
        {_.map(value, (val, index) => {
          return (
            <li
              key={index}
              dangerouslySetInnerHTML={{
                __html: getStringAutoLinks(val),
              }}
            ></li>
          );
        })}
      </ul>
    );
  };

  renderNumberList = value => {
    return (
      <ol className={classes.List}>
        {_.map(value, (val, index) => {
          return (
            <li
              key={index}
              dangerouslySetInnerHTML={{
                __html: getStringAutoLinks(val),
              }}
            ></li>
          );
        })}
      </ol>
    );
  };

  renderView = () => {
    let { value, listType, customTextStyle } = this.props;
    return (
      <div className={classes.detailText} style={customTextStyle}>
        {listType == "number" ? (
          <React.Fragment>{this.renderNumberList(value)}</React.Fragment>
        ) : (
          <React.Fragment>{this.renderBulletList(value)}</React.Fragment>
        )}
      </div>
    );
  };
}

Listview.defaultProps = {
  ...UIBaseComponent.defaultProps,
  customTextStyle: {},
  listType: "bullet",
};

export default Listview;
