import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";

import PortfolioDetailsV2 from "StudentPortfolio/routes/PortfolioDetailsV2";

export default store => {
  return {
    path: "profileV2",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("./modules/ProfileModule").default;
              injectReducer(store, { key: "platformProfileV2", reducer });

              const platformProfileV1 = require("../Profile/modules/ProfileModule")
                .default;

              injectReducer(store, {
                key: "platformProfile",
                reducer: platformProfileV1,
              });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "student-profile-v2"
          );
        })
    ),
    childRoutes: [PortfolioDetailsV2(store)],
  };
};
