import React from "react";
import classes from "./UIBaseContentEditable.scss";
import PropTypes from "prop-types";
import ContentEditable from "react-contenteditable";
import UIBaseComponent from "../UIBaseComponent";
import classNames from "classnames";
import { getStyleStrippedText } from "Utils";

class ContentEditableComp extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      editing: false,
      editValue: props.value,
    };
  }

  focus = () => {
    const { value } = this.props;
    this.setState(
      {
        editValue: value,
      },
      () => {
        this.domElm.focus();
      }
    );
  };

  save = (e, { key, shiftKey } = {}) => {
    const { name, updateInputField, onEnterPress } = this.props;

    if (updateInputField) {
      e.preventDefault();
      updateInputField({ [name]: this.props.value });
    }

    // if (key == "Enter" && onEnterPress && !shiftKey) {
    //   e.preventDefault();
    //   onEnterPress();
    // }
  };

  cancel = () => {
    this.setState({
      editing: false,
    });
  };

  onTabPressed = (e, shiftKey) => {
    const { onTabPressed } = this.props;
    if (onTabPressed) {
      e.preventDefault();
      onTabPressed({ isShiftTab: shiftKey });
    }
  };

  handleKeyDown = e => {
    const { key, shiftKey } = e;
    const { allowEnter } = this.props;

    switch (key) {
      case "Enter": {
        if (allowEnter) {
          document.execCommand("insertLineBreak");
          e.preventDefault();
        }
        return allowEnter;
      }

      case "Escape":
        this.save(e, { key, shiftKey });
        return false;
      case "Tab":
        this.onTabPressed(e, shiftKey);
        break;
    }
  };

  updateDomRef = ref => {
    this.domElm = ref;
    this.updateInputRef(ref);
  };

  updateInputField = params => {
    this.setState(params);
  };

  handleTextPaste = event => {
    const { shouldPasteAsPlainText, styleStripType } = this.props;

    event.preventDefault();

    let text = "";

    if (shouldPasteAsPlainText) {
      text = event.clipboardData.getData("text/plain");
    } else {
      if (event.clipboardData.getData("text/html")) {
        text = event.clipboardData.getData("text/html");
        text = getStyleStrippedText({ text, type: styleStripType });
      } else {
        text = event.clipboardData.getData("text/plain");
      }
    }

    document.execCommand("insertHTML", false, text);
  };

  handleChange = (inputValue = "") => {
    const { shouldParseInPlainText, shouldTrimSpace, inputType } = this.props;

    if (shouldParseInPlainText) {
      const value = _.replace(inputValue, /<\/?[^>]+>/gi, "");

      if (inputType === "NUMBER") {
        const re = /^[0-9\.]*$/;

        if (re.test(value)) this.updateValue(value);
        else this.forceUpdate(); // update the screen
      } else {
        const cleanedValue = shouldTrimSpace
          ? value.replace(/\&nbsp;/g, "")
          : value;
        this.updateValue(cleanedValue);
      }
    } else {
      this.updateValue(inputValue);
    }
  };

  renderView = () => {
    const { textStyle, inputStyle, textAreaStyles, onClick } = this.props;
    return (
      <div
        onClick={onClick}
        style={{ ...textStyle, ...inputStyle, ...textAreaStyles }}
        className={classes.inputText}
        dangerouslySetInnerHTML={{ __html: this.getViewValue() }}
      />
    );
  };

  renderEdit = () => {
    const { error } = this.state;
    const { placeholder, style, value = "", disabled, allowEnter } = this.props;

    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.error]: !!error },
      { [classes.whiteSpace]: allowEnter }
    );

    return (
      <ContentEditable
        style={style}
        innerRef={this.updateDomRef}
        className={containerClass}
        placeholder={placeholder}
        html={value}
        disabled={disabled}
        onChange={e => this.handleChange(e.target.value)}
        onBlur={() => this.onBlur({ value: this.props.value })}
        onFocus={e => this.onFocus({ value: e.target.value })}
        onPaste={this.handleTextPaste}
        onKeyDown={this.handleKeyDown}
      />
    );
  };
}

ContentEditableComp.defaultProps = {
  ...UIBaseComponent.defaultProps,
  shouldParseInPlainText: true,
  shouldPasteAsPlainText: true,
  allowEnter: false,
  onClick: () => {},
  textStyle: {},
  inputStyle: {},
  textAreaStyles: {},
  shouldTrimSpace: true,
};

ContentEditableComp.propTypes = {
  ...UIBaseComponent.propTypes,
  editing: PropTypes.bool,
  shouldParseInPlainText: PropTypes.bool,
  shouldPasteAsPlainText: PropTypes.bool,
  allowEnter: PropTypes.bool,
  onClick: PropTypes.func,
  textStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  textAreaStyles: PropTypes.object,
  shouldTrimSpace: PropTypes.bool,
};

export default ContentEditableComp;
