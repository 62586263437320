import React, { useState, useCallback, useRef, useEffect } from "react";
import Table from "./Components/Table";
import classes from "./NodeEditor.scss";
import Header from "./Components/Header";
import { connect } from "react-redux";
import Sidebar from "./Components/Sidebar";
import { goToBack } from "modules/NavigationModule";
import FilterHeader from "./Components/FilterHeader";
import { getStandardBenchmarkSetQuery } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorQueries";
import { getBenchmarkSetDetailsFromCache } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorGraphqlHelpers";
import {
  updateFilters,
  resetNodeEditorFilters,
  updateSnsSettingsAction,
  toggleNodeEditorMode,
  setSidebarSelectedItem,
} from "AppComponents/MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule";
import {
  buildSearchFlatTree,
  filterNodeIdsByGrades,
  buildTagsFlatTree,
} from "Utils";
import { mergeSelectedNodesMemoize } from "AppComponents/MultiLevelNodeEditor/routes/Levels/Utils";
import TableBulkActions from "./Components/TableBulkActions";
import {
  getSelectedStandardsCount,
  getNodeEditorConfigMemoize,
  columnIds,
  isActionDisableValidate,
  modifyPlannerElements,
  nodeTypeSettingsNameMap,
  getMultiLevelNodeTagsFilters,
  getPlannerElementConfig,
  getRootNodeIds,
  getOrganisationTagsFromSubjectMemoize,
} from "NodeEditor/Utils";
import { colors } from "Constants";
import { graphql, compose } from "react-apollo";
import {
  withLoader,
  DialogueBox,
  NoDataComponent,
  I18nHOC,
} from "UIComponents";
import {
  getMultiLevelNodesQuery,
  getOrganizationMultiLevelNodeTagsQuery,
} from "modules/CommonQuery";
import {
  getOrganizationMultiLevelNodeTagsFromCache,
  getPlatformUserDetailsFromCache,
} from "modules/CommonGraphqlHelpers";
import {
  getMultiLevelNodeDraftFromCache,
  getSubjectDetailsFromCache,
  getPlannerElementSetFromCache,
} from "./Modules/NodeEditorGraphqlHelpers";
import {
  updateMultiLevelNodesDraft,
  createMultiLevelNodesDraft,
  deleteMultiLevelNodesDraft,
  publishMultiLevelNodeDraft,
} from "./Modules/NodeEditorModule";
import { goToRelativeRoute, updateSettings } from "modules/Services";
import {
  getMultiLevelNodeDraftQuery,
  getSubjectDetailsQuery,
  getPlannerElementSetQuery,
} from "./Modules/NodeEditorQueries";
import CoachMarks from "AppComponents/CoachMarks";
import { disableEditForMapleBearChildOrg } from "Courses/modules/utils";

const styles = {
  nodeDataCompContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: "0px 28px 36px 40px",
    border: `1px solid ${colors.strokeOne}`,
    borderRadius: "6px",
  },
};

//this array contains extra keys that are created by frontend in a node object.
//we restrict these keys from going into backend mutation for updating drafts.
const frontEndKeysInNode = ["isChecked", "isError"];

const buildSearchFlatTreeMemoize = _.memoize(
  params => buildSearchFlatTree(params),
  params => JSON.stringify(params)
);

const filterNodeIdsByGradesMemoize = _.memoize(
  params => filterNodeIdsByGrades(params),
  params => JSON.stringify(params)
);

const buildTagsFlatTreeMemoize = _.memoize(
  params => buildTagsFlatTree(params),
  params => JSON.stringify(params)
);

const getPlaceHoldersText = ({ levels = [], addRootNode }) => {
  let updatedLevels = levels;
  if (addRootNode)
    // planner elements do not have subject level but benchmarks do
    updatedLevels = [{ id: "L0", value: "Subject" }, ...levels];

  const labelPlaceholdersText = {};

  _.map(updatedLevels, ({ value }, index) => {
    labelPlaceholdersText[index] = value;
  });

  return labelPlaceholdersText;
};
const nodesObjectMap = ({ nodesData }) => _.keyBy(nodesData, "id");

const getGradeOptions = ({ grades }) =>
  _.map(grades, ({ id, name }, index) => ({
    value: id,
    label: name,
    id,
    displaySequence: index,
  }));

const getOrganizationTags = ({ organizationTags }) =>
  _.map(organizationTags, ({ id, label, icon }) => ({
    id,
    label,
    value: id,
    icon,
  }));

const NodeEditor = props => {
  const {
    goToBack,
    levels,
    filters,
    updateFilters,
    resetNodeEditorFilters,
    description,
    updateSnsSettingsAction,
    maxDepth,
    initialDraftId,
    rootNodes,
    rootNode,
    updateMultiLevelNodesDraft,
    createMultiLevelNodesDraft,
    deleteMultiLevelNodesDraft,
    goToRelativeRoute,
    isSetPreview,
    onBackClick,
    userId,
    toggleNodeEditorMode,
    nodeEditorMode: mode,
    onClickChoose,
    publishMultiLevelNodeDraft,
    organizationTags,
    setSidebarSelectedItem,
    nodeType,
    loginActiveTab,
    showEditButton,
    openedFrom,
    selectedTaggedIds,
    value,
    updateValueLocally,
    onSubmitClick,
    customRef,
    showViewModeOnly = false,
    uptoDepth,
    selectedNodes,
    plannerElementParentNode,
    customOnSettingsItemClick,
    getMultiLevelNodes,
    isEditButtonDisabled,
    disableToolTipMsg,
    configuredColumns,
    updateSettings,
    settingsName,
    addRootNodeTagging,
    t,
    maxSidebarDepth,
    plannerElementSetConfig,
    plannerElementSets,
    multiLevelNodeTagsFilters,
    nodeEditorConfigProp,
  } = props;

  //This is used to identify if the scroll is done manually or automatically (like when we click on sidebar)
  const isScrollManual = useRef(true);
  const [taggedNodeIds, setTaggedNodeIds] = useState(value || []);
  // to return the tagged node IDs using ref (currently used in Progress Report)
  React.useImperativeHandle(customRef, () => ({
    getTaggedIds: () => {
      return taggedNodeIds;
    },
    multiLevelNodesRefetch: () => {
      if (getMultiLevelNodes) getMultiLevelNodes.refetch();
    },
  }));

  const [requiredColumns, setRequiredColumns] = useState(configuredColumns);

  const [draftId, setDraftId] = useState(initialDraftId);
  const [organizationTagsObjectMap, setOrganizationTagsObjectMap] = useState(
    false
  );
  //when we get any error while updating drafts, we freeze the screen
  const [draftError, setDraftError] = useState(false);
  //this state is used for coachmarks
  const [isCoachmarksRunning, setIsCoachmarksRunning] = useState(false);

  // Configuration for the node editor
  const showStarQues = nodeType == "BENCHMARK";
  const nodeEditorConfig = getNodeEditorConfigMemoize({
    configuredColumns,
    openedFrom,
    nodeType,
    showStarQues,
    addRootNodeTagging,
    nodeEditorConfigProp,
  });
  const [currentEditorConfig, setCurrentEditorConfig] = useState(
    nodeEditorConfig
  );
  //reset filters when unmounted
  useEffect(() => {
    return () => {
      resetNodeEditorFilters();
    };
  }, [resetNodeEditorFilters]);

  useEffect(() => {
    const nodeEditorConfig = getNodeEditorConfigMemoize({
      configuredColumns: requiredColumns,
      openedFrom,
      nodeType,
      showStarQues,
      addRootNodeTagging,
      nodeEditorConfigProp,
    });
    setCurrentEditorConfig(nodeEditorConfig);
  }, [requiredColumns]);

  useEffect(() => {
    const { grades, tags } = props;
    if (!_.isEmpty(grades)) {
      updateFilters({ key: "grades", data: grades });
    }
    if (!_.isEmpty(tags)) {
      updateFilters({ key: "tags", data: tags });
    }
  }, []);

  useEffect(() => {
    if (updateValueLocally) updateValueLocally(taggedNodeIds);
  }, [taggedNodeIds]);

  useEffect(() => {
    if (!_.isEmpty(initialDraftId)) {
      setDraftId(initialDraftId);
    }
    return () => {
      setDraftId("");
    };
  }, [initialDraftId]);

  const addRootNode = _.get(currentEditorConfig, "addRootNodeTagging", false);

  //setting up the nodes state
  const [nodes, setNodes] = useState({});
  useEffect(() => {
    // VERY IMP: for planner elements nodes do not have subject node so manually adding it along with parent-child relationship with level 1 nodes
    // This function also modifies the PlannerElementNode and PlannerBenchmark object to the format required by this node editor
    const nodesData = modifyPlannerElements({
      nodesData: props.nodes,
      plannerElementParentNode,
      rootNodes,
      addRootNode,
    });

    const initialNodes = nodesObjectMap({ nodesData });
    setNodes(_.cloneDeep(initialNodes));
    return () => {
      setNodes({});
    };
  }, [props.nodes, mode]);

  const getRootNodeId = () => {
    if (addRootNode) return plannerElementParentNode.id;

    return isSetPreview
      ? _.get(props, "rootNodes[0]", "")
      : _.get(props, "rootNode.id", "");
  };

  const rootNodeId = getRootNodeId();

  //whenever the organization tags are updated, we have to update obj map of that
  useEffect(() => {
    //creating object map of organization tags for faster access
    setOrganizationTagsObjectMap(
      _.keyBy(getOrganizationTags({ organizationTags }), "id")
    );
  }, [organizationTags]);

  // subject name and grades come from different objects for planner elements and benchmarks
  const getSubjectNameGrades = () => {
    if (nodeType == "BENCHMARK")
      return {
        name: _.get(nodes, `[${rootNodeId}].label`, ""),
        grades: _.get(rootNode, "grades", []),
      };
    return {
      name: _.get(plannerElementParentNode, "name", ""),
      grades: _.get(plannerElementParentNode, "grades", []),
    };
  };
  const subjectObj = getSubjectNameGrades();

  const subjectId = _.get(props, "subject", "");
  const headerLabel = subjectObj.name;
  const nodeRefs = useRef({});

  //this is the maximum depth of the editor.
  const placeholdersText = getPlaceHoldersText({ levels, addRootNode });
  const gradeOptions = _.keyBy(
    getGradeOptions({ grades: subjectObj.grades }),
    "value"
  );
  const setNodesRef = ({ id, ref }) => {
    nodeRefs.current[id] = ref;
  };

  const searchNodeIdsBySearch = buildSearchFlatTreeMemoize({
    searchText: filters.searchText,
    nodes,
    isNewNodeEditor: true,
  });

  const filterNodeIdsByGrades = filterNodeIdsByGradesMemoize({
    filterKeys: searchNodeIdsBySearch,
    nodes,
    gradeIds: filters.grades,
    selectedTaggedIds,
  });

  //filterNodesIds contains nodes keys which comes after filtering search by text and search by tags
  const filterNodeIds = buildTagsFlatTreeMemoize({
    selectedTagIds: filters.tags,
    nodes,
    filteredKeys: filterNodeIdsByGrades, // keys which came after filtering from searching the text and grades.
    selectedTaggedIds,
    rootNode,
  });

  const shouldRenderNoDataViewPage = _.size(filterNodeIds) < 2; // Size 0 or 1 means there are no nodes
  const shouldRenderNoDataViewSideBar =
    _.size(filterNodeIds) < 2 &&
    _.isEmpty(filters.searchText) &&
    _.isEmpty(filters.grades) &&
    _.isEmpty(filters.tags);
  // //the first one stores the count of checked nodes and the second one contains the ids of them
  const [
    selectedStandardsCount,
    selectedNodeIds,
    unselectedNodeIds,
  ] = getSelectedStandardsCount({
    nodes,
    filterNodeIds,
  });

  const canEdit = showEditButton && _.get(currentEditorConfig, "canEdit", true);
  const showSelectionBox = _.get(
    currentEditorConfig,
    "showSelectionBox",
    false
  );
  const showUptoDepth = _.get(currentEditorConfig, "showUptoDepth", false);
  const showHeader = _.get(currentEditorConfig, "showHeader", true);
  const showSidebar = _.get(currentEditorConfig, "showSidebar", true);
  const warningText =
    _.get(currentEditorConfig, "warningText", true) &&
    !showViewModeOnly &&
    !disableEditForMapleBearChildOrg();

  //if there are any ids in unselectedNodeIds, uncheck them
  if (!_.isEmpty(unselectedNodeIds)) {
    const uncheckedNodes = {};
    for (const nodeId of unselectedNodeIds) {
      uncheckedNodes[nodeId] = { ...nodes[nodeId], isChecked: false };
    }
    setNodes(prevNodes => {
      return { ...prevNodes, ...uncheckedNodes };
    });
  }

  const inputForMultiLevelNodeQuery = {
    organizationId: props.organizationId,
    subjectId: props.subject,
    type: nodeType,
  };

  const inputForMultiLevelTagsQuery = {
    organizationId: props.organizationId,
    filters: multiLevelNodeTagsFilters,
  };

  const getMaxDepth = () => {
    if (addRootNode) return maxDepth;
    return maxDepth - 1; //backend returns 1 more than the actual maxDepth value for BENCHMARK
  };

  const maxDepthCalculated = getMaxDepth();

  const draftErrorData = {
    modalTitle: t("scopeAndSequence:error_occurred_title"),
    modalBody: t("scopeAndSequence:error_occurred_content"),
    modalBodyStyle: {
      paddingTop: "24px",
      paddingRight: "48px",
    },
    footerContainerStyle: {
      paddingRight: "48px",
    },
  };

  const onUpdateSettings = ({ value }) => {
    updateSettings({
      id: userId,
      type: "user",
      settings: [{ name: settingsName, value: JSON.stringify(value) }],
      showToastMsg: false,
    });
    setRequiredColumns(value);
  };

  const onCheckBoxClick = ({ id }) => {
    let updatedToggleNodeIds = [];
    const valuesToAdd = [];
    const valuesToRemove = [];
    if (_.includes(taggedNodeIds, id)) {
      valuesToRemove.push(id);
      updatedToggleNodeIds = _.filter(taggedNodeIds, nodeId => nodeId != id);
    } else {
      valuesToAdd.push(id);
      updatedToggleNodeIds = [...taggedNodeIds, id];
    }

    setTaggedNodeIds(updatedToggleNodeIds);
    const nodesArray = [];
    _.forOwn(nodes, node => {
      nodesArray.push(node);
    });

    setTimeout(() => {
      onSubmitClick({
        valuesToAdd,
        valuesToRemove,
        shouldCloseModal: false,
        taggedIds: updatedToggleNodeIds,
        nodes: nodesArray,
      });
    });
  };

  //updating drafts
  const updateNodesInDraft = useCallback(
    async ({ removedNodes = [], updatedNodes = [] }) => {
      if (!_.isEmpty(updatedNodes)) {
        let updatedRootNodes = [];
        const filteredUpdatedNodes = [];

        // VERY IMP: removing the subject node for planner elements which as added when the nodes array
        // was created along with parent-child relations with depth 1 nodes

        _.forEach(updatedNodes, node => {
          const newNode = { ...node };
          for (const key of Object.keys(newNode)) {
            if (_.includes(frontEndKeysInNode, key)) {
              delete newNode[key];
            }
          }
          if (!addRootNode) filteredUpdatedNodes.push(newNode);
          else if (node.depth != 0) {
            if (node.depth == 1) newNode.parentId = null;
            filteredUpdatedNodes.push(newNode);
          }
          if (addRootNode && node.depth == 0) updatedRootNodes = node.children; // to maintain level 1 nodes order upon drag & drop in case of planner elements
        });

        const input = {
          draftId,
          rootNodes: addRootNode ? updatedRootNodes : rootNodes,
          removedNodes,
          updatedNodes: filteredUpdatedNodes,
        };
        const isSuccess = await updateMultiLevelNodesDraft(input);
        if (!isSuccess) {
          setDraftError(true);
        }
      }
    },
    [draftId, rootNodes, setDraftError, updateMultiLevelNodesDraft, addRootNode]
  );

  const isActionDisabled = isActionDisableValidate({ filters });
  //this function is used for coachmarks
  const onStepChanged = ({ isDone, isSkipped }) => {
    if (isDone || isSkipped) {
      setIsCoachmarksRunning(false);
    } else if (!isCoachmarksRunning) {
      setIsCoachmarksRunning(true);
    }
  };

  const disableFilters = selectedStandardsCount > 0;

  return (
    <div
      className={classes.container}
      id={
        mode === "view"
          ? "COACHMARKS_SCOPE_SEQUENCE_VIEWER"
          : "COACHMARKS_SCOPE_SEQUENCE_EDITOR"
      }
    >
      {mode === "edit" && (
        <CoachMarks
          type={"SCOPE_SEQUENCE_EDITOR"}
          onStepChanged={onStepChanged}
        />
      )}
      {mode == "view" && !_.isEmpty(nodes) && (
        <CoachMarks
          params={{ subjectLabel: subjectObj.name }}
          type={"SCOPE_SEQUENCE_VIEWER"}
          onStepChanged={onStepChanged}
        />
      )}
      {showHeader && (
        <Header
          mode={mode}
          onChangeMode={toggleNodeEditorMode}
          goToBack={goToBack}
          headerLabel={headerLabel}
          description={description}
          updateSnsSettingsAction={updateSnsSettingsAction}
          draftId={draftId}
          setDraftId={setDraftId}
          nodes={props.nodes}
          rootNodes={rootNodes}
          nodeType={props.nodeType}
          subjectId={subjectId}
          inputForMultiLevelNodeQuery={inputForMultiLevelNodeQuery}
          currentEditorConfig={currentEditorConfig}
          setNodes={setNodes}
          nodesObjMap={nodes}
          createMultiLevelNodesDraft={createMultiLevelNodesDraft}
          deleteMultiLevelNodesDraft={deleteMultiLevelNodesDraft}
          rootNodeId={rootNodeId}
          goToRelativeRoute={goToRelativeRoute}
          onBackClick={onBackClick}
          isSetPreview={isSetPreview}
          onClickChoose={onClickChoose}
          publishMultiLevelNodeDraft={publishMultiLevelNodeDraft}
          userId={userId}
          showEditButton={canEdit}
          customOnSettingsItemClick={customOnSettingsItemClick}
          isEditButtonDisabled={isEditButtonDisabled}
          disableToolTipMsg={disableToolTipMsg}
          plannerElementSetConfig={plannerElementSetConfig}
        />
      )}
      <div className={classes.sidebarTableContainer}>
        {showSidebar && (
          <Sidebar
            nodes={nodes}
            rootNodeId={rootNodeId}
            placeholdersText={placeholdersText}
            maxDepth={maxDepthCalculated}
            filterNodeIds={filterNodeIds}
            filters={filters}
            shouldRenderNoDataViewPage={shouldRenderNoDataViewSideBar}
            nodeRefs={nodeRefs}
            currentEditorConfig={currentEditorConfig}
            maxSidebarDepth={maxSidebarDepth}
            isScrollManual={isScrollManual}
          />
        )}

        <div className={classes.tableActionsContainer}>
          <FilterHeader
            filters={filters}
            nodeType={props.nodeType}
            updateFilters={updateFilters}
            gradeOptions={gradeOptions}
            organizationTagsObjectMap={organizationTagsObjectMap}
            draftId={draftId}
            mode={mode}
            loginActiveTab={loginActiveTab}
            showWarningText={warningText}
            configuredColumns={requiredColumns}
            openedFrom={openedFrom}
            onUpdateSettings={onUpdateSettings}
            disableFilters={disableFilters}
          />

          {selectedStandardsCount > 0 && mode == "edit" && (
            <TableBulkActions
              nodes={nodes}
              setNodes={setNodes}
              selectedStandardsCount={selectedStandardsCount}
              selectedNodeIds={selectedNodeIds}
              updateNodesInDraft={updateNodesInDraft}
              currentEditorConfig={currentEditorConfig}
              rootNodeId={rootNodeId}
              organizationTags={organizationTags}
              inputForMultiLevelTagsQuery={inputForMultiLevelTagsQuery}
              organizationTagsObjectMap={organizationTagsObjectMap}
              userId={userId}
              gradeOptions={gradeOptions}
            />
          )}

          {shouldRenderNoDataViewPage ? (
            <NoDataComponent
              emptyText={t("scopeAndSequence:emptyText_search")}
              containerStyle={styles.nodeDataCompContainer}
            />
          ) : (
            <Table
              mode={mode}
              nodes={nodes}
              setNodes={setNodes}
              rootNodeId={rootNodeId}
              maxDepth={maxDepthCalculated}
              setNodesRef={setNodesRef}
              setSidebarSelectedItem={setSidebarSelectedItem}
              levels={levels}
              placeholdersText={placeholdersText}
              gradeOptions={gradeOptions}
              filters={filters}
              updateNodesInDraft={updateNodesInDraft}
              userId={userId}
              currentEditorConfig={currentEditorConfig}
              columnIds={columnIds}
              organizationTags={organizationTags}
              inputForMultiLevelTagsQuery={inputForMultiLevelTagsQuery}
              organizationTagsObjectMap={organizationTagsObjectMap}
              filterNodeIds={filterNodeIds}
              isActionDisabled={isActionDisabled}
              isCoachmarksRunning={isCoachmarksRunning}
              showSelectionBox={showSelectionBox}
              onCheckBoxClick={onCheckBoxClick}
              taggedNodeIds={taggedNodeIds}
              uptoDepth={uptoDepth}
              showUptoDepth={showUptoDepth}
              selectedNodes={selectedNodes}
              selectedTaggedIds={selectedTaggedIds}
              maxSidebarDepth={maxSidebarDepth}
              isScrollManual={isScrollManual}
              plannerElementSets={plannerElementSets}
              nodeType={nodeType}
            />
          )}
        </div>
      </div>
      {draftError && (
        <DialogueBox
          modalTitle={draftErrorData.modalTitle}
          showModal={true}
          modalBody={draftErrorData.modalBody}
          modalBodyStyle={draftErrorData.modalBodyStyle}
          footerContainerStyle={draftErrorData.footerContainerStyle}
          showCloseButton={false}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  goToBack,
  updateFilters,
  resetNodeEditorFilters,
  updateMultiLevelNodesDraft,
  updateSnsSettingsAction,
  createMultiLevelNodesDraft,
  deleteMultiLevelNodesDraft,
  goToRelativeRoute,
  toggleNodeEditorMode,
  publishMultiLevelNodeDraft,
  setSidebarSelectedItem,
  updateSettings,
};

const mapStateToProps = (state, ownProps) => {
  const filters = state.multiLevelNodeEditor.searchFilters;
  const { snsSettingsAction, nodeEditorMode } = state.multiLevelNodeEditor;
  const userId = state.login.userInfo.id;
  const userEntityType = state.login.userInfo.userEntityType;
  const organizationId = state.login.userInfo.org_id;
  const {
    isSetPreview = false,
    openedFrom,
    nodeType,
    resolvedMinimalTreeNodes,
  } = ownProps;
  let settingsName = "";
  let configuredColumns = [];
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );

  if (openedFrom) {
    const userDetails = getPlatformUserDetailsFromCache({
      id: userId,
      type: userEntityType,
    });
    const settings = _.get(userDetails, "settings", []);
    settingsName = nodeTypeSettingsNameMap[nodeType];
    const columnsSetting = _.find(settings, ({ name }) => name == settingsName);
    const configuredColumnsStr = _.get(columnsSetting, "value", "[]");
    configuredColumns = JSON.parse(configuredColumnsStr);
  }
  const multiLevelNodeTagsFilters = getMultiLevelNodeTagsFilters({ nodeType });
  const maxSidebarDepth = _.get(
    getPlannerElementConfig({ nodeType }),
    "maxSidebarDepth",
    ""
  );
  const currentCurriculumProgram = _.get(
    state,
    "platform.currentCurriculumProgram",
    {}
  );

  let nodes, rootNodes;

  const shouldUseResolvedMinimalTreeNodes = !_.isEmpty(
    resolvedMinimalTreeNodes
  );

  if (shouldUseResolvedMinimalTreeNodes) {
    nodes = resolvedMinimalTreeNodes;
    rootNodes = _.reduce(
      nodes,
      (result, node) => {
        /**
         * NOTE: We can't use parent id here as for root nodes,
         * as for root node, parent id is id of subject
         */
        const isRootNode = node.parent == null;

        if (isRootNode) {
          return [...result, node.id];
        }
        return result;
      },
      []
    );
  }

  return {
    nodeEditorMode,
    snsSettingsAction,
    userId,
    filters,
    isData: true,
    isLoading: false,
    isSetPreview,
    organizationId,
    configuredColumns,
    settingsName,
    multiLevelNodeTagsFilters,
    maxSidebarDepth,
    currentCurriculumProgram,
    nodes,
    rootNodes,
    shouldUseResolvedMinimalTreeNodes,
    plannerElementSets,
  };
};

/*There must be atlease 1 parent in SNS editor in most cases it will be subject but if we dont have subject mapped
  with the planner element we have to create a dummy root node*/

const NodeEditorWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  I18nHOC({ resource: ["common", "scopeAndSequence"] }),
  graphql(getSubjectDetailsQuery, {
    name: "getSubjectDetails",
    skip: ({ subject }) => !subject,
    options: ({ subject }) => ({
      fetchPolicy: "cache-and-network",
      variables: { subject },
    }),
    props({
      getSubjectDetails,
      ownProps: { isData, isLoading, subject, organizationTags, nodeType },
    }) {
      const queryData = getSubjectDetailsFromCache({ subject });
      const subjectGrades = _.get(queryData, "grades", []);
      let rootNode = _.get(queryData, "benchmarkRootNode", {});
      rootNode = { ...rootNode, grades: subjectGrades };
      const subjectName = _.get(queryData, "name");
      if (_.includes(["DP_SYLLABUS"], nodeType)) {
        organizationTags = getOrganisationTagsFromSubjectMemoize({
          subjectVariants: _.get(queryData, "variants", []),
        });
      }
      return {
        rootNode,
        subjectName,
        plannerElementParentNode: _.omit(queryData, ["benchmarkRootNode"]),
        organizationTags,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          _.includes([1, 2], getSubjectDetails["networkStatus"]) || isLoading,
      };
    },
  }),
  graphql(getMultiLevelNodesQuery, {
    name: "getMultiLevelNodes",
    skip: ({
      nodeEditorMode,
      isSetPreview,
      subject,
      shouldUseResolvedMinimalTreeNodes,
    }) =>
      (nodeEditorMode == "edit" && isSetPreview) ||
      _.isEmpty(subject) ||
      shouldUseResolvedMinimalTreeNodes,
    options: ({ organizationId, subject, nodeType }) => ({
      // INTENTIONALLY PUT NETWORK-ONLY TO AVOID ISSUES IN PYP BULK UPLOAD
      fetchPolicy: "network-only",
      variables: {
        organizationId,
        subjectId: subject,
        type: nodeType,
      },
    }),
    props({
      getMultiLevelNodes,
      ownProps: {
        isData,
        isLoading,
        organizationId,
        nodeType,
        subject,
        selectedNodes,
      },
    }) {
      const queryData = _.get(getMultiLevelNodes, "node", {});

      const { levels, nodes, rootNodes, maxDepth, draftId } = _.get(
        queryData,
        "getMultiLevelNodes",
        {}
      );

      let benchmarks = nodes;

      if (!_.isEmpty(selectedNodes)) {
        benchmarks = mergeSelectedNodesMemoize({
          nodes: nodes,
          selectedNodes,
        });
      }
      return {
        getMultiLevelNodes,
        levels,
        nodes: benchmarks,
        rootNodes,
        maxDepth,
        initialDraftId: draftId,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          _.includes([1, 2], getMultiLevelNodes["networkStatus"]) || isLoading,
      };
    },
  }),
  graphql(getMultiLevelNodeDraftQuery, {
    name: "getMultiLevelNodeDraft",
    skip: ({ nodeEditorMode, isSetPreview, initialDraftId }) =>
      nodeEditorMode == "view" || isSetPreview || !initialDraftId,
    options: ({ initialDraftId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        draftId: initialDraftId,
      },
    }),
    props({
      getMultiLevelNodeDraft,
      ownProps: { isData, isLoading, initialDraftId, maxDepth: maxDepths },
    }) {
      const queryData = getMultiLevelNodeDraftFromCache({
        draftId: initialDraftId,
      });

      const { levels, nodes, rootNodes, maxDepth, id } = queryData;
      return {
        levels,
        nodes,
        rootNodes,
        maxDepth,
        initialDraftId: id,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          _.includes([1, 2], getMultiLevelNodeDraft["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  graphql(getStandardBenchmarkSetQuery, {
    name: "getStandardBenchmarkSet",
    skip: ({ isSetPreview, nodeEditorMode }) =>
      !isSetPreview || nodeEditorMode == "edit",
    options: ({ organizationId, setPreviewId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        id: setPreviewId,
      },
    }),
    props({
      getStandardBenchmarkSet,
      ownProps: { isData, isLoading, organizationId, setPreviewId },
    }) {
      const queryData = getBenchmarkSetDetailsFromCache({
        organizationId,
        id: setPreviewId,
      });

      const { levels, nodes, rootNodes, maxDepth } = _.get(
        queryData,
        "benchmarks",
        {}
      );
      const { description } = queryData;

      return {
        description,
        levels,
        nodes,
        rootNodes,
        maxDepth,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          _.includes([1, 2], getStandardBenchmarkSet["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationMultiLevelNodeTagsQuery, {
    name: "getOrganizationMultiLevelNodeTags",
    skip: ({ nodeType, subject }) =>
      _.includes(["DP_SYLLABUS"], nodeType) || !subject,
    options: ({ organizationId, multiLevelNodeTagsFilters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
        filters: multiLevelNodeTagsFilters,
      },
    }),
    props({
      getOrganizationMultiLevelNodeTags,
      ownProps: {
        isData,
        isLoading,
        organizationId,
        nodeType,
        multiLevelNodeTagsFilters,
      },
    }) {
      const queryData = getOrganizationMultiLevelNodeTagsFromCache({
        organizationId,
        nodeType,
        filters: multiLevelNodeTagsFilters,
      });
      const tagsList = _.get(queryData, "genericTagsV2", []);

      return {
        organizationTags: tagsList,
        isData: !_.isEmpty(queryData) && isData,
        isLoading:
          _.includes(
            [1, 2],
            getOrganizationMultiLevelNodeTags["networkStatus"]
          ) || isLoading,
      };
    },
  }),
  graphql(getPlannerElementSetQuery, {
    name: "getPlannerElementSet",
    skip: ({ subject }) => !_.isEmpty(subject),
    options: ({ plannerElementSets, nodeType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: plannerElementSets[nodeType]?.id,
      },
    }),
    props({
      getPlannerElementSet,
      ownProps: {
        isData,
        isLoading,
        plannerElementSets,
        nodeType,
        dummyRootNode,
      },
    }) {
      const plannerElementData = getPlannerElementSetFromCache({
        id: plannerElementSets[nodeType]?.id,
      });
      const nodes = _.get(plannerElementData, "nodes", []);
      const rootNodes = getRootNodeIds({ nodes });
      //We need to provide a rootnode to SNS editor for its proper working
      const rootNode = dummyRootNode;

      return {
        nodes,
        rootNodes,
        maxDepth: 0,
        rootNode,
        plannerElementParentNode: rootNode,
        isData: !_.isEmpty(plannerElementData) && isData,
        isLoading:
          _.includes([1, 2], getPlannerElementSet["networkStatus"]) ||
          isLoading,
      };
    },
  }),
  withLoader
)(NodeEditor);

export default NodeEditorWrapper;

NodeEditorWrapper.defaultProps = {
  nodeEditorConfigProp: {},
  resolvedMinimalTreeNodes: [],
};
