import React from "react";
import _ from "lodash";
import commonClasses from "../common.scss";
import classes from "./ImportantDates.scss";
import moment from "moment";

import classNames from "classnames";
import { UIButton, I18nHOC } from "UIComponents";
import { ImportantDates, CTAButtonText } from "Planathon/data";
const ImportantDatesComp = props => {
  const { onInClick, isRequestedForPlanathon, t } = props;
  const { title, steps, backgroundImages, disclaimerText } = ImportantDates;
  const containerClass = classNames(
    { [commonClasses.container]: true },
    { [classes.container]: true }
  );

  let updatedSteps = [...steps];
  if (isRequestedForPlanathon) {
    updatedSteps.splice(0, 1);
  }
  return (
    <div className={classes.mainContainer}>
      {_.map(backgroundImages, backgroundImage => {
        const { image, style, name } = backgroundImage;
        return <img src={image} style={style} key={name}></img>;
      })}
      <div className={containerClass}>
        <div className={commonClasses.title} style={{ textAlign: "center" }}>
          {t(title)}
        </div>
        <div className={classes.stepsContainer}>
          {_.map(updatedSteps, (step, index) => {
            const { title, color, date } = step;

            const monthDate = moment(date, "DD-MM-YYYY").format("Do MMM");
            const year = moment(date, "DD-MM-YYYY").format("YYYY");
            return (
              <div className={classes.step} key={index}>
                <div className={classes.dateText} style={{ color: color }}>
                  {monthDate}
                </div>
                <div className={classes.year}>{year}</div>
                <div className={classes.details}>{t(title)}</div>
              </div>
            );
          })}
        </div>
        {/* {!isRequestedForPlanathon && (
          <div className={classes.button}>
            <UIButton
              color={"pink"}
              onClick={onInClick}
              containerStyle={{ height: 56 }}
            >
              {`Yes, I'm  in`}
            </UIButton>
          </div>
        )} */}
        <div className={classes.disclaimerText}>{`${t(
          "planathon:tc_disclaimer"
        )} - ${t("tc_disclaimer_description")}`}</div>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["planathon"] })(ImportantDatesComp);
