import PropTypes from "prop-types";
import React from "react";
import classes from "./AssessmentFeed.scss";
import { ResourceCardV1 } from "AppComponents";
import { colors } from "Constants";
import { Tick, CheckTickOff, ChevronIcon } from "SvgComponents";
import { Checkbox } from "UIComponents";
const theme = {
  sectionItem: {
    paddingTop: 32,
    paddingBottom: 32,
    borderBottomWidth: 1,
    borderBottomColor: `${colors.strokeTwo}`,
    borderBottomStyle: "solid",
    cursor: "pointer",
  },
  sectionLabel: {
    textDecoration: "none",
  },
  rightContainer: {
    flex: 1,
  },
};

{
  /* <CheckTickOff height={24} width={24} fill={colors.strokeOne} /> */
}

const ItemRightContainer = ({ type, isChecked }) => {
  return type == "ASSESSMENT_EVALUATION" ? (
    <div className={classes.chevron}>
      <ChevronIcon width={16} height={16} fill={colors.gray31} />
    </div>
  ) : (
    <Checkbox
      fill={colors.blue29}
      offFill={colors.strokeTwo}
      isChecked={isChecked}
      style={{ width: 24, height: 24 }}
      checkBoxParentContainer={{ display: "block", width: "fit-content" }}
    />
  );
};

class AssessmentFeed extends React.PureComponent {
  isSelected = itemId => {
    if (this.props.selectedAssessments) {
      return !(
        _.findIndex(this.props.selectedAssessments, function (activityId) {
          return activityId == itemId;
        }) == -1
      );
    } else {
      return false;
    }
  };

  selectAssessment = params => {
    const value = true;
    let selectedAssessments = [...this.props.selectedAssessments];

    // let index = _.findIndex(selectedAssessments, function(activity) {
    //   return activity.id == params.id;
    // });

    if (value) {
      //add to the list
      // if (index == -1) {
      //    index = selectedAssessments.length;
      // }
      selectedAssessments = [params.id];
      // selectedAssessments = update(selectedAssessments, {
      //   $splice: [[index, 0, params]]
      // });
    } else {
      //remove from the list
      // selectedAssessments = update(selectedAssessments, {
      //   $splice: [[index, 1]]
      // });
      selectedAssessments = [];
    }

    //console.log(selectedAssessments);

    this.props.updateSelectedAssessments(selectedAssessments);
  };

  getItemList = (filteredItems, type) => {
    return _.map(filteredItems, (opt, index) => {
      const isChecked = this.isSelected(opt.id);
      const isDisabled =
        type == "ASSESSMENT_EVALUATION" && opt.evaluations.totalCount > 0
          ? true
          : false;

      return (
        <div key={index} className={classes.resourceFeedItemDiv}>
          <ResourceCardV1
            item={opt}
            index={index}
            isDisabled={isDisabled}
            onContainerClick={() => this.selectAssessment({ id: opt.id })}
            rightComponent={
              <ItemRightContainer isChecked={isChecked} type={type} />
            }
            theme={theme}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.resourceFeedContainerClass}>
          {this.getItemList(this.props.items, this.props.type)}
        </div>
      </div>
    );
  }
}

export default AssessmentFeed;
