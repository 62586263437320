import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classes from "./StudentSelectionStep.scss";
import { UIButton, I18nHOC } from "UIComponents";
import classNames from "classnames";
import { colors } from "Constants";
import { getCountString } from "Utils";
import { EmptySvgIcon, CancelIcon, ChevronIcon } from "SvgComponents";
import { StudentModalFeed } from "AppComponents";
import { get as _get, findIndex as _findIndex } from "lodash";
import { compose } from "react-apollo";

const EmptyView = ({ t }) => {
  return (
    <div className={classes.noDataContainer}>
      <EmptySvgIcon />
      <div className={classes.createText}>{t("common:no_students_found")}</div>
      <div className={classes.createSubText}>
        {t("common:no_students_found_msg")}
      </div>
    </div>
  );
};

class StudentSelectionStep extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showEmptyScreen: false,
      isAddButtonDisabled: false,
    };
  }

  updateStudentArray = includedStudents => {
    const { updateResourceItems, updateAddButtonState } = this.props;

    updateAddButtonState(includedStudents.length > 0);

    updateResourceItems({
      key: "includedStudents",
      value: includedStudents,
    });
  };

  onStepChanged = () => {
    this.props.updateState({ resourceItems: [] });
    this.props.onStepChanged();
  };

  onClose = () => {
    const { onClose, updateState } = this.props;
    onClose();
    updateState({ resourceItems: [] });
  };

  onBackClicked = () => {
    this.setState({ showEmptyScreen: false });
    this.props.updateResourceItems({
      key: "includedStudents",
      value: [],
    });
    this.props.onBackClick();
  };

  render() {
    const {
      includedStudents,
      courseId,
      mode,
      itemsToEdit,
      assessmentEvaluationId,
      type,
      t
    } = this.props;
    const { showEmptyScreen, isAddButtonDisabled } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.leftContainer}>
            {mode === "ADD" ? (
              <div
                className={classes.exitButton}
                onClick={() => this.onBackClicked()}
              >
                <span className={classes.backIcon}>
                  <ChevronIcon fill={colors.salmon60} />
                </span>
                {t("common:back")}
              </div>
            ) : null}
            <div className={classes.resourceFeedHeader}>
              {!showEmptyScreen
                ? t("assessmentEvaluation:select_students_for_evaluation")
                : ""}
            </div>
          </div>
          <div className={classes.cancelIcon} onClick={this.onClose}>
            <CancelIcon width={16} heigth={16} />
          </div>
        </div>
        {!showEmptyScreen ? (
          <React.Fragment>
            <div className={classes.resourceFeed}>
              <StudentModalFeed
                includedStudents={includedStudents}
                updateStudentArray={this.updateStudentArray}
                showEmptyScreen={() => this.setState({ showEmptyScreen: true })}
                courseId={courseId}
                itemsToEdit={itemsToEdit}
                mode={mode}
                ref={ref => (this.studentFeed = ref)}
                assessmentEvaluationId={assessmentEvaluationId}
                type={type}
              />
            </div>
          </React.Fragment>
        ) : (
          <EmptyView t={t} />
        )}
      </div>
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    includedStudents: _get(
      state,
      `assessmentEvaluation.resourceItems.includedStudents`,
      []
    ),
    courseId: ownProps.courseId,
  };
};

export default compose(
  I18nHOC({ resource: ["assessmentEvaluation", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(StudentSelectionStep);
