import React, { memo } from "react";
import classes from "./EventHeader.scss";
import { IconButton } from "@toddle-design/web";
import {
  CloseOutlined,
  DeleteOutlined,
  PencilLineOutlined,
} from "@toddle-design/web-icons";

const EventHeader = props => {
  const {
    userType,
    onClickEdit,
    onClickDelete,
    onCloseEventModal,
    hideEditButton,
    isNotUserCourse,
  } = props;

  return (
    <div className={classes.header}>
      <div className={classes.headerControls}>
        {userType === "staff" && !isNotUserCourse && (
          <>
            {!hideEditButton && (
              <IconButton
                icon={<PencilLineOutlined />}
                variant={"plain"}
                onClick={onClickEdit}
                size="x-small"
              />
            )}

            <IconButton
              icon={<DeleteOutlined />}
              variant={"plain"}
              onClick={onClickDelete}
              size="x-small"
            />
          </>
        )}
        <IconButton
          icon={<CloseOutlined />}
          variant={"plain"}
          onClick={onCloseEventModal}
          size="x-small"
        />
      </div>
    </div>
  );
};

export default memo(EventHeader);
