import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "learner-profile-attributes",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
});
