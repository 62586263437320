import React from "react";
import _ from "lodash";
import commonClasses from "../common.scss";
import classes from "./ParticipantNextStep.scss";
import { colors } from "Constants";
import classNames from "classnames";
import { UIButton, I18nHOC } from "UIComponents";
import { ParticipantNextStepDetails, handbookUrl } from "Planathon/data";
import { BAR_URL } from "Constants";

const ParticipantNextStep = props => {
  const {
    isCurriculumProgramFree,
    downloadFromUrl,
    onNextStepButtonClick,
    demoCourse,
    totalPlanathonUnits,
    t,
  } = props;
  const {
    title,
    description,
    premiumSteps,
    freemiumSteps,
    othersText,
  } = ParticipantNextStepDetails;
  const containerClass = classNames(
    { [commonClasses.container]: true },
    { [classes.container]: true }
  );

  let steps = [];
  if (isCurriculumProgramFree) {
    steps = freemiumSteps;
  } else {
    steps = premiumSteps;
  }

  return (
    <div className={containerClass}>
      {/* <div className={commonClasses.title}>{t(title)}</div>
      <div className={commonClasses.description}>{t(description)}</div> */}
      {/* <div className={classes.stepsContainer}>
        {_.map(steps, (step, index) => {
          const {
            title,
            image,
            buttonName,
            color,
            subTitle,
            buttonDisabled,
            type
          } = step;
          if (type == "SAMPLE_UNIT" && (!demoCourse || _.isEmpty(demoCourse))) {
            return null;
          }

          return (
            <div className={classes.step} key={index}>
              <div className={classes.topContainer}>
                <div className={classes.stepImage}>
                  <img src={image}></img>
                </div>
                <div className={classes.stepTitle} style={{ color }}>
                  {t(title)}
                </div>
                <div className={classes.stepSubTitle}>{t(subTitle)}</div>
              </div>
              <UIButton
                size={"lg"}
                containerStyle={{
                  minWidth: "100%",
                  backgroundColor: buttonDisabled ? colors.white : color,
                  borderColor: buttonDisabled ? colors.strokeOne : color,
                  color: buttonDisabled ? colors.gray72 : colors.white
                }}
                isDisabled={buttonDisabled}
                onClick={() => onNextStepButtonClick({ type })}
              >
                {t(buttonName)}
              </UIButton>
            </div>
          );
        })}
      </div> */}
      <div className={classes.strips}>
        <img src={BAR_URL}></img>
      </div>

      <div className={classes.othersText}>{t(othersText)}</div>
      <div
        className={classes.button}
        onClick={() =>
          downloadFromUrl({
            attachment: {
              url: handbookUrl,
              name: "Toddle_Planathon_Handbook.pdf",
              mimeType: "application/pdf",
              type: "FILE",
            },
            shouldAskForSave: true,
          })
        }
      >
        <UIButton
          containerStyle={{
            backgroundColor: colors.pink39,
            borderColor: colors.pink39,
          }}
        >
          {t("download_handbook")}
        </UIButton>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["planathon"] })(ParticipantNextStep);
