import { initSentry, Sentry } from "./services/sentry";

import classes from "./main.scss";
import { logPageView } from "Utils";
import { FullScreenLoader } from "UIComponents";
import { routes, plainRoutes } from "store/routes";
import { store, browserHistory } from "./store/createStore";
import { initAllThirdPartyModules } from "Login/modules/LoginModule";
import { UnderMaintanance } from "AppComponents";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { hot } from "react-hot-loader/root";

import { syncHistoryWithStore } from "react-router-redux";

import AppContainer from "./containers/AppContainer";
import { ApolloProvider } from "react-apollo";
import client from "./apolloClient";
import { enableLogPageView } from "config";
initAllThirdPartyModules();

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.

const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: state => state.router,
});

history.listen((location, action) => {
  if (enableLogPageView) {
    logPageView(location);
  }
  if (window.Intercom) {
    window.Intercom("update");
  }
});

// ========================================================
// Developer Tools Setup
// ========================================================
// if (__DEBUG__) {
//   if (window.devToolsExtension) {
//     window.devToolsExtension.open();
//   }
// }

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById("root");

initSentry({ plainRoutes });

const isMAintenanceMode = false;
const Main = hot(() => {
  return (
    <Suspense
      fallback={
        <FullScreenLoader overlayContainer={classes.overlayContainer} />
      }
    >
      {isMAintenanceMode ? (
        <UnderMaintanance />
      ) : (
        <ApolloProvider client={client}>
          <AppContainer
            store={store}
            history={history}
            routes={routes}
            // routerKey={routerKey}
          />
        </ApolloProvider>
      )}
    </Suspense>
  );
});

const App = Sentry.withProfiler(Main);
const render = () => {
  // const routes = require("./routes/index").default(store);

  ReactDOM.render(<App />, MOUNT_NODE);
};

// Enable HMR and catch runtime errors in RedBox
// This code is excluded from production bundle
// if (__DEV__ && module.hot) {
//   const renderApp = render;
//   const renderError = error => {
//     const RedBox = require("redbox-react").default;

//     ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
//   };
//   render = () => {
//     try {
//       renderApp(Math.random());
//     } catch (error) {
//       renderError(error);
//     }
//   };
//   module.hot.accept(["./routes/index"], () => render());
// }

// ========================================================
// Go!
// ========================================================
render();
