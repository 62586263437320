export const getMinutesFromTimestamp = timestamp => {
  return Math.floor(timestamp / (1000 * 60)) % 60;
};

export const getSecondsFromTimestamp = timestamp => {
  return Math.floor(timestamp / 1000) % 60;
};

export const formatTimer = timer => {
  if (!timer) return "00:00";
  const minutes = getMinutesFromTimestamp(timer);
  const remainingSeconds = getSecondsFromTimestamp(timer);

  const mm = minutes > 9 ? minutes : `0${minutes}`;
  const ss = remainingSeconds > 9 ? remainingSeconds : `0${remainingSeconds}`;

  return `${mm}:${ss}`;
};

export const prepareAttachmentFromBlob = blob => {
  const audioURL = blob.blobURL;
  const attachment = {
    url: audioURL,
    file: blob.blob,
    type: "AUDIO",
    name: "",
    mimeType: blob.blob.type,
    metadata: { size: _.get(blob, "blob.size", 0) },
  };
  return attachment;
};
