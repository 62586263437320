import React from "react";
import classes from "./LeLibraryEdit.scss";

import { UILabel, I18nHOC } from "UIComponents";

import LibraryBody from "UnitPlans/components/LibraryCommonComponents/LibraryBody";

class LeLibraryEdit extends React.PureComponent {
  onCardClicked = ({ id, mode }) => {
    this.props.showModal({ id, mode });
  };

  onDuplicateClick = (id, promptText) => {
    this.props.duplicateLe({ leId: id, title: promptText });
  };

  render() {
    const {
      props: { les, deleteLe, createLe, getPrintFile, readOnly, t },
    } = this;

    return (
      <div className={classes.container}>
        <UILabel
          label={this.props.label}
          subText={this.props.subText}
          labelComponent={this.props.labelComponent}
          labelStyle={this.props.labelStyle}
          labelContainerStyle={this.props.labelContainerStyle}
        />
        <LibraryBody
          list={les}
          createText={t("common:create_with_label", {
            label: t("common:le_label"),
          })}
          onCardClicked={this.onCardClicked}
          onDeleteClick={id => deleteLe({ leId: id })}
          onPrintClick={id => getPrintFile({ id: id, type: "le" })}
          createItem={() => createLe({})}
          onDuplicateClick={this.onDuplicateClick}
          label={t("common:le_label")}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(LeLibraryEdit);
