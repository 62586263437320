import PropTypes from "prop-types";
import React from "react";

const PostIcon = props => {
  return (
    <svg
      width={props.width}
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2.69231C0.5 1.21031 1.7019 0 3.17361 0H10.6597C12.1314 0 13.3333 1.21031 13.3333 2.69231V11.3077C13.3333 12.7897 12.1314 14 10.6597 14H3.17361C1.7019 14 0.5 12.7897 0.5 11.3077V2.69231ZM3.17361 1.07692C2.29254 1.07692 1.56944 1.80508 1.56944 2.69231V11.3077C1.56944 12.1949 2.29254 12.9231 3.17361 12.9231H10.6597C11.5408 12.9231 12.2639 12.1949 12.2639 11.3077V2.69231C12.2639 1.80508 11.5408 1.07692 10.6597 1.07692H3.17361Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63867 2.96205C2.63867 2.66466 2.87808 2.42358 3.17339 2.42358H10.6595C10.9548 2.42358 11.1942 2.66466 11.1942 2.96205V8.88512C11.1942 9.18251 10.9548 9.42358 10.6595 9.42358H3.17339C2.87808 9.42358 2.63867 9.18251 2.63867 8.88512V2.96205ZM3.70812 3.50051V8.34666H10.1248V3.50051H3.70812Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63867 11.0385C2.63867 10.7411 2.87808 10.5 3.17339 10.5H7.98589C8.28121 10.5 8.52062 10.7411 8.52062 11.0385C8.52062 11.3358 8.28121 11.5769 7.98589 11.5769H3.17339C2.87808 11.5769 2.63867 11.3358 2.63867 11.0385Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.05469 11.0385C9.05469 10.7411 9.29409 10.5 9.58941 10.5H10.6589C10.9542 10.5 11.1936 10.7411 11.1936 11.0385C11.1936 11.3358 10.9542 11.5769 10.6589 11.5769H9.58941C9.29409 11.5769 9.05469 11.3358 9.05469 11.0385Z"
        fill={props.fill}
      />
    </svg>
  );
};

PostIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

PostIcon.defaultProps = {
  height: 14,
  width: 14,
};

export default PostIcon;
