import { injectReducer } from "store/reducers";
import SchoolInformation from "./routes/SchoolInformation";
import AttendanceSetup from "./routes/AttendanceSetup";
import PeriodsSetup from "./routes/PeriodsSetup";
import AcademicYear from "./routes/AcademicYear";
import AcademicYearTerms from "./routes/AcademicYearTerms";
import Students from "../SchoolSetup/routes/Students";
import TeacherSetup from "../SchoolSetup/routes/TeacherSetup";
import VisitorSetup from "../SchoolSetup/routes/VisitorSetup";
import CourseSetup from "../SchoolSetup/routes/CourseSetup";
import ManageFamily from "Course/routes/ManageFamily";
import Sync from "../SchoolSetup/routes/Sync";
import GradesSetup from "./routes/GradesSetup";
import DownloadSheet from "../SchoolSetup/routes/DownloadSheet";
import { getNextMenuRoute } from "Administrator/modules/AdministratorModule";
import { getRelativePath } from "Utils";
import { withAsyncRouteLoading } from "UIComponents";

//TODO:import all routes from routes folder
import { OperationalDaysAndTimeZone } from "./routes";

export default store => ({
  path: "schoolSetup",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components/SchoolSetup").default;
            const reducer = require("./modules/SchoolSetupModule").default;
            injectReducer(store, { key: "schoolSetup", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-schoolSetup"
        );
      })
  ),
  onEnter(nextState, replace) {
    if (_.last(nextState.routes).path == "schoolSetup") {
      const route = getNextMenuRoute("SCHOOL_SETUP");
      if (route) {
        replace(getRelativePath(route));
      } else {
        replace(getRelativePath("../"));
      }
    }
  },
  childRoutes: [
    SchoolInformation(store),
    AcademicYear(store),
    AcademicYearTerms(store),
    GradesSetup(store),
    AttendanceSetup(store),
    PeriodsSetup(store),
    Students(store),
    TeacherSetup(store),
    CourseSetup(store),
    Sync(store),
    VisitorSetup(store),
    ManageFamily(store),
    DownloadSheet(store),
    GradesSetup(store),
    OperationalDaysAndTimeZone(store),
  ],
});
