export const ATTENDANCE_CONSTANTS = {
  MARK_ATTENDANCE: "mark_attendance",
  NOT_MARKED: "NOT_MARKED",
  MARKED: "MARKED",
  SEND_REMINDER: "send_reminder",
  ASSIGN_TEACHER: "assign_teacher",
  ATTENDANCE_DASHBOARD: "ATTENDANCE_DASHBOARD",
  STUDENTS: "STUDENTS",
  CLASSES: "CLASSES",
  DAILY: "DAILY",
  STUDENTS_PERIOD_ATTENDANCE: "STUDENTS_PERIOD_ATTENDANCE",
  STUDENTS_TAB_OVERVIEW: "STUDENTS_TAB_OVERVIEW",
  STUDENT: "STUDENT",
  ASC: "ASC",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  OVERALL_PRESENCE: "OVERALL_PRESENCE",
  ANOMALY: "ANOMALY",
  YEARLY: "YEARLY",
  CUSTOM_RANGE: "CUSTOM_RANGE",
  CLASS: "CLASS",
  TITLE: "TITLE",
  DESC: "DESC",
  FIRST_NAME_AZ: "FIRST_NAME_AZ",
  FIRST_NAME_ZA: "FIRST_NAME_ZA",
  LAST_NAME_AZ: "LAST_NAME_AZ",
  LAST_NAME_ZA: "LAST_NAME_ZA",
  LABEL_AZ: "LABEL_AZ",
  LABEL_ZA: "LABEL_ZA",
  CLASS_EACH_PERIOD: "CLASS_EACH_PERIOD",
  SORT_ENTITY: "sortEntity",
  PRESENCE: "PRESENCE",
  AVATAR_WITH_TEXT: "AvatarWithText",
  AVATAR_WITH_SUBTEXT: "AvatarWithSubText",
  MARK_STUDENT_ATTENDANCE: "MarkStudentAttendance",
  TEXT: "Text",
  BUTTON: "Button",
  ACTION_BUTTON: "ActionButton",
  CHECKBOX: "Checkbox",
  STUDENT_EDIT_OUTLINED: "StudentEditOutlined",
  SETUP_OUTLINED: "SetupOutlined",
  EXCUSAL: "EXCUSAL",
  EXCUSALS: "EXCUSALS",
  NEW_EXCUSALS: "NEW_EXCUSALS",
  MARKED_EXCUSALS: "MARKED_EXCUSALS",
  SINGLE_DAY: "SINGLE_DAY",
  PARTIAL_DAY: "PARTIAL_DAY",
  MULTIPLE_DAYS: "MULTIPLE_DAYS",
  DURATION: "DURATION",
  ATTENDANCE: "ATTENDANCE",
  ONGOING: "ONGOING",
  UPCOMING: "UPCOMING",
  EARLIER: "EARLIER",
  PENDING: "PENDING",
  EXCUSAL_START_DATE: "EXCUSAL_START_DATE",
  DD_MMM: "DD MMM",
  YYYY_MM_DD: "YYYY-MM-DD",
  START: "START",
  END: "END",
  DAY: "day",
  START_DATE: "startDate",
  END_DATE: "endDate",
  D_MMM_YYYY: "D MMM YYYY",
  D_MMM: "D MMM",
  MMMM_YYYY: "MMMM YYYY",
  DDD_MMM_D: "ddd, MMM D",
  DIVIDER: "DIVIDER",
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
  CACHE_AND_NETWORK: "cache-and-network",
  BREAK: "BREAK",
  WEEK: "week",
  MONTH: "month",
  PERFECT_ATTENDANCE: "PERFECT_ATTENDANCE",
  MORE_THAN_90: "MORE_THAN_90",
  LESS_THAN_90: "LESS_THAN_90",
  ATTENDANCE_MARKED_STATUS: "ATTENDANCE_MARKED_STATUS",
  PRESENT_PERCENTAGE: "PRESENT_PERCENTAGE",
  TOGGLE: "toggle",
  ROW_PREFIX: "ROW_PREFIX",
  ROW_PREFIX_KEY: "row_prefix",
  ADMIN: "admin",
};
