/**--external--*/
import React, { cloneElement, useState, useEffect } from "react";
import classNames from "classnames";
/**--internal--*/
import { getFileExtensionFromMimeType } from "Utils";
import {
  colors,
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
  LE_ASSESSMENT_TYPE_MAPPING,
} from "Constants";
import {
  MediaPlaceholder,
  I18nHOC,
  ButtonDropdown,
  LinkWithTooltip,
} from "UIComponents";
import {
  ExpandArrowsFourOutlined,
  DownloadOutlined,
} from "@toddle-design/web-icons";
import { Tick, HollowStarIcon } from "SvgComponents";
import {
  getEvidenceTitle,
  getEvidenceTypeLabel,
  getEvidenceIcon,
  getUnitPlanThemeAndGrade,
} from "SnP_v2/modules/SnPUtils";
import { getStudentResponseData } from "ClassRoom/modules/utils";
/**--relative--*/
import classes from "./EvidenceCardV2.scss";

const ViewOverlay = ({
  openMedia,
  fileType,
  assessmentType,
  itemId,
  studentResponseAttachments,
}) => {
  const Icon = _.get(LE_ASSESSMENT_TYPE_MAPPING[assessmentType], "icon", "");
  return assessmentType ? (
    <div
      className={classes.assessmentOverlay}
      style={{
        backgroundImage: _.isEmpty(studentResponseAttachments)
          ? `url(${
              PLACEHOLDER_RECT_IMAGE[itemId % PLACEHOLDER_RECT_IMAGE_COUNT]
            })`
          : "",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      onClick={openMedia}
    >
      {Icon && (
        <React.Fragment>
          <div className={classes.assessmentOverlayCircle}>
            <Icon className={classes.assessmentIcon} size={"xx-small"} />
          </div>
          <div className={classes.overlay}>
            <button className={classes["expand-btn"]}>
              <ExpandArrowsFourOutlined size={"x-small"} variant={"on"} />
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  ) : (
    <div className={classes["overlay"]} onClick={openMedia}>
      <button className={classes["expand-btn"]}>
        {fileType === "zip" || fileType === "csv" ? (
          <DownloadOutlined size={"x-small"} variant={"on"} />
        ) : (
          <ExpandArrowsFourOutlined size={"x-small"} variant={"on"} />
        )}
      </button>
    </div>
  );
};

const EvidenceCardV2 = props => {
  const {
    id,
    type,
    data,
    showFooterActions,
    footerActions,
    onClickCard,
    mode,
    styles,
    cardType,
    isSelected,
    isDisabled,
    showFooter,
    count,
    customThumbnailElement,
    showStar,
    applyAdditionAnimation,
    showDescriptionToolTip,
    t,
    showTitleTooltip,
    hideDefaultActions,
  } = props;

  const {
    itemId,
    studentResponseAttachments,
    assessmentType,
  } = getStudentResponseData(_.get(data, "items", []));

  const handleMediaClick = () => {
    onClickCard({ evidenceType: type, data });
  };

  const onClickFooterOption = actionType => {
    onClickCard({
      actionType,
      evidenceType: type,
      data,
      id,
    });
  };

  const onEvidenceTitleClick = e => {
    e.stopPropagation();
    handleMediaClick();
  };

  const handleSelectClick = () => {
    if (!isDisabled) handleMediaClick();
  };

  const modeObject = mode ? { mode } : {};

  const getAttachments = () => {
    switch (type) {
      case "POST":
        return data.attachments || [];
      case "ATTACHMENT":
        return [data];
      case "UNIT_PLAN":
      case "ASSESSMENT": {
        const imageUrl =
          _.get(data, "image.value", "") ||
          PLACEHOLDER_RECT_IMAGE[id % PLACEHOLDER_RECT_IMAGE_COUNT];

        return [
          {
            id: _.get(data, "image.id", ""),
            type: "IMAGE",
            thumbUrl: imageUrl,
            url: imageUrl,
          },
        ];
      }
      case "SCHOOL_POLICIES":
      case "TEACHER_RESOURCE":
        return [_.get(data, "attachment", {})];
      case "ASSIGNMENT": {
        const imageUrl =
          _.get(data, "content.image.value", "") ||
          PLACEHOLDER_RECT_IMAGE[id % PLACEHOLDER_RECT_IMAGE_COUNT];

        return [
          {
            id: _.get(data, "content.image.id", ""),
            type: "IMAGE",
            thumbUrl: imageUrl,
            url: imageUrl,
          },
        ];
      }
      default:
        return;
    }
  };

  const getCardOverlay = () => {
    switch (cardType) {
      case "VIEW": {
        return (
          <ViewOverlay
            fileType={getFileExtensionFromMimeType({
              mimeType: _.get(data, "mimeType", ""),
            })}
            openMedia={handleSelectClick}
            assessmentType={assessmentType}
            itemId={itemId}
            studentResponseAttachments={studentResponseAttachments}
          />
        );
      }
      case "CLUBBED_VIEW": {
        return (
          <div className={classes.clubbedOverlay} onClick={handleSelectClick}>
            <div className={classes.label}>
              {t("common:plus_more_with_count", {
                count,
              })}
            </div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const containerClassList = classNames(
    {
      [classes.container]: true,
    },
    { [classes.containerDisable]: isDisabled },
    {
      [classes.animate]: applyAdditionAnimation,
    }
  );
  const getTitle = () => {
    const { isHeadingClickable } = props;
    const title = getEvidenceTitle({
      type,
      data,
      t,
      studentResponseAttachments,
    });
    const headingClasses = classNames(classes.heading, {
      [classes.clickableHeading]: isHeadingClickable,
    });
    const titleElement = (
      <div className={headingClasses} onClick={onEvidenceTitleClick}>
        {title}
      </div>
    );

    return showTitleTooltip ? (
      <LinkWithTooltip href={null} tooltip={title} mouseEnterDelay={0.5}>
        {titleElement}
      </LinkWithTooltip>
    ) : (
      titleElement
    );
  };

  const getDescription = () => {
    let description = getEvidenceTypeLabel({ type, data, t });

    if (type === "UNIT_PLAN") {
      /** UnitPlan evidences need to be shown their theme and grade as well in card view */
      description = description + " - " + getUnitPlanThemeAndGrade({ data });
    }

    const descriptionElement = (
      <div className={classes.description}>{description}</div>
    );

    return showDescriptionToolTip ? (
      <LinkWithTooltip href={null} tooltip={description} placement={"bottom"}>
        {descriptionElement}
      </LinkWithTooltip>
    ) : (
      descriptionElement
    );
  };

  return (
    <div className={containerClassList} style={styles.containerStyle}>
      <div className={classes.thumbnail} style={styles.thumbnailStyle}>
        {showStar && (
          <div className={classes.star}>
            <HollowStarIcon fill={colors.yellow50} />
          </div>
        )}
        {isSelected && !hideDefaultActions && (
          <div className={classes.tickIcon}>
            <Tick fill={colors.blue29} />
          </div>
        )}
        {customThumbnailElement ? (
          cloneElement(customThumbnailElement, { data, type, t })
        ) : (
          <MediaPlaceholder
            attachments={getAttachments()}
            imageMaxHeight={_.get(styles, "image.height") || 196}
            imageMaxWidth={_.get(styles, "image.width") || 320}
            resolutionMutliplier={2}
            containerStyle={styles.mediaContainerStyle}
            {...modeObject}
            fitIn={false}
            postEntityItems={_.get(data, "items", [])}
          />
        )}
        {getCardOverlay()}
      </div>
      {showFooter && (
        <div
          className={classes.footer}
          style={styles.evidenceFooterContainerStyle}
        >
          <div className={classes.icon}>{getEvidenceIcon({ type, data })}</div>
          <div className={classes.content}>
            <div className={classes.labels}>
              {getTitle()}
              {getDescription()}
            </div>
            {showFooterActions && (
              <div className={classes.actions}>
                <ButtonDropdown
                  authTabs={footerActions}
                  onItemClick={onClickFooterOption}
                  containerStyle={styles.dropDownContainerStyle}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default I18nHOC({ resource: ["common", "planningInsights"] })(
  EvidenceCardV2
);

EvidenceCardV2.defaultProps = {
  showFooterActions: true,
  styles: {
    image: {},
    mediaContainerStyle: {},
    dropDownContainerStyle: {},
    containerStyle: {},
    thumbnailStyle: {},
    evidenceFooterContainerStyle: {},
  },
  mode: "select",
  cardType: "VIEW",
  customThumbnailElement: null,
  showDescriptionToolTip: false,
  showTitleTooltip: false,
  showFooter: true,
  isHeadingClickable: true,
};
