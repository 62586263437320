import React, { useState } from "react";
import classes from "./ImageCarousel.scss";
import classNames from "classnames";
import { I18nHOC } from "UIComponents";

const ImageCarousel = React.memo(props => {
  let { images, imageClass, t } = props;
  if (!_.isArray(images)) {
    images = [{ image: images }];
  }

  const [currentStepIndex, changeStepIndex] = useState(0);
  const currentImageObj = images[currentStepIndex];
  const { image } = currentImageObj;

  return (
    <div className={classes.container}>
      <img src={image} className={imageClass}></img>
      {images.length > 1 && (
        <div className={classes.carouselController}>
          {_.map(images, (imageObj, index) => {
            const carouselItemClass = classNames(
              { [classes.carouselItem]: true },
              { [classes.currentItem]: index == currentStepIndex }
            );
            const { label } = imageObj;
            return (
              <div
                className={carouselItemClass}
                key={index}
                onClick={() => changeStepIndex(index)}
              >
                {t(label)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default I18nHOC({ resource: "blockScreen" })(ImageCarousel);
