import gql from "graphql-tag";
import {
  organizationChecklistFragment,
  checklistFragment,
} from "./ChecklistFragments";

export const getOrganizationChecklistQuery = gql`
  query getOrganizationChecklistList(
    $id: ID!
    $filters: AssessmentToolFilterInput
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationChecklistFragment.checklists}
`;
