import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import CycleDetails from "SnP_v2/routes/CycleDetails";
import NotificationSettings from "Teacher/routes/NotificationSettings";

export default store => ({
  path: "snp",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("SnP_v2/modules/SnPModule").default;
            injectReducer(store, { key: "snpV2", reducer });

            const visitorHomeReducer = require("Platform/routes/VisitorHome/modules/VisitorHomeModule")
              .default;

            injectReducer(store, {
              key: "visitorHome",
              reducer: visitorHomeReducer,
            });

            cb(component);
          },
          err => {
            reject(err);
          },
          "standards-and-practices"
        );
      })
  ),
  childRoutes: [CycleDetails(store), NotificationSettings(store)],
});
