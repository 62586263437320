import PropTypes from "prop-types";
import React from "react";

const ColorNoneIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g id="Digital-Tools">
        <g id="Group-46">
          <polygon
            id="Line"
            fill="#F75961"
            points="19.8097124 3.5 20.5 4.19028758 4.19028758 20.5 3.5 19.8097124"
          ></polygon>
          <path
            d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 Z"
            id="Oval"
            fill="#000000"
          ></path>
        </g>
      </g>
    </svg>
  );
};

ColorNoneIcon.defaultProps = {
  height: 24,
  width: 24,
};

ColorNoneIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default ColorNoneIcon;
