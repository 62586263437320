import {
  createMultiLevelNodesDraftMutation,
  deleteMultiLevelNodesDraftMutation,
  updateMultiLevelNodesDraftMutation,
  publishMultiLevelNodeDraftMutation,
  createMultiLevelTagsMutation,
} from "./NodeEditorMutations";
import client from "apolloClient";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  getMultiLevelNodesQuery,
  getOrganizationMultiLevelNodeTagsQuery,
} from "modules/CommonQuery";

export const createMultiLevelNodesDraft = ({
  input,
  inputForMultiLevelNodeQuery,
}) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createMultiLevelNodesDraftMutation,
        variables: input,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getMultiLevelNodesQuery,
            variables: inputForMultiLevelNodeQuery,
          },
        ],
      });

      return _.get(
        result,
        "data.organizationManagement.createMultiLevelNodesDraft.draft.id",
        false
      );
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const deleteMultiLevelNodesDraft = params => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: deleteMultiLevelNodesDraftMutation,
        variables: params,
      });

      const isSuccess = _.get(
        result,
        "data.organizationManagement.deleteMultiLevelNodesDraft.isSuccess",
        false
      );
      if (!isSuccess) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:something_went_wrong",
            type: "alert",
            position: "toast-bottom-left",
          })
        );
        return false;
      } else {
        return true;
      }
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const updateMultiLevelNodesDraft = params => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: updateMultiLevelNodesDraftMutation,
        variables: params,
      });

      const isSuccess = _.get(
        result,
        "data.organizationManagement.updateMultiLevelNodesDraft",
        false
      );
      if (!isSuccess) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const publishMultiLevelNodeDraft = ({
  draftId,
  plannerElementSetId,
  inputForMultiLevelNodeQuery,
}) => {
  return async dispatch => {
    try {
      const result = await client.mutate({
        mutation: publishMultiLevelNodeDraftMutation,
        variables: { draftId, plannerElementSetId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getMultiLevelNodesQuery,
            variables: inputForMultiLevelNodeQuery,
          },
        ],
      });

      const isSuccess = _.get(
        result,
        "data.planner.publishMultiLevelNodeDraft",
        false
      );

      return isSuccess;
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const createMultiLevelTags = params => {
  return async (dispatch, getState) => {
    try {
      const { input, inputForMultiLevelTagsQuery } = params;
      const result = await client.mutate({
        mutation: createMultiLevelTagsMutation,
        variables: { input },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getOrganizationMultiLevelNodeTagsQuery,
            variables: inputForMultiLevelTagsQuery,
          },
        ],
      });
      return _.get(result, "data.platform.insertGenericTagsV2[0]", false);
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};
