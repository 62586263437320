// import { folderFragment } from "./JournalFragments";
import gql from "graphql-tag";

import { folderFragments } from "./JournalFragments";
export const getCourseFolders = gql`
  query getCourseFoldersQuery($courseId: ID!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        id
        folders {
          ...folderItem
        }
      }
    }
  }
  ${folderFragments.folderItem}
`;

export const getCourseFoldersDetailsQuery = gql`
  query getCourseFoldersQuery($courseId: ID!) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        id
        folders {
          ...folderItem
        }
      }
    }
  }
  ${folderFragments.folderDetails}
`;
