import React from "react";
import classes from "./CTAComponent.scss";
import { UIButton, I18nHOC } from "UIComponents";

const CTAComponent = React.memo(props => {
  const { createPlanUpgradeRequest, t } = props;
  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        {t("blockScreen:upgrade_subscription")}
      </div>
      <div className={classes.button} onClick={createPlanUpgradeRequest}>
        <UIButton color={"pink"} size={"lg"}>
          {t("blockScreen:talk_to_our_team")}
        </UIButton>
      </div>
    </div>
  );
});

export default I18nHOC({ resource: "blockScreen" })(CTAComponent);
