import { injectReducer } from "store/reducers";
import UnitPlanDetails from "UnitPlanDetails";
import { withAsyncRouteLoading } from "UIComponents";
export default store => ({
  path: "unitlibrary",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/UnitLibraryModule").default;
            injectReducer(store, { key: "unitLibrary", reducer });

            resolve(component);
          },
          err => {
            reject(err);
          },
          "unitPlans"
        );
      })
  ),
  childRoutes: [UnitPlanDetails(store)],
});
