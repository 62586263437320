import gql from "graphql-tag";
import { fieldScoreFragment, userFragment } from "modules/CommonFragments";

export const getLELibraryFeedQuery = gql`
  query getLELibraryFeed(
    $id: ID!
    $filters: AssessmentOrganizationFilters
    $after: String
  ) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        assessments(first: 16, filters: $filters, after: $after) {
          totalCount
          edges {
            node {
              id
              title {
                id
                value
              }
              image {
                id
                value
              }
              assessmentType {
                id
                value
              }
              type
              assessmentToolType
              createdBy {
                ...userItem
              }
              createdAt
              grades {
                id
                name
              }
              fields(uids: ["measureAssessing", "score"]) {
                id
                uid
                resolvedMinimalTree {
                  id
                  ... on ResolvedFieldUndefinedEntity {
                    undefinedEntity
                  }
                  ... on ResolvedFieldScore {
                    ...fieldScoreItem
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
  ${fieldScoreFragment.fieldScore}
`;
