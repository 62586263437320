import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./AssessmentPreview.scss";
import { connect } from "react-redux";

import { getAssessmentQuery } from "Assessments/modules/AssessmentQuery";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC } from "UIComponents";

import { getPlannerTemplateFromCache } from "modules/CommonGraphqlHelpers";
import { getPlannerTemplateQuery } from "modules/CommonQuery";

import { ASSESSMENT_TOOLS, getElementValue } from "modules/Services";
import { GetLESteps, getRethumbUrl } from "Utils";
import FieldComponent from "UnitPlans/components/IBcomponents/FieldComponent";
import LabelComponent from "UnitPlans/components/LabelComponent";
import {
  fontStyle,
  colors,
  PLACEHOLDER_SQUARE_IMAGE,
  PLACEHOLDER_SQUARE_IMAGE_COUNT,
} from "Constants";
import { getMYPObjectiveRubricOptions } from "modules/Services";
import { getLocalisedTemplate } from "UnitPlans/routes/UnitPlanDetails/routes/EditUnitPlan/routes/IBPlanner/modules/IBPlannerModule";
import { ChevronLeftOutlined, CloseOutlined } from "@toddle-design/web-icons";
import { filterFieldsBasedOnConfig } from "ClassRoom/modules/utils";
import { TableOutlined } from "@toddle-design/web-icons";
import { renderToString } from "react-dom/server";
import _ from "lodash";
import { EmptyState, IconButton } from "@toddle-design/web";
import { NoClassesIllustration } from "@toddle-design/theme";

/* THIS COMPONENT IS SPECIFICALLY USED FOR JOURNAL MODULE. WHENEVER USER CLICKS ON ASSESSMENT TITLE ON POST (IF PRESENT),
 THEN THIS COMPONENT WILL BE RENDERED TO DISPLAY ASSESSMENT PREVIEW HAVING LEARNING INTENTIONS, STUDENT INSTRUCTIONS AND 
 AUDIO INSTRUCTIONS. */

const styleObj = {
  assessmentToolsConStyle: {
    maxWidth: "575px",
    overflowX: "hidden",
  },
};

const isCheckValue = ({ value, fieldKey }) => {
  if (fieldKey == "voiceInstruction") {
    return !_.isEmpty(value);
  }
  return true;
};

const getOptions = ({
  fieldKey,
  defaultOptions,
  field_list,
  assessmentFields,
  unitPlanFields,
}) => {
  switch (fieldKey) {
    case "mypObjectiveRubric":
      return getMYPObjectiveRubricOptions({
        field_list,
        assessmentFields,
        unitPlanFields,
      });

    default:
      return defaultOptions || [];
  }
};

const UpperCon = React.memo(
  ({ t, LETitle, firstName, lastName, imageURL, id, showAttachmentItemV3 }) => {
    const rethumpUrl = `url(${getRethumbUrl({
      width: 512,
      height: 384,
      imageUrl:
        imageURL ||
        PLACEHOLDER_SQUARE_IMAGE[id % PLACEHOLDER_SQUARE_IMAGE_COUNT],
      fitIn: true,
    })})`;

    return (
      <div
        className={classes.upperCon}
        style={{ alignItems: showAttachmentItemV3 && "flex-start" }}
      >
        <div className={classes.BFlexDivCol}>
          {!showAttachmentItemV3 && (
            <div className={classes.previewLabel}>{t("common:preview")}</div>
          )}
          <div className={classes.leTitle}>{LETitle}</div>
          <div className={classes.createdByText}>
            {t("common:by_with_label", {
              label: `${firstName} ${lastName}`,
            })}
          </div>
        </div>
        <div
          className={classes.imageContainer}
          style={{
            backgroundImage: rethumpUrl,
          }}
        ></div>
      </div>
    );
  }
);

const Fields = React.memo(
  ({
    t,
    fields,
    field_list,
    assessmentFields,
    unitPlanFields,
    unitPlanAllFields,
    assessmentId,
    assessmentToolId,
    portalType,
    attachmentItemHeight,
    showAttachmentItemV3,
  }) => {
    return (
      <React.Fragment>
        {_.map(fields, fieldKey => {
          const field = field_list[fieldKey] ? field_list[fieldKey] : {};
          if (fieldKey == "title") {
            return null;
          }
          const {
            viewType,
            config: {
              viewLabel,
              label,
              styles,
              options,
              fieldTypeConfig = {},
            } = {},
          } = field;
          const fieldData = _.find(assessmentFields, { uid: fieldKey });
          const toolType = _.get(
            _.find(assessmentFields, { uid: "measureAssessing" }),
            "value",
            null
          );

          const unitPlanFieldValue = _.get(
            _.find(unitPlanFields, { uid: fieldKey }),
            "value",
            ""
          );

          const resolvedValue =
            _.get(fieldData, "resolvedMinimalTree", "") || {};
          let subjectIds = [];
          if (fieldKey == "benchmarks") {
            subjectIds = _.get(
              _.find(unitPlanFields, { uid: "subjects" }),
              "value",
              ""
            );
          }

          const assessmentFieldValue = _.get(fieldData, "value", "");

          let value = getElementValue({
            fieldUID: fieldKey,
            valueKeys: assessmentFieldValue,
            unitPlanFieldValue,
            fieldObj: field,
            subjectIds: subjectIds,
            resolvedValue: resolvedValue,
            t,
          });

          const style = {
            ..._.get(styles, "style", {}),
            ..._.get(styles, "viewStyle", {}),
            marginTop: fieldKey == "voiceInstruction" ? "16px" : "0",
          };

          const contentStyle = {
            ...fontStyle.medium,
            color: colors.textSubtle,
            fontSize: "1.4rem",
            lineHeight: 1.71,
          };

          const textViewcustomTextStyle = {
            ...fontStyle.medium,
            color: colors.textSubtle,
            fontSize: "1.4rem",
            lineHeight: 1.71,
          };

          const labelStyle = {
            ...fontStyle.demiBold,
            fontSize: "1.4rem",
            lineHeight: 1.71,
            color: colors.textDefault,
          };

          const checkValue = isCheckValue({
            value,
            fieldKey,
          });
          if (!checkValue) {
            return null;
          }

          if (fieldKey === "measureAssessing") {
            value = `
                  <div class=${classes.assessmentToolTypeContainer}>
                    ${renderToString(<TableOutlined />)} 
                    <span class=${
                      classes.assessmentToolTypeValue
                    }>${value}</span>
                  </div>
                `;
          }

          return (
            <div key={fieldKey} className={classes.item} style={style}>
              <FieldComponent
                {...fieldTypeConfig}
                defaultOptions={getOptions({
                  fieldKey,
                  defaultOptions: options,
                  field_list,
                  assessmentFields,
                  unitPlanFields: unitPlanAllFields,
                })}
                contentStyle={contentStyle}
                textViewcustomTextStyle={textViewcustomTextStyle}
                type={viewType}
                mode={"view"}
                parentType={"ASSESSMENT"}
                parentId={assessmentId}
                assessmentToolId={assessmentToolId}
                labelComponent={
                  fieldKey == "voiceInstruction" ? (
                    <LabelComponent
                      label={"Audio Instructions"}
                      labelStyle={labelStyle}
                    />
                  ) : null
                }
                value={value}
                fieldId={_.get(fieldData, "id", "")}
                fieldKey={fieldKey}
                portalType={portalType}
                isAssessmentToolPreview={false}
                isApplyTextViewcustomTextStyle={true}
                cardType={"design-media-card"}
              />
            </div>
          );
        })}
      </React.Fragment>
    );
  }
);

const Steps = React.memo(
  ({
    view_steps,
    step_list,
    field_list,
    assessmentFields,
    unitPlanFields,
    unitPlanAllFields,
    assessmentId,
    assessmentToolId,
    portalType,
    attachmentItemHeight,
    t,
    showAttachmentItemV3,
  }) => {
    return _.map(view_steps, key => {
      const { label, fields, subtext } = step_list[key] || {};

      return (
        <div key={key} className={classes.stepContainer}>
          <div className={classes.stepLabel}>
            {label}
            {subtext && <div className={classes.stepSubText}>{subtext}</div>}
          </div>
          <div className={classes.formContainer}>
            <Fields
              t={t}
              fields={fields}
              field_list={field_list}
              assessmentFields={assessmentFields}
              unitPlanFields={unitPlanFields}
              unitPlanAllFields={unitPlanAllFields}
              assessmentId={assessmentId}
              assessmentToolId={assessmentToolId}
              portalType={portalType}
              attachmentItemHeight={attachmentItemHeight}
              showAttachmentItemV3={showAttachmentItemV3}
            />
          </div>
        </div>
      );
    });
  }
);

// eslint-disable-next-line react/display-name
const AssessmentPreview = React.memo(
  ({
    step_list,
    view_steps,
    field_list,
    assessmentDetails,
    assessmentFields,
    unitPlanFields,
    unitPlanAllFields,
    assessmentId,
    portalType,
    attachmentItemHeight,
    assessmentToolId,
    t,
    showAttachmentItemV3,
    showBackButton,
    onClickBackButton,
    innerContainerStyle,
    wrapperStyle,
  }) => {
    const {
      createdBy: { firstName, lastName },
      title: { value: LETitle },
      image: { value: imageURL },
      id,
      presetAssessment,
    } = assessmentDetails;

    let unitPlanDetails = _.get(presetAssessment, "unitPlan", {});
    if (_.isEmpty(unitPlanDetails))
      unitPlanDetails = _.get(assessmentDetails, "unitPlan", {});

    const getPreviewLabel = assessmentType => {
      switch (assessmentType) {
        case "le":
          return t("journal:learning_experience_preview");
        case "qt":
          return t("journal:quick_task_preview");
        case "fmt":
          return t("journal:formative_assessment_preview");
        case "smt":
          return t("journal:summative_assessment_preview");
        case "se":
          return t("journal:supplementary_evidance_preview");
        case "pt":
          return t("journal:performance_task_preview");
        case "pri":
          return t("journal:preassessment_preview");
        default:
          return t("journal:assessment_preview");
      }
    };

    const unitImageUrl = `url(${getRethumbUrl({
      width: 80,
      height: 40,
      imageUrl:
        _.get(unitPlanDetails, "image.value", "") ||
        PLACEHOLDER_SQUARE_IMAGE[
          _.get(unitPlanDetails, "id", id) % PLACEHOLDER_SQUARE_IMAGE_COUNT
        ],
      fitIn: true,
    })})`;

    return (
      <div className={classes.container}>
        <div className={classes.innerContainer} style={innerContainerStyle}>
          {showBackButton && (
            <div className={classes.backButtonContainer}>
              <div className={classes.lePreviewHeader}>
                <div className={classes.innerHeader}>
                  <div className={classes.assessmentTypeText}>
                    {getPreviewLabel(
                      _.get(assessmentDetails, "assessmentType.value", "")
                    )}
                  </div>
                  <IconButton
                    className={classes.backIcon}
                    onClick={onClickBackButton}
                    variant="plain"
                    icon={<CloseOutlined size={"xx-small"} />}
                    size="x-small"
                  />
                </div>
              </div>
            </div>
          )}
          <div className={classes.wrapper} style={wrapperStyle}>
            <UpperCon
              t={t}
              LETitle={LETitle}
              firstName={firstName}
              lastName={lastName}
              imageURL={imageURL}
              id={id}
              showAttachmentItemV3={showAttachmentItemV3}
            />
            {_.size(view_steps) > 0 && (
              <div className={classes.lowerCon}>
                <Steps
                  t={t}
                  view_steps={view_steps}
                  step_list={step_list}
                  field_list={field_list}
                  assessmentFields={assessmentFields}
                  unitPlanFields={unitPlanFields}
                  unitPlanAllFields={unitPlanAllFields}
                  assessmentId={assessmentId}
                  assessmentToolId={assessmentToolId}
                  portalType={portalType}
                  attachmentItemHeight={attachmentItemHeight}
                  showAttachmentItemV3={showAttachmentItemV3}
                />
              </div>
            )}
            {!_.isEmpty(unitPlanDetails) && (
              <div className={classes.unitContainer}>
                <div className={classes.stepLabel}>
                  {t("common:label_details", { label: t("common:unit_label") })}
                </div>
                <div className={classes.unitDetailsContainer}>
                  <div
                    className={classes.unitImageContainer}
                    style={{ background: unitImageUrl }}
                  ></div>
                  <div className={classes.unitLabelContainer}>
                    <div className={classes.unitTitleContainer}>
                      {_.get(unitPlanDetails, "title.value", "")}
                    </div>
                    <div>{t("common:unit_label")}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const mapActionCreators = {};

const mapStateToProps = state => {
  const userType = state.login.userInfo.user_type;

  return {
    userType,
    isData: true,
    isLoading: false,
  };
};

const templateSelector = _.memoize(
  templates => templates,
  templates => JSON.stringify(templates)
);

const getDynamicTemplate = ({ template, assessmentFields, t, userType }) => {
  let dynamicTemplate = _.cloneDeep(template);
  if (!_.isEmpty(template)) {
    const step_list = dynamicTemplate["step_list"];
    const field_list = dynamicTemplate["field_list"];
    const tool = _.get(
      _.find(assessmentFields, { uid: "measureAssessing" }),
      "value",
      null
    );
    const dynamicStep = step_list["assessmentTool"];
    if (dynamicStep && !!tool) {
      dynamicStep["fields"] = [
        ...dynamicStep["fields"],
        _.get(_.find(ASSESSMENT_TOOLS, { type: tool }), "key", ""),
      ];
      dynamicStep["isMaxium"] = true;
    }

    let view_steps = _.get(dynamicTemplate, "view_steps", []);
    if (!_.isEmpty(view_steps)) {
      _.forEach(view_steps, step => {
        const stepFields = _.get(step_list, `${step}.fields`, []);
        const filteredStepFields = filterFieldsBasedOnConfig({
          fields: stepFields,
          field_list,
          userType,
        });
        if (_.isEmpty(filteredStepFields)) {
          view_steps = _.filter(view_steps, viewStep => {
            return viewStep != step;
          });
          dynamicTemplate["view_steps"] = view_steps;
        }
      });
    }
  }
  const localisedTemplate = getLocalisedTemplate({
    template: { body: dynamicTemplate },
    t,
    templateType: "le",
  });

  return dynamicTemplate;
};

AssessmentPreview.propTypes = {
  parentType: PropTypes.string,
};

AssessmentPreview.defaultProps = {
  parentType: "UNIT_PLAN",
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlanTemplate", "common", "journal"] }),
  graphql(getAssessmentQuery, {
    name: "getAssessment",
    options: ({ assessmentId, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: assessmentId, portalType },
    }),
    props({ getAssessment, ownProps: { isData, isLoading, assessmentId } }) {
      const assessmentDetails = getAssessmentDetailsFromCache(assessmentId);
      const assessmentToolId = _.get(
        assessmentDetails,
        "assessmentTool.id",
        null
      );
      const templateId = _.get(assessmentDetails, "templateId", null);
      let unitPlanTemplateId = _.get(
        assessmentDetails,
        "unitPlan.templateId",
        null
      );

      return {
        assessmentDetails,
        unitPlanTemplateId,
        assessmentToolId,
        templateId,
        isData: !_.isEmpty(assessmentDetails) && isData,
        isLoading:
          getAssessment["networkStatus"] == 1 ||
          getAssessment["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getPlannerTemplateQuery, {
    name: "getPlannerTemplate",
    skip: ({ templateId, unitPlanTemplateId }) =>
      !templateId && !unitPlanTemplateId,
    options: ({ templateId, unitPlanTemplateId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        ids: templateSelector(
          _.filter([templateId, unitPlanTemplateId], id => id)
        ),
      },
    }),
    props({
      getPlannerTemplate,
      ownProps: {
        userType,
        isLoading,
        isData,
        templateId,
        unitPlanTemplateId,
        assessmentDetails,
        parentType,
        t,
      },
    }) {
      const unitPlanFields = _.get(assessmentDetails, "unitPlan.fields", []);
      const unitPlanAllFields = _.get(
        assessmentDetails,
        "unitPlan.allFields",
        []
      );
      const assessmentFields = _.get(assessmentDetails, "allFields", []);
      const filterTemplateIds = _.filter(
        [templateId, unitPlanTemplateId],
        id => id
      );
      const templates = getPlannerTemplateFromCache(filterTemplateIds, true);
      const templateFromQuery = _.get(
        _.find(templates, { id: templateId }),
        "body",
        {}
      );

      const template = getDynamicTemplate({
        template: templateFromQuery,
        assessmentFields,
        t,
        userType,
      });

      const listSteps = GetLESteps({
        userType,
        template,
        resourceDetails: assessmentDetails,
        unitPlanFields,
        parentType,
        isPreview: true,
      });

      listSteps.viewSteps = _.filter(
        listSteps.viewSteps,
        viewStep =>
          viewStep == "goals" ||
          viewStep == "implementationV2" ||
          viewStep == "implementation"
      );
      const allFields = _.get(template, "field_list", {});
      return {
        step_list: listSteps.stepList,
        view_steps: listSteps.viewSteps,
        field_list: allFields,
        assessmentFields,
        unitPlanFields,
        unitPlanAllFields,
        isData: !_.isEmpty(templates) && isData,
        isLoading:
          getPlannerTemplate["networkStatus"] == 1 ||
          getPlannerTemplate["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(AssessmentPreview);
