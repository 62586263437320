import React from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import classes from "./BasicDetails.scss";
import { getTimeSince } from "Utils";
import classNames from "classnames";
import {
  ProfileCascade,
  MediaPlaceholder,
  TextMoreLess,
  I18nHOC,
  LinkWithTooltip,
} from "UIComponents";
import { CancelIcon, CreateAssessmentSvg } from "SvgComponents";
import { colors, LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { getSharedWithString, getSharedWithStringV2 } from "Post/utils";
import { LikeButtonIcon, CommentButtonIcon } from "Post/components";
import ACLStore from "lib/aclStore";
import StudentResponse from "../../CreatePost/components/Screens/StudentResponse/StudentResponse";
import {
  PortfolioOutlined,
  GlobeOutlined,
  LockOutlined,
  StudentOutlined,
  FamilyOutlined,
} from "@toddle-design/web-icons";

const avatarStyle = {
  size: "medium",
  shade: "light",
  randomizeColor: true,
};
const buttonContainerStyle = { marginRight: 24 };

const styles = {
  studentResponseContainerStyle: {
    padding: "16px",
    borderWidth: 1,
    borderColor: colors.gray95,
    borderRadius: "8px",
    cursor: "default",
  },
  responseStyle: {
    marginTop: "16px",
  },
};
const getStudents = taggedStudents =>
  _.map(taggedStudents, item => ({
    isArchived: _.get(item, "node.isArchived"),
    name: `${item.node.firstName} ${item.node.lastName}`,
  }));

export const removeSelfMemoize = _.memoize(
  ({ taggedStudents, studentId }) => {
    return _.filter(taggedStudents, v => v.node?.id !== studentId);
  },
  params => JSON.stringify(params)
);

const TaggedText = ({
  taggedStudents,
  elementPYP,
  createdByName,
  attachments,
  date,
  isPrivate,
  isHiddenFromStudents,
  attachMentMediaType,
  t,
  userType,
  studentId,
  isOpenedFromStudentPortfolio,
  childName,
  isStudentResponse,
}) => {
  let taggedStudentsList;

  // if feed card is opened from student portfolio then remove self
  if (userType === "staff" && !isOpenedFromStudentPortfolio) {
    taggedStudentsList = taggedStudents;
  } else {
    // self should be removed from the list
    taggedStudentsList = removeSelfMemoize({ taggedStudents, studentId });
  }

  const hasTaggedStudents = !_.isEmpty(taggedStudentsList);

  let showTagged;
  if (userType === "staff" || userType === "student") {
    showTagged = hasTaggedStudents;
  } else if (userType === "parent") {
    // dont show tagged if child itself is the creator of the post
    showTagged = createdByName !== childName;
  }

  const isPortfolio = taggedStudents.length > 0;
  const isPYPElement = !!elementPYP && elementPYP.length > 0;
  const timeTextClass = classNames(
    { [classes.timeText]: true },
    { [classes.evidenceTimeText]: !isPortfolio }
  );

  const getSharedWithIcons = type => {
    switch (type) {
      case "everyone":
        return <GlobeOutlined size={"xxx-small"} variant={"subtle"} />;
      case "student":
        return <StudentOutlined size={"xxx-small"} variant={"subtle"} />;
      case "family":
        return <FamilyOutlined size={"xxx-small"} variant={"subtle"} />;
      case "private":
        return <LockOutlined size={"xxx-small"} variant={"subtle"} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.taggedTextContainer}>
      <span className={classes.taggedCreatedByText}>
        <div className={classes.taggedCreatedByTopContainer}>
          {createdByName}
        </div>
        {/* SharedWithString should not be visible to parent */}
        <div className={classes.taggedCreatedByBottomContainer}>
          <span className={timeTextClass}>{getTimeSince(date, t)}</span>
          {userType !== "parent" && (
            <React.Fragment>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>
              <div className={classes.iconContainer}>
                <div className={classes.icon}>
                  <LinkWithTooltip
                    tooltip={getSharedWithString({
                      isPrivate,
                      isHiddenFromStudents,
                      withPrefix: true,
                      t,
                    })}
                    placement={"bottom"}
                  >
                    <div className={classes.icon}>
                      {getSharedWithIcons(
                        getSharedWithStringV2({
                          isPrivate,
                          isHiddenFromStudents,
                        })
                      )}
                    </div>
                  </LinkWithTooltip>
                </div>
              </div>
            </React.Fragment>
          )}
          {isPYPElement && (
            <div className={classes.evidenceContainer}>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>
              <div className={classes.iconContainer}>
                <div className={classes.icon}>
                  <PortfolioOutlined size={"xxx-small"} variant={"subtle"} />
                </div>
              </div>
              <div className={classes.evidenceText}>{t("common:evidence")}</div>
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

class BasicDetails extends React.PureComponent {
  onClickMedia = ({ activeIndex = 0 } = {}) => {
    const { toggleMediaModal } = this.props;
    if (toggleMediaModal) {
      toggleMediaModal(true, activeIndex);
    }
  };

  render() {
    const {
      detail: {
        title,
        createdBy: { id: createdById, firstName, lastName, profileImage },
        elementPYP,
        createdAt,
        publishedAt,
        students: { edges: taggedStudents },
        attachments,
        state,
        isPrivate,
        isHiddenFromStudents,
        likes,
        comments,
        unitPlan,
        items,
      },
      onCloseClick,
      onPostActionClick,
      userId,
      postId,
      t,
      toggleCommentBox,
      toggleLEPreview,
      userType,
      childName,
      showCloseBtn,
      mediaMaxWidth,
      mediaMaxHeight,
      attachmentContainerStyle,
      courseIdFromQuery,
      hideLike,
      studentId,
      isOpenedFromStudentPortfolio,
      hideComments,
      showDownloadBeginOverlay,
    } = this.props;

    const responseData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );
    const item = _.get(responseData, "item", {});
    const content = _.get(item, "assignment.content", {});
    const assessmentId = _.get(content, "id", "");
    const assessmentType = _.get(content, "assessmentType.value", "");
    const unitPlanFields = _.get(unitPlan, "allFields", []);

    const isPostApproveRejectCommentPermission = ACLStore.can(
      "FeatureFlag:NewApprovalFlowOfPost"
    );

    const isPostApproveRejectEnabled =
      _.isEqual(state, "REJECTED") && isPostApproveRejectCommentPermission;

    const studentResponseHasAudioAttachment =
      _.size(attachments) === 1 && !_.isEmpty(responseData);

    const commentAndLikeContainerStyle =
      hideComments && hideLike ? { marginTop: 0 } : {};

    const attachMentMediaType =
      attachments.length > 0 ? attachments[0].type : "";
    const isPublished = state == "PUBLISHED";

    const attachmentContainer = classNames(
      { [classes.attachmentContainer]: true },
      {
        [classes.rejectedPost]: isPostApproveRejectEnabled,
      }
    );

    const titleTextContainer = classNames(
      { [classes.titleTextContainer]: true },
      {
        [classes.rejectedPost]: isPostApproveRejectEnabled,
      }
    );

    const closeButtonClass = classNames({
      [classes.closeButton]: true,
      [classes.closeButtonAbsolute]: !_.isEmpty(responseData),
    });

    const studentResponseContainerClass = classNames({
      [classes.studentResponseContainer]: true,
      [classes.studentResponseContainerWithAudio]: studentResponseHasAudioAttachment,
    });

    const LEAssessmentType = LE_ASSESSMENT_TYPE_MAPPING[assessmentType];
    const Icon = _.get(LEAssessmentType, "filledIcon") || CreateAssessmentSvg;
    const iconColor = _.get(LEAssessmentType, "filledIconColor", colors.blue29);

    return (
      <React.Fragment>
        {!_.isEmpty(responseData) && (
          <div className={classes.leContainer}>
            <div className={classes.leInnerContainer}>
              <div
                className={classes.assessmentContainer}
                onClick={() => toggleLEPreview(assessmentId)}
              >
                <div className={classes.leIconContainer}>
                  <Icon
                    width={20}
                    height={20}
                    fill={iconColor}
                    fill1={colors.white}
                  />
                </div>
                <div className={classes.assessmentTitle}>
                  {_.get(content, "title.value", "")}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={classes.container}>
          <div className={classes.createdByContainer}>
            <div className={classes.createdByLeftContainer}>
              <ProfileCascade
                items={[
                  {
                    id: createdById,
                    value: `${firstName} ${lastName ?? ""}`,
                    imageUrl: profileImage,
                  },
                ]}
                showAvatar={true}
                avatarStyles={avatarStyle}
              />
              <TaggedText
                t={t}
                isPrivate={isPrivate}
                isHiddenFromStudents={isHiddenFromStudents}
                taggedStudents={taggedStudents}
                elementPYP={elementPYP}
                createdByName={`${firstName} ${lastName}`}
                attachments={attachments}
                date={isPublished ? publishedAt : createdAt}
                attachMentMediaType={attachMentMediaType}
                userType={userType}
                childName={childName}
                studentId={studentId}
                isOpenedFromStudentPortfolio={isOpenedFromStudentPortfolio}
                isStudentResponse={!_.isEmpty(responseData)}
              />
            </div>
            {showCloseBtn ? (
              <div className={classes.createdByRightContainer}>
                <div className={closeButtonClass} onClick={onCloseClick}>
                  <CancelIcon width={16} heigth={16} />
                </div>
              </div>
            ) : null}
          </div>
          {title && (
            <div className={titleTextContainer}>
              <div className={classes.titleText}>
                <TextMoreLess value={title} minHeight={240} />
              </div>
            </div>
          )}
          {!_.isEmpty(responseData) ? (
            <div className={studentResponseContainerClass}>
              {studentResponseHasAudioAttachment && (
                <MediaPlaceholder
                  attachments={attachments}
                  hasAudioCaption={true}
                  containerStyle={{ marginBottom: "8px" }}
                />
              )}
              <StudentResponse
                item={{ ...item, unitPlanFields: unitPlanFields }}
                containerStyle={styles.studentResponseContainerStyle}
                responseStyle={styles.responseStyle}
              />
            </div>
          ) : attachments.length > 0 ? (
            <div
              className={attachmentContainer}
              style={attachmentContainerStyle}
            >
              <MediaPlaceholder
                attachments={attachments}
                imageMaxWidth={mediaMaxWidth}
                onClickMedia={this.onClickMedia}
                // backgroundSize={
                //   attachMentMediaType == "IMAGE" ? "contain" : "cover"
                // }
                fitIn={true}
                imageMaxHeight={mediaMaxHeight}
                showOverlayIcons={true}
                hasAudioCaption={true}
                showDownloadBeginOverlay={showDownloadBeginOverlay}
              />
            </div>
          ) : (
            <div className={classes.noAttachment}>
              {t("common:noAttachment")}
            </div>
          )}

          <div
            className={classes.buttonContainer}
            style={commentAndLikeContainerStyle}
          >
            {hideLike ? null : (
              <LikeButtonIcon
                id={postId}
                likes={likes}
                onPostActionClick={onPostActionClick}
                userId={userId}
                showTooltip={true}
                courseIdFromQuery={courseIdFromQuery}
              />
            )}
            {hideComments ? null : (
              <CommentButtonIcon
                id={postId}
                isCommentOpen={false}
                comments={comments}
                onPostActionClick={toggleCommentBox}
                courseIdFromQuery={courseIdFromQuery}
                t={t}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

BasicDetails.defaultProps = {
  showCloseBtn: true,
  mediaMaxWidth: 376,
  mediaMaxHeight: 376,
  attachmentContainerStyle: {},
  hideLike: false,
  hideComments: false,
};

const mapStateToProps = (state, ownProps) => {
  return {
    userType: _.get(state, "login.userInfo.user_type", ""),
    childName: _.get(state, "login.userInfo.childName", ""),
    // for family portal student's id is stored in "childID"
    // for student portfolio in educator app, the student id is passed from parent component as "portfolioStudentId"
    // for student portal the id of student is stored in "id"
    studentId:
      state.login.userInfo.childID ||
      ownProps?.portfolioStudentId ||
      state.login.userInfo.id,
    isOpenedFromStudentPortfolio: !!ownProps?.portfolioStudentId,
  };
};

export default compose(
  connect(mapStateToProps, _),
  I18nHOC({ resource: ["journal", "common"] })
)(BasicDetails);
