import React, { useState } from "react";
import classNames from "classnames";
import { Checkbox } from "@toddle-design/web";
import { connect } from "react-redux";

import classes from "./PracticeList.scss";
import { LinkWithTooltip, DialogueBox } from "UIComponents";
import { useI18n } from "Hooks";
import styles, { approvedPracticesStyles } from "./PracticeListStyles";
import ConditionalWrapper from "Utils/ConditionalWrapper";

import { getSettingValue } from "modules/PermissionModule";
import { StarFilled } from "@toddle-design/web-icons";
import { Trans } from "react-i18next";

const ApprovedPracticeMessage = props => {
  const {
    itemTypeLabel,
    itemTitle,
    cycleType,
    cycleName,
    messageLocale,
  } = props;
  const { tooltipContainerStyle } = approvedPracticesStyles;

  return (
    <LinkWithTooltip
      tooltip={
        <Trans i18nKey={messageLocale}>
          {{ evidenceTypeLabel: itemTypeLabel }}

          <div className={classes.highlightText}>{{ title: itemTitle }}</div>

          {{ cycleType }}

          <div className={classes.highlightText}>{{ cycleName }}</div>
        </Trans>
      }
      tooltipContainerStyle={tooltipContainerStyle}
      placement={"bottom"}
      alignConfig={{ offset: [4, -8] }}
    >
      <div className={classes.approvedPractice}>
        <StarFilled size={"xxx-small"} className={classes.starIcon} />
      </div>
    </LinkWithTooltip>
  );
};

const DisabledCheckboxWrapper = ({
  itemTypeLabel,
  cycleType,
  cycleName,
  children,
  isApproved,
}) => {
  return (
    <LinkWithTooltip
      {...styles}
      placement={"left"}
      applyMouseLeaveOnContentWrapper={true}
      tooltip={
        <Trans
          i18nKey={
            isApproved
              ? "snp:cannot_untag_admin_selected_evidence_msg"
              : "snp:cannot_untag_other_teacher_suggested_evidence_msg"
          }
        >
          <div className={classes.highlightText}>Some Text</div>
          {{ evidenceTypeLabel: itemTypeLabel }}
          {{ cycleType }}
          <div className={classes.highlightText}>{{ cycleName }}</div>
        </Trans>
      }
    >
      {children}
    </LinkWithTooltip>
  );
};

const DialogueBoxBody = ({ itemTypeLabel, cycleName, cycleType }) => {
  return (
    <Trans i18nKey={"snp:untag_evidence_msg"}>
      {{ evidenceTypeLabel: itemTypeLabel }}

      <div className={classes.highlightDialogueBody}>Some Text</div>

      {{ cycleType }}

      <div className={classes.highlightDialogueBody}>{{ cycleName }}</div>
    </Trans>
  );
};

const PracticeList = props => {
  const {
    practices,
    updateSelectedPractices,
    practiceListsCollection,
    itemDetails,
    cycleDetails,
    organizationId,
  } = props;

  const { itemTitle, itemTypeLabel, messageLocale } = itemDetails;

  const {
    approvedPractices,
    disabledPractices,
    selectedPractices,
  } = practiceListsCollection;

  const { cycleName, cycleType } = cycleDetails;

  const { t } = useI18n(["common", "snp"]);
  const [showDialogueBox, setDialogueBoxVisibility] = useState(false);
  const [practiceDetails, setPracticeDetails] = useState({});

  const closeDialogueBox = () => {
    setDialogueBoxVisibility(false);
  };

  const { id, isSelected } = practiceDetails;

  const unSelectPractice = () => {
    updateSelectedPractices({ id, isSelected });
  };

  const requirementLabelSetting = getSettingValue({
    organizationId,
    name: "AppSettingSnpRequirementLabel",
  });

  const requirementLabel = t(`snp:${requirementLabelSetting}`, {
    defaultValue: requirementLabelSetting,
  });

  return (
    <React.Fragment>
      {_.map(practices, ({ label, code, type = "PRACTICE", id }) => {
        const isSelected = _.includes(selectedPractices, id);
        const isDisabled = _.includes(disabledPractices, id);

        const isChecked = isSelected || isDisabled;

        const isApproved = _.includes(approvedPractices, id) && isChecked;

        const practiceClasses = classNames(classes.practice, {
          [classes.disabledPractice]: isDisabled,
        });

        const handlePracticeClick = () => {
          if (isDisabled) return;

          if (isApproved) {
            setPracticeDetails({ id, isSelected: !isChecked });
            setDialogueBoxVisibility(true);
            return;
          }

          updateSelectedPractices({ id, isSelected: !isChecked });
        };

        return (
          <div
            className={practiceClasses}
            key={id}
            onClick={handlePracticeClick}
          >
            <div className={classes.practiceDetails}>
              <div className={classes.practiceCode}>
                {`${code}`}
                <div className={classes.practiceType}>{`(${
                  type === "REQUIREMENTS" ? requirementLabel : type
                })`}</div>

                {isApproved && (
                  <ApprovedPracticeMessage
                    itemTypeLabel={itemTypeLabel}
                    cycleName={cycleName}
                    cycleType={cycleType}
                    itemTitle={itemTitle}
                    messageLocale={messageLocale}
                  />
                )}
              </div>
              <div className={classes.practiceLabel}>{`${label}`}</div>
            </div>
            <ConditionalWrapper
              condition={isDisabled}
              wrapper={
                <DisabledCheckboxWrapper
                  itemTypeLabel={itemTypeLabel}
                  cycleType={cycleType}
                  cycleName={cycleName}
                  isApproved={isApproved}
                />
              }
              type={"wrapperAsElement"}
            >
              <div className={classes.checkbox}>
                <Checkbox
                  isChecked={isChecked}
                  size={"xx-small"}
                  disabled={isDisabled}
                />
              </div>
            </ConditionalWrapper>
          </div>
        );
      })}
      {showDialogueBox && (
        <DialogueBox
          modalTitle={t("snp:untag_practice")}
          modalBody={
            <DialogueBoxBody
              itemTypeLabel={itemTypeLabel}
              cycleName={cycleName}
              cycleType={cycleType}
            />
          }
          toggleDialogueBoxDisplay={closeDialogueBox}
          button1={t("common:cancel")}
          button2={t("snp:untag_practice")}
          onClickButton1={closeDialogueBox}
          onClickButton2={unSelectPractice}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const organizationId = _.get(state, "login.userInfo.org_id", "");
  return { organizationId };
};

export default connect(mapStateToProps)(PracticeList);
