import React from "react";
import classes from "./AssessmentLibrary.scss";
import PropTypes from "prop-types";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { connect } from "react-redux";
import { getUnitPlanResourceLibraryQuery } from "UnitPlans/modules/UnitPlanQuery";
import { withPusherBind } from "UIComponents";
import { getUnitPlanResourceLibraryFromCache } from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import {
  deleteAssessment,
  importAssessment,
  gotoAssessmentDetails,
  updateFilters,
  initialState,
} from "Assessments/modules/AssessmentModule";

import { isUserAuthenticatedForAction } from "Community/modules/CommunityModule";
import { getResourceTypes } from "IBPlanner/modules/IBPlannerModule";
import { graphql, compose } from "react-apollo";
import { getPrintFile } from "modules/Services";
import AssessmentLibraryEdit from "./AssessmentLibraryEdit";
import { ItemViewFeed } from "UnitPlans/components/IBcomponents";
import { ResourceCardV1 } from "AppComponents";
import { colors, fontStyle } from "Constants";

const ResourceViewTheme = {
  imageContainer: {
    height: 44,
    width: 60,
  },
  sectionLabel: {
    fontSize: "1.4rem",
    color: colors.gray31,
    ...fontStyle.bold,
  },
  sectionAuthorAndDuration: {
    fontSize: "1.4rem",
    color: colors.gray48,
  },
  sectionItem: {
    alignItems: "center",
    cursor: "pointer",
  },
  sectionItemDetails: {
    padding: "0 12px",
  },
};
class AssessmentLibrary extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const {
      updateFilters,
      groupType,
      assessmentTypes,
      filters,
      mode,
    } = this.props;

    if (
      (filters.groupType !== groupType ||
        _.size(_.difference(assessmentTypes, filters.assessmentTypes))) &&
      _.isEqual(mode, "edit")
    ) {
      updateFilters({ groupType, assessmentTypes, searchText: "" });
    }
  };

  componentDidUpdate = prevProps => {
    const { updateFilters, groupType, assessmentTypes, mode } = this.props;

    if (
      (prevProps.groupType !== groupType ||
        _.size(_.difference(assessmentTypes, prevProps.assessmentTypes))) &&
      _.isEqual(mode, "edit")
    ) {
      updateFilters({ groupType, assessmentTypes, searchText: "" });
    }
  };

  showModal = ({ id, mode }) => {
    const { onAssessmentClick, gotoAssessmentDetails } = this.props;
    if (onAssessmentClick) {
      onAssessmentClick({ id, mode });
    } else {
      gotoAssessmentDetails({
        id,
        mode,
        isOpenInNewTab: false,
      });
    }
  };

  onViewCardClicked = async ({ id, mode = "view" }) => {
    const { isUserAuthenticatedForAction } = this.props;

    const hasAccess = await isUserAuthenticatedForAction();
    if (!hasAccess) return;
    this.showModal({ id, mode });
  };

  renderViewItem = ({ item, index }) => {
    return (
      <ResourceCardV1
        item={item}
        showTypeLabel={false}
        imageThumbSize={120}
        theme={ResourceViewTheme}
        key={item.id}
        onContainerClick={() => this.onViewCardClicked({ id: item.id })}
      />
    );
  };

  renderView = () => {
    const {
      assessments,
      getResourceTypes,
      groupType,
      curriculumType,
    } = this.props;
    const resources = getResourceTypes({ curriculumType });

    const resource = _.find(resources, {
      key: curriculumType == "IB_PYP" ? "ASSESSMENT" : groupType,
    });

    return (
      <ItemViewFeed
        items={assessments}
        {...this.props}
        renderItem={this.renderViewItem}
        itemText={_.get(resource, "labelWithoutCount", "")}
      />
    );
  };

  render() {
    const {
      mode,
      getResourceTypes,
      size,
      groupType,
      curriculumType,
      isFilteredData,
    } = this.props;

    const resources = getResourceTypes({ curriculumType });

    //TODO: Need to improve curriculumType condition

    const resource = _.find(resources, {
      key: curriculumType == "IB_PYP" ? "ASSESSMENT" : groupType,
    });
    return (
      <React.Fragment>
        {mode == "edit" ? (
          <AssessmentLibraryEdit
            size={size}
            {...this.props}
            resource={resource}
            showModal={this.showModal}
          />
        ) : (
          this.renderView()
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  deleteAssessment,
  importAssessment,
  getPrintFile,
  getResourceTypes,
  gotoAssessmentDetails,
  isUserAuthenticatedForAction,
  updateFilters,
};

const mapStateToProps = (state, ownProps) => {
  const { groupType, assessmentTypes, mode } = ownProps;

  const unitPlanId = ownProps.unitPlanId
    ? ownProps.unitPlanId
    : state.planner.unitPlanData.unitPlanId;
  const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
  const unitPlanFields = _.get(unitPlanDetails, "allFields", []);

  const filters = _.isEqual(mode, "edit")
    ? _.get(state, "assessment.filters", {})
    : { groupType, assessmentTypes, searchText: "" };

  const unitPlantitle = _.get(
    _.find(unitPlanFields, { uid: "title" }),
    "value",
    ""
  );
  const curriculumType = _.get(
    unitPlanDetails,
    "curriculumProgram.type",
    "IB_PYP"
  );

  return {
    unitPlanId,
    unitPlantitle,
    curriculumType,
    filters,
  };
};

const AssessmentLibraryWrapper = compose(
  connect(mapStateToProps, mapActionCreators),
  graphql(getUnitPlanResourceLibraryQuery, {
    name: "getUnitPlanResourceLibrary",
    options: ({ unitPlanId, filters }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: unitPlanId,
        filters,
      },
      context: {
        useHttpLink: true,
      },
    }),
    props({ getUnitPlanResourceLibrary, ownProps: { unitPlanId, filters } }) {
      const unitPlanDetails = getUnitPlanResourceLibraryFromCache({
        id: unitPlanId,
        filters: initialState.filters,
      });

      const filteredUnitPlanDetails = getUnitPlanResourceLibraryFromCache({
        id: unitPlanId,
        filters,
      });

      return {
        onPusherEventTriggered: () => getUnitPlanResourceLibrary.refetch(),
        isData: !_.isEmpty(unitPlanDetails),
        isFilteredData: !_.isEmpty(filteredUnitPlanDetails),
        isLoading:
          getUnitPlanResourceLibrary["networkStatus"] == 1 ||
          getUnitPlanResourceLibrary["networkStatus"] == 2,
        getUnitPlanResourceLibrary,
        assessments: _.map(
          _.get(filteredUnitPlanDetails, "resourceLibrary.edges", []),
          resource => resource.node
        ),
      };
    },
  }),
  withPusherBind(["unitPlanResourceLibraryFeedUpdate"])
)(AssessmentLibrary);

AssessmentLibraryWrapper.propTypes = {
  label: PropTypes.string,
  subText: PropTypes.string,
  labelStyle: PropTypes.object,
  labelComponent: PropTypes.element,
  name: PropTypes.string,
  errorStyle: PropTypes.object,
  customValidation: PropTypes.func,
  mode: PropTypes.string,
  assessmentTypes: PropTypes.array,
  options: PropTypes.array,
};

AssessmentLibraryWrapper.defaultProps = {
  label: "",
  subText: "",
  labelStyle: {},
  mode: "edit",
  size: "regular",
  groupType: "LEARNING_ENGAGEMENT",
  assessmentTypes: [],
  options: [],
};

export default AssessmentLibraryWrapper;
