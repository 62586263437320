import React from "react";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { EmptyField, I18nHOC } from "UIComponents";
import { addRelatedConcept } from "IBPlanner/modules/IBPlannerModule";
import RelatedConceptsModal from "./RelatedConceptsModal";
import { connect } from "react-redux";
import { compose } from "react-apollo";

import classes from "./RelatedConcepts.scss";
import RelatedConceptViewList from "./RelatedConceptViewList";
import { getRelatedConceptsGroupedValueOptions } from "UnitPlans/modules/UnitPlanModule";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  ELEMENT_TYPE_PYP_RELATED_CONCEPT,
  ELEMENT_TYPE_UBD_RELATED_CONCEPT,
} from "Constants/stringConstants";

const PROGRAM_TYPE_AND_ELEMENT_SET_TYPE_MAPPING = {
  [CURRICULUM_TYPE_PYP]: ELEMENT_TYPE_PYP_RELATED_CONCEPT,
  [CURRICULUM_TYPE_UBD]: ELEMENT_TYPE_UBD_RELATED_CONCEPT,
};

class RelatedConcepts extends UIBaseComponent {
  constructor(props) {
    super(props);
    if (props.customRef) {
      props.customRef(this);
    }
    this.state = {
      ...this.state,
      modalVisible: false,
      isEditModal: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { onFocusInputField, onBlurInputField } = this.props;
    const { modalVisible, actionId } = this.state;
    const { modalVisible: prevModalVisible } = prevState;
    if (modalVisible != prevModalVisible) {
      if (modalVisible) {
        onFocusInputField({ others: { id: actionId } });
      } else {
        onBlurInputField({ others: { id: actionId } });
      }
    }
  };

  blur = () => {
    this.setState({ modalVisible: false });
  };

  onModalCancelClick = () => {
    this.setState({ modalVisible: false });
  };

  onEditClick = ({ actionId }) => {
    this.setState({ modalVisible: true, isEditModal: true, actionId });
  };

  isValidValue = value => {
    const { groupedRelatedConcepts } = this.props;

    return _.get(groupedRelatedConcepts, "length", 0) > 0;
  };

  onAddClick = async ({
    groupedOptions,
    currentGroupedOptions,
    groupedValue,
    orgRelatedConceptSets,
  }) => {
    const {
      addRelatedConcept,
      subjects,
      concepts,
      relatedConcepts,
      plannerElementSet,
      groupedValue: oldGroupedValue,
    } = this.props;

    groupedValue = { ...(oldGroupedValue || {}), ...(groupedValue || {}) };

    const newAddedOptions = _.reduce(
      currentGroupedOptions,
      (result, options, key) => {
        const newOptions = _.differenceBy(
          options,
          groupedOptions[key],
          "value"
        );
        const [elementType, elementId] = [...key.split(":")];
        _.map(newOptions, opt => {
          result.push({
            label: opt.label,
            elementId,
            elementType,
          });
        });
        return result;
      },
      []
    );

    let newRelatedConcept = [];
    if (newAddedOptions.length > 0) {
      newRelatedConcept = await addRelatedConcept({
        relatedConcepts: newAddedOptions,
        subjects,
        concepts,
        plannerElementSetId: plannerElementSet.id,
      });
      _.forEach(groupedValue, (value, key) => {
        const [elementType, elementId] = [...key.split(":")];
        const newGroupedOptions = _.filter(
          newRelatedConcept,
          set => set.elementId == elementId && set.elementType == elementType
        );
        _.map(newGroupedOptions, opt => {
          const valIndex = _.indexOf(value, opt.label);
          value[valIndex] = opt.id;
        });
      });
    }
    const mergedValue = _.reduce(
      groupedValue,
      (result, value) => {
        result = [...result, ...value];
        return result;
      },
      []
    );

    const outPutNodes = _.uniqBy(
      [
        ...(newRelatedConcept || []),
        ...(orgRelatedConceptSets || []),
        ...(relatedConcepts || []),
      ],
      "id"
    );

    this.updateValue(mergedValue, {
      nodes: _.map(outPutNodes, item => {
        return {
          node: item,
        };
      }),
    });
  };

  renderView = () => {
    const {
      emptyText,
      listStyle,
      listHeaderStyle,
      listItemsStyle,
      groupedValue,
      mainContainerStyle,
      headerContainerStyle,
      groupedRelatedConcepts,
    } = this.props;

    return (
      <div className={classes.viewList}>
        <RelatedConceptViewList
          mode={"view"}
          emptyText={emptyText}
          listStyle={listStyle}
          groupedValue={groupedValue}
          listItemsStyle={listItemsStyle}
          listHeaderStyle={listHeaderStyle}
          mainContainerStyle={mainContainerStyle}
          headerContainerStyle={headerContainerStyle}
          groupedRelatedConcepts={groupedRelatedConcepts}
        />
      </div>
    );
  };

  shouldShowEmpty = () => {
    const { value } = this.props;

    return !this.isValidValue(value);
  };

  renderEditEmpty = () => {
    const { t } = this.props;
    return <EmptyField title={t("unitPlan:add_subject_or_key_concept")} />;
  };

  renderEdit = () => {
    const {
      groupedRelatedConcepts,
      groupedValue,
      fieldLockedObject,
      readOnly,
    } = this.props;

    const { lockedDynamicFields } = fieldLockedObject;

    return (
      <div className={classes.container}>
        <div className={classes.list}>
          <RelatedConceptViewList
            readOnly={readOnly}
            groupedRelatedConcepts={groupedRelatedConcepts}
            groupedValue={groupedValue}
            lockedDynamicFields={lockedDynamicFields}
            onEditClick={this.onEditClick}
            mode={"edit"}
          />
        </div>
      </div>
    );
  };

  renderChildren = () => {
    const { modalVisible, isEditModal, actionId } = this.state;
    const {
      groupedRelatedConcepts,
      groupedOptions,
      value,
      subjects,
      concepts,
      selectedRelatedConcepts,
      options,
    } = this.props;
    const splitValues = _.split(actionId, ":");
    const actionType = splitValues.length > 0 ? splitValues[0] : "SUBJECT";

    return (
      !!modalVisible && (
        <RelatedConceptsModal
          relatedConceptSets={_.get(
            _.find(groupedRelatedConcepts, { id: actionId }),
            "relatedConceptSets",
            []
          )}
          groupedOptionsFields={_.get(options, "groupedOptionsFields", [])}
          selectedRelatedConcepts={selectedRelatedConcepts}
          onCancelClick={this.onModalCancelClick}
          value={value}
          onAddClick={this.onAddClick}
          isEdit={isEditModal}
          updateValueLocally={this.updateValueLocally}
          actionType={actionType}
          subjects={actionType == "SUBJECT" ? subjects : []}
          concepts={actionType == "CONCEPT" ? concepts : []}
        />
      )
    );
  };
}

const mapActionCreators = {
  addRelatedConcept,
};

const mapStateToProps = (state, ownProps) => {
  const {
    groupedRelatedConcepts,
    groupedValue,
    groupedOptions,
  } = memoizedGetGroupedValueOptions({
    nodes: _.get(ownProps, "resolvedValue.relatedConcepts", []),
    subjects: _.get(ownProps, "options.subjects", []),
    value: _.get(ownProps, "value", []),
    concepts: _.get(ownProps, "options.concepts", []),
    groupedOptionsFields: _.get(ownProps, "options.groupedOptionsFields", []),
  });

  const currentCurriculumProgram = state.platform.currentCurriculumProgram;
  const currentCurriculumProgramType = currentCurriculumProgram.type;
  const plannerElementSetType =
    PROGRAM_TYPE_AND_ELEMENT_SET_TYPE_MAPPING[currentCurriculumProgramType];

  const currentPlannerElementSet =
    state.platform.currentPlannerElementSets[plannerElementSetType];

  return {
    isData: true,
    isLoading: false,
    organizationId: state.login.userInfo.org_id,
    subjects: ownProps.options.subjects,
    concepts: ownProps.options.concepts,
    groupedRelatedConcepts,
    groupedOptions,
    groupedValue,
    plannerElementSet: currentPlannerElementSet,
    selectedRelatedConcepts: _.get(
      ownProps,
      "resolvedValue.relatedConcepts",
      []
    ),
    relatedConcepts: _.get(ownProps, "resolvedValue.relatedConcepts", []),
  };
};

const memoizedGetGroupedValueOptions = _.memoize(
  getRelatedConceptsGroupedValueOptions,
  params => JSON.stringify(params)
);

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "unitPlan" })
)(RelatedConcepts);
