import React from "react";
import classes from "./DynamicChildren.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import FieldComponent from "../FieldComponent";
import { fontStyle } from "Constants";
import { EmptyField, I18nHOC } from "UIComponents";
import { LabelComponent } from "UnitPlans/components/LabelComponent";
import { FieldComponentContext } from "Projects/modules/ProjectModules";

const styles = {
  labelStyle: {
    fontSize: "1.4rem",
    ...fontStyle.medium,
  },
  labelContainerStyle: {
    marginBottom: "4px",
  },
};

class DynamicChildren extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  updateInputFieldLocally = ({ params, extraParams }) => {
    const { updateInputField } = this.props;
    updateInputField(params, extraParams);
  };

  renderEditEmpty = () => {
    const { emptyText, emptyContainerStyle } = this.props;
    return (
      !!emptyText && (
        <EmptyField containerStyle={emptyContainerStyle} title={emptyText} />
      )
    );
  };

  renderEdit = () => {
    const {
      mode,
      childrenContainerStyle,
      childrenList,
      onFocusInputField,
      onBlurInputField,
      t,
      getOptions,
      getFieldValue,
      getGraphqlEnhancerQueryInfo,
      getUpdatedConfig,
      helpEnabledFields,
      getIsHelpActive,
      onHelpClick,
      updateSelectedTemplate,
    } = this.props;

    //to-do Wrapper component and JSON stringify/parser
    return (
      <FieldComponentContext.Consumer>
        {({ fields }) => {
          return (
            <div className={classes.container} style={childrenContainerStyle}>
              {_.map(childrenList, field => {
                const {
                  config = {},
                  type,
                  viewType,
                  childrenList = [],
                  id,
                  disabled = false,
                  inputStyle,
                } = field;

                const fieldDetails = _.find(fields, { uid: id });

                const fielduid = _.get(fieldDetails, "uid");

                const value = getFieldValue({
                  type,
                  fielduid,
                });

                const graphqlEnhancerQueryInfo = getGraphqlEnhancerQueryInfo({
                  fielduid,
                });

                const fieldId = _.get(fieldDetails, "id");

                const {
                  label = "",
                  subtext = "",
                  labelStyle = {},
                  subTextStyle = {},
                  childContainerStyle = {},
                  readLabelFromValue = false,
                  emptyText,
                  placeholder,
                  isPageHeader,
                  isSectionHeader,
                } = config;

                const labelValue = _.get(value, "localeLabel", "");

                const localeSubtext = _.get(value, "localeSubtext", "");

                const updateConfig = getUpdatedConfig({ config, fielduid });
                const helpText = _.find(helpEnabledFields, eachField => {
                  return _.get(eachField, "uid", "") === id;
                });
                const helperTextId = _.get(helpText, "helpText.id", "");

                const labelComponent = (
                  <LabelComponent
                    {...updateConfig}
                    label={label}
                    subText={subtext}
                    showHelper={!_.isEmpty(helpText)}
                    isHelpActive={getIsHelpActive({ fielduid: id })}
                    onHelpClick={() =>
                      onHelpClick({
                        fieldKey: id,
                        helperTextId: helperTextId,
                        id: fieldId,
                      })
                    }
                    isPageHeader={isPageHeader}
                    isSectionHeader={isSectionHeader}
                    isFieldHeader={!isPageHeader && !isSectionHeader}
                  />
                );

                return (
                  <div className={classes.fieldContainer}>
                    {readLabelFromValue && (
                      <LabelComponent
                        label={t(labelValue)}
                        subText={localeSubtext && t(localeSubtext)}
                        labelStyle={labelStyle}
                        subTextStyle={subTextStyle}
                        isPageHeader={isPageHeader}
                        isSectionHeader={isSectionHeader}
                        isFieldHeader={!isPageHeader && !isSectionHeader}
                      />
                    )}
                    <div
                      className={classes.childContainer}
                      style={childContainerStyle}
                    >
                      {/* to-do locked  */}
                      <FieldComponent
                        {...updateConfig}
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        key={id}
                        labelComponent={labelComponent}
                        customRef={this.setFieldRef}
                        onFocusInputField={onFocusInputField}
                        onBlurInputField={onBlurInputField}
                        type={type}
                        fieldKey={id}
                        fieldId={fieldId}
                        updateInputField={this.updateInputFieldLocally}
                        mode={mode}
                        styles={styles}
                        childrenList={childrenList}
                        emptyText={emptyText}
                        inputStyle={inputStyle}
                        getOptions={getOptions}
                        getFieldValue={getFieldValue}
                        graphqlEnhancerQueryInfo={graphqlEnhancerQueryInfo}
                        getGraphqlEnhancerQueryInfo={
                          getGraphqlEnhancerQueryInfo
                        }
                        getUpdatedConfig={getUpdatedConfig}
                        helpEnabledFields={helpEnabledFields}
                        getIsHelpActive={getIsHelpActive}
                        onHelpClick={onHelpClick}
                        updateSelectedTemplate={updateSelectedTemplate}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
      </FieldComponentContext.Consumer>
    );
  };

  shouldShowViewEmpty = () => {
    return false;
  };

  shouldShowEditEmpty = () => {
    const { childrenList } = this.props;
    return _.isEmpty(childrenList);
  };

  renderView = () => {
    return this.renderEdit();
  };
}

DynamicChildren.defaultProps = {
  ...UIBaseComponent.defaultProps,
  childrenContainerStyle: {},
};

DynamicChildren.propTypes = {
  ...UIBaseComponent.propTypes,
};

export default I18nHOC({ resource: ["projectTemplate"] })(DynamicChildren);
