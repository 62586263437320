import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import ContractTrackingForm from "./routes/ContractTrackForm";
export default store => {
  return {
    path: "contract-tracking",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./ContractTracking").default;
              const reducer = require("./modules/ContractTrackModule").default;
              injectReducer(store, { key: "schoolContractTrack", reducer });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "administrator-tooling"
          );
        })
    ),
    childRoutes: [ContractTrackingForm(store)],
  };
};
