/**--external-- */
import React from "react";
import propTypes from "prop-types";

/**--internal-- */
import { I18nHOC, FormTextInput } from "UIComponents";

/**--relative-- */
import classes from "./AdminOptions.scss";

const AdminOptions = props => {
  const { admin, updateAdmin, t } = props;
  const { id, firstName, lastName, email } = admin;
  return (
    <div className={classes.container}>
      <div className={classes.nameContainer}>
        <FormTextInput
          value={firstName}
          size="x-large"
          label={t("onBoarding:first_name")}
          placeholder={t("onBoarding:enter_first_name")}
          updateInputField={value => updateAdmin({ id, firstName: value })}
        />

        <FormTextInput
          value={lastName}
          size="x-large"
          label={t("onBoarding:last_name")}
          placeholder={t("onBoarding:enter_last_name")}
          updateInputField={value => updateAdmin({ id, lastName: value })}
        />
      </div>
      <FormTextInput
        value={email}
        size="x-large"
        label={t("onBoarding:admin_email")}
        placeholder={t("onBoarding:enter_admin_email")}
        updateInputField={value => updateAdmin({ id, email: value })}
      />
    </div>
  );
};

export default I18nHOC({ resource: ["onBoarding"] })(AdminOptions);

AdminOptions.propTypes = {
  admin: propTypes.object,
  updateAdmin: propTypes.func,
  t: propTypes.func,
};
