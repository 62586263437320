import React from "react";
import PropTypes from "prop-types";
import classes from "./AttachmentList.scss";
import CreateDropDown from "../CreateDropDown";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { DialogueBox, I18nHOC, FullScreenLoader } from "UIComponents";
import AttachmentItem from "../AttachmentItem";
import AttachmentItemV2 from "../AttachmentItemV2";
import AttachmentCard from "../AttachmentCard";
import AttachmentCardV2 from "../AttachmentCardV2";
import AttachmentItemV3 from "../AttachmentItemV3";
import MediaCardWrapper from "../MediaCardWrapper/MediaCardWrapper";
import {
  removeProgressElement,
  createAttachmentGroups,
  deleteAttachmentGroups,
  createAttachment,
  deleteAttachment,
  convertPDFToWorkbook,
} from "modules/Services";
import { getAttachmentCardTypeForAttachmentList } from "Utils";
import { Trans } from "react-i18next";
import { Badge } from "@toddle-design/web";

const DIALOGS_INFO = {
  DELETE: {
    title: "common:delete_with_label",
    message: "common:delete_msg_with_label_multiline",
    confirmButtonText: "common:delete_agree",
    cancelButtonText: "common:agree_cancel",
  },
};

const renderAttachmentItemV2ClubbingCard = ({
  attachment,
  mode,
  horizontalCardStyle,
  showInlineAudioPlayer,
  showClubbedCard,
  onClickClubCard,
  lengthOfClubbedAttachment,
  t,
  deleteAttachment,
}) => {
  return (
    <React.Fragment>
      <AttachmentItemV2
        key={attachment.id}
        attachment={attachment}
        mode={mode}
        showThumb={true}
        horizontalCardStyle={horizontalCardStyle}
        showInlineAudioPlayer={showInlineAudioPlayer}
        deleteAttachment={deleteAttachment}
      />
      {!showClubbedCard && (
        <div onClick={onClickClubCard} className={classes.seeMoreBtn}>
          {t("common:see_more_count_between", {
            count: lengthOfClubbedAttachment,
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const ClubbingCard = React.memo(
  ({
    attachment,
    lengthOfClubbedAttachment,
    cardStyle,
    mode,
    showOverlayIcons,
    deleteAttachment,
    showClubbedCard,
    onClickClubCard,
    itemStyles,
    cardType,
    horizontalCardStyle,
    showSingleCardTheme,
    showInlineAudioPlayer,
    t,
  }) => {
    if (showInlineAudioPlayer) {
      return renderAttachmentItemV2ClubbingCard({
        attachment,
        mode,
        horizontalCardStyle,
        showInlineAudioPlayer,
        showClubbedCard,
        onClickClubCard,
        lengthOfClubbedAttachment,
        t,
      });
    }
    switch (cardType) {
      case "verticalHorizontal":
        return (
          <AttachmentCardV2
            cardStyle={cardStyle}
            key={attachment.id}
            attachment={attachment}
            deleteAttachment={deleteAttachment}
            mode={mode}
            showOverlayIcons={showOverlayIcons}
            onClickMedia={onClickClubCard}
            clubingPlusCard={!showClubbedCard}
            lengthOfClubbedAttachment={lengthOfClubbedAttachment}
            itemStyles={itemStyles}
            cardType={cardType}
            showSingleCardTheme={showSingleCardTheme}
          />
        );

      case "vertical":
        return (
          <AttachmentCard
            cardStyle={cardStyle}
            key={attachment.id}
            attachment={attachment}
            deleteAttachment={deleteAttachment}
            mode={mode}
            showOverlayIcons={showOverlayIcons}
            onClickMedia={onClickClubCard}
            clubingPlusCard={!showClubbedCard}
            lengthOfClubbedAttachment={lengthOfClubbedAttachment}
            itemStyles={itemStyles}
          />
        );

      case "horizontalV2":
        return renderAttachmentItemV2ClubbingCard({
          attachment,
          mode,
          horizontalCardStyle,
          showInlineAudioPlayer,
          showClubbedCard,
          onClickClubCard,
          lengthOfClubbedAttachment,
          t,
          deleteAttachment,
        });
    }
  }
);

ClubbingCard.displayName = "ClubbingCard";

class AttachmentList extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showClubbedCard: false,
      currentDialogAction: null,
      isLoading: false,
    };
    if (props.customRef) {
      props.customRef(this);
    }
    this.attachmentItemRef = React.createRef(null);
  }

  processAttachmentBeforeUpload = async attachment => {
    //check if pdf present in attachments

    if (
      _.get(attachment, "type", "") === "WORKBOOK" &&
      _.get(attachment, "mimeType", "") === "application/pdf"
    ) {
      try {
        //convert pdf -> workbook
        this.setState({ isLoading: true });
        const {
          url: newUrl,
          numOfPages,
        } = await this.props.convertPDFToWorkbook({
          url: attachment.url,
        });
        this.setState({ isLoading: false });
        if (_.isEmpty(newUrl)) return {};

        return {
          type: "WORKBOOK",
          url: newUrl,
          name: "Workbook",
          metadata: {
            ...attachment.metadata,
            workbookUrl: newUrl,
            isWorkbookWithPageView: true,
            sourcePdfUrl: attachment.url,
            oldUrls: [],
            oldWorkbookUrls: [],
            numOfPages: numOfPages,
          },
        };
      } catch (e) {
        this.setState({ isLoading: false });
        return {};
      }
    }
    return attachment;
  };

  addAttachment = async (newAttachments, uploadId) => {
    const {
      value,
      shouldCreateAttachmentGroup,
      shouldCallMutation,
      parentId,
      parentType,
      createAttachmentGroups,
      createAttachment,
      portalType,
    } = this.props;

    const newAttachmentsArr = await Promise.all(
      _.map(newAttachments, this.processAttachmentBeforeUpload)
    );

    newAttachments = _.filter(
      newAttachmentsArr,
      attachment => !_.isEmpty(attachment)
    );

    if (_.isEmpty(newAttachments)) return;

    if (shouldCallMutation) {
      if (shouldCreateAttachmentGroup) {
        this.setState({ isLoading: true });
        newAttachments = await createAttachmentGroups({
          input: {
            parentId,
            parentType,
            attachments: newAttachments,
          },
          portalType,
        });
        this.setState({ isLoading: false });
      } else {
        newAttachments = await Promise.all(
          _.map(newAttachments, attachment => {
            return createAttachment({
              attachment,
              nodeId: parentId,
              parentType,
              type: parentType,
            });
          })
        );
        newAttachments = _.map(newAttachments, attachments =>
          _.last(attachments)
        );
      }
    } else {
      newAttachments = _.map(newAttachments, attachment => {
        if (shouldCreateAttachmentGroup) {
          return {
            id: uploadId,
            attachments: [{ ...attachment, id: uploadId }],
            isPost: true,
          };
        } else {
          return { ...attachment, id: uploadId };
        }
      });
    }
    this.updateValue([...value, ...newAttachments], parentId);
  };

  deleteAttachment = async (attachmentId, isForced = false) => {
    const {
      value,
      deleteAttachmentGroups,
      deleteAttachment,
      parentId,
      parentType,
      shouldCallMutation,
    } = this.props;

    if (shouldCallMutation) {
      const attachment = _.find(value, { id: attachmentId });
      if (isForced) {
        if (_.get(attachment, "isPost", false)) {
          this.setState({ isLoading: true });
          await deleteAttachmentGroups({
            attachmentGroupIds: [attachmentId],
            parentId,
            parentType,
          });
          this.setState({ isLoading: false });
        } else {
          deleteAttachment({
            nodeId: parentId,
            attachmentId,
            type: parentType,
          });
        }
        this.updateValue(
          _.filter(value, attachment => attachment.id != attachmentId),
          parentId
        );
      } else {
        this.setState({ currentDialogAction: "DELETE" });
        this.currentDialgItem = attachment;
      }
    } else {
      this.updateValue(
        _.filter(value, attachment => attachment.id != attachmentId)
      );
    }
  };

  stopAllUploads = () => {
    const { uploadingResources, removeProgressElement } = this.props;
    _.forEach(uploadingResources, resource => {
      if (resource.cancelToken) {
        resource.cancelToken();
      }

      removeProgressElement({ id: resource.id });
    });
  };

  updateSelectedItems = ({ attachment, isSelected }) => {
    const { selectedItemType, updateSelectedItems, selectedItems } = this.props;
    let updatedSelectedItems = [];
    if (isSelected) {
      updatedSelectedItems = _.filter(selectedItems, item => {
        if (selectedItemType == "OBJECT") {
          return item.id != attachment.id;
        } else {
          return item == attachment.id;
        }
      });
    } else {
      if (selectedItemType == "OBJECT") {
        updatedSelectedItems = [...selectedItems, attachment];
      } else {
        updatedSelectedItems = [...selectedItems, attachment.id];
      }
    }
    updatedSelectedItems;
    updateSelectedItems(updatedSelectedItems);
  };

  renderList = () => {
    const {
      value,
      setToastMsg,
      updateLoadingState,
      exportAsPdf,
      parentId,
      parentType,
      uploadingResources,
      accept,
      mode,
      cardType,
      showCreateDropdown,
      containerStyle,
      createDropDownContainerStyle,
      attachmentContainerStyle,
      showOverlayIcons,
      onClickMedia,
      cardStyle,
      attachmentResponseObject,
      selectedItems,
      selectedItemType,
      dropDownOptions,
      addMediaText,
      onDropDownOptionClick,
      showSingleCardTheme,
      clubbingCards,
      canAddMaximumAttachments,
      t,
      portalType,
      buttonDropDownContainerStyle,
      buttonParentStyle,
      buttonDropDownStyle,
      createDropdownComponent,
      shouldCreateAsMedia,
      mediaCreationOptions,
      iconContainerStyle,
      onToggleMedia,
      itemStyles,
      onClickAttachmentCard,
      onClickCreatePost,
      activeAttachment,
      horizontalCardStyle,
      addAttachmentOptions,
      onLinkTypeClick,
      sourceType,
      showCreatePostButton,
      toolTipText,
      isDisabledModeObj,
      showOpenLinkInNewTabIcon,
      deleteMsgLabel = "common:media",
      isRightPaneChat,
      shouldCallMutation,
      onDeleteAudioAttachment,
      updateAttachments,
      createDropdownWrapperStyle,
      addButtonStyles,
      imageContainerStyle,
      attachmentBottomComponent,
      progressOfUploads,
      horizontalCardInnerClass,
      disableActions,
      mediaCardSize,
      addMediaTextLocale,
      showDeleteForSelf,
      getViewModeExtraButtons,
      isShowIconsInBar,
      showV2Icons,
      customDropZoneStyle,
      showLocalAttachmentsInViewMode,
      createDropDownButtonComponent,
      attachmentExportOptions,
      defaultExportType,
      multiselect,
      disabled = false,
      createButtonComponentConfig = {},
      disableCreateDropdown,
      deleteEntityLabel,
      showNotificationsDot = false,
      showActionsOnHover,
    } = this.props;

    const { showClubbedCard, currentDialogAction, isLoading } = this.state;

    const conStyle = { paddingBottom: showClubbedCard ? "32px" : 0 };

    const keyToUniqBy = _.first(value)?.attachmentId ? "attachmentId" : "id";

    const attachments = _.uniqBy(
      [...(value ? value : []), ...uploadingResources],
      keyToUniqBy
    );

    const lengthOfAttachments = _.get(attachments, "length", 0);

    const isClubbing = clubbingCards && lengthOfAttachments > clubbingCards;

    const lengthOfClubbedAttachment =
      _.get(attachments, "length", 0) - clubbingCards;

    const onClickClubCard = () => {
      if (showClubbedCard) {
        onClickMedia && onClickMedia();
      } else {
        this.setState({ showClubbedCard: true });
      }
    };
    const deleteLabel = deleteEntityLabel
      ? deleteEntityLabel
      : t(deleteMsgLabel);

    return (
      <div
        className={classes.container}
        style={{
          ...containerStyle,
          ...conStyle,
          ...attachmentContainerStyle,
        }}
      >
        {_.map(attachments, (attachment, index) => {
          const isSelected =
            _.findIndex(selectedItems, item => {
              if (selectedItemType == "OBJECT") {
                return item.id == attachment.id;
              } else {
                return item == attachment.id;
              }
            }) >= 0;

          // this will not allow to delete if attachment id is not there
          let attachmentMode = mode;
          if (
            _.includes(attachment.id, "NEW_") &&
            showLocalAttachmentsInViewMode
          ) {
            attachmentMode = "view";
          }
          const showInlineAudioPlayer =
            isRightPaneChat && _.get(attachment, "type", "") === "AUDIO";

          let isDisabledMode = false;
          if (!_.isEmpty(isDisabledModeObj)) {
            isDisabledMode = _.get(isDisabledModeObj, attachment.id, false);
          }

          const attachmentCardTypeToRender = getAttachmentCardTypeForAttachmentList(
            {
              isClubbing,
              index,
              cardType,
              clubbingCards,
              showClubbedCard,
              showInlineAudioPlayer,
            }
          );

          const getAttachmentCardType = () => {
            switch (attachmentCardTypeToRender) {
              case "ClubbingCard":
                return (
                  <ClubbingCard
                    key={attachment.id}
                    attachment={attachment}
                    lengthOfClubbedAttachment={lengthOfClubbedAttachment}
                    cardStyle={cardStyle}
                    mode={attachmentMode}
                    showOverlayIcons={showOverlayIcons}
                    deleteAttachment={this.deleteAttachment}
                    showClubbedCard={showClubbedCard}
                    onClickMedia={onClickMedia}
                    onClickClubCard={onClickClubCard}
                    itemStyles={itemStyles}
                    cardType={cardType}
                    showSingleCardTheme={showSingleCardTheme}
                    horizontalCardStyle={horizontalCardStyle}
                    t={t}
                    showInlineAudioPlayer={showInlineAudioPlayer}
                  />
                );
              case "design-media-card": {
                return (
                  <MediaCardWrapper
                    mediaCardSize={mediaCardSize}
                    activeAttachment={activeAttachment}
                    deleteAttachment={this.deleteAttachment}
                    mode={attachmentMode}
                    key={attachment.id}
                    attachment={attachment}
                    onClickAttachmentCard={onClickAttachmentCard}
                    attachmentBottomComponent={() =>
                      _.isFunction(attachmentBottomComponent) &&
                      !_.get(progressOfUploads, `${attachment.id}`, null) &&
                      attachmentBottomComponent({ attachment })
                    }
                    horizontalCardStyle={horizontalCardStyle}
                    getViewModeExtraButtons={getViewModeExtraButtons}
                    sourceType={sourceType}
                    updateAttachments={updateAttachments}
                    showActionsOnHover={showActionsOnHover}
                  />
                );
              }
              case "horizontalV2":
                return (
                  <AttachmentItemV2
                    // setting ref only for the active attachment
                    ref={
                      activeAttachment === attachment.id
                        ? this.attachmentItemRef
                        : null
                    }
                    key={attachment.id}
                    attachment={attachment}
                    mode={attachmentMode}
                    onClickAttachmentCard={onClickAttachmentCard}
                    onClickCreatePost={onClickCreatePost}
                    activeAttachment={activeAttachment}
                    showCreatePostButton={showCreatePostButton}
                    showThumb={true}
                    showOpenLinkInNewTabIcon={showOpenLinkInNewTabIcon}
                    updateSelectedItems={this.updateSelectedItems}
                    deleteAttachment={this.deleteAttachment}
                    horizontalCardStyle={horizontalCardStyle}
                    showInlineAudioPlayer={showInlineAudioPlayer}
                    disableActions={disableActions}
                    attachmentBottomComponent={params =>
                      _.isFunction(attachmentBottomComponent) &&
                      !_.get(progressOfUploads, `${attachment.id}`, null) &&
                      attachmentBottomComponent({ ...params, attachment })
                    }
                    horizontalCardInnerClass={horizontalCardInnerClass}
                    attachmentResponseObject={attachmentResponseObject}
                    showDeleteForSelf={showDeleteForSelf}
                    parentId={parentId}
                    parentType={parentType}
                    isLastAttachment={index === lengthOfAttachments - 1}
                  />
                );
              case "horizontalV3":
                return (
                  <AttachmentItemV3
                    key={attachment.id}
                    attachment={attachment}
                    mode={attachmentMode}
                    onClickAttachmentCard={onClickAttachmentCard}
                    horizontalCardStyle={horizontalCardStyle}
                    isDisabledMode={isDisabledMode}
                    showCreatePostButton={showCreatePostButton}
                    onClickCreatePost={onClickCreatePost}
                    showDeleteForSelf={showDeleteForSelf}
                    deleteAttachment={this.deleteAttachment}
                    attachmentBottomComponent={params =>
                      _.isFunction(attachmentBottomComponent) &&
                      attachmentBottomComponent({ ...params, attachment })
                    }
                  />
                );
              case "verticalHorizontal":
                return (
                  <AttachmentCardV2
                    key={attachment.id}
                    attachment={attachment}
                    isSelected={isSelected}
                    cardStyle={cardStyle}
                    mode={attachmentMode}
                    showOverlayIcons={showOverlayIcons}
                    onClickMedia={onClickMedia}
                    showSingleCardTheme={showSingleCardTheme}
                    deleteAttachment={this.deleteAttachment}
                    updateSelectedItems={this.updateSelectedItems}
                    iconContainerStyle={iconContainerStyle}
                    itemStyles={itemStyles}
                    cardType={cardType}
                    toolTipText={toolTipText}
                    isDisabledMode={isDisabledMode}
                    attachmentResponseObject={attachmentResponseObject}
                    attachmentBottomComponent={
                      !_.get(progressOfUploads, `${attachment.id}`, null) &&
                      attachmentBottomComponent
                    }
                    horizontalCardInnerClass={horizontalCardInnerClass}
                  />
                );
              case "horizontal":
                return (
                  <AttachmentItem
                    isSelected={isSelected}
                    key={attachment.id}
                    attachment={attachment}
                    mode={attachmentMode}
                    onClickMedia={onClickMedia}
                    customContainerStyle={horizontalCardStyle}
                    updateSelectedItems={this.updateSelectedItems}
                    deleteAttachment={this.deleteAttachment}
                    onClickAttachmentCard={onClickAttachmentCard}
                    sourceType={sourceType}
                    onDeleteAudioAttachment={onDeleteAudioAttachment}
                    updateAttachments={updateAttachments}
                    imageContainerStyle={imageContainerStyle}
                    disabled={disabled}
                  />
                );
              case "vertical":
                return (
                  <AttachmentCard
                    cardStyle={cardStyle}
                    key={attachment.id}
                    attachment={attachment}
                    deleteAttachment={this.deleteAttachment}
                    mode={attachmentMode}
                    showOverlayIcons={showOverlayIcons}
                    onClickMedia={onClickMedia}
                    iconContainerStyle={iconContainerStyle}
                    onToggleMedia={onToggleMedia}
                    itemStyles={itemStyles}
                    onLinkTypeClick={onLinkTypeClick}
                    isDisabledMode={isDisabledMode}
                    toolTipText={toolTipText}
                    attachmentResponseObject={attachmentResponseObject}
                  />
                );
            }
          };

          return showNotificationsDot &&
            //isNil is used to ensure that notification is not shown on the card in case of null or undefined values. Only for false boolean we will show the notifications.
            !_.isNil(attachment?.isRead) &&
            !attachment?.isRead ? (
            <Badge type="dot">
              <div> {getAttachmentCardType()} </div>
            </Badge>
          ) : (
            getAttachmentCardType()
          );
        })}

        {!!showCreateDropdown &&
          mode == "edit" &&
          !disabled &&
          (!canAddMaximumAttachments ||
            canAddMaximumAttachments > lengthOfAttachments) &&
          (createDropdownComponent ? (
            createDropdownComponent
          ) : (
            <div
              className={classes.createContainer}
              style={createDropdownWrapperStyle}
            >
              <CreateDropDown
                attachments={value}
                mediaCreationOptions={mediaCreationOptions}
                shouldCreateAsMedia={shouldCreateAsMedia}
                exportAsPdf={exportAsPdf}
                addAttachment={this.addAttachment}
                updateLoadingState={updateLoadingState}
                setToastMsg={setToastMsg}
                parentId={parentId}
                parentType={parentType}
                accept={accept}
                buttonDropDownContainerStyle={{
                  top: cardType == "horizontal" ? 72 : 154,
                  ...buttonDropDownContainerStyle,
                }}
                buttonParentStyle={buttonParentStyle}
                buttonDropDownStyle={buttonDropDownStyle}
                addMediaText={
                  addMediaTextLocale ? t(addMediaTextLocale) : addMediaText
                }
                cardType={cardType}
                containerStyle={{
                  height: cardType == "horizontal" ? 71 : 154,
                  borderRadius: cardType == "horizontal" ? 8 : 2,
                  ...createDropDownContainerStyle,
                }}
                dropDownOptions={dropDownOptions}
                onDropDownOptionClick={onDropDownOptionClick}
                portalType={portalType}
                addAttachmentOptions={addAttachmentOptions}
                sourceType={sourceType}
                shouldCallMutation={shouldCallMutation}
                updateAttachments={updateAttachments}
                addButtonStyles={addButtonStyles}
                isShowIconsInBar={isShowIconsInBar}
                showV2Icons={showV2Icons}
                customDropZoneStyle={customDropZoneStyle}
                buttonComponent={createDropDownButtonComponent}
                defaultExportType={defaultExportType}
                attachmentExportOptions={attachmentExportOptions}
                multiselect={multiselect}
                createButtonComponentConfig={createButtonComponentConfig}
                disabled={disableCreateDropdown}
              />
            </div>
          ))}
        {showClubbedCard && (
          <div
            className={classes.seeLessBtn}
            onClick={() => this.setState({ showClubbedCard: false })}
          >{`See Less`}</div>
        )}
        {!!currentDialogAction && (
          <DialogueBox
            modalTitle={t(DIALOGS_INFO[currentDialogAction].title, {
              label: deleteLabel,
            })}
            showModal={true}
            onClickButton2={() =>
              this.deleteAttachment(
                _.get(this.currentDialgItem, "id", ""),
                true
              )
            }
            modalBody={
              <Trans i18nKey={DIALOGS_INFO[currentDialogAction].message}>
                {{
                  label: deleteLabel,
                }}
                <div></div>
              </Trans>
            }
            toggleDialogueBoxDisplay={() =>
              this.setState({ currentDialogAction: null })
            }
            button1={
              t(DIALOGS_INFO[currentDialogAction].cancelButtonText) ||
              t("common:cancel")
            }
            button2={t(DIALOGS_INFO[currentDialogAction].confirmButtonText)}
          />
        )}
        {isLoading && <FullScreenLoader />}
      </div>
    );
  };

  renderEdit = () => {
    return this.renderList();
  };

  renderView = () => {
    return this.renderList();
  };
}

const getUploadingResources = ({ parentId, parentType, progressOfUploads }) => {
  const keys = Object.keys(progressOfUploads);
  const uploadingResources = [];
  _.forEach(keys, key => {
    const resource = progressOfUploads[key];

    if (
      resource.parentType == parentType &&
      resource.parentId == parentId &&
      resource.status !== "DOWNLOAD"
    ) {
      uploadingResources.push({
        id: resource.id,
        progress: resource.progress,
        cancelToken: resource.cancelToken,
        ..._.get(resource, "attachment", {}),
      });
    }
  });

  return uploadingResources;
};

const mapStateToProps = (state, ownProps) => {
  const progressOfUploads = state.app_services.progressOfUploads;
  const mode = _.get(ownProps, "disabled", false) ? "view" : ownProps?.mode;

  return {
    uploadingResources: getUploadingResources({
      parentType: ownProps.parentType,
      parentId: ownProps.parentId,
      progressOfUploads,
    }),
    progressOfUploads,
    mode,
  };
};

const mapActionCreators = {
  removeProgressElement,
  createAttachmentGroups,
  deleteAttachmentGroups,
  createAttachment,
  deleteAttachment,
  convertPDFToWorkbook,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators, null, { withRef: true })
)(AttachmentList);

AttachmentList.propTypes = {
  ...UIBaseComponent.propTypes,
  value: PropTypes.array,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  mode: PropTypes.oneOf(["view", "edit", "select"]),
  cardType: PropTypes.oneOf([
    "horizontal",
    "verticalHorizontal",
    "horizontalV2",
    "horizontalV3",
    "vertical",
  ]),
  showCreateDropdown: PropTypes.bool,
  onClickMedia: PropTypes.func,
  clubbingCards: PropTypes.number,
  showSingleCardTheme: PropTypes.bool,
  activeAttachment: PropTypes.string,
  showCreatePostButton: PropTypes.bool,
  showOpenLinkInNewTabIcon: PropTypes.bool,
  isRightPaneChat: PropTypes.bool,
  createDropdownContainerStyle: PropTypes.object,
  showDeleteForSelf: PropTypes.bool,
  showLocalAttachmentsInViewMode: PropTypes.bool,
  showActionsOnHover: PropTypes.bool,
  disableCreateDropdown: PropTypes.bool,
};

AttachmentList.defaultProps = {
  ...UIBaseComponent.defaultProps,
  canAddMaximumAttachments: null,
  shouldCreateAttachmentGroup: false,
  shouldCallMutation: false,
  exportAsPdf: false,
  parentId: "",
  parentType: "",
  mode: "edit",
  cardType: "horizontal",
  selectedItemType: "ID",
  showCreateDropdown: true,
  disableCreateDropdown: false,
  emptyText: "",
  attachmentContainerStyle: {},
  containerStyle: {},
  createDropDownContainerStyle: {},
  showOverlayIcons: true,
  selectedItems: [],
  clubbingCards: 0,
  showSingleCardTheme: false,
  shouldCreateAsMedia: false,
  buttonDropDownContainerStyle: {},
  buttonDropDownStyle: {},
  activeAttachment: "",
  showCreatePostButton: false,
  showOpenLinkInNewTabIcon: false,
  isRightPaneChat: false,
  showDeleteForSelf: false,
  showLocalAttachmentsInViewMode: true,
  showActionsOnHover: false,
};
