import React from "react";
import classes from "./ItemViewFeed.scss";
import PropTypes from "prop-types";

import { UILabel, I18nHOC } from "UIComponents";
import { AlertIcon } from "SvgComponents";
import { colors } from "Constants";
import MoreItem from "./MoreItem";
class ItemViewFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showAll: false };
  }
  renderMoreCard = () => {};

  onMoreClick = e => {
    e.stopPropagation();
    this.setState({ showAll: true });
  };
  render() {
    const {
      label,
      subText,
      labelComponent,
      labelStyle,
      items,
      mode,
      labelContainerStyle,
      maxItemCount,
      renderItem,
      itemText,
      emptyText,
      t,
    } = this.props;
    const { showAll } = this.state;
    const itemLength = _.get(items, "length", 0);
    let maxItemCountValue =
      maxItemCount + 1 == itemLength ? itemLength : maxItemCount;
    const moreItemsCount = itemLength - maxItemCountValue;
    const updatedEmptyText = emptyText
      ? emptyText
      : `${t("common:no_label_added", { label: `${t(itemText)}` })}`;
    return (
      <div className={classes.container}>
        <UILabel
          label={label}
          subText={subText}
          labelComponent={labelComponent}
          labelStyle={labelStyle}
          labelContainerStyle={labelContainerStyle}
        />
        {itemLength > 0 ? (
          <div className={classes.feedContainer}>
            {_.map(items, (item, index) => {
              if (index < maxItemCountValue || showAll) {
                return (
                  <div
                    className={classes.itemCard}
                    key={item.id ? item.id : index}
                  >
                    {renderItem({ item, index })}
                  </div>
                );
              }
            })}

            {moreItemsCount > 0 && !showAll && (
              <div className={classes.itemCard}>
                <MoreItem
                  moreItemsCount={moreItemsCount}
                  itemText={t(itemText, { count: moreItemsCount })}
                  onMoreClick={this.onMoreClick}
                />
              </div>
            )}
          </div>
        ) : (
          !!updatedEmptyText && (
            <div className={classes.emptyTextContainer}>
              <AlertIcon fill={colors.yellow50} width={16} height={16} />
              <div className={classes.emptyText}>{updatedEmptyText}</div>
            </div>
          )
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: "common" })(ItemViewFeed);

ItemViewFeed.propTypes = {
  maxItemCount: PropTypes.number,
  emptyText: PropTypes.string,
};

ItemViewFeed.defaultProps = {
  maxItemCount: 1,
  emptyText: "",
};
