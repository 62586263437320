import { localSearch } from "Utils";

export const getDefaultDesignation = ({ userType }) => {
  switch (userType) {
    case "student": {
      return { title: "Student" };
    }
    case "parent": {
      return { title: "Family member" };
    }
    case "staff": {
      return { title: "Teacher" };
    }
    default: {
      return { title: userType };
    }
  }
};

export const getMatchingIdentities = ({ linkedIdentities, searchText }) => {
  return _.filter(linkedIdentities, linkedIdentity => {
    const organization = _.get(linkedIdentity, "organization", {});

    const organizationName = _.get(organization, "name", "");

    return localSearch({ text: organizationName, searchText }) > -1;
  });
};

/**
 * This util will take userList data structure(which contains identity details) and converts it in
 * a format which can be used in OrganizationList Component
 */
export const getLinkedIdentities = ({ userList }) => {
  return _.map(userList, user => {
    const {
      org_id,
      org_name,
      org_logo,
      user_id,
      user_type,
      identity_id,
      designation,
    } = user;

    const organization = {
      id: org_id,
      name: org_name,
      logo: org_logo,
    };

    return {
      id: user_id,
      organization,
      /**
       * For student and family members designation does not exist,
       * so for those users we call getDefaultDesignation to get default designation.
       */
      designation: !_.isEmpty(designation)
        ? designation
        : getDefaultDesignation({ userType: user_type }),
      userType: user_type,
      identityId: identity_id,
    };
  });
};

export const getUserLinkedIdentitiesDetailsFromIdentityPool = ({
  identityPoolDetails,
}) => {
  const { edges } = identityPoolDetails;

  const userList = _.map(edges, ({ node }) => node);

  const linkedIdentities = getLinkedIdentities({ userList });

  return { linkedIdentities };
};
