import React from "react";

import classes from "./ThemeCarousel.scss";
import { CategoryData } from "Planathon/data";
import { I18nHOC } from "UIComponents";
import _ from "lodash";
class ThemeCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
    this.setInterval();
  }

  setInterval = () => {
    const { currentIndex } = this.state;
    const { themes } = CategoryData;
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.setState({ currentIndex: (currentIndex + 1) % themes.length });

      this.setInterval();
    }, 5000);
  };

  onDotClick = ({ index }) => {
    this.setState({ currentIndex: index }, this.setInterval);
  };

  render() {
    const { t } = this.props;
    const {
      themes,
      heading,
      subHeading,
      gradeDetails,
      themeDetails,
    } = CategoryData;
    const { currentIndex } = this.state;
    const { image, title } = themes[currentIndex];

    return (
      <div
        className={classes.container}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={classes.overlay}>
          <div className={classes.topContainer}>
            <div className={classes.heading}>{t(heading)}</div>
            <div className={classes.subHeading}>{t(subHeading)}</div>
            <div className={classes.middleDetails}>
              <div
                className={classes.themeGradeContainer}
                style={{ borderColor: gradeDetails.color }}
              >
                <div className={classes.number}>{t(gradeDetails.count)}</div>
                <div className={classes.detailLabel}>
                  {t(gradeDetails.label)}
                </div>
                <div className={classes.detailSubLabel}>
                  {t(gradeDetails.subTitle)}
                </div>
              </div>
              <div
                className={classes.themeGradeContainer}
                style={{ borderColor: themeDetails.color }}
              >
                <div className={classes.number}>{t(themeDetails.count)}</div>
                <div className={classes.detailLabel}>
                  {t(themeDetails.label)}
                  <span className={classes.detailSubLabel}>
                    {` ${t(themeDetails.subTitle)}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.title}>{t(title)}</div>
            <div className={classes.dotContainer}>
              {_.map(themes, (theme, index) => {
                return (
                  <div
                    onClick={() => {
                      this.onDotClick({ index });
                    }}
                    className={classes.dot}
                    key={index}
                    style={{ opacity: currentIndex == index ? 1 : 0.6 }}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["planathon"] })(ThemeCarousel);
