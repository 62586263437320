import React from "react";
import { graphql, compose } from "react-apollo";
import classNames from "classnames";

import classes from "./FolderFilter.scss";
import { I18nHOC } from "UIComponents";
import FolderItem from "Journal/components/FolderItem/FolderItem";

import { getCourseFolders } from "Journal/modules/JournalQuery";

const FolderFilter = props => {
  const { courseId, folders, handleFolderChange, activeFolder, t } = props;
  const headerClass = classNames(
    { [classes.headerText]: courseId !== "ALL" },
    { [classes.ironHeaderText]: courseId === "ALL" }
  );

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={headerClass}>{t("journal:filterByFolder")}</div>
      </div>
      {courseId === "ALL" ? (
        <div className={classes.infoText}>
          {t("journal:select_class_to_filter_by_folder")}
        </div>
      ) : (
        <React.Fragment>
          <FolderItem
            key={"all"}
            isFolderSelected={!activeFolder}
            handleFolderChange={handleFolderChange}
            folderDetails={{ value: "", label: t("common:all") }}
          />
          {_.map(folders, folderItem => {
            const { id, name, color } = folderItem;
            const folderDetails = {
              value: id,
              label: name,
              color,
            };
            return (
              <FolderItem
                key={id}
                folderDetails={folderDetails}
                handleFolderChange={handleFolderChange}
                isFolderSelected={activeFolder === folderItem.id}
              />
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export default compose(
  I18nHOC({ resource: ["journal"] }),
  graphql(getCourseFolders, {
    name: "getCourseFolders",
    skip: ({ courseId }) => courseId === "ALL",
    options: ({ courseId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        courseId: courseId,
      },
    }),
    props({ getCourseFolders, ownProps: { isLoading, isData } }) {
      return {
        getCourseFolders,
        isData: getCourseFolders.node && isData,
        networkStatus: getCourseFolders.networkStatus,
        folders: _.get(getCourseFolders, "node.folders", []),
        isLoading:
          getCourseFolders["networkStatus"] == 1 ||
          getCourseFolders["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(FolderFilter);
