import React from "react";
import classes from "./VoiceRecorder.scss";
import ScreenPanel from "../../ScreenPanel";
import { I18nHOC, AudioRecorderV2, UIButton, Textview } from "UIComponents";
import { compose } from "react-apollo";
import { fontStyle } from "Constants";

const styles = {
  control: {
    position: "absolute",
    bottom: 0,
    left: 0,
    boxShadow: "-2px 0 8px 0 rgba(0,0,0,0.1)",
    zIndex: 10,
    justifyContent: "center",
  },
  buttonsContainerStyles: {
    width: 600,
    margin: "0 auto",
    display: "flex",
  },
  buttonStyles: { flex: 1 },
};

const itemStyle = {
  labelStyle: {
    fontSize: "1.8rem",
    ...fontStyle.bold,
    marginBottom: "8px",
  },
};

const audioRecorderStyles = {
  innerContainerStyle: { maxWidth: "600px", padding: "unset" },
};

const textViewStyles = {
  labelContainerStyle: { marginBottom: 0 },
};

class VoiceRecorder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInstructionVisible: false,
    };
  }
  componentDidMount() {
    const {
      screenPanelProps: { updatePost },
    } = this.props;
    updatePost({ attachments: [] });
  }

  handleFile = attachment => {
    const {
      screenPanelProps: {
        updatePost,
        goToStep,
        postDetails: { attachments = [] },
      },
    } = this.props;

    updatePost({ attachments: [...attachments, attachment] });
    goToStep("POST_DETAIL");
  };

  goBack = () => {
    const {
      screenPanelProps: { onCloseClick },
    } = this.props;
    onCloseClick();
  };

  /**
   *   Function to toggle view/hide instruction
   *   student-web classroom voice submission
   */
  updateInstructionVisibility = () => {
    const { isInstructionVisible } = this.state;
    this.setState({
      isInstructionVisible: !isInstructionVisible,
    });
  };

  viewHideInstructionsButton = () => {
    const { isInstructionVisible } = this.state;
    const { t } = this.props;
    return (
      <UIButton
        size="sm"
        color={"blue"}
        type={"hollow"}
        onClick={this.updateInstructionVisibility}
      >
        {isInstructionVisible
          ? t("common:hide_instructions")
          : t("common:view_instructions")}
      </UIButton>
    );
  };

  getInstructionsFromContent = () => {
    const {
      screenPanelProps: { mediaCreationOptions },
    } = this.props;
    const content = _.get(mediaCreationOptions, "instructionsData.content", {});
    const contentType = _.get(
      mediaCreationOptions,
      "instructionsData.contentType",
      ""
    );
    if (
      contentType === "ASSIGNMENT_RESOURCE" ||
      contentType === "ASSIGNMENT_DISCUSSION"
    ) {
      return _.get(content, "label", "");
    }
    if (contentType === "ASSESSMENT") {
      return _.get(content, "description.value", "");
    }
  };

  render() {
    const { screenPanelProps = {}, t } = this.props;
    const { mediaCreationOptions = {}, onCloseClick } = screenPanelProps;
    const { isInstructionVisible } = this.state;
    const showInstructionsWithVoice = _.get(
      mediaCreationOptions,
      "showInstructionsWithVoice",
      false
    );
    return (
      <ScreenPanel
        {...screenPanelProps}
        rightContainerContent={
          showInstructionsWithVoice ? this.viewHideInstructionsButton() : null
        }
      >
        <div className={classes.container}>
          {isInstructionVisible && (
            <div className={classes.instructionField}>
              <Textview
                {...textViewStyles}
                label={t("classRoom:instructions")}
                mode={"view"}
                showViewEmpty={false}
                labelStyle={itemStyle.labelStyle}
                value={this.getInstructionsFromContent()}
              ></Textview>
            </div>
          )}
          <div className={classes.audioRecorder}>
            {!showInstructionsWithVoice && (
              <div className={classes.label}>{t("record_voice")}</div>
            )}
            <AudioRecorderV2
              handleAudioFile={this.handleFile}
              styles={styles}
              audioRecorderType={isInstructionVisible ? "strip" : "radial"}
              autoStartRecording={false}
              {...audioRecorderStyles}
              onCancel={onCloseClick}
              onDraftModalOutsideClick={this.goBack}
            />
          </div>
        </div>
      </ScreenPanel>
    );
  }
}

export default compose(I18nHOC({ resource: ["common", "classRoom"] }))(
  VoiceRecorder
);
