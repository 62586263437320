import React, { useMemo } from "react";
import { connect } from "react-redux";
import { withLoader } from "UIComponents";
import { withRouter } from "react-router";
import { compose, graphql } from "react-apollo";
import { getStaffListGraphql } from "Course/modules/EnhanceQueries";
import {
  FILTER_NAME_ACADEMIC_YEAR,
  FILTER_NAME_GRADES,
  FILTER_NAME_CREATORS,
  FILTER_NAME_POST_TYPE,
  FILTER_NAME_SUBJECTS,
} from "Course/Constants/StringConstants";
import { useI18n } from "Hooks";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import { getAcademicYearsListAndGradeList } from "Course/modules/CourseModule";
import { getOrganizationSubjectsQuery } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorQueries";
import { getOrganizationSubjectsFromCache } from "MultiLevelNodeEditor/modules/MultiLevelNodeEditorGraphqlHelpers";
import ACLStore from "lib/aclStore";
const getPostFilterOptions = ({
  curriculumProgramType,
  t,
  shouldIncludeQuickTasks,
  groupType,
}) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_DP:
    case CURRICULUM_TYPE_MYP: {
      const quickTaskFilter = shouldIncludeQuickTasks
        ? [
            {
              label: t("common:quick_task"),
              value: "QUICK_TASK",
            },
          ]
        : [];
      let postTypeFilter;
      // for importing LE in unit plan
      if (groupType) {
        postTypeFilter =
          groupType === "LEARNING_ENGAGEMENT"
            ? [
                {
                  label: t("common:le_label"),
                  value: "LEARNING_ENGAGEMENT_MYP",
                },
              ]
            : [
                {
                  label: t("common:formative_assessment"),
                  value: "FORMATIVE_ASSESSMENT_MYP",
                },
                {
                  label: t("common:summative_assessment"),
                  value: "SUMMATIVE_ASSESSMENT_MYP",
                },
              ];
      } else {
        postTypeFilter = [
          { label: t("common:le_label"), value: "LEARNING_ENGAGEMENT_MYP" },
          {
            label: t("common:formative_assessment"),
            value: "FORMATIVE_ASSESSMENT_MYP",
          },
          {
            label: t("common:summative_assessment"),
            value: "SUMMATIVE_ASSESSMENT_MYP",
          },
        ];
      }
      return [...quickTaskFilter, ...postTypeFilter];
    }
    case CURRICULUM_TYPE_UBD: {
      const quickTaskFilter = shouldIncludeQuickTasks
        ? [
            {
              label: t("common:quick_task"),
              value: "QUICK_TASK_UBD",
            },
          ]
        : [];
      let postTypeFilter;
      if (groupType) {
        postTypeFilter =
          groupType === "LEARNING_ENGAGEMENT"
            ? [
                {
                  label: t("common:le_label"),
                  value: "LEARNING_ENGAGEMENT_UBD",
                },
              ]
            : [
                {
                  label: t("common:assessment_label_plural"),
                  value: "ASSESSMENT_UBD",
                },
              ];
      } else {
        postTypeFilter = [
          { label: t("common:le_label"), value: "LEARNING_ENGAGEMENT_UBD" },
          {
            label: t("common:assessment_label_plural"),
            value: "ASSESSMENT_UBD",
          },
        ];
      }
      return [...quickTaskFilter, ...postTypeFilter];
    }
    case CURRICULUM_TYPE_PYP:
      return [
        {
          label: t("common:quick_task"),
          value: "QUICK_TASK",
        },
        { label: t("common:le_label"), value: "LEARNING_ENGAGEMENT" },
      ];
    default: {
      return [];
    }
  }
};
const LELibraryFiltersHOC = WrapperComponent => {
  const WithLELibraryFilters = props => {
    const {
      academicYearsList,
      gradeList,
      creatorList,
      subjectsList,
      organizationId,
      curriculumProgramType,
      shouldIncludeQuickTasks = true,
      groupType,
    } = props;
    const { t } = useI18n(["common"]);
    const postTypeList = getPostFilterOptions({
      curriculumProgramType,
      t,
      shouldIncludeQuickTasks,
      groupType,
    });
    const filtersInfo = useMemo(
      () => [
        {
          id: FILTER_NAME_ACADEMIC_YEAR,
          name: FILTER_NAME_ACADEMIC_YEAR,
          options: academicYearsList,
          label: "common:academic_year",
          showSearchBar: false,
          isCheckList: false,
          showAllOption: false,
          visibleToCurriculum: [
            CURRICULUM_TYPE_PYP,
            CURRICULUM_TYPE_MYP,
            CURRICULUM_TYPE_UBD,
            CURRICULUM_TYPE_DP,
          ],
        },
        {
          id: FILTER_NAME_GRADES,
          name: FILTER_NAME_GRADES,
          options: gradeList,
          label: "common:grade",
          showSearchBar: true,
          noOptionLabel: t("common:select"),
          isCheckList: true,
          showAllOption: true,
          visibleToCurriculum: [
            CURRICULUM_TYPE_PYP,
            CURRICULUM_TYPE_MYP,
            CURRICULUM_TYPE_UBD,
            CURRICULUM_TYPE_DP,
          ],
        },
        {
          id: FILTER_NAME_SUBJECTS,
          name: FILTER_NAME_SUBJECTS,
          options: subjectsList,
          label: "common:subject",
          showSearchBar: true,
          isCheckList: true,
          showAllOption: true,
          noOptionLabel: t("common:select"),
          toolTipText: t("common:select_label_first", {
            label: t("common:grade"),
          }),
          visibleToCurriculum: [
            CURRICULUM_TYPE_UBD,
            CURRICULUM_TYPE_MYP,
            CURRICULUM_TYPE_DP,
          ],
        },
        {
          id: FILTER_NAME_POST_TYPE,
          name: FILTER_NAME_POST_TYPE,
          options: postTypeList,
          label: "common:post_type",
          showSearchBar: false,
          isCheckList: true,
          showAllOption: true,
          noOptionLabel: t("common:select"),
          visibleToCurriculum: [
            ...(shouldIncludeQuickTasks ? [CURRICULUM_TYPE_PYP] : []),
            CURRICULUM_TYPE_MYP,
            CURRICULUM_TYPE_UBD,
            CURRICULUM_TYPE_DP,
          ],
        },
        {
          id: FILTER_NAME_CREATORS,
          name: FILTER_NAME_CREATORS,
          options: creatorList,
          label: "common:creator",
          isCheckList: true,
          showAllOption: true,
          noOptionLabel: t("common:select"),
          isSearchBasedOnQuery: true,
          organizationId,
          graphqlQueryEnhancer: getStaffListGraphql,
          visibleToCurriculum: [
            CURRICULUM_TYPE_PYP,
            CURRICULUM_TYPE_MYP,
            CURRICULUM_TYPE_UBD,
            CURRICULUM_TYPE_DP,
          ],
        },
      ],
      [
        academicYearsList,
        gradeList,
        creatorList,
        organizationId,
        subjectsList,
        postTypeList,
        t,
        shouldIncludeQuickTasks,
      ]
    );
    const newProps = {
      ...props,
      defaultCreators: _.map(creatorList, ({ value }) => value),
      defaultPostType: _.map(postTypeList, item => item.value),
      filtersInfo: _.filter(filtersInfo, item =>
        _.includes(item.visibleToCurriculum, curriculumProgramType)
      ),
    };
    return <WrapperComponent {...newProps} />;
  };
  const mapStateToProps = (state, ownProps) => {
    const organizationId = state.login.userInfo.org_id;
    const curriculumProgramType = state.platform.currentCurriculumProgramType;
    const curriculumProgramId = _.get(
      state,
      "platform.currentCurriculumProgram.id",
      null
    );
    const {
      academicYearsList,
      defaultAcademicYear,
      gradeList,
    } = getAcademicYearsListAndGradeList({
      organizationId,
      curriculumProgramId,
    });
    const defaultGrades = state.teacher.selected_class.selected_grades;
    const currentUserId = state.login.userInfo.id;

    const curriculumProgramIds = curriculumProgramId
      ? [curriculumProgramId]
      : [];
    return {
      curriculumProgramType,
      curriculumProgramIds,
      academicYearsList,
      gradeList,
      organizationId,
      defaultAcademicYear,
      defaultGrades,
      currentUserId,
      isData: true,
      isLoading: false,
    };
  };
  return compose(
    withRouter,
    connect(mapStateToProps),
    getStaffListGraphql,
    graphql(getOrganizationSubjectsQuery, {
      name: "getOrganizationSubjects",
      options: ({ organizationId, curriculumProgramIds }) => ({
        fetchPolicy: "cache-and-network",
        variables: {
          id: organizationId,
          filters: {
            curriculumProgramIds,
          },
        },
      }),
      skip: ({ curriculumProgramType }) =>
        _.isEqual(curriculumProgramType, CURRICULUM_TYPE_PYP),
      props({
        getOrganizationSubjects,
        ownProps: { organizationId, curriculumProgramIds, isData, isLoading },
      }) {
        const data = getOrganizationSubjectsFromCache({
          id: organizationId,
          filters: {
            curriculumProgramIds,
          },
        });
        const subjects = _.get(data, "subjects", []);
        const subjectsList = _.map(subjects, subject => ({
          value: subject.id,
          label: subject.name,
        }));
        return {
          isData: !_.isEmpty(data) && isData,
          isLoading:
            isLoading ||
            _.includes([1, 2], getOrganizationSubjects["networkStatus"]),
          subjectsList,
          subjects: _.map(subjects, item => ({
            value: item.id,
            label: item.name,
            grades: _.map(item.grades, grade => grade.id),
          })),
        };
      },
    }),
    withLoader
  )(WithLELibraryFilters);
};
export default LELibraryFiltersHOC;
