import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS_ID } from "Constants";

export const initGA = () => {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
};

export const setGA = params => {
  ReactGA.set(params);
};
export const logPageView = location => {
  // console.log(`Logging pageview for ${window.location.pathname}`);
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

export const logEvent = (category = "", action = "") => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
