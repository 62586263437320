import _ from "lodash";
import React from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import { PICKER_DEFAULT_IMAGES, DEFAULT_THUMB_IMAGE } from "store/static";
import { getRethumbUrl } from "./GetRethumbUrl";
// import { generateRandomId } from "./GenerateRandomId";
import {
  colors,
  POST_MEDIA_TYPES,
  GOOGLE_DEVELOPER_KEY,
  MISC_FILE_EXTENSION_MIMETYPE_MAPPING,
} from "Constants";
import mime from "mime-types";
import { Sentry } from "services/sentry";
// import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import {
  LinkIcon,
  PlayIcon,
  NoteIconSvg,
  WordDocIcon,
  PresentationIcon,
  PDFThumbnailIcon,
  SpreadsheetIcon,
  UnknownFileIcon,
  VideoIconSvg,
  AudioIcon,
  AttachmentPhotoSvg,
} from "SvgComponents";
import {
  WorkbookOutlined,
  LinkColored,
  FilenotfoundColored,
  PdfColored,
  SheetsColored,
  SlidesColored,
  DocumentsColored,
  VideoColored,
  AudioColored,
  TableColored,
  ZipFileColored,
} from "@toddle-design/web-icons";

// const ffmpeg = createFFmpeg({ log: false });

export const ATTACHMENT_WARNINGS = {
  GOOGLE_TEACHER_ACCOUNT_NOT_LINKED:
    "toastMsgs:google_teacher_account_not_linked",
  GOOGLE_TEACHER_ACCOUNT_NOT_WORKING:
    "toastMsgs:google_teacher_account_not_working",
  STUDENT_EMAIL_NOT_CONNECTED: "toastMsgs:student_gmail_not_connected",
  GOOGLE_NOT_ABLE_TO_ASSIGN_PERMISSION:
    "toastMsgs:google_not_able_to_assign_permission",
  GOOGLE_NOT_ABLE_TO_COPY: "toastMsgs:google_not_able_to_copy",
  ATTACHMENT_WITHOUT_PERMISSION: "toastMsgs:attachments_without_permissions",
  GOOGLE_ACCOUNT_NOT_LINKED: "toastMsgs:google_account_not_linked",
  GOOGLE_ACCOUNT_NOT_WORKING: "toastMsgs:google_account_not_linked",
};

const TEMPLATE_OPTIONS = [
  { label: "common:drive_student_copy", value: "copy" },
  { label: "common:all_students_can_edit_file", value: "edit" },
];

/**
 * Extracted from a api in google documentation for export formats for each
 * type of google document. It's important to notice that here, only one
 * format is chosen for a given document.
 *
 * Link: https://developers.google.com/drive/api/v3/reference/about/get
 */
const GOOGLE_EXPORT_FORMATS = {
  "application/vnd.google-apps.document": [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  "application/vnd.google-apps.spreadsheet": [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  "application/vnd.google-apps.presentation": [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
  "application/vnd.google-apps.jam": ["application/pdf"],
  "application/vnd.google-apps.script": [
    "application/vnd.google-apps.script+json",
  ],
  "application/vnd.google-apps.form": ["application/zip"],
  "application/vnd.google-apps.drawing": ["image/png"],
  "application/vnd.google-apps.site": ["text/plain"],
};

/**
 * This function takes file and access token and returns an array
 * of exportable link objects.
 *
 * @param {Object} param0
 * @param {Object} param0.file - Chosen file object from google picker
 * @param {String} param0.accessToken - Google access token
 *
 * @returns {Array} exportLinkObjects - Array of exportable link objects
 */
export const getExportLinks = async ({ file, accessToken }) => {
  const baseUrl = `https://www.googleapis.com/drive/v3/files/${file.id}`;
  const { text: type } = getFileType(file.mimeType) || {};
  const isShareAsFileFirst =
    type == "IMAGE" ||
    type == "VIDEO" ||
    type == "AUDIO" ||
    file.mimeType == "application/pdf";

  const linkObj = {
    label: "common:drive_share_as_link",
    value: "LINK",
    url: file.url,
    mimeType: file.mimeType,
  };
  // Link as a default option
  const exportLinkObjects = [];
  if (!isShareAsFileFirst) {
    exportLinkObjects.push(linkObj);
  }

  try {
    // Fetch export formats valid for google document
    // TODO: Add caching on network calls
    const response = await axios.get(
      `https://www.googleapis.com/drive/v3/about?fields=exportFormats`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    const exportFormats = _.get(response, "data.exportFormats", []);
    const exportMimeTypes = _.get(exportFormats, file.mimeType, []);

    if (!_.isEmpty(exportMimeTypes)) {
      const fileMimeType = _.head(
        _.intersection(
          exportMimeTypes,
          _.get(GOOGLE_EXPORT_FORMATS, file.mimeType, [])
        )
      );

      // Check if File format exists
      if (!_.isEmpty(fileMimeType)) {
        exportLinkObjects.push({
          label: "common:drive_share_as_file",
          value: "FILE",
          url: `${baseUrl}/export?mimeType=${fileMimeType}&key=${GOOGLE_DEVELOPER_KEY}`,
          mimeType: fileMimeType,
        });
      }
    } else {
      // This gets executed when a file doesn't belong to google document
      exportLinkObjects.push({
        label: "common:drive_share_as_file",
        value: "FILE",
        url: `${baseUrl}?alt=media`,
        mimeType: file.mimeType,
      });
    }

    if (isShareAsFileFirst) {
      exportLinkObjects.push(linkObj);
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  return exportLinkObjects;
};

/**
 * This function returns a single accessible url for a chosen file from
 * google picker.
 *
 * @param {Object} param0
 * @param {Object} param0.file - Chosen file object from google picker
 * @param {String} param0.accessToken - Google access token
 *
 * @return {String} - File url
 */
export const getExportableFileUrl = async ({ file, accessToken }) => {
  const exportLinks = await getExportLinks({ file, accessToken });
  return _.get(_.find(exportLinks, { value: "FILE" }, {}), "url");
};

const getRethumb = ({ imageUrl, width, height, fitIn = true }) => {
  const rethumbUrl = imageUrl
    ? getRethumbUrl({
        width: width,
        height: height,
        imageUrl: imageUrl,
        fitIn: fitIn,
      })
    : "";
  return `${rethumbUrl}`;
};

export const getFileType = mimetype => {
  const fileType = mimetype ? mimetype.split("/")[0] : "";

  if (fileType == "image") {
    return { text: "IMAGE", locale: "common:image" };
  } else if (fileType == "video") {
    return { text: "VIDEO", locale: "common:video" };
  } else if (fileType == "audio") {
    return { text: "AUDIO", locale: "common:audio" };
  } else {
    return { text: "FILE", locale: "common:file" };
  }
};

export const isLocalUrl = url => {
  return _.includes(url, "blob");
};

export const getGoogleFileType = mimeType => {
  switch (mimeType) {
    case "application/vnd.google-apps.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
    case "application/x-iwork-pages-sffpages":
    case "application/vnd.apple.pages":
      return "DOC";
    case "application/epub+zip":
      return "EPUB";
    case "application/pdf":
      return "PDF";
    case "application/vnd.google-apps.spreadsheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/x-iwork-numbers-sffnumbers":
    case "application/vnd.apple.numbers":
      return "SHEET";
    case "application/vnd.google-apps.presentation":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/x-iwork-keynote-sffkey":
    case "application/vnd.apple.keynote":
      return "SLIDES";
    case "application/vnd.google-apps.drawing":
      return "DRAWING";
    default:
      return null;
  }
};

export const getFileThumbUrl = mimeType => {
  let thumbUrl = "";
  let color = `${colors.white}`;
  let borderColor = `${colors.strokeTwo}`;
  let fileType = "";

  switch (mimeType) {
    case "application/vnd.google-apps.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
    case "application/x-iwork-pages-sffpages":
    case "application/vnd.apple.pages":
      thumbUrl = PICKER_DEFAULT_IMAGES[`DOCS`];
      color = "#ecf2fd";
      borderColor = "#2b83fa";
      fileType = "DOCUMENT";
      break;
    case "application/epub+zip":
      thumbUrl = PICKER_DEFAULT_IMAGES[`EPUB`];
      color = "#F4F8F9";
      borderColor = "#20475A";
      fileType = "ZIP";
      break;
    case "application/pdf":
      thumbUrl = PICKER_DEFAULT_IMAGES[`PDF`];
      color = "#fceceb";
      borderColor = "#ea4336";
      fileType = "PDF";
      break;
    case "video/webm;codecs=vp8,opus":
    case "video/webm":
    case "video/mp4":
    case "video/avi":
    case "video/wmv":
    case "video/quicktime":
      thumbUrl = PICKER_DEFAULT_IMAGES[`VIDEO`];
      color = "#6464dc";
      borderColor = "#6464dc";
      fileType = "VIDEO";
      break;
    case "application/vnd.google-apps.spreadsheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
    case "application/x-iwork-numbers-sffnumbers":
    case "application/vnd.apple.numbers":
    case "text/csv":
      thumbUrl = PICKER_DEFAULT_IMAGES[`SHEET`];
      color = "#e7f5ee";
      borderColor = "#0c9d59";
      fileType = "SHEET";
      break;
    case "application/vnd.google-apps.presentation":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/x-iwork-keynote-sffkey":
    case "application/vnd.apple.keynote":
      thumbUrl = PICKER_DEFAULT_IMAGES[`SLIDES`];
      color = "#fef7e8";
      borderColor = "#f9b615";
      fileType = "SLIDES";
      break;
    case "application/zip":
      thumbUrl = PICKER_DEFAULT_IMAGES[`ZIP`];
      color = "#fef7e8";
      borderColor = "#f9b615";
      fileType = "ZIP";
      break;
    case "application/vnd.google-apps.drawing":
      thumbUrl = PICKER_DEFAULT_IMAGES["DRAWING"];
      color = "#FBEDEC";
      fileType = "DRAWING";
      break;
    case "audio/mpeg":
    case "audio/mp3":
    case "audio/mp4":
    case "audio/vnd.wav":
    case "audio/x-m4a":
    case "audio/aac":
      thumbUrl = PICKER_DEFAULT_IMAGES["AUDIO"];
      color = "#EFEFFB";
      fileType = "AUDIO";
      break;

    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/bmp":
    case "image/svg+xml":
      thumbUrl = PICKER_DEFAULT_IMAGES["IMAGE"];
      color = "#E8EDF0";
      fileType = "IMAGE";
      break;

    default:
      thumbUrl = PICKER_DEFAULT_IMAGES[`UNKNOWN`];
      color = "#f8f8f8";
      fileType = "UNKNOWN";
      break;
  }

  return { thumbUrl, color, borderColor, fileType };
};

export const getThumbUrl = ({
  attachment,
  width = 256,
  height = 256,
  fitIn = true,
  onlyIcon = false,
  cardType = "horizontal",
}) => {
  if (!attachment) {
    return {};
  }

  const { type, mimeType, url, thumbUrl } = attachment;

  let newThumbUrl = "";
  let isIcon = false;
  let color = `${colors.white}`;
  let borderColor = `${colors.strokeTwo}`;
  let fileType = "UNKNOWN";

  if (onlyIcon) {
    isIcon = true;
    const {
      thumbUrl: iconThumbUrl,
      color: iconColor,
      borderColor: borderColorAlias,
      fileType: attachmentFileType,
    } = getFileThumbUrl(mimeType);
    color = iconColor;
    newThumbUrl = iconThumbUrl;
    borderColor = borderColorAlias;
    fileType = attachmentFileType;
    let fileExtension = getFileExtensionFromMimeType({ mimeType });
    if (fileExtension == false) fileExtension = "unknown";
    return {
      thumbUrl: newThumbUrl,
      color,
      isIcon: isIcon,
      borderColor,
      fileType,
      fileExtension:
        fileExtension == "unknown" ? fileExtension : `.${fileExtension}`,
    };
  }
  if (type == "NOTE") {
    newThumbUrl = PICKER_DEFAULT_IMAGES["NOTE"];
    isIcon = true;
  } else if (type == "IMAGE") {
    newThumbUrl = isLocalUrl(url)
      ? `${url}`
      : getRethumb({ imageUrl: url, width, height, fitIn });
  } else if (type === "WORKBOOK") {
    newThumbUrl =
      cardType === "horizontal"
        ? PICKER_DEFAULT_IMAGES["WORKBOOK"]
        : _.get(attachment, "thumbUrl", "");
    isIcon = cardType === "horizontal";
    color = colors.pink98;
    borderColor = colors.strokeTwo;
  } else if (thumbUrl) {
    newThumbUrl =
      mimeType == "application/pdf"
        ? thumbUrl
        : getRethumb({ imageUrl: thumbUrl, width, height, fitIn });
  } else {
    isIcon = true;
    const {
      thumbUrl: iconThumbUrl,
      color: iconColor,
      borderColor: borderColorAlias,
      fileType: attachmentFileType,
    } = getFileThumbUrl(mimeType);
    color = iconColor;
    newThumbUrl = iconThumbUrl;
    borderColor = borderColorAlias;
    fileType = attachmentFileType;
  }
  let fileExtension = getFileExtensionFromMimeType({ mimeType });
  if (fileExtension == false) fileExtension = "unknown";
  return {
    thumbUrl: newThumbUrl,
    color,
    isIcon: isIcon,
    borderColor,
    fileType: fileType,
    fileExtension:
      fileExtension == "unknown" ? fileExtension : `.${fileExtension}`,
  };
};

const ICON_MAPPER = {
  IMAGE: <AttachmentPhotoSvg width={20} height={20} fill={colors.blue29} />,
  VIDEO: <VideoColored size="xx-small" />,
  WORKBOOK: <WorkbookOutlined size="xx-small" />,
  NOTE: <NoteIconSvg fill={colors.orange54} width={20} height={20} />,
};

const ICON_MIME_MAPPER = {
  LINK: <LinkColored size="xx-small" />,
  PDF: <PdfColored size="xx-small" />,
  DOC: <DocumentsColored size="xx-small" />,
  PPT: <SlidesColored size="xx-small" />,
  SPREADSHEET: <SheetsColored size="xx-small" />,
  UNKNOWN_FILE: <FilenotfoundColored size="xx-small" />,
  VIDEO: <VideoColored size="xx-small" />,
  AUDIO: <AudioColored size="xx-small" />,
  CSV: <TableColored size={"xx-small"} />,
  WORKBOOK: <WorkbookOutlined size="xx-small" />,
  ZIP: <ZipFileColored size="xx-small" />,
};

export const getThumbnailIcon = ({ type, mimeType }) => {
  const icon = ICON_MAPPER[type];
  if (icon) return icon;
  else if (type === "WORKBOOK") return ICON_MAPPER["WORKBOOK"];
  else if (type === "LINK" && mimeType == null) {
    return ICON_MIME_MAPPER.LINK;
  } else {
    switch (mimeType) {
      case "application/pdf":
        return ICON_MIME_MAPPER.PDF;
      case "text/csv":
        return ICON_MIME_MAPPER.CSV;
      case "application/zip":
        return ICON_MIME_MAPPER.ZIP;
      case "application/vnd.google-apps.document":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
      case "application/x-iwork-pages-sffpages":
      case "application/vnd.apple.pages":
        return ICON_MIME_MAPPER.DOC;
      case "audio/mpeg":
      case "audio/mp3":
      case "audio/mp4":
      case "audio/x-m4a":
      case "audio/vnd.wav":
      case "audio/aac":
        return ICON_MIME_MAPPER.AUDIO;
      case "video/webm;codecs=vp8,opus":
      case "video/webm":
      case "video/mp4":
      case "video/avi":
      case "video/wmv":
      case "video/quicktime":
        return ICON_MIME_MAPPER.VIDEO;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.google-apps.spreadsheet":
      case "application/vnd.ms-excel":
      case "application/x-iwork-numbers-sffnumbers":
      case "application/vnd.apple.numbers":
        return ICON_MIME_MAPPER.SPREADSHEET;
      case "application/vnd.google-apps.presentation":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/x-iwork-keynote-sffkey":
      case "application/vnd.apple.keynote":
        return ICON_MIME_MAPPER.PPT;
      default:
        return ICON_MIME_MAPPER.UNKNOWN_FILE;
    }
  }
};

export const getFileName = fileNameWithExtension => {
  return fileNameWithExtension
    ? fileNameWithExtension.replace(/\.[^/.]+$/, "")
    : "";
};

export const getImageDimensions = async ({ url, type }) => {
  let result = { height: 520, width: 520 };
  try {
    result = await new Promise((resolve, reject) => {
      if (type == "VIDEO") {
        //console.log(url, type);
        const video = document.createElement("video");
        if (video) {
          //Safer side
          video.setAttribute("src", url);
          //video.src = url;
          video.onloadedmetadata = event => {
            const { videoHeight, videoWidth } = _.get(event, "srcElement", {});

            resolve({ height: videoHeight, width: videoWidth });
          };

          //Remove unnecessary event
          // video.onloadeddata = event => {
          //   const { videoHeight, videoWidth } = _.get(event, "srcElement", {});
          //   resolve({ height: videoHeight, width: videoWidth });
          // };
          video.onerror = event => {
            reject();
          };
          // video.addEventListener("error", event => {
          //   console.log("error");
          //   resolve({});
          // });
        } else {
          reject();
        }
      } else if (type == "IMAGE") {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve({ height: img.height, width: img.width });
        img.onerror = () => {
          reject();
        };
      } else {
        reject();
      }
    });
  } catch (e) {}
  return result;
};

export const getFileExtension = fileName => {
  return fileName ? fileName.substring(fileName.lastIndexOf(".") + 1) : "";
};

export const downloadFileFromUrl = file => {
  const xhr = new XMLHttpRequest();

  xhr.open("GET", file.url, true);
  xhr.responseType = "blob";

  xhr.onload = function (e) {
    if (this.status == 200) {
      var blob = this.response;
      saveAs(blob, file.name);
    }
  };
  xhr.send();
};

export const formatBytes = ({ bytes, decimals = 2 }) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isImageCarousel = ({ attachments }) => {
  return (
    _.get(attachments, "length", 0) > 1 &&
    !_.find(attachments, item => _.get(item, "type", "") != "IMAGE")
  );
};

export const isAudioFile = ({ attachments }) => {
  return (
    _.get(attachments, "length", 0) == 1 &&
    _.get(attachments[0], "type") == "AUDIO"
  );
};

export const hasAudioNote = ({ attachments }) => {
  return (
    _.get(attachments, "length", 0) > 1 &&
    _.get(attachments[1], "type") == "AUDIO"
  );
};

export const getAttachmentTypes = ({ attachments }) => {
  let mediaType =
    POST_MEDIA_TYPES[attachments.length > 0 ? attachments[0].type : ""];
  if (isImageCarousel({ attachments })) {
    mediaType = POST_MEDIA_TYPES["MULTI_IMAGE"];
  }
  return mediaType;
};

export const isAllImages = ({ attachments }) => {
  return !_.find(attachments, item => _.get(item, "type", "") != "IMAGE");
};

export const getAttachmentSendObj = async (file, item) => {
  let mimeType = file.type;

  if (!mimeType) {
    mimeType = getMimeTypeFromFileName({ fileName: file.name });
  }

  const attachmentType = getFileType(mimeType).text;
  const url = item;

  const dimensions = await getImageDimensions({
    url,
    type: attachmentType,
  });

  const sendObj = {
    url: url,
    name: getFileName(file.name),
    mimeType,
    type: attachmentType,
    metadata: {
      size: _.get(file, "size", 0),
      fileExtension: getFileExtensionFromFilename({ fileName: file.name }),
      ...dimensions,
    },
  };

  return sendObj;
};

/**
 * This function returns file extension from file name
 * if filename doesn't contain extension then it will return undefined
 * Read more : https://stackoverflow.com/a/680982
 */
const getFileExtensionFromFilename = ({ fileName }) => {
  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(fileName)[1];
};

export const getFileExtensionFromMimeType = ({ mimeType }) => {
  if (mimeType == "image/jpg") return "jpg";
  if (mimeType == "audio/aac") return "aac";
  else return mime.extension(mimeType);
};

export const getMimeTypeFromFileName = ({ fileName }) => {
  const fileExtension = getFileExtension(fileName);

  return (
    mime.lookup(fileName) || MISC_FILE_EXTENSION_MIMETYPE_MAPPING[fileExtension]
  );
};

export const getDriveShareTemplateOptions = ({ file }) => {
  const baseUrl = `https://www.googleapis.com/drive/v3/files/${file.id}`;

  const { text: type } = getFileType(file.mimeType) || {};
  if (
    type == "IMAGE" ||
    type == "VIDEO" ||
    type == "AUDIO" ||
    file.mimeType == "application/pdf"
  ) {
    return [
      {
        ...(_.find(TEMPLATE_OPTIONS, item => item.value == "copy") || {}),
        exportAs: "FILE",
        url: `${baseUrl}?alt=media`,
        mimeType: file.mimeType,
      },
    ];
  } else {
    return _.map(TEMPLATE_OPTIONS, item => {
      return {
        ...(item || {}),
        url: file.url,
        exportAs: "LINK",
        mimeType: file.mimeType,
      };
    });
  }
};

export const getCompressedBlob = async ({ chunks, mimeType }) => {
  try {
    return {
      blob: new Blob(chunks, { type: mimeType }),
      mimeType,
    };

    // // //file name
    // const name = "video.webm";

    // console.log("filename", name);

    // const mediaData = new Uint8Array(await new Blob(chunks).arrayBuffer());
    // console.log("ffmpeg", mediaData);
    // if (!ffmpeg.isLoaded()) {
    //   await ffmpeg.load();
    // }
    // ffmpeg.FS("writeFile", name, await fetchFile(mediaData));
    // await ffmpeg.run("-i", name, "output.mp4");
    // const data = ffmpeg.FS("readFile", "output.mp4");
    // // console.log("file", blob);
    // return { blob: new Blob([data.buffer]), mimeType: "video/mp4" };
  } catch (e) {
    console.error("e", e);
    return {
      blob: new Blob(chunks, { type: mimeType }),
      mimeType,
    };
  }
};

export const getOrgResourcesAttachments = ({ resources }) => {
  return _.reduce(
    resources,
    (result, resource) => {
      const resourceToAdd = _.get(resource, "item", null)
        ? _.get(resource, "item.attachment", null)
        : _.get(resource, "attachment", null);

      if (resourceToAdd) {
        resourceToAdd.name = resource.label || resourceToAdd.name;
        result.push(resourceToAdd);
      }
      return result;
    },
    []
  );
};

export const getAttachmentCardTypeForAttachmentList = ({
  isClubbing,
  index,
  cardType,
  clubbingCards,
  showClubbedCard,
  showInlineAudioPlayer = false,
}) => {
  if (isClubbing && !showClubbedCard && index >= clubbingCards - 1) {
    /* case when the clubbing is enabled and 
      "see more" button is not clicked 
    */
    if (index === clubbingCards - 1) return "ClubbingCard";
    else return "null";
  } else {
    /* All the other cases
     ** when the "see more" button is clicked
     ** when clubbing is disabled
     ** when index < clubbingCards - 1
     */
    if (showInlineAudioPlayer) {
      return "horizontalV2";
    }
    return cardType;
  }
};

export const getAttachmentIdForUploadedAttachment = ({ attachment }) => {
  // returns correct uploaded id of the attachment
  if (
    attachment &&
    "attachmentId" in attachment &&
    !_.startsWith(_.get(attachment, "attachmentId"), "NEW")
  ) {
    return _.get(attachment, "attachmentId");
  } else {
    return _.get(attachment, "id");
  }
};

export const getAttachmentByTypeFromAttachmentList = ({
  attachmentList,
  type,
}) => {
  // returns attachment of the required type if present, otherwise null
  let filteredAttachments = _.filter(
    attachmentList,
    attachment =>
      _.get(_.first(_.get(attachment, "attachments")), "type", "") === type
  );
  if (_.isEmpty(filteredAttachments)) {
    return null;
  }

  return _.first(_.get(_.first(filteredAttachments), "attachments", {}));
};

export const getAttachmentsByTypeFromAttachmentArr = ({
  attachmentsArr,
  type,
  except = false,
}) => {
  let filteredAttachments = _.filter(attachmentsArr, attachment =>
    _.isEqual(_.get(attachment, "type", ""), type)
  );
  if (except) {
    filteredAttachments = _.filter(
      attachmentsArr,
      attachment => !_.isEqual(_.get(attachment, "type", ""), type)
    );
  }
  return filteredAttachments;
};

export const readableBytes = bytes => {
  if (typeof bytes !== "number") return "-";
  if (bytes == 0) return 0;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
};

export const getGoogleFileDetails = async ({ accessToken, fileId }) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/drive/v3/files/${fileId}?fields=thumbnailLink`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return response;
  } catch (e) {
    return { thumbnailLink: DEFAULT_THUMB_IMAGE };
  }
};

export const timeHelper = ({ timeInMinutes }) => {
  if (timeInMinutes < 1) {
    return {
      time: Math.round(timeInMinutes * 60),
      locale: "moment:seconds",
    };
  }
  return { time: Math.round(timeInMinutes), locale: "moment:minutes" };
};

export const sizeHelper = ({ size }) => {
  if (size < 100) {
    return size.toFixed(1) + "KB";
  }
  return (size / 1000).toFixed(1) + "MB";
};

export const getTimeLeftInMinutes = ({
  startTime,
  uploadedSize,
  totalSize,
}) => {
  const timeElapsed = new Date() - startTime;
  const uploadSpeed = uploadedSize / (timeElapsed / 1000);
  const timeLeft = uploadSpeed ? (totalSize - uploadedSize) / uploadSpeed : 0;

  return timeLeft / 60;
};
