import PropTypes from "prop-types";
import React from "react";

const EditInWorkbookSvg = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 33 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7157 6.15531C24.2782 5.71714 23.6466 5.78413 23.3616 6.11042C23.3481 6.12585 23.3341 6.14082 23.3196 6.15531L7.45164 22.0469L6.1731 25.8883L10.0061 24.6087L25.8739 8.71727C26.2862 8.30436 26.2862 7.72816 25.8739 7.31525L26.5225 6.66764L25.8739 7.31525L24.7157 6.15531ZM21.9938 4.87032C23.0637 3.67643 24.9231 3.75017 26.0221 4.85083L26.0221 4.85084L27.1803 6.01078C28.3123 7.14444 28.3123 8.88808 27.1803 10.0217L11.1585 26.0675C11.0571 26.1689 10.9336 26.2454 10.7975 26.2908L5.87518 27.934C5.85275 27.9415 5.83003 27.9482 5.80708 27.9539C5.34813 28.0688 4.83076 27.9712 4.45819 27.6354C4.26497 27.4613 4.10801 27.2171 4.05143 26.9167C3.99975 26.6424 4.04184 26.3752 4.14369 26.1397L5.76874 21.2573C5.81397 21.1214 5.89019 20.9979 5.99138 20.8966L21.9938 4.87032Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86118 19.9289C7.22192 19.5687 7.80638 19.5692 8.1666 19.9299L12.0273 23.7963C12.3875 24.1571 12.3871 24.7416 12.0263 25.1018C11.6656 25.462 11.0811 25.4616 10.7209 25.1008L6.86021 21.2344C6.5 20.8736 6.50043 20.2892 6.86118 19.9289Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3734 6.39641C20.7341 6.03619 21.3186 6.03662 21.6788 6.39737L25.5395 10.2638C25.8997 10.6246 25.8993 11.209 25.5385 11.5693C25.1778 11.9295 24.5933 11.929 24.2331 11.5683L20.3724 7.70185C20.0122 7.3411 20.0126 6.75663 20.3734 6.39641Z"
        fill={props.fill}
      />
    </svg>
  );
};

EditInWorkbookSvg.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

EditInWorkbookSvg.defaultProps = {
  height: 24,
  width: 24,
};

EditInWorkbookSvg.displayName = "EditInWorkbookSvg";

export default EditInWorkbookSvg;
