import PropTypes from "prop-types";
import React from "react";

const QuoteIcon = props => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      transform={`scale(${props.scale})`}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <path d="M26.6796887,3.12364425 C23.1491165,4.85901084 21.3838568,6.898036 21.3838568,9.24078094 C22.8886909,9.41431756 24.1330542,10.0289174 25.1169842,11.0845987 C26.1009142,12.1402801 26.5928719,13.362249 26.5928719,14.7505423 C26.5928719,16.2256039 26.1153836,17.4692646 25.1603927,18.4815618 C24.2054019,19.493859 23.0044465,20 21.5574906,20 C19.9369001,20 18.5333739,19.3420167 17.3468702,18.0260304 C16.1603664,16.7100441 15.5671234,15.1120846 15.5671234,13.2321041 C15.5671234,7.59216269 18.7214398,3.18150543 25.0301673,0 L26.6796887,3.12364425 Z M11.1125654,3.12364425 C7.553054,4.85901084 5.773325,6.898036 5.773325,9.24078094 C7.30709819,9.41431756 8.56593094,10.0289174 9.54986088,11.0845987 C10.5337909,12.1402801 11.0257485,13.362249 11.0257485,14.7505423 C11.0257485,16.2256039 10.5410256,17.4692646 9.57156513,18.4815618 C8.60210469,19.493859 7.39391469,20 5.94695883,20 C4.32636828,20 2.93007682,19.3420167 1.75804258,18.0260304 C0.586008331,16.7100441 0,15.1120846 0,13.2321041 C0,7.59216269 3.13984711,3.18150543 9.4196355,0 L11.1125654,3.12364425 Z" />
      </g>
    </svg>
  );
};
QuoteIcon.defaultProps = {
  scale: 1,
};
QuoteIcon.propTypes = {
  scale: PropTypes.number,
  fill: PropTypes.string,
};
export default QuoteIcon;
