import React from "react";
import classes from "./WarningMsg.scss";

const WarningMsg = ({
  msg,
  svg,
  svgContainerStyle,
  msgContainerStyle,
  containerStyle,
}) => {
  return (
    <div className={classes.container} style={containerStyle}>
      {svg ? (
        <div className={classes.svgContainer} style={svgContainerStyle}>
          {svg}
        </div>
      ) : null}
      <div className={classes.msgContainer} style={msgContainerStyle}>
        <div className={classes.textContent}>{msg}</div>
      </div>
    </div>
  );
};

WarningMsg.defaultProps = {
  svgContainerStyle: {},
  msgContainerStyle: {},
};

export default WarningMsg;
