import { push, replace } from "react-router-redux";
import request from "superagent";
import update from "immutability-helper";
import {
  goToAdminRoute,
  goToPlatformHomeRoute,
  goToBack,
} from "modules/NavigationModule";
import {
  getAdminMenuItemsMemoize,
  getAdminMenuUid,
  removeIBPrefixFromCurriculumType,
} from "Administrator/modules/Utils";
import { goToRelativeRoute } from "modules/Services";
import { getCourseUnitPlanCountOfSubjectQuery } from "modules/CommonQuery";
import client from "apolloClient";

import ACLStore from "lib/aclStore";

export const NAME = "administrator";

export const UPDATE_DYNAMIC_HEADER_TITLES =
  "UPDATE_DYNAMIC_HEADERS_TITLES" + " " + NAME;

export const SET_PARENT_ID = "SET_PARENT_ID" + " " + NAME;
export const SET_CHILD_ID = "SET_CHILD_ID" + " " + NAME;
export const SET_SUB_CHILD_ID = "SET_SUB_CHILD_ID" + " " + NAME;
export const INIT_ROUTES = "INIT_ROUTES" + " " + NAME;
// PURE ACTIONS

export const setParentId = data => {
  return { type: SET_PARENT_ID, payload: data };
};

export const setChildId = data => {
  return { type: SET_CHILD_ID, payload: data };
};

export const setSubChildId = data => {
  return { type: SET_SUB_CHILD_ID, payload: data };
};

export const initRoutes = () => {
  return { type: INIT_ROUTES };
};

export const updateDynamicHeaderTitles = data => {
  return { type: UPDATE_DYNAMIC_HEADER_TITLES, data };
};

export const onClickNavigate = link => {
  return (dispatch, getState) => {
    dispatch(push(link));
  };
};

export const updateParentId = parentId => {
  return (dispatch, getState) => {
    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id"
    );
    const uid = getAdminMenuUid({ curriculumProgramId, nodeId: parentId });
    dispatch(setParentId(uid));
  };
};

export const updateChildId = childId => {
  return (dispatch, getState) => {
    dispatch(setChildId(childId));
    const node = getAdminNode(childId);
    const perm = _.get(node, "perm", "");
    if (!node || !ACLStore.can(perm)) {
      dispatch(goToAdminRoute({ navigateType: "replace" }));
    }
  };
};

export const getAdminNodeByRouteName = route => {
  const adminMenuItems = getAdminMenuItemsMemoize();
  const nodes = _.get(adminMenuItems, "nodes", []);
  return _.find(nodes, item => item.route == route);
};

export const updateChildRoute = route => {
  return (dispatch, getState) => {
    const nodeItem = getAdminNodeByRouteName(route);
    if (nodeItem) {
      dispatch(updateChildId(nodeItem.id));
    }
  };
};

export const goToChildPath = ({ childId }) => {
  return (dispatch, getState) => {
    const { childId: oldChildId } = getState().administrator.routes;
    const oldChildNode = getAdminNode(oldChildId);
    const childNode = getAdminNode(childId);
    let replacePath = "";
    if (oldChildNode) {
      replacePath = oldChildNode.route;
    }
    if (childNode) {
      dispatch(
        goToRelativeRoute({
          route: `./${childNode.route}`,
          replacePath,
          type: "replace",
        })
      );
    }
  };
};

export const goToSubChildPath = ({ subChildId }) => {
  return (dispatch, getState) => {
    const { subChildId: oldSubChildId } = getState().administrator.routes;
    const oldChildNode = getAdminNode(oldSubChildId);
    const childNode = getAdminNode(subChildId);
    let replacePath = "";
    if (oldChildNode) {
      replacePath = _.get(oldChildNode, "route", "");
    }
    const newRoute = _.get(childNode, "route", "");
    if (childNode) {
      dispatch(
        goToRelativeRoute({
          route: `./${newRoute}`,
          replacePath,
          type: "replace",
        })
      );
    }
  };
};

export const goBackFromChild = ({ hasChild, isClickedFromHomeScreen }) => {
  return (dispatch, getState) => {
    const { parentId } = getState().administrator.routes;
    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id"
    );

    const uid = getAdminMenuUid({ curriculumProgramId, nodeId: parentId });
    const parentNode = getAdminNode(uid);

    if (parentNode) {
      const { route } = parentNode;
      // TODO: Find a better solution. If a folder is open then `hasChild` will be true
      if (hasChild) {
        dispatch(goToBack());
      } else {
        const replacePath = _.includes(route, "/")
          ? _.get(_.split(route, "/"), 0) // curriculum program id
          : route;

        // different path for teacher home page
        const updatedRoute = isClickedFromHomeScreen
          ? `${curriculumProgramId}/courses`
          : "";

        dispatch(
          goToRelativeRoute({
            replacePath,
            type: "replace",
            route: updatedRoute,
          })
        );
        dispatch(initRoutes());
      }
    }
  };
};

/* 
  `uid` is added to all admin nodes. If a node is a child of curriculum program then the uid has the format: CURRICULUM_ID:NODE_ID, 
  otherwise, nodeId is used as `uid`
  id cannot be modified as it's used in many places, hence `uid`.
*/
export const getAdminNode = uid => {
  const adminMenuItems = getAdminMenuItemsMemoize();
  const nodes = _.get(adminMenuItems, "nodes", []);

  return _.find(nodes, {
    uid,
  });
};

export const getCourseUnitPlanCountOfSubject = async id => {
  const data = await client.query({
    query: getCourseUnitPlanCountOfSubjectQuery,
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return {
    courseCount: _.get(data, "data.node.courseCount", 0),
    unitPlanCount: _.get(data, "data.node.unitPlanCount", 0),
  };
};

export const getAdminParentNode = ({ childId, getSubSection = false }) => {
  const adminMenuItems = getAdminMenuItemsMemoize();
  const nodes = _.get(adminMenuItems, "nodes", []);
  const parentNode = _.find(nodes, item => _.includes(item.children, childId));
  const { id, isSubsection } = parentNode;
  if (getSubSection || !isSubsection) return parentNode;
  return getAdminParentNodeMemoize({ childId: id, getSubSection });
};

export const getAdminParentNodeMemoize = _.memoize(
  params => getAdminParentNode(params),
  params => JSON.stringify(params)
);

export const getNextMenuRoute = parentId => {
  const parentMenu = getAdminNode(parentId);
  let route = "";
  if (parentMenu) {
    const children = _.get(parentMenu, "children", []);
    if (children.length > 0) {
      _.forEach(children, childId => {
        const childMenu = getAdminNode(childId);
        if (childMenu) {
          const hasChildren = !_.isEmpty(childMenu.children);
          if (hasChildren) {
            const tempRoute = getNextMenuRoute(childMenu.id);
            if (tempRoute) {
              route = tempRoute;
              return false;
            }
          } else {
            const showChildMenu = ACLStore.can(childMenu.perm);
            if (showChildMenu) {
              route = _.get(childMenu, "route", "");
              return false;
            }
          }
        }
      });
    }
  }
  return route;
};

export const prefixCurriculumName = ({ childId, curriculumProgramId }) => (
  dispatch,
  getState
) => {
  const organizationCurriculumPrograms = _.get(
    getState(),
    "platform.organizationCurriculumPrograms",
    []
  );

  const obj = _.find(organizationCurriculumPrograms, {
    id: curriculumProgramId,
  });

  if (!obj) return childId;

  return `${removeIBPrefixFromCurriculumType(obj.type)}_${childId}`;
};

export const updateChildRoutes = ({ routes, path, pathId }) => (
  dispatch,
  getState
) => {
  if (_.last(routes).path == path) {
    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id"
    );
    const uid = getAdminMenuUid({ nodeId: pathId, curriculumProgramId });
    const route = getNextMenuRoute(uid);
    dispatch(goToRelativeRoute({ route: route ? route : "../" }));
  }
};

// ------------------------------------
// Reducer Handlers
// ------------------------------------
const REDUCER_HANDLERS = {
  [SET_PARENT_ID]: (state, action) => {
    return update(state, { routes: { parentId: { $set: action.payload } } });
  },
  [SET_CHILD_ID]: (state, action) => {
    return update(state, { routes: { childId: { $set: action.payload } } });
  },
  [SET_SUB_CHILD_ID]: (state, action) => {
    return update(state, { routes: { subChildId: { $set: action.payload } } });
  },
  [INIT_ROUTES]: (state, action) => {
    return update(state, { routes: { $set: {} } });
  },
  [UPDATE_DYNAMIC_HEADER_TITLES]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        dynamicHeaderTitles: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isDrawerOpen: false,
  routes: {},
  permissionGroups: [
    "Dashboards",
    "AdminPortal",
    "TeacherPortal",
    "FeatureFlag",
  ],
  dynamicHeaderTitles: { course: "", student: "", teacher: "" },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
