import gql from "graphql-tag";
import { rubricFragment } from "Rubric/modules/RubricFragments";
import { checklistFragment } from "Checklist/modules/ChecklistFragments";
import { singlePointRubricFragment } from "SinglePointRubric/modules/SinglePointRubricFragments";
import {
  resourceConnectionFragment,
  plannerFieldFragment,
} from "UnitPlans/modules/UnitPlanFragments";
import {
  likeConnectionNodefragment,
  entitySavedByNodeFragment,
} from "Community/modules/CommunityFragments";

import {
  unitPlanFragment,
  staffUnitPlanFragment,
} from "IBPlanner/modules/IBFragments";
import {
  staffFragment,
  attachmentFragment,
  resolvedFieldValueFragment,
  curriculumProgramFragment,
} from "modules/CommonFragments";
import { assessmentToolFragment } from "modules/AssessmentTool/AssessmentToolFragment";
import { organizationResourceFragment } from "OrganizationResources/modules/OrganizationResourcesFragments";

export const assessmentFragment = {
  assessmentDetails: gql`
    fragment assessmentItem on Assessment {
      id
      curriculumProgram {
        ...curriculumProgramBasicDetailsItem
      }
      title {
        id
        value
      }
      assessmentType {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      templateId
      assessmentTool {
        id
        ... on MypObjectiveRubric {
          ...mypObjectiveRubricItem
        }
      }
      grades {
        id
        name
      }
      allFields {
        ...resourceLibraryFieldItem
      }
      organizationResources {
        id
      }
      presetAssessment {
        id
        unitPlan {
          id
          title {
            id
            value
          }
          image {
            id
            value
          }
          templateId
          allFields {
            ...unitPlanAllFields
          }
        }
      }
      unitPlan {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        templateId
        grades
        collaborators: allCollaborators {
          totalCount
          edges {
            permission
            node {
              ...staffCoursesItem
            }
          }
        }
        allFields {
          ...resourceLibraryFieldItem
        }
        fields(
          uids: [
            "title"
            "theme"
            "centralIdea"
            "image"
            "subjects"
            "relatedConcepts"
            "unitType"
            "lois"
            "lp"
            "atls"
            "goalList"
            "concepts"
            "benchmarks"
            "tags"
            "action"
          ]
        ) {
          ...resourceLibraryFieldItem
        }
      }
      comments {
        totalCount
        unreadCount
      }
      communityComments {
        totalCount
      }
      isDeleted
      resources {
        totalCount
      }
      isImported
      paritaStarStatus
      kristenStarStatus
      juryStarStatus
      paritaProcessed
      kristenProcessed
      juryProcessed
      ...assessmenLikeConnectionNodeItem
      ...assessmentSavedByNodeItem
      enabledSections
      submittedToCommunity
      originalCreatedBy {
        id
        firstName
        lastName
        profileImage
        ... on Staff {
          schoolTenures(currentSchool: true) {
            id
            school {
              id
              name
            }
          }
        }
      }
    }
    ${curriculumProgramFragment.curriculumProgramBasicDetails}
    ${staffFragment.basicStaffDetailsCommunity}
    ${plannerFieldFragment.resourceLibraryField}
    ${staffUnitPlanFragment.staffCourses}
    ${attachmentFragment.attachment}
    ${likeConnectionNodefragment.assessment}
    ${entitySavedByNodeFragment.assessment}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${assessmentToolFragment.mypObjectiveRubric}
    ${plannerFieldFragment.unitPlanAllFields}
  `,
  assessmentSchedulerCard: gql`
    fragment assessmentItem on Assessment {
      id
      templateId
      title {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
      assessmentType {
        id
        value
      }
      duration {
        id
        value
      }
      fields(uids: ["measureAssessing", "evaluationType", "assessmentType"]) {
        id
        uid
        value
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
  assessmentFeed: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      assessmentType {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
  assessmentTool: gql`
    fragment assessmentItem on Assessment {
      id
      assessmentTool {
        id
        ... on Rubric {
          ...rubricItem
        }
        ... on Checklist {
          ...checklist
        }
        ... on Anecdote {
          ...anecdoteItem
        }
        ... on Exemplar {
          ...exemplarItem
        }
        ... on SinglePointRubric {
          ...singlePointRubricItem
        }
      }
    }
    ${assessmentToolFragment.exemplar}
    ${assessmentToolFragment.anecdote}
    ${checklistFragment.checklist}
    ${rubricFragment.rubric}
    ${singlePointRubricFragment.singlePointRubric}
  `,
  assessmentEvaluationFeed: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      templateId
      assessmentType {
        id
        value
      }
      image {
        id
        value
      }
      unitPlan {
        id
        title {
          id
          value
        }
      }
      fields(uids: ["duration", "size", "measureAssessing", "evaluationType"]) {
        id
        uid
        value
      }
    }
  `,
  studentPortfolioAssessmentEvaluationFeed: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      templateId
      assessmentType {
        id
        value
      }
      assessmentTool {
        id
        ... on Rubric {
          ...rubricItem
        }
        ... on Checklist {
          ...checklist
        }
        ... on Anecdote {
          ...anecdoteItem
        }
        ... on Exemplar {
          ...exemplarItem
        }
        ... on SinglePointRubric {
          ...singlePointRubricItem
        }
      }
      fields(uids: ["duration", "size", "measureAssessing", "evaluationType"]) {
        id
        uid
        value
      }
    }
    ${rubricFragment.rubric}
    ${checklistFragment.checklist}
    ${singlePointRubricFragment.singlePointRubric}
    ${assessmentToolFragment.anecdote}
    ${assessmentToolFragment.exemplar}
  `,
  assessmentEvaluationDetails: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      assessmentType {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      templateId
      assessmentTool {
        id
        ... on Rubric {
          ...rubricItem
        }
        ... on Checklist {
          ...checklist
        }
        ... on Anecdote {
          ...anecdoteItem
        }
        ... on Exemplar {
          ...exemplarItem
        }
        ... on SinglePointRubric {
          ...singlePointRubricItem
        }
      }
      allFields {
        id
        uid
        value
        resolvedMinimalTree {
          ...resolvedMinimalTreeItem
        }
      }
      unitPlan {
        id
        title {
          id
          value
        }
        image {
          id
          value
        }
        unitType {
          id
          value
        }
        grades
        templateId
        fields(
          uids: ["lois", "atls", "subjects", "goalList", "lp", "benchmarks"]
        ) {
          id
          uid
          value
          resolvedMinimalTree {
            ...resolvedMinimalTreeItem
          }
        }
      }
    }

    ${rubricFragment.rubric}
    ${checklistFragment.checklist}
    ${singlePointRubricFragment.singlePointRubric}
    ${assessmentToolFragment.anecdote}
    ${assessmentToolFragment.exemplar}
    ${staffFragment.basicStaffDetails}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
  assessmentEvaluationModalFeed: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      createdBy {
        id
        firstName
        lastName
      }
      templateId

      assessmentType {
        id
        value
      }
      evaluations(courseId: $courseId) {
        totalCount
      }
      fields(uids: ["duration", "size", "measureAssessing"]) {
        id
        uid
        value
      }
    }
  `,
  assessmentSelectModalFeed: gql`
    fragment assessmentItem on Assessment {
      id
      title {
        id
        value
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      createdBy {
        id
        firstName
        lastName
        profileImage
      }
      assessmentType {
        id
        value
      }

      fields(uids: ["duration", "size", "measureAssessing"]) {
        id
        uid
        value
        resolvedMinimalTree {
          id
          ... on ResolvedFieldUndefinedEntity {
            undefinedEntity
          }
        }
      }
      grades {
        id
        name
      }
    }
  `,
};
