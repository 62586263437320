import React from "react";
import classNames from "classnames";

import classes from "./FeedListItem.scss";

import { UserNameAvatar } from "AppComponents";
import {
  ButtonDropdown,
  MoreItemWithToolTip,
  LinkWithTooltip,
  I18nHOC,
} from "UIComponents";
import { Dropdown, DropdownMenu, IconButton } from "@toddle-design/web";
import { DotsHorizontalOutlined } from "@toddle-design/web-icons";

const buttonDropDownContainerStyle = {
  marginTop: 4,
  width: "max-content",
  right: 0,
  marginBottom: 16,
};

const buttonDropDownItemStyle = {
  height: 40,
  fontSize: "1.4rem",
};

const tooltipContainerStyle = {
  maxWidth: "250px",
};

class FeedListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
    };
  }

  getHoverText(items) {
    let itemArray = [];
    items.map(item => {
      itemArray = [...itemArray, item.title];
    });

    return itemArray;
  }

  onUpdateShowDropDown = value => {
    this.setState({ showDropdown: value });
  };

  getColumnData = ({ column, columnData, id }) => {
    const {
      getCustomComponent,
      useNewActionsDropdown,
      t,
      tooltipHrefValue,
      onActionMenuItemClick,
      useCustomFuncForDefault = false,
    } = this.props;
    const actionMenuList = _.get(columnData, "actionMenuList", []);
    const item = _.get(columnData, "item");

    switch (column.type) {
      case "NAMEWITHIMAGE":
        return !_.isEmpty(columnData?.name) ? (
          <UserNameAvatar
            id={columnData.id}
            profileImage={columnData.profileImage}
            name={columnData.name}
            email={columnData.email}
            shouldDynamicColor={columnData.shouldDynamicColor}
            tooltipHrefValue={tooltipHrefValue}
          />
        ) : (
          <div className={classes.dashStyle}>-</div>
        );
      case "TEXTCOLUMN":
        return (
          <LinkWithTooltip
            tooltip={columnData}
            href={tooltipHrefValue}
            placement="top"
            tooltipContainerStyle={tooltipContainerStyle}
            isVisible={!column.hideTooltip}
          >
            <div className={classes.textCell} style={column?.columnDataStyle}>
              {columnData}
            </div>
          </LinkWithTooltip>
        );
      case "TEXTCOLUMNWITHSUBTEXT": {
        const customWithSubText = classNames("text-label-4", classes.subText);
        return (
          <LinkWithTooltip
            tooltip={columnData.label}
            href={tooltipHrefValue}
            placement="top"
            tooltipContainerStyle={tooltipContainerStyle}
            isVisible={!column.hideTooltip}
          >
            <div className={classes.textCell}>{columnData.label}</div>
            {columnData.subText && (
              <span className={customWithSubText}>{columnData.subText}</span>
            )}
          </LinkWithTooltip>
        );
      }
      case "INTCOLUMN":
        return <div className={classes.intCell}>{columnData}</div>;
      case "ARRAYCOLUMNWITHOVER":
        return (
          <MoreItemWithToolTip
            items={this.getHoverText(columnData ? columnData : [])}
            length={1}
            noItemText={t("common:no_with_label", { label: t(column.locale) })}
          />
        );
      case "ACTIONS": {
        return (
          !_.isEmpty(columnData) &&
          (useNewActionsDropdown ? (
            <Dropdown
              overlay={
                <DropdownMenu
                  options={actionMenuList}
                  onClick={({ key }) => onActionMenuItemClick({ key, item })}
                />
              }
            >
              <IconButton
                size={"small"}
                icon={<DotsHorizontalOutlined />}
                variant={"plain"}
              />
            </Dropdown>
          ) : (
            <ButtonDropdown
              onUpdateShowDropDown={this.onUpdateShowDropDown}
              authTabs={columnData}
              containerStyle={buttonDropDownContainerStyle}
              itemStyle={buttonDropDownItemStyle}
              disabled={_.get(column, "disabled", false)}
            />
          ))
        );
      }
      case "CUSTOM":
        if (getCustomComponent) {
          return getCustomComponent({
            column,
            columnData,
            id,
            t,
          });
        }
        break;
      default:
        if (useCustomFuncForDefault && getCustomComponent) {
          return getCustomComponent({
            column,
            columnData,
            id,
            t,
          });
        } else {
          return null;
        }
    }
    return null;
  };

  render() {
    const {
      listItem,
      columnList,
      rowWrapperClass,
      parentStyle,
      isItemClickEnable,
    } = this.props;

    let isItemClickable = false;
    if (listItem.isIndividualItemClickEnable != undefined) {
      isItemClickable = listItem.isIndividualItemClickEnable;
    } else {
      isItemClickable = isItemClickEnable;
    }

    const rowClasses = classNames(
      rowWrapperClass,
      classes.row,
      listItem.rowClass
    );
    return (
      <div
        className={rowClasses}
        style={{
          ...parentStyle,
          cursor: isItemClickable ? "pointer" : "auto",
        }}
        onClick={e => {
          if (!e.defaultPrevented && isItemClickable) {
            this.props.onItemClick(e);
          }
        }}
      >
        {_.map(columnList, column => {
          const cellStyle = classNames(
            { [classes.cell]: true },
            { [classes.actionCell]: column.type == "ACTIONS" ? true : false },
            { [classes.hideCell]: column.showOnHover }
          );
          return (
            <div className={cellStyle} key={column.id} style={column.style}>
              {this.getColumnData({
                column,
                columnData:
                  typeof listItem[column.id] == "function"
                    ? listItem[column.id](
                        _.get(listItem[column.id], "extraParams", {})
                      )
                    : listItem[column.id],
                id: listItem.id,
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(FeedListItem);
