import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, UIModal, I18nHOC } from "UIComponents";

import { getBenchmarkSetQuery } from "modules/CommonQuery";
import { getOrganizationPypElementSetFromCache } from "modules/CommonGraphqlHelpers";
import { getPYPElementSetFromNodes } from "Utils";
import { createSelector } from "reselect";
import SubjectBenchmarks from "./SubjectBenchmarks";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { EmptyField } from "UIComponents";
import BenchmarksModal from "./BenchmarksModal";
import { colors } from "Constants";
import { AlertIcon } from "SvgComponents";
import classes from "./BenchmarksComponent.scss";

const emptyFieldStyle = {
  containerStyle: {
    border: 0,
  },
};

const EditComp = React.memo(props => {
  return props.renderComponent();
});

const EditCompWithLoader = withLoader(EditComp);

class BenchmarksComponent extends UIBaseComponent {
  constructor(props) {
    super(props);
    if (props.customRef) {
      props.customRef(this);
    }
    this.state = {
      ...this.state,
      openBenchmarksModal: false,
      selectedSubject: {},
      modalState: "",
    };
  }

  blur = ({ field }) => {
    const { uid, id } = field;
    const { selectedSubject } = this.state;
    if (selectedSubject.id == id) {
      this.onBenchmarkModalCancelClicked();
    }
  };

  onAddNewClicked = subject => {
    this.setState({
      openBenchmarksModal: !this.state.openBenchmarksModal,
      modalState: "add",
      selectedSubject: subject,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { onBlurInputField, onFocusInputField } = this.props;
    const { selectedSubject } = this.state;
    const { selectedSubject: prevSelectedSubject } = prevState;
    if (prevState.openBenchmarksModal != this.state.openBenchmarksModal) {
      if (this.state.openBenchmarksModal) {
        // onFocusInputField({ others: { id: selectedSubject.id } });
      } else {
        // onBlurInputField({ others: { id: prevSelectedSubject.id } });
      }
    }
  };

  onEditClicked = subject => {
    this.setState({
      openBenchmarksModal: !this.state.openBenchmarksModal,
      modalState: "edit",
      selectedSubject: subject,
    });
  };

  onDeleteClicked = ({ tupple, subject }) => {
    const {
      props,
      props: {
        value: { ...value },
        name,
      },
    } = this;
    // const index = _.findIndex(value[subject.value], tupple);
    value[subject.value] = _.reduce(
      value[subject.value],
      (result, item) => {
        if (!_.isEqual(item, tupple)) result.push(item);
        return result;
      },
      []
    );

    props.updatedValue(value);
  };

  isValidValue = value => {
    const benchmarks = _.get(value, "benchmarks", {});
    let isValid = Object.keys(benchmarks).length > 0;
    _.forEach(Object.keys(benchmarks), key => {
      isValid = benchmarks[key] && benchmarks[key].length > 0;
    });
    return isValid;
  };

  onSubmitBenchmarkClicked = result => {
    const { selectedSubject } = this.state;
    const value = this.props.value ? _.cloneDeep(this.props.value) : [];

    const updatedValue = _.uniq([
      ..._.filter(
        value,
        id =>
          !_.includes(
            _.map(result.nodes, item => item.id),
            id
          )
      ),
      ...(result.selections || []),
    ]);
    const { valuesToAdd, valuesToRemove, shouldCloseModal } = result;
    if (shouldCloseModal) {
      this.setState({
        openBenchmarksModal: !this.state.openBenchmarksModal,
        selectedSubject: {},
      });
    }
    setTimeout(() => {
      this.updateValue(updatedValue, {
        nodes: result.nodes,
        rootNodeId: selectedSubject.subjectId,
        rootNodeKey: "subject",
        valuesToAdd,
        valuesToRemove,
      });
    });
  };

  onBenchmarkModalCancelClicked = () => {
    this.setState({
      openBenchmarksModal: !this.state.openBenchmarksModal,
      selectedSubject: {},
    });
  };

  getEmptyComponent = ({ subject, hasData }) => {
    const { t } = this.props;

    return (
      <EmptyField
        title={
          hasData
            ? `${t("unitPlan:select_outcomes_with_label", {
                label: _.lowerCase(subject.label),
              })}`
            : `${t("unitPlan:no_scope_sequence_defined_with_label", {
                label: _.lowerCase(subject.label),
              })}`
        }
        onAddNewClick={() => this.onAddNewClicked(subject)}
        showAdd={true}
        containerStyle={emptyFieldStyle.containerStyle}
        titleStyles={emptyFieldStyle.titleStyles}
        isDisabled={!hasData}
        renderIcon={!hasData && <AlertIcon fill={colors.gray72} />}
      />
    );
  };

  renderSubjects = subjects => {
    const {
      props: { value, nodes, rootNodes, isLoading, fieldLockedObject },
    } = this;
    const { lockedDynamicFields } = fieldLockedObject;

    return (
      <div className={classes.subjectBenchMarkContainer}>
        {_.map(subjects, (item, index) => {
          const subject = item.benchmarkRootNode || {};

          const subjectLevelId = _.get(subject, "levelId", "");
          const levels = _.reduce(
            _.get(subject, "levels", []),
            (result, level) => {
              if (level.id !== subjectLevelId) {
                result.push(level);
              }
              return result;
            },
            []
          );

          const subjectNodes = _.filter(
            nodes,
            nodeItem => nodeItem.subject == item.value
          );

          const children = _.map(
            _.filter(subjectNodes, item => item.depth == 1),
            node => node.id
          );
          const subjectValue = _.map(
            subjectNodes,
            subjectItem => subjectItem.id
          );

          const lockedField = _.find(lockedDynamicFields, { id: subject.id });
          const isEmpty = _.get(subjectValue, `length`, 0) == 0;
          const hasData = !_.isEmpty(subject);

          return (
            <SubjectBenchmarks
              key={item.value}
              value={subjectValue}
              subject={{
                subjectId: item.value,
                label: item.label,
                ...subject,
                benchmarkRootNode: item.benchmarkRootNode,
              }}
              pypType={"benchmarks"}
              levels={levels}
              isEmpty={isEmpty}
              hasData={hasData}
              item={item}
              getEmptyComponent={this.getEmptyComponent}
              nodes={nodes}
              lockedField={lockedField}
              rootNodes={children}
              isLoading={isLoading}
              onAddClicked={this.onEditClicked}
              onDeleteClicked={this.onDeleteClicked}
            />
          );
        })}
      </div>
    );
  };

  renderModal = () => {
    const {
      state: { selectedSubject, openBenchmarksModal, modalState },
      props: {
        subText,
        helperText,
        name,
        value,
        showHelper,
        showComment,
        onHelpClick,
        onCommentClick,
        totalCommentCount,
        unreadCommentCount,
        fieldId,
        nodes,
        grades,
      },
    } = this;

    const subject = selectedSubject.benchmarkRootNode;
    const subjectLevelId = _.get(subject, "levelId", "");
    const levels = _.reduce(
      _.get(subject, "levels", []),
      (result, level) => {
        if (level.id !== subjectLevelId) {
          result.push(level);
        }
        return result;
      },
      []
    );

    const selectedNodes = _.filter(
      nodes,
      node => node.subject == selectedSubject.subjectId
    );

    const subjectValue = _.filter(value, valueId =>
      _.includes(
        _.map(selectedNodes, selectedNode => selectedNode.id),
        valueId
      )
    );

    return (
      openBenchmarksModal && (
        <UIModal
          isOpen={true}
          overlayContainer={classes.overlayContainer}
          isFullScreen={true}
          // onRequestClose={this.onBenchmarkModalCancelClicked}
        >
          <BenchmarksModal
            subText={subText}
            helperText={helperText}
            subject={selectedSubject}
            subjectId={selectedSubject.subjectId}
            id={selectedSubject.id}
            levels={levels}
            modalState={modalState}
            updateValueLocally={this.updateValueLocally}
            rootNode={subject}
            name={name}
            selectedNodes={selectedNodes}
            fieldId={fieldId}
            totalCommentCount={totalCommentCount}
            unreadCommentCount={unreadCommentCount}
            value={subjectValue}
            showHelper={showHelper}
            showComment={showComment}
            onHelpClick={onHelpClick}
            onCommentClick={onCommentClick}
            onCancelClicked={this.onBenchmarkModalCancelClicked}
            onSubmitClicked={this.onSubmitBenchmarkClicked}
            grades={grades}
          />
        </UIModal>
      )
    );
  };

  shouldShowEditEmpty = () => {
    const {
      props: { options: { subjects = [] } = {} },
    } = this;
    return _.get(subjects, "length", 0) == 0;
  };

  renderEditEmpty = () => {
    const { t } = this.props;
    return <EmptyField title={t("add_subjects_to_scope_sequence")} />;
  };

  renderComponent = () => {
    const {
      props: {
        options: { subjects = [] },
      },
    } = this;

    return (
      <div className={classes.container}>
        {this.renderSubjects(subjects)}
        {this.renderModal()}
      </div>
    );
  };
  renderEdit = () => {
    return (
      <EditCompWithLoader
        renderComponent={this.renderComponent}
        {...this.props}
        {...this.state}
      />
    );
  };
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const benchmarkSet = getPYPElementSetFromNodes({
    nodes: _.get(ownProps.resolvedValue, "benchmarks", []),
  });

  return {
    grades: _.get(ownProps.options, "grades", ""),
    organizationId: state.login.userInfo.org_id,

    nodes: _.get(benchmarkSet, "nodes", []),
    rootNodes: _.get(benchmarkSet, "rootNodes", []),
    isLoading: false,
    isData: true,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlan", "common"] })
)(BenchmarksComponent);
