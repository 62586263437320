import { CLOUD_URL } from "Constants";
import { colors } from "Constants";
import ACLStore from "lib/aclStore";
export const handbookUrl = `${CLOUD_URL}/assets/website/planathon/Toddle_Planathon_Handbook.pdf`;
export const HeroData = {
  title: "common:planathon_label",
  description: "planathon:description_msg",
  subTitle: "planathon:header_subTitle",
  duration: "planathon:planathon_duration",
};

export const RegisteredHeroData = {
  title: "common:planathon_label",
  description: `planathon:header_title`,
  descriptionEmoji: `${CLOUD_URL}/assets/website/planathon/subTextEmoji.png`,
  subTitle: "planathon:spread_word",
  duration: "planathon:planathon_duration",
  counterEndTime: "12-01-2020 11:59:PM",
  backgroundImages: [
    // {
    //   image: `${CLOUD_URL}/assets/website/planathon/h1.svg`,
    //   style: { position: "absolute", top: "32%", left: "67%" },
    //   name: "h1"
    // },
    {
      image: `${CLOUD_URL}/assets/website/planathon/h2.svg`,
      style: { position: "absolute", left: 0, bottom: "12%" },
      name: "h2",
    },
    // {
    //   image: `${CLOUD_URL}/assets/website/planathon/h3.svg`,
    //   style: { position: "absolute", right: 48, bottom: 48 },
    //   name: "h3"
    // }
  ],
};

export const CTAButtonText = "planathon:cta_button_text";
export const PlanathonDetails = {
  title: "planathon:why_planathon",
  image: `${CLOUD_URL}/assets/website/planathon/why_Planathon.png`,
  mobileImage: `${CLOUD_URL}/assets/website/planathon/why_Planathon_mobile.png`,
  plusIcon: `${CLOUD_URL}/assets/website/planathon/icn_plus.svg`,
  equalIcon: `${CLOUD_URL}/assets/website/planathon/icn_equal.svg`,
  description: "planathon:details_description",
  steps: [
    {
      title: "planathon:details_step1_title",
      image: `${CLOUD_URL}/assets/website/planathon/G1.png`,
    },
    {
      title: "planathon:details_step2_title",
      image: `${CLOUD_URL}/assets/website/planathon/G2.png`,
    },
    {
      title: "planathon:details_step3_title",
      image: `${CLOUD_URL}/assets/website/planathon/G3.png`,
    },
  ],
};

export const ParticipantDetails = {
  title: "planathon:why_participate",
  description: "planathon:participant_details_description",

  steps: [
    {
      title: "planathon:participant_details_step1_title",
      image: `${CLOUD_URL}/assets/website/planathon/why_participate_1.svg`,
    },
    {
      title: "planathon:participant_details_step2_title",
      image: `${CLOUD_URL}/assets/website/planathon/why_participate_2.svg`,
    },
    {
      title: "planathon:participant_details_step3_title",
      image: `${CLOUD_URL}/assets/website/planathon/why_participate_3.svg`,
    },
    {
      title: "planathon:participant_details_step4_title",
      image: `${CLOUD_URL}/assets/website/planathon/why_participate_4.svg`,
    },
  ],
};

export const ParticipantNextStepDetails = {
  title: "planathon:what_next",
  description: "planathon:participation_description",
  othersText: "planathon:participation_other_texts",
  freemiumSteps: [
    {
      type: "SAMPLE_UNIT",
      title: "planathon:participation_step1_title",
      subTitle: "planathon:participation_step1_subTitle",
      buttonName: "planathon:participation_step1_button_name",
      image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_1.svg`,
      color: colors.salmon60,
    },
    // {
    //   type: "INVITE",
    //   title: "Get your gang together",
    //   subTitle:
    //     "Invite your school team to join you on Toddle and start planning your units collaboratively.",
    //   buttonName: "Invite your team",
    //   image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_2.svg`,
    //   color: colors.blue29
    // },
    {
      type: "CREATE_UNIT",
      title: "planathon:participation_step2_title",
      subTitle: "planathon:participation_step2_subTitle",
      buttonName: "planathon:participation_step2_button_name",
      image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_4.svg`,
      color: colors.blue29,
    },
    {
      type: "SUBMIT_UNIT",
      title: "planathon:participation_step3_title",
      subTitle: "planathon:participation_step3_subTitle",
      buttonName: "planathon:participation_step3_button_name",
      image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_3.svg`,
      color: colors.violet63,
    },
  ],
  premiumSteps: [
    {
      type: "SAMPLE_UNIT",
      title: "planathon:participation_step1_title",
      subTitle: "planathon:participation_step1_subTitle",
      buttonName: "planathon:participation_step1_button_name",
      image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_1.svg`,
      color: colors.salmon60,
    },
    {
      type: "CREATE_UNIT",
      title: "planathon:participation_step2_title",
      subTitle: "planathon:participation_step2_subTitle",
      buttonName: "planathon:participation_step2_button_name",
      image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_4.svg`,
      color: colors.blue29,
    },
    {
      type: "SUBMIT_UNIT",
      title: "planathon:participation_step3_title",
      subTitle: "planathon:participation_step3_subTitle",
      buttonName: "planathon:participation_step3_button_name",
      image: `${CLOUD_URL}/assets/website/planathon/icn_what_next_3.svg`,
      color: colors.violet63,
      // buttonDisabled: !ACLStore.can("TeacherPortal:PlanathonSubmission")
    },
  ],
};

export const ReviewPanelDetails = {
  title: "planathon:our_review_panel",
  backgroundImages: [
    {
      image: `${CLOUD_URL}/assets/website/planathon/r1.svg`,
      style: { position: "absolute", top: 24, left: 64 },
      name: "r1",
    },
    {
      image: `${CLOUD_URL}/assets/website/planathon/r2.svg`,
      style: { position: "absolute", top: 100, right: "5%" },
      name: "r2",
    },
    {
      image: `${CLOUD_URL}/assets/website/planathon/r3.svg`,
      style: { position: "absolute", right: 48, bottom: 48 },
      name: "r3",
    },
  ],
  juries: [
    {
      name: "Anne-Marie Evans",
      content: "planathon:jury_evans_content",
      designation: "planathon:jury_evans_designation",
      image: `${CLOUD_URL}/assets/website/planathon/anne.png`,
    },
    {
      name: "Carla Marschall",
      content: "planathon:jury_carla_content",
      designation: "planathon:jury_carla_designation",
      image: `${CLOUD_URL}/assets/website/planathon/marschall.png`,
    },
    {
      name: "Christianne Cowie",
      image: `${CLOUD_URL}/assets/website/planathon/christianne.png`,
      designation: "planathon:jury_christianne_designation",
      content: "planathon:jury_christianne_content",
    },
    {
      name: "Cindy Kaardal",
      image: `${CLOUD_URL}/assets/website/planathon/kaardal.png`,
      designation: "planathon:jury_cindy_designation",
      content: "planathon:jury_cindy_content",
    },
    {
      name: "Derek Pinchbeck",
      image: `${CLOUD_URL}/assets/website/planathon/derek.png`,
      designation: "planathon:jury_derek_designation",
      content: "planathon:jury_derek_content",
    },
    {
      name: "Mubeen Safura",
      image: `${CLOUD_URL}/assets/website/planathon/safura.png`,
      designation: "planathon:jury_mubeen_designation",
      content: "planathon:jury_mubeen_content",
    },
    {
      name: "Rachel French",
      image: `${CLOUD_URL}/assets/website/planathon/rachel.png`,
      designation: "planathon:jury_rachel_designation",
      content: "planathon:jury_rachel_content",
    },
    {
      name: "Ryan Higbea",
      image: `${CLOUD_URL}/assets/website/planathon/ryan.png`,
      designation: "planathon:jury_ryan_designation",
      content: "planathon:jury_ryan_content",
    },
    {
      name: "Seetha Murty",
      image: `${CLOUD_URL}/assets/website/planathon/seetha.png`,
      designation: "planathon:jury_seetha_designation",
      content: "planathon:jury_seetha_content",
    },
    {
      name: "Yuni Santosa",
      image: `${CLOUD_URL}/assets/website/planathon/yuni.png`,
      designation: "planathon:jury_yuni_designation",
      content: "planathon:jury_yuni_content",
    },
  ],
  othersText: "",
};

export const CategoryData = {
  image: `${CLOUD_URL}/assets/website/planathon/category_bg.svg`,
  heading: "planathon:total_category",
  gradeDetails: {
    count: 9,
    label: "common:grade_plural",
    color: colors.violet63,
    subTitle: "planathon:grade_sub_title1",
  },

  themeDetails: {
    count: 6,
    label: "planathon:transdisciplinary_themes",
    color: colors.blue29,
    subTitle: "planathon:grade_sub_title2",
  },
  themes: [
    {
      title: "planathon:category1",
      image: `${CLOUD_URL}/assets/website/planathon/Who_we_are.png`,
    },
    {
      title: "planathon:category2",
      image: `${CLOUD_URL}/assets/website/planathon/Where_we_are_in_place_and_time.png`,
    },
    {
      title: "planathon:category3",
      image: `${CLOUD_URL}/assets/website/planathon/How_we_express_ourselves.png`,
    },
    {
      title: "planathon:category4",
      image: `${CLOUD_URL}/assets/website/planathon/How_the_world_works.png`,
    },
    {
      title: "planathon:category5",
      image: `${CLOUD_URL}/assets/website/planathon/How_we_organize_ourselves.png`,
    },
    {
      title: "planathon:category6",
      image: `${CLOUD_URL}/assets/website/planathon/Sharing_the_planet.png`,
    },
  ],
  subHeading: "planathon:category_sub_heading",
};

export const ParticipantSteps = {
  title: "planathon:how_to_participate",
  image: `${CLOUD_URL}/assets/website/planathon/how_participate.png`,
  steps: [
    // {
    //   title: "planathon:participant_step1",
    //   color: "#ffb400"
    // },
    { title: "planathon:participant_step2", color: "#189eae" },
    {
      title: "planathon:participant_step3",
      color: "#6464dc",
    },
    {
      title: "planathon:participant_step4",
      color: "#f75961",
    },
  ],

  othersText: "planathon:download_handbook_description",
};

export const ImportantDates = {
  title: "planathon:planathon_dates",
  backgroundImages: [
    {
      image: `${CLOUD_URL}/assets/website/planathon/d1.svg`,
      style: { position: "absolute", top: -64, left: -64 },
      name: "d1",
    },
    {
      image: `${CLOUD_URL}/assets/website/planathon/d2.svg`,
      style: { position: "absolute", bottom: 124, left: 124 },
      name: "d2",
    },
    {
      image: `${CLOUD_URL}/assets/website/planathon/d3.svg`,
      style: { position: "absolute", right: 98, top: 98 },
      name: "d3",
    },
    {
      image: `${CLOUD_URL}/assets/website/planathon/d4.svg`,
      style: { position: "absolute", right: 70, bottom: -30 },
      name: "d4",
    },
    {
      image: `${CLOUD_URL}/assets/website/planathon/d5.svg`,
      style: { position: "absolute", right: 20, bottom: "30%" },
      name: "d5",
    },
  ],
  steps: [
    {
      title: "planathon:important_dates_step1",
      date: "24-10-2019",
      color: "#ffb400",
    },
    {
      date: "13-01-2020",
      color: "#189eae",
      title: "planathon:important_dates_step2",
    },
    {
      date: "21-02-2020",
      color: "#6464dc",
      title: "planathon:important_dates_step3",
    },
    {
      date: "15-03-2020",
      color: "#f75961",
      title: "planathon:important_dates_step4",
    },
  ],
  disclaimerText: "planathon:tc_disclaimer",
};
