import axios from "axios";
import update from "immutability-helper";
import {
  CLOUD_URL,
  DEFAULT_LINK_THUMB_URL,
  ATTACHMENT_OMIT_ATTRIBUTES,
  ATTACHMENT_CREATE_ATTRIBUTES,
} from "store/static";
import { push, goBack, replace } from "react-router-redux";
import moment from "moment";
import client from "apolloClient";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import i18next from "i18next";
import {
  getRelativePath,
  generateNestedSelectedData,
  convertDurationToString,
  buildSlimTree,
  extractContentFromHTML,
  OmitFromArrayValues,
  ATTACHMENT_WARNINGS,
  getUniqAssociatedParentsOfNodes,
  mergeAssociatedParentsWithNodes,
  getStyleStrippedText,
  formatBytes,
  getFileExtensionFromMimeType,
  getCountString,
  generateRandomId,
  getTaggedUserIds,
  getYearDurationFormat,
  getPYPElementSetFromNodes,
  getBackendServerUrl,
} from "Utils";
import { getOrganizationSetting } from "Tooling/modules/ToolingQueries";

import {
  MimeDb,
  LE_ASSESSMENT_TYPE_MAPPING,
  NOTIFICATION_CATEGORY,
  DUMMY_USER_INFO,
  USER_TYPE_ENTITY_MAPPING,
} from "Constants";
import {
  createMessageMutation,
  updateMessageMutation,
  deleteMessageMutation,
  signS3URLMutation,
  updateNotificationIsNew,
  updateNotificationIsRead,
  updateNotificationIsReadForUser,
  scrapeUrlMutation,
  markCommentsReadForFieldMutation,
  createAttachmentMutation,
  deleteAttachmentMutation,
  updateBasicUserDetailsMutation,
  createPlanUpgradeRequestMutation,
  bulkUploadByFileMutation,
  generateZoomRefreshTokenMutation,
  createZoomMeetingMutation,
  deleteZoomMeetingMutation,
  createGenericFolderMutation,
  updateGenericFolderMutation,
  deleteGenericFolderMutation,
  createAttachmentGroupsMutation,
  createAttachmentGroupsMutationV2,
  deleteAttachmentGroupsMutation,
  addApiEventMutation,
  generateThirdPartyRefreshTokenMutation,
  setConfigurationValueMutation,
  sendSigninLinkMutation,
  setNotificationConfigurationForCourseMutation,
  createOrganizationGlobalConstantsMutation,
  checkForSimilarityMutation,
  cancelCheckSimilarityMutation,
  acceptTurnitinEulaMutation,
  createBasicMessageMutation,
  updateUserSettingsMutation,
  updateOrganizationSettingsMutation,
  updateEntityIsReadMutation,
  convertPdfToWorkbookMutation,
} from "modules/CommonMutations";
import {
  getTotalCommentOfNodeQuery,
  getUserZoomAuthorizedStatusQuery,
  getUserThirdPartyAuthorizedStatusQuery,
  getAttachmentQuery,
  getOrganizationGlobalConstantsQuery,
  getTurnitinReportViewerLaunchUrlQuery,
  getIsTurnitinEulaRequiredQuery,
  getLatestTurnitinEulaQuery,
  getBasicCommentOfNodeQuery,
  getConfigurationsQuery,
} from "modules/CommonQuery";
import { getCourseGenericFoldersQuery } from "Course/modules/CourseQuery";
import {
  getCommentsOfNodeFromCache,
  writeCommentNodeFragment,
  writeMessageFragment,
  getNotificationFromCache,
  writeNotificationFragment,
  getNotificationListFromCache,
  writeNotificationListInCache,
  getAttachmentOfNodeFromCache,
  writeAttachmentNodeFragment,
  getSubjectBasicDetailsFromCache,
  getPypElementFromCache,
  getFocusAreaFromCache,
  getCriteriaSetDetailsFromCache,
  getOrganizationConstantsFromCache,
  getOrganizationDetailsFromCache,
  getUserBasicDetailsFromCache,
  writeUserBasicDetailsFromCache,
  getPlatformUserDetailsFromCache,
  getZoomMeetingNodeFromCache,
  writeZoomMeetingNodeToCache,
  getAttachmentGroupsOfNodeFromCache,
  writeAttachmentGroupsOfNodeInCache,
  getConfigurationsFromCache,
  writeConfigurationsToCache,
  getAttachmentFragmentFromCache,
  writeAttachmentSimilarityReportInCache,
  getBasicCommentOfNodeFromCache,
  writeProcessJournalCommentsInCache,
  writeUserNotificationInFromCache,
  getUserNotificationCountFromCache,
  getGlobalConstantsFromCache,
  writePlatformUserDetailsToCache,
} from "modules/CommonGraphqlHelpers";
import { updateUnitPlan } from "IBPlanner/modules/IBPlannerModule";
import { updateLe } from "LearningEngagement/modules/LeModule";
import { updateAssessment } from "Assessments/modules/AssessmentModule";
import { updateChecklistElement } from "Checklist/modules/ChecklistModule";
import { updateRubricElement } from "Rubric/modules/RubricModule";
import { updateSinglePointRubricElement } from "SinglePointRubric/modules/SinglePointRubricModule";
import { updateSelfStudyResponseOnServer } from "SnP/modules/SnPModule";
import {
  updateRemark,
  handleAssessmentToolResponse,
  updateStudentsScore,
} from "AssessmentEvaluation/modules/AssessmentEvaluationModule";

import { updateSubjectMutationFn } from "SubjectsSetup/modules/SubjectsModule";

import {
  setToastMsg,
  getUserInfo,
  getUseNewIdentityFlowStatus,
} from "Login/modules/LoginModule";
import ACLStore from "lib/aclStore";
import { updateUserHandler } from "OnBoarding/modules/OnBoardingModule";
import {
  editStudentAssignmentSubmission,
  updateElementRemark,
} from "ClassRoom/modules/ClassRoomModule";
import * as EventTracker from "lib/eventTracker";
import { getCommunityUserDetailsFromCache } from "Community/modules/CommunityGraphqlHelpers";
import { updateAssessmentToolElement } from "modules/AssessmentTool/AssessmentToolModule";
import { resetUserCircularNewCountMutation } from "Circulars/modules/CircularMutation";
import { Sentry } from "services/sentry";
import {
  updateProjectField,
  updateStudentProjectPortfolioField,
} from "Projects/modules/ProjectModules";
import querystringify from "querystringify";
import {
  setEnabledClassNotificationMutation,
  updateOrganizationInfoMutation,
} from "./CommonMutations";
import { getEnabledClassNotificationsQuery } from "./CommonQuery";
import _ from "lodash";
import queryString from "query-string";
import { getRotationDayLabel } from "Attendance/modules/AttendanceModule";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  CURRICULUM_TYPE_MYP,
  ELEMENT_TYPE_UBD_LEARNING_STANDARD,
  ELEMENT_TYPE_UBD_SUBJECT,
} from "Constants/stringConstants";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";

const PATH_TO_GET_SIGNED_URL = `/auth/generateS3SignedURL`;
const CancelToken = axios.CancelToken;

const QUERY_STRING_CONFIG = { parseBooleans: true };

// ------------------------------------
// Constants
// ------------------------------------
export const NAME = "app_services";

export const SET_IS_ONLINE = "SET_IS_ONLINE" + " " + NAME;
export const SET_IS_PAGE_NOT_FOUND = "SET_IS_PAGE_NOT_FOUND" + " " + NAME;
export const SET_PROGRESS = "SET_PROGRESS" + " " + NAME;
export const REMOVE_PROGRESS_ELEMENT = "REMOVE_PROGRESS_ELEMENT" + " " + NAME;
export const UPDATE_LOCAL_COMMENT = "UPDATE_LOCAL_COMMENT" + " " + NAME;

export const INITIALISE_RESOURCEBANK_COMPONENT =
  "INITIALISE_RESOURCEBANK_COMPONENT" + " " + NAME;
export const UPDATE_RESOURCEBANK_FILTERS =
  "UPDATE_RESOURCEBANK_FILTERS" + " " + NAME;
export const TOGGLE_RESOURCEBANK_FILTER_ITEM =
  "TOGGLE_RESOURCEBANK_FILTER_ITEM" + " " + NAME;
export const TOGGLE_ALL_RESOURCEBANK_FILTER_ITEMS =
  "TOGGLE_ALL_RESOURCE_FILTER_ITEMS" + " " + NAME;
export const TOGGLE_RESOURCEBANK_SELECTED_RESOURCES =
  "TOGGLE_RESOURCEBANK_SELECTED_RESOURCES" + " " + NAME;
export const CLEAR_RESOURCEBANK_SELECTED_RESOURCES =
  "CLEAR_RESOURCEBANK_SELECTED_RESOURCES" + " " + NAME;

export const SET_ORDER_BY_DIRECTION = "SET_ORDER_BY_DIRECTION" + " " + NAME;

export const TOGGLE_ACTION_LOADING = "TOGGLE_ACTION_LOADING" + " " + NAME;

export const UPDATE_AUDIO_RECORDER_BLOB =
  "UPDATE_AUDIO_RECORDER_BLOB" + " " + NAME;

export const SET_PICTURE_IN_PICTURE_DATA =
  "SET_PICTURE_IN_PICTURE_DATA" + " " + NAME;

export const UPDATE_GLOBAL_SEARCH_TEXT = `UPDATE_GLOBAL_SEARCH_TEXT ${NAME}`;

export const SET_GLOBAL_SEARCH_VOICE = `SET_GLOBAL_SEARCH_VOICE ${NAME}`;

export const SET_GLOBAL_SEARCH_MODAL = `SET_GLOBAL_SEARCH_MODAL ${NAME}`;

export const SET_GLOBAL_SEARCH_FILTER_MODULE = `SET_GLOBAL_SEARCH_FILTER_MODULE ${NAME}`;

export const TOGGLE_GLOBAL_SEARCH_MODULE = `TOGGLE_GLOBAL_SEARCH_MODULE ${NAME}`;

export const TOGGLE_GLOBAL_SEARCH_VOICE_MODULE = `TOGGLE_GLOBAL_SEARCH_VOICE_MODULE ${NAME}`;

export const TOGGLE_GLOBAL_SEARCH_AND_VOICE_MODULE = `TOGGLE_GLOBAL_SEARCH_AND_VOICE_MODULE ${NAME}`;

export const UPDATE_ATTACHMENT_LOADER_MENU_STYLES = `UPDATE_ATTACHMENT_LOADER_MENU_STYLES ${NAME}`;

export const RESET_ATTACHMENT_LOADER_MENU_STYLES = `RESET_ATTACHMENT_LOADER_MENU_STYLES ${NAME}`;

// PURE ACTIONS

//never write to prototype
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.allCapitalize = function () {
  return this.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

String.prototype.camelize = function () {
  const str = this;
  return str.replace(/\W+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
};

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

Number.prototype.between = function (a, b) {
  var min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return this > min && this < max;
};

Array.prototype.lastNth = function (n) {
  return this[this.length - n];
};

Array.prototype.firstNth = function (n) {
  return this[n - 1];
};

Array.prototype.first = function () {
  return this[0];
};

Array.prototype.next = function ({ current, findKey }) {
  let index = _.indexOf(this, current);
  if (findKey) {
    index = _.findIndex(this, { [findKey]: current });
  }
  return this[index + 1];
};

Array.prototype.previous = function ({ current, findKey }) {
  let index = _.indexOf(this, current);
  if (findKey) {
    index = _.findIndex(this, { [findKey]: current });
  }
  return this[index - 1];
};
// Array.from = function(what, n, step) {
//   var A = [];
//   if (arguments.length) {
//     if (n) {
//       return A.range(what, n, step);
//     }
//     L = what.length;
//     if (L) {
//       while (L) {
//         A[--L] = what[L];
//       }
//       return A;
//     }
//     if (what.hasOwnProperty) {
//       for (var p in what) {
//         if (what.hasOwnProperty(p)) {
//           A[A.length] = what[p];
//         }
//       }
//       return A;
//     }
//   }
//   return A;
// };

Array.prototype.range = function (what, n, step) {
  this[this.length] = what;
  if (what.split && n.split) {
    what = what.charCodeAt(0);
    n = n.charCodeAt(0);
    while (what < n) {
      this[this.length] = String.fromCharCode(++what);
    }
  } else if (isFinite(what)) {
    step = step || 1;
    while (what < n) this[this.length] = what += step;
  }
  return this;
};

export const MONTH_LIST = Array.apply(0, Array(12)).map(function (_, i) {
  return {
    label: moment().month(i).format("MMMM"),
    value: i + 1,
  };
});

export const YEAR_LIST = () => {
  const step = 1;
  const start = moment().year() - 6;
  const stop = moment().year() + 6;
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  ).map(item => {
    return { label: item, value: item };
  });
};

export const DAYS_LIST = () => {
  return Array.from({ length: 7 }, (_, i) => i).map(i => {
    return {
      label: moment().day(i).format("dddd"),
      value: i,
    };
  });
};

export const ASSESSMENT_TOOLS_OBJ = {
  RUBRIC: {
    type: "RUBRIC",
    entityType: "RUBRIC",
    label: "Rubric",
    locale: "common:rubric",
    locale_plural: "common:rubric_plural",
    plural: "Rubrics",
    itemName: "rubrics",
    key: "rubric",
    printType: "rubric",
    hasTemplate: true,
    hasEvaluation: true,
    hasInsight: true,
  },
  MYP_OBJECTIVE_RUBRIC: {
    type: "MYP_OBJECTIVE_RUBRIC",
    entityType: "MYP_OBJECTIVE_RUBRIC",
    label: "Subject Criteria Rubric",
    locale: "common:criteria_rubric",
    locale_plural: "common:rubric_plural",
    plural: "Criteria Rubrics",
    itemName: "mypObjectiveRubrics",
    key: "mypObjectiveRubric",
    printType: "mypObjectiveRubric",
    objectiveFieldKey: "objectivesMYP",
    hasTemplate: false,
    hasEvaluation: true,
    hasInsight: true,
  },
  MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC: {
    type: "MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC",
    entityType: "MYP_OBJECTIVE_RUBRIC",
    label: "Interdisciplinary Criteria Rubric",
    locale: "common:interdisciplinary_criteria_rubric",
    locale_plural: "common:rubric_plural",
    plural: "Interdisciplinary Criteria Rubrics",
    itemName: "mypObjectiveRubrics",
    objectiveFieldKey: "interdisciplinaryCriteriaMYP",
    key: "mypObjectiveRubric",
    printType: "mypObjectiveRubric",
    hasTemplate: false,
    hasEvaluation: true,
    hasInsight: false,
  },
  SINGLE_POINT_RUBRIC: {
    type: "SINGLE_POINT_RUBRIC",
    entityType: "SINGLE_POINT_RUBRIC",
    label: "Single Point Rubric",
    locale: "common:single_point_rubric",
    locale_plural: "common:single_point_rubric_plural",
    plural: "Single Point Rubrics",
    itemName: "singlePointRubrics",
    key: "singlePointRubric",
    printType: "singlepointrubric",
    hasTemplate: true,
    hasEvaluation: true,
  },
  CHECKLIST: {
    type: "CHECKLIST",
    entityType: "CHECKLIST",
    label: "Checklist",
    locale: "common:checklist",
    locale_plural: "common:checklist_plural",
    plural: "Checklists",
    itemName: "checklists",
    key: "checklist",
    printType: "checklist",
    hasTemplate: true,
    hasEvaluation: true,
    hasInsight: true,
  },
  EXEMPLAR: {
    type: "EXEMPLAR",
    entityType: "EXEMPLAR",
    label: "Exemplar",
    locale: "common:exemplar",
    locale_plural: "common:exemplar_plural",
    plural: "Exemplars",
    itemName: "exemplars",
    key: "exemplar",
    hasTemplate: false,
  },
  ANECDOTE: {
    type: "ANECDOTE",
    entityType: "ANECDOTE",
    label: "Anecdotal",
    locale: "common:anecdotal_records",
    locale_plural: "common:anecdotal_records",
    plural: "Anecdotals",
    itemName: "anecdotals",
    key: "anecdotal",
    hasTemplate: false,
  },
};

export const ASSESSMENT_TOOLS = [
  {
    type: "RUBRIC",
    entityType: "RUBRIC",
    label: "Rubric",
    locale: "common:rubric",
    locale_plural: "common:rubric_plural",
    plural: "Rubrics",
    itemName: "rubrics",
    key: "rubric",
    printType: "rubric",
    hasTemplate: true,
    hasEvaluation: true,
    hasInsight: true,
    canPrint: true,
  },
  {
    type: "MYP_OBJECTIVE_RUBRIC",
    entityType: "MYP_OBJECTIVE_RUBRIC",
    label: "Subject Criteria Rubric",
    locale: "common:criteria_rubric",
    locale_plural: "common:rubric_plural",
    plural: "Criteria Rubrics",
    itemName: "mypObjectiveRubrics",
    key: "mypObjectiveRubric",
    printType: "mypObjectiveRubric",
    objectiveFieldKey: "objectivesMYP",
    hasTemplate: false,
    hasEvaluation: true,
    hasInsight: true,
    canPrint: true,
  },
  {
    type: "MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC",
    entityType: "MYP_OBJECTIVE_RUBRIC",
    label: "Interdisciplinary Criteria Rubric",
    locale: "common:interdisciplinary_criteria_rubric",
    locale_plural: "common:rubric_plural",
    plural: "Interdisciplinary Criteria Rubrics",
    itemName: "mypObjectiveRubrics",
    objectiveFieldKey: "interdisciplinaryCriteriaMYP",
    key: "mypObjectiveRubric",
    printType: "mypObjectiveRubric",
    hasTemplate: false,
    hasEvaluation: true,
    hasInsight: false,
    canPrint: true,
  },
  {
    type: "SINGLE_POINT_RUBRIC",
    entityType: "SINGLE_POINT_RUBRIC",
    label: "Single Point Rubric",
    locale: "common:single_point_rubric",
    locale_plural: "common:single_point_rubric_plural",
    plural: "Single Point Rubrics",
    itemName: "singlePointRubrics",
    key: "singlePointRubric",
    printType: "singlepointrubric",
    hasTemplate: true,
    hasEvaluation: true,
    canPrint: true,
  },
  {
    type: "CHECKLIST",
    entityType: "CHECKLIST",
    label: "Checklist",
    locale: "common:checklist",
    locale_plural: "common:checklist_plural",
    plural: "Checklists",
    itemName: "checklists",
    key: "checklist",
    printType: "checklist",
    hasTemplate: true,
    hasEvaluation: true,
    hasInsight: true,
    canPrint: true,
  },
  {
    type: "EXEMPLAR",
    entityType: "EXEMPLAR",
    label: "Exemplar",
    locale: "common:exemplar",
    locale_plural: "common:exemplar_plural",
    plural: "Exemplars",
    itemName: "exemplars",
    key: "exemplar",
    hasTemplate: false,
    canPrint: false,
  },
  {
    type: "ANECDOTE",
    entityType: "ANECDOTE",
    label: "Anecdotal",
    locale: "common:anecdotal_records",
    locale_plural: "common:anecdotal_records",
    plural: "Anecdotals",
    itemName: "anecdotals",
    key: "anecdotal",
    hasTemplate: false,
    canPrint: false,
  },
];

export const GENERIC_TAGS_INFO = {
  SL: {
    color: "teal",
  },
  HL: {
    color: "pink",
  },
};

export const getPlannerElementSetDynamicLabelsMemoize = _.memoize(
  params => getPlannerElementSetDynamicLabels(params),
  params => JSON.stringify(params)
);

export const getPlannerElementSetDynamicLabels = ({ plannerElementSets }) => {
  const plannerElementSetLabels = _.reduce(
    plannerElementSets,
    (result, { type, label }) => {
      result[type] = label;
      return result;
    },
    {}
  );
  return plannerElementSetLabels;
};

export const SIZE = [
  {
    type: "group",
    label: "Group",
    locale: "common:group",
  },
  {
    type: "indi",
    label: "Individual",
    locale: "common:indi",
  },
];

export const EVALUATION_METHOD = [
  {
    type: "teacher",
    label: "Teacher Evaluation",
    locale: "common:teacher_evaluation",
  },
  {
    type: "self",
    label: "Self Evaluation",
    locale: "common:self_evaluation",
  },
  {
    type: "peer",
    label: "Peer Evaluation",
    locale: "common:peer_evaluation",
  },
];

//TODO: move this into template at Organization level
export const EVALUATE_PLANNER_ELEMENTS_BY_CURRICULUM = {
  [CURRICULUM_TYPE_PYP]: [
    {
      label: "Approaches to Learning",
      value: "atls",
    },
    {
      label: "Learning Outcomes",
      value: "benchmarks",
    },
    {
      label: "Concepts",
      value: "concepts",
    },
    {
      label: "Learner Profile",
      value: "lp",
    },
  ],
  [CURRICULUM_TYPE_UBD]: [
    {
      label: "UBD Learning Standards",
      value: "learningStandardUBD",
    },
    {
      label: "UBD Skills",
      value: "atlsUBD",
    },
  ],
  [CURRICULUM_TYPE_MYP]: [
    {
      label: "Subject Standard",
      value: "learningStandardMYP",
    },
    {
      label: "Approches to learning",
      value: "atlsMYP",
    },
  ],
};

// function to check whether LE have pyp elements associated
export const getPlannerElementsToEvaluate = ({
  assessmentDetails,
  curriculumProgramType = CURRICULUM_TYPE_PYP,
}) => {
  return _.filter(
    EVALUATE_PLANNER_ELEMENTS_BY_CURRICULUM[curriculumProgramType],
    ({ value }) => {
      return checkEmptyElementValue({
        fieldKey: value,
        data: assessmentDetails,
        ignoreUnitPlanSubjectCheck: true, // TODO: hack for classroom LE - remove later
      });
    }
  );
};

export const getTemplateFieldElements = ({ template, fields }) => {
  return _.map(fields, key => {
    const templateField = template.field_list[key];
    const { config: { viewLabel, label } = {} } = templateField || {};
    return {
      label: viewLabel || label,
      value: key,
    };
  });
};

/**
 * Returns total linked identities based on identity flow
 *
 * @param {*} {id: string, type:string}
 * @returns {Int} Total linked identities
 */
export const getTotalLinkedIdentities = ({ id, type }) => {
  const {
    linkedIdentities,
    linkedIdentityUsersV2,
  } = getPlatformUserDetailsFromCache({ id, type });

  const useNewIdentityFlow = getUseNewIdentityFlowStatus();

  if (useNewIdentityFlow) {
    return _.get(linkedIdentityUsersV2, "totalCount", 0);
  }

  return _.size(linkedIdentities);
};

export const updateAudioRecorderBlob = blobObject => {
  return { type: UPDATE_AUDIO_RECORDER_BLOB, payload: blobObject };
};

export const setPictureInPictureConfig = data => {
  return { type: SET_PICTURE_IN_PICTURE_DATA, data };
};

export const setIsOnline = value => {
  return { type: SET_IS_ONLINE, payload: value };
};

export const setIsPageNotFound = value => {
  return { type: SET_IS_PAGE_NOT_FOUND, payload: value };
};

export const setProgress = value => {
  return { type: SET_PROGRESS, payload: value };
};

export const setOrderByDirection = data => {
  return { type: SET_ORDER_BY_DIRECTION, data };
};

export const toggleActionLoading = data => {
  return {
    type: TOGGLE_ACTION_LOADING,
    data,
  };
};

export const updateLocalComment = data => {
  return {
    type: UPDATE_LOCAL_COMMENT,
    data,
  };
};

export const removeProgressElement = value => {
  return { type: REMOVE_PROGRESS_ELEMENT, payload: value };
};

export const initaliseResourceBankComponent = id => {
  return {
    type: INITIALISE_RESOURCEBANK_COMPONENT,
    data: id,
  };
};

export const updateResourceBankFilters = (params, id) => {
  return {
    type: UPDATE_RESOURCEBANK_FILTERS,
    data: { params, id },
  };
};

export const toggleResourceBankFilterItem = (params, id) => {
  return {
    type: TOGGLE_RESOURCEBANK_FILTER_ITEM,
    data: { params, id },
  };
};

export const toggleAllResourceBankFilterItems = (params, id) => {
  return {
    type: TOGGLE_ALL_RESOURCEBANK_FILTER_ITEMS,
    data: { params, id },
  };
};

export const toggleResourceBankSelectedResources = (params, id) => {
  return {
    type: TOGGLE_RESOURCEBANK_SELECTED_RESOURCES,
    data: { params, id },
  };
};

export const clearResourceBankSelectedResources = id => {
  return {
    type: CLEAR_RESOURCEBANK_SELECTED_RESOURCES,
    data: { id },
  };
};

export const checkPermAndNavigate = ({ perm, replace }) => {
  const isPermission = ACLStore.can(perm);
  if (!isPermission) {
    replace(getRelativePath("../"));
  }
};

export const getCurrentAcademicYear = ({ organizationId }) => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const academicYears = _.get(organizationDetails, "academicYears", []);
  return _.find(academicYears, { isCurrentAcademicYear: true });
};

//This Function returns the start and endDate of any entity when academicYears are passed as an input
//In case of multiple academicYears we need startDate of the first academicYear
//and endDate of the last academicYear, This function is used in that case.

export const getStartEndDatesOfAcademicYears = ({ academicYears }) => {
  const sortedAcademicYears = _.sortBy(academicYears, [item => item.startDate]);
  return {
    startDate: _.get(_.first(sortedAcademicYears), "startDate", null),
    endDate: _.get(_.last(sortedAcademicYears), "endDate", null),
  };
};

//This function returns the start and end Dates of the course

export const getCourseAcademicYearDates = courseId => {
  const courseDetails = getCourseDetailsFromCache({ id: courseId });
  const academicYears = _.get(courseDetails, "academicYears", []);

  return getStartEndDatesOfAcademicYears({ academicYears });
};

export const checkIsLastOrIsFirstAcademicYear = ({
  organizationId,
  academicYear,
}) => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);

  const academicYears = _.get(organizationDetails, "academicYears", []);

  return {
    isFirst: academicYears.first().id == academicYear,
    isLast: academicYears.last().id == academicYear,
  };
};

export const getAcademicYearsList = organizationId => {
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);

  return _.map(organizationDetails.academicYears, academicYear => {
    const { id, startDate, endDate, isCurrentAcademicYear } = academicYear;
    return {
      startDate,
      endDate,
      isCurrentAcademicYear,
      label: getYearDurationFormat({ startDate, endDate }),
      value: id,
    };
  });
};

export const getSubjectLabel = value => {
  if (value && !Array.isArray(value)) {
    return _.get(getSubjectBasicDetailsFromCache(value), "name", "");
  } else {
    return _.filter(
      _.map(value, item => {
        return _.get(getSubjectBasicDetailsFromCache(item), "name", "");
      }),
      val => !!val
    );
  }
};

export const getPypElementLabel = ({ type, value }) => {
  if (value && !Array.isArray(value)) {
    const pypElement = getPypElementFromCache({ type, id: value });

    return _.get(pypElement, "label", "No Label");
  } else {
    return _.filter(
      _.map(value, item => {
        const pypElement = getPypElementFromCache({ type, id: item });

        return _.get(pypElement, "label");
      }),
      val => !!val
    );
  }
};

export const getUnitPlanCardThemeTextArr = ({ data, key }) => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return _.map(data, item => {
    return item[key];
  });
};

export const getPlannerElementLabel = ({ nodes, value }) => {
  if (value && !Array.isArray(value)) {
    return _.get(_.find(nodes, { id: value }), "label", "");
  } else {
    return _.filter(
      _.map(value, item => {
        return _.get(_.find(nodes, { id: item }), "label", "");
      }),
      val => !!val
    );
  }
};

export const getFocusAreaLabel = value => {
  if (value && !Array.isArray(value)) {
    return _.get(getFocusAreaFromCache(value), "label", "");
  } else {
    return _.map(value, item => {
      return _.get(getFocusAreaFromCache(item), "label", "");
    });
  }
};

export const extractDeletedPypElements = ({ type, value }) => {
  return _.filter(value, item => {
    const pypElement = getPypElementFromCache({ type, id: item });
    return !_.isEmpty(pypElement);
  });
};

/**
 * @deprecated
 */
export const updateOrganizationInfo = input => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: updateOrganizationInfoMutation,
        variables: {
          input,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

//this to handle global search state
export const handleGlobalSearch = e => {
  return async (dispatch, getState) => {
    //this is basic condition to open and close global search modal
    const openModal = (e.ctrlKey || e.metaKey) && e.key === "/";

    const { showSearchBar } = getState().app_services.globalSearchBar;
    const activeTab = getState().login.activeTab;

    if (activeTab === "community") {
      return;
    }

    if (openModal && e.shiftKey && showSearchBar) {
      dispatch(toggleVoiceSearch());
    } else if (openModal && e.shiftKey) {
      dispatch(toggleSearchAndVoiceModule());
    } else if (openModal) {
      dispatch(toggleGlobalSearchBarModal());
    }

    //handle removal of searchbar
    if (e.key === "Escape" && showSearchBar) {
      dispatch(toggleGlobalSearchBarModal());
    }
  };
};

//this is to toggle voice search on global search bar
export const toggleVoiceSearch = data => {
  return { type: TOGGLE_GLOBAL_SEARCH_VOICE_MODULE, payload: data };
};

//this is to toggle modal state for global search bar
export const toggleGlobalSearchBarModal = () => {
  return { type: TOGGLE_GLOBAL_SEARCH_MODULE };
};

export const toggleSearchAndVoiceModule = () => {
  return { type: TOGGLE_GLOBAL_SEARCH_AND_VOICE_MODULE };
};

export const updateGlobalSearchText = data => {
  return { type: UPDATE_GLOBAL_SEARCH_TEXT, payload: data };
};

export const updateGlobalSearchModuleFilter = data => {
  return { type: SET_GLOBAL_SEARCH_FILTER_MODULE, payload: data };
};

export const updateAttachmentLoaderMenuStyles = data => {
  return {
    type: UPDATE_ATTACHMENT_LOADER_MENU_STYLES,
    payload: data,
  };
};

export const resetAttachmentLoaderMenuStyles = () => {
  return {
    type: RESET_ATTACHMENT_LOADER_MENU_STYLES,
  };
};

export const setConfigurationValue = (
  input,
  platform,
  categories,
  courseId,
  curriculumProgramId,
  optimisticUpdate,
  refetchConfiguration,
  setSelectedValue,
  value
) => {
  return async (dispatch, getState) => {
    const { platform: p, courseId: c, ...rest } = input;
    const oldData = getConfigurationsFromCache({
      platform,
      categories,
      courseId,
      curriculumProgramId,
    });
    const newData = {
      ...oldData,
      platform: {
        ...oldData.platform,
        organization: {
          ...oldData.platform.organization,
          configurations: _.map(
            oldData.platform.organization.configurations,
            configuration => {
              return configuration.id === input.id
                ? {
                    ...configuration,
                    ...rest,
                  }
                : configuration;
            }
          ),
        },
      },
    };
    //hack :- Prevent optimistic update on condition
    if (optimisticUpdate) {
      writeConfigurationsToCache({
        platform,
        categories,
        courseId,
        data: newData,
        curriculumProgramId,
      });
    }
    try {
      const response = await client.mutate({
        mutation: setConfigurationValueMutation,
        variables: {
          input,
        },
        ...(refetchConfiguration
          ? {
              refetchQueries: [
                {
                  query: getConfigurationsQuery,
                  variables: {
                    platform,
                    categories,
                  },
                },
              ],
            }
          : {}),
      });
      const setConfigurationValue = _.get(
        response,
        "data.school.setConfigurationValue",
        true
      );
      if (setSelectedValue) {
        setSelectedValue(value);
      }

      if (setConfigurationValue) {
        if (!optimisticUpdate && !refetchConfiguration) {
          writeConfigurationsToCache({
            platform,
            categories,
            courseId,
            data: newData,
            curriculumProgramId,
          });
        }
        return setConfigurationValue;
      } else {
        writeConfigurationsToCache({
          platform,
          categories,
          courseId,
          data: oldData,
          curriculumProgramId,
        });

        return setConfigurationValue;
      }
    } catch (e) {}
  };
};

export const setSelectedCoursesForNotification = ({
  input,
  platform,
  categories,
  configurationId,
  selectedCourses,
  courseId,
  curriculumProgramId,
}) => {
  return async (dispatch, getState) => {
    const oldData = getConfigurationsFromCache({
      platform,
      categories,
      courseId,
      curriculumProgramId,
    });
    const newData = {
      ...oldData,
      platform: {
        ...oldData.platform,
        organization: {
          ...oldData.platform.organization,
          configurations: _.map(
            oldData.platform.organization.configurations,
            configuration => {
              return configuration.id === configurationId
                ? {
                    ...configuration,
                    selectedCourses: _.map(selectedCourses, course => {
                      return {
                        ...course,
                        __typename: "Course",
                      };
                    }),
                  }
                : configuration;
            }
          ),
        },
      },
    };
    writeConfigurationsToCache({
      platform,
      categories,
      courseId,
      data: newData,
      curriculumProgramId,
    });
    try {
      await client.mutate({
        mutation: setNotificationConfigurationForCourseMutation,
        variables: {
          input,
        },
      });
    } catch (e) {}
  };
};

export const setEnabledClassNotification = input => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    try {
      await client.mutate({
        mutation: setEnabledClassNotificationMutation,
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: getEnabledClassNotificationsQuery,
            variables: {
              id: userId,
              type: "STAFF",
            },
          },
        ],
      });
    } catch (e) {}
  };
};

export const addApiEvent = params => {
  return async (dispatch, getState) => {
    try {
      const userLoggedIn = _.get(getState(), "login.userLoggedIn");

      if (!userLoggedIn) return;

      await client.mutate({
        mutation: addApiEventMutation,
        variables: params,
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const createPlanUpgradeRequest = ({ screen }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(toggleActionLoading(true));
      await client.mutate({
        mutation: createPlanUpgradeRequestMutation,
        variables: {
          screen,
          portalType: "PLANNER",
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:request_successfully_sent",
          type: "tick",
          position: "toast-bottom-right",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleActionLoading(false));
    }
  };
};

export const pypElementMapping = {
  lp: "LEARNER_PROFILE",
  concepts: "CONCEPT",
  theme: "THEME",
  atls: "ATL",
  centralIdea: "CI",
  lois: "LOI",
  benchmarks: "BENCHMARK",
  action: "ACTION",
  unitPlan: "UNIT_PLAN",
  learningStandardUBD: "UBD_LEARNING_STANDARD",
  atlsUBD: "UBD_ATL",
  atlsMYP: "MYP_ATL",
  learningStandardMYP: "MYP_LEARNING_STANDARD",
};

export const updatedPypElementMapping = {
  lp: "LEARNER_PROFILE",
  concept: "CONCEPT",
  atl: "ATL",
  benchmark: "BENCHMARK",
};

export const pypElementLabelMapping = {
  lp: "Learner Profile Attributes",
  action: "Action",
  concepts: "Concepts",
  theme: "Themes",
  atls: "Approaches to Learning",
  benchmarks: "Knowledge",
  atlsUBD: "UBD Skills",
  learningStandardUBD: "Subject Standards",
  learningStandardMYP: "Subject Standards",
};

export const pypElementSetMapping = {
  lp: "learnerProfiles",
  action: "actions",
  concepts: "concepts",
  theme: "themes",
  atls: "atls",
  benchmarks: "benchmarks",
  subjects: "subjects",
  relatedConcepts: "relatedConcepts",
  subjectGroup: "subjectGroups",
  learningStandardUBD: "learningStandardUBD",
  atlsUBD: "atlsUBD",
  atlsMYP: "atlsMYP",
  learningStandardMYP: "learningStandardMYP",
};

export const commentParentTypeToEntityMapping = parentType => {
  switch (parentType) {
    case "FIELD":
      return "PLANNER_FIELD";
    case "FLOW_SECTION":
      return "PLANNER_FLOW_SECTION";
    default:
      return parentType;
  }
};

export const getAttachment = async params => {
  const result = await client.query({
    query: getAttachmentQuery,
    variables: {
      ...params,
    },
    fetchPolicy: "network-only",
  });
  return result ? _.get(result, "data.node", {}) : {};
};

export const getUserThirdPartyAuthorizedStatus = ({ serviceType }) => {
  return async (dispatch, getState) => {
    const userInfo = getState().login.userInfo;
    const userId = userInfo.id;
    const userType = userInfo.user_type;
    const userEntityType = _.get(
      USER_TYPE_ENTITY_MAPPING[userType],
      "entityType",
      ""
    );
    try {
      const response = await client.query({
        query: getUserThirdPartyAuthorizedStatusQuery,
        variables: {
          id: userId,
          type: userEntityType,
          serviceType,
        },
        fetchPolicy: "network-only",
      });
      return _.last(_.get(response, "data.node.linkedThirdPartyAccounts", []));
    } catch (e) {
      return false;
    }
  };
};

export const getUserZoomAuthorizedStatus = () => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    try {
      const response = await client.query({
        query: getUserZoomAuthorizedStatusQuery,
        variables: {
          id: userId,
        },
        fetchPolicy: "network-only",
      });
      return _.get(response, "data.node.isZoomAuthorized", false);
    } catch (e) {
      return false;
    }
  };
};

export const generateZoomRefreshToken = ({ accessCode, redirectUri }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: generateZoomRefreshTokenMutation,
        variables: {
          accessCode,
          redirectUri,
        },
      });
      return _.get(response, "data.platform.generateZoomRefreshToken", false);
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const generateThirdPartyRefreshToken = ({
  accessCode,
  redirectUri,
  serviceType,
}) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: generateThirdPartyRefreshTokenMutation,
        variables: {
          accessCode,
          redirectUri,
          serviceType,
        },
      });
      return _.get(
        response,
        "data.platform.generateThirdPartyRefreshToken.response",
        {}
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const generateZoomMeeting = ({ label, parentId, parentType }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: createZoomMeetingMutation,
        variables: {
          label,
          parentId,
          parentType,
        },
      });
      const zoomNodeData = getZoomMeetingNodeFromCache({
        parentId,
        parentType,
      });

      const zoomMeetingResponse = _.get(
        response,
        "data.platform.createZoomMeeting",
        {}
      );
      const zoomMeeting = _.get(zoomMeetingResponse, "response", {});
      const errorCode = _.get(zoomMeetingResponse, "warning.errorCode", "");
      if (!_.isEmpty(zoomNodeData) && !_.isEmpty(zoomMeeting)) {
        writeZoomMeetingNodeToCache({
          parentId,
          parentType,
          data: { ...zoomNodeData, zoomMeeting },
        });
      }
      if (errorCode == "TD_ZOOM_API_NOT_RESPONDING") {
        dispatch(setToastMsg("toastMsgs:zoom_app_not_responding"));
      }

      return errorCode;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return {};
    }
  };
};

export const deleteZoomMeeting = ({ id, parentId, parentType }) => {
  return async (dispatch, getState) => {
    const zoomNodeData = getZoomMeetingNodeFromCache({
      parentId,
      parentType,
    });
    if (!_.isEmpty(zoomNodeData)) {
      writeZoomMeetingNodeToCache({
        parentId,
        parentType,
        data: { ...zoomNodeData, zoomMeeting: null },
      });
    }
    try {
      const response = await client.mutate({
        mutation: deleteZoomMeetingMutation,
        variables: {
          id,
        },
      });

      const zoomMeeting = _.get(
        response,
        "data.platform.createZoomMeeting",
        {}
      );

      return zoomMeeting;
    } catch (e) {
      writeZoomMeetingNodeToCache({
        parentId,
        parentType,
        data: zoomNodeData,
      });
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return {};
    }
  };
};

export const createGenericFolder = ({ name, color, folderType }) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    try {
      const res = await client.mutate({
        mutation: createGenericFolderMutation,
        variables: {
          courseId,
          name: name,
          color,
          type: folderType,
        },
        refetchQueries: [
          {
            query: getCourseGenericFoldersQuery,
            variables: { courseId, folderType },
          },
        ],
      });

      if (_.get(res, "data.platform.createFolder.response", null) !== null) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:folder_successfully_action",
            locale_params: [
              { key: "action", value: "toastMsgs:created", isPlainText: false },
            ],
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      } else if (
        _.get(res, "data.platform.createFolder.warning", null) !== null
      ) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:label_with_same_name",
            locale_params: [
              { key: "label", value: "common:folder", isPlainText: false },
            ],
          })
        );
      }

      return res;
    } catch (err) {
      if (err.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw err;
    }
  };
};

export const updateGenericFolder = ({ id, name, color }) => {
  return async (dispatch, getState) => {
    try {
      const res = await client.mutate({
        mutation: updateGenericFolderMutation,
        variables: {
          id,
          name: name,
          color,
        },
      });

      if (_.get(res, "data.platform.updateFolder.response", null) !== null) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:folder_successfully_action",
            locale_params: [
              { key: "action", value: "toastMsgs:updated", isPlainText: false },
            ],
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      } else if (
        _.get(res, "data.platform.updateFolder.warning", null) !== null
      ) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:label_with_same_name",
            locale_params: [
              { key: "label", value: "common:folder", isPlainText: false },
            ],
          })
        );
      }

      return res;
    } catch (err) {
      if (err.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw err;
    }
  };
};

export const deleteGenericFolder = ({ id, folderType }) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    try {
      await client.mutate({
        mutation: deleteGenericFolderMutation,
        variables: {
          id,
        },

        refetchQueries: [
          {
            query: getCourseGenericFoldersQuery,
            variables: { courseId, folderType },
          },
        ],
      });
      setToastMsg({
        msg: "toastMsgs:folder_successfully_action",
        locale_params: [
          { key: "action", value: "toastMsgs:deleted", isPlainText: false },
        ],
        type: "tick",
        position: "toast-bottom-left",
      });
    } catch (err) {
      if (err.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw err;
    }
  };
};

export const getTotalCommentOfNode = ({ id, type }) => {
  return async (dispatch, getState) => {
    const isCommunity = getState().login.activeTab == "community";
    await client.query({
      query: getTotalCommentOfNodeQuery,
      variables: {
        id,
        type,
        isCommunity,
      },
      fetchPolicy: "network-only",
    });
  };
};

export const getElementValue = ({
  fieldUID,
  valueKeys,
  unitPlanFieldValue,
  benchmarkSet,
  fieldObj,
  isUnitPlan = false,
  isPost = false,
  resolvedValue,
  isLocalisedTemplate = true,
  subjectIds,
  shouldStripRichTextStyles = false,
  t,
  deriveSubjectIdsFromNodes = false,
  isCommunity = false,
}) => {
  switch (fieldUID) {
    case "hours":
      return _.isNumber(valueKeys) ? _.toString(valueKeys) : "-";
    case "dateDuration":
      if (valueKeys && valueKeys.startDate && valueKeys.endDate) {
        return t("common:start_date_end_date_label", {
          startDate: moment(valueKeys.startDate).format("DD-MM-YYYY"),
          endDate: moment(valueKeys.endDate).format("DD-MM-YYYY"),
        });
      }
      return null;
    case "centralIdea":
      return isUnitPlan ? valueKeys : unitPlanFieldValue;
    case "focusAreas":
      return _.join(getFocusAreaLabel(valueKeys), "; ");
    case "subjectGroup":
      return _.map(
        _.get(resolvedValue, "subjectGroups", []),
        subjectGroup => subjectGroup.name
      );
    case "subjects":
      return _.map(
        _.get(resolvedValue, "subjects", []),
        subjectGroup => subjectGroup.name
      );
    case "subjectLevel":
      return _.map(_.get(resolvedValue, "genericTags", []), tag => tag.label);
    case "action":
      return isPost && !ACLStore.can("FeatureFlag:EnableAction")
        ? "Action"
        : getPypElementLabel({
            value: valueKeys,
            type: pypElementMapping[fieldUID],
          });
    case "theme":
    case "lp":
    case "concepts":
      return getPypElementLabel({
        value: valueKeys,
        type: pypElementMapping[fieldUID],
      });
    case "lois":
    case "goalList":
      return getNotPypInputListLabel({
        values: valueKeys,
        unitPlanFieldValue,
        isUnitPlan,
      });
    case "atls":
      return getBenchMarkValue({
        values: valueKeys,
        nodes: _.get(resolvedValue, "atls", []),
      });
    case "benchmarks":
      // HACK: In the community no unitplan subject reference is there
      if (!_.isEmpty(subjectIds) && !isCommunity) {
        const benchmarks = _.get(resolvedValue, "benchmarks", []);
        resolvedValue = {
          ...resolvedValue,
          benchmarks: _.filter(benchmarks, item =>
            _.includes(subjectIds, item.subject)
          ),
        };
      }

      return getBenchMarkValue({
        values: valueKeys,
        benchmarkSet,
        nodes: _.get(resolvedValue, "benchmarks", []),
      });
    case "voiceInstruction":
      return _.get(resolvedValue, "attachment", {});
    case "studentTemplate":
      resolvedValue = {
        ...resolvedValue,
        attachmentGroups: _.map(
          _.get(resolvedValue, "attachmentGroups", []),
          group => {
            return { ...group, isPost: true };
          }
        ),
      };
      return _.get(resolvedValue, "attachmentGroups", {});
    case "duration":
      return isUnitPlan
        ? valueKeys
          ? `${valueKeys}h`
          : "0h"
        : convertDurationToString(valueKeys);
    // case "assessmentType":
    //   return t && isLocalisedTemplate
    //     ? t(_.get(LE_ASSESSMENT_TYPE_MAPPING[valueKeys], "localeLabel", ""))
    //     : _.get(LE_ASSESSMENT_TYPE_MAPPING[valueKeys], "localeLabel", "");
    case "documentation":
    case "measureAssessing":
    case "evaluationType":
    case "assessmentType":
    case "studentUnderstandCheckList":
    case "stageOneNTwoConnection":
    case "size":
      return getValueFromLabelValuePair({
        values: valueKeys,
        fieldObj,
        fieldUID,
        t,
        isLocalisedTemplate,
      });
    default:
      //Need Review @kunal
      // Following condition is generic to handle all planner elements except MYP_EXPLORATION

      if (
        _.get(fieldObj, "config.type") == "PLANNER_ELEMENT" &&
        !_.includes(
          [
            "MYP_EXPLORATION",
            "DP_PEDAGOGICAL_APPROACH",
            "DP_DIFFERENTIATION_APPROACH",
          ],
          _.get(fieldObj, "config.plannerElementConfig.type", "")
        )
      ) {
        return plannerElementValue({
          fieldObj,
          subjectIds,
          resolvedValue,
          value: valueKeys,
          deriveSubjectIdsFromNodes,
        });
      } else {
        switch (_.get(fieldObj, "viewType", "")) {
          // Following condition is generic to handle MultiFieldComponent
          //E.g - inquiryQuestions in MYP
          case "MultiField": {
            const subFields = _.get(fieldObj, "subFields", []);

            //Iterating over subFields and calling getElementValue for each field
            return _.reduce(
              subFields,
              (result, field) => {
                const { key } = field;
                let subFieldValue = (valueKeys || {})[key];
                const subUnitPlanFieldValue = (unitPlanFieldValue || {})[key];
                subFieldValue = getElementValue({
                  valueKeys: subFieldValue,
                  fieldObj: field,
                  unitPlanFieldValue: subUnitPlanFieldValue,
                  isUnitPlan,
                });
                result[key] = subFieldValue;
                return result;
              },
              {}
            );
          }

          // Following condition is generic to handle listview component
          case "ListView":
            //Added condition wheather field is dependent on unitplan or not
            //E.g - LOIS,GOALS e.t.c

            //Need Review @kunal
            if (_.get(fieldObj, "config.isUnitPlanDependent", false)) {
              return getNotPypInputListLabel({
                values: valueKeys,
                unitPlanFieldValue,
                isUnitPlan,
              });
            } else if (
              !_.isEmpty(_.get(fieldObj, "config.options", [])) &&
              _.get(fieldObj, "type", "") == "CheckList"
            ) {
              return getOptionLabels({
                options: fieldObj.config.options,
                values: valueKeys,
              });
            } else {
              return getLists({
                values: _.filter(valueKeys, val => {
                  /*Handle special case where there is a selection in Input List (E.g MYP_EXPLORATION)
                   * In this case, you have to show only selected items
                   */
                  const isSelected =
                    _.isUndefined(val.isSelected) || val.isSelected;
                  return (
                    !_.isEmpty(_.trim(_.get(val, "value", ""))) && isSelected
                  );
                }),
              });
            }

          case "TextView": {
            if (!_.isEmpty(_.get(fieldObj.config, "options", []))) {
              switch (_.get(fieldObj, "type", "")) {
                case "RadioButtonList": {
                  //Handle the case where field options are passed from template using radioButtonList
                  return getValueFromLabelValuePair({
                    values: valueKeys,
                    fieldObj,
                    fieldUID,
                    t,
                    isLocalisedTemplate,
                  });
                }
                default: {
                  return valueKeys;
                }
              }
            }
            return shouldStripRichTextStyles && valueKeys != null
              ? getStyleStrippedText({
                  text: valueKeys,
                  type: "RICH_TEXT_EDITOR",
                })
              : valueKeys;
          }
          default:
            // Handle field values based on UI type.
            switch (_.get(fieldObj, "type", "")) {
              case "MultiSelect": {
                // Handle the case where field options are passed from template
                // in this UI type
                const options = _.get(fieldObj, "config.options", []);
                const selectedOptions = _.filter(options, option => {
                  return _.includes(valueKeys, option.value);
                });

                return _.map(selectedOptions, option => option.label);
              }

              case "NestedSelectComponent": {
                return plannerElementValue({
                  fieldObj,
                  subjectIds,
                  resolvedValue,
                  value: valueKeys,
                  deriveSubjectIdsFromNodes,
                });
              }

              default:
                return valueKeys;
            }
        }
      }
  }
};

const getOptionLabels = ({ options, values }) => {
  return _.map(values, value => {
    return _.get(
      _.find(options, option => option.value == value),
      "label",
      ""
    );
  });
};

export const plannerElementValue = ({
  value,
  fieldObj,
  subjectIds,
  resolvedValue,
  deriveSubjectIdsFromNodes = false,
}) => {
  const nodes = _.get(resolvedValue, "nodes", []);

  const config = _.get(fieldObj, "config", {});
  const dynamicSubject = _.get(fieldObj, "dynamicSubject", {});

  const plannerElementConfig = _.get(config, "plannerElementConfig", {}) || {};
  const { type } = plannerElementConfig;

  // Following condition is generic to handle nested element
  if (_.get(fieldObj, "viewType") == "Nestedview") {
    let associatedParentNodes = getUniqAssociatedParentsOfNodes({ nodes });
    let updatedNodes = nodes;
    if (_.isEmpty(subjectIds) && deriveSubjectIdsFromNodes) {
      subjectIds = _.map(
        _.filter(
          associatedParentNodes,
          item => _.get(item, "__typename") == "Subject"
        ),
        item => item.id
      );
    }

    //following condition is given to remove the subjects which are selected but removed afterwards
    if (_.includes(["MYP_LEARNING_STANDARD", "UBD_LEARNING_STANDARD"], type)) {
      const subjects = _.map(subjectIds, subjectId => {
        return { id: subjectId };
      });

      const updatedValues = _.filter(updatedNodes, updatedNode => {
        return (
          _.intersectionBy(subjects, updatedNode.associatedParents, "id")
            .length > 0
        );
      });

      updatedNodes = updatedValues;
    }
    if (
      _.includes(
        [
          "MYP_RELATED_CONCEPT",
          "MYP_LEARNING_STANDARD",
          "MYP_ATL",
          "UBD_LEARNING_STANDARD",
          "UBD_ATL",
        ],
        type
      )
    ) {
      let filteredSubjectIds = subjectIds;

      if (!_.isEmpty(dynamicSubject)) {
        filteredSubjectIds = [_.get(fieldObj, "dynamicSubject.id", "")];
      }

      if (!_.isEmpty(filteredSubjectIds)) {
        associatedParentNodes = _.filter(associatedParentNodes, node =>
          _.includes(filteredSubjectIds, node.id)
        );
      }
    }
    if (_.size(associatedParentNodes) > 1) {
      updatedNodes = mergeAssociatedParentsWithNodes({
        associatedParentNodes,
        nodes: updatedNodes,
      });
    }

    return generateNestedSelectedData({
      ...getPYPElementSetFromNodes({ nodes: updatedNodes }),
      value,
    });
  } else {
    return getPlannerElementLabel({ nodes, value });
  }
};

export const checkEmptyElementValue = ({
  fieldKey,
  data,
  unitPlanFields,
  isUnitPlan = false,
  fieldObj,
  ignoreUnitPlanSubjectCheck,
  isCommunity = false,
}) => {
  const allFields = _.unionBy(
    _.get(data, "allFields", []),
    _.get(data, "fields", []),
    "id"
  );
  const dataTofind = _.find(allFields, value => {
    return value.uid == fieldKey;
  });

  const responses = _.get(dataTofind, "responses.edges", []);

  const unitPlanFieldData = _.find(unitPlanFields, { uid: fieldKey });

  const unitPlanFieldValue = _.get(unitPlanFieldData, "value", "");

  const valueKeys = _.get(dataTofind, "value", "");

  const subjects = _.find(unitPlanFields, { uid: "subjects" });
  const subjectIds = _.get(subjects, "value", []);

  const resolvedValue = _.get(dataTofind, "resolvedMinimalTree", "");
  switch (fieldKey) {
    case "les":
    case "fmtAssessments":
    case "digAssessments":
    case "smtAssessments":
      return (
        _.get(data, "assessmentLibrary.assessmentCount", 0) > 0 ||
        _.get(data, "leLibrary.leCount", 0) > 0
      );
    case "relatedConcepts": {
      const subjectsToFind = _.get(data, "subjects.value", []);
      return (
        _.get(valueKeys, "length", 0) != 0 &&
        _.get(subjectsToFind, "length", 0) != 0
      );
    }
    case "resources":
      return _.get(data[fieldKey], "totalCount", 0) > 0;
    case "plannerResources":
      return _.get(data["organizationResources"], "length", 0) > 0;
    case "rubric":
    case "checklist":
    case "singlePointRubric":
    case "exemplar":
    case "anecdotal":
      return !_.isEmpty(_.get(data, "assessmentTool", null));
    case "conceptsQuestion":
      return true;
    case "actionQuestion":
      return true;
    case "studentAction":
      return true;
    // case "exemplar":
    //   return (
    //     !_.isEmpty(_.get(data, "assessmentTool.message", "")) ||
    //     !_.isEmpty(_.get(data, "assessmentTool.attachments", []))
    //   );
    // case "anecdotal":
    //   return !_.isEmpty(_.get(data, "assessmentTool.message", ""));
    case "benchmarks": {
      const benchmarksD0 = _.filter(
        _.get(resolvedValue, "benchmarks", []),
        benchmark => {
          return benchmark.depth == 0;
        }
      );

      if (ignoreUnitPlanSubjectCheck) {
        //TODO: hack for classroom LE - remove later
        return !_.isEmpty(benchmarksD0);
      }

      const subjects = _.find(unitPlanFields, { uid: "subjects" });

      const subjectValues = _.map(
        _.get(subjects, "resolvedMinimalTree.subjects", []),
        subject => {
          return { ...subject.benchmarkRootNode };
        }
      );

      // HACK: In the community no unitplan subject reference is there
      if (isCommunity) {
        return !_.isEmpty(benchmarksD0);
      } else {
        return !_.isEmpty(_.intersectionBy(benchmarksD0, subjectValues, "id"));
      }
    }
    default: {
      //Handled for Assessment Tools
      if (_.get(fieldObj, "type", "") == "AssessmentTool") {
        return !_.isEmpty(_.get(data, "assessmentTool", null));
      }

      if (_.get(fieldObj, "config.isMappedWithDetailsFields", false)) {
        const mappedDetailsFields = _.get(
          fieldObj,
          "config.mappedDetailsFields"
        );
        _.forEach(mappedDetailsFields, val => {
          const result = _.get(
            _.find(unitPlanFields, { uid: val }),
            "value",
            null
          );
          if (_.isEmpty(result)) return false;
        });
        return true;
      }
      const value = getElementValue({
        fieldUID: fieldKey,
        unitPlanFieldValue,
        valueKeys,
        isUnitPlan,
        resolvedValue,
        fieldObj,
        subjectIds,
      });
      if (_.get(fieldObj, "type", "") == "MultiField") {
        return !_.isEmpty(_.filter(value, val => !_.isEmpty(val)));
      }

      if (_.get(fieldObj, "type", "") == "IBReflection") {
        return !_.isEmpty(responses);
      }

      if (_.get(fieldObj, "type", "") === "MultiWindowTable") {
        return getMultiWindowTableCompletionStatus({
          fieldObj,
          valueKeys,
          subjectIds,
          allFields,
        });
      }

      if (_.isNumber(value)) {
        return true;
      } else if (_.isString(value)) {
        return !_.isEmpty(
          _.trim(extractContentFromHTML({ htmlString: value }))
        );
      } else {
        return !_.isEmpty(value);
      }
    }
  }
};

const getMultiWindowTableCompletionStatus = ({
  fieldObj,
  valueKeys,
  subjectIds,
  allFields,
}) => {
  return _.some(valueKeys, ({ tableRowsValues }) => {
    return _.some(tableRowsValues, ({ subFieldValues }) => {
      return _.some(subFieldValues, ({ values, id }) => {
        const subFieldConfig = _.find(
          _.get(fieldObj, "subFields", []),
          ({ key }) => key === id
        );

        const filteredBy = _.get(subFieldConfig, "config.filteredBy", []);

        let resolvedMinimalTree = {};

        _.forEach(filteredBy, fieldUid => {
          resolvedMinimalTree = _.get(
            _.find(allFields, ({ uid }) => uid == fieldUid),
            "resolvedMinimalTree",
            {}
          );
        });

        const availableValues = getElementValue({
          fieldObj: subFieldConfig,
          valueKeys: values,
          subjectIds,
          resolvedValue: resolvedMinimalTree,
        });

        return !_.isEmpty(availableValues);
      });
    });
  });
};

const getNotPypInputListLabel = ({
  values,
  unitPlanFieldValue,
  isUnitPlan,
}) => {
  if (isUnitPlan) {
    return _.filter(
      _.map(values, val => val.value),
      val => !!_.trim(val)
    );
  } else {
    return _.filter(
      _.map(values, value => {
        const valueObject = _.find(unitPlanFieldValue, val => val.id == value);
        return _.get(valueObject, "value", "");
      }),
      val => !!_.trim(val)
    );
  }
};

export const getResolvedOptimisticValue = ({
  fieldUID,
  params,
  value,
  resolvedValue,
  fieldTemplateObj,
}) => {
  const setKey = pypElementSetMapping[fieldUID];

  switch (fieldUID) {
    case "score":
      return {
        ...resolvedValue,
        isUsingScore: _.get(value, "isUsingScore", null),
        maxScore: _.get(value, "maxScore", null),
        category: !_.isEmpty(params) ? { ...params } : null,
      };
    case "benchmarks":
    case "atls":
      return {
        ...resolvedValue,
        [setKey]: _.uniqBy(
          [
            ..._.filter(
              _.get(resolvedValue, setKey, []),
              item => _.get(item, params.rootNodeKey, "") != params.rootNodeId
            ),
            ...(buildSlimTree({
              nodes: _.get(params, "nodes", []),
              selIds: _.filter(value, id =>
                _.includes(
                  _.map(_.get(params, "nodes", []), item => item.id),
                  id
                )
              ),
            }) || []),
          ],
          "id"
        ),
      };

    case "concepts":
    case "action":
    case "lp":
    case "theme":
    case "subjects":
    case "relatedConcepts":
    case "subjectGroup":
      return {
        ...resolvedValue,
        [setKey]: _.map(
          _.filter(_.get(params, "nodes", []), item =>
            _.includes(value, item.node.id)
          ),
          nodeItem => nodeItem.node
        ),
      };
    case "voiceInstruction":
    case "videoAttachment":
      return {
        ...resolvedValue,
        attachment: value,
      };
    // case "studentTemplate":
    //   return {
    //     ...resolvedValue,
    //     attachmentGroups: value
    //   };

    case "tags": {
      let tags = [];
      _.forEach(value, id => {
        const item = _.find(params, item => item.id == id);
        if (!_.isEmpty(item)) {
          tags = [...tags, item];
        }
      });
      return { ...resolvedValue, tags };
    }
    case "subjectLevel": {
      let genericTags = [];
      _.forEach(value, id => {
        const item = _.find(
          _.get(params, "nodes", []),
          item => item.value == id
        );
        if (!_.isEmpty(item)) {
          genericTags = [
            ...genericTags,
            { id: item.value, label: item.label, __typename: "GenericTag" },
          ];
        }
      });
      return { ...resolvedValue, genericTags };
    }
    case "stimulus": {
      const attachmentValue = _.get(params, "value", {});
      return {
        ...resolvedValue,
        attachments: !_.isEmpty(value) ? attachmentValue : [],
      };
    }
    default: {
      const type = _.get(fieldTemplateObj, "config.type", "");

      //Handled Planner Elements
      if (type == "PLANNER_ELEMENT") {
        //Creating nodes using value and combining old nodes and new nodes
        const updatedNodes =
          buildSlimTree({
            nodes: _.uniqBy(
              [
                ..._.get(resolvedValue, "nodes", []),
                ..._.get(params, "nodes", []),
              ],
              "id"
            ),
            selIds: !_.isArray(value) && value ? [value] : value,
          }) || [];

        //Sorting nodes by depth & displaySequence
        const sortedNodes = _.sortBy(updatedNodes, [
          "depth",
          "displaySequence",
        ]);

        return {
          ...resolvedValue,
          nodes: sortedNodes,
        };
      }
      return resolvedValue;
    }
  }
};

const getBenchMarkValue = ({ values, benchmarkSet, nodes }) => {
  return benchmarkSet
    ? generateNestedSelectedData({
        nodes: _.get(benchmarkSet, "nodes", []),
        rootNodes: _.get(benchmarkSet, "rootNodes", []),
        value: values,
      })
    : generateNestedSelectedData({
        ...getPYPElementSetFromNodes({ nodes }),
        value: values,
      });
};

const getValueFromLabelValuePair = ({
  values,
  fieldObj,
  fieldUID,
  t,
  isLocalisedTemplate = true,
}) => {
  const options = _.get(fieldObj, "config.options", []);

  if (t && isLocalisedTemplate) {
    switch (fieldUID) {
      case "measureAssessing":
        if (values === "") {
          return values ? t("common:none") : null;
        }
        return values
          ? t(_.get(_.find(ASSESSMENT_TOOLS, { type: values }), "locale", ""))
          : null;
      case "evaluationType":
        if (_.isArray(values)) {
          return _.map(values, val => {
            return val
              ? t(_.get(_.find(EVALUATION_METHOD, { type: val }), "locale", ""))
              : null;
          });
        }
        return values
          ? t(_.get(_.find(EVALUATION_METHOD, { type: values }), "locale", ""))
          : null;
      case "size":
        return values
          ? t(_.get(_.find(SIZE, { type: values }), "locale", ""))
          : null;
      case "assessmentType":
        return t(_.get(LE_ASSESSMENT_TYPE_MAPPING[values], "localeLabel", ""));
      default:
        if (_.isArray(values)) {
          return _.map(values, val => {
            return val
              ? _.get(_.find(options, { value: val }), "label", "")
              : null;
          });
        } else {
          return values
            ? _.get(_.find(options, { value: values }), "label", "")
            : null;
        }
    }
  } else {
    if (_.isArray(values)) {
      return _.map(values, val => {
        return val ? _.get(_.find(options, { value: val }), "label", "") : null;
      });
    } else {
      return values
        ? _.get(_.find(options, { value: values }), "label", "")
        : null;
    }
  }
};

const getLists = ({ values }) => {
  return _.map(values, val => (typeof val == "object" ? val.value : val));
};

export const getElementData = ({
  elements,
  unitPlanFields,
  benchmarkSet,
  field_list,
  isPost = false,
  deriveSubjectIdsFromNodes = false,
}) => {
  const elementData = {};
  _.forEach(elements, item => {
    const { fieldUID, value: valueKeys, resolvedMinimalTree } = item;
    const fieldKeys = _.split(fieldUID, "_");

    elementData[fieldUID] = getElementValue({
      fieldUID,
      valueKeys,
      isPost,
      deriveSubjectIdsFromNodes,
      unitPlanFieldValue: _.get(
        _.find(unitPlanFields, { uid: fieldUID }),
        "value",
        ""
      ),
      fieldObj: _.get(field_list, `${_.get(fieldKeys, "0")}`, {}),
      benchmarkSet,
      resolvedValue: !_.isEmpty(_.find(unitPlanFields, { uid: fieldUID }))
        ? _.get(
            _.find(unitPlanFields, { uid: fieldUID }),
            "resolvedMinimalTree",
            ""
          )
        : resolvedMinimalTree,
    });
  });

  return elementData;
};

export const getUnitPlanElementData = ({
  fields,
  benchmarkSet,
  fieldValues,
}) => {
  const elementData = {};

  _.forEach(fields, fieldKey => {
    const valueKeys = _.get(
      _.find(fieldValues, { uid: fieldKey }),
      "value",
      ""
    );

    const resolvedValue = _.get(
      _.find(fieldValues, { uid: fieldKey }),
      "resolvedMinimalTree",
      ""
    );

    let value = getElementValue({
      fieldUID: fieldKey,
      valueKeys,
      isUnitPlan: true,
      resolvedValue,
    });

    //TODO: Temp remove this condition and replace with checkEmptyElementValue
    if (!_.isNumber(value)) {
      _.isEmpty(value) ? (value = null) : null;
    }

    elementData[fieldKey] = value;
  });

  return elementData;
};

// THUNKS

export const getWebLinkDetails = url => {
  return async (dispatch, getState) => {
    let result = null;
    try {
      await client.mutate({
        mutation: scrapeUrlMutation,
        variables: {
          url: url,
        },
        update: (
          cache,
          {
            data: {
              platform: { scrapeUrl },
            },
          }
        ) => {
          if (scrapeUrl != null) {
            const updateObject = {
              attachments: [
                {
                  type: "LINK",
                  name: scrapeUrl.title,
                  description: scrapeUrl.description,
                  url: scrapeUrl.url,
                  mimeType: null,
                  thumbUrl: scrapeUrl.image
                    ? scrapeUrl.image
                    : DEFAULT_LINK_THUMB_URL,
                },
              ],
            };
            result = updateObject;
          } else {
            dispatch(
              setToastMsg({
                msg: "toastMsgs:web_link_not_valid",
                type: "alert",
                position: "toast-top-center",
              })
            );
          }
        },
      });

      return result;
    } catch (error) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-top-center",
        })
      );
      return result;
    }
  };
};

export const downloadFromUrl = ({
  downloadUrl,
  fileUploadId,
  onLoaded,
  onContentLoadFailed,
  headers,
  size,
  name,
  startProgress = 1,
  parentType,
  parentId,
  attachment,
  mimeType,
  shouldAskForSave = false,
  showErrorMessage = true,
  showProgress = true,
}) => {
  return async (dispatch, getState) => {
    if (!fileUploadId) {
      fileUploadId = _.get(attachment, "id", generateRandomId());
    }

    showProgress &&
      dispatch(
        setProgress({
          id: fileUploadId,
          progress: 0,
          attachment: attachment,
          parentType,
          parentId,
          status: "DOWNLOAD",
        })
      );
    if (!name) {
      name = _.get(attachment, "name", "") || "";
    }

    const { type, title, url } = attachment || {};

    const isBlobUrl = _.includes(url, "blob");

    if (isBlobUrl) {
      const { data: fileBlob } = await axios.get(url, {
        responseType: "blob",
      });

      if (shouldAskForSave) {
        saveAs(fileBlob, name);
      }

      return;
    }

    if (
      type === "NOTE" ||
      type === "RESPONSE" ||
      type === "LINK" ||
      type === "WORKBOOK"
    ) {
      showProgress &&
        dispatch(
          setProgress({
            id: fileUploadId,
            progress: startProgress,
          })
        );
      let fileExtension, fileBlob;
      if (type === "NOTE" || type === "RESPONSE") {
        fileExtension = "txt";

        if (!name && title) {
          const trimmedTitle = title.split(" ").join("");
          name = trimmedTitle.substring(0, 8);
        }
        fileBlob = new Blob([title], {
          type: "text/plain",
        });
      } else {
        const attachmentUrl =
          type === "WORKBOOK"
            ? _.get(attachment, "metadata.publicUrl", "")
            : url;
        fileExtension = "html";
        fileBlob = new Blob(
          [
            `<html>
        <head>
            <meta http-equiv="Refresh" content="0;url=${attachmentUrl}" />
        </head>
        </html>`,
          ],
          {
            type: "text/html",
          }
        );
      }

      if (onLoaded) {
        onLoaded(fileBlob);
      } else {
        dispatch(removeProgressElement({ id: fileUploadId }));
      }

      if (shouldAskForSave) {
        if (_.isEmpty(name)) {
          name = "toddle_" + moment().format("DD-MM-YY_h_mm");
        }
        name = `${name}.${fileExtension}`;
        saveAs(fileBlob, name);
      }
    } else {
      if (!size) {
        size = _.get(attachment, "metadata.size", 0);
      }
      if (!mimeType) {
        mimeType = _.get(attachment, "mimeType", "");
      }
      if (!downloadUrl) {
        downloadUrl = _.get(attachment, "url", "");
      }
      const tempUrl = _.replace(downloadUrl, CLOUD_URL, "");

      let extension = "";
      if (tempUrl.includes(".")) {
        extension = _.last(_.split(tempUrl, "."));
      }

      if (!extension && mimeType !== "application/octet-stream") {
        const mimeObj = _.get(MimeDb, mimeType, {});
        if (mimeObj.extensions) {
          extension = mimeObj.extensions[0];
        } else if (mimeType) {
          mimeType.split("/")[1];
        }
      }

      //Append extension in name to resolve windows issue
      //Check wheather there is already an extension in name
      if (!!extension && _.last(_.split(name, ".")) != extension) {
        name = `${name}.${extension}`;
      }
      //console.log("download name:", name);

      // If mimeType was application/octet-stream
      // then check whether we have fileExtension in url or not
      // if not then don't put any file extension
      // if (mimeType === "application/octet-stream" && !tempUrl.includes(".")) {
      //   name = _.first(_.split(name, "."));
      // }

      if (!new RegExp(/\?.+=.*/g).test(downloadUrl)) {
        downloadUrl = `${downloadUrl}?_=${new Date().getTime()}`;
      } else {
        downloadUrl = `${downloadUrl}&_=${new Date().getTime()}`;
      }

      var xhr = new XMLHttpRequest();
      // Handled the cors issue with cors-anywhere link while downloading a file
      xhr.open("GET", `${downloadUrl}`, true);

      // Set headers
      _.forEach(headers, header => {
        xhr.setRequestHeader(header.label, header.value);
      });

      xhr.responseType = "blob";

      if (size) {
        const total = size;
        xhr.onprogress = event => {
          showProgress &&
            dispatch(
              setProgress({
                id: fileUploadId,
                progress: ((event.loaded / total) * startProgress).toFixed(0),
              })
            );
        };
      } else {
        showProgress &&
          dispatch(
            setProgress({
              id: fileUploadId,
              progress: startProgress,
            })
          );
      }

      xhr.onload = () => {
        const fileBlob = xhr.response;
        fileBlob["name"] = name;

        if (xhr.status == 200) {
          if (onLoaded) {
            onLoaded(fileBlob);
          } else {
            dispatch(removeProgressElement({ id: fileUploadId }));
          }
          if (shouldAskForSave) {
            saveAs(fileBlob, name);
          }
        } else {
          dispatch(removeProgressElement({ id: fileUploadId }));
          if (showErrorMessage) {
            dispatch(setToastMsg("toastMsgs:unable_to_download"));
          }
          if (onContentLoadFailed) {
            onContentLoadFailed({ errorMessage: `xhr status is not 200` });
          }
        }
      };

      xhr.onerror = e => {
        //console.log("error", e);
        if (onContentLoadFailed) {
          onContentLoadFailed({ errorMessage: `Failed to load xhr` });
        }
        if (showErrorMessage) {
          dispatch(setToastMsg("toastMsgs:unable_to_download"));
        }
        dispatch(removeProgressElement({ id: fileUploadId }));
      };

      xhr.send();
      showProgress &&
        dispatch(
          setProgress({
            id: fileUploadId,
            cancelToken: () => {
              xhr.abort();
              if (xhr.status == 0) {
                if (onContentLoadFailed) {
                  onContentLoadFailed({
                    isCancelled: true,
                    errorMessage: `Cancelled Download`,
                  });
                }
                dispatch(setToastMsg("toastMsgs:download_cancelled"));
                dispatch(removeProgressElement({ id: fileUploadId }));
              }
            },
          })
        );
    }
  };
};

export const downloadAttachmentZip = ({
  attachmentsObjects,
  isEvidence = false,
  zipFileName = "",
  showAttachmentName = false,
}) => {
  return (dispatch, getState) => {
    const lengthOfAttachmentsObjects = _.size(attachmentsObjects);
    const zip = new JSZip();
    if (lengthOfAttachmentsObjects > 1) {
      const foldersObj = {};
      let folderCount = lengthOfAttachmentsObjects;
      dispatch(
        setProgress({
          id: isEvidence ? "allEvidences" : "allSubmissions",
          attachment: { name: isEvidence ? "evidences" : "submissions" },
          status: "DOWNLOAD",
        })
      );
      _.map(attachmentsObjects, ({ folderName, attachments }, folderId) => {
        if (!_.isEmpty(attachments)) {
          foldersObj[folderId] = zip.folder(`${folderName}_${folderId}`);
        } else {
          folderCount--;
        }
        let attachmentCount = _.get(attachments, "length", 0);

        _.map(attachments, attachment => {
          if (
            attachment.type === "NOTE" ||
            attachment.type === "RESPONSE" ||
            attachment.type === "LINK" ||
            attachment.type === "WORKBOOK"
          ) {
            let fileExtension, fileBlob;
            if (attachment.type === "NOTE" || attachment.type === "RESPONSE") {
              fileExtension = "txt";
              fileBlob = attachment.title;
            } else {
              const attachmentUrl =
                attachment.type === "WORKBOOK"
                  ? _.get(attachment, "metadata.publicUrl", "")
                  : attachment.url;
              fileExtension = "html";
              fileBlob = `<html>
                  <head>
                      <meta http-equiv="Refresh" content="0;url=${attachmentUrl}" />
                  </head>
                  </html>`;
            }
            let fileName = showAttachmentName
              ? _.get(attachment, "name", "sample")
              : `${folderName}_${attachments.length - attachmentCount + 1}`;
            if (attachment.type === "RESPONSE") fileName = "response";
            foldersObj[folderId].file(`${fileName}.${fileExtension}`, fileBlob);
            attachmentCount--;
            if (attachmentCount == 0) folderCount--;
            if (folderCount == 0) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(
                  content,
                  zipFileName
                    ? zipFileName
                    : isEvidence
                    ? `Evidences.zip`
                    : "submissions.zip"
                );
              });
              dispatch(
                removeProgressElement({
                  id: zipFileName
                    ? zipFileName
                    : isEvidence
                    ? "allEvidences"
                    : "allSubmissions",
                })
              );
            }
          } else {
            dispatch(
              downloadFromUrl({
                attachment,
                showProgress: false,
                onLoaded: fileBlob => {
                  const fileExtension = getFileExtensionFromMimeType({
                    mimeType: fileBlob.type,
                  });
                  foldersObj[folderId].file(
                    showAttachmentName
                      ? `${_.get(
                          attachment,
                          "name",
                          "sample"
                        )}.${fileExtension}`
                      : `${folderName}_${
                          attachments.length - attachmentCount + 1
                        }.${fileExtension}`,
                    fileBlob
                  );
                  attachmentCount--;
                  if (attachmentCount == 0) folderCount--;
                  if (folderCount == 0) {
                    zip
                      .generateAsync({ type: "blob" })
                      .then(function (content) {
                        saveAs(
                          content,
                          zipFileName
                            ? zipFileName
                            : isEvidence
                            ? `Evidences.zip`
                            : "submissions.zip"
                        );
                      });
                    dispatch(
                      removeProgressElement({
                        id: zipFileName
                          ? zipFileName
                          : isEvidence
                          ? "allEvidences"
                          : "allSubmissions",
                      })
                    );
                  }
                },
                onContentLoadFailed: async ({ isCancelled }) => {},
              })
            );
          }
        });
      });
    } else {
      const attachmentsObjData = _.first(
        _.map(attachmentsObjects, (attachmentObj, folderId) => {
          attachmentObj["folderId"] = folderId;
          return attachmentObj;
        })
      );
      const attachments = _.get(attachmentsObjData, "attachments", []);
      const folderName = _.get(attachmentsObjData, "folderName", "");
      const folderId = _.get(attachmentsObjData, "folderId", "");

      let attachmentCount = _.get(attachments, "length", 0);

      dispatch(
        setProgress({
          id: zipFileName
            ? zipFileName
            : `${folderName}_${folderId}'s_${
                isEvidence ? "evidence" : "submission"
              }`,
          attachment: {
            name: zipFileName
              ? zipFileName
              : `${folderName}_${folderId}'s_${
                  isEvidence ? "evidence" : "submission"
                }`,
          },
          status: "DOWNLOAD",
        })
      );
      _.map(attachments, attachment => {
        if (
          attachment.type === "NOTE" ||
          attachment.type === "RESPONSE" ||
          attachment.type === "LINK" ||
          attachment.type === "WORKBOOK"
        ) {
          let fileExtension, fileBlob;
          if (attachment.type === "NOTE" || attachment.type === "RESPONSE") {
            fileExtension = "txt";
            fileBlob = attachment.title;
          } else {
            const attachmentUrl =
              attachment.type === "WORKBOOK"
                ? _.get(attachment, "metadata.publicUrl", "")
                : attachment.url;
            fileExtension = "html";
            fileBlob = `<html>
                  <head>
                      <meta http-equiv="Refresh" content="0;url=${attachmentUrl}" />
                  </head>
                  </html>`;
          }
          let fileName = showAttachmentName
            ? _.get(attachment, "name", "sample")
            : `${folderName}_${attachments.length - attachmentCount + 1}`;
          if (attachment.type === "RESPONSE") fileName = "response";
          zip.file(`${fileName}.${fileExtension}`, fileBlob);
          attachmentCount--;
          if (attachmentCount == 0) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(
                content,
                zipFileName
                  ? zipFileName
                  : isEvidence
                  ? `${folderName}_evidence.zip`
                  : `${folderName}_${folderId}'s_submission.zip`
              );
            });
            dispatch(
              removeProgressElement({
                id: zipFileName
                  ? zipFileName
                  : `${folderName}_${folderId}'s_${
                      isEvidence ? "evidence" : "submission"
                    }`,
              })
            );
          }
        } else {
          dispatch(
            downloadFromUrl({
              attachment,
              showProgress: false,
              onLoaded: fileBlob => {
                const fileExtension = getFileExtensionFromMimeType({
                  mimeType: fileBlob.type,
                });
                zip.file(
                  showAttachmentName
                    ? `${_.get(attachment, "name", "sample")}.${fileExtension}`
                    : `${folderName}_${
                        attachments.length - attachmentCount + 1
                      }.${fileExtension}`,
                  fileBlob
                );
                attachmentCount--;
                if (attachmentCount == 0) {
                  zip.generateAsync({ type: "blob" }).then(function (content) {
                    saveAs(
                      content,
                      zipFileName
                        ? zipFileName
                        : isEvidence
                        ? `${folderName}_evidence.zip`
                        : `${folderName}_${folderId}'s_submission.zip`
                    );
                  });
                  dispatch(
                    removeProgressElement({
                      id: zipFileName
                        ? zipFileName
                        : `${folderName}_${folderId}'s_${
                            isEvidence ? "evidence" : "submission"
                          }`,
                    })
                  );
                }
              },
              onContentLoadFailed: async ({ isCancelled }) => {},
            })
          );
        }
      });
    }
  };
};

export const exceededSizeError = ({ maxSize } = {}) => {
  return (dispatch, getState) => {
    const sizeString = formatBytes({ bytes: maxSize });
    dispatch(
      setToastMsg({
        msg: `toastMsgs:attachment_size_exceeds_msg_with_label`,
        locale_params: [{ key: "label", value: sizeString, isPlainText: true }],
        type: "alert",
        position: "toast-top-center",
      })
    );
  };
};

export const getCriteriaSetOfPypType = ({
  type,
  parentId = null,
  grades = null,
  levelId = null,
  subType = null,
}) => {
  return (dispatch, getState) => {
    const orgId = getState().login.userInfo.org_id;
    const ratingLevels = _.get(
      getOrganizationConstantsFromCache(orgId),
      "ratingLevels",
      []
    );

    const academicCriteriaSets = _.get(
      getOrganizationConstantsFromCache(orgId),
      "academicCriteriaSets",
      []
    );

    let filteredRatings = _.filter(ratingLevels, rating => {
      return _.isArray(type)
        ? _.includes(type, rating.ibPYPElementType)
        : rating.ibPYPElementType == type;
    });

    if (parentId) {
      filteredRatings = _.filter(filteredRatings, rating => {
        return rating.ibPYPParentElementId == parentId;
      });
    }

    if (levelId) {
      filteredRatings = _.filter(filteredRatings, rating => {
        return rating.levelId == levelId;
      });
    }

    if (subType) {
      filteredRatings = _.filter(filteredRatings, rating => {
        return rating.subType == subType;
      });
    }

    let criteriaSets = _.map(
      _.reduce(
        filteredRatings,
        (result, rating) => {
          result = _.unionBy(result, rating.academicCriteriaSets, "id");
          return result;
        },
        []
      ),
      set => getCriteriaSetDetailsFromCache(set.id)
    );

    criteriaSets = _.filter(criteriaSets, set => {
      return _.findIndex(academicCriteriaSets, { id: set.id }) >= 0;
    });

    if (grades) {
      criteriaSets = _.filter(
        criteriaSets,
        set =>
          !_.isEmpty(
            _.intersectionWith(
              set.grades,
              grades,
              (setGrade, grade) => setGrade.id == grade
            )
          )
      );
    }
    return criteriaSets;
  };
};

export const checkCriteriaSetOfPypTypeInLevels = ({
  type,
  parentId = null,
  levelId,
  setId,
}) => {
  return (dispatch, getState) => {
    const orgId = getState().login.userInfo.org_id;
    const ratingLevels = _.get(
      getOrganizationConstantsFromCache(orgId),
      "ratingLevels",
      []
    );

    return checkCriteriaSetOfPypTypeInLevelsLocalMemoize({
      type,
      parentId,
      levelId,
      setId,
      ratingLevels,
    });

    // const filteredRatings = _.filter(ratingLevels, level => {
    //   if (parentId) {
    //     return (
    //       level.ibPYPElementType == type &&
    //       level.ibPYPParentElementId == parentId &&
    //       level.levelId == levelId
    //     );
    //   } else {
    //     return level.ibPYPElementType == type && level.levelId == levelId;
    //   }
    // });

    // let criteriaSets = _.map(
    //   _.reduce(
    //     filteredRatings,
    //     (result, rating) => {
    //       result = _.unionBy(result, rating.academicCriteriaSets, "id");
    //       return result;
    //     },
    //     []
    //   ),
    //   set => getCriteriaSetDetailsFromCache(set.id)
    // );

    // return _.findIndex(criteriaSets, { id: setId }) >= 0;
  };
};

export const checkCriteriaSetOfPypTypeInLevelsLocalMemoize = _.memoize(
  params => checkCriteriaSetOfPypTypeInLevelsLocal(params),
  params => JSON.stringify(params)
);

export const checkCriteriaSetOfPypTypeInLevelsLocal = ({
  type,
  parentId = null,
  levelId,
  setId,
  ratingLevels,
}) => {
  const filteredRatings = _.filter(ratingLevels, level => {
    if (parentId) {
      return (
        level.ibPYPElementType == type &&
        level.ibPYPParentElementId == parentId &&
        level.levelId == levelId
      );
    } else {
      return level.ibPYPElementType == type && level.levelId == levelId;
    }
  });

  const criteriaSets = _.map(
    _.reduce(
      filteredRatings,
      (result, rating) => {
        result = _.unionBy(result, rating.academicCriteriaSets, "id");
        return result;
      },
      []
    ),
    set => getCriteriaSetDetailsFromCache(set.id)
  );

  return _.findIndex(criteriaSets, { id: setId }) >= 0;
};

export const filteredCriteriaSets = ({
  type,
  levelId,
  rootNodeId,
  node,
  getCriteriaSetOfPypType,
}) => {
  let filteredCriteriaSet = getCriteriaSetOfPypType({
    type,
    levelId,
    parentId: rootNodeId,
  });
  if (!_.isEmpty(node.children)) {
    let childFilteredCriteriaSet = [];
    _.forEach(node.children, childNode => {
      childFilteredCriteriaSet = _.unionBy(
        childFilteredCriteriaSet,
        filteredCriteriaSets({
          type: childNode.ibPlannerElementType,
          levelId: childNode.levelId,
          rootNodeId,
          node: childNode,
          getCriteriaSetOfPypType,
        }),
        "id"
      );
    });
    filteredCriteriaSet = _.unionBy(
      filteredCriteriaSet,
      childFilteredCriteriaSet,
      "id"
    );
  }
  return filteredCriteriaSet;
};

export const filteredCriteriaSetsMemoized = _.memoize(
  params => filteredCriteriaSets(params),
  params => JSON.stringify(params)
);

export const getCriteriaSetBasedOnPlannerElementType = ({
  type,
  parentId,
  getCriteriaSetOfPypType,
}) => {
  switch (type) {
    case ELEMENT_TYPE_UBD_LEARNING_STANDARD: {
      return getCriteriaSetOfPypType({
        type: [ELEMENT_TYPE_UBD_LEARNING_STANDARD, ELEMENT_TYPE_UBD_SUBJECT],
        parentId: parentId,
      });
    }
    default:
      return getCriteriaSetOfPypType({
        type,
        parentId: parentId,
      });
  }
};

export const markCommentRead = ({
  nodeId,
  type,
  parentType,
  isCommunity,
  commentsKey,
  isCommentV2,
}) => {
  return async (dispatch, getState) => {
    const unreadCountKey = isCommentV2 ? "unreadCountV2" : "unreadCount";
    const userInfo = getState().login.userInfo;
    if (!getState().login.userLoggedIn) {
      return;
    }
    const nodeDetails =
      getCommentsOfNodeFromCache({ nodeId, type, isCommunity }) || {};

    const updatedNodeDetails = update(nodeDetails, {
      [commentsKey]: { [unreadCountKey]: { $set: 0 } },
    });
    writeCommentNodeFragment({
      nodeId,
      parentType,
      type,
      data: updatedNodeDetails,
    });

    const mutationVariables = isCommentV2
      ? {
          id: nodeId,
          entityType: parentType,
        }
      : {
          parentId: nodeId,
          parentType,
          userId: userInfo.id,
        };

    try {
      await client.mutate({
        mutation: isCommentV2
          ? updateEntityIsReadMutation
          : markCommentsReadForFieldMutation,
        variables: mutationVariables,
        update: (
          cache,
          {
            data: {
              platform: { markCommentsReadForParent },
            },
          }
        ) => {
          if (markCommentsReadForParent) {
            // writeCommentNodeFragment({
            //   nodeId,
            //   type,
            //   data: updatedNodeDetails
            // });
          }
        },
      });
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      // throw e;
    }
  };
};

export const updateBasicUserDetails = (input = {}, others = {}) => {
  return async (dispatch, getState) => {
    let userInfo = getState().login.userInfo;
    if (input.portalType)
      userInfo = getUserInfo({ portalType: input.portalType });
    const userId = userInfo.id;
    const userEntityType = userInfo.userEntityType;
    const userDetails = getUserBasicDetailsFromCache({
      id: userId,
      type: userEntityType,
    });
    writeUserBasicDetailsFromCache({
      id: userId,
      type: userEntityType,
      data: { ...userDetails, ...input },
    });
    try {
      await client.mutate({
        mutation: updateBasicUserDetailsMutation,
        variables: {
          id: userId,
          ...input,
          ...others,
        },
      });
    } catch (e) {
      writeUserBasicDetailsFromCache({
        id: userId,
        type: userEntityType,
        data: userDetails,
      });
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const createComment = ({
  nodeId,
  type,
  parentType,
  collaborators = [],
  commentsKey,
  parentMessageId,
  message,
  itemType,
  entityName,
  replyItemId,
} = {}) => {
  return async (dispatch, getState) => {
    const isCommunity = getState().login.activeTab == "community";
    const userInfo = _.get(getState(), "login.userInfo", {});
    let schoolTenures = [];

    if (isCommunity) {
      const userData = getCommunityUserDetailsFromCache({
        id: userInfo.id,
      });
      schoolTenures = _.get(userData, "schoolTenures", []);
    }

    const nodeDetails = getCommentsOfNodeFromCache({
      nodeId,
      type,
      isCommunity,
    });

    const commentData = getState().app_services.createComment[nodeId] || {};
    const label = (itemType === "REPLY" ? message : commentData.label) || "";
    const attachments = _.get(commentData, "attachments", []);
    const isCreateEmpty = !_.trim(label) && _.isEmpty(attachments);

    // Reset local comment
    if (itemType !== "REPLY")
      dispatch(
        updateLocalComment({
          id: nodeId,
          commentData: { label: "", attachments: [] },
        })
      );

    if (isCreateEmpty) {
      return;
    }

    const variables = {
      parentId: nodeId,
      parentType: parentType,
      label: _.trim(label),
      subEntityType: "COMMENT",
    };

    if (itemType === "REPLY") {
      variables["parentMessageId"] = replyItemId
        ? replyItemId
        : parentMessageId;
    }

    const taggedUsers = getTaggedUserIds({
      comment: label,
      collaborators,
    });

    if (!_.isEmpty(taggedUsers)) {
      variables["taggedUsers"] = taggedUsers;
    }

    if (!_.isEmpty(attachments)) {
      variables["attachments"] = OmitFromArrayValues({
        arr: attachments,
        omitKeys: ["id", ...ATTACHMENT_OMIT_ATTRIBUTES],
      });
    }

    const comments = _.get(nodeDetails, `${commentsKey}.edges`, []);
    const newComment = {
      id: generateRandomId(),
      isDeleted: false,
      label: label.trim(),
      itemType: "ATTACHMENTS",
      type: "NORMAL",
      item: {
        ...ATTACHMENT_CREATE_ATTRIBUTES,
        id: generateRandomId(),
        attachments: _.map(attachments, attachment => ({
          parentType: "MESSAGE_ITEM",
          streamUrl: null,
          createdBy: null,
          ...attachment,
        })),
        __typename: "AttachmentSet",
      },
      isRead: false,
      isPin: false,
      createdBy: {
        ...getUserBasicDetailsFromCache({
          id: userInfo.id,
          type: userInfo.userEntityType,
        }),
        type: userInfo.user_type,
        schoolTenures,
      },
      createdAt: moment().toISOString(),
      messageReplies: {
        edges: [],
        __typename: "MessageRepliesConnection",
      },
      similarityReport: null,
      __typename: "Message",
    };
    try {
      const result = await client.mutate({
        mutation: createMessageMutation,
        variables,
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            __typename: "PlatformMutations",
            createMessage: newComment,
          },
        },
        update: (
          cache,
          {
            data: {
              platform: { createMessage },
            },
          }
        ) => {
          let updatedComments;

          if (itemType === "REPLY") {
            updatedComments = _.map(comments, comment => {
              const { node } = comment;
              if (node.id === parentMessageId) {
                const messageReplies = _.get(node, "messageReplies.edges", []);

                return {
                  __typename: "ConversationMessageEdge",
                  node: {
                    ...node,
                    messageReplies: {
                      edges: [
                        {
                          node: createMessage,
                          __typename: "MessageRepliesEdge",
                        },
                        ...messageReplies,
                      ],
                      __typename: "MessageRepliesConnection",
                    },
                  },
                };
              }
              return comment;
            });
          } else {
            updatedComments = [
              {
                node: createMessage,
                __typename: "ConversationMessageEdge",
              },
              ...comments,
            ];
          }
          const commentUsersNode = {
            ...nodeDetails[commentsKey],
            edges: updatedComments,
            totalCount: nodeDetails[commentsKey].totalCount + 1,
          };
          const data = {
            ...nodeDetails,
            [commentsKey]: commentUsersNode,
          };
          setTimeout(() => {
            writeCommentNodeFragment({
              nodeId,
              data,
              parentType,
              type,
            });
          });
        },
      });

      if (isCommunity)
        EventTracker.recordEvent({
          eventName: itemType === "REPLY" ? "Comment reply" : "Comment created",
          eventData: {
            source: "community",
            entity_id: nodeId,
            entity_type: type,
            entity_name: entityName,
          },
        });

      return _.get(result, "data.platform.createMessage.id");
    } catch (e) {
      //console.log(e);
      setTimeout(() => {
        writeCommentNodeFragment({
          nodeId,
          parentType,
          type,
          data: nodeDetails,
        });
      });
      // Set back the comment data to box
      dispatch(
        updateLocalComment({
          id: nodeId,
          commentData: { label, attachments },
        })
      );
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
    //await dispatch(markCommentRead({ field_id:nodeId }));
  };
};

export const updateComment = ({
  comment_id,
  comment,
  commentDetails,
  collaborators = [],
  attachments = [],
  commentsKey,
}) => {
  return async (dispatch, getState) => {
    const label = _.trim(comment);
    writeMessageFragment({
      nodeId: comment_id,
      data: {
        ...commentDetails,
        item: commentDetails.item
          ? {
              ...commentDetails.item,
              attachments,
            }
          : null,
        label,
      },
    });

    const variables = {
      id: comment_id,
      label,
    };

    const taggedUsers = getTaggedUserIds({
      comment: label,
      collaborators,
    });
    if (!_.isEmpty(taggedUsers)) {
      variables["taggedUsers"] = taggedUsers;
    }
    if (!_.isEmpty(attachments)) {
      variables["attachments"] = OmitFromArrayValues({
        arr: attachments,
        omitKeys: ["id", ...ATTACHMENT_OMIT_ATTRIBUTES],
      });
    } else {
      variables["attachments"] = [];
    }
    try {
      await client.mutate({
        mutation: updateMessageMutation,
        variables,
      });
    } catch (e) {
      writeMessageFragment({
        nodeId: comment_id,
        data: commentDetails,
      });
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

export const deleteComment = ({
  nodeId,
  comment_id,
  type,
  parentType,
  commentsKey,
  itemType,
  parentMessageId,
  entityName,
}) => {
  return async (dispatch, getState) => {
    const isCommunity = getState().login.activeTab == "community";
    const nodeDetails = getCommentsOfNodeFromCache({
      nodeId,
      type,
      isCommunity,
    });
    const comments = nodeDetails[commentsKey].edges;
    let updatedComments;

    if (itemType === "REPLY") {
      updatedComments = _.map(comments, comment => {
        const { node } = comment;
        if (node.id === parentMessageId) {
          const messageReplies = _.get(node, "messageReplies.edges", []);
          const filteredReplies = _.filter(
            messageReplies,
            message => message.node.id !== comment_id
          );

          return {
            __typename: "ConversationMessageEdge",
            node: {
              ...node,
              messageReplies: {
                edges: filteredReplies,
                __typename: "MessageRepliesConnection",
              },
            },
          };
        }
        return comment;
      });
    } else {
      updatedComments = _.filter(comments, comment => {
        return comment.node.id !== comment_id;
      });
    }
    const commentUsersNode = {
      ...nodeDetails[commentsKey],
      edges: updatedComments,
      totalCount:
        itemType === "REPLY"
          ? nodeDetails[commentsKey].totalCount
          : nodeDetails[commentsKey].totalCount - 1,
    };

    writeCommentNodeFragment({
      nodeId,
      parentType,
      type,
      data: { ...nodeDetails, [commentsKey]: commentUsersNode },
    });
    try {
      await client.mutate({
        mutation: deleteMessageMutation,
        variables: {
          id: comment_id,
        },
      });

      if (isCommunity)
        EventTracker.recordEvent({
          eventName:
            itemType === "REPLY" ? "Comment reply deleted" : "Comment deleted",
          eventData: {
            source: "community",
            entity_id: nodeId,
            entity_type: type,
            entity_name: entityName,
          },
        });
    } catch (e) {
      writeCommentNodeFragment({
        nodeId,
        parentType,
        type,
        data: nodeDetails,
      });
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      throw e;
    }
  };
};

const isExcludedParentTypesForAttachmentCache = ({ parentType }) => {
  return _.includes(
    [
      "PROJECT_REPORT_SUBMISSION",
      "PROJECT_REPORT_SUBMISSION_FINAL",
      "ASSESSMENT_VOICE_INSTRUCTION",
      "ASSIGNMENT_RESOURCE_VOICE_INSTRUCTION",
      "PLANNER_FIELD_VIDEO_ATTACHMENT",
      "USER_SIGNATURE",
    ],
    parentType
  );
};

export const resetUserCircularNewCount = ({ ids, curriculumProgramIds }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: resetUserCircularNewCountMutation,
        variables: {
          ids,
          curriculumProgramIds,
        },
      });
    } catch (error) {
      //console.log(error);
    }
  };
};

export const createAttachment = ({
  nodeId,
  attachment,
  type,
  parentType,
  portalType,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;

    try {
      const response = await client.mutate({
        mutation: createAttachmentMutation,
        variables: {
          name: attachment.name,
          url: attachment.url,
          type: attachment.type,
          thumbUrl: attachment.thumbUrl ? attachment.thumbUrl : "",
          mimeType: attachment.mimeType,
          createdBy: userId,
          parentId: nodeId,
          parentType: parentType,
          metadata: attachment.metadata,
          portalType,
        },

        update: (
          cache,
          {
            data: {
              platform: { createAttachment },
            },
          }
        ) => {
          if (!isExcludedParentTypesForAttachmentCache({ parentType: type })) {
            const nodeDetails = getAttachmentOfNodeFromCache({ nodeId, type });
            const attachments = _.get(nodeDetails, "attachments", []);
            const data = {
              ...nodeDetails,
              attachments: [...attachments, ...createAttachment],
            };

            writeAttachmentNodeFragment({
              nodeId,
              data,
              type,
            });
          }
        },
      });
      return _.get(response, "data.platform.createAttachment", []);
    } catch (e) {
      //console.log(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return [];
      // writeAssessmentFieldFragment({
      //   field_id,
      //   data: fieldDetails
      // });
    }
  };
};

export const createAttachmentGroups = ({
  input,
  portalType,
  groupParentId,
  groupParentType,
}) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createAttachmentGroupsMutation,
        variables: {
          input,
          portalType,
        },
        update: (
          cache,
          {
            data: {
              platform: { createAttachmentGroups },
            },
          }
        ) => {
          const {
            parentId: inputParentId,
            parentType: inputParentType,
          } = input;
          const parentId = groupParentId ? groupParentId : inputParentId;
          const parentType = groupParentType
            ? groupParentType
            : inputParentType;
          if (!isExcludedParentTypesForAttachmentCache({ parentType })) {
            const nodeDetails = getAttachmentGroupsOfNodeFromCache({
              id: parentId,
              type: parentType,
            });
            const attachmentGroups =
              _.get(nodeDetails, "attachmentGroups", []) || [];

            const data = {
              ...nodeDetails,
              attachmentGroups: [
                ...attachmentGroups,
                ...createAttachmentGroups,
              ],
            };

            writeAttachmentGroupsOfNodeInCache({
              id: parentId,
              data,
              type: parentType,
            });
          }
        },
      });

      return _.get(result, "data.platform.createAttachmentGroups", []);
    } catch (err) {
      //console.log(err);

      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return [];
    }
  };
};

export const createAttachmentGroupsV2 = ({ input, portalType }) => {
  return async (dispatch, getState) => {
    try {
      if (input.parentId) {
        const result = await client.mutate({
          mutation: createAttachmentGroupsMutationV2,
          variables: {
            input,
            portalType,
          },
          update: (
            cache,
            {
              data: {
                platform: { createAttachmentGroupsV2 },
              },
            }
          ) => {
            const { parentId, parentType } = input;
            if (!isExcludedParentTypesForAttachmentCache({ parentType })) {
              const nodeDetails = getAttachmentGroupsOfNodeFromCache({
                id: parentId,
                type: parentType,
              });
              const response = _.get(
                createAttachmentGroupsV2,
                "0.response",
                null
              );
              if (!response) {
                return;
              }
              const attachmentGroups =
                _.get(nodeDetails, "attachmentGroups", []) || [];
              const newAttachmentGroup = [response];
              const data = {
                ...nodeDetails,
                attachmentGroups: [...attachmentGroups, ...newAttachmentGroup],
              };
              writeAttachmentGroupsOfNodeInCache({
                id: parentId,
                data,
                type: parentType,
              });
            }
          },
        });
        const resArray = _.get(
          result,
          "data.platform.createAttachmentGroupsV2",
          []
        );
        //console.log(resArray);
        const response = _.get(resArray, ["0", "response"]);
        if (!response) {
          const errorCode = _.get(resArray, ["0", "warning", "errorCode"]);
          const errorText = _.get(ATTACHMENT_WARNINGS, errorCode, "");
          if (errorText) {
            dispatch(
              setToastMsg({
                msg: errorText,
              })
            );
            return [];
          }
          dispatch(setToastMsg("toastMsgs:something_went_wrong"));
          return [];
        }
        return [response];
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    } catch (err) {
      //console.log(err);

      return [];
    }
  };
};

export const deleteAttachmentGroups = ({
  attachmentGroupIds,
  parentId,
  parentType,
}) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: deleteAttachmentGroupsMutation,
        variables: {
          attachmentGroupIds,
        },
        update: (
          cache,
          {
            data: {
              platform: { deleteAttachmentGroups },
            },
          }
        ) => {
          if (
            !isExcludedParentTypesForAttachmentCache({ parentType }) &&
            deleteAttachmentGroups
          ) {
            const nodeDetails = getAttachmentGroupsOfNodeFromCache({
              id: parentId,
              type: parentType,
            });

            const attachmentGroups =
              _.get(nodeDetails, "attachmentGroups", []) || [];

            const data = {
              ...nodeDetails,
              attachmentGroups: _.filter(
                attachmentGroups,
                ({ id }) => _.indexOf(attachmentGroupIds, id) < 0
              ),
            };

            writeAttachmentGroupsOfNodeInCache({
              id: parentId,
              data,
              type: parentType,
            });
          }
        },
      });

      return result;
    } catch (err) {
      //console.log(err);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const deleteAttachment = ({ nodeId, attachmentId, type }) => {
  return async (dispatch, getState) => {
    //console.log(nodeId, attachmentId, type);
    const nodeDetails = getAttachmentOfNodeFromCache({ nodeId, type });
    const attachments = _.get(nodeDetails, "attachments", []);

    const updatedAttachments = _.filter(attachments, attachment => {
      return attachment.id !== attachmentId;
    });

    if (!isExcludedParentTypesForAttachmentCache({ parentType: type })) {
      writeAttachmentNodeFragment({
        nodeId,
        data: { ...nodeDetails, attachments: updatedAttachments },
        type,
      });
    }

    try {
      await client.mutate({
        mutation: deleteAttachmentMutation,
        variables: {
          attachmentIds: [attachmentId],
        },
      });
    } catch (e) {
      if (!isExcludedParentTypesForAttachmentCache({ parentType: type })) {
        writeAttachmentNodeFragment({
          nodeId,
          data: nodeDetails,
          type,
        });
      }
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const convertPDFToWorkbook = ({ url }) => {
  return async dispatch => {
    try {
      const response = await client.mutate({
        mutation: convertPdfToWorkbookMutation,
        variables: {
          url,
        },
      });
      const dataObj = _.get(response, "data.platform.convertPdfToWorkbook", {});
      const { isSuccess = true, response: responseObj, warning } = dataObj;

      if (isSuccess) {
        if (warning && _.isObject(warning)) {
          const localKey = _.get(warning, "key", "");
          if (localKey) {
            dispatch(setToastMsg(`toastMsgs:${localKey}`));
          }
        }
      } else if (isSuccess === false || _.isEmpty(responseObj)) {
        //add breadcrumbs for graphql errors
        if (warning) {
          Sentry.addBreadcrumb({
            category: "console",
            level: Sentry.Severity.Info,
            message: warning,
          });
        }
        throw Error("convertPDFToWorkbook - failed ");
      }

      return responseObj;
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
        Sentry.captureException(e);
      }
      throw e;
    }
  };
};

const fields = {};

export const updateFieldMutationFunc = () => {
  return (dispatch, getState) => {
    _.map(fields, (obj, key) => {
      if (obj.func) {
        obj.func(obj.params);
      } else {
        switch (obj.type) {
          case "LEARNING_ENGAGEMENT":
            dispatch(updateLe(obj.params));
            break;
          case "ASSESSMENT":
            dispatch(updateAssessment(obj.params));
            break;
          case "UNIT_PLAN":
            dispatch(updateUnitPlan(obj.params));
            break;

          case "ASSESSMENT_TOOL_ELEMENT":
            dispatch(updateAssessmentToolElement(obj.params));
            break;
          case "RUBRIC_ELEMENT":
            dispatch(updateRubricElement(obj.params));
            break;
          case "SINGLE_POINT_RUBRIC_ELEMENT":
            dispatch(updateSinglePointRubricElement(obj.params));
            break;
          case "CHECKLIST_ELEMENT":
            dispatch(updateChecklistElement(obj.params));
            break;

          case "EVALUATION_REMARK":
            dispatch(updateRemark(obj.params));
            break;

          case "EVALUATION_TOOL":
            dispatch(handleAssessmentToolResponse(obj.params));
            break;
          case "SUBJECT_GROUPS_SUBJECTS":
          case "SUBJECTS_SETUP":
            dispatch(updateSubjectMutationFn(obj.params));
            break;
          case "SELF_STUDY_TEMPLATE_RESPONSE":
            dispatch(updateSelfStudyResponseOnServer(obj.params));
            break;
          case "COMMUNITY_ONBOARDING":
            dispatch(updateUserHandler(obj.params));
            break;
          case "STUDENT_ASSIGNMENT_SUBMISSION":
            dispatch(
              editStudentAssignmentSubmission({ mutationParams: obj.params })
            );
            break;
          case "PROJECT":
            dispatch(updateProjectField(obj.params));
            break;
          case "STUDENT_PROJECT_PORTFOLIO":
            dispatch(updateStudentProjectPortfolioField(obj.params));
            break;
          case "ELEMENT_REMARK":
            dispatch(updateElementRemark(obj.params));
            break;
          case "CREATE_ELEMENT_RATINGS":
            dispatch(updateStudentsScore({ ...(obj.params || {}) }));
            break;
        }
      }

      delete fields[key];
    });
  };
};

const dispatchUpdateFieldMutationFunc = dispatchFunc => {
  dispatchFunc(updateFieldMutationFunc());
};

const debouncedDispatchUpdateFieldMutation = _.debounce(
  dispatchUpdateFieldMutationFunc,
  1000
);

export const updateField = ({ key, type, params, isDebounce = true, func }) => {
  return (dispatch, getState) => {
    fields[`${type}:${key}`] = { type, params, func };
    if (isDebounce) {
      debouncedDispatchUpdateFieldMutation(dispatch);
    } else {
      dispatch(updateFieldMutationFunc());
    }
  };
};

/**
 * @param {*} param0
 * @param {string} destinationSearch: Destination location search string
 * @param {array} queryParamsToPreserve: An array of query params to preserve on
 * switching route
 *
 * Note: For now, the query params "source_type", "preserve_query_params", "config" are being
 * preserved to support all possible edge cases.
 *
 * window.location.search = "?param1=value1"
 * destinationSearch = "?param2=value2" or "param2=value2"
 *
 * @returns {string} Query params like '?param1=value1&param2=value2' or ""
 */

const getCombinedLocationSearch = ({
  destinationSearch = "",
  queryParamsToPreserve = ["source_type", "preserve_query_params", "config"],
}) => {
  let combinedQueryParams = {};

  const currentQueryParams = queryString.parse(
    window.location.search,
    QUERY_STRING_CONFIG
  );
  const destinationQueryParams = queryString.parse(
    destinationSearch,
    QUERY_STRING_CONFIG
  );

  const {
    preserve_query_params: preserveQueryParams = false,
  } = currentQueryParams;

  if (preserveQueryParams) {
    combinedQueryParams = {
      ..._.pick(currentQueryParams, queryParamsToPreserve),
      ...destinationQueryParams,
    };
  } else {
    combinedQueryParams = destinationQueryParams;
  }

  if (_.isEmpty(combinedQueryParams)) {
    return "";
  } else {
    return "?" + queryString.stringify(combinedQueryParams);
  }
};

/**
 * This function redirects you to specified path from the current one
 *
 * Possible 'route' examples:
 * - /path/to/route
 * - ?key1=value1&key2=value2
 * - /path/to/route?key1=value1&key2=value2
 *
 * @param {*} param0
 * @param {string} route: A destination route string (pathname?queryParams)
 * @param {type} type: Router action type push or replace
 * @param {object} location: A location descriptor
 * @param {string} replacePath: A string sub-route to replace from existing route
 */
export const goToRelativeRoute = ({
  route,
  type = "push",
  location = null,
  replacePath = null,
  queryParamsToPreserve,
}) => {
  return dispatch => {
    let tempLocation = {};

    if (location) {
      const combinedSearch = getCombinedLocationSearch({
        destinationSearch: location.search,
        queryParamsToPreserve,
      });

      tempLocation = { ...location, search: combinedSearch };
    } else {
      const [pathname, queryParamsString] = _.split(route, "?");

      const combinedSearch = getCombinedLocationSearch({
        destinationSearch: queryParamsString,
        queryParamsToPreserve,
      });

      tempLocation = { pathname: pathname, search: combinedSearch };
    }

    const relativePath = getRelativePath(
      `${tempLocation.pathname}`,
      replacePath
    );

    if (type == "push") {
      const isCommandClickEnabled = ACLStore.can(
        "FeatureFlag:EnableCommandClick"
      );
      const newWindowUrl =
        window.location.origin + relativePath + tempLocation.search;

      if (window.shouldOpenInNewTab && _.isEqual(isCommandClickEnabled, true)) {
        openLinkInNewTab({
          url: newWindowUrl,
        });
      } else {
        dispatch(
          push({
            ...tempLocation,
            pathname: relativePath,
          })
        );
      }
    } else {
      dispatch(
        replace({
          ...tempLocation,
          pathname: relativePath,
        })
      );
    }
  };
};

export const getPrintFile = ({
  id,
  mode = "pdfurl",
  shouldOpenInWindow = true,
  type,
  getIds,
  extraParams = [],
  metadata = {},
  isDownload = false,
  name = "",
  throwErrorEnabled = false,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const userType = getState().login.userInfo.user_type;
    metadata.userId = userId;
    metadata.userType = userType;
    metadata.plannerElementSetLabels = _.get(
      getState().platform,
      "organizationPlannerElementSetLabels",
      {}
    );
    if (userType !== "staff") {
      metadata.studentIds =
        userType == "parent" ? [getState().login.userInfo.childID] : [userId];
    }
    if (userType === "parent") {
      metadata.studentId = _.get(getState(), "login.userInfo.childID");
    }
    if (userType === "student") {
      metadata.studentId = userId;
    }
    // const user_type = getState().login.userInfo.user_type;
    const staffData = getPlatformUserDetailsFromCache({
      id: userId,
      type: getState().login.userInfo.userEntityType,
    });
    const settings = _.get(staffData, "settings", []);
    const locale = _.get(_.find(settings, { name: "locale" }), "value", "en");
    const token = _.get(
      JSON.parse(_.get(localStorage, "userInfo", {})),
      "token",
      ""
    );
    const fileId = generateRandomId();
    let result;
    let winRef = null;

    if (mode == "zipurl") {
      isDownload = true;
    }

    // Add api event when generating unit plan pdf
    if (type == "unitplan")
      dispatch(addApiEvent({ eventType: "GENERATE_UNIT_PLAN_PDF" }));

    try {
      const attachment = {
        id: fileId,
        type: "FILE",
        name,
        mimeType: mode == "zipurl" ? "application/zip" : "application/pdf",
      };

      if (shouldOpenInWindow && !isDownload) {
        winRef = window.open("", "_blank");
        const loadingHTML = `<html style="height:100%"><body style="height:100%">
      <div style="height:100%;display:flex;flex:1;align-item:center;justify-content:center">
      <img style="width:40px" src="https://cloud.toddleapp.com/assets/webapp/loading.svg"/>
      </div></body></html>`;

        winRef.document.write(loadingHTML);
      }
      if (isDownload) {
        dispatch(
          setProgress({
            id: fileId,
            progress: 0,
            attachment,
            status: "SAVE",
            type: "FILE",
          })
        );
      }
      if (getIds) {
        const ids = await getIds();
        id = _.join(ids, ",");
      }

      const params = {
        mode,
        type,
        id,
        token,
        lng: locale,
        metadata: encodeURIComponent(JSON.stringify(metadata)),
        ...extraParams,
      };

      const queryString = _.join(
        _.map(params, (val, key) => `${key}=${val}`),
        "&"
      );
      const url = getBackendServerUrl({
        path: `/pdfs?${queryString}`,
      });
      result = await axios.get(url);

      const { data } = result || {};

      /**
       * HACK: download or print preview sample pdf for myp reports
       */
      // if (
      //   curriculumProgramType === CURRICULUM_TYPE_MYP &&
      //   (type === "progressreport" || type === "progress_report_preview")
      // ) {
      //   data.fileUrl = "https://cloud.toddleapp.com/s/content/rkPO5RNBu.pdf"; // sample pdf url
      // }

      if (data.fileUrl) {
        if (winRef) {
          winRef.location = data.fileUrl;
        } else {
          if (isDownload) {
            attachment.url = data.fileUrl;
            dispatch(downloadFromUrl({ attachment, shouldAskForSave: true }));
          }
          return data.fileUrl;
        }
      } else {
        if (throwErrorEnabled)
          throw new Error("Failed to recieve data in getPrintFile");
        dispatch(removeProgressElement({ id: fileId }));
        dispatch(
          setToastMsg({
            msg: "toastMsgs:something_went_wrong",
            type: "alert",
            position: "toast-top-center",
          })
        );
      }
    } catch (e) {
      //console.log(e);
      if (winRef) {
        winRef.close();
      }
      dispatch(removeProgressElement({ id: fileId }));

      if (e.response) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:something_went_wrong",
            type: "alert",
            position: "toast-top-center",
          })
        );
      } else if (e.networkError) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:no_internet_connection",
            type: "alert",
            position: "toast-top-center",
          })
        );
      }

      if (throwErrorEnabled) throw e;
    }
  };
};

/**
 * This thunk helps to open a provided path/link in new tab
 *
 * @param {object} param0
 * @param {string} param0.url: absolute path of the page
 * @param {string} param0.openInNewThread: if true, open in new browser thread instead of
 *                 using existing one.
 *
 * @returns null
 */

export const openLinkInNewTab = ({ url, openInNewThread = true }) => {
  return async dispatch => {
    let winRef = null;

    try {
      if (openInNewThread) {
        winRef = window.open(url, "_blank", "noopener");
      } else {
        winRef = window.open("", "_blank");
        const loadingHTML = `<html style="height:100%"><body style="height:100%">
      <div style="height:100%;display:flex;flex:1;align-item:center;justify-content:center">
      <img style="width:40px" src="https://cloud.toddleapp.com/assets/webapp/loading.svg"/>
      </div></body></html>`;
        winRef.document.write(loadingHTML);

        if (url) {
          winRef.location = url;
        } else {
          dispatch(
            setToastMsg({
              msg: "toastMsgs:something_went_wrong",
              type: "alert",
              position: "toast-top-center",
            })
          );
        }
      }
    } catch (e) {
      if (winRef && winRef.close) winRef.close();
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-top-center",
        })
      );
    }
  };
};

export const uploadFileV2 = ({
  file,
  uploadId,
  startProgress,
  attachment,
  parentType,
  parentId,
  isAuthenticated = true,
  portalType,
}) => {
  return async (dispatch, getState) => {
    const fileUploadId = uploadId;
    const startProgressValue = startProgress ? startProgress : 0;
    const progressObject = {
      id: fileUploadId,
      progress: startProgressValue,
      attachment: attachment,
      parentType,
      parentId,
      status: "UPLOAD",
      startTime: new Date(),
    };

    // case 1: when mimeType is empty, null, undefined , then we send application/octet-stream
    // case 2: when mimeType and fileExtention both are null/undefined empty
    // attachment.mimeType = "application/octet-stream";
    // attachment.metadata.fileExtension = "";
    dispatch(setProgress(progressObject));
    try {
      let result = null;

      if (isAuthenticated) {
        result = await client.mutate({
          mutation: signS3URLMutation,
          variables: {
            fileMimeType: attachment.mimeType
              ? attachment.mimeType
              : "application/octet-stream",
            fileGroup: "content",
            fileExtension: _.get(attachment, ["metadata", "fileExtension"], ""),
            portalType,
          },
        });
      } else {
        result = await axios.post(
          getBackendServerUrl({
            path: PATH_TO_GET_SIGNED_URL,
          }),
          {
            params: {
              fileMimeType: attachment.mimeType,
            },
          }
        );
      }

      const progressOfUploads = _.get(
        getState(),
        "app_services.progressOfUploads",
        {}
      );
      const signS3URL = isAuthenticated
        ? _.get(result, "data.platform.signS3URL", {})
        : _.get(result, "data", {});

      const returnUrl = signS3URL.displayURL;
      const signedUrl = signS3URL.uploadURL;
      if (progressOfUploads[fileUploadId]) {
        var options = {
          onUploadProgress: e => {
            const progressObject = {
              id: fileUploadId,
              progress:
                startProgressValue +
                (e.loaded / e.total) * (100.0 - startProgressValue),
            };

            dispatch(setProgress(progressObject));
          },
          headers: {
            "Content-Type": attachment.mimeType
              ? attachment.mimeType
              : "application/octet-stream",
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            dispatch(setProgress({ id: fileUploadId, cancelToken: c }));
          }),
        };

        await axios.put(signedUrl, file, options);
      }
      dispatch(removeProgressElement({ id: fileUploadId }));
      return returnUrl;
    } catch (e) {
      Sentry.captureException(e);
      dispatch(removeProgressElement({ id: fileUploadId }));
      dispatch(setToastMsg("toastMsgs:unable_to_upload"));
      throw e;
    }
  };
};

export const uploadFile = ({
  file,
  uploadId,
  startProgress,
  attachment,
  parentType,
  parentId,
  isAuthenticated = true,
  portalType,
}) => {
  return (dispatch, getState) => {
    const fileUploadId = uploadId ? uploadId : generateRandomId();

    const startProgressValue = startProgress ? startProgress : 0;
    const progressObject = {
      id: fileUploadId,
      progress: startProgressValue,
      attachment: attachment,
      parentType,
      parentId,
      status: "UPLOAD",
      startTime: new Date(),
    };

    dispatch(setProgress(progressObject));
    const prom = new Promise((resolve, reject) => {
      let returnUrl = null;
      let requestObj = null;

      if (isAuthenticated) {
        requestObj = client.mutate({
          mutation: signS3URLMutation,
          variables: {
            fileMimeType: attachment.mimeType
              ? attachment.mimeType
              : "application/octet-stream",
            fileGroup: "content",
            portalType,
          },
        });
      } else {
        requestObj = axios.post(
          getBackendServerUrl({
            path: PATH_TO_GET_SIGNED_URL,
          }),
          {
            params: {
              fileMimeType: attachment.mimeType,
            },
          }
        );
      }

      requestObj
        .then(result => {
          const progressOfUploads = _.get(
            getState(),
            "app_services.progressOfUploads",
            {}
          );
          const signS3URL = isAuthenticated
            ? _.get(result, "data.platform.signS3URL", {})
            : _.get(result, "data", {});

          if (progressOfUploads[fileUploadId]) {
            returnUrl = signS3URL.displayURL;
            var signedUrl = signS3URL.uploadURL;
            var options = {
              onUploadProgress: e => {
                const progressObject = {
                  id: fileUploadId,
                  progress:
                    startProgressValue +
                    (e.loaded / e.total) * (100.0 - startProgressValue),
                };

                dispatch(setProgress(progressObject));
              },
              headers: {
                "Content-Type": attachment.mimeType
                  ? attachment.mimeType
                  : "application/octet-stream",
              },
              cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                dispatch(setProgress({ id: fileUploadId, cancelToken: c }));
              }),
            };

            return axios.put(signedUrl, file, options);
          } else {
            throw "";
          }
        })
        .then(function (result) {
          resolve(returnUrl);
        })
        .catch(function (err) {
          Sentry.captureException(err);
          dispatch(removeProgressElement({ id: fileUploadId }));
          dispatch(setToastMsg("toastMsgs:unable_to_upload"));
          reject(err);
        });
    });

    return { uploadId: fileUploadId, promise: prom };
  };
};

export const uploadFileS3 = (file, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let returnUrl = null;
      axios
        .get(
          getBackendServerUrl({
            path: PATH_TO_GET_SIGNED_URL,
          }),
          {
            params: {
              "file-name": file.name,
              "file-type": file.type,
              "content-type": "content",
            },
          }
        )
        .then(function (result) {
          returnUrl = result.data.url;
          var signedUrl = result.data.signedRequest;
          var options = {
            onUploadProgress: e => {
              const progressObject = {
                id,
                progress: ((e.loaded / e.total) * 100.0).toFixed(0),
              };
              dispatch(setProgress(progressObject));
            },
            headers: { "Content-Type": file.type },
          };
          return axios.put(signedUrl, file, options);
        })
        .then(function (result) {
          resolve(returnUrl);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  };
};

export const notificationCenterOpenMutation = ({
  userId,
  activeTabFilterValue,
}) => {
  return async (dispatch, getState) => {
    const state = getState();

    const userEntityType = state.login.userInfo.userEntityType;

    const userType = state.login.userInfo.user_type;

    const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });
    // get current category tabs visible in notification center
    const categoryTabsInNotificationCenter = getCategoryTabsForNotification({
      isCurriculumProgramFree,
      userType,
    });

    // get data from cache
    const data =
      _.cloneDeep(
        getUserNotificationCountFromCache({
          id: userId,
          type: userEntityType,
          notificationCategoryFilter: categoryTabsInNotificationCenter,
        })
      ) || {};

    try {
      // new count
      let newCount = _.get(data, "notification.newCount", 0);

      // categorisedNewCount
      const categorisedNewCount = _.get(
        data,
        "notification.categorisedNewCount",
        []
      );

      let updatedCategorisedNewCount = categorisedNewCount;
      _.forEach(activeTabFilterValue, category => {
        const index = _.findIndex(
          updatedCategorisedNewCount,
          item => item.category === category
        );

        if (index >= 0) {
          // subtract from new count
          newCount -= _.get(updatedCategorisedNewCount[index], "count", 0);
          // update categorisedNewCount
          updatedCategorisedNewCount = update(updatedCategorisedNewCount, {
            [index]: {
              $set: { ...(categorisedNewCount[index] || {}), count: 0 },
            },
          });
        }
      });

      // write updated categorised count and updated new count in cache
      writeUserNotificationInFromCache({
        id: userId,
        type: userEntityType,
        data: {
          ...data,
          notification: {
            ...(data.notification || {}),
            newCount,
            categorisedNewCount: updatedCategorisedNewCount,
          },
        },
        notificationCategoryFilter: categoryTabsInNotificationCenter,
      });

      // mutation
      await client.mutate({
        mutation: updateNotificationIsNew,
        variables: {
          input: { userId, updatedBy: userId, types: activeTabFilterValue },
        },
      });
    } catch (e) {
      // on failure write old data in cache
      writeUserNotificationInFromCache({
        id: userId,
        data,
        type: userEntityType,
      });
    }
  };
};

export const notificationCenterReadMutation = ({ userId, notificationId }) => {
  return (dispatch, getState) => {
    client
      .mutate({
        mutation: updateNotificationIsRead,
        variables: {
          input: { id: notificationId, updatedBy: userId },
        },
        update: (
          proxy,
          {
            data: {
              platform: { updateNotificationIsRead },
            },
          }
        ) => {
          if (updateNotificationIsRead) {
            const data = getNotificationFromCache(notificationId);
            data.isRead = true;
            writeNotificationFragment({ notificationId, data });
          }
        },
      })
      .then(res => {})
      .catch(err => {});
  };
};
export const notificationCenterReadForUserMutation = userId => {
  return async (dispatch, getState) => {
    const userEntityType = getState().login.userInfo.userEntityType;

    try {
      await client.mutate({
        mutation: updateNotificationIsReadForUser,
        variables: {
          input: { updatedBy: userId },
        },
        update: (
          proxy,
          {
            data: {
              platform: { updateNotificationIsReadForUser },
            },
          }
        ) => {
          if (updateNotificationIsReadForUser) {
            _.forEach(NOTIFICATION_CATEGORY, category => {
              let data = getNotificationListFromCache({
                id: userId,
                type: userEntityType,
                notificationCategoryFilter: category.filterValue,
              });
              if (!_.isEmpty(data)) {
                //data.notification.edge.node.isRead = true
                let edges = { ..._.get(data, "notification.edge", []) };
                edges = _.map(edges, item => {
                  return { ...item, node: { ...item.node, isRead: true } };
                });

                if (data.notification) {
                  data = update(data, {
                    notification: { edge: { $set: edges } },
                  });

                  writeNotificationListInCache({
                    id: userId,
                    data,
                    type: userEntityType,
                    notificationCategoryFilter: category.filterValue,
                  });
                }
              }
            });
          }
        },
      });
    } catch (e) {}
  };
};

export const bulkUploadByFile = ({
  url,
  type,
  organizationId,
  inputs,
  refetchQueries = [],
}) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: bulkUploadByFileMutation,
        variables: {
          url,
          type,
          inputs,
          organizationId: organizationId
            ? organizationId
            : getState().login.userInfo.org_id,
        },
        refetchQueries,
      });
      const bulkUploadFileData = _.get(
        response,
        "data.platform.bulkUploadByFile",
        undefined
      );

      const responseSuccessful = _.get(bulkUploadFileData, "successful", false);

      let returnObject = {
        type: "success",
        msg: "toastMsgs:successfully_with_label",
        locale_params: [
          { key: "label", value: "toastMsgs:added", isPlainText: false },
        ],
      };
      if (!responseSuccessful) {
        returnObject = "toastMsgs:couldnt_be_added";
      }

      dispatch(setToastMsg(returnObject));

      return bulkUploadFileData;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return {
        successful: false,
        errors: ["Something went wrong"],
      };
    }
  };
};

export const updateSettings = ({
  id,
  type,
  settings,
  isForTools = false,
  showToastMsg = true,
  onSettingsUpdate,
  writeToCache = false,
}) => {
  return async (dispatch, getState) => {
    const userEntityType = getState().login.userInfo.userEntityType;
    try {
      const obj = {
        mutation:
          type == "user"
            ? updateUserSettingsMutation
            : updateOrganizationSettingsMutation,
        variables: {
          id,
          isForTools,
          settings,
        },
        refetchQueries: [
          {
            query: getOrganizationSetting,
            variables: {
              id,
              isForTools,
            },
          },
        ],
      };

      if (!isForTools) {
        delete obj.refetchQueries;
      }

      if (writeToCache) {
        const userDetails = getPlatformUserDetailsFromCache({
          id,
          type: userEntityType,
        });
        const userSettings = _.get(userDetails, "settings", []);
        const newSettings = _.map(userSettings, setting => {
          const { name, value } = setting;
          const newSetting = _.find(settings, { name });
          return { ...setting, value: newSetting ? newSetting.value : value };
        });
        const data = {
          node: update(userDetails, { settings: { $set: newSettings } }),
        };
        writePlatformUserDetailsToCache({ id, type: userEntityType, data });
      }

      await client.mutate(obj);

      if (showToastMsg) {
        dispatch(
          setToastMsg({
            type: "success",
            msg: "toastMsgs:saved_successfully",
          })
        );
      }

      onSettingsUpdate?.();
      return true;
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};
// ------------------------------------
// Reducer Handlers
// ------------------------------------
const REDUCER_HANDLERS = {
  [SET_PICTURE_IN_PICTURE_DATA]: (state, action) => {
    let url = _.get(action, "data.url", null);
    let mediaType = _.get(action, "data.mediaType", null);
    let videoCurrentTime = _.get(action, "data.videoCurrentTime", null);
    const showPip = _.get(action, "data.showPip", false);
    if (!showPip) {
      url = null;
      mediaType = null;
      videoCurrentTime = null;
    }
    if (mediaType !== "VIDEO") {
      videoCurrentTime = null;
    }
    return update(state, {
      pictureInPictureConfig: {
        $set: {
          url,
          mediaType,
          showPip,
          videoCurrentTime,
          item: _.get(action, "data.item", {}),
        },
      },
    });
  },

  [UPDATE_AUDIO_RECORDER_BLOB]: (state, action) => {
    return update(state, {
      draftRecording: { $set: action.payload },
    });
  },

  [SET_IS_ONLINE]: (state, action) => {
    return update(state, {
      isOnline: { $set: action.payload },
    });
  },

  [SET_IS_PAGE_NOT_FOUND]: (state, action) => {
    return update(state, {
      isPageNotFound: { $set: action.payload },
    });
  },

  [SET_PROGRESS]: (state, action) => {
    const id = action.payload.id;

    const keys = Object.keys(action.payload);
    if (!state.progressOfUploads[id]) {
      state = update(state, {
        progressOfUploads: { [id]: { $set: {} } },
      });
    }
    _.forEach(keys, key => {
      state = update(state, {
        progressOfUploads: { [id]: { [key]: { $set: action.payload[key] } } },
      });
    });

    return state;
  },

  [SET_ORDER_BY_DIRECTION]: (state, action) => {
    const params = action.data;

    Object.keys(params).map((parentKey, index) => {
      if (!state["orderByDirectionObj"][parentKey]) {
        state = update(state, {
          orderByDirectionObj: {
            [parentKey]: {
              $set: { orderBy: "TITLE", orderByDirection: "ASC" },
            },
          },
        });
      }

      Object.keys(params[parentKey]).map((key, index) => {
        state = update(state, {
          orderByDirectionObj: {
            [parentKey]: { [key]: { $set: params[parentKey][key] } },
          },
        });
      });
    });

    return state;
  },
  [REMOVE_PROGRESS_ELEMENT]: (state, action) => {
    const { id } = action.payload;
    const newState = update(state, {
      progressOfUploads: { $set: _.omit(state.progressOfUploads, [id]) },
    });
    return newState;
  },
  [INITIALISE_RESOURCEBANK_COMPONENT]: (state, action) => {
    return update(state, {
      resourceBank: { [action.data]: { $set: resourceBank } },
    });
  },

  [UPDATE_RESOURCEBANK_FILTERS]: (state, action) => {
    const params = action.data.params;
    const id = action.data.id;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        resourceBank: { [id]: { filters: { [key]: { $set: params[key] } } } },
      });
    });
    return state;
  },
  [TOGGLE_ALL_RESOURCEBANK_FILTER_ITEMS]: (state, action) => {
    const stateItemToUpdate = action.data.params.type;
    const id = action.data.id;
    return update(state, {
      resourceBank: {
        [id]: {
          filters: {
            selected: {
              [stateItemToUpdate]: { $set: action.data.params.allList },
            },
          },
        },
      },
    });
  },
  [TOGGLE_RESOURCEBANK_FILTER_ITEM]: (state, action) => {
    const params = action.data.params;
    const value = params.value;
    const opt = params.opt;
    const index = params.index;
    const stateItemToUpdate = params.type;
    const item = _.cloneDeep(params.item);
    const id = action.data.id;
    if (value) {
      return update(state, {
        resourceBank: {
          [id]: {
            filters: { selected: { [stateItemToUpdate]: { $push: [opt] } } },
          },
        },
      });
    } else {
      return update(state, {
        resourceBank: {
          [id]: {
            filters: {
              selected: { [stateItemToUpdate]: { $splice: [[index, 1]] } },
            },
          },
        },
      });
    }
  },
  [TOGGLE_RESOURCEBANK_SELECTED_RESOURCES]: (state, action) => {
    const params = action.data.params;
    const type = params.type;
    const data = params.data;
    const arrayindex = params.index;
    const id = action.data.id;

    if (type == "add") {
      if (arrayindex == "") {
        state = update(state, {
          resourceBank: { [id]: { selectedResources: { $push: [data] } } },
        });
      } else {
        state = update(state, {
          resourceBank: {
            [id]: { selectedResources: { $splice: [[arrayindex, 0, data]] } },
          },
        });
      }
    } else if (type == "delete") {
      state = update(state, {
        resourceBank: {
          [id]: { selectedResources: { $splice: [[arrayindex, 1]] } },
        },
      });
    } else {
      state = update(state, {
        resourceBank: {
          [id]: { selectedResources: { $splice: [[arrayindex, 1, data]] } },
        },
      });
    }
    return state;
  },
  [CLEAR_RESOURCEBANK_SELECTED_RESOURCES]: (state, action) => {
    const id = action.data.id;
    return update(state, {
      resourceBank: { [id]: { selectedResources: { $set: [] } } },
    });
  },
  [UPDATE_LOCAL_COMMENT]: (state, action) => {
    const id = action.data.id;
    const commentData = action.data.commentData;
    return update(state, { createComment: { [id]: { $set: commentData } } });
  },
  [TOGGLE_ACTION_LOADING]: (state, action) => {
    return update(state, {
      isActionLoading: { $set: action.data },
    });
  },
  [UPDATE_GLOBAL_SEARCH_TEXT]: (state, action) => {
    return update(state, {
      globalSearchBar: { searchText: { $set: action.payload } },
    });
  },
  [SET_GLOBAL_SEARCH_VOICE]: (state, action) => {
    return update(state, {
      globalSearchBar: { enableVoiceSearch: { $set: action.payload } },
    });
  },
  [SET_GLOBAL_SEARCH_MODAL]: (state, action) => {
    return update(state, {
      globalSearchBar: { showSearchBar: { $set: action.payload } },
    });
  },
  [SET_GLOBAL_SEARCH_FILTER_MODULE]: (state, action) => {
    return update(state, {
      globalSearchBar: { module: { $set: action.payload } },
    });
  },
  [TOGGLE_GLOBAL_SEARCH_MODULE]: (state, action) => {
    const { showSearchBar, enableVoiceSearch } = state.globalSearchBar;

    const updatedStatusOfVoice = enableVoiceSearch ? false : enableVoiceSearch;

    state = update(state, {
      globalSearchBar: {
        showSearchBar: { $set: !showSearchBar },
        enableVoiceSearch: { $set: updatedStatusOfVoice },
      },
    });

    return state;
  },
  [TOGGLE_GLOBAL_SEARCH_VOICE_MODULE]: (state, action) => {
    const { enableVoiceSearch } = state.globalSearchBar;

    const actionState = _.get(action, "payload.state", undefined);

    const updatedStatusOfVoice =
      actionState !== undefined ? actionState : !enableVoiceSearch;

    state = update(state, {
      globalSearchBar: { enableVoiceSearch: { $set: updatedStatusOfVoice } },
    });

    return state;
  },
  [TOGGLE_GLOBAL_SEARCH_AND_VOICE_MODULE]: (state, action) => {
    const { showSearchBar, enableVoiceSearch } = state.globalSearchBar;

    state = update(state, {
      globalSearchBar: {
        showSearchBar: { $set: !showSearchBar },
        enableVoiceSearch: { $set: !enableVoiceSearch },
      },
    });

    return state;
  },
  [UPDATE_ATTACHMENT_LOADER_MENU_STYLES]: (state, action) => {
    return update(state, {
      attachmentLoaderMenuStyles: {
        $set: action.payload,
      },
    });
  },

  [RESET_ATTACHMENT_LOADER_MENU_STYLES]: state => {
    return update(state, {
      attachmentLoaderMenuStyles: {
        $set: _.cloneDeep(initialState.attachmentLoaderMenuStyles),
      },
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  progressOfUploads: {
    //id: progress
  },
  isOnline: true,
  isPageNotFound: false,
  isActionLoading: false,
  resourceBank: {
    default: {
      filters: {
        searchText: "",
        count: 18,
        selected: {
          sources: [],
          mediaTypes: [],
        },
      },
      selectedResources: [],
    },
  },
  orderByDirectionObj: {
    studentProgressReport: {
      orderBy: "FIRST_NAME",
      orderByDirection: "ASC",
    },
    adminTeacherFeed: {
      orderBy: "FIRST_NAME",
      orderByDirection: "ASC",
    },
    adminStudentFeed: {
      orderBy: "FIRST_NAME",
      orderByDirection: "ASC",
    },
  },
  createComment: {},
  globalSearchBar: {
    searchText: "",
    module: null,
    showSearchBar: false,
    enableVoiceSearch: false,
  },
  attachmentLoaderMenuStyles: {
    position: "fixed",
    bottom: "16px",
    left: "88px",
  },
};

const resourceBank = {
  filters: {
    searchText: "",
    count: 18,
    selected: {
      sources: [],
      mediaTypes: [],
    },
  },

  selectedResources: [],
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const DESIGNATIONS_CONSTANTS = [
  {
    title: "Teacher",
    subText: "Can access information only related to their classes",
    locale: "schoolSetup:desig_1_title",
    subTextLocale: "schoolSetup:desig_1_subText",
    id: 1,
  },
  {
    title: "PYP Coordinator",
    subText:
      "Can access information for all classes and configure school settings",
    locale: "schoolSetup:desig_2_title",
    subTextLocale: "schoolSetup:desig_2_subText",
    id: 2,
  },
  {
    title: "IT Specialist",
    subText:
      "Can access information for all classes and configure school settings",
    locale: "schoolSetup:desig_3_title",
    subTextLocale: "schoolSetup:desig_3_subText",
    id: 3,
  },
  {
    title: "School Administrator",
    subText:
      "Can access information for all classes and configure school settings",
    locale: "schoolSetup:desig_4_title",
    subTextLocale: "schoolSetup:desig_4_subText",
    id: 4,
  },
  {
    title: "Other",
    subText: "Can access information only related to their classes",
    locale: "schoolSetup:desig_5_title",
    subTextLocale: "schoolSetup:desig_5_subText",
    id: 5,
  },
];

export const getDesignationsLocales = ({ t, isCheckForAdmin = true }) => {
  const globalConstants = getGlobalConstantsFromCache();
  const designations = _.get(globalConstants, "designations", []);
  const isAdmin = ACLStore.can("AdminPortal");
  let filteredDesignations = [...designations];

  if (isCheckForAdmin && !isAdmin) {
    filteredDesignations = _.filter(
      filteredDesignations,
      ({ roles }) => !_.includes(roles, "admin")
    );
  }

  return _.map(designations, ({ id }) => {
    const designation = _.find(
      DESIGNATIONS_CONSTANTS,
      designation => designation.id == id
    );

    return {
      label: !_.isEmpty(designation) ? t(designation.locale) : "",
      value: id,
      subText: !_.isEmpty(designation) ? t(designation.subTextLocale) : "",
      subtitle: !_.isEmpty(designation) ? t(designation.subTextLocale) : "",
    };
  });
};

export const sendSigninMagicLink = url => {
  return async dispatch => {
    try {
      const mutationData = {
        mutation: sendSigninLinkMutation,
      };
      if (url) {
        mutationData.variables = {
          resourceUrl: url,
          action: "community",
          portalType: "PLANNER",
        };
      }
      await client.mutate(mutationData);
      return true;
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const sendResourceLink = (email, url) => {
  return async dispatch => {
    try {
      await axios.post(
        getBackendServerUrl({
          path: `/community/sendResourceUrlEmail`,
        }),
        { emailId: email, resourceUrl: url },
        { headers: { Authorization: `Bearer ${DUMMY_USER_INFO.token}` } }
      );
      return true;
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const trackEntityPreview = () => {
  return async () => {
    const { utm_token: utmToken, utm_source: platform } = querystringify.parse(
      _.get(window, "location.search", "")
    );
    if (!utmToken && !platform) return false;
    try {
      await axios.post(
        getBackendServerUrl({
          path: `/community/trackPreviews`,
        }),
        { utmToken, platform },
        { headers: { Authorization: `Bearer ${DUMMY_USER_INFO.token}` } }
      );
      return true;
    } catch (err) {
      return false;
    }
  };
};

const getUnitPlanFieldValue = ({ fieldKey, unitPlanFields }) => {
  return _.get(_.find(unitPlanFields, { uid: fieldKey }), "value", "");
};

const getUnitPlanFieldResolvedValue = ({ fieldKey, unitPlanFields }) => {
  return _.get(
    _.find(unitPlanFields, { uid: fieldKey }),
    "resolvedMinimalTree",
    ""
  );
};

const getResouceFieldValue = ({ fieldKey, assessmentFields }) => {
  return _.get(_.find(assessmentFields, { uid: fieldKey }), "value", "");
};

const getResouceFieldResolveValue = ({ fieldKey, assessmentFields }) => {
  return _.get(
    _.find(assessmentFields, { uid: fieldKey }),
    "resolvedMinimalTree",
    ""
  );
};

export const getMYPObjectiveRubricOptions = ({
  field_list,
  assessmentFields,
  unitPlanFields,
}) => {
  const measureAssessingValue = getResouceFieldValue({
    fieldKey: "measureAssessing",
    assessmentFields,
  });
  /*
    For MYP_OBJECTIVE_RUBIRC fieldKey is objectiveMYP
    For MYP_INTERDISCIPLINARY_CRITERIA_RUBRIC fieldKey is interdisciplinaryCriteriaMYP
  */
  const fieldKey = _.get(
    _.find(ASSESSMENT_TOOLS, { type: measureAssessingValue }),
    "objectiveFieldKey",
    ""
  );

  const objectiveFieldValue = getResouceFieldValue({
    fieldKey,
    assessmentFields,
  });
  const objectiveFieldResolvedValue = getResouceFieldResolveValue({
    fieldKey,
    assessmentFields,
  });

  const objectiveUnitPlanFieldValue = getUnitPlanFieldValue({
    fieldKey,
    unitPlanFields,
  });
  const objectiveUnitPlanFieldResolvedValue = getUnitPlanFieldResolvedValue({
    fieldKey,
    unitPlanFields,
  });
  const objectiveTemplateFieldObj = field_list[fieldKey];
  return {
    objectiveField: {
      value: objectiveFieldValue,
      unitPlanFieldValue: objectiveUnitPlanFieldValue,
      unitPlanFieldResolvedValue: objectiveUnitPlanFieldResolvedValue,
      resolvedValue: objectiveFieldResolvedValue,

      templateFieldObj: objectiveTemplateFieldObj,
      fieldKey,
    },
  };
};

export const ATTENDANCE_GLOBAL_CONSTANTS = Object.freeze({
  ROUTINE_MODE: "ROUTINE_MODE",
  NO_OF_DAYS_IN_ROTATION_CYCLE: "NO_OF_DAYS_IN_ROTATION_CYCLE",
  COUNT_HOLIDAYS_AS_ROTATION_DAY: "COUNT_HOLIDAYS_AS_ROTATION_DAY",
  ATTENDANCE_RECORDING_TYPE: "ATTENDANCE_RECORDING_TYPE",
  OPERATIONAL_DAYS: "OPERATIONAL_DAYS",
  ROTATION_CYCLE: "ROTATION_CYCLE",
  STAFF: "staff",
  PARENT: "parent",
  STUDENT: "student",
});

export const createOrganizationGlobalConstants = ({
  organizationId,
  input,
  refetchFilters,
}) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: createOrganizationGlobalConstantsMutation,
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: getOrganizationGlobalConstantsQuery,
            variables: { organizationId, filter: refetchFilters },
          },
        ],
      });
      return true;
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const getSubLabel = ({ type, props }) => {
  const { t } = props;
  switch (type) {
    case "todoList": {
      return getCountString({
        count:
          _.get(props, "assignedTodoCount", 0) +
          _.get(props, "overdueTodoCount", 0),
        singularText: t("common:item_pending"),
        pluralText: t("common:item_pending_plural"),
        shouldShowZero: true,
      });
    }

    case "consolidatedTodos": {
      return getCountString({
        count: _.get(props, "consolidatedTodosCount"),
        singularText: t("common:item_pending"),
        pluralText: t("common:item_pending_plural"),
        shouldShowZero: true,
      });
    }

    case "circularList":
      return getCountString({
        count: _.get(props, "unreadCircularCount", 0),
        singularText: t("common:unread"),
        pluralText: t("common:unread"),
        shouldShowZero: true,
      });

    case "familyMessaging":
      return getCountString({
        count: _.get(props, "unreadMessageCount", 0),
        singularText: t("common:unread"),
        pluralText: t("common:unread"),
        shouldShowZero: true,
      });

    case "reports":
      return getCountString({
        count: _.get(props, "totalReportCount", 0),
        singularText: t("common:report"),
        pluralText: t("common:reports")?.toLowerCase(),
        shouldShowZero: true,
      });

    case "journal":
      return getCountString({
        count: _.get(props, "totalPostCount", 0),
        singularText: t("common:post")?.toLowerCase(),
        pluralText: t("common:post_plural")?.toLowerCase(),
        shouldShowZero: true,
      });
  }
};

const getRotationDayText = ({
  isRotationCycle,
  rotationDays,
  attendanceType,
  date,
  t,
}) => {
  if (attendanceType !== "DAILY" && attendanceType !== "WEEKLY") {
    return;
  }
  if (attendanceType === "DAILY") {
    if (!isRotationCycle) {
      return;
    }
    const rotationDay = _.get(
      _.find(rotationDays, rotationDayObj =>
        moment(date, "YYYY-MM-DD").isSame(
          _.get(rotationDayObj, "date", ""),
          "day"
        )
      ),
      "rotationDay",
      ""
    );
    return rotationDay
      ? t("common:day_single", {
          day: getRotationDayLabel({ rotationDay }),
        })
      : null;
  } else if (isRotationCycle) {
    return t("common:day_range", {
      day1: getRotationDayLabel({
        rotationDay: _.get(_.first(rotationDays), "rotationDay", ""),
      }),
      day2: getRotationDayLabel({
        rotationDay: _.get(_.last(rotationDays), "rotationDay", ""),
      }),
    });
  }
  return null;
};

export const getRotationDayTextMemoize = _.memoize(
  params => getRotationDayText(params),
  params => JSON.stringify(params)
);

export const checkForSimilarity = ({ attachmentId, metadata }) => {
  return async () => {
    const attachment = getAttachmentFragmentFromCache({
      attachmentId,
      metadata,
    });
    try {
      const response = await client.mutate({
        mutation: checkForSimilarityMutation,
        variables: { input: { attachmentId, metadata } },
        optimisticResponse: {
          __typename: "Mutation",
          integration: {
            __typename: "IntegrationMutation",
            generateSimilarityReport: {
              __typename: "SimilarityReport",
              status: "PROCESSING",
              errorCode: null,
              overallMatchPercentage: null,
            },
          },
        },
        update: (
          cache,
          {
            data: {
              integration: { generateSimilarityReport },
            },
          }
        ) => {
          if (!_.isEmpty(generateSimilarityReport)) {
            const updatedAttachment = update(attachment, {
              $set: {
                ...attachment,
                similarityReport: generateSimilarityReport,
              },
            });

            setTimeout(() => {
              writeAttachmentSimilarityReportInCache({
                attachmentId,
                data: updatedAttachment,
              });
            });
          }
        },
      });
      return response;
    } catch (e) {
      setTimeout(() => {
        writeAttachmentSimilarityReportInCache({
          attachmentId,
          data: attachment,
        });
      });
    }
  };
};

export const cancelCheckSimilarityReport = ({ attachmentId }) => {
  return async () => {
    const attachment = getAttachmentFragmentFromCache({ attachmentId });
    try {
      const response = await client.mutate({
        mutation: cancelCheckSimilarityMutation,
        variables: { input: { attachmentId } },
        optimisticResponse: {
          __typename: "Mutation",
          integration: {
            __typename: "IntegrationMutation",
            cancelSimilarityReport: true,
          },
        },
        update: (
          cache,
          {
            data: {
              integration: { cancelSimilarityReport },
            },
          }
        ) => {
          if (cancelSimilarityReport) {
            const updatedAttachment = update(attachment, {
              $set: {
                ...ATTACHMENT_CREATE_ATTRIBUTES,
                ...attachment,
              },
            });

            setTimeout(() => {
              writeAttachmentSimilarityReportInCache({
                attachmentId,
                data: updatedAttachment,
              });
            });
          }
        },
      });
      return response;
    } catch (e) {
      setTimeout(() => {
        writeAttachmentSimilarityReportInCache({
          attachmentId,
          data: attachment,
        });
      });
    }
  };
};

export const getTurnitinReportViewerLaunchUrl = ({ input }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.query({
        query: getTurnitinReportViewerLaunchUrlQuery,
        variables: {
          input,
        },
        fetchPolicy: "network-only",
      });
      return _.get(
        response,
        "data.integration.getTurnitinReportViewerLaunchUrl",
        ""
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const getIsTurnitinEulaRequired = () => {
  return async (dispatch, getState) => {
    try {
      const response = await client.query({
        query: getIsTurnitinEulaRequiredQuery,
        fetchPolicy: "network-only",
      });
      return _.get(response, "data.integration.isTurnitinEulaRequired", true);
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const acceptTurnitinEula = ({ input }) => {
  return async (dispatch, getState) => {
    try {
      const response = await client.mutate({
        mutation: acceptTurnitinEulaMutation,
        variables: { input },
      });
      return response;
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const getCategoryTabsForNotification = ({
  userType,
  isCurriculumProgramFree,
}) => {
  const categoryTabs = [];
  _.forEach(NOTIFICATION_CATEGORY, tab => {
    const isPermission = tab.perm ? ACLStore.can(tab.perm) : true;

    let isAllowed = true;
    if (isCurriculumProgramFree) {
      isAllowed = !_.get(tab, "paidOnly", false);
    }

    if (isPermission && isAllowed && _.includes(tab.userTypes, userType)) {
      categoryTabs.push(...tab.filterValue);
    }
  });
  return categoryTabs;
};

export const replaceEditPreview = string => {
  return _.replace(string, "edit", "preview");
};

export const deletedBasicComment = ({
  id,
  parentId,
  parentType,
  initialCommentsCount,
}) => {
  return async (dispatch, getState) => {
    const data = getBasicCommentOfNodeFromCache({
      id: parentId,
      first: initialCommentsCount,
      type: parentType,
    });

    const index = _.findIndex(_.get(data, "node.comments.messageEdges"), {
      node: { id },
    });

    writeProcessJournalCommentsInCache({
      variables: {
        id: parentId,
        first: initialCommentsCount,
        type: parentType,
      },
      data: update(data, {
        node: {
          comments: {
            messageEdges: { $splice: [[index, 1]] },
          },
        },
      }),
    });
    try {
      await client.mutate({
        mutation: deleteMessageMutation,
        variables: {
          id: id,
        },
      });
    } catch (e) {
      writeProcessJournalCommentsInCache({
        variables: {
          id: parentId,
          first: initialCommentsCount,
          type: parentType,
        },
        data,
      });
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const markAttachmentAsRead = ({ id }) => {
  return async (dispatch, getState) => {
    const attachment = getAttachmentFragmentFromCache({ attachmentId: id });
    writeAttachmentSimilarityReportInCache({
      attachmentId: id,
      data: update(attachment, {
        isRead: { $set: true },
      }),
    });
    try {
      await client.mutate({
        mutation: updateEntityIsReadMutation,
        variables: {
          id: id,
          entityType: "ATTACHMENT",
        },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    }
  };
};

export const createBasicComment = ({
  parentId,
  parentType,
  label,
  subEntityType,
  initialCommentsCount,
}) => {
  return async (dispatch, getState) => {
    const userInfo = getState().login.userInfo;
    const data = getBasicCommentOfNodeFromCache({
      id: parentId,
      first: initialCommentsCount,
      type: parentType,
    });
    const commentID = generateRandomId();
    const newComment = {
      createdAt: moment().toISOString(),
      createdBy: {
        id: userInfo?.id,
        firstName: userInfo?.first_name,
        lastName: userInfo?.last_name,
        profileImage: userInfo?.profile_image ?? null,
        email: userInfo?.email,
        type: userInfo?.user_type,
        schoolTenures: [],
        __typename: "Staff",
      },
      id: commentID,
      itemType: "POST",
      label,
      type: "NORMAL",
      __typename: "Message",
    };
    try {
      await client.mutate({
        mutation: createBasicMessageMutation,
        variables: {
          parentId,
          parentType,
          label,
          subEntityType,
        },
        optimisticResponse: {
          __typename: "Mutation",
          platform: {
            createMessage: newComment,
            __typename: "PlatformMutations",
          },
        },
        update: async (
          cache,
          {
            data: {
              platform: { createMessage },
            },
          }
        ) => {
          const data = getBasicCommentOfNodeFromCache({
            id: parentId,
            first: initialCommentsCount,
            type: parentType,
          });
          const index = _.findIndex(
            _.get(data, "node.comments.messageEdges"),
            item => _.get(item, "node.id", null) === commentID
          );
          const comment = {
            node: { ...createMessage },
            __typename: "ConversationMessageEdge",
          };
          //Optimistic doesn't works correctly without setTimeout so using setTimeout.
          setTimeout(
            () =>
              writeProcessJournalCommentsInCache({
                variables: {
                  id: parentId,
                  first: initialCommentsCount,
                  type: parentType,
                },
                data: update(data, {
                  node: {
                    comments: {
                      messageEdges: messageEdges =>
                        index === -1
                          ? update(messageEdges || [], {
                              $unshift: [comment],
                            })
                          : update(messageEdges || [], {
                              [index]: { $set: comment },
                            }),
                    },
                  },
                }),
              }),
            0
          );
        },
      });
      return true;
    } catch (e) {
      writeProcessJournalCommentsInCache({
        variables: {
          id: parentId,
          first: initialCommentsCount,
          type: parentType,
        },
        data,
      });
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      return false;
    }
  };
};

export const hideBasicComments = async ({
  parentId,
  parentType,
  initialCommentsCount,
}) => {
  const dataWithFirstCommentOnly = await client.query({
    query: getBasicCommentOfNodeQuery,
    variables: {
      id: parentId,
      first: initialCommentsCount,
      type: parentType,
    },
    fetchPolicy: "network-only",
  });

  writeProcessJournalCommentsInCache({
    variables: {
      id: parentId,
      first: initialCommentsCount,
      type: parentType,
    },
    data: dataWithFirstCommentOnly,
  });
};

export const getLabelsOfSelectedItems = ({
  selectedItems = [],
  itemList = [],
}) => {
  const labelsOfSelectedItems = _.map(selectedItems, value => {
    const currentItemObj = _.find(itemList, item => item.value === value);
    return currentItemObj?.label;
  });
  return labelsOfSelectedItems;
};

/**
 * @param {Array} selectedItems Structure -> [value1,value2 ...]
 * @param {Array} itemList Structure -> [{value:value1,label:label1},{value:value2,label:label2}...]
 * @param {Function} t  Description ->  function returned by i18n
 * @param {String} noneSelectedText Description -> text to display when no item is selected
 * @returns {String}
 */
// Use getLabelValueForMultiFilterLabelButton for FilterLabelButton in the case of checklist dropdown
export const getLabelValueForDropdown = ({
  selectedItems,
  itemList,
  t,
  noneSelectedText,
}) => {
  const labelsOfSelectedItems = _.map(selectedItems, value => {
    const currentItemObj = _.find(itemList, item => item.value === value);
    return currentItemObj?.label;
  });

  const itemListSize = _.size(itemList);
  const selectedItemsSize = _.size(selectedItems);

  if (_.isEmpty(selectedItems)) {
    return noneSelectedText;
  } else if (selectedItemsSize === 1) {
    return _.first(labelsOfSelectedItems);
  } else if (selectedItemsSize === itemListSize) {
    return i18next.t("common:all");
  } else {
    return `${_.first(labelsOfSelectedItems)} , ${
      selectedItemsSize - 1
    } ${i18next.t("common:more")}`;
  }
};

/**
 * @param {Array} arrayOfObject Structure -> [{id:1,...},{id:2,...},{id:3,...}]
 * @param {Array} idsArray Structure -> [id1, id2]
 * @returns {Array} Structure -> [{id:id1,...},{id:id2,...}]
 */
const getMatchingIdsData = ({ arrayOfObject, idsArray }) => {
  const length = _.size(idsArray);

  let newArray = [];
  for (let i = 0; i < length; i++) {
    const foundObject = _.find(arrayOfObject, { id: idsArray[i] });

    if (!_.isEmpty(foundObject)) newArray = [...newArray, foundObject];
  }

  return newArray;
};
export const getMatchingIdsDataMemoize = _.memoize(
  params => getMatchingIdsData(params),
  params => JSON.stringify(params)
);

/**
 * @param {Array} superSet Structure -> [{id:1,...},{id:2,...},{id:3,...}]
 * @param {Array} subSet Structure -> [{id:1,...},{id:2,...}]
 * @returns {Array} [{id:3,...}]
 */
const getSuperMinusSub = ({ superSet, subSet }) => {
  return _.filter(superSet, ({ id }) => {
    const index = _.findIndex(
      subSet,
      subSetItem => _.get(subSetItem, "id") === id
    );
    const isElementPresent = index >= 0;

    // include if element is not present
    return !isElementPresent;
  });
};
export const getSuperMinusSubMemoize = _.memoize(
  params => getSuperMinusSub(params),
  params => JSON.stringify(params)
);

export const getFamilyInvitePdfType = () => {
  return ACLStore.can("FeatureFlag:EnableFamilyInvitePdfV2")
    ? "invitecodev2"
    : "invitecode";
};
