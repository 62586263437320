import React from "react";
import { PusherEvent } from "Constants";
import { getHOCDisplayName } from "Utils";

const PusherConsumer = events => {
  return WrappedComponent => {
    class WithPusherConsumer extends React.PureComponent {
      componentDidMount = () => {
        _.map(events, event => {
          PusherEvent.on(event, this.onPusherEventTriggered);
        });
      };

      onPusherEventTriggered = data => {
        const { onPusherEventTriggered } = this.props;
        if (onPusherEventTriggered) {
          onPusherEventTriggered(data);
        }
      };

      componentWillUnmount = () => {
        _.map(events, event => {
          PusherEvent.off(event, this.onPusherEventTriggered);
        });
      };

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    WithPusherConsumer.displayName = `WithPusherConsumer(${getHOCDisplayName(
      WrappedComponent
    )})`;
    return WithPusherConsumer;
  };
};

export default PusherConsumer;
