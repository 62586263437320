import React from "react";
import PropTypes from "prop-types";
import classes from "./CriteriaRubricAssessment.scss";
import { useI18n } from "Hooks";
import { InformationOutlined } from "@toddle-design/web-icons";
import classNames from "classnames";
import { getRatedScoreMemoized, getTotalScoreMemoized } from "./Utils.js";
import { Accordian, CriteriaRatingV2, DescriptorTable } from "UIComponents";
import { HelpButton } from "AppComponents";

const TYPE_UNMARKED = "unMarked";
const TYPE_EVALUATION = "evaluationScale";

//This is the width of the Rating Element defined in class - ratingElement
const RATING_ELEMENT_WIDTH = 40;

const styles = {
  accordianContainerStyle: {
    border: "none",
    padding: "0px 0px 4px 0px",
  },
  headerStyle: {
    minHeight: 80,
    padding: "20px 0px",
  },
};

//This Function returns cannot be assessed text for criterion
const CannotBeAssessedTextComp = props => {
  const { t, width, typeOfScale } = props;
  let style = {};

  if (typeOfScale == TYPE_EVALUATION) {
    style = { width };
  }

  const assessedTextContainerClass = classNames(
    classes.assessedTextContainer,
    "text-body-2"
  );
  return (
    <div className={assessedTextContainerClass} style={style}>
      <span>{t("common:criterion_cannot_be_assessed_text")}</span>
      <InformationOutlined size={"xxx-small"} variant={"subtle"} />
    </div>
  );
};

//This function returns the box based on the header and typeOFEvaluation
const MarksBox = props => {
  const {
    isParentNode = false,
    criteriaLabel = "",
    criterionIds = [],
    criteria,
    t,
    ratedCriteria,
    typeOfScale,
    isEditable = true,
  } = props;

  const totalScore = getTotalScoreMemoized({ criterionIds, criteria });

  let boxLabel = t("common:maximum_marks_with_score", { score: totalScore });

  const totalRatedScore = getRatedScoreMemoized({
    criterionIds,
    ratedCriteria,
  });

  if (typeOfScale == TYPE_EVALUATION) {
    const scoreText =
      totalRatedScore > 0 ? `${totalRatedScore}/${totalScore}` : "--";

    let label = t("common:total");

    if (isEditable) {
      label = criteriaLabel ? criteriaLabel : t("common:predicted");
    }

    boxLabel = t("common:score_with_label_and_score", {
      score: scoreText,
      label,
    });
  }

  const boxClass = classNames(
    { [classes.headerMarksBox]: true },
    { "heading-6": true },
    {
      [classes.unMarkedBox]:
        _.isEqual(typeOfScale, TYPE_UNMARKED) || totalRatedScore <= 0,
    },
    { [classes.criteriaBox]: !isParentNode },
    { "text-body-3": !isParentNode }
  );

  return <div className={boxClass}>{boxLabel}</div>;
};

//This is the component for Leaf nodes Ex : Criterion 1, etc
const LeafNodes = props => {
  const {
    leafNodes,
    t,
    criteria,
    typeOfScale,
    assessmentHeading,
    ...rest
  } = props;

  const getExpandedContent = ({ leafNode }) => {
    const objectiveRubricDescriptors = _.map(
      _.get(leafNode, "objectiveRubricDescriptors", []),
      item => {
        return {
          achievementLevel: _.get(item, "achievementLevel.label", ""),
          descriptor: _.get(item, "label", ""),
          subText: _.get(item, "achievementLevel.subText", ""),
        };
      }
    );

    const descriptorTableDescriptionClass = classNames(
      classes.descriptorTableDescription,
      "text-body-2"
    );
    const descriptorTableTitleClass = classNames(
      classes.descriptorTableTitle,
      "text-label-3"
    );

    return (
      <div className={classes.descriptorTableContainer}>
        {leafNode?.subText && (
          <div
            className={descriptorTableDescriptionClass}
            dangerouslySetInnerHTML={{
              __html: leafNode?.subText,
            }}
          />
        )}
        <div className={descriptorTableTitleClass}>
          {t("common:descriptor_table_label")}
        </div>
        <DescriptorTable
          rowData={objectiveRubricDescriptors}
          firstColumnTitle={"Marks"}
          secondColumnTitle={"Level descriptors"}
        />
      </div>
    );
  };

  //This function is used to return right container of custom Title Component of Criterion
  //which are CannotBeAssessedTextComp when it is not assessed
  //          marks box for "unMarked" evaluation Type
  //          Criteria Rating for "evaluationScale"
  const getRightComponent = ({ leafNode, rightContainerWidth }) => {
    const canBeAssessed = _.get(leafNode, "canBeAssessed", true);

    return canBeAssessed ? (
      _.isEqual(typeOfScale, TYPE_UNMARKED) ? (
        <MarksBox
          criterionIds={[leafNode?.id]}
          criteria={criteria}
          t={t}
          typeOfScale={typeOfScale}
          {...rest}
        />
      ) : (
        <CriteriaRatingV2
          highestMark={_.get(leafNode, "highestMark", 0)}
          id={leafNode?.id}
          type={leafNode.type}
          width={rightContainerWidth}
          {...rest}
        />
      )
    ) : (
      <CannotBeAssessedTextComp
        t={t}
        width={rightContainerWidth}
        typeOfScale={typeOfScale}
      />
    );
  };

  const getTitleComponent = ({
    leafNode,
    maxMarks,
    assessmentHeading,
    numOfLeafNodes,
  }) => {
    const rightContainerWidth = (maxMarks + 1) * RATING_ELEMENT_WIDTH;
    const leftContainerWidth = !_.isEqual(typeOfScale, TYPE_UNMARKED)
      ? `calc(100% - ${rightContainerWidth + 24}px)`
      : `calc(100% - 160px)`;

    const leafNodeLabelClass = classNames(classes.leafNodeLabel, "heading-6");
    const label = _.isEqual(numOfLeafNodes, 1)
      ? assessmentHeading
      : leafNode?.label;

    return (
      <div className={classes.leafNodeHeader}>
        <div
          className={leafNodeLabelClass}
          style={{ width: leftContainerWidth }}
        >
          {label}
        </div>
        {getRightComponent({ leafNode, rightContainerWidth })}
      </div>
    );
  };

  const maxMarks = _.max(_.map(leafNodes, item => item?.highestMark));

  return _.map(leafNodes, leafNode => {
    return (
      <Accordian
        showLeftArrow={true}
        headerStyle={styles.headerStyle}
        mainContentContainerStyle={styles.accordianContainerStyle}
        customTitleComponent={getTitleComponent({
          leafNode,
          maxMarks,
          assessmentHeading,
          numOfLeafNodes: _.size(leafNodes),
        })}
        expandedContent={getExpandedContent({ leafNode })}
      />
    );
  });
};

const BodyContainerComp = props => {
  const { t, criteria, ...rest } = props;

  const singleLevel = !_.some(criteria, item => !_.isEqual(item.parent, null));
  const rootCriteria = _.filter(criteria, item => _.isEqual(item.parent, null));

  return singleLevel ? (
    <div className={classes.singleLevelBodyContainer}>
      <LeafNodes leafNodes={criteria} t={t} criteria={criteria} {...rest} />
    </div>
  ) : (
    <div className={classes.bodyContainer}>
      {_.map(rootCriteria, item => {
        const leafNodeIds = _.get(item, "children", []);
        const leafNodes = _.filter(criteria, item =>
          _.includes(leafNodeIds, item?.id)
        );

        const parentNodeLabelClass = classNames(
          classes.headerLabel,
          "text-label-1"
        );

        return (
          <div key={item.id}>
            <div className={classes.parentNodeHeader}>
              <div className={parentNodeLabelClass}>{item.label}</div>

              <MarksBox
                criterionIds={leafNodeIds}
                criteria={criteria}
                t={t}
                {...rest}
              />
            </div>
            <LeafNodes
              leafNodes={leafNodes}
              t={t}
              criteria={criteria}
              {...rest}
            />
          </div>
        );
      })}
    </div>
  );
};

const CriteriaRubricAssessment = props => {
  const {
    headerLabel,
    headerSubText,
    helpUID,
    containerStyle,
    ...rest
  } = props;

  const { t } = useI18n(["common"]);

  const headerLabelClass = classNames(classes.headerLabel, "heading-5");
  const headerSubTextClass = classNames(classes.headerSubText, "text-body-2");

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.header}>
        <div className={classes.headerLabelContainer}>
          <div className={headerLabelClass}>{headerLabel}</div>
          {!!headerSubText && (
            <div className={headerSubTextClass}>{headerSubText}</div>
          )}
        </div>
        <div className={classes.rightHeaderContainer}>
          <MarksBox t={t} isParentNode={true} {...rest} />

          {helpUID && <HelpButton uid={helpUID} />}
        </div>
      </div>
      <BodyContainerComp t={t} {...rest} />
    </div>
  );
};

CriteriaRubricAssessment.defaultProps = {
  //Two types : [TYPE_UNMARKED, TYPE_EVALUATION]
  typeOfScale: TYPE_UNMARKED,
  isEditable: true,
  headerLabel: "",
  headerSubText: "",
  //nodes of all the criteria / criterions of Assessment
  criteria: [],
  /** Array of  [{
    ibPYPElementId: 8 ( criterion id ),
    value: 5 (selected mark in that criterion),
  },
   .......] */
  ratedCriteria: [],
  // sectionUID for help Text
  helpUID: null,
  onSelectMark: () => {
    return null;
  },
  containerStyle: {},
  assessmentHeading: "",
};

CriteriaRubricAssessment.propTypes = {
  typeOfScale: PropTypes.string,
  isEditable: PropTypes.bool,
  headerLabel: PropTypes.string,
  headerSubText: PropTypes.string,
  criteria: PropTypes.array,
  ratedCriteria: PropTypes.array,
  onSelectMark: PropTypes.func,
  helpUID: PropTypes.string,
  containerStyle: PropTypes.object,
  assessmentHeading: PropTypes.string,
};

export default CriteriaRubricAssessment;
