import { graphql } from "react-apollo";
import {
  SNPv2_PERM,
  SNPv2_TEACHER_MODULE_PERM,
} from "Constants/permissionConstants";
import ACLStore from "lib/aclStore";
import { getSnPEvaluationCyclesQuery } from "../SnPQueries";
import { getSnPEvaluationCyclesFromCache } from "../SnPGraphqlHelpers";
import { getSnpV2Cycles } from "../SnPUtils";

const queryConfig = {
  name: "getSnPEvaluationCycles",
  alias: "getSnPEvaluationCycles",
  skip: ({ curriculumProgramId, loginActiveTab }) => {
    /**Run query only if user is not visitor, snp perms are enabled and
     * curriculumProgramId is not empty
     */

    if (!curriculumProgramId) return true;

    if (loginActiveTab === "visitor") {
      return true;
    }

    if (loginActiveTab === "teacher") {
      return !(
        ACLStore.can(SNPv2_PERM) && ACLStore.can(SNPv2_TEACHER_MODULE_PERM)
      );
    }

    return !ACLStore.can(SNPv2_PERM);
  },
  options: ({ organizationId, curriculumProgramId }) => ({
    fetchPolicy: "cache-and-network",
    variables: {
      id: organizationId,
      filters: {
        isArchived: false,
        curriculumProgramId,
      },
    },
  }),
  props({
    getSnPEvaluationCycles,
    ownProps: {
      organizationId,
      curriculumProgramId,
      isLoading = false,
      isData = true,
    },
  }) {
    const organizationData = getSnPEvaluationCyclesFromCache({
      id: organizationId,
      filters: {
        isArchived: false,
        curriculumProgramId,
      },
    });
    const cycles = _.get(organizationData, "snpEvaluationCycles", []);

    const newSnpCycles = getSnpV2Cycles({ cycles });

    const totalNewSnpCycles = _.size(newSnpCycles);

    return {
      isData: !_.isEmpty(organizationData) && isData,
      totalNewSnpCycles,
      isLoading:
        getSnPEvaluationCycles["networkStatus"] == 1 ||
        getSnPEvaluationCycles["networkStatus"] == 2 ||
        isLoading,
    };
  },
};

export default graphql(getSnPEvaluationCyclesQuery, queryConfig);
