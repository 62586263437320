import gql from "graphql-tag";
import { organizationFragment, rubricFragment } from "./RubricFragments";

export const getOrganizationRubricsQuery = gql`
  query getOrganizationRubrics($id: ID!, $filters: AssessmentToolFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationItem
      }
    }
  }
  ${organizationFragment.rubricList}
`;
