/* eslint-disable react/display-name */
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  Suspense,
} from "react";

// load reactmic on demand using React.lazy and Suspense
const ReactMic = React.lazy(async () => {
  try {
    const { ReactMic } = await import(
      /* webpackChunkName: "reactmic" */ "react-mic"
    );
    return { default: ReactMic };
  } catch (err) {
    window.location.reload();
  }
});

import classes from "./WithRecorder.scss";

import { DialogueBox, MediaPlaceholder } from "UIComponents";

import { formatTimer, prepareAttachmentFromBlob } from "Utils";

import { Alert } from "@toddle-design/web";
import { Loading } from "UIComponents";
const INTERVAL = 1;

const withRecorder = Component => ({
  autoStartRecording,
  draftRecording,
  limit,
  handleAudioStripVisibility,
  handleAudioFile,
  onCancel,
  onDraftModalOutsideClick,
  saveDraftRecording,
  updateAudioRecorderBlob,
  setToastMsg,
  ...props
}) => {
  // =====
  const { t } = props;
  const intervalRef = useRef(null);
  const offsetRef = useRef(0);
  const clockRef = useRef(0);
  const [isPermissionGranted, setIsPermissionGranted] = useState(true);
  const [playerState, setPlayerState] = useState("record");
  const [currentAction, setCurrentDialogueAction] = useState("");
  const [timer, setTimer] = useState(0);
  const [isPaused, setPaused] = useState(false);
  const draftRecordingBlobRef = useRef(null);
  const draftblob = _.get(draftRecordingBlobRef.current, "blob", null);
  const calculatedPercent = Math.abs((timer * 100) / limit);
  // =====
  // =====
  /**
   * If auto start is true and there is no draft
   * recording then start the recording
   */

  const checkPermissions = () => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          setIsPermissionGranted(true);
          stream.stop();
        })
        .catch(error => {
          setIsPermissionGranted(false);
        });
    }
  };
  useEffect(() => {
    checkPermissions();
    if (autoStartRecording && !draftRecording) {
      startRecording();
    }
    // clear interval on unmount
    return () => {
      if (intervalRef && intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPermissionGranted]);

  /**
   * Check if isPaused is changed
   * and clear the interval or start
   * the time interval again
   */
  useEffect(() => {
    if (
      isPaused &&
      playerState === "recording" &&
      intervalRef &&
      intervalRef.current
    ) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    } else if (
      !isPaused &&
      playerState === "recording" &&
      intervalRef &&
      !intervalRef.current
    ) {
      offsetRef.current = Date.now();
      intervalRef.current = setInterval(updateTime, INTERVAL);
    }
  }, [isPaused]);

  useEffect(() => {
    if (draftRecording) {
      setCurrentDialogueAction("DRAFT_RECORDING");
    }
  }, [draftRecording]);

  useEffect(() => {
    if (timer >= limit) stopRecording();
  }, [timer]);

  useEffect(() => {
    if (playerState === "cancelled") {
      handleAudioStripVisibility();
    }
  }, [playerState]);

  const startRecording = useCallback(async () => {
    checkPermissions();
    setPlayerState("recording");
  }, []);

  const stopRecording = useCallback(() => {
    if (playerState === "recording") {
      setPlayerState("completed");
    }
    stopTimer();
  }, [playerState]);

  const cancelRecording = useCallback(async () => {
    await stopTimer();
    await setPlayerState("cancelled");
    onCancel();
  }, []);

  const startTimer = () => {
    if (!isPaused) {
      offsetRef.current = Date.now();
      intervalRef.current = setInterval(updateTime, INTERVAL);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setTimer(0);
  };

  /**
   * Calculate the elapsed time
   */
  const delta = () => {
    const now = Date.now();
    const d = now - offsetRef.current;
    offsetRef.current = now;
    return d;
  };

  const updateTime = () => {
    clockRef.current += delta();
    setTimer(clockRef.current);
  };

  // save audio blob in redux when react-mic unmounts
  const onMicUnmount = blob => {
    if (!saveDraftRecording) {
      return;
    }
    blob["duration"] = clockRef.current;
    updateAudioRecorderBlob(blob);
    setToastMsg("common:save_audio_as_draft");
  };

  const handleRecordedData = recordedBlob => {
    // handle the stale playerState due to async onStop and closure
    setPlayerState(state => {
      if (state === "completed") {
        try {
          const attachment = prepareAttachmentFromBlob(recordedBlob);
          handleAudioFile(attachment);
        } catch {}
      }
      // No state change
      updateAudioRecorderBlob(null);
      return state;
    });
  };
  // =====
  // ===== Dialog Box =====
  const DIALOG_INFO = {
    DRAFT_RECORDING: {
      title: t("common:audio_recorder"),
      modalBody: <AudioModalBody recordedBlob={draftRecording} t={t} />,
      buttonOneText: t("common:continue_with_this_recording"),
      buttonTwoText: t("common:discard_and_record_new"),
      modalBodyStyle: {
        padding: "0px 40px 0px 40px",
      },
      button1Props: {
        color: "blue",
        containerStyle: {
          width: "100%",
        },
      },
      button2Props: {
        color: "pink",
        containerStyle: {
          width: "100%",
        },
      },
      footerContainerStyle: {
        padding: "0px 40px 40px 40px",
      },
      button1Styles: {
        flex: 1,
        marginRight: "8px",
      },
      button2Styles: {
        flex: 1,
        marginLeft: "8px",
      },
      headerStyles: {
        padding: "40px 40px 8px 40px",
      },
    },
  };
  const onClickButton1 = () => {
    const { duration } = draftRecording;
    switch (currentAction) {
      case "DRAFT_RECORDING":
        // pause the recording and let user continue from previous draft recording
        setPaused(true);
        clockRef.current = duration;
        setTimer(clockRef.current);
        draftRecordingBlobRef.current = draftRecording;
        updateAudioRecorderBlob(null);
        setPlayerState("recording");
        break;
    }
  };
  const onClickButton2 = async () => {
    switch (currentAction) {
      case "DRAFT_RECORDING":
        draftRecordingBlobRef.current = null;
        updateAudioRecorderBlob(null);
        clockRef.current = 0;
        setPlayerState("recording");
        break;
    }
  };
  // =====
  return (
    <>
      <Suspense fallback={<Loading />}>
        {currentAction && !!draftRecording && saveDraftRecording && (
          <DialogueBox
            modalTitle={DIALOG_INFO[currentAction].title}
            modalBody={DIALOG_INFO[currentAction].modalBody}
            showModal={true}
            button1={DIALOG_INFO[currentAction].buttonOneText}
            button2={DIALOG_INFO[currentAction].buttonTwoText}
            modalBodyStyle={DIALOG_INFO[currentAction].modalBodyStyle}
            onClickButton1={onClickButton1}
            onClickButton2={onClickButton2}
            toggleDialogueBoxDisplay={(params = {}) => {
              setPlayerState(prevState => {
                if (prevState !== "recording") {
                  return "cancelled";
                }
                return prevState;
              });
              setCurrentDialogueAction("");
              const { isCancel } = params;
              if (isCancel && onDraftModalOutsideClick) {
                onDraftModalOutsideClick();
              }
            }}
            button1Props={DIALOG_INFO[currentAction].button1Props}
            button2Props={DIALOG_INFO[currentAction].button2Props}
            footerContainerStyle={
              DIALOG_INFO[currentAction].footerContainerStyle
            }
            customModalContentClass={classes.dialogContent}
            showCloseButton={false}
            button1Styles={DIALOG_INFO[currentAction].button1Styles}
            button2Styles={DIALOG_INFO[currentAction].button2Styles}
            headerStyles={DIALOG_INFO[currentAction].headerStyles}
          />
        )}
        {!draftRecording && (
          <>
            <ReactMic
              record={isPermissionGranted && playerState === "recording"}
              className={classes.hideReactMicUI}
              onStop={handleRecordedData}
              mimeType="audio/mp3"
              onStart={startTimer}
              onUnmount={onMicUnmount}
              isPaused={isPaused}
              draftRecordingBlob={draftblob}
            />
            <Component
              isPaused={isPaused}
              togglePause={setPaused}
              timer={formatTimer(timer)}
              formattedLimit={formatTimer(limit)}
              isPermissionGranted={isPermissionGranted}
              playerState={playerState}
              startRecording={startRecording}
              stopRecording={stopRecording}
              cancelRecording={cancelRecording}
              calculatedPercent={calculatedPercent}
              timeStamp={timer}
              {...props}
            />
          </>
        )}
      </Suspense>
    </>
  );
};

/**
 * Draft Audio Modal Body
 */
const AudioModalBody = ({ recordedBlob, t }) => {
  const attachment = prepareAttachmentFromBlob(recordedBlob);
  return (
    <div className={classes.modalBodyContainer}>
      <div className={classes.description}>{t("common:unused_recording")}</div>
      <div className={classes.playerContainer}>
        <MediaPlaceholder
          attachments={[attachment]}
          mode="view"
          showDownloadIcon={false}
        />
      </div>
    </div>
  );
};

export default withRecorder;
