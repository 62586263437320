import React from "react";
import PropTypes from "prop-types";

import classes from "./CustomLoginButton.scss";

const CustomLoginButton = props => {
  const { iconUrl, buttonText, onClick, innerConStyle, icon } = props;

  return (
    <div
      className={classes.container}
      data-test-id={props["data-test-id"]}
      onClick={onClick}
    >
      <div className={classes.innerContainer} style={innerConStyle}>
        <div className={classes.iconContainer}>
          {iconUrl && <img src={iconUrl} />}
          {!!icon && icon}
        </div>
        <div className={classes.buttonText}>{buttonText}</div>
      </div>
    </div>
  );
};

CustomLoginButton.defaultProps = {
  innerConStyle: {},
  ["data-test-id"]: "button-custom-login",
};

CustomLoginButton.propTypes = {
  iconUrl: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  innerConStyle: PropTypes.object,
};

export default CustomLoginButton;
