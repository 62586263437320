import React from "react";
import classes from "./DropDown.scss";
import { ButtonDropdown } from "UIComponents";
import { MoreIcon } from "SvgComponents";
import { colors } from "Constants";
import { useTranslation } from "react-i18next";
import ACLStore from "lib/aclStore";
const buttonDropDownContainerStyle = {
  marginTop: -12,
  minWidth: 120,
  width: "max-content",
  right: 0,
};

const buttonDropDownItemStyle = {
  height: 32,
};

const changeLocale = (menuItems, t) => {
  const menus = [];
  _.forEach(menuItems, element => {
    const { label, perm } = element;
    if (!perm || ACLStore.can(perm)) {
      menus.push({ ...element, label: t(label) });
    }
  });
  return menus;
};

const DropDown = ({
  item,
  onDeleteClick,
  onEditClick,
  onDuplicateClick,
  onPrintClick,
  onAddToCommunityClick,
}) => {
  const menuItems = [
    {
      label: "edit",
      clickAction: () => {
        onEditClick({ id: item.id, mode: "edit" });
      },
      svg: null,
      isDisable: false,
    },
    {
      label: "duplicate",
      clickAction: () => {
        onDuplicateClick(item);
      },
      svg: null,
      isDisable: false,
    },
    {
      label: "print",
      clickAction: () => {
        onPrintClick(item);
      },
      svg: null,
      isDisable: false,
    },
    {
      label: "delete",
      clickAction: () => {
        onDeleteClick(item);
      },
      svg: null,
      isDisable: false,
    },
    {
      label: "add_to_community",
      clickAction: () => {
        onAddToCommunityClick(item);
      },
      svg: null,
      isDisable: false,
      permission: "TeacherPortal:Community",
    },
  ];

  const { t } = useTranslation("common");

  return (
    <div className={classes.container}>
      <ButtonDropdown
        authTabs={changeLocale(menuItems, t)}
        containerStyle={buttonDropDownContainerStyle}
        buttonParentStyle={{ padding: "0px 16px", marginRight: "-16px" }}
        itemStyle={buttonDropDownItemStyle}
        buttonComponent={
          <MoreIcon width={16} height={16} fill={colors.warmGrey} />
        }
      />
    </div>
  );
};

export default DropDown;
