import { Avatar } from "@toddle-design/web";
import React from "react";
import classes from "./DualProfile.scss";
import { AVATAR_COLORS } from "Constants/colorConstants";

export const DualProfile = props => {
  const { user1 = {}, user2 = {} } = props;

  return (
    <div className={classes.cascadedProfileContainer}>
      {user1 && !_.isEmpty(user1) && (
        <Avatar
          name={_.upperCase(user1?.value)}
          src={user1?.imageUrl}
          shade={"light"}
          color={AVATAR_COLORS[user1?.id % AVATAR_COLORS.length]}
          size={"medium"}
        />
      )}

      {user2 && !_.isEmpty(user2) && (
        <div className={classes.cascadedProfile}>
          <Avatar
            name={_.upperCase(user2?.value)}
            src={user2?.imageUrl}
            shade={"light"}
            color={AVATAR_COLORS[user2?.id % AVATAR_COLORS.length]}
            size={"medium"}
          />
        </div>
      )}
    </div>
  );
};
