import gql from "graphql-tag";

/*
 * TODO: Hide child status due to performance issue
 * in backend. Uncomment when fixed. And remove the
 * gradeFragment currently in use
 */
// export const gradeFragment = gql`
//   fragment gradeItem on Grade {
//     id
//     name
//     globalGrade {
//       id
//       uid
//       name
//       constants
//       displaySequence
//     }
//     childStatus {
//       message
//       status
//     }
//     curriculumProgram {
//       id
//       type
//     }
//   }
// `;

export const gradeFragment = gql`
  fragment gradeItem on Grade {
    id
    name
    globalGrade {
      id
      uid
      name
      constants
      displaySequence
    }
    curriculumProgram {
      id
      type
    }
  }
`;

export const organizationGradeItem = gql`
  fragment organizationItem on Organization {
    id
    grades(filters: $filters) {
      ...gradeItem
    }
  }
  ${gradeFragment}
`;
