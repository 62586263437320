import React, { memo } from "react";
import { Tick } from "SvgComponents";
import classes from "./AssignmentFeed.scss";
import { ASSIGNMENT_TYPE_MAPPING } from "../AssignmentSelectionUtils";
import { ResourceCardV1 } from "AppComponents";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { colors } from "Constants";

const ContentTypeThumbnail = memo(props => {
  const { type } = props;

  return (
    <div className={classes.iconContainer}>
      {ASSIGNMENT_TYPE_MAPPING[type].iconElement}
    </div>
  );
});
ContentTypeThumbnail.displayName = "ContentTypeThumbnail";

const AssignmentFeed = props => {
  const {
    assignments,
    selectedItems,
    updateSelectedItems,
    disabledItems,
    showTypeLabel,
    customOverlayElement,
    styles,
  } = props;

  const checkIsSelected = ({ id }) => {
    return _.includes(selectedItems, id);
  };

  const checkIsDisabled = ({ id }) => {
    return _.includes(disabledItems, id);
  };

  const onClickSelect = ({ id }) => {
    if (checkIsSelected({ id })) {
      updateSelectedItems(_.difference(selectedItems, [id]));
    } else {
      updateSelectedItems([...selectedItems, id]);
    }
  };

  return _.map(assignments, assignment => {
    const { id, subTitle, image, type } = assignment;

    const isSelected = checkIsSelected({ id });
    const isDisabled = checkIsDisabled({ id });

    const onCardClick = () => onClickSelect({ id });

    return (
      <div key={id} className={classes.feedItemContainer}>
        <ConditionalWrapper
          key={id}
          type="wrapperAsElement"
          wrapper={customOverlayElement}
          data={assignment}
          isSelected={isSelected}
          onCardClick={onCardClick}
          condition={!!customOverlayElement}
        >
          <ResourceCardV1
            item={assignment}
            customSubTitle={subTitle}
            customImageElement={
              _.isEmpty(_.get(image, "value", "")) ? (
                <ContentTypeThumbnail type={type} />
              ) : null
            }
            isDisabled={isDisabled}
            onContainerClick={onCardClick}
            theme={_.get(styles, "card", {})}
            rightComponent={
              !customOverlayElement && isSelected ? (
                <Tick
                  width={24}
                  height={24}
                  fillBackground={colors.blue29}
                  fillTick={colors.white}
                />
              ) : null
            }
            showTypeLabel={showTypeLabel}
          />
        </ConditionalWrapper>
      </div>
    );
  });
};

export default AssignmentFeed;

AssignmentFeed.defaultProps = {
  showTypeLabel: false,
};
