import React, { useRef } from "react";
import { SearchItem } from "../SearchItem/SearchItem";

export const RecentSearchList = props => {
  const { data = [], onSelectItem, searchText, cursor, onCursorFocus } = props;
  const ref = useRef([]);

  return (
    <div className="flex flex-col" role="list">
      {data.map((item, index) => {
        const { id } = item;
        return (
          <SearchItem
            key={id}
            id={id}
            item={item}
            onSelectItem={onSelectItem}
            searchText={searchText}
            uiState={{
              isSelected: cursor == id,
              index,
              cursor,
            }}
            onFocus={onCursorFocus}
            onFocusIn={() => {
              onCursorFocus({ id });
            }}
            onFocusOut={() => {
              onCursorFocus({ id: "search" });
            }}
            ref={ref}
          />
        );
      })}
    </div>
  );
};
