export default {
  stage: ["stage1"],
  stageList: {
    stage1: {
      label: `onBoarding:stage1_label`,
      subLabel: `onBoarding:stage1_sub_label`,
      steps: [
        "BASIC_INFO",
        "PASSION",
        "PROFESSION",
        "INTERESTED_TOPICS_STEP",
        "SHARE_PASSION_STEP",
      ],
    },
    stage2: {
      label: `onBoarding:stage2_label`,
      steps: [
        "WORKSHOP",
        "TRAININGS",
        "INTERESTED_TOPICS_STEP",
        "SHARE_PASSION_STEP",
      ],
    },
  },
  stepList: {
    BASIC_INFO: {
      fields: ["PROFILE_PHOTO", "BASIC_DETAILS", "EMAIL_MESSAGE"],
    },
    BRIEF: {
      fields: ["SELF_INFO"],
    },
    PASSION: {
      fields: ["PASSIONATE", "YOE"],
    },
    PROFESSION: {
      fields: ["CURRENT_PROFESSION"],
    },
    PAST_PROFESSION: {
      fields: ["PAST_PROFESSION"],
    },
    WORKSHOP: {
      fields: ["WORKSHOP_1", "WORKSHOP"],
    },
    TRAININGS: {
      fields: ["TRAINING_1", "TRAINING"],
    },
    INTERESTED_TOPICS_STEP: {
      fields: ["INTERESTED_TOPICS"],
    },
    SHARE_PASSION_STEP: {
      fields: ["SHARE_PASSION"],
    },
  },
  fieldList: {
    PROFILE_PHOTO: {
      type: "PROFILE_PHOTO_PICKER",
      config: {
        errorText: "community:valid_profile_photo",
        isRequired: true,
        key: "profileImage",
        styles: {
          alignSelf: "flex-start",
          marginBottom: "64px",
          fontSize: "1.6rem",
        },
      },
    },
    BASIC_DETAILS: {
      type: "NLF",
      config: {
        sentence: `onBoarding:basic_details_label`,
        isRequired: true,
        errorText: "community:fill_required_details",
        showErrorState: true,
        inputOptions: {
          firstName: {
            key: "firstName",
            type: "INPUT",
            placeholder: "onBoarding:first_name_placeholder",
            required: true,
            errorText: "community:valid_first_name",
          },
          lastName: {
            key: "lastName",
            type: "INPUT",
            placeholder: "onBoarding:last_name_placeholder",
            required: true,
            errorText: "community:valid_last_name",
          },
          city: {
            key: "communityDetails.city",
            type: "INPUT",
            placeholder: "onBoarding:city_placeholder",
            required: true,
            errorText: "community:valid_city",
          },
          country: {
            key: "communityDetails.country",
            type: "INPUT",
            placeholder: "onBoarding:country_placeholder",
            required: true,
            errorText: "community:valid_country",
          },
          email: {
            key: "communityDetails.personalEmail",
            type: "INPUT",
            placeholder: "onBoarding:email_placeholder",
            required: true,
            validation: "EMAIL",
            errorText: "common:valid_email_msg",
          },
        },
      },
    },
    EMAIL_MESSAGE: {
      type: "PERSONAL_EMAIL",
      config: {
        message: `onBoarding:personal_email_msg`,
      },
    },
    SELF_INFO: {
      type: "TEXTAREA",
      config: {
        key: "communityDetails.aboutMe",
        label: "onBoarding:about_me_label",
        placeholder: "onBoarding:about_me_placeholder",
      },
    },
    PASSIONATE: {
      type: "TAGS",
      config: {
        errorText: "community:valid_role",
        styles: {
          marginBottom: "0",
        },
        key: "interests",
        label: "onBoarding:interest_label",
        multiSelect: true,
        isRequired: true,
      },
    },
    YOE: {
      type: "NLF",
      config: {
        isRequired: true,
        sentence: "onBoarding:years_experience_label",
        inputOptions: {
          yoe: {
            required: true,
            key: "communityDetails.experience",
            type: "INPUT",
            placeholder: "onBoarding:number_placeholder",
            inputType: "NUMBER",
            validation: "DECIMAL",
            errorText: "community:valid_number",
          },
        },
        styles: { marginTop: "-12px" },
      },
    },
    CURRENT_PROFESSION: {
      type: "NLF",
      config: {
        isRequired: true,
        dependencyKey: "interests",
        sentence: `onBoarding:current_profession_label`,
        sentence2: `current_profession_label_alternate`,
        key: "currentSchools",
        multiValue: true,
        showIndividualErrors: true,
        inputOptions: {
          grades: {
            type: "SELECT",
            placeholder: "onBoarding:grade_placeholder",
            optionKey: "grades",
            isUpdateValueWithLabel: true,
            multi: true,
            customStyles: {
              minWidth: "164px",
              width: "100%",
            },
          },
          school: {
            type: "CUSTOM",
            placeholder: "onBoarding:school_name_placeholder",
            allowAddOption: true,
            isUpdateValueWithLabel: true,
            isAsync: true,
            clearable: true,
            customStyles: {
              width: "480px",
            },
          },
          startMonth: {
            type: "SELECT",
            placeholder: "onBoarding:month_placeholder",
            optionKey: "monthOptions",
            required: true,
            errorText: "community:valid_month",
            customStyles: {
              width: "160px",
            },
          },
          startYear: {
            type: "SELECT",
            placeholder: "onBoarding:year_placeholder",
            optionKey: "yearOptions",
            required: true,
            errorText: "community:valid_year",
            customStyles: {
              width: "100px",
            },
            validation: "YEAR",
          },
        },
        styles: { lineHeight: "6rem" },
      },
    },
    PAST_PROFESSION: {
      type: "NLF",
      config: {
        sentence: `onBoarding:past_profession_label`,
        multiInput: true,
        multiInputLabel: "onBoarding:add_school",
        key: "pastSchools",
        deleteLabel: "onBoarding:past_experience",
        inputOptions: {
          school: {
            type: "CUSTOM",
            placeholder: "onBoarding:school_name_placeholder",
            allowAddOption: true,
            isUpdateValueWithLabel: true,
            isAsync: true,
            clearable: true,
            customStyles: {
              width: "480px",
            },
          },
          country: {
            type: "INPUT",
            placeholder: "onBoarding:country_placeholder",
            key: "school.country",
          },
          startMonth: {
            type: "SELECT",
            placeholder: "onBoarding:month_placeholder",

            optionKey: "monthOptions",
            customStyles: {
              width: "160px",
            },
          },
          startYear: {
            type: "SELECT",
            placeholder: "onBoarding:year_placeholder",
            optionKey: "yearOptions",
            customStyles: {
              width: "72px",
            },
          },
          endMonth: {
            type: "SELECT",
            placeholder: "onBoarding:month_placeholder",
            optionKey: "monthOptions",
            customStyles: {
              width: "160px",
            },
          },
          endYear: {
            type: "SELECT",
            placeholder: "onBoarding:year_placeholder",
            optionKey: "yearOptions",
            customStyles: {
              width: "72px",
            },
          },
        },
      },
    },
    WORKSHOP_1: {
      type: "NLF",
      config: {
        sentence: `onBoarding:workshop_label`,
        inputOptions: {
          numberOfworkshops: {
            type: "INPUT",
            placeholder: "onBoarding:number_placeholder",
            key: "communityDetails.numberOfworkshops",
          },
        },
      },
    },
    WORKSHOP: {
      type: "NLF",
      config: {
        sentence: `onBoarding:workshop_sentence`,
        multiInput: true,
        multiInputLabel: "onBoarding:add_workshop",
        key: "workshops",
        deleteLabel: "onBoarding:workshop",
        inputOptions: {
          workshopCategory: {
            type: "SELECT",
            placeholder: "onBoarding:workshop_category",
            optionKey: "workshopCategories",
            isUpdateValueWithLabel: true,
            customContainerStyle: {
              width: "45%",
            },
            customStyles: {
              width: "300px",
            },
          },
          label: {
            type: "INPUT",
            placeholder: "onBoarding:workshop_name",
            customContainerStyle: {
              width: "45%",
            },
            customStyles: {
              width: "100%",
              minWidth: "100%",
            },
          },
        },
      },
    },
    TRAINING_1: {
      type: "TEXT",
      config: {
        label: "onBoarding:training_label",
      },
    },
    TRAINING: {
      type: "NLF",
      config: {
        sentence: `onBoarding:training_sentence`,
        multiInput: true,
        multiInputLabel: "onBoarding:add_training",
        key: "trainings",
        deleteLabel: "onBoarding:training",
        inputOptions: {
          label: {
            type: "INPUT",
            placeholder: "onBoarding:training",
            customContainerStyle: {
              width: "90%",
            },
            customStyles: {
              width: "100%",
              minWidth: "100%",
            },
          },
        },
      },
    },
    INTERESTED_TOPICS: {
      type: "TAGS",
      config: {
        errorText: "community:valid_topic",
        isRequired: true,
        key: "topics",
        label: "onBoarding:topics_sentence",
        multiSelect: true,
      },
    },
    SHARE_PASSION: {
      type: "SEARCH_WITH_TAG",
      config: {
        errorText: "community:valid_skill",
        isRequired: true,
        key: "skills",
        resultSize: 10,
        label: "onBoarding:skill_sentence",
        placeholder: "onBoarding:skill_placeholder",
        placeholder_alt: "onBoarding:skill_placeholder_alt",
        noResultText: "onBoarding:noResultText",
      },
    },
  },
};
