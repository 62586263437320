import client from "apolloClient";
import {
  organizationResourceFeedFragment,
  organizationChildResourceFragment,
  organizationResourceFragment,
} from "./OrganizationResourcesFragments";

import {
  getOrganizationResourcesBasicDetailsQuery,
  getOrganizationSyncStatusQuery,
} from "./OrganizationResourcesQuery";

const resourcesFragmentMap = {
  ORGANIZATION: {
    typeName: "Organization",
    fragment: organizationResourceFeedFragment.organizationResourceFeed,
    fragmentName: "organizationResourceFeedItem",
  },
  UNIT_PLAN: {
    typeName: "UnitPlan",
    fragment: organizationResourceFeedFragment.unitPlanResourceFeed,
    fragmentName: "unitPlanResourceFeedItem",
  },
  ASSESSMENT: {
    typeName: "Assessment",
    fragment: organizationResourceFeedFragment.assessmentResourceFeed,
    fragmentName: "assessmentResourceFeedItem",
  },
  COURSE: {
    typeName: "Course",
    fragment: organizationResourceFeedFragment.studentDriveResourceFeed,
    fragmentName: "studentDriveResourceFeedItem",
  },
};

export const getOrganizationResourceFromCache = ({ id }) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `OrganizationResource:${id}`,
      fragment: organizationResourceFragment.organizationResource,
      fragmentName: "organizationResourceItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getOrganizationResourcesFromCache = ({
  filters,
  parentType,
  parentId,
  orderBy,
  orderByDirection,
  organizationResourceFilter,
}) => {
  let queryData = {};
  const { typeName, fragment, fragmentName } = resourcesFragmentMap[parentType];
  try {
    queryData = client.readFragment({
      id: `${typeName}:${parentId}`,
      fragment,
      fragmentName,
      variables: {
        ...filters,
        orderBy,
        orderByDirection,
        organizationResourceFilter,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeOrganizationResourcesInCache = ({
  filters,
  data,
  parentType,
  parentId,
  orderBy,
  orderByDirection,
  organizationResourceFilter,
}) => {
  const { typeName, fragment, fragmentName } = resourcesFragmentMap[parentType];
  try {
    client.writeFragment({
      id: `${typeName}:${parentId}`,
      fragment,
      fragmentName,
      variables: {
        ...filters,
        orderBy,
        orderByDirection,
        organizationResourceFilter,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getChildResourcesFromCache = ({
  id,
  searchText,
  visibleTo,
  filters,
  orderBy,
  orderByDirection,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `OrganizationResource:${id}`,
      fragment: organizationChildResourceFragment.childResourceFragment,
      fragmentName: "organizationChildResourceItem",
      variables: {
        searchText,
        visibleTo,
        filters,
        orderBy,
        orderByDirection,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getChildResourcesFromCacheV2 = ({
  id,
  first,
  after,
  orderBy,
  orderByDirection,
  filters,
}) => {
  let queryData = {};

  try {
    queryData = client.readFragment({
      id: `OrganizationResource:${id}`,
      fragment: organizationChildResourceFragment.childResourceFragmentV2,
      fragmentName: "organizationChildResourceItem",
      variables: {
        first,
        after,
        filters,
        orderBy,
        orderByDirection,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeChildResourcesInCache = ({
  id,
  searchText,
  visibleTo,
  filters,
  orderBy,
  orderByDirection,
  data,
}) => {
  try {
    client.writeFragment({
      id: `OrganizationResource:${id}`,
      fragment: organizationChildResourceFragment.childResourceFragment,
      fragmentName: "organizationChildResourceItem",
      variables: {
        searchText,
        visibleTo,
        filters,
        orderBy,
        orderByDirection,
      },
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getOrganizationResourcesBasicDetailsFromCache = ({
  parentId,
  filters,
  parentType,
  types,
  visibleTo,
  resourceType,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationResourcesBasicDetailsQuery,
      variables: {
        parentId,
        filters,
        parentType,
        types,
        visibleTo,
        resourceType,
      },
    });
  } catch (e) {
    console.error(e);
    return queryData;
  }

  return queryData;
};

export const getOrganizationSyncStatusFromCache = ({ id, type }) => {
  try {
    const queryData = client.readQuery({
      query: getOrganizationSyncStatusQuery,
      variables: {
        id: id,
        type,
      },
    });

    return queryData ? _.get(queryData, "node", {}) : {};
  } catch (e) {
    return {};
  }
};
