import gql from "graphql-tag";
import {
  courseCalendarDetailFragment,
  calendarEventDetailFragment,
  calendarEventFragment,
  courseItemFragment,
} from "./MyCalendarFragments";

export const getCourseCalendarDetailsQuery = gql`
  query getCourseCalendarDetails(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: CourseFilter
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        ...staffItem
      }
      ... on Student {
        ...studentItem
      }
    }
  }
  ${courseCalendarDetailFragment.staffItem}
  ${courseCalendarDetailFragment.studentItem}
`;

export const getCalendarOtherCoursesDetailQuery = gql`
  query getCalendarOtherCoursesDetail($id: ID!, $filters: CourseFilterInput) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        courses(orderBy: TITLE, orderByDirection: ASC, filters: $filters) {
          edges {
            node {
              ...courseItem
            }
          }
        }
      }
    }
  }
  ${courseItemFragment.courseItem}
`;

export const getCalendarEventsQuery = gql`
  query getCalendarEvents(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UserCoursesCalendarFilter!
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        ...staffItem
      }
      ... on Student {
        ...studentItem
      }
    }
  }
  ${calendarEventFragment.studentItem}
  ${calendarEventFragment.staffItem}
`;

export const getCalendarEventDetailsQuery = gql`
  query getCalendarEventDetails($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on PlatformCalendarEvent {
        id
        startTime
        endTime
        calendars {
          id
          parentType
          parent {
            ... on Course {
              ...courseItem
            }
          }
        }
        itemType
        item {
          id
          ... on Assignment {
            id
            createdBy {
              ...staffItem
            }
            assignedStudents {
              id
              student {
                ...studentItem
              }
            }
            state {
              state
              publishedAt
              updatedAt
            }
            contentType
            content {
              id
              ... on Assessment {
                id
                title {
                  id
                  value
                }
                assessmentType {
                  id
                  value
                }
              }
              ... on AssignmentResource {
                id
                label
                assignmentTitle: title
                meeting {
                  ...meetingItem
                }
              }
            }
          }
          ... on CalendarEventItem {
            id
            label
            description
            users {
              ...studentItem
            }
            createdBy {
              ...staffItem
            }
            meeting {
              ...meetingItem
            }
            recurringSetting {
              frequency
              weekDays
              interval
              endDate
              monthDays
              setPositions
            }
          }
        }
      }
    }
  }

  ${calendarEventDetailFragment.courseItem}
  ${calendarEventDetailFragment.staffItem}
  ${calendarEventDetailFragment.studentItem}
  ${calendarEventDetailFragment.meetingItem}
`;

export const getCalendarEventsTrimmedQuery = gql`
  query getCalendarEventsTrimmed(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UserCoursesCalendarFilter!
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        calendarEvents(filters: $filters) {
          id
          startTime
        }
      }
      ... on Student {
        id
        calendarEvents(filters: $filters) {
          id
          startTime
        }
      }
    }
  }
`;
