import React from "react";
import {
  StudentPortfolioSvg,
  POISvg,
  SchoolPolicy,
  ScopeAndSequenceSvg,
  TeacherResourcesSvg,
  ReportsSvg,
  ReportAndPortfolioIcon,
  FamilyCommunicationIcon,
  PremiumFeaturesIcon,
  ClassroomSvg,
  ProgressSummarySvg,
} from "SvgComponents";
import {
  CalendarScheduleOutlined,
  CalendarScheduleWeekOutlined,
  CalendarOutlined,
  UnitLibraryOutlined,
  LeLibraryOutlined,
  ClassSettingsOutlined,
  NotificationSettingsOutlined,
  FamilyOutlined,
  FolderOutlined,
  ClassProgressOutlined,
  OpenBookOutlined,
  SettingsOutlined,
  UnitPlanOutlined,
  AssessmentEvaluatedOutlined,
  JournalOutlined,
  ProgressReportOutlined,
  AssessmentandEvaluationOutlined,
  PolicyTickOutlined,
  TeacherResourceOutlined,
  InsightsSquareOutlined,
  CommentMultipleOutlined,
  StudentOutlined,
  AttendanceOutlined,
  ClassProgressSummaryOutlined,
  CommentSquareLeftClassroomOutlined,
  TickFrameOutlined,
} from "@toddle-design/web-icons";

export const TeacherMenu = {
  teacherPoralIBPYPFreemiumRootNodes: ["UNIT_PLANS", "PREMIUM_FEATURES"],

  // default teacher
  defaultTeacherNodeId: "UNIT_PLANS",

  // default student
  defaultPYPStudentNodeId: "CLASS_ROOM",
  defaultMYPStudentNodeId: "CLASS_ROOM_MYP",
  defaultUBDStudentNodeId: "CLASS_ROOM_UBD",

  // default parent
  defaultParentNodeId: "JOURNAL",

  // teacher root nodes
  teacherPortalIBPYPRootNodes: [
    "PLANNING",
    "CLASSROOM",
    "CLASS_PROGRESS",
    "EDUCATOR_CENTER",
    "STUDENT_PORTFOLIO",
    "SETTINGS",
  ],

  teacherPortalIBMYPRootNodes: [
    "PLANNING",
    "CLASSROOM",
    "CLASS_PROGRESS",
    "EDUCATOR_CENTER",
    "STUDENT_PORTFOLIO",
    "SETTINGS",
  ],
  teacherPortalUBDRootNodes: [
    "PLANNING",
    "UBD_CLASS",
    "CLASS_PROGRESS",
    "EDUCATOR_CENTER",
    "STUDENT_PORTFOLIO",
    "SETTINGS",
  ],
  teacherPortalIBDPRootNodes: [
    "PLANNING",
    "CLASSROOM",
    "CLASS_PROGRESS",
    "DP_STUDENT_PORTFOLIO",
    "EDUCATOR_CENTER",
    "SETTINGS",
  ],
  teacherPortalIBDPCASRootNodes: ["PROJECT_GROUP", "CLASS_ROOM"],
  teacherPortalIBDPTOKRootNodes: ["PLANNING", "PROJECT_GROUP"],
  teacherPortalIBDPEERootNodes: ["PROJECT_GROUP", "CLASS_ROOM"],

  // student root nodes
  studentPortalIBPYPRootNodes: [
    "UNIT_PLANS",
    "CLASS_ROOM",
    "JOURNAL",
    "STUDENT_DRIVE",
  ],
  studentPortalIBMYPRootNodes: [
    "UNIT_PLANS",
    "CLASS_ROOM_MYP",
    "MYP_JOURNAL",
    "STUDENT_DRIVE",
  ],
  studentPortalUBDRootNodes: [
    "UNIT_PLANS",
    "CLASS_ROOM_UBD",
    "UBD_JOURNAL",
    "STUDENT_DRIVE",
  ],
  studentPortalIBDPRootNodes: [
    "UNIT_PLANS",
    "CLASS_ROOM",
    "PROJECT_GROUP",
    "STUDENT_DRIVE",
  ],
  studentPortalIBDPCASRootNodes: ["PROJECT_GROUP"],
  studentPortalIBDPTOKRootNodes: ["UNIT_PLANS", "CLASS_ROOM", "PROJECT_GROUP"],
  studentPortalIBDPEERootNodes: ["PROJECT_GROUP"],

  // parent root nodes
  parentPortalIBPYPRootNodes: ["UNIT_PLANS", "CLASS_ROOM", "JOURNAL"],
  parentPortalIBMYPRootNodes: ["UNIT_PLANS", "CLASS_ROOM_MYP", "MYP_JOURNAL"],
  parentPortalUBDRootNodes: ["UNIT_PLANS", "CLASS_ROOM_UBD", "UBD_JOURNAL"],

  nodes: [
    {
      id: "PREMIUM_FEATURES",
      label: "premium_features",
      children: [
        "SCHEDULER",
        "POI",
        "PORTFOLIO_REPORTS",
        "FAMILY_COMMUNICATION",
      ],
      levelId: "L0",
      icon: <PremiumFeaturesIcon />,
      isBottom: false,
    },
    {
      id: "PROJECT_GROUP",
      label: "{{projectGroupType}}",
      //by default we are applying t fn to the label. So to not apply t fn we need to pass this boolean.
      isLabelNotLocalized: true,
      isBottom: false,
      children: [],
      route: "projects/:projectGroupId",
      levelId: "L1",
      icon: <StudentPortfolioSvg />,
      perm: "Common:Projects",
      studentPerm: "Common:Projects",
      isDynamicNode: true,
      dynamicNodeConfig: {
        //this id will replace the id of the node
        id: "PROJECT_GROUP_{{projectGroupType}}",
        type: "PROJECT_GROUP",
      },
    },
    {
      id: "PLANNING",
      label: "planning",
      children: [
        "UNIT_PLANS",
        "SCHEDULER",
        // "POI",
        "PLANNING_INSIGHTS",
        "SCOPE_SEQUENCE",
        // "MY_CALENDAR"
      ],
      curriculumIBMYPChildren: [
        "UNIT_PLANS",
        "SCHEDULER_MYP",
        "PLANNING_INSIGHTS",
        "MYP_LEARNING_STANDARDS",
        // "MY_CALENDAR"
      ],
      curriculumUBDChildren: [
        "UNIT_PLANS",
        "SCHEDULER_UBD",
        "PLANNING_INSIGHTS",
      ],
      curriculumDPChildren: ["UNIT_PLANS", "DP_SUBJECT_STANDARD"],
      levelId: "L0",
      icon: <CalendarScheduleOutlined variant={"on"} />,
      isBottom: false,
    },
    {
      id: "DP_SUBJECT_STANDARD",
      label: "common:content_standards",
      children: [],
      levelId: "L1",
      route: "dp-learning-standards",
      icon: <ScopeAndSequenceSvg />,
      perm: "TeacherPortal:DPLearningStandards",
    },
    {
      id: "MYP_LEARNING_STANDARDS",
      label: "common:content_standards",
      children: [],
      levelId: "L1",
      route: "learning-standards",
      icon: <ScopeAndSequenceSvg />,
      perm: "TeacherPortal:MYPLearningStandards",
    },
    {
      id: "PORTFOLIO_REPORTS",
      label: "teacherHomePage:portfolio_reports",
      perm: "TeacherPortal:PortfolioReports",
      children: [
        "JOURNAL",
        "STUDENT_PORTFOLIO",
        "ASSESSMENT_EVALUATION",
        "PROGRESS_REPORT",
        "CLASS_PROGRESS_SUMMARY",
      ],

      route: "studentPortfolioReport",
      levelId: "L0",
      icon: <ReportAndPortfolioIcon />,
      isBottom: false,
    },
    {
      id: "CLASSROOM",
      label: "teacherHomePage:classRoom",

      children: [
        "CLASS_ROOM",
        "MYP_JOURNAL",
        "MY_CALENDAR",
        "STUDENT_DRIVE",
        "ATTENDANCE",
      ],
      curriculumIBMYPChildren: [
        "CLASS_ROOM_MYP",
        "MYP_JOURNAL",
        "MY_CALENDAR",
        "STUDENT_DRIVE",
        "ATTENDANCE",
      ],
      curriculumDPChildren: [
        "CLASS_ROOM_DP",
        "PROJECT_GROUP",
        "MY_CALENDAR",
        "STUDENT_DRIVE",
        "ATTENDANCE_DP",
      ],

      route: "studentPortfolioReport",
      levelId: "L0",
      icon: <CommentMultipleOutlined variant={"on"} />,
      isBottom: false,
    },
    {
      id: "UBD_CLASS",
      label: "teacherHomePage:classRoom",
      curriculumUBDChildren: [
        "CLASS_ROOM_UBD",
        "UBD_JOURNAL",
        "MY_CALENDAR",
        "STUDENT_DRIVE",
        "ATTENDANCE",
      ],

      levelId: "L0",
      icon: <CommentMultipleOutlined variant={"on"} />,
      isBottom: false,
    },

    {
      id: "FAMILY_COMMUNICATION",
      label: "teacherHomePage:family_communication",
      children: [],
      route: "familyconversations",
      levelId: "L1",
      icon: <FamilyCommunicationIcon />,
      isBottom: false,
      perm: "TeacherPortal:FamilyCommunication",
    },

    {
      id: "ATTENDANCE",
      label: "teacherHomePage:attendance",
      children: [],
      route: "attendance",
      levelId: "L0",
      isBottom: false,
      icon: <AttendanceOutlined variant={"on"} />,
      perm: "TeacherPortal:Attendance",
    },

    {
      id: "ATTENDANCE_DP",
      label: "teacherHomePage:attendance",
      children: [],
      route: "attendance",
      levelId: "L0",
      isBottom: false,
      icon: <AttendanceOutlined variant={"on"} />,
      perm: "Common:EnableDPAttendance",
    },
    // --------------------------------------Classroom--------------------------------------
    {
      id: "CLASS_ROOM",
      label: "teacherHomePage:class_stream",
      children: [],
      route: "classroom",
      levelId: "L0",
      isBottom: false,
      icon: <CommentSquareLeftClassroomOutlined variant={"on"} />,
      perm: "Common:Classroom",
      studentPerm: "Common:Classroom",
      parentPerm: "Common:ClassroomFamily",
    },
    {
      id: "CLASS_ROOM_MYP",
      label: "teacherHomePage:class_stream",
      children: [],
      route: "classroom",
      levelId: "L0",
      isBottom: false,
      icon: <ClassroomSvg fill="#ffff" />,
      perm: "Common:MYPClassroom",
      studentPerm: "Common:MYPClassroom",
      parentPerm: "Common:ClassroomFamily",
    },
    {
      id: "CLASS_ROOM_UBD",
      label: "teacherHomePage:class_stream",
      children: [],
      route: "classroom",
      levelId: "L0",
      isBottom: false,
      icon: <ClassroomSvg fill="#ffff" />,
      perm: "Common:UBDClassroom",
      studentPerm: "Common:UBDClassroom",
      parentPerm: "Common:ClassroomFamily",
    },
    {
      id: "CLASS_ROOM_DP",
      label: "teacherHomePage:class_stream",
      children: [],
      route: "classroom",
      levelId: "L0",
      isBottom: false,
      icon: <ClassroomSvg fill="#ffff" />,
      perm: "Common:DPClassroom",
      // studentPerm: "Common:UBDClassroom",
      // parentPerm: "Common:ClassroomFamily",
    },
    // --------------------------------------Settings--------------------------------------
    {
      id: "SETTINGS",
      label: "common:settings",
      levelId: "L0",
      isBottom: false,
      icon: <SettingsOutlined variant={"on"} />,
      perm: "TeacherPortal:ClassSetting",
      studentPerm: "StudentPortal:ClassSetting",
      // route: "settings",
      children: [
        "CLASS_SETTINGS",
        "NOTIFICATION_SETTINGS",
        "MANAGE_FAMILY",
        "TIMETABLE_CONFIGURATION",
      ],
      curriculumIBMYPChildren: ["MANAGE_FAMILY", "TIMETABLE_CONFIGURATION"],
      curriculumUBDChildren: ["MANAGE_FAMILY", "TIMETABLE_CONFIGURATION"],
      curriculumDPChildren: ["MANAGE_FAMILY", "TIMETABLE_CONFIGURATION"],
    },
    {
      id: "CLASS_SETTINGS",
      label: "common:class_settings",
      levelId: "L1",
      isBottom: false,
      icon: <ClassSettingsOutlined variant="on" />,
      perm: "TeacherPortal:ClassSetting",
      studentPerm: "StudentPortal:ClassSetting",
      children: [],
      route: "settings",
    },
    {
      id: "NOTIFICATION_SETTINGS",
      label: "common:notification_settings",
      levelId: "L1",
      isBottom: false,
      icon: <NotificationSettingsOutlined variant="on" />,
      perm: "Common:NotificationConfiguration",
      studentPerm: "Common:NotificationConfiguration",
      children: [],
      route: "notification-settings",
    },
    {
      id: "MANAGE_FAMILY",
      label: "common:manage_family_members",
      levelId: "L1",
      isBottom: false,
      icon: <FamilyOutlined variant="on" />,
      perm: "TeacherPortal:ManageFamilyMembers",
      children: [],
      route: "manage-family",
    },
    {
      id: "TIMETABLE_CONFIGURATION",
      label: "common:timetable_config",
      levelId: "L1",
      isBottom: false,
      icon: <SettingsOutlined variant={"on"} />,
      perm: "Common:EnableStudentTimeTable",
      children: [],
      route: "timetable-configuration",
    },

    {
      id: "EDUCATOR_CENTER",
      label: "teacherHomePage:educator_center",
      children: [
        "TEACHER_SCHOOL_POLICIES",
        "TEACHER_RESOURCE",
        "UNIT_LIBRARY",
        "LE_LIBRARY_PYP",
      ],
      curriculumIBMYPChildren: [
        "TEACHER_SCHOOL_POLICIES",
        "TEACHER_RESOURCE",
        "UNIT_LIBRARY",
        "LE_LIBRARY_MYP",
      ],
      curriculumUBDChildren: [
        "TEACHER_SCHOOL_POLICIES",
        "TEACHER_RESOURCE",
        "UNIT_LIBRARY",
        "LE_LIBRARY_UBD",
      ],
      curriculumDPChildren: [
        "TEACHER_SCHOOL_POLICIES",
        "TEACHER_RESOURCE",
        "UNIT_LIBRARY",
        "LE_LIBRARY_DP",
      ],
      levelId: "L0",
      icon: <OpenBookOutlined variant={"on"} />,
      isBottom: false,
    },
    {
      id: "UNIT_PLANS",
      label: "teacherHomePage:units_of_inquiry",
      children: [],
      levelId: "L1",
      route: "unitPlans",
      icon: <UnitPlanOutlined variant={"on"} />,
      perm: "TeacherPortal:UnitPlans",
      studentPerm: "StudentPortal:UnitPlans",
      parentPerm: "FamilyPortal:UnitPlans",
      isBottom: false,
    },
    {
      id: "PLANNING_INSIGHTS",
      label: "teacherHomePage:planning_insights",
      children: [],
      levelId: "L1",
      route: "planningInsights",
      icon: <InsightsSquareOutlined variant={"on"} />,
      perm: "TeacherPortal:PlanningInsights",
      isBottom: false,
    },
    // --------------------------------------Scheduler--------------------------------------
    {
      id: "SCHEDULER",
      label: "teacherHomePage:weekly_yearly_planning",
      children: [],
      levelId: "L1",
      route: "scheduler",
      icon: <CalendarScheduleWeekOutlined variant="on" />,
      perm: "TeacherPortal:Scheduler",
    },
    {
      id: "SCHEDULER_MYP",
      label: "teacherHomePage:weekly_planning",
      children: [],
      levelId: "L1",
      route: "scheduler",
      icon: <CalendarScheduleWeekOutlined variant="on" />,
      perm: "TeacherPortal:SchedulerMYP",
    },
    {
      id: "SCHEDULER_UBD",
      label: "teacherHomePage:weekly_planning",
      children: [],
      levelId: "L1",
      route: "scheduler",
      icon: <CalendarScheduleWeekOutlined variant="on" />,
      perm: "TeacherPortal:SchedulerUBD",
    },
    {
      id: "POI",
      label: "teacherHomePage:poi",
      children: [],
      route: "poi",
      levelId: "L1",
      icon: <POISvg />,
      isBottom: true,
      perm: "TeacherPortal:POI",
    },
    {
      id: "SCOPE_SEQUENCE",
      label: "common:content_standards",
      children: [],
      levelId: "L1",
      route: "scopeandsequence",
      icon: <AssessmentEvaluatedOutlined variant={"on"} />,
      perm: "TeacherPortal:ScopeAndSequance",
    },
    // --------------------------------------Journal--------------------------------------
    {
      id: "JOURNAL",
      label: "teacherHomePage:class_journal",
      children: [],
      route: "journal",
      levelId: "L1",
      icon: <JournalOutlined variant={"on"} />,
      perm: "TeacherPortal:Journal",
      studentPerm: "StudentPortal:Journal",
      parentPerm: "FamilyPortal:Journal",
    },
    {
      id: "MYP_JOURNAL",
      label: "teacherHomePage:journal",
      children: [],
      route: "journal",
      levelId: "L1",
      icon: <JournalOutlined variant={"on"} />,
      perm: "TeacherPortal:MYPJournal",
      studentPerm: "StudentPortal:MYPJournal",
      parentPerm: "FamilyPortal:Journal",
    },
    {
      id: "UBD_JOURNAL",
      label: "teacherHomePage:journal",
      children: [],
      route: "journal",
      levelId: "L1",
      icon: <JournalOutlined variant={"on"} />,
      perm: "TeacherPortal:UBDJournal",
      studentPerm: "StudentPortal:UBDJournal",
      parentPerm: "FamilyPortal:Journal",
    },
    {
      id: "STUDENT_PORTFOLIO",
      label: "teacherHomePage:student_profile",
      children: [],
      route: "studentPortfolio",
      levelId: "L1",
      icon: <StudentOutlined variant={"on"} />,
      perm: "TeacherPortal:StudentPortfolio",
    },
    {
      id: "DP_STUDENT_PORTFOLIO",
      label: "teacherHomePage:student_profile",
      children: [],
      route: "studentPortfolio",
      levelId: "L1",
      icon: <StudentOutlined variant={"on"} />,
      perm: "TeacherPortal:DPStudentPortfolio",
    },
    {
      id: "ASSESSMENT_EVALUATION",
      label: "teacherHomePage:assess_learning",
      children: [],
      route: "assessmentEvaluation",
      levelId: "L1",
      icon: <AssessmentandEvaluationOutlined variant={"on"} />,
      perm: "TeacherPortal:AssessmentEvaluation",
      isBottom: false,
    },
    {
      id: "CONTINUUM",
      label: "teacherHomePage:continuum",
      children: [],
      route: "continuum",
      levelId: "L1",
      icon: <TickFrameOutlined variant={"on"} />,
      perm: "TeacherPortal:Continuum",
      isBottom: false,
    },
    {
      id: "CONTINUUM_MYP",
      label: "teacherHomePage:continuum",
      children: [],
      route: "continuum",
      levelId: "L1",
      icon: <TickFrameOutlined variant={"on"} />,
      perm: "TeacherPortal:MYPContinuum",
      isBottom: false,
    },
    {
      id: "CONTINUUM_UBD",
      label: "teacherHomePage:continuum",
      children: [],
      route: "continuum",
      levelId: "L1",
      icon: <TickFrameOutlined variant={"on"} />,
      perm: "TeacherPortal:UBDContinuum",
      isBottom: false,
    },
    {
      id: "PROGRESS_REPORT",
      label: "teacherHomePage:progress_report",
      children: [],
      route: "progressreport",
      levelId: "L1",
      icon: <ProgressReportOutlined variant={"on"} />,
      perm: "TeacherPortal:ProgressReport",
    },
    {
      id: "CLASS_PROGRESS",
      label: "teacherHomePage:class_progress",
      children: [
        "PROGRESS_REPORT",
        "CLASS_PROGRESS_SUMMARY",
        "ASSESSMENT_EVALUATION",
        "CONTINUUM",
      ],
      curriculumIBMYPChildren: ["PROGRESS_SUMMARY", "CONTINUUM_MYP"],
      curriculumUBDChildren: ["CLASS_PROGRESS_SUMMARY", "CONTINUUM_UBD"],
      curriculumDPChildren: ["PROGRESS_SUMMARY_DP"],
      levelId: "L0",
      icon: <ClassProgressOutlined variant={"on"} />,
    },
    {
      id: "CLASS_PROGRESS_SUMMARY",
      label: "teacherHomePage:class_progress_summary",
      children: [],
      route: "classProgressSummary",
      levelId: "L1",
      icon: <ClassProgressSummaryOutlined variant={"on"} />,
      perm: "TeacherPortal:ClassProgressSummary",
    },
    {
      id: "PROGRESS_SUMMARY",
      label: "teacherHomePage:class_summary",
      children: [],
      route: "progressSummary",
      levelId: "L1",
      icon: <ProgressSummarySvg />,
      perm: "TeacherPortal:MYPProgressSummary",
    },
    {
      id: "PROGRESS_SUMMARY_DP",
      label: "teacherHomePage:class_summary",
      children: [],
      route: "progressSummary",
      levelId: "L1",
      icon: <ProgressSummarySvg />,
      perm: "TeacherPortal:DPProgressSummary",
    },
    {
      id: "UNIT_LIBRARY",
      label: "teacherHomePage:unit_library",
      children: [],
      route: "unitlibrary",
      levelId: "L1",
      icon: <UnitLibraryOutlined variant="on" />,
      isBottom: true,
      perm: "TeacherPortal:UnitLibrary",
    },
    {
      id: "LE_LIBRARY_PYP",
      label: "common:learning_experience_library",
      children: [],
      route: "lelibrary",
      levelId: "L1",
      icon: <LeLibraryOutlined variant="on" />,
      perm: "TeacherPortal:LELibrary",
    },
    {
      id: "LE_LIBRARY_MYP",
      label: "teacherHomePage:learning_experience_&_assessment_library",
      children: [],
      route: "lelibrary",
      levelId: "L1",
      icon: <LeLibraryOutlined variant="on" />,
      perm: "TeacherPortal:LELibraryMyp",
    },
    {
      id: "LE_LIBRARY_UBD",
      label: "teacherHomePage:learning_experience_&_assessment_library",
      children: [],
      route: "lelibrary",
      levelId: "L1",
      icon: <LeLibraryOutlined variant="on" />,
      perm: "TeacherPortal:LELibraryUbd",
    },
    {
      id: "LE_LIBRARY_DP",
      label: "teacherHomePage:learning_experience_&_assessment_library",
      children: [],
      route: "lelibrary",
      levelId: "L1",
      icon: <LeLibraryOutlined variant="on" />,
      perm: "TeacherPortal:LELibraryDP",
    },
    {
      id: "TEACHER_SCHOOL_POLICIES",
      label: "teacherHomePage:school_policy",
      children: [],
      levelId: "L1",
      route: "teacherpolicies",
      icon: <PolicyTickOutlined variant={"on"} />,
      perm: "TeacherPortal:TeacherSchoolPolicies",
    },
    {
      id: "FAMILY_PORTAL_SCHOOL_POLICIES",
      label: "teacherHomePage:all_policies",
      children: [],
      levelId: "L1",
      route: "schoolpolicies",
      icon: <SchoolPolicy />,
      perm: "FamilyApp:SchoolPolicies",
    },
    {
      id: "TEACHER_RESOURCE",
      label: "teacherHomePage:teacher_resources",
      children: [],
      levelId: "L1",
      route: "teacherresources",
      icon: <TeacherResourceOutlined variant={"on"} />,
      perm: "TeacherPortal:TeacherResources",
    },
    {
      id: "ANNOUNCEMENTS",
      label: "teacherHomePage:announcements",
      children: [],
      levelId: "L1",
      route: "circulars",
      icon: <TeacherResourcesSvg />,
      isBottom: true,
      perm: "TeacherPortal:TeacherResources",
    },
    {
      id: "FAMILY_COMMUNICATIONS",
      label: "teacherHomePage:family_messaging",
      children: [],
      levelId: "L0",
      route: "familyconversations",
      icon: <FamilyCommunicationIcon />,
      isBottom: true,
      perm: "TeacherPortal:FamilyCommunications",
    },
    {
      id: "MY_CALENDAR",
      label: "common:calendar",
      children: [],
      route: "my-calendar",
      levelId: "L1",
      icon: <CalendarOutlined variant="on" />,
      perm: "Common:PlatformCalendar",
    },
    {
      id: "STUDENT_DRIVE",
      label: "common:class_files",
      children: [],
      route: "student-drive",
      levelId: "L1",
      icon: <FolderOutlined variant="on" />,
      perm: "FeatureFlag:StudentDrive",
      studentPerm: "FeatureFlag:StudentDrive",
    },
  ],
};
