import client from "apolloClient";
import {
  plannerFieldFragment,
  responseFragment,
  evidenceResponseFeedPostFragment,
} from "UnitPlans/modules/UnitPlanFragments";
import {
  getStaffListQuery,
  getUnitPlanQuery,
  getUnitSummaryPlanQuery,
  getCourseStaffGroupQuery,
} from "./IBQuery";
import { unitPlanFragment, staffUnitPlanFragment } from "./IBFragments";

export const getStaffCollaborator = staff_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Staff:${staff_id}`,
      fragment: staffUnitPlanFragment.staffCourses,
      fragmentName: "staffCoursesItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getUnitPlanDetailsFromCache = (unitPlanId, fields) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUnitPlanQuery,
      variables: {
        id: unitPlanId,
        fields,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getUnitSummaryPlanDetailsFromCache = unitPlanId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getUnitSummaryPlanQuery,
      variables: {
        id: unitPlanId,
      },
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getUnitPlanCollaboratorsFromCache = unitPlanId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `UnitPlan:${unitPlanId}`,
      fragment: unitPlanFragment.unitPlanCollaborator,
      fragmentName: "unitPlanItem",
    });
  } catch (e) {
    // console.log(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "collaborators.edges", []);
  else return {};
};

export const getUnitPlanFieldFromCache = field_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `PlannerField:${field_id}`,
      fragment: plannerFieldFragment.unitplanField,
      fragmentName: "unitPlanFieldItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getPostDetailFromCache = postId => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Post:${postId}`,
      fragment: evidenceResponseFeedPostFragment.postEvidence,
      fragmentName: "postItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getUnitPlanFieldResponseFromCache = response_id => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `Response:${response_id}`,
      fragment: responseFragment.response,
      fragmentName: "responseItem",
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeResponseFragment = ({ response_id, data }) => {
  client.writeFragment({
    id: `Response:${response_id}`,
    fragment: responseFragment.response,
    fragmentName: "responseItem",
    data: data,
  });
};

export const writeUnitPlanFieldFragment = ({ fieldId, data }) => {
  client.writeFragment({
    id: `PlannerField:${fieldId}`,
    fragment: plannerFieldFragment.unitplanField,
    fragmentName: "unitPlanFieldItem",
    data: data,
  });
};

export const writeUnitPlanFragment = ({ unitPlanId, data }) => {
  client.writeFragment({
    id: `UnitPlan:${unitPlanId}`,
    fragment: unitPlanFragment.unitPlanDetails,
    fragmentName: "unitPlanItem",
    data: data,
  });
};

export const getStaffListFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStaffListQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getCourseStaffGroupFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseStaffGroupQuery,
      variables: params,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
