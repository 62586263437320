import PropTypes from "prop-types";
import React from "react";

const CheckboxOn = props => {
  return (
    <svg viewBox="0 0 12 12">
      <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="CheckSvg">
          <path
            d="M9.052422,4.488373 L5.465424,8.074371 C5.369423,8.171371 5.242424,8.218368 5.116425,8.218368 C4.991425,8.218368 4.866425,8.170379 4.770424,8.074371 L2.976425,6.282379 C2.784424,6.090378 2.782425,5.777374 2.974426,5.585373 C3.167427,5.393372 3.479423,5.393372 3.672424,5.585373 L5.118423,7.03038 L8.355423,3.791382 C8.547424,3.599381 8.860428,3.599381 9.052422,3.791382 C9.242424,3.983368 9.242424,4.296371 9.052422,4.488373 Z"
            id="Path"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  );
};

CheckboxOn.defaultProps = {
  fill: "#FFFFFF",
};

export default CheckboxOn;
