import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "learning-standards",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/Module").default;
            const multiLevelNodeEditorReducer = require("MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule")
              .default;
            const teacherReducer = require("Teacher/modules/TeacherModule")
              .default;
            injectReducer(store, { key: "learningStandards", reducer });
            injectReducer(store, {
              key: "multiLevelNodeEditor",
              reducer: multiLevelNodeEditorReducer,
            });
            const administratorReducer = require("Administrator/modules/AdministratorModule")
              .default;

            injectReducer(store, { key: "teacher", reducer: teacherReducer });
            injectReducer(store, {
              key: "administrator",
              reducer: administratorReducer,
            });

            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
});
