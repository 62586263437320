import { withAsyncRouteLoading } from "UIComponents";
import { routes } from "Projects/config/routes";

const {
  introduction: { path },
} = routes;

const IntroductionRoute = store => {
  return {
    path,
    component: withAsyncRouteLoading(
      () =>
        new Promise(resolve => {
          require.ensure(
            [],
            require => {
              const component = require("./Introduction").default;
              resolve(component);
            },
            "introduction"
          );
        })
    ),
    childRoutes: [],
  };
};
export default IntroductionRoute;
