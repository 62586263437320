import { withAsyncRouteLoading } from "UIComponents";

export default store => {
  return {
    path: "evidences",
    component: withAsyncRouteLoading(
      () =>
        new Promise((cb, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              cb(component);
            },
            err => {
              reject(err);
            },
            "project-group"
          );
        })
    ),
    childRoutes: [],
  };
};
