import React from "react";
import classes from "./PrivacyAware.scss";
import { connect } from "react-redux";
import classNames from "classnames";
import { isSettingEnabled, getUserInfo } from "Login/modules/LoginModule";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
class PrivateComponent extends React.PureComponent {
  render() {
    const { isScreen, isPrivate } = this.props;
    const containerClass = classNames(
      { [classes.container]: true },
      { [classes.screenContainer]: isScreen }
    );

    return isPrivate ? (
      <div data-private className={containerClass}>
        {this.props.children}
      </div>
    ) : (
      this.props.children
    );
  }
}

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: "PLANNER" });
  const organizationId = _.get(userInfo, "org_id", "");
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const settings = _.get(organizationDetails, "settings", []);
  // console.log(
  //   !isSettingEnabled({
  //     settings,
  //     settingName: "disableLogRocket"
  //   }),
  //   "private"
  // );
  return {
    isPrivate: false,
    // isPrivate: !isSettingEnabled({
    //   settings,
    //   settingName: "enableLogRocket",
    //   valueCheck: true
    // })
  };
};

export default connect(mapStateToProps, mapActionCreators)(PrivateComponent);
