import * as Router from "react-router";

import { store } from "store/createStore";
import { createRoutes } from "routes";

export const routes = createRoutes(store);
export const plainRoutes = Router.createRoutes(routes);

/**
 * @description workaround to fix the possible module order issue
 * when importing this file in services/tracing and importing
 * services/tracing 's getExtensions in apolloClient.
 * webpacks builds successfully but page breaks at
 * injectReducer
 */
window.plainRoutes = plainRoutes;
