import React from "react";
import classes from "./AddAttachment.scss";
import ScreenPanel from "../../ScreenPanel";
import { OneDriveIcon, GoogleDriveIcon, ImageIcon } from "SvgComponents";
import {
  LinkModal,
  DropzoneComponent,
  ButtonDropdown,
  OneDrivePicker,
  GooglePicker,
  FullScreenLoader,
  I18nHOC,
} from "UIComponents";
import AttachmentItem from "./AttachmentItem";
import { connect } from "react-redux";
import { isImageCarousel } from "Utils";
import { colors } from "Constants";
import { setToastMsg } from "Login/modules/LoginModule";
import { compose } from "react-apollo";
import ACLStore from "lib/aclStore";
import { CLOUD_URL } from "Constants";

const buttonDropDownContainerStyle = {
  top: 144,
  marginTop: 12,
  marginBottom: 24,
  width: 240,
  left: 0,
};

const styles = {
  modalBodyStyle: {
    marginTop: "0",
    padding: "8px 48px 48px 48px",
    flex: "0",
  },
  cancelButtonStyle: {
    flexBasis: "0%",
    marginRight: "0",
  },
  cancelButtonProps: {
    color: "transparent",
    type: "hollow",
    disableHover: true,
  },
};

const ATTACHMENT_TYPES = {
  PHOTO: {
    type: "PHOTO",
    title: "common:photo",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/photoIcon.svg`,
    color: colors.violet94,
    captureStep: "CAPTURE_IMAGE",
    mimeTypes: ["image/jpeg", "image/png", "image/jpg"],
    multiple: true,
  },
  // PHOTO_COLLAGE: {
  //   type: "PHOTO_COLLAGE",
  //   title: "Photo Collage",
  //   svg: <CollageIconSvg fill={"white"} />,
  //   color: "#ff2d55"
  // },
  VOICE: {
    type: "VOICE",
    title: "common:voice",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/voiceIcon.svg`,
    color: "#efdef8",
  },
  VIDEO: {
    type: "VIDEO",
    title: "common:video",
    captureStep: "CAPTURE_VIDEO",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/videoIcon.svg`,
    color: "#ffdce5",
    mimeTypes: ["video/mp4", "video/avi", "video/wmv", "video/quicktime"],
  },
  FILE: {
    type: "FILE",
    title: "common:file",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/fileIcon.svg`,
    color: "#bdf3e6",
    mimeTypes: undefined,
  },
  NOTE: {
    type: "NOTE",
    title: "common:note",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/noteIcon.svg`,
    color: "#ffeabf",
  },
  LINK: {
    type: "LINK",
    title: "common:link",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/linkIcon.svg`,
    color: "#c5f6ff",
  },
  WORKBOOK: {
    type: "WORKBOOK",
    title: "common:workbook",
    svgUrl: `${CLOUD_URL}/assets/webapp/journal_icons/drawingIcon.svg`,
    color: "#fee2d0",
  },
};
class AddAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLinkModal: false,
      uploadCount: 0,
      showReorderModal: false,
      currentSelectedDropdown: {},
    };
    const {
      screenPanelProps: { updatePost },
    } = props;
  }

  onAttachmentSelected = type => {
    const {
      screenPanelProps: { goToStep, updatePost, setWorkbookMode } = {},
    } = this.props;
    switch (type) {
      case "LINK":
        this.setState({ showLinkModal: true });
        break;
      case "WORKBOOK":
        setWorkbookMode && setWorkbookMode("USE_TEMPLATE");
        break;
      case "VOICE":
        this.goToStep("VOICE_RECORDER");
        break;
      case "NOTE":
        updatePost({ attachments: [{ type: "NOTE", title: "" }] });
        this.goToStep("POST_DETAIL");
        break;
      default:
        this.goToStep("POST_DETAIL");
    }
  };

  toggleLinkModal = () => {
    this.setState({ showLinkModal: !this.state.showLinkModal });
  };

  onLinkAdd = async ({ attachments }) => {
    const { screenPanelProps: { goToStep, updatePost } = {} } = this.props;

    updatePost({ attachments });
    this.goToStep("POST_DETAIL");
  };

  updatePostAttachments = attachment => {
    const {
      screenPanelProps: {
        updatePost,

        postDetails: { attachments = [] },
      },
    } = this.props;
    updatePost({ attachments: [...attachments, attachment] });
  };

  updateFilesCount = (count, isError) => {
    const {
      screenPanelProps: {
        goToStep,
        postDetails: { attachments = [] },
        mediaCreationOptions,
      } = {},
    } = this.props;
    let newCount = this.state.uploadCount;
    newCount = newCount + count;
    const allowCarouselCreation = _.get(
      mediaCreationOptions,
      "allowCarouselCreation",
      true
    );

    this.setState({ uploadCount: newCount });
    if (isError) {
      return;
    }
    if (newCount == 0) {
      setTimeout(() => {
        if (isImageCarousel({ attachments }) && allowCarouselCreation) {
          this.goToStep("REORDER_IMAGES");
        } else {
          this.goToStep("POST_DETAIL");
        }
      }, 100);
    }
  };

  renderTabItem = ({ tab, index }) => {
    switch (tab.value) {
      default:
        return null;
    }
  };

  goToStep = step => {
    const {
      screenPanelProps: { goToStep },
    } = this.props;
    goToStep(step);
  };

  onFailedAllowedMimeType = () => {
    this.props.setToastMsg("toastMsgs:unsupported_file_format");
  };

  onDropDownItemClick = () => {
    // console.log("HERE");
    if (this.buttonDropDownRef) {
      // this.buttonDropDownRef.removeDocumentClickListener();
    }
  };

  onOneDriveClick = () => {
    this.onDropDownItemClick();
    if (this.oneDrivePickerRef) {
      this.oneDrivePickerRef.onLoginClick();
    }
  };

  updateButtonDropDownRef = ref => {
    this.buttonDropDownRef = ref;
  };

  updateOneDriveRef = ref => {
    this.oneDrivePickerRef = ref;
  };

  onAttachmentClose = () => {};

  renderDropdownComponent = ({ item }) => {
    const { t } = this.props;
    return (
      <div className={classes.dropDownContainer}>
        <div className={classes.dropDownItemContainer}>
          <DropzoneComponent
            multiple={false}
            parentId={null}
            parentType={"POST"}
            onClick={this.onDropDownItemClick}
            renderComponent={
              <div className={classes.buttonIconAndText}>
                <ImageIcon fill={colors.gray48} height={16} width={16} />
                <div className={classes.hoverItemText}>
                  {t("common:upload_device")}
                </div>
              </div>
            }
            onDropContent={this.updatePostAttachments}
            shouldUpload={false}
            accept={_.get(item, "mimeTypes", undefined)}
            onClose={this.onAttachmentClose}
            updateFilesCount={this.updateFilesCount}
          />
        </div>

        <div
          className={classes.dropDownItemContainer}
          onClick={this.onDropDownItemClick}
        >
          <GooglePicker
            parentId={null}
            parentType={"POST"}
            renderComponent={
              <div className={classes.googleDriveInnerDiv}>
                <GoogleDriveIcon fill={colors.gray48} />
                <div className={classes.hoverItemText}>
                  {t("common:add_google_drive")}
                </div>
              </div>
            }
            pickerType="GoogleDrive"
            classStyle={classes.iconSvgContainer}
            multiselect={false}
            onContentLoaded={this.updatePostAttachments}
            onFailedAllowedMimeType={this.onFailedAllowedMimeType}
            thumbnailSize={"w248-h200"}
            shouldUpload={false}
            exportAsPdf={true}
            updateFilesCount={this.updateFilesCount}
            onClose={this.onAttachmentClose}
          />
        </div>
        <div
          className={classes.dropDownItemContainer}
          onClick={this.onOneDriveClick}
        >
          <div className={classes.oneDriveInnerDiv}>
            <OneDriveIcon fill={colors.gray48} />
            <div className={classes.hoverItemText}>
              {t("common:add_one_drive")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPhotoVideoDropdownComponent = ({ item }) => {
    const { t } = this.props;
    return (
      <div className={classes.dropDownContainer}>
        <div className={classes.dropDownItemContainer}>
          <DropzoneComponent
            multiple={item.multiple}
            parentId={null}
            parentType={"POST"}
            onClick={this.onDropDownItemClick}
            renderComponent={
              <div className={classes.buttonIconAndText}>
                <ImageIcon fill={colors.gray48} height={16} width={16} />
                <div className={classes.hoverItemText}>
                  {t("common:upload_device")}
                </div>
              </div>
            }
            onDropContent={this.updatePostAttachments}
            shouldUpload={false}
            accept={_.get(item, "mimeTypes", [])}
            onClose={this.onAttachmentClose}
            updateFilesCount={this.updateFilesCount}
          />
        </div>

        <div
          className={classes.dropDownItemContainer}
          onClick={() => this.goToStep(item.captureStep)}
        >
          <div className={classes.oneDriveInnerDiv}>
            <OneDriveIcon fill={colors.gray48} />
            <div className={classes.hoverItemText}>
              {item.type == "PHOTO"
                ? t("common:capture_image")
                : t("common:capture_video")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  onUpdateShowDropDown = ({ type, value }) => {
    const { currentSelectedDropdown } = this.state;
    const updatedCurrentSelectedDropdown = _.cloneDeep(currentSelectedDropdown);
    updatedCurrentSelectedDropdown[type] = value;
    this.setState({ currentSelectedDropdown: updatedCurrentSelectedDropdown });
  };

  getAttachmentItem = ({ item, index }) => {
    const { currentSelectedDropdown } = this.state;
    const isDropdownOpen = _.get(currentSelectedDropdown, item.type);
    switch (item.type) {
      case "PHOTO":
        return (
          <ButtonDropdown
            key={index}
            containerStyle={buttonDropDownContainerStyle}
            buttonComponent={
              <AttachmentItem item={item} isDropdownOpen={isDropdownOpen} />
            }
            dropdownComponent={this.renderPhotoVideoDropdownComponent({ item })}
            ref={this.updateButtonDropDownRef}
            onUpdateShowDropDown={value =>
              this.onUpdateShowDropDown({ type: item.type, value })
            }
          />
        );
      case "VIDEO":
        return ACLStore.can("Common:WebcamRecording") ? (
          <ButtonDropdown
            key={index}
            containerStyle={buttonDropDownContainerStyle}
            buttonComponent={
              <AttachmentItem item={item} isDropdownOpen={isDropdownOpen} />
            }
            dropdownComponent={this.renderPhotoVideoDropdownComponent({ item })}
            ref={this.updateButtonDropDownRef}
            onUpdateShowDropDown={value =>
              this.onUpdateShowDropDown({ type: item.type, value })
            }
          />
        ) : (
          <DropzoneComponent
            key={index}
            multiple={_.get(item, "multiple", false)}
            parentId={null}
            parentType={"POST"}
            renderComponent={<AttachmentItem item={item} />}
            onDropContent={this.updatePostAttachments}
            accept={_.get(item, "mimeTypes", [])}
            shouldUpload={false}
            onFailedAllowedMimeType={this.onFailedAllowedMimeType}
            updateFilesCount={this.updateFilesCount}
          />
        );

      case "FILE":
        return (
          <ButtonDropdown
            key={index}
            containerStyle={buttonDropDownContainerStyle}
            buttonComponent={
              <AttachmentItem item={item} isDropdownOpen={isDropdownOpen} />
            }
            dropdownComponent={this.renderDropdownComponent({ item })}
            ref={this.updateButtonDropDownRef}
            onUpdateShowDropDown={value =>
              this.onUpdateShowDropDown({ type: item.type, value })
            }
          />
        );

      case "WORKBOOK":
        return (
          <AttachmentItem
            item={item}
            onAttachmentSelected={this.onAttachmentSelected}
          />
        );

      default:
        return (
          <AttachmentItem
            item={item}
            key={index}
            onAttachmentSelected={this.onAttachmentSelected}
          />
        );
    }
  };

  render() {
    const { screenPanelProps, t } = this.props;
    const { showLinkModal, isLoading, link, uploadCount } = this.state;
    const addMediaOptions = _.get(
      screenPanelProps,
      "mediaCreationOptions.addMediaOptions",
      []
    );
    return (
      <ScreenPanel {...screenPanelProps}>
        <div className={classes.container}>
          <div className={classes.iconContainer}>
            {_.map(addMediaOptions, (item, index) => {
              const addMediaOption = ATTACHMENT_TYPES[item];
              if (
                _.isEqual(item, "WORKBOOK") &&
                !ACLStore.can("FeatureFlag:Workbook") //permission to add workbook in journal
              )
                return;
              return this.getAttachmentItem({
                item: { ...addMediaOption, title: t(addMediaOption.title) },
                index,
              });
            })}
          </div>
        </div>

        {!!showLinkModal && (
          <LinkModal
            onLinkAdd={this.onLinkAdd}
            onClose={this.toggleLinkModal}
          />
        )}

        <OneDrivePicker
          parentId={null}
          parentType={"POST"}
          multiselect={false}
          onContentLoaded={this.updatePostAttachments}
          onFailedAllowedMimeType={this.onFailedAllowedMimeType}
          thumbnailSize={"w248-h200"}
          updateRef={this.updateOneDriveRef}
          shouldUpload={false}
          updateFilesCount={this.updateFilesCount}
          onClose={this.onAttachmentClose}
          exportAsPdf={true}
        />
        {uploadCount > 0 && <FullScreenLoader />}
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {
  setToastMsg,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["journal", "common"] })
)(AddAttachment);
