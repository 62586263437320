import Overview from "./routes/Overview";
import CandidateInfo from "./routes/CandidateInfo";
import YearGroups from "./routes/YearGroups";
import Subjects from "./routes/Subjects";
import ExamDetails from "./routes/ExamDetails";
import Permissions from "./routes/Permissions";
import StudentDetails from "./routes/StudentDetails";
import ComponentEdit from "./routes/ComponentEdit";
import Sync from "./routes/Sync";

const ExamRegistration = [
  Overview,
  YearGroups,
  CandidateInfo,
  Subjects,
  ExamDetails,
  Permissions,
  ComponentEdit,
  StudentDetails,
  Sync,
];

export { ExamRegistration };
