import qs from "querystringify";

export const parseQueryString = url => {
  const parsed = qs.parse(url);
  return parsed;
};

export const getQueryString = params => {
  return _.join(
    _.map(params, (val, key) => {
      if (typeof val !== "string") {
        return `${key}=${JSON.stringify(val)}`;
      }
      return `${key}=${val}`;
    }),
    "&"
  );
};

export const getFamilyInviteCodeFromQuery = queryConfig => {
  let familyInviteCode;
  try {
    const parsedQuery = queryConfig ? JSON.parse(queryConfig) : {};
    const metadata = _.get(parsedQuery, "metadata");
    familyInviteCode = metadata ? _.get(metadata, "inviteCode", "") : "";
  } catch {
    // errors
  }
  return familyInviteCode;
};
