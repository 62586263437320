/**--external-- */
import React from "react";
import classNames from "classnames";

/**--relative-- */
import classes from "./OrganizationBasicDetails.scss";

const OrganizationBasicDetails = props => {
  const { profileImage, name, id } = props;
  const organizationNameClasses = classNames("text-body-1", classes.name);
  const organizationIdClasses = classNames("text-body-3", classes.id);

  return (
    <div className={classes.container}>
      <figure className={classes.figure}>
        <img src={profileImage} alt={`${name} logo`} />
      </figure>
      <div className={classes.rightContent}>
        <p className={organizationNameClasses}>{name}</p>
        <p className={organizationIdClasses}>{`Org ID-${id}`}</p>
      </div>
    </div>
  );
};

export default OrganizationBasicDetails;

OrganizationBasicDetails.displayName = "OrganizationBasicDetails";
