import update from "immutability-helper";
import client from "apolloClient";
import axios from "axios";
import {
  editBenchmarkMutation,
  addBenchmarkMutation,
  removeBenchmarkMutation,
  editATLMutation,
  removeATLMutation,
  addATLMutation,
  setStandardSetOfSubjectMutation,
  handleBenchmarkInBulkMutation,
  createStandardSetRequestMutation,
  createSubjectSnSMutation,
} from "./MultiLevelNodeEditorMutations";

import {
  createPlannerElementNodesMutation,
  updatePlannerElementNodesMutation,
  deletePlannerElementNodesMutation,
} from "modules/CommonMutations";

import {
  getOrganizationPypElementSetFromCache,
  writeOrganizationPypElementSetInCache,
  getPypElementFromCache,
  writePypElementFromCache,
  getPlannerElementNodeFromCache,
  writePlannerElementInCache,
  getPlannerElementNodesOfSetFromCache,
  writePlannerElementNodesOfSetInCache,
  getPlannerElementNodesOfSetWithDepthLabelFromCache,
} from "modules/CommonGraphqlHelpers";
// import { plannerElementNodeFragment } from "modules/CommonFragments";
import {
  getPypAtlSetFromCache,
  getSubjectFromCache,
  writePypAtlSetInCache,
  writeSubjectInCache,
} from "./MultiLevelNodeEditorGraphqlHelpers";
import { pypElementMapping } from "modules/Services";
import { setToastMsg } from "Login/modules/LoginModule";

import { generateRandomId, delay, getBackendServerUrl } from "Utils";
import {
  get as _get,
  map as _map,
  filter as _filter,
  find as _find,
  includes as _includes,
  findKey as _findKey,
  forEach as _forEach,
  findLast as _findLast,
  findIndex as _findIndex,
  uniq as _uniq,
  omit as _omit,
  findLastIndex as _findLastIndex,
  isEqual as _isEqual,
  intersectionBy as _intersectionBy,
  unionBy as _unionBy,
  cloneDeep as _cloneDeep,
  isEmpty as _isEmpty,
  slice as _slice,
  maxBy as _maxBy,
} from "lodash";
import {
  getMultiLevelNodesQuery,
  getPlannerElementNodesOfSetWithDepthLabelQuery,
} from "modules/CommonQuery";

const getTemplateURL = ({ nodeType }) => {
  switch (nodeType) {
    case "MYP_LEARNING_STANDARD":
    case "UBD_LEARNING_STANDARD":
    case "DP_SUBJECT_STANDARD":
      return "getPlannerElementNodeUploadTemplate";
    default:
      return "getScopeAndSequenceUploadTemplateV2";
  }
};

const getExistingTemplateParams = ({
  nodeType,
  subjectId,
  accessToken,
  plannerElementSetId,
}) => {
  switch (nodeType) {
    case "MYP_LEARNING_STANDARD":
    case "UBD_LEARNING_STANDARD":
    case "DP_SUBJECT_STANDARD":
      return {
        url: "getPlannerElementNodeData",
        body: {
          Authorization: `${accessToken}`,
          subject_id: subjectId,
          planner_type: nodeType,
          planner_set_id: plannerElementSetId,
        },
      };
    default:
      return {
        url: "getSubjectScopeAndSequenceDataNonDestructiveV2",
        body: { Authorization: `${accessToken}`, subject_id: subjectId },
      };
  }
};

export const NAME = "multiLevelNodeEditor";
export const UPDATE_SEARCH_FILTERS = "UPDATE_SEARCH_FILTERS" + " " + NAME;
export const UPDATE_EDIT_NODES = "UPDATE_EDIT_NODES" + " " + NAME;
export const UPDATE_ROOT_NODE = "UPDATE_ROOT_NODE" + " " + NAME;
export const TOGGLE_LOADING = "TOGGLE_LOADING" + " " + NAME;
export const UPDATE_SUBJECT_ITEM = "UPDATE_SUBJECT_ITEM" + " " + NAME;
export const UPDATE_SNS_SETTINGS_ACTION =
  "UPDATE_SNS_SETTINGS_ACTION" + " " + NAME;
export const UPDATE_FILTERS = "UPDATE_FILTERS" + " " + NAME;
export const RESET_NODE_EDITOR_FILTERS =
  "RESET_NODE_EDITOR_FILTERS" + " " + NAME;
export const TOGGLE_NODE_EDITOR_MODE = "TOGGLE_NODE_EDITOR_MODE" + " " + NAME;
export const SET_SIDEBAR_SELECTED_ITEM =
  "SET_SIDEBAR_SELECTED_ITEM" + " " + NAME;
export const RESET_NODE_EDITOR_STATE = "RESET_NODE_EDITOR_STATE" + " " + NAME;

export const toggleNodeEditorMode = (mode = null) => {
  return async (dispatch, getState) => {
    const nodeEditorMode = getState().multiLevelNodeEditor.nodeEditorMode;
    const newMode = mode ? mode : nodeEditorMode === "view" ? "edit" : "view";
    dispatch({ type: TOGGLE_NODE_EDITOR_MODE, mode: newMode });
  };
};

export const resetNodeEditorState = () => {
  return {
    type: RESET_NODE_EDITOR_STATE,
  };
};

export const setSidebarSelectedItem = data => {
  return {
    type: SET_SIDEBAR_SELECTED_ITEM,
    data,
  };
};

export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};
export const resetNodeEditorFilters = () => {
  return {
    type: RESET_NODE_EDITOR_FILTERS,
  };
};
export const updateSearchFilters = data => {
  return {
    type: UPDATE_SEARCH_FILTERS,
    data,
  };
};

export const updateEditNodes = data => {
  return {
    type: UPDATE_EDIT_NODES,
    data,
  };
};

export const updateRootNode = data => {
  return {
    type: UPDATE_ROOT_NODE,
    data,
  };
};

export const toggleLoading = data => {
  return {
    type: TOGGLE_LOADING,
    data,
  };
};
export const updateSnsSettingsAction = data => {
  return {
    type: UPDATE_SNS_SETTINGS_ACTION,
    data,
  };
};

export const initRootNode = data => {
  return (dispatch, getState) => {
    dispatch(updateRootNode(data));
    const editNodes = getState().multiLevelNodeEditor.editNodes;
    dispatch(checkAndUpdateMissingNodes({ nodes: editNodes }));
  };
};

export const initNodeList = ({ nodes }) => {
  return (dispatch, getState) => {
    dispatch(updateEditNodes(nodes));
    dispatch(checkAndUpdateMissingNodes({ nodes }));
  };
};

export const getNodesToUpdateCache = ({ nodes, subject }) => {
  const finalNodes = [];
  _forEach(nodes, (item, index) => {
    const parent = _findLast(
      nodes,
      (nodeItem, nodeIndex) =>
        nodeIndex < index && nodeItem.depth <= item.depth - 1
    );

    finalNodes.push({
      children: [],
      subject: subject,
      __typename: "PlannerBenchmark",
      levelId: `L${item.depth}`,
      ...item,
      parent: _get(parent, "id", null),
      displaySequence: index,
    });
  });
  return finalNodes;
};

export const getNodesToSendServer = ({ nodes, subject }) => {
  // const currenParentId = null;
  const outputNodes = getNodesToUpdateCache({ nodes, subject });
  const finalNodes = [];
  _forEach(outputNodes, (item, index) => {
    finalNodes.push({
      ..._omit(item, [
        "children",
        "displaySequence",
        "__typename",
        "grades",
        "parent",
        "levels",
        "isLeaf",
      ]),
      grades: _map(item.grades, gradeItem => gradeItem.id),
      parentId: item.parent,
      levels: item.levels
        ? JSON.stringify(
            _map(item.levels, level => _omit(level, ["count", "__typename"]))
          )
        : undefined,
    });
  });
  return finalNodes;
};

export const editSubjectSnS = params => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: editBenchmarkMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:saved_successfully",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
      return _.get(result, "data.planner.editNode.id", {});
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const getSubjectSnSData = subject_id => {
  return async (dispatch, getState) => {
    dispatch(toggleLoading(true));
    try {
      const accessToken = _get(JSON.parse(localStorage.userInfo), "token", "");
      const response = await axios.post(
        getBackendServerUrl({
          token: accessToken,
          path: "/auth/tools/getSubjectScopeAndSequenceData",
        }),
        { Authorization: `Bearer ${accessToken}`, subject_id }
      );
      const data = _get(response, "data.yearData", {});
      return data;
    } catch (error) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
};

export const createStandardSetRequest = ({ params }) => {
  return async (dispatch, getState) => {
    dispatch(toggleLoading(true));
    try {
      await client.mutate({
        mutation: createStandardSetRequestMutation,
        variables: params,
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:request_successfully_sent",
          type: "tick",
          position: "toast-bottom-right",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
};
export const getSubjectSnSTemplateData = (subject_id, nodeType) => {
  return async (dispatch, getState) => {
    dispatch(toggleLoading(true));
    try {
      const accessToken = _.get(JSON.parse(localStorage.userInfo), "token", "");
      const templateURL = getTemplateURL({ nodeType });
      const response = await axios.post(
        getBackendServerUrl({
          token: accessToken,
          path: `/auth/tools/${templateURL}`,
        }),
        { Authorization: `${accessToken}`, subject_id }
      );
      return response;
    } catch (error) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
};
//this is used to download existing scope and sequence csv file
export const getSubjectExistingSnSTemplateData = ({
  subjectId,
  nodeType,
  plannerElementSetId,
}) => {
  return async (dispatch, getState) => {
    dispatch(toggleLoading(true));
    try {
      const accessToken = _.get(JSON.parse(localStorage.userInfo), "token", "");
      const { url, body } = getExistingTemplateParams({
        nodeType,
        subjectId,
        accessToken,
        plannerElementSetId,
      });
      const response = await axios.post(
        getBackendServerUrl({
          token: accessToken,
          path: `/auth/tools/${url}`,
        }),
        body
      );
      if (_.isEmpty(_.get(response, "data.yearData", {}))) {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      return response;
    } catch (error) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
};
export const handleBenchmarkInBulk = ({ plannerElementSetConfig }) => {
  return async (dispatch, getState) => {
    dispatch(toggleLoading(true));
    const { id: plannerElementSetId } = plannerElementSetConfig;
    const nodes = getState().multiLevelNodeEditor.editNodes;
    const rootNode = getState().multiLevelNodeEditor.rootNode;
    const organizationId = getState().login.userInfo.org_id;
    const subject = rootNode.subject;
    const nodeType = "BENCHMARK";
    const snsChildren = _get(rootNode, "snsChildren", []);
    const filteredNodes = _filter(nodes, item =>
      _includes(snsChildren, item.type)
    );
    const finalNodes = getNodesToSendServer({ nodes: filteredNodes, subject });
    try {
      await client.mutate({
        mutation: handleBenchmarkInBulkMutation,
        variables: {
          subject: subject,
          benchmarks: finalNodes,
          snsChildren: snsChildren,
          plannerElementSetId,
        },
        update: (
          cache,
          {
            data: {
              platform: { updateSubject },
              planner: { handleBenchmarkInBulk },
            },
          }
        ) => {
          const queryData = getOrganizationPypElementSetFromCache({
            organizationId,
            type: nodeType,
            subjects: subject,
          });

          const elementKey = _findKey(
            pypElementMapping,
            item => item == nodeType
          );
          const nodeSet = _get(queryData, `pypElement.${elementKey}`, {});
          const updateNodeSet = {
            ...nodeSet,
            ...handleBenchmarkInBulk,
          };

          const updatedQueryData = {
            ...queryData,
            pypElement: {
              ...queryData.pypElement,
              [elementKey]: updateNodeSet,
            },
          };

          writeOrganizationPypElementSetInCache({
            organizationId,
            type: nodeType,
            data: updatedQueryData,
            subjects: subject,
          });
        },
      });

      dispatch(toggleLoading(false));
      dispatch(
        setToastMsg({
          msg: "toastMsgs:saved_successfully",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
      return true;
    } catch (e) {
      console.error(e);
      dispatch(toggleLoading(false));
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      return false;
    }
  };
};

export const checkAndUpdateMissingNodes = ({ nodes }) => {
  return (dispatch, getState) => {
    const isNodesAvailable = _find(nodes, item => item.depth == 1);
    if (!isNodesAvailable) {
      // Add strand when no strands
      dispatch(addStand({ editNodes: nodes }));
    } else {
      //check empty bucket children and add it in individual strand
      let types = [];
      let tempIndex = -1;
      const tempData = [];
      _forEach(nodes, (item, index) => {
        const { depth, type } = item;

        const { type: nextType, depth: nextDepth = -1 } = _get(
          nodes,
          index + 1,
          {}
        );

        if (depth > 1) {
          types.push(type);
          if (tempIndex == -1 && type == "LEARNING_OUTCOME") {
            tempIndex = index - 1;
          }
        }

        if (nextDepth <= depth && (nextDepth == 1 || nextDepth == -1)) {
          tempData.push({
            node: nodes[tempIndex != -1 ? tempIndex : index],
            snsNotIncludes: _uniq(types),
          });

          types = [];
          tempIndex = -1;
        }
      });
      // console.log(tempData);
      // Add sns children where missing
      _forEach(tempData, item => {
        dispatch(
          addSNSChildren({
            index: _findIndex(
              getState().multiLevelNodeEditor.editNodes,
              nodeItem => nodeItem.id == item.node.id
            ),
            snsNotIncludes: item.snsNotIncludes,
          })
        );
      });
    }
    return getState().multiLevelNodeEditor.editNodes;
  };
};

export const addSNSChildren = ({ index, snsNotIncludes = [] }) => {
  return (dispatch, getState) => {
    const snsChildren = _get(
      getState().multiLevelNodeEditor,
      "rootNode.snsChildren",
      []
    );

    // console.log(snsChildren);
    let newObj = { index };
    _forEach(snsChildren, type => {
      if (!_includes(snsNotIncludes, type)) {
        newObj = dispatch(
          updateEditNodeList({
            index: newObj.index,
            action: "ADD",
            params: { depth: 2, type: type },
          })
        );
      }
    });
  };
};

export const addStand = ({ index, editNodes }) => {
  return (dispatch, getState) => {
    if (!index) {
      index = editNodes.length - 1;
    }

    const strandObj = dispatch(
      updateEditNodeList({
        index: index,
        action: "ADD",
        params: { depth: 1, type: "LEARNING_OUTCOME", label: "" },
      })
    );

    dispatch(addSNSChildren({ index: strandObj.index }));

    return strandObj;
  };
};

export const getNextSameDepthIndex = ({ index, editNodes, params }) => {
  const nextIndex = _findIndex(
    editNodes,
    (item, itemIndex) =>
      itemIndex > index &&
      ((item.depth == params.depth && item.type == params.type) ||
        item.depth < params.depth ||
        (params.depth > 1 && item.type != params.type))
  );
  return nextIndex >= 0 ? nextIndex : editNodes.length;
};

export const getPreviousSameDepthIndex = ({ index, editNodes, params }) => {
  const previousIndex = _findLastIndex(
    editNodes,
    (item, itemIndex) =>
      itemIndex < index &&
      ((item.depth == params.depth && item.type == params.type) ||
        item.depth < params.depth ||
        (params.depth > 1 && item.type != params.type))
  );
  return previousIndex >= 0 ? previousIndex : 0;
};

export const getParentNodeIndex = ({ nodes, index, depth }) => {
  return (dispatch, getState) => {
    const rootNode = _get(getState().multiLevelNodeEditor, "rootNode", {});

    const parentNodeIndex =
      index == 1
        ? rootNode
        : _findLastIndex(
            nodes,
            (nodeItem, nodeIndex) =>
              nodeIndex < index && nodeItem.depth <= depth - 1
          );

    return parentNodeIndex || {};
  };
};

export const updateEditNodeList = ({ index, action, params = {} }) => {
  return (dispatch, getState) => {
    let editNodes = getState().multiLevelNodeEditor.editNodes;
    const newObj = {};
    let cnt;
    let currentNodes;
    let nextMoveIndex;
    let currentNode;
    switch (action) {
      case "ADD_STRAND":
        return dispatch(addStand({ index, editNodes }));

      case "ADD":
        currentNode = editNodes[index] || {};
        newObj.id = generateRandomId();
        newObj.index = getNextSameDepthIndex({
          index,
          params,
          editNodes,
        });

        editNodes = update(editNodes, {
          $splice: [
            [
              newObj.index,
              0,
              {
                ...initialState.nodeTemplate,
                ...params,
                id: newObj.id,
                isNew: true,
                grades: params.depth > 1 ? _get(currentNode, "grades", []) : [],
              },
            ],
          ],
        });
        dispatch(updateEditNodes(editNodes));

        break;

      case "EDIT": {
        const skippedParams = _omit(params, ["alreadyUpdated"]);
        currentNode = editNodes[index] || {};
        Object.keys(skippedParams).map(key => {
          editNodes = update(editNodes, {
            [index]: {
              [key]: { $set: skippedParams[key] },
            },
          });
        });
        dispatch(updateEditNodes(editNodes));

        //Check if grade then update childs grades
        if (params.grades && !params.alreadyUpdated) {
          const nextIndex = getNextSameDepthIndex({
            index,
            params,
            editNodes,
          });

          for (let curr = index; curr < nextIndex; curr++) {
            const currNode = editNodes[curr];

            dispatch(
              updateEditNodeList({
                index: curr,
                action: "EDIT",
                params: {
                  alreadyUpdated: true,
                  grades: _intersectionBy(
                    params.grades,
                    _get(currNode, "grades", []),
                    "id"
                  ),
                },
              })
            );
          }
        }

        if (params.depth) {
          if (params.depth > currentNode.depth) {
            //Right Arrow Click
            const parentNodeIndex = dispatch(
              getParentNodeIndex({
                nodes: editNodes,
                depth: params.depth,
                index,
              })
            );
            const parentNode = editNodes[parentNodeIndex] || {};
            // console.log(parentNode);
            dispatch(
              updateEditNodeList({
                index: parentNodeIndex,
                action: "EDIT",
                params: {
                  alreadyUpdated: false,
                  ...parentNode,
                },
              })
            );
          } else if (params.depth < currentNode.depth) {
            //Left Arrow Click
            dispatch(
              updateEditNodeList({
                index,
                action: "EDIT",
                params: {
                  alreadyUpdated: false,
                  type: currentNode.type,
                  depth: params.depth,
                  grades: currentNode.grades,
                },
              })
            );
          }
        }
        break;
      }

      case "DELETE": {
        const nextIndex = getNextSameDepthIndex({
          index,
          params,
          editNodes,
        });

        editNodes = _filter(
          editNodes,
          (item, itemIndex) => itemIndex < index || itemIndex >= nextIndex
        );
        dispatch(updateEditNodes(editNodes));
        editNodes = dispatch(checkAndUpdateMissingNodes({ nodes: editNodes }));
        break;
      }

      case "MOVE_DOWN": {
        nextMoveIndex = getNextSameDepthIndex({
          index,
          params,
          editNodes,
        });
        const nextToNextMoveIndex = getNextSameDepthIndex({
          index: nextMoveIndex,
          params,
          editNodes,
        });
        cnt = 0;
        currentNodes = _cloneDeep(editNodes);
        for (let curr = index; curr < nextMoveIndex; curr++) {
          const node = currentNodes[curr];
          editNodes = update(editNodes, {
            $splice: [
              [
                nextToNextMoveIndex + cnt,
                0,
                {
                  ...node,
                },
              ],
            ],
          });
          cnt++;
        }

        dispatch(updateEditNodes(editNodes));
        dispatch(
          updateEditNodeList({
            index: index,
            action: "DELETE",
            params,
          })
        );

        break;
      }

      case "MOVE_UP": {
        nextMoveIndex = getNextSameDepthIndex({
          index,
          params,
          editNodes,
        });
        const prevMoveIndex = getPreviousSameDepthIndex({
          index,
          params,
          editNodes,
        });

        cnt = 0;
        currentNodes = _cloneDeep(editNodes);
        for (let curr = index; curr < nextMoveIndex; curr++) {
          const node = currentNodes[curr];
          editNodes = update(editNodes, {
            $splice: [
              [
                prevMoveIndex + cnt,
                0,
                {
                  ...node,
                },
              ],
            ],
          });
          cnt++;
        }

        dispatch(updateEditNodes(editNodes));

        dispatch(
          updateEditNodeList({
            index: index + cnt,
            action: "DELETE",
            params,
          })
        );
        break;
      }
    }

    return newObj;
  };
};

export const getNodeById = ({ nodeSet, nodeId }) => {
  const nodes = _get(nodeSet, "nodes", []);

  return _find(nodes, item => item.id == nodeId);
};

export const getParentFilterNodes = ({ nodes, parentId }) => {
  const childIds = [parentId];
  getChildIds({ ids: childIds, nodes, nodeId: parentId });

  return _filter(nodes, node => _includes(childIds, node.id));
};

export const getNodeLevelCounts = ({ nodeSet, rootNode }) => {
  let nodes = _get(nodeSet, "nodes", []);
  const levelCounts = [];

  if (!_isEmpty(rootNode)) {
    const levels = _get(rootNode, "levels", []);
    nodes = getParentFilterNodes({ nodes, parentId: rootNode.id });
    _forEach(levels, (item, index) => {
      if (index > 0) {
        levelCounts.push({
          id: item.id,
          value: item.value,
          count: _filter(nodes, node => node.levelId == item.id).length,
        });
      }
    });
  }

  return levelCounts;
};

export const getSubjectRootNodes = ({ subjects }) => {
  return _map(subjects, item => {
    const { id: subject, name: label, benchmarkRootNode = {} } = item;
    const rootNode = benchmarkRootNode || {};
    const { id: rootNodeId, levels = [], children } = rootNode;

    return {
      ...item,
      children,
      levels,
      id: subject,
      subject,
      label,
      rootNodeId,
      benchmarkLevelCounts: _slice(levels, 1, levels.length),
    };
  });
};

export const getRootNodes = ({ nodeSet, subjects }) => {
  if (_get(subjects, "length", 0) > 0) {
    return getSubjectRootNodes({ subjects });
  }

  const rootNodes = _get(nodeSet, "rootNodes", []);
  const nodes = _get(nodeSet, "nodes", []);
  const outputNodes = [];
  _forEach(rootNodes, rootNodeId => {
    const rootNode = _find(nodes, item => item.id == rootNodeId);

    if (rootNode) {
      outputNodes.push({
        ...rootNode,
        rootNodeId: rootNode.id,
        benchmarkLevelCounts: getNodeLevelCounts({
          rootNode: rootNode,
          nodeSet: nodeSet,
        }),
      });
    }
  });
  return outputNodes;
};

export const getGradesUpdatedNode = ({
  nodeId,
  grades,
  outputNodes,
  nodes,
}) => {
  const nodeIndex = _findIndex(nodes, item => item.id == nodeId);
  if (nodeIndex > 0) {
    const node = outputNodes[nodeIndex];
    const children = _get(node, "children", []);
    node.grades = _intersectionBy(grades, node.grades, "id");
    _forEach(children, childId => {
      getGradesUpdatedNode({ nodeId: childId, grades, outputNodes, nodes });
    });
  }
};

export const updateSubjectNode = ({
  addNode = {},
  subject,
  levelId,
  organizationId,
  nodeType,
  parent,
}) => {
  if (!subject) {
    return;
  }

  const subjectData = getSubjectFromCache(subject);
  if (_isEmpty(subjectData)) {
    return;
  }

  const queryData = getOrganizationPypElementSetFromCache({
    organizationId,
    type: nodeType,
    subjects: subject,
  });

  const elementKey = _findKey(pypElementMapping, item => item == nodeType);
  const nodeSet = _get(queryData, `pypElement.${elementKey}`, {});
  const nodes = _get(nodeSet, "nodes", []);

  const currentLevels = _get(subjectData, "benchmarkRootNode.levels", []);
  if (parent) {
    _forEach(currentLevels, level => {
      level.count = _filter(nodes, item => item.levelId == level.id).length;
    });

    setTimeout(() => {
      writeSubjectInCache({
        subjectId: subject,
        data: {
          ...subjectData,
          benchmarkRootNode: {
            ...subjectData.benchmarkRootNode,
            levels: currentLevels,
          },
        },
      });
    });
  } else {
    const { id, children, levels, __typename, label, grades } = addNode;

    writeSubjectInCache({
      subjectId: subject,
      data: {
        ...subjectData,
        benchmarkRootNode: !_isEmpty(addNode)
          ? {
              ...addNode,
              levels: _map(levels, item => {
                return { ...item, count: 0 };
              }),
            }
          : null,
      },
    });
  }
};

/*This function adds a new node in PlannerElementSet.
It also inserts tha added node's id into it's parent's object if parent is present.
*/
export const addPlannerNodeInCache = async ({
  cache,
  node,
  // plannerElementSetConfig,
  plannerElementSetConfig: {
    id: plannerElementSetId,
    filters: plannerElementSetFilters,
  },
  plannerElementSetData,
  shouldRefetchQueries,
}) => {
  const nodes = plannerElementSetData?.nodes ?? [];
  const parentNode = _find(nodes, n => n.id === node.parent);
  const updatedParentNode = {
    ...parentNode,
    //If parentNode is present and has children. add node.id in it's children. Else it'll be null
    children: parentNode ? [...(parentNode.children ?? []), node.id] : null,
  };

  //adding __typename in Subject's object in associatedParents
  const associatedParents = _map(node.associatedParents, aP => {
    let typeName;
    switch (aP.type) {
      case "SUBJECT": {
        typeName = "Subject";
        break;
      }
      case "SUBJECT_GROUP": {
        typeName = "SubjectGroup";
        break;
      }
      default: {
        typeName = "Grade";
      }
    }
    return { ...aP, __typename: typeName };
  });

  const parentId = parentNode?.id ?? null;

  const newNode = {
    subText: "",
    ...node,
    associatedParents,
    parent: parentId,
    __typename: "PlannerElementNode",
  };

  const updatedData = {
    ...plannerElementSetData,
    nodes: [
      ..._map(nodes, n => (n.id === parentId ? updatedParentNode : n)),
      newNode,
    ],
  };
  if (!shouldRefetchQueries)
    writePlannerElementNodesOfSetInCache({
      id: plannerElementSetId,
      filters: plannerElementSetFilters,
      data: { node: { ...updatedData } },
    });
};

export const addPlannerNode = ({
  plannerElementSetConfig: {
    id: plannerElementSetId,
    filters: plannerElementSetFilters,
  },
  node,
  nodeType,
  depthLabels = null,
  shouldRefetchQueries = false,
}) => {
  return async dispatch => {
    const plannerElementSetData = getPlannerElementNodesOfSetFromCache({
      id: plannerElementSetId,
      filters: plannerElementSetFilters,
    });

    try {
      const newNode = {
        ...node,
        children: null,
        isLeaf: false,
        type: nodeType,
      };

      await client.mutate({
        mutation: createPlannerElementNodesMutation,
        variables: {
          input: {
            type: nodeType,
            setId: plannerElementSetId,
            plannerElementNodes: [node],
            depthLabels,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: shouldRefetchQueries
          ? [
              {
                query: getPlannerElementNodesOfSetWithDepthLabelQuery,
                variables: {
                  filters: plannerElementSetFilters,
                  id: plannerElementSetId,
                  plannerElementDepthLabelSetFilters: {
                    elementTypes: [nodeType],
                  },
                },
              },
            ]
          : [],
        update: async (cache, result) => {
          const nodeId = result.data.planner.createPlannerElementNodes?.[0].id;
          addPlannerNodeInCache({
            cache,
            plannerElementSetData,
            node: {
              ...newNode,
              id: nodeId,
            },
            plannerElementSetConfig: {
              id: plannerElementSetId,
              filters: plannerElementSetFilters,
            },
            shouldRefetchQueries,
          });
        },
      });
    } catch (e) {
      console.error(e);

      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const removePlannerElementNode = ({
  node,
  plannerElementSetConfig: {
    id: plannerElementSetId,
    filters: plannerElementSetFilters,
  },
  plannerElementDepthLabelSetFilters = null,
}) => {
  return async (dispatch, getState) => {
    const plannerElementSetData = plannerElementDepthLabelSetFilters
      ? getPlannerElementNodesOfSetWithDepthLabelFromCache({
          id: plannerElementSetId,
          filters: plannerElementSetFilters,
          plannerElementDepthLabelSetFilters,
        })
      : getPlannerElementNodesOfSetFromCache({
          id: plannerElementSetId,
          filters: plannerElementSetFilters,
        });

    try {
      await client.mutate({
        mutation: deletePlannerElementNodesMutation,
        variables: {
          input: {
            ids: [node.id],
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            deletePlannerElementNodes: true,
            __typename: "PlannerMutations",
          },
        },
        update: (cache, result) => {
          if (result.data.planner.deletePlannerElementNodes) {
            removePlannerNodeInCache({
              plannerElementSetData,
              cache,
              node,
              plannerElementSetConfig: {
                id: plannerElementSetId,
                filters: plannerElementSetFilters,
              },
            });
          }
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const updatePlannerElementNode = ({
  node,
  gradesToRemove = [],
  gradesToAdd = [],
  subjectGroupsToRemove = [],
  subjectGroupsToAdd = [],

  plannerElementSetConfig,
  shouldRefetchQueries = false,
  associatedParentTypeName = "Grade",
}) => {
  return async (dispatch, getState) => {
    const { id, label, subText = "", code } = node;

    let removedAssociatedParents = [],
      addedAssociatedParents = [];
    switch (associatedParentTypeName) {
      case "Grade": {
        removedAssociatedParents = _map(gradesToRemove, grade => ({
          type: "GRADE",
          id: grade.id,
        }));

        addedAssociatedParents = _map(gradesToAdd, grade => ({
          type: "GRADE",
          id: grade.id,
        }));
        break;
      }
      case "SubjectGroup": {
        (removedAssociatedParents = subjectGroupsToRemove),
          (addedAssociatedParents = subjectGroupsToAdd);
        break;
      }
    }

    try {
      await client.mutate({
        mutation: updatePlannerElementNodesMutation,
        variables: {
          input: {
            id,
            label,
            code,
            subText,
            associatedParents: {
              removedAssociatedParents,
              addedAssociatedParents,
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: shouldRefetchQueries
          ? [
              {
                query: getPlannerElementNodesOfSetWithDepthLabelQuery,
                variables: plannerElementSetConfig,
              },
            ]
          : [],
        update(cache, result) {
          if (_.size(removedAssociatedParents) > 0) {
            removeAssociatedParentsFromPlannerNodeInCache({
              cache,
              nodeId: node.id,
              associatedParents: removedAssociatedParents,
              typeName: associatedParentTypeName,
            });
          }
          if (_.size(addedAssociatedParents) > 0) {
            addAssociatedParentsToPlannerNodeInCache({
              cache,
              nodeId: node.id,
              associatedParents: addedAssociatedParents,
              typeName: associatedParentTypeName,
            });
          }
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const removePlannerNodeInCache = async ({
  cache,
  plannerElementSetData,
  plannerElementSetConfig: {
    id: plannerElementSetId,
    filters: plannerElementSetFilters,
  },
  node,
}) => {
  //find parent node from the nodes array and remove node's id from the children in the parentNode
  const nodes = plannerElementSetData?.nodes ?? [];
  const parentNode = _find(nodes, n => n.id === node.parent) ?? {};
  const updatedParentNode = {
    ...parentNode,
    children: _filter(parentNode.children, n => n.id !== node.id),
  };
  const childNodeIds = node.children ? node.children : [];
  //Also filter out(delete) all the child nodes of the node which is to be deleted.
  const updatedNodes = _filter(
    nodes,
    n => !(n.id === node.id || _includes(childNodeIds, n.id))
  ).map(n => (n.id === parentNode.id ? updatedParentNode : n));
  const updatedData = {
    ...plannerElementSetData,
    nodes: updatedNodes,
  };

  await delay(0);

  writePlannerElementNodesOfSetInCache({
    id: plannerElementSetId,
    filters: plannerElementSetFilters,
    data: { node: { ...updatedData } },
  });
};

export const removeAssociatedParentsFromPlannerNodeInCache = ({
  cache,
  nodeId,
  associatedParents = [],
  typeName,
}) => {
  const queryData = getPlannerElementNodeFromCache({ id: nodeId });

  const associatedParentIds = _map(
    associatedParents,
    associatedParent => associatedParent.id
  );
  const data = {
    ...queryData,
    associatedParents: _filter(
      //from the list of associated parent remove Grades which are in gradeIds
      queryData.associatedParents,
      asP =>
        !(associatedParentIds.includes(asP.id) && asP.__typename === typeName)
    ),
  };
  writePlannerElementInCache({ id: nodeId, data });
};

export const addAssociatedParentsToPlannerNodeInCache = ({
  cache,
  associatedParents,
  nodeId,
  typeName,
}) => {
  const queryData = getPlannerElementNodeFromCache({ id: nodeId });

  const associatedParentsToBeAdded = _map(
    associatedParents,
    associatedParent => ({ ...associatedParent, __typename: typeName })
  );

  const data = {
    ...queryData,
    associatedParents: _unionBy(
      queryData.associatedParents,
      associatedParentsToBeAdded,
      "id"
    ),
  };
  writePlannerElementInCache({ id: nodeId, data });
};

export const editPlannerNodeInCache = ({ params }) => {
  const queryData = getPlannerElementNodeFromCache({ id: params.id });
  //do we need to check if queryData present only then write to cache!!
  const data = {
    ...queryData,
    ...params.updatedParams,
  };
  writePlannerElementInCache({ id: params.id, data });
};

export const editNodeInCache = ({ params, nodeType }) => {
  return (dispatch, getState) => {
    const pypElement = getPypElementFromCache({
      type: nodeType,
      id: params.id,
    });

    writePypElementFromCache({
      type: nodeType,
      id: params.id,
      data: { ...pypElement, ...params },
    });

    if (!_isEqual(params.grades, pypElement.grades)) {
      const organizationId = getState().login.userInfo.org_id;
      const queryData = getOrganizationPypElementSetFromCache({
        organizationId,
        type: nodeType,
        subjects: params.subject,
      });
      const elementKey = _findKey(pypElementMapping, item => item == nodeType);
      const nodeSet = _get(queryData, `pypElement.${elementKey}`, {});
      const nodes = _get(nodeSet, "nodes", []);
      const outputNodes = _cloneDeep(nodes);

      getGradesUpdatedNode({
        nodeId: params.id,
        grades: params.grades,
        outputNodes,
        nodes,
      });

      const updateNodeSet = {
        ...nodeSet,
        nodes: outputNodes,
      };
      const updatedQueryData = {
        ...queryData,
        pypElement: {
          ...queryData.pypElement,
          [elementKey]: updateNodeSet,
        },
      };

      writeOrganizationPypElementSetInCache({
        organizationId,
        type: nodeType,
        data: updatedQueryData,
        subjects: params.subject,
      });
    }
  };
};

export const editNode = ({
  id,
  levels = undefined,
  grades,
  label,
  nodeType,
  subText = undefined,
  code,
}) => {
  return async (dispatch, getState) => {
    if (_includes(id, "NEW") || _includes(id, "RANDOM")) {
      return;
    }
    const gradeIds = grades
      ? _map(grades, item => {
          return item.id;
        })
      : undefined;

    let result;
    try {
      switch (nodeType) {
        case "BENCHMARK":
          result = await client.mutate({
            mutation: editBenchmarkMutation,
            variables: {
              id,
              label,
              levels: levels
                ? JSON.stringify(
                    _map(levels, level => _omit(level, ["count", "__typename"]))
                  )
                : undefined,
              grades: gradeIds,
              code: code,
            },
          });
          break;

        case "ATL":
          result = await client.mutate({
            mutation: editATLMutation,
            variables: {
              id,
              label,
              levels: levels
                ? _map(levels, level => _omit(level, ["__typename"]))
                : undefined,
              grades: gradeIds,
              subText: subText,
            },
          });
          break;
      }
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const removeNode = ({
  id,
  parent,
  nodeType,
  subject,
  levelId,
  plannerElementSetId,
}) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;

    let queryData = {};
    let mutationName;
    let typeData = {};
    switch (nodeType) {
      case "BENCHMARK":
        {
          mutationName = removeBenchmarkMutation;
          queryData = getOrganizationPypElementSetFromCache({
            organizationId,
            type: nodeType,
            subjects: subject,
          });
          typeData = {
            cacheRemoveFunction: removeNodeInCache,
            cacheWriteFunction: writeOrganizationPypElementSetInCache,
            cacheWriteFunctionParams: {
              organizationId,
              type: nodeType,
              data: queryData,
              subjects: subject,
            },
          };
        }
        break;
      case "ATL":
        {
          mutationName = removeATLMutation;
          queryData = getPypAtlSetFromCache({
            id: plannerElementSetId,
          });
          typeData = {
            cacheRemoveFunction: removeAtlNodeFromCache,
            cacheWriteFunction: writePypAtlSetInCache,
            cacheWriteFunctionParams: {
              id: plannerElementSetId,
              data: queryData,
            },
          };
        }
        break;
    }
    try {
      await client.mutate({
        mutation: mutationName,
        variables: {
          id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            removeNode: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { removeNode },
            },
          }
        ) => {
          if (removeNode) {
            setTimeout(() => {
              typeData.cacheRemoveFunction({
                id,
                queryData,
                organizationId,
                parentId: parent,
                nodeType,
                subject,
                plannerElementSetId,
              });
              updateSubjectNode({
                organizationId,
                nodeType,
                levelId,
                subject,
                parent,
              });
            });
          }
        },
      });
    } catch (e) {
      setTimeout(() => {
        typeData.cacheWriteFunction(typeData.cacheWriteFunctionParams);
        updateSubjectNode({
          organizationId,
          nodeType,
          levelId,
          subject,
          parent,
        });
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const getChildIds = ({ ids, nodeId, nodes }) => {
  const childIds = _get(
    _find(nodes, item => item.id == nodeId),
    "children",
    []
  );
  if (childIds.length == 0) {
    return;
  }

  _forEach(childIds, childId => {
    ids.push(childId);
    getChildIds({ ids, nodeId: childId, nodes });
  });
};

export const removeNodeInCache = ({
  id,
  parentId,
  queryData,
  organizationId,
  nodeType,
  subject,
}) => {
  const pypElement = getPypElementFromCache({
    type: nodeType,
    id: parentId,
  });

  const elementKey = _findKey(pypElementMapping, item => item == nodeType);
  const nodeSet = _get(queryData, `pypElement.${elementKey}`, {});

  const rootNodes = _filter(_get(nodeSet, "rootNodes", []), item => item != id);
  const nodes = _get(nodeSet, "nodes", []);
  const removedIds = [id];
  getChildIds({ ids: removedIds, nodes, nodeId: id });

  const updateNodeSet = {
    ...nodeSet,
    rootNodes: rootNodes,
    nodes: _filter(nodes, item => !_includes(removedIds, item.id)),
  };
  const updatedQueryData = {
    ...queryData,
    pypElement: {
      ...queryData.pypElement,
      [elementKey]: updateNodeSet,
    },
  };

  writeOrganizationPypElementSetInCache({
    organizationId,
    type: nodeType,
    data: updatedQueryData,
    subjects: subject,
  });

  if (parentId) {
    let children = _get(pypElement, "children", []);
    children = _filter(children, childId => childId != id);

    writePypElementFromCache({
      type: nodeType,
      id: parentId,
      data: {
        ...pypElement,
        children: children,
      },
    });
  }
};

export const addNodeInCache = ({
  addNode,
  levelId,
  parentId,
  id,
  queryData,
  organizationId,
  nodeType,
}) => {
  const pypElement = getPypElementFromCache({
    type: nodeType,
    id: parentId,
  });
  const elementKey = _findKey(pypElementMapping, item => item == nodeType);
  const nodeSet = _get(queryData, `pypElement.${elementKey}`, {});
  const nodes = _filter(
    _get(nodeSet, "nodes", []),
    item => item.levelId == levelId && item.parent == parentId
  );
  const maxDisplaySequence = _get(
    _maxBy(nodes, "displaySequence"),
    "displaySequence",
    0
  );

  let rootNodes = _get(nodeSet, "rootNodes", []);
  if (!addNode.parent) {
    rootNodes = _filter(rootNodes, nodeId => nodeId != id);
    rootNodes = [...rootNodes, addNode.id];
  }

  if (parentId || nodeType == "ATL") {
    const updateNodeSet = {
      ...nodeSet,
      rootNodes: rootNodes,
      nodes: [
        ..._filter(_get(nodeSet, "nodes", []), node => node.id != id),
        {
          displaySequence: maxDisplaySequence + 1,
          ...addNode,
        },
      ],
    };
    const updatedQueryData = {
      ...queryData,
      pypElement: {
        ...queryData.pypElement,
        [elementKey]: updateNodeSet,
      },
    };
    writeOrganizationPypElementSetInCache({
      organizationId,
      type: nodeType,
      data: updatedQueryData,
      subjects: addNode.subject,
    });
  }

  if (parentId) {
    let children = _get(pypElement, "children", []);
    children = _filter(children, childId => childId != id);

    writePypElementFromCache({
      type: nodeType,
      id: parentId,
      data: {
        ...pypElement,
        children: [...children, addNode.id],
      },
    });
  }
  setTimeout(() => {
    updateSubjectNode({
      addNode: { ...addNode, displaySequence: maxDisplaySequence + 1 },
      organizationId,
      nodeType,
      levelId,
      subject: addNode.subject,
      parent: parentId,
    });
  });
};

export const addNode = (
  {
    id = "NEW",
    levels = [],
    grades = [],
    parentId = null,
    subject,
    levelId,
    label = "",
    nodeType,
    subText = "",
    code = "",
    plannerElementSetConfig,
    atlCategory = {},
  },
  successCallBack
) => {
  return async (dispatch, getState) => {
    const { id: plannerElementSetId } = plannerElementSetConfig;
    const gradeIds = _map(grades, item => {
      return item.id;
    });
    const organizationId = getState().login.userInfo.org_id;

    let queryData = {};

    let typeData = {};
    switch (nodeType) {
      case "BENCHMARK":
        typeData = {
          typeName: "PlannerBenchmark",
          mutation: addBenchmarkMutation,
          levels: levels
            ? JSON.stringify(
                _map(levels, level => _omit(level, ["count", "__typename"]))
              )
            : undefined,
          query: getOrganizationPypElementSetFromCache,
          queryParams: {
            organizationId,
            type: nodeType,
            subjects: subject,
          },
          cacheAddFunction: addNodeInCache,
          cacheWriteFunction: writeOrganizationPypElementSetInCache,
          cacheWriteFunctionParams: {
            organizationId,
            type: nodeType,
            data: queryData,
            subjects: subject,
          },
        };

        break;
      case "ATL":
        typeData = {
          typeName: "PlannerATL",
          mutation: addATLMutation,
          levels: levels
            ? _map(levels, level => _omit(level, ["__typename"]))
            : undefined,
          query: getPypAtlSetFromCache,
          queryParams: {
            id: plannerElementSetId,
          },
          cacheAddFunction: addAtlNodeInCache,
          cacheWriteFunction: writePypAtlSetInCache,
          cacheWriteFunctionParams: {
            id: plannerElementSetId,
            data: queryData,
          },
        };
        break;
    }

    queryData = typeData.query(typeData.queryParams);
    const depth = parseInt(levelId.substring(levelId.length - 1));
    typeData.cacheAddFunction({
      addNode: {
        id,
        grades,
        label,
        subject,
        genericTags: [],
        levelId,
        levels,
        subText,
        type: "LEARNING_OUTCOME",
        depth: depth,
        parent: parentId,
        code: code,
        isLeaf: _get(levels, "length", -1) == depth,
        children: [],
        benchmarkQuestionCount: [],
        atlCategory: { ...(atlCategory || {}), __typename: "PlannerATL" },
        __typename: typeData.typeName,
      },
      id,
      levelId,
      parentId,
      queryData,
      organizationId,
      nodeType,
      plannerElementSetId,
    });

    try {
      await client.mutate({
        mutation: typeData.mutation,
        variables: {
          label,
          levels: typeData.levels,
          grades: gradeIds,
          subText,
          parentId,
          subject,
          levelId,
          code,
          type: "LEARNING_OUTCOME",
          depth: depth,
          plannerElementSetId,
        },
        update: (
          cache,
          {
            data: {
              planner: { addNode },
            },
          }
        ) => {
          setTimeout(() => {
            typeData.cacheAddFunction({
              addNode,
              id,
              levelId,
              parentId,
              queryData,
              organizationId,
              nodeType,
              plannerElementSetId,
            });
          });
        },
      });

      if (successCallBack) {
        successCallBack();
      }
    } catch (e) {
      setTimeout(() => {
        typeData.cacheWriteFunction(typeData.cacheWriteFunctionParams);
        updateSubjectNode({
          addNode: undefined,
          organizationId,
          nodeType,
          levelId,
          subject,
          parent: parentId,
        });
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      console.error(e);
    }
  };
};

export const addAtlNodeInCache = ({
  addNode,
  levelId,
  parentId,
  id,
  queryData,
  plannerElementSetId,
}) => {
  let rootNodes = _.get(queryData, "rootNodes", []);
  if (!addNode.parent) {
    rootNodes = _filter(rootNodes, nodeId => nodeId != id);
    rootNodes = [...rootNodes, addNode.id];
  }
  const nodes = _filter(
    _get(queryData, "nodes", []),
    item => item.levelId == levelId && item.parent == parentId
  );
  const maxDisplaySequence = _get(
    _maxBy(nodes, "displaySequence"),
    "displaySequence",
    0
  );
  const updateNodeSet = {
    ...queryData,
    rootNodes,
    nodes: [
      ..._filter(_get(queryData, "nodes", []), node => node.id != id),
      {
        displaySequence: maxDisplaySequence + 1,
        ...addNode,
      },
    ],
  };
  writePypAtlSetInCache({
    id: plannerElementSetId,
    data: _.cloneDeep(updateNodeSet),
  });

  if (parentId) {
    const pypElement = getPypElementFromCache({
      type: "ATL",
      id: parentId,
    });
    let children = _get(pypElement, "children", []);
    children = _filter(children, childId => childId != id);

    writePypElementFromCache({
      type: "ATL",
      id: parentId,
      data: {
        ...pypElement,
        children: [...children, addNode.id],
      },
    });
  }
};

const removeAtlNodeFromCache = ({
  id,
  queryData,
  plannerElementSetId,
  parentId,
}) => {
  const rootNodes = _filter(
    _get(queryData, "rootNodes", []),
    item => item.id != id
  );
  const nodes = _get(queryData, "nodes", []);

  const updateNodeSet = {
    ...queryData,
    rootNodes,
    nodes: _filter(nodes, item => item.id !== id),
  };

  writePypAtlSetInCache({
    id: plannerElementSetId,
    data: updateNodeSet,
  });

  if (parentId) {
    const pypElement = getPypElementFromCache({
      type: "ATL",
      id: parentId,
    });
    let children = _get(pypElement, "children", []);
    children = _filter(children, childId => childId != id);

    writePypElementFromCache({
      type: "ATL",
      id: parentId,
      data: {
        ...pypElement,
        children: children,
      },
    });
  }
};

export const createSubjectSnS = ({ params, inputForMultiLevelNodesQuery }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: createSubjectSnSMutation,
        variables: params,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: getMultiLevelNodesQuery,
            variables: inputForMultiLevelNodesQuery,
          },
        ],
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:saved_successfully",
          type: "tick",
          position: "toast-bottom-left",
        })
      );
      return _.get(result, "data.planner.addBenchmark.id", {});
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const setStandardSetOfSubject = params => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: setStandardSetOfSubjectMutation,
        variables: params,
      });

      return _get(result, "data.platform.updateSubject.benchmarkRootNode", {});
    } catch (e) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const updateSubjectItem = data => {
  return {
    type: UPDATE_SUBJECT_ITEM,
    data,
  };
};

export const onTableWithOperationCellChange = params => {
  const { id, changedCellId } = params;
  const queryData = getPlannerElementNodeFromCache({ id });
  const data = {
    ...queryData,
    [changedCellId]: params[changedCellId],
  };

  writePlannerElementInCache({ id, data });
};
const REDUCER_HANDLERS = {
  [UPDATE_SEARCH_FILTERS]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        searchFilters: {
          [key]: { $set: params[key] },
        },
      });
    });

    return state;
  },
  [UPDATE_FILTERS]: (state, action) => {
    const { key, data } = action.data;

    return update(state, {
      searchFilters: {
        [key]: { $set: data },
      },
    });
  },
  [RESET_NODE_EDITOR_FILTERS]: (state, action) => {
    return update(state, {
      searchFilters: {
        searchText: { $set: "" },
        grades: { $set: [] },
        tags: { $set: [] },
      },
    });
  },
  [UPDATE_EDIT_NODES]: (state, action) => {
    return update(state, {
      editNodes: { $set: action.data },
    });
  },
  [UPDATE_ROOT_NODE]: (state, action) => {
    return update(state, {
      rootNode: { $set: action.data },
    });
  },
  [TOGGLE_LOADING]: (state, action) => {
    return update(state, {
      isLoading: { $set: action.data },
    });
  },
  [UPDATE_SUBJECT_ITEM]: (state, action) => {
    return update(state, {
      subjectItem: { $set: action.data },
    });
  },
  [UPDATE_SNS_SETTINGS_ACTION]: (state, action) => {
    return update(state, {
      snsSettingsAction: { $set: action.data },
    });
  },
  [TOGGLE_NODE_EDITOR_MODE]: (state, action) => {
    return update(state, {
      nodeEditorMode: { $set: action.mode },
    });
  },
  [SET_SIDEBAR_SELECTED_ITEM]: (state, action) => {
    return update(state, {
      sidebarSelectedItem: {
        $set: { ...state.sidebarSelectedItem, ...action.data },
      },
    });
  },
  [RESET_NODE_EDITOR_STATE]: (state, action) => {
    return initialState;
  },
};

const initialState = {
  isLoading: false,
  searchFilters: { searchText: "", grades: [], tags: [] },
  editNodes: [],
  rootNode: {},
  subjectItem: {},
  nodeTemplate: {
    id: "",
    label: "",
    code: "",
    grades: [],
    type: "LEARNING_OUTCOME",
    levels: [],
  },
  snsSettingsAction: "NEW_SUBJECT",
  nodeEditorMode: "view",
  sidebarSelectedItem: {},
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
