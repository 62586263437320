import PropTypes from "prop-types";
import React from "react";

const VoiceIcon = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Classroom"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M1.7,7.8 C2.1,7.8 2.5,8.2 2.5,8.6 L2.5,8.6 L2.5,9.5 C2.5,12.5 5,15 8,15 C11,15 13.5,12.5 13.5,9.5 L13.5,9.5 L13.5,8.7 C13.5,8.3 13.9,7.9 14.3,7.9 C14.7,7.9 15.1,8.2 15.2,8.6 L15.2,8.6 L15.2,9.4 C15.2,13 12.5,16 8.9,16.4 L8.9,16.4 L8.9,20.4 L12,20.4 C12.4,20.4 12.8,20.8 12.8,21.2 C12.8,21.6 12.4,22 12,22 L12,22 L4.1,22 C3.7,22 3.3,21.6 3.3,21.2 C3.3,20.8 3.7,20.4 4.1,20.4 L4.1,20.4 L7.2,20.4 L7.2,16.4 C3.6,16 0.9,13 0.9,9.4 L0.9,9.4 L0.9,8.6 C0.9,8.2 1.3,7.8 1.7,7.8 Z M8,-1.82076576e-13 C10.2,-1.82076576e-13 11.9,1.8 11.9,3.9 L11.9,3.9 L11.9,9.5 C11.9,11.6 10.2,13.4 8,13.4 C5.8,13.4 4.1,11.6 4.1,9.4 L4.1,9.4 L4.1,3.9 C4.1,1.8 5.8,-1.82076576e-13 8,-1.82076576e-13 Z M8,1.5 C6.7,1.5 5.6,2.6 5.6,3.9 L5.6,3.9 L5.6,9.4 C5.6,10.7 6.7,11.8 8,11.8 C9.3,11.8 10.4,10.7 10.4,9.4 L10.4,9.4 L10.4,3.9 C10.4,2.6 9.3,1.5 8,1.5 Z"
        id="Combined-Shape"
        fill={props.fill}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

VoiceIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

VoiceIcon.defaultProps = {
  height: 22,
  width: 16,
  fill: "#fff",
};

export default VoiceIcon;
