import React, { useState } from "react";
import { UIModal, UIButton, SearchBar } from "UIComponents";
import { CloseOutlined, AddCircleOutlined } from "@toddle-design/web-icons";
import {
  AssessmentIllustration,
  colors,
  fontStyles,
} from "@toddle-design/theme";
import classes from "./AddItemsModal.scss";
import PropTypes from "prop-types";
import { useI18n } from "Hooks";

const searchBarStyle = {
  borderBottom: `1px solid ${colors.gray87}`,
  fontSize: "2rem",
  paddingBottom: "8px",
  height: "36px",
};
const inputTextStyle = {
  color: colors.gray13,
  fontStyle: fontStyles.demiBold,
};
const buttonContainerStyle = {
  fontStyle: fontStyles.bold,
};

const AddItemsModal = props => {
  const {
    items,
    onClickButton1,
    onClickButton2,
    buttonText1,
    buttonText2,
    modalTitle,
    searchPlaceholderText,
    noItemsAddedText,
    noItemsText,
    noItemsSubText,
    isCreateItems,
    onCreateItemClick,
    itemName,
    selectedItemsList,
    setSelectedItemsList,
  } = props;
  const { t } = useI18n(["scopeAndSequence"]);
  //state declerations
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsList, setItemslist] = useState(items);
  //search results list
  const [searchResultsList, setSearchResultsList] = useState(itemsList);

  const onEnterClick = e => {
    if (e.key === "Enter" && _.isEmpty(searchResultsList)) {
      onCreateItem();
    }
  };

  const onSearchChange = searchTerm => {
    setSearchTerm(_.trim(searchTerm));

    if (_.isEmpty(_.trim(searchTerm))) {
      setSearchResultsList(itemsList);
    } else {
      //we search for this searchFitler string in the labels of items
      const searchFilter = searchTerm.trim().toUpperCase();
      const newSearchResults = _.filter(itemsList, item => {
        if (item.label.toUpperCase().indexOf(searchFilter) > -1) {
          return true;
        }
      });
      setSearchResultsList(newSearchResults);
    }
  };

  const getIsCreateBtnVisible = () => {
    if (isCreateItems && searchTerm) {
      //if it is already selected, return false
      for (const item of selectedItemsList) {
        if (
          item &&
          item.label.trim().toUpperCase() === searchTerm.toUpperCase()
        ) {
          return false;
        }
      }
      //if anything in the searchResults matches the searchTerm then return false;
      for (const item of searchResultsList) {
        if (
          item &&
          item.label.trim().toUpperCase() === searchTerm.toUpperCase()
        ) {
          return false;
        }
      }
      //in every other case, return true
      return true;
    } else {
      return false;
    }
  };

  const onSearchItemClick = item => {
    //change unique temp tags list
    const newItems = _.filter(itemsList, tempItem => {
      if (tempItem.id != item.id) {
        return true;
      }
    });
    setItemslist(newItems);
    //remove this from search results list
    const newSearchResults = _.filter(searchResultsList, searchResultItem => {
      if (searchResultItem.id != item.id) {
        return true;
      }
    });
    setSearchResultsList(newSearchResults);
    //append this to selected items list
    const newSelectedTagsList = [...selectedItemsList];
    newSelectedTagsList.push(item);
    setSelectedItemsList(newSelectedTagsList);
  };

  const onRemoveClick = item => {
    //Remove from selected Tags list
    const newSelectedItems = _.filter(selectedItemsList, tagItem => {
      if (tagItem.id != item.id) {
        return true;
      }
    });
    setSelectedItemsList(newSelectedItems);
    //add this to itemsList
    setItemslist(prevItems => [...prevItems, item]);

    //add this to search List if required
    const filter = searchTerm.toUpperCase();
    if (item.label.toUpperCase().indexOf(filter) > -1) {
      const newSearchResults = [...searchResultsList];
      newSearchResults.push(item);
      setSearchResultsList(newSearchResults);
    }
  };

  const onCreateItem = () => {
    onCreateItemClick(searchTerm);
  };

  //handle enter key press
  const handleKeyPress = e => {
    e.persist();
    if (e.key === "Enter" && searchTerm) {
      if (!_.isEmpty(searchResultsList)) {
        onSearchItemClick(searchResultsList[0]);
      }
    }
  };

  const isCreateBtnVisible = getIsCreateBtnVisible();

  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      overlayContainer={classes.modalOverlayContainer}
      onRequestClose={onClickButton1}
    >
      <div className={classes.container}>
        <div className={classes.header}>{modalTitle}</div>
        <div className={classes.body}>
          <div onKeyPress={handleKeyPress}>
            <SearchBar
              showSearchIcon={false}
              customStyle={searchBarStyle}
              inputTextStyle={inputTextStyle}
              placeholder={searchPlaceholderText}
              searchTerm={searchTerm}
              changeSearchTerm={onSearchChange}
              handleKeyDown={onEnterClick}
              clearSearchTermOnEnter={true}
            />
          </div>

          {_.isEmpty(items) &&
          _.isEmpty(searchTerm) &&
          _.isEmpty(selectedItemsList) &&
          _.isEmpty(searchResultsList) ? (
            <div className={classes.emptyTagsContainer}>
              <div className={classes.emptyTagsIcon}>
                <img
                  src={AssessmentIllustration}
                  width={"152px"}
                  height={"128px"}
                />
              </div>
              <div className={classes.emptyTagsTextContainer}>
                <div className={classes.emptyTagsTextHeader}>{noItemsText}</div>
                <div className={classes.emptyTagsTextDescription}>
                  {noItemsSubText}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.tagsContainer}>
              <div className={classes.selectedTagsContainer}>
                {!_.isEmpty(selectedItemsList) &&
                  _.map(selectedItemsList, item => {
                    const onCloseBtnClick = () => {
                      onRemoveClick(item);
                    };
                    return (
                      <div key={item.id} className={classes.selectedTag}>
                        <div className={classes.selectedTagText}>
                          {item.label}
                        </div>
                        <div
                          className={classes.selectedTagIcon}
                          onClick={onCloseBtnClick}
                        >
                          <CloseOutlined variant={"on"} size={"xxx-small"} />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={classes.searchResultsContainer}>
                {_.map(searchResultsList, item => {
                  const onItemClick = () => {
                    onSearchItemClick(item);
                  };
                  return (
                    <div
                      key={item.id}
                      onClick={onItemClick}
                      className={classes.searchResultsItem}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>

              {isCreateBtnVisible && (
                <div
                  className={classes.addNewTagContainer}
                  onClick={onCreateItem}
                >
                  <div className={classes.addNewTagIcon}>
                    <AddCircleOutlined size={"xxx-small"} />
                  </div>
                  <div className={classes.addNewTagText}>
                    {t("scopeAndSequence:add_new_tag", {
                      type: itemName,
                    })}
                    <b>{` ${searchTerm}`}</b>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <UIButton
            size="sm"
            onClick={onClickButton1}
            containerStyle={buttonContainerStyle}
          >
            {buttonText1}
          </UIButton>
          <UIButton
            size="sm"
            onClick={onClickButton2}
            containerStyle={{ ...buttonContainerStyle, marginLeft: "16px" }}
            color="pink"
            isDisabled={_.isEmpty(selectedItemsList)}
          >
            {buttonText2}
          </UIButton>
        </div>
      </div>
    </UIModal>
  );
};

AddItemsModal.defaultProps = {
  buttonText1: "Cancel",
  buttonText2: "Add",
  modalTitle: "Add Items",
  searchPlaceholderText: "Search an item",
  noItemsAddedText: "No items added yet",
  noItemsText: "No items created yet",
  noItemsSubText: "Go ahead and create your first item",
  isCreateItems: true,
  itemName: "item",
};
AddItemsModal.propTypes = {
  buttonText1: PropTypes.string,
  buttonText2: PropTypes.string,
  modalTitle: PropTypes.string,
  searchPlaceholderText: PropTypes.string,
  noItemsAddedText: PropTypes.string,
  noItemsText: PropTypes.string,
  noItemsSubText: PropTypes.string,
  itemName: PropTypes.string,
  isCreateItems: PropTypes.bool,
  selectedItemsList: PropTypes.array,
  onClickButton1: PropTypes.func,
  onClickButton2: PropTypes.func,
  onCreateItemClick: PropTypes.func,
  setSelectedItemsList: PropTypes.func,
};
export default AddItemsModal;
