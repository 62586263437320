import { withAsyncRouteLoading } from "UIComponents";
export default store => {
  return {
    path: ":element_id",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
  };
};
