import momentTimeZone from "moment-timezone";

export const isTouchable = () => {
  const userAgent = getUserAgent();

  return (
    !!userAgent && userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
  );
};

export const isTouchableV2 = () => {
  if (_.get(window, "matchMedia")) {
    return _.get(window.matchMedia("(pointer: coarse)"), "matches", false);
  }

  return false;
};
export const getUserAgent = () => {
  return (
    _.get(window, "navigator.userAgent", undefined) ||
    _.get(navigator, "userAgent") ||
    _.get(navigator, "vendor") ||
    _.get(window, "opera")
  );
};
export const isIphone = () => {
  const userAgent = getUserAgent();

  return !!userAgent && userAgent.match(/(iPad)|(iPhone)|(iPod)/i);
};

export const isAndroid = () => {
  const userAgent = getUserAgent();

  return !!userAgent && userAgent.match(/(android)|(webOS)/i);
};

export const isMobile = () => {
  // const userAgent = _.get(window, "navigator.userAgent", undefined);
  // return !!userAgent && userAgent.match(/(iPhone)|(iPod)|(android)/i);
  return isTouchable() ? window.outerWidth <= 776 : window.innerWidth <= 776;
};

export const isIpad = () => {
  return (
    (/iPad/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) && // ipad 12 +
    !window.MSStream
  );
};

export const isTablet = () => {
  return isTouchable()
    ? window.outerWidth <= 1024 && window.outerWidth > 776
    : window.innerWidth <= 1024 && window.innerWidth > 776;
};

export const isDesktop = () => {
  return isTouchable() ? window.outerWidth > 1024 : window.innerWidth > 1024;
};

export const isSafari = () => {
  const userAgent = _.toLower(_.get(window, "navigator.userAgent", ""));
  return userAgent.search("safari") > -1 && userAgent.search("chrome") < 0;
};

export const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;

  return (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  );
};

export const isSmallMobile = () => {
  return (
    isTouchable() && (window.outerHeight <= 450 || window.outerWidth <= 800)
  );
};

const getCountryCode = () => {
  try {
    const localeTimeZone = momentTimeZone.tz.guess();
    const localeCountryCodes = momentTimeZone.tz
      .zone(localeTimeZone)
      .countries();
    return _.first(localeCountryCodes);
  } catch {
    return "";
  }
};

const checkChinaAndroid = () => {
  try {
    const isAndroidDevice = isAndroid();
    const countryCode = getCountryCode();
    return isAndroidDevice && countryCode == "CN";
  } catch {
    return false;
  }
};

const storeLinks = {
  STUDENT: {
    ios: `itms-apps://itunes.apple.com/app/toddle-student/id1473393667?ls=1&mt=8`,
    android: "http://play.google.com/store/apps/details?id=com.toddle.student",
  },
  STAFF: {
    ios: `itms-apps://apps.apple.com/app/id1529065681`,
    android: "http://play.google.com/store/apps/details?id=com.toddle.teacher",
  },
  FAMILY_MEMBER: {
    ios: `https://apps.apple.com/app/toddle-family/id1473392321`,
    android: "http://play.google.com/store/apps/details?id=com.toddle.family",
  },
};

const s3ApkLinks = {
  STUDENT:
    "https://toddle.s3.eu-west-1.amazonaws.com/app_builds/Student/student_latest.apk",
  STAFF:
    "https://toddle.s3.eu-west-1.amazonaws.com/app_builds/Educator/educator_latest.apk",
  FAMILY_MEMBER:
    "https://toddle.s3.eu-west-1.amazonaws.com/app_builds/Family/family_latest.apk",
};

export const goToAppStore = ({ appName = "FAMILY_MEMBER" } = {}) => {
  const isChinaAndroid = checkChinaAndroid();
  if (!isChinaAndroid) {
    const platformOS = isAndroid() ? "android" : "ios";
    window.location = storeLinks[appName][platformOS];
  } else {
    window.location = s3ApkLinks[appName];
  }
};
