/**--external-- */
import React from "react";
import PropTypes from "prop-types";

/**--internal-- */
import { AnimateHeight } from "UIComponents";

/**--relative-- */
import classes from "./FilterAccordionGroup.scss";
import DropdownItemHeader from "../DropdownItemHeader";
import FilterAccordion from "../FilterAccordion";
import { getTotalValuesInGroupMemoized } from "./FilterAccordionGroupUtils";
import { getValues } from "../DropdownContentUtils";

const FilterAccordionGroup = props => {
  const {
    label,
    optionList,
    valueGroupKey,
    valueList,
    updateAccordionState,
    searchText,
    getAllOptionLabel,
    openSections,
  } = props;

  const totalValues = getTotalValuesInGroupMemoized({ valueList });

  const isExpanded = _.includes(openSections, valueGroupKey);

  return (
    <div>
      <DropdownItemHeader
        isExpanded={isExpanded}
        onHeaderClick={() => updateAccordionState({ key: valueGroupKey })}
        totalValues={totalValues}
        label={label}
      />
      <AnimateHeight duration={300} height={isExpanded ? "auto" : 0}>
        <div className={classes.filterAccordionContainer}>
          {_.map(optionList, ({ key, label, options }) => {
            const accordionKey = `${valueGroupKey}-${key}`;

            const isExpanded = _.includes(openSections, accordionKey);

            const updateInputField = values => {
              props.updateInputField({
                key: valueGroupKey,
                subGroupKey: key,
                values,
              });
            };

            const onAccordionClick = () => {
              updateAccordionState({ key: accordionKey });
            };

            return (
              <FilterAccordion
                key={key}
                isExpanded={isExpanded}
                onAccordionClick={onAccordionClick}
                allOptionLabel={getAllOptionLabel({ label })}
                searchText={searchText}
                options={options}
                label={label}
                values={getValues({ valueList, key })}
                updateInputField={updateInputField}
              />
            );
          })}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default FilterAccordionGroup;

FilterAccordionGroup.propTypes = {
  label: PropTypes.string,
  optionList: PropTypes.array,
  valueGroupKey: PropTypes.string,
  valueList: PropTypes.array,
  updateAccordionState: PropTypes.func,
  searchText: PropTypes.string,
  getAllOptionLabel: PropTypes.string,
  openSections: PropTypes.array,
};

FilterAccordionGroup.displayName = "FilterAccordionGroup";
