import client from "apolloClient";
import moment from "moment";
import update from "immutability-helper";
import {
  createPlatformCalendarEventMutation,
  deletePlatformCalendarEventMutation,
  editPlatformCalendarEventMutation,
  updatePlatformCalendarEventBasicDetailsMutation,
} from "./MyCalendarMutation";
import { setToastMsg } from "Login/modules/LoginModule";
import { TrimTrailingSlashes, isURL } from "Utils";
import { generateDateTimeString } from "./Utils";
import momentTimeZone from "moment-timezone";

export const NAME = "myCalendar";

export const UPDATE_STATE = "UPDATE_STATE" + " " + NAME;

export const updateState = data => {
  return {
    type: UPDATE_STATE,
    data,
  };
};

export const createPlatformCalendarEvent = ({
  meetingObj,
  courses,
  dateForMonthlyRecurringOption,
  toggleFullScreenLoader,
  onClickDiscard,
}) => {
  return async dispatch => {
    // check for title not empty for meeting
    if (_.isEmpty(_.get(meetingObj, "label", ""))) {
      dispatch(setToastMsg("toastMsgs:please_enter_title_error"));
      return;
    }
    const meetingType = _.get(meetingObj, "type", "");
    const meetingURL = TrimTrailingSlashes(
      _.trim(_.get(meetingObj, "url", ""))
    );
    const scheduleTime = _.get(meetingObj, "scheduleTime");
    const scheduleEndTime = _.get(meetingObj, "scheduleEndTime");
    const isZoomAuthorized = _.get(meetingObj, "isZoomAuthorized");
    const isGoogleAuthorized = _.get(meetingObj, "isGoogleAuthorized");
    const microsoftAuthToken = _.get(meetingObj, "microsoftAuthToken", "");
    const meetingAuthToken = _.get(meetingObj, "authToken", "");

    const isRecurring = _.get(meetingObj, "isRecurring", false);

    let sendMeetingObj = {};

    const newCourseIds = _.map(courses, course => course.id);
    const newUserIds = _.reduce(
      courses,
      (result, obj) => {
        result = result.concat(_.map(obj.students, student => student.id));
        return result;
      },
      []
    );

    if (!newCourseIds.length) {
      dispatch(setToastMsg("toastMsgs:please_add_atleast_one_class_error"));
      return;
    }

    sendMeetingObj = update(sendMeetingObj, {
      label: {
        $set: _.get(meetingObj, "label", ""),
      },
    });

    sendMeetingObj = update(sendMeetingObj, {
      description: {
        $set: _.get(meetingObj, "description", ""),
      },
    });

    const scheduleString = generateDateTimeString({
      dateString: _.get(meetingObj, "scheduleDate"),
      timeString: _.get(meetingObj, "scheduleTime"),
    });

    const scheduleStringToSend = !_.isEmpty(scheduleString)
      ? moment(scheduleString, "YYYY-MM-DD:HH:mm", true).isValid()
        ? moment(scheduleString, "YYYY-MM-DD:HH:mm").toISOString()
        : moment(scheduleString, "YYYY-MM-DD").toISOString()
      : moment().toISOString();

    sendMeetingObj = update(sendMeetingObj, {
      startAt: {
        $set: scheduleStringToSend,
      },
    });

    const startTime = moment(scheduleTime, "HH:mm");
    const endTime = moment(scheduleEndTime, "HH:mm");
    // calculate total duration
    const duration = moment.duration(endTime.diff(startTime));

    const minutes = parseInt(duration.asMinutes());

    sendMeetingObj = update(sendMeetingObj, {
      durationInMin: {
        $set: minutes,
      },
    });

    if (isRecurring) {
      sendMeetingObj["recurrenceSetting"] = {
        frequency: _.get(meetingObj, "frequency"),
        weekDays: _.get(meetingObj, "recurringWeekDays"),
        interval: _.get(meetingObj, "recurringInterval"),
        endDate: _.get(meetingObj, "scheduledEndDate"),
        monthDays: _.get(meetingObj, "monthDays").length
          ? _.get(meetingObj, "monthDays")
          : _.get(meetingObj, "frequency") === "MONTHLY" &&
            _.get(meetingObj, "monthRecurringUnit") === "by_date"
          ? [+dateForMonthlyRecurringOption]
          : [],
        setPositions: _.get(meetingObj, "setPositions"),
        timezone: momentTimeZone.tz.guess(),
      };
    } else {
      sendMeetingObj["recurrenceSetting"] = null;
    }

    sendMeetingObj["meeting"] = {};

    sendMeetingObj = update(sendMeetingObj, {
      meeting: {
        type: {
          $set: meetingType,
        },
      },
    });

    switch (meetingType) {
      case "OTHER":
        // check for url not empty for OTHER meeting type
        if (_.isEmpty(meetingURL)) {
          dispatch(setToastMsg("toastMsgs:please_add_meeting_link"));
          return;
        } else if (!isURL(meetingURL)) {
          dispatch(setToastMsg("toastMsgs:please_enter_valid_link")); // if url is not valid display toast
          return;
        } else {
          sendMeetingObj = update(sendMeetingObj, {
            meeting: {
              url: {
                $set: _.startsWith(meetingURL, "https://")
                  ? meetingURL
                  : `https://${meetingURL}`,
              },
            },
          });
        }
        break;
      case "ZOOM":
        // check for whether user is zoom authorized or not to schedule a ZOOM call
        if (!isZoomAuthorized) {
          dispatch(setToastMsg("toastMsgs:please_link_zoom_account"));
          return;
        } else {
          sendMeetingObj = update(sendMeetingObj, {
            meeting: {
              authToken: {
                $set: meetingAuthToken,
              },
            },
          });
        }
        break;
      case "MICROSOFT":
        // check for whether microsoftAuthToken is there or not to schedule a MICROSOFT call
        if (_.isEmpty(microsoftAuthToken)) {
          dispatch(setToastMsg("toastMsgs:please_link_microsoft_account"));
          return;
        } else {
          sendMeetingObj = update(sendMeetingObj, {
            meeting: {
              authToken: {
                $set: microsoftAuthToken,
              },
            },
          });
        }
        break;
      case "GOOGLE":
        // check for whether microsoftAuthToken is there or not to schedule a MICROSOFT call
        if (!isGoogleAuthorized) {
          dispatch(setToastMsg("toastMsgs:please_link_google_account"));
          return;
        }
        break;
    }
    sendMeetingObj["courseIds"] = newCourseIds;
    sendMeetingObj["userIds"] = newUserIds;
    try {
      toggleFullScreenLoader(true);
      const result = await client.mutate({
        mutation: createPlatformCalendarEventMutation,
        variables: {
          ...sendMeetingObj,
        },
      });
      toggleFullScreenLoader(false);
      if (
        _.get(result, "data.platform.createPlatformCalendarEvent.warning") ||
        !_.get(result, "data.platform.createPlatformCalendarEvent.response")
      ) {
        dispatch(
          setToastMsg(
            `toastMsgs:${_.toLower(
              _.get(
                result,
                "data.platform.createPlatformCalendarEvent.warning.message",
                "something_went_wrong"
              )
            )}`
          )
        );
        return false;
      }
      onClickDiscard();
      return true;
    } catch (e) {
      // console.log(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    } finally {
      toggleFullScreenLoader(false);
    }
  };
};

export const deletePlatformCalendarEvent = ({
  id,
  actionFor,
  refetchEvents,
  setShowRecurringDeleteOptions,
  onCloseEventModal,
}) => {
  return async dispatch => {
    try {
      await client.mutate({
        mutation: deletePlatformCalendarEventMutation,
        variables: {
          id,
          actionFor,
        },
      });
      dispatch(
        setToastMsg({
          type: "success",
          msg: "toastMsgs:event_deleted_successfully",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    } finally {
      await refetchEvents();
      setShowRecurringDeleteOptions(false);
      onCloseEventModal();
    }
  };
};

export const editPlatformCalendarEvent = ({
  meetingObj,
  courses,
  dateForMonthlyRecurringOption,
  oldCourseIds,
  oldUserIds,
  toggleFullScreenLoader,
  onClickDiscard,
  actionFor,
}) => {
  return async dispatch => {
    // check for title not empty for meeting
    if (_.isEmpty(_.get(meetingObj, "label", ""))) {
      dispatch(setToastMsg("toastMsgs:please_enter_title_error"));
      return;
    }
    const meetingType = _.get(meetingObj, "type", "");
    const meetingURL = TrimTrailingSlashes(
      _.trim(_.get(meetingObj, "url", ""))
    );
    const scheduleTime = _.get(meetingObj, "scheduleTime");
    const scheduleEndTime = _.get(meetingObj, "scheduleEndTime");
    const isZoomAuthorized = _.get(meetingObj, "isZoomAuthorized");
    const microsoftAuthToken = _.get(meetingObj, "microsoftAuthToken", "");
    const meetingAuthToken = _.get(meetingObj, "authToken", "");

    const isRecurring = _.get(meetingObj, "isRecurring", false);

    let sendMeetingObj = {};

    const newCourseIds = _.map(courses, course => course.id);
    const newUserIds = _.reduce(
      courses,
      (result, obj) => {
        result = result.concat(_.map(obj.students, student => student.id));
        return result;
      },
      []
    );

    if (!newCourseIds.length) {
      dispatch(setToastMsg("toastMsgs:please_add_atleast_one_class_error"));
      return;
    }

    sendMeetingObj = update(sendMeetingObj, {
      label: {
        $set: _.get(meetingObj, "label", ""),
      },
    });

    sendMeetingObj = update(sendMeetingObj, {
      description: {
        $set: _.get(meetingObj, "description", ""),
      },
    });

    const scheduleString = generateDateTimeString({
      dateString: _.get(meetingObj, "scheduleDate"),
      timeString: _.get(meetingObj, "scheduleTime"),
    });

    const scheduleStringToSend = !_.isEmpty(scheduleString)
      ? moment(scheduleString, "YYYY-MM-DD:HH:mm", true).isValid()
        ? moment(scheduleString, "YYYY-MM-DD:HH:mm").toISOString()
        : moment(scheduleString, "YYYY-MM-DD").toISOString()
      : moment().toISOString();

    sendMeetingObj = update(sendMeetingObj, {
      startAt: {
        $set: scheduleStringToSend,
      },
    });

    const startTime = moment(scheduleTime, "HH:mm");
    const endTime = moment(scheduleEndTime, "HH:mm");
    // calculate total duration
    const duration = moment.duration(endTime.diff(startTime));

    const minutes = parseInt(duration.asMinutes());

    sendMeetingObj = update(sendMeetingObj, {
      durationInMin: {
        $set: minutes,
      },
    });

    if (isRecurring) {
      sendMeetingObj["recurrenceSetting"] = {
        frequency: _.get(meetingObj, "frequency"),
        weekDays: _.get(meetingObj, "recurringWeekDays"),
        interval: _.get(meetingObj, "recurringInterval"),
        endDate: _.get(meetingObj, "scheduledEndDate"),
        monthDays: _.get(meetingObj, "monthDays").length
          ? _.get(meetingObj, "monthDays")
          : _.get(meetingObj, "frequency") === "MONTHLY" &&
            _.get(meetingObj, "monthRecurringUnit") === "by_date"
          ? [+dateForMonthlyRecurringOption]
          : [],
        setPositions: _.get(meetingObj, "setPositions"),
        timezone: momentTimeZone.tz.guess(),
      };
    } else {
      sendMeetingObj["recurrenceSetting"] = null;
    }

    sendMeetingObj["meeting"] = {};

    sendMeetingObj = update(sendMeetingObj, {
      meeting: {
        type: {
          $set: meetingType,
        },
      },
    });

    switch (meetingType) {
      case "OTHER":
        // check for url not empty for OTHER meeting type
        if (_.isEmpty(meetingURL)) {
          dispatch(setToastMsg("toastMsgs:please_add_meeting_link"));
          return;
        } else if (!isURL(meetingURL)) {
          dispatch(setToastMsg("toastMsgs:please_enter_valid_link")); // if url is not valid display toast
          return;
        } else {
          sendMeetingObj = update(sendMeetingObj, {
            meeting: {
              url: {
                $set: _.startsWith(meetingURL, "https://")
                  ? meetingURL
                  : `https://${meetingURL}`,
              },
            },
          });
        }
        break;
      case "ZOOM":
        // check for whether user is zoom authorized or not to schedule a ZOOM call
        if (!isZoomAuthorized) {
          dispatch(setToastMsg("toastMsgs:please_link_zoom_account"));
          return;
        } else {
          sendMeetingObj = update(sendMeetingObj, {
            meeting: {
              authToken: {
                $set: meetingAuthToken,
              },
            },
          });
        }
        break;
      case "MICROSOFT":
        // check for whether microsoftAuthToken is there or not to schedule a MICROSOFT call
        if (_.isEmpty(microsoftAuthToken)) {
          dispatch(setToastMsg("toastMsgs:please_link_microsoft_account"));
          return;
        } else {
          sendMeetingObj = update(sendMeetingObj, {
            meeting: {
              authToken: {
                $set: microsoftAuthToken,
              },
            },
          });
        }
        break;
    }
    sendMeetingObj.id = _.get(meetingObj, "id");
    sendMeetingObj.removedCourseIds = _.differenceWith(
      oldCourseIds,
      newCourseIds,
      _.isEqual
    );
    sendMeetingObj.addedCourseIds = _.differenceWith(
      newCourseIds,
      oldCourseIds,
      _.isEqual
    );
    sendMeetingObj.removedUserIds = _.differenceWith(
      oldUserIds,
      newUserIds,
      _.isEqual
    );
    sendMeetingObj.addedUserIds = _.differenceWith(
      newUserIds,
      oldUserIds,
      _.isEqual
    );
    try {
      toggleFullScreenLoader(true);
      const result = await client.mutate({
        mutation: editPlatformCalendarEventMutation,
        variables: {
          ...sendMeetingObj,
          actionFor,
        },
      });
      toggleFullScreenLoader(false);
      if (
        _.get(result, "data.platform.editPlatformCalendarEvent.warning") ||
        !_.get(result, "data.platform.editPlatformCalendarEvent.response")
      ) {
        dispatch(
          setToastMsg(
            `toastMsgs:${_.toLower(
              _.get(
                result,
                "data.platform.editPlatformCalendarEvent.warning.message",
                "something_went_wrong"
              )
            )}`
          )
        );
        return false;
      }
      onClickDiscard();
      return true;
    } catch (e) {
      // console.log(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    } finally {
      toggleFullScreenLoader(false);
    }
  };
};

export const updatePlatformCalendarEventBasicDetails = ({
  id,
  rescheduleData,
  itemType,
  setIsQuickReschedule,
}) => {
  return async dispatch => {
    const startTime = moment(
      _.get(rescheduleData, "scheduleStartTime", ""),
      "hh:mm a"
    );
    const endTime = moment(
      _.get(rescheduleData, "scheduleEndTime", ""),
      "hh:mm a"
    );
    const duration = moment.duration(endTime.diff(startTime));
    const durationInMin = parseInt(duration.asMinutes());

    const scheduleString = generateDateTimeString({
      dateString: _.get(rescheduleData, "scheduleDate"),
      timeString: moment(_.get(rescheduleData, "scheduleStartTime", ""), [
        "hh:mm a",
      ]).format("HH:mm"),
    });
    const startAt = !_.isEmpty(scheduleString)
      ? moment(scheduleString, "YYYY-MM-DD:HH:mm", true).isValid()
        ? moment(scheduleString, "YYYY-MM-DD:HH:mm").toISOString()
        : moment(scheduleString, "YYYY-MM-DD").toISOString()
      : moment().toISOString();

    try {
      await client.mutate({
        mutation: updatePlatformCalendarEventBasicDetailsMutation,
        variables: { id, startAt, itemType, durationInMin },
      });
      return true;
    } catch (e) {
      // console.log(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    } finally {
      setIsQuickReschedule(false);
    }
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_STATE]: (state, action) => {
    const params = action.data;
    Object.keys(params).map(key => {
      state = update(state, {
        [key]: { $set: params[key] },
      });
    });
    return state;
  },
};

const initialState = {
  dailyView: {
    week: moment().isoWeek(),
    visibleDays: [0, 1, 2, 3, 4, 5, 6],
    selectedDate: moment().startOf("day").toDate().toISOString(),
    calendarIds: [],
    folderIds: [],
  },
  fullscreenView: {
    week: moment().isoWeek(),
    visibleDays: [0, 1, 2, 3, 4, 5, 6],
    calendarIds: [],
    folderIds: [],
    notUserCourseIds: [],
  },
  calendarParentColorMap: {},
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
