import React from "react";
import classes from "./UnitDetails.scss";
import { ResourceImagePlaceHolder } from "UIComponents";
import { useTranslation } from "react-i18next";
import { UnitPlanOutlined } from "@toddle-design/web-icons";

const UnitDetailComponent = ({ headerText, item, subDetailContainerStyle }) => {
  return (
    <div className={classes.subDetailContainer} style={subDetailContainerStyle}>
      <div className={classes.subDetailLeftContainer}>
        <div className={classes.detailSubHeaderText}>{headerText}</div>
        <div className={classes.detailText}>
          {!item
            ? `No ${_.lowerCase(headerText)} associated`
            : _.get(item, "title.value", "Untitled")}
        </div>
      </div>
      {item && (
        <div className={classes.subDetailRightContainer}>
          <ResourceImagePlaceHolder
            id={item.id}
            imageUrl={_.get(item, "image.value", "")}
            type={item.assessmentType}
          />
        </div>
      )}
    </div>
  );
};

const UnitDetails = ({
  unitPlan,
  resourceItem,
  resourceType,
  showIcon,
  subDetailContainerStyle,
  iconContainerStyle,
  iconVariant,
  showResourceItem = true,
}) => {
  const { t } = useTranslation(["journal", "common"]);
  return (
    <div className={classes.detailContainer}>
      <div className={classes.detailInnerContainer}>
        {showIcon && (
          <div
            className={classes.iconContainer}
            style={{ ...(iconContainerStyle || {}) }}
          >
            <UnitPlanOutlined
              size={"xx-small"}
              variant={iconVariant ?? "default"}
            />
          </div>
        )}
        <div className={classes.detailsHeaderText}>
          {t("journal:unit_details")}
        </div>
      </div>
      {unitPlan || resourceItem ? (
        <div className={classes.detailDataContainer}>
          <UnitDetailComponent
            headerText={t("common:unit")}
            item={unitPlan}
            subDetailContainerStyle={subDetailContainerStyle}
          />
          {showResourceItem && resourceItem && (
            <UnitDetailComponent
              headerText={t("common:le_label")}
              item={resourceItem}
              subDetailContainerStyle={subDetailContainerStyle}
            />
          )}
        </div>
      ) : (
        <div className={classes.subDetailContainer}>
          <div className={classes.detailText}>
            {t("no_unit_details_associated")}
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitDetails;
