import React from "react";
import classes from "./ResourceCard.scss";
import { DEFAULT_PROFILE_PIC, THUMB_URL_CUSTOM } from "store/static";
import classNames from "classnames";
import { Tick } from "SvgComponents";
import { ProfileAvtar } from "UIComponents";

class ResourceCard extends React.Component {
  isSelected = () => {
    let selectedResources = this.props.selectedResources;
    let resourceId = this.props.resource.url;
    return _.findIndex(selectedResources, { url: resourceId }) >= 0;
  };

  toggleSelectedResources = () => {
    const { selectedResources, resource, id, multi } = this.props;

    let index = _.findIndex(selectedResources, { url: resource.url });
    let param = {};
    if (index >= 0) {
      param = { data: "", index: index, type: "delete", multi };
    } else {
      param = { data: resource, index: "", type: "add", multi };
    }

    this.props.toggleSelectedResources(param, id);
  };

  onClickView = (e, url) => {
    url += `?utm_source=toddle&utm_medium=referral`;
    e.stopPropagation();
    window.open(url, "_blank");
  };

  render() {
    const { source, thumbURL, author } = this.props.resource;
    const { userName, imageUrl, firstName, lastName } = author || {};
    let profileImage = imageUrl
      ? `${THUMB_URL_CUSTOM}48x48/${imageUrl}`
      : `${DEFAULT_PROFILE_PIC}`;
    let thumbImage = thumbURL
      ? `url(${thumbURL})`
      : `url(${DEFAULT_PROFILE_PIC})`;
    let selectClassName = classNames(
      { [classes.container]: true },
      { [classes.selectedContainer]: this.isSelected() }
    );
    return (
      <div
        className={selectClassName}
        onClick={() => this.toggleSelectedResources()}
      >
        <div className={classes.thumbImageContainer}>
          <div
            className={classes.thumbImage}
            style={{ backgroundImage: thumbImage }}
          />
        </div>
        <div className={classes.footerBarContainer}>
          <div className={classes.footerBar}>
            <div className={classes.profileInfo}>
              <ProfileAvtar
                name={firstName}
                profileImage={profileImage}
                size={32}
              />
              <div>
                <div
                  onClick={e =>
                    this.onClickView(e, `http://unsplash.com/@${userName}`)
                  }
                  className={classes.profileName}
                >
                  {`${firstName ? firstName : `Untitled`} ${
                    lastName ? lastName : ``
                  }`}
                </div>
                <div
                  onClick={e => this.onClickView(e, `http://unsplash.com`)}
                  className={classes.source}
                >
                  {source}
                </div>
              </div>
            </div>
            {this.isSelected() ? (
              <div className={classes.selectedSvg}>
                <Tick width={24} height={24} />
              </div>
            ) : null}
          </div>
          <div className={classes.footBarHoverContainer}></div>
        </div>
      </div>
    );
  }
}

export default ResourceCard;
