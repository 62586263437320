import PropTypes from "prop-types";
import React from "react";

const DecrementArrow = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      fill={props.fill && props.fill}
    >
      <path d="M0.609266 0.420382C0.767787 0.2871 1.00434 0.30756 1.13762 0.466082L4.80975 4.83359C4.8915 4.93322 5.10427 4.96275 5.24386 4.82147L8.87014 0.467423C9.00268 0.308282 9.23913 0.286718 9.39827 0.41926C9.55742 0.551801 9.57898 0.788256 9.44644 0.947398L5.81182 5.31146C5.80712 5.31711 5.80225 5.32262 5.79722 5.32799C5.38333 5.76936 4.63355 5.79876 4.23152 5.31128L0.563566 0.94874C0.430284 0.790218 0.450744 0.553664 0.609266 0.420382Z" />
    </svg>
  );
};

DecrementArrow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

DecrementArrow.defaultProps = {
  width: 16,
  height: 16,
};
export default DecrementArrow;
