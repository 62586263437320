import React from "react";
import PropTypes from "prop-types";

const MathematicsSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <path
        d="M16 3.52002H25.28C27.0473 3.52002 28.48 4.95271 28.48 6.72002V16H16V3.52002Z"
        fill="#D4D1FF"
      />
      <path
        d="M15.6799 28.8L6.39993 28.8C4.63262 28.8 3.19993 27.3674 3.19993 25.6L3.19993 16.32L15.6799 16.32L15.6799 28.8Z"
        fill="#D4D1FF"
      />
      <rect x="3.5" y="3.5" width="25" height="25" rx="3.5" stroke="#5856D6" />
      <path d="M16 3.5V28.5" stroke="#5856D6" />
      <path d="M3.5 16H28.5" stroke="#5856D6" />
      <path
        d="M7 10H13"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87891 19.8787L12.1215 24.1213"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10H25"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 22H25"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7L10 13"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1211 19.8787L7.87845 24.1213"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 24.5L22 25"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 19V19.5"
        stroke="#5856D6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

MathematicsSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

MathematicsSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default MathematicsSGIcon;
