import ACLStore from "lib/aclStore";
import { colors } from "Constants";
import {
  ROLE_ADMIN,
  ROLE_SUPERVISOR,
  ROLE_STUDENT,
  PROJECT_PROGRESS_GROUP_BY_STATUS,
  PROJECT_PROGRESS_GROUP_BY_SUPERVISOR,
  PROJECT_GROUP_TYPE_COMMUNITY,
  PROJECT_GROUP_TYPE_PERSONAL,
  PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
  PROJECT_GROUP_TYPE_DP_CAS,
  PROJECT_GROUP_TYPE_DP_EE,
  PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
  PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
  PROJECT_STATUS_PENDING_FOR_APPROVAL,
  PROJECT_STATUS_NOT_STARTED,
  PROJECT_STATUS_DOING_GREAT,
  PROJECT_STATUS_NOT_QUITE,
  PROJECT_STATUS_IN_PROGRESS,
  PROJECT_STATUS_REVIEW_REQUESTED,
  PROJECT_STATUS_COMPLETED,
  PROJECT_REPORT_STATUS_PENDING,
  PROJECT_REPORT_STATUS_SUBMITTED,
  PROJECT_STATUS_REJECTED,
  PROJECT_REPORT_STATUS_APPROVED,
  PROJECT_STATUS_VERY_GOOD,
  PROJECT_STATUS_AVERAGE,
  PROJECT_STATUS_GOOD,
  PROJECT_STATUS_POOR,
  PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL,
  PROJECT_STATUS_APPROVED,
  PROJECT_STATUS_DRAFT,
  PROJECT_REPORT_STATUS_NOT_STARTED,
  PROJECT_REPORT_STATUS_NON_SUBMISSION,
} from "Projects/Constants/stringConstants";

export const ROLES_MAP = {
  coordinator: {
    id: "coordinator",
    value: ROLE_ADMIN,
  },
  supervisor: {
    id: "supervisor",
    value: ROLE_SUPERVISOR,
  },
  student: {
    id: "student",
    value: ROLE_STUDENT,
  },
};

export const getRoleValue = ({ user_type }) => {
  const isAdmin = ACLStore.can("AdminPortal");

  if (isAdmin) {
    return ROLES_MAP.coordinator.value;
  } else if (user_type == "staff") {
    return ROLES_MAP.supervisor.value;
  } else {
    return ROLES_MAP.student.value;
  }
};

export const PROJECT_STATUS = {
  [PROJECT_STATUS_PENDING_FOR_APPROVAL]: {
    id: PROJECT_STATUS_PENDING_FOR_APPROVAL,
    label: "project:pending_for_approval",
    color: colors.violet63,
    projects: [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON, PROJECT_GROUP_TYPE_DP_CAS],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_STATUS_IN_PROGRESS,
        PROJECT_STATUS_REVIEW_REQUESTED,
        PROJECT_STATUS_COMPLETED,
        PROJECT_STATUS_REJECTED,
        PROJECT_STATUS_APPROVED,
      ],
    },
  },
  [PROJECT_STATUS_NOT_STARTED]: {
    id: PROJECT_STATUS_NOT_STARTED,
    label: "project:not_started",
    color: colors.yellow50,
    projects: [
      PROJECT_GROUP_TYPE_COMMUNITY,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_DOING_GREAT,
        PROJECT_STATUS_NOT_QUITE,
        PROJECT_STATUS_POOR,
        PROJECT_STATUS_AVERAGE,
        PROJECT_STATUS_GOOD,
        PROJECT_STATUS_VERY_GOOD,
        PROJECT_STATUS_IN_PROGRESS,
        PROJECT_STATUS_REVIEW_REQUESTED,
        PROJECT_STATUS_COMPLETED,
        PROJECT_STATUS_REJECTED,
        PROJECT_STATUS_APPROVED,
      ],
    },
  },
  [PROJECT_STATUS_DOING_GREAT]: {
    id: PROJECT_STATUS_DOING_GREAT,
    label: "project:doing_great",
    color: colors.grass,
    projects: [PROJECT_GROUP_TYPE_COMMUNITY, PROJECT_GROUP_TYPE_PERSONAL],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_DOING_GREAT,
        PROJECT_STATUS_NOT_QUITE,
      ],
    },
  },
  [PROJECT_STATUS_NOT_QUITE]: {
    id: PROJECT_STATUS_NOT_QUITE,
    label: "project:not_quite",
    color: colors.salmon60,
    projects: [PROJECT_GROUP_TYPE_COMMUNITY, PROJECT_GROUP_TYPE_PERSONAL],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_DOING_GREAT,
        PROJECT_STATUS_NOT_QUITE,
      ],
    },
  },
  [PROJECT_STATUS_IN_PROGRESS]: {
    id: PROJECT_STATUS_IN_PROGRESS,
    label: "project:in_progress_approved",
    color: colors.grass,
    projects: [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_STATUS_IN_PROGRESS,
        PROJECT_STATUS_COMPLETED,
      ],
    },
  },
  [PROJECT_STATUS_REVIEW_REQUESTED]: {
    id: PROJECT_STATUS_REVIEW_REQUESTED,
    label: "project:review_requested",
    color: colors.yellow50,
    projects: [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_STATUS_REVIEW_REQUESTED,
        PROJECT_STATUS_COMPLETED,
      ],
    },
  },
  [PROJECT_STATUS_COMPLETED]: {
    id: PROJECT_STATUS_COMPLETED,
    label: "common:completed",
    color: colors.success42,
    projects: [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON, PROJECT_GROUP_TYPE_DP_CAS],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_STATUS_IN_PROGRESS,
        PROJECT_STATUS_REVIEW_REQUESTED,
        PROJECT_STATUS_COMPLETED,
        PROJECT_STATUS_REJECTED,
        PROJECT_STATUS_APPROVED,
        PROJECT_STATUS_DRAFT,
      ],
    },
  },
  [PROJECT_STATUS_REJECTED]: {
    id: PROJECT_STATUS_REJECTED,
    label: "project:request_revision",
    color: colors.borderCritical,
    projects: [PROJECT_GROUP_TYPE_DP_CAS],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_COMPLETED,
        PROJECT_STATUS_REJECTED,
        PROJECT_STATUS_APPROVED,
      ],
    },
  },
  [PROJECT_STATUS_APPROVED]: {
    id: PROJECT_STATUS_APPROVED,
    label: "common:approved",
    color: colors.success42,
    projects: [PROJECT_GROUP_TYPE_DP_CAS],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_STATUS_COMPLETED,
        PROJECT_STATUS_REJECTED,
        PROJECT_STATUS_APPROVED,
        PROJECT_STATUS_DRAFT,
      ],
    },
  },
  [PROJECT_STATUS_DRAFT]: {
    id: PROJECT_STATUS_DRAFT,
    label: "common:draft",
    color: colors.yellow50,
    projects: [PROJECT_GROUP_TYPE_DP_CAS],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_COMPLETED,
        PROJECT_STATUS_REJECTED,
        PROJECT_STATUS_APPROVED,
        PROJECT_STATUS_DRAFT,
      ],
    },
  },
  [PROJECT_STATUS_POOR]: {
    id: PROJECT_STATUS_POOR,
    label: "project:poor_progress",
    color: colors.red52,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_POOR,
        PROJECT_STATUS_AVERAGE,
        PROJECT_STATUS_GOOD,
        PROJECT_STATUS_VERY_GOOD,
      ],
    },
  },
  [PROJECT_STATUS_AVERAGE]: {
    id: PROJECT_STATUS_AVERAGE,
    label: "project:average_progress",
    color: colors.yellow38,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_POOR,
        PROJECT_STATUS_AVERAGE,
        PROJECT_STATUS_GOOD,
        PROJECT_STATUS_VERY_GOOD,
      ],
    },
  },
  [PROJECT_STATUS_GOOD]: {
    id: PROJECT_STATUS_GOOD,
    label: "project:good_progress",
    color: colors.blue29,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_POOR,
        PROJECT_STATUS_AVERAGE,
        PROJECT_STATUS_GOOD,
        PROJECT_STATUS_VERY_GOOD,
      ],
    },
  },
  [PROJECT_STATUS_VERY_GOOD]: {
    id: PROJECT_STATUS_VERY_GOOD,
    label: "project:very_good_progress",
    color: colors.blue29,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_STATUS_NOT_STARTED,
        PROJECT_STATUS_POOR,
        PROJECT_STATUS_AVERAGE,
        PROJECT_STATUS_GOOD,
        PROJECT_STATUS_VERY_GOOD,
      ],
    },
  },
};

export const PROJECT_STATUS_OPTIONS = [
  { key: PROJECT_STATUS_NOT_STARTED, label: "project:not_started" },
  { key: PROJECT_STATUS_DOING_GREAT, label: "project:doing_great" },
  { key: PROJECT_STATUS_NOT_QUITE, label: "project:not_quite" },
];

export const PROJECT_REPORT_STATUS = {
  [PROJECT_REPORT_STATUS_PENDING]: {
    id: PROJECT_REPORT_STATUS_PENDING,
    label: "project:pending",
    color: colors.yellow50,
    projects: [
      PROJECT_GROUP_TYPE_COMMUNITY,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_REPORT_STATUS_PENDING,
        PROJECT_REPORT_STATUS_SUBMITTED,
        PROJECT_REPORT_STATUS_APPROVED,
      ],
    },
  },
  [PROJECT_REPORT_STATUS_SUBMITTED]: {
    id: PROJECT_REPORT_STATUS_SUBMITTED,
    label: "project:submitted",
    color: colors.teal54,
    projects: [
      PROJECT_GROUP_TYPE_COMMUNITY,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_REPORT_STATUS_PENDING,
        PROJECT_REPORT_STATUS_SUBMITTED,
      ],
    },
  },
  [PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL]: {
    id: PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL,
    label: "project:pending_for_approval",
    color: colors.yellow50,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_REPORT_STATUS_APPROVED,
      ],
    },
  },
  [PROJECT_REPORT_STATUS_APPROVED]: {
    id: PROJECT_REPORT_STATUS_APPROVED,
    label: "project:approved",
    color: colors.teal42,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_REPORT_STATUS_PENDING_FOR_APPROVAL,
        PROJECT_REPORT_STATUS_APPROVED,
      ],
    },
  },
  [PROJECT_REPORT_STATUS_NOT_STARTED]: {
    id: PROJECT_REPORT_STATUS_NOT_STARTED,
    label: "project:not_started",
    color: colors.yellow50,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
      PROJECT_GROUP_TYPE_COMMUNITY,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
      PROJECT_GROUP_TYPE_DP_CAS,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_REPORT_STATUS_NOT_STARTED,
        PROJECT_REPORT_STATUS_APPROVED,
        PROJECT_REPORT_STATUS_SUBMITTED,
        PROJECT_REPORT_STATUS_NON_SUBMISSION,
      ],
    },
  },
  [PROJECT_REPORT_STATUS_NON_SUBMISSION]: {
    id: PROJECT_REPORT_STATUS_NON_SUBMISSION,
    label: "project:non_submission",
    color: colors.borderCritical,
    projects: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
      PROJECT_GROUP_TYPE_COMMUNITY,
      PROJECT_GROUP_TYPE_PERSONAL,
      PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
    ],
    disableStatus: {
      [ROLE_ADMIN]: [],
      [ROLE_SUPERVISOR]: [],
      [ROLE_STUDENT]: [
        PROJECT_REPORT_STATUS_NON_SUBMISSION,
        PROJECT_REPORT_STATUS_APPROVED,
        PROJECT_REPORT_STATUS_NOT_STARTED,
        PROJECT_REPORT_STATUS_SUBMITTED,
      ],
    },
  },
};

export const PROJECT_REPORT_STATUS_FILTER_OPTIONS = {
  [PROJECT_REPORT_STATUS_NOT_STARTED]: {
    label: "common:not_started",
    value: PROJECT_REPORT_STATUS_NOT_STARTED,
  },
  [PROJECT_REPORT_STATUS_APPROVED]: {
    label: "project:proposal_with_label",
    subLabel: "common:approved",

    value: PROJECT_REPORT_STATUS_APPROVED,
  },
  [PROJECT_REPORT_STATUS_SUBMITTED]: {
    label: "project:project_with_label",
    subLabel: "project:completed",
    value: PROJECT_REPORT_STATUS_SUBMITTED,
  },
  [PROJECT_REPORT_STATUS_NON_SUBMISSION]: {
    label: "project:non_submission",
    value: PROJECT_REPORT_STATUS_NON_SUBMISSION,
  },
};

export const OVERALL_PROGRESS = [
  {
    label: "project:not_started",
    value: PROJECT_STATUS_NOT_STARTED,
    style: { color: colors.yellow50 },
    projectType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
      PROJECT_GROUP_TYPE_DP_CAS,
    ],
  },
  {
    label: "project:poor_progress",
    value: PROJECT_STATUS_POOR,
    style: { color: colors.red52 },
    projectType: [
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
      PROJECT_GROUP_TYPE_DP_CAS,
    ],
  },
  {
    label: "project:average_progress",
    value: PROJECT_STATUS_AVERAGE,
    style: { color: colors.yellow38 },
    projectType: [],
  },
  {
    label: "project:doing_great",
    value: PROJECT_STATUS_GOOD,
    style: { color: colors.grass },
    projectType: [
      PROJECT_GROUP_TYPE_DP_CAS,
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
  {
    label: "project:exceeding_expectations",
    value: PROJECT_STATUS_VERY_GOOD,
    style: { color: colors.blue29 },
    projectType: [
      PROJECT_GROUP_TYPE_DP_CAS,
      PROJECT_GROUP_TYPE_DP_EE,
      PROJECT_GROUP_TYPE_DP_TOK_ESSAY,
      PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION,
    ],
  },
];

export const PROJECT_PROGRESS_GROUP_BY_OPTIONS = [
  { label: "project:supervisor", value: PROJECT_PROGRESS_GROUP_BY_SUPERVISOR },
  {
    label: "project:status",
    value: PROJECT_PROGRESS_GROUP_BY_STATUS,
  },
];

export const ACTIVITY_PROGRESS_GROUP_BY_OPTIONS = [
  { label: "project:supervisor", value: PROJECT_PROGRESS_GROUP_BY_SUPERVISOR },
  { label: "project:activity_status", value: PROJECT_PROGRESS_GROUP_BY_STATUS },
];

export const LOCALE_PROJECT_TYPE = {
  [PROJECT_GROUP_TYPE_COMMUNITY]: "project:community_project",
  [PROJECT_GROUP_TYPE_PERSONAL]: "project:personal_project",
  [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON]: "project:activity",
  [PROJECT_GROUP_TYPE_DP_CAS]: "project:cas",
  [PROJECT_GROUP_TYPE_DP_EE]: "project:extended_essay",
  [PROJECT_GROUP_TYPE_DP_TOK_EXHIBITION]: "project:tok_exhibition",
  [PROJECT_GROUP_TYPE_DP_TOK_ESSAY]: "project:tok_essay",
};
