import PropTypes from "prop-types";
import React from "react";

const Alert = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7.67308C13.6397 7.67308 7.67308 13.6397 7.67308 21C7.67308 28.3603 13.6397 34.3269 21 34.3269C28.3603 34.3269 34.3269 28.3603 34.3269 21C34.3269 13.6397 28.3603 7.67308 21 7.67308ZM5.25 21C5.25 12.3015 12.3015 5.25 21 5.25C29.6985 5.25 36.75 12.3015 36.75 21C36.75 29.6985 29.6985 36.75 21 36.75C12.3015 36.75 5.25 29.6985 5.25 21Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8789 25.4824C20.2098 25.4824 19.6674 24.94 19.6674 24.2709V14.942C19.6674 14.2729 20.2098 13.7305 20.8789 13.7305C21.548 13.7305 22.0905 14.2729 22.0905 14.942V24.2709C22.0905 24.94 21.548 25.4824 20.8789 25.4824Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8789 29.1172C20.2098 29.1172 19.6674 28.5748 19.6674 27.9056V27.7845C19.6674 27.1154 20.2098 26.573 20.8789 26.573C21.548 26.573 22.0905 27.1154 22.0905 27.7845V27.9056C22.0905 28.5748 21.548 29.1172 20.8789 29.1172Z"
        fill={fill}
      />
    </svg>
  );
};

Alert.defaultProps = {
  fill: "#FFAB00",
  width: "32px",
  height: "32px",
};

Alert.propTypes = {
  fill: PropTypes.string,
};

export default Alert;
