import React from "react";
import classes from "./ChildHeader.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { goToRelativeRoute } from "modules/Services";
import { I18nHOC } from "UIComponents";
import { withRouter } from "react-router";
import { compose } from "react-apollo";
import {
  getAdminMenuItemsMemoize,
  getItemLabel,
} from "Administrator/modules/Utils";
import { getCurriculumProgramLabel } from "Platform/modules/PlatformUtils";
import { getAdminNode } from "../../modules/AdministratorModule";

class ChildHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    const { childRouteName } = this.props;
    this.state = { childRouteName };
  }

  componentDidUpdate = prevProps => {
    const { subChildId: oldSubChildId, childId: oldChildId } = prevProps;
    const { subChildId, childId, childRouteName } = this.props;
    const isChildIdChanged = !_.isEqual(oldChildId, childId);
    const isSubChildIdChanged = !_.isEqual(oldSubChildId, subChildId);
    if (isChildIdChanged || (isSubChildIdChanged && subChildId))
      this.setState({ childRouteName });
  };

  render() {
    const {
      rightComponent,
      subText,
      t,
      containerStyle,
      subTextStyle,
      customLabel,
      parentRouteName,
      rootRoute,
      showBreadCrumb = true,
    } = this.props;
    const { childRouteName } = this.state;

    const headerClasses = classNames("text-body-2", classes.firstRow);

    const isParentRoutePresent = !!parentRouteName;

    const routeString = isParentRoutePresent
      ? `${rootRoute} / ${t(parentRouteName)}`
      : rootRoute;

    return (
      <div className={classes.container} style={containerStyle}>
        <div className={classes.leftContainer}>
          {showBreadCrumb && <div className={headerClasses}>{routeString}</div>}
          <div className={classes.secondRow}>
            {customLabel || childRouteName}
          </div>
          {subText && (
            <div className={classes.subText} style={subTextStyle}>
              {subText}
            </div>
          )}
        </div>
        {rightComponent}
      </div>
    );
  }
}

const mapActionCreators = { goToRelativeRoute };

const mapStateToProps = (state, ownProps) => {
  const { t, params } = ownProps;

  const curriculumProgramId = _.get(params, "curriculumProgramId", "");

  const organizationCurriculumPrograms = _.get(
    state,
    "platform.organizationCurriculumPrograms",
    []
  );

  const defaultRootRoute = t("common:school_configurations");

  const currentCurriculumProgram =
    _.find(
      organizationCurriculumPrograms,
      ({ id }) => id == curriculumProgramId
    ) ?? {};

  const rootRoute = _.isEmpty(currentCurriculumProgram)
    ? defaultRootRoute
    : getCurriculumProgramLabel({
        curriculumProgram: currentCurriculumProgram,
        shouldIncludeAcronym: true,
      });

  const { parentId, childId, subChildId } = _.get(
    state,
    "administrator.routes",
    {}
  );

  const adminMenuItems = getAdminMenuItemsMemoize();
  const { nodes } = adminMenuItems;
  const parentRouteName = _.get(_.find(nodes, { id: parentId }), "label", "");
  const item = getAdminNode(subChildId || childId);

  const currentPlannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    []
  );
  const childRouteName = getItemLabel({
    t,
    item,
    plannerElementSets: currentPlannerElementSets,
  });

  return {
    parentRouteName,
    childRouteName,
    parentId,
    childId,
    subChildId,
    rootRoute,
  };
};

export default compose(
  I18nHOC({ resource: ["teacherHomePage", "common"] }),
  withRouter,
  connect(mapStateToProps, mapActionCreators)
)(ChildHeader);

ChildHeader.defaultProps = {
  rightComponent: null,
};
