import gql from "graphql-tag";
import { subjectFragment } from "modules/CommonFragments";

export const createSubjectMutation = gql`
  mutation createSubject(
    $name: String!
    $grades: [ID!]
    $icon: String
    $subjectGroupId: ID
    $curriculumProgramId: ID
    $presetSubjectId: ID
    $tags: [ID!]
  ) {
    platform {
      createSubject(
        input: {
          name: $name
          icon: $icon
          grades: $grades
          subjectGroupId: $subjectGroupId
          curriculumProgramId: $curriculumProgramId
          presetSubjectId: $presetSubjectId
          tags: $tags
        }
      ) {
        ...subjectItem
      }
    }
  }
  ${subjectFragment.subjectBenchmarkDetails}
`;

export const updateSubjectMutation = gql`
  mutation updateSubject(
    $id: ID!
    $name: String
    $grades: [ID!]
    $icon: String
    $addedTags: [ID!]
    $removedTags: [ID!]
  ) {
    platform {
      updateSubject(
        input: {
          id: $id
          name: $name
          grades: $grades
          icon: $icon
          addedTags: $addedTags
          removedTags: $removedTags
        }
      ) {
        id
        name
      }
    }
  }
`;

export const deleteSubjectMutation = gql`
  mutation deleteSubject($id: ID!) {
    platform {
      deleteSubject(input: { id: $id })
    }
  }
`;
