import React from "react";
import classes from "./ResponseItem.scss";
import { ProfileCascade, I18nHOC, Textview } from "UIComponents";

const styles = {
  profileCascade: {
    width: 32,
    height: 32,
  },
};

const ResponseItem = ({ item, t }) => {
  const {
    message,
    evidence,
    createdBy: { id: creatorId, firstName, lastName, profileImage },
  } = item;
  const evidencesCount = _.size(evidence);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <ProfileCascade
          customStyle={styles.profileCascade}
          items={[{ id: creatorId, value: firstName, imageUrl: profileImage }]}
        />
        <div className={classes.nameText}>{`${firstName} ${lastName}`}</div>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.richText} />
        {message && (
          <Textview
            mode={"view"}
            value={message}
            showViewEmpty={false}
            isWordWrapRequired={true}
          />
        )}
        {evidencesCount > 0 && (
          <div className={classes.evidenceCount}>{`${t(
            "common:evidence_count_interval",
            {
              postProcess: "interval",
              count: evidencesCount,
            }
          )} ${t("common:attached")}`}</div>
        )}
      </div>
    </div>
  );
};

export default I18nHOC({ resource: "common" })(ResponseItem);
