import React from "react";
import classes from "./LockOverlay.scss";
import { ProfileCascade } from "UIComponents";
import { LockIcon } from "SvgComponents";
import { colors } from "Constants";

const cascadeStyle = {
  width: 36,
  height: 36,
  boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.4)",
};
const LockOverlay = React.memo(props => {
  const { userInfo, readOnly } = props;
  const { id, firstName, lastName, profileImage } = userInfo;
  return (
    <React.Fragment>
      <div
        className={classes.container}
        style={{ opacity: readOnly ? 0.2 : 0.5 }}
      />
      {!_.isEmpty(userInfo) && (
        <div className={classes.profile}>
          <ProfileCascade
            items={[
              { id, value: `${firstName} ${lastName}`, imageUrl: profileImage },
            ]}
            length={1}
            customStyle={cascadeStyle}
            showToolTip={true}
          />
          <div className={classes.lock}>
            <LockIcon width={8} height={9} fill={colors.white} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default LockOverlay;
