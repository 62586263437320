import { gradeFragment, studentFragment } from "modules/CommonFragments";
import { courseFragment } from "./CourseFragments";
import gql from "graphql-tag";

export const getGradeDetailsQuery = gql`
  query getGradeDetails($ids: [ID!]) {
    multiNode(ids: $ids, type: GRADE) {
      id
      ... on Grade {
        ...gradeItem
      }
    }
  }
  ${gradeFragment.gradeDetails}
`;

export const getOrganizationCoursesStudents = gql`
  query getCourseStudentGroup(
    $organizationId: ID!
    $searchText: String
    $grades: [ID!]
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      ... on Organization {
        id
        courses(filters: { searchText: $searchText, grades: $grades }) {
          totalCount
          edges {
            node {
              id
              title
              collaborators: students {
                totalCount
                edges {
                  node {
                    ...studentItem
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${studentFragment.basicStudentDetails}
`;

export const getCourseStudentsFoldersQuery = gql`
  query getCourseDetails($id: ID!, $folderType: FOLDER_TYPE_ENUM!) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseFragment.courseStudentsFolders}
`;

export const getMultiCourseStudentsFoldersQuery = gql`
  query getMultipleCourseDetails($ids: [ID!], $folderType: FOLDER_TYPE_ENUM!) {
    multiNode(ids: $ids, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseFragment.courseStudentsFolders}
`;

export const getCourseDetailsQuery = gql`
  query getCourseDetails($id: ID!) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseDetailsItem
      }
    }
  }
  ${courseFragment.courseDetails}
`;

export const getCourseCollaboratorQuery = gql`
  query getCourseCollaborator($id: ID!) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseFragment.courseCollaborator}
`;

export const getOrganizationTeachersCountQuery = gql`
  query getOrganizationTeachersCountQuery(
    $organizationId: ID!
    $filters: OrgConnectionFilterInput
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        staff(filters: $filters) {
          userStatusCount {
            status
            totalCount
          }
          userCount {
            role
            totalCount
          }
          totalCount
        }
      }
    }
  }
`;

export const getCourseGenericFoldersQuery = gql`
  query getCourseGenericFolders(
    $courseId: ID!
    $folderType: FOLDER_TYPE_ENUM!
    $filters: TypeDetailsFilterInput
  ) {
    node(id: $courseId, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseFragment.courseGenericFoldersDetailsFragment}
`;

export const getCourseArchivedStudentsQuery = gql`
  query getCourseArchivedStudents($id: ID!, $filters: CourseFilter) {
    node(id: $id, type: COURSE) {
      ... on Course {
        id
        students(filters: $filters) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const getCourseSidebarTabsContentCountQuery = gql`
  query getCourseSidebarTabsContentCount($id: ID!) {
    node(id: $id, type: COURSE) {
      ... on Course {
        id
        ...courseUniPlansItem
        ...courseStudentFoldersItem
        ...courseAssesmentEvaluationCountItem
      }
    }
  }
  ${courseFragment.courseUnitPlansCountFragment}
  ${courseFragment.courseStudentFoldersCountFragment}
  ${courseFragment.courseAssesmentEvaluationCountFragment}
`;
