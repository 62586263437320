import ACLStore from "lib/aclStore";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_DP,
} from "Constants/stringConstants";

export const PDFTRON_VIEWER_MODE_TYPES = {
  TEACHER_EDITOR: "TEACHER_EDITOR",
  STUDENT_EDITOR: "STUDENT_EDITOR",
  VIEWER: "VIEWER",
};

export const canEditPdfInPdftron = ({ curriculumProgramType }) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_MYP:
      return ACLStore.can("FeatureFlag:MypPdfTronPDFSupport");
    case CURRICULUM_TYPE_DP:
      return ACLStore.can("FeatureFlag:DPPdfTronPDFSupport");
    default:
      return ACLStore.can("FeatureFlag:PdfTronPDFSupport");
  }
};

export const shouldConverPdfToWorkbook = ({ curriculumProgramType }) => {
  if (
    _.isEmpty(curriculumProgramType) ||
    canEditPdfInPdftron({ curriculumProgramType })
  )
    return false;

  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return ACLStore.can("FeatureFlag:PypPdfToWorkbookTemplate");
    default:
      return false;
  }
};

export const canViewPdfInPdftron = () => {
  return ACLStore.can("FeatureFlag:EnablePdfViewModeInPdftron");
};

export const canOpenOfficeFileInPdftron = () => {
  return ACLStore.can("FeatureFlag:PdfTronOfficeSupport");
};

export const canViewCommentsInPdftron = () => {
  return ACLStore.can("FeatureFlag:PdfTronEnableComments");
};

export const getFilteredDisableElementsInPdftron = disableElements => {
  const filteredDisableElements = canViewCommentsInPdftron()
    ? _.pull(disableElements, "toggleNotesButton", "notesPanel")
    : disableElements;

  return filteredDisableElements;
};
