import PropTypes from "prop-types";
import React from "react";

const TwitterIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 20 17">
      <g
        id="Website-Pages"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <path
          d="M17.9528548,4.04932721 C17.9614507,4.22521242 17.9647568,4.40175885 17.9647568,4.58028895 C17.9647568,10.0029755 13.8374054,16.2548352 6.2902106,16.2548352 C3.97328661,16.2548352 1.81571726,15.5757596 0,14.4120078 C0.321354184,14.4496975 0.647336926,14.4688729 0.978609449,14.4688729 C2.90144477,14.4688729 4.67087645,13.8129401 6.07465203,12.7126657 C4.27943267,12.6789434 2.76457169,11.4920488 2.24220584,9.86213509 C2.49214798,9.91106556 2.74936357,9.9355308 3.01385261,9.9355308 C3.38810461,9.9355308 3.75045459,9.88660032 4.09495157,9.79204549 C2.21707938,9.41514861 0.802724237,7.75547988 0.802724237,5.76916719 C0.802724237,5.75131418 0.802724237,5.73412239 0.80338546,5.71626938 C1.35616755,6.02373789 1.98895758,6.208219 2.66208219,6.23003934 C1.56048534,5.49277614 0.83644659,4.23843687 0.83644659,2.81416339 C0.83644659,2.06235329 1.03878071,1.35682878 1.39187357,0.751148874 C3.41587595,3.23337852 6.43964691,4.86725956 9.85023308,5.03785499 C9.78014349,4.73832116 9.74377624,4.42424042 9.74377624,4.10288624 C9.74377624,1.83753761 11.5806526,0 13.8473237,0 C15.027606,0 16.0934969,0.498561841 16.8420009,1.29533507 C17.7769696,1.11217641 18.6550732,0.769663107 19.4478791,0.299533838 C19.1404106,1.25830661 18.4904288,2.06235329 17.6427414,2.56951103 C18.4738982,2.47032764 19.2647205,2.24947929 20,1.92349655 C19.4511852,2.74804113 18.7549178,3.47075743 17.9528548,4.04932721 Z"
          id="Path"
        ></path>
      </g>
    </svg>
  );
};

TwitterIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

TwitterIcon.defaultProps = {
  height: 17,
  width: 20,
};

export default TwitterIcon;
