import { getMemoizedFunction } from "Utils";
const getFlattenOptions = ({ optionList }) => {
  const flattenOptions = [];
  _.forEach(optionList, ({ options, isLeafNode }) => {
    if (isLeafNode) {
      flattenOptions.push(...options);
    } else {
      const flattenSubGroupOptions = getFlattenOptions({ optionList: options });
      flattenOptions.push(...flattenSubGroupOptions);
    }
  });
  return flattenOptions;
};

export const getFlattenOptionsMemoized = getMemoizedFunction(getFlattenOptions);

const getFlattenValues = ({ valueList }) => {
  const flattenValues = [];

  _.forEach(valueList, ({ values, isLeafNode }) => {
    if (isLeafNode) {
      flattenValues.push(...values);
    } else {
      const flattenSubGroupValues = getFlattenValues({ valueList: values });
      flattenValues.push(...flattenSubGroupValues);
    }
  });

  return flattenValues;
};

export const getFlattenValuesMemoized = getMemoizedFunction(getFlattenValues);
