import React from "react";
import classes from "./CollaboratorList.scss";
import CollaboratorItem from "./CollaboratorItem";
import InviteBox from "./InviteBox";
import { getCountString } from "Utils";
import { AnimateHeight, I18nHOC } from "UIComponents";
import classNames from "classnames";
const FEED_COLLAPSE_LENGTH = 2;

const getAnimationData = ({ all }) => {
  let height = "auto";
  const duration = 300;

  if (!all) {
    height = FEED_COLLAPSE_LENGTH * 88 + 80;
  }

  return { height, duration };
};

const CollaboratorListComp = ({
  collaborators,
  countText,
  collaboratorLength,
  ownerId,
  openAll,
  lessOrMore,
  onActionClick,
  actionMenuItems,
  mode,
  hideCollaboratorListCountTxt,
}) => {
  return (
    <div className={classes.listContainer}>
      {collaboratorLength > 0 && !hideCollaboratorListCountTxt && (
        <div className={classes.countText}>{countText}</div>
      )}

      {_.map(collaborators, (item, index) => {
        const isOwner = item.id == ownerId;
        // const showSeeMore =
        //   lessOrMore && !openAll && index == FEED_COLLAPSE_LENGTH - 1;
        // const showSeeLess =
        //   lessOrMore && openAll && index == collaboratorLength - 1;
        return (
          <CollaboratorItem
            item={item}
            key={item.id}
            isShow={openAll || index < FEED_COLLAPSE_LENGTH}
            permission={item.permission}
            isOwner={isOwner ? "OWNER" : ""}
            mode={mode}
            onActionClick={onActionClick}
            actionMenuItems={actionMenuItems}
          />
        );
      })}
    </div>
  );
};
class CollaboratorList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { openAll: false };
  }

  toggelOpenAll = () => {
    this.setState({ openAll: true });
  };

  toggelCloseAll = () => {
    this.setState({ openAll: false });
  };

  onActionClick = ({ action, item }) => {
    const { onPermissionChanged, onRemoveClick } = this.props;
    switch (action) {
      case "EDIT":
      case "VIEW":
        onPermissionChanged({ ...item, permission: action });
        break;

      case "REMOVE":
        onRemoveClick(item);
        break;
    }
  };

  render() {
    const {
      collaborators,
      ownerId,
      listContainerStyle,
      onClickInvite,
      showInviteBox,
      t,
      mode,
      hideCollaboratorListCountTxt,
      actionMenuItems,
    } = this.props;

    const collaboratorLength = _.get(collaborators, "length", 0);
    const countText = getCountString({
      count: collaboratorLength,
      singularText: t("teacher_has_access"),
      pluralText: t("teachers_have_access"),
    });

    const { openAll } = this.state;
    const lessOrMore = collaborators.length > FEED_COLLAPSE_LENGTH;

    const animationData = getAnimationData({
      all: openAll || !lessOrMore,
    });

    const containerClassStyle = classNames(
      { [classes.container]: true },
      { [classes.editContainer]: mode == "edit" }
    );

    return (
      <div className={containerClassStyle} style={listContainerStyle}>
        <CollaboratorListComp
          hideCollaboratorListCountTxt={hideCollaboratorListCountTxt}
          collaborators={collaborators}
          countText={countText}
          openAll={openAll}
          collaboratorLength={collaboratorLength}
          onActionClick={this.onActionClick}
          ownerId={ownerId}
          lessOrMore={lessOrMore}
          toggelOpenAll={this.toggelOpenAll}
          toggelCloseAll={this.toggelCloseAll}
          mode={mode}
          actionMenuItems={actionMenuItems}
        />

        {showInviteBox && <InviteBox onClickInvite={onClickInvite}></InviteBox>}
      </div>
    );
  }
}

export default I18nHOC({ resource: "unitPlan" })(CollaboratorList);
