import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import StudentDriveChildren from "./routes/StudentDriveChildren";

export default store => ({
  path: "student-drive",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/StudentDriveModule").default;
            injectReducer(store, { key: "studentDrive", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-student-drive"
        );
      })
  ),
  childRoutes: [StudentDriveChildren(store)],
});
