import React from "react";
import classes from "./PostCard.scss";
import {
  ProfileCascade,
  MediaPlaceholder,
  I18nHOC,
  ButtonDropdown,
} from "UIComponents";
import { Tick } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";

const ProfileCascadeStyle = {
  width: 22,
  height: 22,
};

const dropDownContainerStyle = {
  right: "-24px",
};

const PostCard = React.memo(props => {
  const {
    id,
    title,
    createdBy: { id: createdById, firstName, lastName, profileImage },
    attachments,
    onPostSelectionCardClick,
    onPostMediaClick,
    shouldShowMoreOptions,
    authTabs,
    isSelected,
    isDisabled,
    styles = {},

    t,
    hideDefaultActions,
    items,
  } = props;

  const onPostSelectionCardClickLocal = () => {
    if (onPostSelectionCardClick) {
      onPostSelectionCardClick({ id });
    }
  };
  const onPostMediaClickLocal = () => {
    if (onPostMediaClick) {
      onPostMediaClick({ id, type: "VIEW_DETAILS" });
    }
  };
  const onDropdownItemClick = type => {
    if (onPostMediaClick) {
      onPostMediaClick({
        id,
        type,
        isStudentResponse: !_.isEmpty(items),
      });
    }
  };

  const containerStyle = classNames(
    { [classes.container]: true },
    { [classes.containerSelected]: isSelected },
    { [classes.containerDisable]: isDisabled }
  );

  const mediaContainerStyleObject = styles.mediaContainerStyle
    ? { containerStyle: styles.mediaContainerStyle }
    : {};

  return (
    <div
      className={containerStyle}
      style={styles.cardStyles}
      onClick={onPostSelectionCardClickLocal}
    >
      {isSelected && !hideDefaultActions && (
        <div className={classes.tickIcon}>
          <Tick fill={colors.blue29} />
        </div>
      )}
      <div className={classes.imageContainer} onClick={onPostMediaClickLocal}>
        <MediaPlaceholder
          attachments={attachments}
          imageMaxHeight={104}
          imageMaxWidth={215}
          resolutionMutliplier={2}
          {...mediaContainerStyleObject}
          //fitIn={false}
          postEntityItems={items}
        />
      </div>
      <div
        className={classes.detailContainer}
        style={_.get(styles, "detailContainerStyle", {})}
      >
        <div
          className={classes.titleText}
          style={_.get(styles, "titleTextStyle", {})}
        >
          {!!title ? title : t("common:untitled")}
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.createByContainer}>
            <ProfileCascade
              items={[
                { createdById, value: firstName, imageUrl: profileImage },
              ]}
              customStyle={ProfileCascadeStyle}
            />
            <div
              className={classes.createdByText}
              style={_.get(styles, "createdByTextStyle", {})}
            >{`${firstName} ${lastName}`}</div>
          </div>
          {shouldShowMoreOptions && (
            <ButtonDropdown
              authTabs={authTabs}
              containerStyle={dropDownContainerStyle}
              onItemClick={onDropdownItemClick}
            />
          )}
        </div>
      </div>
    </div>
  );
});

PostCard.defaultProps = {
  shouldShowMoreOptions: false,
  customTitleComponent: null,
};

export default I18nHOC({ resource: ["common"] })(PostCard);
