import PropTypes from "prop-types";
import React from "react";
import classes from "./InputTextField.scss";
import classNames from "classnames";
import UIBaseComponent from "UIComponents/UIBaseComponent";

class InputTextField extends UIBaseComponent {
  constructor(props) {
    super(props);
  }
  handleKeyPress = e => {
    const { handleKeyPress } = this.props;
    if (e.key === "Enter") {
      const param = {};
      param[e.target.name] = e.target.value;
      if (this.props.onEnter) {
        this.props.onEnter(param);
      }
    }
    if (handleKeyPress) {
      handleKeyPress(e);
    }
  };

  renderEdit = () => {
    const {
      inputStyle,
      disabled,
      type,
      placeholder,
      name,
      errorStyle,
      value,
      autoFocus,
      className,
    } = this.props;

    let updatedInputStyle = { ...inputStyle };
    const { error } = this.state;
    if (disabled) {
      updatedInputStyle = {
        ...updatedInputStyle,
        cursor: "not-allowed",
        borderWidth: 0,
      };
    }
    if (error) {
      updatedInputStyle = { ...updatedInputStyle, ...errorStyle };
    }
    const inputText = classNames(
      { [classes.inputText]: true },
      { [classes.disabledInputBox]: disabled },
      { [classes.error]: !!error },
      { [className]: true }
    );

    return (
      <input
        data-test-id={`input-text-${name}`}
        autoFocus={autoFocus}
        onKeyPress={this.handleKeyPress}
        disabled={disabled}
        style={updatedInputStyle}
        className={inputText}
        type={type}
        value={value ? value : ""}
        onChange={e => this.updateValue(e.target.value)}
        name={name}
        placeholder={placeholder}
        onBlur={e => this.onBlur({ value: e.target.value })}
        onFocus={e => this.onFocus({ value: e.target.value })}
        min={0}
        ref={this.updateInputRef}
      />
    );
  };
}

InputTextField.defaultProps = {
  ...UIBaseComponent.defaultProps,
  inputStyle: {},
  type: "text",
  autoFocus: false,
  showErrorText: false,
};

InputTextField.propTypes = {
  ...UIBaseComponent.propTypes,
  onBlur: PropTypes.func,
  inputStyle: PropTypes.object,
  onEnter: PropTypes.func,
  updateInputRef: PropTypes.func,
  showErrorText: PropTypes.bool,
};

export default InputTextField;
