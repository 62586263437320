import React from "react";
import PropTypes from "prop-types";
import classes from "./AssessStudentLearning.scss";
import { UILabel, RichTextEditor } from "UIComponents";
import UIBaseComponent from "UIComponents/UIBaseComponent";

class AssessStudentLearning extends UIBaseComponent {
  isValidValue = value => {
    return !!value && !!_.trim(value);
  };

  updateInputField = params => {
    this.updateValue(_.get(params, this.props.name, ""));
  };
  getLOIList = () => {
    return _.map(this.props.options, option => {
      return <li key={option.id}>{option.value}</li>;
    });
  };

  renderEdit = () => {
    const {
      value,
      height,
      width,
      name,
      onBlurInputField,
      onFocusInputField,
    } = this.props;

    return (
      <div className={classes.container}>
        {/* {!_.isEmpty(this.props.options) ? (
          <div className={classes.loiContainer}>
            <div className={classes.loiHeader}>
              {`Teachers will observe and collect evidence of:`}
            </div>
            <ol>{this.getLOIList()}</ol>
          </div>
        ) : null} */}

        <div className={classes.editor}>
          <RichTextEditor
            value={value}
            updateInputField={this.updateInputField}
            height={height}
            width={width}
            name={name}
            onBlurInputField={onBlurInputField}
            onFocusInputField={onFocusInputField}
          />
        </div>
      </div>
    );
  };
}

export default AssessStudentLearning;

AssessStudentLearning.propTypes = {
  ...UIBaseComponent.propTypes,
  height: PropTypes.number,
  width: PropTypes.string,
  options: PropTypes.array,
};

AssessStudentLearning.defaultProps = {
  ...UIBaseComponent.defaultProps,
  height: 120,
  width: "100%",
  type: "text",
  options: [],
};
