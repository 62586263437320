import React from "react";
import { I18nHOC } from "UIComponents";
import classes from "./CommunityBenefits.scss";
import { CLOUD_URL } from "Constants";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import SignupButton from "../SignupButton";

const options = ({ t }) => [
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_unitplans.svg`,
    title: t("community:community_benefits_1_title"),
    content: t("community:community_benefits_1_content"),
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_les.svg`,
    title: t("community:community_benefits_2_title"),
    content: t("community:community_benefits_2_content"),
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_collections.svg`,
    title: t("community:community_benefits_3_title"),
    content: t("community:community_benefits_3_content"),
  },
  {
    url: `${CLOUD_URL}/assets/webapp/community_paccess/icn_portfolios.svg`,
    title: t("community:community_benefits_4_title"),
    content: t("community:community_benefits_4_content"),
  },
];

const CommunityBenefits = ({ type, t }) => {
  return (
    <div className={classes.containerWrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          {t("community:public_access_community_benefits")}
        </div>

        <div className={classes.itemsContainer}>
          {_.map(options({ t }), ({ url, title, content, color }, index) => (
            <div key={index} className={classes.item}>
              <div className={classes.imageContainer}>
                <img src={url} />
              </div>
              <div className={classes.itemTitle} style={{ color }}>
                {title}
              </div>
              <div className={classes.itemContent}>{content}</div>
            </div>
          ))}
        </div>

        <SignupButton type={type} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: _.get(state, "login.userInfo.id"),
  };
};

export default compose(
  connect(mapStateToProps),
  I18nHOC({ resource: ["community", "common"] })
)(CommunityBenefits);
