import React from "react";
import classes from "./SelectAttachmentComponent.scss";
import { Button } from "@toddle-design/web";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { AttachmentList } from "Attachment";
import { compose } from "react-apollo";
import { UIModal, I18nHOC, NotAddedEmptyView } from "UIComponents";
import { connect } from "react-redux";
import { updateField } from "modules/Services";
import { withRouter } from "react-router";
import ModalComponentWrapper from "./ModalComponentWrapper";

const styles = {
  attachmentContainerStyle: {
    gridTemplateColumns: "288px",
  },
};

class SelectAttachmentComponent extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSelectModal: false,
    };
  }
  toggleModal = () => {
    const { showSelectModal } = this.state;
    this.setState({ showSelectModal: !showSelectModal });
  };

  createDropdownComponent = () => {
    const { buttonLabel } = this.props;
    return (
      <div className={classes.selectContainer}>
        <Button variant="outlined" size="medium" onClick={this.toggleModal}>
          {buttonLabel}
        </Button>
      </div>
    );
  };

  renderEdit = () => {
    const {
      value = [],
      options: { parentType, parentId },
      graphqlEnhancerQueryInfo,
      modalConfig,
    } = this.props;

    const { showSelectModal } = this.state;

    return (
      <div className={classes.container}>
        <AttachmentList
          value={value}
          parentType={parentType}
          createDropdownComponent={this.createDropdownComponent()}
          showCreateDropdown={_.isEmpty(value)}
          parentId={parentId}
          shouldCallMutation={false}
          attachmentContainerStyle={styles.attachmentContainerStyle}
          cardType={"design-media-card"}
          updateInputField={value => {
            this.updateValue(value);
          }}
        />
        {showSelectModal && (
          <UIModal isOpen={true} modalContent={classes.modalContainer}>
            <ModalComponentWrapper
              modalConfig={modalConfig}
              updateValue={this.updateValue}
              toggleModal={this.toggleModal}
              parentType={parentType}
              parentId={parentId}
              graphqlEnhancerQueryInfo={graphqlEnhancerQueryInfo}
            />
          </UIModal>
        )}
      </div>
    );
  };

  renderView = () => {
    const {
      value = [],
      options: { parentType, parentId },
      disabled,
      childMode,
    } = this.props;

    return (
      <div className={classes.container}>
        <AttachmentList
          value={value}
          parentType={parentType}
          showCreateDropdown={false}
          parentId={parentId}
          shouldCallMutation={false}
          attachmentContainerStyle={styles.attachmentContainerStyle}
          cardType={"design-media-card"}
          updateInputField={value => {
            this.updateValue(value);
          }}
          mode={childMode}
          disabled={disabled}
        />
      </div>
    );
  };

  renderViewEmpty = () => {
    const { inputStyle = {} } = this.props;

    return <NotAddedEmptyView inputStyle={inputStyle} />;
  };
}

const mapStateToProps = (state, ownProps) => {
  const mode = _.get(ownProps, "disabled", false) ? "view" : ownProps?.mode;

  //Mode coming from ownProps will be passed as the same to the child components.
  const childMode = _.get(ownProps, "mode", "edit");

  return {
    mode,
    childMode,
  };
};

const mapActionCreators = {
  updateField,
};

export default compose(
  withRouter,
  I18nHOC({
    resource: ["project", "common"],
  }),
  connect(mapStateToProps, mapActionCreators)
)(SelectAttachmentComponent);
