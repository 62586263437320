import React from "react";
import classes from "./Tooltip.scss";
import { MoreItemWithToolTip } from "UIComponents";
import { useI18n } from "Hooks";
import { isEmpty as _isEmpty, forEach as _forEach, map as _map } from "lodash";

const generateList = ({ data, key }) => {
  const active = [];
  const archived = [];

  _forEach(data, item => {
    const value = item[key];
    const { isArchived } = item;

    const element = <div key={value}>{value}</div>;

    if (isArchived) archived.push(element);
    else active.push(element);
  });

  return { active, archived };
};

const generateSection = ({ fields, t, label, maxLength }) => {
  return _map(fields, field => {
    const { locale, content, showLabel } = field;

    if (_isEmpty(content)) return null;

    let list = [...content];

    if (maxLength) {
      list = _.slice(content, 0, maxLength);
      const remainingCount = _.get(content, "length") - maxLength;
      if (remainingCount > 0) {
        list.push(
          t("common:and_label_more", {
            label: remainingCount,
          })
        );
      }
    }

    return (
      <span className={classes.section}>
        {showLabel && (
          <span className={classes.heading}>{t(locale, { label })}</span>
        )}
        <span className={classes.content}>{list}</span>
      </span>
    );
  });
};

const Tooltip = ({
  data = [],
  label,
  keyToMap = "title",
  maxLength,
  ...rest
}) => {
  const { t } = useI18n(["common"]);

  const { active, archived } = generateList({ data, key: keyToMap });

  const fields = [
    {
      content: active,
      locale: "common:active_label",
      showLabel: !_.isEmpty(archived),
    },
    {
      content: archived,
      locale: "common:archived_label",
      showLabel: true,
    },
  ];

  const moreComponent = (
    <div className={classes.hoverWrapper}>
      {generateSection({ fields, t, label, maxLength })}
    </div>
  );

  const items = _.map(data, item => item[keyToMap]);
  return (
    <MoreItemWithToolTip
      items={items}
      length={1}
      noItemText={t("common:no_with_label", { label })}
      renderMoreComponent={moreComponent}
      {...rest}
    />
  );
};

Tooltip.defaultProps = {
  maxLength: 5,
};

export default Tooltip;
