import React from "react";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { I18nHOC, withLoader } from "UIComponents";
import {
  RadioButton,
  Avatar,
  SearchInput,
  IconButton,
} from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";
import classNames from "classnames";

import { getCourseStudentListWithInviteCodesQuery } from "StudentPortfolio/modules/StudentPortfolioQuery";
import { getFilterObject } from "services/filterList";
import classes from "./StudentContent.scss";
import { prepareLoadMoreFeed } from "Utils";

const StudentContent = ({
  students,
  handleSelectionChange,
  searchText,
  setSearchText,
  t,
  selectedStudent,
  onClose,
  organizationName,
  allChildren,
}) => {
  const formattedStudents = _.map(_.get(students, "edges", []), edge => ({
    profile: edge.node.profileImage,
    name: `${edge.node.firstName} ${edge.node.lastName}`,
    id: edge.node.id,
    inviteCode: edge.node.familyInviteCode,
  }));

  const filteredStudentsBySearchText = _.filter(
    formattedStudents,
    student =>
      searchText === "" ||
      _.includes(_.lowerCase(student.name), _.lowerCase(searchText))
  );

  const childIds = _.map(allChildren, child => child.id);
  const nonChildrenStudents = _.filter(
    filteredStudentsBySearchText,
    student => !_.includes(childIds, student.id)
  );

  const studentNameStyles = classNames({
    "text-body-1": true,
    [classes.studentName]: true,
  });

  const headerTitleStyles = classNames({
    "heading-3": true,
    [classes.headerTitle]: true,
  });

  const subTitleStyles = classNames({
    "text-body-2": true,
    [classes.subheaderTitle]: true,
  });

  return (
    <>
      <div className={classes.heading}>
        <div>
          <div className={headerTitleStyles}>
            {t("common:select_your_child")}
          </div>
          <div className={subTitleStyles}>
            {t("common:select_child_subheading_2", { label: organizationName })}{" "}
            - {t("common:select_child_subheading")}
          </div>
        </div>
        <IconButton
          icon={<CloseOutlined />}
          size={"large"}
          variant={"plain"}
          onClick={onClose}
        />
      </div>
      <div className={classes.searchSection}>
        <SearchInput
          onChange={setSearchText}
          placeholder={t("common:search")}
        />
      </div>
      <div className={classes.studentList}>
        {_.map(nonChildrenStudents, student => (
          <div key={student.id} className={classes.radioBox}>
            <RadioButton
              checked={selectedStudent?.id === student.id}
              onChange={() => handleSelectionChange(student)}
            >
              <div className={classes.labelBox}>
                <div className={studentNameStyles}>{student.name}</div>
              </div>
            </RadioButton>
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    searchText: ownProps.searchText,
    courseId: ownProps.courseId,
    isData: true,
    isLoading: false,
  };
};

export default compose(
  connect(mapStateToProps, null),
  I18nHOC({ resource: ["integration", "common"] }),
  graphql(getCourseStudentListWithInviteCodesQuery, {
    name: "getCourseStudentPortfolio",
    skip: ({ courseId }) => !courseId,
    options: ({ searchText, courseId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: courseId,
        filters: getFilterObject({ searchText }),
      },
    }),
    props({
      getCourseStudentPortfolio,
      ownProps: { isData, isLoading, courseId, searchText },
    }) {
      return {
        getCourseStudentPortfolio,
        students: _.get(getCourseStudentPortfolio, "node.students", []),
        isData: !_.isEmpty(getCourseStudentPortfolio.node) && isData,
        networkStatus: getCourseStudentPortfolio.networkStatus,
        organizationName: _.get(
          getCourseStudentPortfolio,
          "node.organization.name"
        ),
        isLoading:
          _.includes([1, 2], getCourseStudentPortfolio["networkStatus"]) ||
          isLoading,
        loadMoreStudents: prepareLoadMoreFeed({
          query: getCourseStudentListWithInviteCodesQuery,
          queryData: getCourseStudentPortfolio,
          variables: {
            id: courseId,
            filters: getFilterObject({ searchText }),
            after: _.get(
              getCourseStudentPortfolio,
              "node.students.pageInfo.endCursor",
              ""
            ),
            key: "previousResult",
          },
        }),
      };
    },
  }),
  withLoader
)(StudentContent);
