import React from "react";
import classes from "./LineSeparator.scss";
import { UIBaseComponent } from "UIComponents";

class LineSeparator extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  renderEdit = () => {
    return <div className={classes.container}></div>;
  };
}

export default LineSeparator;
