import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";

export default store => ({
  path: "exam-registration/:examRegId/permissions",
  exact: true,
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("DPExamRegistration/modules/DPExamRegistrationModule")
              .default;
            injectReducer(store, { key: "dpExamReg", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "exam-registration"
        );
      })
  ),
  childRoutes: [],
});
