import React, { Component } from "react";
import PropTypes from "prop-types";
// import { defaultStyle } from "substyle";

import { calculateAbsoluteCardPosition } from "Utils";

import { countSuggestions, getSuggestions } from "./utils";
import Suggestion from "./Suggestion";
import LoadingIndicator from "./LoadingIndicator";

import { CommentTagIcon } from "SvgComponents";

import { colors } from "Constants";

import { I18nHOC } from "UIComponents";

class SuggestionsOverlay extends Component {
  static propTypes = {
    suggestions: PropTypes.object.isRequired,
    focusIndex: PropTypes.number,
    scrollFocusedIntoView: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    suggestions: {},
    notifyAllMessage: "common:all_notify_msg",
    onSelect: () => null,
  };

  componentDidUpdate() {
    if (
      !this.suggestionsParentRef ||
      this.suggestionsParentRef.offsetHeight >=
        this.suggestionsParentRef.scrollHeight ||
      !this.props.scrollFocusedIntoView
    ) {
      return;
    }

    const scrollTop = this.suggestionsParentRef.scrollTop;
    let { top, bottom } = this.suggestionsParent.children[
      this.props.focusIndex
    ].getBoundingClientRect();
    const {
      top: topContainer,
    } = this.suggestionsParentRef.getBoundingClientRect();
    top = top - topContainer + scrollTop;
    bottom = bottom - topContainer + scrollTop;

    if (top < scrollTop) {
      this.suggestionsParentRef.scrollTop = top;
    } else if (bottom > this.suggestionsParentRef.offsetHeight) {
      this.suggestionsParentRef.scrollTop =
        bottom - this.suggestionsParentRef.offsetHeight;
    }
  }

  render() {
    const {
      suggestions,
      isLoading,
      style,
      onMouseDown,
      commentContainerRef,
      query,
      showProfileAvatar,
      parentNode,
      suggestionsOverlayStyle,
      notifyAllMessage,
      t,
    } = this.props;

    // do not show suggestions until there is some data
    if (countSuggestions(suggestions) === 0 && !isLoading) {
      return null;
    }

    let containerStyle = {
      borderRadius: "4px",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      backgroundColor: "#ffffff",
      ...suggestionsOverlayStyle,
    };
    if (commentContainerRef) {
      const commentContainerRect = commentContainerRef.getBoundingClientRect();
      containerStyle.position = "absolute";
      containerStyle.left = "auto";
      containerStyle.width = commentContainerRect.width - 2;
      // console.log(
      //   parentNode,
      //   commentContainerRef,
      //   calculateAbsoluteCardPosition({
      //     parentNode,
      //     node: commentContainerRef,
      //     cardDimensions: { width: 384, height: 240 },
      //     direction: "UP_DOWN",
      //     margin: { x: 0, y: 4 }
      //   }),
      //   commentContainerRect
      // );
      //
      if (commentContainerRect.y - 4 > 244)
        containerStyle.bottom = commentContainerRect.height + 4;
      else {
        // containerStyle.marginTop = commentContainerRect.height + 40 + 4;
        containerStyle.top = commentContainerRect.height + 8;
      }
    }

    return !_.isEmpty(query) ? (
      <div
        // className={classes.listContainer}
        style={containerStyle}
        onMouseDown={onMouseDown}
      >
        <div
          ref={el => {
            this.suggestionsParentRef = el;
          }}
          onScroll={e => e.stopPropagation()}
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          <ul
            ref={el => {
              this.suggestionsParent = el;
            }}
            style={{ margin: 0, padding: 0, listStyleType: "none" }}
            // {...style("list")}
          >
            {this.renderSuggestions()}
          </ul>
        </div>

        <div
          style={{
            padding: "12px 16px 12px 16px",
            borderTop: "solid 1px #dbdbdb",
          }}
        >
          {t(notifyAllMessage)}
        </div>

        {this.renderLoadingIndicator()}
      </div>
    ) : (
      <div
        // className={classes.listContainer}
        style={{
          ...containerStyle,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "16px 16px 16px 16px",
        }}
        // onMouseDown={onMouseDown}
      >
        <div
          style={{
            width: "24px",
            height: "24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CommentTagIcon height={24} width={24} fill={colors.gray48} />
        </div>
        <div
          style={{
            width: "auto",
            display: "flex",
            color: colors.gray48,
            textAlign: "center",
            fontFamily: "AvenirNext-Regular",
            fontSize: "14px",
            marginTop: "12px",
            lineHeight: 1.43,
          }}
        >
          {t("start_typing_name")}
        </div>
      </div>
    );
  }

  renderSuggestions() {
    return getSuggestions(this.props.suggestions).reduce(
      (result, { suggestions, descriptor }) => [
        ...result,

        ...suggestions.map((suggestion, index) =>
          this.renderSuggestion(suggestion, descriptor, result.length + index)
        ),
      ],
      []
    );
  }

  renderSuggestion(suggestion, descriptor, index) {
    let id = this.getID(suggestion);
    let isFocused = index === this.props.focusIndex;

    let { mentionDescriptor, query } = descriptor;

    const type = mentionDescriptor.props.type;

    return (
      <Suggestion
        // style={this.props.style("item")}
        key={`${type}-${id}`}
        id={id}
        query={query}
        index={index}
        descriptor={mentionDescriptor}
        suggestion={suggestion}
        focused={isFocused}
        onClick={() => this.select(suggestion, descriptor)}
        onMouseEnter={() => this.handleMouseEnter(index)}
      />
    );
  }

  getID(suggestion) {
    if (suggestion instanceof String) {
      return suggestion;
    }

    return suggestion.id;
  }

  renderLoadingIndicator() {
    if (!this.props.isLoading) {
      return;
    }

    return (
      <LoadingIndicator
      //  {...this.props.style("loadingIndicator")}
      />
    );
  }

  handleMouseEnter(index, ev) {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(index);
    }
  }

  select(suggestion, descriptor) {
    this.props.onSelect(suggestion, descriptor);
  }
}

// const styled = defaultStyle(({ position }) => ({
// position: "absolute",
// zIndex: 1,
// backgroundColor: "white",
// marginTop: 14,
// minWidth: 100,
// ...position
// }));

export default I18nHOC({ resource: "common" })(SuggestionsOverlay);
