import gql from "graphql-tag";
import { resourceLibraryFragment } from "modules/CommonFragments";
export const updateLeMutation = gql`
  mutation updateLe(
    $fields: [UpdatePlannerFieldInput!]
    $updatedBy: ID!
    $leId: ID!
  ) {
    planner {
      updateLearningEngagement(
        input: { updatedBy: $updatedBy, fields: $fields, leId: $leId }
      ) {
        id
      }
    }
  }
`;

export const createLeMutation = gql`
  mutation createLearningEngagement(
    $templateId: ID!
    $createdBy: ID!
    $unitPlanId: ID!
    $fixedFields: CreateLearningEngagementFixedFieldInput
  ) {
    planner {
      createLearningEngagement(
        input: {
          templateId: $templateId
          createdBy: $createdBy
          fixedFields: $fixedFields
          unitPlanId: $unitPlanId
        }
      ) {
        ...resourceLibraryItem
      }
    }
  }
  ${resourceLibraryFragment.resourceFeed}
`;

export const deleteLeMutation = gql`
  mutation deleteLearningEngagement($id: ID!) {
    planner {
      deleteLearningEngagement(input: { id: $id })
    }
  }
`;

export const copyLEsMutation = gql`
  mutation copyLEs(
    $learningEngagementIds: [ID!]
    $copiedBy: ID!
    $unitPlanId: ID!
    $title: String
  ) {
    planner {
      copyLEs(
        input: {
          learningEngagementIds: $learningEngagementIds
          copiedBy: $copiedBy
          unitPlanId: $unitPlanId
          title: $title
        }
      ) {
        ...resourceLibraryItem
      }
    }
  }
  ${resourceLibraryFragment.resourceFeed}
`;
