import gql from "graphql-tag";
import { leFragment } from "./LeFragments";
export const getLeQuery = gql`
  query getLe($id: ID!) {
    node(id: $id, type: LEARNING_ENGAGEMENT) {
      id
      ... on LearningEngagement {
        ...leItem
      }
    }
  }
  ${leFragment.leDetails}
`;
