import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import StudentProgressReport from "ProgressReportDetails/routes/StudentProgressReport";
import Insights from "ProgressReportDetails/routes/Insights";

export default store => ({
  path: "student-view",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("ProgressReportDetails/components")
              .default;
            const reducer = require("ProgressReport/modules/ProgressReportModule")
              .default;
            injectReducer(store, { key: "progressReport", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-progressreport"
        );
      })
  ),
  childRoutes: [Insights(store), StudentProgressReport(store)],
});
