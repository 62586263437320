import React from "react";
import PropTypes from "prop-types";

const LinkIconV2 = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 22"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0102 7.94388C13.2473 8.18562 13.2473 8.57755 13.0102 8.81929L7.84823 14.0809C7.61107 14.3226 7.22655 14.3226 6.98939 14.0809C6.75223 13.8392 6.75223 13.4472 6.98939 13.2055L12.1513 7.94388C12.3885 7.70214 12.773 7.70214 13.0102 7.94388Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.90513 9.98666C6.14229 10.2284 6.14229 10.6203 5.90513 10.8621L4.62982 12.162L4.62059 12.1712C3.41775 13.3462 3.40432 15.299 4.62982 16.5482L4.63886 16.5576C5.79009 17.7821 7.70245 17.7973 8.92815 16.5531L10.1984 15.1967C10.43 14.9494 10.8144 14.9403 11.057 15.1763C11.2996 15.4124 11.3085 15.8042 11.0769 16.0515L9.80163 17.4133L9.79177 17.4236C8.10383 19.1441 5.40766 19.1594 3.76644 17.419C2.08308 15.6982 2.06965 12.9535 3.77538 11.2821L5.04629 9.98666C5.28345 9.74492 5.66797 9.74492 5.90513 9.98666Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2243 4.65856C17.9317 6.33147 17.9168 9.07969 16.2288 10.8002L14.9535 12.1001C14.7164 12.3419 14.3319 12.3419 14.0947 12.1001C13.8575 11.8584 13.8575 11.4665 14.0947 11.2247L15.37 9.9248C16.5955 8.67564 16.5821 6.72281 15.3792 5.54783L15.37 5.53862C14.1445 4.28945 12.2287 4.30314 11.0759 5.52921L11.0669 5.53862L9.79159 6.83854C9.55443 7.08028 9.16991 7.08028 8.93275 6.83854C8.69559 6.5968 8.69559 6.20487 8.93275 5.96313L10.2037 4.66768C11.8434 2.92902 14.5361 2.94271 16.2243 4.65856Z"
      fill={fill}
    />
  </svg>
);

LinkIconV2.defaultProps = {
  width: 20,
  height: 22,
};

LinkIconV2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default LinkIconV2;
