import React from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";

import classes from "./TopHeader.scss";
import { ToddleIcon, LeftIcon, FilterIcon } from "SvgComponents";
import { FilterDropdown, I18nHOC } from "UIComponents";

import { goToPlatformHomeChildRoute } from "modules/NavigationModule";

import { colors, fontStyle } from "Constants";

const styles = {
  filterBoxStyle: {
    minWidth: "178px",
    maxHeight: "60px",
    whiteSpace: "nowrap",
    border: `1px solid colors.strokeTwo`,
    backgroundColor: colors.toddleBG1,
    padding: "16px",
  },
  filterTextStyle: {
    display: "flex",
    alignItems: "center",
  },
  buttonDropDownContainerStyle: {
    zIndex: 13,
  },
  nameTextStyle: {
    fontSize: "1.6rem",
    lineHeight: 1.62,
    ...fontStyle.demiBold,
    color: colors.gray31,
  },
};

const TopHeader = props => {
  const {
    childName,
    goToPlatformHomeChildRoute,
    coursesForDropdown,
    handleCourseChange,
    activeCourse,
    t,
  } = props;

  const onBackClick = () => {
    goToPlatformHomeChildRoute({ route: "courses" });
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.logoContainer} onClick={onBackClick}>
          <div className={classes.chevronIconContainer}>
            <LeftIcon fill={colors.gray72} />
          </div>
          <div className={classes.toddleIconContainer}>
            <ToddleIcon />
          </div>
          <div className={classes.titleText}>
            {t("journal:labels_journal", {
              label: childName,
            })}
          </div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <FilterDropdown
          labelElement={
            <FilterIcon
              fill={activeCourse !== "ALL" ? colors.blue29 : colors.gray48}
            />
          }
          allOptionLabel={t("common:all_classes_with_label")}
          filterBoxStyle={styles.filterBoxStyle}
          buttonDropDownContainerStyle={styles.buttonDropDownContainerStyle}
          filterTextStyle={styles.filterTextStyle}
          nameTextStyle={styles.nameTextStyle}
          isCheckList={false}
          value={activeCourse}
          updateInputField={values => handleCourseChange(values[0])}
          options={coursesForDropdown}
        />
      </div>
    </div>
  );
};

const mapActionCreators = {
  goToPlatformHomeChildRoute,
};

const mapStateToProps = () => {};

export default compose(
  I18nHOC({ resource: ["journal", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(TopHeader);
