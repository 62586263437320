import { unitPlanFragment } from "AppComponents/Post/modules/PostFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";
import gql from "graphql-tag";
import {
  pypElementRatingFragment,
  resolvedFieldValueFragment,
  toolResponseFragment,
  criteriaSetFragment,
  criteriaValueFragment,
} from "modules/CommonFragments";

export const assignmentFragment = {
  assignmentProgressSummary: gql`
    fragment assignmentProgressSummaryItem on Assignment {
      id
      contentType
      createdAt
      isTeacherEvaluationEnabled
      isStudentEvaluationEnabled
      course {
        id
      }
      content {
        id
        ... on Assessment {
          id
          title {
            id
            value
          }
          assessmentTool {
            id
          }
          assessmentToolType
          template {
            id
            body
          }
          unitPlan {
            id
            allFields {
              ...unitPlanAllFields
            }
            ...unitPlanItem
          }
          fields(
            uids: [
              "measureAssessing"
              "objectivesMYP"
              "interdisciplinaryCriteriaMYP"
              "score"
            ]
          ) {
            id
            uid
            value
            resolvedMinimalTree {
              ...resolvedMinimalTreeItem
            }
          }
        }
      }
      assignedStudents {
        id
        student {
          id
        }
        toolResponses(userFilter: ALL) {
          ...toolResponseItem
        }
        ibPYPElementRatings {
          ...ratingItem
        }
        elementRemarks {
          id
          message
          ibPYPElementId
          ibPYPElementType
        }
      }
    }
    ${pypElementRatingFragment.studentElementRating}
    ${resolvedFieldValueFragment.resolvedMinimalTree}
    ${unitPlanFragment.unitPlanDetails}
    ${plannerFieldFragment.unitPlanAllFields}
    ${toolResponseFragment.toolResponse}
  `,
};

export const organizationAssignmentsFragment = {
  organizationAssignmentProgressSummary: gql`
    fragment organizationAssignmentItem on Organization {
      id
      assignments(
        filters: {
          contentTypes: $contentTypes
          courseIds: $courseIds
          isTeacherEvaluationEnabled: $isTeacherEvaluationEnabled
          assessmentToolTypes: $assessmentToolTypes
          assessmentTypes: $assessmentTypes
          isAssessmentScoringEnabled: $isAssessmentScoringEnabled
        }
      ) {
        edges {
          node {
            ...assignmentProgressSummaryItem
          }
        }
      }
    }
    ${assignmentFragment.assignmentProgressSummary}
  `,
};

export const courseFragment = {
  courseDetails: gql`
    fragment courseDetailsItem on Course {
      id
      title

      subjects {
        id
      }
      grades {
        id
        name
      }
    }
  `,
};

export const studentSubjectSummaryPypRatingFragment = {
  studentElementRating: gql`
    fragment ratingItem on IBPYPElementRating {
      id
      ibPYPElementId
      ibPYPElementType

      academicCriteriaSet {
        ...criteriaSetItem
      }
      academicCriteriaValue {
        ...criteriaValueItem
      }
      parentType
    }
    ${criteriaSetFragment.criteriaSet}
    ${criteriaValueFragment.criteriaValue}
  `,
};
