import React, { useState } from "react";
import { UIButton, OtpInput } from "UIComponents";
import classes from "./CodeVerification.scss";
import { CLOUD_URL, colors, fontStyle } from "Constants";
import { CancelIcon } from "SvgComponents";

const INPUT_LENGTH = 8;

const CodeVerification = ({
  t,
  handleClick,
  errorMessage,
  setRetentionModalStatus,
}) => {
  const [code, setCode] = useState("");

  const handleCancel = () => {
    setRetentionModalStatus(false);
  };

  const onChangeOTP = code => setCode(code);

  const submitCode = () => {
    handleClick({
      codes: _.toUpper(code),
    });
  };

  return (
    <div
      className={classes.enterCodeContainer}
      style={{
        backgroundImage: `url('${CLOUD_URL}/assets/webapp/Community/Type%20A_Last_mail_community%20Modal_without_code_boxes.png')`,
      }}
    >
      <div className={classes.cancelIcon} onClick={handleCancel}>
        <CancelIcon width={16} height={16} />
      </div>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          {t("community:retention_enter_code_header")}
        </div>
        <div className={classes.header}>
          {t("community:retention_enter_code_header_2")}
        </div>
      </div>

      <div className={classes.subText}>
        {t("community:retention_enter_code_subtext")}
      </div>
      <div className={classes.otpInput}>
        <OtpInput
          inputStyle={{
            width: "38px",
            height: "56px",
            margin: "4px",
            fontSize: "3.2rem",
            borderRadius: 4,
            border: "1px solid transparent",
            outline: "none",
            textTransform: "uppercase",
            ...fontStyle.bold,
          }}
          focusStyle={{
            borderColor: colors.gray48,
            outline: "none",
          }}
          errorStyle={{
            border: `1px solid ${colors.salmon60}`,
          }}
          numInputs={INPUT_LENGTH}
          isDisabled={false}
          hasErrored={!!errorMessage}
          onChange={onChangeOTP}
          separator={<span />}
          isInputNum={false}
          shouldAutoFocus
          value={code}
          // isDisabled={!!isLoading}
          onEnter={submitCode}
        />
        {errorMessage && (
          <div className={classes.codeVerificationError}>{errorMessage}</div>
        )}
      </div>
      <UIButton
        onClick={submitCode}
        color="pink"
        containerStyle={{
          borderRadius: "4px",
        }}
      >
        {t("community:retention_enter_code_button")}
      </UIButton>
    </div>
  );
};

export default CodeVerification;
