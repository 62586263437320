import React from "react";
import classes from "./SuperAdmin.scss";
import { connect } from "react-redux";
import {
  onSwitchToUserFromSA,
  initOrganizationFilters,
  updateOrganizationFilters,
} from "../../modules/LoginModule";
import TopHeader from "./TopHeader";
import FilterHeader from "./FilterHeader";
import FeedList from "AppComponents/FeedList";
import { getCountString } from "Utils";
const parentStyle = {
  gridTemplateColumns: "50% 50%",
  gridColumnGap: "20px",
};

const columnList = [
  {
    label: "Organization name",
    id: "orgDetail",
    style: { justifySelf: "start" },
    type: "NAMEWITHIMAGE",
  },

  {
    label: "Admin name",
    id: "admin",
    type: "TEXTCOLUMN",
  },
];

class SuperAdmin extends React.PureComponent {
  componentWillUnmount = () => {
    this.props.initOrganizationFilters();
  };

  //   getOrgList = orgs => {
  //     const orgItems = orgs.map(org => (
  //       <div
  //         className={classes.orgItemContainer}
  //         key={org.org_id}
  //         onClick={() => {
  //           this.onSelectUser(org.user.user_id);
  //         }}
  //       >
  //         <div className={classes.orgItemName}>{org.org_name}</div>
  //         <div className={classes.orgAdminName}>{`${org.user.first_name} ${
  //           org.user.last_name
  //         }`}</div>
  //       </div>
  //     ));
  //     return <div className={classes.orgList}>{orgItems}</div>;
  //   };

  onItemClick = id => {
    this.props.onSwitchToUserFromSA({ userId: id });
  };

  onSearchChange = value => {
    let param = {};
    param["searchText"] = value;
    this.props.updateOrganizationFilters(param);
  };

  updateFilterHeaderContainerRef = ref => {
    this.filterHeader = ref;
  };

  bodyClick = () => {
    if (this.filterHeader) {
      this.filterHeader.closeSearchInput();
    }
  };

  getOrganizationList = ({ orgList }) => {
    const feedData = _.map(orgList, item => {
      const { user = {} } = item;

      return {
        id: user.user_id,
        orgDetail: {
          id: user.user_id,
          name: `${item.org_name}`,
          profileImage: null,
        },
        admin: `${user.first_name} ${user.last_name}`,
      };
    });

    return feedData;
  };

  render() {
    const { orgList, filters, updateOrganizationFilters } = this.props;
    const orgLength = _.get(orgList, "length", 0);

    const orgCountString = getCountString({
      count: orgLength,
      singularText: "Organization",
      pluralText: "Organizations",
    });

    return (
      <div className={classes.mainContainer} onClick={this.bodyClick}>
        <div className={classes.feedList}>
          <TopHeader title={"Pick a Campus"} subTitle={""} />

          <FilterHeader
            filters={filters}
            onSearchChange={this.onSearchChange}
            ref={this.updateFilterHeaderContainerRef}
            filterOptions={[]}
            updateFilters={updateOrganizationFilters}
          />

          {orgLength > 0 && (
            <div className={classes.countText}>{orgCountString}</div>
          )}

          <FeedList
            parentStyle={parentStyle}
            columnList={columnList}
            feedData={this.getOrganizationList({ orgList })}
            onItemClick={this.onItemClick}
            isItemClickEnable={true}
          />
        </div>
      </div>
    );
  }
}

const getSearchList = ({ list, searchText }) => {
  let searchList = [];
  if (!searchText) {
    searchList = list;
  } else {
    _.forEach(list, item => {
      if (_.includes(_.toLower(item.org_name), _.toLower(searchText))) {
        searchList.push(item);
      }
    });
  }
  return _.orderBy(searchList, [org => _.toLower(org.org_name)], ["asc"]);
};

const mapStateToProps = state => {
  const filters = state.login.organizationFilters;
  return {
    orgList: getSearchList({
      list: state.login.sa.orgList,
      searchText: filters.searchText,
    }),
    filters: filters,
  };
};
const mapActionCreators = {
  onSwitchToUserFromSA,
  initOrganizationFilters,
  updateOrganizationFilters,
};

export default connect(mapStateToProps, mapActionCreators)(SuperAdmin);
