import React from "react";
import classes from "./AddIdentification.scss";
import { checkIsPYPCoordinator } from "Login/modules/LoginModule";
import { InputTextField, UIButton } from "UIComponents";
import { isURL } from "Utils";
import DocumentSelector from "../components/DocumentSelector";

const DOCUMENT_INSTRUCTION = {
  headerText:
    "To verify your identity, please upload a photo of your school's identity card with your name and school name clearly visible.",
  texts: [],
};

class AddIdentification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.state = { showAttachmentError: false, isUploading: false };
  }

  onSaveClick = () => {
    const {
      updateCurrentScreen,
      data: { role, designation } = {},
      showErrorToast,
    } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    updateCurrentScreen({
      type: checkIsPYPCoordinator({ role, designation })
        ? "verifyOTP"
        : "addPYPDetails",
    });
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "createAccount" });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });
    const { data: { attachment = {} } = {} } = this.props;

    return errorCount;
  };

  updateLoadingState = state => {
    this.setState({ isUploading: state });
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      data: { weburl, attachment = {} } = {},
      renderErrorComponent,
      renderBackButtonComponent,
      progressOfUploads,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
      renderContactUsButton,
    } = this.props;
    const { isUploading } = this.state;
    const progress = _.get(progressOfUploads[attachment.id], "progress", 100);
    return (
      <div
        className={classes.container}
        style={{ ...containerStyle, paddingTop: 64 }}
      >
        {renderBackButtonComponent({ onClick: this.onBackClick })}
        <div style={headerStyle}> {`Provide Identification`} </div>
        <div style={subTextStyle}>
          {" "}
          {`For copyright reasons, Toddle can only be used by either authorised or candidate PYP schools. We just need to verify that you are a part of an IB PYP authorised/candidate school.`}{" "}
        </div>

        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <DocumentSelector
              progress={progress}
              instruction={DOCUMENT_INSTRUCTION}
              attachment={attachment}
              updateInputField={updateInputField}
              ref={ref => (this.inputRefs["attachment"] = ref)}
              renderErrorComponent={renderErrorComponent}
              renderContactUsButton={renderContactUsButton}
              updateLoadingState={this.updateLoadingState}
            />
          </div>
          <div className={classes.bottomContainer}>
            <div />
            <UIButton
              size="lg"
              type="filled"
              color="pink"
              onClick={this.onSaveClick}
              isDisabled={isUploading}
            >
              {"Continue"}
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AddIdentification;
