import PropTypes from "prop-types";
import React from "react";

const StudentPortfolioSvg = props => {
  return (
    <svg viewBox="0 0 20 20" height={props.height} width={props.width}>
      <g fill={props.fill}>
        <path
          d="M14.4,9.9c-0.3,0.3-0.5,0.7-0.5,1.2s0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5
		c0.7-0.7,0.7-1.7,0-2.4C16.2,9.3,15,9.3,14.4,9.9z M16,11.6c-0.2,0.2-0.7,0.2-0.9,0c-0.1-0.1-0.2-0.3-0.2-0.5s0.1-0.3,0.2-0.5
		c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2C16.3,10.9,16.3,11.3,16,11.6z"
        />
        <path
          d="M15.6,13.6L15.6,13.6c-2,0-3.6,1.6-3.6,3.5v2.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2.4
		c0-1.4,1.1-2.5,2.5-2.5h0.1c1.4,0,2.5,1.1,2.5,2.5v2.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2.4
		C19.1,15.2,17.6,13.6,15.6,13.6z"
        />
        <path d="M4.5,8C4.2,8,4,8.2,4,8.5C4,8.8,4.2,9,4.5,9h7C11.8,9,12,8.8,12,8.5C12,8.2,11.8,8,11.5,8H4.5z" />
        <path d="M11.5,11h-7C4.2,11,4,11.2,4,11.5C4,11.8,4.2,12,4.5,12h7c0.3,0,0.5-0.2,0.5-0.5C12,11.2,11.8,11,11.5,11z" />
        <path d="M9.5,14h-5C4.2,14,4,14.2,4,14.5C4,14.8,4.2,15,4.5,15h5c0.3,0,0.5-0.2,0.5-0.5C10,14.2,9.8,14,9.5,14z" />
        <path
          d="M9.5,19h-7C1.7,19,1,18.3,1,17.5V6.2h2.7c1.4,0,2.5-1.1,2.5-2.5V1h7.3C14.3,1,15,1.7,15,2.5V7
		c0,0.3,0.2,0.5,0.5,0.5S16,7.3,16,7V2.5C16,1.1,14.9,0,13.5,0H5.3L0,5.3v12.2C0,18.9,1.1,20,2.5,20h7c0.3,0,0.5-0.2,0.5-0.5
		C10,19.2,9.8,19,9.5,19z M5.2,1.5v2.2c0,0.8-0.7,1.5-1.5,1.5H1.5L5.2,1.5z"
        />
      </g>
    </svg>
  );
};

StudentPortfolioSvg.defaultProps = {
  fill: "white",
  height: 20,
  width: 20,
};

export default StudentPortfolioSvg;
