import { UserAgentApplication } from "msal";
import { MICROSOFT_CLIENT_ID, MICROSOFT_SCOPES } from "Constants";

class Microsoft {
  constructor() {
    if (!Microsoft.instance) {
      const msalConfig = {
        auth: {
          clientId: MICROSOFT_CLIENT_ID,
          redirectUri: `${window.location.origin}`,
        },
      };
      this.userAgentApplication = new UserAgentApplication(msalConfig);
      Microsoft.instance = this;
    }
    return Microsoft.instance;
  }

  loginPopup = ({ successCallback, failureCallback }) => {
    let loginRequest = {
      scopes: MICROSOFT_SCOPES,
      prompt: "select_account",
    };
    this.userAgentApplication.loginPopup(loginRequest).then(params => {
      this.getUserProfile({ successCallback, failureCallback });
    });
  };

  async getUserProfile({ successCallback, failureCallback }) {
    try {
      let request = {
        account: this.userAgentApplication.getAllAccounts()[0],
        scopes: MICROSOFT_SCOPES,
      };
      var response = await this.userAgentApplication.acquireTokenSilent(
        request
      );

      if (response) {
        if (successCallback) {
          successCallback(response.accessToken);
        }
      } else {
        if (failureCallback) {
          failureCallback();
        }
      }
    } catch (err) {
      if (failureCallback) {
        failureCallback();
      }
      console.error(err);
    }
  }

  logout = () => {
    this.userAgentApplication.logout();
  };
}

const instance = new Microsoft();
Object.freeze(instance);

export default instance;
