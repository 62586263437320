import PropTypes from "prop-types";
import React from "react";

const AddIcon = props => {
  return (
    <svg
      viewBox="0 0 12 12"
      fill={props.fill && props.fill}
      width={props.width}
      height={props.height}
    >
      <path d="M11.5,5.495127h-5V0.5C6.5,0.224,6.275999,0,6,0S5.5,0.224,5.5,0.5v4.995127h-5C0.224001,5.495127,0,5.724,0,6s0.224001,0.495128,0.5,0.495128h5V11.5C5.5,11.775999,5.724001,12,6,12s0.5-0.224001,0.5-0.5V6.495127h5C11.776001,6.495127,12,6.276,12,6S11.776001,5.495127,11.5,5.495127z" />
    </svg>
  );
};

AddIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

AddIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default AddIcon;
