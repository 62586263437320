import React from "react";
import { compose } from "redux";
import {
  Loading,
  Toastr,
  I18nHOC,
  NetworkStatus,
  FullScreenLoader,
} from "UIComponents";
import "styles/core.scss";
import classes from "./LoginView.scss";
import LoginPage from "./LoginPage";
import AddChildPage from "./AddChildPage";
import ChildrenPage from "./ChildrenPage";
import ClassInviteChildSelection from "./FamilyInviteChildSelection";
import SuperAdmin from "./SuperAdmin";
import OrganizationList from "./OrganizationList";
import { connect } from "react-redux";
import { localeParamsHelper, isTouchable, isDesktop, isIpad } from "Utils";
import {
  clearToast,
  checkAuthentication,
  starChatWidgetForVisitors,
  isCommunityRoute,
  isPublicPlatformRoute,
} from "Login/modules/LoginModule";
import {
  setIsOnline,
  setIsPageNotFound,
  handleGlobalSearch,
} from "modules/Services";
import { AttachmentLoaderMenu } from "Attachment";
import { handleQueryParameters, goToBack } from "modules/NavigationModule";
import { GlobalSearchBar, MobileDevice } from "AppComponents";
import {
  UnderMaintanance,
  SigninBlockerScreen,
  PageNotFound,
} from "AppComponents";
import { PictureInPicture } from "UIComponents";

const isMAintenanceMode = false;

const LoginLoadingStyle = {
  backgroundColor: `rgba(0,0,0, 0.5)`,
};

class LoginView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toastrRef = null;
  }

  UNSAFE_componentWillMount = () => {
    const {
      checkAuthentication,
      handleQueryParameters,

      location: { query = {} } = {},
    } = this.props;

    const { user_id } = query;
    let params = {};

    if (user_id) params = { id: user_id };

    checkAuthentication(params);

    handleQueryParameters({ query });

    setTimeout(() => {
      this.setState({ run: true });
    }, 10000);
    starChatWidgetForVisitors();
  };

  componentDidMount() {
    const { handleGlobalSearch } = this.props;
    document.addEventListener("keydown", handleGlobalSearch, false);
  }

  componentWillUnmount() {
    const { handleGlobalSearch } = this.props;
    document.removeEventListener("keydown", handleGlobalSearch, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    const { toast = {} } = nextProps || {};
    const {
      showToast,
      msg,
      notString,
      fill,
      timeOut,
      locale_params,
      onClick,
      type,
      closeButton,
      actions,
    } = toast || {};
    if (showToast && msg.length != 0) {
      let message;
      if (toast.hasOwnProperty("notString")) {
        message = notString ? msg : t(msg);
      } else {
        message = t(msg);
      }
      if (toast.hasOwnProperty("locale_params")) {
        const message_with_params = localeParamsHelper({
          params: locale_params,
          t,
        });
        message = t(msg, message_with_params);
      }

      const { showToast } = this.toastrRef || {};
      if (showToast) {
        showToast({
          message,
          type,
          fill,
          timeOut: timeOut || 2000,
          onClick,
          closeButton,
          actions,
        });
      }
      this.props.clearToast();
    }
  }

  render() {
    let compToShow = null;
    const {
      isLoading,
      userLoggedIn,
      isSALoggedIn,
      progressOfUploads,
      goToBack,
      location,
      currentRoute,
      isOnline,
      setIsOnline,
      isPageNotFound,
      setIsPageNotFound,
      toast,
      children,
      showPip,
      userType,
      isChildPresent,
      showOrganizationSwitcher,
      invitedFamilyData,
      showSearchBar,
      attachmentLoaderMenuStyles,
    } = this.props;

    const isPublicAccess = isPublicPlatformRoute();
    const showLoginPage =
      !userLoggedIn && !isCommunityRoute() && !isPublicAccess;
    // showAddChildPage will only be visible to parent if no child Present
    const showAddChildPage =
      !showLoginPage &&
      userType === "parent" &&
      !isChildPresent &&
      _.isEmpty(invitedFamilyData) &&
      !isPublicAccess;

    const showAddChildFamilyInvitePage =
      !showLoginPage &&
      userType === "parent" &&
      !_.isEmpty(invitedFamilyData) &&
      _.get(invitedFamilyData, "type") === "family_class_invite";

    const areChildrenShown =
      !showLoginPage &&
      !isLoading &&
      !showAddChildPage &&
      !showAddChildFamilyInvitePage &&
      _.isEmpty(invitedFamilyData);

    const showFullScreenLoader =
      !_.isEmpty(invitedFamilyData) &&
      !showLoginPage &&
      !showAddChildFamilyInvitePage;
    const isSAPageShown = isSALoggedIn && !isLoading;
    const isCommunityWithoutLogin = !userLoggedIn && isCommunityRoute();
    const showSigninBlockerScreen =
      isCommunityRoute() && ((isTouchable() && !isDesktop()) || isIpad());

    // if (isMAintenanceMode) {
    //   compToShow = <UnderMaintanance />;
    // } else {

    if (showLoginPage)
      compToShow = (
        <React.Fragment>
          <LoginPage location={location} />
        </React.Fragment>
      );
    if (showAddChildPage) {
      compToShow = (
        <React.Fragment>
          <AddChildPage />
        </React.Fragment>
      );
    }
    if (showAddChildFamilyInvitePage) {
      compToShow = (
        <React.Fragment>
          <ClassInviteChildSelection />
        </React.Fragment>
      );
    }

    if (areChildrenShown)
      compToShow = (
        <React.Fragment>
          {isCommunityWithoutLogin || isPublicAccess ? (
            children
          ) : showSigninBlockerScreen ? (
            <SigninBlockerScreen />
          ) : (
            <>
              <ChildrenPage {...this.props} />
              {showPip ? <PictureInPicture /> : null}
            </>
          )}
          {/* <MobileDevice goToBack={goToBack} currentRoute={currentRoute} /> */}
        </React.Fragment>
      );
    if (isSAPageShown) compToShow = <SuperAdmin />;
    // }
    if (isPageNotFound) {
      compToShow = <PageNotFound />;
    }

    /**Organization switcher for users which are part
     * of more than one organization.
     * (Not to be confused with Super Admin Org selection screen)
     */
    if (showOrganizationSwitcher) {
      compToShow = <OrganizationList />;
    }

    return (
      <div className={classes.wrapper}>
        <NetworkStatus isOnline={isOnline} setIsOnline={setIsOnline} />

        {showSearchBar && <GlobalSearchBar />}

        <Toastr
          ref={ref => {
            this.toastrRef = ref;
          }}
          {...toast}
        />

        {!!userLoggedIn && (
          <AttachmentLoaderMenu
            progressOfUploads={progressOfUploads}
            attachmentLoaderMenuStyles={attachmentLoaderMenuStyles}
          />
        )}
        <div className={classes.mainContainer}>
          {compToShow}
          {!!isLoading && (
            <div
              className={classes.fullScreenLoaderContainer}
              style={userLoggedIn ? {} : LoginLoadingStyle}
            >
              <Loading />
            </div>
          )}
        </div>
        {showFullScreenLoader && <FullScreenLoader />}
      </div>
    );
  }
}

const mapActionCreators = {
  checkAuthentication,
  clearToast,
  goToBack,
  handleQueryParameters,
  setIsOnline,
  setIsPageNotFound,
  handleGlobalSearch,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isOnline: state.app_services.isOnline,
    isPageNotFound: state.app_services.isPageNotFound,
    isSALoggedIn: state.login.sa.loggedIn,
    userLoggedIn: state.login.userLoggedIn,
    userType: state.login.userInfo.user_type,
    isChildPresent: state.login.isChildPresent,
    isLoading: state.login.isLoading,
    currentRoute: _.get(_.last(_.get(ownProps, "routes", [])), "path", ""),
    toast: _.get(state, "login.toast", {}),
    progressOfUploads: state.app_services.progressOfUploads,
    showPip: _.get(state, "app_services.pictureInPictureConfig.showPip", false),
    showOrganizationSwitcher: _.get(
      state,
      "login.organizationSwitcherDetails.isVisible",
      false
    ),
    invitedFamilyData: state.login.invitedFamilyData,
    showSearchBar: _.get(
      state,
      "app_services.globalSearchBar.showSearchBar",
      false
    ),
    attachmentLoaderMenuStyles: state.app_services.attachmentLoaderMenuStyles,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators)
)(LoginView);
