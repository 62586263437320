import { Avatar, IconButton } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";
import React from "react";
import { UIModal, I18nHOC } from "UIComponents";
import classes from "./PostSharedWithModal.scss";
import { AVATAR_COLORS } from "Constants/colorConstants";
import { getSharedWithStringV2 } from "Post/utils";
import classNames from "classnames";
import { compose } from "react-apollo";

const TaggedStudent = props => {
  const { student } = props;
  return (
    <div className={classes.taggedStudentContainer}>
      <Avatar
        size={"small"}
        iconType={"individual"}
        color={AVATAR_COLORS[student?.id % AVATAR_COLORS.length]}
        src={student.profileImage}
        shade={"light"}
      />
      <div
        className={classes.taggedStudentNameText}
      >{`${student?.firstName} ${student?.lastName}`}</div>
    </div>
  );
};

const PostSharedWithModal = props => {
  const {
    isModalOpen,
    closeSharingModal,
    post,
    taggedStudents,
    isPrivate,
    isHiddenFromStudents,
    t,
  } = props;

  const hasTaggedStudents = _.get(taggedStudents, "length", 0) > 0;
  const SharedWith = getSharedWithStringV2({ isPrivate, isHiddenFromStudents });
  const modalContainerClass = classNames({
    [classes.privateModalContainer]:
      isPrivate && isHiddenFromStudents && !hasTaggedStudents,
    [classes.modalContainer]: true,
  });

  const getTagMessage = type => {
    switch (type) {
      case "everyone":
        return t("journal:tagged_students_and_families");
      case "student":
        return t("journal:tagged_students");
      case "family":
        return t("journal:tagged_families_only");
      case "private":
        return t("journal:private");
      default:
        return "";
    }
  };

  return (
    <UIModal isOpen={isModalOpen} modalContent={modalContainerClass}>
      <div className={classes.modalBodyContainer}>
        <div className={classes.modalHeaderContainer}>
          <div className={classes.shareWithTitle}>{t("common:audience")}</div>
          <IconButton
            icon={<CloseOutlined />}
            onClick={closeSharingModal}
            variant="plain"
          />
        </div>

        <div className={classes.taggingTypeContainer}>
          <div className={classes.tagMessage}>{getTagMessage(SharedWith)}</div>
        </div>

        {isPrivate && isHiddenFromStudents && (
          <div className={classes.privatePostMessage}>
            {hasTaggedStudents
              ? t("journal:post_visible_to_teachers")
              : t("journal:post_visible_to_teachers_only")}
          </div>
        )}
        {hasTaggedStudents && (
          <div className={classes.taggedListContainer}>
            {_.map(taggedStudents, student => (
              <TaggedStudent key={student.id} student={student} />
            ))}
          </div>
        )}
      </div>
    </UIModal>
  );
};

export default compose(I18nHOC({ resource: ["journal"] }))(PostSharedWithModal);
