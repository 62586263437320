import React, { useState } from "react";
import { I18nHOC, UILabel, SelectDropdown } from "UIComponents";
import { UnitSelection } from "AppComponents";
import classes from "./UnitFromGradeSelection.scss";
import { compose } from "react-apollo";
import { colors, fontStyle } from "Constants";

const styles = {
  notesTextStyle: {
    fontSize: "1.2rem",
    color: colors.gray48,
    ...fontStyle.regular,
  },
  classTextStyle: {
    ...fontStyle.demiBold,
  },
  unitLabelText: {
    color: colors.blue29,
    fontSize: "1.6rem",
    ...fontStyle.bold,
  },
  unitsCountText: {
    color: colors.blue29,
    fontSize: "1.6rem",
    ...fontStyle.regular,
  },
};

const UnitFromGradeSelection = ({
  courseList,
  selectedItems,
  onCourseSelect,
  unitSelectionStepStyles,
  updateSelectedItems,
  selectedCourse,
  t,
}) => {
  const [selectedCourseId, setSelectedCourseId] = useState(selectedCourse);
  const [searchText, updateSearchText] = useState("");
  const onClassSelect = ({ selectedCourseId }) => {
    const selectedCourse = _.find(
      courseList,
      course => course.courseId === selectedCourseId
    );
    onCourseSelect({ course: selectedCourse });
    setSelectedCourseId(selectedCourseId);
  };
  const handleUnitSearch = value => {
    updateSearchText(value);
  };
  return (
    <div className={classes.mainContainer}>
      <UILabel
        label={`${t("calendar:choose_class")}`}
        labelStyle={styles.classTextStyle}
      ></UILabel>
      <div className={classes.courseDropDownContainer}>
        <SelectDropdown
          name={"selectedCourseId"}
          value={selectedCourseId}
          placeholder={t("calendar:search_for_a_class")}
          updateInputField={onClassSelect}
          options={_.map(courseList, course => ({
            label: course.label,
            value: course.courseId,
          }))}
          isCheckList={false}
          showAllOption={false}
          parentContainerStyle={{
            width: "664px",
          }}
        />
        <UILabel
          label={`${t("calendar:note_units_that_belong")}`}
          labelStyle={styles.notesTextStyle}
        ></UILabel>
      </div>
      {selectedCourseId ? (
        <UnitSelection
          updateSelectedItems={updateSelectedItems}
          styles={unitSelectionStepStyles}
          cardDimensions={{ width: 320, height: 280 }}
          selectedItems={selectedItems}
          courseId={selectedCourseId}
          showEmptyState={true}
          isSearchEnabled={true}
          searchText={searchText}
          selectedCourseId={selectedCourseId}
          handleUnitSearch={handleUnitSearch}
        />
      ) : null}
    </div>
  );
};

export default compose(I18nHOC({ resource: ["common", "calendar"] }))(
  UnitFromGradeSelection
);
