import React from "react";

import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, withPrivateScreen, UIModal } from "UIComponents";
import { updateBasicUserDetails, goToRelativeRoute } from "modules/Services";
import { UnderMaintanance } from "AppComponents";
import { InviteToCommunity, RetentionModal } from "AppComponents/Community";
import { USER_TYPE_ENTITY_MAPPING } from "Constants";
import { updateNavigationObject } from "modules/NavigationModule";
import classes from "./ChildrenPage.scss";
import {
  setUserInfo,
  getUserInfo,
  setBifurcationModalStatus,
  setIsLastChildRemoved,
} from "Login/modules/LoginModule";
import { updateUserCourses } from "Course/modules/CourseModule";
import {
  getPlatformUserDetailsQuery,
  getOrganizationDetailsQuery,
  getGlobalConstantsQuery,
} from "modules/CommonQuery";
import {
  getOrganizationDetailsFromCache,
  getPlatformUserDetailsFromCache,
  getUserBasicDetailsFromCache,
} from "modules/CommonGraphqlHelpers";
import moment from "moment";
import { getPermissionsQuery } from "QueryMutationFragment/CommonQueryMutationFragment/queries";
import ACLStore from "lib/aclStore";
import { Bifurcation } from "AppComponents";
import {
  UserCurriculumProgramsContext,
  getCurriculumProgramFreeStatus,
} from "Platform/modules/PlatformModule";
import * as EventTracker from "lib/eventTracker";
import ClassSelectionModal from "Courses/components/components/ClassSelectionModal";
import EventModal from "MyCalendar/components/EventModal";
import ProductUpdatesModal from "ProductUpdates/components/ProductUpdatesModal";
import { getUserAcadYearConstantsMemoize } from "./Utils";
import { CURRICULUM_TYPE_PYP } from "Constants/stringConstants";

const TEACHER_TABS = ["teacher", "admin"];

class ChildrenPage extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const { staffData = {}, updateBasicUserDetails, userLoggedIn } = this.props;
    const { firstLoggedInAt } = staffData;

    this.updateUserDetails({ shouldSaveInLocalStorage: true });
    if (userLoggedIn && !firstLoggedInAt) {
      EventTracker.recordEvent({
        eventName: "First Login",
      });
      setTimeout(() => {
        EventTracker.recordTrait({
          data: {
            first_logged_in_at: moment().toISOString(),
          },
        });
        updateBasicUserDetails({ firstLoggedInAt: moment().toISOString() });
      }, 1000);
    }
  };

  updateUserDetails = ({ shouldSaveInLocalStorage = false } = {}) => {
    const {
      setUserInfo,
      staffData,
      i18n,
      organizationData,
      settings,
      projects,
      userLoggedIn,
    } = this.props;

    const { userCurriculumPrograms } = this.getUserDetails();

    if (!userLoggedIn) {
      return;
    }

    const staffSettings = _.get(staffData, "settings", []);
    const locale = _.get(
      _.find(staffSettings, { name: "locale" }),
      "value",
      "en"
    );

    //Do not change this line
    i18n.changeLanguage(locale);

    setUserInfo({
      staffDetails: staffData,
      organization: {
        ...organizationData,
        ...projects,
      },
      settings,
      portalType: "PLANNER",
      shouldSaveInLocalStorage,
      userCurriculumPrograms,
    });
  };

  componentDidUpdate = prevProps => {
    if (
      (!_.includes(TEACHER_TABS, prevProps.activeTab) ||
        !_.isEqual(prevProps.projects, this.props.projects)) &&
      _.includes(TEACHER_TABS, this.props.activeTab)
    ) {
      this.updateUserDetails();
    }

    /** __FamilyPortal__
     *  1. "isLastChildRemoved" will be true when last remaining child is removed from settings page in Family Portal (Refer: ParentSettings.js)
     *  2. make "isLastChildRemoved" false in componentDidUpdate
     */
    const { isLastChildRemoved, setIsLastChildRemoved } = this.props;
    if (isLastChildRemoved) {
      setIsLastChildRemoved(false);
    }

    const { goToRelativeRoute } = this.props;
    if (!this.props.children) {
      goToRelativeRoute({ route: "platform", type: "replace" });
    }
  };

  classSelectionDoneListener = async ({ addedCourses, removedCourses }) => {
    const {
      updateUserCourses,
      updateBasicUserDetails,
      setBifurcationModalStatus,
      portalType,
    } = this.props;

    await updateUserCourses({ addedCourses, removedCourses, portalType });
    await updateBasicUserDetails({
      showClassSelectionScreen: false,
      portalType,
    });
    setBifurcationModalStatus(true);
  };

  onCloseCalendarEventModal = () => {
    const { updateNavigationObject } = this.props;
    updateNavigationObject({
      calendarEventModal: null,
    });
  };

  getUserDetails = () => {
    const {
      organizationAcademicYears,
      academicYearCurriculumProgramsMapping,
      selectedAcademicYear,
    } = this.props;

    /*
    // get academic year id from local storage (on first load, it won't be there)
    const storageAcademicYearId = manageAcademicYear.getFromLocalStorage();
    const selectedAcademicYearId = storageAcademicYearId
      ? storageAcademicYearId
      : currentAcademicYearId;
    */

    // find current academic year from organizationAcademicYears
    const currentAcademicYear = _.find(organizationAcademicYears, {
      isCurrentAcademicYear: true,
    });
    const currentAcademicYearId = _.get(currentAcademicYear, "id");
    const selectedAcademicYearId = _.get(selectedAcademicYear, "id", null);

    // get userCurriculumPrograms based on current selected academic year
    const userCurriculumPrograms = _.get(
      academicYearCurriculumProgramsMapping,
      [
        selectedAcademicYearId ? selectedAcademicYearId : currentAcademicYearId,
        "curriculumPrograms",
      ],
      []
    );

    return {
      userCurriculumPrograms,
      // selectedAcademicYearId,
      currentAcademicYearId,
    };
  };

  render() {
    const {
      isPYPFree,
      staffData,
      inviteToCommunityModal,
      retentionModalStatus,
      bifurcationModalStatus,
      showClassSelectionScreen,
      calendarEventModal,
      productUpdateModalId,
    } = this.props;

    const { firstName = "", showBifurcationScreen = false } = staffData || {};

    //BifurcationModal is shown only for free PYP curriculum program
    const showBifurcationModal =
      bifurcationModalStatus && showBifurcationScreen && isPYPFree;

    const { userCurriculumPrograms } = this.getUserDetails();

    return (
      <React.Fragment>
        <UserCurriculumProgramsContext.Provider value={userCurriculumPrograms}>
          {this.props.children &&
            React.cloneElement(this.props.children, { userCurriculumPrograms })}
        </UserCurriculumProgramsContext.Provider>
        {inviteToCommunityModal && <InviteToCommunity />}
        {productUpdateModalId && (
          <ProductUpdatesModal
            userCurriculumPrograms={userCurriculumPrograms}
          />
        )}
        {retentionModalStatus && <RetentionModal />}
        {showBifurcationModal && <Bifurcation firstName={firstName} />}
        {/**ClassSelectionModal is shown only for free PYP Curriculum program */}
        {showClassSelectionScreen && isPYPFree && (
          <UIModal
            isFullScreen={true}
            isOpen={true}
            overlayContainer={classes.overlayContainer}
          >
            <ClassSelectionModal
              onClickDoneCourses={this.classSelectionDoneListener}
            />
          </UIModal>
        )}
        {!_.isEmpty(calendarEventModal) && (
          <UIModal
            isOpen={true}
            modalContent={classes.modalContentCalendarEvent}
            overlayContainer={classes.modalOverlayCalendarEvent}
            onRequestClose={this.onCloseCalendarEventModal}
          >
            <EventModal
              eventId={_.get(calendarEventModal, "id", null)}
              onCloseEventModal={this.onCloseCalendarEventModal}
              accessedViaDailyView={true}
            />
          </UIModal>
        )}
      </React.Fragment>
    );
  }
}

const mapActionCreators = {
  setUserInfo,
  updateBasicUserDetails,
  updateUserCourses,
  setBifurcationModalStatus,
  updateNavigationObject,
  setIsLastChildRemoved,
  goToRelativeRoute,
};

const mapStateToProps = (state, ownProps) => {
  const portalType = "PLANNER";
  const userInfo = getUserInfo({ portalType });
  const childID = _.get(userInfo, "childID", "");
  const userDetails = getUserBasicDetailsFromCache({
    id: userInfo.id,
    type: userInfo.userEntityType,
  });
  const user_type = userInfo.user_type;
  const inviteToCommunityModal = _.get(state, "login.inviteToCommunityModal");
  const productUpdateModalId = _.get(state, "login.productUpdateModalId");
  const retentionModalStatus = _.get(state, "login.retentionModalStatus");
  const bifurcationModalStatus = _.get(state, "login.bifurcationModalStatus");
  const showClassSelectionScreen = _.get(
    userDetails,
    "showClassSelectionScreen",
    false
  );

  const activeCurriculumProgramType = _.get(
    state,
    "platform.currentCurriculumProgramType",
    CURRICULUM_TYPE_PYP
  );

  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });
  const isPYPFree =
    isCurriculumProgramFree &&
    activeCurriculumProgramType == CURRICULUM_TYPE_PYP;
  return {
    isData: true,
    isLoading: false,
    userId: userInfo.id,
    parentToken: _.get(userInfo, "parentToken", ""),
    isCurriculumProgramFree,
    user_type,
    userEntityType: _.get(
      USER_TYPE_ENTITY_MAPPING[user_type],
      "entityType",
      ""
    ),
    isPrivate: false,
    portalType,
    activeTab: state.login.activeTab,
    permissionGroups: state.teacher.permissionGroups[user_type],
    selectedAcademicYear: _.get(state, "platform.academicYearSelected"),
    inviteToCommunityModal,
    retentionModalStatus,
    bifurcationModalStatus,
    showClassSelectionScreen,
    calendarEventModal: state.navigation.navigationObject.calendarEventModal,
    childID,
    productUpdateModalId,
    isLastChildRemoved: _.get(state, "login.isLastChildRemoved", false),
    isPYPFree,
  };
};

const manageUserData = ({ childData, userData, parentToken }) => {
  const firstName = _.get(childData, "firstName", "");
  const lastName = _.get(childData, "lastName", "");

  userData.parentToken = parentToken;
  userData.email = _.get(userData, "Email", "");
  userData.jwt = _.get(childData, "jwt", "");
  userData.childID = _.get(childData, "id", "");
  userData.childProfileImage = _.get(childData, "profileImage", "");
  userData.childFirstName = firstName;
  userData.childName = `${firstName} ${lastName}`;
};

const getPlatformUserData = ({ staffDataFromCache, childID, parentToken }) => {
  const userData = { ...(staffDataFromCache || {}) };

  let organizationData, organizationId, allCourses;

  if (_.get(userData, "type", "") === "parent") {
    const children = _.get(userData, "children", []);

    // childID is from local storage
    const activeChildData = _.find(children, { id: childID });

    // if no active child data then, get first element of array
    const childData = activeChildData || _.head(children);

    // "userData" is passed by reference
    manageUserData({ childData, userData, parentToken });

    allCourses = _.get(childData, "allCourses", []);
    organizationData = _.get(childData, "organization", {});
    organizationId = _.get(organizationData, `id`, "");
  } else {
    allCourses = _.get(userData, "allCourses", []);
    organizationData = _.get(userData, "organization", {});
    organizationId = _.get(organizationData, `id`, "");
  }

  const orgAcademicYears = _.get(organizationData, "academicYears", []);
  const organizationCurriculumPrograms = _.get(
    organizationData,
    "curriculumPrograms",
    []
  );
  const {
    academicYearCurriculumProgramsMapping,
  } = getUserAcadYearConstantsMemoize({
    allCourses,
    orgAcademicYears,
    organizationCurriculumPrograms,
  });

  return {
    staffData: userData,
    organizationData,
    organizationId,
    academicYearCurriculumProgramsMapping,
  };
};

export default compose(
  I18nHOC({ resource: ["common", "blockScreen", "toastMsgs"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getPermissionsQuery, {
    name: "getPermissions",
    skip: ({ userId, userLoggedIn, user_type }) =>
      !userId || !userLoggedIn || user_type === "parent",
    options: ({ userId, permissionGroups, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { userId: userId, groups: permissionGroups, portalType },
    }),
    props: ({
      getPermissions,

      ownProps: { isData, isLoading },
    }) => {
      if (
        getPermissions["networkStatus"] == 1 ||
        getPermissions["networkStatus"] == 2
      ) {
      } else {
        ACLStore.update(_.get(getPermissions, "platform.permissions", {}));
      }
      return {
        getPermissions,
        isData: !!getPermissions.platform && isData,
        isLoading:
          getPermissions["networkStatus"] == 1 ||
          getPermissions["networkStatus"] == 2 ||
          getPermissions["networkStatus"] == 4 ||
          isLoading,
      };
    },
  }),
  graphql(getPlatformUserDetailsQuery, {
    name: "getPlatformUserDetails",
    skip: ({ userLoggedIn }) => !userLoggedIn,
    options: ({ userId, portalType, userEntityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: userEntityType,
        portalType,
      },
    }),
    props({
      getPlatformUserDetails,
      ownProps: {
        userId,
        isData,
        isLoading,
        userEntityType,
        childID,
        parentToken,
        isLastChildRemoved,
      },
    }) {
      const staffDataFromCache = getPlatformUserDetailsFromCache({
        id: userId,
        type: userEntityType,
      });

      const {
        academicYearCurriculumProgramsMapping,
        organizationData,
        organizationId,
        staffData,
      } = getPlatformUserData({ staffDataFromCache, childID, parentToken });

      // default
      let isPlatformData = !_.isEmpty(staffData) && isData;

      /** __Family Portal__
       * 1. "isLastChildRemoved" will be true when last remaining child is removed from settings page in Family Portal (Refer: ParentSettings.js)
       * 2. Without this condition, cached staffData was getting set in Local Storage which didn't included jwt
       *    .... without jwt the succeding network request was failing and throwing "Un-authenticated error"
       * 3. As a result, Don't return data unless jwt is arrived from network request
       */
      if (isLastChildRemoved) {
        isPlatformData =
          !_.isEmpty(staffData) &&
          isData &&
          !_.isEmpty(_.get(staffData, "jwt", ""));
      }

      return {
        getPlatformUserDetails,
        organizationId,
        academicYearCurriculumProgramsMapping,
        organizationData,
        staffData,
        isData: isPlatformData,
        isLoading:
          getPlatformUserDetails["networkStatus"] == 1 ||
          getPlatformUserDetails["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getGlobalConstantsQuery, {
    name: "getGlobalConstants",
    skip: ({ userLoggedIn }) => !userLoggedIn,
    options: ({ portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        portalType,
      },
    }),
    props({ getGlobalConstants, ownProps: { isData, isLoading } }) {
      return {
        isData: _.get(getGlobalConstants, "globalConstants", {}) && isData,
        isLoading:
          getGlobalConstants["networkStatus"] == 1 ||
          getGlobalConstants["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getOrganizationDetailsQuery, {
    name: "getOrganizationDetails",
    skip: ({ organizationId, userLoggedIn, user_type, childID }) =>
      !organizationId || !userLoggedIn || (user_type === "parent" && !childID),
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: organizationId,
        portalType: _.get(getUserInfo(), "portalType", "PLANNER"),
      },
    }),
    props: ({
      getOrganizationDetails,
      ownProps: { isData, isLoading, organizationId },
    }) => {
      const organizationDetails = getOrganizationDetailsFromCache(
        organizationId
      );
      const settings = _.get(organizationDetails, "settings", []);
      const personalProject = _.get(
        organizationDetails,
        "personalProject",
        null
      );

      const communityProject = _.get(
        organizationDetails,
        "communityProject",
        null
      );

      const serviceAsAction = _.get(
        organizationDetails,
        "serviceAsAction",
        null
      );

      const projects = {
        personalProject,
        communityProject,
        serviceAsAction,
      };

      return {
        getOrganizationDetails,
        isData: !_.isEmpty(organizationDetails) && isData,
        settings,
        projects,
        organizationAcademicYears: _.get(
          organizationDetails,
          "academicYears",
          []
        ),
        isLoading:
          getOrganizationDetails["networkStatus"] == 1 ||
          getOrganizationDetails["networkStatus"] == 2 ||
          getOrganizationDetails["networkStatus"] == 4 ||
          isLoading,
      };
    },
  }),
  withPrivateScreen(),
  withLoader
)(ChildrenPage);
