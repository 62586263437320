import PropTypes from "prop-types";
import React from "react";

const LEFilledIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      fill={props.fill && props.fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 28H8C5.8 28 4 26.2 4 24V8C4 5.8 5.8 4 8 4H24C26.2 4 28 5.8 28 8V24C28 26.2 26.2 28 24 28ZM8.80005 12.1C8.80005 11.3 9.40005 11.1 9.70005 11.1C10 11.1 10.6 11.2 10.6 12.1V19.3H14C14.7 19.3 14.8 19.8 14.8 20.1C14.8 20.4 14.7 20.9 14 20.9H9.80005C9.10005 20.9 8.80005 20.6 8.80005 19.9V12.1ZM18.2 19.3V16.6V16.5H21.9C22.6 16.5 22.7 16 22.7 15.8C22.7 15.6 22.6 15.1 21.9 15.1H18.2V12.7H22.1C22.8 12.7 22.9 12.2 22.9 12C22.9 11.8 22.8 11.3 22.1 11.3H17.5C16.8 11.3 16.5 11.6 16.5 12.3V19.9C16.5 20.6 16.8 20.9 17.5 20.9H22.4C23.1 20.9 23.2 20.4 23.2 20.1C23.2 19.8 23.1 19.3 22.4 19.3H18.2Z"
      />
    </svg>
  );
};

LEFilledIcon.defaultProps = {
  height: 20,
  width: 20,
};

LEFilledIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LEFilledIcon;
