import React, { useState } from "react";
import { FullScreenLoader, LinkWithTooltip, UIButton } from "UIComponents";
import classes from "./TagCell.scss";
import { colors } from "@toddle-design/theme";
import { useI18n } from "Hooks";
import AddItemsModal from "../AddItemsModal/AddItemsModal";
import { createMultiLevelTags } from "NodeEditor/Modules/NodeEditorModule";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import classNames from "classnames";
import ACLStore from "lib/aclStore";

const plusBtnStyle = { color: colors.gray31, cursor: "pointer" };
const addTagBtnStyle = {
  width: "36px",
  height: "20px",
  borderRadius: "12px",
  padding: "4px 8px",
  minWidth: "36px",
  marginLeft: "auto",
};
const addTagLabelStyle = { fontSize: "1rem" };

const TagCell = props => {
  const {
    tags,
    organizationTags,
    mode,
    createMultiLevelTags,
    inputForMultiLevelTagsQuery,
    organizationTagsObjectMap,
    onAddTags,
  } = props;
  const [isAddTagsModalOpen, setIsAddTagsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useI18n(["common", "scopeAndSequence"]);
  const showTagImages = ACLStore.can("FeatureFlag:ShowTagsBenchmarkView");

  //exisiting tag ids
  const tagIds = _.map(tags, tag => {
    return tag.id;
  });

  //selected tags contains the final result of selected tags from add tags modal
  const [selectedTags, setSelectedTags] = useState([]);

  //list of existing tag labels. Labels and ids are got from separate lists to manage edit state
  const tagLabelAndIcons = _.map(tagIds, tagId => {
    const label = _.get(organizationTagsObjectMap[tagId], "label", "");
    const iconURL = _.get(organizationTagsObjectMap[tagId], "icon.url", "");
    return { label, iconURL };
  });
  //max count contains maximum number of tags to display. after that we show more with tooltip
  const maxCount = 2;

  const tooltipComp = (
    <div>
      {_.map(_.slice(tagLabelAndIcons, maxCount, Infinity), ({ label }, i) => {
        return (
          <React.Fragment key={i}>
            {label}
            <br />
          </React.Fragment>
        );
      })}
    </div>
  );

  const toggleAddTagsModal = () => {
    setIsAddTagsModalOpen(prevState => !prevState);
    setSelectedTags([]);
  };

  //this function creates a new tag when user clicks on plus icon in add tags modal
  const onCreateNewTag = async value => {
    const input = [{ label: value }];
    setIsLoading(true);
    const newTag = await createMultiLevelTags({
      input,
      inputForMultiLevelTagsQuery,
    });
    setIsLoading(false);
    if (newTag) {
      setSelectedTags(prevTags => [...prevTags, newTag]);
    }
  };

  const handleAddTags = () => {
    onAddTags({ itemsList: selectedTags });
    toggleAddTagsModal();
  };

  //localized texts
  const texts = {
    addText: t("common:add"),
    buttonText1: t("common:cancel"),
    buttonText2: t("common:add"),
    modalTitle: t("common:add_with_label", { label: t("common:tags") }),
    searchPlaceholderText: t("scopeAndSequence:enter_a_item", {
      label: t("scopeAndSequence:tag_name"),
    }),
    noItemsAddedText: t("scopeAndSequence:no_item_added", {
      type: t("common:tags"),
    }),
    noItemsText: t("scopeAndSequence:no_item_created", {
      type: t("common:tags"),
    }),
    noItemsSubText: t("scopeAndSequence:go_ahead_tags_desc", {
      msg: t("scopeAndSequence:create_first_tag"),
    }),
    tag: t("common:tag"),
  };

  const getLabelComp = ({ label, iconURL, index }) => {
    const showIcon = iconURL && showTagImages;
    const tagContainerClasses = classNames(classes.tag, classes.tagLabel, {
      [classes.paddingLeft]: showIcon,
    });
    return (
      <LinkWithTooltip
        tooltip={label}
        linkStyles={plusBtnStyle}
        placement={"right"}
      >
        <div key={index} className={tagContainerClasses}>
          {showIcon && <img className={classes.tagImage} src={iconURL} />}
          <span className={classes.label}>{label}</span>
        </div>
      </LinkWithTooltip>
    );
  };

  const moreTagsCount = `+${_.size(tagLabelAndIcons) - maxCount}`;
  return (
    <div className={classes.tagsContainer}>
      {_.isEmpty(tagLabelAndIcons) && <div>{"--"}</div>}
      {_.map(
        _.slice(tagLabelAndIcons, 0, maxCount),
        ({ label, iconURL }, idx) => getLabelComp({ label, iconURL, idx })
      )}
      {_.size(tagLabelAndIcons) > maxCount && (
        <div className={classes.tag}>
          <LinkWithTooltip
            tooltip={tooltipComp}
            trigger={["click"]}
            placement={"right"}
            linkStyles={plusBtnStyle}
          >
            {moreTagsCount}
          </LinkWithTooltip>
        </div>
      )}
      {mode === "edit" && (
        <UIButton
          className={classes.addTagBtn}
          size="sm"
          type="hollow"
          containerStyle={addTagBtnStyle}
          labelStyle={addTagLabelStyle}
          color="blue"
          onClick={toggleAddTagsModal}
        >
          {texts.addText}
        </UIButton>
      )}
      {isAddTagsModalOpen && (
        <AddItemsModal
          items={organizationTags}
          onClickButton1={toggleAddTagsModal}
          onClickButton2={handleAddTags}
          buttonText1={texts.buttonText1}
          buttonText2={texts.buttonText2}
          modalTitle={texts.modalTitle}
          searchPlaceholderText={texts.searchPlaceholderText}
          noItemsAddedText={texts.noItemsAddedText}
          noItemsText={texts.noItemsText}
          noItemsSubText={texts.noItemsSubText}
          isCreateItems={true}
          onCreateItemClick={onCreateNewTag}
          itemName={texts.tag}
          selectedItemsList={selectedTags}
          setSelectedItemsList={setSelectedTags}
        />
      )}
      {isLoading && <FullScreenLoader />}
    </div>
  );
};
const mapActionCreators = {
  createMultiLevelTags,
};
export default compose(connect(null, mapActionCreators))(TagCell);
