import React from "react";
import classes from "./LoginPage.scss";
import { colors, fontStyle } from "Constants";
import { ToddleIcon, LogoTextSvg, ChevronIcon } from "SvgComponents";
import { WarningTriangleFilled } from "@toddle-design/web-icons";
import { emailValidation } from "Utils";
import { connect } from "react-redux";
import { TERM_URL, PRIVACY_URL, CLOUD_URL } from "Constants";
import * as EventTracker from "lib/eventTracker";
import { getCountString, isIphone } from "Utils";
import cx from "classnames";

import {
  initMixPanel,
  authenticateUserWithServer,
  updateCurrentScreen,
  sendForgotPassword,
  sendResetPassword,
  verifyDomain,
  createUnauthenticatedUser,
  createSchoolRequest,
  generateOTPForUnauthenticatedUser,
  generateOTPForParent,
  updateInputField,
  verifyUnauthenticatedUserOTP,
  verifyParentOTP,
  createPYPCoordinator,
  createInvitedUser,
  createInvitedParent,
  setCurrentScreen,
  getDesignationList,
  openChat,
  setToastMsg,
  signInUsingCode,
  setBifurcationAction,
  setRedirectUrl,
  setUserComingFromPublicAccess,
  setCurrentParentLoginTab,
  handleCreateParentAccount,
  handleVerifyParentInviteCode,
  updateInvitedFamilyData,
} from "Login/modules/LoginModule";
import { setUTMSourceInStorage } from "Utils";
import LoginForm from "./LoginForm";
import VisitorSignUp from "./VisitorSignUp";
import ParentLoginForm from "./ParentLoginForm";
import ForgotPassword from "./ForgotPassword";
import ForgotPhonePassword from "./ForgotPhonePassword";
import ResetPassword from "./ResetPassword";
import LoginHome from "./LoginHome";
import StudentLogin from "./StudentLogin";
import TeacherLogin from "./TeacherLogin";
import ParentLogin from "./ParentLogin";
import CreateParentAccount from "./CreateParentAccount";
import OrganizationSelection from "./OrganizationSelection";
import CreateAccount from "./CreateAccount";
import AddSchool from "./AddSchool";
import VerifyOTP from "./VerifyOTP";
import VerifyPhoneOTP from "./VerifyPhoneOTP";
import AddPYPDetails from "./AddPYPDetails";
import RequestSuccess from "./RequestSuccess";
import AddIdentification from "./AddIdentification";
import VisitorLogin from "./VisitorLogin";
import updateQueryParameters, { goToBack } from "modules/NavigationModule";
import { MagicLinkExpired } from "AppComponents";
import VerifyParentInviteCode from "./VerifyParentInviteCode";

const customStyle = {
  containerStyle: {
    paddingTop: "84px",
    paddingBottom: "32px",
  },
  headerStyle: {
    fontSize: "2.4rem",
    lineHeight: 1.36,
    color: colors.gray13,
    ...fontStyle.bold,
  },
  subTextStyle: {
    marginTop: "4px",
    fontSize: "1.6rem",
    color: colors.gray31,
    ...fontStyle.medium,
  },
};

const inputStyle = {
  labelContainerStyle: {
    marginBottom: "8px",
  },
  labelStyle: {
    fontSize: "1.6rem",
    color: colors.gray31,
    ...fontStyle.demiBold,
    lineHeight: "1.6rem",
  },
  inputStyle: {
    padding: "12px 16px",
    borderRadius: "8px",
  },
  errorStyle: {
    borderColor: colors.salmon60,
    backgroundColor: colors.white,
  },
};

class LoginPage extends React.PureComponent {
  state = {
    showMagicLinkModal: true,
  };

  componentDidMount = () => {
    const {
      currentScreen,
      location,
      setBifurcationAction,
      setRedirectUrl,
      setUserComingFromPublicAccess,
      userType,
    } = this.props;
    this.trackCurrentScreenPage({ currentScreen });
    setUTMSourceInStorage();
    // this.target = _.get(
    //   this.props,
    //   "location.query.utm_source",
    //   "direct_landing"
    // );

    const pathname = _.get(location, "pathname", "/");

    const bifurcationAction = _.get(location, "query.action");
    if (bifurcationAction) setBifurcationAction(bifurcationAction);

    const redirectUrl = _.get(location, "query.redirect_url");
    if (redirectUrl) {
      setRedirectUrl(redirectUrl);
    }

    const isUserComingFromPublicAccess = _.get(
      location,
      "query.is_public_access"
    );
    if (isUserComingFromPublicAccess)
      setUserComingFromPublicAccess(isUserComingFromPublicAccess);

    const type = _.get(location, "query.type", "");
    if (!type && pathname == "/") {
      this.updateCurrentScreen({ type: "loginHome" });
    }

    if (type === "studentLogin" && userType !== "student") {
      this.updateCurrentScreen({ type: "studentLogin", userType: "student" });
    }

    const queryData = _.get(location, "query", {});

    const screen = _.get(queryData, "screen", "");

    if (screen === "visitorSignup") {
      const {
        email,
        user_id: id,
        token,
        first_name,
        last_name,
        identity_id,
        region,
      } = queryData;
      const visitorName = `${first_name} ${last_name}`;

      this.updateCurrentScreen({ type: screen });
      this.props.updateInputField(screen, {
        email,
        id,
        token,
        name: visitorName,
        identityId: identity_id,
        region,
      });
    }
  };

  updateInputField = data => {
    const { updateInputField, currentScreen } = this.props;
    updateInputField(currentScreen, data);
  };

  trackCurrentScreenPage = ({ currentScreen }) => {
    switch (currentScreen) {
      case "loginHome":
      case "teacherLogin":
        // EventTracker.recordEvent({
        //   eventName: "Viewed Page",
        //   eventData: { page: "login", user_type: "lead" }
        // });
        // EventTracker.recordTrait({ data: { user_type: "lead" } });
        break;
      case "organizationSelection":
        initMixPanel();
        EventTracker.recordEvent({
          eventName: "Viewed Page",
          eventData: {
            page: "create account",
            user_type: "lead",
            // target: this.target
          },
        });
        EventTracker.recordTrait({ data: { user_type: "lead" } });
        break;
      case "addSchool":
        initMixPanel();
        EventTracker.recordEvent({
          eventName: "Viewed Page",
          eventData: {
            page: "add school",
            user_type: "lead",
            // target: this.target
          },
        });

        break;
      case "createAccount":
        initMixPanel();
        EventTracker.recordEvent({
          eventName: "Viewed Page",
          eventData: {
            page: "ask user details",
            user_type: "lead",
            // target: this.target
          },
        });
        break;
      case "addIdentification":
        EventTracker.recordEvent({
          eventName: "Viewed Page",
          eventData: {
            page: "ask user identification",
            user_type: "lead",
            // target: this.target
          },
        });
        break;
    }
  };

  onNextClick = () => {
    const {
      currentScreen,
      designationList,
      formData: {
        loginForm = {},
        forgotPassword = {},
        resetPassword = {},
        organizationSelection = {},
        createAccount = {},
        addSchool = {},
        verifyOTP = {},
        addPYPDetails = {},
        requestSuccess = {},
        addIdentification = {},
      } = {},
    } = this.props;
    const { selectedSchool } = organizationSelection;
    let designation, email, role, org_name, firstName, lastName;

    switch (currentScreen) {
      case "organizationSelection":
        org_name = selectedSchool?.name;
        EventTracker.recordTrait({ data: { user_type: "lead", org_name } });
        break;
      case "addSchool": {
        const { type } = addSchool;
        firstName = addSchool.firstName;
        lastName = addSchool.lastName;
        org_name = addSchool.name;
        designation = addSchool.designation;
        email = addSchool.email;
        role = _.get(_.find(designationList, { value: designation }), "label");
        // EventTracker.identifyUser({ identityString: email });
        EventTracker.recordTrait({
          data: {
            user_type: "lead",
            fname: firstName,
            lname: lastName,
            email,
            role,
            org_name,
          },
        });
        EventTracker.recordEvent({
          eventName: "Provided School Info",
          eventData: {
            org_type: type,
            fname: firstName,
            lname: lastName,
            email,
            role,
            org_name,
            user_type: "lead",
            // target: this.target
          },
        });

        break;
      }
      case "createAccount": {
        const { stateCode } = createAccount;
        firstName = createAccount.firstName;
        lastName = createAccount.lastName;

        const is_work_email = !(
          stateCode == "SUP_DOM_MIS_MTCH_NO_ORG" ||
          stateCode == "SUP_DOM_MIS_MTCH_HS_ORG"
        );

        org_name = selectedSchool.name;
        designation = createAccount.designation;
        email = createAccount.email;
        role = _.get(_.find(designationList, { value: designation }), "label");
        // EventTracker.identifyUser({ identityString: email });
        EventTracker.recordTrait({
          data: {
            user_type: "lead",
            fname: firstName,
            lname: lastName,
            email,
            role,
            org_name,
          },
        });

        EventTracker.recordEvent({
          eventName: "Provided Signup Details",
          eventData: {
            role,
            is_work_email,
            email,
            org_name,
            fname: firstName,
            lname: lastName,
            user_type: "lead",
            // target: this.target
          },
        });

        break;
      }
    }
  };

  resetScreen = (screenName, data = {}) => {
    const { updateInputField } = this.props;
    updateInputField(screenName, data);
  };

  updateCurrentScreen = ({
    type,
    userType: localUserType,
    paramsToBeRemoved,
    role,
  }) => {
    const { updateCurrentScreen, updateQueryParameters, userType } = this.props;

    updateCurrentScreen({
      type,
      usertype: localUserType || userType,
      paramsToBeRemoved,
      role,
    });
  };

  checkEmailVerification = value => {
    const isValidEmail = !!value && emailValidation(value);
    if (!isValidEmail) {
      return "Please enter a valid email address";
    } else {
      return "";
    }
  };

  renderErrorComponent = error => {
    // return <div className={classes.errorText}>{error}</div>;

    const errorContainerClasses = cx("text-error50 text-body-3 mt-1 flex", {
      invisible: !error,
    });
    return (
      <span className={errorContainerClasses}>
        <span className="mr-1 flex justify-center">
          <WarningTriangleFilled variant="critical" size="xxx-small" />
        </span>
        <span className="break-all pt-0.5">{error}</span>
      </span>
    );
  };

  onGoogleSignInCallBack = data => {
    const { userType, currentScreen } = this.props;
    const idTokenObj = data.tokenObj || data.Zi || data.uc || {};
    const idToken = idTokenObj.id_token;

    // Login only when current screen is correct according user type
    // if (
    //   !!idToken &&
    //   ((currentScreen == "teacherLogin" && userType == "staff") ||
    //     (currentScreen == "studentLogin" && userType == "student"))
    // ) {
    this.props.authenticateUserWithServer({
      idToken,
      method: "google",
      userType,
    });
    // }
  };

  onMicroSoftSignInCallBack = accessToken => {
    // Comment it as of now
    const { userType, currentScreen } = this.props;
    // Login only when current screen is correct according user type
    // if (
    //   accessToken &&
    //   ((currentScreen == "teacherLogin" && userType == "staff") ||
    //     (currentScreen == "studentLogin" && userType == "student"))
    // ) {
    this.props.authenticateUserWithServer({
      idToken: accessToken,
      method: "microsoft",
      userType,
    });
    // }
  };

  //clever log in callback
  onCleverSignInCallBack = ({ accessCode, redirectUri }) => {
    const { userType } = this.props;

    this.props.authenticateUserWithServer({
      idToken: accessCode,
      method: "clever",
      userType,
      redirectUri,
    });
  };

  onLexSignInCallBack = ({ accessCode, redirectUri }) => {
    const { userType } = this.props;

    this.props.authenticateUserWithServer({
      idToken: accessCode,
      method: "lex",
      userType,
      redirectUri,
    });
  };

  onSignInClick = () => {
    const { userType } = this.props;

    this.props.authenticateUserWithServer({
      method: "local",
      userType: userType,
    });
  };

  onPhoneSignInClick = () => {
    const { userType } = this.props;

    this.props.authenticateUserWithServer({
      method: "local",
      userType,
      isPhoneSignIn: true,
    });
  };

  renderBackButtonComponent = ({
    onClick,
    style = {},
    buttonText = "Back",
    currentStep,
    maxStep,
  } = {}) => {
    return (
      <div className={classes.backBtnContainer} style={style}>
        <div className={classes.backBtnInnerContainer} onClick={onClick}>
          <ChevronIcon width={6} height={11} fill={colors.gray13} />
          <div className={classes.backBtnText} onClick={this.onBackClick}>
            {buttonText}
          </div>
        </div>
        {currentStep && maxStep ? (
          <div
            className={classes.stepContainer}
          >{`Step ${currentStep} of ${maxStep}`}</div>
        ) : null}
      </div>
    );
  };

  isPrivacyTextVisible = () => {
    const { currentScreen, userType } = this.props;
    const privacyTextVisibleScreens = ["loginHome", "visitorSignup"];
    return (
      _.includes(privacyTextVisibleScreens, currentScreen) ||
      userType === "visitor"
    );
  };

  renderCreateAccountPrivacyText = ({
    privacyTextStyle = {},
    buttonText = "",
    isRequesting = false,
  }) => {
    const {
      formData: {
        organizationSelection: {
          selectedSchool: { name: schoolName = "" } = {},
        } = {},
      } = {},
    } = this.props;

    return (
      <div className={classes.privacyText} style={privacyTextStyle}>
        {`${
          buttonText ? `By clicking the “${buttonText}” button,` : ``
        } you are creating a Toddle account ${
          isRequesting && schoolName
            ? `and requesting access from ${schoolName}.`
            : `and`
        } are agreeing to Toddle’s `}
        <span>
          <a
            href={TERM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.underLineButton}
          >
            {`Terms of Use`}
          </a>
        </span>
        {" and "}
        <span>
          <a
            href={PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.underLineButton}
          >
            {`Privacy Policy`}
          </a>
        </span>
        .
      </div>
    );
  };

  renderTradeMarkText = (
    { align, containerStyle = {} } = {
      align: "center",
    }
  ) => {
    if (!this.isPrivacyTextVisible()) {
      return null;
    }

    const tradeMarkTextClassname =
      align == "left"
        ? classes.tradeMarkTextContainerLeft
        : classes.tradeMarkTextContainerCenter;

    return (
      <div className={tradeMarkTextClassname} style={containerStyle}>
        {`© Teacher Tools Private Limited. All Rights Reserved. `}
        {/* <span>
          <a
            href={TERM_URL}
            target="_blank"
            className={classes.underLineButton}
          >
            {`Cookie Preferences`}
          </a>
        </span>
        ,{" "} */}
        <span>
          <a
            href={PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.underLineButton}
          >
            {`Privacy Policy`}
          </a>
        </span>{" "}
        and{" "}
        <span>
          <a
            href={TERM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.underLineButton}
          >
            Terms of use
          </a>
        </span>
        .
      </div>
    );
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const nextScreen =
      _.get(nextProps, "location.query.type", "") || "loginHome";
    const userType = _.get(nextProps, "location.query.usertype", "staff");

    if (!_.isEqual(nextProps.currentScreen, this.props.currentScreen)) {
      this.scrollRightSideTop();
    }

    if (
      !!nextScreen &&
      !_.isEqual(nextScreen, _.get(this.props, "location.query.type", ""))
    ) {
      this.updateCurrentScreen({ type: nextScreen, userType });
    }
  };

  componentDidUpdate = prevProps => {
    if (!_.isEqual(prevProps.currentScreen, this.props.currentScreen)) {
      this.trackCurrentScreenPage({ currentScreen: this.props.currentScreen });
    }
  };

  scrollRightSideTop = () => {
    if (this.rightSideScrollRef) {
      this.rightSideScrollRef.scrollTop = 0;
    }
  };

  sendResetPassword = (params = {}) => {
    const { userType, sendResetPassword } = this.props;
    sendResetPassword({ ...params, userType });
  };

  sendForgotPassword = (params = {}) => {
    const { userType, sendForgotPassword } = this.props;
    sendForgotPassword({ ...params, userType });
  };

  onGoogelFailureCallBack = e => {
    // if (e?.error === "popup_closed_by_user") {
    //   this.props.setToastMsg({
    //     msg: "toastMsgs:click_again_to_login",
    //     type: "alert"
    //   });
    // }
    console.error(e);
  };

  renderRightSide = () => {
    const {
      verifyDomain,
      createUnauthenticatedUser,
      createSchoolRequest,
      generateOTPForUnauthenticatedUser,
      generateOTPForParent,
      verifyUnauthenticatedUserOTP,
      verifyParentOTP,
      createPYPCoordinator,
      createInvitedUser,
      createInvitedParent,
      progressOfUploads,
      isLoading,
      formData: {
        loginForm = {},
        visitorSignup = {},
        parentLoginForm = {},
        forgotPassword = {},
        forgotPhonePassword = {},
        resetPassword = {},
        organizationSelection = {},
        createAccount = {},
        createParentAccount = {},
        verifyParentInviteCode = {},
        addSchool = {},
        verifyOTP = {},
        verifyPhoneOTP = {},
        addPYPDetails = {},
        requestSuccess = {},
        addIdentification = {},
        requestSuccessCall = {},
        studentLogin = {},
      } = {},
      currentScreen,
      signInUsingCode,
      designationList,
      getDesignationList,
      location,
      userType,
      setCurrentParentLoginTab,
      currentParentLoginTab,
      isFamilyInviteRoute,
      isFamilyClassInviteRoute,
      handleCreateParentAccount,
      handleVerifyParentInviteCode,
      updateInvitedFamilyData,
      goToBack,
      role,
    } = this.props;

    switch (currentScreen) {
      case "loginHome":
        /** Hide the UI as of now */
        return (
          <LoginHome
            customStyle={customStyle}
            updateCurrentScreen={this.updateCurrentScreen}
            renderRightSideLogo={this.renderRightSideLogo}
            renderTradeMarkText={this.renderTradeMarkText}
          />
        );

      case "teacherLogin":
        return (
          <TeacherLogin
            customStyle={customStyle}
            onGoogleSignInCallBack={this.onGoogleSignInCallBack}
            onGoogelFailureCallBack={this.onGoogelFailureCallBack}
            onMicroSoftFailureCallBack={() => {
              console.error("error");
            }}
            onMicroSoftSignInCallBack={this.onMicroSoftSignInCallBack}
            onCleverSignInCallBack={this.onCleverSignInCallBack}
            onLexSignInCallBack={this.onLexSignInCallBack}
            updateCurrentScreen={this.updateCurrentScreen}
            renderBackButtonComponent={this.renderBackButtonComponent}
            renderTradeMarkText={this.renderTradeMarkText}
          />
        );
      case "studentLogin":
        return (
          <StudentLogin
            customStyle={customStyle}
            inputStyle={inputStyle}
            onGoogleSignInCallBack={this.onGoogleSignInCallBack}
            onGoogelFailureCallBack={this.onGoogelFailureCallBack}
            onMicroSoftFailureCallBack={() => {
              console.error("error");
            }}
            onMicroSoftSignInCallBack={this.onMicroSoftSignInCallBack}
            onCleverSignInCallBack={this.onCleverSignInCallBack}
            onLexSignInCallBack={this.onLexSignInCallBack}
            data={studentLogin}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            renderTradeMarkText={this.renderTradeMarkText}
            renderBackButtonComponent={this.renderBackButtonComponent}
            signInUsingCode={signInUsingCode}
            currentScreen={currentScreen}
          />
        );
      case "parentLogin":
        return (
          <ParentLogin
            customStyle={customStyle}
            onGoogleSignInCallBack={this.onGoogleSignInCallBack}
            onGoogelFailureCallBack={this.onGoogelFailureCallBack}
            onMicroSoftFailureCallBack={() => {
              console.error("error");
            }}
            onMicroSoftSignInCallBack={this.onMicroSoftSignInCallBack}
            onLexSignInCallBack={this.onLexSignInCallBack}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            renderTradeMarkText={this.renderTradeMarkText}
            renderBackButtonComponent={this.renderBackButtonComponent}
            signInUsingCode={signInUsingCode}
            isFamilyClassInviteRoute={isFamilyClassInviteRoute}
            isFamilyInviteRoute={isFamilyInviteRoute}
          />
        );

      case "verifyParentInviteCode":
        return (
          <VerifyParentInviteCode
            customStyle={customStyle}
            renderBackButtonComponent={this.renderBackButtonComponent}
            updateCurrentScreen={this.updateCurrentScreen}
            updateInputField={this.updateInputField}
            data={verifyParentInviteCode}
            showErrorToast={this.showErrorToast}
            handleVerifyParentInviteCode={handleVerifyParentInviteCode}
          />
        );
      case "createParentAccount":
        return (
          <CreateParentAccount
            customStyle={customStyle}
            renderBackButtonComponent={this.renderBackButtonComponent}
            updateCurrentScreen={this.updateCurrentScreen}
            updateInputField={this.updateInputField}
            data={createParentAccount}
            inputStyle={inputStyle}
            renderErrorComponent={this.renderErrorComponent}
            showErrorToast={this.showErrorToast}
            checkEmailVerification={this.checkEmailVerification}
            handleCreateParentAccount={handleCreateParentAccount}
            createInvitedParent={createInvitedParent}
            isFamilyClassInviteRoute={isFamilyClassInviteRoute}
            isFamilyInviteRoute={isFamilyInviteRoute}
            updateInvitedFamilyData={updateInvitedFamilyData}
          />
        );
      case "parentLoginForm":
        return (
          <ParentLoginForm
            customStyle={customStyle}
            renderBackButtonComponent={this.renderBackButtonComponent}
            updateCurrentScreen={this.updateCurrentScreen}
            onSignInClick={this.onSignInClick}
            onPhoneSignInClick={this.onPhoneSignInClick}
            showErrorToast={this.showErrorToast}
            updateInputField={this.updateInputField}
            data={parentLoginForm}
            inputStyle={inputStyle}
            checkEmailVerification={this.checkEmailVerification}
            renderErrorComponent={this.renderErrorComponent}
            setCurrentParentLoginTab={setCurrentParentLoginTab}
            currentParentLoginTab={currentParentLoginTab}
          />
        );
      case "organizationSelection":
        return (
          <OrganizationSelection
            customStyle={customStyle}
            data={organizationSelection}
            onNextClick={this.onNextClick}
            inputStyle={inputStyle}
            renderBackButtonComponent={this.renderBackButtonComponent}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            renderErrorComponent={this.renderErrorComponent}
            resetScreen={this.resetScreen}
            goToScrollTopOffset={this.goToScrollTopOffset}
            showErrorToast={this.showErrorToast}
          />
        );
      case "loginForm": {
        return (
          <LoginForm
            customStyle={customStyle}
            inputStyle={inputStyle}
            onNextClick={this.onNextClick}
            data={loginForm}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            resetScreen={this.resetScreen}
            onSignInClick={this.onSignInClick}
            checkEmailVerification={this.checkEmailVerification}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            showErrorToast={this.showErrorToast}
            userType={userType}
            role={role}
            renderTradeMarkText={this.renderTradeMarkText}
          />
        );
      }
      case "forgotPassword":
        return (
          <ForgotPassword
            customStyle={customStyle}
            data={forgotPassword}
            loginData={loginForm}
            inputStyle={inputStyle}
            updateInputField={this.updateInputField}
            resetScreen={this.resetScreen}
            sendForgotPassword={this.sendForgotPassword}
            checkEmailVerification={this.checkEmailVerification}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            showErrorToast={this.showErrorToast}
            userType={userType}
            goToBack={goToBack}
          />
        );
      case "forgotPhonePassword":
        return (
          <ForgotPhonePassword
            renderBackButtonComponent={this.renderBackButtonComponent}
            updateCurrentScreen={this.updateCurrentScreen}
            customStyle={customStyle}
            data={forgotPhonePassword}
            updateInputField={this.updateInputField}
            inputStyle={inputStyle}
            renderErrorComponent={this.renderErrorComponent}
            showErrorToast={this.showErrorToast}
            generateOTPForParent={generateOTPForParent}
          />
        );
      case "resetPassword":
        return (
          <ResetPassword
            customStyle={customStyle}
            data={resetPassword}
            inputStyle={inputStyle}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            resetScreen={this.resetScreen}
            sendResetPassword={this.sendResetPassword}
            renderErrorComponent={this.renderErrorComponent}
            showErrorToast={this.showErrorToast}
          />
        );

      case "createAccount":
        return (
          <CreateAccount
            customStyle={customStyle}
            getDesignationList={getDesignationList}
            data={createAccount}
            onNextClick={this.onNextClick}
            designationList={designationList}
            organizationSelectionData={organizationSelection}
            inputStyle={inputStyle}
            checkEmailVerification={this.checkEmailVerification}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            resetScreen={this.resetScreen}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            verifyDomain={verifyDomain}
            createUnauthenticatedUser={createUnauthenticatedUser}
            renderCreateAccountPrivacyText={this.renderCreateAccountPrivacyText}
            createInvitedUser={createInvitedUser}
            renderContactUsButton={this.renderContactUsButton}
            showErrorToast={this.showErrorToast}
          />
        );

      case "visitorSignup": {
        return (
          <VisitorSignUp
            updateInputField={this.updateInputField}
            checkEmailVerification={this.checkEmailVerification}
            renderErrorComponent={this.renderErrorComponent}
            updateCurrentScreen={this.updateCurrentScreen}
            data={{ ...visitorSignup }}
            customStyle={customStyle}
            sendResetPassword={this.sendResetPassword}
            showErrorToast={this.showErrorToast}
            renderTradeMarkText={this.renderTradeMarkText}
            setVisitorLoginForm={this.props.updateInputField}
          />
        );
      }

      case "visitorLogin": {
        return (
          <VisitorLogin
            renderBackButtonComponent={this.renderBackButtonComponent}
            updateCurrentScreen={this.updateCurrentScreen}
            onGoogleSignInCallBack={this.onGoogleSignInCallBack}
            onGoogelFailureCallBack={this.onGoogelFailureCallBack}
            onMicroSoftSignInCallBack={this.onMicroSoftSignInCallBack}
            onMicroSoftFailureCallBack={this.onMicroSoftFailureCallBack}
          />
        );
      }

      case "addSchool":
        return (
          <AddSchool
            onNextClick={this.onNextClick}
            customStyle={customStyle}
            getDesignationList={getDesignationList}
            data={addSchool}
            designationList={designationList}
            inputStyle={inputStyle}
            checkEmailVerification={this.checkEmailVerification}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            resetScreen={this.resetScreen}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            createSchoolRequest={createSchoolRequest}
            progressOfUploads={progressOfUploads}
            renderContactUsButton={this.renderContactUsButton}
            showErrorToast={this.showErrorToast}
          />
        );

      case "verifyOTP":
        return (
          <VerifyOTP
            isLoading={isLoading}
            customStyle={customStyle}
            data={verifyOTP}
            inputStyle={inputStyle}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            resetScreen={this.resetScreen}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            generateOTPForUnauthenticatedUser={
              generateOTPForUnauthenticatedUser
            }
            verifyUnauthenticatedUserOTP={verifyUnauthenticatedUserOTP}
            verifyParentOTP={verifyParentOTP}
            renderCreateAccountPrivacyText={this.renderCreateAccountPrivacyText}
            userType={userType}
            generateOTPForParent={generateOTPForParent}
          />
        );
      case "verifyPhoneOTP":
        return (
          <VerifyPhoneOTP
            generateOTPForParent={generateOTPForParent}
            customStyle={customStyle}
            renderBackButtonComponent={this.renderBackButtonComponent}
            data={verifyPhoneOTP}
            updateCurrentScreen={this.updateCurrentScreen}
            updateInputField={this.updateInputField}
            isLoading={isLoading}
            renderCreateAccountPrivacyText={this.renderCreateAccountPrivacyText}
            verifyParentOTP={verifyParentOTP}
          />
        );
      case "addPYPDetails":
        return (
          <AddPYPDetails
            customStyle={customStyle}
            data={addPYPDetails}
            inputStyle={inputStyle}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            resetScreen={this.resetScreen}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            createPYPCoordinator={createPYPCoordinator}
            checkEmailVerification={this.checkEmailVerification}
            showErrorToast={this.showErrorToast}
          />
        );

      case "requestSuccess":
        return (
          <RequestSuccess
            data={requestSuccess}
            customStyle={customStyle}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
          />
        );

      case "addIdentification":
        return (
          <AddIdentification
            data={addIdentification}
            customStyle={customStyle}
            inputStyle={inputStyle}
            updateInputField={this.updateInputField}
            updateCurrentScreen={this.updateCurrentScreen}
            renderErrorComponent={this.renderErrorComponent}
            renderBackButtonComponent={this.renderBackButtonComponent}
            progressOfUploads={progressOfUploads}
            renderContactUsButton={this.renderContactUsButton}
            showErrorToast={this.showErrorToast}
          />
        );
    }
    return null;
  };

  updateRightSideScrollRef = ref => {
    this.rightSideScrollRef = ref;
  };

  goToScrollTopOffset = ({ element }) => {
    if (this.rightSideScrollRef) {
      if (element && !isIphone()) {
        this.rightSideScrollRef.scrollTop = _.get(element, "offsetTop", 0) - 32;
      }
    }
  };

  showErrorToast = ({ errorCount }) => {
    if (errorCount <= 0) {
      return;
    }
    const countString = getCountString({
      count: errorCount,
      singularText: "error",
      pluralText: "errors",
      postfix: " ",
      shouldShowZero: true,
    });
    this.props.setToastMsg({
      msg: `toastMsgs:rectify_errors_with_count`,
      locale_params: [{ key: "count", value: errorCount, isPlainText: true }],
      position: "toast-top-center",
      type: "alert",
      fill: colors.yellow50,
    });
  };

  renderLogo = () => {
    return (
      <div className={classes.logoContainer}>
        <div className={classes.toddleLogo}>
          <ToddleIcon width={48} height={48} />
        </div>
        <div className={classes.toddleLogoText}>
          <LogoTextSvg scale={1.35} />
        </div>
      </div>
    );
  };

  renderRightSideLogo = () => {
    return (
      <div className={classes.rightsidelogocontainer}>{this.renderLogo()}</div>
    );
  };

  renderContactUsButton = ({
    text = "Chat with us",
    style = { color: colors.blue29 },
  } = {}) => {
    return (
      <span
        className={classes.writeToUsButton}
        onClick={this.onContactusButtonClick}
        style={style}
      >
        {text}
      </span>
    );
  };

  onContactusButtonClick = () => {
    openChat();
  };

  getLeftBannerUrl = () => {
    const { userType, currentScreen } = this.props;
    const URL = `${CLOUD_URL}/assets/webapp/login_page`;

    if (currentScreen === "loginHome") {
      return `${URL}/HomeLeftBanner.svg`;
    }

    switch (userType) {
      case "staff":
        return `${URL}/TeacherLeftBanner.svg`;
      case "parent":
        return `${URL}/FamilyLeftBanner.svg`;
      case "student":
        return `${URL}/StudentLeftBanner.svg`;
    }
  };

  render() {
    const { loginError } = this.props;
    const { showMagicLinkModal } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <div
            className={classes.leftBanner}
            style={{
              backgroundImage: `url(${this.getLeftBannerUrl()})`,
            }}
            key={this.getLeftBannerUrl()}
          />
          <div className={classes.leftSideLogoContainer}>
            {this.renderLogo()}
          </div>
        </div>
        <div className={classes.rightSide} ref={this.updateRightSideScrollRef}>
          <div className={classes.rightSideInnerContainer}>
            {this.renderRightSide()}
          </div>
        </div>
        {!!loginError && showMagicLinkModal && (
          <MagicLinkExpired
            closeModal={() => this.setState({ showMagicLinkModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  authenticateUserWithServer,
  updateInputField,
  updateCurrentScreen,
  sendForgotPassword,
  sendResetPassword,
  verifyDomain,
  createUnauthenticatedUser,
  createSchoolRequest,
  generateOTPForUnauthenticatedUser,
  generateOTPForParent,
  verifyUnauthenticatedUserOTP,
  verifyParentOTP,
  createPYPCoordinator,
  createInvitedUser,
  createInvitedParent,
  getDesignationList,
  setToastMsg,
  setCurrentScreen,
  signInUsingCode,
  updateQueryParameters,
  setBifurcationAction,
  setRedirectUrl,
  setUserComingFromPublicAccess,
  setCurrentParentLoginTab,
  handleCreateParentAccount,
  goToBack,
  handleVerifyParentInviteCode,
  updateInvitedFamilyData,
};

const mapStateToProps = (state, ownProps) => {
  const userType = _.get(ownProps, "location.query.usertype", "staff");
  const invitedFamilyData = state.login.invitedFamilyData;

  return {
    designationList: state.login.designationList,
    isLoading: state.login.isLoading,
    currentScreen: state.login.currentScreen,
    userType,
    formData: state.login.formData,
    progressOfUploads: state.app_services.progressOfUploads,
    loginError: _.get(state, "login.loginError"),
    currentParentLoginTab: _.get(state, "login.currentParentLoginTab", "EMAIL"),
    role: _.get(ownProps, "location.query.role", ""),
    isFamilyClassInviteRoute:
      _.get(invitedFamilyData, "type") === "family_class_invite",
    isFamilyInviteRoute: _.get(invitedFamilyData, "type") === "invite_family",
  };
};

export default connect(mapStateToProps, mapActionCreators)(LoginPage);
