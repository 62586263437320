import React, { useEffect, useState } from "react";
import classes from "./SelectionList.scss";
import classNames from "classnames";
import { CheckListContainer, withLoader } from "UIComponents";
import { colors } from "Constants";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { getUserCoursesFromCache } from "Teacher/modules/TeacherGraphqlHelpers";
import { getUserCoursesQuery } from "Teacher/modules/TeacherQuery";
import { getEnabledClassNotificationsQuery } from "modules/CommonQuery";
import { getEnabledClassNotificationsFromCache } from "modules/CommonGraphqlHelpers";
import { setEnabledClassNotification } from "modules/Services";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import { withRouter } from "react-router";

const checkListStyles = {
  checkListContainerStyle: {
    display: "grid",
    gridGap: "16px",
    gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
  },
};

const SelectionList = ({
  subModule,
  checkListContainerStyle = {},
  onUpdateSetting,
  headerStyle,
  subModuleTitleStyles,
  allCourses,
  enabledClassNotifications,
  setEnabledClassNotification,
  listItemStyle,
  activeBorder,
}) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(
      _.map(
        _.filter(enabledClassNotifications, notification => {
          return _.get(notification, "isEnabled") === true;
        }),
        item => _.get(item, "course.id")
      )
    );
  }, [enabledClassNotifications]);

  const options = _.map(allCourses, course => {
    return {
      value: course.id,
      label: course.title,
    };
  });

  const handleChange = async params => {
    const courseId = _.get(params, "value");
    const input = {
      courseId,
    };
    const isEnabled = !_.includes(values, courseId);
    if (isEnabled) {
      setValues(prevState => [...prevState, courseId]);
    } else {
      setValues(prevState => {
        return values.filter(value => value !== courseId);
      });
    }
    input["isEnabled"] = isEnabled;
    try {
      await setEnabledClassNotification(input);
    } catch (error) {
      console.error(error);
    }
  };

  const checklistItemStyle = {
    border: `1px solid ${colors.strokeOne}`,
    padding: "14px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "160px",
    minWidth: "160px",
    maxWidth: "160px",
    margin: "0",
    ...listItemStyle,
  };

  return (
    <div className={classes.container}>
      <div className={classes.header} style={headerStyle}>
        <div className={classes.title} style={subModuleTitleStyles}>
          {subModule.label}
        </div>
        <div className={classes.subTitle}>{subModule.subText}</div>
      </div>
      <CheckListContainer
        theme={"CUSTOM"}
        value={values}
        options={options}
        name="classNotifications"
        listItemStyle={checklistItemStyle}
        checkListContainerStyle={checkListStyles.checkListContainerStyle}
        isSingleValueUpdate={true}
        customToggleFn={handleChange}
        {...checkListContainerStyle}
        activeBorder={activeBorder}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const curriculumProgramId = _.get(ownProps, "params.curriculumProgramId", "");

  const tabConfigurations = { admin: { curriculumProgramId } };
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });

  const userId = state.login.userInfo.id;
  return {
    userId,
    isLoading: false,
    isData: true,
    isCurriculumProgramFree,
  };
};

const mapActionCreators = {
  setEnabledClassNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators),
  graphql(getUserCoursesQuery, {
    name: "getUserCourses",
    options: ({ userId, isCurriculumProgramFree }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: "STAFF",
        isCurriculumProgramFree,
      },
    }),
    props({
      getUserCourses,
      ownProps: { userId, isData, isLoading, isCurriculumProgramFree },
    }) {
      const queryData = getUserCoursesFromCache({
        id: userId,
        type: "STAFF",
        isCurriculumProgramFree,
      });
      const allCourses = _.get(queryData, "courses", []);
      const courses = _.filter(allCourses, { isDemo: false });
      return {
        isData: !_.isEmpty(queryData) && isData,
        allCourses: courses,
        isLoading:
          getUserCourses["networkStatus"] == 1 ||
          getUserCourses["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  graphql(getEnabledClassNotificationsQuery, {
    name: "getEnabledClassNotifications",
    options: ({ userId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: userId,
        type: "STAFF",
      },
    }),
    props({
      getEnabledClassNotifications,
      ownProps: { userId, isData, isLoading },
    }) {
      const enabledClassNotifications = getEnabledClassNotificationsFromCache({
        id: userId,
        type: "STAFF",
      });
      return {
        isData: !_.isEmpty(enabledClassNotifications) && isData,
        enabledClassNotifications,
        isLoading:
          getEnabledClassNotifications["networkStatus"] == 1 ||
          getEnabledClassNotifications["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(React.memo(SelectionList));
