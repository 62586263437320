import { normalizeTransactionName } from "services/sentry/router";
import * as Router from "react-router";
import _ from "lodash";

const getRouteName = () => {
  const { plainRoutes } = window;
  let routeName;
  normalizeTransactionName(plainRoutes, window.location, Router.match, name => {
    routeName = name;
  });

  return routeName;
};

export const getExtensions = ({ request }) => {
  const extensions = _.get(request, "extensions", {});
  const { href } = window.location;
  const route = getRouteName();
  return {
    ...extensions,
    route,
    href,
  };
};
