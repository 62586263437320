import React from "react";

const EmailIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 20 20">
      <path
        fill={props.fill}
        d="M18.2421875,2 L1.7578125,2 C0.789765625,2 0,2.78890625 0,3.7578125 L0,15.4765625 C0,16.4421875 0.78625,17.234375 1.7578125,17.234375 L18.2421875,17.234375 C19.2078125,17.234375 20,16.448125 20,15.4765625 L20,3.7578125 C20,2.7921875 19.21375,2 18.2421875,2 Z M17.9994531,3.171875 L10.0372656,11.1341016 L2.00621094,3.171875 L17.9994531,3.171875 Z M1.171875,15.2339063 L1.171875,3.99488281 L6.81558594,9.5901953 L1.171875,15.2339063 Z M2.00050781,16.0625 L7.64777344,10.4152344 L9.6265625,12.3770312 C9.855625,12.6041406 10.2252734,12.6033984 10.4533984,12.3752344 L12.3828125,10.4458203 L17.9994922,16.0625 L2.00050781,16.0625 Z M18.828125,15.2338672 L13.2114453,9.6171875 L18.828125,4.00046875 L18.828125,15.2338672 Z"
      />
    </svg>
  );
};

EmailIcon.defaultProps = {
  height: 20,
  width: 20,
  fill: "#202632",
};

export default EmailIcon;
