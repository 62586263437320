import React from "react";
import PropTypes from "prop-types";
import classes from "./ResourceImagePlaceHolder.scss";
import { getRethumbUrl } from "Utils";
import {
  LE_ASSESSMENT_TYPE_MAPPING,
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
} from "Constants";

const getRethumb = ({ imageUrl, imageThumbSize }) => {
  const rethumpUrl = imageUrl
    ? getRethumbUrl({
        width: imageThumbSize,
        height: imageThumbSize,
        imageUrl: imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${rethumpUrl})`;
};

const ResourceImagePlaceHolder = ({
  type,
  imageUrl,
  imageThumbSize,
  icon,
  id,
  containerStyle = {},
}) => {
  //const typeVal = _.get(type, "value", "le");
  const typeVal = "le";
  const typeText = _.get(
    LE_ASSESSMENT_TYPE_MAPPING[typeVal],
    "abbrevation",
    ""
  );

  const rethumpUrl = !typeText
    ? getRethumb({
        imageUrl:
          imageUrl || PLACEHOLDER_RECT_IMAGE[id % PLACEHOLDER_RECT_IMAGE_COUNT],
        imageThumbSize,
      })
    : "";

  return !typeText && !icon ? (
    <div
      className={classes.imageContainer}
      style={{
        backgroundImage: rethumpUrl,
        ...containerStyle,
      }}
    />
  ) : (
    <div className={classes.container} style={containerStyle}>
      {icon ? icon : <div className={classes.text}>{typeText}</div>}
    </div>
  );
};

export default ResourceImagePlaceHolder;

ResourceImagePlaceHolder.propTypes = {
  imageUrl: PropTypes.string,
  imageThumbSize: PropTypes.number,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ResourceImagePlaceHolder.defaultProps = {
  type: "",
  imageUrl: "",
  imageThumbSize: 128,
};
