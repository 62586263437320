import gql from "graphql-tag";
import { staffFragment } from "modules/CommonFragments";
export const checklistItemFragment = {
  checklistOption: gql`
    fragment checklistOption on ChecklistOptionItem {
      id
      label
    }
  `,
  checklistItem: gql`
    fragment checklistItem on ChecklistItem {
      id
      label
    }
  `,
};

export const checklistFragment = {
  checklist: gql`
    fragment checklist on Checklist {
      id
      title
      checklistItems {
        ...checklistItem
      }
      checklistOptionItems {
        ...checklistOption
      }
      createdBy {
        ...staffItem
      }
      lastEdited: updatedAt
    }
    ${checklistItemFragment.checklistOption}
    ${checklistItemFragment.checklistItem}
    ${staffFragment.basicStaffDetails}
  `,
};

export const organizationChecklistFragment = {
  checklists: gql`
    fragment organizationItem on Organization {
      id
      checklists(filters: $filters) {
        ...checklist
      }
    }
    ${checklistFragment.checklist}
  `,
};
