import { colors, fontStyle } from "Constants";

export const filterSearchStyle = {
  customStyle: {
    paddingBottom: "4px",
    border: "unset",
    borderBottom: `1px solid ${colors.gray72}`,
    borderRadius: 0,
    ...fontStyle.medium,
  },

  searchSvgStyle: {
    marginTop: "6px",
    marginRight: "12px",
    marginLeft: 0,
  },

  searchIconStyle: {
    height: 16,
    width: 16,
  },

  cancelIconStyle: {
    height: 12,
    width: 12,
  },
};

export const parentContainerStyle = {
  flexShrink: 0,
};

export const filterStyles = {
  checkListItemStyle: {
    paddingRight: 0,
  },
  checkboxItemStyle: {
    borderRadius: "2px",
  },
  checkBoxParentContainer: {
    alignItems: "start",
  },
  buttonDropDownContainerStyle: {
    padding: 0,
    minWidth: "160px",
    top: "24px",
    maxWidth: "320px",
  },
};
