import PropTypes from "prop-types";
import React from "react";

const NewTabIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 14 14">
      <g fill={props.fill && props.fill} fillRule="evenodd">
        <path d="M8.338,5.552 C8.478,5.552 8.619,5.498 8.727,5.392 L12.078,2.039 L12.078,3.121 C12.078,3.42392819 12.3235718,3.6695 12.6265,3.6695 C12.9294282,3.6695 13.175,3.42392819 13.175,3.121 L13.176,0.714 C13.1762661,0.568314736 13.1185106,0.42851978 13.0154954,0.32550457 C12.9124802,0.22248936 12.7726853,0.164733908 12.627,0.165 L10.22,0.165 C9.91679567,0.165 9.671,0.410795672 9.671,0.714 C9.671,1.01720433 9.91679567,1.263 10.22,1.263 L11.302,1.263 L7.95,4.614 C7.79346433,4.77126564 7.74676179,5.0071904 7.83157671,5.21223266 C7.91639163,5.41727492 8.11610957,5.55126882 8.338,5.552 L8.338,5.552 Z" />
        <path d="M12.627,8.588 C12.3240239,8.58855037 12.0785504,8.83402393 12.078,9.137 L12.078,12.244 L1.098,12.244 L1.098,1.262 L4.134,1.262 C4.42435298,1.24446714 4.65084883,1.00388186 4.65084883,0.713 C4.65084883,0.422118143 4.42435298,0.181532861 4.134,0.164 L1.098,0.164 C0.492,0.165 0,0.657 0,1.263 L0,12.243 C0,12.849 0.492,13.341 1.098,13.341 L12.078,13.341 C12.685,13.341 13.176,12.849 13.176,12.243 L13.176,9.137 C13.1760005,8.83363348 12.930366,8.58755158 12.627,8.587 L12.627,8.588 Z" />
      </g>
    </svg>
  );
};

NewTabIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

NewTabIcon.defaultProps = {
  height: 14,
  width: 14,
  fill: "#393939",
};

export default NewTabIcon;
