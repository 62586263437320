import PropTypes from "prop-types";
import React from "react";

import { colors } from "Constants";

const CancelIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 12 12"
      version="1.1"
      fill={props.fill && props.fill}
    >
      <g id="Student-Profile" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M6.90451592,6.00236249 L11.8065006,1.09782782 C12.0644998,0.848378599 12.0644998,0.438654879 11.8065006,0.188380661 C11.5614014,-0.0627935538 11.1567776,-0.0627935538 10.9030534,0.188380661 L6.00106875,5.09126534 L1.09908407,0.188380661 C0.849634845,-0.0627935538 0.44163612,-0.0627935538 0.187086915,0.188380661 C-0.0623623051,0.438654879 -0.0623623051,0.848378599 0.187086915,1.09782782 L5.0890716,6.00236249 L0.187086915,10.9017972 C-0.0623623051,11.1537964 -0.0623623051,11.5626201 0.187086915,11.8129693 C0.308811535,11.9389689 0.475086015,12.0006187 0.641360496,12.0006187 C0.808534973,12.0006187 0.970534467,11.9389689 1.09563408,11.8129693 L6.00106875,6.91090965 L10.9030534,11.8129693 C11.028228,11.9389689 11.1945025,12.0006187 11.352227,12.0006187 C11.5236765,12.0006187 11.685676,11.9389689 11.8065006,11.8129693 C12.0644998,11.5626201 12.0644998,11.1537964 11.8065006,10.9017972 L6.90451592,6.00236249 Z"
          id="No"
        />
      </g>
    </svg>
  );
};

CancelIcon.defaultProps = {
  height: 24,
  width: 24,
};

CancelIcon.propTypes = {
  fill: PropTypes.string,
};

export default CancelIcon;
