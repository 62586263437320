module.exports = {
  workbookUrl: "https://staging-wrbk.toddleapp.com",
  useDynamicRoute: true,
  sentryTraceSampleRate: 0,
  isSentryBrowserTracingEnabled: false,
  isDevToolsExtensionEnabled: false,
  i18nextLanguage: undefined,
  websiteUrl: "https://staging.toddleapp.com",
  intercomAppId: "f8a4dqdz",
  microsoftClientId: "bad85d63-7d59-4a6b-a3ce-73c1119c5106",
  zoomClientId: "N6RRpTkDStuYXjGiFGbIcg",
  lexClientId: "9eecb09391f74eedb9253ee4bfb6ebdc",
  cleverClientId: "460bd5421c8d0b56bac7",
  classLinkClientId: "c1643884117260c69cfa798563e371a16c152e4df379",
  mixPanelClientId: "2897f706944cd76bc106c6e935f63ddc",
  sprigClientId: "YZoqEAzmJ",
  configMyp: {
    demoCourse: 4826,
    sourceOrganization: 782,
    repoOrganization: 827,
    portalType: "MYP_SAMPLE_ORG",
    curriculumType: "IB_MYP",
  },
  configUbd: {
    demoCourse: 19235,
    sourceOrganization: 1036,
    repoOrganization: 1390,
    portalType: "UBD_SAMPLE_ORG",
    curriculumType: "UBD",
  },
  dummyUserToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6LTcsImVtYWlsIjoicmFtaXpAdG9kZGxlYXBwLmNvbSIsInV0Ijoic3RhZmYiLCJyb2xlcyI6WyJ0ZWFjaGVyIiwiYWRtaW4iLCJyZXZpZXdlciJdLCJvaWQiOi03LCJpYXQiOjE1OTg2OTUzMDl9.hTkQc5hjIOPjM4hvSWbtShnbMBrS6LVwG7nPwkM9nS8",
  publicUserToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6LTgsImVtYWlsIjoicHVibGljLnBsYXRmb3JtLmFjY2Vzc0B0b2RkbGVhcHAuY29tIiwidXQiOiJzdGFmZiIsInJvbGVzIjpbInRlYWNoZXIiLCJyZXZpZXdlciJdLCJvaWQiOi04LCJpYXQiOjE2Mzc5MzA5MTl9.LML3OPWCbEGcDiq8J9F4K_uAmTI4s1VcPh5JtLepWwk",
  webpack: {
    isBabelLoaderCacheCompressionEnabled: true,
  },
  demoMypOrg: { id: "827", curriculumId: "110" },
  enableGoogleAnalytics: false,
  enableMixPanel: false,
  enableSprig: false,
  enableUpvoty: false,
  enableLogPageView: false,
  shouldRerouteAfterError: false,
  enableUniqueIdGenerator: true,
  enableTemplateDeploymentAutomation: false,
  pusherAppKey: "COLp9w.0CNo-Q:9wvmiPdAFHlTQKSO",
  shouldUseDynamicUrlForDemo: false,
  getLexSsoUrl: (LEX_CLIENT_ID, redirectUri, LEX_CODE_CHALLENGE) => {
    return `https://homolog-api.lex.education/sso/connect/authorize?client_id=${LEX_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=openid&code_challenge=${LEX_CODE_CHALLENGE}&code_challenge_method=S256`;
  },
  getClassLinkSsoUrl: (CLASSLINK_CLIENT_ID, redirectUri) => {
    return `https://launchpad.classlink.com/oauth2/v2/auth?client_id=${CLASSLINK_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=profile`;
  },
  backendUrl: "https://staging-apigw.toddleapp.com",
  isNewFamilySignUpFlowEnabled: true,
};
