const containerStyle = {
  flex: "unset",
  width: "auto",
};

const buttonDropDownContainerStyle = {
  right: "unset",
  left: 0,
};

const filterGrpContainerStyle = {
  backgroundColor: "unset",
  paddingLeft: 0,
  paddingRight: 0,
  marginLeft: "24px",
};

const moreFilterGrpContainerStyle = {
  marginTop: 0,
  paddingBottom: 0,
};

const moreFiltersStyle = {
  display: "none",
};

const searchStyles = {
  containerStyle: {
    marginRight: "auto",
    justifySelf: "flex-start",
  },
  searchContainerStyle: { marginBottom: 0 },
};

export {
  containerStyle,
  buttonDropDownContainerStyle,
  filterGrpContainerStyle,
  moreFiltersStyle,
  moreFilterGrpContainerStyle,
  searchStyles,
};
