import React, { Component } from "react";
import classes from "./BulkUploadError.scss";
import { UIButton, UIModal } from "UIComponents";
import { saveAs } from "file-saver";

export class BulkUploadError extends Component {
  onClose = () => {
    return this.props.toggleBulkUploadErrorModal();
  };

  onDownloadClick = () => {
    //loop to get a random number
    let errorMessageFormatted = JSON.stringify(this.props.errors, null, "\t")
      .replace(/\t/g, "")
      .replace(/\[/g, "")
      .replace(/]/g, "")
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/"/g, "");

    let blob = new Blob([errorMessageFormatted], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "errors.txt");
    return this.props.toggleBulkUploadErrorModal();
  };

  render() {
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={this.onClose}
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalTitleText}>
            {"Errors found in sheet"}
          </div>
        </div>
        <div className={classes.modalFooter}>
          <div className={classes.bottomLeftContainer} />
          <div className={classes.buttonContainer}>
            <UIButton
              color={"grey"}
              size={"sm"}
              type={"filled"}
              onClick={this.onClose}
            >{`Close`}</UIButton>

            <UIButton
              color={"pink"}
              size={"sm"}
              type={"filled"}
              onClick={this.onDownloadClick}
            >
              {`Download Error List`}
            </UIButton>
          </div>
        </div>
      </UIModal>
    );
  }
}

export default BulkUploadError;
