import React from "react";
import { compose } from "redux";
import classes from "./CheckListEdit.scss";
import { connect } from "react-redux";
import { I18nHOC } from "UIComponents";
import { Button } from "@toddle-design/web";
import { AddOutlined } from "@toddle-design/web-icons";

import {
  createChecklistItem,
  createChecklistOptionItem,
  deleteChecklistOption,
  deleteChecklistItem,
  writeChecklistElementLocal,
} from "Checklist/modules/ChecklistModule";

import { updateField } from "modules/Services";
import CheckListEditRow from "./CheckListEditRow";

const MIN_OPTION_COUNT = 1;
const MAX_OPTION_COUNT = 4;

class CheckListComp extends React.PureComponent {
  constructor(props) {
    super(props);
    if (props.customRef) {
      props.customRef(this);
    }
    this.state = { inFocusColumn: null, inFocusRow: null };
    this.focusedCell = null;
    this.cellRefs = {};
  }

  onColumnFocus = id => {
    this.setState({ inFocusColumn: id });
  };

  onRowFocus = id => {
    this.setState({ inFocusRow: id });
  };

  blur = () => {
    if (this.focusedCell && this.cellRefs[this.focusedCell]) {
      this.cellRefs[this.focusedCell].blur();
    }
  };

  setCellRef = ({ key, ref }) => {
    this.cellRefs[key] = ref;
  };

  setFocusedCell = key => {
    this.focusedCell = key;
  };

  render() {
    const {
      checklistItems,
      checklistOptionItems,
      onOptionDeleteClick,
      onItemDeleteClick,
      writeCheckListElement,
    } = this.props;

    const { inFocusColumn, inFocusRow } = this.state;
    return (
      <div
        onBlur={() => this.setState({ inFocusColumn: null, inFocusRow: null })}
        className={classes.checkListContainer}
      >
        {_.map(checklistItems, (item, index) => {
          return (
            <CheckListEditRow
              onColumnFocus={this.onColumnFocus}
              onRowFocus={this.onRowFocus}
              focusedColumn={inFocusColumn}
              setCellRef={this.setCellRef}
              setFocusedCell={this.setFocusedCell}
              focusedRow={inFocusRow}
              key={item.id}
              item={item}
              label={item.label}
              options={checklistOptionItems}
              index={index}
              minOptionCount={MIN_OPTION_COUNT}
              onOptionDeleteClick={onOptionDeleteClick}
              onItemDeleteClick={onItemDeleteClick}
              id={item.id}
              writeCheckListElement={writeCheckListElement}
            />
          );
        })}
      </div>
    );
  }
}
class CheckListEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showToolList: false };
    if (props.customRef) {
      props.customRef(this);
    }
    this.checklistComp = null;
  }

  blur = () => {
    if (this.checklistComp) {
      this.checklistComp.blur();
    }
  };

  onCopyTool = params => {
    const { onCopyTool } = this.props;
    onCopyTool(params);
    this.setState({ showToolList: false });
  };
  writeCheckListElement = ({ id, type, label, elementDetails }) => {
    const { updateField, writeChecklistElementLocal } = this.props;

    writeChecklistElementLocal({ id, type, label, elementDetails });
    updateField({
      key: id,
      params: { id, type, label },
      type: "CHECKLIST_ELEMENT",
    });
  };

  createEmptyCheckListItem = () => {
    const {
      createChecklistItem,
      setScrollToBottom,
      checklist: { id: checklistId },
    } = this.props;
    createChecklistItem({ label: "", checklistId });
    if (setScrollToBottom) {
      setTimeout(setScrollToBottom);
    }
  };

  createEmptyCheckListOptionItem = () => {
    const {
      createChecklistOptionItem,
      checklist: { id: checklistId },
    } = this.props;
    createChecklistOptionItem({ label: "", checklistId });
  };

  onOptionDeleteClick = optionId => {
    const {
      deleteChecklistOption,
      checklist: { id: checklistId },
    } = this.props;

    deleteChecklistOption({ checklistId, optionId });
  };

  onItemDeleteClick = item => {
    const {
      deleteChecklistItem,
      checklist: { id: checklistId, checklistItems },
    } = this.props;
    if (checklistItems.length == 1) {
      this.writeCheckListElement({
        id: item.id,
        type: "CHECKLIST_ITEM",
        label: "",
        elementDetails: item,
      });
    } else {
      deleteChecklistItem({ checklistId, itemId: item.id });
    }
  };

  shouldOptionAddButtonDisabled = () => {
    const { checklistOptionItems } = this.props.checklist || {};

    return (
      !!checklistOptionItems && checklistOptionItems.length >= MAX_OPTION_COUNT
    );
  };
  render() {
    const {
      checklist,
      onClickToolSave,
      containerStyle,
      t,
      showSaveTemplateButton,
    } = this.props;

    if (!checklist) {
      return <div className={classes.emptyText}>No Checklist defined</div>;
    }

    const { checklistItems, checklistOptionItems } = checklist || {};

    const isOptionAddButtonDisabled = this.shouldOptionAddButtonDisabled();
    return (
      <div className={classes.container} style={containerStyle}>
        <CheckListComp
          ref={ref => (this.checklistComp = ref)}
          createEmptyCheckListItem={this.createEmptyCheckListItem}
          checklistItems={checklistItems}
          checklistOptionItems={checklistOptionItems}
          onOptionDeleteClick={this.onOptionDeleteClick}
          onItemDeleteClick={this.onItemDeleteClick}
          writeCheckListElement={this.writeCheckListElement}
        />

        <div className={classes.buttonContainer}>
          <div className={classes.borderLeftContainer}>
            <Button
              variant={"outlined-subtle"}
              size={"medium"}
              onClick={this.createEmptyCheckListItem}
              icon={<AddOutlined />}
            >
              {t("common:add_with_label", {
                label: t("common:lowercase", {
                  text: t("common:criteria"),
                }),
              })}
            </Button>
            <Button
              variant={"outlined-subtle"}
              size={"medium"}
              onClick={this.createEmptyCheckListOptionItem}
              icon={<AddOutlined />}
              disabled={isOptionAddButtonDisabled}
            >
              {t("common:add_with_label", {
                label: t("common:lowercase", {
                  text: t("common:option"),
                }),
              })}
            </Button>
          </div>

          {showSaveTemplateButton && (
            <div
              className={classes.saveTemplateButton}
              onClick={onClickToolSave}
            >
              {t("common:save_this_as_this", {
                from_label: t("common:checklist"),
                to_label: t("common:template"),
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapActionCreators = {
  createChecklistItem,
  createChecklistOptionItem,
  deleteChecklistOption,
  deleteChecklistItem,
  writeChecklistElementLocal,
  updateField,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

CheckListEdit.defaultProps = {
  showSaveTemplateButton: true,
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators)
)(CheckListEdit);
