import React from "react";
import {
  subscribeToPresenceChannel,
  unSubscribeToPresenceChannel,
  getChannelInstance,
  updateStateToPresenceChannel,
  TYPE_TO_PRESENCE_EVENTS_MAPPING,
} from "modules/PusherModule";
import { connect } from "react-redux";
import { PresenceEvent } from "Constants";
import { getHOCDisplayName } from "Utils";

const PresenseChannelHoc = channelType => {
  return WrappedComponent => {
    class WithPresenseChannel extends React.PureComponent {
      componentDidMount = () => {
        const {
          pusherData,
          subscribeToPresenceChannel,
          hasPusherPermission,
        } = this.props;
        if (hasPusherPermission) {
          this.channel = getChannelInstance({ data: pusherData, channelType });
          if (this.channel) {
            this.event = TYPE_TO_PRESENCE_EVENTS_MAPPING[channelType];

            subscribeToPresenceChannel({
              channel: this.channel,
              channelType,
            });

            PresenceEvent.on(this.event, this.updateStateToPresenceChannel);
          }
        }
      };

      updateStateToPresenceChannel = data => {
        const {
          updateStateToPresenceChannel,
          hasPusherPermission,
        } = this.props;
        if (hasPusherPermission) {
          const { isFocused, focusedField } = data;
          if (updateStateToPresenceChannel) {
            let lockedField = null;
            if (isFocused) {
              lockedField = focusedField;
            }
            updateStateToPresenceChannel({
              channel: this.channel,
              lockedField,
            });
          }
        }
      };

      componentWillUnmount = () => {
        const {
          unSubscribeToPresenceChannel,
          hasPusherPermission,
        } = this.props;
        if (hasPusherPermission) {
          unSubscribeToPresenceChannel({ channel: this.channel });
          PresenceEvent.off(this.event, this.updateStateToPresenceChannel);
        }
      };

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    const mapActionCreators = {
      subscribeToPresenceChannel,
      unSubscribeToPresenceChannel,
      updateStateToPresenceChannel,
    };

    const mapStateToProps = (state, ownProps) => {
      return {
        hasPusherPermission: state.pusher.hasPusherPermission,
      };
    };
    WithPresenseChannel.displayName = `WithPresenseChannel(${getHOCDisplayName(
      WrappedComponent
    )})`;
    return connect(mapStateToProps, mapActionCreators)(WithPresenseChannel);
  };
};

export default PresenseChannelHoc;
