import React from "react";
import classes from "./TemplateAttachment.scss";
import { getThumbUrl, getGoogleFileType } from "Utils";
import { LockIconV2, FeedbackResponseIcon } from "SvgComponents";
import { downloadFromUrl } from "modules/Services";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { I18nHOC, LinkWithTooltip } from "UIComponents";
import classNames from "classnames";
import ACLStore from "lib/aclStore";
import { HiddenIfPreviousYear } from "AppComponents";
import {
  IconButton,
  MediaCard,
  Button,
  Dropdown,
  DropdownMenu,
} from "@toddle-design/web";
import {
  DownloadCloudOutlined,
  JournalAddOutlined,
  MoreVerticalDotsOutlined,
  DownloadOutlined,
  RefreshArrowOutlined,
  AddOutlined,
  PencilOutlined,
} from "@toddle-design/web-icons";
import { getSettingValue } from "modules/PermissionModule";
import { colors } from "@toddle-design/theme";

const styles = {
  templateActionBtnStyle: { marginLeft: "16px" },
};

const getText = ({ shouldShowEditResponse, userType, t, canTurnIn }) => {
  switch (userType) {
    case "staff":
      return t("classRoom:view_template");

    case "student":
      return canTurnIn ? (
        shouldShowEditResponse ? (
          <div className={classes.templateActionBtn}>
            <PencilOutlined /> {t("classRoom:edit_response")}
          </div>
        ) : (
          <div className={classes.templateActionBtn}>
            <AddOutlined /> {t("classRoom:add_response")}
          </div>
        )
      ) : shouldShowEditResponse ? (
        t("classRoom:view_response")
      ) : (
        t("classRoom:view_template")
      );

    case "parent":
      return shouldShowEditResponse
        ? t("classRoom:view_response")
        : t("classRoom:view_template");
  }
};

const TemplateActionButton = React.memo(props => {
  const {
    showTemplateActionButton,
    onUseTemplateClick,
    canTurnIn,
    userType,
    shouldShowEditResponse,
    t,
    attachment,
    buttonVariant,
    buttonSize,
    showAddToJournal,
    onClickCreatePost,
    disableTemplateButton,
  } = props;

  return (
    <div className={classes.templateButtonsContainer}>
      {showAddToJournal && shouldShowEditResponse && (
        <IconButton
          onClick={onClickCreatePost}
          icon={<JournalAddOutlined />}
          variant={"plain"}
          size={"medium"}
        />
      )}
      {showTemplateActionButton ? (
        <HiddenIfPreviousYear>
          <div
            className={classes.templateActionButton}
            style={styles.templateActionBtnStyle}
          >
            <Button
              size={"medium"}
              variant={"outlined"}
              onClick={onUseTemplateClick}
              disabled={disableTemplateButton}
            >
              {getText({
                shouldShowEditResponse,
                userType,
                t,
                canTurnIn,
              })}
            </Button>
          </div>
        </HiddenIfPreviousYear>
      ) : (
        _.get(attachment, "type", "") === "WORKBOOK" && (
          <HiddenIfPreviousYear>
            <div
              className={classes.templateActionButton}
              style={styles.templateActionBtnStyle}
            >
              <Button
                size={buttonSize}
                variant={buttonVariant}
                onClick={onUseTemplateClick}
                disabled={false}
              >
                {getText({
                  shouldShowEditResponse,
                  userType,
                  t,
                  canTurnIn,
                })}
              </Button>
            </div>
          </HiddenIfPreviousYear>
        )
      )}
    </div>
  );
});

TemplateActionButton.displayName = "TemplateActionButton";

const TemplateAttachment = ({
  attachment,
  onUseTemplateClick,
  downloadFromUrl,
  t,
  canTurnIn,
  shouldShowTemplateToParent,
  userType,
  shouldShowEditResponse,
  wrapperStyle,
  showMediaCard,
  attachmentBottomComponent,
  onClickCreatePost,
  showAddToJournal,
  isFeedbackGiven,
  submittedTemplate,
  resetStudentTemplate,
  isSubmitted,
  isScheduled,
  isArchivedClass,
}) => {
  // get updated attachment from submittedTemplate when student has added response
  const submittedTemplateAttachment = _.first(
    _.get(submittedTemplate, "attachments", [])
  );
  const templateAttachment = !_.isEmpty(submittedTemplateAttachment)
    ? submittedTemplateAttachment
    : attachment;

  const { thumbUrl, color, isIcon } = getThumbUrl({
    attachment: templateAttachment,
  });
  const isPermission = !!_.get(attachment, "metadata.permission");
  const isLinkType = _.get(attachment, "type", "") === "LINK";
  const isWorkbookType = _.get(attachment, "type", "") === "WORKBOOK";
  const workbookPageCount =
    isWorkbookType && _.get(attachment, "metadata.numOfPages", 0);

  const workbookPageCountLabel = workbookPageCount
    ? t("common:workbook_page", {
        count: workbookPageCount,
      })
    : "";

  let fileType = _.get(attachment, "type");
  if (isLinkType) {
    fileType = getGoogleFileType(_.get(attachment, "mimeType", "")) || fileType;
  }
  const isParent = userType === "parent";

  const readOnlyMode = isParent && isLinkType && !shouldShowTemplateToParent;

  const containerClass = classNames(
    { [classes.container]: true },
    { [classes.disabledContainer]: readOnlyMode }
  );

  const shouldUseWorkbook = ACLStore.can("FeatureFlag:Workbook");

  const showTemplateActionButton =
    (isPermission || shouldUseWorkbook) &&
    (!isParent ||
      shouldShowTemplateToParent ||
      _.get(attachment, "type", "") === "IMAGE");

  const showSimilarityIndex = userType === "student" && shouldShowEditResponse;

  const serviceType = _.get(attachment, "metadata.serviceType");
  const isFromGoogleDrive = _.isEqual(serviceType, "GOOGLE");
  const disableTemplateButton =
    (isFromGoogleDrive &&
      (isScheduled || (isSubmitted && _.isEmpty(submittedTemplate)))) ||
    isArchivedClass;

  const templateActionOptions = [
    {
      key: "downloadTemplate",
      label: t("common:download_with_label", {
        label: t("common:template"),
      }),
      disabled: isWorkbookType,
      icon: <DownloadOutlined disabled={isWorkbookType} size="xx-small" />,
    },
    {
      key: "downloadResponse",
      label: t("common:download_with_label", {
        label: t("common:response"),
      }),
      disabled: !submittedTemplateAttachment || isWorkbookType,
      icon: (
        <DownloadOutlined
          disabled={!submittedTemplateAttachment || isWorkbookType}
          size="xx-small"
        />
      ),
    },
    {
      key: "resetTemplate",
      label: t("common:reset_with_label", {
        label: t("common:template"),
      }),
      disabled: !submittedTemplateAttachment || isSubmitted,
      icon: (
        <RefreshArrowOutlined
          disabled={!submittedTemplateAttachment || isSubmitted}
          size="xx-small"
        />
      ),
    },
  ];

  const onCardActionClick = action => {
    switch (action) {
      case "downloadTemplate":
        downloadFromUrl({
          attachment,
          shouldAskForSave: true,
        });
        break;
      case "downloadResponse":
        downloadFromUrl({
          attachment: submittedTemplateAttachment,
          shouldAskForSave: true,
        });
        break;
      case "resetTemplate":
        resetStudentTemplate({ submittedTemplate });
        break;
    }
  };

  const templateActions = () => {
    const onDropDownMenuClick = event => {
      event.domEvent.stopPropagation();
      const action = event.key;
      onCardActionClick(action);
    };

    const stopPropagationHandler = event => {
      event.stopPropagation();
    };

    return (
      <div
        onClick={stopPropagationHandler}
        className={classes.templateActionsContainer}
      >
        <TemplateActionButton
          showTemplateActionButton={showTemplateActionButton}
          onUseTemplateClick={onUseTemplateClick}
          canTurnIn={canTurnIn}
          userType={userType}
          shouldShowEditResponse={shouldShowEditResponse}
          t={t}
          attachment={attachment}
          buttonVariant={"outlined"}
          buttonSize={"medium"}
          showAddToJournal={showAddToJournal}
          onClickCreatePost={onClickCreatePost}
          disableTemplateButton={disableTemplateButton}
        />
        <Dropdown
          getPopupContainer={el => {
            return el;
          }}
          disabled={isLinkType}
          overlay={
            <DropdownMenu
              onClick={onDropDownMenuClick}
              options={templateActionOptions}
            />
          }
          placement={"bottomRight"}
        >
          <IconButton
            onClick={stopPropagationHandler}
            icon={
              <MoreVerticalDotsOutlined variant={"subtle"} size={"xx-small"} />
            }
            variant={"plain"}
          />
        </Dropdown>
      </div>
    );
  };

  const renderComponent = () => {
    const onMediaCardClick = event => {
      onUseTemplateClick(event, true);
    };

    return showMediaCard ? (
      <MediaCard
        cardType={"medium"}
        name={_.get(attachment, "name")}
        type={fileType}
        mimeType={_.get(attachment, "mimeType")}
        thumbUrl={thumbUrl}
        pageCount={workbookPageCountLabel}
        bottomElement={
          showSimilarityIndex ? attachmentBottomComponent?.() : undefined
        }
        onClick={onMediaCardClick}
        options={templateActions()}
      />
    ) : (
      <div className={classes.wrapper} style={wrapperStyle}>
        {!!isFeedbackGiven && (
          <span className={classes.notificationCounter}>
            <FeedbackResponseIcon width={16} height={16} fill={colors.white} />
          </span>
        )}
        <div
          className={classes.attachmentIconContainer}
          style={{
            backgroundImage: `url(${thumbUrl})`,
            backgroundColor: color,
            backgroundSize: isIcon ? "35%" : "cover",
          }}
        ></div>
        <div className={classes.attachmentInfo}>
          <div className={classes.attachmentName}>{attachment.name}</div>
          <div className={classes.attachmentType}>
            {isWorkbookType
              ? t("common:workbook_page", {
                  count: _.get(attachment, "metadata.numOfPages", 1),
                })
              : fileType}
          </div>
        </div>

        {showAddToJournal && shouldShowEditResponse && (
          <IconButton
            onClick={onClickCreatePost}
            icon={<JournalAddOutlined />}
            variant={"plain"}
            size={"medium"}
          />
        )}

        {/* Don't show download icon for like or workbook type attachments */}
        {!isLinkType && !isWorkbookType && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              downloadFromUrl({
                attachment: attachment,
                shouldAskForSave: true,
              });
            }}
            icon={<DownloadCloudOutlined />}
            variant={"plain"}
            size={"medium"}
          />
        )}
        {readOnlyMode && <LockIconV2 />}
        <TemplateActionButton
          showTemplateActionButton={showTemplateActionButton}
          onUseTemplateClick={onUseTemplateClick}
          canTurnIn={canTurnIn}
          userType={userType}
          shouldShowEditResponse={shouldShowEditResponse}
          t={t}
          attachment={attachment}
          buttonVariant={"outlined-subtle"}
          buttonSize={"large"}
          disableTemplateButton={disableTemplateButton}
        />
      </div>
    );
  };

  return (
    <div className={containerClass}>
      {readOnlyMode ? (
        <LinkWithTooltip
          tooltip={t("toastMsgs:document_sharing_permission")}
          placement={"topLeft"}
          href={null}
        >
          {renderComponent()}
        </LinkWithTooltip>
      ) : (
        renderComponent()
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userType = _.get(state, "login.userInfo.user_type");
  let shouldShowTemplateToParent;
  if (userType === "parent") {
    shouldShowTemplateToParent = getSettingValue({
      name: "UnlockFamilyStudentTemplate",
      courseId: ownProps.assignmentCourseId,
      organizationId: _.get(state, "login.userInfo.org_id"),
      userEntityType: _.get(state, "login.userInfo.userEntityType"),
    });
  }
  return {
    shouldShowTemplateToParent,
  };
};

const mapActionCreators = { downloadFromUrl };

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common", "classRoom", "toastMsgs"] })
)(TemplateAttachment);
