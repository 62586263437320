import React, { PureComponent } from "react";
import { CancelIcon, ArrowIcon } from "SvgComponents";
import {
  UIButton,
  UIModal,
  I18nHOC,
  ButtonDropdown,
  Loading,
  NoDataComponent,
} from "UIComponents";

import { getExportLinks, getDriveShareTemplateOptions } from "Utils";
import update from "immutability-helper";
import byteSize from "byte-size";
import classes from "./DriveShareModal.scss";
import PropTypes from "prop-types";
import { colors } from "Constants";
import { getExportTypeIndex } from "./DriveShareModalUtils";

const SHARE_MODAL_TYPES = {
  RESOURCE: {
    label: "common:sharing_with_google_drive",
    subText: "sharing_with_google_drive_msg",
  },
  STUDENT_TEMPLATE: {
    label: "common:student_template_drive_share_label",
  },
  STUDENT_DRIVE: {
    label: "common:add_from_google_drive",
    subText: "common:adding_from_google_drive_msg",
  },
};

const buttonDropdownStyles = {
  containerStyle: {
    top: 40,
    right: 0,
    minWidth: 200,
    maxWidth: 300,
    maxHeight: 300,
    overflowY: "auto",
  },
};

class DriveShareModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    const {
      files,
      accessToken,
      sourceType,
      onClickClose,
      t,
      defaultExportType,
      attachmentExportOptions,
    } = this.props;

    this.setState({ isLoading: true });

    const requests = _.map(files, async file => {
      let exportOptions = [];
      let exportAs = "";
      let permission = "";
      let exportTypeIndex = -1;
      switch (sourceType) {
        case "STUDENT_TEMPLATE":
          {
            exportOptions = getDriveShareTemplateOptions({ file });

            exportTypeIndex = getExportTypeIndex({
              exportOptions,
              exportType: defaultExportType,
            });

            exportAs = _.get(exportOptions, `[${exportTypeIndex}]exportAs`, "");
            permission = _.get(exportOptions, `[${exportTypeIndex}]value`, "");
          }
          break;
        default: {
          exportOptions = await getExportLinks({
            file,
            accessToken,
          });

          if (!_.isEmpty(attachmentExportOptions)) {
            exportOptions = _.filter(exportOptions, option =>
              _.includes(attachmentExportOptions, option.value)
            );
          }

          exportTypeIndex = getExportTypeIndex({
            exportOptions,
            exportType: defaultExportType,
          });

          exportAs = _.get(exportOptions, `[${exportTypeIndex}]value`, "");
        }
      }
      return {
        ...file,
        exportOptions: _.map(exportOptions, option => {
          return { ...(option || {}), label: t(option.label) };
        }),
        url: _.get(exportOptions, `[${exportTypeIndex}]url`, ""),
        mimeType: _.get(exportOptions, `[${exportTypeIndex}]mimeType`, ""),
        exportAs,
        value: _.get(exportOptions, `[${exportTypeIndex}]value`, ""),
        permission,
      };
    });

    Promise.all(requests)
      .then(files => this.setState({ files, isLoading: false }))
      .catch(err => {
        // console.log("err", err);
        onClickClose();
      });
  };

  onDropdownItemClick = ({ exportFormat, id }) => {
    const { sourceType } = this.props;
    const { files } = this.state;
    const fileIndex = _.findIndex(files, { id });

    let { value, url, mimeType, exportAs } = _.find(
      files[fileIndex].exportOptions,
      {
        value: exportFormat,
      }
    );

    //value as export template in source type RESOURCE
    if (sourceType != "STUDENT_TEMPLATE") {
      exportAs = value;
    }

    let permission = undefined;
    //check permission from dropdown
    if (sourceType == "STUDENT_TEMPLATE" && exportAs != "FILE") {
      permission = value;
    }

    this.setState({
      files: update(files, {
        [fileIndex]: {
          value: { $set: value },
          exportAs: { $set: exportAs },
          url: { $set: url },
          mimeType: { $set: mimeType },
          permission: { $set: permission },
        },
      }),
    });
  };

  removeSelectedDocument = ({ id }) => {
    const { files } = this.state;
    const fileIndex = _.findIndex(files, file => file.id == id);

    this.setState({ files: update(files, { $splice: [[fileIndex, 1]] }) });
  };

  onClickDone = e => {
    e.stopPropagation();

    const { processChosenFiles, onClickClose } = this.props;
    const { files } = this.state;
    // console.log("Files", files);
    setTimeout(() => {
      processChosenFiles({ files });
    }, 100);

    onClickClose();
  };

  onModalClick = e => {
    e.stopPropagation();
  };

  getButtonComponnent = option => {
    return (
      <div className={classes.filterBox}>
        <div className={classes.filterText}>{`${option?.label}`}</div>
        <div className={classes.bottomArrow}>
          <ArrowIcon width={10} height={5} />
        </div>
      </div>
    );
  };

  render() {
    const { t, onClickClose, sourceType } = this.props;
    const { files, isLoading } = this.state;
    const { label, subText } = _.get(SHARE_MODAL_TYPES, sourceType, {});
    const getIsSingleImportOptionForAllFiles = _.memoize(
      () => !_.size(_.filter(files, file => _.size(file?.exportOptions) > 1))
    );
    return (
      <UIModal isOpen={true} modalContent={classes.modalContent}>
        <div className={classes.headerContainer} onClick={this.onModalClick}>
          <div className={classes.headerText}>{t(label)}</div>
          {!getIsSingleImportOptionForAllFiles() && (
            <div className={classes.headerSubtext}>{t(subText)}</div>
          )}
        </div>
        <div className={classes.bodyContainer} onClick={this.onModalClick}>
          {_.isEmpty(files) && !isLoading && (
            <NoDataComponent
              emptyText=""
              isSearch={false}
              emptyHeaderText={t("classRoom:no_templates_selected")}
            />
          )}
          {!_.isEmpty(files) && (
            <>
              <div className={classes.fileCountContainer}>
                <div className={classes.fileCountText}>
                  {t("educatorCenter:file_selected", {
                    count: _.get(files, "length", 0),
                  })}
                </div>
              </div>
              <div className={classes.scrollContainer}>
                {_.map(files, file => {
                  // Disable if export option is limited to one
                  const isDropdownDisabled =
                    _.size(_.get(file, "exportOptions")) <= 1;

                  return (
                    <div className={classes.fileContainer} key={file.id}>
                      <div className={classes.leftFileContainer}>
                        <div className={classes.fileIconContainer}>
                          <img
                            src={file.iconUrl}
                            className={classes.fileIcon}
                          />
                        </div>
                        <div className={classes.fileDetails}>
                          <div className={classes.fileName}>{file.name}</div>
                          <div className={classes.fileType}>
                            <span>{_.upperFirst(file.type)}</span>
                            {!_.isEmpty(file.sizeBytes) && (
                              <span>`(${byteSize(file.sizeBytes)})`</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.rightFileContainer}>
                        <div className={classes.dropdownContainer}>
                          <ButtonDropdown
                            disabled={isDropdownDisabled}
                            buttonParentStyle={
                              isDropdownDisabled
                                ? {
                                    backgroundColor: colors.toddleBG1,
                                    borderColor: colors.toddleBG1,
                                  }
                                : {}
                            }
                            authTabs={_.get(file, "exportOptions", [])}
                            onItemClick={value =>
                              this.onDropdownItemClick({
                                exportFormat: value,
                                id: file.id,
                              })
                            }
                            {...buttonDropdownStyles}
                            buttonComponent={this.getButtonComponnent(
                              _.find(_.get(file, "exportOptions", []), {
                                value: file.value,
                              })
                            )}
                          />
                        </div>
                        <div
                          className={classes.cancelButton}
                          onClick={() =>
                            this.removeSelectedDocument({ id: file.id })
                          }
                        >
                          <CancelIcon width={12} height={12} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {isLoading && <Loading />}
        </div>

        <div className={classes.footerContainer}>
          <div className={classes.rightButtonContainer}>
            <UIButton
              color={"grey"}
              size={"sm"}
              type={"filled"}
              onClick={onClickClose}
            >
              {t("common:cancel")}
            </UIButton>
            <UIButton
              color={"pink"}
              size={"sm"}
              type={"filled"}
              onClick={this.onClickDone}
            >
              {t("common:done")}
            </UIButton>
          </div>
        </div>
      </UIModal>
    );
  }
}

DriveShareModal.propTypes = {
  files: PropTypes.array,
  onClickClose: PropTypes.func,
  processChosenFiles: PropTypes.func,
};

DriveShareModal.defaultProps = {
  files: [],
  sourceType: "RESOURCE",
};

export default I18nHOC({ resource: ["common", "educatorCenter", "classRoom"] })(
  DriveShareModal
);
