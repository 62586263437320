import { ROLES_MAP } from "./constants";

export const routes = {
  projects: {
    path: "projects",
    name: "project:personal_project_card",
    /**
     * @description landing page based on current role
     */
    redirects: {
      [ROLES_MAP.coordinator.value]: "setup",
      [ROLES_MAP.student.value]: "progress",
      [ROLES_MAP.supervisor.value]: "progress",
    },
  },
  overview: {
    path: "overview",
    name: "common:overview",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  projectGroup: {
    path: ":projectGroup",
    name: "",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  projectDetails: {
    path: ":projectId",
    name: "",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  projectReport: {
    path: "report",
    name: "",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  projectDetailsStep: {
    path: ":stepId",
    name: "",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  setup: {
    path: "setup",
    name: "project:setup",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: false,
      [ROLES_MAP.supervisor.value]: false,
    },
  },

  stimuliSetup: {
    path: "stimuliSetup",
    name: "project:setup",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: false,
      [ROLES_MAP.supervisor.value]: false,
    },
  },
  progress: {
    path: "progress",
    name: "project:student_progress",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  casInsights: {
    path: "casInsights",
    name: "project:insights",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: false,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  deadlines: {
    path: "deadlines",
    name: "project:deadline_plural",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: false,
      [ROLES_MAP.supervisor.value]: false,
    },
  },
  guidence: {
    path: "guidance/:type",
    name: "project:guidance",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  establishDeadlines: {
    path: "establish-deadlines",
    name: "project:establish_deadline",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: false,
      [ROLES_MAP.student.value]: false,
    },
  },
  invite: {
    path: "invite",
    name: "project:invite",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: false,
      [ROLES_MAP.student.value]: false,
    },
  },
  initiateProject: {
    path: "initiate-project",
    name: "project:initiate_project",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: true,
      [ROLES_MAP.student.value]: true,
    },
  },
  createProject: {
    path: "create-project",
    name: "project:create_project",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: true,
      [ROLES_MAP.student.value]: true,
    },
  },
  introduction: {
    path: "introduction",
    name: "project:introduction",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: false,
      [ROLES_MAP.student.value]: false,
    },
  },
  reportTemplate: {
    path: "report-template",
    name: "project:report_template",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: true,
      [ROLES_MAP.student.value]: true,
    },
  },
  guidingQuestions: {
    path: "guiding-questions",
    name: "project:reflectionQuestion_plural",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.supervisor.value]: true,
      [ROLES_MAP.student.value]: false,
    },
  },
  serviceAsActionInsights: {
    path: "insights",
    name: "project:serviceAsAction_insights",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: false,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  portfolios: {
    path: "portfolios",
    name: "project:cas_portfolios",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: false,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  portfolioDetails: {
    path: ":portfolioId",
    name: "project:portfolios",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
  rppf: {
    path: "rppf",
    name: "project:rppf",
    access: {
      [ROLES_MAP.coordinator.value]: true,
      [ROLES_MAP.student.value]: true,
      [ROLES_MAP.supervisor.value]: true,
    },
  },
};
