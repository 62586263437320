import { getUserInfo } from "Login/modules/LoginModule";
import axios from "axios";
import _ from "lodash";
import { getUTMSourceFromStorage, getBackendServerUrl } from "Utils";
import { mixPanelClientId } from "config";

const eventHistory = {};
const loadTracker = (useProdKey = false) => {
  loadMixPanel(useProdKey);
  // loadKissMetrics(useProdKey);
};

const loadKissMetrics = useProdKey => {
  const key = useProdKey
    ? "027581c8ce638b1b2c7bf64e96eeb224c14bf271"
    : "631b8bc208fa6e69e0880a83fb66e0918f131034";
  var w = window;
  w._kmq = w._kmq || [];
  w._kmk = w._kmk || key;
  w._kms = function (u) {
    setTimeout(function () {
      var d = document,
        f = d.getElementsByTagName("script")[0],
        s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = u;
      f.parentNode.insertBefore(s, f);
    }, 1);
  };
  w._kms("//i.kissmetrics.com/i.js");
  w._kms("//scripts.kissmetrics.com/" + w._kmk + ".2.js");
};

const loadMixPanel = useProdKey => {
  const mixpanelkey = mixPanelClientId;
  const abz = function (c, a) {
    if (!a.__SV) {
      var b = window;
      try {
        var d,
          m,
          j,
          k = b.location,
          f = k.hash;
        d = function (a, b) {
          // eslint-disable-next-line no-cond-assign
          return (m = a.match(RegExp(b + "=([^&]*)"))) ? m[1] : null;
        };
        f &&
          d(f, "state") &&
          ((j = JSON.parse(decodeURIComponent(d(f, "state")))),
          "mpeditor" === j.action &&
            (b.sessionStorage.setItem("_mpcehash", f),
            history.replaceState(
              j.desiredHash || "",
              c.title,
              k.pathname + k.search
            )));
      } catch (n) {}
      var l, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function (b, d, g) {
        function c(b, i) {
          var a = i.split(".");
          2 == a.length && ((b = b[a[0]]), (i = a[1]));
          b[i] = function () {
            b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var e = a;
        "undefined" !== typeof g ? (e = a[g] = []) : (g = "mixpanel");
        e.people = e.people || [];
        e.toString = function (b) {
          var a = "mixpanel";
          "mixpanel" !== g && (a += "." + g);
          b || (a += " (stub)");
          return a;
        };
        e.people.toString = function () {
          return e.toString(1) + ".people (stub)";
        };
        l = "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(
          " "
        );
        for (h = 0; h < l.length; h++) c(e, l[h]);
        var f = "set set_once union unset remove delete".split(" ");
        e.get_group = function () {
          function a(c) {
            b[c] = function () {
              var call2_args = arguments;
              var call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
              e.push([d, call2]);
            };
          }
          for (
            var b = {},
              d = ["get_group"].concat(
                Array.prototype.slice.call(arguments, 0)
              ),
              c = 0;
            c < f.length;
            c++
          )
            a(f[c]);
          return b;
        };
        a._i.push([b, d, g]);
      };
      a.__SV = 1.2;
      b = c.createElement("script");
      b.type = "text/javascript";
      b.async = !0;
      b.src =
        "undefined" !== typeof window.MIXPANEL_CUSTOM_LIB_URL
          ? window.MIXPANEL_CUSTOM_LIB_URL
          : "file:" === c.location.protocol &&
            "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)
          ? "https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js"
          : "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";
      d = c.getElementsByTagName("script")[0];
      d.parentNode.insertBefore(b, d);
    }
  };

  abz(document, window.mixpanel || []);
  window.mixpanel.init(mixpanelkey);
};

const recordEvent = ({ eventName, eventData }) => {
  if (window.mixpanel) {
    const userInfo = getUserInfo({ portalType: "PLANNER" });
    const { org_id } = userInfo || {};
    const defaultEvent = getUTMSourceFromStorage();
    const utm_source = _.get(eventData, "utm_source", defaultEvent);
    const data = {
      ...(_.cloneDeep(eventData) || {}),
      school_id: org_id,
      utm_source,
    };
    window.mixpanel.track(eventName, data);
    eventHistory[eventName] = _.get(eventData, "target");
  }
  // if (window._kmq) {window._kmq.push(["record", eventName, _.cloneDeep(eventData)])};
  //recordEventInDB({ eventName, eventData });
};

const recordEventInDB = ({ eventName, eventData }) => {
  try {
    let anonymousId = "";
    let token = "";
    if (window.KM) {
      anonymousId = window.KM.i();
    }
    if (localStorage && localStorage.userInfo) {
      token = JSON.parse(localStorage.userInfo).token;
    }
    const requestObj = {
      eventName,
      eventData,
      isAnonymousUser: !token,
      anonymousId,
      token,
    };

    axios.post(
      getBackendServerUrl({
        token: token,
        path: "/auth/event/tracker",
      }),
      requestObj
    );
  } catch (e) {
    console.error("error", e);
  }
};

const identifyUser = ({ identityString }) => {
  //CAUTION: Should only be called after login

  //if (window._kmq) window._kmq.push(["identify", identityString]);
  //console.log("identifyUser");

  if (window.mixpanel) window.mixpanel.identify(identityString);
};
const aliasUser = ({ identityString }) => {
  //CAUTION: Should only be called once in the life time of a user (on Signup)
  // It is used to associate non-signed up events with identified events

  // if (window._kmq) window._kmq.push(["identify", identityString]);
  //console.log("aliasUser");

  if (window.mixpanel) window.mixpanel.alias(identityString);
};
const recordTrait = ({ data }) => {
  //if (window._kmq) window._kmq.push(["set", _.cloneDeep(data)]);
  //console.log("recordTrait");

  if (window.mixpanel) {
    const _data = _.cloneDeep(data);
    if (_data.email) _data["$email"] = _data.email; // Mixpanel special property
    if (_data.fname) _data["$first_name"] = _data.fname; // Mixpanel special property
    if (_data.lname) _data["$last_name"] = _data.lname; // Mixpanel special property
    window.mixpanel.people.set(_data);
  }
};

const recordTraitOnce = ({ data }) => {
  //if (window._kmq) window._kmq.push(["set", _.cloneDeep(data)]);
  //console.log("recordTraitOnce");

  if (window.mixpanel) {
    const _data = _.cloneDeep(data);
    window.mixpanel.people.set_once(_data);
  }
};

const recordRegister = ({ data }) => {
  //console.log("recordRegister");

  if (window.mixpanel) {
    const _data = _.cloneDeep(data);
    window.mixpanel.register(_data);
  }
};

/**
 * @deprecated
 */
const setOrganization = ({ data }) => {
  //console.log("setOrganization");

  if (window.mixpanel) {
    const { org_id, orgPlan, org_name } = data || {};
    window.mixpanel
      .get_group("school_id", org_id)
      .set({ plan: orgPlan, name: org_name, id: org_id });
    window.mixpanel.add_group("school_id", org_id);
  }
};

const clearIdentity = () => {
  // if (window._kmq) {
  //   window._kmq.push(["clearIdentity"]);
  // }
  //console.log("clearIdentity");
  if (window.mixpanel) {
    window.mixpanel.reset();
  }
};

export {
  loadTracker,
  recordEvent,
  identifyUser,
  recordTrait,
  clearIdentity,
  aliasUser,
  recordTraitOnce,
  recordRegister,
  setOrganization,
  eventHistory,
};
