import React from "react";
import classes from "./UpdateOrgAcademicYears.scss";
import { graphql, compose } from "react-apollo";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import {
  UIButton,
  I18nHOC,
  Loading,
  withLoader,
  UIModal,
  FullScreenLoader,
  DateSelector,
} from "UIComponents";
import { getAcademicYears } from "Tooling/modules/ToolingQueries";
import { EditIcon, TickPlain } from "SvgComponents";
import {
  updateOrganizationAcademicYear,
  createOrganizationAcademicYear,
} from "Tooling/modules/ToolingModule";
import { getAcademicYearsFromCache } from "Tooling/modules/ToolingGraphqlHelpers";
const style = {
  currentYear: {
    backgroundColor: "#D1F2EB",
  },
  newYear: {
    backgroundColor: "#FEF9E7",
  },
};

class UpdateOrgAcademicYears extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      academicYears: props.academicYears,
      isFullScreenLoading: false,
      mode: "",
      orgId: this.props.orgID,
    };
    this.scrollContainer = React.createRef();
    this.EditYearId = "";
  }

  close = () => {
    this.props.closeModal();
  };

  updateOrgObject = param => {
    const obj = [...this.state.academicYears];
    const currentObj = _.find(obj, { id: this.EditYearId }); // finding object from array to update
    if (_.has(param, "startDate")) {
      if (_.isEqual(this.state.mode, "add")) {
        param["endDate"] = moment(param["startDate"])
          .add(1, "year")
          .subtract(1, "day");
        currentObj.endDate = moment(param.endDate).format("YYYY-MM-DD");
      }
      currentObj.startDate = moment(param.startDate).format("YYYY-MM-DD");
    } else currentObj.endDate = moment(param.endDate).format("YYYY-MM-DD");
    this.setState({ academicYears: [...(obj || {})] });
  };

  onDisable = id => {
    this.EditYearId = id;
    const obj = [...this.state.academicYears];
    return obj;
  };

  onEdit = param => {
    const obj = this.onDisable(param.id); // passing Academic ID as for updating existing Academic Year
    this.setState({ academicYears: [...(obj || {})], mode: "edit" });
  };

  addNewAcademicYear = () => {
    setTimeout(() => {
      this.scrollContainer.scrollTop = 0;
    }, 300);
    const newYearObj = {
      id: this.state.orgId,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().subtract(1, "days").add(1, "year").format("YYYY-MM-DD"),
    };
    let obj = this.onDisable(newYearObj.id); // passing Organization ID as for creating new Academic Year
    obj.unshift(newYearObj);
    this.setState({ academicYears: [...(obj || {})], mode: "add" });
  };

  onSave = async param => {
    const { mode, orgId } = this.state;
    this.setState({ isFullScreenLoading: true });
    let response = "";
    param = _.omit(param, ["isCurrentAcademicYear", "__typename"]);
    _.isEqual(mode, "add")
      ? (response = await this.props.createOrganizationAcademicYear(param))
      : (response = await this.props.updateOrganizationAcademicYear(
          param,
          (param.orgId = orgId)
        ));
    this.EditYearId = "";
    this.setState({
      academicYears: response || undefined,
      isFullScreenLoading: false,
      mode: "",
    });
  };

  onFocusDateSelector = () => {
    setTimeout(() => {
      const calendarEle = _.first(
        document.getElementsByClassName("SingleDatePicker_picker")
      );

      if (calendarEle) {
        const topPosition =
          this.scrollContainer.scrollTop +
          (calendarEle.getBoundingClientRect().top -
            this.scrollContainer.getBoundingClientRect().top) -
          100;

        this.scrollContainer.scrollTop = topPosition;
      }
    }, 300);
  };

  renderAcademicYears = () => {
    const { academicYears, orgId } = this.state;
    return _.map(academicYears, academicYear => {
      const { id, endDate, startDate, isCurrentAcademicYear } = academicYear;
      return (
        // <div key={id} className={classes.horizontalRow, isCurrentAcademicYear ? classes.currentYearRow : null}>
        <div
          key={id}
          className={classes.horizontalRow}
          style={
            isCurrentAcademicYear
              ? style.currentYear
              : _.isEqual(this.EditYearId, id)
              ? style.newYear
              : null
          }
        >
          <div className={classes.horizontalInputElement}>
            <DateSelector
              label={
                isCurrentAcademicYear
                  ? "Current Academic Year Start Date"
                  : _.isEqual(orgId, id)
                  ? "New Academic Year Start Date"
                  : "Academic Year Start Date"
              }
              name={"startDate"}
              value={startDate}
              isDisabled={_.isEqual(this.EditYearId, id) ? false : true}
              updateInputField={this.updateOrgObject}
              onFocusInputField={this.onFocusDateSelector}
            />
          </div>
          <div className={classes.horizontalInputElement}>
            <DateSelector
              label={
                isCurrentAcademicYear
                  ? "Current Academic Year End Date"
                  : _.isEqual(orgId, id)
                  ? "New Academic Year End Date"
                  : "Academic Year End Date"
              }
              name={"endDate"}
              value={endDate}
              isDisabled={_.isEqual(this.EditYearId, id) ? false : true}
              minDate={moment(startDate).add(1, "day").format("YYYY-MM-DD")}
              updateInputField={this.updateOrgObject}
              onFocusInputField={this.onFocusDateSelector}
            />
          </div>
          {!_.isEqual(this.EditYearId, id) ? (
            <div
              onClick={() => this.onEdit(academicYear)}
              className={classes.horizontalIconElement}
            >
              <EditIcon />
            </div>
          ) : (
            <div
              onClick={() => this.onSave(academicYear)}
              className={classes.horizontalIconElement}
            >
              <TickPlain />
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const { loading } = this.props;
    const { isFullScreenLoading, mode } = this.state;
    return (
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>{`Manage academic years`}</div>
        <div className={classes.br}></div>
        <div
          className={classes.stepContainer}
          ref={ref => (this.scrollContainer = ref)}
        >
          {this.renderAcademicYears()}
          <div className={classes.editStep2}>
            {loading ? (
              <div className={classes.loadingOverlay}>
                <Loading />
              </div>
            ) : null}
            <div className={classes.modalFooter}>
              <UIButton
                type="hollow"
                color="grey"
                size="sm"
                onClick={this.close}
              >
                {`Close`}
              </UIButton>
              <div className={classes.lastButton}>
                <UIButton
                  color="blue"
                  size="sm"
                  onClick={this.addNewAcademicYear}
                  isDisabled={!_.isEmpty(mode)}
                >
                  {"Add"}
                </UIButton>
              </div>
            </div>
          </div>
        </div>
        {!!isFullScreenLoading && <FullScreenLoader />}
      </div>
    );
  }
}
const EditModalWithLoading = withLoader(UpdateOrgAcademicYears);

const UpdateOrgAcademicYearsComp = React.memo(props => {
  const { closeModal } = props;
  return (
    <UIModal
      isOpen={true}
      onRequestClose={closeModal}
      modalContent={classes.modalContent}
    >
      <EditModalWithLoading {...props} />
    </UIModal>
  );
});

UpdateOrgAcademicYearsComp.displayName = "UpdateOrgAcademicYearsComp";

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    id: ownProps.orgDetail.id,
  };
};

const mapActionCreators = {
  updateOrganizationAcademicYear,
  createOrganizationAcademicYear,
};

export default compose(
  I18nHOC({ resource: ["schoolSetup", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getAcademicYears, {
    name: "getAcademicYears",
    options: ({ orgDetail }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: orgDetail.id },
    }),
    props: ({ getAcademicYears, ownProps: { id, isData, isLoading } }) => {
      const academicYearsData = getAcademicYearsFromCache(id);
      const academicYears = _.get(academicYearsData, "academicYears", {});
      const orgID = _.get(academicYearsData, "id", {});
      return {
        academicYears,
        orgID,
        isData: !_.isEmpty(academicYears) && isData,
        isLoading:
          getAcademicYears["networkStatus"] == 1 ||
          getAcademicYears["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(UpdateOrgAcademicYearsComp);
