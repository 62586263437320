import React from "react";
import classes from "./MoreFiltersBar.scss";
import { useI18n } from "Hooks";
import PropTypes from "prop-types";

/**
 * This Component is used to display the selected more filters in the bar.
 * Example can be seen in classroom - Import Learning Experience from LELibrary Modal
 *
 *
 * @param selectedMoreFilters -  An array of Selected more filters
 * 
 * Ex : [
      {
        id: FILTER_NAME,
        name: FILTER_NAME,
        options: filterOptions,
        label: "common:label",
        showSearchBar: false
      },
      {
        id: FILTER_NAME,
        name: FILTER_NAME,
        options: filterOptions,
        label: "common:label",
        showSearchBar: false
      }

 * @param getFilterDropdown : Function which takes filter, filter value, update filterValue as 
 * input and returns FilterDropDown
 * 
 * Ex : const getFilterDropdown = ({filter,onChangeFilterValues,filterValues}) => {
                return (
                        <FilterDropdown
                        key={filter.id}
                        name={filter.name}
                        options={filter.options}
                        label={filter.label}
                        showSearchBar={filter.showSearchBar}
                        isCheckList={filter.isCheckList}
                        showAllOption={filter.showAllOption}
                        updateInputField={onChangeFilterValues}
                        value={_.get(filterValues, `${filter.name}`, [])}
                        isSearchBasedOnQuery={filter.isSearchBasedOnQuery}
                        organizationId={filter.organizationId}
                        graphqlQueryEnhancer={filter.graphqlQueryEnhancer}
                        filterBoxStyle={styles.filterBoxStyle}
                        buttonDropDownContainerStyle={styles.buttonDropDownContainerStyle}
                        arrowContainerStyle={styles.arrowContainerStyle}
                        nameTextStyle={styles.nameTextStyle}
                        filterTextStyle={styles.filterTextStyle}
                        emptyTextStyle={styles.nameTextStyle}
                        />
                      );
                    };
                    
 * @param filterValues - An object with filter names as keys and selected filter values as values
 *
 * Ex : [
 *      {filterOne : [1, 2, 3]
 *      filterTwo : ["ALL"]}
 *      ]
 * 
 * @param onChangeFilterValues : Function to update filter Values
 * @param removeFilter : Function used to remove filter on clicking close icon
 *
 *
 */

const MoreFiltersBar = props => {
  const {
    selectedMoreFilters,
    getFilterDropdown,
    filterValues,
    onChangeFilterValues,
    groupType,
  } = props;

  const { t } = useI18n(["common", "snp"]);

  return (
    <div className={classes.moreFiltersContainer}>
      {_.map(selectedMoreFilters, filter => {
        return (
          <div className={classes.filter}>
            {getFilterDropdown({
              searchPlaceholder: t("common:search"),
              filter,
              filterValues,
              onChangeFilterValues,
              t,
              groupType,
            })}
          </div>
        );
      })}
    </div>
  );
};

MoreFiltersBar.propTypes = {
  selectedMoreFilters: PropTypes.Array,
  filterValues: PropTypes.object,
  onChangeFilterValues: PropTypes.func,
  getFilterDropdown: PropTypes.func,
  removeFilter: PropTypes.func,
};

MoreFiltersBar.defaultProps = {
  selectedMoreFilters: [],
  filterValues: {},
};

export default MoreFiltersBar;
