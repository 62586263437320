import React from "react";
import classes from "./LearningStandards.scss";
import classNames from "classnames";
import { colors } from "Constants";
import { getHTMLLabel } from "NodeEditor/Utils";
import { Checkbox } from "@toddle-design/web";
import { useI18n } from "Hooks";
import {
  WarningTriangleOutlined,
  RightArrowOutlined,
  LeftArrowOutlined,
  DragDotsOutlined,
  AddCircleOutlined,
} from "@toddle-design/web-icons";
import { LinkWithTooltip } from "UIComponents";

const CELL_NAME = "label";

const styles = {
  disableStyle: { fill: colors.gray72, cursor: "not-allowed" },
};

const getStandardsRowStyleMemoize = _.memoize(
  params => getStandardsRowStyle(params),
  params => JSON.stringify(params)
);

const getStandardsRowStyle = ({
  depth,
  showSelectionBox,
  mode,
  currentEditorConfig,
}) => {
  /**
   * Condition  for showSelectionBox is added for Unit Plan SNS editor
   * Mode === Edit condition will be applied for Admin Portal SNS editor
   * _.get(currentEditorConfig, "showSelectionBox", false) condtion will be applied for Admin Portal SNS view mode
   **/
  const defaultMargin =
    showSelectionBox ||
    mode === "edit" ||
    !_.get(currentEditorConfig, "showSelectionBox", false)
      ? 0
      : 30;
  if (depth < 5) return { marginLeft: `${(depth - 1) * 30 + defaultMargin}px` };
  else
    return {
      marginLeft: (depth - 1) * 30 + defaultMargin + "px",
      color: colors.textSubtle,
    };
};

const LearningStandards = props => {
  const {
    node,
    mode,
    dragHandleProps,
    onContentEdit,
    onLeftIndent,
    onRightIndent,
    onAddRow,
    placeholdersText,
    filters: { searchText },
    onCheckboxClick,
    isActionDisabled,
    isError,
    currentEditorConfig,
    removeError,
    onBulkPasteRows,
    indexOfNode,
    isCoachmarksRunning,
    updateDraftNode,
    showSelectionBox,
    onCheckBoxClick,
    taggedNodeIds,
  } = props;

  const { t } = useI18n(["common", "scopeAndSequence"]);
  const depth = node.depth;
  const isLabelEmpty = _.isEmpty(node.label);
  const isNodeSelected = _.includes(taggedNodeIds, node.id);

  const isSearchFilter = !_.isEmpty(searchText);
  const actionDisableStyle = isActionDisabled ? styles.disableStyle : {};
  const iconClasses = classNames({ [classes.iconDisabled]: isActionDisabled });
  const addIconClasses = classNames({
    [classes.iconDisabled]: isSearchFilter,
  });

  const textStyles = classNames({
    ["heading-5"]: depth == 1,
    ["heading-6"]: depth == 2,
    ["text-label-3"]: depth == 3,
    ["text-body-3"]: depth > 3,
    [classes.textContainer]: true,
    [classes.selectionBox]: showSelectionBox,
  });

  const handleContentEdit = event => {
    event.stopPropagation();
    const updatedLabel = event.currentTarget.textContent;
    const prevLabel = node.label;

    if (updatedLabel !== prevLabel || _.isEmpty(updatedLabel)) {
      const params = {
        label: updatedLabel,
      };
      onContentEdit({ params });
    }
  };
  //dynamic level style
  const standardsRowStyle = getStandardsRowStyleMemoize({
    depth,
    showSelectionBox,
    mode,
    currentEditorConfig,
  });

  //left right indentation functions
  const handleLeftIndent = () => {
    if (!isActionDisabled) {
      onLeftIndent();
    }
  };

  const handleAddRow = () => {
    if (!isSearchFilter) onAddRow();
  };

  const handleRightIndent = () => {
    if (!isActionDisabled) {
      onRightIndent();
    }
  };

  const newLabel = getHTMLLabel({
    searchText,
    label: node.label,
    style: { color: colors.blue29 },
  });
  const isChecked = _.get(node, "isChecked", false);

  const handleCheckboxClick = () => {
    onCheckboxClick({ prevIsChecked: isChecked });
  };

  const handleOnPaste = event => {
    const pastedData = event.clipboardData.getData("Text");
    const itemsList = pastedData.split(/\r?\n/); // regex spitting the excel sheet data into a list;
    const itemsListLength = _.size(itemsList);
    if (itemsListLength > 1) {
      // the list will be pasted in row format
      event.preventDefault();
      onBulkPasteRows({
        pastedData,
        cellName: CELL_NAME,
        isNewRow: true,
        itemsList,
      });
    }
  };

  const dragDropContainerStyle = classNames({
    [classes.dragDropIcon]: true,
    [classes.dragDropIconContainer]: !isActionDisabled,
    [classes.dragDropIconContainerDisabled]: isActionDisabled,
  });

  //if error is present, generate the error text
  //get the error text to display in tooltip of warning icon
  const getErrorText = () => {
    const errorCheckFields = currentEditorConfig.errorCheckFields;
    const errorCheckFieldLabels = currentEditorConfig.errorCheckFieldLabels;
    const missingFieldLabels = [];
    for (const field of errorCheckFields) {
      if (_.isEmpty(node[field])) {
        missingFieldLabels.push(t(errorCheckFieldLabels[field]));
      }
    }
    const errorText = missingFieldLabels.join(", ");
    return errorText;
  };
  const errorText = isError ? getErrorText() : "";
  const addIconTooltipText = isSearchFilter
    ? t("scopeAndSequence:disable_on_search")
    : t("common:add_with_label", { label: placeholdersText[depth] });
  //this function removes the error state if it exists for a row
  const resolveError = _.debounce(
    () => {
      removeError();
    },
    7000,
    { leading: true }
  );

  const handleOnInput = event => {
    const updatedLabel = event.currentTarget.textContent;
    if (!_.isEmpty(updatedLabel) && isError) {
      resolveError();
    }
    updateDraftNode({ cellName: CELL_NAME, labelValue: updatedLabel });
  };

  const onCheckboxChange = () => {
    onCheckBoxClick({ id: node.id });
  };

  return mode === "view" ? (
    showSelectionBox ? (
      <div
        className={textStyles}
        onClick={onCheckboxChange}
        style={standardsRowStyle}
      >
        <Checkbox isChecked={isNodeSelected} size="xx-small" />
        <div
          className={classes.cursorPointer}
          dangerouslySetInnerHTML={{
            __html: isLabelEmpty ? "--" : newLabel,
          }}
        />
      </div>
    ) : (
      <div
        className={textStyles}
        style={standardsRowStyle}
        dangerouslySetInnerHTML={{
          __html: isLabelEmpty ? "--" : newLabel,
        }}
      />
    )
  ) : (
    <div className={classes.container}>
      <div className={classes.actionButtonContainer}>
        {!isError && (
          <div
            className={classes.addIconContainer}
            style={
              isCoachmarksRunning && indexOfNode == 1
                ? { visibility: "visible" }
                : {}
            }
          >
            <div
              className={classes.addIcon}
              onClick={handleAddRow}
              style={
                isCoachmarksRunning && indexOfNode == 1
                  ? { visibility: "visible" }
                  : actionDisableStyle
              }
              id={
                indexOfNode == 1
                  ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_ADD_STANDARDS"
                  : ""
              }
            >
              <LinkWithTooltip tooltip={addIconTooltipText} placement={"top"}>
                <AddCircleOutlined
                  className={addIconClasses}
                  size={"xx-small"}
                />
              </LinkWithTooltip>
            </div>
          </div>
        )}
        {isError && (
          <div className={classes.warningIconContainer}>
            <div className={classes.warningIcon}>
              <LinkWithTooltip tooltip={errorText} placement={"top"}>
                <WarningTriangleOutlined
                  width={16}
                  height={16}
                  variant="critical"
                />
              </LinkWithTooltip>
            </div>
          </div>
        )}

        <div
          className={classes.checkboxContainer}
          id={
            indexOfNode == 1
              ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_BULK_ACTIONS"
              : ""
          }
        >
          <Checkbox
            isChecked={isChecked}
            onChange={handleCheckboxClick}
            size="xx-small"
          />
        </div>
        <div
          className={classes.arrowsContainer}
          id={
            indexOfNode == 1
              ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_HIERARCHY_ARROWS"
              : ""
          }
        >
          <div
            className={classes.leftArrowContainer}
            onClick={handleLeftIndent}
            style={actionDisableStyle}
          >
            <LeftArrowOutlined className={iconClasses} size={"xxx-small"} />
          </div>
          <div
            className={classes.rightArrowContainer}
            onClick={handleRightIndent}
            style={actionDisableStyle}
          >
            <RightArrowOutlined className={iconClasses} size={"xxx-small"} />
          </div>
        </div>

        <div
          {...dragHandleProps}
          className={dragDropContainerStyle}
          style={
            isCoachmarksRunning && indexOfNode == 1
              ? { visibility: "visible" }
              : actionDisableStyle
          }
          id={
            indexOfNode == 1
              ? "COACHMARKS_SCOPE_SEQUENCE_EDITOR_REARRANGE_STANDARDS"
              : ""
          }
        >
          <DragDotsOutlined className={iconClasses} size={"x-small"} />
        </div>
      </div>
      <div
        className={textStyles}
        contentEditable={true}
        onBlur={handleContentEdit}
        onInput={handleOnInput}
        suppressContentEditableWarning={true}
        style={standardsRowStyle}
        placeholdertext={
          isLabelEmpty
            ? t("common:enter_with_label", {
                label: placeholdersText[depth],
              })
            : ""
        }
        dangerouslySetInnerHTML={{
          __html: isLabelEmpty ? "" : newLabel,
        }}
        onPasteCapture={handleOnPaste}
      />
    </div>
  );
};

const checkProps = (prevProps, nextProps) => {
  const prevPropsNode = prevProps.node;
  const nextPropsNode = nextProps.node;

  const prevPropsLabel = prevPropsNode.label;
  const nextPropsLabel = nextPropsNode.label;

  const prevPropsDepth = prevPropsNode.depth;
  const nextPropsDepth = nextPropsNode.depth;

  const prevPropsFilters = prevProps.filters;
  const nextPropsFilters = nextProps.filters;

  const prevPropsIsChecked = _.get(prevPropsNode, "isChecked", false);
  const nextPropsIsChecked = _.get(nextPropsNode, "isChecked", false);

  const prevPropsOnBulkPasteRows = prevProps.onBulkPasteRows;
  const nextPropsOnBulkPasteRows = nextProps.onBulkPasteRows;

  return (
    prevProps.placeholdersText === nextProps.placeholdersText &&
    prevPropsLabel === nextPropsLabel &&
    prevProps.mode === nextProps.mode &&
    prevPropsDepth === nextPropsDepth &&
    prevProps.onContentEdit === nextProps.onContentEdit &&
    prevProps.onLeftIndent === nextProps.onLeftIndent &&
    prevProps.onRightIndent === nextProps.onRightIndent &&
    prevProps.onAddRow === nextProps.onAddRow &&
    prevProps.removeError === nextProps.removeError &&
    prevPropsFilters === nextPropsFilters &&
    prevProps.onCheckboxClick === nextProps.onCheckboxClick &&
    prevPropsIsChecked === nextPropsIsChecked &&
    prevPropsOnBulkPasteRows === nextPropsOnBulkPasteRows &&
    prevProps.indexOfNode === nextProps.indexOfNode &&
    prevProps.isCoachmarksRunning === nextProps.isCoachmarksRunning &&
    prevProps.updateDraftNode === nextProps.updateDraftNode
  );
};

export default React.memo(LearningStandards, checkProps);
