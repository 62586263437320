import { withAsyncRouteLoading } from "UIComponents";
import ApproachesToLearning from "./routes/ApproachesToLearning";
import GlobalContexts from "./routes/GlobalContexts";
import KeyConcepts from "./routes/KeyConcepts";
import TimelineInsights from "./routes/TimelineInsights";

export default store => ({
  path: "horizontalOverview/:gradeId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "planning-insights"
        );
      })
  ),
  childRoutes: [
    GlobalContexts(store),
    KeyConcepts(store),
    ApproachesToLearning(store),
    TimelineInsights(store),
  ],
});
