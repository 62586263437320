import { checkEmptyElementValue } from "modules/Services";

export const GetLEStepsLocally = ({
  userType,
  template,
  resourceDetails,
  unitPlanFields,
  parentType = "UNIT_PLAN",
  isPreview,
  isCommunity = false,
}) => {
  const field_list = _.get(template, "field_list", {});
  let stepKeyName = "view_steps";
  if (_.isEqual(parentType, "CLASSROOM")) {
    stepKeyName = "classroomSteps";
  } else if (_.isEqual(parentType, "JOURNAL")) {
    stepKeyName = "view_steps";
  } else if (isPreview) {
    stepKeyName = "previewSteps";
  } else if (_.isEqual(userType, "student")) {
    stepKeyName = "studentSteps";
  }

  const stepList = _.reduce(
      _.get(template, "step_list", {}),
      (result, value, key) => {
        result[key] = {
          ...value,
          fields: _.filter(_.get(value, "fields", []), field => {
            return checkEmptyElementValue({
              fieldKey: field,
              data: resourceDetails,
              unitPlanFields,
              fieldObj: field_list[field],
              isCommunity,
            });
          }),
        };
        return result;
      },
      {}
    ),
    viewSteps = _.filter(_.get(template, stepKeyName, []), step => {
      return _.get(stepList[step], "fields.length", 0) > 0;
    });

  return {
    stepList,
    viewSteps,
  };
};

export const GetLESteps = _.memoize(
  params => GetLEStepsLocally(params),
  params => JSON.stringify(params)
);
