import React from "react";
import PropTypes from "prop-types";
import classes from "./PypEvaluation.scss";
import { connect } from "react-redux";
import { generateNestedSelectedData, getPYPElementSetFromNodes } from "Utils";
import {
  getCriteriaSetOfPypType,
  checkCriteriaSetOfPypTypeInLevels,
  filteredCriteriaSetsMemoized,
  pypElementMapping,
  pypElementSetMapping,
  getPlannerElementsToEvaluate,
} from "modules/Services";
import {
  createPypElementRating,
  deletePypElementRating,
} from "AssessmentEvaluation/modules/AssessmentEvaluationModule";
import { getOrganizationConstantsFromCache } from "modules/CommonGraphqlHelpers";
import { colors } from "Constants";

import { Checkbox, EmptyField, SelectDropdown, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import {
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
  ELEMENT_TYPE_UBD_LEARNING_STANDARD,
  ELEMENT_TYPE_UBD_ATL,
  ELEMENT_TYPE_UBD_SUBJECT,
  ELEMENT_TYPE_MYP_ATL,
  CURRICULUM_TYPE_MYP,
  ELEMENT_TYPE_MYP_LEARNING_STANDARD,
  ELEMENT_TYPE_MYP_SUBJECT,
} from "Constants/stringConstants";
import classNames from "classnames";

const PARENT_CHILD_INDENTATION = 24;
const PARENT_CHILD_INDENTATION_V2 = 12;

const levelsLabelStyleMapping = ["h1Label", "h2Label", "h3Label", "h4Label"];

const pypRootLevelStyleMapping = {
  BENCHMARK: "h1Label",
  CONCEPT: "h4Label",
  LEARNER_PROFILE: "h4Label",
  ATL: "h2Label",
  UBD_ATL: "h2Label",
  UBD_LEARNING_STANDARD: "h1Label",
  MYP_ATL: "h2Label",
  MYP_LEARNING_STANDARD: "h1Label",
};

const getCreateRatingParams = ({
  rootNodeId,
  curriculumProgramType,
  type,
  subjectId,
}) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_MYP:
      switch (type) {
        case ELEMENT_TYPE_MYP_ATL:
          return { subjectId };
        default:
          return { subjectId: rootNodeId };
      }
    default:
      return {};
  }
};

const getRootNodeIdByCurriculum = ({ curriculumProgramType, rootNodeId }) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_MYP:
    case CURRICULUM_TYPE_UBD:
      return rootNodeId;
    default:
      return null;
  }
};

const CriteriaSetOptionDropdown = ({
  setOptions,
  setValue,
  onClickOption,
  t,
}) => {
  const options = _.map(setOptions, opt => {
    return {
      label: opt.abbreviation,
      value: opt.id,
    };
  });

  if (setValue) {
    options.push({ label: "-", value: "" });
  }

  return (
    <SelectDropdown
      value={setValue}
      dropdownStyle={{ height: 48 }}
      options={options}
      updateInputField={onClickOption}
      placeholder={t("assessmentEvaluation:mark")}
    />
  );
};

const CriteriaSetContainer = ({
  isComplete,
  criteriaSet,
  createPypElementRating,
  deletePypElementRating,
  checkCriteriaSetOfPypTypeInLevels,
  getStudentElementRatingValueOfCriteriaSet,
  t,
  isPreviewMode,
}) => {
  const _onClickOption = ({ criteriaValue, setId }) => {
    if (criteriaValue) {
      createPypElementRating({
        criteriaValue,
        setId,
      });
    } else {
      deletePypElementRating({
        setId,
      });
    }
  };
  const setValueClass = classNames({
    [classes.setValue]: true,
    [classes.setValuePreview]: isPreviewMode,
  });
  const setClass = classNames({
    [classes.set]: true,
    [classes.setPreview]: isPreviewMode,
  });

  return (
    <div className={classes.rightContainer}>
      {_.map(criteriaSet, set => {
        const showCriteria = checkCriteriaSetOfPypTypeInLevels({
          setId: set.id,
        });
        const setValue = getStudentElementRatingValueOfCriteriaSet({
          setId: set.id,
        });
        const setOptions = set.academicCriteriaValues;

        return (
          <div className={setClass} key={set.id}>
            {showCriteria ? (
              !isComplete ? (
                <CriteriaSetOptionDropdown
                  t={t}
                  setValue={setValue}
                  onClickOption={value =>
                    _onClickOption({
                      criteriaValue: value,
                      setId: set.id,
                    })
                  }
                  setOptions={setOptions}
                />
              ) : (
                <div className={setValueClass}>
                  {_.get(
                    _.find(setOptions, { id: setValue }),
                    "abbreviation",
                    "-"
                  )}
                </div>
              )
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

const CriteriaSetHeaderRow = ({
  criteriaSet,
  headerLabel,
  isPreviewMode,
  levelType,
}) => {
  const criteriaSetHeaderRowClass = classNames({
    [classes.criteriaSetHeaderRow]: true,
    [classes.criteriaSetHeaderRowPreview]: isPreviewMode,
    [classes.criteriaSetHeaderSingle]: isPreviewMode && levelType == "single",
  });
  const criteriaSetHeaderLabelClass = classNames({
    [classes.criteriaSetHeaderLabel]: true,
    [classes.criteriaSetHeaderLabelPreview]: isPreviewMode,
  });
  const leftContainerClass = classNames({
    [classes.leftContainer]: true,
    [classes.leftContainerPreview]: isPreviewMode,
  });
  const setClass = classNames({
    [classes.set]: true,
    [classes.setPreview]: isPreviewMode,
  });

  return (
    <div className={criteriaSetHeaderRowClass}>
      <div
        className={leftContainerClass}
        style={{
          width: `${Math.max(40, 80 - 10 * criteriaSet.length)}%`,
        }}
      >
        <div className={criteriaSetHeaderLabelClass}>{`${headerLabel}`}</div>
      </div>
      <div className={classes.rightContainer}>
        {_.map(criteriaSet, set => {
          return (
            <div className={setClass} key={set.id}>
              <div
                className={criteriaSetHeaderLabelClass}
              >{`${set.label}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const EvaluationRowsContainer = ({
  criteriaSet,
  nodes,
  createPypElementRating,
  deletePypElementRating,
  rootNodeId,
  getStudentElementRatingValueOfCriteriaSet,
  checkCriteriaSetOfPypTypeInLevels,
  isComplete,
  totalLevels,
  depth,
  rootStyle,
  t,
  curriculumProgramType,
  subjectId,
  isPreviewMode,
}) => {
  const getRootNodeId = ({ type, rootNodeId, nodeId }) => {
    switch (type) {
      case ELEMENT_TYPE_UBD_ATL:
      case ELEMENT_TYPE_MYP_ATL:
        return rootNodeId || nodeId;
      default:
        return rootNodeId;
    }
  };
  return (
    <div className={classes.rowsContainer}>
      {_.map(nodes, node => {
        return (
          <EvaluationRowContainer
            t={t}
            criteriaSet={criteriaSet}
            node={node}
            getStudentElementRatingValueOfCriteriaSet={
              getStudentElementRatingValueOfCriteriaSet
            }
            rootStyle={rootStyle}
            totalLevels={totalLevels}
            depth={depth}
            key={node.id}
            isComplete={isComplete}
            rootNodeId={getRootNodeId({
              type: node.type,
              rootNodeId,
              nodeId: node.id,
            })}
            checkCriteriaSetOfPypTypeInLevels={
              checkCriteriaSetOfPypTypeInLevels
            }
            createPypElementRating={createPypElementRating}
            deletePypElementRating={deletePypElementRating}
            curriculumProgramType={curriculumProgramType}
            subjectId={subjectId}
            isPreviewMode={isPreviewMode}
          />
        );
      })}
    </div>
  );
};

const EvaluationRowContainer = ({
  criteriaSet,
  node,
  createPypElementRating,
  deletePypElementRating,
  rootNodeId,
  getStudentElementRatingValueOfCriteriaSet,
  checkCriteriaSetOfPypTypeInLevels,
  isComplete,
  depth,
  rootStyle,
  totalLevels,
  t,
  curriculumProgramType,
  subjectId,
  isPreviewMode,
}) => {
  const rootLabelIndex = _.indexOf(levelsLabelStyleMapping, rootStyle);
  const labelStyle = levelsLabelStyleMapping[depth + rootLabelIndex - 1];
  const minLevelId = _.get(node, "levels[0].id", "");
  const rowContainerClass = classNames({
    [classes.rowContainer]: true,
    [classes.rowContainerPreview]: isPreviewMode,
    [classes.rowContainerLevelPreview]:
      isPreviewMode && node.levelId !== minLevelId,
  });
  const nodeLabelClass = classNames({
    [classes[labelStyle]]: true,
    [classes[`${labelStyle}Preview`]]: isPreviewMode,
  });
  const leftContainerClass = classNames({
    [classes.leftContainer]: true,
    [classes.leftContainerPreview]: isPreviewMode,
  });
  const indentation = isPreviewMode
    ? depth * PARENT_CHILD_INDENTATION_V2 - 4
    : (depth - 1) * PARENT_CHILD_INDENTATION;

  return (
    <div className={classes.parentContainer}>
      <div className={rowContainerClass}>
        <div
          className={leftContainerClass}
          style={{
            paddingLeft: indentation,
            width: `${Math.max(40, 80 - 10 * criteriaSet.length)}%`,
          }}
        >
          <div className={nodeLabelClass}>{node.label}</div>
        </div>
        <CriteriaSetContainer
          t={t}
          isComplete={isComplete}
          criteriaSet={criteriaSet}
          checkCriteriaSetOfPypTypeInLevels={params =>
            checkCriteriaSetOfPypTypeInLevels({
              ...params,
              levelId: node.levelId,
              parentId: rootNodeId,
              type: node.ibPlannerElementType,
            })
          }
          createPypElementRating={params =>
            createPypElementRating({
              ...params,
              id: node.id,
              type: node.ibPlannerElementType,
              ...getCreateRatingParams({
                rootNodeId,
                curriculumProgramType,
                type: node.ibPlannerElementType,
                subjectId,
              }),
            })
          }
          deletePypElementRating={params =>
            deletePypElementRating({
              ...params,
              id: node.id,
              type: node.ibPlannerElementType,
            })
          }
          getStudentElementRatingValueOfCriteriaSet={params =>
            getStudentElementRatingValueOfCriteriaSet({
              ...params,
              id: node.id,
              type: node.ibPlannerElementType,
            })
          }
          isPreviewMode={isPreviewMode}
        />
      </div>
      {_.get(node.children, "length", 0) > 0 && (
        <EvaluationRowsContainer
          t={t}
          depth={depth + 1}
          isComplete={isComplete}
          criteriaSet={criteriaSet}
          rootNodeId={rootNodeId}
          nodes={node.children}
          totalLevels={totalLevels}
          createPypElementRating={createPypElementRating}
          deletePypElementRating={deletePypElementRating}
          checkCriteriaSetOfPypTypeInLevels={checkCriteriaSetOfPypTypeInLevels}
          getStudentElementRatingValueOfCriteriaSet={
            getStudentElementRatingValueOfCriteriaSet
          }
          rootStyle={rootStyle}
          curriculumProgramType={curriculumProgramType}
          subjectId={subjectId}
          isPreviewMode={isPreviewMode}
        />
      )}
    </div>
  );
};

const MultiLevelEvaluationBox = ({
  type,
  nodes,
  getCriteriaSetOfPypType,
  createPypElementRating,
  deletePypElementRating,
  getStudentElementRatingValueOfCriteriaSet,
  checkCriteriaSetOfPypTypeInLevels,
  isComplete,
  rootStyle,
  label,
  t,
  curriculumProgramType,
  subjectId,
  isPreviewMode,
  levelType,
}) => {
  const evaluationBodyClass = classNames({
    [classes.evaluationBody]: true,
    [classes.evaluationBodyMultiLevelPreview]: isPreviewMode,
  });
  const rootContainerClass = classNames({
    [classes.rootContainer]: true,
    [classes.rootContainerPreview]: isPreviewMode,
  });

  return (
    <div className={classes.evaluationContainer}>
      {!isPreviewMode && <div className={classes.evaluationLabel}>{label}</div>}
      {_.get(nodes, "length", 0) > 0 ? (
        <div className={evaluationBodyClass}>
          {_.map(nodes, (node, index) => {
            const rootNodeId = node.id;

            const criteriaSet = filteredCriteriaSetsMemoized({
              type: node.ibPlannerElementType,
              levelId: node.levelId,
              rootNodeId,
              node: node,
              getCriteriaSetOfPypType,
            });

            const hideCriteriaSetHeader = isPreviewMode && index !== 0;

            return (
              <div className={rootContainerClass} key={node.id}>
                {!hideCriteriaSetHeader && (
                  <CriteriaSetHeaderRow
                    criteriaSet={criteriaSet}
                    headerLabel={label}
                    isPreviewMode={isPreviewMode}
                    levelType={levelType}
                  />
                )}
                <EvaluationRowContainer
                  t={t}
                  criteriaSet={criteriaSet}
                  node={node}
                  getStudentElementRatingValueOfCriteriaSet={
                    getStudentElementRatingValueOfCriteriaSet
                  }
                  rootStyle={rootStyle}
                  totalLevels={_.get(node, "levels.length", 1)}
                  depth={1}
                  isComplete={isComplete}
                  rootNodeId={rootNodeId}
                  checkCriteriaSetOfPypTypeInLevels={
                    checkCriteriaSetOfPypTypeInLevels
                  }
                  createPypElementRating={createPypElementRating}
                  deletePypElementRating={deletePypElementRating}
                  curriculumProgramType={curriculumProgramType}
                  subjectId={subjectId}
                  isPreviewMode={isPreviewMode}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyField
          title={t("assessmentEvaluation:not_selected_le_label", {
            label: label.toLowerCase(),
          })}
        />
      )}
    </div>
  );
};

const SignleLevelEvaluationBox = ({
  type,
  nodes,
  getCriteriaSetOfPypType,
  getStudentElementRatingValueOfCriteriaSet,
  checkCriteriaSetOfPypTypeInLevels,
  createPypElementRating,
  deletePypElementRating,
  isComplete,
  rootStyle,
  label,
  t,
  curriculumProgramType,
  subjectId,
  isPreviewMode,
  levelType,
}) => {
  const criteriaSet = _.reduce(
    nodes,
    (result, node) => {
      result = _.unionBy(
        result,
        filteredCriteriaSetsMemoized({
          type: node.ibPlannerElementType,
          levelId: node.levelId,
          rootNodeId: getRootNodeIdByCurriculum({
            curriculumProgramType,
            rootNodeId: node.id,
          }),
          node: node,
          getCriteriaSetOfPypType,
        }),
        "id"
      );
      return result;
    },
    []
  );

  const evaluationBodyClass = classNames({
    [classes.evaluationBody]: true,
    [classes.evaluationBodyPreview]: isPreviewMode,
  });

  return (
    _.get(nodes, "length", 0) > 0 && (
      <div className={classes.evaluationContainer}>
        {!isPreviewMode && (
          <div className={classes.evaluationLabel}>{label}</div>
        )}
        {_.get(nodes, "length", 0) > 0 ? (
          <div className={evaluationBodyClass}>
            <CriteriaSetHeaderRow
              criteriaSet={criteriaSet}
              headerLabel={label}
              isPreviewMode={isPreviewMode}
              levelType={levelType}
            />

            <EvaluationRowsContainer
              t={t}
              depth={1}
              totalLevels={1}
              rootStyle={rootStyle}
              type={type}
              isComplete={isComplete}
              criteriaSet={criteriaSet}
              nodes={nodes}
              getStudentElementRatingValueOfCriteriaSet={
                getStudentElementRatingValueOfCriteriaSet
              }
              createPypElementRating={createPypElementRating}
              deletePypElementRating={deletePypElementRating}
              checkCriteriaSetOfPypTypeInLevels={
                checkCriteriaSetOfPypTypeInLevels
              }
              curriculumProgramType={curriculumProgramType}
              subjectId={subjectId}
              isPreviewMode={isPreviewMode}
            />
          </div>
        ) : (
          <EmptyField
            title={t("assessmentEvaluation:not_selected_le_label", {
              label: label.toLowerCase(),
            })}
          />
        )}
      </div>
    )
  );
};

const DynamicLevelEvaluationBox = props => {
  return props.levelType == "single" ? (
    <SignleLevelEvaluationBox {...props} />
  ) : (
    <MultiLevelEvaluationBox {...props} />
  );
};

class PypEvaluation extends React.PureComponent {
  getCriteriaSetOfPypType = params => {
    const { grades, getCriteriaSetOfPypType } = this.props;
    return getCriteriaSetOfPypType({ ...params, grades });
  };
  render() {
    const {
      pypElementsData,
      parentId,
      parentType,
      studentDetails,
      elementRatings,
      createPypElementRating,
      deletePypElementRating,
      checkCriteriaSetOfPypTypeInLevels,
      evaluateIBPYPElements,
      isComplete,
      toggleStudentPypEvaluateStatus,
      isEvaluatePypElement,
      showToggleStudentPypEvaluateStatus,
      isEvaluated,
      t,
      isArchivedClass,
      subjectId,
      curriculumProgramType,
      isPreviewMode,
    } = this.props;

    const isArchived = _.get(studentDetails, "isArchived");
    const isDisabled = isArchivedClass || isArchived;

    return isEvaluatePypElement ? (
      <div className={classes.container}>
        {showToggleStudentPypEvaluateStatus && (
          <div className={classes.header}>
            <div
              onClick={
                isComplete || isDisabled
                  ? null
                  : () => toggleStudentPypEvaluateStatus(!evaluateIBPYPElements)
              }
            >
              <Checkbox
                isDisabled={isComplete || isDisabled}
                fill={colors.blue29}
                offFill={colors.strokeTwo}
                isChecked={evaluateIBPYPElements}
                style={{ width: 24, height: 24 }}
                checkBoxParentContainer={{
                  width: "max-content",
                  paddingTop: 0,
                }}
              />
            </div>
            <div className={classes.naLabel}>
              {t("assessmentEvaluation:evaluate_pyp_label_with_le", {
                label: studentDetails.firstName,
              })}
            </div>
          </div>
        )}
        {evaluateIBPYPElements && (
          <div className={classes.evaluationContainer}>
            {_.map(
              pypElementsData,
              ({ levelType, elementList, type, label }) => (
                <DynamicLevelEvaluationBox
                  t={t}
                  key={type}
                  isComplete={isComplete}
                  getStudentElementRatingValueOfCriteriaSet={params =>
                    getStudentElementRatingValueOfCriteriaSet({
                      ...params,
                      elementRatings,
                    })
                  }
                  levelType={levelType}
                  label={label}
                  rootStyle={pypRootLevelStyleMapping[type]}
                  createPypElementRating={params =>
                    createPypElementRating({
                      ...params,

                      parentId,
                      parentType,
                      studentId: studentDetails.id,
                      isPublished: isEvaluated,

                      curriculumProgramType,
                    })
                  }
                  deletePypElementRating={params =>
                    deletePypElementRating({
                      ...params,
                      parentId,
                      parentType,
                      studentId: studentDetails.id,
                    })
                  }
                  type={type}
                  nodes={elementList}
                  getCriteriaSetOfPypType={this.getCriteriaSetOfPypType}
                  checkCriteriaSetOfPypTypeInLevels={params =>
                    checkCriteriaSetOfPypTypeInLevels({
                      ...params,
                    })
                  }
                  curriculumProgramType={curriculumProgramType}
                  subjectId={subjectId}
                  isPreviewMode={isPreviewMode}
                />
              )
            )}
          </div>
        )}
      </div>
    ) : null;
  }
}

const getStudentElementRatingValueOfCriteriaSet = ({
  elementRatings,
  type,
  id,
  setId,
}) => {
  const elementRating = _.find(elementRatings, ratingObj => {
    return (
      ratingObj.ibPYPElementId == id &&
      ratingObj.ibPYPElementType == type &&
      ratingObj.academicCriteriaSet.id == setId
    );
  });

  return _.get(elementRating, "academicCriteriaValue.id", null);
};

const getPypElementsNestedData = ({ values, resolvedMinimalTreeSet, type }) => {
  const nodeSet = getPYPElementSetFromNodes({
    nodes: resolvedMinimalTreeSet,
  });
  const rootNodes = _.get(nodeSet, "rootNodes", []);
  const nodes = _.get(nodeSet, "nodes", []);

  switch (type) {
    case "CONCEPT":
    case "LEARNER_PROFILE":
      return {
        elementList: _.map(values, nodeId => {
          return _.find(nodes, { id: nodeId });
        }),
        levelType: "single",
      };
    case "BENCHMARK":
      return {
        elementList: generateNestedSelectedData({
          nodes,
          rootNodes,
          value: values,
        }),
        levelType: "multi",
      };
    case "ATL":
    case "UBD_LEARNING_STANDARD":
    case "MYP_LEARNING_STANDARD":
      return {
        elementList: generateNestedSelectedData({
          nodes,
          rootNodes,
          value: values,
        }),
        levelType: "multi",
      };
    case "MYP_ATL":
    case "UBD_ATL":
      return {
        elementList: generateNestedSelectedData({
          nodes,
          rootNodes,
          value: values,
        }),
        levelType: "single",
      };
  }
};

const mapActionCreators = {
  getCriteriaSetOfPypType,
  checkCriteriaSetOfPypTypeInLevels,
  createPypElementRating,
  deletePypElementRating,
};

const getResolvedMinimalTreeSet = ({
  curriculumProgramType,
  assessmentField,
  elementKey,
}) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return _.get(
        assessmentField,
        `resolvedMinimalTree.${pypElementSetMapping[elementKey]}`,
        {}
      );
    default:
      return _.get(assessmentField, `resolvedMinimalTree.nodes`, {});
  }
};

const updatePlannerElementLabels = ({
  plannerElementsToEvaluate,
  curriculumProgramType,
  plannerElementSets,
}) => {
  switch (curriculumProgramType) {
    case CURRICULUM_TYPE_PYP:
      return plannerElementsToEvaluate;
    default: {
      const updatedPlannerElementsToEvaluate = _.map(
        plannerElementsToEvaluate,
        element => {
          return {
            ...element,
            label: plannerElementSets[pypElementMapping[element.value]]?.label,
          };
        }
      );
      return updatedPlannerElementsToEvaluate;
    }
  }
};

const getPypElementsData = ({
  assessmentFields,
  curriculumProgramType,
  plannerElementSets,
}) => {
  const plannerElementsToEvaluate = getPlannerElementsToEvaluate({
    assessmentDetails: {
      allFields: assessmentFields,
    },
    curriculumProgramType,
  });

  const updatedPlannerElementToEvaluate = updatePlannerElementLabels({
    plannerElementsToEvaluate,
    curriculumProgramType,
    plannerElementSets,
  });

  return _.map(updatedPlannerElementToEvaluate, pypElement => {
    const elementKey = pypElement.value;
    const type = pypElementMapping[elementKey];

    const assessmentField = _.find(assessmentFields, { uid: elementKey });

    const values = _.get(assessmentField, "value", []);
    const resolvedMinimalTreeSet = getResolvedMinimalTreeSet({
      curriculumProgramType,
      assessmentField,
      elementKey,
    });

    const { elementList, levelType } = getPypElementsNestedData({
      values,
      resolvedMinimalTreeSet,
      type,
    });
    return {
      ...pypElement,
      elementList,
      levelType,
      type,
    };
  });
};

const updatePlannerElementNode = ({ node, type }) => {
  let updatedNode = {
    ...node,
    ibPlannerElementType: type,
    levelId: node.levelId || node.depth,
  };
  if (!_.isEmpty(node.children)) {
    const updatedChildren = _.map(updatedNode.children, child => {
      return updatePlannerElementNode({ node: child, type });
    });
    updatedNode = { ...updatedNode, children: updatedChildren };
  }
  return updatedNode;
};

const getSubjectType = ({ type }) => {
  switch (type) {
    case ELEMENT_TYPE_MYP_LEARNING_STANDARD:
      return ELEMENT_TYPE_MYP_SUBJECT;
    case ELEMENT_TYPE_UBD_LEARNING_STANDARD:
      return ELEMENT_TYPE_UBD_SUBJECT;
  }
};

//This function is to update the element nodes with ibPlannerElementType and levelId if it's not there
const getUpdatedPlannerElementNodes = ({ pypElementsData }) => {
  return _.map(pypElementsData, pypElement => {
    const ibPlannerElementType = _.get(pypElement, "type", "");
    const elementList = _.get(pypElement, "elementList", []);

    const updatedElementList = _.map(elementList, element => {
      return updatePlannerElementNode({
        node: element,
        type: ibPlannerElementType,
      });
    });

    return { ...pypElement, elementList: updatedElementList };
  });
};

//This will update the element nodes and then
//it will add subjectNode as a parent in UBD_LEARNING_STANDARD
const getUpdatedPlannerElementsData = ({ pypElementsData }) => {
  const updatedPlannerElementTypeInNodes = getUpdatedPlannerElementNodes({
    pypElementsData,
  });

  const updatedPypElementsData = _.map(
    updatedPlannerElementTypeInNodes,
    pypElement => {
      let updatedPypElement = { ...pypElement };
      switch (pypElement.type) {
        case ELEMENT_TYPE_MYP_LEARNING_STANDARD:
        case ELEMENT_TYPE_UBD_LEARNING_STANDARD: {
          const elementList = _.get(updatedPypElement, "elementList", []);
          if (!_.isEmpty(elementList)) {
            let subjectArray = [];

            //To Support different Subjects and Learning Standards in interdisciplinary Learning
            _.forEach(elementList, element => {
              const associatedParents = _.get(element, "associatedParents", []);
              const associatedSubjectParent = _.find(
                associatedParents,
                parent => parent["__typename"] == "Subject"
              );
              const isAlreadyAddedSubject = _.find(
                subjectArray,
                subject => subject.id == associatedSubjectParent.id
              );
              if (!isAlreadyAddedSubject) {
                subjectArray = [...subjectArray, associatedSubjectParent];
              }
            });

            const updatedElementList = _.map(subjectArray, subject => {
              const childrenElement = _.filter(elementList, element => {
                const elementSubjectAssociatedParent = _.find(
                  _.get(element, "associatedParents", []),
                  parent => parent["__typename"] == "Subject"
                );
                return (
                  _.get(elementSubjectAssociatedParent, "id", "") ==
                  _.get(subject, "id", "")
                );
              });
              return {
                id: subject.id,
                label: subject.name,
                children: childrenElement,
                levelId: 0,
                type: getSubjectType({ type: pypElement.type }),
                ibPlannerElementType: getSubjectType({ type: pypElement.type }),
              };
            });

            updatedPypElement = {
              ...pypElement,
              elementList: updatedElementList,
            };
          }
        }
      }
      return updatedPypElement;
    }
  );
  return updatedPypElementsData;
};

const mapStateToProps = (
  state,
  { assessmentFields, ibPYPElementRatings, curriculumProgramType, course }
) => {
  const grades = state.teacher.selected_class.selected_grades;
  const orgId = state.login.userInfo.org_id;
  const plannerElementSets = _.get(
    state,
    "platform.currentPlannerElementSets",
    {}
  );

  const pypElementsData = getPypElementsData({
    assessmentFields,
    curriculumProgramType,
    plannerElementSets,
  });

  const updatedPypElementsData = getUpdatedPlannerElementsData({
    pypElementsData,
  });

  const organizationConstants = getOrganizationConstantsFromCache(orgId);

  const subjectId = _.get(course, "subjects.0.id", "");

  return {
    elementRatings: ibPYPElementRatings,
    pypElementsData: updatedPypElementsData,
    grades,
    isEvaluatePypElement: organizationConstants.isEvaluatePypElement,
    isArchivedClass: _.get(state, "teacher.selected_class.isArchivedClass"),
    curriculumProgramType,
    plannerElementSets,
    subjectId,
  };
};

PypEvaluation.propTypes = {
  showToggleStudentPypEvaluateStatus: PropTypes.bool,
  isEvaluated: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
};

PypEvaluation.defaultProps = {
  showToggleStudentPypEvaluateStatus: true,
  isEvaluated: false,
  isPreviewMode: false,
};

export default compose(
  I18nHOC({ resource: "assessmentEvaluation" }),
  connect(mapStateToProps, mapActionCreators)
)(PypEvaluation);
