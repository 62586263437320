import moment from "moment";
import { getDatesDifferenceInDays } from "Utils";

export const getOrganizationStatus = ({ academicYear }) => {
  const currentDate = new Date();
  const academicYearEndDate = academicYear?.endDate;

  /**
   * If there is no current academic year OR
   * end date is before current date
   */
  if (
    _.isEmpty(academicYear) ||
    moment(currentDate).isAfter(moment(academicYearEndDate))
  ) {
    return "OVERDUE";
  }
  const differenceInDays = getDatesDifferenceInDays({
    startDate: currentDate,
    endDate: academicYearEndDate,
  });

  if (differenceInDays > 7 && differenceInDays <= 30) {
    return "IN_30_7";
  }
  if (differenceInDays <= 7) {
    return "IN_7_0";
  }
};
