import React from "react";

import classes from "./ChildrenListItem.scss";
import { ProfileCascade } from "UIComponents";

import { colors } from "Constants";
import { DeleteOutlined } from "@toddle-design/web-icons";
import { Button } from "@toddle-design/web";

const styles = {
  profileCascadeStyle: {
    width: "40px",
    height: "40px",
  },
};

const ChildrenListItem = props => {
  const {
    childName,
    schoolName,
    removeText,
    profileImage,
    onRemoveClick,
    childId,
  } = props;

  return (
    <div className={classes.childrenListItem}>
      <div className={classes.leftContent}>
        <div>
          <ProfileCascade
            items={[{ value: childName, imageUrl: profileImage }]}
            customStyle={styles.profileCascadeStyle}
          />
        </div>
        <div className={classes.childInfo}>
          <div className={classes.childName}>{childName}</div>
          <div className={classes.schoolName}>{schoolName}</div>
        </div>
      </div>
      <Button
        icon={<DeleteOutlined />}
        variant="inline-destructive"
        onClick={() => onRemoveClick(childId, childName, profileImage)}
        size="small"
      >
        {removeText}
      </Button>
    </div>
  );
};

export default ChildrenListItem;
