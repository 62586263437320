import { withAsyncRouteLoading } from "UIComponents";
import ProgrammeOfInquiry from "./routes/ProgrammeOfInquiry";
import SubjectGroupOverview from "./routes/SubjectGroupOverview";
import OtherInsightElements from "./routes/OtherInsightElements";

export default store => ({
  path: "verticalOverview/:subjectGroupId",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            resolve(component);
          },
          err => {
            reject(err);
          },
          "planning-insights"
        );
      })
  ),
  childRoutes: [
    ProgrammeOfInquiry(store),
    SubjectGroupOverview(store),
    OtherInsightElements(store),
  ],
});
