import client from "apolloClient";
import update from "immutability-helper";

import { productUpdateFragment } from "./ProductUpdatesFragments";

import { getProductUpdatesQuery } from "./ProductUpdatesQueries";

export const getProductUpdatesFromCache = variables => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getProductUpdatesQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  return _.get(queryData, "cms", {});
};

export const writeProductUpdatesFragmentToCache = ({ id, myVote }) => {
  let queryData = {};
  try {
    const prevData = client.readFragment({
      id: `ProductUpdates:${id}`,
      fragment: productUpdateFragment.productUpdate,
      fragmentName: "productUpdateItem",
    });

    const updatedData = update(prevData, {
      votes: { myVote: { $set: myVote } },
    });

    queryData = client.writeFragment({
      id: `ProductUpdates:${id}`,
      fragment: productUpdateFragment.productUpdate,
      fragmentName: "productUpdateItem",
      data: updatedData,
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};
