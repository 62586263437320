import React from "react";
import PropTypes from "prop-types";

const Tick = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g
        id="KeyConcepts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-342.000000, -434.000000)"
      >
        <g id="tick" transform="translate(342.000000, 434.000000)">
          <circle
            id="Oval"
            fill={props.fillBackground}
            cx="12"
            cy="12"
            r="12"
          />

          <path
            d="M14.8724927,9.28375685 L10.8173433,13.4083433 L9.12740019,11.6894631 C8.75556988,11.3111206 8.15284493,11.3111206 7.77887274,11.6894631 C7.40704242,12.0696936 7.40704242,12.6827391 7.77887274,13.0610816 L10.1440791,15.4666426 C10.3291375,15.6548697 10.5733118,15.75 10.8173433,15.75 C11.0615176,15.75 11.3056918,15.6548697 11.4926065,15.4666426 L16.2210202,10.6553754 C16.5929933,10.2770329 16.5929933,9.66209932 16.2210202,9.28375685 C15.8490471,8.90541438 15.2444658,8.90541438 14.8724927,9.28375685 Z"
            id="macro"
            fill={props.fillTick}
          />
        </g>
      </g>
    </svg>
  );
};

Tick.propTypes = {
  fillBackground: PropTypes.string,
  fillTick: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Tick.defaultProps = {
  fillBackground: "#10ACBD",
  fillTick: "#FFF",
  width: 24,
  height: 24,
};

export default Tick;
