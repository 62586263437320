import PropTypes from "prop-types";
import React from "react";
import { Router, browserHistory } from "react-router";
import { Provider } from "react-redux";
import { DragDropEvent } from "Constants";
import { DragDropContext } from "react-beautiful-dnd";
import { shouldRerouteAfterError } from "config";
class AppContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired,
    routerKey: PropTypes.number,
    store: PropTypes.object.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.debounceRerouteAfterError = this.debounce(
      this.rerouteAfterError,
      1000
    );
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick, { capture: true });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick, { capture: true });
  }

  handleClick = e => {
    window.shouldOpenInNewTab = false;
    if (e.metaKey || e.ctrlKey) {
      window.shouldOpenInNewTab = true;
    }
  };

  componentDidCatch = (error, info) => {
    if (shouldRerouteAfterError) {
      this.debounceRerouteAfterError();
    } else {
      //Logs error in console when env not prod
      console.error(error, info);
    }
  };

  rerouteAfterError = () => {
    // if (this.prevLocation != window.location.pathname) {
    //   console.log("Hello");
    //   window.location.reload();
    //   browserHistory.goBack();
    //   localStorage.setItem("errorReload", true);
    // } else {
    //   console.log("Lalal");
    //   const pathArray = window.location.pathname.split("/");
    //   if (pathArray.length > 2) {
    //     browserHistory.push(`/${pathArray[1]}`);
    //   } else {
    //     browserHistory.push(`/`);
    //   }
    //   localStorage.setItem("errorReload", true);
    //   window.location.reload();
    // }

    const pathArray = window.location.pathname.split("/");
    if (pathArray.length > 2) {
      browserHistory.push(`/${pathArray[1]}`);
    } else {
      browserHistory.push(`/`);
    }
    if (localStorage) {
      localStorage.setItem("errorReload", true);
    }
    window.location.reload();

    // console.log(window.location);
    // if (pathArray.length > 2) {
    //   let reRoutePath = `/${pathArray[1]}/${pathArray[2]}`;
    //   browserHistory.push(reRoutePath);
    // } else {
    //   switch (pathArray[1]) {
    //     case "teacher":
    //       browserHistory.push("/teacher/classes");
    //       break;
    //     case "admin":
    //       browserHistory.push("/admin");
    //       break;
    //     case "administrator":
    //       browserHistory.push("/administrator");
    //       break;
    //     default:
    //       browserHistory.push("/");
    //   }
    // }
  };

  debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  onDragEnd = params => {
    //Emit onDrag event
    document.body.style.pointerEvents = "unset";
    DragDropEvent.emit("onDragEnd", params);
  };

  onDragStart = params => {
    document.body.style.pointerEvents = "none";
    DragDropEvent.emit("onDragStart", params);
  };

  render() {
    const { history, routes, routerKey, store } = this.props;

    return (
      <Provider store={store}>
        <div style={{ height: "100%" }}>
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}
          >
            <Router history={history} key={routerKey}>
              {routes}
            </Router>
          </DragDropContext>
        </div>
      </Provider>
    );
  }
}

export default AppContainer;
