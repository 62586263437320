import React, { useMemo } from "react";

// Components
import { I18nHOC, withLoader } from "UIComponents";
import GroupedListItem from "./GroupedListItem";

// Design system
import { Checkbox, SearchInput, EmptyState } from "@toddle-design/web";
import {
  AcademicHonestyIllustration,
  NoSearchResultsIllustration,
} from "@toddle-design/theme";

// External libs.
import { compose } from "react-apollo";

// Scss
import classes from "./GroupedList.scss";
import { Trans } from "react-i18next";

const EmptyStateComp = props => {
  const {
    searchValue,
    showSearchInput,
    emptySearchIllustration,
    emptySearchTitle,
    emptySearchSubtitle,
    emptyItemsIllustration,
    emptyItemsTitle,
    emptyItemsSubtitle,
  } = props;

  const showSearchEmptyState = showSearchInput && !_.isEmpty(searchValue);

  const illustration = showSearchEmptyState
    ? emptySearchIllustration
    : emptyItemsIllustration;

  const title = showSearchEmptyState ? emptySearchTitle : emptyItemsTitle;

  const subtitle = showSearchEmptyState
    ? emptySearchSubtitle
    : emptyItemsSubtitle;

  return (
    <EmptyState illustration={illustration} title={title} subtitle={subtitle} />
  );
};

const GroupedListContent = React.memo(props => {
  const {
    items,
    selectedItems,
    setSelectedItems,
    showEmptyState,
    subject,
    category,
    t,
  } = props;

  const getIsChecked = itemId => {
    return _.findIndex(selectedItems, ({ id }) => _.isEqual(id, itemId)) !== -1;
  };

  const groupsStatus = useMemo(() => {
    const groupsResult = {};
    _.forEach(items, item => {
      const { id, options } = item;
      const selectedItemsIds = _.map(selectedItems, ({ id }) => id);
      groupsResult[id] = _.every(options, ({ id }) =>
        _.includes(selectedItemsIds, id)
      );
    });
    return groupsResult;
  }, [items, selectedItems]);

  const handleOnGroupClick = item => {
    const { id, options: selectedGroupOptions } = item;
    const selectedGroupStatus = _.get(groupsStatus, `${id}`);

    if (selectedGroupStatus) {
      const selectedGroupOptionsIds = _.map(
        selectedGroupOptions,
        ({ id }) => id
      );
      const filteredItems = _.filter(
        selectedItems,
        ({ id }) => !_.includes(selectedGroupOptionsIds, id)
      );
      setSelectedItems(filteredItems);
    } else {
      const combinedItems = _.concat(selectedItems, selectedGroupOptions);
      const uniqueSelectedItems = _.uniqBy(combinedItems, "id");
      setSelectedItems(uniqueSelectedItems);
    }
  };

  return _.isEmpty(items) && showEmptyState ? (
    <EmptyStateComp {...props} />
  ) : (
    <>
      {category && subject && (
        <div className={classes.scoreCategoryLabelText}>
          <Trans i18nKey="classRoom:showing_grades_with_category">
            Showing grades with the category
            <span className={classes.categoryText}>{{ category }}</span>
            for
            <span className={classes.categoryText}>{{ subject }}</span>
          </Trans>
        </div>
      )}
      {_.map(items, item => (
        <React.Fragment>
          <div
            className={classes.groupOptionContainer}
            onClick={() => handleOnGroupClick(item)}
          >
            <div className={classes.labelContainer}>{item?.label}</div>
            <Checkbox isChecked={groupsStatus[item?.id]} />
          </div>

          {_.map(item?.options, option => (
            <GroupedListItem
              option={option}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              isChecked={getIsChecked(option?.id)}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
});

const GroupedListWithLoader = withLoader(GroupedListContent);

const GroupedList = React.memo(props => {
  const {
    t,
    placeholder,
    searchValue,
    onChangeSearchValue,
    showSearchInput,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.searchInputContainer}>
        {showSearchInput && (
          <SearchInput
            placeholder={placeholder ?? t("common:search")}
            value={searchValue}
            onChange={onChangeSearchValue}
          />
        )}
      </div>
      <GroupedListWithLoader t={t} {...props} />
    </div>
  );
});

GroupedList.displayName = "GroupedList";
GroupedListContent.displayName = "GroupedListContent";

GroupedList.defaultProps = {
  showSearchInput: false,
  isData: true,
  isLoading: false,
  showEmptyState: false,
  emptySearchIllustration: NoSearchResultsIllustration,
  emptySearchTitle: "",
  emptySearchSubtitle: "",
  emptyItemsIllustration: AcademicHonestyIllustration,
  emptyItemsTitle: "",
  emptyItemsSubtitle: "",
};

export default compose(I18nHOC({ resource: ["common"] }))(GroupedList);
