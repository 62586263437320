import React from "react";
import classes from "./Chip.scss";
import classNames from "classnames";

const LabelChip = props => {
  const { id, icon, label, isSelected, onClick, onFocus } = props;

  return (
    <button
      data-elm="module-filter-item"
      className={classNames({
        [classes.chipContainer]: true,
        [classes.focusState]: isSelected,
      })}
      onClick={onClick}
      tabIndex="-1"
      onMouseEnter={() => {
        onFocus({ id });
      }}
      onMouseLeave={() => {
        onFocus({ id: "search" });
      }}
    >
      <div className={classes.chipIcon}>{icon}</div>
      <div className="text-body-2 whitespace-nowrap">{label}</div>
    </button>
  );
};

export default LabelChip;
