import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import OrganizationChildResources from "OrganizationResources/routes/OrganizationChildResouces";

export default store => ({
  path: "resources",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const orgResourcesReducer = require("OrganizationResources/modules/OrganizationResourcesModule")
              .default;
            injectReducer(store, {
              key: "organizationResource",
              reducer: orgResourcesReducer,
            });

            resolve(component);
          },
          err => {
            reject(err);
          },
          "unit-planner"
        );
      })
  ),
  childRoutes: [OrganizationChildResources(store)],
});
