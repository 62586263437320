import PropTypes from "prop-types";
import React from "react";

const DetailsIcon = props => {
  return (
    <svg viewBox="0 0 12 12" width={props.width} height={props.height}>
      <g fille={props.fill && props.fill}>
        <path
          d="M11,12.000023H1c-0.551998,0-1-0.478424-1-1.030424V0.969597
       c0-0.134998,0.026001-0.266998,0.078999-0.389999C0.181,0.340599,0.369999,0.151596,0.611,0.0486
       C0.734001-0.003402,0.865002,0.000023,1,0.000023h10c0.551998,0,1,0.417576,1,0.969574v10.000002
       c0,0.134998-0.027,0.265999-0.080002,0.390999c-0.101997,0.239-0.288998,0.427-0.528999,0.530001
       C11.264999,11.943598,11.133999,12.000023,11,12.000023 M1,1.000023v10h9.999001l0.000999-10H1z"
        />
        <path
          d="M8.75,4.000023H3.269501c-0.276001,0-0.5-0.286724-0.5-0.562725s0.223999-0.437275,0.5-0.437275h5.460999
       c0.276001,0,0.5,0.161274,0.5,0.437275S9.0065,4.000023,8.75,4.000023z"
        />
        <path
          d="M8.75,6.500023H3.269501c-0.276001,0-0.5-0.247625-0.5-0.523624c0-0.276001,0.223999-0.476376,0.5-0.476376
       h5.460999c0.276001,0,0.5,0.200375,0.5,0.476376C9.230499,6.252398,9.0065,6.500023,8.75,6.500023z"
        />
        <path
          d="M8.75,9.000023H3.269501c-0.276001,0-0.5-0.207624-0.5-0.483625c0-0.275999,0.223999-0.516375,0.5-0.516375
       h5.460999c0.276001,0,0.5,0.240376,0.5,0.516375C9.230499,8.792398,9.0065,9.000023,8.75,9.000023z"
        />
      </g>
    </svg>
  );
};

DetailsIcon.defaultProps = {
  height: 16,
  width: 16,
};

DetailsIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
};

export default DetailsIcon;
