/**--external-- */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MailColored } from "@toddle-design/web-icons";

/**--internal-- */
import { GOOGLE_CLIENT_ID } from "Constants";
import {
  GoogleLoginBtn,
  MicrosoftLoginBtn,
  CustomLoginButton,
} from "UIComponents";

/**--relative-- */
import classes from "./VisitorLogin.scss";
import { innerConStyle } from "./VisitorLoginStyles";

const VisitorLogin = props => {
  const {
    renderBackButtonComponent,
    updateCurrentScreen,
    onGoogleSignInCallBack,
    onGoogelFailureCallBack,
    onMicroSoftSignInCallBack,
    onMicroSoftFailureCallBack,
  } = props;

  const onBackClick = () => {
    updateCurrentScreen({ screen: "loginHome", paramsToBeRemoved: ["role"] });
  };

  const onLoginClick = () => {
    updateCurrentScreen({
      type: "loginForm",
      userType: "staff",
      role: "visitor",
    });
  };

  const headerClasses = classNames("heading-2", classes.header);

  return (
    <div className={classes.container}>
      {renderBackButtonComponent({
        onClick: onBackClick,
        buttonText: "Toddle home",
      })}
      <h2
        className={headerClasses}
      >{`Programme evaluation team member account`}</h2>
      <ul className={classes.loginOptionsContainer}>
        <li>
          <GoogleLoginBtn
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={onGoogleSignInCallBack}
            onFailure={onGoogelFailureCallBack}
            prompt="select_account"
            innerConStyle={innerConStyle}
          />
        </li>
        <li>
          <MicrosoftLoginBtn
            onSuccess={onMicroSoftSignInCallBack}
            onFailure={onMicroSoftFailureCallBack}
            className={classes.microsoftButtonContainer}
            innerConStyle={innerConStyle}
          />
        </li>
        <li className={classes.loginOptions}>
          <CustomLoginButton
            data-test-id={"button-login-email"}
            icon={<MailColored size="x-small" />}
            buttonText={"Sign in with email"}
            onClick={onLoginClick}
            innerConStyle={innerConStyle}
          />
        </li>
      </ul>
    </div>
  );
};

export default VisitorLogin;

VisitorLogin.displayName = "VisitorLogin";

VisitorLogin.propTypes = {
  renderBackButtonComponent: PropTypes.func.isRequired,
  updateCurrentScreen: PropTypes.func.isRequired,
  onGoogleSignInCallBack: PropTypes.func.isRequired,
  onMicroSoftSignInCallBack: PropTypes.func.isRequired,
  onMicroSoftFailureCallBack: PropTypes.func.isRequired,
};
