import update from "immutability-helper";
import client from "apolloClient";
import { generateRandomId } from "Utils";
import {
  getSinglePointRubricElementFromCache,
  writeSinglePointRubricElementInCache,
  writeOrganizationSinglePointRubricsInCache,
  getOrganizationSinglePointRubricsFromCache,
} from "./SinglePointRubricGraphqlHelpers";
import {
  updateSinglePointRubricCriteriaMutation,
  updateSinglePointRubricStandardMutation,
  createSinglePointRubricStandardMutation,
  deleteSinglePointRubricStandardMutation,
  createSinglePointRubricMutation,
  deleteSinglePointRubricMutation,
} from "./SinglePointRubricMutation";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  getAssessmentToolNodeDetailsFromCache,
  writeAssessmentToolNodeDetailsInCache,
} from "modules/CommonGraphqlHelpers";
import {
  getOrganizationConstantsFromCache,
  getStaffBasicDetailsFromCache,
} from "modules/CommonGraphqlHelpers";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import {
  writeAssessmentToolOfParentInCache,
  getAssessmentToolOfParentFromCache,
} from "modules/AssessmentTool/AssessmentToolGraphqlHelpers";
import ACLStore from "lib/aclStore";

export const NAME = "singlerPointRubric";

export const saveSinglePointRubric = ({
  copyToolId,
  mode = "CREATE",
  isGlobal = true,
  title = "",
  // assessmentId = null,
  parentId,
  parentType,
  filters,
  curriculumProgramId,
} = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const rubricId = copyToolId;
    const organizationId = state.login.userInfo.org_id;

    const organizationDeatils = getOrganizationSinglePointRubricsFromCache({
      id: organizationId,
      filters,
    });

    const singlePointRubrics =
      _.get(organizationDeatils, "singlePointRubrics", []) || [];
    const organizationConstants = getOrganizationConstantsFromCache(
      organizationId
    );

    const criterias = _.map(
      organizationConstants.singlePointRubricCriterias,
      criteria => {
        return {
          ...criteria,
          isCriteriaStandardColumn: !!criteria.isCriteriaStandardColumn,
          id: generateRandomId(),
          __typename: "SinglePointRubricCriteria",
        };
      }
    );
    const standards = [
      {
        id: generateRandomId(),
        title: "",
        subTitle: "",
        __typename: "SinglePointRubricStandard",
      },
    ];

    let rubricData = getAssessmentToolNodeDetailsFromCache({
      id: rubricId,
      type: "SINGLE_POINT_RUBRIC",
    });

    if (mode == "CREATE") {
      rubricData = {
        id: generateRandomId(),
        title,
        standards,
        criterias,
        createdBy: getStaffBasicDetailsFromCache(state.login.userInfo.id),
        __typename: "SinglePointRubric",
      };
    } else {
      rubricData = {
        ...rubricData,
        id: generateRandomId(),
        title,
        createdBy: getStaffBasicDetailsFromCache(state.login.userInfo.id),
      };
    }

    const variables = {
      createdBy: state.login.userInfo.id,
      criterias: _.map(criterias, criteria =>
        _.pick(criteria, ["title", "subTitle", "isCriteriaStandardColumn"])
      ),
      standards: _.map(standards, standard =>
        _.pick(standard, ["title", "subTitle"])
      ),
      mode,
      title,
      rubricId,
      isGlobal,
      parentId,
      parentType,
      ...(isGlobal && { curriculumProgramId }),
    };

    try {
      const result = await client.mutate({
        mutation: createSinglePointRubricMutation,
        variables: variables,
        // optimisticResponse: {
        //   __typename: "Mutation",
        //   planner: {
        //     __typename: "PlannerMutations",
        //     createSinglePointRubric: rubricData
        //   }
        // },
        update: (
          cache,
          {
            data: {
              planner: { createSinglePointRubric },
            },
          }
        ) => {
          if (!_.isEmpty(createSinglePointRubric)) {
            if (isGlobal) {
              const data = {
                ...organizationDeatils,
                singlePointRubrics: [
                  ...singlePointRubrics,
                  createSinglePointRubric,
                ],
              };

              setTimeout(() =>
                writeOrganizationSinglePointRubricsInCache({
                  id: organizationId,
                  data,
                  filters,
                })
              );
            }
            let updatedAssessmentDetails = {};
            let parentAssessmentToolDetails;
            if (parentId) {
              parentAssessmentToolDetails = getAssessmentToolOfParentFromCache({
                id: parentId,
                type: parentType,
              });
              updatedAssessmentDetails = {
                ...parentAssessmentToolDetails,
                assessmentTool: createSinglePointRubric,
              };

              setTimeout(() =>
                writeAssessmentToolOfParentInCache({
                  id: parentId,
                  data: updatedAssessmentDetails,
                  type: parentType,
                })
              );
            }
          }
        },
      });

      if (!ACLStore.can("FeatureFlag:AssessmentCreationRevamp")) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:successfully_added_a_single_point_rubric",
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      }
      return _.get(result, "data.planner.createSinglePointRubric.id", null);
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      setTimeout(() =>
        writeOrganizationSinglePointRubricsInCache({
          id: organizationId,
          data: organizationDeatils,
          filters,
        })
      );
      console.error(e);
      return null;
    }
  };
};

export const deleteSinglePointRubric = ({ id, filters }) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;

    const organizationDeatils = getOrganizationSinglePointRubricsFromCache({
      id: organizationId,
      filters,
    });
    const singlePointRubrics = _.get(
      organizationDeatils,
      "singlePointRubrics",
      []
    );
    try {
      await client.mutate({
        mutation: deleteSinglePointRubricMutation,
        variables: {
          id: id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteSinglePointRubric: {
              isSuccess: true,
              warning: null,
              __typename: "DeleteOutput",
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteSinglePointRubric },
            },
          }
        ) => {
          if (deleteSinglePointRubric.isSuccess) {
            const updatedRubrics = _.filter(
              singlePointRubrics,
              rub => rub.id != id
            );
            const data = {
              ...organizationDeatils,
              singlePointRubrics: updatedRubrics,
            };

            setTimeout(() =>
              writeOrganizationSinglePointRubricsInCache({
                id: organizationId,
                data,
                filters,
              })
            );
          }
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:successfully_deleted_a_rubric_template",
          type: "tick",
          position: "toast-bottom-right",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      setTimeout(() =>
        writeOrganizationSinglePointRubricsInCache({
          id: organizationId,
          data: organizationDeatils,
          filters,
        })
      );
      console.error(e);
    }
  };
};

export const createSinglePointRubricStandard = ({ rubricId }) => {
  return async (dispatch, getState) => {
    const rubricData = getAssessmentToolNodeDetailsFromCache({
      id: rubricId,
      type: "SINGLE_POINT_RUBRIC",
    });
    try {
      const standard = {
        __typename: "SinglePointRubricStandard",
        id: generateRandomId(),
        title: "",
        subTitle: "",
      };

      await client.mutate({
        mutation: createSinglePointRubricStandardMutation,
        variables: {
          ..._.omit(standard, ["id", "__typename"]),
          rubricId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createSinglePointRubricStandard: standard,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createSinglePointRubricStandard },
            },
          }
        ) => {
          let standards = _.get(rubricData, "standards", []) || [];

          standards = [...standards, createSinglePointRubricStandard];

          const data = {
            ...rubricData,
            standards,
          };

          setTimeout(() =>
            writeAssessmentToolNodeDetailsInCache({
              id: rubricId,
              data,
              type: "SINGLE_POINT_RUBRIC",
            })
          );
        },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      setTimeout(() =>
        writeAssessmentToolNodeDetailsInCache({
          id: rubricId,
          data: rubricData,
          type: "SINGLE_POINT_RUBRIC",
        })
      );
    }
  };
};

export const deleteSinglePointRubricStandard = ({ id, rubricId }) => {
  return async (dispatch, getState) => {
    let rubricData = getAssessmentToolNodeDetailsFromCache({
      id: rubricId,
      type: "SINGLE_POINT_RUBRIC",
    });
    try {
      await client.mutate({
        mutation: deleteSinglePointRubricStandardMutation,
        variables: {
          id: id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteSinglePointRubricStandard: {
              isSuccess: true,
              warning: null,
              __typename: "DeleteOutput",
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteSinglePointRubricStandard },
            },
          }
        ) => {
          if (deleteSinglePointRubricStandard.isSuccess) {
            let standards = _.get(rubricData, "standards", []);

            standards = _.filter(standards, obj => obj.id != id);

            rubricData = {
              ...rubricData,
              standards,
            };
            setTimeout(() =>
              writeAssessmentToolNodeDetailsInCache({
                id: rubricId,
                data: rubricData,
                type: "SINGLE_POINT_RUBRIC",
              })
            );
          }
        },
      });
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      setTimeout(() =>
        writeAssessmentToolNodeDetailsInCache({
          id: rubricId,
          data: rubricData,
          type: "SINGLE_POINT_RUBRIC",
        })
      );
    }
  };
};

export const updateSinglePointRubricElement = ({ id, type, params }) => {
  return async (dispatch, getState) => {
    try {
      let mutation = "";
      switch (type) {
        case "STANDARD":
          mutation = updateSinglePointRubricStandardMutation;
          break;
        case "CRITERIA":
          mutation = updateSinglePointRubricCriteriaMutation;
          break;
      }
      await client.mutate({
        mutation,
        variables: {
          ...params,
          id,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const writeSinglePointRubricElementLocal = ({
  id,
  type,
  params,
  elementDetails,
}) => {
  return (dispatch, getState) => {
    const updatedElementDetails = { ...elementDetails, ...params };

    writeSinglePointRubricElementInCache({
      id,
      type,
      data: updatedElementDetails,
    });
  };
};

const REDUCER_HANDLERS = {};

const initialState = {};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
