import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import UnitPlanDetails from "UnitPlanDetails";

export default store => {
  return {
    path: ":tagId",
    component: withAsyncRouteLoading(
      () =>
        new Promise(cb => {
          require.ensure(
            [],
            require => {
              const component = require("./component").default;

              const unitPlanModule = require("UnitPlans/modules/UnitPlanModule")
                .default;

              const plannerReducer = require("IBPlanner/modules/IBPlannerModule")
                .default;
              injectReducer(store, { key: "planner", reducer: plannerReducer });
              injectReducer(store, {
                key: "unitPlans",
                reducer: unitPlanModule,
              });

              cb(component);
            },
            "unitPlans"
          );
        })
    ),

    childRoutes: [UnitPlanDetails(store)],
  };
};
