import React, { useState } from "react";
import classes from "./PracticeList.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withLoader, I18nHOC, AnimateHeight, Checkbox } from "UIComponents";
import { getSnPSetPracticesQuery } from "SnP/modules/SnPQueries";
import { getSnPSetPracticesFromCache } from "SnP/modules/SnPGraphqlHelpers";
import { ArrowIcon } from "SvgComponents";
import update from "immutability-helper";
import { colors } from "Constants";
import { localSearch } from "Utils";

const PracticeList = React.memo(props => {
  const { standards, t, selectedPractices, toggleSelectedPractices } = props;
  const [currentOpenStandards, changeOpenCurrentStandards] = useState([]);

  const changeOpenCurrentStandardsLocally = ({ value, id }) => {
    if (value) {
      changeOpenCurrentStandards([...currentOpenStandards, id]);
    } else {
      const index = _.indexOf(currentOpenStandards, id);
      const newCurrentOpenStandards = update(currentOpenStandards, {
        $splice: [[index, 1]],
      });

      changeOpenCurrentStandards(newCurrentOpenStandards);
    }
  };
  return (
    <div className={classes.container}>
      {_.map(standards, ({ id, label, code, practices }, index) => {
        const isOpen = _.indexOf(currentOpenStandards, id) >= 0;
        const arrowStyle = classNames(
          { [classes.arrowContainer]: true },
          { [classes.openArrow]: isOpen },
          { [classes.closeArrow]: !isOpen }
        );

        const selectedPracticesOfStandardCount = _.get(
          _.intersection(
            selectedPractices,
            _.map(practices, ({ id }) => id)
          ),
          "length",
          0
        );

        return (
          <div className={classes.standard} key={id}>
            <div
              className={classes.standardLabelContainer}
              onClick={() =>
                changeOpenCurrentStandardsLocally({ value: !isOpen, id })
              }
            >
              <div className={classes.standardLabel}>{label}</div>
              <div className={classes.rightContainer}>
                {selectedPracticesOfStandardCount > 0 && (
                  <div className={classes.selectedCount}>
                    {selectedPracticesOfStandardCount}
                  </div>
                )}
                <div className={arrowStyle}>
                  <ArrowIcon width={12} height={6} fill={colors.gray48} />
                </div>
              </div>
            </div>
            <AnimateHeight
              duration={300}
              height={isOpen ? "auto" : 0}
              style={{ width: "100%" }}
            >
              <div className={classes.practiceList}>
                {_.map(practices, ({ label, code, id }) => {
                  const isChecked = _.indexOf(selectedPractices, id) >= 0;
                  return (
                    <div
                      className={classes.practice}
                      key={id}
                      onClick={() =>
                        toggleSelectedPractices({ id, value: !isChecked })
                      }
                    >
                      <div className={classes.practiceDetails}>
                        <div className={classes.practiceCode}>{code}</div>
                        <div className={classes.practiceLabel}>{label}</div>
                      </div>
                      <div className={classes.checkbox}>
                        <Checkbox
                          isChecked={isChecked}
                          style={{ height: 24, width: 24 }}
                        ></Checkbox>
                      </div>
                    </div>
                  );
                })}
              </div>
            </AnimateHeight>
          </div>
        );
      })}
      {_.isEmpty(standards) && (
        <div className={classes.noDataText}>
          {t("common:no_with_label", { label: t("common:practice_plural") })}
        </div>
      )}
    </div>
  );
});

const getStandards = ({ frameworks, t, searchText }) => {
  const standards = _.reduce(
    frameworks,
    (result, { standards }) => {
      result = result.concat(
        _.chain(standards)
          .map(standard => {
            const { practices } = standard;
            const filteredPractices = _.filter(practices, ({ label, code }) => {
              const localeLabel = t(`snpTemplate:${label}`);
              return (
                localSearch({ text: localeLabel, searchText }) >= 0 ||
                localSearch({ text: code, searchText }) >= 0
              );
            });

            return { ...standard, practices: filteredPractices };
          })
          .filter(({ practices }) => !_.isEmpty(practices))
          .map(standard => {
            return {
              ...standard,
              label: t("snp:code_label", {
                code: standard.code,
                label: t(`snpTemplate:${standard.label}`),
              }),
              value: standard.id,

              practices: _.map(standard.practices, practice => {
                return {
                  ...practice,

                  label: t(`snpTemplate:${practice.label}`),
                  value: practice.id,
                };
              }),
            };
          })
          .value()
      );
      return result;
    },
    []
  );

  return standards;
};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    setId: state.login.userInfo.currentSnPSet,
  };
};

const mapActionCreators = {};

export default compose(
  I18nHOC({ resource: ["snpTemplate", "snp", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getSnPSetPracticesQuery, {
    name: "getSnPSetPractices",
    options: ({ setId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: setId,
      },
    }),
    props({
      getSnPSetPractices,
      ownProps: { isData, isLoading, setId, t, searchText },
    }) {
      const snpSet = getSnPSetPracticesFromCache({ setId });

      const frameworks = _.get(snpSet, "frameworks", []);
      const standards = getStandards({ frameworks, t, searchText });

      return {
        isData: !_.isEmpty(snpSet) && isData,
        standards,
        isLoading:
          getSnPSetPractices["networkStatus"] == 1 ||
          getSnPSetPractices["networkStatus"] == 2 ||
          isLoading,
      };
    },
  }),
  withLoader
)(PracticeList);
