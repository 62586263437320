import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import VerticalOverviewElements from "./routes/VerticalOverviewElements";
import HorizontalOverviewElements from "./routes/HorizontalOverviewElements";
import CurriculumMapsElements from "./routes/CurriculumMapsElements";
import Insight from "./routes/Insight";
import UnitPlanDetails from "UnitPlanDetails/index.v2";

export default store => ({
  path: "planningInsights",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("./modules/Module").default;

            injectReducer(store, { key: "planningInsights", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "planning-insights"
        );
      })
  ),
  childRoutes: [
    VerticalOverviewElements(store),
    HorizontalOverviewElements(store),
    CurriculumMapsElements(store),
    UnitPlanDetails(store),
    Insight(store),
  ],
});
