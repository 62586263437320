import { getMemoizedFunction } from "Utils";

const getFlattenOptions = ({ optionList }) => {
  return _.flatMap(optionList, ({ options }) => options);
};

export const getFlattenOptionsMemoized = getMemoizedFunction(getFlattenOptions);

const getFlattenValues = ({ valueList }) => {
  return _.flatMap(valueList, ({ values }) => values);
};

export const getFlattenValuesMemoized = getMemoizedFunction(getFlattenValues);
