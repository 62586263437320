import React from "react";
import { connect } from "react-redux";
import { UILabel } from "UIComponents";
import { fontStyle, colors } from "Constants";
import UIBaseComponent from "UIComponents/UIBaseComponent";

const styles = {
  label: {
    ...fontStyle.bold,
    color: colors.black,
    fontSize: "2rem",
    lineHeight: "2.7rem",
  },
  prompts: {
    ...fontStyle.medium,
    color: colors.gray48,
    fontSize: "1.4rem",
    lineHeight: " 1.9rem",
    marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "40px",
  },
};

class IBReflection extends UIBaseComponent {
  constructor(props) {
    super(props);
  }

  renderEdit = () => {
    return null;
  };

  getLabel = () => {
    const { label, isRequired, showErrorText } = this.props;
    return `${label}${(isRequired || showErrorText) && !!label ? `*` : ``}`;
  };

  renderLabel = () => {
    const {
      label,
      subText,
      prompts,
      userType,
      labelStyle,
      subTextStyle,
      labelComponent,
      labelContainerStyle,
    } = this.props;

    return _.isEqual(userType, "staff") ? (
      <UILabel
        label={this.getLabel()}
        subText={subText}
        labelComponent={labelComponent}
        labelStyle={labelStyle}
        labelContainerStyle={labelContainerStyle}
        subTextStyle={subTextStyle}
      />
    ) : (
      <UILabel
        label={label}
        subText={prompts}
        labelStyle={styles.label}
        subTextStyle={styles.prompts}
        labelContainerStyle={styles.labelContainer}
      />
    );
  };

  shouldShowViewEmpty = () => {
    const { responses } = this.props;

    return _.get(responses, "totalCount", 0) == 0;
  };
}

const mapStateToProps = state => {
  return {
    userType: state.login.userInfo.user_type,
  };
};

const IBReflectionWrapper = connect(mapStateToProps, null)(IBReflection);

export default IBReflectionWrapper;
