import PropTypes from "prop-types";
import React from "react";

const SubmissionIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 100 100">
      <g
        stroke="none"
        strokeWidth="1"
        fill={props.fill && props.fill}
        fillRule="evenodd"
      >
        <path d="M82.7527127,100 C94.1747573,100 100,94.2318675 100,82.9240434 L100,17.0188464 C100,5.7681325 94.1747573,0 82.7527127,0 L17.2472873,0 C5.82524272,0 0,5.71102227 0,17.0188464 L0,82.9240434 C0,94.2318675 5.82524272,100 17.2472873,100 L82.7527127,100 Z M82.4100514,90.5768132 L17.5899486,90.5768132 C12.3358081,90.5768132 9.42318675,87.7784123 9.42318675,82.295831 L9.42318675,17.6470588 C9.42318675,12.1644774 12.3358081,9.42318675 17.5899486,9.42318675 L82.4100514,9.42318675 C87.6070817,9.42318675 90.5768132,12.1644774 90.5768132,17.6470588 L90.5768132,82.295831 C90.5768132,87.7784123 87.6070817,90.5768132 82.4100514,90.5768132 Z M49.9714449,77.7841234 C51.28498,77.7841234 52.312964,77.2130211 53.3980583,76.1279269 L71.5019989,57.9668761 C72.2444318,57.2244432 72.6442033,56.2535694 72.6442033,55.0542547 C72.6442033,52.7698458 70.8737864,51.0565391 68.5893775,51.0565391 C67.3900628,51.0565391 66.3620788,51.5134209 65.6196459,52.312964 L59.1090805,58.9377499 L53.7978298,65.3340948 L54.2547116,54.0833809 L54.2547116,26.4991433 C54.2547116,24.0434038 52.4842947,22.2729869 49.9714449,22.2729869 C47.5157053,22.2729869 45.7452884,24.0434038 45.7452884,26.4991433 L45.7452884,54.0833809 L46.14506,65.2769846 L40.9480297,58.9377499 L34.4374643,52.312964 C33.6950314,51.5134209 32.6670474,51.0565391 31.4106225,51.0565391 C29.1262136,51.0565391 27.4129069,52.7698458 27.4129069,55.0542547 C27.4129069,56.2535694 27.7555682,57.2244432 28.4980011,57.9668761 L46.659052,76.1279269 C47.8012564,77.2701314 48.7721302,77.7841234 49.9714449,77.7841234 Z"></path>
      </g>
    </svg>
  );
};
SubmissionIcon.defaultProps = {
  width: 16,
  height: 16,
};
SubmissionIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default SubmissionIcon;
