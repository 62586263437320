import React from "react";
import classes from "./PublicAccessWidgets.scss";
import * as EventTracker from "lib/eventTracker";
import Explore from "./Explore";
import CommunityOverview from "./CommunityOverview";
import Testimonials from "./Testimonials";
import CommunityBenefits from "./CommunityBenefits";
import UnitPreview from "./UnitPreview";

const getWidget = (name, type) => {
  switch (name) {
    case "UnitPreview":
      return <UnitPreview type={type} />;
    case "Explore":
      return <Explore type={type} />;
    case "CommunityOverview":
      return <CommunityOverview type={type} />;
    case "Testimonials":
      return <Testimonials type={type} />;
    case "CommunityBenefits":
      return <CommunityBenefits type={type} />;
  }
};

const PublicAccessWidgets = ({ type, customStyles }) => {
  const filteredWidgets = [
    type === "unit" ? "UnitPreview" : "Explore",
    // "CommunityOverview",
    "Testimonials",
    "CommunityBenefits",
  ];

  const { container = {} } = customStyles || {};

  return (
    <div className={classes.container} style={{ ...container }}>
      {/* <div className={classes.shadowContainer} /> */}
      {_.map(filteredWidgets, name => getWidget(name, type))}
    </div>
  );
};

export default PublicAccessWidgets;

PublicAccessWidgets.defaultValue = {
  customStyles: {},
};
