import React, { createRef } from "react";
import classes from "./EmbedVideoPlayer.scss";
import ReactPlayer from "react-player";
import { PlayIconV2 } from "SvgComponents";
import { LinkOutlined } from "@toddle-design/web-icons";
class EmbedVideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showThumbnail: true,
      showPlayer: false,
      isPlayerSpace: true,
      isControlsSpace: false,
    };
    this.containerRef = createRef();
  }
  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    if (this.containerRef?.current) {
      this.setState({
        isControlsSpace:
          !this.state.isControlsSpace &&
          this.containerRef?.current.offsetWidth >= 400,
        isPlayerSpace: this.containerRef?.current.offsetWidth >= 300,
      });
    }
  }

  onClick = e => {
    if (this.props.onClick) this.props.onClick(e);
    if (this.state.isPlayerSpace) {
      e.stopPropagation();
      if (this.state.showThumbnail) this.setState({ showThumbnail: false });
      this.setState({ showPlayer: true });
    }
  };
  onPlay = () => {
    if (this.props.onPlay) this.props.onPlay();
    this.setState({ showPlayer: true });
  };

  play() {
    this.setState({ showPlayer: true });
  }
  pause() {
    this.setState({ showPlayer: false });
  }
  resetPlayer() {
    this.setState({ showThumbnail: true });
  }

  render() {
    const { itemStyle } = this.props;
    const renderElements = (
      <div
        onClick={this.onClick}
        ref={this.containerRef}
        className={this.props.className || classes.embedVidePlayerContainer}
      >
        {this.state.showThumbnail ? (
          <div
            style={{
              backgroundImage: "url(" + this.props.thumbnail + ")",
              backgroundSize: "cover",
              ...(itemStyle || {}),
            }}
            className={classes.overlayContainer}
          >
            {this.state.isPlayerSpace ? (
              <React.Fragment>
                <div className={classes.embedPlayerOverlay}></div>

                <div className={classes.middleIconContainer}>
                  <PlayIconV2
                    width={"33%"}
                    height={"33%"}
                    style={{ maxWidth: "20px", maxHeight: "24px" }}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={classes.embedPlayerOverlay}></div>
                <a
                  href={this.props.url}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={classes.linkContainer}
                  onClick={e => e.stopPropagation()}
                >
                  <div className={classes.middleIconContainer}>
                    <LinkOutlined variant={"on"} size={"xxx-small"} />
                  </div>
                </a>
              </React.Fragment>
            )}
          </div>
        ) : (
          this.state.isPlayerSpace && (
            <ReactPlayer
              url={this.props.url}
              playing={this.state.showPlayer}
              width={"100%"}
              height={"100%"}
              className={classes.embedVidePlayerContainer}
              controls={this.props.controls || this.state.isControlsSpace}
              onPlay={this.onPlay}
            />
          )
        )}
      </div>
    );
    return renderElements;
  }
}

export default EmbedVideoPlayer;
