import React from "react";
import classes from "./TaggedStudentDetails.scss";
import { ProfileCascade, I18nHOC } from "UIComponents";
import { Tooltip } from "AppComponents/ArchivedComponents";

const ProfileCadeStyle = {
  width: 32,
  height: 32,
  marginRight: 4,
};

const avatarStyle = {
  size: "small",
  shade: "light",
  randomizeColor: true,
};
const getProfileCascadeItems = students => {
  return _.map(students, student => {
    const { id, firstName, lastName, profileImage } = student.node;
    return {
      id: id,
      value: `${firstName} ${lastName}`,
      imageUrl: profileImage,
    };
  });
};

class TaggedStudentDetails extends React.PureComponent {
  render() {
    const {
      taggedStudents = [],
      t,
      customProfileCadeStyle,
      customStudentNameStyle,
    } = this.props;
    const moreLength = 1;

    const studentItems = _.map(taggedStudents, item => ({
      name: `${item.node.firstName} ${item.node.lastName}`,
      isArchived: _.get(item, "node.isArchived"),
    }));

    const taggedProfiles = getProfileCascadeItems(taggedStudents);
    return (
      <div className={classes.container}>
        <ProfileCascade
          items={taggedProfiles}
          customStyle={
            customProfileCadeStyle ? customProfileCadeStyle : ProfileCadeStyle
          }
          length={moreLength}
          showAvatar={taggedProfiles?.length >= 1}
          avatarStyles={avatarStyle}
        />
        <div
          className={classes.tagStudentNameContainer}
          style={customStudentNameStyle ? customStudentNameStyle : {}}
        >
          <Tooltip
            data={studentItems}
            tooltipPlacement={"bottom"}
            length={moreLength}
            keyToMap="name"
            label={t("common:students")}
          />
        </div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common"] })(TaggedStudentDetails);
