import React from "react";
import classes from "./TeacherLogin.scss";
import { GOOGLE_CLIENT_ID } from "Constants";
import {
  GoogleLoginBtn,
  MicrosoftLoginBtn,
  CustomLoginButton,
} from "UIComponents";
import {
  CleverColored,
  ProfileColored,
  MailColored,
  LexColored,
} from "@toddle-design/web-icons";

import { ThirdPartyAuth } from "AppComponents";

const styles = {
  innerConStyle: {
    width: "200px",
  },
};

class TeacherLogin extends React.PureComponent {
  onLoginClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "loginForm" });
  };

  onCreateAccountClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "organizationSelection" });
  };

  onBackClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "loginHome" });
  };

  updateCleverAuthRef = ref => {
    this.authCleverRef = _.get(ref, "refs.wrappedInstance", {});
  };

  updateLexAuthRef = ref => {
    this.authLexRef = _.get(ref, "refs.wrappedInstance", {});
  };

  onCleverLogInClick = () => {
    if (this.authCleverRef) {
      this.authCleverRef.onAuthClick();
    }
  };

  onLexLoginClick = () => {
    if (this.authLexRef) {
      this.authLexRef.onAuthClick();
    }
  };

  render() {
    const {
      onGoogleSignInCallBack,
      onGoogelFailureCallBack,
      onMicroSoftSignInCallBack,
      onMicroSoftFailureCallBack,
      onCleverSignInCallBack,
      onLexSignInCallBack,
      renderTradeMarkText,
      customStyle: { containerStyle, headerStyle, subTextStyle } = {},
      renderBackButtonComponent,
      t,
    } = this.props;
    return (
      <div className={classes.container} style={containerStyle}>
        {/* Hide the back button UI as of now */}
        {renderBackButtonComponent({
          onClick: this.onBackClick,
          buttonText: "Toddle home",
        })}
        <div style={{ ...headerStyle, fontSize: "3.2rem" }}>
          Teacher account
        </div>
        <div className={classes.buttonsContainer}>
          <GoogleLoginBtn
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={onGoogleSignInCallBack}
            onFailure={onGoogelFailureCallBack}
            prompt="select_account"
            innerConStyle={styles.innerConStyle}
          />

          <MicrosoftLoginBtn
            onSuccess={onMicroSoftSignInCallBack}
            onFailure={onMicroSoftFailureCallBack}
            className={classes.buttonContainer}
            innerConStyle={styles.innerConStyle}
          />

          <CustomLoginButton
            data-test-id={"button-login-clever"}
            icon={<CleverColored size="x-small" />}
            onClick={this.onCleverLogInClick}
            buttonText={"Sign in with Clever"}
            innerConStyle={styles.innerConStyle}
          />

          <CustomLoginButton
            data-test-id={"button-login-clever"}
            icon={<LexColored size="x-small" />}
            onClick={this.onLexLoginClick}
            buttonText={"Sign in with Lex"}
            innerConStyle={styles.innerConStyle}
          />

          <CustomLoginButton
            data-test-id={"button-login-email"}
            icon={<MailColored size="x-small" />}
            buttonText={"Sign in with email"}
            onClick={this.onLoginClick}
            innerConStyle={styles.innerConStyle}
          />
        </div>

        <ThirdPartyAuth
          serviceType="CLEVER"
          ref={this.updateCleverAuthRef}
          onSuccessCallBack={onCleverSignInCallBack}
        />
        <ThirdPartyAuth
          serviceType="LEX"
          ref={this.updateLexAuthRef}
          onSuccessCallBack={onLexSignInCallBack}
        />

        <div className={classes.orContainer}>
          <div className={classes.orLineContainer} />
          <div className={classes.orText}>or</div>
          <div className={classes.orLineContainer} />
        </div>

        <CustomLoginButton
          icon={<ProfileColored size="x-small" />}
          buttonText={"Create an account"}
          onClick={this.onCreateAccountClick}
          innerConStyle={styles.innerConStyle}
        />
        {renderTradeMarkText()}
      </div>
    );
  }
}

export default TeacherLogin;
