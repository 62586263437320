import { updateAttachmentMutation } from "./PdfTronViewerMutation";
import client from "apolloClient";
import { setToastMsg } from "Login/modules/LoginModule";

export const updateAttachmentPdfTron = ({ newAttachment }) => {
  return async dispatch => {
    await client.mutate({
      mutation: updateAttachmentMutation,
      variables: {
        newAttachment: _.omit(newAttachment, "isRead"),
      },
    });

    dispatch(
      setToastMsg({
        msg: "toastMsgs:saved_successfully",
        type: "tick",
        position: "toast-bottom-left",
      })
    );
  };
};
