import React from "react";
import { useI18n } from "Hooks";
import { getProjectGroupYearGroupFromCache } from "Projects/modules/ProjectGraphqlHelpers";
import { getProjectGroupTypeFromId } from "Projects/modules/ProjectModules";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "react-apollo";
import classes from "./YearGroupName.scss";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import classNames from "classnames";
import {
  PROJECT_GROUP_TYPE_COMMUNITY,
  PROJECT_GROUP_TYPE_PERSONAL,
  PROJECT_GROUP_TYPE_SERVICE_AS_ACTON,
} from "Projects/Constants/stringConstants";
import { Tooltip } from "@toddle-design/web";

const projectGroupName = {
  [PROJECT_GROUP_TYPE_PERSONAL]: "project:personal_project",
  [PROJECT_GROUP_TYPE_COMMUNITY]: "project:community_project",
  [PROJECT_GROUP_TYPE_SERVICE_AS_ACTON]: "project:service_as_action",
};

const YearGroupName = props => {
  const {
    params: { projectGroup: projectGroupId, course_id } = {},
    showSubText = true,
    projectGroupType,
  } = props;

  const { t } = useI18n(["project"]);

  const data = getProjectGroupYearGroupFromCache({ id: projectGroupId });

  let subText = "";

  if (course_id) {
    subText = _.get(getCourseDetailsFromCache({ id: course_id }), "title", "");
  } else {
    subText = _.get(data, "node.yearGroup.name", null);
  }

  const projectTypeName = projectGroupName[projectGroupType]
    ? t(projectGroupName[projectGroupType])
    : _.get(data, "node.name", "");

  const labelClass = classNames({
    ["heading-5"]: true,
    [classes.labelContainer]: true,
  });

  const subTextClass = classNames({
    ["text-body-2"]: true,
    [classes.subTextContainer]: true,
  });
  return (
    <div className={classes.textContainer}>
      <div className={labelClass}>{projectTypeName}</div>
      {showSubText && subText && (
        <Tooltip
          tooltip={<div className={classes.subtextTooltip}>{subText}</div>}
          placement={"bottom"}
        >
          <div className={subTextClass}>{subText}</div>
        </Tooltip>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { projectGroup: projectGroupId } = ownProps.params;
  const projectGroupType = getProjectGroupTypeFromId({
    state,
    projectGroupId,
  });
  return {
    projectGroupType,
  };
};

export default compose(withRouter, connect(mapStateToProps))(YearGroupName);
