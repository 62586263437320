import React from "react";
import classes from "./JoinCommunityModal.scss";
import { UIButton, UIModal, I18nHOC } from "UIComponents";
import { CancelIcon } from "SvgComponents";
import { goToCommunityOnboarding } from "modules/NavigationModule";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import * as EventTracker from "lib/eventTracker";

const JoinCommunityModal = React.memo(props => {
  const { onClose, goToCommunityOnboarding, t, eventData } = props;
  const heading = t("common:toddle_community");
  const subHeading = t(
    "teacherHomePage:introducing_toddle_community__subLabel"
  );
  const onJoinClick = e => {
    e.stopPropagation();
    if (onClose) {
      onClose();
    }

    EventTracker.recordEvent({
      eventName: "Clicked join community",
      eventData,
    });
    goToCommunityOnboarding({ saveLocation: false, isOpenInNewTab: true });
  };
  const onClick = e => {
    e.stopPropagation();
  };

  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      onRequestClose={onClose}
    >
      <div className={classes.container} onClick={onClick}>
        <div
          className={classes.cancelIcon}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <CancelIcon width={16} heigth={16} />
        </div>
        <div className={classes.middleBody}>
          <div className={classes.heading}>{heading}</div>
          <div className={classes.subHeading}>{subHeading}</div>
        </div>
        <div className={classes.footer}>
          <UIButton size={"lg"} color={"pink"} onClick={onJoinClick}>
            {t("teacherHomePage:join_now_free")}
          </UIButton>
        </div>
      </div>
    </UIModal>
  );
});

const mapActionCreators = { goToCommunityOnboarding };

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default compose(
  I18nHOC({ resource: ["teacherHomePage", "common"] }),
  connect(mapStateToProps, mapActionCreators)
)(JoinCommunityModal);
