import client from "apolloClient";
import {
  organizationFragment,
  rubricElementsFragment,
  rubricFragment,
} from "./RubricFragments";
import { getOrganizationRubricsQuery } from "./RubricQuery";

const fragmentNameMapping = {
  INDICATOR: {
    typeName: "IndicatorRubric",
    fragment: "indicator",
    fragmentName: "indicatorItem",
  },
  CRITERIA: {
    typeName: "CriteriaRubric",
    fragment: "criteria",
    fragmentName: "criteriaItem",
  },
  DESCRIPTOR: {
    typeName: "DescriptorRubric",
    fragment: "descriptor",
    fragmentName: "descriptorItem",
  },
};
export const getOrganizationRubricsFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getOrganizationRubricsQuery,
      variables: { id, filters },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeOrganizationRubricsInCache = ({ id, data, filters }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `Organization:${id}`,
      fragment: organizationFragment.rubricList,
      fragmentName: "organizationItem",
      data,
      variables: { filters },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const getRubricElementFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;
    queryData = client.readFragment({
      id: `${typeName}:${id}`,
      fragment: rubricElementsFragment[fragment],
      fragmentName: fragmentName,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};

export const writeRubricElementInCache = ({ id, data, type }) => {
  let queryData = {};
  try {
    const typeName = fragmentNameMapping[type].typeName;
    const fragment = fragmentNameMapping[type].fragment;
    const fragmentName = fragmentNameMapping[type].fragmentName;
    queryData = client.writeFragment({
      id: `${typeName}:${id}`,
      fragment: rubricElementsFragment[fragment],
      fragmentName: fragmentName,
      data,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return queryData;
  else return {};
};
