import React, { createRef } from "react";
import classes from "./PostCard.scss";
import {
  ProfileCascade,
  MediaPlaceholder,
  TextMoreLess,
  I18nHOC,
  LinkWithTooltip,
  MediaModal,
} from "UIComponents";
import { colors, LE_ASSESSMENT_TYPE_MAPPING } from "Constants";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { getTimeSince, isAudioFile } from "Utils";
import {
  CommentRemarkOutlined,
  WarningCircleOutlined,
  PortfolioOutlined,
  LockOutlined,
  StudentOutlined,
  FamilyOutlined,
  GlobeOutlined,
  TeacherTagOutlined,
  CloseOutlined,
} from "@toddle-design/web-icons";
import classNames from "classnames";
import {
  getSharedWithString,
  getSharedWithStringV2,
  isPostSeenByUser,
  hasTranslationPermission,
} from "Post/utils";
import { FooterButtons } from "../FooterButtons";
import DropDown from "../FooterButtons/DropDown";
import ACLStore from "lib/aclStore";
import { Tooltip } from "AppComponents/ArchivedComponents";
import { CreateAssessmentSvg } from "SvgComponents";
import _ from "lodash";

// Journal revamp imports
import DualProfile from "../DualProfile";
import StudentResponseV2 from "AppComponents/Post/routes/CreatePost/components/Screens/StudentResponseV2";
import PostSharedWithModal from "../PostSharedWithModal";
import { Trans } from "react-i18next";
import { IconButton } from "@toddle-design/web";
import { getPostTranslationQuery } from "../../modules/PostQuery";
import { getSettingValue } from "modules/PermissionModule";
import { markPostAsSeenByUser } from "Journal/modules/JournalModule";
import PostSeenLikeModal from "../PostSeenLikeModal";

const avatarStyle = {
  size: "medium",
  shade: "light",
  randomizeColor: true,
};

const style = {
  largeCardContainerStyle: {
    border: `solid 1px ${colors.strokeOne}`,
    boxShadow: "0px 1px 2px 0px #002E3926",
  },
  smallCardContainerStyle: {
    border: "none",
    boxShadow: "none",
  },
  containerStyle: {
    paddingBottom: "16px",
    margin: "0 24px",
    borderWidth: 1,
    borderColor: colors.borderDefault,
    borderRadius: "16px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
  },
  horizontalCardStyle: {
    width: "192px",
  },
  responseStyle: {
    marginTop: "16px",
  },
  moreStyle: {
    color: colors.textDefault,
  },
};

const getStudents = taggedStudents => {
  return _.map(taggedStudents, item => {
    return {
      isArchived: _.get(item, "node.isArchived"),
      name: `${item.node.firstName} ${item.node.lastName}`,
    };
  });
};

export const removeSelfMemoize = _.memoize(
  ({ taggedStudents, studentId }) => {
    return _.filter(taggedStudents, v => v.node.id !== studentId);
  },
  params => JSON.stringify(params)
);

const TaggedText = ({
  taggedStudents,
  elementPYP,
  createdByName,
  attachments,
  date,
  isPrivate,
  isHiddenFromStudents,
  userType,
  t,
  studentId,
  isOpenedFromStudentPortfolio,
  childName,
  isStudentResponse,
  isStudentOwnResponse,
  isLETaggedAsResource,
  LEResource,
  assesmentData,
  onLEtextClick,
  setSharingModalVisibility,
  setTaggedStudents,
  isSidePaneContent,
}) => {
  const taggedStudentsList = taggedStudents;

  const hasTaggedStudents = !_.isEmpty(taggedStudentsList);

  let showTagged;
  if (userType === "staff") {
    showTagged = hasTaggedStudents;
  } else if (userType === "parent") {
    // dont show tagged if child itself is the creator of the post
    // showTagged = createdByName !== childName;

    showTagged = false;
  } else if (userType === "student") {
    showTagged = false;
  }

  const isPortfolio = taggedStudents.length > 0;
  const isPYPElement = !!elementPYP && elementPYP.length > 0;
  const timeTextClass = classNames(
    { [classes.timeText]: true },
    { [classes.evidenceTimeText]: !isPortfolio }
  );
  const hideEvidenceIndicator = userType !== "staff";

  const getSharedWithIcons = type => {
    switch (type) {
      case "everyone":
        return <GlobeOutlined size={"xxx-small"} variant={"subtle"} />;
      case "student":
        return <StudentOutlined size={"xxx-small"} variant={"subtle"} />;
      case "family":
        return <FamilyOutlined size={"xxx-small"} variant={"subtle"} />;
      case "private":
        return <LockOutlined size={"xxx-small"} variant={"subtle"} />;
      default:
        return null;
    }
  };

  const comp = (
    <div className={classes.taggedStudentsCountContainer}>
      {`${_.size(taggedStudentsList)} ${t("common:student_plural")}`}
    </div>
  );

  const onLEClick = e => {
    onLEtextClick(e);
  };

  const showSharingModal = e => {
    e.stopPropagation();
    if (hasTaggedStudents || (isPrivate && isHiddenFromStudents))
      setSharingModalVisibility(true);
    if (hasTaggedStudents)
      setTaggedStudents(
        _.map(taggedStudentsList, student => _.get(student, "node", {}), [])
      );
  };

  const renderCreatorDetails = () => {
    if (!isStudentResponse) {
      if (isLETaggedAsResource && !isSidePaneContent) {
        return (
          <div>
            {/* Following <Trans> results: {teacher} tagged {assessment} to the post */}
            <p className={classes.taggedAssesmentMessageText}>
              <Trans i18nKey="journal:teacher_tagged_le_message">
                <b className={classes.taggedCreatedByTopContainer}>
                  {{ name: createdByName }}
                </b>
                <b
                  className={classes.assesmentLinkContainer}
                  onClick={onLEClick}
                >
                  {{ label: _.get(LEResource, "title.value", "assessment") }}
                </b>
              </Trans>
            </p>
          </div>
        );
      } else {
        return (
          <div className={classes.taggedCreatedByTopContainer}>
            {createdByName}
          </div>
        );
      }
    } else {
      const content = _.get(assesmentData, "assesment.assignment.content", "");
      if (isStudentOwnResponse) {
        return (
          <div>
            {/* Following <Trans> results: {student} shared thier own response to {assessment}  */}
            <p className={classes.taggedAssesmentMessageText}>
              <Trans
                i18nKey={
                  isSidePaneContent
                    ? "journal:shared_own_response_to_journal_without_label"
                    : "journal:shared_own_response_to_journal"
                }
              >
                <b className={classes.taggedCreatedByTopContainer}>
                  {{ name: createdByName }}
                </b>
                {!isSidePaneContent && (
                  <b
                    className={classes.assesmentLinkContainer}
                    onClick={onLEClick}
                  >
                    {{ label: _.get(content, "title.value", "assessment") }}
                  </b>
                )}
              </Trans>
            </p>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            {/* Following <Trans> results: {teacher} shared {student}'s response to {assessment} */}
            <p className={classes.taggedAssesmentMessageText}>
              <Trans
                i18nKey={
                  isSidePaneContent
                    ? "journal:shared_response_to_journal_without_label"
                    : "journal:shared_response_to_journal"
                }
              >
                <b className={classes.taggedCreatedByTopContainer}>
                  {{ createdBy: createdByName }}
                </b>
                <b className={classes.taggedCreatedByTopContainer}>
                  {{
                    student: _.get(
                      assesmentData,
                      "student.firstName",
                      "student"
                    ),
                  }}
                  &apos;s response
                </b>
                {!isSidePaneContent && (
                  <b
                    className={classes.assesmentLinkContainer}
                    onClick={onLEClick}
                  >
                    {{ label: _.get(content, "title.value", "assessment") }}
                  </b>
                )}
              </Trans>
            </p>
          </div>
        );
      }
    }
  };

  return (
    <div className={classes.taggedTextContainer}>
      <span className={classes.taggedCreatedByText}>
        {renderCreatorDetails()}

        {/* show shared with text to only staff */}
        <div className={classes.taggedCreatedByBottomContainer}>
          <span className={timeTextClass}>{getTimeSince(date, t)}</span>
          {userType === "staff" && (
            <React.Fragment>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>
              <div className={classes.iconContainer}>
                <LinkWithTooltip
                  tooltip={getSharedWithString({
                    isPrivate,
                    isHiddenFromStudents,
                    withPrefix: true,
                    t,
                  })}
                  placement={"bottom"}
                >
                  <IconButton
                    className={classes.icon}
                    onClick={showSharingModal}
                    icon={getSharedWithIcons(
                      getSharedWithStringV2({ isPrivate, isHiddenFromStudents })
                    )}
                    variant={"plain"}
                    size={"xx-small"}
                  />
                </LinkWithTooltip>
              </div>
            </React.Fragment>
          )}

          {
            // Hide Taggged students list from shared with icon tooltip
            showTagged && false && (
              <div className={classes.taggedStudentsContainer}>
                <div className={classes.dotContainer}>
                  <div className={classes.dot}></div>
                </div>

                <div className={classes.iconContainer}>
                  <div className={classes.icon}>
                    <TeacherTagOutlined size={"xxx-small"} variant={"subtle"} />
                  </div>
                </div>

                <div className={classes.taggedText}>
                  {_.size(taggedStudentsList) === 1 ? (
                    _.get(getStudents(taggedStudentsList)[0], "name")
                  ) : (
                    <Tooltip
                      data={getStudents(taggedStudentsList)}
                      length={0}
                      tooltipPlacement="bottom"
                      moreStyle={style.moreStyle}
                      moreJoinSymbol={`& `}
                      keyToMap="name"
                      label={t("common:students")}
                      renderComponent={comp}
                      trigger={["hover"]}
                      containerStyle={style.toolTipContainerStyle}
                      valueStyle={style.toolTipContainerStyle}
                    />
                  )}
                </div>
              </div>
            )
          }

          {false && isPYPElement && !hideEvidenceIndicator && (
            <div className={classes.evidenceContainer}>
              <div className={classes.dotContainer}>
                <div className={classes.dot}></div>
              </div>
              <div className={classes.iconContainer}>
                <div className={classes.icon}>
                  <PortfolioOutlined size={"xxx-small"} variant={"subtle"} />
                </div>
              </div>
              <div className={classes.evidenceText}>{t("common:evidence")}</div>
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

const ModelContent = ({
  attachments,
  toggleMediaModal,
  showMediaModal,
  activeIndex,
  toggleDownloadBeginOverlay,
}) => {
  return showMediaModal ? (
    <MediaModal
      toggleMediaModal={toggleMediaModal}
      attachments={attachments}
      activeIndex={activeIndex}
      toggleDownloadBeginOverlay={toggleDownloadBeginOverlay}
    />
  ) : null;
};

class PostCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMediaModal: false,
      showDownloadBeginOverlay: false,
      isSharingModalOpen: false,
      taggedStudentsForModal: [],
      showTranslatedText: false,
      titleText: _.get(props, "item.title", ""),
      isSeenLikeModalOpen: false,
    };
    this.embedPlayerRef = createRef();
  }

  componentDidMount = () => {
    const {
      item: post,
      item: { id },
      userId,
      markPostAsSeenByUser,
      userType,
    } = this.props;
    if (
      userType === "parent" &&
      !isPostSeenByUser({ postId: id, userId: userId })
    ) {
      markPostAsSeenByUser({
        parentIds: [id],
        userId: userId,
        postDetails: post,
      });
    }
  };

  onPostActionClick = params => {
    const { item, onPostActionClick } = this.props;
    onPostActionClick({ ...params, item });

    if (
      this.embedPlayerRef &&
      this.embedPlayerRef.current &&
      (params?.action === "POST_DETAIL" ||
        params?.action === "COMMENT" ||
        params?.action === "POST_EDIT")
    ) {
      this.embedPlayerRef?.current?.pause();
    }
  };

  onStudentResponseClick = id => {
    this.onPostActionClick({ id, action: "POST_DETAIL" });
  };

  onLEClick = (id, assessmentId) => {
    this.onPostActionClick({
      id,
      action: "LE_PREVIEW",
      assessmentId: assessmentId,
    });
  };

  toggleDownloadBeginOverlay = () => {
    this.setState({ showDownloadBeginOverlay: true });
  };

  toggleMediaModal = (value, activeIndex) => {
    this.setState({
      showMediaModal: value,
      activeIndex: activeIndex,
    });
  };

  onMediaClick = ({ activeIndex = 0 }) => {
    this.toggleMediaModal(true, activeIndex);
  };

  openPostDetails = () => {
    const { item } = this.props;
    const id = _.get(item, "id", "");
    const items = _.get(item, "items", {});
    const assesmentData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );

    !_.isEmpty(assesmentData)
      ? this.onStudentResponseClick(id)
      : this.onPostActionClick({ id, action: "POST_DETAIL" });
  };

  updateEmbedPlayerRef = ref => {
    if (this.embedPlayerRef) this.embedPlayerRef.current = ref;
  };

  toggleTranslatedText = () => {
    this.setState({ showTranslatedText: !this.state.showTranslatedText });
  };

  toggleLikeSeenModal = state => {
    this.setState({ isSeenLikeModalOpen: state });
  };

  setTitleText = text => {
    this.setState({ titleText: text });
  };
  render() {
    const {
      item: {
        title,
        id,
        titleTranslationItem = {},
        createdBy: {
          id: createdById,
          firstName,
          lastName,
          profileImage,
          type: createdByType,
        },
        elementPYP,
        resourceItem,
        createdAt,
        publishedAt,
        students: { edges: taggedStudents },
        likes,
        seen,
        comments,
        attachments,
        state,
        isPrivate,
        isHiddenFromStudents,
        rejectComment,
        approveOrRejectBy,
        items,
        unitPlan,
      },
      currentCommentPostId,
      onPostActionClick,
      onCloseClick,
      userId,
      userType,
      childName,
      studentId,
      isOpenedFromStudentPortfolio,
      canEditOrDelete,
      t,
      isArchivedClass,
      isSidePaneContent,
      isViewFromPostPage = false,
    } = this.props;
    const {
      showMediaModal,
      activeIndex,
      showDownloadBeginOverlay,
      showTranslatedText,
    } = this.state;

    const imageMaxWidth = isSidePaneContent
      ? 380
      : isViewFromPostPage
      ? 540
      : 600;

    const assesmentData = _.find(
      items,
      item => _.get(item, "itemType", "") === "STUDENT_ASSIGNMENT"
    );
    const assessmentItem = _.get(assesmentData, "item", {});
    const unitPlanFields = _.get(unitPlan, "allFields", []);
    const itemType = _.get(assesmentData, "itemType", "");
    const content = _.get(assessmentItem, "assignment.content", {});
    const assessmentType = _.get(content, "assessmentType.value", "");
    const assessmentId = _.get(content, "id", "");
    const assesmentTitle = _.get(content, "title.value", "");
    const studentData = _.get(assessmentItem, "student", {});
    const isCommentOpen = currentCommentPostId == id;
    const isPublished = state == "PUBLISHED";

    const isLETaggedAsResource =
      _.get(resourceItem, "assessmentType.value", "") == "le";

    const isPostApproveRejectCommentPermission = ACLStore.can(
      "FeatureFlag:NewApprovalFlowOfPost"
    );

    const isPostApproveRejectEnabled =
      _.isEqual(state, "REJECTED") && isPostApproveRejectCommentPermission;

    const isPostUnderApproval =
      state == "IN_APPROVAL" &&
      ((userType != "staff" && !isSidePaneContent) || isViewFromPostPage);

    const studentResponseHasAudioAttachment =
      _.size(attachments) === 1 && !_.isEmpty(assesmentData);

    const attachmentContainer = classNames(
      { [classes.attachmentContainer]: true },
      { [classes.audioAttachmentContainer]: isAudioFile({ attachments }) },
      {
        [classes.rejectedPost]: isPostApproveRejectEnabled,
      }
    );

    const titleTextContainer = classNames(
      { [classes.titleTextContainer]: true },
      {
        [classes.rejectedPost]: isPostApproveRejectEnabled,
      }
    );

    const studentResponseContainerClass = classNames({
      [classes.studentResponseContainer]: true,
      [classes.studentResponseContainerWithAudio]: studentResponseHasAudioAttachment,
    });

    const teacherFirstName = _.get(approveOrRejectBy, "firstName", "");
    const teacherLastName = _.get(approveOrRejectBy, "lastName", "");

    const assesmentStudentId = _.get(studentData, "id", 0);
    const studentFirstName = _.get(studentData, "firstName", "");
    const studentLastName = _.get(studentData, "lastName", "");
    const studentImage = _.get(studentData, "profileImage", "");
    const isStudentOwnResponse = _.get(studentData, "id", "") === createdById;
    const LEAssessmentType = LE_ASSESSMENT_TYPE_MAPPING[assessmentType];
    const Icon = _.get(LEAssessmentType, "filledIcon") || CreateAssessmentSvg;
    const iconColor = _.get(LEAssessmentType, "filledIconColor", colors.blue29);
    const isImageCarousel = _.filter(attachments, { type: "IMAGE" }).length > 1;
    const isPortraitImage =
      !isImageCarousel &&
      _.get(attachments, "[0].type", "") === "IMAGE" &&
      _.get(attachments, "[0].metadata.height", 0) >
        _.get(attachments, "[0].metadata.width", 0);
    const isVideo = _.get(attachments, "[0].type", "") === "VIDEO";
    // if isOnlyAudioAttachmentPresent is true, then display it in MediaPlaceholder
    // else display it in AttachmentList below TestMoreLess as it will be an audio caption

    const onLETextClick = e => {
      e.stopPropagation();
      if (!_.isEmpty(assesmentData)) this.onLEClick(id, assessmentId);
      else if (isLETaggedAsResource)
        this.onLEClick(id, _.get(resourceItem, "id", ""));
    };
    return (
      <div
        className={classes.container}
        style={{
          ...(this.props.containerStyle || {}),
          ...(!this.props.isSidePaneContent
            ? style.largeCardContainerStyle
            : style.smallCardContainerStyle),
        }}
      >
        {this.state.isSharingModalOpen && (
          <PostSharedWithModal
            isModalOpen={this.state.isSharingModalOpen}
            closeSharingModal={() =>
              this.setState({ isSharingModalOpen: false })
            }
            post={this.props.item}
            taggedStudents={this.state.taggedStudentsForModal}
            isPrivate={isPrivate}
            isHiddenFromStudents={isHiddenFromStudents}
            t={t}
          />
        )}
        {this.state.isSeenLikeModalOpen && (
          <PostSeenLikeModal
            isModalOpen={this.state.isSeenLikeModalOpen}
            closeSeenLikeModal={() =>
              this.setState({ isSeenLikeModalOpen: false })
            }
            postId={id}
            t={t}
          />
        )}
        {isPostApproveRejectEnabled && !isSidePaneContent && (
          <div>
            <div className={classes.rejectTextContainer}>
              <span className={classes.rejectText}>
                {t("journal:teacher_returned_for_review", {
                  label: `${teacherFirstName} ${teacherLastName}`,
                })}
              </span>
            </div>
            {!_.isEmpty(rejectComment) && (
              <div className={classes.rejectCommentContainer}>
                <div className={classes.rejectCommentIcon}>
                  <CommentRemarkOutlined />
                </div>
                <span className={classes.rejectCommentText}>
                  {rejectComment}
                </span>
              </div>
            )}
          </div>
        )}
        {isPostUnderApproval && (
          <div className={classes.approvalContainer}>
            {t("journal:waiting_teacher_approval")}
          </div>
        )}
        {
          // Hide top banner having assessment title
          !_.isEmpty(assesmentData) && false && (
            <div className={classes.leContainer}>
              <span className={classes.leInnerContainer}>
                <div
                  className={classes.assessmentContainer}
                  onClick={() => this.onLEClick(id, assessmentId)}
                >
                  <div className={classes.leIconContainer}>
                    <Icon
                      width={20}
                      height={20}
                      fill={iconColor}
                      fill1={colors.white}
                    />
                  </div>
                  <div className={classes.assessmentTitle}>
                    {_.get(content, "title.value", "")}
                  </div>
                </div>
              </span>
            </div>
          )
        }
        <div
          className={classes.createdByWrapper}
          onClick={this.openPostDetails}
        >
          <div className={classes.createdByContainer}>
            <div className={classes.createdByLeftContainer}>
              <DualProfile
                user1={{
                  id: createdById,
                  value: `${firstName} ${lastName ?? ""}`,
                  imageUrl: profileImage,
                }}
                user2={
                  _.isEmpty(studentData) || isStudentOwnResponse
                    ? {}
                    : {
                        id: assesmentStudentId,
                        value: `${studentFirstName} ${studentLastName ?? ""}`,
                        imageUrl: studentImage,
                      }
                }
              />
              <TaggedText
                t={t}
                postId={id}
                taggedStudents={taggedStudents}
                elementPYP={elementPYP}
                createdByName={`${firstName} ${lastName}`}
                attachments={attachments}
                date={isPublished ? publishedAt : createdAt}
                isPrivate={isPrivate}
                isHiddenFromStudents={isHiddenFromStudents}
                userType={userType}
                childName={childName}
                studentId={studentId}
                isOpenedFromStudentPortfolio={isOpenedFromStudentPortfolio}
                isStudentResponse={!_.isEmpty(assesmentData)}
                isStudentOwnResponse={isStudentOwnResponse}
                isLETaggedAsResource={isLETaggedAsResource}
                LEResource={resourceItem}
                assesmentData={{
                  assesment: assessmentItem,
                  student: studentData,
                }}
                onLEtextClick={onLETextClick}
                setSharingModalVisibility={modalState => {
                  this.setState({ isSharingModalOpen: modalState });
                }}
                setTaggedStudents={taggedStudentsList => {
                  this.setState({ taggedStudentsForModal: taggedStudentsList });
                }}
                isSidePaneContent={isSidePaneContent}
              />
            </div>
            {/* <div className={classes.createdByRightContainer}>
            {elementPYP && elementPYP.length > 0 && (
              <div className={classes.tagSvgIcon}>
                <EvidenceIcon />
              </div>
            )}
            {!!isPrivate ? (
              <HideFromParentIcon />
            ) : (
              <VisibleToParentIcon fill={colors.blue29} />
            )}
          </div> */}
            <div className={classes.moreIconContainer}>
              {canEditOrDelete && state == "PUBLISHED" && (
                <DropDown
                  userType={userType}
                  id={id}
                  onPostActionClick={onPostActionClick}
                  createdByType={createdByType}
                  isArchivedClass={isArchivedClass}
                  isAddLEToJournal={_.isEqual(itemType, "STUDENT_ASSIGNMENT")}
                />
              )}
              {isSidePaneContent && (
                <div style={{ margin: "0 8px" }}>
                  <IconButton
                    icon={<CloseOutlined />}
                    onClick={onCloseClick}
                    variant="plain"
                    size={"x-small"}
                  />
                </div>
              )}
            </div>
          </div>
          {title && (
            <div className={titleTextContainer}>
              <div className={classes.titleText}>
                <TextMoreLess
                  value={showTranslatedText ? this.state.titleText : title}
                  minHeight={230}
                />
              </div>
            </div>
          )}
        </div>
        <div className={attachmentContainer}>
          {!_.isEmpty(assesmentData) ? (
            <div className={studentResponseContainerClass}>
              {studentResponseHasAudioAttachment && (
                <MediaPlaceholder
                  attachments={attachments}
                  hasAudioCaption={true}
                  containerStyle={{ marginBottom: "8px" }}
                  showFileName={false}
                />
              )}
              <StudentResponseV2
                item={{ ...assessmentItem }}
                showSeeMore={true}
                containerStyle={{
                  ...style.containerStyle,
                  margin: isAudioFile({ attachments }) ? "0px" : "0 24px",
                }}
                horizontalCardStyle={style.horizontalCardStyle}
                showEvaluation={true}
                onClickResponse={() => this.onStudentResponseClick(id)}
                isPreviewCard={true}
                isSidePaneContent={this.props.isSidePaneContent}
              />
            </div>
          ) : _.get(attachments, "length", 0) > 0 ? (
            <MediaPlaceholder
              attachments={attachments}
              imageMaxWidth={imageMaxWidth}
              itemStyles={{
                borderRadius: "8px",
                margin: "0 auto",
                width: "92%",
              }}
              imageMaxHeight={
                isPortraitImage ? window.screen.height * 0.6 : 414
              }
              iconContainerStyle={{ width: "12%" }}
              onClickMedia={this.onMediaClick}
              hasAudioCaption={true}
              audioCaptionContainerStyles={{
                padding: isSidePaneContent
                  ? "0px 16px 16px 16px"
                  : "0px 24px 16px 24px",
              }}
              showOverlayIcons={isVideo}
              showDownloadBeginOverlay={showDownloadBeginOverlay}
              getEmbedPlayerRef={this.updateEmbedPlayerRef}
              showCarouselV2={true}
              showFileName={false}
              showIconCardsWithExtension={true}
              noteContainerStyle={{ padding: "0 24px" }}
            />
          ) : (
            <div className={classes.noAttachment}>
              {t("common:noAttachment")}
            </div>
          )}
        </div>
        <ModelContent
          attachments={attachments}
          toggleMediaModal={this.toggleMediaModal}
          showMediaModal={showMediaModal}
          activeIndex={activeIndex}
          toggleDownloadBeginOverlay={this.toggleDownloadBeginOverlay}
        />
        <FooterButtons
          userType={userType}
          id={id}
          likes={likes}
          comments={comments}
          seenCount={_.get(seen, "totalCount", 0)}
          state={state}
          userId={userId}
          isCommentOpen={isCommentOpen}
          onPostActionClick={this.onPostActionClick}
          canEditOrDelete={canEditOrDelete}
          createdByType={createdByType}
          t={t}
          createdByName={firstName}
          isArchivedClass={isArchivedClass}
          isAddLEToJournal={_.isEqual(itemType, "STUDENT_ASSIGNMENT")}
          isSidePaneContent={isSidePaneContent}
          showTranslationButton={
            hasTranslationPermission() &&
            _.get(titleTranslationItem, "showTranslate", false)
          }
          showTranslatedText={showTranslatedText}
          toggleTranslatedText={this.toggleTranslatedText}
          setTitleText={this.setTitleText}
          toggleLikeSeenModal={this.toggleLikeSeenModal}
          isViewFromPostPage={isViewFromPostPage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const localeLanguage = getSettingValue({
    name: "locale",
    organizationId: state.login.userInfo.org_id,
    userId: state.login.userInfo.id,
    userEntityType: state.login.userInfo.userEntityType,
  });

  return {
    childName: _.get(state, "login.userInfo.childName", ""),
    userType: state.login.userInfo.user_type,
    // for family portal student's id is stored in "childID"
    // for student portfolio in educator app, the student id is passed from parent component as "portfolioStudentId"
    // for student portal the id of student is stored in "id"
    studentId:
      state.login.userInfo.childID ||
      ownProps?.portfolioStudentId ||
      state.login.userInfo.id,
    isOpenedFromStudentPortfolio: !!ownProps?.portfolioStudentId,
    postId: ownProps.item.id,
  };
};

const mapActionCreators = {
  markPostAsSeenByUser,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["journal", "common"] })
)(PostCard);
