import React, { useState } from "react";
import classes from "./Header.scss";
import {
  ChevronLeftOutlined,
  PrintOutlined,
  CommentTextOutlined,
  PencilLineOutlined,
  StudentAddOutlined,
} from "@toddle-design/web-icons";
import { Button, IconButton } from "@toddle-design/web";
import { ToddleIcon } from "SvgComponents";
import { getOrganizationDetailsFromCache } from "modules/CommonGraphqlHelpers";
import {
  findCountryUser,
  isGlobalUser,
} from "routes/routes/Teacher/routes/Courses/modules/utils";
import { isMapleBearLikeEducator } from "Courses/modules/utils";
import { getAssessmentDetailsFromCache } from "Assessments/modules/AssessmentGraphqlHelpers";
import { cloneAssessment } from "Assessments/modules/AssessmentModule";
import { connect } from "react-redux";
import { getCourseDetailsFromCache } from "Course/modules/CourseGraphqlHelpers";
import { getIsCurrentAcademicYear } from "Platform/modules/PlatformModule";
import { CURRICULUM_TYPE_DP } from "Constants/stringConstants";
import { isUnitPlanEditPermission } from "UnitPlans/modules/UnitPlanModule";
import ACLStore from "lib/aclStore";
import { UIModal, I18nHOC, FullScreenLoader } from "UIComponents";
import AddAssignment from "ClassRoom/components/AddAssignment";
import {
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
  LE_ASSESSMENT_TYPE_MAPPING,
} from "Constants";
import { compose } from "react-apollo";

const getAssignmentModalData = ({ t, type }) => {
  switch (type) {
    case "ASSIGN_TO_STUDENTS":
      return {
        mode: "create",
        creationMode: "ASSIGN_TO_CLASS",
        steps: [
          {
            label: t("common:edit_with_label", {
              label: t("common:post"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
          },
        ],
        contentType: "ASSESSMENT",
        createHeader: t("common:assign_to_students"),
        updateUnitPlanDataInRedux: false,
      };
    case "COMMUNITY":
      return {
        mode: "create",
        creationMode: "IMPORT_IN_CLASS",
        steps: [
          {
            label: t("common:select_with_label", {
              label: t("common:class"),
            }),
            value: "CLASS_SELECTION",
          },
          {
            label: t("common:edit_with_label", {
              label: t("community:learning_experience"),
            }),
            value: "ASSESSMENT_DETAILS",
          },
          {
            label: t("classRoom:post_settings"),
            value: "SETTINGS",
          },
        ],
        contentType: "ASSESSMENT",
        createHeader: t("community:add_le_to_class"),
        updateUnitPlanDataInRedux: false,
      };
  }
};

const Header = props => {
  const {
    onCommentClick,
    onClickPrint,
    isDemo,
    centralRepoConfig,
    onClickEdit,
    resourceId: parentId,
    courseAcademicYearsIds,
    disableAssignToStudents,
    isCurrentAcademicYear,
    unitPlanId,
    curriculumType,
    resourceFields,
    courseId,
    t,
    course,
    assessmentType,
    onClose,
    isCommunity,
    cloneAssessment,
    isUnitPlanEditPermission,
  } = props;

  const {
    shouldDisableEdit = false,
    isGlobalOrCountryUser,
  } = centralRepoConfig;

  const [isLoading, setIsLoading] = useState(false);
  const [showAssignToStudentsModal, setShowAssignToStudentsModal] = useState(
    false
  );
  const [clonedAssessmentId, setClonedAssessmentId] = useState(null);

  const onClickAddAssignmentToStudents = () => {
    addAssignment();
  };
  const addAssignment = async () => {
    setIsLoading(true);
    const clonedAssessmentId = await cloneAssessment({
      sourceAssessmentId: parentId,
      portalType: "PLANNER",
      academicYears: courseAcademicYearsIds,
    });

    setClonedAssessmentId(clonedAssessmentId);
    setIsLoading(false);

    if (clonedAssessmentId) {
      setShowAssignToStudentsModal(true);
    }
  };

  const showAssignToStudents =
    !disableAssignToStudents &&
    isCurrentAcademicYear &&
    !_.includes([CURRICULUM_TYPE_DP], curriculumType);

  const isEditPermission = isUnitPlanEditPermission({ unitPlanId });

  const assignmentModalData = getAssignmentModalData({
    t,
    type: isCommunity ? "COMMUNITY" : "ASSIGN_TO_STUDENTS",
  });

  const image = _.get(
    _.find(resourceFields, ({ uid }) => uid == "image"),
    "value",
    ""
  );

  const title = _.get(
    _.find(resourceFields, ({ uid }) => uid == "title"),
    "value",
    ""
  );

  const imageUrl =
    image || PLACEHOLDER_RECT_IMAGE[parentId % PLACEHOLDER_RECT_IMAGE_COUNT];

  const assessmentTypeLocale =
    LE_ASSESSMENT_TYPE_MAPPING?.[assessmentType]?.localeLabel;

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer} onClick={onClose}>
        <ChevronLeftOutlined />
        <ToddleIcon />
        <div className={"heading-5"}>
          {t("common:capitalize", {
            text: t(assessmentTypeLocale),
          })}
        </div>
      </div>
      <div className={classes.rightContainer}>
        <IconButton
          icon={<CommentTextOutlined />}
          variant="outlined-subtle"
          size="large"
          onClick={onCommentClick}
        />
        <IconButton
          icon={<PrintOutlined />}
          variant="outlined-subtle"
          size="large"
          onClick={() => onClickPrint()}
        />

        <div className={classes.spiltter} />

        {!isDemo && (
          <React.Fragment>
            {!shouldDisableEdit && (
              <Button
                size="large"
                variant="outlined"
                icon={<PencilLineOutlined />}
                onClick={onClickEdit}
                disabled={
                  !isEditPermission &&
                  !ACLStore.can("TeacherPortal:ShowLeReadOnly") &&
                  !ACLStore.can("TeacherPortal:CanEditUnitPlan")
                }
              >
                Edit
              </Button>
            )}

            {!isGlobalOrCountryUser && showAssignToStudents && (
              <Button
                size="large"
                icon={<StudentAddOutlined />}
                onClick={onClickAddAssignmentToStudents}
              >
                Assign
              </Button>
            )}
          </React.Fragment>
        )}
      </div>

      {showAssignToStudentsModal && (
        <UIModal isOpen={true} isFullScreen={true}>
          <AddAssignment
            sourceAssessmentId={parentId}
            mode={assignmentModalData["mode"]}
            creationMode={assignmentModalData["creationMode"]}
            steps={assignmentModalData["steps"]}
            contentType={assignmentModalData["contentType"]}
            createHeader={assignmentModalData["createHeader"]}
            onClose={() => setShowAssignToStudentsModal(false)}
            portalType={"PLANNER"}
            assignmentDetails={{
              assignmentImage: imageUrl,
              assignmentTitle: title ?? t("common:untitled"),
            }}
            showImageTitleInHeader={true}
            clonedAssessmentId={clonedAssessmentId}
            courseId={courseId}
            updateUnitPlanDataInRedux={
              assignmentModalData["updateUnitPlanDataInRedux"]
            }
            course={course}
          />
        </UIModal>
      )}
      {isLoading && <FullScreenLoader />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { params: { assessmentId = null } = {}, curriculumType } = ownProps;
  const courseId = state.teacher.selected_class.selected_course;
  const disableAssignToStudents = !_.get(ownProps, "params.course_id", null);

  const assessmentDetails = getAssessmentDetailsFromCache(assessmentId);
  const { isImported = false } = assessmentDetails;

  const organizationId = state.login.userInfo.org_id;
  const organizationDetails = getOrganizationDetailsFromCache(organizationId);
  const organizationTags = _.get(organizationDetails, "tags", []);
  const isEducator = isMapleBearLikeEducator({ organizationTags });
  const shouldDisableActionsForEducator = isImported && isEducator;
  const userTags = _.get(state, "login.userInfo.tags", []);
  const isGlobalOrCountryUser = Boolean(
    isGlobalUser({ userTags }) || findCountryUser({ userTags })
  );

  const centralRepoConfig = {
    shouldDisableEdit: shouldDisableActionsForEducator,
    shouldDisableDelete: shouldDisableActionsForEducator,
    isGlobalOrCountryUser: isGlobalOrCountryUser,
  };

  const courseDetails = getCourseDetailsFromCache({
    id: courseId,
  });

  const courseAcademicYearsIds = _.map(
    _.get(courseDetails, "academicYears", []),
    item => item.id
  );

  const isCurrentAcademicYear = getIsCurrentAcademicYear();

  return {
    centralRepoConfig,
    courseAcademicYearsIds,
    disableAssignToStudents,
    isCurrentAcademicYear,
    curriculumType,
    courseId,
    course: courseDetails,
  };
};

const mapActionCreators = {
  cloneAssessment,
  isUnitPlanEditPermission,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators)
)(Header);
