import React, { PureComponent } from "react";
import classes from "./BasicUserDetails.scss";
import PropTypes from "prop-types";
import { DropzoneComponent, UILabel, I18nHOC } from "UIComponents";
import { emailValidation } from "services/emailValidation";
import { getRethumbUrl, getTagValues } from "Utils";
import { TextInput, SelectDropdown } from "@toddle-design/web";
import { validateForm } from "Administrator/modules/Utils";
import ACLStore from "lib/aclStore";

const styles = {
  dropZoneStyle: {
    alignItems: "flex-start",
  },
};

const PROFILE_SIZE = 152;
class BasicUserDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: "",
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        designation: false,
        curriculum: false,
      },
    };

    if (props.customRef) {
      props.customRef(this);
    }
  }

  componentDidMount = () => {
    const {
      selectedCurriculumProgramId,
      updateRedux,
      curriculumList,
      userDetails,
    } = this.props;
    if (!userDetails.curriculum) {
      let curriculum = selectedCurriculumProgramId;
      if (_.size(curriculumList) <= 1)
        curriculum = _.get(_.head(curriculumList), "value");
      updateRedux({ curriculum });
    }
  };

  onContentUploaded = params => {
    const url = _.get(params, "[0].url", "");
    if (url != "") {
      const { updateRedux } = this.props;
      updateRedux({ profileImage: url });
      this.props.onContentUploaded(url);
    }
  };

  dropzoneRenderComponent = () => {
    const { t } = this.props;
    const { profileImage } = this.props.userDetails;
    const profileImg = getRethumbUrl({
      imageUrl: profileImage,
      width: PROFILE_SIZE,
      height: PROFILE_SIZE,
    });

    return (
      <div className={classes.profileAvatar}>
        {profileImage ? (
          <React.Fragment>
            <div
              className={classes.profileBackgroundImage}
              style={{
                backgroundImage: `url(${profileImg})`,
              }}
            >
              <div className={classes.overlayDark}>
                {t("common:change_profile")}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className={classes.profileImage}>
            <div className={classes.profileImageBG}>
              <div className={classes.uploadText}>
                {t("common:add_with_label", {
                  label: t("common:lowercase", { text: t("common:image") }),
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  getDropZone = () => {
    const { t } = this.props;

    return (
      <div className={classes.dropZoneContainer}>
        <div className={classes.imageDropZone}>
          <UILabel label={t("common:profile_image")} />
          <DropzoneComponent
            accept="image/jpeg,image/png,image/jpg"
            onContentUploaded={this.onContentUploaded}
            multiple={false}
            renderComponent={this.dropzoneRenderComponent()}
            dropZoneStyle={styles.dropZoneStyle}
            // HACK: to re-render DropzoneComponent on profileImage change
            profilePicture={this.props.userDetails.profileImage}
          />
        </div>
      </div>
    );
  };

  checkEmailVerification = email => {
    const boolean = emailValidation(email);
    if (!boolean) {
      return "Enter valid email id";
    } else {
      return "";
    }
  };

  canAddMYPYearGroup = ({ isMYP }) =>
    isMYP && ACLStore.can("FeatureFlag:ShowYearGroupInMYP");

  getRequiredFields = () => {
    const {
      selectedCurriculumObj,
      showDesignation,
      showCurriculum,
    } = this.props;

    const requiredFields = ["firstName", "lastName"];
    const { isDP, isMYP } = selectedCurriculumObj || {};
    const showMYPYearGroup = this.canAddMYPYearGroup({ isMYP });

    if (isDP || showMYPYearGroup) requiredFields.push("yearGroup");
    if (isDP) requiredFields.push("registrationCategory");
    if (showDesignation) requiredFields.push("designation");
    if (showCurriculum) requiredFields.push("curriculum");
    return requiredFields;
  };

  isValid = () => {
    const { showEmail, isValidateEmail, userDetails } = this.props;

    const requiredFields = this.getRequiredFields();
    let { totalCount, newErrors } = validateForm({
      formDetails: userDetails,
      requiredFields,
    });
    const isEmailValid = emailValidation(userDetails.email);
    if ((isValidateEmail && showEmail) || !_.isEmpty(userDetails.email)) {
      newErrors["email"] = !isEmailValid;
      totalCount += !isEmailValid;
    }
    this.setState({ errors: newErrors });
    return totalCount;
  };

  getDropdownValue = ({ name, selection }) => {
    const {
      designationList,
      registrationCategoryOptions,
      yearGroupsList,
      curriculumList,
      tags,
      t,
    } = this.props;
    const tagOptions = getTagValues(tags, t);

    const dropDownLists = {
      designation: designationList,
      registrationCategory: registrationCategoryOptions,
      yearGroup: yearGroupsList,
      curriculum: curriculumList,
      tags: tagOptions,
    };
    const dropDownList = _.get(dropDownLists, [name], []);
    let dropdownValue = _.find(dropDownList, { value: selection });
    if (name == "tags")
      dropdownValue = _.filter(dropDownList, ({ value }) =>
        _.includes(selection, value)
      );
    return dropdownValue;
  };

  updateRedux = param => {
    const { errors } = this.state;
    const { updateRedux } = this.props;
    const name = _.head(_.keys(param));
    if (_.isEqual(name, "tags"))
      param["tags"] = _.map(param["tags"], ({ value }) => value);
    updateRedux(param);
    if (errors[name]) this.setState({ errors: { ...errors, [name]: false } });
  };

  render() {
    const {
      t,
      showEmail,
      disabledEmail,
      showCurriculum,
      showSourceID,
      showProfile,
      showTags = false,
      userDetails,
      curriculumList,
      showDesignation,
      designationList,
      disableCurriculum = true,
      customStyles,
      registrationCategoryOptions,
      tags,
      selectedCurriculumObj,
      yearGroupsList,
      disableYearGroup,
    } = this.props;
    const { errors } = this.state;
    const { isDP, isMYP } = selectedCurriculumObj || {};
    const dropZone = this.getDropZone();
    const isCurriculumDisabled =
      disableCurriculum || _.size(curriculumList) <= 1;
    const tagOptions = getTagValues(tags, t);
    const showMYPYearGroup = this.canAddMYPYearGroup({ isMYP });

    const designationComp = (
      <div className={classes.inputRow}>
        <div className={classes.dropdownLabel}>{t("common:role")}</div>
        <SelectDropdown
          size={"large"}
          placeholder={t("common:select_with_label", {
            label: t("common:role"),
          })}
          options={designationList}
          value={this.getDropdownValue({
            name: "designation",
            selection: userDetails.designation,
          })}
          onChange={params =>
            this.updateRedux({ designation: _.get(params, "value") })
          }
          ref={ref => (this.designationRef = ref)}
          error={errors.designation}
          isClearable={false}
        />
      </div>
    );

    const emailComp = (
      <div className={classes.inputRow}>
        <TextInput
          label={t("common:email")}
          size={"large"}
          placeholder={t("common:enter_with_label", {
            label: t("common:email"),
          })}
          onChange={e => this.updateRedux({ email: e.target.value })}
          value={userDetails.email}
          ref={ref => (this.emailRef = ref)}
          disabled={disabledEmail}
          error={errors.email}
        />
      </div>
    );

    const sourceIdComp = (
      <div className={classes.inputRow}>
        <TextInput
          label={t("common:student_sourceUID")}
          placeholder={t("common:enter_with_label", {
            label: t("common:student_sourceUID"),
          })}
          size={"large"}
          onChange={e => this.updateRedux({ sourceId: e.target.value })}
          value={userDetails.sourceId}
          ref={ref => (this.sourceIdRef = ref)}
        />
      </div>
    );

    const curriculumComp = (
      <div className={classes.inputRow}>
        <div className={classes.dropdownLabel}>{t("common:programme")}</div>
        <SelectDropdown
          size={"large"}
          isDisabled={isCurriculumDisabled}
          value={this.getDropdownValue({
            name: "curriculum",
            selection: userDetails.curriculum,
          })}
          onChange={params =>
            this.updateRedux({ curriculum: _.get(params, "value") })
          }
          placeholder={t("common:select_with_label", {
            label: t("common:programme"),
          })}
          options={curriculumList}
          error={errors.curriculum}
          isClearable={false}
        />
      </div>
    );

    const yearGroupComp = (
      <div className={classes.inputRow}>
        <div className={classes.dropdownLabel}>{t("common:year_group")}</div>
        <SelectDropdown
          size={"large"}
          placeholder={t("common:select_with_label", {
            label: t("common:year_group"),
          })}
          options={yearGroupsList}
          value={this.getDropdownValue({
            name: "yearGroup",
            selection: userDetails.yearGroup,
          })}
          onChange={params =>
            this.updateRedux({ yearGroup: _.get(params, "value") })
          }
          error={errors.yearGroup}
          isClearable={false}
          isDisabled={disableYearGroup}
        />
      </div>
    );

    const registrationCategoryYearGroupComp = (
      <div className={classes.multiInputRow}>
        <div className={classes.inputRow}>
          <div className={classes.dropdownLabel}>
            {t("common:registration_category")}
          </div>
          <SelectDropdown
            size={"large"}
            placeholder={t("common:select_with_label", {
              label: t("common:category"),
            })}
            options={registrationCategoryOptions}
            value={this.getDropdownValue({
              name: "registrationCategory",
              selection: userDetails.registrationCategory,
            })}
            onChange={params =>
              this.updateRedux({
                registrationCategory: _.get(params, "value"),
              })
            }
            error={errors.registrationCategory}
            isClearable={false}
          />
        </div>
        {yearGroupComp}
      </div>
    );

    const tagsComp = (
      <div className={classes.inputRow}>
        <div className={classes.dropdownLabel}>
          {t("common:tag_with_plural")}
        </div>
        <SelectDropdown
          size={"large"}
          ref={ref => (this.grades = ref)}
          isMulti={true}
          value={this.getDropdownValue({
            name: "tags",
            selection: userDetails.tags,
          })}
          onChange={params => this.updateRedux({ tags: params })}
          placeholder={t("common:tag_with_label", {
            label: t("common:tag_with_label"),
          })}
          options={tagOptions}
          isClearable={false}
        />
      </div>
    );

    const inputItemList = [
      { comp: designationComp, visible: showDesignation },
      { comp: emailComp, visible: showEmail },
      { comp: sourceIdComp, visible: showSourceID },
      { comp: curriculumComp, visible: showCurriculum },
      { comp: registrationCategoryYearGroupComp, visible: isDP },
      { comp: yearGroupComp, visible: showMYPYearGroup },
      { comp: tagsComp, visible: showTags },
    ];

    return (
      <div className={classes.modalBody}>
        <div className={classes.topLevel}>
          {showProfile ? dropZone : null}
          <div
            className={classes.userNameComponent}
            style={_.get(customStyles, "userNameComponent", {})}
          >
            <TextInput
              label={t("common:first_name")}
              size={"large"}
              ref={ref => (this.firstNameRef = ref)}
              value={userDetails.firstName}
              onChange={e => this.updateRedux({ firstName: e.target.value })}
              placeholder={t("common:enter_with_label", {
                label: t("common:first_name"),
              })}
              error={errors.firstName}
            />
            <TextInput
              label={t("common:last_name")}
              size={"large"}
              ref={ref => (this.lastNameRef = ref)}
              value={userDetails.lastName}
              onChange={e => this.updateRedux({ lastName: e.target.value })}
              placeholder={t("common:enter_with_label", {
                label: t("common:last_name"),
              })}
              error={errors.lastName}
            />
          </div>
        </div>

        {_.map(_.filter(inputItemList, "visible"), ({ comp }) => comp)}
      </div>
    );
  }
}

const BasicUserDetailsWrapper = I18nHOC({ resource: ["common"] })(
  BasicUserDetails
);

BasicUserDetailsWrapper.propTypes = {
  updateRedux: PropTypes.func,
  onContentUploaded: PropTypes.func,
  userDetails: PropTypes.object,
  fnName: PropTypes.string,
  lnName: PropTypes.string,
  email: PropTypes.string,
  sourceId: PropTypes.string,
  showDesignation: PropTypes.bool,
  designationList: PropTypes.array,
  disableYearGroup: PropTypes.bool,
};

BasicUserDetailsWrapper.defaultProps = {
  showEmail: true,
  showProfile: true,
  isValidateEmail: true,
  showDesignation: false,
  designationList: [],
  duplicateEmailErrorText: "",
  disableYearGroup: false,
};

export default BasicUserDetailsWrapper;
