import React, { Suspense } from "react";
import classes from "./JsonEditor.scss";

let ajv = null;

const Editor = React.lazy(async () => {
  await import(
    /* webpackChunkName: "jsoneditor" */ "jsoneditor-react/es/editor.min.css"
  );
  const { default: Ajv } = await import(
    /* webpackChunkName: "jsoneditor" */ "ajv"
  );
  ajv = new Ajv({ allErrors: true, verbose: true });
  const { JsonEditor } = await import(
    /* webpackChunkName: "jsoneditor" */ "jsoneditor-react"
  );
  return { default: JsonEditor };
});

class JsonEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderEditor: true,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.renderEditor == false) {
      this.props.parentChildMount(true);
    }
  }

  render() {
    const {
      htmlElementProps,
      onChange,
      value,
      name,
      renderEditor,
      mode,
      onError,
    } = this.props;

    return (
      <div className={classes.container}>
        <Suspense fallback={<div>Loading...</div>}>
          {renderEditor ? (
            <Editor
              value={value}
              onChange={onChange}
              ajv={ajv}
              htmlElementProps={htmlElementProps}
              name={name}
              mode={mode}
              onError={onError}
            />
          ) : null}
        </Suspense>
      </div>
    );
  }
}

export default JsonEditor;
