import React, { memo, useState } from "react";
import { Detector } from "react-detect-offline";
import { POLLING_CONFIGURATION } from "Constants";
import classes from "./NetworkStatus.scss";
import classnames from "classnames";

function NetworkStatus(props) {
  const { isOnline, setIsOnline } = props;

  const [isSnackbarActive, setSnackbarActive] = useState(false);

  const handleNetworkChange = isOnline => {
    setIsOnline(isOnline);
    setSnackbarActive(true);
  };

  return (
    <Detector
      polling={false}
      onChange={handleNetworkChange}
      render={() =>
        !isSnackbarActive ? (
          " "
        ) : isOnline ? (
          <div
            className={classnames(
              classes["snackbarContainerUp"],
              classes["snackbarContainer"]
            )}
          >
            <div
              className={classnames(
                classes["snackbarOnline"],
                classes["snackbar"]
              )}
            >
              <span className={classnames(classes["snackbarOnlineText"])}>
                {"You're back online."}
              </span>
            </div>
          </div>
        ) : (
          <div
            className={classnames(
              classes["snackbarContainerDown"],
              classes["snackbarContainer"]
            )}
          >
            <div
              className={classnames(
                classes["snackbarOffline"],
                classes["snackbar"]
              )}
            >
              <div className={classnames(classes["snackbarOfflineText"])}>
                Trying to connect. You’re currently offline. Your changes will
                not be saved.
              </div>
            </div>
          </div>
        )
      }
    />
  );
}

export default memo(NetworkStatus);
