import client from "apolloClient";

import {
  getSnPSetFrameworksQuery,
  getSnPStandardPracticesQuery,
  getSnPPracticeDetailsQuery,
  getSnPEvaluationCyclesQuery,
  getSnPCycleDetailsQuery,
  getSnPSelfStudyTemplateQuery,
  getSnPSelfStudyDetailsQuery,
  getSnPSetPracticesQuery,
  getSnPSetActionsQuery,
  getSnPActionDetailsQuery,
  getSnPActionTableDetailsQuery,
  getSnPPracticeActionDetailsQuery,
  getSnPPracticeSelfStudyResponseDetailsQuery,
} from "./SnPQueries";

import {
  selfStudyResponseFragment,
  selfStudySectionFragment,
  snpSelfStudyFragment,
  snpPracticeFragment,
  snpActionFragment,
} from "./SnPFragments";

export const getPracticeListDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `SnPPractice:${id}`,
      fragment: snpPracticeFragment.practiceList,
      fragmentName: "snpPracticeItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getPracticeActionFragmentFromCache = ({ id, cycleId }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `SnPPractice:${id}`,
      fragment: snpPracticeFragment.practiceActionDetails,
      fragmentName: "snpPracticeItem",
      variables: { evaluationCycleIds: cycleId },
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writePracticeActionDetailsInCache = ({ id, data, cycleId }) => {
  let queryData = {};
  try {
    queryData = client.writeFragment({
      id: `SnPPractice:${id}`,
      fragment: snpPracticeFragment.practiceActionDetails,
      fragmentName: "snpPracticeItem",
      variables: { evaluationCycleIds: cycleId },
      data,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const getSnPSelfStudyTemplateFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPSelfStudyTemplateQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSnPSelfStudyDetailsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPSelfStudyDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeSnPSelfStudyDetailsInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SnPSelfStudy:${id}`,
      fragment: snpSelfStudyFragment.selfStudyDetails,
      fragmentName: "snpSelfStudyItem",
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getSnPSetFrameworksFromCache = setId => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPSetFrameworksQuery,
      variables: {
        id: setId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSnPSetPracticesFromCache = ({ setId, searchText }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPSetPracticesQuery,
      variables: {
        id: setId,
        searchText,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSnPSetActionsFromCache = ({ setId, cycleId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPSetActionsQuery,
      variables: {
        id: setId,
        evaluationCycleIds: cycleId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSnPEvaluationCyclesFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPEvaluationCyclesQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSnPCycleDetailsFromCache = id => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPCycleDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStandardPracticesFromCache = ({
  id,
  searchText,
  showOnlyPracticeWithAction,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPStandardPracticesQuery,
      variables: {
        id,
        searchText,
        showOnlyPracticeWithAction,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPracticeDetailsFromCache = ({ id, cycleId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPPracticeDetailsQuery,
      variables: {
        id,
        evaluationCycleIds: cycleId,
        evaluationCycleId: cycleId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPracticeActionDetailsFromCache = ({ id, cycleId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPPracticeActionDetailsQuery,
      variables: {
        id,
        evaluationCycleIds: cycleId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getPracticeSelfStudyResponseDetailsFromCache = ({
  id,
  cycleId,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPPracticeSelfStudyResponseDetailsQuery,
      variables: {
        id,
        evaluationCycleIds: cycleId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getSelfStudyResponseFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `SnPSelfStudyResponse:${id}`,
      fragment: selfStudyResponseFragment.selfStudyResponse,
      fragmentName: "selfStudyResponseItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeSelfStudyResponseInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SnPSelfStudyResponse:${id}`,
      fragment: selfStudyResponseFragment.selfStudyResponse,
      fragmentName: "selfStudyResponseItem",
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getSelfStudySectionFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readFragment({
      id: `SnPSection:${id}`,
      fragment: selfStudySectionFragment.selfStudySection,
      fragmentName: "selfStudySectionItem",
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return queryData;
  else return {};
};

export const writeSelfStudySectionInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `SnPSection:${id}`,
      fragment: selfStudySectionFragment.selfStudySection,
      fragmentName: "selfStudySectionItem",
      data: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getActionDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPActionDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getActionTableDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getSnPActionTableDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
