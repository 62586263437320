import React from "react";
import classes from "./ImageViewer.scss";
import Viewer from "react-viewer";
// import "react-viewer/dist/index.css";
import PropTypes from "prop-types";
import FaClose from "react-icons/lib/fa/close";
import { GiveFeedbackIcon } from "SvgComponents";
import { I18nHOC } from "UIComponents";
import _ from "lodash";
import { DownloadOutlined } from "@toddle-design/web-icons";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { downloadFromUrl } from "modules/Services";

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeIndex: props.activeIndex
        ? props.activeIndex
        : this.getActiveImage(),
    };
  }

  componentDidMount = () => {
    this.setState({ visible: true });
  };

  shouldComponentUpdate = nextProps => {
    if (this.props.imageOrientation !== nextProps.imageOrientation) {
      return false;
    }
    return true;
  };

  componentDidUpdate = prevProps => {
    const { activeIndex, attachment } = this.props;

    //update local activeIndex if activeIndex from parent gets update
    if (prevProps.activeIndex != activeIndex) {
      this.setState({ activeIndex });
    }

    //update local activeIndex if attachment gets update
    if (prevProps.attachment != attachment) {
      this.setState({ activeIndex: this.getActiveImage() });
    }
  };

  showImageViewer = () => {
    this.setState({ visible: false });
  };

  onChange = (activeImage, index) => {
    const { onChangeActiveImage, attachmentsArray } = this.props;
    this.setState({ activeIndex: index });
    if (onChangeActiveImage) {
      const attachmentId = _.get(attachmentsArray[index], "id", "");
      onChangeActiveImage(attachmentId);
    }
  };

  onCloseImageViewer = e => {
    const { closeImageViewer } = this.props;

    // this.setState({ visible: false });
    if (closeImageViewer) {
      closeImageViewer(e);
    }
  };

  getActiveImage = () => {
    const { attachmentsArray, attachment } = this.props;
    const activeIndex = _.findIndex(
      attachmentsArray,
      item => item.id == attachment.id
    );
    return activeIndex ? activeIndex : 0;
  };

  getCustomToolbar = toolbars => {
    const {
      shouldShowGiveFeedbackButton,
      setShowGiveFeedbackModal,
      updateImageOrientation,
      t,
      downloadFromUrl,
      attachmentsArray,
    } = this.props;
    const { activeIndex } = this.state;

    const onClickDownloadButton = () => {
      const currentAttachment = _.get(attachmentsArray, `[${activeIndex}]`, {});

      if (downloadFromUrl) {
        downloadFromUrl({
          attachment: currentAttachment,
          shouldAskForSave: true,
        });
      }
    };

    const downloadButton = {
      key: "downloadButton",
      render: (
        <div className={classes.downloadButton}>
          <DownloadOutlined size={"xx-small"} variant={"on"} />
        </div>
      ),
      onClick: onClickDownloadButton,
    };

    const customToolbar = toolbars.concat([downloadButton]);

    if (!shouldShowGiveFeedbackButton) {
      return customToolbar;
    }

    const onClickLeft = () => {
      if (updateImageOrientation) {
        updateImageOrientation(90);
      }
    };

    const onClickRight = () => {
      if (updateImageOrientation) {
        updateImageOrientation(-90);
      }
    };

    const onClickGiveFeedback = () => {
      if (setShowGiveFeedbackModal) {
        setShowGiveFeedbackModal();
      }
    };

    const newToolbars = _.map(customToolbar, toolbar => {
      switch (toolbar.key) {
        case "rotateLeft":
          return {
            ...toolbar,
            onClick: onClickLeft,
          };
        case "rotateRight":
          return {
            ...toolbar,
            onClick: onClickRight,
          };
        default:
          return toolbar;
      }
    });

    const giveFeedbackBtn = {
      key: "giveFeedback",
      render: (
        <div className={classes.feedbackButton}>
          <GiveFeedbackIcon width={16} height={16} />
          <span className={classes.feedbackButtonText}>
            {t("common:give_feedback_text")}
          </span>
        </div>
      ),
      onClick: onClickGiveFeedback,
    };
    return [giveFeedbackBtn].concat(newToolbars);
  };

  render() {
    const {
      attachmentsArray,
      showInsideParent,
      allowDrag,
      showCloseButton,
      isDisableKeyboard,
    } = this.props;

    const imagesArray = _.map(attachmentsArray, item => {
      return {
        src:
          _.get(item, "type", "") === "WORKBOOK"
            ? _.get(item, "thumbUrl", "")
            : _.get(item, "url", ""),
        alt: _.get(item, "name", ""),
      };
    });

    const { activeIndex } = this.state;
    return (
      <div
        className={classes.container}
        ref={ref => (this.reactImageViewer = ref)}
      >
        {showCloseButton && (
          <div
            className={classes.crossSvg}
            onClick={e => this.onCloseImageViewer(e)}
          >
            <FaClose size={20} color="white" className={classes.closeIcon} />
          </div>
        )}

        <Viewer
          visible={true}
          scalable={false}
          attribute={false}
          rotatable={true}
          zoomable={true}
          drag={allowDrag}
          activeIndex={activeIndex}
          onClose={this.onCloseImageViewer}
          noFooter={false}
          noNavbar={imagesArray.length <= 1}
          changeable={imagesArray.length > 1}
          onMaskClick={this.onCloseImageViewer}
          images={imagesArray}
          onChange={this.onChange}
          noClose={true}
          className={classes.imageViewer}
          container={showInsideParent && this.reactImageViewer}
          customToolbar={this.getCustomToolbar}
          disableKeyboardSupport={isDisableKeyboard}
        />
      </div>
    );
  }
}

ImageViewer.propTypes = {
  attachmentsArray: PropTypes.array,
  attachment: PropTypes.object,
  closeImageViewer: PropTypes.func,
  allowDrag: PropTypes.bool,
  showInsideParent: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  shouldShowGiveFeedbackButton: PropTypes.bool,
  isDisableKeyboard: PropTypes.bool,
};

ImageViewer.defaultProps = {
  attachmentsArray: [],
  attachment: {},
  allowDrag: false,
  showInsideParent: false,
  showCloseButton: true,
  shouldShowGiveFeedbackButton: false,
  isDisableKeyboard: false,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapActionCreators = { downloadFromUrl };

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "common" })
)(ImageViewer);
