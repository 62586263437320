import React from "react";
import classNames from "classnames";
import classes from "./SelectableCard.scss";
import { TickCircleFilled } from "@toddle-design/web-icons";

const SelectableCard = ({ item, value, isActive, onSelect }) => {
  const containerClasses = classNames(
    { [classes.card]: true },
    { [classes.cardActive]: isActive }
  );

  return (
    <div
      className={containerClasses}
      onClick={() => {
        onSelect(value);
      }}
    >
      <div className={classes.tickContainer}>
        {isActive ? <TickCircleFilled variant={"on"} size={"x-small"} /> : null}
      </div>
      <div className={isActive ? classes.titleTextSelected : classes.titleText}>
        {item.label}
      </div>
      {item.subText && (
        <div className={isActive ? classes.subTextSelected : classes.subText}>
          {item.subText}
        </div>
      )}
    </div>
  );
};

export default SelectableCard;
