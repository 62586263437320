import React from "react";
import PropTypes from "prop-types";
import { EmptyField, UIModal, I18nHOC } from "UIComponents";
import classes from "./KeyConcepts.scss";
import SelectKCModal from "../SelectKCModal";
import UIBaseComponent from "UIComponents/UIBaseComponent";

class KeyConcepts extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalVisible: false,
      isEditModal: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { modalVisible } = this.state;
    const { modalVisible: prevModalVisible } = prevState;
    if (modalVisible != prevModalVisible) {
      if (modalVisible) {
        this.onFocus();
      } else {
        this.onBlur();
      }
    }
  };

  blur = () => {
    this.setState({ modalVisible: false });
  };

  renderLock = () => {
    return null;
  };

  onEditClick = () => {
    this.setState({ modalVisible: true, isEditModal: true });
  };

  updateInputField = params => {
    const { options } = this.props;
    this.updateValue(params[this.props.name], { nodes: options });
  };

  shouldShowEditEmpty = () => {
    const { value } = this.props;
    return !this.isValidValue(value);
  };

  renderResponseHeader = () => {
    const { responseHeaderText, mode } = this.props;
    return (
      !!responseHeaderText &&
      mode == "edit" && (
        <div
          className={classes.extraTextContainer}
          dangerouslySetInnerHTML={{ __html: responseHeaderText }}
        ></div>
      )
    );
  };

  renderEditEmpty = () => {
    const { fieldKey, t } = this.props;
    const title =
      fieldKey === "action"
        ? t("common:add_with_label", { label: t("common:action_label") })
        : t("common:add_concept_plural");
    return (
      <div className={classes.innerContainer}>
        {this.renderResponseHeader()}
        <EmptyField
          title={title}
          showAdd={true}
          onAddNewClick={() =>
            this.setState({ modalVisible: true, isEditModal: false })
          }
        />
      </div>
    );
  };

  getLabel = () => {
    const { fieldKey, t } = this.props;

    const label =
      fieldKey === "action"
        ? t("common:action_label")
        : t("common:concept_plural");

    return label;
  };

  renderChildren = () => {
    const { t, fieldKey } = this.props;
    const addLabel = this.state.isEditModal
      ? t("common:update_with_label", { label: this.getLabel() })
      : fieldKey === "action"
      ? t("common:add_with_label", { label: t("common:action_label") })
      : t("common:add_concept_plural");

    return (
      <UIModal
        isOpen={this.state.modalVisible}
        onRequestClose={() => this.setState({ modalVisible: false })}
      >
        <SelectKCModal
          addLabel={addLabel}
          updateInputField={this.updateInputField}
          keyConcepts={this.props.options}
          selectedKeyConcepts={this.props.value}
          name={this.props.name}
          isEdit={this.state.isEditModal}
          updateValueLocally={this.updateValueLocally}
          onCancelClick={() => this.setState({ modalVisible: false })}
        />
      </UIModal>
    );
  };

  renderEdit = () => {
    return null;
  };
}

KeyConcepts.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
  title: PropTypes.string,
};

KeyConcepts.defaultProps = {
  ...UIBaseComponent.defaultProps,
  title: "",
  options: [],
  value: [],
};

export default I18nHOC({ resource: ["unitPlan", "common"] })(KeyConcepts);
