import PropTypes from "prop-types";
import React from "react";

const OneDriveIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 18 12"
      fill={props.fill && props.fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5983 5.54582C11.7805 4.13973 10.0277 3.4843 8.56774 3.9953L8.56647 3.99574C7.31405 4.43777 6.48028 5.65308 6.42919 6.99123C5.66728 7.17424 5.00468 7.63989 4.70073 8.39364C4.45134 8.97975 4.44336 9.64452 4.67681 10.2357C4.82704 11.0895 5.66848 11.5016 6.40842 11.5016H15.7246C16.7812 11.5016 17.8281 10.627 17.7454 9.46098C17.7452 9.42019 17.7433 9.35084 17.7269 9.26874C17.7105 9.18695 17.6831 9.10676 17.6452 9.02409C17.4073 8.22696 16.7236 7.69245 15.9354 7.55636C15.9151 6.9104 15.6438 6.28624 15.0908 5.86307C14.414 5.27399 13.4302 5.19556 12.5983 5.54582ZM8.90783 4.9649C10.012 4.57887 11.4333 5.19419 11.8852 6.43682C11.9404 6.58847 12.0635 6.70541 12.2179 6.75257C12.3722 6.79974 12.5397 6.77166 12.6702 6.67674C13.2643 6.24468 14.0194 6.28533 14.4243 6.64524C14.4349 6.6547 14.4459 6.66371 14.4573 6.67225C14.82 6.94429 14.9836 7.41505 14.8783 7.94152C14.8467 8.09981 14.8912 8.26376 14.9987 8.38423C15.1061 8.5047 15.2639 8.56767 15.4248 8.55427C16.0319 8.50367 16.5352 8.85708 16.6675 9.34198C16.6764 9.37462 16.6885 9.4063 16.7036 9.43656C16.711 9.45141 16.7154 9.46159 16.7178 9.46776L16.7179 9.48081C16.7179 9.49505 16.7185 9.50928 16.7196 9.52348C16.7585 9.99008 16.3051 10.4741 15.7246 10.4741H6.40842C5.93657 10.4741 5.70913 10.2252 5.68519 10.0336C5.67922 9.98585 5.64762 9.95721 5.64762 9.89494C5.4975 9.54467 5.4975 9.14295 5.64762 8.79267L5.65241 8.7811C5.83489 8.3249 6.31331 7.9949 6.9991 7.93776C7.13681 7.92628 7.2641 7.85981 7.35221 7.75335C7.44032 7.64689 7.48183 7.50942 7.46737 7.37199C7.35361 6.2913 7.98258 5.29181 8.90783 4.9649Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10665 2.60522C7.21208 1.24032 8.97712 0.605165 10.6278 0.964005L10.6301 0.964508L10.6301 0.964513C12.7407 1.43354 14.1988 3.45344 14.0452 5.67997C14.0257 5.96304 13.7804 6.17669 13.4974 6.15716C13.2143 6.13764 13.0006 5.89234 13.0202 5.60927C13.1406 3.86314 11.9962 2.32129 10.4083 1.96782C9.04603 1.67219 7.54429 2.26418 6.69904 3.53206C6.55 3.75562 6.25387 3.82658 6.01969 3.69485C5.14854 3.20483 4.14138 3.48946 3.67992 4.15602L3.67839 4.15821C3.33249 4.65235 3.3053 5.32345 3.70128 6.00226C3.81298 6.19374 3.78926 6.43527 3.64246 6.60137C3.49566 6.76747 3.25888 6.82068 3.05513 6.73335C2.40809 6.45605 1.61766 6.80758 1.35324 7.57082C1.35046 7.5819 1.34776 7.59265 1.34515 7.6031C1.27706 7.87462 1.26095 7.93887 1.28988 8.05456C1.29687 8.08253 1.30149 8.11103 1.3037 8.13977C1.34684 8.70057 1.95186 9.24094 2.7095 9.24094H4.49053C4.77427 9.24094 5.00429 9.47096 5.00429 9.7547C5.00429 10.0384 4.77427 10.2685 4.49053 10.2685H2.7095C1.56231 10.2685 0.399877 9.45761 0.28284 8.26014C0.20439 7.89933 0.283751 7.59782 0.341521 7.37833C0.348632 7.35131 0.355417 7.32554 0.361539 7.30105C0.364728 7.28829 0.368406 7.27566 0.372564 7.26319C0.686767 6.32058 1.52605 5.67239 2.45236 5.61304C2.29312 4.9039 2.40919 4.18024 2.83585 3.57005C3.52797 2.57145 4.88219 2.14676 6.10665 2.60522Z"
      />
    </svg>
  );
};

OneDriveIcon.defaultProps = {
  width: 17,
  height: 12,
};
OneDriveIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
export default OneDriveIcon;
