/**--external-- */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/**--internal-- */
import { SearchBarContainer } from "AppComponents";

/**--relative-- */
import classes from "./DropdownContent.scss";
import { filterSearchStyle } from "./DropdownContentStyles";
import {
  getValues,
  getMatchingOptionListMemoized,
} from "./DropdownContentUtils";

import CheckListAccordion from "./CheckListAccordion";
import FilterAccordionGroup from "./FilterAccordionGroup";

const DropdownContent = props => {
  const [searchText, setSearchText] = useState("");

  const [openSections, setOpenSections] = useState([]);

  const { optionList, placeholder, valueList, getAllOptionLabel } = props;

  const matchingOptionList = getMatchingOptionListMemoized({
    optionList,
    searchText,
  });

  useEffect(() => {
    if (_.isEmpty(searchText)) {
      setOpenSections([]);
      return;
    }

    const sections = [];

    _.forEach(matchingOptionList, ({ key, options, isLeafNode }) => {
      sections.push(key);

      if (!isLeafNode) {
        _.forEach(options, ({ key: subSectionKey }) => {
          sections.push(`${key}-${subSectionKey}`);
        });
      }

      return setOpenSections(sections);
    });
  }, [searchText]);

  const updateOpenGroups = ({ key }) => {
    setOpenSections(openSections => {
      const updatedSections = _.filter(
        openSections,
        section => !_.startsWith(section, key)
      );

      if (_.size(updatedSections) === _.size(openSections)) {
        return [...updatedSections, key];
      }

      return updatedSections;
    });
  };

  const [filterWrapperOptionList, filterAccordionGroupOptionList] = _.partition(
    matchingOptionList,
    ({ isLeafNode }) => isLeafNode
  );

  const updateInputField = ({ key, values, subGroupKey }) => {
    let updatedValueList = [];

    /**
     * If subGroup key is not passed then
     * we are updating leaf nodes, else we will find corresponding leaf node and then update
     * that node and corresponding parent node
     */

    if (subGroupKey) {
      const oldValuesForKey = _.get(
        _.find(valueList, ({ key: groupKey }) => key == groupKey),
        "values",
        []
      );

      const updatedValuesForSubGroupKey = [
        ..._.filter(oldValuesForKey, ({ key }) => key != subGroupKey),
        { key: subGroupKey, values, isLeafNode: true },
      ];

      updatedValueList = [
        ..._.filter(valueList, ({ key: groupKey }) => key != groupKey),
        { key, values: updatedValuesForSubGroupKey, isLeafNode: false },
      ];
    } else {
      updatedValueList = [
        ..._.filter(valueList, ({ key: groupKey }) => key != groupKey),
        { key, values, isLeafNode: true },
      ];
    }

    props.updateInputField({ updatedValueList });
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <SearchBarContainer
          searchTerm={searchText}
          changeSearchTerm={val => {
            setSearchText(val);
          }}
          {...filterSearchStyle}
          placeholder={placeholder}
        />
      </div>
      {_.map(filterAccordionGroupOptionList, ({ key, label, options }) => (
        <FilterAccordionGroup
          key={key}
          label={label}
          optionList={options}
          valueGroupKey={key}
          updateInputField={updateInputField}
          valueList={getValues({ valueList, key })}
          openSections={openSections}
          updateAccordionState={updateOpenGroups}
          searchText={searchText}
          getAllOptionLabel={getAllOptionLabel}
        />
      ))}
      {_.map(filterWrapperOptionList, ({ key, label, options }) => (
        <CheckListAccordion
          key={key}
          label={label}
          options={options}
          updateInputField={values => updateInputField({ key, values })}
          valueGroupKey={key}
          values={getValues({
            valueList,
            key,
          })}
          isExpanded={_.includes(openSections, key)}
          allOptionLabel={getAllOptionLabel({ label })}
          updateAccordionState={() => updateOpenGroups({ key })}
          searchText={searchText}
        />
      ))}
    </div>
  );
};

export default DropdownContent;

DropdownContent.propTypes = {
  optionList: PropTypes.array,
  placeholder: PropTypes.string,
  valueList: PropTypes.array,
  getAllOptionLabel: PropTypes.func,
  updateInputField: PropTypes.func,
};

DropdownContent.displayName = "DropdownContent";
