import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import MaxScoreTool from "./MaxScoreTool";

const MaxScoreToolWrapper = props => {
  const { value, selectedCourseId } = props;

  const courseId = _.get(value, "courseId");

  // to fire query in MaxScoreTool.js, once state is set
  const [stateCourseId, setStateCourseId] = useState(
    courseId ? courseId : selectedCourseId
  );

  return (
    <MaxScoreTool
      {...props}
      stateCourseId={stateCourseId}
      setStateCourseId={setStateCourseId}
    />
  );
};

const mapActionCreators = {};

const mapStateToProps = state => {
  const selectedCourseId = _.get(state, "classRoom.courseId", "");

  return {
    selectedCourseId,
  };
};

export default compose(connect(mapStateToProps, mapActionCreators))(
  MaxScoreToolWrapper
);
