/**
 * This function is used to Calculate the Total score of a criteria
 *
 * It is used for both single and multi levels
 *
 * In multi level, criterionIds = [], Is empty, So we add up highest Mark of all criteria
 *
 * In Single level, criterionIds is not empty, So we calculate for specific ids only
 *
 * @param {*} param0
 * @param {Array} param0.criterionIds - Ids of Criterions
 * @param {Array} param0.criteria - The whole criteria nodes Array of Rubric Assessment
 *
 * @returns {Number} - Total score of whole rubric assessment
 */

const getTotalScore = ({ criteria, criterionIds }) => {
  let filteredCriterions = criteria;

  if (!_.isEmpty(criterionIds)) {
    filteredCriterions = _.filter(criteria, item =>
      _.includes(criterionIds, item?.id)
    );
  }

  return _.reduce(
    filteredCriterions,
    (result, criterion) => {
      result = result + _.toNumber(_.get(criterion, "highestMark", 0));
      return result;
    },
    0
  );
};

/**
 * This function is used to Calculate the Rated score of a criteria
 *
 *
 * It is used for both single and multi levels
 *
 * In multi level, criterionIds = [], Is empty, So we add up highest Mark of all criteria
 *
 * In Single level, criterionIds is not empty, So we calculate for specific ids only
 *
 * @param {*} param0
 * @param {Array} param0.criterionIds - Ids of Criterions
 * @param {Array} param0.ratedCriteria- Array of rated criteria
 *
 * @returns {Number} - Rated score of whole rubric assessment
 */

const getRatedScore = ({ criterionIds, ratedCriteria }) => {
  let criterions = ratedCriteria;

  if (!_.isEmpty(criterionIds)) {
    criterions = _.filter(ratedCriteria, item =>
      _.includes(criterionIds, item?.ibPYPElementId)
    );
  }

  return _.reduce(
    criterions,
    (result, criterion) => {
      result = result + _.toNumber(_.get(criterion, "value", 0));
      return result;
    },
    0
  );
};

export const getRatedScoreMemoized = _.memoize(getRatedScore, params =>
  JSON.stringify(params)
);

export const getTotalScoreMemoized = _.memoize(getTotalScore, params =>
  JSON.stringify(params)
);
