const getUpdatedArchivedFilters = (filters = {}, { entityType, entityId }) => {
  return {
    ...filters,
    archivalState: "ACTIVE",
    includeArchived: entityId ? { entityType, entityId } : undefined,
  };
};

const hasArchivedStudentsInList = data => {
  const totalEntities = _.size(
    _.filter(
      data,
      item =>
        _.get(item, "student.isArchived") || _.get(item, "node.isArchived")
    )
  );
  return totalEntities > 0;
};

export { getUpdatedArchivedFilters, hasArchivedStudentsInList };
