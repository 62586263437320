import React from "react";
import classes from "./DropdownList.scss";
import ListItem from "../ListItem";
import { useI18n } from "Hooks";
import { Loading } from "UIComponents";

const ItemList = ({ options, itemType, updateSelectedValue }) => {
  return _.map(options, item => {
    return (
      <div
        className={classes.itemContainer}
        key={item.value}
        onClick={() => {
          updateSelectedValue({
            selectedValue: item.value,
          });
        }}
      >
        <ListItem item={item} itemType={itemType} />
      </div>
    );
  });
};

export const DropdownList = props => {
  const {
    dropDownContainerStyle,
    options,
    itemType,
    updateSelectedValue,
    searchText,
    isLoading,
  } = props;
  const { t } = useI18n(["common"]);
  return (
    <div className={classes.container} style={dropDownContainerStyle}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.innerContainer}>
          {_.size(options) > 0 ? (
            <ItemList
              options={options}
              itemType={itemType}
              updateSelectedValue={updateSelectedValue}
            />
          ) : !_.isEmpty(searchText) ? (
            <div className={classes.noData}>
              {t("common:no_result_found_for_label", { label: searchText })}
            </div>
          ) : (
            <div className={classes.noData}>{t("common:no_data_found")}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownList;
