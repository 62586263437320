import React from "react";
import PropTypes from "prop-types";

import classes from "./CustomLoginBoxButton.scss";

const CustomLoginBoxButton = props => {
  const { iconUrl, buttonText, onClick, icon, iconContainerStyle } = props;

  return (
    <div
      className={classes.container}
      data-test-id={props["data-test-id"]}
      onClick={onClick}
    >
      {iconUrl && <img src={iconUrl} />}
      {!!icon && (
        <div className={classes.iconContainer} style={iconContainerStyle}>
          {icon}
        </div>
      )}

      <div className={classes.buttonText}>{buttonText}</div>
    </div>
  );
};

CustomLoginBoxButton.defaultProps = {
  ["data-test-id"]: "button-custom-login-box-button",
};

CustomLoginBoxButton.propTypes = {
  iconUrl: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default CustomLoginBoxButton;
