import React from "react";
import classes from "./ResourceFooter.scss";
import { UIButton } from "UIComponents";

class ResourceFooter extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.leftPane}>
          <div className={classes.selectedText}>
            {`${this.props.totalSelected} selected`}
          </div>
        </div>
        <div className={classes.rightPane}>
          <UIButton
            color="grey"
            size="sm"
            containerStyle={{ marginRight: "16px" }}
            onClick={this.props.onCancelClick}
          >
            {t("cancel")}
          </UIButton>
          <UIButton
            color="pink"
            size="sm"
            isDisabled={this.props.totalSelected == 0}
            onClick={this.props.onAddClick}
          >
            {t("add")}
          </UIButton>
        </div>
      </div>
    );
  }
}

export default ResourceFooter;
