import React from "react";
import classes from "./GqlWithLoader.scss";
import { Loading } from "UIComponents";
import classNames from "classnames";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import LoadingBar from "react-redux-loading-bar";
import { getHOCDisplayName } from "Utils";
import { colors } from "@toddle-design/theme";

function withLoader(WrappedComponent) {
  class WithLoader extends React.PureComponent {
    constructor(props) {
      super(props);
      this.wrappedComponentRef = null;
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
      if (nextProps.isLoading != this.props.isLoading) {
        if (nextProps.isData && this.props.showLoaderBar) {
          if (nextProps.isLoading) {
            nextProps.showLoading();
            //console.log("Show Loading")
          } else {
            nextProps.hideLoading();
            //console.log("Hide loading");
          }
        }
      }
    };

    componentDidMount = () => {
      // console.log('nav', navigator.onLine);

      if (this.props.isData && this.props.showLoaderBar) {
        if (this.props.isLoading) {
          this.props.showLoading();
        } else {
          this.props.hideLoading();
        }
      }
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.isData != this.props.isData) {
        if (this.props.customRef) {
          //this.props.customRef(this.wrappedComponentRef);
        }
      }
    }

    render() {
      const {
        isData,
        isLoading,
        loadingStyle,
        forwardedRef,
        containerStyle,
        showLoadingIcon,
        loadingContainerStyle,
        showLoaderBar,
      } = this.props;

      const container = classNames(
        { [classes.container]: true },
        {
          [classes.noScrollContainer]: isLoading && !isData,
        }
      );

      return (
        <div className={container} style={containerStyle}>
          {isData ? (
            <WrappedComponent {...this.props} ref={forwardedRef} />
          ) : null}
          {isLoading && !isData && !!showLoadingIcon ? (
            <Loading
              loadingStyle={loadingStyle}
              containerStyle={loadingContainerStyle}
            />
          ) : null}
          {!!showLoaderBar && (
            <LoadingBar
              style={{
                backgroundColor: colors.red66,
                height: "2px",
                position: "fixed",
                top: "0",
                left: "0",
              }}
            />
          )}
        </div>
      );
    }
  }

  WithLoader.displayName = `WithLoader(${getHOCDisplayName(WrappedComponent)})`;
  WithLoader.defaultProps = {
    showLoaderBar: true,
    showLoadingIcon: true,
    loadingStyle: {},
    containerStyle: {},
    loadingContainerStyle: {},
  };
  const mapActionCreators = {
    showLoading,
    hideLoading,
  };

  const mapStateToProps = state => {
    return {};
  };
  return connect(
    mapStateToProps,
    mapActionCreators
  )(
    React.forwardRef(function withLoader(props, ref) {
      return <WithLoader {...props} forwardedRef={props.customRef} />;
    })
  );
}

export default withLoader;
