import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "react-apollo";
import { colors } from "Constants";
import { ChevronIcon, ArrowIcon } from "SvgComponents";

import {
  InputTextField,
  UIButton,
  UIModal,
  FullScreenLoader,
  withLoader,
  Checkbox,
  DropzoneComponent,
  BulkUploadErrorModal,
  ButtonDropdown,
} from "UIComponents";
import { getStandardFeedQuery } from "Tooling/modules/ToolingQueries";
import classes from "./ManageStandard.scss";
import { updateStandardInfo } from "Tooling/modules/ToolingModule";
import { bulkUploadByFile } from "modules/Services";
import { AttachmentItem } from "Attachment";

const SLOT_VALUES = {
  key: "academicType",
  label: "Acedemic Type",
  options: [
    { label: "Generic", value: "GENERIC" },
    {
      label: "International Baccalaureate",
      value: "IB",
    },
  ],
};
const filterOptions = () => {
  return [SLOT_VALUES];
};
const styles = {
  dropZoneStyle: {},
  parentContainerStyle: {
    width: "100%",
  },
  buttonDropDownContainerStyle: {
    top: 60,
    right: 0,
    width: "100%",
    overflowY: "auto",
  },
  buttonParentStyle: {
    marginRight: "12px",
    width: "100%",
  },
  buttonComponentStyle: {
    resize: "none",
    outline: "none",
    fontSize: "1.6rem",
    backgroundColor: "inherit",
    borderRadius: " 4px",
    border: `1px solid ${colors.strokeTwo}`,
    padding: " 16px 24px",
  },
};
const getButtonComponnent = option => {
  return (
    <div className={classes.filterBox}>
      <div className={classes.filterText}>{option.label}</div>
      <div className={classes.bottomArrow}>
        <ArrowIcon width={10} height={5} />
      </div>
    </div>
  );
};
class ManageStandard extends React.PureComponent {
  constructor(props) {
    super(props);
    let id = "";
    let title = "";
    let description = "";
    let subjects = [];
    let curriculumName = [];
    let country = "";
    let framework = "GENERIC";

    let state = "";
    if (this.props.mode == "edit") {
      id = this.props.standardObject.id;
      title = this.props.standardObject.title;
      description = this.props.standardObject.description;
      subjects = this.props.standardObject.globalSubjects;
      curriculumName = this.props.standardObject.curriculumName;
      country = this.props.standardObject.country;
      state = this.props.standardObject.state;
      framework = this.props.standardObject.framework;
    }
    this.state = {
      itemDetails: {
        id,
        title,
        description,
        curriculumName,
        country,
        state,
        framework,
        subjects: subjects,
        type: "STANDARD_BENCHMARK_SET",
        url: {},
      },
      isFullScreenLoading: false,
      standardBenchmarkBulkUploadErrorModal: false,
      errors: [],
      currentStep: this.props.step[0],
    };
    this.inputRefs = {};
  }

  onContentUploaded = data => {
    this.updateOrgObject({ url: data[0] });
  };

  toggleBulkUploadErrorModal = () => {
    this.setState({
      standardBenchmarkBulkUploadErrorModal: !this.state
        .standardBenchmarkBulkUploadErrorModal,
    });
  };

  deleteAttachment = () => {
    this.setState({
      itemDetails: { ...this.state.itemDetails, ...{ url: {} } },
    });
  };

  close = () => {
    this.props.closeModal();
  };

  showButton = () => {
    const { step } = this.props;
    const { currentStep } = this.state;
    const currentStepIndex = _.indexOf(step, currentStep);
    if (currentStepIndex == _.size(step) - 1) {
      return "Save";
    } else return "Next";
  };
  onSwitch = () => {
    const errorCount = this.isValid();

    if (errorCount > 0) {
      return;
    }
    const { step } = this.props;
    const { currentStep } = this.state;
    const currentStepIndex = _.indexOf(step, currentStep);
    if (currentStepIndex == _.size(step) - 1) {
      this.onSave();
    } else this.setState({ currentStep: step[currentStepIndex + 1] });
  };

  onSave = async () => {
    const { itemDetails, currentStep } = this.state;
    const { mode, getStandardFeed } = this.props;
    this.setState({ isFullScreenLoading: true });
    switch (mode) {
      case "edit": {
        const {
          id,
          title,
          description,
          subjects,
          curriculumName,
          country,
          state,
        } = this.state.itemDetails;

        const isSuccess = await this.props.updateStandardInfo({
          input: {
            id,
            title,
            description,
            subjects,
            curriculumName,
            country,
            state,
          },
        });
        if (isSuccess) {
          this.close();
          getStandardFeed.refetch();
        }
        break;
      }
      case "create": {
        const { successful, errors } = await this.props.bulkUploadByFile({
          url: itemDetails.url.url,
          type: itemDetails.type,
          inputs: {
            subjects: itemDetails.subjects,
            standardSetDetails: {
              title: itemDetails.title,
              description: itemDetails.description,
              framework: itemDetails.framework,
              curriculumName: itemDetails.curriculumName,
              state: itemDetails.state,
              country: itemDetails.country,
            },
          },
        });
        if (successful) {
          this.close();
          getStandardFeed.refetch();
        }
        if (!successful) {
          this.setState({
            errors: errors,
          });
          this.toggleBulkUploadErrorModal();
        }
        break;
      }
    }
    this.setState({ isFullScreenLoading: false });
  };

  onClickPrevious = () => {
    const { step } = this.props;
    const { currentStep } = this.state;
    const currentStepIndex = _.indexOf(step, currentStep);
    this.setState({ currentStep: step[currentStepIndex - 1] });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);

    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  updateOrgObject = param => {
    this.setState({ itemDetails: { ...this.state.itemDetails, ...param } });
  };

  toggleSelection = param => {
    const { subjects } = this.state.itemDetails;
    let subs = subjects;
    if (_.includes(subs, param)) {
      subs = _.filter(subs, sub => sub != param);
    } else subs.push(param);
    this.setState({
      itemDetails: { ...this.state.itemDetails, ...{ subjects: subs } },
    });
  };
  updateFilters = ({ key, value }) => {
    let newitemDetails = this.state.itemDetails;
    const newFramework = value;
    newitemDetails = { ...newitemDetails, framework: newFramework };
    this.setState({
      itemDetails: newitemDetails,
    });
  };
  basicDetailsPanel = () => {
    const {
      title,
      description,
      curriculumName,
      country,
      state,
      framework,
    } = this.state.itemDetails;
    const { mode } = this.props;
    return (
      <div className={classes.editStep2}>
        {mode !== "edit" && (
          <div className={classes.horizontalRowInput}>
            {_.map(filterOptions(), opt => {
              const { key, options } = opt;
              const value = framework;
              const buttonComponent = getButtonComponnent(
                _.find(options, { value })
              );
              return (
                <ButtonDropdown
                  key={key}
                  onItemClick={value => this.updateFilters({ key, value })}
                  authTabs={options}
                  parentContainerStyle={styles.parentContainerStyle}
                  containerStyle={styles.buttonDropDownContainerStyle}
                  buttonParentStyle={styles.buttonParentStyle}
                  buttonComponentStyle={styles.buttonComponentStyle}
                  buttonComponent={
                    <div className={classes.filterLabelContainer} key={key}>
                      <span className={classes.dropDownLabel}>
                        {buttonComponent}
                      </span>
                    </div>
                  }
                />
              );
            })}
          </div>
        )}

        <div className={classes.horizontalRowInput}>
          <InputTextField
            label="Standard Title"
            error="Standard Title can not be empty"
            name={"title"}
            value={title}
            updateInputField={this.updateOrgObject}
            placeholder="Enter Standard Title"
            inputStyle={classes.titleInput}
            ref={ref => {
              this.inputRefs["title"] = ref;
            }}
            showErrorText={true}
          />
        </div>

        <div className={classes.horizontalRowInput}>
          <InputTextField
            label="Standard Description"
            error={"Standard Description can not be empty"}
            name={"description"}
            value={description}
            updateInputField={this.updateOrgObject}
            placeholder="Enter Standard Description"
            inputStyle={classes.titleInput}
            ref={ref => (this.inputRefs["description"] = ref)}
            showErrorText={true}
          />
        </div>
        <div className={classes.horizontalRowInput}>
          <InputTextField
            label="Curriculum Name"
            error={"Curriculum Name can not be empty"}
            name={"curriculumName"}
            value={curriculumName}
            updateInputField={this.updateOrgObject}
            placeholder="Enter Curriculum Name"
            inputStyle={classes.titleInput}
            ref={ref => (this.inputRefs["curriculumName"] = ref)}
            showErrorText={true}
          />
        </div>
        <div className={classes.horizontalRowInput}>
          <InputTextField
            label="Enter Country"
            name={"country"}
            value={country}
            updateInputField={this.updateOrgObject}
            placeholder="Enter Country Name"
            inputStyle={classes.titleInput}
          />
        </div>
        <div className={classes.horizontalRowInput}>
          <InputTextField
            label="Enter State or Province"
            name={"state"}
            value={state}
            updateInputField={this.updateOrgObject}
            placeholder="Enter State Name"
            inputStyle={classes.titleInput}
          />
        </div>
        {mode == "create" ? this.benchmarkPanel() : null}
      </div>
    );
  };

  benchmarkPanel = () => {
    const { type, url } = this.state.itemDetails;
    const attachment = { title: url.name, type: type };
    return (
      <div className={classes.horizontalRowInput}>
        {/* <div className={classes.horizontalFrameworkElement}>
          <InputTextField
            label="Standard Framework"
            error={"Standard Framework can not be null"}
            name={"framework"}
            value={framework}
            updateInputField={this.updateOrgObject}
            placeholder="Enter Standard Framework"
            inputStyle={classes.titleInput}
            ref={ref => (this.inputRefs["framework"] = ref)}
            showErrorText={true}
          />
        </div> */}
        <div className={classes.horizontalUploadElement}>
          <div className={classes.modalInnerHeaderUpload}>
            {"Standard Benchmark Set"}
          </div>
          <div className={classes.fileComponent}>
            {_.isEmpty(url, true) ? (
              <DropzoneComponent
                dropZoneStyle={styles.dropZoneStyle}
                key={type}
                multiple={false}
                renderComponent={
                  <UIButton color={"pink"} size={"lg"}>
                    {`Import Standard Benchmark Set`}
                  </UIButton>
                }
                accept={[".csv", "text/csv"]}
                onContentUploaded={this.onContentUploaded}
              />
            ) : (
              <AttachmentItem
                attachment={attachment}
                mode={"edit"}
                deleteAttachment={this.deleteAttachment}
              ></AttachmentItem>
            )}
          </div>
        </div>
      </div>
    );
  };

  subjectsPanel = () => {
    return (
      <div className={classes.editStep3}>
        <div className={classes.modalInnerHeader}>{`Select Subjects`}</div>
        <div className={classes.renderClass}>{this.renderSubjects()}</div>
      </div>
    );
  };

  showBackButton = () => {
    const { step } = this.props;
    const { currentStep } = this.state;
    const currentStepIndex = _.indexOf(step, currentStep);
    if (currentStepIndex != 0) {
      return (
        <div
          className={classes.exitButton}
          onClick={() => this.onClickPrevious()}
        >
          <span className={classes.backIcon}>
            <ChevronIcon fill={colors.salmon60} />
          </span>
          {"Back"}
        </div>
      );
    }
  };

  renderSubjects = () => {
    const { subjects } = this.props;
    return _.map(subjects, subject => {
      const { value, label } = subject;
      return (
        <div
          className={classes.horizontalCheckBoxElement}
          key={value}
          onClick={() => this.toggleSelection(value)}
        >
          <div className={classes.labelText}>{label}</div>
          <Checkbox
            name={label}
            value={value}
            fill={colors.blue29}
            offFill={colors.strokeTwo}
            style={{ width: 24, height: 24 }}
            checkBoxParentContainer={{ width: "auto" }}
            isChecked={
              _.includes(this.state.itemDetails.subjects, value) ? true : false
            }
          ></Checkbox>
        </div>
      );
    });
  };

  render() {
    const { isFullScreenLoading, errors, currentStep } = this.state;
    const { headerTitle } = this.props;
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={this.close}
      >
        <React.Fragment>
          <div className={classes.editStep}>
            {this.showBackButton()}
            <div className={classes.modalHeader}>{headerTitle}</div>
          </div>
          {
            {
              editStandard: this.basicDetailsPanel(),
              manageSubjects: this.subjectsPanel(),
            }[currentStep]
          }
          <div className={classes.modalFooter}>
            <UIButton size="sm" color="grey" onClick={this.close}>
              {"Cancel"}
            </UIButton>
            <div className={classes.lastButton}>
              <UIButton color="pink" size="sm" onClick={this.onSwitch}>
                {this.showButton()}
              </UIButton>
            </div>
          </div>

          {this.state.standardBenchmarkBulkUploadErrorModal ? (
            <BulkUploadErrorModal
              toggleBulkUploadErrorModal={this.toggleBulkUploadErrorModal}
              errors={errors}
            />
          ) : null}
        </React.Fragment>
        {!!isFullScreenLoading && <FullScreenLoader />}
      </UIModal>
    );
  }
}

const ManageStandardModalWithLoading = withLoader(ManageStandard);

const ManageStandardComp = React.memo(props => {
  const { closeModal } = props;
  return (
    <UIModal
      isOpen={true}
      onRequestClose={closeModal}
      modalContent={classes.modalContent}
    >
      <ManageStandardModalWithLoading {...props} />
    </UIModal>
  );
});

const mapActionCreators = {
  updateStandardInfo,
  bulkUploadByFile,
};

const mapStateToProps = () => {
  return {
    isData: true,
    isLoading: false,
    getStandardFeedQuery,
  };
};

export default compose(connect(mapStateToProps, mapActionCreators))(
  ManageStandardComp
);
