import React from "react";
import classes from "./ResourceView.scss";
import { connect } from "react-redux";
import MiddlePane from "./components/MiddlePane";
import { scrollToWithAnimation } from "Utils";
import ScreenBaseComponent from "AppComponents/ScreenBaseComponent";
import { CommentBox } from "AppComponents";
import {
  isUnitPlanEditPermission,
  getPlannerFieldMutationParams,
} from "UnitPlans/modules/UnitPlanModule";
import {
  navigateToUserProfile,
  setActiveSectionValue,
  setCoachmarkRenderStatus,
} from "Community/modules/CommunityModule";
import { goToCommunityRoute } from "modules/NavigationModule";
import { MetaTags, MediaModal } from "UIComponents";
import { GetLESteps } from "Utils";
import classNames from "classnames";
import { getRethumbUrl } from "Utils";
import * as EventTracker from "lib/eventTracker";
import * as TimeTracker from "lib/timeTracker";
import { updateField } from "modules/Services";
import { compose } from "react-apollo";
// TODO: Named imports( putting in {} ) is causing withQueryParamsBasedInterface undefined
import withQueryParamsBasedInterface from "UIComponents/QueryParamsBasedInterfaceHOC";

import Header from "./components/Header";
import ACLStore from "lib/aclStore";

import { getLECreationRevampTemplate } from "Assessments/modules/Utils";

import { updateAssessmentInRedux } from "Assessments/modules/AssessmentModule";

export const getResourceViewProps = ({
  sourceType,
  ownProps: { viewSteps },
}) => {
  let sourceTypeBasedConfig = {};

  switch (sourceType) {
    case "SNP":
      sourceTypeBasedConfig = {
        viewSteps: _.without(viewSteps, "teacherNotes"),
        middlePaneProps: {
          showFooter: false,
          showBackButton: false,
          showViewActions: false,
        },
      };
      break;
    default:
      sourceTypeBasedConfig = {};
  }

  return sourceTypeBasedConfig;
};

class ResourceView extends ScreenBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      currentStep: "",
      showMediaModal: false,
    };
    this.middlePane = null;
    this.stepsRefs = {};
    this.canCurrentStepUpdate = true;
    this.resourceViewRef = React.createRef();
  }

  UNSAFE_componentWillMount = () => {
    const { updateAssessmentInRedux, assessmentId } = this.props;
    updateAssessmentInRedux({
      assessmentId,
    });
  };

  componentDidMount() {
    this.checkCurrentStep();
    const { resourceId, isCommunity, resourceDetails, location } = this.props;
    const { title } = resourceDetails || {};
    const titleValue = _.get(title, "value", "");

    if (isCommunity) {
      EventTracker.recordEvent({
        eventName: "Viewed learning experience",
        eventData: {
          entity_type: "ASSESSMENT",
          entity_id: resourceId,
          source: "community",
          entity_name: titleValue,
          target: _.get(location, "query.target", undefined),
        },
      });
      TimeTracker.startTimer({
        page_name: "learning experience",
      });
    }
  }

  componentWillUnmount() {
    const { resourceId, isCommunity, resourceDetails, location } = this.props;
    const { title } = resourceDetails || {};
    const titleValue = _.get(title, "value", "");

    if (isCommunity) {
      EventTracker.recordEvent({
        eventName: "Viewed learning experience",
        eventData: {
          entity_type: "ASSESSMENT",
          entity_id: resourceId,
          source: "community",
          entity_name: titleValue,
          target: _.get(location, "query.target", undefined),
        },
      });
      TimeTracker.stopTimer({
        page_name: "learning experience",
        entity_id: resourceId,
        entity_type: "ASSESSMENT",
        entity_name: titleValue,
        target: _.get(location, "query.target", undefined),
      });
    }
  }

  onMiddlePaneScroll = () => {
    this.checkCurrentStep();
  };

  setCurrentStep = step => {
    this.setState({ currentStep: step });
  };

  checkCurrentStep = () => {
    const scrollContainer = this.middlePane.container;
    let currentSection = null;
    if (scrollContainer) {
      _.map(this.stepsRefs, (ref, key) => {
        if (ref) {
          const { top } = ref.getBoundingClientRect();
          if (top < scrollContainer.clientHeight / 4) {
            currentSection = key;
          }
        }
      });
    }
    if (this.state.currentStep != currentSection && this.canCurrentStepUpdate) {
      this.setCurrentStep(currentSection);
    }
  };

  onClickStep = step => {
    const scrollContainer = this.middlePane.container;
    const stepNode = this.stepsRefs[step];
    this.canCurrentStepUpdate = false;
    if (scrollContainer && stepNode) {
      const top = stepNode.offsetTop - scrollContainer.offsetTop + 4;

      scrollToWithAnimation(scrollContainer, top);
    }
    setTimeout(() => {
      this.setCurrentStep(step);
      this.canCurrentStepUpdate = true;
    }, 300);
  };

  getRightPaneComponent = () => {
    const { resourceId, parentType, resourceDetails } = this.props;
    const { currentRightPane } = this.state;
    switch (currentRightPane) {
      case "comment":
        return (
          <CommentBox
            parentType={parentType}
            nodeId={resourceId}
            type={parentType}
            headerStyle={{ border: "none" }}
            feedStyle={{ paddingTop: 0 }}
            commentBoxContainerStyle={{ minHeight: 70 }}
            onClose={this.onCommentClick}
            collaborators={_.get(resourceDetails, "unitPlan.collaborators", [])}
            isRightPaneChat={true}
          />
        );
    }
    return null;
  };

  onCommentClick = () => {
    this.handleRightPane({ rightPane: "comment" });
  };

  setStepsRef = ({ ref, key }) => {
    this.stepsRefs[key] = ref;
  };

  toggleMediaModal = value => {
    this.setState({ showMediaModal: value });
  };

  handleBack = params => {
    const {
      onClose,
      location,
      setActiveSectionValue,
      setCoachmarkRenderStatus,
    } = this.props;
    onClose(params);
    setCoachmarkRenderStatus(true);
    const target = _.get(location, "query.target");
    if (target === "banner") setActiveSectionValue("NEW_ASSESSMENT");
  };

  updateField = ({
    params,
    isMutation = false,
    isDebounce = true,
    hasAddRemoveValues = false,
    extraParams = {},
  }) => {
    const {
      portalType,
      resourceFields,
      updateFieldInCache,
      updateField,
    } = this.props;
    _.map(params, (obj, key) => {
      const fieldObj = _.find(resourceFields, { uid: key }) || {};

      let { id, value: oldValue } = fieldObj;
      const value = { id, value: obj };
      const shouldUpdate = true;
      switch (key) {
        case "tags":
          oldValue = _.map(
            _.get(fieldObj, "resolvedMinimalTree.tags", []),
            item => item.id
          );
          break;
      }

      if (shouldUpdate) {
        updateFieldInCache({
          fieldUID: key,
          params: { ...value, uid: key },
          fieldObj,
          extraParams,
        });
        const mutationParams = getPlannerFieldMutationParams({
          id,
          fieldUID: key,
          oldValue,
          hasAddRemoveValues,
          newValue: obj,
        });
        if (isMutation) {
          updateField({
            key: id,
            params: { ...(mutationParams || {}), portalType },
            type: this.props.parentType,
            isDebounce,
          });
        }
      }
    });
  };

  render() {
    const {
      userType,
      resourceDetails,
      template,
      resourceFields,
      resourceId,
      unitPlanFields,
      benchmarkSet,
      onClickEdit,
      parentType,
      onClickPrint,
      showRightPane,
      isUnitPlanEditPermission,
      unitPlanId,
      assessmentToolId,
      view_steps,
      step_list,
      isLocalisedTemplate,
      field_list,
      isCommunity,
      navigateToUserProfile,
      isCurriculumProgramFree,
      goToCommunityRoute,
      tags,
      userLoggedIn,
      curriculumType,
      middlePaneProps,
      localisedTemplateId,
      isDemo,
      assessmentTool,
      isResourceViewRevamp,
    } = this.props;
    const { currentRightPane, showMediaModal } = this.state;

    const isEditPermission = isUnitPlanEditPermission({ unitPlanId });
    const image = _.get(
      _.find(resourceFields, field => field.uid == "image"),
      "value",
      ""
    );

    const containerWrapperClasses = classNames(
      { [classes.containerWrapper]: true },
      { [classes.containerWithoutRightPane]: !isCommunity }
    );

    const rethumpUrl = image
      ? `url(${getRethumbUrl({
          width: 512,
          height: 384,
          imageUrl: image,
          fitIn: true,
        })})`
      : image;

    const resourceTitle = _.get(resourceDetails, "title.value", "");
    const resourceImage = _.get(resourceDetails, "image.value", "");
    return (
      <div className={containerWrapperClasses} ref={this.resourceViewRef}>
        <div className={classes.container}>
          {isResourceViewRevamp && (
            <Header
              onCommentClick={this.onCommentClick}
              onClose={this.handleBack}
              {...this.props}
            />
          )}

          <MiddlePane
            userType={userType}
            isCommunity={isCommunity}
            field_list={field_list}
            step_list={step_list}
            view_steps={view_steps}
            localisedTemplateId={localisedTemplateId}
            isLocalisedTemplate={isLocalisedTemplate}
            setStepsRef={this.setStepsRef}
            showRightPane={showRightPane}
            parentType={parentType}
            resourceId={resourceId}
            assessmentToolId={assessmentToolId}
            onClickPrint={onClickPrint}
            ref={ref => (this.middlePane = ref)}
            onCommentClick={this.onCommentClick}
            resourceDetails={resourceDetails}
            totalCommentCount={_.get(resourceDetails, "comments.totalCount", 0)}
            unreadCommentCount={_.get(
              resourceDetails,
              "comments.unreadCount",
              0
            )}
            template={template}
            resourceFields={resourceFields}
            benchmarkSet={benchmarkSet}
            currentRightPane={currentRightPane}
            isEditPermission={isEditPermission}
            onClickEdit={onClickEdit}
            onScroll={this.onMiddlePaneScroll}
            unitPlanFields={unitPlanFields}
            rethumpUrl={rethumpUrl}
            toggleMediaModal={this.toggleMediaModal}
            isCurriculumProgramFree={isCurriculumProgramFree}
            image={image}
            parentRef={this.resourceViewRef}
            navigateToUserProfile={navigateToUserProfile}
            onClose={this.handleBack}
            goToCommunityRoute={goToCommunityRoute}
            tags={tags}
            updateField={this.updateField}
            userLoggedIn={userLoggedIn}
            curriculumType={curriculumType}
            isDemo={isDemo}
            assessmentTool={assessmentTool}
            isResourceViewRevamp={isResourceViewRevamp}
            {...middlePaneProps}
          />
          {currentRightPane && this.renderRightPane()}

          {showMediaModal && (
            <MediaModal
              toggleMediaModal={this.toggleMediaModal}
              attachments={[{ url: image, type: "IMAGE" }]}
            />
          )}
          <MetaTags
            title={resourceTitle}
            image={resourceImage}
            url={window.location.href}
          />
        </div>
      </div>
    );
  }
}

const mapActionCreators = {
  isUnitPlanEditPermission,
  navigateToUserProfile,
  goToCommunityRoute,
  setActiveSectionValue,
  updateField,
  setCoachmarkRenderStatus,
  updateAssessmentInRedux,
};

const getTags = ({ resourceDetails }) => {
  const tags = _.get(
    _.find(_.get(resourceDetails, "allFields", []), item => item.uid == "tags"),
    "resolvedMinimalTree.tags",
    []
  );
  return _.map(tags, item => {
    const { id, label } = item;
    return { id, value: id, label, __typename: "TOPIC", type: "TOPIC" };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    t,
    assessmentType,

    template: originalTemplate,
    resourceDetails,
    unitPlanFields,
    location: { query },
  } = ownProps;
  const userType = state.login.userInfo.user_type;
  const { userLoggedIn } = state.login;
  const isCommunity = state.login.activeTab == "community";
  const selected_class = state.teacher.selected_class;

  const isResourceViewRevamp = ACLStore.can("FeatureFlag:ResourceViewRevamp");

  const { type: curriculumType } = state.platform.currentCurriculumProgram;

  const template = isResourceViewRevamp
    ? getLECreationRevampTemplate({
        t,
        template: originalTemplate,
        assessmentType,
        isFromClassroom: false,
        curriculumType,
      })
    : originalTemplate;

  const listSteps = GetLESteps({
    userType,
    template,
    resourceDetails,
    unitPlanFields,
    isPreview: false,
    isCommunity,
  });
  let { stepList, viewSteps } = listSteps;
  if (!userLoggedIn) {
    viewSteps = _.slice(viewSteps, 0, 2);
  }
  return {
    userType,
    isCommunity,
    step_list: stepList,
    view_steps: viewSteps,
    field_list: _.get(template, "field_list", {}),
    isLocalisedTemplate: _.get(template, "isLocalisedTemplate", true),
    localisedTemplateId: _.get(template, "localisedTemplateId", ""),
    tags: getTags({ resourceDetails }),
    userLoggedIn,
    isDemo: selected_class.isDemo,
    isResourceViewRevamp,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  withQueryParamsBasedInterface({ cb: getResourceViewProps })
)(ResourceView);
