import React from "react";

import classes from "./Exemplar.scss";
import { Attachment, RichTextEditor, Textview } from "UIComponents";

class Exemplar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = null;
  }
  updateAssessmentTool = params => {
    const { writeAssessmentToolInLocal, updateAssessmentTool } = this.props;
    writeAssessmentToolInLocal({ params });
    updateAssessmentTool({ params, isDebounce: true });
  };

  renderView = () => {
    const { attachments, value, mode } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.attachmentViewContainer}>
          <Textview
            value={!!value ? value : ""}
            showViewEmpty={false}
            mode={mode}
          />
          <div className={classes.attachmentViewContainer}>
            <Attachment value={attachments} mode={mode} showViewEmpty={false} />
          </div>
        </div>
      </div>
    );
  };

  blur = () => {
    if (this.inputRef && this.inputRef.blur) {
      this.inputRef.blur();
    }
  };

  renderEdit = () => {
    const {
      attachments,
      value,
      createAttachment,
      deleteAttachment,
      mode,
      onFocus,
      onBlur,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.attachmentEditorContainer}>
          <div className={classes.editorContainer}>
            <RichTextEditor
              height={210}
              width={"100%"}
              onBlurInputField={onBlur}
              onFocusInputField={onFocus}
              name={"message"}
              updateInputField={this.updateAssessmentTool}
              value={value}
              updateInputRef={ref => (this.inputRef = ref)}
            />
          </div>
          <div className={classes.attachmentContainer}>
            <Attachment
              value={attachments}
              addAttachment={createAttachment}
              deleteAttachment={deleteAttachment}
              mode={mode}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { mode } = this.props;
    return mode == "view" ? this.renderView() : this.renderEdit();
  }
}

export default Exemplar;
