import React, { PureComponent } from "react";
import classes from "./ResourceItemCard.scss";
import { Tick } from "SvgComponents";
import classNames from "classnames";
import { colors } from "Constants";
import DropDown from "./DropDown";
import { TextMoreLess, LockOverlay, I18nHOC } from "UIComponents";
import { AttachmentFeed } from "Attachment";
class ResourceItemCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  onDeleteClick = () => {
    const { onDeleteClick, item } = this.props;
    onDeleteClick(item.id);
  };

  render() {
    const {
      item: {
        id,
        attachments,
        title,
        type,
        description,
        usedCount,
        createdBy: { firstName, lastName } = {},
      } = {},
      onDeleteClick,
      fieldValue,
      onEditClick,
      isSelected,
      updateSelectedResources,
      canPick,
      cardmode,
      lockedField,
      attachmentItemHeight,
      attachmentFeedContainerStyle,
      t,
    } = this.props;

    const containerStyle = classNames(
      { [classes.container]: true },
      { [classes.containerSelected]: !!isSelected },
      { [classes.containerSelectionMode]: cardmode == "select" }
    );

    const innerContainerStyle = classNames(
      { [classes.innerContainer]: true },
      { [classes.selectInnerContainer]: cardmode == "select" }
    );

    const attachmentsCount = _.get(attachments, "length", 0);

    return (
      <div
        className={containerStyle}
        onClick={() =>
          cardmode == "select" ? updateSelectedResources(id) : null
        }
      >
        <div className={innerContainerStyle}>
          {cardmode == "select" && (
            <div className={classes.tickIconContainer}>
              {isSelected ? (
                <Tick fill={colors.blue29} width={24} height={24} />
              ) : (
                <div className={classes.tickIcon} />
              )}
            </div>
          )}
          <div className={classes.topContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.headerLeftContainer}>
                <div className={classes.titleText}>
                  {title ? title : t("common:no_title")}
                </div>
                <div className={classes.createdByText}>
                  {t("common:by_with_label", {
                    label: `${firstName} ${lastName}`,
                  })}
                </div>
              </div>
              <div className={classes.headerRightContainer}>
                {cardmode == "edit" && (
                  <DropDown
                    item={this.props.item}
                    onEditClick={onEditClick}
                    onDeleteClick={this.onDeleteClick}
                    fieldValue={fieldValue}
                    canPick={canPick}
                  />
                )}
              </div>
            </div>
            {description && (
              <div className={classes.descriptionContainer}>
                <div className={classes.descriptionText}>
                  <TextMoreLess
                    value={description}
                    minHeight={24}
                    customTextStyle={
                      cardmode == "select" ? { color: colors.gray48 } : {}
                    }
                  />
                </div>
              </div>
            )}
            {!description && attachmentsCount == 0 && (
              <div className={classes.noDataText}>
                {t("no_description_attachments")}
              </div>
            )}
          </div>
          {attachmentsCount > 0 && (
            <AttachmentFeed
              attachments={attachments}
              cardmode={cardmode}
              mode={"view"}
              attachmentItemHeight={attachmentItemHeight}
              containerStyle={attachmentFeedContainerStyle}
            />
          )}
        </div>
        {lockedField && cardmode == "edit" && (
          <LockOverlay userInfo={_.get(lockedField, "userInfo", {})} />
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(ResourceItemCard);
