import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "attendance",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            // attendance setup module in school setup
            const attendanceSetupModule = require("SchoolSetup/routes/AttendanceSetup/modules/Module");
            const attendanceSetupReducer = attendanceSetupModule.default;
            const attendanceSetupReducerKey = attendanceSetupModule.NAME;
            injectReducer(store, {
              key: attendanceSetupReducerKey,
              reducer: attendanceSetupReducer,
            });
            // ========================
            const component = require("./components").default;
            const reducer = require("./modules/Module").default;
            injectReducer(store, { key: "attendanceSettings", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-settings"
        );
      })
  ),
});
