import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import CircularDetails from "Circulars/routes/CircularDetails";
export default store => ({
  path: "circulars",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("Circulars/modules/CircularsContainer")
              .default;
            const module2 = require("Circulars/modules/CircularsModule");
            const reducer = module2.default;
            const key = module2.NAME;
            injectReducer(store, { key, reducer });

            cb(component);
          },
          err => {
            reject(err);
          },
          "administrator-communications"
        );
      })
  ),
  childRoutes: [CircularDetails(store)],
});
