import gql from "graphql-tag";
import { userFragment } from "modules/CommonFragments";

export const projectFragments = {
  projectInfoFragment: gql`
    fragment projectInfo on Project {
      id
      title {
        id
        value
      }
      students {
        ...userItem
        isArchived
        grades
        courses {
          id
          grades {
            id
            name
          }
        }
      }
      supervisors {
        ...userItem
      }
    }
    ${userFragment.basicUser}
  `,
};

export const projectGroupFragments = {
  projectGroupStudentFragment: gql`
    fragment projectGroupStudent on Student {
      ...userItem
      isArchived
      grades
      courses {
        id
        grades {
          id
          name
        }
      }
    }
    ${userFragment.basicUser}
  `,
};

export const studentProjectPortfolioFragments = {
  studentProjectPortfolioDetailsFragment: gql`
    fragment portfolioStudentAdvisor on StudentProjectPortfolio {
      id
      student {
        ...userItem
      }
      staff {
        ...userItem
      }
    }
    ${userFragment.basicUser}
  `,
};
