import React, { createRef } from "react";
import classes from "./MediaPlaceholder.scss";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import PropTypes from "prop-types";
import {
  PlayIcon,
  PdfLineIcon,
  SpeakerIcon,
  LockIconV2,
  PlayIconV2,
} from "SvgComponents";
import {
  DownloadCloudOutlined,
  WorkbookOutlined,
  ExpandArrowsFourOutlined,
  LinkOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlayFilled,
  PlayCircleOutlined,
  AudioColored,
  DocumentsColored,
  VideoColored,
  ImageColored,
  PdfColored,
  ZipFileColored,
  FilenotfoundColored,
  SlidesColored,
  SheetsColored,
} from "@toddle-design/web-icons";
import { IconButton } from "@toddle-design/web";
import { CLOUD_URL } from "Constants";
import {
  ScalableView,
  SlickSlider,
  AudioPlayer,
  LinkWithTooltip,
  VideoPlayer,
  I18nHOC,
  EmbedVideoPlayer,
} from "UIComponents";
import {
  getThumbUrl,
  isImageCarousel,
  hasAudioNote,
  getCommentDisplayContent,
  addHttpsInUrl,
  getAttachmentsByTypeFromAttachmentArr,
} from "Utils";
import {
  colors,
  LE_ASSESSMENT_TYPE_MAPPING,
  PLACEHOLDER_RECT_IMAGE,
  PLACEHOLDER_RECT_IMAGE_COUNT,
} from "Constants";
import { connect } from "react-redux";
import { downloadFromUrl } from "modules/Services";
import { AttachmentList } from "Attachment/";
import classNames from "classnames";
import { getStudentResponseData } from "ClassRoom/modules/utils";
import _ from "lodash";
import { compose } from "react-apollo";
import { Trans } from "react-i18next";
import ReactPlayer from "react-player";
import IframeWrapper from "UIComponents/FileViewer/IframeWrapper/IframeWrapper";
import moment from "moment";
import { isJournalCardRevamp } from "Post/utils";
import { file } from "jszip/lib/object";

class MediaPlaceholder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLinkVideoPlaying: false,
    };
    this.embedPlayerRef = createRef();
  }

  componentDidMount() {
    if (this.props.getEmbedPlayerRef) {
      this.props.getEmbedPlayerRef(this.embedPlayerRef?.current);
    }
  }
  isLocalUrl = url => {
    return _.includes(url, "blob");
  };

  getThumbResolution = ({
    imageHeight,
    imageMaxWidth,
    resolutionMutliplier,
    fitIn,
  }) => {
    if (!fitIn) {
      return {
        width: imageMaxWidth * resolutionMutliplier,
        height: imageHeight * resolutionMutliplier,
      };
    } else {
      const heightValue =
        Math.ceil((imageMaxWidth * resolutionMutliplier) / 256) * 256;
      const widthValue =
        Math.ceil((imageHeight * resolutionMutliplier) / 256) * 256;
      const value = Math.max(heightValue, widthValue);

      return {
        width: value,
        height: value,
      };
    }
  };

  onDeleteClick = attachment => {
    if (this.props.deleteAttachment) {
      this.props.deleteAttachment(attachment);
    }
  };

  attachmentActionsSvgs = attachment => {
    const { type, url, metadata } = attachment;
    const {
      iconContainerStyle,
      downloadFromUrl,
      mode,
      isAttachmentCreated,
      showDownloadIcon,
      postEntityItems,
    } = this.props;

    const hasPostEntityItems = !_.isEmpty(postEntityItems);
    return (
      <div className={classes.svgContainer}>
        {isAttachmentCreated &&
        mode != "edit" &&
        showDownloadIcon &&
        !hasPostEntityItems ? (
          <div
            onClick={e => {
              e.stopPropagation();
              downloadFromUrl({
                attachment: attachment,
                shouldAskForSave: true,
              });
            }}
            className={classes.actionButton}
            style={iconContainerStyle}
          >
            <div className={classes.actionSvgIcon}>
              <div className={classes.downloadIcon}>
                <DownloadCloudOutlined variant={"on"} size={"xxx-small"} />
              </div>
            </div>
          </div>
        ) : null}
        {isAttachmentCreated && mode == "edit" && (
          <div
            onClick={e => {
              e.stopPropagation();
              this.onDeleteClick(attachment);
            }}
            className={classes.actionButton}
            style={iconContainerStyle}
          >
            <div className={classes.actionSvgIcon}>
              <DeleteOutlined variant={"on"} size={"xxx-small"} />
            </div>
          </div>
        )}
        <div
          className={classes.actionButton}
          style={iconContainerStyle}
          onClick={this.props.onClickMedia}
        >
          <div className={classes.actionSvgIcon}>
            {type == "VIDEO" ? (
              <PlayIconV2
                fill={colors.strokeTwo}
                width={"33%"}
                height={"33%"}
                style={{ maxWidth: "20px", maxHeight: "24px" }}
              />
            ) : (_.get(metadata, "workbookUrl") && type === "IMAGE") ||
              type == "WORKBOOK" ? (
              <WorkbookOutlined size="xxx-small" variant="on" />
            ) : (
              <ExpandArrowsFourOutlined size={"xxx-small"} variant={"on"} />
            )}
          </div>
        </div>
      </div>
    );
  };

  getCenterSvgDetails = ({ type, mimeType, url }) => {
    let svgIcon = null;
    switch (type) {
      case "IMAGE":
        svgIcon = null;
        break;
      case "WORKBOOK":
        svgIcon = <WorkbookOutlined size="xxx-small" variant="on" />;
        break;
      case "FILE":
        svgIcon = null;
        break;
      // svgIcon = (
      //   <PdfLineIcon width={"33%"} height={"33%"} fill={colors.white} />
      // );
      case "VIDEO":
      case "AUDIO":
        svgIcon = (
          <PlayIconV2
            width={"33%"}
            height={"33%"}
            style={{ maxWidth: "16px", maxHeight: "18px" }}
          />
        );
        break;
      case "LINK":
        svgIcon = ReactPlayer.canPlay(url) ? (
          <PlayIconV2
            width={"33%"}
            height={"33%"}
            fill={colors.strokeTwo}
            style={{ maxWidth: "16px", maxHeight: "18px" }}
          />
        ) : (
          <LinkOutlined variant={"on"} size={"xxx-small"} />
        );
        break;
      default:
        break;
    }
    return svgIcon;
  };

  startDownloadFile = ({ attachment }) => {
    const { downloadFromUrl } = this.props;

    downloadFromUrl({ attachment, shouldAskForSave: true });
  };

  onClickFileContainer = e => {
    const { showDownloadBeginOverlay } = this.props;

    showDownloadBeginOverlay && e.stopPropagation();
  };

  audioFileDownload = attachment => {
    const { downloadFromUrl } = this.props;
    let name = _.get(attachment, "name", "");
    if (_.isEmpty(name)) {
      name = "toddle_audio_" + moment().format("DD-MM-YY_h_mm");
    }

    downloadFromUrl({
      name: name,
      attachment: attachment,
      shouldAskForSave: true,
    });
  };

  getFormattedFileType = fileType => {
    if (_.isEqual(fileType, "PDF")) return fileType;
    return _.startCase(_.toLower(fileType));
  };

  getIconCardByFileType = ({
    fileType,
    itemStyles = {},
    containerStyle = {},
  }) => {
    let icon = <FilenotfoundColored size="large" />;
    switch (fileType) {
      case "IMAGE":
        icon = <ImageColored size="large" />;
        break;
      case "VIDEO":
        icon = <VideoColored size="large" />;
        break;
      case "AUDIO":
        icon = <AudioColored size="large" />;
        break;
      case "PDF":
        icon = <PdfColored size="large" />;
        break;
      case "DOCUMENT":
        icon = <DocumentsColored size="large" />;
        break;
      case "SHEET":
        icon = <SheetsColored size="large" />;
        break;
      case "SLIDES":
        icon = <SlidesColored size="large" />;
        break;
      case "ZIP":
        icon = <ZipFileColored size="large" />;
        break;
      case "UNKNOWN":
        icon = <FilenotfoundColored size="large" />;
        break;
    }
    return (
      <div
        className={classes.iconCardContainer}
        style={{ ...containerStyle, ...itemStyles }}
      >
        <div className={classes.iconBackground}>
          {icon}
          <div className={classes.extentionContainer}>
            {this.getFormattedFileType(fileType)}
          </div>
        </div>
      </div>
    );
  };
  getMediaRender = attachment => {
    let mediaToRender;
    const {
      imageMaxWidth,
      imageMaxHeight,
      resolutionMutliplier,
      fitIn,
      backgroundSize,
      scalableRatio,
      itemStyles,
      containerStyle,
      downloadFromUrl,
      showVideoPlayer,
      showFullscreenImage,
      toolTipText,
      isDisabledMode,
      showLinkInNewTab,
      noteContainerStyle,
      noteContainerWithLimitStyles,
      showDownloadBeginOverlay,
      t,
      isUploading,
      isLastAttachment,
      isStudentResponse,
      showIconCardsWithExtension,
    } = this.props;
    const imageHeight = imageMaxHeight;

    const downloadBeginOverlayClass = classNames(
      { [classes.downloadBeginOverlay]: true },
      { [classes.downloadBeginAnimation]: showDownloadBeginOverlay }
    );

    const {
      thumbUrl: rethumbImage,
      isIcon,
      color,
      fileType,
      fileExtension,
    } = getThumbUrl({
      ...this.getThumbResolution({
        imageMaxWidth,
        imageHeight,
        resolutionMutliplier,
        fitIn,
      }),
      attachment,
      fitIn,
      cardType: "vertical",
    });

    let updatedItemStyles = itemStyles;

    if (isIcon) {
      updatedItemStyles = {
        backgroundSize: "20%",
        backgroundPosition: "center",
        backgroundColor: color,
      };
      if (showIconCardsWithExtension) {
        const attachmentType = _.get(attachment, "type", "");
        if (
          fileType &&
          (attachmentType == "LINK" || attachmentType == "FILE")
        ) {
          updatedItemStyles = {
            ...itemStyles,
            height: "50vh",
            border: isStudentResponse
              ? "none"
              : `1px solid ${colors.borderSubtle}`,
          };

          mediaToRender = this.getIconCardByFileType({
            fileType,
            itemStyles: updatedItemStyles,
            containerStyle,
          });
          return mediaToRender;
        }
      }
    }

    const onAudioFileDownload = e => {
      e.stopPropagation();
      this.audioFileDownload(attachment);
    };

    const isMediaUploading = isUploading && isLastAttachment;
    const audioWrapperClass = classNames(
      { [classes.audioWrapper]: !isStudentResponse },
      { [classes.audioWrapperUploading]: isMediaUploading }
    );

    const updateEmbedPlayerRef = ref => {
      if (this.embedPlayerRef) this.embedPlayerRef.current = ref;
    };
    const sourceWidth = _.get(attachment.metadata, "width", 556) || 556;
    const sourceHeight = _.get(attachment.metadata, "height", 288) || 288;
    const attachmentUrl = _.get(attachment, "url", "");
    const streamVideoUrl = _.get(attachment, "streamUrl", "");
    const cardType = this.getCardType();
    switch (_.get(attachment, "type")) {
      case "IMAGE":
      case "WORKBOOK":
        mediaToRender = showFullscreenImage ? ( // if showFullscreenImage is true render image in img tag else render ScalableView
          <div
            style={{
              ...updatedItemStyles,
            }}
            className={classes.imageContainer}
          >
            <img src={rethumbImage} style={{ width: "100%" }}></img>
            {this.renderIconContainer(attachment)}
          </div>
        ) : (
          <ScalableView
            width={imageMaxWidth}
            maxHeight={imageHeight}
            sourceWidth={sourceWidth}
            sourceHeight={sourceHeight}
            scalableRatio={scalableRatio}
            containerStyle={containerStyle}
          >
            <div
              style={{
                backgroundImage: `url('${rethumbImage}')`,
                backgroundSize,
                ...updatedItemStyles,
              }}
              className={classes.imageContainer}
            >
              {this.renderIconContainer(attachment)}
            </div>
          </ScalableView>
        );

        break;
      case "AUDIO": {
        const { showFileName, mode } = this.props;

        mediaToRender = (
          <div className={audioWrapperClass} style={{ ...containerStyle }}>
            {cardType == "small" ? (
              <React.Fragment>
                {isStudentResponse ? (
                  <div
                    className={classes.audioSpeakerIconContainer}
                    style={{ ...containerStyle, ...itemStyles }}
                  >
                    <div className={classes.audioSpeakerIconBackground}>
                      <AudioColored size="large" />
                      {"Audio"}
                    </div>
                  </div>
                ) : (
                  this.renderIconContainer(attachment)
                )}
              </React.Fragment>
            ) : (
              <div
                className={classes.audioPlayerWrapper}
                style={{
                  paddingRight: mode == "edit" ? 40 : 0,
                }}
              >
                <AudioPlayer
                  attachment={attachment}
                  showFileName={showFileName}
                  ref={ref => (this._audioPlayerRef = ref)}
                  isUploading={isMediaUploading}
                ></AudioPlayer>

                {mode == "view" && (
                  <div
                    className={classes.audioSvgIcon}
                    onClick={onAudioFileDownload}
                  >
                    <div className={classes.downloadIcon}>
                      <IconButton
                        icon={<DownloadOutlined size={"xx-small"} />}
                        variant={"plain"}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );

        break;
      }
      case "VIDEO":
        mediaToRender = (
          <ScalableView
            width={imageMaxWidth}
            maxHeight={imageMaxHeight}
            sourceWidth={sourceWidth}
            sourceHeight={sourceHeight}
            scalableRatio={scalableRatio}
            containerStyle={containerStyle}
          >
            {showVideoPlayer ? (
              <VideoPlayer
                videoUrl={streamVideoUrl ? streamVideoUrl : attachmentUrl}
                isHLSUrl={!!streamVideoUrl}
              />
            ) : (
              <div
                style={{
                  backgroundImage: `url('${rethumbImage}')`,
                  backgroundSize: backgroundSize,
                  ...updatedItemStyles,
                }}
                className={classes.imageContainer}
              >
                {this.renderIconContainer(attachment)}
              </div>
            )}
          </ScalableView>
        );
        break;
      case "LINK": {
        const hasPermission = !!_.get(attachment, "metadata.permission", false);
        const linkUrl = addHttpsInUrl(_.get(attachment, "url", ""));
        const isVideoLink = ReactPlayer.canPlay(linkUrl);
        const SEESAW_REGEX = /https:\/\/app\.seesaw\.me\/#\/item\/item\.([A-Za-z]+|[0-9]*|-*)+\/share\/([A-Za-z])+/;
        const isSeesawLink = SEESAW_REGEX.test(linkUrl);
        mediaToRender = (
          <ScalableView
            width={imageMaxWidth}
            maxHeight={imageMaxHeight}
            sourceWidth={sourceWidth}
            sourceHeight={sourceHeight}
            scalableRatio={scalableRatio}
            containerStyle={containerStyle}
          >
            {isVideoLink ? (
              <React.Fragment>
                <EmbedVideoPlayer
                  url={linkUrl}
                  thumbnail={rethumbImage}
                  className={classes.imageContainer}
                  onRef={updateEmbedPlayerRef}
                  itemStyle={itemStyles}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isSeesawLink ? (
                  <IframeWrapper iframeSrc={linkUrl} />
                ) : (
                  <ConditionalWrapper
                    // if showLinkInNewTab is true wrap whole card in <a> tag else wrap only bottom card in <a> tag
                    condition={showLinkInNewTab}
                    wrapper={({ children }) => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={linkUrl}
                      >
                        {children}
                      </a>
                    )}
                  >
                    {isDisabledMode ? (
                      <LinkWithTooltip
                        tooltip={toolTipText}
                        placement={"top"}
                        href={null}
                        alignConfig={{ offset: [300, 0] }}
                      >
                        <div
                          style={{
                            backgroundImage: `url('${rethumbImage}')`,
                            backgroundSize: backgroundSize,
                            ...updatedItemStyles,
                            position: "relative",
                          }}
                          className={classes.imageContainer}
                        >
                          <div className={classes.lockIcon}>
                            <LockIconV2
                              width={32}
                              height={32}
                              fill={colors.white}
                            />
                          </div>
                          <div className={classes.lockBackground} />
                        </div>
                      </LinkWithTooltip>
                    ) : (
                      <div
                        style={{
                          backgroundImage: `url('${rethumbImage}')`,
                          backgroundSize: backgroundSize,
                          ...updatedItemStyles,
                        }}
                        className={classes.imageContainer}
                      >
                        {this.renderIconContainer(attachment)}
                      </div>
                    )}
                  </ConditionalWrapper>
                )}
              </React.Fragment>
            )}
          </ScalableView>
        );
        break;
      }
      case "FILE":
        mediaToRender = (
          <ScalableView
            width={imageMaxWidth}
            maxHeight={imageMaxHeight}
            sourceWidth={sourceWidth}
            sourceHeight={sourceHeight}
            scalableRatio={scalableRatio}
            containerStyle={containerStyle}
          >
            <div
              style={{
                backgroundImage: `url('${rethumbImage}')`,
                backgroundSize: backgroundSize,
                ...updatedItemStyles,
                cursor: showDownloadBeginOverlay ? "default" : "pointer",
              }}
              className={classes.imageContainer}
              onClick={this.onClickFileContainer}
            >
              {this.renderIconContainer(attachment)}
              {showDownloadBeginOverlay && (
                <div
                  onClick={e => e.stopPropagation()}
                  className={downloadBeginOverlayClass}
                >
                  <div className={classes.downloadBeginText}>
                    <Trans i18nKey="journal:download_begin_shortly">
                      {"Your download will begin shortly. "}
                      <span
                        className={classes.clickHereText}
                        onClick={() => this.startDownloadFile({ attachment })}
                        style={{ cursor: "pointer" }}
                      >
                        {"Click here"}
                      </span>
                      {" to download again"}
                    </Trans>
                  </div>
                </div>
              )}
            </div>
          </ScalableView>
        );
        break;
      case "NOTE": {
        const noteHeight = Math.min(imageMaxHeight, sourceHeight) - 32;
        const numOfLines = Math.floor(noteHeight / 22);

        mediaToRender = (
          <div
            className={classes.noteWrapper}
            style={{
              height: noteHeight + 32,
              width: imageMaxWidth,
              ...updatedItemStyles,
              backgroundColor: colors.white,
              ...containerStyle,
              ...noteContainerStyle,
            }}
          >
            <div className={classes.noteLinesContainer}>
              <div
                style={{
                  WebkitLineClamp: numOfLines,
                  ...noteContainerWithLimitStyles,
                }}
                className={classes.noteContainerWithLimit}
                dangerouslySetInnerHTML={{
                  __html: getCommentDisplayContent(_.get(attachment, "title")),
                }}
              />
              <div className={classes.notesOverlayContainer}>
                {this.renderIconContainer(attachment)}
              </div>
            </div>
          </div>
        );
        break;
      }
      default:
        break;
    }
    return mediaToRender;
  };

  renderIconContainer = attachment => {
    const centerIconDiv = this.getCenterSvgDetails(attachment);
    const attachmentActionsSvgs = this.attachmentActionsSvgs(attachment);
    const {
      showOverlayIcons,
      iconContainerStyle,
      mode,
      showMiddleContainerIcons,
      postEntityItems,
    } = this.props;
    if (mode === "select") return null;

    const { assessmentType } = getStudentResponseData(postEntityItems);
    const showEmbedPlayButton =
      _.get(attachment, "type", "") === "LINK" &&
      ReactPlayer.canPlay(_.get(attachment, "url", ""));
    const Icon = _.get(
      LE_ASSESSMENT_TYPE_MAPPING[assessmentType],
      "icon",
      null
    );

    return (
      <React.Fragment>
        {showMiddleContainerIcons && assessmentType ? (
          <React.Fragment>
            <div
              className={classes.assessmentOverlay}
              style={{
                ...(!showOverlayIcons ? { display: "flex" } : {}),
              }}
            >
              <div className={classes.assessmentOverlayCircle}>
                <Icon className={classes.assessmentIcon} size={"xx-small"} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!(
              attachment.type == "IMAGE" ||
              attachment.type == "FILE" /*&& !attachment.thumbUrl */ ||
              attachment.type == "NOTE"
            ) &&
              (showMiddleContainerIcons || showEmbedPlayButton) && (
                <div
                  className={classes.middleIconContainer}
                  style={{
                    ...iconContainerStyle,
                    ...(!showOverlayIcons || showEmbedPlayButton
                      ? { display: "flex" }
                      : {}),
                  }}
                >
                  {centerIconDiv}
                </div>
              )}
          </React.Fragment>
        )}
        {!!showOverlayIcons && !showEmbedPlayButton && (
          <React.Fragment>
            <div className={classes.overlay} />
            {attachmentActionsSvgs}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  getCardType = () => {
    const { imageMaxWidth } = this.props;
    return imageMaxWidth > 340 ? "large" : "small";
  };

  onCarouselItemClick = (event, index) => {
    const { onClickMedia } = this.props;
    if (onClickMedia) {
      event.stopPropagation();
      onClickMedia({ activeIndex: index, event });
    }
  };

  renderCarouselImageV2 = ({ item, index }) => {
    const { thumbUrl } = getThumbUrl({
      ...this.getThumbResolution({
        imageMaxWidth: _.get(this.props, "imageMaxWidth", 520),
        imageHeight: 400,
        resolutionMutliplier: 1,
        fitIn: false,
      }),
      attachment: item,
      fitIn: false,
    });
    const cardType = this.getCardType();

    const containerClass = classNames(
      { [classes.carouselItemV2]: true },
      { [classes.smallCarouselImageContainer]: cardType == "small" }
    );
    return (
      <div
        onClick={e => this.onCarouselItemClick(e, index)}
        style={{ width: "100%" }}
      >
        <div className={containerClass}>
          <img src={thumbUrl} style={{ width: "100%", height: "100%" }} />
          {this.renderIconContainer(item)}
        </div>
      </div>
    );
  };
  renderCarouselImage = ({ item, index }) => {
    const {
      imageMaxWidth,
      imageMaxHeight,
      attachments,
      itemContainerStyle,
    } = this.props;
    const { thumbUrl } = getThumbUrl({
      ...this.getThumbResolution({
        imageMaxWidth,
        imageHeight: imageMaxHeight,
        resolutionMutliplier: 2,
        fitIn: true,
      }),
      attachment: item,
      fitIn: true,
    });
    const cardType = this.getCardType();
    const containerStyle = classNames(
      { [classes.carouselImageContainer]: cardType == "large" },
      { [classes.smallCarouselImageContainer]: cardType == "small" }
    );

    const { metadata } = item;
    const { width, height } = metadata || {};
    return (
      <div
        key={_.get(item, "id", _.get(item, "url", ""))}
        onClick={e => this.onCarouselItemClick(e, index)}
      >
        <div
          className={containerStyle}
          style={{
            height: cardType == "small" ? imageMaxHeight : "100%",
            maxWidth:
              cardType == "small"
                ? "100%"
                : imageMaxWidth *
                  (index < _.get(attachments, "length", 0) - 1 ? 0.8 : 1),
            overflow: "hidden",
            ...itemContainerStyle,
          }}
        >
          <img
            src={thumbUrl}
            style={{ height: imageMaxHeight }}
            width={"100%"}
          />
          {this.renderIconContainer(item)}
          {cardType == "small" && (
            <div
              className={classes.counterText}
              style={{ top: imageMaxHeight - 24 }}
            >
              {`${index + 1}/${_.get(attachments, "length", 0)}`}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderAudioCaptionAttachmentList = attachments => {
    const { audioCaptionContainerStyles } = this.props;
    const audioAttachmentsCount = getAttachmentsByTypeFromAttachmentArr({
      attachmentsArr: _.get(attachments, "attachments"),
      type: "AUDIO",
    }).length;
    const isOnlyAudioAttachmentPresent =
      _.get(attachments, "attachments.length", 0) === audioAttachmentsCount;

    return !isOnlyAudioAttachmentPresent ? (
      <AttachmentList
        value={getAttachmentsByTypeFromAttachmentArr({
          attachmentsArr: _.get(attachments, "attachments"),
          type: "AUDIO",
        })}
        name={"attachments"}
        cardType={"horizontal"}
        showCreateDropdown={false}
        parentId={""}
        mode={"view"}
        parentType={""}
        attachmentContainerStyle={{
          gridTemplateColumns: "unset",
          margin: "8px 0px",
          ...audioCaptionContainerStyles,
        }}
      />
    ) : null;
  };

  renderMedia = () => {
    const {
      attachments,
      imageMaxHeight,
      carouselContainerStyle,
      hasAudioCaption,
      postEntityItems,
      showOverlayIcons,
      showCarouselV2,
    } = this.props;

    const {
      itemId,
      assessmentType,
      studentResponseAttachments,
    } = getStudentResponseData(postEntityItems);

    const attachment =
      _.get(attachments, "length", 0) > 0 ? attachments[0] : null;
    const studentResponseAttachment =
      _.get(studentResponseAttachments, "length", 0) > 0
        ? studentResponseAttachments[0]
        : null;

    let media = null;
    const cardType = this.getCardType();
    const isImageCarousel = _.filter(attachments, { type: "IMAGE" }).length > 1;

    if (assessmentType && _.isEmpty(studentResponseAttachments)) {
      const Icon = _.get(
        LE_ASSESSMENT_TYPE_MAPPING[assessmentType],
        "icon",
        null
      );
      const attachmentActionsSvgs = this.attachmentActionsSvgs([]);

      return (
        <React.Fragment>
          <div
            className={classes.assessmentPlaceholderOverlay}
            style={{
              backgroundImage: `url(${
                PLACEHOLDER_RECT_IMAGE[
                  (itemId || 0) % PLACEHOLDER_RECT_IMAGE_COUNT
                ]
              })`,
            }}
          >
            <div
              className={classes.assessmentOverlayCircle}
              style={{ ...(!showOverlayIcons ? { display: "flex" } : {}) }}
            >
              <Icon className={classes.assessmentIcon} size={"xx-small"} />
            </div>
          </div>
          {!!showOverlayIcons && (
            <React.Fragment>
              <div className={classes.overlay} />
              {attachmentActionsSvgs}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    if (isImageCarousel && !assessmentType) {
      if (showCarouselV2) {
        media = (
          <React.Fragment>
            {hasAudioCaption ? (
              <div className={classes.audioContainer}>
                {this.renderAudioCaptionAttachmentList({ attachments })}
              </div>
            ) : null}
            <div>
              <SlickSlider
                items={_.filter(attachments, ({ type }) => type !== "AUDIO")}
                renderItem={this.renderCarouselImageV2}
                variableWidth={cardType == "large"}
                showDots={cardType == "large"}
                containerStyle={{ paddingBottom: "25px" }}
              />
            </div>
          </React.Fragment>
        );
      } else {
        const containerStyle = classNames(
          {
            [classes.carouselContainer]: cardType == "large",
          },
          { [classes.smallCarouselContainer]: cardType == "small" }
        );
        let customPrevArrowStyle = {};
        let customNextArrowStyle = {};
        let customArrowIconContainerStyle = {};
        if (cardType == "small" && imageMaxHeight && imageMaxHeight > 0) {
          const arrowStyle = {
            width: 20,
            height: 20,
            borderRadius: 10,
            border: "solid 0.5px #424b5d",
            backgroundColor: "rgba(32, 38, 50, 0.9)",
          };
          customPrevArrowStyle = {
            ...arrowStyle,
            top: imageMaxHeight - 20,
            left: "unset",
            right: 40,
          };
          customNextArrowStyle = {
            ...arrowStyle,
            top: imageMaxHeight - 20,
            left: "unset",
            right: 12,
          };
          customArrowIconContainerStyle = {
            fill: "white",
            width: 8,
            height: 8,
          };
        }
        media = (
          <React.Fragment>
            {hasAudioCaption ? (
              <div className={classes.audioContainer}>
                {this.renderAudioCaptionAttachmentList({ attachments })}
              </div>
            ) : null}
            <div className={containerStyle} style={carouselContainerStyle}>
              <SlickSlider
                items={_.filter(attachments, ({ type }) => type !== "AUDIO")}
                renderItem={this.renderCarouselImage}
                customPrevArrowStyle={customPrevArrowStyle}
                customNextArrowStyle={customNextArrowStyle}
                variableWidth={cardType == "large"}
                showDots={cardType == "large"}
                customArrowIconContainerStyle={customArrowIconContainerStyle}
              />
            </div>
          </React.Fragment>
        );
      }
    } else if (attachment || studentResponseAttachment) {
      const audioAttachment =
        hasAudioNote({ attachments }) && !hasAudioCaption
          ? attachments[1]
          : null;
      media = (
        <React.Fragment>
          {hasAudioCaption
            ? this.renderAudioCaptionAttachmentList({ attachments })
            : null}
          {this.getMediaRender(
            attachment ? attachment : studentResponseAttachment
          )}
          {audioAttachment ? (
            cardType == "large" ? (
              <div className={classes.audioNote}>
                <AudioPlayer
                  attachment={audioAttachment}
                  mediaType={"secondary"}
                ></AudioPlayer>
              </div>
            ) : (
              !assessmentType && (
                <div className={classes.smallAudioNote}>
                  <SpeakerIcon width={10} height={10}></SpeakerIcon>
                </div>
              )
            )
          ) : null}
        </React.Fragment>
      );
    }

    return media;
  };

  isShowVideoPlayer = () => {
    const { attachments, showVideoPlayer } = this.props;
    const attachmentType = _.get(_.first(attachments), "type", "");
    return attachmentType == "VIDEO" && showVideoPlayer;
  };

  onClickMedia = event => {
    const { onClickMedia } = this.props;
    if (onClickMedia) {
      onClickMedia({ event });
    }
  };

  isAudioAttachment = () => {
    const { attachments } = this.props;
    const attachment =
      _.get(attachments, "length", 0) > 0 ? attachments[0] : null;
    const attachmentType = _.get(attachment, "type", "");

    return attachmentType === "AUDIO";
  };

  toggleAudio = () => {
    this._audioPlayerRef?.toggleAudio();
  };

  handleClick = event => {
    const {
      attachments,
      isDisabledMode,
      isUploading,
      isLastAttachment,
      isStudentResponse,
    } = this.props;
    const disableOnClickMedia =
      !isStudentResponse &&
      (isImageCarousel({ attachments }) ||
        this.isShowVideoPlayer() ||
        isDisabledMode ||
        this.isAudioAttachment());

    disableOnClickMedia ? null : this.onClickMedia(event);

    this.isAudioAttachment() &&
      !(isUploading && isLastAttachment) &&
      this.toggleAudio();
  };

  render() {
    return (
      <div
        className={classes.container}
        style={this.props.containerStyle}
        onClick={this.handleClick}
      >
        {this.renderMedia()}
      </div>
    );
  }
}

MediaPlaceholder.defaultProps = {
  imageMaxHeight: 512,
  imageMaxWidth: 512,
  attachments: [],
  resolutionMutliplier: 1,
  fitIn: true,
  backgroundSize: "cover",
  scalableRatio: 0,
  iconContainerStyle: {},
  itemContainerStyle: {},
  carouselContainerStyle: {},
  containerStyle: {},
  showOverlayIcons: false,
  mode: "view",
  showDownloadIcon: true,
  showVideoPlayer: false,
  showFullscreenImage: false,
  isDisabledMode: false,
  showLinkInNewTab: false,
  showMiddleContainerIcons: true,
  hasAudioCaption: false,
  audioCaptionContainerStyles: {},
  noteContainerWithLimitStyles: {},
  noteContainerStyle: {},
  isAttachmentCreated: true,
  showDownloadBeginOverlay: false,
  isUploading: false,
  isLastAttachment: false,
  isStudentResponse: false,
  showCarouselV2: false,
  showIconCardsWithExtension: false,
};

MediaPlaceholder.propTypes = {
  imageMaxHeight: PropTypes.number,
  attachments: PropTypes.array,
  onClickMedia: PropTypes.func,
  resolutionMutliplier: PropTypes.number,
  fitIn: PropTypes.bool,
  backgroundSize: PropTypes.string,
  scalableRatio: PropTypes.number,
  containerStyle: PropTypes.object,
  showOverlayIcons: PropTypes.bool,
  mode: PropTypes.string,
  deleteAttachment: PropTypes.func,
  showDownloadIcon: PropTypes.bool,
  showVideoPlayer: PropTypes.bool,
  showFullscreenImage: PropTypes.bool,
  isDisabledMode: PropTypes.bool,
  showLinkInNewTab: PropTypes.bool,
  hasAudioCaption: PropTypes.bool,
  audioCaptionContainerStyles: PropTypes.object,
  isAttachmentCreated: PropTypes.bool,
  showDownloadBeginOverlay: PropTypes.bool,
  isUploading: PropTypes.bool,
  isLastAttachment: PropTypes.bool,
  isStudentResponse: PropTypes.bool,
  showCarouselV2: PropTypes.bool,
  showIconCardsWithExtension: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapActionCreators = { downloadFromUrl };

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: "journal" })
)(MediaPlaceholder);
