import React from "react";
import PropTypes from "prop-types";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import { SelectDropdown, FormInputError } from "@toddle-design/web";
import { Translation } from "react-i18next";
import { getRethumbUrl, getStringFromArray } from "Utils";
import classes from "./SelectDropdown.scss";
import _ from "lodash";

//Reference https://dev.to/nioufe/you-should-not-use-lodash-for-memoization-3441
//I did not intentionally use JSON.stringify for cache key. The optons list may be long and will also contain icons component/url. this would be a slow process. I know what I'm doing here!!
const getOptions = ({ options = [], createOptions = [] }) => {
  return _.map([...options, ...createOptions], option => {
    const optionObj = { ...option };
    if (optionObj.icon && optionObj.iconType === "URL") {
      optionObj.icon = (
        <IconImageThumbnail
          iconSize={optionObj.optionIconSize}
          style={optionObj.optionIconStyle}
          icon={optionObj.icon}
        />
      );
    }
    return optionObj;
  });
};

class FormSelectDropdown extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      createOptions: [],
    };
  }

  updateMultiValue = params => {
    const { isUpdateValueWithLabel } = this.props;
    const options = this.combinedOptions();
    if (isUpdateValueWithLabel) {
      this.updateValue(params, options);
    } else {
      if (_.isArray(params)) {
        const values = _.map(params, val => val.value);
        this.updateValue(values, options);
      } else {
        this.updateValue(_.get(params, "value", ""), options);
      }
    }
  };

  //   componentDidCatch = (error, info) => {
  //     console.warn({error, info});
  // }

  getViewValue = () => {
    const options = this.combinedOptions();
    const value = _.get(
      _.find(options, { value: this.props.value }),
      "label",
      ""
    );
    return value;
  };

  updateValueLocally = (params, metaData) => {
    const { action, option } = metaData;
    if (action === "create-option") {
      const createdOption = {
        ...option,
        id: option.value,
      };
      this.setState(prevState => ({
        createOptions: [...prevState.createOptions, createdOption],
      }));
    }
    if (this.props.multi) {
      this.updateMultiValue(params);
    } else {
      this.updateValue(_.get(params, "value", ""));
    }
  };

  combinedOptions = () => {
    const { createOptions } = this.state;
    const { options: optionProps } = this.props;
    return getOptions({ options: optionProps, createOptions });
  };
  //react-select now wants value as an object to display icons and label in ValueComponent. Thats why we find out value object from Option
  getSelectValue = () => {
    const { value } = this.props;
    const options = this.combinedOptions();
    if (_.isArray(value)) {
      const valuesObj = _.map(value, value =>
        _.find(options, option => option.value === value)
      ).filter(Boolean);
      return valuesObj;
    } else {
      const valueObj = _.find(options, option => option.value === value);
      return valueObj ?? null;
    }
  };
  renderEdit = () => {
    const {
      allowAddOption: isCreatable = false,
      placeholder,
      clearable = false,
      disabled = false,
      multi,
      addButtonText = "Add",
      isSearchable = true,
      size = "x-large",
      areOptionsEmptyAllowed,
      noOptionsText = (
        <Translation>{t => t("common:noResultHeading")}</Translation>
      ),
      menuPlacement,
    } = this.props;
    const { error = "" } = this.state;

    const options = this.combinedOptions();
    const isDisabled = areOptionsEmptyAllowed
      ? disabled
      : options.length == 0 || disabled;

    const selectValue = this.getSelectValue();

    return (
      <div className="w-full">
        <SelectDropdown
          value={selectValue}
          size={size}
          onChange={this.updateValueLocally}
          isClearable={clearable}
          isCreatable={isCreatable}
          isSearchable={isSearchable}
          createText={addButtonText}
          isMulti={multi}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          error={error}
          noOptionsText={noOptionsText}
          ref={this.updateInputRef}
          menuPlacement={menuPlacement}
        />
        <FormInputError className="mt-1" error={error} />
      </div>
    );
  };
}

const getRethumb = ({ imageUrl, size = 24 }) => {
  const thumbnailUrl = imageUrl
    ? getRethumbUrl({
        width: size,
        height: size,
        imageUrl,
        fitIn: true,
      })
    : "";
  return `url(${thumbnailUrl})`;
};

const IconImageThumbnail = ({ icon, iconSize = 24, style = {} }) => {
  return (
    <span
      style={{
        background: getRethumb({
          imageUrl: icon,
          size: iconSize,
        }),
        ...style,
      }}
      className={classes.icon}
    />
  );
};

FormSelectDropdown.defaultProps = {
  ...UIBaseComponent.defaultProps,
  areOptionsEmptyAllowed: true,
  options: [],
  multi: false,
  allowAddOption: false,
  isUpdateValueWithLabel: false,
  error: "Required",
};

FormSelectDropdown.propTypes = {
  ...UIBaseComponent.propTypes,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  options: PropTypes.array,
  areOptionsEmptyAllowed: PropTypes.bool,
  allowAddOption: PropTypes.bool,
  addButtonText: PropTypes.string,
  isUpdateValueWithLabel: PropTypes.bool,
  menuPlacement: PropTypes.string,
};

export default FormSelectDropdown;
