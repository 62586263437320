import gql from "graphql-tag";
import { staffFragment } from "modules/CommonFragments";
import {
  resourceConnectionFragment,
  plannerFieldFragment,
} from "UnitPlans/modules/UnitPlanFragments";
import { unitPlanFragment } from "IBPlanner/modules/IBFragments";

export const leFragment = {
  leDetails: gql`
    fragment leItem on LearningEngagement {
      id
      title {
        id
        value
      }
      description {
        id
        value
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
      templateId
      createdBy {
        ...staffItem
      }
      allFields {
        ...resourceLibraryFieldItem
      }
      unitPlan {
        id
        templateId
        grades
        fields(
          uids: [
            "title"
            "theme"
            "centralIdea"
            "image"
            "subjects"
            "relatedConcepts"
            "unitType"
            "lois"
            "lp"
            "atls"
            "goalList"
            "concepts"
            "benchmarks"
          ]
        ) {
          ...resourceLibraryFieldItem
        }
        ...unitPlanItem
      }
      comments {
        totalCount
        unreadCount
      }
      resources {
        ...resourceConnectionItem
      }
      isDeleted
    }
    ${unitPlanFragment.unitPlanCollaborator}
    ${resourceConnectionFragment.resourceConnection}
    ${staffFragment.basicStaffDetails}
    ${plannerFieldFragment.resourceLibraryField}
  `,
  leSchedulerCard: gql`
    fragment leItem on LearningEngagement {
      id
      title {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
      duration {
        id
        value
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
  leFeed: gql`
    fragment leItem on LearningEngagement {
      id
      title {
        id
        value
      }
      description {
        id
        value
      }
      createdBy {
        ...staffItem
      }
      image {
        id
        value
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
};
