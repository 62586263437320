import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import ViewUnitPlan from "./routes/ViewUnitPlan";
import EditUnitPlan from "./routes/EditUnitPlan";
import { AssessmentDetails } from "Assessments";
import { LeDetails } from "LearningEngagement";

import { getRelativePath } from "Utils";

export default store => {
  return {
    path: "units/:unit_id",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const unitLibraryReducer = require("UnitLibrary/modules/UnitLibraryModule")
                .default;
              injectReducer(store, {
                key: "unitLibrary",
                reducer: unitLibraryReducer,
              });
              const reducer = require("UnitPlans/modules/UnitPlanModule")
                .default;
              injectReducer(store, { key: "unitPlans", reducer });
              const plannerReducer = require("IBPlanner/modules/IBPlannerModule")
                .default;
              injectReducer(store, { key: "planner", reducer: plannerReducer });
              const assessmentReducer = require("Assessments/modules/AssessmentModule");
              injectReducer(store, {
                key: assessmentReducer.NAME,
                reducer: assessmentReducer.default,
              });
              const leReducer = require("LearningEngagement/modules/LeModule");
              injectReducer(store, {
                key: leReducer.NAME,
                reducer: leReducer.default,
              });

              const multiLevelNodeEditorReducer = require("MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule");
              injectReducer(store, {
                key: "multiLevelNodeEditor",
                reducer: multiLevelNodeEditorReducer.default,
              });
              const subjectSetupReducer = require("SubjectsSetup/modules/SubjectsModule")
                .default;
              injectReducer(store, {
                key: "subjectsSetup",
                reducer: subjectSetupReducer,
              });

              resolve(component);
            },
            err => {
              reject(err);
            },
            "unit-planner"
          );
        })
    ),
    onEnter(nextState, replace) {
      if (_.last(nextState.routes).path == ":unit_id") {
        replace(getRelativePath("edit"));
      }
    },
    childRoutes: [
      ViewUnitPlan(store),
      EditUnitPlan(store),
      AssessmentDetails(store),
      LeDetails(store),
    ],
  };
};
