import React from "react";
import classes from "./SelectInput.scss";

import InputSearchBar from "./InputSearchBar";
import SearchDropDown from "./SearchDropDown";
import { ButtonDropdown } from "UIComponents";

const dropDownStyle = {
  containerStyle: {
    top: "unset",
    position: "unset",
    width: "100%",
    flex: 1,
    minHeight: 0,
    marginTop: "4px",
    borderRadius: "4px",
    marginBottom: "8px",
  },
  parentContainerStyle: {
    height: "100%",
    flexDirection: "column",
  },
};

class SelectInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchSelectedItems:
        props.mode == "search" ? props.collaborators || [] : [],
      isFocus: false,
    };
    this.inputList = null;
    this.inputSearchBar = null;
    this.buttonDropDownRef = null;
    this.inputSearchBarRef = null;
  }

  setInputFocus = value => {
    this.setState({ isFocus: value });
    const { onCollaboratorToggle } = this.props;
    if (onCollaboratorToggle) {
      onCollaboratorToggle(value);
    }
  };

  handleFocus = () => {
    this.setInputFocus(true);
  };

  removeOption = (item, event) => {
    if (!!event) {
      event.stopPropagation();
    }
    const options = _.filter(this.state.searchSelectedItems, function (o) {
      return o.id != item.id;
    });
    this.updateSearchSelectedItems(options);
  };

  updateSearchSelectedItems = searchSelectedItems => {
    this.setState({ searchSelectedItems: searchSelectedItems });
    if (this.props.mode == "search") {
      this.props.onCollaboratorSearchUpdate(searchSelectedItems);
    }
  };

  onDoneClick = e => {
    e.stopPropagation();
    this.updateSearchSelectedItems([]);
    this.setInputFocus(false);
    this.inputSearchBarRef.clearText();
    this.props.onDoneClick(this.state.searchSelectedItems);
    this.buttonDropDownRef.onClose();
  };

  addOption = (e, item) => {
    e.preventDefault();
    const options = [...this.state.searchSelectedItems, ...item];
    this.updateSearchSelectedItems(_.uniqBy(options, "id"));
    setTimeout(this.setInputScrollToBottom);
    this.inputSearchBarRef.clearText();
  };

  setInputScrollToBottom = () => {
    if (this.inputList) {
      this.inputList.scrollTop = this.inputList.scrollHeight;
    }
  };

  updateButtonDropDownRef = ref => {
    this.buttonDropDownRef = ref;
  };

  render() {
    const { searchSelectedItems, isFocus } = this.state;
    const {
      changeSearchTerm,
      collaborators,
      grades,
      searchText,
      inputContainerStyle,
      inputSearchBarContainerStyle,
      singlePlaceholder,
      placeholder,
      searchListTypes,
      mode,
      error,
      errorStyle,
      searchDropdownListContainerStyle,
      showErrorText,
      queryParams,
      disabledIds,
      portalType,
      courses,
      ownerId,
      searchMenuAddedText,
      searchType,
      archivalFilters,
      hideCollaborators,
      projectGroupId,
    } = this.props;

    const selectedItems = _.unionBy(searchSelectedItems, collaborators, "id");

    return (
      <div className={classes.container} style={inputContainerStyle}>
        <ButtonDropdown
          ref={this.updateButtonDropDownRef}
          {...dropDownStyle}
          shouldCloseOnSelfClick={false}
          closeOnOutSideClick={mode == "search"}
          buttonComponent={
            <InputSearchBar
              customRef={ref => (this.inputList = ref)}
              searchText={searchText}
              searchSelectedItems={searchSelectedItems}
              onDoneClick={this.onDoneClick}
              isFocus={isFocus}
              removeOption={this.removeOption}
              handleFocus={this.handleFocus}
              changeSearchTerm={changeSearchTerm}
              ref={ref => {
                this.inputSearchBarRef = ref;
              }}
              mode={mode}
              inputSearchBarContainerStyle={inputSearchBarContainerStyle}
              singlePlaceholder={singlePlaceholder}
              placeholder={placeholder}
              errorStyle={errorStyle}
              error={error}
              showErrorText={showErrorText}
              disabledIds={disabledIds}
            />
          }
          dropdownComponent={
            <SearchDropDown
              ownerId={ownerId}
              searchDropdownListContainerStyle={
                searchDropdownListContainerStyle
              }
              searchText={searchText}
              grades={grades}
              courses={courses}
              queryParams={queryParams}
              selectedItems={selectedItems}
              onAddClick={this.addOption}
              searchListTypes={searchListTypes}
              portalType={portalType}
              searchMenuAddedText={searchMenuAddedText}
              searchType={searchType}
              archivalFilters={archivalFilters}
              hideCollaborators={hideCollaborators}
              projectGroupId={projectGroupId}
            />
          }
        />
      </div>
    );
  }
}

export default SelectInput;
