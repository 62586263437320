import React from "react";
import PropTypes from "prop-types";
import { EmptyField, I18nHOC } from "UIComponents";
import classes from "./MultiSelectWithModal.scss";
import SelectionModal from "./SelectionModal";
import UIBaseComponent from "UIComponents/UIBaseComponent";

class MultiSelectWithModal extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalVisible: false,
      isEditModal: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { modalVisible } = this.state;
    const { modalVisible: prevModalVisible } = prevState;
    if (modalVisible != prevModalVisible) {
      if (modalVisible) {
        this.onFocus();
      } else {
        this.onBlur();
      }
    }
  };

  blur = () => {
    this.setState({ modalVisible: false });
  };

  renderLock = () => {
    return null;
  };

  onEditClick = () => {
    this.setState({ modalVisible: true, isEditModal: true });
  };

  updateInputField = params => {
    const { name, options } = this.props;
    this.updateValue(params[name], { nodes: options });
  };

  shouldShowEditEmpty = () => {
    const { value } = this.props;
    return !this.isValidValue(value);
  };

  renderResponseHeader = () => {
    const { responseHeaderText, mode } = this.props;
    return (
      !!responseHeaderText &&
      mode == "edit" && (
        <div
          className={classes.extraTextContainer}
          dangerouslySetInnerHTML={{ __html: responseHeaderText }}
        ></div>
      )
    );
  };

  renderEditEmpty = () => {
    const { t } = this.props;
    return (
      <div className={classes.innerContainer}>
        {this.renderResponseHeader()}
        <EmptyField
          title={`${t("common:add_with_label", { label: this.getLabel() })}`}
          showAdd={true}
          onAddNewClick={() =>
            this.setState({ modalVisible: true, isEditModal: false })
          }
        />
      </div>
    );
  };

  getLabel = () => {
    const { t, label, labelLocale } = this.props;
    return labelLocale ? t(labelLocale) : label;
  };

  getHeaderLabel = () => {
    const { t } = this.props;
    const { isEditModal } = this.state;

    const label = this.getLabel();
    const modeLabelLocale = isEditModal
      ? "common:add_with_label"
      : "common:add_with_label";

    return t(modeLabelLocale, { label });
  };

  renderEdit = () => {
    return null;
  };

  renderChildren = () => {
    const { name, value, options } = this.props;
    const { isEditModal, modalVisible } = this.state;

    const headerLabel = this.getHeaderLabel();

    return modalVisible ? (
      <SelectionModal
        name={name}
        options={options}
        isEdit={isEditModal}
        selectedOptions={value}
        headerLabel={headerLabel}
        updateInputField={this.updateInputField}
        updateValueLocally={this.updateValueLocally}
        onCancelClick={() => this.setState({ modalVisible: false })}
      />
    ) : null;
  };
}

MultiSelectWithModal.propTypes = {
  ...UIBaseComponent.propTypes,
  options: PropTypes.array,
  title: PropTypes.string,
};

MultiSelectWithModal.defaultProps = {
  ...UIBaseComponent.defaultProps,
  title: "",
  options: [],
  value: [],
};

export default I18nHOC({ resource: ["unitPlan", "common"] })(
  MultiSelectWithModal
);
