import React from "react";

const EY1Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g id="1st" fill={props.fill && props.fill}>
        <path d="M41.9839,19.4257 C43.4699,19.4257 44.7819,20.4957 44.7819,22.1197 C44.7819,22.7067 44.6089,23.5017 43.8499,24.4687 L36.2839,33.8657 L36.2839,41.2927 C36.2839,44.1247 34.3149,44.5747 33.3479,44.5747 C32.3459,44.5747 30.3769,44.1247 30.3769,41.2927 L30.3769,33.8657 L23.0189,24.5727 C22.1539,23.4677 22.0859,22.7757 22.0859,22.2927 C22.0859,20.6687 23.3629,19.4257 25.0219,19.4257 C25.8859,19.4257 26.7839,19.7357 27.9239,21.2567 L33.5549,28.8217 L39.2549,21.1177 C40.3259,19.7017 41.2239,19.4257 41.9839,19.4257 Z M56.9784,19.4254 C58.2214,19.4254 60.5714,19.9424 60.5714,23.5014 L60.5714,41.3284 C60.5714,44.1254 58.6024,44.5744 57.6694,44.5744 C56.7364,44.5744 54.7674,44.1254 54.7674,41.3284 L54.7674,26.2654 L52.1414,28.3034 C51.5204,28.7864 50.8644,28.9594 50.3454,28.9594 C48.9644,28.9594 47.8924,27.8894 47.8924,26.4384 C47.8924,25.6784 48.2384,24.9174 49.3094,24.1234 L54.3184,20.4614 C55.4234,19.6664 56.2874,19.4254 56.9784,19.4254 Z M17.4204,19.7705 C19.8384,19.7705 20.2174,21.4635 20.2174,22.2575 C20.2174,23.0875 19.8384,24.7795 17.4204,24.7795 L9.1294,24.7795 L9.1294,29.3745 L16.9014,29.3745 C19.3204,29.3745 19.6304,30.9975 19.6304,31.7235 C19.6304,32.4835 19.3204,34.1075 16.9014,34.1075 L9.1294,34.1075 L9.1294,39.1855 L18.0424,39.1855 C20.4604,39.1855 20.8394,40.8785 20.8394,41.7075 C20.8394,42.5375 20.4604,44.2285 18.0424,44.2285 L6.8144,44.2285 C4.5004,44.2285 3.4284,43.1585 3.4284,40.8435 L3.4284,23.1555 C3.4284,20.8415 4.5004,19.7705 6.8144,19.7705 L17.4204,19.7705 Z"></path>
      </g>
    </svg>
  );
};

EY1Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default EY1Icon;
