import { localSearch } from "Utils";

const isMatch = ({ value, searchText }) => {
  return localSearch({ text: value, searchText }) >= 0;
};

export const getMatchingOptions = ({ options, searchText }) => {
  return _.filter(options, ({ label }) => {
    return isMatch({ value: label, searchText });
  });
};
