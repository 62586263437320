import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { getCommunityCommonFeedQuery } from "CommunityParentEntity/modules/CommunityParentEntityQuery";
import { getCommunityCommonFeedFromCache } from "CommunityParentEntity/modules/CommunityParentEntityGraphqlHelpers";
import {
  withLoader,
  Loading,
  I18nHOC,
  NoDataComponent,
  MoreItemWithToolTip,
  LinkWithTooltip,
  BreadCrumbs,
} from "UIComponents";
import { Tick } from "SvgComponents";
import { getAssessmentOfUnitQuery } from "Assessments/modules/AssessmentQuery";
import { checkScrollAtBottom } from "services/checkScrollAtBottom";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import {
  convertDurationToString,
  getRethumbUrl,
  prepareLoadMoreFeed,
  getLabelValueForMultiFilterLabelButton,
} from "Utils";
import { ASSESSMENT_TOOLS } from "modules/Services";
import {
  LE_ASSESSMENT_TYPE_MAPPING,
  PLACEHOLDER_SQUARE_IMAGE_COUNT,
  PLACEHOLDER_SQUARE_IMAGE,
  colors,
} from "Constants";
import classes from "./LeFeed.scss";
import { getUserInfo } from "Login/modules/LoginModule";
import { getLELibraryFeedQuery } from "Teacher/routes/Courses/routes/Course/routes/LELibrary/modules/LELibraryQueries";
import { getLELibraryFeedFromCache } from "Teacher/routes/Courses/routes/Course/routes/LELibrary/modules/LELibraryGraphqlHelpers";
import { MoreFilters } from "AppComponents/LeFeed/MoreFilters";
import { MoreFiltersBar } from "AppComponents/LeFeed/MoreFiltersBar";
import { withLELibraryFilters } from "Course/routes/LELibrary/modules/HOC";
import {
  FILTER_NAME_ACADEMIC_YEAR,
  FILTER_NAME_GRADES,
  FILTER_NAME_CREATORS,
  FILTER_NAME_POST_TYPE,
  POST_TYPE_FILTER_MAPPING,
  FILTER_NAME_POST_ASSESSMENT_TYPE,
  FILTER_NAME_SUBJECTS,
} from "Course/Constants/StringConstants";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_MYP,
  CURRICULUM_TYPE_PYP,
  CURRICULUM_TYPE_UBD,
} from "Constants/stringConstants";
import classNames from "classnames";
import { getChildResourcesFeedQueryV2 } from "OrganizationResources/modules/OrganizationResourcesQuery";
import { getChildResourcesFromCacheV2 } from "OrganizationResources/modules/OrganizationResourcesGraphqlHelpers";
import update from "immutability-helper";
import {
  Checkbox,
  ChecklistDropdown,
  FilterLabelButton,
  Dropdown,
  DropdownMenu,
  ButtonDisclosure,
  SearchInput,
  Chip,
} from "@toddle-design/web";

import { FolderColored } from "@toddle-design/web-icons";
import { getScoreDetailsMemoize } from "ScoreCategories/modules/ScoreCategoriesModule";
import _ from "lodash";

const noOfFiltersOutOfMore = 1;

const getFilterDropdown = ({
  filter,
  onChangeFilterValues,
  filterValues,
  t,
  groupType,
}) => {
  const onFilterChange = params => {
    onChangeFilterValues({ ...filterValues, ...params }, Object.keys(params));
  };

  const filterId = _.get(filter, "id");

  const filterOptions = _.get(filter, "options", []);

  const filterLabel = _.get(filter, "label", "");
  const selectedOptions = _.get(filterValues, `${filterId}`, []);

  const updatedOptions = _.map(filterOptions, ({ label, value }) => {
    return {
      key: value,
      label,
      value,
    };
  });

  return (
    <LinkWithTooltip
      isVisible={!!filter.isToolTipVisible}
      tooltip={filter.toolTipText}
    >
      {_.get(filter, "isCheckList") ? (
        <ChecklistDropdown
          options={updatedOptions}
          value={selectedOptions}
          onChange={value => {
            onFilterChange({
              [`${filterId}`]: value,
            });
          }}
          showAllOption={filter.showAllOption}
          isSearchEnabled={filter.showSearchBar || filter.isSearchBasedOnQuery}
        >
          <FilterLabelButton
            variant="plain"
            size="medium"
            label={t(filterLabel)}
            labelValue={getLabelValueForMultiFilterLabelButton({
              options: updatedOptions,
              selectedOptions: _.map(selectedOptions, option => {
                const selectedOptionObj = _.find(
                  updatedOptions,
                  opt => opt?.key === option
                );
                return _.get(selectedOptionObj, "label");
              }),
              t,
            })}
          />
        </ChecklistDropdown>
      ) : (
        <Dropdown
          overlay={
            <DropdownMenu
              options={updatedOptions}
              onClick={e =>
                onFilterChange({
                  [`${filterId}`]: e?.key,
                })
              }
            />
          }
        >
          <ButtonDisclosure variant={"outlined-subtle"} size="medium">
            {_.get(
              _.find(
                updatedOptions,
                option => _.get(option, "value") == selectedOptions
              ),
              "label",
              ""
            )}
          </ButtonDisclosure>
        </Dropdown>
      )}
    </LinkWithTooltip>
  );
};

const getAssessments = ({ data }) => {
  if (!data) {
    return [];
  }
  return _.map(data, item => {
    return item.node;
  });
};

const getDropdownFilters = activeTab => {
  if (activeTab === "all") {
    return {
      groupType: undefined,
      assessmentTypes: undefined,
    };
  }
  return {
    groupType: activeTab === "le" ? "LEARNING_ENGAGEMENT" : "ASSESSMENT",
    assessmentTypes: activeTab,
  };
};

const getTabs = ({ t, feedAsPosts, curriculumType }) => {
  if (feedAsPosts) {
    switch (curriculumType) {
      case "IB_MYP":
      case "IB_DP":
        return [
          {
            label: t("common:all"),
            key: "all",
          },
          { label: t("common:le_label"), key: "le" },
          { label: t("classRoom:formative_assessment"), key: "fmt" },
          { label: t("classRoom:summative_assessment"), key: "smt" },
        ];
      case "UBD":
        return [
          {
            label: t("common:all"),
            key: "all",
          },
          { label: t("common:le_label"), key: "le" },
          { label: t("common:assessment"), key: "assessment" },
        ];
      default:
        return [];
    }
  } else {
    return [
      {
        label: t("common:all_label", { label: t("common:le_label_plural") }),
        key: `ALL`,
      },
      { label: t("common:my_bookmarks"), key: `SAVED` },
    ];
  }
};

const getTabsMemoize = _.memoize(
  params => getTabs(params),
  params => JSON.stringify(params)
);
const containerStyle = {
  background: `#f3fafb`,
  border: `1px solid ${colors.blue29}`,
};

const clickedLeItem = ({
  id,
  updateAssessmentId,
  isMultiSelect,
  selectedItems,
  updateSelectedItems,
}) => {
  if (isMultiSelect) {
    updateAssessmentId(id);
    const index = _.indexOf(selectedItems, id);
    let selectedItemsLocal = [];
    if (index < 0) {
      selectedItemsLocal = update(selectedItems, { $push: [id] });
    } else {
      selectedItemsLocal = update(selectedItems, { $splice: [[index, 1]] });
    }
    updateSelectedItems(selectedItemsLocal);
  } else {
    updateAssessmentId(id);
  }
};

const handleScroll = ({ hasNextPage, networkStatus, loadMore, scrollRef }) => {
  if (scrollRef) {
    if (
      hasNextPage &&
      networkStatus != 3 &&
      checkScrollAtBottom(scrollRef.current)
    ) {
      loadMore();
    }
  }
};

const CardWithProfilePicture = props => {
  const {
    feedItemTitleStyle,
    title,
    showCardWithStaffProfile,
    importFromLeLibrary,
    gradeNames,
    duration,
    assessmentTool,
    imageUrl,
    staffProfileImageStyle,
    staffNameStyle,
    name,
    t,
    maxScore,
    isScoreEnabled,
  } = props;

  return (
    <React.Fragment>
      <div className={classes.feedItemTitleText} style={feedItemTitleStyle}>
        {!_.isEmpty(title) ? title : t("common:untitled")}
      </div>
      <div className={classes.durationAndTool}>
        {showCardWithStaffProfile || importFromLeLibrary ? (
          <MoreItemWithToolTip items={gradeNames} length={1} noItemText={" "} />
        ) : (
          duration
        )}
        {(((showCardWithStaffProfile || importFromLeLibrary) &&
          !_.isEmpty(gradeNames)) ||
          duration) &&
          assessmentTool && (
            <span className={classes.dotContainer}>{" • "}</span>
          )}

        {assessmentTool && assessmentTool}

        {isScoreEnabled && maxScore && (
          <>
            {(assessmentTool || !_.isEmpty(gradeNames)) && (
              <span className={classes.dotContainer}>{` • `}</span>
            )}
            {t("classRoom:max_score_label", {
              label: maxScore,
            })}
          </>
        )}
      </div>
      <div className={classes.staffDetails}>
        <div
          className={classes.profileImageContainer}
          style={{
            backgroundImage: `url(${imageUrl})`,
            ...staffProfileImageStyle,
          }}
        />
        <div className={classes.staffNameWithUserPic} style={staffNameStyle}>
          {name}
        </div>
      </div>
    </React.Fragment>
  );
};

const CardWithoutProfilePicture = props => {
  const {
    typeObj,
    label,
    index,
    feedAsPosts,
    title,
    assessmentTool,
    duration,
    name,
    t,
  } = props;
  return (
    <React.Fragment>
      <div
        className={classes.assessmentTypeLabel}
        style={{ color: `${typeObj.color}` }}
      >
        {`${index + 1}# ${label ? t(label) : ""}`}
      </div>
      <div
        className={
          feedAsPosts ? classes.postItemTitleText : classes.feedItemTitleText
        }
      >
        {!_.isEmpty(title) ? title : t("common:untitled")}
      </div>
      <div className={classes.durationAndTool}>
        {duration && assessmentTool
          ? `${duration} • ${assessmentTool}`
          : duration
          ? duration
          : assessmentTool && assessmentTool}
      </div>
      <div
        className={
          feedAsPosts ? classes.staffDetailsPostFeed : classes.staffDetails
        }
      >
        <div className={classes.staffName}>
          {t("common:by_with_label", {
            label: name,
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

const FeedListComp = ({
  listItem,
  id,
  isSelected,
  updateAssessmentId,
  t,
  index,
  feedAsPosts,
  importFromLeLibrary,
  imageContainerStyle,
  customSelectedContainerStyle,
  customNonSelectedContainerStyle,
  currentlySelectedstyle,
  selectedItems,
  isMultiSelect,
  currentlySelected,
  updateSelectedItems,
  showCardWithStaffProfile,
  feedItemTitleStyle,
  staffProfileImageStyle,
  staffNameStyle,
  showCheckBox,
  updateCurrentUnitFlowSection,
}) => {
  const title = _.get(listItem, "title.value", "");
  const assessmentType = _.get(listItem, "assessmentType.value", "");

  const name = `${_.get(listItem, "createdBy.firstName", "")} ${_.get(
    listItem,
    "createdBy.lastName",
    ""
  )}`;
  const getImageUrlLe =
    _.get(listItem, "image.value", "") ||
    PLACEHOLDER_SQUARE_IMAGE[listItem?.id % PLACEHOLDER_SQUARE_IMAGE_COUNT];
  const imageUrlLe = getImageUrlLe
    ? getRethumbUrl({
        width: 200,
        height: 200,
        imageUrl: getImageUrlLe,
        fitIn: true,
      })
    : "";
  const getImageUrl =
    _.get(listItem, "createdBy.profileImage", "") || RESOURCE_PLACEHOLDER_URL;
  const imageUrl = getImageUrl
    ? getRethumbUrl({
        width: 50,
        height: 50,
        imageUrl: getImageUrl,
        fitIn: true,
      })
    : "";
  const duration = convertDurationToString(
    _.get(listItem, "duration.value", "")
  );

  const getItemValue = ({ fields, key }) => {
    return _.find(fields, item => item.uid == key) || {};
  };

  const gradeNames = _.map(_.get(listItem, "grades"), grade => grade.name);

  const tool = getItemValue({
    fields: _.get(listItem, "fields", []),
    key: "measureAssessing",
  });

  const { maxScore, isScoreEnabled } = getScoreDetailsMemoize({
    assessmentFields: _.get(listItem, "fields", []),
  });

  const toolName = _.get(
    _.find(
      ASSESSMENT_TOOLS,
      item =>
        item.type == _.get(tool, "resolvedMinimalTree.undefinedEntity", "")
    ),
    "locale",
    ""
  );
  const assessmentTool = toolName && t(toolName);

  const typeObj = LE_ASSESSMENT_TYPE_MAPPING[assessmentType] || {};
  const label = typeObj.localeLabel;

  const getFeedDataListStyles = () => {
    if (isMultiSelect) {
      if (currentlySelected) {
        return currentlySelectedstyle;
      } else {
        return customNonSelectedContainerStyle;
      }
    } else {
      if (isSelected) {
        return {
          ...containerStyle,
          ...customSelectedContainerStyle,
        };
      } else {
        return customNonSelectedContainerStyle;
      }
    }
  };

  const handleOnClickDataList = () => {
    clickedLeItem({
      id,
      updateAssessmentId,
      isMultiSelect,
      selectedItems,
      updateSelectedItems,
    });
  };

  const handleFolderClick = () => updateCurrentUnitFlowSection(id);

  const resourceType = _.get(listItem, "resourceType");
  const isFolder = _.isEqual(resourceType, "FOLDER");

  if (isFolder) {
    const label = _.get(listItem, "label");
    return (
      <div
        className={classes.feedDataListContainer}
        key={id}
        onClick={handleFolderClick}
        style={getFeedDataListStyles()}
      >
        <div className={classes.feedDataInnerContainer}>
          <div
            className={classes.folderImageContainer}
            style={imageContainerStyle}
          >
            <FolderColored size={"small"} />
          </div>
          <div className={classes.feedItemTitleText} style={feedItemTitleStyle}>
            {!_.isEmpty(label) ? label : t("common:untitled")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classes.feedDataListContainer}
      key={id}
      onClick={handleOnClickDataList}
      style={getFeedDataListStyles()}
    >
      <div
        className={classes.imageContainerLe}
        style={{
          backgroundImage: `url(${imageUrlLe})`,
          ...imageContainerStyle,
        }}
      />
      <div className={classes.rightPane}>
        <div className={classes.leDetails}>
          {feedAsPosts && !showCardWithStaffProfile ? (
            <CardWithoutProfilePicture
              typeObj={typeObj}
              label={label}
              index={index}
              feedAsPosts={feedAsPosts}
              title={title}
              assessmentTool={assessmentTool}
              duration={duration}
              name={name}
              t={t}
            />
          ) : (
            <CardWithProfilePicture
              feedItemTitleStyle={feedItemTitleStyle}
              title={title}
              showCardWithStaffProfile={showCardWithStaffProfile}
              importFromLeLibrary={importFromLeLibrary}
              gradeNames={gradeNames}
              duration={duration}
              assessmentTool={assessmentTool}
              imageUrl={imageUrl}
              staffProfileImageStyle={staffProfileImageStyle}
              staffNameStyle={staffNameStyle}
              name={name}
              t={t}
              isScoreEnabled={isScoreEnabled}
              maxScore={maxScore}
            />
          )}
        </div>

        {showCheckBox ? (
          isMultiSelect ? (
            <Checkbox isChecked={isSelected} />
          ) : isSelected ? (
            <Tick
              width={20}
              height={20}
              fillBackground={colors.blue29}
              fillTick={colors.white}
            />
          ) : (
            <div className={classes.tickEmptyIcon} />
          )
        ) : null}
      </div>
    </div>
  );
};

const getNoDataComponentText = ({ curriculumType, t, feedAsPosts }) => {
  if (curriculumType == "IB_PYP" || !feedAsPosts) {
    return t("common:le_label_plural");
  }

  return t("common:post_label_plural");
};
let LeFeedLocally = ({
  feedList,
  assessmentId,
  updateAssessmentId,
  hasNextPage,
  networkStatus,
  loadMore,
  t,
  totalCount,
  scrollRef,
  feedAsPosts,
  curriculumType,
  importFromLeLibrary,
  imageContainerStyle,
  customSelectedContainerStyle,
  customNonSelectedContainerStyle,
  feedContainerStyle,
  feedInnerContainerStyle,
  selectedItems,
  isMultiSelect,
  updateSelectedItems,
  currentlySelectedstyle,
  showCardWithStaffProfile,
  feedItemTitleStyle,
  staffProfileImageStyle,
  staffNameStyle,
  showCheckBox,
  updateCurrentUnitFlowSection,
  parentHierarchy,
  headerTabContainerStyle,
  currentFolder,
  customRef,
}) => {
  const totalPosts = feedAsPosts ? feedList.length : totalCount;

  const isCurrentlySelected = ({ id }) => {
    let isSelected = false;
    if (isMultiSelect) {
      isSelected = _.includes(selectedItems, id);
    } else {
      isSelected = assessmentId === id;
    }
    return isSelected;
  };

  const onHierarchyClick = id => updateCurrentUnitFlowSection(id);

  const isInsideFolder = !_.isEmpty(parentHierarchy);
  const currentFolderLabel = isInsideFolder
    ? _.get(_.first(currentFolder), "label", "")
    : undefined;

  useImperativeHandle(
    customRef,
    () => ({
      currentFolderLabel,
    }),
    [currentFolderLabel]
  );

  return (
    <>
      {isInsideFolder && (
        <div
          className={classes.breadCrumbsContainer}
          style={headerTabContainerStyle}
        >
          <BreadCrumbs
            hierarchy={parentHierarchy}
            onHierarchyClick={onHierarchyClick}
            postHierarchy={currentFolder}
          />
        </div>
      )}
      <div
        className={classes.feedContainer}
        style={feedContainerStyle}
        ref={scrollRef}
        onScroll={() =>
          handleScroll({
            hasNextPage,
            networkStatus,
            loadMore,
            scrollRef,
          })
        }
      >
        <div
          className={classes.feedInnerContainer}
          style={feedInnerContainerStyle}
        >
          {totalPosts ? (
            <React.Fragment>
              <div className={classes.titleText}>
                {t(
                  feedAsPosts || importFromLeLibrary
                    ? "common:post_label"
                    : "common:le_with_count",
                  {
                    count: totalPosts,
                  }
                )}
              </div>
              {_.map(feedList, (list, index) => {
                const id = feedAsPosts
                  ? _.get(list, "id", "")
                  : _.get(list, "node.id", "");

                const node = _.get(list, "node", []);
                const isSelected = isCurrentlySelected({ id });
                const currentlySelected = assessmentId === id;
                return (
                  <FeedListComp
                    listItem={feedAsPosts ? list : node}
                    key={id}
                    id={id}
                    index={index}
                    feedAsPosts={feedAsPosts}
                    isSelected={isSelected}
                    updateAssessmentId={updateAssessmentId}
                    t={t}
                    importFromLeLibrary={importFromLeLibrary}
                    imageContainerStyle={imageContainerStyle}
                    customSelectedContainerStyle={customSelectedContainerStyle}
                    customNonSelectedContainerStyle={
                      customNonSelectedContainerStyle
                    }
                    currentlySelectedstyle={currentlySelectedstyle}
                    selectedItems={selectedItems}
                    isMultiSelect={isMultiSelect}
                    currentlySelected={currentlySelected}
                    updateSelectedItems={updateSelectedItems}
                    showCardWithStaffProfile={showCardWithStaffProfile}
                    feedItemTitleStyle={feedItemTitleStyle}
                    staffProfileImageStyle={staffProfileImageStyle}
                    staffNameStyle={staffNameStyle}
                    showCheckBox={showCheckBox}
                    updateCurrentUnitFlowSection={updateCurrentUnitFlowSection}
                  />
                );
              })}
              {networkStatus == 3 && (
                <div className={classes.loadingContainer}>
                  <Loading />
                </div>
              )}
            </React.Fragment>
          ) : (
            <NoDataComponent
              containerStyle={{ flex: 1, minHeight: "400px" }}
              emptyText={t("common:filter_result_msg", {
                label: getNoDataComponentText({
                  t,
                  curriculumType,
                  feedAsPosts,
                }),
              })}
              emptyHeaderText={""}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  const {
    activeTab,
    searchText,
    portalType,
    feedAsPosts,
    unitPlanId,
    leFeedFilters,
    shouldIncludeQuickTasks,
    showSearchChip,
    currentUnitFlowSection,
    parentResourceId,
  } = ownProps;

  const curriculumType = state.platform.currentCurriculumProgramType;

  const assessmentTypes = getDropdownFilters(activeTab)?.assessmentTypes;
  const showFlatten = !_.isEmpty(searchText) && !showSearchChip;

  const orgResourceId = showFlatten ? parentResourceId : currentUnitFlowSection;
  const organizationResourceFilteres = {
    searchParentResourceId: orgResourceId,
    types: "PLANNER_FLOW_RESOURCE",
    resourceTypes: ["ASSESSMENT", "FOLDER"],
    assessmentTypes: !_.isEmpty(assessmentTypes) ? assessmentTypes : "",
    searchText: !_.isEmpty(searchText) ? searchText : "",
    searchParentResourceType: "FOLDER",
    isFlattenFeedStructure: showFlatten,
  };

  if (!feedAsPosts) {
    const userInfo = getUserInfo({ portalType });
    const orgId = userInfo.org_id;
    const curriculumProgramId = state.platform.currentCurriculumProgram.id;

    return {
      queryVariables: {
        id: userInfo.id,
        entityTypes: `ASSESSMENT`,
        feedType: activeTab == "ALL" ? undefined : activeTab,
        first: 9,
        searchText,
        portalType,
      },
      leLibraryFeedVariables: {
        id: orgId,
        filters: {
          isDeleted: false,
          isLeLibrary: true,
          shouldIncludeQuickTasks,
          searchText,
          curriculumProgramIds: [curriculumProgramId],
          ...leFeedFilters,
          //uniq is used as some enum types as Assessment repeat
          [FILTER_NAME_POST_TYPE]: _.uniq(
            _.map(_.get(leFeedFilters, `${FILTER_NAME_POST_TYPE}`, []), item =>
              _.get(POST_TYPE_FILTER_MAPPING, `${item}.enumType`)
            )
          ),
          // Filter is required as post type filter in few curriculum Types are not using assessmentTypes
          [FILTER_NAME_POST_ASSESSMENT_TYPE]: _.filter(
            _.map(_.get(leFeedFilters, `${FILTER_NAME_POST_TYPE}`, []), item =>
              _.get(POST_TYPE_FILTER_MAPPING, `${item}.assessmentType`)
            )
          ),
        },
      },
      isData: true,
      isLoading: false,
    };
  }
  return {
    queryVariables: {
      id: unitPlanId,
      portalType,
      filters: {
        searchText: searchText || undefined,
        ...getDropdownFilters(activeTab),
      },
    },
    isData: true,
    isLoading: false,
    curriculumType,
    organizationResourceFilteres,
    orgResourceId,
  };
};

LeFeedLocally = compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getCommunityCommonFeedQuery, {
    name: "getCommunityCommonFeed",
    skip: ({ feedAsPosts, importFromLeLibrary }) =>
      feedAsPosts || importFromLeLibrary,
    options: ({ queryVariables }) => ({
      fetchPolicy: "cache-and-network",
      variables: queryVariables,
    }),
    props({
      getCommunityCommonFeed,
      ownProps: { queryVariables, isData, isLoading },
    }) {
      const entityObject = _.get(
        getCommunityCommonFeed,
        "node.communityEntities",
        {}
      );
      const pageInfo = _.get(entityObject, "pageInfo", {});
      const data = getCommunityCommonFeedFromCache(queryVariables);
      return {
        getCommunityCommonFeed,
        hasNextPage: _.get(pageInfo, "hasNextPage", false),
        feedList: _.get(entityObject, "edges", []),
        totalCount: _.get(entityObject, "totalCount", 0),
        isData: !_.isEmpty(data) && isData,
        networkStatus: getCommunityCommonFeed.networkStatus,
        isLoading:
          getCommunityCommonFeed["networkStatus"] == 1 ||
          getCommunityCommonFeed["networkStatus"] == 2 ||
          isLoading,
        loadMore: () => {
          const endCursor = _.get(pageInfo, "endCursor", null);

          return getCommunityCommonFeed.fetchMore({
            query: getCommunityCommonFeedQuery,
            variables: {
              ...queryVariables,
              after: endCursor,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const previousEntityObj = previousResult.node;
              const newEntityObj = fetchMoreResult.node;

              return {
                ...previousResult,
                node: {
                  ...previousEntityObj,
                  communityEntities: {
                    ...previousEntityObj.communityEntities,
                    pageInfo: {
                      ...previousEntityObj.communityEntities.pageInfo,
                      ...newEntityObj.communityEntities.pageInfo,
                    },
                    edges: [
                      ...previousEntityObj.communityEntities.edges,
                      ...newEntityObj.communityEntities.edges,
                    ],
                  },
                },
              };
            },
          });
        },
      };
    },
  }),
  graphql(getAssessmentOfUnitQuery, {
    name: "getAssessmentOfUnit",
    skip: ({ feedAsPosts, importFromLeLibrary, currentUnitFlowSection }) =>
      currentUnitFlowSection || !feedAsPosts || importFromLeLibrary,
    options: ({ queryVariables }) => ({
      fetchPolicy: "cache-and-network",
      variables: queryVariables,
    }),
    props({ getAssessmentOfUnit, ownProps: { unitPlanId } }) {
      const unitPlanDetails = _.get(
        getAssessmentOfUnit,
        "node.resourceLibrary",
        {}
      );
      return {
        feedList: getAssessments({
          data: _.get(unitPlanDetails, "edges", []),
        }),
        isData: !_.isEmpty(unitPlanDetails),
        networkStatus: getAssessmentOfUnit.networkStatus,
        isLoading:
          getAssessmentOfUnit["networkStatus"] == 1 ||
          getAssessmentOfUnit["networkStatus"] == 2,
      };
    },
  }),
  graphql(getLELibraryFeedQuery, {
    name: "getLELibraryFeed",
    skip: ({ importFromLeLibrary }) => !importFromLeLibrary,
    options: ({ leLibraryFeedVariables }) => ({
      fetchPolicy: "cache-and-network",
      variables: leLibraryFeedVariables,
    }),
    props({ getLELibraryFeed, ownProps: { leLibraryFeedVariables } }) {
      const data = getLELibraryFeedFromCache(leLibraryFeedVariables);

      const assessments = _.get(data, "node.assessments", {});
      const feedList = _.get(assessments, "edges", []);
      const pageInfo = _.get(assessments, "pageInfo", {});
      const totalCount = _.get(assessments, "totalCount", {});
      const endCursor = _.get(pageInfo, "endCursor", null);

      return {
        getLELibraryFeed,
        hasNextPage: _.get(pageInfo, "hasNextPage", false),
        feedList,
        totalCount,
        isData: !_.isEmpty(data),
        networkStatus: getLELibraryFeed.networkStatus,
        isLoading:
          getLELibraryFeed["networkStatus"] == 1 ||
          getLELibraryFeed["networkStatus"] == 2,
        loadMore: prepareLoadMoreFeed({
          query: getLELibraryFeedQuery,
          variables: {
            ...leLibraryFeedVariables,
            after: endCursor,
          },
          queryData: getLELibraryFeed,
          key: "assessments",
        }),
      };
    },
  }),
  graphql(getChildResourcesFeedQueryV2, {
    name: "getChildResourcesFeedV2",
    skip: ({ isUnitFlow, currentUnitFlowSection }) =>
      _.isEmpty(currentUnitFlowSection) || !isUnitFlow,
    options: ({ organizationResourceFilteres, orgResourceId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: orgResourceId,
        filters: organizationResourceFilteres,
      },
    }),
    props({
      getChildResourcesFeedV2,
      ownProps: {
        isData,
        isLoading,
        organizationResourceFilteres,
        orgResourceId,
      },
    }) {
      const organizationResourcesData = getChildResourcesFromCacheV2({
        id: orgResourceId,
        filters: organizationResourceFilteres,
      });

      const pageInfo = _.get(organizationResourcesData, "children.pageInfo");
      const hasNextPage = _.get(pageInfo, "hasNextPage");
      const endCursor = _.get(pageInfo, "endCursor");

      const children = _.get(organizationResourcesData, "children.edges", []);

      const items = _.map(children, child => {
        const node = _.get(child, "node", {});
        const resourceType = _.get(node, "resourceType");
        switch (resourceType) {
          case "ASSESSMENT":
            return _.get(node, "item", []);
          case "FOLDER": {
            const { id, label, resourceType } = node;
            return {
              id,
              label,
              resourceType,
            };
          }
        }
      });

      const parentHierarchy = _.get(
        organizationResourcesData,
        "parentHierarchy",
        []
      );

      const currentFolder = [
        {
          id: _.get(organizationResourcesData, "id"),
          label: _.get(organizationResourcesData, "label"),
        },
      ];

      const networkStatus = _.get(getChildResourcesFeedV2, "networkStatus");

      return {
        isData: !_.isEmpty(organizationResourcesData) && isData,
        feedList: items,
        parentHierarchy,
        currentFolder,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
        hasNextPage,
        networkStatus,
        loadMore: prepareLoadMoreFeed({
          query: getChildResourcesFeedQueryV2,
          variables: {
            id: orgResourceId,
            filters: organizationResourceFilteres,
            after: endCursor,
          },
          queryData: getChildResourcesFeedV2,
          key: "children",
        }),
      };
    },
  }),
  withLoader
)(LeFeedLocally);

const getSubjectFilterOptions = ({ grades, subjects }) => {
  return _.filter(
    subjects,
    subject => !_.isEmpty(_.intersection(grades, subject.grades))
  );
};

const LeFeed = React.memo(
  ({
    t,
    assessmentId,
    setAssessmentId,
    unitPlanId,
    portalType,
    showHeaderText,
    feedConStyle,
    feedAsPosts,
    curriculumType,
    importFromLeLibrary,
    defaultAcademicYear,
    defaultGrades,
    defaultPostType,
    defaultCreators,
    curriculumProgramType,
    subjects,
    filtersInfo,
    showSubText = false,
    shouldIncludeQuickTasks,
    courseId,
    courseList,
    imageContainerStyle,
    customSearchBarStyle,
    customSelectedContainerStyle,
    customNonSelectedContainerStyle,
    feedContainerStyle,
    feedInnerContainerStyle,
    headerTabContainerStyle,
    selectedItems,
    isMultiSelect,
    isUnitFlow,
    currentUnitFlowSection,
    updateSelectedItems,
    currentlySelectedstyle,
    showCardWithStaffProfile,
    feedItemTitleStyle,
    staffProfileImageStyle,
    staffNameStyle,
    showCheckBox,
    updateCurrentUnitFlowSection,
    parentResourceId,
    groupType,
  }) => {
    const tabValues = getTabsMemoize({ t, feedAsPosts, curriculumType });
    const firstTab = _.get(tabValues[0], "key", undefined);
    const [activeTab, setActiveTab] = useState(firstTab);
    const [searchText, updateSearchText] = useState("");
    const [leFeedFilters, updateLeFeedFilters] = useState({});
    const [selectedMoreOptions, setSelectedMoreOptions] = useState([]);
    const [currentFolderLabel, setCurrentFolderLabel] = useState("");
    const [showSearchChip, setShowSearchChip] = useState(true);

    const customRef = useRef(null);
    const searchInputRef = useRef(null);

    const customCallbackRef = useCallback(node => {
      if (node !== null) {
        customRef.current = node;
        setCurrentFolderLabel(customRef?.current?.currentFolderLabel);
      }
    }, []);

    useEffect(() => {
      if (!_.isEmpty(currentFolderLabel)) {
        setShowSearchChip(true);
      }
    }, [currentFolderLabel]);

    let defaultGrade = defaultGrades ? defaultGrades : [];

    const grade = _.get(
      _.find(courseList, course => course.courseId === courseId),
      "grade.id"
    );
    if (grade) {
      defaultGrade = [grade];
    }

    const isPYPCurriculum = _.isEqual(
      curriculumProgramType,
      CURRICULUM_TYPE_PYP
    );
    let updatedFiltersInfo = filtersInfo;
    if (importFromLeLibrary && !isPYPCurriculum) {
      updatedFiltersInfo = _.map(filtersInfo, filterInfo => {
        if (filterInfo.id === FILTER_NAME_SUBJECTS) {
          const isDisabled = _.isEmpty(leFeedFilters[FILTER_NAME_GRADES]);
          return {
            ...filterInfo,
            isDisabled,
            options: getSubjectFilterOptions({
              grades: leFeedFilters[FILTER_NAME_GRADES],
              subjects,
            }),
            isToolTipVisible: isDisabled,
          };
        }
        return filterInfo;
      });
    }

    useEffect(() => {
      if (importFromLeLibrary) {
        onChangeLeFeedFilters({
          [FILTER_NAME_ACADEMIC_YEAR]: [defaultAcademicYear],
          [FILTER_NAME_GRADES]: defaultGrade,
          [FILTER_NAME_CREATORS]: defaultCreators,
          [FILTER_NAME_POST_TYPE]: defaultPostType,
          [FILTER_NAME_SUBJECTS]: [],
        });
        setSelectedMoreOptions(
          _.map(
            _.slice(
              updatedFiltersInfo,
              noOfFiltersOutOfMore,
              _.size(updatedFiltersInfo)
            ),
            item => item.id
          )
        );
      }
    }, []);

    const selectedMoreFilters = useMemo(
      () =>
        _.filter(updatedFiltersInfo, filter =>
          _.includes(selectedMoreOptions, filter.id)
        ),
      [selectedMoreOptions, updatedFiltersInfo]
    );

    const onChangeLeFeedFilters = (params, changedFilters) => {
      if (!isPYPCurriculum && _.first(changedFilters) === FILTER_NAME_GRADES) {
        //calculating subject list with changed grades
        const subjectOptions = getSubjectFilterOptions({
          grades: params[FILTER_NAME_GRADES],
          subjects,
        });

        //extracting values of subjects
        const subjectOptionsValues = _.map(
          subjectOptions,
          subject => subject.value
        );
        params = {
          ...params,
          //here we are only keeping those subjects in filters which are in the new calculated subject list
          [FILTER_NAME_SUBJECTS]: _.filter(
            leFeedFilters[FILTER_NAME_SUBJECTS],
            subject => _.includes(subjectOptionsValues, subject)
          ),
        };
      }
      updateLeFeedFilters(prev => ({ ...prev, ...params }));
    };

    const removeFilter = id => {
      onChangeLeFeedFilters({ [id]: [] });

      setSelectedMoreOptions(prev => {
        return _.filter(prev, option => !_.isEqual(option, id));
      });
    };

    const scrollRef = useRef(null);
    const resetScroll = () => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    };
    const onToggleTab = e => {
      const selectedTab = _.get(e, "key");
      setAssessmentId("");
      resetScroll();
      setActiveTab(selectedTab);
    };

    const updateSearchTerm = value => {
      updateSearchText(value);
      resetScroll();
    };

    const updateAssessmentId = id => {
      setAssessmentId(id != assessmentId ? id : "");
    };

    const getHeaderTitle = () => {
      return importFromLeLibrary
        ? _.isEqual(curriculumType, CURRICULUM_TYPE_PYP)
          ? t("common:add_from_learning_experience_library")
          : t("common:add_from_learning_experience_&_assessment_library")
        : t("community:search_on_toddle_community");
    };

    const getSubTitle = () => {
      return t("common:import_from_le_modal_subtext", {
        label: _.isEqual(curriculumType, CURRICULUM_TYPE_PYP)
          ? _.capitalize(t("common:le_label"))
          : t("common:learning_experience_or_assessment"),
      });
    };

    const getRightContainer = () => {
      return importFromLeLibrary ? (
        <MoreFilters
          filterValues={leFeedFilters}
          onChangeFilterValues={onChangeLeFeedFilters}
          getFilterDropdown={getFilterDropdown}
          selectedMoreOptions={selectedMoreOptions}
          setSelectedMoreOptions={setSelectedMoreOptions}
          filtersInfo={updatedFiltersInfo}
          noOfFiltersOutOfMore={noOfFiltersOutOfMore}
          t={t}
          groupType={groupType}
        />
      ) : !_.isEmpty(tabValues) ? (
        <Dropdown
          overlay={<DropdownMenu options={tabValues} onClick={onToggleTab} />}
        >
          <ButtonDisclosure size="large" variant="plain">
            {_.get(_.find(tabValues, { key: activeTab }), "label", "")}
          </ButtonDisclosure>
        </Dropdown>
      ) : null;
    };

    const leftPaneTitleClassList = classNames(classes.leftPaneTitleText, {
      [classes.leftPaneTitleTextWithSubtext]: showSubText,
    });

    const onCloseSearchChip = () => {
      searchInputRef?.current?.focus();
      setShowSearchChip(false);
    };

    const onFocusSearchInput = () => {
      const isInsideFolder = !_.isEmpty(currentFolderLabel);
      if (isInsideFolder && _.isEmpty(searchText)) {
        setShowSearchChip(true);
      }
    };

    const showChip = !_.isEmpty(currentFolderLabel) && showSearchChip;

    return (
      <div className={classes.container} style={feedConStyle}>
        <div className={classes.headerContainer}>
          {showHeaderText && (
            <div className={leftPaneTitleClassList}>{getHeaderTitle()}</div>
          )}
          {showSubText && (
            <div className={classes.subTitle}>{getSubTitle()} </div>
          )}
          <div
            className={classes.headerTabContainer}
            style={headerTabContainerStyle}
          >
            <div
              className={classes.searchInputContainer}
              style={customSearchBarStyle}
            >
              <SearchInput
                value={searchText}
                placeholder={t("common:search")}
                onChange={updateSearchTerm}
                onFocus={onFocusSearchInput}
                ref={searchInputRef}
                chip={
                  showChip && (
                    <Chip
                      isClosable={true}
                      icon={<FolderColored />}
                      onClose={onCloseSearchChip}
                    >
                      {currentFolderLabel}
                    </Chip>
                  )
                }
              />
            </div>

            {getRightContainer()}
          </div>
          {!_.isEmpty(selectedMoreFilters) && (
            <MoreFiltersBar
              selectedMoreFilters={selectedMoreFilters}
              getFilterDropdown={getFilterDropdown}
              filterValues={leFeedFilters}
              onChangeFilterValues={onChangeLeFeedFilters}
              removeFilter={removeFilter}
              t={t}
              groupType={groupType}
            />
          )}
        </div>
        <LeFeedLocally
          assessmentId={assessmentId}
          activeTab={activeTab}
          updateAssessmentId={updateAssessmentId}
          searchText={searchText}
          leFeedFilters={leFeedFilters}
          portalType={portalType}
          unitPlanId={unitPlanId}
          scrollRef={scrollRef}
          feedAsPosts={feedAsPosts}
          importFromLeLibrary={importFromLeLibrary}
          curriculumType={curriculumType}
          shouldIncludeQuickTasks={shouldIncludeQuickTasks}
          imageContainerStyle={imageContainerStyle}
          customSelectedContainerStyle={customSelectedContainerStyle}
          customNonSelectedContainerStyle={customNonSelectedContainerStyle}
          currentlySelectedstyle={currentlySelectedstyle}
          feedContainerStyle={feedContainerStyle}
          feedInnerContainerStyle={feedInnerContainerStyle}
          selectedItems={selectedItems}
          isMultiSelect={isMultiSelect}
          isUnitFlow={isUnitFlow}
          currentUnitFlowSection={currentUnitFlowSection}
          updateSelectedItems={updateSelectedItems}
          showCardWithStaffProfile={showCardWithStaffProfile}
          feedItemTitleStyle={feedItemTitleStyle}
          staffProfileImageStyle={staffProfileImageStyle}
          staffNameStyle={staffNameStyle}
          showCheckBox={showCheckBox}
          updateCurrentUnitFlowSection={updateCurrentUnitFlowSection}
          headerTabContainerStyle={headerTabContainerStyle}
          customRef={customCallbackRef}
          showSearchChip={showSearchChip}
          parentResourceId={parentResourceId}
        />
      </div>
    );
  }
);

LeFeed.propTypes = {
  showHeaderText: PropTypes.bool,
  feedConStyle: PropTypes.object,
};

LeFeed.defaultProps = {
  showHeaderText: true,
  feedConStyle: {},
  feedAsPosts: false,
  importFromLeLibrary: false,
  shouldIncludeQuickTasks: true,
  isMultiSelect: false,
  showCardWithStaffProfile: false,
  showCheckBox: true,
};

export default compose(
  I18nHOC({ resource: ["common", "community", "snp"] }),
  withLELibraryFilters
)(LeFeed);
