import client from "apolloClient";
import { generateRandomId } from "Utils";
import {
  writeRubricElementInCache,
  writeOrganizationRubricsInCache,
  getOrganizationRubricsFromCache,
} from "./RubricGraphqlHelpers";
import {
  createRubricIndicatorMutation,
  createRubricCriteriaMutation,
  updateRubricIndicatorMutation,
  updateRubricCriteriaMutation,
  updateRubricDescriptorMutation,
  deleteRubricIndicatorMutation,
  deleteRubricCriteriaMutation,
  createRubricMutation,
  deleteRubricMutation,
} from "./RubricMutation";
import {
  getAssessmentToolNodeDetailsFromCache,
  writeAssessmentToolNodeDetailsInCache,
} from "modules/CommonGraphqlHelpers";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  getOrganizationConstantsFromCache,
  getStaffBasicDetailsFromCache,
} from "modules/CommonGraphqlHelpers";

import {
  writeAssessmentToolOfParentInCache,
  getAssessmentToolOfParentFromCache,
} from "modules/AssessmentTool/AssessmentToolGraphqlHelpers";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import ACLStore from "lib/aclStore";

export const NAME = "rubric";

export const saveRubric = ({
  copyToolId,
  mode = "CREATE",
  isGlobal = true,
  title = "",
  parentId,
  parentType,
  filters,
  curriculumProgramId,
} = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const rubricId = copyToolId;
    const copyRubric = mode == "COPY";
    const organizationId = state.login.userInfo.org_id;
    const organizationConstants = getOrganizationConstantsFromCache(
      organizationId
    );
    const rubricLevels = _.get(organizationConstants, "rubricLevels", []);

    const organizationDeatils = getOrganizationRubricsFromCache({
      id: organizationId,
      filters,
    });

    const rubrics = _.get(organizationDeatils, "rubrics", []) || [];
    const criterias = _.map(rubricLevels, level => {
      return {
        label: level,
        id: generateRandomId(),
        __typename: "CriteriaRubric",
      };
    });

    const indicators = [
      { label: "", id: generateRandomId(), __typename: "IndicatorRubric" },
    ];

    const descriptors = _.map(criterias, obj => {
      return {
        id: generateRandomId(),
        label: "",
        indicator: indicators[0],
        criteria: obj,
        __typename: "DescriptorRubric",
      };
    });

    let rubricData = getAssessmentToolNodeDetailsFromCache({
      id: rubricId,
      type: "RUBRIC",
    });

    if (!copyRubric) {
      rubricData = {
        id: generateRandomId(),
        __typename: "Rubric",
        title,
        indicators,
        criterias,
        descriptors,
        createdBy: getStaffBasicDetailsFromCache(state.login.userInfo.id),
      };
    } else {
      rubricData = {
        ...rubricData,
        id: generateRandomId(),
        title,
        createdBy: getStaffBasicDetailsFromCache(state.login.userInfo.id),
      };
    }
    try {
      const result = await client.mutate({
        mutation: createRubricMutation,
        variables: {
          createdBy: state.login.userInfo.id,
          criterias: _.map(criterias, criteria => _.pick(criteria, "label")),
          indicators: _.map(indicators, indicator =>
            _.pick(indicator, "label")
          ),
          title,
          copyRubric,
          rubricId,
          isGlobal,
          parentId,
          parentType,
          ...(isGlobal && { curriculumProgramId }),
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //   planner: {
        //     __typename: "PlannerMutations",
        //     createRubric: rubricData
        //   }
        // },
        update: (
          cache,
          {
            data: {
              planner: { createRubric },
            },
          }
        ) => {
          if (!_.isEmpty(createRubric)) {
            if (isGlobal) {
              const data = {
                ...organizationDeatils,
                rubrics: [...rubrics, createRubric],
              };

              setTimeout(() =>
                writeOrganizationRubricsInCache({
                  id: organizationId,
                  data,
                  filters,
                })
              );
            }
            let updatedAssessmentDetails = {};
            let parentAssessmentToolDetails;
            if (parentId) {
              parentAssessmentToolDetails = getAssessmentToolOfParentFromCache({
                id: parentId,
                type: parentType,
              });
              updatedAssessmentDetails = {
                ...parentAssessmentToolDetails,
                assessmentTool: createRubric,
              };
              setTimeout(() =>
                writeAssessmentToolOfParentInCache({
                  id: parentId,
                  data: updatedAssessmentDetails,
                  type: parentType,
                })
              );
            }
          }
        },
      });

      if (!ACLStore.can("FeatureFlag:AssessmentCreationRevamp")) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:successfully_added_a_rubric",
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      }
      return _.get(result, "data.planner.createRubric.id", null);
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      console.error(e);
      return null;
    }
  };
};

export const updateRubricElement = ({ id, label, type }) => {
  return async (dispatch, getState) => {
    try {
      let mutation = "";
      switch (type) {
        case "DESCRIPTOR":
          mutation = updateRubricDescriptorMutation;
          break;
        case "INDICATOR":
          mutation = updateRubricIndicatorMutation;
          break;
        case "CRITERIA":
          mutation = updateRubricCriteriaMutation;
          break;
      }
      await client.mutate({
        mutation,
        variables: {
          label,
          updatedBy: getState().login.userInfo.id,
          id,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const writeRubricElementLocal = ({
  id,
  type,
  label,
  elementDetails,
}) => {
  return (dispatch, getState) => {
    const updatedElementDetails = { ...elementDetails, label };
    writeRubricElementInCache({ id, type, data: updatedElementDetails });
  };
};

export const createRubricIndicator = ({ rubricId }) => {
  return async (dispatch, getState) => {
    try {
      const rubricData = getAssessmentToolNodeDetailsFromCache({
        id: rubricId,
        type: "RUBRIC",
      });

      const indicator = {
        __typename: "IndicatorRubric",
        id: generateRandomId(),
        label: "",
      };

      const criterias = _.get(rubricData, "criterias", []);
      const newDescriptors = _.map(criterias, criteria => {
        return {
          id: generateRandomId(),
          label: "",
          __typename: "DescriptorRubric",

          indicator,
          criteria,
        };
      });

      await client.mutate({
        mutation: createRubricIndicatorMutation,
        variables: {
          label: "",
          createdBy: getState().login.userInfo.id,
          rubricId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createRubricIndicator: {
              __typename: "IndicatorRow",
              indicator,
              descriptors: newDescriptors,
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createRubricIndicator },
            },
          }
        ) => {
          let indicators = _.get(rubricData, "indicators", []);
          if (!indicators) {
            indicators = [];
          }
          indicators = [...indicators, createRubricIndicator.indicator];
          let descriptors = _.get(rubricData, "descriptors", []);
          if (!descriptors) {
            descriptors = [];
          }
          descriptors = [...descriptors, ...createRubricIndicator.descriptors];
          const data = {
            ...rubricData,
            indicators,
            descriptors,
          };

          setTimeout(() =>
            writeAssessmentToolNodeDetailsInCache({
              id: rubricId,
              data,
              type: "RUBRIC",
            })
          );
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const createRubricCriteria = ({ rubricId }) => {
  return async (dispatch, getState) => {
    try {
      const rubricData = getAssessmentToolNodeDetailsFromCache({
        id: rubricId,
        type: "RUBRIC",
      });

      const criteria = {
        __typename: "CriteriaRubric",
        id: generateRandomId(),
        label: "",
      };

      const indicators = _.get(rubricData, "indicators", []);
      const newDescriptors = _.map(indicators, indicator => {
        return {
          id: generateRandomId(),
          label: "",
          __typename: "DescriptorRubric",

          indicator,
          criteria,
        };
      });

      await client.mutate({
        mutation: createRubricCriteriaMutation,
        variables: {
          label: "",
          createdBy: getState().login.userInfo.id,
          rubricId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createRubricCriteria: {
              __typename: "CriteriaColumn",
              criteria,
              descriptors: newDescriptors,
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createRubricCriteria },
            },
          }
        ) => {
          let criterias = _.get(rubricData, "criterias", []);
          if (!criterias) {
            criterias = [];
          }
          criterias = [...criterias, createRubricCriteria.criteria];
          let descriptors = _.get(rubricData, "descriptors", []);
          if (!descriptors) {
            descriptors = [];
          }
          descriptors = [...descriptors, ...createRubricCriteria.descriptors];
          const data = {
            ...rubricData,
            criterias,
            descriptors,
          };

          setTimeout(() =>
            writeAssessmentToolNodeDetailsInCache({
              id: rubricId,
              data,
              type: "RUBRIC",
            })
          );
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteRubric = ({ id, filters }) => {
  return async (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;

    const organizationDeatils = getOrganizationRubricsFromCache({
      id: organizationId,
      filters,
    });

    const rubrics = _.get(organizationDeatils, "rubrics", []);
    try {
      await client.mutate({
        mutation: deleteRubricMutation,
        variables: {
          id: id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteRubric: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteRubric },
            },
          }
        ) => {
          if (deleteRubric) {
            const updatedRubrics = _.filter(rubrics, rub => rub.id != id);
            const data = {
              ...organizationDeatils,
              rubrics: updatedRubrics,
            };

            setTimeout(() =>
              writeOrganizationRubricsInCache({
                id: organizationId,
                data,
                filters,
              })
            );
          }
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:successfully_deleted_a_rubric_template",
          type: "tick",
          position: "toast-bottom-right",
        })
      );
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
      setTimeout(() =>
        writeOrganizationRubricsInCache({
          id: organizationId,
          data: organizationDeatils,
          filters,
        })
      );
      console.error(e);
    }
  };
};

export const deleteRubricIndicator = ({ id, rubricId }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: deleteRubricIndicatorMutation,
        variables: {
          id: id,
          deletedBy: getState().login.userInfo.id,
          rubricId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteRubricIndicator: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteRubricIndicator },
            },
          }
        ) => {
          if (deleteRubricIndicator) {
            let rubricData = getAssessmentToolNodeDetailsFromCache({
              id: rubricId,
              type: "RUBRIC",
            });
            let indicators = _.get(rubricData, "indicators", []);

            indicators = _.filter(indicators, obj => obj.id != id);
            let descriptors = _.get(rubricData, "descriptors", []);
            descriptors = _.filter(descriptors, obj => obj.indicator.id != id);
            rubricData = {
              ...rubricData,
              indicators,
              descriptors,
            };
            setTimeout(() =>
              writeAssessmentToolNodeDetailsInCache({
                id: rubricId,
                data: rubricData,
                type: "RUBRIC",
              })
            );
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteRubricCriteria = ({ id, rubricId }) => {
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: deleteRubricCriteriaMutation,
        variables: {
          id: id,
          deletedBy: getState().login.userInfo.id,
          rubricId,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteRubricCriteria: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteRubricCriteria },
            },
          }
        ) => {
          if (deleteRubricCriteria) {
            let rubricData = getAssessmentToolNodeDetailsFromCache({
              id: rubricId,
              type: "RUBRIC",
            });
            let criterias = _.get(rubricData, "criterias", []);

            criterias = _.filter(criterias, obj => obj.id != id);
            let descriptors = _.get(rubricData, "descriptors", []);
            descriptors = _.filter(descriptors, obj => obj.criteria.id != id);
            rubricData = {
              ...rubricData,
              criterias,
              descriptors,
            };
            setTimeout(() =>
              writeAssessmentToolNodeDetailsInCache({
                id: rubricId,
                data: rubricData,
                type: "RUBRIC",
              })
            );
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

const REDUCER_HANDLERS = {};

const initialState = {};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
