import React from "react";

const Grade5Icon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 64 64">
      <g fill={props.fill && props.fill}>
        <path d="M32.0342,32.1562 C29.7552,32.1562 28.1652,33.7442 28.1652,36.0252 C28.1652,38.1312 29.6852,39.8942 32.0002,39.8942 C34.2802,39.8942 35.8352,38.3042 35.8352,35.9902 C35.8352,33.7792 34.3142,32.1562 32.0342,32.1562 L32.0342,32.1562 Z M30.6522,20.8922 C31.5852,19.6152 32.4832,19.2692 33.3122,19.2692 C34.8672,19.2692 36.1112,20.3062 36.1112,21.8942 C36.1112,22.4472 35.9722,23.2422 35.2132,24.1762 L32.0002,28.1482 L32.1032,28.1482 C32.5532,27.9742 33.1752,27.8712 33.7272,27.8712 C38.0112,27.8712 41.6382,31.0842 41.6382,35.8512 C41.6382,41.4142 37.2852,44.7302 32.0002,44.7302 C26.6802,44.7302 22.3612,41.3442 22.3612,35.9902 C22.3612,33.0882 23.5362,30.4982 25.0912,28.4242 L30.6522,20.8922 Z"></path>
      </g>
    </svg>
  );
};

Grade5Icon.defaultProps = {
  height: 64,
  width: 64,
};

export default Grade5Icon;
