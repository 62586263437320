import { withAsyncRouteLoading } from "UIComponents";
import LearningStandards from "./routes/LearningStandards";
import SubjectGroupsAndSubjects from "./routes/SubjectGroupsAndSubjects";
import MYPRelatedConcepts from "./routes/MYPRelatedConcepts";
import AssessmentCriteria from "AssessmentCriteria";
import MYPKeyConcepts from "./routes/MYPKeyConcepts";
import EvaluationCriteria from "EvaluationCriteria";
import LocalGrades from "./routes/LocalGrades";
import StudentProfileTemplates from "Administrator/commonComponents/StudentProfileTemplates";
import TemplateGradeMapping from "Administrator/commonComponents/TemplateGradeMapping";
import AssessmentToolSetup from "AssessmentToolSetup";
import LearnerProfileAttributes from "Administrator/commonComponents/LearnerProfileAttributes";
import Atls from "Administrator/commonComponents/Atls";
import ScoreCategories from "./routes/ScoreCategories";
import Continuum from "Continuum";
import { updateChildRoutes } from "Administrator/modules/AdministratorModule";

export default store => ({
  path: "mypAcademicSetup",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
  onEnter(nextState) {
    const { routes } = nextState;
    store.dispatch(
      updateChildRoutes({
        routes,
        path: "mypAcademicSetup",
        pathId: "MYP_ACADEMIC_SETUP",
      })
    );
  },
  childRoutes: [
    SubjectGroupsAndSubjects(store),
    LearningStandards(store),
    MYPKeyConcepts(store),
    LearnerProfileAttributes(store),
    Atls(store),
    MYPRelatedConcepts(store),
    EvaluationCriteria(store),
    AssessmentCriteria(store),
    LocalGrades(store),
    StudentProfileTemplates(store),
    TemplateGradeMapping(store),
    AssessmentToolSetup(store),
    ScoreCategories(store),
    Continuum(store),
  ],
});
