import React, { PureComponent } from "react";
import classes from "./EmptyField.scss";
import PropTypes from "prop-types";
import { AddCircleIcon } from "SvgComponents";
import { colors } from "Constants";
import classNames from "classnames";

const RenderIcon = ({ showAdd, renderIcon }) => {
  let updatedRenderIcon = null;
  if (showAdd) {
    updatedRenderIcon = <AddCircleIcon width={24} height={24} />;
  }
  if (renderIcon) {
    updatedRenderIcon = renderIcon;
  }

  return (
    updatedRenderIcon && (
      <div className={classes.renderIcon}>{updatedRenderIcon}</div>
    )
  );
};
class EmptyField extends PureComponent {
  render() {
    const {
      showAdd,
      onAddNewClick,
      titleStyles,
      containerStyle,
      title,
      isDisabled,
      renderIcon,
      theme,
    } = this.props;

    const showButton = !isDisabled && showAdd;
    const containerClass = classNames(
      { [classes.container]: !showAdd },
      { [classes.containerButton]: showAdd },
      { [classes.disableButton]: isDisabled },
      { [classes.renderBlueTheme]: !!theme }
    );

    const containerUpdatedStyle = {
      ...containerStyle,
      cursor: showButton ? "pointer" : "auto",
    };
    const texts = _.split(title, "\n");

    return (
      <div
        className={containerClass}
        style={containerUpdatedStyle}
        onClick={() => {
          showButton ? onAddNewClick() : null;
        }}
      >
        <RenderIcon showAdd={showAdd} renderIcon={renderIcon} />
        {texts[0] && (
          <div className={classes.title} style={this.props.titleStyles}>
            {texts[0]}
          </div>
        )}
        {texts[1] && <div className={classes.subText}>{texts[1]}</div>}
      </div>
    );
  }
}

EmptyField.propTypes = {
  onAddNewClick: PropTypes.func,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
  showAdd: PropTypes.bool,
  containerStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  renderIcon: PropTypes.any,
};

EmptyField.defaultProps = {
  title: "",
  titleStyles: {},
  showAdd: false,
  containerStyle: {},
  isDisabled: false,
};

export default EmptyField;
