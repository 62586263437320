import React from "react";
import classNames from "classnames";
import classes from "./TabList.scss";
import _ from "lodash";

const TabList = React.memo(props => {
  const {
    tabs,
    activeTab,
    toggleTab,
    containerStyle,
    activeTabContainerStyle,
    tabContainerStyle,
    subtextStyle,
    minimumTabCount,
    showTabUnreadCount,
    labelStyle,
    badgeStyle,
    showUpdatedTab = false,
  } = props;

  return (
    _.get(tabs, "length", 0) >= minimumTabCount && (
      <div className={classes.container} style={containerStyle}>
        {_.map(tabs, tab => {
          const isActive = tab.value == activeTab;
          const tabClass = classNames(
            { [classes.tab]: true },
            { [classes.activeTab]: isActive },
            { [classes.updatedActiveTab]: isActive && showUpdatedTab },
            { [classes.nonActiveTab]: !isActive },
            { [classes.notSubTextTab]: tab.subtext ? false : true }
          );

          return (
            <div
              className={tabClass}
              key={tab.value}
              onClick={() => toggleTab(tab.value)}
              style={isActive ? activeTabContainerStyle : tabContainerStyle}
            >
              <span className={classes.tabLabel} style={labelStyle}>
                {tab.label}
                {showTabUnreadCount && _.get(tab, "unreadCount", "") ? (
                  <div className={classes.badge} style={badgeStyle}>
                    {tab.unreadCount}
                  </div>
                ) : null}
              </span>
              {tab.subtext ? (
                <span style={subtextStyle}>{tab.subtext}</span>
              ) : null}
            </div>
          );
        })}
      </div>
    )
  );
});

export default TabList;

TabList.defaultProps = {
  minimumTabCount: 1,
  showTabUnreadCount: true,
  subtextStyle: {},
  shouldShowHoverState: false,
};
