import React from "react";
import _ from "lodash";
import commonClasses from "../common.scss";
import classes from "./ParticipantDetails.scss";
import { I18nHOC } from "UIComponents";
import classNames from "classnames";
import { UIButton } from "UIComponents";
import { ParticipantDetails } from "Planathon/data";
const ParticipantDetailsComp = props => {
  const { t } = props;
  const { title, description, steps } = ParticipantDetails;
  const containerClass = classNames(
    { [commonClasses.container]: true },
    { [classes.container]: true }
  );

  return (
    <div className={classes.outerContainer}>
      <div className={containerClass}>
        <div className={commonClasses.title}>{t(title)}</div>
        <div className={commonClasses.description}>{t(description)}</div>
        <div className={classes.stepsContainer}>
          {_.map(steps, (step, index) => {
            const { title, image } = step;
            return (
              <div className={classes.step} key={index}>
                <div className={classes.stepImage}>
                  <img src={image} height={"100%"}></img>
                </div>
                <div className={classes.stepTitle}>{t(title)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default I18nHOC({ resource: ["planathon"] })(ParticipantDetailsComp);
