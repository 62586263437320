import React from "react";
import PropTypes from "prop-types";

const ArtSGIcon = props => {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 32 32"
      fill={props.fill}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M22.4525 9.5H19.4372C19.2594 9.5 19.1181 9.4118 19.0533 9.29305C18.432 8.15509 18.4257 7.28096 18.5931 6.57269C18.6796 6.20668 18.8157 5.87234 18.9614 5.54781C18.9923 5.47903 19.0247 5.40847 19.0575 5.33693C19.1709 5.09013 19.2896 4.8318 19.3682 4.59542C19.5975 3.90515 19.7004 3.22231 19.7471 2.75634C19.7579 2.64849 19.7932 2.56915 19.8251 2.52838C19.8395 2.51006 19.8493 2.50413 19.8518 2.50281C19.8518 2.50278 19.8519 2.50275 19.8519 2.50271C19.853 2.50211 19.8561 2.50035 19.8665 2.50018C20.3223 2.49257 21.4323 2.72305 22.8159 4.93413C24.1417 7.05266 23.2134 8.72914 22.73 9.38385C22.6841 9.44608 22.5919 9.5 22.4525 9.5Z"
          fill="white"
          stroke="#5856D6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 9.5H13.5V27C13.5 28.3807 12.3807 29.5 11 29.5C9.61929 29.5 8.5 28.3807 8.5 27V9.5Z"
          fill="#D4D1FF"
          stroke="#5856D6"
        />
        <path
          d="M10.0299 3.38057L8.5 9.5H13.5L11.9701 3.38057C11.7177 2.37062 10.2823 2.37062 10.0299 3.38057Z"
          fill="white"
          stroke="#5856D6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 12.57L19.3772 9.5H22.6228L23.5 12.57V13.5H18.5V12.57Z"
          fill="#D4D1FF"
          stroke="#5856D6"
        />
        <path
          d="M23.5 27V13.5H18.5V27C18.5 28.3807 19.6193 29.5 21 29.5C22.3807 29.5 23.5 28.3807 23.5 27Z"
          fill="#D4D1FF"
          stroke="#5856D6"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ArtSGIcon.defaultProps = {
  fill: "none",
  height: 32,
  width: 32,
};

ArtSGIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

export default ArtSGIcon;
