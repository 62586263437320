import PropTypes from "prop-types";
import React from "react";

const NoSearchIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 334 334">
      <g fill={props.fill && props.fill}>
        <path
          d="M271.317,309.168l-33.247-33.247c-1.032-1.033-2.323-1.578-3.73-1.578c-1.426,0-2.482,0.563-2.778,0.739
      c-9.706,5.823-20.743,8.9-31.918,8.901c-0.001,0-0.003,0-0.003,0c-16.371,0-31.9-6.511-43.724-18.334
      c-24.448-24.449-24.448-64.232,0-88.683c11.826-11.824,27.573-18.336,44.343-18.336s32.518,6.512,44.342,18.336
      c10.085,10.085,16.279,22.731,17.913,36.568c1.585,13.438-1.427,27.316-8.484,39.08c-1.018,1.709-1.211,4.451,0.772,6.436
      l33.315,33.316c2.878,2.876,4.696,6.31,5.119,9.663c0.286,2.296,0.039,5.626-2.79,8.454l-1.012,1.012
      c-1.886,1.886-4.304,2.883-6.996,2.883C278.683,314.379,274.628,312.48,271.317,309.168z M164.309,185.358
      c-9.589,9.588-14.869,22.355-14.869,35.95s5.28,26.362,14.869,35.952c9.59,9.589,22.356,14.868,35.951,14.868
      c13.596,0,26.361-5.279,35.951-14.868c9.589-9.59,14.869-22.357,14.869-35.952s-5.28-26.362-14.869-35.95
      c-9.59-9.589-22.357-14.868-35.951-14.868C186.664,170.49,173.897,175.769,164.309,185.358z"
        />
        <path
          d="M323.544,80.107c0,0-13.775,0-18.367,0c-2.064,0-1.753-3.068-1.753-3.068v-9.207c0-12.087-9.834-21.921-21.922-21.921
      H131.525c-1.007,0-2.225-2.023-4.411-6.274c-0.325-0.632-0.652-1.267-0.986-1.898c-7.644-14.485-17.565-17.525-24.542-17.525
      H62.567c-9.864,0-15.751,5.729-22.686,17.03c-0.452,0.736-0.896,1.483-1.338,2.23c-2.235,3.77-3.919,6.437-5.218,6.437H21.922
      C9.834,45.911,0,55.745,0,67.832v182.24c0,10.712,7.725,19.647,17.894,21.544c1.23,0.432,2.579,0.675,4.028,0.675h112.807
      c5.821,0,2.928-3.758,1.454-5.721c-22.929-30.572-20.504-74.263,7.285-102.053c14.732-14.73,34.348-22.843,55.23-22.843
      c20.886,0,40.5,8.112,55.23,22.843c12.559,12.56,20.275,28.331,22.314,45.609c1.73,14.67-0.922,29.749-7.494,43.105
      c-0.586,1.189-1.907,3.466-0.041,5.332l12.392,12.392c0,0,1.331,1.335,2.823,1.335c1.356,0,5.425,0,5.425,0
      c7.233,0,14.189-5.828,15.456-12.949l29.589-166.285C335.66,85.935,330.778,80.107,323.544,80.107z M284.133,80.107
      c-57.004,0-228.016,0-228.016,0c-7.233,0-14.189,5.828-15.456,12.949L18.659,216.702c0,0-1.123,4.138-1.123-3.535
      c0-36.335,0-145.336,0-145.336c0-2.377,2.008-4.384,4.386-4.384h11.403c11.389,0,16.752-9.045,20.302-15.029
      c0.396-0.67,0.795-1.341,1.199-2.003c3.53-5.751,6.289-8.667,9.641-8.667h37.119c1.167,0,4.719,0,9.032,8.173
      c0.305,0.578,0.604,1.159,0.901,1.736c3.234,6.288,8.122,15.79,20.006,15.79h149.976c2.378,0,4.385,2.007,4.385,4.384v8.768
      C285.887,76.6,286.16,80.107,284.133,80.107z"
        />
      </g>
    </svg>
  );
};

NoSearchIcon.defaultProps = {
  width: 24,
  height: 24,
};

NoSearchIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default NoSearchIcon;
