import React from "react";
import classes from "./FooterV2.scss";
import { I18nHOC } from "UIComponents";
import {
  SplitButtonDropdown,
  Button,
  DropdownMenu,
  Tooltip,
} from "@toddle-design/web";
import moment from "moment";
import classNames from "classnames";
import { ClockAlarmOutlined } from "@toddle-design/web-icons";
import ConditionalWrapper from "Utils/ConditionalWrapper";

const getCreateOptions = t => [
  {
    label: t("common:assign"),
    key: "assign",
  },
  { key: "schedule", label: t("common:schedule") },
  { isDivider: true, key: "1-2" },
  {
    key: "discard",
    label: t("common:discard"),
    isDestructive: true,
  },
];

const getEditOptions = t => [
  {
    label: t("common:update"),
    key: "update",
  },
  { key: "reschedule", label: t("common:reschedule") },
  { isDivider: true, key: "1-2" },
  {
    key: "discard",
    label: t("common:discard_label", {
      label: t("classRoom:post_settings"),
    }),
    isDestructive: true,
  },
];

const getSplitButtonOptions = ({ t, mode, showScheduleButton }) => {
  switch (mode) {
    case "create":
      return getCreateOptions(t);
    case "edit": {
      const editOptions = getEditOptions(t);
      if (showScheduleButton) {
        return editOptions;
      } else {
        return _.filter(
          editOptions,
          ({ key }) => !_.isEqual(key, "reschedule")
        );
      }
    }
    default:
      return getCreateOptions(t);
  }
};

const getShowScheduleButton = ({
  currentStep,
  isScheduled,
  scheduleDate,
  mode,
}) => {
  if (_.isEqual(currentStep, "SETTINGS")) {
    switch (mode) {
      case "create":
        return !_.isEmpty(scheduleDate);
      case "edit":
        return isScheduled && !_.isEmpty(scheduleDate);
    }
  } else {
    return false;
  }
};

const getButtonText = ({ t, mode, isScheduled }) => {
  if (mode === "create") {
    if (isScheduled) {
      return t("common:schedule");
    } else {
      return t("common:assign");
    }
  } else {
    return t("common:update");
  }
};

const getToolTipText = ({ courses }) => {
  return _.map(
    _.filter(
      courses,
      course => !_.isEmpty(_.get(course, "state.scheduleDate"))
    ),
    course => {
      return <div>{_.get(course, "title")}</div>;
    }
  );
};

const getLastStepButtons = params => {
  const {
    t,
    mode,
    resourceSelection,
    onClickDone,
    onClickSchedule,
    isScheduled,
    showScheduleButton,
    onClickCancel,
  } = params;

  const onChangeButtonOption = ({ key }) => {
    switch (key) {
      case "assign":
      case "update":
        onClickDone();
        break;
      case "schedule":
      case "reschedule":
        onClickSchedule();
        break;
      case "discard":
        onClickCancel();
        break;
      default:
        null;
    }
  };

  if (resourceSelection) {
    return (
      <Button size="medium" variant="progressive" onClick={onClickDone}>
        {t("common:add")}
      </Button>
    );
  } else {
    if (mode === "create" || isScheduled) {
      return (
        <SplitButtonDropdown
          overlay={
            <DropdownMenu
              options={getSplitButtonOptions({
                t,
                mode,
                showScheduleButton: isScheduled,
              })}
              showSelection
              onClick={onChangeButtonOption}
            />
          }
        >
          <Button size={"medium"} onClick={onClickDone} variant={"progressive"}>
            {getButtonText({ t, mode, isScheduled: showScheduleButton })}
          </Button>
        </SplitButtonDropdown>
      );
    } else {
      return (
        <React.Fragment>
          <Button variant="outlined" size="medium" onClick={onClickCancel}>
            {t("common:discard_label", {
              label: t("classRoom:post_settings"),
            })}
          </Button>
          <Button variant="progressive" size="medium" onClick={onClickDone}>
            {t("common:update")}
          </Button>
        </React.Fragment>
      );
    }
  }
};

const FooterV2 = React.memo(props => {
  const {
    isLast,
    isFirst,
    onClickNext,
    onClickPrevious,
    onClickCancel,
    onClickDone,
    isNextDisabled,
    mode,
    currentStep,
    courses,
    resourceSelection,
    t,
    onClickSchedule,
  } = props;

  const getScheduleText = () => {
    const scheduledCourses = _.filter(courses, course => {
      return !_.isEmpty(course.state.scheduleDate);
    });

    if (mode == "create") {
      let scheduleText = "";
      _.forEach(scheduledCourses, ({ title }, index) => {
        if (index == 0) scheduleText += title;
        else scheduleText += ` & ${title}`;
      });
      return t("common:scheduled_for_with_label", {
        label: scheduleText,
      });
    } else {
      return t("common:scheduled_for_with_label", {
        label: moment(_.first(scheduledCourses).state.scheduleDate).format(
          "Do, MMM YYYY"
        ),
      });
    }
  };

  const state = _.get(_.first(courses), "state");
  const isScheduled = _.get(state, "state") == "SCHEDULED";
  const scheduleDate = _.get(state, "scheduleDate");

  const showScheduleButton = getShowScheduleButton({
    currentStep,
    mode,
    isScheduled,
    scheduleDate,
  });

  const isEditMode = mode === "edit";

  const tooltipText = getToolTipText({
    courses,
  });

  const buttonContainerClass = classNames({
    [classes.buttonsContainer]: true,
    [classes.buttonsContainerScheduled]:
      showScheduleButton || (isEditMode && !isScheduled),
  });

  const innerContainerClass = classNames({
    [classes.innerContainer]: true,
    [classes.innerContainerEdit]: isEditMode,
    [classes.resourcesInnerContainer]: resourceSelection,
  });

  return (
    <div className={classes.container}>
      <div className={innerContainerClass}>
        {!isLast && !isEditMode && (
          <Button variant={"outlined"} size={"medium"} onClick={onClickCancel}>
            {resourceSelection ? t("common:cancel") : t("common:discard")}
          </Button>
        )}
        <div className={classes.rightButtonContainer}>
          {resourceSelection && !isFirst && (
            <div className={classes.cancelButtonText} onClick={onClickPrevious}>
              {t("common:previous")}
            </div>
          )}
          {!isLast && (
            <Button
              onClick={onClickNext}
              disabled={isNextDisabled}
              size={"medium"}
              variant={"progressive"}
            >
              {t("common:next")}
            </Button>
          )}
        </div>
        {isLast && (
          <div className={buttonContainerClass}>
            {showScheduleButton && (
              <ConditionalWrapper
                condition={mode === "create"}
                wrapper={<Tooltip placement={"top"} tooltip={tooltipText} />}
                type="wrapperAsElement"
              >
                <Button
                  icon={<ClockAlarmOutlined />}
                  variant={"plain"}
                  onClick={onClickSchedule}
                >
                  <div className={classes.scheduleButton}>
                    {getScheduleText()}
                  </div>
                </Button>
              </ConditionalWrapper>
            )}
            {getLastStepButtons({
              t,
              mode,
              isScheduled,
              onClickDone,
              onClickSchedule,
              onClickCancel,
              resourceSelection,
              showScheduleButton,
            })}
          </div>
        )}
      </div>
    </div>
  );
});

FooterV2.displayName = "FooterV2";

FooterV2.defaultProps = {
  isButtonDisabled: false,
};

export default I18nHOC({ resource: ["common"] })(FooterV2);
