import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";

export default store => ({
  path: "allsubmissions",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ClassRoom/modules/ClassRoomModule")
              .default;

            injectReducer(store, { key: "classRoom", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "course-classRoom"
        );
      })
  ),
  childRoutes: [],
});
