import { withAsyncRouteLoading } from "UIComponents";
import Entity from "./routes/CommunityEntity";
import { injectReducer } from "store/reducers";
export default store => {
  return {
    path: ":parentEntityType",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const reducer = require("CommunityParentEntity/modules/CommunityParentEntityModule")
                .default;
              injectReducer(store, { key: "communityParentEntity", reducer });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "teacher-community-featured"
          );
        })
    ),
    childRoutes: [Entity(store)],
  };
};
