import React from "react";
import classes from "./SearchFilter.scss";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import classNames from "classnames";

class SearchComponent extends React.PureComponent {
  setSelectedFilterValue = ({ isSelected, uid, value, subTopicLabel }) => {
    const { setSelectedFilterValue } = this.props;

    isSelected
      ? setSelectedFilterValue({})
      : setSelectedFilterValue({
          uid,
          value,
          subTopicLabel,
        });
  };

  topicRow = ({
    value,
    label,
    isDynamicPlannerElementLabel,
    hasSubtopics = false,
  }) => {
    const { t, selectedFilterValue } = this.props;
    const isSelected = selectedFilterValue?.value === value;

    const topicTextStyle = classNames(
      { [classes.topicText]: true },
      { [classes.textSelected]: isSelected }
    );

    return (
      <div key={value}>
        <div
          className={hasSubtopics ? classes.topicRowDisabled : classes.topicRow}
          onClick={() => {
            if (hasSubtopics) return;
            this.setSelectedFilterValue({ isSelected, uid: value, value });
          }}
        >
          <div className={topicTextStyle}>
            {isDynamicPlannerElementLabel ? label : t(label)}
          </div>
        </div>
        {!hasSubtopics && <div className={classes.divider} />}
      </div>
    );
  };

  renderNodeRow = (item, node) => {
    const { value } = item;
    const { id, label } = node;
    const { selectedFilterValue } = this.props;
    const isSelected =
      selectedFilterValue.uid === value && selectedFilterValue?.value === id;

    const subTopicTextStyle = classNames(
      { [classes.subTopicText]: true },
      { [classes.textSelected]: isSelected }
    );

    return (
      <div
        key={id}
        className={classes.topicRow}
        onClick={() => {
          this.setSelectedFilterValue({
            isSelected,
            uid: value,
            value: id,
            subTopicLabel: label,
          });
        }}
      >
        <div className={subTopicTextStyle}>{label}</div>
      </div>
    );
  };

  renderRows = () => {
    const { searchFilterData } = this.props;

    return _.map(searchFilterData, item => {
      if (item.isNestedView)
        return (
          <div key={item.id}>
            {this.topicRow({ ...item, hasSubtopics: true })}
            {_.map(item.rootNodes, node => {
              return this.renderNodeRow(item, node);
            })}
            {<div style={{ marginTop: "10px" }} className={classes.divider} />}
          </div>
        );
      else {
        return this.topicRow(item);
      }
    });
  };

  render() {
    const { t, setSelectedFilterValue, selectedFilterValue } = this.props;

    const topicTextStyle = classNames(
      { [classes.topicText]: true },
      { [classes.textSelected]: _.isEmpty(selectedFilterValue) }
    );

    return (
      <div className={classes.searchContainer}>
        <div className={classes.contentContainer}>
          <div
            className={classes.topicRow}
            onClick={() => {
              setSelectedFilterValue({});
            }}
          >
            <div className={topicTextStyle}>
              {t("journal:all_pyp_elements")}
            </div>
          </div>
          {<div className={classes.dividerDark} />}
          <div className={classes.scrollContainer}>{this.renderRows()}</div>
        </div>
      </div>
    );
  }
}

SearchComponent.displayName = "SearchComponent";

export default compose(I18nHOC({ resource: ["journal", "common"] }))(
  SearchComponent
);
