import React from "react";
import LabelChip from "../Chips/LabelChip";
import { getChipImage } from "../Utils";
import { compose } from "react-apollo";
import { I18nHOC } from "UIComponents";

const ModulesSection = props => {
  const { onSelectModule, t, data = [], cursor, onCursorFocus } = props;
  return (
    <div className="mt-6 px-10" role="list">
      <div className="text-label-3 mb-3">
        {t("integration:i_m_looking_for")}
      </div>
      <div className="flex flex-wrap gap-y-2">
        {data.map(module => {
          const { id, title, type } = module;
          const isSelected = id == cursor;
          return (
            <LabelChip
              key={id}
              id={id}
              icon={getChipImage({ type })}
              label={title}
              size="xxx-small"
              onClick={() => {
                onSelectModule({ type });
              }}
              onFocus={onCursorFocus}
              isSelected={isSelected}
            />
          );
        })}
      </div>
    </div>
  );
};

export default compose(
  I18nHOC({
    resource: ["common", "teacherHomePage", "integration"],
  })
)(ModulesSection);
