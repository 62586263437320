import { localSearch, getMemoizedFunction } from "Utils";
export const getValues = ({ valueList, key }) => {
  return _.get(
    _.find(valueList, ({ key: groupKey }) => key == groupKey),
    "values",
    []
  );
};

const getMatchingOptionList = ({ optionList, searchText }) => {
  const matchingOptionList = [];

  /**
   * Approach: If node corresponding to options is not a leaf node,
   * then call this util recursively. If we found leaf node then compute matching labels.
   * If node does not have matching options then ignore it.
   */
  _.forEach(optionList, ({ options, isLeafNode, ...rest }) => {
    if (isLeafNode) {
      const updatedOptions = _.filter(options, ({ label }) => {
        return localSearch({ text: label, searchText }) > -1;
      });

      if (!_.isEmpty(updatedOptions)) {
        matchingOptionList.push({
          ...rest,
          isLeafNode,
          options: updatedOptions,
        });
      }
    } else {
      const matchingSubGroupOptionList = getMatchingOptionList({
        optionList: options,
        searchText,
      });

      if (!_.isEmpty(matchingSubGroupOptionList)) {
        matchingOptionList.push({
          ...rest,
          isLeafNode,
          options: matchingSubGroupOptionList,
        });
      }
    }
  });

  return matchingOptionList;
};

export const getMatchingOptionListMemoized = getMemoizedFunction(
  getMatchingOptionList
);
