import gql from "graphql-tag";

export const upsertCourseAttendanceMutation = gql`
  mutation upsertCourseAttendance(
    $courseId: ID!
    $date: String!
    $studentAttendance: [StudentAttendanceInput!]
  ) {
    platform {
      upsertCourseAttendance(
        input: {
          courseId: $courseId
          date: $date
          studentAttendance: $studentAttendance
        }
      ) {
        id
        student {
          id
        }
        date
        status
      }
    }
  }
`;

export const upsertCourseAttendanceMutationV2 = gql`
  mutation upsertCourseAttendance(
    $courseId: ID!
    $date: String!
    $studentAttendance: [StudentAttendanceValueInput!]
    $shouldSendSMS: Boolean
    $periodId: ID
    $showDeletedRemark: Boolean
    $academicYearId: ID
    $curriculumProgramId: ID
  ) {
    platform {
      upsertCourseAttendanceV2(
        input: {
          courseId: $courseId
          date: $date
          studentAttendance: $studentAttendance
          shouldSendSMS: $shouldSendSMS
          periodId: $periodId
          showDeletedRemark: $showDeletedRemark
          academicYearId: $academicYearId
          curriculumProgramId: $curriculumProgramId
        }
      ) {
        id
        student {
          id
          __typename
        }
        date
        value {
          id
        }
        __typename
      }
      __typename
    }
  }
`;

export const updatePeriodSelectionMutation = gql`
  mutation upsertPeriodSelection($input: UpsertPeriodSelectionInput!) {
    platform {
      upsertPeriodSelection(input: $input) {
        warning
        response {
          id
          day
          rotationDay
          period {
            id
            label
          }
        }
      }
    }
  }
`;
