import gql from "graphql-tag";

export const gradeFragment = {
  globalGrade: gql`
    fragment globalGradeItem on GlobalGrade {
      id
      value: id
      uid
      label: name
    }
  `,
};
export const tagsOnBoardingFragment = {
  tag: gql`
    fragment tagInfo on Tag {
      id
      value: id
      label
      type
    }
  `,
};
export const workshopCategoryOnBoardingFragment = {
  workshopCategory: gql`
    fragment workshopCategoryItem on WorkshopCategory {
      id
      value: id
      label
      type
    }
  `,
};

export const communityActivityFragment = {
  communityActivity: gql`
    fragment communityActivityItem on CommunityActivity {
      id
      label
      type
      activityDate
      workshopCategory {
        ...workshopCategoryItem
      }
    }
    ${workshopCategoryOnBoardingFragment.workshopCategory}
  `,
};

export const schoolFragment = {
  schoolTenure: gql`
    fragment schoolTenureItem on SchoolTenure {
      id
      school {
        id
        name
        country
      }
      startDate
      endDate
      currentSchool
      city
      country
      designation {
        id
      }
    }
  `,
};

export const staffOnBoardingFragment = {
  staff: gql`
    fragment staffItem on Staff {
      id
      firstName
      lastName
      profileImage
      skills: tags(tagTypes: SKILL) {
        ...tagInfo
      }
      topics: tags(tagTypes: TOPIC) {
        ...tagInfo
      }
      interests: tags(tagTypes: INTEREST) {
        ...tagInfo
      }
      communityDetails {
        id
        personalEmail
        aboutMe
        experience
        numberOfworkshops
        currentSchoolDuration
        city
        country
        onboardingStatus
        banner
        facebook
        twitter
        linkedin
      }
      currentSchools: schoolTenures(currentSchool: true) {
        ...schoolTenureItem
      }
      pastSchools: schoolTenures(currentSchool: false) {
        ...schoolTenureItem
      }
      workshops: communityActivities(types: WORKSHOP) {
        ...communityActivityItem
      }
      trainings: communityActivities(types: TRAINING) {
        ...communityActivityItem
      }
      awards: communityActivities(types: AWARD) {
        ...communityActivityItem
      }
      grades: communityGlobalGrades {
        ...globalGradeItem
      }
    }
    ${tagsOnBoardingFragment.tag}
    ${schoolFragment.schoolTenure}
    ${communityActivityFragment.communityActivity}
    ${gradeFragment.globalGrade}
  `,
};
