import React from "react";
import classes from "./PermissionDropDown.scss";
import { ButtonDropdown } from "UIComponents";
import { colors } from "Constants";
import { ArrowIcon } from "SvgComponents";
import PropTypes from "prop-types";

const buttonDropDownStyle = {
  containerStyle: {
    top: 48,
    width: 160,
    right: 0,
  },
  itemStyle: {
    height: 40,
  },
  textStyle: {
    fontSize: 14,
  },
};

class PermissionDropDown extends React.PureComponent {
  render() {
    const {
      isButtonDisabled,
      menuItems,
      onActionClick,
      permissionLabel,
      buttonContainerStyle,
    } = this.props;

    return (
      <div className={classes.container}>
        <ButtonDropdown
          isButtonDisabled={isButtonDisabled}
          authTabs={menuItems}
          onItemClick={onActionClick}
          containerStyle={buttonDropDownStyle.containerStyle}
          itemStyle={buttonDropDownStyle.itemStyle}
          textStyle={buttonDropDownStyle.textStyle}
          buttonComponent={
            <div className={classes.selectedValue} style={buttonContainerStyle}>
              <div className={classes.selectedText}>{permissionLabel}</div>
              <div className={classes.arrowContainer}>
                <ArrowIcon fill={colors.gray48} width={6} height={4} />
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

PermissionDropDown.defaultProps = {
  buttonContainerStyle: {},
};

PermissionDropDown.propTypes = {
  buttonContainerStyle: PropTypes.object,
};

export default PermissionDropDown;
