import { getConversationsQuery } from "./ChatContainerQuery";
import client from "apolloClient";

import { conversationsFragment } from "./ChatContainerFragments";

export const getConversationsFromCache = params => {
  let queryData = {};
  try {
    queryData = client.readQuery(
      {
        query: getConversationsQuery,
        variables: params,
      },
      true
    );
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeConversationsFragment = ({
  id,
  data,
  first,
  filters,
  after,
}) => {
  try {
    client.writeFragment({
      id: `Conversation:${id}`,
      fragment: conversationsFragment.messages,
      fragmentName: "messages",
      data: data,
      variables: { first, filters, after },
    });
  } catch (e) {
    console.error(e);
  }
};

export const getConversationBasicDetailsFromCache = ({ conversationId }) => {
  let fragmentData;
  try {
    fragmentData = client.readFragment({
      id: `Conversation:${conversationId}`,
      fragment: conversationsFragment.basicDetails,
      fragmentName: "conversationBasicDetails",
    });
  } catch (e) {
    return {};
  }
  return fragmentData;
};
