import React from "react";
import classes from "./OrganizationSelection.scss";
import OrganizationSelector from "./OrganizationSelector";
import { UIButton } from "UIComponents";
import { WEBSITE_URL } from "Constants";
import { isMobile } from "Utils";
import { InfoTextIcon } from "SvgComponents";

class OrganizationSelection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRefs = {};
  }

  UNSAFE_componentWillMount = () => {
    this.props.resetScreen("organizationSelection");
  };
  componentDidMount = () => {};

  onNextClick = () => {
    const {
      updateCurrentScreen,
      resetScreen,
      onNextClick,
      showErrorToast,
    } = this.props;
    const errorCount = this.isValid();
    showErrorToast({ errorCount });
    if (errorCount > 0) {
      return;
    }

    onNextClick();

    resetScreen("createAccount");
    resetScreen("addPYPDetails");
    resetScreen("addIdentification");
    updateCurrentScreen({ type: "createAccount" });
  };

  onSignInClick = () => {
    const { updateCurrentScreen } = this.props;
    // Comment it as of now
    // updateCurrentScreen({ type: "teacherLogin" });
    updateCurrentScreen({ type: "teacherLogin" });
  };

  onAddSchoolClick = () => {
    const { updateCurrentScreen } = this.props;
    updateCurrentScreen({ type: "addSchool" });
  };

  isValid = () => {
    const refKeys = Object.keys(this.inputRefs);
    let errorCount = 0;
    _.forEach(refKeys, key => {
      if (this.inputRefs[key]) {
        errorCount += this.inputRefs[key].isValid();
      }
    });

    return errorCount;
  };

  onSearchBoxFocus = () => {
    const { goToScrollTopOffset } = this.props;
    if (goToScrollTopOffset) {
      setTimeout(
        () => goToScrollTopOffset({ element: this.updateInputContainerRef }),
        100
      );
    }
  };

  render() {
    const {
      inputStyle,
      updateInputField,
      data: { selectedSchool } = {},
      customStyle: { containerStyle, headerStyle, buttonStyle } = {},
      renderErrorComponent,
      renderBackButtonComponent,
    } = this.props;

    const mobileStyles = isMobile()
      ? {
          labelContainerStyle: {
            marginBottom: "16px",
          },
        }
      : {};

    return (
      <div className={classes.container} style={containerStyle}>
        <div className={classes.headerButtonContainer}>
          {renderBackButtonComponent({
            style: { marginBottom: "0" },
            onClick: this.onSignInClick,
            buttonText: "Teacher account",
          })}
        </div>
        <div style={headerStyle}>{`Let's get you started!`}</div>
        <div className={classes.subTextStyle}>
          {`Access the ultimate library of PYP resources and collaborate with your team to create your own units.`}
        </div>

        <div className={classes.infoTextBox}>
          <div>
            Please note signing up for a free account on Toddle is currently
            available only for IB PYP schools.
          </div>
          <br />
          <div>
            To get early access for MYP, request a demo{" "}
            <a
              href={`${WEBSITE_URL}/?show_request_modal=true`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.underLineButton}
            >
              {`here`}
            </a>
            <span>{`.`}</span>
          </div>
          <div className={classes.yellowDot}>
            <InfoTextIcon />
          </div>
        </div>

        <div className={classes.innerContainer}>
          <div className={classes.inputsContainer}>
            <div
              className={classes.inputContainer}
              ref={ref => (this.updateInputContainerRef = ref)}
            >
              <OrganizationSelector
                label={`Find your school to start creating your account`}
                {...inputStyle}
                {...mobileStyles}
                name={"selectedSchool"}
                updateInputField={updateInputField}
                value={selectedSchool}
                customRef={ref => (this.inputRefs["selectedSchool"] = ref)}
                showErrorText={true}
                renderErrorComponent={renderErrorComponent}
                error={"Select a valid school name"}
                onAddSchoolClick={this.onAddSchoolClick}
                onSearchBoxFocus={this.onSearchBoxFocus}
              />
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <UIButton
              type="filled"
              color="pink"
              onClick={this.onNextClick}
              containerStyle={buttonStyle}
            >
              {"Next"}
            </UIButton>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationSelection;
