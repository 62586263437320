import gql from "graphql-tag";
import {
  deadlineFragments,
  projectFragments,
  serviceAsActionFragments,
  insightFragments,
  guidingQuestionsFragment,
  studentProjectPortfolioFragment,
  studentProjectPortfolioFragments,
  studentPortfolioProgressFragment,
  projectFieldsFragment,
  projectGroupFragment,
  studentFragments,
} from "./ProjectFragments";
import {
  attachmentFragment,
  userFragment,
  yearGroupFragments,
  projectGroupFragments,
  remarkFragment,
  pypElementRatingFragment,
} from "modules/CommonFragments";
import { plannerFieldFragment } from "UnitPlans/modules/UnitPlanFragments";

export const getProjectGroupGuidanceStatusQuery = gql`
  query getProjectGroupGuidanceStatus($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        showStudentGuidance
        showSupervisorGuidance
      }
    }
  }
`;

export const getProjectGroupDeadlinesQuery = gql`
  query getProjectGroupDetails(
    $projectGroupId: ID!
    $fetchStatusWiseProjects: Boolean!
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        deadlines {
          ...projectDeadline
        }
      }
    }
  }
  ${deadlineFragments.deadlineFragmentWithStatusCount}
`;

export const getProjectGroupSelectedReportTemplateQuery = gql`
  query getProjectGroupSelectedReportTemplateQuery($projectGroupId: ID!) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        selectedProjectReportTemplate {
          ...attachmentItem
          presetAttachment {
            ...attachmentItem
          }
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;
export const getProjectGroupProjectCountQuery = gql`
  query getProjectGroupProjectCount(
    $id: ID!
    $filters: ProjectGroupProjectsFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        projects(filters: $filters) {
          totalCount
        }
      }
    }
  }
`;

export const getProjectGroupProjectsQuery = gql`
  query getProjectGroupProjects(
    $id: ID!
    $filters: ProjectGroupProjectsFilters
    $studentCourseFilters: UserCourseMapFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        projects(filters: $filters) {
          edges {
            node {
              ...projectStudentInfo
            }
          }
        }
      }
    }
  }
  ${projectFragments.projectStudentInfoFragment}
`;

export const getStudentsBulkDownloadReportsQuery = gql`
  query getProjectGroupProjectsSubmissionsAndFields(
    $id: ID!
    $filters: ProjectGroupProjectsFilters
    $fieldFilters: PlannerFieldFilter
    $shouldFetchFields: Boolean!
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        projects(filters: $filters) {
          edges {
            node {
              id
              students {
                ...studentInfo
              }
              finalProjectReports {
                id
              }
              fields(filters: $fieldFilters) @include(if: $shouldFetchFields) {
                id
                uid
                value
              }
            }
          }
        }
      }
    }
  }
  ${studentFragments.studentInfoFragment}
`;

export const getProjectAssessmentBulkDownloadQuery = gql`
  query getProjectAssessmentBulkDownload($id: ID!, $projectIds: [ID!]) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        assessmentCsvExport(filters: { projectIds: $projectIds })
      }
    }
  }
`;

export const getProjectDetailsQuery = gql`
  query getProjectDetailsQuery($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        type
        isCompleted
        students {
          ...userItem
        }
        supervisors {
          ...userItem
        }
        grades {
          id
          name
        }
        template {
          id
          name
          body
          type
          helpEnabledFields {
            id
            uid
            type
            helpText {
              id
              title
              content
              subtitle
            }
          }
        }
        fields {
          ...projectFieldItem
        }
      }
    }
  }
  ${plannerFieldFragment.projectFieldItem}
  ${userFragment.basicUser}
`;

export const getProjectConversationDetailsQuery = gql`
  query getProjectConversationDetails($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        conversation {
          id
        }
      }
    }
  }
`;

export const getProjectReportsQuery = gql`
  query getProjectReports($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        projectReports {
          ...attachmentItem
          presetAttachment {
            ...attachmentItem
          }
        }
        finalProjectReports {
          ...attachmentItem
          presetAttachment {
            ...attachmentItem
          }
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const getProjectFinalReportQuery = gql`
  query getProjectFinalReport($id: ID!) {
    node(id: $id, type: PROJECT) {
      id
      ... on Project {
        id
        finalProjectReports {
          id
        }
      }
    }
  }
`;

export const getProjectInfoQuery = gql`
  query getProjectInfo($projectId: ID!) {
    node(id: $projectId, type: PROJECT) {
      ... on Project {
        ...projectInfoItem
      }
    }
  }

  ${projectFragments.projectInfoFragment}
`;

export const getProjectsCountQuery = gql`
  query getProjectsCount(
    $id: ID!
    $projectGroupIds: [ID!]
    $type: ENTITY_TYPE_ENUM!
  ) {
    node(id: $id, type: $type) {
      ... on Staff {
        id
        projects(filters: { projectGroupIds: $projectGroupIds }) {
          id
          projectGroup {
            type
          }
          __typename
        }
        __typename
      }
      ... on Student {
        id
        projects(filters: { projectGroupIds: $projectGroupIds }) {
          id
          projectGroup {
            type
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getMultiNodeProjectCountsQuery = gql`
  query getMultiNodeProjectCounts($ids: [ID!]) {
    multiNode(ids: $ids, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        projects {
          totalCount
        }
        isInvited
      }
    }
  }
`;

export const getGuidingQuestionsQuery = gql`
  query getGuidingQuestions(
    $projectGroupId: ID!
    $filters: GuidingQuestionFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        guidingQuestions(filters: $filters) {
          ...guidingQuestions
        }
      }
    }
  }
  ${guidingQuestionsFragment.guidingQuestions}
`;

export const getGradeWiseProjectCountQuery = gql`
  query getGradeWiseProjectCount(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: GradeProjectFilters
    $allGradesFilters: AllGradesFilters
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        allGrades(filters: $allGradesFilters) {
          ...gradeWiseProjectCount
        }
      }
      ... on Student {
        id
        allGrades(filters: $allGradesFilters) {
          ...gradeWiseProjectCount
        }
      }
    }
  }
  ${serviceAsActionFragments.gradeWiseProjectCount}
`;

export const getPlannerElementInsightSpecificsQuery = gql`
  query getPlannerElementInsightSpecifics(
    $projectGroupId: ID!
    $gradeFilters: PlannerElementInsightSpecificsFilter
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        plannerElementInsightSpecifics(filters: $gradeFilters) {
          ...plannerElementInsightSpecificItem
        }
      }
    }
  }
  ${insightFragments.plannerElementInsightSpecific}
`;

export const getProjectsWithEvidenceInsightsQuery = gql`
  query getProjectsWithEvidenceInsights(
    $ids: [ID!]
    $filters: PlannerFieldFilter
    $evidenceFilters: ProjectEvidenceInsightFilters
  ) {
    multiNode(ids: $ids, type: PROJECT) {
      ... on Project {
        id
        title {
          id
          value
        }
        fields(filters: $filters) {
          id
          uid
          value
        }
        evidenceInsights(filters: $evidenceFilters) {
          evidence {
            edges {
              node {
                id
              }
            }
            totalCount
          }
        }
      }
    }
  }
`;

export const getPostsQuery = gql`
  query getPostsQuery($ids: [ID!]) {
    multiNode(ids: $ids, type: POST) {
      ... on Post {
        id
        title
        attachments {
          ...attachmentItem
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const getProjectGroupSupervisorsQuery = gql`
  query getProjectGroupSupervisors(
    $projectGroupId: ID!
    $filters: ProjectGroupUserFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        allSupervisors(filters: $filters) {
          totalCount
          edges {
            node {
              ...userItem
            }
          }
        }
      }
    }
  }

  ${userFragment.basicUser}
`;

export const getProjectGroupStudentsQuery = gql`
  query getProjectGroupStudents(
    $projectGroupId: ID!
    $filters: ProjectGroupUserFilters
  ) {
    node(id: $projectGroupId, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        allStudents(filters: $filters) {
          totalCount
          edges {
            node {
              ...userItem
            }
          }
        }
      }
    }
  }

  ${userFragment.basicUser}
`;

export const getProjectGroupStudentProjectPortfoliosCountQuery = gql`
  query getProjectGroupStudentProjectPortfoliosCount($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        studentProjectPortfolio {
          totalCount
        }
      }
    }
  }
`;

export const getStudentProjectPortfolioDetailsQuery = gql`
  query getStudentProjectPortfolioDetails($id: ID!) {
    node(id: $id, type: STUDENT_PROJECT_PORTFOLIO) {
      ... on StudentProjectPortfolio {
        id
        student {
          ...userItem
        }
        staff {
          ...userItem
        }
        template {
          id
          body
          helpEnabledFields {
            id
            uid
            type
            helpText {
              id
              title
              content
              subtitle
            }
          }
        }
        fields {
          ...projectFieldItem
        }
      }
    }
  }
  ${plannerFieldFragment.projectFieldItem}
  ${userFragment.basicUser}
`;

export const getStudentProjectPortfolioInterviewsQuery = gql`
  query getStudentProjectPortfolioInterviews($id: ID!) {
    node(id: $id, type: STUDENT_PROJECT_PORTFOLIO) {
      id
      ... on StudentProjectPortfolio {
        ...studentProjectPortfolioInterviewsItem
      }
    }
  }
  ${studentProjectPortfolioFragments.studentProjectPortfolioInterviewsFragment}
`;

export const getStudentPortfolioProjectsQuery = gql`
  query getStudentProjectPortfolioProjects(
    $id: ID!
    $projectFilters: ProjectFilter
    $filters: PlannerFieldFilter
  ) {
    node(id: $id, type: STUDENT_PROJECT_PORTFOLIO) {
      ... on StudentProjectPortfolio {
        id
        projects(filters: $projectFilters) {
          edges {
            node {
              type
              allPostsCount
              ...projectFieldsDetails
            }
          }
        }
      }
    }
  }
  ${projectFieldsFragment.projectFieldsDetails}
`;

export const getStudentPortfolioDetailsQuery = gql`
  query getStudentPortfolioDetails(
    $id: ID!
    $filters: StudentProjectPortfolioFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        studentProjectPortfolio(filters: $filters) {
          edges {
            node {
              ...portfolioProgress
              student {
                ...userItem
              }
              staff {
                ...userItem
              }
              projectCounts {
                projectsCount
                experiencesCount
              }
              fields{
                comments{
                  unreadCountV2
                }
              }
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser},
  ${studentPortfolioProgressFragment.studentPortfolioProgress}
`;

export const getLearningOutcomesWithEvidencesQuery = gql`
  query getLearningOutcomesWithEvidences($id: ID!) {
    node(id: $id, type: STUDENT_PROJECT_PORTFOLIO) {
      ... on StudentProjectPortfolio {
        id
        plannerElementEvidences(
          filters: { plannerElement: { type: DP_CAS_LEARNING_OUTCOMES } }
        ) {
          ...studentProjectPortfolioEvidences
        }
      }
    }
  }
  ${studentProjectPortfolioFragment.studentProjectPortfolioEvidences}
`;

export const getProjectGroupProjectsWithFieldsQuery = gql`
  query getProjectGroupProjectsWithFields(
    $id: ID!
    $projectFilters: ProjectGroupProjectsFilters
    $projectFieldFilters: PlannerFieldFilter
    $studentCourseFilters: UserCourseMapFilters
    $isServiceAsAction: Boolean!
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        type
        deadlines @skip(if: $isServiceAsAction) {
          ...deadline
        }
        projects(filters: $projectFilters) {
          totalCount
          edges {
            node {
              ...projectInfoProgress
              fields(filters: $projectFieldFilters) {
                ...fieldsResolvedMinimalTreeFragmentItem
              }
            }
          }
        }
      }
    }
  }
  ${projectFragments.projectInfoProgressFragment}
  ${plannerFieldFragment.fieldsResolvedMinimalTreeFragment}
  ${deadlineFragments.deadlineFragment}
`;

export const getProjectGroupPortfolioWithStudentQuery = gql`
  query getProjectGroupPortfolioWithStudent($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        studentProjectPortfolio {
          edges {
            node {
              id
              student {
                ...userItem
              }
            }
          }
        }
      }
    }
  }
  ${userFragment.basicUser}
`;

export const getStudentProjectPortfolioQuery = gql`
  query getStudentProjectPortfolio(
    $id: ID!
    $filters: PlannerFieldFilter
    $projectFilters: ProjectFilter
    $experienceFilters: ProjectFilter
    $postFilters: PostFilterInput
  ) {
    node(id: $id, type: STUDENT_PROJECT_PORTFOLIO) {
      ... on StudentProjectPortfolio {
        ...studentProjectPortfolioDetailsItem
      }
    }
  }
  ${studentProjectPortfolioFragments.studentProjectPortfolioDetailsFragment}
`;

export const getProjectGroupStudentPortfolioIdQuery = gql`
  query getProjectGroupStudentProjectPortfolio($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      id
      ... on ProjectGroup {
        id
        studentProjectPortfolio {
          totalCount
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const getProjectProjectReportsUnreadCountQuery = gql`
  query getProjectGroupStudentProjectPortfolio($id: ID!) {
    node(id: $id, type: PROJECT) {
      ... on Project {
        id
        projectReports {
          id
          isRead
        }
      }
    }
  }
`;

export const getUserProjectGroupsQuery = gql`
  query getUserProjectGroups(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UserProjectGroupFilter
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        projectGroups(filters: $filters) {
          ...basicProjectGroupDetails
        }
      }
      ... on Student {
        id
        projectGroups(filters: $filters) {
          ...basicProjectGroupDetails
        }
      }
    }
  }
  ${projectGroupFragments.basicProjectGroupDetails}
`;

export const getProjectGroupYearGroupQuery = gql`
  query getProjectGroupYearGroup($id: ID!) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        ...basicProjectGroupDetails
        yearGroup {
          id
          name
        }
      }
    }
  }
  ${projectGroupFragments.basicProjectGroupDetails}
`;

export const getUserProjectGroupYearGroupsQuery = gql`
  query getUserProjectGroupYearGroups(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UserProjectGroupFilter
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        projectGroups(filters: $filters) {
          id
          type
          yearGroup {
            ...basicYearGroupDetails
          }
          projects {
            totalCount
          }
          studentProjectPortfolio {
            totalCount
          }
        }
      }

      ... on Student {
        id
        projectGroups(filters: $filters) {
          id
          type
          yearGroup {
            ...basicYearGroupDetails
          }
          projects {
            totalCount
          }
          studentProjectPortfolio {
            totalCount
          }
        }
      }
    }
  }
  ${yearGroupFragments.basicYearGroupDetails}
`;

export const getUserProjectGroupsTotalCountQuery = gql`
  query getUserProjectGroupsTotalCount(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: UserProjectGroupFilter
  ) {
    node(id: $id, type: $type) {
      id
      ... on Staff {
        id
        projectGroups(filters: $filters) {
          id
        }
      }
      ... on Student {
        id
        projectGroups(filters: $filters) {
          id
        }
      }
    }
  }
`;

export const getProjectGroupAssessmentToolDetailsQuery = gql`
  query getProjectGroupAssessmentToolDetails(
    $id: ID!
    $assessmentToolFilters: AssessmentToolFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        ...projectGroupWithAssessmentToolsItem
      }
    }
  }
  ${projectGroupFragment.projectGroupWithAssessmentTools}
`;

export const getProjectCriteriaDetailsQuery = gql`
  query getProjectCriteriaDetails(
    $id: ID!
    $studentCourseFilters: UserCourseMapFilters
  ) {
    node(id: $id, type: PROJECT) {
      ... on Project {
        id
        students {
          id
          courseMap(filters: $studentCourseFilters) {
            id
            tags {
              id
              type
            }
          }
        }
        assessmentRemarks {
          ...remarkMessage
        }
        ibPYPElementRatings {
          ...basicIBPYPElementRatingItem
        }
      }
    }
  }
  ${pypElementRatingFragment.basicIBPYPElementRating}
  ${remarkFragment.remarkMessageFragment}
`;

export const getProjectGroupDetailsQuery = gql`
  query getProjectGroupDetails(
    $id: ID!
    $assessmentToolFilters: AssessmentToolFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        overview
        ...projectGroupWithAssessmentToolsItem
      }
    }
  }
  ${projectGroupFragment.projectGroupWithAssessmentTools}
`;

export const getProjectGroupSetupGuidanceDetailsQuery = gql`
  query getProjectGroupSetupGuidanceDetails(
    $id: ID!
    $filters: SetupGuidanceFilters
  ) {
    node(id: $id, type: PROJECT_GROUP) {
      ... on ProjectGroup {
        id
        setupGuidance(filters: $filters) {
          ...attachmentItem
        }
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const getStudentTagDetailsQuery = gql`
  query getStudentTagDetails(
    $id: ID!
    $studentCourseFilters: UserCourseMapFilters
  ) {
    node(id: $id, type: STUDENT) {
      ... on Student {
        ...studentInfo
      }
    }
  }
  ${studentFragments.studentInfoFragmentV2}
`;
