import gql from "graphql-tag";
export const applicableFiltersFragment = gql`
  fragment applicableFilters on GlobalConstants {
    moduleFacets(module: $module) {
      label
      levels
      options
      attributes
      entityType
    }
  }
`;

export const filterFragments = {
  folderFragment: gql`
    fragment folderItem on Folder {
      id
      folderName: name
    }
  `,
  atlFragment: gql`
    fragment atlItem on PlannerATL {
      id
      atlLabel: label
    }
  `,
  lpaFragment: gql`
    fragment lpaItem on PlannerLearnerProfile {
      id
      lpaLabel: label
    }
  `,
  conceptFragment: gql`
    fragment conceptItem on PlannerConcept {
      id
      conceptLabel: label
    }
  `,
  academicYearFragment: gql`
    fragment academicYearItem on AcademicYear {
      id
      startDate
      endDate
    }
  `,
  reportSetFragment: gql`
    fragment reportSetItem on ProgressReportTaskGroup {
      id
      title
    }
  `,
  themeFragment: gql`
    fragment themeItem on Theme {
      id
      themeLabel: label
      uid
    }
  `,
  unitFragment: gql`
    fragment unitItem on UnitPlan {
      id
      unitPlanTitle: title {
        id
        value
      }
    }
  `,
  subjectFragment: gql`
    fragment subjectItem on Subject {
      id
      subjectName: name
    }
  `,
  courseFragment: gql`
    fragment courseItem on Course {
      id
      title
      subjects {
        id
        name
      }
    }
  `,
  activityFragment: gql`
    fragment activityItem on ActivityTypeFacet {
      id
      activityLabel: label
    }
  `,
  unitPlanTypeFragment: gql`
    fragment unitPlanTypeItem on UnitPlanTypeFacet {
      id
      unitPlanTypeLabel: label
    }
  `,
  themeTypeFragment: gql`
    fragment themeTypeItem on Theme {
      id
      themeTypeLabel: label
    }
  `,
  gradeFragment: gql`
    fragment gradeItem on Grade {
      id
      gradeName: name
    }
  `,
  subjectGroupFragment: gql`
    fragment subjectGroupItem on SubjectGroup {
      id
      subjectGroupName: name
    }
  `,
  plannerElementFragment: gql`
    fragment plannerElementItem on PlannerElementSet {
      id
      nodes(filters: $filters) {
        id
        label
        children
        depth
        parent
        associatedParents {
          id
        }
      }
    }
  `,
};
