import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import update from "immutability-helper";
import classes from "./UIInputList.scss";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import InputItem from "./InputItem";
import { generateRandomId, moveElementInArray } from "Utils";
import { I18nHOC, EmptyField } from "UIComponents";
import { AddIcon } from "SvgComponents";
import { colors } from "Constants";
import { TickPlain } from "SvgComponents";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropEvent } from "Constants";
import { IconButton } from "@toddle-design/web";
import {
  DragDotsOutlined,
  DeleteOutlined,
  PencilLineOutlined,
} from "@toddle-design/web-icons";
class UIInputList extends UIBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      currentFocusIndex: null,
      updateMaxRowsId: false,
      editingItem: null,
    };
    this.inputListItems = {};
    this.focusedCount = 0;
  }

  updateFocusedCount = ({ value, index }) => {
    const updatedFocusedCount = this.focusedCount + value;

    if (this.focusedCount == 0 && updatedFocusedCount != 0) {
      this.onFocus();
    } else if (updatedFocusedCount == 0 && this.focusedCount != 0) {
      this.onBlur();
    }
    this.focusedCount = updatedFocusedCount;
    if (value == 1) {
      setTimeout(() => {
        this.setState({ currentFocusIndex: index });
      });
    } else {
      this.setState({ currentFocusIndex: null });
    }
  };

  blur = () => {
    _.map(this.inputListItems, itemRef => {
      if (itemRef) {
        itemRef.blur();
      }
    });
  };

  isValidValue = value => {
    return value && value.length > 0;
  };

  emptyObj = () => {
    return {
      id: generateRandomId(),
      value: "",
      isAchieved: false,
      isSelected: true,
    };
  };

  componentDidMount = () => {
    const { value, options } = this.props;

    if (value.length == 0) {
      this.handleInit();
    }
    //Listen onDrag event
    DragDropEvent.on("onDragEnd", this.onDragEnd);
  };

  componentWillUnmount = () => {
    DragDropEvent.off("onDragEnd", this.onDragEnd);
  };

  shouldShowEditEmpty = () => {
    const { options, shouldInitOptions } = this.props;
    if (shouldInitOptions) {
      return _.get(options, "length", 0) == 0;
    } else {
      return false;
    }
  };

  renderEditEmpty = () => {
    const { emptyText, emptyEditText } = this.props;
    return <EmptyField title={emptyEditText || emptyText} />;
  };

  handleInit = () => {
    const { options, shouldInitOptions } = this.props;

    if (shouldInitOptions) {
      this._updateValue(
        _.map(options, option => {
          return { id: option.value, value: option.label, isSelected: false };
        })
      );
    } else {
      this.handleEmptyValue();
    }
  };

  handleEmptyValue = () => {
    this.updateListValue(
      {
        text: "",
        index: 0,
        action: "ADD",
      },
      false
    );
  };

  componentDidUpdate = prevProps => {
    if (
      _.get(this.props.value, "length", 0) == 0 ||
      !_.isEqualWith(prevProps.options, this.props.options, "id")
    ) {
      this.handleInit();
    }
  };

  updateCurrentFocusIndex = val => {
    this.setState({ currentFocusIndex: val });

    setTimeout(() => {
      if (this.inputListItems[val]) {
        this.inputListItems[val].focus();
      }
    });
  };

  getPreviousIndex = index => {
    let previousIndex = index;
    if (index == 0) {
      previousIndex = 0;
    } else {
      previousIndex = index - 1;
    }
    return previousIndex;
  };

  onItemBlur = () => {
    const { value } = this.props;
    const updatedFocusIndex = _.get(value, "length", 0) - 1;
    this.updateCurrentFocusIndex(updatedFocusIndex);
    this.setState({ editingItem: null });
    this.updateListValue({ isComplete: true });
  };

  _updateValue = updatedArray => {
    this.updateValue(updatedArray);
  };

  updateListValue = (
    { text, index, action, isComplete, isAchieved, isSelected },
    shouldFocus = true
  ) => {
    const { value, thinInteractions } = this.props;

    let updatedArray = _.cloneDeep(value);
    let updatedFocusIndex = -1;

    if (isComplete) {
      this._updateValue(updatedArray);
    } else {
      if (action == "EDIT") {
        updatedArray = update(updatedArray, {
          [index]: { value: { $set: text } },
        });
      } else if (action == "ADD") {
        const obj = this.emptyObj();
        updatedArray = update(updatedArray, { $splice: [[index, 0, obj]] });
        updatedFocusIndex = index;
      } else if (action == "REMOVE") {
        if (value.length > 1) {
          updatedArray = update(updatedArray, { $splice: [[index, 1]] });
          updatedFocusIndex = this.getPreviousIndex(index);
        }
      } else if (action === "ISACHIEVED") {
        updatedArray = update(updatedArray, {
          [index]: { isAchieved: { $set: !isAchieved } },
        });
      } else if (action === "SELECTION") {
        updatedArray = update(updatedArray, {
          [index]: { isSelected: { $set: isSelected } },
        });
      }
      this._updateValue(updatedArray);
      this.setState({
        editingItem:
          action == "REMOVE" && !thinInteractions
            ? null
            : this.state.editingItem,
      });
    }

    if (updatedFocusIndex >= 0 && shouldFocus) {
      this.updateCurrentFocusIndex(updatedFocusIndex);
    }
  };

  canAdd = () => {
    const { value } = this.props;
    return (
      !value ||
      value.length == 0 ||
      (value.length > 0 && value[value.length - 1].value)
    );
  };

  onAddClick = () => {
    const { value } = this.props;

    if (this.canAdd()) {
      this.updateListValue({
        text: "",
        index: value.length,
        action: "ADD",
      });
    }
  };

  onItemEditClicked = ({ id, index }) => {
    this.setState({ editingItem: id });
    if (index >= 0) {
      this.updateCurrentFocusIndex(index);
    }
  };

  updateRef = (index, ref) => {
    this.inputListItems[index] = ref;
  };

  onDragEnd = params => {
    this.setState({ updateMaxRowsId: false });
    const { value, fieldKey } = this.props;
    const droppableId = _.get(params, "destination.droppableId", "");
    const draggableId = _.split(_.get(params, "draggableId", ""), ":");
    const srcDroppableId = _.get(params, "source.droppableId", "");
    if (
      srcDroppableId === `UI_INPUT_LIST:${fieldKey}` &&
      droppableId === `UI_INPUT_LIST:${fieldKey}` &&
      draggableId[0] === "UI_INPUT_ITEM"
    ) {
      const updatedArray = moveElementInArray({
        array: value,
        fromIndex: _.get(params, "source.index", 0),
        toIndex: _.get(params, "destination.index", 0),
      });

      this._updateValue(updatedArray);
    }
  };

  renderItems = () => {
    const { currentFocusIndex, updateMaxRowsId } = this.state;
    const {
      value,
      t,
      isAchievedOption,
      mode,
      showListItemNumber,
      listItemSvg,
      thinInteractions,
      shouldCreateOnEnter,
      hasSelection,
      isDNDDisabled,
    } = this.props;

    const lengthOfList = _.get(value, "length", 0);
    return _.map(value, (item, index) => {
      const isAchieved = item.isAchieved;

      const key = item.id;

      const tickIconClass = classNames(
        { [classes.tickIcon]: true },
        { [classes.selectedTickIcon]: isAchieved }
      );

      const dragSvgIconStyle = classNames(
        { [classes.dragSvgIcon]: true },
        { [classes.noDrag]: isDNDDisabled }
      );

      return (
        <Draggable draggableId={`UI_INPUT_ITEM:${key}`} index={index} key={key}>
          {(provided, snapshot) => {
            return (
              <div
                key={key}
                {...provided.draggableProps}
                ref={provided.innerRef}
                style={{ ...provided.draggableProps.style }}
                className={classes.inputListItemCon}
              >
                <div className={dragSvgIconStyle} {...provided.dragHandleProps}>
                  <div
                    onMouseDown={() =>
                      this.setState({ updateMaxRowsId: item.id })
                    }
                    onMouseUp={() => this.setState({ updateMaxRowsId: false })}
                  >
                    <DragDotsOutlined size={"xxx-small"} variant={"subtle"} />
                  </div>
                </div>
                <div
                  className={classes.inputItemCon}
                  style={{
                    width:
                      isAchievedOption && lengthOfList > 1 && mode !== "view"
                        ? "80%"
                        : isAchievedOption ||
                          (lengthOfList > 1 && mode !== "view")
                        ? "90%"
                        : "100%",
                    backgroundColor: "rgba(255,255,255,0.6)",
                  }}
                >
                  <InputItem
                    value={item.value}
                    index={index}
                    id={item.id}
                    onBlur={() =>
                      setTimeout(() =>
                        this.updateFocusedCount({ value: -1, index })
                      )
                    }
                    onFocus={() => this.updateFocusedCount({ value: 1, index })}
                    isLast={index + 1 === lengthOfList}
                    updateListValue={this.updateListValue}
                    placeholder={t("common:typeHere")}
                    ref={ref => this.updateRef(index, ref)}
                    listItemSvg={listItemSvg}
                    showListItemNumber={showListItemNumber}
                    onEditClicked={this.onItemEditClicked}
                    isEditing={item.id === this.state.editingItem}
                    isSelected={item.isSelected}
                    onItemBlur={this.onItemBlur}
                    thinInteractions={thinInteractions}
                    shouldCreateOnEnter={shouldCreateOnEnter}
                    mode={mode}
                    hasSelection={hasSelection}
                    isCurrentFocused={index === currentFocusIndex}
                    shouldUpdateMaxRows={
                      updateMaxRowsId ? updateMaxRowsId == item.id : false
                    }
                  />
                </div>
                {isAchievedOption && (
                  <div
                    className={tickIconClass}
                    onClick={() => {
                      this.updateListValue({
                        index,
                        action: "ISACHIEVED",
                        isAchieved,
                      });
                    }}
                  >
                    {isAchieved && (
                      <TickPlain width={14} height={12}></TickPlain>
                    )}
                  </div>
                )}
                <div className={classes.editCellIconButton}>
                  <IconButton
                    onClick={() => this.updateCurrentFocusIndex(index)}
                    icon={<PencilLineOutlined />}
                    variant={"plain"}
                    size={"small"}
                  />
                </div>

                {lengthOfList > 1 && mode !== "view" && (
                  <IconButton
                    onClick={() => {
                      this.updateListValue({
                        index,
                        action: "REMOVE",
                      });
                    }}
                    icon={<DeleteOutlined />}
                    variant={"plain"}
                    size={"small"}
                  />
                )}
              </div>
            );
          }}
        </Draggable>
      );
    });
  };

  renderEdit = () => {
    const addButtonStyle = classNames(
      { [classes.addAnotherButton]: true },
      { [classes.addButtonDisable]: !this.canAdd() }
    );

    const {
      value,
      t,
      showAddButton,
      fieldKey,
      inputListContainerStyle,
    } = this.props;

    return (
      <Droppable droppableId={`UI_INPUT_LIST:${fieldKey}`}>
        {(provided, snapshot) => {
          return (
            <div
              className={classes.container}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={inputListContainerStyle}
            >
              {this.renderItems()}
              {/* {showAddButton && !!_.get(value[value.length - 1], "value", "") && ( */}
              <div className={classes.addButtonContainer}>
                <div className={classes.addButtonSvgContainer}>
                  <AddIcon width={6} height={6} fill={colors.blue29} />
                </div>

                <div className={addButtonStyle} onClick={this.onAddClick}>
                  {t("common:add_with_label", {
                    label: t("common:another"),
                  })}
                </div>
              </div>
              {/* )} */}
            </div>
          );
        }}
      </Droppable>
    );
  };

  renderView = () => {
    return <div className={classes.container}>{this.renderItems()}</div>;
  };
}

UIInputList.defaultProps = {
  ...UIBaseComponent.defaultProps,
  inputListContainerStyle: {},
  showListItemNumber: true,
  hasSelection: false,
  thinInteractions: true,
  shouldCreateOnEnter: true,
  shouldInitOptions: false,
  isDNDDisabled: false,
};

UIInputList.propTypes = {
  ...UIBaseComponent.propTypes,
  inputListContainerStyle: PropTypes.object,
  addButton: PropTypes.bool,
  listItemSvg: PropTypes.element,
  showListItemNumber: PropTypes.bool,
  hasSelection: PropTypes.bool,
  shouldInitOptions: PropTypes.bool,
  isDNDDisabled: PropTypes.bool,
};

export default I18nHOC({ resource: "common" })(UIInputList);
