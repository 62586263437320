import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { UIButton, withLoader, UIModal, I18nHOC } from "UIComponents";
import { CancelIcon } from "SvgComponents";
import classes from "./RelatedConceptsModal.scss";
import classNames from "classnames";
import update from "immutability-helper";
import RelatedConceptItem from "./RelatedConceptItem";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { getOrganizationPypElementSetFromCache } from "modules/CommonGraphqlHelpers";
import { getRelatedConceptSetQuery } from "modules/CommonQuery";
import { getRelatedConceptsGroupedValueOptions } from "UnitPlans/modules/UnitPlanModule";
class RelatedConceptList extends PureComponent {
  componentDidMount = () => {
    this.props.onComponentMounted();
  };
  render() {
    const {
      relatedConceptSets,
      updateInputField,
      groupedValue,
      groupedOptions,
    } = this.props;
    return (
      <div className={classes.relatedConceptContainer}>
        {_.map(relatedConceptSets, set => {
          const { id, label } = set;
          const value = groupedValue[id];
          const options = groupedOptions[id];
          return (
            <RelatedConceptItem
              value={value}
              options={options}
              key={id}
              updateInputField={value => updateInputField({ value, key: id })}
              label={label}
            />
          );
        })}
      </div>
    );
  }
}

const RelatedConceptListWithLoader = withLoader(RelatedConceptList);

class RelatedConceptsModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupedValue: props.groupedValue || {},
      groupedOptions: _.cloneDeep(props.groupedOptions) || {},
    };
  }

  onListMounted = () => {
    const { groupedValue, groupedOptions } = this.props;

    this.setState({
      groupedValue: groupedValue || {},
      groupedOptions: _.cloneDeep(groupedOptions),
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { groupedValue: oldGroupedValue } = prevState;
    const { groupedValue } = this.state;
    const { updateValueLocally } = this.props;
    if (groupedValue != oldGroupedValue) {
      if (updateValueLocally) {
        updateValueLocally(groupedValue);
      }
    }
  };

  UNSAFE_componentWillMount = () => {};

  onCancelClick = () => {
    this.props.onCancelClick();
  };

  onAddClick = () => {
    this.props.onAddClick({
      orgRelatedConceptSets: this.props.orgRelatedConceptSets,
      currentGroupedOptions: this.state.groupedOptions,
      groupedOptions: this.props.groupedOptions,
      groupedValue: this.state.groupedValue,
    });
    this.props.onCancelClick();
  };

  shouldShowShadow = () => {
    if (this.modalBody && this.listComponent) {
      return this.listComponent.scrollHeight > this.modalBody.clientHeight;
    }
    return false;
  };

  updateInputField = ({ value, key }) => {
    let { groupedValue } = this.state;

    groupedValue = update(groupedValue, { [key]: { $set: value } });
    this.setState({ groupedValue });
  };

  render() {
    const footerStyle = classNames(
      { [classes.modalFooter]: true },
      { [classes.modalFooterShadow]: this.shouldShowShadow() }
    );
    const { groupedValue, groupedOptions } = this.state;
    const { isEdit, relatedConceptSets, isData, isLoading, t } = this.props;

    return (
      <UIModal isOpen={true} onRequestClose={this.onCancelClick}>
        <div className={classes.modalHeader}>
          <div className={classes.headerTitle}>
            {t("unitPlan:create_related_concept")}
          </div>
          <div className={classes.cancelIcon} onClick={this.onCancelClick}>
            <CancelIcon width={16} height={16} />
          </div>
        </div>
        <div className={classes.modalBody} ref={ref => (this.modalBody = ref)}>
          <div
            className={classes.fieldComp}
            ref={ref => (this.listComponent = ref)}
          >
            <RelatedConceptListWithLoader
              relatedConceptSets={relatedConceptSets}
              groupedOptions={groupedOptions}
              groupedValue={groupedValue}
              updateInputField={this.updateInputField}
              isData={isData}
              isLoading={isLoading}
              onComponentMounted={this.onListMounted}
            />
          </div>
        </div>

        <div className={footerStyle}>
          <div className={classes.cancelButton}>
            <UIButton color="grey" onClick={this.onCancelClick} size="sm">
              {t("common:cancel")}
            </UIButton>
          </div>

          <UIButton
            color="pink"
            isDisabled={isLoading}
            onClick={this.onAddClick}
            size="sm"
          >{`${
            this.props.isEdit ? t("common:update") : t("common:add")
          }`}</UIButton>
        </div>
      </UIModal>
    );
  }
}

const memoizedGetGroupedValueOptions = _.memoize(
  getRelatedConceptsGroupedValueOptions,
  params => JSON.stringify(params)
);

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
  return {
    isData: true,
    isLoading: false,
    organizationId: state.login.userInfo.org_id,
  };
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["unitPlan", "common"] }),
  graphql(getRelatedConceptSetQuery, {
    name: "getRelatedConceptSet",
    skip: ({ subjects, concepts }) =>
      _.get(subjects, "length", 0) == 0 && _.get(concepts, "length", 0) == 0,
    options: ({ subjects, organizationId, concepts }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        subjects,
        concepts,
        organizationId,
      },
    }),
    props({
      getRelatedConceptSet,
      ownProps: {
        subjects,
        value,
        organizationId,
        concepts,
        isData,
        isLoading,
        selectedRelatedConcepts,
        groupedOptionsFields,
      },
    }) {
      const queryData = getOrganizationPypElementSetFromCache({
        subjects,
        organizationId,
        concepts,
        type: "RELATED_CONCEPT",
      });
      const relatedConceptSet = _.get(
        queryData,
        "pypElement.relatedConcept",
        {}
      );

      const {
        groupedRelatedConcepts,
        groupedValue,
        groupedOptions,
      } = memoizedGetGroupedValueOptions({
        nodes: _.unionBy(
          [
            ..._.get(relatedConceptSet, "nodes", []),
            ...selectedRelatedConcepts,
          ],
          "id"
        ),
        subjects,
        value,
        concepts,
        groupedOptionsFields,
      });

      return {
        isData: !_.isEmpty(queryData) && isData,
        groupedRelatedConcepts,
        groupedOptions,
        groupedValue,
        orgRelatedConceptSets: _.get(relatedConceptSet, "nodes", []),

        isLoading:
          getRelatedConceptSet["networkStatus"] == 1 ||
          getRelatedConceptSet["networkStatus"] == 2 ||
          isLoading,
      };
    },
  })
)(RelatedConceptsModal);

RelatedConceptsModal.propTypes = {
  updateInputField: PropTypes.func,
  onCancelClick: PropTypes.func,
  name: PropTypes.string,
  isEdit: PropTypes.bool,
};

RelatedConceptsModal.defaultProps = {
  isEdit: false,
};
