import React from "react";
import classes from "./InputSet.scss";
import { SearchBarWithTag, UIBaseComponent, I18nHOC } from "UIComponents";

const searchBarWithTagStyles = {
  searchBarWithTagContainer: {
    padding: "6px 16px",
  },
  searchBarContainer: {
    padding: "6px 0",
  },
  inputTextStyle: {
    fontSize: "1.6rem",
  },
  tag: {
    container: {
      margin: "2px",
      padding: "6px 8px 6px 12px",
    },
  },
};
class InputSet extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      searchText: "",
    };

    this.searchBarWithTagRef = React.createRef();
  }

  updateInput = () => {
    const { value = [], onAddCustomValidation } = this.props;
    let { searchText } = this.state;

    searchText = searchText.trim();
    searchText =
      searchText[searchText.length - 1] === ","
        ? searchText.slice(0, -1)
        : searchText;

    if (!searchText) return;

    let isValid = true;
    if (onAddCustomValidation) {
      isValid = onAddCustomValidation(searchText);
    }

    if (!isValid) return;

    this.updateValue([...value, searchText]);
    this.setState({ searchText: "" });
    setTimeout(this.clearSearchText, 0);
  };

  removeOption = item => {
    const { value = [] } = this.props;
    const updatedValue = _.filter(value, i => i !== item.value);
    this.updateValue(updatedValue);
  };

  handleKeyPress = value => this.setState({ searchText: value });

  clearSearchText = () => {
    if (this.searchBarWithTagRef.current)
      this.searchBarWithTagRef.current.clearSearchBar();
  };

  renderEdit = () => {
    const { searchText } = this.state;
    const { t, value, addOnEnter, placeholder } = this.props;

    return (
      <div className={classes.container}>
        <SearchBarWithTag
          removeOnBackspace={true}
          addOnComma={true}
          addOnSpace={true}
          removeOption={this.removeOption}
          ref={this.searchBarWithTagRef}
          styles={searchBarWithTagStyles}
          placeholder={placeholder}
          value={_.map(value, value => ({ label: value, value }))}
          onSearchChange={this.handleKeyPress}
          showSearchIcon={false}
          inlineView={true}
          onAddLabel={this.updateInput}
          searchText={searchText}
          addOnEnter={addOnEnter}
          onBlur={this.updateInput}
        />
      </div>
    );
  };
}

InputSet.defaultProps = {
  ...UIBaseComponent.defaultProps,
  options: [],
  value: [],
  addOnEnter: false,
};

InputSet.propTypes = {
  ...UIBaseComponent.propTypes,
};

export default I18nHOC({ resource: "common" })(InputSet);
