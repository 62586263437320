import React from "react";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { getIsCurrentAcademicYear } from "Platform/modules/PlatformModule";

const HiddenIfPreviousYear = ({ getIsCurrentAcademicYear, children }) => {
  const isCurrentAcademicYear = getIsCurrentAcademicYear();
  if (!isCurrentAcademicYear) {
    return null;
  }
  return <>{children}</>;
};

const HiddenIfPreviousYearMemoized = React.memo(HiddenIfPreviousYear);

const mapActionCreators = {
  getIsCurrentAcademicYear,
};

export default compose(connect(null, mapActionCreators))(
  HiddenIfPreviousYearMemoized
);
