import React, { Component } from "react";
import PropTypes from "prop-types";

const ImageIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.fill}
        d="M6.2261,2.3097 C3.5291,2.3097 1.3358,4.5044 1.3358,7.2 C1.3358,9.8969 3.5291,12.0903 6.2261,12.0903 L9.7739,12.0903 C12.4709,12.0903 14.6642,9.8969 14.6642,7.2 C14.6642,4.5044 12.4709,2.3097 9.7739,2.3097 L6.2261,2.3097 Z M9.7739,13.4261 L6.2261,13.4261 C2.7931,13.4261 0,10.633 0,7.2 C0,3.767 2.7931,0.9739 6.2261,0.9739 L9.7739,0.9739 C13.2069,0.9739 16,3.767 16,7.2 C16,10.633 13.2069,13.4261 9.7739,13.4261 Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.fill}
        d="M6.533,5.2229 C6.533,5.9309 5.9587,6.5053 5.2507,6.5053 C4.5427,6.5053 3.9683,5.9309 3.9683,5.2229 C3.9683,4.5149 4.5427,3.9406 5.2507,3.9406 C5.9587,3.9406 6.533,4.5149 6.533,5.2229 Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.fill}
        d="M10.2054,12.484 C9.5549,10.1784 8.525,8.7972 7.3789,8.6943 C6.1927,8.5808 4.75,9.8778 3.6814,12.0685 L2.4819,11.4821 C3.8364,8.701 5.6677,7.1956 7.4977,7.3639 C8.5143,7.4547 10.383,8.1934 11.4917,12.122 L10.2054,12.484 Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.fill}
        d="M10.0488,9.7113 L8.7972,9.2437 C9.799,6.5655 11.1388,5.8509 12.0872,5.7253 C12.9755,5.6077 14.33,5.8883 15.7686,7.8919 L14.6827,8.6707 C13.8611,7.5259 12.9969,6.9529 12.2649,7.0491 C11.4354,7.1599 10.6486,8.1043 10.0488,9.7113 Z"
      />
    </svg>
  );
};

ImageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ImageIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default ImageIcon;
