import { fontStyle, colors } from "Constants";

export const styles = {
  feedListItemStyle: {
    fontWeight: fontStyle.demiBold,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: colors.textDefault,
  },
  feedListJustifyStartStyle: {
    justifySelf: "flex-start",
  },
  feedListJustifyCenterStyle: {
    justifyItems: "center",
  },
};
