import client from "apolloClient";
import {
  getCourseAssignmentFeedQuery,
  getCourseAssignmentStateWiseCountQuery,
  getAssignmentEditDetailsQuery,
  getStudentAssignmentDetailsQuery,
  getAssignmentDetailsQuery,
  getAssignmentAllSubmissionQuery,
  getAssessmentDetailsQuery,
  getStudentInsightsQuery,
  getStudentTodosQuery,
  getFamilyStudentTodosQuery,
  getTeacherClassroomReviewQuery,
  getFamilyStudentTodosFilterCountQuery,
  getAssignmentEvaluationInsightQuery,
  getStudentTodosFilterCountQuery,
  getParentStudentCourseAssignmentStateWiseCountQuery,
  getOrganizationAssignmentFeedQuery,
  getCourseUserMapQuery,
  getMultiCourseUserMapQuery,
  getAssignedStudentsQuery,
} from "./ClassRoomQuery";
import {
  courseAssignmentFragment,
  studentAssigmentSubmissionFragment,
  studentAssignmentFragment,
  assignmentFragment,
} from "./ClassRoomFragments";
import { pypElementRatingFragment } from "modules/CommonFragments";

export const getCourseAssignmentFeedFromCache = ({
  courseId,
  createdBy,
  contentTypes,
  assessmentTypes,
  searchText,
  states,
  orderBy,
  orderByDirection,
  folderIds,
  studentIds,
  showUpcoming,
  unreadMessageCountFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseAssignmentFeedQuery,
      variables: {
        courseId,
        createdBy,
        searchText,
        contentTypes,
        assessmentTypes,
        states,
        orderBy,
        orderByDirection,
        folderIds,
        studentIds,
        showUpcoming,
        unreadMessageCountFilters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getFamilyStudentTodosFilterFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getFamilyStudentTodosFilterCountQuery,
      variables,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  return {};
};

export const getCourseAssignmentStateWiseCountFromCache = ({ courseId }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseAssignmentStateWiseCountQuery,
      variables: {
        courseId,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getParentStudentCourseAssignmentStateWiseCountFromCache = ({
  userId,
  filters,
  type,
  studentIds,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getParentStudentCourseAssignmentStateWiseCountQuery,
      variables: {
        userId,
        filters,
        type,
        studentIds,
      },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getAssignmentEditDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssignmentEditDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAssignmentDetailsFromCache = ({
  id,
  showOnlySubmitted,
  studentIds,
  presetAssessmentUids,
  userFilter,
  unreadMessageCountFilters,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssignmentDetailsQuery,
      variables: {
        id,
        showOnlySubmitted,
        studentIds,
        presetAssessmentUids,
        userFilter,
        unreadMessageCountFilters,
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentAssignmentDetailsFromCache = ({
  id,
  showOnlySubmitted,
  userFilter,
}) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentAssignmentDetailsQuery,
      variables: {
        id,
        showOnlySubmitted,
        userFilter,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeStudentAssignmentDetailsInCache = ({
  id,
  data,
  showOnlySubmitted,
  userFilter,
}) => {
  try {
    client.writeFragment({
      id: `StudentAssignment:${id}`,
      fragment: studentAssignmentFragment.studentAssignmentDetails,
      fragmentName: "studentAssignmentItem",
      variables: {
        showOnlySubmitted,
        userFilter,
      },
      data,
    });
  } catch (e) {}
};

export const getAssignmentAllSubmissionDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssignmentAllSubmissionQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const writeCourseAssignmentFeedFromCache = ({
  courseId,
  createdBy,
  contentTypes,
  assessmentTypes,
  searchText,
  data,
  states,
  orderBy,
  orderByDirection,
  folderIds,
}) => {
  try {
    client.writeFragment({
      id: `Course:${courseId}`,
      fragment: courseAssignmentFragment.courseAssignmentFeed,
      fragmentName: "courseAssignmentItem",
      variables: {
        createdBy,
        searchText,
        contentTypes,
        assessmentTypes,
        states,
        orderBy,
        orderByDirection,
        folderIds,
      },
      data,
    });
  } catch (e) {}
};

export const writeCourseAssignmentStateWiseCountInCache = ({
  courseId,
  data,
}) => {
  try {
    client.writeFragment({
      id: `Course:${courseId}`,
      fragment: courseAssignmentFragment.courseAssignmentStateWiseCount,
      fragmentName: "courseAssignmentItem",
      data,
    });
  } catch (e) {}
};

export const writeStudentAssigmentSubmissionInCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `StudentAssignmentSubmission:${id}`,
      fragment: studentAssigmentSubmissionFragment.studentAssigmentSubmission,
      fragmentName: "studentAssigmentSubmissionItem",
      data,
    });
  } catch (e) {}
};

export const getAssessmentDetailsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssessmentDetailsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentInsightsFromCache = input => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getStudentInsightsQuery,
      variables: input,
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getStudentTodosFeedFromCache = input => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getStudentTodosQuery,
      variables: { ...input },
    });
  } catch (e) {
    return {};
  }

  return queryData ? _.get(queryData, "node", {}) : {};
};

export const getFamilyStudentTodosFeedFromCache = input => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getFamilyStudentTodosQuery,
      variables: { ...input },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getTeacherClassroomReviewFromCache = input => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getTeacherClassroomReviewQuery,
      variables: { ...input },
    });
  } catch (e) {
    return {};
  }

  return queryData ? _.get(queryData, "node", {}) : {};
};

export const getOrganizationAssignmentFeedFromCache = variables => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getOrganizationAssignmentFeedQuery,
      variables,
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getAssignmentEvaluationInsightFromCache = ({ assignmentId }) => {
  let queryData = {};

  try {
    queryData = client.readQuery({
      query: getAssignmentEvaluationInsightQuery,
      variables: {
        id: assignmentId,
      },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getStudentTodosFiltersFromCache = input => {
  let queryData;
  try {
    queryData = client.readQuery({
      query: getStudentTodosFilterCountQuery,
      variables: { ...input },
    });
  } catch (e) {
    return {};
  }

  return _.get(queryData, "node", {});
};

export const getAssignmentFeedItemFromCache = ({ id, variables }) => {
  let fragmentData;
  try {
    fragmentData = client.readFragment({
      id: `Assignment:${id}`,
      fragment: assignmentFragment.assignmentFeed,
      fragmentName: "assignmentItem",
      variables,
    });
  } catch (e) {
    return {};
  }
  return fragmentData;
};

export const writeAssignmentFeedItemToCache = ({ id, data }) => {
  try {
    client.writeFragment({
      id: `Assignment:${id}`,
      fragment: assignmentFragment.assignmentFeed,
      fragmentName: "assignmentItem",
      data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getCourseUserMapFromCache = ({ id, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseUserMapQuery,
      variables: {
        id,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getAssignedStudentsFromCache = ({ id }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getAssignedStudentsQuery,
      variables: {
        id,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getMultiCourseUserMapFromCache = ({ ids, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getMultiCourseUserMapQuery,
      variables: {
        ids,
        filters,
      },
    });
  } catch (e) {
    return {};
  }

  if (queryData != null) return _.get(queryData, "multiNode", []);
  else return {};
};
