import React, { useState, memo } from "react";
import classes from "./SimilarityIndexComponent.scss";
import classNames from "classnames";
import {
  RefreshArrowOutlined,
  CloseOutlined,
  WarningTriangleFilled,
  InformationOutlined,
} from "@toddle-design/web-icons";
import { connect } from "react-redux";
import EULAModal from "./EULAModal";
import { FullScreenLoader, I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import {
  checkForSimilarity,
  cancelCheckSimilarityReport,
  getTurnitinReportViewerLaunchUrl,
  openLinkInNewTab,
  getIsTurnitinEulaRequired,
} from "modules/Services";
import { SIMILARITY_FILE_MIMETYPE, SIMILARITY_ERROR_CODE_MAP } from "Constants";
import { Button, Tooltip } from "@toddle-design/web";
import { getSettingValue } from "modules/PermissionModule";
import { setToastMsg } from "Login/modules/LoginModule";
import { getShowSimilarityOnProject } from "Projects/modules/ProjectModules";

const ARRAY_FOR_SHOW_RECHECK_ON_ERROR = [
  "PROCESSING_ERROR",
  "CANNOT_EXTRACT_TEXT",
  "TOO_LITTLE_TEXT",
  "TOO_MANY_PAGES",
  "FAILED_TO_EXPORT",
  "UNKNOWN_ERROR",
  "CANNOT_REACH_TURNITIN",
];

const getSimilarityIndexLevel = ({ percentage }) => {
  if (percentage > 30) return "high";
  else if (percentage <= 30 && percentage > 15) return "medium";
  else if (percentage <= 15 && percentage >= 0) return "low";
};

const getSimilarityPercentageClass = ({
  percentage,
  showSimilarityInAllSubmission,
  showInAttachmentItem,
}) => {
  const similarityIndexLevel = getSimilarityIndexLevel({ percentage });
  return classNames(
    { [classes.similarityPercentage]: showSimilarityInAllSubmission },
    { [classes.cardSimilarityPercentage]: !showSimilarityInAllSubmission },
    { [classes.attachmentItemSimilarityPercentage]: showInAttachmentItem },
    { [classes.attachmentItemFont]: showInAttachmentItem },
    { [classes.highIndex]: similarityIndexLevel === "high" },
    { [classes.mediumIndex]: similarityIndexLevel === "medium" },
    { [classes.lowIndex]: similarityIndexLevel === "low" }
  );
};

const SimilarityIndexComponent = memo(props => {
  const {
    metadata,
    attachment: oldAttachment,
    showSimilarityInAllSubmission,
    getIsTurnitinEulaRequired,
    t,
    cancelCheckSimilarityReport,
    showSimilarityOnHeader,
    getTurnitinReportViewerLaunchUrl,
    openLinkInNewTab,
    isParent = false,
    isTurnitinEnabled,
    recheckButtonHoverClass,
    setToastMsg,
    showInAttachmentItem,
  } = props;

  const [showEULA, setShowEULA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState("");

  const attachment = !_.isEmpty(_.get(oldAttachment, "attachments", []))
    ? _.first(_.get(oldAttachment, "attachments", []))
    : oldAttachment;

  const { id, type, mimeType, similarityReport } = attachment;

  const checkForSimilarity = async () => {
    const { checkForSimilarity } = props;

    setIsLoading(true);
    await checkForSimilarity({ attachmentId: id, metadata });
    setIsLoading(false);
  };

  const getTurnitinReportURL = async () => {
    const { submissionId } = similarityReport;
    setIsLoading(true);
    const turnItInUrl = await getTurnitinReportViewerLaunchUrl({
      input: {
        submissionId,
      },
    });

    if (turnItInUrl) {
      openLinkInNewTab({ url: turnItInUrl });
    } else {
      setToastMsg({
        msg: "toastMsgs:report_not_available_text",
        type: "alert",
      });
    }
    setIsLoading(false);
  };

  const onClickCheckForSimilarity = async event => {
    event.stopPropagation();
    setAction("CHECK_FOR_SIMILARITY");
    setIsLoading(true);
    const isEulaRequire = await getIsTurnitinEulaRequired();
    setIsLoading(false);

    if (isEulaRequire) {
      setShowEULA(true);
    } else {
      await checkForSimilarity();
    }
  };

  const onClickSimilarityPercentage = async event => {
    event.stopPropagation();
    setAction("GENERATE_TURNITIN_REPORT_URL");
    setIsLoading(true);
    const isEulaRequire = await getIsTurnitinEulaRequired();
    setIsLoading(false);

    if (isEulaRequire) {
      setShowEULA(true);
    } else {
      await getTurnitinReportURL();
    }
  };

  const getModalOnAgreeAction = () => {
    switch (action) {
      case "CHECK_FOR_SIMILARITY":
        return checkForSimilarity();
      case "GENERATE_TURNITIN_REPORT_URL":
        return getTurnitinReportURL();
    }
  };

  const onClickCancel = async event => {
    event.stopPropagation();
    await cancelCheckSimilarityReport({
      attachmentId: id,
    });
  };

  const getErrorMessage = ({ errorCode }) => {
    const message = SIMILARITY_ERROR_CODE_MAP[errorCode];
    switch (errorCode) {
      case "PROCESSING_ERROR":
        return t("common:failed_with_message", {
          message: "",
        });
      case "UNKNOWN_ERROR":
        return t(message);
      default:
        return t("common:failed_with_message", {
          message: t(message),
        });
    }
  };

  const getShowRecheckButtonOnError = ({ errorCode }) => {
    return _.includes(ARRAY_FOR_SHOW_RECHECK_ON_ERROR, errorCode);
  };

  const getPercentageComponent = () => {
    const { status, overallMatchPercentage, errorCode } = similarityReport;

    const checkingForSimilarityClass = classNames({
      [classes.checkingForSimilarity]: true,
      [classes.checkingForSimilarityHeader]: showSimilarityOnHeader,
      [classes.noBorderStyle]: showSimilarityInAllSubmission,
      [classes.attachmentItemFont]: showInAttachmentItem,
    });

    switch (status) {
      case "COMPLETE": {
        const similarityPercentageClass = getSimilarityPercentageClass({
          percentage: overallMatchPercentage,
          showSimilarityInAllSubmission,
        });
        const similarityPercentageAttachmentItemClass = getSimilarityPercentageClass(
          {
            percentage: overallMatchPercentage,
            showInAttachmentItem: true,
          }
        );
        const percentageDivClass = classNames({
          [classes.percentageDiv]: true,
          [classes.percentageDivHeader]: showSimilarityOnHeader,
          [classes.noBorderStyle]: showSimilarityInAllSubmission || isParent,
          [classes.similarityDivInAttachmentItem]: showInAttachmentItem,
        });
        const similarityIndexClass = classNames({
          [similarityPercentageClass]: !showSimilarityOnHeader,
          [classes.titleBarIndex]: showSimilarityOnHeader,
        });
        const recheckButtonClass = classNames({
          [classes.recheckButton]: true,
          [classes.recheckButtonHeader]:
            showSimilarityOnHeader || showInAttachmentItem,
          [classes.noBorderStyle]: showSimilarityInAllSubmission || isParent,
          [classes.recheckButtonInAttachment]: showInAttachmentItem,
          [recheckButtonHoverClass]: !!recheckButtonHoverClass,
        });
        const percentageInnerDivClass = classNames({
          [classes.percentageInnerDiv]: true,
          [classes.percentageInnerDivHeader]: showSimilarityOnHeader,
        });

        return (
          <div className={percentageDivClass}>
            {showInAttachmentItem ? (
              <div
                className={classes.similarityContainerInAttachment}
                onClick={onClickSimilarityPercentage}
              >
                <div className={`${classes.similarityIndex} text-label-4`}>
                  {t("classRoom:similarity_index")}:
                </div>
                <div
                  className={`${similarityPercentageAttachmentItemClass} text-label-4`}
                >
                  {overallMatchPercentage}%
                </div>
              </div>
            ) : (
              <div className={percentageInnerDivClass}>
                <div
                  className={similarityIndexClass}
                  onClick={onClickSimilarityPercentage}
                >
                  {showSimilarityInAllSubmission
                    ? `${overallMatchPercentage}%`
                    : t("classRoom:percentage_with_similar", {
                        percentage: overallMatchPercentage,
                      })}
                </div>
              </div>
            )}
            {!isParent && (
              <div
                className={`${recheckButtonClass} ${
                  showInAttachmentItem && "text-label-4"
                }`}
                onClick={onClickCheckForSimilarity}
              >
                <Button
                  size={"small"}
                  variant={showSimilarityOnHeader ? "primary" : "inline"}
                  icon={
                    <RefreshArrowOutlined
                      size={"xxx-small"}
                      variant={"subtle"}
                    />
                  }
                >
                  <div
                    className={
                      showInAttachmentItem && classes.attachmentItemFont
                    }
                  >
                    {showSimilarityOnHeader
                      ? t("classRoom:recheck_for_similarity")
                      : t("common:recheck")}
                  </div>
                </Button>
              </div>
            )}
          </div>
        );
      }
      case "PROCESSING": {
        const similarityDivClass = classNames({
          [classes.similarityDiv]: true,
          [classes.similarityDivHeader]: showSimilarityOnHeader,
          [classes.similarityDivInColumn]:
            !showInAttachmentItem && showSimilarityInAllSubmission,
          [classes.similarityDivInAttachmentItem]: showInAttachmentItem,
        });
        const cancelButtonClass = classNames({
          [classes.cancelButton]: true,
          [classes.cancelButtonHeader]: showSimilarityOnHeader,
          [classes.noBorderStyle]:
            showSimilarityInAllSubmission || showInAttachmentItem,
        });

        return !isParent ? (
          <div className={similarityDivClass}>
            <div className={checkingForSimilarityClass}>
              {`${t("classRoom:checking_for_similarity")}...`}
            </div>
            <div className={cancelButtonClass} onClick={onClickCancel}>
              <Button
                size={"small"}
                variant={showSimilarityOnHeader ? "destructive" : "inline"}
                icon={
                  !showSimilarityOnHeader && (
                    <CloseOutlined size={"xxx-small"} variant={"subtle"} />
                  )
                }
              >
                <div
                  className={showInAttachmentItem && classes.attachmentItemFont}
                >
                  {t("common:cancel")}
                </div>
              </Button>
            </div>
          </div>
        ) : null;
      }
      case "ERROR": {
        const showRecheckButton = getShowRecheckButtonOnError({ errorCode });
        const errorContainerDivClass = classNames({
          [classes.errorContainerDiv]: true,
          [classes.errorContainerDivInColumn]:
            !showSimilarityOnHeader && !showInAttachmentItem,
          [classes.errorContainerDivAttachmentItem]: showInAttachmentItem,
        });
        const errorInnerDivClass = classNames({
          [classes.percentageInnerDiv]: true,
          [classes.percentageInnerDivHeader]: showSimilarityOnHeader,
        });
        const errorDivClass = classNames({
          [classes.errorDiv]: true,
          [classes.errorDivOnHeader]: showSimilarityOnHeader,
          [classes.errorDivAttachmentItem]: showInAttachmentItem,
        });
        const recheckButtonClass = classNames({
          [classes.recheckButton]: true,
          [classes.recheckVisible]: true,
          [classes.noBorderStyle]: true,
        });

        return !isParent ? (
          <div className={errorContainerDivClass}>
            <div className={errorInnerDivClass}>
              {!showInAttachmentItem && (
                <div className={classes.warningIcon}>
                  <WarningTriangleFilled
                    size={showSimilarityOnHeader ? "x-small" : "xxx-small"}
                    variant={"critical"}
                  />
                </div>
              )}
              <div className={errorDivClass}>
                {showInAttachmentItem ? (
                  <div className={classes.errorContainer}>
                    <div>Check failed!</div>
                    <Tooltip
                      tooltip={getErrorMessage({ errorCode })}
                      placement={"top"}
                    >
                      <InformationOutlined
                        className={classes.errorInfoIcon}
                        variant={"critical"}
                        size={"xxx-small"}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  getErrorMessage({ errorCode })
                )}
              </div>
            </div>
            {showRecheckButton && (
              <div
                className={`${recheckButtonClass} ${
                  showInAttachmentItem && "text-label-4"
                }`}
                onClick={onClickCheckForSimilarity}
              >
                <Button
                  size={"small"}
                  variant={showSimilarityOnHeader ? "primary" : "inline"}
                  icon={
                    <RefreshArrowOutlined
                      size={"xxx-small"}
                      variant={"subtle"}
                    />
                  }
                >
                  <div
                    className={
                      showInAttachmentItem && classes.attachmentItemFont
                    }
                  >
                    {showSimilarityOnHeader
                      ? t("classRoom:recheck_for_similarity")
                      : t("common:recheck")}
                  </div>
                </Button>
              </div>
            )}
          </div>
        ) : null;
      }
    }
  };

  const getCheckForSimilarityComponent = () => {
    const checkForSimilarityClass = classNames({
      [classes.checkForSimilarity]: true,
      [classes.checkForSimilarityHeader]: showSimilarityOnHeader,
      [classes.attachmentItemFont]: showInAttachmentItem,
    });
    return (
      <div
        className={checkForSimilarityClass}
        onClick={onClickCheckForSimilarity}
      >
        {showSimilarityOnHeader ? (
          <Button size={"small"} variant={"primary"}>
            {t("classRoom:check_for_similarity")}
          </Button>
        ) : (
          t("classRoom:check_for_similarity")
        )}
      </div>
    );
  };

  const getIsTurnitinAllowedMimeType = () => {
    return (
      (type == "FILE" || type == "LINK") &&
      _.includes(SIMILARITY_FILE_MIMETYPE, mimeType)
    );
  };

  const isAttachmentReadyToShowSimilarity = !_.includes(id, "NEW");

  return isAttachmentReadyToShowSimilarity && isTurnitinEnabled ? (
    getIsTurnitinAllowedMimeType() ? (
      <div className={classes.similarityContainer}>
        {similarityReport
          ? getPercentageComponent()
          : !isParent && getCheckForSimilarityComponent()}
        {showEULA && (
          <EULAModal
            setShowEULA={setShowEULA}
            onClickAgree={getModalOnAgreeAction}
            t={t}
          />
        )}
        {isLoading && <FullScreenLoader />}
      </div>
    ) : showSimilarityInAllSubmission ? (
      "-"
    ) : null
  ) : null;
});

SimilarityIndexComponent.displayName = "SimilarityIndexComponent";

const mapStateToProps = (state, ownProps) => {
  const organizationId = _.get(state, "login.userInfo.org_id", "");

  //Checking Turnitin Enabled for Personal Projects/Community Projects
  const projectGroupType = _.get(ownProps, "projectGroupType", null);
  const projectGroupId = _.get(ownProps, "projectGroupId", null);

  const showSimilarityOnProject =
    projectGroupId && projectGroupType
      ? getShowSimilarityOnProject({
          projectGroupType,
          projectGroupId,
          organizationId,
        })
      : false;

  //Checking Turnitin Enabled for classroom grades
  let isTurnitinEnabledForGrade = false;
  if (!showSimilarityOnProject) {
    const turnitinEnabledGrades = getSettingValue({
      name: "TurnitinEnabledGrades",
      organizationId,
    });
    const selectedGrade = _.get(ownProps, "course.grade.id", "");
    isTurnitinEnabledForGrade = _.includes(
      turnitinEnabledGrades,
      selectedGrade
    );
  }

  const isTurnitinEnabled =
    isTurnitinEnabledForGrade || showSimilarityOnProject;

  return {
    organizationId,
    isTurnitinEnabled,
  };
};

const mapActionCreators = {
  checkForSimilarity,
  getIsTurnitinEulaRequired,
  cancelCheckSimilarityReport,
  getTurnitinReportViewerLaunchUrl,
  openLinkInNewTab,
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: ["common", "classRoom"] }),
  connect(mapStateToProps, mapActionCreators)
)(SimilarityIndexComponent);
