import React from "react";
import classes from "./CollaboratorItem.scss";
import { ProfileCascade, I18nHOC } from "UIComponents";
import { colors } from "Constants";
import classNames from "classnames";
import { UserDetails } from "AppComponents/ArchivedComponents";
import PermissionDropDown from "../PermissionDropDown";

const profileCascadeStyle = {
  customStyle: {
    borderWidth: 0,
    width: 32,
    height: 32,
  },
};

// const getClassesList = courses => {
//   return _.map(courses, item => {
//     return item.title;
//   });
// };

const menuItemsMap = {
  EDIT: {
    label: "common:can_edit",
    value: "EDIT",
    svg: null,
    isDisable: false,
    style: {
      borderWidth: 0,
    },
  },
  VIEW: {
    label: "common:can_view",
    value: "VIEW",
    svg: null,
    isDisable: false,
  },
  REMOVE: {
    label: "common:remove",
    value: "REMOVE",
    svg: null,
    isDisable: false,
    style: {
      color: colors.salmon60,
    },
  },
};

const changeLocale = (menuItem, t, removeRequired) => {
  return _.reduce(
    menuItem,
    (result, item) => {
      if (!removeRequired) {
        if (item.value !== "REMOVE")
          result.push({ ...item, label: t(item.label) });
      } else {
        result.push({ ...item, label: t(item.label) });
      }
      return result;
    },
    []
  );
};

const renderOwnerText = ownerType => {
  switch (ownerType) {
    case "OWNER":
      return "common:owner";
    case "ADMIN":
      return "common:admin";
    default:
      return "common:owner";
  }
};

const CollaboratorItem = ({
  item,
  permission,
  isOwner,
  isShow,
  t,
  onActionClick,
  mode,
  removeRequired,
  isDisable,
  actionMenuItems,
}) => {
  const {
    id,
    firstName,
    lastName,
    profileImage,
    courses,
    email,
    isArchived,
  } = item;
  const name = !!firstName ? `${firstName} ${lastName}` : email;

  const menuItems = _.map(actionMenuItems, action => menuItemsMap[action]);

  let permissionLabel = _.get(
    _.find(menuItems, menuItem => menuItem.value == permission),
    "label",
    ""
  );

  if (isDisable) {
    permissionLabel = "--";
  } else {
    permissionLabel = t(permissionLabel);
  }

  const containerClass = classNames(
    { [classes.container]: true },
    { [classes.disabledContainer]: isDisable }
  );

  const newActionMenuItems = changeLocale(menuItems, t, removeRequired);

  return (
    <div className={containerClass}>
      <div className={classes.leftContainer}>
        <ProfileCascade
          items={[{ id, value: firstName, imageUrl: profileImage }]}
          customStyle={profileCascadeStyle.customStyle}
          isArchived={isArchived}
        />
        <UserDetails
          name={name}
          isArchived={isArchived}
          nameClasses={classes.nameText}
          viewMode={"NAME_ONLY"}
        />
      </div>
      <div className={classes.rightContainer}>
        {isOwner ? (
          <div className={classes.permissionLabel}>
            {t(renderOwnerText(isOwner))}
          </div>
        ) : mode == "edit" ? (
          <>
            {_.size(newActionMenuItems) > 1 ? (
              <PermissionDropDown
                buttonContainerStyle={{ minWidth: 74 }}
                menuItems={newActionMenuItems}
                id={id}
                onActionClick={action => onActionClick({ action, item })}
                permissionLabel={permissionLabel}
                isButtonDisabled={isDisable}
              />
            ) : (
              <div
                style={newActionMenuItems[0].style}
                className={classes.cursorPointer} //TODO: Make this is a universal class
                onClick={() => {
                  onActionClick({ item, action: newActionMenuItems[0].value });
                }}
              >
                {newActionMenuItems[0].label}
              </div>
            )}
          </>
        ) : (
          <div className={classes.permissionLabel}>{permissionLabel}</div>
        )}
      </div>
    </div>
  );
};

CollaboratorItem.defaultProps = {
  removeRequired: true,
  isDisable: false,
  actionMenuItems: ["EDIT", "VIEW", "REMOVE"],
};

export default I18nHOC({ resource: ["unitPlan", "common"] })(CollaboratorItem);
