import gql from "graphql-tag";
import {
  teacherFragments,
  organizationStaffFeedFragment,
} from "TeacherSetup/modules/Fragments";

export const getStaffDetailQuery = gql`
  query getStaff($ids: [ID!]) {
    platform {
      staff(ids: $ids) {
        ...teacherItemDetail
      }
    }
  }
  ${teacherFragments.teacherDetails}
`;

export const getTeacherFeedQuery = gql`
  query getTeacherFeed(
    $organizationId: ID!
    $after: String
    $filters: OrgConnectionFilterInput
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationStaffFeedEdgeItem
      }
    }
  }
  ${organizationStaffFeedFragment.organizationStaffFeedEdge}
`;

export const getCoursesOfTeacherQuery = gql`
  query getCoursesOfTeacher($ids: [ID!], $filters: CourseFilter) {
    platform {
      staff(ids: $ids) {
        id
        courses(filters: $filters) {
          id
          title
          profileImage
          isArchived
          owner {
            id
          }
          grades {
            id
            name
          }
        }
      }
    }
  }
`;

export const checkDuplicateEmailQuery = gql`
  query checkDuplicateEmailQuery($id: ID!, $email: String!) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        id
        checkUserEmailExists(input: { email: $email })
      }
    }
  }
`;
