/**--external-- */
import React from "react";
import classNames from "classnames";
import { AddOutlined } from "@toddle-design/web-icons";

/**--internal-- */
import { useI18n } from "Hooks";

/**--relative-- */
import classes from "./DropZoneButtonComponent.scss";

const DropZoneButtonComponent = props => {
  const { logoAttachment } = props;

  const { t } = useI18n(["common"]);

  if (_.isEmpty(logoAttachment)) {
    const addButtonTextClasses = classNames(
      "text-label-3",
      classes.addButtonText
    );

    return (
      <div className={classes.addButtonContainer}>
        <div className={classes.addButton}>
          <AddOutlined size="x-small" />
          <div className={addButtonTextClasses}>{t("common:add_image")}</div>
        </div>
      </div>
    );
  }

  const { url } = logoAttachment;

  return (
    <div className={classes.figureContainer}>
      <figure className={classes.figure}>
        <img src={url} alt={"school logo"} />
      </figure>
      <div className={classes.overlay}>
        <span className={"text-body-1"}>{t("common:change_school_logo")}</span>
      </div>
    </div>
  );
};

export default DropZoneButtonComponent;
