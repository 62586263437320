import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import SchoolHandoverForm from "./routes/SchoolHandoverForm";
export default store => {
  return {
    path: "school-handover",
    component: withAsyncRouteLoading(
      () =>
        new Promise(cb => {
          require.ensure(
            [],
            require => {
              const component = require("./SchoolHandover").default;
              const reducer = require("./modules/SchoolHandoverModule").default;
              injectReducer(store, { key: "schoolHandover", reducer });
              cb(component);
            },
            "administrator-tooling"
          );
        })
    ),
    childRoutes: [SchoolHandoverForm(store)],
  };
};
