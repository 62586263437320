import { withAsyncRouteLoading } from "UIComponents";
import RegionalRepository from "./routes/RegionUnitRepo";

export default store => ({
  path: "unit-publishing",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "unit-publishing"
        );
      })
  ),
  childRoutes: [RegionalRepository(store)],
});
