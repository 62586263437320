import gql from "graphql-tag";

export const getGlobalGradesQuery = gql`
  query getGlobalGrades($filters: globalGradesFilter) {
    platform {
      globalGrades(filters: $filters) {
        id
        uid
        name
        curriculum
      }
    }
  }
`;
