import React from "react";
import classes from "./PaginationLoader.scss";
import { Loading } from "UIComponents";

const PaginationLoader = () => {
  return (
    <div className={classes.loadingContainer}>
      <Loading />
    </div>
  );
};

export default PaginationLoader;
