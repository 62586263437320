/*
 This function scrolls the given element into view port.
 element: it is a ref to the element
 scrollAfter: time in ms
 block: align the element vertically in the view port (can be center, start, end or nearest)
 inline: align the element horizontally in the view port (can be center, start, end or nearest)
 checkForElementInViewPort: check if element is in view port or not.
*/

import { isElementInViewPort } from "./IsElementInViewPort";
export const scrollIntoView = ({
  element,
  scrollAfter = 0,
  block = "start",
  inline = "nearest",
  checkForElementInViewPort = false,
}) => {
  if (!element) {
    console.error("please check the element you have passed");
  } else {
    setTimeout(() => {
      if (
        (checkForElementInViewPort && !isElementInViewPort({ element })) ||
        !checkForElementInViewPort
      ) {
        element.scrollIntoView({
          behavior: "smooth",
          block,
          inline,
        });
      }
    }, scrollAfter);
  }
};
