import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import ReportTemplateCreation from "ProgressReportTemplate/routes/ReportTemplateCreation";

export default store => ({
  path: "template-config",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;
            const reducer = require("ProgressReportTemplate/modules/ProgressReportTemplateModule")
              .default;
            injectReducer(store, { key: "progressReportTemplate", reducer });
            cb(component);
          },
          err => {
            reject(err);
          },
          "transcript-chunk"
        );
      })
  ),
  childRoutes: [ReportTemplateCreation(store)],
});
