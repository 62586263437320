import update from "immutability-helper";
import { writeAssessmentFieldFragment } from "./AssessmentGraphqlHelpers";
import { getUnitPlanDetailsFromCache } from "IBPlanner/modules/IBGraphqlHelpers";
import { getRelativePath } from "Utils";
import {
  getUnitPlanResourceLibraryFromCache,
  writeUnitPlanResourceLibraryInCache,
} from "UnitPlans/modules/UnitPlanGraphqlHelpers";
import {
  getOrganizationDetailsFromCache,
  getResourceFeedItemFromCache,
  writeResourceFeedItemFromCache,
} from "modules/CommonGraphqlHelpers";
import {
  updateAssessmentMutation,
  createAssessmentMutation,
  deleteAssessmentMutation,
  clearAssessmentToolMutation,
  updateAssessmentForPlanathonMutation,
} from "./AssessmentMutations";
import {
  goToRelativeRoute,
  getResolvedOptimisticValue,
  openLinkInNewTab,
} from "modules/Services";
import client from "apolloClient";
import {
  setToastMsg,
  goToCommunityHome,
  getUserInfo,
} from "Login/modules/LoginModule";
import { importAssessmentMutation } from "modules/CommonMutations";
import {
  checkCommunityActiveStatusAndGoToOnboarding,
  handleEntityTag,
} from "Community/modules/CommunityModule";
import * as EventTracker from "lib/eventTracker";
import { colors } from "Constants";
import { goToResourceDetails } from "modules/NavigationModule";
import { getChildResourcesFeedQuery } from "OrganizationResources/modules/OrganizationResourcesQuery";

export const NAME = "assessment";

export const SET_CURRENT_STEP = "SET_CURRENT_STEP" + " " + NAME;
export const UPDATE_ASSESSMENT_IN_REDUX =
  "UPDATE_ASSESSMENT_IN_REDUX" + " " + NAME;
export const UPDATE_FILTERS = "UPDATE_FILTERS" + " " + NAME;
export const INIT_FILTERS = "INIT_REPORT_FILTERS" + " " + NAME;

export const setCurrentStep = data => {
  return { type: SET_CURRENT_STEP, data };
};

export const updateAssessmentInRedux = data => {
  return {
    type: UPDATE_ASSESSMENT_IN_REDUX,
    data,
  };
};

export const initFilters = () => {
  return {
    type: INIT_FILTERS,
  };
};

export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};

export const gotoAssessmentDetails = ({
  id,
  mode = "view",
  isOpenInNewTab = true,
  routePostFix = "",
  target,
}) => {
  return async (dispatch, getState) => {
    const activeTab = getState().login.activeTab;
    if (activeTab == "community") {
      const isActiveCommunity = await dispatch(
        checkCommunityActiveStatusAndGoToOnboarding()
      );
      if (!isActiveCommunity) {
        return;
      }
    }

    if (activeTab == "community") {
      isOpenInNewTab = false; //#hack community
    }

    let qs = "";
    if (target) {
      qs = `?target=${target}`;
    }
    const route = `${routePostFix}assessment/${id}/${mode}${qs}`;
    if (isOpenInNewTab) {
      const replacePath =
        activeTab == "community"
          ? ""
          : activeTab == "admin"
          ? `administrator`
          : `courses`;

      const routePath =
        activeTab == "community" ? route : `${replacePath}/${route}`;

      const path = getRelativePath(routePath, `${replacePath}`);
      dispatch(openLinkInNewTab({ url: path }));
    } else {
      dispatch(goToRelativeRoute({ route }));
    }
  };
};

export const onClose = () => {
  return (dispatch, getState) => {
    // This is to identify the old url and the new url
    // The new url looks like '/community/assesment/:assesmentId/view' and has 4 nodes
    // The old url looks like '/community/home/featured/all/assesment/:assesmentId/view' as has atleast 6 nodes
    // The pathname after a split will have an empty string so the magic number here is (4+1)
    const currentPath = window.location.pathname;
    const currentPathNodes = currentPath.split("/");
    if (currentPathNodes.length > 5) {
      dispatch(
        goToRelativeRoute({
          replacePath: "assessment",
          type: "replace",
          route: "./",
        })
      );
    } else {
      dispatch(goToCommunityHome({ isForced: true }));
    }
  };
};

export const onClickEdit = () => {
  return (dispatch, getState) => {
    dispatch(goToRelativeRoute({ route: `../edit`, type: "replace" }));
  };
};

export const clearAssessmentTool = () => {
  return async (dispatch, getState) => {
    await client.mutate({
      mutation: clearAssessmentToolMutation,
      variables: {
        assessmentId: getState().assessment.assessmentData.assessmentId,
        updatedBy: getState().login.userInfo.id,
      },
    });
  };
};

export const updateAssessmentSections = params => {
  return async (dispatch, getState) => {
    await client.mutate({
      mutation: updateAssessmentMutation,
      variables: {
        assessmentId: getState().assessment.assessmentData.assessmentId,
        updatedBy: getState().login.userInfo.id,
        enabledSections: params,
      },
    });
  };
};

export const updateAssessmentInCache = ({
  fieldUID,
  params,
  fieldObj,
  extraParams,
  fieldTemplateObj,
}) => {
  return (dispatch, getState) => {
    const resolvedMinimalTree = getResolvedOptimisticValue({
      resolvedValue: _.get(fieldObj, "resolvedMinimalTree", {}),
      fieldUID,
      fieldTemplateObj,
      params: extraParams,
      value: _.get(params, "value", ""),
    });

    const data = {
      ...fieldObj,
      value: params.value,
      resolvedMinimalTree,
    };

    writeAssessmentFieldFragment({ data, field_id: params.id });
  };
};

export const updateAssessment = params => {
  return (dispatch, getState) => {
    const { portalType, fieldUID, valuesToAdd, valuesToRemove } = params || {};
    const assessmentId = getState().assessment.assessmentData.assessmentId;
    client.mutate({
      mutation: updateAssessmentMutation,
      variables: {
        assessmentId: getState().assessment.assessmentData.assessmentId,
        updatedBy: getState().login.userInfo.id,
        fields: _.omit(params, ["portalType", "fieldUID"]),
        portalType,
      },
    });

    if (fieldUID == "tags") {
      dispatch(
        handleEntityTag({
          entityId: assessmentId,
          entityType: "ASSESSMENT",
          valuesToAdd,
          valuesToRemove,
        })
      );
    }
  };
};

export const updateAssessmentForPlanathon = ({
  assessmentId,
  paritaStarStatus,
  kristenStarStatus,
  juryStarStatus,
  paritaProcessed,
  kristenProcessed,
  juryProcessed,
}) => {
  return async (dispatch, getState) => {
    const assessmentDetails = getResourceFeedItemFromCache({
      resource_id: assessmentId,
      type: "ASSESSMENT",
    });
    try {
      const userId = getState().login.userInfo.id;

      let updateAssessmentDetails = _.cloneDeep(assessmentDetails) || {};

      if (paritaStarStatus != undefined) {
        updateAssessmentDetails = {
          ...updateAssessmentDetails,
          paritaStarStatus,
        };
      }
      if (kristenStarStatus != undefined) {
        updateAssessmentDetails = {
          ...updateAssessmentDetails,
          kristenStarStatus,
        };
      }
      if (juryStarStatus != undefined) {
        updateAssessmentDetails = {
          ...updateAssessmentDetails,
          juryStarStatus,
        };
      }
      if (paritaProcessed != undefined) {
        updateAssessmentDetails = {
          ...updateAssessmentDetails,
          paritaProcessed,
        };
      }
      if (kristenProcessed != undefined) {
        updateAssessmentDetails = {
          ...updateAssessmentDetails,
          kristenProcessed,
        };
      }
      if (juryProcessed != undefined) {
        updateAssessmentDetails = { ...updateAssessmentDetails, juryProcessed };
      }

      if (!_.isEmpty(updateAssessmentDetails)) {
        writeResourceFeedItemFromCache({
          resource_id: assessmentId,
          type: "ASSESSMENT",
          data: updateAssessmentDetails,
        });
      }

      await client.mutate({
        mutation: updateAssessmentForPlanathonMutation,
        variables: {
          assessmentId,
          updatedBy: userId,
          paritaStarStatus,
          kristenStarStatus,
          juryStarStatus,
          paritaProcessed,
          kristenProcessed,
          juryProcessed,
        },
      });
    } catch (e) {
      console.error("error", e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      if (!_.isEmpty(assessmentDetails)) {
        writeResourceFeedItemFromCache({
          resource_id: assessmentId,
          type: "ASSESSMENT",
          data: assessmentDetails,
        });
      }
    }
  };
};

export const importAssessment = ({
  sourceAssessmentId,
  unitId,
  portalType,
  eventTarget,
  title,
  isCopy,
  courseId,
  groupType = "LEARNING_ENGAGEMENT",
  assessmentTypes,
  academicYears = [],
  userInfoPortalType = "PLANNER",
  selectedUnitCurriculumProgramId,
}) => {
  return async (dispatch, getState) => {
    let unitPlanId;
    if (unitId) {
      unitPlanId = unitId;
    } else {
      const unitPlanData = getState().planner.unitPlanData;
      unitPlanId = unitPlanData.unitPlanId;
    }
    const organizationUserInfo = getUserInfo({
      portalType: userInfoPortalType,
    });
    const organizationId = organizationUserInfo.org_id;
    const curriculumProgramId =
      selectedUnitCurriculumProgramId ||
      _.get(getState(), "platform.currentCurriculumProgram.id");

    const filters = getState().assessment.filters;

    const unitPlanResourceDetails = getUnitPlanResourceLibraryFromCache({
      id: unitPlanId,
      filters,
    });
    let fields = undefined;
    if (title) {
      fields = [
        ...(fields || []),
        {
          fieldUid: "title",
          value: title,
        },
      ];
    }
    try {
      const resourceEdges = _.get(
        unitPlanResourceDetails.resourceLibrary,
        "edges",
        []
      );
      const result = await client.mutate({
        mutation: importAssessmentMutation,
        variables: {
          sourceAssessmentId,
          targetUnitPlanId: unitPlanId,
          portalType,
          fields,
          academicYears,
          sourceOrganizationId: organizationId,
          targetCurriculumProgramId: curriculumProgramId,
        },
        update: (
          cache,
          {
            data: {
              planner: { importAssessment },
            },
          }
        ) => {
          const updatedResourceEdges = [
            ...resourceEdges,
            {
              type: groupType,
              node: importAssessment,
              __typename: "ResourceLibraryEdge",
            },
          ];

          const data = {
            ...unitPlanResourceDetails,
            resourceLibrary: {
              ...unitPlanResourceDetails.resourceLibrary,
              assessmentCount: _.get(updatedResourceEdges, "length", 0),
              edges: updatedResourceEdges,
            },
          };

          writeUnitPlanResourceLibraryInCache({
            id: unitPlanId,
            data,
            filters,
          });

          const onToastClick = () => {
            if (!isCopy) {
              dispatch(
                goToResourceDetails({
                  id: _.get(importAssessment, "id", ""),
                  unitPlanId,
                  courseId,
                  type: "assessment",
                  isOpenInNewTab: true,
                })
              );
            }
          };

          dispatch(
            setToastMsg({
              msg: isCopy
                ? "toastMsgs:assessment_successfully_duplicated"
                : "toastMsgs:le_successfully_imported",
              type: "tick",
              position: "toast-bottom-left",
              closeButton: !isCopy,
              onClick: onToastClick,
            })
          );
        },
      });
      if (!!eventTarget && !!unitPlanId) {
        EventTracker.recordEvent({
          eventName: "import le in unit",
          eventData: {
            target: eventTarget,
            entity_id: sourceAssessmentId,
            entity_type: "ASSESSMENT",
            unit_id: unitPlanId,
            source: "community",
            entity_name: title,
          },
        });
      }
      return _.get(result, "data.planner.importAssessment.id", null);
    } catch (e) {
      writeUnitPlanResourceLibraryInCache({
        id: unitPlanId,
        data: unitPlanResourceDetails,
        filters,
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:something_went_wrong",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
      return false;
    }
  };
};

export const cloneAssessment = ({
  sourceAssessmentId,
  portalType,
  academicYears = [],
}) => {
  return async (dispatch, getState) => {
    const organizationId = _.get(getState(), "login.userInfo.org_id", "");
    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id"
    );
    try {
      const clone = await client.mutate({
        mutation: importAssessmentMutation,
        variables: {
          sourceAssessmentId,
          portalType,
          academicYears,
          sourceOrganizationId: organizationId,
          targetCurriculumProgramId: curriculumProgramId,
        },
      });
      return _.get(clone, "data.planner.importAssessment.id");
    } catch (err) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      return false;
    }
  };
};

export const showNotEditableLePlanToast = () => {
  return (dispatch, getState) => {
    dispatch(
      setToastMsg({
        msg: `toastMsgs:no_le_edit_permission`,
        position: "toast-top-center",
        type: "alert",
        fill: colors.yellow50,
      })
    );
  };
};

export const createAssessment = ({
  assessmentType = "le",
  title = "",
  description = "",
  groupType = "LEARNING_ENGAGEMENT",
  unitPlanObj,
  academicYears = [],
} = {}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const { org_id: organizationId } = getUserInfo({ portalType: "PLANNER" });

    const unitPlanId = _.get(
      unitPlanObj,
      "unitPlanId",
      _.get(getState(), "planner.unitPlanData.unitPlanId", "")
    );

    const assessmentData = getState().assessment.assessmentData;
    const unitPlanDetails = getUnitPlanDetailsFromCache(unitPlanId);
    const unitPlanFields = _.get(unitPlanDetails, "allFields", []);

    const filters = getState().assessment.filters;

    const unitType = _.get(
      _.find(unitPlanFields, { uid: "unitType" }),
      "value",
      ""
    );

    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id",
      null
    );
    const unitPlanResourceDetails = getUnitPlanResourceLibraryFromCache({
      id: unitPlanId,
      filters,
    });
    // const unitPlanTemplateId = _.get(unitPlanDetails, "templateId", "");
    // const unitPlanTemplate = _.get(
    //   getPlannerTemplateFromCache(unitPlanTemplateId),
    //   "body",
    //   {}
    // );

    //Fetching all templates from organization
    const plannerTemplates = _.get(
      getOrganizationDetailsFromCache(organizationId),
      "templates",
      []
    );

    //Extract a template of particular curriculum(IB_PYP,IB_MYP), particular type (ASSESSMENT,LE) and assessmentType (le,smt,fmt)
    const templateId = _.get(
      _.find(plannerTemplates, template => {
        if (template.assessmentType) {
          return (
            template.type == groupType &&
            template.assessmentType == assessmentType &&
            _.some(template?.curriculumProgram, { id: curriculumProgramId })
          );
        } else {
          return (
            template.type == groupType &&
            _.some(template?.curriculumProgram, { id: curriculumProgramId })
          );
        }
      }),
      "id",
      null
    );

    const resourceEdges = _.get(
      unitPlanResourceDetails.resourceLibrary,
      "edges",
      []
    );

    const fields = [];

    if (unitType == "ibMYPInterdisciplinary") {
      fields.push({
        uid: "subjects",
        value: _.get(_.find(unitPlanFields, { uid: "subjects" }), "value", []),
      });
    }

    let result = "";
    try {
      await client.mutate({
        mutation: createAssessmentMutation,
        variables: {
          templateId,
          curriculumProgramId,
          fields,
          academicYears,
          type: groupType,
          fixedFields: {
            title: assessmentData.title,
            duration: 0,
            description: assessmentData.description,
            assessmentType: assessmentType,
          },
          unitPlanId: unitPlanId,
          createdBy: userId,
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //   planner: {
        //     __typename: "PlannerMutations",
        //     createAssessment: newAssessment
        //   }
        // },
        update: (
          cache,
          {
            data: {
              planner: { createAssessment },
            },
          }
        ) => {
          result = createAssessment.id;
          const updatedResourceEdges = [
            {
              type: groupType,
              node: createAssessment,
              __typename: "ResourceLibraryEdge",
            },
            ...resourceEdges,
          ];

          const data = {
            ...unitPlanResourceDetails,
            resourceLibrary: {
              ...unitPlanResourceDetails.resourceLibrary,
              assessmentCount: _.get(updatedResourceEdges, "length", 0),
              edges: updatedResourceEdges,
            },
          };

          setTimeout(() =>
            writeUnitPlanResourceLibraryInCache({
              id: unitPlanId,
              filters,
              data,
            })
          );
        },
      });
    } catch (e) {
      setTimeout(() =>
        writeUnitPlanResourceLibraryInCache({
          id: unitPlanId,
          filters,
          data: unitPlanResourceDetails,
        })
      );
    }
    return result;
  };
};

export const deleteAssessment = ({
  assessmentId,
  groupType = "LEARNING_ENGAGEMENT",
  assessmentTypes,
}) => {
  return async (dispatch, getState) => {
    const unitPlanData = getState().planner.unitPlanData;
    const unitPlanId = unitPlanData.unitPlanId;
    const filters = getState().assessment.filters;

    const unitPlanResourceDetails = getUnitPlanResourceLibraryFromCache({
      id: unitPlanId,
      filters,
    });

    const resourceEdges = _.get(
      unitPlanResourceDetails.resourceLibrary,
      "edges",
      []
    );

    const currentFlowSection = _.get(
      getState(),
      "unitFlow.currentSection",
      null
    );

    try {
      await client.mutate({
        mutation: deleteAssessmentMutation,
        variables: {
          id: assessmentId,
        },
        refetchQueries: currentFlowSection
          ? [
              {
                query: getChildResourcesFeedQuery,
                variables: { id: currentFlowSection, searchText: "" },
              },
            ]
          : [],
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            deleteAssessment: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { deleteAssessment },
            },
          }
        ) => {
          if (deleteAssessment) {
            const updatedResourceEdges = _.filter(resourceEdges, resource => {
              return resource.node.id != assessmentId;
            });

            const data = {
              ...unitPlanResourceDetails,
              resourceLibrary: {
                ...unitPlanResourceDetails.resourceLibrary,
                assessmentCount: _.get(updatedResourceEdges, "length", 0),
                edges: updatedResourceEdges,
              },
            };

            setTimeout(() =>
              writeUnitPlanResourceLibraryInCache({
                id: unitPlanId,
                filters,
                data,
              })
            );
          }
        },
      });
    } catch (e) {
      setTimeout(() =>
        writeUnitPlanResourceLibraryInCache({
          id: unitPlanId,
          filters,
          data: unitPlanResourceDetails,
        })
      );
    }
  };
};

export const deleteClonedAssessment = ({ assessmentId, portalType }) => {
  return async (dispatch, getState) => {
    try {
      const result = await client.mutate({
        mutation: deleteAssessmentMutation,
        variables: {
          id: assessmentId,
          portalType,
        },
      });
      return result;
    } catch (err) {
      return false;
    }
  };
};

const REDUCER_HANDLERS = {
  [INIT_FILTERS]: (state, action) => {
    return update(state, {
      filters: { $set: initialState.filters },
    });
  },
  [SET_CURRENT_STEP]: (state, action) => {
    return update(state, { currentStep: { $set: action.data } });
  },
  [UPDATE_ASSESSMENT_IN_REDUX]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        assessmentData: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [UPDATE_FILTERS]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        filters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
};

export const initialState = {
  currentStep: "",
  assessmentData: {
    assessmentId: "",
    templateId: "",
    title: "",
    description: "",
    mode: "edit",
    showAssessmentToolModal: false,
    assessmentToolModalMode: "",
    assessmentToolMode: "edit",
  },
  filters: {
    searchText: "",
    assessmentTypes: [],
    groupType: "LEARNING_ENGAGEMENT",
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
