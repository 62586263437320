import React from "react";

const BookmarkIconSvg = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.19202 10.763L5.89793 10.5455L5.60161 10.7599L2.0739 13.3128C2.07345 13.3131 2.073 13.3134 2.07255 13.3137C1.90181 13.435 1.69523 13.5 1.53509 13.5C1.39182 13.5 1.23447 13.4609 1.05751 13.3622C0.729488 13.146 0.5 12.7221 0.5 12.25V1.75C0.5 0.982584 1.01112 0.5 1.53509 0.5H10.1316C10.6555 0.5 11.1667 0.982584 11.1667 1.75V12.25C11.1667 12.7221 10.9372 13.146 10.6092 13.3622C10.4322 13.4609 10.2748 13.5 10.1316 13.5C9.95034 13.5 9.78779 13.442 9.62756 13.305L9.61412 13.2935L9.59991 13.283L6.19202 10.763Z"
        stroke={props.fill}
      />
    </svg>
  );
};

BookmarkIconSvg.defaultProps = {
  height: 14,
  width: 16,
  fill: "#000000",
};

export default BookmarkIconSvg;
