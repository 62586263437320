import PropTypes from "prop-types";
import React from "react";

const SmallStarIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 15">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Planathon_External-Review-1"
          transform="translate(-665.000000, -367.000000)"
          fill={props.fill}
          stroke={props.fill}
        >
          <path
            d="M673,368.129776 L670.980892,372.220936 L666.466028,372.876984 L669.733014,376.061508 L668.961783,380.558128 L673,378.435112 L677.038217,380.558128 L676.266986,376.061508 L679.533972,372.876984 L675.019108,372.220936 L673,368.129776 Z"
            id="Star"
          ></path>
        </g>
      </g>
    </svg>
  );
};

SmallStarIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

SmallStarIcon.defaultProps = {
  height: 16,
  width: 16,
  fill: "white",
};

export default SmallStarIcon;
