import React from "react";
import classes from "./NodeTreeView.scss";
import NodeTreeRow from "./NodeTreeRow";
import HeaderRow from "./HeaderRow";
import classNames from "classnames";
import { isStrand } from "MultiLevelNodeEditor/utils";
import ACLStore from "lib/aclStore";
import {
  SearchBar,
  NoDataComponent,
  UIButton,
  I18nHOC,
  MultiSelectComponent,
  LinkWithTooltip,
} from "UIComponents";
import { CircularAlertIcon } from "SvgComponents";
import { AddCircleIcon } from "SvgComponents";
import GradeFilter from "../../GradeFilter";
import { colors, fontStyle } from "Constants";
import { BENCHMARK_TYPES } from "MultiLevelNodeEditor/utils";
import { filterOutRootNodes } from "Utils";
import { InsightCountInfo } from "AppComponents";

const noDataStyle = {
  backgroundColor: colors.white,
  borderRadius: 8,
  border: `1px solid  ${colors.strokeOne}`,
};
const SearchInputStyle = {
  customStyle: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 0,
    borderColor: colors.strokeOne,
    paddingLeft: 16,
    paddingRight: 16,
    // height: 48,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

const gradeFilterStyle = {
  filterBoxStyle: {
    height: "48px",
  },
  filterTextStyle: {
    color: colors.gray31,
    ...fontStyle.regular,
  },
  gradeNameTextStyle: {
    color: colors.gray31,
    ...fontStyle.demiBold,
  },
  parentContainerStyle: {
    marginLeft: 24,
  },
};
const overlayStyle = { maxWidth: 180 };
class NodeTreeView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.treeLabelRowsRef = {};

    this.state = {
      showInsightCountInfo: false,
      coveredUnitPlanIds: [],
      plannerElementNodeId: null,
    };
  }

  componentDidMount = () => {
    this.resetSearchBar();
  };

  isLastNodeInDepth = ({ index, nodes, depth, mode, type }) => {
    let isLast = true;
    if (index != nodes.length - 1) {
      for (let i = index + 1; i < nodes.length; i++) {
        if (nodes[i].depth == depth && type == nodes[i].type) {
          isLast = false;
          break;
        }

        if (nodes[i].depth < depth) {
          break;
        }
      }
    }

    return isLast;
  };

  isFirstNodeInDepth = ({ index, nodes, depth, mode, type }) => {
    let isFirst = true;
    if (index != 0) {
      for (let i = index - 1; i >= 0; i--) {
        if (nodes[i].depth == depth && type == nodes[i].type) {
          isFirst = false;
          break;
        }
        if (nodes[i].depth < depth) {
          break;
        }
      }
    }

    return isFirst;
  };

  isShowHeader = ({ index, nodes, framework, depth, type, mode }) => {
    const { rootNode } = this.props;

    if (mode == "view" && _.get(rootNode, "snsChildren.length", 0) <= 1) {
      return false;
    }
    const {
      depth: prevDepth = -1,
      type: prevType = "LEARNING_OUTCOME",
    } = _.get(nodes, index - 1, {});

    return (prevDepth == 1 && depth > 1) || (prevType != type && depth > 1);
  };

  isShowCheckBox = ({
    id,
    isTagging,
    mode,
    depth,
    isLeaf,
    uptoDepth,
    value,
    children,
  }) => {
    // const { depth: prevDepth = -1 } = _.get(nodes, index - 1, {});
    // const { depth: nextDepth = -1 } = _.get(nodes, index + 1, {});

    if (uptoDepth) {
      return isTagging && mode == "view" && depth == uptoDepth;
    }
    return (
      isTagging &&
      mode == "view" &&
      (isLeaf || _.includes(value, id) || _.isEmpty(children))
    ); //nextDepth <= depth;
  };

  onSearchTextChanged = value => {
    const { onFilterChanged } = this.props;
    onFilterChanged({ searchText: value });
  };

  resetSearchBar = () => {
    if (this.searchBar) {
      this.searchBar.cancelText();
    }
  };

  isChecked = ({ id }) => {
    const { taggedNodeIds, isTagging } = this.props;
    return isTagging && _.includes(taggedNodeIds, id);
  };

  renderFilterHeader = () => {
    const {
      filters,
      gradeFilters,
      updateGradeFilters,
      rootOptionsGrades,
      isTagging,
      showInsights,
      mode,
      searchPlaceHolder,
      showGrade,
      t,
    } = this.props;
    if (mode == "edit") {
      return null;
    }

    return (
      <div className={classes.headerContainer}>
        <div className={classes.searchBarContainer}>
          <SearchBar
            placeholder={t(searchPlaceHolder)}
            ref={ref => (this.searchBar = ref)}
            // customStyle={SearchInputStyle.customStyle}
            searchTerm={filters.searchText}
            changeSearchTerm={this.onSearchTextChanged}
            wrapperType={"box"}
          />
        </div>
        {!(isTagging || showInsights || !showGrade) && (
          <GradeFilter
            grades={rootOptionsGrades}
            gradeFilters={gradeFilters}
            updateGradeFilters={updateGradeFilters}
            label={t("common:label_with_colon", {
              label: t("common:grade_plural"),
            })}
            {...gradeFilterStyle}
          />
        )}
      </div>
    );
  };

  updateTreeLabelRowsRef = ({ ref, id }) => {
    this.treeLabelRowsRef[id] = ref;
  };

  focusTreeLabel = ({ id }) => {
    if (this.treeLabelRowsRef[id]) {
      this.treeLabelRowsRef[id].focus();
    }
  };

  addStrand = () => {
    const { updateEditNodeList } = this.props;

    const newObj = updateEditNodeList({
      action: "ADD_STRAND",
    });

    setTimeout(() => {
      if (newObj) {
        this.focusTreeLabel({ id: newObj.id });
      }
    }, 100);
  };

  updateGradeFilters = ({ index, filters, depth, type }) => {
    const { rootOptionsGrades, updateEditNodeList } = this.props;

    const gradeFilters = [];
    const filterValues = filters["gradeFilters"];
    _.forEach(rootOptionsGrades, item => {
      if (_.includes(filterValues, item.id)) {
        gradeFilters.push(item);
      }
    });
    updateEditNodeList({
      index,
      action: "EDIT",
      params: { grades: gradeFilters, depth, type },
    });
  };

  getParentNode = ({ nodes, index, mode, depth, rootNode }) => {
    if (mode == "view") {
      return {};
    }
    const parentNode =
      index == 1
        ? rootNode
        : _.findLast(
            nodes,
            (nodeItem, nodeIndex) =>
              nodeIndex < index && nodeItem.depth <= depth - 1
          );

    return parentNode || {};
  };

  isNotVisible = ({ depth, type }) => {
    const { rootNode } = this.props;
    const snsChildren = _.get(rootNode, "snsChildren", []);
    return (
      depth == 0 ||
      (snsChildren.length > 0 &&
        !_.includes(_.get(rootNode, "snsChildren", []), type))
    );
  };

  renderRowsHeader = () => {
    /**
     * this view is not GENERIC this view is only for one school currently.
     */
    const { isTagging, t } = this.props;

    const rowHeaderClass = classNames({
      [classes.questionDescriptionGradesLabel]: !isTagging,
      [classes.questionDescription]: !!isTagging,
    });
    const standardLabel = classNames({
      [classes.standardLabelCommon]: true,
      [classes.standardLabel1]: !isTagging,
      [classes.standardLabel2]: !!isTagging,
    });

    return (
      <div className={rowHeaderClass}>
        <div />
        <div className={standardLabel}>{t("common:standard_plural")}</div>{" "}
        {!isTagging && (
          <div className={classes.gradesLabel}>{t("common:grade_plural")}</div>
        )}
        <div className={classes.benchmarkWrapper}>
          {" "}
          <div className={classes.questionHeader}>
            {t("academicSetup:benchmarks_question_label")}
          </div>
          <div className={classes.SVGWrapper}>
            <LinkWithTooltip
              tooltip={
                <>
                  <ul className={classes.benchmarkQuestionTooltipContainer}>
                    <li>
                      {t("academicSetup:benchmark_question_tooltip_label1")}
                    </li>
                    <li>
                      {t("academicSetup:benchmark_question_tooltip_label2")}
                    </li>
                  </ul>
                </>
              }
              placement={"bottom"}
              id="tooltip-CreatePostSvg"
              overlayStyle={overlayStyle}
              href="javascript:void(0)"
            >
              <CircularAlertIcon fill={colors.blue29} width={16} height={16} />
            </LinkWithTooltip>
          </div>
        </div>
      </div>
    );
  };
  renderRows = () => {
    const {
      nodes,
      mode,
      startDepth,
      isTagging,
      onCheckBoxClick,
      updateEditNodeList,
      rootNode,
      onActionClick,
      updateNodesRef,
      isCoachMarksRunning,
      theme: { nodeTreeViewListContainerStyle },
      uptoDepth,
      value,
      showInsights,
      insightsData,
      nodeType,
      showGrade,
    } = this.props;
    if (
      _.get(
        _.filter(nodes, node => node.depth > 0),
        "length",
        0
      ) == 0
    ) {
      return null;
    }
    const listContainerStyle = classNames(
      { [classes.listContainer]: true },
      {
        [classes.listViewContainer]: mode == "view",
      }
    );

    const subjectLevelLabels = _.get(rootNode, "subjectLevelLabels", {});
    const isPlannerBenchmarkQuestionsEnable = ACLStore.can(
      "TeacherPortal:PlannerBenchmarkQuestions"
    );
    return (
      <div
        className={listContainerStyle}
        style={nodeTreeViewListContainerStyle}
      >
        {isPlannerBenchmarkQuestionsEnable &&
          nodeType === "BENCHMARK" &&
          this.renderRowsHeader()}
        {_.map(nodes, (item, index) => {
          const {
            id,
            label,
            depth,
            type,
            code,
            isLeaf,
            subText,
            children,
            benchmarkQuestionCount,
          } = item;
          let grades = [];
          let typeDetail = BENCHMARK_TYPES[type];

          grades = _.get(item, "grades", []);

          if (type == "LEARNING_OUTCOME") {
            typeDetail = {
              ...typeDetail,
              label: subjectLevelLabels["loLevelName"],
            };
          } else if (type == "CONCEPTUAL_UNDERSTANDING") {
            typeDetail = {
              ...typeDetail,
              label: subjectLevelLabels["cuLevelName"],
            };
          }

          const isNodeStrand = isStrand({
            depth,
          });
          const tableRowStyle = classNames(
            { [classes.tableRow]: true },
            { [classes.tableRowEdit]: mode == "edit" },
            { [classes.tableRowView]: mode == "view" },
            { [classes.tableRowTag]: isTagging },
            { [classes.tableRowView]: isNodeStrand },
            {
              [classes.tableRowBenchmarkCount]:
                isPlannerBenchmarkQuestionsEnable && !isTagging,
            },
            {
              [classes.tableRowBenchmarkCountTagging]:
                isPlannerBenchmarkQuestionsEnable && isTagging,
            }
          );
          const showHeader = this.isShowHeader({
            nodes,
            index,
            type,
            depth,
            mode,
          });

          const showCheckBox = this.isShowCheckBox({
            id,
            isTagging,
            mode,
            depth,
            nodes,
            index,
            isLeaf,
            uptoDepth,
            value,
            children,
          });

          const isChecked = this.isChecked({ id });

          const parentNode = this.getParentNode({
            index,
            nodes,
            depth,
            rootNode,
          });
          const optionsGrades = _.get(parentNode, "grades", []);
          const isFirstNodeInDepth = this.isFirstNodeInDepth({
            index,
            nodes,
            depth,
            mode,
            type,
          });

          const isLastNodeInDepth = this.isLastNodeInDepth({
            index,
            nodes,
            depth,
            mode,
            type,
          });

          const isNotVisible = this.isNotVisible({ depth, type });

          if (isNotVisible) {
            return null;
          }

          return (
            <React.Fragment key={id}>
              {showHeader ? (
                <HeaderRow
                  mode={mode}
                  containerClass={tableRowStyle}
                  typeDetail={typeDetail}
                  isTagging={isTagging}
                />
              ) : null}
              <NodeTreeRow
                key={`row${id}`}
                subText={subText}
                id={id}
                index={index}
                label={label}
                depth={depth}
                mode={mode}
                type={type}
                startDepth={startDepth}
                containerClass={tableRowStyle}
                isRootNode={_.isEmpty(parentNode)}
                isStrand={isNodeStrand}
                grades={grades}
                showCheckBox={showCheckBox}
                isChecked={isChecked}
                onCheckBoxClick={onCheckBoxClick}
                showBorderTop={!showHeader}
                typeDetail={typeDetail}
                updateEditNodeList={updateEditNodeList}
                updateTreeLabelRowsRef={this.updateTreeLabelRowsRef}
                focusTreeLabel={this.focusTreeLabel}
                isTagging={isTagging}
                showInsights={showInsights}
                insightsData={insightsData}
                code={code}
                updateGradeFilters={this.updateGradeFilters}
                optionsGrades={optionsGrades}
                onActionClick={onActionClick}
                isFirstNodeInDepth={isFirstNodeInDepth}
                isLastNodeInDepth={isLastNodeInDepth}
                updateNodesRef={updateNodesRef}
                isCoachMarksRunning={isCoachMarksRunning}
                showCoveredUnitsModal={this.showCoveredUnitsModal}
                benchmarkQuestionCount={benchmarkQuestionCount}
                nodeType={nodeType}
                isLeaf={isLeaf}
                showGrade={showGrade}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  /*
    If nodeRenderType is multiSelect
  */

  renderMultiSelect = () => {
    const {
      multiSelectConfig,
      taggedNodeIds,
      updateTaggedNodes,
      updateNodesRef,
      showOutline,
    } = this.props;
    const options = this.convertNodesIntoMultiSelectOptions();

    return (
      <div className={classes.multiSelectContainer}>
        <MultiSelectComponent
          options={options}
          mode={"edit"}
          value={taggedNodeIds}
          {...multiSelectConfig}
          isGroupedOptions={showOutline}
          updateInputField={updateTaggedNodes}
          updateNodesRef={updateNodesRef}
        />
      </div>
    );
  };

  /*
    Convert nodes into multiSelectOptions based whether nodes are nested or not
  */

  convertNodesIntoMultiSelectOptions = () => {
    const { showOutline, nodes } = this.props;

    const updatedNodes = _.map(nodes, node => {
      return { ...node, value: node.id };
    });
    const rootNodes = filterOutRootNodes({ nodes: updatedNodes });

    //Fow nested nodes created grouped options
    if (showOutline) {
      return _.map(rootNodes, rootNode => {
        const children = _.filter(updatedNodes, node =>
          _.includes(rootNode.children, node.id)
        );
        return { ...rootNode, children };
      });
    } else {
      return updatedNodes;
    }
  };

  renderEmpty = () => {
    const { nodes, mode } = this.props;
    return mode == "view" &&
      _.get(
        _.filter(nodes, item => item.depth > 0),
        "length",
        0
      ) == 0 ? (
      <NoDataComponent containerStyle={noDataStyle} />
    ) : null;
  };

  renderAddStrandButton = () => {
    const { mode, t } = this.props;
    return mode == "edit" ? (
      <div className={classes.buttoncontainer}>
        <UIButton
          type={"hollow"}
          size="sm"
          color="blue"
          onClick={this.addStrand}
        >
          <div className={classes.buttonIcon}>
            <AddCircleIcon width={16} height={16} />
          </div>

          {t("common:add_with_label", { label: t("academicSetup:strand") })}
        </UIButton>
      </div>
    ) : null;
  };

  showCoveredUnitsModal = ({ unitPlanIds, id }) => {
    this.setState({
      showInsightCountInfo: true,
      coveredUnitPlanIds: unitPlanIds,
      plannerElementNodeId: id,
    });
  };

  closeCoveredUnitsModal = () => {
    this.setState({
      showInsightCountInfo: false,
      coveredUnitPlanIds: [],
      plannerElementNodeId: null,
    });
  };

  render() {
    const {
      mode,
      updateScrollContainerRef,
      handleScroll,
      theme: { viewContainerStyle },
      showSearchHeader,
      nodeRenderType,
      nodeType,
    } = this.props;
    const {
      showInsightCountInfo,
      coveredUnitPlanIds,
      plannerElementNodeId,
    } = this.state;
    const containerStyle = classNames({
      [classes.container]: true,
      [classes.editContainer]: mode == "edit",
    });
    return (
      <div
        className={containerStyle}
        ref={updateScrollContainerRef}
        onScroll={handleScroll}
        style={viewContainerStyle}
      >
        {showSearchHeader && this.renderFilterHeader()}

        {nodeRenderType == "multiSelect"
          ? this.renderMultiSelect()
          : this.renderRows()}

        {this.renderAddStrandButton()}
        {this.renderEmpty()}
        {showInsightCountInfo && (
          <InsightCountInfo
            coveredUnitPlanIds={coveredUnitPlanIds}
            onClose={this.closeCoveredUnitsModal}
            plannerElementNodeId={plannerElementNodeId}
            showModalWithInsightsCount={true}
            insightType={`yearlyInsights`}
          />
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["academicSetup", "common"] })(NodeTreeView);
