import { colors, fontStyle } from "Constants";

const styles = {
  checkbox: {
    height: "24px",
    width: "24px",
    borderRadius: "4px",
  },
  searchCustomStyle: {
    flex: 0.6,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  searchActiveCustomStyle: {
    border: `1px solid ${colors.blue29}`,
  },
  searchFilterContainer: {
    display: "flex",
    flex: 0.4,
    flexDirection: "row",
    borderWidth: "1px",
    borderColor: "#dbdbdb",
    borderStyle: "solid",
    borderLeftWidth: "0px",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fafafa",
    cursor: "default",
  },
  searchFilterText: {
    maxWidth: "130px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
  arrowIcon: {
    marginLeft: "8px",
  },
  searchButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 12px",
  },
  buttonParentStyle: {
    width: "100%",
    justifyContent: "center",
  },
  buttonComponentStyle: {
    justifyContent: "center",
  },
  customTitleStyle: {
    fontFamily: fontStyle.demiBold.fontFamily,
  },
  checkboxItemStyle: {
    height: "24px",
    width: "24px",
    borderRadius: "4px",
  },
  mainHeaderStyles: {
    fontSize: "1.6rem",
    color: colors.teal20,
  },
  nodeSpanStyle: {
    marginRight: "15%",
  },
  mainContentContainerStyle: {
    marginBottom: "0px",
  },
};

export default styles;
