import React, { useState, memo } from "react";
import classes from "./TextInputSetting.scss";
import { FullScreenLoader } from "UIComponents";
import { Button } from "@toddle-design/web";
import EditAPIModal from "./EditAPIModal";
import { setToastMsg } from "Login/modules/LoginModule";
import { connect } from "react-redux";
import APIInputDetails from "./APIInputDetails";
import { openLinkInNewTab } from "modules/Services";
import { SETUP_TURNITIN_API_KEY_HELP_CENTER_URL } from "Constants";

const styles = {
  modalBodyStyle: {
    paddingTop: "0px",
  },
};

const TextInputSetting = props => {
  const {
    t,
    subModule,
    allSettings,
    subSettings,
    onUpdateSetting,
    setToastMsg,
    openLinkInNewTab,
  } = props;

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const turnItInSetting = _.find(
    subSettings,
    setting => (setting.permission = "TurnitinConfig")
  );
  const jsonValue = _.get(turnItInSetting, "jsonValue", {});
  const apiKey = _.get(jsonValue, "api_key", "");
  const apiUrl = _.get(jsonValue, "api_url", "");

  const onClickEdit = () => {
    setShowEditDialog(true);
  };

  const goToTurnitinHelpCenter = () => {
    openLinkInNewTab({
      url: SETUP_TURNITIN_API_KEY_HELP_CENTER_URL,
    });
  };

  const isSubmoduleTextLocalized = _.get(
    subModule,
    "metadata.isTextLocalized",
    true
  );

  const label = _.get(subModule, "label", "");
  const subText = _.get(subModule, "subText", null);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleContainerSubmodule}>
          <div className={classes.leftContainer}>
            <div className={classes.titleSubmodule}>
              {isSubmoduleTextLocalized ? t(`configuration:${label}`) : label}
            </div>
            {subText && (
              <div className={classes.subTitle}>
                {isSubmoduleTextLocalized
                  ? t(`configuration:${subText}`)
                  : subText}
                <span className={classes.bulletSpan}>{"•"}</span>
                <span
                  className={classes.turnitinHelpCenterLink}
                  onClick={goToTurnitinHelpCenter}
                >
                  {t("configuration:need_help_checkout_this_article")}
                </span>
              </div>
            )}
          </div>
          {apiUrl && apiKey && (
            <div className={classes.rightContainer} onClick={onClickEdit}>
              <Button size="medium" variant="outlined-subtle">
                {t("common:edit")}
              </Button>
            </div>
          )}
        </div>
        <div className={classes.settingContainer}>
          <APIInputDetails
            setShowEditDialog={setShowEditDialog}
            subModule={subModule}
            t={t}
            apiKey={apiKey}
            apiUrl={apiUrl}
            onUpdateSetting={onUpdateSetting}
            turnItInSetting={turnItInSetting}
            isSubmoduleTextLocalized={isSubmoduleTextLocalized}
            setIsLoading={setIsLoading}
            setToastMsg={setToastMsg}
            action={"create"}
            modalBodyStyle={styles.modalBodyStyle}
          />
        </div>
      </div>
      {showEditDialog && (
        <EditAPIModal
          setShowEditDialog={setShowEditDialog}
          subModule={subModule}
          t={t}
          apiKey={apiKey}
          apiUrl={apiUrl}
          onUpdateSetting={onUpdateSetting}
          turnItInSetting={turnItInSetting}
          isSubmoduleTextLocalized={isSubmoduleTextLocalized}
          setIsLoading={setIsLoading}
          setToastMsg={setToastMsg}
        />
      )}
      {isLoading && <FullScreenLoader />}
    </>
  );
};

const mapActionCreators = {
  setToastMsg,
  openLinkInNewTab,
};

const TextInputSettingMemoised = memo(TextInputSetting);

export default connect(null, mapActionCreators)(TextInputSettingMemoised);
