import gql from "graphql-tag";
import {
  coursePostFragment,
  parentPostFragment,
  courseStudentFragment,
  journalPostFragment,
  unitPlanFragment,
  resourceFragments,
  studentPostFragment,
  nodePostSelectionFragment,
} from "./PostFragments";

import {
  resolvedFieldValueFragment,
  settingFragment,
} from "modules/CommonFragments";

export const getCoursePostFeedQuery = gql`
  query getCoursePostFeed(
    $id: ID!
    $after: String
    $filters: PostFilterInput
    $translationFilter: TranslatedLabelFilter
    $uids: [String!]
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${coursePostFragment.coursePost}
`;

export const getParentJournalFeedQuery = gql`
  query getParentJournalFeed(
    $id: ID!
    $after: String
    $filters: PostFilterInput
  ) {
    node(id: $id, type: FAMILY_MEMBER) {
      id
      ... on FamilyMember {
        ...parentItem
      }
    }
  }
  ${parentPostFragment.parentPost}
`;

export const getCourseAllStudentQuery = gql`
  query getCourseAllStudent(
    $id: ID!
    $folderIds: [ID!]
    $includeRemovedStudentIds: [ID!]
    $isShowHiddenFromStudents: Boolean
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseStudentFragment.courseStudent}
`;

export const getPostDetailQuery = gql`
  query getPostDetail($id: ID!, $translationFilter: TranslatedLabelFilter) {
    node(id: $id, type: POST) {
      id
      ... on Post {
        ...postItem
      }
    }
  }
  ${journalPostFragment.journalPostDetail}
`;

export const getPostTranslationQuery = gql`
  query getPostTranslation(
    $id: ID!
    $translationFilter: TranslatedLabelFilter
  ) {
    node(id: $id, type: POST) {
      id
      ... on Post {
        ...translationPostItem
      }
    }
  }
  ${journalPostFragment.translationPostItem}
`;

export const getPostSeenLikesDetailsQuery = gql`
  query getPostSeenLikesDetails($id: ID!) {
    node(id: $id, type: POST) {
      ... on Post {
        ...postItem
      }
    }
  }
  ${journalPostFragment.postSeenLikesDetails}
`;
export const getCourseIdForPostQuery = gql`
  query getCourseIdForPost($id: ID!) {
    node(id: $id, type: POST) {
      id
      ... on Post {
        course {
          id
          settings {
            ...settingItem
          }
        }
      }
    }
  }
  ${settingFragment.setting}
`;

export const getCourseFilteredStudentQuery = gql`
  query getCourseFilteredStudent($id: ID!, $filters: CourseFilter) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseItem
      }
    }
  }
  ${courseStudentFragment.courseFiltersStudent}
`;

export const getYearlySchedulerQuery = gql`
  query getYearlyScheduler(
    $id: ID!
    $startDate: String!
    $endDate: String!
    $sortOrder: ORDER_BY_DIRECTION
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        id
        yearlySchedule(
          filters: {
            date: { startDate: $startDate, endDate: $endDate }
            sortOrder: $sortOrder
          }
        ) {
          id
          groupId
          startDate
          endDate
          unitPlan {
            ...unitPlanItem
          }
        }
      }
    }
  }
  ${unitPlanFragment.unitPlanDetailsWithoutFields}
`;

export const getCourseUnitQuery = gql`
  query getCourseUnit(
    $id: ID!
    $filters: UnitPlanFilter
    $groupBy: UNIT_PLAN_FEED_GROUPING_ENUM
  ) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        id

        unitPlans(groupBy: $groupBy, filters: $filters) {
          edges {
            node {
              ...unitPlanItem
            }
          }
        }
      }
    }
  }
  ${unitPlanFragment.unitPlanDetailsWithoutFields}
`;

export const getResourceBucketQuery = gql`
  query getResourceBucketQuery($id: ID!, $type: ENTITY_TYPE_ENUM!) {
    node(id: $id, type: $type) {
      id
      ... on UnitPlan {
        id
        resourceLibrary {
          leCount
          assessmentCount
          edges {
            type
            node {
              ... on Assessment {
                ...assessmentItem
              }
              ... on LearningEngagement {
                ...leItem
              }
            }
          }
        }
      }
    }
  }
  ${resourceFragments.assessmentDetails}
  ${resourceFragments.leDetails}
`;

export const SubjectFragment = {
  SubjectDetail: gql`
    fragment SubjectItem on Subject {
      id
      name
    }
  `,
};

export const BenchmarkFragment = {
  BenchmarkDetail: gql`
    fragment BenchmarkItem on PlannerBenchmark {
      id
      label
      resolvedSubject {
        ...SubjectItem
      }
    }
    ${SubjectFragment.SubjectDetail}
  `,
};

export const ATLFragment = {
  ATLDetail: gql`
    fragment ATLItem on PlannerATL {
      id
      label
    }
  `,
};

export const ConceptFragment = {
  ConceptDetail: gql`
    fragment ConceptItem on PlannerConcept {
      id
      label
    }
  `,
};

export const LPFragment = {
  LPDetail: gql`
    fragment LPItem on PlannerLearnerProfile {
      id
      label
    }
  `,
};

export const ResolvedFieldBenchmarkSetFragment = {
  ResolvedFieldBenchmarkSetDetail: gql`
    fragment ResolvedFieldBenchmarkSetItem on ResolvedFieldBenchmarkSet {
      id
      type
      benchmarks {
        ...BenchmarkItem
      }
    }
    ${BenchmarkFragment.BenchmarkDetail}
  `,
};

export const ResolvedFieldATLSetFragment = {
  ResolvedFieldATLSetDetail: gql`
    fragment ResolvedFieldATLSetItem on ResolvedFieldATLSet {
      id
      type
      atls {
        ...ATLItem
      }
    }
    ${ATLFragment.ATLDetail}
  `,
};

export const ResolvedFieldConceptSetFragment = {
  ResolvedFieldConceptSetDetail: gql`
    fragment ResolvedFieldConceptSetItem on ResolvedFieldConceptSet {
      id
      type
      concepts {
        ...ConceptItem
      }
    }
    ${ConceptFragment.ConceptDetail}
  `,
};

export const ResolvedFieldLPSetFragment = {
  ResolvedFieldLPSetDetail: gql`
    fragment ResolvedFieldLPSetItem on ResolvedFieldLPSet {
      id
      type
      learnerProfiles {
        ...LPItem
      }
    }
    ${LPFragment.LPDetail}
  `,
};

export const ResolvedFieldValueFragment = {
  ResolvedFieldValueDetail: gql`
    fragment ResolvedFieldValueItem on ResolvedFieldValue {
      id

      ... on ResolvedFieldBenchmarkSet {
        ...ResolvedFieldBenchmarkSetItem
      }
      ... on ResolvedFieldATLSet {
        id
        ...ResolvedFieldATLSetItem
      }
      ... on ResolvedFieldConceptSet {
        ...ResolvedFieldConceptSetItem
      }
      ... on ResolvedFieldLPSet {
        ...ResolvedFieldLPSetItem
      }
    }
    ${ResolvedFieldBenchmarkSetFragment.ResolvedFieldBenchmarkSetDetail}
    ${ResolvedFieldATLSetFragment.ResolvedFieldATLSetDetail}
    ${ResolvedFieldConceptSetFragment.ResolvedFieldConceptSetDetail}
    ${ResolvedFieldLPSetFragment.ResolvedFieldLPSetDetail}
  `,
};

export const PlannerFieldFragment = {
  PlannerFieldDetail: gql`
    fragment PlannerFieldItem on PlannerField {
      id
      uid
      value
      resolvedMinimalTree {
        ...resolvedMinimalTreeItem
      }
    }
    ${resolvedFieldValueFragment.resolvedMinimalTree}
  `,
};

export const PlannerSetFieldFragment = {
  PlannerSetFieldDetail: gql`
    fragment PlannerSetFieldItem on PlannerSetField {
      id
      displayName
      set
      templateId
      field {
        ...PlannerFieldItem
      }
    }
    ${PlannerFieldFragment.PlannerFieldDetail}
  `,
};

export const getUnitPlanQuery = gql`
  query getUnitPlan($id: ID!, $uids: [String!]) {
    node(id: $id, type: UNIT_PLAN) {
      ... on UnitPlan {
        id
        templateId
        fields(uids: $uids) {
          ...PlannerFieldItem
        }
      }
    }
  }
  ${PlannerFieldFragment.PlannerFieldDetail}
`;

export const fetchLEQuery = gql`
  query getLE($id: ID!, $uids: [String!]) {
    node(id: $id, type: LEARNING_ENGAGEMENT) {
      ... on LearningEngagement {
        id
        fields(uids: $uids) {
          ...PlannerFieldItem
        }
      }
    }
  }
  ${PlannerFieldFragment.PlannerFieldDetail}
`;

export const fetchAssessmentQuery = gql`
  query getAssessment($id: ID!, $uids: [String!]) {
    node(id: $id, type: ASSESSMENT) {
      ... on Assessment {
        id
        fields(uids: $uids) {
          ...PlannerFieldItem
        }
      }
    }
  }
  ${PlannerFieldFragment.PlannerFieldDetail}
`;

export const getPostOfStudentQuery = gql`
  query getStudentPypTaggedPost(
    $studentId: ID!
    $after: String
    $filters: PostFilterInput
    $uids: [String!]
    $translationFilter: TranslatedLabelFilter
  ) {
    node(id: $studentId, type: STUDENT) {
      id
      ... on Student {
        ...studentPostItem
      }
    }
  }
  ${studentPostFragment.studentPost}
`;

export const getNodePostQuery = gql`
  query getNodePost(
    $id: ID!
    $type: ENTITY_TYPE_ENUM!
    $filters: PostFilterInput
    $after: String
  ) {
    node(id: $id, type: $type) {
      id
      ... on Organization {
        ...organizationPostItem
      }
      ... on Student {
        ...studentPostItem
      }
    }
  }
  ${nodePostSelectionFragment.organizationPost}
  ${nodePostSelectionFragment.studentPost}
`;
