import gql from "graphql-tag";
import {
  attachmentFragment,
  staffFragment,
  studentFragment,
  pypElementRatingFragment,
  coachMarkFragment,
  resourceLibraryFragment,
  teacherFragments,
  zoomMeetingFragment,
  genericFolderFragment,
  attachmentGroupsFragment,
  messageFragment,
  plannerElementNodeFragment,
  plannerElementDepthFragment,
  remarkFragment,
  organizationFragment,
} from "./CommonFragments";

export const addApiEventMutation = gql`
  mutation addApiEvent(
    $eventType: API_EVENT_TYPE_ENUM!
    $onlyOnceInADay: Boolean
  ) {
    platform {
      addApiEvent(eventType: $eventType, onlyOnceInADay: $onlyOnceInADay)
    }
  }
`;
export const bulkUploadByFileMutation = gql`
  mutation bulkUploadByFile(
    $url: String!
    $type: BULK_UPLOAD_TYPE_ENUM!
    $organizationId: ID
    $inputs: JSON
  ) {
    platform {
      bulkUploadByFile(
        input: {
          url: $url
          type: $type
          organizationId: $organizationId
          inputs: $inputs
        }
      ) {
        successful
        errors
      }
    }
  }
`;

export const createPlanUpgradeRequestMutation = gql`
  mutation createPlanUpgradeRequest($screen: String) {
    platform {
      createPlanUpgradeRequest(screen: $screen)
    }
  }
`;

export const updateBasicUserDetailsMutation = gql`
  mutation updateBasicUserDetails(
    $firstName: String
    $lastName: String
    $id: ID!
    $email: String
    $profileImage: String
    $showPitchScreen: Boolean
    $firstLoggedInAt: String
    $showClassSelectionScreen: Boolean
    $showBifurcationScreen: Boolean
  ) {
    platform {
      updateUser(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          profileImage: $profileImage
          email: $email
          firstLoggedInAt: $firstLoggedInAt
          showPitchScreen: $showPitchScreen
          showClassSelectionScreen: $showClassSelectionScreen
          showBifurcationScreen: $showBifurcationScreen
        }
      ) {
        id
        ... on Staff {
          ...staffItem
        }
        ... on Student {
          ...studentItem
        }
      }
    }
  }
  ${studentFragment.basicStudentDetails}
  ${staffFragment.basicStaffDetails}
`;

export const updateUserSettingsMutation = gql`
  mutation updateUserSettings($id: ID!, $settings: [SettingsInput!]) {
    platform {
      updateUser(input: { id: $id, settings: $settings }) {
        id
        settings {
          name
          value
        }
      }
    }
  }
`;

export const updateOrganizationSettingsMutation = gql`
  mutation updateOrganizationSettings(
    $id: ID!
    $settings: [SettingsInput!]
    $isForTools: Boolean
  ) {
    platform {
      updateOrganization(input: { id: $id, settings: $settings }) {
        id
        settings(isForTools: $isForTools) {
          name
          value
        }
      }
    }
  }
`;

export const signS3URLMutation = gql`
  mutation signS3URL(
    $fileMimeType: String!
    $fileGroup: String
    $fileExtension: String
  ) {
    platform {
      signS3URL(
        fileMimeType: $fileMimeType
        fileGroup: $fileGroup
        fileExtension: $fileExtension
      ) {
        uploadURL
        displayURL
        tag
      }
    }
  }
`;

// Mutation to create no-IB subjects in DP
export const createSubjectFromPresetMutation = gql`
  mutation createSubjectFromPreset($input: SubjectVariantsInput!) {
    platform {
      createSubjectFromPreset(input: $input) {
        id
        name
      }
    }
  }
`;

export const markCommentsReadForFieldMutation = gql`
  mutation markCommentsReadForField(
    $parentId: ID!
    $userId: ID!
    $parentType: PARENT_TYPE_ENUM!
  ) {
    platform {
      markCommentsReadForParent(
        input: {
          parents: { id: $parentId, type: $parentType }
          userId: $userId
        }
      )
    }
  }
`;

export const createAttachmentMutation = gql`
  mutation createAttachment(
    $name: String
    $url: String
    $thumbUrl: String
    $mimeType: String
    $type: ATTACHMENT_TYPE_ENUM!
    $parentId: ID
    $createdBy: ID
    $parentType: ATTACHMENT_PARENT_TYPE_ENUM
    $metadata: JSON
  ) {
    platform {
      createAttachment(
        input: {
          url: $url
          mimeType: $mimeType
          type: $type
          thumbUrl: $thumbUrl
          parentId: $parentId
          createdBy: $createdBy
          parentType: $parentType
          name: $name
          metadata: $metadata
        }
      ) {
        ...attachmentItem
      }
    }
  }
  ${attachmentFragment.attachment}
`;

export const deleteAttachmentMutation = gql`
  mutation deleteAttachments($attachmentIds: [ID]!) {
    platform {
      deleteAttachments(attachmentIds: $attachmentIds) {
        isSuccess
      }
    }
  }
`;

export const updateNotificationIsNew = gql`
  mutation updateNotificationIsNew($input: NotificationIsNewUpdateInput!) {
    platform {
      updateNotificationIsNew(input: $input)
    }
  }
`;

export const updateNotificationIsRead = gql`
  mutation updateNotificationIsRead($input: NotificationIsReadUpdateInput!) {
    platform {
      updateNotificationIsRead(input: $input)
    }
  }
`;

export const updateNotificationIsReadForUser = gql`
  mutation updateNotificationIsReadForUser(
    $input: NotificationIsReadForUserUpdateInput!
  ) {
    platform {
      updateNotificationIsReadForUser(input: $input)
    }
  }
`;

export const scrapeUrlMutation = gql`
  mutation scrapeUrl($url: String!) {
    platform {
      scrapeUrl(url: $url) {
        description
        image
        title
        url
        date
        publisher
        author
      }
    }
  }
`;

export const createIBPYPElementRatingsMutation = gql`
  mutation createIBPYPElementRatings(
    $elementRatings: [IBPYPElementRatingInput!]
  ) {
    planner {
      createIBPYPElementRatings(input: $elementRatings) {
        ...ratingItem
      }
    }
  }
  ${pypElementRatingFragment.studentElementRating}
`;

export const updateStudentAssessmentMarksMutation = gql`
  mutation updateStudentAssessmentMarks(
    $elementRatings: [IBPYPElementRatingInput!]
  ) {
    planner {
      createIBPYPElementRatings(input: $elementRatings) {
        id
      }
    }
  }
`;

export const deleteIBPYPElementRatingsMutation = gql`
  mutation deleteIBPYPElementRatings($input: DeleteIBPYPElementRatingsInput!) {
    planner {
      deleteIBPYPElementRatings(input: $input)
    }
  }
`;

export const deleteMultipleIBPYPElementRatingsMutation = gql`
  mutation deleteMultipleIBPYPElementRatings(
    $input: [DeleteIBPYPElementRatingsInput!]
  ) {
    planner {
      deleteMultipleIBPYPElementRatings(input: $input)
    }
  }
`;

export const updateUserCoachMarkStatusMutation = gql`
  mutation updateUserCoachMarkStatus(
    $userId: ID!
    $coachMark: String!
    $isViewed: Boolean!
    $count: Int
  ) {
    platform {
      updateUserCoachMarkStatus(
        input: {
          userId: $userId
          count: $count
          coachMark: $coachMark
          isViewed: $isViewed
        }
      ) {
        ...coachMarkItem
      }
    }
  }
  ${coachMarkFragment.coachMark}
`;

export const createUserPlanathonRequestMutation = gql`
  mutation createUserPlanathonRequest {
    platform {
      createUserPlanathonRequest
    }
  }
`;

export const importAssessmentMutation = gql`
  mutation importAssessment(
    $sourceAssessmentId: ID!
    $targetUnitPlanId: ID
    $fields: [ImportAssessmentFieldInput!]
    $academicYears: [ID!]
    $sourceOrganizationId: ID
    $targetCurriculumProgramId: ID
  ) {
    planner {
      importAssessment(
        input: {
          sourceAssessmentId: $sourceAssessmentId
          targetUnitPlanId: $targetUnitPlanId
          fields: $fields
          academicYears: $academicYears
          sourceOrganizationId: $sourceOrganizationId
          targetCurriculumProgramId: $targetCurriculumProgramId
        }
      ) {
        ...resourceLibraryItem
      }
    }
  }
  ${resourceLibraryFragment.resourceFeed}
`;

export const resetPasswordMutation = gql`
  mutation resetPassword(
    $id: ID!
    $password: String!
    $is_password_updated: Boolean
  ) {
    platform {
      updateUserPasswordData(
        input: {
          id: $id
          password: $password
          is_password_updated: $is_password_updated
        }
      ) {
        ...teacherItem
      }
    }
  }
  ${teacherFragments.teacherFeed}
`;

export const upsertSubjectVariantsMutation = gql`
  mutation upsertSubjectVariants($input: [SubjectVariantsInput!]) {
    platform {
      upsertSubjectVariants(input: $input) {
        id
        name
      }
    }
  }
`;

export const generateZoomRefreshTokenMutation = gql`
  mutation generateZoomRefreshToken(
    $accessCode: String!
    $redirectUri: String!
  ) {
    platform {
      generateZoomRefreshToken(
        input: { accessCode: $accessCode, redirectUri: $redirectUri }
      )
    }
  }
`;

export const createZoomMeetingMutation = gql`
  mutation createZoomMeeting(
    $label: String!
    $parentId: ID!
    $parentType: ZOOM_MEETING_PARENT_TYPE_ENUM!
  ) {
    platform {
      createZoomMeeting(
        input: { label: $label, parentId: $parentId, parentType: $parentType }
      ) {
        warning
        response {
          ...zoomMeetingItem
        }
      }
    }
  }
  ${zoomMeetingFragment.zoomMeeting}
`;

export const deleteZoomMeetingMutation = gql`
  mutation deleteZoomMeeting($id: ID!) {
    platform {
      deleteZoomMeeting(input: { id: $id })
    }
  }
`;

export const createGenericFolderMutation = gql`
  mutation createFolder(
    $courseId: ID!
    $name: String!
    $color: String
    $type: FOLDER_TYPE_ENUM!
  ) {
    platform {
      createFolder(
        input: { courseId: $courseId, name: $name, color: $color, type: $type }
      ) {
        warning
        response {
          ...genericFolderItem
        }
      }
    }
  }
  ${genericFolderFragment.genericFolderBasicDetails}
`;

export const updateGenericFolderMutation = gql`
  mutation updateFolder($id: ID!, $name: String, $color: String) {
    platform {
      updateFolder(input: { id: $id, name: $name, color: $color }) {
        warning
        response {
          ...genericFolderItem
        }
      }
    }
  }
  ${genericFolderFragment.genericFolderBasicDetails}
`;

export const deleteGenericFolderMutation = gql`
  mutation deleteFolder($id: ID!) {
    platform {
      deleteFolder(input: { id: $id })
    }
  }
`;

export const createAttachmentGroupsMutation = gql`
  mutation createAttachmentGroups($input: [AttachmentGroupInput!]) {
    platform {
      createAttachmentGroups(input: $input) {
        ...attachmentGroupItem
      }
    }
  }
  ${attachmentGroupsFragment.attachmentGroup}
`;

export const createAttachmentGroupsMutationV2 = gql`
  mutation createAttachmentGroupsV2($input: [AttachmentGroupInput!]) {
    platform {
      createAttachmentGroupsV2(input: $input) {
        response {
          ...attachmentGroupItem
        }
        warning
      }
    }
  }
  ${attachmentGroupsFragment.attachmentGroup}
`;

export const deleteAttachmentGroupsMutation = gql`
  mutation deleteAttachmentGroups($attachmentGroupIds: [ID!]) {
    platform {
      deleteAttachmentGroups(
        input: { attachmentGroupIds: $attachmentGroupIds }
      ) {
        warning
        isSuccess
      }
    }
  }
`;

export const createMessageMutation = gql`
  mutation createMessage(
    $label: String
    $attachments: [AttachmentInput!]
    $parentId: ID!
    $parentType: MESSAGE_PARENT_TYPE_ENUM
    $taggedUsers: [ID!]
    $subEntityType: MESSAGE_SUB_ENTITY_TYPE_ENUM
    $parentMessageId: ID
    $type: String
  ) {
    platform {
      createMessage: createMessageV2(
        input: {
          label: $label
          itemType: ATTACHMENTS
          subEntityType: $subEntityType
          attachments: $attachments
          parentId: $parentId
          parentType: $parentType
          taggedUsers: $taggedUsers
          parentMessageId: $parentMessageId
          type: $type
        }
      ) {
        ...messageItem
      }
    }
  }
  ${messageFragment.message}
`;

export const createBasicMessageMutation = gql`
  mutation createBasicComment(
    $label: String
    $parentId: ID!
    $parentType: MESSAGE_PARENT_TYPE_ENUM
    $subEntityType: MESSAGE_SUB_ENTITY_TYPE_ENUM
  ) {
    platform {
      createMessage: createMessageV2(
        input: {
          label: $label
          subEntityType: $subEntityType
          parentId: $parentId
          parentType: $parentType
          itemType: POST
        }
      ) {
        ...basicMessageItem
      }
    }
  }
  ${messageFragment.basicMessage}
`;

export const updateMessageMutation = gql`
  mutation updateMessage(
    $id: ID!
    $label: String
    $attachments: [AttachmentInput!]
    $taggedUsers: [ID!]
    $type: String
    $isPin: Boolean
  ) {
    platform {
      updateMessage(
        input: {
          id: $id
          label: $label
          itemType: ATTACHMENTS
          attachments: $attachments
          taggedUsers: $taggedUsers
          type: $type
          isPin: $isPin
        }
      ) {
        ...messageItem
      }
    }
  }
  ${messageFragment.message}
`;

export const deleteMessageMutation = gql`
  mutation deleteMessage($id: ID!) {
    platform {
      deleteMessage(input: { id: $id })
    }
  }
`;

export const sendSigninLinkMutation = gql`
  mutation sendSigninLinkMutation($resourceUrl: String, $action: String) {
    platform {
      sendSignInUrlEmail(input: { resourceUrl: $resourceUrl, action: $action })
    }
  }
`;

export const generateThirdPartyRefreshTokenMutation = gql`
  mutation generateThirdPartyRefreshToken(
    $accessCode: String!
    $redirectUri: String!
    $serviceType: THIRD_PARTY_SERVICE_TYPE!
  ) {
    platform {
      generateThirdPartyRefreshToken(
        input: {
          accessCode: $accessCode
          redirectUri: $redirectUri
          serviceType: $serviceType
        }
      ) {
        response {
          id
          serviceType
          accessToken
        }
      }
    }
  }
`;

export const setConfigurationValueMutation = gql`
  mutation setConfigurationValue($input: ConfigurationInput!) {
    school {
      setConfigurationValue(input: $input)
    }
  }
`;

export const updateOrganizationInfoMutation = gql`
  mutation updateOrganizationInfo($input: OrganizationUpdateInput) {
    platform {
      updateOrganization(input: $input) {
        id
        name
        code
        country
        state
        city
        address
        logo
      }
    }
  }
`;

export const setEnabledClassNotificationMutation = gql`
  mutation setEnabledClassNotification($input: ClassNotificationsInput!) {
    platform {
      setEnabledClassNotification(input: $input)
    }
  }
`;

export const handlePlannerElementNodesMutation = gql`
  mutation handlePlannerElementNodes($input: PlannerElementNodesHandleInput!) {
    planner {
      handlePlannerElementNodes(input: $input)
    }
  }
`;

export const setNotificationConfigurationForCourseMutation = gql`
  mutation setNotificationConfigurationForCourse(
    $input: [NotificationConfigurationInput!]
  ) {
    platform {
      setNotificationConfigurationForCourse(input: $input)
    }
  }
`;

export const createPlannerElementNodesMutation = gql`
  mutation createPlannerElementNodes($input: PlannerElementNodeCreateInput!) {
    planner {
      createPlannerElementNodes(input: $input) {
        ...plannerElementNodeItem
      }
    }
  }
  ${plannerElementNodeFragment.plannerElementNode}
`;

export const updatePlannerElementNodesMutation = gql`
  mutation updatePlannerElementNodes($input: [PlannerElementNodesEditInput!]) {
    planner {
      updatePlannerElementNodes(input: $input) {
        id
      }
    }
  }
`;

export const deletePlannerElementNodesMutation = gql`
  mutation deletePlannerElementNodes($input: PlannerElementNodesDeleteInput!) {
    planner {
      deletePlannerElementNodes(input: $input)
    }
  }
`;

export const handlePlannerElementSetDepthLabelsMutation = gql`
  mutation handlePlannerElementSetDepthLabels(
    $input: PlannerElementSetDepthLabelsHandleInput!
  ) {
    planner {
      handlePlannerElementSetDepthLabels(input: $input) {
        plannerElementDepthLabels {
          ...plannerElementDepthLabelItem
        }
      }
    }
  }
  ${plannerElementDepthFragment.plannerElementDepthLabels}
`;

export const remarkMutation = gql`
  mutation insertRemarkForProject($input: RemarkInput!) {
    planner {
      upsertRemark(input: $input) {
        ...remarkMessage
      }
    }
  }
  ${remarkFragment.remarkMessageFragment}
`;

export const deleteAcademicHonestyRemarkMutation = gql`
  mutation deleteRemark($id: ID!) {
    planner {
      deleteRemark(input: { id: $id })
    }
  }
`;

export const createOrganizationGlobalConstantsMutation = gql`
  mutation upsertOrganizationGlobalConstant(
    $input: [OrganizationConstantInput!]
  ) {
    platform {
      upsertOrganizationGlobalConstant(input: $input) {
        ...organizationGlobalConstantItem
      }
    }
  }
  ${organizationFragment.organizationGlobalConstants}
`;

export const deleteOrganizationGlobalConstant = gql`
  mutation removeOrganizationGlobalConstant($input: DeleteInput!) {
    platform {
      removeOrganizationGlobalConstant(input: $input) {
        isSuccess
      }
    }
  }
`;

export const checkForSimilarityMutation = gql`
  mutation checkForSimilarityMutation($input: GenerateSimilarityReportInput!) {
    integration {
      generateSimilarityReport(input: $input) {
        submissionId
        overallMatchPercentage
        status
        errorCode
      }
    }
  }
`;

export const cancelCheckSimilarityMutation = gql`
  mutation cancelCheckSimilarityMutation($input: CancelSimilarityReportInput!) {
    integration {
      cancelSimilarityReport(input: $input)
    }
  }
`;

export const acceptTurnitinEulaMutation = gql`
  mutation acceptTurnitinEula($input: AcceptTurnitinEulaInput!) {
    integration {
      acceptTurnitinEula(input: $input)
    }
  }
`;

export const deleteIBPYPElementRatingByIdMutation = gql`
  mutation deleteIBPYPElementRatingById($id: ID!) {
    planner {
      deleteIBPYPElementRatingById(id: $id)
    }
  }
`;

export const updateEntityIsReadMutation = gql`
  mutation updateEntityIsRead($id: ID!, $entityType: String!) {
    platform {
      updateEntityIsRead(input: { entityId: $id, entityType: $entityType })
    }
  }
`;

export const convertPdfToWorkbookMutation = gql`
  mutation convertPdfToWorkbook($url: String!) {
    platform {
      convertPdfToWorkbook(url: $url) {
        isSuccess
        warning
        response {
          url
          numOfPages
        }
      }
    }
  }
`;
