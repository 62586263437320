import PropTypes from "prop-types";
import React from "react";

const GoalsIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9361 5.12456C21.5334 6.96665 22.5 9.37044 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C14.6296 1.5 17.0334 2.46661 18.8754 4.0639L20.4697 2.46967C20.7626 2.17678 21.2374 2.17678 21.5303 2.46967C21.8232 2.76256 21.8232 3.23744 21.5303 3.53033L19.9361 5.12456ZM3 12C3 7.02944 7.02944 3 12 3C14.2153 3 16.2436 3.80037 17.8116 5.12771L15.6792 7.26011C14.6632 6.47031 13.3865 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 10.6135 17.5297 9.33678 16.7399 8.32077L18.8723 6.18837C20.1996 7.75638 21 9.78472 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM14.6074 8.33194C13.8718 7.80809 12.9719 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 11.0281 16.1919 10.1282 15.6681 9.3926L13.4492 11.6115C13.4823 11.7354 13.5 11.8656 13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.1344 10.5 12.2646 10.5177 12.3885 10.5508L14.6074 8.33194Z"
        fill="white"
      />
    </svg>
  );
};

GoalsIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

GoalsIcon.defaultProps = {
  height: 20,
  width: 20,
};

export default GoalsIcon;
