import gql from "graphql-tag";
import {
  organizationAssignmentsFragment,
  courseFragment,
} from "./ProgressSummaryFragments";
import { studentSubjectSummaryPypRatingFragment } from "./ProgressSummaryFragments";

export const getOrganizationAssignmentsProgressSummaryQuery = gql`
  query getOrganizationAssignmentsProgressSummary(
    $organizationId: ID!
    $courseIds: [ID!]
    $contentTypes: [ASSIGNMENT_CONTENT_TYPE_ENUM!]
    $isTeacherEvaluationEnabled: Boolean
    $assessmentTypes: [String!]
    $assessmentToolTypes: [ASSESSMENT_EVALUATION_TYPE_ENUM!]
    $isAssessmentScoringEnabled: Boolean
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        ...organizationAssignmentItem
      }
    }
  }
  ${organizationAssignmentsFragment.organizationAssignmentProgressSummary}
`;

export const getCourseDetailsQuery = gql`
  query getCourseDetails($id: ID!) {
    node(id: $id, type: COURSE) {
      id
      ... on Course {
        ...courseDetailsItem
      }
    }
  }
  ${courseFragment.courseDetails}
`;

export const getStudentsPypElementRatingsQuery = gql`
  query getStudentPypEvaluation(
    $studentIds: [ID!]
    $academicCriteriaSetId: ID
    $elementTypes: [IB_PYP_ELEMENT_RATING_TYPE_ENUM!]
    $subjectIds: [ID]
    $parentTypes: [IB_PYP_RATING_PARENT_TYPE!]
  ) {
    multiNode(ids: $studentIds, type: STUDENT) {
      id
      ... on Student {
        id
        ibPYPElementRatings(
          filtersForRecentMost: {
            elementTypes: $elementTypes
            academicCriteriaSetId: $academicCriteriaSetId
            subjectIds: $subjectIds
            parentTypes: $parentTypes
          }
          dataSet: MOST_RECENT
        ) {
          ...ratingItem
        }
      }
    }
  }
  ${studentSubjectSummaryPypRatingFragment.studentElementRating}
`;

export const getAcademicCriteriaSetsQuery = gql`
  query getAcademicCriteriaSets(
    $organizationId: ID!
    $filters: academicCriteriaSetsFilter
  ) {
    node(id: $organizationId, type: ORGANIZATION) {
      id
      ... on Organization {
        constants {
          academicCriteriaSets(filters: $filters) {
            id
            label
            academicCriteriaValues {
              id
              label
              abbreviation
              localGrades {
                id
                grade {
                  id
                }
                label
              }
              boundaryGuidelines
            }
            criteriaType
          }
        }
      }
    }
  }
`;
