import { injectReducer } from "store/reducers";
import { withAsyncRouteLoading } from "UIComponents";
import Levels from "MultiLevelNodeEditor/routes/Levels";

export default store => ({
  path: "scopeandsequence",
  component: withAsyncRouteLoading(
    () =>
      new Promise((resolve, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./component").default;

            const reducer = require("MultiLevelNodeEditor/modules/MultiLevelNodeEditorModule")
              .default;
            const subjectSetupReducer = require("SubjectsSetup/modules/SubjectsModule")
              .default;
            injectReducer(store, {
              key: "subjectsSetup",
              reducer: subjectSetupReducer,
            });
            injectReducer(store, { key: "multiLevelNodeEditor", reducer });
            resolve(component);
          },
          err => {
            reject(err);
          },
          "content-skills"
        );
      })
  ),
  childRoutes: [Levels(store)],
});
