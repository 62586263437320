import React from "react";
import PropTypes from "prop-types";
import classes from "./OneDrivePicker.scss";
// import { GraphFileBrowser } from "@microsoft/file-browser";
const GraphFileBrowser = React.lazy(async () => {
  const { GraphFileBrowser } = await import(
    /* webpackChunkName: "microsoft-file-browser" */ "@microsoft/file-browser"
  );
  return { default: GraphFileBrowser };
});

import { PICKER_DIRECT_DOWNLOADABLE_TYPES } from "Constants";
import request from "superagent";
import {
  generateRandomId,
  getFileType,
  getFileName,
  getImageDimensions,
} from "Utils";
import {
  uploadFile,
  setProgress,
  downloadFromUrl,
  removeProgressElement,
} from "modules/Services";
import { connect } from "react-redux";
import Microsoft from "lib/microsoft";
import { UIModal } from "UIComponents";

class OneDrivePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      isAuthenticated: false,
      user: {},
      error: null,
      showFileBrowser: false,
    };
    if (props.updateRef) {
      props.updateRef(this);
    }
  }

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  onLoginClick = () => {
    this.Login();
  };

  async Login() {
    try {
      await Microsoft.getUserProfile({
        successCallback: accessToken => {
          this.setState({
            accessToken: accessToken,
            showBrowser: true,
          });
        },
        failureCallback: () => {
          this.loginPopup();
        },
      });
    } catch (err) {
      console.error("login(): \n\t", err);
    }
  }

  loginPopup = () => {
    Microsoft.loginPopup({
      successCallback: accessToken => {
        this.setState({
          accessToken: accessToken,
          showBrowser: true,
        });
      },
      failureCallback: () => {
        this.loginPopup();
      },
    });
  };

  onCancel = () => {
    if (!this.mounted) {
      return;
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      showBrowser: false,
    });
  };

  getAuthenticationToken = () => {
    return new Promise(resolve => resolve(this.state.accessToken));
  };

  async getFileInfo({ file: selectedFile, sendObjList, fileCount }) {
    const { accessToken } = this.state;
    this.updateFileCount(1);
    const fileInfoResponse = await request
      .get(`${selectedFile[0]}/drive/items/${selectedFile[2]}`)
      .set({ Authorization: `Bearer ${accessToken}` });

    const fileInfo = fileInfoResponse.body;

    if (!fileInfo) {
      this.updateFileCount(-1, true);
      return;
    }

    const file = {
      downloadUrl: fileInfo["@microsoft.graph.downloadUrl"],
      url: fileInfo.webUrl,
      type: _.get(fileInfo, "file.mimeType", ""),
      size: fileInfo.size,
      name: fileInfo.name,
    };
    this.fileDownloadAndUpload({ file, sendObjList, fileCount });
  }

  async fileDownloadAndUpload({ file, sendObjList, fileCount }) {
    const {
      downloadFromUrl,
      onDropContent,
      parentId,
      parentType,
      onMultipleFilesUploaded,
    } = this.props;
    const isLink = this.shouldExportAsLink(file.type);

    let sendObj = await this.getSendObject(file, isLink);

    const fileUploadId = generateRandomId();
    if (onDropContent) {
      //here we have either thumburl or link url(actual file url) as file url
      onDropContent({ ...sendObj, id: fileUploadId });
    }

    let startProgress = 50;
    if (!isLink) {
      //file is not of type link, i.e may be exportAsPdf is true or it is inside the allowed mmimetype
      //starting the download of file from one drive.
      downloadFromUrl({
        downloadUrl: file.downloadUrl,
        size: file.size,
        name: file.name,
        fileUploadId: fileUploadId,
        parentType: this.props.parentType,
        parentId: this.props.parentId,
        attachment: sendObj,
        onContentLoadFailed: () => {
          this.updateFileCount(-1, true);
        },
        onLoaded: async fileBlob => {
          if (!!fileBlob) {
            fileBlob.name = _.get(file, "name", "Untitled");
            if (!fileBlob.type) {
              fileBlob.mimeType = _.get(file, "mimeType");
            }
          }
          const sendObj = this.onFileDownloadedFromDrive({
            file: fileBlob,
            uploadId: fileUploadId,
            startProgress: startProgress,
          });
          sendObjList.push(sendObj);

          // Sends all files' sendObj together as an array
          if (_.size(sendObjList) == fileCount && onMultipleFilesUploaded) {
            const values = _.filter(
              await Promise.all(sendObjList),
              urlObj => urlObj
            );

            onMultipleFilesUploaded(values);
          }
        },
      });
    } else {
      if (this.props.onContentLoaded) {
        this.props.onContentLoaded(sendObj);
      }
      // we have a link of file
      if (this.props.onContentUploaded) {
        this.props.onContentUploaded(sendObj);
      }
      if (this.props.onSingleContentUploaded) {
        this.props.onSingleContentUploaded(sendObj, fileUploadId, {
          parentId,
          parentType,
        });
      }
      this.updateFileCount(-1);
    }
  }

  onSuccess = keys => {
    const { multiselect } = this.props;
    this.onCancel();
    const sendObjList = [];
    const fileCount = _.size(keys);
    if (multiselect) {
      _.forEach(keys, item => {
        const file = item["driveItem_203"];
        if (file) {
          this.getFileInfo({ file, sendObjList, fileCount });
        }
      });
    } else {
      const item = keys[0];
      const file = item["driveItem_203"];

      if (file) {
        this.getFileInfo({ file, sendObjList, fileCount });
      }
    }
  };

  updateFileCount(count, isError) {
    if (this.props.updateFilesCount) {
      this.props.updateFilesCount(count, isError);
    }
  }

  shouldExportAsLink = mimeType => {
    if (_.includes(PICKER_DIRECT_DOWNLOADABLE_TYPES, mimeType)) {
      return false;
    }
    return !this.props.exportAsPdf;
  };

  getSendObject = async (file, isLink) => {
    const mimeType = file.mimeType ? file.mimeType : file.type;
    const url = isLink ? file.url : file.downloadUrl;
    const attachmentType = this.shouldExportAsLink(mimeType)
      ? "LINK"
      : getFileType(mimeType).text;
    const dimensions = await getImageDimensions({ url, type: attachmentType });

    return {
      type: attachmentType,
      url: url,
      name: getFileName(file.name),
      mimeType: mimeType,
      metadata: { size: _.get(file, "size", 0), ...dimensions },
    };
  };

  async onFileDownloadedFromDrive({ file, uploadId, startProgress }) {
    const {
      onContentLoaded,
      onFailedAllowedMimeType,
      parentType,
      parentId,
      shouldUpload,
      portalType,
    } = this.props;

    if (onContentLoaded) {
      //handle your own upload
      const url = URL.createObjectURL(file);

      const updateFile = file;
      file["downloadUrl"] = url;
      const sendObj = await this.getSendObject(updateFile);
      onContentLoaded({ ...sendObj, file, url });
      this.props.removeProgressElement({ id: uploadId });
      this.updateFileCount(-1);
    } else {
      if (shouldUpload) {
        // uploading asset to our server. only when file has allowed mimetype

        return new Promise(async resolve => {
          let sendObj = await this.getSendObject({
            ...file,
            size: file.size,
            type: file.type,
          });
          let uploadStartTime = Date.now();
          const { promise } = this.props.uploadFile({
            file,
            uploadId,
            startProgress,
            parentType,
            parentId,
            attachment: sendObj,
            portalType,
          });

          promise
            .then(async item => {
              let sendObj = await this.getSendObject({
                ...file,
                type: file.type,
                size: file.size,
                downloadUrl: item,
              });
              let uploadTime = Date.now() - uploadStartTime;
              const metadata = _.get(sendObj, "metadata", {});
              const updatedMetadata = { ...metadata, uploadTime };
              sendObj = { ...sendObj, metadata: updatedMetadata };
              if (this.props.onContentUploaded) {
                await this.props.onContentUploaded(sendObj);
              }
              if (this.props.onSingleContentUploaded) {
                await this.props.onSingleContentUploaded(sendObj, uploadId, {
                  parentId,
                  parentType,
                });
              }
              this.props.removeProgressElement({ id: uploadId });
              this.updateFileCount(-1);
              resolve(sendObj);
            })
            .catch(e => {
              this.updateFileCount(-1, true);
            });
        });
      }
    }
  }
  render() {
    const { showBrowser } = this.state;

    if (!showBrowser) {
      return null;
    }
    const { multiselect } = this.props;
    return (
      <UIModal
        isOpen={true}
        modalContent={classes.modalContent}
        onRequestClose={this.onCancel}
      >
        <GraphFileBrowser
          getAuthenticationToken={this.getAuthenticationToken}
          onSuccess={this.onSuccess}
          onCancel={this.onCancel}
          selectionMode={multiselect ? "multiple" : "single"}
        />
      </UIModal>
    );
  }
}

OneDrivePicker.propTypes = {
  onContentLoaded: PropTypes.func,
  onContentUploaded: PropTypes.func,
  onContentUploadProgress: PropTypes.func,
  updateFilesCount: PropTypes.func,
  parentId: PropTypes.string,
  parentType: PropTypes.string,
  multiselect: PropTypes.bool,
  exportAsPdf: PropTypes.bool,
};

OneDrivePicker.defaultProps = {
  parentType: "",
  parentId: null,
  multiselect: true,
  exportAsPdf: false,
  shouldUpload: true,
};

const mapActionCreators = {
  uploadFile,
  setProgress,
  downloadFromUrl,
  removeProgressElement,
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, mapActionCreators)(OneDrivePicker);
