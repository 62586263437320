import React, { useState, Fragment, useRef, useEffect } from "react";
import classes from "./GlobalSearchBar.scss";
import { UIModal, I18nHOC } from "UIComponents";
import { withPermission } from "lib/PermissionAware";
import { withRouter } from "react-router";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { useKey } from "react-use";
import classNames from "classnames";
import SearchInput from "./SearchInput";
import Header from "./Header";
import RecentSearch from "./RecentSearch";
import SearchResultsComp from "./SearchResult";
import ModulesSection from "./ModulesSection";
import Suggestions from "./Suggestions";
import Footer from "./Footer";
import _findIndex from "lodash/findIndex";
import {
  typesConfig,
  getModulesFiltersList,
  changeLocalStorageData,
  getRecentSearchItems,
} from "./Utils";
import { navigate } from "modules/NavigationModule";
import {
  updateGlobalSearchText,
  toggleVoiceSearch,
  toggleGlobalSearchBarModal,
  updateGlobalSearchModuleFilter,
} from "modules/Services";
import * as EventTracker from "lib/eventTracker";

const GlobalSearchBar = props => {
  const {
    showSearchBar,
    toggleGlobalSearchBarModal,
    enableVoiceSearch,
    toggleVoiceSearch,
    userId,
    navigate,
    searchText,
    module,
    updateGlobalSearchText,
    t,
  } = props;

  const [searchState, setSearchState] = useState({
    cursor: "search",
    mousePosition: null,
  });

  const searchInputRef = useRef();

  const { cursor, mousePosition } = searchState;

  const recentSearchResults = getRecentSearchItems({ userId, module, t });
  const modulesList = getModulesFiltersList({ t });

  const setInputFocus = () => {
    searchInputRef.current.focus();
  };

  const onSearchInputChange = (value = "") => {
    updateGlobalSearchText(value);

    EventTracker.recordEvent({
      eventName: "GLOBAL_SEARCH",
      eventData: {
        searchText: value,
        event_type: "SEARCH",
      },
    });
  };

  const setCursor = cursor => {
    setSearchState({ ...searchState, cursor });
  };

  const setMousePosition = val => {
    setSearchState(prevState => {
      return {
        ...prevState,
        mousePosition: val,
      };
    });
  };

  const onContainerMouseMove = event => {
    const { pageX, pageY } = event;
    const { mousePosition } = searchState;
    if (mousePosition == null) {
      const value = `${pageX}-${pageY}`;
      setMousePosition(value);
    }
  };

  const getComputedCursor = () => {
    return recentSearchResults[0]?.id ? recentSearchResults[0].id : "search";
  };

  const onSelectModule = ({ type }) => {
    const { updateGlobalSearchModuleFilter } = props;

    updateGlobalSearchModuleFilter(type);

    setSearchState({
      ...searchState,
      cursor: getComputedCursor(),
    });
    setInputFocus();
  };

  const onUnselectModule = () => {
    const { updateGlobalSearchModuleFilter } = props;

    updateGlobalSearchModuleFilter(null);

    setSearchState({
      ...searchState,
      cursor: getComputedCursor(),
    });
    setInputFocus();
  };

  const onSuggestionClick = ({ item }) => {
    const type = item.type;
    const params = {
      ...item,
      type,
    };
    let shouldNavigate = true;
    const routerParams = props.router.params;

    EventTracker.recordEvent({
      eventName: "GLOBAL_SEARCH",
      eventData: {
        clicked_item: type,
        searchText,
        filter_module: module,
      },
    });

    /**
     * @description unit plan details tab data doesn't render
     * when same unit plan is selected again.
     */
    if (
      routerParams?.course_id == item?.metadata?.courseId &&
      routerParams?.unit_id == item?.metadata?.unitPlanId &&
      item.type == typesConfig.UNIT_PLAN.id
    ) {
      shouldNavigate = false;
    }

    toggleGlobalSearchBarModal();
    //navigate to that particular module
    if (shouldNavigate) {
      navigate({ params });
    }
    //change in local storage data
    changeLocalStorageData({ data: params, userId });
  };

  const onCursorFocus = ({ id }) => {
    if (mousePosition == null) {
      return;
    }
    setCursor(id);
  };

  /**
   * @todo refactor with cursor array
   */
  const handleArrowUp = () => {
    const recentCursorIndex = _findIndex(recentSearchResults, { id: cursor });
    const recentSearchCursorItem = recentSearchResults[recentCursorIndex];
    const lastRecentSearchItem =
      recentSearchResults[recentSearchResults.length - 1];

    const moduleCursorIndex = _findIndex(modulesList, { id: cursor });
    const moduleCursorItem = modulesList[moduleCursorIndex];
    const lastModuleItem = modulesList[modulesList.length - 1];

    if (cursor == "search" && lastRecentSearchItem) {
      const updatedCursor = lastRecentSearchItem.id;
      setCursor(updatedCursor);
      return;
    }

    if (cursor == "search" && lastModuleItem && !module) {
      const updatedCursor = lastModuleItem.id;
      setCursor(updatedCursor);
      return;
    }

    if (moduleCursorItem && modulesList[moduleCursorIndex - 1] && !module) {
      const updatedCursor = modulesList[moduleCursorIndex - 1].id;
      setCursor(updatedCursor);
      return;
    }

    if (moduleCursorIndex == 0 && lastRecentSearchItem && !module) {
      const updatedCursor = lastRecentSearchItem.id;
      setCursor(updatedCursor);
      return;
    }

    if (moduleCursorIndex == 0 && !lastRecentSearchItem && !module) {
      const updatedCursor = lastModuleItem.id;
      setCursor(updatedCursor);
      return;
    }

    if (recentSearchCursorItem && recentSearchResults[recentCursorIndex - 1]) {
      const updatedCursor = recentSearchResults[recentCursorIndex - 1].id;
      setCursor(updatedCursor);
      return;
    }

    if (
      recentSearchCursorItem &&
      !recentSearchResults[recentCursorIndex - 1] &&
      module
    ) {
      const updatedCursor = lastRecentSearchItem.id;
      setCursor(updatedCursor);
      return;
    }

    if (recentCursorIndex == 0 && lastModuleItem && !module) {
      const updatedCursor = lastModuleItem.id;
      setCursor(updatedCursor);
      return;
    }
  };

  /**
   * @todo refactor with cursor array
   */
  const handleArrowDown = () => {
    const moduleCursorIndex = _findIndex(modulesList, { id: cursor });
    const moduleCursorItem = modulesList[moduleCursorIndex];

    const recentCursorIndex = _findIndex(recentSearchResults, { id: cursor });
    const recentSearchCursorItem = recentSearchResults[recentCursorIndex];

    if (cursor == "search" && !module) {
      const updatedCursor = modulesList[0]?.id;
      setCursor(updatedCursor);
      return;
    }

    if (cursor == "search" && module && recentSearchResults[0]) {
      const updatedCursor = recentSearchResults[0]?.id;
      setCursor(updatedCursor);
      return;
    }

    if (moduleCursorItem && modulesList[moduleCursorIndex + 1] && !module) {
      const updatedCursor = modulesList[moduleCursorIndex + 1].id;
      setCursor(updatedCursor);
      return;
    }

    if (!recentSearchResults.length && !module) {
      const updatedCursor = modulesList[0]?.id;
      setCursor(updatedCursor);
      return;
    }

    if (!recentSearchCursorItem && moduleCursorItem) {
      const updatedCursor = recentSearchResults[0].id;
      setCursor(updatedCursor);
      return;
    }

    if (recentSearchCursorItem && recentSearchResults[recentCursorIndex + 1]) {
      const updatedCursor = recentSearchResults[recentCursorIndex + 1].id;
      setCursor(updatedCursor);
      return;
    }

    if (
      recentSearchCursorItem &&
      !recentSearchResults[recentCursorIndex + 1] &&
      module
    ) {
      const updatedCursor = recentSearchResults[0].id;
      setCursor(updatedCursor);
      return;
    }

    if (modulesList[0] && !module) {
      const updatedCursor = modulesList[0]?.id;
      setCursor(updatedCursor);
      return;
    }
  };

  const handleEnterKey = e => {
    const moduleCursorIndex = _findIndex(modulesList, { id: cursor });
    const moduleCursorItem = modulesList[moduleCursorIndex];

    if (moduleCursorItem) {
      onSelectModule({ type: moduleCursorItem.type });
      e.stopPropagation();
      return;
    }

    const recentCursorIndex = _findIndex(recentSearchResults, { id: cursor });
    const recentSearchCursorItem = recentSearchResults[recentCursorIndex];

    if (recentSearchCursorItem) {
      onSuggestionClick({ item: recentSearchCursorItem });
      e.stopPropagation();
      return;
    }
  };

  /**
   *
   * @description handles key up/down/enter events
   * for modules list and recent search list only
   * - that is our init state
   */
  const handleInitStateCursor = e => {
    if (searchText) {
      return;
    }
    const { key } = e;
    if (key == "ArrowUp") {
      handleArrowUp();
    } else if (key == "ArrowDown") {
      handleArrowDown();
    } else if (key == "Enter") {
      handleEnterKey(e);
    }
  };

  const onKeyDown = e => {
    handleInitStateCursor(e);
  };

  useEffect(() => {
    setCursor(getComputedCursor());
  }, [module]);

  const setContainerFocus = event => {
    setMousePosition(null);
    setInputFocus();
  };

  useKey(
    event => {
      return ["ArrowUp", "ArrowDown"].includes(event.key);
    },
    setContainerFocus,
    {},
    []
  );

  if (!showSearchBar) {
    return null;
  }

  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      closeOnOutSideClick={true}
      onRequestClose={toggleGlobalSearchBarModal}
    >
      <div
        className={classNames({
          [classes.container]: true,
          [classes.useKeyboard]: mousePosition == null,
        })}
        onKeyDown={onKeyDown}
        onMouseMove={onContainerMouseMove}
      >
        <Header />
        <SearchInput
          onChange={onSearchInputChange}
          module={module}
          onUnselectModule={onUnselectModule}
          enableVoiceSearch={enableVoiceSearch}
          toggleVoiceSearch={toggleVoiceSearch}
          ref={searchInputRef}
          setInputFocus={setInputFocus}
          searchText={searchText}
        />
        {/* <ModulesSection /> */}
        {/* <RecentSearch /> */}
        {!searchText ? (
          <Fragment>
            {!module ? (
              <React.Fragment>
                <ModulesSection
                  data={modulesList}
                  onSelectModule={onSelectModule}
                  onCursorFocus={onCursorFocus}
                  cursor={cursor}
                />

                <RecentSearch
                  data={recentSearchResults}
                  module={module}
                  cursor={cursor}
                  onSuggestionClick={onSuggestionClick}
                  onCursorFocus={onCursorFocus}
                />
              </React.Fragment>
            ) : _.size(recentSearchResults) ? (
              <RecentSearch
                data={recentSearchResults}
                module={module}
                cursor={cursor}
                onSuggestionClick={onSuggestionClick}
                onCursorFocus={onCursorFocus}
              />
            ) : (
              <Suggestions
                searchState={searchState}
                module={module}
                onSuggestionClick={onSuggestionClick}
              />
            )}
          </Fragment>
        ) : null}

        {searchText ? (
          <SearchResultsComp
            searchState={searchState}
            onSuggestionClick={onSuggestionClick}
            term={searchText}
            module={module}
          />
        ) : null}
      </div>
      <Footer />
    </UIModal>
  );
};

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;
  const userId = state.login.userInfo.id;
  const searchText = _.get(
    state,
    "app_services.globalSearchBar.searchText",
    ""
  );

  const showSearchBar = _.get(
    state,
    "app_services.globalSearchBar.showSearchBar",
    false
  );
  const enableVoiceSearch = _.get(
    state,
    "app_services.globalSearchBar.enableVoiceSearch",
    false
  );

  const module = _.get(state, "app_services.globalSearchBar.module", null);

  return {
    perm: ["TeacherPortal:GlobalSearchBar", "StudentPortal:GlobalSearchBar"],
    errorText: "Do not have permission to see this page",
    organizationId,
    userId,
    searchText,
    showSearchBar,
    enableVoiceSearch,
    module,
  };
};

const mapActionCreators = {
  navigate,
  updateGlobalSearchText,
  toggleVoiceSearch,
  toggleGlobalSearchBarModal,
  updateGlobalSearchModuleFilter,
};

export default compose(
  withRouter,
  I18nHOC({
    resource: [
      "common",
      "teacherHomePage",
      "classRoom",
      "configuration",
      "attendance",
      "integration",
    ],
  }),
  connect(mapStateToProps, mapActionCreators),
  withPermission
)(GlobalSearchBar);
