import PrivateComponent from "./PrivateComponent";
import React from "react";
import { getHOCDisplayName } from "Utils";

const PrivateScreenHoc = () => {
  return WrappedComponent => {
    class WithPrivateScreen extends React.PureComponent {
      render() {
        const { forwardedRef, ...rest } = this.props;
        return (
          <PrivateComponent isScreen={true}>
            <WrappedComponent ref={forwardedRef} {...rest} />
          </PrivateComponent>
        );
      }
    }

    WithPrivateScreen.displayName = `WithPrivateScreen(${getHOCDisplayName(
      WrappedComponent
    )})`;

    return React.forwardRef(function WithPrivateScreenForwardRef(props, ref) {
      return <WithPrivateScreen {...props} forwardedRef={ref} />;
    });
  };
};

export default PrivateScreenHoc;
