import React from "react";
import _ from "lodash";
import FeatureCard from "./FeatureCard";
const Features = React.memo(props => {
  const { features, photoOnLeftType } = props;
  return _.map(features, (feature, index) => {
    const isEven = (index + 1) % 2 == 0;
    let photoOnLeft = true;
    if (isEven) {
      photoOnLeft = photoOnLeftType == "even";
    } else {
      photoOnLeft = photoOnLeftType == "odd";
    }

    return (
      <FeatureCard
        feature={feature}
        key={index}
        photoOnLeft={photoOnLeft}
      ></FeatureCard>
    );
  });
});

export default Features;
