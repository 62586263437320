import React from "react";

import classes from "./UnitList.scss";
import { colors } from "Constants";
import { getSubjectLabel, getElementValue } from "modules/Services";
import { getRethumbUrl } from "Utils";
import { Tick, GoalsIcon } from "SvgComponents";
import { I18nHOC, ResourceImagePlaceHolder } from "UIComponents";
import classNames from "classnames";
import { EmptyState } from "@toddle-design/web";
import { NoUnitFoundIllustration } from "@toddle-design/theme";

const getRethumb = imageUrl => {
  const rethumpUrl = imageUrl
    ? getRethumbUrl({
        width: 128,
        height: 128,
        imageUrl: imageUrl,
        fitIn: false,
      })
    : "";
  return `url(${rethumpUrl})`;
};

const styles = {
  imagePlaceholder: {
    height: "64px",
    width: "80px",
    flex: "inherit",
  },
};

const emptyStateSubtitleStyle = {
  lineHeight: "24px",
};

class UnitList extends React.PureComponent {
  renderUnits = () => {
    const {
      props: {
        unitPlans,
        onItemClicked,
        selectedUnitId,
        t,
        curriculumProgramType,
      },
    } = this;

    return _.get(unitPlans, "length", 0) > 0 ? (
      _.map(unitPlans, unit => {
        const planDetailStrings = [];
        const leCount = _.get(unit, "resourceLibrary.leCount", 0);
        const assessmentCount = _.get(
          unit,
          "resourceLibrary.assessmentCount",
          0
        );

        if (leCount > 0) {
          const leCountString = t("common:le_with_count", {
            count: leCount,
          });
          planDetailStrings.push(leCountString);
        }

        if (assessmentCount > 0) {
          const assessmentCountString = t("common:assessment_with_count", {
            count: assessmentCount,
          });
          planDetailStrings.push(assessmentCountString);
        }

        const isSelected = unit.id == selectedUnitId;

        const unitContainerClass = classNames({
          [classes.unitContainerSelected]: isSelected,
          [classes.unitContainer]: true,
        });

        return (
          <div
            onClick={() => onItemClicked(unit)}
            className={unitContainerClass}
            key={unit.id}
          >
            <div className={classes.tickIconContainer}>
              {isSelected ? (
                <Tick width={20} height={20} fillBackground={colors.blue29} />
              ) : (
                <div className={classes.emptyTick} />
              )}
            </div>
            <ResourceImagePlaceHolder
              id={unit.id}
              imageUrl={_.get(unit, "image.value", "")}
              imageThumbSize={128}
              containerStyle={styles.imagePlaceholder}
            />
            <div className={classes.unitContentContainer}>
              <div className={classes.unitTitle}>
                {_.get(unit, "title.value")}
              </div>
              <div className={classes.themeOrSubject}>
                {_.get(unit, "unitType.value", "") === "ibInquiry"
                  ? getElementValue({
                      fieldUID: "theme",
                      valueKeys: _.get(unit, "theme.value", ""),
                    })
                  : _.join(
                      getSubjectLabel(_.get(unit, "subjects.value", "")),
                      ","
                    )}
              </div>
              <div
                style={{ marginTop: "4px" }}
                className={classes.planDetailTextContainer}
              >
                {_.map(planDetailStrings, (item, index) => {
                  return [
                    <div
                      className={classes.planDetailText}
                      key={`Text${index}`}
                    >
                      {item}
                    </div>,
                    index < planDetailStrings.length - 1 && (
                      <div className={classes.dot} key={`Dot${index}`} />
                    ),
                  ];
                })}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className={classes.noDataContainer}>
        <EmptyState
          illustration={NoUnitFoundIllustration}
          size="small"
          title={
            curriculumProgramType == "IB_PYP"
              ? t("journal:no_unit_scheduled")
              : t("journal:no_units")
          }
          subtitle={t("journal:create_unit_and_learning_goals")}
          subtitleStyle={emptyStateSubtitleStyle}
        />
      </div>
    );
  };

  render() {
    const { t, onSelectFromAllPYP, selectFromAllPYP } = this.props;

    const showSelectAllOption = true;
    const unitContainerClass = classNames({
      [classes.unitContainerSelected]: selectFromAllPYP,
      [classes.unitContainer]: true,
    });

    return (
      <div className={classes.listContainer}>
        {showSelectAllOption && (
          <>
            <div onClick={onSelectFromAllPYP} className={unitContainerClass}>
              <div className={classes.tickIconContainer}>
                {selectFromAllPYP ? (
                  <Tick width={20} height={20} fillBackground={colors.blue29} />
                ) : (
                  <div className={classes.emptyTick} />
                )}
              </div>
              <div className={classes.goalsSvgContainer}>
                <GoalsIcon width={20} height={20} />
              </div>
              <div className={classes.unitContentContainer}>
                <div className={classes.unitTitle}>
                  {t("journal:select_from_all_learning_goals")}
                </div>
              </div>
            </div>

            <div className={classes.orContainer}>
              <div className={classes.orContentLine} />
              <div className={classes.orText}>
                {t("common:uppercase", { text: t("common:or") })}
              </div>
              <div className={classes.orContentLine} />
            </div>

            <div className={classes.selectUnitTitle}>
              {t("journal:select_learning_goals_from_unit")}
            </div>
          </>
        )}
        <div className={classes.listContent}>{this.renderUnits()}</div>
      </div>
    );
  }
}

export default I18nHOC({ resource: ["common", "journal"] })(UnitList);
