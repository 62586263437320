import React from "react";
import PropTypes from "prop-types";
import classes from "./Collaborator.scss";
import { connect } from "react-redux";
import SelectInput from "./SelectInput";
import CollaboratorList from "./CollaboratorList";
import { createOrganizationInvitedUser } from "TeacherSetup/modules/Module";
import update from "immutability-helper";
import { InviteTeacherModal } from "AppComponents";
import { getStaffCollaborator } from "IBPlanner/modules/IBGraphqlHelpers";
import ACLStore from "lib/aclStore";
import { getUserInfo } from "Login/modules/LoginModule";
import { compose } from "react-apollo";
import { withRouter } from "react-router";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";

class Collaborator extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      collaborators: props.collaborators,
      showInviteModal: false,
      error: "",
    };

    if (props.onCollaboratorsUpdate) {
      props.onCollaboratorsUpdate(props.collaborators);
    }

    if (props.customRef) {
      props.customRef(this);
    }
  }

  // componentDidUpdate(prevProps) {
  //   const { collaborators } = this.props;
  //   if (prevProps.collaborators !== collaborators) {
  //     this.setState({
  //       collaborators
  //     });
  //   }
  // }

  isValid = () => {
    const { error } = this.props;
    const { collaborators } = this.state;

    if (_.get(collaborators, "length", 0) == 0) {
      this.setState({ error: error });
      return 1;
    }
    return 0;
  };

  getCollaborators = () => {
    const { collaborators } = this.state;
    return _.map(collaborators, collaborator =>
      _.pick(collaborator, ["id", "permission"])
    );
  };

  onSelectInputDoneClick = value => {
    const newCollaborators = _.map(value, (staff, index) => {
      return { ...staff, permission: "EDIT" };
    });

    this.updateCollaborator({
      type: "add",
      value: newCollaborators,
    });
  };

  changeSearchTerm = value => {
    this.updateCollaboratorSearchText(value);
  };

  updateCollaboratorSearchText = value => {
    this.setState({ searchText: value });
  };

  updateCollaborator = params => {
    const type = params["type"];
    const value = params["value"];
    const { collaborators = [] } = this.state;
    let updatedCollaborators = [];
    let index = 0;
    switch (type) {
      case "add":
        updatedCollaborators = _.uniqBy([...collaborators, ...value], "id");
        break;
      case "remove":
        index = _.findIndex(collaborators, item => item.id == value.id);
        if (index >= 0) {
          updatedCollaborators = update(collaborators, {
            $splice: [[index, 1]],
          });
        }
        break;
      case "update":
        index = _.findIndex(collaborators, item => item.id == value.id);
        if (index >= 0) {
          updatedCollaborators = update(collaborators, {
            [index]: { $set: value },
          });
        }
        break;
    }
    this.onCollaboratorSearchUpdate(updatedCollaborators);
  };

  onCollaboratorSearchUpdate = collaborators => {
    const { onCollaboratorsUpdate, updateInputField, name } = this.props;
    this.setState({ collaborators, error: "" });
    if (updateInputField) {
      updateInputField({ [name]: collaborators });
    }
    if (onCollaboratorsUpdate) {
      onCollaboratorsUpdate(collaborators);
    }
  };

  onCollaboratorPermissionChanged = value => {
    this.updateCollaborator({
      type: "update",
      value: value,
    });
  };

  onCollaboratorRemoveClick = value => {
    this.updateCollaborator({
      type: "remove",
      value: value,
    });
  };

  onClickInvite = () => {
    this.setState({ showInviteModal: true });
  };

  onClickCancelInvite = () => {
    this.setState({ showInviteModal: false });
  };

  createOrganizationInvitedUser = async ({ invitedUsers }) => {
    const { createOrganizationInvitedUser, grades } = this.props;
    const result = await createOrganizationInvitedUser({
      invitedUsers: _.map(invitedUsers, user => {
        return {
          ...user,
          grades,
        };
      }),
    });
    const { response = [], warning = [] } = result;

    if (_.isEmpty(warning)) {
      this.onSelectInputDoneClick(response);
    }
    return result;
  };

  getCollaboratorList = () => {
    const { collaborators } = this.state;

    const { hideFromList } = this.props;

    if (_.get(hideFromList, "length", 0)) {
      return _.filter(collaborators, collaborator => {
        return !_.includes(hideFromList, collaborator.id);
      });
    }

    return collaborators;
  };

  render() {
    const {
      userId,
      owner,
      containerStyle,
      listContainerStyle,
      inputContainerStyle,
      inputSearchBarContainerStyle,
      onCollaboratorToggle,
      grades,
      showInviteBox,
      singlePlaceholder,
      placeholder,
      searchListTypes,
      mode,
      errorStyle,
      showErrorText,
      t,
      searchDropdownListContainerStyle,
      queryParams,
      disabledIds,
      portalType,
      courses,
      hideCollaboratorListCountTxt,
      actionMenuItems,
      searchMenuAddedText,
      searchType,
      archivalFilters,
      labelComponent,
      hideCollaborators,
      projectGroupId,
    } = this.props;

    const { showInviteModal } = this.state;

    const { collaborators, searchText, error } = this.state;

    const ownerId = owner ? owner.id : userId;

    return (
      <div className={classes.container} style={containerStyle}>
        {!!labelComponent && labelComponent}
        {mode != "view" && (
          <SelectInput
            ownerId={ownerId}
            onDoneClick={this.onSelectInputDoneClick}
            changeSearchTerm={this.changeSearchTerm}
            collaborators={collaborators}
            searchText={searchText}
            inputContainerStyle={inputContainerStyle}
            inputSearchBarContainerStyle={inputSearchBarContainerStyle}
            onCollaboratorToggle={onCollaboratorToggle}
            grades={grades}
            courses={courses}
            singlePlaceholder={singlePlaceholder}
            placeholder={placeholder}
            searchListTypes={searchListTypes}
            mode={mode}
            onCollaboratorSearchUpdate={this.onCollaboratorSearchUpdate}
            error={error}
            errorStyle={errorStyle}
            searchDropdownListContainerStyle={searchDropdownListContainerStyle}
            showErrorText={showErrorText}
            queryParams={queryParams}
            disabledIds={disabledIds}
            portalType={portalType}
            searchMenuAddedText={searchMenuAddedText}
            searchType={searchType}
            archivalFilters={archivalFilters}
            hideCollaborators={hideCollaborators}
            projectGroupId={projectGroupId}
          />
        )}

        {mode != "search" && (
          <CollaboratorList
            hideCollaboratorListCountTxt={hideCollaboratorListCountTxt}
            showInviteBox={showInviteBox}
            collaborators={this.getCollaboratorList()}
            onRemoveClick={this.onCollaboratorRemoveClick}
            onPermissionChanged={this.onCollaboratorPermissionChanged}
            ownerId={ownerId}
            listContainerStyle={listContainerStyle}
            onClickInvite={this.onClickInvite}
            mode={mode}
            actionMenuItems={actionMenuItems}
          />
        )}
        {showInviteModal && (
          <InviteTeacherModal
            parentType={"unit"}
            createOrganizationInvitedUser={this.createOrganizationInvitedUser}
            onCancelClick={this.onClickCancelInvite}
          />
        )}
      </div>
    );
  }
}

const mapActionCreators = {
  createOrganizationInvitedUser,
};

const mapStateToProps = (state, ownProps) => {
  const { portalType } = ownProps;
  const curriculumProgramId = _.get(ownProps, "params.curriculumProgramId", "");
  const tabConfigurations = { admin: { curriculumProgramId } };
  const isCurriculumProgramFree = getCurriculumProgramFreeStatus({
    state,
    tabConfigurations,
  });
  const userInfo = getUserInfo({ portalType });
  const userId = userInfo.id;

  const showInviteBox = isCurriculumProgramFree;
  return {
    userId,
    grades: ownProps.grades,
    courses: ownProps.courses,
    showInviteBox,
    collaborators: !!ownProps.collaborators
      ? ownProps.collaborators
      : [{ ...getStaffCollaborator(userId), permission: "EDIT" }],
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapActionCreators)
)(Collaborator);

Collaborator.propTypes = {
  containerStyle: PropTypes.object,
  listContainerStyle: PropTypes.object,
  inputContainerStyle: PropTypes.object,
  inputSearchBarContainerStyle: PropTypes.object,
  mode: PropTypes.string, //oneOf 'view','edit','search'
  errorStyle: PropTypes.object,
  showErrorText: PropTypes.bool,
  hideCollaborators: PropTypes.array,
};

Collaborator.defaultProps = {
  containerStyle: {},
  listContainerStyle: {},
  inputContainerStyle: {},
  inputSearchBarContainerStyle: {},
  singlePlaceholder: "unitPlan:add_more_teachers",
  placeholderText: "unitPlan:enter_teacher_names_placeholder",
  searchListTypes: ["GROUP", "INDIVIDUAL"],
  mode: "edit",
  error: "planathon:select_collaborator",
  errorStyle: {},
  showErrorText: false,
  disabledIds: [],
  hideFromList: [],
  hideCollaborators: [],
  hideCollaboratorListCountTxt: false,
};
