import React, { useState, useLayoutEffect } from "react";

// UI Components
import { I18nHOC, withLoader } from "UIComponents";
import SelectEditTemplate from "./SelectEditTemplate";

// GraphQL
import { getStaffListQuery } from "modules/CommonQuery";
import { getStaffListFromCache } from "modules/CommonGraphqlHelpers";
import { getOrganizationRubricsFromCache } from "Rubric/modules/RubricGraphqlHelpers";
import { getOrganizationChecklistsFromCache } from "Checklist/modules/ChecklistGraphqlHelpers";
import { getOrganizationSinglePointRubricsFromCache } from "SinglePointRubric/modules/SinglePointRubricGraphqlHelpers";
import { getOrganizationRubricsQuery } from "Rubric/modules/RubricQuery";
import { getOrganizationChecklistQuery } from "Checklist/modules/ChecklistQuery";
import { getOrganizationSinglePointRubricsQuery } from "SinglePointRubric/modules/SinglePointRubricQuery";

// External lib.
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import moment from "moment";

// Utils and Services
import { getAssessmentToolFilters } from "Administrator/modules/Utils";
import { getLabelValueForDropdown } from "modules/Services";

// Design System
import {
  SearchInput,
  ChecklistDropdown,
  FilterLabelButton,
  Table,
  EmptyState,
} from "@toddle-design/web";
import { ChevronRightOutlined } from "@toddle-design/web-icons";
import {
  colors,
  fontStyles,
  ProjectOverviewIllustration,
} from "@toddle-design/theme";

// Scss
import classes from "./AssessmentToolChooseListV2.scss";

const styles = {
  columnListStyle: {
    fontSize: "1.6rem",
    color: colors.textDefault,
    fontWeight: fontStyles.demiBold,
  },
  columnDataStyle: {
    fontSize: "1.6rem",
    color: colors.textSubtle,
  },
};

const getColumnList = ({ t }) => [
  {
    key: "name",
    value: t("common:name"),
    style: styles.columnListStyle,
    type: "TEXTCOLUMN",
  },
  {
    key: "createdBy",
    value: t("common:created_by"),
    style: styles.columnListStyle,
    type: "TEXTCOLUMN",
  },
  {
    key: "lastEdited",
    value: t("common:last_edited_label"),
    style: styles.columnListStyle,
    type: "TEXTCOLUMN",
  },
  {
    key: "arrowIcon",
    type: "CUSTOM",
  },
];

const steps = [
  {
    value: "TEMPLATE_LIST",
  },
  {
    value: "SELECTED_TEMPLATE",
  },
  {
    value: "EDIT_TEMPLATE",
  },
];

const getCellData = ({ t, column, list }) => {
  const { key } = column;
  switch (key) {
    case "name": {
      const { title = "" } = list;
      return !_.isEmpty(title) ? title : t("common:untitled");
    }
    case "createdBy": {
      const {
        createdBy: { firstName, lastName },
      } = list;
      return `${firstName} ${lastName}`;
    }
    case "lastEdited": {
      const updatedAt = _.get(list, "updatedAt") ?? _.get(list, "lastEdited");
      return moment(updatedAt).format("DD MMM YYYY");
    }
    case "arrowIcon": {
      return <ChevronRightOutlined variant={"subtle"} />;
    }
  }
};

const getBodyData = ({ t, columnList, toolList }) => {
  return _.map(toolList, list => {
    const listId = _.get(list, "id");

    const rowData = _.map(columnList, column => {
      return {
        key: _.get(column, "key"),
        value: getCellData({ t, column, list }),
      };
    });

    return {
      id: listId,
      rowData,
    };
  });
};

const FeedListComp = props => {
  const { toolList, updateToolId, updateCurrentStep, t } = props;

  const columnList = getColumnList({ t });

  const onTemplateClick = id => {
    updateToolId(id);
    updateCurrentStep("SELECT_TEMPLATE");
  };

  const data = getBodyData({ t, columnList, toolList });

  return !_.isEmpty(data) ? (
    <div className={classes.feedListContainer}>
      <Table headers={columnList} data={data} onRowClick={onTemplateClick} />
    </div>
  ) : (
    <EmptyState
      illustration={ProjectOverviewIllustration}
      title={t("common:no_with_label", {
        label: t("common:template_plural"),
      })}
      subtitle={"classRoom:no_templates_subtext"}
    />
  );
};

const FeedListWithLoader = withLoader(FeedListComp);

const TemplateList = props => {
  const {
    searchText,
    updateFooterDetails,
    handleTemplateSearch,
    staffList,
    createdBy,
    updateTemplateCreatedBy,
    isData,
    isLoading,
    t,
  } = props;

  useLayoutEffect(() => {
    const staffIds = _.map(staffList, "key");
    updateTemplateCreatedBy(staffIds); // initally set createdBy filter to ALL
    updateFooterDetails({ showFooter: false });
  }, []);

  const staffLabelValue = getLabelValueForDropdown({
    selectedItems: createdBy,
    itemList: staffList,
    t,
    noneSelectedText: t("common:select"),
  });

  return (
    <>
      <div className={classes.actions}>
        <div className={classes.searchInput}>
          <SearchInput
            value={searchText}
            initialValue={searchText}
            onChange={_.debounce(handleTemplateSearch, 200)}
            placeholder={t("common:search")}
          />
        </div>
        <div className={classes.filerButtons}>
          <ChecklistDropdown
            options={staffList}
            showAllOption={true}
            value={createdBy}
            onChange={updateTemplateCreatedBy}
          >
            <FilterLabelButton
              variant={"plain"}
              label={t("common:creator")}
              labelValue={staffLabelValue}
            />
          </ChecklistDropdown>
        </div>
      </div>
      <div className={classes.container}>
        <FeedListWithLoader {...props} isData={isData} isLoading={isLoading} />
      </div>
    </>
  );
};

const AssessmentToolChooseListV2 = props => {
  const { updateHeaderDetails, name, t, type } = props;
  const [currentStep, setCurrentStep] = useState(_.first(steps)?.value);
  const [toolId, setToolId] = useState({});

  useLayoutEffect(() => {
    if (currentStep === "TEMPLATE_LIST") {
      updateHeaderDetails({
        title: `${name} ${_.lowerCase(t("common:template_plural"))}`,
        showBackButton: false,
      });
    }
  }, [currentStep, name]);

  const updateCurrentStep = step => setCurrentStep(step);
  const updateToolId = id => setToolId(id);

  switch (currentStep) {
    case "TEMPLATE_LIST": {
      return (
        <TemplateList
          {...props}
          updateCurrentStep={updateCurrentStep}
          updateToolId={updateToolId}
        />
      );
    }
    case "SELECT_TEMPLATE": {
      return (
        <SelectEditTemplate
          {...props}
          mode={"view"}
          type={type}
          toolId={toolId}
          currentStep={currentStep}
          updateCurrentStep={updateCurrentStep}
        />
      );
    }
    case "EDIT_TEMPLATE": {
      return (
        <SelectEditTemplate
          {...props}
          mode={"edit"}
          toolId={toolId}
          type={type}
          currentStep={currentStep}
          updateCurrentStep={updateCurrentStep}
        />
      );
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const { searchText, createdBy } = ownProps;
  const organizationId = _.get(state, "login.userInfo.org_id", "");

  const curriculumProgramId = _.get(
    state,
    "platform.currentCurriculumProgram.id",
    null
  );
  const assessmentToolFilters = getAssessmentToolFilters({
    curriculumProgramId,
  });

  const filters = {
    ...assessmentToolFilters,
    createdBy,
    searchText,
  };

  return {
    organizationId,
    filters,
    isData: true,
    isLoading: false,
    portalType: "PLANNER",
  };
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(mapStateToProps, null),
  graphql(getStaffListQuery, {
    name: "getStaffList",
    options: ({ organizationId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        organizationId,
      },
    }),
    props({ getStaffList, ownProps: { isData, isLoading, organizationId } }) {
      const queryData = getStaffListFromCache({ organizationId });
      const networkStatus = _.get(getStaffList, "networkStatus");

      const staffList = _.get(queryData, "node.staff.edges", []);
      const staffListOptions = _.map(staffList, staff => {
        const node = _.get(staff, "node", {});
        const id = _.get(node, "id");
        const firstName = _.get(node, "firstName");
        const lastName = _.get(node, "lastName");
        const fullName = `${firstName} ${lastName}`;
        return {
          key: id,
          value: id,
          label: fullName,
        };
      });
      return {
        staffList: staffListOptions,
        isData: !_.isEmpty(queryData) && isData,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  }),
  graphql(getOrganizationRubricsQuery, {
    name: "getOrganizationRubrics",
    skip: ({ type }) => type !== "RUBRIC",
    options: ({ organizationId, filters, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, filters, portalType },
    }),
    props({
      getOrganizationRubrics,
      ownProps: { organizationId, filters, isData, isLoading },
    }) {
      const organizationDetails = getOrganizationRubricsFromCache({
        id: organizationId,
        filters,
      });

      const toolList = _.get(organizationDetails, "rubrics", []);
      const networkStatus = _.get(getOrganizationRubrics, "networkStatus");
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        toolList,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  }),
  graphql(getOrganizationChecklistQuery, {
    name: "getOrganizationChecklist",
    skip: ({ type }) => type !== "CHECKLIST",
    options: ({ organizationId, filters, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, filters, portalType },
    }),
    props({
      getOrganizationChecklist,
      ownProps: { organizationId, filters, isData, isLoading },
    }) {
      const organizationDetails = getOrganizationChecklistsFromCache({
        id: organizationId,
        filters,
      });
      const toolList = _.get(organizationDetails, "checklists", []);
      const networkStatus = _.get(getOrganizationChecklist, "networkStatus");
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        toolList,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  }),
  graphql(getOrganizationSinglePointRubricsQuery, {
    name: "getOrganizationSinglePointRubrics",
    skip: ({ type }) => type !== "SINGLE_POINT_RUBRIC",
    options: ({ organizationId, filters, portalType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: organizationId, filters, portalType },
    }),
    props({
      getOrganizationSinglePointRubrics,
      ownProps: { organizationId, filters, isData, isLoading },
    }) {
      const organizationDetails = getOrganizationSinglePointRubricsFromCache({
        id: organizationId,
        filters,
      });
      const toolList = _.get(organizationDetails, "singlePointRubrics", []);
      const networkStatus = _.get(
        getOrganizationSinglePointRubrics,
        "networkStatus"
      );
      return {
        isData: !_.isEmpty(organizationDetails) && isData,
        toolList,
        isLoading: _.includes([1, 2], networkStatus) || isLoading,
      };
    },
  })
)(AssessmentToolChooseListV2);
