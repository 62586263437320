import React, { PureComponent } from "react";

import CheckListEdit from "./CheckListEdit";
import CheckListView from "./CheckListView";

class ChecklistTable extends PureComponent {
  render() {
    const { mode } = this.props;
    return mode == "view" ? (
      <CheckListView {...this.props} />
    ) : (
      <CheckListEdit {...this.props} />
    );
  }
}

export default ChecklistTable;

ChecklistTable.defaultProps = {
  isEvaluation: false,
  showInsight: false,
  insights: [],
  toolEvaluationResponses: [],
};
