import React, { useCallback, useEffect } from "react";
import classes from "./FooterButtons.scss";
import {
  isUserLiked,
  isJournalCardRevamp,
  getPostTranslationFilter,
  isPostViewCountEnabled,
} from "Post/utils";
import DropDown from "./DropDown";
import {
  LikeIcon,
  LikeFillIcon,
  FeedCommentIcon,
  ArrowIcon,
  DiscussionAddIcon,
} from "SvgComponents";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PencilLineOutlined,
  PublishOutlined,
  CommentCircleOutlined,
  HeartLikeOutlined,
  HeartLikeFilled,
  EyeOutlined,
} from "@toddle-design/web-icons";
import { MoreItemWithToolTip, ButtonDropdown, UIButton } from "UIComponents";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import { getSettingValue } from "modules/PermissionModule";
import { getIsCurrentAcademicYear } from "Platform/modules/PlatformModule";
import { HiddenIfPreviousYear } from "AppComponents";
import { colors } from "Constants";
import ACLStore from "lib/aclStore";
import { Button, IconButton, Tooltip } from "@toddle-design/web";
import ConditionalWrapper from "Utils/ConditionalWrapper";
import { getPostTranslationQuery } from "../../modules/PostQuery";

const myDropdownStyles = {
  textStyle: {
    maxHeight: "unset",
    overflow: "unset",
    textOverflow: "unset",
    WebkitBoxOrient: "unset",
  },
  containerStyle: {
    width: "232px",
    zIndex: 20,
  },
};

const FooterButtonIcon = ({
  svgIcon,
  count,
  svgClassName,
  onIconClick,
  buttonContainerStyle,
  isDisabled,
}) => {
  const onClick = () => {
    if (!isDisabled && onIconClick) {
      onIconClick();
    }
  };

  return (
    <div className={classes.footerButton} style={buttonContainerStyle}>
      {(!!count || count == 0) && (
        <Button
          className={svgClassName}
          onClick={onClick}
          disabled={isDisabled}
          variant={"plain"}
          size={"medium"}
          icon={svgIcon}
        >
          <div className={classes.countText}>{count}</div>
        </Button>
      )}
      {_.isUndefined(count) && (
        <div className={classes.iconButton}>
          <IconButton
            className={svgClassName}
            onClick={onClick}
            disabled={isDisabled}
            variant={"plain"}
            size={"medium"}
            icon={svgIcon}
          />
        </div>
      )}
    </div>
  );
};

const LikeButton = ({
  id,
  likes,
  onPostActionClick,
  userId,
  showTooltip,
  buttonContainerStyle,
  showLikeButton = true,
  getIsCurrentAcademicYear,
  isArchivedClass,
}) => {
  const getSvgClassName = () => {
    if (!isCurrentAcademicYear || isArchivedClass) {
      return classes.disabledLikeIcon;
    }
    if (isLiked) {
      return classes.svgPinkIcon;
    }
    return classes.svgLikeIcon;
  };

  const isCurrentAcademicYear = getIsCurrentAcademicYear();
  const isLiked = isUserLiked({ likes, userId });
  const totalLikeCount = _.get(likes, "totalCount", 0);
  const button = (
    <FooterButtonIcon
      svgClassName={getSvgClassName()}
      svgIcon={
        isLiked ? (
          <HeartLikeFilled size={"xx-small"} variant={"critical"} />
        ) : (
          <HeartLikeOutlined size={"xx-small"} variant={"subtle"} />
        )
      }
      count={totalLikeCount}
      onIconClick={() =>
        onPostActionClick({ id, action: "LIKE", params: { isLiked } })
      }
      buttonContainerStyle={buttonContainerStyle}
      isDisabled={!showLikeButton}
    />
  );

  const items = _.map(_.get(likes, "edges", []), item => {
    const { firstName, lastName } = _.get(item, "node.user", {});
    return `${firstName} ${lastName}`;
  });

  return showTooltip && items.length > 0 ? (
    <MoreItemWithToolTip
      items={items}
      length={0}
      tooltipPlacement={"bottom"}
      renderComponent={button}
    />
  ) : (
    button
  );
};
const mapStateToPropsLikeButton = (state, ownProps) => {
  const userType = state.login.userInfo.user_type;
  const { courseIdFromQuery } = ownProps;
  const courseId = state.teacher.selected_class.selected_course;
  const showLikeButton =
    userType === "staff" ||
    getSettingValue({
      name:
        userType === "parent"
          ? "EnablePostLikeByFamily"
          : "EnablePostLikeByStudents",
      courseId: courseIdFromQuery ? courseIdFromQuery : courseId,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    });
  return {
    showLikeButton,
  };
};
const mapActionCreatorsLikeButton = {
  getIsCurrentAcademicYear,
};
const LikeButtonIcon = compose(
  connect(mapStateToPropsLikeButton, mapActionCreatorsLikeButton)
)(LikeButton);

const CommentButton = ({
  id,
  comments,
  isCommentOpen,
  onPostActionClick,
  buttonContainerStyle,
  showCommentButton = true,
  t,
}) => {
  const totalCommentCount = _.get(comments, "totalCount", 0);
  return (
    <ConditionalWrapper
      type="wrapperAsElement"
      condition={!showCommentButton && totalCommentCount == 0}
      wrapper={
        <Tooltip tooltip={t("common:comments_are_off")} placement={"top"} />
      }
    >
      <div>
        <FooterButtonIcon
          svgClassName={isCommentOpen ? classes.svgBlueIcon : classes.svgIcon}
          svgIcon={
            <CommentCircleOutlined size={"xx-small"} variant={"subtle"} />
          }
          count={totalCommentCount}
          onIconClick={() => onPostActionClick({ id, action: "COMMENT" })}
          buttonContainerStyle={buttonContainerStyle}
          isDisabled={!showCommentButton && totalCommentCount == 0}
        />
      </div>
    </ConditionalWrapper>
  );
};
const mapStateToPropsCommentButton = (state, ownProps) => {
  const userType = state.login.userInfo.user_type;
  const { courseIdFromQuery, t } = ownProps;
  const courseId = state.teacher.selected_class.selected_course;
  const showCommentButton =
    userType === "staff" ||
    getSettingValue({
      name:
        userType === "parent"
          ? "enableFamilyCommentCreation"
          : "EnableCommentingOnPostByStudents",
      courseId: courseIdFromQuery ? courseIdFromQuery : courseId,
      organizationId: state.login.userInfo.org_id,
      userId: state.login.userInfo.id,
      userEntityType: state.login.userInfo.userEntityType,
    });
  return {
    showCommentButton,
    t,
  };
};
const CommentButtonIcon = compose(connect(mapStateToPropsCommentButton, null))(
  CommentButton
);

const SeenButtonIcon = ({ id, seenCount, onIconClick }) => {
  return (
    <FooterButtonIcon
      svgClassName={classes.svgIcon}
      svgIcon={<EyeOutlined />}
      count={seenCount}
      onIconClick={onIconClick}
      isDisabled={false}
    />
  );
};
const FooterButtonsComp = ({
  id,
  comments,
  likes,
  seenCount = 0,
  state,
  userType,
  isCommentOpen,
  onPostActionClick,
  canEditOrDelete = true,
  createdByType,
  userId,
  t,
  createdByName,
  isArchivedClass,
  isAddLEToJournal,
  isSidePaneContent = false,
  showTranslatedText = false,
  isViewFromPostPage = false,
  toggleTranslatedText,
  toggleLikeSeenModal,
  showTranslationButton,
  setTitleText = null,
  translatedText = "",
}) => {
  useEffect(() => {
    if (setTitleText && _.isFunction(setTitleText))
      setTitleText(translatedText);
  }, [translatedText]);

  const isPublished = state == "PUBLISHED";
  const isPostApproveRejectCommentPermission = ACLStore.can(
    "FeatureFlag:NewApprovalFlowOfPost"
  );
  const approveButtonDropdown = (
    <UIButton
      containerStyle={{
        minWidth: "40px",
        marginLeft: "1px",
        borderRadius: "0px 6px 6px 0px",
      }}
      size="sm"
      color="blue"
      isDisabled={isArchivedClass}
    >
      <div className={classes.dropdownArrowStyles}>
        <ArrowIcon />
      </div>
    </UIButton>
  );

  const approveButtonDropdownTabs = [
    {
      label: t("journal:approve_with_comment"),
      svg: <DiscussionAddIcon height={20} width={20} fill={colors.gray13} />,
      value: "approve_with_comment",
    },
  ];

  const PostActionClickFunc = useCallback(
    ({ buttonAction, name = "" }) => {
      onPostActionClick({
        id,
        action: buttonAction,
        createdByName: name,
        isAddLEToJournal: isAddLEToJournal,
      });
    },
    [id]
  );

  const onTranslationClick = e => {
    toggleTranslatedText();
  };

  return (
    <div
      className={classes.footerButtonContainer}
      style={isJournalCardRevamp() ? { height: "80px" } : {}}
    >
      <div className={classes.footerButtonLeftContainer}>
        {/* Show publish icon only to teacher on in-approval or draft posts */}
        {!isPublished && userType == "staff" && !isViewFromPostPage && (
          <HiddenIfPreviousYear>
            {isPostApproveRejectCommentPermission ? (
              state == "IN_APPROVAL" && !isSidePaneContent ? (
                <>
                  <UIButton
                    color={"blue"}
                    size="sm"
                    onClick={PostActionClickFunc.bind(this, {
                      buttonAction: "APPROVE",
                    })}
                    containerStyle={{
                      borderRadius: "6px 0px 0px 6px",
                    }}
                    isDisabled={isArchivedClass}
                  >
                    <PublishOutlined variant={"on"} />
                    <div className={classes.approveText}>
                      {t("common:approve")}
                    </div>
                  </UIButton>
                  <ButtonDropdown
                    disabled={isArchivedClass}
                    buttonComponent={approveButtonDropdown}
                    authTabs={approveButtonDropdownTabs}
                    onItemClick={PostActionClickFunc.bind(this, {
                      buttonAction: "APPROVE_WITH_COMMENT",
                    })}
                    {...myDropdownStyles}
                  />
                  <UIButton
                    type={"hollow"}
                    color={"greyThree"}
                    size="sm"
                    containerStyle={{
                      marginLeft: "16px",
                      borderColor: colors.borderDefault,
                      color: colors.textDefault,
                      borderRadius: "6px",
                    }}
                    onClick={PostActionClickFunc.bind(this, {
                      buttonAction: "REJECT_POST",
                      name: createdByName,
                    })}
                    isDisabled={isArchivedClass}
                  >
                    <CloseCircleOutlined />
                    <div className={classes.approveText}>
                      {t("common:return")}
                    </div>
                  </UIButton>
                </>
              ) : (
                <FooterButtonIcon
                  svgClassName={classes.svgIcon}
                  svgIcon={<PublishOutlined />}
                  onIconClick={PostActionClickFunc.bind(this, {
                    buttonAction: "PUBLISH",
                  })}
                  isDisabled={isArchivedClass}
                />
              )
            ) : (
              <FooterButtonIcon
                svgClassName={classes.svgIcon}
                svgIcon={<PublishOutlined />}
                onIconClick={PostActionClickFunc.bind(this, {
                  buttonAction: state == "IN_APPROVAL" ? "APPROVE" : "PUBLISH",
                })}
                isDisabled={isArchivedClass}
              />
            )}
          </HiddenIfPreviousYear>
        )}
        {/* Show edit icon only to student's own posts in approval and any post for teacher*/}
        {canEditOrDelete && state == "DRAFT" && !isViewFromPostPage && (
          <FooterButtonIcon
            svgClassName={classes.svgIcon}
            svgIcon={<PencilLineOutlined />}
            onIconClick={() =>
              onPostActionClick({
                id,
                action: "POST_EDIT",
                isAddLEToJournal: isAddLEToJournal,
              })
            }
            isDisabled={isArchivedClass}
          />
        )}
        {userType != "staff" &&
          (state == "REJECTED" || state == "IN_APPROVAL") &&
          canEditOrDelete && (
            <FooterButtonIcon
              svgClassName={classes.svgIcon}
              svgIcon={<PencilLineOutlined />}
              onIconClick={PostActionClickFunc.bind(this, {
                buttonAction: "POST_EDIT",
              })}
              isDisabled={isArchivedClass}
            />
          )}
        {state == "PUBLISHED" && (
          <LikeButtonIcon
            id={id}
            likes={likes}
            onPostActionClick={onPostActionClick}
            userId={userId}
            isArchivedClass={isArchivedClass}
          />
        )}
        {isJournalCardRevamp() &&
          isPostViewCountEnabled() &&
          state == "PUBLISHED" &&
          userType === "staff" && (
            <SeenButtonIcon
              id={id}
              seenCount={seenCount}
              t={t}
              onIconClick={() => toggleLikeSeenModal(true)}
            />
          )}
        {state == "PUBLISHED" && (
          <CommentButtonIcon
            id={id}
            isCommentOpen={isCommentOpen}
            comments={comments}
            onPostActionClick={onPostActionClick}
            t={t}
          />
        )}
      </div>
      {/* Show delete icon only to student's own posts in approval and any post to teacher */}

      <div className={classes.footerButtonRightContainer}>
        {canEditOrDelete && state == "IN_APPROVAL" && userType == "staff" && (
          <FooterButtonIcon
            svgClassName={classes.svgIcon}
            svgIcon={<PencilLineOutlined />}
            onIconClick={() => onPostActionClick({ id, action: "POST_EDIT" })}
            isDisabled={isArchivedClass}
          />
        )}
        {canEditOrDelete && state != "PUBLISHED" && (
          <FooterButtonIcon
            svgClassName={classes.svgIcon}
            svgIcon={<DeleteOutlined />}
            onIconClick={() => onPostActionClick({ id, action: "DELETE" })}
            isDisabled={isArchivedClass}
          />
        )}
        {
          // "See translation" button
          isJournalCardRevamp() && showTranslationButton && (
            <div className={classes.footerButton}>
              <Button
                variant={"inline"}
                size={"small"}
                onClick={onTranslationClick}
              >
                {showTranslatedText
                  ? t("common:see_original")
                  : t("common:see_translation")}
              </Button>
            </div>
          )
        }
        {/* Show dropdown only to student's own published posts and any post to teacher*/}
        {!isJournalCardRevamp() && canEditOrDelete && state == "PUBLISHED" && (
          <DropDown
            userType={userType}
            id={id}
            onPostActionClick={onPostActionClick}
            createdByType={createdByType}
            isArchivedClass={isArchivedClass}
            isAddLEToJournal={isAddLEToJournal}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const translationFilter = getPostTranslationFilter({
    userInfo: _.get(state, "login.userInfo", {}),
    shouldTranslate: _.get(ownProps, "showTranslatedText", false),
    handleTranslation: _.get(ownProps, "showTranslationButton", false),
  });
  return {
    postId: ownProps.id,
    translationFilter,
  };
};

export { FooterButtonIcon, LikeButtonIcon, CommentButtonIcon };
export const FooterButtons = compose(
  connect(mapStateToProps, null),
  graphql(getPostTranslationQuery, {
    name: "getPostTranslation",
    skip: ({ showTranslatedText }) => !showTranslatedText,
    options: ({ postId, translationFilter }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: postId,
        translationFilter,
      },
    }),
    props({ getPostTranslation, ownProps }) {
      const translatedText = _.get(
        getPostTranslation,
        "node.translatedText.translatedLabel",
        ""
      );
      return {
        translatedText,
      };
    },
  })
)(FooterButtonsComp);
