import React from "react";
import { DialogueBox } from "UIComponents";
import classes from "./DialogBox.scss";

const DIALOGUE_INFO = {
  FEEDBACK: {
    title: ({ t, firstName }) => {
      return (
        <div className={classes.modalHeader}>
          {t("classRoom:give_feedback_title", {
            firstName,
          })}
        </div>
      );
    },
    modalBody: ({ t }) => {
      return (
        <div className={classes.modalBody}>{t("common:proceed_confirm")}</div>
      );
    },
    confirmButtonText: t => t("common:give_feedback_text"),
    cancelButtonText: t => t("common:cancel"),
  },
  DISCARD: {
    title: ({ t }) => {
      return t("discard_changes");
    },
    modalBody: ({ t }) => {
      return (
        <div className={classes.modalBody}>
          {t("classRoom:discard_feedback_warning_msg")}
        </div>
      );
    },
    confirmButtonText: t => t("common:discard_exit"),
    cancelButtonText: t => t("common:cancel"),
  },
};

const styles = {
  modalBodyStyle: {
    marginTop: "0",
    padding: "8px 48px 48px 48px",
    flex: "0",
    alignItems: "center",
  },
  cancelButtonStyle: {
    flexBasis: "0%",
    marginRight: "0",
  },
  cancelButtonProps: {
    color: "transparent",
    type: "hollow",
    disableHover: true,
  },
  footerContainerStyle: {
    padding: "0px 40px 28px 48px",
  },
  headerStyles: {
    padding: "28px 48px 4px 48px",
    display: "flex",
    alignItems: "center",
  },
};

const DialogBox = ({
  dialogueBoxState,
  setCurrentDialogAction,
  changePdftronEnable,
  onClickGiveFeedback,
  setDialougeBoxState,
  pdftronViewerMode,
  onDiscardDirectly,
  changeEditToViewMode,
  instance,
  closeViewer,
  t,
  studentDetails,
}) => {
  const firstName = _.get(studentDetails, "firstName", "");

  return (
    <DialogueBox
      modalTitle={DIALOGUE_INFO[dialogueBoxState].title({
        t,
        firstName,
      })}
      modalBody={DIALOGUE_INFO[dialogueBoxState].modalBody({ t })}
      button1={DIALOGUE_INFO[dialogueBoxState].cancelButtonText(t)}
      button2={DIALOGUE_INFO[dialogueBoxState].confirmButtonText(t)}
      onClickButton1={() => setCurrentDialogAction(false)}
      toggleDialogueBoxDisplay={() => setCurrentDialogAction(false)}
      onClickButton2={() => {
        if (_.isEqual("FEEDBACK", dialogueBoxState)) {
          changePdftronEnable(true);
          onClickGiveFeedback();
          setDialougeBoxState("DISCARD");
        }
        if (_.isEqual("DISCARD", dialogueBoxState)) {
          if (_.isEqual("TEACHER_EDITOR", pdftronViewerMode)) {
            onDiscardDirectly();
            changeEditToViewMode({ instance });
          } else {
            closeViewer();
          }

          //   setDialougeBoxState("FEEDBACK");
        }
      }}
      button1Props={styles.cancelButtonProps}
      button1Styles={styles.cancelButtonStyle}
      modalBodyStyle={styles.modalBodyStyle}
      footerContainerStyle={styles.footerContainerStyle}
      headerStyles={styles.headerStyles}
      size={"auto"}
    />
  );
};

export default DialogBox;
