import React from "react";
import { I18nHOC } from "UIComponents";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { updateInvitedFamilyData } from "Login/modules/LoginModule";
import FamilyInviteChildSelection from "Courses/components/ParentHomePage/FamilyInviteChildSelection";
import { goToRelativeRoute } from "modules/Services";

const ClassInviteChildSelection = props => {
  const {
    goToRelativeRoute,
    updateInvitedFamilyData,
    courseId,
    isClassLinkVerified,
  } = props;

  const onClose = () => {
    // reset the invited famliy data so that when the code goes through
    // LoginView.js and invitedFamilyData is empty, this component is not rendered
    updateInvitedFamilyData({});
    goToRelativeRoute({ route: "/", type: "replace" });
  };

  return isClassLinkVerified ? (
    <FamilyInviteChildSelection onClose={onClose} courseId={courseId} />
  ) : null;
};

const mapStateToProps = state => {
  const courseId = _.get(
    state.login.invitedFamilyData,
    "metadata.classId",
    null
  );

  const isClassLinkVerified = _.get(
    state.login.invitedFamilyData,
    "verified",
    false
  );

  return {
    courseId,
    isClassLinkVerified,
  };
};

const mapActionCreators = {
  updateInvitedFamilyData,
  goToRelativeRoute,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["integration", "common"] })
)(ClassInviteChildSelection);
