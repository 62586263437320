import moment from "moment";

var momentDurationFormatSetup = require("moment-duration-format");

export const getTimeSince = (date, t) => {
  const now = moment(new Date());
  const momentDate = moment(date);
  const diffInHours = now.diff(momentDate, "hours");

  if (diffInHours >= 24) {
    const isCurrentYear = momentDate.format("YYYY") === now.format("YYYY");
    return moment(date).format(isCurrentYear ? "D MMM" : "D MMM YYYY");
  } else {
    if (diffInHours < 1) {
      const diffInMin = moment().diff(date, "minutes");
      if (diffInMin < 1) {
        return t("common:now");
      }
      return t("common:minit_abb", { minit: diffInMin });
    }
    return t("common:hour_abb", { hour: diffInHours });
  }
};

export const convertDurationToString = duration => {
  let durationString = moment
    .duration(duration, "seconds")
    .format("d[d] h[h] m[m] s[s]", {
      trim: "both final",
    });
  // console.log(duration, durationString);
  return durationString;
};

export const getTimeDifferenceIsNow = ({ nowTime, thenTime }) => {
  const now = moment(nowTime);
  const then = moment(thenTime);
  const diffInMin = now.diff(then, "minutes");
  if (diffInMin < 1) {
    return true;
  }
  return false;
};

export const formatTime = timer => {
  if (!timer) return "00:00";

  const minutes = Math.floor(timer / 60);
  const remainingSeconds = timer - minutes * 60;

  const mm = minutes > 9 ? minutes : `0${minutes}`;
  const ss = remainingSeconds > 9 ? remainingSeconds : `0${remainingSeconds}`;

  return `${mm}:${ss}`;
};
