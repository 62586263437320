// This function is ported of @mixin multiLineEllipsis
export const multiLineEllipsis = ({
  lineHeight,
  lineCount,
  maxCharacter = "default",
}) => ({
  display: "-webkit-box",
  lineHeight: lineHeight,
  WebkitLineClamp: lineCount,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: maxCharacter !== "default" ? `${maxCharacter}ch` : "none",
});
