import React, { useState, Fragment, useRef, useEffect } from "react";
import {
  UIModal,
  UIButton,
  I18nHOC,
  InputSet,
  SearchSelect,
  withLoader,
  FullScreenLoader,
  Loading,
} from "UIComponents";
import {
  FBIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  LinkIconSvg,
} from "SvgComponents";
import classes from "./ShareModal.scss";
import { SOCIAL_TYPES, colors } from "Constants";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { RESOURCE_PLACEHOLDER_URL } from "store/static";
import { getRethumbUrl } from "Utils";
import { getUserInfo, setToastMsg } from "Login/modules/LoginModule";
import {
  getEntityBasicInfoQuery,
  getOrganizationMembersQuery,
} from "Community/modules/CommunityQuery";
import { shareEntity } from "Community/modules/CommunityModule";
import { checkAndUpdateCoachMarkStatus } from "modules/CoachMarksModule";
import { emailValidation, copyToClipboard, getSharerURL } from "Utils";
import { recordEvent } from "lib/eventTracker";
const customStyles = {
  cancel: {
    minWidth: "120px",
  },
  invite: {
    minWidth: "120px",
    marginLeft: 16,
  },
};

function EmailShareModal({ onClose, entity, shareEntity, t }) {
  const inputSetRef = useRef(null);
  const [isInProgress, setIsInProgress] = useState(false);
  const [emails, setEmails] = useState([]);

  const entityLabel =
    entity.type === "UNIT_PLAN" ? t("common:unit") : t("common:le_label");

  const shareClickListener = async () => {
    if (inputSetRef.current) inputSetRef.current.updateInput();
    setIsInProgress(true);
    const status = await shareEntity({
      entityId: entity.id,
      entityType: entity.type,
      entityName: entity.name,
      typeLabel: entityLabel,
      emails: _.uniq(emails),
    });
    setIsInProgress(false);
    if (!status) return;
    onClose();
  };

  return (
    <UIModal isOpen={true} modalContent={classes.emailShareModalContent}>
      {isInProgress ? (
        <Loading containerStyle={{ minHeight: "240px" }} />
      ) : (
        <Fragment>
          <div className={classes.emailShareModalHeader}>
            {t("community:share_via_email")}
          </div>
          <InputSet
            ref={inputSetRef}
            label={t("community:invite_teacher_label", {
              label: t("common:lowercase", { text: entityLabel }),
            })}
            value={emails}
            updateInputField={updatedValue => setEmails(updatedValue)}
            addOnEnter={true}
            onAddCustomValidation={emailValidation}
            placeholder={
              _.get(emails, "length", 0) === 0
                ? t("common:email_box_input")
                : t("common:invite_more")
            }
          />
          <div className={classes.emailShareModalActions}>
            <UIButton containerStyle={customStyles.cancel} onClick={onClose}>
              {t("common:cancel")}
            </UIButton>
            <UIButton
              onClick={shareClickListener}
              containerStyle={customStyles.invite}
              color="pink"
              isDisabled={_.get(emails, "length", 0) === 0}
            >
              {t("common:share")}
            </UIButton>
          </div>
        </Fragment>
      )}
    </UIModal>
  );
}

const SOCIAL_DATA = [
  {
    type: SOCIAL_TYPES.FACEBOOK,
    Icon: FBIcon,
    backgroundColor: "#3b5a9a",
    textId: "common:facebook",
  },
  {
    type: SOCIAL_TYPES.TWITTER,
    Icon: TwitterIcon,
    backgroundColor: "#28aae1",
    textId: "common:twitter",
  },
  {
    type: SOCIAL_TYPES.LINKEDIN,
    Icon: LinkedinIcon,
    backgroundColor: "#0097d3",
    textId: "common:linkedin",
  },
];

const ShareModal = ({
  data = {},
  closeShareModal,
  t,
  organizationMembers,
  members,
  entityId,
  entityType,
  portalType,
  shareEntity,
  setToastMsg,
  checkAndUpdateCoachMarkStatus,
}) => {
  const [contacts, setContacts] = useState([]);
  const [isInviteBoxActive, setIsInviteBoxActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { title = {} } = data;

  const entity = {
    id: entityId,
    type: entityType,
    name: _.get(title, "value", ""),
  };

  useEffect(() => {
    recordEvent({
      eventName: "Viewed Page",
      eventData: {
        page: "Share Modal",
        entityId: entity.id,
        entityType: entity.type,
        entityName: entity.name,
        source: "community",
      },
    });
    checkAndUpdateCoachMarkStatus({
      type: "COMMUNITY_SHARE_BUTTON",
      isViewed: true,
      portalType,
    });
  }, []);

  const shareClickListener = async () => {
    const memberEmails = _.map(
      _.filter(members, member => _.includes(contacts, member.id)),
      "value"
    );
    setLoading(true);
    const isSuccess = await shareEntity({
      entityId: entity.id,
      entityType: entity.type,
      entityName: entity.name,
      typeLabel:
        entityType === "UNIT_PLAN" ? t("common:unit") : t("common:le_label"),
      emails: _.uniq(memberEmails),
    });
    setLoading(false);
    if (!isSuccess) return;
    closeShareModal();
  };

  const profileImage =
    _.get(data, "image.value", "") || RESOURCE_PLACEHOLDER_URL;
  const getNoResultText = ({ searchText }) => {
    return t("common:no_result_found_for_label", { label: searchText });
  };

  const imageUrl = profileImage
    ? getRethumbUrl({
        width: 240,
        height: 600,
        imageUrl: profileImage,
      })
    : "";

  const totalSelected = _.get(contacts, "length", 0);

  const copiedUrl = _.get(data, "copiedUrl", "");
  const facebookShareUrl = _.get(data, "facebookShareUrl", "");
  const twitterShareUrl = _.get(data, "twitterShareUrl", "");
  const linkedinShareUrl = _.get(data, "linkedinShareUrl", "");

  const contentType =
    entityType === "UNIT_PLAN" ? "unit plan" : "learning experience";

  let author = "";
  if (entityType === "UNIT_PLAN") {
    author = _.capitalize(_.get(data, "organization.name"));
  } else {
    const authorFirstName = _.capitalize(
      _.get(data, "createdBy.firstName", "")
    );
    const authorLastName = _.capitalize(_.get(data, "createdBy.lastName", ""));
    author = `${authorFirstName} ${authorLastName}`;
  }

  const shareText = `Check out this engaging ${contentType} by ${author} on Toddle!`;

  const socialShareClickListener = type => {
    recordEvent({
      eventName: "Share",
      eventData: {
        target: type,
        entityId: entity.id,
        entityType: entity.type,
        entityName: entity.name,
        source: "community",
      },
    });
    let url = "";
    switch (type) {
      case SOCIAL_TYPES.FACEBOOK:
        url = facebookShareUrl;
        break;
      case SOCIAL_TYPES.TWITTER:
        url = twitterShareUrl;
        break;
      case SOCIAL_TYPES.LINKEDIN:
        url = linkedinShareUrl;
        break;
      default:
        break;
    }
    window.open(getSharerURL(type, url, shareText), "_blank");
  };

  const emailShareClickListener = () => {
    setIsInviteBoxActive(true);
  };

  const copyURLClickListener = () => {
    recordEvent({
      eventName: "Copied to clipboard",
      eventData: {
        entityId: entity.id,
        entityType: entity.type,
        entityName: entity.name,
        source: "community",
      },
    });
    const success = copyToClipboard(copiedUrl);
    if (!success) return;
    setToastMsg({
      msg: "toastMsgs:link_copy_success",
      type: "success",
      position: "toast-bottom-left",
    });
  };

  const emailShareCloseListener = () => {
    setIsInviteBoxActive(false);
  };

  return (
    <Fragment>
      {isInviteBoxActive && (
        <EmailShareModal
          onClose={emailShareCloseListener}
          entity={entity}
          shareEntity={shareEntity}
          t={t}
        />
      )}
      <div onClick={e => e.stopPropagation()} className={classes.container}>
        <div
          className={classes.banner}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
        <div className={classes.content}>
          <div className={classes.headerContainer}>
            <div className={classes.headerWrapper}>
              <div className={classes.header}>
                {t("community:share_label_msg_new", { label: title["value"] })}
              </div>
            </div>
          </div>

          <div className={classes.body}>
            <div className={classes.shareActionsContainer}>
              {SOCIAL_DATA.map(({ type, Icon, backgroundColor, textId }) => (
                <div
                  key={type}
                  className={classes.shareAction}
                  onClick={() => socialShareClickListener(type)}
                >
                  <div
                    className={classes.shareIcon}
                    style={{ backgroundColor }}
                  >
                    <Icon width={24} height={24} fill={colors.white} />
                  </div>
                  <div className={classes.shareTitle}>{t(textId)}</div>
                </div>
              ))}
              <div
                className={classes.shareAction}
                onClick={emailShareClickListener}
              >
                <div
                  className={classes.shareIcon}
                  style={{ backgroundColor: "#F75961" }}
                >
                  <EmailIcon width={24} height={24} fill={colors.white} />
                </div>
                <div className={classes.shareTitle}>{t("common:email")}</div>
              </div>
              <div
                className={classes.shareAction}
                onClick={copyURLClickListener}
              >
                <div
                  className={classes.shareIcon}
                  style={{ backgroundColor: "#202632" }}
                >
                  <LinkIconSvg width={24} height={24} fill={colors.white} />
                </div>
                <div className={classes.shareTitle}>
                  {t("common:copy_link")}
                </div>
              </div>
            </div>

            {_.get(members, "length", 0) > 0 ? (
              <div className={classes.contactSearchContainer}>
                <SearchSelect
                  getNoResultText={getNoResultText}
                  viewMode="GRID_CARD"
                  placeholder={t("common:search_label", {
                    label: t("common:contact_count", {
                      count: _.get(members, "length", 0),
                    }),
                  })}
                  options={members}
                  value={contacts}
                  updateInputField={updatedValue => setContacts(updatedValue)}
                />
              </div>
            ) : null}
          </div>

          <div className={classes.footer}>
            <div className={classes.selectedCount}>
              {totalSelected > 0
                ? t("community:people_selected_count", { count: totalSelected })
                : ``}
            </div>
            <div className={classes.footerButtonContainer}>
              <UIButton
                size="sm"
                containerStyle={customStyles.cancel}
                onClick={closeShareModal}
              >
                {t("common:cancel")}
              </UIButton>
              <UIButton
                size="sm"
                onClick={shareClickListener}
                containerStyle={customStyles.invite}
                color="pink"
                isDisabled={totalSelected === 0}
              >
                {t("common:share")}
              </UIButton>
            </div>
          </div>
        </div>
      </div>
      {loading && <FullScreenLoader />}
    </Fragment>
  );
};

const ShareModalWithLoader = withLoader(ShareModal);

const ShareModalComponent = props => {
  const { closeShareModal } = props;
  return (
    <UIModal
      isOpen={true}
      modalContent={classes.modalContent}
      onRequestClose={closeShareModal}
    >
      <ShareModalWithLoader {...props} />
    </UIModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userInfo = getUserInfo({ portalType: ownProps.portalType });
  const plannerUserInfo = getUserInfo({ portalType: "PLANNER" });
  return {
    isData: true,
    isLoading: false,
    orgId: _.get(userInfo, "org_id"),
    plannerOrgId: _.get(plannerUserInfo, "org_id"),
  };
};

const mapActionCreators = {
  shareEntity,
  setToastMsg,
  checkAndUpdateCoachMarkStatus,
};

export default compose(
  I18nHOC({ resource: ["community", "common"] }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getEntityBasicInfoQuery, {
    name: "entityInfo",
    skip: ({ entityId }) => !entityId,
    options: ({ entityId, entityType }) => ({
      fetchPolicy: "cache-and-network",
      variables: { id: entityId, entityType },
    }),
    props: ({ entityInfo, ownProps }) => {
      const data = _.get(entityInfo, "node");
      return {
        data,
        ownProps,
        isData: ownProps.isData && !_.isEmpty(data),
        isLoading:
          entityInfo["networkStatus"] == 1 ||
          entityInfo["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  }),
  graphql(getOrganizationMembersQuery, {
    name: "organizationMembers",
    skip: ({ plannerOrgId }) => !plannerOrgId,
    options: ({ plannerOrgId, searchText }) => {
      return {
        fetchPolicy: "cache-and-network",
        variables: {
          id: plannerOrgId,
          filters: { searchText },
          portalType: "PLANNER",
        },
      };
    },
    props: ({ organizationMembers, ownProps }) => {
      const members = _.map(
        _.get(organizationMembers, "node.staff.edges"),
        ({ node: { id, firstName, lastName, email } } = {}) => ({
          id,
          label:
            !firstName && !lastName
              ? "-"
              : `${firstName || ""} ${lastName || ""}`,
          value: email,
        })
      );

      return {
        members,
        organizationMembers,
        ownProps,
        isData: ownProps.isData && !_.isEmpty(organizationMembers.node),
        isLoading:
          organizationMembers["networkStatus"] == 1 ||
          organizationMembers["networkStatus"] == 2 ||
          ownProps.isLoading,
      };
    },
  })
)(ShareModalComponent);
