import update from "immutability-helper";
import moment from "moment";
import client from "apolloClient";
import { generateAttendanceReportMutation } from "./AttendanceManagerMutations";
import { setToastMsg } from "Login/modules/LoginModule";
export const NAME = "attendanceManager";
export const UPDATE_FILTERS = "UPDATE_FILTERS" + " " + NAME;
export const SET_START_END_DATE = "SET_START_END_DATE" + " " + NAME;
export const CHANGE_STRIP_COLUMN_ID = "CHANGE_STRIP_COLUMN_ID" + " " + NAME;
export const SET_DATES_WEEK_SLOT = "SET_DATES_WEEK_SLOT" + " " + NAME;
export const SET_CLASSES_DATE = "SET_CLASSES_DATE" + " " + NAME;
export const RESET_DAILY_WEEKLY_YEARLY_MONTHLY_STATE =
  "RESET_DAILY_WEEKLY_YEARLY_MONTHLY_STATE" + " " + NAME;
export const CHANGE_SEGMENT_TAB = "CHANGE_SEGMENT_TAB" + " " + NAME;
export const RESET_ALL_STATES = "RESET_ALL_STATES" + " " + NAME;
export const TOGGLE_ANOMALY = "TOGGLE_ANOMALY" + " " + NAME;

// classes tab - table initial columns
export const onceADayColumns = ({ t }) => [
  t("common:class_plural"),
  t("common:status"),
  t("attendance:taken_by"),
  t("common:total"),
];

export const dateFormats = {
  default: "YYYY-MM-DD",
  dd: "DD",
  dd_mmm: "DD MMM",
  dd_mmm_yy: "DD MMM YY",
  dd_mmm_yyyy: "DD MMM YYYY",
  dd_mmmm_yyyy: "DD MMMM YYYY",
  mmmm_yyyy: "MMMM YYYY",
};

// classes tab - table initial columns
export const eachPeriodColumns = ({ t }) => [
  { title: t("common:class_plural"), subtitle: "" },
  { title: t("common:total"), subtitle: t("common:student_plural") },
];

export const classesTabFilterOptions = ({ grades, t }) => {
  const newFilterGrades = grades.map(grade => {
    const { name, id } = grade;
    return {
      label: name,
      value: id,
    };
  });
  // const FIRST_FILTER_OPTION = {
  //   key: "markedStatus",
  //   label: "status",
  //   isCheckList: false,
  //   options: [
  //     { label: t("attendance:marked"), value: "MARKED" },
  //     { label: t("attendance:not_marked"), value: "NOT_MARKED" }
  //   ]
  // };
  const filterOptions = [
    {
      key: "gradesStatus",
      label: "grade",
      isCheckList: false,
      allOptionLabel: t("common:all_label", {
        label: t("common:grade_plural").toLowerCase(),
      }),
      options: newFilterGrades,
    },
  ];

  return filterOptions;
};

export const studentTabFilterOptions = ({
  allCourses,
  t,
  sourceFrom,
  isRotationCycle,
}) => {
  const options = [
    {
      key: "attendanceType",
      label: "",
      isCheckList: false,
      options: _.filter(
        [
          sourceFrom === "CLASSROOM"
            ? null
            : { label: t("common:daily"), value: "DAILY" },
          { label: t("common:weekly"), value: "WEEKLY" },
          { label: t("common:monthly"), value: "MONTHLY" },
          { label: t("common:yearly"), value: "YEARLY" },
          isRotationCycle
            ? {
                label: t("common:rotation_cycle"),
                value: "ROTATION_CYCLE",
              }
            : null,
        ],
        item => !!item
      ),
    },
  ];
  if (sourceFrom !== "CLASSROOM") {
    options.unshift({
      key: "classesType",
      label: "",
      isCheckList: true,
      options: allCourses,
      allOptionLabel: t("common:all_label", {
        label: `${t("common:class_plural")}`,
      }),
      noOptionLabel: t("common:no_with_label", {
        label: t("common:class_plural"),
      }),
    });
  }
  return options;
};

export const updateFilters = data => {
  return {
    type: UPDATE_FILTERS,
    data,
  };
};
export const setStartEndDate = data => {
  return {
    type: SET_START_END_DATE,
    data,
  };
};
export const setDatesWeekSlot = data => {
  return {
    type: SET_DATES_WEEK_SLOT,
    data,
  };
};
export const changeStripColumnId = data => {
  return {
    type: CHANGE_STRIP_COLUMN_ID,
    data,
  };
};
export const setClassesDate = data => {
  return {
    type: SET_CLASSES_DATE,
    data,
  };
};

export const resetDailyWeeklyYearlyMonthlyState = () => {
  return {
    type: RESET_DAILY_WEEKLY_YEARLY_MONTHLY_STATE,
  };
};
export const changeSegmentTab = data => {
  return {
    type: CHANGE_SEGMENT_TAB,
    data,
  };
};
export const resetAllStates = data => {
  return {
    type: RESET_ALL_STATES,
    data,
  };
};
export const toggleAnomaly = data => {
  return {
    type: TOGGLE_ANOMALY,
    data,
  };
};

const REDUCER_HANDLERS = {
  [UPDATE_FILTERS]: (state, action) => {
    const { key, value, filterName } = action.data;
    if (filterName === "studentFilters") {
      /**
       * This will reset the strip details of DAILY MONTHLY YEARLY of students
       */
      return update(state, {
        [filterName]: {
          [key]: { $set: value },
          ["dateSlot"]: {
            ["DAILY"]: {
              ["selectedOptionIds"]: { $set: [] },
              ["attendanceInsightOptions"]: { $set: "ALL" },
            },
            ["MONTHLY"]: {
              ["attendanceInsightOptions"]: { $set: "ALL" },
            },
            ["YEARLY"]: {
              ["attendanceInsightOptions"]: { $set: "ALL" },
            },
          },
        },
      });
    }
    return update(state, {
      [filterName]: { [key]: { $set: value } },
    });
  },
  [SET_START_END_DATE]: (state, action) => {
    const { startDate, endDate, attendanceType } = action.data;
    return update(state, {
      ["studentFilters"]: {
        ["dateSlot"]: {
          [attendanceType]: {
            ["endDate"]: { $set: endDate },
            ["startDate"]: { $set: startDate },
            ["selectedOptionIds"]: { $set: [] },
            ["attendanceInsightOptions"]: { $set: "ALL" },
          },
        },
      },
    });
  },
  [SET_DATES_WEEK_SLOT]: (state, action) => {
    const { startDate, endDate, weekLength } = action.data;
    return update(state, {
      ["studentFilters"]: {
        ["dateSlot"]: {
          ["WEEKLY"]: {
            ["endDate"]: { $set: endDate },
            ["startDate"]: { $set: startDate },
            ["weekLength"]: { $set: weekLength },
          },
        },
      },
    });
  },
  [SET_CLASSES_DATE]: (state, action) => {
    const { endDate } = action.data;
    return update(state, {
      ["classesFilters"]: {
        ["endDate"]: { $set: endDate },
      },
    });
  },
  [CHANGE_STRIP_COLUMN_ID]: (state, action) => {
    const { id, attendanceType, call } = action.data;
    if (call === "BACK") {
      return update(state, {
        ["studentFilters"]: {
          ["dateSlot"]: {
            [attendanceType]: {
              ["selectedOptionIds"]: { $set: [id] },
            },
          },
        },
      });
    } else {
      return update(state, {
        ["studentFilters"]: {
          ["dateSlot"]: {
            [attendanceType]: {
              ["attendanceInsightOptions"]: { $set: id },
              ["selectedOptionIds"]: { $set: [] },
            },
          },
        },
      });
    }
  },
  [RESET_DAILY_WEEKLY_YEARLY_MONTHLY_STATE]: (state, action) => {
    return update(state, {
      ["studentFilters"]: {
        ["dateSlot"]: {
          ["DAILY"]: {
            ["attendanceInsightOptions"]: { $set: "ALL" },
            ["selectedOptionIds"]: { $set: [] },
            ["isDateBlocked"]: { $set: false },
          },
          ["WEEKLY"]: {
            ["attendanceInsightOptions"]: { $set: "ALL" },
            ["selectedOptionIds"]: { $set: [] },
            ["isDateBlocked"]: { $set: false },
          },
          ["MONTHLY"]: {
            ["attendanceInsightOptions"]: { $set: "ALL" },
          },
          ["YEARLY"]: {
            ["attendanceInsightOptions"]: { $set: "ALL" },
          },
        },
      },
    });
  },
  [CHANGE_SEGMENT_TAB]: (state, action) => {
    const { tab } = action.data;
    /**
     * this feature is for to set same date while switching the tabs
     */
    resetDailyWeeklyYearlyMonthlyState();
    let newDate;
    if (tab === "CLASSES") {
      newDate = _.get(
        state,
        "studentFilters.dateSlot.DAILY.endDate",
        TODAY_DATE
      );

      return update(state, {
        ["selectedTab"]: { $set: tab },
        ["classesFilters"]: {
          ["endDate"]: { $set: newDate },
        },
      });
    } else {
      newDate = _.get(state, "classesFilters.endDate", TODAY_DATE);

      return update(state, {
        ["selectedTab"]: { $set: tab },
        ["studentFilters"]: {
          ["dateSlot"]: {
            ["DAILY"]: {
              ["endDate"]: { $set: newDate },
              ["startDate"]: { $set: newDate },
            },
          },
        },
      });
    }
  },
  [RESET_ALL_STATES]: (state, action) => {
    resetDailyWeeklyYearlyMonthlyState();
    return update(state, {
      ["selectedTab"]: { $set: "CLASSES" },
      ["classesFilters"]: {
        ["markedStatus"]: { $set: "ALL" },
        ["gradesStatus"]: { $set: "ALL" },
        ["searchText"]: { $set: "" },
        ["endDate"]: { $set: TODAY_DATE },
      },
      ["studentFilters"]: {
        ["classesType"]: { $set: [] },
      },
    });
  },
  [TOGGLE_ANOMALY]: (state, action) => {
    const { filterName, reset } = action.data;
    if (reset) {
      return update(state, {
        ["classesFilters"]: {
          showAnomalies: { $set: false },
        },
        ["studentFilters"]: {
          showAnomalies: { $set: false },
        },
      });
    }
    return update(state, {
      [filterName]: {
        showAnomalies: { $set: !_.get(state, `${filterName}.showAnomalies`) },
      },
    });
  },
};

// Initial State and export Reducer
const TODAY_DATE = moment().format("YYYY-MM-DD");
const DAY_5_BACK = moment().subtract(4, "days").format("YYYY-MM-DD");
const MONTH_START_DATE = moment().startOf("month").format("YYYY-MM-DD");
const MONTH_END_DATE = moment().endOf("month").format("YYYY-MM-DD");
const YEAR_START_DATE = moment().startOf("year").format("YYYY-MM-DD");
const YEAR_END_DATE = moment().endOf("year").format("YYYY-MM-DD");
const initialState = {
  selectedTab: "CLASSES",
  classesFilters: {
    markedStatus: "ALL",
    gradesStatus: "ALL",
    searchText: "",
    endDate: TODAY_DATE,
    showAnomalies: false,
  },
  studentFilters: {
    attendanceType: "DAILY",
    classesType: [],
    searchText: "",
    dateSlot: {
      DAILY: {
        endDate: TODAY_DATE,
        startDate: TODAY_DATE,
        selectedOptionIds: [],
        attendanceInsightOptions: "ALL",
        weekLength: 1,
        isDateBlocked: false,
      },
      WEEKLY: {
        endDate: TODAY_DATE,
        startDate: DAY_5_BACK,
        attendanceInsightOptions: "ALL",
        selectedOptionIds: [],
        weekLength: 1,
        isDateBlocked: false,
      },
      MONTHLY: {
        startDate: MONTH_START_DATE,
        endDate: MONTH_END_DATE,
        attendanceInsightOptions: "ALL",
      },
      YEARLY: {
        startDate: YEAR_START_DATE,
        endDate: YEAR_END_DATE,
        attendanceInsightOptions: "ALL",
      },
      ROTATION_CYCLE: {
        endDate: TODAY_DATE,
        startDate: DAY_5_BACK,
        attendanceInsightOptions: "ALL",
        selectedOptionIds: [],
        weekLength: 1,
        isDateBlocked: false,
      },
    },
    showAnomalies: false,
  },
  isPeriodByAttendance: false,
  periodSet: {},
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const getMonthOptions = (
  fromTimeStamp,
  toTimeStamp,
  formatStr = "MMMM YYYY"
) => {
  const options = [];
  let currTimeStamp = toTimeStamp;
  while (+moment(currTimeStamp) >= +moment(fromTimeStamp)) {
    options.push({
      label: moment(currTimeStamp).format(formatStr),
      value: currTimeStamp,
    });
    currTimeStamp = moment(currTimeStamp).subtract(1, "month").toISOString();
  }
  if (
    options.length &&
    moment(fromTimeStamp).format(formatStr) !== _.get(_.last(options), "label")
  ) {
    options.push({
      label: moment(fromTimeStamp).format(formatStr),
      value: fromTimeStamp,
    });
  }
  return _.reverse(options);
};

export const generateAttendanceReport = ({
  startDate,
  endDate,
  courseIds,
  toggleFullScreenLoader,
  toggleDownloadModal,
  curriculumId,
  academicYearId,
}) => {
  return async dispatch => {
    try {
      const result = await client.mutate({
        mutation: generateAttendanceReportMutation,
        variables: {
          startDate,
          endDate,
          courseIds,
          curriculumId,
          academicYearId,
        },
      });
      toggleDownloadModal(false);
      return _.get(result, "data.platform.generateAttendanceReport.url", null);
    } catch (e) {
      if (e.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    } finally {
      toggleFullScreenLoader();
    }
  };
};

const getContainingRange = ({
  currDate,
  groupedRotationDays,
  startIndex,
  endIndex,
}) => {
  if (startIndex > endIndex) {
    return {};
  }
  const midIndex = Math.floor((startIndex + endIndex) / 2);
  if (
    currDate.isSameOrAfter(groupedRotationDays[midIndex].start, "day") &&
    currDate.isSameOrBefore(groupedRotationDays[midIndex].end, "day")
  ) {
    return {
      group: groupedRotationDays[midIndex],
      index: midIndex,
    };
  } else if (currDate.isBefore(groupedRotationDays[midIndex].start)) {
    return getContainingRange({
      currDate,
      groupedRotationDays,
      startIndex,
      endIndex: midIndex - 1,
    });
  }
  return getContainingRange({
    currDate,
    groupedRotationDays,
    startIndex: midIndex + 1,
    endIndex,
  });
};

const getRotationDayRange = ({ groupedRotationDays }) => {
  const currDate = moment();
  const containingRange = getContainingRange({
    currDate,
    groupedRotationDays,
    startIndex: 0,
    endIndex: groupedRotationDays.length - 1,
  });
  return containingRange;
};

export const setRotationDaysGroup = ({
  index,
  offset,
  groups,
  setStartEndDate,
  setCurrGroupIndex,
  setCurrentRotationDayGroup,
}) => {
  const group = groups[index + offset];
  const items = _.get(group, "items", []);
  setStartEndDate({
    endDate: moment(_.get(_.last(items), "date", "")).format(
      dateFormats.default
    ),
    startDate: moment(_.get(_.first(items), "date", "")).format(
      dateFormats.default
    ),
    attendanceType: "ROTATION_CYCLE",
  });
  setCurrGroupIndex(index + offset);
  setCurrentRotationDayGroup(group);
};

export const getRotationDayRangeMemoize = _.memoize(
  params => getRotationDayRange(params),
  params => JSON.stringify(params)
);
