import PropTypes from "prop-types";
import React from "react";

const LeftIcon = props => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 13.00023 24"
      width={props.width}
      height={props.height}
    >
      <g id="Page-1">
        <g id="create_popup" data-name="create popup">
          <g id="Group">
            <g id="Group-2" fill={props.fill && props.fill}>
              <path
                id="left_arrow"
                data-name="left arrow"
                d="M2.41444,12.00031,12.70623,1.7085a1.00054,1.00054,0,0,0-1.414-1.416L.2925,11.2923a1.00313,1.00313,0,0,0,0,1.416L11.29227,23.70795a.9998.9998,0,0,0,1.414-1.41391Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LeftIcon.defaultProps = {
  width: 16,
  height: 16,
};

LeftIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
export default LeftIcon;
