import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import classes from "./WorkbookIframePortal.scss";
import PropTypes from "prop-types";
import { WORK_BOOK_URL } from "Constants";

/*
Functionality:- 
=> if first time load ,init workbook & store Workbook in cache 
=> else use the preloaded workbook component

Approach:
=> Using portal ,on mount iframe in DOM 
=> On Unmount display none the portal
=> For second time mount toggle display tag to use portal
*/
class WorkbookIframePortal extends PureComponent {
  constructor(props) {
    super(props);
    this.iframe = null;
    // window.map = this;

    const { iframeIdSelector, iframeSrc } = this.props;

    //check if iframe already mounted
    const workbookIframeDOM = document.getElementById(iframeIdSelector);
    this.isIframeAlreadyPresent = !_.isEmpty(workbookIframeDOM) ? true : false;

    if (this.isIframeAlreadyPresent) {
      this.iframe = workbookIframeDOM;
    } else {
      this.iframe = document.createElement("iframe");
      this.iframe.classList.add(classes.overlayContainer);
      this.iframe.id = iframeIdSelector;
      this.iframe.src = iframeSrc;
      this.iframe.referrerPolicy = "origin";
      this.iframe.frameBorder = "0";
      this.iframe.style.border = "0px";
      this.iframe.allow = "camera; microphone;fullscreen;";
    }
  }

  componentDidMount() {
    const { parentRef, onIframeLoad } = this.props;
    if (this.isIframeAlreadyPresent) {
      this.iframe.style.display = "block";
      if (onIframeLoad) onIframeLoad(this.iframe);
    } else {
      this.iframe.onload = () => {
        if (onIframeLoad) onIframeLoad(this.iframe);
      };
      if (parentRef) {
        this.iframe.style.position = "relative";
        parentRef.appendChild(this.iframe);
      } else {
        document.body.appendChild(this.iframe);
      }
    }
  }

  componentWillUnmount() {
    this.iframe.style.display = "none";
    this.iframe.onload = () => {};
    if (this.props.onIframeUnMount) this.props.onIframeUnMount();
  }
  /*
   * add portal only once when component mounts & reuse it in next renders
   */
  render() {
    if (this.isIframeAlreadyPresent) {
      return null;
    }
    return ReactDOM.createPortal("", this.iframe);
  }
}

WorkbookIframePortal.defaultProps = {
  iframeSrc: WORK_BOOK_URL,
};

WorkbookIframePortal.propTypes = {
  iframeIdSelector: PropTypes.string,
  iframeSrc: PropTypes.string,
  parentRef: PropTypes.string,
  onIframeLoad: PropTypes.func,
  onIframeUnMount: PropTypes.func,
};
export default WorkbookIframePortal;
