import client from "apolloClient";

import {
  getCourseCalendarDetailsQuery,
  getCalendarEventsQuery,
  getCalendarEventDetailsQuery,
  getCalendarEventsTrimmedQuery,
  getCalendarOtherCoursesDetailQuery,
} from "./MyCalendarQuery";

export const getCourseCalendarDetailsFromCache = ({ id, type, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCourseCalendarDetailsQuery,
      variables: {
        id,
        type,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCalendarOtherCoursesDetailFromCache = ({ variables }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCalendarOtherCoursesDetailQuery,
      variables,
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCalendarEventsFromCache = ({ id, type, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCalendarEventsQuery,
      variables: {
        id,
        type,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCalendarEventDetailsFromCache = ({ id, type }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCalendarEventDetailsQuery,
      variables: {
        id,
        type,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};

export const getCalendarEventsTrimmedFromCache = ({ id, type, filters }) => {
  let queryData = {};
  try {
    queryData = client.readQuery({
      query: getCalendarEventsTrimmedQuery,
      variables: {
        id,
        type,
        filters,
      },
    });
  } catch (e) {
    return {};
  }
  if (queryData != null) return _.get(queryData, "node", {});
  else return {};
};
