import React from "react";
import PropTypes from "prop-types";

const NoteIconV2 = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10.1949C4 7.74458 6.0027 5.88135 8.34681 5.88135H17.6878C18.1986 5.88135 18.6127 6.29225 18.6127 6.79913C18.6127 7.306 18.1986 7.71691 17.6878 7.71691H8.34681C6.9915 7.71691 5.8497 8.79054 5.8497 10.1949V23.6863C5.8497 25.0312 6.93162 26.1643 8.34681 26.1643H21.9421C23.2974 26.1643 24.4392 25.0907 24.4392 23.6863V14.4167C24.4392 13.9098 24.8533 13.4989 25.3641 13.4989C25.8749 13.4989 26.2889 13.9098 26.2889 14.4167V23.6863C26.2889 26.1366 24.2863 27.9999 21.9421 27.9999H8.34681C5.87761 27.9999 4 26.0125 4 23.6863V10.1949Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0115 5.83561C23.9334 5.83561 23.8185 5.86808 23.706 5.9798L14.8421 14.7759L14.2848 17.6104L17.1411 17.0573L26.0049 8.2612C26.0247 8.24158 26.0454 8.22287 26.0668 8.20511C26.1239 8.15796 26.1503 8.09974 26.1503 8.0268C26.1503 7.94929 26.1175 7.8353 26.005 7.72358L24.2477 5.9798C24.228 5.96018 24.2091 5.93969 24.1912 5.91838C24.1437 5.8618 24.085 5.83561 24.0115 5.83561ZM25.5815 4.70747C24.7151 3.71845 23.2673 3.8192 22.398 4.68186L13.3345 13.6761C13.2043 13.8053 13.116 13.97 13.0807 14.1493L12.3443 17.8943C12.2193 18.4374 12.4383 18.9226 12.7686 19.2176C13.0629 19.4804 13.5308 19.6626 14.0219 19.5314L17.7725 18.8052C17.9531 18.7702 18.1192 18.6826 18.2493 18.5534L27.2871 9.58475C28.2837 8.72498 28.1822 7.2883 27.3129 6.42564L25.5815 4.70747Z"
        fill={props.fill}
      />
    </svg>
  );
};

NoteIconV2.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

NoteIconV2.defaultProps = {
  height: 24,
  width: 24,
};

export default NoteIconV2;
