import React from "react";
import classes from "./ExamSessionComp.scss";
import { RadioButton } from "@toddle-design/web";
import _ from "lodash";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { useI18n } from "Hooks";

//TODO : To be exposed in globalConstants (BE)
const getExamSessionOptions = ({ t }) => {
  return [
    {
      label: t("onBoarding:may"),
      value: "MAY",
    },
    {
      label: t("onBoarding:november"),
      value: "NOVEMBER",
    },
  ];
};

const ExamSessionComp = props => {
  const { examSessionMonth, updateOrgObject, disabled = false } = props;
  const { t } = useI18n(["onBoarding"]);

  const examSessionOptions = getExamSessionOptions({ t });

  return (
    <div className={classes.examSessionContainer}>
      <div className={classes.examSessionTitle}>
        {t("onBoarding:select_main_exam_session")}
      </div>
      <div className={classes.examSessionOptionsContainer}>
        {_.map(examSessionOptions, option => (
          <div className={classes.examSessionOption} key={option.value}>
            <span className={classes.examSessionLabel}>{option.label}</span>
            <RadioButton
              onChange={() =>
                updateOrgObject({ examSessionMonth: option.value })
              }
              checked={examSessionMonth === option.value}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const selectedSubjects = state?.dpSubjectsSetup?.selectedSubjects;

  return {
    selectedSubjects,
  };
};

export default compose(connect(mapStateToProps, null))(ExamSessionComp);
