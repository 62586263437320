import PropTypes from "prop-types";
import React from "react";

const AddAssessment = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 25 26"
      version="1.1"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="Toddle_icon_add-tag-(1)"
          fill={props.fill && props.fill}
          fillRule="nonzero"
        >
          <path
            d="M19.9004,15.7002 L12.7004,22.9002 C12.2994,23.3002 11.7004,23.3002 11.2994,22.9002 L2.2994,13.9002 C2.1004,13.7002 2.0004,13.4002 2.0004,13.2002 L2.0004,6.0002 C2.0004,5.4002 2.4004,5.0002 3.0004,5.0002 L10.2004,5.0002 C10.5004,5.0002 10.7004,5.1002 10.9004,5.3002 L19.9004,14.3002 C20.0994,14.5002 20.2004,14.7002 20.2004,15.0002 C20.2004,15.3002 20.0994,15.5002 19.9004,15.7002 Z M12.2994,3.9002 C11.7004,3.3002 11.0004,3.0002 10.2004,3.0002 L3.0004,3.0002 C1.2994,3.0002 0.0004,4.3002 0.0004,6.0002 L0.0004,13.2002 C0.0004,14.0002 0.2994,14.8002 0.9004,15.3002 L9.9004,24.3002 C10.5004,24.9002 11.2994,25.2002 12.0004,25.2002 C12.7004,25.2002 13.5004,24.9002 14.0994,24.3002 L21.2994,17.0992 C21.9004,16.5002 22.2004,15.8002 22.2004,15.0002 C22.2004,14.2002 21.9004,13.4002 21.2994,12.9002 L12.2994,3.9002 Z"
            id="Shape"
          ></path>
          <path
            d="M24,3 L22,3 L22,1 C22,0.4 21.6,-3.55271368e-15 21,-3.55271368e-15 C20.4,-3.55271368e-15 20,0.4 20,1 L20,3 L18,3 C17.4,3 17,3.4 17,4 C17,4.6 17.4,5 18,5 L20,5 L20,7 C20,7.6 20.4,8 21,8 C21.6,8 22,7.6 22,7 L22,5 L24,5 C24.6,5 25,4.6 25,4 C25,3.4 24.6,3 24,3 M7,11 C6.4,11 6,10.6 6,10 C6,9.4 6.4,9 7,9 C7.6,9 8,9.4 8,10 C8,10.6 7.6,11 7,11 M7,7 C5.3,7 4,8.3 4,10 C4,11.7 5.3,13 7,13 C8.7,13 10,11.7 10,10 C10,8.3 8.7,7 7,7"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

AddAssessment.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

AddAssessment.defaultProps = {
  height: 24,
  width: 24,
};

export default AddAssessment;
