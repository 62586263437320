import React, { memo, useEffect, useRef, useImperativeHandle } from "react";

import classes from "./CurriculumOptions.scss";
import { I18nHOC, FormTextInput, FormSelectDropdown } from "UIComponents";
import propTypes from "prop-types";
import { graphql, compose } from "react-apollo";
import { getGlobalGradesQuery } from "./modules/CurriculumOptionsQuery";
import { getGlobalGradesFromCache } from "./modules/CurriculumOptionGraphQLHelpers.js";
import { connect } from "react-redux";
import { CURRICULUM_TYPE_DP } from "Constants/stringConstants";
import { getCurriculumPlans } from "../../modules/UpsertOrganizationModule";
import ExamSessionComp from "Tooling/routes/Organization/components/ExamSessionComp";
import {
  getCurriculumProgramAcronym,
  getCurriculumProgramName,
  MAXIMUM_ACRONYM_LENGTH,
} from "./modules/CurriculumOptionsUtils";

import ACLStore from "lib/aclStore";
import { ENABLE_MULTIPLE_CURRICULUM_PROGRAMS } from "Constants/permissionConstants";
import _ from "lodash";

const CurriculumOptions = ({
  mode,
  t,
  curriculumProgram,
  curriculumProgramOptions,
  updateCurriculumProgram,
  toddleBuddiesOptions,
  globalGradeOptions,
  customRef,
}) => {
  const {
    type,
    name,
    toddleBuddy,
    plan,
    grades,
    examSessionMonth,
    acronym,
    isTypeEditable,
    orgDpExamSession,
  } = curriculumProgram;

  const nameRef = useRef();

  const acronymRef = useRef();

  const typeRef = useRef();

  const toddleBuddyRef = useRef();

  const gradeRef = useRef();

  const planRef = useRef();

  const previousTypeRef = useRef(type);
  useImperativeHandle(customRef, () => {
    return {
      isValid: () => {
        const refs = [
          typeRef,
          nameRef,
          acronymRef,
          toddleBuddyRef,
          planRef,
          gradeRef,
        ];

        const totalErrors = _.reduce(
          refs,
          (result, ref) => {
            return result + ref.current.isValid();
          },
          0
        );

        return totalErrors;
      },
    };
  });

  useEffect(() => {
    if (!_.isEmpty(type) && previousTypeRef.current !== type) {
      const defaultName = getCurriculumProgramName({
        type,
      });

      const defaultAcronym = getCurriculumProgramAcronym({ type });

      updateCurriculumProgram({
        id: curriculumProgram.id,
        name: defaultName,
        acronym: defaultAcronym,
      });

      nameRef.current.resetErrorState();
      acronymRef.current.resetErrorState();
    }
  }, [type]);

  useEffect(() => {
    previousTypeRef.current = type;
  }, [type]);

  const updateCurriculumProgramType = value => {
    const payload = { id: curriculumProgram.id, type: value };
    updateCurriculumProgram(payload);
  };
  const curriculumPlans = getCurriculumPlans({ currentPlan: plan, mode });

  const validateAcronym = value => {
    const length = _.size(value);
    if (length > MAXIMUM_ACRONYM_LENGTH) {
      return t("onBoarding:programme_acronym_warning");
    }
    return "";
  };

  return (
    <div className={classes.container}>
      <FormSelectDropdown
        options={curriculumProgramOptions}
        value={type}
        size="x-large"
        updateInputField={updateCurriculumProgramType}
        placeholder={t("onBoarding:choose_curriculum_program")}
        isRequired
        label={t("onBoarding:select_curriculum")}
        ref={typeRef}
        disabled={!isTypeEditable}
      />
      <div className={classes.flexRow}>
        <div className={classes.input}>
          <FormTextInput
            value={name}
            updateInputField={value =>
              updateCurriculumProgram({ id: curriculumProgram.id, name: value })
            }
            size="x-large"
            isRequired
            label={t("onBoarding:enter_programme_name")}
            placeholder={t("common:enter_text_here")}
            ref={nameRef}
          />
        </div>
        <div className={classes.input}>
          <FormTextInput
            value={acronym}
            updateInputField={value =>
              updateCurriculumProgram({
                id: curriculumProgram.id,
                acronym: value,
              })
            }
            size="x-large"
            label={t("onBoarding:enter_programme_acronym")}
            placeholder={t("common:enter_text_here")}
            isRequired
            ref={acronymRef}
            customValidation={validateAcronym}
          />
        </div>
      </div>
      <div className={classes.flexRow}>
        <div className={classes.input}>
          <FormSelectDropdown
            value={toddleBuddy}
            size="x-large"
            updateInputField={value =>
              updateCurriculumProgram({
                id: curriculumProgram.id,
                toddleBuddy: value,
              })
            }
            options={toddleBuddiesOptions}
            placeholder={t("onBoarding:select_toddle_buddy")}
            isRequired
            label={t("onBoarding:select_toddle_buddy")}
            ref={toddleBuddyRef}
            menuPlacement="top"
          />
        </div>
        <div className={classes.input}>
          <FormSelectDropdown
            value={plan}
            options={curriculumPlans}
            updateInputField={value =>
              updateCurriculumProgram({ id: curriculumProgram.id, plan: value })
            }
            size="x-large"
            placeholder={t("onBoarding:select_plan")}
            label={t("onBoarding:select_plan")}
            isRequired
            ref={planRef}
            menuPlacement="top"
          />
        </div>
      </div>

      <FormSelectDropdown
        value={grades}
        multi
        size="x-large"
        options={globalGradeOptions}
        placeholder={t("onBoarding:select_grades_applicable")}
        updateInputField={values =>
          updateCurriculumProgram({ id: curriculumProgram.id, grades: values })
        }
        isRequired
        label={t("onBoarding:select_grades_for_programme")}
        ref={gradeRef}
        menuPlacement="top"
      />

      {type == CURRICULUM_TYPE_DP && (
        <ExamSessionComp
          examSessionMonth={examSessionMonth}
          disabled={_.isEqual(mode, "edit") && !_.isEmpty(orgDpExamSession)}
          updateOrgObject={param =>
            updateCurriculumProgram({ id: curriculumProgram.id, ...param })
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = () => {
  const isMultipleCurriculumProgramEnabled = ACLStore.can(
    ENABLE_MULTIPLE_CURRICULUM_PROGRAMS
  );

  return {
    isLoading: false,
    isData: true,
    isMultipleCurriculumProgramEnabled,
  };
};
export default compose(
  I18nHOC({ resource: ["onBoarding"] }),
  connect(mapStateToProps, null),
  graphql(getGlobalGradesQuery, {
    name: "getGlobalGrades",
    options: () => ({
      fetchPolicy: "cache-first",
    }),
    props: ({ getGlobalGrades, ownProps: { isData, isLoading } }) => {
      const globalGrades = getGlobalGradesFromCache();

      const globalGradeOptions = _.map(globalGrades, ({ id, name }) => ({
        value: id,
        label: name,
      }));
      return {
        globalGrades,
        globalGradeOptions,
        isData: !_.isEmpty(getGlobalGrades) && isData,
        isLoading:
          _.includes([1, 2], getGlobalGrades["networkStatus"]) || isLoading,
      };
    },
  })
)(memo(CurriculumOptions));

CurriculumOptions.propTypes = {
  mode: propTypes.string,
  t: propTypes.func,
  curriculumProgram: propTypes.object,
  curriculumProgramOptions: propTypes.array,
  updateCurriculumProgram: propTypes.func,
  toddleBuddiesOptions: propTypes.array,
  globalGradeOptions: propTypes.array,
  curriculumProgramIndex: propTypes.number,
  updateCurriculumProgramInputRefs: propTypes.func,
};
