import React from "react";
import classes from "./TagResource.scss";
import ScreenPanel from "../../ScreenPanel";

import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";

import { getResourceBucketQuery } from "Post/modules/PostQuery";

import { I18nHOC, UIButton } from "UIComponents";

import { updatePost } from "Post/modules/PostModule";

import { getCurrentAcademicYear } from "modules/Services";

import moment from "moment";

import ResourceList from "./ResourceList";

const styles = {
  titleTextStyle: {
    fontSize: "2.2rem",
  },
};

const FooterComponent = React.memo(props => {
  const { onNextClick, isDisabled, t } = props;
  return (
    <div className={classes.footerButtonContainer}>
      <div />
      <UIButton
        color={"pink"}
        type={"filled"}
        size={"sm"}
        onClick={onNextClick}
        isDisabled={isDisabled}
      >
        {t("next")}
      </UIButton>
    </div>
  );
});

FooterComponent.displayName = "FooterComponent";

class TagResource extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      resourceId: props.resourceId,
      resourceType: props.resourceType,
      isResourceSkipped: props.isResourceSkipped,
    };
  }

  onNextClick = () => {
    const {
      screenPanelProps: {
        goToNextScreen,
        postDetails: {
          isResourceSkipped: propIsResourceSkipped,
          resourceItem: propResourceItem,
          elementPYP: propElementPYP,
        },
      },
      resources,
    } = this.props;

    const { resourceId, isResourceSkipped } = this.state;
    const resourceItem = _.find(resources, item => item.id == resourceId);

    //first check if resourceItem exists and is equal
    let updatedElementPYP = [];

    if (isResourceSkipped) {
      if (propIsResourceSkipped) {
        updatedElementPYP = _.cloneDeep(propElementPYP);
      } else {
        updatedElementPYP = undefined;
      }
    } else {
      if (
        propResourceItem == undefined ||
        (resourceItem != undefined &&
          (resourceItem.id != propResourceItem.id ||
            resourceItem.type != propResourceItem.type))
      ) {
        updatedElementPYP = undefined;
      } else {
        updatedElementPYP = _.cloneDeep(propElementPYP);
      }
    }

    this.props.updatePost({
      elementPYP: updatedElementPYP,
      resourceItem: resourceItem,
      resourceType: _.get(resourceItem, "type", null),
      isResourceSkipped,
    });
    goToNextScreen();
  };

  skipResource = () => {
    this.setState({
      isResourceSkipped: !this.state.isResourceSkipped,
      resourceId: null,
      resourceType: null,
    });
  };

  onResourceClicked = resource => {
    const { resourceId } = this.state;
    this.setState({
      resourceId: resource.id !== resourceId ? resource.id : null,
      resourceType: resource.id !== resourceId ? resource.type : null,
      isResourceSkipped: resource.id == resourceId,
    });
  };

  render() {
    const {
      screenPanelProps,
      resources,
      isLoading,
      isData,
      t,
      assessmentCount,
      leCount,
      curriculumProgramType,
    } = this.props;

    const { isResourceSkipped, resourceType, resourceId } = this.state;

    return (
      <ScreenPanel
        {...screenPanelProps}
        titleTextStyle={styles.titleTextStyle}
        footerComponent={
          <FooterComponent
            t={t}
            isDisabled={!isResourceSkipped && !resourceId}
            onNextClick={this.onNextClick}
          />
        }
      >
        <div className={classes.container}>
          <ResourceList
            isLoading={isLoading}
            isData={isData}
            resources={resources}
            resourceId={resourceId}
            resourceType={resourceType}
            isResourceSkipped={isResourceSkipped}
            onResourceSkipClicked={this.skipResource}
            onItemClicked={this.onResourceClicked}
            showLoaderBar={false}
            assessmentCount={assessmentCount}
            leCount={leCount}
            curriculumProgramType={curriculumProgramType}
          />
        </div>
      </ScreenPanel>
    );
  }
}

const mapActionCreators = {
  updatePost,
};

const groupType = ["LEARNING_ENGAGEMENT", "ASSESSMENT"];

const mapStateToProps = state => {
  const organizationId = state.login.userInfo.org_id;
  const courseId = state.teacher.selected_class.selected_course;
  const {
    unitPlan,
    resourceItem = {},
    resourceType,
    attachments,
    isResourceSkipped,
    elementPYP,
  } = state.post.postDetails;
  const dateOfToday = moment().format("YYYY-MM-DD");
  const currentAcademicYear = getCurrentAcademicYear({
    organizationId: organizationId,
  });
  const startDate = moment(_.get(currentAcademicYear, "startDate", "")).format(
    "YYYY-MM-DD"
  );
  const endDate = moment(_.get(currentAcademicYear, "endDate", "")).format(
    "YYYY-MM-DD"
  );

  return {
    startDate,
    endDate,
    mode: state.post.postCreationMode,
    unitPlanId: _.get(unitPlan, "id", ""),
    templateId: _.get(unitPlan, "templateId", ""),
    resourceId: _.get(resourceItem, "id", ""),
    resourceType,
    attachments,
    courseId,
    isResourceSkipped,
    elementPYP,
    showLoaderBar: false,
    resourceItem,
    curriculumProgramType: state.platform?.currentCurriculumProgramType,
  };
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators),

  graphql(getResourceBucketQuery, {
    name: "getResourceBucket",
    options: ({ unitPlanId }) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: unitPlanId,
        type: "UNIT_PLAN",
      },
    }),
    props: ({ getResourceBucket, ownProps: { resourceItem } }) => {
      const resourceLibrary = _.get(
        getResourceBucket,
        "node.resourceLibrary",
        {}
      );
      const edges = _.get(resourceLibrary, "edges", []);

      const resources = _.map(edges, item => {
        return { type: item.type, ...item.node };
      });

      return {
        getResourceBucket,
        leCount: _.get(resourceLibrary, "leCount", 0),
        assessmentCount: _.get(resourceLibrary, "assessmentCount", 0),
        resources: !_.isEmpty(resourceItem)
          ? _.uniqBy([resourceItem, ...resources], "id")
          : resources,
        isData: !!_.get(getResourceBucket, "node.resourceLibrary", null),
        isLoading:
          getResourceBucket["networkStatus"] == 1 ||
          getResourceBucket["networkStatus"] == 2,
      };
    },
  })
)(TagResource);
