import React from "react";
import PropTypes from "prop-types";
import classes from "./UIBaseComponent.scss";
import { UILabel, EmptyField, LockOverlay } from "UIComponents";
import { Translation } from "react-i18next";
import { getStringAutoLinks, scrollIntoView } from "Utils";

class UIBaseComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: "", isFocused: false };

    if (props.customRef) {
      props.customRef(this);
    }
    this.inputRef = null;
  }

  updateInputRef = ref => {
    const { updateInputRef } = this.props;
    this.inputRef = ref;
    if (updateInputRef) {
      updateInputRef(ref);
    }
  };

  blur = () => {
    if (this.inputRef && this.inputRef.blur) {
      this.inputRef.blur();
    }
  };

  focus = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  isFocused = () => {
    return this.state.isFocused;
  };

  isValidValue = value => {
    if (_.isNumber(value)) {
      return true;
    } else if (_.isString(value)) {
      return _.get(value.trim(), "length", 0) != 0;
    } else {
      return _.get(value, "length", 0) != 0;
    }
  };

  isValid = () => {
    const { value, error, customValidation, name, isRequired } = this.props;

    if (!this.isValidValue(value)) {
      if (
        (!value || (_.get(value, "trim") && value.trim() === "")) &&
        isRequired
      ) {
        this.setState({ error: "Required" });
      } else {
        this.setState({ error: error });
      }
      return 1;
    } else if (customValidation) {
      const customError = customValidation(value);
      if (customError != "") {
        this.setState({ error: customError });
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  updateValue = (value, extraParams) => {
    const { updateInputField, name, errorResolve, isRequired } = this.props;
    const { error } = this.state;
    if (
      updateInputField &&
      (JSON.stringify(value) != JSON.stringify(this.props.value) ||
        JSON.stringify(extraParams) != JSON.stringify(this.props.extraParams))
    ) {
      if (name) {
        updateInputField({ [name]: value }, extraParams);
      } else {
        updateInputField(value, extraParams);
      }
    }
    if (error != "") {
      this.setState({ error: "" });
      if (errorResolve) {
        errorResolve();
      }
    }

    if (isRequired && !value) {
      setTimeout(this.isValid);
    }
  };

  updateValueLocally = value => {
    const { updateInputFieldLocally, name } = this.props;

    if (
      updateInputFieldLocally &&
      JSON.stringify(value) != JSON.stringify(this.props.value)
    ) {
      if (name) {
        updateInputFieldLocally({ [name]: value });
      } else {
        updateInputFieldLocally(value);
      }
    }
  };

  onBlur = ({ value } = {}) => {
    const { onBlurInputField, name } = this.props;

    if (onBlurInputField) {
      if (name) {
        onBlurInputField({ params: { [name]: value } });
      } else {
        onBlurInputField({ params: value });
      }
    }
    this.setState({ isFocused: false });
  };

  resetErrorState = () => {
    this.setState({ error: "" });
  };

  onFocus = ({ value } = {}) => {
    const { onFocusInputField, name, scrollOnFocus = false } = this.props;
    if (scrollOnFocus) {
      if (this.inputRef && this.inputRef.wrapper) {
        scrollIntoView({
          element: this.inputRef.wrapper,
          scrollAfter: 100,
          block: "center",
        });
      }
    }
    if (onFocusInputField) {
      if (name) {
        onFocusInputField({ params: { [name]: value } });
      } else {
        onFocusInputField({ params: value });
      }
    }
    this.setState({ isFocused: true });
  };

  renderLabel = () => {
    const {
      label,
      subText,
      labelComponent,
      labelStyle,
      subTextStyle,
      labelContainerStyle,
      isRequired,
      showErrorText,
    } = this.props;

    return (
      <UILabel
        label={`${label}${(isRequired || showErrorText) && !!label ? `*` : ``}`}
        subText={subText}
        labelComponent={labelComponent}
        labelStyle={labelStyle}
        labelContainerStyle={labelContainerStyle}
        subTextStyle={subTextStyle}
      />
    );
  };

  renderError = () => {
    if (this.props.showErrorText) {
      const { renderErrorComponent } = this.props;
      const { error } = this.state;
      if (renderErrorComponent) {
        return renderErrorComponent(error);
      } else {
        return <div className={classes.errorText}>{error}</div>;
      }
    }
    return null;
  };

  renderWarnings = () => {
    const { renderWarningsComponent, warningsText } = this.props;
    if (warningsText) {
      if (renderWarningsComponent) {
        return renderWarningsComponent(warningsText);
      } else {
        return <div className={classes.warningsText}>{warningsText}</div>;
      }
    }
    return null;
  };

  shouldShowEditEmpty = () => {
    return false;
  };

  shouldShowViewEmpty = () => {
    const { value, name } = this.props;
    return !this.isValidValue(value);
  };

  shouldShowEmpty = () => {
    const { mode } = this.props;
    if (mode == "view") {
      return this.shouldShowViewEmpty();
    } else {
      return this.shouldShowEditEmpty();
    }
  };

  renderEditEmpty = () => {
    return null;
  };

  renderViewEmpty = () => {
    const { emptyText, textStyle, inputStyle, t, emptyComponent } = this.props;

    if (emptyComponent) {
      return emptyComponent;
    } else {
      if (emptyText === "not_defined") {
        return (
          <Translation>
            {(t, { i18n }) => (
              <div
                className={classes.emptyText}
                style={{ ...textStyle, ...inputStyle }}
              >
                {t("common:not_defined")}
              </div>
            )}
          </Translation>
        );
      } else {
        return (
          <div
            className={classes.emptyText}
            style={{ ...textStyle, ...inputStyle }}
          >
            {emptyText}
          </div>
        );
      }
    }
  };

  getViewValue = () => {
    return getStringAutoLinks(this.props.value);
  };

  renderView = () => {
    const { textStyle, inputStyle, textAreaStyles } = this.props;

    return (
      <div
        style={{ ...textStyle, ...inputStyle, ...textAreaStyles }}
        className={classes.inputText}
        dangerouslySetInnerHTML={{ __html: this.getViewValue() }}
      />
    );
  };

  renderChildren = () => {};

  renderEdit = () => {};

  renderLock = () => {
    const { fieldLockedObject: { lockedField } = {}, readOnly } = this.props;

    return (
      <LockOverlay
        userInfo={_.get(lockedField, "userInfo", {})}
        readOnly={readOnly}
      />
    );
  };

  renderReadOnly = () => {
    return this.renderLock();
  };

  renderResponseHeader = () => {
    const { responseHeaderText } = this.props;
    return responseHeaderText ? (
      <div className={classes.responseHeaderText}>{responseHeaderText}</div>
    ) : null;
  };

  render() {
    const {
      containerStyle,
      editContainerStyle,
      value,
      showEditEmpty,
      mode,
      showViewEmpty,
      fieldLockedObject,
      warningsText,
      readOnly,
      label,
    } = this.props;

    const { error } = this.state;
    const { lockedField } = fieldLockedObject;
    const showEmpty = mode == "view" ? showViewEmpty : showEditEmpty;

    return !this.shouldShowEmpty(value) ? (
      <div className={classes.container} style={containerStyle}>
        {this.renderLabel()}
        {this.renderResponseHeader()}
        {mode == "view" ? (
          this.renderView()
        ) : (
          <div className={classes.editContainer} style={editContainerStyle}>
            {this.renderEdit()}
            {lockedField && !_.isEmpty(lockedField) && this.renderLock()}
            {readOnly &&
              !lockedField &&
              _.isEmpty(lockedField) &&
              this.renderReadOnly()}
          </div>
        )}
        {this.renderChildren()}
        {!!error || !warningsText ? this.renderError() : this.renderWarnings()}
      </div>
    ) : showEmpty ? (
      <div className={classes.container} style={containerStyle}>
        {this.renderLabel()}
        {mode == "view" ? this.renderViewEmpty() : this.renderEditEmpty()}
        {this.renderChildren()}
      </div>
    ) : null;
  }
}

UIBaseComponent.defaultProps = {
  label: "",
  subText: "",
  labelStyle: {},
  value: undefined,
  name: "",
  errorStyle: {},
  containerStyle: {},
  editContainerStyle: {},
  textStyle: {},
  disabled: false,
  mode: "edit",
  placeholder: "",
  showViewEmpty: true,
  showEditEmpty: true,
  isRequired: false,
  error: "Error",
  emptyText: "not_defined",
  fieldLockedObject: {},
  showErrorText: false,
  warningsText: "",
  readOnly: false,
};

UIBaseComponent.propTypes = {
  label: PropTypes.string,
  showViewEmpty: PropTypes.bool,
  showEditEmpty: PropTypes.bool,
  subText: PropTypes.string,
  labelStyle: PropTypes.object,
  labelComponent: PropTypes.element,
  updateInputField: PropTypes.func,
  onBlurInputField: PropTypes.func,
  value: PropTypes.any,
  errorResolve: PropTypes.func,
  emptyText: PropTypes.string,
  customValidation: PropTypes.func,
  containerStyle: PropTypes.object,
  editContainerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  renderErrorComponent: PropTypes.func,
  renderWarningsComponent: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default UIBaseComponent;
