import React from "react";
import { withRouter } from "react-router";
import { getHOCDisplayName } from "Utils";

function withQueryParamsBasedInterface(injectedProps) {
  return WrappedComponent => {
    class WithQueryParamsBasedInterface extends React.PureComponent {
      render() {
        const {
          forwardedRef,
          location: { query },
          source_type,
        } = this.props;
        const { cb: getSourceTypeBasedProps } = injectedProps;

        const sourceType = _.get(query, "source_type", "") || source_type;

        const queryConfig = _.get(query, "config", {});
        const extraConfig = !_.isEmpty(queryConfig)
          ? JSON.parse(queryConfig)
          : {};

        const sourceTypeBasedProps = getSourceTypeBasedProps({
          sourceType,
          ownProps: this.props,
        });

        const combinedProps = _.merge(sourceTypeBasedProps, extraConfig);

        return (
          <WrappedComponent
            ref={forwardedRef}
            {...this.props}
            {...combinedProps}
          />
        );
      }
    }

    WithQueryParamsBasedInterface.defaultProps = {};

    WithQueryParamsBasedInterface.displayName = `WithQueryParamsBasedInterface(${getHOCDisplayName(
      WrappedComponent
    )})`;

    return withRouter(
      React.forwardRef(function WithQueryParamsBasedInterfaceForwardRef(
        props,
        ref
      ) {
        return (
          <WithQueryParamsBasedInterface
            {...props}
            forwardedRef={props.customRef}
          />
        );
      })
    );
  };
}

export default withQueryParamsBasedInterface;
