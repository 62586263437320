import { parseQueryString } from "./QueryString";

export const getUTMData = () => {
  const parsed = parseQueryString(window.location.search);
  return parsed;
};

export const setUTMSourceInStorage = () => {
  const { utm_source = "direct_landing" } = getUTMData();
  localStorage.setItem("utm_source", utm_source);
};

export const getUTMSourceFromStorage = () => {
  return localStorage.getItem("utm_source");
};
