import React from "react";

// Components
import { I18nHOC } from "UIComponents";
import AssessmentToolV2 from "../AssessmentToolV2";

// Services
import { getPrintFile, ASSESSMENT_TOOLS } from "modules/Services";

// Design System
import {
  Dropdown,
  DropdownMenu,
  IconButton,
  MediaCard,
} from "@toddle-design/web";
import {
  DeleteOutlined,
  MoreVerticalDotsOutlined,
  PencilOutlined,
  PrintOutlined,
} from "@toddle-design/web-icons";

// Module
import {
  clearAssessmentTool,
  updateAssessmentInRedux,
} from "Assessments/modules/AssessmentModule";

// External libs.
import { connect } from "react-redux";
import { compose } from "react-apollo";

const styles = {
  container: {
    width: "100%",
  },
};

const getOptions = ({ t, mode, type }) => {
  const assessmentToolTypeLocale = t(
    _.find(ASSESSMENT_TOOLS, { type })?.locale
  );
  if (mode === "view") {
    return [
      {
        key: "print_tool",
        label: t("common:print"),
        icon: <PrintOutlined />,
      },
    ];
  } else {
    return [
      {
        key: "edit_tool",
        label: t("common:edit_with_label", {
          label: assessmentToolTypeLocale,
        }),
        icon: <PencilOutlined />,
      },
      {
        key: "remove_tool",
        label: t("common:remove"),
        icon: <DeleteOutlined />,
        isDestructive: true,
      },
    ];
  }
};

const onCardActionClick = ({
  type,
  action,
  updateInputField,
  updateAssessmentInRedux,
  toggleAssessmentToolModal,
  assessmentToolId,
  resourceId,
  assessmentId,
  getPrintFile,
}) => {
  switch (action) {
    case "edit_tool":
      {
        updateAssessmentInRedux({
          assessmentToolModalMode: "edit_tool",
        });
        toggleAssessmentToolModal();
      }
      break;
    case "remove_tool":
      {
        const params = {
          measureAssessing: type,
        };
        updateInputField(params);
      }
      break;
    case "print_tool":
      {
        const assessmentToolKey = _.get(
          _.find(ASSESSMENT_TOOLS, { type }),
          "key"
        );

        const printType = _.get(
          _.find(ASSESSMENT_TOOLS, { type }),
          "printType"
        );
        const printId =
          assessmentToolKey === "mypObjectiveRubric"
            ? resourceId
            : assessmentToolId;

        getPrintFile({ id: printId || assessmentId, type: printType });
      }
      break;
  }
};

const getMediaCardOptions = params => {
  const { t, mode, type, showToolPrintOption = false } = params;

  const canPrint = _.get(_.find(ASSESSMENT_TOOLS, { type }), "canPrint");

  if (mode === "view" && !showToolPrintOption && !canPrint) return null;

  const onClickDropdownMenu = e => {
    e.domEvent.stopPropagation();
    const action = e.key;
    onCardActionClick({ ...params, action });
  };

  const overlay = (
    <DropdownMenu
      onClick={onClickDropdownMenu}
      options={getOptions({ t, mode, type })}
    />
  );

  return (
    <Dropdown
      getPopupContainer={el => el}
      overlay={overlay}
      placement={"bottomRight"}
    >
      <IconButton
        onClick={e => e.stopPropagation()}
        icon={<MoreVerticalDotsOutlined variant={"subtle"} size={"xx-small"} />}
        variant={"plain"}
      />
    </Dropdown>
  );
};

const CustomMediaCard = props => {
  const {
    t,
    toolType,
    showAssessmentToolModal,
    assessmentToolId,
    clearAssessmentTool,
    updateInputField,
    updateAssessmentInRedux,
  } = props;

  const toggleAssessmentToolModal = () => {
    updateAssessmentInRedux({
      showAssessmentToolModal: !showAssessmentToolModal,
    });
  };

  const showMediaCard =
    !_.isEmpty(assessmentToolId) && !showAssessmentToolModal;

  const onClickAssessmentToolCard = () => {
    updateAssessmentInRedux({
      assessmentToolModalMode: "view_tool",
    });
    toggleAssessmentToolModal();
  };

  const toolName = t(
    _.find(ASSESSMENT_TOOLS, {
      type: toolType,
    })?.locale
  );

  return (
    <React.Fragment>
      {showMediaCard && (
        <div style={styles.container}>
          <MediaCard
            cardType="medium"
            type={_.replace(toolType, /_/g, " ")}
            onClick={onClickAssessmentToolCard}
            name={toolName}
            options={getMediaCardOptions({
              ...props,
              t,
              type: toolType,
              clearAssessmentTool,
              updateInputField,
              updateAssessmentInRedux,
              toggleAssessmentToolModal,
            })}
            showSubtext={false}
          />
        </div>
      )}
      {showAssessmentToolModal && (
        <AssessmentToolV2
          mode={"edit"}
          parentId={props.resourceId}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    showAssessmentToolModal,
    assessmentId,
  } = state.assessment.assessmentData;

  return {
    assessmentId,
    showAssessmentToolModal,
  };
};

const mapActionCreators = {
  clearAssessmentTool,
  updateAssessmentInRedux,
  getPrintFile,
};

export default compose(
  connect(mapStateToProps, mapActionCreators),
  I18nHOC({ resource: ["common"] })
)(CustomMediaCard);
