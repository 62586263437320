import React from "react";
import classes from "./TableRow.scss";
import {
  TextAreaInput,
  MoreItemWithToolTip,
  Loading,
  ButtonDropdown,
} from "UIComponents";
import { DeleteIcon } from "SvgComponents";
import GradeSelector from "../../GradeSelector";
import { fontStyle } from "Constants";
import { colors } from "Constants";
import classNames from "classnames";

const loadingStyle = {
  width: 20,
  height: 20,
};

const textAreaStyles = {
  borderWidth: 0,
  flexShrink: 0,
  padding: "16px 24px",
  paddingRight: 48,
  ...fontStyle.regular,
  fontSize: "1.6rem",
  color: colors.gray31,
  overflowY: "hidden",
};

const moreStyle = {
  color: colors.gray31,
  ...fontStyle.medium,
  fontSize: "1.6rem",
};

const gradeContainerStyle = {
  fontSize: "1.6rem",
  ...fontStyle.medium,
};

const buttonDropDownStyle = {
  containerStyle: {
    top: 4,
    borderWidth: 0,
  },
};

const getGradeIdsArray = grades => {
  return _.map(grades, item => {
    return item.name;
  });
};

const GRADE_BUTTONS = [
  {
    type: "REMOVE",
    buttonText: "common:remove",
    buttonTextColor: colors.salmon60,
  },
  { type: "ADD", buttonText: "common:add", buttonTextColor: colors.blue29 },
];

const HeaderRow = React.memo(props => {
  const {
    showDescription,
    titleText,
    levelName,
    nodeType,
    showCode,
    showGrade,
    t,
  } = props;
  const containerStyle = classNames(
    { [classes.rowContainer]: true },
    { [classes.rowATLSContainer]: nodeType == "ATL" },
    { [classes.rowWithDescriptionContainer]: showDescription },
    { [classes.rowWithoutGradeContainer]: !showGrade }
  );
  return (
    <div className={containerStyle}>
      <div className={classes.cellContainer}>
        <div className={classes.headerTitleText}>{titleText}</div>
      </div>
      {!!showDescription && (
        <div className={classes.cellContainer}>
          <div className={classes.headerTitleText}>
            {t("common:description")}
          </div>
        </div>
      )}
      {showCode && (
        <div className={classes.cellContainer}>
          <div className={classes.headerTitleText}>{t("common:code")}</div>
        </div>
      )}
      {showGrade && (
        <div className={classes.cellContainer}>
          <div className={classes.headerTitleText}>
            {t("common:grade_plural")}
          </div>

          <div className={classes.headerSubText}>
            {t("academicSetup:sns_select_grade_header", { label: levelName })}
          </div>
        </div>
      )}
    </div>
  );
});

const GradeButton = React.memo(props => {
  const {
    updategradeSelectorRef,
    id,
    isDisabled,
    item,
    parentGrades,
    grades,
    onBlurInputField,
    type,
    onGradeEditClick,
    buttonText,
    buttonTextColor,
    visible,
    onCloseGradeSelector,
  } = props;
  return (
    <div className={classes.gradeButtonContainer}>
      {!!visible && (
        <ButtonDropdown
          containerStyle={buttonDropDownStyle.containerStyle}
          shouldCloseOnSelfClick={false}
          onUpdateShowDropDown={value => {
            if (!value) {
              onBlurInputField(id);
            }
          }}
          ref={ref => updategradeSelectorRef({ ref, id: `${id}_${type}` })}
          isButtonDisabled={isDisabled}
          buttonComponent={
            <div
              className={classes.gradeButton}
              style={
                isDisabled
                  ? { cursor: "not-allowed", color: colors.strokeTwo }
                  : { color: buttonTextColor }
              }
              onClick={() => {
                if (!isDisabled) {
                  onGradeEditClick(item, type);
                }
              }}
            >
              {buttonText}
            </div>
          }
          dropdownComponent={
            !isDisabled ? (
              <GradeSelector
                options={parentGrades}
                nodeId={id}
                value={grades}
                onBlurInputField={onBlurInputField}
                type={type}
                onClose={onCloseGradeSelector}
              />
            ) : (
              <div />
            )
          }
        />
      )}
    </div>
  );
});

GradeButton.displayName = "GradeButton";

const TableRow = React.memo(props => {
  const {
    item,
    updateInputField,
    levelIdIndex,
    index,
    parentGrades,
    onBlurInputField,
    onFocusInputField,
    onGradeEditClick,
    updategradeSelectorRef,
    onDeleteClick,
    showDelete,
    showDescription,
    updateEditBoxRefs,
    isDisabled,
    currentGradeEditType,
    onCloseGradeSelector,
    nodeType,
    t,
    showGrade,
    showCode,
  } = props;

  const { grades, id } = item;
  const gradeIdsArray = getGradeIdsArray(grades);
  const parentGradesLength = _.get(parentGrades, "length", 0);
  const gradesLength = _.get(grades, "length", 0);
  const buttonVisible =
    parentGradesLength - gradesLength > 0 || gradesLength > 0;
  const containerStyle = classNames(
    { [classes.rowContainer]: true },
    { [classes.rowATLSContainer]: nodeType == "ATL" },
    { [classes.rowWithDescriptionContainer]: showDescription },
    { [classes.rowWithoutGradeContainer]: !showGrade }
  );
  return (
    <div className={containerStyle}>
      <div className={classes.labelContainer}>
        {levelIdIndex == 0 && (
          <div className={classes.numberText}>{`${index + 1}.`}</div>
        )}
        <div className={classes.textAreaContainer}>
          <TextAreaInput
            value={item.label}
            ref={ref => updateEditBoxRefs(id, ref)}
            textAreaStyles={textAreaStyles}
            minRows={1}
            maxRows={null}
            isRequired={true}
            onBlurInputField={({ params }) => onBlurInputField(item.id, params)}
            onFocusInputField={() => onFocusInputField(item.id)}
            updateInputField={params => updateInputField(item, params)}
            name={`label`}
            disabled={isDisabled}
            placeholder={t("common:type_to_add")}
          />
        </div>

        {!!showDelete && (
          <div
            className={classes.deleteButtonContainer}
            onClick={() => onDeleteClick(item)}
          >
            <DeleteIcon />
          </div>
        )}
        {isDisabled && (
          <div className={classes.loadingContainer}>
            <Loading loadingStyle={loadingStyle} />
          </div>
        )}
      </div>
      {showCode && (
        <div className={classes.labelContainer}>
          <div className={classes.textAreaContainer}>
            <TextAreaInput
              value={item.code}
              textAreaStyles={textAreaStyles}
              minRows={1}
              maxRows={null}
              onBlurInputField={({ params }) =>
                onBlurInputField(item.id, params)
              }
              onFocusInputField={() => onFocusInputField(item.id)}
              updateInputField={params => updateInputField(item, params)}
              name={`code`}
              disabled={isDisabled}
              placeholder={t("common:code")}
            />
          </div>
        </div>
      )}
      {!!showDescription && (
        <div className={classes.labelContainer}>
          <div className={classes.textAreaContainer}>
            <TextAreaInput
              value={item.subText}
              textAreaStyles={textAreaStyles}
              minRows={1}
              maxRows={null}
              onBlurInputField={({ params }) =>
                onBlurInputField(item.id, params)
              }
              onFocusInputField={() => onFocusInputField(item.id)}
              updateInputField={params => updateInputField(item, params)}
              name={`subText`}
              disabled={isDisabled}
              placeholder={t("common:type_to_add")}
            />
          </div>
        </div>
      )}
      {showGrade && (
        <div className={classes.cellContainer}>
          <div className={classes.gradeInnerContainer}>
            <MoreItemWithToolTip
              items={gradeIdsArray}
              moreStyle={moreStyle}
              noItemText={
                parentGradesLength > 0
                  ? t("common:select_with_label", {
                      label: t("common:grade_plural"),
                    })
                  : t("common:no_label_available", {
                      label: t("common:grade_plural"),
                    })
              }
              containerStyle={{
                ...gradeContainerStyle,
                color:
                  gradeIdsArray.length > 0 ? colors.gray31 : colors.strokeTwo,
              }}
            />
            <div className={classes.rightContainer}>
              {_.map(GRADE_BUTTONS, button => {
                const { type, buttonText, buttonTextColor } = button;

                const isButtonDisabled =
                  (type == "REMOVE"
                    ? gradesLength <= 0
                    : parentGradesLength - gradesLength <= 0) || isDisabled;
                return (
                  <GradeButton
                    key={type}
                    updategradeSelectorRef={updategradeSelectorRef}
                    id={id}
                    isDisabled={isButtonDisabled}
                    item={item}
                    parentGrades={parentGrades}
                    grades={grades}
                    onBlurInputField={onBlurInputField}
                    type={type}
                    onGradeEditClick={onGradeEditClick}
                    buttonText={t(buttonText)}
                    visible={buttonVisible}
                    buttonTextColor={buttonTextColor}
                    onCloseGradeSelector={onCloseGradeSelector}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export { TableRow, HeaderRow };
