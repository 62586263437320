import React, { useContext } from "react";
import { ProjectPlannerContext } from "Projects/modules/ProjectModules";
import labelClasses from "UnitPlans/components/LabelComponent/LabelComponent.scss";
import { IconButton } from "@toddle-design/web";
import { HelpBulbOutlined } from "@toddle-design/web-icons";

//Only inside Projects!
const HelpButton = ({ uid, details, size }) => {
  let helpButtonDetails = useContext(ProjectPlannerContext);

  //For components that don't belong to ProjectPlannerContext
  if (_.isEmpty(helpButtonDetails)) {
    helpButtonDetails = details;
  }

  const {
    onHelpClick,
    currentRightPane,
    currentFieldId,
    template,
  } = helpButtonDetails;

  const helpEnabledFields = template?.helpEnabledFields;
  const helpText = _.find(helpEnabledFields, { uid });

  const isHelpActive =
    currentRightPane === "help" && currentFieldId == helpText?.id;

  const onClickHelp = () => {
    onHelpClick({
      fieldKey: uid,
      helperTextId: _.get(helpText, "helpText.id", null),
      id: helpText?.id,
    });
  };
  return helpText ? (
    <div className={labelClasses.labelHelper}>
      <IconButton
        icon={<HelpBulbOutlined />}
        variant={isHelpActive ? "primary" : "plain"}
        size={size}
        onClick={onClickHelp}
      />
    </div>
  ) : null;
};

HelpButton.defaultProps = {
  size: "medium",
};

export default HelpButton;
