import React from "react";
import { DateSelector, UIBaseComponent, I18nHOC } from "UIComponents";
import classes from "./DateDurationSelector.scss";
import moment from "moment";

const labelStyle = {
  fontSize: "1.3rem",
  lineHeight: "initial",
};

class DateDurationSelector extends UIBaseComponent {
  constructor(props) {
    super(props);
    this.endDateRef = null;
    this.startDateRef = null;
  }

  componentDidMount = () => {
    const {
      value: { endDate },
      maxDate,
    } = this.props;

    if (moment(endDate).isAfter(moment(maxDate))) {
      this.changeDate({ endDate: maxDate });
    }
  };

  changeDate = params => {
    const {
      value,
      maxDate,
      minNumOfDays,
      shouldChangeEndDateWithStartDate,
    } = this.props;

    const { endDate } = value;

    if (
      _.has(params, "startDate") &&
      moment(params.startDate).isAfter(moment(endDate))
    ) {
      params["endDate"] = moment(params.startDate).add(minNumOfDays, "days");
      if (this.endDateRef) this.endDateRef.setFocused(true);
    }

    // When we need the end Date to be made start Date + minNumOfDays, automatically when startDate is changed
    //        shouldChangeEndDateWithStartDate = true
    //        the number of days difference between dates = minNumOfDays
    //
    if (_.has(params, "startDate") && shouldChangeEndDateWithStartDate) {
      params["endDate"] = moment(params.startDate).add(minNumOfDays, "days");
      if (this.endDateRef) this.endDateRef.setFocused(true);
    }

    if (
      _.has(params, "endDate") &&
      moment(params.endDate).isAfter(moment(maxDate))
    ) {
      params["endDate"] = maxDate;
    }

    this.updateValue({ ...value, ...params });
  };

  isValid = () => {
    const { value, customValidation } = this.props;
    if (customValidation) {
      const customError = customValidation(value);
      if (customError != "") {
        this.setState({ error: customError });
        return 1;
      } else {
        return 0;
      }
    } else {
      return this.endDateRef.isValid() + this.startDateRef.isValid();
    }
  };

  renderEdit = () => {
    const {
      t,
      minDate,
      maxDate,
      startDateLabel,
      endDateLabel,
      startDateName,
      endDateName,
      startDatePlaceholder,
      endDatePlaceholder,
      value: { startDate, endDate },
      isDisabled,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.dateContainer}>
          <DateSelector
            minDate={minDate}
            maxDate={maxDate}
            value={startDate}
            labelStyle={labelStyle}
            updateInputField={this.changeDate}
            ref={ref => (this.startDateRef = ref)}
            name={startDateName}
            label={
              _.isEqual(startDateLabel, "common:start_date")
                ? t(startDateLabel)
                : startDateLabel
            }
            placeholder={
              _.isEqual(startDatePlaceholder, "common:start_date")
                ? t(startDatePlaceholder)
                : startDatePlaceholder
            }
            isBottomPaddingRequired={false}
            isDisabled={isDisabled}
          />
        </div>
        <div className={classes.dateContainer}>
          <DateSelector
            minDate={startDate}
            maxDate={maxDate}
            value={endDate}
            labelStyle={labelStyle}
            ref={ref => (this.endDateRef = ref)}
            updateInputField={this.changeDate}
            name={endDateName}
            label={
              _.isEqual(endDateLabel, "common:end_date")
                ? t(endDateLabel)
                : endDateLabel
            }
            placeholder={
              _.isEqual(endDatePlaceholder, "common:end_date")
                ? t(endDatePlaceholder)
                : endDatePlaceholder
            }
            isBottomPaddingRequired={false}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    );
  };
}

DateDurationSelector.defaultProps = {
  ...UIBaseComponent.defaultProps,
  minDate: null,
  maxDate: null,
  isDisabled: false,
  value: { startDate: null, endDate: null },
  startDateLabel: "common:start_date",
  endDateLabel: "common:end_date",
  startDateName: "startDate",
  endDateName: "endDate",
  startDatePlaceholder: "common:start_date",
  endDatePlaceholder: "common:end_date",
  minNumOfDays: 40,
  shouldChangeEndDateWithStartDate: false,
};

DateDurationSelector.propTypes = {
  ...UIBaseComponent.propTypes,
};

DateDurationSelector.displayName = "DateDurationSelector";

export default I18nHOC({ resource: ["common"] })(DateDurationSelector);
