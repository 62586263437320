import update from "immutability-helper";
import React from "react";
import { generateRandomId, getRelativePath } from "Utils";
import {
  getPlannerResourcesOfNodeFromCache,
  writePlannerResourcesOfNodeInCache,
  writeResourceInCache,
  getFeedUnitPlanItemFromCache,
  writeFeedUnitPlanItemToCache,
} from "./UnitPlanGraphqlHelpers";
import client from "apolloClient";
import {
  geCourseGradesDetailFromCache,
  getStaffBasicDetailsFromCache,
  getOrganizationPypElementSetFromCache,
  getGradeUnitDetailsFromCache,
  writeGradeUnitDetailsInCache,
  getOrganizationDetailsFromCache,
  getOrganizationCurriculumProgramsFromCache,
} from "modules/CommonGraphqlHelpers";
import {
  getPlannerFieldsOfNodeQuery,
  getFlatUnitPlansQuery,
  getFlatUnitPlanFeedQuery,
} from "./UnitPlanQuery";
import {
  getCourseDetailsFromCache,
  getUniqlyCombinedGradesDetails,
  getCourseFeedDetailsFromCache,
} from "Course/modules/CourseGraphqlHelpers";
import {
  deleteUnitPlanMutation,
  createPlannerResourceMutation,
  updatePlannerResourceMutation,
  removePlannerResourceMutation,
  addPlannerResourceMutation,
  copyUnitPlanMutation,
  importUnitPlanMutation,
  updateUnitPlanForPlanathonMutation,
  clearUnitPlanAssessmentToolMutation,
  updateSubjectForMypInterdisciplinaryUnitMutation,
} from "./UnitPlanMutations";
import { publishResourcesMutation } from "AppComponents/OrganizationResources/modules/OrganizationResourcesMutations";
import { updateQueryParameters } from "modules/NavigationModule";
import { setToastMsg } from "Login/modules/LoginModule";
import {
  getUnitPlanFieldFromCache,
  getUnitPlanCollaboratorsFromCache,
  getUnitPlanDetailsFromCache,
} from "IBPlanner/modules/IBGraphqlHelpers";
import {
  goToRelativeRoute,
  pypElementMapping,
  pypElementSetMapping,
  openLinkInNewTab,
  getSubjectLabel,
  getPypElementLabel,
  getElementValue,
  getCurrentAcademicYear,
  getStartEndDatesOfAcademicYears,
} from "modules/Services";
import { checkCommunityActiveStatusAndGoToOnboarding } from "Community/modules/CommunityModule";
import { colors, UNIT_TYPE_LOCALE } from "Constants";
import moment from "moment";
import ACLStore from "lib/aclStore";
import {
  CURRICULUM_TYPE_DP,
  CURRICULUM_TYPE_UBD,
  SUBJECT_TYPE_GENERIC,
  UBD_SUBJECT_SPECIFIC,
} from "Constants/stringConstants";
import { getCourseOverviewElementSetsNotCoveredCountQuery } from "UnitPlans/routes/CourseUnitPlansOverview/modules/Queries";
import {
  isGlobalUser,
  getUnitPlanCentralRepoInputAndFilters,
} from "Courses/modules/utils";
import {
  UploadCloudOutlined,
  CancelledCloudOutlined,
  DownloadCloudOutlined,
} from "@toddle-design/web-icons";
import { getCurriculumProgramFreeStatus } from "Platform/modules/PlatformModule";
import { getFieldLockedObject } from "modules/PusherModule";

import _ from "lodash";

export const NAME = "unitPlans";

export const UPDATE_FILTER_OBJECT = "UPDATE_FILTER_OBJECT" + " " + NAME;
export const INIT_FILTER_OBJECT = "INIT_FILTER_OBJECT" + " " + NAME;
export const SET_CURRENT_TAB = "SET_CURRENT_TAB" + " " + NAME;
export const UPDATE_LOCAL_RESOURCE = "UPDATE_LOCAL_RESOURCE" + " " + NAME;
export const INIT_LOCAL_RESOURCE = "INIT_LOCAL_RESOURCE" + " " + NAME;
export const SET_UNIT_PLAN_MODE = "SET_UNIT_PLAN_MODE" + " " + NAME;
export const SET_UNIT_PLAN_READ_ONLY = "SET_UNIT_PLAN_READ_ONLY" + " " + NAME;
export const INIT_CREATE_EDIT_OBJECT = "INIT_CREATE_EDIT_OBJECT" + " " + NAME;
export const TOGGLE_SELECT_COURSE = "TOGGLE_SELECT_COURSE" + " " + NAME;
export const SWITCH_YEARLY_VIEW = "SWITCH_YEARLY_VIEW" + " " + NAME;
export const UPDATE_CREATE_EDIT_OBJECT =
  "UPDATE_CREATE_EDIT_OBJECT" + " " + NAME;
export const TOGGLE_PERMISSION = "TOGGLE_PERMISSION" + " " + NAME;
export const SET_UNIT_PLAN_EVIDENCE_VIEW =
  "SET_UNIT_PLAN_EVIDENCE_VIEW" + " " + NAME;
export const SET_CURRENT_VIEW_TYPE = "SET_CURRENT_VIEW_TYPE" + " " + NAME;
export const SET_TAB_LIST = "SET_TAB_LIST" + " " + NAME;

export const FIELD_UID_CURRICULUM_MAPPING = {
  IB_PYP: null,
  IB_MYP: ["globalContextMYP", "subjects"],
  UBD: [],
  IB_DP: [
    "subjectStandardDP",
    "aimDP",
    "syllabusContentDP",
    "objectivesDP",
    "hours",
    "subjectLevel",
  ],
};

export const ResourceDetailsContext = React.createContext();

export const getPlannerFieldMutationParams = ({
  oldValue,
  fieldUID,
  newValue,
  id,
  hasAddRemoveValues,
  valuesToAdd,
  valuesToRemove,
}) => {
  const params = { id, value: newValue, fieldUID };
  params["hasAddRemoveValues"] = hasAddRemoveValues;
  if (hasAddRemoveValues) {
    params["valuesToAdd"] = valuesToAdd ?? _.difference(newValue, oldValue);
    params["valuesToRemove"] =
      valuesToRemove ?? _.difference(oldValue, newValue);
  } else {
    params["value"] = newValue;
  }
  return params;
};

export const attachmentObj = {
  id: generateRandomId(),
  name: "",
  type: "",
  mimeType: "",
  url: "",
  thumbUrl: "",
  title: "",
  metadata: "",
  __typename: "Attachment",
};

export const enityEnumToLocalTypeMapping = {
  UNIT_PLAN: "unitPlan",
  ASSESSMENT: "assessment",
  LEARNING_ENGAGEMENT: "le",
};

const UPDATE_DEBOUNCE_TIME = 0;

export const initCreateEditObj = () => {
  return { type: INIT_CREATE_EDIT_OBJECT };
};

export const changeCourseSelection = data => {
  return { type: TOGGLE_SELECT_COURSE, data };
};

export const togglePermission = data => {
  return { type: TOGGLE_PERMISSION, data };
};

export const updateEditCreateObj = data => {
  return { type: UPDATE_CREATE_EDIT_OBJECT, data };
};

export const initLocalResource = () => {
  return {
    type: INIT_LOCAL_RESOURCE,
  };
};

export const updateLocalResource = data => {
  return {
    type: UPDATE_LOCAL_RESOURCE,
    data,
  };
};

export const updateFilterObject = data => {
  return { type: UPDATE_FILTER_OBJECT, data: data };
};

export const initFilterObject = () => {
  return { type: INIT_FILTER_OBJECT };
};

export const setCurrentTab = data => {
  return { type: SET_CURRENT_TAB, data };
};

export const setCurrentViewType = data => {
  return { type: SET_CURRENT_VIEW_TYPE, data };
};

export const switchYearlyView = data => {
  return { type: SWITCH_YEARLY_VIEW, data };
};

export const setUnitPlanMode = data => {
  return { type: SET_UNIT_PLAN_MODE, data };
};

export const setUnitPlanReadOnly = data => {
  return { type: SET_UNIT_PLAN_READ_ONLY, data };
};

export const setUnitPlanEvidenceView = data => {
  return { type: SET_UNIT_PLAN_EVIDENCE_VIEW, data };
};

export const getSortedSubjectLevelTags = ({
  organizationSubjectLevels,
  selectedTagList,
}) => {
  return _.intersectionBy(organizationSubjectLevels, selectedTagList, "id");
};

const fieldUids = {};

export const toggleCourseSelection = data => {
  return (dispatch, getState) => {
    const userTags = getState().login.userInfo.tags || [];

    const isAdmin =
      getState().login.activeTab == "admin" || isGlobalUser({ userTags });

    dispatch(
      changeCourseSelection({
        id: data.id,
        currentCourse: isAdmin
          ? null
          : getState().teacher.selected_class.selected_course,
      })
    );
  };
};

export const debounceGetPlannerFieldsOfNodeQueryFunc = () => {
  return (dispatch, getState) => {
    const focusedField = getState().pusher.focusedField;

    _.map(fieldUids, async (obj, key) => {
      const { id, type, uids } = obj;
      const filteredUids = _.filter(
        uids,
        uid =>
          uid !=
          _.get(focusedField[enityEnumToLocalTypeMapping[type]], "uid", "")
      );

      await client.query({
        query: getPlannerFieldsOfNodeQuery,
        variables: {
          id,
          type,
          uids: filteredUids,
        },
        fetchPolicy: "network-only",
      });
      delete fieldUids[key];
    });
  };
};

const dispatchGetPlannerFieldsOfNodeQueryFunc = dispatchFunc => {
  dispatchFunc(debounceGetPlannerFieldsOfNodeQueryFunc());
};

const debouncedGetPlannerFieldsOfNode = _.debounce(
  dispatchGetPlannerFieldsOfNodeQueryFunc,
  UPDATE_DEBOUNCE_TIME
);

export const getPlannerFieldsOfNode = ({ id, type, uids }) => {
  return async (dispatch, getState) => {
    const key = `${type}:${id}`;
    fieldUids[key] = {
      id,
      type,
      uids: _.union(_.get(fieldUids[key], "uids", []), uids),
    };
    debouncedGetPlannerFieldsOfNode(dispatch);
  };
};

export const goToCreateUnitPlan = ({
  courseId,
  replacePath = "courses",
  unitType,
}) => {
  return (dispatch, getState) => {
    let route = `courses/${courseId}/unitPlans/create`;
    if (unitType) {
      route = `${route}?unitType=${unitType}`;
    }
    dispatch(
      goToRelativeRoute({
        route,
        replacePath: replacePath,
      })
    );
  };
};

export const updateGradeUnitPlanCount = ({ count = 1 }) => {
  return (dispatch, getState) => {
    const state = getState();

    const grades = _.get(state.teacher, "selected_class.selected_grades", []);
    const userId = getState().login.userInfo.id;
    const isCurriculumProgramFree = getCurriculumProgramFreeStatus({ state });

    if (_.get(grades, "length", 0) >= 1) {
      const gradeId = grades[0];
      const unitGradeDetails = getGradeUnitDetailsFromCache({
        gradeId,
        userId,
        isCurriculumProgramFree,
      });
      if (!_.isEmpty(unitGradeDetails)) {
        writeGradeUnitDetailsInCache({
          id: gradeId,
          userId: userId,
          isCurriculumProgramFree,
          data: {
            ...unitGradeDetails,
            unitPlanCount: _.get(unitGradeDetails, "unitPlanCount", 0) + count,
          },
        });
      }
    }
  };
};

export const isUnitPlanEditPermission = ({ unitPlanId }) => {
  return (dispatch, getState) => {
    const collaborators = getUnitPlanCollaboratorsFromCache(unitPlanId);
    const userId = getState().login.userInfo.id;
    const isDevUser = getState().login.userInfo.isDevUser;
    const permission = _.get(
      _.find(collaborators, collaborator => collaborator.node.id == userId),
      "permission",
      "VIEW"
    );
    let isQuickTaskEditable = false;
    //if unit plan is not attached than it will either be quick task or it is opened from community so in quick task case we are adding temporary siolution
    if (_.isEmpty(unitPlanId)) {
      isQuickTaskEditable = ACLStore.can("TeacherPortal:IsQuickTaskEditable");
    }

    return _.isEqual(permission, "EDIT") || isDevUser || isQuickTaskEditable;
  };
};

export const showNotEditableUnitPlanToast = () => {
  return (dispatch, getState) => {
    dispatch(
      setToastMsg({
        msg: `toastMsgs:no_unit_edit_permission`,
        position: "toast-top-center",
        type: "alert",
        fill: colors.yellow50,
      })
    );
  };
};

export const goToUnitPlanDetail = ({
  unitPlanId,
  mode = "view",
  type = "push",
  replacePath = "",
  isOpenInNewTab = false,
  routePostFix = "",
}) => {
  return async (dispatch, getState) => {
    const activeTab = getState().login.activeTab;

    const currentTabArray = getState().router.locationBeforeTransitions.pathname.split(
      "/"
    );
    const currentTab = currentTabArray[currentTabArray.length - 1];

    if (_.includes([CARD_VIEW, TIMELINE_VIEW, COURSE_OVERVIEW], currentTab)) {
      replacePath = currentTab;
    }
    if (activeTab == "community") {
      const isActiveCommunity = await dispatch(
        checkCommunityActiveStatusAndGoToOnboarding()
      );

      if (!isActiveCommunity) {
        return;
      }
    }

    if (activeTab == "community") {
      isOpenInNewTab = false; //#hack community
    }

    const route = `${unitPlanId}/${mode}${routePostFix}`;
    if (isOpenInNewTab) {
      dispatch(openLinkInNewTab({ url: getRelativePath(route, replacePath) }));
    } else {
      dispatch(goToRelativeRoute({ route, replacePath, type }));
    }
  };
};

export const goToEditUnitPlanFromView = ({ step, queryParams } = {}) => {
  return (dispatch, getState) => {
    const currentTab = getState().unitPlans.currentTab;
    const stepRoute = step ? `/${step}` : ``;

    // Update here

    dispatch(
      goToRelativeRoute({
        route: `edit/${currentTab}${stepRoute}`,
        replacePath: "view",
      })
    );

    if (queryParams) {
      dispatch(updateQueryParameters({ params: queryParams }));
    }
  };
};

export const goBackToViewUnitPlan = () => {
  return (dispatch, getState) => {
    const currentTab = getState().unitPlans.currentTab;
    dispatch(
      goToRelativeRoute({
        route: `view/${currentTab}`,
        type: "replace",
        replacePath: "edit",
      })
    );
  };
};

export const goUnitPlanToViewType = ({ replacePath }) => {
  return (dispatch, getState) => {
    const currentViewType = getState().unitPlans.currentViewType;
    dispatch(
      goToRelativeRoute({
        route: `${currentViewType}`,
        type: "replace",
        replacePath,
      })
    );
  };
};

export const copyUnitPlan = ({
  unitPlanId,
  title = null,
  courses,
  grades,
  unitState,
  collaborators,
  startDate = null,
  endDate = null,
  academicYears = [],
}) => {
  return async (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const currentViewType = getState().unitPlans.currentViewType;

    const courseDetails = getCourseDetailsFromCache({
      id: courseId,
    });
    const curriculumType = _.get(
      courseDetails,
      "curriculumProgram.type",
      "IB_PYP"
    );

    const curriculumProgramId = _.get(
      getState(),
      "platform.currentCurriculumProgram.id"
    );

    const state = getState();
    const staffId = state.login.userInfo.id;

    const refetchQueries = [];

    switch (currentViewType) {
      case CARD_VIEW: {
        refetchQueries.push({
          query: getFlatUnitPlanFeedQuery,
          variables: dispatch(getFlatUnitPlanFeedQueryVariables()),
        });
        break;
      }
      case TIMELINE_VIEW: {
        refetchQueries.push({
          query: getFlatUnitPlansQuery,
          variables: dispatch(getFlatUnitPlanQueryVariables({})),
        });
        break;
      }
      case COURSE_OVERVIEW:
        refetchQueries.push(
          {
            query: getFlatUnitPlansQuery,
            variables: dispatch(getFlatUnitPlanQueryVariables({})),
          },
          {
            query: getCourseOverviewElementSetsNotCoveredCountQuery,
            variables: {
              id: courseId,
              filters: { curriculumType },
            },
          }
        );
        break;
    }

    /** MapleBear specific variables required for flat UnitPlans feed fetching logic */
    const organizationId = getState().login?.userInfo?.org_id ?? "";
    const { centralRepoInput } = getUnitPlanCentralRepoInputAndFilters({
      courseId,
      organizationId,
    });
    /* MapleBear end */

    try {
      await client.mutate({
        mutation: copyUnitPlanMutation,
        variables: {
          unitPlanId: unitPlanId,
          copiedBy: staffId,
          title: title,
          startDate,
          endDate,
          courses,
          grades,
          academicYears,
          state: unitState,
          collaborators,
          showCollaborators: true,
          centralRepoInput,
          sourceOrganizationId: organizationId,
          curriculumProgramId,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:unit_duplication_failed",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

// export const copyUnitPlan = ({
//   unitPlanId,
//   fieldValue,
//   title = null,
//   courses,
//   grades,
//   unitState,
//   collaborators,
//   startDate = null,
//   endDate = null,
//   academicYears = [],
// }) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const staffId = state.login.userInfo.id;

//     const filters = _.get(state, "unitPlans.filters", {
//       searchText: "",
//       isShowPrivate: true,
//     });

//     const courseId = getState().teacher.selected_class.selected_course;
//     const type = "COURSE";
//     const staffData = getUnitPlanFeedFromCache({
//       id: courseId,
//       filters,
//       groupBy: "UNIT_TYPE",
//       type,
//     });

//     const edges = _.get(staffData, `unitPlans.edges`, []);

//     const edgeIndex = _.findIndex(edges, item => {
//       return item.fieldValue.toLowerCase() === fieldValue.toLowerCase();
//     });

//     const totalCount = _.get(staffData, "unitPlans.totalCount", 0);

//     try {
//       await client.mutate({
//         mutation: copyUnitPlanMutation,
//         variables: {
//           unitPlanId: unitPlanId,
//           copiedBy: staffId,
//           title: title,
//           startDate,
//           endDate,
//           courses,
//           grades,
//           academicYears,
//           state: unitState,
//           collaborators,
//           showCollaborators: true,
//         },

//         update: (
//           cache,
//           {
//             data: {
//               planner: { copyUnitPlan },
//             },
//           }
//         ) => {
//           let updatedStaffData;

//           if (edgeIndex < 0) {
//             updatedStaffData = update(staffData, {
//               unitPlans: {
//                 totalCount: { $set: totalCount + 1 },
//                 edges: {
//                   $push: [
//                     {
//                       __typename: "GroupedUnitPlanEdge",
//                       fieldValue: _.get(fieldValue, "value", ""),
//                       type: "UNIT_TYPE",
//                       node: [copyUnitPlan],
//                     },
//                   ],
//                 },
//               },
//             });
//           } else {
//             updatedStaffData = update(staffData, {
//               unitPlans: {
//                 totalCount: { $set: totalCount + 1 },
//                 edges: {
//                   [edgeIndex]: {
//                     node: {
//                       $splice: [[0, 0, copyUnitPlan]],
//                     },
//                   },
//                 },
//               },
//             });
//           }
//           writeUnitPlanFeedInCache({
//             id: courseId,
//             type,
//             filters,
//             groupBy: "UNIT_TYPE",
//             data: updatedStaffData,
//           });
//           writeUnitPlanFeedInCache({
//             id: courseId,
//             type,
//             filters: { isShowPrivate: true, searchText: "" },
//             groupBy: "UNIT_TYPE",
//             data: updatedStaffData,
//           });
//           dispatch(updateGradeUnitPlanCount({ count: 1 }));
//           dispatch(
//             setToastMsg({
//               msg: "toastMsgs:unit_successfully_action",
//               locale_params: [
//                 {
//                   key: "action",
//                   value: "toastMsgs:duplicated",
//                   isPlainText: false,
//                 },
//               ],
//               type: "tick",
//               position: "toast-bottom-left",
//             })
//           );
//         },
//       });
//     } catch (err) {
//       writeUnitPlanFeedInCache({
//         id: courseId,
//         type,
//         filters,
//         groupBy: "UNIT_TYPE",
//         data: staffData,
//       });
//       writeUnitPlanFeedInCache({
//         id: courseId,
//         type,
//         filters: { isShowPrivate: true, searchText: "" },
//         groupBy: "UNIT_TYPE",
//         data: staffData,
//       });
//       dispatch(
//         setToastMsg({
//           msg: "toastMsgs:unit_duplication_failed",
//           type: "alert",
//           position: "toast-bottom-left",
//         })
//       );
//     }
//   };
// };

export const importUnitPlan = params => {
  const { input } = params;
  return async (dispatch, getState) => {
    try {
      await client.mutate({
        mutation: importUnitPlanMutation,
        variables: {
          input,
        },
      });
      //console.log("MUTATION", { input });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:unit_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:imported", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (err) {
      dispatch(
        setToastMsg({
          msg: "toastMsgs:unit_importing_failed",
          type: "alert",
          position: "toast-bottom-left",
        })
      );
    }
  };
};

export const getFieldTypeConfig = ({ fieldTypeConfig, fieldKey, unitType }) => {
  switch (fieldKey) {
    // case "subjects":
    //   return {
    //     multi: _.includes(["ibInquiry", "ibMYPInterdisciplinary"], unitType)
    //   }; //Handle multi based on unitType wheather it is inquiry or standalone
    default:
      return fieldTypeConfig;
  }
};

export const getFieldObject = ({ field_list, fieldKey }) => {
  const fieldObject = _.find(field_list, (value, key) => {
    const fieldObject = field_list[key];

    const subFields = _.get(fieldObject, "subFields", []);

    return (
      key === fieldKey ||
      _.findIndex(subFields, ({ key }) => key === fieldKey) > -1
    );
  });

  const subFieldObject = _.find(
    _.get(fieldObject, "subFields", []),
    ({ key }) => key === fieldKey
  );

  if (_.isEmpty(subFieldObject)) {
    return fieldObject;
  }

  return subFieldObject;
};

export const getPlannerFieldOptions = ({
  fieldKey,
  organizationId,
  grades,
  unitPlanReduxData,
  defaultOptions,
  unitPlanFields,
  field_list,
  readOnly,
  plannerElements, //To fetch curriculum planner elements
  curriculumType, //To handle curriculum specific condition
  courseId, //To fetch subject from course
  mode = "edit",
  objectiveLevelNodes = [], // get the levels of objectives for subjects
  selectedAcademicYear,
  courseSubjects,
  t,
  academicYears,
  genericTags,
}) => {
  let subjects = [];
  let subjectGroups = [];

  if (!_.isEmpty(unitPlanReduxData)) {
    unitPlanFields = _.map(unitPlanReduxData, (obj, key) => {
      return { uid: key, ...obj };
    });
  }

  const unitType = _.get(
    _.find(unitPlanFields, { uid: "unitType" }),
    "value",
    ""
  );

  const fieldObj = getFieldObject({ field_list, fieldKey });

  switch (fieldKey) {
    case "dateDuration": {
      let academicYearStartDate, academicYearEndDate, minDate, maxDate;

      if (!_.isEmpty(academicYears)) {
        const { startDate, endDate } = getStartEndDatesOfAcademicYears({
          academicYears,
        });

        minDate = moment(startDate).format("YYYY-MM-DD");
        maxDate = moment(endDate).format("YYYY-MM-DD");
      } else {
        if (!_.isNil(selectedAcademicYear)) {
          academicYearStartDate = selectedAcademicYear.startDate;
          academicYearEndDate = selectedAcademicYear.endDate;
        } else {
          const currentAcademicYear = getCurrentAcademicYear({
            organizationId,
          });
          academicYearStartDate = _.get(currentAcademicYear, "startDate");
          academicYearEndDate = _.get(currentAcademicYear, "endDate");
        }

        minDate = moment(academicYearStartDate).format("YYYY-MM-DD");
        maxDate = moment(academicYearEndDate).format("YYYY-MM-DD");
      }

      return {
        minDate,
        maxDate,
      };
    }
    case "subjects":
      if (curriculumType == "IB_MYP") {
        let subjectList = [];

        const objectiveLevelsData =
          _.find(unitPlanFields, { uid: "objectiveLevel" }) || {};
        const selectedObjectiveLevels = _.get(objectiveLevelsData, "value", "");
        subjectGroups = _.get(
          _.find(
            _.get(
              getOrganizationCurriculumProgramsFromCache(organizationId),
              "curriculumPrograms",
              []
            ),
            { type: curriculumType }
          ),
          "subjectGroups",
          []
        );

        if (courseId) {
          subjectList = _.get(
            getCourseDetailsFromCache({
              id: courseId,
            }),
            "subjects",
            []
          );
        } else {
          const subjectsFromUnitPlan = _.find(unitPlanFields, {
            uid: "subjects",
          });

          subjectList = _.get(
            subjectsFromUnitPlan,
            "resolvedMinimalTree.subjects",
            []
          );
        }

        // Extract subjects from current class
        const initSubject = !_.isEmpty(subjectList) ? _.first(subjectList) : {};

        // For MYP Subject Specific Unit extract subject group from current class
        if (unitType == "ibMYPSubjectSpecific") {
          subjectGroups = _.filter(
            subjectGroups,
            sg => _.get(initSubject, "subjectGroup.id", "") == sg.id
          );
        }
        const objectiveUidsWithSelectedValue = _.filter(
          unitPlanFields,
          field => {
            return (
              (_.startsWith(field.uid, "disciplinaryObjectivesMYP") ||
                _.isEqual(field.uid, "objectivesMYP")) &&
              !_.isEmpty(field.value)
            );
          }
        );
        const selectedObjectiveLevelsWithValue = [];
        _.forEach(objectiveUidsWithSelectedValue, objective => {
          //for every subjects objective filtered out there selected objectiveids
          selectedObjectiveLevelsWithValue.push(
            ..._.map(
              //filtered out the selected objectives parent
              _.filter(
                objective.resolvedMinimalTree.nodes,
                node => node.depth == 1
              ),
              objectiveNode => objectiveNode.id
            )
          );
        });

        return {
          subjectGroups,
          initSubject,
          objectiveLevelNodes,
          selectedObjectiveLevels,
          selectedObjectiveLevelsWithValue,
          isEditable: mode == "create",
          unitType,
        };
      } else {
        subjects = getSubjectList({
          grades,
          unitPlanFields,
          readOnly,
        });
        if (
          curriculumType == CURRICULUM_TYPE_UBD &&
          unitType == UBD_SUBJECT_SPECIFIC
        ) {
          //filtering data of subjects with course mapped subjects
          subjects = _.filter(subjects, subject =>
            _.find(
              courseSubjects,
              courseSubject => courseSubject.id == subject.id
            )
          );
        }
        return getSubjectOptions({ subjects });
      }
    case "subjectLevel": {
      //In DP for subject level there will always 1 subject mapped to course so we are getting the data of 1st subject
      const subjectVariant = _.get(
        getCourseDetailsFromCache({
          id: courseId,
        }),
        "subjects[0].variants",
        []
      );
      const subjectLevels = _.map(subjectVariant, variant => {
        return {
          value: _.get(variant, "level.id", ""),
          label: _.get(variant, "level.label", ""),
        };
      });
      return subjectLevels;
    }
    case "benchmarks":
      return getBenchmarksOptions({ unitPlanFields, grades });
    case "relatedConcepts":
      return getRelatedConceptOptions({ unitPlanFields, defaultOptions });
    case "teacherQues":
    case "studentQues":
    case "F16":
      return getTeacherQuestionsOptions({ unitPlanFields });
    case "focusAreas":
      return getFocusAreaOptions({
        organizationId,
        unitPlanFields,
        readOnly,
      });
    case "theme":
    case "concepts":
    case "action":
    case "lp":
      return getElementOptions({
        organizationId,
        uid: fieldKey,
        unitPlanFields,
        readOnly,
      });

    case "atls":
      return getAtlsOptions({
        organizationId,
        grades,
        unitPlanFields,
        readOnly,
      });
    case "unitType":
      return getUnitTypeOptions({ grades, organizationId, curriculumType });
    case "plannerType":
      return getPlannerTypeOptions({
        grades,
        unitType,
        curriculumType,
        selectedSubjects: _.get(unitPlanReduxData, "subjects", {}),
      });
    case "conceptsQuestion":
      return getElementQuesOptions({
        organizationId,
        fieldObj,
        unitPlanFields,
        uid: "concepts",
      });
    case "actionQuestion":
      return getElementQuesOptions({
        organizationId,
        fieldObj,
        unitPlanFields,
        uid: "action",
      });
    default: {
      const configType = _.get(fieldObj, "config.type", "");

      if (configType == "PLANNER_ELEMENT") {
        return getPlannerElementOptions({
          t,
          grades,
          fieldObj,
          plannerElements,
          unitPlanFields,
          objectiveLevelNodes,
          fieldKey,
          genericTags,
        });
      } else {
        const filteredBy = _.get(fieldObj, "config.filteredBy", []);

        if (!_.isEmpty(filteredBy)) {
          return getNonPlannerFieldOptions({
            filteredBy,
            unitPlanFields,
            defaultOptions,
            genericTags,
            objectiveLevelNodes,
            plannerElements,
            fieldObj,
            t,
            grades,
            fieldList: field_list,
          });
        }

        return defaultOptions ? defaultOptions : [];
      }
    }
  }
};

const getNonPlannerFieldOptions = ({
  filteredBy,
  unitPlanFields,
  defaultOptions,
  genericTags,
  objectiveLevelNodes,
  plannerElements,
  fieldObj,
  t,
  grades,
  fieldList,
}) => {
  const filteredNodes = [];

  let options = {};

  let resolvedMinimalTree = {};

  _.forEach(filteredBy, fieldKey => {
    const { type } = fieldList[fieldKey];

    switch (type) {
      case "NestedSelectComponent": {
        options = getPlannerElementOptions({
          t,
          grades,
          fieldObj,
          plannerElements,
          unitPlanFields,
          objectiveLevelNodes,
          fieldKey,
          genericTags,
        });

        resolvedMinimalTree = _.get(
          _.find(unitPlanFields, ({ uid }) => uid == fieldKey),
          "resolvedMinimalTree",
          {}
        );

        break;
      }
      default: {
        const resolvedValue = _.get(
          _.find(unitPlanFields, { uid: fieldKey }),
          "resolvedMinimalTree.nodes",
          []
        );

        _.forEach(resolvedValue, node => {
          if (node) {
            filteredNodes.push({
              label: node.label,
              value: node.id,
              subText: node.subText,
            });
          }
        });
      }
    }
  });
  return {
    ...defaultOptions,
    nodes: filteredNodes,
    extraText: "",
    ...options,
    resolvedMinimalTree,
  };
};

export const getFieldLockedObjectForFieldUids = ({ key, lockedFields }) => {
  let uids = [];
  switch (key) {
    case "conceptsQuestion":
    case "concepts":
      uids = ["conceptsQuestion", "concepts"];
      break;

    default:
      uids = [key];
  }

  return getFieldLockedObject({ uids, lockedFields });
};

/*
Handle SubFields for Unit Plan, LE and Assessment.

@params
  fieldKey :"inquiryQuestions"
  fieldTemplateObj: field obj of template
  fieldDataObj: field data obj get from backend.
  unitPlanFieldDataObj: unitplan field data obj get from backend
*/

//Need Review @kunal
export const getSubFields = ({
  fieldKey,
  fieldTemplateObj,
  fieldDataObj,
  unitPlanFieldDataObj,
  isUnitPlan = false,
}) => {
  const subFields = _.get(fieldTemplateObj, "subFields", []);

  return _.reduce(
    subFields,
    (result, subField) => {
      const { key } = subField;

      let fieldValue = _.get(fieldDataObj, "value", {});
      const unitPlanFieldValue = _.get(unitPlanFieldDataObj, "value", {});
      fieldValue = getElementValue({
        fieldUID: fieldKey,
        valueKeys: fieldValue,
        unitPlanFieldValue,
        fieldObj: fieldTemplateObj,
        isUnitPlan,
      });

      const subFieldValue = fieldValue[key];

      if (!_.isEmpty(subFieldValue)) {
        result.push(subField);
      }

      return result;
    },
    []
  );
};

//Need Review @kunal
export const getPlannerElementOptions = ({
  fieldObj,
  plannerElements,
  unitPlanFields,
  grades,
  genericTags,
  objectiveLevelNodes,
  t,
  fieldKey,
}) => {
  const config = _.get(fieldObj, "config", {});
  const dynamicSubject = _.get(fieldObj, "dynamicSubject", {});
  const { isSubjectSpecificDynamic = false } = config || {};
  const plannerElementConfig = _.get(config, "plannerElementConfig", {}) || {};

  const unitType = _.get(
    _.find(unitPlanFields, { uid: "unitType" }),
    "value",
    ""
  );

  const {
    type,
    filteredBy,
    subType,
    isPlannerElementWithoutParent,
    rootNodeLabel,
  } = plannerElementConfig;
  const plannerElementSet =
    _.find(plannerElements, {
      type: type,
    }) || {};

  let plannerElementNodes = _.get(plannerElementSet, "nodes", []);

  let options = {};
  let parentNodes = [];
  let nodes = [];
  let value = [];
  switch (type) {
    case "MYP_ATL":
    case "UBD_ATL": {
      parentNodes = _.filter(plannerElementNodes, ({ parent }) => !parent);
      return {
        parentNodes: [
          {
            id: type,
            label: "",
          },
        ],
        plannerElementSetData: {
          type,
          parentType: "PLANNER_ELEMENT",
          id: plannerElementSet.id,
          groupedByType: null,

          filters: {
            associatedParents: [{ ids: grades, type: "GRADE" }],
          },
        },
      };
    }

    //It should be grouped by Subject Group category
    case "MYP_OBJECTIVES":
      /*
      Handled for Interdiscplinary Criteria
    */
      if (subType == "INTERDISCIPLINARY_CRITERIA") {
        return {
          parentNodes: [
            {
              id: "INTERDISCIPLINARY_CRITERIA",
              label: "Interdisciplinary objectives",
            },
          ],
          plannerElementSetData: {
            type,
            subType,
            parentType: "PLANNER_ELEMENT",
            id: plannerElementSet.id,
            groupedByType: null,
          },
        };
      }
      //Handled for Subject Objectives
      else {
        const resolvedValue = _.get(
          _.find(unitPlanFields, { uid: "subjects" }),
          "resolvedMinimalTree.subjects",
          []
        );
        const objectiveLevelsData =
          _.find(unitPlanFields, { uid: "objectiveLevel" }) || {};
        let selectedObjectiveLevels = _.get(objectiveLevelsData, "value", "");
        //filter out the empty objectives
        selectedObjectiveLevels = _.filter(selectedObjectiveLevels, Boolean);

        parentNodes = _.map(resolvedValue, value => {
          return {
            id: value.id,
            label: value.name,
            selectedObjectiveIds: _.find(
              selectedObjectiveLevels,
              objectiveId => {
                const objective = _.find(objectiveLevelNodes, {
                  id: objectiveId,
                });
                return _.find(_.get(objective, "associatedParents", []), {
                  id: value.id,
                });
              }
            ),
          };
        });
        //For Disciplinary Subject Grounding Step, It should be only one lens of that subject group
        if (isSubjectSpecificDynamic) {
          parentNodes = _.filter(
            parentNodes,
            parentNode => parentNode.id == dynamicSubject.id
          );
        }

        const groupedByType = "SUBJECT";

        return {
          parentNodes,
          plannerElementSetData: {
            type,
            groupedByType,
            id: plannerElementSet.id,
            parentType: "PLANNER_ELEMENT",
          },
          // selectedObjectiveIds
        };
      }

    //It should be grouped by Subjects
    case "MYP_LEARNING_STANDARD":
    case "UBD_LEARNING_STANDARD":
    case "DP_SUBJECT_STANDARD":
      parentNodes = _.map(
        _.get(
          _.find(unitPlanFields, { uid: "subjects" }),
          "resolvedMinimalTree.subjects",
          []
        ),
        subject => {
          return { id: subject.id, label: subject.name };
        }
      );

      //For Displinary Subject Grounding Step, It should be only one lens of that subject
      if (isSubjectSpecificDynamic) {
        parentNodes = _.filter(
          parentNodes,
          parentNode => parentNode.id == dynamicSubject.id
        );
      }

      return {
        parentNodes,
        plannerElementSetData: {
          type,
          parentType: "PLANNER_ELEMENT",
          id: plannerElementSet.id,
          groupedByType: "SUBJECT",
          filters: { associatedParents: [{ ids: grades, type: "GRADE" }] },
        },
      };
    case "DP_SYLLABUS":
      parentNodes = _.map(
        _.get(
          _.find(unitPlanFields, { uid: "subjects" }),
          "resolvedMinimalTree.subjects",
          []
        ),
        subject => {
          return { id: subject.id, label: subject.name };
        }
      );

      return {
        parentNodes,
        plannerElementSetData: {
          type,
          parentType: "PLANNER_ELEMENT",
          groupedByType: "SUBJECT",
          filters: {
            tags: genericTags,
          },
        },
      };
    case "DP_TOK_CONNECTION": {
      let parentNodes = _.filter(plannerElementNodes, { parent: null });
      parentNodes = _.map(parentNodes, parentNode => {
        const children = _.map(parentNode.children, child => {
          return _.find(plannerElementNodes, { id: child });
        });
        return {
          id: parentNode.id,
          label: parentNode.label,
          children,
        };
      });
      return parentNodes;
    }
    case "MYP_KEY_CONCEPT":
      //For Interdisciplinary unit, show all Key Concepts in a single list
      if (unitType == "ibMYPInterdisciplinary") {
        return _.map(plannerElementNodes, node => {
          return { ...node, value: node.id };
        });
      } else {
        /*
        Show two lens - One is subject specific and second is others
      */
        const subjectGroupedValue = _.get(
          _.find(unitPlanFields, { uid: "subjectGroup" }),
          "value",
          []
        );

        const subjectGroupedNode = _.first(
          _.get(
            _.find(unitPlanFields, { uid: "subjectGroup" }),
            "resolvedMinimalTree.subjectGroups",
            []
          )
        );

        const subjectGroupLabel = _.get(subjectGroupedNode, "name", "");

        //Filter out subject specific options

        const subjectSpecificOptions = filteredPlannerElementNodesByAssociatedParent(
          { plannerElementNodes, filerFieldValue: subjectGroupedValue }
        );

        //Filter out other options
        const otherOptions = _.differenceBy(
          plannerElementNodes,
          subjectSpecificOptions,
          "id"
        );

        //Adding only those lens in which options are not empty
        parentNodes = [];
        if (!_.isEmpty(subjectSpecificOptions)) {
          parentNodes.push({
            id: "SUBJECT_SPECIFIC",
            label: t("common:key_concepts_with_subject_group_name", {
              subjectGroupName: subjectGroupLabel,
            }),
            children: _.map(subjectSpecificOptions, node => {
              return { ...node, value: node.id };
            }),
          });
        }
        if (!_.isEmpty(otherOptions)) {
          parentNodes.push({
            id: "OTHERS",
            label: t("common:other_subject_group_key_concepts_label"),
            children: _.map(otherOptions, node => {
              return { ...node, value: node.id };
            }),
          });
        }

        return parentNodes;
      }

    case "MYP_RELATED_CONCEPT":
      parentNodes = _.map(
        _.get(
          _.find(unitPlanFields, { uid: "subjects" }),
          "resolvedMinimalTree.subjects",
          []
        ),
        subject => {
          return { id: subject.id, label: subject.name };
        }
      );
      //For Displinary Subject Grounding Step, It should be only one lens of that subject
      if (isSubjectSpecificDynamic) {
        parentNodes = _.filter(
          parentNodes,
          parentNode => parentNode.id == dynamicSubject.id
        );
      }
      return {
        parentNodes,
        plannerElementSetData: {
          type,
          parentType: "PLANNER_ELEMENT",
          id: plannerElementSet.id,
          groupedByType: "SUBJECT",
        },
      };
    case "DP_AIM":
    case "DP_OBJECTIVES":
      /*
      we are creating a union of nodes here beacause if parent is removed based on filters than we can still display the selected
      nodes
      Eg : if we have selected a HL aim and removed HL from the subject level than we wont get the HL nodes in Planner elements
      so if any of the HL node is selected we can fetch it from resolved minimal tree
      */
      nodes = getPlannerElementNodesWithSelectedValues({
        plannerElementNodes,
        unitPlanFields,
        fieldKey,
      });
      parentNodes = _.reduce(
        nodes,
        (result, node) => {
          const associatedParents = _.map(node.associatedParents, parent => {
            return { id: parent.id, label: parent.name };
          });

          result = _.unionBy(result, associatedParents, "id");
          return result;
        },
        []
      );

      value = _.reduce(
        nodes,
        (result, node) => {
          const associatedParents = _.map(node.associatedParents, parent => {
            return parent.id;
          });
          if (
            _.find(parentNodes, parentNode => {
              return _.includes(associatedParents, parentNode.id);
            })
          ) {
            result.push(node.id);
          }
          return result;
        },
        []
      );
      /*
        description of options for nestedCHecklist
        parent nodes are the nodes which will be at depth 0
        values are the id of the nodes which we are displaying as options
        nodes will store the details of the values
      */
      return {
        parentNodes,
        value,
        nodes,
        plannerElementSetData: {
          type,
          parentType: "PLANNER_ELEMENT",
          groupedByType: "SUBJECT",
          filters: {
            tags: genericTags,
          },
        },
      };
    default:
      if (isPlannerElementWithoutParent) {
        /**
         * When ever we want to use the SNS editor where there is no parent associated to the plannerelement node
         * we need to pass a boolean for that planner element isPlannerElementWithoutParent so this will add a dummy
         * root node to the list of nodes
         */
        nodes = getPlannerElementNodesWithSelectedValues({
          plannerElementNodes,
          unitPlanFields,
          fieldKey,
        });
        const dummyRootNode = getDummyRootNode({
          t,
          type,
          rootNodeLabel,
          rootNodes: _.reduce(
            nodes,
            (result, node) => {
              if (node.depth === 1) result.push(node.id);
              return result;
            },
            []
          ),
        });
        parentNodes = [dummyRootNode];
        nodes = [dummyRootNode, ...nodes];
        value = _.reduce(
          nodes,
          (result, node) => {
            const associatedParents = [node.parent];
            if (
              _.find(parentNodes, parentNode => {
                return _.includes(associatedParents, parentNode.id);
              })
            ) {
              result.push(node.id);
            }
            return result;
          },
          []
        );
        nodes = _.map(nodes, node => {
          if (_.isEmpty(node.parent))
            return {
              ...node,
              parent: -1,
            };
          return node;
        });
        /*
          description of options for nestedCHecklist
          parent nodes are the nodes which will be at depth 0
          values are the id of the nodes which we are displaying as options
          nodes will store the details of the values
        */
        return {
          parentNodes,
          value,
          nodes,
          dummyRootNode,
          plannerElementSetData: {
            type,
            parentType: "PLANNER_ELEMENT",
            groupedByType: type,
          },
        };
      }

      /*
    Handled If you want to filtered nodes in the frontend
    E.g - Focus Areas are filtered by Global Context, Related Key Concepts are filtered by Subject Group
  */
      if (!_.isEmpty(filteredBy)) {
        _.map(filteredBy, filterKey => {
          const filerFieldValue = _.get(
            _.find(unitPlanFields, { uid: filterKey }),
            "value",
            []
          );

          plannerElementNodes = filteredPlannerElementNodesByAssociatedParent({
            plannerElementNodes,
            filerFieldValue,
          });
        });
      }

      options = _.map(plannerElementNodes, node => {
        return { ...node, value: node.id };
      });
      return options;
  }
};

//if the selected node is removed from the planner elements still we will add it from here so that user will be able to remove it
const getPlannerElementNodesWithSelectedValues = ({
  plannerElementNodes,
  unitPlanFields,
  fieldKey,
}) => {
  return _.unionBy(
    plannerElementNodes,
    _.get(
      _.find(unitPlanFields, { uid: fieldKey }),
      "resolvedMinimalTree.nodes",
      []
    ),
    "id"
  );
};

const getDummyRootNode = ({ rootNodeLabel, rootNodes, type, t }) => {
  return {
    id: -1,
    name: t(rootNodeLabel),
    depth: 0,
    type,
    children: rootNodes,
    isDummy: true,
  };
};

const filteredPlannerElementNodesByAssociatedParent = ({
  plannerElementNodes,
  filerFieldValue,
}) => {
  return _.filter(plannerElementNodes, node => {
    const associatedParents = _.reduce(
      node.associatedParents,
      (result, assParent) => {
        if (assParent) {
          result.push(assParent.id);
        }
        return result;
      },
      []
    );

    if (!!filerFieldValue && !_.isArray(filerFieldValue)) {
      filerFieldValue = [filerFieldValue];
    }

    return !_.isEmpty(_.intersection(associatedParents, filerFieldValue));
  });
};

export const getFilteredUnitTypeBasedOnSubject = ({ subject }) => {
  switch (subject.type) {
    default:
      return [
        {
          id: "ibDPSubjectSpecific",
          name: "TOK Subject Specific",
        },
      ];
  }
};

export const getUnitTypeOptions = ({
  grades,
  organizationId,
  curriculumType,
  t,
  courseId,
}) => {
  // const unitTypes = _.uniqBy(
  //   _.filter(
  //     _.get(getUniqlyCombinedGradesDetails(grades), "plannerTemplates", []),
  //     template => template.type == "UNIT_PLAN" && !!template.unitType
  //   ),
  //   "unitType"
  // );

  //Get unittypes from organization

  const unitTypes = _.filter(
    _.get(getOrganizationDetailsFromCache(organizationId), "unitTypes", []),
    unitType => _.get(unitType, "curriculum.type", "IB_PYP") == curriculumType
  );

  return _.map(unitTypes, (unitType, index) => {
    return {
      label: t ? t(UNIT_TYPE_LOCALE[unitType.id]) : unitType.name,
      value: unitType.id,
    };
  });
};

export const getPlannerTypeOptions = ({
  grades,
  unitType,
  curriculumType,
  selectedSubjects,
}) => {
  const gradeDetails = getUniqlyCombinedGradesDetails(grades);

  let plannerTemplates = _.filter(
    _.get(gradeDetails, "plannerTemplates", []),
    template =>
      template.type == "UNIT_PLAN" &&
      _.get(template, "curriculum.type", "IB_PYP") == curriculumType &&
      (template.unitType == unitType || !template.unitType) //Handled for those templates which are independent from unit type
  );

  //In case of DP we need to fetch the planner types based on the subject type so we are adding a additional filter in case of DP
  if (curriculumType == CURRICULUM_TYPE_DP && !_.isEmpty(selectedSubjects)) {
    const subjectType = _.get(
      _.find(gradeDetails.subjects, sub =>
        _.includes(selectedSubjects?.value, sub.id)
      ),
      "type",
      SUBJECT_TYPE_GENERIC
    );
    plannerTemplates = _.filter(plannerTemplates, template => {
      if (subjectType != SUBJECT_TYPE_GENERIC)
        return template.subType == subjectType;
      return _.isEmpty(template.subType);
    });
  }

  return _.map(plannerTemplates, template => {
    return { label: template.name, value: template.id };
  });
};

const getAtlsOptions = ({ grades, unitPlanFields, readOnly }) => {
  const atlCategories = getATLCategoriesList({
    grades,
    unitPlanFields,
    readOnly,
  });

  return {
    grades,
    atlCategories,
  };
};

const getElementOptions = ({
  organizationId,
  uid,
  unitPlanFields,
  readOnly,
}) => {
  const type = pypElementMapping[uid];
  const pypConstants = getOrganizationPypElementSetFromCache({
    organizationId,
    type: type,
  });
  const setKey = pypElementSetMapping[uid];

  let combineNodes = [];

  if (!readOnly) {
    const elementSet = _.get(pypConstants, `pypElement.${setKey}`);
    const hasRootNodes = _.has(elementSet, "rootNodes");

    if (hasRootNodes) {
      const rootNodes = _.get(elementSet, "rootNodes", []);
      const nodes = _.get(elementSet, "nodes", []);
      _.forEach(rootNodes, (nodeId, index) => {
        const element = _.find(nodes, { id: nodeId });
        if (!_.isEmpty(element)) {
          combineNodes.push(element);
        }
      });
    } else {
      combineNodes = [...combineNodes, ...(elementSet || [])];
    }
  }

  const resolvedValue = _.get(
    _.find(unitPlanFields, { uid: uid }),
    "resolvedMinimalTree",
    {}
  );
  const resolvedNodes = _.get(resolvedValue, setKey, []);
  combineNodes = _.unionBy([...combineNodes, ...resolvedNodes], "id");

  const elements = [];
  _.forEach(combineNodes, element => {
    if (!_.isEmpty(element)) {
      elements.push({
        node: element,
        label: element.label,
        value: element.id,
        subText: element.subText,
      });
    }
  });

  return elements;
};

const getElementQuesOptions = ({
  organizationId,
  fieldObj,
  unitPlanFields,
  uid,
}) => {
  const fieldOptions = _.get(fieldObj, "config.options", {});
  const cacheKey = pypElementMapping[uid];
  const setKey = pypElementSetMapping[uid];
  // let pypConstants = getOrganizationPypElementSetFromCache({
  //   organizationId,
  //   type: cacheKey
  // });
  const resolvedValue = _.get(
    _.find(unitPlanFields, { uid: uid }),
    "resolvedMinimalTree",
    {}
  );

  const nodes = _.get(resolvedValue, setKey, []); //_.get(pypConstants, `pypElement.${setKey}`, {});

  const selectedElement = _.get(
    _.find(unitPlanFields, { uid: uid }),
    "value",
    ""
  );
  const elements = [];
  _.forEach(selectedElement, (nodeId, index) => {
    const element = _.find(nodes, { id: nodeId });
    if (element) {
      elements.push({
        label: element.label,
        value: element.id,
        subText: element.subText,
      });
    }
  });
  const extraText = "";

  return { ...fieldOptions, nodes: elements, extraText };
};

const getFocusAreaOptions = ({ organizationId, unitPlanFields, readOnly }) => {
  const themes = getElementOptions({
    organizationId,
    uid: "theme",
    unitPlanFields,
    readOnly,
  });
  const id = _.get(_.find(unitPlanFields, { uid: "theme" }), "id", "");
  const theme = getUnitPlanFieldFromCache(id).value;

  const focusAreas = _.get(
    _.find(themes, item => _.get(item, "node.id", "") == theme),
    "node.focusAreas",
    []
  );
  return _.map(focusAreas, (obj, index) => {
    return { label: obj.label, value: obj.id };
  });
};

const getSubjectOptions = ({ subjects }) => {
  return _.map(subjects, (subject, index) => {
    return {
      node: subject,
      label: subject.name,
      value: subject.id,
      benchmarkRootNode: subject.benchmarkRootNode,
    };
  });
};

const getTeacherQuestionsOptions = ({ unitPlanFields }) => {
  const id = _.get(_.find(unitPlanFields, { uid: "lois" }), "id", "");
  return _.filter(
    getUnitPlanFieldFromCache(id).value,
    item => _.trim(item.value) != ""
  );
};

const getSubjectList = ({ grades, readOnly, unitPlanFields }) => {
  let subjects = [];
  if (!readOnly) {
    //For MYP Fetch Subjects from Course

    subjects = _.get(getUniqlyCombinedGradesDetails(grades), "subjects", []);
  }
  const resolvedValue = _.get(
    _.find(unitPlanFields, { uid: "subjects" }),
    "resolvedMinimalTree",
    ""
  );
  return _.uniqBy([...subjects, ..._.get(resolvedValue, "subjects", [])], "id");
};

const getATLCategoriesList = ({ grades, readOnly, unitPlanFields }) => {
  let atlCategories = [];
  if (!readOnly) {
    atlCategories = _.get(
      getUniqlyCombinedGradesDetails(grades),
      "atlCategories",
      []
    );
  }
  const resolvedValue = _.get(
    _.find(unitPlanFields, { uid: "atls" }),
    "resolvedMinimalTree",
    []
  );

  return _.uniqBy(
    [
      ...atlCategories,
      ..._.filter(_.get(resolvedValue, "atls", []), item => !item.parent),
    ],
    "id"
  );
};

const getBenchmarksOptions = ({ grades, readOnly, unitPlanFields }) => {
  const subjects = getSubjectList({ grades, readOnly: true, unitPlanFields });
  const subjectList = getSubjectOptions({ subjects });

  return {
    subjects: subjectList,
    grades,
  };
};

const getRelatedConceptOptions = ({ unitPlanFields, defaultOptions }) => {
  const selectdSubject = _.get(
    _.find(unitPlanFields, { uid: "subjects" }),
    "value",
    ""
  );

  const selectedConcepts = _.get(
    _.find(unitPlanFields, { uid: "concepts" }),
    "value",
    ""
  );

  const groupedOptionsFields = _.get(defaultOptions, "groupedOptionsFields", [
    "subjects",
    "concepts",
  ]);
  return {
    subjects: selectdSubject ? selectdSubject : [],
    concepts: selectedConcepts ? selectedConcepts : [],
    groupedOptionsFields: groupedOptionsFields ? groupedOptionsFields : [],
  };
};

export const getRelatedConceptsGroupedValueOptions = ({
  nodes,
  subjects,
  value,
  concepts,
  groupedOptionsFields,
}) => {
  const groupedRelatedConcepts = [];
  const groupedOptions = {};
  const groupedValue = {};
  const subjectRelatedConceptSets = [];
  const conceptRelatedConceptSets = [];
  _.forEach(groupedOptionsFields, fields => {
    switch (fields) {
      case "subjects":
        _.forEach(subjects, subject => {
          const children = _.filter(
            nodes,
            node =>
              node.elementId == subject &&
              node.elementType == "SUBJECT" &&
              node.parent
          );
          const currentOptions = [];
          let currentValue = [];
          if (children.length > 0) {
            _.forEach(children, child => {
              if (child) {
                currentOptions.push({ label: child.label, value: child.id });
              }
            });
            currentValue = _.filter(value, val => {
              const node = _.find(nodes, { id: val });
              return _.get(node, "elementId", "") == subject;
            });
          }
          const label = getSubjectLabel(subject);
          if (label) {
            const id = `SUBJECT:${subject}`;
            subjectRelatedConceptSets.push({
              id,
              label,
            });
            groupedOptions[id] = currentOptions;
            groupedValue[id] = currentValue;
          }
        });
        groupedRelatedConcepts.push({
          id: "SUBJECT",
          label: "Subjects",
          relatedConceptSets: subjectRelatedConceptSets,
        });
        break;
      case "concepts":
        _.forEach(concepts, concept => {
          const children = _.filter(
            nodes,
            node =>
              node.elementId == concept &&
              node.elementType == "CONCEPT" &&
              node.parent
          );

          const currentOptions = [];
          let currentValue = [];
          if (children.length > 0) {
            _.forEach(children, child => {
              if (child) {
                currentOptions.push({ label: child.label, value: child.id });
              }
            });

            currentValue = _.filter(value, val => {
              const node = _.find(nodes, { id: val });
              return _.get(node, "elementId", "") == concept;
            });
          }

          const label = getPypElementLabel({ type: "CONCEPT", value: concept });
          if (label) {
            const id = `CONCEPT:${concept}`;
            conceptRelatedConceptSets.push({
              id,
              label,
            });
            groupedOptions[id] = currentOptions;
            groupedValue[id] = currentValue;
          }
        });

        groupedRelatedConcepts.push({
          id: "CONCEPT",
          label: "Key Concepts",
          relatedConceptSets: conceptRelatedConceptSets,
        });
        break;
    }
  });

  return { groupedRelatedConcepts, groupedValue, groupedOptions };
};

export const changeTab = ({ tab, type = "push" }) => {
  return (dispatch, getState) => {
    if (tab != getState().unitPlans.currentTab) {
      dispatch(
        goToRelativeRoute({
          route: tab,
          type,
          replacePath: getState().unitPlans.currentTab,
        })
      );

      dispatch(setCurrentTab(tab));
    }
  };
};

export const changeViewType = ({ currentViewType, type = "push" }) => {
  return (dispatch, getState) => {
    if (currentViewType != getState().unitPlans.currentTab) {
      dispatch(
        goToRelativeRoute({
          route: currentViewType,
          type,
          replacePath: getState().unitPlans.currentTab,
        })
      );

      dispatch(setCurrentTab(currentViewType));
    }
  };
};

export const getFlatUnitPlanFeedQueryVariables = () => {
  return (dispatch, getState) => {
    const organizationId = getState().login.userInfo.org_id;
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = _.get(
      getState(),
      "unitPlans.filters",
      unitPlanDefaultFilters
    );
    const orderConstants = _.get(
      getState(),
      "unitPlans.orderConstants",
      defaultOrderConstants
    );

    /** MapleBear specific variables required for flat UnitPlans feed fetching logic */
    const {
      centralRepoInput,
      centralRepoFilters,
    } = getUnitPlanCentralRepoInputAndFilters({ courseId, organizationId });
    /* MapleBear end */

    return {
      id: courseId,
      filters: { ...filters, ...centralRepoFilters },
      showCollaborators: true,
      centralRepoInput,
      ...orderConstants,
    };
  };
};

export const getFlatUnitPlanQueryVariables = ({
  fieldUIDs,
  filtersFromParent,
}) => {
  return (dispatch, getState) => {
    const courseId = getState().teacher.selected_class.selected_course;
    const filters = filtersFromParent
      ? filtersFromParent
      : _.get(getState(), "unitPlans.filters", unitPlanDefaultFilters);
    const courseDetails = getCourseDetailsFromCache({
      id: courseId,
    });
    const curriculumType = _.get(
      courseDetails,
      "curriculumProgram.type",
      "IB_PYP"
    );
    return {
      id: courseId,
      uids: fieldUIDs || FIELD_UID_CURRICULUM_MAPPING[curriculumType],
      first: 20,
      orderByDirection: "ASC",
      orderBy: "START_DATE",
      filters: {
        ...filters,
        courses: [courseId],
      },
    };
  };
};

export const deleteUnitPlan = ({ unitplanId }) => {
  return async (dispatch, getState) => {
    const currentViewType = getState().unitPlans.currentViewType;
    const courseId = getState().teacher.selected_class.selected_course;

    const courseDetails = getCourseFeedDetailsFromCache(courseId);
    const curriculumType = _.get(
      courseDetails,
      "curriculumProgram.type",
      "IB_PYP"
    );

    const refetchQueries = [];

    switch (currentViewType) {
      case CARD_VIEW: {
        refetchQueries.push({
          query: getFlatUnitPlanFeedQuery,
          variables: dispatch(getFlatUnitPlanFeedQueryVariables()),
        });
        break;
      }
      case TIMELINE_VIEW:
        refetchQueries.push({
          query: getFlatUnitPlansQuery,
          variables: dispatch(getFlatUnitPlanQueryVariables({})),
        });
        break;
      case COURSE_OVERVIEW:
        refetchQueries.push(
          {
            query: getFlatUnitPlansQuery,
            variables: dispatch(getFlatUnitPlanQueryVariables({})),
          },
          {
            query: getCourseOverviewElementSetsNotCoveredCountQuery,
            variables: {
              id: courseId,
              filters: { curriculumType },
            },
          }
        );
        break;
    }

    try {
      await client.mutate({
        mutation: deleteUnitPlanMutation,
        variables: {
          id: unitplanId,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
      dispatch(updateGradeUnitPlanCount({ count: -1 }));
      dispatch(
        setToastMsg({
          msg: "toastMsgs:unit_action_successfully",
          locale_params: [
            { key: "action", value: "toastMsgs:deleted", isPlainText: false },
          ],
          type: "tick",
          position: "toast-bottom-left",
        })
      );
    } catch (e) {
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const updateSubjectForMypInterdisciplinaryUnit = ({
  unitPlanId,
  addedSubjects,
  removedSubjects,
}) => {
  return async (dispatch, getState) => {
    await client.mutate({
      mutation: updateSubjectForMypInterdisciplinaryUnitMutation,
      variables: {
        unitPlanId,
        addedSubjects,
        removedSubjects,
      },
    });
  };
};

export const updateUnitPlanForPlanathon = ({
  unitPlanId,
  shortlistForPlanathon,
  paritaStarStatus,
  kristenStarStatus,
  juryStarStatus,
  paritaProcessed,
  kristenProcessed,
  juryProcessed,
}) => {
  return async (dispatch, getState) => {
    const unitDetails = getFeedUnitPlanItemFromCache({ id: unitPlanId });
    try {
      const userId = getState().login.userInfo.id;

      let updateUnitDetails = _.cloneDeep(unitDetails) || {};

      if (shortlistForPlanathon != undefined) {
        updateUnitDetails = { ...updateUnitDetails, shortlistForPlanathon };
      }
      if (paritaStarStatus != undefined) {
        updateUnitDetails = { ...updateUnitDetails, paritaStarStatus };
      }
      if (kristenStarStatus != undefined) {
        updateUnitDetails = { ...updateUnitDetails, kristenStarStatus };
      }
      if (juryStarStatus != undefined) {
        updateUnitDetails = { ...updateUnitDetails, juryStarStatus };
      }
      if (paritaProcessed != undefined) {
        updateUnitDetails = { ...updateUnitDetails, paritaProcessed };
      }
      if (kristenProcessed != undefined) {
        updateUnitDetails = { ...updateUnitDetails, kristenProcessed };
      }
      if (juryProcessed != undefined) {
        updateUnitDetails = { ...updateUnitDetails, juryProcessed };
      }

      if (_.isEmpty(updateUnitDetails)) {
        writeFeedUnitPlanItemToCache({
          id: unitPlanId,
          data: updateUnitDetails,
        });
      }
      await client.mutate({
        mutation: updateUnitPlanForPlanathonMutation,
        variables: {
          unitPlanId,
          updatedBy: userId,
          shortlistForPlanathon,
          paritaStarStatus,
          kristenStarStatus,
          juryStarStatus,
          paritaProcessed,
          kristenProcessed,
          juryProcessed,
        },
      });
    } catch (e) {
      console.error("error", e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      if (!_.isEmpty(unitDetails)) {
        writeFeedUnitPlanItemToCache({ id: unitPlanId, data: unitDetails });
      }
    }
  };
};

export const createPlannerResource = ({ nodeId, type, portalType }) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const nodeDetails = getPlannerResourcesOfNodeFromCache({ nodeId, type });
    const createResourceDetails = getState().unitPlans.resourceObject;

    const newResource = {
      ...createResourceDetails,
      usedCount: 0,
      attachments: _.map(createResourceDetails.attachments, attachment => {
        return { ...attachmentObj, ...attachment };
      }),
      id: generateRandomId(),
      createdBy: getStaffBasicDetailsFromCache(userId),
      __typename: "PlannerResource",
    };

    const attachments = _.map(createResourceDetails.attachments, attachment => {
      return _.omit(attachment, ["__typename", "id", "streamUrl", "isRead"]);
    });

    try {
      await client.mutate({
        mutation: createPlannerResourceMutation,
        variables: {
          ...createResourceDetails,
          createdBy: userId,
          attachments: !_.isEmpty(attachments) ? attachments : null,

          parentId: nodeId,
          parentType: type,
          portalType,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            createPlannerResource: newResource,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { createPlannerResource },
            },
          }
        ) => {
          const resourceEdges = _.get(nodeDetails, "resources.edges", []);
          const updatedResourceEdges = [
            { node: createPlannerResource, __typename: "PlannerResourceEdge" },
            ...resourceEdges,
          ];
          const resourceConnection = {
            ...nodeDetails.resources,
            edges: updatedResourceEdges,
            totalCount: nodeDetails.resources.totalCount + 1,
          };
          const data = {
            ...nodeDetails,
            resources: resourceConnection,
          };
          setTimeout(() =>
            writePlannerResourcesOfNodeInCache({
              nodeId,
              data,
              type,
            })
          );
        },
      });
      dispatch(
        setToastMsg({
          type: "tick",
          msg: "toastMsgs:resource_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:created", isPlainText: false },
          ],
        })
      );
    } catch (error) {
      console.error(error);

      setTimeout(() =>
        writePlannerResourcesOfNodeInCache({
          nodeId,
          data: nodeDetails,
          type,
        })
      );

      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(setToastMsg({ msg: error.message, notString: true }));
      }
    }
  };
};

export const clearUnitPlanAssessment = ({ unitPlanId }) => {
  return async (dispatch, getState) => {
    await client.mutate({
      mutation: clearUnitPlanAssessmentToolMutation,
      variables: {
        unitPlanId,
        updatedBy: getState().login.userInfo.id,
      },
    });
  };
};

export const addPlannerResource = ({
  nodeId,
  type,
  resourceIds,
  resources,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const nodeDetails = getPlannerResourcesOfNodeFromCache({ nodeId, type });

    try {
      await client.mutate({
        mutation: addPlannerResourceMutation,
        variables: {
          resourceIds,
          createdBy: userId,
          parentId: nodeId,
          parentType: type,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            addPlannerResource: true,
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { addPlannerResource },
            },
          }
        ) => {
          if (addPlannerResource) {
            const resourceEdges = _.get(nodeDetails, "resources.edges", []);
            const newAddedResources = _.map(resources, resource => {
              return {
                node: {
                  ...resource,
                  usedCount: resource.usedCount + 1,
                },
                __typename: "PlannerResourceEdge",
              };
            });

            const updatedResourceEdges = [
              ...newAddedResources,
              ...resourceEdges,
            ];
            const resourceConnection = {
              ...nodeDetails.resources,
              edges: updatedResourceEdges,
              totalCount:
                nodeDetails.resources.totalCount + newAddedResources.length,
            };
            const data = {
              ...nodeDetails,
              resources: resourceConnection,
            };
            setTimeout(() =>
              writePlannerResourcesOfNodeInCache({
                nodeId,
                data,
                type,
              })
            );
          }
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:resource_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:added", isPlainText: false },
          ],
          type: "tick",
        })
      );
    } catch (error) {
      console.error(error);
      setTimeout(() =>
        writePlannerResourcesOfNodeInCache({
          nodeId,
          data: nodeDetails,
          type,
        })
      );
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(
          setToastMsg({ msg: error.graphQLErrors[0].message, notString: true })
        );
      }
    }
  };
};

export const updatePlannerResource = ({
  resourceId,
  isForced = true,
  resourceDetails,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;

    const updateResourceDetails = getState().unitPlans.resourceObject;
    writeResourceInCache({
      id: resourceId,
      data: {
        ...resourceDetails,
        ...updateResourceDetails,
        attachments: _.map(updateResourceDetails.attachments, attachment => {
          return { ...attachmentObj, ...attachment };
        }),
      },
    });
    const attachments = _.map(updateResourceDetails.attachments, attachment => {
      return _.omit(attachment, ["__typename", "id", "streamUrl", "isRead"]);
    });
    try {
      await client.mutate({
        mutation: updatePlannerResourceMutation,
        variables: {
          ...updateResourceDetails,
          attachments: !_.isEmpty(attachments) ? attachments : null,
          resourceId,
          updatedBy: userId,
          isForced,
        },
      });
      dispatch(
        setToastMsg({
          msg: "toastMsgs:resource_successfully_action",
          locale_params: [
            { key: "action", value: "toastMsgs:updated", isPlainText: false },
          ],
          type: "tick",
        })
      );
    } catch (error) {
      writeResourceInCache({
        id: resourceId,
        data: resourceDetails,
      });
      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(
          setToastMsg({ msg: error.graphQLErrors[0].message, notString: true })
        );
      }
    }
  };
};

export const removePlannerResource = ({
  nodeId,
  type,
  resourceId,
  isForced = false,
}) => {
  return async (dispatch, getState) => {
    const userId = getState().login.userInfo.id;
    const nodeDetails = getPlannerResourcesOfNodeFromCache({ nodeId, type });

    try {
      const result = await client.mutate({
        mutation: removePlannerResourceMutation,
        variables: {
          resourceId,
          isForced,
          deletedBy: userId,
          parentId: nodeId,
          parentType: type,
        },
        optimisticResponse: {
          __typename: "Mutation",
          planner: {
            __typename: "PlannerMutations",
            removePlannerResource: {
              warning: isForced ? null : {},
              response: true,
              __typename: "PlannerResourceRemoveResponse",
            },
          },
        },
        update: (
          cache,
          {
            data: {
              planner: { removePlannerResource },
            },
          }
        ) => {
          if (_.isNull(removePlannerResource.warning)) {
            const resourceEdges = _.get(nodeDetails, "resources.edges", []);
            const updatedResourceEdges = _.filter(
              resourceEdges,
              resource => resource.node.id != resourceId
            );

            const resourceConnection = {
              ...nodeDetails.resources,
              edges: updatedResourceEdges,
              totalCount: nodeDetails.resources.totalCount - 1,
            };
            const data = {
              ...nodeDetails,
              resources: resourceConnection,
            };

            setTimeout(() =>
              writePlannerResourcesOfNodeInCache({
                nodeId,
                data,
                type,
              })
            );
          } else {
            setTimeout(() =>
              writePlannerResourcesOfNodeInCache({
                nodeId,
                data: nodeDetails,
                type,
              })
            );
          }
        },
      });
      const warning = result.data.planner.removePlannerResource.warning;
      if (warning) {
        return warning;
      } else {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:resource_successfully_action",
            locale_params: [
              { key: "action", value: "toastMsgs:removed", isPlainText: false },
            ],
            type: "tick",
          })
        );
        return null;
      }
    } catch (error) {
      setTimeout(() =>
        writePlannerResourcesOfNodeInCache({
          nodeId,
          data: nodeDetails,
          type,
        })
      );

      if (error.networkError) {
        dispatch(setToastMsg("toastMsgs:no_internet_connection"));
      } else {
        dispatch(
          setToastMsg({ msg: error.graphQLErrors[0].message, notString: true })
        );
      }

      return null;
    }
  };
};

export const publishUnitPlanToCountryClasses = ({ courseIds }) => {
  return async (dispatch, getState) => {
    const unitPlanId = _.get(getState(), "planner.unitPlanData.unitPlanId", "");

    const unitPlanData = getUnitPlanDetailsFromCache(unitPlanId);
    const title = _.get(unitPlanData, "title.value", "");
    const startDate = moment(_.get(unitPlanData, "startDate", "")).format(
      "YYYY-MM-DD"
    );
    const endDate = moment(_.get(unitPlanData, "startDate", "")).format(
      "YYYY-MM-DD"
    );
    const staffId = getState().login.userInfo.id;

    //this is variables that will be sent to backend
    const objToSend = {
      unitPlanId,
      copiedBy: staffId,
      title,
      startDate,
      endDate,
      unitPansToCopied: _.map(courseIds, id => {
        return {
          courseId: id,
          grades: _.map(
            _.get(geCourseGradesDetailFromCache(id), "grades", []),
            grade => grade.id
          ),
        };
      }),
    };

    try {
      const response = await client.mutate({
        mutation: publishResourcesMutation,
        variables: {
          input: {
            entityType: "UNIT_PLAN",
            metaData: objToSend,
          },
        },
      });

      const statusResponse = _.get(
        response,
        "data.integration.integrationCreateJobAndTask",
        false
      );

      if (statusResponse) {
        dispatch(
          setToastMsg({
            msg: "toastMsgs:publish_in_progress",
            type: "tick",
            position: "toast-bottom-left",
          })
        );
      } else {
        dispatch(setToastMsg("toastMsgs:something_went_wrong"));
      }
    } catch (e) {
      console.error(e);
      dispatch(setToastMsg("toastMsgs:something_went_wrong"));
    }
  };
};

export const UNIT_STATUS_MAP = {
  PUBLISHED: {
    localeLabel: "common:published",
    icon: <UploadCloudOutlined size={"xxx-small"} variant={"on"} />,
  },
  NOT_PUBLISHED: {
    localeLabel: "common:not_published",
    icon: <CancelledCloudOutlined size={"xxx-small"} variant={"on"} />,
  },
  UNPUBLISHED_CHANGES: {
    localeLabel: "common:unpublished_changes",
    icon: <CancelledCloudOutlined size={"xxx-small"} variant={"on"} />,
  },
  IMPORTED: {
    localeLabel: "toastMsgs:imported",
    icon: <DownloadCloudOutlined size={"xxx-small"} variant={"on"} />,
  },
};

const REDUCER_HANDLERS = {
  [INIT_FILTER_OBJECT]: (state, action) => {
    return update(state, {
      filters: { $set: _.cloneDeep(initialState.filters) },
    });
  },
  [UPDATE_FILTER_OBJECT]: (state, action) => {
    const params = action.data;
    Object.keys(params).map((key, index) => {
      state = update(state, {
        filters: { [key]: { $set: params[key] } },
      });
    });
    return state;
  },
  [SET_CURRENT_TAB]: (state, action) => {
    return update(state, { currentTab: { $set: action.data } });
  },
  [SET_CURRENT_VIEW_TYPE]: (state, action) => {
    return update(state, { currentViewType: { $set: action.data } });
  },

  [SWITCH_YEARLY_VIEW]: (state, action) => {
    return update(state, { showYearlyView: { $set: action.data } });
  },
  [UPDATE_LOCAL_RESOURCE]: (state, action) => {
    const params = action.data;

    Object.keys(params).map((key, index) => {
      state = update(state, {
        resourceObject: { [key]: { $set: params[key] } },
      });
    });

    return state;
  },
  [INIT_LOCAL_RESOURCE]: (state, action) => {
    return update(state, {
      resourceObject: {
        $set: { ...initialState.resourceObject, id: generateRandomId() },
      },
    });
  },
  [SET_UNIT_PLAN_MODE]: (state, action) => {
    return update(state, { unitPlanMode: { $set: action.data } });
  },
  [SET_UNIT_PLAN_READ_ONLY]: (state, action) => {
    return update(state, { readOnly: { $set: action.data } });
  },
  [INIT_CREATE_EDIT_OBJECT]: (state, action) => {
    return update(state, {
      unitPlanCreateEditObj: { $set: initialState.unitPlanCreateEditObj },
    });
  },
  [TOGGLE_SELECT_COURSE]: (state, action) => {
    const { id, currentCourse } = action.data;

    if (id !== currentCourse) {
      const {
          unitPlanCreateEditObj: { selectedCourses },
        } = state,
        elemetIndex = _.indexOf(selectedCourses, id);

      if (elemetIndex > -1) {
        return update(state, {
          unitPlanCreateEditObj: {
            selectedCourses: { $splice: [[elemetIndex, 1]] },
          },
        });
      } else {
        return update(state, {
          unitPlanCreateEditObj: {
            selectedCourses: { $push: [id] },
          },
        });
      }
    }

    return state;
  },
  [UPDATE_CREATE_EDIT_OBJECT]: (state, action) => {
    const params = action.data;

    Object.keys(params).map((key, index) => {
      state = update(state, {
        unitPlanCreateEditObj: { [key]: { $set: params[key] } },
      });
    });

    return state;
  },
  [TOGGLE_PERMISSION]: (state, action) => {
    const params = action.data;

    const elemetIndex = _.findIndex(
      state.unitPlanCreateEditObj.collaboratorsWithPermissions,
      item => {
        return item.id == params.item.id;
      }
    );

    if (elemetIndex > -1)
      return update(state, {
        unitPlanCreateEditObj: {
          collaboratorsWithPermissions: {
            $splice: [
              [elemetIndex, 1, { ...params.item, permission: params.action }],
            ],
          },
        },
      });

    return state;
  },
  [SET_UNIT_PLAN_EVIDENCE_VIEW]: (state, action) => {
    return update(state, { evidenceView: { $set: action.data } });
  },
};

export const unitPlanDefaultFilters = {
  searchText: "",
  // isShowPrivate: true,
  grades: [],
  subjects: [],
  subjectGroups: [],
  unitType: [],
};

export const defaultOrderConstants = {
  orderBy: "UNIT_TYPE",
  orderByDirection: "ASC",
  first: 20,
};

export const CARD_VIEW = "cardView";
export const TIMELINE_VIEW = "timelineView";
export const COURSE_OVERVIEW = "courseOverview";

const initialState = {
  orderConstants: defaultOrderConstants,
  filters: unitPlanDefaultFilters,
  unitPlanMode: "edit",
  readOnly: false,
  evidenceView: false,
  currentTab: null,
  showYearlyView: false,
  currentViewType: null,
  resourceObject: {
    id: "",
    title: "",
    type: "",
    description: "",
    attachments: [],
    attachment: null,
  },
  unitPlanCreateEditObj: {
    selectedCourses: [],
    addedCourses: [],
    collaboratorsWithPermissions: [],
    admins: [],
    unitPlanState: "PUBLISHED",
  },
};

export default function myReducer(state = initialState, action) {
  const handler = REDUCER_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
