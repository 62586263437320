import PropTypes from "prop-types";
import React from "react";
const TwistyIcon = props => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 8 4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.73093 3.39174C3.8835 3.53044 4.1165 3.53044 4.26907 3.39174L7.23443 0.695965C7.50496 0.450028 7.33097 -1.12057e-05 6.96536 -1.12057e-05H1.03464C0.66903 -1.12057e-05 0.495042 0.450027 0.765574 0.695965L3.73093 3.39174Z"
        fill={props.fill}
      />
    </svg>
  );
};
TwistyIcon.defaultProps = {
  width: 12,
  height: 7,
};
TwistyIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};
export default TwistyIcon;
