import React, { useState, memo } from "react";
import classes from "./SelectResourceDialogue.scss";
import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";
import { UIModal, I18nHOC, withLoader } from "UIComponents";
import { createOrganizationResources } from "OrganizationResources/modules/OrganizationResourcesModule";
import classNames from "classnames";
import AttachmentListWrapper from "./AttachmentListWrapper";
import { Button, EmptyState, IconButton } from "@toddle-design/web";
import { CloseOutlined } from "@toddle-design/web-icons";
import { ResourcesIllustration } from "@toddle-design/theme";
import { getOrganizationResourcesFeedQuery } from "OrganizationResources/modules/OrganizationResourcesQuery";
import { getOrganizationResourcesFromCache } from "OrganizationResources/modules/OrganizationResourcesGraphqlHelpers";

const resourceTypes = {
  value: "UNIT_PLAN_RESOURCES",
  label: "Unit plan resources",
  visibleTo: [],
  types: "PLANNER_RESOURCE",
};

const ModalContent = props => {
  const { t, onClose, organizationResources } = props;

  const [selectedResources, setSelecedResources] = useState([]);

  const toggleSelectResources = selectedResources => {
    setSelecedResources(selectedResources);
  };

  const addSelectedResources = () => {
    const { addSelectedResources, onClose } = props;
    addSelectedResources(selectedResources);
    onClose();
  };

  const activityCountClass = classNames(
    { [classes.activityCount]: selectedResources.length > 0 },
    { [classes.noActivityCount]: selectedResources.length == 0 }
  );

  return (
    <React.Fragment>
      <div className={classes.header}>
        <div className={classes.modalHeading}>{t("common:unit_resources")}</div>

        <IconButton
          icon={<CloseOutlined />}
          variant="plain"
          size="medium"
          onClick={onClose}
        />
      </div>
      {!_.isEmpty(organizationResources) ? (
        <React.Fragment>
          <div className={classes.mainContainer}>
            <AttachmentListWrapper
              organizationResources={organizationResources}
              selectedResources={selectedResources}
              toggleSelectResources={toggleSelectResources}
            />
          </div>
          <div className={classes.footer}>
            <div className={activityCountClass}>
              {t("common:resource_selected_count", {
                postProcess: "interval",
                count: selectedResources.length,
              })}
            </div>
            <div className={classes.rightContainer}>
              <Button
                size="medium"
                variant="outlined"
                onClick={onClose}
                className={classes.closeButton}
              >
                {t("common:cancel")}
              </Button>
              <Button
                size="medium"
                disabled={_.isEmpty(selectedResources)}
                onClick={addSelectedResources}
              >
                {t("common:add")}
              </Button>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <EmptyState
          illustration={ResourcesIllustration}
          title={t("common:no_with_label", {
            label: t("common:resources"),
          })}
          subtitle={t("classRoom:no_resources_in_unit_yet")}
        />
      )}
    </React.Fragment>
  );
};

const ModalContentWithLoader = withLoader(ModalContent);

const SelectResourceDialogue = memo(props => {
  const { onClose } = props;

  return (
    <UIModal
      isOpen
      modalContent={classes.modalContent}
      onRequestClose={onClose}
    >
      <ModalContentWithLoader {...props} />
    </UIModal>
  );
});

SelectResourceDialogue.displayName = "SelectResourceDialogue";

const mapStateToProps = (state, ownProps) => {
  const unitPlanId = _.get(ownProps, "unitPlanId");

  const orgResourcesVariables = {
    parentId: unitPlanId,
    parentType: "UNIT_PLAN",
    filters: {
      types: resourceTypes.types,
      visibleTo: resourceTypes.visibleTo,
    },
  };
  return {
    orgResourcesVariables,
  };
};

const mapActionCreators = {
  createOrganizationResources,
};

export default compose(
  I18nHOC({ resource: "common" }),
  connect(mapStateToProps, mapActionCreators),
  graphql(getOrganizationResourcesFeedQuery, {
    name: "getOrganizationResourcesFeed",
    options: ({ orgResourcesVariables }) => ({
      fetchPolicy: "cache-and-network",
      variables: orgResourcesVariables,
    }),
    props({
      getOrganizationResourcesFeed,
      ownProps: { orgResourcesVariables },
    }) {
      const organizationResourcesData = getOrganizationResourcesFromCache(
        orgResourcesVariables
      );
      return {
        isData: !_.isEmpty(organizationResourcesData),
        organizationResources:
          organizationResourcesData?.organizationResources ?? [],
        isLoading: [1, 2].includes(getOrganizationResourcesFeed.networkStatus),
      };
    },
  })
)(SelectResourceDialogue);
