import { withAsyncRouteLoading } from "UIComponents";
import YearGroupDetails from "Administrator/commonComponents/YearGroupDetails";

export default store => ({
  path: "project-group-setup",
  component: withAsyncRouteLoading(
    () =>
      new Promise((cb, reject) => {
        require.ensure(
          [],
          require => {
            const component = require("./components").default;

            cb(component);
          },
          err => {
            reject(err);
          },
          "admin-academicSetup"
        );
      })
  ),
  childRoutes: [YearGroupDetails(store)],
});
