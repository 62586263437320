import React from "react";
import classes from "./ListItem.scss";
import { ProfileCascade } from "UIComponents";

function ListItem(props) {
  const renderItem = () => {
    const { itemType, item } = props;
    switch (itemType) {
      case "UserWithProfileImage": {
        return (
          <div className={classes.labelWithIcon}>
            <div className={classes.iconContainer}>
              <ProfileCascade
                items={[
                  {
                    id: item.value,
                    value: item.label,
                    imageUrl: item.profileImage,
                  },
                ]}
                shouldDynamicColor={true}
              />
            </div>
            {item.label}
          </div>
        );
      }
      default: {
        return item.label;
      }
    }
  };
  return renderItem();
}

export default ListItem;
