import React from "react";
import classes from "./MatchingText.scss";

const MatchingText = ({ searchText, text }) => {
  const searchTextRegex = new RegExp(searchText, "i");
  const startIndex = text.search(searchTextRegex);
  const matchingStringLength = searchText.length;
  const nonMatchingFirstPart = text.substr(0, startIndex);
  const matchingPart = text.substr(startIndex, matchingStringLength);
  const nonMatchingSecondPart = text.substr(startIndex + matchingStringLength);

  return (
    <div className={classes.label}>
      {`${nonMatchingFirstPart}`}
      <span className={classes.matchedPart}>{matchingPart}</span>
      {`${nonMatchingSecondPart}`}
    </div>
  );
};

export default MatchingText;
