import { withAsyncRouteLoading } from "UIComponents";
import { injectReducer } from "store/reducers";
import ProgressReportTasks from "AdminProgressReports/routes/ProgressReportTasks";

export default store => {
  return {
    path: "progress-report-sets",
    component: withAsyncRouteLoading(
      () =>
        new Promise((resolve, reject) => {
          require.ensure(
            [],
            require => {
              const component = require("./components").default;
              const progressReportReducer = require("ProgressReport/modules/ProgressReportModule")
                .default;
              const progressReportReducerName = require("ProgressReport/modules/ProgressReportModule")
                .NAME;
              injectReducer(store, {
                key: progressReportReducerName,
                reducer: progressReportReducer,
              });
              const adminProgressReportReducer = require("AdminProgressReports/modules/AdminProgressReportsModule")
                .default;
              const adminProgressReportReducerName = require("AdminProgressReports/modules/AdminProgressReportsModule")
                .NAME;
              injectReducer(store, {
                key: adminProgressReportReducerName,
                reducer: adminProgressReportReducer,
              });
              resolve(component);
            },
            err => {
              reject(err);
            },
            "progress-report-sets"
          );
        })
    ),
    childRoutes: [ProgressReportTasks(store)],
  };
};
