import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useMount } from "react-use";
import classes from "./SearchInput.scss";
import {
  CloseOutlined,
  MicrophoneOutlined,
  SearchOutlined,
} from "@toddle-design/web-icons";
import ActionChip from "../Chips/ActionChip";
import { useDebouncedCallback } from "Utils/CustomHooks";
import useSpeechToText from "react-hook-speech-to-text";
import { getChipImage, getDynamicTypesConfig } from "../Utils";
import { withPermission } from "lib/PermissionAware";
import { isChrome } from "Utils/DeviceDetectHelpers";
import { I18nHOC } from "UIComponents";
import classNames from "classnames";
import ACLStore from "lib/aclStore";

const MicComponent = props => {
  const {
    enableVoiceSearch,
    setSearchText,
    toggleVoiceSearch,
    delayedChange,
  } = props;

  const {
    isRecording,
    interimResult,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  const intervalRef = useRef(null);

  //stop voice search after 1 seconds of last return results

  useEffect(() => {
    let count = 0;
    if (isRecording) {
      intervalRef.current = setInterval(() => {
        const currentTime = Math.floor(new Date().getTime() / 1000);

        //increase count each time interval run

        count++;

        //if there is time stamp in result will get that
        //otherwise timestamp for that transcript
        const timeDiff =
          currentTime - _.get(_.last(results), "timestamp", currentTime);

        //if result is empty that means timeDiff will be 0 and
        //else part will be checked otherwise first part will be checked
        if (timeDiff && timeDiff > 1000) {
          clearInterval(intervalRef.current);
          stopSpeechToText();
          toggleVoiceSearch({ state: false });

          //if total count is 3 that means total 3 time this was run -- 3 seconds so
          //stop the speech recognition
        } else if (count === 3) {
          clearInterval(intervalRef.current);
          stopSpeechToText();
          toggleVoiceSearch({ state: false });
        }
      }, 1000);
    }
  }, [isRecording]);

  //this will run for mount and de-mount of component
  //and check whether should enable voice search on mount on component or not
  useEffect(() => {
    if (enableVoiceSearch) {
      setSearchText("");
      startSpeechToText();
    }

    return () => {
      clearInterval(intervalRef?.current);
      delayedChange.cancel();
      stopSpeechToText();
    };
  }, [enableVoiceSearch]);

  //this will update text on search bar
  useEffect(() => {
    if (interimResult) {
      setSearchText(interimResult);
    }
  }, [interimResult]);

  const onMicroPhoneClick = () => {
    if (isRecording) {
      clearInterval(intervalRef?.current);
      stopSpeechToText();
      toggleVoiceSearch({ state: false });
    } else {
      toggleVoiceSearch();
    }
  };

  return (
    <MicrophoneOutlined
      onClick={onMicroPhoneClick}
      variant={isRecording ? "link" : "subtle"}
    />
  );
};

const MicComponentWrapper = withPermission(MicComponent);

export const SearchInput = forwardRef((props, inputRef) => {
  const {
    searchText = "",
    onChange = () => {},
    setInputFocus,
    module,
    onUnselectModule,
    enableVoiceSearch,
    toggleVoiceSearch,
    t,
  } = props;
  const [value, setValue] = useState({ searchText, initValue: searchText });

  /**
   * if open modal there is some value by default on backspace both chip and text should be
   * removed
   *
   * this state is for that if search text value and initial value is same then on press
   * remove chip
   *
   * other wise on every text change setInitialValue to empty or null
   */

  const [delayedChange] = useDebouncedCallback(onChange, 300, [
    module,
    searchText,
  ]);

  const typesConfig = getDynamicTypesConfig();

  const setInputValue = value => {
    delayedChange(value);
    setValue({ initValue: "", searchText: value });
  };

  const onCrossClick = () => {
    setInputValue("");
  };

  const onInputChange = e => {
    setInputValue(e.target.value);
  };

  const setInitialValue = params => {
    setValue({ ...value, initValue: params });
  };

  useMount(() => {
    setInputFocus();

    if (searchText) {
      inputRef.current.select();
    }

    if (module && !value.searchText) {
      onUnselectModule();
    }
  });

  const onKeyDown = e => {
    if (e.key == "Backspace") {
      if (module && !value.searchText) {
        onUnselectModule();
      }

      if (module && value.searchText === value.initValue) {
        onUnselectModule();

        setInputValue("");
      }
    }

    /**
     * for de-selecting selected text either user press mouse or left or right arrow key
     * so in those case we set initial value to empty
     */
    if (["ArrowLeft", "ArrowRight"].includes(e.key)) {
      setInitialValue("");
    }

    /**
     * @description disable cursor position
     * jump to stat/end of line
     */
    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const onMouseDown = () => {
    setInitialValue("");
  };

  //this is permission to show mic on global search bar
  const perm = "FeatureFlag:ShowMicOption";

  const showVoiceText = ACLStore.can(perm) && isChrome();

  const searchInputStyle = classNames({
    [classes.searchInput]: true,
    [classes.placeHolderColor]: enableVoiceSearch,
  });

  return (
    <div className={classes.searchContainer}>
      <SearchOutlined className={classes.searchIcon} />
      <div className={classes.searchInputContainer}>
        {module ? (
          <ActionChip
            icon={getChipImage({ type: module })}
            label={typesConfig[module].title}
            size="xxx-small"
            onClose={onUnselectModule}
          />
        ) : null}
        <input
          ref={inputRef}
          type="text"
          className={searchInputStyle}
          placeholder={
            enableVoiceSearch
              ? t("integration:listening")
              : module
              ? t("integration:global_search_placeholder", {
                  label: t(typesConfig[module].title),
                })
              : `${t("common:search")}...`
          }
          value={value.searchText}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          onMouseDown={onMouseDown}
        />
      </div>
      {value.searchText && (
        <div className={classes.clearIcon}>
          <CloseOutlined onClick={onCrossClick} variant="subtle" />
        </div>
      )}
      {value.searchText && showVoiceText && (
        <div className={classes.border}></div>
      )}
      <div
        className={classes.microPhone}
        tabIndex="0"
        role="button"
        aria-pressed="false"
      >
        {isChrome() ? (
          <MicComponentWrapper
            enableVoiceSearch={enableVoiceSearch}
            setSearchText={setInputValue}
            toggleVoiceSearch={toggleVoiceSearch}
            perm={perm}
            delayedChange={delayedChange}
          />
        ) : null}
      </div>
    </div>
  );
});

SearchInput.displayName = "SearchInput";

export default I18nHOC({ resource: ["common", "teacherHomePage"] })(
  SearchInput
);
