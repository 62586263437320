import React, { useRef, useState, useEffect, useImperativeHandle } from "react";

import classes from "./AudioPlayer.scss";
import moment from "moment";
import classNames from "classnames";
import { IconButton } from "@toddle-design/web";
import { PlayFilled, PauseFilled } from "@toddle-design/web-icons";
import { colors, AudioPlayerEvent } from "Constants";

const PlayerButton = props => {
  const { handleClick, mediaType, playing, isUploading } = props;
  const playerButtonClass = classNames(classes.playerButton, {
    [classes.secondaryPlayerButton]: mediaType === "secondary",
    [classes.audioUploading]: isUploading,
  });
  return (
    <IconButton
      icon={
        playing ? (
          <PauseFilled size={"xx-small"} />
        ) : (
          <PlayFilled size={"xx-small"} />
        )
      }
      size={"medium"}
      variant={"plain"}
      onClick={e => {
        e.stopPropagation();
        handleClick();
      }}
      className={playerButtonClass}
      disabled={isUploading}
    />
  );
};

function formatDuration(duration) {
  return moment.duration(duration, "seconds").format("mm:ss", { trim: false });
}

const Bar = props => {
  const barRef = useRef(null);
  const {
    duration,
    curTime,
    onTimeUpdate,
    fileName,
    mediaType,
    showFileName,
    barTimeStyle,
    bar,
    isUploading,
  } = props;

  const curPercentage = (curTime / duration) * 100;

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;

    const bar = barRef.current;
    if (bar) {
      const barStart = bar.getBoundingClientRect().left + window.scrollX;
      const barWidth = bar.offsetWidth;
      const clickPositionInBar = clickPositionInPage - barStart;
      const timePerPixel = duration / barWidth;
      return timePerPixel * clickPositionInBar;
    }
    return 0;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener("mousemove", updateTimeOnMove);

    document.addEventListener("mouseup", e => {
      document.removeEventListener("mousemove", updateTimeOnMove);
    });
  }

  const barClass = classNames(
    { [classes.bar]: true },
    { [classes.secondaryMediaBar]: mediaType === "secondary" }
  );
  const barProgressKnobClass = classNames(
    { [classes.bar__progress__knob]: true },
    { [classes.bar_progress_uploading]: isUploading }
  );

  const playerProgressClass = classNames({ [classes.bar__progress]: true });

  return (
    <div
      className={barClass}
      style={{
        marginTop:
          mediaType === "secondary" || (showFileName && fileName) ? 0 : 18,
      }}
    >
      {mediaType === "primary" && showFileName && fileName && (
        <div className={classes.fileName}>{fileName}</div>
      )}
      {mediaType === "secondary" && (
        <span
          className={classes.barTime}
          style={{
            marginRight: 8,
          }}
        >
          {formatDuration(curTime)}
        </span>
      )}
      <div
        id={"bar__progress"}
        ref={barRef}
        className={playerProgressClass}
        style={{
          background: `linear-gradient(to right, ${colors.violet63} ${curPercentage}%, ${colors.gray87} 0)`,
        }}
        onClick={e => e.stopPropagation()}
        onMouseDown={e => handleTimeDrag(e)}
      >
        <span
          className={barProgressKnobClass}
          style={{
            left: `${curPercentage}%`,
          }}
        />
      </div>
      {mediaType === "secondary" && (
        <span
          className={classes.barTime}
          style={{
            marginLeft: 8,
          }}
        >
          {duration ? formatDuration(duration) : ""}
        </span>
      )}
      {mediaType === "primary" && (
        <div className={classes.barDuration}>
          <span className={classes.barTime} style={{ color: colors.gray48 }}>
            {formatDuration(curTime)}
          </span>
          <span className={classes.barTime} style={{ color: colors.gray48 }}>
            {duration ? formatDuration(duration) : ""}
          </span>
        </div>
      )}
    </div>
  );
};

const AudioPlayer = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    toggleAudio: () => {
      setPlayingWrapper(!playing);
    },
  }));

  const {
    attachment,
    mediaType,
    showFileName,
    controlsStyle,
    isUploading,
  } = props;
  const { url, name } = attachment;
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(null);
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();

  const stopPlay = ({ ref } = {}) => {
    if (!ref || !ref.contains(audioRef.current)) {
      setPlayingWrapper(false);
    }
  };

  const onEnded = () => {
    setCurTime(0);
    setPlayingWrapper(false);
  };

  const setPlayingWrapper = playing => {
    const audio = audioRef.current;

    if (audio) {
      if (playing) {
        AudioPlayerEvent.emit("stop", { ref: audio });
        audio.play();
      } else {
        audio.pause();
      }
      setPlaying(playing);
    }
  };

  useEffect(() => {
    AudioPlayerEvent.on("stop", stopPlay);
    const audio = audioRef.current;

    // state setters wrappers
    const setAudioData = params => {
      setDurationValue();
      setCurTime(audio.currentTime);
    };

    const setDurationValue = () => {
      if (!!audio.duration && audio.duration != Infinity) {
        setDuration(audio.duration);
      }
    };

    const loadAudio = () => {
      if (audio.duration && !!!duration) {
        audio.load();
      }
    };

    const setAudioTime = () => {
      setDurationValue();
      setCurTime(audio.currentTime);
    };

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadedmetadata", setAudioData);

    audio.addEventListener("stalled", loadAudio);

    audio.addEventListener("timeupdate", setAudioTime);
    audio.addEventListener("ended", onEnded);

    // React state listeners: update DOM on React state changes

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      AudioPlayerEvent.off("stop", stopPlay);
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
      audio.removeEventListener("onended", onEnded);
    };
  });

  const controlsClass = classNames(
    { [classes.controls]: true },
    { [classes.secondaryMediaControls]: mediaType === "secondary" }
  );

  return (
    <div className={classes.player}>
      <audio
        ref={audioRef}
        className={"audioPlayer"}
        src={url}
        preload="metadata"
      >
        Your browser does not support the <code>audio</code> element.
      </audio>

      <div className={controlsClass} style={controlsStyle}>
        <PlayerButton
          playing={playing}
          handleClick={() => setPlayingWrapper(!playing)}
          mediaType={mediaType}
          isUploading={isUploading}
        />

        <Bar
          showFileName={showFileName}
          curTime={curTime}
          duration={duration}
          mediaType={mediaType}
          fileName={name}
          onTimeUpdate={time => setClickedTime(time)}
          isUploading={isUploading}
        />
      </div>
    </div>
  );
});

AudioPlayer.defaultProps = {
  mediaType: "primary",
  showFileName: true,
  barTimeStyle: {},
  isUploading: false,
};
export default AudioPlayer;
