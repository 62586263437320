import gql from "graphql-tag";

export const createFolderMutation = gql`
  mutation createFolder($courseId: ID!, $name: String!, $color: String) {
    documentation {
      createFolder(input: { course: $courseId, name: $name, color: $color }) {
        id
        name
      }
    }
  }
`;

export const updateFolderMutation = gql`
  mutation updateFolder(
    $id: ID!
    $courseId: ID
    $name: String
    $color: String
  ) {
    documentation {
      updateFolder(
        input: { id: $id, course: $courseId, name: $name, color: $color }
      ) {
        id
        name
        color
      }
    }
  }
`;

export const deleteFolderMutation = gql`
  mutation deleteFolder($id: ID!) {
    documentation {
      deleteFolder(input: { id: $id })
    }
  }
`;
