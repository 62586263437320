import React from "react";
import classes from "./RelatedConceptItem.scss";
import { SelectDropdown, I18nHOC } from "UIComponents";

class RelatedConceptItem extends React.PureComponent {
  render() {
    const { label, value, options, updateInputField, t } = this.props;
    return (
      <div className={classes.container}>
        <SelectDropdown
          multi={true}
          label={label}
          value={value}
          allowAddOption={true}
          options={options}
          addButtonText={`+ ${t("common:create")}`}
          updateInputField={updateInputField}
          placeholder={t("unitPlan:relatedConcept_dropdown_emptyText")}
        />
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(
  RelatedConceptItem
);
