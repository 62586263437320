import {
  MS_FILE_VIEWER_SUPPORTED_MIMETYPE,
  GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE,
} from "Constants";
import {
  ATTACHMENT_INPUT_ATTRIBUTES,
  ATTACHMENT_OMIT_ATTRIBUTES,
} from "store/static";

export const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const getpdfTronEditModeSupported = ({ file }) => {
  if (_.isEqual(file.type, "FILE")) {
    if (_.isEqual(file.mimeType, "application/pdf")) return true;
    if (
      _.includes(GOOGLE_FILE_VIEWER_SUPPORTED_MIMETYPE, file.mimeType) ||
      _.includes(MS_FILE_VIEWER_SUPPORTED_MIMETYPE, file.mimeType)
    ) {
      return false;
    }
  } else if (file.type === "IMAGE") {
    return false;
  } else {
    return false;
  }
};

export const cleanAttachmentObject = ({ attachment }) => {
  attachment = {
    ...attachment,
    id: attachment?.attachmentId || attachment?.id,
  };
  const attachmentObj = _.pick(attachment, ATTACHMENT_INPUT_ATTRIBUTES);
  return _.omit(attachmentObj, ATTACHMENT_OMIT_ATTRIBUTES);
};
