import React from "react";
import classes from "./AcademicYearSwitcher.scss";
import { colors } from "Constants";
import { RightIcon } from "SvgComponents";
import {
  getAcademicYearLabel,
  ACADEMIC_SWITCHER_ICON_URL,
} from "../PlatformSwitcherUtils";

const AcademicYearSwitcher = ({ academicYearSelected, t }) => {
  const { startDate, endDate } = academicYearSelected;
  return (
    <div className={classes.academicSwitcherButton}>
      <div className={classes.yearContainer}>
        <img src={ACADEMIC_SWITCHER_ICON_URL} alt={"Academic Switcher"} />
        <div className={classes.currentYear}>
          <div className={classes.yearLabel}>{t("common:academic_year")}</div>
          <div className={classes.yearValue}>
            {getAcademicYearLabel({ startDate, endDate })}
          </div>
        </div>
      </div>
      <RightIcon fill={colors.gray48} />
    </div>
  );
};

export default AcademicYearSwitcher;
