import React from "react";

const IframeWrapper = React.memo(
  ({ iframeSrc, iframeRef, iframeLoaded, isLoading }) => {
    return (
      <iframe
        src={iframeSrc}
        width="100%"
        height="100%"
        ref={iframeRef}
        onLoad={iframeLoaded}
        frameBorder="0"
        allow="camera; microphone;fullscreen;"
      ></iframe>
    );
  }
);

IframeWrapper.displayName = "IframeWrapper";
export default IframeWrapper;
