import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SENTRY_URL } from "store/static";
import _ from "lodash";
import { getUserInfofromStorage } from "services/localStorage";
import * as Router from "react-router";
import { browserHistory } from "store/history";
import { reactRouterV3Instrumentation } from "./router";
import {
  sentryTraceSampleRate,
  isSentryEnabled,
  isSentryBrowserTracingEnabled,
  env,
  _sentryMetricOptions,
} from "config";

const getFormattedPlainRoutes = routes => {
  const plainRoutes = Router.createRoutes(routes);

  const formatRoute = (route = {}) => {
    const { path, childRoutes = [] } = route;
    return {
      ...route,
      path: `${path}/`,
      childRoutes: _.map(childRoutes, formatRoute),
    };
  };

  const formattedRoutes = _.map(plainRoutes[0]?.childRoutes, formatRoute);
  return formattedRoutes;
};

const getTraceSampleRate = () => {
  return sentryTraceSampleRate;
};

export const initSentry = ({ plainRoutes }) => {
  // const plainRoutes = Router.createRoutes(routes);

  if (!isSentryEnabled) {
    return;
  }

  const integrations = [];

  if (isSentryBrowserTracingEnabled) {
    integrations.push(
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", /.*\.toddleapp\.com/],
        routingInstrumentation: reactRouterV3Instrumentation(
          browserHistory,
          // Must be Plain Routes.
          plainRoutes,
          Router.match
        ),
        _metricOptions: _sentryMetricOptions,
      })
    );
  }

  Sentry.init({
    dsn: SENTRY_URL,
    environment: env,
    allowUrls: [".toddleapp.com"],
    ignoreErrors: [
      "ChunkLoadError",
      /Loading chunk/i,
      /Failed to execute 'pushState' on 'History'/i,
      /Failed to execute 'send' on 'XMLHttpRequest'/i,
      /Failed to execute 'setStart' on 'Range'/i,
      /Non-Error exception captured with keys/i,
    ],
    integrations,
    tracesSampleRate: getTraceSampleRate(),
    debug: false,
    beforeSend(event, hint) {
      const error = hint.originalException;
      const errorMessage = _.get(error, "message", "");
      const userInfo = getUserInfofromStorage();
      /**
       * @description filter 'JSON syntax error' only for user 121591
       * @link https://sentry.io/organizations/teacher-tools/issues/2241224291/events/9f43a778812342e29cd3f040a3dadb7f/?project=1330341
       */
      if (
        _.get(userInfo, "id", null) == "121591" &&
        errorMessage.match(/JSON syntax error/i)
      ) {
        //console.info("JSON syntax error : Error dropped from sentry report.");
        return null;
      }
      return event;
    },
  });
};

export { Sentry };
