import gql from "graphql-tag";
import {
  organizationResourceFeedFragment,
  organizationChildResourceFragment,
  organizationResourceFragment,
} from "./OrganizationResourcesFragments";

export const getOrganizationResourcesFeedQuery = gql`
  query getOrganizationResourcesFeed(
    $parentId: ID!
    $searchText: String
    $parentType: ENTITY_TYPE_ENUM!
    $types: [ORGANIZATION_RESOURCE_TYPE_ENUM!]
    $visibleTo: [ORGANIZATION_RESOURCE_VISIBLE_TO_ENUM!]
    $organizationResourceFilter: OrganizationResourceFilter
  ) {
    node(id: $parentId, type: $parentType) {
      id
      ... on Organization {
        ...organizationResourceFeedItem
      }
      ... on UnitPlan {
        ...unitPlanResourceFeedItem
      }
    }
  }
  ${organizationResourceFeedFragment.organizationResourceFeed}
  ${organizationResourceFeedFragment.unitPlanResourceFeed}
`;

export const getChildResourcesFeedQuery = gql`
  query getChildResourcesFeed(
    $id: ID!
    $searchText: String
    $visibleTo: [ORGANIZATION_RESOURCE_VISIBLE_TO_ENUM!]
    $filters: OrganizationResourceFilter
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
  ) {
    node(id: $id, type: ORGANIZATION_RESOURCE) {
      ...organizationChildResourceItem
    }
  }
  ${organizationChildResourceFragment.childResourceFragment}
`;

export const getChildResourcesFeedQueryV2 = gql`
  query getChildResourcesFeed(
    $id: ID!
    $first: Int
    $after: String
    $orderBy: ORDER_BY_ENUM
    $orderByDirection: ORDER_BY_DIRECTION
    $filters: OrganizationResourceFilter
  ) {
    node(id: $id, type: ORGANIZATION_RESOURCE) {
      ...organizationChildResourceItem
    }
  }
  ${organizationChildResourceFragment.childResourceFragmentV2}
`;

export const getOrganizationResourcesBasicDetailsQuery = gql`
  query getOrganizationResourcesBasicDetails(
    $parentId: ID!
    $parentType: ENTITY_TYPE_ENUM!
    $types: [ORGANIZATION_RESOURCE_TYPE_ENUM!]
    $visibleTo: [ORGANIZATION_RESOURCE_VISIBLE_TO_ENUM!]
    $resourceType: ORGANIZATION_RESOURCE_RESOURCE_TYPE_ENUM
    $filters: OrganizationResourceFilter
  ) {
    node(id: $parentId, type: $parentType) {
      id
      ... on Organization {
        id
        organizationResources(
          types: $types
          visibleTo: $visibleTo
          resourceType: $resourceType
          filters: $filters
        ) {
          ...organizationResourceBasicDetailsItem
        }
      }
    }
  }
  ${organizationResourceFragment.basicDetails}
`;

export const getOrganizationSyncStatusQuery = gql`
  query getOrganizationSyncStatus($id: ID!, $type: JOB_TYPE_ENUM) {
    node(id: $id, type: ORGANIZATION) {
      id
      ... on Organization {
        syncStatus(type: $type) {
          id
          status
        }
      }
    }
  }
`;
