import React, { useState, useEffect } from "react";
import classes from "./LinkifyModal.scss";
import { UIModal, I18nHOC, RadioButton } from "UIComponents";
import { CreateDropDown } from "Attachment";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { setToastMsg } from "Login/modules/LoginModule";
import { fontStyle } from "Constants";
import { isURL, getThumbUrl, htmlToText } from "Utils";
import { TextInput, Button, MediaCard, IconButton } from "@toddle-design/web";
import { DeleteOutlined } from "@toddle-design/web-icons";

const DROP_DOWN_OPTIONS = [
  { key: "upload_device", label: "upload_device" },
  { key: "add_google_drive", label: "add_google_drive" },
  { key: "add_one_drive", label: "add_one_drive" },
];

const RADIO_BUTTONS = [
  { label: "common:add_url", value: "URL" },
  { label: "common:upload_a_file", value: "FILE" },
];

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const radioButtonLabelStyles = {
  fontSize: "1.6rem",
  ...fontStyle.demiBold,
};
const radioButtonIconStyles = { width: 20, height: 20 };

const buttonDropDownStyles = {
  buttonDropdownParentContainerStyle: {
    width: "100%",
  },
  buttonDropdownButtonComponentStyle: {
    height: "100%",
  },
  buttonDropDownContainerStyle: {
    top: "52px",
    width: "100%",
  },
};

const hoverContainerStyles = {
  width: "100%",
};

const cancelButtonContainerStyle = {
  marginRight: "16px",
};

const inputRefs = {};

const getRadioButtons = ({
  t,
  onClickRadioButton,
  selectedButton,
  isLoading,
}) => {
  return (
    <div className={classes.radioButtonContainer}>
      {_.map(RADIO_BUTTONS, (button, index) => {
        return (
          <div
            className={classes.radioContainer}
            key={button.value}
            onClick={() => onClickRadioButton(button.value)}
          >
            <RadioButton
              label={t(button.label)}
              isChecked={selectedButton === button.value}
              labelStyles={radioButtonLabelStyles}
              iconStyle={radioButtonIconStyles}
              color={"blue"}
              isDisabled={isLoading}
            ></RadioButton>
          </div>
        );
      })}
    </div>
  );
};

const LinkifyModal = ({
  url: urlFromProp,
  title: titleFromProp = "",
  linkType: linkTypeFromProp,
  filename: filenameFromProp,
  mimeType: mimeTypeFromProp,
  fileType: fileTypeFromProp,
  onClickSave,
  onClose,
  t,
  setToastMsg,
}) => {
  // states
  const [urlTypeState, setUrlTypeState] = useState({});
  const [fileTypeState, setFileTypeState] = useState({});
  const [linkType, setLinkType] = useState("URL");
  const [title, setTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputFieldErrors, setInputFieldErrors] = useState({
    url: "",
    title: "",
  });

  // vars
  let state = {};
  if (linkType === "URL") {
    state = urlTypeState;
  }
  if (linkType === "FILE") {
    state = fileTypeState;
  }
  const { filename, url, mimeType, fileType } = state;

  useEffect(() => {
    const lType = linkTypeFromProp || "URL";
    if (lType === "URL") {
      setUrlTypeState({
        url: urlFromProp,
      });
    } else if (lType === "FILE") {
      setFileTypeState({
        url: urlFromProp,
        filename: filenameFromProp,
        mimeType: mimeTypeFromProp,
        fileType: fileTypeFromProp,
      });
    }
    setLinkType(lType);
    setTitle(titleFromProp);
  }, []);

  const updateUrlTypeState = params => {
    setUrlTypeState(prevState => {
      return {
        ...prevState,
        ...params,
      };
    });
  };

  const updateFileTypeState = params => {
    setFileTypeState(prevState => {
      return {
        ...prevState,
        ...params,
      };
    });
  };

  const updateState = params => {
    switch (linkType) {
      case "URL": {
        updateUrlTypeState(params);
        break;
      }
      case "FILE": {
        updateFileTypeState(params);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onSave = () => {
    if (validateInputFields()) {
      let errors = 0;
      _.forEach(inputRefs, ref => {
        if (ref && !ref.value) {
          errors++;
        }
      });
      // validate if there is no file uploaded
      if (!_.trim(url)) {
        errors += 1;
        setError(t("common:please_upload_a_file"));
      }
      if (errors > 0) {
        setToastMsg({
          msg: "toastMsgs:please_fill_required_fields",
          type: "alert",
          position: "toast-top-center",
        });
        return;
      }
      const params = {
        ...state,
        linkType: linkType,
        title,
      };
      if (onClickSave) {
        onClickSave(params);
      }
    }
  };

  const onClickRadioButton = value => {
    setLinkType(value);
  };

  const onAddAttachment = params => {
    const attachment = _.get(params, "0", {});
    const { url, mimeType, name: filename, type } = attachment;
    updateState({ url, filename, mimeType, fileType: type });
    setError("");
  };

  const { thumbUrl, isIcon } = getThumbUrl({
    attachment: { ...state, type: state.fileType },
    width: 40,
    height: 40,
  });

  const validateInputFields = () => {
    if (!isURL(state?.url)) {
      setInputFieldErrors({
        title: "",
        url: t("common:web_link_not_valid"),
      });
      return false;
    } else if (!title) {
      setInputFieldErrors({
        url: "",
        title: t("common:error"),
      });
      return false;
    } else {
      setInputFieldErrors({
        url: "",
        title: "",
      });
      return true;
    }
  };

  const getUrlContainer = () => {
    switch (linkType) {
      case "URL": {
        return (
          <div style={containerStyle}>
            <TextInput
              value={url}
              name={"url"}
              onChange={e => updateState({ url: e.target.value })}
              label={t("common:url")}
              isRequired={true}
              ref={ref => (inputRefs["url"] = ref)}
              placeholder={t("common:enter_with_label", {
                label: t("common:url"),
              })}
              error={inputFieldErrors.url}
              size={"large"}
            />
            {inputFieldErrors.url && (
              <div className={classes.errorText}>{inputFieldErrors.url}</div>
            )}
          </div>
        );
      }
      case "FILE": {
        const isUploaded = url && mimeType;
        return isUploaded ? (
          <div className={classes.uploadButtonContainer}>
            <MediaCard
              cardType={"medium"}
              name={htmlToText(filename) || t("common:untitled")}
              type={fileType}
              mimeType={mimeType}
              url={url}
              thumbUrl={isIcon ? undefined : thumbUrl}
              showActionsOnHover
              onClick={() => window.open(url, "_blank").focus()}
              options={
                <IconButton
                  onClick={() => updateState({ url: "" })}
                  icon={<DeleteOutlined variant={"subtle"} size={"xx-small"} />}
                  variant={"plain"}
                  shouldStopPropagation
                />
              }
            />
          </div>
        ) : (
          <div className={classes.uploadButtonContainer}>
            <CreateDropDown
              dropDownOptions={DROP_DOWN_OPTIONS}
              buttonComponent={
                <Button
                  size="large"
                  disabled={isLoading}
                  variant="progressive"
                  isFullWidth
                >
                  {t("common:upload_with_label", {
                    label: t("common:lowercase", {
                      text: t("common:file"),
                    }),
                  })}
                </Button>
              }
              updateLoadingState={setLoading}
              addAttachment={onAddAttachment}
              {...buttonDropDownStyles}
              multiselect={false}
              hoverContainerStyles={hoverContainerStyles}
            />
            {error && <div className={classes.uploadError}>{error}</div>}
          </div>
        );
      }
    }
  };

  return (
    <UIModal
      isOpen={true}
      onRequestClose={onClose}
      modalContent={classes.modalContent}
      customStyle={{ content: { maxWidth: 480 } }}
    >
      <div className={classes.container}>
        <div className={classes.title}>
          {t("common:insert_with_label", {
            label: t("common:lowercase", { text: t("common:link") }),
          })}
        </div>
        {getRadioButtons({
          t,
          onClickRadioButton,
          selectedButton: linkType,
          isLoading,
        })}
        <div className={classes.inputsContainer}>
          <div className={classes.urlContainer}>{getUrlContainer()}</div>
          <div className={classes.titleInputContainer}>
            <TextInput
              value={title}
              onChange={e => setTitle(e.target.value)}
              label={t("common:text_to_display")}
              isRequired={true}
              ref={ref => (inputRefs["title"] = ref)}
              placeholder={t("common:enter_text_to_display")}
              error={inputFieldErrors.title}
              size="large"
            />
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            size="large"
            onClick={onClose}
            style={cancelButtonContainerStyle}
            variant="outlined-subtle"
          >
            {t("common:cancel")}
          </Button>
          <Button
            size="large"
            disabled={isLoading || !url || !title}
            onClick={onSave}
            variant="progressive"
          >
            {urlFromProp
              ? t("common:update")
              : t("common:insert_with_label", {
                  label: t("common:lowercase", { text: t("common:link") }),
                })}
          </Button>
        </div>
      </div>
    </UIModal>
  );
};

const mapActionCreators = {
  setToastMsg,
};

export default compose(
  I18nHOC({ resource: ["common"] }),
  connect(null, mapActionCreators)
)(LinkifyModal);
