import React, { useState } from "react";
import classes from "./CommentView.scss";
import { I18nHOC } from "UIComponents";
import { colors, fontStyle } from "Constants";
import { CommentBox } from "AppComponents";
import merge from "deepmerge";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { getBasicInfo } from "Community/modules/utils";

const commentBoxStyles = {
  commentPaneContainerStyle: { backgroundColor: colors.white },
  headerStyle: { border: "none" },
  commentBoxContainerStyle: {
    minHeight: 70,
    padding: "6px 32px 24px 16px",
    border: `1px solid ${colors.strokeOne}`,
    borderRadius: "8px",
    background: colors.white,
  },
  bottomButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sendButtonStyle: {
    type: "filled",
  },
  commentItemContainerStyle: { padding: "12px 0" },
  feedStyle: {
    paddingTop: 0,
    overflow: "unset",
  },
  seeMoreContainerStyle: { padding: "16px 0" },
  commentItemStyle: {
    profileCascadeStyle: {
      borderWidth: 0,
      width: 48,
      height: 48,
    },
    nameText: {
      fontSize: "1.8rem",
    },
    timeTextContainerStyle: {
      fontSize: "1.4rem",
    },
    schoolName: {
      fontSize: "1.6rem",
    },
    moreContainer: {
      marginRight: 0,
    },
    messageContainer: {
      marginLeft: "-52px",
      marginTop: "12px",
      minHeight: "initial",
      marginBottom: "12px",
    },
    textContainer: {
      fontSize: "1.8rem",
      color: colors.gray31,
      ...fontStyle.medium,
    },
    replyContainer: {
      marginLeft: "-52px",
      marginTop: "0px",
    },
  },
  commentBoxInnerWrapper: {
    border: "none",
    overflow: "visible",
  },
};

const CommentView = React.memo(props => {
  const {
    entityId,
    parentType,
    entityType,
    t,
    customCommentBoxStyles,
    showHeaderComponent,
    allowReply,
    hideEmptyView,
    nagivateToProfile,
    scrollToPosition,
    isPublicAccess,
  } = props;
  const renderCommentHeaderComponent = params => {
    const { totalCount } = params;
    return showHeaderComponent ? (
      <div className={classes.headerText}>
        {totalCount
          ? t("common:comment_with_count", { count: totalCount })
          : t("common:comment_plural")}
      </div>
    ) : null;
  };
  const commentBoxUpdatedStyles =
    merge.all([commentBoxStyles, customCommentBoxStyles || {}]) || {};

  return (
    <CommentBox
      parentType={parentType}
      nodeId={entityId}
      type={entityType} //this is entity type
      scrollable={false}
      hideEmptyView={hideEmptyView}
      disableTagging={true}
      showProfileAvatar={true}
      onClose={null}
      renderHeaderComponent={renderCommentHeaderComponent}
      allowReply={allowReply}
      theme="COMMUNITY_VIEW"
      nagivateToProfile={nagivateToProfile}
      scrollToPosition={scrollToPosition}
      canCreateAudioComment={false}
      canCreateComment={!isPublicAccess}
      {...commentBoxUpdatedStyles}
      sendButtonText={t("common:post")}
    />
  );
});

CommentView.defaultProps = {
  showHeaderComponent: true,
  hideEmptyView: true,
  nagivateToProfile: true,
};

const mapStateToProps = (state, ownProps) => {
  const { isPublicAccess } = getBasicInfo(state);
  return {
    isPublicAccess,
  };
};

export default compose(
  I18nHOC({
    resource: ["common"],
  }),
  connect(mapStateToProps)
)(CommentView);
