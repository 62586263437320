import React, { useRef } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import classes from "./TheatreMode.scss";
import {
  MediaPlaceholder,
  I18nHOC,
  FileViewer,
  ImageViewer,
} from "UIComponents";
import {
  GetTheatreModeType,
  WORKBOOK_MODE_TYPES,
  PDFTRON_VIEWER_MODE_TYPES,
  WORKBOOK_IFRAME_ID_SELECTORS,
  getThumbnailIcon,
  formatBytes,
} from "Utils";
import { Workbook, PdfTronViewer } from "AppComponents";
import ACLStore from "lib/aclStore";

const styles = {
  mediaPlaceHolderContainerStyle: {
    alignItems: "center",
    width: "100%",
  },
  noteContainerStyle: {
    height: "unset",
  },
  noteContainerWithLimitStyles: {
    height: "unset",
    maxHeight: "unset",
    WebkitBoxOrient: "unset",
  },
};

const WORKBOOK_DISABLED_ITEMS = ["CANCEL_BTN"];

const ViewerComponent = React.memo(props => {
  const {
    theatreModeRef,
    currentMediaData,
    onChangeActiveImage,
    setShowGiveFeedbackModal,
    shouldShowGiveFeedbackButton,
    isPdftronEnabled,
    changePdftronEnable,
    imageOrientation,
    updateImageOrientation,
    onWorkbookUpdateSuccessfully,
    parentRef,
    iframeIdSelector,
    onPdftronUpdateSuccessfullly,
    isTextFocus,
    userInfo,
    t,
    onDiscardDirectly,
    studentDetails,
    invokeWorkbookCustomLoader,
  } = props;

  const isAllSubmissionsRevamp = ACLStore.can(
    "FeatureFlag:AllSubmissionsRevamp"
  );

  const mediaDataAttachments = _.get(currentMediaData, "attachments");

  const mediaDataAttachment = _.first(mediaDataAttachments) || currentMediaData;

  const theatreModeType = GetTheatreModeType({ media: mediaDataAttachment });
  if (
    ACLStore.can("FeatureFlag:WorkbookPreloadWeb") &&
    theatreModeRef &&
    theatreModeRef.current &&
    _.isEqual(theatreModeType, "WORKBOOKVIEWER") &&
    iframeIdSelector &&
    _.isEqual(iframeIdSelector, WORKBOOK_IFRAME_ID_SELECTORS["CLASSROOM"])
  ) {
    theatreModeRef.current.style.height = "0%";
  }
  switch (theatreModeType) {
    case "WORKBOOKVIEWER":
      return (
        <Workbook
          attachment={mediaDataAttachment}
          workbookMode={WORKBOOK_MODE_TYPES["WORKBOOK_FEEDBACK_VIEW"]}
          isFullScreen={false}
          onUpdateSuccessfully={onWorkbookUpdateSuccessfully}
          disableArr={WORKBOOK_DISABLED_ITEMS}
          setShowGiveFeedbackModal={setShowGiveFeedbackModal}
          shouldShowGiveFeedbackButton={shouldShowGiveFeedbackButton}
          parentRef={parentRef}
          iframeIdSelector={iframeIdSelector}
          invokeWorkbookCustomLoader={invokeWorkbookCustomLoader}
        />
      );

    case "IMAGEVIEWER":
      return (
        <ImageViewer
          key={mediaDataAttachment.id}
          attachment={mediaDataAttachment}
          attachmentsArray={[mediaDataAttachment]}
          showInsideParent={true}
          allowDrag={true}
          onChangeActiveImage={onChangeActiveImage}
          showCloseButton={false}
          shouldShowGiveFeedbackButton={shouldShowGiveFeedbackButton}
          setShowGiveFeedbackModal={setShowGiveFeedbackModal}
          imageOrientation={imageOrientation}
          updateImageOrientation={updateImageOrientation}
          isDisableKeyboard={isTextFocus}
        />
      );

    case "FILEVIEWER":
      return (
        <FileViewer file={mediaDataAttachment} key={mediaDataAttachment.id} />
      );

    case "PDFTRONVIEWER":
      return (
        <PdfTronViewer
          file={mediaDataAttachment}
          key={mediaDataAttachment.id}
          shouldShowGiveFeedbackButton={shouldShowGiveFeedbackButton}
          isPdftronEnabled={isPdftronEnabled}
          changePdftronEnable={changePdftronEnable}
          studentDetails={studentDetails}
          pdftronViewerMode={PDFTRON_VIEWER_MODE_TYPES["TEACHER_EDITOR"]}
          annotationUserInfo={userInfo}
          onDiscardDirectly={onDiscardDirectly}
          onUpdateSuccessfully={onPdftronUpdateSuccessfullly}
        />
      );

    case "MEDIAPLACEHOLDER": {
      const iconSvg = getThumbnailIcon({
        type: mediaDataAttachment?.type,
        mimeType: mediaDataAttachment?.mimeType,
      });

      const fileSize = mediaDataAttachment?.metadata?.size || 0;

      return (
        <React.Fragment>
          <div className={classes.mediaHeaderCon}>
            {isAllSubmissionsRevamp ? (
              <div className={classes.headerLeftCon}>
                {iconSvg && iconSvg}

                <div className={classes.mediaNameTextRevamp}>
                  {mediaDataAttachment?.name ||
                    mediaDataAttachment?.title ||
                    t("common:untitled")}
                </div>
                <div className={classes.dot} />

                <div className={classes.mediaType}>
                  {mediaDataAttachment?.type}
                  {fileSize > 0 &&
                    ` (${formatBytes({ bytes: fileSize, decimals: 1 })})`}
                </div>
              </div>
            ) : (
              <div className={classes.mediaNameText}>
                {mediaDataAttachment?.name ||
                  mediaDataAttachment?.title ||
                  t("common:untitled")}
              </div>
            )}
          </div>
          <div className={classes.mediaPlaceWrapper}>
            <MediaPlaceholder
              attachments={[mediaDataAttachment]}
              imageMaxHeight={575}
              imageMaxWidth={920}
              showVideoPlayer={true}
              showFullscreenImage={true}
              containerStyle={styles.mediaPlaceHolderContainerStyle}
              noteContainerStyle={styles.noteContainerStyle}
              noteContainerWithLimitStyles={styles.noteContainerWithLimitStyles}
              showLinkInNewTab={true}
            />
          </div>
        </React.Fragment>
      );
    }
  }
});

const TheatreMode = React.memo(props => {
  const theatreModeRef = useRef(null);
  return (
    <div
      className={classes.container}
      id={WORKBOOK_IFRAME_ID_SELECTORS["THEATRE_MODE"]}
      ref={theatreModeRef}
    >
      <ViewerComponent {...props} theatreModeRef={theatreModeRef} />
    </div>
  );
});

TheatreMode.displayName = "TheatreMode";

const mapStateToProps = (state, props) => {
  const userInfo = _.get(state, "login.userInfo", "");
  return {
    userInfo,
  };
};

const mapActionCreators = {};

export default compose(
  I18nHOC({ resource: ["common", "journal"] }),
  connect(mapStateToProps, mapActionCreators)
)(TheatreMode);
