import React, { PureComponent } from "react";
import classes from "./AssessmentToolChooseList.scss";
import { UIButton, EmptyField, DialogueBox, I18nHOC } from "UIComponents";
import { AssessmentToolPreviewModal } from "UnitPlans/components/LibraryCommonComponents";
import { colors } from "Constants";
import { AlertIcon } from "SvgComponents";
import { IconButton } from "@toddle-design/web";
import { DeleteOutlined } from "@toddle-design/web-icons";

class AssessmentToolChooseList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showPreviewModal: false, showDialogueBox: false };
  }

  onClickPreview = id => {
    this.toolId = id;
    this.setState({ showPreviewModal: true });
  };

  onClickDelete = id => {
    this.toolId = id;

    this.setState({ showDialogueBox: true });
  };

  OnToggleDialogueClick = () => {
    this.setState({ showDialogueBox: false });
  };
  onDialogConfirmClick = () => {
    const { onDeleteTool } = this.props;
    onDeleteTool({ id: this.toolId });
  };

  render() {
    const {
      toolList,
      onCopyTool,
      getPreviewModalBody,
      name,
      modal,
      userId,
      t,
    } = this.props;
    const { showPreviewModal, showDialogueBox } = this.state;

    return (
      <div
        style={modal ? { boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.1)" } : {}}
        className={classes.container}
      >
        {_.map(toolList, tool => {
          const {
            id,
            title,
            createdBy: { firstName, lastName, id: createdId } = {},
          } = tool;

          return (
            <div className={classes.item} key={id}>
              <div className={classes.details}>
                <div className={classes.title}>
                  {!!title ? title : t("common:untitled")}
                </div>
                <div className={classes.userName}>
                  {`${firstName} ${lastName}`}
                </div>
              </div>
              <div className={classes.buttonContainer}>
                {createdId == userId && (
                  <div className={classes.deleteIconButton}>
                    <IconButton
                      onClick={() => this.onClickDelete(id)}
                      icon={<DeleteOutlined />}
                      variant={"plain"}
                      size={"medium"}
                    />
                  </div>
                )}
                <div className={classes.previewButton}>
                  <UIButton
                    type={"hollow"}
                    size={"sm"}
                    onClick={() => this.onClickPreview(id)}
                  >
                    {t("common:preview")}
                  </UIButton>
                </div>

                <UIButton
                  color={"blue"}
                  type={"hollow"}
                  size={"sm"}
                  onClick={() => onCopyTool(id)}
                >
                  {t("common:choose")}
                </UIButton>
              </div>
            </div>
          );
        })}
        {_.get(toolList, "length", 0) == 0 && (
          <EmptyField
            title={t("unitPlan:assessment_tool_templates_emptyText", {
              label: name,
            })}
            isDisabled={true}
            containerStyle={{ backgroundColor: colors.white }}
            renderIcon={<AlertIcon fill={colors.gray72} />}
          />
        )}
        {showPreviewModal ? (
          <AssessmentToolPreviewModal
            getPreviewModalBody={getPreviewModalBody}
            tool={_.find(toolList, { id: this.toolId }) || {}}
            toolId={this.toolId}
            title={_.get(_.find(toolList, { id: this.toolId }), "title", null)}
            onCopyTool={() => onCopyTool(this.toolId)}
            onClickClose={() => this.setState({ showPreviewModal: false })}
          />
        ) : null}
        {!!showDialogueBox && (
          <DialogueBox
            modalTitle={`${t("common:delete")} ${name}`}
            showModal={true}
            onClickButton2={this.onDialogConfirmClick}
            modalBody={t("common:delete_msg_with_label", { label: name })}
            toggleDialogueBoxDisplay={this.OnToggleDialogueClick}
            button1={t("common:agree_cancel")}
            button2={t("common:delete_agree")}
          />
        )}
      </div>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(
  AssessmentToolChooseList
);
