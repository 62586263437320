import React from "react";
import classes from "./TaggedPractices.scss";
import { I18nHOC } from "UIComponents";

const TaggedPractices = React.memo(props => {
  const { practices, t } = props;
  return (
    <div className={classes.container}>
      {_.map(practices, ({ id, label, code }) => {
        return (
          <div className={classes.practice} key={id}>
            <span className={classes.code}>{code}</span>
            <span> {t(`snpTemplate:${label}`)}</span>
          </div>
        );
      })}
    </div>
  );
});

export default I18nHOC({ resource: ["snpTemplate"] })(TaggedPractices);
