import gql from "graphql-tag";
import {
  singlePointRubricFragment,
  singlePointRubricElementsFragment,
} from "./SinglePointRubricFragments";
export const createSinglePointRubricMutation = gql`
  mutation createSinglePointRubric(
    $title: String!
    $criterias: [SinglePointRubricCriteriaInput!]
    $standards: [SinglePointRubricStandardInput!]
    $isGlobal: Boolean
    $rubricId: ID
    $mode: SINGLE_POINT_RUBRIC_CREATION_MODE!
    $parentId: ID
    $parentType: ASSESSMENT_TOOL_PARENT_TYPE_ENUM
    $curriculumProgramId: ID
  ) {
    planner {
      createSinglePointRubric(
        input: {
          title: $title
          criterias: $criterias
          standards: $standards
          isGlobal: $isGlobal
          rubricId: $rubricId
          mode: $mode
          parentId: $parentId
          parentType: $parentType
          curriculumProgramId: $curriculumProgramId
        }
      ) {
        ...singlePointRubricItem
      }
    }
  }
  ${singlePointRubricFragment.singlePointRubric}
`;

export const deleteSinglePointRubricMutation = gql`
  mutation deleteSinglePointRubric($id: ID!) {
    planner {
      deleteSinglePointRubric(input: { id: $id }) {
        isSuccess
        warning
      }
    }
  }
`;

export const updateSinglePointRubricMutation = gql`
  mutation updateSinglePointRubric($id: ID!, $title: String!) {
    planner {
      updateSinglePointRubric(input: { id: $id, title: $title }) {
        id
      }
    }
  }
`;

export const createSinglePointRubricStandardMutation = gql`
  mutation createSinglePointRubricStandard(
    $rubricId: ID!
    $title: String
    $subTitle: String
  ) {
    planner {
      createSinglePointRubricStandard(
        input: { rubricId: $rubricId, title: $title, subTitle: $subTitle }
      ) {
        ...singlePointRubricStandardItem
      }
    }
  }
  ${singlePointRubricElementsFragment.singlePointRubricStandard}
`;

export const updateSinglePointRubricStandardMutation = gql`
  mutation updateSinglePointRubricStandard(
    $id: ID!
    $title: String
    $subTitle: String
  ) {
    planner {
      updateSinglePointRubricStandard(
        input: { id: $id, title: $title, subTitle: $subTitle }
      ) {
        id
      }
    }
  }
`;

export const deleteSinglePointRubricStandardMutation = gql`
  mutation deleteSinglePointRubricStandard($id: ID!) {
    planner {
      deleteSinglePointRubricStandard(input: { id: $id }) {
        isSuccess
        warning
      }
    }
  }
`;

export const updateSinglePointRubricCriteriaMutation = gql`
  mutation updateSinglePointRubricCriteria(
    $id: ID!
    $title: String
    $subTitle: String
  ) {
    planner {
      updateSinglePointRubricCriteria(
        input: { id: $id, title: $title, subTitle: $subTitle }
      ) {
        id
      }
    }
  }
`;
