import gql from "graphql-tag";
import {
  attachmentFragment,
  studentFragment,
  toolResponseFragment,
  pypElementRatingFragment,
  staffFragment,
  userFragment,
} from "modules/CommonFragments";
import { journalPostFragment } from "Post/modules/PostFragments";
import { assessmentFragment } from "Assessments/modules/AssessmentFragments";

export const postFragment = {
  postAttachment: gql`
    fragment postItem on Post {
      id
      title
      createdBy {
        ...userItem
      }
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
    ${userFragment.basicUserDetails}
  `,
  postAttachmentBasic: gql`
    fragment postItem on Post {
      id
      attachments {
        ...attachmentItem
      }
    }
    ${attachmentFragment.attachment}
  `,
};
export const studentAssessmentEvaluationFragment = {
  studentEvaluationDetails: gql`
    fragment studentEvaluationItem on StudentAssessmentEvaluation {
      id
      assessment {
        ...assessmentItem
      }
      toolResponses(userFilter: ALL) {
        ...toolResponseItem
      }
      isComplete
      submittedByStudent
      studentRemark
      studentAttachments {
        ...attachmentItem
      }
      assessmentEvaluation {
        isEvaluatedByStudent
      }
      remark
      media {
        ...postItem
      }
    }

    ${postFragment.postAttachment}
    ${toolResponseFragment.toolResponse}
    ${attachmentFragment.attachment}
    ${assessmentFragment.studentPortfolioAssessmentEvaluationFeed}
  `,
};
export const studentAssessmentsFragment = {
  studentAssessmentsFeed: gql`
    fragment studentAssessmentsItem on Student {
      id
      studentAssessmentEvaluations(filters: { type: ALL }) {
        totalCount
        edges {
          node {
            ...studentEvaluationItem
          }
        }
      }
    }
    ${studentAssessmentEvaluationFragment.studentEvaluationDetails}
  `,
};

export const courseStudentFragment = {
  courseStudent: gql`
    fragment courseItem on Course {
      id
      students(
        after: $after
        filters: $filters
        orderBy: TITLE
        orderByDirection: ASC
      ) {
        edges {
          node {
            ...studentItem
          }
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
  courseStudentWithInviteCode: gql`
    fragment courseItem on Course {
      id
      organization {
        name
      }
      students(
        after: $after
        filters: $filters
        orderBy: TITLE
        orderByDirection: ASC
      ) {
        edges {
          node {
            ...studentItem
            familyInviteCode
            signInCode
          }
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
  courseStudentPortfolio: gql`
    fragment courseItem on Course {
      id
      students(
        after: $after
        filters: $filters
        orderBy: TITLE
        orderByDirection: ASC
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...studentItem
            allPosts(filters: $postFilters) {
              totalCount
            }
            familyInviteCode
            signInCode
            families {
              id
              status
              familyMember {
                id
                lastName
                firstName
              }
              createdAt
            }
          }
        }
      }
    }
    ${studentFragment.basicStudentDetails}
  `,
};

export const pypElementRemarkFragment = {
  studentElementRemark: gql`
    fragment remarkItem on IbPypElementRemark {
      id
      ibPYPElementId
      ibPYPElementType
      message
      createdBy {
        ...staffItem
      }
      updatedAt
      parentType
      parent {
        id
        ... on StudentProgressReport {
          courseProgressReportTask {
            id
            progressReportTaskGroup {
              id
              title
            }
          }
        }
      }
    }
    ${staffFragment.basicStaffDetails}
  `,
};

export const studentPypEvaluationFragment = {
  studentPypElementRatings: gql`
    fragment studentPypEvaluationItem on Student {
      id
      ibPYPElementRatings(
        filtersForRecentMost: { elementTypes: $elementTypes }
        dataSet: MOST_RECENT
      ) {
        ...ratingItem
      }
    }
    ${pypElementRatingFragment.studentElementRating}
  `,
  studentPypElementRemarks: gql`
    fragment studentPypEvaluationItem on Student {
      id
      ibPypElementRemarks(elementTypes: $elementTypes) {
        ...remarkItem
      }
    }
    ${pypElementRemarkFragment.studentElementRemark}
  `,
};

export const studentPostFragment = {
  studentPypTaggedPost: gql`
    fragment studentPostItem on Student {
      id
      posts(
        filters: {
          state: PUBLISHED
          requiredFieldUIDs: $fields
          searchText: $searchText
          pypElements: $pypElements
          unitPlanIds: $unitPlanIds
          isShowHiddenFromStudents: true
        }
      ) {
        totalCount
        edges {
          node {
            ...postItem
          }
        }
      }
    }
    ${postFragment.postAttachment}
  `,
};

export const studentGoalTask = gql`
  fragment goalTaskItem on GoalTask {
    id
    title
    note
    checkinDate
    posts {
      ...postItem
    }
    isComplete
    createdBy {
      ...userItem
    }
  }
  ${studentFragment.basicStudentDetails}
  ${journalPostFragment.journalPost}
`;

export const studentGoalFragments = {
  goalFeed: gql`
    fragment studentGoalsItem on Student {
      studentGoalsV2(last: $last, after: $after) {
        totalCount
        edges {
          cursor
          node {
            id
            title
            targetDate
            goalTasks {
              ...goalTaskItem
            }
            createdBy {
              ...userItem
            }
            updatedBy {
              ...userItem
            }
            createdAt
            updatedAt
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
    ${studentFragment.basicStudentDetails}
    ${studentGoalTask}
  `,
  goalDetails: gql`
    fragment goalItem on StudentGoal {
      id
      title
      targetDate
      goalTasks {
        ...goalTaskItem
      }
      createdBy {
        ...userItem
      }
      updatedBy {
        ...userItem
      }
      createdAt
      updatedAt
    }
    ${studentFragment.basicStudentDetails}
    ${studentGoalTask}
  `,
};
