import React from "react";
import PropTypes from "prop-types";

const ToddleIcon = props => {
  return (
    <svg viewBox="0 0 100 100" width={props.width} height={props.height}>
      <g fill={props.fill && props.fill}>
        <path
          d="M16.1063848,2.36637802 C-4.70212828,14.2798866 -4.70212828,85.7217492 16.1063848,97.6352579 C36.914898,109.548767 99.3799973,73.8474298 99.3799973,50.0204125 C99.3799973,26.1933953 36.914898,-9.54713061 16.1063848,2.36637802 Z"
          fill="#FF5869"
        ></path>
        <path
          d="M48.8224311,62.5217588 C49.0993505,62.5217588 49.4949496,62.4433804 49.8905487,62.4041912 C50.3257078,62.3258129 50.4443875,62.3258129 50.681747,62.3258129 C52.3828231,62.3258129 53.2927011,63.5798664 53.2927011,64.9122983 C53.2927011,65.970406 52.6597425,66.9109462 51.6311848,67.4204054 C50.7213069,67.8906755 49.5345095,68.1649997 48.3081523,68.1649997 C41.1082485,68.1649997 39.5258521,63.7366231 39.5258521,58.4852739 L39.5258521,45.7096035 L37.3104971,45.7096035 C34.699543,45.7096035 34.2643839,43.8677124 34.2643839,42.9663614 C34.2643839,42.0650104 34.699543,40.2231193 37.3104971,40.2231193 L39.5258521,40.2231193 L39.5258521,35.5204185 C39.5258521,32.3852846 41.7412071,31.797447 42.8488846,31.797447 C43.996122,31.797447 46.1719171,32.3460955 46.1719171,35.5204185 L46.1719171,40.2231193 L50.4443875,40.2231193 C53.0553416,40.2231193 53.4905006,42.0650104 53.4905006,42.9663614 C53.4905006,43.8677124 53.0553416,45.7096035 50.4443875,45.7096035 L46.1719171,45.7096035 L46.1719171,58.4068956 C46.211477,61.1109485 47.1609149,62.5217588 48.8224311,62.5217588 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
};

ToddleIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
};

ToddleIcon.defaultProps = {
  height: 40,
  width: 40,
};

export default ToddleIcon;
