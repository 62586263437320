import React, { useEffect, useRef } from "react";
import UIBaseComponent from "UIComponents/UIBaseComponent";
import classes from "./Note.scss";
import { TextAreaInput } from "UIComponents";
import PropTypes from "prop-types";

const Note = props => {
  const {
    updateNote,
    value,
    noteTextAreaStyles,
    minRows,
    noteName,
    placeholder,
    onBlur,
    linesContainerStyles,
    autoFocus,
    isFocused,
  } = props;

  const textAreaInputRef = useRef();

  useEffect(() => {
    if (isFocused && textAreaInputRef?.current) {
      textAreaInputRef.current.focus();
    }
  }, []);

  return (
    <div className={classes.noteLinesContainer} style={linesContainerStyles}>
      <div className={classes.noteContainer}>
        <TextAreaInput
          ref={textAreaInputRef}
          name={noteName}
          value={value}
          minRows={minRows}
          placeholder={placeholder}
          updateInputField={updateNote}
          textAreaStyles={noteTextAreaStyles}
          onBlur={onBlur}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
};

Note.defaultProps = {
  ...UIBaseComponent.defaultProps,
  noteTextAreaStyles: {},
  minRows: 10,
  noteName: "Title",
  linesContainerStyles: {},
  autoFocus: false,
  isFocused: false,
};

Note.propTypes = {
  ...UIBaseComponent.propTypes,
  minRows: PropTypes.number,
  noteTextAreaStyles: PropTypes.object,
  linesContainerStyles: PropTypes.object,
  updateNote: PropTypes.func,
  noteName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  isFocused: PropTypes.bool,
};

export default Note;
