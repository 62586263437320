import PropTypes from "prop-types";
import React from "react";

const BigStarIcon = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 20 19"
      version="1.1"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Planathon_External-Review-1"
          transform="translate(-626.000000, -365.000000)"
          fill={props.fill}
          stroke={props.stroke}
        >
          <path
            d="M636,365.884454 L632.78521,370.575221 L627.3306,372.183141 L630.79836,376.690115 L630.642016,382.374632 L636,380.469328 L641.357984,382.374632 L641.20164,376.690115 L644.6694,372.183141 L639.21479,370.575221 L636,365.884454 Z"
            id="Star-Copy-4"
          ></path>
        </g>
      </g>
    </svg>
  );
};

BigStarIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

BigStarIcon.defaultProps = {
  height: 16,
  width: 16,
  fill: "white",
  stroke: "black",
};

export default BigStarIcon;
