import React from "react";
import classes from "./ResourceCreateEditModal.scss";
import { UIButton, I18nHOC } from "UIComponents";
import ModalContainer from "./ModalContainer";

class ResourceCreateEditModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.modalContainer = null;
    this.state = {
      attachmentLoading: false,
    };
  }
  UNSAFE_componentWillMount = () => {
    if (this.props.mode == "new") {
      this.props.initializeResource();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { resourceObject: oldResourceObject } = prevProps;

    const { updateValueLocally, resourceObject } = this.props;
    if (resourceObject != oldResourceObject) {
      if (updateValueLocally) {
        updateValueLocally(resourceObject);
      }
    }
  };

  onClickAdd = () => {
    if (this.isValid() == 0) {
      if (this.props.mode == "new") {
        this.props.createResource();
      } else {
        this.props.updateResource();
      }
      this.props.onClickClose();
    }
  };

  isValid = () => {
    let totalErrors = 0;
    if (this.modalContainer) {
      totalErrors = this.modalContainer.isValid();
    }

    return totalErrors;
  };

  updateLoadingState = state => {
    // console.log(state);
    this.setState({ attachmentLoading: state });
  };

  render() {
    const {
      mode,
      resourceObject,
      updateLocalResource,
      setToastMsg,
      onClickClose,
      updateAttachmentListRef,
      t,
      portalType,
    } = this.props;

    const { attachmentLoading } = this.state;
    return (
      <React.Fragment>
        <div className={classes.title}>
          {mode == "new"
            ? t("common:create_new_with_label", {
                label: t("common:resource_label").toLowerCase(),
              })
            : t("common:edit_with_label", {
                label: t("common:resource_label").toLowerCase(),
              })}
        </div>

        <div className={classes.formFieldsParentContainer}>
          <div className={classes.formFields}>
            <ModalContainer
              ref={ref => (this.modalContainer = ref)}
              mode={mode}
              resourceObject={resourceObject}
              updateLocalResource={updateLocalResource}
              updateLoadingState={this.updateLoadingState}
              setToastMsg={setToastMsg}
              updateAttachmentListRef={updateAttachmentListRef}
              portalType={portalType}
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.cancelDiv}>
            <UIButton color="grey" onClick={onClickClose} size="sm">
              {t("common:cancel")}
            </UIButton>
          </div>

          <UIButton
            color="pink"
            onClick={this.onClickAdd}
            size="sm"
            isDisabled={attachmentLoading}
          >
            {mode == "edit" ? t("common:update") : t("common:create")}
          </UIButton>
        </div>
      </React.Fragment>
    );
  }
}

export default I18nHOC({ resource: ["unitPlan", "common"] })(
  ResourceCreateEditModal
);
