import { useEffect } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, container }) => {
  let mount = document.body;
  if (container) {
    mount = container;
  }

  // useEffect(() => {
  //   // unmounting logic in cleanup function
  // }, [children, mount]);

  return createPortal(children, mount);
};

export default Portal;
