import React, { useEffect } from "react";
import classes from "./UnderMaintanance.scss";
import { INTERCOM_APP_ID } from "Constants";
import { ToddleIcon } from "SvgComponents";
import moment from "moment";

const UnderMaintanance = React.memo(() => {
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: INTERCOM_APP_ID,
      });
    }
  }, []);
  return (
    <div className={classes.modalContent}>
      <ToddleIcon width={120} height={120} />
      <div
        className={classes.headerText}
      >{`We are undertaking a scheduled maintenance`}</div>
      <div className={classes.text}>{`We'll be back at ${moment(
        "2020-12-26T07:30:00.000Z"
      ).format("hh:mm a")}.`}</div>
    </div>
  );
});

export default UnderMaintanance;
